/*
@tailwind base;

@tailwind components;
*/

.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse))
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.1rem * var(--tw-space-y-reverse))
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.1rem * var(--tw-space-x-reverse));
  margin-left: calc(0.1rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.2rem * var(--tw-space-y-reverse))
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.2rem * var(--tw-space-x-reverse));
  margin-left: calc(0.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.3rem * var(--tw-space-y-reverse))
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.3rem * var(--tw-space-x-reverse));
  margin-left: calc(0.3rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.4rem * var(--tw-space-y-reverse))
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.4rem * var(--tw-space-x-reverse));
  margin-left: calc(0.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.6rem * var(--tw-space-y-reverse))
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.6rem * var(--tw-space-x-reverse));
  margin-left: calc(0.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.7rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.7rem * var(--tw-space-y-reverse))
}

.space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.7rem * var(--tw-space-x-reverse));
  margin-left: calc(0.7rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.8rem * var(--tw-space-y-reverse))
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.8rem * var(--tw-space-x-reverse));
  margin-left: calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.9rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.9rem * var(--tw-space-y-reverse))
}

.space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.9rem * var(--tw-space-x-reverse));
  margin-left: calc(0.9rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.0rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.0rem * var(--tw-space-y-reverse))
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.0rem * var(--tw-space-x-reverse));
  margin-left: calc(1.0rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.2rem * var(--tw-space-y-reverse))
}

.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.2rem * var(--tw-space-x-reverse));
  margin-left: calc(1.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.4rem * var(--tw-space-y-reverse))
}

.space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.4rem * var(--tw-space-x-reverse));
  margin-left: calc(1.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.6rem * var(--tw-space-y-reverse))
}

.space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.6rem * var(--tw-space-x-reverse));
  margin-left: calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse))
}

.space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.4rem * var(--tw-space-y-reverse))
}

.space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.4rem * var(--tw-space-x-reverse));
  margin-left: calc(2.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.8rem * var(--tw-space-y-reverse))
}

.space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.8rem * var(--tw-space-x-reverse));
  margin-left: calc(2.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.2rem * var(--tw-space-y-reverse))
}

.space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3.2rem * var(--tw-space-x-reverse));
  margin-left: calc(3.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.6rem * var(--tw-space-y-reverse))
}

.space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3.6rem * var(--tw-space-x-reverse));
  margin-left: calc(3.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse))
}

.space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4.4rem * var(--tw-space-y-reverse))
}

.space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4.4rem * var(--tw-space-x-reverse));
  margin-left: calc(4.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4.8rem * var(--tw-space-y-reverse))
}

.space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4.8rem * var(--tw-space-x-reverse));
  margin-left: calc(4.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5.2rem * var(--tw-space-y-reverse))
}

.space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(5.2rem * var(--tw-space-x-reverse));
  margin-left: calc(5.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5.6rem * var(--tw-space-y-reverse))
}

.space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(5.6rem * var(--tw-space-x-reverse));
  margin-left: calc(5.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6rem * var(--tw-space-y-reverse))
}

.space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6rem * var(--tw-space-x-reverse));
  margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6.4rem * var(--tw-space-y-reverse))
}

.space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6.4rem * var(--tw-space-x-reverse));
  margin-left: calc(6.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-68 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6.8rem * var(--tw-space-y-reverse))
}

.space-x-68 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6.8rem * var(--tw-space-x-reverse));
  margin-left: calc(6.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(7.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(7.2rem * var(--tw-space-y-reverse))
}

.space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(7.2rem * var(--tw-space-x-reverse));
  margin-left: calc(7.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-76 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(7.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(7.6rem * var(--tw-space-y-reverse))
}

.space-x-76 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(7.6rem * var(--tw-space-x-reverse));
  margin-left: calc(7.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8rem * var(--tw-space-y-reverse))
}

.space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8rem * var(--tw-space-x-reverse));
  margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-84 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8.4rem * var(--tw-space-y-reverse))
}

.space-x-84 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8.4rem * var(--tw-space-x-reverse));
  margin-left: calc(8.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-88 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8.8rem * var(--tw-space-y-reverse))
}

.space-x-88 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8.8rem * var(--tw-space-x-reverse));
  margin-left: calc(8.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-92 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(9.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(9.2rem * var(--tw-space-y-reverse))
}

.space-x-92 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(9.2rem * var(--tw-space-x-reverse));
  margin-left: calc(9.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(9.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(9.6rem * var(--tw-space-y-reverse))
}

.space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(9.6rem * var(--tw-space-x-reverse));
  margin-left: calc(9.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-128 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12.8rem * var(--tw-space-y-reverse))
}

.space-x-128 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(12.8rem * var(--tw-space-x-reverse));
  margin-left: calc(12.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-136 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(13.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(13.6rem * var(--tw-space-y-reverse))
}

.space-x-136 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(13.6rem * var(--tw-space-x-reverse));
  margin-left: calc(13.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-160 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(16rem * var(--tw-space-y-reverse))
}

.space-x-160 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(16rem * var(--tw-space-x-reverse));
  margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-192 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(19.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(19.2rem * var(--tw-space-y-reverse))
}

.space-x-192 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(19.2rem * var(--tw-space-x-reverse));
  margin-left: calc(19.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(20rem * var(--tw-space-y-reverse))
}

.space-x-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(20rem * var(--tw-space-x-reverse));
  margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-208 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(20.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(20.8rem * var(--tw-space-y-reverse))
}

.space-x-208 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(20.8rem * var(--tw-space-x-reverse));
  margin-left: calc(20.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-216 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(21.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(21.6rem * var(--tw-space-y-reverse))
}

.space-x-216 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(21.6rem * var(--tw-space-x-reverse));
  margin-left: calc(21.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-224 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(22.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(22.4rem * var(--tw-space-y-reverse))
}

.space-x-224 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(22.4rem * var(--tw-space-x-reverse));
  margin-left: calc(22.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-256 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(25.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(25.6rem * var(--tw-space-y-reverse))
}

.space-x-256 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(25.6rem * var(--tw-space-x-reverse));
  margin-left: calc(25.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-288 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(28.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(28.8rem * var(--tw-space-y-reverse))
}

.space-x-288 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(28.8rem * var(--tw-space-x-reverse));
  margin-left: calc(28.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-320 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(32rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(32rem * var(--tw-space-y-reverse))
}

.space-x-320 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(32rem * var(--tw-space-x-reverse));
  margin-left: calc(32rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-360 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(36rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(36rem * var(--tw-space-y-reverse))
}

.space-x-360 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(36rem * var(--tw-space-x-reverse));
  margin-left: calc(36rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-384 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(38.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(38.4rem * var(--tw-space-y-reverse))
}

.space-x-384 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(38.4rem * var(--tw-space-x-reverse));
  margin-left: calc(38.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(40rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(40rem * var(--tw-space-y-reverse))
}

.space-x-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(40rem * var(--tw-space-x-reverse));
  margin-left: calc(40rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-512 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(51.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(51.2rem * var(--tw-space-y-reverse))
}

.space-x-512 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(51.2rem * var(--tw-space-x-reverse));
  margin-left: calc(51.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-640 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(64rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(64rem * var(--tw-space-y-reverse))
}

.space-x-640 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(64rem * var(--tw-space-x-reverse));
  margin-left: calc(64rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-xs > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(32rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(32rem * var(--tw-space-y-reverse))
}

.space-x-xs > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(32rem * var(--tw-space-x-reverse));
  margin-left: calc(32rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-sm > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(48rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(48rem * var(--tw-space-y-reverse))
}

.space-x-sm > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(48rem * var(--tw-space-x-reverse));
  margin-left: calc(48rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-md > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(64rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(64rem * var(--tw-space-y-reverse))
}

.space-x-md > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(64rem * var(--tw-space-x-reverse));
  margin-left: calc(64rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-lg > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(80rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(80rem * var(--tw-space-y-reverse))
}

.space-x-lg > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(80rem * var(--tw-space-x-reverse));
  margin-left: calc(80rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(96rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(96rem * var(--tw-space-y-reverse))
}

.space-x-xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(96rem * var(--tw-space-x-reverse));
  margin-left: calc(96rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-2xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(112rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(112rem * var(--tw-space-y-reverse))
}

.space-x-2xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(112rem * var(--tw-space-x-reverse));
  margin-left: calc(112rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-3xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(128rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(128rem * var(--tw-space-y-reverse))
}

.space-x-3xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(128rem * var(--tw-space-x-reverse));
  margin-left: calc(128rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-4xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(144rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(144rem * var(--tw-space-y-reverse))
}

.space-x-4xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(144rem * var(--tw-space-x-reverse));
  margin-left: calc(144rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-5xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(160rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(160rem * var(--tw-space-y-reverse))
}

.space-x-5xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(160rem * var(--tw-space-x-reverse));
  margin-left: calc(160rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1px * var(--tw-space-y-reverse))
}

.space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1px * var(--tw-space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.05rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.05rem * var(--tw-space-y-reverse))
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.05rem * var(--tw-space-x-reverse));
  margin-left: calc(0.05rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.15rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.15rem * var(--tw-space-y-reverse))
}

.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.15rem * var(--tw-space-x-reverse));
  margin-left: calc(0.15rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
}

.space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.35rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.35rem * var(--tw-space-y-reverse))
}

.space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.35rem * var(--tw-space-x-reverse));
  margin-left: calc(0.35rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.1rem * var(--tw-space-y-reverse))
}

.-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.1rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.1rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.2rem * var(--tw-space-y-reverse))
}

.-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.2rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.3rem * var(--tw-space-y-reverse))
}

.-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.3rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.3rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.4rem * var(--tw-space-y-reverse))
}

.-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.4rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse))
}

.-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.6rem * var(--tw-space-y-reverse))
}

.-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.6rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.7rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.7rem * var(--tw-space-y-reverse))
}

.-space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.7rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.7rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.8rem * var(--tw-space-y-reverse))
}

.-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.8rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.9rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.9rem * var(--tw-space-y-reverse))
}

.-space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.9rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.9rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse))
}

.-space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1rem * var(--tw-space-x-reverse));
  margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.2rem * var(--tw-space-y-reverse))
}

.-space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.2rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.4rem * var(--tw-space-y-reverse))
}

.-space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.4rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.6rem * var(--tw-space-y-reverse))
}

.-space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.6rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2rem * var(--tw-space-y-reverse))
}

.-space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2rem * var(--tw-space-x-reverse));
  margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.4rem * var(--tw-space-y-reverse))
}

.-space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.4rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.8rem * var(--tw-space-y-reverse))
}

.-space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.8rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3.2rem * var(--tw-space-y-reverse))
}

.-space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3.2rem * var(--tw-space-x-reverse));
  margin-left: calc(-3.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3.6rem * var(--tw-space-y-reverse))
}

.-space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3.6rem * var(--tw-space-x-reverse));
  margin-left: calc(-3.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-4rem * var(--tw-space-y-reverse))
}

.-space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-4rem * var(--tw-space-x-reverse));
  margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-4.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-4.4rem * var(--tw-space-y-reverse))
}

.-space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-4.4rem * var(--tw-space-x-reverse));
  margin-left: calc(-4.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-4.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-4.8rem * var(--tw-space-y-reverse))
}

.-space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-4.8rem * var(--tw-space-x-reverse));
  margin-left: calc(-4.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-5.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-5.2rem * var(--tw-space-y-reverse))
}

.-space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-5.2rem * var(--tw-space-x-reverse));
  margin-left: calc(-5.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-5.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-5.6rem * var(--tw-space-y-reverse))
}

.-space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-5.6rem * var(--tw-space-x-reverse));
  margin-left: calc(-5.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-6rem * var(--tw-space-y-reverse))
}

.-space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-6rem * var(--tw-space-x-reverse));
  margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-6.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-6.4rem * var(--tw-space-y-reverse))
}

.-space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-6.4rem * var(--tw-space-x-reverse));
  margin-left: calc(-6.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-68 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-6.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-6.8rem * var(--tw-space-y-reverse))
}

.-space-x-68 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-6.8rem * var(--tw-space-x-reverse));
  margin-left: calc(-6.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-7.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-7.2rem * var(--tw-space-y-reverse))
}

.-space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-7.2rem * var(--tw-space-x-reverse));
  margin-left: calc(-7.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-76 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-7.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-7.6rem * var(--tw-space-y-reverse))
}

.-space-x-76 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-7.6rem * var(--tw-space-x-reverse));
  margin-left: calc(-7.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-8rem * var(--tw-space-y-reverse))
}

.-space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-8rem * var(--tw-space-x-reverse));
  margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-84 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-8.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-8.4rem * var(--tw-space-y-reverse))
}

.-space-x-84 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-8.4rem * var(--tw-space-x-reverse));
  margin-left: calc(-8.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-88 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-8.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-8.8rem * var(--tw-space-y-reverse))
}

.-space-x-88 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-8.8rem * var(--tw-space-x-reverse));
  margin-left: calc(-8.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-92 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-9.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-9.2rem * var(--tw-space-y-reverse))
}

.-space-x-92 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-9.2rem * var(--tw-space-x-reverse));
  margin-left: calc(-9.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-9.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-9.6rem * var(--tw-space-y-reverse))
}

.-space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-9.6rem * var(--tw-space-x-reverse));
  margin-left: calc(-9.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-128 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-12.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-12.8rem * var(--tw-space-y-reverse))
}

.-space-x-128 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-12.8rem * var(--tw-space-x-reverse));
  margin-left: calc(-12.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-136 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-13.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-13.6rem * var(--tw-space-y-reverse))
}

.-space-x-136 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-13.6rem * var(--tw-space-x-reverse));
  margin-left: calc(-13.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-160 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-16rem * var(--tw-space-y-reverse))
}

.-space-x-160 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-16rem * var(--tw-space-x-reverse));
  margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-192 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-19.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-19.2rem * var(--tw-space-y-reverse))
}

.-space-x-192 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-19.2rem * var(--tw-space-x-reverse));
  margin-left: calc(-19.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-20rem * var(--tw-space-y-reverse))
}

.-space-x-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-20rem * var(--tw-space-x-reverse));
  margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-208 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-20.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-20.8rem * var(--tw-space-y-reverse))
}

.-space-x-208 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-20.8rem * var(--tw-space-x-reverse));
  margin-left: calc(-20.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-216 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-21.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-21.6rem * var(--tw-space-y-reverse))
}

.-space-x-216 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-21.6rem * var(--tw-space-x-reverse));
  margin-left: calc(-21.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-224 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-22.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-22.4rem * var(--tw-space-y-reverse))
}

.-space-x-224 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-22.4rem * var(--tw-space-x-reverse));
  margin-left: calc(-22.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-256 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-25.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-25.6rem * var(--tw-space-y-reverse))
}

.-space-x-256 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-25.6rem * var(--tw-space-x-reverse));
  margin-left: calc(-25.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-288 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-28.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-28.8rem * var(--tw-space-y-reverse))
}

.-space-x-288 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-28.8rem * var(--tw-space-x-reverse));
  margin-left: calc(-28.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-320 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-32rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-32rem * var(--tw-space-y-reverse))
}

.-space-x-320 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-32rem * var(--tw-space-x-reverse));
  margin-left: calc(-32rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-360 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-36rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-36rem * var(--tw-space-y-reverse))
}

.-space-x-360 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-36rem * var(--tw-space-x-reverse));
  margin-left: calc(-36rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-384 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-38.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-38.4rem * var(--tw-space-y-reverse))
}

.-space-x-384 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-38.4rem * var(--tw-space-x-reverse));
  margin-left: calc(-38.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-40rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-40rem * var(--tw-space-y-reverse))
}

.-space-x-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-40rem * var(--tw-space-x-reverse));
  margin-left: calc(-40rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-512 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-51.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-51.2rem * var(--tw-space-y-reverse))
}

.-space-x-512 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-51.2rem * var(--tw-space-x-reverse));
  margin-left: calc(-51.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-640 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-64rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-64rem * var(--tw-space-y-reverse))
}

.-space-x-640 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-64rem * var(--tw-space-x-reverse));
  margin-left: calc(-64rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-xs > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-32rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-32rem * var(--tw-space-y-reverse))
}

.-space-x-xs > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-32rem * var(--tw-space-x-reverse));
  margin-left: calc(-32rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-sm > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-48rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-48rem * var(--tw-space-y-reverse))
}

.-space-x-sm > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-48rem * var(--tw-space-x-reverse));
  margin-left: calc(-48rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-md > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-64rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-64rem * var(--tw-space-y-reverse))
}

.-space-x-md > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-64rem * var(--tw-space-x-reverse));
  margin-left: calc(-64rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-lg > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-80rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-80rem * var(--tw-space-y-reverse))
}

.-space-x-lg > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-80rem * var(--tw-space-x-reverse));
  margin-left: calc(-80rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-96rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-96rem * var(--tw-space-y-reverse))
}

.-space-x-xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-96rem * var(--tw-space-x-reverse));
  margin-left: calc(-96rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-2xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-112rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-112rem * var(--tw-space-y-reverse))
}

.-space-x-2xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-112rem * var(--tw-space-x-reverse));
  margin-left: calc(-112rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-3xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-128rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-128rem * var(--tw-space-y-reverse))
}

.-space-x-3xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-128rem * var(--tw-space-x-reverse));
  margin-left: calc(-128rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-4xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-144rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-144rem * var(--tw-space-y-reverse))
}

.-space-x-4xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-144rem * var(--tw-space-x-reverse));
  margin-left: calc(-144rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-5xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-160rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-160rem * var(--tw-space-y-reverse))
}

.-space-x-5xl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-160rem * var(--tw-space-x-reverse));
  margin-left: calc(-160rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1px * var(--tw-space-y-reverse))
}

.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.05rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.05rem * var(--tw-space-y-reverse))
}

.-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.05rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.05rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.15rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.15rem * var(--tw-space-y-reverse))
}

.-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.15rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.15rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse))
}

.-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)))
}

.-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.35rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.35rem * var(--tw-space-y-reverse))
}

.-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.35rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.35rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1
}

.space-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1
}

.divide-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse))
}

.divide-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(0px * var(--tw-divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)))
}

.divide-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}

.divide-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse))
}

.divide-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(2px * var(--tw-divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)))
}

.divide-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(3px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(3px * var(--tw-divide-y-reverse))
}

.divide-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(3px * var(--tw-divide-x-reverse));
  border-left-width: calc(3px * calc(1 - var(--tw-divide-x-reverse)))
}

.divide-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(4px * var(--tw-divide-y-reverse))
}

.divide-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(4px * var(--tw-divide-x-reverse));
  border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)))
}

.divide-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(8px * var(--tw-divide-y-reverse))
}

.divide-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(8px * var(--tw-divide-x-reverse));
  border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)))
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
}

.divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 1
}

.divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 1
}

.divide-transparent > :not([hidden]) ~ :not([hidden]) {
  border-color: transparent
}

.divide-current > :not([hidden]) ~ :not([hidden]) {
  border-color: currentColor
}

.divide-black > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(34, 41, 47, var(--tw-divide-opacity))
}

.divide-white > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-divide-opacity))
}

.divide-grey-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-divide-opacity))
}

.divide-grey-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-divide-opacity))
}

.divide-grey-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-divide-opacity))
}

.divide-grey-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-divide-opacity))
}

.divide-grey-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(189, 189, 189, var(--tw-divide-opacity))
}

.divide-grey-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
}

.divide-grey-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(117, 117, 117, var(--tw-divide-opacity))
}

.divide-grey-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
}

.divide-grey-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(66, 66, 66, var(--tw-divide-opacity))
}

.divide-grey-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(33, 33, 33, var(--tw-divide-opacity))
}

.divide-grey > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
}

.divide-grey-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(213, 213, 213, var(--tw-divide-opacity))
}

.divide-grey-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(170, 170, 170, var(--tw-divide-opacity))
}

.divide-grey-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(48, 48, 48, var(--tw-divide-opacity))
}

.divide-grey-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
}

.divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-divide-opacity))
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-divide-opacity))
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-divide-opacity))
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-divide-opacity))
}

.divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(189, 189, 189, var(--tw-divide-opacity))
}

.divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
}

.divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(117, 117, 117, var(--tw-divide-opacity))
}

.divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
}

.divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(66, 66, 66, var(--tw-divide-opacity))
}

.divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(33, 33, 33, var(--tw-divide-opacity))
}

.divide-gray > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
}

.divide-gray-hover > :not([hidden]) ~ :not([hidden]) {
  border-color: rgba(0, 0, 0, 0.04)
}

.divide-gray-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(213, 213, 213, var(--tw-divide-opacity))
}

.divide-gray-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(170, 170, 170, var(--tw-divide-opacity))
}

.divide-gray-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(48, 48, 48, var(--tw-divide-opacity))
}

.divide-gray-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
}

.divide-red-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 235, 238, var(--tw-divide-opacity))
}

.divide-red-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 205, 210, var(--tw-divide-opacity))
}

.divide-red-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(239, 154, 154, var(--tw-divide-opacity))
}

.divide-red-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 115, 115, var(--tw-divide-opacity))
}

.divide-red-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(239, 83, 80, var(--tw-divide-opacity))
}

.divide-red-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-divide-opacity))
}

.divide-red-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 57, 53, var(--tw-divide-opacity))
}

.divide-red-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(211, 47, 47, var(--tw-divide-opacity))
}

.divide-red-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(198, 40, 40, var(--tw-divide-opacity))
}

.divide-red-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(183, 28, 28, var(--tw-divide-opacity))
}

.divide-red > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-divide-opacity))
}

.divide-red-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 138, 128, var(--tw-divide-opacity))
}

.divide-red-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 82, 82, var(--tw-divide-opacity))
}

.divide-red-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 23, 68, var(--tw-divide-opacity))
}

.divide-red-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(213, 0, 0, var(--tw-divide-opacity))
}

.divide-orange-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 243, 224, var(--tw-divide-opacity))
}

.divide-orange-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 224, 178, var(--tw-divide-opacity))
}

.divide-orange-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 204, 128, var(--tw-divide-opacity))
}

.divide-orange-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 183, 77, var(--tw-divide-opacity))
}

.divide-orange-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 167, 38, var(--tw-divide-opacity))
}

.divide-orange-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-divide-opacity))
}

.divide-orange-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(251, 140, 0, var(--tw-divide-opacity))
}

.divide-orange-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 124, 0, var(--tw-divide-opacity))
}

.divide-orange-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(239, 108, 0, var(--tw-divide-opacity))
}

.divide-orange-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(230, 81, 0, var(--tw-divide-opacity))
}

.divide-orange > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-divide-opacity))
}

.divide-orange-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 209, 128, var(--tw-divide-opacity))
}

.divide-orange-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 171, 64, var(--tw-divide-opacity))
}

.divide-orange-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 145, 0, var(--tw-divide-opacity))
}

.divide-orange-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 109, 0, var(--tw-divide-opacity))
}

.divide-deep-orange-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(251, 233, 231, var(--tw-divide-opacity))
}

.divide-deep-orange-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 204, 188, var(--tw-divide-opacity))
}

.divide-deep-orange-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 171, 145, var(--tw-divide-opacity))
}

.divide-deep-orange-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 138, 101, var(--tw-divide-opacity))
}

.divide-deep-orange-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 112, 67, var(--tw-divide-opacity))
}

.divide-deep-orange-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-divide-opacity))
}

.divide-deep-orange-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(244, 81, 30, var(--tw-divide-opacity))
}

.divide-deep-orange-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(230, 74, 25, var(--tw-divide-opacity))
}

.divide-deep-orange-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(216, 67, 21, var(--tw-divide-opacity))
}

.divide-deep-orange-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(191, 54, 12, var(--tw-divide-opacity))
}

.divide-deep-orange > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-divide-opacity))
}

.divide-deep-orange-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 158, 128, var(--tw-divide-opacity))
}

.divide-deep-orange-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 110, 64, var(--tw-divide-opacity))
}

.divide-deep-orange-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 61, 0, var(--tw-divide-opacity))
}

.divide-deep-orange-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(221, 44, 0, var(--tw-divide-opacity))
}

.divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 253, 231, var(--tw-divide-opacity))
}

.divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 249, 196, var(--tw-divide-opacity))
}

.divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 245, 157, var(--tw-divide-opacity))
}

.divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 241, 118, var(--tw-divide-opacity))
}

.divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 238, 88, var(--tw-divide-opacity))
}

.divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 235, 59, var(--tw-divide-opacity))
}

.divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(253, 216, 53, var(--tw-divide-opacity))
}

.divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(251, 192, 45, var(--tw-divide-opacity))
}

.divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(249, 168, 37, var(--tw-divide-opacity))
}

.divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 127, 23, var(--tw-divide-opacity))
}

.divide-yellow > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 235, 59, var(--tw-divide-opacity))
}

.divide-yellow-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 255, 141, var(--tw-divide-opacity))
}

.divide-yellow-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 255, 0, var(--tw-divide-opacity))
}

.divide-yellow-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 234, 0, var(--tw-divide-opacity))
}

.divide-yellow-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 214, 0, var(--tw-divide-opacity))
}

.divide-green-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(232, 245, 233, var(--tw-divide-opacity))
}

.divide-green-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(200, 230, 201, var(--tw-divide-opacity))
}

.divide-green-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(165, 214, 167, var(--tw-divide-opacity))
}

.divide-green-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(129, 199, 132, var(--tw-divide-opacity))
}

.divide-green-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(102, 187, 106, var(--tw-divide-opacity))
}

.divide-green-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-divide-opacity))
}

.divide-green-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(67, 160, 71, var(--tw-divide-opacity))
}

.divide-green-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(56, 142, 60, var(--tw-divide-opacity))
}

.divide-green-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(46, 125, 50, var(--tw-divide-opacity))
}

.divide-green-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(27, 94, 32, var(--tw-divide-opacity))
}

.divide-green > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-divide-opacity))
}

.divide-green-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(185, 246, 202, var(--tw-divide-opacity))
}

.divide-green-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(105, 240, 174, var(--tw-divide-opacity))
}

.divide-green-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 230, 118, var(--tw-divide-opacity))
}

.divide-green-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 200, 83, var(--tw-divide-opacity))
}

.divide-light-green-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(241, 248, 233, var(--tw-divide-opacity))
}

.divide-light-green-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(220, 237, 200, var(--tw-divide-opacity))
}

.divide-light-green-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(197, 225, 165, var(--tw-divide-opacity))
}

.divide-light-green-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(174, 213, 129, var(--tw-divide-opacity))
}

.divide-light-green-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(156, 204, 101, var(--tw-divide-opacity))
}

.divide-light-green-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-divide-opacity))
}

.divide-light-green-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(124, 179, 66, var(--tw-divide-opacity))
}

.divide-light-green-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(104, 159, 56, var(--tw-divide-opacity))
}

.divide-light-green-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(85, 139, 47, var(--tw-divide-opacity))
}

.divide-light-green-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(51, 105, 30, var(--tw-divide-opacity))
}

.divide-light-green > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-divide-opacity))
}

.divide-light-green-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(204, 255, 144, var(--tw-divide-opacity))
}

.divide-light-green-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(178, 255, 89, var(--tw-divide-opacity))
}

.divide-light-green-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(118, 255, 3, var(--tw-divide-opacity))
}

.divide-light-green-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(100, 221, 23, var(--tw-divide-opacity))
}

.divide-teal-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(224, 242, 241, var(--tw-divide-opacity))
}

.divide-teal-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(178, 223, 219, var(--tw-divide-opacity))
}

.divide-teal-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(128, 203, 196, var(--tw-divide-opacity))
}

.divide-teal-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(77, 182, 172, var(--tw-divide-opacity))
}

.divide-teal-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(38, 166, 154, var(--tw-divide-opacity))
}

.divide-teal-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-divide-opacity))
}

.divide-teal-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 137, 123, var(--tw-divide-opacity))
}

.divide-teal-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 121, 107, var(--tw-divide-opacity))
}

.divide-teal-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 105, 92, var(--tw-divide-opacity))
}

.divide-teal-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 77, 64, var(--tw-divide-opacity))
}

.divide-teal > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-divide-opacity))
}

.divide-teal-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(167, 255, 235, var(--tw-divide-opacity))
}

.divide-teal-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(100, 255, 218, var(--tw-divide-opacity))
}

.divide-teal-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(29, 233, 182, var(--tw-divide-opacity))
}

.divide-teal-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 191, 165, var(--tw-divide-opacity))
}

.divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(227, 242, 253, var(--tw-divide-opacity))
}

.divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(187, 222, 251, var(--tw-divide-opacity))
}

.divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(144, 202, 249, var(--tw-divide-opacity))
}

.divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(100, 181, 246, var(--tw-divide-opacity))
}

.divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(66, 165, 245, var(--tw-divide-opacity))
}

.divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-divide-opacity))
}

.divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(30, 136, 229, var(--tw-divide-opacity))
}

.divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(25, 118, 210, var(--tw-divide-opacity))
}

.divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(21, 101, 192, var(--tw-divide-opacity))
}

.divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(13, 71, 161, var(--tw-divide-opacity))
}

.divide-blue > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-divide-opacity))
}

.divide-blue-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(130, 177, 255, var(--tw-divide-opacity))
}

.divide-blue-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(68, 138, 255, var(--tw-divide-opacity))
}

.divide-blue-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(41, 121, 255, var(--tw-divide-opacity))
}

.divide-blue-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(41, 98, 255, var(--tw-divide-opacity))
}

.divide-light-blue-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(225, 245, 254, var(--tw-divide-opacity))
}

.divide-light-blue-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(179, 229, 252, var(--tw-divide-opacity))
}

.divide-light-blue-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(129, 212, 250, var(--tw-divide-opacity))
}

.divide-light-blue-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(79, 195, 247, var(--tw-divide-opacity))
}

.divide-light-blue-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(41, 182, 246, var(--tw-divide-opacity))
}

.divide-light-blue-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-divide-opacity))
}

.divide-light-blue-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(3, 155, 229, var(--tw-divide-opacity))
}

.divide-light-blue-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(2, 136, 209, var(--tw-divide-opacity))
}

.divide-light-blue-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(2, 119, 189, var(--tw-divide-opacity))
}

.divide-light-blue-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(1, 87, 155, var(--tw-divide-opacity))
}

.divide-light-blue > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-divide-opacity))
}

.divide-light-blue-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(128, 216, 255, var(--tw-divide-opacity))
}

.divide-light-blue-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(64, 196, 255, var(--tw-divide-opacity))
}

.divide-light-blue-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 176, 255, var(--tw-divide-opacity))
}

.divide-light-blue-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 145, 234, var(--tw-divide-opacity))
}

.divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(232, 234, 246, var(--tw-divide-opacity))
}

.divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(197, 202, 233, var(--tw-divide-opacity))
}

.divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(159, 168, 218, var(--tw-divide-opacity))
}

.divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(121, 134, 203, var(--tw-divide-opacity))
}

.divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(92, 107, 192, var(--tw-divide-opacity))
}

.divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-divide-opacity))
}

.divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(57, 73, 171, var(--tw-divide-opacity))
}

.divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(48, 63, 159, var(--tw-divide-opacity))
}

.divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(40, 53, 147, var(--tw-divide-opacity))
}

.divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(26, 35, 126, var(--tw-divide-opacity))
}

.divide-indigo > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-divide-opacity))
}

.divide-indigo-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(140, 158, 255, var(--tw-divide-opacity))
}

.divide-indigo-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(83, 109, 254, var(--tw-divide-opacity))
}

.divide-indigo-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(61, 90, 254, var(--tw-divide-opacity))
}

.divide-indigo-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(48, 79, 254, var(--tw-divide-opacity))
}

.divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(243, 229, 245, var(--tw-divide-opacity))
}

.divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(225, 190, 231, var(--tw-divide-opacity))
}

.divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(206, 147, 216, var(--tw-divide-opacity))
}

.divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(186, 104, 200, var(--tw-divide-opacity))
}

.divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(171, 71, 188, var(--tw-divide-opacity))
}

.divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-divide-opacity))
}

.divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(142, 36, 170, var(--tw-divide-opacity))
}

.divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(123, 31, 162, var(--tw-divide-opacity))
}

.divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(106, 27, 154, var(--tw-divide-opacity))
}

.divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(74, 20, 140, var(--tw-divide-opacity))
}

.divide-purple > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-divide-opacity))
}

.divide-purple-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(234, 128, 252, var(--tw-divide-opacity))
}

.divide-purple-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(224, 64, 251, var(--tw-divide-opacity))
}

.divide-purple-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(213, 0, 249, var(--tw-divide-opacity))
}

.divide-purple-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(170, 0, 255, var(--tw-divide-opacity))
}

.divide-deep-purple-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(237, 231, 246, var(--tw-divide-opacity))
}

.divide-deep-purple-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(209, 196, 233, var(--tw-divide-opacity))
}

.divide-deep-purple-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(179, 157, 219, var(--tw-divide-opacity))
}

.divide-deep-purple-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(149, 117, 205, var(--tw-divide-opacity))
}

.divide-deep-purple-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(126, 87, 194, var(--tw-divide-opacity))
}

.divide-deep-purple-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-divide-opacity))
}

.divide-deep-purple-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(94, 53, 177, var(--tw-divide-opacity))
}

.divide-deep-purple-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(81, 45, 168, var(--tw-divide-opacity))
}

.divide-deep-purple-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(69, 39, 160, var(--tw-divide-opacity))
}

.divide-deep-purple-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(49, 27, 146, var(--tw-divide-opacity))
}

.divide-deep-purple > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-divide-opacity))
}

.divide-deep-purple-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(179, 136, 255, var(--tw-divide-opacity))
}

.divide-deep-purple-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(124, 77, 255, var(--tw-divide-opacity))
}

.divide-deep-purple-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(101, 31, 255, var(--tw-divide-opacity))
}

.divide-deep-purple-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(98, 0, 234, var(--tw-divide-opacity))
}

.divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(252, 228, 236, var(--tw-divide-opacity))
}

.divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(248, 187, 208, var(--tw-divide-opacity))
}

.divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(244, 143, 177, var(--tw-divide-opacity))
}

.divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(240, 98, 146, var(--tw-divide-opacity))
}

.divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(236, 64, 122, var(--tw-divide-opacity))
}

.divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-divide-opacity))
}

.divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(216, 27, 96, var(--tw-divide-opacity))
}

.divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(194, 24, 91, var(--tw-divide-opacity))
}

.divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(173, 20, 87, var(--tw-divide-opacity))
}

.divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(136, 14, 79, var(--tw-divide-opacity))
}

.divide-pink > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-divide-opacity))
}

.divide-pink-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 128, 171, var(--tw-divide-opacity))
}

.divide-pink-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 64, 129, var(--tw-divide-opacity))
}

.divide-pink-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 0, 87, var(--tw-divide-opacity))
}

.divide-pink-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(197, 17, 98, var(--tw-divide-opacity))
}

.divide-lime-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(249, 251, 231, var(--tw-divide-opacity))
}

.divide-lime-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(240, 244, 195, var(--tw-divide-opacity))
}

.divide-lime-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(230, 238, 156, var(--tw-divide-opacity))
}

.divide-lime-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(220, 231, 117, var(--tw-divide-opacity))
}

.divide-lime-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(212, 225, 87, var(--tw-divide-opacity))
}

.divide-lime-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-divide-opacity))
}

.divide-lime-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(192, 202, 51, var(--tw-divide-opacity))
}

.divide-lime-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(175, 180, 43, var(--tw-divide-opacity))
}

.divide-lime-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(158, 157, 36, var(--tw-divide-opacity))
}

.divide-lime-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(130, 119, 23, var(--tw-divide-opacity))
}

.divide-lime > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-divide-opacity))
}

.divide-lime-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(244, 255, 129, var(--tw-divide-opacity))
}

.divide-lime-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(238, 255, 65, var(--tw-divide-opacity))
}

.divide-lime-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(198, 255, 0, var(--tw-divide-opacity))
}

.divide-lime-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(174, 234, 0, var(--tw-divide-opacity))
}

.divide-amber-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 248, 225, var(--tw-divide-opacity))
}

.divide-amber-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 236, 179, var(--tw-divide-opacity))
}

.divide-amber-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 224, 130, var(--tw-divide-opacity))
}

.divide-amber-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 213, 79, var(--tw-divide-opacity))
}

.divide-amber-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 202, 40, var(--tw-divide-opacity))
}

.divide-amber-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-divide-opacity))
}

.divide-amber-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 179, 0, var(--tw-divide-opacity))
}

.divide-amber-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 160, 0, var(--tw-divide-opacity))
}

.divide-amber-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 143, 0, var(--tw-divide-opacity))
}

.divide-amber-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 111, 0, var(--tw-divide-opacity))
}

.divide-amber > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-divide-opacity))
}

.divide-amber-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 229, 127, var(--tw-divide-opacity))
}

.divide-amber-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 215, 64, var(--tw-divide-opacity))
}

.divide-amber-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 196, 0, var(--tw-divide-opacity))
}

.divide-amber-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 171, 0, var(--tw-divide-opacity))
}

.divide-brown-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(239, 235, 233, var(--tw-divide-opacity))
}

.divide-brown-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(215, 204, 200, var(--tw-divide-opacity))
}

.divide-brown-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(188, 170, 164, var(--tw-divide-opacity))
}

.divide-brown-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(161, 136, 127, var(--tw-divide-opacity))
}

.divide-brown-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(141, 110, 99, var(--tw-divide-opacity))
}

.divide-brown-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-divide-opacity))
}

.divide-brown-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(109, 76, 65, var(--tw-divide-opacity))
}

.divide-brown-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(93, 64, 55, var(--tw-divide-opacity))
}

.divide-brown-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(78, 52, 46, var(--tw-divide-opacity))
}

.divide-brown-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(62, 39, 35, var(--tw-divide-opacity))
}

.divide-brown > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-divide-opacity))
}

.divide-brown-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(215, 204, 200, var(--tw-divide-opacity))
}

.divide-brown-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(188, 170, 164, var(--tw-divide-opacity))
}

.divide-brown-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(141, 110, 99, var(--tw-divide-opacity))
}

.divide-brown-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(93, 64, 55, var(--tw-divide-opacity))
}

.divide-blue-gray-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(236, 239, 241, var(--tw-divide-opacity))
}

.divide-blue-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(207, 216, 220, var(--tw-divide-opacity))
}

.divide-blue-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(176, 190, 197, var(--tw-divide-opacity))
}

.divide-blue-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(144, 164, 174, var(--tw-divide-opacity))
}

.divide-blue-gray-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-divide-opacity))
}

.divide-blue-gray-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-divide-opacity))
}

.divide-blue-gray-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(84, 110, 122, var(--tw-divide-opacity))
}

.divide-blue-gray-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(69, 90, 100, var(--tw-divide-opacity))
}

.divide-blue-gray-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 71, 79, var(--tw-divide-opacity))
}

.divide-blue-gray-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(38, 50, 56, var(--tw-divide-opacity))
}

.divide-blue-gray > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-divide-opacity))
}

.divide-blue-gray-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(207, 216, 220, var(--tw-divide-opacity))
}

.divide-blue-gray-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(176, 190, 197, var(--tw-divide-opacity))
}

.divide-blue-gray-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-divide-opacity))
}

.divide-blue-gray-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(69, 90, 100, var(--tw-divide-opacity))
}

.divide-cyan-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(224, 247, 250, var(--tw-divide-opacity))
}

.divide-cyan-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(178, 235, 242, var(--tw-divide-opacity))
}

.divide-cyan-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(128, 222, 234, var(--tw-divide-opacity))
}

.divide-cyan-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(77, 208, 225, var(--tw-divide-opacity))
}

.divide-cyan-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(38, 198, 218, var(--tw-divide-opacity))
}

.divide-cyan-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-divide-opacity))
}

.divide-cyan-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 172, 193, var(--tw-divide-opacity))
}

.divide-cyan-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 151, 167, var(--tw-divide-opacity))
}

.divide-cyan-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 131, 143, var(--tw-divide-opacity))
}

.divide-cyan-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 96, 100, var(--tw-divide-opacity))
}

.divide-cyan > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-divide-opacity))
}

.divide-cyan-A100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(132, 255, 255, var(--tw-divide-opacity))
}

.divide-cyan-A200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(24, 255, 255, var(--tw-divide-opacity))
}

.divide-cyan-A400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 229, 255, var(--tw-divide-opacity))
}

.divide-cyan-A700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 184, 212, var(--tw-divide-opacity))
}

.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid
}

.divide-dashed > :not([hidden]) ~ :not([hidden]) {
  border-style: dashed
}

.divide-dotted > :not([hidden]) ~ :not([hidden]) {
  border-style: dotted
}

.divide-double > :not([hidden]) ~ :not([hidden]) {
  border-style: double
}

.divide-none > :not([hidden]) ~ :not([hidden]) {
  border-style: none
}

.divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0
}

.divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.05
}

.divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.1
}

.divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.2
}

.divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.25
}

.divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.3
}

.divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.4
}

.divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.5
}

.divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.6
}

.divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.7
}

.divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.75
}

.divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.8
}

.divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.9
}

.divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.95
}

.divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.focus-within\:sr-only:focus-within {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.focus-within\:not-sr-only:focus-within {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.focus\:sr-only:focus {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.focus\:not-sr-only:focus {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.appearance-none {
  -webkit-appearance: none;
          appearance: none
}

.bg-fixed {
  background-attachment: fixed
}

.bg-local {
  background-attachment: local
}

.bg-scroll {
  background-attachment: scroll
}

.bg-clip-border {
  background-clip: border-box
}

.bg-clip-padding {
  background-clip: padding-box
}

.bg-clip-content {
  background-clip: content-box
}

.bg-clip-text {
  -webkit-background-clip: text;
          background-clip: text
}

.bg-transparent {
  background-color: transparent
}

.bg-current {
  background-color: currentColor
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.bg-grey-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
}

.bg-grey-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
}

.bg-grey-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
}

.bg-grey-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
}

.bg-grey-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
}

.bg-grey-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.bg-grey-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
}

.bg-grey-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.bg-grey-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
}

.bg-grey-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
}

.bg-grey {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.bg-grey-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
}

.bg-grey-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
}

.bg-grey-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
}

.bg-grey-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
}

.bg-gray {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.bg-gray-hover {
  background-color: rgba(0, 0, 0, 0.04)
}

.bg-gray-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
}

.bg-gray-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
}

.bg-gray-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
}

.bg-gray-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
}

.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
}

.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
}

.bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
}

.bg-red-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
}

.bg-red-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
}

.bg-red-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
}

.bg-red-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
}

.bg-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
}

.bg-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
}

.bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
}

.bg-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
}

.bg-orange-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
}

.bg-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
}

.bg-orange {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
}

.bg-orange-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
}

.bg-orange-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
}

.bg-orange-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
}

.bg-orange-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
}

.bg-deep-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
}

.bg-deep-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
}

.bg-deep-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
}

.bg-deep-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
}

.bg-deep-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
}

.bg-deep-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
}

.bg-deep-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
}

.bg-deep-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
}

.bg-deep-orange-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
}

.bg-deep-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
}

.bg-deep-orange {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
}

.bg-deep-orange-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
}

.bg-deep-orange-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
}

.bg-deep-orange-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
}

.bg-deep-orange-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
}

.bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
}

.bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
}

.bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
}

.bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
}

.bg-yellow-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
}

.bg-yellow-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
}

.bg-yellow-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
}

.bg-yellow-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
}

.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
}

.bg-green-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
}

.bg-green-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
}

.bg-green-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
}

.bg-green-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
}

.bg-light-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
}

.bg-light-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
}

.bg-light-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
}

.bg-light-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
}

.bg-light-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
}

.bg-light-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
}

.bg-light-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
}

.bg-light-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
}

.bg-light-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
}

.bg-light-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
}

.bg-light-green {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
}

.bg-light-green-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
}

.bg-light-green-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
}

.bg-light-green-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
}

.bg-light-green-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
}

.bg-teal-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
}

.bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
}

.bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
}

.bg-teal-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
}

.bg-teal-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
}

.bg-teal-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
}

.bg-teal-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
}

.bg-teal-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
}

.bg-teal-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
}

.bg-teal {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
}

.bg-teal-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
}

.bg-teal-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
}

.bg-teal-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
}

.bg-teal-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
}

.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
}

.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
}

.bg-blue {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
}

.bg-blue-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
}

.bg-blue-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
}

.bg-blue-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
}

.bg-blue-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
}

.bg-light-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
}

.bg-light-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
}

.bg-light-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
}

.bg-light-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
}

.bg-light-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
}

.bg-light-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
}

.bg-light-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
}

.bg-light-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
}

.bg-light-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
}

.bg-light-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
}

.bg-light-blue {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
}

.bg-light-blue-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
}

.bg-light-blue-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
}

.bg-light-blue-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
}

.bg-light-blue-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
}

.bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
}

.bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
}

.bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
}

.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
}

.bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
}

.bg-indigo {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
}

.bg-indigo-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
}

.bg-indigo-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
}

.bg-indigo-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
}

.bg-indigo-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
}

.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
}

.bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
}

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
}

.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
}

.bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
}

.bg-purple {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
}

.bg-purple-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
}

.bg-purple-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
}

.bg-purple-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
}

.bg-purple-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
}

.bg-deep-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
}

.bg-deep-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
}

.bg-deep-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
}

.bg-deep-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
}

.bg-deep-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
}

.bg-deep-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
}

.bg-deep-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
}

.bg-deep-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
}

.bg-deep-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
}

.bg-deep-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
}

.bg-deep-purple {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
}

.bg-deep-purple-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
}

.bg-deep-purple-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
}

.bg-deep-purple-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
}

.bg-deep-purple-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
}

.bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
}

.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
}

.bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
}

.bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
}

.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
}

.bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
}

.bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
}

.bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
}

.bg-pink {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
}

.bg-pink-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
}

.bg-pink-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
}

.bg-pink-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
}

.bg-pink-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
}

.bg-lime-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
}

.bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
}

.bg-lime-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
}

.bg-lime-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
}

.bg-lime-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
}

.bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
}

.bg-lime-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
}

.bg-lime-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
}

.bg-lime-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
}

.bg-lime-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
}

.bg-lime {
  --tw-bg-opacity: 1;
  background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
}

.bg-lime-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
}

.bg-lime-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
}

.bg-lime-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
}

.bg-lime-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
}

.bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
}

.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
}

.bg-amber-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
}

.bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
}

.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
}

.bg-amber-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
}

.bg-amber-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
}

.bg-amber-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
}

.bg-amber {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
}

.bg-amber-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
}

.bg-amber-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
}

.bg-amber-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
}

.bg-amber-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
}

.bg-brown-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
}

.bg-brown-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
}

.bg-brown-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
}

.bg-brown-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
}

.bg-brown-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
}

.bg-brown-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
}

.bg-brown-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
}

.bg-brown-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
}

.bg-brown-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
}

.bg-brown-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
}

.bg-brown {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
}

.bg-brown-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
}

.bg-brown-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
}

.bg-brown-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
}

.bg-brown-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
}

.bg-blue-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
}

.bg-blue-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
}

.bg-blue-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
}

.bg-blue-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
}

.bg-blue-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
}

.bg-blue-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
}

.bg-blue-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
}

.bg-blue-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
}

.bg-blue-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
}

.bg-blue-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
}

.bg-blue-gray {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
}

.bg-blue-gray-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
}

.bg-blue-gray-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
}

.bg-blue-gray-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
}

.bg-blue-gray-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
}

.bg-cyan-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
}

.bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
}

.bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
}

.bg-cyan-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
}

.bg-cyan-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
}

.bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
}

.bg-cyan-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
}

.bg-cyan-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
}

.bg-cyan-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
}

.bg-cyan-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
}

.bg-cyan {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
}

.bg-cyan-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
}

.bg-cyan-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
}

.bg-cyan-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
}

.bg-cyan-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-transparent {
  background-color: transparent
}

.group:hover .group-hover\:bg-current {
  background-color: currentColor
}

.group:hover .group-hover\:bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-grey-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-grey-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-grey-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-grey-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-grey-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-grey-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-grey-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-grey-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-grey-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-grey-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-grey {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-grey-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-grey-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-grey-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-grey-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray-hover {
  background-color: rgba(0, 0, 0, 0.04)
}

.group:hover .group-hover\:bg-gray-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-orange-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-orange {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-orange-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-orange-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-orange-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-orange-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-orange-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-orange {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-orange-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-orange-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-orange-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-orange-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-yellow-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-yellow-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-yellow-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-yellow-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-green {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-green-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-green-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-green-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-green-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-teal-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-teal-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-teal-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-teal-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-teal-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-teal-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-teal-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-teal {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-teal-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-teal-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-teal-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-teal-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-blue {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-blue-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-blue-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-blue-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-light-blue-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-indigo {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-indigo-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-indigo-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-indigo-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-indigo-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-purple {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-purple-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-purple-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-purple-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-purple-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-purple {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-purple-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-purple-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-purple-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-deep-purple-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-pink {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-pink-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-pink-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-pink-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-pink-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lime-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lime-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lime-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lime-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lime-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lime-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lime-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lime-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lime {
  --tw-bg-opacity: 1;
  background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lime-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lime-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lime-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lime-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-amber-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-amber-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-amber-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-amber-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-amber {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-amber-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-amber-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-amber-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-amber-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-brown-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-brown-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-brown-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-brown-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-brown-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-brown-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-brown-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-brown-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-brown-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-brown-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-brown {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-brown-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-brown-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-brown-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-brown-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-gray {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-gray-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-gray-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-gray-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-gray-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan-A100 {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan-A200 {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan-A400 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan-A700 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
}

.focus-within\:bg-transparent:focus-within {
  background-color: transparent
}

.focus-within\:bg-current:focus-within {
  background-color: currentColor
}

.focus-within\:bg-black:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
}

.focus-within\:bg-white:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-grey-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
}

.focus-within\:bg-grey-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
}

.focus-within\:bg-grey-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
}

.focus-within\:bg-grey-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
}

.focus-within\:bg-grey-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
}

.focus-within\:bg-grey-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.focus-within\:bg-grey-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
}

.focus-within\:bg-grey-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.focus-within\:bg-grey-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
}

.focus-within\:bg-grey-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
}

.focus-within\:bg-grey:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.focus-within\:bg-grey-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
}

.focus-within\:bg-grey-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
}

.focus-within\:bg-grey-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
}

.focus-within\:bg-grey-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.focus-within\:bg-gray-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
}

.focus-within\:bg-gray-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
}

.focus-within\:bg-gray-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
}

.focus-within\:bg-gray-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
}

.focus-within\:bg-gray-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
}

.focus-within\:bg-gray-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.focus-within\:bg-gray-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
}

.focus-within\:bg-gray-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.focus-within\:bg-gray-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
}

.focus-within\:bg-gray-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
}

.focus-within\:bg-gray:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.focus-within\:bg-gray-hover:focus-within {
  background-color: rgba(0, 0, 0, 0.04)
}

.focus-within\:bg-gray-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
}

.focus-within\:bg-gray-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
}

.focus-within\:bg-gray-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
}

.focus-within\:bg-gray-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.focus-within\:bg-red-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
}

.focus-within\:bg-red-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
}

.focus-within\:bg-red-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
}

.focus-within\:bg-red-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
}

.focus-within\:bg-red-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
}

.focus-within\:bg-red-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
}

.focus-within\:bg-red-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
}

.focus-within\:bg-red-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
}

.focus-within\:bg-red-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
}

.focus-within\:bg-red-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
}

.focus-within\:bg-red:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
}

.focus-within\:bg-red-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
}

.focus-within\:bg-red-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
}

.focus-within\:bg-red-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
}

.focus-within\:bg-red-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-orange-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
}

.focus-within\:bg-orange-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
}

.focus-within\:bg-orange-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
}

.focus-within\:bg-orange-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
}

.focus-within\:bg-orange-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
}

.focus-within\:bg-orange-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-orange-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-orange-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-orange-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-orange-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-orange:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-orange-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
}

.focus-within\:bg-orange-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
}

.focus-within\:bg-orange-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-orange-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-orange-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-orange-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-orange-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-orange-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-orange-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-orange-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-orange-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-orange-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-orange-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-orange-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-orange:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-orange-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-orange-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-orange-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-orange-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-yellow-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
}

.focus-within\:bg-yellow-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
}

.focus-within\:bg-yellow-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
}

.focus-within\:bg-yellow-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
}

.focus-within\:bg-yellow-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
}

.focus-within\:bg-yellow-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
}

.focus-within\:bg-yellow-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
}

.focus-within\:bg-yellow-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
}

.focus-within\:bg-yellow-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
}

.focus-within\:bg-yellow-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
}

.focus-within\:bg-yellow:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
}

.focus-within\:bg-yellow-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
}

.focus-within\:bg-yellow-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-yellow-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-yellow-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-green-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
}

.focus-within\:bg-green-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
}

.focus-within\:bg-green-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
}

.focus-within\:bg-green-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
}

.focus-within\:bg-green-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
}

.focus-within\:bg-green-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
}

.focus-within\:bg-green-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
}

.focus-within\:bg-green-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
}

.focus-within\:bg-green-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
}

.focus-within\:bg-green-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
}

.focus-within\:bg-green:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
}

.focus-within\:bg-green-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
}

.focus-within\:bg-green-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
}

.focus-within\:bg-green-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
}

.focus-within\:bg-green-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
}

.focus-within\:bg-light-green-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
}

.focus-within\:bg-light-green-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
}

.focus-within\:bg-light-green-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
}

.focus-within\:bg-light-green-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
}

.focus-within\:bg-light-green-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
}

.focus-within\:bg-light-green-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
}

.focus-within\:bg-light-green-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
}

.focus-within\:bg-light-green-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
}

.focus-within\:bg-light-green-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
}

.focus-within\:bg-light-green-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
}

.focus-within\:bg-light-green:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
}

.focus-within\:bg-light-green-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
}

.focus-within\:bg-light-green-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
}

.focus-within\:bg-light-green-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
}

.focus-within\:bg-light-green-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
}

.focus-within\:bg-teal-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
}

.focus-within\:bg-teal-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
}

.focus-within\:bg-teal-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
}

.focus-within\:bg-teal-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
}

.focus-within\:bg-teal-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
}

.focus-within\:bg-teal-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
}

.focus-within\:bg-teal-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
}

.focus-within\:bg-teal-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
}

.focus-within\:bg-teal-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
}

.focus-within\:bg-teal-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
}

.focus-within\:bg-teal:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
}

.focus-within\:bg-teal-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
}

.focus-within\:bg-teal-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
}

.focus-within\:bg-teal-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
}

.focus-within\:bg-teal-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
}

.focus-within\:bg-blue:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-light-blue-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
}

.focus-within\:bg-light-blue-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
}

.focus-within\:bg-light-blue-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
}

.focus-within\:bg-light-blue-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
}

.focus-within\:bg-light-blue-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
}

.focus-within\:bg-light-blue-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
}

.focus-within\:bg-light-blue-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
}

.focus-within\:bg-light-blue-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
}

.focus-within\:bg-light-blue-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
}

.focus-within\:bg-light-blue-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
}

.focus-within\:bg-light-blue:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
}

.focus-within\:bg-light-blue-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-light-blue-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-light-blue-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-light-blue-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
}

.focus-within\:bg-indigo-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
}

.focus-within\:bg-indigo-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
}

.focus-within\:bg-indigo-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
}

.focus-within\:bg-indigo-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
}

.focus-within\:bg-indigo-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
}

.focus-within\:bg-indigo-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
}

.focus-within\:bg-indigo-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
}

.focus-within\:bg-indigo-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
}

.focus-within\:bg-indigo-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
}

.focus-within\:bg-indigo-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
}

.focus-within\:bg-indigo:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
}

.focus-within\:bg-indigo-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-indigo-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
}

.focus-within\:bg-indigo-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
}

.focus-within\:bg-indigo-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
}

.focus-within\:bg-purple-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
}

.focus-within\:bg-purple-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
}

.focus-within\:bg-purple-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
}

.focus-within\:bg-purple-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
}

.focus-within\:bg-purple-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
}

.focus-within\:bg-purple-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
}

.focus-within\:bg-purple-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
}

.focus-within\:bg-purple-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
}

.focus-within\:bg-purple-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
}

.focus-within\:bg-purple-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
}

.focus-within\:bg-purple:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
}

.focus-within\:bg-purple-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
}

.focus-within\:bg-purple-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
}

.focus-within\:bg-purple-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
}

.focus-within\:bg-purple-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-purple-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-purple-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-purple-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-purple-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-purple-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-purple-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-purple-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-purple-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-purple-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-purple-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-purple:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-purple-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-purple-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-purple-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-deep-purple-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
}

.focus-within\:bg-pink-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
}

.focus-within\:bg-pink-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
}

.focus-within\:bg-pink-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
}

.focus-within\:bg-pink-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
}

.focus-within\:bg-pink-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
}

.focus-within\:bg-pink-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
}

.focus-within\:bg-pink-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
}

.focus-within\:bg-pink-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
}

.focus-within\:bg-pink-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
}

.focus-within\:bg-pink-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
}

.focus-within\:bg-pink:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
}

.focus-within\:bg-pink-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
}

.focus-within\:bg-pink-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
}

.focus-within\:bg-pink-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
}

.focus-within\:bg-pink-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
}

.focus-within\:bg-lime-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
}

.focus-within\:bg-lime-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
}

.focus-within\:bg-lime-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
}

.focus-within\:bg-lime-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
}

.focus-within\:bg-lime-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
}

.focus-within\:bg-lime-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
}

.focus-within\:bg-lime-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
}

.focus-within\:bg-lime-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
}

.focus-within\:bg-lime-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
}

.focus-within\:bg-lime-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
}

.focus-within\:bg-lime:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
}

.focus-within\:bg-lime-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
}

.focus-within\:bg-lime-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
}

.focus-within\:bg-lime-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-lime-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-amber-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
}

.focus-within\:bg-amber-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
}

.focus-within\:bg-amber-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
}

.focus-within\:bg-amber-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
}

.focus-within\:bg-amber-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
}

.focus-within\:bg-amber-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
}

.focus-within\:bg-amber-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-amber-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-amber-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-amber-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-amber:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
}

.focus-within\:bg-amber-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
}

.focus-within\:bg-amber-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
}

.focus-within\:bg-amber-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-amber-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
}

.focus-within\:bg-brown-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
}

.focus-within\:bg-brown-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
}

.focus-within\:bg-brown-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
}

.focus-within\:bg-brown-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
}

.focus-within\:bg-brown-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
}

.focus-within\:bg-brown-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
}

.focus-within\:bg-brown-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
}

.focus-within\:bg-brown-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
}

.focus-within\:bg-brown-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
}

.focus-within\:bg-brown-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
}

.focus-within\:bg-brown:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
}

.focus-within\:bg-brown-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
}

.focus-within\:bg-brown-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
}

.focus-within\:bg-brown-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
}

.focus-within\:bg-brown-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-gray-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-gray-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-gray-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-gray-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-gray-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-gray-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-gray-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-gray-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-gray-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-gray-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-gray:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-gray-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-gray-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-gray-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
}

.focus-within\:bg-blue-gray-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
}

.focus-within\:bg-cyan-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
}

.focus-within\:bg-cyan-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
}

.focus-within\:bg-cyan-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
}

.focus-within\:bg-cyan-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
}

.focus-within\:bg-cyan-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
}

.focus-within\:bg-cyan-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
}

.focus-within\:bg-cyan-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
}

.focus-within\:bg-cyan-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
}

.focus-within\:bg-cyan-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
}

.focus-within\:bg-cyan-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
}

.focus-within\:bg-cyan:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
}

.focus-within\:bg-cyan-A100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-cyan-A200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-cyan-A400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
}

.focus-within\:bg-cyan-A700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.hover\:bg-current:hover {
  background-color: currentColor
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.hover\:bg-grey-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
}

.hover\:bg-grey-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
}

.hover\:bg-grey-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
}

.hover\:bg-grey-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
}

.hover\:bg-grey-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
}

.hover\:bg-grey-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.hover\:bg-grey-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
}

.hover\:bg-grey-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.hover\:bg-grey-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
}

.hover\:bg-grey-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
}

.hover\:bg-grey:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.hover\:bg-grey-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
}

.hover\:bg-grey-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
}

.hover\:bg-grey-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
}

.hover\:bg-grey-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
}

.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
}

.hover\:bg-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
}

.hover\:bg-gray:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.hover\:bg-gray-hover:hover {
  background-color: rgba(0, 0, 0, 0.04)
}

.hover\:bg-gray-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
}

.hover\:bg-gray-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
}

.hover\:bg-gray-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
}

.hover\:bg-gray-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.hover\:bg-red-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
}

.hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
}

.hover\:bg-red-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
}

.hover\:bg-red-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
}

.hover\:bg-red-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
}

.hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
}

.hover\:bg-red-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
}

.hover\:bg-red-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
}

.hover\:bg-red:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
}

.hover\:bg-red-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
}

.hover\:bg-red-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
}

.hover\:bg-red-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
}

.hover\:bg-red-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
}

.hover\:bg-orange-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
}

.hover\:bg-orange-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
}

.hover\:bg-orange-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
}

.hover\:bg-orange-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
}

.hover\:bg-orange-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
}

.hover\:bg-orange-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
}

.hover\:bg-orange-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
}

.hover\:bg-orange-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
}

.hover\:bg-orange-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
}

.hover\:bg-orange-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
}

.hover\:bg-orange:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
}

.hover\:bg-orange-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
}

.hover\:bg-orange-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
}

.hover\:bg-orange-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
}

.hover\:bg-orange-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
}

.hover\:bg-deep-orange-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
}

.hover\:bg-deep-orange-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
}

.hover\:bg-deep-orange-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
}

.hover\:bg-deep-orange-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
}

.hover\:bg-deep-orange-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
}

.hover\:bg-deep-orange-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
}

.hover\:bg-deep-orange-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
}

.hover\:bg-deep-orange-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
}

.hover\:bg-deep-orange-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
}

.hover\:bg-deep-orange-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
}

.hover\:bg-deep-orange:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
}

.hover\:bg-deep-orange-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
}

.hover\:bg-deep-orange-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
}

.hover\:bg-deep-orange-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
}

.hover\:bg-deep-orange-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
}

.hover\:bg-yellow-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
}

.hover\:bg-yellow-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
}

.hover\:bg-yellow-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
}

.hover\:bg-yellow-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
}

.hover\:bg-yellow-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
}

.hover\:bg-yellow-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
}

.hover\:bg-yellow-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
}

.hover\:bg-yellow-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
}

.hover\:bg-yellow-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
}

.hover\:bg-yellow-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
}

.hover\:bg-yellow:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
}

.hover\:bg-yellow-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
}

.hover\:bg-yellow-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
}

.hover\:bg-yellow-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
}

.hover\:bg-yellow-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
}

.hover\:bg-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
}

.hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
}

.hover\:bg-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
}

.hover\:bg-green-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
}

.hover\:bg-green-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
}

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
}

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
}

.hover\:bg-green-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
}

.hover\:bg-green-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
}

.hover\:bg-green:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
}

.hover\:bg-green-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
}

.hover\:bg-green-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
}

.hover\:bg-green-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
}

.hover\:bg-green-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
}

.hover\:bg-light-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
}

.hover\:bg-light-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
}

.hover\:bg-light-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
}

.hover\:bg-light-green-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
}

.hover\:bg-light-green-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
}

.hover\:bg-light-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
}

.hover\:bg-light-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
}

.hover\:bg-light-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
}

.hover\:bg-light-green-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
}

.hover\:bg-light-green-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
}

.hover\:bg-light-green:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
}

.hover\:bg-light-green-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
}

.hover\:bg-light-green-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
}

.hover\:bg-light-green-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
}

.hover\:bg-light-green-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
}

.hover\:bg-teal-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
}

.hover\:bg-teal-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
}

.hover\:bg-teal-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
}

.hover\:bg-teal-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
}

.hover\:bg-teal-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
}

.hover\:bg-teal-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
}

.hover\:bg-teal-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
}

.hover\:bg-teal-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
}

.hover\:bg-teal-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
}

.hover\:bg-teal-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
}

.hover\:bg-teal:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
}

.hover\:bg-teal-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
}

.hover\:bg-teal-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
}

.hover\:bg-teal-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
}

.hover\:bg-teal-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
}

.hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
}

.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
}

.hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
}

.hover\:bg-blue-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
}

.hover\:bg-blue-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
}

.hover\:bg-blue-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
}

.hover\:bg-blue:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
}

.hover\:bg-blue-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
}

.hover\:bg-blue-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
}

.hover\:bg-blue-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
}

.hover\:bg-blue-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
}

.hover\:bg-light-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
}

.hover\:bg-light-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
}

.hover\:bg-light-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
}

.hover\:bg-light-blue-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
}

.hover\:bg-light-blue-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
}

.hover\:bg-light-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
}

.hover\:bg-light-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
}

.hover\:bg-light-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
}

.hover\:bg-light-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
}

.hover\:bg-light-blue-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
}

.hover\:bg-light-blue:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
}

.hover\:bg-light-blue-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
}

.hover\:bg-light-blue-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
}

.hover\:bg-light-blue-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
}

.hover\:bg-light-blue-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
}

.hover\:bg-indigo-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
}

.hover\:bg-indigo-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
}

.hover\:bg-indigo-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
}

.hover\:bg-indigo-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
}

.hover\:bg-indigo-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
}

.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
}

.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
}

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
}

.hover\:bg-indigo-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
}

.hover\:bg-indigo-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
}

.hover\:bg-indigo:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
}

.hover\:bg-indigo-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
}

.hover\:bg-indigo-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
}

.hover\:bg-indigo-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
}

.hover\:bg-indigo-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
}

.hover\:bg-purple-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
}

.hover\:bg-purple-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
}

.hover\:bg-purple-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
}

.hover\:bg-purple-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
}

.hover\:bg-purple-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
}

.hover\:bg-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
}

.hover\:bg-purple-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
}

.hover\:bg-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
}

.hover\:bg-purple-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
}

.hover\:bg-purple-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
}

.hover\:bg-purple:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
}

.hover\:bg-purple-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
}

.hover\:bg-purple-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
}

.hover\:bg-purple-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
}

.hover\:bg-purple-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
}

.hover\:bg-deep-purple-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
}

.hover\:bg-deep-purple-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
}

.hover\:bg-deep-purple-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
}

.hover\:bg-deep-purple-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
}

.hover\:bg-deep-purple-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
}

.hover\:bg-deep-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
}

.hover\:bg-deep-purple-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
}

.hover\:bg-deep-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
}

.hover\:bg-deep-purple-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
}

.hover\:bg-deep-purple-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
}

.hover\:bg-deep-purple:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
}

.hover\:bg-deep-purple-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
}

.hover\:bg-deep-purple-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
}

.hover\:bg-deep-purple-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
}

.hover\:bg-deep-purple-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
}

.hover\:bg-pink-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
}

.hover\:bg-pink-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
}

.hover\:bg-pink-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
}

.hover\:bg-pink-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
}

.hover\:bg-pink-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
}

.hover\:bg-pink-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
}

.hover\:bg-pink-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
}

.hover\:bg-pink-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
}

.hover\:bg-pink-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
}

.hover\:bg-pink-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
}

.hover\:bg-pink:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
}

.hover\:bg-pink-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
}

.hover\:bg-pink-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
}

.hover\:bg-pink-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
}

.hover\:bg-pink-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
}

.hover\:bg-lime-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
}

.hover\:bg-lime-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
}

.hover\:bg-lime-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
}

.hover\:bg-lime-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
}

.hover\:bg-lime-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
}

.hover\:bg-lime-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
}

.hover\:bg-lime-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
}

.hover\:bg-lime-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
}

.hover\:bg-lime-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
}

.hover\:bg-lime-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
}

.hover\:bg-lime:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
}

.hover\:bg-lime-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
}

.hover\:bg-lime-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
}

.hover\:bg-lime-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
}

.hover\:bg-lime-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
}

.hover\:bg-amber-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
}

.hover\:bg-amber-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
}

.hover\:bg-amber-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
}

.hover\:bg-amber-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
}

.hover\:bg-amber-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
}

.hover\:bg-amber-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
}

.hover\:bg-amber-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
}

.hover\:bg-amber-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
}

.hover\:bg-amber-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
}

.hover\:bg-amber-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
}

.hover\:bg-amber:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
}

.hover\:bg-amber-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
}

.hover\:bg-amber-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
}

.hover\:bg-amber-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
}

.hover\:bg-amber-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
}

.hover\:bg-brown-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
}

.hover\:bg-brown-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
}

.hover\:bg-brown-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
}

.hover\:bg-brown-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
}

.hover\:bg-brown-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
}

.hover\:bg-brown-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
}

.hover\:bg-brown-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
}

.hover\:bg-brown-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
}

.hover\:bg-brown-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
}

.hover\:bg-brown-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
}

.hover\:bg-brown:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
}

.hover\:bg-brown-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
}

.hover\:bg-brown-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
}

.hover\:bg-brown-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
}

.hover\:bg-brown-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
}

.hover\:bg-blue-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
}

.hover\:bg-blue-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
}

.hover\:bg-blue-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
}

.hover\:bg-blue-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
}

.hover\:bg-blue-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
}

.hover\:bg-blue-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
}

.hover\:bg-blue-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
}

.hover\:bg-blue-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
}

.hover\:bg-blue-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
}

.hover\:bg-blue-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
}

.hover\:bg-blue-gray:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
}

.hover\:bg-blue-gray-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
}

.hover\:bg-blue-gray-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
}

.hover\:bg-blue-gray-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
}

.hover\:bg-blue-gray-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
}

.hover\:bg-cyan-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
}

.hover\:bg-cyan-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
}

.hover\:bg-cyan-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
}

.hover\:bg-cyan-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
}

.hover\:bg-cyan-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
}

.hover\:bg-cyan-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
}

.hover\:bg-cyan-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
}

.hover\:bg-cyan-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
}

.hover\:bg-cyan-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
}

.hover\:bg-cyan-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
}

.hover\:bg-cyan:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
}

.hover\:bg-cyan-A100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
}

.hover\:bg-cyan-A200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
}

.hover\:bg-cyan-A400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
}

.hover\:bg-cyan-A700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
}

.focus\:bg-transparent:focus {
  background-color: transparent
}

.focus\:bg-current:focus {
  background-color: currentColor
}

.focus\:bg-black:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.focus\:bg-grey-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
}

.focus\:bg-grey-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
}

.focus\:bg-grey-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
}

.focus\:bg-grey-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
}

.focus\:bg-grey-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
}

.focus\:bg-grey-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.focus\:bg-grey-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
}

.focus\:bg-grey-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.focus\:bg-grey-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
}

.focus\:bg-grey-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
}

.focus\:bg-grey:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.focus\:bg-grey-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
}

.focus\:bg-grey-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
}

.focus\:bg-grey-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
}

.focus\:bg-grey-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.focus\:bg-gray-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
}

.focus\:bg-gray-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
}

.focus\:bg-gray-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
}

.focus\:bg-gray-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
}

.focus\:bg-gray-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
}

.focus\:bg-gray-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.focus\:bg-gray-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
}

.focus\:bg-gray-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.focus\:bg-gray-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
}

.focus\:bg-gray-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
}

.focus\:bg-gray:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
}

.focus\:bg-gray-hover:focus {
  background-color: rgba(0, 0, 0, 0.04)
}

.focus\:bg-gray-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
}

.focus\:bg-gray-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
}

.focus\:bg-gray-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
}

.focus\:bg-gray-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
}

.focus\:bg-red-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
}

.focus\:bg-red-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
}

.focus\:bg-red-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
}

.focus\:bg-red-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
}

.focus\:bg-red-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
}

.focus\:bg-red-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
}

.focus\:bg-red-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
}

.focus\:bg-red-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
}

.focus\:bg-red-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
}

.focus\:bg-red-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
}

.focus\:bg-red:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
}

.focus\:bg-red-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
}

.focus\:bg-red-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
}

.focus\:bg-red-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
}

.focus\:bg-red-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
}

.focus\:bg-orange-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
}

.focus\:bg-orange-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
}

.focus\:bg-orange-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
}

.focus\:bg-orange-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
}

.focus\:bg-orange-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
}

.focus\:bg-orange-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
}

.focus\:bg-orange-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
}

.focus\:bg-orange-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
}

.focus\:bg-orange-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
}

.focus\:bg-orange-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
}

.focus\:bg-orange:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
}

.focus\:bg-orange-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
}

.focus\:bg-orange-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
}

.focus\:bg-orange-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
}

.focus\:bg-orange-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
}

.focus\:bg-deep-orange-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
}

.focus\:bg-deep-orange-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
}

.focus\:bg-deep-orange-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
}

.focus\:bg-deep-orange-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
}

.focus\:bg-deep-orange-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
}

.focus\:bg-deep-orange-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
}

.focus\:bg-deep-orange-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
}

.focus\:bg-deep-orange-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
}

.focus\:bg-deep-orange-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
}

.focus\:bg-deep-orange-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
}

.focus\:bg-deep-orange:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
}

.focus\:bg-deep-orange-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
}

.focus\:bg-deep-orange-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
}

.focus\:bg-deep-orange-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
}

.focus\:bg-deep-orange-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
}

.focus\:bg-yellow-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
}

.focus\:bg-yellow-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
}

.focus\:bg-yellow-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
}

.focus\:bg-yellow-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
}

.focus\:bg-yellow-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
}

.focus\:bg-yellow-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
}

.focus\:bg-yellow-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
}

.focus\:bg-yellow-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
}

.focus\:bg-yellow-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
}

.focus\:bg-yellow-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
}

.focus\:bg-yellow:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
}

.focus\:bg-yellow-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
}

.focus\:bg-yellow-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
}

.focus\:bg-yellow-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
}

.focus\:bg-yellow-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
}

.focus\:bg-green-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
}

.focus\:bg-green-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
}

.focus\:bg-green-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
}

.focus\:bg-green-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
}

.focus\:bg-green-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
}

.focus\:bg-green-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
}

.focus\:bg-green-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
}

.focus\:bg-green-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
}

.focus\:bg-green-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
}

.focus\:bg-green-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
}

.focus\:bg-green:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
}

.focus\:bg-green-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
}

.focus\:bg-green-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
}

.focus\:bg-green-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
}

.focus\:bg-green-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
}

.focus\:bg-light-green-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
}

.focus\:bg-light-green-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
}

.focus\:bg-light-green-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
}

.focus\:bg-light-green-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
}

.focus\:bg-light-green-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
}

.focus\:bg-light-green-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
}

.focus\:bg-light-green-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
}

.focus\:bg-light-green-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
}

.focus\:bg-light-green-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
}

.focus\:bg-light-green-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
}

.focus\:bg-light-green:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
}

.focus\:bg-light-green-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
}

.focus\:bg-light-green-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
}

.focus\:bg-light-green-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
}

.focus\:bg-light-green-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
}

.focus\:bg-teal-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
}

.focus\:bg-teal-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
}

.focus\:bg-teal-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
}

.focus\:bg-teal-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
}

.focus\:bg-teal-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
}

.focus\:bg-teal-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
}

.focus\:bg-teal-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
}

.focus\:bg-teal-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
}

.focus\:bg-teal-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
}

.focus\:bg-teal-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
}

.focus\:bg-teal:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
}

.focus\:bg-teal-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
}

.focus\:bg-teal-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
}

.focus\:bg-teal-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
}

.focus\:bg-teal-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
}

.focus\:bg-blue-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
}

.focus\:bg-blue-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
}

.focus\:bg-blue-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
}

.focus\:bg-blue-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
}

.focus\:bg-blue-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
}

.focus\:bg-blue-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
}

.focus\:bg-blue-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
}

.focus\:bg-blue-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
}

.focus\:bg-blue-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
}

.focus\:bg-blue-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
}

.focus\:bg-blue:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
}

.focus\:bg-blue-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
}

.focus\:bg-blue-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
}

.focus\:bg-blue-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
}

.focus\:bg-blue-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
}

.focus\:bg-light-blue-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
}

.focus\:bg-light-blue-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
}

.focus\:bg-light-blue-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
}

.focus\:bg-light-blue-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
}

.focus\:bg-light-blue-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
}

.focus\:bg-light-blue-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
}

.focus\:bg-light-blue-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
}

.focus\:bg-light-blue-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
}

.focus\:bg-light-blue-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
}

.focus\:bg-light-blue-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
}

.focus\:bg-light-blue:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
}

.focus\:bg-light-blue-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
}

.focus\:bg-light-blue-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
}

.focus\:bg-light-blue-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
}

.focus\:bg-light-blue-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
}

.focus\:bg-indigo-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
}

.focus\:bg-indigo-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
}

.focus\:bg-indigo-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
}

.focus\:bg-indigo-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
}

.focus\:bg-indigo-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
}

.focus\:bg-indigo-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
}

.focus\:bg-indigo-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
}

.focus\:bg-indigo-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
}

.focus\:bg-indigo-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
}

.focus\:bg-indigo-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
}

.focus\:bg-indigo:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
}

.focus\:bg-indigo-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
}

.focus\:bg-indigo-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
}

.focus\:bg-indigo-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
}

.focus\:bg-indigo-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
}

.focus\:bg-purple-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
}

.focus\:bg-purple-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
}

.focus\:bg-purple-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
}

.focus\:bg-purple-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
}

.focus\:bg-purple-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
}

.focus\:bg-purple-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
}

.focus\:bg-purple-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
}

.focus\:bg-purple-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
}

.focus\:bg-purple-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
}

.focus\:bg-purple-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
}

.focus\:bg-purple:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
}

.focus\:bg-purple-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
}

.focus\:bg-purple-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
}

.focus\:bg-purple-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
}

.focus\:bg-purple-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
}

.focus\:bg-deep-purple-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
}

.focus\:bg-deep-purple-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
}

.focus\:bg-deep-purple-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
}

.focus\:bg-deep-purple-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
}

.focus\:bg-deep-purple-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
}

.focus\:bg-deep-purple-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
}

.focus\:bg-deep-purple-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
}

.focus\:bg-deep-purple-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
}

.focus\:bg-deep-purple-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
}

.focus\:bg-deep-purple-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
}

.focus\:bg-deep-purple:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
}

.focus\:bg-deep-purple-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
}

.focus\:bg-deep-purple-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
}

.focus\:bg-deep-purple-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
}

.focus\:bg-deep-purple-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
}

.focus\:bg-pink-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
}

.focus\:bg-pink-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
}

.focus\:bg-pink-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
}

.focus\:bg-pink-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
}

.focus\:bg-pink-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
}

.focus\:bg-pink-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
}

.focus\:bg-pink-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
}

.focus\:bg-pink-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
}

.focus\:bg-pink-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
}

.focus\:bg-pink-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
}

.focus\:bg-pink:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
}

.focus\:bg-pink-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
}

.focus\:bg-pink-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
}

.focus\:bg-pink-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
}

.focus\:bg-pink-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
}

.focus\:bg-lime-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
}

.focus\:bg-lime-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
}

.focus\:bg-lime-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
}

.focus\:bg-lime-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
}

.focus\:bg-lime-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
}

.focus\:bg-lime-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
}

.focus\:bg-lime-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
}

.focus\:bg-lime-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
}

.focus\:bg-lime-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
}

.focus\:bg-lime-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
}

.focus\:bg-lime:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
}

.focus\:bg-lime-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
}

.focus\:bg-lime-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
}

.focus\:bg-lime-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
}

.focus\:bg-lime-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
}

.focus\:bg-amber-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
}

.focus\:bg-amber-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
}

.focus\:bg-amber-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
}

.focus\:bg-amber-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
}

.focus\:bg-amber-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
}

.focus\:bg-amber-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
}

.focus\:bg-amber-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
}

.focus\:bg-amber-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
}

.focus\:bg-amber-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
}

.focus\:bg-amber-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
}

.focus\:bg-amber:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
}

.focus\:bg-amber-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
}

.focus\:bg-amber-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
}

.focus\:bg-amber-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
}

.focus\:bg-amber-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
}

.focus\:bg-brown-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
}

.focus\:bg-brown-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
}

.focus\:bg-brown-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
}

.focus\:bg-brown-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
}

.focus\:bg-brown-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
}

.focus\:bg-brown-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
}

.focus\:bg-brown-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
}

.focus\:bg-brown-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
}

.focus\:bg-brown-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
}

.focus\:bg-brown-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
}

.focus\:bg-brown:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
}

.focus\:bg-brown-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
}

.focus\:bg-brown-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
}

.focus\:bg-brown-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
}

.focus\:bg-brown-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
}

.focus\:bg-blue-gray-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
}

.focus\:bg-blue-gray-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
}

.focus\:bg-blue-gray-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
}

.focus\:bg-blue-gray-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
}

.focus\:bg-blue-gray-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
}

.focus\:bg-blue-gray-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
}

.focus\:bg-blue-gray-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
}

.focus\:bg-blue-gray-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
}

.focus\:bg-blue-gray-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
}

.focus\:bg-blue-gray-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
}

.focus\:bg-blue-gray:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
}

.focus\:bg-blue-gray-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
}

.focus\:bg-blue-gray-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
}

.focus\:bg-blue-gray-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
}

.focus\:bg-blue-gray-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
}

.focus\:bg-cyan-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
}

.focus\:bg-cyan-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
}

.focus\:bg-cyan-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
}

.focus\:bg-cyan-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
}

.focus\:bg-cyan-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
}

.focus\:bg-cyan-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
}

.focus\:bg-cyan-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
}

.focus\:bg-cyan-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
}

.focus\:bg-cyan-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
}

.focus\:bg-cyan-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
}

.focus\:bg-cyan:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
}

.focus\:bg-cyan-A100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
}

.focus\:bg-cyan-A200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
}

.focus\:bg-cyan-A400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
}

.focus\:bg-cyan-A700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
}

.bg-none {
  background-image: none
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--gradient-color-stops))
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--gradient-color-stops))
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--gradient-color-stops))
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--gradient-color-stops))
}

.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--gradient-color-stops))
}

.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--gradient-color-stops))
}

.from-transparent {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.from-current {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.from-black {
  --tw-gradient-from: #22292F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
}

.from-white {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.from-grey-50 {
  --tw-gradient-from: #FAFAFA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
}

.from-grey-100 {
  --tw-gradient-from: #F5F5F5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
}

.from-grey-200 {
  --tw-gradient-from: #EEEEEE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
}

.from-grey-300 {
  --tw-gradient-from: #E0E0E0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
}

.from-grey-400 {
  --tw-gradient-from: #BDBDBD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
}

.from-grey-500 {
  --tw-gradient-from: #9E9E9E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.from-grey-600 {
  --tw-gradient-from: #757575;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
}

.from-grey-700 {
  --tw-gradient-from: #616161;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.from-grey-800 {
  --tw-gradient-from: #424242;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
}

.from-grey-900 {
  --tw-gradient-from: #212121;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
}

.from-grey {
  --tw-gradient-from: #9E9E9E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.from-grey-A100 {
  --tw-gradient-from: #D5D5D5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
}

.from-grey-A200 {
  --tw-gradient-from: #AAAAAA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
}

.from-grey-A400 {
  --tw-gradient-from: #303030;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
}

.from-grey-A700 {
  --tw-gradient-from: #616161;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.from-gray-50 {
  --tw-gradient-from: #FAFAFA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
}

.from-gray-100 {
  --tw-gradient-from: #F5F5F5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
}

.from-gray-200 {
  --tw-gradient-from: #EEEEEE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
}

.from-gray-300 {
  --tw-gradient-from: #E0E0E0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
}

.from-gray-400 {
  --tw-gradient-from: #BDBDBD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
}

.from-gray-500 {
  --tw-gradient-from: #9E9E9E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.from-gray-600 {
  --tw-gradient-from: #757575;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
}

.from-gray-700 {
  --tw-gradient-from: #616161;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.from-gray-800 {
  --tw-gradient-from: #424242;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
}

.from-gray-900 {
  --tw-gradient-from: #212121;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
}

.from-gray {
  --tw-gradient-from: #9E9E9E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.from-gray-hover {
  --tw-gradient-from: rgba(0, 0, 0, 0.04);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.from-gray-A100 {
  --tw-gradient-from: #D5D5D5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
}

.from-gray-A200 {
  --tw-gradient-from: #AAAAAA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
}

.from-gray-A400 {
  --tw-gradient-from: #303030;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
}

.from-gray-A700 {
  --tw-gradient-from: #616161;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.from-red-50 {
  --tw-gradient-from: #FFEBEE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
}

.from-red-100 {
  --tw-gradient-from: #FFCDD2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
}

.from-red-200 {
  --tw-gradient-from: #EF9A9A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
}

.from-red-300 {
  --tw-gradient-from: #E57373;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
}

.from-red-400 {
  --tw-gradient-from: #EF5350;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
}

.from-red-500 {
  --tw-gradient-from: #F44336;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
}

.from-red-600 {
  --tw-gradient-from: #E53935;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
}

.from-red-700 {
  --tw-gradient-from: #D32F2F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
}

.from-red-800 {
  --tw-gradient-from: #C62828;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
}

.from-red-900 {
  --tw-gradient-from: #B71C1C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
}

.from-red {
  --tw-gradient-from: #F44336;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
}

.from-red-A100 {
  --tw-gradient-from: #FF8A80;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
}

.from-red-A200 {
  --tw-gradient-from: #FF5252;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
}

.from-red-A400 {
  --tw-gradient-from: #FF1744;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
}

.from-red-A700 {
  --tw-gradient-from: #D50000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
}

.from-orange-50 {
  --tw-gradient-from: #FFF3E0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
}

.from-orange-100 {
  --tw-gradient-from: #FFE0B2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
}

.from-orange-200 {
  --tw-gradient-from: #FFCC80;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
}

.from-orange-300 {
  --tw-gradient-from: #FFB74D;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
}

.from-orange-400 {
  --tw-gradient-from: #FFA726;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
}

.from-orange-500 {
  --tw-gradient-from: #FF9800;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
}

.from-orange-600 {
  --tw-gradient-from: #FB8C00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
}

.from-orange-700 {
  --tw-gradient-from: #F57C00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
}

.from-orange-800 {
  --tw-gradient-from: #EF6C00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
}

.from-orange-900 {
  --tw-gradient-from: #E65100;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
}

.from-orange {
  --tw-gradient-from: #FF9800;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
}

.from-orange-A100 {
  --tw-gradient-from: #FFD180;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
}

.from-orange-A200 {
  --tw-gradient-from: #FFAB40;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
}

.from-orange-A400 {
  --tw-gradient-from: #FF9100;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
}

.from-orange-A700 {
  --tw-gradient-from: #FF6D00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
}

.from-deep-orange-50 {
  --tw-gradient-from: #FBE9E7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
}

.from-deep-orange-100 {
  --tw-gradient-from: #FFCCBC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
}

.from-deep-orange-200 {
  --tw-gradient-from: #FFAB91;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
}

.from-deep-orange-300 {
  --tw-gradient-from: #FF8A65;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
}

.from-deep-orange-400 {
  --tw-gradient-from: #FF7043;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
}

.from-deep-orange-500 {
  --tw-gradient-from: #FF5722;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
}

.from-deep-orange-600 {
  --tw-gradient-from: #F4511E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
}

.from-deep-orange-700 {
  --tw-gradient-from: #E64A19;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
}

.from-deep-orange-800 {
  --tw-gradient-from: #D84315;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
}

.from-deep-orange-900 {
  --tw-gradient-from: #BF360C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
}

.from-deep-orange {
  --tw-gradient-from: #FF5722;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
}

.from-deep-orange-A100 {
  --tw-gradient-from: #FF9E80;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
}

.from-deep-orange-A200 {
  --tw-gradient-from: #FF6E40;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
}

.from-deep-orange-A400 {
  --tw-gradient-from: #FF3D00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
}

.from-deep-orange-A700 {
  --tw-gradient-from: #DD2C00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
}

.from-yellow-50 {
  --tw-gradient-from: #FFFDE7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
}

.from-yellow-100 {
  --tw-gradient-from: #FFF9C4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
}

.from-yellow-200 {
  --tw-gradient-from: #FFF59D;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
}

.from-yellow-300 {
  --tw-gradient-from: #FFF176;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
}

.from-yellow-400 {
  --tw-gradient-from: #FFEE58;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
}

.from-yellow-500 {
  --tw-gradient-from: #FFEB3B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
}

.from-yellow-600 {
  --tw-gradient-from: #FDD835;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
}

.from-yellow-700 {
  --tw-gradient-from: #FBC02D;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
}

.from-yellow-800 {
  --tw-gradient-from: #F9A825;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
}

.from-yellow-900 {
  --tw-gradient-from: #F57F17;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
}

.from-yellow {
  --tw-gradient-from: #FFEB3B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
}

.from-yellow-A100 {
  --tw-gradient-from: #FFFF8D;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
}

.from-yellow-A200 {
  --tw-gradient-from: #FFFF00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
}

.from-yellow-A400 {
  --tw-gradient-from: #FFEA00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
}

.from-yellow-A700 {
  --tw-gradient-from: #FFD600;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
}

.from-green-50 {
  --tw-gradient-from: #E8F5E9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
}

.from-green-100 {
  --tw-gradient-from: #C8E6C9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
}

.from-green-200 {
  --tw-gradient-from: #A5D6A7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
}

.from-green-300 {
  --tw-gradient-from: #81C784;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
}

.from-green-400 {
  --tw-gradient-from: #66BB6A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
}

.from-green-500 {
  --tw-gradient-from: #4CAF50;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
}

.from-green-600 {
  --tw-gradient-from: #43A047;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
}

.from-green-700 {
  --tw-gradient-from: #388E3C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
}

.from-green-800 {
  --tw-gradient-from: #2E7D32;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
}

.from-green-900 {
  --tw-gradient-from: #1B5E20;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
}

.from-green {
  --tw-gradient-from: #4CAF50;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
}

.from-green-A100 {
  --tw-gradient-from: #B9F6CA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
}

.from-green-A200 {
  --tw-gradient-from: #69F0AE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
}

.from-green-A400 {
  --tw-gradient-from: #00E676;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
}

.from-green-A700 {
  --tw-gradient-from: #00C853;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
}

.from-light-green-50 {
  --tw-gradient-from: #F1F8E9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
}

.from-light-green-100 {
  --tw-gradient-from: #DCEDC8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
}

.from-light-green-200 {
  --tw-gradient-from: #C5E1A5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
}

.from-light-green-300 {
  --tw-gradient-from: #AED581;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
}

.from-light-green-400 {
  --tw-gradient-from: #9CCC65;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
}

.from-light-green-500 {
  --tw-gradient-from: #8BC34A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
}

.from-light-green-600 {
  --tw-gradient-from: #7CB342;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
}

.from-light-green-700 {
  --tw-gradient-from: #689F38;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
}

.from-light-green-800 {
  --tw-gradient-from: #558B2F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
}

.from-light-green-900 {
  --tw-gradient-from: #33691E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
}

.from-light-green {
  --tw-gradient-from: #8BC34A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
}

.from-light-green-A100 {
  --tw-gradient-from: #CCFF90;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
}

.from-light-green-A200 {
  --tw-gradient-from: #B2FF59;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
}

.from-light-green-A400 {
  --tw-gradient-from: #76FF03;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
}

.from-light-green-A700 {
  --tw-gradient-from: #64DD17;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
}

.from-teal-50 {
  --tw-gradient-from: #E0F2F1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
}

.from-teal-100 {
  --tw-gradient-from: #B2DFDB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
}

.from-teal-200 {
  --tw-gradient-from: #80CBC4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
}

.from-teal-300 {
  --tw-gradient-from: #4DB6AC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
}

.from-teal-400 {
  --tw-gradient-from: #26A69A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
}

.from-teal-500 {
  --tw-gradient-from: #009688;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
}

.from-teal-600 {
  --tw-gradient-from: #00897B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
}

.from-teal-700 {
  --tw-gradient-from: #00796B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
}

.from-teal-800 {
  --tw-gradient-from: #00695C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
}

.from-teal-900 {
  --tw-gradient-from: #004D40;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
}

.from-teal {
  --tw-gradient-from: #009688;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
}

.from-teal-A100 {
  --tw-gradient-from: #A7FFEB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
}

.from-teal-A200 {
  --tw-gradient-from: #64FFDA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
}

.from-teal-A400 {
  --tw-gradient-from: #1DE9B6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
}

.from-teal-A700 {
  --tw-gradient-from: #00BFA5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
}

.from-blue-50 {
  --tw-gradient-from: #E3F2FD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
}

.from-blue-100 {
  --tw-gradient-from: #BBDEFB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
}

.from-blue-200 {
  --tw-gradient-from: #90CAF9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
}

.from-blue-300 {
  --tw-gradient-from: #64B5F6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
}

.from-blue-400 {
  --tw-gradient-from: #42A5F5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
}

.from-blue-500 {
  --tw-gradient-from: #2196F3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
}

.from-blue-600 {
  --tw-gradient-from: #1E88E5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
}

.from-blue-700 {
  --tw-gradient-from: #1976D2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
}

.from-blue-800 {
  --tw-gradient-from: #1565C0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
}

.from-blue-900 {
  --tw-gradient-from: #0D47A1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
}

.from-blue {
  --tw-gradient-from: #2196F3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
}

.from-blue-A100 {
  --tw-gradient-from: #82B1FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
}

.from-blue-A200 {
  --tw-gradient-from: #448AFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
}

.from-blue-A400 {
  --tw-gradient-from: #2979FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
}

.from-blue-A700 {
  --tw-gradient-from: #2962FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
}

.from-light-blue-50 {
  --tw-gradient-from: #E1F5FE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
}

.from-light-blue-100 {
  --tw-gradient-from: #B3E5FC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
}

.from-light-blue-200 {
  --tw-gradient-from: #81D4FA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
}

.from-light-blue-300 {
  --tw-gradient-from: #4FC3F7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
}

.from-light-blue-400 {
  --tw-gradient-from: #29B6F6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
}

.from-light-blue-500 {
  --tw-gradient-from: #03A9F4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
}

.from-light-blue-600 {
  --tw-gradient-from: #039BE5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
}

.from-light-blue-700 {
  --tw-gradient-from: #0288D1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
}

.from-light-blue-800 {
  --tw-gradient-from: #0277BD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
}

.from-light-blue-900 {
  --tw-gradient-from: #01579B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
}

.from-light-blue {
  --tw-gradient-from: #03A9F4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
}

.from-light-blue-A100 {
  --tw-gradient-from: #80D8FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
}

.from-light-blue-A200 {
  --tw-gradient-from: #40C4FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
}

.from-light-blue-A400 {
  --tw-gradient-from: #00B0FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
}

.from-light-blue-A700 {
  --tw-gradient-from: #0091EA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
}

.from-indigo-50 {
  --tw-gradient-from: #E8EAF6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
}

.from-indigo-100 {
  --tw-gradient-from: #C5CAE9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
}

.from-indigo-200 {
  --tw-gradient-from: #9FA8DA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
}

.from-indigo-300 {
  --tw-gradient-from: #7986CB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
}

.from-indigo-400 {
  --tw-gradient-from: #5C6BC0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
}

.from-indigo-500 {
  --tw-gradient-from: #3F51B5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
}

.from-indigo-600 {
  --tw-gradient-from: #3949AB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
}

.from-indigo-700 {
  --tw-gradient-from: #303F9F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
}

.from-indigo-800 {
  --tw-gradient-from: #283593;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
}

.from-indigo-900 {
  --tw-gradient-from: #1A237E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
}

.from-indigo {
  --tw-gradient-from: #3F51B5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
}

.from-indigo-A100 {
  --tw-gradient-from: #8C9EFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
}

.from-indigo-A200 {
  --tw-gradient-from: #536DFE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
}

.from-indigo-A400 {
  --tw-gradient-from: #3D5AFE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
}

.from-indigo-A700 {
  --tw-gradient-from: #304FFE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
}

.from-purple-50 {
  --tw-gradient-from: #F3E5F5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
}

.from-purple-100 {
  --tw-gradient-from: #E1BEE7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
}

.from-purple-200 {
  --tw-gradient-from: #CE93D8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
}

.from-purple-300 {
  --tw-gradient-from: #BA68C8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
}

.from-purple-400 {
  --tw-gradient-from: #AB47BC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
}

.from-purple-500 {
  --tw-gradient-from: #9C27B0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
}

.from-purple-600 {
  --tw-gradient-from: #8E24AA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
}

.from-purple-700 {
  --tw-gradient-from: #7B1FA2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
}

.from-purple-800 {
  --tw-gradient-from: #6A1B9A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
}

.from-purple-900 {
  --tw-gradient-from: #4A148C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
}

.from-purple {
  --tw-gradient-from: #9C27B0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
}

.from-purple-A100 {
  --tw-gradient-from: #EA80FC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
}

.from-purple-A200 {
  --tw-gradient-from: #E040FB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
}

.from-purple-A400 {
  --tw-gradient-from: #D500F9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
}

.from-purple-A700 {
  --tw-gradient-from: #AA00FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
}

.from-deep-purple-50 {
  --tw-gradient-from: #EDE7F6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
}

.from-deep-purple-100 {
  --tw-gradient-from: #D1C4E9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
}

.from-deep-purple-200 {
  --tw-gradient-from: #B39DDB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
}

.from-deep-purple-300 {
  --tw-gradient-from: #9575CD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
}

.from-deep-purple-400 {
  --tw-gradient-from: #7E57C2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
}

.from-deep-purple-500 {
  --tw-gradient-from: #673AB7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
}

.from-deep-purple-600 {
  --tw-gradient-from: #5E35B1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
}

.from-deep-purple-700 {
  --tw-gradient-from: #512DA8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
}

.from-deep-purple-800 {
  --tw-gradient-from: #4527A0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
}

.from-deep-purple-900 {
  --tw-gradient-from: #311B92;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
}

.from-deep-purple {
  --tw-gradient-from: #673AB7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
}

.from-deep-purple-A100 {
  --tw-gradient-from: #B388FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
}

.from-deep-purple-A200 {
  --tw-gradient-from: #7C4DFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
}

.from-deep-purple-A400 {
  --tw-gradient-from: #651FFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
}

.from-deep-purple-A700 {
  --tw-gradient-from: #6200EA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
}

.from-pink-50 {
  --tw-gradient-from: #FCE4EC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
}

.from-pink-100 {
  --tw-gradient-from: #F8BBD0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
}

.from-pink-200 {
  --tw-gradient-from: #F48FB1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
}

.from-pink-300 {
  --tw-gradient-from: #F06292;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
}

.from-pink-400 {
  --tw-gradient-from: #EC407A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
}

.from-pink-500 {
  --tw-gradient-from: #E91E63;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
}

.from-pink-600 {
  --tw-gradient-from: #D81B60;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
}

.from-pink-700 {
  --tw-gradient-from: #C2185B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
}

.from-pink-800 {
  --tw-gradient-from: #AD1457;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
}

.from-pink-900 {
  --tw-gradient-from: #880E4F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
}

.from-pink {
  --tw-gradient-from: #E91E63;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
}

.from-pink-A100 {
  --tw-gradient-from: #FF80AB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
}

.from-pink-A200 {
  --tw-gradient-from: #FF4081;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
}

.from-pink-A400 {
  --tw-gradient-from: #F50057;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
}

.from-pink-A700 {
  --tw-gradient-from: #C51162;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
}

.from-lime-50 {
  --tw-gradient-from: #F9FBE7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
}

.from-lime-100 {
  --tw-gradient-from: #F0F4C3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
}

.from-lime-200 {
  --tw-gradient-from: #E6EE9C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
}

.from-lime-300 {
  --tw-gradient-from: #DCE775;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
}

.from-lime-400 {
  --tw-gradient-from: #D4E157;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
}

.from-lime-500 {
  --tw-gradient-from: #CDDC39;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
}

.from-lime-600 {
  --tw-gradient-from: #C0CA33;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
}

.from-lime-700 {
  --tw-gradient-from: #AFB42B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
}

.from-lime-800 {
  --tw-gradient-from: #9E9D24;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
}

.from-lime-900 {
  --tw-gradient-from: #827717;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
}

.from-lime {
  --tw-gradient-from: #CDDC39;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
}

.from-lime-A100 {
  --tw-gradient-from: #F4FF81;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
}

.from-lime-A200 {
  --tw-gradient-from: #EEFF41;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
}

.from-lime-A400 {
  --tw-gradient-from: #C6FF00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
}

.from-lime-A700 {
  --tw-gradient-from: #AEEA00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
}

.from-amber-50 {
  --tw-gradient-from: #FFF8E1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
}

.from-amber-100 {
  --tw-gradient-from: #FFECB3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
}

.from-amber-200 {
  --tw-gradient-from: #FFE082;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
}

.from-amber-300 {
  --tw-gradient-from: #FFD54F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
}

.from-amber-400 {
  --tw-gradient-from: #FFCA28;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
}

.from-amber-500 {
  --tw-gradient-from: #FFC107;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
}

.from-amber-600 {
  --tw-gradient-from: #FFB300;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
}

.from-amber-700 {
  --tw-gradient-from: #FFA000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
}

.from-amber-800 {
  --tw-gradient-from: #FF8F00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
}

.from-amber-900 {
  --tw-gradient-from: #FF6F00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
}

.from-amber {
  --tw-gradient-from: #FFC107;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
}

.from-amber-A100 {
  --tw-gradient-from: #FFE57F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
}

.from-amber-A200 {
  --tw-gradient-from: #FFD740;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
}

.from-amber-A400 {
  --tw-gradient-from: #FFC400;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
}

.from-amber-A700 {
  --tw-gradient-from: #FFAB00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
}

.from-brown-50 {
  --tw-gradient-from: #EFEBE9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
}

.from-brown-100 {
  --tw-gradient-from: #D7CCC8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
}

.from-brown-200 {
  --tw-gradient-from: #BCAAA4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
}

.from-brown-300 {
  --tw-gradient-from: #A1887F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
}

.from-brown-400 {
  --tw-gradient-from: #8D6E63;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
}

.from-brown-500 {
  --tw-gradient-from: #795548;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
}

.from-brown-600 {
  --tw-gradient-from: #6D4C41;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
}

.from-brown-700 {
  --tw-gradient-from: #5D4037;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
}

.from-brown-800 {
  --tw-gradient-from: #4E342E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
}

.from-brown-900 {
  --tw-gradient-from: #3E2723;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
}

.from-brown {
  --tw-gradient-from: #795548;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
}

.from-brown-A100 {
  --tw-gradient-from: #D7CCC8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
}

.from-brown-A200 {
  --tw-gradient-from: #BCAAA4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
}

.from-brown-A400 {
  --tw-gradient-from: #8D6E63;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
}

.from-brown-A700 {
  --tw-gradient-from: #5D4037;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
}

.from-blue-gray-50 {
  --tw-gradient-from: #ECEFF1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
}

.from-blue-gray-100 {
  --tw-gradient-from: #CFD8DC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
}

.from-blue-gray-200 {
  --tw-gradient-from: #B0BEC5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
}

.from-blue-gray-300 {
  --tw-gradient-from: #90A4AE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
}

.from-blue-gray-400 {
  --tw-gradient-from: #78909C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
}

.from-blue-gray-500 {
  --tw-gradient-from: #607D8B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
}

.from-blue-gray-600 {
  --tw-gradient-from: #546E7A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
}

.from-blue-gray-700 {
  --tw-gradient-from: #455A64;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
}

.from-blue-gray-800 {
  --tw-gradient-from: #37474F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
}

.from-blue-gray-900 {
  --tw-gradient-from: #263238;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
}

.from-blue-gray {
  --tw-gradient-from: #607D8B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
}

.from-blue-gray-A100 {
  --tw-gradient-from: #CFD8DC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
}

.from-blue-gray-A200 {
  --tw-gradient-from: #B0BEC5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
}

.from-blue-gray-A400 {
  --tw-gradient-from: #78909C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
}

.from-blue-gray-A700 {
  --tw-gradient-from: #455A64;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
}

.from-cyan-50 {
  --tw-gradient-from: #E0F7FA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
}

.from-cyan-100 {
  --tw-gradient-from: #B2EBF2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
}

.from-cyan-200 {
  --tw-gradient-from: #80DEEA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
}

.from-cyan-300 {
  --tw-gradient-from: #4DD0E1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
}

.from-cyan-400 {
  --tw-gradient-from: #26C6DA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
}

.from-cyan-500 {
  --tw-gradient-from: #00BCD4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
}

.from-cyan-600 {
  --tw-gradient-from: #00ACC1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
}

.from-cyan-700 {
  --tw-gradient-from: #0097A7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
}

.from-cyan-800 {
  --tw-gradient-from: #00838F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
}

.from-cyan-900 {
  --tw-gradient-from: #006064;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
}

.from-cyan {
  --tw-gradient-from: #00BCD4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
}

.from-cyan-A100 {
  --tw-gradient-from: #84FFFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
}

.from-cyan-A200 {
  --tw-gradient-from: #18FFFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
}

.from-cyan-A400 {
  --tw-gradient-from: #00E5FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
}

.from-cyan-A700 {
  --tw-gradient-from: #00B8D4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
}

.via-transparent {
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.via-current {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.via-black {
  --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
}

.via-white {
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.via-grey-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
}

.via-grey-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
}

.via-grey-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
}

.via-grey-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
}

.via-grey-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
}

.via-grey-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.via-grey-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
}

.via-grey-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.via-grey-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
}

.via-grey-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
}

.via-grey {
  --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.via-grey-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
}

.via-grey-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
}

.via-grey-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
}

.via-grey-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.via-gray-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
}

.via-gray-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
}

.via-gray-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
}

.via-gray-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
}

.via-gray-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
}

.via-gray-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.via-gray-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
}

.via-gray-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.via-gray-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
}

.via-gray-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
}

.via-gray {
  --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.via-gray-hover {
  --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.via-gray-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
}

.via-gray-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
}

.via-gray-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
}

.via-gray-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.via-red-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
}

.via-red-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
}

.via-red-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
}

.via-red-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
}

.via-red-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
}

.via-red-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
}

.via-red-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
}

.via-red-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
}

.via-red-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
}

.via-red-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
}

.via-red {
  --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
}

.via-red-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
}

.via-red-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
}

.via-red-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
}

.via-red-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
}

.via-orange-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
}

.via-orange-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
}

.via-orange-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
}

.via-orange-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
}

.via-orange-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
}

.via-orange-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
}

.via-orange-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
}

.via-orange-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
}

.via-orange-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
}

.via-orange-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
}

.via-orange {
  --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
}

.via-orange-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
}

.via-orange-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
}

.via-orange-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
}

.via-orange-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
}

.via-deep-orange-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
}

.via-deep-orange-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
}

.via-deep-orange-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
}

.via-deep-orange-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
}

.via-deep-orange-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
}

.via-deep-orange-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
}

.via-deep-orange-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
}

.via-deep-orange-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
}

.via-deep-orange-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
}

.via-deep-orange-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
}

.via-deep-orange {
  --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
}

.via-deep-orange-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
}

.via-deep-orange-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
}

.via-deep-orange-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
}

.via-deep-orange-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
}

.via-yellow-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
}

.via-yellow-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
}

.via-yellow-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
}

.via-yellow-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
}

.via-yellow-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
}

.via-yellow-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
}

.via-yellow-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
}

.via-yellow-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
}

.via-yellow-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
}

.via-yellow-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
}

.via-yellow {
  --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
}

.via-yellow-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
}

.via-yellow-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
}

.via-yellow-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
}

.via-yellow-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
}

.via-green-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
}

.via-green-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
}

.via-green-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
}

.via-green-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
}

.via-green-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
}

.via-green-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
}

.via-green-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
}

.via-green-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
}

.via-green-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
}

.via-green-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
}

.via-green {
  --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
}

.via-green-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
}

.via-green-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
}

.via-green-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
}

.via-green-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
}

.via-light-green-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
}

.via-light-green-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
}

.via-light-green-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
}

.via-light-green-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
}

.via-light-green-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
}

.via-light-green-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
}

.via-light-green-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
}

.via-light-green-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
}

.via-light-green-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
}

.via-light-green-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
}

.via-light-green {
  --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
}

.via-light-green-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
}

.via-light-green-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
}

.via-light-green-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
}

.via-light-green-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
}

.via-teal-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
}

.via-teal-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
}

.via-teal-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
}

.via-teal-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
}

.via-teal-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
}

.via-teal-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
}

.via-teal-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
}

.via-teal-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
}

.via-teal-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
}

.via-teal-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
}

.via-teal {
  --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
}

.via-teal-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
}

.via-teal-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
}

.via-teal-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
}

.via-teal-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
}

.via-blue-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
}

.via-blue-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
}

.via-blue-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
}

.via-blue-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
}

.via-blue-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
}

.via-blue-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
}

.via-blue-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
}

.via-blue-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
}

.via-blue-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
}

.via-blue-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
}

.via-blue {
  --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
}

.via-blue-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
}

.via-blue-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
}

.via-blue-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
}

.via-blue-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
}

.via-light-blue-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
}

.via-light-blue-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
}

.via-light-blue-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
}

.via-light-blue-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
}

.via-light-blue-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
}

.via-light-blue-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
}

.via-light-blue-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
}

.via-light-blue-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
}

.via-light-blue-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
}

.via-light-blue-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
}

.via-light-blue {
  --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
}

.via-light-blue-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
}

.via-light-blue-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
}

.via-light-blue-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
}

.via-light-blue-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
}

.via-indigo-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
}

.via-indigo-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
}

.via-indigo-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
}

.via-indigo-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
}

.via-indigo-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
}

.via-indigo-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
}

.via-indigo-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
}

.via-indigo-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
}

.via-indigo-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
}

.via-indigo-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
}

.via-indigo {
  --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
}

.via-indigo-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
}

.via-indigo-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
}

.via-indigo-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
}

.via-indigo-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
}

.via-purple-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
}

.via-purple-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
}

.via-purple-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
}

.via-purple-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
}

.via-purple-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
}

.via-purple-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
}

.via-purple-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
}

.via-purple-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
}

.via-purple-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
}

.via-purple-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
}

.via-purple {
  --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
}

.via-purple-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
}

.via-purple-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
}

.via-purple-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
}

.via-purple-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
}

.via-deep-purple-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
}

.via-deep-purple-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
}

.via-deep-purple-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
}

.via-deep-purple-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
}

.via-deep-purple-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
}

.via-deep-purple-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
}

.via-deep-purple-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
}

.via-deep-purple-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
}

.via-deep-purple-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
}

.via-deep-purple-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
}

.via-deep-purple {
  --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
}

.via-deep-purple-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
}

.via-deep-purple-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
}

.via-deep-purple-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
}

.via-deep-purple-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
}

.via-pink-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
}

.via-pink-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
}

.via-pink-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
}

.via-pink-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
}

.via-pink-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
}

.via-pink-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
}

.via-pink-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
}

.via-pink-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
}

.via-pink-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
}

.via-pink-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
}

.via-pink {
  --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
}

.via-pink-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
}

.via-pink-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
}

.via-pink-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
}

.via-pink-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
}

.via-lime-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
}

.via-lime-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
}

.via-lime-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
}

.via-lime-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
}

.via-lime-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
}

.via-lime-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
}

.via-lime-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
}

.via-lime-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
}

.via-lime-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
}

.via-lime-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
}

.via-lime {
  --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
}

.via-lime-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
}

.via-lime-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
}

.via-lime-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
}

.via-lime-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
}

.via-amber-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
}

.via-amber-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
}

.via-amber-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
}

.via-amber-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
}

.via-amber-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
}

.via-amber-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
}

.via-amber-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
}

.via-amber-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
}

.via-amber-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
}

.via-amber-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
}

.via-amber {
  --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
}

.via-amber-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
}

.via-amber-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
}

.via-amber-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
}

.via-amber-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
}

.via-brown-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
}

.via-brown-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
}

.via-brown-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
}

.via-brown-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
}

.via-brown-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
}

.via-brown-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
}

.via-brown-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
}

.via-brown-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
}

.via-brown-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
}

.via-brown-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
}

.via-brown {
  --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
}

.via-brown-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
}

.via-brown-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
}

.via-brown-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
}

.via-brown-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
}

.via-blue-gray-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
}

.via-blue-gray-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
}

.via-blue-gray-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
}

.via-blue-gray-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
}

.via-blue-gray-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
}

.via-blue-gray-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
}

.via-blue-gray-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
}

.via-blue-gray-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
}

.via-blue-gray-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
}

.via-blue-gray-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
}

.via-blue-gray {
  --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
}

.via-blue-gray-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
}

.via-blue-gray-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
}

.via-blue-gray-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
}

.via-blue-gray-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
}

.via-cyan-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
}

.via-cyan-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
}

.via-cyan-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
}

.via-cyan-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
}

.via-cyan-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
}

.via-cyan-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
}

.via-cyan-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
}

.via-cyan-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
}

.via-cyan-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
}

.via-cyan-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
}

.via-cyan {
  --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
}

.via-cyan-A100 {
  --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
}

.via-cyan-A200 {
  --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
}

.via-cyan-A400 {
  --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
}

.via-cyan-A700 {
  --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
}

.to-transparent {
  --tw-gradient-to: transparent
}

.to-current {
  --tw-gradient-to: currentColor
}

.to-black {
  --tw-gradient-to: #22292F
}

.to-white {
  --tw-gradient-to: #fff
}

.to-grey-50 {
  --tw-gradient-to: #FAFAFA
}

.to-grey-100 {
  --tw-gradient-to: #F5F5F5
}

.to-grey-200 {
  --tw-gradient-to: #EEEEEE
}

.to-grey-300 {
  --tw-gradient-to: #E0E0E0
}

.to-grey-400 {
  --tw-gradient-to: #BDBDBD
}

.to-grey-500 {
  --tw-gradient-to: #9E9E9E
}

.to-grey-600 {
  --tw-gradient-to: #757575
}

.to-grey-700 {
  --tw-gradient-to: #616161
}

.to-grey-800 {
  --tw-gradient-to: #424242
}

.to-grey-900 {
  --tw-gradient-to: #212121
}

.to-grey {
  --tw-gradient-to: #9E9E9E
}

.to-grey-A100 {
  --tw-gradient-to: #D5D5D5
}

.to-grey-A200 {
  --tw-gradient-to: #AAAAAA
}

.to-grey-A400 {
  --tw-gradient-to: #303030
}

.to-grey-A700 {
  --tw-gradient-to: #616161
}

.to-gray-50 {
  --tw-gradient-to: #FAFAFA
}

.to-gray-100 {
  --tw-gradient-to: #F5F5F5
}

.to-gray-200 {
  --tw-gradient-to: #EEEEEE
}

.to-gray-300 {
  --tw-gradient-to: #E0E0E0
}

.to-gray-400 {
  --tw-gradient-to: #BDBDBD
}

.to-gray-500 {
  --tw-gradient-to: #9E9E9E
}

.to-gray-600 {
  --tw-gradient-to: #757575
}

.to-gray-700 {
  --tw-gradient-to: #616161
}

.to-gray-800 {
  --tw-gradient-to: #424242
}

.to-gray-900 {
  --tw-gradient-to: #212121
}

.to-gray {
  --tw-gradient-to: #9E9E9E
}

.to-gray-hover {
  --tw-gradient-to: rgba(0, 0, 0, 0.04)
}

.to-gray-A100 {
  --tw-gradient-to: #D5D5D5
}

.to-gray-A200 {
  --tw-gradient-to: #AAAAAA
}

.to-gray-A400 {
  --tw-gradient-to: #303030
}

.to-gray-A700 {
  --tw-gradient-to: #616161
}

.to-red-50 {
  --tw-gradient-to: #FFEBEE
}

.to-red-100 {
  --tw-gradient-to: #FFCDD2
}

.to-red-200 {
  --tw-gradient-to: #EF9A9A
}

.to-red-300 {
  --tw-gradient-to: #E57373
}

.to-red-400 {
  --tw-gradient-to: #EF5350
}

.to-red-500 {
  --tw-gradient-to: #F44336
}

.to-red-600 {
  --tw-gradient-to: #E53935
}

.to-red-700 {
  --tw-gradient-to: #D32F2F
}

.to-red-800 {
  --tw-gradient-to: #C62828
}

.to-red-900 {
  --tw-gradient-to: #B71C1C
}

.to-red {
  --tw-gradient-to: #F44336
}

.to-red-A100 {
  --tw-gradient-to: #FF8A80
}

.to-red-A200 {
  --tw-gradient-to: #FF5252
}

.to-red-A400 {
  --tw-gradient-to: #FF1744
}

.to-red-A700 {
  --tw-gradient-to: #D50000
}

.to-orange-50 {
  --tw-gradient-to: #FFF3E0
}

.to-orange-100 {
  --tw-gradient-to: #FFE0B2
}

.to-orange-200 {
  --tw-gradient-to: #FFCC80
}

.to-orange-300 {
  --tw-gradient-to: #FFB74D
}

.to-orange-400 {
  --tw-gradient-to: #FFA726
}

.to-orange-500 {
  --tw-gradient-to: #FF9800
}

.to-orange-600 {
  --tw-gradient-to: #FB8C00
}

.to-orange-700 {
  --tw-gradient-to: #F57C00
}

.to-orange-800 {
  --tw-gradient-to: #EF6C00
}

.to-orange-900 {
  --tw-gradient-to: #E65100
}

.to-orange {
  --tw-gradient-to: #FF9800
}

.to-orange-A100 {
  --tw-gradient-to: #FFD180
}

.to-orange-A200 {
  --tw-gradient-to: #FFAB40
}

.to-orange-A400 {
  --tw-gradient-to: #FF9100
}

.to-orange-A700 {
  --tw-gradient-to: #FF6D00
}

.to-deep-orange-50 {
  --tw-gradient-to: #FBE9E7
}

.to-deep-orange-100 {
  --tw-gradient-to: #FFCCBC
}

.to-deep-orange-200 {
  --tw-gradient-to: #FFAB91
}

.to-deep-orange-300 {
  --tw-gradient-to: #FF8A65
}

.to-deep-orange-400 {
  --tw-gradient-to: #FF7043
}

.to-deep-orange-500 {
  --tw-gradient-to: #FF5722
}

.to-deep-orange-600 {
  --tw-gradient-to: #F4511E
}

.to-deep-orange-700 {
  --tw-gradient-to: #E64A19
}

.to-deep-orange-800 {
  --tw-gradient-to: #D84315
}

.to-deep-orange-900 {
  --tw-gradient-to: #BF360C
}

.to-deep-orange {
  --tw-gradient-to: #FF5722
}

.to-deep-orange-A100 {
  --tw-gradient-to: #FF9E80
}

.to-deep-orange-A200 {
  --tw-gradient-to: #FF6E40
}

.to-deep-orange-A400 {
  --tw-gradient-to: #FF3D00
}

.to-deep-orange-A700 {
  --tw-gradient-to: #DD2C00
}

.to-yellow-50 {
  --tw-gradient-to: #FFFDE7
}

.to-yellow-100 {
  --tw-gradient-to: #FFF9C4
}

.to-yellow-200 {
  --tw-gradient-to: #FFF59D
}

.to-yellow-300 {
  --tw-gradient-to: #FFF176
}

.to-yellow-400 {
  --tw-gradient-to: #FFEE58
}

.to-yellow-500 {
  --tw-gradient-to: #FFEB3B
}

.to-yellow-600 {
  --tw-gradient-to: #FDD835
}

.to-yellow-700 {
  --tw-gradient-to: #FBC02D
}

.to-yellow-800 {
  --tw-gradient-to: #F9A825
}

.to-yellow-900 {
  --tw-gradient-to: #F57F17
}

.to-yellow {
  --tw-gradient-to: #FFEB3B
}

.to-yellow-A100 {
  --tw-gradient-to: #FFFF8D
}

.to-yellow-A200 {
  --tw-gradient-to: #FFFF00
}

.to-yellow-A400 {
  --tw-gradient-to: #FFEA00
}

.to-yellow-A700 {
  --tw-gradient-to: #FFD600
}

.to-green-50 {
  --tw-gradient-to: #E8F5E9
}

.to-green-100 {
  --tw-gradient-to: #C8E6C9
}

.to-green-200 {
  --tw-gradient-to: #A5D6A7
}

.to-green-300 {
  --tw-gradient-to: #81C784
}

.to-green-400 {
  --tw-gradient-to: #66BB6A
}

.to-green-500 {
  --tw-gradient-to: #4CAF50
}

.to-green-600 {
  --tw-gradient-to: #43A047
}

.to-green-700 {
  --tw-gradient-to: #388E3C
}

.to-green-800 {
  --tw-gradient-to: #2E7D32
}

.to-green-900 {
  --tw-gradient-to: #1B5E20
}

.to-green {
  --tw-gradient-to: #4CAF50
}

.to-green-A100 {
  --tw-gradient-to: #B9F6CA
}

.to-green-A200 {
  --tw-gradient-to: #69F0AE
}

.to-green-A400 {
  --tw-gradient-to: #00E676
}

.to-green-A700 {
  --tw-gradient-to: #00C853
}

.to-light-green-50 {
  --tw-gradient-to: #F1F8E9
}

.to-light-green-100 {
  --tw-gradient-to: #DCEDC8
}

.to-light-green-200 {
  --tw-gradient-to: #C5E1A5
}

.to-light-green-300 {
  --tw-gradient-to: #AED581
}

.to-light-green-400 {
  --tw-gradient-to: #9CCC65
}

.to-light-green-500 {
  --tw-gradient-to: #8BC34A
}

.to-light-green-600 {
  --tw-gradient-to: #7CB342
}

.to-light-green-700 {
  --tw-gradient-to: #689F38
}

.to-light-green-800 {
  --tw-gradient-to: #558B2F
}

.to-light-green-900 {
  --tw-gradient-to: #33691E
}

.to-light-green {
  --tw-gradient-to: #8BC34A
}

.to-light-green-A100 {
  --tw-gradient-to: #CCFF90
}

.to-light-green-A200 {
  --tw-gradient-to: #B2FF59
}

.to-light-green-A400 {
  --tw-gradient-to: #76FF03
}

.to-light-green-A700 {
  --tw-gradient-to: #64DD17
}

.to-teal-50 {
  --tw-gradient-to: #E0F2F1
}

.to-teal-100 {
  --tw-gradient-to: #B2DFDB
}

.to-teal-200 {
  --tw-gradient-to: #80CBC4
}

.to-teal-300 {
  --tw-gradient-to: #4DB6AC
}

.to-teal-400 {
  --tw-gradient-to: #26A69A
}

.to-teal-500 {
  --tw-gradient-to: #009688
}

.to-teal-600 {
  --tw-gradient-to: #00897B
}

.to-teal-700 {
  --tw-gradient-to: #00796B
}

.to-teal-800 {
  --tw-gradient-to: #00695C
}

.to-teal-900 {
  --tw-gradient-to: #004D40
}

.to-teal {
  --tw-gradient-to: #009688
}

.to-teal-A100 {
  --tw-gradient-to: #A7FFEB
}

.to-teal-A200 {
  --tw-gradient-to: #64FFDA
}

.to-teal-A400 {
  --tw-gradient-to: #1DE9B6
}

.to-teal-A700 {
  --tw-gradient-to: #00BFA5
}

.to-blue-50 {
  --tw-gradient-to: #E3F2FD
}

.to-blue-100 {
  --tw-gradient-to: #BBDEFB
}

.to-blue-200 {
  --tw-gradient-to: #90CAF9
}

.to-blue-300 {
  --tw-gradient-to: #64B5F6
}

.to-blue-400 {
  --tw-gradient-to: #42A5F5
}

.to-blue-500 {
  --tw-gradient-to: #2196F3
}

.to-blue-600 {
  --tw-gradient-to: #1E88E5
}

.to-blue-700 {
  --tw-gradient-to: #1976D2
}

.to-blue-800 {
  --tw-gradient-to: #1565C0
}

.to-blue-900 {
  --tw-gradient-to: #0D47A1
}

.to-blue {
  --tw-gradient-to: #2196F3
}

.to-blue-A100 {
  --tw-gradient-to: #82B1FF
}

.to-blue-A200 {
  --tw-gradient-to: #448AFF
}

.to-blue-A400 {
  --tw-gradient-to: #2979FF
}

.to-blue-A700 {
  --tw-gradient-to: #2962FF
}

.to-light-blue-50 {
  --tw-gradient-to: #E1F5FE
}

.to-light-blue-100 {
  --tw-gradient-to: #B3E5FC
}

.to-light-blue-200 {
  --tw-gradient-to: #81D4FA
}

.to-light-blue-300 {
  --tw-gradient-to: #4FC3F7
}

.to-light-blue-400 {
  --tw-gradient-to: #29B6F6
}

.to-light-blue-500 {
  --tw-gradient-to: #03A9F4
}

.to-light-blue-600 {
  --tw-gradient-to: #039BE5
}

.to-light-blue-700 {
  --tw-gradient-to: #0288D1
}

.to-light-blue-800 {
  --tw-gradient-to: #0277BD
}

.to-light-blue-900 {
  --tw-gradient-to: #01579B
}

.to-light-blue {
  --tw-gradient-to: #03A9F4
}

.to-light-blue-A100 {
  --tw-gradient-to: #80D8FF
}

.to-light-blue-A200 {
  --tw-gradient-to: #40C4FF
}

.to-light-blue-A400 {
  --tw-gradient-to: #00B0FF
}

.to-light-blue-A700 {
  --tw-gradient-to: #0091EA
}

.to-indigo-50 {
  --tw-gradient-to: #E8EAF6
}

.to-indigo-100 {
  --tw-gradient-to: #C5CAE9
}

.to-indigo-200 {
  --tw-gradient-to: #9FA8DA
}

.to-indigo-300 {
  --tw-gradient-to: #7986CB
}

.to-indigo-400 {
  --tw-gradient-to: #5C6BC0
}

.to-indigo-500 {
  --tw-gradient-to: #3F51B5
}

.to-indigo-600 {
  --tw-gradient-to: #3949AB
}

.to-indigo-700 {
  --tw-gradient-to: #303F9F
}

.to-indigo-800 {
  --tw-gradient-to: #283593
}

.to-indigo-900 {
  --tw-gradient-to: #1A237E
}

.to-indigo {
  --tw-gradient-to: #3F51B5
}

.to-indigo-A100 {
  --tw-gradient-to: #8C9EFF
}

.to-indigo-A200 {
  --tw-gradient-to: #536DFE
}

.to-indigo-A400 {
  --tw-gradient-to: #3D5AFE
}

.to-indigo-A700 {
  --tw-gradient-to: #304FFE
}

.to-purple-50 {
  --tw-gradient-to: #F3E5F5
}

.to-purple-100 {
  --tw-gradient-to: #E1BEE7
}

.to-purple-200 {
  --tw-gradient-to: #CE93D8
}

.to-purple-300 {
  --tw-gradient-to: #BA68C8
}

.to-purple-400 {
  --tw-gradient-to: #AB47BC
}

.to-purple-500 {
  --tw-gradient-to: #9C27B0
}

.to-purple-600 {
  --tw-gradient-to: #8E24AA
}

.to-purple-700 {
  --tw-gradient-to: #7B1FA2
}

.to-purple-800 {
  --tw-gradient-to: #6A1B9A
}

.to-purple-900 {
  --tw-gradient-to: #4A148C
}

.to-purple {
  --tw-gradient-to: #9C27B0
}

.to-purple-A100 {
  --tw-gradient-to: #EA80FC
}

.to-purple-A200 {
  --tw-gradient-to: #E040FB
}

.to-purple-A400 {
  --tw-gradient-to: #D500F9
}

.to-purple-A700 {
  --tw-gradient-to: #AA00FF
}

.to-deep-purple-50 {
  --tw-gradient-to: #EDE7F6
}

.to-deep-purple-100 {
  --tw-gradient-to: #D1C4E9
}

.to-deep-purple-200 {
  --tw-gradient-to: #B39DDB
}

.to-deep-purple-300 {
  --tw-gradient-to: #9575CD
}

.to-deep-purple-400 {
  --tw-gradient-to: #7E57C2
}

.to-deep-purple-500 {
  --tw-gradient-to: #673AB7
}

.to-deep-purple-600 {
  --tw-gradient-to: #5E35B1
}

.to-deep-purple-700 {
  --tw-gradient-to: #512DA8
}

.to-deep-purple-800 {
  --tw-gradient-to: #4527A0
}

.to-deep-purple-900 {
  --tw-gradient-to: #311B92
}

.to-deep-purple {
  --tw-gradient-to: #673AB7
}

.to-deep-purple-A100 {
  --tw-gradient-to: #B388FF
}

.to-deep-purple-A200 {
  --tw-gradient-to: #7C4DFF
}

.to-deep-purple-A400 {
  --tw-gradient-to: #651FFF
}

.to-deep-purple-A700 {
  --tw-gradient-to: #6200EA
}

.to-pink-50 {
  --tw-gradient-to: #FCE4EC
}

.to-pink-100 {
  --tw-gradient-to: #F8BBD0
}

.to-pink-200 {
  --tw-gradient-to: #F48FB1
}

.to-pink-300 {
  --tw-gradient-to: #F06292
}

.to-pink-400 {
  --tw-gradient-to: #EC407A
}

.to-pink-500 {
  --tw-gradient-to: #E91E63
}

.to-pink-600 {
  --tw-gradient-to: #D81B60
}

.to-pink-700 {
  --tw-gradient-to: #C2185B
}

.to-pink-800 {
  --tw-gradient-to: #AD1457
}

.to-pink-900 {
  --tw-gradient-to: #880E4F
}

.to-pink {
  --tw-gradient-to: #E91E63
}

.to-pink-A100 {
  --tw-gradient-to: #FF80AB
}

.to-pink-A200 {
  --tw-gradient-to: #FF4081
}

.to-pink-A400 {
  --tw-gradient-to: #F50057
}

.to-pink-A700 {
  --tw-gradient-to: #C51162
}

.to-lime-50 {
  --tw-gradient-to: #F9FBE7
}

.to-lime-100 {
  --tw-gradient-to: #F0F4C3
}

.to-lime-200 {
  --tw-gradient-to: #E6EE9C
}

.to-lime-300 {
  --tw-gradient-to: #DCE775
}

.to-lime-400 {
  --tw-gradient-to: #D4E157
}

.to-lime-500 {
  --tw-gradient-to: #CDDC39
}

.to-lime-600 {
  --tw-gradient-to: #C0CA33
}

.to-lime-700 {
  --tw-gradient-to: #AFB42B
}

.to-lime-800 {
  --tw-gradient-to: #9E9D24
}

.to-lime-900 {
  --tw-gradient-to: #827717
}

.to-lime {
  --tw-gradient-to: #CDDC39
}

.to-lime-A100 {
  --tw-gradient-to: #F4FF81
}

.to-lime-A200 {
  --tw-gradient-to: #EEFF41
}

.to-lime-A400 {
  --tw-gradient-to: #C6FF00
}

.to-lime-A700 {
  --tw-gradient-to: #AEEA00
}

.to-amber-50 {
  --tw-gradient-to: #FFF8E1
}

.to-amber-100 {
  --tw-gradient-to: #FFECB3
}

.to-amber-200 {
  --tw-gradient-to: #FFE082
}

.to-amber-300 {
  --tw-gradient-to: #FFD54F
}

.to-amber-400 {
  --tw-gradient-to: #FFCA28
}

.to-amber-500 {
  --tw-gradient-to: #FFC107
}

.to-amber-600 {
  --tw-gradient-to: #FFB300
}

.to-amber-700 {
  --tw-gradient-to: #FFA000
}

.to-amber-800 {
  --tw-gradient-to: #FF8F00
}

.to-amber-900 {
  --tw-gradient-to: #FF6F00
}

.to-amber {
  --tw-gradient-to: #FFC107
}

.to-amber-A100 {
  --tw-gradient-to: #FFE57F
}

.to-amber-A200 {
  --tw-gradient-to: #FFD740
}

.to-amber-A400 {
  --tw-gradient-to: #FFC400
}

.to-amber-A700 {
  --tw-gradient-to: #FFAB00
}

.to-brown-50 {
  --tw-gradient-to: #EFEBE9
}

.to-brown-100 {
  --tw-gradient-to: #D7CCC8
}

.to-brown-200 {
  --tw-gradient-to: #BCAAA4
}

.to-brown-300 {
  --tw-gradient-to: #A1887F
}

.to-brown-400 {
  --tw-gradient-to: #8D6E63
}

.to-brown-500 {
  --tw-gradient-to: #795548
}

.to-brown-600 {
  --tw-gradient-to: #6D4C41
}

.to-brown-700 {
  --tw-gradient-to: #5D4037
}

.to-brown-800 {
  --tw-gradient-to: #4E342E
}

.to-brown-900 {
  --tw-gradient-to: #3E2723
}

.to-brown {
  --tw-gradient-to: #795548
}

.to-brown-A100 {
  --tw-gradient-to: #D7CCC8
}

.to-brown-A200 {
  --tw-gradient-to: #BCAAA4
}

.to-brown-A400 {
  --tw-gradient-to: #8D6E63
}

.to-brown-A700 {
  --tw-gradient-to: #5D4037
}

.to-blue-gray-50 {
  --tw-gradient-to: #ECEFF1
}

.to-blue-gray-100 {
  --tw-gradient-to: #CFD8DC
}

.to-blue-gray-200 {
  --tw-gradient-to: #B0BEC5
}

.to-blue-gray-300 {
  --tw-gradient-to: #90A4AE
}

.to-blue-gray-400 {
  --tw-gradient-to: #78909C
}

.to-blue-gray-500 {
  --tw-gradient-to: #607D8B
}

.to-blue-gray-600 {
  --tw-gradient-to: #546E7A
}

.to-blue-gray-700 {
  --tw-gradient-to: #455A64
}

.to-blue-gray-800 {
  --tw-gradient-to: #37474F
}

.to-blue-gray-900 {
  --tw-gradient-to: #263238
}

.to-blue-gray {
  --tw-gradient-to: #607D8B
}

.to-blue-gray-A100 {
  --tw-gradient-to: #CFD8DC
}

.to-blue-gray-A200 {
  --tw-gradient-to: #B0BEC5
}

.to-blue-gray-A400 {
  --tw-gradient-to: #78909C
}

.to-blue-gray-A700 {
  --tw-gradient-to: #455A64
}

.to-cyan-50 {
  --tw-gradient-to: #E0F7FA
}

.to-cyan-100 {
  --tw-gradient-to: #B2EBF2
}

.to-cyan-200 {
  --tw-gradient-to: #80DEEA
}

.to-cyan-300 {
  --tw-gradient-to: #4DD0E1
}

.to-cyan-400 {
  --tw-gradient-to: #26C6DA
}

.to-cyan-500 {
  --tw-gradient-to: #00BCD4
}

.to-cyan-600 {
  --tw-gradient-to: #00ACC1
}

.to-cyan-700 {
  --tw-gradient-to: #0097A7
}

.to-cyan-800 {
  --tw-gradient-to: #00838F
}

.to-cyan-900 {
  --tw-gradient-to: #006064
}

.to-cyan {
  --tw-gradient-to: #00BCD4
}

.to-cyan-A100 {
  --tw-gradient-to: #84FFFF
}

.to-cyan-A200 {
  --tw-gradient-to: #18FFFF
}

.to-cyan-A400 {
  --tw-gradient-to: #00E5FF
}

.to-cyan-A700 {
  --tw-gradient-to: #00B8D4
}

.hover\:from-transparent:hover {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.hover\:from-current:hover {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.hover\:from-black:hover {
  --tw-gradient-from: #22292F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
}

.hover\:from-white:hover {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.hover\:from-grey-50:hover {
  --tw-gradient-from: #FAFAFA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
}

.hover\:from-grey-100:hover {
  --tw-gradient-from: #F5F5F5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
}

.hover\:from-grey-200:hover {
  --tw-gradient-from: #EEEEEE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
}

.hover\:from-grey-300:hover {
  --tw-gradient-from: #E0E0E0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
}

.hover\:from-grey-400:hover {
  --tw-gradient-from: #BDBDBD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
}

.hover\:from-grey-500:hover {
  --tw-gradient-from: #9E9E9E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.hover\:from-grey-600:hover {
  --tw-gradient-from: #757575;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
}

.hover\:from-grey-700:hover {
  --tw-gradient-from: #616161;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.hover\:from-grey-800:hover {
  --tw-gradient-from: #424242;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
}

.hover\:from-grey-900:hover {
  --tw-gradient-from: #212121;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
}

.hover\:from-grey:hover {
  --tw-gradient-from: #9E9E9E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.hover\:from-grey-A100:hover {
  --tw-gradient-from: #D5D5D5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
}

.hover\:from-grey-A200:hover {
  --tw-gradient-from: #AAAAAA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
}

.hover\:from-grey-A400:hover {
  --tw-gradient-from: #303030;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
}

.hover\:from-grey-A700:hover {
  --tw-gradient-from: #616161;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.hover\:from-gray-50:hover {
  --tw-gradient-from: #FAFAFA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
}

.hover\:from-gray-100:hover {
  --tw-gradient-from: #F5F5F5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
}

.hover\:from-gray-200:hover {
  --tw-gradient-from: #EEEEEE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
}

.hover\:from-gray-300:hover {
  --tw-gradient-from: #E0E0E0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
}

.hover\:from-gray-400:hover {
  --tw-gradient-from: #BDBDBD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
}

.hover\:from-gray-500:hover {
  --tw-gradient-from: #9E9E9E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.hover\:from-gray-600:hover {
  --tw-gradient-from: #757575;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
}

.hover\:from-gray-700:hover {
  --tw-gradient-from: #616161;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.hover\:from-gray-800:hover {
  --tw-gradient-from: #424242;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
}

.hover\:from-gray-900:hover {
  --tw-gradient-from: #212121;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
}

.hover\:from-gray:hover {
  --tw-gradient-from: #9E9E9E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.hover\:from-gray-hover:hover {
  --tw-gradient-from: rgba(0, 0, 0, 0.04);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.hover\:from-gray-A100:hover {
  --tw-gradient-from: #D5D5D5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
}

.hover\:from-gray-A200:hover {
  --tw-gradient-from: #AAAAAA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
}

.hover\:from-gray-A400:hover {
  --tw-gradient-from: #303030;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
}

.hover\:from-gray-A700:hover {
  --tw-gradient-from: #616161;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.hover\:from-red-50:hover {
  --tw-gradient-from: #FFEBEE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
}

.hover\:from-red-100:hover {
  --tw-gradient-from: #FFCDD2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
}

.hover\:from-red-200:hover {
  --tw-gradient-from: #EF9A9A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
}

.hover\:from-red-300:hover {
  --tw-gradient-from: #E57373;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
}

.hover\:from-red-400:hover {
  --tw-gradient-from: #EF5350;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
}

.hover\:from-red-500:hover {
  --tw-gradient-from: #F44336;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
}

.hover\:from-red-600:hover {
  --tw-gradient-from: #E53935;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
}

.hover\:from-red-700:hover {
  --tw-gradient-from: #D32F2F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
}

.hover\:from-red-800:hover {
  --tw-gradient-from: #C62828;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
}

.hover\:from-red-900:hover {
  --tw-gradient-from: #B71C1C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
}

.hover\:from-red:hover {
  --tw-gradient-from: #F44336;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
}

.hover\:from-red-A100:hover {
  --tw-gradient-from: #FF8A80;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
}

.hover\:from-red-A200:hover {
  --tw-gradient-from: #FF5252;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
}

.hover\:from-red-A400:hover {
  --tw-gradient-from: #FF1744;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
}

.hover\:from-red-A700:hover {
  --tw-gradient-from: #D50000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
}

.hover\:from-orange-50:hover {
  --tw-gradient-from: #FFF3E0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
}

.hover\:from-orange-100:hover {
  --tw-gradient-from: #FFE0B2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
}

.hover\:from-orange-200:hover {
  --tw-gradient-from: #FFCC80;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
}

.hover\:from-orange-300:hover {
  --tw-gradient-from: #FFB74D;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
}

.hover\:from-orange-400:hover {
  --tw-gradient-from: #FFA726;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
}

.hover\:from-orange-500:hover {
  --tw-gradient-from: #FF9800;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
}

.hover\:from-orange-600:hover {
  --tw-gradient-from: #FB8C00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
}

.hover\:from-orange-700:hover {
  --tw-gradient-from: #F57C00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
}

.hover\:from-orange-800:hover {
  --tw-gradient-from: #EF6C00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
}

.hover\:from-orange-900:hover {
  --tw-gradient-from: #E65100;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
}

.hover\:from-orange:hover {
  --tw-gradient-from: #FF9800;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
}

.hover\:from-orange-A100:hover {
  --tw-gradient-from: #FFD180;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
}

.hover\:from-orange-A200:hover {
  --tw-gradient-from: #FFAB40;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
}

.hover\:from-orange-A400:hover {
  --tw-gradient-from: #FF9100;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
}

.hover\:from-orange-A700:hover {
  --tw-gradient-from: #FF6D00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
}

.hover\:from-deep-orange-50:hover {
  --tw-gradient-from: #FBE9E7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
}

.hover\:from-deep-orange-100:hover {
  --tw-gradient-from: #FFCCBC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
}

.hover\:from-deep-orange-200:hover {
  --tw-gradient-from: #FFAB91;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
}

.hover\:from-deep-orange-300:hover {
  --tw-gradient-from: #FF8A65;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
}

.hover\:from-deep-orange-400:hover {
  --tw-gradient-from: #FF7043;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
}

.hover\:from-deep-orange-500:hover {
  --tw-gradient-from: #FF5722;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
}

.hover\:from-deep-orange-600:hover {
  --tw-gradient-from: #F4511E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
}

.hover\:from-deep-orange-700:hover {
  --tw-gradient-from: #E64A19;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
}

.hover\:from-deep-orange-800:hover {
  --tw-gradient-from: #D84315;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
}

.hover\:from-deep-orange-900:hover {
  --tw-gradient-from: #BF360C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
}

.hover\:from-deep-orange:hover {
  --tw-gradient-from: #FF5722;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
}

.hover\:from-deep-orange-A100:hover {
  --tw-gradient-from: #FF9E80;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
}

.hover\:from-deep-orange-A200:hover {
  --tw-gradient-from: #FF6E40;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
}

.hover\:from-deep-orange-A400:hover {
  --tw-gradient-from: #FF3D00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
}

.hover\:from-deep-orange-A700:hover {
  --tw-gradient-from: #DD2C00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
}

.hover\:from-yellow-50:hover {
  --tw-gradient-from: #FFFDE7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
}

.hover\:from-yellow-100:hover {
  --tw-gradient-from: #FFF9C4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
}

.hover\:from-yellow-200:hover {
  --tw-gradient-from: #FFF59D;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
}

.hover\:from-yellow-300:hover {
  --tw-gradient-from: #FFF176;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
}

.hover\:from-yellow-400:hover {
  --tw-gradient-from: #FFEE58;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
}

.hover\:from-yellow-500:hover {
  --tw-gradient-from: #FFEB3B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
}

.hover\:from-yellow-600:hover {
  --tw-gradient-from: #FDD835;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
}

.hover\:from-yellow-700:hover {
  --tw-gradient-from: #FBC02D;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
}

.hover\:from-yellow-800:hover {
  --tw-gradient-from: #F9A825;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
}

.hover\:from-yellow-900:hover {
  --tw-gradient-from: #F57F17;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
}

.hover\:from-yellow:hover {
  --tw-gradient-from: #FFEB3B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
}

.hover\:from-yellow-A100:hover {
  --tw-gradient-from: #FFFF8D;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
}

.hover\:from-yellow-A200:hover {
  --tw-gradient-from: #FFFF00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
}

.hover\:from-yellow-A400:hover {
  --tw-gradient-from: #FFEA00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
}

.hover\:from-yellow-A700:hover {
  --tw-gradient-from: #FFD600;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
}

.hover\:from-green-50:hover {
  --tw-gradient-from: #E8F5E9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
}

.hover\:from-green-100:hover {
  --tw-gradient-from: #C8E6C9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
}

.hover\:from-green-200:hover {
  --tw-gradient-from: #A5D6A7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
}

.hover\:from-green-300:hover {
  --tw-gradient-from: #81C784;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
}

.hover\:from-green-400:hover {
  --tw-gradient-from: #66BB6A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
}

.hover\:from-green-500:hover {
  --tw-gradient-from: #4CAF50;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
}

.hover\:from-green-600:hover {
  --tw-gradient-from: #43A047;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
}

.hover\:from-green-700:hover {
  --tw-gradient-from: #388E3C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
}

.hover\:from-green-800:hover {
  --tw-gradient-from: #2E7D32;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
}

.hover\:from-green-900:hover {
  --tw-gradient-from: #1B5E20;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
}

.hover\:from-green:hover {
  --tw-gradient-from: #4CAF50;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
}

.hover\:from-green-A100:hover {
  --tw-gradient-from: #B9F6CA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
}

.hover\:from-green-A200:hover {
  --tw-gradient-from: #69F0AE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
}

.hover\:from-green-A400:hover {
  --tw-gradient-from: #00E676;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
}

.hover\:from-green-A700:hover {
  --tw-gradient-from: #00C853;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
}

.hover\:from-light-green-50:hover {
  --tw-gradient-from: #F1F8E9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
}

.hover\:from-light-green-100:hover {
  --tw-gradient-from: #DCEDC8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
}

.hover\:from-light-green-200:hover {
  --tw-gradient-from: #C5E1A5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
}

.hover\:from-light-green-300:hover {
  --tw-gradient-from: #AED581;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
}

.hover\:from-light-green-400:hover {
  --tw-gradient-from: #9CCC65;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
}

.hover\:from-light-green-500:hover {
  --tw-gradient-from: #8BC34A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
}

.hover\:from-light-green-600:hover {
  --tw-gradient-from: #7CB342;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
}

.hover\:from-light-green-700:hover {
  --tw-gradient-from: #689F38;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
}

.hover\:from-light-green-800:hover {
  --tw-gradient-from: #558B2F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
}

.hover\:from-light-green-900:hover {
  --tw-gradient-from: #33691E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
}

.hover\:from-light-green:hover {
  --tw-gradient-from: #8BC34A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
}

.hover\:from-light-green-A100:hover {
  --tw-gradient-from: #CCFF90;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
}

.hover\:from-light-green-A200:hover {
  --tw-gradient-from: #B2FF59;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
}

.hover\:from-light-green-A400:hover {
  --tw-gradient-from: #76FF03;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
}

.hover\:from-light-green-A700:hover {
  --tw-gradient-from: #64DD17;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
}

.hover\:from-teal-50:hover {
  --tw-gradient-from: #E0F2F1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
}

.hover\:from-teal-100:hover {
  --tw-gradient-from: #B2DFDB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
}

.hover\:from-teal-200:hover {
  --tw-gradient-from: #80CBC4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
}

.hover\:from-teal-300:hover {
  --tw-gradient-from: #4DB6AC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
}

.hover\:from-teal-400:hover {
  --tw-gradient-from: #26A69A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
}

.hover\:from-teal-500:hover {
  --tw-gradient-from: #009688;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
}

.hover\:from-teal-600:hover {
  --tw-gradient-from: #00897B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
}

.hover\:from-teal-700:hover {
  --tw-gradient-from: #00796B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
}

.hover\:from-teal-800:hover {
  --tw-gradient-from: #00695C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
}

.hover\:from-teal-900:hover {
  --tw-gradient-from: #004D40;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
}

.hover\:from-teal:hover {
  --tw-gradient-from: #009688;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
}

.hover\:from-teal-A100:hover {
  --tw-gradient-from: #A7FFEB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
}

.hover\:from-teal-A200:hover {
  --tw-gradient-from: #64FFDA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
}

.hover\:from-teal-A400:hover {
  --tw-gradient-from: #1DE9B6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
}

.hover\:from-teal-A700:hover {
  --tw-gradient-from: #00BFA5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
}

.hover\:from-blue-50:hover {
  --tw-gradient-from: #E3F2FD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
}

.hover\:from-blue-100:hover {
  --tw-gradient-from: #BBDEFB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
}

.hover\:from-blue-200:hover {
  --tw-gradient-from: #90CAF9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
}

.hover\:from-blue-300:hover {
  --tw-gradient-from: #64B5F6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
}

.hover\:from-blue-400:hover {
  --tw-gradient-from: #42A5F5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
}

.hover\:from-blue-500:hover {
  --tw-gradient-from: #2196F3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
}

.hover\:from-blue-600:hover {
  --tw-gradient-from: #1E88E5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
}

.hover\:from-blue-700:hover {
  --tw-gradient-from: #1976D2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
}

.hover\:from-blue-800:hover {
  --tw-gradient-from: #1565C0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
}

.hover\:from-blue-900:hover {
  --tw-gradient-from: #0D47A1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
}

.hover\:from-blue:hover {
  --tw-gradient-from: #2196F3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
}

.hover\:from-blue-A100:hover {
  --tw-gradient-from: #82B1FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
}

.hover\:from-blue-A200:hover {
  --tw-gradient-from: #448AFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
}

.hover\:from-blue-A400:hover {
  --tw-gradient-from: #2979FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
}

.hover\:from-blue-A700:hover {
  --tw-gradient-from: #2962FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
}

.hover\:from-light-blue-50:hover {
  --tw-gradient-from: #E1F5FE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
}

.hover\:from-light-blue-100:hover {
  --tw-gradient-from: #B3E5FC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
}

.hover\:from-light-blue-200:hover {
  --tw-gradient-from: #81D4FA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
}

.hover\:from-light-blue-300:hover {
  --tw-gradient-from: #4FC3F7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
}

.hover\:from-light-blue-400:hover {
  --tw-gradient-from: #29B6F6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
}

.hover\:from-light-blue-500:hover {
  --tw-gradient-from: #03A9F4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
}

.hover\:from-light-blue-600:hover {
  --tw-gradient-from: #039BE5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
}

.hover\:from-light-blue-700:hover {
  --tw-gradient-from: #0288D1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
}

.hover\:from-light-blue-800:hover {
  --tw-gradient-from: #0277BD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
}

.hover\:from-light-blue-900:hover {
  --tw-gradient-from: #01579B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
}

.hover\:from-light-blue:hover {
  --tw-gradient-from: #03A9F4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
}

.hover\:from-light-blue-A100:hover {
  --tw-gradient-from: #80D8FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
}

.hover\:from-light-blue-A200:hover {
  --tw-gradient-from: #40C4FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
}

.hover\:from-light-blue-A400:hover {
  --tw-gradient-from: #00B0FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
}

.hover\:from-light-blue-A700:hover {
  --tw-gradient-from: #0091EA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
}

.hover\:from-indigo-50:hover {
  --tw-gradient-from: #E8EAF6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
}

.hover\:from-indigo-100:hover {
  --tw-gradient-from: #C5CAE9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
}

.hover\:from-indigo-200:hover {
  --tw-gradient-from: #9FA8DA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
}

.hover\:from-indigo-300:hover {
  --tw-gradient-from: #7986CB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
}

.hover\:from-indigo-400:hover {
  --tw-gradient-from: #5C6BC0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
}

.hover\:from-indigo-500:hover {
  --tw-gradient-from: #3F51B5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
}

.hover\:from-indigo-600:hover {
  --tw-gradient-from: #3949AB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
}

.hover\:from-indigo-700:hover {
  --tw-gradient-from: #303F9F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
}

.hover\:from-indigo-800:hover {
  --tw-gradient-from: #283593;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
}

.hover\:from-indigo-900:hover {
  --tw-gradient-from: #1A237E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
}

.hover\:from-indigo:hover {
  --tw-gradient-from: #3F51B5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
}

.hover\:from-indigo-A100:hover {
  --tw-gradient-from: #8C9EFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
}

.hover\:from-indigo-A200:hover {
  --tw-gradient-from: #536DFE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
}

.hover\:from-indigo-A400:hover {
  --tw-gradient-from: #3D5AFE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
}

.hover\:from-indigo-A700:hover {
  --tw-gradient-from: #304FFE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
}

.hover\:from-purple-50:hover {
  --tw-gradient-from: #F3E5F5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
}

.hover\:from-purple-100:hover {
  --tw-gradient-from: #E1BEE7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
}

.hover\:from-purple-200:hover {
  --tw-gradient-from: #CE93D8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
}

.hover\:from-purple-300:hover {
  --tw-gradient-from: #BA68C8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
}

.hover\:from-purple-400:hover {
  --tw-gradient-from: #AB47BC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
}

.hover\:from-purple-500:hover {
  --tw-gradient-from: #9C27B0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
}

.hover\:from-purple-600:hover {
  --tw-gradient-from: #8E24AA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
}

.hover\:from-purple-700:hover {
  --tw-gradient-from: #7B1FA2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
}

.hover\:from-purple-800:hover {
  --tw-gradient-from: #6A1B9A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
}

.hover\:from-purple-900:hover {
  --tw-gradient-from: #4A148C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
}

.hover\:from-purple:hover {
  --tw-gradient-from: #9C27B0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
}

.hover\:from-purple-A100:hover {
  --tw-gradient-from: #EA80FC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
}

.hover\:from-purple-A200:hover {
  --tw-gradient-from: #E040FB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
}

.hover\:from-purple-A400:hover {
  --tw-gradient-from: #D500F9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
}

.hover\:from-purple-A700:hover {
  --tw-gradient-from: #AA00FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
}

.hover\:from-deep-purple-50:hover {
  --tw-gradient-from: #EDE7F6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
}

.hover\:from-deep-purple-100:hover {
  --tw-gradient-from: #D1C4E9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
}

.hover\:from-deep-purple-200:hover {
  --tw-gradient-from: #B39DDB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
}

.hover\:from-deep-purple-300:hover {
  --tw-gradient-from: #9575CD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
}

.hover\:from-deep-purple-400:hover {
  --tw-gradient-from: #7E57C2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
}

.hover\:from-deep-purple-500:hover {
  --tw-gradient-from: #673AB7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
}

.hover\:from-deep-purple-600:hover {
  --tw-gradient-from: #5E35B1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
}

.hover\:from-deep-purple-700:hover {
  --tw-gradient-from: #512DA8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
}

.hover\:from-deep-purple-800:hover {
  --tw-gradient-from: #4527A0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
}

.hover\:from-deep-purple-900:hover {
  --tw-gradient-from: #311B92;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
}

.hover\:from-deep-purple:hover {
  --tw-gradient-from: #673AB7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
}

.hover\:from-deep-purple-A100:hover {
  --tw-gradient-from: #B388FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
}

.hover\:from-deep-purple-A200:hover {
  --tw-gradient-from: #7C4DFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
}

.hover\:from-deep-purple-A400:hover {
  --tw-gradient-from: #651FFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
}

.hover\:from-deep-purple-A700:hover {
  --tw-gradient-from: #6200EA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
}

.hover\:from-pink-50:hover {
  --tw-gradient-from: #FCE4EC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
}

.hover\:from-pink-100:hover {
  --tw-gradient-from: #F8BBD0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
}

.hover\:from-pink-200:hover {
  --tw-gradient-from: #F48FB1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
}

.hover\:from-pink-300:hover {
  --tw-gradient-from: #F06292;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
}

.hover\:from-pink-400:hover {
  --tw-gradient-from: #EC407A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
}

.hover\:from-pink-500:hover {
  --tw-gradient-from: #E91E63;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
}

.hover\:from-pink-600:hover {
  --tw-gradient-from: #D81B60;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
}

.hover\:from-pink-700:hover {
  --tw-gradient-from: #C2185B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
}

.hover\:from-pink-800:hover {
  --tw-gradient-from: #AD1457;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
}

.hover\:from-pink-900:hover {
  --tw-gradient-from: #880E4F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
}

.hover\:from-pink:hover {
  --tw-gradient-from: #E91E63;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
}

.hover\:from-pink-A100:hover {
  --tw-gradient-from: #FF80AB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
}

.hover\:from-pink-A200:hover {
  --tw-gradient-from: #FF4081;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
}

.hover\:from-pink-A400:hover {
  --tw-gradient-from: #F50057;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
}

.hover\:from-pink-A700:hover {
  --tw-gradient-from: #C51162;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
}

.hover\:from-lime-50:hover {
  --tw-gradient-from: #F9FBE7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
}

.hover\:from-lime-100:hover {
  --tw-gradient-from: #F0F4C3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
}

.hover\:from-lime-200:hover {
  --tw-gradient-from: #E6EE9C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
}

.hover\:from-lime-300:hover {
  --tw-gradient-from: #DCE775;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
}

.hover\:from-lime-400:hover {
  --tw-gradient-from: #D4E157;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
}

.hover\:from-lime-500:hover {
  --tw-gradient-from: #CDDC39;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
}

.hover\:from-lime-600:hover {
  --tw-gradient-from: #C0CA33;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
}

.hover\:from-lime-700:hover {
  --tw-gradient-from: #AFB42B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
}

.hover\:from-lime-800:hover {
  --tw-gradient-from: #9E9D24;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
}

.hover\:from-lime-900:hover {
  --tw-gradient-from: #827717;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
}

.hover\:from-lime:hover {
  --tw-gradient-from: #CDDC39;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
}

.hover\:from-lime-A100:hover {
  --tw-gradient-from: #F4FF81;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
}

.hover\:from-lime-A200:hover {
  --tw-gradient-from: #EEFF41;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
}

.hover\:from-lime-A400:hover {
  --tw-gradient-from: #C6FF00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
}

.hover\:from-lime-A700:hover {
  --tw-gradient-from: #AEEA00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
}

.hover\:from-amber-50:hover {
  --tw-gradient-from: #FFF8E1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
}

.hover\:from-amber-100:hover {
  --tw-gradient-from: #FFECB3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
}

.hover\:from-amber-200:hover {
  --tw-gradient-from: #FFE082;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
}

.hover\:from-amber-300:hover {
  --tw-gradient-from: #FFD54F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
}

.hover\:from-amber-400:hover {
  --tw-gradient-from: #FFCA28;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
}

.hover\:from-amber-500:hover {
  --tw-gradient-from: #FFC107;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
}

.hover\:from-amber-600:hover {
  --tw-gradient-from: #FFB300;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
}

.hover\:from-amber-700:hover {
  --tw-gradient-from: #FFA000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
}

.hover\:from-amber-800:hover {
  --tw-gradient-from: #FF8F00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
}

.hover\:from-amber-900:hover {
  --tw-gradient-from: #FF6F00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
}

.hover\:from-amber:hover {
  --tw-gradient-from: #FFC107;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
}

.hover\:from-amber-A100:hover {
  --tw-gradient-from: #FFE57F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
}

.hover\:from-amber-A200:hover {
  --tw-gradient-from: #FFD740;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
}

.hover\:from-amber-A400:hover {
  --tw-gradient-from: #FFC400;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
}

.hover\:from-amber-A700:hover {
  --tw-gradient-from: #FFAB00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
}

.hover\:from-brown-50:hover {
  --tw-gradient-from: #EFEBE9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
}

.hover\:from-brown-100:hover {
  --tw-gradient-from: #D7CCC8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
}

.hover\:from-brown-200:hover {
  --tw-gradient-from: #BCAAA4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
}

.hover\:from-brown-300:hover {
  --tw-gradient-from: #A1887F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
}

.hover\:from-brown-400:hover {
  --tw-gradient-from: #8D6E63;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
}

.hover\:from-brown-500:hover {
  --tw-gradient-from: #795548;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
}

.hover\:from-brown-600:hover {
  --tw-gradient-from: #6D4C41;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
}

.hover\:from-brown-700:hover {
  --tw-gradient-from: #5D4037;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
}

.hover\:from-brown-800:hover {
  --tw-gradient-from: #4E342E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
}

.hover\:from-brown-900:hover {
  --tw-gradient-from: #3E2723;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
}

.hover\:from-brown:hover {
  --tw-gradient-from: #795548;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
}

.hover\:from-brown-A100:hover {
  --tw-gradient-from: #D7CCC8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
}

.hover\:from-brown-A200:hover {
  --tw-gradient-from: #BCAAA4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
}

.hover\:from-brown-A400:hover {
  --tw-gradient-from: #8D6E63;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
}

.hover\:from-brown-A700:hover {
  --tw-gradient-from: #5D4037;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
}

.hover\:from-blue-gray-50:hover {
  --tw-gradient-from: #ECEFF1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
}

.hover\:from-blue-gray-100:hover {
  --tw-gradient-from: #CFD8DC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
}

.hover\:from-blue-gray-200:hover {
  --tw-gradient-from: #B0BEC5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
}

.hover\:from-blue-gray-300:hover {
  --tw-gradient-from: #90A4AE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
}

.hover\:from-blue-gray-400:hover {
  --tw-gradient-from: #78909C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
}

.hover\:from-blue-gray-500:hover {
  --tw-gradient-from: #607D8B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
}

.hover\:from-blue-gray-600:hover {
  --tw-gradient-from: #546E7A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
}

.hover\:from-blue-gray-700:hover {
  --tw-gradient-from: #455A64;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
}

.hover\:from-blue-gray-800:hover {
  --tw-gradient-from: #37474F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
}

.hover\:from-blue-gray-900:hover {
  --tw-gradient-from: #263238;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
}

.hover\:from-blue-gray:hover {
  --tw-gradient-from: #607D8B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
}

.hover\:from-blue-gray-A100:hover {
  --tw-gradient-from: #CFD8DC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
}

.hover\:from-blue-gray-A200:hover {
  --tw-gradient-from: #B0BEC5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
}

.hover\:from-blue-gray-A400:hover {
  --tw-gradient-from: #78909C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
}

.hover\:from-blue-gray-A700:hover {
  --tw-gradient-from: #455A64;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
}

.hover\:from-cyan-50:hover {
  --tw-gradient-from: #E0F7FA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
}

.hover\:from-cyan-100:hover {
  --tw-gradient-from: #B2EBF2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
}

.hover\:from-cyan-200:hover {
  --tw-gradient-from: #80DEEA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
}

.hover\:from-cyan-300:hover {
  --tw-gradient-from: #4DD0E1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
}

.hover\:from-cyan-400:hover {
  --tw-gradient-from: #26C6DA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
}

.hover\:from-cyan-500:hover {
  --tw-gradient-from: #00BCD4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
}

.hover\:from-cyan-600:hover {
  --tw-gradient-from: #00ACC1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
}

.hover\:from-cyan-700:hover {
  --tw-gradient-from: #0097A7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
}

.hover\:from-cyan-800:hover {
  --tw-gradient-from: #00838F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
}

.hover\:from-cyan-900:hover {
  --tw-gradient-from: #006064;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
}

.hover\:from-cyan:hover {
  --tw-gradient-from: #00BCD4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
}

.hover\:from-cyan-A100:hover {
  --tw-gradient-from: #84FFFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
}

.hover\:from-cyan-A200:hover {
  --tw-gradient-from: #18FFFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
}

.hover\:from-cyan-A400:hover {
  --tw-gradient-from: #00E5FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
}

.hover\:from-cyan-A700:hover {
  --tw-gradient-from: #00B8D4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
}

.hover\:via-transparent:hover {
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.hover\:via-current:hover {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.hover\:via-black:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
}

.hover\:via-white:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.hover\:via-grey-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
}

.hover\:via-grey-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
}

.hover\:via-grey-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
}

.hover\:via-grey-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
}

.hover\:via-grey-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
}

.hover\:via-grey-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.hover\:via-grey-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
}

.hover\:via-grey-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.hover\:via-grey-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
}

.hover\:via-grey-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
}

.hover\:via-grey:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.hover\:via-grey-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
}

.hover\:via-grey-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
}

.hover\:via-grey-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
}

.hover\:via-grey-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.hover\:via-gray-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
}

.hover\:via-gray-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
}

.hover\:via-gray-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
}

.hover\:via-gray-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
}

.hover\:via-gray-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
}

.hover\:via-gray-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.hover\:via-gray-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
}

.hover\:via-gray-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.hover\:via-gray-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
}

.hover\:via-gray-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
}

.hover\:via-gray:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.hover\:via-gray-hover:hover {
  --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.hover\:via-gray-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
}

.hover\:via-gray-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
}

.hover\:via-gray-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
}

.hover\:via-gray-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.hover\:via-red-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
}

.hover\:via-red-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
}

.hover\:via-red-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
}

.hover\:via-red-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
}

.hover\:via-red-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
}

.hover\:via-red-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
}

.hover\:via-red-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
}

.hover\:via-red-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
}

.hover\:via-red-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
}

.hover\:via-red-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
}

.hover\:via-red:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
}

.hover\:via-red-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
}

.hover\:via-red-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
}

.hover\:via-red-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
}

.hover\:via-red-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
}

.hover\:via-orange-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
}

.hover\:via-orange-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
}

.hover\:via-orange-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
}

.hover\:via-orange-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
}

.hover\:via-orange-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
}

.hover\:via-orange-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
}

.hover\:via-orange-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
}

.hover\:via-orange-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
}

.hover\:via-orange-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
}

.hover\:via-orange-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
}

.hover\:via-orange:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
}

.hover\:via-orange-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
}

.hover\:via-orange-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
}

.hover\:via-orange-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
}

.hover\:via-orange-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
}

.hover\:via-deep-orange-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
}

.hover\:via-deep-orange-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
}

.hover\:via-deep-orange-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
}

.hover\:via-deep-orange-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
}

.hover\:via-deep-orange-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
}

.hover\:via-deep-orange-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
}

.hover\:via-deep-orange-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
}

.hover\:via-deep-orange-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
}

.hover\:via-deep-orange-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
}

.hover\:via-deep-orange-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
}

.hover\:via-deep-orange:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
}

.hover\:via-deep-orange-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
}

.hover\:via-deep-orange-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
}

.hover\:via-deep-orange-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
}

.hover\:via-deep-orange-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
}

.hover\:via-yellow-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
}

.hover\:via-yellow-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
}

.hover\:via-yellow-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
}

.hover\:via-yellow-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
}

.hover\:via-yellow-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
}

.hover\:via-yellow-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
}

.hover\:via-yellow-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
}

.hover\:via-yellow-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
}

.hover\:via-yellow-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
}

.hover\:via-yellow-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
}

.hover\:via-yellow:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
}

.hover\:via-yellow-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
}

.hover\:via-yellow-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
}

.hover\:via-yellow-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
}

.hover\:via-yellow-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
}

.hover\:via-green-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
}

.hover\:via-green-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
}

.hover\:via-green-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
}

.hover\:via-green-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
}

.hover\:via-green-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
}

.hover\:via-green-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
}

.hover\:via-green-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
}

.hover\:via-green-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
}

.hover\:via-green-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
}

.hover\:via-green-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
}

.hover\:via-green:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
}

.hover\:via-green-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
}

.hover\:via-green-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
}

.hover\:via-green-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
}

.hover\:via-green-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
}

.hover\:via-light-green-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
}

.hover\:via-light-green-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
}

.hover\:via-light-green-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
}

.hover\:via-light-green-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
}

.hover\:via-light-green-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
}

.hover\:via-light-green-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
}

.hover\:via-light-green-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
}

.hover\:via-light-green-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
}

.hover\:via-light-green-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
}

.hover\:via-light-green-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
}

.hover\:via-light-green:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
}

.hover\:via-light-green-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
}

.hover\:via-light-green-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
}

.hover\:via-light-green-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
}

.hover\:via-light-green-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
}

.hover\:via-teal-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
}

.hover\:via-teal-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
}

.hover\:via-teal-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
}

.hover\:via-teal-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
}

.hover\:via-teal-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
}

.hover\:via-teal-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
}

.hover\:via-teal-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
}

.hover\:via-teal-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
}

.hover\:via-teal-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
}

.hover\:via-teal-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
}

.hover\:via-teal:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
}

.hover\:via-teal-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
}

.hover\:via-teal-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
}

.hover\:via-teal-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
}

.hover\:via-teal-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
}

.hover\:via-blue-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
}

.hover\:via-blue-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
}

.hover\:via-blue-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
}

.hover\:via-blue-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
}

.hover\:via-blue-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
}

.hover\:via-blue-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
}

.hover\:via-blue-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
}

.hover\:via-blue-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
}

.hover\:via-blue-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
}

.hover\:via-blue-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
}

.hover\:via-blue:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
}

.hover\:via-blue-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
}

.hover\:via-blue-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
}

.hover\:via-blue-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
}

.hover\:via-blue-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
}

.hover\:via-light-blue-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
}

.hover\:via-light-blue-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
}

.hover\:via-light-blue-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
}

.hover\:via-light-blue-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
}

.hover\:via-light-blue-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
}

.hover\:via-light-blue-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
}

.hover\:via-light-blue-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
}

.hover\:via-light-blue-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
}

.hover\:via-light-blue-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
}

.hover\:via-light-blue-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
}

.hover\:via-light-blue:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
}

.hover\:via-light-blue-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
}

.hover\:via-light-blue-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
}

.hover\:via-light-blue-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
}

.hover\:via-light-blue-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
}

.hover\:via-indigo-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
}

.hover\:via-indigo-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
}

.hover\:via-indigo-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
}

.hover\:via-indigo-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
}

.hover\:via-indigo-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
}

.hover\:via-indigo-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
}

.hover\:via-indigo-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
}

.hover\:via-indigo-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
}

.hover\:via-indigo-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
}

.hover\:via-indigo-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
}

.hover\:via-indigo:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
}

.hover\:via-indigo-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
}

.hover\:via-indigo-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
}

.hover\:via-indigo-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
}

.hover\:via-indigo-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
}

.hover\:via-purple-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
}

.hover\:via-purple-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
}

.hover\:via-purple-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
}

.hover\:via-purple-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
}

.hover\:via-purple-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
}

.hover\:via-purple-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
}

.hover\:via-purple-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
}

.hover\:via-purple-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
}

.hover\:via-purple-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
}

.hover\:via-purple-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
}

.hover\:via-purple:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
}

.hover\:via-purple-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
}

.hover\:via-purple-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
}

.hover\:via-purple-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
}

.hover\:via-purple-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
}

.hover\:via-deep-purple-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
}

.hover\:via-deep-purple-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
}

.hover\:via-deep-purple-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
}

.hover\:via-deep-purple-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
}

.hover\:via-deep-purple-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
}

.hover\:via-deep-purple-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
}

.hover\:via-deep-purple-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
}

.hover\:via-deep-purple-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
}

.hover\:via-deep-purple-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
}

.hover\:via-deep-purple-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
}

.hover\:via-deep-purple:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
}

.hover\:via-deep-purple-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
}

.hover\:via-deep-purple-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
}

.hover\:via-deep-purple-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
}

.hover\:via-deep-purple-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
}

.hover\:via-pink-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
}

.hover\:via-pink-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
}

.hover\:via-pink-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
}

.hover\:via-pink-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
}

.hover\:via-pink-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
}

.hover\:via-pink-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
}

.hover\:via-pink-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
}

.hover\:via-pink-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
}

.hover\:via-pink-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
}

.hover\:via-pink-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
}

.hover\:via-pink:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
}

.hover\:via-pink-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
}

.hover\:via-pink-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
}

.hover\:via-pink-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
}

.hover\:via-pink-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
}

.hover\:via-lime-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
}

.hover\:via-lime-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
}

.hover\:via-lime-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
}

.hover\:via-lime-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
}

.hover\:via-lime-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
}

.hover\:via-lime-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
}

.hover\:via-lime-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
}

.hover\:via-lime-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
}

.hover\:via-lime-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
}

.hover\:via-lime-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
}

.hover\:via-lime:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
}

.hover\:via-lime-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
}

.hover\:via-lime-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
}

.hover\:via-lime-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
}

.hover\:via-lime-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
}

.hover\:via-amber-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
}

.hover\:via-amber-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
}

.hover\:via-amber-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
}

.hover\:via-amber-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
}

.hover\:via-amber-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
}

.hover\:via-amber-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
}

.hover\:via-amber-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
}

.hover\:via-amber-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
}

.hover\:via-amber-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
}

.hover\:via-amber-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
}

.hover\:via-amber:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
}

.hover\:via-amber-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
}

.hover\:via-amber-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
}

.hover\:via-amber-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
}

.hover\:via-amber-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
}

.hover\:via-brown-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
}

.hover\:via-brown-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
}

.hover\:via-brown-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
}

.hover\:via-brown-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
}

.hover\:via-brown-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
}

.hover\:via-brown-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
}

.hover\:via-brown-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
}

.hover\:via-brown-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
}

.hover\:via-brown-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
}

.hover\:via-brown-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
}

.hover\:via-brown:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
}

.hover\:via-brown-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
}

.hover\:via-brown-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
}

.hover\:via-brown-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
}

.hover\:via-brown-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
}

.hover\:via-blue-gray-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
}

.hover\:via-blue-gray-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
}

.hover\:via-blue-gray-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
}

.hover\:via-blue-gray-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
}

.hover\:via-blue-gray-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
}

.hover\:via-blue-gray-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
}

.hover\:via-blue-gray-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
}

.hover\:via-blue-gray-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
}

.hover\:via-blue-gray-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
}

.hover\:via-blue-gray-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
}

.hover\:via-blue-gray:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
}

.hover\:via-blue-gray-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
}

.hover\:via-blue-gray-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
}

.hover\:via-blue-gray-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
}

.hover\:via-blue-gray-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
}

.hover\:via-cyan-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
}

.hover\:via-cyan-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
}

.hover\:via-cyan-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
}

.hover\:via-cyan-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
}

.hover\:via-cyan-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
}

.hover\:via-cyan-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
}

.hover\:via-cyan-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
}

.hover\:via-cyan-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
}

.hover\:via-cyan-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
}

.hover\:via-cyan-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
}

.hover\:via-cyan:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
}

.hover\:via-cyan-A100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
}

.hover\:via-cyan-A200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
}

.hover\:via-cyan-A400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
}

.hover\:via-cyan-A700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
}

.hover\:to-transparent:hover {
  --tw-gradient-to: transparent
}

.hover\:to-current:hover {
  --tw-gradient-to: currentColor
}

.hover\:to-black:hover {
  --tw-gradient-to: #22292F
}

.hover\:to-white:hover {
  --tw-gradient-to: #fff
}

.hover\:to-grey-50:hover {
  --tw-gradient-to: #FAFAFA
}

.hover\:to-grey-100:hover {
  --tw-gradient-to: #F5F5F5
}

.hover\:to-grey-200:hover {
  --tw-gradient-to: #EEEEEE
}

.hover\:to-grey-300:hover {
  --tw-gradient-to: #E0E0E0
}

.hover\:to-grey-400:hover {
  --tw-gradient-to: #BDBDBD
}

.hover\:to-grey-500:hover {
  --tw-gradient-to: #9E9E9E
}

.hover\:to-grey-600:hover {
  --tw-gradient-to: #757575
}

.hover\:to-grey-700:hover {
  --tw-gradient-to: #616161
}

.hover\:to-grey-800:hover {
  --tw-gradient-to: #424242
}

.hover\:to-grey-900:hover {
  --tw-gradient-to: #212121
}

.hover\:to-grey:hover {
  --tw-gradient-to: #9E9E9E
}

.hover\:to-grey-A100:hover {
  --tw-gradient-to: #D5D5D5
}

.hover\:to-grey-A200:hover {
  --tw-gradient-to: #AAAAAA
}

.hover\:to-grey-A400:hover {
  --tw-gradient-to: #303030
}

.hover\:to-grey-A700:hover {
  --tw-gradient-to: #616161
}

.hover\:to-gray-50:hover {
  --tw-gradient-to: #FAFAFA
}

.hover\:to-gray-100:hover {
  --tw-gradient-to: #F5F5F5
}

.hover\:to-gray-200:hover {
  --tw-gradient-to: #EEEEEE
}

.hover\:to-gray-300:hover {
  --tw-gradient-to: #E0E0E0
}

.hover\:to-gray-400:hover {
  --tw-gradient-to: #BDBDBD
}

.hover\:to-gray-500:hover {
  --tw-gradient-to: #9E9E9E
}

.hover\:to-gray-600:hover {
  --tw-gradient-to: #757575
}

.hover\:to-gray-700:hover {
  --tw-gradient-to: #616161
}

.hover\:to-gray-800:hover {
  --tw-gradient-to: #424242
}

.hover\:to-gray-900:hover {
  --tw-gradient-to: #212121
}

.hover\:to-gray:hover {
  --tw-gradient-to: #9E9E9E
}

.hover\:to-gray-hover:hover {
  --tw-gradient-to: rgba(0, 0, 0, 0.04)
}

.hover\:to-gray-A100:hover {
  --tw-gradient-to: #D5D5D5
}

.hover\:to-gray-A200:hover {
  --tw-gradient-to: #AAAAAA
}

.hover\:to-gray-A400:hover {
  --tw-gradient-to: #303030
}

.hover\:to-gray-A700:hover {
  --tw-gradient-to: #616161
}

.hover\:to-red-50:hover {
  --tw-gradient-to: #FFEBEE
}

.hover\:to-red-100:hover {
  --tw-gradient-to: #FFCDD2
}

.hover\:to-red-200:hover {
  --tw-gradient-to: #EF9A9A
}

.hover\:to-red-300:hover {
  --tw-gradient-to: #E57373
}

.hover\:to-red-400:hover {
  --tw-gradient-to: #EF5350
}

.hover\:to-red-500:hover {
  --tw-gradient-to: #F44336
}

.hover\:to-red-600:hover {
  --tw-gradient-to: #E53935
}

.hover\:to-red-700:hover {
  --tw-gradient-to: #D32F2F
}

.hover\:to-red-800:hover {
  --tw-gradient-to: #C62828
}

.hover\:to-red-900:hover {
  --tw-gradient-to: #B71C1C
}

.hover\:to-red:hover {
  --tw-gradient-to: #F44336
}

.hover\:to-red-A100:hover {
  --tw-gradient-to: #FF8A80
}

.hover\:to-red-A200:hover {
  --tw-gradient-to: #FF5252
}

.hover\:to-red-A400:hover {
  --tw-gradient-to: #FF1744
}

.hover\:to-red-A700:hover {
  --tw-gradient-to: #D50000
}

.hover\:to-orange-50:hover {
  --tw-gradient-to: #FFF3E0
}

.hover\:to-orange-100:hover {
  --tw-gradient-to: #FFE0B2
}

.hover\:to-orange-200:hover {
  --tw-gradient-to: #FFCC80
}

.hover\:to-orange-300:hover {
  --tw-gradient-to: #FFB74D
}

.hover\:to-orange-400:hover {
  --tw-gradient-to: #FFA726
}

.hover\:to-orange-500:hover {
  --tw-gradient-to: #FF9800
}

.hover\:to-orange-600:hover {
  --tw-gradient-to: #FB8C00
}

.hover\:to-orange-700:hover {
  --tw-gradient-to: #F57C00
}

.hover\:to-orange-800:hover {
  --tw-gradient-to: #EF6C00
}

.hover\:to-orange-900:hover {
  --tw-gradient-to: #E65100
}

.hover\:to-orange:hover {
  --tw-gradient-to: #FF9800
}

.hover\:to-orange-A100:hover {
  --tw-gradient-to: #FFD180
}

.hover\:to-orange-A200:hover {
  --tw-gradient-to: #FFAB40
}

.hover\:to-orange-A400:hover {
  --tw-gradient-to: #FF9100
}

.hover\:to-orange-A700:hover {
  --tw-gradient-to: #FF6D00
}

.hover\:to-deep-orange-50:hover {
  --tw-gradient-to: #FBE9E7
}

.hover\:to-deep-orange-100:hover {
  --tw-gradient-to: #FFCCBC
}

.hover\:to-deep-orange-200:hover {
  --tw-gradient-to: #FFAB91
}

.hover\:to-deep-orange-300:hover {
  --tw-gradient-to: #FF8A65
}

.hover\:to-deep-orange-400:hover {
  --tw-gradient-to: #FF7043
}

.hover\:to-deep-orange-500:hover {
  --tw-gradient-to: #FF5722
}

.hover\:to-deep-orange-600:hover {
  --tw-gradient-to: #F4511E
}

.hover\:to-deep-orange-700:hover {
  --tw-gradient-to: #E64A19
}

.hover\:to-deep-orange-800:hover {
  --tw-gradient-to: #D84315
}

.hover\:to-deep-orange-900:hover {
  --tw-gradient-to: #BF360C
}

.hover\:to-deep-orange:hover {
  --tw-gradient-to: #FF5722
}

.hover\:to-deep-orange-A100:hover {
  --tw-gradient-to: #FF9E80
}

.hover\:to-deep-orange-A200:hover {
  --tw-gradient-to: #FF6E40
}

.hover\:to-deep-orange-A400:hover {
  --tw-gradient-to: #FF3D00
}

.hover\:to-deep-orange-A700:hover {
  --tw-gradient-to: #DD2C00
}

.hover\:to-yellow-50:hover {
  --tw-gradient-to: #FFFDE7
}

.hover\:to-yellow-100:hover {
  --tw-gradient-to: #FFF9C4
}

.hover\:to-yellow-200:hover {
  --tw-gradient-to: #FFF59D
}

.hover\:to-yellow-300:hover {
  --tw-gradient-to: #FFF176
}

.hover\:to-yellow-400:hover {
  --tw-gradient-to: #FFEE58
}

.hover\:to-yellow-500:hover {
  --tw-gradient-to: #FFEB3B
}

.hover\:to-yellow-600:hover {
  --tw-gradient-to: #FDD835
}

.hover\:to-yellow-700:hover {
  --tw-gradient-to: #FBC02D
}

.hover\:to-yellow-800:hover {
  --tw-gradient-to: #F9A825
}

.hover\:to-yellow-900:hover {
  --tw-gradient-to: #F57F17
}

.hover\:to-yellow:hover {
  --tw-gradient-to: #FFEB3B
}

.hover\:to-yellow-A100:hover {
  --tw-gradient-to: #FFFF8D
}

.hover\:to-yellow-A200:hover {
  --tw-gradient-to: #FFFF00
}

.hover\:to-yellow-A400:hover {
  --tw-gradient-to: #FFEA00
}

.hover\:to-yellow-A700:hover {
  --tw-gradient-to: #FFD600
}

.hover\:to-green-50:hover {
  --tw-gradient-to: #E8F5E9
}

.hover\:to-green-100:hover {
  --tw-gradient-to: #C8E6C9
}

.hover\:to-green-200:hover {
  --tw-gradient-to: #A5D6A7
}

.hover\:to-green-300:hover {
  --tw-gradient-to: #81C784
}

.hover\:to-green-400:hover {
  --tw-gradient-to: #66BB6A
}

.hover\:to-green-500:hover {
  --tw-gradient-to: #4CAF50
}

.hover\:to-green-600:hover {
  --tw-gradient-to: #43A047
}

.hover\:to-green-700:hover {
  --tw-gradient-to: #388E3C
}

.hover\:to-green-800:hover {
  --tw-gradient-to: #2E7D32
}

.hover\:to-green-900:hover {
  --tw-gradient-to: #1B5E20
}

.hover\:to-green:hover {
  --tw-gradient-to: #4CAF50
}

.hover\:to-green-A100:hover {
  --tw-gradient-to: #B9F6CA
}

.hover\:to-green-A200:hover {
  --tw-gradient-to: #69F0AE
}

.hover\:to-green-A400:hover {
  --tw-gradient-to: #00E676
}

.hover\:to-green-A700:hover {
  --tw-gradient-to: #00C853
}

.hover\:to-light-green-50:hover {
  --tw-gradient-to: #F1F8E9
}

.hover\:to-light-green-100:hover {
  --tw-gradient-to: #DCEDC8
}

.hover\:to-light-green-200:hover {
  --tw-gradient-to: #C5E1A5
}

.hover\:to-light-green-300:hover {
  --tw-gradient-to: #AED581
}

.hover\:to-light-green-400:hover {
  --tw-gradient-to: #9CCC65
}

.hover\:to-light-green-500:hover {
  --tw-gradient-to: #8BC34A
}

.hover\:to-light-green-600:hover {
  --tw-gradient-to: #7CB342
}

.hover\:to-light-green-700:hover {
  --tw-gradient-to: #689F38
}

.hover\:to-light-green-800:hover {
  --tw-gradient-to: #558B2F
}

.hover\:to-light-green-900:hover {
  --tw-gradient-to: #33691E
}

.hover\:to-light-green:hover {
  --tw-gradient-to: #8BC34A
}

.hover\:to-light-green-A100:hover {
  --tw-gradient-to: #CCFF90
}

.hover\:to-light-green-A200:hover {
  --tw-gradient-to: #B2FF59
}

.hover\:to-light-green-A400:hover {
  --tw-gradient-to: #76FF03
}

.hover\:to-light-green-A700:hover {
  --tw-gradient-to: #64DD17
}

.hover\:to-teal-50:hover {
  --tw-gradient-to: #E0F2F1
}

.hover\:to-teal-100:hover {
  --tw-gradient-to: #B2DFDB
}

.hover\:to-teal-200:hover {
  --tw-gradient-to: #80CBC4
}

.hover\:to-teal-300:hover {
  --tw-gradient-to: #4DB6AC
}

.hover\:to-teal-400:hover {
  --tw-gradient-to: #26A69A
}

.hover\:to-teal-500:hover {
  --tw-gradient-to: #009688
}

.hover\:to-teal-600:hover {
  --tw-gradient-to: #00897B
}

.hover\:to-teal-700:hover {
  --tw-gradient-to: #00796B
}

.hover\:to-teal-800:hover {
  --tw-gradient-to: #00695C
}

.hover\:to-teal-900:hover {
  --tw-gradient-to: #004D40
}

.hover\:to-teal:hover {
  --tw-gradient-to: #009688
}

.hover\:to-teal-A100:hover {
  --tw-gradient-to: #A7FFEB
}

.hover\:to-teal-A200:hover {
  --tw-gradient-to: #64FFDA
}

.hover\:to-teal-A400:hover {
  --tw-gradient-to: #1DE9B6
}

.hover\:to-teal-A700:hover {
  --tw-gradient-to: #00BFA5
}

.hover\:to-blue-50:hover {
  --tw-gradient-to: #E3F2FD
}

.hover\:to-blue-100:hover {
  --tw-gradient-to: #BBDEFB
}

.hover\:to-blue-200:hover {
  --tw-gradient-to: #90CAF9
}

.hover\:to-blue-300:hover {
  --tw-gradient-to: #64B5F6
}

.hover\:to-blue-400:hover {
  --tw-gradient-to: #42A5F5
}

.hover\:to-blue-500:hover {
  --tw-gradient-to: #2196F3
}

.hover\:to-blue-600:hover {
  --tw-gradient-to: #1E88E5
}

.hover\:to-blue-700:hover {
  --tw-gradient-to: #1976D2
}

.hover\:to-blue-800:hover {
  --tw-gradient-to: #1565C0
}

.hover\:to-blue-900:hover {
  --tw-gradient-to: #0D47A1
}

.hover\:to-blue:hover {
  --tw-gradient-to: #2196F3
}

.hover\:to-blue-A100:hover {
  --tw-gradient-to: #82B1FF
}

.hover\:to-blue-A200:hover {
  --tw-gradient-to: #448AFF
}

.hover\:to-blue-A400:hover {
  --tw-gradient-to: #2979FF
}

.hover\:to-blue-A700:hover {
  --tw-gradient-to: #2962FF
}

.hover\:to-light-blue-50:hover {
  --tw-gradient-to: #E1F5FE
}

.hover\:to-light-blue-100:hover {
  --tw-gradient-to: #B3E5FC
}

.hover\:to-light-blue-200:hover {
  --tw-gradient-to: #81D4FA
}

.hover\:to-light-blue-300:hover {
  --tw-gradient-to: #4FC3F7
}

.hover\:to-light-blue-400:hover {
  --tw-gradient-to: #29B6F6
}

.hover\:to-light-blue-500:hover {
  --tw-gradient-to: #03A9F4
}

.hover\:to-light-blue-600:hover {
  --tw-gradient-to: #039BE5
}

.hover\:to-light-blue-700:hover {
  --tw-gradient-to: #0288D1
}

.hover\:to-light-blue-800:hover {
  --tw-gradient-to: #0277BD
}

.hover\:to-light-blue-900:hover {
  --tw-gradient-to: #01579B
}

.hover\:to-light-blue:hover {
  --tw-gradient-to: #03A9F4
}

.hover\:to-light-blue-A100:hover {
  --tw-gradient-to: #80D8FF
}

.hover\:to-light-blue-A200:hover {
  --tw-gradient-to: #40C4FF
}

.hover\:to-light-blue-A400:hover {
  --tw-gradient-to: #00B0FF
}

.hover\:to-light-blue-A700:hover {
  --tw-gradient-to: #0091EA
}

.hover\:to-indigo-50:hover {
  --tw-gradient-to: #E8EAF6
}

.hover\:to-indigo-100:hover {
  --tw-gradient-to: #C5CAE9
}

.hover\:to-indigo-200:hover {
  --tw-gradient-to: #9FA8DA
}

.hover\:to-indigo-300:hover {
  --tw-gradient-to: #7986CB
}

.hover\:to-indigo-400:hover {
  --tw-gradient-to: #5C6BC0
}

.hover\:to-indigo-500:hover {
  --tw-gradient-to: #3F51B5
}

.hover\:to-indigo-600:hover {
  --tw-gradient-to: #3949AB
}

.hover\:to-indigo-700:hover {
  --tw-gradient-to: #303F9F
}

.hover\:to-indigo-800:hover {
  --tw-gradient-to: #283593
}

.hover\:to-indigo-900:hover {
  --tw-gradient-to: #1A237E
}

.hover\:to-indigo:hover {
  --tw-gradient-to: #3F51B5
}

.hover\:to-indigo-A100:hover {
  --tw-gradient-to: #8C9EFF
}

.hover\:to-indigo-A200:hover {
  --tw-gradient-to: #536DFE
}

.hover\:to-indigo-A400:hover {
  --tw-gradient-to: #3D5AFE
}

.hover\:to-indigo-A700:hover {
  --tw-gradient-to: #304FFE
}

.hover\:to-purple-50:hover {
  --tw-gradient-to: #F3E5F5
}

.hover\:to-purple-100:hover {
  --tw-gradient-to: #E1BEE7
}

.hover\:to-purple-200:hover {
  --tw-gradient-to: #CE93D8
}

.hover\:to-purple-300:hover {
  --tw-gradient-to: #BA68C8
}

.hover\:to-purple-400:hover {
  --tw-gradient-to: #AB47BC
}

.hover\:to-purple-500:hover {
  --tw-gradient-to: #9C27B0
}

.hover\:to-purple-600:hover {
  --tw-gradient-to: #8E24AA
}

.hover\:to-purple-700:hover {
  --tw-gradient-to: #7B1FA2
}

.hover\:to-purple-800:hover {
  --tw-gradient-to: #6A1B9A
}

.hover\:to-purple-900:hover {
  --tw-gradient-to: #4A148C
}

.hover\:to-purple:hover {
  --tw-gradient-to: #9C27B0
}

.hover\:to-purple-A100:hover {
  --tw-gradient-to: #EA80FC
}

.hover\:to-purple-A200:hover {
  --tw-gradient-to: #E040FB
}

.hover\:to-purple-A400:hover {
  --tw-gradient-to: #D500F9
}

.hover\:to-purple-A700:hover {
  --tw-gradient-to: #AA00FF
}

.hover\:to-deep-purple-50:hover {
  --tw-gradient-to: #EDE7F6
}

.hover\:to-deep-purple-100:hover {
  --tw-gradient-to: #D1C4E9
}

.hover\:to-deep-purple-200:hover {
  --tw-gradient-to: #B39DDB
}

.hover\:to-deep-purple-300:hover {
  --tw-gradient-to: #9575CD
}

.hover\:to-deep-purple-400:hover {
  --tw-gradient-to: #7E57C2
}

.hover\:to-deep-purple-500:hover {
  --tw-gradient-to: #673AB7
}

.hover\:to-deep-purple-600:hover {
  --tw-gradient-to: #5E35B1
}

.hover\:to-deep-purple-700:hover {
  --tw-gradient-to: #512DA8
}

.hover\:to-deep-purple-800:hover {
  --tw-gradient-to: #4527A0
}

.hover\:to-deep-purple-900:hover {
  --tw-gradient-to: #311B92
}

.hover\:to-deep-purple:hover {
  --tw-gradient-to: #673AB7
}

.hover\:to-deep-purple-A100:hover {
  --tw-gradient-to: #B388FF
}

.hover\:to-deep-purple-A200:hover {
  --tw-gradient-to: #7C4DFF
}

.hover\:to-deep-purple-A400:hover {
  --tw-gradient-to: #651FFF
}

.hover\:to-deep-purple-A700:hover {
  --tw-gradient-to: #6200EA
}

.hover\:to-pink-50:hover {
  --tw-gradient-to: #FCE4EC
}

.hover\:to-pink-100:hover {
  --tw-gradient-to: #F8BBD0
}

.hover\:to-pink-200:hover {
  --tw-gradient-to: #F48FB1
}

.hover\:to-pink-300:hover {
  --tw-gradient-to: #F06292
}

.hover\:to-pink-400:hover {
  --tw-gradient-to: #EC407A
}

.hover\:to-pink-500:hover {
  --tw-gradient-to: #E91E63
}

.hover\:to-pink-600:hover {
  --tw-gradient-to: #D81B60
}

.hover\:to-pink-700:hover {
  --tw-gradient-to: #C2185B
}

.hover\:to-pink-800:hover {
  --tw-gradient-to: #AD1457
}

.hover\:to-pink-900:hover {
  --tw-gradient-to: #880E4F
}

.hover\:to-pink:hover {
  --tw-gradient-to: #E91E63
}

.hover\:to-pink-A100:hover {
  --tw-gradient-to: #FF80AB
}

.hover\:to-pink-A200:hover {
  --tw-gradient-to: #FF4081
}

.hover\:to-pink-A400:hover {
  --tw-gradient-to: #F50057
}

.hover\:to-pink-A700:hover {
  --tw-gradient-to: #C51162
}

.hover\:to-lime-50:hover {
  --tw-gradient-to: #F9FBE7
}

.hover\:to-lime-100:hover {
  --tw-gradient-to: #F0F4C3
}

.hover\:to-lime-200:hover {
  --tw-gradient-to: #E6EE9C
}

.hover\:to-lime-300:hover {
  --tw-gradient-to: #DCE775
}

.hover\:to-lime-400:hover {
  --tw-gradient-to: #D4E157
}

.hover\:to-lime-500:hover {
  --tw-gradient-to: #CDDC39
}

.hover\:to-lime-600:hover {
  --tw-gradient-to: #C0CA33
}

.hover\:to-lime-700:hover {
  --tw-gradient-to: #AFB42B
}

.hover\:to-lime-800:hover {
  --tw-gradient-to: #9E9D24
}

.hover\:to-lime-900:hover {
  --tw-gradient-to: #827717
}

.hover\:to-lime:hover {
  --tw-gradient-to: #CDDC39
}

.hover\:to-lime-A100:hover {
  --tw-gradient-to: #F4FF81
}

.hover\:to-lime-A200:hover {
  --tw-gradient-to: #EEFF41
}

.hover\:to-lime-A400:hover {
  --tw-gradient-to: #C6FF00
}

.hover\:to-lime-A700:hover {
  --tw-gradient-to: #AEEA00
}

.hover\:to-amber-50:hover {
  --tw-gradient-to: #FFF8E1
}

.hover\:to-amber-100:hover {
  --tw-gradient-to: #FFECB3
}

.hover\:to-amber-200:hover {
  --tw-gradient-to: #FFE082
}

.hover\:to-amber-300:hover {
  --tw-gradient-to: #FFD54F
}

.hover\:to-amber-400:hover {
  --tw-gradient-to: #FFCA28
}

.hover\:to-amber-500:hover {
  --tw-gradient-to: #FFC107
}

.hover\:to-amber-600:hover {
  --tw-gradient-to: #FFB300
}

.hover\:to-amber-700:hover {
  --tw-gradient-to: #FFA000
}

.hover\:to-amber-800:hover {
  --tw-gradient-to: #FF8F00
}

.hover\:to-amber-900:hover {
  --tw-gradient-to: #FF6F00
}

.hover\:to-amber:hover {
  --tw-gradient-to: #FFC107
}

.hover\:to-amber-A100:hover {
  --tw-gradient-to: #FFE57F
}

.hover\:to-amber-A200:hover {
  --tw-gradient-to: #FFD740
}

.hover\:to-amber-A400:hover {
  --tw-gradient-to: #FFC400
}

.hover\:to-amber-A700:hover {
  --tw-gradient-to: #FFAB00
}

.hover\:to-brown-50:hover {
  --tw-gradient-to: #EFEBE9
}

.hover\:to-brown-100:hover {
  --tw-gradient-to: #D7CCC8
}

.hover\:to-brown-200:hover {
  --tw-gradient-to: #BCAAA4
}

.hover\:to-brown-300:hover {
  --tw-gradient-to: #A1887F
}

.hover\:to-brown-400:hover {
  --tw-gradient-to: #8D6E63
}

.hover\:to-brown-500:hover {
  --tw-gradient-to: #795548
}

.hover\:to-brown-600:hover {
  --tw-gradient-to: #6D4C41
}

.hover\:to-brown-700:hover {
  --tw-gradient-to: #5D4037
}

.hover\:to-brown-800:hover {
  --tw-gradient-to: #4E342E
}

.hover\:to-brown-900:hover {
  --tw-gradient-to: #3E2723
}

.hover\:to-brown:hover {
  --tw-gradient-to: #795548
}

.hover\:to-brown-A100:hover {
  --tw-gradient-to: #D7CCC8
}

.hover\:to-brown-A200:hover {
  --tw-gradient-to: #BCAAA4
}

.hover\:to-brown-A400:hover {
  --tw-gradient-to: #8D6E63
}

.hover\:to-brown-A700:hover {
  --tw-gradient-to: #5D4037
}

.hover\:to-blue-gray-50:hover {
  --tw-gradient-to: #ECEFF1
}

.hover\:to-blue-gray-100:hover {
  --tw-gradient-to: #CFD8DC
}

.hover\:to-blue-gray-200:hover {
  --tw-gradient-to: #B0BEC5
}

.hover\:to-blue-gray-300:hover {
  --tw-gradient-to: #90A4AE
}

.hover\:to-blue-gray-400:hover {
  --tw-gradient-to: #78909C
}

.hover\:to-blue-gray-500:hover {
  --tw-gradient-to: #607D8B
}

.hover\:to-blue-gray-600:hover {
  --tw-gradient-to: #546E7A
}

.hover\:to-blue-gray-700:hover {
  --tw-gradient-to: #455A64
}

.hover\:to-blue-gray-800:hover {
  --tw-gradient-to: #37474F
}

.hover\:to-blue-gray-900:hover {
  --tw-gradient-to: #263238
}

.hover\:to-blue-gray:hover {
  --tw-gradient-to: #607D8B
}

.hover\:to-blue-gray-A100:hover {
  --tw-gradient-to: #CFD8DC
}

.hover\:to-blue-gray-A200:hover {
  --tw-gradient-to: #B0BEC5
}

.hover\:to-blue-gray-A400:hover {
  --tw-gradient-to: #78909C
}

.hover\:to-blue-gray-A700:hover {
  --tw-gradient-to: #455A64
}

.hover\:to-cyan-50:hover {
  --tw-gradient-to: #E0F7FA
}

.hover\:to-cyan-100:hover {
  --tw-gradient-to: #B2EBF2
}

.hover\:to-cyan-200:hover {
  --tw-gradient-to: #80DEEA
}

.hover\:to-cyan-300:hover {
  --tw-gradient-to: #4DD0E1
}

.hover\:to-cyan-400:hover {
  --tw-gradient-to: #26C6DA
}

.hover\:to-cyan-500:hover {
  --tw-gradient-to: #00BCD4
}

.hover\:to-cyan-600:hover {
  --tw-gradient-to: #00ACC1
}

.hover\:to-cyan-700:hover {
  --tw-gradient-to: #0097A7
}

.hover\:to-cyan-800:hover {
  --tw-gradient-to: #00838F
}

.hover\:to-cyan-900:hover {
  --tw-gradient-to: #006064
}

.hover\:to-cyan:hover {
  --tw-gradient-to: #00BCD4
}

.hover\:to-cyan-A100:hover {
  --tw-gradient-to: #84FFFF
}

.hover\:to-cyan-A200:hover {
  --tw-gradient-to: #18FFFF
}

.hover\:to-cyan-A400:hover {
  --tw-gradient-to: #00E5FF
}

.hover\:to-cyan-A700:hover {
  --tw-gradient-to: #00B8D4
}

.focus\:from-transparent:focus {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.focus\:from-current:focus {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.focus\:from-black:focus {
  --tw-gradient-from: #22292F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
}

.focus\:from-white:focus {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.focus\:from-grey-50:focus {
  --tw-gradient-from: #FAFAFA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
}

.focus\:from-grey-100:focus {
  --tw-gradient-from: #F5F5F5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
}

.focus\:from-grey-200:focus {
  --tw-gradient-from: #EEEEEE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
}

.focus\:from-grey-300:focus {
  --tw-gradient-from: #E0E0E0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
}

.focus\:from-grey-400:focus {
  --tw-gradient-from: #BDBDBD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
}

.focus\:from-grey-500:focus {
  --tw-gradient-from: #9E9E9E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.focus\:from-grey-600:focus {
  --tw-gradient-from: #757575;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
}

.focus\:from-grey-700:focus {
  --tw-gradient-from: #616161;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.focus\:from-grey-800:focus {
  --tw-gradient-from: #424242;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
}

.focus\:from-grey-900:focus {
  --tw-gradient-from: #212121;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
}

.focus\:from-grey:focus {
  --tw-gradient-from: #9E9E9E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.focus\:from-grey-A100:focus {
  --tw-gradient-from: #D5D5D5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
}

.focus\:from-grey-A200:focus {
  --tw-gradient-from: #AAAAAA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
}

.focus\:from-grey-A400:focus {
  --tw-gradient-from: #303030;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
}

.focus\:from-grey-A700:focus {
  --tw-gradient-from: #616161;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.focus\:from-gray-50:focus {
  --tw-gradient-from: #FAFAFA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
}

.focus\:from-gray-100:focus {
  --tw-gradient-from: #F5F5F5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
}

.focus\:from-gray-200:focus {
  --tw-gradient-from: #EEEEEE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
}

.focus\:from-gray-300:focus {
  --tw-gradient-from: #E0E0E0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
}

.focus\:from-gray-400:focus {
  --tw-gradient-from: #BDBDBD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
}

.focus\:from-gray-500:focus {
  --tw-gradient-from: #9E9E9E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.focus\:from-gray-600:focus {
  --tw-gradient-from: #757575;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
}

.focus\:from-gray-700:focus {
  --tw-gradient-from: #616161;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.focus\:from-gray-800:focus {
  --tw-gradient-from: #424242;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
}

.focus\:from-gray-900:focus {
  --tw-gradient-from: #212121;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
}

.focus\:from-gray:focus {
  --tw-gradient-from: #9E9E9E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.focus\:from-gray-hover:focus {
  --tw-gradient-from: rgba(0, 0, 0, 0.04);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.focus\:from-gray-A100:focus {
  --tw-gradient-from: #D5D5D5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
}

.focus\:from-gray-A200:focus {
  --tw-gradient-from: #AAAAAA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
}

.focus\:from-gray-A400:focus {
  --tw-gradient-from: #303030;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
}

.focus\:from-gray-A700:focus {
  --tw-gradient-from: #616161;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.focus\:from-red-50:focus {
  --tw-gradient-from: #FFEBEE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
}

.focus\:from-red-100:focus {
  --tw-gradient-from: #FFCDD2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
}

.focus\:from-red-200:focus {
  --tw-gradient-from: #EF9A9A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
}

.focus\:from-red-300:focus {
  --tw-gradient-from: #E57373;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
}

.focus\:from-red-400:focus {
  --tw-gradient-from: #EF5350;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
}

.focus\:from-red-500:focus {
  --tw-gradient-from: #F44336;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
}

.focus\:from-red-600:focus {
  --tw-gradient-from: #E53935;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
}

.focus\:from-red-700:focus {
  --tw-gradient-from: #D32F2F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
}

.focus\:from-red-800:focus {
  --tw-gradient-from: #C62828;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
}

.focus\:from-red-900:focus {
  --tw-gradient-from: #B71C1C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
}

.focus\:from-red:focus {
  --tw-gradient-from: #F44336;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
}

.focus\:from-red-A100:focus {
  --tw-gradient-from: #FF8A80;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
}

.focus\:from-red-A200:focus {
  --tw-gradient-from: #FF5252;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
}

.focus\:from-red-A400:focus {
  --tw-gradient-from: #FF1744;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
}

.focus\:from-red-A700:focus {
  --tw-gradient-from: #D50000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
}

.focus\:from-orange-50:focus {
  --tw-gradient-from: #FFF3E0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
}

.focus\:from-orange-100:focus {
  --tw-gradient-from: #FFE0B2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
}

.focus\:from-orange-200:focus {
  --tw-gradient-from: #FFCC80;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
}

.focus\:from-orange-300:focus {
  --tw-gradient-from: #FFB74D;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
}

.focus\:from-orange-400:focus {
  --tw-gradient-from: #FFA726;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
}

.focus\:from-orange-500:focus {
  --tw-gradient-from: #FF9800;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
}

.focus\:from-orange-600:focus {
  --tw-gradient-from: #FB8C00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
}

.focus\:from-orange-700:focus {
  --tw-gradient-from: #F57C00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
}

.focus\:from-orange-800:focus {
  --tw-gradient-from: #EF6C00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
}

.focus\:from-orange-900:focus {
  --tw-gradient-from: #E65100;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
}

.focus\:from-orange:focus {
  --tw-gradient-from: #FF9800;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
}

.focus\:from-orange-A100:focus {
  --tw-gradient-from: #FFD180;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
}

.focus\:from-orange-A200:focus {
  --tw-gradient-from: #FFAB40;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
}

.focus\:from-orange-A400:focus {
  --tw-gradient-from: #FF9100;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
}

.focus\:from-orange-A700:focus {
  --tw-gradient-from: #FF6D00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
}

.focus\:from-deep-orange-50:focus {
  --tw-gradient-from: #FBE9E7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
}

.focus\:from-deep-orange-100:focus {
  --tw-gradient-from: #FFCCBC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
}

.focus\:from-deep-orange-200:focus {
  --tw-gradient-from: #FFAB91;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
}

.focus\:from-deep-orange-300:focus {
  --tw-gradient-from: #FF8A65;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
}

.focus\:from-deep-orange-400:focus {
  --tw-gradient-from: #FF7043;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
}

.focus\:from-deep-orange-500:focus {
  --tw-gradient-from: #FF5722;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
}

.focus\:from-deep-orange-600:focus {
  --tw-gradient-from: #F4511E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
}

.focus\:from-deep-orange-700:focus {
  --tw-gradient-from: #E64A19;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
}

.focus\:from-deep-orange-800:focus {
  --tw-gradient-from: #D84315;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
}

.focus\:from-deep-orange-900:focus {
  --tw-gradient-from: #BF360C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
}

.focus\:from-deep-orange:focus {
  --tw-gradient-from: #FF5722;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
}

.focus\:from-deep-orange-A100:focus {
  --tw-gradient-from: #FF9E80;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
}

.focus\:from-deep-orange-A200:focus {
  --tw-gradient-from: #FF6E40;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
}

.focus\:from-deep-orange-A400:focus {
  --tw-gradient-from: #FF3D00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
}

.focus\:from-deep-orange-A700:focus {
  --tw-gradient-from: #DD2C00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
}

.focus\:from-yellow-50:focus {
  --tw-gradient-from: #FFFDE7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
}

.focus\:from-yellow-100:focus {
  --tw-gradient-from: #FFF9C4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
}

.focus\:from-yellow-200:focus {
  --tw-gradient-from: #FFF59D;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
}

.focus\:from-yellow-300:focus {
  --tw-gradient-from: #FFF176;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
}

.focus\:from-yellow-400:focus {
  --tw-gradient-from: #FFEE58;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
}

.focus\:from-yellow-500:focus {
  --tw-gradient-from: #FFEB3B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
}

.focus\:from-yellow-600:focus {
  --tw-gradient-from: #FDD835;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
}

.focus\:from-yellow-700:focus {
  --tw-gradient-from: #FBC02D;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
}

.focus\:from-yellow-800:focus {
  --tw-gradient-from: #F9A825;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
}

.focus\:from-yellow-900:focus {
  --tw-gradient-from: #F57F17;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
}

.focus\:from-yellow:focus {
  --tw-gradient-from: #FFEB3B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
}

.focus\:from-yellow-A100:focus {
  --tw-gradient-from: #FFFF8D;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
}

.focus\:from-yellow-A200:focus {
  --tw-gradient-from: #FFFF00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
}

.focus\:from-yellow-A400:focus {
  --tw-gradient-from: #FFEA00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
}

.focus\:from-yellow-A700:focus {
  --tw-gradient-from: #FFD600;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
}

.focus\:from-green-50:focus {
  --tw-gradient-from: #E8F5E9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
}

.focus\:from-green-100:focus {
  --tw-gradient-from: #C8E6C9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
}

.focus\:from-green-200:focus {
  --tw-gradient-from: #A5D6A7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
}

.focus\:from-green-300:focus {
  --tw-gradient-from: #81C784;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
}

.focus\:from-green-400:focus {
  --tw-gradient-from: #66BB6A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
}

.focus\:from-green-500:focus {
  --tw-gradient-from: #4CAF50;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
}

.focus\:from-green-600:focus {
  --tw-gradient-from: #43A047;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
}

.focus\:from-green-700:focus {
  --tw-gradient-from: #388E3C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
}

.focus\:from-green-800:focus {
  --tw-gradient-from: #2E7D32;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
}

.focus\:from-green-900:focus {
  --tw-gradient-from: #1B5E20;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
}

.focus\:from-green:focus {
  --tw-gradient-from: #4CAF50;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
}

.focus\:from-green-A100:focus {
  --tw-gradient-from: #B9F6CA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
}

.focus\:from-green-A200:focus {
  --tw-gradient-from: #69F0AE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
}

.focus\:from-green-A400:focus {
  --tw-gradient-from: #00E676;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
}

.focus\:from-green-A700:focus {
  --tw-gradient-from: #00C853;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
}

.focus\:from-light-green-50:focus {
  --tw-gradient-from: #F1F8E9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
}

.focus\:from-light-green-100:focus {
  --tw-gradient-from: #DCEDC8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
}

.focus\:from-light-green-200:focus {
  --tw-gradient-from: #C5E1A5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
}

.focus\:from-light-green-300:focus {
  --tw-gradient-from: #AED581;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
}

.focus\:from-light-green-400:focus {
  --tw-gradient-from: #9CCC65;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
}

.focus\:from-light-green-500:focus {
  --tw-gradient-from: #8BC34A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
}

.focus\:from-light-green-600:focus {
  --tw-gradient-from: #7CB342;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
}

.focus\:from-light-green-700:focus {
  --tw-gradient-from: #689F38;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
}

.focus\:from-light-green-800:focus {
  --tw-gradient-from: #558B2F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
}

.focus\:from-light-green-900:focus {
  --tw-gradient-from: #33691E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
}

.focus\:from-light-green:focus {
  --tw-gradient-from: #8BC34A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
}

.focus\:from-light-green-A100:focus {
  --tw-gradient-from: #CCFF90;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
}

.focus\:from-light-green-A200:focus {
  --tw-gradient-from: #B2FF59;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
}

.focus\:from-light-green-A400:focus {
  --tw-gradient-from: #76FF03;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
}

.focus\:from-light-green-A700:focus {
  --tw-gradient-from: #64DD17;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
}

.focus\:from-teal-50:focus {
  --tw-gradient-from: #E0F2F1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
}

.focus\:from-teal-100:focus {
  --tw-gradient-from: #B2DFDB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
}

.focus\:from-teal-200:focus {
  --tw-gradient-from: #80CBC4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
}

.focus\:from-teal-300:focus {
  --tw-gradient-from: #4DB6AC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
}

.focus\:from-teal-400:focus {
  --tw-gradient-from: #26A69A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
}

.focus\:from-teal-500:focus {
  --tw-gradient-from: #009688;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
}

.focus\:from-teal-600:focus {
  --tw-gradient-from: #00897B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
}

.focus\:from-teal-700:focus {
  --tw-gradient-from: #00796B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
}

.focus\:from-teal-800:focus {
  --tw-gradient-from: #00695C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
}

.focus\:from-teal-900:focus {
  --tw-gradient-from: #004D40;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
}

.focus\:from-teal:focus {
  --tw-gradient-from: #009688;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
}

.focus\:from-teal-A100:focus {
  --tw-gradient-from: #A7FFEB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
}

.focus\:from-teal-A200:focus {
  --tw-gradient-from: #64FFDA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
}

.focus\:from-teal-A400:focus {
  --tw-gradient-from: #1DE9B6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
}

.focus\:from-teal-A700:focus {
  --tw-gradient-from: #00BFA5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
}

.focus\:from-blue-50:focus {
  --tw-gradient-from: #E3F2FD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
}

.focus\:from-blue-100:focus {
  --tw-gradient-from: #BBDEFB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
}

.focus\:from-blue-200:focus {
  --tw-gradient-from: #90CAF9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
}

.focus\:from-blue-300:focus {
  --tw-gradient-from: #64B5F6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
}

.focus\:from-blue-400:focus {
  --tw-gradient-from: #42A5F5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
}

.focus\:from-blue-500:focus {
  --tw-gradient-from: #2196F3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
}

.focus\:from-blue-600:focus {
  --tw-gradient-from: #1E88E5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
}

.focus\:from-blue-700:focus {
  --tw-gradient-from: #1976D2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
}

.focus\:from-blue-800:focus {
  --tw-gradient-from: #1565C0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
}

.focus\:from-blue-900:focus {
  --tw-gradient-from: #0D47A1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
}

.focus\:from-blue:focus {
  --tw-gradient-from: #2196F3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
}

.focus\:from-blue-A100:focus {
  --tw-gradient-from: #82B1FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
}

.focus\:from-blue-A200:focus {
  --tw-gradient-from: #448AFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
}

.focus\:from-blue-A400:focus {
  --tw-gradient-from: #2979FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
}

.focus\:from-blue-A700:focus {
  --tw-gradient-from: #2962FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
}

.focus\:from-light-blue-50:focus {
  --tw-gradient-from: #E1F5FE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
}

.focus\:from-light-blue-100:focus {
  --tw-gradient-from: #B3E5FC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
}

.focus\:from-light-blue-200:focus {
  --tw-gradient-from: #81D4FA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
}

.focus\:from-light-blue-300:focus {
  --tw-gradient-from: #4FC3F7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
}

.focus\:from-light-blue-400:focus {
  --tw-gradient-from: #29B6F6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
}

.focus\:from-light-blue-500:focus {
  --tw-gradient-from: #03A9F4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
}

.focus\:from-light-blue-600:focus {
  --tw-gradient-from: #039BE5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
}

.focus\:from-light-blue-700:focus {
  --tw-gradient-from: #0288D1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
}

.focus\:from-light-blue-800:focus {
  --tw-gradient-from: #0277BD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
}

.focus\:from-light-blue-900:focus {
  --tw-gradient-from: #01579B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
}

.focus\:from-light-blue:focus {
  --tw-gradient-from: #03A9F4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
}

.focus\:from-light-blue-A100:focus {
  --tw-gradient-from: #80D8FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
}

.focus\:from-light-blue-A200:focus {
  --tw-gradient-from: #40C4FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
}

.focus\:from-light-blue-A400:focus {
  --tw-gradient-from: #00B0FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
}

.focus\:from-light-blue-A700:focus {
  --tw-gradient-from: #0091EA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
}

.focus\:from-indigo-50:focus {
  --tw-gradient-from: #E8EAF6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
}

.focus\:from-indigo-100:focus {
  --tw-gradient-from: #C5CAE9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
}

.focus\:from-indigo-200:focus {
  --tw-gradient-from: #9FA8DA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
}

.focus\:from-indigo-300:focus {
  --tw-gradient-from: #7986CB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
}

.focus\:from-indigo-400:focus {
  --tw-gradient-from: #5C6BC0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
}

.focus\:from-indigo-500:focus {
  --tw-gradient-from: #3F51B5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
}

.focus\:from-indigo-600:focus {
  --tw-gradient-from: #3949AB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
}

.focus\:from-indigo-700:focus {
  --tw-gradient-from: #303F9F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
}

.focus\:from-indigo-800:focus {
  --tw-gradient-from: #283593;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
}

.focus\:from-indigo-900:focus {
  --tw-gradient-from: #1A237E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
}

.focus\:from-indigo:focus {
  --tw-gradient-from: #3F51B5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
}

.focus\:from-indigo-A100:focus {
  --tw-gradient-from: #8C9EFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
}

.focus\:from-indigo-A200:focus {
  --tw-gradient-from: #536DFE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
}

.focus\:from-indigo-A400:focus {
  --tw-gradient-from: #3D5AFE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
}

.focus\:from-indigo-A700:focus {
  --tw-gradient-from: #304FFE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
}

.focus\:from-purple-50:focus {
  --tw-gradient-from: #F3E5F5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
}

.focus\:from-purple-100:focus {
  --tw-gradient-from: #E1BEE7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
}

.focus\:from-purple-200:focus {
  --tw-gradient-from: #CE93D8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
}

.focus\:from-purple-300:focus {
  --tw-gradient-from: #BA68C8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
}

.focus\:from-purple-400:focus {
  --tw-gradient-from: #AB47BC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
}

.focus\:from-purple-500:focus {
  --tw-gradient-from: #9C27B0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
}

.focus\:from-purple-600:focus {
  --tw-gradient-from: #8E24AA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
}

.focus\:from-purple-700:focus {
  --tw-gradient-from: #7B1FA2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
}

.focus\:from-purple-800:focus {
  --tw-gradient-from: #6A1B9A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
}

.focus\:from-purple-900:focus {
  --tw-gradient-from: #4A148C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
}

.focus\:from-purple:focus {
  --tw-gradient-from: #9C27B0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
}

.focus\:from-purple-A100:focus {
  --tw-gradient-from: #EA80FC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
}

.focus\:from-purple-A200:focus {
  --tw-gradient-from: #E040FB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
}

.focus\:from-purple-A400:focus {
  --tw-gradient-from: #D500F9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
}

.focus\:from-purple-A700:focus {
  --tw-gradient-from: #AA00FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
}

.focus\:from-deep-purple-50:focus {
  --tw-gradient-from: #EDE7F6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
}

.focus\:from-deep-purple-100:focus {
  --tw-gradient-from: #D1C4E9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
}

.focus\:from-deep-purple-200:focus {
  --tw-gradient-from: #B39DDB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
}

.focus\:from-deep-purple-300:focus {
  --tw-gradient-from: #9575CD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
}

.focus\:from-deep-purple-400:focus {
  --tw-gradient-from: #7E57C2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
}

.focus\:from-deep-purple-500:focus {
  --tw-gradient-from: #673AB7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
}

.focus\:from-deep-purple-600:focus {
  --tw-gradient-from: #5E35B1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
}

.focus\:from-deep-purple-700:focus {
  --tw-gradient-from: #512DA8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
}

.focus\:from-deep-purple-800:focus {
  --tw-gradient-from: #4527A0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
}

.focus\:from-deep-purple-900:focus {
  --tw-gradient-from: #311B92;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
}

.focus\:from-deep-purple:focus {
  --tw-gradient-from: #673AB7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
}

.focus\:from-deep-purple-A100:focus {
  --tw-gradient-from: #B388FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
}

.focus\:from-deep-purple-A200:focus {
  --tw-gradient-from: #7C4DFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
}

.focus\:from-deep-purple-A400:focus {
  --tw-gradient-from: #651FFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
}

.focus\:from-deep-purple-A700:focus {
  --tw-gradient-from: #6200EA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
}

.focus\:from-pink-50:focus {
  --tw-gradient-from: #FCE4EC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
}

.focus\:from-pink-100:focus {
  --tw-gradient-from: #F8BBD0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
}

.focus\:from-pink-200:focus {
  --tw-gradient-from: #F48FB1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
}

.focus\:from-pink-300:focus {
  --tw-gradient-from: #F06292;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
}

.focus\:from-pink-400:focus {
  --tw-gradient-from: #EC407A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
}

.focus\:from-pink-500:focus {
  --tw-gradient-from: #E91E63;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
}

.focus\:from-pink-600:focus {
  --tw-gradient-from: #D81B60;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
}

.focus\:from-pink-700:focus {
  --tw-gradient-from: #C2185B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
}

.focus\:from-pink-800:focus {
  --tw-gradient-from: #AD1457;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
}

.focus\:from-pink-900:focus {
  --tw-gradient-from: #880E4F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
}

.focus\:from-pink:focus {
  --tw-gradient-from: #E91E63;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
}

.focus\:from-pink-A100:focus {
  --tw-gradient-from: #FF80AB;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
}

.focus\:from-pink-A200:focus {
  --tw-gradient-from: #FF4081;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
}

.focus\:from-pink-A400:focus {
  --tw-gradient-from: #F50057;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
}

.focus\:from-pink-A700:focus {
  --tw-gradient-from: #C51162;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
}

.focus\:from-lime-50:focus {
  --tw-gradient-from: #F9FBE7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
}

.focus\:from-lime-100:focus {
  --tw-gradient-from: #F0F4C3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
}

.focus\:from-lime-200:focus {
  --tw-gradient-from: #E6EE9C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
}

.focus\:from-lime-300:focus {
  --tw-gradient-from: #DCE775;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
}

.focus\:from-lime-400:focus {
  --tw-gradient-from: #D4E157;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
}

.focus\:from-lime-500:focus {
  --tw-gradient-from: #CDDC39;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
}

.focus\:from-lime-600:focus {
  --tw-gradient-from: #C0CA33;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
}

.focus\:from-lime-700:focus {
  --tw-gradient-from: #AFB42B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
}

.focus\:from-lime-800:focus {
  --tw-gradient-from: #9E9D24;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
}

.focus\:from-lime-900:focus {
  --tw-gradient-from: #827717;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
}

.focus\:from-lime:focus {
  --tw-gradient-from: #CDDC39;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
}

.focus\:from-lime-A100:focus {
  --tw-gradient-from: #F4FF81;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
}

.focus\:from-lime-A200:focus {
  --tw-gradient-from: #EEFF41;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
}

.focus\:from-lime-A400:focus {
  --tw-gradient-from: #C6FF00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
}

.focus\:from-lime-A700:focus {
  --tw-gradient-from: #AEEA00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
}

.focus\:from-amber-50:focus {
  --tw-gradient-from: #FFF8E1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
}

.focus\:from-amber-100:focus {
  --tw-gradient-from: #FFECB3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
}

.focus\:from-amber-200:focus {
  --tw-gradient-from: #FFE082;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
}

.focus\:from-amber-300:focus {
  --tw-gradient-from: #FFD54F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
}

.focus\:from-amber-400:focus {
  --tw-gradient-from: #FFCA28;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
}

.focus\:from-amber-500:focus {
  --tw-gradient-from: #FFC107;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
}

.focus\:from-amber-600:focus {
  --tw-gradient-from: #FFB300;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
}

.focus\:from-amber-700:focus {
  --tw-gradient-from: #FFA000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
}

.focus\:from-amber-800:focus {
  --tw-gradient-from: #FF8F00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
}

.focus\:from-amber-900:focus {
  --tw-gradient-from: #FF6F00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
}

.focus\:from-amber:focus {
  --tw-gradient-from: #FFC107;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
}

.focus\:from-amber-A100:focus {
  --tw-gradient-from: #FFE57F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
}

.focus\:from-amber-A200:focus {
  --tw-gradient-from: #FFD740;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
}

.focus\:from-amber-A400:focus {
  --tw-gradient-from: #FFC400;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
}

.focus\:from-amber-A700:focus {
  --tw-gradient-from: #FFAB00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
}

.focus\:from-brown-50:focus {
  --tw-gradient-from: #EFEBE9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
}

.focus\:from-brown-100:focus {
  --tw-gradient-from: #D7CCC8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
}

.focus\:from-brown-200:focus {
  --tw-gradient-from: #BCAAA4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
}

.focus\:from-brown-300:focus {
  --tw-gradient-from: #A1887F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
}

.focus\:from-brown-400:focus {
  --tw-gradient-from: #8D6E63;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
}

.focus\:from-brown-500:focus {
  --tw-gradient-from: #795548;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
}

.focus\:from-brown-600:focus {
  --tw-gradient-from: #6D4C41;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
}

.focus\:from-brown-700:focus {
  --tw-gradient-from: #5D4037;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
}

.focus\:from-brown-800:focus {
  --tw-gradient-from: #4E342E;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
}

.focus\:from-brown-900:focus {
  --tw-gradient-from: #3E2723;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
}

.focus\:from-brown:focus {
  --tw-gradient-from: #795548;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
}

.focus\:from-brown-A100:focus {
  --tw-gradient-from: #D7CCC8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
}

.focus\:from-brown-A200:focus {
  --tw-gradient-from: #BCAAA4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
}

.focus\:from-brown-A400:focus {
  --tw-gradient-from: #8D6E63;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
}

.focus\:from-brown-A700:focus {
  --tw-gradient-from: #5D4037;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
}

.focus\:from-blue-gray-50:focus {
  --tw-gradient-from: #ECEFF1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
}

.focus\:from-blue-gray-100:focus {
  --tw-gradient-from: #CFD8DC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
}

.focus\:from-blue-gray-200:focus {
  --tw-gradient-from: #B0BEC5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
}

.focus\:from-blue-gray-300:focus {
  --tw-gradient-from: #90A4AE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
}

.focus\:from-blue-gray-400:focus {
  --tw-gradient-from: #78909C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
}

.focus\:from-blue-gray-500:focus {
  --tw-gradient-from: #607D8B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
}

.focus\:from-blue-gray-600:focus {
  --tw-gradient-from: #546E7A;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
}

.focus\:from-blue-gray-700:focus {
  --tw-gradient-from: #455A64;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
}

.focus\:from-blue-gray-800:focus {
  --tw-gradient-from: #37474F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
}

.focus\:from-blue-gray-900:focus {
  --tw-gradient-from: #263238;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
}

.focus\:from-blue-gray:focus {
  --tw-gradient-from: #607D8B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
}

.focus\:from-blue-gray-A100:focus {
  --tw-gradient-from: #CFD8DC;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
}

.focus\:from-blue-gray-A200:focus {
  --tw-gradient-from: #B0BEC5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
}

.focus\:from-blue-gray-A400:focus {
  --tw-gradient-from: #78909C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
}

.focus\:from-blue-gray-A700:focus {
  --tw-gradient-from: #455A64;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
}

.focus\:from-cyan-50:focus {
  --tw-gradient-from: #E0F7FA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
}

.focus\:from-cyan-100:focus {
  --tw-gradient-from: #B2EBF2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
}

.focus\:from-cyan-200:focus {
  --tw-gradient-from: #80DEEA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
}

.focus\:from-cyan-300:focus {
  --tw-gradient-from: #4DD0E1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
}

.focus\:from-cyan-400:focus {
  --tw-gradient-from: #26C6DA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
}

.focus\:from-cyan-500:focus {
  --tw-gradient-from: #00BCD4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
}

.focus\:from-cyan-600:focus {
  --tw-gradient-from: #00ACC1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
}

.focus\:from-cyan-700:focus {
  --tw-gradient-from: #0097A7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
}

.focus\:from-cyan-800:focus {
  --tw-gradient-from: #00838F;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
}

.focus\:from-cyan-900:focus {
  --tw-gradient-from: #006064;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
}

.focus\:from-cyan:focus {
  --tw-gradient-from: #00BCD4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
}

.focus\:from-cyan-A100:focus {
  --tw-gradient-from: #84FFFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
}

.focus\:from-cyan-A200:focus {
  --tw-gradient-from: #18FFFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
}

.focus\:from-cyan-A400:focus {
  --tw-gradient-from: #00E5FF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
}

.focus\:from-cyan-A700:focus {
  --tw-gradient-from: #00B8D4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
}

.focus\:via-transparent:focus {
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.focus\:via-current:focus {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.focus\:via-black:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
}

.focus\:via-white:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.focus\:via-grey-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
}

.focus\:via-grey-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
}

.focus\:via-grey-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
}

.focus\:via-grey-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
}

.focus\:via-grey-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
}

.focus\:via-grey-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.focus\:via-grey-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
}

.focus\:via-grey-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.focus\:via-grey-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
}

.focus\:via-grey-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
}

.focus\:via-grey:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.focus\:via-grey-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
}

.focus\:via-grey-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
}

.focus\:via-grey-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
}

.focus\:via-grey-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.focus\:via-gray-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
}

.focus\:via-gray-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
}

.focus\:via-gray-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
}

.focus\:via-gray-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
}

.focus\:via-gray-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
}

.focus\:via-gray-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.focus\:via-gray-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
}

.focus\:via-gray-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.focus\:via-gray-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
}

.focus\:via-gray-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
}

.focus\:via-gray:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
}

.focus\:via-gray-hover:focus {
  --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.focus\:via-gray-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
}

.focus\:via-gray-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
}

.focus\:via-gray-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
}

.focus\:via-gray-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
}

.focus\:via-red-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
}

.focus\:via-red-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
}

.focus\:via-red-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
}

.focus\:via-red-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
}

.focus\:via-red-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
}

.focus\:via-red-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
}

.focus\:via-red-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
}

.focus\:via-red-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
}

.focus\:via-red-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
}

.focus\:via-red-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
}

.focus\:via-red:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
}

.focus\:via-red-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
}

.focus\:via-red-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
}

.focus\:via-red-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
}

.focus\:via-red-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
}

.focus\:via-orange-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
}

.focus\:via-orange-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
}

.focus\:via-orange-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
}

.focus\:via-orange-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
}

.focus\:via-orange-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
}

.focus\:via-orange-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
}

.focus\:via-orange-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
}

.focus\:via-orange-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
}

.focus\:via-orange-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
}

.focus\:via-orange-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
}

.focus\:via-orange:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
}

.focus\:via-orange-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
}

.focus\:via-orange-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
}

.focus\:via-orange-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
}

.focus\:via-orange-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
}

.focus\:via-deep-orange-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
}

.focus\:via-deep-orange-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
}

.focus\:via-deep-orange-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
}

.focus\:via-deep-orange-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
}

.focus\:via-deep-orange-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
}

.focus\:via-deep-orange-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
}

.focus\:via-deep-orange-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
}

.focus\:via-deep-orange-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
}

.focus\:via-deep-orange-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
}

.focus\:via-deep-orange-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
}

.focus\:via-deep-orange:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
}

.focus\:via-deep-orange-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
}

.focus\:via-deep-orange-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
}

.focus\:via-deep-orange-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
}

.focus\:via-deep-orange-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
}

.focus\:via-yellow-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
}

.focus\:via-yellow-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
}

.focus\:via-yellow-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
}

.focus\:via-yellow-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
}

.focus\:via-yellow-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
}

.focus\:via-yellow-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
}

.focus\:via-yellow-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
}

.focus\:via-yellow-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
}

.focus\:via-yellow-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
}

.focus\:via-yellow-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
}

.focus\:via-yellow:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
}

.focus\:via-yellow-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
}

.focus\:via-yellow-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
}

.focus\:via-yellow-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
}

.focus\:via-yellow-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
}

.focus\:via-green-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
}

.focus\:via-green-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
}

.focus\:via-green-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
}

.focus\:via-green-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
}

.focus\:via-green-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
}

.focus\:via-green-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
}

.focus\:via-green-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
}

.focus\:via-green-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
}

.focus\:via-green-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
}

.focus\:via-green-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
}

.focus\:via-green:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
}

.focus\:via-green-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
}

.focus\:via-green-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
}

.focus\:via-green-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
}

.focus\:via-green-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
}

.focus\:via-light-green-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
}

.focus\:via-light-green-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
}

.focus\:via-light-green-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
}

.focus\:via-light-green-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
}

.focus\:via-light-green-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
}

.focus\:via-light-green-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
}

.focus\:via-light-green-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
}

.focus\:via-light-green-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
}

.focus\:via-light-green-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
}

.focus\:via-light-green-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
}

.focus\:via-light-green:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
}

.focus\:via-light-green-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
}

.focus\:via-light-green-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
}

.focus\:via-light-green-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
}

.focus\:via-light-green-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
}

.focus\:via-teal-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
}

.focus\:via-teal-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
}

.focus\:via-teal-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
}

.focus\:via-teal-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
}

.focus\:via-teal-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
}

.focus\:via-teal-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
}

.focus\:via-teal-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
}

.focus\:via-teal-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
}

.focus\:via-teal-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
}

.focus\:via-teal-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
}

.focus\:via-teal:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
}

.focus\:via-teal-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
}

.focus\:via-teal-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
}

.focus\:via-teal-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
}

.focus\:via-teal-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
}

.focus\:via-blue-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
}

.focus\:via-blue-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
}

.focus\:via-blue-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
}

.focus\:via-blue-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
}

.focus\:via-blue-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
}

.focus\:via-blue-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
}

.focus\:via-blue-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
}

.focus\:via-blue-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
}

.focus\:via-blue-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
}

.focus\:via-blue-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
}

.focus\:via-blue:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
}

.focus\:via-blue-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
}

.focus\:via-blue-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
}

.focus\:via-blue-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
}

.focus\:via-blue-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
}

.focus\:via-light-blue-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
}

.focus\:via-light-blue-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
}

.focus\:via-light-blue-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
}

.focus\:via-light-blue-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
}

.focus\:via-light-blue-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
}

.focus\:via-light-blue-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
}

.focus\:via-light-blue-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
}

.focus\:via-light-blue-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
}

.focus\:via-light-blue-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
}

.focus\:via-light-blue-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
}

.focus\:via-light-blue:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
}

.focus\:via-light-blue-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
}

.focus\:via-light-blue-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
}

.focus\:via-light-blue-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
}

.focus\:via-light-blue-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
}

.focus\:via-indigo-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
}

.focus\:via-indigo-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
}

.focus\:via-indigo-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
}

.focus\:via-indigo-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
}

.focus\:via-indigo-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
}

.focus\:via-indigo-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
}

.focus\:via-indigo-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
}

.focus\:via-indigo-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
}

.focus\:via-indigo-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
}

.focus\:via-indigo-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
}

.focus\:via-indigo:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
}

.focus\:via-indigo-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
}

.focus\:via-indigo-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
}

.focus\:via-indigo-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
}

.focus\:via-indigo-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
}

.focus\:via-purple-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
}

.focus\:via-purple-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
}

.focus\:via-purple-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
}

.focus\:via-purple-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
}

.focus\:via-purple-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
}

.focus\:via-purple-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
}

.focus\:via-purple-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
}

.focus\:via-purple-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
}

.focus\:via-purple-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
}

.focus\:via-purple-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
}

.focus\:via-purple:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
}

.focus\:via-purple-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
}

.focus\:via-purple-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
}

.focus\:via-purple-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
}

.focus\:via-purple-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
}

.focus\:via-deep-purple-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
}

.focus\:via-deep-purple-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
}

.focus\:via-deep-purple-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
}

.focus\:via-deep-purple-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
}

.focus\:via-deep-purple-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
}

.focus\:via-deep-purple-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
}

.focus\:via-deep-purple-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
}

.focus\:via-deep-purple-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
}

.focus\:via-deep-purple-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
}

.focus\:via-deep-purple-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
}

.focus\:via-deep-purple:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
}

.focus\:via-deep-purple-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
}

.focus\:via-deep-purple-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
}

.focus\:via-deep-purple-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
}

.focus\:via-deep-purple-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
}

.focus\:via-pink-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
}

.focus\:via-pink-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
}

.focus\:via-pink-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
}

.focus\:via-pink-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
}

.focus\:via-pink-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
}

.focus\:via-pink-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
}

.focus\:via-pink-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
}

.focus\:via-pink-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
}

.focus\:via-pink-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
}

.focus\:via-pink-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
}

.focus\:via-pink:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
}

.focus\:via-pink-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
}

.focus\:via-pink-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
}

.focus\:via-pink-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
}

.focus\:via-pink-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
}

.focus\:via-lime-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
}

.focus\:via-lime-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
}

.focus\:via-lime-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
}

.focus\:via-lime-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
}

.focus\:via-lime-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
}

.focus\:via-lime-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
}

.focus\:via-lime-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
}

.focus\:via-lime-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
}

.focus\:via-lime-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
}

.focus\:via-lime-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
}

.focus\:via-lime:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
}

.focus\:via-lime-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
}

.focus\:via-lime-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
}

.focus\:via-lime-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
}

.focus\:via-lime-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
}

.focus\:via-amber-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
}

.focus\:via-amber-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
}

.focus\:via-amber-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
}

.focus\:via-amber-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
}

.focus\:via-amber-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
}

.focus\:via-amber-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
}

.focus\:via-amber-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
}

.focus\:via-amber-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
}

.focus\:via-amber-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
}

.focus\:via-amber-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
}

.focus\:via-amber:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
}

.focus\:via-amber-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
}

.focus\:via-amber-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
}

.focus\:via-amber-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
}

.focus\:via-amber-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
}

.focus\:via-brown-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
}

.focus\:via-brown-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
}

.focus\:via-brown-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
}

.focus\:via-brown-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
}

.focus\:via-brown-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
}

.focus\:via-brown-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
}

.focus\:via-brown-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
}

.focus\:via-brown-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
}

.focus\:via-brown-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
}

.focus\:via-brown-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
}

.focus\:via-brown:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
}

.focus\:via-brown-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
}

.focus\:via-brown-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
}

.focus\:via-brown-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
}

.focus\:via-brown-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
}

.focus\:via-blue-gray-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
}

.focus\:via-blue-gray-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
}

.focus\:via-blue-gray-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
}

.focus\:via-blue-gray-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
}

.focus\:via-blue-gray-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
}

.focus\:via-blue-gray-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
}

.focus\:via-blue-gray-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
}

.focus\:via-blue-gray-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
}

.focus\:via-blue-gray-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
}

.focus\:via-blue-gray-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
}

.focus\:via-blue-gray:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
}

.focus\:via-blue-gray-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
}

.focus\:via-blue-gray-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
}

.focus\:via-blue-gray-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
}

.focus\:via-blue-gray-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
}

.focus\:via-cyan-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
}

.focus\:via-cyan-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
}

.focus\:via-cyan-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
}

.focus\:via-cyan-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
}

.focus\:via-cyan-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
}

.focus\:via-cyan-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
}

.focus\:via-cyan-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
}

.focus\:via-cyan-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
}

.focus\:via-cyan-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
}

.focus\:via-cyan-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
}

.focus\:via-cyan:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
}

.focus\:via-cyan-A100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
}

.focus\:via-cyan-A200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
}

.focus\:via-cyan-A400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
}

.focus\:via-cyan-A700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
}

.focus\:to-transparent:focus {
  --tw-gradient-to: transparent
}

.focus\:to-current:focus {
  --tw-gradient-to: currentColor
}

.focus\:to-black:focus {
  --tw-gradient-to: #22292F
}

.focus\:to-white:focus {
  --tw-gradient-to: #fff
}

.focus\:to-grey-50:focus {
  --tw-gradient-to: #FAFAFA
}

.focus\:to-grey-100:focus {
  --tw-gradient-to: #F5F5F5
}

.focus\:to-grey-200:focus {
  --tw-gradient-to: #EEEEEE
}

.focus\:to-grey-300:focus {
  --tw-gradient-to: #E0E0E0
}

.focus\:to-grey-400:focus {
  --tw-gradient-to: #BDBDBD
}

.focus\:to-grey-500:focus {
  --tw-gradient-to: #9E9E9E
}

.focus\:to-grey-600:focus {
  --tw-gradient-to: #757575
}

.focus\:to-grey-700:focus {
  --tw-gradient-to: #616161
}

.focus\:to-grey-800:focus {
  --tw-gradient-to: #424242
}

.focus\:to-grey-900:focus {
  --tw-gradient-to: #212121
}

.focus\:to-grey:focus {
  --tw-gradient-to: #9E9E9E
}

.focus\:to-grey-A100:focus {
  --tw-gradient-to: #D5D5D5
}

.focus\:to-grey-A200:focus {
  --tw-gradient-to: #AAAAAA
}

.focus\:to-grey-A400:focus {
  --tw-gradient-to: #303030
}

.focus\:to-grey-A700:focus {
  --tw-gradient-to: #616161
}

.focus\:to-gray-50:focus {
  --tw-gradient-to: #FAFAFA
}

.focus\:to-gray-100:focus {
  --tw-gradient-to: #F5F5F5
}

.focus\:to-gray-200:focus {
  --tw-gradient-to: #EEEEEE
}

.focus\:to-gray-300:focus {
  --tw-gradient-to: #E0E0E0
}

.focus\:to-gray-400:focus {
  --tw-gradient-to: #BDBDBD
}

.focus\:to-gray-500:focus {
  --tw-gradient-to: #9E9E9E
}

.focus\:to-gray-600:focus {
  --tw-gradient-to: #757575
}

.focus\:to-gray-700:focus {
  --tw-gradient-to: #616161
}

.focus\:to-gray-800:focus {
  --tw-gradient-to: #424242
}

.focus\:to-gray-900:focus {
  --tw-gradient-to: #212121
}

.focus\:to-gray:focus {
  --tw-gradient-to: #9E9E9E
}

.focus\:to-gray-hover:focus {
  --tw-gradient-to: rgba(0, 0, 0, 0.04)
}

.focus\:to-gray-A100:focus {
  --tw-gradient-to: #D5D5D5
}

.focus\:to-gray-A200:focus {
  --tw-gradient-to: #AAAAAA
}

.focus\:to-gray-A400:focus {
  --tw-gradient-to: #303030
}

.focus\:to-gray-A700:focus {
  --tw-gradient-to: #616161
}

.focus\:to-red-50:focus {
  --tw-gradient-to: #FFEBEE
}

.focus\:to-red-100:focus {
  --tw-gradient-to: #FFCDD2
}

.focus\:to-red-200:focus {
  --tw-gradient-to: #EF9A9A
}

.focus\:to-red-300:focus {
  --tw-gradient-to: #E57373
}

.focus\:to-red-400:focus {
  --tw-gradient-to: #EF5350
}

.focus\:to-red-500:focus {
  --tw-gradient-to: #F44336
}

.focus\:to-red-600:focus {
  --tw-gradient-to: #E53935
}

.focus\:to-red-700:focus {
  --tw-gradient-to: #D32F2F
}

.focus\:to-red-800:focus {
  --tw-gradient-to: #C62828
}

.focus\:to-red-900:focus {
  --tw-gradient-to: #B71C1C
}

.focus\:to-red:focus {
  --tw-gradient-to: #F44336
}

.focus\:to-red-A100:focus {
  --tw-gradient-to: #FF8A80
}

.focus\:to-red-A200:focus {
  --tw-gradient-to: #FF5252
}

.focus\:to-red-A400:focus {
  --tw-gradient-to: #FF1744
}

.focus\:to-red-A700:focus {
  --tw-gradient-to: #D50000
}

.focus\:to-orange-50:focus {
  --tw-gradient-to: #FFF3E0
}

.focus\:to-orange-100:focus {
  --tw-gradient-to: #FFE0B2
}

.focus\:to-orange-200:focus {
  --tw-gradient-to: #FFCC80
}

.focus\:to-orange-300:focus {
  --tw-gradient-to: #FFB74D
}

.focus\:to-orange-400:focus {
  --tw-gradient-to: #FFA726
}

.focus\:to-orange-500:focus {
  --tw-gradient-to: #FF9800
}

.focus\:to-orange-600:focus {
  --tw-gradient-to: #FB8C00
}

.focus\:to-orange-700:focus {
  --tw-gradient-to: #F57C00
}

.focus\:to-orange-800:focus {
  --tw-gradient-to: #EF6C00
}

.focus\:to-orange-900:focus {
  --tw-gradient-to: #E65100
}

.focus\:to-orange:focus {
  --tw-gradient-to: #FF9800
}

.focus\:to-orange-A100:focus {
  --tw-gradient-to: #FFD180
}

.focus\:to-orange-A200:focus {
  --tw-gradient-to: #FFAB40
}

.focus\:to-orange-A400:focus {
  --tw-gradient-to: #FF9100
}

.focus\:to-orange-A700:focus {
  --tw-gradient-to: #FF6D00
}

.focus\:to-deep-orange-50:focus {
  --tw-gradient-to: #FBE9E7
}

.focus\:to-deep-orange-100:focus {
  --tw-gradient-to: #FFCCBC
}

.focus\:to-deep-orange-200:focus {
  --tw-gradient-to: #FFAB91
}

.focus\:to-deep-orange-300:focus {
  --tw-gradient-to: #FF8A65
}

.focus\:to-deep-orange-400:focus {
  --tw-gradient-to: #FF7043
}

.focus\:to-deep-orange-500:focus {
  --tw-gradient-to: #FF5722
}

.focus\:to-deep-orange-600:focus {
  --tw-gradient-to: #F4511E
}

.focus\:to-deep-orange-700:focus {
  --tw-gradient-to: #E64A19
}

.focus\:to-deep-orange-800:focus {
  --tw-gradient-to: #D84315
}

.focus\:to-deep-orange-900:focus {
  --tw-gradient-to: #BF360C
}

.focus\:to-deep-orange:focus {
  --tw-gradient-to: #FF5722
}

.focus\:to-deep-orange-A100:focus {
  --tw-gradient-to: #FF9E80
}

.focus\:to-deep-orange-A200:focus {
  --tw-gradient-to: #FF6E40
}

.focus\:to-deep-orange-A400:focus {
  --tw-gradient-to: #FF3D00
}

.focus\:to-deep-orange-A700:focus {
  --tw-gradient-to: #DD2C00
}

.focus\:to-yellow-50:focus {
  --tw-gradient-to: #FFFDE7
}

.focus\:to-yellow-100:focus {
  --tw-gradient-to: #FFF9C4
}

.focus\:to-yellow-200:focus {
  --tw-gradient-to: #FFF59D
}

.focus\:to-yellow-300:focus {
  --tw-gradient-to: #FFF176
}

.focus\:to-yellow-400:focus {
  --tw-gradient-to: #FFEE58
}

.focus\:to-yellow-500:focus {
  --tw-gradient-to: #FFEB3B
}

.focus\:to-yellow-600:focus {
  --tw-gradient-to: #FDD835
}

.focus\:to-yellow-700:focus {
  --tw-gradient-to: #FBC02D
}

.focus\:to-yellow-800:focus {
  --tw-gradient-to: #F9A825
}

.focus\:to-yellow-900:focus {
  --tw-gradient-to: #F57F17
}

.focus\:to-yellow:focus {
  --tw-gradient-to: #FFEB3B
}

.focus\:to-yellow-A100:focus {
  --tw-gradient-to: #FFFF8D
}

.focus\:to-yellow-A200:focus {
  --tw-gradient-to: #FFFF00
}

.focus\:to-yellow-A400:focus {
  --tw-gradient-to: #FFEA00
}

.focus\:to-yellow-A700:focus {
  --tw-gradient-to: #FFD600
}

.focus\:to-green-50:focus {
  --tw-gradient-to: #E8F5E9
}

.focus\:to-green-100:focus {
  --tw-gradient-to: #C8E6C9
}

.focus\:to-green-200:focus {
  --tw-gradient-to: #A5D6A7
}

.focus\:to-green-300:focus {
  --tw-gradient-to: #81C784
}

.focus\:to-green-400:focus {
  --tw-gradient-to: #66BB6A
}

.focus\:to-green-500:focus {
  --tw-gradient-to: #4CAF50
}

.focus\:to-green-600:focus {
  --tw-gradient-to: #43A047
}

.focus\:to-green-700:focus {
  --tw-gradient-to: #388E3C
}

.focus\:to-green-800:focus {
  --tw-gradient-to: #2E7D32
}

.focus\:to-green-900:focus {
  --tw-gradient-to: #1B5E20
}

.focus\:to-green:focus {
  --tw-gradient-to: #4CAF50
}

.focus\:to-green-A100:focus {
  --tw-gradient-to: #B9F6CA
}

.focus\:to-green-A200:focus {
  --tw-gradient-to: #69F0AE
}

.focus\:to-green-A400:focus {
  --tw-gradient-to: #00E676
}

.focus\:to-green-A700:focus {
  --tw-gradient-to: #00C853
}

.focus\:to-light-green-50:focus {
  --tw-gradient-to: #F1F8E9
}

.focus\:to-light-green-100:focus {
  --tw-gradient-to: #DCEDC8
}

.focus\:to-light-green-200:focus {
  --tw-gradient-to: #C5E1A5
}

.focus\:to-light-green-300:focus {
  --tw-gradient-to: #AED581
}

.focus\:to-light-green-400:focus {
  --tw-gradient-to: #9CCC65
}

.focus\:to-light-green-500:focus {
  --tw-gradient-to: #8BC34A
}

.focus\:to-light-green-600:focus {
  --tw-gradient-to: #7CB342
}

.focus\:to-light-green-700:focus {
  --tw-gradient-to: #689F38
}

.focus\:to-light-green-800:focus {
  --tw-gradient-to: #558B2F
}

.focus\:to-light-green-900:focus {
  --tw-gradient-to: #33691E
}

.focus\:to-light-green:focus {
  --tw-gradient-to: #8BC34A
}

.focus\:to-light-green-A100:focus {
  --tw-gradient-to: #CCFF90
}

.focus\:to-light-green-A200:focus {
  --tw-gradient-to: #B2FF59
}

.focus\:to-light-green-A400:focus {
  --tw-gradient-to: #76FF03
}

.focus\:to-light-green-A700:focus {
  --tw-gradient-to: #64DD17
}

.focus\:to-teal-50:focus {
  --tw-gradient-to: #E0F2F1
}

.focus\:to-teal-100:focus {
  --tw-gradient-to: #B2DFDB
}

.focus\:to-teal-200:focus {
  --tw-gradient-to: #80CBC4
}

.focus\:to-teal-300:focus {
  --tw-gradient-to: #4DB6AC
}

.focus\:to-teal-400:focus {
  --tw-gradient-to: #26A69A
}

.focus\:to-teal-500:focus {
  --tw-gradient-to: #009688
}

.focus\:to-teal-600:focus {
  --tw-gradient-to: #00897B
}

.focus\:to-teal-700:focus {
  --tw-gradient-to: #00796B
}

.focus\:to-teal-800:focus {
  --tw-gradient-to: #00695C
}

.focus\:to-teal-900:focus {
  --tw-gradient-to: #004D40
}

.focus\:to-teal:focus {
  --tw-gradient-to: #009688
}

.focus\:to-teal-A100:focus {
  --tw-gradient-to: #A7FFEB
}

.focus\:to-teal-A200:focus {
  --tw-gradient-to: #64FFDA
}

.focus\:to-teal-A400:focus {
  --tw-gradient-to: #1DE9B6
}

.focus\:to-teal-A700:focus {
  --tw-gradient-to: #00BFA5
}

.focus\:to-blue-50:focus {
  --tw-gradient-to: #E3F2FD
}

.focus\:to-blue-100:focus {
  --tw-gradient-to: #BBDEFB
}

.focus\:to-blue-200:focus {
  --tw-gradient-to: #90CAF9
}

.focus\:to-blue-300:focus {
  --tw-gradient-to: #64B5F6
}

.focus\:to-blue-400:focus {
  --tw-gradient-to: #42A5F5
}

.focus\:to-blue-500:focus {
  --tw-gradient-to: #2196F3
}

.focus\:to-blue-600:focus {
  --tw-gradient-to: #1E88E5
}

.focus\:to-blue-700:focus {
  --tw-gradient-to: #1976D2
}

.focus\:to-blue-800:focus {
  --tw-gradient-to: #1565C0
}

.focus\:to-blue-900:focus {
  --tw-gradient-to: #0D47A1
}

.focus\:to-blue:focus {
  --tw-gradient-to: #2196F3
}

.focus\:to-blue-A100:focus {
  --tw-gradient-to: #82B1FF
}

.focus\:to-blue-A200:focus {
  --tw-gradient-to: #448AFF
}

.focus\:to-blue-A400:focus {
  --tw-gradient-to: #2979FF
}

.focus\:to-blue-A700:focus {
  --tw-gradient-to: #2962FF
}

.focus\:to-light-blue-50:focus {
  --tw-gradient-to: #E1F5FE
}

.focus\:to-light-blue-100:focus {
  --tw-gradient-to: #B3E5FC
}

.focus\:to-light-blue-200:focus {
  --tw-gradient-to: #81D4FA
}

.focus\:to-light-blue-300:focus {
  --tw-gradient-to: #4FC3F7
}

.focus\:to-light-blue-400:focus {
  --tw-gradient-to: #29B6F6
}

.focus\:to-light-blue-500:focus {
  --tw-gradient-to: #03A9F4
}

.focus\:to-light-blue-600:focus {
  --tw-gradient-to: #039BE5
}

.focus\:to-light-blue-700:focus {
  --tw-gradient-to: #0288D1
}

.focus\:to-light-blue-800:focus {
  --tw-gradient-to: #0277BD
}

.focus\:to-light-blue-900:focus {
  --tw-gradient-to: #01579B
}

.focus\:to-light-blue:focus {
  --tw-gradient-to: #03A9F4
}

.focus\:to-light-blue-A100:focus {
  --tw-gradient-to: #80D8FF
}

.focus\:to-light-blue-A200:focus {
  --tw-gradient-to: #40C4FF
}

.focus\:to-light-blue-A400:focus {
  --tw-gradient-to: #00B0FF
}

.focus\:to-light-blue-A700:focus {
  --tw-gradient-to: #0091EA
}

.focus\:to-indigo-50:focus {
  --tw-gradient-to: #E8EAF6
}

.focus\:to-indigo-100:focus {
  --tw-gradient-to: #C5CAE9
}

.focus\:to-indigo-200:focus {
  --tw-gradient-to: #9FA8DA
}

.focus\:to-indigo-300:focus {
  --tw-gradient-to: #7986CB
}

.focus\:to-indigo-400:focus {
  --tw-gradient-to: #5C6BC0
}

.focus\:to-indigo-500:focus {
  --tw-gradient-to: #3F51B5
}

.focus\:to-indigo-600:focus {
  --tw-gradient-to: #3949AB
}

.focus\:to-indigo-700:focus {
  --tw-gradient-to: #303F9F
}

.focus\:to-indigo-800:focus {
  --tw-gradient-to: #283593
}

.focus\:to-indigo-900:focus {
  --tw-gradient-to: #1A237E
}

.focus\:to-indigo:focus {
  --tw-gradient-to: #3F51B5
}

.focus\:to-indigo-A100:focus {
  --tw-gradient-to: #8C9EFF
}

.focus\:to-indigo-A200:focus {
  --tw-gradient-to: #536DFE
}

.focus\:to-indigo-A400:focus {
  --tw-gradient-to: #3D5AFE
}

.focus\:to-indigo-A700:focus {
  --tw-gradient-to: #304FFE
}

.focus\:to-purple-50:focus {
  --tw-gradient-to: #F3E5F5
}

.focus\:to-purple-100:focus {
  --tw-gradient-to: #E1BEE7
}

.focus\:to-purple-200:focus {
  --tw-gradient-to: #CE93D8
}

.focus\:to-purple-300:focus {
  --tw-gradient-to: #BA68C8
}

.focus\:to-purple-400:focus {
  --tw-gradient-to: #AB47BC
}

.focus\:to-purple-500:focus {
  --tw-gradient-to: #9C27B0
}

.focus\:to-purple-600:focus {
  --tw-gradient-to: #8E24AA
}

.focus\:to-purple-700:focus {
  --tw-gradient-to: #7B1FA2
}

.focus\:to-purple-800:focus {
  --tw-gradient-to: #6A1B9A
}

.focus\:to-purple-900:focus {
  --tw-gradient-to: #4A148C
}

.focus\:to-purple:focus {
  --tw-gradient-to: #9C27B0
}

.focus\:to-purple-A100:focus {
  --tw-gradient-to: #EA80FC
}

.focus\:to-purple-A200:focus {
  --tw-gradient-to: #E040FB
}

.focus\:to-purple-A400:focus {
  --tw-gradient-to: #D500F9
}

.focus\:to-purple-A700:focus {
  --tw-gradient-to: #AA00FF
}

.focus\:to-deep-purple-50:focus {
  --tw-gradient-to: #EDE7F6
}

.focus\:to-deep-purple-100:focus {
  --tw-gradient-to: #D1C4E9
}

.focus\:to-deep-purple-200:focus {
  --tw-gradient-to: #B39DDB
}

.focus\:to-deep-purple-300:focus {
  --tw-gradient-to: #9575CD
}

.focus\:to-deep-purple-400:focus {
  --tw-gradient-to: #7E57C2
}

.focus\:to-deep-purple-500:focus {
  --tw-gradient-to: #673AB7
}

.focus\:to-deep-purple-600:focus {
  --tw-gradient-to: #5E35B1
}

.focus\:to-deep-purple-700:focus {
  --tw-gradient-to: #512DA8
}

.focus\:to-deep-purple-800:focus {
  --tw-gradient-to: #4527A0
}

.focus\:to-deep-purple-900:focus {
  --tw-gradient-to: #311B92
}

.focus\:to-deep-purple:focus {
  --tw-gradient-to: #673AB7
}

.focus\:to-deep-purple-A100:focus {
  --tw-gradient-to: #B388FF
}

.focus\:to-deep-purple-A200:focus {
  --tw-gradient-to: #7C4DFF
}

.focus\:to-deep-purple-A400:focus {
  --tw-gradient-to: #651FFF
}

.focus\:to-deep-purple-A700:focus {
  --tw-gradient-to: #6200EA
}

.focus\:to-pink-50:focus {
  --tw-gradient-to: #FCE4EC
}

.focus\:to-pink-100:focus {
  --tw-gradient-to: #F8BBD0
}

.focus\:to-pink-200:focus {
  --tw-gradient-to: #F48FB1
}

.focus\:to-pink-300:focus {
  --tw-gradient-to: #F06292
}

.focus\:to-pink-400:focus {
  --tw-gradient-to: #EC407A
}

.focus\:to-pink-500:focus {
  --tw-gradient-to: #E91E63
}

.focus\:to-pink-600:focus {
  --tw-gradient-to: #D81B60
}

.focus\:to-pink-700:focus {
  --tw-gradient-to: #C2185B
}

.focus\:to-pink-800:focus {
  --tw-gradient-to: #AD1457
}

.focus\:to-pink-900:focus {
  --tw-gradient-to: #880E4F
}

.focus\:to-pink:focus {
  --tw-gradient-to: #E91E63
}

.focus\:to-pink-A100:focus {
  --tw-gradient-to: #FF80AB
}

.focus\:to-pink-A200:focus {
  --tw-gradient-to: #FF4081
}

.focus\:to-pink-A400:focus {
  --tw-gradient-to: #F50057
}

.focus\:to-pink-A700:focus {
  --tw-gradient-to: #C51162
}

.focus\:to-lime-50:focus {
  --tw-gradient-to: #F9FBE7
}

.focus\:to-lime-100:focus {
  --tw-gradient-to: #F0F4C3
}

.focus\:to-lime-200:focus {
  --tw-gradient-to: #E6EE9C
}

.focus\:to-lime-300:focus {
  --tw-gradient-to: #DCE775
}

.focus\:to-lime-400:focus {
  --tw-gradient-to: #D4E157
}

.focus\:to-lime-500:focus {
  --tw-gradient-to: #CDDC39
}

.focus\:to-lime-600:focus {
  --tw-gradient-to: #C0CA33
}

.focus\:to-lime-700:focus {
  --tw-gradient-to: #AFB42B
}

.focus\:to-lime-800:focus {
  --tw-gradient-to: #9E9D24
}

.focus\:to-lime-900:focus {
  --tw-gradient-to: #827717
}

.focus\:to-lime:focus {
  --tw-gradient-to: #CDDC39
}

.focus\:to-lime-A100:focus {
  --tw-gradient-to: #F4FF81
}

.focus\:to-lime-A200:focus {
  --tw-gradient-to: #EEFF41
}

.focus\:to-lime-A400:focus {
  --tw-gradient-to: #C6FF00
}

.focus\:to-lime-A700:focus {
  --tw-gradient-to: #AEEA00
}

.focus\:to-amber-50:focus {
  --tw-gradient-to: #FFF8E1
}

.focus\:to-amber-100:focus {
  --tw-gradient-to: #FFECB3
}

.focus\:to-amber-200:focus {
  --tw-gradient-to: #FFE082
}

.focus\:to-amber-300:focus {
  --tw-gradient-to: #FFD54F
}

.focus\:to-amber-400:focus {
  --tw-gradient-to: #FFCA28
}

.focus\:to-amber-500:focus {
  --tw-gradient-to: #FFC107
}

.focus\:to-amber-600:focus {
  --tw-gradient-to: #FFB300
}

.focus\:to-amber-700:focus {
  --tw-gradient-to: #FFA000
}

.focus\:to-amber-800:focus {
  --tw-gradient-to: #FF8F00
}

.focus\:to-amber-900:focus {
  --tw-gradient-to: #FF6F00
}

.focus\:to-amber:focus {
  --tw-gradient-to: #FFC107
}

.focus\:to-amber-A100:focus {
  --tw-gradient-to: #FFE57F
}

.focus\:to-amber-A200:focus {
  --tw-gradient-to: #FFD740
}

.focus\:to-amber-A400:focus {
  --tw-gradient-to: #FFC400
}

.focus\:to-amber-A700:focus {
  --tw-gradient-to: #FFAB00
}

.focus\:to-brown-50:focus {
  --tw-gradient-to: #EFEBE9
}

.focus\:to-brown-100:focus {
  --tw-gradient-to: #D7CCC8
}

.focus\:to-brown-200:focus {
  --tw-gradient-to: #BCAAA4
}

.focus\:to-brown-300:focus {
  --tw-gradient-to: #A1887F
}

.focus\:to-brown-400:focus {
  --tw-gradient-to: #8D6E63
}

.focus\:to-brown-500:focus {
  --tw-gradient-to: #795548
}

.focus\:to-brown-600:focus {
  --tw-gradient-to: #6D4C41
}

.focus\:to-brown-700:focus {
  --tw-gradient-to: #5D4037
}

.focus\:to-brown-800:focus {
  --tw-gradient-to: #4E342E
}

.focus\:to-brown-900:focus {
  --tw-gradient-to: #3E2723
}

.focus\:to-brown:focus {
  --tw-gradient-to: #795548
}

.focus\:to-brown-A100:focus {
  --tw-gradient-to: #D7CCC8
}

.focus\:to-brown-A200:focus {
  --tw-gradient-to: #BCAAA4
}

.focus\:to-brown-A400:focus {
  --tw-gradient-to: #8D6E63
}

.focus\:to-brown-A700:focus {
  --tw-gradient-to: #5D4037
}

.focus\:to-blue-gray-50:focus {
  --tw-gradient-to: #ECEFF1
}

.focus\:to-blue-gray-100:focus {
  --tw-gradient-to: #CFD8DC
}

.focus\:to-blue-gray-200:focus {
  --tw-gradient-to: #B0BEC5
}

.focus\:to-blue-gray-300:focus {
  --tw-gradient-to: #90A4AE
}

.focus\:to-blue-gray-400:focus {
  --tw-gradient-to: #78909C
}

.focus\:to-blue-gray-500:focus {
  --tw-gradient-to: #607D8B
}

.focus\:to-blue-gray-600:focus {
  --tw-gradient-to: #546E7A
}

.focus\:to-blue-gray-700:focus {
  --tw-gradient-to: #455A64
}

.focus\:to-blue-gray-800:focus {
  --tw-gradient-to: #37474F
}

.focus\:to-blue-gray-900:focus {
  --tw-gradient-to: #263238
}

.focus\:to-blue-gray:focus {
  --tw-gradient-to: #607D8B
}

.focus\:to-blue-gray-A100:focus {
  --tw-gradient-to: #CFD8DC
}

.focus\:to-blue-gray-A200:focus {
  --tw-gradient-to: #B0BEC5
}

.focus\:to-blue-gray-A400:focus {
  --tw-gradient-to: #78909C
}

.focus\:to-blue-gray-A700:focus {
  --tw-gradient-to: #455A64
}

.focus\:to-cyan-50:focus {
  --tw-gradient-to: #E0F7FA
}

.focus\:to-cyan-100:focus {
  --tw-gradient-to: #B2EBF2
}

.focus\:to-cyan-200:focus {
  --tw-gradient-to: #80DEEA
}

.focus\:to-cyan-300:focus {
  --tw-gradient-to: #4DD0E1
}

.focus\:to-cyan-400:focus {
  --tw-gradient-to: #26C6DA
}

.focus\:to-cyan-500:focus {
  --tw-gradient-to: #00BCD4
}

.focus\:to-cyan-600:focus {
  --tw-gradient-to: #00ACC1
}

.focus\:to-cyan-700:focus {
  --tw-gradient-to: #0097A7
}

.focus\:to-cyan-800:focus {
  --tw-gradient-to: #00838F
}

.focus\:to-cyan-900:focus {
  --tw-gradient-to: #006064
}

.focus\:to-cyan:focus {
  --tw-gradient-to: #00BCD4
}

.focus\:to-cyan-A100:focus {
  --tw-gradient-to: #84FFFF
}

.focus\:to-cyan-A200:focus {
  --tw-gradient-to: #18FFFF
}

.focus\:to-cyan-A400:focus {
  --tw-gradient-to: #00E5FF
}

.focus\:to-cyan-A700:focus {
  --tw-gradient-to: #00B8D4
}

.bg-opacity-0 {
  --tw-bg-opacity: 0
}

.bg-opacity-5 {
  --tw-bg-opacity: 0.05
}

.bg-opacity-10 {
  --tw-bg-opacity: 0.1
}

.bg-opacity-20 {
  --tw-bg-opacity: 0.2
}

.bg-opacity-25 {
  --tw-bg-opacity: 0.25
}

.bg-opacity-30 {
  --tw-bg-opacity: 0.3
}

.bg-opacity-40 {
  --tw-bg-opacity: 0.4
}

.bg-opacity-50 {
  --tw-bg-opacity: 0.5
}

.bg-opacity-60 {
  --tw-bg-opacity: 0.6
}

.bg-opacity-70 {
  --tw-bg-opacity: 0.7
}

.bg-opacity-75 {
  --tw-bg-opacity: 0.75
}

.bg-opacity-80 {
  --tw-bg-opacity: 0.8
}

.bg-opacity-90 {
  --tw-bg-opacity: 0.9
}

.bg-opacity-95 {
  --tw-bg-opacity: 0.95
}

.bg-opacity-100 {
  --tw-bg-opacity: 1
}

.group:hover .group-hover\:bg-opacity-0 {
  --tw-bg-opacity: 0
}

.group:hover .group-hover\:bg-opacity-5 {
  --tw-bg-opacity: 0.05
}

.group:hover .group-hover\:bg-opacity-10 {
  --tw-bg-opacity: 0.1
}

.group:hover .group-hover\:bg-opacity-20 {
  --tw-bg-opacity: 0.2
}

.group:hover .group-hover\:bg-opacity-25 {
  --tw-bg-opacity: 0.25
}

.group:hover .group-hover\:bg-opacity-30 {
  --tw-bg-opacity: 0.3
}

.group:hover .group-hover\:bg-opacity-40 {
  --tw-bg-opacity: 0.4
}

.group:hover .group-hover\:bg-opacity-50 {
  --tw-bg-opacity: 0.5
}

.group:hover .group-hover\:bg-opacity-60 {
  --tw-bg-opacity: 0.6
}

.group:hover .group-hover\:bg-opacity-70 {
  --tw-bg-opacity: 0.7
}

.group:hover .group-hover\:bg-opacity-75 {
  --tw-bg-opacity: 0.75
}

.group:hover .group-hover\:bg-opacity-80 {
  --tw-bg-opacity: 0.8
}

.group:hover .group-hover\:bg-opacity-90 {
  --tw-bg-opacity: 0.9
}

.group:hover .group-hover\:bg-opacity-95 {
  --tw-bg-opacity: 0.95
}

.group:hover .group-hover\:bg-opacity-100 {
  --tw-bg-opacity: 1
}

.focus-within\:bg-opacity-0:focus-within {
  --tw-bg-opacity: 0
}

.focus-within\:bg-opacity-5:focus-within {
  --tw-bg-opacity: 0.05
}

.focus-within\:bg-opacity-10:focus-within {
  --tw-bg-opacity: 0.1
}

.focus-within\:bg-opacity-20:focus-within {
  --tw-bg-opacity: 0.2
}

.focus-within\:bg-opacity-25:focus-within {
  --tw-bg-opacity: 0.25
}

.focus-within\:bg-opacity-30:focus-within {
  --tw-bg-opacity: 0.3
}

.focus-within\:bg-opacity-40:focus-within {
  --tw-bg-opacity: 0.4
}

.focus-within\:bg-opacity-50:focus-within {
  --tw-bg-opacity: 0.5
}

.focus-within\:bg-opacity-60:focus-within {
  --tw-bg-opacity: 0.6
}

.focus-within\:bg-opacity-70:focus-within {
  --tw-bg-opacity: 0.7
}

.focus-within\:bg-opacity-75:focus-within {
  --tw-bg-opacity: 0.75
}

.focus-within\:bg-opacity-80:focus-within {
  --tw-bg-opacity: 0.8
}

.focus-within\:bg-opacity-90:focus-within {
  --tw-bg-opacity: 0.9
}

.focus-within\:bg-opacity-95:focus-within {
  --tw-bg-opacity: 0.95
}

.focus-within\:bg-opacity-100:focus-within {
  --tw-bg-opacity: 1
}

.hover\:bg-opacity-0:hover {
  --tw-bg-opacity: 0
}

.hover\:bg-opacity-5:hover {
  --tw-bg-opacity: 0.05
}

.hover\:bg-opacity-10:hover {
  --tw-bg-opacity: 0.1
}

.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: 0.2
}

.hover\:bg-opacity-25:hover {
  --tw-bg-opacity: 0.25
}

.hover\:bg-opacity-30:hover {
  --tw-bg-opacity: 0.3
}

.hover\:bg-opacity-40:hover {
  --tw-bg-opacity: 0.4
}

.hover\:bg-opacity-50:hover {
  --tw-bg-opacity: 0.5
}

.hover\:bg-opacity-60:hover {
  --tw-bg-opacity: 0.6
}

.hover\:bg-opacity-70:hover {
  --tw-bg-opacity: 0.7
}

.hover\:bg-opacity-75:hover {
  --tw-bg-opacity: 0.75
}

.hover\:bg-opacity-80:hover {
  --tw-bg-opacity: 0.8
}

.hover\:bg-opacity-90:hover {
  --tw-bg-opacity: 0.9
}

.hover\:bg-opacity-95:hover {
  --tw-bg-opacity: 0.95
}

.hover\:bg-opacity-100:hover {
  --tw-bg-opacity: 1
}

.focus\:bg-opacity-0:focus {
  --tw-bg-opacity: 0
}

.focus\:bg-opacity-5:focus {
  --tw-bg-opacity: 0.05
}

.focus\:bg-opacity-10:focus {
  --tw-bg-opacity: 0.1
}

.focus\:bg-opacity-20:focus {
  --tw-bg-opacity: 0.2
}

.focus\:bg-opacity-25:focus {
  --tw-bg-opacity: 0.25
}

.focus\:bg-opacity-30:focus {
  --tw-bg-opacity: 0.3
}

.focus\:bg-opacity-40:focus {
  --tw-bg-opacity: 0.4
}

.focus\:bg-opacity-50:focus {
  --tw-bg-opacity: 0.5
}

.focus\:bg-opacity-60:focus {
  --tw-bg-opacity: 0.6
}

.focus\:bg-opacity-70:focus {
  --tw-bg-opacity: 0.7
}

.focus\:bg-opacity-75:focus {
  --tw-bg-opacity: 0.75
}

.focus\:bg-opacity-80:focus {
  --tw-bg-opacity: 0.8
}

.focus\:bg-opacity-90:focus {
  --tw-bg-opacity: 0.9
}

.focus\:bg-opacity-95:focus {
  --tw-bg-opacity: 0.95
}

.focus\:bg-opacity-100:focus {
  --tw-bg-opacity: 1
}

.bg-bottom {
  background-position: bottom
}

.bg-center {
  background-position: center
}

.bg-left {
  background-position: left
}

.bg-left-bottom {
  background-position: left bottom
}

.bg-left-top {
  background-position: left top
}

.bg-right {
  background-position: right
}

.bg-right-bottom {
  background-position: right bottom
}

.bg-right-top {
  background-position: right top
}

.bg-top {
  background-position: top
}

.bg-repeat {
  background-repeat: repeat
}

.bg-no-repeat {
  background-repeat: no-repeat
}

.bg-repeat-x {
  background-repeat: repeat-x
}

.bg-repeat-y {
  background-repeat: repeat-y
}

.bg-repeat-round {
  background-repeat: round
}

.bg-repeat-space {
  background-repeat: space
}

.bg-auto {
  background-size: auto
}

.bg-cover {
  background-size: cover
}

.bg-contain {
  background-size: contain
}

.border-collapse {
  border-collapse: collapse
}

.border-separate {
  border-collapse: separate
}

.border-transparent {
  border-color: transparent
}

.border-current {
  border-color: currentColor
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgba(34, 41, 47, var(--tw-border-opacity))
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

.border-grey-50 {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity))
}

.border-grey-100 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity))
}

.border-grey-200 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-border-opacity))
}

.border-grey-300 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity))
}

.border-grey-400 {
  --tw-border-opacity: 1;
  border-color: rgba(189, 189, 189, var(--tw-border-opacity))
}

.border-grey-500 {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.border-grey-600 {
  --tw-border-opacity: 1;
  border-color: rgba(117, 117, 117, var(--tw-border-opacity))
}

.border-grey-700 {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.border-grey-800 {
  --tw-border-opacity: 1;
  border-color: rgba(66, 66, 66, var(--tw-border-opacity))
}

.border-grey-900 {
  --tw-border-opacity: 1;
  border-color: rgba(33, 33, 33, var(--tw-border-opacity))
}

.border-grey {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.border-grey-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(213, 213, 213, var(--tw-border-opacity))
}

.border-grey-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(170, 170, 170, var(--tw-border-opacity))
}

.border-grey-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(48, 48, 48, var(--tw-border-opacity))
}

.border-grey-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity))
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity))
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-border-opacity))
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity))
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(189, 189, 189, var(--tw-border-opacity))
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(117, 117, 117, var(--tw-border-opacity))
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(66, 66, 66, var(--tw-border-opacity))
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(33, 33, 33, var(--tw-border-opacity))
}

.border-gray {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.border-gray-hover {
  border-color: rgba(0, 0, 0, 0.04)
}

.border-gray-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(213, 213, 213, var(--tw-border-opacity))
}

.border-gray-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(170, 170, 170, var(--tw-border-opacity))
}

.border-gray-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(48, 48, 48, var(--tw-border-opacity))
}

.border-gray-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 235, 238, var(--tw-border-opacity))
}

.border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 205, 210, var(--tw-border-opacity))
}

.border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 154, 154, var(--tw-border-opacity))
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 115, 115, var(--tw-border-opacity))
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 83, 80, var(--tw-border-opacity))
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity))
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 57, 53, var(--tw-border-opacity))
}

.border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgba(211, 47, 47, var(--tw-border-opacity))
}

.border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgba(198, 40, 40, var(--tw-border-opacity))
}

.border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgba(183, 28, 28, var(--tw-border-opacity))
}

.border-red {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity))
}

.border-red-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 138, 128, var(--tw-border-opacity))
}

.border-red-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 82, 82, var(--tw-border-opacity))
}

.border-red-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 23, 68, var(--tw-border-opacity))
}

.border-red-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(213, 0, 0, var(--tw-border-opacity))
}

.border-orange-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 243, 224, var(--tw-border-opacity))
}

.border-orange-100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 224, 178, var(--tw-border-opacity))
}

.border-orange-200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 204, 128, var(--tw-border-opacity))
}

.border-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 183, 77, var(--tw-border-opacity))
}

.border-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 167, 38, var(--tw-border-opacity))
}

.border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-border-opacity))
}

.border-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 140, 0, var(--tw-border-opacity))
}

.border-orange-700 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 124, 0, var(--tw-border-opacity))
}

.border-orange-800 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 108, 0, var(--tw-border-opacity))
}

.border-orange-900 {
  --tw-border-opacity: 1;
  border-color: rgba(230, 81, 0, var(--tw-border-opacity))
}

.border-orange {
  --tw-border-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-border-opacity))
}

.border-orange-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 209, 128, var(--tw-border-opacity))
}

.border-orange-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 171, 64, var(--tw-border-opacity))
}

.border-orange-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 145, 0, var(--tw-border-opacity))
}

.border-orange-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 109, 0, var(--tw-border-opacity))
}

.border-deep-orange-50 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 233, 231, var(--tw-border-opacity))
}

.border-deep-orange-100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 204, 188, var(--tw-border-opacity))
}

.border-deep-orange-200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 171, 145, var(--tw-border-opacity))
}

.border-deep-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 138, 101, var(--tw-border-opacity))
}

.border-deep-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 112, 67, var(--tw-border-opacity))
}

.border-deep-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-border-opacity))
}

.border-deep-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 81, 30, var(--tw-border-opacity))
}

.border-deep-orange-700 {
  --tw-border-opacity: 1;
  border-color: rgba(230, 74, 25, var(--tw-border-opacity))
}

.border-deep-orange-800 {
  --tw-border-opacity: 1;
  border-color: rgba(216, 67, 21, var(--tw-border-opacity))
}

.border-deep-orange-900 {
  --tw-border-opacity: 1;
  border-color: rgba(191, 54, 12, var(--tw-border-opacity))
}

.border-deep-orange {
  --tw-border-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-border-opacity))
}

.border-deep-orange-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 158, 128, var(--tw-border-opacity))
}

.border-deep-orange-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 110, 64, var(--tw-border-opacity))
}

.border-deep-orange-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 61, 0, var(--tw-border-opacity))
}

.border-deep-orange-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(221, 44, 0, var(--tw-border-opacity))
}

.border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 253, 231, var(--tw-border-opacity))
}

.border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 249, 196, var(--tw-border-opacity))
}

.border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 245, 157, var(--tw-border-opacity))
}

.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 241, 118, var(--tw-border-opacity))
}

.border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 238, 88, var(--tw-border-opacity))
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 235, 59, var(--tw-border-opacity))
}

.border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 216, 53, var(--tw-border-opacity))
}

.border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 192, 45, var(--tw-border-opacity))
}

.border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 37, var(--tw-border-opacity))
}

.border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 127, 23, var(--tw-border-opacity))
}

.border-yellow {
  --tw-border-opacity: 1;
  border-color: rgba(255, 235, 59, var(--tw-border-opacity))
}

.border-yellow-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 141, var(--tw-border-opacity))
}

.border-yellow-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 0, var(--tw-border-opacity))
}

.border-yellow-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 234, 0, var(--tw-border-opacity))
}

.border-yellow-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 214, 0, var(--tw-border-opacity))
}

.border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(232, 245, 233, var(--tw-border-opacity))
}

.border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(200, 230, 201, var(--tw-border-opacity))
}

.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(165, 214, 167, var(--tw-border-opacity))
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 199, 132, var(--tw-border-opacity))
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(102, 187, 106, var(--tw-border-opacity))
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity))
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(67, 160, 71, var(--tw-border-opacity))
}

.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(56, 142, 60, var(--tw-border-opacity))
}

.border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(46, 125, 50, var(--tw-border-opacity))
}

.border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(27, 94, 32, var(--tw-border-opacity))
}

.border-green {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity))
}

.border-green-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(185, 246, 202, var(--tw-border-opacity))
}

.border-green-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(105, 240, 174, var(--tw-border-opacity))
}

.border-green-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 230, 118, var(--tw-border-opacity))
}

.border-green-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 200, 83, var(--tw-border-opacity))
}

.border-light-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(241, 248, 233, var(--tw-border-opacity))
}

.border-light-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 237, 200, var(--tw-border-opacity))
}

.border-light-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(197, 225, 165, var(--tw-border-opacity))
}

.border-light-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(174, 213, 129, var(--tw-border-opacity))
}

.border-light-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 204, 101, var(--tw-border-opacity))
}

.border-light-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-border-opacity))
}

.border-light-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(124, 179, 66, var(--tw-border-opacity))
}

.border-light-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(104, 159, 56, var(--tw-border-opacity))
}

.border-light-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(85, 139, 47, var(--tw-border-opacity))
}

.border-light-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(51, 105, 30, var(--tw-border-opacity))
}

.border-light-green {
  --tw-border-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-border-opacity))
}

.border-light-green-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(204, 255, 144, var(--tw-border-opacity))
}

.border-light-green-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(178, 255, 89, var(--tw-border-opacity))
}

.border-light-green-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(118, 255, 3, var(--tw-border-opacity))
}

.border-light-green-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(100, 221, 23, var(--tw-border-opacity))
}

.border-teal-50 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 242, 241, var(--tw-border-opacity))
}

.border-teal-100 {
  --tw-border-opacity: 1;
  border-color: rgba(178, 223, 219, var(--tw-border-opacity))
}

.border-teal-200 {
  --tw-border-opacity: 1;
  border-color: rgba(128, 203, 196, var(--tw-border-opacity))
}

.border-teal-300 {
  --tw-border-opacity: 1;
  border-color: rgba(77, 182, 172, var(--tw-border-opacity))
}

.border-teal-400 {
  --tw-border-opacity: 1;
  border-color: rgba(38, 166, 154, var(--tw-border-opacity))
}

.border-teal-500 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-border-opacity))
}

.border-teal-600 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 137, 123, var(--tw-border-opacity))
}

.border-teal-700 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 121, 107, var(--tw-border-opacity))
}

.border-teal-800 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 105, 92, var(--tw-border-opacity))
}

.border-teal-900 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 77, 64, var(--tw-border-opacity))
}

.border-teal {
  --tw-border-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-border-opacity))
}

.border-teal-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 255, 235, var(--tw-border-opacity))
}

.border-teal-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(100, 255, 218, var(--tw-border-opacity))
}

.border-teal-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(29, 233, 182, var(--tw-border-opacity))
}

.border-teal-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 191, 165, var(--tw-border-opacity))
}

.border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(227, 242, 253, var(--tw-border-opacity))
}

.border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(187, 222, 251, var(--tw-border-opacity))
}

.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(144, 202, 249, var(--tw-border-opacity))
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(100, 181, 246, var(--tw-border-opacity))
}

.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(66, 165, 245, var(--tw-border-opacity))
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-border-opacity))
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 136, 229, var(--tw-border-opacity))
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(25, 118, 210, var(--tw-border-opacity))
}

.border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(21, 101, 192, var(--tw-border-opacity))
}

.border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(13, 71, 161, var(--tw-border-opacity))
}

.border-blue {
  --tw-border-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-border-opacity))
}

.border-blue-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(130, 177, 255, var(--tw-border-opacity))
}

.border-blue-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(68, 138, 255, var(--tw-border-opacity))
}

.border-blue-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(41, 121, 255, var(--tw-border-opacity))
}

.border-blue-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(41, 98, 255, var(--tw-border-opacity))
}

.border-light-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(225, 245, 254, var(--tw-border-opacity))
}

.border-light-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(179, 229, 252, var(--tw-border-opacity))
}

.border-light-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 212, 250, var(--tw-border-opacity))
}

.border-light-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(79, 195, 247, var(--tw-border-opacity))
}

.border-light-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(41, 182, 246, var(--tw-border-opacity))
}

.border-light-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-border-opacity))
}

.border-light-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(3, 155, 229, var(--tw-border-opacity))
}

.border-light-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(2, 136, 209, var(--tw-border-opacity))
}

.border-light-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(2, 119, 189, var(--tw-border-opacity))
}

.border-light-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(1, 87, 155, var(--tw-border-opacity))
}

.border-light-blue {
  --tw-border-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-border-opacity))
}

.border-light-blue-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(128, 216, 255, var(--tw-border-opacity))
}

.border-light-blue-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(64, 196, 255, var(--tw-border-opacity))
}

.border-light-blue-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 176, 255, var(--tw-border-opacity))
}

.border-light-blue-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 145, 234, var(--tw-border-opacity))
}

.border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgba(232, 234, 246, var(--tw-border-opacity))
}

.border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgba(197, 202, 233, var(--tw-border-opacity))
}

.border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(159, 168, 218, var(--tw-border-opacity))
}

.border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgba(121, 134, 203, var(--tw-border-opacity))
}

.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgba(92, 107, 192, var(--tw-border-opacity))
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-border-opacity))
}

.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgba(57, 73, 171, var(--tw-border-opacity))
}

.border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgba(48, 63, 159, var(--tw-border-opacity))
}

.border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgba(40, 53, 147, var(--tw-border-opacity))
}

.border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgba(26, 35, 126, var(--tw-border-opacity))
}

.border-indigo {
  --tw-border-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-border-opacity))
}

.border-indigo-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(140, 158, 255, var(--tw-border-opacity))
}

.border-indigo-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(83, 109, 254, var(--tw-border-opacity))
}

.border-indigo-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(61, 90, 254, var(--tw-border-opacity))
}

.border-indigo-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(48, 79, 254, var(--tw-border-opacity))
}

.border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 229, 245, var(--tw-border-opacity))
}

.border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(225, 190, 231, var(--tw-border-opacity))
}

.border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(206, 147, 216, var(--tw-border-opacity))
}

.border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(186, 104, 200, var(--tw-border-opacity))
}

.border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(171, 71, 188, var(--tw-border-opacity))
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-border-opacity))
}

.border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(142, 36, 170, var(--tw-border-opacity))
}

.border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(123, 31, 162, var(--tw-border-opacity))
}

.border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(106, 27, 154, var(--tw-border-opacity))
}

.border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgba(74, 20, 140, var(--tw-border-opacity))
}

.border-purple {
  --tw-border-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-border-opacity))
}

.border-purple-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(234, 128, 252, var(--tw-border-opacity))
}

.border-purple-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 64, 251, var(--tw-border-opacity))
}

.border-purple-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(213, 0, 249, var(--tw-border-opacity))
}

.border-purple-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(170, 0, 255, var(--tw-border-opacity))
}

.border-deep-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgba(237, 231, 246, var(--tw-border-opacity))
}

.border-deep-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 196, 233, var(--tw-border-opacity))
}

.border-deep-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(179, 157, 219, var(--tw-border-opacity))
}

.border-deep-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(149, 117, 205, var(--tw-border-opacity))
}

.border-deep-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(126, 87, 194, var(--tw-border-opacity))
}

.border-deep-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity))
}

.border-deep-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(94, 53, 177, var(--tw-border-opacity))
}

.border-deep-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(81, 45, 168, var(--tw-border-opacity))
}

.border-deep-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(69, 39, 160, var(--tw-border-opacity))
}

.border-deep-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgba(49, 27, 146, var(--tw-border-opacity))
}

.border-deep-purple {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity))
}

.border-deep-purple-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(179, 136, 255, var(--tw-border-opacity))
}

.border-deep-purple-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(124, 77, 255, var(--tw-border-opacity))
}

.border-deep-purple-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(101, 31, 255, var(--tw-border-opacity))
}

.border-deep-purple-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(98, 0, 234, var(--tw-border-opacity))
}

.border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 228, 236, var(--tw-border-opacity))
}

.border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 187, 208, var(--tw-border-opacity))
}

.border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 143, 177, var(--tw-border-opacity))
}

.border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(240, 98, 146, var(--tw-border-opacity))
}

.border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 64, 122, var(--tw-border-opacity))
}

.border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-border-opacity))
}

.border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(216, 27, 96, var(--tw-border-opacity))
}

.border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(194, 24, 91, var(--tw-border-opacity))
}

.border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgba(173, 20, 87, var(--tw-border-opacity))
}

.border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgba(136, 14, 79, var(--tw-border-opacity))
}

.border-pink {
  --tw-border-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-border-opacity))
}

.border-pink-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 128, 171, var(--tw-border-opacity))
}

.border-pink-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 64, 129, var(--tw-border-opacity))
}

.border-pink-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 0, 87, var(--tw-border-opacity))
}

.border-pink-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(197, 17, 98, var(--tw-border-opacity))
}

.border-lime-50 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 251, 231, var(--tw-border-opacity))
}

.border-lime-100 {
  --tw-border-opacity: 1;
  border-color: rgba(240, 244, 195, var(--tw-border-opacity))
}

.border-lime-200 {
  --tw-border-opacity: 1;
  border-color: rgba(230, 238, 156, var(--tw-border-opacity))
}

.border-lime-300 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 231, 117, var(--tw-border-opacity))
}

.border-lime-400 {
  --tw-border-opacity: 1;
  border-color: rgba(212, 225, 87, var(--tw-border-opacity))
}

.border-lime-500 {
  --tw-border-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-border-opacity))
}

.border-lime-600 {
  --tw-border-opacity: 1;
  border-color: rgba(192, 202, 51, var(--tw-border-opacity))
}

.border-lime-700 {
  --tw-border-opacity: 1;
  border-color: rgba(175, 180, 43, var(--tw-border-opacity))
}

.border-lime-800 {
  --tw-border-opacity: 1;
  border-color: rgba(158, 157, 36, var(--tw-border-opacity))
}

.border-lime-900 {
  --tw-border-opacity: 1;
  border-color: rgba(130, 119, 23, var(--tw-border-opacity))
}

.border-lime {
  --tw-border-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-border-opacity))
}

.border-lime-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 255, 129, var(--tw-border-opacity))
}

.border-lime-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 255, 65, var(--tw-border-opacity))
}

.border-lime-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(198, 255, 0, var(--tw-border-opacity))
}

.border-lime-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(174, 234, 0, var(--tw-border-opacity))
}

.border-amber-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 248, 225, var(--tw-border-opacity))
}

.border-amber-100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 236, 179, var(--tw-border-opacity))
}

.border-amber-200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 224, 130, var(--tw-border-opacity))
}

.border-amber-300 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 213, 79, var(--tw-border-opacity))
}

.border-amber-400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 202, 40, var(--tw-border-opacity))
}

.border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity))
}

.border-amber-600 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 179, 0, var(--tw-border-opacity))
}

.border-amber-700 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 160, 0, var(--tw-border-opacity))
}

.border-amber-800 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 143, 0, var(--tw-border-opacity))
}

.border-amber-900 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 111, 0, var(--tw-border-opacity))
}

.border-amber {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity))
}

.border-amber-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 229, 127, var(--tw-border-opacity))
}

.border-amber-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 215, 64, var(--tw-border-opacity))
}

.border-amber-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 196, 0, var(--tw-border-opacity))
}

.border-amber-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 171, 0, var(--tw-border-opacity))
}

.border-brown-50 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 235, 233, var(--tw-border-opacity))
}

.border-brown-100 {
  --tw-border-opacity: 1;
  border-color: rgba(215, 204, 200, var(--tw-border-opacity))
}

.border-brown-200 {
  --tw-border-opacity: 1;
  border-color: rgba(188, 170, 164, var(--tw-border-opacity))
}

.border-brown-300 {
  --tw-border-opacity: 1;
  border-color: rgba(161, 136, 127, var(--tw-border-opacity))
}

.border-brown-400 {
  --tw-border-opacity: 1;
  border-color: rgba(141, 110, 99, var(--tw-border-opacity))
}

.border-brown-500 {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity))
}

.border-brown-600 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 76, 65, var(--tw-border-opacity))
}

.border-brown-700 {
  --tw-border-opacity: 1;
  border-color: rgba(93, 64, 55, var(--tw-border-opacity))
}

.border-brown-800 {
  --tw-border-opacity: 1;
  border-color: rgba(78, 52, 46, var(--tw-border-opacity))
}

.border-brown-900 {
  --tw-border-opacity: 1;
  border-color: rgba(62, 39, 35, var(--tw-border-opacity))
}

.border-brown {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity))
}

.border-brown-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(215, 204, 200, var(--tw-border-opacity))
}

.border-brown-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(188, 170, 164, var(--tw-border-opacity))
}

.border-brown-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(141, 110, 99, var(--tw-border-opacity))
}

.border-brown-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(93, 64, 55, var(--tw-border-opacity))
}

.border-blue-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 239, 241, var(--tw-border-opacity))
}

.border-blue-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(207, 216, 220, var(--tw-border-opacity))
}

.border-blue-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(176, 190, 197, var(--tw-border-opacity))
}

.border-blue-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(144, 164, 174, var(--tw-border-opacity))
}

.border-blue-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-border-opacity))
}

.border-blue-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity))
}

.border-blue-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(84, 110, 122, var(--tw-border-opacity))
}

.border-blue-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(69, 90, 100, var(--tw-border-opacity))
}

.border-blue-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 71, 79, var(--tw-border-opacity))
}

.border-blue-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(38, 50, 56, var(--tw-border-opacity))
}

.border-blue-gray {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity))
}

.border-blue-gray-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(207, 216, 220, var(--tw-border-opacity))
}

.border-blue-gray-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(176, 190, 197, var(--tw-border-opacity))
}

.border-blue-gray-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-border-opacity))
}

.border-blue-gray-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(69, 90, 100, var(--tw-border-opacity))
}

.border-cyan-50 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 247, 250, var(--tw-border-opacity))
}

.border-cyan-100 {
  --tw-border-opacity: 1;
  border-color: rgba(178, 235, 242, var(--tw-border-opacity))
}

.border-cyan-200 {
  --tw-border-opacity: 1;
  border-color: rgba(128, 222, 234, var(--tw-border-opacity))
}

.border-cyan-300 {
  --tw-border-opacity: 1;
  border-color: rgba(77, 208, 225, var(--tw-border-opacity))
}

.border-cyan-400 {
  --tw-border-opacity: 1;
  border-color: rgba(38, 198, 218, var(--tw-border-opacity))
}

.border-cyan-500 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-border-opacity))
}

.border-cyan-600 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 172, 193, var(--tw-border-opacity))
}

.border-cyan-700 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 151, 167, var(--tw-border-opacity))
}

.border-cyan-800 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 131, 143, var(--tw-border-opacity))
}

.border-cyan-900 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 96, 100, var(--tw-border-opacity))
}

.border-cyan {
  --tw-border-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-border-opacity))
}

.border-cyan-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(132, 255, 255, var(--tw-border-opacity))
}

.border-cyan-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(24, 255, 255, var(--tw-border-opacity))
}

.border-cyan-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 229, 255, var(--tw-border-opacity))
}

.border-cyan-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 184, 212, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-transparent {
  border-color: transparent
}

.group:hover .group-hover\:border-current {
  border-color: currentColor
}

.group:hover .group-hover\:border-black {
  --tw-border-opacity: 1;
  border-color: rgba(34, 41, 47, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-grey-50 {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-grey-100 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-grey-200 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-grey-300 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-grey-400 {
  --tw-border-opacity: 1;
  border-color: rgba(189, 189, 189, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-grey-500 {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-grey-600 {
  --tw-border-opacity: 1;
  border-color: rgba(117, 117, 117, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-grey-700 {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-grey-800 {
  --tw-border-opacity: 1;
  border-color: rgba(66, 66, 66, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-grey-900 {
  --tw-border-opacity: 1;
  border-color: rgba(33, 33, 33, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-grey {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-grey-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(213, 213, 213, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-grey-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(170, 170, 170, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-grey-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(48, 48, 48, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-grey-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(189, 189, 189, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(117, 117, 117, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(66, 66, 66, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(33, 33, 33, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gray {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gray-hover {
  border-color: rgba(0, 0, 0, 0.04)
}

.group:hover .group-hover\:border-gray-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(213, 213, 213, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gray-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(170, 170, 170, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gray-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(48, 48, 48, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gray-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 235, 238, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 205, 210, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 154, 154, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 115, 115, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 83, 80, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 57, 53, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgba(211, 47, 47, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgba(198, 40, 40, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgba(183, 28, 28, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-red {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-red-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 138, 128, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-red-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 82, 82, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-red-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 23, 68, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-red-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(213, 0, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-orange-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 243, 224, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-orange-100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 224, 178, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-orange-200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 204, 128, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 183, 77, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 167, 38, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 140, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-orange-700 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 124, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-orange-800 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 108, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-orange-900 {
  --tw-border-opacity: 1;
  border-color: rgba(230, 81, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-orange {
  --tw-border-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-orange-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 209, 128, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-orange-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 171, 64, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-orange-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 145, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-orange-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 109, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-orange-50 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 233, 231, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-orange-100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 204, 188, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-orange-200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 171, 145, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 138, 101, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 112, 67, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 81, 30, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-orange-700 {
  --tw-border-opacity: 1;
  border-color: rgba(230, 74, 25, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-orange-800 {
  --tw-border-opacity: 1;
  border-color: rgba(216, 67, 21, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-orange-900 {
  --tw-border-opacity: 1;
  border-color: rgba(191, 54, 12, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-orange {
  --tw-border-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-orange-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 158, 128, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-orange-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 110, 64, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-orange-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 61, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-orange-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(221, 44, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 253, 231, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 249, 196, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 245, 157, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 241, 118, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 238, 88, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 235, 59, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 216, 53, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 192, 45, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 37, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 127, 23, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-yellow {
  --tw-border-opacity: 1;
  border-color: rgba(255, 235, 59, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-yellow-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 141, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-yellow-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-yellow-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 234, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-yellow-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 214, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(232, 245, 233, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(200, 230, 201, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(165, 214, 167, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 199, 132, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(102, 187, 106, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(67, 160, 71, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(56, 142, 60, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(46, 125, 50, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(27, 94, 32, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-green {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-green-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(185, 246, 202, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-green-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(105, 240, 174, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-green-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 230, 118, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-green-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 200, 83, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(241, 248, 233, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 237, 200, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(197, 225, 165, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(174, 213, 129, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 204, 101, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(124, 179, 66, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(104, 159, 56, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(85, 139, 47, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(51, 105, 30, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-green {
  --tw-border-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-green-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(204, 255, 144, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-green-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(178, 255, 89, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-green-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(118, 255, 3, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-green-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(100, 221, 23, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-teal-50 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 242, 241, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-teal-100 {
  --tw-border-opacity: 1;
  border-color: rgba(178, 223, 219, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-teal-200 {
  --tw-border-opacity: 1;
  border-color: rgba(128, 203, 196, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-teal-300 {
  --tw-border-opacity: 1;
  border-color: rgba(77, 182, 172, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-teal-400 {
  --tw-border-opacity: 1;
  border-color: rgba(38, 166, 154, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-teal-500 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-teal-600 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 137, 123, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-teal-700 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 121, 107, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-teal-800 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 105, 92, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-teal-900 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 77, 64, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-teal {
  --tw-border-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-teal-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 255, 235, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-teal-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(100, 255, 218, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-teal-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(29, 233, 182, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-teal-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 191, 165, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(227, 242, 253, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(187, 222, 251, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(144, 202, 249, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(100, 181, 246, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(66, 165, 245, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 136, 229, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(25, 118, 210, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(21, 101, 192, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(13, 71, 161, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue {
  --tw-border-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(130, 177, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(68, 138, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(41, 121, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(41, 98, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(225, 245, 254, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(179, 229, 252, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 212, 250, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(79, 195, 247, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(41, 182, 246, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(3, 155, 229, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(2, 136, 209, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(2, 119, 189, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(1, 87, 155, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-blue {
  --tw-border-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-blue-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(128, 216, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-blue-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(64, 196, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-blue-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 176, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-light-blue-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 145, 234, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgba(232, 234, 246, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgba(197, 202, 233, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(159, 168, 218, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgba(121, 134, 203, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgba(92, 107, 192, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgba(57, 73, 171, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgba(48, 63, 159, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgba(40, 53, 147, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgba(26, 35, 126, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-indigo {
  --tw-border-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-indigo-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(140, 158, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-indigo-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(83, 109, 254, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-indigo-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(61, 90, 254, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-indigo-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(48, 79, 254, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 229, 245, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(225, 190, 231, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(206, 147, 216, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(186, 104, 200, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(171, 71, 188, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(142, 36, 170, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(123, 31, 162, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(106, 27, 154, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgba(74, 20, 140, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-purple {
  --tw-border-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-purple-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(234, 128, 252, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-purple-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 64, 251, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-purple-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(213, 0, 249, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-purple-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(170, 0, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgba(237, 231, 246, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 196, 233, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(179, 157, 219, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(149, 117, 205, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(126, 87, 194, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(94, 53, 177, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(81, 45, 168, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(69, 39, 160, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgba(49, 27, 146, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-purple {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-purple-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(179, 136, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-purple-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(124, 77, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-purple-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(101, 31, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-deep-purple-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(98, 0, 234, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 228, 236, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 187, 208, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 143, 177, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(240, 98, 146, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 64, 122, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(216, 27, 96, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(194, 24, 91, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgba(173, 20, 87, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgba(136, 14, 79, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-pink {
  --tw-border-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-pink-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 128, 171, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-pink-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 64, 129, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-pink-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 0, 87, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-pink-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(197, 17, 98, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-lime-50 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 251, 231, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-lime-100 {
  --tw-border-opacity: 1;
  border-color: rgba(240, 244, 195, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-lime-200 {
  --tw-border-opacity: 1;
  border-color: rgba(230, 238, 156, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-lime-300 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 231, 117, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-lime-400 {
  --tw-border-opacity: 1;
  border-color: rgba(212, 225, 87, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-lime-500 {
  --tw-border-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-lime-600 {
  --tw-border-opacity: 1;
  border-color: rgba(192, 202, 51, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-lime-700 {
  --tw-border-opacity: 1;
  border-color: rgba(175, 180, 43, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-lime-800 {
  --tw-border-opacity: 1;
  border-color: rgba(158, 157, 36, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-lime-900 {
  --tw-border-opacity: 1;
  border-color: rgba(130, 119, 23, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-lime {
  --tw-border-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-lime-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 255, 129, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-lime-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 255, 65, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-lime-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(198, 255, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-lime-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(174, 234, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-amber-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 248, 225, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-amber-100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 236, 179, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-amber-200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 224, 130, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-amber-300 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 213, 79, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-amber-400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 202, 40, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-amber-600 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 179, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-amber-700 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 160, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-amber-800 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 143, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-amber-900 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 111, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-amber {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-amber-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 229, 127, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-amber-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 215, 64, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-amber-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 196, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-amber-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 171, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-brown-50 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 235, 233, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-brown-100 {
  --tw-border-opacity: 1;
  border-color: rgba(215, 204, 200, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-brown-200 {
  --tw-border-opacity: 1;
  border-color: rgba(188, 170, 164, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-brown-300 {
  --tw-border-opacity: 1;
  border-color: rgba(161, 136, 127, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-brown-400 {
  --tw-border-opacity: 1;
  border-color: rgba(141, 110, 99, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-brown-500 {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-brown-600 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 76, 65, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-brown-700 {
  --tw-border-opacity: 1;
  border-color: rgba(93, 64, 55, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-brown-800 {
  --tw-border-opacity: 1;
  border-color: rgba(78, 52, 46, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-brown-900 {
  --tw-border-opacity: 1;
  border-color: rgba(62, 39, 35, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-brown {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-brown-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(215, 204, 200, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-brown-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(188, 170, 164, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-brown-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(141, 110, 99, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-brown-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(93, 64, 55, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 239, 241, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(207, 216, 220, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(176, 190, 197, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(144, 164, 174, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(84, 110, 122, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(69, 90, 100, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 71, 79, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(38, 50, 56, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-gray {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-gray-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(207, 216, 220, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-gray-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(176, 190, 197, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-gray-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-blue-gray-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(69, 90, 100, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan-50 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 247, 250, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan-100 {
  --tw-border-opacity: 1;
  border-color: rgba(178, 235, 242, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan-200 {
  --tw-border-opacity: 1;
  border-color: rgba(128, 222, 234, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan-300 {
  --tw-border-opacity: 1;
  border-color: rgba(77, 208, 225, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan-400 {
  --tw-border-opacity: 1;
  border-color: rgba(38, 198, 218, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan-500 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan-600 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 172, 193, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan-700 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 151, 167, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan-800 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 131, 143, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan-900 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 96, 100, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan {
  --tw-border-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan-A100 {
  --tw-border-opacity: 1;
  border-color: rgba(132, 255, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan-A200 {
  --tw-border-opacity: 1;
  border-color: rgba(24, 255, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan-A400 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 229, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan-A700 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 184, 212, var(--tw-border-opacity))
}

.focus-within\:border-transparent:focus-within {
  border-color: transparent
}

.focus-within\:border-current:focus-within {
  border-color: currentColor
}

.focus-within\:border-black:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(34, 41, 47, var(--tw-border-opacity))
}

.focus-within\:border-white:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

.focus-within\:border-grey-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity))
}

.focus-within\:border-grey-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity))
}

.focus-within\:border-grey-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-border-opacity))
}

.focus-within\:border-grey-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity))
}

.focus-within\:border-grey-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(189, 189, 189, var(--tw-border-opacity))
}

.focus-within\:border-grey-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.focus-within\:border-grey-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(117, 117, 117, var(--tw-border-opacity))
}

.focus-within\:border-grey-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.focus-within\:border-grey-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(66, 66, 66, var(--tw-border-opacity))
}

.focus-within\:border-grey-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(33, 33, 33, var(--tw-border-opacity))
}

.focus-within\:border-grey:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.focus-within\:border-grey-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(213, 213, 213, var(--tw-border-opacity))
}

.focus-within\:border-grey-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(170, 170, 170, var(--tw-border-opacity))
}

.focus-within\:border-grey-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(48, 48, 48, var(--tw-border-opacity))
}

.focus-within\:border-grey-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.focus-within\:border-gray-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity))
}

.focus-within\:border-gray-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity))
}

.focus-within\:border-gray-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-border-opacity))
}

.focus-within\:border-gray-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity))
}

.focus-within\:border-gray-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(189, 189, 189, var(--tw-border-opacity))
}

.focus-within\:border-gray-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.focus-within\:border-gray-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(117, 117, 117, var(--tw-border-opacity))
}

.focus-within\:border-gray-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.focus-within\:border-gray-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(66, 66, 66, var(--tw-border-opacity))
}

.focus-within\:border-gray-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(33, 33, 33, var(--tw-border-opacity))
}

.focus-within\:border-gray:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.focus-within\:border-gray-hover:focus-within {
  border-color: rgba(0, 0, 0, 0.04)
}

.focus-within\:border-gray-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(213, 213, 213, var(--tw-border-opacity))
}

.focus-within\:border-gray-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(170, 170, 170, var(--tw-border-opacity))
}

.focus-within\:border-gray-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(48, 48, 48, var(--tw-border-opacity))
}

.focus-within\:border-gray-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.focus-within\:border-red-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 235, 238, var(--tw-border-opacity))
}

.focus-within\:border-red-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 205, 210, var(--tw-border-opacity))
}

.focus-within\:border-red-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(239, 154, 154, var(--tw-border-opacity))
}

.focus-within\:border-red-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(229, 115, 115, var(--tw-border-opacity))
}

.focus-within\:border-red-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(239, 83, 80, var(--tw-border-opacity))
}

.focus-within\:border-red-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity))
}

.focus-within\:border-red-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(229, 57, 53, var(--tw-border-opacity))
}

.focus-within\:border-red-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(211, 47, 47, var(--tw-border-opacity))
}

.focus-within\:border-red-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(198, 40, 40, var(--tw-border-opacity))
}

.focus-within\:border-red-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(183, 28, 28, var(--tw-border-opacity))
}

.focus-within\:border-red:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity))
}

.focus-within\:border-red-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 138, 128, var(--tw-border-opacity))
}

.focus-within\:border-red-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 82, 82, var(--tw-border-opacity))
}

.focus-within\:border-red-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 23, 68, var(--tw-border-opacity))
}

.focus-within\:border-red-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(213, 0, 0, var(--tw-border-opacity))
}

.focus-within\:border-orange-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 243, 224, var(--tw-border-opacity))
}

.focus-within\:border-orange-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 224, 178, var(--tw-border-opacity))
}

.focus-within\:border-orange-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 204, 128, var(--tw-border-opacity))
}

.focus-within\:border-orange-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 183, 77, var(--tw-border-opacity))
}

.focus-within\:border-orange-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 167, 38, var(--tw-border-opacity))
}

.focus-within\:border-orange-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-border-opacity))
}

.focus-within\:border-orange-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(251, 140, 0, var(--tw-border-opacity))
}

.focus-within\:border-orange-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 124, 0, var(--tw-border-opacity))
}

.focus-within\:border-orange-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(239, 108, 0, var(--tw-border-opacity))
}

.focus-within\:border-orange-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(230, 81, 0, var(--tw-border-opacity))
}

.focus-within\:border-orange:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-border-opacity))
}

.focus-within\:border-orange-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 209, 128, var(--tw-border-opacity))
}

.focus-within\:border-orange-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 171, 64, var(--tw-border-opacity))
}

.focus-within\:border-orange-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 145, 0, var(--tw-border-opacity))
}

.focus-within\:border-orange-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 109, 0, var(--tw-border-opacity))
}

.focus-within\:border-deep-orange-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(251, 233, 231, var(--tw-border-opacity))
}

.focus-within\:border-deep-orange-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 204, 188, var(--tw-border-opacity))
}

.focus-within\:border-deep-orange-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 171, 145, var(--tw-border-opacity))
}

.focus-within\:border-deep-orange-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 138, 101, var(--tw-border-opacity))
}

.focus-within\:border-deep-orange-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 112, 67, var(--tw-border-opacity))
}

.focus-within\:border-deep-orange-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-border-opacity))
}

.focus-within\:border-deep-orange-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(244, 81, 30, var(--tw-border-opacity))
}

.focus-within\:border-deep-orange-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(230, 74, 25, var(--tw-border-opacity))
}

.focus-within\:border-deep-orange-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(216, 67, 21, var(--tw-border-opacity))
}

.focus-within\:border-deep-orange-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(191, 54, 12, var(--tw-border-opacity))
}

.focus-within\:border-deep-orange:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-border-opacity))
}

.focus-within\:border-deep-orange-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 158, 128, var(--tw-border-opacity))
}

.focus-within\:border-deep-orange-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 110, 64, var(--tw-border-opacity))
}

.focus-within\:border-deep-orange-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 61, 0, var(--tw-border-opacity))
}

.focus-within\:border-deep-orange-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(221, 44, 0, var(--tw-border-opacity))
}

.focus-within\:border-yellow-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 253, 231, var(--tw-border-opacity))
}

.focus-within\:border-yellow-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 249, 196, var(--tw-border-opacity))
}

.focus-within\:border-yellow-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 245, 157, var(--tw-border-opacity))
}

.focus-within\:border-yellow-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 241, 118, var(--tw-border-opacity))
}

.focus-within\:border-yellow-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 238, 88, var(--tw-border-opacity))
}

.focus-within\:border-yellow-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 235, 59, var(--tw-border-opacity))
}

.focus-within\:border-yellow-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(253, 216, 53, var(--tw-border-opacity))
}

.focus-within\:border-yellow-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(251, 192, 45, var(--tw-border-opacity))
}

.focus-within\:border-yellow-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 37, var(--tw-border-opacity))
}

.focus-within\:border-yellow-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 127, 23, var(--tw-border-opacity))
}

.focus-within\:border-yellow:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 235, 59, var(--tw-border-opacity))
}

.focus-within\:border-yellow-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 141, var(--tw-border-opacity))
}

.focus-within\:border-yellow-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 0, var(--tw-border-opacity))
}

.focus-within\:border-yellow-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 234, 0, var(--tw-border-opacity))
}

.focus-within\:border-yellow-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 214, 0, var(--tw-border-opacity))
}

.focus-within\:border-green-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(232, 245, 233, var(--tw-border-opacity))
}

.focus-within\:border-green-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(200, 230, 201, var(--tw-border-opacity))
}

.focus-within\:border-green-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(165, 214, 167, var(--tw-border-opacity))
}

.focus-within\:border-green-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(129, 199, 132, var(--tw-border-opacity))
}

.focus-within\:border-green-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(102, 187, 106, var(--tw-border-opacity))
}

.focus-within\:border-green-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity))
}

.focus-within\:border-green-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(67, 160, 71, var(--tw-border-opacity))
}

.focus-within\:border-green-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(56, 142, 60, var(--tw-border-opacity))
}

.focus-within\:border-green-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(46, 125, 50, var(--tw-border-opacity))
}

.focus-within\:border-green-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(27, 94, 32, var(--tw-border-opacity))
}

.focus-within\:border-green:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity))
}

.focus-within\:border-green-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(185, 246, 202, var(--tw-border-opacity))
}

.focus-within\:border-green-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(105, 240, 174, var(--tw-border-opacity))
}

.focus-within\:border-green-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 230, 118, var(--tw-border-opacity))
}

.focus-within\:border-green-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 200, 83, var(--tw-border-opacity))
}

.focus-within\:border-light-green-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(241, 248, 233, var(--tw-border-opacity))
}

.focus-within\:border-light-green-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(220, 237, 200, var(--tw-border-opacity))
}

.focus-within\:border-light-green-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(197, 225, 165, var(--tw-border-opacity))
}

.focus-within\:border-light-green-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(174, 213, 129, var(--tw-border-opacity))
}

.focus-within\:border-light-green-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(156, 204, 101, var(--tw-border-opacity))
}

.focus-within\:border-light-green-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-border-opacity))
}

.focus-within\:border-light-green-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(124, 179, 66, var(--tw-border-opacity))
}

.focus-within\:border-light-green-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(104, 159, 56, var(--tw-border-opacity))
}

.focus-within\:border-light-green-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(85, 139, 47, var(--tw-border-opacity))
}

.focus-within\:border-light-green-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(51, 105, 30, var(--tw-border-opacity))
}

.focus-within\:border-light-green:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-border-opacity))
}

.focus-within\:border-light-green-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(204, 255, 144, var(--tw-border-opacity))
}

.focus-within\:border-light-green-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(178, 255, 89, var(--tw-border-opacity))
}

.focus-within\:border-light-green-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(118, 255, 3, var(--tw-border-opacity))
}

.focus-within\:border-light-green-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(100, 221, 23, var(--tw-border-opacity))
}

.focus-within\:border-teal-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(224, 242, 241, var(--tw-border-opacity))
}

.focus-within\:border-teal-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(178, 223, 219, var(--tw-border-opacity))
}

.focus-within\:border-teal-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(128, 203, 196, var(--tw-border-opacity))
}

.focus-within\:border-teal-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(77, 182, 172, var(--tw-border-opacity))
}

.focus-within\:border-teal-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(38, 166, 154, var(--tw-border-opacity))
}

.focus-within\:border-teal-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-border-opacity))
}

.focus-within\:border-teal-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 137, 123, var(--tw-border-opacity))
}

.focus-within\:border-teal-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 121, 107, var(--tw-border-opacity))
}

.focus-within\:border-teal-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 105, 92, var(--tw-border-opacity))
}

.focus-within\:border-teal-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 77, 64, var(--tw-border-opacity))
}

.focus-within\:border-teal:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-border-opacity))
}

.focus-within\:border-teal-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(167, 255, 235, var(--tw-border-opacity))
}

.focus-within\:border-teal-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(100, 255, 218, var(--tw-border-opacity))
}

.focus-within\:border-teal-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(29, 233, 182, var(--tw-border-opacity))
}

.focus-within\:border-teal-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 191, 165, var(--tw-border-opacity))
}

.focus-within\:border-blue-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(227, 242, 253, var(--tw-border-opacity))
}

.focus-within\:border-blue-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(187, 222, 251, var(--tw-border-opacity))
}

.focus-within\:border-blue-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(144, 202, 249, var(--tw-border-opacity))
}

.focus-within\:border-blue-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(100, 181, 246, var(--tw-border-opacity))
}

.focus-within\:border-blue-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(66, 165, 245, var(--tw-border-opacity))
}

.focus-within\:border-blue-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-border-opacity))
}

.focus-within\:border-blue-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(30, 136, 229, var(--tw-border-opacity))
}

.focus-within\:border-blue-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(25, 118, 210, var(--tw-border-opacity))
}

.focus-within\:border-blue-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(21, 101, 192, var(--tw-border-opacity))
}

.focus-within\:border-blue-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(13, 71, 161, var(--tw-border-opacity))
}

.focus-within\:border-blue:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-border-opacity))
}

.focus-within\:border-blue-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(130, 177, 255, var(--tw-border-opacity))
}

.focus-within\:border-blue-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(68, 138, 255, var(--tw-border-opacity))
}

.focus-within\:border-blue-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(41, 121, 255, var(--tw-border-opacity))
}

.focus-within\:border-blue-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(41, 98, 255, var(--tw-border-opacity))
}

.focus-within\:border-light-blue-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(225, 245, 254, var(--tw-border-opacity))
}

.focus-within\:border-light-blue-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(179, 229, 252, var(--tw-border-opacity))
}

.focus-within\:border-light-blue-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(129, 212, 250, var(--tw-border-opacity))
}

.focus-within\:border-light-blue-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(79, 195, 247, var(--tw-border-opacity))
}

.focus-within\:border-light-blue-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(41, 182, 246, var(--tw-border-opacity))
}

.focus-within\:border-light-blue-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-border-opacity))
}

.focus-within\:border-light-blue-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(3, 155, 229, var(--tw-border-opacity))
}

.focus-within\:border-light-blue-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(2, 136, 209, var(--tw-border-opacity))
}

.focus-within\:border-light-blue-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(2, 119, 189, var(--tw-border-opacity))
}

.focus-within\:border-light-blue-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(1, 87, 155, var(--tw-border-opacity))
}

.focus-within\:border-light-blue:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-border-opacity))
}

.focus-within\:border-light-blue-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(128, 216, 255, var(--tw-border-opacity))
}

.focus-within\:border-light-blue-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(64, 196, 255, var(--tw-border-opacity))
}

.focus-within\:border-light-blue-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 176, 255, var(--tw-border-opacity))
}

.focus-within\:border-light-blue-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 145, 234, var(--tw-border-opacity))
}

.focus-within\:border-indigo-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(232, 234, 246, var(--tw-border-opacity))
}

.focus-within\:border-indigo-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(197, 202, 233, var(--tw-border-opacity))
}

.focus-within\:border-indigo-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(159, 168, 218, var(--tw-border-opacity))
}

.focus-within\:border-indigo-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(121, 134, 203, var(--tw-border-opacity))
}

.focus-within\:border-indigo-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(92, 107, 192, var(--tw-border-opacity))
}

.focus-within\:border-indigo-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-border-opacity))
}

.focus-within\:border-indigo-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(57, 73, 171, var(--tw-border-opacity))
}

.focus-within\:border-indigo-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(48, 63, 159, var(--tw-border-opacity))
}

.focus-within\:border-indigo-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(40, 53, 147, var(--tw-border-opacity))
}

.focus-within\:border-indigo-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(26, 35, 126, var(--tw-border-opacity))
}

.focus-within\:border-indigo:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-border-opacity))
}

.focus-within\:border-indigo-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(140, 158, 255, var(--tw-border-opacity))
}

.focus-within\:border-indigo-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(83, 109, 254, var(--tw-border-opacity))
}

.focus-within\:border-indigo-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(61, 90, 254, var(--tw-border-opacity))
}

.focus-within\:border-indigo-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(48, 79, 254, var(--tw-border-opacity))
}

.focus-within\:border-purple-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(243, 229, 245, var(--tw-border-opacity))
}

.focus-within\:border-purple-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(225, 190, 231, var(--tw-border-opacity))
}

.focus-within\:border-purple-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(206, 147, 216, var(--tw-border-opacity))
}

.focus-within\:border-purple-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(186, 104, 200, var(--tw-border-opacity))
}

.focus-within\:border-purple-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(171, 71, 188, var(--tw-border-opacity))
}

.focus-within\:border-purple-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-border-opacity))
}

.focus-within\:border-purple-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(142, 36, 170, var(--tw-border-opacity))
}

.focus-within\:border-purple-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(123, 31, 162, var(--tw-border-opacity))
}

.focus-within\:border-purple-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(106, 27, 154, var(--tw-border-opacity))
}

.focus-within\:border-purple-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(74, 20, 140, var(--tw-border-opacity))
}

.focus-within\:border-purple:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-border-opacity))
}

.focus-within\:border-purple-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(234, 128, 252, var(--tw-border-opacity))
}

.focus-within\:border-purple-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(224, 64, 251, var(--tw-border-opacity))
}

.focus-within\:border-purple-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(213, 0, 249, var(--tw-border-opacity))
}

.focus-within\:border-purple-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(170, 0, 255, var(--tw-border-opacity))
}

.focus-within\:border-deep-purple-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(237, 231, 246, var(--tw-border-opacity))
}

.focus-within\:border-deep-purple-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(209, 196, 233, var(--tw-border-opacity))
}

.focus-within\:border-deep-purple-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(179, 157, 219, var(--tw-border-opacity))
}

.focus-within\:border-deep-purple-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(149, 117, 205, var(--tw-border-opacity))
}

.focus-within\:border-deep-purple-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(126, 87, 194, var(--tw-border-opacity))
}

.focus-within\:border-deep-purple-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity))
}

.focus-within\:border-deep-purple-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(94, 53, 177, var(--tw-border-opacity))
}

.focus-within\:border-deep-purple-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(81, 45, 168, var(--tw-border-opacity))
}

.focus-within\:border-deep-purple-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(69, 39, 160, var(--tw-border-opacity))
}

.focus-within\:border-deep-purple-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(49, 27, 146, var(--tw-border-opacity))
}

.focus-within\:border-deep-purple:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity))
}

.focus-within\:border-deep-purple-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(179, 136, 255, var(--tw-border-opacity))
}

.focus-within\:border-deep-purple-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(124, 77, 255, var(--tw-border-opacity))
}

.focus-within\:border-deep-purple-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(101, 31, 255, var(--tw-border-opacity))
}

.focus-within\:border-deep-purple-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(98, 0, 234, var(--tw-border-opacity))
}

.focus-within\:border-pink-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(252, 228, 236, var(--tw-border-opacity))
}

.focus-within\:border-pink-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(248, 187, 208, var(--tw-border-opacity))
}

.focus-within\:border-pink-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(244, 143, 177, var(--tw-border-opacity))
}

.focus-within\:border-pink-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(240, 98, 146, var(--tw-border-opacity))
}

.focus-within\:border-pink-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(236, 64, 122, var(--tw-border-opacity))
}

.focus-within\:border-pink-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-border-opacity))
}

.focus-within\:border-pink-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(216, 27, 96, var(--tw-border-opacity))
}

.focus-within\:border-pink-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(194, 24, 91, var(--tw-border-opacity))
}

.focus-within\:border-pink-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(173, 20, 87, var(--tw-border-opacity))
}

.focus-within\:border-pink-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(136, 14, 79, var(--tw-border-opacity))
}

.focus-within\:border-pink:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-border-opacity))
}

.focus-within\:border-pink-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 128, 171, var(--tw-border-opacity))
}

.focus-within\:border-pink-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 64, 129, var(--tw-border-opacity))
}

.focus-within\:border-pink-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 0, 87, var(--tw-border-opacity))
}

.focus-within\:border-pink-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(197, 17, 98, var(--tw-border-opacity))
}

.focus-within\:border-lime-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(249, 251, 231, var(--tw-border-opacity))
}

.focus-within\:border-lime-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(240, 244, 195, var(--tw-border-opacity))
}

.focus-within\:border-lime-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(230, 238, 156, var(--tw-border-opacity))
}

.focus-within\:border-lime-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(220, 231, 117, var(--tw-border-opacity))
}

.focus-within\:border-lime-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(212, 225, 87, var(--tw-border-opacity))
}

.focus-within\:border-lime-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-border-opacity))
}

.focus-within\:border-lime-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(192, 202, 51, var(--tw-border-opacity))
}

.focus-within\:border-lime-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(175, 180, 43, var(--tw-border-opacity))
}

.focus-within\:border-lime-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(158, 157, 36, var(--tw-border-opacity))
}

.focus-within\:border-lime-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(130, 119, 23, var(--tw-border-opacity))
}

.focus-within\:border-lime:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-border-opacity))
}

.focus-within\:border-lime-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(244, 255, 129, var(--tw-border-opacity))
}

.focus-within\:border-lime-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(238, 255, 65, var(--tw-border-opacity))
}

.focus-within\:border-lime-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(198, 255, 0, var(--tw-border-opacity))
}

.focus-within\:border-lime-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(174, 234, 0, var(--tw-border-opacity))
}

.focus-within\:border-amber-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 248, 225, var(--tw-border-opacity))
}

.focus-within\:border-amber-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 236, 179, var(--tw-border-opacity))
}

.focus-within\:border-amber-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 224, 130, var(--tw-border-opacity))
}

.focus-within\:border-amber-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 213, 79, var(--tw-border-opacity))
}

.focus-within\:border-amber-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 202, 40, var(--tw-border-opacity))
}

.focus-within\:border-amber-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity))
}

.focus-within\:border-amber-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 179, 0, var(--tw-border-opacity))
}

.focus-within\:border-amber-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 160, 0, var(--tw-border-opacity))
}

.focus-within\:border-amber-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 143, 0, var(--tw-border-opacity))
}

.focus-within\:border-amber-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 111, 0, var(--tw-border-opacity))
}

.focus-within\:border-amber:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity))
}

.focus-within\:border-amber-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 229, 127, var(--tw-border-opacity))
}

.focus-within\:border-amber-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 215, 64, var(--tw-border-opacity))
}

.focus-within\:border-amber-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 196, 0, var(--tw-border-opacity))
}

.focus-within\:border-amber-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 171, 0, var(--tw-border-opacity))
}

.focus-within\:border-brown-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(239, 235, 233, var(--tw-border-opacity))
}

.focus-within\:border-brown-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(215, 204, 200, var(--tw-border-opacity))
}

.focus-within\:border-brown-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(188, 170, 164, var(--tw-border-opacity))
}

.focus-within\:border-brown-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(161, 136, 127, var(--tw-border-opacity))
}

.focus-within\:border-brown-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(141, 110, 99, var(--tw-border-opacity))
}

.focus-within\:border-brown-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity))
}

.focus-within\:border-brown-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(109, 76, 65, var(--tw-border-opacity))
}

.focus-within\:border-brown-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(93, 64, 55, var(--tw-border-opacity))
}

.focus-within\:border-brown-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(78, 52, 46, var(--tw-border-opacity))
}

.focus-within\:border-brown-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(62, 39, 35, var(--tw-border-opacity))
}

.focus-within\:border-brown:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity))
}

.focus-within\:border-brown-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(215, 204, 200, var(--tw-border-opacity))
}

.focus-within\:border-brown-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(188, 170, 164, var(--tw-border-opacity))
}

.focus-within\:border-brown-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(141, 110, 99, var(--tw-border-opacity))
}

.focus-within\:border-brown-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(93, 64, 55, var(--tw-border-opacity))
}

.focus-within\:border-blue-gray-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(236, 239, 241, var(--tw-border-opacity))
}

.focus-within\:border-blue-gray-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(207, 216, 220, var(--tw-border-opacity))
}

.focus-within\:border-blue-gray-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(176, 190, 197, var(--tw-border-opacity))
}

.focus-within\:border-blue-gray-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(144, 164, 174, var(--tw-border-opacity))
}

.focus-within\:border-blue-gray-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-border-opacity))
}

.focus-within\:border-blue-gray-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity))
}

.focus-within\:border-blue-gray-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(84, 110, 122, var(--tw-border-opacity))
}

.focus-within\:border-blue-gray-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(69, 90, 100, var(--tw-border-opacity))
}

.focus-within\:border-blue-gray-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(55, 71, 79, var(--tw-border-opacity))
}

.focus-within\:border-blue-gray-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(38, 50, 56, var(--tw-border-opacity))
}

.focus-within\:border-blue-gray:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity))
}

.focus-within\:border-blue-gray-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(207, 216, 220, var(--tw-border-opacity))
}

.focus-within\:border-blue-gray-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(176, 190, 197, var(--tw-border-opacity))
}

.focus-within\:border-blue-gray-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-border-opacity))
}

.focus-within\:border-blue-gray-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(69, 90, 100, var(--tw-border-opacity))
}

.focus-within\:border-cyan-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(224, 247, 250, var(--tw-border-opacity))
}

.focus-within\:border-cyan-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(178, 235, 242, var(--tw-border-opacity))
}

.focus-within\:border-cyan-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(128, 222, 234, var(--tw-border-opacity))
}

.focus-within\:border-cyan-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(77, 208, 225, var(--tw-border-opacity))
}

.focus-within\:border-cyan-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(38, 198, 218, var(--tw-border-opacity))
}

.focus-within\:border-cyan-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-border-opacity))
}

.focus-within\:border-cyan-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 172, 193, var(--tw-border-opacity))
}

.focus-within\:border-cyan-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 151, 167, var(--tw-border-opacity))
}

.focus-within\:border-cyan-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 131, 143, var(--tw-border-opacity))
}

.focus-within\:border-cyan-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 96, 100, var(--tw-border-opacity))
}

.focus-within\:border-cyan:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-border-opacity))
}

.focus-within\:border-cyan-A100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(132, 255, 255, var(--tw-border-opacity))
}

.focus-within\:border-cyan-A200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(24, 255, 255, var(--tw-border-opacity))
}

.focus-within\:border-cyan-A400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 229, 255, var(--tw-border-opacity))
}

.focus-within\:border-cyan-A700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 184, 212, var(--tw-border-opacity))
}

.hover\:border-transparent:hover {
  border-color: transparent
}

.hover\:border-current:hover {
  border-color: currentColor
}

.hover\:border-black:hover {
  --tw-border-opacity: 1;
  border-color: rgba(34, 41, 47, var(--tw-border-opacity))
}

.hover\:border-white:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

.hover\:border-grey-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity))
}

.hover\:border-grey-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity))
}

.hover\:border-grey-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-border-opacity))
}

.hover\:border-grey-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity))
}

.hover\:border-grey-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(189, 189, 189, var(--tw-border-opacity))
}

.hover\:border-grey-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.hover\:border-grey-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(117, 117, 117, var(--tw-border-opacity))
}

.hover\:border-grey-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.hover\:border-grey-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(66, 66, 66, var(--tw-border-opacity))
}

.hover\:border-grey-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(33, 33, 33, var(--tw-border-opacity))
}

.hover\:border-grey:hover {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.hover\:border-grey-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(213, 213, 213, var(--tw-border-opacity))
}

.hover\:border-grey-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(170, 170, 170, var(--tw-border-opacity))
}

.hover\:border-grey-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(48, 48, 48, var(--tw-border-opacity))
}

.hover\:border-grey-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.hover\:border-gray-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity))
}

.hover\:border-gray-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity))
}

.hover\:border-gray-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-border-opacity))
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity))
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(189, 189, 189, var(--tw-border-opacity))
}

.hover\:border-gray-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.hover\:border-gray-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(117, 117, 117, var(--tw-border-opacity))
}

.hover\:border-gray-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.hover\:border-gray-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(66, 66, 66, var(--tw-border-opacity))
}

.hover\:border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(33, 33, 33, var(--tw-border-opacity))
}

.hover\:border-gray:hover {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.hover\:border-gray-hover:hover {
  border-color: rgba(0, 0, 0, 0.04)
}

.hover\:border-gray-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(213, 213, 213, var(--tw-border-opacity))
}

.hover\:border-gray-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(170, 170, 170, var(--tw-border-opacity))
}

.hover\:border-gray-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(48, 48, 48, var(--tw-border-opacity))
}

.hover\:border-gray-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.hover\:border-red-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 235, 238, var(--tw-border-opacity))
}

.hover\:border-red-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 205, 210, var(--tw-border-opacity))
}

.hover\:border-red-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(239, 154, 154, var(--tw-border-opacity))
}

.hover\:border-red-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(229, 115, 115, var(--tw-border-opacity))
}

.hover\:border-red-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(239, 83, 80, var(--tw-border-opacity))
}

.hover\:border-red-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity))
}

.hover\:border-red-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(229, 57, 53, var(--tw-border-opacity))
}

.hover\:border-red-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(211, 47, 47, var(--tw-border-opacity))
}

.hover\:border-red-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(198, 40, 40, var(--tw-border-opacity))
}

.hover\:border-red-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(183, 28, 28, var(--tw-border-opacity))
}

.hover\:border-red:hover {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity))
}

.hover\:border-red-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 138, 128, var(--tw-border-opacity))
}

.hover\:border-red-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 82, 82, var(--tw-border-opacity))
}

.hover\:border-red-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 23, 68, var(--tw-border-opacity))
}

.hover\:border-red-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(213, 0, 0, var(--tw-border-opacity))
}

.hover\:border-orange-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 243, 224, var(--tw-border-opacity))
}

.hover\:border-orange-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 224, 178, var(--tw-border-opacity))
}

.hover\:border-orange-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 204, 128, var(--tw-border-opacity))
}

.hover\:border-orange-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 183, 77, var(--tw-border-opacity))
}

.hover\:border-orange-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 167, 38, var(--tw-border-opacity))
}

.hover\:border-orange-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-border-opacity))
}

.hover\:border-orange-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(251, 140, 0, var(--tw-border-opacity))
}

.hover\:border-orange-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 124, 0, var(--tw-border-opacity))
}

.hover\:border-orange-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(239, 108, 0, var(--tw-border-opacity))
}

.hover\:border-orange-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(230, 81, 0, var(--tw-border-opacity))
}

.hover\:border-orange:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-border-opacity))
}

.hover\:border-orange-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 209, 128, var(--tw-border-opacity))
}

.hover\:border-orange-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 171, 64, var(--tw-border-opacity))
}

.hover\:border-orange-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 145, 0, var(--tw-border-opacity))
}

.hover\:border-orange-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 109, 0, var(--tw-border-opacity))
}

.hover\:border-deep-orange-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(251, 233, 231, var(--tw-border-opacity))
}

.hover\:border-deep-orange-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 204, 188, var(--tw-border-opacity))
}

.hover\:border-deep-orange-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 171, 145, var(--tw-border-opacity))
}

.hover\:border-deep-orange-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 138, 101, var(--tw-border-opacity))
}

.hover\:border-deep-orange-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 112, 67, var(--tw-border-opacity))
}

.hover\:border-deep-orange-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-border-opacity))
}

.hover\:border-deep-orange-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(244, 81, 30, var(--tw-border-opacity))
}

.hover\:border-deep-orange-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(230, 74, 25, var(--tw-border-opacity))
}

.hover\:border-deep-orange-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(216, 67, 21, var(--tw-border-opacity))
}

.hover\:border-deep-orange-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(191, 54, 12, var(--tw-border-opacity))
}

.hover\:border-deep-orange:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-border-opacity))
}

.hover\:border-deep-orange-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 158, 128, var(--tw-border-opacity))
}

.hover\:border-deep-orange-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 110, 64, var(--tw-border-opacity))
}

.hover\:border-deep-orange-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 61, 0, var(--tw-border-opacity))
}

.hover\:border-deep-orange-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(221, 44, 0, var(--tw-border-opacity))
}

.hover\:border-yellow-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 253, 231, var(--tw-border-opacity))
}

.hover\:border-yellow-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 249, 196, var(--tw-border-opacity))
}

.hover\:border-yellow-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 245, 157, var(--tw-border-opacity))
}

.hover\:border-yellow-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 241, 118, var(--tw-border-opacity))
}

.hover\:border-yellow-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 238, 88, var(--tw-border-opacity))
}

.hover\:border-yellow-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 235, 59, var(--tw-border-opacity))
}

.hover\:border-yellow-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(253, 216, 53, var(--tw-border-opacity))
}

.hover\:border-yellow-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(251, 192, 45, var(--tw-border-opacity))
}

.hover\:border-yellow-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 37, var(--tw-border-opacity))
}

.hover\:border-yellow-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 127, 23, var(--tw-border-opacity))
}

.hover\:border-yellow:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 235, 59, var(--tw-border-opacity))
}

.hover\:border-yellow-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 141, var(--tw-border-opacity))
}

.hover\:border-yellow-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 0, var(--tw-border-opacity))
}

.hover\:border-yellow-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 234, 0, var(--tw-border-opacity))
}

.hover\:border-yellow-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 214, 0, var(--tw-border-opacity))
}

.hover\:border-green-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(232, 245, 233, var(--tw-border-opacity))
}

.hover\:border-green-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(200, 230, 201, var(--tw-border-opacity))
}

.hover\:border-green-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(165, 214, 167, var(--tw-border-opacity))
}

.hover\:border-green-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(129, 199, 132, var(--tw-border-opacity))
}

.hover\:border-green-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(102, 187, 106, var(--tw-border-opacity))
}

.hover\:border-green-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity))
}

.hover\:border-green-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(67, 160, 71, var(--tw-border-opacity))
}

.hover\:border-green-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(56, 142, 60, var(--tw-border-opacity))
}

.hover\:border-green-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(46, 125, 50, var(--tw-border-opacity))
}

.hover\:border-green-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(27, 94, 32, var(--tw-border-opacity))
}

.hover\:border-green:hover {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity))
}

.hover\:border-green-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(185, 246, 202, var(--tw-border-opacity))
}

.hover\:border-green-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(105, 240, 174, var(--tw-border-opacity))
}

.hover\:border-green-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 230, 118, var(--tw-border-opacity))
}

.hover\:border-green-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 200, 83, var(--tw-border-opacity))
}

.hover\:border-light-green-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(241, 248, 233, var(--tw-border-opacity))
}

.hover\:border-light-green-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(220, 237, 200, var(--tw-border-opacity))
}

.hover\:border-light-green-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(197, 225, 165, var(--tw-border-opacity))
}

.hover\:border-light-green-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(174, 213, 129, var(--tw-border-opacity))
}

.hover\:border-light-green-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(156, 204, 101, var(--tw-border-opacity))
}

.hover\:border-light-green-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-border-opacity))
}

.hover\:border-light-green-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(124, 179, 66, var(--tw-border-opacity))
}

.hover\:border-light-green-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(104, 159, 56, var(--tw-border-opacity))
}

.hover\:border-light-green-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(85, 139, 47, var(--tw-border-opacity))
}

.hover\:border-light-green-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(51, 105, 30, var(--tw-border-opacity))
}

.hover\:border-light-green:hover {
  --tw-border-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-border-opacity))
}

.hover\:border-light-green-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(204, 255, 144, var(--tw-border-opacity))
}

.hover\:border-light-green-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(178, 255, 89, var(--tw-border-opacity))
}

.hover\:border-light-green-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(118, 255, 3, var(--tw-border-opacity))
}

.hover\:border-light-green-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(100, 221, 23, var(--tw-border-opacity))
}

.hover\:border-teal-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(224, 242, 241, var(--tw-border-opacity))
}

.hover\:border-teal-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(178, 223, 219, var(--tw-border-opacity))
}

.hover\:border-teal-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(128, 203, 196, var(--tw-border-opacity))
}

.hover\:border-teal-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(77, 182, 172, var(--tw-border-opacity))
}

.hover\:border-teal-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(38, 166, 154, var(--tw-border-opacity))
}

.hover\:border-teal-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-border-opacity))
}

.hover\:border-teal-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 137, 123, var(--tw-border-opacity))
}

.hover\:border-teal-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 121, 107, var(--tw-border-opacity))
}

.hover\:border-teal-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 105, 92, var(--tw-border-opacity))
}

.hover\:border-teal-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 77, 64, var(--tw-border-opacity))
}

.hover\:border-teal:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-border-opacity))
}

.hover\:border-teal-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(167, 255, 235, var(--tw-border-opacity))
}

.hover\:border-teal-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(100, 255, 218, var(--tw-border-opacity))
}

.hover\:border-teal-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(29, 233, 182, var(--tw-border-opacity))
}

.hover\:border-teal-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 191, 165, var(--tw-border-opacity))
}

.hover\:border-blue-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(227, 242, 253, var(--tw-border-opacity))
}

.hover\:border-blue-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(187, 222, 251, var(--tw-border-opacity))
}

.hover\:border-blue-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(144, 202, 249, var(--tw-border-opacity))
}

.hover\:border-blue-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(100, 181, 246, var(--tw-border-opacity))
}

.hover\:border-blue-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(66, 165, 245, var(--tw-border-opacity))
}

.hover\:border-blue-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-border-opacity))
}

.hover\:border-blue-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(30, 136, 229, var(--tw-border-opacity))
}

.hover\:border-blue-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(25, 118, 210, var(--tw-border-opacity))
}

.hover\:border-blue-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(21, 101, 192, var(--tw-border-opacity))
}

.hover\:border-blue-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(13, 71, 161, var(--tw-border-opacity))
}

.hover\:border-blue:hover {
  --tw-border-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-border-opacity))
}

.hover\:border-blue-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(130, 177, 255, var(--tw-border-opacity))
}

.hover\:border-blue-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(68, 138, 255, var(--tw-border-opacity))
}

.hover\:border-blue-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(41, 121, 255, var(--tw-border-opacity))
}

.hover\:border-blue-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(41, 98, 255, var(--tw-border-opacity))
}

.hover\:border-light-blue-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(225, 245, 254, var(--tw-border-opacity))
}

.hover\:border-light-blue-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(179, 229, 252, var(--tw-border-opacity))
}

.hover\:border-light-blue-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(129, 212, 250, var(--tw-border-opacity))
}

.hover\:border-light-blue-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(79, 195, 247, var(--tw-border-opacity))
}

.hover\:border-light-blue-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(41, 182, 246, var(--tw-border-opacity))
}

.hover\:border-light-blue-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-border-opacity))
}

.hover\:border-light-blue-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(3, 155, 229, var(--tw-border-opacity))
}

.hover\:border-light-blue-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(2, 136, 209, var(--tw-border-opacity))
}

.hover\:border-light-blue-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(2, 119, 189, var(--tw-border-opacity))
}

.hover\:border-light-blue-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(1, 87, 155, var(--tw-border-opacity))
}

.hover\:border-light-blue:hover {
  --tw-border-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-border-opacity))
}

.hover\:border-light-blue-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(128, 216, 255, var(--tw-border-opacity))
}

.hover\:border-light-blue-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(64, 196, 255, var(--tw-border-opacity))
}

.hover\:border-light-blue-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 176, 255, var(--tw-border-opacity))
}

.hover\:border-light-blue-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 145, 234, var(--tw-border-opacity))
}

.hover\:border-indigo-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(232, 234, 246, var(--tw-border-opacity))
}

.hover\:border-indigo-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(197, 202, 233, var(--tw-border-opacity))
}

.hover\:border-indigo-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(159, 168, 218, var(--tw-border-opacity))
}

.hover\:border-indigo-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(121, 134, 203, var(--tw-border-opacity))
}

.hover\:border-indigo-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(92, 107, 192, var(--tw-border-opacity))
}

.hover\:border-indigo-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-border-opacity))
}

.hover\:border-indigo-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(57, 73, 171, var(--tw-border-opacity))
}

.hover\:border-indigo-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(48, 63, 159, var(--tw-border-opacity))
}

.hover\:border-indigo-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(40, 53, 147, var(--tw-border-opacity))
}

.hover\:border-indigo-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(26, 35, 126, var(--tw-border-opacity))
}

.hover\:border-indigo:hover {
  --tw-border-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-border-opacity))
}

.hover\:border-indigo-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(140, 158, 255, var(--tw-border-opacity))
}

.hover\:border-indigo-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(83, 109, 254, var(--tw-border-opacity))
}

.hover\:border-indigo-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(61, 90, 254, var(--tw-border-opacity))
}

.hover\:border-indigo-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(48, 79, 254, var(--tw-border-opacity))
}

.hover\:border-purple-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(243, 229, 245, var(--tw-border-opacity))
}

.hover\:border-purple-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(225, 190, 231, var(--tw-border-opacity))
}

.hover\:border-purple-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(206, 147, 216, var(--tw-border-opacity))
}

.hover\:border-purple-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(186, 104, 200, var(--tw-border-opacity))
}

.hover\:border-purple-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(171, 71, 188, var(--tw-border-opacity))
}

.hover\:border-purple-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-border-opacity))
}

.hover\:border-purple-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(142, 36, 170, var(--tw-border-opacity))
}

.hover\:border-purple-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(123, 31, 162, var(--tw-border-opacity))
}

.hover\:border-purple-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(106, 27, 154, var(--tw-border-opacity))
}

.hover\:border-purple-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(74, 20, 140, var(--tw-border-opacity))
}

.hover\:border-purple:hover {
  --tw-border-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-border-opacity))
}

.hover\:border-purple-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(234, 128, 252, var(--tw-border-opacity))
}

.hover\:border-purple-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(224, 64, 251, var(--tw-border-opacity))
}

.hover\:border-purple-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(213, 0, 249, var(--tw-border-opacity))
}

.hover\:border-purple-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(170, 0, 255, var(--tw-border-opacity))
}

.hover\:border-deep-purple-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(237, 231, 246, var(--tw-border-opacity))
}

.hover\:border-deep-purple-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(209, 196, 233, var(--tw-border-opacity))
}

.hover\:border-deep-purple-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(179, 157, 219, var(--tw-border-opacity))
}

.hover\:border-deep-purple-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(149, 117, 205, var(--tw-border-opacity))
}

.hover\:border-deep-purple-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(126, 87, 194, var(--tw-border-opacity))
}

.hover\:border-deep-purple-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity))
}

.hover\:border-deep-purple-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(94, 53, 177, var(--tw-border-opacity))
}

.hover\:border-deep-purple-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(81, 45, 168, var(--tw-border-opacity))
}

.hover\:border-deep-purple-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(69, 39, 160, var(--tw-border-opacity))
}

.hover\:border-deep-purple-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(49, 27, 146, var(--tw-border-opacity))
}

.hover\:border-deep-purple:hover {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity))
}

.hover\:border-deep-purple-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(179, 136, 255, var(--tw-border-opacity))
}

.hover\:border-deep-purple-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(124, 77, 255, var(--tw-border-opacity))
}

.hover\:border-deep-purple-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(101, 31, 255, var(--tw-border-opacity))
}

.hover\:border-deep-purple-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(98, 0, 234, var(--tw-border-opacity))
}

.hover\:border-pink-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(252, 228, 236, var(--tw-border-opacity))
}

.hover\:border-pink-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(248, 187, 208, var(--tw-border-opacity))
}

.hover\:border-pink-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(244, 143, 177, var(--tw-border-opacity))
}

.hover\:border-pink-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(240, 98, 146, var(--tw-border-opacity))
}

.hover\:border-pink-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(236, 64, 122, var(--tw-border-opacity))
}

.hover\:border-pink-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-border-opacity))
}

.hover\:border-pink-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(216, 27, 96, var(--tw-border-opacity))
}

.hover\:border-pink-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(194, 24, 91, var(--tw-border-opacity))
}

.hover\:border-pink-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(173, 20, 87, var(--tw-border-opacity))
}

.hover\:border-pink-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(136, 14, 79, var(--tw-border-opacity))
}

.hover\:border-pink:hover {
  --tw-border-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-border-opacity))
}

.hover\:border-pink-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 128, 171, var(--tw-border-opacity))
}

.hover\:border-pink-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 64, 129, var(--tw-border-opacity))
}

.hover\:border-pink-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 0, 87, var(--tw-border-opacity))
}

.hover\:border-pink-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(197, 17, 98, var(--tw-border-opacity))
}

.hover\:border-lime-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(249, 251, 231, var(--tw-border-opacity))
}

.hover\:border-lime-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(240, 244, 195, var(--tw-border-opacity))
}

.hover\:border-lime-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(230, 238, 156, var(--tw-border-opacity))
}

.hover\:border-lime-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(220, 231, 117, var(--tw-border-opacity))
}

.hover\:border-lime-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(212, 225, 87, var(--tw-border-opacity))
}

.hover\:border-lime-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-border-opacity))
}

.hover\:border-lime-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(192, 202, 51, var(--tw-border-opacity))
}

.hover\:border-lime-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(175, 180, 43, var(--tw-border-opacity))
}

.hover\:border-lime-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(158, 157, 36, var(--tw-border-opacity))
}

.hover\:border-lime-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(130, 119, 23, var(--tw-border-opacity))
}

.hover\:border-lime:hover {
  --tw-border-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-border-opacity))
}

.hover\:border-lime-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(244, 255, 129, var(--tw-border-opacity))
}

.hover\:border-lime-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(238, 255, 65, var(--tw-border-opacity))
}

.hover\:border-lime-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(198, 255, 0, var(--tw-border-opacity))
}

.hover\:border-lime-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(174, 234, 0, var(--tw-border-opacity))
}

.hover\:border-amber-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 248, 225, var(--tw-border-opacity))
}

.hover\:border-amber-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 236, 179, var(--tw-border-opacity))
}

.hover\:border-amber-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 224, 130, var(--tw-border-opacity))
}

.hover\:border-amber-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 213, 79, var(--tw-border-opacity))
}

.hover\:border-amber-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 202, 40, var(--tw-border-opacity))
}

.hover\:border-amber-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity))
}

.hover\:border-amber-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 179, 0, var(--tw-border-opacity))
}

.hover\:border-amber-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 160, 0, var(--tw-border-opacity))
}

.hover\:border-amber-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 143, 0, var(--tw-border-opacity))
}

.hover\:border-amber-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 111, 0, var(--tw-border-opacity))
}

.hover\:border-amber:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity))
}

.hover\:border-amber-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 229, 127, var(--tw-border-opacity))
}

.hover\:border-amber-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 215, 64, var(--tw-border-opacity))
}

.hover\:border-amber-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 196, 0, var(--tw-border-opacity))
}

.hover\:border-amber-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 171, 0, var(--tw-border-opacity))
}

.hover\:border-brown-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(239, 235, 233, var(--tw-border-opacity))
}

.hover\:border-brown-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(215, 204, 200, var(--tw-border-opacity))
}

.hover\:border-brown-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(188, 170, 164, var(--tw-border-opacity))
}

.hover\:border-brown-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(161, 136, 127, var(--tw-border-opacity))
}

.hover\:border-brown-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(141, 110, 99, var(--tw-border-opacity))
}

.hover\:border-brown-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity))
}

.hover\:border-brown-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(109, 76, 65, var(--tw-border-opacity))
}

.hover\:border-brown-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(93, 64, 55, var(--tw-border-opacity))
}

.hover\:border-brown-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(78, 52, 46, var(--tw-border-opacity))
}

.hover\:border-brown-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(62, 39, 35, var(--tw-border-opacity))
}

.hover\:border-brown:hover {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity))
}

.hover\:border-brown-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(215, 204, 200, var(--tw-border-opacity))
}

.hover\:border-brown-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(188, 170, 164, var(--tw-border-opacity))
}

.hover\:border-brown-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(141, 110, 99, var(--tw-border-opacity))
}

.hover\:border-brown-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(93, 64, 55, var(--tw-border-opacity))
}

.hover\:border-blue-gray-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(236, 239, 241, var(--tw-border-opacity))
}

.hover\:border-blue-gray-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(207, 216, 220, var(--tw-border-opacity))
}

.hover\:border-blue-gray-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(176, 190, 197, var(--tw-border-opacity))
}

.hover\:border-blue-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(144, 164, 174, var(--tw-border-opacity))
}

.hover\:border-blue-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-border-opacity))
}

.hover\:border-blue-gray-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity))
}

.hover\:border-blue-gray-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(84, 110, 122, var(--tw-border-opacity))
}

.hover\:border-blue-gray-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(69, 90, 100, var(--tw-border-opacity))
}

.hover\:border-blue-gray-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(55, 71, 79, var(--tw-border-opacity))
}

.hover\:border-blue-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(38, 50, 56, var(--tw-border-opacity))
}

.hover\:border-blue-gray:hover {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity))
}

.hover\:border-blue-gray-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(207, 216, 220, var(--tw-border-opacity))
}

.hover\:border-blue-gray-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(176, 190, 197, var(--tw-border-opacity))
}

.hover\:border-blue-gray-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-border-opacity))
}

.hover\:border-blue-gray-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(69, 90, 100, var(--tw-border-opacity))
}

.hover\:border-cyan-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(224, 247, 250, var(--tw-border-opacity))
}

.hover\:border-cyan-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(178, 235, 242, var(--tw-border-opacity))
}

.hover\:border-cyan-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(128, 222, 234, var(--tw-border-opacity))
}

.hover\:border-cyan-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(77, 208, 225, var(--tw-border-opacity))
}

.hover\:border-cyan-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(38, 198, 218, var(--tw-border-opacity))
}

.hover\:border-cyan-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-border-opacity))
}

.hover\:border-cyan-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 172, 193, var(--tw-border-opacity))
}

.hover\:border-cyan-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 151, 167, var(--tw-border-opacity))
}

.hover\:border-cyan-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 131, 143, var(--tw-border-opacity))
}

.hover\:border-cyan-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 96, 100, var(--tw-border-opacity))
}

.hover\:border-cyan:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-border-opacity))
}

.hover\:border-cyan-A100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(132, 255, 255, var(--tw-border-opacity))
}

.hover\:border-cyan-A200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(24, 255, 255, var(--tw-border-opacity))
}

.hover\:border-cyan-A400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 229, 255, var(--tw-border-opacity))
}

.hover\:border-cyan-A700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 184, 212, var(--tw-border-opacity))
}

.focus\:border-transparent:focus {
  border-color: transparent
}

.focus\:border-current:focus {
  border-color: currentColor
}

.focus\:border-black:focus {
  --tw-border-opacity: 1;
  border-color: rgba(34, 41, 47, var(--tw-border-opacity))
}

.focus\:border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

.focus\:border-grey-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity))
}

.focus\:border-grey-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity))
}

.focus\:border-grey-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-border-opacity))
}

.focus\:border-grey-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity))
}

.focus\:border-grey-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(189, 189, 189, var(--tw-border-opacity))
}

.focus\:border-grey-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.focus\:border-grey-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(117, 117, 117, var(--tw-border-opacity))
}

.focus\:border-grey-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.focus\:border-grey-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(66, 66, 66, var(--tw-border-opacity))
}

.focus\:border-grey-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(33, 33, 33, var(--tw-border-opacity))
}

.focus\:border-grey:focus {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.focus\:border-grey-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(213, 213, 213, var(--tw-border-opacity))
}

.focus\:border-grey-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 170, 170, var(--tw-border-opacity))
}

.focus\:border-grey-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(48, 48, 48, var(--tw-border-opacity))
}

.focus\:border-grey-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.focus\:border-gray-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity))
}

.focus\:border-gray-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity))
}

.focus\:border-gray-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-border-opacity))
}

.focus\:border-gray-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity))
}

.focus\:border-gray-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(189, 189, 189, var(--tw-border-opacity))
}

.focus\:border-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.focus\:border-gray-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(117, 117, 117, var(--tw-border-opacity))
}

.focus\:border-gray-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.focus\:border-gray-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(66, 66, 66, var(--tw-border-opacity))
}

.focus\:border-gray-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(33, 33, 33, var(--tw-border-opacity))
}

.focus\:border-gray:focus {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity))
}

.focus\:border-gray-hover:focus {
  border-color: rgba(0, 0, 0, 0.04)
}

.focus\:border-gray-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(213, 213, 213, var(--tw-border-opacity))
}

.focus\:border-gray-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 170, 170, var(--tw-border-opacity))
}

.focus\:border-gray-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(48, 48, 48, var(--tw-border-opacity))
}

.focus\:border-gray-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity))
}

.focus\:border-red-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 235, 238, var(--tw-border-opacity))
}

.focus\:border-red-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 205, 210, var(--tw-border-opacity))
}

.focus\:border-red-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 154, 154, var(--tw-border-opacity))
}

.focus\:border-red-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(229, 115, 115, var(--tw-border-opacity))
}

.focus\:border-red-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 83, 80, var(--tw-border-opacity))
}

.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity))
}

.focus\:border-red-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(229, 57, 53, var(--tw-border-opacity))
}

.focus\:border-red-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(211, 47, 47, var(--tw-border-opacity))
}

.focus\:border-red-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(198, 40, 40, var(--tw-border-opacity))
}

.focus\:border-red-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(183, 28, 28, var(--tw-border-opacity))
}

.focus\:border-red:focus {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity))
}

.focus\:border-red-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 138, 128, var(--tw-border-opacity))
}

.focus\:border-red-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 82, 82, var(--tw-border-opacity))
}

.focus\:border-red-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 23, 68, var(--tw-border-opacity))
}

.focus\:border-red-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(213, 0, 0, var(--tw-border-opacity))
}

.focus\:border-orange-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 243, 224, var(--tw-border-opacity))
}

.focus\:border-orange-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 224, 178, var(--tw-border-opacity))
}

.focus\:border-orange-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 204, 128, var(--tw-border-opacity))
}

.focus\:border-orange-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 183, 77, var(--tw-border-opacity))
}

.focus\:border-orange-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 167, 38, var(--tw-border-opacity))
}

.focus\:border-orange-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-border-opacity))
}

.focus\:border-orange-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 140, 0, var(--tw-border-opacity))
}

.focus\:border-orange-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 124, 0, var(--tw-border-opacity))
}

.focus\:border-orange-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 108, 0, var(--tw-border-opacity))
}

.focus\:border-orange-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(230, 81, 0, var(--tw-border-opacity))
}

.focus\:border-orange:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-border-opacity))
}

.focus\:border-orange-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 209, 128, var(--tw-border-opacity))
}

.focus\:border-orange-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 171, 64, var(--tw-border-opacity))
}

.focus\:border-orange-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 145, 0, var(--tw-border-opacity))
}

.focus\:border-orange-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 109, 0, var(--tw-border-opacity))
}

.focus\:border-deep-orange-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 233, 231, var(--tw-border-opacity))
}

.focus\:border-deep-orange-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 204, 188, var(--tw-border-opacity))
}

.focus\:border-deep-orange-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 171, 145, var(--tw-border-opacity))
}

.focus\:border-deep-orange-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 138, 101, var(--tw-border-opacity))
}

.focus\:border-deep-orange-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 112, 67, var(--tw-border-opacity))
}

.focus\:border-deep-orange-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-border-opacity))
}

.focus\:border-deep-orange-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(244, 81, 30, var(--tw-border-opacity))
}

.focus\:border-deep-orange-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(230, 74, 25, var(--tw-border-opacity))
}

.focus\:border-deep-orange-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(216, 67, 21, var(--tw-border-opacity))
}

.focus\:border-deep-orange-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(191, 54, 12, var(--tw-border-opacity))
}

.focus\:border-deep-orange:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-border-opacity))
}

.focus\:border-deep-orange-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 158, 128, var(--tw-border-opacity))
}

.focus\:border-deep-orange-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 110, 64, var(--tw-border-opacity))
}

.focus\:border-deep-orange-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 61, 0, var(--tw-border-opacity))
}

.focus\:border-deep-orange-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(221, 44, 0, var(--tw-border-opacity))
}

.focus\:border-yellow-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 253, 231, var(--tw-border-opacity))
}

.focus\:border-yellow-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 249, 196, var(--tw-border-opacity))
}

.focus\:border-yellow-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 245, 157, var(--tw-border-opacity))
}

.focus\:border-yellow-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 241, 118, var(--tw-border-opacity))
}

.focus\:border-yellow-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 238, 88, var(--tw-border-opacity))
}

.focus\:border-yellow-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 235, 59, var(--tw-border-opacity))
}

.focus\:border-yellow-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 216, 53, var(--tw-border-opacity))
}

.focus\:border-yellow-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 192, 45, var(--tw-border-opacity))
}

.focus\:border-yellow-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 37, var(--tw-border-opacity))
}

.focus\:border-yellow-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 127, 23, var(--tw-border-opacity))
}

.focus\:border-yellow:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 235, 59, var(--tw-border-opacity))
}

.focus\:border-yellow-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 141, var(--tw-border-opacity))
}

.focus\:border-yellow-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 0, var(--tw-border-opacity))
}

.focus\:border-yellow-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 234, 0, var(--tw-border-opacity))
}

.focus\:border-yellow-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 214, 0, var(--tw-border-opacity))
}

.focus\:border-green-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(232, 245, 233, var(--tw-border-opacity))
}

.focus\:border-green-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(200, 230, 201, var(--tw-border-opacity))
}

.focus\:border-green-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(165, 214, 167, var(--tw-border-opacity))
}

.focus\:border-green-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(129, 199, 132, var(--tw-border-opacity))
}

.focus\:border-green-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(102, 187, 106, var(--tw-border-opacity))
}

.focus\:border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity))
}

.focus\:border-green-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(67, 160, 71, var(--tw-border-opacity))
}

.focus\:border-green-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(56, 142, 60, var(--tw-border-opacity))
}

.focus\:border-green-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(46, 125, 50, var(--tw-border-opacity))
}

.focus\:border-green-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(27, 94, 32, var(--tw-border-opacity))
}

.focus\:border-green:focus {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity))
}

.focus\:border-green-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(185, 246, 202, var(--tw-border-opacity))
}

.focus\:border-green-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(105, 240, 174, var(--tw-border-opacity))
}

.focus\:border-green-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 230, 118, var(--tw-border-opacity))
}

.focus\:border-green-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 200, 83, var(--tw-border-opacity))
}

.focus\:border-light-green-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(241, 248, 233, var(--tw-border-opacity))
}

.focus\:border-light-green-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(220, 237, 200, var(--tw-border-opacity))
}

.focus\:border-light-green-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(197, 225, 165, var(--tw-border-opacity))
}

.focus\:border-light-green-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(174, 213, 129, var(--tw-border-opacity))
}

.focus\:border-light-green-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(156, 204, 101, var(--tw-border-opacity))
}

.focus\:border-light-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-border-opacity))
}

.focus\:border-light-green-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(124, 179, 66, var(--tw-border-opacity))
}

.focus\:border-light-green-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(104, 159, 56, var(--tw-border-opacity))
}

.focus\:border-light-green-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(85, 139, 47, var(--tw-border-opacity))
}

.focus\:border-light-green-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(51, 105, 30, var(--tw-border-opacity))
}

.focus\:border-light-green:focus {
  --tw-border-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-border-opacity))
}

.focus\:border-light-green-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(204, 255, 144, var(--tw-border-opacity))
}

.focus\:border-light-green-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(178, 255, 89, var(--tw-border-opacity))
}

.focus\:border-light-green-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(118, 255, 3, var(--tw-border-opacity))
}

.focus\:border-light-green-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(100, 221, 23, var(--tw-border-opacity))
}

.focus\:border-teal-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(224, 242, 241, var(--tw-border-opacity))
}

.focus\:border-teal-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(178, 223, 219, var(--tw-border-opacity))
}

.focus\:border-teal-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(128, 203, 196, var(--tw-border-opacity))
}

.focus\:border-teal-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(77, 182, 172, var(--tw-border-opacity))
}

.focus\:border-teal-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(38, 166, 154, var(--tw-border-opacity))
}

.focus\:border-teal-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-border-opacity))
}

.focus\:border-teal-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 137, 123, var(--tw-border-opacity))
}

.focus\:border-teal-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 121, 107, var(--tw-border-opacity))
}

.focus\:border-teal-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 105, 92, var(--tw-border-opacity))
}

.focus\:border-teal-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 77, 64, var(--tw-border-opacity))
}

.focus\:border-teal:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-border-opacity))
}

.focus\:border-teal-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(167, 255, 235, var(--tw-border-opacity))
}

.focus\:border-teal-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(100, 255, 218, var(--tw-border-opacity))
}

.focus\:border-teal-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(29, 233, 182, var(--tw-border-opacity))
}

.focus\:border-teal-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 191, 165, var(--tw-border-opacity))
}

.focus\:border-blue-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(227, 242, 253, var(--tw-border-opacity))
}

.focus\:border-blue-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(187, 222, 251, var(--tw-border-opacity))
}

.focus\:border-blue-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(144, 202, 249, var(--tw-border-opacity))
}

.focus\:border-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(100, 181, 246, var(--tw-border-opacity))
}

.focus\:border-blue-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(66, 165, 245, var(--tw-border-opacity))
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-border-opacity))
}

.focus\:border-blue-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(30, 136, 229, var(--tw-border-opacity))
}

.focus\:border-blue-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(25, 118, 210, var(--tw-border-opacity))
}

.focus\:border-blue-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(21, 101, 192, var(--tw-border-opacity))
}

.focus\:border-blue-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(13, 71, 161, var(--tw-border-opacity))
}

.focus\:border-blue:focus {
  --tw-border-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-border-opacity))
}

.focus\:border-blue-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(130, 177, 255, var(--tw-border-opacity))
}

.focus\:border-blue-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(68, 138, 255, var(--tw-border-opacity))
}

.focus\:border-blue-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(41, 121, 255, var(--tw-border-opacity))
}

.focus\:border-blue-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(41, 98, 255, var(--tw-border-opacity))
}

.focus\:border-light-blue-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(225, 245, 254, var(--tw-border-opacity))
}

.focus\:border-light-blue-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(179, 229, 252, var(--tw-border-opacity))
}

.focus\:border-light-blue-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(129, 212, 250, var(--tw-border-opacity))
}

.focus\:border-light-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(79, 195, 247, var(--tw-border-opacity))
}

.focus\:border-light-blue-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(41, 182, 246, var(--tw-border-opacity))
}

.focus\:border-light-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-border-opacity))
}

.focus\:border-light-blue-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(3, 155, 229, var(--tw-border-opacity))
}

.focus\:border-light-blue-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(2, 136, 209, var(--tw-border-opacity))
}

.focus\:border-light-blue-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(2, 119, 189, var(--tw-border-opacity))
}

.focus\:border-light-blue-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(1, 87, 155, var(--tw-border-opacity))
}

.focus\:border-light-blue:focus {
  --tw-border-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-border-opacity))
}

.focus\:border-light-blue-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(128, 216, 255, var(--tw-border-opacity))
}

.focus\:border-light-blue-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(64, 196, 255, var(--tw-border-opacity))
}

.focus\:border-light-blue-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 176, 255, var(--tw-border-opacity))
}

.focus\:border-light-blue-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 145, 234, var(--tw-border-opacity))
}

.focus\:border-indigo-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(232, 234, 246, var(--tw-border-opacity))
}

.focus\:border-indigo-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(197, 202, 233, var(--tw-border-opacity))
}

.focus\:border-indigo-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(159, 168, 218, var(--tw-border-opacity))
}

.focus\:border-indigo-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(121, 134, 203, var(--tw-border-opacity))
}

.focus\:border-indigo-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(92, 107, 192, var(--tw-border-opacity))
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-border-opacity))
}

.focus\:border-indigo-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(57, 73, 171, var(--tw-border-opacity))
}

.focus\:border-indigo-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(48, 63, 159, var(--tw-border-opacity))
}

.focus\:border-indigo-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(40, 53, 147, var(--tw-border-opacity))
}

.focus\:border-indigo-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(26, 35, 126, var(--tw-border-opacity))
}

.focus\:border-indigo:focus {
  --tw-border-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-border-opacity))
}

.focus\:border-indigo-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(140, 158, 255, var(--tw-border-opacity))
}

.focus\:border-indigo-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(83, 109, 254, var(--tw-border-opacity))
}

.focus\:border-indigo-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(61, 90, 254, var(--tw-border-opacity))
}

.focus\:border-indigo-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(48, 79, 254, var(--tw-border-opacity))
}

.focus\:border-purple-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(243, 229, 245, var(--tw-border-opacity))
}

.focus\:border-purple-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(225, 190, 231, var(--tw-border-opacity))
}

.focus\:border-purple-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(206, 147, 216, var(--tw-border-opacity))
}

.focus\:border-purple-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(186, 104, 200, var(--tw-border-opacity))
}

.focus\:border-purple-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(171, 71, 188, var(--tw-border-opacity))
}

.focus\:border-purple-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-border-opacity))
}

.focus\:border-purple-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(142, 36, 170, var(--tw-border-opacity))
}

.focus\:border-purple-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(123, 31, 162, var(--tw-border-opacity))
}

.focus\:border-purple-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(106, 27, 154, var(--tw-border-opacity))
}

.focus\:border-purple-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(74, 20, 140, var(--tw-border-opacity))
}

.focus\:border-purple:focus {
  --tw-border-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-border-opacity))
}

.focus\:border-purple-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(234, 128, 252, var(--tw-border-opacity))
}

.focus\:border-purple-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(224, 64, 251, var(--tw-border-opacity))
}

.focus\:border-purple-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(213, 0, 249, var(--tw-border-opacity))
}

.focus\:border-purple-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 0, 255, var(--tw-border-opacity))
}

.focus\:border-deep-purple-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(237, 231, 246, var(--tw-border-opacity))
}

.focus\:border-deep-purple-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(209, 196, 233, var(--tw-border-opacity))
}

.focus\:border-deep-purple-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(179, 157, 219, var(--tw-border-opacity))
}

.focus\:border-deep-purple-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(149, 117, 205, var(--tw-border-opacity))
}

.focus\:border-deep-purple-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(126, 87, 194, var(--tw-border-opacity))
}

.focus\:border-deep-purple-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity))
}

.focus\:border-deep-purple-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(94, 53, 177, var(--tw-border-opacity))
}

.focus\:border-deep-purple-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(81, 45, 168, var(--tw-border-opacity))
}

.focus\:border-deep-purple-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(69, 39, 160, var(--tw-border-opacity))
}

.focus\:border-deep-purple-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(49, 27, 146, var(--tw-border-opacity))
}

.focus\:border-deep-purple:focus {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity))
}

.focus\:border-deep-purple-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(179, 136, 255, var(--tw-border-opacity))
}

.focus\:border-deep-purple-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(124, 77, 255, var(--tw-border-opacity))
}

.focus\:border-deep-purple-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(101, 31, 255, var(--tw-border-opacity))
}

.focus\:border-deep-purple-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(98, 0, 234, var(--tw-border-opacity))
}

.focus\:border-pink-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(252, 228, 236, var(--tw-border-opacity))
}

.focus\:border-pink-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(248, 187, 208, var(--tw-border-opacity))
}

.focus\:border-pink-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(244, 143, 177, var(--tw-border-opacity))
}

.focus\:border-pink-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(240, 98, 146, var(--tw-border-opacity))
}

.focus\:border-pink-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(236, 64, 122, var(--tw-border-opacity))
}

.focus\:border-pink-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-border-opacity))
}

.focus\:border-pink-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(216, 27, 96, var(--tw-border-opacity))
}

.focus\:border-pink-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(194, 24, 91, var(--tw-border-opacity))
}

.focus\:border-pink-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(173, 20, 87, var(--tw-border-opacity))
}

.focus\:border-pink-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(136, 14, 79, var(--tw-border-opacity))
}

.focus\:border-pink:focus {
  --tw-border-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-border-opacity))
}

.focus\:border-pink-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 128, 171, var(--tw-border-opacity))
}

.focus\:border-pink-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 64, 129, var(--tw-border-opacity))
}

.focus\:border-pink-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 0, 87, var(--tw-border-opacity))
}

.focus\:border-pink-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(197, 17, 98, var(--tw-border-opacity))
}

.focus\:border-lime-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(249, 251, 231, var(--tw-border-opacity))
}

.focus\:border-lime-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(240, 244, 195, var(--tw-border-opacity))
}

.focus\:border-lime-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(230, 238, 156, var(--tw-border-opacity))
}

.focus\:border-lime-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(220, 231, 117, var(--tw-border-opacity))
}

.focus\:border-lime-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(212, 225, 87, var(--tw-border-opacity))
}

.focus\:border-lime-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-border-opacity))
}

.focus\:border-lime-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(192, 202, 51, var(--tw-border-opacity))
}

.focus\:border-lime-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(175, 180, 43, var(--tw-border-opacity))
}

.focus\:border-lime-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(158, 157, 36, var(--tw-border-opacity))
}

.focus\:border-lime-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(130, 119, 23, var(--tw-border-opacity))
}

.focus\:border-lime:focus {
  --tw-border-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-border-opacity))
}

.focus\:border-lime-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(244, 255, 129, var(--tw-border-opacity))
}

.focus\:border-lime-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(238, 255, 65, var(--tw-border-opacity))
}

.focus\:border-lime-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(198, 255, 0, var(--tw-border-opacity))
}

.focus\:border-lime-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(174, 234, 0, var(--tw-border-opacity))
}

.focus\:border-amber-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 248, 225, var(--tw-border-opacity))
}

.focus\:border-amber-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 236, 179, var(--tw-border-opacity))
}

.focus\:border-amber-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 224, 130, var(--tw-border-opacity))
}

.focus\:border-amber-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 213, 79, var(--tw-border-opacity))
}

.focus\:border-amber-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 202, 40, var(--tw-border-opacity))
}

.focus\:border-amber-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity))
}

.focus\:border-amber-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 179, 0, var(--tw-border-opacity))
}

.focus\:border-amber-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 160, 0, var(--tw-border-opacity))
}

.focus\:border-amber-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 143, 0, var(--tw-border-opacity))
}

.focus\:border-amber-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 111, 0, var(--tw-border-opacity))
}

.focus\:border-amber:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity))
}

.focus\:border-amber-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 229, 127, var(--tw-border-opacity))
}

.focus\:border-amber-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 215, 64, var(--tw-border-opacity))
}

.focus\:border-amber-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 196, 0, var(--tw-border-opacity))
}

.focus\:border-amber-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 171, 0, var(--tw-border-opacity))
}

.focus\:border-brown-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 235, 233, var(--tw-border-opacity))
}

.focus\:border-brown-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(215, 204, 200, var(--tw-border-opacity))
}

.focus\:border-brown-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(188, 170, 164, var(--tw-border-opacity))
}

.focus\:border-brown-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(161, 136, 127, var(--tw-border-opacity))
}

.focus\:border-brown-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(141, 110, 99, var(--tw-border-opacity))
}

.focus\:border-brown-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity))
}

.focus\:border-brown-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(109, 76, 65, var(--tw-border-opacity))
}

.focus\:border-brown-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(93, 64, 55, var(--tw-border-opacity))
}

.focus\:border-brown-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(78, 52, 46, var(--tw-border-opacity))
}

.focus\:border-brown-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(62, 39, 35, var(--tw-border-opacity))
}

.focus\:border-brown:focus {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity))
}

.focus\:border-brown-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(215, 204, 200, var(--tw-border-opacity))
}

.focus\:border-brown-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(188, 170, 164, var(--tw-border-opacity))
}

.focus\:border-brown-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(141, 110, 99, var(--tw-border-opacity))
}

.focus\:border-brown-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(93, 64, 55, var(--tw-border-opacity))
}

.focus\:border-blue-gray-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(236, 239, 241, var(--tw-border-opacity))
}

.focus\:border-blue-gray-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(207, 216, 220, var(--tw-border-opacity))
}

.focus\:border-blue-gray-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(176, 190, 197, var(--tw-border-opacity))
}

.focus\:border-blue-gray-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(144, 164, 174, var(--tw-border-opacity))
}

.focus\:border-blue-gray-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-border-opacity))
}

.focus\:border-blue-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity))
}

.focus\:border-blue-gray-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(84, 110, 122, var(--tw-border-opacity))
}

.focus\:border-blue-gray-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(69, 90, 100, var(--tw-border-opacity))
}

.focus\:border-blue-gray-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(55, 71, 79, var(--tw-border-opacity))
}

.focus\:border-blue-gray-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(38, 50, 56, var(--tw-border-opacity))
}

.focus\:border-blue-gray:focus {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity))
}

.focus\:border-blue-gray-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(207, 216, 220, var(--tw-border-opacity))
}

.focus\:border-blue-gray-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(176, 190, 197, var(--tw-border-opacity))
}

.focus\:border-blue-gray-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-border-opacity))
}

.focus\:border-blue-gray-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(69, 90, 100, var(--tw-border-opacity))
}

.focus\:border-cyan-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(224, 247, 250, var(--tw-border-opacity))
}

.focus\:border-cyan-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(178, 235, 242, var(--tw-border-opacity))
}

.focus\:border-cyan-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(128, 222, 234, var(--tw-border-opacity))
}

.focus\:border-cyan-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(77, 208, 225, var(--tw-border-opacity))
}

.focus\:border-cyan-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(38, 198, 218, var(--tw-border-opacity))
}

.focus\:border-cyan-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-border-opacity))
}

.focus\:border-cyan-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 172, 193, var(--tw-border-opacity))
}

.focus\:border-cyan-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 151, 167, var(--tw-border-opacity))
}

.focus\:border-cyan-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 131, 143, var(--tw-border-opacity))
}

.focus\:border-cyan-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 96, 100, var(--tw-border-opacity))
}

.focus\:border-cyan:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-border-opacity))
}

.focus\:border-cyan-A100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(132, 255, 255, var(--tw-border-opacity))
}

.focus\:border-cyan-A200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(24, 255, 255, var(--tw-border-opacity))
}

.focus\:border-cyan-A400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 229, 255, var(--tw-border-opacity))
}

.focus\:border-cyan-A700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 184, 212, var(--tw-border-opacity))
}

.border-opacity-0 {
  --tw-border-opacity: 0
}

.border-opacity-5 {
  --tw-border-opacity: 0.05
}

.border-opacity-10 {
  --tw-border-opacity: 0.1
}

.border-opacity-20 {
  --tw-border-opacity: 0.2
}

.border-opacity-25 {
  --tw-border-opacity: 0.25
}

.border-opacity-30 {
  --tw-border-opacity: 0.3
}

.border-opacity-40 {
  --tw-border-opacity: 0.4
}

.border-opacity-50 {
  --tw-border-opacity: 0.5
}

.border-opacity-60 {
  --tw-border-opacity: 0.6
}

.border-opacity-70 {
  --tw-border-opacity: 0.7
}

.border-opacity-75 {
  --tw-border-opacity: 0.75
}

.border-opacity-80 {
  --tw-border-opacity: 0.8
}

.border-opacity-90 {
  --tw-border-opacity: 0.9
}

.border-opacity-95 {
  --tw-border-opacity: 0.95
}

.border-opacity-100 {
  --tw-border-opacity: 1
}

.group:hover .group-hover\:border-opacity-0 {
  --tw-border-opacity: 0
}

.group:hover .group-hover\:border-opacity-5 {
  --tw-border-opacity: 0.05
}

.group:hover .group-hover\:border-opacity-10 {
  --tw-border-opacity: 0.1
}

.group:hover .group-hover\:border-opacity-20 {
  --tw-border-opacity: 0.2
}

.group:hover .group-hover\:border-opacity-25 {
  --tw-border-opacity: 0.25
}

.group:hover .group-hover\:border-opacity-30 {
  --tw-border-opacity: 0.3
}

.group:hover .group-hover\:border-opacity-40 {
  --tw-border-opacity: 0.4
}

.group:hover .group-hover\:border-opacity-50 {
  --tw-border-opacity: 0.5
}

.group:hover .group-hover\:border-opacity-60 {
  --tw-border-opacity: 0.6
}

.group:hover .group-hover\:border-opacity-70 {
  --tw-border-opacity: 0.7
}

.group:hover .group-hover\:border-opacity-75 {
  --tw-border-opacity: 0.75
}

.group:hover .group-hover\:border-opacity-80 {
  --tw-border-opacity: 0.8
}

.group:hover .group-hover\:border-opacity-90 {
  --tw-border-opacity: 0.9
}

.group:hover .group-hover\:border-opacity-95 {
  --tw-border-opacity: 0.95
}

.group:hover .group-hover\:border-opacity-100 {
  --tw-border-opacity: 1
}

.focus-within\:border-opacity-0:focus-within {
  --tw-border-opacity: 0
}

.focus-within\:border-opacity-5:focus-within {
  --tw-border-opacity: 0.05
}

.focus-within\:border-opacity-10:focus-within {
  --tw-border-opacity: 0.1
}

.focus-within\:border-opacity-20:focus-within {
  --tw-border-opacity: 0.2
}

.focus-within\:border-opacity-25:focus-within {
  --tw-border-opacity: 0.25
}

.focus-within\:border-opacity-30:focus-within {
  --tw-border-opacity: 0.3
}

.focus-within\:border-opacity-40:focus-within {
  --tw-border-opacity: 0.4
}

.focus-within\:border-opacity-50:focus-within {
  --tw-border-opacity: 0.5
}

.focus-within\:border-opacity-60:focus-within {
  --tw-border-opacity: 0.6
}

.focus-within\:border-opacity-70:focus-within {
  --tw-border-opacity: 0.7
}

.focus-within\:border-opacity-75:focus-within {
  --tw-border-opacity: 0.75
}

.focus-within\:border-opacity-80:focus-within {
  --tw-border-opacity: 0.8
}

.focus-within\:border-opacity-90:focus-within {
  --tw-border-opacity: 0.9
}

.focus-within\:border-opacity-95:focus-within {
  --tw-border-opacity: 0.95
}

.focus-within\:border-opacity-100:focus-within {
  --tw-border-opacity: 1
}

.hover\:border-opacity-0:hover {
  --tw-border-opacity: 0
}

.hover\:border-opacity-5:hover {
  --tw-border-opacity: 0.05
}

.hover\:border-opacity-10:hover {
  --tw-border-opacity: 0.1
}

.hover\:border-opacity-20:hover {
  --tw-border-opacity: 0.2
}

.hover\:border-opacity-25:hover {
  --tw-border-opacity: 0.25
}

.hover\:border-opacity-30:hover {
  --tw-border-opacity: 0.3
}

.hover\:border-opacity-40:hover {
  --tw-border-opacity: 0.4
}

.hover\:border-opacity-50:hover {
  --tw-border-opacity: 0.5
}

.hover\:border-opacity-60:hover {
  --tw-border-opacity: 0.6
}

.hover\:border-opacity-70:hover {
  --tw-border-opacity: 0.7
}

.hover\:border-opacity-75:hover {
  --tw-border-opacity: 0.75
}

.hover\:border-opacity-80:hover {
  --tw-border-opacity: 0.8
}

.hover\:border-opacity-90:hover {
  --tw-border-opacity: 0.9
}

.hover\:border-opacity-95:hover {
  --tw-border-opacity: 0.95
}

.hover\:border-opacity-100:hover {
  --tw-border-opacity: 1
}

.focus\:border-opacity-0:focus {
  --tw-border-opacity: 0
}

.focus\:border-opacity-5:focus {
  --tw-border-opacity: 0.05
}

.focus\:border-opacity-10:focus {
  --tw-border-opacity: 0.1
}

.focus\:border-opacity-20:focus {
  --tw-border-opacity: 0.2
}

.focus\:border-opacity-25:focus {
  --tw-border-opacity: 0.25
}

.focus\:border-opacity-30:focus {
  --tw-border-opacity: 0.3
}

.focus\:border-opacity-40:focus {
  --tw-border-opacity: 0.4
}

.focus\:border-opacity-50:focus {
  --tw-border-opacity: 0.5
}

.focus\:border-opacity-60:focus {
  --tw-border-opacity: 0.6
}

.focus\:border-opacity-70:focus {
  --tw-border-opacity: 0.7
}

.focus\:border-opacity-75:focus {
  --tw-border-opacity: 0.75
}

.focus\:border-opacity-80:focus {
  --tw-border-opacity: 0.8
}

.focus\:border-opacity-90:focus {
  --tw-border-opacity: 0.9
}

.focus\:border-opacity-95:focus {
  --tw-border-opacity: 0.95
}

.focus\:border-opacity-100:focus {
  --tw-border-opacity: 1
}

.rounded-2 {
  border-radius: .2rem
}

.rounded-4 {
  border-radius: .4rem
}

.rounded-6 {
  border-radius: .6rem
}

.rounded-8 {
  border-radius: .8rem
}

.rounded-12 {
  border-radius: 1.2rem
}

.rounded-16 {
  border-radius: 1.6rem
}

.rounded-20 {
  border-radius: 2rem
}

.rounded-24 {
  border-radius: 2.4rem
}

.rounded-28 {
  border-radius: 2.8rem
}

.rounded-32 {
  border-radius: 3.2rem
}

.rounded-none {
  border-radius: 0
}

.rounded-sm {
  border-radius: .2rem
}

.rounded {
  border-radius: .4rem
}

.rounded-md {
  border-radius: .6rem
}

.rounded-lg {
  border-radius: .8rem
}

.rounded-xl {
  border-radius: 1.2rem
}

.rounded-2xl {
  border-radius: 1.6rem
}

.rounded-3xl {
  border-radius: 2.4rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-t-2 {
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem
}

.rounded-r-2 {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem
}

.rounded-b-2 {
  border-bottom-right-radius: .2rem;
  border-bottom-left-radius: .2rem
}

.rounded-l-2 {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem
}

.rounded-t-4 {
  border-top-left-radius: .4rem;
  border-top-right-radius: .4rem
}

.rounded-r-4 {
  border-top-right-radius: .4rem;
  border-bottom-right-radius: .4rem
}

.rounded-b-4 {
  border-bottom-right-radius: .4rem;
  border-bottom-left-radius: .4rem
}

.rounded-l-4 {
  border-top-left-radius: .4rem;
  border-bottom-left-radius: .4rem
}

.rounded-t-6 {
  border-top-left-radius: .6rem;
  border-top-right-radius: .6rem
}

.rounded-r-6 {
  border-top-right-radius: .6rem;
  border-bottom-right-radius: .6rem
}

.rounded-b-6 {
  border-bottom-right-radius: .6rem;
  border-bottom-left-radius: .6rem
}

.rounded-l-6 {
  border-top-left-radius: .6rem;
  border-bottom-left-radius: .6rem
}

.rounded-t-8 {
  border-top-left-radius: .8rem;
  border-top-right-radius: .8rem
}

.rounded-r-8 {
  border-top-right-radius: .8rem;
  border-bottom-right-radius: .8rem
}

.rounded-b-8 {
  border-bottom-right-radius: .8rem;
  border-bottom-left-radius: .8rem
}

.rounded-l-8 {
  border-top-left-radius: .8rem;
  border-bottom-left-radius: .8rem
}

.rounded-t-12 {
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem
}

.rounded-r-12 {
  border-top-right-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem
}

.rounded-b-12 {
  border-bottom-right-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem
}

.rounded-l-12 {
  border-top-left-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem
}

.rounded-t-16 {
  border-top-left-radius: 1.6rem;
  border-top-right-radius: 1.6rem
}

.rounded-r-16 {
  border-top-right-radius: 1.6rem;
  border-bottom-right-radius: 1.6rem
}

.rounded-b-16 {
  border-bottom-right-radius: 1.6rem;
  border-bottom-left-radius: 1.6rem
}

.rounded-l-16 {
  border-top-left-radius: 1.6rem;
  border-bottom-left-radius: 1.6rem
}

.rounded-t-20 {
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem
}

.rounded-r-20 {
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem
}

.rounded-b-20 {
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem
}

.rounded-l-20 {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem
}

.rounded-t-24 {
  border-top-left-radius: 2.4rem;
  border-top-right-radius: 2.4rem
}

.rounded-r-24 {
  border-top-right-radius: 2.4rem;
  border-bottom-right-radius: 2.4rem
}

.rounded-b-24 {
  border-bottom-right-radius: 2.4rem;
  border-bottom-left-radius: 2.4rem
}

.rounded-l-24 {
  border-top-left-radius: 2.4rem;
  border-bottom-left-radius: 2.4rem
}

.rounded-t-28 {
  border-top-left-radius: 2.8rem;
  border-top-right-radius: 2.8rem
}

.rounded-r-28 {
  border-top-right-radius: 2.8rem;
  border-bottom-right-radius: 2.8rem
}

.rounded-b-28 {
  border-bottom-right-radius: 2.8rem;
  border-bottom-left-radius: 2.8rem
}

.rounded-l-28 {
  border-top-left-radius: 2.8rem;
  border-bottom-left-radius: 2.8rem
}

.rounded-t-32 {
  border-top-left-radius: 3.2rem;
  border-top-right-radius: 3.2rem
}

.rounded-r-32 {
  border-top-right-radius: 3.2rem;
  border-bottom-right-radius: 3.2rem
}

.rounded-b-32 {
  border-bottom-right-radius: 3.2rem;
  border-bottom-left-radius: 3.2rem
}

.rounded-l-32 {
  border-top-left-radius: 3.2rem;
  border-bottom-left-radius: 3.2rem
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.rounded-t-sm {
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem
}

.rounded-r-sm {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem
}

.rounded-b-sm {
  border-bottom-right-radius: .2rem;
  border-bottom-left-radius: .2rem
}

.rounded-l-sm {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem
}

.rounded-t {
  border-top-left-radius: .4rem;
  border-top-right-radius: .4rem
}

.rounded-r {
  border-top-right-radius: .4rem;
  border-bottom-right-radius: .4rem
}

.rounded-b {
  border-bottom-right-radius: .4rem;
  border-bottom-left-radius: .4rem
}

.rounded-l {
  border-top-left-radius: .4rem;
  border-bottom-left-radius: .4rem
}

.rounded-t-md {
  border-top-left-radius: .6rem;
  border-top-right-radius: .6rem
}

.rounded-r-md {
  border-top-right-radius: .6rem;
  border-bottom-right-radius: .6rem
}

.rounded-b-md {
  border-bottom-right-radius: .6rem;
  border-bottom-left-radius: .6rem
}

.rounded-l-md {
  border-top-left-radius: .6rem;
  border-bottom-left-radius: .6rem
}

.rounded-t-lg {
  border-top-left-radius: .8rem;
  border-top-right-radius: .8rem
}

.rounded-r-lg {
  border-top-right-radius: .8rem;
  border-bottom-right-radius: .8rem
}

.rounded-b-lg {
  border-bottom-right-radius: .8rem;
  border-bottom-left-radius: .8rem
}

.rounded-l-lg {
  border-top-left-radius: .8rem;
  border-bottom-left-radius: .8rem
}

.rounded-t-xl {
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem
}

.rounded-r-xl {
  border-top-right-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem
}

.rounded-b-xl {
  border-bottom-right-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem
}

.rounded-l-xl {
  border-top-left-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem
}

.rounded-t-2xl {
  border-top-left-radius: 1.6rem;
  border-top-right-radius: 1.6rem
}

.rounded-r-2xl {
  border-top-right-radius: 1.6rem;
  border-bottom-right-radius: 1.6rem
}

.rounded-b-2xl {
  border-bottom-right-radius: 1.6rem;
  border-bottom-left-radius: 1.6rem
}

.rounded-l-2xl {
  border-top-left-radius: 1.6rem;
  border-bottom-left-radius: 1.6rem
}

.rounded-t-3xl {
  border-top-left-radius: 2.4rem;
  border-top-right-radius: 2.4rem
}

.rounded-r-3xl {
  border-top-right-radius: 2.4rem;
  border-bottom-right-radius: 2.4rem
}

.rounded-b-3xl {
  border-bottom-right-radius: 2.4rem;
  border-bottom-left-radius: 2.4rem
}

.rounded-l-3xl {
  border-top-left-radius: 2.4rem;
  border-bottom-left-radius: 2.4rem
}

.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px
}

.rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px
}

.rounded-tl-2 {
  border-top-left-radius: .2rem
}

.rounded-tr-2 {
  border-top-right-radius: .2rem
}

.rounded-br-2 {
  border-bottom-right-radius: .2rem
}

.rounded-bl-2 {
  border-bottom-left-radius: .2rem
}

.rounded-tl-4 {
  border-top-left-radius: .4rem
}

.rounded-tr-4 {
  border-top-right-radius: .4rem
}

.rounded-br-4 {
  border-bottom-right-radius: .4rem
}

.rounded-bl-4 {
  border-bottom-left-radius: .4rem
}

.rounded-tl-6 {
  border-top-left-radius: .6rem
}

.rounded-tr-6 {
  border-top-right-radius: .6rem
}

.rounded-br-6 {
  border-bottom-right-radius: .6rem
}

.rounded-bl-6 {
  border-bottom-left-radius: .6rem
}

.rounded-tl-8 {
  border-top-left-radius: .8rem
}

.rounded-tr-8 {
  border-top-right-radius: .8rem
}

.rounded-br-8 {
  border-bottom-right-radius: .8rem
}

.rounded-bl-8 {
  border-bottom-left-radius: .8rem
}

.rounded-tl-12 {
  border-top-left-radius: 1.2rem
}

.rounded-tr-12 {
  border-top-right-radius: 1.2rem
}

.rounded-br-12 {
  border-bottom-right-radius: 1.2rem
}

.rounded-bl-12 {
  border-bottom-left-radius: 1.2rem
}

.rounded-tl-16 {
  border-top-left-radius: 1.6rem
}

.rounded-tr-16 {
  border-top-right-radius: 1.6rem
}

.rounded-br-16 {
  border-bottom-right-radius: 1.6rem
}

.rounded-bl-16 {
  border-bottom-left-radius: 1.6rem
}

.rounded-tl-20 {
  border-top-left-radius: 2rem
}

.rounded-tr-20 {
  border-top-right-radius: 2rem
}

.rounded-br-20 {
  border-bottom-right-radius: 2rem
}

.rounded-bl-20 {
  border-bottom-left-radius: 2rem
}

.rounded-tl-24 {
  border-top-left-radius: 2.4rem
}

.rounded-tr-24 {
  border-top-right-radius: 2.4rem
}

.rounded-br-24 {
  border-bottom-right-radius: 2.4rem
}

.rounded-bl-24 {
  border-bottom-left-radius: 2.4rem
}

.rounded-tl-28 {
  border-top-left-radius: 2.8rem
}

.rounded-tr-28 {
  border-top-right-radius: 2.8rem
}

.rounded-br-28 {
  border-bottom-right-radius: 2.8rem
}

.rounded-bl-28 {
  border-bottom-left-radius: 2.8rem
}

.rounded-tl-32 {
  border-top-left-radius: 3.2rem
}

.rounded-tr-32 {
  border-top-right-radius: 3.2rem
}

.rounded-br-32 {
  border-bottom-right-radius: 3.2rem
}

.rounded-bl-32 {
  border-bottom-left-radius: 3.2rem
}

.rounded-tl-none {
  border-top-left-radius: 0
}

.rounded-tr-none {
  border-top-right-radius: 0
}

.rounded-br-none {
  border-bottom-right-radius: 0
}

.rounded-bl-none {
  border-bottom-left-radius: 0
}

.rounded-tl-sm {
  border-top-left-radius: .2rem
}

.rounded-tr-sm {
  border-top-right-radius: .2rem
}

.rounded-br-sm {
  border-bottom-right-radius: .2rem
}

.rounded-bl-sm {
  border-bottom-left-radius: .2rem
}

.rounded-tl {
  border-top-left-radius: .4rem
}

.rounded-tr {
  border-top-right-radius: .4rem
}

.rounded-br {
  border-bottom-right-radius: .4rem
}

.rounded-bl {
  border-bottom-left-radius: .4rem
}

.rounded-tl-md {
  border-top-left-radius: .6rem
}

.rounded-tr-md {
  border-top-right-radius: .6rem
}

.rounded-br-md {
  border-bottom-right-radius: .6rem
}

.rounded-bl-md {
  border-bottom-left-radius: .6rem
}

.rounded-tl-lg {
  border-top-left-radius: .8rem
}

.rounded-tr-lg {
  border-top-right-radius: .8rem
}

.rounded-br-lg {
  border-bottom-right-radius: .8rem
}

.rounded-bl-lg {
  border-bottom-left-radius: .8rem
}

.rounded-tl-xl {
  border-top-left-radius: 1.2rem
}

.rounded-tr-xl {
  border-top-right-radius: 1.2rem
}

.rounded-br-xl {
  border-bottom-right-radius: 1.2rem
}

.rounded-bl-xl {
  border-bottom-left-radius: 1.2rem
}

.rounded-tl-2xl {
  border-top-left-radius: 1.6rem
}

.rounded-tr-2xl {
  border-top-right-radius: 1.6rem
}

.rounded-br-2xl {
  border-bottom-right-radius: 1.6rem
}

.rounded-bl-2xl {
  border-bottom-left-radius: 1.6rem
}

.rounded-tl-3xl {
  border-top-left-radius: 2.4rem
}

.rounded-tr-3xl {
  border-top-right-radius: 2.4rem
}

.rounded-br-3xl {
  border-bottom-right-radius: 2.4rem
}

.rounded-bl-3xl {
  border-bottom-left-radius: 2.4rem
}

.rounded-tl-full {
  border-top-left-radius: 9999px
}

.rounded-tr-full {
  border-top-right-radius: 9999px
}

.rounded-br-full {
  border-bottom-right-radius: 9999px
}

.rounded-bl-full {
  border-bottom-left-radius: 9999px
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-dotted {
  border-style: dotted
}

.border-double {
  border-style: double
}

.border-none {
  border-style: none
}

.border-0 {
  border-width: 0px
}

.border-1 {
  border-width: 1px
}

.border-2 {
  border-width: 2px
}

.border-3 {
  border-width: 3px
}

.border-4 {
  border-width: 4px
}

.border-8 {
  border-width: 8px
}

.border {
  border-width: 1px
}

.border-t-0 {
  border-top-width: 0px
}

.border-r-0 {
  border-right-width: 0px
}

.border-b-0 {
  border-bottom-width: 0px
}

.border-l-0 {
  border-left-width: 0px
}

.border-t-1 {
  border-top-width: 1px
}

.border-r-1 {
  border-right-width: 1px
}

.border-b-1 {
  border-bottom-width: 1px
}

.border-l-1 {
  border-left-width: 1px
}

.border-t-2 {
  border-top-width: 2px
}

.border-r-2 {
  border-right-width: 2px
}

.border-b-2 {
  border-bottom-width: 2px
}

.border-l-2 {
  border-left-width: 2px
}

.border-t-3 {
  border-top-width: 3px
}

.border-r-3 {
  border-right-width: 3px
}

.border-b-3 {
  border-bottom-width: 3px
}

.border-l-3 {
  border-left-width: 3px
}

.border-t-4 {
  border-top-width: 4px
}

.border-r-4 {
  border-right-width: 4px
}

.border-b-4 {
  border-bottom-width: 4px
}

.border-l-4 {
  border-left-width: 4px
}

.border-t-8 {
  border-top-width: 8px
}

.border-r-8 {
  border-right-width: 8px
}

.border-b-8 {
  border-bottom-width: 8px
}

.border-l-8 {
  border-left-width: 8px
}

.border-t {
  border-top-width: 1px
}

.border-r {
  border-right-width: 1px
}

.border-b {
  border-bottom-width: 1px
}

.border-l {
  border-left-width: 1px
}

.box-border {
  box-sizing: border-box
}

.box-content {
  box-sizing: content-box
}

.cursor-auto {
  cursor: auto
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.cursor-wait {
  cursor: wait
}

.cursor-text {
  cursor: text
}

.cursor-move {
  cursor: move
}

.cursor-not-allowed {
  cursor: not-allowed
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.table-caption {
  display: table-caption
}

.table-cell {
  display: table-cell
}

.table-column {
  display: table-column
}

.table-column-group {
  display: table-column-group
}

.table-footer-group {
  display: table-footer-group
}

.table-header-group {
  display: table-header-group
}

.table-row-group {
  display: table-row-group
}

.table-row {
  display: table-row
}

.flow-root {
  display: flow-root
}

.grid {
  display: grid
}

.inline-grid {
  display: inline-grid
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.hover\:block:hover {
  display: block
}

.hover\:inline-block:hover {
  display: inline-block
}

.hover\:inline:hover {
  display: inline
}

.hover\:flex:hover {
  display: flex
}

.hover\:inline-flex:hover {
  display: inline-flex
}

.hover\:table:hover {
  display: table
}

.hover\:table-caption:hover {
  display: table-caption
}

.hover\:table-cell:hover {
  display: table-cell
}

.hover\:table-column:hover {
  display: table-column
}

.hover\:table-column-group:hover {
  display: table-column-group
}

.hover\:table-footer-group:hover {
  display: table-footer-group
}

.hover\:table-header-group:hover {
  display: table-header-group
}

.hover\:table-row-group:hover {
  display: table-row-group
}

.hover\:table-row:hover {
  display: table-row
}

.hover\:flow-root:hover {
  display: flow-root
}

.hover\:grid:hover {
  display: grid
}

.hover\:inline-grid:hover {
  display: inline-grid
}

.hover\:contents:hover {
  display: contents
}

.hover\:hidden:hover {
  display: none
}

.focus\:block:focus {
  display: block
}

.focus\:inline-block:focus {
  display: inline-block
}

.focus\:inline:focus {
  display: inline
}

.focus\:flex:focus {
  display: flex
}

.focus\:inline-flex:focus {
  display: inline-flex
}

.focus\:table:focus {
  display: table
}

.focus\:table-caption:focus {
  display: table-caption
}

.focus\:table-cell:focus {
  display: table-cell
}

.focus\:table-column:focus {
  display: table-column
}

.focus\:table-column-group:focus {
  display: table-column-group
}

.focus\:table-footer-group:focus {
  display: table-footer-group
}

.focus\:table-header-group:focus {
  display: table-header-group
}

.focus\:table-row-group:focus {
  display: table-row-group
}

.focus\:table-row:focus {
  display: table-row
}

.focus\:flow-root:focus {
  display: flow-root
}

.focus\:grid:focus {
  display: grid
}

.focus\:inline-grid:focus {
  display: inline-grid
}

.focus\:contents:focus {
  display: contents
}

.focus\:hidden:focus {
  display: none
}

.flex-row {
  flex-direction: row
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.flex-col {
  flex-direction: column
}

.flex-col-reverse {
  flex-direction: column-reverse
}

.flex-wrap {
  flex-wrap: wrap
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse
}

.flex-nowrap {
  flex-wrap: nowrap
}

.place-items-auto {
  place-items: auto
}

.place-items-start {
  place-items: start
}

.place-items-end {
  place-items: end
}

.place-items-center {
  place-items: center
}

.place-items-stretch {
  place-items: stretch
}

.place-content-center {
  place-content: center
}

.place-content-start {
  place-content: start
}

.place-content-end {
  place-content: end
}

.place-content-between {
  place-content: space-between
}

.place-content-around {
  place-content: space-around
}

.place-content-evenly {
  place-content: space-evenly
}

.place-content-stretch {
  place-content: stretch
}

.place-self-auto {
  place-self: auto
}

.place-self-start {
  place-self: start
}

.place-self-end {
  place-self: end
}

.place-self-center {
  place-self: center
}

.place-self-stretch {
  place-self: stretch
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.items-baseline {
  align-items: baseline
}

.items-stretch {
  align-items: stretch
}

.content-center {
  align-content: center
}

.content-start {
  align-content: flex-start
}

.content-end {
  align-content: flex-end
}

.content-between {
  align-content: space-between
}

.content-around {
  align-content: space-around
}

.content-evenly {
  align-content: space-evenly
}

.self-auto {
  align-self: auto
}

.self-start {
  align-self: flex-start
}

.self-end {
  align-self: flex-end
}

.self-center {
  align-self: center
}

.self-stretch {
  align-self: stretch
}

.justify-items-auto {
  justify-items: auto
}

.justify-items-start {
  justify-items: start
}

.justify-items-end {
  justify-items: end
}

.justify-items-center {
  justify-items: center
}

.justify-items-stretch {
  justify-items: stretch
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.justify-around {
  justify-content: space-around
}

.justify-evenly {
  justify-content: space-evenly
}

.justify-self-auto {
  justify-self: auto
}

.justify-self-start {
  justify-self: start
}

.justify-self-end {
  justify-self: end
}

.justify-self-center {
  justify-self: center
}

.justify-self-stretch {
  justify-self: stretch
}

.flex-1 {
  flex: 1 1 0%
}

.flex-auto {
  flex: 1 1 auto
}

.flex-initial {
  flex: 0 1 auto
}

.flex-none {
  flex: none
}

.flex-grow-0 {
  flex-grow: 0
}

.flex-grow {
  flex-grow: 1
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.order-1 {
  order: 1
}

.order-2 {
  order: 2
}

.order-3 {
  order: 3
}

.order-4 {
  order: 4
}

.order-5 {
  order: 5
}

.order-6 {
  order: 6
}

.order-7 {
  order: 7
}

.order-8 {
  order: 8
}

.order-9 {
  order: 9
}

.order-10 {
  order: 10
}

.order-11 {
  order: 11
}

.order-12 {
  order: 12
}

.order-first {
  order: -9999
}

.order-last {
  order: 9999
}

.order-none {
  order: 0
}

.float-right {
  float: right
}

.float-left {
  float: left
}

.float-none {
  float: none
}

[dir='ltr'] .ltr\:float-right,[dir='ltr'].ltr\:float-right {
  float: right
}

[dir='ltr'] .ltr\:float-left,[dir='ltr'].ltr\:float-left {
  float: left
}

[dir='ltr'] .ltr\:float-none,[dir='ltr'].ltr\:float-none {
  float: none
}

[dir='rtl'] .rtl\:float-right,[dir='rtl'].rtl\:float-right {
  float: right
}

[dir='rtl'] .rtl\:float-left,[dir='rtl'].rtl\:float-left {
  float: left
}

[dir='rtl'] .rtl\:float-none,[dir='rtl'].rtl\:float-none {
  float: none
}

.clear-left {
  clear: left
}

.clear-right {
  clear: right
}

.clear-both {
  clear: both
}

.clear-none {
  clear: none
}

.font-sans {
  font-family: Muli, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.font-serif {
  font-family: Georgia, Cambria, "Times New Roman", Times, serif
}

.font-mono {
  font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace
}

.font-100 {
  font-weight: 100
}

.font-200 {
  font-weight: 200
}

.font-300 {
  font-weight: 300
}

.font-400 {
  font-weight: 400
}

.font-500 {
  font-weight: 500
}

.font-600 {
  font-weight: 600
}

.font-700 {
  font-weight: 700
}

.font-800 {
  font-weight: 800
}

.font-900 {
  font-weight: 900
}

.font-thin {
  font-weight: 100
}

.font-extralight {
  font-weight: 200
}

.font-light {
  font-weight: 300
}

.font-normal {
  font-weight: 400
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.font-extrabold {
  font-weight: 800
}

.font-black {
  font-weight: 900
}

.hover\:font-100:hover {
  font-weight: 100
}

.hover\:font-200:hover {
  font-weight: 200
}

.hover\:font-300:hover {
  font-weight: 300
}

.hover\:font-400:hover {
  font-weight: 400
}

.hover\:font-500:hover {
  font-weight: 500
}

.hover\:font-600:hover {
  font-weight: 600
}

.hover\:font-700:hover {
  font-weight: 700
}

.hover\:font-800:hover {
  font-weight: 800
}

.hover\:font-900:hover {
  font-weight: 900
}

.hover\:font-thin:hover {
  font-weight: 100
}

.hover\:font-extralight:hover {
  font-weight: 200
}

.hover\:font-light:hover {
  font-weight: 300
}

.hover\:font-normal:hover {
  font-weight: 400
}

.hover\:font-medium:hover {
  font-weight: 500
}

.hover\:font-semibold:hover {
  font-weight: 600
}

.hover\:font-bold:hover {
  font-weight: 700
}

.hover\:font-extrabold:hover {
  font-weight: 800
}

.hover\:font-black:hover {
  font-weight: 900
}

.focus\:font-100:focus {
  font-weight: 100
}

.focus\:font-200:focus {
  font-weight: 200
}

.focus\:font-300:focus {
  font-weight: 300
}

.focus\:font-400:focus {
  font-weight: 400
}

.focus\:font-500:focus {
  font-weight: 500
}

.focus\:font-600:focus {
  font-weight: 600
}

.focus\:font-700:focus {
  font-weight: 700
}

.focus\:font-800:focus {
  font-weight: 800
}

.focus\:font-900:focus {
  font-weight: 900
}

.focus\:font-thin:focus {
  font-weight: 100
}

.focus\:font-extralight:focus {
  font-weight: 200
}

.focus\:font-light:focus {
  font-weight: 300
}

.focus\:font-normal:focus {
  font-weight: 400
}

.focus\:font-medium:focus {
  font-weight: 500
}

.focus\:font-semibold:focus {
  font-weight: 600
}

.focus\:font-bold:focus {
  font-weight: 700
}

.focus\:font-extrabold:focus {
  font-weight: 800
}

.focus\:font-black:focus {
  font-weight: 900
}

.h-0 {
  height: 0
}

.h-1 {
  height: 0.1rem
}

.h-2 {
  height: 0.2rem
}

.h-3 {
  height: 0.3rem
}

.h-4 {
  height: 0.4rem
}

.h-5 {
  height: 0.5rem
}

.h-6 {
  height: 0.6rem
}

.h-7 {
  height: 0.7rem
}

.h-8 {
  height: 0.8rem
}

.h-9 {
  height: 0.9rem
}

.h-10 {
  height: 1.0rem
}

.h-12 {
  height: 1.2rem
}

.h-14 {
  height: 1.4rem
}

.h-16 {
  height: 1.6rem
}

.h-20 {
  height: 2rem
}

.h-24 {
  height: 2.4rem
}

.h-28 {
  height: 2.8rem
}

.h-32 {
  height: 3.2rem
}

.h-36 {
  height: 3.6rem
}

.h-40 {
  height: 4rem
}

.h-44 {
  height: 4.4rem
}

.h-48 {
  height: 4.8rem
}

.h-52 {
  height: 5.2rem
}

.h-56 {
  height: 5.6rem
}

.h-60 {
  height: 6rem
}

.h-64 {
  height: 6.4rem
}

.h-68 {
  height: 6.8rem
}

.h-72 {
  height: 7.2rem
}

.h-76 {
  height: 7.6rem
}

.h-80 {
  height: 8rem
}

.h-84 {
  height: 8.4rem
}

.h-88 {
  height: 8.8rem
}

.h-92 {
  height: 9.2rem
}

.h-96 {
  height: 9.6rem
}

.h-128 {
  height: 12.8rem
}

.h-136 {
  height: 13.6rem
}

.h-160 {
  height: 16rem
}

.h-192 {
  height: 19.2rem
}

.h-200 {
  height: 20rem
}

.h-208 {
  height: 20.8rem
}

.h-216 {
  height: 21.6rem
}

.h-224 {
  height: 22.4rem
}

.h-256 {
  height: 25.6rem
}

.h-288 {
  height: 28.8rem
}

.h-320 {
  height: 32rem
}

.h-360 {
  height: 36rem
}

.h-384 {
  height: 38.4rem
}

.h-400 {
  height: 40rem
}

.h-512 {
  height: 51.2rem
}

.h-640 {
  height: 64rem
}

.h-auto {
  height: auto
}

.h-xs {
  height: 32rem
}

.h-sm {
  height: 48rem
}

.h-md {
  height: 64rem
}

.h-lg {
  height: 80rem
}

.h-xl {
  height: 96rem
}

.h-2xl {
  height: 112rem
}

.h-3xl {
  height: 128rem
}

.h-4xl {
  height: 144rem
}

.h-5xl {
  height: 160rem
}

.h-px {
  height: 1px
}

.h-0\.5 {
  height: 0.05rem
}

.h-1\.5 {
  height: 0.15rem
}

.h-2\.5 {
  height: 0.25rem
}

.h-3\.5 {
  height: 0.35rem
}

.h-1\/2 {
  height: 50%
}

.h-1\/3 {
  height: 33.333333%
}

.h-2\/3 {
  height: 66.666667%
}

.h-1\/4 {
  height: 25%
}

.h-2\/4 {
  height: 50%
}

.h-3\/4 {
  height: 75%
}

.h-1\/5 {
  height: 20%
}

.h-2\/5 {
  height: 40%
}

.h-3\/5 {
  height: 60%
}

.h-4\/5 {
  height: 80%
}

.h-1\/6 {
  height: 16.666667%
}

.h-2\/6 {
  height: 33.333333%
}

.h-3\/6 {
  height: 50%
}

.h-4\/6 {
  height: 66.666667%
}

.h-5\/6 {
  height: 83.333333%
}

.h-full {
  height: 100%
}

.h-screen {
  height: 100vh
}

.text-10 {
  font-size: 1rem
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-15 {
  font-size: 1.5rem
}

.text-16 {
  font-size: 1.6rem
}

.text-17 {
  font-size: 1.7rem
}

.text-18 {
  font-size: 1.8rem
}

.text-19 {
  font-size: 1.9rem
}

.text-20 {
  font-size: 2rem
}

.text-24 {
  font-size: 2.4rem
}

.text-28 {
  font-size: 2.8rem
}

.text-32 {
  font-size: 3.2rem
}

.text-36 {
  font-size: 3.6rem
}

.text-40 {
  font-size: 4rem
}

.text-44 {
  font-size: 4.4rem
}

.text-48 {
  font-size: 4.8rem
}

.text-52 {
  font-size: 5.2rem
}

.text-56 {
  font-size: 5.6rem
}

.text-60 {
  font-size: 6rem
}

.text-64 {
  font-size: 6.4rem
}

.text-68 {
  font-size: 6.8rem
}

.text-72 {
  font-size: 7.2rem
}

.text-96 {
  font-size: 9.6rem
}

.text-128 {
  font-size: 12.8rem
}

.text-xs {
  font-size: 1.2rem;
  line-height: 1.6rem
}

.text-sm {
  font-size: 1.4rem;
  line-height: 2rem
}

.text-base {
  font-size: 1.6rem;
  line-height: 2.4rem
}

.text-lg {
  font-size: 1.8rem;
  line-height: 2.8em
}

.text-xl {
  font-size: 2rem;
  line-height: 2.8rem
}

.text-2xl {
  font-size: 2.4rem;
  line-height: 3.2rem
}

.text-3xl {
  font-size: 3rem;
  line-height: 3.6rem
}

.text-4xl {
  font-size: 3.6rem;
  line-height: 4rem
}

.text-5xl {
  font-size: 4.8rem;
  line-height: 1
}

.text-6xl {
  font-size: 6rem;
  line-height: 1
}

.text-7xl {
  font-size: 7.2rem;
  line-height: 1
}

.text-8xl {
  font-size: 9.6rem;
  line-height: 1
}

.text-9xl {
  font-size: 12.8rem;
  line-height: 1
}

.leading-3 {
  line-height: 1.2rem
}

.leading-4 {
  line-height: 1.6rem
}

.leading-5 {
  line-height: 2rem
}

.leading-6 {
  line-height: 2.4rem
}

.leading-7 {
  line-height: 2.8rem
}

.leading-8 {
  line-height: 3.2rem
}

.leading-9 {
  line-height: 3.6rem
}

.leading-10 {
  line-height: 4rem
}

.leading-none {
  line-height: 1
}

.leading-tight {
  line-height: 1.25
}

.leading-snug {
  line-height: 1.375
}

.leading-normal {
  line-height: 1.5
}

.leading-relaxed {
  line-height: 1.625
}

.leading-loose {
  line-height: 2
}

.list-inside {
  list-style-position: inside
}

.list-outside {
  list-style-position: outside
}

.list-none {
  list-style-type: none
}

.list-disc {
  list-style-type: disc
}

.list-decimal {
  list-style-type: decimal
}

.m-0 {
  margin: 0
}

.m-1 {
  margin: 0.1rem
}

.m-2 {
  margin: 0.2rem
}

.m-3 {
  margin: 0.3rem
}

.m-4 {
  margin: 0.4rem
}

.m-5 {
  margin: 0.5rem
}

.m-6 {
  margin: 0.6rem
}

.m-7 {
  margin: 0.7rem
}

.m-8 {
  margin: 0.8rem
}

.m-9 {
  margin: 0.9rem
}

.m-10 {
  margin: 1.0rem
}

.m-12 {
  margin: 1.2rem
}

.m-14 {
  margin: 1.4rem
}

.m-16 {
  margin: 1.6rem
}

.m-20 {
  margin: 2rem
}

.m-24 {
  margin: 2.4rem
}

.m-28 {
  margin: 2.8rem
}

.m-32 {
  margin: 3.2rem
}

.m-36 {
  margin: 3.6rem
}

.m-40 {
  margin: 4rem
}

.m-44 {
  margin: 4.4rem
}

.m-48 {
  margin: 4.8rem
}

.m-52 {
  margin: 5.2rem
}

.m-56 {
  margin: 5.6rem
}

.m-60 {
  margin: 6rem
}

.m-64 {
  margin: 6.4rem
}

.m-68 {
  margin: 6.8rem
}

.m-72 {
  margin: 7.2rem
}

.m-76 {
  margin: 7.6rem
}

.m-80 {
  margin: 8rem
}

.m-84 {
  margin: 8.4rem
}

.m-88 {
  margin: 8.8rem
}

.m-92 {
  margin: 9.2rem
}

.m-96 {
  margin: 9.6rem
}

.m-128 {
  margin: 12.8rem
}

.m-136 {
  margin: 13.6rem
}

.m-160 {
  margin: 16rem
}

.m-192 {
  margin: 19.2rem
}

.m-200 {
  margin: 20rem
}

.m-208 {
  margin: 20.8rem
}

.m-216 {
  margin: 21.6rem
}

.m-224 {
  margin: 22.4rem
}

.m-256 {
  margin: 25.6rem
}

.m-288 {
  margin: 28.8rem
}

.m-320 {
  margin: 32rem
}

.m-360 {
  margin: 36rem
}

.m-384 {
  margin: 38.4rem
}

.m-400 {
  margin: 40rem
}

.m-512 {
  margin: 51.2rem
}

.m-640 {
  margin: 64rem
}

.m-auto {
  margin: auto
}

.m-xs {
  margin: 32rem
}

.m-sm {
  margin: 48rem
}

.m-md {
  margin: 64rem
}

.m-lg {
  margin: 80rem
}

.m-xl {
  margin: 96rem
}

.m-2xl {
  margin: 112rem
}

.m-3xl {
  margin: 128rem
}

.m-4xl {
  margin: 144rem
}

.m-5xl {
  margin: 160rem
}

.m-px {
  margin: 1px
}

.m-0\.5 {
  margin: 0.05rem
}

.m-1\.5 {
  margin: 0.15rem
}

.m-2\.5 {
  margin: 0.25rem
}

.m-3\.5 {
  margin: 0.35rem
}

.-m-1 {
  margin: -0.1rem
}

.-m-2 {
  margin: -0.2rem
}

.-m-3 {
  margin: -0.3rem
}

.-m-4 {
  margin: -0.4rem
}

.-m-5 {
  margin: -0.5rem
}

.-m-6 {
  margin: -0.6rem
}

.-m-7 {
  margin: -0.7rem
}

.-m-8 {
  margin: -0.8rem
}

.-m-9 {
  margin: -0.9rem
}

.-m-10 {
  margin: -1rem
}

.-m-12 {
  margin: -1.2rem
}

.-m-14 {
  margin: -1.4rem
}

.-m-16 {
  margin: -1.6rem
}

.-m-20 {
  margin: -2rem
}

.-m-24 {
  margin: -2.4rem
}

.-m-28 {
  margin: -2.8rem
}

.-m-32 {
  margin: -3.2rem
}

.-m-36 {
  margin: -3.6rem
}

.-m-40 {
  margin: -4rem
}

.-m-44 {
  margin: -4.4rem
}

.-m-48 {
  margin: -4.8rem
}

.-m-52 {
  margin: -5.2rem
}

.-m-56 {
  margin: -5.6rem
}

.-m-60 {
  margin: -6rem
}

.-m-64 {
  margin: -6.4rem
}

.-m-68 {
  margin: -6.8rem
}

.-m-72 {
  margin: -7.2rem
}

.-m-76 {
  margin: -7.6rem
}

.-m-80 {
  margin: -8rem
}

.-m-84 {
  margin: -8.4rem
}

.-m-88 {
  margin: -8.8rem
}

.-m-92 {
  margin: -9.2rem
}

.-m-96 {
  margin: -9.6rem
}

.-m-128 {
  margin: -12.8rem
}

.-m-136 {
  margin: -13.6rem
}

.-m-160 {
  margin: -16rem
}

.-m-192 {
  margin: -19.2rem
}

.-m-200 {
  margin: -20rem
}

.-m-208 {
  margin: -20.8rem
}

.-m-216 {
  margin: -21.6rem
}

.-m-224 {
  margin: -22.4rem
}

.-m-256 {
  margin: -25.6rem
}

.-m-288 {
  margin: -28.8rem
}

.-m-320 {
  margin: -32rem
}

.-m-360 {
  margin: -36rem
}

.-m-384 {
  margin: -38.4rem
}

.-m-400 {
  margin: -40rem
}

.-m-512 {
  margin: -51.2rem
}

.-m-640 {
  margin: -64rem
}

.-m-xs {
  margin: -32rem
}

.-m-sm {
  margin: -48rem
}

.-m-md {
  margin: -64rem
}

.-m-lg {
  margin: -80rem
}

.-m-xl {
  margin: -96rem
}

.-m-2xl {
  margin: -112rem
}

.-m-3xl {
  margin: -128rem
}

.-m-4xl {
  margin: -144rem
}

.-m-5xl {
  margin: -160rem
}

.-m-px {
  margin: -1px
}

.-m-0\.5 {
  margin: -0.05rem
}

.-m-1\.5 {
  margin: -0.15rem
}

.-m-2\.5 {
  margin: -0.25rem
}

.-m-3\.5 {
  margin: -0.35rem
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0
}

.mx-0 {
  margin-left: 0;
  margin-right: 0
}

.my-1 {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem
}

.mx-1 {
  margin-left: 0.1rem;
  margin-right: 0.1rem
}

.my-2 {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem
}

.mx-2 {
  margin-left: 0.2rem;
  margin-right: 0.2rem
}

.my-3 {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem
}

.mx-3 {
  margin-left: 0.3rem;
  margin-right: 0.3rem
}

.my-4 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.my-5 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.mx-5 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.my-6 {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem
}

.mx-6 {
  margin-left: 0.6rem;
  margin-right: 0.6rem
}

.my-7 {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem
}

.mx-7 {
  margin-left: 0.7rem;
  margin-right: 0.7rem
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.my-9 {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem
}

.mx-9 {
  margin-left: 0.9rem;
  margin-right: 0.9rem
}

.my-10 {
  margin-top: 1.0rem;
  margin-bottom: 1.0rem
}

.mx-10 {
  margin-left: 1.0rem;
  margin-right: 1.0rem
}

.my-12 {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

.my-14 {
  margin-top: 1.4rem;
  margin-bottom: 1.4rem
}

.mx-14 {
  margin-left: 1.4rem;
  margin-right: 1.4rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

.my-20 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.mx-20 {
  margin-left: 2rem;
  margin-right: 2rem
}

.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem
}

.mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem
}

.my-28 {
  margin-top: 2.8rem;
  margin-bottom: 2.8rem
}

.mx-28 {
  margin-left: 2.8rem;
  margin-right: 2.8rem
}

.my-32 {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem
}

.mx-32 {
  margin-left: 3.2rem;
  margin-right: 3.2rem
}

.my-36 {
  margin-top: 3.6rem;
  margin-bottom: 3.6rem
}

.mx-36 {
  margin-left: 3.6rem;
  margin-right: 3.6rem
}

.my-40 {
  margin-top: 4rem;
  margin-bottom: 4rem
}

.mx-40 {
  margin-left: 4rem;
  margin-right: 4rem
}

.my-44 {
  margin-top: 4.4rem;
  margin-bottom: 4.4rem
}

.mx-44 {
  margin-left: 4.4rem;
  margin-right: 4.4rem
}

.my-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem
}

.mx-48 {
  margin-left: 4.8rem;
  margin-right: 4.8rem
}

.my-52 {
  margin-top: 5.2rem;
  margin-bottom: 5.2rem
}

.mx-52 {
  margin-left: 5.2rem;
  margin-right: 5.2rem
}

.my-56 {
  margin-top: 5.6rem;
  margin-bottom: 5.6rem
}

.mx-56 {
  margin-left: 5.6rem;
  margin-right: 5.6rem
}

.my-60 {
  margin-top: 6rem;
  margin-bottom: 6rem
}

.mx-60 {
  margin-left: 6rem;
  margin-right: 6rem
}

.my-64 {
  margin-top: 6.4rem;
  margin-bottom: 6.4rem
}

.mx-64 {
  margin-left: 6.4rem;
  margin-right: 6.4rem
}

.my-68 {
  margin-top: 6.8rem;
  margin-bottom: 6.8rem
}

.mx-68 {
  margin-left: 6.8rem;
  margin-right: 6.8rem
}

.my-72 {
  margin-top: 7.2rem;
  margin-bottom: 7.2rem
}

.mx-72 {
  margin-left: 7.2rem;
  margin-right: 7.2rem
}

.my-76 {
  margin-top: 7.6rem;
  margin-bottom: 7.6rem
}

.mx-76 {
  margin-left: 7.6rem;
  margin-right: 7.6rem
}

.my-80 {
  margin-top: 8rem;
  margin-bottom: 8rem
}

.mx-80 {
  margin-left: 8rem;
  margin-right: 8rem
}

.my-84 {
  margin-top: 8.4rem;
  margin-bottom: 8.4rem
}

.mx-84 {
  margin-left: 8.4rem;
  margin-right: 8.4rem
}

.my-88 {
  margin-top: 8.8rem;
  margin-bottom: 8.8rem
}

.mx-88 {
  margin-left: 8.8rem;
  margin-right: 8.8rem
}

.my-92 {
  margin-top: 9.2rem;
  margin-bottom: 9.2rem
}

.mx-92 {
  margin-left: 9.2rem;
  margin-right: 9.2rem
}

.my-96 {
  margin-top: 9.6rem;
  margin-bottom: 9.6rem
}

.mx-96 {
  margin-left: 9.6rem;
  margin-right: 9.6rem
}

.my-128 {
  margin-top: 12.8rem;
  margin-bottom: 12.8rem
}

.mx-128 {
  margin-left: 12.8rem;
  margin-right: 12.8rem
}

.my-136 {
  margin-top: 13.6rem;
  margin-bottom: 13.6rem
}

.mx-136 {
  margin-left: 13.6rem;
  margin-right: 13.6rem
}

.my-160 {
  margin-top: 16rem;
  margin-bottom: 16rem
}

.mx-160 {
  margin-left: 16rem;
  margin-right: 16rem
}

.my-192 {
  margin-top: 19.2rem;
  margin-bottom: 19.2rem
}

.mx-192 {
  margin-left: 19.2rem;
  margin-right: 19.2rem
}

.my-200 {
  margin-top: 20rem;
  margin-bottom: 20rem
}

.mx-200 {
  margin-left: 20rem;
  margin-right: 20rem
}

.my-208 {
  margin-top: 20.8rem;
  margin-bottom: 20.8rem
}

.mx-208 {
  margin-left: 20.8rem;
  margin-right: 20.8rem
}

.my-216 {
  margin-top: 21.6rem;
  margin-bottom: 21.6rem
}

.mx-216 {
  margin-left: 21.6rem;
  margin-right: 21.6rem
}

.my-224 {
  margin-top: 22.4rem;
  margin-bottom: 22.4rem
}

.mx-224 {
  margin-left: 22.4rem;
  margin-right: 22.4rem
}

.my-256 {
  margin-top: 25.6rem;
  margin-bottom: 25.6rem
}

.mx-256 {
  margin-left: 25.6rem;
  margin-right: 25.6rem
}

.my-288 {
  margin-top: 28.8rem;
  margin-bottom: 28.8rem
}

.mx-288 {
  margin-left: 28.8rem;
  margin-right: 28.8rem
}

.my-320 {
  margin-top: 32rem;
  margin-bottom: 32rem
}

.mx-320 {
  margin-left: 32rem;
  margin-right: 32rem
}

.my-360 {
  margin-top: 36rem;
  margin-bottom: 36rem
}

.mx-360 {
  margin-left: 36rem;
  margin-right: 36rem
}

.my-384 {
  margin-top: 38.4rem;
  margin-bottom: 38.4rem
}

.mx-384 {
  margin-left: 38.4rem;
  margin-right: 38.4rem
}

.my-400 {
  margin-top: 40rem;
  margin-bottom: 40rem
}

.mx-400 {
  margin-left: 40rem;
  margin-right: 40rem
}

.my-512 {
  margin-top: 51.2rem;
  margin-bottom: 51.2rem
}

.mx-512 {
  margin-left: 51.2rem;
  margin-right: 51.2rem
}

.my-640 {
  margin-top: 64rem;
  margin-bottom: 64rem
}

.mx-640 {
  margin-left: 64rem;
  margin-right: 64rem
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-xs {
  margin-top: 32rem;
  margin-bottom: 32rem
}

.mx-xs {
  margin-left: 32rem;
  margin-right: 32rem
}

.my-sm {
  margin-top: 48rem;
  margin-bottom: 48rem
}

.mx-sm {
  margin-left: 48rem;
  margin-right: 48rem
}

.my-md {
  margin-top: 64rem;
  margin-bottom: 64rem
}

.mx-md {
  margin-left: 64rem;
  margin-right: 64rem
}

.my-lg {
  margin-top: 80rem;
  margin-bottom: 80rem
}

.mx-lg {
  margin-left: 80rem;
  margin-right: 80rem
}

.my-xl {
  margin-top: 96rem;
  margin-bottom: 96rem
}

.mx-xl {
  margin-left: 96rem;
  margin-right: 96rem
}

.my-2xl {
  margin-top: 112rem;
  margin-bottom: 112rem
}

.mx-2xl {
  margin-left: 112rem;
  margin-right: 112rem
}

.my-3xl {
  margin-top: 128rem;
  margin-bottom: 128rem
}

.mx-3xl {
  margin-left: 128rem;
  margin-right: 128rem
}

.my-4xl {
  margin-top: 144rem;
  margin-bottom: 144rem
}

.mx-4xl {
  margin-left: 144rem;
  margin-right: 144rem
}

.my-5xl {
  margin-top: 160rem;
  margin-bottom: 160rem
}

.mx-5xl {
  margin-left: 160rem;
  margin-right: 160rem
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px
}

.my-0\.5 {
  margin-top: 0.05rem;
  margin-bottom: 0.05rem
}

.mx-0\.5 {
  margin-left: 0.05rem;
  margin-right: 0.05rem
}

.my-1\.5 {
  margin-top: 0.15rem;
  margin-bottom: 0.15rem
}

.mx-1\.5 {
  margin-left: 0.15rem;
  margin-right: 0.15rem
}

.my-2\.5 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.mx-2\.5 {
  margin-left: 0.25rem;
  margin-right: 0.25rem
}

.my-3\.5 {
  margin-top: 0.35rem;
  margin-bottom: 0.35rem
}

.mx-3\.5 {
  margin-left: 0.35rem;
  margin-right: 0.35rem
}

.-my-1 {
  margin-top: -0.1rem;
  margin-bottom: -0.1rem
}

.-mx-1 {
  margin-left: -0.1rem;
  margin-right: -0.1rem
}

.-my-2 {
  margin-top: -0.2rem;
  margin-bottom: -0.2rem
}

.-mx-2 {
  margin-left: -0.2rem;
  margin-right: -0.2rem
}

.-my-3 {
  margin-top: -0.3rem;
  margin-bottom: -0.3rem
}

.-mx-3 {
  margin-left: -0.3rem;
  margin-right: -0.3rem
}

.-my-4 {
  margin-top: -0.4rem;
  margin-bottom: -0.4rem
}

.-mx-4 {
  margin-left: -0.4rem;
  margin-right: -0.4rem
}

.-my-5 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem
}

.-mx-5 {
  margin-left: -0.5rem;
  margin-right: -0.5rem
}

.-my-6 {
  margin-top: -0.6rem;
  margin-bottom: -0.6rem
}

.-mx-6 {
  margin-left: -0.6rem;
  margin-right: -0.6rem
}

.-my-7 {
  margin-top: -0.7rem;
  margin-bottom: -0.7rem
}

.-mx-7 {
  margin-left: -0.7rem;
  margin-right: -0.7rem
}

.-my-8 {
  margin-top: -0.8rem;
  margin-bottom: -0.8rem
}

.-mx-8 {
  margin-left: -0.8rem;
  margin-right: -0.8rem
}

.-my-9 {
  margin-top: -0.9rem;
  margin-bottom: -0.9rem
}

.-mx-9 {
  margin-left: -0.9rem;
  margin-right: -0.9rem
}

.-my-10 {
  margin-top: -1rem;
  margin-bottom: -1rem
}

.-mx-10 {
  margin-left: -1rem;
  margin-right: -1rem
}

.-my-12 {
  margin-top: -1.2rem;
  margin-bottom: -1.2rem
}

.-mx-12 {
  margin-left: -1.2rem;
  margin-right: -1.2rem
}

.-my-14 {
  margin-top: -1.4rem;
  margin-bottom: -1.4rem
}

.-mx-14 {
  margin-left: -1.4rem;
  margin-right: -1.4rem
}

.-my-16 {
  margin-top: -1.6rem;
  margin-bottom: -1.6rem
}

.-mx-16 {
  margin-left: -1.6rem;
  margin-right: -1.6rem
}

.-my-20 {
  margin-top: -2rem;
  margin-bottom: -2rem
}

.-mx-20 {
  margin-left: -2rem;
  margin-right: -2rem
}

.-my-24 {
  margin-top: -2.4rem;
  margin-bottom: -2.4rem
}

.-mx-24 {
  margin-left: -2.4rem;
  margin-right: -2.4rem
}

.-my-28 {
  margin-top: -2.8rem;
  margin-bottom: -2.8rem
}

.-mx-28 {
  margin-left: -2.8rem;
  margin-right: -2.8rem
}

.-my-32 {
  margin-top: -3.2rem;
  margin-bottom: -3.2rem
}

.-mx-32 {
  margin-left: -3.2rem;
  margin-right: -3.2rem
}

.-my-36 {
  margin-top: -3.6rem;
  margin-bottom: -3.6rem
}

.-mx-36 {
  margin-left: -3.6rem;
  margin-right: -3.6rem
}

.-my-40 {
  margin-top: -4rem;
  margin-bottom: -4rem
}

.-mx-40 {
  margin-left: -4rem;
  margin-right: -4rem
}

.-my-44 {
  margin-top: -4.4rem;
  margin-bottom: -4.4rem
}

.-mx-44 {
  margin-left: -4.4rem;
  margin-right: -4.4rem
}

.-my-48 {
  margin-top: -4.8rem;
  margin-bottom: -4.8rem
}

.-mx-48 {
  margin-left: -4.8rem;
  margin-right: -4.8rem
}

.-my-52 {
  margin-top: -5.2rem;
  margin-bottom: -5.2rem
}

.-mx-52 {
  margin-left: -5.2rem;
  margin-right: -5.2rem
}

.-my-56 {
  margin-top: -5.6rem;
  margin-bottom: -5.6rem
}

.-mx-56 {
  margin-left: -5.6rem;
  margin-right: -5.6rem
}

.-my-60 {
  margin-top: -6rem;
  margin-bottom: -6rem
}

.-mx-60 {
  margin-left: -6rem;
  margin-right: -6rem
}

.-my-64 {
  margin-top: -6.4rem;
  margin-bottom: -6.4rem
}

.-mx-64 {
  margin-left: -6.4rem;
  margin-right: -6.4rem
}

.-my-68 {
  margin-top: -6.8rem;
  margin-bottom: -6.8rem
}

.-mx-68 {
  margin-left: -6.8rem;
  margin-right: -6.8rem
}

.-my-72 {
  margin-top: -7.2rem;
  margin-bottom: -7.2rem
}

.-mx-72 {
  margin-left: -7.2rem;
  margin-right: -7.2rem
}

.-my-76 {
  margin-top: -7.6rem;
  margin-bottom: -7.6rem
}

.-mx-76 {
  margin-left: -7.6rem;
  margin-right: -7.6rem
}

.-my-80 {
  margin-top: -8rem;
  margin-bottom: -8rem
}

.-mx-80 {
  margin-left: -8rem;
  margin-right: -8rem
}

.-my-84 {
  margin-top: -8.4rem;
  margin-bottom: -8.4rem
}

.-mx-84 {
  margin-left: -8.4rem;
  margin-right: -8.4rem
}

.-my-88 {
  margin-top: -8.8rem;
  margin-bottom: -8.8rem
}

.-mx-88 {
  margin-left: -8.8rem;
  margin-right: -8.8rem
}

.-my-92 {
  margin-top: -9.2rem;
  margin-bottom: -9.2rem
}

.-mx-92 {
  margin-left: -9.2rem;
  margin-right: -9.2rem
}

.-my-96 {
  margin-top: -9.6rem;
  margin-bottom: -9.6rem
}

.-mx-96 {
  margin-left: -9.6rem;
  margin-right: -9.6rem
}

.-my-128 {
  margin-top: -12.8rem;
  margin-bottom: -12.8rem
}

.-mx-128 {
  margin-left: -12.8rem;
  margin-right: -12.8rem
}

.-my-136 {
  margin-top: -13.6rem;
  margin-bottom: -13.6rem
}

.-mx-136 {
  margin-left: -13.6rem;
  margin-right: -13.6rem
}

.-my-160 {
  margin-top: -16rem;
  margin-bottom: -16rem
}

.-mx-160 {
  margin-left: -16rem;
  margin-right: -16rem
}

.-my-192 {
  margin-top: -19.2rem;
  margin-bottom: -19.2rem
}

.-mx-192 {
  margin-left: -19.2rem;
  margin-right: -19.2rem
}

.-my-200 {
  margin-top: -20rem;
  margin-bottom: -20rem
}

.-mx-200 {
  margin-left: -20rem;
  margin-right: -20rem
}

.-my-208 {
  margin-top: -20.8rem;
  margin-bottom: -20.8rem
}

.-mx-208 {
  margin-left: -20.8rem;
  margin-right: -20.8rem
}

.-my-216 {
  margin-top: -21.6rem;
  margin-bottom: -21.6rem
}

.-mx-216 {
  margin-left: -21.6rem;
  margin-right: -21.6rem
}

.-my-224 {
  margin-top: -22.4rem;
  margin-bottom: -22.4rem
}

.-mx-224 {
  margin-left: -22.4rem;
  margin-right: -22.4rem
}

.-my-256 {
  margin-top: -25.6rem;
  margin-bottom: -25.6rem
}

.-mx-256 {
  margin-left: -25.6rem;
  margin-right: -25.6rem
}

.-my-288 {
  margin-top: -28.8rem;
  margin-bottom: -28.8rem
}

.-mx-288 {
  margin-left: -28.8rem;
  margin-right: -28.8rem
}

.-my-320 {
  margin-top: -32rem;
  margin-bottom: -32rem
}

.-mx-320 {
  margin-left: -32rem;
  margin-right: -32rem
}

.-my-360 {
  margin-top: -36rem;
  margin-bottom: -36rem
}

.-mx-360 {
  margin-left: -36rem;
  margin-right: -36rem
}

.-my-384 {
  margin-top: -38.4rem;
  margin-bottom: -38.4rem
}

.-mx-384 {
  margin-left: -38.4rem;
  margin-right: -38.4rem
}

.-my-400 {
  margin-top: -40rem;
  margin-bottom: -40rem
}

.-mx-400 {
  margin-left: -40rem;
  margin-right: -40rem
}

.-my-512 {
  margin-top: -51.2rem;
  margin-bottom: -51.2rem
}

.-mx-512 {
  margin-left: -51.2rem;
  margin-right: -51.2rem
}

.-my-640 {
  margin-top: -64rem;
  margin-bottom: -64rem
}

.-mx-640 {
  margin-left: -64rem;
  margin-right: -64rem
}

.-my-xs {
  margin-top: -32rem;
  margin-bottom: -32rem
}

.-mx-xs {
  margin-left: -32rem;
  margin-right: -32rem
}

.-my-sm {
  margin-top: -48rem;
  margin-bottom: -48rem
}

.-mx-sm {
  margin-left: -48rem;
  margin-right: -48rem
}

.-my-md {
  margin-top: -64rem;
  margin-bottom: -64rem
}

.-mx-md {
  margin-left: -64rem;
  margin-right: -64rem
}

.-my-lg {
  margin-top: -80rem;
  margin-bottom: -80rem
}

.-mx-lg {
  margin-left: -80rem;
  margin-right: -80rem
}

.-my-xl {
  margin-top: -96rem;
  margin-bottom: -96rem
}

.-mx-xl {
  margin-left: -96rem;
  margin-right: -96rem
}

.-my-2xl {
  margin-top: -112rem;
  margin-bottom: -112rem
}

.-mx-2xl {
  margin-left: -112rem;
  margin-right: -112rem
}

.-my-3xl {
  margin-top: -128rem;
  margin-bottom: -128rem
}

.-mx-3xl {
  margin-left: -128rem;
  margin-right: -128rem
}

.-my-4xl {
  margin-top: -144rem;
  margin-bottom: -144rem
}

.-mx-4xl {
  margin-left: -144rem;
  margin-right: -144rem
}

.-my-5xl {
  margin-top: -160rem;
  margin-bottom: -160rem
}

.-mx-5xl {
  margin-left: -160rem;
  margin-right: -160rem
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px
}

.-mx-px {
  margin-left: -1px;
  margin-right: -1px
}

.-my-0\.5 {
  margin-top: -0.05rem;
  margin-bottom: -0.05rem
}

.-mx-0\.5 {
  margin-left: -0.05rem;
  margin-right: -0.05rem
}

.-my-1\.5 {
  margin-top: -0.15rem;
  margin-bottom: -0.15rem
}

.-mx-1\.5 {
  margin-left: -0.15rem;
  margin-right: -0.15rem
}

.-my-2\.5 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem
}

.-mx-2\.5 {
  margin-left: -0.25rem;
  margin-right: -0.25rem
}

.-my-3\.5 {
  margin-top: -0.35rem;
  margin-bottom: -0.35rem
}

.-mx-3\.5 {
  margin-left: -0.35rem;
  margin-right: -0.35rem
}

.mt-0 {
  margin-top: 0
}

.mr-0 {
  margin-right: 0
}

.mb-0 {
  margin-bottom: 0
}

.ml-0 {
  margin-left: 0
}

.mt-1 {
  margin-top: 0.1rem
}

.mr-1 {
  margin-right: 0.1rem
}

.mb-1 {
  margin-bottom: 0.1rem
}

.ml-1 {
  margin-left: 0.1rem
}

.mt-2 {
  margin-top: 0.2rem
}

.mr-2 {
  margin-right: 0.2rem
}

.mb-2 {
  margin-bottom: 0.2rem
}

.ml-2 {
  margin-left: 0.2rem
}

.mt-3 {
  margin-top: 0.3rem
}

.mr-3 {
  margin-right: 0.3rem
}

.mb-3 {
  margin-bottom: 0.3rem
}

.ml-3 {
  margin-left: 0.3rem
}

.mt-4 {
  margin-top: 0.4rem
}

.mr-4 {
  margin-right: 0.4rem
}

.mb-4 {
  margin-bottom: 0.4rem
}

.ml-4 {
  margin-left: 0.4rem
}

.mt-5 {
  margin-top: 0.5rem
}

.mr-5 {
  margin-right: 0.5rem
}

.mb-5 {
  margin-bottom: 0.5rem
}

.ml-5 {
  margin-left: 0.5rem
}

.mt-6 {
  margin-top: 0.6rem
}

.mr-6 {
  margin-right: 0.6rem
}

.mb-6 {
  margin-bottom: 0.6rem
}

.ml-6 {
  margin-left: 0.6rem
}

.mt-7 {
  margin-top: 0.7rem
}

.mr-7 {
  margin-right: 0.7rem
}

.mb-7 {
  margin-bottom: 0.7rem
}

.ml-7 {
  margin-left: 0.7rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mr-8 {
  margin-right: 0.8rem
}

.mb-8 {
  margin-bottom: 0.8rem
}

.ml-8 {
  margin-left: 0.8rem
}

.mt-9 {
  margin-top: 0.9rem
}

.mr-9 {
  margin-right: 0.9rem
}

.mb-9 {
  margin-bottom: 0.9rem
}

.ml-9 {
  margin-left: 0.9rem
}

.mt-10 {
  margin-top: 1.0rem
}

.mr-10 {
  margin-right: 1.0rem
}

.mb-10 {
  margin-bottom: 1.0rem
}

.ml-10 {
  margin-left: 1.0rem
}

.mt-12 {
  margin-top: 1.2rem
}

.mr-12 {
  margin-right: 1.2rem
}

.mb-12 {
  margin-bottom: 1.2rem
}

.ml-12 {
  margin-left: 1.2rem
}

.mt-14 {
  margin-top: 1.4rem
}

.mr-14 {
  margin-right: 1.4rem
}

.mb-14 {
  margin-bottom: 1.4rem
}

.ml-14 {
  margin-left: 1.4rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mr-16 {
  margin-right: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.ml-16 {
  margin-left: 1.6rem
}

.mt-20 {
  margin-top: 2rem
}

.mr-20 {
  margin-right: 2rem
}

.mb-20 {
  margin-bottom: 2rem
}

.ml-20 {
  margin-left: 2rem
}

.mt-24 {
  margin-top: 2.4rem
}

.mr-24 {
  margin-right: 2.4rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.ml-24 {
  margin-left: 2.4rem
}

.mt-28 {
  margin-top: 2.8rem
}

.mr-28 {
  margin-right: 2.8rem
}

.mb-28 {
  margin-bottom: 2.8rem
}

.ml-28 {
  margin-left: 2.8rem
}

.mt-32 {
  margin-top: 3.2rem
}

.mr-32 {
  margin-right: 3.2rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.ml-32 {
  margin-left: 3.2rem
}

.mt-36 {
  margin-top: 3.6rem
}

.mr-36 {
  margin-right: 3.6rem
}

.mb-36 {
  margin-bottom: 3.6rem
}

.ml-36 {
  margin-left: 3.6rem
}

.mt-40 {
  margin-top: 4rem
}

.mr-40 {
  margin-right: 4rem
}

.mb-40 {
  margin-bottom: 4rem
}

.ml-40 {
  margin-left: 4rem
}

.mt-44 {
  margin-top: 4.4rem
}

.mr-44 {
  margin-right: 4.4rem
}

.mb-44 {
  margin-bottom: 4.4rem
}

.ml-44 {
  margin-left: 4.4rem
}

.mt-48 {
  margin-top: 4.8rem
}

.mr-48 {
  margin-right: 4.8rem
}

.mb-48 {
  margin-bottom: 4.8rem
}

.ml-48 {
  margin-left: 4.8rem
}

.mt-52 {
  margin-top: 5.2rem
}

.mr-52 {
  margin-right: 5.2rem
}

.mb-52 {
  margin-bottom: 5.2rem
}

.ml-52 {
  margin-left: 5.2rem
}

.mt-56 {
  margin-top: 5.6rem
}

.mr-56 {
  margin-right: 5.6rem
}

.mb-56 {
  margin-bottom: 5.6rem
}

.ml-56 {
  margin-left: 5.6rem
}

.mt-60 {
  margin-top: 6rem
}

.mr-60 {
  margin-right: 6rem
}

.mb-60 {
  margin-bottom: 6rem
}

.ml-60 {
  margin-left: 6rem
}

.mt-64 {
  margin-top: 6.4rem
}

.mr-64 {
  margin-right: 6.4rem
}

.mb-64 {
  margin-bottom: 6.4rem
}

.ml-64 {
  margin-left: 6.4rem
}

.mt-68 {
  margin-top: 6.8rem
}

.mr-68 {
  margin-right: 6.8rem
}

.mb-68 {
  margin-bottom: 6.8rem
}

.ml-68 {
  margin-left: 6.8rem
}

.mt-72 {
  margin-top: 7.2rem
}

.mr-72 {
  margin-right: 7.2rem
}

.mb-72 {
  margin-bottom: 7.2rem
}

.ml-72 {
  margin-left: 7.2rem
}

.mt-76 {
  margin-top: 7.6rem
}

.mr-76 {
  margin-right: 7.6rem
}

.mb-76 {
  margin-bottom: 7.6rem
}

.ml-76 {
  margin-left: 7.6rem
}

.mt-80 {
  margin-top: 8rem
}

.mr-80 {
  margin-right: 8rem
}

.mb-80 {
  margin-bottom: 8rem
}

.ml-80 {
  margin-left: 8rem
}

.mt-84 {
  margin-top: 8.4rem
}

.mr-84 {
  margin-right: 8.4rem
}

.mb-84 {
  margin-bottom: 8.4rem
}

.ml-84 {
  margin-left: 8.4rem
}

.mt-88 {
  margin-top: 8.8rem
}

.mr-88 {
  margin-right: 8.8rem
}

.mb-88 {
  margin-bottom: 8.8rem
}

.ml-88 {
  margin-left: 8.8rem
}

.mt-92 {
  margin-top: 9.2rem
}

.mr-92 {
  margin-right: 9.2rem
}

.mb-92 {
  margin-bottom: 9.2rem
}

.ml-92 {
  margin-left: 9.2rem
}

.mt-96 {
  margin-top: 9.6rem
}

.mr-96 {
  margin-right: 9.6rem
}

.mb-96 {
  margin-bottom: 9.6rem
}

.ml-96 {
  margin-left: 9.6rem
}

.mt-128 {
  margin-top: 12.8rem
}

.mr-128 {
  margin-right: 12.8rem
}

.mb-128 {
  margin-bottom: 12.8rem
}

.ml-128 {
  margin-left: 12.8rem
}

.mt-136 {
  margin-top: 13.6rem
}

.mr-136 {
  margin-right: 13.6rem
}

.mb-136 {
  margin-bottom: 13.6rem
}

.ml-136 {
  margin-left: 13.6rem
}

.mt-160 {
  margin-top: 16rem
}

.mr-160 {
  margin-right: 16rem
}

.mb-160 {
  margin-bottom: 16rem
}

.ml-160 {
  margin-left: 16rem
}

.mt-192 {
  margin-top: 19.2rem
}

.mr-192 {
  margin-right: 19.2rem
}

.mb-192 {
  margin-bottom: 19.2rem
}

.ml-192 {
  margin-left: 19.2rem
}

.mt-200 {
  margin-top: 20rem
}

.mr-200 {
  margin-right: 20rem
}

.mb-200 {
  margin-bottom: 20rem
}

.ml-200 {
  margin-left: 20rem
}

.mt-208 {
  margin-top: 20.8rem
}

.mr-208 {
  margin-right: 20.8rem
}

.mb-208 {
  margin-bottom: 20.8rem
}

.ml-208 {
  margin-left: 20.8rem
}

.mt-216 {
  margin-top: 21.6rem
}

.mr-216 {
  margin-right: 21.6rem
}

.mb-216 {
  margin-bottom: 21.6rem
}

.ml-216 {
  margin-left: 21.6rem
}

.mt-224 {
  margin-top: 22.4rem
}

.mr-224 {
  margin-right: 22.4rem
}

.mb-224 {
  margin-bottom: 22.4rem
}

.ml-224 {
  margin-left: 22.4rem
}

.mt-256 {
  margin-top: 25.6rem
}

.mr-256 {
  margin-right: 25.6rem
}

.mb-256 {
  margin-bottom: 25.6rem
}

.ml-256 {
  margin-left: 25.6rem
}

.mt-288 {
  margin-top: 28.8rem
}

.mr-288 {
  margin-right: 28.8rem
}

.mb-288 {
  margin-bottom: 28.8rem
}

.ml-288 {
  margin-left: 28.8rem
}

.mt-320 {
  margin-top: 32rem
}

.mr-320 {
  margin-right: 32rem
}

.mb-320 {
  margin-bottom: 32rem
}

.ml-320 {
  margin-left: 32rem
}

.mt-360 {
  margin-top: 36rem
}

.mr-360 {
  margin-right: 36rem
}

.mb-360 {
  margin-bottom: 36rem
}

.ml-360 {
  margin-left: 36rem
}

.mt-384 {
  margin-top: 38.4rem
}

.mr-384 {
  margin-right: 38.4rem
}

.mb-384 {
  margin-bottom: 38.4rem
}

.ml-384 {
  margin-left: 38.4rem
}

.mt-400 {
  margin-top: 40rem
}

.mr-400 {
  margin-right: 40rem
}

.mb-400 {
  margin-bottom: 40rem
}

.ml-400 {
  margin-left: 40rem
}

.mt-512 {
  margin-top: 51.2rem
}

.mr-512 {
  margin-right: 51.2rem
}

.mb-512 {
  margin-bottom: 51.2rem
}

.ml-512 {
  margin-left: 51.2rem
}

.mt-640 {
  margin-top: 64rem
}

.mr-640 {
  margin-right: 64rem
}

.mb-640 {
  margin-bottom: 64rem
}

.ml-640 {
  margin-left: 64rem
}

.mt-auto {
  margin-top: auto
}

.mr-auto {
  margin-right: auto
}

.mb-auto {
  margin-bottom: auto
}

.ml-auto {
  margin-left: auto
}

.mt-xs {
  margin-top: 32rem
}

.mr-xs {
  margin-right: 32rem
}

.mb-xs {
  margin-bottom: 32rem
}

.ml-xs {
  margin-left: 32rem
}

.mt-sm {
  margin-top: 48rem
}

.mr-sm {
  margin-right: 48rem
}

.mb-sm {
  margin-bottom: 48rem
}

.ml-sm {
  margin-left: 48rem
}

.mt-md {
  margin-top: 64rem
}

.mr-md {
  margin-right: 64rem
}

.mb-md {
  margin-bottom: 64rem
}

.ml-md {
  margin-left: 64rem
}

.mt-lg {
  margin-top: 80rem
}

.mr-lg {
  margin-right: 80rem
}

.mb-lg {
  margin-bottom: 80rem
}

.ml-lg {
  margin-left: 80rem
}

.mt-xl {
  margin-top: 96rem
}

.mr-xl {
  margin-right: 96rem
}

.mb-xl {
  margin-bottom: 96rem
}

.ml-xl {
  margin-left: 96rem
}

.mt-2xl {
  margin-top: 112rem
}

.mr-2xl {
  margin-right: 112rem
}

.mb-2xl {
  margin-bottom: 112rem
}

.ml-2xl {
  margin-left: 112rem
}

.mt-3xl {
  margin-top: 128rem
}

.mr-3xl {
  margin-right: 128rem
}

.mb-3xl {
  margin-bottom: 128rem
}

.ml-3xl {
  margin-left: 128rem
}

.mt-4xl {
  margin-top: 144rem
}

.mr-4xl {
  margin-right: 144rem
}

.mb-4xl {
  margin-bottom: 144rem
}

.ml-4xl {
  margin-left: 144rem
}

.mt-5xl {
  margin-top: 160rem
}

.mr-5xl {
  margin-right: 160rem
}

.mb-5xl {
  margin-bottom: 160rem
}

.ml-5xl {
  margin-left: 160rem
}

.mt-px {
  margin-top: 1px
}

.mr-px {
  margin-right: 1px
}

.mb-px {
  margin-bottom: 1px
}

.ml-px {
  margin-left: 1px
}

.mt-0\.5 {
  margin-top: 0.05rem
}

.mr-0\.5 {
  margin-right: 0.05rem
}

.mb-0\.5 {
  margin-bottom: 0.05rem
}

.ml-0\.5 {
  margin-left: 0.05rem
}

.mt-1\.5 {
  margin-top: 0.15rem
}

.mr-1\.5 {
  margin-right: 0.15rem
}

.mb-1\.5 {
  margin-bottom: 0.15rem
}

.ml-1\.5 {
  margin-left: 0.15rem
}

.mt-2\.5 {
  margin-top: 0.25rem
}

.mr-2\.5 {
  margin-right: 0.25rem
}

.mb-2\.5 {
  margin-bottom: 0.25rem
}

.ml-2\.5 {
  margin-left: 0.25rem
}

.mt-3\.5 {
  margin-top: 0.35rem
}

.mr-3\.5 {
  margin-right: 0.35rem
}

.mb-3\.5 {
  margin-bottom: 0.35rem
}

.ml-3\.5 {
  margin-left: 0.35rem
}

.-mt-1 {
  margin-top: -0.1rem
}

.-mr-1 {
  margin-right: -0.1rem
}

.-mb-1 {
  margin-bottom: -0.1rem
}

.-ml-1 {
  margin-left: -0.1rem
}

.-mt-2 {
  margin-top: -0.2rem
}

.-mr-2 {
  margin-right: -0.2rem
}

.-mb-2 {
  margin-bottom: -0.2rem
}

.-ml-2 {
  margin-left: -0.2rem
}

.-mt-3 {
  margin-top: -0.3rem
}

.-mr-3 {
  margin-right: -0.3rem
}

.-mb-3 {
  margin-bottom: -0.3rem
}

.-ml-3 {
  margin-left: -0.3rem
}

.-mt-4 {
  margin-top: -0.4rem
}

.-mr-4 {
  margin-right: -0.4rem
}

.-mb-4 {
  margin-bottom: -0.4rem
}

.-ml-4 {
  margin-left: -0.4rem
}

.-mt-5 {
  margin-top: -0.5rem
}

.-mr-5 {
  margin-right: -0.5rem
}

.-mb-5 {
  margin-bottom: -0.5rem
}

.-ml-5 {
  margin-left: -0.5rem
}

.-mt-6 {
  margin-top: -0.6rem
}

.-mr-6 {
  margin-right: -0.6rem
}

.-mb-6 {
  margin-bottom: -0.6rem
}

.-ml-6 {
  margin-left: -0.6rem
}

.-mt-7 {
  margin-top: -0.7rem
}

.-mr-7 {
  margin-right: -0.7rem
}

.-mb-7 {
  margin-bottom: -0.7rem
}

.-ml-7 {
  margin-left: -0.7rem
}

.-mt-8 {
  margin-top: -0.8rem
}

.-mr-8 {
  margin-right: -0.8rem
}

.-mb-8 {
  margin-bottom: -0.8rem
}

.-ml-8 {
  margin-left: -0.8rem
}

.-mt-9 {
  margin-top: -0.9rem
}

.-mr-9 {
  margin-right: -0.9rem
}

.-mb-9 {
  margin-bottom: -0.9rem
}

.-ml-9 {
  margin-left: -0.9rem
}

.-mt-10 {
  margin-top: -1rem
}

.-mr-10 {
  margin-right: -1rem
}

.-mb-10 {
  margin-bottom: -1rem
}

.-ml-10 {
  margin-left: -1rem
}

.-mt-12 {
  margin-top: -1.2rem
}

.-mr-12 {
  margin-right: -1.2rem
}

.-mb-12 {
  margin-bottom: -1.2rem
}

.-ml-12 {
  margin-left: -1.2rem
}

.-mt-14 {
  margin-top: -1.4rem
}

.-mr-14 {
  margin-right: -1.4rem
}

.-mb-14 {
  margin-bottom: -1.4rem
}

.-ml-14 {
  margin-left: -1.4rem
}

.-mt-16 {
  margin-top: -1.6rem
}

.-mr-16 {
  margin-right: -1.6rem
}

.-mb-16 {
  margin-bottom: -1.6rem
}

.-ml-16 {
  margin-left: -1.6rem
}

.-mt-20 {
  margin-top: -2rem
}

.-mr-20 {
  margin-right: -2rem
}

.-mb-20 {
  margin-bottom: -2rem
}

.-ml-20 {
  margin-left: -2rem
}

.-mt-24 {
  margin-top: -2.4rem
}

.-mr-24 {
  margin-right: -2.4rem
}

.-mb-24 {
  margin-bottom: -2.4rem
}

.-ml-24 {
  margin-left: -2.4rem
}

.-mt-28 {
  margin-top: -2.8rem
}

.-mr-28 {
  margin-right: -2.8rem
}

.-mb-28 {
  margin-bottom: -2.8rem
}

.-ml-28 {
  margin-left: -2.8rem
}

.-mt-32 {
  margin-top: -3.2rem
}

.-mr-32 {
  margin-right: -3.2rem
}

.-mb-32 {
  margin-bottom: -3.2rem
}

.-ml-32 {
  margin-left: -3.2rem
}

.-mt-36 {
  margin-top: -3.6rem
}

.-mr-36 {
  margin-right: -3.6rem
}

.-mb-36 {
  margin-bottom: -3.6rem
}

.-ml-36 {
  margin-left: -3.6rem
}

.-mt-40 {
  margin-top: -4rem
}

.-mr-40 {
  margin-right: -4rem
}

.-mb-40 {
  margin-bottom: -4rem
}

.-ml-40 {
  margin-left: -4rem
}

.-mt-44 {
  margin-top: -4.4rem
}

.-mr-44 {
  margin-right: -4.4rem
}

.-mb-44 {
  margin-bottom: -4.4rem
}

.-ml-44 {
  margin-left: -4.4rem
}

.-mt-48 {
  margin-top: -4.8rem
}

.-mr-48 {
  margin-right: -4.8rem
}

.-mb-48 {
  margin-bottom: -4.8rem
}

.-ml-48 {
  margin-left: -4.8rem
}

.-mt-52 {
  margin-top: -5.2rem
}

.-mr-52 {
  margin-right: -5.2rem
}

.-mb-52 {
  margin-bottom: -5.2rem
}

.-ml-52 {
  margin-left: -5.2rem
}

.-mt-56 {
  margin-top: -5.6rem
}

.-mr-56 {
  margin-right: -5.6rem
}

.-mb-56 {
  margin-bottom: -5.6rem
}

.-ml-56 {
  margin-left: -5.6rem
}

.-mt-60 {
  margin-top: -6rem
}

.-mr-60 {
  margin-right: -6rem
}

.-mb-60 {
  margin-bottom: -6rem
}

.-ml-60 {
  margin-left: -6rem
}

.-mt-64 {
  margin-top: -6.4rem
}

.-mr-64 {
  margin-right: -6.4rem
}

.-mb-64 {
  margin-bottom: -6.4rem
}

.-ml-64 {
  margin-left: -6.4rem
}

.-mt-68 {
  margin-top: -6.8rem
}

.-mr-68 {
  margin-right: -6.8rem
}

.-mb-68 {
  margin-bottom: -6.8rem
}

.-ml-68 {
  margin-left: -6.8rem
}

.-mt-72 {
  margin-top: -7.2rem
}

.-mr-72 {
  margin-right: -7.2rem
}

.-mb-72 {
  margin-bottom: -7.2rem
}

.-ml-72 {
  margin-left: -7.2rem
}

.-mt-76 {
  margin-top: -7.6rem
}

.-mr-76 {
  margin-right: -7.6rem
}

.-mb-76 {
  margin-bottom: -7.6rem
}

.-ml-76 {
  margin-left: -7.6rem
}

.-mt-80 {
  margin-top: -8rem
}

.-mr-80 {
  margin-right: -8rem
}

.-mb-80 {
  margin-bottom: -8rem
}

.-ml-80 {
  margin-left: -8rem
}

.-mt-84 {
  margin-top: -8.4rem
}

.-mr-84 {
  margin-right: -8.4rem
}

.-mb-84 {
  margin-bottom: -8.4rem
}

.-ml-84 {
  margin-left: -8.4rem
}

.-mt-88 {
  margin-top: -8.8rem
}

.-mr-88 {
  margin-right: -8.8rem
}

.-mb-88 {
  margin-bottom: -8.8rem
}

.-ml-88 {
  margin-left: -8.8rem
}

.-mt-92 {
  margin-top: -9.2rem
}

.-mr-92 {
  margin-right: -9.2rem
}

.-mb-92 {
  margin-bottom: -9.2rem
}

.-ml-92 {
  margin-left: -9.2rem
}

.-mt-96 {
  margin-top: -9.6rem
}

.-mr-96 {
  margin-right: -9.6rem
}

.-mb-96 {
  margin-bottom: -9.6rem
}

.-ml-96 {
  margin-left: -9.6rem
}

.-mt-128 {
  margin-top: -12.8rem
}

.-mr-128 {
  margin-right: -12.8rem
}

.-mb-128 {
  margin-bottom: -12.8rem
}

.-ml-128 {
  margin-left: -12.8rem
}

.-mt-136 {
  margin-top: -13.6rem
}

.-mr-136 {
  margin-right: -13.6rem
}

.-mb-136 {
  margin-bottom: -13.6rem
}

.-ml-136 {
  margin-left: -13.6rem
}

.-mt-160 {
  margin-top: -16rem
}

.-mr-160 {
  margin-right: -16rem
}

.-mb-160 {
  margin-bottom: -16rem
}

.-ml-160 {
  margin-left: -16rem
}

.-mt-192 {
  margin-top: -19.2rem
}

.-mr-192 {
  margin-right: -19.2rem
}

.-mb-192 {
  margin-bottom: -19.2rem
}

.-ml-192 {
  margin-left: -19.2rem
}

.-mt-200 {
  margin-top: -20rem
}

.-mr-200 {
  margin-right: -20rem
}

.-mb-200 {
  margin-bottom: -20rem
}

.-ml-200 {
  margin-left: -20rem
}

.-mt-208 {
  margin-top: -20.8rem
}

.-mr-208 {
  margin-right: -20.8rem
}

.-mb-208 {
  margin-bottom: -20.8rem
}

.-ml-208 {
  margin-left: -20.8rem
}

.-mt-216 {
  margin-top: -21.6rem
}

.-mr-216 {
  margin-right: -21.6rem
}

.-mb-216 {
  margin-bottom: -21.6rem
}

.-ml-216 {
  margin-left: -21.6rem
}

.-mt-224 {
  margin-top: -22.4rem
}

.-mr-224 {
  margin-right: -22.4rem
}

.-mb-224 {
  margin-bottom: -22.4rem
}

.-ml-224 {
  margin-left: -22.4rem
}

.-mt-256 {
  margin-top: -25.6rem
}

.-mr-256 {
  margin-right: -25.6rem
}

.-mb-256 {
  margin-bottom: -25.6rem
}

.-ml-256 {
  margin-left: -25.6rem
}

.-mt-288 {
  margin-top: -28.8rem
}

.-mr-288 {
  margin-right: -28.8rem
}

.-mb-288 {
  margin-bottom: -28.8rem
}

.-ml-288 {
  margin-left: -28.8rem
}

.-mt-320 {
  margin-top: -32rem
}

.-mr-320 {
  margin-right: -32rem
}

.-mb-320 {
  margin-bottom: -32rem
}

.-ml-320 {
  margin-left: -32rem
}

.-mt-360 {
  margin-top: -36rem
}

.-mr-360 {
  margin-right: -36rem
}

.-mb-360 {
  margin-bottom: -36rem
}

.-ml-360 {
  margin-left: -36rem
}

.-mt-384 {
  margin-top: -38.4rem
}

.-mr-384 {
  margin-right: -38.4rem
}

.-mb-384 {
  margin-bottom: -38.4rem
}

.-ml-384 {
  margin-left: -38.4rem
}

.-mt-400 {
  margin-top: -40rem
}

.-mr-400 {
  margin-right: -40rem
}

.-mb-400 {
  margin-bottom: -40rem
}

.-ml-400 {
  margin-left: -40rem
}

.-mt-512 {
  margin-top: -51.2rem
}

.-mr-512 {
  margin-right: -51.2rem
}

.-mb-512 {
  margin-bottom: -51.2rem
}

.-ml-512 {
  margin-left: -51.2rem
}

.-mt-640 {
  margin-top: -64rem
}

.-mr-640 {
  margin-right: -64rem
}

.-mb-640 {
  margin-bottom: -64rem
}

.-ml-640 {
  margin-left: -64rem
}

.-mt-xs {
  margin-top: -32rem
}

.-mr-xs {
  margin-right: -32rem
}

.-mb-xs {
  margin-bottom: -32rem
}

.-ml-xs {
  margin-left: -32rem
}

.-mt-sm {
  margin-top: -48rem
}

.-mr-sm {
  margin-right: -48rem
}

.-mb-sm {
  margin-bottom: -48rem
}

.-ml-sm {
  margin-left: -48rem
}

.-mt-md {
  margin-top: -64rem
}

.-mr-md {
  margin-right: -64rem
}

.-mb-md {
  margin-bottom: -64rem
}

.-ml-md {
  margin-left: -64rem
}

.-mt-lg {
  margin-top: -80rem
}

.-mr-lg {
  margin-right: -80rem
}

.-mb-lg {
  margin-bottom: -80rem
}

.-ml-lg {
  margin-left: -80rem
}

.-mt-xl {
  margin-top: -96rem
}

.-mr-xl {
  margin-right: -96rem
}

.-mb-xl {
  margin-bottom: -96rem
}

.-ml-xl {
  margin-left: -96rem
}

.-mt-2xl {
  margin-top: -112rem
}

.-mr-2xl {
  margin-right: -112rem
}

.-mb-2xl {
  margin-bottom: -112rem
}

.-ml-2xl {
  margin-left: -112rem
}

.-mt-3xl {
  margin-top: -128rem
}

.-mr-3xl {
  margin-right: -128rem
}

.-mb-3xl {
  margin-bottom: -128rem
}

.-ml-3xl {
  margin-left: -128rem
}

.-mt-4xl {
  margin-top: -144rem
}

.-mr-4xl {
  margin-right: -144rem
}

.-mb-4xl {
  margin-bottom: -144rem
}

.-ml-4xl {
  margin-left: -144rem
}

.-mt-5xl {
  margin-top: -160rem
}

.-mr-5xl {
  margin-right: -160rem
}

.-mb-5xl {
  margin-bottom: -160rem
}

.-ml-5xl {
  margin-left: -160rem
}

.-mt-px {
  margin-top: -1px
}

.-mr-px {
  margin-right: -1px
}

.-mb-px {
  margin-bottom: -1px
}

.-ml-px {
  margin-left: -1px
}

.-mt-0\.5 {
  margin-top: -0.05rem
}

.-mr-0\.5 {
  margin-right: -0.05rem
}

.-mb-0\.5 {
  margin-bottom: -0.05rem
}

.-ml-0\.5 {
  margin-left: -0.05rem
}

.-mt-1\.5 {
  margin-top: -0.15rem
}

.-mr-1\.5 {
  margin-right: -0.15rem
}

.-mb-1\.5 {
  margin-bottom: -0.15rem
}

.-ml-1\.5 {
  margin-left: -0.15rem
}

.-mt-2\.5 {
  margin-top: -0.25rem
}

.-mr-2\.5 {
  margin-right: -0.25rem
}

.-mb-2\.5 {
  margin-bottom: -0.25rem
}

.-ml-2\.5 {
  margin-left: -0.25rem
}

.-mt-3\.5 {
  margin-top: -0.35rem
}

.-mr-3\.5 {
  margin-right: -0.35rem
}

.-mb-3\.5 {
  margin-bottom: -0.35rem
}

.-ml-3\.5 {
  margin-left: -0.35rem
}

[dir='ltr'] .ltr\:m-0,[dir='ltr'].ltr\:m-0 {
  margin: 0
}

[dir='ltr'] .ltr\:m-1,[dir='ltr'].ltr\:m-1 {
  margin: 0.1rem
}

[dir='ltr'] .ltr\:m-2,[dir='ltr'].ltr\:m-2 {
  margin: 0.2rem
}

[dir='ltr'] .ltr\:m-3,[dir='ltr'].ltr\:m-3 {
  margin: 0.3rem
}

[dir='ltr'] .ltr\:m-4,[dir='ltr'].ltr\:m-4 {
  margin: 0.4rem
}

[dir='ltr'] .ltr\:m-5,[dir='ltr'].ltr\:m-5 {
  margin: 0.5rem
}

[dir='ltr'] .ltr\:m-6,[dir='ltr'].ltr\:m-6 {
  margin: 0.6rem
}

[dir='ltr'] .ltr\:m-7,[dir='ltr'].ltr\:m-7 {
  margin: 0.7rem
}

[dir='ltr'] .ltr\:m-8,[dir='ltr'].ltr\:m-8 {
  margin: 0.8rem
}

[dir='ltr'] .ltr\:m-9,[dir='ltr'].ltr\:m-9 {
  margin: 0.9rem
}

[dir='ltr'] .ltr\:m-10,[dir='ltr'].ltr\:m-10 {
  margin: 1.0rem
}

[dir='ltr'] .ltr\:m-12,[dir='ltr'].ltr\:m-12 {
  margin: 1.2rem
}

[dir='ltr'] .ltr\:m-14,[dir='ltr'].ltr\:m-14 {
  margin: 1.4rem
}

[dir='ltr'] .ltr\:m-16,[dir='ltr'].ltr\:m-16 {
  margin: 1.6rem
}

[dir='ltr'] .ltr\:m-20,[dir='ltr'].ltr\:m-20 {
  margin: 2rem
}

[dir='ltr'] .ltr\:m-24,[dir='ltr'].ltr\:m-24 {
  margin: 2.4rem
}

[dir='ltr'] .ltr\:m-28,[dir='ltr'].ltr\:m-28 {
  margin: 2.8rem
}

[dir='ltr'] .ltr\:m-32,[dir='ltr'].ltr\:m-32 {
  margin: 3.2rem
}

[dir='ltr'] .ltr\:m-36,[dir='ltr'].ltr\:m-36 {
  margin: 3.6rem
}

[dir='ltr'] .ltr\:m-40,[dir='ltr'].ltr\:m-40 {
  margin: 4rem
}

[dir='ltr'] .ltr\:m-44,[dir='ltr'].ltr\:m-44 {
  margin: 4.4rem
}

[dir='ltr'] .ltr\:m-48,[dir='ltr'].ltr\:m-48 {
  margin: 4.8rem
}

[dir='ltr'] .ltr\:m-52,[dir='ltr'].ltr\:m-52 {
  margin: 5.2rem
}

[dir='ltr'] .ltr\:m-56,[dir='ltr'].ltr\:m-56 {
  margin: 5.6rem
}

[dir='ltr'] .ltr\:m-60,[dir='ltr'].ltr\:m-60 {
  margin: 6rem
}

[dir='ltr'] .ltr\:m-64,[dir='ltr'].ltr\:m-64 {
  margin: 6.4rem
}

[dir='ltr'] .ltr\:m-68,[dir='ltr'].ltr\:m-68 {
  margin: 6.8rem
}

[dir='ltr'] .ltr\:m-72,[dir='ltr'].ltr\:m-72 {
  margin: 7.2rem
}

[dir='ltr'] .ltr\:m-76,[dir='ltr'].ltr\:m-76 {
  margin: 7.6rem
}

[dir='ltr'] .ltr\:m-80,[dir='ltr'].ltr\:m-80 {
  margin: 8rem
}

[dir='ltr'] .ltr\:m-84,[dir='ltr'].ltr\:m-84 {
  margin: 8.4rem
}

[dir='ltr'] .ltr\:m-88,[dir='ltr'].ltr\:m-88 {
  margin: 8.8rem
}

[dir='ltr'] .ltr\:m-92,[dir='ltr'].ltr\:m-92 {
  margin: 9.2rem
}

[dir='ltr'] .ltr\:m-96,[dir='ltr'].ltr\:m-96 {
  margin: 9.6rem
}

[dir='ltr'] .ltr\:m-128,[dir='ltr'].ltr\:m-128 {
  margin: 12.8rem
}

[dir='ltr'] .ltr\:m-136,[dir='ltr'].ltr\:m-136 {
  margin: 13.6rem
}

[dir='ltr'] .ltr\:m-160,[dir='ltr'].ltr\:m-160 {
  margin: 16rem
}

[dir='ltr'] .ltr\:m-192,[dir='ltr'].ltr\:m-192 {
  margin: 19.2rem
}

[dir='ltr'] .ltr\:m-200,[dir='ltr'].ltr\:m-200 {
  margin: 20rem
}

[dir='ltr'] .ltr\:m-208,[dir='ltr'].ltr\:m-208 {
  margin: 20.8rem
}

[dir='ltr'] .ltr\:m-216,[dir='ltr'].ltr\:m-216 {
  margin: 21.6rem
}

[dir='ltr'] .ltr\:m-224,[dir='ltr'].ltr\:m-224 {
  margin: 22.4rem
}

[dir='ltr'] .ltr\:m-256,[dir='ltr'].ltr\:m-256 {
  margin: 25.6rem
}

[dir='ltr'] .ltr\:m-288,[dir='ltr'].ltr\:m-288 {
  margin: 28.8rem
}

[dir='ltr'] .ltr\:m-320,[dir='ltr'].ltr\:m-320 {
  margin: 32rem
}

[dir='ltr'] .ltr\:m-360,[dir='ltr'].ltr\:m-360 {
  margin: 36rem
}

[dir='ltr'] .ltr\:m-384,[dir='ltr'].ltr\:m-384 {
  margin: 38.4rem
}

[dir='ltr'] .ltr\:m-400,[dir='ltr'].ltr\:m-400 {
  margin: 40rem
}

[dir='ltr'] .ltr\:m-512,[dir='ltr'].ltr\:m-512 {
  margin: 51.2rem
}

[dir='ltr'] .ltr\:m-640,[dir='ltr'].ltr\:m-640 {
  margin: 64rem
}

[dir='ltr'] .ltr\:m-auto,[dir='ltr'].ltr\:m-auto {
  margin: auto
}

[dir='ltr'] .ltr\:m-xs,[dir='ltr'].ltr\:m-xs {
  margin: 32rem
}

[dir='ltr'] .ltr\:m-sm,[dir='ltr'].ltr\:m-sm {
  margin: 48rem
}

[dir='ltr'] .ltr\:m-md,[dir='ltr'].ltr\:m-md {
  margin: 64rem
}

[dir='ltr'] .ltr\:m-lg,[dir='ltr'].ltr\:m-lg {
  margin: 80rem
}

[dir='ltr'] .ltr\:m-xl,[dir='ltr'].ltr\:m-xl {
  margin: 96rem
}

[dir='ltr'] .ltr\:m-2xl,[dir='ltr'].ltr\:m-2xl {
  margin: 112rem
}

[dir='ltr'] .ltr\:m-3xl,[dir='ltr'].ltr\:m-3xl {
  margin: 128rem
}

[dir='ltr'] .ltr\:m-4xl,[dir='ltr'].ltr\:m-4xl {
  margin: 144rem
}

[dir='ltr'] .ltr\:m-5xl,[dir='ltr'].ltr\:m-5xl {
  margin: 160rem
}

[dir='ltr'] .ltr\:m-px,[dir='ltr'].ltr\:m-px {
  margin: 1px
}

[dir='ltr'] .ltr\:m-0\.5,[dir='ltr'].ltr\:m-0\.5 {
  margin: 0.05rem
}

[dir='ltr'] .ltr\:m-1\.5,[dir='ltr'].ltr\:m-1\.5 {
  margin: 0.15rem
}

[dir='ltr'] .ltr\:m-2\.5,[dir='ltr'].ltr\:m-2\.5 {
  margin: 0.25rem
}

[dir='ltr'] .ltr\:m-3\.5,[dir='ltr'].ltr\:m-3\.5 {
  margin: 0.35rem
}

[dir='ltr'] .ltr\:-m-1,[dir='ltr'].ltr\:-m-1 {
  margin: -0.1rem
}

[dir='ltr'] .ltr\:-m-2,[dir='ltr'].ltr\:-m-2 {
  margin: -0.2rem
}

[dir='ltr'] .ltr\:-m-3,[dir='ltr'].ltr\:-m-3 {
  margin: -0.3rem
}

[dir='ltr'] .ltr\:-m-4,[dir='ltr'].ltr\:-m-4 {
  margin: -0.4rem
}

[dir='ltr'] .ltr\:-m-5,[dir='ltr'].ltr\:-m-5 {
  margin: -0.5rem
}

[dir='ltr'] .ltr\:-m-6,[dir='ltr'].ltr\:-m-6 {
  margin: -0.6rem
}

[dir='ltr'] .ltr\:-m-7,[dir='ltr'].ltr\:-m-7 {
  margin: -0.7rem
}

[dir='ltr'] .ltr\:-m-8,[dir='ltr'].ltr\:-m-8 {
  margin: -0.8rem
}

[dir='ltr'] .ltr\:-m-9,[dir='ltr'].ltr\:-m-9 {
  margin: -0.9rem
}

[dir='ltr'] .ltr\:-m-10,[dir='ltr'].ltr\:-m-10 {
  margin: -1rem
}

[dir='ltr'] .ltr\:-m-12,[dir='ltr'].ltr\:-m-12 {
  margin: -1.2rem
}

[dir='ltr'] .ltr\:-m-14,[dir='ltr'].ltr\:-m-14 {
  margin: -1.4rem
}

[dir='ltr'] .ltr\:-m-16,[dir='ltr'].ltr\:-m-16 {
  margin: -1.6rem
}

[dir='ltr'] .ltr\:-m-20,[dir='ltr'].ltr\:-m-20 {
  margin: -2rem
}

[dir='ltr'] .ltr\:-m-24,[dir='ltr'].ltr\:-m-24 {
  margin: -2.4rem
}

[dir='ltr'] .ltr\:-m-28,[dir='ltr'].ltr\:-m-28 {
  margin: -2.8rem
}

[dir='ltr'] .ltr\:-m-32,[dir='ltr'].ltr\:-m-32 {
  margin: -3.2rem
}

[dir='ltr'] .ltr\:-m-36,[dir='ltr'].ltr\:-m-36 {
  margin: -3.6rem
}

[dir='ltr'] .ltr\:-m-40,[dir='ltr'].ltr\:-m-40 {
  margin: -4rem
}

[dir='ltr'] .ltr\:-m-44,[dir='ltr'].ltr\:-m-44 {
  margin: -4.4rem
}

[dir='ltr'] .ltr\:-m-48,[dir='ltr'].ltr\:-m-48 {
  margin: -4.8rem
}

[dir='ltr'] .ltr\:-m-52,[dir='ltr'].ltr\:-m-52 {
  margin: -5.2rem
}

[dir='ltr'] .ltr\:-m-56,[dir='ltr'].ltr\:-m-56 {
  margin: -5.6rem
}

[dir='ltr'] .ltr\:-m-60,[dir='ltr'].ltr\:-m-60 {
  margin: -6rem
}

[dir='ltr'] .ltr\:-m-64,[dir='ltr'].ltr\:-m-64 {
  margin: -6.4rem
}

[dir='ltr'] .ltr\:-m-68,[dir='ltr'].ltr\:-m-68 {
  margin: -6.8rem
}

[dir='ltr'] .ltr\:-m-72,[dir='ltr'].ltr\:-m-72 {
  margin: -7.2rem
}

[dir='ltr'] .ltr\:-m-76,[dir='ltr'].ltr\:-m-76 {
  margin: -7.6rem
}

[dir='ltr'] .ltr\:-m-80,[dir='ltr'].ltr\:-m-80 {
  margin: -8rem
}

[dir='ltr'] .ltr\:-m-84,[dir='ltr'].ltr\:-m-84 {
  margin: -8.4rem
}

[dir='ltr'] .ltr\:-m-88,[dir='ltr'].ltr\:-m-88 {
  margin: -8.8rem
}

[dir='ltr'] .ltr\:-m-92,[dir='ltr'].ltr\:-m-92 {
  margin: -9.2rem
}

[dir='ltr'] .ltr\:-m-96,[dir='ltr'].ltr\:-m-96 {
  margin: -9.6rem
}

[dir='ltr'] .ltr\:-m-128,[dir='ltr'].ltr\:-m-128 {
  margin: -12.8rem
}

[dir='ltr'] .ltr\:-m-136,[dir='ltr'].ltr\:-m-136 {
  margin: -13.6rem
}

[dir='ltr'] .ltr\:-m-160,[dir='ltr'].ltr\:-m-160 {
  margin: -16rem
}

[dir='ltr'] .ltr\:-m-192,[dir='ltr'].ltr\:-m-192 {
  margin: -19.2rem
}

[dir='ltr'] .ltr\:-m-200,[dir='ltr'].ltr\:-m-200 {
  margin: -20rem
}

[dir='ltr'] .ltr\:-m-208,[dir='ltr'].ltr\:-m-208 {
  margin: -20.8rem
}

[dir='ltr'] .ltr\:-m-216,[dir='ltr'].ltr\:-m-216 {
  margin: -21.6rem
}

[dir='ltr'] .ltr\:-m-224,[dir='ltr'].ltr\:-m-224 {
  margin: -22.4rem
}

[dir='ltr'] .ltr\:-m-256,[dir='ltr'].ltr\:-m-256 {
  margin: -25.6rem
}

[dir='ltr'] .ltr\:-m-288,[dir='ltr'].ltr\:-m-288 {
  margin: -28.8rem
}

[dir='ltr'] .ltr\:-m-320,[dir='ltr'].ltr\:-m-320 {
  margin: -32rem
}

[dir='ltr'] .ltr\:-m-360,[dir='ltr'].ltr\:-m-360 {
  margin: -36rem
}

[dir='ltr'] .ltr\:-m-384,[dir='ltr'].ltr\:-m-384 {
  margin: -38.4rem
}

[dir='ltr'] .ltr\:-m-400,[dir='ltr'].ltr\:-m-400 {
  margin: -40rem
}

[dir='ltr'] .ltr\:-m-512,[dir='ltr'].ltr\:-m-512 {
  margin: -51.2rem
}

[dir='ltr'] .ltr\:-m-640,[dir='ltr'].ltr\:-m-640 {
  margin: -64rem
}

[dir='ltr'] .ltr\:-m-xs,[dir='ltr'].ltr\:-m-xs {
  margin: -32rem
}

[dir='ltr'] .ltr\:-m-sm,[dir='ltr'].ltr\:-m-sm {
  margin: -48rem
}

[dir='ltr'] .ltr\:-m-md,[dir='ltr'].ltr\:-m-md {
  margin: -64rem
}

[dir='ltr'] .ltr\:-m-lg,[dir='ltr'].ltr\:-m-lg {
  margin: -80rem
}

[dir='ltr'] .ltr\:-m-xl,[dir='ltr'].ltr\:-m-xl {
  margin: -96rem
}

[dir='ltr'] .ltr\:-m-2xl,[dir='ltr'].ltr\:-m-2xl {
  margin: -112rem
}

[dir='ltr'] .ltr\:-m-3xl,[dir='ltr'].ltr\:-m-3xl {
  margin: -128rem
}

[dir='ltr'] .ltr\:-m-4xl,[dir='ltr'].ltr\:-m-4xl {
  margin: -144rem
}

[dir='ltr'] .ltr\:-m-5xl,[dir='ltr'].ltr\:-m-5xl {
  margin: -160rem
}

[dir='ltr'] .ltr\:-m-px,[dir='ltr'].ltr\:-m-px {
  margin: -1px
}

[dir='ltr'] .ltr\:-m-0\.5,[dir='ltr'].ltr\:-m-0\.5 {
  margin: -0.05rem
}

[dir='ltr'] .ltr\:-m-1\.5,[dir='ltr'].ltr\:-m-1\.5 {
  margin: -0.15rem
}

[dir='ltr'] .ltr\:-m-2\.5,[dir='ltr'].ltr\:-m-2\.5 {
  margin: -0.25rem
}

[dir='ltr'] .ltr\:-m-3\.5,[dir='ltr'].ltr\:-m-3\.5 {
  margin: -0.35rem
}

[dir='ltr'] .ltr\:my-0,[dir='ltr'].ltr\:my-0 {
  margin-top: 0;
  margin-bottom: 0
}

[dir='ltr'] .ltr\:mx-0,[dir='ltr'].ltr\:mx-0 {
  margin-left: 0;
  margin-right: 0
}

[dir='ltr'] .ltr\:my-1,[dir='ltr'].ltr\:my-1 {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem
}

[dir='ltr'] .ltr\:mx-1,[dir='ltr'].ltr\:mx-1 {
  margin-left: 0.1rem;
  margin-right: 0.1rem
}

[dir='ltr'] .ltr\:my-2,[dir='ltr'].ltr\:my-2 {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem
}

[dir='ltr'] .ltr\:mx-2,[dir='ltr'].ltr\:mx-2 {
  margin-left: 0.2rem;
  margin-right: 0.2rem
}

[dir='ltr'] .ltr\:my-3,[dir='ltr'].ltr\:my-3 {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem
}

[dir='ltr'] .ltr\:mx-3,[dir='ltr'].ltr\:mx-3 {
  margin-left: 0.3rem;
  margin-right: 0.3rem
}

[dir='ltr'] .ltr\:my-4,[dir='ltr'].ltr\:my-4 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem
}

[dir='ltr'] .ltr\:mx-4,[dir='ltr'].ltr\:mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

[dir='ltr'] .ltr\:my-5,[dir='ltr'].ltr\:my-5 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

[dir='ltr'] .ltr\:mx-5,[dir='ltr'].ltr\:mx-5 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

[dir='ltr'] .ltr\:my-6,[dir='ltr'].ltr\:my-6 {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem
}

[dir='ltr'] .ltr\:mx-6,[dir='ltr'].ltr\:mx-6 {
  margin-left: 0.6rem;
  margin-right: 0.6rem
}

[dir='ltr'] .ltr\:my-7,[dir='ltr'].ltr\:my-7 {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem
}

[dir='ltr'] .ltr\:mx-7,[dir='ltr'].ltr\:mx-7 {
  margin-left: 0.7rem;
  margin-right: 0.7rem
}

[dir='ltr'] .ltr\:my-8,[dir='ltr'].ltr\:my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

[dir='ltr'] .ltr\:mx-8,[dir='ltr'].ltr\:mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

[dir='ltr'] .ltr\:my-9,[dir='ltr'].ltr\:my-9 {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem
}

[dir='ltr'] .ltr\:mx-9,[dir='ltr'].ltr\:mx-9 {
  margin-left: 0.9rem;
  margin-right: 0.9rem
}

[dir='ltr'] .ltr\:my-10,[dir='ltr'].ltr\:my-10 {
  margin-top: 1.0rem;
  margin-bottom: 1.0rem
}

[dir='ltr'] .ltr\:mx-10,[dir='ltr'].ltr\:mx-10 {
  margin-left: 1.0rem;
  margin-right: 1.0rem
}

[dir='ltr'] .ltr\:my-12,[dir='ltr'].ltr\:my-12 {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem
}

[dir='ltr'] .ltr\:mx-12,[dir='ltr'].ltr\:mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

[dir='ltr'] .ltr\:my-14,[dir='ltr'].ltr\:my-14 {
  margin-top: 1.4rem;
  margin-bottom: 1.4rem
}

[dir='ltr'] .ltr\:mx-14,[dir='ltr'].ltr\:mx-14 {
  margin-left: 1.4rem;
  margin-right: 1.4rem
}

[dir='ltr'] .ltr\:my-16,[dir='ltr'].ltr\:my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

[dir='ltr'] .ltr\:mx-16,[dir='ltr'].ltr\:mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

[dir='ltr'] .ltr\:my-20,[dir='ltr'].ltr\:my-20 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

[dir='ltr'] .ltr\:mx-20,[dir='ltr'].ltr\:mx-20 {
  margin-left: 2rem;
  margin-right: 2rem
}

[dir='ltr'] .ltr\:my-24,[dir='ltr'].ltr\:my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem
}

[dir='ltr'] .ltr\:mx-24,[dir='ltr'].ltr\:mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem
}

[dir='ltr'] .ltr\:my-28,[dir='ltr'].ltr\:my-28 {
  margin-top: 2.8rem;
  margin-bottom: 2.8rem
}

[dir='ltr'] .ltr\:mx-28,[dir='ltr'].ltr\:mx-28 {
  margin-left: 2.8rem;
  margin-right: 2.8rem
}

[dir='ltr'] .ltr\:my-32,[dir='ltr'].ltr\:my-32 {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem
}

[dir='ltr'] .ltr\:mx-32,[dir='ltr'].ltr\:mx-32 {
  margin-left: 3.2rem;
  margin-right: 3.2rem
}

[dir='ltr'] .ltr\:my-36,[dir='ltr'].ltr\:my-36 {
  margin-top: 3.6rem;
  margin-bottom: 3.6rem
}

[dir='ltr'] .ltr\:mx-36,[dir='ltr'].ltr\:mx-36 {
  margin-left: 3.6rem;
  margin-right: 3.6rem
}

[dir='ltr'] .ltr\:my-40,[dir='ltr'].ltr\:my-40 {
  margin-top: 4rem;
  margin-bottom: 4rem
}

[dir='ltr'] .ltr\:mx-40,[dir='ltr'].ltr\:mx-40 {
  margin-left: 4rem;
  margin-right: 4rem
}

[dir='ltr'] .ltr\:my-44,[dir='ltr'].ltr\:my-44 {
  margin-top: 4.4rem;
  margin-bottom: 4.4rem
}

[dir='ltr'] .ltr\:mx-44,[dir='ltr'].ltr\:mx-44 {
  margin-left: 4.4rem;
  margin-right: 4.4rem
}

[dir='ltr'] .ltr\:my-48,[dir='ltr'].ltr\:my-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem
}

[dir='ltr'] .ltr\:mx-48,[dir='ltr'].ltr\:mx-48 {
  margin-left: 4.8rem;
  margin-right: 4.8rem
}

[dir='ltr'] .ltr\:my-52,[dir='ltr'].ltr\:my-52 {
  margin-top: 5.2rem;
  margin-bottom: 5.2rem
}

[dir='ltr'] .ltr\:mx-52,[dir='ltr'].ltr\:mx-52 {
  margin-left: 5.2rem;
  margin-right: 5.2rem
}

[dir='ltr'] .ltr\:my-56,[dir='ltr'].ltr\:my-56 {
  margin-top: 5.6rem;
  margin-bottom: 5.6rem
}

[dir='ltr'] .ltr\:mx-56,[dir='ltr'].ltr\:mx-56 {
  margin-left: 5.6rem;
  margin-right: 5.6rem
}

[dir='ltr'] .ltr\:my-60,[dir='ltr'].ltr\:my-60 {
  margin-top: 6rem;
  margin-bottom: 6rem
}

[dir='ltr'] .ltr\:mx-60,[dir='ltr'].ltr\:mx-60 {
  margin-left: 6rem;
  margin-right: 6rem
}

[dir='ltr'] .ltr\:my-64,[dir='ltr'].ltr\:my-64 {
  margin-top: 6.4rem;
  margin-bottom: 6.4rem
}

[dir='ltr'] .ltr\:mx-64,[dir='ltr'].ltr\:mx-64 {
  margin-left: 6.4rem;
  margin-right: 6.4rem
}

[dir='ltr'] .ltr\:my-68,[dir='ltr'].ltr\:my-68 {
  margin-top: 6.8rem;
  margin-bottom: 6.8rem
}

[dir='ltr'] .ltr\:mx-68,[dir='ltr'].ltr\:mx-68 {
  margin-left: 6.8rem;
  margin-right: 6.8rem
}

[dir='ltr'] .ltr\:my-72,[dir='ltr'].ltr\:my-72 {
  margin-top: 7.2rem;
  margin-bottom: 7.2rem
}

[dir='ltr'] .ltr\:mx-72,[dir='ltr'].ltr\:mx-72 {
  margin-left: 7.2rem;
  margin-right: 7.2rem
}

[dir='ltr'] .ltr\:my-76,[dir='ltr'].ltr\:my-76 {
  margin-top: 7.6rem;
  margin-bottom: 7.6rem
}

[dir='ltr'] .ltr\:mx-76,[dir='ltr'].ltr\:mx-76 {
  margin-left: 7.6rem;
  margin-right: 7.6rem
}

[dir='ltr'] .ltr\:my-80,[dir='ltr'].ltr\:my-80 {
  margin-top: 8rem;
  margin-bottom: 8rem
}

[dir='ltr'] .ltr\:mx-80,[dir='ltr'].ltr\:mx-80 {
  margin-left: 8rem;
  margin-right: 8rem
}

[dir='ltr'] .ltr\:my-84,[dir='ltr'].ltr\:my-84 {
  margin-top: 8.4rem;
  margin-bottom: 8.4rem
}

[dir='ltr'] .ltr\:mx-84,[dir='ltr'].ltr\:mx-84 {
  margin-left: 8.4rem;
  margin-right: 8.4rem
}

[dir='ltr'] .ltr\:my-88,[dir='ltr'].ltr\:my-88 {
  margin-top: 8.8rem;
  margin-bottom: 8.8rem
}

[dir='ltr'] .ltr\:mx-88,[dir='ltr'].ltr\:mx-88 {
  margin-left: 8.8rem;
  margin-right: 8.8rem
}

[dir='ltr'] .ltr\:my-92,[dir='ltr'].ltr\:my-92 {
  margin-top: 9.2rem;
  margin-bottom: 9.2rem
}

[dir='ltr'] .ltr\:mx-92,[dir='ltr'].ltr\:mx-92 {
  margin-left: 9.2rem;
  margin-right: 9.2rem
}

[dir='ltr'] .ltr\:my-96,[dir='ltr'].ltr\:my-96 {
  margin-top: 9.6rem;
  margin-bottom: 9.6rem
}

[dir='ltr'] .ltr\:mx-96,[dir='ltr'].ltr\:mx-96 {
  margin-left: 9.6rem;
  margin-right: 9.6rem
}

[dir='ltr'] .ltr\:my-128,[dir='ltr'].ltr\:my-128 {
  margin-top: 12.8rem;
  margin-bottom: 12.8rem
}

[dir='ltr'] .ltr\:mx-128,[dir='ltr'].ltr\:mx-128 {
  margin-left: 12.8rem;
  margin-right: 12.8rem
}

[dir='ltr'] .ltr\:my-136,[dir='ltr'].ltr\:my-136 {
  margin-top: 13.6rem;
  margin-bottom: 13.6rem
}

[dir='ltr'] .ltr\:mx-136,[dir='ltr'].ltr\:mx-136 {
  margin-left: 13.6rem;
  margin-right: 13.6rem
}

[dir='ltr'] .ltr\:my-160,[dir='ltr'].ltr\:my-160 {
  margin-top: 16rem;
  margin-bottom: 16rem
}

[dir='ltr'] .ltr\:mx-160,[dir='ltr'].ltr\:mx-160 {
  margin-left: 16rem;
  margin-right: 16rem
}

[dir='ltr'] .ltr\:my-192,[dir='ltr'].ltr\:my-192 {
  margin-top: 19.2rem;
  margin-bottom: 19.2rem
}

[dir='ltr'] .ltr\:mx-192,[dir='ltr'].ltr\:mx-192 {
  margin-left: 19.2rem;
  margin-right: 19.2rem
}

[dir='ltr'] .ltr\:my-200,[dir='ltr'].ltr\:my-200 {
  margin-top: 20rem;
  margin-bottom: 20rem
}

[dir='ltr'] .ltr\:mx-200,[dir='ltr'].ltr\:mx-200 {
  margin-left: 20rem;
  margin-right: 20rem
}

[dir='ltr'] .ltr\:my-208,[dir='ltr'].ltr\:my-208 {
  margin-top: 20.8rem;
  margin-bottom: 20.8rem
}

[dir='ltr'] .ltr\:mx-208,[dir='ltr'].ltr\:mx-208 {
  margin-left: 20.8rem;
  margin-right: 20.8rem
}

[dir='ltr'] .ltr\:my-216,[dir='ltr'].ltr\:my-216 {
  margin-top: 21.6rem;
  margin-bottom: 21.6rem
}

[dir='ltr'] .ltr\:mx-216,[dir='ltr'].ltr\:mx-216 {
  margin-left: 21.6rem;
  margin-right: 21.6rem
}

[dir='ltr'] .ltr\:my-224,[dir='ltr'].ltr\:my-224 {
  margin-top: 22.4rem;
  margin-bottom: 22.4rem
}

[dir='ltr'] .ltr\:mx-224,[dir='ltr'].ltr\:mx-224 {
  margin-left: 22.4rem;
  margin-right: 22.4rem
}

[dir='ltr'] .ltr\:my-256,[dir='ltr'].ltr\:my-256 {
  margin-top: 25.6rem;
  margin-bottom: 25.6rem
}

[dir='ltr'] .ltr\:mx-256,[dir='ltr'].ltr\:mx-256 {
  margin-left: 25.6rem;
  margin-right: 25.6rem
}

[dir='ltr'] .ltr\:my-288,[dir='ltr'].ltr\:my-288 {
  margin-top: 28.8rem;
  margin-bottom: 28.8rem
}

[dir='ltr'] .ltr\:mx-288,[dir='ltr'].ltr\:mx-288 {
  margin-left: 28.8rem;
  margin-right: 28.8rem
}

[dir='ltr'] .ltr\:my-320,[dir='ltr'].ltr\:my-320 {
  margin-top: 32rem;
  margin-bottom: 32rem
}

[dir='ltr'] .ltr\:mx-320,[dir='ltr'].ltr\:mx-320 {
  margin-left: 32rem;
  margin-right: 32rem
}

[dir='ltr'] .ltr\:my-360,[dir='ltr'].ltr\:my-360 {
  margin-top: 36rem;
  margin-bottom: 36rem
}

[dir='ltr'] .ltr\:mx-360,[dir='ltr'].ltr\:mx-360 {
  margin-left: 36rem;
  margin-right: 36rem
}

[dir='ltr'] .ltr\:my-384,[dir='ltr'].ltr\:my-384 {
  margin-top: 38.4rem;
  margin-bottom: 38.4rem
}

[dir='ltr'] .ltr\:mx-384,[dir='ltr'].ltr\:mx-384 {
  margin-left: 38.4rem;
  margin-right: 38.4rem
}

[dir='ltr'] .ltr\:my-400,[dir='ltr'].ltr\:my-400 {
  margin-top: 40rem;
  margin-bottom: 40rem
}

[dir='ltr'] .ltr\:mx-400,[dir='ltr'].ltr\:mx-400 {
  margin-left: 40rem;
  margin-right: 40rem
}

[dir='ltr'] .ltr\:my-512,[dir='ltr'].ltr\:my-512 {
  margin-top: 51.2rem;
  margin-bottom: 51.2rem
}

[dir='ltr'] .ltr\:mx-512,[dir='ltr'].ltr\:mx-512 {
  margin-left: 51.2rem;
  margin-right: 51.2rem
}

[dir='ltr'] .ltr\:my-640,[dir='ltr'].ltr\:my-640 {
  margin-top: 64rem;
  margin-bottom: 64rem
}

[dir='ltr'] .ltr\:mx-640,[dir='ltr'].ltr\:mx-640 {
  margin-left: 64rem;
  margin-right: 64rem
}

[dir='ltr'] .ltr\:my-auto,[dir='ltr'].ltr\:my-auto {
  margin-top: auto;
  margin-bottom: auto
}

[dir='ltr'] .ltr\:mx-auto,[dir='ltr'].ltr\:mx-auto {
  margin-left: auto;
  margin-right: auto
}

[dir='ltr'] .ltr\:my-xs,[dir='ltr'].ltr\:my-xs {
  margin-top: 32rem;
  margin-bottom: 32rem
}

[dir='ltr'] .ltr\:mx-xs,[dir='ltr'].ltr\:mx-xs {
  margin-left: 32rem;
  margin-right: 32rem
}

[dir='ltr'] .ltr\:my-sm,[dir='ltr'].ltr\:my-sm {
  margin-top: 48rem;
  margin-bottom: 48rem
}

[dir='ltr'] .ltr\:mx-sm,[dir='ltr'].ltr\:mx-sm {
  margin-left: 48rem;
  margin-right: 48rem
}

[dir='ltr'] .ltr\:my-md,[dir='ltr'].ltr\:my-md {
  margin-top: 64rem;
  margin-bottom: 64rem
}

[dir='ltr'] .ltr\:mx-md,[dir='ltr'].ltr\:mx-md {
  margin-left: 64rem;
  margin-right: 64rem
}

[dir='ltr'] .ltr\:my-lg,[dir='ltr'].ltr\:my-lg {
  margin-top: 80rem;
  margin-bottom: 80rem
}

[dir='ltr'] .ltr\:mx-lg,[dir='ltr'].ltr\:mx-lg {
  margin-left: 80rem;
  margin-right: 80rem
}

[dir='ltr'] .ltr\:my-xl,[dir='ltr'].ltr\:my-xl {
  margin-top: 96rem;
  margin-bottom: 96rem
}

[dir='ltr'] .ltr\:mx-xl,[dir='ltr'].ltr\:mx-xl {
  margin-left: 96rem;
  margin-right: 96rem
}

[dir='ltr'] .ltr\:my-2xl,[dir='ltr'].ltr\:my-2xl {
  margin-top: 112rem;
  margin-bottom: 112rem
}

[dir='ltr'] .ltr\:mx-2xl,[dir='ltr'].ltr\:mx-2xl {
  margin-left: 112rem;
  margin-right: 112rem
}

[dir='ltr'] .ltr\:my-3xl,[dir='ltr'].ltr\:my-3xl {
  margin-top: 128rem;
  margin-bottom: 128rem
}

[dir='ltr'] .ltr\:mx-3xl,[dir='ltr'].ltr\:mx-3xl {
  margin-left: 128rem;
  margin-right: 128rem
}

[dir='ltr'] .ltr\:my-4xl,[dir='ltr'].ltr\:my-4xl {
  margin-top: 144rem;
  margin-bottom: 144rem
}

[dir='ltr'] .ltr\:mx-4xl,[dir='ltr'].ltr\:mx-4xl {
  margin-left: 144rem;
  margin-right: 144rem
}

[dir='ltr'] .ltr\:my-5xl,[dir='ltr'].ltr\:my-5xl {
  margin-top: 160rem;
  margin-bottom: 160rem
}

[dir='ltr'] .ltr\:mx-5xl,[dir='ltr'].ltr\:mx-5xl {
  margin-left: 160rem;
  margin-right: 160rem
}

[dir='ltr'] .ltr\:my-px,[dir='ltr'].ltr\:my-px {
  margin-top: 1px;
  margin-bottom: 1px
}

[dir='ltr'] .ltr\:mx-px,[dir='ltr'].ltr\:mx-px {
  margin-left: 1px;
  margin-right: 1px
}

[dir='ltr'] .ltr\:my-0\.5,[dir='ltr'].ltr\:my-0\.5 {
  margin-top: 0.05rem;
  margin-bottom: 0.05rem
}

[dir='ltr'] .ltr\:mx-0\.5,[dir='ltr'].ltr\:mx-0\.5 {
  margin-left: 0.05rem;
  margin-right: 0.05rem
}

[dir='ltr'] .ltr\:my-1\.5,[dir='ltr'].ltr\:my-1\.5 {
  margin-top: 0.15rem;
  margin-bottom: 0.15rem
}

[dir='ltr'] .ltr\:mx-1\.5,[dir='ltr'].ltr\:mx-1\.5 {
  margin-left: 0.15rem;
  margin-right: 0.15rem
}

[dir='ltr'] .ltr\:my-2\.5,[dir='ltr'].ltr\:my-2\.5 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

[dir='ltr'] .ltr\:mx-2\.5,[dir='ltr'].ltr\:mx-2\.5 {
  margin-left: 0.25rem;
  margin-right: 0.25rem
}

[dir='ltr'] .ltr\:my-3\.5,[dir='ltr'].ltr\:my-3\.5 {
  margin-top: 0.35rem;
  margin-bottom: 0.35rem
}

[dir='ltr'] .ltr\:mx-3\.5,[dir='ltr'].ltr\:mx-3\.5 {
  margin-left: 0.35rem;
  margin-right: 0.35rem
}

[dir='ltr'] .ltr\:-my-1,[dir='ltr'].ltr\:-my-1 {
  margin-top: -0.1rem;
  margin-bottom: -0.1rem
}

[dir='ltr'] .ltr\:-mx-1,[dir='ltr'].ltr\:-mx-1 {
  margin-left: -0.1rem;
  margin-right: -0.1rem
}

[dir='ltr'] .ltr\:-my-2,[dir='ltr'].ltr\:-my-2 {
  margin-top: -0.2rem;
  margin-bottom: -0.2rem
}

[dir='ltr'] .ltr\:-mx-2,[dir='ltr'].ltr\:-mx-2 {
  margin-left: -0.2rem;
  margin-right: -0.2rem
}

[dir='ltr'] .ltr\:-my-3,[dir='ltr'].ltr\:-my-3 {
  margin-top: -0.3rem;
  margin-bottom: -0.3rem
}

[dir='ltr'] .ltr\:-mx-3,[dir='ltr'].ltr\:-mx-3 {
  margin-left: -0.3rem;
  margin-right: -0.3rem
}

[dir='ltr'] .ltr\:-my-4,[dir='ltr'].ltr\:-my-4 {
  margin-top: -0.4rem;
  margin-bottom: -0.4rem
}

[dir='ltr'] .ltr\:-mx-4,[dir='ltr'].ltr\:-mx-4 {
  margin-left: -0.4rem;
  margin-right: -0.4rem
}

[dir='ltr'] .ltr\:-my-5,[dir='ltr'].ltr\:-my-5 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem
}

[dir='ltr'] .ltr\:-mx-5,[dir='ltr'].ltr\:-mx-5 {
  margin-left: -0.5rem;
  margin-right: -0.5rem
}

[dir='ltr'] .ltr\:-my-6,[dir='ltr'].ltr\:-my-6 {
  margin-top: -0.6rem;
  margin-bottom: -0.6rem
}

[dir='ltr'] .ltr\:-mx-6,[dir='ltr'].ltr\:-mx-6 {
  margin-left: -0.6rem;
  margin-right: -0.6rem
}

[dir='ltr'] .ltr\:-my-7,[dir='ltr'].ltr\:-my-7 {
  margin-top: -0.7rem;
  margin-bottom: -0.7rem
}

[dir='ltr'] .ltr\:-mx-7,[dir='ltr'].ltr\:-mx-7 {
  margin-left: -0.7rem;
  margin-right: -0.7rem
}

[dir='ltr'] .ltr\:-my-8,[dir='ltr'].ltr\:-my-8 {
  margin-top: -0.8rem;
  margin-bottom: -0.8rem
}

[dir='ltr'] .ltr\:-mx-8,[dir='ltr'].ltr\:-mx-8 {
  margin-left: -0.8rem;
  margin-right: -0.8rem
}

[dir='ltr'] .ltr\:-my-9,[dir='ltr'].ltr\:-my-9 {
  margin-top: -0.9rem;
  margin-bottom: -0.9rem
}

[dir='ltr'] .ltr\:-mx-9,[dir='ltr'].ltr\:-mx-9 {
  margin-left: -0.9rem;
  margin-right: -0.9rem
}

[dir='ltr'] .ltr\:-my-10,[dir='ltr'].ltr\:-my-10 {
  margin-top: -1rem;
  margin-bottom: -1rem
}

[dir='ltr'] .ltr\:-mx-10,[dir='ltr'].ltr\:-mx-10 {
  margin-left: -1rem;
  margin-right: -1rem
}

[dir='ltr'] .ltr\:-my-12,[dir='ltr'].ltr\:-my-12 {
  margin-top: -1.2rem;
  margin-bottom: -1.2rem
}

[dir='ltr'] .ltr\:-mx-12,[dir='ltr'].ltr\:-mx-12 {
  margin-left: -1.2rem;
  margin-right: -1.2rem
}

[dir='ltr'] .ltr\:-my-14,[dir='ltr'].ltr\:-my-14 {
  margin-top: -1.4rem;
  margin-bottom: -1.4rem
}

[dir='ltr'] .ltr\:-mx-14,[dir='ltr'].ltr\:-mx-14 {
  margin-left: -1.4rem;
  margin-right: -1.4rem
}

[dir='ltr'] .ltr\:-my-16,[dir='ltr'].ltr\:-my-16 {
  margin-top: -1.6rem;
  margin-bottom: -1.6rem
}

[dir='ltr'] .ltr\:-mx-16,[dir='ltr'].ltr\:-mx-16 {
  margin-left: -1.6rem;
  margin-right: -1.6rem
}

[dir='ltr'] .ltr\:-my-20,[dir='ltr'].ltr\:-my-20 {
  margin-top: -2rem;
  margin-bottom: -2rem
}

[dir='ltr'] .ltr\:-mx-20,[dir='ltr'].ltr\:-mx-20 {
  margin-left: -2rem;
  margin-right: -2rem
}

[dir='ltr'] .ltr\:-my-24,[dir='ltr'].ltr\:-my-24 {
  margin-top: -2.4rem;
  margin-bottom: -2.4rem
}

[dir='ltr'] .ltr\:-mx-24,[dir='ltr'].ltr\:-mx-24 {
  margin-left: -2.4rem;
  margin-right: -2.4rem
}

[dir='ltr'] .ltr\:-my-28,[dir='ltr'].ltr\:-my-28 {
  margin-top: -2.8rem;
  margin-bottom: -2.8rem
}

[dir='ltr'] .ltr\:-mx-28,[dir='ltr'].ltr\:-mx-28 {
  margin-left: -2.8rem;
  margin-right: -2.8rem
}

[dir='ltr'] .ltr\:-my-32,[dir='ltr'].ltr\:-my-32 {
  margin-top: -3.2rem;
  margin-bottom: -3.2rem
}

[dir='ltr'] .ltr\:-mx-32,[dir='ltr'].ltr\:-mx-32 {
  margin-left: -3.2rem;
  margin-right: -3.2rem
}

[dir='ltr'] .ltr\:-my-36,[dir='ltr'].ltr\:-my-36 {
  margin-top: -3.6rem;
  margin-bottom: -3.6rem
}

[dir='ltr'] .ltr\:-mx-36,[dir='ltr'].ltr\:-mx-36 {
  margin-left: -3.6rem;
  margin-right: -3.6rem
}

[dir='ltr'] .ltr\:-my-40,[dir='ltr'].ltr\:-my-40 {
  margin-top: -4rem;
  margin-bottom: -4rem
}

[dir='ltr'] .ltr\:-mx-40,[dir='ltr'].ltr\:-mx-40 {
  margin-left: -4rem;
  margin-right: -4rem
}

[dir='ltr'] .ltr\:-my-44,[dir='ltr'].ltr\:-my-44 {
  margin-top: -4.4rem;
  margin-bottom: -4.4rem
}

[dir='ltr'] .ltr\:-mx-44,[dir='ltr'].ltr\:-mx-44 {
  margin-left: -4.4rem;
  margin-right: -4.4rem
}

[dir='ltr'] .ltr\:-my-48,[dir='ltr'].ltr\:-my-48 {
  margin-top: -4.8rem;
  margin-bottom: -4.8rem
}

[dir='ltr'] .ltr\:-mx-48,[dir='ltr'].ltr\:-mx-48 {
  margin-left: -4.8rem;
  margin-right: -4.8rem
}

[dir='ltr'] .ltr\:-my-52,[dir='ltr'].ltr\:-my-52 {
  margin-top: -5.2rem;
  margin-bottom: -5.2rem
}

[dir='ltr'] .ltr\:-mx-52,[dir='ltr'].ltr\:-mx-52 {
  margin-left: -5.2rem;
  margin-right: -5.2rem
}

[dir='ltr'] .ltr\:-my-56,[dir='ltr'].ltr\:-my-56 {
  margin-top: -5.6rem;
  margin-bottom: -5.6rem
}

[dir='ltr'] .ltr\:-mx-56,[dir='ltr'].ltr\:-mx-56 {
  margin-left: -5.6rem;
  margin-right: -5.6rem
}

[dir='ltr'] .ltr\:-my-60,[dir='ltr'].ltr\:-my-60 {
  margin-top: -6rem;
  margin-bottom: -6rem
}

[dir='ltr'] .ltr\:-mx-60,[dir='ltr'].ltr\:-mx-60 {
  margin-left: -6rem;
  margin-right: -6rem
}

[dir='ltr'] .ltr\:-my-64,[dir='ltr'].ltr\:-my-64 {
  margin-top: -6.4rem;
  margin-bottom: -6.4rem
}

[dir='ltr'] .ltr\:-mx-64,[dir='ltr'].ltr\:-mx-64 {
  margin-left: -6.4rem;
  margin-right: -6.4rem
}

[dir='ltr'] .ltr\:-my-68,[dir='ltr'].ltr\:-my-68 {
  margin-top: -6.8rem;
  margin-bottom: -6.8rem
}

[dir='ltr'] .ltr\:-mx-68,[dir='ltr'].ltr\:-mx-68 {
  margin-left: -6.8rem;
  margin-right: -6.8rem
}

[dir='ltr'] .ltr\:-my-72,[dir='ltr'].ltr\:-my-72 {
  margin-top: -7.2rem;
  margin-bottom: -7.2rem
}

[dir='ltr'] .ltr\:-mx-72,[dir='ltr'].ltr\:-mx-72 {
  margin-left: -7.2rem;
  margin-right: -7.2rem
}

[dir='ltr'] .ltr\:-my-76,[dir='ltr'].ltr\:-my-76 {
  margin-top: -7.6rem;
  margin-bottom: -7.6rem
}

[dir='ltr'] .ltr\:-mx-76,[dir='ltr'].ltr\:-mx-76 {
  margin-left: -7.6rem;
  margin-right: -7.6rem
}

[dir='ltr'] .ltr\:-my-80,[dir='ltr'].ltr\:-my-80 {
  margin-top: -8rem;
  margin-bottom: -8rem
}

[dir='ltr'] .ltr\:-mx-80,[dir='ltr'].ltr\:-mx-80 {
  margin-left: -8rem;
  margin-right: -8rem
}

[dir='ltr'] .ltr\:-my-84,[dir='ltr'].ltr\:-my-84 {
  margin-top: -8.4rem;
  margin-bottom: -8.4rem
}

[dir='ltr'] .ltr\:-mx-84,[dir='ltr'].ltr\:-mx-84 {
  margin-left: -8.4rem;
  margin-right: -8.4rem
}

[dir='ltr'] .ltr\:-my-88,[dir='ltr'].ltr\:-my-88 {
  margin-top: -8.8rem;
  margin-bottom: -8.8rem
}

[dir='ltr'] .ltr\:-mx-88,[dir='ltr'].ltr\:-mx-88 {
  margin-left: -8.8rem;
  margin-right: -8.8rem
}

[dir='ltr'] .ltr\:-my-92,[dir='ltr'].ltr\:-my-92 {
  margin-top: -9.2rem;
  margin-bottom: -9.2rem
}

[dir='ltr'] .ltr\:-mx-92,[dir='ltr'].ltr\:-mx-92 {
  margin-left: -9.2rem;
  margin-right: -9.2rem
}

[dir='ltr'] .ltr\:-my-96,[dir='ltr'].ltr\:-my-96 {
  margin-top: -9.6rem;
  margin-bottom: -9.6rem
}

[dir='ltr'] .ltr\:-mx-96,[dir='ltr'].ltr\:-mx-96 {
  margin-left: -9.6rem;
  margin-right: -9.6rem
}

[dir='ltr'] .ltr\:-my-128,[dir='ltr'].ltr\:-my-128 {
  margin-top: -12.8rem;
  margin-bottom: -12.8rem
}

[dir='ltr'] .ltr\:-mx-128,[dir='ltr'].ltr\:-mx-128 {
  margin-left: -12.8rem;
  margin-right: -12.8rem
}

[dir='ltr'] .ltr\:-my-136,[dir='ltr'].ltr\:-my-136 {
  margin-top: -13.6rem;
  margin-bottom: -13.6rem
}

[dir='ltr'] .ltr\:-mx-136,[dir='ltr'].ltr\:-mx-136 {
  margin-left: -13.6rem;
  margin-right: -13.6rem
}

[dir='ltr'] .ltr\:-my-160,[dir='ltr'].ltr\:-my-160 {
  margin-top: -16rem;
  margin-bottom: -16rem
}

[dir='ltr'] .ltr\:-mx-160,[dir='ltr'].ltr\:-mx-160 {
  margin-left: -16rem;
  margin-right: -16rem
}

[dir='ltr'] .ltr\:-my-192,[dir='ltr'].ltr\:-my-192 {
  margin-top: -19.2rem;
  margin-bottom: -19.2rem
}

[dir='ltr'] .ltr\:-mx-192,[dir='ltr'].ltr\:-mx-192 {
  margin-left: -19.2rem;
  margin-right: -19.2rem
}

[dir='ltr'] .ltr\:-my-200,[dir='ltr'].ltr\:-my-200 {
  margin-top: -20rem;
  margin-bottom: -20rem
}

[dir='ltr'] .ltr\:-mx-200,[dir='ltr'].ltr\:-mx-200 {
  margin-left: -20rem;
  margin-right: -20rem
}

[dir='ltr'] .ltr\:-my-208,[dir='ltr'].ltr\:-my-208 {
  margin-top: -20.8rem;
  margin-bottom: -20.8rem
}

[dir='ltr'] .ltr\:-mx-208,[dir='ltr'].ltr\:-mx-208 {
  margin-left: -20.8rem;
  margin-right: -20.8rem
}

[dir='ltr'] .ltr\:-my-216,[dir='ltr'].ltr\:-my-216 {
  margin-top: -21.6rem;
  margin-bottom: -21.6rem
}

[dir='ltr'] .ltr\:-mx-216,[dir='ltr'].ltr\:-mx-216 {
  margin-left: -21.6rem;
  margin-right: -21.6rem
}

[dir='ltr'] .ltr\:-my-224,[dir='ltr'].ltr\:-my-224 {
  margin-top: -22.4rem;
  margin-bottom: -22.4rem
}

[dir='ltr'] .ltr\:-mx-224,[dir='ltr'].ltr\:-mx-224 {
  margin-left: -22.4rem;
  margin-right: -22.4rem
}

[dir='ltr'] .ltr\:-my-256,[dir='ltr'].ltr\:-my-256 {
  margin-top: -25.6rem;
  margin-bottom: -25.6rem
}

[dir='ltr'] .ltr\:-mx-256,[dir='ltr'].ltr\:-mx-256 {
  margin-left: -25.6rem;
  margin-right: -25.6rem
}

[dir='ltr'] .ltr\:-my-288,[dir='ltr'].ltr\:-my-288 {
  margin-top: -28.8rem;
  margin-bottom: -28.8rem
}

[dir='ltr'] .ltr\:-mx-288,[dir='ltr'].ltr\:-mx-288 {
  margin-left: -28.8rem;
  margin-right: -28.8rem
}

[dir='ltr'] .ltr\:-my-320,[dir='ltr'].ltr\:-my-320 {
  margin-top: -32rem;
  margin-bottom: -32rem
}

[dir='ltr'] .ltr\:-mx-320,[dir='ltr'].ltr\:-mx-320 {
  margin-left: -32rem;
  margin-right: -32rem
}

[dir='ltr'] .ltr\:-my-360,[dir='ltr'].ltr\:-my-360 {
  margin-top: -36rem;
  margin-bottom: -36rem
}

[dir='ltr'] .ltr\:-mx-360,[dir='ltr'].ltr\:-mx-360 {
  margin-left: -36rem;
  margin-right: -36rem
}

[dir='ltr'] .ltr\:-my-384,[dir='ltr'].ltr\:-my-384 {
  margin-top: -38.4rem;
  margin-bottom: -38.4rem
}

[dir='ltr'] .ltr\:-mx-384,[dir='ltr'].ltr\:-mx-384 {
  margin-left: -38.4rem;
  margin-right: -38.4rem
}

[dir='ltr'] .ltr\:-my-400,[dir='ltr'].ltr\:-my-400 {
  margin-top: -40rem;
  margin-bottom: -40rem
}

[dir='ltr'] .ltr\:-mx-400,[dir='ltr'].ltr\:-mx-400 {
  margin-left: -40rem;
  margin-right: -40rem
}

[dir='ltr'] .ltr\:-my-512,[dir='ltr'].ltr\:-my-512 {
  margin-top: -51.2rem;
  margin-bottom: -51.2rem
}

[dir='ltr'] .ltr\:-mx-512,[dir='ltr'].ltr\:-mx-512 {
  margin-left: -51.2rem;
  margin-right: -51.2rem
}

[dir='ltr'] .ltr\:-my-640,[dir='ltr'].ltr\:-my-640 {
  margin-top: -64rem;
  margin-bottom: -64rem
}

[dir='ltr'] .ltr\:-mx-640,[dir='ltr'].ltr\:-mx-640 {
  margin-left: -64rem;
  margin-right: -64rem
}

[dir='ltr'] .ltr\:-my-xs,[dir='ltr'].ltr\:-my-xs {
  margin-top: -32rem;
  margin-bottom: -32rem
}

[dir='ltr'] .ltr\:-mx-xs,[dir='ltr'].ltr\:-mx-xs {
  margin-left: -32rem;
  margin-right: -32rem
}

[dir='ltr'] .ltr\:-my-sm,[dir='ltr'].ltr\:-my-sm {
  margin-top: -48rem;
  margin-bottom: -48rem
}

[dir='ltr'] .ltr\:-mx-sm,[dir='ltr'].ltr\:-mx-sm {
  margin-left: -48rem;
  margin-right: -48rem
}

[dir='ltr'] .ltr\:-my-md,[dir='ltr'].ltr\:-my-md {
  margin-top: -64rem;
  margin-bottom: -64rem
}

[dir='ltr'] .ltr\:-mx-md,[dir='ltr'].ltr\:-mx-md {
  margin-left: -64rem;
  margin-right: -64rem
}

[dir='ltr'] .ltr\:-my-lg,[dir='ltr'].ltr\:-my-lg {
  margin-top: -80rem;
  margin-bottom: -80rem
}

[dir='ltr'] .ltr\:-mx-lg,[dir='ltr'].ltr\:-mx-lg {
  margin-left: -80rem;
  margin-right: -80rem
}

[dir='ltr'] .ltr\:-my-xl,[dir='ltr'].ltr\:-my-xl {
  margin-top: -96rem;
  margin-bottom: -96rem
}

[dir='ltr'] .ltr\:-mx-xl,[dir='ltr'].ltr\:-mx-xl {
  margin-left: -96rem;
  margin-right: -96rem
}

[dir='ltr'] .ltr\:-my-2xl,[dir='ltr'].ltr\:-my-2xl {
  margin-top: -112rem;
  margin-bottom: -112rem
}

[dir='ltr'] .ltr\:-mx-2xl,[dir='ltr'].ltr\:-mx-2xl {
  margin-left: -112rem;
  margin-right: -112rem
}

[dir='ltr'] .ltr\:-my-3xl,[dir='ltr'].ltr\:-my-3xl {
  margin-top: -128rem;
  margin-bottom: -128rem
}

[dir='ltr'] .ltr\:-mx-3xl,[dir='ltr'].ltr\:-mx-3xl {
  margin-left: -128rem;
  margin-right: -128rem
}

[dir='ltr'] .ltr\:-my-4xl,[dir='ltr'].ltr\:-my-4xl {
  margin-top: -144rem;
  margin-bottom: -144rem
}

[dir='ltr'] .ltr\:-mx-4xl,[dir='ltr'].ltr\:-mx-4xl {
  margin-left: -144rem;
  margin-right: -144rem
}

[dir='ltr'] .ltr\:-my-5xl,[dir='ltr'].ltr\:-my-5xl {
  margin-top: -160rem;
  margin-bottom: -160rem
}

[dir='ltr'] .ltr\:-mx-5xl,[dir='ltr'].ltr\:-mx-5xl {
  margin-left: -160rem;
  margin-right: -160rem
}

[dir='ltr'] .ltr\:-my-px,[dir='ltr'].ltr\:-my-px {
  margin-top: -1px;
  margin-bottom: -1px
}

[dir='ltr'] .ltr\:-mx-px,[dir='ltr'].ltr\:-mx-px {
  margin-left: -1px;
  margin-right: -1px
}

[dir='ltr'] .ltr\:-my-0\.5,[dir='ltr'].ltr\:-my-0\.5 {
  margin-top: -0.05rem;
  margin-bottom: -0.05rem
}

[dir='ltr'] .ltr\:-mx-0\.5,[dir='ltr'].ltr\:-mx-0\.5 {
  margin-left: -0.05rem;
  margin-right: -0.05rem
}

[dir='ltr'] .ltr\:-my-1\.5,[dir='ltr'].ltr\:-my-1\.5 {
  margin-top: -0.15rem;
  margin-bottom: -0.15rem
}

[dir='ltr'] .ltr\:-mx-1\.5,[dir='ltr'].ltr\:-mx-1\.5 {
  margin-left: -0.15rem;
  margin-right: -0.15rem
}

[dir='ltr'] .ltr\:-my-2\.5,[dir='ltr'].ltr\:-my-2\.5 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem
}

[dir='ltr'] .ltr\:-mx-2\.5,[dir='ltr'].ltr\:-mx-2\.5 {
  margin-left: -0.25rem;
  margin-right: -0.25rem
}

[dir='ltr'] .ltr\:-my-3\.5,[dir='ltr'].ltr\:-my-3\.5 {
  margin-top: -0.35rem;
  margin-bottom: -0.35rem
}

[dir='ltr'] .ltr\:-mx-3\.5,[dir='ltr'].ltr\:-mx-3\.5 {
  margin-left: -0.35rem;
  margin-right: -0.35rem
}

[dir='ltr'] .ltr\:mt-0,[dir='ltr'].ltr\:mt-0 {
  margin-top: 0
}

[dir='ltr'] .ltr\:mr-0,[dir='ltr'].ltr\:mr-0 {
  margin-right: 0
}

[dir='ltr'] .ltr\:mb-0,[dir='ltr'].ltr\:mb-0 {
  margin-bottom: 0
}

[dir='ltr'] .ltr\:ml-0,[dir='ltr'].ltr\:ml-0 {
  margin-left: 0
}

[dir='ltr'] .ltr\:mt-1,[dir='ltr'].ltr\:mt-1 {
  margin-top: 0.1rem
}

[dir='ltr'] .ltr\:mr-1,[dir='ltr'].ltr\:mr-1 {
  margin-right: 0.1rem
}

[dir='ltr'] .ltr\:mb-1,[dir='ltr'].ltr\:mb-1 {
  margin-bottom: 0.1rem
}

[dir='ltr'] .ltr\:ml-1,[dir='ltr'].ltr\:ml-1 {
  margin-left: 0.1rem
}

[dir='ltr'] .ltr\:mt-2,[dir='ltr'].ltr\:mt-2 {
  margin-top: 0.2rem
}

[dir='ltr'] .ltr\:mr-2,[dir='ltr'].ltr\:mr-2 {
  margin-right: 0.2rem
}

[dir='ltr'] .ltr\:mb-2,[dir='ltr'].ltr\:mb-2 {
  margin-bottom: 0.2rem
}

[dir='ltr'] .ltr\:ml-2,[dir='ltr'].ltr\:ml-2 {
  margin-left: 0.2rem
}

[dir='ltr'] .ltr\:mt-3,[dir='ltr'].ltr\:mt-3 {
  margin-top: 0.3rem
}

[dir='ltr'] .ltr\:mr-3,[dir='ltr'].ltr\:mr-3 {
  margin-right: 0.3rem
}

[dir='ltr'] .ltr\:mb-3,[dir='ltr'].ltr\:mb-3 {
  margin-bottom: 0.3rem
}

[dir='ltr'] .ltr\:ml-3,[dir='ltr'].ltr\:ml-3 {
  margin-left: 0.3rem
}

[dir='ltr'] .ltr\:mt-4,[dir='ltr'].ltr\:mt-4 {
  margin-top: 0.4rem
}

[dir='ltr'] .ltr\:mr-4,[dir='ltr'].ltr\:mr-4 {
  margin-right: 0.4rem
}

[dir='ltr'] .ltr\:mb-4,[dir='ltr'].ltr\:mb-4 {
  margin-bottom: 0.4rem
}

[dir='ltr'] .ltr\:ml-4,[dir='ltr'].ltr\:ml-4 {
  margin-left: 0.4rem
}

[dir='ltr'] .ltr\:mt-5,[dir='ltr'].ltr\:mt-5 {
  margin-top: 0.5rem
}

[dir='ltr'] .ltr\:mr-5,[dir='ltr'].ltr\:mr-5 {
  margin-right: 0.5rem
}

[dir='ltr'] .ltr\:mb-5,[dir='ltr'].ltr\:mb-5 {
  margin-bottom: 0.5rem
}

[dir='ltr'] .ltr\:ml-5,[dir='ltr'].ltr\:ml-5 {
  margin-left: 0.5rem
}

[dir='ltr'] .ltr\:mt-6,[dir='ltr'].ltr\:mt-6 {
  margin-top: 0.6rem
}

[dir='ltr'] .ltr\:mr-6,[dir='ltr'].ltr\:mr-6 {
  margin-right: 0.6rem
}

[dir='ltr'] .ltr\:mb-6,[dir='ltr'].ltr\:mb-6 {
  margin-bottom: 0.6rem
}

[dir='ltr'] .ltr\:ml-6,[dir='ltr'].ltr\:ml-6 {
  margin-left: 0.6rem
}

[dir='ltr'] .ltr\:mt-7,[dir='ltr'].ltr\:mt-7 {
  margin-top: 0.7rem
}

[dir='ltr'] .ltr\:mr-7,[dir='ltr'].ltr\:mr-7 {
  margin-right: 0.7rem
}

[dir='ltr'] .ltr\:mb-7,[dir='ltr'].ltr\:mb-7 {
  margin-bottom: 0.7rem
}

[dir='ltr'] .ltr\:ml-7,[dir='ltr'].ltr\:ml-7 {
  margin-left: 0.7rem
}

[dir='ltr'] .ltr\:mt-8,[dir='ltr'].ltr\:mt-8 {
  margin-top: 0.8rem
}

[dir='ltr'] .ltr\:mr-8,[dir='ltr'].ltr\:mr-8 {
  margin-right: 0.8rem
}

[dir='ltr'] .ltr\:mb-8,[dir='ltr'].ltr\:mb-8 {
  margin-bottom: 0.8rem
}

[dir='ltr'] .ltr\:ml-8,[dir='ltr'].ltr\:ml-8 {
  margin-left: 0.8rem
}

[dir='ltr'] .ltr\:mt-9,[dir='ltr'].ltr\:mt-9 {
  margin-top: 0.9rem
}

[dir='ltr'] .ltr\:mr-9,[dir='ltr'].ltr\:mr-9 {
  margin-right: 0.9rem
}

[dir='ltr'] .ltr\:mb-9,[dir='ltr'].ltr\:mb-9 {
  margin-bottom: 0.9rem
}

[dir='ltr'] .ltr\:ml-9,[dir='ltr'].ltr\:ml-9 {
  margin-left: 0.9rem
}

[dir='ltr'] .ltr\:mt-10,[dir='ltr'].ltr\:mt-10 {
  margin-top: 1.0rem
}

[dir='ltr'] .ltr\:mr-10,[dir='ltr'].ltr\:mr-10 {
  margin-right: 1.0rem
}

[dir='ltr'] .ltr\:mb-10,[dir='ltr'].ltr\:mb-10 {
  margin-bottom: 1.0rem
}

[dir='ltr'] .ltr\:ml-10,[dir='ltr'].ltr\:ml-10 {
  margin-left: 1.0rem
}

[dir='ltr'] .ltr\:mt-12,[dir='ltr'].ltr\:mt-12 {
  margin-top: 1.2rem
}

[dir='ltr'] .ltr\:mr-12,[dir='ltr'].ltr\:mr-12 {
  margin-right: 1.2rem
}

[dir='ltr'] .ltr\:mb-12,[dir='ltr'].ltr\:mb-12 {
  margin-bottom: 1.2rem
}

[dir='ltr'] .ltr\:ml-12,[dir='ltr'].ltr\:ml-12 {
  margin-left: 1.2rem
}

[dir='ltr'] .ltr\:mt-14,[dir='ltr'].ltr\:mt-14 {
  margin-top: 1.4rem
}

[dir='ltr'] .ltr\:mr-14,[dir='ltr'].ltr\:mr-14 {
  margin-right: 1.4rem
}

[dir='ltr'] .ltr\:mb-14,[dir='ltr'].ltr\:mb-14 {
  margin-bottom: 1.4rem
}

[dir='ltr'] .ltr\:ml-14,[dir='ltr'].ltr\:ml-14 {
  margin-left: 1.4rem
}

[dir='ltr'] .ltr\:mt-16,[dir='ltr'].ltr\:mt-16 {
  margin-top: 1.6rem
}

[dir='ltr'] .ltr\:mr-16,[dir='ltr'].ltr\:mr-16 {
  margin-right: 1.6rem
}

[dir='ltr'] .ltr\:mb-16,[dir='ltr'].ltr\:mb-16 {
  margin-bottom: 1.6rem
}

[dir='ltr'] .ltr\:ml-16,[dir='ltr'].ltr\:ml-16 {
  margin-left: 1.6rem
}

[dir='ltr'] .ltr\:mt-20,[dir='ltr'].ltr\:mt-20 {
  margin-top: 2rem
}

[dir='ltr'] .ltr\:mr-20,[dir='ltr'].ltr\:mr-20 {
  margin-right: 2rem
}

[dir='ltr'] .ltr\:mb-20,[dir='ltr'].ltr\:mb-20 {
  margin-bottom: 2rem
}

[dir='ltr'] .ltr\:ml-20,[dir='ltr'].ltr\:ml-20 {
  margin-left: 2rem
}

[dir='ltr'] .ltr\:mt-24,[dir='ltr'].ltr\:mt-24 {
  margin-top: 2.4rem
}

[dir='ltr'] .ltr\:mr-24,[dir='ltr'].ltr\:mr-24 {
  margin-right: 2.4rem
}

[dir='ltr'] .ltr\:mb-24,[dir='ltr'].ltr\:mb-24 {
  margin-bottom: 2.4rem
}

[dir='ltr'] .ltr\:ml-24,[dir='ltr'].ltr\:ml-24 {
  margin-left: 2.4rem
}

[dir='ltr'] .ltr\:mt-28,[dir='ltr'].ltr\:mt-28 {
  margin-top: 2.8rem
}

[dir='ltr'] .ltr\:mr-28,[dir='ltr'].ltr\:mr-28 {
  margin-right: 2.8rem
}

[dir='ltr'] .ltr\:mb-28,[dir='ltr'].ltr\:mb-28 {
  margin-bottom: 2.8rem
}

[dir='ltr'] .ltr\:ml-28,[dir='ltr'].ltr\:ml-28 {
  margin-left: 2.8rem
}

[dir='ltr'] .ltr\:mt-32,[dir='ltr'].ltr\:mt-32 {
  margin-top: 3.2rem
}

[dir='ltr'] .ltr\:mr-32,[dir='ltr'].ltr\:mr-32 {
  margin-right: 3.2rem
}

[dir='ltr'] .ltr\:mb-32,[dir='ltr'].ltr\:mb-32 {
  margin-bottom: 3.2rem
}

[dir='ltr'] .ltr\:ml-32,[dir='ltr'].ltr\:ml-32 {
  margin-left: 3.2rem
}

[dir='ltr'] .ltr\:mt-36,[dir='ltr'].ltr\:mt-36 {
  margin-top: 3.6rem
}

[dir='ltr'] .ltr\:mr-36,[dir='ltr'].ltr\:mr-36 {
  margin-right: 3.6rem
}

[dir='ltr'] .ltr\:mb-36,[dir='ltr'].ltr\:mb-36 {
  margin-bottom: 3.6rem
}

[dir='ltr'] .ltr\:ml-36,[dir='ltr'].ltr\:ml-36 {
  margin-left: 3.6rem
}

[dir='ltr'] .ltr\:mt-40,[dir='ltr'].ltr\:mt-40 {
  margin-top: 4rem
}

[dir='ltr'] .ltr\:mr-40,[dir='ltr'].ltr\:mr-40 {
  margin-right: 4rem
}

[dir='ltr'] .ltr\:mb-40,[dir='ltr'].ltr\:mb-40 {
  margin-bottom: 4rem
}

[dir='ltr'] .ltr\:ml-40,[dir='ltr'].ltr\:ml-40 {
  margin-left: 4rem
}

[dir='ltr'] .ltr\:mt-44,[dir='ltr'].ltr\:mt-44 {
  margin-top: 4.4rem
}

[dir='ltr'] .ltr\:mr-44,[dir='ltr'].ltr\:mr-44 {
  margin-right: 4.4rem
}

[dir='ltr'] .ltr\:mb-44,[dir='ltr'].ltr\:mb-44 {
  margin-bottom: 4.4rem
}

[dir='ltr'] .ltr\:ml-44,[dir='ltr'].ltr\:ml-44 {
  margin-left: 4.4rem
}

[dir='ltr'] .ltr\:mt-48,[dir='ltr'].ltr\:mt-48 {
  margin-top: 4.8rem
}

[dir='ltr'] .ltr\:mr-48,[dir='ltr'].ltr\:mr-48 {
  margin-right: 4.8rem
}

[dir='ltr'] .ltr\:mb-48,[dir='ltr'].ltr\:mb-48 {
  margin-bottom: 4.8rem
}

[dir='ltr'] .ltr\:ml-48,[dir='ltr'].ltr\:ml-48 {
  margin-left: 4.8rem
}

[dir='ltr'] .ltr\:mt-52,[dir='ltr'].ltr\:mt-52 {
  margin-top: 5.2rem
}

[dir='ltr'] .ltr\:mr-52,[dir='ltr'].ltr\:mr-52 {
  margin-right: 5.2rem
}

[dir='ltr'] .ltr\:mb-52,[dir='ltr'].ltr\:mb-52 {
  margin-bottom: 5.2rem
}

[dir='ltr'] .ltr\:ml-52,[dir='ltr'].ltr\:ml-52 {
  margin-left: 5.2rem
}

[dir='ltr'] .ltr\:mt-56,[dir='ltr'].ltr\:mt-56 {
  margin-top: 5.6rem
}

[dir='ltr'] .ltr\:mr-56,[dir='ltr'].ltr\:mr-56 {
  margin-right: 5.6rem
}

[dir='ltr'] .ltr\:mb-56,[dir='ltr'].ltr\:mb-56 {
  margin-bottom: 5.6rem
}

[dir='ltr'] .ltr\:ml-56,[dir='ltr'].ltr\:ml-56 {
  margin-left: 5.6rem
}

[dir='ltr'] .ltr\:mt-60,[dir='ltr'].ltr\:mt-60 {
  margin-top: 6rem
}

[dir='ltr'] .ltr\:mr-60,[dir='ltr'].ltr\:mr-60 {
  margin-right: 6rem
}

[dir='ltr'] .ltr\:mb-60,[dir='ltr'].ltr\:mb-60 {
  margin-bottom: 6rem
}

[dir='ltr'] .ltr\:ml-60,[dir='ltr'].ltr\:ml-60 {
  margin-left: 6rem
}

[dir='ltr'] .ltr\:mt-64,[dir='ltr'].ltr\:mt-64 {
  margin-top: 6.4rem
}

[dir='ltr'] .ltr\:mr-64,[dir='ltr'].ltr\:mr-64 {
  margin-right: 6.4rem
}

[dir='ltr'] .ltr\:mb-64,[dir='ltr'].ltr\:mb-64 {
  margin-bottom: 6.4rem
}

[dir='ltr'] .ltr\:ml-64,[dir='ltr'].ltr\:ml-64 {
  margin-left: 6.4rem
}

[dir='ltr'] .ltr\:mt-68,[dir='ltr'].ltr\:mt-68 {
  margin-top: 6.8rem
}

[dir='ltr'] .ltr\:mr-68,[dir='ltr'].ltr\:mr-68 {
  margin-right: 6.8rem
}

[dir='ltr'] .ltr\:mb-68,[dir='ltr'].ltr\:mb-68 {
  margin-bottom: 6.8rem
}

[dir='ltr'] .ltr\:ml-68,[dir='ltr'].ltr\:ml-68 {
  margin-left: 6.8rem
}

[dir='ltr'] .ltr\:mt-72,[dir='ltr'].ltr\:mt-72 {
  margin-top: 7.2rem
}

[dir='ltr'] .ltr\:mr-72,[dir='ltr'].ltr\:mr-72 {
  margin-right: 7.2rem
}

[dir='ltr'] .ltr\:mb-72,[dir='ltr'].ltr\:mb-72 {
  margin-bottom: 7.2rem
}

[dir='ltr'] .ltr\:ml-72,[dir='ltr'].ltr\:ml-72 {
  margin-left: 7.2rem
}

[dir='ltr'] .ltr\:mt-76,[dir='ltr'].ltr\:mt-76 {
  margin-top: 7.6rem
}

[dir='ltr'] .ltr\:mr-76,[dir='ltr'].ltr\:mr-76 {
  margin-right: 7.6rem
}

[dir='ltr'] .ltr\:mb-76,[dir='ltr'].ltr\:mb-76 {
  margin-bottom: 7.6rem
}

[dir='ltr'] .ltr\:ml-76,[dir='ltr'].ltr\:ml-76 {
  margin-left: 7.6rem
}

[dir='ltr'] .ltr\:mt-80,[dir='ltr'].ltr\:mt-80 {
  margin-top: 8rem
}

[dir='ltr'] .ltr\:mr-80,[dir='ltr'].ltr\:mr-80 {
  margin-right: 8rem
}

[dir='ltr'] .ltr\:mb-80,[dir='ltr'].ltr\:mb-80 {
  margin-bottom: 8rem
}

[dir='ltr'] .ltr\:ml-80,[dir='ltr'].ltr\:ml-80 {
  margin-left: 8rem
}

[dir='ltr'] .ltr\:mt-84,[dir='ltr'].ltr\:mt-84 {
  margin-top: 8.4rem
}

[dir='ltr'] .ltr\:mr-84,[dir='ltr'].ltr\:mr-84 {
  margin-right: 8.4rem
}

[dir='ltr'] .ltr\:mb-84,[dir='ltr'].ltr\:mb-84 {
  margin-bottom: 8.4rem
}

[dir='ltr'] .ltr\:ml-84,[dir='ltr'].ltr\:ml-84 {
  margin-left: 8.4rem
}

[dir='ltr'] .ltr\:mt-88,[dir='ltr'].ltr\:mt-88 {
  margin-top: 8.8rem
}

[dir='ltr'] .ltr\:mr-88,[dir='ltr'].ltr\:mr-88 {
  margin-right: 8.8rem
}

[dir='ltr'] .ltr\:mb-88,[dir='ltr'].ltr\:mb-88 {
  margin-bottom: 8.8rem
}

[dir='ltr'] .ltr\:ml-88,[dir='ltr'].ltr\:ml-88 {
  margin-left: 8.8rem
}

[dir='ltr'] .ltr\:mt-92,[dir='ltr'].ltr\:mt-92 {
  margin-top: 9.2rem
}

[dir='ltr'] .ltr\:mr-92,[dir='ltr'].ltr\:mr-92 {
  margin-right: 9.2rem
}

[dir='ltr'] .ltr\:mb-92,[dir='ltr'].ltr\:mb-92 {
  margin-bottom: 9.2rem
}

[dir='ltr'] .ltr\:ml-92,[dir='ltr'].ltr\:ml-92 {
  margin-left: 9.2rem
}

[dir='ltr'] .ltr\:mt-96,[dir='ltr'].ltr\:mt-96 {
  margin-top: 9.6rem
}

[dir='ltr'] .ltr\:mr-96,[dir='ltr'].ltr\:mr-96 {
  margin-right: 9.6rem
}

[dir='ltr'] .ltr\:mb-96,[dir='ltr'].ltr\:mb-96 {
  margin-bottom: 9.6rem
}

[dir='ltr'] .ltr\:ml-96,[dir='ltr'].ltr\:ml-96 {
  margin-left: 9.6rem
}

[dir='ltr'] .ltr\:mt-128,[dir='ltr'].ltr\:mt-128 {
  margin-top: 12.8rem
}

[dir='ltr'] .ltr\:mr-128,[dir='ltr'].ltr\:mr-128 {
  margin-right: 12.8rem
}

[dir='ltr'] .ltr\:mb-128,[dir='ltr'].ltr\:mb-128 {
  margin-bottom: 12.8rem
}

[dir='ltr'] .ltr\:ml-128,[dir='ltr'].ltr\:ml-128 {
  margin-left: 12.8rem
}

[dir='ltr'] .ltr\:mt-136,[dir='ltr'].ltr\:mt-136 {
  margin-top: 13.6rem
}

[dir='ltr'] .ltr\:mr-136,[dir='ltr'].ltr\:mr-136 {
  margin-right: 13.6rem
}

[dir='ltr'] .ltr\:mb-136,[dir='ltr'].ltr\:mb-136 {
  margin-bottom: 13.6rem
}

[dir='ltr'] .ltr\:ml-136,[dir='ltr'].ltr\:ml-136 {
  margin-left: 13.6rem
}

[dir='ltr'] .ltr\:mt-160,[dir='ltr'].ltr\:mt-160 {
  margin-top: 16rem
}

[dir='ltr'] .ltr\:mr-160,[dir='ltr'].ltr\:mr-160 {
  margin-right: 16rem
}

[dir='ltr'] .ltr\:mb-160,[dir='ltr'].ltr\:mb-160 {
  margin-bottom: 16rem
}

[dir='ltr'] .ltr\:ml-160,[dir='ltr'].ltr\:ml-160 {
  margin-left: 16rem
}

[dir='ltr'] .ltr\:mt-192,[dir='ltr'].ltr\:mt-192 {
  margin-top: 19.2rem
}

[dir='ltr'] .ltr\:mr-192,[dir='ltr'].ltr\:mr-192 {
  margin-right: 19.2rem
}

[dir='ltr'] .ltr\:mb-192,[dir='ltr'].ltr\:mb-192 {
  margin-bottom: 19.2rem
}

[dir='ltr'] .ltr\:ml-192,[dir='ltr'].ltr\:ml-192 {
  margin-left: 19.2rem
}

[dir='ltr'] .ltr\:mt-200,[dir='ltr'].ltr\:mt-200 {
  margin-top: 20rem
}

[dir='ltr'] .ltr\:mr-200,[dir='ltr'].ltr\:mr-200 {
  margin-right: 20rem
}

[dir='ltr'] .ltr\:mb-200,[dir='ltr'].ltr\:mb-200 {
  margin-bottom: 20rem
}

[dir='ltr'] .ltr\:ml-200,[dir='ltr'].ltr\:ml-200 {
  margin-left: 20rem
}

[dir='ltr'] .ltr\:mt-208,[dir='ltr'].ltr\:mt-208 {
  margin-top: 20.8rem
}

[dir='ltr'] .ltr\:mr-208,[dir='ltr'].ltr\:mr-208 {
  margin-right: 20.8rem
}

[dir='ltr'] .ltr\:mb-208,[dir='ltr'].ltr\:mb-208 {
  margin-bottom: 20.8rem
}

[dir='ltr'] .ltr\:ml-208,[dir='ltr'].ltr\:ml-208 {
  margin-left: 20.8rem
}

[dir='ltr'] .ltr\:mt-216,[dir='ltr'].ltr\:mt-216 {
  margin-top: 21.6rem
}

[dir='ltr'] .ltr\:mr-216,[dir='ltr'].ltr\:mr-216 {
  margin-right: 21.6rem
}

[dir='ltr'] .ltr\:mb-216,[dir='ltr'].ltr\:mb-216 {
  margin-bottom: 21.6rem
}

[dir='ltr'] .ltr\:ml-216,[dir='ltr'].ltr\:ml-216 {
  margin-left: 21.6rem
}

[dir='ltr'] .ltr\:mt-224,[dir='ltr'].ltr\:mt-224 {
  margin-top: 22.4rem
}

[dir='ltr'] .ltr\:mr-224,[dir='ltr'].ltr\:mr-224 {
  margin-right: 22.4rem
}

[dir='ltr'] .ltr\:mb-224,[dir='ltr'].ltr\:mb-224 {
  margin-bottom: 22.4rem
}

[dir='ltr'] .ltr\:ml-224,[dir='ltr'].ltr\:ml-224 {
  margin-left: 22.4rem
}

[dir='ltr'] .ltr\:mt-256,[dir='ltr'].ltr\:mt-256 {
  margin-top: 25.6rem
}

[dir='ltr'] .ltr\:mr-256,[dir='ltr'].ltr\:mr-256 {
  margin-right: 25.6rem
}

[dir='ltr'] .ltr\:mb-256,[dir='ltr'].ltr\:mb-256 {
  margin-bottom: 25.6rem
}

[dir='ltr'] .ltr\:ml-256,[dir='ltr'].ltr\:ml-256 {
  margin-left: 25.6rem
}

[dir='ltr'] .ltr\:mt-288,[dir='ltr'].ltr\:mt-288 {
  margin-top: 28.8rem
}

[dir='ltr'] .ltr\:mr-288,[dir='ltr'].ltr\:mr-288 {
  margin-right: 28.8rem
}

[dir='ltr'] .ltr\:mb-288,[dir='ltr'].ltr\:mb-288 {
  margin-bottom: 28.8rem
}

[dir='ltr'] .ltr\:ml-288,[dir='ltr'].ltr\:ml-288 {
  margin-left: 28.8rem
}

[dir='ltr'] .ltr\:mt-320,[dir='ltr'].ltr\:mt-320 {
  margin-top: 32rem
}

[dir='ltr'] .ltr\:mr-320,[dir='ltr'].ltr\:mr-320 {
  margin-right: 32rem
}

[dir='ltr'] .ltr\:mb-320,[dir='ltr'].ltr\:mb-320 {
  margin-bottom: 32rem
}

[dir='ltr'] .ltr\:ml-320,[dir='ltr'].ltr\:ml-320 {
  margin-left: 32rem
}

[dir='ltr'] .ltr\:mt-360,[dir='ltr'].ltr\:mt-360 {
  margin-top: 36rem
}

[dir='ltr'] .ltr\:mr-360,[dir='ltr'].ltr\:mr-360 {
  margin-right: 36rem
}

[dir='ltr'] .ltr\:mb-360,[dir='ltr'].ltr\:mb-360 {
  margin-bottom: 36rem
}

[dir='ltr'] .ltr\:ml-360,[dir='ltr'].ltr\:ml-360 {
  margin-left: 36rem
}

[dir='ltr'] .ltr\:mt-384,[dir='ltr'].ltr\:mt-384 {
  margin-top: 38.4rem
}

[dir='ltr'] .ltr\:mr-384,[dir='ltr'].ltr\:mr-384 {
  margin-right: 38.4rem
}

[dir='ltr'] .ltr\:mb-384,[dir='ltr'].ltr\:mb-384 {
  margin-bottom: 38.4rem
}

[dir='ltr'] .ltr\:ml-384,[dir='ltr'].ltr\:ml-384 {
  margin-left: 38.4rem
}

[dir='ltr'] .ltr\:mt-400,[dir='ltr'].ltr\:mt-400 {
  margin-top: 40rem
}

[dir='ltr'] .ltr\:mr-400,[dir='ltr'].ltr\:mr-400 {
  margin-right: 40rem
}

[dir='ltr'] .ltr\:mb-400,[dir='ltr'].ltr\:mb-400 {
  margin-bottom: 40rem
}

[dir='ltr'] .ltr\:ml-400,[dir='ltr'].ltr\:ml-400 {
  margin-left: 40rem
}

[dir='ltr'] .ltr\:mt-512,[dir='ltr'].ltr\:mt-512 {
  margin-top: 51.2rem
}

[dir='ltr'] .ltr\:mr-512,[dir='ltr'].ltr\:mr-512 {
  margin-right: 51.2rem
}

[dir='ltr'] .ltr\:mb-512,[dir='ltr'].ltr\:mb-512 {
  margin-bottom: 51.2rem
}

[dir='ltr'] .ltr\:ml-512,[dir='ltr'].ltr\:ml-512 {
  margin-left: 51.2rem
}

[dir='ltr'] .ltr\:mt-640,[dir='ltr'].ltr\:mt-640 {
  margin-top: 64rem
}

[dir='ltr'] .ltr\:mr-640,[dir='ltr'].ltr\:mr-640 {
  margin-right: 64rem
}

[dir='ltr'] .ltr\:mb-640,[dir='ltr'].ltr\:mb-640 {
  margin-bottom: 64rem
}

[dir='ltr'] .ltr\:ml-640,[dir='ltr'].ltr\:ml-640 {
  margin-left: 64rem
}

[dir='ltr'] .ltr\:mt-auto,[dir='ltr'].ltr\:mt-auto {
  margin-top: auto
}

[dir='ltr'] .ltr\:mr-auto,[dir='ltr'].ltr\:mr-auto {
  margin-right: auto
}

[dir='ltr'] .ltr\:mb-auto,[dir='ltr'].ltr\:mb-auto {
  margin-bottom: auto
}

[dir='ltr'] .ltr\:ml-auto,[dir='ltr'].ltr\:ml-auto {
  margin-left: auto
}

[dir='ltr'] .ltr\:mt-xs,[dir='ltr'].ltr\:mt-xs {
  margin-top: 32rem
}

[dir='ltr'] .ltr\:mr-xs,[dir='ltr'].ltr\:mr-xs {
  margin-right: 32rem
}

[dir='ltr'] .ltr\:mb-xs,[dir='ltr'].ltr\:mb-xs {
  margin-bottom: 32rem
}

[dir='ltr'] .ltr\:ml-xs,[dir='ltr'].ltr\:ml-xs {
  margin-left: 32rem
}

[dir='ltr'] .ltr\:mt-sm,[dir='ltr'].ltr\:mt-sm {
  margin-top: 48rem
}

[dir='ltr'] .ltr\:mr-sm,[dir='ltr'].ltr\:mr-sm {
  margin-right: 48rem
}

[dir='ltr'] .ltr\:mb-sm,[dir='ltr'].ltr\:mb-sm {
  margin-bottom: 48rem
}

[dir='ltr'] .ltr\:ml-sm,[dir='ltr'].ltr\:ml-sm {
  margin-left: 48rem
}

[dir='ltr'] .ltr\:mt-md,[dir='ltr'].ltr\:mt-md {
  margin-top: 64rem
}

[dir='ltr'] .ltr\:mr-md,[dir='ltr'].ltr\:mr-md {
  margin-right: 64rem
}

[dir='ltr'] .ltr\:mb-md,[dir='ltr'].ltr\:mb-md {
  margin-bottom: 64rem
}

[dir='ltr'] .ltr\:ml-md,[dir='ltr'].ltr\:ml-md {
  margin-left: 64rem
}

[dir='ltr'] .ltr\:mt-lg,[dir='ltr'].ltr\:mt-lg {
  margin-top: 80rem
}

[dir='ltr'] .ltr\:mr-lg,[dir='ltr'].ltr\:mr-lg {
  margin-right: 80rem
}

[dir='ltr'] .ltr\:mb-lg,[dir='ltr'].ltr\:mb-lg {
  margin-bottom: 80rem
}

[dir='ltr'] .ltr\:ml-lg,[dir='ltr'].ltr\:ml-lg {
  margin-left: 80rem
}

[dir='ltr'] .ltr\:mt-xl,[dir='ltr'].ltr\:mt-xl {
  margin-top: 96rem
}

[dir='ltr'] .ltr\:mr-xl,[dir='ltr'].ltr\:mr-xl {
  margin-right: 96rem
}

[dir='ltr'] .ltr\:mb-xl,[dir='ltr'].ltr\:mb-xl {
  margin-bottom: 96rem
}

[dir='ltr'] .ltr\:ml-xl,[dir='ltr'].ltr\:ml-xl {
  margin-left: 96rem
}

[dir='ltr'] .ltr\:mt-2xl,[dir='ltr'].ltr\:mt-2xl {
  margin-top: 112rem
}

[dir='ltr'] .ltr\:mr-2xl,[dir='ltr'].ltr\:mr-2xl {
  margin-right: 112rem
}

[dir='ltr'] .ltr\:mb-2xl,[dir='ltr'].ltr\:mb-2xl {
  margin-bottom: 112rem
}

[dir='ltr'] .ltr\:ml-2xl,[dir='ltr'].ltr\:ml-2xl {
  margin-left: 112rem
}

[dir='ltr'] .ltr\:mt-3xl,[dir='ltr'].ltr\:mt-3xl {
  margin-top: 128rem
}

[dir='ltr'] .ltr\:mr-3xl,[dir='ltr'].ltr\:mr-3xl {
  margin-right: 128rem
}

[dir='ltr'] .ltr\:mb-3xl,[dir='ltr'].ltr\:mb-3xl {
  margin-bottom: 128rem
}

[dir='ltr'] .ltr\:ml-3xl,[dir='ltr'].ltr\:ml-3xl {
  margin-left: 128rem
}

[dir='ltr'] .ltr\:mt-4xl,[dir='ltr'].ltr\:mt-4xl {
  margin-top: 144rem
}

[dir='ltr'] .ltr\:mr-4xl,[dir='ltr'].ltr\:mr-4xl {
  margin-right: 144rem
}

[dir='ltr'] .ltr\:mb-4xl,[dir='ltr'].ltr\:mb-4xl {
  margin-bottom: 144rem
}

[dir='ltr'] .ltr\:ml-4xl,[dir='ltr'].ltr\:ml-4xl {
  margin-left: 144rem
}

[dir='ltr'] .ltr\:mt-5xl,[dir='ltr'].ltr\:mt-5xl {
  margin-top: 160rem
}

[dir='ltr'] .ltr\:mr-5xl,[dir='ltr'].ltr\:mr-5xl {
  margin-right: 160rem
}

[dir='ltr'] .ltr\:mb-5xl,[dir='ltr'].ltr\:mb-5xl {
  margin-bottom: 160rem
}

[dir='ltr'] .ltr\:ml-5xl,[dir='ltr'].ltr\:ml-5xl {
  margin-left: 160rem
}

[dir='ltr'] .ltr\:mt-px,[dir='ltr'].ltr\:mt-px {
  margin-top: 1px
}

[dir='ltr'] .ltr\:mr-px,[dir='ltr'].ltr\:mr-px {
  margin-right: 1px
}

[dir='ltr'] .ltr\:mb-px,[dir='ltr'].ltr\:mb-px {
  margin-bottom: 1px
}

[dir='ltr'] .ltr\:ml-px,[dir='ltr'].ltr\:ml-px {
  margin-left: 1px
}

[dir='ltr'] .ltr\:mt-0\.5,[dir='ltr'].ltr\:mt-0\.5 {
  margin-top: 0.05rem
}

[dir='ltr'] .ltr\:mr-0\.5,[dir='ltr'].ltr\:mr-0\.5 {
  margin-right: 0.05rem
}

[dir='ltr'] .ltr\:mb-0\.5,[dir='ltr'].ltr\:mb-0\.5 {
  margin-bottom: 0.05rem
}

[dir='ltr'] .ltr\:ml-0\.5,[dir='ltr'].ltr\:ml-0\.5 {
  margin-left: 0.05rem
}

[dir='ltr'] .ltr\:mt-1\.5,[dir='ltr'].ltr\:mt-1\.5 {
  margin-top: 0.15rem
}

[dir='ltr'] .ltr\:mr-1\.5,[dir='ltr'].ltr\:mr-1\.5 {
  margin-right: 0.15rem
}

[dir='ltr'] .ltr\:mb-1\.5,[dir='ltr'].ltr\:mb-1\.5 {
  margin-bottom: 0.15rem
}

[dir='ltr'] .ltr\:ml-1\.5,[dir='ltr'].ltr\:ml-1\.5 {
  margin-left: 0.15rem
}

[dir='ltr'] .ltr\:mt-2\.5,[dir='ltr'].ltr\:mt-2\.5 {
  margin-top: 0.25rem
}

[dir='ltr'] .ltr\:mr-2\.5,[dir='ltr'].ltr\:mr-2\.5 {
  margin-right: 0.25rem
}

[dir='ltr'] .ltr\:mb-2\.5,[dir='ltr'].ltr\:mb-2\.5 {
  margin-bottom: 0.25rem
}

[dir='ltr'] .ltr\:ml-2\.5,[dir='ltr'].ltr\:ml-2\.5 {
  margin-left: 0.25rem
}

[dir='ltr'] .ltr\:mt-3\.5,[dir='ltr'].ltr\:mt-3\.5 {
  margin-top: 0.35rem
}

[dir='ltr'] .ltr\:mr-3\.5,[dir='ltr'].ltr\:mr-3\.5 {
  margin-right: 0.35rem
}

[dir='ltr'] .ltr\:mb-3\.5,[dir='ltr'].ltr\:mb-3\.5 {
  margin-bottom: 0.35rem
}

[dir='ltr'] .ltr\:ml-3\.5,[dir='ltr'].ltr\:ml-3\.5 {
  margin-left: 0.35rem
}

[dir='ltr'] .ltr\:-mt-1,[dir='ltr'].ltr\:-mt-1 {
  margin-top: -0.1rem
}

[dir='ltr'] .ltr\:-mr-1,[dir='ltr'].ltr\:-mr-1 {
  margin-right: -0.1rem
}

[dir='ltr'] .ltr\:-mb-1,[dir='ltr'].ltr\:-mb-1 {
  margin-bottom: -0.1rem
}

[dir='ltr'] .ltr\:-ml-1,[dir='ltr'].ltr\:-ml-1 {
  margin-left: -0.1rem
}

[dir='ltr'] .ltr\:-mt-2,[dir='ltr'].ltr\:-mt-2 {
  margin-top: -0.2rem
}

[dir='ltr'] .ltr\:-mr-2,[dir='ltr'].ltr\:-mr-2 {
  margin-right: -0.2rem
}

[dir='ltr'] .ltr\:-mb-2,[dir='ltr'].ltr\:-mb-2 {
  margin-bottom: -0.2rem
}

[dir='ltr'] .ltr\:-ml-2,[dir='ltr'].ltr\:-ml-2 {
  margin-left: -0.2rem
}

[dir='ltr'] .ltr\:-mt-3,[dir='ltr'].ltr\:-mt-3 {
  margin-top: -0.3rem
}

[dir='ltr'] .ltr\:-mr-3,[dir='ltr'].ltr\:-mr-3 {
  margin-right: -0.3rem
}

[dir='ltr'] .ltr\:-mb-3,[dir='ltr'].ltr\:-mb-3 {
  margin-bottom: -0.3rem
}

[dir='ltr'] .ltr\:-ml-3,[dir='ltr'].ltr\:-ml-3 {
  margin-left: -0.3rem
}

[dir='ltr'] .ltr\:-mt-4,[dir='ltr'].ltr\:-mt-4 {
  margin-top: -0.4rem
}

[dir='ltr'] .ltr\:-mr-4,[dir='ltr'].ltr\:-mr-4 {
  margin-right: -0.4rem
}

[dir='ltr'] .ltr\:-mb-4,[dir='ltr'].ltr\:-mb-4 {
  margin-bottom: -0.4rem
}

[dir='ltr'] .ltr\:-ml-4,[dir='ltr'].ltr\:-ml-4 {
  margin-left: -0.4rem
}

[dir='ltr'] .ltr\:-mt-5,[dir='ltr'].ltr\:-mt-5 {
  margin-top: -0.5rem
}

[dir='ltr'] .ltr\:-mr-5,[dir='ltr'].ltr\:-mr-5 {
  margin-right: -0.5rem
}

[dir='ltr'] .ltr\:-mb-5,[dir='ltr'].ltr\:-mb-5 {
  margin-bottom: -0.5rem
}

[dir='ltr'] .ltr\:-ml-5,[dir='ltr'].ltr\:-ml-5 {
  margin-left: -0.5rem
}

[dir='ltr'] .ltr\:-mt-6,[dir='ltr'].ltr\:-mt-6 {
  margin-top: -0.6rem
}

[dir='ltr'] .ltr\:-mr-6,[dir='ltr'].ltr\:-mr-6 {
  margin-right: -0.6rem
}

[dir='ltr'] .ltr\:-mb-6,[dir='ltr'].ltr\:-mb-6 {
  margin-bottom: -0.6rem
}

[dir='ltr'] .ltr\:-ml-6,[dir='ltr'].ltr\:-ml-6 {
  margin-left: -0.6rem
}

[dir='ltr'] .ltr\:-mt-7,[dir='ltr'].ltr\:-mt-7 {
  margin-top: -0.7rem
}

[dir='ltr'] .ltr\:-mr-7,[dir='ltr'].ltr\:-mr-7 {
  margin-right: -0.7rem
}

[dir='ltr'] .ltr\:-mb-7,[dir='ltr'].ltr\:-mb-7 {
  margin-bottom: -0.7rem
}

[dir='ltr'] .ltr\:-ml-7,[dir='ltr'].ltr\:-ml-7 {
  margin-left: -0.7rem
}

[dir='ltr'] .ltr\:-mt-8,[dir='ltr'].ltr\:-mt-8 {
  margin-top: -0.8rem
}

[dir='ltr'] .ltr\:-mr-8,[dir='ltr'].ltr\:-mr-8 {
  margin-right: -0.8rem
}

[dir='ltr'] .ltr\:-mb-8,[dir='ltr'].ltr\:-mb-8 {
  margin-bottom: -0.8rem
}

[dir='ltr'] .ltr\:-ml-8,[dir='ltr'].ltr\:-ml-8 {
  margin-left: -0.8rem
}

[dir='ltr'] .ltr\:-mt-9,[dir='ltr'].ltr\:-mt-9 {
  margin-top: -0.9rem
}

[dir='ltr'] .ltr\:-mr-9,[dir='ltr'].ltr\:-mr-9 {
  margin-right: -0.9rem
}

[dir='ltr'] .ltr\:-mb-9,[dir='ltr'].ltr\:-mb-9 {
  margin-bottom: -0.9rem
}

[dir='ltr'] .ltr\:-ml-9,[dir='ltr'].ltr\:-ml-9 {
  margin-left: -0.9rem
}

[dir='ltr'] .ltr\:-mt-10,[dir='ltr'].ltr\:-mt-10 {
  margin-top: -1rem
}

[dir='ltr'] .ltr\:-mr-10,[dir='ltr'].ltr\:-mr-10 {
  margin-right: -1rem
}

[dir='ltr'] .ltr\:-mb-10,[dir='ltr'].ltr\:-mb-10 {
  margin-bottom: -1rem
}

[dir='ltr'] .ltr\:-ml-10,[dir='ltr'].ltr\:-ml-10 {
  margin-left: -1rem
}

[dir='ltr'] .ltr\:-mt-12,[dir='ltr'].ltr\:-mt-12 {
  margin-top: -1.2rem
}

[dir='ltr'] .ltr\:-mr-12,[dir='ltr'].ltr\:-mr-12 {
  margin-right: -1.2rem
}

[dir='ltr'] .ltr\:-mb-12,[dir='ltr'].ltr\:-mb-12 {
  margin-bottom: -1.2rem
}

[dir='ltr'] .ltr\:-ml-12,[dir='ltr'].ltr\:-ml-12 {
  margin-left: -1.2rem
}

[dir='ltr'] .ltr\:-mt-14,[dir='ltr'].ltr\:-mt-14 {
  margin-top: -1.4rem
}

[dir='ltr'] .ltr\:-mr-14,[dir='ltr'].ltr\:-mr-14 {
  margin-right: -1.4rem
}

[dir='ltr'] .ltr\:-mb-14,[dir='ltr'].ltr\:-mb-14 {
  margin-bottom: -1.4rem
}

[dir='ltr'] .ltr\:-ml-14,[dir='ltr'].ltr\:-ml-14 {
  margin-left: -1.4rem
}

[dir='ltr'] .ltr\:-mt-16,[dir='ltr'].ltr\:-mt-16 {
  margin-top: -1.6rem
}

[dir='ltr'] .ltr\:-mr-16,[dir='ltr'].ltr\:-mr-16 {
  margin-right: -1.6rem
}

[dir='ltr'] .ltr\:-mb-16,[dir='ltr'].ltr\:-mb-16 {
  margin-bottom: -1.6rem
}

[dir='ltr'] .ltr\:-ml-16,[dir='ltr'].ltr\:-ml-16 {
  margin-left: -1.6rem
}

[dir='ltr'] .ltr\:-mt-20,[dir='ltr'].ltr\:-mt-20 {
  margin-top: -2rem
}

[dir='ltr'] .ltr\:-mr-20,[dir='ltr'].ltr\:-mr-20 {
  margin-right: -2rem
}

[dir='ltr'] .ltr\:-mb-20,[dir='ltr'].ltr\:-mb-20 {
  margin-bottom: -2rem
}

[dir='ltr'] .ltr\:-ml-20,[dir='ltr'].ltr\:-ml-20 {
  margin-left: -2rem
}

[dir='ltr'] .ltr\:-mt-24,[dir='ltr'].ltr\:-mt-24 {
  margin-top: -2.4rem
}

[dir='ltr'] .ltr\:-mr-24,[dir='ltr'].ltr\:-mr-24 {
  margin-right: -2.4rem
}

[dir='ltr'] .ltr\:-mb-24,[dir='ltr'].ltr\:-mb-24 {
  margin-bottom: -2.4rem
}

[dir='ltr'] .ltr\:-ml-24,[dir='ltr'].ltr\:-ml-24 {
  margin-left: -2.4rem
}

[dir='ltr'] .ltr\:-mt-28,[dir='ltr'].ltr\:-mt-28 {
  margin-top: -2.8rem
}

[dir='ltr'] .ltr\:-mr-28,[dir='ltr'].ltr\:-mr-28 {
  margin-right: -2.8rem
}

[dir='ltr'] .ltr\:-mb-28,[dir='ltr'].ltr\:-mb-28 {
  margin-bottom: -2.8rem
}

[dir='ltr'] .ltr\:-ml-28,[dir='ltr'].ltr\:-ml-28 {
  margin-left: -2.8rem
}

[dir='ltr'] .ltr\:-mt-32,[dir='ltr'].ltr\:-mt-32 {
  margin-top: -3.2rem
}

[dir='ltr'] .ltr\:-mr-32,[dir='ltr'].ltr\:-mr-32 {
  margin-right: -3.2rem
}

[dir='ltr'] .ltr\:-mb-32,[dir='ltr'].ltr\:-mb-32 {
  margin-bottom: -3.2rem
}

[dir='ltr'] .ltr\:-ml-32,[dir='ltr'].ltr\:-ml-32 {
  margin-left: -3.2rem
}

[dir='ltr'] .ltr\:-mt-36,[dir='ltr'].ltr\:-mt-36 {
  margin-top: -3.6rem
}

[dir='ltr'] .ltr\:-mr-36,[dir='ltr'].ltr\:-mr-36 {
  margin-right: -3.6rem
}

[dir='ltr'] .ltr\:-mb-36,[dir='ltr'].ltr\:-mb-36 {
  margin-bottom: -3.6rem
}

[dir='ltr'] .ltr\:-ml-36,[dir='ltr'].ltr\:-ml-36 {
  margin-left: -3.6rem
}

[dir='ltr'] .ltr\:-mt-40,[dir='ltr'].ltr\:-mt-40 {
  margin-top: -4rem
}

[dir='ltr'] .ltr\:-mr-40,[dir='ltr'].ltr\:-mr-40 {
  margin-right: -4rem
}

[dir='ltr'] .ltr\:-mb-40,[dir='ltr'].ltr\:-mb-40 {
  margin-bottom: -4rem
}

[dir='ltr'] .ltr\:-ml-40,[dir='ltr'].ltr\:-ml-40 {
  margin-left: -4rem
}

[dir='ltr'] .ltr\:-mt-44,[dir='ltr'].ltr\:-mt-44 {
  margin-top: -4.4rem
}

[dir='ltr'] .ltr\:-mr-44,[dir='ltr'].ltr\:-mr-44 {
  margin-right: -4.4rem
}

[dir='ltr'] .ltr\:-mb-44,[dir='ltr'].ltr\:-mb-44 {
  margin-bottom: -4.4rem
}

[dir='ltr'] .ltr\:-ml-44,[dir='ltr'].ltr\:-ml-44 {
  margin-left: -4.4rem
}

[dir='ltr'] .ltr\:-mt-48,[dir='ltr'].ltr\:-mt-48 {
  margin-top: -4.8rem
}

[dir='ltr'] .ltr\:-mr-48,[dir='ltr'].ltr\:-mr-48 {
  margin-right: -4.8rem
}

[dir='ltr'] .ltr\:-mb-48,[dir='ltr'].ltr\:-mb-48 {
  margin-bottom: -4.8rem
}

[dir='ltr'] .ltr\:-ml-48,[dir='ltr'].ltr\:-ml-48 {
  margin-left: -4.8rem
}

[dir='ltr'] .ltr\:-mt-52,[dir='ltr'].ltr\:-mt-52 {
  margin-top: -5.2rem
}

[dir='ltr'] .ltr\:-mr-52,[dir='ltr'].ltr\:-mr-52 {
  margin-right: -5.2rem
}

[dir='ltr'] .ltr\:-mb-52,[dir='ltr'].ltr\:-mb-52 {
  margin-bottom: -5.2rem
}

[dir='ltr'] .ltr\:-ml-52,[dir='ltr'].ltr\:-ml-52 {
  margin-left: -5.2rem
}

[dir='ltr'] .ltr\:-mt-56,[dir='ltr'].ltr\:-mt-56 {
  margin-top: -5.6rem
}

[dir='ltr'] .ltr\:-mr-56,[dir='ltr'].ltr\:-mr-56 {
  margin-right: -5.6rem
}

[dir='ltr'] .ltr\:-mb-56,[dir='ltr'].ltr\:-mb-56 {
  margin-bottom: -5.6rem
}

[dir='ltr'] .ltr\:-ml-56,[dir='ltr'].ltr\:-ml-56 {
  margin-left: -5.6rem
}

[dir='ltr'] .ltr\:-mt-60,[dir='ltr'].ltr\:-mt-60 {
  margin-top: -6rem
}

[dir='ltr'] .ltr\:-mr-60,[dir='ltr'].ltr\:-mr-60 {
  margin-right: -6rem
}

[dir='ltr'] .ltr\:-mb-60,[dir='ltr'].ltr\:-mb-60 {
  margin-bottom: -6rem
}

[dir='ltr'] .ltr\:-ml-60,[dir='ltr'].ltr\:-ml-60 {
  margin-left: -6rem
}

[dir='ltr'] .ltr\:-mt-64,[dir='ltr'].ltr\:-mt-64 {
  margin-top: -6.4rem
}

[dir='ltr'] .ltr\:-mr-64,[dir='ltr'].ltr\:-mr-64 {
  margin-right: -6.4rem
}

[dir='ltr'] .ltr\:-mb-64,[dir='ltr'].ltr\:-mb-64 {
  margin-bottom: -6.4rem
}

[dir='ltr'] .ltr\:-ml-64,[dir='ltr'].ltr\:-ml-64 {
  margin-left: -6.4rem
}

[dir='ltr'] .ltr\:-mt-68,[dir='ltr'].ltr\:-mt-68 {
  margin-top: -6.8rem
}

[dir='ltr'] .ltr\:-mr-68,[dir='ltr'].ltr\:-mr-68 {
  margin-right: -6.8rem
}

[dir='ltr'] .ltr\:-mb-68,[dir='ltr'].ltr\:-mb-68 {
  margin-bottom: -6.8rem
}

[dir='ltr'] .ltr\:-ml-68,[dir='ltr'].ltr\:-ml-68 {
  margin-left: -6.8rem
}

[dir='ltr'] .ltr\:-mt-72,[dir='ltr'].ltr\:-mt-72 {
  margin-top: -7.2rem
}

[dir='ltr'] .ltr\:-mr-72,[dir='ltr'].ltr\:-mr-72 {
  margin-right: -7.2rem
}

[dir='ltr'] .ltr\:-mb-72,[dir='ltr'].ltr\:-mb-72 {
  margin-bottom: -7.2rem
}

[dir='ltr'] .ltr\:-ml-72,[dir='ltr'].ltr\:-ml-72 {
  margin-left: -7.2rem
}

[dir='ltr'] .ltr\:-mt-76,[dir='ltr'].ltr\:-mt-76 {
  margin-top: -7.6rem
}

[dir='ltr'] .ltr\:-mr-76,[dir='ltr'].ltr\:-mr-76 {
  margin-right: -7.6rem
}

[dir='ltr'] .ltr\:-mb-76,[dir='ltr'].ltr\:-mb-76 {
  margin-bottom: -7.6rem
}

[dir='ltr'] .ltr\:-ml-76,[dir='ltr'].ltr\:-ml-76 {
  margin-left: -7.6rem
}

[dir='ltr'] .ltr\:-mt-80,[dir='ltr'].ltr\:-mt-80 {
  margin-top: -8rem
}

[dir='ltr'] .ltr\:-mr-80,[dir='ltr'].ltr\:-mr-80 {
  margin-right: -8rem
}

[dir='ltr'] .ltr\:-mb-80,[dir='ltr'].ltr\:-mb-80 {
  margin-bottom: -8rem
}

[dir='ltr'] .ltr\:-ml-80,[dir='ltr'].ltr\:-ml-80 {
  margin-left: -8rem
}

[dir='ltr'] .ltr\:-mt-84,[dir='ltr'].ltr\:-mt-84 {
  margin-top: -8.4rem
}

[dir='ltr'] .ltr\:-mr-84,[dir='ltr'].ltr\:-mr-84 {
  margin-right: -8.4rem
}

[dir='ltr'] .ltr\:-mb-84,[dir='ltr'].ltr\:-mb-84 {
  margin-bottom: -8.4rem
}

[dir='ltr'] .ltr\:-ml-84,[dir='ltr'].ltr\:-ml-84 {
  margin-left: -8.4rem
}

[dir='ltr'] .ltr\:-mt-88,[dir='ltr'].ltr\:-mt-88 {
  margin-top: -8.8rem
}

[dir='ltr'] .ltr\:-mr-88,[dir='ltr'].ltr\:-mr-88 {
  margin-right: -8.8rem
}

[dir='ltr'] .ltr\:-mb-88,[dir='ltr'].ltr\:-mb-88 {
  margin-bottom: -8.8rem
}

[dir='ltr'] .ltr\:-ml-88,[dir='ltr'].ltr\:-ml-88 {
  margin-left: -8.8rem
}

[dir='ltr'] .ltr\:-mt-92,[dir='ltr'].ltr\:-mt-92 {
  margin-top: -9.2rem
}

[dir='ltr'] .ltr\:-mr-92,[dir='ltr'].ltr\:-mr-92 {
  margin-right: -9.2rem
}

[dir='ltr'] .ltr\:-mb-92,[dir='ltr'].ltr\:-mb-92 {
  margin-bottom: -9.2rem
}

[dir='ltr'] .ltr\:-ml-92,[dir='ltr'].ltr\:-ml-92 {
  margin-left: -9.2rem
}

[dir='ltr'] .ltr\:-mt-96,[dir='ltr'].ltr\:-mt-96 {
  margin-top: -9.6rem
}

[dir='ltr'] .ltr\:-mr-96,[dir='ltr'].ltr\:-mr-96 {
  margin-right: -9.6rem
}

[dir='ltr'] .ltr\:-mb-96,[dir='ltr'].ltr\:-mb-96 {
  margin-bottom: -9.6rem
}

[dir='ltr'] .ltr\:-ml-96,[dir='ltr'].ltr\:-ml-96 {
  margin-left: -9.6rem
}

[dir='ltr'] .ltr\:-mt-128,[dir='ltr'].ltr\:-mt-128 {
  margin-top: -12.8rem
}

[dir='ltr'] .ltr\:-mr-128,[dir='ltr'].ltr\:-mr-128 {
  margin-right: -12.8rem
}

[dir='ltr'] .ltr\:-mb-128,[dir='ltr'].ltr\:-mb-128 {
  margin-bottom: -12.8rem
}

[dir='ltr'] .ltr\:-ml-128,[dir='ltr'].ltr\:-ml-128 {
  margin-left: -12.8rem
}

[dir='ltr'] .ltr\:-mt-136,[dir='ltr'].ltr\:-mt-136 {
  margin-top: -13.6rem
}

[dir='ltr'] .ltr\:-mr-136,[dir='ltr'].ltr\:-mr-136 {
  margin-right: -13.6rem
}

[dir='ltr'] .ltr\:-mb-136,[dir='ltr'].ltr\:-mb-136 {
  margin-bottom: -13.6rem
}

[dir='ltr'] .ltr\:-ml-136,[dir='ltr'].ltr\:-ml-136 {
  margin-left: -13.6rem
}

[dir='ltr'] .ltr\:-mt-160,[dir='ltr'].ltr\:-mt-160 {
  margin-top: -16rem
}

[dir='ltr'] .ltr\:-mr-160,[dir='ltr'].ltr\:-mr-160 {
  margin-right: -16rem
}

[dir='ltr'] .ltr\:-mb-160,[dir='ltr'].ltr\:-mb-160 {
  margin-bottom: -16rem
}

[dir='ltr'] .ltr\:-ml-160,[dir='ltr'].ltr\:-ml-160 {
  margin-left: -16rem
}

[dir='ltr'] .ltr\:-mt-192,[dir='ltr'].ltr\:-mt-192 {
  margin-top: -19.2rem
}

[dir='ltr'] .ltr\:-mr-192,[dir='ltr'].ltr\:-mr-192 {
  margin-right: -19.2rem
}

[dir='ltr'] .ltr\:-mb-192,[dir='ltr'].ltr\:-mb-192 {
  margin-bottom: -19.2rem
}

[dir='ltr'] .ltr\:-ml-192,[dir='ltr'].ltr\:-ml-192 {
  margin-left: -19.2rem
}

[dir='ltr'] .ltr\:-mt-200,[dir='ltr'].ltr\:-mt-200 {
  margin-top: -20rem
}

[dir='ltr'] .ltr\:-mr-200,[dir='ltr'].ltr\:-mr-200 {
  margin-right: -20rem
}

[dir='ltr'] .ltr\:-mb-200,[dir='ltr'].ltr\:-mb-200 {
  margin-bottom: -20rem
}

[dir='ltr'] .ltr\:-ml-200,[dir='ltr'].ltr\:-ml-200 {
  margin-left: -20rem
}

[dir='ltr'] .ltr\:-mt-208,[dir='ltr'].ltr\:-mt-208 {
  margin-top: -20.8rem
}

[dir='ltr'] .ltr\:-mr-208,[dir='ltr'].ltr\:-mr-208 {
  margin-right: -20.8rem
}

[dir='ltr'] .ltr\:-mb-208,[dir='ltr'].ltr\:-mb-208 {
  margin-bottom: -20.8rem
}

[dir='ltr'] .ltr\:-ml-208,[dir='ltr'].ltr\:-ml-208 {
  margin-left: -20.8rem
}

[dir='ltr'] .ltr\:-mt-216,[dir='ltr'].ltr\:-mt-216 {
  margin-top: -21.6rem
}

[dir='ltr'] .ltr\:-mr-216,[dir='ltr'].ltr\:-mr-216 {
  margin-right: -21.6rem
}

[dir='ltr'] .ltr\:-mb-216,[dir='ltr'].ltr\:-mb-216 {
  margin-bottom: -21.6rem
}

[dir='ltr'] .ltr\:-ml-216,[dir='ltr'].ltr\:-ml-216 {
  margin-left: -21.6rem
}

[dir='ltr'] .ltr\:-mt-224,[dir='ltr'].ltr\:-mt-224 {
  margin-top: -22.4rem
}

[dir='ltr'] .ltr\:-mr-224,[dir='ltr'].ltr\:-mr-224 {
  margin-right: -22.4rem
}

[dir='ltr'] .ltr\:-mb-224,[dir='ltr'].ltr\:-mb-224 {
  margin-bottom: -22.4rem
}

[dir='ltr'] .ltr\:-ml-224,[dir='ltr'].ltr\:-ml-224 {
  margin-left: -22.4rem
}

[dir='ltr'] .ltr\:-mt-256,[dir='ltr'].ltr\:-mt-256 {
  margin-top: -25.6rem
}

[dir='ltr'] .ltr\:-mr-256,[dir='ltr'].ltr\:-mr-256 {
  margin-right: -25.6rem
}

[dir='ltr'] .ltr\:-mb-256,[dir='ltr'].ltr\:-mb-256 {
  margin-bottom: -25.6rem
}

[dir='ltr'] .ltr\:-ml-256,[dir='ltr'].ltr\:-ml-256 {
  margin-left: -25.6rem
}

[dir='ltr'] .ltr\:-mt-288,[dir='ltr'].ltr\:-mt-288 {
  margin-top: -28.8rem
}

[dir='ltr'] .ltr\:-mr-288,[dir='ltr'].ltr\:-mr-288 {
  margin-right: -28.8rem
}

[dir='ltr'] .ltr\:-mb-288,[dir='ltr'].ltr\:-mb-288 {
  margin-bottom: -28.8rem
}

[dir='ltr'] .ltr\:-ml-288,[dir='ltr'].ltr\:-ml-288 {
  margin-left: -28.8rem
}

[dir='ltr'] .ltr\:-mt-320,[dir='ltr'].ltr\:-mt-320 {
  margin-top: -32rem
}

[dir='ltr'] .ltr\:-mr-320,[dir='ltr'].ltr\:-mr-320 {
  margin-right: -32rem
}

[dir='ltr'] .ltr\:-mb-320,[dir='ltr'].ltr\:-mb-320 {
  margin-bottom: -32rem
}

[dir='ltr'] .ltr\:-ml-320,[dir='ltr'].ltr\:-ml-320 {
  margin-left: -32rem
}

[dir='ltr'] .ltr\:-mt-360,[dir='ltr'].ltr\:-mt-360 {
  margin-top: -36rem
}

[dir='ltr'] .ltr\:-mr-360,[dir='ltr'].ltr\:-mr-360 {
  margin-right: -36rem
}

[dir='ltr'] .ltr\:-mb-360,[dir='ltr'].ltr\:-mb-360 {
  margin-bottom: -36rem
}

[dir='ltr'] .ltr\:-ml-360,[dir='ltr'].ltr\:-ml-360 {
  margin-left: -36rem
}

[dir='ltr'] .ltr\:-mt-384,[dir='ltr'].ltr\:-mt-384 {
  margin-top: -38.4rem
}

[dir='ltr'] .ltr\:-mr-384,[dir='ltr'].ltr\:-mr-384 {
  margin-right: -38.4rem
}

[dir='ltr'] .ltr\:-mb-384,[dir='ltr'].ltr\:-mb-384 {
  margin-bottom: -38.4rem
}

[dir='ltr'] .ltr\:-ml-384,[dir='ltr'].ltr\:-ml-384 {
  margin-left: -38.4rem
}

[dir='ltr'] .ltr\:-mt-400,[dir='ltr'].ltr\:-mt-400 {
  margin-top: -40rem
}

[dir='ltr'] .ltr\:-mr-400,[dir='ltr'].ltr\:-mr-400 {
  margin-right: -40rem
}

[dir='ltr'] .ltr\:-mb-400,[dir='ltr'].ltr\:-mb-400 {
  margin-bottom: -40rem
}

[dir='ltr'] .ltr\:-ml-400,[dir='ltr'].ltr\:-ml-400 {
  margin-left: -40rem
}

[dir='ltr'] .ltr\:-mt-512,[dir='ltr'].ltr\:-mt-512 {
  margin-top: -51.2rem
}

[dir='ltr'] .ltr\:-mr-512,[dir='ltr'].ltr\:-mr-512 {
  margin-right: -51.2rem
}

[dir='ltr'] .ltr\:-mb-512,[dir='ltr'].ltr\:-mb-512 {
  margin-bottom: -51.2rem
}

[dir='ltr'] .ltr\:-ml-512,[dir='ltr'].ltr\:-ml-512 {
  margin-left: -51.2rem
}

[dir='ltr'] .ltr\:-mt-640,[dir='ltr'].ltr\:-mt-640 {
  margin-top: -64rem
}

[dir='ltr'] .ltr\:-mr-640,[dir='ltr'].ltr\:-mr-640 {
  margin-right: -64rem
}

[dir='ltr'] .ltr\:-mb-640,[dir='ltr'].ltr\:-mb-640 {
  margin-bottom: -64rem
}

[dir='ltr'] .ltr\:-ml-640,[dir='ltr'].ltr\:-ml-640 {
  margin-left: -64rem
}

[dir='ltr'] .ltr\:-mt-xs,[dir='ltr'].ltr\:-mt-xs {
  margin-top: -32rem
}

[dir='ltr'] .ltr\:-mr-xs,[dir='ltr'].ltr\:-mr-xs {
  margin-right: -32rem
}

[dir='ltr'] .ltr\:-mb-xs,[dir='ltr'].ltr\:-mb-xs {
  margin-bottom: -32rem
}

[dir='ltr'] .ltr\:-ml-xs,[dir='ltr'].ltr\:-ml-xs {
  margin-left: -32rem
}

[dir='ltr'] .ltr\:-mt-sm,[dir='ltr'].ltr\:-mt-sm {
  margin-top: -48rem
}

[dir='ltr'] .ltr\:-mr-sm,[dir='ltr'].ltr\:-mr-sm {
  margin-right: -48rem
}

[dir='ltr'] .ltr\:-mb-sm,[dir='ltr'].ltr\:-mb-sm {
  margin-bottom: -48rem
}

[dir='ltr'] .ltr\:-ml-sm,[dir='ltr'].ltr\:-ml-sm {
  margin-left: -48rem
}

[dir='ltr'] .ltr\:-mt-md,[dir='ltr'].ltr\:-mt-md {
  margin-top: -64rem
}

[dir='ltr'] .ltr\:-mr-md,[dir='ltr'].ltr\:-mr-md {
  margin-right: -64rem
}

[dir='ltr'] .ltr\:-mb-md,[dir='ltr'].ltr\:-mb-md {
  margin-bottom: -64rem
}

[dir='ltr'] .ltr\:-ml-md,[dir='ltr'].ltr\:-ml-md {
  margin-left: -64rem
}

[dir='ltr'] .ltr\:-mt-lg,[dir='ltr'].ltr\:-mt-lg {
  margin-top: -80rem
}

[dir='ltr'] .ltr\:-mr-lg,[dir='ltr'].ltr\:-mr-lg {
  margin-right: -80rem
}

[dir='ltr'] .ltr\:-mb-lg,[dir='ltr'].ltr\:-mb-lg {
  margin-bottom: -80rem
}

[dir='ltr'] .ltr\:-ml-lg,[dir='ltr'].ltr\:-ml-lg {
  margin-left: -80rem
}

[dir='ltr'] .ltr\:-mt-xl,[dir='ltr'].ltr\:-mt-xl {
  margin-top: -96rem
}

[dir='ltr'] .ltr\:-mr-xl,[dir='ltr'].ltr\:-mr-xl {
  margin-right: -96rem
}

[dir='ltr'] .ltr\:-mb-xl,[dir='ltr'].ltr\:-mb-xl {
  margin-bottom: -96rem
}

[dir='ltr'] .ltr\:-ml-xl,[dir='ltr'].ltr\:-ml-xl {
  margin-left: -96rem
}

[dir='ltr'] .ltr\:-mt-2xl,[dir='ltr'].ltr\:-mt-2xl {
  margin-top: -112rem
}

[dir='ltr'] .ltr\:-mr-2xl,[dir='ltr'].ltr\:-mr-2xl {
  margin-right: -112rem
}

[dir='ltr'] .ltr\:-mb-2xl,[dir='ltr'].ltr\:-mb-2xl {
  margin-bottom: -112rem
}

[dir='ltr'] .ltr\:-ml-2xl,[dir='ltr'].ltr\:-ml-2xl {
  margin-left: -112rem
}

[dir='ltr'] .ltr\:-mt-3xl,[dir='ltr'].ltr\:-mt-3xl {
  margin-top: -128rem
}

[dir='ltr'] .ltr\:-mr-3xl,[dir='ltr'].ltr\:-mr-3xl {
  margin-right: -128rem
}

[dir='ltr'] .ltr\:-mb-3xl,[dir='ltr'].ltr\:-mb-3xl {
  margin-bottom: -128rem
}

[dir='ltr'] .ltr\:-ml-3xl,[dir='ltr'].ltr\:-ml-3xl {
  margin-left: -128rem
}

[dir='ltr'] .ltr\:-mt-4xl,[dir='ltr'].ltr\:-mt-4xl {
  margin-top: -144rem
}

[dir='ltr'] .ltr\:-mr-4xl,[dir='ltr'].ltr\:-mr-4xl {
  margin-right: -144rem
}

[dir='ltr'] .ltr\:-mb-4xl,[dir='ltr'].ltr\:-mb-4xl {
  margin-bottom: -144rem
}

[dir='ltr'] .ltr\:-ml-4xl,[dir='ltr'].ltr\:-ml-4xl {
  margin-left: -144rem
}

[dir='ltr'] .ltr\:-mt-5xl,[dir='ltr'].ltr\:-mt-5xl {
  margin-top: -160rem
}

[dir='ltr'] .ltr\:-mr-5xl,[dir='ltr'].ltr\:-mr-5xl {
  margin-right: -160rem
}

[dir='ltr'] .ltr\:-mb-5xl,[dir='ltr'].ltr\:-mb-5xl {
  margin-bottom: -160rem
}

[dir='ltr'] .ltr\:-ml-5xl,[dir='ltr'].ltr\:-ml-5xl {
  margin-left: -160rem
}

[dir='ltr'] .ltr\:-mt-px,[dir='ltr'].ltr\:-mt-px {
  margin-top: -1px
}

[dir='ltr'] .ltr\:-mr-px,[dir='ltr'].ltr\:-mr-px {
  margin-right: -1px
}

[dir='ltr'] .ltr\:-mb-px,[dir='ltr'].ltr\:-mb-px {
  margin-bottom: -1px
}

[dir='ltr'] .ltr\:-ml-px,[dir='ltr'].ltr\:-ml-px {
  margin-left: -1px
}

[dir='ltr'] .ltr\:-mt-0\.5,[dir='ltr'].ltr\:-mt-0\.5 {
  margin-top: -0.05rem
}

[dir='ltr'] .ltr\:-mr-0\.5,[dir='ltr'].ltr\:-mr-0\.5 {
  margin-right: -0.05rem
}

[dir='ltr'] .ltr\:-mb-0\.5,[dir='ltr'].ltr\:-mb-0\.5 {
  margin-bottom: -0.05rem
}

[dir='ltr'] .ltr\:-ml-0\.5,[dir='ltr'].ltr\:-ml-0\.5 {
  margin-left: -0.05rem
}

[dir='ltr'] .ltr\:-mt-1\.5,[dir='ltr'].ltr\:-mt-1\.5 {
  margin-top: -0.15rem
}

[dir='ltr'] .ltr\:-mr-1\.5,[dir='ltr'].ltr\:-mr-1\.5 {
  margin-right: -0.15rem
}

[dir='ltr'] .ltr\:-mb-1\.5,[dir='ltr'].ltr\:-mb-1\.5 {
  margin-bottom: -0.15rem
}

[dir='ltr'] .ltr\:-ml-1\.5,[dir='ltr'].ltr\:-ml-1\.5 {
  margin-left: -0.15rem
}

[dir='ltr'] .ltr\:-mt-2\.5,[dir='ltr'].ltr\:-mt-2\.5 {
  margin-top: -0.25rem
}

[dir='ltr'] .ltr\:-mr-2\.5,[dir='ltr'].ltr\:-mr-2\.5 {
  margin-right: -0.25rem
}

[dir='ltr'] .ltr\:-mb-2\.5,[dir='ltr'].ltr\:-mb-2\.5 {
  margin-bottom: -0.25rem
}

[dir='ltr'] .ltr\:-ml-2\.5,[dir='ltr'].ltr\:-ml-2\.5 {
  margin-left: -0.25rem
}

[dir='ltr'] .ltr\:-mt-3\.5,[dir='ltr'].ltr\:-mt-3\.5 {
  margin-top: -0.35rem
}

[dir='ltr'] .ltr\:-mr-3\.5,[dir='ltr'].ltr\:-mr-3\.5 {
  margin-right: -0.35rem
}

[dir='ltr'] .ltr\:-mb-3\.5,[dir='ltr'].ltr\:-mb-3\.5 {
  margin-bottom: -0.35rem
}

[dir='ltr'] .ltr\:-ml-3\.5,[dir='ltr'].ltr\:-ml-3\.5 {
  margin-left: -0.35rem
}

[dir='rtl'] .rtl\:m-0,[dir='rtl'].rtl\:m-0 {
  margin: 0
}

[dir='rtl'] .rtl\:m-1,[dir='rtl'].rtl\:m-1 {
  margin: 0.1rem
}

[dir='rtl'] .rtl\:m-2,[dir='rtl'].rtl\:m-2 {
  margin: 0.2rem
}

[dir='rtl'] .rtl\:m-3,[dir='rtl'].rtl\:m-3 {
  margin: 0.3rem
}

[dir='rtl'] .rtl\:m-4,[dir='rtl'].rtl\:m-4 {
  margin: 0.4rem
}

[dir='rtl'] .rtl\:m-5,[dir='rtl'].rtl\:m-5 {
  margin: 0.5rem
}

[dir='rtl'] .rtl\:m-6,[dir='rtl'].rtl\:m-6 {
  margin: 0.6rem
}

[dir='rtl'] .rtl\:m-7,[dir='rtl'].rtl\:m-7 {
  margin: 0.7rem
}

[dir='rtl'] .rtl\:m-8,[dir='rtl'].rtl\:m-8 {
  margin: 0.8rem
}

[dir='rtl'] .rtl\:m-9,[dir='rtl'].rtl\:m-9 {
  margin: 0.9rem
}

[dir='rtl'] .rtl\:m-10,[dir='rtl'].rtl\:m-10 {
  margin: 1.0rem
}

[dir='rtl'] .rtl\:m-12,[dir='rtl'].rtl\:m-12 {
  margin: 1.2rem
}

[dir='rtl'] .rtl\:m-14,[dir='rtl'].rtl\:m-14 {
  margin: 1.4rem
}

[dir='rtl'] .rtl\:m-16,[dir='rtl'].rtl\:m-16 {
  margin: 1.6rem
}

[dir='rtl'] .rtl\:m-20,[dir='rtl'].rtl\:m-20 {
  margin: 2rem
}

[dir='rtl'] .rtl\:m-24,[dir='rtl'].rtl\:m-24 {
  margin: 2.4rem
}

[dir='rtl'] .rtl\:m-28,[dir='rtl'].rtl\:m-28 {
  margin: 2.8rem
}

[dir='rtl'] .rtl\:m-32,[dir='rtl'].rtl\:m-32 {
  margin: 3.2rem
}

[dir='rtl'] .rtl\:m-36,[dir='rtl'].rtl\:m-36 {
  margin: 3.6rem
}

[dir='rtl'] .rtl\:m-40,[dir='rtl'].rtl\:m-40 {
  margin: 4rem
}

[dir='rtl'] .rtl\:m-44,[dir='rtl'].rtl\:m-44 {
  margin: 4.4rem
}

[dir='rtl'] .rtl\:m-48,[dir='rtl'].rtl\:m-48 {
  margin: 4.8rem
}

[dir='rtl'] .rtl\:m-52,[dir='rtl'].rtl\:m-52 {
  margin: 5.2rem
}

[dir='rtl'] .rtl\:m-56,[dir='rtl'].rtl\:m-56 {
  margin: 5.6rem
}

[dir='rtl'] .rtl\:m-60,[dir='rtl'].rtl\:m-60 {
  margin: 6rem
}

[dir='rtl'] .rtl\:m-64,[dir='rtl'].rtl\:m-64 {
  margin: 6.4rem
}

[dir='rtl'] .rtl\:m-68,[dir='rtl'].rtl\:m-68 {
  margin: 6.8rem
}

[dir='rtl'] .rtl\:m-72,[dir='rtl'].rtl\:m-72 {
  margin: 7.2rem
}

[dir='rtl'] .rtl\:m-76,[dir='rtl'].rtl\:m-76 {
  margin: 7.6rem
}

[dir='rtl'] .rtl\:m-80,[dir='rtl'].rtl\:m-80 {
  margin: 8rem
}

[dir='rtl'] .rtl\:m-84,[dir='rtl'].rtl\:m-84 {
  margin: 8.4rem
}

[dir='rtl'] .rtl\:m-88,[dir='rtl'].rtl\:m-88 {
  margin: 8.8rem
}

[dir='rtl'] .rtl\:m-92,[dir='rtl'].rtl\:m-92 {
  margin: 9.2rem
}

[dir='rtl'] .rtl\:m-96,[dir='rtl'].rtl\:m-96 {
  margin: 9.6rem
}

[dir='rtl'] .rtl\:m-128,[dir='rtl'].rtl\:m-128 {
  margin: 12.8rem
}

[dir='rtl'] .rtl\:m-136,[dir='rtl'].rtl\:m-136 {
  margin: 13.6rem
}

[dir='rtl'] .rtl\:m-160,[dir='rtl'].rtl\:m-160 {
  margin: 16rem
}

[dir='rtl'] .rtl\:m-192,[dir='rtl'].rtl\:m-192 {
  margin: 19.2rem
}

[dir='rtl'] .rtl\:m-200,[dir='rtl'].rtl\:m-200 {
  margin: 20rem
}

[dir='rtl'] .rtl\:m-208,[dir='rtl'].rtl\:m-208 {
  margin: 20.8rem
}

[dir='rtl'] .rtl\:m-216,[dir='rtl'].rtl\:m-216 {
  margin: 21.6rem
}

[dir='rtl'] .rtl\:m-224,[dir='rtl'].rtl\:m-224 {
  margin: 22.4rem
}

[dir='rtl'] .rtl\:m-256,[dir='rtl'].rtl\:m-256 {
  margin: 25.6rem
}

[dir='rtl'] .rtl\:m-288,[dir='rtl'].rtl\:m-288 {
  margin: 28.8rem
}

[dir='rtl'] .rtl\:m-320,[dir='rtl'].rtl\:m-320 {
  margin: 32rem
}

[dir='rtl'] .rtl\:m-360,[dir='rtl'].rtl\:m-360 {
  margin: 36rem
}

[dir='rtl'] .rtl\:m-384,[dir='rtl'].rtl\:m-384 {
  margin: 38.4rem
}

[dir='rtl'] .rtl\:m-400,[dir='rtl'].rtl\:m-400 {
  margin: 40rem
}

[dir='rtl'] .rtl\:m-512,[dir='rtl'].rtl\:m-512 {
  margin: 51.2rem
}

[dir='rtl'] .rtl\:m-640,[dir='rtl'].rtl\:m-640 {
  margin: 64rem
}

[dir='rtl'] .rtl\:m-auto,[dir='rtl'].rtl\:m-auto {
  margin: auto
}

[dir='rtl'] .rtl\:m-xs,[dir='rtl'].rtl\:m-xs {
  margin: 32rem
}

[dir='rtl'] .rtl\:m-sm,[dir='rtl'].rtl\:m-sm {
  margin: 48rem
}

[dir='rtl'] .rtl\:m-md,[dir='rtl'].rtl\:m-md {
  margin: 64rem
}

[dir='rtl'] .rtl\:m-lg,[dir='rtl'].rtl\:m-lg {
  margin: 80rem
}

[dir='rtl'] .rtl\:m-xl,[dir='rtl'].rtl\:m-xl {
  margin: 96rem
}

[dir='rtl'] .rtl\:m-2xl,[dir='rtl'].rtl\:m-2xl {
  margin: 112rem
}

[dir='rtl'] .rtl\:m-3xl,[dir='rtl'].rtl\:m-3xl {
  margin: 128rem
}

[dir='rtl'] .rtl\:m-4xl,[dir='rtl'].rtl\:m-4xl {
  margin: 144rem
}

[dir='rtl'] .rtl\:m-5xl,[dir='rtl'].rtl\:m-5xl {
  margin: 160rem
}

[dir='rtl'] .rtl\:m-px,[dir='rtl'].rtl\:m-px {
  margin: 1px
}

[dir='rtl'] .rtl\:m-0\.5,[dir='rtl'].rtl\:m-0\.5 {
  margin: 0.05rem
}

[dir='rtl'] .rtl\:m-1\.5,[dir='rtl'].rtl\:m-1\.5 {
  margin: 0.15rem
}

[dir='rtl'] .rtl\:m-2\.5,[dir='rtl'].rtl\:m-2\.5 {
  margin: 0.25rem
}

[dir='rtl'] .rtl\:m-3\.5,[dir='rtl'].rtl\:m-3\.5 {
  margin: 0.35rem
}

[dir='rtl'] .rtl\:-m-1,[dir='rtl'].rtl\:-m-1 {
  margin: -0.1rem
}

[dir='rtl'] .rtl\:-m-2,[dir='rtl'].rtl\:-m-2 {
  margin: -0.2rem
}

[dir='rtl'] .rtl\:-m-3,[dir='rtl'].rtl\:-m-3 {
  margin: -0.3rem
}

[dir='rtl'] .rtl\:-m-4,[dir='rtl'].rtl\:-m-4 {
  margin: -0.4rem
}

[dir='rtl'] .rtl\:-m-5,[dir='rtl'].rtl\:-m-5 {
  margin: -0.5rem
}

[dir='rtl'] .rtl\:-m-6,[dir='rtl'].rtl\:-m-6 {
  margin: -0.6rem
}

[dir='rtl'] .rtl\:-m-7,[dir='rtl'].rtl\:-m-7 {
  margin: -0.7rem
}

[dir='rtl'] .rtl\:-m-8,[dir='rtl'].rtl\:-m-8 {
  margin: -0.8rem
}

[dir='rtl'] .rtl\:-m-9,[dir='rtl'].rtl\:-m-9 {
  margin: -0.9rem
}

[dir='rtl'] .rtl\:-m-10,[dir='rtl'].rtl\:-m-10 {
  margin: -1rem
}

[dir='rtl'] .rtl\:-m-12,[dir='rtl'].rtl\:-m-12 {
  margin: -1.2rem
}

[dir='rtl'] .rtl\:-m-14,[dir='rtl'].rtl\:-m-14 {
  margin: -1.4rem
}

[dir='rtl'] .rtl\:-m-16,[dir='rtl'].rtl\:-m-16 {
  margin: -1.6rem
}

[dir='rtl'] .rtl\:-m-20,[dir='rtl'].rtl\:-m-20 {
  margin: -2rem
}

[dir='rtl'] .rtl\:-m-24,[dir='rtl'].rtl\:-m-24 {
  margin: -2.4rem
}

[dir='rtl'] .rtl\:-m-28,[dir='rtl'].rtl\:-m-28 {
  margin: -2.8rem
}

[dir='rtl'] .rtl\:-m-32,[dir='rtl'].rtl\:-m-32 {
  margin: -3.2rem
}

[dir='rtl'] .rtl\:-m-36,[dir='rtl'].rtl\:-m-36 {
  margin: -3.6rem
}

[dir='rtl'] .rtl\:-m-40,[dir='rtl'].rtl\:-m-40 {
  margin: -4rem
}

[dir='rtl'] .rtl\:-m-44,[dir='rtl'].rtl\:-m-44 {
  margin: -4.4rem
}

[dir='rtl'] .rtl\:-m-48,[dir='rtl'].rtl\:-m-48 {
  margin: -4.8rem
}

[dir='rtl'] .rtl\:-m-52,[dir='rtl'].rtl\:-m-52 {
  margin: -5.2rem
}

[dir='rtl'] .rtl\:-m-56,[dir='rtl'].rtl\:-m-56 {
  margin: -5.6rem
}

[dir='rtl'] .rtl\:-m-60,[dir='rtl'].rtl\:-m-60 {
  margin: -6rem
}

[dir='rtl'] .rtl\:-m-64,[dir='rtl'].rtl\:-m-64 {
  margin: -6.4rem
}

[dir='rtl'] .rtl\:-m-68,[dir='rtl'].rtl\:-m-68 {
  margin: -6.8rem
}

[dir='rtl'] .rtl\:-m-72,[dir='rtl'].rtl\:-m-72 {
  margin: -7.2rem
}

[dir='rtl'] .rtl\:-m-76,[dir='rtl'].rtl\:-m-76 {
  margin: -7.6rem
}

[dir='rtl'] .rtl\:-m-80,[dir='rtl'].rtl\:-m-80 {
  margin: -8rem
}

[dir='rtl'] .rtl\:-m-84,[dir='rtl'].rtl\:-m-84 {
  margin: -8.4rem
}

[dir='rtl'] .rtl\:-m-88,[dir='rtl'].rtl\:-m-88 {
  margin: -8.8rem
}

[dir='rtl'] .rtl\:-m-92,[dir='rtl'].rtl\:-m-92 {
  margin: -9.2rem
}

[dir='rtl'] .rtl\:-m-96,[dir='rtl'].rtl\:-m-96 {
  margin: -9.6rem
}

[dir='rtl'] .rtl\:-m-128,[dir='rtl'].rtl\:-m-128 {
  margin: -12.8rem
}

[dir='rtl'] .rtl\:-m-136,[dir='rtl'].rtl\:-m-136 {
  margin: -13.6rem
}

[dir='rtl'] .rtl\:-m-160,[dir='rtl'].rtl\:-m-160 {
  margin: -16rem
}

[dir='rtl'] .rtl\:-m-192,[dir='rtl'].rtl\:-m-192 {
  margin: -19.2rem
}

[dir='rtl'] .rtl\:-m-200,[dir='rtl'].rtl\:-m-200 {
  margin: -20rem
}

[dir='rtl'] .rtl\:-m-208,[dir='rtl'].rtl\:-m-208 {
  margin: -20.8rem
}

[dir='rtl'] .rtl\:-m-216,[dir='rtl'].rtl\:-m-216 {
  margin: -21.6rem
}

[dir='rtl'] .rtl\:-m-224,[dir='rtl'].rtl\:-m-224 {
  margin: -22.4rem
}

[dir='rtl'] .rtl\:-m-256,[dir='rtl'].rtl\:-m-256 {
  margin: -25.6rem
}

[dir='rtl'] .rtl\:-m-288,[dir='rtl'].rtl\:-m-288 {
  margin: -28.8rem
}

[dir='rtl'] .rtl\:-m-320,[dir='rtl'].rtl\:-m-320 {
  margin: -32rem
}

[dir='rtl'] .rtl\:-m-360,[dir='rtl'].rtl\:-m-360 {
  margin: -36rem
}

[dir='rtl'] .rtl\:-m-384,[dir='rtl'].rtl\:-m-384 {
  margin: -38.4rem
}

[dir='rtl'] .rtl\:-m-400,[dir='rtl'].rtl\:-m-400 {
  margin: -40rem
}

[dir='rtl'] .rtl\:-m-512,[dir='rtl'].rtl\:-m-512 {
  margin: -51.2rem
}

[dir='rtl'] .rtl\:-m-640,[dir='rtl'].rtl\:-m-640 {
  margin: -64rem
}

[dir='rtl'] .rtl\:-m-xs,[dir='rtl'].rtl\:-m-xs {
  margin: -32rem
}

[dir='rtl'] .rtl\:-m-sm,[dir='rtl'].rtl\:-m-sm {
  margin: -48rem
}

[dir='rtl'] .rtl\:-m-md,[dir='rtl'].rtl\:-m-md {
  margin: -64rem
}

[dir='rtl'] .rtl\:-m-lg,[dir='rtl'].rtl\:-m-lg {
  margin: -80rem
}

[dir='rtl'] .rtl\:-m-xl,[dir='rtl'].rtl\:-m-xl {
  margin: -96rem
}

[dir='rtl'] .rtl\:-m-2xl,[dir='rtl'].rtl\:-m-2xl {
  margin: -112rem
}

[dir='rtl'] .rtl\:-m-3xl,[dir='rtl'].rtl\:-m-3xl {
  margin: -128rem
}

[dir='rtl'] .rtl\:-m-4xl,[dir='rtl'].rtl\:-m-4xl {
  margin: -144rem
}

[dir='rtl'] .rtl\:-m-5xl,[dir='rtl'].rtl\:-m-5xl {
  margin: -160rem
}

[dir='rtl'] .rtl\:-m-px,[dir='rtl'].rtl\:-m-px {
  margin: -1px
}

[dir='rtl'] .rtl\:-m-0\.5,[dir='rtl'].rtl\:-m-0\.5 {
  margin: -0.05rem
}

[dir='rtl'] .rtl\:-m-1\.5,[dir='rtl'].rtl\:-m-1\.5 {
  margin: -0.15rem
}

[dir='rtl'] .rtl\:-m-2\.5,[dir='rtl'].rtl\:-m-2\.5 {
  margin: -0.25rem
}

[dir='rtl'] .rtl\:-m-3\.5,[dir='rtl'].rtl\:-m-3\.5 {
  margin: -0.35rem
}

[dir='rtl'] .rtl\:my-0,[dir='rtl'].rtl\:my-0 {
  margin-top: 0;
  margin-bottom: 0
}

[dir='rtl'] .rtl\:mx-0,[dir='rtl'].rtl\:mx-0 {
  margin-left: 0;
  margin-right: 0
}

[dir='rtl'] .rtl\:my-1,[dir='rtl'].rtl\:my-1 {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem
}

[dir='rtl'] .rtl\:mx-1,[dir='rtl'].rtl\:mx-1 {
  margin-left: 0.1rem;
  margin-right: 0.1rem
}

[dir='rtl'] .rtl\:my-2,[dir='rtl'].rtl\:my-2 {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem
}

[dir='rtl'] .rtl\:mx-2,[dir='rtl'].rtl\:mx-2 {
  margin-left: 0.2rem;
  margin-right: 0.2rem
}

[dir='rtl'] .rtl\:my-3,[dir='rtl'].rtl\:my-3 {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem
}

[dir='rtl'] .rtl\:mx-3,[dir='rtl'].rtl\:mx-3 {
  margin-left: 0.3rem;
  margin-right: 0.3rem
}

[dir='rtl'] .rtl\:my-4,[dir='rtl'].rtl\:my-4 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem
}

[dir='rtl'] .rtl\:mx-4,[dir='rtl'].rtl\:mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

[dir='rtl'] .rtl\:my-5,[dir='rtl'].rtl\:my-5 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

[dir='rtl'] .rtl\:mx-5,[dir='rtl'].rtl\:mx-5 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

[dir='rtl'] .rtl\:my-6,[dir='rtl'].rtl\:my-6 {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem
}

[dir='rtl'] .rtl\:mx-6,[dir='rtl'].rtl\:mx-6 {
  margin-left: 0.6rem;
  margin-right: 0.6rem
}

[dir='rtl'] .rtl\:my-7,[dir='rtl'].rtl\:my-7 {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem
}

[dir='rtl'] .rtl\:mx-7,[dir='rtl'].rtl\:mx-7 {
  margin-left: 0.7rem;
  margin-right: 0.7rem
}

[dir='rtl'] .rtl\:my-8,[dir='rtl'].rtl\:my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

[dir='rtl'] .rtl\:mx-8,[dir='rtl'].rtl\:mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

[dir='rtl'] .rtl\:my-9,[dir='rtl'].rtl\:my-9 {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem
}

[dir='rtl'] .rtl\:mx-9,[dir='rtl'].rtl\:mx-9 {
  margin-left: 0.9rem;
  margin-right: 0.9rem
}

[dir='rtl'] .rtl\:my-10,[dir='rtl'].rtl\:my-10 {
  margin-top: 1.0rem;
  margin-bottom: 1.0rem
}

[dir='rtl'] .rtl\:mx-10,[dir='rtl'].rtl\:mx-10 {
  margin-left: 1.0rem;
  margin-right: 1.0rem
}

[dir='rtl'] .rtl\:my-12,[dir='rtl'].rtl\:my-12 {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem
}

[dir='rtl'] .rtl\:mx-12,[dir='rtl'].rtl\:mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

[dir='rtl'] .rtl\:my-14,[dir='rtl'].rtl\:my-14 {
  margin-top: 1.4rem;
  margin-bottom: 1.4rem
}

[dir='rtl'] .rtl\:mx-14,[dir='rtl'].rtl\:mx-14 {
  margin-left: 1.4rem;
  margin-right: 1.4rem
}

[dir='rtl'] .rtl\:my-16,[dir='rtl'].rtl\:my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

[dir='rtl'] .rtl\:mx-16,[dir='rtl'].rtl\:mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

[dir='rtl'] .rtl\:my-20,[dir='rtl'].rtl\:my-20 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

[dir='rtl'] .rtl\:mx-20,[dir='rtl'].rtl\:mx-20 {
  margin-left: 2rem;
  margin-right: 2rem
}

[dir='rtl'] .rtl\:my-24,[dir='rtl'].rtl\:my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem
}

[dir='rtl'] .rtl\:mx-24,[dir='rtl'].rtl\:mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem
}

[dir='rtl'] .rtl\:my-28,[dir='rtl'].rtl\:my-28 {
  margin-top: 2.8rem;
  margin-bottom: 2.8rem
}

[dir='rtl'] .rtl\:mx-28,[dir='rtl'].rtl\:mx-28 {
  margin-left: 2.8rem;
  margin-right: 2.8rem
}

[dir='rtl'] .rtl\:my-32,[dir='rtl'].rtl\:my-32 {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem
}

[dir='rtl'] .rtl\:mx-32,[dir='rtl'].rtl\:mx-32 {
  margin-left: 3.2rem;
  margin-right: 3.2rem
}

[dir='rtl'] .rtl\:my-36,[dir='rtl'].rtl\:my-36 {
  margin-top: 3.6rem;
  margin-bottom: 3.6rem
}

[dir='rtl'] .rtl\:mx-36,[dir='rtl'].rtl\:mx-36 {
  margin-left: 3.6rem;
  margin-right: 3.6rem
}

[dir='rtl'] .rtl\:my-40,[dir='rtl'].rtl\:my-40 {
  margin-top: 4rem;
  margin-bottom: 4rem
}

[dir='rtl'] .rtl\:mx-40,[dir='rtl'].rtl\:mx-40 {
  margin-left: 4rem;
  margin-right: 4rem
}

[dir='rtl'] .rtl\:my-44,[dir='rtl'].rtl\:my-44 {
  margin-top: 4.4rem;
  margin-bottom: 4.4rem
}

[dir='rtl'] .rtl\:mx-44,[dir='rtl'].rtl\:mx-44 {
  margin-left: 4.4rem;
  margin-right: 4.4rem
}

[dir='rtl'] .rtl\:my-48,[dir='rtl'].rtl\:my-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem
}

[dir='rtl'] .rtl\:mx-48,[dir='rtl'].rtl\:mx-48 {
  margin-left: 4.8rem;
  margin-right: 4.8rem
}

[dir='rtl'] .rtl\:my-52,[dir='rtl'].rtl\:my-52 {
  margin-top: 5.2rem;
  margin-bottom: 5.2rem
}

[dir='rtl'] .rtl\:mx-52,[dir='rtl'].rtl\:mx-52 {
  margin-left: 5.2rem;
  margin-right: 5.2rem
}

[dir='rtl'] .rtl\:my-56,[dir='rtl'].rtl\:my-56 {
  margin-top: 5.6rem;
  margin-bottom: 5.6rem
}

[dir='rtl'] .rtl\:mx-56,[dir='rtl'].rtl\:mx-56 {
  margin-left: 5.6rem;
  margin-right: 5.6rem
}

[dir='rtl'] .rtl\:my-60,[dir='rtl'].rtl\:my-60 {
  margin-top: 6rem;
  margin-bottom: 6rem
}

[dir='rtl'] .rtl\:mx-60,[dir='rtl'].rtl\:mx-60 {
  margin-left: 6rem;
  margin-right: 6rem
}

[dir='rtl'] .rtl\:my-64,[dir='rtl'].rtl\:my-64 {
  margin-top: 6.4rem;
  margin-bottom: 6.4rem
}

[dir='rtl'] .rtl\:mx-64,[dir='rtl'].rtl\:mx-64 {
  margin-left: 6.4rem;
  margin-right: 6.4rem
}

[dir='rtl'] .rtl\:my-68,[dir='rtl'].rtl\:my-68 {
  margin-top: 6.8rem;
  margin-bottom: 6.8rem
}

[dir='rtl'] .rtl\:mx-68,[dir='rtl'].rtl\:mx-68 {
  margin-left: 6.8rem;
  margin-right: 6.8rem
}

[dir='rtl'] .rtl\:my-72,[dir='rtl'].rtl\:my-72 {
  margin-top: 7.2rem;
  margin-bottom: 7.2rem
}

[dir='rtl'] .rtl\:mx-72,[dir='rtl'].rtl\:mx-72 {
  margin-left: 7.2rem;
  margin-right: 7.2rem
}

[dir='rtl'] .rtl\:my-76,[dir='rtl'].rtl\:my-76 {
  margin-top: 7.6rem;
  margin-bottom: 7.6rem
}

[dir='rtl'] .rtl\:mx-76,[dir='rtl'].rtl\:mx-76 {
  margin-left: 7.6rem;
  margin-right: 7.6rem
}

[dir='rtl'] .rtl\:my-80,[dir='rtl'].rtl\:my-80 {
  margin-top: 8rem;
  margin-bottom: 8rem
}

[dir='rtl'] .rtl\:mx-80,[dir='rtl'].rtl\:mx-80 {
  margin-left: 8rem;
  margin-right: 8rem
}

[dir='rtl'] .rtl\:my-84,[dir='rtl'].rtl\:my-84 {
  margin-top: 8.4rem;
  margin-bottom: 8.4rem
}

[dir='rtl'] .rtl\:mx-84,[dir='rtl'].rtl\:mx-84 {
  margin-left: 8.4rem;
  margin-right: 8.4rem
}

[dir='rtl'] .rtl\:my-88,[dir='rtl'].rtl\:my-88 {
  margin-top: 8.8rem;
  margin-bottom: 8.8rem
}

[dir='rtl'] .rtl\:mx-88,[dir='rtl'].rtl\:mx-88 {
  margin-left: 8.8rem;
  margin-right: 8.8rem
}

[dir='rtl'] .rtl\:my-92,[dir='rtl'].rtl\:my-92 {
  margin-top: 9.2rem;
  margin-bottom: 9.2rem
}

[dir='rtl'] .rtl\:mx-92,[dir='rtl'].rtl\:mx-92 {
  margin-left: 9.2rem;
  margin-right: 9.2rem
}

[dir='rtl'] .rtl\:my-96,[dir='rtl'].rtl\:my-96 {
  margin-top: 9.6rem;
  margin-bottom: 9.6rem
}

[dir='rtl'] .rtl\:mx-96,[dir='rtl'].rtl\:mx-96 {
  margin-left: 9.6rem;
  margin-right: 9.6rem
}

[dir='rtl'] .rtl\:my-128,[dir='rtl'].rtl\:my-128 {
  margin-top: 12.8rem;
  margin-bottom: 12.8rem
}

[dir='rtl'] .rtl\:mx-128,[dir='rtl'].rtl\:mx-128 {
  margin-left: 12.8rem;
  margin-right: 12.8rem
}

[dir='rtl'] .rtl\:my-136,[dir='rtl'].rtl\:my-136 {
  margin-top: 13.6rem;
  margin-bottom: 13.6rem
}

[dir='rtl'] .rtl\:mx-136,[dir='rtl'].rtl\:mx-136 {
  margin-left: 13.6rem;
  margin-right: 13.6rem
}

[dir='rtl'] .rtl\:my-160,[dir='rtl'].rtl\:my-160 {
  margin-top: 16rem;
  margin-bottom: 16rem
}

[dir='rtl'] .rtl\:mx-160,[dir='rtl'].rtl\:mx-160 {
  margin-left: 16rem;
  margin-right: 16rem
}

[dir='rtl'] .rtl\:my-192,[dir='rtl'].rtl\:my-192 {
  margin-top: 19.2rem;
  margin-bottom: 19.2rem
}

[dir='rtl'] .rtl\:mx-192,[dir='rtl'].rtl\:mx-192 {
  margin-left: 19.2rem;
  margin-right: 19.2rem
}

[dir='rtl'] .rtl\:my-200,[dir='rtl'].rtl\:my-200 {
  margin-top: 20rem;
  margin-bottom: 20rem
}

[dir='rtl'] .rtl\:mx-200,[dir='rtl'].rtl\:mx-200 {
  margin-left: 20rem;
  margin-right: 20rem
}

[dir='rtl'] .rtl\:my-208,[dir='rtl'].rtl\:my-208 {
  margin-top: 20.8rem;
  margin-bottom: 20.8rem
}

[dir='rtl'] .rtl\:mx-208,[dir='rtl'].rtl\:mx-208 {
  margin-left: 20.8rem;
  margin-right: 20.8rem
}

[dir='rtl'] .rtl\:my-216,[dir='rtl'].rtl\:my-216 {
  margin-top: 21.6rem;
  margin-bottom: 21.6rem
}

[dir='rtl'] .rtl\:mx-216,[dir='rtl'].rtl\:mx-216 {
  margin-left: 21.6rem;
  margin-right: 21.6rem
}

[dir='rtl'] .rtl\:my-224,[dir='rtl'].rtl\:my-224 {
  margin-top: 22.4rem;
  margin-bottom: 22.4rem
}

[dir='rtl'] .rtl\:mx-224,[dir='rtl'].rtl\:mx-224 {
  margin-left: 22.4rem;
  margin-right: 22.4rem
}

[dir='rtl'] .rtl\:my-256,[dir='rtl'].rtl\:my-256 {
  margin-top: 25.6rem;
  margin-bottom: 25.6rem
}

[dir='rtl'] .rtl\:mx-256,[dir='rtl'].rtl\:mx-256 {
  margin-left: 25.6rem;
  margin-right: 25.6rem
}

[dir='rtl'] .rtl\:my-288,[dir='rtl'].rtl\:my-288 {
  margin-top: 28.8rem;
  margin-bottom: 28.8rem
}

[dir='rtl'] .rtl\:mx-288,[dir='rtl'].rtl\:mx-288 {
  margin-left: 28.8rem;
  margin-right: 28.8rem
}

[dir='rtl'] .rtl\:my-320,[dir='rtl'].rtl\:my-320 {
  margin-top: 32rem;
  margin-bottom: 32rem
}

[dir='rtl'] .rtl\:mx-320,[dir='rtl'].rtl\:mx-320 {
  margin-left: 32rem;
  margin-right: 32rem
}

[dir='rtl'] .rtl\:my-360,[dir='rtl'].rtl\:my-360 {
  margin-top: 36rem;
  margin-bottom: 36rem
}

[dir='rtl'] .rtl\:mx-360,[dir='rtl'].rtl\:mx-360 {
  margin-left: 36rem;
  margin-right: 36rem
}

[dir='rtl'] .rtl\:my-384,[dir='rtl'].rtl\:my-384 {
  margin-top: 38.4rem;
  margin-bottom: 38.4rem
}

[dir='rtl'] .rtl\:mx-384,[dir='rtl'].rtl\:mx-384 {
  margin-left: 38.4rem;
  margin-right: 38.4rem
}

[dir='rtl'] .rtl\:my-400,[dir='rtl'].rtl\:my-400 {
  margin-top: 40rem;
  margin-bottom: 40rem
}

[dir='rtl'] .rtl\:mx-400,[dir='rtl'].rtl\:mx-400 {
  margin-left: 40rem;
  margin-right: 40rem
}

[dir='rtl'] .rtl\:my-512,[dir='rtl'].rtl\:my-512 {
  margin-top: 51.2rem;
  margin-bottom: 51.2rem
}

[dir='rtl'] .rtl\:mx-512,[dir='rtl'].rtl\:mx-512 {
  margin-left: 51.2rem;
  margin-right: 51.2rem
}

[dir='rtl'] .rtl\:my-640,[dir='rtl'].rtl\:my-640 {
  margin-top: 64rem;
  margin-bottom: 64rem
}

[dir='rtl'] .rtl\:mx-640,[dir='rtl'].rtl\:mx-640 {
  margin-left: 64rem;
  margin-right: 64rem
}

[dir='rtl'] .rtl\:my-auto,[dir='rtl'].rtl\:my-auto {
  margin-top: auto;
  margin-bottom: auto
}

[dir='rtl'] .rtl\:mx-auto,[dir='rtl'].rtl\:mx-auto {
  margin-left: auto;
  margin-right: auto
}

[dir='rtl'] .rtl\:my-xs,[dir='rtl'].rtl\:my-xs {
  margin-top: 32rem;
  margin-bottom: 32rem
}

[dir='rtl'] .rtl\:mx-xs,[dir='rtl'].rtl\:mx-xs {
  margin-left: 32rem;
  margin-right: 32rem
}

[dir='rtl'] .rtl\:my-sm,[dir='rtl'].rtl\:my-sm {
  margin-top: 48rem;
  margin-bottom: 48rem
}

[dir='rtl'] .rtl\:mx-sm,[dir='rtl'].rtl\:mx-sm {
  margin-left: 48rem;
  margin-right: 48rem
}

[dir='rtl'] .rtl\:my-md,[dir='rtl'].rtl\:my-md {
  margin-top: 64rem;
  margin-bottom: 64rem
}

[dir='rtl'] .rtl\:mx-md,[dir='rtl'].rtl\:mx-md {
  margin-left: 64rem;
  margin-right: 64rem
}

[dir='rtl'] .rtl\:my-lg,[dir='rtl'].rtl\:my-lg {
  margin-top: 80rem;
  margin-bottom: 80rem
}

[dir='rtl'] .rtl\:mx-lg,[dir='rtl'].rtl\:mx-lg {
  margin-left: 80rem;
  margin-right: 80rem
}

[dir='rtl'] .rtl\:my-xl,[dir='rtl'].rtl\:my-xl {
  margin-top: 96rem;
  margin-bottom: 96rem
}

[dir='rtl'] .rtl\:mx-xl,[dir='rtl'].rtl\:mx-xl {
  margin-left: 96rem;
  margin-right: 96rem
}

[dir='rtl'] .rtl\:my-2xl,[dir='rtl'].rtl\:my-2xl {
  margin-top: 112rem;
  margin-bottom: 112rem
}

[dir='rtl'] .rtl\:mx-2xl,[dir='rtl'].rtl\:mx-2xl {
  margin-left: 112rem;
  margin-right: 112rem
}

[dir='rtl'] .rtl\:my-3xl,[dir='rtl'].rtl\:my-3xl {
  margin-top: 128rem;
  margin-bottom: 128rem
}

[dir='rtl'] .rtl\:mx-3xl,[dir='rtl'].rtl\:mx-3xl {
  margin-left: 128rem;
  margin-right: 128rem
}

[dir='rtl'] .rtl\:my-4xl,[dir='rtl'].rtl\:my-4xl {
  margin-top: 144rem;
  margin-bottom: 144rem
}

[dir='rtl'] .rtl\:mx-4xl,[dir='rtl'].rtl\:mx-4xl {
  margin-left: 144rem;
  margin-right: 144rem
}

[dir='rtl'] .rtl\:my-5xl,[dir='rtl'].rtl\:my-5xl {
  margin-top: 160rem;
  margin-bottom: 160rem
}

[dir='rtl'] .rtl\:mx-5xl,[dir='rtl'].rtl\:mx-5xl {
  margin-left: 160rem;
  margin-right: 160rem
}

[dir='rtl'] .rtl\:my-px,[dir='rtl'].rtl\:my-px {
  margin-top: 1px;
  margin-bottom: 1px
}

[dir='rtl'] .rtl\:mx-px,[dir='rtl'].rtl\:mx-px {
  margin-left: 1px;
  margin-right: 1px
}

[dir='rtl'] .rtl\:my-0\.5,[dir='rtl'].rtl\:my-0\.5 {
  margin-top: 0.05rem;
  margin-bottom: 0.05rem
}

[dir='rtl'] .rtl\:mx-0\.5,[dir='rtl'].rtl\:mx-0\.5 {
  margin-left: 0.05rem;
  margin-right: 0.05rem
}

[dir='rtl'] .rtl\:my-1\.5,[dir='rtl'].rtl\:my-1\.5 {
  margin-top: 0.15rem;
  margin-bottom: 0.15rem
}

[dir='rtl'] .rtl\:mx-1\.5,[dir='rtl'].rtl\:mx-1\.5 {
  margin-left: 0.15rem;
  margin-right: 0.15rem
}

[dir='rtl'] .rtl\:my-2\.5,[dir='rtl'].rtl\:my-2\.5 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

[dir='rtl'] .rtl\:mx-2\.5,[dir='rtl'].rtl\:mx-2\.5 {
  margin-left: 0.25rem;
  margin-right: 0.25rem
}

[dir='rtl'] .rtl\:my-3\.5,[dir='rtl'].rtl\:my-3\.5 {
  margin-top: 0.35rem;
  margin-bottom: 0.35rem
}

[dir='rtl'] .rtl\:mx-3\.5,[dir='rtl'].rtl\:mx-3\.5 {
  margin-left: 0.35rem;
  margin-right: 0.35rem
}

[dir='rtl'] .rtl\:-my-1,[dir='rtl'].rtl\:-my-1 {
  margin-top: -0.1rem;
  margin-bottom: -0.1rem
}

[dir='rtl'] .rtl\:-mx-1,[dir='rtl'].rtl\:-mx-1 {
  margin-left: -0.1rem;
  margin-right: -0.1rem
}

[dir='rtl'] .rtl\:-my-2,[dir='rtl'].rtl\:-my-2 {
  margin-top: -0.2rem;
  margin-bottom: -0.2rem
}

[dir='rtl'] .rtl\:-mx-2,[dir='rtl'].rtl\:-mx-2 {
  margin-left: -0.2rem;
  margin-right: -0.2rem
}

[dir='rtl'] .rtl\:-my-3,[dir='rtl'].rtl\:-my-3 {
  margin-top: -0.3rem;
  margin-bottom: -0.3rem
}

[dir='rtl'] .rtl\:-mx-3,[dir='rtl'].rtl\:-mx-3 {
  margin-left: -0.3rem;
  margin-right: -0.3rem
}

[dir='rtl'] .rtl\:-my-4,[dir='rtl'].rtl\:-my-4 {
  margin-top: -0.4rem;
  margin-bottom: -0.4rem
}

[dir='rtl'] .rtl\:-mx-4,[dir='rtl'].rtl\:-mx-4 {
  margin-left: -0.4rem;
  margin-right: -0.4rem
}

[dir='rtl'] .rtl\:-my-5,[dir='rtl'].rtl\:-my-5 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem
}

[dir='rtl'] .rtl\:-mx-5,[dir='rtl'].rtl\:-mx-5 {
  margin-left: -0.5rem;
  margin-right: -0.5rem
}

[dir='rtl'] .rtl\:-my-6,[dir='rtl'].rtl\:-my-6 {
  margin-top: -0.6rem;
  margin-bottom: -0.6rem
}

[dir='rtl'] .rtl\:-mx-6,[dir='rtl'].rtl\:-mx-6 {
  margin-left: -0.6rem;
  margin-right: -0.6rem
}

[dir='rtl'] .rtl\:-my-7,[dir='rtl'].rtl\:-my-7 {
  margin-top: -0.7rem;
  margin-bottom: -0.7rem
}

[dir='rtl'] .rtl\:-mx-7,[dir='rtl'].rtl\:-mx-7 {
  margin-left: -0.7rem;
  margin-right: -0.7rem
}

[dir='rtl'] .rtl\:-my-8,[dir='rtl'].rtl\:-my-8 {
  margin-top: -0.8rem;
  margin-bottom: -0.8rem
}

[dir='rtl'] .rtl\:-mx-8,[dir='rtl'].rtl\:-mx-8 {
  margin-left: -0.8rem;
  margin-right: -0.8rem
}

[dir='rtl'] .rtl\:-my-9,[dir='rtl'].rtl\:-my-9 {
  margin-top: -0.9rem;
  margin-bottom: -0.9rem
}

[dir='rtl'] .rtl\:-mx-9,[dir='rtl'].rtl\:-mx-9 {
  margin-left: -0.9rem;
  margin-right: -0.9rem
}

[dir='rtl'] .rtl\:-my-10,[dir='rtl'].rtl\:-my-10 {
  margin-top: -1rem;
  margin-bottom: -1rem
}

[dir='rtl'] .rtl\:-mx-10,[dir='rtl'].rtl\:-mx-10 {
  margin-left: -1rem;
  margin-right: -1rem
}

[dir='rtl'] .rtl\:-my-12,[dir='rtl'].rtl\:-my-12 {
  margin-top: -1.2rem;
  margin-bottom: -1.2rem
}

[dir='rtl'] .rtl\:-mx-12,[dir='rtl'].rtl\:-mx-12 {
  margin-left: -1.2rem;
  margin-right: -1.2rem
}

[dir='rtl'] .rtl\:-my-14,[dir='rtl'].rtl\:-my-14 {
  margin-top: -1.4rem;
  margin-bottom: -1.4rem
}

[dir='rtl'] .rtl\:-mx-14,[dir='rtl'].rtl\:-mx-14 {
  margin-left: -1.4rem;
  margin-right: -1.4rem
}

[dir='rtl'] .rtl\:-my-16,[dir='rtl'].rtl\:-my-16 {
  margin-top: -1.6rem;
  margin-bottom: -1.6rem
}

[dir='rtl'] .rtl\:-mx-16,[dir='rtl'].rtl\:-mx-16 {
  margin-left: -1.6rem;
  margin-right: -1.6rem
}

[dir='rtl'] .rtl\:-my-20,[dir='rtl'].rtl\:-my-20 {
  margin-top: -2rem;
  margin-bottom: -2rem
}

[dir='rtl'] .rtl\:-mx-20,[dir='rtl'].rtl\:-mx-20 {
  margin-left: -2rem;
  margin-right: -2rem
}

[dir='rtl'] .rtl\:-my-24,[dir='rtl'].rtl\:-my-24 {
  margin-top: -2.4rem;
  margin-bottom: -2.4rem
}

[dir='rtl'] .rtl\:-mx-24,[dir='rtl'].rtl\:-mx-24 {
  margin-left: -2.4rem;
  margin-right: -2.4rem
}

[dir='rtl'] .rtl\:-my-28,[dir='rtl'].rtl\:-my-28 {
  margin-top: -2.8rem;
  margin-bottom: -2.8rem
}

[dir='rtl'] .rtl\:-mx-28,[dir='rtl'].rtl\:-mx-28 {
  margin-left: -2.8rem;
  margin-right: -2.8rem
}

[dir='rtl'] .rtl\:-my-32,[dir='rtl'].rtl\:-my-32 {
  margin-top: -3.2rem;
  margin-bottom: -3.2rem
}

[dir='rtl'] .rtl\:-mx-32,[dir='rtl'].rtl\:-mx-32 {
  margin-left: -3.2rem;
  margin-right: -3.2rem
}

[dir='rtl'] .rtl\:-my-36,[dir='rtl'].rtl\:-my-36 {
  margin-top: -3.6rem;
  margin-bottom: -3.6rem
}

[dir='rtl'] .rtl\:-mx-36,[dir='rtl'].rtl\:-mx-36 {
  margin-left: -3.6rem;
  margin-right: -3.6rem
}

[dir='rtl'] .rtl\:-my-40,[dir='rtl'].rtl\:-my-40 {
  margin-top: -4rem;
  margin-bottom: -4rem
}

[dir='rtl'] .rtl\:-mx-40,[dir='rtl'].rtl\:-mx-40 {
  margin-left: -4rem;
  margin-right: -4rem
}

[dir='rtl'] .rtl\:-my-44,[dir='rtl'].rtl\:-my-44 {
  margin-top: -4.4rem;
  margin-bottom: -4.4rem
}

[dir='rtl'] .rtl\:-mx-44,[dir='rtl'].rtl\:-mx-44 {
  margin-left: -4.4rem;
  margin-right: -4.4rem
}

[dir='rtl'] .rtl\:-my-48,[dir='rtl'].rtl\:-my-48 {
  margin-top: -4.8rem;
  margin-bottom: -4.8rem
}

[dir='rtl'] .rtl\:-mx-48,[dir='rtl'].rtl\:-mx-48 {
  margin-left: -4.8rem;
  margin-right: -4.8rem
}

[dir='rtl'] .rtl\:-my-52,[dir='rtl'].rtl\:-my-52 {
  margin-top: -5.2rem;
  margin-bottom: -5.2rem
}

[dir='rtl'] .rtl\:-mx-52,[dir='rtl'].rtl\:-mx-52 {
  margin-left: -5.2rem;
  margin-right: -5.2rem
}

[dir='rtl'] .rtl\:-my-56,[dir='rtl'].rtl\:-my-56 {
  margin-top: -5.6rem;
  margin-bottom: -5.6rem
}

[dir='rtl'] .rtl\:-mx-56,[dir='rtl'].rtl\:-mx-56 {
  margin-left: -5.6rem;
  margin-right: -5.6rem
}

[dir='rtl'] .rtl\:-my-60,[dir='rtl'].rtl\:-my-60 {
  margin-top: -6rem;
  margin-bottom: -6rem
}

[dir='rtl'] .rtl\:-mx-60,[dir='rtl'].rtl\:-mx-60 {
  margin-left: -6rem;
  margin-right: -6rem
}

[dir='rtl'] .rtl\:-my-64,[dir='rtl'].rtl\:-my-64 {
  margin-top: -6.4rem;
  margin-bottom: -6.4rem
}

[dir='rtl'] .rtl\:-mx-64,[dir='rtl'].rtl\:-mx-64 {
  margin-left: -6.4rem;
  margin-right: -6.4rem
}

[dir='rtl'] .rtl\:-my-68,[dir='rtl'].rtl\:-my-68 {
  margin-top: -6.8rem;
  margin-bottom: -6.8rem
}

[dir='rtl'] .rtl\:-mx-68,[dir='rtl'].rtl\:-mx-68 {
  margin-left: -6.8rem;
  margin-right: -6.8rem
}

[dir='rtl'] .rtl\:-my-72,[dir='rtl'].rtl\:-my-72 {
  margin-top: -7.2rem;
  margin-bottom: -7.2rem
}

[dir='rtl'] .rtl\:-mx-72,[dir='rtl'].rtl\:-mx-72 {
  margin-left: -7.2rem;
  margin-right: -7.2rem
}

[dir='rtl'] .rtl\:-my-76,[dir='rtl'].rtl\:-my-76 {
  margin-top: -7.6rem;
  margin-bottom: -7.6rem
}

[dir='rtl'] .rtl\:-mx-76,[dir='rtl'].rtl\:-mx-76 {
  margin-left: -7.6rem;
  margin-right: -7.6rem
}

[dir='rtl'] .rtl\:-my-80,[dir='rtl'].rtl\:-my-80 {
  margin-top: -8rem;
  margin-bottom: -8rem
}

[dir='rtl'] .rtl\:-mx-80,[dir='rtl'].rtl\:-mx-80 {
  margin-left: -8rem;
  margin-right: -8rem
}

[dir='rtl'] .rtl\:-my-84,[dir='rtl'].rtl\:-my-84 {
  margin-top: -8.4rem;
  margin-bottom: -8.4rem
}

[dir='rtl'] .rtl\:-mx-84,[dir='rtl'].rtl\:-mx-84 {
  margin-left: -8.4rem;
  margin-right: -8.4rem
}

[dir='rtl'] .rtl\:-my-88,[dir='rtl'].rtl\:-my-88 {
  margin-top: -8.8rem;
  margin-bottom: -8.8rem
}

[dir='rtl'] .rtl\:-mx-88,[dir='rtl'].rtl\:-mx-88 {
  margin-left: -8.8rem;
  margin-right: -8.8rem
}

[dir='rtl'] .rtl\:-my-92,[dir='rtl'].rtl\:-my-92 {
  margin-top: -9.2rem;
  margin-bottom: -9.2rem
}

[dir='rtl'] .rtl\:-mx-92,[dir='rtl'].rtl\:-mx-92 {
  margin-left: -9.2rem;
  margin-right: -9.2rem
}

[dir='rtl'] .rtl\:-my-96,[dir='rtl'].rtl\:-my-96 {
  margin-top: -9.6rem;
  margin-bottom: -9.6rem
}

[dir='rtl'] .rtl\:-mx-96,[dir='rtl'].rtl\:-mx-96 {
  margin-left: -9.6rem;
  margin-right: -9.6rem
}

[dir='rtl'] .rtl\:-my-128,[dir='rtl'].rtl\:-my-128 {
  margin-top: -12.8rem;
  margin-bottom: -12.8rem
}

[dir='rtl'] .rtl\:-mx-128,[dir='rtl'].rtl\:-mx-128 {
  margin-left: -12.8rem;
  margin-right: -12.8rem
}

[dir='rtl'] .rtl\:-my-136,[dir='rtl'].rtl\:-my-136 {
  margin-top: -13.6rem;
  margin-bottom: -13.6rem
}

[dir='rtl'] .rtl\:-mx-136,[dir='rtl'].rtl\:-mx-136 {
  margin-left: -13.6rem;
  margin-right: -13.6rem
}

[dir='rtl'] .rtl\:-my-160,[dir='rtl'].rtl\:-my-160 {
  margin-top: -16rem;
  margin-bottom: -16rem
}

[dir='rtl'] .rtl\:-mx-160,[dir='rtl'].rtl\:-mx-160 {
  margin-left: -16rem;
  margin-right: -16rem
}

[dir='rtl'] .rtl\:-my-192,[dir='rtl'].rtl\:-my-192 {
  margin-top: -19.2rem;
  margin-bottom: -19.2rem
}

[dir='rtl'] .rtl\:-mx-192,[dir='rtl'].rtl\:-mx-192 {
  margin-left: -19.2rem;
  margin-right: -19.2rem
}

[dir='rtl'] .rtl\:-my-200,[dir='rtl'].rtl\:-my-200 {
  margin-top: -20rem;
  margin-bottom: -20rem
}

[dir='rtl'] .rtl\:-mx-200,[dir='rtl'].rtl\:-mx-200 {
  margin-left: -20rem;
  margin-right: -20rem
}

[dir='rtl'] .rtl\:-my-208,[dir='rtl'].rtl\:-my-208 {
  margin-top: -20.8rem;
  margin-bottom: -20.8rem
}

[dir='rtl'] .rtl\:-mx-208,[dir='rtl'].rtl\:-mx-208 {
  margin-left: -20.8rem;
  margin-right: -20.8rem
}

[dir='rtl'] .rtl\:-my-216,[dir='rtl'].rtl\:-my-216 {
  margin-top: -21.6rem;
  margin-bottom: -21.6rem
}

[dir='rtl'] .rtl\:-mx-216,[dir='rtl'].rtl\:-mx-216 {
  margin-left: -21.6rem;
  margin-right: -21.6rem
}

[dir='rtl'] .rtl\:-my-224,[dir='rtl'].rtl\:-my-224 {
  margin-top: -22.4rem;
  margin-bottom: -22.4rem
}

[dir='rtl'] .rtl\:-mx-224,[dir='rtl'].rtl\:-mx-224 {
  margin-left: -22.4rem;
  margin-right: -22.4rem
}

[dir='rtl'] .rtl\:-my-256,[dir='rtl'].rtl\:-my-256 {
  margin-top: -25.6rem;
  margin-bottom: -25.6rem
}

[dir='rtl'] .rtl\:-mx-256,[dir='rtl'].rtl\:-mx-256 {
  margin-left: -25.6rem;
  margin-right: -25.6rem
}

[dir='rtl'] .rtl\:-my-288,[dir='rtl'].rtl\:-my-288 {
  margin-top: -28.8rem;
  margin-bottom: -28.8rem
}

[dir='rtl'] .rtl\:-mx-288,[dir='rtl'].rtl\:-mx-288 {
  margin-left: -28.8rem;
  margin-right: -28.8rem
}

[dir='rtl'] .rtl\:-my-320,[dir='rtl'].rtl\:-my-320 {
  margin-top: -32rem;
  margin-bottom: -32rem
}

[dir='rtl'] .rtl\:-mx-320,[dir='rtl'].rtl\:-mx-320 {
  margin-left: -32rem;
  margin-right: -32rem
}

[dir='rtl'] .rtl\:-my-360,[dir='rtl'].rtl\:-my-360 {
  margin-top: -36rem;
  margin-bottom: -36rem
}

[dir='rtl'] .rtl\:-mx-360,[dir='rtl'].rtl\:-mx-360 {
  margin-left: -36rem;
  margin-right: -36rem
}

[dir='rtl'] .rtl\:-my-384,[dir='rtl'].rtl\:-my-384 {
  margin-top: -38.4rem;
  margin-bottom: -38.4rem
}

[dir='rtl'] .rtl\:-mx-384,[dir='rtl'].rtl\:-mx-384 {
  margin-left: -38.4rem;
  margin-right: -38.4rem
}

[dir='rtl'] .rtl\:-my-400,[dir='rtl'].rtl\:-my-400 {
  margin-top: -40rem;
  margin-bottom: -40rem
}

[dir='rtl'] .rtl\:-mx-400,[dir='rtl'].rtl\:-mx-400 {
  margin-left: -40rem;
  margin-right: -40rem
}

[dir='rtl'] .rtl\:-my-512,[dir='rtl'].rtl\:-my-512 {
  margin-top: -51.2rem;
  margin-bottom: -51.2rem
}

[dir='rtl'] .rtl\:-mx-512,[dir='rtl'].rtl\:-mx-512 {
  margin-left: -51.2rem;
  margin-right: -51.2rem
}

[dir='rtl'] .rtl\:-my-640,[dir='rtl'].rtl\:-my-640 {
  margin-top: -64rem;
  margin-bottom: -64rem
}

[dir='rtl'] .rtl\:-mx-640,[dir='rtl'].rtl\:-mx-640 {
  margin-left: -64rem;
  margin-right: -64rem
}

[dir='rtl'] .rtl\:-my-xs,[dir='rtl'].rtl\:-my-xs {
  margin-top: -32rem;
  margin-bottom: -32rem
}

[dir='rtl'] .rtl\:-mx-xs,[dir='rtl'].rtl\:-mx-xs {
  margin-left: -32rem;
  margin-right: -32rem
}

[dir='rtl'] .rtl\:-my-sm,[dir='rtl'].rtl\:-my-sm {
  margin-top: -48rem;
  margin-bottom: -48rem
}

[dir='rtl'] .rtl\:-mx-sm,[dir='rtl'].rtl\:-mx-sm {
  margin-left: -48rem;
  margin-right: -48rem
}

[dir='rtl'] .rtl\:-my-md,[dir='rtl'].rtl\:-my-md {
  margin-top: -64rem;
  margin-bottom: -64rem
}

[dir='rtl'] .rtl\:-mx-md,[dir='rtl'].rtl\:-mx-md {
  margin-left: -64rem;
  margin-right: -64rem
}

[dir='rtl'] .rtl\:-my-lg,[dir='rtl'].rtl\:-my-lg {
  margin-top: -80rem;
  margin-bottom: -80rem
}

[dir='rtl'] .rtl\:-mx-lg,[dir='rtl'].rtl\:-mx-lg {
  margin-left: -80rem;
  margin-right: -80rem
}

[dir='rtl'] .rtl\:-my-xl,[dir='rtl'].rtl\:-my-xl {
  margin-top: -96rem;
  margin-bottom: -96rem
}

[dir='rtl'] .rtl\:-mx-xl,[dir='rtl'].rtl\:-mx-xl {
  margin-left: -96rem;
  margin-right: -96rem
}

[dir='rtl'] .rtl\:-my-2xl,[dir='rtl'].rtl\:-my-2xl {
  margin-top: -112rem;
  margin-bottom: -112rem
}

[dir='rtl'] .rtl\:-mx-2xl,[dir='rtl'].rtl\:-mx-2xl {
  margin-left: -112rem;
  margin-right: -112rem
}

[dir='rtl'] .rtl\:-my-3xl,[dir='rtl'].rtl\:-my-3xl {
  margin-top: -128rem;
  margin-bottom: -128rem
}

[dir='rtl'] .rtl\:-mx-3xl,[dir='rtl'].rtl\:-mx-3xl {
  margin-left: -128rem;
  margin-right: -128rem
}

[dir='rtl'] .rtl\:-my-4xl,[dir='rtl'].rtl\:-my-4xl {
  margin-top: -144rem;
  margin-bottom: -144rem
}

[dir='rtl'] .rtl\:-mx-4xl,[dir='rtl'].rtl\:-mx-4xl {
  margin-left: -144rem;
  margin-right: -144rem
}

[dir='rtl'] .rtl\:-my-5xl,[dir='rtl'].rtl\:-my-5xl {
  margin-top: -160rem;
  margin-bottom: -160rem
}

[dir='rtl'] .rtl\:-mx-5xl,[dir='rtl'].rtl\:-mx-5xl {
  margin-left: -160rem;
  margin-right: -160rem
}

[dir='rtl'] .rtl\:-my-px,[dir='rtl'].rtl\:-my-px {
  margin-top: -1px;
  margin-bottom: -1px
}

[dir='rtl'] .rtl\:-mx-px,[dir='rtl'].rtl\:-mx-px {
  margin-left: -1px;
  margin-right: -1px
}

[dir='rtl'] .rtl\:-my-0\.5,[dir='rtl'].rtl\:-my-0\.5 {
  margin-top: -0.05rem;
  margin-bottom: -0.05rem
}

[dir='rtl'] .rtl\:-mx-0\.5,[dir='rtl'].rtl\:-mx-0\.5 {
  margin-left: -0.05rem;
  margin-right: -0.05rem
}

[dir='rtl'] .rtl\:-my-1\.5,[dir='rtl'].rtl\:-my-1\.5 {
  margin-top: -0.15rem;
  margin-bottom: -0.15rem
}

[dir='rtl'] .rtl\:-mx-1\.5,[dir='rtl'].rtl\:-mx-1\.5 {
  margin-left: -0.15rem;
  margin-right: -0.15rem
}

[dir='rtl'] .rtl\:-my-2\.5,[dir='rtl'].rtl\:-my-2\.5 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem
}

[dir='rtl'] .rtl\:-mx-2\.5,[dir='rtl'].rtl\:-mx-2\.5 {
  margin-left: -0.25rem;
  margin-right: -0.25rem
}

[dir='rtl'] .rtl\:-my-3\.5,[dir='rtl'].rtl\:-my-3\.5 {
  margin-top: -0.35rem;
  margin-bottom: -0.35rem
}

[dir='rtl'] .rtl\:-mx-3\.5,[dir='rtl'].rtl\:-mx-3\.5 {
  margin-left: -0.35rem;
  margin-right: -0.35rem
}

[dir='rtl'] .rtl\:mt-0,[dir='rtl'].rtl\:mt-0 {
  margin-top: 0
}

[dir='rtl'] .rtl\:mr-0,[dir='rtl'].rtl\:mr-0 {
  margin-right: 0
}

[dir='rtl'] .rtl\:mb-0,[dir='rtl'].rtl\:mb-0 {
  margin-bottom: 0
}

[dir='rtl'] .rtl\:ml-0,[dir='rtl'].rtl\:ml-0 {
  margin-left: 0
}

[dir='rtl'] .rtl\:mt-1,[dir='rtl'].rtl\:mt-1 {
  margin-top: 0.1rem
}

[dir='rtl'] .rtl\:mr-1,[dir='rtl'].rtl\:mr-1 {
  margin-right: 0.1rem
}

[dir='rtl'] .rtl\:mb-1,[dir='rtl'].rtl\:mb-1 {
  margin-bottom: 0.1rem
}

[dir='rtl'] .rtl\:ml-1,[dir='rtl'].rtl\:ml-1 {
  margin-left: 0.1rem
}

[dir='rtl'] .rtl\:mt-2,[dir='rtl'].rtl\:mt-2 {
  margin-top: 0.2rem
}

[dir='rtl'] .rtl\:mr-2,[dir='rtl'].rtl\:mr-2 {
  margin-right: 0.2rem
}

[dir='rtl'] .rtl\:mb-2,[dir='rtl'].rtl\:mb-2 {
  margin-bottom: 0.2rem
}

[dir='rtl'] .rtl\:ml-2,[dir='rtl'].rtl\:ml-2 {
  margin-left: 0.2rem
}

[dir='rtl'] .rtl\:mt-3,[dir='rtl'].rtl\:mt-3 {
  margin-top: 0.3rem
}

[dir='rtl'] .rtl\:mr-3,[dir='rtl'].rtl\:mr-3 {
  margin-right: 0.3rem
}

[dir='rtl'] .rtl\:mb-3,[dir='rtl'].rtl\:mb-3 {
  margin-bottom: 0.3rem
}

[dir='rtl'] .rtl\:ml-3,[dir='rtl'].rtl\:ml-3 {
  margin-left: 0.3rem
}

[dir='rtl'] .rtl\:mt-4,[dir='rtl'].rtl\:mt-4 {
  margin-top: 0.4rem
}

[dir='rtl'] .rtl\:mr-4,[dir='rtl'].rtl\:mr-4 {
  margin-right: 0.4rem
}

[dir='rtl'] .rtl\:mb-4,[dir='rtl'].rtl\:mb-4 {
  margin-bottom: 0.4rem
}

[dir='rtl'] .rtl\:ml-4,[dir='rtl'].rtl\:ml-4 {
  margin-left: 0.4rem
}

[dir='rtl'] .rtl\:mt-5,[dir='rtl'].rtl\:mt-5 {
  margin-top: 0.5rem
}

[dir='rtl'] .rtl\:mr-5,[dir='rtl'].rtl\:mr-5 {
  margin-right: 0.5rem
}

[dir='rtl'] .rtl\:mb-5,[dir='rtl'].rtl\:mb-5 {
  margin-bottom: 0.5rem
}

[dir='rtl'] .rtl\:ml-5,[dir='rtl'].rtl\:ml-5 {
  margin-left: 0.5rem
}

[dir='rtl'] .rtl\:mt-6,[dir='rtl'].rtl\:mt-6 {
  margin-top: 0.6rem
}

[dir='rtl'] .rtl\:mr-6,[dir='rtl'].rtl\:mr-6 {
  margin-right: 0.6rem
}

[dir='rtl'] .rtl\:mb-6,[dir='rtl'].rtl\:mb-6 {
  margin-bottom: 0.6rem
}

[dir='rtl'] .rtl\:ml-6,[dir='rtl'].rtl\:ml-6 {
  margin-left: 0.6rem
}

[dir='rtl'] .rtl\:mt-7,[dir='rtl'].rtl\:mt-7 {
  margin-top: 0.7rem
}

[dir='rtl'] .rtl\:mr-7,[dir='rtl'].rtl\:mr-7 {
  margin-right: 0.7rem
}

[dir='rtl'] .rtl\:mb-7,[dir='rtl'].rtl\:mb-7 {
  margin-bottom: 0.7rem
}

[dir='rtl'] .rtl\:ml-7,[dir='rtl'].rtl\:ml-7 {
  margin-left: 0.7rem
}

[dir='rtl'] .rtl\:mt-8,[dir='rtl'].rtl\:mt-8 {
  margin-top: 0.8rem
}

[dir='rtl'] .rtl\:mr-8,[dir='rtl'].rtl\:mr-8 {
  margin-right: 0.8rem
}

[dir='rtl'] .rtl\:mb-8,[dir='rtl'].rtl\:mb-8 {
  margin-bottom: 0.8rem
}

[dir='rtl'] .rtl\:ml-8,[dir='rtl'].rtl\:ml-8 {
  margin-left: 0.8rem
}

[dir='rtl'] .rtl\:mt-9,[dir='rtl'].rtl\:mt-9 {
  margin-top: 0.9rem
}

[dir='rtl'] .rtl\:mr-9,[dir='rtl'].rtl\:mr-9 {
  margin-right: 0.9rem
}

[dir='rtl'] .rtl\:mb-9,[dir='rtl'].rtl\:mb-9 {
  margin-bottom: 0.9rem
}

[dir='rtl'] .rtl\:ml-9,[dir='rtl'].rtl\:ml-9 {
  margin-left: 0.9rem
}

[dir='rtl'] .rtl\:mt-10,[dir='rtl'].rtl\:mt-10 {
  margin-top: 1.0rem
}

[dir='rtl'] .rtl\:mr-10,[dir='rtl'].rtl\:mr-10 {
  margin-right: 1.0rem
}

[dir='rtl'] .rtl\:mb-10,[dir='rtl'].rtl\:mb-10 {
  margin-bottom: 1.0rem
}

[dir='rtl'] .rtl\:ml-10,[dir='rtl'].rtl\:ml-10 {
  margin-left: 1.0rem
}

[dir='rtl'] .rtl\:mt-12,[dir='rtl'].rtl\:mt-12 {
  margin-top: 1.2rem
}

[dir='rtl'] .rtl\:mr-12,[dir='rtl'].rtl\:mr-12 {
  margin-right: 1.2rem
}

[dir='rtl'] .rtl\:mb-12,[dir='rtl'].rtl\:mb-12 {
  margin-bottom: 1.2rem
}

[dir='rtl'] .rtl\:ml-12,[dir='rtl'].rtl\:ml-12 {
  margin-left: 1.2rem
}

[dir='rtl'] .rtl\:mt-14,[dir='rtl'].rtl\:mt-14 {
  margin-top: 1.4rem
}

[dir='rtl'] .rtl\:mr-14,[dir='rtl'].rtl\:mr-14 {
  margin-right: 1.4rem
}

[dir='rtl'] .rtl\:mb-14,[dir='rtl'].rtl\:mb-14 {
  margin-bottom: 1.4rem
}

[dir='rtl'] .rtl\:ml-14,[dir='rtl'].rtl\:ml-14 {
  margin-left: 1.4rem
}

[dir='rtl'] .rtl\:mt-16,[dir='rtl'].rtl\:mt-16 {
  margin-top: 1.6rem
}

[dir='rtl'] .rtl\:mr-16,[dir='rtl'].rtl\:mr-16 {
  margin-right: 1.6rem
}

[dir='rtl'] .rtl\:mb-16,[dir='rtl'].rtl\:mb-16 {
  margin-bottom: 1.6rem
}

[dir='rtl'] .rtl\:ml-16,[dir='rtl'].rtl\:ml-16 {
  margin-left: 1.6rem
}

[dir='rtl'] .rtl\:mt-20,[dir='rtl'].rtl\:mt-20 {
  margin-top: 2rem
}

[dir='rtl'] .rtl\:mr-20,[dir='rtl'].rtl\:mr-20 {
  margin-right: 2rem
}

[dir='rtl'] .rtl\:mb-20,[dir='rtl'].rtl\:mb-20 {
  margin-bottom: 2rem
}

[dir='rtl'] .rtl\:ml-20,[dir='rtl'].rtl\:ml-20 {
  margin-left: 2rem
}

[dir='rtl'] .rtl\:mt-24,[dir='rtl'].rtl\:mt-24 {
  margin-top: 2.4rem
}

[dir='rtl'] .rtl\:mr-24,[dir='rtl'].rtl\:mr-24 {
  margin-right: 2.4rem
}

[dir='rtl'] .rtl\:mb-24,[dir='rtl'].rtl\:mb-24 {
  margin-bottom: 2.4rem
}

[dir='rtl'] .rtl\:ml-24,[dir='rtl'].rtl\:ml-24 {
  margin-left: 2.4rem
}

[dir='rtl'] .rtl\:mt-28,[dir='rtl'].rtl\:mt-28 {
  margin-top: 2.8rem
}

[dir='rtl'] .rtl\:mr-28,[dir='rtl'].rtl\:mr-28 {
  margin-right: 2.8rem
}

[dir='rtl'] .rtl\:mb-28,[dir='rtl'].rtl\:mb-28 {
  margin-bottom: 2.8rem
}

[dir='rtl'] .rtl\:ml-28,[dir='rtl'].rtl\:ml-28 {
  margin-left: 2.8rem
}

[dir='rtl'] .rtl\:mt-32,[dir='rtl'].rtl\:mt-32 {
  margin-top: 3.2rem
}

[dir='rtl'] .rtl\:mr-32,[dir='rtl'].rtl\:mr-32 {
  margin-right: 3.2rem
}

[dir='rtl'] .rtl\:mb-32,[dir='rtl'].rtl\:mb-32 {
  margin-bottom: 3.2rem
}

[dir='rtl'] .rtl\:ml-32,[dir='rtl'].rtl\:ml-32 {
  margin-left: 3.2rem
}

[dir='rtl'] .rtl\:mt-36,[dir='rtl'].rtl\:mt-36 {
  margin-top: 3.6rem
}

[dir='rtl'] .rtl\:mr-36,[dir='rtl'].rtl\:mr-36 {
  margin-right: 3.6rem
}

[dir='rtl'] .rtl\:mb-36,[dir='rtl'].rtl\:mb-36 {
  margin-bottom: 3.6rem
}

[dir='rtl'] .rtl\:ml-36,[dir='rtl'].rtl\:ml-36 {
  margin-left: 3.6rem
}

[dir='rtl'] .rtl\:mt-40,[dir='rtl'].rtl\:mt-40 {
  margin-top: 4rem
}

[dir='rtl'] .rtl\:mr-40,[dir='rtl'].rtl\:mr-40 {
  margin-right: 4rem
}

[dir='rtl'] .rtl\:mb-40,[dir='rtl'].rtl\:mb-40 {
  margin-bottom: 4rem
}

[dir='rtl'] .rtl\:ml-40,[dir='rtl'].rtl\:ml-40 {
  margin-left: 4rem
}

[dir='rtl'] .rtl\:mt-44,[dir='rtl'].rtl\:mt-44 {
  margin-top: 4.4rem
}

[dir='rtl'] .rtl\:mr-44,[dir='rtl'].rtl\:mr-44 {
  margin-right: 4.4rem
}

[dir='rtl'] .rtl\:mb-44,[dir='rtl'].rtl\:mb-44 {
  margin-bottom: 4.4rem
}

[dir='rtl'] .rtl\:ml-44,[dir='rtl'].rtl\:ml-44 {
  margin-left: 4.4rem
}

[dir='rtl'] .rtl\:mt-48,[dir='rtl'].rtl\:mt-48 {
  margin-top: 4.8rem
}

[dir='rtl'] .rtl\:mr-48,[dir='rtl'].rtl\:mr-48 {
  margin-right: 4.8rem
}

[dir='rtl'] .rtl\:mb-48,[dir='rtl'].rtl\:mb-48 {
  margin-bottom: 4.8rem
}

[dir='rtl'] .rtl\:ml-48,[dir='rtl'].rtl\:ml-48 {
  margin-left: 4.8rem
}

[dir='rtl'] .rtl\:mt-52,[dir='rtl'].rtl\:mt-52 {
  margin-top: 5.2rem
}

[dir='rtl'] .rtl\:mr-52,[dir='rtl'].rtl\:mr-52 {
  margin-right: 5.2rem
}

[dir='rtl'] .rtl\:mb-52,[dir='rtl'].rtl\:mb-52 {
  margin-bottom: 5.2rem
}

[dir='rtl'] .rtl\:ml-52,[dir='rtl'].rtl\:ml-52 {
  margin-left: 5.2rem
}

[dir='rtl'] .rtl\:mt-56,[dir='rtl'].rtl\:mt-56 {
  margin-top: 5.6rem
}

[dir='rtl'] .rtl\:mr-56,[dir='rtl'].rtl\:mr-56 {
  margin-right: 5.6rem
}

[dir='rtl'] .rtl\:mb-56,[dir='rtl'].rtl\:mb-56 {
  margin-bottom: 5.6rem
}

[dir='rtl'] .rtl\:ml-56,[dir='rtl'].rtl\:ml-56 {
  margin-left: 5.6rem
}

[dir='rtl'] .rtl\:mt-60,[dir='rtl'].rtl\:mt-60 {
  margin-top: 6rem
}

[dir='rtl'] .rtl\:mr-60,[dir='rtl'].rtl\:mr-60 {
  margin-right: 6rem
}

[dir='rtl'] .rtl\:mb-60,[dir='rtl'].rtl\:mb-60 {
  margin-bottom: 6rem
}

[dir='rtl'] .rtl\:ml-60,[dir='rtl'].rtl\:ml-60 {
  margin-left: 6rem
}

[dir='rtl'] .rtl\:mt-64,[dir='rtl'].rtl\:mt-64 {
  margin-top: 6.4rem
}

[dir='rtl'] .rtl\:mr-64,[dir='rtl'].rtl\:mr-64 {
  margin-right: 6.4rem
}

[dir='rtl'] .rtl\:mb-64,[dir='rtl'].rtl\:mb-64 {
  margin-bottom: 6.4rem
}

[dir='rtl'] .rtl\:ml-64,[dir='rtl'].rtl\:ml-64 {
  margin-left: 6.4rem
}

[dir='rtl'] .rtl\:mt-68,[dir='rtl'].rtl\:mt-68 {
  margin-top: 6.8rem
}

[dir='rtl'] .rtl\:mr-68,[dir='rtl'].rtl\:mr-68 {
  margin-right: 6.8rem
}

[dir='rtl'] .rtl\:mb-68,[dir='rtl'].rtl\:mb-68 {
  margin-bottom: 6.8rem
}

[dir='rtl'] .rtl\:ml-68,[dir='rtl'].rtl\:ml-68 {
  margin-left: 6.8rem
}

[dir='rtl'] .rtl\:mt-72,[dir='rtl'].rtl\:mt-72 {
  margin-top: 7.2rem
}

[dir='rtl'] .rtl\:mr-72,[dir='rtl'].rtl\:mr-72 {
  margin-right: 7.2rem
}

[dir='rtl'] .rtl\:mb-72,[dir='rtl'].rtl\:mb-72 {
  margin-bottom: 7.2rem
}

[dir='rtl'] .rtl\:ml-72,[dir='rtl'].rtl\:ml-72 {
  margin-left: 7.2rem
}

[dir='rtl'] .rtl\:mt-76,[dir='rtl'].rtl\:mt-76 {
  margin-top: 7.6rem
}

[dir='rtl'] .rtl\:mr-76,[dir='rtl'].rtl\:mr-76 {
  margin-right: 7.6rem
}

[dir='rtl'] .rtl\:mb-76,[dir='rtl'].rtl\:mb-76 {
  margin-bottom: 7.6rem
}

[dir='rtl'] .rtl\:ml-76,[dir='rtl'].rtl\:ml-76 {
  margin-left: 7.6rem
}

[dir='rtl'] .rtl\:mt-80,[dir='rtl'].rtl\:mt-80 {
  margin-top: 8rem
}

[dir='rtl'] .rtl\:mr-80,[dir='rtl'].rtl\:mr-80 {
  margin-right: 8rem
}

[dir='rtl'] .rtl\:mb-80,[dir='rtl'].rtl\:mb-80 {
  margin-bottom: 8rem
}

[dir='rtl'] .rtl\:ml-80,[dir='rtl'].rtl\:ml-80 {
  margin-left: 8rem
}

[dir='rtl'] .rtl\:mt-84,[dir='rtl'].rtl\:mt-84 {
  margin-top: 8.4rem
}

[dir='rtl'] .rtl\:mr-84,[dir='rtl'].rtl\:mr-84 {
  margin-right: 8.4rem
}

[dir='rtl'] .rtl\:mb-84,[dir='rtl'].rtl\:mb-84 {
  margin-bottom: 8.4rem
}

[dir='rtl'] .rtl\:ml-84,[dir='rtl'].rtl\:ml-84 {
  margin-left: 8.4rem
}

[dir='rtl'] .rtl\:mt-88,[dir='rtl'].rtl\:mt-88 {
  margin-top: 8.8rem
}

[dir='rtl'] .rtl\:mr-88,[dir='rtl'].rtl\:mr-88 {
  margin-right: 8.8rem
}

[dir='rtl'] .rtl\:mb-88,[dir='rtl'].rtl\:mb-88 {
  margin-bottom: 8.8rem
}

[dir='rtl'] .rtl\:ml-88,[dir='rtl'].rtl\:ml-88 {
  margin-left: 8.8rem
}

[dir='rtl'] .rtl\:mt-92,[dir='rtl'].rtl\:mt-92 {
  margin-top: 9.2rem
}

[dir='rtl'] .rtl\:mr-92,[dir='rtl'].rtl\:mr-92 {
  margin-right: 9.2rem
}

[dir='rtl'] .rtl\:mb-92,[dir='rtl'].rtl\:mb-92 {
  margin-bottom: 9.2rem
}

[dir='rtl'] .rtl\:ml-92,[dir='rtl'].rtl\:ml-92 {
  margin-left: 9.2rem
}

[dir='rtl'] .rtl\:mt-96,[dir='rtl'].rtl\:mt-96 {
  margin-top: 9.6rem
}

[dir='rtl'] .rtl\:mr-96,[dir='rtl'].rtl\:mr-96 {
  margin-right: 9.6rem
}

[dir='rtl'] .rtl\:mb-96,[dir='rtl'].rtl\:mb-96 {
  margin-bottom: 9.6rem
}

[dir='rtl'] .rtl\:ml-96,[dir='rtl'].rtl\:ml-96 {
  margin-left: 9.6rem
}

[dir='rtl'] .rtl\:mt-128,[dir='rtl'].rtl\:mt-128 {
  margin-top: 12.8rem
}

[dir='rtl'] .rtl\:mr-128,[dir='rtl'].rtl\:mr-128 {
  margin-right: 12.8rem
}

[dir='rtl'] .rtl\:mb-128,[dir='rtl'].rtl\:mb-128 {
  margin-bottom: 12.8rem
}

[dir='rtl'] .rtl\:ml-128,[dir='rtl'].rtl\:ml-128 {
  margin-left: 12.8rem
}

[dir='rtl'] .rtl\:mt-136,[dir='rtl'].rtl\:mt-136 {
  margin-top: 13.6rem
}

[dir='rtl'] .rtl\:mr-136,[dir='rtl'].rtl\:mr-136 {
  margin-right: 13.6rem
}

[dir='rtl'] .rtl\:mb-136,[dir='rtl'].rtl\:mb-136 {
  margin-bottom: 13.6rem
}

[dir='rtl'] .rtl\:ml-136,[dir='rtl'].rtl\:ml-136 {
  margin-left: 13.6rem
}

[dir='rtl'] .rtl\:mt-160,[dir='rtl'].rtl\:mt-160 {
  margin-top: 16rem
}

[dir='rtl'] .rtl\:mr-160,[dir='rtl'].rtl\:mr-160 {
  margin-right: 16rem
}

[dir='rtl'] .rtl\:mb-160,[dir='rtl'].rtl\:mb-160 {
  margin-bottom: 16rem
}

[dir='rtl'] .rtl\:ml-160,[dir='rtl'].rtl\:ml-160 {
  margin-left: 16rem
}

[dir='rtl'] .rtl\:mt-192,[dir='rtl'].rtl\:mt-192 {
  margin-top: 19.2rem
}

[dir='rtl'] .rtl\:mr-192,[dir='rtl'].rtl\:mr-192 {
  margin-right: 19.2rem
}

[dir='rtl'] .rtl\:mb-192,[dir='rtl'].rtl\:mb-192 {
  margin-bottom: 19.2rem
}

[dir='rtl'] .rtl\:ml-192,[dir='rtl'].rtl\:ml-192 {
  margin-left: 19.2rem
}

[dir='rtl'] .rtl\:mt-200,[dir='rtl'].rtl\:mt-200 {
  margin-top: 20rem
}

[dir='rtl'] .rtl\:mr-200,[dir='rtl'].rtl\:mr-200 {
  margin-right: 20rem
}

[dir='rtl'] .rtl\:mb-200,[dir='rtl'].rtl\:mb-200 {
  margin-bottom: 20rem
}

[dir='rtl'] .rtl\:ml-200,[dir='rtl'].rtl\:ml-200 {
  margin-left: 20rem
}

[dir='rtl'] .rtl\:mt-208,[dir='rtl'].rtl\:mt-208 {
  margin-top: 20.8rem
}

[dir='rtl'] .rtl\:mr-208,[dir='rtl'].rtl\:mr-208 {
  margin-right: 20.8rem
}

[dir='rtl'] .rtl\:mb-208,[dir='rtl'].rtl\:mb-208 {
  margin-bottom: 20.8rem
}

[dir='rtl'] .rtl\:ml-208,[dir='rtl'].rtl\:ml-208 {
  margin-left: 20.8rem
}

[dir='rtl'] .rtl\:mt-216,[dir='rtl'].rtl\:mt-216 {
  margin-top: 21.6rem
}

[dir='rtl'] .rtl\:mr-216,[dir='rtl'].rtl\:mr-216 {
  margin-right: 21.6rem
}

[dir='rtl'] .rtl\:mb-216,[dir='rtl'].rtl\:mb-216 {
  margin-bottom: 21.6rem
}

[dir='rtl'] .rtl\:ml-216,[dir='rtl'].rtl\:ml-216 {
  margin-left: 21.6rem
}

[dir='rtl'] .rtl\:mt-224,[dir='rtl'].rtl\:mt-224 {
  margin-top: 22.4rem
}

[dir='rtl'] .rtl\:mr-224,[dir='rtl'].rtl\:mr-224 {
  margin-right: 22.4rem
}

[dir='rtl'] .rtl\:mb-224,[dir='rtl'].rtl\:mb-224 {
  margin-bottom: 22.4rem
}

[dir='rtl'] .rtl\:ml-224,[dir='rtl'].rtl\:ml-224 {
  margin-left: 22.4rem
}

[dir='rtl'] .rtl\:mt-256,[dir='rtl'].rtl\:mt-256 {
  margin-top: 25.6rem
}

[dir='rtl'] .rtl\:mr-256,[dir='rtl'].rtl\:mr-256 {
  margin-right: 25.6rem
}

[dir='rtl'] .rtl\:mb-256,[dir='rtl'].rtl\:mb-256 {
  margin-bottom: 25.6rem
}

[dir='rtl'] .rtl\:ml-256,[dir='rtl'].rtl\:ml-256 {
  margin-left: 25.6rem
}

[dir='rtl'] .rtl\:mt-288,[dir='rtl'].rtl\:mt-288 {
  margin-top: 28.8rem
}

[dir='rtl'] .rtl\:mr-288,[dir='rtl'].rtl\:mr-288 {
  margin-right: 28.8rem
}

[dir='rtl'] .rtl\:mb-288,[dir='rtl'].rtl\:mb-288 {
  margin-bottom: 28.8rem
}

[dir='rtl'] .rtl\:ml-288,[dir='rtl'].rtl\:ml-288 {
  margin-left: 28.8rem
}

[dir='rtl'] .rtl\:mt-320,[dir='rtl'].rtl\:mt-320 {
  margin-top: 32rem
}

[dir='rtl'] .rtl\:mr-320,[dir='rtl'].rtl\:mr-320 {
  margin-right: 32rem
}

[dir='rtl'] .rtl\:mb-320,[dir='rtl'].rtl\:mb-320 {
  margin-bottom: 32rem
}

[dir='rtl'] .rtl\:ml-320,[dir='rtl'].rtl\:ml-320 {
  margin-left: 32rem
}

[dir='rtl'] .rtl\:mt-360,[dir='rtl'].rtl\:mt-360 {
  margin-top: 36rem
}

[dir='rtl'] .rtl\:mr-360,[dir='rtl'].rtl\:mr-360 {
  margin-right: 36rem
}

[dir='rtl'] .rtl\:mb-360,[dir='rtl'].rtl\:mb-360 {
  margin-bottom: 36rem
}

[dir='rtl'] .rtl\:ml-360,[dir='rtl'].rtl\:ml-360 {
  margin-left: 36rem
}

[dir='rtl'] .rtl\:mt-384,[dir='rtl'].rtl\:mt-384 {
  margin-top: 38.4rem
}

[dir='rtl'] .rtl\:mr-384,[dir='rtl'].rtl\:mr-384 {
  margin-right: 38.4rem
}

[dir='rtl'] .rtl\:mb-384,[dir='rtl'].rtl\:mb-384 {
  margin-bottom: 38.4rem
}

[dir='rtl'] .rtl\:ml-384,[dir='rtl'].rtl\:ml-384 {
  margin-left: 38.4rem
}

[dir='rtl'] .rtl\:mt-400,[dir='rtl'].rtl\:mt-400 {
  margin-top: 40rem
}

[dir='rtl'] .rtl\:mr-400,[dir='rtl'].rtl\:mr-400 {
  margin-right: 40rem
}

[dir='rtl'] .rtl\:mb-400,[dir='rtl'].rtl\:mb-400 {
  margin-bottom: 40rem
}

[dir='rtl'] .rtl\:ml-400,[dir='rtl'].rtl\:ml-400 {
  margin-left: 40rem
}

[dir='rtl'] .rtl\:mt-512,[dir='rtl'].rtl\:mt-512 {
  margin-top: 51.2rem
}

[dir='rtl'] .rtl\:mr-512,[dir='rtl'].rtl\:mr-512 {
  margin-right: 51.2rem
}

[dir='rtl'] .rtl\:mb-512,[dir='rtl'].rtl\:mb-512 {
  margin-bottom: 51.2rem
}

[dir='rtl'] .rtl\:ml-512,[dir='rtl'].rtl\:ml-512 {
  margin-left: 51.2rem
}

[dir='rtl'] .rtl\:mt-640,[dir='rtl'].rtl\:mt-640 {
  margin-top: 64rem
}

[dir='rtl'] .rtl\:mr-640,[dir='rtl'].rtl\:mr-640 {
  margin-right: 64rem
}

[dir='rtl'] .rtl\:mb-640,[dir='rtl'].rtl\:mb-640 {
  margin-bottom: 64rem
}

[dir='rtl'] .rtl\:ml-640,[dir='rtl'].rtl\:ml-640 {
  margin-left: 64rem
}

[dir='rtl'] .rtl\:mt-auto,[dir='rtl'].rtl\:mt-auto {
  margin-top: auto
}

[dir='rtl'] .rtl\:mr-auto,[dir='rtl'].rtl\:mr-auto {
  margin-right: auto
}

[dir='rtl'] .rtl\:mb-auto,[dir='rtl'].rtl\:mb-auto {
  margin-bottom: auto
}

[dir='rtl'] .rtl\:ml-auto,[dir='rtl'].rtl\:ml-auto {
  margin-left: auto
}

[dir='rtl'] .rtl\:mt-xs,[dir='rtl'].rtl\:mt-xs {
  margin-top: 32rem
}

[dir='rtl'] .rtl\:mr-xs,[dir='rtl'].rtl\:mr-xs {
  margin-right: 32rem
}

[dir='rtl'] .rtl\:mb-xs,[dir='rtl'].rtl\:mb-xs {
  margin-bottom: 32rem
}

[dir='rtl'] .rtl\:ml-xs,[dir='rtl'].rtl\:ml-xs {
  margin-left: 32rem
}

[dir='rtl'] .rtl\:mt-sm,[dir='rtl'].rtl\:mt-sm {
  margin-top: 48rem
}

[dir='rtl'] .rtl\:mr-sm,[dir='rtl'].rtl\:mr-sm {
  margin-right: 48rem
}

[dir='rtl'] .rtl\:mb-sm,[dir='rtl'].rtl\:mb-sm {
  margin-bottom: 48rem
}

[dir='rtl'] .rtl\:ml-sm,[dir='rtl'].rtl\:ml-sm {
  margin-left: 48rem
}

[dir='rtl'] .rtl\:mt-md,[dir='rtl'].rtl\:mt-md {
  margin-top: 64rem
}

[dir='rtl'] .rtl\:mr-md,[dir='rtl'].rtl\:mr-md {
  margin-right: 64rem
}

[dir='rtl'] .rtl\:mb-md,[dir='rtl'].rtl\:mb-md {
  margin-bottom: 64rem
}

[dir='rtl'] .rtl\:ml-md,[dir='rtl'].rtl\:ml-md {
  margin-left: 64rem
}

[dir='rtl'] .rtl\:mt-lg,[dir='rtl'].rtl\:mt-lg {
  margin-top: 80rem
}

[dir='rtl'] .rtl\:mr-lg,[dir='rtl'].rtl\:mr-lg {
  margin-right: 80rem
}

[dir='rtl'] .rtl\:mb-lg,[dir='rtl'].rtl\:mb-lg {
  margin-bottom: 80rem
}

[dir='rtl'] .rtl\:ml-lg,[dir='rtl'].rtl\:ml-lg {
  margin-left: 80rem
}

[dir='rtl'] .rtl\:mt-xl,[dir='rtl'].rtl\:mt-xl {
  margin-top: 96rem
}

[dir='rtl'] .rtl\:mr-xl,[dir='rtl'].rtl\:mr-xl {
  margin-right: 96rem
}

[dir='rtl'] .rtl\:mb-xl,[dir='rtl'].rtl\:mb-xl {
  margin-bottom: 96rem
}

[dir='rtl'] .rtl\:ml-xl,[dir='rtl'].rtl\:ml-xl {
  margin-left: 96rem
}

[dir='rtl'] .rtl\:mt-2xl,[dir='rtl'].rtl\:mt-2xl {
  margin-top: 112rem
}

[dir='rtl'] .rtl\:mr-2xl,[dir='rtl'].rtl\:mr-2xl {
  margin-right: 112rem
}

[dir='rtl'] .rtl\:mb-2xl,[dir='rtl'].rtl\:mb-2xl {
  margin-bottom: 112rem
}

[dir='rtl'] .rtl\:ml-2xl,[dir='rtl'].rtl\:ml-2xl {
  margin-left: 112rem
}

[dir='rtl'] .rtl\:mt-3xl,[dir='rtl'].rtl\:mt-3xl {
  margin-top: 128rem
}

[dir='rtl'] .rtl\:mr-3xl,[dir='rtl'].rtl\:mr-3xl {
  margin-right: 128rem
}

[dir='rtl'] .rtl\:mb-3xl,[dir='rtl'].rtl\:mb-3xl {
  margin-bottom: 128rem
}

[dir='rtl'] .rtl\:ml-3xl,[dir='rtl'].rtl\:ml-3xl {
  margin-left: 128rem
}

[dir='rtl'] .rtl\:mt-4xl,[dir='rtl'].rtl\:mt-4xl {
  margin-top: 144rem
}

[dir='rtl'] .rtl\:mr-4xl,[dir='rtl'].rtl\:mr-4xl {
  margin-right: 144rem
}

[dir='rtl'] .rtl\:mb-4xl,[dir='rtl'].rtl\:mb-4xl {
  margin-bottom: 144rem
}

[dir='rtl'] .rtl\:ml-4xl,[dir='rtl'].rtl\:ml-4xl {
  margin-left: 144rem
}

[dir='rtl'] .rtl\:mt-5xl,[dir='rtl'].rtl\:mt-5xl {
  margin-top: 160rem
}

[dir='rtl'] .rtl\:mr-5xl,[dir='rtl'].rtl\:mr-5xl {
  margin-right: 160rem
}

[dir='rtl'] .rtl\:mb-5xl,[dir='rtl'].rtl\:mb-5xl {
  margin-bottom: 160rem
}

[dir='rtl'] .rtl\:ml-5xl,[dir='rtl'].rtl\:ml-5xl {
  margin-left: 160rem
}

[dir='rtl'] .rtl\:mt-px,[dir='rtl'].rtl\:mt-px {
  margin-top: 1px
}

[dir='rtl'] .rtl\:mr-px,[dir='rtl'].rtl\:mr-px {
  margin-right: 1px
}

[dir='rtl'] .rtl\:mb-px,[dir='rtl'].rtl\:mb-px {
  margin-bottom: 1px
}

[dir='rtl'] .rtl\:ml-px,[dir='rtl'].rtl\:ml-px {
  margin-left: 1px
}

[dir='rtl'] .rtl\:mt-0\.5,[dir='rtl'].rtl\:mt-0\.5 {
  margin-top: 0.05rem
}

[dir='rtl'] .rtl\:mr-0\.5,[dir='rtl'].rtl\:mr-0\.5 {
  margin-right: 0.05rem
}

[dir='rtl'] .rtl\:mb-0\.5,[dir='rtl'].rtl\:mb-0\.5 {
  margin-bottom: 0.05rem
}

[dir='rtl'] .rtl\:ml-0\.5,[dir='rtl'].rtl\:ml-0\.5 {
  margin-left: 0.05rem
}

[dir='rtl'] .rtl\:mt-1\.5,[dir='rtl'].rtl\:mt-1\.5 {
  margin-top: 0.15rem
}

[dir='rtl'] .rtl\:mr-1\.5,[dir='rtl'].rtl\:mr-1\.5 {
  margin-right: 0.15rem
}

[dir='rtl'] .rtl\:mb-1\.5,[dir='rtl'].rtl\:mb-1\.5 {
  margin-bottom: 0.15rem
}

[dir='rtl'] .rtl\:ml-1\.5,[dir='rtl'].rtl\:ml-1\.5 {
  margin-left: 0.15rem
}

[dir='rtl'] .rtl\:mt-2\.5,[dir='rtl'].rtl\:mt-2\.5 {
  margin-top: 0.25rem
}

[dir='rtl'] .rtl\:mr-2\.5,[dir='rtl'].rtl\:mr-2\.5 {
  margin-right: 0.25rem
}

[dir='rtl'] .rtl\:mb-2\.5,[dir='rtl'].rtl\:mb-2\.5 {
  margin-bottom: 0.25rem
}

[dir='rtl'] .rtl\:ml-2\.5,[dir='rtl'].rtl\:ml-2\.5 {
  margin-left: 0.25rem
}

[dir='rtl'] .rtl\:mt-3\.5,[dir='rtl'].rtl\:mt-3\.5 {
  margin-top: 0.35rem
}

[dir='rtl'] .rtl\:mr-3\.5,[dir='rtl'].rtl\:mr-3\.5 {
  margin-right: 0.35rem
}

[dir='rtl'] .rtl\:mb-3\.5,[dir='rtl'].rtl\:mb-3\.5 {
  margin-bottom: 0.35rem
}

[dir='rtl'] .rtl\:ml-3\.5,[dir='rtl'].rtl\:ml-3\.5 {
  margin-left: 0.35rem
}

[dir='rtl'] .rtl\:-mt-1,[dir='rtl'].rtl\:-mt-1 {
  margin-top: -0.1rem
}

[dir='rtl'] .rtl\:-mr-1,[dir='rtl'].rtl\:-mr-1 {
  margin-right: -0.1rem
}

[dir='rtl'] .rtl\:-mb-1,[dir='rtl'].rtl\:-mb-1 {
  margin-bottom: -0.1rem
}

[dir='rtl'] .rtl\:-ml-1,[dir='rtl'].rtl\:-ml-1 {
  margin-left: -0.1rem
}

[dir='rtl'] .rtl\:-mt-2,[dir='rtl'].rtl\:-mt-2 {
  margin-top: -0.2rem
}

[dir='rtl'] .rtl\:-mr-2,[dir='rtl'].rtl\:-mr-2 {
  margin-right: -0.2rem
}

[dir='rtl'] .rtl\:-mb-2,[dir='rtl'].rtl\:-mb-2 {
  margin-bottom: -0.2rem
}

[dir='rtl'] .rtl\:-ml-2,[dir='rtl'].rtl\:-ml-2 {
  margin-left: -0.2rem
}

[dir='rtl'] .rtl\:-mt-3,[dir='rtl'].rtl\:-mt-3 {
  margin-top: -0.3rem
}

[dir='rtl'] .rtl\:-mr-3,[dir='rtl'].rtl\:-mr-3 {
  margin-right: -0.3rem
}

[dir='rtl'] .rtl\:-mb-3,[dir='rtl'].rtl\:-mb-3 {
  margin-bottom: -0.3rem
}

[dir='rtl'] .rtl\:-ml-3,[dir='rtl'].rtl\:-ml-3 {
  margin-left: -0.3rem
}

[dir='rtl'] .rtl\:-mt-4,[dir='rtl'].rtl\:-mt-4 {
  margin-top: -0.4rem
}

[dir='rtl'] .rtl\:-mr-4,[dir='rtl'].rtl\:-mr-4 {
  margin-right: -0.4rem
}

[dir='rtl'] .rtl\:-mb-4,[dir='rtl'].rtl\:-mb-4 {
  margin-bottom: -0.4rem
}

[dir='rtl'] .rtl\:-ml-4,[dir='rtl'].rtl\:-ml-4 {
  margin-left: -0.4rem
}

[dir='rtl'] .rtl\:-mt-5,[dir='rtl'].rtl\:-mt-5 {
  margin-top: -0.5rem
}

[dir='rtl'] .rtl\:-mr-5,[dir='rtl'].rtl\:-mr-5 {
  margin-right: -0.5rem
}

[dir='rtl'] .rtl\:-mb-5,[dir='rtl'].rtl\:-mb-5 {
  margin-bottom: -0.5rem
}

[dir='rtl'] .rtl\:-ml-5,[dir='rtl'].rtl\:-ml-5 {
  margin-left: -0.5rem
}

[dir='rtl'] .rtl\:-mt-6,[dir='rtl'].rtl\:-mt-6 {
  margin-top: -0.6rem
}

[dir='rtl'] .rtl\:-mr-6,[dir='rtl'].rtl\:-mr-6 {
  margin-right: -0.6rem
}

[dir='rtl'] .rtl\:-mb-6,[dir='rtl'].rtl\:-mb-6 {
  margin-bottom: -0.6rem
}

[dir='rtl'] .rtl\:-ml-6,[dir='rtl'].rtl\:-ml-6 {
  margin-left: -0.6rem
}

[dir='rtl'] .rtl\:-mt-7,[dir='rtl'].rtl\:-mt-7 {
  margin-top: -0.7rem
}

[dir='rtl'] .rtl\:-mr-7,[dir='rtl'].rtl\:-mr-7 {
  margin-right: -0.7rem
}

[dir='rtl'] .rtl\:-mb-7,[dir='rtl'].rtl\:-mb-7 {
  margin-bottom: -0.7rem
}

[dir='rtl'] .rtl\:-ml-7,[dir='rtl'].rtl\:-ml-7 {
  margin-left: -0.7rem
}

[dir='rtl'] .rtl\:-mt-8,[dir='rtl'].rtl\:-mt-8 {
  margin-top: -0.8rem
}

[dir='rtl'] .rtl\:-mr-8,[dir='rtl'].rtl\:-mr-8 {
  margin-right: -0.8rem
}

[dir='rtl'] .rtl\:-mb-8,[dir='rtl'].rtl\:-mb-8 {
  margin-bottom: -0.8rem
}

[dir='rtl'] .rtl\:-ml-8,[dir='rtl'].rtl\:-ml-8 {
  margin-left: -0.8rem
}

[dir='rtl'] .rtl\:-mt-9,[dir='rtl'].rtl\:-mt-9 {
  margin-top: -0.9rem
}

[dir='rtl'] .rtl\:-mr-9,[dir='rtl'].rtl\:-mr-9 {
  margin-right: -0.9rem
}

[dir='rtl'] .rtl\:-mb-9,[dir='rtl'].rtl\:-mb-9 {
  margin-bottom: -0.9rem
}

[dir='rtl'] .rtl\:-ml-9,[dir='rtl'].rtl\:-ml-9 {
  margin-left: -0.9rem
}

[dir='rtl'] .rtl\:-mt-10,[dir='rtl'].rtl\:-mt-10 {
  margin-top: -1rem
}

[dir='rtl'] .rtl\:-mr-10,[dir='rtl'].rtl\:-mr-10 {
  margin-right: -1rem
}

[dir='rtl'] .rtl\:-mb-10,[dir='rtl'].rtl\:-mb-10 {
  margin-bottom: -1rem
}

[dir='rtl'] .rtl\:-ml-10,[dir='rtl'].rtl\:-ml-10 {
  margin-left: -1rem
}

[dir='rtl'] .rtl\:-mt-12,[dir='rtl'].rtl\:-mt-12 {
  margin-top: -1.2rem
}

[dir='rtl'] .rtl\:-mr-12,[dir='rtl'].rtl\:-mr-12 {
  margin-right: -1.2rem
}

[dir='rtl'] .rtl\:-mb-12,[dir='rtl'].rtl\:-mb-12 {
  margin-bottom: -1.2rem
}

[dir='rtl'] .rtl\:-ml-12,[dir='rtl'].rtl\:-ml-12 {
  margin-left: -1.2rem
}

[dir='rtl'] .rtl\:-mt-14,[dir='rtl'].rtl\:-mt-14 {
  margin-top: -1.4rem
}

[dir='rtl'] .rtl\:-mr-14,[dir='rtl'].rtl\:-mr-14 {
  margin-right: -1.4rem
}

[dir='rtl'] .rtl\:-mb-14,[dir='rtl'].rtl\:-mb-14 {
  margin-bottom: -1.4rem
}

[dir='rtl'] .rtl\:-ml-14,[dir='rtl'].rtl\:-ml-14 {
  margin-left: -1.4rem
}

[dir='rtl'] .rtl\:-mt-16,[dir='rtl'].rtl\:-mt-16 {
  margin-top: -1.6rem
}

[dir='rtl'] .rtl\:-mr-16,[dir='rtl'].rtl\:-mr-16 {
  margin-right: -1.6rem
}

[dir='rtl'] .rtl\:-mb-16,[dir='rtl'].rtl\:-mb-16 {
  margin-bottom: -1.6rem
}

[dir='rtl'] .rtl\:-ml-16,[dir='rtl'].rtl\:-ml-16 {
  margin-left: -1.6rem
}

[dir='rtl'] .rtl\:-mt-20,[dir='rtl'].rtl\:-mt-20 {
  margin-top: -2rem
}

[dir='rtl'] .rtl\:-mr-20,[dir='rtl'].rtl\:-mr-20 {
  margin-right: -2rem
}

[dir='rtl'] .rtl\:-mb-20,[dir='rtl'].rtl\:-mb-20 {
  margin-bottom: -2rem
}

[dir='rtl'] .rtl\:-ml-20,[dir='rtl'].rtl\:-ml-20 {
  margin-left: -2rem
}

[dir='rtl'] .rtl\:-mt-24,[dir='rtl'].rtl\:-mt-24 {
  margin-top: -2.4rem
}

[dir='rtl'] .rtl\:-mr-24,[dir='rtl'].rtl\:-mr-24 {
  margin-right: -2.4rem
}

[dir='rtl'] .rtl\:-mb-24,[dir='rtl'].rtl\:-mb-24 {
  margin-bottom: -2.4rem
}

[dir='rtl'] .rtl\:-ml-24,[dir='rtl'].rtl\:-ml-24 {
  margin-left: -2.4rem
}

[dir='rtl'] .rtl\:-mt-28,[dir='rtl'].rtl\:-mt-28 {
  margin-top: -2.8rem
}

[dir='rtl'] .rtl\:-mr-28,[dir='rtl'].rtl\:-mr-28 {
  margin-right: -2.8rem
}

[dir='rtl'] .rtl\:-mb-28,[dir='rtl'].rtl\:-mb-28 {
  margin-bottom: -2.8rem
}

[dir='rtl'] .rtl\:-ml-28,[dir='rtl'].rtl\:-ml-28 {
  margin-left: -2.8rem
}

[dir='rtl'] .rtl\:-mt-32,[dir='rtl'].rtl\:-mt-32 {
  margin-top: -3.2rem
}

[dir='rtl'] .rtl\:-mr-32,[dir='rtl'].rtl\:-mr-32 {
  margin-right: -3.2rem
}

[dir='rtl'] .rtl\:-mb-32,[dir='rtl'].rtl\:-mb-32 {
  margin-bottom: -3.2rem
}

[dir='rtl'] .rtl\:-ml-32,[dir='rtl'].rtl\:-ml-32 {
  margin-left: -3.2rem
}

[dir='rtl'] .rtl\:-mt-36,[dir='rtl'].rtl\:-mt-36 {
  margin-top: -3.6rem
}

[dir='rtl'] .rtl\:-mr-36,[dir='rtl'].rtl\:-mr-36 {
  margin-right: -3.6rem
}

[dir='rtl'] .rtl\:-mb-36,[dir='rtl'].rtl\:-mb-36 {
  margin-bottom: -3.6rem
}

[dir='rtl'] .rtl\:-ml-36,[dir='rtl'].rtl\:-ml-36 {
  margin-left: -3.6rem
}

[dir='rtl'] .rtl\:-mt-40,[dir='rtl'].rtl\:-mt-40 {
  margin-top: -4rem
}

[dir='rtl'] .rtl\:-mr-40,[dir='rtl'].rtl\:-mr-40 {
  margin-right: -4rem
}

[dir='rtl'] .rtl\:-mb-40,[dir='rtl'].rtl\:-mb-40 {
  margin-bottom: -4rem
}

[dir='rtl'] .rtl\:-ml-40,[dir='rtl'].rtl\:-ml-40 {
  margin-left: -4rem
}

[dir='rtl'] .rtl\:-mt-44,[dir='rtl'].rtl\:-mt-44 {
  margin-top: -4.4rem
}

[dir='rtl'] .rtl\:-mr-44,[dir='rtl'].rtl\:-mr-44 {
  margin-right: -4.4rem
}

[dir='rtl'] .rtl\:-mb-44,[dir='rtl'].rtl\:-mb-44 {
  margin-bottom: -4.4rem
}

[dir='rtl'] .rtl\:-ml-44,[dir='rtl'].rtl\:-ml-44 {
  margin-left: -4.4rem
}

[dir='rtl'] .rtl\:-mt-48,[dir='rtl'].rtl\:-mt-48 {
  margin-top: -4.8rem
}

[dir='rtl'] .rtl\:-mr-48,[dir='rtl'].rtl\:-mr-48 {
  margin-right: -4.8rem
}

[dir='rtl'] .rtl\:-mb-48,[dir='rtl'].rtl\:-mb-48 {
  margin-bottom: -4.8rem
}

[dir='rtl'] .rtl\:-ml-48,[dir='rtl'].rtl\:-ml-48 {
  margin-left: -4.8rem
}

[dir='rtl'] .rtl\:-mt-52,[dir='rtl'].rtl\:-mt-52 {
  margin-top: -5.2rem
}

[dir='rtl'] .rtl\:-mr-52,[dir='rtl'].rtl\:-mr-52 {
  margin-right: -5.2rem
}

[dir='rtl'] .rtl\:-mb-52,[dir='rtl'].rtl\:-mb-52 {
  margin-bottom: -5.2rem
}

[dir='rtl'] .rtl\:-ml-52,[dir='rtl'].rtl\:-ml-52 {
  margin-left: -5.2rem
}

[dir='rtl'] .rtl\:-mt-56,[dir='rtl'].rtl\:-mt-56 {
  margin-top: -5.6rem
}

[dir='rtl'] .rtl\:-mr-56,[dir='rtl'].rtl\:-mr-56 {
  margin-right: -5.6rem
}

[dir='rtl'] .rtl\:-mb-56,[dir='rtl'].rtl\:-mb-56 {
  margin-bottom: -5.6rem
}

[dir='rtl'] .rtl\:-ml-56,[dir='rtl'].rtl\:-ml-56 {
  margin-left: -5.6rem
}

[dir='rtl'] .rtl\:-mt-60,[dir='rtl'].rtl\:-mt-60 {
  margin-top: -6rem
}

[dir='rtl'] .rtl\:-mr-60,[dir='rtl'].rtl\:-mr-60 {
  margin-right: -6rem
}

[dir='rtl'] .rtl\:-mb-60,[dir='rtl'].rtl\:-mb-60 {
  margin-bottom: -6rem
}

[dir='rtl'] .rtl\:-ml-60,[dir='rtl'].rtl\:-ml-60 {
  margin-left: -6rem
}

[dir='rtl'] .rtl\:-mt-64,[dir='rtl'].rtl\:-mt-64 {
  margin-top: -6.4rem
}

[dir='rtl'] .rtl\:-mr-64,[dir='rtl'].rtl\:-mr-64 {
  margin-right: -6.4rem
}

[dir='rtl'] .rtl\:-mb-64,[dir='rtl'].rtl\:-mb-64 {
  margin-bottom: -6.4rem
}

[dir='rtl'] .rtl\:-ml-64,[dir='rtl'].rtl\:-ml-64 {
  margin-left: -6.4rem
}

[dir='rtl'] .rtl\:-mt-68,[dir='rtl'].rtl\:-mt-68 {
  margin-top: -6.8rem
}

[dir='rtl'] .rtl\:-mr-68,[dir='rtl'].rtl\:-mr-68 {
  margin-right: -6.8rem
}

[dir='rtl'] .rtl\:-mb-68,[dir='rtl'].rtl\:-mb-68 {
  margin-bottom: -6.8rem
}

[dir='rtl'] .rtl\:-ml-68,[dir='rtl'].rtl\:-ml-68 {
  margin-left: -6.8rem
}

[dir='rtl'] .rtl\:-mt-72,[dir='rtl'].rtl\:-mt-72 {
  margin-top: -7.2rem
}

[dir='rtl'] .rtl\:-mr-72,[dir='rtl'].rtl\:-mr-72 {
  margin-right: -7.2rem
}

[dir='rtl'] .rtl\:-mb-72,[dir='rtl'].rtl\:-mb-72 {
  margin-bottom: -7.2rem
}

[dir='rtl'] .rtl\:-ml-72,[dir='rtl'].rtl\:-ml-72 {
  margin-left: -7.2rem
}

[dir='rtl'] .rtl\:-mt-76,[dir='rtl'].rtl\:-mt-76 {
  margin-top: -7.6rem
}

[dir='rtl'] .rtl\:-mr-76,[dir='rtl'].rtl\:-mr-76 {
  margin-right: -7.6rem
}

[dir='rtl'] .rtl\:-mb-76,[dir='rtl'].rtl\:-mb-76 {
  margin-bottom: -7.6rem
}

[dir='rtl'] .rtl\:-ml-76,[dir='rtl'].rtl\:-ml-76 {
  margin-left: -7.6rem
}

[dir='rtl'] .rtl\:-mt-80,[dir='rtl'].rtl\:-mt-80 {
  margin-top: -8rem
}

[dir='rtl'] .rtl\:-mr-80,[dir='rtl'].rtl\:-mr-80 {
  margin-right: -8rem
}

[dir='rtl'] .rtl\:-mb-80,[dir='rtl'].rtl\:-mb-80 {
  margin-bottom: -8rem
}

[dir='rtl'] .rtl\:-ml-80,[dir='rtl'].rtl\:-ml-80 {
  margin-left: -8rem
}

[dir='rtl'] .rtl\:-mt-84,[dir='rtl'].rtl\:-mt-84 {
  margin-top: -8.4rem
}

[dir='rtl'] .rtl\:-mr-84,[dir='rtl'].rtl\:-mr-84 {
  margin-right: -8.4rem
}

[dir='rtl'] .rtl\:-mb-84,[dir='rtl'].rtl\:-mb-84 {
  margin-bottom: -8.4rem
}

[dir='rtl'] .rtl\:-ml-84,[dir='rtl'].rtl\:-ml-84 {
  margin-left: -8.4rem
}

[dir='rtl'] .rtl\:-mt-88,[dir='rtl'].rtl\:-mt-88 {
  margin-top: -8.8rem
}

[dir='rtl'] .rtl\:-mr-88,[dir='rtl'].rtl\:-mr-88 {
  margin-right: -8.8rem
}

[dir='rtl'] .rtl\:-mb-88,[dir='rtl'].rtl\:-mb-88 {
  margin-bottom: -8.8rem
}

[dir='rtl'] .rtl\:-ml-88,[dir='rtl'].rtl\:-ml-88 {
  margin-left: -8.8rem
}

[dir='rtl'] .rtl\:-mt-92,[dir='rtl'].rtl\:-mt-92 {
  margin-top: -9.2rem
}

[dir='rtl'] .rtl\:-mr-92,[dir='rtl'].rtl\:-mr-92 {
  margin-right: -9.2rem
}

[dir='rtl'] .rtl\:-mb-92,[dir='rtl'].rtl\:-mb-92 {
  margin-bottom: -9.2rem
}

[dir='rtl'] .rtl\:-ml-92,[dir='rtl'].rtl\:-ml-92 {
  margin-left: -9.2rem
}

[dir='rtl'] .rtl\:-mt-96,[dir='rtl'].rtl\:-mt-96 {
  margin-top: -9.6rem
}

[dir='rtl'] .rtl\:-mr-96,[dir='rtl'].rtl\:-mr-96 {
  margin-right: -9.6rem
}

[dir='rtl'] .rtl\:-mb-96,[dir='rtl'].rtl\:-mb-96 {
  margin-bottom: -9.6rem
}

[dir='rtl'] .rtl\:-ml-96,[dir='rtl'].rtl\:-ml-96 {
  margin-left: -9.6rem
}

[dir='rtl'] .rtl\:-mt-128,[dir='rtl'].rtl\:-mt-128 {
  margin-top: -12.8rem
}

[dir='rtl'] .rtl\:-mr-128,[dir='rtl'].rtl\:-mr-128 {
  margin-right: -12.8rem
}

[dir='rtl'] .rtl\:-mb-128,[dir='rtl'].rtl\:-mb-128 {
  margin-bottom: -12.8rem
}

[dir='rtl'] .rtl\:-ml-128,[dir='rtl'].rtl\:-ml-128 {
  margin-left: -12.8rem
}

[dir='rtl'] .rtl\:-mt-136,[dir='rtl'].rtl\:-mt-136 {
  margin-top: -13.6rem
}

[dir='rtl'] .rtl\:-mr-136,[dir='rtl'].rtl\:-mr-136 {
  margin-right: -13.6rem
}

[dir='rtl'] .rtl\:-mb-136,[dir='rtl'].rtl\:-mb-136 {
  margin-bottom: -13.6rem
}

[dir='rtl'] .rtl\:-ml-136,[dir='rtl'].rtl\:-ml-136 {
  margin-left: -13.6rem
}

[dir='rtl'] .rtl\:-mt-160,[dir='rtl'].rtl\:-mt-160 {
  margin-top: -16rem
}

[dir='rtl'] .rtl\:-mr-160,[dir='rtl'].rtl\:-mr-160 {
  margin-right: -16rem
}

[dir='rtl'] .rtl\:-mb-160,[dir='rtl'].rtl\:-mb-160 {
  margin-bottom: -16rem
}

[dir='rtl'] .rtl\:-ml-160,[dir='rtl'].rtl\:-ml-160 {
  margin-left: -16rem
}

[dir='rtl'] .rtl\:-mt-192,[dir='rtl'].rtl\:-mt-192 {
  margin-top: -19.2rem
}

[dir='rtl'] .rtl\:-mr-192,[dir='rtl'].rtl\:-mr-192 {
  margin-right: -19.2rem
}

[dir='rtl'] .rtl\:-mb-192,[dir='rtl'].rtl\:-mb-192 {
  margin-bottom: -19.2rem
}

[dir='rtl'] .rtl\:-ml-192,[dir='rtl'].rtl\:-ml-192 {
  margin-left: -19.2rem
}

[dir='rtl'] .rtl\:-mt-200,[dir='rtl'].rtl\:-mt-200 {
  margin-top: -20rem
}

[dir='rtl'] .rtl\:-mr-200,[dir='rtl'].rtl\:-mr-200 {
  margin-right: -20rem
}

[dir='rtl'] .rtl\:-mb-200,[dir='rtl'].rtl\:-mb-200 {
  margin-bottom: -20rem
}

[dir='rtl'] .rtl\:-ml-200,[dir='rtl'].rtl\:-ml-200 {
  margin-left: -20rem
}

[dir='rtl'] .rtl\:-mt-208,[dir='rtl'].rtl\:-mt-208 {
  margin-top: -20.8rem
}

[dir='rtl'] .rtl\:-mr-208,[dir='rtl'].rtl\:-mr-208 {
  margin-right: -20.8rem
}

[dir='rtl'] .rtl\:-mb-208,[dir='rtl'].rtl\:-mb-208 {
  margin-bottom: -20.8rem
}

[dir='rtl'] .rtl\:-ml-208,[dir='rtl'].rtl\:-ml-208 {
  margin-left: -20.8rem
}

[dir='rtl'] .rtl\:-mt-216,[dir='rtl'].rtl\:-mt-216 {
  margin-top: -21.6rem
}

[dir='rtl'] .rtl\:-mr-216,[dir='rtl'].rtl\:-mr-216 {
  margin-right: -21.6rem
}

[dir='rtl'] .rtl\:-mb-216,[dir='rtl'].rtl\:-mb-216 {
  margin-bottom: -21.6rem
}

[dir='rtl'] .rtl\:-ml-216,[dir='rtl'].rtl\:-ml-216 {
  margin-left: -21.6rem
}

[dir='rtl'] .rtl\:-mt-224,[dir='rtl'].rtl\:-mt-224 {
  margin-top: -22.4rem
}

[dir='rtl'] .rtl\:-mr-224,[dir='rtl'].rtl\:-mr-224 {
  margin-right: -22.4rem
}

[dir='rtl'] .rtl\:-mb-224,[dir='rtl'].rtl\:-mb-224 {
  margin-bottom: -22.4rem
}

[dir='rtl'] .rtl\:-ml-224,[dir='rtl'].rtl\:-ml-224 {
  margin-left: -22.4rem
}

[dir='rtl'] .rtl\:-mt-256,[dir='rtl'].rtl\:-mt-256 {
  margin-top: -25.6rem
}

[dir='rtl'] .rtl\:-mr-256,[dir='rtl'].rtl\:-mr-256 {
  margin-right: -25.6rem
}

[dir='rtl'] .rtl\:-mb-256,[dir='rtl'].rtl\:-mb-256 {
  margin-bottom: -25.6rem
}

[dir='rtl'] .rtl\:-ml-256,[dir='rtl'].rtl\:-ml-256 {
  margin-left: -25.6rem
}

[dir='rtl'] .rtl\:-mt-288,[dir='rtl'].rtl\:-mt-288 {
  margin-top: -28.8rem
}

[dir='rtl'] .rtl\:-mr-288,[dir='rtl'].rtl\:-mr-288 {
  margin-right: -28.8rem
}

[dir='rtl'] .rtl\:-mb-288,[dir='rtl'].rtl\:-mb-288 {
  margin-bottom: -28.8rem
}

[dir='rtl'] .rtl\:-ml-288,[dir='rtl'].rtl\:-ml-288 {
  margin-left: -28.8rem
}

[dir='rtl'] .rtl\:-mt-320,[dir='rtl'].rtl\:-mt-320 {
  margin-top: -32rem
}

[dir='rtl'] .rtl\:-mr-320,[dir='rtl'].rtl\:-mr-320 {
  margin-right: -32rem
}

[dir='rtl'] .rtl\:-mb-320,[dir='rtl'].rtl\:-mb-320 {
  margin-bottom: -32rem
}

[dir='rtl'] .rtl\:-ml-320,[dir='rtl'].rtl\:-ml-320 {
  margin-left: -32rem
}

[dir='rtl'] .rtl\:-mt-360,[dir='rtl'].rtl\:-mt-360 {
  margin-top: -36rem
}

[dir='rtl'] .rtl\:-mr-360,[dir='rtl'].rtl\:-mr-360 {
  margin-right: -36rem
}

[dir='rtl'] .rtl\:-mb-360,[dir='rtl'].rtl\:-mb-360 {
  margin-bottom: -36rem
}

[dir='rtl'] .rtl\:-ml-360,[dir='rtl'].rtl\:-ml-360 {
  margin-left: -36rem
}

[dir='rtl'] .rtl\:-mt-384,[dir='rtl'].rtl\:-mt-384 {
  margin-top: -38.4rem
}

[dir='rtl'] .rtl\:-mr-384,[dir='rtl'].rtl\:-mr-384 {
  margin-right: -38.4rem
}

[dir='rtl'] .rtl\:-mb-384,[dir='rtl'].rtl\:-mb-384 {
  margin-bottom: -38.4rem
}

[dir='rtl'] .rtl\:-ml-384,[dir='rtl'].rtl\:-ml-384 {
  margin-left: -38.4rem
}

[dir='rtl'] .rtl\:-mt-400,[dir='rtl'].rtl\:-mt-400 {
  margin-top: -40rem
}

[dir='rtl'] .rtl\:-mr-400,[dir='rtl'].rtl\:-mr-400 {
  margin-right: -40rem
}

[dir='rtl'] .rtl\:-mb-400,[dir='rtl'].rtl\:-mb-400 {
  margin-bottom: -40rem
}

[dir='rtl'] .rtl\:-ml-400,[dir='rtl'].rtl\:-ml-400 {
  margin-left: -40rem
}

[dir='rtl'] .rtl\:-mt-512,[dir='rtl'].rtl\:-mt-512 {
  margin-top: -51.2rem
}

[dir='rtl'] .rtl\:-mr-512,[dir='rtl'].rtl\:-mr-512 {
  margin-right: -51.2rem
}

[dir='rtl'] .rtl\:-mb-512,[dir='rtl'].rtl\:-mb-512 {
  margin-bottom: -51.2rem
}

[dir='rtl'] .rtl\:-ml-512,[dir='rtl'].rtl\:-ml-512 {
  margin-left: -51.2rem
}

[dir='rtl'] .rtl\:-mt-640,[dir='rtl'].rtl\:-mt-640 {
  margin-top: -64rem
}

[dir='rtl'] .rtl\:-mr-640,[dir='rtl'].rtl\:-mr-640 {
  margin-right: -64rem
}

[dir='rtl'] .rtl\:-mb-640,[dir='rtl'].rtl\:-mb-640 {
  margin-bottom: -64rem
}

[dir='rtl'] .rtl\:-ml-640,[dir='rtl'].rtl\:-ml-640 {
  margin-left: -64rem
}

[dir='rtl'] .rtl\:-mt-xs,[dir='rtl'].rtl\:-mt-xs {
  margin-top: -32rem
}

[dir='rtl'] .rtl\:-mr-xs,[dir='rtl'].rtl\:-mr-xs {
  margin-right: -32rem
}

[dir='rtl'] .rtl\:-mb-xs,[dir='rtl'].rtl\:-mb-xs {
  margin-bottom: -32rem
}

[dir='rtl'] .rtl\:-ml-xs,[dir='rtl'].rtl\:-ml-xs {
  margin-left: -32rem
}

[dir='rtl'] .rtl\:-mt-sm,[dir='rtl'].rtl\:-mt-sm {
  margin-top: -48rem
}

[dir='rtl'] .rtl\:-mr-sm,[dir='rtl'].rtl\:-mr-sm {
  margin-right: -48rem
}

[dir='rtl'] .rtl\:-mb-sm,[dir='rtl'].rtl\:-mb-sm {
  margin-bottom: -48rem
}

[dir='rtl'] .rtl\:-ml-sm,[dir='rtl'].rtl\:-ml-sm {
  margin-left: -48rem
}

[dir='rtl'] .rtl\:-mt-md,[dir='rtl'].rtl\:-mt-md {
  margin-top: -64rem
}

[dir='rtl'] .rtl\:-mr-md,[dir='rtl'].rtl\:-mr-md {
  margin-right: -64rem
}

[dir='rtl'] .rtl\:-mb-md,[dir='rtl'].rtl\:-mb-md {
  margin-bottom: -64rem
}

[dir='rtl'] .rtl\:-ml-md,[dir='rtl'].rtl\:-ml-md {
  margin-left: -64rem
}

[dir='rtl'] .rtl\:-mt-lg,[dir='rtl'].rtl\:-mt-lg {
  margin-top: -80rem
}

[dir='rtl'] .rtl\:-mr-lg,[dir='rtl'].rtl\:-mr-lg {
  margin-right: -80rem
}

[dir='rtl'] .rtl\:-mb-lg,[dir='rtl'].rtl\:-mb-lg {
  margin-bottom: -80rem
}

[dir='rtl'] .rtl\:-ml-lg,[dir='rtl'].rtl\:-ml-lg {
  margin-left: -80rem
}

[dir='rtl'] .rtl\:-mt-xl,[dir='rtl'].rtl\:-mt-xl {
  margin-top: -96rem
}

[dir='rtl'] .rtl\:-mr-xl,[dir='rtl'].rtl\:-mr-xl {
  margin-right: -96rem
}

[dir='rtl'] .rtl\:-mb-xl,[dir='rtl'].rtl\:-mb-xl {
  margin-bottom: -96rem
}

[dir='rtl'] .rtl\:-ml-xl,[dir='rtl'].rtl\:-ml-xl {
  margin-left: -96rem
}

[dir='rtl'] .rtl\:-mt-2xl,[dir='rtl'].rtl\:-mt-2xl {
  margin-top: -112rem
}

[dir='rtl'] .rtl\:-mr-2xl,[dir='rtl'].rtl\:-mr-2xl {
  margin-right: -112rem
}

[dir='rtl'] .rtl\:-mb-2xl,[dir='rtl'].rtl\:-mb-2xl {
  margin-bottom: -112rem
}

[dir='rtl'] .rtl\:-ml-2xl,[dir='rtl'].rtl\:-ml-2xl {
  margin-left: -112rem
}

[dir='rtl'] .rtl\:-mt-3xl,[dir='rtl'].rtl\:-mt-3xl {
  margin-top: -128rem
}

[dir='rtl'] .rtl\:-mr-3xl,[dir='rtl'].rtl\:-mr-3xl {
  margin-right: -128rem
}

[dir='rtl'] .rtl\:-mb-3xl,[dir='rtl'].rtl\:-mb-3xl {
  margin-bottom: -128rem
}

[dir='rtl'] .rtl\:-ml-3xl,[dir='rtl'].rtl\:-ml-3xl {
  margin-left: -128rem
}

[dir='rtl'] .rtl\:-mt-4xl,[dir='rtl'].rtl\:-mt-4xl {
  margin-top: -144rem
}

[dir='rtl'] .rtl\:-mr-4xl,[dir='rtl'].rtl\:-mr-4xl {
  margin-right: -144rem
}

[dir='rtl'] .rtl\:-mb-4xl,[dir='rtl'].rtl\:-mb-4xl {
  margin-bottom: -144rem
}

[dir='rtl'] .rtl\:-ml-4xl,[dir='rtl'].rtl\:-ml-4xl {
  margin-left: -144rem
}

[dir='rtl'] .rtl\:-mt-5xl,[dir='rtl'].rtl\:-mt-5xl {
  margin-top: -160rem
}

[dir='rtl'] .rtl\:-mr-5xl,[dir='rtl'].rtl\:-mr-5xl {
  margin-right: -160rem
}

[dir='rtl'] .rtl\:-mb-5xl,[dir='rtl'].rtl\:-mb-5xl {
  margin-bottom: -160rem
}

[dir='rtl'] .rtl\:-ml-5xl,[dir='rtl'].rtl\:-ml-5xl {
  margin-left: -160rem
}

[dir='rtl'] .rtl\:-mt-px,[dir='rtl'].rtl\:-mt-px {
  margin-top: -1px
}

[dir='rtl'] .rtl\:-mr-px,[dir='rtl'].rtl\:-mr-px {
  margin-right: -1px
}

[dir='rtl'] .rtl\:-mb-px,[dir='rtl'].rtl\:-mb-px {
  margin-bottom: -1px
}

[dir='rtl'] .rtl\:-ml-px,[dir='rtl'].rtl\:-ml-px {
  margin-left: -1px
}

[dir='rtl'] .rtl\:-mt-0\.5,[dir='rtl'].rtl\:-mt-0\.5 {
  margin-top: -0.05rem
}

[dir='rtl'] .rtl\:-mr-0\.5,[dir='rtl'].rtl\:-mr-0\.5 {
  margin-right: -0.05rem
}

[dir='rtl'] .rtl\:-mb-0\.5,[dir='rtl'].rtl\:-mb-0\.5 {
  margin-bottom: -0.05rem
}

[dir='rtl'] .rtl\:-ml-0\.5,[dir='rtl'].rtl\:-ml-0\.5 {
  margin-left: -0.05rem
}

[dir='rtl'] .rtl\:-mt-1\.5,[dir='rtl'].rtl\:-mt-1\.5 {
  margin-top: -0.15rem
}

[dir='rtl'] .rtl\:-mr-1\.5,[dir='rtl'].rtl\:-mr-1\.5 {
  margin-right: -0.15rem
}

[dir='rtl'] .rtl\:-mb-1\.5,[dir='rtl'].rtl\:-mb-1\.5 {
  margin-bottom: -0.15rem
}

[dir='rtl'] .rtl\:-ml-1\.5,[dir='rtl'].rtl\:-ml-1\.5 {
  margin-left: -0.15rem
}

[dir='rtl'] .rtl\:-mt-2\.5,[dir='rtl'].rtl\:-mt-2\.5 {
  margin-top: -0.25rem
}

[dir='rtl'] .rtl\:-mr-2\.5,[dir='rtl'].rtl\:-mr-2\.5 {
  margin-right: -0.25rem
}

[dir='rtl'] .rtl\:-mb-2\.5,[dir='rtl'].rtl\:-mb-2\.5 {
  margin-bottom: -0.25rem
}

[dir='rtl'] .rtl\:-ml-2\.5,[dir='rtl'].rtl\:-ml-2\.5 {
  margin-left: -0.25rem
}

[dir='rtl'] .rtl\:-mt-3\.5,[dir='rtl'].rtl\:-mt-3\.5 {
  margin-top: -0.35rem
}

[dir='rtl'] .rtl\:-mr-3\.5,[dir='rtl'].rtl\:-mr-3\.5 {
  margin-right: -0.35rem
}

[dir='rtl'] .rtl\:-mb-3\.5,[dir='rtl'].rtl\:-mb-3\.5 {
  margin-bottom: -0.35rem
}

[dir='rtl'] .rtl\:-ml-3\.5,[dir='rtl'].rtl\:-ml-3\.5 {
  margin-left: -0.35rem
}

.max-h-0 {
  max-height: 0
}

.max-h-1 {
  max-height: 0.1rem
}

.max-h-2 {
  max-height: 0.2rem
}

.max-h-3 {
  max-height: 0.3rem
}

.max-h-4 {
  max-height: 0.4rem
}

.max-h-5 {
  max-height: 0.5rem
}

.max-h-6 {
  max-height: 0.6rem
}

.max-h-7 {
  max-height: 0.7rem
}

.max-h-8 {
  max-height: 0.8rem
}

.max-h-9 {
  max-height: 0.9rem
}

.max-h-10 {
  max-height: 1.0rem
}

.max-h-12 {
  max-height: 1.2rem
}

.max-h-14 {
  max-height: 1.4rem
}

.max-h-16 {
  max-height: 1.6rem
}

.max-h-20 {
  max-height: 2rem
}

.max-h-24 {
  max-height: 2.4rem
}

.max-h-28 {
  max-height: 2.8rem
}

.max-h-32 {
  max-height: 3.2rem
}

.max-h-36 {
  max-height: 3.6rem
}

.max-h-40 {
  max-height: 4rem
}

.max-h-44 {
  max-height: 4.4rem
}

.max-h-48 {
  max-height: 4.8rem
}

.max-h-52 {
  max-height: 5.2rem
}

.max-h-56 {
  max-height: 5.6rem
}

.max-h-60 {
  max-height: 6rem
}

.max-h-64 {
  max-height: 6.4rem
}

.max-h-68 {
  max-height: 6.8rem
}

.max-h-72 {
  max-height: 7.2rem
}

.max-h-76 {
  max-height: 7.6rem
}

.max-h-80 {
  max-height: 8rem
}

.max-h-84 {
  max-height: 8.4rem
}

.max-h-88 {
  max-height: 8.8rem
}

.max-h-92 {
  max-height: 9.2rem
}

.max-h-96 {
  max-height: 9.6rem
}

.max-h-128 {
  max-height: 12.8rem
}

.max-h-136 {
  max-height: 13.6rem
}

.max-h-160 {
  max-height: 16rem
}

.max-h-192 {
  max-height: 19.2rem
}

.max-h-200 {
  max-height: 20rem
}

.max-h-208 {
  max-height: 20.8rem
}

.max-h-216 {
  max-height: 21.6rem
}

.max-h-224 {
  max-height: 22.4rem
}

.max-h-256 {
  max-height: 25.6rem
}

.max-h-288 {
  max-height: 28.8rem
}

.max-h-320 {
  max-height: 32rem
}

.max-h-360 {
  max-height: 36rem
}

.max-h-384 {
  max-height: 38.4rem
}

.max-h-400 {
  max-height: 40rem
}

.max-h-512 {
  max-height: 51.2rem
}

.max-h-640 {
  max-height: 64rem
}

.max-h-xs {
  max-height: 32rem
}

.max-h-sm {
  max-height: 48rem
}

.max-h-md {
  max-height: 64rem
}

.max-h-lg {
  max-height: 80rem
}

.max-h-xl {
  max-height: 96rem
}

.max-h-2xl {
  max-height: 112rem
}

.max-h-3xl {
  max-height: 128rem
}

.max-h-4xl {
  max-height: 144rem
}

.max-h-5xl {
  max-height: 160rem
}

.max-h-px {
  max-height: 1px
}

.max-h-0\.5 {
  max-height: 0.05rem
}

.max-h-1\.5 {
  max-height: 0.15rem
}

.max-h-2\.5 {
  max-height: 0.25rem
}

.max-h-3\.5 {
  max-height: 0.35rem
}

.max-h-full {
  max-height: 100%
}

.max-h-screen {
  max-height: 100vh
}

.max-h-auto {
  max-height: auto
}

.max-w-0 {
  max-width: 0
}

.max-w-1 {
  max-width: 0.1rem
}

.max-w-2 {
  max-width: 0.2rem
}

.max-w-3 {
  max-width: 0.3rem
}

.max-w-4 {
  max-width: 0.4rem
}

.max-w-5 {
  max-width: 0.5rem
}

.max-w-6 {
  max-width: 0.6rem
}

.max-w-7 {
  max-width: 0.7rem
}

.max-w-8 {
  max-width: 0.8rem
}

.max-w-9 {
  max-width: 0.9rem
}

.max-w-10 {
  max-width: 1.0rem
}

.max-w-12 {
  max-width: 1.2rem
}

.max-w-14 {
  max-width: 1.4rem
}

.max-w-16 {
  max-width: 1.6rem
}

.max-w-20 {
  max-width: 2rem
}

.max-w-24 {
  max-width: 2.4rem
}

.max-w-28 {
  max-width: 2.8rem
}

.max-w-32 {
  max-width: 3.2rem
}

.max-w-36 {
  max-width: 3.6rem
}

.max-w-40 {
  max-width: 4rem
}

.max-w-44 {
  max-width: 4.4rem
}

.max-w-48 {
  max-width: 4.8rem
}

.max-w-52 {
  max-width: 5.2rem
}

.max-w-56 {
  max-width: 5.6rem
}

.max-w-60 {
  max-width: 6rem
}

.max-w-64 {
  max-width: 6.4rem
}

.max-w-68 {
  max-width: 6.8rem
}

.max-w-72 {
  max-width: 7.2rem
}

.max-w-76 {
  max-width: 7.6rem
}

.max-w-80 {
  max-width: 8rem
}

.max-w-84 {
  max-width: 8.4rem
}

.max-w-88 {
  max-width: 8.8rem
}

.max-w-92 {
  max-width: 9.2rem
}

.max-w-96 {
  max-width: 9.6rem
}

.max-w-128 {
  max-width: 12.8rem
}

.max-w-136 {
  max-width: 13.6rem
}

.max-w-160 {
  max-width: 16rem
}

.max-w-192 {
  max-width: 19.2rem
}

.max-w-200 {
  max-width: 20rem
}

.max-w-208 {
  max-width: 20.8rem
}

.max-w-216 {
  max-width: 21.6rem
}

.max-w-224 {
  max-width: 22.4rem
}

.max-w-256 {
  max-width: 25.6rem
}

.max-w-288 {
  max-width: 28.8rem
}

.max-w-320 {
  max-width: 32rem
}

.max-w-360 {
  max-width: 36rem
}

.max-w-384 {
  max-width: 38.4rem
}

.max-w-400 {
  max-width: 40rem
}

.max-w-512 {
  max-width: 51.2rem
}

.max-w-640 {
  max-width: 64rem
}

.max-w-none {
  max-width: none
}

.max-w-xs {
  max-width: 32rem
}

.max-w-sm {
  max-width: 48rem
}

.max-w-md {
  max-width: 64rem
}

.max-w-lg {
  max-width: 80rem
}

.max-w-xl {
  max-width: 96rem
}

.max-w-2xl {
  max-width: 112rem
}

.max-w-3xl {
  max-width: 128rem
}

.max-w-4xl {
  max-width: 144rem
}

.max-w-5xl {
  max-width: 160rem
}

.max-w-px {
  max-width: 1px
}

.max-w-0\.5 {
  max-width: 0.05rem
}

.max-w-1\.5 {
  max-width: 0.15rem
}

.max-w-2\.5 {
  max-width: 0.25rem
}

.max-w-3\.5 {
  max-width: 0.35rem
}

.max-w-full {
  max-width: 100%
}

.max-w-min {
  max-width: min-content
}

.max-w-max {
  max-width: max-content
}

.max-w-prose {
  max-width: 65ch
}

.max-w-screen-sm {
  max-width: 600px
}

.max-w-screen-md {
  max-width: 960px
}

.max-w-screen-lg {
  max-width: 1280px
}

.max-w-screen-xl {
  max-width: 1920px
}

.min-h-0 {
  min-height: 0
}

.min-h-1 {
  min-height: 0.1rem
}

.min-h-2 {
  min-height: 0.2rem
}

.min-h-3 {
  min-height: 0.3rem
}

.min-h-4 {
  min-height: 0.4rem
}

.min-h-5 {
  min-height: 0.5rem
}

.min-h-6 {
  min-height: 0.6rem
}

.min-h-7 {
  min-height: 0.7rem
}

.min-h-8 {
  min-height: 0.8rem
}

.min-h-9 {
  min-height: 0.9rem
}

.min-h-10 {
  min-height: 1.0rem
}

.min-h-12 {
  min-height: 1.2rem
}

.min-h-14 {
  min-height: 1.4rem
}

.min-h-16 {
  min-height: 1.6rem
}

.min-h-20 {
  min-height: 2rem
}

.min-h-24 {
  min-height: 2.4rem
}

.min-h-28 {
  min-height: 2.8rem
}

.min-h-32 {
  min-height: 3.2rem
}

.min-h-36 {
  min-height: 3.6rem
}

.min-h-40 {
  min-height: 4rem
}

.min-h-44 {
  min-height: 4.4rem
}

.min-h-48 {
  min-height: 4.8rem
}

.min-h-52 {
  min-height: 5.2rem
}

.min-h-56 {
  min-height: 5.6rem
}

.min-h-60 {
  min-height: 6rem
}

.min-h-64 {
  min-height: 6.4rem
}

.min-h-68 {
  min-height: 6.8rem
}

.min-h-72 {
  min-height: 7.2rem
}

.min-h-76 {
  min-height: 7.6rem
}

.min-h-80 {
  min-height: 8rem
}

.min-h-84 {
  min-height: 8.4rem
}

.min-h-88 {
  min-height: 8.8rem
}

.min-h-92 {
  min-height: 9.2rem
}

.min-h-96 {
  min-height: 9.6rem
}

.min-h-128 {
  min-height: 12.8rem
}

.min-h-136 {
  min-height: 13.6rem
}

.min-h-160 {
  min-height: 16rem
}

.min-h-192 {
  min-height: 19.2rem
}

.min-h-200 {
  min-height: 20rem
}

.min-h-208 {
  min-height: 20.8rem
}

.min-h-216 {
  min-height: 21.6rem
}

.min-h-224 {
  min-height: 22.4rem
}

.min-h-256 {
  min-height: 25.6rem
}

.min-h-288 {
  min-height: 28.8rem
}

.min-h-320 {
  min-height: 32rem
}

.min-h-360 {
  min-height: 36rem
}

.min-h-384 {
  min-height: 38.4rem
}

.min-h-400 {
  min-height: 40rem
}

.min-h-512 {
  min-height: 51.2rem
}

.min-h-640 {
  min-height: 64rem
}

.min-h-auto {
  min-height: auto
}

.min-h-xs {
  min-height: 32rem
}

.min-h-sm {
  min-height: 48rem
}

.min-h-md {
  min-height: 64rem
}

.min-h-lg {
  min-height: 80rem
}

.min-h-xl {
  min-height: 96rem
}

.min-h-2xl {
  min-height: 112rem
}

.min-h-3xl {
  min-height: 128rem
}

.min-h-4xl {
  min-height: 144rem
}

.min-h-5xl {
  min-height: 160rem
}

.min-h-px {
  min-height: 1px
}

.min-h-0\.5 {
  min-height: 0.05rem
}

.min-h-1\.5 {
  min-height: 0.15rem
}

.min-h-2\.5 {
  min-height: 0.25rem
}

.min-h-3\.5 {
  min-height: 0.35rem
}

.min-h-full {
  min-height: 100%
}

.min-h-screen {
  min-height: 100vh
}

.min-w-0 {
  min-width: 0
}

.min-w-1 {
  min-width: 0.1rem
}

.min-w-2 {
  min-width: 0.2rem
}

.min-w-3 {
  min-width: 0.3rem
}

.min-w-4 {
  min-width: 0.4rem
}

.min-w-5 {
  min-width: 0.5rem
}

.min-w-6 {
  min-width: 0.6rem
}

.min-w-7 {
  min-width: 0.7rem
}

.min-w-8 {
  min-width: 0.8rem
}

.min-w-9 {
  min-width: 0.9rem
}

.min-w-10 {
  min-width: 1.0rem
}

.min-w-12 {
  min-width: 1.2rem
}

.min-w-14 {
  min-width: 1.4rem
}

.min-w-16 {
  min-width: 1.6rem
}

.min-w-20 {
  min-width: 2rem
}

.min-w-24 {
  min-width: 2.4rem
}

.min-w-28 {
  min-width: 2.8rem
}

.min-w-32 {
  min-width: 3.2rem
}

.min-w-36 {
  min-width: 3.6rem
}

.min-w-40 {
  min-width: 4rem
}

.min-w-44 {
  min-width: 4.4rem
}

.min-w-48 {
  min-width: 4.8rem
}

.min-w-52 {
  min-width: 5.2rem
}

.min-w-56 {
  min-width: 5.6rem
}

.min-w-60 {
  min-width: 6rem
}

.min-w-64 {
  min-width: 6.4rem
}

.min-w-68 {
  min-width: 6.8rem
}

.min-w-72 {
  min-width: 7.2rem
}

.min-w-76 {
  min-width: 7.6rem
}

.min-w-80 {
  min-width: 8rem
}

.min-w-84 {
  min-width: 8.4rem
}

.min-w-88 {
  min-width: 8.8rem
}

.min-w-92 {
  min-width: 9.2rem
}

.min-w-96 {
  min-width: 9.6rem
}

.min-w-128 {
  min-width: 12.8rem
}

.min-w-136 {
  min-width: 13.6rem
}

.min-w-160 {
  min-width: 16rem
}

.min-w-192 {
  min-width: 19.2rem
}

.min-w-200 {
  min-width: 20rem
}

.min-w-208 {
  min-width: 20.8rem
}

.min-w-216 {
  min-width: 21.6rem
}

.min-w-224 {
  min-width: 22.4rem
}

.min-w-256 {
  min-width: 25.6rem
}

.min-w-288 {
  min-width: 28.8rem
}

.min-w-320 {
  min-width: 32rem
}

.min-w-360 {
  min-width: 36rem
}

.min-w-384 {
  min-width: 38.4rem
}

.min-w-400 {
  min-width: 40rem
}

.min-w-512 {
  min-width: 51.2rem
}

.min-w-640 {
  min-width: 64rem
}

.min-w-xs {
  min-width: 32rem
}

.min-w-sm {
  min-width: 48rem
}

.min-w-md {
  min-width: 64rem
}

.min-w-lg {
  min-width: 80rem
}

.min-w-xl {
  min-width: 96rem
}

.min-w-2xl {
  min-width: 112rem
}

.min-w-3xl {
  min-width: 128rem
}

.min-w-4xl {
  min-width: 144rem
}

.min-w-5xl {
  min-width: 160rem
}

.min-w-px {
  min-width: 1px
}

.min-w-0\.5 {
  min-width: 0.05rem
}

.min-w-1\.5 {
  min-width: 0.15rem
}

.min-w-2\.5 {
  min-width: 0.25rem
}

.min-w-3\.5 {
  min-width: 0.35rem
}

.min-w-full {
  min-width: 100%
}

.min-w-min {
  min-width: min-content
}

.min-w-max {
  min-width: max-content
}

.min-w-screen {
  min-width: 100vw
}

.object-contain {
  object-fit: contain
}

.object-cover {
  object-fit: cover
}

.object-fill {
  object-fit: fill
}

.object-none {
  object-fit: none
}

.object-scale-down {
  object-fit: scale-down
}

.object-bottom {
  object-position: bottom
}

.object-center {
  object-position: center
}

.object-left {
  object-position: left
}

.object-left-bottom {
  object-position: left bottom
}

.object-left-top {
  object-position: left top
}

.object-right {
  object-position: right
}

.object-right-bottom {
  object-position: right bottom
}

.object-right-top {
  object-position: right top
}

.object-top {
  object-position: top
}

.opacity-0 {
  opacity: 0
}

.opacity-5 {
  opacity: 0.05
}

.opacity-10 {
  opacity: 0.1
}

.opacity-20 {
  opacity: 0.2
}

.opacity-25 {
  opacity: 0.25
}

.opacity-30 {
  opacity: 0.3
}

.opacity-40 {
  opacity: 0.4
}

.opacity-50 {
  opacity: 0.5
}

.opacity-60 {
  opacity: 0.6
}

.opacity-70 {
  opacity: 0.7
}

.opacity-75 {
  opacity: 0.75
}

.opacity-80 {
  opacity: 0.8
}

.opacity-90 {
  opacity: 0.9
}

.opacity-95 {
  opacity: 0.95
}

.opacity-100 {
  opacity: 1
}

.group:hover .group-hover\:opacity-0 {
  opacity: 0
}

.group:hover .group-hover\:opacity-5 {
  opacity: 0.05
}

.group:hover .group-hover\:opacity-10 {
  opacity: 0.1
}

.group:hover .group-hover\:opacity-20 {
  opacity: 0.2
}

.group:hover .group-hover\:opacity-25 {
  opacity: 0.25
}

.group:hover .group-hover\:opacity-30 {
  opacity: 0.3
}

.group:hover .group-hover\:opacity-40 {
  opacity: 0.4
}

.group:hover .group-hover\:opacity-50 {
  opacity: 0.5
}

.group:hover .group-hover\:opacity-60 {
  opacity: 0.6
}

.group:hover .group-hover\:opacity-70 {
  opacity: 0.7
}

.group:hover .group-hover\:opacity-75 {
  opacity: 0.75
}

.group:hover .group-hover\:opacity-80 {
  opacity: 0.8
}

.group:hover .group-hover\:opacity-90 {
  opacity: 0.9
}

.group:hover .group-hover\:opacity-95 {
  opacity: 0.95
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1
}

.focus-within\:opacity-0:focus-within {
  opacity: 0
}

.focus-within\:opacity-5:focus-within {
  opacity: 0.05
}

.focus-within\:opacity-10:focus-within {
  opacity: 0.1
}

.focus-within\:opacity-20:focus-within {
  opacity: 0.2
}

.focus-within\:opacity-25:focus-within {
  opacity: 0.25
}

.focus-within\:opacity-30:focus-within {
  opacity: 0.3
}

.focus-within\:opacity-40:focus-within {
  opacity: 0.4
}

.focus-within\:opacity-50:focus-within {
  opacity: 0.5
}

.focus-within\:opacity-60:focus-within {
  opacity: 0.6
}

.focus-within\:opacity-70:focus-within {
  opacity: 0.7
}

.focus-within\:opacity-75:focus-within {
  opacity: 0.75
}

.focus-within\:opacity-80:focus-within {
  opacity: 0.8
}

.focus-within\:opacity-90:focus-within {
  opacity: 0.9
}

.focus-within\:opacity-95:focus-within {
  opacity: 0.95
}

.focus-within\:opacity-100:focus-within {
  opacity: 1
}

.hover\:opacity-0:hover {
  opacity: 0
}

.hover\:opacity-5:hover {
  opacity: 0.05
}

.hover\:opacity-10:hover {
  opacity: 0.1
}

.hover\:opacity-20:hover {
  opacity: 0.2
}

.hover\:opacity-25:hover {
  opacity: 0.25
}

.hover\:opacity-30:hover {
  opacity: 0.3
}

.hover\:opacity-40:hover {
  opacity: 0.4
}

.hover\:opacity-50:hover {
  opacity: 0.5
}

.hover\:opacity-60:hover {
  opacity: 0.6
}

.hover\:opacity-70:hover {
  opacity: 0.7
}

.hover\:opacity-75:hover {
  opacity: 0.75
}

.hover\:opacity-80:hover {
  opacity: 0.8
}

.hover\:opacity-90:hover {
  opacity: 0.9
}

.hover\:opacity-95:hover {
  opacity: 0.95
}

.hover\:opacity-100:hover {
  opacity: 1
}

.focus\:opacity-0:focus {
  opacity: 0
}

.focus\:opacity-5:focus {
  opacity: 0.05
}

.focus\:opacity-10:focus {
  opacity: 0.1
}

.focus\:opacity-20:focus {
  opacity: 0.2
}

.focus\:opacity-25:focus {
  opacity: 0.25
}

.focus\:opacity-30:focus {
  opacity: 0.3
}

.focus\:opacity-40:focus {
  opacity: 0.4
}

.focus\:opacity-50:focus {
  opacity: 0.5
}

.focus\:opacity-60:focus {
  opacity: 0.6
}

.focus\:opacity-70:focus {
  opacity: 0.7
}

.focus\:opacity-75:focus {
  opacity: 0.75
}

.focus\:opacity-80:focus {
  opacity: 0.8
}

.focus\:opacity-90:focus {
  opacity: 0.9
}

.focus\:opacity-95:focus {
  opacity: 0.95
}

.focus\:opacity-100:focus {
  opacity: 1
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.outline-white {
  outline: 2px dotted white;
  outline-offset: 2px
}

.outline-black {
  outline: 2px dotted black;
  outline-offset: 2px
}

.focus-within\:outline-none:focus-within {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus-within\:outline-white:focus-within {
  outline: 2px dotted white;
  outline-offset: 2px
}

.focus-within\:outline-black:focus-within {
  outline: 2px dotted black;
  outline-offset: 2px
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:outline-white:focus {
  outline: 2px dotted white;
  outline-offset: 2px
}

.focus\:outline-black:focus {
  outline: 2px dotted black;
  outline-offset: 2px
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-visible {
  overflow: visible
}

.overflow-scroll {
  overflow: scroll
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.overflow-y-hidden {
  overflow-y: hidden
}

.overflow-x-visible {
  overflow-x: visible
}

.overflow-y-visible {
  overflow-y: visible
}

.overflow-x-scroll {
  overflow-x: scroll
}

.overflow-y-scroll {
  overflow-y: scroll
}

.overscroll-auto {
  overscroll-behavior: auto
}

.overscroll-contain {
  overscroll-behavior: contain
}

.overscroll-none {
  overscroll-behavior: none
}

.overscroll-y-auto {
  overscroll-behavior-y: auto
}

.overscroll-y-contain {
  overscroll-behavior-y: contain
}

.overscroll-y-none {
  overscroll-behavior-y: none
}

.overscroll-x-auto {
  overscroll-behavior-x: auto
}

.overscroll-x-contain {
  overscroll-behavior-x: contain
}

.overscroll-x-none {
  overscroll-behavior-x: none
}

.p-0 {
  padding: 0
}

.p-1 {
  padding: 0.1rem
}

.p-2 {
  padding: 0.2rem
}

.p-3 {
  padding: 0.3rem
}

.p-4 {
  padding: 0.4rem
}

.p-5 {
  padding: 0.5rem
}

.p-6 {
  padding: 0.6rem
}

.p-7 {
  padding: 0.7rem
}

.p-8 {
  padding: 0.8rem
}

.p-9 {
  padding: 0.9rem
}

.p-10 {
  padding: 1.0rem
}

.p-12 {
  padding: 1.2rem
}

.p-14 {
  padding: 1.4rem
}

.p-16 {
  padding: 1.6rem
}

.p-20 {
  padding: 2rem
}

.p-24 {
  padding: 2.4rem
}

.p-28 {
  padding: 2.8rem
}

.p-32 {
  padding: 3.2rem
}

.p-36 {
  padding: 3.6rem
}

.p-40 {
  padding: 4rem
}

.p-44 {
  padding: 4.4rem
}

.p-48 {
  padding: 4.8rem
}

.p-52 {
  padding: 5.2rem
}

.p-56 {
  padding: 5.6rem
}

.p-60 {
  padding: 6rem
}

.p-64 {
  padding: 6.4rem
}

.p-68 {
  padding: 6.8rem
}

.p-72 {
  padding: 7.2rem
}

.p-76 {
  padding: 7.6rem
}

.p-80 {
  padding: 8rem
}

.p-84 {
  padding: 8.4rem
}

.p-88 {
  padding: 8.8rem
}

.p-92 {
  padding: 9.2rem
}

.p-96 {
  padding: 9.6rem
}

.p-128 {
  padding: 12.8rem
}

.p-136 {
  padding: 13.6rem
}

.p-160 {
  padding: 16rem
}

.p-192 {
  padding: 19.2rem
}

.p-200 {
  padding: 20rem
}

.p-208 {
  padding: 20.8rem
}

.p-216 {
  padding: 21.6rem
}

.p-224 {
  padding: 22.4rem
}

.p-256 {
  padding: 25.6rem
}

.p-288 {
  padding: 28.8rem
}

.p-320 {
  padding: 32rem
}

.p-360 {
  padding: 36rem
}

.p-384 {
  padding: 38.4rem
}

.p-400 {
  padding: 40rem
}

.p-512 {
  padding: 51.2rem
}

.p-640 {
  padding: 64rem
}

.p-xs {
  padding: 32rem
}

.p-sm {
  padding: 48rem
}

.p-md {
  padding: 64rem
}

.p-lg {
  padding: 80rem
}

.p-xl {
  padding: 96rem
}

.p-2xl {
  padding: 112rem
}

.p-3xl {
  padding: 128rem
}

.p-4xl {
  padding: 144rem
}

.p-5xl {
  padding: 160rem
}

.p-px {
  padding: 1px
}

.p-0\.5 {
  padding: 0.05rem
}

.p-1\.5 {
  padding: 0.15rem
}

.p-2\.5 {
  padding: 0.25rem
}

.p-3\.5 {
  padding: 0.35rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-1 {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem
}

.px-1 {
  padding-left: 0.1rem;
  padding-right: 0.1rem
}

.py-2 {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem
}

.px-2 {
  padding-left: 0.2rem;
  padding-right: 0.2rem
}

.py-3 {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem
}

.px-3 {
  padding-left: 0.3rem;
  padding-right: 0.3rem
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.py-5 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.px-5 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.py-6 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem
}

.px-6 {
  padding-left: 0.6rem;
  padding-right: 0.6rem
}

.py-7 {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem
}

.px-7 {
  padding-left: 0.7rem;
  padding-right: 0.7rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-9 {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem
}

.px-9 {
  padding-left: 0.9rem;
  padding-right: 0.9rem
}

.py-10 {
  padding-top: 1.0rem;
  padding-bottom: 1.0rem
}

.px-10 {
  padding-left: 1.0rem;
  padding-right: 1.0rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-14 {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem
}

.px-14 {
  padding-left: 1.4rem;
  padding-right: 1.4rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.py-20 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.px-20 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.py-28 {
  padding-top: 2.8rem;
  padding-bottom: 2.8rem
}

.px-28 {
  padding-left: 2.8rem;
  padding-right: 2.8rem
}

.py-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem
}

.px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem
}

.py-36 {
  padding-top: 3.6rem;
  padding-bottom: 3.6rem
}

.px-36 {
  padding-left: 3.6rem;
  padding-right: 3.6rem
}

.py-40 {
  padding-top: 4rem;
  padding-bottom: 4rem
}

.px-40 {
  padding-left: 4rem;
  padding-right: 4rem
}

.py-44 {
  padding-top: 4.4rem;
  padding-bottom: 4.4rem
}

.px-44 {
  padding-left: 4.4rem;
  padding-right: 4.4rem
}

.py-48 {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem
}

.px-48 {
  padding-left: 4.8rem;
  padding-right: 4.8rem
}

.py-52 {
  padding-top: 5.2rem;
  padding-bottom: 5.2rem
}

.px-52 {
  padding-left: 5.2rem;
  padding-right: 5.2rem
}

.py-56 {
  padding-top: 5.6rem;
  padding-bottom: 5.6rem
}

.px-56 {
  padding-left: 5.6rem;
  padding-right: 5.6rem
}

.py-60 {
  padding-top: 6rem;
  padding-bottom: 6rem
}

.px-60 {
  padding-left: 6rem;
  padding-right: 6rem
}

.py-64 {
  padding-top: 6.4rem;
  padding-bottom: 6.4rem
}

.px-64 {
  padding-left: 6.4rem;
  padding-right: 6.4rem
}

.py-68 {
  padding-top: 6.8rem;
  padding-bottom: 6.8rem
}

.px-68 {
  padding-left: 6.8rem;
  padding-right: 6.8rem
}

.py-72 {
  padding-top: 7.2rem;
  padding-bottom: 7.2rem
}

.px-72 {
  padding-left: 7.2rem;
  padding-right: 7.2rem
}

.py-76 {
  padding-top: 7.6rem;
  padding-bottom: 7.6rem
}

.px-76 {
  padding-left: 7.6rem;
  padding-right: 7.6rem
}

.py-80 {
  padding-top: 8rem;
  padding-bottom: 8rem
}

.px-80 {
  padding-left: 8rem;
  padding-right: 8rem
}

.py-84 {
  padding-top: 8.4rem;
  padding-bottom: 8.4rem
}

.px-84 {
  padding-left: 8.4rem;
  padding-right: 8.4rem
}

.py-88 {
  padding-top: 8.8rem;
  padding-bottom: 8.8rem
}

.px-88 {
  padding-left: 8.8rem;
  padding-right: 8.8rem
}

.py-92 {
  padding-top: 9.2rem;
  padding-bottom: 9.2rem
}

.px-92 {
  padding-left: 9.2rem;
  padding-right: 9.2rem
}

.py-96 {
  padding-top: 9.6rem;
  padding-bottom: 9.6rem
}

.px-96 {
  padding-left: 9.6rem;
  padding-right: 9.6rem
}

.py-128 {
  padding-top: 12.8rem;
  padding-bottom: 12.8rem
}

.px-128 {
  padding-left: 12.8rem;
  padding-right: 12.8rem
}

.py-136 {
  padding-top: 13.6rem;
  padding-bottom: 13.6rem
}

.px-136 {
  padding-left: 13.6rem;
  padding-right: 13.6rem
}

.py-160 {
  padding-top: 16rem;
  padding-bottom: 16rem
}

.px-160 {
  padding-left: 16rem;
  padding-right: 16rem
}

.py-192 {
  padding-top: 19.2rem;
  padding-bottom: 19.2rem
}

.px-192 {
  padding-left: 19.2rem;
  padding-right: 19.2rem
}

.py-200 {
  padding-top: 20rem;
  padding-bottom: 20rem
}

.px-200 {
  padding-left: 20rem;
  padding-right: 20rem
}

.py-208 {
  padding-top: 20.8rem;
  padding-bottom: 20.8rem
}

.px-208 {
  padding-left: 20.8rem;
  padding-right: 20.8rem
}

.py-216 {
  padding-top: 21.6rem;
  padding-bottom: 21.6rem
}

.px-216 {
  padding-left: 21.6rem;
  padding-right: 21.6rem
}

.py-224 {
  padding-top: 22.4rem;
  padding-bottom: 22.4rem
}

.px-224 {
  padding-left: 22.4rem;
  padding-right: 22.4rem
}

.py-256 {
  padding-top: 25.6rem;
  padding-bottom: 25.6rem
}

.px-256 {
  padding-left: 25.6rem;
  padding-right: 25.6rem
}

.py-288 {
  padding-top: 28.8rem;
  padding-bottom: 28.8rem
}

.px-288 {
  padding-left: 28.8rem;
  padding-right: 28.8rem
}

.py-320 {
  padding-top: 32rem;
  padding-bottom: 32rem
}

.px-320 {
  padding-left: 32rem;
  padding-right: 32rem
}

.py-360 {
  padding-top: 36rem;
  padding-bottom: 36rem
}

.px-360 {
  padding-left: 36rem;
  padding-right: 36rem
}

.py-384 {
  padding-top: 38.4rem;
  padding-bottom: 38.4rem
}

.px-384 {
  padding-left: 38.4rem;
  padding-right: 38.4rem
}

.py-400 {
  padding-top: 40rem;
  padding-bottom: 40rem
}

.px-400 {
  padding-left: 40rem;
  padding-right: 40rem
}

.py-512 {
  padding-top: 51.2rem;
  padding-bottom: 51.2rem
}

.px-512 {
  padding-left: 51.2rem;
  padding-right: 51.2rem
}

.py-640 {
  padding-top: 64rem;
  padding-bottom: 64rem
}

.px-640 {
  padding-left: 64rem;
  padding-right: 64rem
}

.py-xs {
  padding-top: 32rem;
  padding-bottom: 32rem
}

.px-xs {
  padding-left: 32rem;
  padding-right: 32rem
}

.py-sm {
  padding-top: 48rem;
  padding-bottom: 48rem
}

.px-sm {
  padding-left: 48rem;
  padding-right: 48rem
}

.py-md {
  padding-top: 64rem;
  padding-bottom: 64rem
}

.px-md {
  padding-left: 64rem;
  padding-right: 64rem
}

.py-lg {
  padding-top: 80rem;
  padding-bottom: 80rem
}

.px-lg {
  padding-left: 80rem;
  padding-right: 80rem
}

.py-xl {
  padding-top: 96rem;
  padding-bottom: 96rem
}

.px-xl {
  padding-left: 96rem;
  padding-right: 96rem
}

.py-2xl {
  padding-top: 112rem;
  padding-bottom: 112rem
}

.px-2xl {
  padding-left: 112rem;
  padding-right: 112rem
}

.py-3xl {
  padding-top: 128rem;
  padding-bottom: 128rem
}

.px-3xl {
  padding-left: 128rem;
  padding-right: 128rem
}

.py-4xl {
  padding-top: 144rem;
  padding-bottom: 144rem
}

.px-4xl {
  padding-left: 144rem;
  padding-right: 144rem
}

.py-5xl {
  padding-top: 160rem;
  padding-bottom: 160rem
}

.px-5xl {
  padding-left: 160rem;
  padding-right: 160rem
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px
}

.px-px {
  padding-left: 1px;
  padding-right: 1px
}

.py-0\.5 {
  padding-top: 0.05rem;
  padding-bottom: 0.05rem
}

.px-0\.5 {
  padding-left: 0.05rem;
  padding-right: 0.05rem
}

.py-1\.5 {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem
}

.px-1\.5 {
  padding-left: 0.15rem;
  padding-right: 0.15rem
}

.py-2\.5 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.px-2\.5 {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.py-3\.5 {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem
}

.px-3\.5 {
  padding-left: 0.35rem;
  padding-right: 0.35rem
}

.pt-0 {
  padding-top: 0
}

.pr-0 {
  padding-right: 0
}

.pb-0 {
  padding-bottom: 0
}

.pl-0 {
  padding-left: 0
}

.pt-1 {
  padding-top: 0.1rem
}

.pr-1 {
  padding-right: 0.1rem
}

.pb-1 {
  padding-bottom: 0.1rem
}

.pl-1 {
  padding-left: 0.1rem
}

.pt-2 {
  padding-top: 0.2rem
}

.pr-2 {
  padding-right: 0.2rem
}

.pb-2 {
  padding-bottom: 0.2rem
}

.pl-2 {
  padding-left: 0.2rem
}

.pt-3 {
  padding-top: 0.3rem
}

.pr-3 {
  padding-right: 0.3rem
}

.pb-3 {
  padding-bottom: 0.3rem
}

.pl-3 {
  padding-left: 0.3rem
}

.pt-4 {
  padding-top: 0.4rem
}

.pr-4 {
  padding-right: 0.4rem
}

.pb-4 {
  padding-bottom: 0.4rem
}

.pl-4 {
  padding-left: 0.4rem
}

.pt-5 {
  padding-top: 0.5rem
}

.pr-5 {
  padding-right: 0.5rem
}

.pb-5 {
  padding-bottom: 0.5rem
}

.pl-5 {
  padding-left: 0.5rem
}

.pt-6 {
  padding-top: 0.6rem
}

.pr-6 {
  padding-right: 0.6rem
}

.pb-6 {
  padding-bottom: 0.6rem
}

.pl-6 {
  padding-left: 0.6rem
}

.pt-7 {
  padding-top: 0.7rem
}

.pr-7 {
  padding-right: 0.7rem
}

.pb-7 {
  padding-bottom: 0.7rem
}

.pl-7 {
  padding-left: 0.7rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pr-8 {
  padding-right: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pt-9 {
  padding-top: 0.9rem
}

.pr-9 {
  padding-right: 0.9rem
}

.pb-9 {
  padding-bottom: 0.9rem
}

.pl-9 {
  padding-left: 0.9rem
}

.pt-10 {
  padding-top: 1.0rem
}

.pr-10 {
  padding-right: 1.0rem
}

.pb-10 {
  padding-bottom: 1.0rem
}

.pl-10 {
  padding-left: 1.0rem
}

.pt-12 {
  padding-top: 1.2rem
}

.pr-12 {
  padding-right: 1.2rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pl-12 {
  padding-left: 1.2rem
}

.pt-14 {
  padding-top: 1.4rem
}

.pr-14 {
  padding-right: 1.4rem
}

.pb-14 {
  padding-bottom: 1.4rem
}

.pl-14 {
  padding-left: 1.4rem
}

.pt-16 {
  padding-top: 1.6rem
}

.pr-16 {
  padding-right: 1.6rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pt-20 {
  padding-top: 2rem
}

.pr-20 {
  padding-right: 2rem
}

.pb-20 {
  padding-bottom: 2rem
}

.pl-20 {
  padding-left: 2rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pr-24 {
  padding-right: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pt-28 {
  padding-top: 2.8rem
}

.pr-28 {
  padding-right: 2.8rem
}

.pb-28 {
  padding-bottom: 2.8rem
}

.pl-28 {
  padding-left: 2.8rem
}

.pt-32 {
  padding-top: 3.2rem
}

.pr-32 {
  padding-right: 3.2rem
}

.pb-32 {
  padding-bottom: 3.2rem
}

.pl-32 {
  padding-left: 3.2rem
}

.pt-36 {
  padding-top: 3.6rem
}

.pr-36 {
  padding-right: 3.6rem
}

.pb-36 {
  padding-bottom: 3.6rem
}

.pl-36 {
  padding-left: 3.6rem
}

.pt-40 {
  padding-top: 4rem
}

.pr-40 {
  padding-right: 4rem
}

.pb-40 {
  padding-bottom: 4rem
}

.pl-40 {
  padding-left: 4rem
}

.pt-44 {
  padding-top: 4.4rem
}

.pr-44 {
  padding-right: 4.4rem
}

.pb-44 {
  padding-bottom: 4.4rem
}

.pl-44 {
  padding-left: 4.4rem
}

.pt-48 {
  padding-top: 4.8rem
}

.pr-48 {
  padding-right: 4.8rem
}

.pb-48 {
  padding-bottom: 4.8rem
}

.pl-48 {
  padding-left: 4.8rem
}

.pt-52 {
  padding-top: 5.2rem
}

.pr-52 {
  padding-right: 5.2rem
}

.pb-52 {
  padding-bottom: 5.2rem
}

.pl-52 {
  padding-left: 5.2rem
}

.pt-56 {
  padding-top: 5.6rem
}

.pr-56 {
  padding-right: 5.6rem
}

.pb-56 {
  padding-bottom: 5.6rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pt-60 {
  padding-top: 6rem
}

.pr-60 {
  padding-right: 6rem
}

.pb-60 {
  padding-bottom: 6rem
}

.pl-60 {
  padding-left: 6rem
}

.pt-64 {
  padding-top: 6.4rem
}

.pr-64 {
  padding-right: 6.4rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pl-64 {
  padding-left: 6.4rem
}

.pt-68 {
  padding-top: 6.8rem
}

.pr-68 {
  padding-right: 6.8rem
}

.pb-68 {
  padding-bottom: 6.8rem
}

.pl-68 {
  padding-left: 6.8rem
}

.pt-72 {
  padding-top: 7.2rem
}

.pr-72 {
  padding-right: 7.2rem
}

.pb-72 {
  padding-bottom: 7.2rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pt-76 {
  padding-top: 7.6rem
}

.pr-76 {
  padding-right: 7.6rem
}

.pb-76 {
  padding-bottom: 7.6rem
}

.pl-76 {
  padding-left: 7.6rem
}

.pt-80 {
  padding-top: 8rem
}

.pr-80 {
  padding-right: 8rem
}

.pb-80 {
  padding-bottom: 8rem
}

.pl-80 {
  padding-left: 8rem
}

.pt-84 {
  padding-top: 8.4rem
}

.pr-84 {
  padding-right: 8.4rem
}

.pb-84 {
  padding-bottom: 8.4rem
}

.pl-84 {
  padding-left: 8.4rem
}

.pt-88 {
  padding-top: 8.8rem
}

.pr-88 {
  padding-right: 8.8rem
}

.pb-88 {
  padding-bottom: 8.8rem
}

.pl-88 {
  padding-left: 8.8rem
}

.pt-92 {
  padding-top: 9.2rem
}

.pr-92 {
  padding-right: 9.2rem
}

.pb-92 {
  padding-bottom: 9.2rem
}

.pl-92 {
  padding-left: 9.2rem
}

.pt-96 {
  padding-top: 9.6rem
}

.pr-96 {
  padding-right: 9.6rem
}

.pb-96 {
  padding-bottom: 9.6rem
}

.pl-96 {
  padding-left: 9.6rem
}

.pt-128 {
  padding-top: 12.8rem
}

.pr-128 {
  padding-right: 12.8rem
}

.pb-128 {
  padding-bottom: 12.8rem
}

.pl-128 {
  padding-left: 12.8rem
}

.pt-136 {
  padding-top: 13.6rem
}

.pr-136 {
  padding-right: 13.6rem
}

.pb-136 {
  padding-bottom: 13.6rem
}

.pl-136 {
  padding-left: 13.6rem
}

.pt-160 {
  padding-top: 16rem
}

.pr-160 {
  padding-right: 16rem
}

.pb-160 {
  padding-bottom: 16rem
}

.pl-160 {
  padding-left: 16rem
}

.pt-192 {
  padding-top: 19.2rem
}

.pr-192 {
  padding-right: 19.2rem
}

.pb-192 {
  padding-bottom: 19.2rem
}

.pl-192 {
  padding-left: 19.2rem
}

.pt-200 {
  padding-top: 20rem
}

.pr-200 {
  padding-right: 20rem
}

.pb-200 {
  padding-bottom: 20rem
}

.pl-200 {
  padding-left: 20rem
}

.pt-208 {
  padding-top: 20.8rem
}

.pr-208 {
  padding-right: 20.8rem
}

.pb-208 {
  padding-bottom: 20.8rem
}

.pl-208 {
  padding-left: 20.8rem
}

.pt-216 {
  padding-top: 21.6rem
}

.pr-216 {
  padding-right: 21.6rem
}

.pb-216 {
  padding-bottom: 21.6rem
}

.pl-216 {
  padding-left: 21.6rem
}

.pt-224 {
  padding-top: 22.4rem
}

.pr-224 {
  padding-right: 22.4rem
}

.pb-224 {
  padding-bottom: 22.4rem
}

.pl-224 {
  padding-left: 22.4rem
}

.pt-256 {
  padding-top: 25.6rem
}

.pr-256 {
  padding-right: 25.6rem
}

.pb-256 {
  padding-bottom: 25.6rem
}

.pl-256 {
  padding-left: 25.6rem
}

.pt-288 {
  padding-top: 28.8rem
}

.pr-288 {
  padding-right: 28.8rem
}

.pb-288 {
  padding-bottom: 28.8rem
}

.pl-288 {
  padding-left: 28.8rem
}

.pt-320 {
  padding-top: 32rem
}

.pr-320 {
  padding-right: 32rem
}

.pb-320 {
  padding-bottom: 32rem
}

.pl-320 {
  padding-left: 32rem
}

.pt-360 {
  padding-top: 36rem
}

.pr-360 {
  padding-right: 36rem
}

.pb-360 {
  padding-bottom: 36rem
}

.pl-360 {
  padding-left: 36rem
}

.pt-384 {
  padding-top: 38.4rem
}

.pr-384 {
  padding-right: 38.4rem
}

.pb-384 {
  padding-bottom: 38.4rem
}

.pl-384 {
  padding-left: 38.4rem
}

.pt-400 {
  padding-top: 40rem
}

.pr-400 {
  padding-right: 40rem
}

.pb-400 {
  padding-bottom: 40rem
}

.pl-400 {
  padding-left: 40rem
}

.pt-512 {
  padding-top: 51.2rem
}

.pr-512 {
  padding-right: 51.2rem
}

.pb-512 {
  padding-bottom: 51.2rem
}

.pl-512 {
  padding-left: 51.2rem
}

.pt-640 {
  padding-top: 64rem
}

.pr-640 {
  padding-right: 64rem
}

.pb-640 {
  padding-bottom: 64rem
}

.pl-640 {
  padding-left: 64rem
}

.pt-xs {
  padding-top: 32rem
}

.pr-xs {
  padding-right: 32rem
}

.pb-xs {
  padding-bottom: 32rem
}

.pl-xs {
  padding-left: 32rem
}

.pt-sm {
  padding-top: 48rem
}

.pr-sm {
  padding-right: 48rem
}

.pb-sm {
  padding-bottom: 48rem
}

.pl-sm {
  padding-left: 48rem
}

.pt-md {
  padding-top: 64rem
}

.pr-md {
  padding-right: 64rem
}

.pb-md {
  padding-bottom: 64rem
}

.pl-md {
  padding-left: 64rem
}

.pt-lg {
  padding-top: 80rem
}

.pr-lg {
  padding-right: 80rem
}

.pb-lg {
  padding-bottom: 80rem
}

.pl-lg {
  padding-left: 80rem
}

.pt-xl {
  padding-top: 96rem
}

.pr-xl {
  padding-right: 96rem
}

.pb-xl {
  padding-bottom: 96rem
}

.pl-xl {
  padding-left: 96rem
}

.pt-2xl {
  padding-top: 112rem
}

.pr-2xl {
  padding-right: 112rem
}

.pb-2xl {
  padding-bottom: 112rem
}

.pl-2xl {
  padding-left: 112rem
}

.pt-3xl {
  padding-top: 128rem
}

.pr-3xl {
  padding-right: 128rem
}

.pb-3xl {
  padding-bottom: 128rem
}

.pl-3xl {
  padding-left: 128rem
}

.pt-4xl {
  padding-top: 144rem
}

.pr-4xl {
  padding-right: 144rem
}

.pb-4xl {
  padding-bottom: 144rem
}

.pl-4xl {
  padding-left: 144rem
}

.pt-5xl {
  padding-top: 160rem
}

.pr-5xl {
  padding-right: 160rem
}

.pb-5xl {
  padding-bottom: 160rem
}

.pl-5xl {
  padding-left: 160rem
}

.pt-px {
  padding-top: 1px
}

.pr-px {
  padding-right: 1px
}

.pb-px {
  padding-bottom: 1px
}

.pl-px {
  padding-left: 1px
}

.pt-0\.5 {
  padding-top: 0.05rem
}

.pr-0\.5 {
  padding-right: 0.05rem
}

.pb-0\.5 {
  padding-bottom: 0.05rem
}

.pl-0\.5 {
  padding-left: 0.05rem
}

.pt-1\.5 {
  padding-top: 0.15rem
}

.pr-1\.5 {
  padding-right: 0.15rem
}

.pb-1\.5 {
  padding-bottom: 0.15rem
}

.pl-1\.5 {
  padding-left: 0.15rem
}

.pt-2\.5 {
  padding-top: 0.25rem
}

.pr-2\.5 {
  padding-right: 0.25rem
}

.pb-2\.5 {
  padding-bottom: 0.25rem
}

.pl-2\.5 {
  padding-left: 0.25rem
}

.pt-3\.5 {
  padding-top: 0.35rem
}

.pr-3\.5 {
  padding-right: 0.35rem
}

.pb-3\.5 {
  padding-bottom: 0.35rem
}

.pl-3\.5 {
  padding-left: 0.35rem
}

[dir='ltr'] .ltr\:p-0,[dir='ltr'].ltr\:p-0 {
  padding: 0
}

[dir='ltr'] .ltr\:p-1,[dir='ltr'].ltr\:p-1 {
  padding: 0.1rem
}

[dir='ltr'] .ltr\:p-2,[dir='ltr'].ltr\:p-2 {
  padding: 0.2rem
}

[dir='ltr'] .ltr\:p-3,[dir='ltr'].ltr\:p-3 {
  padding: 0.3rem
}

[dir='ltr'] .ltr\:p-4,[dir='ltr'].ltr\:p-4 {
  padding: 0.4rem
}

[dir='ltr'] .ltr\:p-5,[dir='ltr'].ltr\:p-5 {
  padding: 0.5rem
}

[dir='ltr'] .ltr\:p-6,[dir='ltr'].ltr\:p-6 {
  padding: 0.6rem
}

[dir='ltr'] .ltr\:p-7,[dir='ltr'].ltr\:p-7 {
  padding: 0.7rem
}

[dir='ltr'] .ltr\:p-8,[dir='ltr'].ltr\:p-8 {
  padding: 0.8rem
}

[dir='ltr'] .ltr\:p-9,[dir='ltr'].ltr\:p-9 {
  padding: 0.9rem
}

[dir='ltr'] .ltr\:p-10,[dir='ltr'].ltr\:p-10 {
  padding: 1.0rem
}

[dir='ltr'] .ltr\:p-12,[dir='ltr'].ltr\:p-12 {
  padding: 1.2rem
}

[dir='ltr'] .ltr\:p-14,[dir='ltr'].ltr\:p-14 {
  padding: 1.4rem
}

[dir='ltr'] .ltr\:p-16,[dir='ltr'].ltr\:p-16 {
  padding: 1.6rem
}

[dir='ltr'] .ltr\:p-20,[dir='ltr'].ltr\:p-20 {
  padding: 2rem
}

[dir='ltr'] .ltr\:p-24,[dir='ltr'].ltr\:p-24 {
  padding: 2.4rem
}

[dir='ltr'] .ltr\:p-28,[dir='ltr'].ltr\:p-28 {
  padding: 2.8rem
}

[dir='ltr'] .ltr\:p-32,[dir='ltr'].ltr\:p-32 {
  padding: 3.2rem
}

[dir='ltr'] .ltr\:p-36,[dir='ltr'].ltr\:p-36 {
  padding: 3.6rem
}

[dir='ltr'] .ltr\:p-40,[dir='ltr'].ltr\:p-40 {
  padding: 4rem
}

[dir='ltr'] .ltr\:p-44,[dir='ltr'].ltr\:p-44 {
  padding: 4.4rem
}

[dir='ltr'] .ltr\:p-48,[dir='ltr'].ltr\:p-48 {
  padding: 4.8rem
}

[dir='ltr'] .ltr\:p-52,[dir='ltr'].ltr\:p-52 {
  padding: 5.2rem
}

[dir='ltr'] .ltr\:p-56,[dir='ltr'].ltr\:p-56 {
  padding: 5.6rem
}

[dir='ltr'] .ltr\:p-60,[dir='ltr'].ltr\:p-60 {
  padding: 6rem
}

[dir='ltr'] .ltr\:p-64,[dir='ltr'].ltr\:p-64 {
  padding: 6.4rem
}

[dir='ltr'] .ltr\:p-68,[dir='ltr'].ltr\:p-68 {
  padding: 6.8rem
}

[dir='ltr'] .ltr\:p-72,[dir='ltr'].ltr\:p-72 {
  padding: 7.2rem
}

[dir='ltr'] .ltr\:p-76,[dir='ltr'].ltr\:p-76 {
  padding: 7.6rem
}

[dir='ltr'] .ltr\:p-80,[dir='ltr'].ltr\:p-80 {
  padding: 8rem
}

[dir='ltr'] .ltr\:p-84,[dir='ltr'].ltr\:p-84 {
  padding: 8.4rem
}

[dir='ltr'] .ltr\:p-88,[dir='ltr'].ltr\:p-88 {
  padding: 8.8rem
}

[dir='ltr'] .ltr\:p-92,[dir='ltr'].ltr\:p-92 {
  padding: 9.2rem
}

[dir='ltr'] .ltr\:p-96,[dir='ltr'].ltr\:p-96 {
  padding: 9.6rem
}

[dir='ltr'] .ltr\:p-128,[dir='ltr'].ltr\:p-128 {
  padding: 12.8rem
}

[dir='ltr'] .ltr\:p-136,[dir='ltr'].ltr\:p-136 {
  padding: 13.6rem
}

[dir='ltr'] .ltr\:p-160,[dir='ltr'].ltr\:p-160 {
  padding: 16rem
}

[dir='ltr'] .ltr\:p-192,[dir='ltr'].ltr\:p-192 {
  padding: 19.2rem
}

[dir='ltr'] .ltr\:p-200,[dir='ltr'].ltr\:p-200 {
  padding: 20rem
}

[dir='ltr'] .ltr\:p-208,[dir='ltr'].ltr\:p-208 {
  padding: 20.8rem
}

[dir='ltr'] .ltr\:p-216,[dir='ltr'].ltr\:p-216 {
  padding: 21.6rem
}

[dir='ltr'] .ltr\:p-224,[dir='ltr'].ltr\:p-224 {
  padding: 22.4rem
}

[dir='ltr'] .ltr\:p-256,[dir='ltr'].ltr\:p-256 {
  padding: 25.6rem
}

[dir='ltr'] .ltr\:p-288,[dir='ltr'].ltr\:p-288 {
  padding: 28.8rem
}

[dir='ltr'] .ltr\:p-320,[dir='ltr'].ltr\:p-320 {
  padding: 32rem
}

[dir='ltr'] .ltr\:p-360,[dir='ltr'].ltr\:p-360 {
  padding: 36rem
}

[dir='ltr'] .ltr\:p-384,[dir='ltr'].ltr\:p-384 {
  padding: 38.4rem
}

[dir='ltr'] .ltr\:p-400,[dir='ltr'].ltr\:p-400 {
  padding: 40rem
}

[dir='ltr'] .ltr\:p-512,[dir='ltr'].ltr\:p-512 {
  padding: 51.2rem
}

[dir='ltr'] .ltr\:p-640,[dir='ltr'].ltr\:p-640 {
  padding: 64rem
}

[dir='ltr'] .ltr\:p-xs,[dir='ltr'].ltr\:p-xs {
  padding: 32rem
}

[dir='ltr'] .ltr\:p-sm,[dir='ltr'].ltr\:p-sm {
  padding: 48rem
}

[dir='ltr'] .ltr\:p-md,[dir='ltr'].ltr\:p-md {
  padding: 64rem
}

[dir='ltr'] .ltr\:p-lg,[dir='ltr'].ltr\:p-lg {
  padding: 80rem
}

[dir='ltr'] .ltr\:p-xl,[dir='ltr'].ltr\:p-xl {
  padding: 96rem
}

[dir='ltr'] .ltr\:p-2xl,[dir='ltr'].ltr\:p-2xl {
  padding: 112rem
}

[dir='ltr'] .ltr\:p-3xl,[dir='ltr'].ltr\:p-3xl {
  padding: 128rem
}

[dir='ltr'] .ltr\:p-4xl,[dir='ltr'].ltr\:p-4xl {
  padding: 144rem
}

[dir='ltr'] .ltr\:p-5xl,[dir='ltr'].ltr\:p-5xl {
  padding: 160rem
}

[dir='ltr'] .ltr\:p-px,[dir='ltr'].ltr\:p-px {
  padding: 1px
}

[dir='ltr'] .ltr\:p-0\.5,[dir='ltr'].ltr\:p-0\.5 {
  padding: 0.05rem
}

[dir='ltr'] .ltr\:p-1\.5,[dir='ltr'].ltr\:p-1\.5 {
  padding: 0.15rem
}

[dir='ltr'] .ltr\:p-2\.5,[dir='ltr'].ltr\:p-2\.5 {
  padding: 0.25rem
}

[dir='ltr'] .ltr\:p-3\.5,[dir='ltr'].ltr\:p-3\.5 {
  padding: 0.35rem
}

[dir='ltr'] .ltr\:py-0,[dir='ltr'].ltr\:py-0 {
  padding-top: 0;
  padding-bottom: 0
}

[dir='ltr'] .ltr\:px-0,[dir='ltr'].ltr\:px-0 {
  padding-left: 0;
  padding-right: 0
}

[dir='ltr'] .ltr\:py-1,[dir='ltr'].ltr\:py-1 {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem
}

[dir='ltr'] .ltr\:px-1,[dir='ltr'].ltr\:px-1 {
  padding-left: 0.1rem;
  padding-right: 0.1rem
}

[dir='ltr'] .ltr\:py-2,[dir='ltr'].ltr\:py-2 {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem
}

[dir='ltr'] .ltr\:px-2,[dir='ltr'].ltr\:px-2 {
  padding-left: 0.2rem;
  padding-right: 0.2rem
}

[dir='ltr'] .ltr\:py-3,[dir='ltr'].ltr\:py-3 {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem
}

[dir='ltr'] .ltr\:px-3,[dir='ltr'].ltr\:px-3 {
  padding-left: 0.3rem;
  padding-right: 0.3rem
}

[dir='ltr'] .ltr\:py-4,[dir='ltr'].ltr\:py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

[dir='ltr'] .ltr\:px-4,[dir='ltr'].ltr\:px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

[dir='ltr'] .ltr\:py-5,[dir='ltr'].ltr\:py-5 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

[dir='ltr'] .ltr\:px-5,[dir='ltr'].ltr\:px-5 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

[dir='ltr'] .ltr\:py-6,[dir='ltr'].ltr\:py-6 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem
}

[dir='ltr'] .ltr\:px-6,[dir='ltr'].ltr\:px-6 {
  padding-left: 0.6rem;
  padding-right: 0.6rem
}

[dir='ltr'] .ltr\:py-7,[dir='ltr'].ltr\:py-7 {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem
}

[dir='ltr'] .ltr\:px-7,[dir='ltr'].ltr\:px-7 {
  padding-left: 0.7rem;
  padding-right: 0.7rem
}

[dir='ltr'] .ltr\:py-8,[dir='ltr'].ltr\:py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

[dir='ltr'] .ltr\:px-8,[dir='ltr'].ltr\:px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

[dir='ltr'] .ltr\:py-9,[dir='ltr'].ltr\:py-9 {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem
}

[dir='ltr'] .ltr\:px-9,[dir='ltr'].ltr\:px-9 {
  padding-left: 0.9rem;
  padding-right: 0.9rem
}

[dir='ltr'] .ltr\:py-10,[dir='ltr'].ltr\:py-10 {
  padding-top: 1.0rem;
  padding-bottom: 1.0rem
}

[dir='ltr'] .ltr\:px-10,[dir='ltr'].ltr\:px-10 {
  padding-left: 1.0rem;
  padding-right: 1.0rem
}

[dir='ltr'] .ltr\:py-12,[dir='ltr'].ltr\:py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

[dir='ltr'] .ltr\:px-12,[dir='ltr'].ltr\:px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

[dir='ltr'] .ltr\:py-14,[dir='ltr'].ltr\:py-14 {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem
}

[dir='ltr'] .ltr\:px-14,[dir='ltr'].ltr\:px-14 {
  padding-left: 1.4rem;
  padding-right: 1.4rem
}

[dir='ltr'] .ltr\:py-16,[dir='ltr'].ltr\:py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

[dir='ltr'] .ltr\:px-16,[dir='ltr'].ltr\:px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

[dir='ltr'] .ltr\:py-20,[dir='ltr'].ltr\:py-20 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

[dir='ltr'] .ltr\:px-20,[dir='ltr'].ltr\:px-20 {
  padding-left: 2rem;
  padding-right: 2rem
}

[dir='ltr'] .ltr\:py-24,[dir='ltr'].ltr\:py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem
}

[dir='ltr'] .ltr\:px-24,[dir='ltr'].ltr\:px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

[dir='ltr'] .ltr\:py-28,[dir='ltr'].ltr\:py-28 {
  padding-top: 2.8rem;
  padding-bottom: 2.8rem
}

[dir='ltr'] .ltr\:px-28,[dir='ltr'].ltr\:px-28 {
  padding-left: 2.8rem;
  padding-right: 2.8rem
}

[dir='ltr'] .ltr\:py-32,[dir='ltr'].ltr\:py-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem
}

[dir='ltr'] .ltr\:px-32,[dir='ltr'].ltr\:px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem
}

[dir='ltr'] .ltr\:py-36,[dir='ltr'].ltr\:py-36 {
  padding-top: 3.6rem;
  padding-bottom: 3.6rem
}

[dir='ltr'] .ltr\:px-36,[dir='ltr'].ltr\:px-36 {
  padding-left: 3.6rem;
  padding-right: 3.6rem
}

[dir='ltr'] .ltr\:py-40,[dir='ltr'].ltr\:py-40 {
  padding-top: 4rem;
  padding-bottom: 4rem
}

[dir='ltr'] .ltr\:px-40,[dir='ltr'].ltr\:px-40 {
  padding-left: 4rem;
  padding-right: 4rem
}

[dir='ltr'] .ltr\:py-44,[dir='ltr'].ltr\:py-44 {
  padding-top: 4.4rem;
  padding-bottom: 4.4rem
}

[dir='ltr'] .ltr\:px-44,[dir='ltr'].ltr\:px-44 {
  padding-left: 4.4rem;
  padding-right: 4.4rem
}

[dir='ltr'] .ltr\:py-48,[dir='ltr'].ltr\:py-48 {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem
}

[dir='ltr'] .ltr\:px-48,[dir='ltr'].ltr\:px-48 {
  padding-left: 4.8rem;
  padding-right: 4.8rem
}

[dir='ltr'] .ltr\:py-52,[dir='ltr'].ltr\:py-52 {
  padding-top: 5.2rem;
  padding-bottom: 5.2rem
}

[dir='ltr'] .ltr\:px-52,[dir='ltr'].ltr\:px-52 {
  padding-left: 5.2rem;
  padding-right: 5.2rem
}

[dir='ltr'] .ltr\:py-56,[dir='ltr'].ltr\:py-56 {
  padding-top: 5.6rem;
  padding-bottom: 5.6rem
}

[dir='ltr'] .ltr\:px-56,[dir='ltr'].ltr\:px-56 {
  padding-left: 5.6rem;
  padding-right: 5.6rem
}

[dir='ltr'] .ltr\:py-60,[dir='ltr'].ltr\:py-60 {
  padding-top: 6rem;
  padding-bottom: 6rem
}

[dir='ltr'] .ltr\:px-60,[dir='ltr'].ltr\:px-60 {
  padding-left: 6rem;
  padding-right: 6rem
}

[dir='ltr'] .ltr\:py-64,[dir='ltr'].ltr\:py-64 {
  padding-top: 6.4rem;
  padding-bottom: 6.4rem
}

[dir='ltr'] .ltr\:px-64,[dir='ltr'].ltr\:px-64 {
  padding-left: 6.4rem;
  padding-right: 6.4rem
}

[dir='ltr'] .ltr\:py-68,[dir='ltr'].ltr\:py-68 {
  padding-top: 6.8rem;
  padding-bottom: 6.8rem
}

[dir='ltr'] .ltr\:px-68,[dir='ltr'].ltr\:px-68 {
  padding-left: 6.8rem;
  padding-right: 6.8rem
}

[dir='ltr'] .ltr\:py-72,[dir='ltr'].ltr\:py-72 {
  padding-top: 7.2rem;
  padding-bottom: 7.2rem
}

[dir='ltr'] .ltr\:px-72,[dir='ltr'].ltr\:px-72 {
  padding-left: 7.2rem;
  padding-right: 7.2rem
}

[dir='ltr'] .ltr\:py-76,[dir='ltr'].ltr\:py-76 {
  padding-top: 7.6rem;
  padding-bottom: 7.6rem
}

[dir='ltr'] .ltr\:px-76,[dir='ltr'].ltr\:px-76 {
  padding-left: 7.6rem;
  padding-right: 7.6rem
}

[dir='ltr'] .ltr\:py-80,[dir='ltr'].ltr\:py-80 {
  padding-top: 8rem;
  padding-bottom: 8rem
}

[dir='ltr'] .ltr\:px-80,[dir='ltr'].ltr\:px-80 {
  padding-left: 8rem;
  padding-right: 8rem
}

[dir='ltr'] .ltr\:py-84,[dir='ltr'].ltr\:py-84 {
  padding-top: 8.4rem;
  padding-bottom: 8.4rem
}

[dir='ltr'] .ltr\:px-84,[dir='ltr'].ltr\:px-84 {
  padding-left: 8.4rem;
  padding-right: 8.4rem
}

[dir='ltr'] .ltr\:py-88,[dir='ltr'].ltr\:py-88 {
  padding-top: 8.8rem;
  padding-bottom: 8.8rem
}

[dir='ltr'] .ltr\:px-88,[dir='ltr'].ltr\:px-88 {
  padding-left: 8.8rem;
  padding-right: 8.8rem
}

[dir='ltr'] .ltr\:py-92,[dir='ltr'].ltr\:py-92 {
  padding-top: 9.2rem;
  padding-bottom: 9.2rem
}

[dir='ltr'] .ltr\:px-92,[dir='ltr'].ltr\:px-92 {
  padding-left: 9.2rem;
  padding-right: 9.2rem
}

[dir='ltr'] .ltr\:py-96,[dir='ltr'].ltr\:py-96 {
  padding-top: 9.6rem;
  padding-bottom: 9.6rem
}

[dir='ltr'] .ltr\:px-96,[dir='ltr'].ltr\:px-96 {
  padding-left: 9.6rem;
  padding-right: 9.6rem
}

[dir='ltr'] .ltr\:py-128,[dir='ltr'].ltr\:py-128 {
  padding-top: 12.8rem;
  padding-bottom: 12.8rem
}

[dir='ltr'] .ltr\:px-128,[dir='ltr'].ltr\:px-128 {
  padding-left: 12.8rem;
  padding-right: 12.8rem
}

[dir='ltr'] .ltr\:py-136,[dir='ltr'].ltr\:py-136 {
  padding-top: 13.6rem;
  padding-bottom: 13.6rem
}

[dir='ltr'] .ltr\:px-136,[dir='ltr'].ltr\:px-136 {
  padding-left: 13.6rem;
  padding-right: 13.6rem
}

[dir='ltr'] .ltr\:py-160,[dir='ltr'].ltr\:py-160 {
  padding-top: 16rem;
  padding-bottom: 16rem
}

[dir='ltr'] .ltr\:px-160,[dir='ltr'].ltr\:px-160 {
  padding-left: 16rem;
  padding-right: 16rem
}

[dir='ltr'] .ltr\:py-192,[dir='ltr'].ltr\:py-192 {
  padding-top: 19.2rem;
  padding-bottom: 19.2rem
}

[dir='ltr'] .ltr\:px-192,[dir='ltr'].ltr\:px-192 {
  padding-left: 19.2rem;
  padding-right: 19.2rem
}

[dir='ltr'] .ltr\:py-200,[dir='ltr'].ltr\:py-200 {
  padding-top: 20rem;
  padding-bottom: 20rem
}

[dir='ltr'] .ltr\:px-200,[dir='ltr'].ltr\:px-200 {
  padding-left: 20rem;
  padding-right: 20rem
}

[dir='ltr'] .ltr\:py-208,[dir='ltr'].ltr\:py-208 {
  padding-top: 20.8rem;
  padding-bottom: 20.8rem
}

[dir='ltr'] .ltr\:px-208,[dir='ltr'].ltr\:px-208 {
  padding-left: 20.8rem;
  padding-right: 20.8rem
}

[dir='ltr'] .ltr\:py-216,[dir='ltr'].ltr\:py-216 {
  padding-top: 21.6rem;
  padding-bottom: 21.6rem
}

[dir='ltr'] .ltr\:px-216,[dir='ltr'].ltr\:px-216 {
  padding-left: 21.6rem;
  padding-right: 21.6rem
}

[dir='ltr'] .ltr\:py-224,[dir='ltr'].ltr\:py-224 {
  padding-top: 22.4rem;
  padding-bottom: 22.4rem
}

[dir='ltr'] .ltr\:px-224,[dir='ltr'].ltr\:px-224 {
  padding-left: 22.4rem;
  padding-right: 22.4rem
}

[dir='ltr'] .ltr\:py-256,[dir='ltr'].ltr\:py-256 {
  padding-top: 25.6rem;
  padding-bottom: 25.6rem
}

[dir='ltr'] .ltr\:px-256,[dir='ltr'].ltr\:px-256 {
  padding-left: 25.6rem;
  padding-right: 25.6rem
}

[dir='ltr'] .ltr\:py-288,[dir='ltr'].ltr\:py-288 {
  padding-top: 28.8rem;
  padding-bottom: 28.8rem
}

[dir='ltr'] .ltr\:px-288,[dir='ltr'].ltr\:px-288 {
  padding-left: 28.8rem;
  padding-right: 28.8rem
}

[dir='ltr'] .ltr\:py-320,[dir='ltr'].ltr\:py-320 {
  padding-top: 32rem;
  padding-bottom: 32rem
}

[dir='ltr'] .ltr\:px-320,[dir='ltr'].ltr\:px-320 {
  padding-left: 32rem;
  padding-right: 32rem
}

[dir='ltr'] .ltr\:py-360,[dir='ltr'].ltr\:py-360 {
  padding-top: 36rem;
  padding-bottom: 36rem
}

[dir='ltr'] .ltr\:px-360,[dir='ltr'].ltr\:px-360 {
  padding-left: 36rem;
  padding-right: 36rem
}

[dir='ltr'] .ltr\:py-384,[dir='ltr'].ltr\:py-384 {
  padding-top: 38.4rem;
  padding-bottom: 38.4rem
}

[dir='ltr'] .ltr\:px-384,[dir='ltr'].ltr\:px-384 {
  padding-left: 38.4rem;
  padding-right: 38.4rem
}

[dir='ltr'] .ltr\:py-400,[dir='ltr'].ltr\:py-400 {
  padding-top: 40rem;
  padding-bottom: 40rem
}

[dir='ltr'] .ltr\:px-400,[dir='ltr'].ltr\:px-400 {
  padding-left: 40rem;
  padding-right: 40rem
}

[dir='ltr'] .ltr\:py-512,[dir='ltr'].ltr\:py-512 {
  padding-top: 51.2rem;
  padding-bottom: 51.2rem
}

[dir='ltr'] .ltr\:px-512,[dir='ltr'].ltr\:px-512 {
  padding-left: 51.2rem;
  padding-right: 51.2rem
}

[dir='ltr'] .ltr\:py-640,[dir='ltr'].ltr\:py-640 {
  padding-top: 64rem;
  padding-bottom: 64rem
}

[dir='ltr'] .ltr\:px-640,[dir='ltr'].ltr\:px-640 {
  padding-left: 64rem;
  padding-right: 64rem
}

[dir='ltr'] .ltr\:py-xs,[dir='ltr'].ltr\:py-xs {
  padding-top: 32rem;
  padding-bottom: 32rem
}

[dir='ltr'] .ltr\:px-xs,[dir='ltr'].ltr\:px-xs {
  padding-left: 32rem;
  padding-right: 32rem
}

[dir='ltr'] .ltr\:py-sm,[dir='ltr'].ltr\:py-sm {
  padding-top: 48rem;
  padding-bottom: 48rem
}

[dir='ltr'] .ltr\:px-sm,[dir='ltr'].ltr\:px-sm {
  padding-left: 48rem;
  padding-right: 48rem
}

[dir='ltr'] .ltr\:py-md,[dir='ltr'].ltr\:py-md {
  padding-top: 64rem;
  padding-bottom: 64rem
}

[dir='ltr'] .ltr\:px-md,[dir='ltr'].ltr\:px-md {
  padding-left: 64rem;
  padding-right: 64rem
}

[dir='ltr'] .ltr\:py-lg,[dir='ltr'].ltr\:py-lg {
  padding-top: 80rem;
  padding-bottom: 80rem
}

[dir='ltr'] .ltr\:px-lg,[dir='ltr'].ltr\:px-lg {
  padding-left: 80rem;
  padding-right: 80rem
}

[dir='ltr'] .ltr\:py-xl,[dir='ltr'].ltr\:py-xl {
  padding-top: 96rem;
  padding-bottom: 96rem
}

[dir='ltr'] .ltr\:px-xl,[dir='ltr'].ltr\:px-xl {
  padding-left: 96rem;
  padding-right: 96rem
}

[dir='ltr'] .ltr\:py-2xl,[dir='ltr'].ltr\:py-2xl {
  padding-top: 112rem;
  padding-bottom: 112rem
}

[dir='ltr'] .ltr\:px-2xl,[dir='ltr'].ltr\:px-2xl {
  padding-left: 112rem;
  padding-right: 112rem
}

[dir='ltr'] .ltr\:py-3xl,[dir='ltr'].ltr\:py-3xl {
  padding-top: 128rem;
  padding-bottom: 128rem
}

[dir='ltr'] .ltr\:px-3xl,[dir='ltr'].ltr\:px-3xl {
  padding-left: 128rem;
  padding-right: 128rem
}

[dir='ltr'] .ltr\:py-4xl,[dir='ltr'].ltr\:py-4xl {
  padding-top: 144rem;
  padding-bottom: 144rem
}

[dir='ltr'] .ltr\:px-4xl,[dir='ltr'].ltr\:px-4xl {
  padding-left: 144rem;
  padding-right: 144rem
}

[dir='ltr'] .ltr\:py-5xl,[dir='ltr'].ltr\:py-5xl {
  padding-top: 160rem;
  padding-bottom: 160rem
}

[dir='ltr'] .ltr\:px-5xl,[dir='ltr'].ltr\:px-5xl {
  padding-left: 160rem;
  padding-right: 160rem
}

[dir='ltr'] .ltr\:py-px,[dir='ltr'].ltr\:py-px {
  padding-top: 1px;
  padding-bottom: 1px
}

[dir='ltr'] .ltr\:px-px,[dir='ltr'].ltr\:px-px {
  padding-left: 1px;
  padding-right: 1px
}

[dir='ltr'] .ltr\:py-0\.5,[dir='ltr'].ltr\:py-0\.5 {
  padding-top: 0.05rem;
  padding-bottom: 0.05rem
}

[dir='ltr'] .ltr\:px-0\.5,[dir='ltr'].ltr\:px-0\.5 {
  padding-left: 0.05rem;
  padding-right: 0.05rem
}

[dir='ltr'] .ltr\:py-1\.5,[dir='ltr'].ltr\:py-1\.5 {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem
}

[dir='ltr'] .ltr\:px-1\.5,[dir='ltr'].ltr\:px-1\.5 {
  padding-left: 0.15rem;
  padding-right: 0.15rem
}

[dir='ltr'] .ltr\:py-2\.5,[dir='ltr'].ltr\:py-2\.5 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

[dir='ltr'] .ltr\:px-2\.5,[dir='ltr'].ltr\:px-2\.5 {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

[dir='ltr'] .ltr\:py-3\.5,[dir='ltr'].ltr\:py-3\.5 {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem
}

[dir='ltr'] .ltr\:px-3\.5,[dir='ltr'].ltr\:px-3\.5 {
  padding-left: 0.35rem;
  padding-right: 0.35rem
}

[dir='ltr'] .ltr\:pt-0,[dir='ltr'].ltr\:pt-0 {
  padding-top: 0
}

[dir='ltr'] .ltr\:pr-0,[dir='ltr'].ltr\:pr-0 {
  padding-right: 0
}

[dir='ltr'] .ltr\:pb-0,[dir='ltr'].ltr\:pb-0 {
  padding-bottom: 0
}

[dir='ltr'] .ltr\:pl-0,[dir='ltr'].ltr\:pl-0 {
  padding-left: 0
}

[dir='ltr'] .ltr\:pt-1,[dir='ltr'].ltr\:pt-1 {
  padding-top: 0.1rem
}

[dir='ltr'] .ltr\:pr-1,[dir='ltr'].ltr\:pr-1 {
  padding-right: 0.1rem
}

[dir='ltr'] .ltr\:pb-1,[dir='ltr'].ltr\:pb-1 {
  padding-bottom: 0.1rem
}

[dir='ltr'] .ltr\:pl-1,[dir='ltr'].ltr\:pl-1 {
  padding-left: 0.1rem
}

[dir='ltr'] .ltr\:pt-2,[dir='ltr'].ltr\:pt-2 {
  padding-top: 0.2rem
}

[dir='ltr'] .ltr\:pr-2,[dir='ltr'].ltr\:pr-2 {
  padding-right: 0.2rem
}

[dir='ltr'] .ltr\:pb-2,[dir='ltr'].ltr\:pb-2 {
  padding-bottom: 0.2rem
}

[dir='ltr'] .ltr\:pl-2,[dir='ltr'].ltr\:pl-2 {
  padding-left: 0.2rem
}

[dir='ltr'] .ltr\:pt-3,[dir='ltr'].ltr\:pt-3 {
  padding-top: 0.3rem
}

[dir='ltr'] .ltr\:pr-3,[dir='ltr'].ltr\:pr-3 {
  padding-right: 0.3rem
}

[dir='ltr'] .ltr\:pb-3,[dir='ltr'].ltr\:pb-3 {
  padding-bottom: 0.3rem
}

[dir='ltr'] .ltr\:pl-3,[dir='ltr'].ltr\:pl-3 {
  padding-left: 0.3rem
}

[dir='ltr'] .ltr\:pt-4,[dir='ltr'].ltr\:pt-4 {
  padding-top: 0.4rem
}

[dir='ltr'] .ltr\:pr-4,[dir='ltr'].ltr\:pr-4 {
  padding-right: 0.4rem
}

[dir='ltr'] .ltr\:pb-4,[dir='ltr'].ltr\:pb-4 {
  padding-bottom: 0.4rem
}

[dir='ltr'] .ltr\:pl-4,[dir='ltr'].ltr\:pl-4 {
  padding-left: 0.4rem
}

[dir='ltr'] .ltr\:pt-5,[dir='ltr'].ltr\:pt-5 {
  padding-top: 0.5rem
}

[dir='ltr'] .ltr\:pr-5,[dir='ltr'].ltr\:pr-5 {
  padding-right: 0.5rem
}

[dir='ltr'] .ltr\:pb-5,[dir='ltr'].ltr\:pb-5 {
  padding-bottom: 0.5rem
}

[dir='ltr'] .ltr\:pl-5,[dir='ltr'].ltr\:pl-5 {
  padding-left: 0.5rem
}

[dir='ltr'] .ltr\:pt-6,[dir='ltr'].ltr\:pt-6 {
  padding-top: 0.6rem
}

[dir='ltr'] .ltr\:pr-6,[dir='ltr'].ltr\:pr-6 {
  padding-right: 0.6rem
}

[dir='ltr'] .ltr\:pb-6,[dir='ltr'].ltr\:pb-6 {
  padding-bottom: 0.6rem
}

[dir='ltr'] .ltr\:pl-6,[dir='ltr'].ltr\:pl-6 {
  padding-left: 0.6rem
}

[dir='ltr'] .ltr\:pt-7,[dir='ltr'].ltr\:pt-7 {
  padding-top: 0.7rem
}

[dir='ltr'] .ltr\:pr-7,[dir='ltr'].ltr\:pr-7 {
  padding-right: 0.7rem
}

[dir='ltr'] .ltr\:pb-7,[dir='ltr'].ltr\:pb-7 {
  padding-bottom: 0.7rem
}

[dir='ltr'] .ltr\:pl-7,[dir='ltr'].ltr\:pl-7 {
  padding-left: 0.7rem
}

[dir='ltr'] .ltr\:pt-8,[dir='ltr'].ltr\:pt-8 {
  padding-top: 0.8rem
}

[dir='ltr'] .ltr\:pr-8,[dir='ltr'].ltr\:pr-8 {
  padding-right: 0.8rem
}

[dir='ltr'] .ltr\:pb-8,[dir='ltr'].ltr\:pb-8 {
  padding-bottom: 0.8rem
}

[dir='ltr'] .ltr\:pl-8,[dir='ltr'].ltr\:pl-8 {
  padding-left: 0.8rem
}

[dir='ltr'] .ltr\:pt-9,[dir='ltr'].ltr\:pt-9 {
  padding-top: 0.9rem
}

[dir='ltr'] .ltr\:pr-9,[dir='ltr'].ltr\:pr-9 {
  padding-right: 0.9rem
}

[dir='ltr'] .ltr\:pb-9,[dir='ltr'].ltr\:pb-9 {
  padding-bottom: 0.9rem
}

[dir='ltr'] .ltr\:pl-9,[dir='ltr'].ltr\:pl-9 {
  padding-left: 0.9rem
}

[dir='ltr'] .ltr\:pt-10,[dir='ltr'].ltr\:pt-10 {
  padding-top: 1.0rem
}

[dir='ltr'] .ltr\:pr-10,[dir='ltr'].ltr\:pr-10 {
  padding-right: 1.0rem
}

[dir='ltr'] .ltr\:pb-10,[dir='ltr'].ltr\:pb-10 {
  padding-bottom: 1.0rem
}

[dir='ltr'] .ltr\:pl-10,[dir='ltr'].ltr\:pl-10 {
  padding-left: 1.0rem
}

[dir='ltr'] .ltr\:pt-12,[dir='ltr'].ltr\:pt-12 {
  padding-top: 1.2rem
}

[dir='ltr'] .ltr\:pr-12,[dir='ltr'].ltr\:pr-12 {
  padding-right: 1.2rem
}

[dir='ltr'] .ltr\:pb-12,[dir='ltr'].ltr\:pb-12 {
  padding-bottom: 1.2rem
}

[dir='ltr'] .ltr\:pl-12,[dir='ltr'].ltr\:pl-12 {
  padding-left: 1.2rem
}

[dir='ltr'] .ltr\:pt-14,[dir='ltr'].ltr\:pt-14 {
  padding-top: 1.4rem
}

[dir='ltr'] .ltr\:pr-14,[dir='ltr'].ltr\:pr-14 {
  padding-right: 1.4rem
}

[dir='ltr'] .ltr\:pb-14,[dir='ltr'].ltr\:pb-14 {
  padding-bottom: 1.4rem
}

[dir='ltr'] .ltr\:pl-14,[dir='ltr'].ltr\:pl-14 {
  padding-left: 1.4rem
}

[dir='ltr'] .ltr\:pt-16,[dir='ltr'].ltr\:pt-16 {
  padding-top: 1.6rem
}

[dir='ltr'] .ltr\:pr-16,[dir='ltr'].ltr\:pr-16 {
  padding-right: 1.6rem
}

[dir='ltr'] .ltr\:pb-16,[dir='ltr'].ltr\:pb-16 {
  padding-bottom: 1.6rem
}

[dir='ltr'] .ltr\:pl-16,[dir='ltr'].ltr\:pl-16 {
  padding-left: 1.6rem
}

[dir='ltr'] .ltr\:pt-20,[dir='ltr'].ltr\:pt-20 {
  padding-top: 2rem
}

[dir='ltr'] .ltr\:pr-20,[dir='ltr'].ltr\:pr-20 {
  padding-right: 2rem
}

[dir='ltr'] .ltr\:pb-20,[dir='ltr'].ltr\:pb-20 {
  padding-bottom: 2rem
}

[dir='ltr'] .ltr\:pl-20,[dir='ltr'].ltr\:pl-20 {
  padding-left: 2rem
}

[dir='ltr'] .ltr\:pt-24,[dir='ltr'].ltr\:pt-24 {
  padding-top: 2.4rem
}

[dir='ltr'] .ltr\:pr-24,[dir='ltr'].ltr\:pr-24 {
  padding-right: 2.4rem
}

[dir='ltr'] .ltr\:pb-24,[dir='ltr'].ltr\:pb-24 {
  padding-bottom: 2.4rem
}

[dir='ltr'] .ltr\:pl-24,[dir='ltr'].ltr\:pl-24 {
  padding-left: 2.4rem
}

[dir='ltr'] .ltr\:pt-28,[dir='ltr'].ltr\:pt-28 {
  padding-top: 2.8rem
}

[dir='ltr'] .ltr\:pr-28,[dir='ltr'].ltr\:pr-28 {
  padding-right: 2.8rem
}

[dir='ltr'] .ltr\:pb-28,[dir='ltr'].ltr\:pb-28 {
  padding-bottom: 2.8rem
}

[dir='ltr'] .ltr\:pl-28,[dir='ltr'].ltr\:pl-28 {
  padding-left: 2.8rem
}

[dir='ltr'] .ltr\:pt-32,[dir='ltr'].ltr\:pt-32 {
  padding-top: 3.2rem
}

[dir='ltr'] .ltr\:pr-32,[dir='ltr'].ltr\:pr-32 {
  padding-right: 3.2rem
}

[dir='ltr'] .ltr\:pb-32,[dir='ltr'].ltr\:pb-32 {
  padding-bottom: 3.2rem
}

[dir='ltr'] .ltr\:pl-32,[dir='ltr'].ltr\:pl-32 {
  padding-left: 3.2rem
}

[dir='ltr'] .ltr\:pt-36,[dir='ltr'].ltr\:pt-36 {
  padding-top: 3.6rem
}

[dir='ltr'] .ltr\:pr-36,[dir='ltr'].ltr\:pr-36 {
  padding-right: 3.6rem
}

[dir='ltr'] .ltr\:pb-36,[dir='ltr'].ltr\:pb-36 {
  padding-bottom: 3.6rem
}

[dir='ltr'] .ltr\:pl-36,[dir='ltr'].ltr\:pl-36 {
  padding-left: 3.6rem
}

[dir='ltr'] .ltr\:pt-40,[dir='ltr'].ltr\:pt-40 {
  padding-top: 4rem
}

[dir='ltr'] .ltr\:pr-40,[dir='ltr'].ltr\:pr-40 {
  padding-right: 4rem
}

[dir='ltr'] .ltr\:pb-40,[dir='ltr'].ltr\:pb-40 {
  padding-bottom: 4rem
}

[dir='ltr'] .ltr\:pl-40,[dir='ltr'].ltr\:pl-40 {
  padding-left: 4rem
}

[dir='ltr'] .ltr\:pt-44,[dir='ltr'].ltr\:pt-44 {
  padding-top: 4.4rem
}

[dir='ltr'] .ltr\:pr-44,[dir='ltr'].ltr\:pr-44 {
  padding-right: 4.4rem
}

[dir='ltr'] .ltr\:pb-44,[dir='ltr'].ltr\:pb-44 {
  padding-bottom: 4.4rem
}

[dir='ltr'] .ltr\:pl-44,[dir='ltr'].ltr\:pl-44 {
  padding-left: 4.4rem
}

[dir='ltr'] .ltr\:pt-48,[dir='ltr'].ltr\:pt-48 {
  padding-top: 4.8rem
}

[dir='ltr'] .ltr\:pr-48,[dir='ltr'].ltr\:pr-48 {
  padding-right: 4.8rem
}

[dir='ltr'] .ltr\:pb-48,[dir='ltr'].ltr\:pb-48 {
  padding-bottom: 4.8rem
}

[dir='ltr'] .ltr\:pl-48,[dir='ltr'].ltr\:pl-48 {
  padding-left: 4.8rem
}

[dir='ltr'] .ltr\:pt-52,[dir='ltr'].ltr\:pt-52 {
  padding-top: 5.2rem
}

[dir='ltr'] .ltr\:pr-52,[dir='ltr'].ltr\:pr-52 {
  padding-right: 5.2rem
}

[dir='ltr'] .ltr\:pb-52,[dir='ltr'].ltr\:pb-52 {
  padding-bottom: 5.2rem
}

[dir='ltr'] .ltr\:pl-52,[dir='ltr'].ltr\:pl-52 {
  padding-left: 5.2rem
}

[dir='ltr'] .ltr\:pt-56,[dir='ltr'].ltr\:pt-56 {
  padding-top: 5.6rem
}

[dir='ltr'] .ltr\:pr-56,[dir='ltr'].ltr\:pr-56 {
  padding-right: 5.6rem
}

[dir='ltr'] .ltr\:pb-56,[dir='ltr'].ltr\:pb-56 {
  padding-bottom: 5.6rem
}

[dir='ltr'] .ltr\:pl-56,[dir='ltr'].ltr\:pl-56 {
  padding-left: 5.6rem
}

[dir='ltr'] .ltr\:pt-60,[dir='ltr'].ltr\:pt-60 {
  padding-top: 6rem
}

[dir='ltr'] .ltr\:pr-60,[dir='ltr'].ltr\:pr-60 {
  padding-right: 6rem
}

[dir='ltr'] .ltr\:pb-60,[dir='ltr'].ltr\:pb-60 {
  padding-bottom: 6rem
}

[dir='ltr'] .ltr\:pl-60,[dir='ltr'].ltr\:pl-60 {
  padding-left: 6rem
}

[dir='ltr'] .ltr\:pt-64,[dir='ltr'].ltr\:pt-64 {
  padding-top: 6.4rem
}

[dir='ltr'] .ltr\:pr-64,[dir='ltr'].ltr\:pr-64 {
  padding-right: 6.4rem
}

[dir='ltr'] .ltr\:pb-64,[dir='ltr'].ltr\:pb-64 {
  padding-bottom: 6.4rem
}

[dir='ltr'] .ltr\:pl-64,[dir='ltr'].ltr\:pl-64 {
  padding-left: 6.4rem
}

[dir='ltr'] .ltr\:pt-68,[dir='ltr'].ltr\:pt-68 {
  padding-top: 6.8rem
}

[dir='ltr'] .ltr\:pr-68,[dir='ltr'].ltr\:pr-68 {
  padding-right: 6.8rem
}

[dir='ltr'] .ltr\:pb-68,[dir='ltr'].ltr\:pb-68 {
  padding-bottom: 6.8rem
}

[dir='ltr'] .ltr\:pl-68,[dir='ltr'].ltr\:pl-68 {
  padding-left: 6.8rem
}

[dir='ltr'] .ltr\:pt-72,[dir='ltr'].ltr\:pt-72 {
  padding-top: 7.2rem
}

[dir='ltr'] .ltr\:pr-72,[dir='ltr'].ltr\:pr-72 {
  padding-right: 7.2rem
}

[dir='ltr'] .ltr\:pb-72,[dir='ltr'].ltr\:pb-72 {
  padding-bottom: 7.2rem
}

[dir='ltr'] .ltr\:pl-72,[dir='ltr'].ltr\:pl-72 {
  padding-left: 7.2rem
}

[dir='ltr'] .ltr\:pt-76,[dir='ltr'].ltr\:pt-76 {
  padding-top: 7.6rem
}

[dir='ltr'] .ltr\:pr-76,[dir='ltr'].ltr\:pr-76 {
  padding-right: 7.6rem
}

[dir='ltr'] .ltr\:pb-76,[dir='ltr'].ltr\:pb-76 {
  padding-bottom: 7.6rem
}

[dir='ltr'] .ltr\:pl-76,[dir='ltr'].ltr\:pl-76 {
  padding-left: 7.6rem
}

[dir='ltr'] .ltr\:pt-80,[dir='ltr'].ltr\:pt-80 {
  padding-top: 8rem
}

[dir='ltr'] .ltr\:pr-80,[dir='ltr'].ltr\:pr-80 {
  padding-right: 8rem
}

[dir='ltr'] .ltr\:pb-80,[dir='ltr'].ltr\:pb-80 {
  padding-bottom: 8rem
}

[dir='ltr'] .ltr\:pl-80,[dir='ltr'].ltr\:pl-80 {
  padding-left: 8rem
}

[dir='ltr'] .ltr\:pt-84,[dir='ltr'].ltr\:pt-84 {
  padding-top: 8.4rem
}

[dir='ltr'] .ltr\:pr-84,[dir='ltr'].ltr\:pr-84 {
  padding-right: 8.4rem
}

[dir='ltr'] .ltr\:pb-84,[dir='ltr'].ltr\:pb-84 {
  padding-bottom: 8.4rem
}

[dir='ltr'] .ltr\:pl-84,[dir='ltr'].ltr\:pl-84 {
  padding-left: 8.4rem
}

[dir='ltr'] .ltr\:pt-88,[dir='ltr'].ltr\:pt-88 {
  padding-top: 8.8rem
}

[dir='ltr'] .ltr\:pr-88,[dir='ltr'].ltr\:pr-88 {
  padding-right: 8.8rem
}

[dir='ltr'] .ltr\:pb-88,[dir='ltr'].ltr\:pb-88 {
  padding-bottom: 8.8rem
}

[dir='ltr'] .ltr\:pl-88,[dir='ltr'].ltr\:pl-88 {
  padding-left: 8.8rem
}

[dir='ltr'] .ltr\:pt-92,[dir='ltr'].ltr\:pt-92 {
  padding-top: 9.2rem
}

[dir='ltr'] .ltr\:pr-92,[dir='ltr'].ltr\:pr-92 {
  padding-right: 9.2rem
}

[dir='ltr'] .ltr\:pb-92,[dir='ltr'].ltr\:pb-92 {
  padding-bottom: 9.2rem
}

[dir='ltr'] .ltr\:pl-92,[dir='ltr'].ltr\:pl-92 {
  padding-left: 9.2rem
}

[dir='ltr'] .ltr\:pt-96,[dir='ltr'].ltr\:pt-96 {
  padding-top: 9.6rem
}

[dir='ltr'] .ltr\:pr-96,[dir='ltr'].ltr\:pr-96 {
  padding-right: 9.6rem
}

[dir='ltr'] .ltr\:pb-96,[dir='ltr'].ltr\:pb-96 {
  padding-bottom: 9.6rem
}

[dir='ltr'] .ltr\:pl-96,[dir='ltr'].ltr\:pl-96 {
  padding-left: 9.6rem
}

[dir='ltr'] .ltr\:pt-128,[dir='ltr'].ltr\:pt-128 {
  padding-top: 12.8rem
}

[dir='ltr'] .ltr\:pr-128,[dir='ltr'].ltr\:pr-128 {
  padding-right: 12.8rem
}

[dir='ltr'] .ltr\:pb-128,[dir='ltr'].ltr\:pb-128 {
  padding-bottom: 12.8rem
}

[dir='ltr'] .ltr\:pl-128,[dir='ltr'].ltr\:pl-128 {
  padding-left: 12.8rem
}

[dir='ltr'] .ltr\:pt-136,[dir='ltr'].ltr\:pt-136 {
  padding-top: 13.6rem
}

[dir='ltr'] .ltr\:pr-136,[dir='ltr'].ltr\:pr-136 {
  padding-right: 13.6rem
}

[dir='ltr'] .ltr\:pb-136,[dir='ltr'].ltr\:pb-136 {
  padding-bottom: 13.6rem
}

[dir='ltr'] .ltr\:pl-136,[dir='ltr'].ltr\:pl-136 {
  padding-left: 13.6rem
}

[dir='ltr'] .ltr\:pt-160,[dir='ltr'].ltr\:pt-160 {
  padding-top: 16rem
}

[dir='ltr'] .ltr\:pr-160,[dir='ltr'].ltr\:pr-160 {
  padding-right: 16rem
}

[dir='ltr'] .ltr\:pb-160,[dir='ltr'].ltr\:pb-160 {
  padding-bottom: 16rem
}

[dir='ltr'] .ltr\:pl-160,[dir='ltr'].ltr\:pl-160 {
  padding-left: 16rem
}

[dir='ltr'] .ltr\:pt-192,[dir='ltr'].ltr\:pt-192 {
  padding-top: 19.2rem
}

[dir='ltr'] .ltr\:pr-192,[dir='ltr'].ltr\:pr-192 {
  padding-right: 19.2rem
}

[dir='ltr'] .ltr\:pb-192,[dir='ltr'].ltr\:pb-192 {
  padding-bottom: 19.2rem
}

[dir='ltr'] .ltr\:pl-192,[dir='ltr'].ltr\:pl-192 {
  padding-left: 19.2rem
}

[dir='ltr'] .ltr\:pt-200,[dir='ltr'].ltr\:pt-200 {
  padding-top: 20rem
}

[dir='ltr'] .ltr\:pr-200,[dir='ltr'].ltr\:pr-200 {
  padding-right: 20rem
}

[dir='ltr'] .ltr\:pb-200,[dir='ltr'].ltr\:pb-200 {
  padding-bottom: 20rem
}

[dir='ltr'] .ltr\:pl-200,[dir='ltr'].ltr\:pl-200 {
  padding-left: 20rem
}

[dir='ltr'] .ltr\:pt-208,[dir='ltr'].ltr\:pt-208 {
  padding-top: 20.8rem
}

[dir='ltr'] .ltr\:pr-208,[dir='ltr'].ltr\:pr-208 {
  padding-right: 20.8rem
}

[dir='ltr'] .ltr\:pb-208,[dir='ltr'].ltr\:pb-208 {
  padding-bottom: 20.8rem
}

[dir='ltr'] .ltr\:pl-208,[dir='ltr'].ltr\:pl-208 {
  padding-left: 20.8rem
}

[dir='ltr'] .ltr\:pt-216,[dir='ltr'].ltr\:pt-216 {
  padding-top: 21.6rem
}

[dir='ltr'] .ltr\:pr-216,[dir='ltr'].ltr\:pr-216 {
  padding-right: 21.6rem
}

[dir='ltr'] .ltr\:pb-216,[dir='ltr'].ltr\:pb-216 {
  padding-bottom: 21.6rem
}

[dir='ltr'] .ltr\:pl-216,[dir='ltr'].ltr\:pl-216 {
  padding-left: 21.6rem
}

[dir='ltr'] .ltr\:pt-224,[dir='ltr'].ltr\:pt-224 {
  padding-top: 22.4rem
}

[dir='ltr'] .ltr\:pr-224,[dir='ltr'].ltr\:pr-224 {
  padding-right: 22.4rem
}

[dir='ltr'] .ltr\:pb-224,[dir='ltr'].ltr\:pb-224 {
  padding-bottom: 22.4rem
}

[dir='ltr'] .ltr\:pl-224,[dir='ltr'].ltr\:pl-224 {
  padding-left: 22.4rem
}

[dir='ltr'] .ltr\:pt-256,[dir='ltr'].ltr\:pt-256 {
  padding-top: 25.6rem
}

[dir='ltr'] .ltr\:pr-256,[dir='ltr'].ltr\:pr-256 {
  padding-right: 25.6rem
}

[dir='ltr'] .ltr\:pb-256,[dir='ltr'].ltr\:pb-256 {
  padding-bottom: 25.6rem
}

[dir='ltr'] .ltr\:pl-256,[dir='ltr'].ltr\:pl-256 {
  padding-left: 25.6rem
}

[dir='ltr'] .ltr\:pt-288,[dir='ltr'].ltr\:pt-288 {
  padding-top: 28.8rem
}

[dir='ltr'] .ltr\:pr-288,[dir='ltr'].ltr\:pr-288 {
  padding-right: 28.8rem
}

[dir='ltr'] .ltr\:pb-288,[dir='ltr'].ltr\:pb-288 {
  padding-bottom: 28.8rem
}

[dir='ltr'] .ltr\:pl-288,[dir='ltr'].ltr\:pl-288 {
  padding-left: 28.8rem
}

[dir='ltr'] .ltr\:pt-320,[dir='ltr'].ltr\:pt-320 {
  padding-top: 32rem
}

[dir='ltr'] .ltr\:pr-320,[dir='ltr'].ltr\:pr-320 {
  padding-right: 32rem
}

[dir='ltr'] .ltr\:pb-320,[dir='ltr'].ltr\:pb-320 {
  padding-bottom: 32rem
}

[dir='ltr'] .ltr\:pl-320,[dir='ltr'].ltr\:pl-320 {
  padding-left: 32rem
}

[dir='ltr'] .ltr\:pt-360,[dir='ltr'].ltr\:pt-360 {
  padding-top: 36rem
}

[dir='ltr'] .ltr\:pr-360,[dir='ltr'].ltr\:pr-360 {
  padding-right: 36rem
}

[dir='ltr'] .ltr\:pb-360,[dir='ltr'].ltr\:pb-360 {
  padding-bottom: 36rem
}

[dir='ltr'] .ltr\:pl-360,[dir='ltr'].ltr\:pl-360 {
  padding-left: 36rem
}

[dir='ltr'] .ltr\:pt-384,[dir='ltr'].ltr\:pt-384 {
  padding-top: 38.4rem
}

[dir='ltr'] .ltr\:pr-384,[dir='ltr'].ltr\:pr-384 {
  padding-right: 38.4rem
}

[dir='ltr'] .ltr\:pb-384,[dir='ltr'].ltr\:pb-384 {
  padding-bottom: 38.4rem
}

[dir='ltr'] .ltr\:pl-384,[dir='ltr'].ltr\:pl-384 {
  padding-left: 38.4rem
}

[dir='ltr'] .ltr\:pt-400,[dir='ltr'].ltr\:pt-400 {
  padding-top: 40rem
}

[dir='ltr'] .ltr\:pr-400,[dir='ltr'].ltr\:pr-400 {
  padding-right: 40rem
}

[dir='ltr'] .ltr\:pb-400,[dir='ltr'].ltr\:pb-400 {
  padding-bottom: 40rem
}

[dir='ltr'] .ltr\:pl-400,[dir='ltr'].ltr\:pl-400 {
  padding-left: 40rem
}

[dir='ltr'] .ltr\:pt-512,[dir='ltr'].ltr\:pt-512 {
  padding-top: 51.2rem
}

[dir='ltr'] .ltr\:pr-512,[dir='ltr'].ltr\:pr-512 {
  padding-right: 51.2rem
}

[dir='ltr'] .ltr\:pb-512,[dir='ltr'].ltr\:pb-512 {
  padding-bottom: 51.2rem
}

[dir='ltr'] .ltr\:pl-512,[dir='ltr'].ltr\:pl-512 {
  padding-left: 51.2rem
}

[dir='ltr'] .ltr\:pt-640,[dir='ltr'].ltr\:pt-640 {
  padding-top: 64rem
}

[dir='ltr'] .ltr\:pr-640,[dir='ltr'].ltr\:pr-640 {
  padding-right: 64rem
}

[dir='ltr'] .ltr\:pb-640,[dir='ltr'].ltr\:pb-640 {
  padding-bottom: 64rem
}

[dir='ltr'] .ltr\:pl-640,[dir='ltr'].ltr\:pl-640 {
  padding-left: 64rem
}

[dir='ltr'] .ltr\:pt-xs,[dir='ltr'].ltr\:pt-xs {
  padding-top: 32rem
}

[dir='ltr'] .ltr\:pr-xs,[dir='ltr'].ltr\:pr-xs {
  padding-right: 32rem
}

[dir='ltr'] .ltr\:pb-xs,[dir='ltr'].ltr\:pb-xs {
  padding-bottom: 32rem
}

[dir='ltr'] .ltr\:pl-xs,[dir='ltr'].ltr\:pl-xs {
  padding-left: 32rem
}

[dir='ltr'] .ltr\:pt-sm,[dir='ltr'].ltr\:pt-sm {
  padding-top: 48rem
}

[dir='ltr'] .ltr\:pr-sm,[dir='ltr'].ltr\:pr-sm {
  padding-right: 48rem
}

[dir='ltr'] .ltr\:pb-sm,[dir='ltr'].ltr\:pb-sm {
  padding-bottom: 48rem
}

[dir='ltr'] .ltr\:pl-sm,[dir='ltr'].ltr\:pl-sm {
  padding-left: 48rem
}

[dir='ltr'] .ltr\:pt-md,[dir='ltr'].ltr\:pt-md {
  padding-top: 64rem
}

[dir='ltr'] .ltr\:pr-md,[dir='ltr'].ltr\:pr-md {
  padding-right: 64rem
}

[dir='ltr'] .ltr\:pb-md,[dir='ltr'].ltr\:pb-md {
  padding-bottom: 64rem
}

[dir='ltr'] .ltr\:pl-md,[dir='ltr'].ltr\:pl-md {
  padding-left: 64rem
}

[dir='ltr'] .ltr\:pt-lg,[dir='ltr'].ltr\:pt-lg {
  padding-top: 80rem
}

[dir='ltr'] .ltr\:pr-lg,[dir='ltr'].ltr\:pr-lg {
  padding-right: 80rem
}

[dir='ltr'] .ltr\:pb-lg,[dir='ltr'].ltr\:pb-lg {
  padding-bottom: 80rem
}

[dir='ltr'] .ltr\:pl-lg,[dir='ltr'].ltr\:pl-lg {
  padding-left: 80rem
}

[dir='ltr'] .ltr\:pt-xl,[dir='ltr'].ltr\:pt-xl {
  padding-top: 96rem
}

[dir='ltr'] .ltr\:pr-xl,[dir='ltr'].ltr\:pr-xl {
  padding-right: 96rem
}

[dir='ltr'] .ltr\:pb-xl,[dir='ltr'].ltr\:pb-xl {
  padding-bottom: 96rem
}

[dir='ltr'] .ltr\:pl-xl,[dir='ltr'].ltr\:pl-xl {
  padding-left: 96rem
}

[dir='ltr'] .ltr\:pt-2xl,[dir='ltr'].ltr\:pt-2xl {
  padding-top: 112rem
}

[dir='ltr'] .ltr\:pr-2xl,[dir='ltr'].ltr\:pr-2xl {
  padding-right: 112rem
}

[dir='ltr'] .ltr\:pb-2xl,[dir='ltr'].ltr\:pb-2xl {
  padding-bottom: 112rem
}

[dir='ltr'] .ltr\:pl-2xl,[dir='ltr'].ltr\:pl-2xl {
  padding-left: 112rem
}

[dir='ltr'] .ltr\:pt-3xl,[dir='ltr'].ltr\:pt-3xl {
  padding-top: 128rem
}

[dir='ltr'] .ltr\:pr-3xl,[dir='ltr'].ltr\:pr-3xl {
  padding-right: 128rem
}

[dir='ltr'] .ltr\:pb-3xl,[dir='ltr'].ltr\:pb-3xl {
  padding-bottom: 128rem
}

[dir='ltr'] .ltr\:pl-3xl,[dir='ltr'].ltr\:pl-3xl {
  padding-left: 128rem
}

[dir='ltr'] .ltr\:pt-4xl,[dir='ltr'].ltr\:pt-4xl {
  padding-top: 144rem
}

[dir='ltr'] .ltr\:pr-4xl,[dir='ltr'].ltr\:pr-4xl {
  padding-right: 144rem
}

[dir='ltr'] .ltr\:pb-4xl,[dir='ltr'].ltr\:pb-4xl {
  padding-bottom: 144rem
}

[dir='ltr'] .ltr\:pl-4xl,[dir='ltr'].ltr\:pl-4xl {
  padding-left: 144rem
}

[dir='ltr'] .ltr\:pt-5xl,[dir='ltr'].ltr\:pt-5xl {
  padding-top: 160rem
}

[dir='ltr'] .ltr\:pr-5xl,[dir='ltr'].ltr\:pr-5xl {
  padding-right: 160rem
}

[dir='ltr'] .ltr\:pb-5xl,[dir='ltr'].ltr\:pb-5xl {
  padding-bottom: 160rem
}

[dir='ltr'] .ltr\:pl-5xl,[dir='ltr'].ltr\:pl-5xl {
  padding-left: 160rem
}

[dir='ltr'] .ltr\:pt-px,[dir='ltr'].ltr\:pt-px {
  padding-top: 1px
}

[dir='ltr'] .ltr\:pr-px,[dir='ltr'].ltr\:pr-px {
  padding-right: 1px
}

[dir='ltr'] .ltr\:pb-px,[dir='ltr'].ltr\:pb-px {
  padding-bottom: 1px
}

[dir='ltr'] .ltr\:pl-px,[dir='ltr'].ltr\:pl-px {
  padding-left: 1px
}

[dir='ltr'] .ltr\:pt-0\.5,[dir='ltr'].ltr\:pt-0\.5 {
  padding-top: 0.05rem
}

[dir='ltr'] .ltr\:pr-0\.5,[dir='ltr'].ltr\:pr-0\.5 {
  padding-right: 0.05rem
}

[dir='ltr'] .ltr\:pb-0\.5,[dir='ltr'].ltr\:pb-0\.5 {
  padding-bottom: 0.05rem
}

[dir='ltr'] .ltr\:pl-0\.5,[dir='ltr'].ltr\:pl-0\.5 {
  padding-left: 0.05rem
}

[dir='ltr'] .ltr\:pt-1\.5,[dir='ltr'].ltr\:pt-1\.5 {
  padding-top: 0.15rem
}

[dir='ltr'] .ltr\:pr-1\.5,[dir='ltr'].ltr\:pr-1\.5 {
  padding-right: 0.15rem
}

[dir='ltr'] .ltr\:pb-1\.5,[dir='ltr'].ltr\:pb-1\.5 {
  padding-bottom: 0.15rem
}

[dir='ltr'] .ltr\:pl-1\.5,[dir='ltr'].ltr\:pl-1\.5 {
  padding-left: 0.15rem
}

[dir='ltr'] .ltr\:pt-2\.5,[dir='ltr'].ltr\:pt-2\.5 {
  padding-top: 0.25rem
}

[dir='ltr'] .ltr\:pr-2\.5,[dir='ltr'].ltr\:pr-2\.5 {
  padding-right: 0.25rem
}

[dir='ltr'] .ltr\:pb-2\.5,[dir='ltr'].ltr\:pb-2\.5 {
  padding-bottom: 0.25rem
}

[dir='ltr'] .ltr\:pl-2\.5,[dir='ltr'].ltr\:pl-2\.5 {
  padding-left: 0.25rem
}

[dir='ltr'] .ltr\:pt-3\.5,[dir='ltr'].ltr\:pt-3\.5 {
  padding-top: 0.35rem
}

[dir='ltr'] .ltr\:pr-3\.5,[dir='ltr'].ltr\:pr-3\.5 {
  padding-right: 0.35rem
}

[dir='ltr'] .ltr\:pb-3\.5,[dir='ltr'].ltr\:pb-3\.5 {
  padding-bottom: 0.35rem
}

[dir='ltr'] .ltr\:pl-3\.5,[dir='ltr'].ltr\:pl-3\.5 {
  padding-left: 0.35rem
}

[dir='rtl'] .rtl\:p-0,[dir='rtl'].rtl\:p-0 {
  padding: 0
}

[dir='rtl'] .rtl\:p-1,[dir='rtl'].rtl\:p-1 {
  padding: 0.1rem
}

[dir='rtl'] .rtl\:p-2,[dir='rtl'].rtl\:p-2 {
  padding: 0.2rem
}

[dir='rtl'] .rtl\:p-3,[dir='rtl'].rtl\:p-3 {
  padding: 0.3rem
}

[dir='rtl'] .rtl\:p-4,[dir='rtl'].rtl\:p-4 {
  padding: 0.4rem
}

[dir='rtl'] .rtl\:p-5,[dir='rtl'].rtl\:p-5 {
  padding: 0.5rem
}

[dir='rtl'] .rtl\:p-6,[dir='rtl'].rtl\:p-6 {
  padding: 0.6rem
}

[dir='rtl'] .rtl\:p-7,[dir='rtl'].rtl\:p-7 {
  padding: 0.7rem
}

[dir='rtl'] .rtl\:p-8,[dir='rtl'].rtl\:p-8 {
  padding: 0.8rem
}

[dir='rtl'] .rtl\:p-9,[dir='rtl'].rtl\:p-9 {
  padding: 0.9rem
}

[dir='rtl'] .rtl\:p-10,[dir='rtl'].rtl\:p-10 {
  padding: 1.0rem
}

[dir='rtl'] .rtl\:p-12,[dir='rtl'].rtl\:p-12 {
  padding: 1.2rem
}

[dir='rtl'] .rtl\:p-14,[dir='rtl'].rtl\:p-14 {
  padding: 1.4rem
}

[dir='rtl'] .rtl\:p-16,[dir='rtl'].rtl\:p-16 {
  padding: 1.6rem
}

[dir='rtl'] .rtl\:p-20,[dir='rtl'].rtl\:p-20 {
  padding: 2rem
}

[dir='rtl'] .rtl\:p-24,[dir='rtl'].rtl\:p-24 {
  padding: 2.4rem
}

[dir='rtl'] .rtl\:p-28,[dir='rtl'].rtl\:p-28 {
  padding: 2.8rem
}

[dir='rtl'] .rtl\:p-32,[dir='rtl'].rtl\:p-32 {
  padding: 3.2rem
}

[dir='rtl'] .rtl\:p-36,[dir='rtl'].rtl\:p-36 {
  padding: 3.6rem
}

[dir='rtl'] .rtl\:p-40,[dir='rtl'].rtl\:p-40 {
  padding: 4rem
}

[dir='rtl'] .rtl\:p-44,[dir='rtl'].rtl\:p-44 {
  padding: 4.4rem
}

[dir='rtl'] .rtl\:p-48,[dir='rtl'].rtl\:p-48 {
  padding: 4.8rem
}

[dir='rtl'] .rtl\:p-52,[dir='rtl'].rtl\:p-52 {
  padding: 5.2rem
}

[dir='rtl'] .rtl\:p-56,[dir='rtl'].rtl\:p-56 {
  padding: 5.6rem
}

[dir='rtl'] .rtl\:p-60,[dir='rtl'].rtl\:p-60 {
  padding: 6rem
}

[dir='rtl'] .rtl\:p-64,[dir='rtl'].rtl\:p-64 {
  padding: 6.4rem
}

[dir='rtl'] .rtl\:p-68,[dir='rtl'].rtl\:p-68 {
  padding: 6.8rem
}

[dir='rtl'] .rtl\:p-72,[dir='rtl'].rtl\:p-72 {
  padding: 7.2rem
}

[dir='rtl'] .rtl\:p-76,[dir='rtl'].rtl\:p-76 {
  padding: 7.6rem
}

[dir='rtl'] .rtl\:p-80,[dir='rtl'].rtl\:p-80 {
  padding: 8rem
}

[dir='rtl'] .rtl\:p-84,[dir='rtl'].rtl\:p-84 {
  padding: 8.4rem
}

[dir='rtl'] .rtl\:p-88,[dir='rtl'].rtl\:p-88 {
  padding: 8.8rem
}

[dir='rtl'] .rtl\:p-92,[dir='rtl'].rtl\:p-92 {
  padding: 9.2rem
}

[dir='rtl'] .rtl\:p-96,[dir='rtl'].rtl\:p-96 {
  padding: 9.6rem
}

[dir='rtl'] .rtl\:p-128,[dir='rtl'].rtl\:p-128 {
  padding: 12.8rem
}

[dir='rtl'] .rtl\:p-136,[dir='rtl'].rtl\:p-136 {
  padding: 13.6rem
}

[dir='rtl'] .rtl\:p-160,[dir='rtl'].rtl\:p-160 {
  padding: 16rem
}

[dir='rtl'] .rtl\:p-192,[dir='rtl'].rtl\:p-192 {
  padding: 19.2rem
}

[dir='rtl'] .rtl\:p-200,[dir='rtl'].rtl\:p-200 {
  padding: 20rem
}

[dir='rtl'] .rtl\:p-208,[dir='rtl'].rtl\:p-208 {
  padding: 20.8rem
}

[dir='rtl'] .rtl\:p-216,[dir='rtl'].rtl\:p-216 {
  padding: 21.6rem
}

[dir='rtl'] .rtl\:p-224,[dir='rtl'].rtl\:p-224 {
  padding: 22.4rem
}

[dir='rtl'] .rtl\:p-256,[dir='rtl'].rtl\:p-256 {
  padding: 25.6rem
}

[dir='rtl'] .rtl\:p-288,[dir='rtl'].rtl\:p-288 {
  padding: 28.8rem
}

[dir='rtl'] .rtl\:p-320,[dir='rtl'].rtl\:p-320 {
  padding: 32rem
}

[dir='rtl'] .rtl\:p-360,[dir='rtl'].rtl\:p-360 {
  padding: 36rem
}

[dir='rtl'] .rtl\:p-384,[dir='rtl'].rtl\:p-384 {
  padding: 38.4rem
}

[dir='rtl'] .rtl\:p-400,[dir='rtl'].rtl\:p-400 {
  padding: 40rem
}

[dir='rtl'] .rtl\:p-512,[dir='rtl'].rtl\:p-512 {
  padding: 51.2rem
}

[dir='rtl'] .rtl\:p-640,[dir='rtl'].rtl\:p-640 {
  padding: 64rem
}

[dir='rtl'] .rtl\:p-xs,[dir='rtl'].rtl\:p-xs {
  padding: 32rem
}

[dir='rtl'] .rtl\:p-sm,[dir='rtl'].rtl\:p-sm {
  padding: 48rem
}

[dir='rtl'] .rtl\:p-md,[dir='rtl'].rtl\:p-md {
  padding: 64rem
}

[dir='rtl'] .rtl\:p-lg,[dir='rtl'].rtl\:p-lg {
  padding: 80rem
}

[dir='rtl'] .rtl\:p-xl,[dir='rtl'].rtl\:p-xl {
  padding: 96rem
}

[dir='rtl'] .rtl\:p-2xl,[dir='rtl'].rtl\:p-2xl {
  padding: 112rem
}

[dir='rtl'] .rtl\:p-3xl,[dir='rtl'].rtl\:p-3xl {
  padding: 128rem
}

[dir='rtl'] .rtl\:p-4xl,[dir='rtl'].rtl\:p-4xl {
  padding: 144rem
}

[dir='rtl'] .rtl\:p-5xl,[dir='rtl'].rtl\:p-5xl {
  padding: 160rem
}

[dir='rtl'] .rtl\:p-px,[dir='rtl'].rtl\:p-px {
  padding: 1px
}

[dir='rtl'] .rtl\:p-0\.5,[dir='rtl'].rtl\:p-0\.5 {
  padding: 0.05rem
}

[dir='rtl'] .rtl\:p-1\.5,[dir='rtl'].rtl\:p-1\.5 {
  padding: 0.15rem
}

[dir='rtl'] .rtl\:p-2\.5,[dir='rtl'].rtl\:p-2\.5 {
  padding: 0.25rem
}

[dir='rtl'] .rtl\:p-3\.5,[dir='rtl'].rtl\:p-3\.5 {
  padding: 0.35rem
}

[dir='rtl'] .rtl\:py-0,[dir='rtl'].rtl\:py-0 {
  padding-top: 0;
  padding-bottom: 0
}

[dir='rtl'] .rtl\:px-0,[dir='rtl'].rtl\:px-0 {
  padding-left: 0;
  padding-right: 0
}

[dir='rtl'] .rtl\:py-1,[dir='rtl'].rtl\:py-1 {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem
}

[dir='rtl'] .rtl\:px-1,[dir='rtl'].rtl\:px-1 {
  padding-left: 0.1rem;
  padding-right: 0.1rem
}

[dir='rtl'] .rtl\:py-2,[dir='rtl'].rtl\:py-2 {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem
}

[dir='rtl'] .rtl\:px-2,[dir='rtl'].rtl\:px-2 {
  padding-left: 0.2rem;
  padding-right: 0.2rem
}

[dir='rtl'] .rtl\:py-3,[dir='rtl'].rtl\:py-3 {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem
}

[dir='rtl'] .rtl\:px-3,[dir='rtl'].rtl\:px-3 {
  padding-left: 0.3rem;
  padding-right: 0.3rem
}

[dir='rtl'] .rtl\:py-4,[dir='rtl'].rtl\:py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

[dir='rtl'] .rtl\:px-4,[dir='rtl'].rtl\:px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

[dir='rtl'] .rtl\:py-5,[dir='rtl'].rtl\:py-5 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

[dir='rtl'] .rtl\:px-5,[dir='rtl'].rtl\:px-5 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

[dir='rtl'] .rtl\:py-6,[dir='rtl'].rtl\:py-6 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem
}

[dir='rtl'] .rtl\:px-6,[dir='rtl'].rtl\:px-6 {
  padding-left: 0.6rem;
  padding-right: 0.6rem
}

[dir='rtl'] .rtl\:py-7,[dir='rtl'].rtl\:py-7 {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem
}

[dir='rtl'] .rtl\:px-7,[dir='rtl'].rtl\:px-7 {
  padding-left: 0.7rem;
  padding-right: 0.7rem
}

[dir='rtl'] .rtl\:py-8,[dir='rtl'].rtl\:py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

[dir='rtl'] .rtl\:px-8,[dir='rtl'].rtl\:px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

[dir='rtl'] .rtl\:py-9,[dir='rtl'].rtl\:py-9 {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem
}

[dir='rtl'] .rtl\:px-9,[dir='rtl'].rtl\:px-9 {
  padding-left: 0.9rem;
  padding-right: 0.9rem
}

[dir='rtl'] .rtl\:py-10,[dir='rtl'].rtl\:py-10 {
  padding-top: 1.0rem;
  padding-bottom: 1.0rem
}

[dir='rtl'] .rtl\:px-10,[dir='rtl'].rtl\:px-10 {
  padding-left: 1.0rem;
  padding-right: 1.0rem
}

[dir='rtl'] .rtl\:py-12,[dir='rtl'].rtl\:py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

[dir='rtl'] .rtl\:px-12,[dir='rtl'].rtl\:px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

[dir='rtl'] .rtl\:py-14,[dir='rtl'].rtl\:py-14 {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem
}

[dir='rtl'] .rtl\:px-14,[dir='rtl'].rtl\:px-14 {
  padding-left: 1.4rem;
  padding-right: 1.4rem
}

[dir='rtl'] .rtl\:py-16,[dir='rtl'].rtl\:py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

[dir='rtl'] .rtl\:px-16,[dir='rtl'].rtl\:px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

[dir='rtl'] .rtl\:py-20,[dir='rtl'].rtl\:py-20 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

[dir='rtl'] .rtl\:px-20,[dir='rtl'].rtl\:px-20 {
  padding-left: 2rem;
  padding-right: 2rem
}

[dir='rtl'] .rtl\:py-24,[dir='rtl'].rtl\:py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem
}

[dir='rtl'] .rtl\:px-24,[dir='rtl'].rtl\:px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

[dir='rtl'] .rtl\:py-28,[dir='rtl'].rtl\:py-28 {
  padding-top: 2.8rem;
  padding-bottom: 2.8rem
}

[dir='rtl'] .rtl\:px-28,[dir='rtl'].rtl\:px-28 {
  padding-left: 2.8rem;
  padding-right: 2.8rem
}

[dir='rtl'] .rtl\:py-32,[dir='rtl'].rtl\:py-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem
}

[dir='rtl'] .rtl\:px-32,[dir='rtl'].rtl\:px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem
}

[dir='rtl'] .rtl\:py-36,[dir='rtl'].rtl\:py-36 {
  padding-top: 3.6rem;
  padding-bottom: 3.6rem
}

[dir='rtl'] .rtl\:px-36,[dir='rtl'].rtl\:px-36 {
  padding-left: 3.6rem;
  padding-right: 3.6rem
}

[dir='rtl'] .rtl\:py-40,[dir='rtl'].rtl\:py-40 {
  padding-top: 4rem;
  padding-bottom: 4rem
}

[dir='rtl'] .rtl\:px-40,[dir='rtl'].rtl\:px-40 {
  padding-left: 4rem;
  padding-right: 4rem
}

[dir='rtl'] .rtl\:py-44,[dir='rtl'].rtl\:py-44 {
  padding-top: 4.4rem;
  padding-bottom: 4.4rem
}

[dir='rtl'] .rtl\:px-44,[dir='rtl'].rtl\:px-44 {
  padding-left: 4.4rem;
  padding-right: 4.4rem
}

[dir='rtl'] .rtl\:py-48,[dir='rtl'].rtl\:py-48 {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem
}

[dir='rtl'] .rtl\:px-48,[dir='rtl'].rtl\:px-48 {
  padding-left: 4.8rem;
  padding-right: 4.8rem
}

[dir='rtl'] .rtl\:py-52,[dir='rtl'].rtl\:py-52 {
  padding-top: 5.2rem;
  padding-bottom: 5.2rem
}

[dir='rtl'] .rtl\:px-52,[dir='rtl'].rtl\:px-52 {
  padding-left: 5.2rem;
  padding-right: 5.2rem
}

[dir='rtl'] .rtl\:py-56,[dir='rtl'].rtl\:py-56 {
  padding-top: 5.6rem;
  padding-bottom: 5.6rem
}

[dir='rtl'] .rtl\:px-56,[dir='rtl'].rtl\:px-56 {
  padding-left: 5.6rem;
  padding-right: 5.6rem
}

[dir='rtl'] .rtl\:py-60,[dir='rtl'].rtl\:py-60 {
  padding-top: 6rem;
  padding-bottom: 6rem
}

[dir='rtl'] .rtl\:px-60,[dir='rtl'].rtl\:px-60 {
  padding-left: 6rem;
  padding-right: 6rem
}

[dir='rtl'] .rtl\:py-64,[dir='rtl'].rtl\:py-64 {
  padding-top: 6.4rem;
  padding-bottom: 6.4rem
}

[dir='rtl'] .rtl\:px-64,[dir='rtl'].rtl\:px-64 {
  padding-left: 6.4rem;
  padding-right: 6.4rem
}

[dir='rtl'] .rtl\:py-68,[dir='rtl'].rtl\:py-68 {
  padding-top: 6.8rem;
  padding-bottom: 6.8rem
}

[dir='rtl'] .rtl\:px-68,[dir='rtl'].rtl\:px-68 {
  padding-left: 6.8rem;
  padding-right: 6.8rem
}

[dir='rtl'] .rtl\:py-72,[dir='rtl'].rtl\:py-72 {
  padding-top: 7.2rem;
  padding-bottom: 7.2rem
}

[dir='rtl'] .rtl\:px-72,[dir='rtl'].rtl\:px-72 {
  padding-left: 7.2rem;
  padding-right: 7.2rem
}

[dir='rtl'] .rtl\:py-76,[dir='rtl'].rtl\:py-76 {
  padding-top: 7.6rem;
  padding-bottom: 7.6rem
}

[dir='rtl'] .rtl\:px-76,[dir='rtl'].rtl\:px-76 {
  padding-left: 7.6rem;
  padding-right: 7.6rem
}

[dir='rtl'] .rtl\:py-80,[dir='rtl'].rtl\:py-80 {
  padding-top: 8rem;
  padding-bottom: 8rem
}

[dir='rtl'] .rtl\:px-80,[dir='rtl'].rtl\:px-80 {
  padding-left: 8rem;
  padding-right: 8rem
}

[dir='rtl'] .rtl\:py-84,[dir='rtl'].rtl\:py-84 {
  padding-top: 8.4rem;
  padding-bottom: 8.4rem
}

[dir='rtl'] .rtl\:px-84,[dir='rtl'].rtl\:px-84 {
  padding-left: 8.4rem;
  padding-right: 8.4rem
}

[dir='rtl'] .rtl\:py-88,[dir='rtl'].rtl\:py-88 {
  padding-top: 8.8rem;
  padding-bottom: 8.8rem
}

[dir='rtl'] .rtl\:px-88,[dir='rtl'].rtl\:px-88 {
  padding-left: 8.8rem;
  padding-right: 8.8rem
}

[dir='rtl'] .rtl\:py-92,[dir='rtl'].rtl\:py-92 {
  padding-top: 9.2rem;
  padding-bottom: 9.2rem
}

[dir='rtl'] .rtl\:px-92,[dir='rtl'].rtl\:px-92 {
  padding-left: 9.2rem;
  padding-right: 9.2rem
}

[dir='rtl'] .rtl\:py-96,[dir='rtl'].rtl\:py-96 {
  padding-top: 9.6rem;
  padding-bottom: 9.6rem
}

[dir='rtl'] .rtl\:px-96,[dir='rtl'].rtl\:px-96 {
  padding-left: 9.6rem;
  padding-right: 9.6rem
}

[dir='rtl'] .rtl\:py-128,[dir='rtl'].rtl\:py-128 {
  padding-top: 12.8rem;
  padding-bottom: 12.8rem
}

[dir='rtl'] .rtl\:px-128,[dir='rtl'].rtl\:px-128 {
  padding-left: 12.8rem;
  padding-right: 12.8rem
}

[dir='rtl'] .rtl\:py-136,[dir='rtl'].rtl\:py-136 {
  padding-top: 13.6rem;
  padding-bottom: 13.6rem
}

[dir='rtl'] .rtl\:px-136,[dir='rtl'].rtl\:px-136 {
  padding-left: 13.6rem;
  padding-right: 13.6rem
}

[dir='rtl'] .rtl\:py-160,[dir='rtl'].rtl\:py-160 {
  padding-top: 16rem;
  padding-bottom: 16rem
}

[dir='rtl'] .rtl\:px-160,[dir='rtl'].rtl\:px-160 {
  padding-left: 16rem;
  padding-right: 16rem
}

[dir='rtl'] .rtl\:py-192,[dir='rtl'].rtl\:py-192 {
  padding-top: 19.2rem;
  padding-bottom: 19.2rem
}

[dir='rtl'] .rtl\:px-192,[dir='rtl'].rtl\:px-192 {
  padding-left: 19.2rem;
  padding-right: 19.2rem
}

[dir='rtl'] .rtl\:py-200,[dir='rtl'].rtl\:py-200 {
  padding-top: 20rem;
  padding-bottom: 20rem
}

[dir='rtl'] .rtl\:px-200,[dir='rtl'].rtl\:px-200 {
  padding-left: 20rem;
  padding-right: 20rem
}

[dir='rtl'] .rtl\:py-208,[dir='rtl'].rtl\:py-208 {
  padding-top: 20.8rem;
  padding-bottom: 20.8rem
}

[dir='rtl'] .rtl\:px-208,[dir='rtl'].rtl\:px-208 {
  padding-left: 20.8rem;
  padding-right: 20.8rem
}

[dir='rtl'] .rtl\:py-216,[dir='rtl'].rtl\:py-216 {
  padding-top: 21.6rem;
  padding-bottom: 21.6rem
}

[dir='rtl'] .rtl\:px-216,[dir='rtl'].rtl\:px-216 {
  padding-left: 21.6rem;
  padding-right: 21.6rem
}

[dir='rtl'] .rtl\:py-224,[dir='rtl'].rtl\:py-224 {
  padding-top: 22.4rem;
  padding-bottom: 22.4rem
}

[dir='rtl'] .rtl\:px-224,[dir='rtl'].rtl\:px-224 {
  padding-left: 22.4rem;
  padding-right: 22.4rem
}

[dir='rtl'] .rtl\:py-256,[dir='rtl'].rtl\:py-256 {
  padding-top: 25.6rem;
  padding-bottom: 25.6rem
}

[dir='rtl'] .rtl\:px-256,[dir='rtl'].rtl\:px-256 {
  padding-left: 25.6rem;
  padding-right: 25.6rem
}

[dir='rtl'] .rtl\:py-288,[dir='rtl'].rtl\:py-288 {
  padding-top: 28.8rem;
  padding-bottom: 28.8rem
}

[dir='rtl'] .rtl\:px-288,[dir='rtl'].rtl\:px-288 {
  padding-left: 28.8rem;
  padding-right: 28.8rem
}

[dir='rtl'] .rtl\:py-320,[dir='rtl'].rtl\:py-320 {
  padding-top: 32rem;
  padding-bottom: 32rem
}

[dir='rtl'] .rtl\:px-320,[dir='rtl'].rtl\:px-320 {
  padding-left: 32rem;
  padding-right: 32rem
}

[dir='rtl'] .rtl\:py-360,[dir='rtl'].rtl\:py-360 {
  padding-top: 36rem;
  padding-bottom: 36rem
}

[dir='rtl'] .rtl\:px-360,[dir='rtl'].rtl\:px-360 {
  padding-left: 36rem;
  padding-right: 36rem
}

[dir='rtl'] .rtl\:py-384,[dir='rtl'].rtl\:py-384 {
  padding-top: 38.4rem;
  padding-bottom: 38.4rem
}

[dir='rtl'] .rtl\:px-384,[dir='rtl'].rtl\:px-384 {
  padding-left: 38.4rem;
  padding-right: 38.4rem
}

[dir='rtl'] .rtl\:py-400,[dir='rtl'].rtl\:py-400 {
  padding-top: 40rem;
  padding-bottom: 40rem
}

[dir='rtl'] .rtl\:px-400,[dir='rtl'].rtl\:px-400 {
  padding-left: 40rem;
  padding-right: 40rem
}

[dir='rtl'] .rtl\:py-512,[dir='rtl'].rtl\:py-512 {
  padding-top: 51.2rem;
  padding-bottom: 51.2rem
}

[dir='rtl'] .rtl\:px-512,[dir='rtl'].rtl\:px-512 {
  padding-left: 51.2rem;
  padding-right: 51.2rem
}

[dir='rtl'] .rtl\:py-640,[dir='rtl'].rtl\:py-640 {
  padding-top: 64rem;
  padding-bottom: 64rem
}

[dir='rtl'] .rtl\:px-640,[dir='rtl'].rtl\:px-640 {
  padding-left: 64rem;
  padding-right: 64rem
}

[dir='rtl'] .rtl\:py-xs,[dir='rtl'].rtl\:py-xs {
  padding-top: 32rem;
  padding-bottom: 32rem
}

[dir='rtl'] .rtl\:px-xs,[dir='rtl'].rtl\:px-xs {
  padding-left: 32rem;
  padding-right: 32rem
}

[dir='rtl'] .rtl\:py-sm,[dir='rtl'].rtl\:py-sm {
  padding-top: 48rem;
  padding-bottom: 48rem
}

[dir='rtl'] .rtl\:px-sm,[dir='rtl'].rtl\:px-sm {
  padding-left: 48rem;
  padding-right: 48rem
}

[dir='rtl'] .rtl\:py-md,[dir='rtl'].rtl\:py-md {
  padding-top: 64rem;
  padding-bottom: 64rem
}

[dir='rtl'] .rtl\:px-md,[dir='rtl'].rtl\:px-md {
  padding-left: 64rem;
  padding-right: 64rem
}

[dir='rtl'] .rtl\:py-lg,[dir='rtl'].rtl\:py-lg {
  padding-top: 80rem;
  padding-bottom: 80rem
}

[dir='rtl'] .rtl\:px-lg,[dir='rtl'].rtl\:px-lg {
  padding-left: 80rem;
  padding-right: 80rem
}

[dir='rtl'] .rtl\:py-xl,[dir='rtl'].rtl\:py-xl {
  padding-top: 96rem;
  padding-bottom: 96rem
}

[dir='rtl'] .rtl\:px-xl,[dir='rtl'].rtl\:px-xl {
  padding-left: 96rem;
  padding-right: 96rem
}

[dir='rtl'] .rtl\:py-2xl,[dir='rtl'].rtl\:py-2xl {
  padding-top: 112rem;
  padding-bottom: 112rem
}

[dir='rtl'] .rtl\:px-2xl,[dir='rtl'].rtl\:px-2xl {
  padding-left: 112rem;
  padding-right: 112rem
}

[dir='rtl'] .rtl\:py-3xl,[dir='rtl'].rtl\:py-3xl {
  padding-top: 128rem;
  padding-bottom: 128rem
}

[dir='rtl'] .rtl\:px-3xl,[dir='rtl'].rtl\:px-3xl {
  padding-left: 128rem;
  padding-right: 128rem
}

[dir='rtl'] .rtl\:py-4xl,[dir='rtl'].rtl\:py-4xl {
  padding-top: 144rem;
  padding-bottom: 144rem
}

[dir='rtl'] .rtl\:px-4xl,[dir='rtl'].rtl\:px-4xl {
  padding-left: 144rem;
  padding-right: 144rem
}

[dir='rtl'] .rtl\:py-5xl,[dir='rtl'].rtl\:py-5xl {
  padding-top: 160rem;
  padding-bottom: 160rem
}

[dir='rtl'] .rtl\:px-5xl,[dir='rtl'].rtl\:px-5xl {
  padding-left: 160rem;
  padding-right: 160rem
}

[dir='rtl'] .rtl\:py-px,[dir='rtl'].rtl\:py-px {
  padding-top: 1px;
  padding-bottom: 1px
}

[dir='rtl'] .rtl\:px-px,[dir='rtl'].rtl\:px-px {
  padding-left: 1px;
  padding-right: 1px
}

[dir='rtl'] .rtl\:py-0\.5,[dir='rtl'].rtl\:py-0\.5 {
  padding-top: 0.05rem;
  padding-bottom: 0.05rem
}

[dir='rtl'] .rtl\:px-0\.5,[dir='rtl'].rtl\:px-0\.5 {
  padding-left: 0.05rem;
  padding-right: 0.05rem
}

[dir='rtl'] .rtl\:py-1\.5,[dir='rtl'].rtl\:py-1\.5 {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem
}

[dir='rtl'] .rtl\:px-1\.5,[dir='rtl'].rtl\:px-1\.5 {
  padding-left: 0.15rem;
  padding-right: 0.15rem
}

[dir='rtl'] .rtl\:py-2\.5,[dir='rtl'].rtl\:py-2\.5 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

[dir='rtl'] .rtl\:px-2\.5,[dir='rtl'].rtl\:px-2\.5 {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

[dir='rtl'] .rtl\:py-3\.5,[dir='rtl'].rtl\:py-3\.5 {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem
}

[dir='rtl'] .rtl\:px-3\.5,[dir='rtl'].rtl\:px-3\.5 {
  padding-left: 0.35rem;
  padding-right: 0.35rem
}

[dir='rtl'] .rtl\:pt-0,[dir='rtl'].rtl\:pt-0 {
  padding-top: 0
}

[dir='rtl'] .rtl\:pr-0,[dir='rtl'].rtl\:pr-0 {
  padding-right: 0
}

[dir='rtl'] .rtl\:pb-0,[dir='rtl'].rtl\:pb-0 {
  padding-bottom: 0
}

[dir='rtl'] .rtl\:pl-0,[dir='rtl'].rtl\:pl-0 {
  padding-left: 0
}

[dir='rtl'] .rtl\:pt-1,[dir='rtl'].rtl\:pt-1 {
  padding-top: 0.1rem
}

[dir='rtl'] .rtl\:pr-1,[dir='rtl'].rtl\:pr-1 {
  padding-right: 0.1rem
}

[dir='rtl'] .rtl\:pb-1,[dir='rtl'].rtl\:pb-1 {
  padding-bottom: 0.1rem
}

[dir='rtl'] .rtl\:pl-1,[dir='rtl'].rtl\:pl-1 {
  padding-left: 0.1rem
}

[dir='rtl'] .rtl\:pt-2,[dir='rtl'].rtl\:pt-2 {
  padding-top: 0.2rem
}

[dir='rtl'] .rtl\:pr-2,[dir='rtl'].rtl\:pr-2 {
  padding-right: 0.2rem
}

[dir='rtl'] .rtl\:pb-2,[dir='rtl'].rtl\:pb-2 {
  padding-bottom: 0.2rem
}

[dir='rtl'] .rtl\:pl-2,[dir='rtl'].rtl\:pl-2 {
  padding-left: 0.2rem
}

[dir='rtl'] .rtl\:pt-3,[dir='rtl'].rtl\:pt-3 {
  padding-top: 0.3rem
}

[dir='rtl'] .rtl\:pr-3,[dir='rtl'].rtl\:pr-3 {
  padding-right: 0.3rem
}

[dir='rtl'] .rtl\:pb-3,[dir='rtl'].rtl\:pb-3 {
  padding-bottom: 0.3rem
}

[dir='rtl'] .rtl\:pl-3,[dir='rtl'].rtl\:pl-3 {
  padding-left: 0.3rem
}

[dir='rtl'] .rtl\:pt-4,[dir='rtl'].rtl\:pt-4 {
  padding-top: 0.4rem
}

[dir='rtl'] .rtl\:pr-4,[dir='rtl'].rtl\:pr-4 {
  padding-right: 0.4rem
}

[dir='rtl'] .rtl\:pb-4,[dir='rtl'].rtl\:pb-4 {
  padding-bottom: 0.4rem
}

[dir='rtl'] .rtl\:pl-4,[dir='rtl'].rtl\:pl-4 {
  padding-left: 0.4rem
}

[dir='rtl'] .rtl\:pt-5,[dir='rtl'].rtl\:pt-5 {
  padding-top: 0.5rem
}

[dir='rtl'] .rtl\:pr-5,[dir='rtl'].rtl\:pr-5 {
  padding-right: 0.5rem
}

[dir='rtl'] .rtl\:pb-5,[dir='rtl'].rtl\:pb-5 {
  padding-bottom: 0.5rem
}

[dir='rtl'] .rtl\:pl-5,[dir='rtl'].rtl\:pl-5 {
  padding-left: 0.5rem
}

[dir='rtl'] .rtl\:pt-6,[dir='rtl'].rtl\:pt-6 {
  padding-top: 0.6rem
}

[dir='rtl'] .rtl\:pr-6,[dir='rtl'].rtl\:pr-6 {
  padding-right: 0.6rem
}

[dir='rtl'] .rtl\:pb-6,[dir='rtl'].rtl\:pb-6 {
  padding-bottom: 0.6rem
}

[dir='rtl'] .rtl\:pl-6,[dir='rtl'].rtl\:pl-6 {
  padding-left: 0.6rem
}

[dir='rtl'] .rtl\:pt-7,[dir='rtl'].rtl\:pt-7 {
  padding-top: 0.7rem
}

[dir='rtl'] .rtl\:pr-7,[dir='rtl'].rtl\:pr-7 {
  padding-right: 0.7rem
}

[dir='rtl'] .rtl\:pb-7,[dir='rtl'].rtl\:pb-7 {
  padding-bottom: 0.7rem
}

[dir='rtl'] .rtl\:pl-7,[dir='rtl'].rtl\:pl-7 {
  padding-left: 0.7rem
}

[dir='rtl'] .rtl\:pt-8,[dir='rtl'].rtl\:pt-8 {
  padding-top: 0.8rem
}

[dir='rtl'] .rtl\:pr-8,[dir='rtl'].rtl\:pr-8 {
  padding-right: 0.8rem
}

[dir='rtl'] .rtl\:pb-8,[dir='rtl'].rtl\:pb-8 {
  padding-bottom: 0.8rem
}

[dir='rtl'] .rtl\:pl-8,[dir='rtl'].rtl\:pl-8 {
  padding-left: 0.8rem
}

[dir='rtl'] .rtl\:pt-9,[dir='rtl'].rtl\:pt-9 {
  padding-top: 0.9rem
}

[dir='rtl'] .rtl\:pr-9,[dir='rtl'].rtl\:pr-9 {
  padding-right: 0.9rem
}

[dir='rtl'] .rtl\:pb-9,[dir='rtl'].rtl\:pb-9 {
  padding-bottom: 0.9rem
}

[dir='rtl'] .rtl\:pl-9,[dir='rtl'].rtl\:pl-9 {
  padding-left: 0.9rem
}

[dir='rtl'] .rtl\:pt-10,[dir='rtl'].rtl\:pt-10 {
  padding-top: 1.0rem
}

[dir='rtl'] .rtl\:pr-10,[dir='rtl'].rtl\:pr-10 {
  padding-right: 1.0rem
}

[dir='rtl'] .rtl\:pb-10,[dir='rtl'].rtl\:pb-10 {
  padding-bottom: 1.0rem
}

[dir='rtl'] .rtl\:pl-10,[dir='rtl'].rtl\:pl-10 {
  padding-left: 1.0rem
}

[dir='rtl'] .rtl\:pt-12,[dir='rtl'].rtl\:pt-12 {
  padding-top: 1.2rem
}

[dir='rtl'] .rtl\:pr-12,[dir='rtl'].rtl\:pr-12 {
  padding-right: 1.2rem
}

[dir='rtl'] .rtl\:pb-12,[dir='rtl'].rtl\:pb-12 {
  padding-bottom: 1.2rem
}

[dir='rtl'] .rtl\:pl-12,[dir='rtl'].rtl\:pl-12 {
  padding-left: 1.2rem
}

[dir='rtl'] .rtl\:pt-14,[dir='rtl'].rtl\:pt-14 {
  padding-top: 1.4rem
}

[dir='rtl'] .rtl\:pr-14,[dir='rtl'].rtl\:pr-14 {
  padding-right: 1.4rem
}

[dir='rtl'] .rtl\:pb-14,[dir='rtl'].rtl\:pb-14 {
  padding-bottom: 1.4rem
}

[dir='rtl'] .rtl\:pl-14,[dir='rtl'].rtl\:pl-14 {
  padding-left: 1.4rem
}

[dir='rtl'] .rtl\:pt-16,[dir='rtl'].rtl\:pt-16 {
  padding-top: 1.6rem
}

[dir='rtl'] .rtl\:pr-16,[dir='rtl'].rtl\:pr-16 {
  padding-right: 1.6rem
}

[dir='rtl'] .rtl\:pb-16,[dir='rtl'].rtl\:pb-16 {
  padding-bottom: 1.6rem
}

[dir='rtl'] .rtl\:pl-16,[dir='rtl'].rtl\:pl-16 {
  padding-left: 1.6rem
}

[dir='rtl'] .rtl\:pt-20,[dir='rtl'].rtl\:pt-20 {
  padding-top: 2rem
}

[dir='rtl'] .rtl\:pr-20,[dir='rtl'].rtl\:pr-20 {
  padding-right: 2rem
}

[dir='rtl'] .rtl\:pb-20,[dir='rtl'].rtl\:pb-20 {
  padding-bottom: 2rem
}

[dir='rtl'] .rtl\:pl-20,[dir='rtl'].rtl\:pl-20 {
  padding-left: 2rem
}

[dir='rtl'] .rtl\:pt-24,[dir='rtl'].rtl\:pt-24 {
  padding-top: 2.4rem
}

[dir='rtl'] .rtl\:pr-24,[dir='rtl'].rtl\:pr-24 {
  padding-right: 2.4rem
}

[dir='rtl'] .rtl\:pb-24,[dir='rtl'].rtl\:pb-24 {
  padding-bottom: 2.4rem
}

[dir='rtl'] .rtl\:pl-24,[dir='rtl'].rtl\:pl-24 {
  padding-left: 2.4rem
}

[dir='rtl'] .rtl\:pt-28,[dir='rtl'].rtl\:pt-28 {
  padding-top: 2.8rem
}

[dir='rtl'] .rtl\:pr-28,[dir='rtl'].rtl\:pr-28 {
  padding-right: 2.8rem
}

[dir='rtl'] .rtl\:pb-28,[dir='rtl'].rtl\:pb-28 {
  padding-bottom: 2.8rem
}

[dir='rtl'] .rtl\:pl-28,[dir='rtl'].rtl\:pl-28 {
  padding-left: 2.8rem
}

[dir='rtl'] .rtl\:pt-32,[dir='rtl'].rtl\:pt-32 {
  padding-top: 3.2rem
}

[dir='rtl'] .rtl\:pr-32,[dir='rtl'].rtl\:pr-32 {
  padding-right: 3.2rem
}

[dir='rtl'] .rtl\:pb-32,[dir='rtl'].rtl\:pb-32 {
  padding-bottom: 3.2rem
}

[dir='rtl'] .rtl\:pl-32,[dir='rtl'].rtl\:pl-32 {
  padding-left: 3.2rem
}

[dir='rtl'] .rtl\:pt-36,[dir='rtl'].rtl\:pt-36 {
  padding-top: 3.6rem
}

[dir='rtl'] .rtl\:pr-36,[dir='rtl'].rtl\:pr-36 {
  padding-right: 3.6rem
}

[dir='rtl'] .rtl\:pb-36,[dir='rtl'].rtl\:pb-36 {
  padding-bottom: 3.6rem
}

[dir='rtl'] .rtl\:pl-36,[dir='rtl'].rtl\:pl-36 {
  padding-left: 3.6rem
}

[dir='rtl'] .rtl\:pt-40,[dir='rtl'].rtl\:pt-40 {
  padding-top: 4rem
}

[dir='rtl'] .rtl\:pr-40,[dir='rtl'].rtl\:pr-40 {
  padding-right: 4rem
}

[dir='rtl'] .rtl\:pb-40,[dir='rtl'].rtl\:pb-40 {
  padding-bottom: 4rem
}

[dir='rtl'] .rtl\:pl-40,[dir='rtl'].rtl\:pl-40 {
  padding-left: 4rem
}

[dir='rtl'] .rtl\:pt-44,[dir='rtl'].rtl\:pt-44 {
  padding-top: 4.4rem
}

[dir='rtl'] .rtl\:pr-44,[dir='rtl'].rtl\:pr-44 {
  padding-right: 4.4rem
}

[dir='rtl'] .rtl\:pb-44,[dir='rtl'].rtl\:pb-44 {
  padding-bottom: 4.4rem
}

[dir='rtl'] .rtl\:pl-44,[dir='rtl'].rtl\:pl-44 {
  padding-left: 4.4rem
}

[dir='rtl'] .rtl\:pt-48,[dir='rtl'].rtl\:pt-48 {
  padding-top: 4.8rem
}

[dir='rtl'] .rtl\:pr-48,[dir='rtl'].rtl\:pr-48 {
  padding-right: 4.8rem
}

[dir='rtl'] .rtl\:pb-48,[dir='rtl'].rtl\:pb-48 {
  padding-bottom: 4.8rem
}

[dir='rtl'] .rtl\:pl-48,[dir='rtl'].rtl\:pl-48 {
  padding-left: 4.8rem
}

[dir='rtl'] .rtl\:pt-52,[dir='rtl'].rtl\:pt-52 {
  padding-top: 5.2rem
}

[dir='rtl'] .rtl\:pr-52,[dir='rtl'].rtl\:pr-52 {
  padding-right: 5.2rem
}

[dir='rtl'] .rtl\:pb-52,[dir='rtl'].rtl\:pb-52 {
  padding-bottom: 5.2rem
}

[dir='rtl'] .rtl\:pl-52,[dir='rtl'].rtl\:pl-52 {
  padding-left: 5.2rem
}

[dir='rtl'] .rtl\:pt-56,[dir='rtl'].rtl\:pt-56 {
  padding-top: 5.6rem
}

[dir='rtl'] .rtl\:pr-56,[dir='rtl'].rtl\:pr-56 {
  padding-right: 5.6rem
}

[dir='rtl'] .rtl\:pb-56,[dir='rtl'].rtl\:pb-56 {
  padding-bottom: 5.6rem
}

[dir='rtl'] .rtl\:pl-56,[dir='rtl'].rtl\:pl-56 {
  padding-left: 5.6rem
}

[dir='rtl'] .rtl\:pt-60,[dir='rtl'].rtl\:pt-60 {
  padding-top: 6rem
}

[dir='rtl'] .rtl\:pr-60,[dir='rtl'].rtl\:pr-60 {
  padding-right: 6rem
}

[dir='rtl'] .rtl\:pb-60,[dir='rtl'].rtl\:pb-60 {
  padding-bottom: 6rem
}

[dir='rtl'] .rtl\:pl-60,[dir='rtl'].rtl\:pl-60 {
  padding-left: 6rem
}

[dir='rtl'] .rtl\:pt-64,[dir='rtl'].rtl\:pt-64 {
  padding-top: 6.4rem
}

[dir='rtl'] .rtl\:pr-64,[dir='rtl'].rtl\:pr-64 {
  padding-right: 6.4rem
}

[dir='rtl'] .rtl\:pb-64,[dir='rtl'].rtl\:pb-64 {
  padding-bottom: 6.4rem
}

[dir='rtl'] .rtl\:pl-64,[dir='rtl'].rtl\:pl-64 {
  padding-left: 6.4rem
}

[dir='rtl'] .rtl\:pt-68,[dir='rtl'].rtl\:pt-68 {
  padding-top: 6.8rem
}

[dir='rtl'] .rtl\:pr-68,[dir='rtl'].rtl\:pr-68 {
  padding-right: 6.8rem
}

[dir='rtl'] .rtl\:pb-68,[dir='rtl'].rtl\:pb-68 {
  padding-bottom: 6.8rem
}

[dir='rtl'] .rtl\:pl-68,[dir='rtl'].rtl\:pl-68 {
  padding-left: 6.8rem
}

[dir='rtl'] .rtl\:pt-72,[dir='rtl'].rtl\:pt-72 {
  padding-top: 7.2rem
}

[dir='rtl'] .rtl\:pr-72,[dir='rtl'].rtl\:pr-72 {
  padding-right: 7.2rem
}

[dir='rtl'] .rtl\:pb-72,[dir='rtl'].rtl\:pb-72 {
  padding-bottom: 7.2rem
}

[dir='rtl'] .rtl\:pl-72,[dir='rtl'].rtl\:pl-72 {
  padding-left: 7.2rem
}

[dir='rtl'] .rtl\:pt-76,[dir='rtl'].rtl\:pt-76 {
  padding-top: 7.6rem
}

[dir='rtl'] .rtl\:pr-76,[dir='rtl'].rtl\:pr-76 {
  padding-right: 7.6rem
}

[dir='rtl'] .rtl\:pb-76,[dir='rtl'].rtl\:pb-76 {
  padding-bottom: 7.6rem
}

[dir='rtl'] .rtl\:pl-76,[dir='rtl'].rtl\:pl-76 {
  padding-left: 7.6rem
}

[dir='rtl'] .rtl\:pt-80,[dir='rtl'].rtl\:pt-80 {
  padding-top: 8rem
}

[dir='rtl'] .rtl\:pr-80,[dir='rtl'].rtl\:pr-80 {
  padding-right: 8rem
}

[dir='rtl'] .rtl\:pb-80,[dir='rtl'].rtl\:pb-80 {
  padding-bottom: 8rem
}

[dir='rtl'] .rtl\:pl-80,[dir='rtl'].rtl\:pl-80 {
  padding-left: 8rem
}

[dir='rtl'] .rtl\:pt-84,[dir='rtl'].rtl\:pt-84 {
  padding-top: 8.4rem
}

[dir='rtl'] .rtl\:pr-84,[dir='rtl'].rtl\:pr-84 {
  padding-right: 8.4rem
}

[dir='rtl'] .rtl\:pb-84,[dir='rtl'].rtl\:pb-84 {
  padding-bottom: 8.4rem
}

[dir='rtl'] .rtl\:pl-84,[dir='rtl'].rtl\:pl-84 {
  padding-left: 8.4rem
}

[dir='rtl'] .rtl\:pt-88,[dir='rtl'].rtl\:pt-88 {
  padding-top: 8.8rem
}

[dir='rtl'] .rtl\:pr-88,[dir='rtl'].rtl\:pr-88 {
  padding-right: 8.8rem
}

[dir='rtl'] .rtl\:pb-88,[dir='rtl'].rtl\:pb-88 {
  padding-bottom: 8.8rem
}

[dir='rtl'] .rtl\:pl-88,[dir='rtl'].rtl\:pl-88 {
  padding-left: 8.8rem
}

[dir='rtl'] .rtl\:pt-92,[dir='rtl'].rtl\:pt-92 {
  padding-top: 9.2rem
}

[dir='rtl'] .rtl\:pr-92,[dir='rtl'].rtl\:pr-92 {
  padding-right: 9.2rem
}

[dir='rtl'] .rtl\:pb-92,[dir='rtl'].rtl\:pb-92 {
  padding-bottom: 9.2rem
}

[dir='rtl'] .rtl\:pl-92,[dir='rtl'].rtl\:pl-92 {
  padding-left: 9.2rem
}

[dir='rtl'] .rtl\:pt-96,[dir='rtl'].rtl\:pt-96 {
  padding-top: 9.6rem
}

[dir='rtl'] .rtl\:pr-96,[dir='rtl'].rtl\:pr-96 {
  padding-right: 9.6rem
}

[dir='rtl'] .rtl\:pb-96,[dir='rtl'].rtl\:pb-96 {
  padding-bottom: 9.6rem
}

[dir='rtl'] .rtl\:pl-96,[dir='rtl'].rtl\:pl-96 {
  padding-left: 9.6rem
}

[dir='rtl'] .rtl\:pt-128,[dir='rtl'].rtl\:pt-128 {
  padding-top: 12.8rem
}

[dir='rtl'] .rtl\:pr-128,[dir='rtl'].rtl\:pr-128 {
  padding-right: 12.8rem
}

[dir='rtl'] .rtl\:pb-128,[dir='rtl'].rtl\:pb-128 {
  padding-bottom: 12.8rem
}

[dir='rtl'] .rtl\:pl-128,[dir='rtl'].rtl\:pl-128 {
  padding-left: 12.8rem
}

[dir='rtl'] .rtl\:pt-136,[dir='rtl'].rtl\:pt-136 {
  padding-top: 13.6rem
}

[dir='rtl'] .rtl\:pr-136,[dir='rtl'].rtl\:pr-136 {
  padding-right: 13.6rem
}

[dir='rtl'] .rtl\:pb-136,[dir='rtl'].rtl\:pb-136 {
  padding-bottom: 13.6rem
}

[dir='rtl'] .rtl\:pl-136,[dir='rtl'].rtl\:pl-136 {
  padding-left: 13.6rem
}

[dir='rtl'] .rtl\:pt-160,[dir='rtl'].rtl\:pt-160 {
  padding-top: 16rem
}

[dir='rtl'] .rtl\:pr-160,[dir='rtl'].rtl\:pr-160 {
  padding-right: 16rem
}

[dir='rtl'] .rtl\:pb-160,[dir='rtl'].rtl\:pb-160 {
  padding-bottom: 16rem
}

[dir='rtl'] .rtl\:pl-160,[dir='rtl'].rtl\:pl-160 {
  padding-left: 16rem
}

[dir='rtl'] .rtl\:pt-192,[dir='rtl'].rtl\:pt-192 {
  padding-top: 19.2rem
}

[dir='rtl'] .rtl\:pr-192,[dir='rtl'].rtl\:pr-192 {
  padding-right: 19.2rem
}

[dir='rtl'] .rtl\:pb-192,[dir='rtl'].rtl\:pb-192 {
  padding-bottom: 19.2rem
}

[dir='rtl'] .rtl\:pl-192,[dir='rtl'].rtl\:pl-192 {
  padding-left: 19.2rem
}

[dir='rtl'] .rtl\:pt-200,[dir='rtl'].rtl\:pt-200 {
  padding-top: 20rem
}

[dir='rtl'] .rtl\:pr-200,[dir='rtl'].rtl\:pr-200 {
  padding-right: 20rem
}

[dir='rtl'] .rtl\:pb-200,[dir='rtl'].rtl\:pb-200 {
  padding-bottom: 20rem
}

[dir='rtl'] .rtl\:pl-200,[dir='rtl'].rtl\:pl-200 {
  padding-left: 20rem
}

[dir='rtl'] .rtl\:pt-208,[dir='rtl'].rtl\:pt-208 {
  padding-top: 20.8rem
}

[dir='rtl'] .rtl\:pr-208,[dir='rtl'].rtl\:pr-208 {
  padding-right: 20.8rem
}

[dir='rtl'] .rtl\:pb-208,[dir='rtl'].rtl\:pb-208 {
  padding-bottom: 20.8rem
}

[dir='rtl'] .rtl\:pl-208,[dir='rtl'].rtl\:pl-208 {
  padding-left: 20.8rem
}

[dir='rtl'] .rtl\:pt-216,[dir='rtl'].rtl\:pt-216 {
  padding-top: 21.6rem
}

[dir='rtl'] .rtl\:pr-216,[dir='rtl'].rtl\:pr-216 {
  padding-right: 21.6rem
}

[dir='rtl'] .rtl\:pb-216,[dir='rtl'].rtl\:pb-216 {
  padding-bottom: 21.6rem
}

[dir='rtl'] .rtl\:pl-216,[dir='rtl'].rtl\:pl-216 {
  padding-left: 21.6rem
}

[dir='rtl'] .rtl\:pt-224,[dir='rtl'].rtl\:pt-224 {
  padding-top: 22.4rem
}

[dir='rtl'] .rtl\:pr-224,[dir='rtl'].rtl\:pr-224 {
  padding-right: 22.4rem
}

[dir='rtl'] .rtl\:pb-224,[dir='rtl'].rtl\:pb-224 {
  padding-bottom: 22.4rem
}

[dir='rtl'] .rtl\:pl-224,[dir='rtl'].rtl\:pl-224 {
  padding-left: 22.4rem
}

[dir='rtl'] .rtl\:pt-256,[dir='rtl'].rtl\:pt-256 {
  padding-top: 25.6rem
}

[dir='rtl'] .rtl\:pr-256,[dir='rtl'].rtl\:pr-256 {
  padding-right: 25.6rem
}

[dir='rtl'] .rtl\:pb-256,[dir='rtl'].rtl\:pb-256 {
  padding-bottom: 25.6rem
}

[dir='rtl'] .rtl\:pl-256,[dir='rtl'].rtl\:pl-256 {
  padding-left: 25.6rem
}

[dir='rtl'] .rtl\:pt-288,[dir='rtl'].rtl\:pt-288 {
  padding-top: 28.8rem
}

[dir='rtl'] .rtl\:pr-288,[dir='rtl'].rtl\:pr-288 {
  padding-right: 28.8rem
}

[dir='rtl'] .rtl\:pb-288,[dir='rtl'].rtl\:pb-288 {
  padding-bottom: 28.8rem
}

[dir='rtl'] .rtl\:pl-288,[dir='rtl'].rtl\:pl-288 {
  padding-left: 28.8rem
}

[dir='rtl'] .rtl\:pt-320,[dir='rtl'].rtl\:pt-320 {
  padding-top: 32rem
}

[dir='rtl'] .rtl\:pr-320,[dir='rtl'].rtl\:pr-320 {
  padding-right: 32rem
}

[dir='rtl'] .rtl\:pb-320,[dir='rtl'].rtl\:pb-320 {
  padding-bottom: 32rem
}

[dir='rtl'] .rtl\:pl-320,[dir='rtl'].rtl\:pl-320 {
  padding-left: 32rem
}

[dir='rtl'] .rtl\:pt-360,[dir='rtl'].rtl\:pt-360 {
  padding-top: 36rem
}

[dir='rtl'] .rtl\:pr-360,[dir='rtl'].rtl\:pr-360 {
  padding-right: 36rem
}

[dir='rtl'] .rtl\:pb-360,[dir='rtl'].rtl\:pb-360 {
  padding-bottom: 36rem
}

[dir='rtl'] .rtl\:pl-360,[dir='rtl'].rtl\:pl-360 {
  padding-left: 36rem
}

[dir='rtl'] .rtl\:pt-384,[dir='rtl'].rtl\:pt-384 {
  padding-top: 38.4rem
}

[dir='rtl'] .rtl\:pr-384,[dir='rtl'].rtl\:pr-384 {
  padding-right: 38.4rem
}

[dir='rtl'] .rtl\:pb-384,[dir='rtl'].rtl\:pb-384 {
  padding-bottom: 38.4rem
}

[dir='rtl'] .rtl\:pl-384,[dir='rtl'].rtl\:pl-384 {
  padding-left: 38.4rem
}

[dir='rtl'] .rtl\:pt-400,[dir='rtl'].rtl\:pt-400 {
  padding-top: 40rem
}

[dir='rtl'] .rtl\:pr-400,[dir='rtl'].rtl\:pr-400 {
  padding-right: 40rem
}

[dir='rtl'] .rtl\:pb-400,[dir='rtl'].rtl\:pb-400 {
  padding-bottom: 40rem
}

[dir='rtl'] .rtl\:pl-400,[dir='rtl'].rtl\:pl-400 {
  padding-left: 40rem
}

[dir='rtl'] .rtl\:pt-512,[dir='rtl'].rtl\:pt-512 {
  padding-top: 51.2rem
}

[dir='rtl'] .rtl\:pr-512,[dir='rtl'].rtl\:pr-512 {
  padding-right: 51.2rem
}

[dir='rtl'] .rtl\:pb-512,[dir='rtl'].rtl\:pb-512 {
  padding-bottom: 51.2rem
}

[dir='rtl'] .rtl\:pl-512,[dir='rtl'].rtl\:pl-512 {
  padding-left: 51.2rem
}

[dir='rtl'] .rtl\:pt-640,[dir='rtl'].rtl\:pt-640 {
  padding-top: 64rem
}

[dir='rtl'] .rtl\:pr-640,[dir='rtl'].rtl\:pr-640 {
  padding-right: 64rem
}

[dir='rtl'] .rtl\:pb-640,[dir='rtl'].rtl\:pb-640 {
  padding-bottom: 64rem
}

[dir='rtl'] .rtl\:pl-640,[dir='rtl'].rtl\:pl-640 {
  padding-left: 64rem
}

[dir='rtl'] .rtl\:pt-xs,[dir='rtl'].rtl\:pt-xs {
  padding-top: 32rem
}

[dir='rtl'] .rtl\:pr-xs,[dir='rtl'].rtl\:pr-xs {
  padding-right: 32rem
}

[dir='rtl'] .rtl\:pb-xs,[dir='rtl'].rtl\:pb-xs {
  padding-bottom: 32rem
}

[dir='rtl'] .rtl\:pl-xs,[dir='rtl'].rtl\:pl-xs {
  padding-left: 32rem
}

[dir='rtl'] .rtl\:pt-sm,[dir='rtl'].rtl\:pt-sm {
  padding-top: 48rem
}

[dir='rtl'] .rtl\:pr-sm,[dir='rtl'].rtl\:pr-sm {
  padding-right: 48rem
}

[dir='rtl'] .rtl\:pb-sm,[dir='rtl'].rtl\:pb-sm {
  padding-bottom: 48rem
}

[dir='rtl'] .rtl\:pl-sm,[dir='rtl'].rtl\:pl-sm {
  padding-left: 48rem
}

[dir='rtl'] .rtl\:pt-md,[dir='rtl'].rtl\:pt-md {
  padding-top: 64rem
}

[dir='rtl'] .rtl\:pr-md,[dir='rtl'].rtl\:pr-md {
  padding-right: 64rem
}

[dir='rtl'] .rtl\:pb-md,[dir='rtl'].rtl\:pb-md {
  padding-bottom: 64rem
}

[dir='rtl'] .rtl\:pl-md,[dir='rtl'].rtl\:pl-md {
  padding-left: 64rem
}

[dir='rtl'] .rtl\:pt-lg,[dir='rtl'].rtl\:pt-lg {
  padding-top: 80rem
}

[dir='rtl'] .rtl\:pr-lg,[dir='rtl'].rtl\:pr-lg {
  padding-right: 80rem
}

[dir='rtl'] .rtl\:pb-lg,[dir='rtl'].rtl\:pb-lg {
  padding-bottom: 80rem
}

[dir='rtl'] .rtl\:pl-lg,[dir='rtl'].rtl\:pl-lg {
  padding-left: 80rem
}

[dir='rtl'] .rtl\:pt-xl,[dir='rtl'].rtl\:pt-xl {
  padding-top: 96rem
}

[dir='rtl'] .rtl\:pr-xl,[dir='rtl'].rtl\:pr-xl {
  padding-right: 96rem
}

[dir='rtl'] .rtl\:pb-xl,[dir='rtl'].rtl\:pb-xl {
  padding-bottom: 96rem
}

[dir='rtl'] .rtl\:pl-xl,[dir='rtl'].rtl\:pl-xl {
  padding-left: 96rem
}

[dir='rtl'] .rtl\:pt-2xl,[dir='rtl'].rtl\:pt-2xl {
  padding-top: 112rem
}

[dir='rtl'] .rtl\:pr-2xl,[dir='rtl'].rtl\:pr-2xl {
  padding-right: 112rem
}

[dir='rtl'] .rtl\:pb-2xl,[dir='rtl'].rtl\:pb-2xl {
  padding-bottom: 112rem
}

[dir='rtl'] .rtl\:pl-2xl,[dir='rtl'].rtl\:pl-2xl {
  padding-left: 112rem
}

[dir='rtl'] .rtl\:pt-3xl,[dir='rtl'].rtl\:pt-3xl {
  padding-top: 128rem
}

[dir='rtl'] .rtl\:pr-3xl,[dir='rtl'].rtl\:pr-3xl {
  padding-right: 128rem
}

[dir='rtl'] .rtl\:pb-3xl,[dir='rtl'].rtl\:pb-3xl {
  padding-bottom: 128rem
}

[dir='rtl'] .rtl\:pl-3xl,[dir='rtl'].rtl\:pl-3xl {
  padding-left: 128rem
}

[dir='rtl'] .rtl\:pt-4xl,[dir='rtl'].rtl\:pt-4xl {
  padding-top: 144rem
}

[dir='rtl'] .rtl\:pr-4xl,[dir='rtl'].rtl\:pr-4xl {
  padding-right: 144rem
}

[dir='rtl'] .rtl\:pb-4xl,[dir='rtl'].rtl\:pb-4xl {
  padding-bottom: 144rem
}

[dir='rtl'] .rtl\:pl-4xl,[dir='rtl'].rtl\:pl-4xl {
  padding-left: 144rem
}

[dir='rtl'] .rtl\:pt-5xl,[dir='rtl'].rtl\:pt-5xl {
  padding-top: 160rem
}

[dir='rtl'] .rtl\:pr-5xl,[dir='rtl'].rtl\:pr-5xl {
  padding-right: 160rem
}

[dir='rtl'] .rtl\:pb-5xl,[dir='rtl'].rtl\:pb-5xl {
  padding-bottom: 160rem
}

[dir='rtl'] .rtl\:pl-5xl,[dir='rtl'].rtl\:pl-5xl {
  padding-left: 160rem
}

[dir='rtl'] .rtl\:pt-px,[dir='rtl'].rtl\:pt-px {
  padding-top: 1px
}

[dir='rtl'] .rtl\:pr-px,[dir='rtl'].rtl\:pr-px {
  padding-right: 1px
}

[dir='rtl'] .rtl\:pb-px,[dir='rtl'].rtl\:pb-px {
  padding-bottom: 1px
}

[dir='rtl'] .rtl\:pl-px,[dir='rtl'].rtl\:pl-px {
  padding-left: 1px
}

[dir='rtl'] .rtl\:pt-0\.5,[dir='rtl'].rtl\:pt-0\.5 {
  padding-top: 0.05rem
}

[dir='rtl'] .rtl\:pr-0\.5,[dir='rtl'].rtl\:pr-0\.5 {
  padding-right: 0.05rem
}

[dir='rtl'] .rtl\:pb-0\.5,[dir='rtl'].rtl\:pb-0\.5 {
  padding-bottom: 0.05rem
}

[dir='rtl'] .rtl\:pl-0\.5,[dir='rtl'].rtl\:pl-0\.5 {
  padding-left: 0.05rem
}

[dir='rtl'] .rtl\:pt-1\.5,[dir='rtl'].rtl\:pt-1\.5 {
  padding-top: 0.15rem
}

[dir='rtl'] .rtl\:pr-1\.5,[dir='rtl'].rtl\:pr-1\.5 {
  padding-right: 0.15rem
}

[dir='rtl'] .rtl\:pb-1\.5,[dir='rtl'].rtl\:pb-1\.5 {
  padding-bottom: 0.15rem
}

[dir='rtl'] .rtl\:pl-1\.5,[dir='rtl'].rtl\:pl-1\.5 {
  padding-left: 0.15rem
}

[dir='rtl'] .rtl\:pt-2\.5,[dir='rtl'].rtl\:pt-2\.5 {
  padding-top: 0.25rem
}

[dir='rtl'] .rtl\:pr-2\.5,[dir='rtl'].rtl\:pr-2\.5 {
  padding-right: 0.25rem
}

[dir='rtl'] .rtl\:pb-2\.5,[dir='rtl'].rtl\:pb-2\.5 {
  padding-bottom: 0.25rem
}

[dir='rtl'] .rtl\:pl-2\.5,[dir='rtl'].rtl\:pl-2\.5 {
  padding-left: 0.25rem
}

[dir='rtl'] .rtl\:pt-3\.5,[dir='rtl'].rtl\:pt-3\.5 {
  padding-top: 0.35rem
}

[dir='rtl'] .rtl\:pr-3\.5,[dir='rtl'].rtl\:pr-3\.5 {
  padding-right: 0.35rem
}

[dir='rtl'] .rtl\:pb-3\.5,[dir='rtl'].rtl\:pb-3\.5 {
  padding-bottom: 0.35rem
}

[dir='rtl'] .rtl\:pl-3\.5,[dir='rtl'].rtl\:pl-3\.5 {
  padding-left: 0.35rem
}

.placeholder-transparent::placeholder {
  color: transparent
}

.placeholder-current::placeholder {
  color: currentColor
}

.placeholder-black::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
}

.placeholder-white::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
}

.placeholder-grey-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
}

.placeholder-grey-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
}

.placeholder-grey-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
}

.placeholder-grey-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
}

.placeholder-grey-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
}

.placeholder-grey-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
}

.placeholder-grey-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
}

.placeholder-grey-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
}

.placeholder-grey-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
}

.placeholder-grey-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
}

.placeholder-grey::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
}

.placeholder-grey-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
}

.placeholder-grey-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
}

.placeholder-grey-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
}

.placeholder-grey-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
}

.placeholder-gray-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
}

.placeholder-gray-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
}

.placeholder-gray-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
}

.placeholder-gray-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
}

.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
}

.placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
}

.placeholder-gray-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
}

.placeholder-gray-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
}

.placeholder-gray-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
}

.placeholder-gray-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
}

.placeholder-gray::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
}

.placeholder-gray-hover::placeholder {
  color: rgba(0, 0, 0, 0.04)
}

.placeholder-gray-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
}

.placeholder-gray-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
}

.placeholder-gray-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
}

.placeholder-gray-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
}

.placeholder-red-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
}

.placeholder-red-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
}

.placeholder-red-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
}

.placeholder-red-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
}

.placeholder-red-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
}

.placeholder-red-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
}

.placeholder-red-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
}

.placeholder-red-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
}

.placeholder-red-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
}

.placeholder-red-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
}

.placeholder-red::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
}

.placeholder-red-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
}

.placeholder-red-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
}

.placeholder-red-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
}

.placeholder-red-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
}

.placeholder-orange-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
}

.placeholder-orange-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
}

.placeholder-orange-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
}

.placeholder-orange-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
}

.placeholder-orange-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
}

.placeholder-orange-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
}

.placeholder-orange-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
}

.placeholder-orange-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
}

.placeholder-orange-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
}

.placeholder-orange-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
}

.placeholder-orange::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
}

.placeholder-orange-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
}

.placeholder-orange-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
}

.placeholder-orange-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
}

.placeholder-orange-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
}

.placeholder-deep-orange-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
}

.placeholder-deep-orange-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
}

.placeholder-deep-orange-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
}

.placeholder-deep-orange-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
}

.placeholder-deep-orange-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
}

.placeholder-deep-orange-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
}

.placeholder-deep-orange-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
}

.placeholder-deep-orange-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
}

.placeholder-deep-orange-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
}

.placeholder-deep-orange-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
}

.placeholder-deep-orange::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
}

.placeholder-deep-orange-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
}

.placeholder-deep-orange-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
}

.placeholder-deep-orange-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
}

.placeholder-deep-orange-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
}

.placeholder-yellow-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
}

.placeholder-yellow-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
}

.placeholder-yellow-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
}

.placeholder-yellow-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
}

.placeholder-yellow-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
}

.placeholder-yellow-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
}

.placeholder-yellow-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
}

.placeholder-yellow-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
}

.placeholder-yellow-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
}

.placeholder-yellow-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
}

.placeholder-yellow::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
}

.placeholder-yellow-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
}

.placeholder-yellow-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
}

.placeholder-yellow-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
}

.placeholder-yellow-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
}

.placeholder-green-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
}

.placeholder-green-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
}

.placeholder-green-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
}

.placeholder-green-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
}

.placeholder-green-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
}

.placeholder-green-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
}

.placeholder-green-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
}

.placeholder-green-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
}

.placeholder-green-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
}

.placeholder-green-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
}

.placeholder-green::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
}

.placeholder-green-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
}

.placeholder-green-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
}

.placeholder-green-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
}

.placeholder-green-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
}

.placeholder-light-green-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
}

.placeholder-light-green-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
}

.placeholder-light-green-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
}

.placeholder-light-green-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
}

.placeholder-light-green-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
}

.placeholder-light-green-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
}

.placeholder-light-green-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
}

.placeholder-light-green-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
}

.placeholder-light-green-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
}

.placeholder-light-green-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
}

.placeholder-light-green::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
}

.placeholder-light-green-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
}

.placeholder-light-green-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
}

.placeholder-light-green-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
}

.placeholder-light-green-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
}

.placeholder-teal-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
}

.placeholder-teal-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
}

.placeholder-teal-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
}

.placeholder-teal-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
}

.placeholder-teal-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
}

.placeholder-teal-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
}

.placeholder-teal-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
}

.placeholder-teal-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
}

.placeholder-teal-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
}

.placeholder-teal-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
}

.placeholder-teal::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
}

.placeholder-teal-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
}

.placeholder-teal-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
}

.placeholder-teal-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
}

.placeholder-teal-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
}

.placeholder-blue-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
}

.placeholder-blue-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
}

.placeholder-blue-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
}

.placeholder-blue-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
}

.placeholder-blue-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
}

.placeholder-blue-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
}

.placeholder-blue-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
}

.placeholder-blue-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
}

.placeholder-blue-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
}

.placeholder-blue-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
}

.placeholder-blue::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
}

.placeholder-blue-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
}

.placeholder-blue-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
}

.placeholder-blue-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
}

.placeholder-blue-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
}

.placeholder-light-blue-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
}

.placeholder-light-blue-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
}

.placeholder-light-blue-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
}

.placeholder-light-blue-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
}

.placeholder-light-blue-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
}

.placeholder-light-blue-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
}

.placeholder-light-blue-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
}

.placeholder-light-blue-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
}

.placeholder-light-blue-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
}

.placeholder-light-blue-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
}

.placeholder-light-blue::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
}

.placeholder-light-blue-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
}

.placeholder-light-blue-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
}

.placeholder-light-blue-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
}

.placeholder-light-blue-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
}

.placeholder-indigo-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
}

.placeholder-indigo-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
}

.placeholder-indigo-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
}

.placeholder-indigo-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
}

.placeholder-indigo-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
}

.placeholder-indigo-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
}

.placeholder-indigo-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
}

.placeholder-indigo-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
}

.placeholder-indigo-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
}

.placeholder-indigo-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
}

.placeholder-indigo::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
}

.placeholder-indigo-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
}

.placeholder-indigo-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
}

.placeholder-indigo-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
}

.placeholder-indigo-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
}

.placeholder-purple-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
}

.placeholder-purple-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
}

.placeholder-purple-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
}

.placeholder-purple-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
}

.placeholder-purple-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
}

.placeholder-purple-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
}

.placeholder-purple-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
}

.placeholder-purple-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
}

.placeholder-purple-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
}

.placeholder-purple-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
}

.placeholder-purple::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
}

.placeholder-purple-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
}

.placeholder-purple-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
}

.placeholder-purple-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
}

.placeholder-purple-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
}

.placeholder-deep-purple-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
}

.placeholder-deep-purple-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
}

.placeholder-deep-purple-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
}

.placeholder-deep-purple-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
}

.placeholder-deep-purple-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
}

.placeholder-deep-purple-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
}

.placeholder-deep-purple-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
}

.placeholder-deep-purple-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
}

.placeholder-deep-purple-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
}

.placeholder-deep-purple-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
}

.placeholder-deep-purple::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
}

.placeholder-deep-purple-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
}

.placeholder-deep-purple-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
}

.placeholder-deep-purple-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
}

.placeholder-deep-purple-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
}

.placeholder-pink-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
}

.placeholder-pink-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
}

.placeholder-pink-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
}

.placeholder-pink-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
}

.placeholder-pink-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
}

.placeholder-pink-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
}

.placeholder-pink-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
}

.placeholder-pink-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
}

.placeholder-pink-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
}

.placeholder-pink-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
}

.placeholder-pink::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
}

.placeholder-pink-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
}

.placeholder-pink-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
}

.placeholder-pink-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
}

.placeholder-pink-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
}

.placeholder-lime-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
}

.placeholder-lime-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
}

.placeholder-lime-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
}

.placeholder-lime-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
}

.placeholder-lime-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
}

.placeholder-lime-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
}

.placeholder-lime-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
}

.placeholder-lime-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
}

.placeholder-lime-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
}

.placeholder-lime-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
}

.placeholder-lime::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
}

.placeholder-lime-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
}

.placeholder-lime-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
}

.placeholder-lime-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
}

.placeholder-lime-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
}

.placeholder-amber-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
}

.placeholder-amber-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
}

.placeholder-amber-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
}

.placeholder-amber-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
}

.placeholder-amber-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
}

.placeholder-amber-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
}

.placeholder-amber-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
}

.placeholder-amber-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
}

.placeholder-amber-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
}

.placeholder-amber-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
}

.placeholder-amber::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
}

.placeholder-amber-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
}

.placeholder-amber-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
}

.placeholder-amber-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
}

.placeholder-amber-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
}

.placeholder-brown-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
}

.placeholder-brown-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
}

.placeholder-brown-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
}

.placeholder-brown-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
}

.placeholder-brown-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
}

.placeholder-brown-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
}

.placeholder-brown-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
}

.placeholder-brown-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
}

.placeholder-brown-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
}

.placeholder-brown-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
}

.placeholder-brown::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
}

.placeholder-brown-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
}

.placeholder-brown-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
}

.placeholder-brown-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
}

.placeholder-brown-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
}

.placeholder-blue-gray-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
}

.placeholder-blue-gray-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
}

.placeholder-blue-gray-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
}

.placeholder-blue-gray-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
}

.placeholder-blue-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
}

.placeholder-blue-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
}

.placeholder-blue-gray-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
}

.placeholder-blue-gray-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
}

.placeholder-blue-gray-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
}

.placeholder-blue-gray-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
}

.placeholder-blue-gray::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
}

.placeholder-blue-gray-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
}

.placeholder-blue-gray-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
}

.placeholder-blue-gray-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
}

.placeholder-blue-gray-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
}

.placeholder-cyan-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
}

.placeholder-cyan-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
}

.placeholder-cyan-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
}

.placeholder-cyan-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
}

.placeholder-cyan-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
}

.placeholder-cyan-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
}

.placeholder-cyan-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
}

.placeholder-cyan-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
}

.placeholder-cyan-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
}

.placeholder-cyan-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
}

.placeholder-cyan::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
}

.placeholder-cyan-A100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
}

.placeholder-cyan-A200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
}

.placeholder-cyan-A400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
}

.placeholder-cyan-A700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
}

.focus\:placeholder-transparent:focus::placeholder {
  color: transparent
}

.focus\:placeholder-current:focus::placeholder {
  color: currentColor
}

.focus\:placeholder-black:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
}

.focus\:placeholder-white:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-grey-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
}

.focus\:placeholder-grey-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
}

.focus\:placeholder-grey-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
}

.focus\:placeholder-grey-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
}

.focus\:placeholder-grey-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
}

.focus\:placeholder-grey-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
}

.focus\:placeholder-grey-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
}

.focus\:placeholder-grey-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
}

.focus\:placeholder-grey-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
}

.focus\:placeholder-grey-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
}

.focus\:placeholder-grey:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
}

.focus\:placeholder-grey-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
}

.focus\:placeholder-grey-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
}

.focus\:placeholder-grey-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
}

.focus\:placeholder-grey-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
}

.focus\:placeholder-gray-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
}

.focus\:placeholder-gray-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
}

.focus\:placeholder-gray-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
}

.focus\:placeholder-gray-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
}

.focus\:placeholder-gray-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
}

.focus\:placeholder-gray-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
}

.focus\:placeholder-gray-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
}

.focus\:placeholder-gray-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
}

.focus\:placeholder-gray-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
}

.focus\:placeholder-gray-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
}

.focus\:placeholder-gray:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
}

.focus\:placeholder-gray-hover:focus::placeholder {
  color: rgba(0, 0, 0, 0.04)
}

.focus\:placeholder-gray-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
}

.focus\:placeholder-gray-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
}

.focus\:placeholder-gray-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
}

.focus\:placeholder-gray-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
}

.focus\:placeholder-red-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
}

.focus\:placeholder-red-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
}

.focus\:placeholder-red-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
}

.focus\:placeholder-red-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
}

.focus\:placeholder-red-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
}

.focus\:placeholder-red-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
}

.focus\:placeholder-red-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
}

.focus\:placeholder-red-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
}

.focus\:placeholder-red-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
}

.focus\:placeholder-red-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
}

.focus\:placeholder-red:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
}

.focus\:placeholder-red-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
}

.focus\:placeholder-red-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
}

.focus\:placeholder-red-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
}

.focus\:placeholder-red-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-orange-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
}

.focus\:placeholder-orange-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
}

.focus\:placeholder-orange-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
}

.focus\:placeholder-orange-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
}

.focus\:placeholder-orange-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
}

.focus\:placeholder-orange-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-orange-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-orange-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-orange-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-orange-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-orange:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-orange-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
}

.focus\:placeholder-orange-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
}

.focus\:placeholder-orange-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-orange-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-orange-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-orange-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-orange-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-orange-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-orange-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-orange-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-orange-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-orange-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-orange-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-orange-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-orange:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-orange-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-orange-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-orange-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-orange-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-yellow-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
}

.focus\:placeholder-yellow-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
}

.focus\:placeholder-yellow-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
}

.focus\:placeholder-yellow-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
}

.focus\:placeholder-yellow-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
}

.focus\:placeholder-yellow-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
}

.focus\:placeholder-yellow-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
}

.focus\:placeholder-yellow-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
}

.focus\:placeholder-yellow-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
}

.focus\:placeholder-yellow-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
}

.focus\:placeholder-yellow:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
}

.focus\:placeholder-yellow-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
}

.focus\:placeholder-yellow-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-yellow-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-yellow-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-green-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
}

.focus\:placeholder-green-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
}

.focus\:placeholder-green-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
}

.focus\:placeholder-green-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
}

.focus\:placeholder-green-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
}

.focus\:placeholder-green-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
}

.focus\:placeholder-green-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
}

.focus\:placeholder-green-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
}

.focus\:placeholder-green-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
}

.focus\:placeholder-green-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
}

.focus\:placeholder-green:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
}

.focus\:placeholder-green-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
}

.focus\:placeholder-green-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
}

.focus\:placeholder-green-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
}

.focus\:placeholder-green-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-green-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-green-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-green-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-green-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-green-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-green-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-green-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-green-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-green-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-green-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-green:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-green-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-green-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-green-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-green-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
}

.focus\:placeholder-teal-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
}

.focus\:placeholder-teal-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
}

.focus\:placeholder-teal-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
}

.focus\:placeholder-teal-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
}

.focus\:placeholder-teal-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
}

.focus\:placeholder-teal-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
}

.focus\:placeholder-teal-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
}

.focus\:placeholder-teal-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
}

.focus\:placeholder-teal-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
}

.focus\:placeholder-teal-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
}

.focus\:placeholder-teal:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
}

.focus\:placeholder-teal-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
}

.focus\:placeholder-teal-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
}

.focus\:placeholder-teal-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
}

.focus\:placeholder-teal-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-blue-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-blue-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-blue-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-blue-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-blue-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-blue-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-blue-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-blue-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-blue-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-blue-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-blue:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-blue-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-blue-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-blue-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-blue-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
}

.focus\:placeholder-indigo-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
}

.focus\:placeholder-indigo-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
}

.focus\:placeholder-indigo-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
}

.focus\:placeholder-indigo-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
}

.focus\:placeholder-indigo-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
}

.focus\:placeholder-indigo-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
}

.focus\:placeholder-indigo-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
}

.focus\:placeholder-indigo-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
}

.focus\:placeholder-indigo-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
}

.focus\:placeholder-indigo-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
}

.focus\:placeholder-indigo:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
}

.focus\:placeholder-indigo-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-indigo-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
}

.focus\:placeholder-indigo-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
}

.focus\:placeholder-indigo-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
}

.focus\:placeholder-purple-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
}

.focus\:placeholder-purple-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
}

.focus\:placeholder-purple-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
}

.focus\:placeholder-purple-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
}

.focus\:placeholder-purple-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
}

.focus\:placeholder-purple-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
}

.focus\:placeholder-purple-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
}

.focus\:placeholder-purple-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
}

.focus\:placeholder-purple-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
}

.focus\:placeholder-purple-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
}

.focus\:placeholder-purple:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
}

.focus\:placeholder-purple-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
}

.focus\:placeholder-purple-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
}

.focus\:placeholder-purple-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
}

.focus\:placeholder-purple-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-purple-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-purple-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-purple-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-purple-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-purple-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-purple-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-purple-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-purple-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-purple-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-purple-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-purple:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-purple-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-purple-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-purple-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-deep-purple-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
}

.focus\:placeholder-pink-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
}

.focus\:placeholder-pink-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
}

.focus\:placeholder-pink-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
}

.focus\:placeholder-pink-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
}

.focus\:placeholder-pink-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
}

.focus\:placeholder-pink-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
}

.focus\:placeholder-pink-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
}

.focus\:placeholder-pink-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
}

.focus\:placeholder-pink-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
}

.focus\:placeholder-pink-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
}

.focus\:placeholder-pink:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
}

.focus\:placeholder-pink-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
}

.focus\:placeholder-pink-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
}

.focus\:placeholder-pink-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
}

.focus\:placeholder-pink-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
}

.focus\:placeholder-lime-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
}

.focus\:placeholder-lime-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
}

.focus\:placeholder-lime-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
}

.focus\:placeholder-lime-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
}

.focus\:placeholder-lime-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
}

.focus\:placeholder-lime-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
}

.focus\:placeholder-lime-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
}

.focus\:placeholder-lime-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
}

.focus\:placeholder-lime-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
}

.focus\:placeholder-lime-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
}

.focus\:placeholder-lime:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
}

.focus\:placeholder-lime-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
}

.focus\:placeholder-lime-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
}

.focus\:placeholder-lime-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-lime-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-amber-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
}

.focus\:placeholder-amber-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
}

.focus\:placeholder-amber-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
}

.focus\:placeholder-amber-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
}

.focus\:placeholder-amber-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
}

.focus\:placeholder-amber-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
}

.focus\:placeholder-amber-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-amber-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-amber-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-amber-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-amber:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
}

.focus\:placeholder-amber-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
}

.focus\:placeholder-amber-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
}

.focus\:placeholder-amber-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-amber-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
}

.focus\:placeholder-brown-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
}

.focus\:placeholder-brown-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
}

.focus\:placeholder-brown-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
}

.focus\:placeholder-brown-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
}

.focus\:placeholder-brown-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
}

.focus\:placeholder-brown-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
}

.focus\:placeholder-brown-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
}

.focus\:placeholder-brown-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
}

.focus\:placeholder-brown-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
}

.focus\:placeholder-brown-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
}

.focus\:placeholder-brown:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
}

.focus\:placeholder-brown-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
}

.focus\:placeholder-brown-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
}

.focus\:placeholder-brown-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
}

.focus\:placeholder-brown-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-gray-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-gray-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-gray-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-gray-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-gray-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-gray-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-gray-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-gray-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-gray-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-gray-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-gray:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-gray-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-gray-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-gray-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
}

.focus\:placeholder-blue-gray-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
}

.focus\:placeholder-cyan-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
}

.focus\:placeholder-cyan-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
}

.focus\:placeholder-cyan-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
}

.focus\:placeholder-cyan-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
}

.focus\:placeholder-cyan-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
}

.focus\:placeholder-cyan-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
}

.focus\:placeholder-cyan-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
}

.focus\:placeholder-cyan-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
}

.focus\:placeholder-cyan-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
}

.focus\:placeholder-cyan-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
}

.focus\:placeholder-cyan:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
}

.focus\:placeholder-cyan-A100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-cyan-A200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-cyan-A400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
}

.focus\:placeholder-cyan-A700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
}

.placeholder-opacity-0::placeholder {
  --tw-placeholder-opacity: 0
}

.placeholder-opacity-5::placeholder {
  --tw-placeholder-opacity: 0.05
}

.placeholder-opacity-10::placeholder {
  --tw-placeholder-opacity: 0.1
}

.placeholder-opacity-20::placeholder {
  --tw-placeholder-opacity: 0.2
}

.placeholder-opacity-25::placeholder {
  --tw-placeholder-opacity: 0.25
}

.placeholder-opacity-30::placeholder {
  --tw-placeholder-opacity: 0.3
}

.placeholder-opacity-40::placeholder {
  --tw-placeholder-opacity: 0.4
}

.placeholder-opacity-50::placeholder {
  --tw-placeholder-opacity: 0.5
}

.placeholder-opacity-60::placeholder {
  --tw-placeholder-opacity: 0.6
}

.placeholder-opacity-70::placeholder {
  --tw-placeholder-opacity: 0.7
}

.placeholder-opacity-75::placeholder {
  --tw-placeholder-opacity: 0.75
}

.placeholder-opacity-80::placeholder {
  --tw-placeholder-opacity: 0.8
}

.placeholder-opacity-90::placeholder {
  --tw-placeholder-opacity: 0.9
}

.placeholder-opacity-95::placeholder {
  --tw-placeholder-opacity: 0.95
}

.placeholder-opacity-100::placeholder {
  --tw-placeholder-opacity: 1
}

.focus\:placeholder-opacity-0:focus::placeholder {
  --tw-placeholder-opacity: 0
}

.focus\:placeholder-opacity-5:focus::placeholder {
  --tw-placeholder-opacity: 0.05
}

.focus\:placeholder-opacity-10:focus::placeholder {
  --tw-placeholder-opacity: 0.1
}

.focus\:placeholder-opacity-20:focus::placeholder {
  --tw-placeholder-opacity: 0.2
}

.focus\:placeholder-opacity-25:focus::placeholder {
  --tw-placeholder-opacity: 0.25
}

.focus\:placeholder-opacity-30:focus::placeholder {
  --tw-placeholder-opacity: 0.3
}

.focus\:placeholder-opacity-40:focus::placeholder {
  --tw-placeholder-opacity: 0.4
}

.focus\:placeholder-opacity-50:focus::placeholder {
  --tw-placeholder-opacity: 0.5
}

.focus\:placeholder-opacity-60:focus::placeholder {
  --tw-placeholder-opacity: 0.6
}

.focus\:placeholder-opacity-70:focus::placeholder {
  --tw-placeholder-opacity: 0.7
}

.focus\:placeholder-opacity-75:focus::placeholder {
  --tw-placeholder-opacity: 0.75
}

.focus\:placeholder-opacity-80:focus::placeholder {
  --tw-placeholder-opacity: 0.8
}

.focus\:placeholder-opacity-90:focus::placeholder {
  --tw-placeholder-opacity: 0.9
}

.focus\:placeholder-opacity-95:focus::placeholder {
  --tw-placeholder-opacity: 0.95
}

.focus\:placeholder-opacity-100:focus::placeholder {
  --tw-placeholder-opacity: 1
}

.pointer-events-none {
  pointer-events: none
}

.pointer-events-auto {
  pointer-events: auto
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.inset-1 {
  top: 0.1rem;
  right: 0.1rem;
  bottom: 0.1rem;
  left: 0.1rem
}

.inset-2 {
  top: 0.2rem;
  right: 0.2rem;
  bottom: 0.2rem;
  left: 0.2rem
}

.inset-3 {
  top: 0.3rem;
  right: 0.3rem;
  bottom: 0.3rem;
  left: 0.3rem
}

.inset-4 {
  top: 0.4rem;
  right: 0.4rem;
  bottom: 0.4rem;
  left: 0.4rem
}

.inset-5 {
  top: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem
}

.inset-6 {
  top: 0.6rem;
  right: 0.6rem;
  bottom: 0.6rem;
  left: 0.6rem
}

.inset-7 {
  top: 0.7rem;
  right: 0.7rem;
  bottom: 0.7rem;
  left: 0.7rem
}

.inset-8 {
  top: 0.8rem;
  right: 0.8rem;
  bottom: 0.8rem;
  left: 0.8rem
}

.inset-9 {
  top: 0.9rem;
  right: 0.9rem;
  bottom: 0.9rem;
  left: 0.9rem
}

.inset-10 {
  top: 1.0rem;
  right: 1.0rem;
  bottom: 1.0rem;
  left: 1.0rem
}

.inset-12 {
  top: 1.2rem;
  right: 1.2rem;
  bottom: 1.2rem;
  left: 1.2rem
}

.inset-14 {
  top: 1.4rem;
  right: 1.4rem;
  bottom: 1.4rem;
  left: 1.4rem
}

.inset-16 {
  top: 1.6rem;
  right: 1.6rem;
  bottom: 1.6rem;
  left: 1.6rem
}

.inset-20 {
  top: 2rem;
  right: 2rem;
  bottom: 2rem;
  left: 2rem
}

.inset-24 {
  top: 2.4rem;
  right: 2.4rem;
  bottom: 2.4rem;
  left: 2.4rem
}

.inset-28 {
  top: 2.8rem;
  right: 2.8rem;
  bottom: 2.8rem;
  left: 2.8rem
}

.inset-32 {
  top: 3.2rem;
  right: 3.2rem;
  bottom: 3.2rem;
  left: 3.2rem
}

.inset-36 {
  top: 3.6rem;
  right: 3.6rem;
  bottom: 3.6rem;
  left: 3.6rem
}

.inset-40 {
  top: 4rem;
  right: 4rem;
  bottom: 4rem;
  left: 4rem
}

.inset-44 {
  top: 4.4rem;
  right: 4.4rem;
  bottom: 4.4rem;
  left: 4.4rem
}

.inset-48 {
  top: 4.8rem;
  right: 4.8rem;
  bottom: 4.8rem;
  left: 4.8rem
}

.inset-52 {
  top: 5.2rem;
  right: 5.2rem;
  bottom: 5.2rem;
  left: 5.2rem
}

.inset-56 {
  top: 5.6rem;
  right: 5.6rem;
  bottom: 5.6rem;
  left: 5.6rem
}

.inset-60 {
  top: 6rem;
  right: 6rem;
  bottom: 6rem;
  left: 6rem
}

.inset-64 {
  top: 6.4rem;
  right: 6.4rem;
  bottom: 6.4rem;
  left: 6.4rem
}

.inset-68 {
  top: 6.8rem;
  right: 6.8rem;
  bottom: 6.8rem;
  left: 6.8rem
}

.inset-72 {
  top: 7.2rem;
  right: 7.2rem;
  bottom: 7.2rem;
  left: 7.2rem
}

.inset-76 {
  top: 7.6rem;
  right: 7.6rem;
  bottom: 7.6rem;
  left: 7.6rem
}

.inset-80 {
  top: 8rem;
  right: 8rem;
  bottom: 8rem;
  left: 8rem
}

.inset-84 {
  top: 8.4rem;
  right: 8.4rem;
  bottom: 8.4rem;
  left: 8.4rem
}

.inset-88 {
  top: 8.8rem;
  right: 8.8rem;
  bottom: 8.8rem;
  left: 8.8rem
}

.inset-92 {
  top: 9.2rem;
  right: 9.2rem;
  bottom: 9.2rem;
  left: 9.2rem
}

.inset-96 {
  top: 9.6rem;
  right: 9.6rem;
  bottom: 9.6rem;
  left: 9.6rem
}

.inset-128 {
  top: 12.8rem;
  right: 12.8rem;
  bottom: 12.8rem;
  left: 12.8rem
}

.inset-136 {
  top: 13.6rem;
  right: 13.6rem;
  bottom: 13.6rem;
  left: 13.6rem
}

.inset-160 {
  top: 16rem;
  right: 16rem;
  bottom: 16rem;
  left: 16rem
}

.inset-192 {
  top: 19.2rem;
  right: 19.2rem;
  bottom: 19.2rem;
  left: 19.2rem
}

.inset-200 {
  top: 20rem;
  right: 20rem;
  bottom: 20rem;
  left: 20rem
}

.inset-208 {
  top: 20.8rem;
  right: 20.8rem;
  bottom: 20.8rem;
  left: 20.8rem
}

.inset-216 {
  top: 21.6rem;
  right: 21.6rem;
  bottom: 21.6rem;
  left: 21.6rem
}

.inset-224 {
  top: 22.4rem;
  right: 22.4rem;
  bottom: 22.4rem;
  left: 22.4rem
}

.inset-256 {
  top: 25.6rem;
  right: 25.6rem;
  bottom: 25.6rem;
  left: 25.6rem
}

.inset-288 {
  top: 28.8rem;
  right: 28.8rem;
  bottom: 28.8rem;
  left: 28.8rem
}

.inset-320 {
  top: 32rem;
  right: 32rem;
  bottom: 32rem;
  left: 32rem
}

.inset-360 {
  top: 36rem;
  right: 36rem;
  bottom: 36rem;
  left: 36rem
}

.inset-384 {
  top: 38.4rem;
  right: 38.4rem;
  bottom: 38.4rem;
  left: 38.4rem
}

.inset-400 {
  top: 40rem;
  right: 40rem;
  bottom: 40rem;
  left: 40rem
}

.inset-512 {
  top: 51.2rem;
  right: 51.2rem;
  bottom: 51.2rem;
  left: 51.2rem
}

.inset-640 {
  top: 64rem;
  right: 64rem;
  bottom: 64rem;
  left: 64rem
}

.inset-auto {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto
}

.inset-xs {
  top: 32rem;
  right: 32rem;
  bottom: 32rem;
  left: 32rem
}

.inset-sm {
  top: 48rem;
  right: 48rem;
  bottom: 48rem;
  left: 48rem
}

.inset-md {
  top: 64rem;
  right: 64rem;
  bottom: 64rem;
  left: 64rem
}

.inset-lg {
  top: 80rem;
  right: 80rem;
  bottom: 80rem;
  left: 80rem
}

.inset-xl {
  top: 96rem;
  right: 96rem;
  bottom: 96rem;
  left: 96rem
}

.inset-2xl {
  top: 112rem;
  right: 112rem;
  bottom: 112rem;
  left: 112rem
}

.inset-3xl {
  top: 128rem;
  right: 128rem;
  bottom: 128rem;
  left: 128rem
}

.inset-4xl {
  top: 144rem;
  right: 144rem;
  bottom: 144rem;
  left: 144rem
}

.inset-5xl {
  top: 160rem;
  right: 160rem;
  bottom: 160rem;
  left: 160rem
}

.inset-px {
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px
}

.inset-0\.5 {
  top: 0.05rem;
  right: 0.05rem;
  bottom: 0.05rem;
  left: 0.05rem
}

.inset-1\.5 {
  top: 0.15rem;
  right: 0.15rem;
  bottom: 0.15rem;
  left: 0.15rem
}

.inset-2\.5 {
  top: 0.25rem;
  right: 0.25rem;
  bottom: 0.25rem;
  left: 0.25rem
}

.inset-3\.5 {
  top: 0.35rem;
  right: 0.35rem;
  bottom: 0.35rem;
  left: 0.35rem
}

.-inset-1 {
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem
}

.-inset-2 {
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem
}

.-inset-3 {
  top: -0.3rem;
  right: -0.3rem;
  bottom: -0.3rem;
  left: -0.3rem
}

.-inset-4 {
  top: -0.4rem;
  right: -0.4rem;
  bottom: -0.4rem;
  left: -0.4rem
}

.-inset-5 {
  top: -0.5rem;
  right: -0.5rem;
  bottom: -0.5rem;
  left: -0.5rem
}

.-inset-6 {
  top: -0.6rem;
  right: -0.6rem;
  bottom: -0.6rem;
  left: -0.6rem
}

.-inset-7 {
  top: -0.7rem;
  right: -0.7rem;
  bottom: -0.7rem;
  left: -0.7rem
}

.-inset-8 {
  top: -0.8rem;
  right: -0.8rem;
  bottom: -0.8rem;
  left: -0.8rem
}

.-inset-9 {
  top: -0.9rem;
  right: -0.9rem;
  bottom: -0.9rem;
  left: -0.9rem
}

.-inset-10 {
  top: -1rem;
  right: -1rem;
  bottom: -1rem;
  left: -1rem
}

.-inset-12 {
  top: -1.2rem;
  right: -1.2rem;
  bottom: -1.2rem;
  left: -1.2rem
}

.-inset-14 {
  top: -1.4rem;
  right: -1.4rem;
  bottom: -1.4rem;
  left: -1.4rem
}

.-inset-16 {
  top: -1.6rem;
  right: -1.6rem;
  bottom: -1.6rem;
  left: -1.6rem
}

.-inset-20 {
  top: -2rem;
  right: -2rem;
  bottom: -2rem;
  left: -2rem
}

.-inset-24 {
  top: -2.4rem;
  right: -2.4rem;
  bottom: -2.4rem;
  left: -2.4rem
}

.-inset-28 {
  top: -2.8rem;
  right: -2.8rem;
  bottom: -2.8rem;
  left: -2.8rem
}

.-inset-32 {
  top: -3.2rem;
  right: -3.2rem;
  bottom: -3.2rem;
  left: -3.2rem
}

.-inset-36 {
  top: -3.6rem;
  right: -3.6rem;
  bottom: -3.6rem;
  left: -3.6rem
}

.-inset-40 {
  top: -4rem;
  right: -4rem;
  bottom: -4rem;
  left: -4rem
}

.-inset-44 {
  top: -4.4rem;
  right: -4.4rem;
  bottom: -4.4rem;
  left: -4.4rem
}

.-inset-48 {
  top: -4.8rem;
  right: -4.8rem;
  bottom: -4.8rem;
  left: -4.8rem
}

.-inset-52 {
  top: -5.2rem;
  right: -5.2rem;
  bottom: -5.2rem;
  left: -5.2rem
}

.-inset-56 {
  top: -5.6rem;
  right: -5.6rem;
  bottom: -5.6rem;
  left: -5.6rem
}

.-inset-60 {
  top: -6rem;
  right: -6rem;
  bottom: -6rem;
  left: -6rem
}

.-inset-64 {
  top: -6.4rem;
  right: -6.4rem;
  bottom: -6.4rem;
  left: -6.4rem
}

.-inset-68 {
  top: -6.8rem;
  right: -6.8rem;
  bottom: -6.8rem;
  left: -6.8rem
}

.-inset-72 {
  top: -7.2rem;
  right: -7.2rem;
  bottom: -7.2rem;
  left: -7.2rem
}

.-inset-76 {
  top: -7.6rem;
  right: -7.6rem;
  bottom: -7.6rem;
  left: -7.6rem
}

.-inset-80 {
  top: -8rem;
  right: -8rem;
  bottom: -8rem;
  left: -8rem
}

.-inset-84 {
  top: -8.4rem;
  right: -8.4rem;
  bottom: -8.4rem;
  left: -8.4rem
}

.-inset-88 {
  top: -8.8rem;
  right: -8.8rem;
  bottom: -8.8rem;
  left: -8.8rem
}

.-inset-92 {
  top: -9.2rem;
  right: -9.2rem;
  bottom: -9.2rem;
  left: -9.2rem
}

.-inset-96 {
  top: -9.6rem;
  right: -9.6rem;
  bottom: -9.6rem;
  left: -9.6rem
}

.-inset-128 {
  top: -12.8rem;
  right: -12.8rem;
  bottom: -12.8rem;
  left: -12.8rem
}

.-inset-136 {
  top: -13.6rem;
  right: -13.6rem;
  bottom: -13.6rem;
  left: -13.6rem
}

.-inset-160 {
  top: -16rem;
  right: -16rem;
  bottom: -16rem;
  left: -16rem
}

.-inset-192 {
  top: -19.2rem;
  right: -19.2rem;
  bottom: -19.2rem;
  left: -19.2rem
}

.-inset-200 {
  top: -20rem;
  right: -20rem;
  bottom: -20rem;
  left: -20rem
}

.-inset-208 {
  top: -20.8rem;
  right: -20.8rem;
  bottom: -20.8rem;
  left: -20.8rem
}

.-inset-216 {
  top: -21.6rem;
  right: -21.6rem;
  bottom: -21.6rem;
  left: -21.6rem
}

.-inset-224 {
  top: -22.4rem;
  right: -22.4rem;
  bottom: -22.4rem;
  left: -22.4rem
}

.-inset-256 {
  top: -25.6rem;
  right: -25.6rem;
  bottom: -25.6rem;
  left: -25.6rem
}

.-inset-288 {
  top: -28.8rem;
  right: -28.8rem;
  bottom: -28.8rem;
  left: -28.8rem
}

.-inset-320 {
  top: -32rem;
  right: -32rem;
  bottom: -32rem;
  left: -32rem
}

.-inset-360 {
  top: -36rem;
  right: -36rem;
  bottom: -36rem;
  left: -36rem
}

.-inset-384 {
  top: -38.4rem;
  right: -38.4rem;
  bottom: -38.4rem;
  left: -38.4rem
}

.-inset-400 {
  top: -40rem;
  right: -40rem;
  bottom: -40rem;
  left: -40rem
}

.-inset-512 {
  top: -51.2rem;
  right: -51.2rem;
  bottom: -51.2rem;
  left: -51.2rem
}

.-inset-640 {
  top: -64rem;
  right: -64rem;
  bottom: -64rem;
  left: -64rem
}

.-inset-xs {
  top: -32rem;
  right: -32rem;
  bottom: -32rem;
  left: -32rem
}

.-inset-sm {
  top: -48rem;
  right: -48rem;
  bottom: -48rem;
  left: -48rem
}

.-inset-md {
  top: -64rem;
  right: -64rem;
  bottom: -64rem;
  left: -64rem
}

.-inset-lg {
  top: -80rem;
  right: -80rem;
  bottom: -80rem;
  left: -80rem
}

.-inset-xl {
  top: -96rem;
  right: -96rem;
  bottom: -96rem;
  left: -96rem
}

.-inset-2xl {
  top: -112rem;
  right: -112rem;
  bottom: -112rem;
  left: -112rem
}

.-inset-3xl {
  top: -128rem;
  right: -128rem;
  bottom: -128rem;
  left: -128rem
}

.-inset-4xl {
  top: -144rem;
  right: -144rem;
  bottom: -144rem;
  left: -144rem
}

.-inset-5xl {
  top: -160rem;
  right: -160rem;
  bottom: -160rem;
  left: -160rem
}

.-inset-px {
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px
}

.-inset-0\.5 {
  top: -0.05rem;
  right: -0.05rem;
  bottom: -0.05rem;
  left: -0.05rem
}

.-inset-1\.5 {
  top: -0.15rem;
  right: -0.15rem;
  bottom: -0.15rem;
  left: -0.15rem
}

.-inset-2\.5 {
  top: -0.25rem;
  right: -0.25rem;
  bottom: -0.25rem;
  left: -0.25rem
}

.-inset-3\.5 {
  top: -0.35rem;
  right: -0.35rem;
  bottom: -0.35rem;
  left: -0.35rem
}

.inset-1\/2 {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%
}

.inset-1\/3 {
  top: 33.333333%;
  right: 33.333333%;
  bottom: 33.333333%;
  left: 33.333333%
}

.inset-2\/3 {
  top: 66.666667%;
  right: 66.666667%;
  bottom: 66.666667%;
  left: 66.666667%
}

.inset-1\/4 {
  top: 25%;
  right: 25%;
  bottom: 25%;
  left: 25%
}

.inset-2\/4 {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%
}

.inset-3\/4 {
  top: 75%;
  right: 75%;
  bottom: 75%;
  left: 75%
}

.inset-full {
  top: 100%;
  right: 100%;
  bottom: 100%;
  left: 100%
}

.-inset-1\/2 {
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%
}

.-inset-1\/3 {
  top: -33.333333%;
  right: -33.333333%;
  bottom: -33.333333%;
  left: -33.333333%
}

.-inset-2\/3 {
  top: -66.666667%;
  right: -66.666667%;
  bottom: -66.666667%;
  left: -66.666667%
}

.-inset-1\/4 {
  top: -25%;
  right: -25%;
  bottom: -25%;
  left: -25%
}

.-inset-2\/4 {
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%
}

.-inset-3\/4 {
  top: -75%;
  right: -75%;
  bottom: -75%;
  left: -75%
}

.-inset-full {
  top: -100%;
  right: -100%;
  bottom: -100%;
  left: -100%
}

.inset-y-0 {
  top: 0;
  bottom: 0
}

.inset-x-0 {
  right: 0;
  left: 0
}

.inset-y-1 {
  top: 0.1rem;
  bottom: 0.1rem
}

.inset-x-1 {
  right: 0.1rem;
  left: 0.1rem
}

.inset-y-2 {
  top: 0.2rem;
  bottom: 0.2rem
}

.inset-x-2 {
  right: 0.2rem;
  left: 0.2rem
}

.inset-y-3 {
  top: 0.3rem;
  bottom: 0.3rem
}

.inset-x-3 {
  right: 0.3rem;
  left: 0.3rem
}

.inset-y-4 {
  top: 0.4rem;
  bottom: 0.4rem
}

.inset-x-4 {
  right: 0.4rem;
  left: 0.4rem
}

.inset-y-5 {
  top: 0.5rem;
  bottom: 0.5rem
}

.inset-x-5 {
  right: 0.5rem;
  left: 0.5rem
}

.inset-y-6 {
  top: 0.6rem;
  bottom: 0.6rem
}

.inset-x-6 {
  right: 0.6rem;
  left: 0.6rem
}

.inset-y-7 {
  top: 0.7rem;
  bottom: 0.7rem
}

.inset-x-7 {
  right: 0.7rem;
  left: 0.7rem
}

.inset-y-8 {
  top: 0.8rem;
  bottom: 0.8rem
}

.inset-x-8 {
  right: 0.8rem;
  left: 0.8rem
}

.inset-y-9 {
  top: 0.9rem;
  bottom: 0.9rem
}

.inset-x-9 {
  right: 0.9rem;
  left: 0.9rem
}

.inset-y-10 {
  top: 1.0rem;
  bottom: 1.0rem
}

.inset-x-10 {
  right: 1.0rem;
  left: 1.0rem
}

.inset-y-12 {
  top: 1.2rem;
  bottom: 1.2rem
}

.inset-x-12 {
  right: 1.2rem;
  left: 1.2rem
}

.inset-y-14 {
  top: 1.4rem;
  bottom: 1.4rem
}

.inset-x-14 {
  right: 1.4rem;
  left: 1.4rem
}

.inset-y-16 {
  top: 1.6rem;
  bottom: 1.6rem
}

.inset-x-16 {
  right: 1.6rem;
  left: 1.6rem
}

.inset-y-20 {
  top: 2rem;
  bottom: 2rem
}

.inset-x-20 {
  right: 2rem;
  left: 2rem
}

.inset-y-24 {
  top: 2.4rem;
  bottom: 2.4rem
}

.inset-x-24 {
  right: 2.4rem;
  left: 2.4rem
}

.inset-y-28 {
  top: 2.8rem;
  bottom: 2.8rem
}

.inset-x-28 {
  right: 2.8rem;
  left: 2.8rem
}

.inset-y-32 {
  top: 3.2rem;
  bottom: 3.2rem
}

.inset-x-32 {
  right: 3.2rem;
  left: 3.2rem
}

.inset-y-36 {
  top: 3.6rem;
  bottom: 3.6rem
}

.inset-x-36 {
  right: 3.6rem;
  left: 3.6rem
}

.inset-y-40 {
  top: 4rem;
  bottom: 4rem
}

.inset-x-40 {
  right: 4rem;
  left: 4rem
}

.inset-y-44 {
  top: 4.4rem;
  bottom: 4.4rem
}

.inset-x-44 {
  right: 4.4rem;
  left: 4.4rem
}

.inset-y-48 {
  top: 4.8rem;
  bottom: 4.8rem
}

.inset-x-48 {
  right: 4.8rem;
  left: 4.8rem
}

.inset-y-52 {
  top: 5.2rem;
  bottom: 5.2rem
}

.inset-x-52 {
  right: 5.2rem;
  left: 5.2rem
}

.inset-y-56 {
  top: 5.6rem;
  bottom: 5.6rem
}

.inset-x-56 {
  right: 5.6rem;
  left: 5.6rem
}

.inset-y-60 {
  top: 6rem;
  bottom: 6rem
}

.inset-x-60 {
  right: 6rem;
  left: 6rem
}

.inset-y-64 {
  top: 6.4rem;
  bottom: 6.4rem
}

.inset-x-64 {
  right: 6.4rem;
  left: 6.4rem
}

.inset-y-68 {
  top: 6.8rem;
  bottom: 6.8rem
}

.inset-x-68 {
  right: 6.8rem;
  left: 6.8rem
}

.inset-y-72 {
  top: 7.2rem;
  bottom: 7.2rem
}

.inset-x-72 {
  right: 7.2rem;
  left: 7.2rem
}

.inset-y-76 {
  top: 7.6rem;
  bottom: 7.6rem
}

.inset-x-76 {
  right: 7.6rem;
  left: 7.6rem
}

.inset-y-80 {
  top: 8rem;
  bottom: 8rem
}

.inset-x-80 {
  right: 8rem;
  left: 8rem
}

.inset-y-84 {
  top: 8.4rem;
  bottom: 8.4rem
}

.inset-x-84 {
  right: 8.4rem;
  left: 8.4rem
}

.inset-y-88 {
  top: 8.8rem;
  bottom: 8.8rem
}

.inset-x-88 {
  right: 8.8rem;
  left: 8.8rem
}

.inset-y-92 {
  top: 9.2rem;
  bottom: 9.2rem
}

.inset-x-92 {
  right: 9.2rem;
  left: 9.2rem
}

.inset-y-96 {
  top: 9.6rem;
  bottom: 9.6rem
}

.inset-x-96 {
  right: 9.6rem;
  left: 9.6rem
}

.inset-y-128 {
  top: 12.8rem;
  bottom: 12.8rem
}

.inset-x-128 {
  right: 12.8rem;
  left: 12.8rem
}

.inset-y-136 {
  top: 13.6rem;
  bottom: 13.6rem
}

.inset-x-136 {
  right: 13.6rem;
  left: 13.6rem
}

.inset-y-160 {
  top: 16rem;
  bottom: 16rem
}

.inset-x-160 {
  right: 16rem;
  left: 16rem
}

.inset-y-192 {
  top: 19.2rem;
  bottom: 19.2rem
}

.inset-x-192 {
  right: 19.2rem;
  left: 19.2rem
}

.inset-y-200 {
  top: 20rem;
  bottom: 20rem
}

.inset-x-200 {
  right: 20rem;
  left: 20rem
}

.inset-y-208 {
  top: 20.8rem;
  bottom: 20.8rem
}

.inset-x-208 {
  right: 20.8rem;
  left: 20.8rem
}

.inset-y-216 {
  top: 21.6rem;
  bottom: 21.6rem
}

.inset-x-216 {
  right: 21.6rem;
  left: 21.6rem
}

.inset-y-224 {
  top: 22.4rem;
  bottom: 22.4rem
}

.inset-x-224 {
  right: 22.4rem;
  left: 22.4rem
}

.inset-y-256 {
  top: 25.6rem;
  bottom: 25.6rem
}

.inset-x-256 {
  right: 25.6rem;
  left: 25.6rem
}

.inset-y-288 {
  top: 28.8rem;
  bottom: 28.8rem
}

.inset-x-288 {
  right: 28.8rem;
  left: 28.8rem
}

.inset-y-320 {
  top: 32rem;
  bottom: 32rem
}

.inset-x-320 {
  right: 32rem;
  left: 32rem
}

.inset-y-360 {
  top: 36rem;
  bottom: 36rem
}

.inset-x-360 {
  right: 36rem;
  left: 36rem
}

.inset-y-384 {
  top: 38.4rem;
  bottom: 38.4rem
}

.inset-x-384 {
  right: 38.4rem;
  left: 38.4rem
}

.inset-y-400 {
  top: 40rem;
  bottom: 40rem
}

.inset-x-400 {
  right: 40rem;
  left: 40rem
}

.inset-y-512 {
  top: 51.2rem;
  bottom: 51.2rem
}

.inset-x-512 {
  right: 51.2rem;
  left: 51.2rem
}

.inset-y-640 {
  top: 64rem;
  bottom: 64rem
}

.inset-x-640 {
  right: 64rem;
  left: 64rem
}

.inset-y-auto {
  top: auto;
  bottom: auto
}

.inset-x-auto {
  right: auto;
  left: auto
}

.inset-y-xs {
  top: 32rem;
  bottom: 32rem
}

.inset-x-xs {
  right: 32rem;
  left: 32rem
}

.inset-y-sm {
  top: 48rem;
  bottom: 48rem
}

.inset-x-sm {
  right: 48rem;
  left: 48rem
}

.inset-y-md {
  top: 64rem;
  bottom: 64rem
}

.inset-x-md {
  right: 64rem;
  left: 64rem
}

.inset-y-lg {
  top: 80rem;
  bottom: 80rem
}

.inset-x-lg {
  right: 80rem;
  left: 80rem
}

.inset-y-xl {
  top: 96rem;
  bottom: 96rem
}

.inset-x-xl {
  right: 96rem;
  left: 96rem
}

.inset-y-2xl {
  top: 112rem;
  bottom: 112rem
}

.inset-x-2xl {
  right: 112rem;
  left: 112rem
}

.inset-y-3xl {
  top: 128rem;
  bottom: 128rem
}

.inset-x-3xl {
  right: 128rem;
  left: 128rem
}

.inset-y-4xl {
  top: 144rem;
  bottom: 144rem
}

.inset-x-4xl {
  right: 144rem;
  left: 144rem
}

.inset-y-5xl {
  top: 160rem;
  bottom: 160rem
}

.inset-x-5xl {
  right: 160rem;
  left: 160rem
}

.inset-y-px {
  top: 1px;
  bottom: 1px
}

.inset-x-px {
  right: 1px;
  left: 1px
}

.inset-y-0\.5 {
  top: 0.05rem;
  bottom: 0.05rem
}

.inset-x-0\.5 {
  right: 0.05rem;
  left: 0.05rem
}

.inset-y-1\.5 {
  top: 0.15rem;
  bottom: 0.15rem
}

.inset-x-1\.5 {
  right: 0.15rem;
  left: 0.15rem
}

.inset-y-2\.5 {
  top: 0.25rem;
  bottom: 0.25rem
}

.inset-x-2\.5 {
  right: 0.25rem;
  left: 0.25rem
}

.inset-y-3\.5 {
  top: 0.35rem;
  bottom: 0.35rem
}

.inset-x-3\.5 {
  right: 0.35rem;
  left: 0.35rem
}

.-inset-y-1 {
  top: -0.1rem;
  bottom: -0.1rem
}

.-inset-x-1 {
  right: -0.1rem;
  left: -0.1rem
}

.-inset-y-2 {
  top: -0.2rem;
  bottom: -0.2rem
}

.-inset-x-2 {
  right: -0.2rem;
  left: -0.2rem
}

.-inset-y-3 {
  top: -0.3rem;
  bottom: -0.3rem
}

.-inset-x-3 {
  right: -0.3rem;
  left: -0.3rem
}

.-inset-y-4 {
  top: -0.4rem;
  bottom: -0.4rem
}

.-inset-x-4 {
  right: -0.4rem;
  left: -0.4rem
}

.-inset-y-5 {
  top: -0.5rem;
  bottom: -0.5rem
}

.-inset-x-5 {
  right: -0.5rem;
  left: -0.5rem
}

.-inset-y-6 {
  top: -0.6rem;
  bottom: -0.6rem
}

.-inset-x-6 {
  right: -0.6rem;
  left: -0.6rem
}

.-inset-y-7 {
  top: -0.7rem;
  bottom: -0.7rem
}

.-inset-x-7 {
  right: -0.7rem;
  left: -0.7rem
}

.-inset-y-8 {
  top: -0.8rem;
  bottom: -0.8rem
}

.-inset-x-8 {
  right: -0.8rem;
  left: -0.8rem
}

.-inset-y-9 {
  top: -0.9rem;
  bottom: -0.9rem
}

.-inset-x-9 {
  right: -0.9rem;
  left: -0.9rem
}

.-inset-y-10 {
  top: -1rem;
  bottom: -1rem
}

.-inset-x-10 {
  right: -1rem;
  left: -1rem
}

.-inset-y-12 {
  top: -1.2rem;
  bottom: -1.2rem
}

.-inset-x-12 {
  right: -1.2rem;
  left: -1.2rem
}

.-inset-y-14 {
  top: -1.4rem;
  bottom: -1.4rem
}

.-inset-x-14 {
  right: -1.4rem;
  left: -1.4rem
}

.-inset-y-16 {
  top: -1.6rem;
  bottom: -1.6rem
}

.-inset-x-16 {
  right: -1.6rem;
  left: -1.6rem
}

.-inset-y-20 {
  top: -2rem;
  bottom: -2rem
}

.-inset-x-20 {
  right: -2rem;
  left: -2rem
}

.-inset-y-24 {
  top: -2.4rem;
  bottom: -2.4rem
}

.-inset-x-24 {
  right: -2.4rem;
  left: -2.4rem
}

.-inset-y-28 {
  top: -2.8rem;
  bottom: -2.8rem
}

.-inset-x-28 {
  right: -2.8rem;
  left: -2.8rem
}

.-inset-y-32 {
  top: -3.2rem;
  bottom: -3.2rem
}

.-inset-x-32 {
  right: -3.2rem;
  left: -3.2rem
}

.-inset-y-36 {
  top: -3.6rem;
  bottom: -3.6rem
}

.-inset-x-36 {
  right: -3.6rem;
  left: -3.6rem
}

.-inset-y-40 {
  top: -4rem;
  bottom: -4rem
}

.-inset-x-40 {
  right: -4rem;
  left: -4rem
}

.-inset-y-44 {
  top: -4.4rem;
  bottom: -4.4rem
}

.-inset-x-44 {
  right: -4.4rem;
  left: -4.4rem
}

.-inset-y-48 {
  top: -4.8rem;
  bottom: -4.8rem
}

.-inset-x-48 {
  right: -4.8rem;
  left: -4.8rem
}

.-inset-y-52 {
  top: -5.2rem;
  bottom: -5.2rem
}

.-inset-x-52 {
  right: -5.2rem;
  left: -5.2rem
}

.-inset-y-56 {
  top: -5.6rem;
  bottom: -5.6rem
}

.-inset-x-56 {
  right: -5.6rem;
  left: -5.6rem
}

.-inset-y-60 {
  top: -6rem;
  bottom: -6rem
}

.-inset-x-60 {
  right: -6rem;
  left: -6rem
}

.-inset-y-64 {
  top: -6.4rem;
  bottom: -6.4rem
}

.-inset-x-64 {
  right: -6.4rem;
  left: -6.4rem
}

.-inset-y-68 {
  top: -6.8rem;
  bottom: -6.8rem
}

.-inset-x-68 {
  right: -6.8rem;
  left: -6.8rem
}

.-inset-y-72 {
  top: -7.2rem;
  bottom: -7.2rem
}

.-inset-x-72 {
  right: -7.2rem;
  left: -7.2rem
}

.-inset-y-76 {
  top: -7.6rem;
  bottom: -7.6rem
}

.-inset-x-76 {
  right: -7.6rem;
  left: -7.6rem
}

.-inset-y-80 {
  top: -8rem;
  bottom: -8rem
}

.-inset-x-80 {
  right: -8rem;
  left: -8rem
}

.-inset-y-84 {
  top: -8.4rem;
  bottom: -8.4rem
}

.-inset-x-84 {
  right: -8.4rem;
  left: -8.4rem
}

.-inset-y-88 {
  top: -8.8rem;
  bottom: -8.8rem
}

.-inset-x-88 {
  right: -8.8rem;
  left: -8.8rem
}

.-inset-y-92 {
  top: -9.2rem;
  bottom: -9.2rem
}

.-inset-x-92 {
  right: -9.2rem;
  left: -9.2rem
}

.-inset-y-96 {
  top: -9.6rem;
  bottom: -9.6rem
}

.-inset-x-96 {
  right: -9.6rem;
  left: -9.6rem
}

.-inset-y-128 {
  top: -12.8rem;
  bottom: -12.8rem
}

.-inset-x-128 {
  right: -12.8rem;
  left: -12.8rem
}

.-inset-y-136 {
  top: -13.6rem;
  bottom: -13.6rem
}

.-inset-x-136 {
  right: -13.6rem;
  left: -13.6rem
}

.-inset-y-160 {
  top: -16rem;
  bottom: -16rem
}

.-inset-x-160 {
  right: -16rem;
  left: -16rem
}

.-inset-y-192 {
  top: -19.2rem;
  bottom: -19.2rem
}

.-inset-x-192 {
  right: -19.2rem;
  left: -19.2rem
}

.-inset-y-200 {
  top: -20rem;
  bottom: -20rem
}

.-inset-x-200 {
  right: -20rem;
  left: -20rem
}

.-inset-y-208 {
  top: -20.8rem;
  bottom: -20.8rem
}

.-inset-x-208 {
  right: -20.8rem;
  left: -20.8rem
}

.-inset-y-216 {
  top: -21.6rem;
  bottom: -21.6rem
}

.-inset-x-216 {
  right: -21.6rem;
  left: -21.6rem
}

.-inset-y-224 {
  top: -22.4rem;
  bottom: -22.4rem
}

.-inset-x-224 {
  right: -22.4rem;
  left: -22.4rem
}

.-inset-y-256 {
  top: -25.6rem;
  bottom: -25.6rem
}

.-inset-x-256 {
  right: -25.6rem;
  left: -25.6rem
}

.-inset-y-288 {
  top: -28.8rem;
  bottom: -28.8rem
}

.-inset-x-288 {
  right: -28.8rem;
  left: -28.8rem
}

.-inset-y-320 {
  top: -32rem;
  bottom: -32rem
}

.-inset-x-320 {
  right: -32rem;
  left: -32rem
}

.-inset-y-360 {
  top: -36rem;
  bottom: -36rem
}

.-inset-x-360 {
  right: -36rem;
  left: -36rem
}

.-inset-y-384 {
  top: -38.4rem;
  bottom: -38.4rem
}

.-inset-x-384 {
  right: -38.4rem;
  left: -38.4rem
}

.-inset-y-400 {
  top: -40rem;
  bottom: -40rem
}

.-inset-x-400 {
  right: -40rem;
  left: -40rem
}

.-inset-y-512 {
  top: -51.2rem;
  bottom: -51.2rem
}

.-inset-x-512 {
  right: -51.2rem;
  left: -51.2rem
}

.-inset-y-640 {
  top: -64rem;
  bottom: -64rem
}

.-inset-x-640 {
  right: -64rem;
  left: -64rem
}

.-inset-y-xs {
  top: -32rem;
  bottom: -32rem
}

.-inset-x-xs {
  right: -32rem;
  left: -32rem
}

.-inset-y-sm {
  top: -48rem;
  bottom: -48rem
}

.-inset-x-sm {
  right: -48rem;
  left: -48rem
}

.-inset-y-md {
  top: -64rem;
  bottom: -64rem
}

.-inset-x-md {
  right: -64rem;
  left: -64rem
}

.-inset-y-lg {
  top: -80rem;
  bottom: -80rem
}

.-inset-x-lg {
  right: -80rem;
  left: -80rem
}

.-inset-y-xl {
  top: -96rem;
  bottom: -96rem
}

.-inset-x-xl {
  right: -96rem;
  left: -96rem
}

.-inset-y-2xl {
  top: -112rem;
  bottom: -112rem
}

.-inset-x-2xl {
  right: -112rem;
  left: -112rem
}

.-inset-y-3xl {
  top: -128rem;
  bottom: -128rem
}

.-inset-x-3xl {
  right: -128rem;
  left: -128rem
}

.-inset-y-4xl {
  top: -144rem;
  bottom: -144rem
}

.-inset-x-4xl {
  right: -144rem;
  left: -144rem
}

.-inset-y-5xl {
  top: -160rem;
  bottom: -160rem
}

.-inset-x-5xl {
  right: -160rem;
  left: -160rem
}

.-inset-y-px {
  top: -1px;
  bottom: -1px
}

.-inset-x-px {
  right: -1px;
  left: -1px
}

.-inset-y-0\.5 {
  top: -0.05rem;
  bottom: -0.05rem
}

.-inset-x-0\.5 {
  right: -0.05rem;
  left: -0.05rem
}

.-inset-y-1\.5 {
  top: -0.15rem;
  bottom: -0.15rem
}

.-inset-x-1\.5 {
  right: -0.15rem;
  left: -0.15rem
}

.-inset-y-2\.5 {
  top: -0.25rem;
  bottom: -0.25rem
}

.-inset-x-2\.5 {
  right: -0.25rem;
  left: -0.25rem
}

.-inset-y-3\.5 {
  top: -0.35rem;
  bottom: -0.35rem
}

.-inset-x-3\.5 {
  right: -0.35rem;
  left: -0.35rem
}

.inset-y-1\/2 {
  top: 50%;
  bottom: 50%
}

.inset-x-1\/2 {
  right: 50%;
  left: 50%
}

.inset-y-1\/3 {
  top: 33.333333%;
  bottom: 33.333333%
}

.inset-x-1\/3 {
  right: 33.333333%;
  left: 33.333333%
}

.inset-y-2\/3 {
  top: 66.666667%;
  bottom: 66.666667%
}

.inset-x-2\/3 {
  right: 66.666667%;
  left: 66.666667%
}

.inset-y-1\/4 {
  top: 25%;
  bottom: 25%
}

.inset-x-1\/4 {
  right: 25%;
  left: 25%
}

.inset-y-2\/4 {
  top: 50%;
  bottom: 50%
}

.inset-x-2\/4 {
  right: 50%;
  left: 50%
}

.inset-y-3\/4 {
  top: 75%;
  bottom: 75%
}

.inset-x-3\/4 {
  right: 75%;
  left: 75%
}

.inset-y-full {
  top: 100%;
  bottom: 100%
}

.inset-x-full {
  right: 100%;
  left: 100%
}

.-inset-y-1\/2 {
  top: -50%;
  bottom: -50%
}

.-inset-x-1\/2 {
  right: -50%;
  left: -50%
}

.-inset-y-1\/3 {
  top: -33.333333%;
  bottom: -33.333333%
}

.-inset-x-1\/3 {
  right: -33.333333%;
  left: -33.333333%
}

.-inset-y-2\/3 {
  top: -66.666667%;
  bottom: -66.666667%
}

.-inset-x-2\/3 {
  right: -66.666667%;
  left: -66.666667%
}

.-inset-y-1\/4 {
  top: -25%;
  bottom: -25%
}

.-inset-x-1\/4 {
  right: -25%;
  left: -25%
}

.-inset-y-2\/4 {
  top: -50%;
  bottom: -50%
}

.-inset-x-2\/4 {
  right: -50%;
  left: -50%
}

.-inset-y-3\/4 {
  top: -75%;
  bottom: -75%
}

.-inset-x-3\/4 {
  right: -75%;
  left: -75%
}

.-inset-y-full {
  top: -100%;
  bottom: -100%
}

.-inset-x-full {
  right: -100%;
  left: -100%
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

.top-1 {
  top: 0.1rem
}

.right-1 {
  right: 0.1rem
}

.bottom-1 {
  bottom: 0.1rem
}

.left-1 {
  left: 0.1rem
}

.top-2 {
  top: 0.2rem
}

.right-2 {
  right: 0.2rem
}

.bottom-2 {
  bottom: 0.2rem
}

.left-2 {
  left: 0.2rem
}

.top-3 {
  top: 0.3rem
}

.right-3 {
  right: 0.3rem
}

.bottom-3 {
  bottom: 0.3rem
}

.left-3 {
  left: 0.3rem
}

.top-4 {
  top: 0.4rem
}

.right-4 {
  right: 0.4rem
}

.bottom-4 {
  bottom: 0.4rem
}

.left-4 {
  left: 0.4rem
}

.top-5 {
  top: 0.5rem
}

.right-5 {
  right: 0.5rem
}

.bottom-5 {
  bottom: 0.5rem
}

.left-5 {
  left: 0.5rem
}

.top-6 {
  top: 0.6rem
}

.right-6 {
  right: 0.6rem
}

.bottom-6 {
  bottom: 0.6rem
}

.left-6 {
  left: 0.6rem
}

.top-7 {
  top: 0.7rem
}

.right-7 {
  right: 0.7rem
}

.bottom-7 {
  bottom: 0.7rem
}

.left-7 {
  left: 0.7rem
}

.top-8 {
  top: 0.8rem
}

.right-8 {
  right: 0.8rem
}

.bottom-8 {
  bottom: 0.8rem
}

.left-8 {
  left: 0.8rem
}

.top-9 {
  top: 0.9rem
}

.right-9 {
  right: 0.9rem
}

.bottom-9 {
  bottom: 0.9rem
}

.left-9 {
  left: 0.9rem
}

.top-10 {
  top: 1.0rem
}

.right-10 {
  right: 1.0rem
}

.bottom-10 {
  bottom: 1.0rem
}

.left-10 {
  left: 1.0rem
}

.top-12 {
  top: 1.2rem
}

.right-12 {
  right: 1.2rem
}

.bottom-12 {
  bottom: 1.2rem
}

.left-12 {
  left: 1.2rem
}

.top-14 {
  top: 1.4rem
}

.right-14 {
  right: 1.4rem
}

.bottom-14 {
  bottom: 1.4rem
}

.left-14 {
  left: 1.4rem
}

.top-16 {
  top: 1.6rem
}

.right-16 {
  right: 1.6rem
}

.bottom-16 {
  bottom: 1.6rem
}

.left-16 {
  left: 1.6rem
}

.top-20 {
  top: 2rem
}

.right-20 {
  right: 2rem
}

.bottom-20 {
  bottom: 2rem
}

.left-20 {
  left: 2rem
}

.top-24 {
  top: 2.4rem
}

.right-24 {
  right: 2.4rem
}

.bottom-24 {
  bottom: 2.4rem
}

.left-24 {
  left: 2.4rem
}

.top-28 {
  top: 2.8rem
}

.right-28 {
  right: 2.8rem
}

.bottom-28 {
  bottom: 2.8rem
}

.left-28 {
  left: 2.8rem
}

.top-32 {
  top: 3.2rem
}

.right-32 {
  right: 3.2rem
}

.bottom-32 {
  bottom: 3.2rem
}

.left-32 {
  left: 3.2rem
}

.top-36 {
  top: 3.6rem
}

.right-36 {
  right: 3.6rem
}

.bottom-36 {
  bottom: 3.6rem
}

.left-36 {
  left: 3.6rem
}

.top-40 {
  top: 4rem
}

.right-40 {
  right: 4rem
}

.bottom-40 {
  bottom: 4rem
}

.left-40 {
  left: 4rem
}

.top-44 {
  top: 4.4rem
}

.right-44 {
  right: 4.4rem
}

.bottom-44 {
  bottom: 4.4rem
}

.left-44 {
  left: 4.4rem
}

.top-48 {
  top: 4.8rem
}

.right-48 {
  right: 4.8rem
}

.bottom-48 {
  bottom: 4.8rem
}

.left-48 {
  left: 4.8rem
}

.top-52 {
  top: 5.2rem
}

.right-52 {
  right: 5.2rem
}

.bottom-52 {
  bottom: 5.2rem
}

.left-52 {
  left: 5.2rem
}

.top-56 {
  top: 5.6rem
}

.right-56 {
  right: 5.6rem
}

.bottom-56 {
  bottom: 5.6rem
}

.left-56 {
  left: 5.6rem
}

.top-60 {
  top: 6rem
}

.right-60 {
  right: 6rem
}

.bottom-60 {
  bottom: 6rem
}

.left-60 {
  left: 6rem
}

.top-64 {
  top: 6.4rem
}

.right-64 {
  right: 6.4rem
}

.bottom-64 {
  bottom: 6.4rem
}

.left-64 {
  left: 6.4rem
}

.top-68 {
  top: 6.8rem
}

.right-68 {
  right: 6.8rem
}

.bottom-68 {
  bottom: 6.8rem
}

.left-68 {
  left: 6.8rem
}

.top-72 {
  top: 7.2rem
}

.right-72 {
  right: 7.2rem
}

.bottom-72 {
  bottom: 7.2rem
}

.left-72 {
  left: 7.2rem
}

.top-76 {
  top: 7.6rem
}

.right-76 {
  right: 7.6rem
}

.bottom-76 {
  bottom: 7.6rem
}

.left-76 {
  left: 7.6rem
}

.top-80 {
  top: 8rem
}

.right-80 {
  right: 8rem
}

.bottom-80 {
  bottom: 8rem
}

.left-80 {
  left: 8rem
}

.top-84 {
  top: 8.4rem
}

.right-84 {
  right: 8.4rem
}

.bottom-84 {
  bottom: 8.4rem
}

.left-84 {
  left: 8.4rem
}

.top-88 {
  top: 8.8rem
}

.right-88 {
  right: 8.8rem
}

.bottom-88 {
  bottom: 8.8rem
}

.left-88 {
  left: 8.8rem
}

.top-92 {
  top: 9.2rem
}

.right-92 {
  right: 9.2rem
}

.bottom-92 {
  bottom: 9.2rem
}

.left-92 {
  left: 9.2rem
}

.top-96 {
  top: 9.6rem
}

.right-96 {
  right: 9.6rem
}

.bottom-96 {
  bottom: 9.6rem
}

.left-96 {
  left: 9.6rem
}

.top-128 {
  top: 12.8rem
}

.right-128 {
  right: 12.8rem
}

.bottom-128 {
  bottom: 12.8rem
}

.left-128 {
  left: 12.8rem
}

.top-136 {
  top: 13.6rem
}

.right-136 {
  right: 13.6rem
}

.bottom-136 {
  bottom: 13.6rem
}

.left-136 {
  left: 13.6rem
}

.top-160 {
  top: 16rem
}

.right-160 {
  right: 16rem
}

.bottom-160 {
  bottom: 16rem
}

.left-160 {
  left: 16rem
}

.top-192 {
  top: 19.2rem
}

.right-192 {
  right: 19.2rem
}

.bottom-192 {
  bottom: 19.2rem
}

.left-192 {
  left: 19.2rem
}

.top-200 {
  top: 20rem
}

.right-200 {
  right: 20rem
}

.bottom-200 {
  bottom: 20rem
}

.left-200 {
  left: 20rem
}

.top-208 {
  top: 20.8rem
}

.right-208 {
  right: 20.8rem
}

.bottom-208 {
  bottom: 20.8rem
}

.left-208 {
  left: 20.8rem
}

.top-216 {
  top: 21.6rem
}

.right-216 {
  right: 21.6rem
}

.bottom-216 {
  bottom: 21.6rem
}

.left-216 {
  left: 21.6rem
}

.top-224 {
  top: 22.4rem
}

.right-224 {
  right: 22.4rem
}

.bottom-224 {
  bottom: 22.4rem
}

.left-224 {
  left: 22.4rem
}

.top-256 {
  top: 25.6rem
}

.right-256 {
  right: 25.6rem
}

.bottom-256 {
  bottom: 25.6rem
}

.left-256 {
  left: 25.6rem
}

.top-288 {
  top: 28.8rem
}

.right-288 {
  right: 28.8rem
}

.bottom-288 {
  bottom: 28.8rem
}

.left-288 {
  left: 28.8rem
}

.top-320 {
  top: 32rem
}

.right-320 {
  right: 32rem
}

.bottom-320 {
  bottom: 32rem
}

.left-320 {
  left: 32rem
}

.top-360 {
  top: 36rem
}

.right-360 {
  right: 36rem
}

.bottom-360 {
  bottom: 36rem
}

.left-360 {
  left: 36rem
}

.top-384 {
  top: 38.4rem
}

.right-384 {
  right: 38.4rem
}

.bottom-384 {
  bottom: 38.4rem
}

.left-384 {
  left: 38.4rem
}

.top-400 {
  top: 40rem
}

.right-400 {
  right: 40rem
}

.bottom-400 {
  bottom: 40rem
}

.left-400 {
  left: 40rem
}

.top-512 {
  top: 51.2rem
}

.right-512 {
  right: 51.2rem
}

.bottom-512 {
  bottom: 51.2rem
}

.left-512 {
  left: 51.2rem
}

.top-640 {
  top: 64rem
}

.right-640 {
  right: 64rem
}

.bottom-640 {
  bottom: 64rem
}

.left-640 {
  left: 64rem
}

.top-auto {
  top: auto
}

.right-auto {
  right: auto
}

.bottom-auto {
  bottom: auto
}

.left-auto {
  left: auto
}

.top-xs {
  top: 32rem
}

.right-xs {
  right: 32rem
}

.bottom-xs {
  bottom: 32rem
}

.left-xs {
  left: 32rem
}

.top-sm {
  top: 48rem
}

.right-sm {
  right: 48rem
}

.bottom-sm {
  bottom: 48rem
}

.left-sm {
  left: 48rem
}

.top-md {
  top: 64rem
}

.right-md {
  right: 64rem
}

.bottom-md {
  bottom: 64rem
}

.left-md {
  left: 64rem
}

.top-lg {
  top: 80rem
}

.right-lg {
  right: 80rem
}

.bottom-lg {
  bottom: 80rem
}

.left-lg {
  left: 80rem
}

.top-xl {
  top: 96rem
}

.right-xl {
  right: 96rem
}

.bottom-xl {
  bottom: 96rem
}

.left-xl {
  left: 96rem
}

.top-2xl {
  top: 112rem
}

.right-2xl {
  right: 112rem
}

.bottom-2xl {
  bottom: 112rem
}

.left-2xl {
  left: 112rem
}

.top-3xl {
  top: 128rem
}

.right-3xl {
  right: 128rem
}

.bottom-3xl {
  bottom: 128rem
}

.left-3xl {
  left: 128rem
}

.top-4xl {
  top: 144rem
}

.right-4xl {
  right: 144rem
}

.bottom-4xl {
  bottom: 144rem
}

.left-4xl {
  left: 144rem
}

.top-5xl {
  top: 160rem
}

.right-5xl {
  right: 160rem
}

.bottom-5xl {
  bottom: 160rem
}

.left-5xl {
  left: 160rem
}

.top-px {
  top: 1px
}

.right-px {
  right: 1px
}

.bottom-px {
  bottom: 1px
}

.left-px {
  left: 1px
}

.top-0\.5 {
  top: 0.05rem
}

.right-0\.5 {
  right: 0.05rem
}

.bottom-0\.5 {
  bottom: 0.05rem
}

.left-0\.5 {
  left: 0.05rem
}

.top-1\.5 {
  top: 0.15rem
}

.right-1\.5 {
  right: 0.15rem
}

.bottom-1\.5 {
  bottom: 0.15rem
}

.left-1\.5 {
  left: 0.15rem
}

.top-2\.5 {
  top: 0.25rem
}

.right-2\.5 {
  right: 0.25rem
}

.bottom-2\.5 {
  bottom: 0.25rem
}

.left-2\.5 {
  left: 0.25rem
}

.top-3\.5 {
  top: 0.35rem
}

.right-3\.5 {
  right: 0.35rem
}

.bottom-3\.5 {
  bottom: 0.35rem
}

.left-3\.5 {
  left: 0.35rem
}

.-top-1 {
  top: -0.1rem
}

.-right-1 {
  right: -0.1rem
}

.-bottom-1 {
  bottom: -0.1rem
}

.-left-1 {
  left: -0.1rem
}

.-top-2 {
  top: -0.2rem
}

.-right-2 {
  right: -0.2rem
}

.-bottom-2 {
  bottom: -0.2rem
}

.-left-2 {
  left: -0.2rem
}

.-top-3 {
  top: -0.3rem
}

.-right-3 {
  right: -0.3rem
}

.-bottom-3 {
  bottom: -0.3rem
}

.-left-3 {
  left: -0.3rem
}

.-top-4 {
  top: -0.4rem
}

.-right-4 {
  right: -0.4rem
}

.-bottom-4 {
  bottom: -0.4rem
}

.-left-4 {
  left: -0.4rem
}

.-top-5 {
  top: -0.5rem
}

.-right-5 {
  right: -0.5rem
}

.-bottom-5 {
  bottom: -0.5rem
}

.-left-5 {
  left: -0.5rem
}

.-top-6 {
  top: -0.6rem
}

.-right-6 {
  right: -0.6rem
}

.-bottom-6 {
  bottom: -0.6rem
}

.-left-6 {
  left: -0.6rem
}

.-top-7 {
  top: -0.7rem
}

.-right-7 {
  right: -0.7rem
}

.-bottom-7 {
  bottom: -0.7rem
}

.-left-7 {
  left: -0.7rem
}

.-top-8 {
  top: -0.8rem
}

.-right-8 {
  right: -0.8rem
}

.-bottom-8 {
  bottom: -0.8rem
}

.-left-8 {
  left: -0.8rem
}

.-top-9 {
  top: -0.9rem
}

.-right-9 {
  right: -0.9rem
}

.-bottom-9 {
  bottom: -0.9rem
}

.-left-9 {
  left: -0.9rem
}

.-top-10 {
  top: -1rem
}

.-right-10 {
  right: -1rem
}

.-bottom-10 {
  bottom: -1rem
}

.-left-10 {
  left: -1rem
}

.-top-12 {
  top: -1.2rem
}

.-right-12 {
  right: -1.2rem
}

.-bottom-12 {
  bottom: -1.2rem
}

.-left-12 {
  left: -1.2rem
}

.-top-14 {
  top: -1.4rem
}

.-right-14 {
  right: -1.4rem
}

.-bottom-14 {
  bottom: -1.4rem
}

.-left-14 {
  left: -1.4rem
}

.-top-16 {
  top: -1.6rem
}

.-right-16 {
  right: -1.6rem
}

.-bottom-16 {
  bottom: -1.6rem
}

.-left-16 {
  left: -1.6rem
}

.-top-20 {
  top: -2rem
}

.-right-20 {
  right: -2rem
}

.-bottom-20 {
  bottom: -2rem
}

.-left-20 {
  left: -2rem
}

.-top-24 {
  top: -2.4rem
}

.-right-24 {
  right: -2.4rem
}

.-bottom-24 {
  bottom: -2.4rem
}

.-left-24 {
  left: -2.4rem
}

.-top-28 {
  top: -2.8rem
}

.-right-28 {
  right: -2.8rem
}

.-bottom-28 {
  bottom: -2.8rem
}

.-left-28 {
  left: -2.8rem
}

.-top-32 {
  top: -3.2rem
}

.-right-32 {
  right: -3.2rem
}

.-bottom-32 {
  bottom: -3.2rem
}

.-left-32 {
  left: -3.2rem
}

.-top-36 {
  top: -3.6rem
}

.-right-36 {
  right: -3.6rem
}

.-bottom-36 {
  bottom: -3.6rem
}

.-left-36 {
  left: -3.6rem
}

.-top-40 {
  top: -4rem
}

.-right-40 {
  right: -4rem
}

.-bottom-40 {
  bottom: -4rem
}

.-left-40 {
  left: -4rem
}

.-top-44 {
  top: -4.4rem
}

.-right-44 {
  right: -4.4rem
}

.-bottom-44 {
  bottom: -4.4rem
}

.-left-44 {
  left: -4.4rem
}

.-top-48 {
  top: -4.8rem
}

.-right-48 {
  right: -4.8rem
}

.-bottom-48 {
  bottom: -4.8rem
}

.-left-48 {
  left: -4.8rem
}

.-top-52 {
  top: -5.2rem
}

.-right-52 {
  right: -5.2rem
}

.-bottom-52 {
  bottom: -5.2rem
}

.-left-52 {
  left: -5.2rem
}

.-top-56 {
  top: -5.6rem
}

.-right-56 {
  right: -5.6rem
}

.-bottom-56 {
  bottom: -5.6rem
}

.-left-56 {
  left: -5.6rem
}

.-top-60 {
  top: -6rem
}

.-right-60 {
  right: -6rem
}

.-bottom-60 {
  bottom: -6rem
}

.-left-60 {
  left: -6rem
}

.-top-64 {
  top: -6.4rem
}

.-right-64 {
  right: -6.4rem
}

.-bottom-64 {
  bottom: -6.4rem
}

.-left-64 {
  left: -6.4rem
}

.-top-68 {
  top: -6.8rem
}

.-right-68 {
  right: -6.8rem
}

.-bottom-68 {
  bottom: -6.8rem
}

.-left-68 {
  left: -6.8rem
}

.-top-72 {
  top: -7.2rem
}

.-right-72 {
  right: -7.2rem
}

.-bottom-72 {
  bottom: -7.2rem
}

.-left-72 {
  left: -7.2rem
}

.-top-76 {
  top: -7.6rem
}

.-right-76 {
  right: -7.6rem
}

.-bottom-76 {
  bottom: -7.6rem
}

.-left-76 {
  left: -7.6rem
}

.-top-80 {
  top: -8rem
}

.-right-80 {
  right: -8rem
}

.-bottom-80 {
  bottom: -8rem
}

.-left-80 {
  left: -8rem
}

.-top-84 {
  top: -8.4rem
}

.-right-84 {
  right: -8.4rem
}

.-bottom-84 {
  bottom: -8.4rem
}

.-left-84 {
  left: -8.4rem
}

.-top-88 {
  top: -8.8rem
}

.-right-88 {
  right: -8.8rem
}

.-bottom-88 {
  bottom: -8.8rem
}

.-left-88 {
  left: -8.8rem
}

.-top-92 {
  top: -9.2rem
}

.-right-92 {
  right: -9.2rem
}

.-bottom-92 {
  bottom: -9.2rem
}

.-left-92 {
  left: -9.2rem
}

.-top-96 {
  top: -9.6rem
}

.-right-96 {
  right: -9.6rem
}

.-bottom-96 {
  bottom: -9.6rem
}

.-left-96 {
  left: -9.6rem
}

.-top-128 {
  top: -12.8rem
}

.-right-128 {
  right: -12.8rem
}

.-bottom-128 {
  bottom: -12.8rem
}

.-left-128 {
  left: -12.8rem
}

.-top-136 {
  top: -13.6rem
}

.-right-136 {
  right: -13.6rem
}

.-bottom-136 {
  bottom: -13.6rem
}

.-left-136 {
  left: -13.6rem
}

.-top-160 {
  top: -16rem
}

.-right-160 {
  right: -16rem
}

.-bottom-160 {
  bottom: -16rem
}

.-left-160 {
  left: -16rem
}

.-top-192 {
  top: -19.2rem
}

.-right-192 {
  right: -19.2rem
}

.-bottom-192 {
  bottom: -19.2rem
}

.-left-192 {
  left: -19.2rem
}

.-top-200 {
  top: -20rem
}

.-right-200 {
  right: -20rem
}

.-bottom-200 {
  bottom: -20rem
}

.-left-200 {
  left: -20rem
}

.-top-208 {
  top: -20.8rem
}

.-right-208 {
  right: -20.8rem
}

.-bottom-208 {
  bottom: -20.8rem
}

.-left-208 {
  left: -20.8rem
}

.-top-216 {
  top: -21.6rem
}

.-right-216 {
  right: -21.6rem
}

.-bottom-216 {
  bottom: -21.6rem
}

.-left-216 {
  left: -21.6rem
}

.-top-224 {
  top: -22.4rem
}

.-right-224 {
  right: -22.4rem
}

.-bottom-224 {
  bottom: -22.4rem
}

.-left-224 {
  left: -22.4rem
}

.-top-256 {
  top: -25.6rem
}

.-right-256 {
  right: -25.6rem
}

.-bottom-256 {
  bottom: -25.6rem
}

.-left-256 {
  left: -25.6rem
}

.-top-288 {
  top: -28.8rem
}

.-right-288 {
  right: -28.8rem
}

.-bottom-288 {
  bottom: -28.8rem
}

.-left-288 {
  left: -28.8rem
}

.-top-320 {
  top: -32rem
}

.-right-320 {
  right: -32rem
}

.-bottom-320 {
  bottom: -32rem
}

.-left-320 {
  left: -32rem
}

.-top-360 {
  top: -36rem
}

.-right-360 {
  right: -36rem
}

.-bottom-360 {
  bottom: -36rem
}

.-left-360 {
  left: -36rem
}

.-top-384 {
  top: -38.4rem
}

.-right-384 {
  right: -38.4rem
}

.-bottom-384 {
  bottom: -38.4rem
}

.-left-384 {
  left: -38.4rem
}

.-top-400 {
  top: -40rem
}

.-right-400 {
  right: -40rem
}

.-bottom-400 {
  bottom: -40rem
}

.-left-400 {
  left: -40rem
}

.-top-512 {
  top: -51.2rem
}

.-right-512 {
  right: -51.2rem
}

.-bottom-512 {
  bottom: -51.2rem
}

.-left-512 {
  left: -51.2rem
}

.-top-640 {
  top: -64rem
}

.-right-640 {
  right: -64rem
}

.-bottom-640 {
  bottom: -64rem
}

.-left-640 {
  left: -64rem
}

.-top-xs {
  top: -32rem
}

.-right-xs {
  right: -32rem
}

.-bottom-xs {
  bottom: -32rem
}

.-left-xs {
  left: -32rem
}

.-top-sm {
  top: -48rem
}

.-right-sm {
  right: -48rem
}

.-bottom-sm {
  bottom: -48rem
}

.-left-sm {
  left: -48rem
}

.-top-md {
  top: -64rem
}

.-right-md {
  right: -64rem
}

.-bottom-md {
  bottom: -64rem
}

.-left-md {
  left: -64rem
}

.-top-lg {
  top: -80rem
}

.-right-lg {
  right: -80rem
}

.-bottom-lg {
  bottom: -80rem
}

.-left-lg {
  left: -80rem
}

.-top-xl {
  top: -96rem
}

.-right-xl {
  right: -96rem
}

.-bottom-xl {
  bottom: -96rem
}

.-left-xl {
  left: -96rem
}

.-top-2xl {
  top: -112rem
}

.-right-2xl {
  right: -112rem
}

.-bottom-2xl {
  bottom: -112rem
}

.-left-2xl {
  left: -112rem
}

.-top-3xl {
  top: -128rem
}

.-right-3xl {
  right: -128rem
}

.-bottom-3xl {
  bottom: -128rem
}

.-left-3xl {
  left: -128rem
}

.-top-4xl {
  top: -144rem
}

.-right-4xl {
  right: -144rem
}

.-bottom-4xl {
  bottom: -144rem
}

.-left-4xl {
  left: -144rem
}

.-top-5xl {
  top: -160rem
}

.-right-5xl {
  right: -160rem
}

.-bottom-5xl {
  bottom: -160rem
}

.-left-5xl {
  left: -160rem
}

.-top-px {
  top: -1px
}

.-right-px {
  right: -1px
}

.-bottom-px {
  bottom: -1px
}

.-left-px {
  left: -1px
}

.-top-0\.5 {
  top: -0.05rem
}

.-right-0\.5 {
  right: -0.05rem
}

.-bottom-0\.5 {
  bottom: -0.05rem
}

.-left-0\.5 {
  left: -0.05rem
}

.-top-1\.5 {
  top: -0.15rem
}

.-right-1\.5 {
  right: -0.15rem
}

.-bottom-1\.5 {
  bottom: -0.15rem
}

.-left-1\.5 {
  left: -0.15rem
}

.-top-2\.5 {
  top: -0.25rem
}

.-right-2\.5 {
  right: -0.25rem
}

.-bottom-2\.5 {
  bottom: -0.25rem
}

.-left-2\.5 {
  left: -0.25rem
}

.-top-3\.5 {
  top: -0.35rem
}

.-right-3\.5 {
  right: -0.35rem
}

.-bottom-3\.5 {
  bottom: -0.35rem
}

.-left-3\.5 {
  left: -0.35rem
}

.top-1\/2 {
  top: 50%
}

.right-1\/2 {
  right: 50%
}

.bottom-1\/2 {
  bottom: 50%
}

.left-1\/2 {
  left: 50%
}

.top-1\/3 {
  top: 33.333333%
}

.right-1\/3 {
  right: 33.333333%
}

.bottom-1\/3 {
  bottom: 33.333333%
}

.left-1\/3 {
  left: 33.333333%
}

.top-2\/3 {
  top: 66.666667%
}

.right-2\/3 {
  right: 66.666667%
}

.bottom-2\/3 {
  bottom: 66.666667%
}

.left-2\/3 {
  left: 66.666667%
}

.top-1\/4 {
  top: 25%
}

.right-1\/4 {
  right: 25%
}

.bottom-1\/4 {
  bottom: 25%
}

.left-1\/4 {
  left: 25%
}

.top-2\/4 {
  top: 50%
}

.right-2\/4 {
  right: 50%
}

.bottom-2\/4 {
  bottom: 50%
}

.left-2\/4 {
  left: 50%
}

.top-3\/4 {
  top: 75%
}

.right-3\/4 {
  right: 75%
}

.bottom-3\/4 {
  bottom: 75%
}

.left-3\/4 {
  left: 75%
}

.top-full {
  top: 100%
}

.right-full {
  right: 100%
}

.bottom-full {
  bottom: 100%
}

.left-full {
  left: 100%
}

.-top-1\/2 {
  top: -50%
}

.-right-1\/2 {
  right: -50%
}

.-bottom-1\/2 {
  bottom: -50%
}

.-left-1\/2 {
  left: -50%
}

.-top-1\/3 {
  top: -33.333333%
}

.-right-1\/3 {
  right: -33.333333%
}

.-bottom-1\/3 {
  bottom: -33.333333%
}

.-left-1\/3 {
  left: -33.333333%
}

.-top-2\/3 {
  top: -66.666667%
}

.-right-2\/3 {
  right: -66.666667%
}

.-bottom-2\/3 {
  bottom: -66.666667%
}

.-left-2\/3 {
  left: -66.666667%
}

.-top-1\/4 {
  top: -25%
}

.-right-1\/4 {
  right: -25%
}

.-bottom-1\/4 {
  bottom: -25%
}

.-left-1\/4 {
  left: -25%
}

.-top-2\/4 {
  top: -50%
}

.-right-2\/4 {
  right: -50%
}

.-bottom-2\/4 {
  bottom: -50%
}

.-left-2\/4 {
  left: -50%
}

.-top-3\/4 {
  top: -75%
}

.-right-3\/4 {
  right: -75%
}

.-bottom-3\/4 {
  bottom: -75%
}

.-left-3\/4 {
  left: -75%
}

.-top-full {
  top: -100%
}

.-right-full {
  right: -100%
}

.-bottom-full {
  bottom: -100%
}

.-left-full {
  left: -100%
}

[dir='ltr'] .ltr\:inset-0,[dir='ltr'].ltr\:inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

[dir='ltr'] .ltr\:inset-1,[dir='ltr'].ltr\:inset-1 {
  top: 0.1rem;
  right: 0.1rem;
  bottom: 0.1rem;
  left: 0.1rem
}

[dir='ltr'] .ltr\:inset-2,[dir='ltr'].ltr\:inset-2 {
  top: 0.2rem;
  right: 0.2rem;
  bottom: 0.2rem;
  left: 0.2rem
}

[dir='ltr'] .ltr\:inset-3,[dir='ltr'].ltr\:inset-3 {
  top: 0.3rem;
  right: 0.3rem;
  bottom: 0.3rem;
  left: 0.3rem
}

[dir='ltr'] .ltr\:inset-4,[dir='ltr'].ltr\:inset-4 {
  top: 0.4rem;
  right: 0.4rem;
  bottom: 0.4rem;
  left: 0.4rem
}

[dir='ltr'] .ltr\:inset-5,[dir='ltr'].ltr\:inset-5 {
  top: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem
}

[dir='ltr'] .ltr\:inset-6,[dir='ltr'].ltr\:inset-6 {
  top: 0.6rem;
  right: 0.6rem;
  bottom: 0.6rem;
  left: 0.6rem
}

[dir='ltr'] .ltr\:inset-7,[dir='ltr'].ltr\:inset-7 {
  top: 0.7rem;
  right: 0.7rem;
  bottom: 0.7rem;
  left: 0.7rem
}

[dir='ltr'] .ltr\:inset-8,[dir='ltr'].ltr\:inset-8 {
  top: 0.8rem;
  right: 0.8rem;
  bottom: 0.8rem;
  left: 0.8rem
}

[dir='ltr'] .ltr\:inset-9,[dir='ltr'].ltr\:inset-9 {
  top: 0.9rem;
  right: 0.9rem;
  bottom: 0.9rem;
  left: 0.9rem
}

[dir='ltr'] .ltr\:inset-10,[dir='ltr'].ltr\:inset-10 {
  top: 1.0rem;
  right: 1.0rem;
  bottom: 1.0rem;
  left: 1.0rem
}

[dir='ltr'] .ltr\:inset-12,[dir='ltr'].ltr\:inset-12 {
  top: 1.2rem;
  right: 1.2rem;
  bottom: 1.2rem;
  left: 1.2rem
}

[dir='ltr'] .ltr\:inset-14,[dir='ltr'].ltr\:inset-14 {
  top: 1.4rem;
  right: 1.4rem;
  bottom: 1.4rem;
  left: 1.4rem
}

[dir='ltr'] .ltr\:inset-16,[dir='ltr'].ltr\:inset-16 {
  top: 1.6rem;
  right: 1.6rem;
  bottom: 1.6rem;
  left: 1.6rem
}

[dir='ltr'] .ltr\:inset-20,[dir='ltr'].ltr\:inset-20 {
  top: 2rem;
  right: 2rem;
  bottom: 2rem;
  left: 2rem
}

[dir='ltr'] .ltr\:inset-24,[dir='ltr'].ltr\:inset-24 {
  top: 2.4rem;
  right: 2.4rem;
  bottom: 2.4rem;
  left: 2.4rem
}

[dir='ltr'] .ltr\:inset-28,[dir='ltr'].ltr\:inset-28 {
  top: 2.8rem;
  right: 2.8rem;
  bottom: 2.8rem;
  left: 2.8rem
}

[dir='ltr'] .ltr\:inset-32,[dir='ltr'].ltr\:inset-32 {
  top: 3.2rem;
  right: 3.2rem;
  bottom: 3.2rem;
  left: 3.2rem
}

[dir='ltr'] .ltr\:inset-36,[dir='ltr'].ltr\:inset-36 {
  top: 3.6rem;
  right: 3.6rem;
  bottom: 3.6rem;
  left: 3.6rem
}

[dir='ltr'] .ltr\:inset-40,[dir='ltr'].ltr\:inset-40 {
  top: 4rem;
  right: 4rem;
  bottom: 4rem;
  left: 4rem
}

[dir='ltr'] .ltr\:inset-44,[dir='ltr'].ltr\:inset-44 {
  top: 4.4rem;
  right: 4.4rem;
  bottom: 4.4rem;
  left: 4.4rem
}

[dir='ltr'] .ltr\:inset-48,[dir='ltr'].ltr\:inset-48 {
  top: 4.8rem;
  right: 4.8rem;
  bottom: 4.8rem;
  left: 4.8rem
}

[dir='ltr'] .ltr\:inset-52,[dir='ltr'].ltr\:inset-52 {
  top: 5.2rem;
  right: 5.2rem;
  bottom: 5.2rem;
  left: 5.2rem
}

[dir='ltr'] .ltr\:inset-56,[dir='ltr'].ltr\:inset-56 {
  top: 5.6rem;
  right: 5.6rem;
  bottom: 5.6rem;
  left: 5.6rem
}

[dir='ltr'] .ltr\:inset-60,[dir='ltr'].ltr\:inset-60 {
  top: 6rem;
  right: 6rem;
  bottom: 6rem;
  left: 6rem
}

[dir='ltr'] .ltr\:inset-64,[dir='ltr'].ltr\:inset-64 {
  top: 6.4rem;
  right: 6.4rem;
  bottom: 6.4rem;
  left: 6.4rem
}

[dir='ltr'] .ltr\:inset-68,[dir='ltr'].ltr\:inset-68 {
  top: 6.8rem;
  right: 6.8rem;
  bottom: 6.8rem;
  left: 6.8rem
}

[dir='ltr'] .ltr\:inset-72,[dir='ltr'].ltr\:inset-72 {
  top: 7.2rem;
  right: 7.2rem;
  bottom: 7.2rem;
  left: 7.2rem
}

[dir='ltr'] .ltr\:inset-76,[dir='ltr'].ltr\:inset-76 {
  top: 7.6rem;
  right: 7.6rem;
  bottom: 7.6rem;
  left: 7.6rem
}

[dir='ltr'] .ltr\:inset-80,[dir='ltr'].ltr\:inset-80 {
  top: 8rem;
  right: 8rem;
  bottom: 8rem;
  left: 8rem
}

[dir='ltr'] .ltr\:inset-84,[dir='ltr'].ltr\:inset-84 {
  top: 8.4rem;
  right: 8.4rem;
  bottom: 8.4rem;
  left: 8.4rem
}

[dir='ltr'] .ltr\:inset-88,[dir='ltr'].ltr\:inset-88 {
  top: 8.8rem;
  right: 8.8rem;
  bottom: 8.8rem;
  left: 8.8rem
}

[dir='ltr'] .ltr\:inset-92,[dir='ltr'].ltr\:inset-92 {
  top: 9.2rem;
  right: 9.2rem;
  bottom: 9.2rem;
  left: 9.2rem
}

[dir='ltr'] .ltr\:inset-96,[dir='ltr'].ltr\:inset-96 {
  top: 9.6rem;
  right: 9.6rem;
  bottom: 9.6rem;
  left: 9.6rem
}

[dir='ltr'] .ltr\:inset-128,[dir='ltr'].ltr\:inset-128 {
  top: 12.8rem;
  right: 12.8rem;
  bottom: 12.8rem;
  left: 12.8rem
}

[dir='ltr'] .ltr\:inset-136,[dir='ltr'].ltr\:inset-136 {
  top: 13.6rem;
  right: 13.6rem;
  bottom: 13.6rem;
  left: 13.6rem
}

[dir='ltr'] .ltr\:inset-160,[dir='ltr'].ltr\:inset-160 {
  top: 16rem;
  right: 16rem;
  bottom: 16rem;
  left: 16rem
}

[dir='ltr'] .ltr\:inset-192,[dir='ltr'].ltr\:inset-192 {
  top: 19.2rem;
  right: 19.2rem;
  bottom: 19.2rem;
  left: 19.2rem
}

[dir='ltr'] .ltr\:inset-200,[dir='ltr'].ltr\:inset-200 {
  top: 20rem;
  right: 20rem;
  bottom: 20rem;
  left: 20rem
}

[dir='ltr'] .ltr\:inset-208,[dir='ltr'].ltr\:inset-208 {
  top: 20.8rem;
  right: 20.8rem;
  bottom: 20.8rem;
  left: 20.8rem
}

[dir='ltr'] .ltr\:inset-216,[dir='ltr'].ltr\:inset-216 {
  top: 21.6rem;
  right: 21.6rem;
  bottom: 21.6rem;
  left: 21.6rem
}

[dir='ltr'] .ltr\:inset-224,[dir='ltr'].ltr\:inset-224 {
  top: 22.4rem;
  right: 22.4rem;
  bottom: 22.4rem;
  left: 22.4rem
}

[dir='ltr'] .ltr\:inset-256,[dir='ltr'].ltr\:inset-256 {
  top: 25.6rem;
  right: 25.6rem;
  bottom: 25.6rem;
  left: 25.6rem
}

[dir='ltr'] .ltr\:inset-288,[dir='ltr'].ltr\:inset-288 {
  top: 28.8rem;
  right: 28.8rem;
  bottom: 28.8rem;
  left: 28.8rem
}

[dir='ltr'] .ltr\:inset-320,[dir='ltr'].ltr\:inset-320 {
  top: 32rem;
  right: 32rem;
  bottom: 32rem;
  left: 32rem
}

[dir='ltr'] .ltr\:inset-360,[dir='ltr'].ltr\:inset-360 {
  top: 36rem;
  right: 36rem;
  bottom: 36rem;
  left: 36rem
}

[dir='ltr'] .ltr\:inset-384,[dir='ltr'].ltr\:inset-384 {
  top: 38.4rem;
  right: 38.4rem;
  bottom: 38.4rem;
  left: 38.4rem
}

[dir='ltr'] .ltr\:inset-400,[dir='ltr'].ltr\:inset-400 {
  top: 40rem;
  right: 40rem;
  bottom: 40rem;
  left: 40rem
}

[dir='ltr'] .ltr\:inset-512,[dir='ltr'].ltr\:inset-512 {
  top: 51.2rem;
  right: 51.2rem;
  bottom: 51.2rem;
  left: 51.2rem
}

[dir='ltr'] .ltr\:inset-640,[dir='ltr'].ltr\:inset-640 {
  top: 64rem;
  right: 64rem;
  bottom: 64rem;
  left: 64rem
}

[dir='ltr'] .ltr\:inset-auto,[dir='ltr'].ltr\:inset-auto {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto
}

[dir='ltr'] .ltr\:inset-xs,[dir='ltr'].ltr\:inset-xs {
  top: 32rem;
  right: 32rem;
  bottom: 32rem;
  left: 32rem
}

[dir='ltr'] .ltr\:inset-sm,[dir='ltr'].ltr\:inset-sm {
  top: 48rem;
  right: 48rem;
  bottom: 48rem;
  left: 48rem
}

[dir='ltr'] .ltr\:inset-md,[dir='ltr'].ltr\:inset-md {
  top: 64rem;
  right: 64rem;
  bottom: 64rem;
  left: 64rem
}

[dir='ltr'] .ltr\:inset-lg,[dir='ltr'].ltr\:inset-lg {
  top: 80rem;
  right: 80rem;
  bottom: 80rem;
  left: 80rem
}

[dir='ltr'] .ltr\:inset-xl,[dir='ltr'].ltr\:inset-xl {
  top: 96rem;
  right: 96rem;
  bottom: 96rem;
  left: 96rem
}

[dir='ltr'] .ltr\:inset-2xl,[dir='ltr'].ltr\:inset-2xl {
  top: 112rem;
  right: 112rem;
  bottom: 112rem;
  left: 112rem
}

[dir='ltr'] .ltr\:inset-3xl,[dir='ltr'].ltr\:inset-3xl {
  top: 128rem;
  right: 128rem;
  bottom: 128rem;
  left: 128rem
}

[dir='ltr'] .ltr\:inset-4xl,[dir='ltr'].ltr\:inset-4xl {
  top: 144rem;
  right: 144rem;
  bottom: 144rem;
  left: 144rem
}

[dir='ltr'] .ltr\:inset-5xl,[dir='ltr'].ltr\:inset-5xl {
  top: 160rem;
  right: 160rem;
  bottom: 160rem;
  left: 160rem
}

[dir='ltr'] .ltr\:inset-px,[dir='ltr'].ltr\:inset-px {
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px
}

[dir='ltr'] .ltr\:inset-0\.5,[dir='ltr'].ltr\:inset-0\.5 {
  top: 0.05rem;
  right: 0.05rem;
  bottom: 0.05rem;
  left: 0.05rem
}

[dir='ltr'] .ltr\:inset-1\.5,[dir='ltr'].ltr\:inset-1\.5 {
  top: 0.15rem;
  right: 0.15rem;
  bottom: 0.15rem;
  left: 0.15rem
}

[dir='ltr'] .ltr\:inset-2\.5,[dir='ltr'].ltr\:inset-2\.5 {
  top: 0.25rem;
  right: 0.25rem;
  bottom: 0.25rem;
  left: 0.25rem
}

[dir='ltr'] .ltr\:inset-3\.5,[dir='ltr'].ltr\:inset-3\.5 {
  top: 0.35rem;
  right: 0.35rem;
  bottom: 0.35rem;
  left: 0.35rem
}

[dir='ltr'] .ltr\:-inset-1,[dir='ltr'].ltr\:-inset-1 {
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem
}

[dir='ltr'] .ltr\:-inset-2,[dir='ltr'].ltr\:-inset-2 {
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem
}

[dir='ltr'] .ltr\:-inset-3,[dir='ltr'].ltr\:-inset-3 {
  top: -0.3rem;
  right: -0.3rem;
  bottom: -0.3rem;
  left: -0.3rem
}

[dir='ltr'] .ltr\:-inset-4,[dir='ltr'].ltr\:-inset-4 {
  top: -0.4rem;
  right: -0.4rem;
  bottom: -0.4rem;
  left: -0.4rem
}

[dir='ltr'] .ltr\:-inset-5,[dir='ltr'].ltr\:-inset-5 {
  top: -0.5rem;
  right: -0.5rem;
  bottom: -0.5rem;
  left: -0.5rem
}

[dir='ltr'] .ltr\:-inset-6,[dir='ltr'].ltr\:-inset-6 {
  top: -0.6rem;
  right: -0.6rem;
  bottom: -0.6rem;
  left: -0.6rem
}

[dir='ltr'] .ltr\:-inset-7,[dir='ltr'].ltr\:-inset-7 {
  top: -0.7rem;
  right: -0.7rem;
  bottom: -0.7rem;
  left: -0.7rem
}

[dir='ltr'] .ltr\:-inset-8,[dir='ltr'].ltr\:-inset-8 {
  top: -0.8rem;
  right: -0.8rem;
  bottom: -0.8rem;
  left: -0.8rem
}

[dir='ltr'] .ltr\:-inset-9,[dir='ltr'].ltr\:-inset-9 {
  top: -0.9rem;
  right: -0.9rem;
  bottom: -0.9rem;
  left: -0.9rem
}

[dir='ltr'] .ltr\:-inset-10,[dir='ltr'].ltr\:-inset-10 {
  top: -1rem;
  right: -1rem;
  bottom: -1rem;
  left: -1rem
}

[dir='ltr'] .ltr\:-inset-12,[dir='ltr'].ltr\:-inset-12 {
  top: -1.2rem;
  right: -1.2rem;
  bottom: -1.2rem;
  left: -1.2rem
}

[dir='ltr'] .ltr\:-inset-14,[dir='ltr'].ltr\:-inset-14 {
  top: -1.4rem;
  right: -1.4rem;
  bottom: -1.4rem;
  left: -1.4rem
}

[dir='ltr'] .ltr\:-inset-16,[dir='ltr'].ltr\:-inset-16 {
  top: -1.6rem;
  right: -1.6rem;
  bottom: -1.6rem;
  left: -1.6rem
}

[dir='ltr'] .ltr\:-inset-20,[dir='ltr'].ltr\:-inset-20 {
  top: -2rem;
  right: -2rem;
  bottom: -2rem;
  left: -2rem
}

[dir='ltr'] .ltr\:-inset-24,[dir='ltr'].ltr\:-inset-24 {
  top: -2.4rem;
  right: -2.4rem;
  bottom: -2.4rem;
  left: -2.4rem
}

[dir='ltr'] .ltr\:-inset-28,[dir='ltr'].ltr\:-inset-28 {
  top: -2.8rem;
  right: -2.8rem;
  bottom: -2.8rem;
  left: -2.8rem
}

[dir='ltr'] .ltr\:-inset-32,[dir='ltr'].ltr\:-inset-32 {
  top: -3.2rem;
  right: -3.2rem;
  bottom: -3.2rem;
  left: -3.2rem
}

[dir='ltr'] .ltr\:-inset-36,[dir='ltr'].ltr\:-inset-36 {
  top: -3.6rem;
  right: -3.6rem;
  bottom: -3.6rem;
  left: -3.6rem
}

[dir='ltr'] .ltr\:-inset-40,[dir='ltr'].ltr\:-inset-40 {
  top: -4rem;
  right: -4rem;
  bottom: -4rem;
  left: -4rem
}

[dir='ltr'] .ltr\:-inset-44,[dir='ltr'].ltr\:-inset-44 {
  top: -4.4rem;
  right: -4.4rem;
  bottom: -4.4rem;
  left: -4.4rem
}

[dir='ltr'] .ltr\:-inset-48,[dir='ltr'].ltr\:-inset-48 {
  top: -4.8rem;
  right: -4.8rem;
  bottom: -4.8rem;
  left: -4.8rem
}

[dir='ltr'] .ltr\:-inset-52,[dir='ltr'].ltr\:-inset-52 {
  top: -5.2rem;
  right: -5.2rem;
  bottom: -5.2rem;
  left: -5.2rem
}

[dir='ltr'] .ltr\:-inset-56,[dir='ltr'].ltr\:-inset-56 {
  top: -5.6rem;
  right: -5.6rem;
  bottom: -5.6rem;
  left: -5.6rem
}

[dir='ltr'] .ltr\:-inset-60,[dir='ltr'].ltr\:-inset-60 {
  top: -6rem;
  right: -6rem;
  bottom: -6rem;
  left: -6rem
}

[dir='ltr'] .ltr\:-inset-64,[dir='ltr'].ltr\:-inset-64 {
  top: -6.4rem;
  right: -6.4rem;
  bottom: -6.4rem;
  left: -6.4rem
}

[dir='ltr'] .ltr\:-inset-68,[dir='ltr'].ltr\:-inset-68 {
  top: -6.8rem;
  right: -6.8rem;
  bottom: -6.8rem;
  left: -6.8rem
}

[dir='ltr'] .ltr\:-inset-72,[dir='ltr'].ltr\:-inset-72 {
  top: -7.2rem;
  right: -7.2rem;
  bottom: -7.2rem;
  left: -7.2rem
}

[dir='ltr'] .ltr\:-inset-76,[dir='ltr'].ltr\:-inset-76 {
  top: -7.6rem;
  right: -7.6rem;
  bottom: -7.6rem;
  left: -7.6rem
}

[dir='ltr'] .ltr\:-inset-80,[dir='ltr'].ltr\:-inset-80 {
  top: -8rem;
  right: -8rem;
  bottom: -8rem;
  left: -8rem
}

[dir='ltr'] .ltr\:-inset-84,[dir='ltr'].ltr\:-inset-84 {
  top: -8.4rem;
  right: -8.4rem;
  bottom: -8.4rem;
  left: -8.4rem
}

[dir='ltr'] .ltr\:-inset-88,[dir='ltr'].ltr\:-inset-88 {
  top: -8.8rem;
  right: -8.8rem;
  bottom: -8.8rem;
  left: -8.8rem
}

[dir='ltr'] .ltr\:-inset-92,[dir='ltr'].ltr\:-inset-92 {
  top: -9.2rem;
  right: -9.2rem;
  bottom: -9.2rem;
  left: -9.2rem
}

[dir='ltr'] .ltr\:-inset-96,[dir='ltr'].ltr\:-inset-96 {
  top: -9.6rem;
  right: -9.6rem;
  bottom: -9.6rem;
  left: -9.6rem
}

[dir='ltr'] .ltr\:-inset-128,[dir='ltr'].ltr\:-inset-128 {
  top: -12.8rem;
  right: -12.8rem;
  bottom: -12.8rem;
  left: -12.8rem
}

[dir='ltr'] .ltr\:-inset-136,[dir='ltr'].ltr\:-inset-136 {
  top: -13.6rem;
  right: -13.6rem;
  bottom: -13.6rem;
  left: -13.6rem
}

[dir='ltr'] .ltr\:-inset-160,[dir='ltr'].ltr\:-inset-160 {
  top: -16rem;
  right: -16rem;
  bottom: -16rem;
  left: -16rem
}

[dir='ltr'] .ltr\:-inset-192,[dir='ltr'].ltr\:-inset-192 {
  top: -19.2rem;
  right: -19.2rem;
  bottom: -19.2rem;
  left: -19.2rem
}

[dir='ltr'] .ltr\:-inset-200,[dir='ltr'].ltr\:-inset-200 {
  top: -20rem;
  right: -20rem;
  bottom: -20rem;
  left: -20rem
}

[dir='ltr'] .ltr\:-inset-208,[dir='ltr'].ltr\:-inset-208 {
  top: -20.8rem;
  right: -20.8rem;
  bottom: -20.8rem;
  left: -20.8rem
}

[dir='ltr'] .ltr\:-inset-216,[dir='ltr'].ltr\:-inset-216 {
  top: -21.6rem;
  right: -21.6rem;
  bottom: -21.6rem;
  left: -21.6rem
}

[dir='ltr'] .ltr\:-inset-224,[dir='ltr'].ltr\:-inset-224 {
  top: -22.4rem;
  right: -22.4rem;
  bottom: -22.4rem;
  left: -22.4rem
}

[dir='ltr'] .ltr\:-inset-256,[dir='ltr'].ltr\:-inset-256 {
  top: -25.6rem;
  right: -25.6rem;
  bottom: -25.6rem;
  left: -25.6rem
}

[dir='ltr'] .ltr\:-inset-288,[dir='ltr'].ltr\:-inset-288 {
  top: -28.8rem;
  right: -28.8rem;
  bottom: -28.8rem;
  left: -28.8rem
}

[dir='ltr'] .ltr\:-inset-320,[dir='ltr'].ltr\:-inset-320 {
  top: -32rem;
  right: -32rem;
  bottom: -32rem;
  left: -32rem
}

[dir='ltr'] .ltr\:-inset-360,[dir='ltr'].ltr\:-inset-360 {
  top: -36rem;
  right: -36rem;
  bottom: -36rem;
  left: -36rem
}

[dir='ltr'] .ltr\:-inset-384,[dir='ltr'].ltr\:-inset-384 {
  top: -38.4rem;
  right: -38.4rem;
  bottom: -38.4rem;
  left: -38.4rem
}

[dir='ltr'] .ltr\:-inset-400,[dir='ltr'].ltr\:-inset-400 {
  top: -40rem;
  right: -40rem;
  bottom: -40rem;
  left: -40rem
}

[dir='ltr'] .ltr\:-inset-512,[dir='ltr'].ltr\:-inset-512 {
  top: -51.2rem;
  right: -51.2rem;
  bottom: -51.2rem;
  left: -51.2rem
}

[dir='ltr'] .ltr\:-inset-640,[dir='ltr'].ltr\:-inset-640 {
  top: -64rem;
  right: -64rem;
  bottom: -64rem;
  left: -64rem
}

[dir='ltr'] .ltr\:-inset-xs,[dir='ltr'].ltr\:-inset-xs {
  top: -32rem;
  right: -32rem;
  bottom: -32rem;
  left: -32rem
}

[dir='ltr'] .ltr\:-inset-sm,[dir='ltr'].ltr\:-inset-sm {
  top: -48rem;
  right: -48rem;
  bottom: -48rem;
  left: -48rem
}

[dir='ltr'] .ltr\:-inset-md,[dir='ltr'].ltr\:-inset-md {
  top: -64rem;
  right: -64rem;
  bottom: -64rem;
  left: -64rem
}

[dir='ltr'] .ltr\:-inset-lg,[dir='ltr'].ltr\:-inset-lg {
  top: -80rem;
  right: -80rem;
  bottom: -80rem;
  left: -80rem
}

[dir='ltr'] .ltr\:-inset-xl,[dir='ltr'].ltr\:-inset-xl {
  top: -96rem;
  right: -96rem;
  bottom: -96rem;
  left: -96rem
}

[dir='ltr'] .ltr\:-inset-2xl,[dir='ltr'].ltr\:-inset-2xl {
  top: -112rem;
  right: -112rem;
  bottom: -112rem;
  left: -112rem
}

[dir='ltr'] .ltr\:-inset-3xl,[dir='ltr'].ltr\:-inset-3xl {
  top: -128rem;
  right: -128rem;
  bottom: -128rem;
  left: -128rem
}

[dir='ltr'] .ltr\:-inset-4xl,[dir='ltr'].ltr\:-inset-4xl {
  top: -144rem;
  right: -144rem;
  bottom: -144rem;
  left: -144rem
}

[dir='ltr'] .ltr\:-inset-5xl,[dir='ltr'].ltr\:-inset-5xl {
  top: -160rem;
  right: -160rem;
  bottom: -160rem;
  left: -160rem
}

[dir='ltr'] .ltr\:-inset-px,[dir='ltr'].ltr\:-inset-px {
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px
}

[dir='ltr'] .ltr\:-inset-0\.5,[dir='ltr'].ltr\:-inset-0\.5 {
  top: -0.05rem;
  right: -0.05rem;
  bottom: -0.05rem;
  left: -0.05rem
}

[dir='ltr'] .ltr\:-inset-1\.5,[dir='ltr'].ltr\:-inset-1\.5 {
  top: -0.15rem;
  right: -0.15rem;
  bottom: -0.15rem;
  left: -0.15rem
}

[dir='ltr'] .ltr\:-inset-2\.5,[dir='ltr'].ltr\:-inset-2\.5 {
  top: -0.25rem;
  right: -0.25rem;
  bottom: -0.25rem;
  left: -0.25rem
}

[dir='ltr'] .ltr\:-inset-3\.5,[dir='ltr'].ltr\:-inset-3\.5 {
  top: -0.35rem;
  right: -0.35rem;
  bottom: -0.35rem;
  left: -0.35rem
}

[dir='ltr'] .ltr\:inset-1\/2,[dir='ltr'].ltr\:inset-1\/2 {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%
}

[dir='ltr'] .ltr\:inset-1\/3,[dir='ltr'].ltr\:inset-1\/3 {
  top: 33.333333%;
  right: 33.333333%;
  bottom: 33.333333%;
  left: 33.333333%
}

[dir='ltr'] .ltr\:inset-2\/3,[dir='ltr'].ltr\:inset-2\/3 {
  top: 66.666667%;
  right: 66.666667%;
  bottom: 66.666667%;
  left: 66.666667%
}

[dir='ltr'] .ltr\:inset-1\/4,[dir='ltr'].ltr\:inset-1\/4 {
  top: 25%;
  right: 25%;
  bottom: 25%;
  left: 25%
}

[dir='ltr'] .ltr\:inset-2\/4,[dir='ltr'].ltr\:inset-2\/4 {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%
}

[dir='ltr'] .ltr\:inset-3\/4,[dir='ltr'].ltr\:inset-3\/4 {
  top: 75%;
  right: 75%;
  bottom: 75%;
  left: 75%
}

[dir='ltr'] .ltr\:inset-full,[dir='ltr'].ltr\:inset-full {
  top: 100%;
  right: 100%;
  bottom: 100%;
  left: 100%
}

[dir='ltr'] .ltr\:-inset-1\/2,[dir='ltr'].ltr\:-inset-1\/2 {
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%
}

[dir='ltr'] .ltr\:-inset-1\/3,[dir='ltr'].ltr\:-inset-1\/3 {
  top: -33.333333%;
  right: -33.333333%;
  bottom: -33.333333%;
  left: -33.333333%
}

[dir='ltr'] .ltr\:-inset-2\/3,[dir='ltr'].ltr\:-inset-2\/3 {
  top: -66.666667%;
  right: -66.666667%;
  bottom: -66.666667%;
  left: -66.666667%
}

[dir='ltr'] .ltr\:-inset-1\/4,[dir='ltr'].ltr\:-inset-1\/4 {
  top: -25%;
  right: -25%;
  bottom: -25%;
  left: -25%
}

[dir='ltr'] .ltr\:-inset-2\/4,[dir='ltr'].ltr\:-inset-2\/4 {
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%
}

[dir='ltr'] .ltr\:-inset-3\/4,[dir='ltr'].ltr\:-inset-3\/4 {
  top: -75%;
  right: -75%;
  bottom: -75%;
  left: -75%
}

[dir='ltr'] .ltr\:-inset-full,[dir='ltr'].ltr\:-inset-full {
  top: -100%;
  right: -100%;
  bottom: -100%;
  left: -100%
}

[dir='ltr'] .ltr\:inset-y-0,[dir='ltr'].ltr\:inset-y-0 {
  top: 0;
  bottom: 0
}

[dir='ltr'] .ltr\:inset-x-0,[dir='ltr'].ltr\:inset-x-0 {
  right: 0;
  left: 0
}

[dir='ltr'] .ltr\:inset-y-1,[dir='ltr'].ltr\:inset-y-1 {
  top: 0.1rem;
  bottom: 0.1rem
}

[dir='ltr'] .ltr\:inset-x-1,[dir='ltr'].ltr\:inset-x-1 {
  right: 0.1rem;
  left: 0.1rem
}

[dir='ltr'] .ltr\:inset-y-2,[dir='ltr'].ltr\:inset-y-2 {
  top: 0.2rem;
  bottom: 0.2rem
}

[dir='ltr'] .ltr\:inset-x-2,[dir='ltr'].ltr\:inset-x-2 {
  right: 0.2rem;
  left: 0.2rem
}

[dir='ltr'] .ltr\:inset-y-3,[dir='ltr'].ltr\:inset-y-3 {
  top: 0.3rem;
  bottom: 0.3rem
}

[dir='ltr'] .ltr\:inset-x-3,[dir='ltr'].ltr\:inset-x-3 {
  right: 0.3rem;
  left: 0.3rem
}

[dir='ltr'] .ltr\:inset-y-4,[dir='ltr'].ltr\:inset-y-4 {
  top: 0.4rem;
  bottom: 0.4rem
}

[dir='ltr'] .ltr\:inset-x-4,[dir='ltr'].ltr\:inset-x-4 {
  right: 0.4rem;
  left: 0.4rem
}

[dir='ltr'] .ltr\:inset-y-5,[dir='ltr'].ltr\:inset-y-5 {
  top: 0.5rem;
  bottom: 0.5rem
}

[dir='ltr'] .ltr\:inset-x-5,[dir='ltr'].ltr\:inset-x-5 {
  right: 0.5rem;
  left: 0.5rem
}

[dir='ltr'] .ltr\:inset-y-6,[dir='ltr'].ltr\:inset-y-6 {
  top: 0.6rem;
  bottom: 0.6rem
}

[dir='ltr'] .ltr\:inset-x-6,[dir='ltr'].ltr\:inset-x-6 {
  right: 0.6rem;
  left: 0.6rem
}

[dir='ltr'] .ltr\:inset-y-7,[dir='ltr'].ltr\:inset-y-7 {
  top: 0.7rem;
  bottom: 0.7rem
}

[dir='ltr'] .ltr\:inset-x-7,[dir='ltr'].ltr\:inset-x-7 {
  right: 0.7rem;
  left: 0.7rem
}

[dir='ltr'] .ltr\:inset-y-8,[dir='ltr'].ltr\:inset-y-8 {
  top: 0.8rem;
  bottom: 0.8rem
}

[dir='ltr'] .ltr\:inset-x-8,[dir='ltr'].ltr\:inset-x-8 {
  right: 0.8rem;
  left: 0.8rem
}

[dir='ltr'] .ltr\:inset-y-9,[dir='ltr'].ltr\:inset-y-9 {
  top: 0.9rem;
  bottom: 0.9rem
}

[dir='ltr'] .ltr\:inset-x-9,[dir='ltr'].ltr\:inset-x-9 {
  right: 0.9rem;
  left: 0.9rem
}

[dir='ltr'] .ltr\:inset-y-10,[dir='ltr'].ltr\:inset-y-10 {
  top: 1.0rem;
  bottom: 1.0rem
}

[dir='ltr'] .ltr\:inset-x-10,[dir='ltr'].ltr\:inset-x-10 {
  right: 1.0rem;
  left: 1.0rem
}

[dir='ltr'] .ltr\:inset-y-12,[dir='ltr'].ltr\:inset-y-12 {
  top: 1.2rem;
  bottom: 1.2rem
}

[dir='ltr'] .ltr\:inset-x-12,[dir='ltr'].ltr\:inset-x-12 {
  right: 1.2rem;
  left: 1.2rem
}

[dir='ltr'] .ltr\:inset-y-14,[dir='ltr'].ltr\:inset-y-14 {
  top: 1.4rem;
  bottom: 1.4rem
}

[dir='ltr'] .ltr\:inset-x-14,[dir='ltr'].ltr\:inset-x-14 {
  right: 1.4rem;
  left: 1.4rem
}

[dir='ltr'] .ltr\:inset-y-16,[dir='ltr'].ltr\:inset-y-16 {
  top: 1.6rem;
  bottom: 1.6rem
}

[dir='ltr'] .ltr\:inset-x-16,[dir='ltr'].ltr\:inset-x-16 {
  right: 1.6rem;
  left: 1.6rem
}

[dir='ltr'] .ltr\:inset-y-20,[dir='ltr'].ltr\:inset-y-20 {
  top: 2rem;
  bottom: 2rem
}

[dir='ltr'] .ltr\:inset-x-20,[dir='ltr'].ltr\:inset-x-20 {
  right: 2rem;
  left: 2rem
}

[dir='ltr'] .ltr\:inset-y-24,[dir='ltr'].ltr\:inset-y-24 {
  top: 2.4rem;
  bottom: 2.4rem
}

[dir='ltr'] .ltr\:inset-x-24,[dir='ltr'].ltr\:inset-x-24 {
  right: 2.4rem;
  left: 2.4rem
}

[dir='ltr'] .ltr\:inset-y-28,[dir='ltr'].ltr\:inset-y-28 {
  top: 2.8rem;
  bottom: 2.8rem
}

[dir='ltr'] .ltr\:inset-x-28,[dir='ltr'].ltr\:inset-x-28 {
  right: 2.8rem;
  left: 2.8rem
}

[dir='ltr'] .ltr\:inset-y-32,[dir='ltr'].ltr\:inset-y-32 {
  top: 3.2rem;
  bottom: 3.2rem
}

[dir='ltr'] .ltr\:inset-x-32,[dir='ltr'].ltr\:inset-x-32 {
  right: 3.2rem;
  left: 3.2rem
}

[dir='ltr'] .ltr\:inset-y-36,[dir='ltr'].ltr\:inset-y-36 {
  top: 3.6rem;
  bottom: 3.6rem
}

[dir='ltr'] .ltr\:inset-x-36,[dir='ltr'].ltr\:inset-x-36 {
  right: 3.6rem;
  left: 3.6rem
}

[dir='ltr'] .ltr\:inset-y-40,[dir='ltr'].ltr\:inset-y-40 {
  top: 4rem;
  bottom: 4rem
}

[dir='ltr'] .ltr\:inset-x-40,[dir='ltr'].ltr\:inset-x-40 {
  right: 4rem;
  left: 4rem
}

[dir='ltr'] .ltr\:inset-y-44,[dir='ltr'].ltr\:inset-y-44 {
  top: 4.4rem;
  bottom: 4.4rem
}

[dir='ltr'] .ltr\:inset-x-44,[dir='ltr'].ltr\:inset-x-44 {
  right: 4.4rem;
  left: 4.4rem
}

[dir='ltr'] .ltr\:inset-y-48,[dir='ltr'].ltr\:inset-y-48 {
  top: 4.8rem;
  bottom: 4.8rem
}

[dir='ltr'] .ltr\:inset-x-48,[dir='ltr'].ltr\:inset-x-48 {
  right: 4.8rem;
  left: 4.8rem
}

[dir='ltr'] .ltr\:inset-y-52,[dir='ltr'].ltr\:inset-y-52 {
  top: 5.2rem;
  bottom: 5.2rem
}

[dir='ltr'] .ltr\:inset-x-52,[dir='ltr'].ltr\:inset-x-52 {
  right: 5.2rem;
  left: 5.2rem
}

[dir='ltr'] .ltr\:inset-y-56,[dir='ltr'].ltr\:inset-y-56 {
  top: 5.6rem;
  bottom: 5.6rem
}

[dir='ltr'] .ltr\:inset-x-56,[dir='ltr'].ltr\:inset-x-56 {
  right: 5.6rem;
  left: 5.6rem
}

[dir='ltr'] .ltr\:inset-y-60,[dir='ltr'].ltr\:inset-y-60 {
  top: 6rem;
  bottom: 6rem
}

[dir='ltr'] .ltr\:inset-x-60,[dir='ltr'].ltr\:inset-x-60 {
  right: 6rem;
  left: 6rem
}

[dir='ltr'] .ltr\:inset-y-64,[dir='ltr'].ltr\:inset-y-64 {
  top: 6.4rem;
  bottom: 6.4rem
}

[dir='ltr'] .ltr\:inset-x-64,[dir='ltr'].ltr\:inset-x-64 {
  right: 6.4rem;
  left: 6.4rem
}

[dir='ltr'] .ltr\:inset-y-68,[dir='ltr'].ltr\:inset-y-68 {
  top: 6.8rem;
  bottom: 6.8rem
}

[dir='ltr'] .ltr\:inset-x-68,[dir='ltr'].ltr\:inset-x-68 {
  right: 6.8rem;
  left: 6.8rem
}

[dir='ltr'] .ltr\:inset-y-72,[dir='ltr'].ltr\:inset-y-72 {
  top: 7.2rem;
  bottom: 7.2rem
}

[dir='ltr'] .ltr\:inset-x-72,[dir='ltr'].ltr\:inset-x-72 {
  right: 7.2rem;
  left: 7.2rem
}

[dir='ltr'] .ltr\:inset-y-76,[dir='ltr'].ltr\:inset-y-76 {
  top: 7.6rem;
  bottom: 7.6rem
}

[dir='ltr'] .ltr\:inset-x-76,[dir='ltr'].ltr\:inset-x-76 {
  right: 7.6rem;
  left: 7.6rem
}

[dir='ltr'] .ltr\:inset-y-80,[dir='ltr'].ltr\:inset-y-80 {
  top: 8rem;
  bottom: 8rem
}

[dir='ltr'] .ltr\:inset-x-80,[dir='ltr'].ltr\:inset-x-80 {
  right: 8rem;
  left: 8rem
}

[dir='ltr'] .ltr\:inset-y-84,[dir='ltr'].ltr\:inset-y-84 {
  top: 8.4rem;
  bottom: 8.4rem
}

[dir='ltr'] .ltr\:inset-x-84,[dir='ltr'].ltr\:inset-x-84 {
  right: 8.4rem;
  left: 8.4rem
}

[dir='ltr'] .ltr\:inset-y-88,[dir='ltr'].ltr\:inset-y-88 {
  top: 8.8rem;
  bottom: 8.8rem
}

[dir='ltr'] .ltr\:inset-x-88,[dir='ltr'].ltr\:inset-x-88 {
  right: 8.8rem;
  left: 8.8rem
}

[dir='ltr'] .ltr\:inset-y-92,[dir='ltr'].ltr\:inset-y-92 {
  top: 9.2rem;
  bottom: 9.2rem
}

[dir='ltr'] .ltr\:inset-x-92,[dir='ltr'].ltr\:inset-x-92 {
  right: 9.2rem;
  left: 9.2rem
}

[dir='ltr'] .ltr\:inset-y-96,[dir='ltr'].ltr\:inset-y-96 {
  top: 9.6rem;
  bottom: 9.6rem
}

[dir='ltr'] .ltr\:inset-x-96,[dir='ltr'].ltr\:inset-x-96 {
  right: 9.6rem;
  left: 9.6rem
}

[dir='ltr'] .ltr\:inset-y-128,[dir='ltr'].ltr\:inset-y-128 {
  top: 12.8rem;
  bottom: 12.8rem
}

[dir='ltr'] .ltr\:inset-x-128,[dir='ltr'].ltr\:inset-x-128 {
  right: 12.8rem;
  left: 12.8rem
}

[dir='ltr'] .ltr\:inset-y-136,[dir='ltr'].ltr\:inset-y-136 {
  top: 13.6rem;
  bottom: 13.6rem
}

[dir='ltr'] .ltr\:inset-x-136,[dir='ltr'].ltr\:inset-x-136 {
  right: 13.6rem;
  left: 13.6rem
}

[dir='ltr'] .ltr\:inset-y-160,[dir='ltr'].ltr\:inset-y-160 {
  top: 16rem;
  bottom: 16rem
}

[dir='ltr'] .ltr\:inset-x-160,[dir='ltr'].ltr\:inset-x-160 {
  right: 16rem;
  left: 16rem
}

[dir='ltr'] .ltr\:inset-y-192,[dir='ltr'].ltr\:inset-y-192 {
  top: 19.2rem;
  bottom: 19.2rem
}

[dir='ltr'] .ltr\:inset-x-192,[dir='ltr'].ltr\:inset-x-192 {
  right: 19.2rem;
  left: 19.2rem
}

[dir='ltr'] .ltr\:inset-y-200,[dir='ltr'].ltr\:inset-y-200 {
  top: 20rem;
  bottom: 20rem
}

[dir='ltr'] .ltr\:inset-x-200,[dir='ltr'].ltr\:inset-x-200 {
  right: 20rem;
  left: 20rem
}

[dir='ltr'] .ltr\:inset-y-208,[dir='ltr'].ltr\:inset-y-208 {
  top: 20.8rem;
  bottom: 20.8rem
}

[dir='ltr'] .ltr\:inset-x-208,[dir='ltr'].ltr\:inset-x-208 {
  right: 20.8rem;
  left: 20.8rem
}

[dir='ltr'] .ltr\:inset-y-216,[dir='ltr'].ltr\:inset-y-216 {
  top: 21.6rem;
  bottom: 21.6rem
}

[dir='ltr'] .ltr\:inset-x-216,[dir='ltr'].ltr\:inset-x-216 {
  right: 21.6rem;
  left: 21.6rem
}

[dir='ltr'] .ltr\:inset-y-224,[dir='ltr'].ltr\:inset-y-224 {
  top: 22.4rem;
  bottom: 22.4rem
}

[dir='ltr'] .ltr\:inset-x-224,[dir='ltr'].ltr\:inset-x-224 {
  right: 22.4rem;
  left: 22.4rem
}

[dir='ltr'] .ltr\:inset-y-256,[dir='ltr'].ltr\:inset-y-256 {
  top: 25.6rem;
  bottom: 25.6rem
}

[dir='ltr'] .ltr\:inset-x-256,[dir='ltr'].ltr\:inset-x-256 {
  right: 25.6rem;
  left: 25.6rem
}

[dir='ltr'] .ltr\:inset-y-288,[dir='ltr'].ltr\:inset-y-288 {
  top: 28.8rem;
  bottom: 28.8rem
}

[dir='ltr'] .ltr\:inset-x-288,[dir='ltr'].ltr\:inset-x-288 {
  right: 28.8rem;
  left: 28.8rem
}

[dir='ltr'] .ltr\:inset-y-320,[dir='ltr'].ltr\:inset-y-320 {
  top: 32rem;
  bottom: 32rem
}

[dir='ltr'] .ltr\:inset-x-320,[dir='ltr'].ltr\:inset-x-320 {
  right: 32rem;
  left: 32rem
}

[dir='ltr'] .ltr\:inset-y-360,[dir='ltr'].ltr\:inset-y-360 {
  top: 36rem;
  bottom: 36rem
}

[dir='ltr'] .ltr\:inset-x-360,[dir='ltr'].ltr\:inset-x-360 {
  right: 36rem;
  left: 36rem
}

[dir='ltr'] .ltr\:inset-y-384,[dir='ltr'].ltr\:inset-y-384 {
  top: 38.4rem;
  bottom: 38.4rem
}

[dir='ltr'] .ltr\:inset-x-384,[dir='ltr'].ltr\:inset-x-384 {
  right: 38.4rem;
  left: 38.4rem
}

[dir='ltr'] .ltr\:inset-y-400,[dir='ltr'].ltr\:inset-y-400 {
  top: 40rem;
  bottom: 40rem
}

[dir='ltr'] .ltr\:inset-x-400,[dir='ltr'].ltr\:inset-x-400 {
  right: 40rem;
  left: 40rem
}

[dir='ltr'] .ltr\:inset-y-512,[dir='ltr'].ltr\:inset-y-512 {
  top: 51.2rem;
  bottom: 51.2rem
}

[dir='ltr'] .ltr\:inset-x-512,[dir='ltr'].ltr\:inset-x-512 {
  right: 51.2rem;
  left: 51.2rem
}

[dir='ltr'] .ltr\:inset-y-640,[dir='ltr'].ltr\:inset-y-640 {
  top: 64rem;
  bottom: 64rem
}

[dir='ltr'] .ltr\:inset-x-640,[dir='ltr'].ltr\:inset-x-640 {
  right: 64rem;
  left: 64rem
}

[dir='ltr'] .ltr\:inset-y-auto,[dir='ltr'].ltr\:inset-y-auto {
  top: auto;
  bottom: auto
}

[dir='ltr'] .ltr\:inset-x-auto,[dir='ltr'].ltr\:inset-x-auto {
  right: auto;
  left: auto
}

[dir='ltr'] .ltr\:inset-y-xs,[dir='ltr'].ltr\:inset-y-xs {
  top: 32rem;
  bottom: 32rem
}

[dir='ltr'] .ltr\:inset-x-xs,[dir='ltr'].ltr\:inset-x-xs {
  right: 32rem;
  left: 32rem
}

[dir='ltr'] .ltr\:inset-y-sm,[dir='ltr'].ltr\:inset-y-sm {
  top: 48rem;
  bottom: 48rem
}

[dir='ltr'] .ltr\:inset-x-sm,[dir='ltr'].ltr\:inset-x-sm {
  right: 48rem;
  left: 48rem
}

[dir='ltr'] .ltr\:inset-y-md,[dir='ltr'].ltr\:inset-y-md {
  top: 64rem;
  bottom: 64rem
}

[dir='ltr'] .ltr\:inset-x-md,[dir='ltr'].ltr\:inset-x-md {
  right: 64rem;
  left: 64rem
}

[dir='ltr'] .ltr\:inset-y-lg,[dir='ltr'].ltr\:inset-y-lg {
  top: 80rem;
  bottom: 80rem
}

[dir='ltr'] .ltr\:inset-x-lg,[dir='ltr'].ltr\:inset-x-lg {
  right: 80rem;
  left: 80rem
}

[dir='ltr'] .ltr\:inset-y-xl,[dir='ltr'].ltr\:inset-y-xl {
  top: 96rem;
  bottom: 96rem
}

[dir='ltr'] .ltr\:inset-x-xl,[dir='ltr'].ltr\:inset-x-xl {
  right: 96rem;
  left: 96rem
}

[dir='ltr'] .ltr\:inset-y-2xl,[dir='ltr'].ltr\:inset-y-2xl {
  top: 112rem;
  bottom: 112rem
}

[dir='ltr'] .ltr\:inset-x-2xl,[dir='ltr'].ltr\:inset-x-2xl {
  right: 112rem;
  left: 112rem
}

[dir='ltr'] .ltr\:inset-y-3xl,[dir='ltr'].ltr\:inset-y-3xl {
  top: 128rem;
  bottom: 128rem
}

[dir='ltr'] .ltr\:inset-x-3xl,[dir='ltr'].ltr\:inset-x-3xl {
  right: 128rem;
  left: 128rem
}

[dir='ltr'] .ltr\:inset-y-4xl,[dir='ltr'].ltr\:inset-y-4xl {
  top: 144rem;
  bottom: 144rem
}

[dir='ltr'] .ltr\:inset-x-4xl,[dir='ltr'].ltr\:inset-x-4xl {
  right: 144rem;
  left: 144rem
}

[dir='ltr'] .ltr\:inset-y-5xl,[dir='ltr'].ltr\:inset-y-5xl {
  top: 160rem;
  bottom: 160rem
}

[dir='ltr'] .ltr\:inset-x-5xl,[dir='ltr'].ltr\:inset-x-5xl {
  right: 160rem;
  left: 160rem
}

[dir='ltr'] .ltr\:inset-y-px,[dir='ltr'].ltr\:inset-y-px {
  top: 1px;
  bottom: 1px
}

[dir='ltr'] .ltr\:inset-x-px,[dir='ltr'].ltr\:inset-x-px {
  right: 1px;
  left: 1px
}

[dir='ltr'] .ltr\:inset-y-0\.5,[dir='ltr'].ltr\:inset-y-0\.5 {
  top: 0.05rem;
  bottom: 0.05rem
}

[dir='ltr'] .ltr\:inset-x-0\.5,[dir='ltr'].ltr\:inset-x-0\.5 {
  right: 0.05rem;
  left: 0.05rem
}

[dir='ltr'] .ltr\:inset-y-1\.5,[dir='ltr'].ltr\:inset-y-1\.5 {
  top: 0.15rem;
  bottom: 0.15rem
}

[dir='ltr'] .ltr\:inset-x-1\.5,[dir='ltr'].ltr\:inset-x-1\.5 {
  right: 0.15rem;
  left: 0.15rem
}

[dir='ltr'] .ltr\:inset-y-2\.5,[dir='ltr'].ltr\:inset-y-2\.5 {
  top: 0.25rem;
  bottom: 0.25rem
}

[dir='ltr'] .ltr\:inset-x-2\.5,[dir='ltr'].ltr\:inset-x-2\.5 {
  right: 0.25rem;
  left: 0.25rem
}

[dir='ltr'] .ltr\:inset-y-3\.5,[dir='ltr'].ltr\:inset-y-3\.5 {
  top: 0.35rem;
  bottom: 0.35rem
}

[dir='ltr'] .ltr\:inset-x-3\.5,[dir='ltr'].ltr\:inset-x-3\.5 {
  right: 0.35rem;
  left: 0.35rem
}

[dir='ltr'] .ltr\:-inset-y-1,[dir='ltr'].ltr\:-inset-y-1 {
  top: -0.1rem;
  bottom: -0.1rem
}

[dir='ltr'] .ltr\:-inset-x-1,[dir='ltr'].ltr\:-inset-x-1 {
  right: -0.1rem;
  left: -0.1rem
}

[dir='ltr'] .ltr\:-inset-y-2,[dir='ltr'].ltr\:-inset-y-2 {
  top: -0.2rem;
  bottom: -0.2rem
}

[dir='ltr'] .ltr\:-inset-x-2,[dir='ltr'].ltr\:-inset-x-2 {
  right: -0.2rem;
  left: -0.2rem
}

[dir='ltr'] .ltr\:-inset-y-3,[dir='ltr'].ltr\:-inset-y-3 {
  top: -0.3rem;
  bottom: -0.3rem
}

[dir='ltr'] .ltr\:-inset-x-3,[dir='ltr'].ltr\:-inset-x-3 {
  right: -0.3rem;
  left: -0.3rem
}

[dir='ltr'] .ltr\:-inset-y-4,[dir='ltr'].ltr\:-inset-y-4 {
  top: -0.4rem;
  bottom: -0.4rem
}

[dir='ltr'] .ltr\:-inset-x-4,[dir='ltr'].ltr\:-inset-x-4 {
  right: -0.4rem;
  left: -0.4rem
}

[dir='ltr'] .ltr\:-inset-y-5,[dir='ltr'].ltr\:-inset-y-5 {
  top: -0.5rem;
  bottom: -0.5rem
}

[dir='ltr'] .ltr\:-inset-x-5,[dir='ltr'].ltr\:-inset-x-5 {
  right: -0.5rem;
  left: -0.5rem
}

[dir='ltr'] .ltr\:-inset-y-6,[dir='ltr'].ltr\:-inset-y-6 {
  top: -0.6rem;
  bottom: -0.6rem
}

[dir='ltr'] .ltr\:-inset-x-6,[dir='ltr'].ltr\:-inset-x-6 {
  right: -0.6rem;
  left: -0.6rem
}

[dir='ltr'] .ltr\:-inset-y-7,[dir='ltr'].ltr\:-inset-y-7 {
  top: -0.7rem;
  bottom: -0.7rem
}

[dir='ltr'] .ltr\:-inset-x-7,[dir='ltr'].ltr\:-inset-x-7 {
  right: -0.7rem;
  left: -0.7rem
}

[dir='ltr'] .ltr\:-inset-y-8,[dir='ltr'].ltr\:-inset-y-8 {
  top: -0.8rem;
  bottom: -0.8rem
}

[dir='ltr'] .ltr\:-inset-x-8,[dir='ltr'].ltr\:-inset-x-8 {
  right: -0.8rem;
  left: -0.8rem
}

[dir='ltr'] .ltr\:-inset-y-9,[dir='ltr'].ltr\:-inset-y-9 {
  top: -0.9rem;
  bottom: -0.9rem
}

[dir='ltr'] .ltr\:-inset-x-9,[dir='ltr'].ltr\:-inset-x-9 {
  right: -0.9rem;
  left: -0.9rem
}

[dir='ltr'] .ltr\:-inset-y-10,[dir='ltr'].ltr\:-inset-y-10 {
  top: -1rem;
  bottom: -1rem
}

[dir='ltr'] .ltr\:-inset-x-10,[dir='ltr'].ltr\:-inset-x-10 {
  right: -1rem;
  left: -1rem
}

[dir='ltr'] .ltr\:-inset-y-12,[dir='ltr'].ltr\:-inset-y-12 {
  top: -1.2rem;
  bottom: -1.2rem
}

[dir='ltr'] .ltr\:-inset-x-12,[dir='ltr'].ltr\:-inset-x-12 {
  right: -1.2rem;
  left: -1.2rem
}

[dir='ltr'] .ltr\:-inset-y-14,[dir='ltr'].ltr\:-inset-y-14 {
  top: -1.4rem;
  bottom: -1.4rem
}

[dir='ltr'] .ltr\:-inset-x-14,[dir='ltr'].ltr\:-inset-x-14 {
  right: -1.4rem;
  left: -1.4rem
}

[dir='ltr'] .ltr\:-inset-y-16,[dir='ltr'].ltr\:-inset-y-16 {
  top: -1.6rem;
  bottom: -1.6rem
}

[dir='ltr'] .ltr\:-inset-x-16,[dir='ltr'].ltr\:-inset-x-16 {
  right: -1.6rem;
  left: -1.6rem
}

[dir='ltr'] .ltr\:-inset-y-20,[dir='ltr'].ltr\:-inset-y-20 {
  top: -2rem;
  bottom: -2rem
}

[dir='ltr'] .ltr\:-inset-x-20,[dir='ltr'].ltr\:-inset-x-20 {
  right: -2rem;
  left: -2rem
}

[dir='ltr'] .ltr\:-inset-y-24,[dir='ltr'].ltr\:-inset-y-24 {
  top: -2.4rem;
  bottom: -2.4rem
}

[dir='ltr'] .ltr\:-inset-x-24,[dir='ltr'].ltr\:-inset-x-24 {
  right: -2.4rem;
  left: -2.4rem
}

[dir='ltr'] .ltr\:-inset-y-28,[dir='ltr'].ltr\:-inset-y-28 {
  top: -2.8rem;
  bottom: -2.8rem
}

[dir='ltr'] .ltr\:-inset-x-28,[dir='ltr'].ltr\:-inset-x-28 {
  right: -2.8rem;
  left: -2.8rem
}

[dir='ltr'] .ltr\:-inset-y-32,[dir='ltr'].ltr\:-inset-y-32 {
  top: -3.2rem;
  bottom: -3.2rem
}

[dir='ltr'] .ltr\:-inset-x-32,[dir='ltr'].ltr\:-inset-x-32 {
  right: -3.2rem;
  left: -3.2rem
}

[dir='ltr'] .ltr\:-inset-y-36,[dir='ltr'].ltr\:-inset-y-36 {
  top: -3.6rem;
  bottom: -3.6rem
}

[dir='ltr'] .ltr\:-inset-x-36,[dir='ltr'].ltr\:-inset-x-36 {
  right: -3.6rem;
  left: -3.6rem
}

[dir='ltr'] .ltr\:-inset-y-40,[dir='ltr'].ltr\:-inset-y-40 {
  top: -4rem;
  bottom: -4rem
}

[dir='ltr'] .ltr\:-inset-x-40,[dir='ltr'].ltr\:-inset-x-40 {
  right: -4rem;
  left: -4rem
}

[dir='ltr'] .ltr\:-inset-y-44,[dir='ltr'].ltr\:-inset-y-44 {
  top: -4.4rem;
  bottom: -4.4rem
}

[dir='ltr'] .ltr\:-inset-x-44,[dir='ltr'].ltr\:-inset-x-44 {
  right: -4.4rem;
  left: -4.4rem
}

[dir='ltr'] .ltr\:-inset-y-48,[dir='ltr'].ltr\:-inset-y-48 {
  top: -4.8rem;
  bottom: -4.8rem
}

[dir='ltr'] .ltr\:-inset-x-48,[dir='ltr'].ltr\:-inset-x-48 {
  right: -4.8rem;
  left: -4.8rem
}

[dir='ltr'] .ltr\:-inset-y-52,[dir='ltr'].ltr\:-inset-y-52 {
  top: -5.2rem;
  bottom: -5.2rem
}

[dir='ltr'] .ltr\:-inset-x-52,[dir='ltr'].ltr\:-inset-x-52 {
  right: -5.2rem;
  left: -5.2rem
}

[dir='ltr'] .ltr\:-inset-y-56,[dir='ltr'].ltr\:-inset-y-56 {
  top: -5.6rem;
  bottom: -5.6rem
}

[dir='ltr'] .ltr\:-inset-x-56,[dir='ltr'].ltr\:-inset-x-56 {
  right: -5.6rem;
  left: -5.6rem
}

[dir='ltr'] .ltr\:-inset-y-60,[dir='ltr'].ltr\:-inset-y-60 {
  top: -6rem;
  bottom: -6rem
}

[dir='ltr'] .ltr\:-inset-x-60,[dir='ltr'].ltr\:-inset-x-60 {
  right: -6rem;
  left: -6rem
}

[dir='ltr'] .ltr\:-inset-y-64,[dir='ltr'].ltr\:-inset-y-64 {
  top: -6.4rem;
  bottom: -6.4rem
}

[dir='ltr'] .ltr\:-inset-x-64,[dir='ltr'].ltr\:-inset-x-64 {
  right: -6.4rem;
  left: -6.4rem
}

[dir='ltr'] .ltr\:-inset-y-68,[dir='ltr'].ltr\:-inset-y-68 {
  top: -6.8rem;
  bottom: -6.8rem
}

[dir='ltr'] .ltr\:-inset-x-68,[dir='ltr'].ltr\:-inset-x-68 {
  right: -6.8rem;
  left: -6.8rem
}

[dir='ltr'] .ltr\:-inset-y-72,[dir='ltr'].ltr\:-inset-y-72 {
  top: -7.2rem;
  bottom: -7.2rem
}

[dir='ltr'] .ltr\:-inset-x-72,[dir='ltr'].ltr\:-inset-x-72 {
  right: -7.2rem;
  left: -7.2rem
}

[dir='ltr'] .ltr\:-inset-y-76,[dir='ltr'].ltr\:-inset-y-76 {
  top: -7.6rem;
  bottom: -7.6rem
}

[dir='ltr'] .ltr\:-inset-x-76,[dir='ltr'].ltr\:-inset-x-76 {
  right: -7.6rem;
  left: -7.6rem
}

[dir='ltr'] .ltr\:-inset-y-80,[dir='ltr'].ltr\:-inset-y-80 {
  top: -8rem;
  bottom: -8rem
}

[dir='ltr'] .ltr\:-inset-x-80,[dir='ltr'].ltr\:-inset-x-80 {
  right: -8rem;
  left: -8rem
}

[dir='ltr'] .ltr\:-inset-y-84,[dir='ltr'].ltr\:-inset-y-84 {
  top: -8.4rem;
  bottom: -8.4rem
}

[dir='ltr'] .ltr\:-inset-x-84,[dir='ltr'].ltr\:-inset-x-84 {
  right: -8.4rem;
  left: -8.4rem
}

[dir='ltr'] .ltr\:-inset-y-88,[dir='ltr'].ltr\:-inset-y-88 {
  top: -8.8rem;
  bottom: -8.8rem
}

[dir='ltr'] .ltr\:-inset-x-88,[dir='ltr'].ltr\:-inset-x-88 {
  right: -8.8rem;
  left: -8.8rem
}

[dir='ltr'] .ltr\:-inset-y-92,[dir='ltr'].ltr\:-inset-y-92 {
  top: -9.2rem;
  bottom: -9.2rem
}

[dir='ltr'] .ltr\:-inset-x-92,[dir='ltr'].ltr\:-inset-x-92 {
  right: -9.2rem;
  left: -9.2rem
}

[dir='ltr'] .ltr\:-inset-y-96,[dir='ltr'].ltr\:-inset-y-96 {
  top: -9.6rem;
  bottom: -9.6rem
}

[dir='ltr'] .ltr\:-inset-x-96,[dir='ltr'].ltr\:-inset-x-96 {
  right: -9.6rem;
  left: -9.6rem
}

[dir='ltr'] .ltr\:-inset-y-128,[dir='ltr'].ltr\:-inset-y-128 {
  top: -12.8rem;
  bottom: -12.8rem
}

[dir='ltr'] .ltr\:-inset-x-128,[dir='ltr'].ltr\:-inset-x-128 {
  right: -12.8rem;
  left: -12.8rem
}

[dir='ltr'] .ltr\:-inset-y-136,[dir='ltr'].ltr\:-inset-y-136 {
  top: -13.6rem;
  bottom: -13.6rem
}

[dir='ltr'] .ltr\:-inset-x-136,[dir='ltr'].ltr\:-inset-x-136 {
  right: -13.6rem;
  left: -13.6rem
}

[dir='ltr'] .ltr\:-inset-y-160,[dir='ltr'].ltr\:-inset-y-160 {
  top: -16rem;
  bottom: -16rem
}

[dir='ltr'] .ltr\:-inset-x-160,[dir='ltr'].ltr\:-inset-x-160 {
  right: -16rem;
  left: -16rem
}

[dir='ltr'] .ltr\:-inset-y-192,[dir='ltr'].ltr\:-inset-y-192 {
  top: -19.2rem;
  bottom: -19.2rem
}

[dir='ltr'] .ltr\:-inset-x-192,[dir='ltr'].ltr\:-inset-x-192 {
  right: -19.2rem;
  left: -19.2rem
}

[dir='ltr'] .ltr\:-inset-y-200,[dir='ltr'].ltr\:-inset-y-200 {
  top: -20rem;
  bottom: -20rem
}

[dir='ltr'] .ltr\:-inset-x-200,[dir='ltr'].ltr\:-inset-x-200 {
  right: -20rem;
  left: -20rem
}

[dir='ltr'] .ltr\:-inset-y-208,[dir='ltr'].ltr\:-inset-y-208 {
  top: -20.8rem;
  bottom: -20.8rem
}

[dir='ltr'] .ltr\:-inset-x-208,[dir='ltr'].ltr\:-inset-x-208 {
  right: -20.8rem;
  left: -20.8rem
}

[dir='ltr'] .ltr\:-inset-y-216,[dir='ltr'].ltr\:-inset-y-216 {
  top: -21.6rem;
  bottom: -21.6rem
}

[dir='ltr'] .ltr\:-inset-x-216,[dir='ltr'].ltr\:-inset-x-216 {
  right: -21.6rem;
  left: -21.6rem
}

[dir='ltr'] .ltr\:-inset-y-224,[dir='ltr'].ltr\:-inset-y-224 {
  top: -22.4rem;
  bottom: -22.4rem
}

[dir='ltr'] .ltr\:-inset-x-224,[dir='ltr'].ltr\:-inset-x-224 {
  right: -22.4rem;
  left: -22.4rem
}

[dir='ltr'] .ltr\:-inset-y-256,[dir='ltr'].ltr\:-inset-y-256 {
  top: -25.6rem;
  bottom: -25.6rem
}

[dir='ltr'] .ltr\:-inset-x-256,[dir='ltr'].ltr\:-inset-x-256 {
  right: -25.6rem;
  left: -25.6rem
}

[dir='ltr'] .ltr\:-inset-y-288,[dir='ltr'].ltr\:-inset-y-288 {
  top: -28.8rem;
  bottom: -28.8rem
}

[dir='ltr'] .ltr\:-inset-x-288,[dir='ltr'].ltr\:-inset-x-288 {
  right: -28.8rem;
  left: -28.8rem
}

[dir='ltr'] .ltr\:-inset-y-320,[dir='ltr'].ltr\:-inset-y-320 {
  top: -32rem;
  bottom: -32rem
}

[dir='ltr'] .ltr\:-inset-x-320,[dir='ltr'].ltr\:-inset-x-320 {
  right: -32rem;
  left: -32rem
}

[dir='ltr'] .ltr\:-inset-y-360,[dir='ltr'].ltr\:-inset-y-360 {
  top: -36rem;
  bottom: -36rem
}

[dir='ltr'] .ltr\:-inset-x-360,[dir='ltr'].ltr\:-inset-x-360 {
  right: -36rem;
  left: -36rem
}

[dir='ltr'] .ltr\:-inset-y-384,[dir='ltr'].ltr\:-inset-y-384 {
  top: -38.4rem;
  bottom: -38.4rem
}

[dir='ltr'] .ltr\:-inset-x-384,[dir='ltr'].ltr\:-inset-x-384 {
  right: -38.4rem;
  left: -38.4rem
}

[dir='ltr'] .ltr\:-inset-y-400,[dir='ltr'].ltr\:-inset-y-400 {
  top: -40rem;
  bottom: -40rem
}

[dir='ltr'] .ltr\:-inset-x-400,[dir='ltr'].ltr\:-inset-x-400 {
  right: -40rem;
  left: -40rem
}

[dir='ltr'] .ltr\:-inset-y-512,[dir='ltr'].ltr\:-inset-y-512 {
  top: -51.2rem;
  bottom: -51.2rem
}

[dir='ltr'] .ltr\:-inset-x-512,[dir='ltr'].ltr\:-inset-x-512 {
  right: -51.2rem;
  left: -51.2rem
}

[dir='ltr'] .ltr\:-inset-y-640,[dir='ltr'].ltr\:-inset-y-640 {
  top: -64rem;
  bottom: -64rem
}

[dir='ltr'] .ltr\:-inset-x-640,[dir='ltr'].ltr\:-inset-x-640 {
  right: -64rem;
  left: -64rem
}

[dir='ltr'] .ltr\:-inset-y-xs,[dir='ltr'].ltr\:-inset-y-xs {
  top: -32rem;
  bottom: -32rem
}

[dir='ltr'] .ltr\:-inset-x-xs,[dir='ltr'].ltr\:-inset-x-xs {
  right: -32rem;
  left: -32rem
}

[dir='ltr'] .ltr\:-inset-y-sm,[dir='ltr'].ltr\:-inset-y-sm {
  top: -48rem;
  bottom: -48rem
}

[dir='ltr'] .ltr\:-inset-x-sm,[dir='ltr'].ltr\:-inset-x-sm {
  right: -48rem;
  left: -48rem
}

[dir='ltr'] .ltr\:-inset-y-md,[dir='ltr'].ltr\:-inset-y-md {
  top: -64rem;
  bottom: -64rem
}

[dir='ltr'] .ltr\:-inset-x-md,[dir='ltr'].ltr\:-inset-x-md {
  right: -64rem;
  left: -64rem
}

[dir='ltr'] .ltr\:-inset-y-lg,[dir='ltr'].ltr\:-inset-y-lg {
  top: -80rem;
  bottom: -80rem
}

[dir='ltr'] .ltr\:-inset-x-lg,[dir='ltr'].ltr\:-inset-x-lg {
  right: -80rem;
  left: -80rem
}

[dir='ltr'] .ltr\:-inset-y-xl,[dir='ltr'].ltr\:-inset-y-xl {
  top: -96rem;
  bottom: -96rem
}

[dir='ltr'] .ltr\:-inset-x-xl,[dir='ltr'].ltr\:-inset-x-xl {
  right: -96rem;
  left: -96rem
}

[dir='ltr'] .ltr\:-inset-y-2xl,[dir='ltr'].ltr\:-inset-y-2xl {
  top: -112rem;
  bottom: -112rem
}

[dir='ltr'] .ltr\:-inset-x-2xl,[dir='ltr'].ltr\:-inset-x-2xl {
  right: -112rem;
  left: -112rem
}

[dir='ltr'] .ltr\:-inset-y-3xl,[dir='ltr'].ltr\:-inset-y-3xl {
  top: -128rem;
  bottom: -128rem
}

[dir='ltr'] .ltr\:-inset-x-3xl,[dir='ltr'].ltr\:-inset-x-3xl {
  right: -128rem;
  left: -128rem
}

[dir='ltr'] .ltr\:-inset-y-4xl,[dir='ltr'].ltr\:-inset-y-4xl {
  top: -144rem;
  bottom: -144rem
}

[dir='ltr'] .ltr\:-inset-x-4xl,[dir='ltr'].ltr\:-inset-x-4xl {
  right: -144rem;
  left: -144rem
}

[dir='ltr'] .ltr\:-inset-y-5xl,[dir='ltr'].ltr\:-inset-y-5xl {
  top: -160rem;
  bottom: -160rem
}

[dir='ltr'] .ltr\:-inset-x-5xl,[dir='ltr'].ltr\:-inset-x-5xl {
  right: -160rem;
  left: -160rem
}

[dir='ltr'] .ltr\:-inset-y-px,[dir='ltr'].ltr\:-inset-y-px {
  top: -1px;
  bottom: -1px
}

[dir='ltr'] .ltr\:-inset-x-px,[dir='ltr'].ltr\:-inset-x-px {
  right: -1px;
  left: -1px
}

[dir='ltr'] .ltr\:-inset-y-0\.5,[dir='ltr'].ltr\:-inset-y-0\.5 {
  top: -0.05rem;
  bottom: -0.05rem
}

[dir='ltr'] .ltr\:-inset-x-0\.5,[dir='ltr'].ltr\:-inset-x-0\.5 {
  right: -0.05rem;
  left: -0.05rem
}

[dir='ltr'] .ltr\:-inset-y-1\.5,[dir='ltr'].ltr\:-inset-y-1\.5 {
  top: -0.15rem;
  bottom: -0.15rem
}

[dir='ltr'] .ltr\:-inset-x-1\.5,[dir='ltr'].ltr\:-inset-x-1\.5 {
  right: -0.15rem;
  left: -0.15rem
}

[dir='ltr'] .ltr\:-inset-y-2\.5,[dir='ltr'].ltr\:-inset-y-2\.5 {
  top: -0.25rem;
  bottom: -0.25rem
}

[dir='ltr'] .ltr\:-inset-x-2\.5,[dir='ltr'].ltr\:-inset-x-2\.5 {
  right: -0.25rem;
  left: -0.25rem
}

[dir='ltr'] .ltr\:-inset-y-3\.5,[dir='ltr'].ltr\:-inset-y-3\.5 {
  top: -0.35rem;
  bottom: -0.35rem
}

[dir='ltr'] .ltr\:-inset-x-3\.5,[dir='ltr'].ltr\:-inset-x-3\.5 {
  right: -0.35rem;
  left: -0.35rem
}

[dir='ltr'] .ltr\:inset-y-1\/2,[dir='ltr'].ltr\:inset-y-1\/2 {
  top: 50%;
  bottom: 50%
}

[dir='ltr'] .ltr\:inset-x-1\/2,[dir='ltr'].ltr\:inset-x-1\/2 {
  right: 50%;
  left: 50%
}

[dir='ltr'] .ltr\:inset-y-1\/3,[dir='ltr'].ltr\:inset-y-1\/3 {
  top: 33.333333%;
  bottom: 33.333333%
}

[dir='ltr'] .ltr\:inset-x-1\/3,[dir='ltr'].ltr\:inset-x-1\/3 {
  right: 33.333333%;
  left: 33.333333%
}

[dir='ltr'] .ltr\:inset-y-2\/3,[dir='ltr'].ltr\:inset-y-2\/3 {
  top: 66.666667%;
  bottom: 66.666667%
}

[dir='ltr'] .ltr\:inset-x-2\/3,[dir='ltr'].ltr\:inset-x-2\/3 {
  right: 66.666667%;
  left: 66.666667%
}

[dir='ltr'] .ltr\:inset-y-1\/4,[dir='ltr'].ltr\:inset-y-1\/4 {
  top: 25%;
  bottom: 25%
}

[dir='ltr'] .ltr\:inset-x-1\/4,[dir='ltr'].ltr\:inset-x-1\/4 {
  right: 25%;
  left: 25%
}

[dir='ltr'] .ltr\:inset-y-2\/4,[dir='ltr'].ltr\:inset-y-2\/4 {
  top: 50%;
  bottom: 50%
}

[dir='ltr'] .ltr\:inset-x-2\/4,[dir='ltr'].ltr\:inset-x-2\/4 {
  right: 50%;
  left: 50%
}

[dir='ltr'] .ltr\:inset-y-3\/4,[dir='ltr'].ltr\:inset-y-3\/4 {
  top: 75%;
  bottom: 75%
}

[dir='ltr'] .ltr\:inset-x-3\/4,[dir='ltr'].ltr\:inset-x-3\/4 {
  right: 75%;
  left: 75%
}

[dir='ltr'] .ltr\:inset-y-full,[dir='ltr'].ltr\:inset-y-full {
  top: 100%;
  bottom: 100%
}

[dir='ltr'] .ltr\:inset-x-full,[dir='ltr'].ltr\:inset-x-full {
  right: 100%;
  left: 100%
}

[dir='ltr'] .ltr\:-inset-y-1\/2,[dir='ltr'].ltr\:-inset-y-1\/2 {
  top: -50%;
  bottom: -50%
}

[dir='ltr'] .ltr\:-inset-x-1\/2,[dir='ltr'].ltr\:-inset-x-1\/2 {
  right: -50%;
  left: -50%
}

[dir='ltr'] .ltr\:-inset-y-1\/3,[dir='ltr'].ltr\:-inset-y-1\/3 {
  top: -33.333333%;
  bottom: -33.333333%
}

[dir='ltr'] .ltr\:-inset-x-1\/3,[dir='ltr'].ltr\:-inset-x-1\/3 {
  right: -33.333333%;
  left: -33.333333%
}

[dir='ltr'] .ltr\:-inset-y-2\/3,[dir='ltr'].ltr\:-inset-y-2\/3 {
  top: -66.666667%;
  bottom: -66.666667%
}

[dir='ltr'] .ltr\:-inset-x-2\/3,[dir='ltr'].ltr\:-inset-x-2\/3 {
  right: -66.666667%;
  left: -66.666667%
}

[dir='ltr'] .ltr\:-inset-y-1\/4,[dir='ltr'].ltr\:-inset-y-1\/4 {
  top: -25%;
  bottom: -25%
}

[dir='ltr'] .ltr\:-inset-x-1\/4,[dir='ltr'].ltr\:-inset-x-1\/4 {
  right: -25%;
  left: -25%
}

[dir='ltr'] .ltr\:-inset-y-2\/4,[dir='ltr'].ltr\:-inset-y-2\/4 {
  top: -50%;
  bottom: -50%
}

[dir='ltr'] .ltr\:-inset-x-2\/4,[dir='ltr'].ltr\:-inset-x-2\/4 {
  right: -50%;
  left: -50%
}

[dir='ltr'] .ltr\:-inset-y-3\/4,[dir='ltr'].ltr\:-inset-y-3\/4 {
  top: -75%;
  bottom: -75%
}

[dir='ltr'] .ltr\:-inset-x-3\/4,[dir='ltr'].ltr\:-inset-x-3\/4 {
  right: -75%;
  left: -75%
}

[dir='ltr'] .ltr\:-inset-y-full,[dir='ltr'].ltr\:-inset-y-full {
  top: -100%;
  bottom: -100%
}

[dir='ltr'] .ltr\:-inset-x-full,[dir='ltr'].ltr\:-inset-x-full {
  right: -100%;
  left: -100%
}

[dir='ltr'] .ltr\:top-0,[dir='ltr'].ltr\:top-0 {
  top: 0
}

[dir='ltr'] .ltr\:right-0,[dir='ltr'].ltr\:right-0 {
  right: 0
}

[dir='ltr'] .ltr\:bottom-0,[dir='ltr'].ltr\:bottom-0 {
  bottom: 0
}

[dir='ltr'] .ltr\:left-0,[dir='ltr'].ltr\:left-0 {
  left: 0
}

[dir='ltr'] .ltr\:top-1,[dir='ltr'].ltr\:top-1 {
  top: 0.1rem
}

[dir='ltr'] .ltr\:right-1,[dir='ltr'].ltr\:right-1 {
  right: 0.1rem
}

[dir='ltr'] .ltr\:bottom-1,[dir='ltr'].ltr\:bottom-1 {
  bottom: 0.1rem
}

[dir='ltr'] .ltr\:left-1,[dir='ltr'].ltr\:left-1 {
  left: 0.1rem
}

[dir='ltr'] .ltr\:top-2,[dir='ltr'].ltr\:top-2 {
  top: 0.2rem
}

[dir='ltr'] .ltr\:right-2,[dir='ltr'].ltr\:right-2 {
  right: 0.2rem
}

[dir='ltr'] .ltr\:bottom-2,[dir='ltr'].ltr\:bottom-2 {
  bottom: 0.2rem
}

[dir='ltr'] .ltr\:left-2,[dir='ltr'].ltr\:left-2 {
  left: 0.2rem
}

[dir='ltr'] .ltr\:top-3,[dir='ltr'].ltr\:top-3 {
  top: 0.3rem
}

[dir='ltr'] .ltr\:right-3,[dir='ltr'].ltr\:right-3 {
  right: 0.3rem
}

[dir='ltr'] .ltr\:bottom-3,[dir='ltr'].ltr\:bottom-3 {
  bottom: 0.3rem
}

[dir='ltr'] .ltr\:left-3,[dir='ltr'].ltr\:left-3 {
  left: 0.3rem
}

[dir='ltr'] .ltr\:top-4,[dir='ltr'].ltr\:top-4 {
  top: 0.4rem
}

[dir='ltr'] .ltr\:right-4,[dir='ltr'].ltr\:right-4 {
  right: 0.4rem
}

[dir='ltr'] .ltr\:bottom-4,[dir='ltr'].ltr\:bottom-4 {
  bottom: 0.4rem
}

[dir='ltr'] .ltr\:left-4,[dir='ltr'].ltr\:left-4 {
  left: 0.4rem
}

[dir='ltr'] .ltr\:top-5,[dir='ltr'].ltr\:top-5 {
  top: 0.5rem
}

[dir='ltr'] .ltr\:right-5,[dir='ltr'].ltr\:right-5 {
  right: 0.5rem
}

[dir='ltr'] .ltr\:bottom-5,[dir='ltr'].ltr\:bottom-5 {
  bottom: 0.5rem
}

[dir='ltr'] .ltr\:left-5,[dir='ltr'].ltr\:left-5 {
  left: 0.5rem
}

[dir='ltr'] .ltr\:top-6,[dir='ltr'].ltr\:top-6 {
  top: 0.6rem
}

[dir='ltr'] .ltr\:right-6,[dir='ltr'].ltr\:right-6 {
  right: 0.6rem
}

[dir='ltr'] .ltr\:bottom-6,[dir='ltr'].ltr\:bottom-6 {
  bottom: 0.6rem
}

[dir='ltr'] .ltr\:left-6,[dir='ltr'].ltr\:left-6 {
  left: 0.6rem
}

[dir='ltr'] .ltr\:top-7,[dir='ltr'].ltr\:top-7 {
  top: 0.7rem
}

[dir='ltr'] .ltr\:right-7,[dir='ltr'].ltr\:right-7 {
  right: 0.7rem
}

[dir='ltr'] .ltr\:bottom-7,[dir='ltr'].ltr\:bottom-7 {
  bottom: 0.7rem
}

[dir='ltr'] .ltr\:left-7,[dir='ltr'].ltr\:left-7 {
  left: 0.7rem
}

[dir='ltr'] .ltr\:top-8,[dir='ltr'].ltr\:top-8 {
  top: 0.8rem
}

[dir='ltr'] .ltr\:right-8,[dir='ltr'].ltr\:right-8 {
  right: 0.8rem
}

[dir='ltr'] .ltr\:bottom-8,[dir='ltr'].ltr\:bottom-8 {
  bottom: 0.8rem
}

[dir='ltr'] .ltr\:left-8,[dir='ltr'].ltr\:left-8 {
  left: 0.8rem
}

[dir='ltr'] .ltr\:top-9,[dir='ltr'].ltr\:top-9 {
  top: 0.9rem
}

[dir='ltr'] .ltr\:right-9,[dir='ltr'].ltr\:right-9 {
  right: 0.9rem
}

[dir='ltr'] .ltr\:bottom-9,[dir='ltr'].ltr\:bottom-9 {
  bottom: 0.9rem
}

[dir='ltr'] .ltr\:left-9,[dir='ltr'].ltr\:left-9 {
  left: 0.9rem
}

[dir='ltr'] .ltr\:top-10,[dir='ltr'].ltr\:top-10 {
  top: 1.0rem
}

[dir='ltr'] .ltr\:right-10,[dir='ltr'].ltr\:right-10 {
  right: 1.0rem
}

[dir='ltr'] .ltr\:bottom-10,[dir='ltr'].ltr\:bottom-10 {
  bottom: 1.0rem
}

[dir='ltr'] .ltr\:left-10,[dir='ltr'].ltr\:left-10 {
  left: 1.0rem
}

[dir='ltr'] .ltr\:top-12,[dir='ltr'].ltr\:top-12 {
  top: 1.2rem
}

[dir='ltr'] .ltr\:right-12,[dir='ltr'].ltr\:right-12 {
  right: 1.2rem
}

[dir='ltr'] .ltr\:bottom-12,[dir='ltr'].ltr\:bottom-12 {
  bottom: 1.2rem
}

[dir='ltr'] .ltr\:left-12,[dir='ltr'].ltr\:left-12 {
  left: 1.2rem
}

[dir='ltr'] .ltr\:top-14,[dir='ltr'].ltr\:top-14 {
  top: 1.4rem
}

[dir='ltr'] .ltr\:right-14,[dir='ltr'].ltr\:right-14 {
  right: 1.4rem
}

[dir='ltr'] .ltr\:bottom-14,[dir='ltr'].ltr\:bottom-14 {
  bottom: 1.4rem
}

[dir='ltr'] .ltr\:left-14,[dir='ltr'].ltr\:left-14 {
  left: 1.4rem
}

[dir='ltr'] .ltr\:top-16,[dir='ltr'].ltr\:top-16 {
  top: 1.6rem
}

[dir='ltr'] .ltr\:right-16,[dir='ltr'].ltr\:right-16 {
  right: 1.6rem
}

[dir='ltr'] .ltr\:bottom-16,[dir='ltr'].ltr\:bottom-16 {
  bottom: 1.6rem
}

[dir='ltr'] .ltr\:left-16,[dir='ltr'].ltr\:left-16 {
  left: 1.6rem
}

[dir='ltr'] .ltr\:top-20,[dir='ltr'].ltr\:top-20 {
  top: 2rem
}

[dir='ltr'] .ltr\:right-20,[dir='ltr'].ltr\:right-20 {
  right: 2rem
}

[dir='ltr'] .ltr\:bottom-20,[dir='ltr'].ltr\:bottom-20 {
  bottom: 2rem
}

[dir='ltr'] .ltr\:left-20,[dir='ltr'].ltr\:left-20 {
  left: 2rem
}

[dir='ltr'] .ltr\:top-24,[dir='ltr'].ltr\:top-24 {
  top: 2.4rem
}

[dir='ltr'] .ltr\:right-24,[dir='ltr'].ltr\:right-24 {
  right: 2.4rem
}

[dir='ltr'] .ltr\:bottom-24,[dir='ltr'].ltr\:bottom-24 {
  bottom: 2.4rem
}

[dir='ltr'] .ltr\:left-24,[dir='ltr'].ltr\:left-24 {
  left: 2.4rem
}

[dir='ltr'] .ltr\:top-28,[dir='ltr'].ltr\:top-28 {
  top: 2.8rem
}

[dir='ltr'] .ltr\:right-28,[dir='ltr'].ltr\:right-28 {
  right: 2.8rem
}

[dir='ltr'] .ltr\:bottom-28,[dir='ltr'].ltr\:bottom-28 {
  bottom: 2.8rem
}

[dir='ltr'] .ltr\:left-28,[dir='ltr'].ltr\:left-28 {
  left: 2.8rem
}

[dir='ltr'] .ltr\:top-32,[dir='ltr'].ltr\:top-32 {
  top: 3.2rem
}

[dir='ltr'] .ltr\:right-32,[dir='ltr'].ltr\:right-32 {
  right: 3.2rem
}

[dir='ltr'] .ltr\:bottom-32,[dir='ltr'].ltr\:bottom-32 {
  bottom: 3.2rem
}

[dir='ltr'] .ltr\:left-32,[dir='ltr'].ltr\:left-32 {
  left: 3.2rem
}

[dir='ltr'] .ltr\:top-36,[dir='ltr'].ltr\:top-36 {
  top: 3.6rem
}

[dir='ltr'] .ltr\:right-36,[dir='ltr'].ltr\:right-36 {
  right: 3.6rem
}

[dir='ltr'] .ltr\:bottom-36,[dir='ltr'].ltr\:bottom-36 {
  bottom: 3.6rem
}

[dir='ltr'] .ltr\:left-36,[dir='ltr'].ltr\:left-36 {
  left: 3.6rem
}

[dir='ltr'] .ltr\:top-40,[dir='ltr'].ltr\:top-40 {
  top: 4rem
}

[dir='ltr'] .ltr\:right-40,[dir='ltr'].ltr\:right-40 {
  right: 4rem
}

[dir='ltr'] .ltr\:bottom-40,[dir='ltr'].ltr\:bottom-40 {
  bottom: 4rem
}

[dir='ltr'] .ltr\:left-40,[dir='ltr'].ltr\:left-40 {
  left: 4rem
}

[dir='ltr'] .ltr\:top-44,[dir='ltr'].ltr\:top-44 {
  top: 4.4rem
}

[dir='ltr'] .ltr\:right-44,[dir='ltr'].ltr\:right-44 {
  right: 4.4rem
}

[dir='ltr'] .ltr\:bottom-44,[dir='ltr'].ltr\:bottom-44 {
  bottom: 4.4rem
}

[dir='ltr'] .ltr\:left-44,[dir='ltr'].ltr\:left-44 {
  left: 4.4rem
}

[dir='ltr'] .ltr\:top-48,[dir='ltr'].ltr\:top-48 {
  top: 4.8rem
}

[dir='ltr'] .ltr\:right-48,[dir='ltr'].ltr\:right-48 {
  right: 4.8rem
}

[dir='ltr'] .ltr\:bottom-48,[dir='ltr'].ltr\:bottom-48 {
  bottom: 4.8rem
}

[dir='ltr'] .ltr\:left-48,[dir='ltr'].ltr\:left-48 {
  left: 4.8rem
}

[dir='ltr'] .ltr\:top-52,[dir='ltr'].ltr\:top-52 {
  top: 5.2rem
}

[dir='ltr'] .ltr\:right-52,[dir='ltr'].ltr\:right-52 {
  right: 5.2rem
}

[dir='ltr'] .ltr\:bottom-52,[dir='ltr'].ltr\:bottom-52 {
  bottom: 5.2rem
}

[dir='ltr'] .ltr\:left-52,[dir='ltr'].ltr\:left-52 {
  left: 5.2rem
}

[dir='ltr'] .ltr\:top-56,[dir='ltr'].ltr\:top-56 {
  top: 5.6rem
}

[dir='ltr'] .ltr\:right-56,[dir='ltr'].ltr\:right-56 {
  right: 5.6rem
}

[dir='ltr'] .ltr\:bottom-56,[dir='ltr'].ltr\:bottom-56 {
  bottom: 5.6rem
}

[dir='ltr'] .ltr\:left-56,[dir='ltr'].ltr\:left-56 {
  left: 5.6rem
}

[dir='ltr'] .ltr\:top-60,[dir='ltr'].ltr\:top-60 {
  top: 6rem
}

[dir='ltr'] .ltr\:right-60,[dir='ltr'].ltr\:right-60 {
  right: 6rem
}

[dir='ltr'] .ltr\:bottom-60,[dir='ltr'].ltr\:bottom-60 {
  bottom: 6rem
}

[dir='ltr'] .ltr\:left-60,[dir='ltr'].ltr\:left-60 {
  left: 6rem
}

[dir='ltr'] .ltr\:top-64,[dir='ltr'].ltr\:top-64 {
  top: 6.4rem
}

[dir='ltr'] .ltr\:right-64,[dir='ltr'].ltr\:right-64 {
  right: 6.4rem
}

[dir='ltr'] .ltr\:bottom-64,[dir='ltr'].ltr\:bottom-64 {
  bottom: 6.4rem
}

[dir='ltr'] .ltr\:left-64,[dir='ltr'].ltr\:left-64 {
  left: 6.4rem
}

[dir='ltr'] .ltr\:top-68,[dir='ltr'].ltr\:top-68 {
  top: 6.8rem
}

[dir='ltr'] .ltr\:right-68,[dir='ltr'].ltr\:right-68 {
  right: 6.8rem
}

[dir='ltr'] .ltr\:bottom-68,[dir='ltr'].ltr\:bottom-68 {
  bottom: 6.8rem
}

[dir='ltr'] .ltr\:left-68,[dir='ltr'].ltr\:left-68 {
  left: 6.8rem
}

[dir='ltr'] .ltr\:top-72,[dir='ltr'].ltr\:top-72 {
  top: 7.2rem
}

[dir='ltr'] .ltr\:right-72,[dir='ltr'].ltr\:right-72 {
  right: 7.2rem
}

[dir='ltr'] .ltr\:bottom-72,[dir='ltr'].ltr\:bottom-72 {
  bottom: 7.2rem
}

[dir='ltr'] .ltr\:left-72,[dir='ltr'].ltr\:left-72 {
  left: 7.2rem
}

[dir='ltr'] .ltr\:top-76,[dir='ltr'].ltr\:top-76 {
  top: 7.6rem
}

[dir='ltr'] .ltr\:right-76,[dir='ltr'].ltr\:right-76 {
  right: 7.6rem
}

[dir='ltr'] .ltr\:bottom-76,[dir='ltr'].ltr\:bottom-76 {
  bottom: 7.6rem
}

[dir='ltr'] .ltr\:left-76,[dir='ltr'].ltr\:left-76 {
  left: 7.6rem
}

[dir='ltr'] .ltr\:top-80,[dir='ltr'].ltr\:top-80 {
  top: 8rem
}

[dir='ltr'] .ltr\:right-80,[dir='ltr'].ltr\:right-80 {
  right: 8rem
}

[dir='ltr'] .ltr\:bottom-80,[dir='ltr'].ltr\:bottom-80 {
  bottom: 8rem
}

[dir='ltr'] .ltr\:left-80,[dir='ltr'].ltr\:left-80 {
  left: 8rem
}

[dir='ltr'] .ltr\:top-84,[dir='ltr'].ltr\:top-84 {
  top: 8.4rem
}

[dir='ltr'] .ltr\:right-84,[dir='ltr'].ltr\:right-84 {
  right: 8.4rem
}

[dir='ltr'] .ltr\:bottom-84,[dir='ltr'].ltr\:bottom-84 {
  bottom: 8.4rem
}

[dir='ltr'] .ltr\:left-84,[dir='ltr'].ltr\:left-84 {
  left: 8.4rem
}

[dir='ltr'] .ltr\:top-88,[dir='ltr'].ltr\:top-88 {
  top: 8.8rem
}

[dir='ltr'] .ltr\:right-88,[dir='ltr'].ltr\:right-88 {
  right: 8.8rem
}

[dir='ltr'] .ltr\:bottom-88,[dir='ltr'].ltr\:bottom-88 {
  bottom: 8.8rem
}

[dir='ltr'] .ltr\:left-88,[dir='ltr'].ltr\:left-88 {
  left: 8.8rem
}

[dir='ltr'] .ltr\:top-92,[dir='ltr'].ltr\:top-92 {
  top: 9.2rem
}

[dir='ltr'] .ltr\:right-92,[dir='ltr'].ltr\:right-92 {
  right: 9.2rem
}

[dir='ltr'] .ltr\:bottom-92,[dir='ltr'].ltr\:bottom-92 {
  bottom: 9.2rem
}

[dir='ltr'] .ltr\:left-92,[dir='ltr'].ltr\:left-92 {
  left: 9.2rem
}

[dir='ltr'] .ltr\:top-96,[dir='ltr'].ltr\:top-96 {
  top: 9.6rem
}

[dir='ltr'] .ltr\:right-96,[dir='ltr'].ltr\:right-96 {
  right: 9.6rem
}

[dir='ltr'] .ltr\:bottom-96,[dir='ltr'].ltr\:bottom-96 {
  bottom: 9.6rem
}

[dir='ltr'] .ltr\:left-96,[dir='ltr'].ltr\:left-96 {
  left: 9.6rem
}

[dir='ltr'] .ltr\:top-128,[dir='ltr'].ltr\:top-128 {
  top: 12.8rem
}

[dir='ltr'] .ltr\:right-128,[dir='ltr'].ltr\:right-128 {
  right: 12.8rem
}

[dir='ltr'] .ltr\:bottom-128,[dir='ltr'].ltr\:bottom-128 {
  bottom: 12.8rem
}

[dir='ltr'] .ltr\:left-128,[dir='ltr'].ltr\:left-128 {
  left: 12.8rem
}

[dir='ltr'] .ltr\:top-136,[dir='ltr'].ltr\:top-136 {
  top: 13.6rem
}

[dir='ltr'] .ltr\:right-136,[dir='ltr'].ltr\:right-136 {
  right: 13.6rem
}

[dir='ltr'] .ltr\:bottom-136,[dir='ltr'].ltr\:bottom-136 {
  bottom: 13.6rem
}

[dir='ltr'] .ltr\:left-136,[dir='ltr'].ltr\:left-136 {
  left: 13.6rem
}

[dir='ltr'] .ltr\:top-160,[dir='ltr'].ltr\:top-160 {
  top: 16rem
}

[dir='ltr'] .ltr\:right-160,[dir='ltr'].ltr\:right-160 {
  right: 16rem
}

[dir='ltr'] .ltr\:bottom-160,[dir='ltr'].ltr\:bottom-160 {
  bottom: 16rem
}

[dir='ltr'] .ltr\:left-160,[dir='ltr'].ltr\:left-160 {
  left: 16rem
}

[dir='ltr'] .ltr\:top-192,[dir='ltr'].ltr\:top-192 {
  top: 19.2rem
}

[dir='ltr'] .ltr\:right-192,[dir='ltr'].ltr\:right-192 {
  right: 19.2rem
}

[dir='ltr'] .ltr\:bottom-192,[dir='ltr'].ltr\:bottom-192 {
  bottom: 19.2rem
}

[dir='ltr'] .ltr\:left-192,[dir='ltr'].ltr\:left-192 {
  left: 19.2rem
}

[dir='ltr'] .ltr\:top-200,[dir='ltr'].ltr\:top-200 {
  top: 20rem
}

[dir='ltr'] .ltr\:right-200,[dir='ltr'].ltr\:right-200 {
  right: 20rem
}

[dir='ltr'] .ltr\:bottom-200,[dir='ltr'].ltr\:bottom-200 {
  bottom: 20rem
}

[dir='ltr'] .ltr\:left-200,[dir='ltr'].ltr\:left-200 {
  left: 20rem
}

[dir='ltr'] .ltr\:top-208,[dir='ltr'].ltr\:top-208 {
  top: 20.8rem
}

[dir='ltr'] .ltr\:right-208,[dir='ltr'].ltr\:right-208 {
  right: 20.8rem
}

[dir='ltr'] .ltr\:bottom-208,[dir='ltr'].ltr\:bottom-208 {
  bottom: 20.8rem
}

[dir='ltr'] .ltr\:left-208,[dir='ltr'].ltr\:left-208 {
  left: 20.8rem
}

[dir='ltr'] .ltr\:top-216,[dir='ltr'].ltr\:top-216 {
  top: 21.6rem
}

[dir='ltr'] .ltr\:right-216,[dir='ltr'].ltr\:right-216 {
  right: 21.6rem
}

[dir='ltr'] .ltr\:bottom-216,[dir='ltr'].ltr\:bottom-216 {
  bottom: 21.6rem
}

[dir='ltr'] .ltr\:left-216,[dir='ltr'].ltr\:left-216 {
  left: 21.6rem
}

[dir='ltr'] .ltr\:top-224,[dir='ltr'].ltr\:top-224 {
  top: 22.4rem
}

[dir='ltr'] .ltr\:right-224,[dir='ltr'].ltr\:right-224 {
  right: 22.4rem
}

[dir='ltr'] .ltr\:bottom-224,[dir='ltr'].ltr\:bottom-224 {
  bottom: 22.4rem
}

[dir='ltr'] .ltr\:left-224,[dir='ltr'].ltr\:left-224 {
  left: 22.4rem
}

[dir='ltr'] .ltr\:top-256,[dir='ltr'].ltr\:top-256 {
  top: 25.6rem
}

[dir='ltr'] .ltr\:right-256,[dir='ltr'].ltr\:right-256 {
  right: 25.6rem
}

[dir='ltr'] .ltr\:bottom-256,[dir='ltr'].ltr\:bottom-256 {
  bottom: 25.6rem
}

[dir='ltr'] .ltr\:left-256,[dir='ltr'].ltr\:left-256 {
  left: 25.6rem
}

[dir='ltr'] .ltr\:top-288,[dir='ltr'].ltr\:top-288 {
  top: 28.8rem
}

[dir='ltr'] .ltr\:right-288,[dir='ltr'].ltr\:right-288 {
  right: 28.8rem
}

[dir='ltr'] .ltr\:bottom-288,[dir='ltr'].ltr\:bottom-288 {
  bottom: 28.8rem
}

[dir='ltr'] .ltr\:left-288,[dir='ltr'].ltr\:left-288 {
  left: 28.8rem
}

[dir='ltr'] .ltr\:top-320,[dir='ltr'].ltr\:top-320 {
  top: 32rem
}

[dir='ltr'] .ltr\:right-320,[dir='ltr'].ltr\:right-320 {
  right: 32rem
}

[dir='ltr'] .ltr\:bottom-320,[dir='ltr'].ltr\:bottom-320 {
  bottom: 32rem
}

[dir='ltr'] .ltr\:left-320,[dir='ltr'].ltr\:left-320 {
  left: 32rem
}

[dir='ltr'] .ltr\:top-360,[dir='ltr'].ltr\:top-360 {
  top: 36rem
}

[dir='ltr'] .ltr\:right-360,[dir='ltr'].ltr\:right-360 {
  right: 36rem
}

[dir='ltr'] .ltr\:bottom-360,[dir='ltr'].ltr\:bottom-360 {
  bottom: 36rem
}

[dir='ltr'] .ltr\:left-360,[dir='ltr'].ltr\:left-360 {
  left: 36rem
}

[dir='ltr'] .ltr\:top-384,[dir='ltr'].ltr\:top-384 {
  top: 38.4rem
}

[dir='ltr'] .ltr\:right-384,[dir='ltr'].ltr\:right-384 {
  right: 38.4rem
}

[dir='ltr'] .ltr\:bottom-384,[dir='ltr'].ltr\:bottom-384 {
  bottom: 38.4rem
}

[dir='ltr'] .ltr\:left-384,[dir='ltr'].ltr\:left-384 {
  left: 38.4rem
}

[dir='ltr'] .ltr\:top-400,[dir='ltr'].ltr\:top-400 {
  top: 40rem
}

[dir='ltr'] .ltr\:right-400,[dir='ltr'].ltr\:right-400 {
  right: 40rem
}

[dir='ltr'] .ltr\:bottom-400,[dir='ltr'].ltr\:bottom-400 {
  bottom: 40rem
}

[dir='ltr'] .ltr\:left-400,[dir='ltr'].ltr\:left-400 {
  left: 40rem
}

[dir='ltr'] .ltr\:top-512,[dir='ltr'].ltr\:top-512 {
  top: 51.2rem
}

[dir='ltr'] .ltr\:right-512,[dir='ltr'].ltr\:right-512 {
  right: 51.2rem
}

[dir='ltr'] .ltr\:bottom-512,[dir='ltr'].ltr\:bottom-512 {
  bottom: 51.2rem
}

[dir='ltr'] .ltr\:left-512,[dir='ltr'].ltr\:left-512 {
  left: 51.2rem
}

[dir='ltr'] .ltr\:top-640,[dir='ltr'].ltr\:top-640 {
  top: 64rem
}

[dir='ltr'] .ltr\:right-640,[dir='ltr'].ltr\:right-640 {
  right: 64rem
}

[dir='ltr'] .ltr\:bottom-640,[dir='ltr'].ltr\:bottom-640 {
  bottom: 64rem
}

[dir='ltr'] .ltr\:left-640,[dir='ltr'].ltr\:left-640 {
  left: 64rem
}

[dir='ltr'] .ltr\:top-auto,[dir='ltr'].ltr\:top-auto {
  top: auto
}

[dir='ltr'] .ltr\:right-auto,[dir='ltr'].ltr\:right-auto {
  right: auto
}

[dir='ltr'] .ltr\:bottom-auto,[dir='ltr'].ltr\:bottom-auto {
  bottom: auto
}

[dir='ltr'] .ltr\:left-auto,[dir='ltr'].ltr\:left-auto {
  left: auto
}

[dir='ltr'] .ltr\:top-xs,[dir='ltr'].ltr\:top-xs {
  top: 32rem
}

[dir='ltr'] .ltr\:right-xs,[dir='ltr'].ltr\:right-xs {
  right: 32rem
}

[dir='ltr'] .ltr\:bottom-xs,[dir='ltr'].ltr\:bottom-xs {
  bottom: 32rem
}

[dir='ltr'] .ltr\:left-xs,[dir='ltr'].ltr\:left-xs {
  left: 32rem
}

[dir='ltr'] .ltr\:top-sm,[dir='ltr'].ltr\:top-sm {
  top: 48rem
}

[dir='ltr'] .ltr\:right-sm,[dir='ltr'].ltr\:right-sm {
  right: 48rem
}

[dir='ltr'] .ltr\:bottom-sm,[dir='ltr'].ltr\:bottom-sm {
  bottom: 48rem
}

[dir='ltr'] .ltr\:left-sm,[dir='ltr'].ltr\:left-sm {
  left: 48rem
}

[dir='ltr'] .ltr\:top-md,[dir='ltr'].ltr\:top-md {
  top: 64rem
}

[dir='ltr'] .ltr\:right-md,[dir='ltr'].ltr\:right-md {
  right: 64rem
}

[dir='ltr'] .ltr\:bottom-md,[dir='ltr'].ltr\:bottom-md {
  bottom: 64rem
}

[dir='ltr'] .ltr\:left-md,[dir='ltr'].ltr\:left-md {
  left: 64rem
}

[dir='ltr'] .ltr\:top-lg,[dir='ltr'].ltr\:top-lg {
  top: 80rem
}

[dir='ltr'] .ltr\:right-lg,[dir='ltr'].ltr\:right-lg {
  right: 80rem
}

[dir='ltr'] .ltr\:bottom-lg,[dir='ltr'].ltr\:bottom-lg {
  bottom: 80rem
}

[dir='ltr'] .ltr\:left-lg,[dir='ltr'].ltr\:left-lg {
  left: 80rem
}

[dir='ltr'] .ltr\:top-xl,[dir='ltr'].ltr\:top-xl {
  top: 96rem
}

[dir='ltr'] .ltr\:right-xl,[dir='ltr'].ltr\:right-xl {
  right: 96rem
}

[dir='ltr'] .ltr\:bottom-xl,[dir='ltr'].ltr\:bottom-xl {
  bottom: 96rem
}

[dir='ltr'] .ltr\:left-xl,[dir='ltr'].ltr\:left-xl {
  left: 96rem
}

[dir='ltr'] .ltr\:top-2xl,[dir='ltr'].ltr\:top-2xl {
  top: 112rem
}

[dir='ltr'] .ltr\:right-2xl,[dir='ltr'].ltr\:right-2xl {
  right: 112rem
}

[dir='ltr'] .ltr\:bottom-2xl,[dir='ltr'].ltr\:bottom-2xl {
  bottom: 112rem
}

[dir='ltr'] .ltr\:left-2xl,[dir='ltr'].ltr\:left-2xl {
  left: 112rem
}

[dir='ltr'] .ltr\:top-3xl,[dir='ltr'].ltr\:top-3xl {
  top: 128rem
}

[dir='ltr'] .ltr\:right-3xl,[dir='ltr'].ltr\:right-3xl {
  right: 128rem
}

[dir='ltr'] .ltr\:bottom-3xl,[dir='ltr'].ltr\:bottom-3xl {
  bottom: 128rem
}

[dir='ltr'] .ltr\:left-3xl,[dir='ltr'].ltr\:left-3xl {
  left: 128rem
}

[dir='ltr'] .ltr\:top-4xl,[dir='ltr'].ltr\:top-4xl {
  top: 144rem
}

[dir='ltr'] .ltr\:right-4xl,[dir='ltr'].ltr\:right-4xl {
  right: 144rem
}

[dir='ltr'] .ltr\:bottom-4xl,[dir='ltr'].ltr\:bottom-4xl {
  bottom: 144rem
}

[dir='ltr'] .ltr\:left-4xl,[dir='ltr'].ltr\:left-4xl {
  left: 144rem
}

[dir='ltr'] .ltr\:top-5xl,[dir='ltr'].ltr\:top-5xl {
  top: 160rem
}

[dir='ltr'] .ltr\:right-5xl,[dir='ltr'].ltr\:right-5xl {
  right: 160rem
}

[dir='ltr'] .ltr\:bottom-5xl,[dir='ltr'].ltr\:bottom-5xl {
  bottom: 160rem
}

[dir='ltr'] .ltr\:left-5xl,[dir='ltr'].ltr\:left-5xl {
  left: 160rem
}

[dir='ltr'] .ltr\:top-px,[dir='ltr'].ltr\:top-px {
  top: 1px
}

[dir='ltr'] .ltr\:right-px,[dir='ltr'].ltr\:right-px {
  right: 1px
}

[dir='ltr'] .ltr\:bottom-px,[dir='ltr'].ltr\:bottom-px {
  bottom: 1px
}

[dir='ltr'] .ltr\:left-px,[dir='ltr'].ltr\:left-px {
  left: 1px
}

[dir='ltr'] .ltr\:top-0\.5,[dir='ltr'].ltr\:top-0\.5 {
  top: 0.05rem
}

[dir='ltr'] .ltr\:right-0\.5,[dir='ltr'].ltr\:right-0\.5 {
  right: 0.05rem
}

[dir='ltr'] .ltr\:bottom-0\.5,[dir='ltr'].ltr\:bottom-0\.5 {
  bottom: 0.05rem
}

[dir='ltr'] .ltr\:left-0\.5,[dir='ltr'].ltr\:left-0\.5 {
  left: 0.05rem
}

[dir='ltr'] .ltr\:top-1\.5,[dir='ltr'].ltr\:top-1\.5 {
  top: 0.15rem
}

[dir='ltr'] .ltr\:right-1\.5,[dir='ltr'].ltr\:right-1\.5 {
  right: 0.15rem
}

[dir='ltr'] .ltr\:bottom-1\.5,[dir='ltr'].ltr\:bottom-1\.5 {
  bottom: 0.15rem
}

[dir='ltr'] .ltr\:left-1\.5,[dir='ltr'].ltr\:left-1\.5 {
  left: 0.15rem
}

[dir='ltr'] .ltr\:top-2\.5,[dir='ltr'].ltr\:top-2\.5 {
  top: 0.25rem
}

[dir='ltr'] .ltr\:right-2\.5,[dir='ltr'].ltr\:right-2\.5 {
  right: 0.25rem
}

[dir='ltr'] .ltr\:bottom-2\.5,[dir='ltr'].ltr\:bottom-2\.5 {
  bottom: 0.25rem
}

[dir='ltr'] .ltr\:left-2\.5,[dir='ltr'].ltr\:left-2\.5 {
  left: 0.25rem
}

[dir='ltr'] .ltr\:top-3\.5,[dir='ltr'].ltr\:top-3\.5 {
  top: 0.35rem
}

[dir='ltr'] .ltr\:right-3\.5,[dir='ltr'].ltr\:right-3\.5 {
  right: 0.35rem
}

[dir='ltr'] .ltr\:bottom-3\.5,[dir='ltr'].ltr\:bottom-3\.5 {
  bottom: 0.35rem
}

[dir='ltr'] .ltr\:left-3\.5,[dir='ltr'].ltr\:left-3\.5 {
  left: 0.35rem
}

[dir='ltr'] .ltr\:-top-1,[dir='ltr'].ltr\:-top-1 {
  top: -0.1rem
}

[dir='ltr'] .ltr\:-right-1,[dir='ltr'].ltr\:-right-1 {
  right: -0.1rem
}

[dir='ltr'] .ltr\:-bottom-1,[dir='ltr'].ltr\:-bottom-1 {
  bottom: -0.1rem
}

[dir='ltr'] .ltr\:-left-1,[dir='ltr'].ltr\:-left-1 {
  left: -0.1rem
}

[dir='ltr'] .ltr\:-top-2,[dir='ltr'].ltr\:-top-2 {
  top: -0.2rem
}

[dir='ltr'] .ltr\:-right-2,[dir='ltr'].ltr\:-right-2 {
  right: -0.2rem
}

[dir='ltr'] .ltr\:-bottom-2,[dir='ltr'].ltr\:-bottom-2 {
  bottom: -0.2rem
}

[dir='ltr'] .ltr\:-left-2,[dir='ltr'].ltr\:-left-2 {
  left: -0.2rem
}

[dir='ltr'] .ltr\:-top-3,[dir='ltr'].ltr\:-top-3 {
  top: -0.3rem
}

[dir='ltr'] .ltr\:-right-3,[dir='ltr'].ltr\:-right-3 {
  right: -0.3rem
}

[dir='ltr'] .ltr\:-bottom-3,[dir='ltr'].ltr\:-bottom-3 {
  bottom: -0.3rem
}

[dir='ltr'] .ltr\:-left-3,[dir='ltr'].ltr\:-left-3 {
  left: -0.3rem
}

[dir='ltr'] .ltr\:-top-4,[dir='ltr'].ltr\:-top-4 {
  top: -0.4rem
}

[dir='ltr'] .ltr\:-right-4,[dir='ltr'].ltr\:-right-4 {
  right: -0.4rem
}

[dir='ltr'] .ltr\:-bottom-4,[dir='ltr'].ltr\:-bottom-4 {
  bottom: -0.4rem
}

[dir='ltr'] .ltr\:-left-4,[dir='ltr'].ltr\:-left-4 {
  left: -0.4rem
}

[dir='ltr'] .ltr\:-top-5,[dir='ltr'].ltr\:-top-5 {
  top: -0.5rem
}

[dir='ltr'] .ltr\:-right-5,[dir='ltr'].ltr\:-right-5 {
  right: -0.5rem
}

[dir='ltr'] .ltr\:-bottom-5,[dir='ltr'].ltr\:-bottom-5 {
  bottom: -0.5rem
}

[dir='ltr'] .ltr\:-left-5,[dir='ltr'].ltr\:-left-5 {
  left: -0.5rem
}

[dir='ltr'] .ltr\:-top-6,[dir='ltr'].ltr\:-top-6 {
  top: -0.6rem
}

[dir='ltr'] .ltr\:-right-6,[dir='ltr'].ltr\:-right-6 {
  right: -0.6rem
}

[dir='ltr'] .ltr\:-bottom-6,[dir='ltr'].ltr\:-bottom-6 {
  bottom: -0.6rem
}

[dir='ltr'] .ltr\:-left-6,[dir='ltr'].ltr\:-left-6 {
  left: -0.6rem
}

[dir='ltr'] .ltr\:-top-7,[dir='ltr'].ltr\:-top-7 {
  top: -0.7rem
}

[dir='ltr'] .ltr\:-right-7,[dir='ltr'].ltr\:-right-7 {
  right: -0.7rem
}

[dir='ltr'] .ltr\:-bottom-7,[dir='ltr'].ltr\:-bottom-7 {
  bottom: -0.7rem
}

[dir='ltr'] .ltr\:-left-7,[dir='ltr'].ltr\:-left-7 {
  left: -0.7rem
}

[dir='ltr'] .ltr\:-top-8,[dir='ltr'].ltr\:-top-8 {
  top: -0.8rem
}

[dir='ltr'] .ltr\:-right-8,[dir='ltr'].ltr\:-right-8 {
  right: -0.8rem
}

[dir='ltr'] .ltr\:-bottom-8,[dir='ltr'].ltr\:-bottom-8 {
  bottom: -0.8rem
}

[dir='ltr'] .ltr\:-left-8,[dir='ltr'].ltr\:-left-8 {
  left: -0.8rem
}

[dir='ltr'] .ltr\:-top-9,[dir='ltr'].ltr\:-top-9 {
  top: -0.9rem
}

[dir='ltr'] .ltr\:-right-9,[dir='ltr'].ltr\:-right-9 {
  right: -0.9rem
}

[dir='ltr'] .ltr\:-bottom-9,[dir='ltr'].ltr\:-bottom-9 {
  bottom: -0.9rem
}

[dir='ltr'] .ltr\:-left-9,[dir='ltr'].ltr\:-left-9 {
  left: -0.9rem
}

[dir='ltr'] .ltr\:-top-10,[dir='ltr'].ltr\:-top-10 {
  top: -1rem
}

[dir='ltr'] .ltr\:-right-10,[dir='ltr'].ltr\:-right-10 {
  right: -1rem
}

[dir='ltr'] .ltr\:-bottom-10,[dir='ltr'].ltr\:-bottom-10 {
  bottom: -1rem
}

[dir='ltr'] .ltr\:-left-10,[dir='ltr'].ltr\:-left-10 {
  left: -1rem
}

[dir='ltr'] .ltr\:-top-12,[dir='ltr'].ltr\:-top-12 {
  top: -1.2rem
}

[dir='ltr'] .ltr\:-right-12,[dir='ltr'].ltr\:-right-12 {
  right: -1.2rem
}

[dir='ltr'] .ltr\:-bottom-12,[dir='ltr'].ltr\:-bottom-12 {
  bottom: -1.2rem
}

[dir='ltr'] .ltr\:-left-12,[dir='ltr'].ltr\:-left-12 {
  left: -1.2rem
}

[dir='ltr'] .ltr\:-top-14,[dir='ltr'].ltr\:-top-14 {
  top: -1.4rem
}

[dir='ltr'] .ltr\:-right-14,[dir='ltr'].ltr\:-right-14 {
  right: -1.4rem
}

[dir='ltr'] .ltr\:-bottom-14,[dir='ltr'].ltr\:-bottom-14 {
  bottom: -1.4rem
}

[dir='ltr'] .ltr\:-left-14,[dir='ltr'].ltr\:-left-14 {
  left: -1.4rem
}

[dir='ltr'] .ltr\:-top-16,[dir='ltr'].ltr\:-top-16 {
  top: -1.6rem
}

[dir='ltr'] .ltr\:-right-16,[dir='ltr'].ltr\:-right-16 {
  right: -1.6rem
}

[dir='ltr'] .ltr\:-bottom-16,[dir='ltr'].ltr\:-bottom-16 {
  bottom: -1.6rem
}

[dir='ltr'] .ltr\:-left-16,[dir='ltr'].ltr\:-left-16 {
  left: -1.6rem
}

[dir='ltr'] .ltr\:-top-20,[dir='ltr'].ltr\:-top-20 {
  top: -2rem
}

[dir='ltr'] .ltr\:-right-20,[dir='ltr'].ltr\:-right-20 {
  right: -2rem
}

[dir='ltr'] .ltr\:-bottom-20,[dir='ltr'].ltr\:-bottom-20 {
  bottom: -2rem
}

[dir='ltr'] .ltr\:-left-20,[dir='ltr'].ltr\:-left-20 {
  left: -2rem
}

[dir='ltr'] .ltr\:-top-24,[dir='ltr'].ltr\:-top-24 {
  top: -2.4rem
}

[dir='ltr'] .ltr\:-right-24,[dir='ltr'].ltr\:-right-24 {
  right: -2.4rem
}

[dir='ltr'] .ltr\:-bottom-24,[dir='ltr'].ltr\:-bottom-24 {
  bottom: -2.4rem
}

[dir='ltr'] .ltr\:-left-24,[dir='ltr'].ltr\:-left-24 {
  left: -2.4rem
}

[dir='ltr'] .ltr\:-top-28,[dir='ltr'].ltr\:-top-28 {
  top: -2.8rem
}

[dir='ltr'] .ltr\:-right-28,[dir='ltr'].ltr\:-right-28 {
  right: -2.8rem
}

[dir='ltr'] .ltr\:-bottom-28,[dir='ltr'].ltr\:-bottom-28 {
  bottom: -2.8rem
}

[dir='ltr'] .ltr\:-left-28,[dir='ltr'].ltr\:-left-28 {
  left: -2.8rem
}

[dir='ltr'] .ltr\:-top-32,[dir='ltr'].ltr\:-top-32 {
  top: -3.2rem
}

[dir='ltr'] .ltr\:-right-32,[dir='ltr'].ltr\:-right-32 {
  right: -3.2rem
}

[dir='ltr'] .ltr\:-bottom-32,[dir='ltr'].ltr\:-bottom-32 {
  bottom: -3.2rem
}

[dir='ltr'] .ltr\:-left-32,[dir='ltr'].ltr\:-left-32 {
  left: -3.2rem
}

[dir='ltr'] .ltr\:-top-36,[dir='ltr'].ltr\:-top-36 {
  top: -3.6rem
}

[dir='ltr'] .ltr\:-right-36,[dir='ltr'].ltr\:-right-36 {
  right: -3.6rem
}

[dir='ltr'] .ltr\:-bottom-36,[dir='ltr'].ltr\:-bottom-36 {
  bottom: -3.6rem
}

[dir='ltr'] .ltr\:-left-36,[dir='ltr'].ltr\:-left-36 {
  left: -3.6rem
}

[dir='ltr'] .ltr\:-top-40,[dir='ltr'].ltr\:-top-40 {
  top: -4rem
}

[dir='ltr'] .ltr\:-right-40,[dir='ltr'].ltr\:-right-40 {
  right: -4rem
}

[dir='ltr'] .ltr\:-bottom-40,[dir='ltr'].ltr\:-bottom-40 {
  bottom: -4rem
}

[dir='ltr'] .ltr\:-left-40,[dir='ltr'].ltr\:-left-40 {
  left: -4rem
}

[dir='ltr'] .ltr\:-top-44,[dir='ltr'].ltr\:-top-44 {
  top: -4.4rem
}

[dir='ltr'] .ltr\:-right-44,[dir='ltr'].ltr\:-right-44 {
  right: -4.4rem
}

[dir='ltr'] .ltr\:-bottom-44,[dir='ltr'].ltr\:-bottom-44 {
  bottom: -4.4rem
}

[dir='ltr'] .ltr\:-left-44,[dir='ltr'].ltr\:-left-44 {
  left: -4.4rem
}

[dir='ltr'] .ltr\:-top-48,[dir='ltr'].ltr\:-top-48 {
  top: -4.8rem
}

[dir='ltr'] .ltr\:-right-48,[dir='ltr'].ltr\:-right-48 {
  right: -4.8rem
}

[dir='ltr'] .ltr\:-bottom-48,[dir='ltr'].ltr\:-bottom-48 {
  bottom: -4.8rem
}

[dir='ltr'] .ltr\:-left-48,[dir='ltr'].ltr\:-left-48 {
  left: -4.8rem
}

[dir='ltr'] .ltr\:-top-52,[dir='ltr'].ltr\:-top-52 {
  top: -5.2rem
}

[dir='ltr'] .ltr\:-right-52,[dir='ltr'].ltr\:-right-52 {
  right: -5.2rem
}

[dir='ltr'] .ltr\:-bottom-52,[dir='ltr'].ltr\:-bottom-52 {
  bottom: -5.2rem
}

[dir='ltr'] .ltr\:-left-52,[dir='ltr'].ltr\:-left-52 {
  left: -5.2rem
}

[dir='ltr'] .ltr\:-top-56,[dir='ltr'].ltr\:-top-56 {
  top: -5.6rem
}

[dir='ltr'] .ltr\:-right-56,[dir='ltr'].ltr\:-right-56 {
  right: -5.6rem
}

[dir='ltr'] .ltr\:-bottom-56,[dir='ltr'].ltr\:-bottom-56 {
  bottom: -5.6rem
}

[dir='ltr'] .ltr\:-left-56,[dir='ltr'].ltr\:-left-56 {
  left: -5.6rem
}

[dir='ltr'] .ltr\:-top-60,[dir='ltr'].ltr\:-top-60 {
  top: -6rem
}

[dir='ltr'] .ltr\:-right-60,[dir='ltr'].ltr\:-right-60 {
  right: -6rem
}

[dir='ltr'] .ltr\:-bottom-60,[dir='ltr'].ltr\:-bottom-60 {
  bottom: -6rem
}

[dir='ltr'] .ltr\:-left-60,[dir='ltr'].ltr\:-left-60 {
  left: -6rem
}

[dir='ltr'] .ltr\:-top-64,[dir='ltr'].ltr\:-top-64 {
  top: -6.4rem
}

[dir='ltr'] .ltr\:-right-64,[dir='ltr'].ltr\:-right-64 {
  right: -6.4rem
}

[dir='ltr'] .ltr\:-bottom-64,[dir='ltr'].ltr\:-bottom-64 {
  bottom: -6.4rem
}

[dir='ltr'] .ltr\:-left-64,[dir='ltr'].ltr\:-left-64 {
  left: -6.4rem
}

[dir='ltr'] .ltr\:-top-68,[dir='ltr'].ltr\:-top-68 {
  top: -6.8rem
}

[dir='ltr'] .ltr\:-right-68,[dir='ltr'].ltr\:-right-68 {
  right: -6.8rem
}

[dir='ltr'] .ltr\:-bottom-68,[dir='ltr'].ltr\:-bottom-68 {
  bottom: -6.8rem
}

[dir='ltr'] .ltr\:-left-68,[dir='ltr'].ltr\:-left-68 {
  left: -6.8rem
}

[dir='ltr'] .ltr\:-top-72,[dir='ltr'].ltr\:-top-72 {
  top: -7.2rem
}

[dir='ltr'] .ltr\:-right-72,[dir='ltr'].ltr\:-right-72 {
  right: -7.2rem
}

[dir='ltr'] .ltr\:-bottom-72,[dir='ltr'].ltr\:-bottom-72 {
  bottom: -7.2rem
}

[dir='ltr'] .ltr\:-left-72,[dir='ltr'].ltr\:-left-72 {
  left: -7.2rem
}

[dir='ltr'] .ltr\:-top-76,[dir='ltr'].ltr\:-top-76 {
  top: -7.6rem
}

[dir='ltr'] .ltr\:-right-76,[dir='ltr'].ltr\:-right-76 {
  right: -7.6rem
}

[dir='ltr'] .ltr\:-bottom-76,[dir='ltr'].ltr\:-bottom-76 {
  bottom: -7.6rem
}

[dir='ltr'] .ltr\:-left-76,[dir='ltr'].ltr\:-left-76 {
  left: -7.6rem
}

[dir='ltr'] .ltr\:-top-80,[dir='ltr'].ltr\:-top-80 {
  top: -8rem
}

[dir='ltr'] .ltr\:-right-80,[dir='ltr'].ltr\:-right-80 {
  right: -8rem
}

[dir='ltr'] .ltr\:-bottom-80,[dir='ltr'].ltr\:-bottom-80 {
  bottom: -8rem
}

[dir='ltr'] .ltr\:-left-80,[dir='ltr'].ltr\:-left-80 {
  left: -8rem
}

[dir='ltr'] .ltr\:-top-84,[dir='ltr'].ltr\:-top-84 {
  top: -8.4rem
}

[dir='ltr'] .ltr\:-right-84,[dir='ltr'].ltr\:-right-84 {
  right: -8.4rem
}

[dir='ltr'] .ltr\:-bottom-84,[dir='ltr'].ltr\:-bottom-84 {
  bottom: -8.4rem
}

[dir='ltr'] .ltr\:-left-84,[dir='ltr'].ltr\:-left-84 {
  left: -8.4rem
}

[dir='ltr'] .ltr\:-top-88,[dir='ltr'].ltr\:-top-88 {
  top: -8.8rem
}

[dir='ltr'] .ltr\:-right-88,[dir='ltr'].ltr\:-right-88 {
  right: -8.8rem
}

[dir='ltr'] .ltr\:-bottom-88,[dir='ltr'].ltr\:-bottom-88 {
  bottom: -8.8rem
}

[dir='ltr'] .ltr\:-left-88,[dir='ltr'].ltr\:-left-88 {
  left: -8.8rem
}

[dir='ltr'] .ltr\:-top-92,[dir='ltr'].ltr\:-top-92 {
  top: -9.2rem
}

[dir='ltr'] .ltr\:-right-92,[dir='ltr'].ltr\:-right-92 {
  right: -9.2rem
}

[dir='ltr'] .ltr\:-bottom-92,[dir='ltr'].ltr\:-bottom-92 {
  bottom: -9.2rem
}

[dir='ltr'] .ltr\:-left-92,[dir='ltr'].ltr\:-left-92 {
  left: -9.2rem
}

[dir='ltr'] .ltr\:-top-96,[dir='ltr'].ltr\:-top-96 {
  top: -9.6rem
}

[dir='ltr'] .ltr\:-right-96,[dir='ltr'].ltr\:-right-96 {
  right: -9.6rem
}

[dir='ltr'] .ltr\:-bottom-96,[dir='ltr'].ltr\:-bottom-96 {
  bottom: -9.6rem
}

[dir='ltr'] .ltr\:-left-96,[dir='ltr'].ltr\:-left-96 {
  left: -9.6rem
}

[dir='ltr'] .ltr\:-top-128,[dir='ltr'].ltr\:-top-128 {
  top: -12.8rem
}

[dir='ltr'] .ltr\:-right-128,[dir='ltr'].ltr\:-right-128 {
  right: -12.8rem
}

[dir='ltr'] .ltr\:-bottom-128,[dir='ltr'].ltr\:-bottom-128 {
  bottom: -12.8rem
}

[dir='ltr'] .ltr\:-left-128,[dir='ltr'].ltr\:-left-128 {
  left: -12.8rem
}

[dir='ltr'] .ltr\:-top-136,[dir='ltr'].ltr\:-top-136 {
  top: -13.6rem
}

[dir='ltr'] .ltr\:-right-136,[dir='ltr'].ltr\:-right-136 {
  right: -13.6rem
}

[dir='ltr'] .ltr\:-bottom-136,[dir='ltr'].ltr\:-bottom-136 {
  bottom: -13.6rem
}

[dir='ltr'] .ltr\:-left-136,[dir='ltr'].ltr\:-left-136 {
  left: -13.6rem
}

[dir='ltr'] .ltr\:-top-160,[dir='ltr'].ltr\:-top-160 {
  top: -16rem
}

[dir='ltr'] .ltr\:-right-160,[dir='ltr'].ltr\:-right-160 {
  right: -16rem
}

[dir='ltr'] .ltr\:-bottom-160,[dir='ltr'].ltr\:-bottom-160 {
  bottom: -16rem
}

[dir='ltr'] .ltr\:-left-160,[dir='ltr'].ltr\:-left-160 {
  left: -16rem
}

[dir='ltr'] .ltr\:-top-192,[dir='ltr'].ltr\:-top-192 {
  top: -19.2rem
}

[dir='ltr'] .ltr\:-right-192,[dir='ltr'].ltr\:-right-192 {
  right: -19.2rem
}

[dir='ltr'] .ltr\:-bottom-192,[dir='ltr'].ltr\:-bottom-192 {
  bottom: -19.2rem
}

[dir='ltr'] .ltr\:-left-192,[dir='ltr'].ltr\:-left-192 {
  left: -19.2rem
}

[dir='ltr'] .ltr\:-top-200,[dir='ltr'].ltr\:-top-200 {
  top: -20rem
}

[dir='ltr'] .ltr\:-right-200,[dir='ltr'].ltr\:-right-200 {
  right: -20rem
}

[dir='ltr'] .ltr\:-bottom-200,[dir='ltr'].ltr\:-bottom-200 {
  bottom: -20rem
}

[dir='ltr'] .ltr\:-left-200,[dir='ltr'].ltr\:-left-200 {
  left: -20rem
}

[dir='ltr'] .ltr\:-top-208,[dir='ltr'].ltr\:-top-208 {
  top: -20.8rem
}

[dir='ltr'] .ltr\:-right-208,[dir='ltr'].ltr\:-right-208 {
  right: -20.8rem
}

[dir='ltr'] .ltr\:-bottom-208,[dir='ltr'].ltr\:-bottom-208 {
  bottom: -20.8rem
}

[dir='ltr'] .ltr\:-left-208,[dir='ltr'].ltr\:-left-208 {
  left: -20.8rem
}

[dir='ltr'] .ltr\:-top-216,[dir='ltr'].ltr\:-top-216 {
  top: -21.6rem
}

[dir='ltr'] .ltr\:-right-216,[dir='ltr'].ltr\:-right-216 {
  right: -21.6rem
}

[dir='ltr'] .ltr\:-bottom-216,[dir='ltr'].ltr\:-bottom-216 {
  bottom: -21.6rem
}

[dir='ltr'] .ltr\:-left-216,[dir='ltr'].ltr\:-left-216 {
  left: -21.6rem
}

[dir='ltr'] .ltr\:-top-224,[dir='ltr'].ltr\:-top-224 {
  top: -22.4rem
}

[dir='ltr'] .ltr\:-right-224,[dir='ltr'].ltr\:-right-224 {
  right: -22.4rem
}

[dir='ltr'] .ltr\:-bottom-224,[dir='ltr'].ltr\:-bottom-224 {
  bottom: -22.4rem
}

[dir='ltr'] .ltr\:-left-224,[dir='ltr'].ltr\:-left-224 {
  left: -22.4rem
}

[dir='ltr'] .ltr\:-top-256,[dir='ltr'].ltr\:-top-256 {
  top: -25.6rem
}

[dir='ltr'] .ltr\:-right-256,[dir='ltr'].ltr\:-right-256 {
  right: -25.6rem
}

[dir='ltr'] .ltr\:-bottom-256,[dir='ltr'].ltr\:-bottom-256 {
  bottom: -25.6rem
}

[dir='ltr'] .ltr\:-left-256,[dir='ltr'].ltr\:-left-256 {
  left: -25.6rem
}

[dir='ltr'] .ltr\:-top-288,[dir='ltr'].ltr\:-top-288 {
  top: -28.8rem
}

[dir='ltr'] .ltr\:-right-288,[dir='ltr'].ltr\:-right-288 {
  right: -28.8rem
}

[dir='ltr'] .ltr\:-bottom-288,[dir='ltr'].ltr\:-bottom-288 {
  bottom: -28.8rem
}

[dir='ltr'] .ltr\:-left-288,[dir='ltr'].ltr\:-left-288 {
  left: -28.8rem
}

[dir='ltr'] .ltr\:-top-320,[dir='ltr'].ltr\:-top-320 {
  top: -32rem
}

[dir='ltr'] .ltr\:-right-320,[dir='ltr'].ltr\:-right-320 {
  right: -32rem
}

[dir='ltr'] .ltr\:-bottom-320,[dir='ltr'].ltr\:-bottom-320 {
  bottom: -32rem
}

[dir='ltr'] .ltr\:-left-320,[dir='ltr'].ltr\:-left-320 {
  left: -32rem
}

[dir='ltr'] .ltr\:-top-360,[dir='ltr'].ltr\:-top-360 {
  top: -36rem
}

[dir='ltr'] .ltr\:-right-360,[dir='ltr'].ltr\:-right-360 {
  right: -36rem
}

[dir='ltr'] .ltr\:-bottom-360,[dir='ltr'].ltr\:-bottom-360 {
  bottom: -36rem
}

[dir='ltr'] .ltr\:-left-360,[dir='ltr'].ltr\:-left-360 {
  left: -36rem
}

[dir='ltr'] .ltr\:-top-384,[dir='ltr'].ltr\:-top-384 {
  top: -38.4rem
}

[dir='ltr'] .ltr\:-right-384,[dir='ltr'].ltr\:-right-384 {
  right: -38.4rem
}

[dir='ltr'] .ltr\:-bottom-384,[dir='ltr'].ltr\:-bottom-384 {
  bottom: -38.4rem
}

[dir='ltr'] .ltr\:-left-384,[dir='ltr'].ltr\:-left-384 {
  left: -38.4rem
}

[dir='ltr'] .ltr\:-top-400,[dir='ltr'].ltr\:-top-400 {
  top: -40rem
}

[dir='ltr'] .ltr\:-right-400,[dir='ltr'].ltr\:-right-400 {
  right: -40rem
}

[dir='ltr'] .ltr\:-bottom-400,[dir='ltr'].ltr\:-bottom-400 {
  bottom: -40rem
}

[dir='ltr'] .ltr\:-left-400,[dir='ltr'].ltr\:-left-400 {
  left: -40rem
}

[dir='ltr'] .ltr\:-top-512,[dir='ltr'].ltr\:-top-512 {
  top: -51.2rem
}

[dir='ltr'] .ltr\:-right-512,[dir='ltr'].ltr\:-right-512 {
  right: -51.2rem
}

[dir='ltr'] .ltr\:-bottom-512,[dir='ltr'].ltr\:-bottom-512 {
  bottom: -51.2rem
}

[dir='ltr'] .ltr\:-left-512,[dir='ltr'].ltr\:-left-512 {
  left: -51.2rem
}

[dir='ltr'] .ltr\:-top-640,[dir='ltr'].ltr\:-top-640 {
  top: -64rem
}

[dir='ltr'] .ltr\:-right-640,[dir='ltr'].ltr\:-right-640 {
  right: -64rem
}

[dir='ltr'] .ltr\:-bottom-640,[dir='ltr'].ltr\:-bottom-640 {
  bottom: -64rem
}

[dir='ltr'] .ltr\:-left-640,[dir='ltr'].ltr\:-left-640 {
  left: -64rem
}

[dir='ltr'] .ltr\:-top-xs,[dir='ltr'].ltr\:-top-xs {
  top: -32rem
}

[dir='ltr'] .ltr\:-right-xs,[dir='ltr'].ltr\:-right-xs {
  right: -32rem
}

[dir='ltr'] .ltr\:-bottom-xs,[dir='ltr'].ltr\:-bottom-xs {
  bottom: -32rem
}

[dir='ltr'] .ltr\:-left-xs,[dir='ltr'].ltr\:-left-xs {
  left: -32rem
}

[dir='ltr'] .ltr\:-top-sm,[dir='ltr'].ltr\:-top-sm {
  top: -48rem
}

[dir='ltr'] .ltr\:-right-sm,[dir='ltr'].ltr\:-right-sm {
  right: -48rem
}

[dir='ltr'] .ltr\:-bottom-sm,[dir='ltr'].ltr\:-bottom-sm {
  bottom: -48rem
}

[dir='ltr'] .ltr\:-left-sm,[dir='ltr'].ltr\:-left-sm {
  left: -48rem
}

[dir='ltr'] .ltr\:-top-md,[dir='ltr'].ltr\:-top-md {
  top: -64rem
}

[dir='ltr'] .ltr\:-right-md,[dir='ltr'].ltr\:-right-md {
  right: -64rem
}

[dir='ltr'] .ltr\:-bottom-md,[dir='ltr'].ltr\:-bottom-md {
  bottom: -64rem
}

[dir='ltr'] .ltr\:-left-md,[dir='ltr'].ltr\:-left-md {
  left: -64rem
}

[dir='ltr'] .ltr\:-top-lg,[dir='ltr'].ltr\:-top-lg {
  top: -80rem
}

[dir='ltr'] .ltr\:-right-lg,[dir='ltr'].ltr\:-right-lg {
  right: -80rem
}

[dir='ltr'] .ltr\:-bottom-lg,[dir='ltr'].ltr\:-bottom-lg {
  bottom: -80rem
}

[dir='ltr'] .ltr\:-left-lg,[dir='ltr'].ltr\:-left-lg {
  left: -80rem
}

[dir='ltr'] .ltr\:-top-xl,[dir='ltr'].ltr\:-top-xl {
  top: -96rem
}

[dir='ltr'] .ltr\:-right-xl,[dir='ltr'].ltr\:-right-xl {
  right: -96rem
}

[dir='ltr'] .ltr\:-bottom-xl,[dir='ltr'].ltr\:-bottom-xl {
  bottom: -96rem
}

[dir='ltr'] .ltr\:-left-xl,[dir='ltr'].ltr\:-left-xl {
  left: -96rem
}

[dir='ltr'] .ltr\:-top-2xl,[dir='ltr'].ltr\:-top-2xl {
  top: -112rem
}

[dir='ltr'] .ltr\:-right-2xl,[dir='ltr'].ltr\:-right-2xl {
  right: -112rem
}

[dir='ltr'] .ltr\:-bottom-2xl,[dir='ltr'].ltr\:-bottom-2xl {
  bottom: -112rem
}

[dir='ltr'] .ltr\:-left-2xl,[dir='ltr'].ltr\:-left-2xl {
  left: -112rem
}

[dir='ltr'] .ltr\:-top-3xl,[dir='ltr'].ltr\:-top-3xl {
  top: -128rem
}

[dir='ltr'] .ltr\:-right-3xl,[dir='ltr'].ltr\:-right-3xl {
  right: -128rem
}

[dir='ltr'] .ltr\:-bottom-3xl,[dir='ltr'].ltr\:-bottom-3xl {
  bottom: -128rem
}

[dir='ltr'] .ltr\:-left-3xl,[dir='ltr'].ltr\:-left-3xl {
  left: -128rem
}

[dir='ltr'] .ltr\:-top-4xl,[dir='ltr'].ltr\:-top-4xl {
  top: -144rem
}

[dir='ltr'] .ltr\:-right-4xl,[dir='ltr'].ltr\:-right-4xl {
  right: -144rem
}

[dir='ltr'] .ltr\:-bottom-4xl,[dir='ltr'].ltr\:-bottom-4xl {
  bottom: -144rem
}

[dir='ltr'] .ltr\:-left-4xl,[dir='ltr'].ltr\:-left-4xl {
  left: -144rem
}

[dir='ltr'] .ltr\:-top-5xl,[dir='ltr'].ltr\:-top-5xl {
  top: -160rem
}

[dir='ltr'] .ltr\:-right-5xl,[dir='ltr'].ltr\:-right-5xl {
  right: -160rem
}

[dir='ltr'] .ltr\:-bottom-5xl,[dir='ltr'].ltr\:-bottom-5xl {
  bottom: -160rem
}

[dir='ltr'] .ltr\:-left-5xl,[dir='ltr'].ltr\:-left-5xl {
  left: -160rem
}

[dir='ltr'] .ltr\:-top-px,[dir='ltr'].ltr\:-top-px {
  top: -1px
}

[dir='ltr'] .ltr\:-right-px,[dir='ltr'].ltr\:-right-px {
  right: -1px
}

[dir='ltr'] .ltr\:-bottom-px,[dir='ltr'].ltr\:-bottom-px {
  bottom: -1px
}

[dir='ltr'] .ltr\:-left-px,[dir='ltr'].ltr\:-left-px {
  left: -1px
}

[dir='ltr'] .ltr\:-top-0\.5,[dir='ltr'].ltr\:-top-0\.5 {
  top: -0.05rem
}

[dir='ltr'] .ltr\:-right-0\.5,[dir='ltr'].ltr\:-right-0\.5 {
  right: -0.05rem
}

[dir='ltr'] .ltr\:-bottom-0\.5,[dir='ltr'].ltr\:-bottom-0\.5 {
  bottom: -0.05rem
}

[dir='ltr'] .ltr\:-left-0\.5,[dir='ltr'].ltr\:-left-0\.5 {
  left: -0.05rem
}

[dir='ltr'] .ltr\:-top-1\.5,[dir='ltr'].ltr\:-top-1\.5 {
  top: -0.15rem
}

[dir='ltr'] .ltr\:-right-1\.5,[dir='ltr'].ltr\:-right-1\.5 {
  right: -0.15rem
}

[dir='ltr'] .ltr\:-bottom-1\.5,[dir='ltr'].ltr\:-bottom-1\.5 {
  bottom: -0.15rem
}

[dir='ltr'] .ltr\:-left-1\.5,[dir='ltr'].ltr\:-left-1\.5 {
  left: -0.15rem
}

[dir='ltr'] .ltr\:-top-2\.5,[dir='ltr'].ltr\:-top-2\.5 {
  top: -0.25rem
}

[dir='ltr'] .ltr\:-right-2\.5,[dir='ltr'].ltr\:-right-2\.5 {
  right: -0.25rem
}

[dir='ltr'] .ltr\:-bottom-2\.5,[dir='ltr'].ltr\:-bottom-2\.5 {
  bottom: -0.25rem
}

[dir='ltr'] .ltr\:-left-2\.5,[dir='ltr'].ltr\:-left-2\.5 {
  left: -0.25rem
}

[dir='ltr'] .ltr\:-top-3\.5,[dir='ltr'].ltr\:-top-3\.5 {
  top: -0.35rem
}

[dir='ltr'] .ltr\:-right-3\.5,[dir='ltr'].ltr\:-right-3\.5 {
  right: -0.35rem
}

[dir='ltr'] .ltr\:-bottom-3\.5,[dir='ltr'].ltr\:-bottom-3\.5 {
  bottom: -0.35rem
}

[dir='ltr'] .ltr\:-left-3\.5,[dir='ltr'].ltr\:-left-3\.5 {
  left: -0.35rem
}

[dir='ltr'] .ltr\:top-1\/2,[dir='ltr'].ltr\:top-1\/2 {
  top: 50%
}

[dir='ltr'] .ltr\:right-1\/2,[dir='ltr'].ltr\:right-1\/2 {
  right: 50%
}

[dir='ltr'] .ltr\:bottom-1\/2,[dir='ltr'].ltr\:bottom-1\/2 {
  bottom: 50%
}

[dir='ltr'] .ltr\:left-1\/2,[dir='ltr'].ltr\:left-1\/2 {
  left: 50%
}

[dir='ltr'] .ltr\:top-1\/3,[dir='ltr'].ltr\:top-1\/3 {
  top: 33.333333%
}

[dir='ltr'] .ltr\:right-1\/3,[dir='ltr'].ltr\:right-1\/3 {
  right: 33.333333%
}

[dir='ltr'] .ltr\:bottom-1\/3,[dir='ltr'].ltr\:bottom-1\/3 {
  bottom: 33.333333%
}

[dir='ltr'] .ltr\:left-1\/3,[dir='ltr'].ltr\:left-1\/3 {
  left: 33.333333%
}

[dir='ltr'] .ltr\:top-2\/3,[dir='ltr'].ltr\:top-2\/3 {
  top: 66.666667%
}

[dir='ltr'] .ltr\:right-2\/3,[dir='ltr'].ltr\:right-2\/3 {
  right: 66.666667%
}

[dir='ltr'] .ltr\:bottom-2\/3,[dir='ltr'].ltr\:bottom-2\/3 {
  bottom: 66.666667%
}

[dir='ltr'] .ltr\:left-2\/3,[dir='ltr'].ltr\:left-2\/3 {
  left: 66.666667%
}

[dir='ltr'] .ltr\:top-1\/4,[dir='ltr'].ltr\:top-1\/4 {
  top: 25%
}

[dir='ltr'] .ltr\:right-1\/4,[dir='ltr'].ltr\:right-1\/4 {
  right: 25%
}

[dir='ltr'] .ltr\:bottom-1\/4,[dir='ltr'].ltr\:bottom-1\/4 {
  bottom: 25%
}

[dir='ltr'] .ltr\:left-1\/4,[dir='ltr'].ltr\:left-1\/4 {
  left: 25%
}

[dir='ltr'] .ltr\:top-2\/4,[dir='ltr'].ltr\:top-2\/4 {
  top: 50%
}

[dir='ltr'] .ltr\:right-2\/4,[dir='ltr'].ltr\:right-2\/4 {
  right: 50%
}

[dir='ltr'] .ltr\:bottom-2\/4,[dir='ltr'].ltr\:bottom-2\/4 {
  bottom: 50%
}

[dir='ltr'] .ltr\:left-2\/4,[dir='ltr'].ltr\:left-2\/4 {
  left: 50%
}

[dir='ltr'] .ltr\:top-3\/4,[dir='ltr'].ltr\:top-3\/4 {
  top: 75%
}

[dir='ltr'] .ltr\:right-3\/4,[dir='ltr'].ltr\:right-3\/4 {
  right: 75%
}

[dir='ltr'] .ltr\:bottom-3\/4,[dir='ltr'].ltr\:bottom-3\/4 {
  bottom: 75%
}

[dir='ltr'] .ltr\:left-3\/4,[dir='ltr'].ltr\:left-3\/4 {
  left: 75%
}

[dir='ltr'] .ltr\:top-full,[dir='ltr'].ltr\:top-full {
  top: 100%
}

[dir='ltr'] .ltr\:right-full,[dir='ltr'].ltr\:right-full {
  right: 100%
}

[dir='ltr'] .ltr\:bottom-full,[dir='ltr'].ltr\:bottom-full {
  bottom: 100%
}

[dir='ltr'] .ltr\:left-full,[dir='ltr'].ltr\:left-full {
  left: 100%
}

[dir='ltr'] .ltr\:-top-1\/2,[dir='ltr'].ltr\:-top-1\/2 {
  top: -50%
}

[dir='ltr'] .ltr\:-right-1\/2,[dir='ltr'].ltr\:-right-1\/2 {
  right: -50%
}

[dir='ltr'] .ltr\:-bottom-1\/2,[dir='ltr'].ltr\:-bottom-1\/2 {
  bottom: -50%
}

[dir='ltr'] .ltr\:-left-1\/2,[dir='ltr'].ltr\:-left-1\/2 {
  left: -50%
}

[dir='ltr'] .ltr\:-top-1\/3,[dir='ltr'].ltr\:-top-1\/3 {
  top: -33.333333%
}

[dir='ltr'] .ltr\:-right-1\/3,[dir='ltr'].ltr\:-right-1\/3 {
  right: -33.333333%
}

[dir='ltr'] .ltr\:-bottom-1\/3,[dir='ltr'].ltr\:-bottom-1\/3 {
  bottom: -33.333333%
}

[dir='ltr'] .ltr\:-left-1\/3,[dir='ltr'].ltr\:-left-1\/3 {
  left: -33.333333%
}

[dir='ltr'] .ltr\:-top-2\/3,[dir='ltr'].ltr\:-top-2\/3 {
  top: -66.666667%
}

[dir='ltr'] .ltr\:-right-2\/3,[dir='ltr'].ltr\:-right-2\/3 {
  right: -66.666667%
}

[dir='ltr'] .ltr\:-bottom-2\/3,[dir='ltr'].ltr\:-bottom-2\/3 {
  bottom: -66.666667%
}

[dir='ltr'] .ltr\:-left-2\/3,[dir='ltr'].ltr\:-left-2\/3 {
  left: -66.666667%
}

[dir='ltr'] .ltr\:-top-1\/4,[dir='ltr'].ltr\:-top-1\/4 {
  top: -25%
}

[dir='ltr'] .ltr\:-right-1\/4,[dir='ltr'].ltr\:-right-1\/4 {
  right: -25%
}

[dir='ltr'] .ltr\:-bottom-1\/4,[dir='ltr'].ltr\:-bottom-1\/4 {
  bottom: -25%
}

[dir='ltr'] .ltr\:-left-1\/4,[dir='ltr'].ltr\:-left-1\/4 {
  left: -25%
}

[dir='ltr'] .ltr\:-top-2\/4,[dir='ltr'].ltr\:-top-2\/4 {
  top: -50%
}

[dir='ltr'] .ltr\:-right-2\/4,[dir='ltr'].ltr\:-right-2\/4 {
  right: -50%
}

[dir='ltr'] .ltr\:-bottom-2\/4,[dir='ltr'].ltr\:-bottom-2\/4 {
  bottom: -50%
}

[dir='ltr'] .ltr\:-left-2\/4,[dir='ltr'].ltr\:-left-2\/4 {
  left: -50%
}

[dir='ltr'] .ltr\:-top-3\/4,[dir='ltr'].ltr\:-top-3\/4 {
  top: -75%
}

[dir='ltr'] .ltr\:-right-3\/4,[dir='ltr'].ltr\:-right-3\/4 {
  right: -75%
}

[dir='ltr'] .ltr\:-bottom-3\/4,[dir='ltr'].ltr\:-bottom-3\/4 {
  bottom: -75%
}

[dir='ltr'] .ltr\:-left-3\/4,[dir='ltr'].ltr\:-left-3\/4 {
  left: -75%
}

[dir='ltr'] .ltr\:-top-full,[dir='ltr'].ltr\:-top-full {
  top: -100%
}

[dir='ltr'] .ltr\:-right-full,[dir='ltr'].ltr\:-right-full {
  right: -100%
}

[dir='ltr'] .ltr\:-bottom-full,[dir='ltr'].ltr\:-bottom-full {
  bottom: -100%
}

[dir='ltr'] .ltr\:-left-full,[dir='ltr'].ltr\:-left-full {
  left: -100%
}

[dir='rtl'] .rtl\:inset-0,[dir='rtl'].rtl\:inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

[dir='rtl'] .rtl\:inset-1,[dir='rtl'].rtl\:inset-1 {
  top: 0.1rem;
  right: 0.1rem;
  bottom: 0.1rem;
  left: 0.1rem
}

[dir='rtl'] .rtl\:inset-2,[dir='rtl'].rtl\:inset-2 {
  top: 0.2rem;
  right: 0.2rem;
  bottom: 0.2rem;
  left: 0.2rem
}

[dir='rtl'] .rtl\:inset-3,[dir='rtl'].rtl\:inset-3 {
  top: 0.3rem;
  right: 0.3rem;
  bottom: 0.3rem;
  left: 0.3rem
}

[dir='rtl'] .rtl\:inset-4,[dir='rtl'].rtl\:inset-4 {
  top: 0.4rem;
  right: 0.4rem;
  bottom: 0.4rem;
  left: 0.4rem
}

[dir='rtl'] .rtl\:inset-5,[dir='rtl'].rtl\:inset-5 {
  top: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem
}

[dir='rtl'] .rtl\:inset-6,[dir='rtl'].rtl\:inset-6 {
  top: 0.6rem;
  right: 0.6rem;
  bottom: 0.6rem;
  left: 0.6rem
}

[dir='rtl'] .rtl\:inset-7,[dir='rtl'].rtl\:inset-7 {
  top: 0.7rem;
  right: 0.7rem;
  bottom: 0.7rem;
  left: 0.7rem
}

[dir='rtl'] .rtl\:inset-8,[dir='rtl'].rtl\:inset-8 {
  top: 0.8rem;
  right: 0.8rem;
  bottom: 0.8rem;
  left: 0.8rem
}

[dir='rtl'] .rtl\:inset-9,[dir='rtl'].rtl\:inset-9 {
  top: 0.9rem;
  right: 0.9rem;
  bottom: 0.9rem;
  left: 0.9rem
}

[dir='rtl'] .rtl\:inset-10,[dir='rtl'].rtl\:inset-10 {
  top: 1.0rem;
  right: 1.0rem;
  bottom: 1.0rem;
  left: 1.0rem
}

[dir='rtl'] .rtl\:inset-12,[dir='rtl'].rtl\:inset-12 {
  top: 1.2rem;
  right: 1.2rem;
  bottom: 1.2rem;
  left: 1.2rem
}

[dir='rtl'] .rtl\:inset-14,[dir='rtl'].rtl\:inset-14 {
  top: 1.4rem;
  right: 1.4rem;
  bottom: 1.4rem;
  left: 1.4rem
}

[dir='rtl'] .rtl\:inset-16,[dir='rtl'].rtl\:inset-16 {
  top: 1.6rem;
  right: 1.6rem;
  bottom: 1.6rem;
  left: 1.6rem
}

[dir='rtl'] .rtl\:inset-20,[dir='rtl'].rtl\:inset-20 {
  top: 2rem;
  right: 2rem;
  bottom: 2rem;
  left: 2rem
}

[dir='rtl'] .rtl\:inset-24,[dir='rtl'].rtl\:inset-24 {
  top: 2.4rem;
  right: 2.4rem;
  bottom: 2.4rem;
  left: 2.4rem
}

[dir='rtl'] .rtl\:inset-28,[dir='rtl'].rtl\:inset-28 {
  top: 2.8rem;
  right: 2.8rem;
  bottom: 2.8rem;
  left: 2.8rem
}

[dir='rtl'] .rtl\:inset-32,[dir='rtl'].rtl\:inset-32 {
  top: 3.2rem;
  right: 3.2rem;
  bottom: 3.2rem;
  left: 3.2rem
}

[dir='rtl'] .rtl\:inset-36,[dir='rtl'].rtl\:inset-36 {
  top: 3.6rem;
  right: 3.6rem;
  bottom: 3.6rem;
  left: 3.6rem
}

[dir='rtl'] .rtl\:inset-40,[dir='rtl'].rtl\:inset-40 {
  top: 4rem;
  right: 4rem;
  bottom: 4rem;
  left: 4rem
}

[dir='rtl'] .rtl\:inset-44,[dir='rtl'].rtl\:inset-44 {
  top: 4.4rem;
  right: 4.4rem;
  bottom: 4.4rem;
  left: 4.4rem
}

[dir='rtl'] .rtl\:inset-48,[dir='rtl'].rtl\:inset-48 {
  top: 4.8rem;
  right: 4.8rem;
  bottom: 4.8rem;
  left: 4.8rem
}

[dir='rtl'] .rtl\:inset-52,[dir='rtl'].rtl\:inset-52 {
  top: 5.2rem;
  right: 5.2rem;
  bottom: 5.2rem;
  left: 5.2rem
}

[dir='rtl'] .rtl\:inset-56,[dir='rtl'].rtl\:inset-56 {
  top: 5.6rem;
  right: 5.6rem;
  bottom: 5.6rem;
  left: 5.6rem
}

[dir='rtl'] .rtl\:inset-60,[dir='rtl'].rtl\:inset-60 {
  top: 6rem;
  right: 6rem;
  bottom: 6rem;
  left: 6rem
}

[dir='rtl'] .rtl\:inset-64,[dir='rtl'].rtl\:inset-64 {
  top: 6.4rem;
  right: 6.4rem;
  bottom: 6.4rem;
  left: 6.4rem
}

[dir='rtl'] .rtl\:inset-68,[dir='rtl'].rtl\:inset-68 {
  top: 6.8rem;
  right: 6.8rem;
  bottom: 6.8rem;
  left: 6.8rem
}

[dir='rtl'] .rtl\:inset-72,[dir='rtl'].rtl\:inset-72 {
  top: 7.2rem;
  right: 7.2rem;
  bottom: 7.2rem;
  left: 7.2rem
}

[dir='rtl'] .rtl\:inset-76,[dir='rtl'].rtl\:inset-76 {
  top: 7.6rem;
  right: 7.6rem;
  bottom: 7.6rem;
  left: 7.6rem
}

[dir='rtl'] .rtl\:inset-80,[dir='rtl'].rtl\:inset-80 {
  top: 8rem;
  right: 8rem;
  bottom: 8rem;
  left: 8rem
}

[dir='rtl'] .rtl\:inset-84,[dir='rtl'].rtl\:inset-84 {
  top: 8.4rem;
  right: 8.4rem;
  bottom: 8.4rem;
  left: 8.4rem
}

[dir='rtl'] .rtl\:inset-88,[dir='rtl'].rtl\:inset-88 {
  top: 8.8rem;
  right: 8.8rem;
  bottom: 8.8rem;
  left: 8.8rem
}

[dir='rtl'] .rtl\:inset-92,[dir='rtl'].rtl\:inset-92 {
  top: 9.2rem;
  right: 9.2rem;
  bottom: 9.2rem;
  left: 9.2rem
}

[dir='rtl'] .rtl\:inset-96,[dir='rtl'].rtl\:inset-96 {
  top: 9.6rem;
  right: 9.6rem;
  bottom: 9.6rem;
  left: 9.6rem
}

[dir='rtl'] .rtl\:inset-128,[dir='rtl'].rtl\:inset-128 {
  top: 12.8rem;
  right: 12.8rem;
  bottom: 12.8rem;
  left: 12.8rem
}

[dir='rtl'] .rtl\:inset-136,[dir='rtl'].rtl\:inset-136 {
  top: 13.6rem;
  right: 13.6rem;
  bottom: 13.6rem;
  left: 13.6rem
}

[dir='rtl'] .rtl\:inset-160,[dir='rtl'].rtl\:inset-160 {
  top: 16rem;
  right: 16rem;
  bottom: 16rem;
  left: 16rem
}

[dir='rtl'] .rtl\:inset-192,[dir='rtl'].rtl\:inset-192 {
  top: 19.2rem;
  right: 19.2rem;
  bottom: 19.2rem;
  left: 19.2rem
}

[dir='rtl'] .rtl\:inset-200,[dir='rtl'].rtl\:inset-200 {
  top: 20rem;
  right: 20rem;
  bottom: 20rem;
  left: 20rem
}

[dir='rtl'] .rtl\:inset-208,[dir='rtl'].rtl\:inset-208 {
  top: 20.8rem;
  right: 20.8rem;
  bottom: 20.8rem;
  left: 20.8rem
}

[dir='rtl'] .rtl\:inset-216,[dir='rtl'].rtl\:inset-216 {
  top: 21.6rem;
  right: 21.6rem;
  bottom: 21.6rem;
  left: 21.6rem
}

[dir='rtl'] .rtl\:inset-224,[dir='rtl'].rtl\:inset-224 {
  top: 22.4rem;
  right: 22.4rem;
  bottom: 22.4rem;
  left: 22.4rem
}

[dir='rtl'] .rtl\:inset-256,[dir='rtl'].rtl\:inset-256 {
  top: 25.6rem;
  right: 25.6rem;
  bottom: 25.6rem;
  left: 25.6rem
}

[dir='rtl'] .rtl\:inset-288,[dir='rtl'].rtl\:inset-288 {
  top: 28.8rem;
  right: 28.8rem;
  bottom: 28.8rem;
  left: 28.8rem
}

[dir='rtl'] .rtl\:inset-320,[dir='rtl'].rtl\:inset-320 {
  top: 32rem;
  right: 32rem;
  bottom: 32rem;
  left: 32rem
}

[dir='rtl'] .rtl\:inset-360,[dir='rtl'].rtl\:inset-360 {
  top: 36rem;
  right: 36rem;
  bottom: 36rem;
  left: 36rem
}

[dir='rtl'] .rtl\:inset-384,[dir='rtl'].rtl\:inset-384 {
  top: 38.4rem;
  right: 38.4rem;
  bottom: 38.4rem;
  left: 38.4rem
}

[dir='rtl'] .rtl\:inset-400,[dir='rtl'].rtl\:inset-400 {
  top: 40rem;
  right: 40rem;
  bottom: 40rem;
  left: 40rem
}

[dir='rtl'] .rtl\:inset-512,[dir='rtl'].rtl\:inset-512 {
  top: 51.2rem;
  right: 51.2rem;
  bottom: 51.2rem;
  left: 51.2rem
}

[dir='rtl'] .rtl\:inset-640,[dir='rtl'].rtl\:inset-640 {
  top: 64rem;
  right: 64rem;
  bottom: 64rem;
  left: 64rem
}

[dir='rtl'] .rtl\:inset-auto,[dir='rtl'].rtl\:inset-auto {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto
}

[dir='rtl'] .rtl\:inset-xs,[dir='rtl'].rtl\:inset-xs {
  top: 32rem;
  right: 32rem;
  bottom: 32rem;
  left: 32rem
}

[dir='rtl'] .rtl\:inset-sm,[dir='rtl'].rtl\:inset-sm {
  top: 48rem;
  right: 48rem;
  bottom: 48rem;
  left: 48rem
}

[dir='rtl'] .rtl\:inset-md,[dir='rtl'].rtl\:inset-md {
  top: 64rem;
  right: 64rem;
  bottom: 64rem;
  left: 64rem
}

[dir='rtl'] .rtl\:inset-lg,[dir='rtl'].rtl\:inset-lg {
  top: 80rem;
  right: 80rem;
  bottom: 80rem;
  left: 80rem
}

[dir='rtl'] .rtl\:inset-xl,[dir='rtl'].rtl\:inset-xl {
  top: 96rem;
  right: 96rem;
  bottom: 96rem;
  left: 96rem
}

[dir='rtl'] .rtl\:inset-2xl,[dir='rtl'].rtl\:inset-2xl {
  top: 112rem;
  right: 112rem;
  bottom: 112rem;
  left: 112rem
}

[dir='rtl'] .rtl\:inset-3xl,[dir='rtl'].rtl\:inset-3xl {
  top: 128rem;
  right: 128rem;
  bottom: 128rem;
  left: 128rem
}

[dir='rtl'] .rtl\:inset-4xl,[dir='rtl'].rtl\:inset-4xl {
  top: 144rem;
  right: 144rem;
  bottom: 144rem;
  left: 144rem
}

[dir='rtl'] .rtl\:inset-5xl,[dir='rtl'].rtl\:inset-5xl {
  top: 160rem;
  right: 160rem;
  bottom: 160rem;
  left: 160rem
}

[dir='rtl'] .rtl\:inset-px,[dir='rtl'].rtl\:inset-px {
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px
}

[dir='rtl'] .rtl\:inset-0\.5,[dir='rtl'].rtl\:inset-0\.5 {
  top: 0.05rem;
  right: 0.05rem;
  bottom: 0.05rem;
  left: 0.05rem
}

[dir='rtl'] .rtl\:inset-1\.5,[dir='rtl'].rtl\:inset-1\.5 {
  top: 0.15rem;
  right: 0.15rem;
  bottom: 0.15rem;
  left: 0.15rem
}

[dir='rtl'] .rtl\:inset-2\.5,[dir='rtl'].rtl\:inset-2\.5 {
  top: 0.25rem;
  right: 0.25rem;
  bottom: 0.25rem;
  left: 0.25rem
}

[dir='rtl'] .rtl\:inset-3\.5,[dir='rtl'].rtl\:inset-3\.5 {
  top: 0.35rem;
  right: 0.35rem;
  bottom: 0.35rem;
  left: 0.35rem
}

[dir='rtl'] .rtl\:-inset-1,[dir='rtl'].rtl\:-inset-1 {
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem
}

[dir='rtl'] .rtl\:-inset-2,[dir='rtl'].rtl\:-inset-2 {
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem
}

[dir='rtl'] .rtl\:-inset-3,[dir='rtl'].rtl\:-inset-3 {
  top: -0.3rem;
  right: -0.3rem;
  bottom: -0.3rem;
  left: -0.3rem
}

[dir='rtl'] .rtl\:-inset-4,[dir='rtl'].rtl\:-inset-4 {
  top: -0.4rem;
  right: -0.4rem;
  bottom: -0.4rem;
  left: -0.4rem
}

[dir='rtl'] .rtl\:-inset-5,[dir='rtl'].rtl\:-inset-5 {
  top: -0.5rem;
  right: -0.5rem;
  bottom: -0.5rem;
  left: -0.5rem
}

[dir='rtl'] .rtl\:-inset-6,[dir='rtl'].rtl\:-inset-6 {
  top: -0.6rem;
  right: -0.6rem;
  bottom: -0.6rem;
  left: -0.6rem
}

[dir='rtl'] .rtl\:-inset-7,[dir='rtl'].rtl\:-inset-7 {
  top: -0.7rem;
  right: -0.7rem;
  bottom: -0.7rem;
  left: -0.7rem
}

[dir='rtl'] .rtl\:-inset-8,[dir='rtl'].rtl\:-inset-8 {
  top: -0.8rem;
  right: -0.8rem;
  bottom: -0.8rem;
  left: -0.8rem
}

[dir='rtl'] .rtl\:-inset-9,[dir='rtl'].rtl\:-inset-9 {
  top: -0.9rem;
  right: -0.9rem;
  bottom: -0.9rem;
  left: -0.9rem
}

[dir='rtl'] .rtl\:-inset-10,[dir='rtl'].rtl\:-inset-10 {
  top: -1rem;
  right: -1rem;
  bottom: -1rem;
  left: -1rem
}

[dir='rtl'] .rtl\:-inset-12,[dir='rtl'].rtl\:-inset-12 {
  top: -1.2rem;
  right: -1.2rem;
  bottom: -1.2rem;
  left: -1.2rem
}

[dir='rtl'] .rtl\:-inset-14,[dir='rtl'].rtl\:-inset-14 {
  top: -1.4rem;
  right: -1.4rem;
  bottom: -1.4rem;
  left: -1.4rem
}

[dir='rtl'] .rtl\:-inset-16,[dir='rtl'].rtl\:-inset-16 {
  top: -1.6rem;
  right: -1.6rem;
  bottom: -1.6rem;
  left: -1.6rem
}

[dir='rtl'] .rtl\:-inset-20,[dir='rtl'].rtl\:-inset-20 {
  top: -2rem;
  right: -2rem;
  bottom: -2rem;
  left: -2rem
}

[dir='rtl'] .rtl\:-inset-24,[dir='rtl'].rtl\:-inset-24 {
  top: -2.4rem;
  right: -2.4rem;
  bottom: -2.4rem;
  left: -2.4rem
}

[dir='rtl'] .rtl\:-inset-28,[dir='rtl'].rtl\:-inset-28 {
  top: -2.8rem;
  right: -2.8rem;
  bottom: -2.8rem;
  left: -2.8rem
}

[dir='rtl'] .rtl\:-inset-32,[dir='rtl'].rtl\:-inset-32 {
  top: -3.2rem;
  right: -3.2rem;
  bottom: -3.2rem;
  left: -3.2rem
}

[dir='rtl'] .rtl\:-inset-36,[dir='rtl'].rtl\:-inset-36 {
  top: -3.6rem;
  right: -3.6rem;
  bottom: -3.6rem;
  left: -3.6rem
}

[dir='rtl'] .rtl\:-inset-40,[dir='rtl'].rtl\:-inset-40 {
  top: -4rem;
  right: -4rem;
  bottom: -4rem;
  left: -4rem
}

[dir='rtl'] .rtl\:-inset-44,[dir='rtl'].rtl\:-inset-44 {
  top: -4.4rem;
  right: -4.4rem;
  bottom: -4.4rem;
  left: -4.4rem
}

[dir='rtl'] .rtl\:-inset-48,[dir='rtl'].rtl\:-inset-48 {
  top: -4.8rem;
  right: -4.8rem;
  bottom: -4.8rem;
  left: -4.8rem
}

[dir='rtl'] .rtl\:-inset-52,[dir='rtl'].rtl\:-inset-52 {
  top: -5.2rem;
  right: -5.2rem;
  bottom: -5.2rem;
  left: -5.2rem
}

[dir='rtl'] .rtl\:-inset-56,[dir='rtl'].rtl\:-inset-56 {
  top: -5.6rem;
  right: -5.6rem;
  bottom: -5.6rem;
  left: -5.6rem
}

[dir='rtl'] .rtl\:-inset-60,[dir='rtl'].rtl\:-inset-60 {
  top: -6rem;
  right: -6rem;
  bottom: -6rem;
  left: -6rem
}

[dir='rtl'] .rtl\:-inset-64,[dir='rtl'].rtl\:-inset-64 {
  top: -6.4rem;
  right: -6.4rem;
  bottom: -6.4rem;
  left: -6.4rem
}

[dir='rtl'] .rtl\:-inset-68,[dir='rtl'].rtl\:-inset-68 {
  top: -6.8rem;
  right: -6.8rem;
  bottom: -6.8rem;
  left: -6.8rem
}

[dir='rtl'] .rtl\:-inset-72,[dir='rtl'].rtl\:-inset-72 {
  top: -7.2rem;
  right: -7.2rem;
  bottom: -7.2rem;
  left: -7.2rem
}

[dir='rtl'] .rtl\:-inset-76,[dir='rtl'].rtl\:-inset-76 {
  top: -7.6rem;
  right: -7.6rem;
  bottom: -7.6rem;
  left: -7.6rem
}

[dir='rtl'] .rtl\:-inset-80,[dir='rtl'].rtl\:-inset-80 {
  top: -8rem;
  right: -8rem;
  bottom: -8rem;
  left: -8rem
}

[dir='rtl'] .rtl\:-inset-84,[dir='rtl'].rtl\:-inset-84 {
  top: -8.4rem;
  right: -8.4rem;
  bottom: -8.4rem;
  left: -8.4rem
}

[dir='rtl'] .rtl\:-inset-88,[dir='rtl'].rtl\:-inset-88 {
  top: -8.8rem;
  right: -8.8rem;
  bottom: -8.8rem;
  left: -8.8rem
}

[dir='rtl'] .rtl\:-inset-92,[dir='rtl'].rtl\:-inset-92 {
  top: -9.2rem;
  right: -9.2rem;
  bottom: -9.2rem;
  left: -9.2rem
}

[dir='rtl'] .rtl\:-inset-96,[dir='rtl'].rtl\:-inset-96 {
  top: -9.6rem;
  right: -9.6rem;
  bottom: -9.6rem;
  left: -9.6rem
}

[dir='rtl'] .rtl\:-inset-128,[dir='rtl'].rtl\:-inset-128 {
  top: -12.8rem;
  right: -12.8rem;
  bottom: -12.8rem;
  left: -12.8rem
}

[dir='rtl'] .rtl\:-inset-136,[dir='rtl'].rtl\:-inset-136 {
  top: -13.6rem;
  right: -13.6rem;
  bottom: -13.6rem;
  left: -13.6rem
}

[dir='rtl'] .rtl\:-inset-160,[dir='rtl'].rtl\:-inset-160 {
  top: -16rem;
  right: -16rem;
  bottom: -16rem;
  left: -16rem
}

[dir='rtl'] .rtl\:-inset-192,[dir='rtl'].rtl\:-inset-192 {
  top: -19.2rem;
  right: -19.2rem;
  bottom: -19.2rem;
  left: -19.2rem
}

[dir='rtl'] .rtl\:-inset-200,[dir='rtl'].rtl\:-inset-200 {
  top: -20rem;
  right: -20rem;
  bottom: -20rem;
  left: -20rem
}

[dir='rtl'] .rtl\:-inset-208,[dir='rtl'].rtl\:-inset-208 {
  top: -20.8rem;
  right: -20.8rem;
  bottom: -20.8rem;
  left: -20.8rem
}

[dir='rtl'] .rtl\:-inset-216,[dir='rtl'].rtl\:-inset-216 {
  top: -21.6rem;
  right: -21.6rem;
  bottom: -21.6rem;
  left: -21.6rem
}

[dir='rtl'] .rtl\:-inset-224,[dir='rtl'].rtl\:-inset-224 {
  top: -22.4rem;
  right: -22.4rem;
  bottom: -22.4rem;
  left: -22.4rem
}

[dir='rtl'] .rtl\:-inset-256,[dir='rtl'].rtl\:-inset-256 {
  top: -25.6rem;
  right: -25.6rem;
  bottom: -25.6rem;
  left: -25.6rem
}

[dir='rtl'] .rtl\:-inset-288,[dir='rtl'].rtl\:-inset-288 {
  top: -28.8rem;
  right: -28.8rem;
  bottom: -28.8rem;
  left: -28.8rem
}

[dir='rtl'] .rtl\:-inset-320,[dir='rtl'].rtl\:-inset-320 {
  top: -32rem;
  right: -32rem;
  bottom: -32rem;
  left: -32rem
}

[dir='rtl'] .rtl\:-inset-360,[dir='rtl'].rtl\:-inset-360 {
  top: -36rem;
  right: -36rem;
  bottom: -36rem;
  left: -36rem
}

[dir='rtl'] .rtl\:-inset-384,[dir='rtl'].rtl\:-inset-384 {
  top: -38.4rem;
  right: -38.4rem;
  bottom: -38.4rem;
  left: -38.4rem
}

[dir='rtl'] .rtl\:-inset-400,[dir='rtl'].rtl\:-inset-400 {
  top: -40rem;
  right: -40rem;
  bottom: -40rem;
  left: -40rem
}

[dir='rtl'] .rtl\:-inset-512,[dir='rtl'].rtl\:-inset-512 {
  top: -51.2rem;
  right: -51.2rem;
  bottom: -51.2rem;
  left: -51.2rem
}

[dir='rtl'] .rtl\:-inset-640,[dir='rtl'].rtl\:-inset-640 {
  top: -64rem;
  right: -64rem;
  bottom: -64rem;
  left: -64rem
}

[dir='rtl'] .rtl\:-inset-xs,[dir='rtl'].rtl\:-inset-xs {
  top: -32rem;
  right: -32rem;
  bottom: -32rem;
  left: -32rem
}

[dir='rtl'] .rtl\:-inset-sm,[dir='rtl'].rtl\:-inset-sm {
  top: -48rem;
  right: -48rem;
  bottom: -48rem;
  left: -48rem
}

[dir='rtl'] .rtl\:-inset-md,[dir='rtl'].rtl\:-inset-md {
  top: -64rem;
  right: -64rem;
  bottom: -64rem;
  left: -64rem
}

[dir='rtl'] .rtl\:-inset-lg,[dir='rtl'].rtl\:-inset-lg {
  top: -80rem;
  right: -80rem;
  bottom: -80rem;
  left: -80rem
}

[dir='rtl'] .rtl\:-inset-xl,[dir='rtl'].rtl\:-inset-xl {
  top: -96rem;
  right: -96rem;
  bottom: -96rem;
  left: -96rem
}

[dir='rtl'] .rtl\:-inset-2xl,[dir='rtl'].rtl\:-inset-2xl {
  top: -112rem;
  right: -112rem;
  bottom: -112rem;
  left: -112rem
}

[dir='rtl'] .rtl\:-inset-3xl,[dir='rtl'].rtl\:-inset-3xl {
  top: -128rem;
  right: -128rem;
  bottom: -128rem;
  left: -128rem
}

[dir='rtl'] .rtl\:-inset-4xl,[dir='rtl'].rtl\:-inset-4xl {
  top: -144rem;
  right: -144rem;
  bottom: -144rem;
  left: -144rem
}

[dir='rtl'] .rtl\:-inset-5xl,[dir='rtl'].rtl\:-inset-5xl {
  top: -160rem;
  right: -160rem;
  bottom: -160rem;
  left: -160rem
}

[dir='rtl'] .rtl\:-inset-px,[dir='rtl'].rtl\:-inset-px {
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px
}

[dir='rtl'] .rtl\:-inset-0\.5,[dir='rtl'].rtl\:-inset-0\.5 {
  top: -0.05rem;
  right: -0.05rem;
  bottom: -0.05rem;
  left: -0.05rem
}

[dir='rtl'] .rtl\:-inset-1\.5,[dir='rtl'].rtl\:-inset-1\.5 {
  top: -0.15rem;
  right: -0.15rem;
  bottom: -0.15rem;
  left: -0.15rem
}

[dir='rtl'] .rtl\:-inset-2\.5,[dir='rtl'].rtl\:-inset-2\.5 {
  top: -0.25rem;
  right: -0.25rem;
  bottom: -0.25rem;
  left: -0.25rem
}

[dir='rtl'] .rtl\:-inset-3\.5,[dir='rtl'].rtl\:-inset-3\.5 {
  top: -0.35rem;
  right: -0.35rem;
  bottom: -0.35rem;
  left: -0.35rem
}

[dir='rtl'] .rtl\:inset-1\/2,[dir='rtl'].rtl\:inset-1\/2 {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%
}

[dir='rtl'] .rtl\:inset-1\/3,[dir='rtl'].rtl\:inset-1\/3 {
  top: 33.333333%;
  right: 33.333333%;
  bottom: 33.333333%;
  left: 33.333333%
}

[dir='rtl'] .rtl\:inset-2\/3,[dir='rtl'].rtl\:inset-2\/3 {
  top: 66.666667%;
  right: 66.666667%;
  bottom: 66.666667%;
  left: 66.666667%
}

[dir='rtl'] .rtl\:inset-1\/4,[dir='rtl'].rtl\:inset-1\/4 {
  top: 25%;
  right: 25%;
  bottom: 25%;
  left: 25%
}

[dir='rtl'] .rtl\:inset-2\/4,[dir='rtl'].rtl\:inset-2\/4 {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%
}

[dir='rtl'] .rtl\:inset-3\/4,[dir='rtl'].rtl\:inset-3\/4 {
  top: 75%;
  right: 75%;
  bottom: 75%;
  left: 75%
}

[dir='rtl'] .rtl\:inset-full,[dir='rtl'].rtl\:inset-full {
  top: 100%;
  right: 100%;
  bottom: 100%;
  left: 100%
}

[dir='rtl'] .rtl\:-inset-1\/2,[dir='rtl'].rtl\:-inset-1\/2 {
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%
}

[dir='rtl'] .rtl\:-inset-1\/3,[dir='rtl'].rtl\:-inset-1\/3 {
  top: -33.333333%;
  right: -33.333333%;
  bottom: -33.333333%;
  left: -33.333333%
}

[dir='rtl'] .rtl\:-inset-2\/3,[dir='rtl'].rtl\:-inset-2\/3 {
  top: -66.666667%;
  right: -66.666667%;
  bottom: -66.666667%;
  left: -66.666667%
}

[dir='rtl'] .rtl\:-inset-1\/4,[dir='rtl'].rtl\:-inset-1\/4 {
  top: -25%;
  right: -25%;
  bottom: -25%;
  left: -25%
}

[dir='rtl'] .rtl\:-inset-2\/4,[dir='rtl'].rtl\:-inset-2\/4 {
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%
}

[dir='rtl'] .rtl\:-inset-3\/4,[dir='rtl'].rtl\:-inset-3\/4 {
  top: -75%;
  right: -75%;
  bottom: -75%;
  left: -75%
}

[dir='rtl'] .rtl\:-inset-full,[dir='rtl'].rtl\:-inset-full {
  top: -100%;
  right: -100%;
  bottom: -100%;
  left: -100%
}

[dir='rtl'] .rtl\:inset-y-0,[dir='rtl'].rtl\:inset-y-0 {
  top: 0;
  bottom: 0
}

[dir='rtl'] .rtl\:inset-x-0,[dir='rtl'].rtl\:inset-x-0 {
  right: 0;
  left: 0
}

[dir='rtl'] .rtl\:inset-y-1,[dir='rtl'].rtl\:inset-y-1 {
  top: 0.1rem;
  bottom: 0.1rem
}

[dir='rtl'] .rtl\:inset-x-1,[dir='rtl'].rtl\:inset-x-1 {
  right: 0.1rem;
  left: 0.1rem
}

[dir='rtl'] .rtl\:inset-y-2,[dir='rtl'].rtl\:inset-y-2 {
  top: 0.2rem;
  bottom: 0.2rem
}

[dir='rtl'] .rtl\:inset-x-2,[dir='rtl'].rtl\:inset-x-2 {
  right: 0.2rem;
  left: 0.2rem
}

[dir='rtl'] .rtl\:inset-y-3,[dir='rtl'].rtl\:inset-y-3 {
  top: 0.3rem;
  bottom: 0.3rem
}

[dir='rtl'] .rtl\:inset-x-3,[dir='rtl'].rtl\:inset-x-3 {
  right: 0.3rem;
  left: 0.3rem
}

[dir='rtl'] .rtl\:inset-y-4,[dir='rtl'].rtl\:inset-y-4 {
  top: 0.4rem;
  bottom: 0.4rem
}

[dir='rtl'] .rtl\:inset-x-4,[dir='rtl'].rtl\:inset-x-4 {
  right: 0.4rem;
  left: 0.4rem
}

[dir='rtl'] .rtl\:inset-y-5,[dir='rtl'].rtl\:inset-y-5 {
  top: 0.5rem;
  bottom: 0.5rem
}

[dir='rtl'] .rtl\:inset-x-5,[dir='rtl'].rtl\:inset-x-5 {
  right: 0.5rem;
  left: 0.5rem
}

[dir='rtl'] .rtl\:inset-y-6,[dir='rtl'].rtl\:inset-y-6 {
  top: 0.6rem;
  bottom: 0.6rem
}

[dir='rtl'] .rtl\:inset-x-6,[dir='rtl'].rtl\:inset-x-6 {
  right: 0.6rem;
  left: 0.6rem
}

[dir='rtl'] .rtl\:inset-y-7,[dir='rtl'].rtl\:inset-y-7 {
  top: 0.7rem;
  bottom: 0.7rem
}

[dir='rtl'] .rtl\:inset-x-7,[dir='rtl'].rtl\:inset-x-7 {
  right: 0.7rem;
  left: 0.7rem
}

[dir='rtl'] .rtl\:inset-y-8,[dir='rtl'].rtl\:inset-y-8 {
  top: 0.8rem;
  bottom: 0.8rem
}

[dir='rtl'] .rtl\:inset-x-8,[dir='rtl'].rtl\:inset-x-8 {
  right: 0.8rem;
  left: 0.8rem
}

[dir='rtl'] .rtl\:inset-y-9,[dir='rtl'].rtl\:inset-y-9 {
  top: 0.9rem;
  bottom: 0.9rem
}

[dir='rtl'] .rtl\:inset-x-9,[dir='rtl'].rtl\:inset-x-9 {
  right: 0.9rem;
  left: 0.9rem
}

[dir='rtl'] .rtl\:inset-y-10,[dir='rtl'].rtl\:inset-y-10 {
  top: 1.0rem;
  bottom: 1.0rem
}

[dir='rtl'] .rtl\:inset-x-10,[dir='rtl'].rtl\:inset-x-10 {
  right: 1.0rem;
  left: 1.0rem
}

[dir='rtl'] .rtl\:inset-y-12,[dir='rtl'].rtl\:inset-y-12 {
  top: 1.2rem;
  bottom: 1.2rem
}

[dir='rtl'] .rtl\:inset-x-12,[dir='rtl'].rtl\:inset-x-12 {
  right: 1.2rem;
  left: 1.2rem
}

[dir='rtl'] .rtl\:inset-y-14,[dir='rtl'].rtl\:inset-y-14 {
  top: 1.4rem;
  bottom: 1.4rem
}

[dir='rtl'] .rtl\:inset-x-14,[dir='rtl'].rtl\:inset-x-14 {
  right: 1.4rem;
  left: 1.4rem
}

[dir='rtl'] .rtl\:inset-y-16,[dir='rtl'].rtl\:inset-y-16 {
  top: 1.6rem;
  bottom: 1.6rem
}

[dir='rtl'] .rtl\:inset-x-16,[dir='rtl'].rtl\:inset-x-16 {
  right: 1.6rem;
  left: 1.6rem
}

[dir='rtl'] .rtl\:inset-y-20,[dir='rtl'].rtl\:inset-y-20 {
  top: 2rem;
  bottom: 2rem
}

[dir='rtl'] .rtl\:inset-x-20,[dir='rtl'].rtl\:inset-x-20 {
  right: 2rem;
  left: 2rem
}

[dir='rtl'] .rtl\:inset-y-24,[dir='rtl'].rtl\:inset-y-24 {
  top: 2.4rem;
  bottom: 2.4rem
}

[dir='rtl'] .rtl\:inset-x-24,[dir='rtl'].rtl\:inset-x-24 {
  right: 2.4rem;
  left: 2.4rem
}

[dir='rtl'] .rtl\:inset-y-28,[dir='rtl'].rtl\:inset-y-28 {
  top: 2.8rem;
  bottom: 2.8rem
}

[dir='rtl'] .rtl\:inset-x-28,[dir='rtl'].rtl\:inset-x-28 {
  right: 2.8rem;
  left: 2.8rem
}

[dir='rtl'] .rtl\:inset-y-32,[dir='rtl'].rtl\:inset-y-32 {
  top: 3.2rem;
  bottom: 3.2rem
}

[dir='rtl'] .rtl\:inset-x-32,[dir='rtl'].rtl\:inset-x-32 {
  right: 3.2rem;
  left: 3.2rem
}

[dir='rtl'] .rtl\:inset-y-36,[dir='rtl'].rtl\:inset-y-36 {
  top: 3.6rem;
  bottom: 3.6rem
}

[dir='rtl'] .rtl\:inset-x-36,[dir='rtl'].rtl\:inset-x-36 {
  right: 3.6rem;
  left: 3.6rem
}

[dir='rtl'] .rtl\:inset-y-40,[dir='rtl'].rtl\:inset-y-40 {
  top: 4rem;
  bottom: 4rem
}

[dir='rtl'] .rtl\:inset-x-40,[dir='rtl'].rtl\:inset-x-40 {
  right: 4rem;
  left: 4rem
}

[dir='rtl'] .rtl\:inset-y-44,[dir='rtl'].rtl\:inset-y-44 {
  top: 4.4rem;
  bottom: 4.4rem
}

[dir='rtl'] .rtl\:inset-x-44,[dir='rtl'].rtl\:inset-x-44 {
  right: 4.4rem;
  left: 4.4rem
}

[dir='rtl'] .rtl\:inset-y-48,[dir='rtl'].rtl\:inset-y-48 {
  top: 4.8rem;
  bottom: 4.8rem
}

[dir='rtl'] .rtl\:inset-x-48,[dir='rtl'].rtl\:inset-x-48 {
  right: 4.8rem;
  left: 4.8rem
}

[dir='rtl'] .rtl\:inset-y-52,[dir='rtl'].rtl\:inset-y-52 {
  top: 5.2rem;
  bottom: 5.2rem
}

[dir='rtl'] .rtl\:inset-x-52,[dir='rtl'].rtl\:inset-x-52 {
  right: 5.2rem;
  left: 5.2rem
}

[dir='rtl'] .rtl\:inset-y-56,[dir='rtl'].rtl\:inset-y-56 {
  top: 5.6rem;
  bottom: 5.6rem
}

[dir='rtl'] .rtl\:inset-x-56,[dir='rtl'].rtl\:inset-x-56 {
  right: 5.6rem;
  left: 5.6rem
}

[dir='rtl'] .rtl\:inset-y-60,[dir='rtl'].rtl\:inset-y-60 {
  top: 6rem;
  bottom: 6rem
}

[dir='rtl'] .rtl\:inset-x-60,[dir='rtl'].rtl\:inset-x-60 {
  right: 6rem;
  left: 6rem
}

[dir='rtl'] .rtl\:inset-y-64,[dir='rtl'].rtl\:inset-y-64 {
  top: 6.4rem;
  bottom: 6.4rem
}

[dir='rtl'] .rtl\:inset-x-64,[dir='rtl'].rtl\:inset-x-64 {
  right: 6.4rem;
  left: 6.4rem
}

[dir='rtl'] .rtl\:inset-y-68,[dir='rtl'].rtl\:inset-y-68 {
  top: 6.8rem;
  bottom: 6.8rem
}

[dir='rtl'] .rtl\:inset-x-68,[dir='rtl'].rtl\:inset-x-68 {
  right: 6.8rem;
  left: 6.8rem
}

[dir='rtl'] .rtl\:inset-y-72,[dir='rtl'].rtl\:inset-y-72 {
  top: 7.2rem;
  bottom: 7.2rem
}

[dir='rtl'] .rtl\:inset-x-72,[dir='rtl'].rtl\:inset-x-72 {
  right: 7.2rem;
  left: 7.2rem
}

[dir='rtl'] .rtl\:inset-y-76,[dir='rtl'].rtl\:inset-y-76 {
  top: 7.6rem;
  bottom: 7.6rem
}

[dir='rtl'] .rtl\:inset-x-76,[dir='rtl'].rtl\:inset-x-76 {
  right: 7.6rem;
  left: 7.6rem
}

[dir='rtl'] .rtl\:inset-y-80,[dir='rtl'].rtl\:inset-y-80 {
  top: 8rem;
  bottom: 8rem
}

[dir='rtl'] .rtl\:inset-x-80,[dir='rtl'].rtl\:inset-x-80 {
  right: 8rem;
  left: 8rem
}

[dir='rtl'] .rtl\:inset-y-84,[dir='rtl'].rtl\:inset-y-84 {
  top: 8.4rem;
  bottom: 8.4rem
}

[dir='rtl'] .rtl\:inset-x-84,[dir='rtl'].rtl\:inset-x-84 {
  right: 8.4rem;
  left: 8.4rem
}

[dir='rtl'] .rtl\:inset-y-88,[dir='rtl'].rtl\:inset-y-88 {
  top: 8.8rem;
  bottom: 8.8rem
}

[dir='rtl'] .rtl\:inset-x-88,[dir='rtl'].rtl\:inset-x-88 {
  right: 8.8rem;
  left: 8.8rem
}

[dir='rtl'] .rtl\:inset-y-92,[dir='rtl'].rtl\:inset-y-92 {
  top: 9.2rem;
  bottom: 9.2rem
}

[dir='rtl'] .rtl\:inset-x-92,[dir='rtl'].rtl\:inset-x-92 {
  right: 9.2rem;
  left: 9.2rem
}

[dir='rtl'] .rtl\:inset-y-96,[dir='rtl'].rtl\:inset-y-96 {
  top: 9.6rem;
  bottom: 9.6rem
}

[dir='rtl'] .rtl\:inset-x-96,[dir='rtl'].rtl\:inset-x-96 {
  right: 9.6rem;
  left: 9.6rem
}

[dir='rtl'] .rtl\:inset-y-128,[dir='rtl'].rtl\:inset-y-128 {
  top: 12.8rem;
  bottom: 12.8rem
}

[dir='rtl'] .rtl\:inset-x-128,[dir='rtl'].rtl\:inset-x-128 {
  right: 12.8rem;
  left: 12.8rem
}

[dir='rtl'] .rtl\:inset-y-136,[dir='rtl'].rtl\:inset-y-136 {
  top: 13.6rem;
  bottom: 13.6rem
}

[dir='rtl'] .rtl\:inset-x-136,[dir='rtl'].rtl\:inset-x-136 {
  right: 13.6rem;
  left: 13.6rem
}

[dir='rtl'] .rtl\:inset-y-160,[dir='rtl'].rtl\:inset-y-160 {
  top: 16rem;
  bottom: 16rem
}

[dir='rtl'] .rtl\:inset-x-160,[dir='rtl'].rtl\:inset-x-160 {
  right: 16rem;
  left: 16rem
}

[dir='rtl'] .rtl\:inset-y-192,[dir='rtl'].rtl\:inset-y-192 {
  top: 19.2rem;
  bottom: 19.2rem
}

[dir='rtl'] .rtl\:inset-x-192,[dir='rtl'].rtl\:inset-x-192 {
  right: 19.2rem;
  left: 19.2rem
}

[dir='rtl'] .rtl\:inset-y-200,[dir='rtl'].rtl\:inset-y-200 {
  top: 20rem;
  bottom: 20rem
}

[dir='rtl'] .rtl\:inset-x-200,[dir='rtl'].rtl\:inset-x-200 {
  right: 20rem;
  left: 20rem
}

[dir='rtl'] .rtl\:inset-y-208,[dir='rtl'].rtl\:inset-y-208 {
  top: 20.8rem;
  bottom: 20.8rem
}

[dir='rtl'] .rtl\:inset-x-208,[dir='rtl'].rtl\:inset-x-208 {
  right: 20.8rem;
  left: 20.8rem
}

[dir='rtl'] .rtl\:inset-y-216,[dir='rtl'].rtl\:inset-y-216 {
  top: 21.6rem;
  bottom: 21.6rem
}

[dir='rtl'] .rtl\:inset-x-216,[dir='rtl'].rtl\:inset-x-216 {
  right: 21.6rem;
  left: 21.6rem
}

[dir='rtl'] .rtl\:inset-y-224,[dir='rtl'].rtl\:inset-y-224 {
  top: 22.4rem;
  bottom: 22.4rem
}

[dir='rtl'] .rtl\:inset-x-224,[dir='rtl'].rtl\:inset-x-224 {
  right: 22.4rem;
  left: 22.4rem
}

[dir='rtl'] .rtl\:inset-y-256,[dir='rtl'].rtl\:inset-y-256 {
  top: 25.6rem;
  bottom: 25.6rem
}

[dir='rtl'] .rtl\:inset-x-256,[dir='rtl'].rtl\:inset-x-256 {
  right: 25.6rem;
  left: 25.6rem
}

[dir='rtl'] .rtl\:inset-y-288,[dir='rtl'].rtl\:inset-y-288 {
  top: 28.8rem;
  bottom: 28.8rem
}

[dir='rtl'] .rtl\:inset-x-288,[dir='rtl'].rtl\:inset-x-288 {
  right: 28.8rem;
  left: 28.8rem
}

[dir='rtl'] .rtl\:inset-y-320,[dir='rtl'].rtl\:inset-y-320 {
  top: 32rem;
  bottom: 32rem
}

[dir='rtl'] .rtl\:inset-x-320,[dir='rtl'].rtl\:inset-x-320 {
  right: 32rem;
  left: 32rem
}

[dir='rtl'] .rtl\:inset-y-360,[dir='rtl'].rtl\:inset-y-360 {
  top: 36rem;
  bottom: 36rem
}

[dir='rtl'] .rtl\:inset-x-360,[dir='rtl'].rtl\:inset-x-360 {
  right: 36rem;
  left: 36rem
}

[dir='rtl'] .rtl\:inset-y-384,[dir='rtl'].rtl\:inset-y-384 {
  top: 38.4rem;
  bottom: 38.4rem
}

[dir='rtl'] .rtl\:inset-x-384,[dir='rtl'].rtl\:inset-x-384 {
  right: 38.4rem;
  left: 38.4rem
}

[dir='rtl'] .rtl\:inset-y-400,[dir='rtl'].rtl\:inset-y-400 {
  top: 40rem;
  bottom: 40rem
}

[dir='rtl'] .rtl\:inset-x-400,[dir='rtl'].rtl\:inset-x-400 {
  right: 40rem;
  left: 40rem
}

[dir='rtl'] .rtl\:inset-y-512,[dir='rtl'].rtl\:inset-y-512 {
  top: 51.2rem;
  bottom: 51.2rem
}

[dir='rtl'] .rtl\:inset-x-512,[dir='rtl'].rtl\:inset-x-512 {
  right: 51.2rem;
  left: 51.2rem
}

[dir='rtl'] .rtl\:inset-y-640,[dir='rtl'].rtl\:inset-y-640 {
  top: 64rem;
  bottom: 64rem
}

[dir='rtl'] .rtl\:inset-x-640,[dir='rtl'].rtl\:inset-x-640 {
  right: 64rem;
  left: 64rem
}

[dir='rtl'] .rtl\:inset-y-auto,[dir='rtl'].rtl\:inset-y-auto {
  top: auto;
  bottom: auto
}

[dir='rtl'] .rtl\:inset-x-auto,[dir='rtl'].rtl\:inset-x-auto {
  right: auto;
  left: auto
}

[dir='rtl'] .rtl\:inset-y-xs,[dir='rtl'].rtl\:inset-y-xs {
  top: 32rem;
  bottom: 32rem
}

[dir='rtl'] .rtl\:inset-x-xs,[dir='rtl'].rtl\:inset-x-xs {
  right: 32rem;
  left: 32rem
}

[dir='rtl'] .rtl\:inset-y-sm,[dir='rtl'].rtl\:inset-y-sm {
  top: 48rem;
  bottom: 48rem
}

[dir='rtl'] .rtl\:inset-x-sm,[dir='rtl'].rtl\:inset-x-sm {
  right: 48rem;
  left: 48rem
}

[dir='rtl'] .rtl\:inset-y-md,[dir='rtl'].rtl\:inset-y-md {
  top: 64rem;
  bottom: 64rem
}

[dir='rtl'] .rtl\:inset-x-md,[dir='rtl'].rtl\:inset-x-md {
  right: 64rem;
  left: 64rem
}

[dir='rtl'] .rtl\:inset-y-lg,[dir='rtl'].rtl\:inset-y-lg {
  top: 80rem;
  bottom: 80rem
}

[dir='rtl'] .rtl\:inset-x-lg,[dir='rtl'].rtl\:inset-x-lg {
  right: 80rem;
  left: 80rem
}

[dir='rtl'] .rtl\:inset-y-xl,[dir='rtl'].rtl\:inset-y-xl {
  top: 96rem;
  bottom: 96rem
}

[dir='rtl'] .rtl\:inset-x-xl,[dir='rtl'].rtl\:inset-x-xl {
  right: 96rem;
  left: 96rem
}

[dir='rtl'] .rtl\:inset-y-2xl,[dir='rtl'].rtl\:inset-y-2xl {
  top: 112rem;
  bottom: 112rem
}

[dir='rtl'] .rtl\:inset-x-2xl,[dir='rtl'].rtl\:inset-x-2xl {
  right: 112rem;
  left: 112rem
}

[dir='rtl'] .rtl\:inset-y-3xl,[dir='rtl'].rtl\:inset-y-3xl {
  top: 128rem;
  bottom: 128rem
}

[dir='rtl'] .rtl\:inset-x-3xl,[dir='rtl'].rtl\:inset-x-3xl {
  right: 128rem;
  left: 128rem
}

[dir='rtl'] .rtl\:inset-y-4xl,[dir='rtl'].rtl\:inset-y-4xl {
  top: 144rem;
  bottom: 144rem
}

[dir='rtl'] .rtl\:inset-x-4xl,[dir='rtl'].rtl\:inset-x-4xl {
  right: 144rem;
  left: 144rem
}

[dir='rtl'] .rtl\:inset-y-5xl,[dir='rtl'].rtl\:inset-y-5xl {
  top: 160rem;
  bottom: 160rem
}

[dir='rtl'] .rtl\:inset-x-5xl,[dir='rtl'].rtl\:inset-x-5xl {
  right: 160rem;
  left: 160rem
}

[dir='rtl'] .rtl\:inset-y-px,[dir='rtl'].rtl\:inset-y-px {
  top: 1px;
  bottom: 1px
}

[dir='rtl'] .rtl\:inset-x-px,[dir='rtl'].rtl\:inset-x-px {
  right: 1px;
  left: 1px
}

[dir='rtl'] .rtl\:inset-y-0\.5,[dir='rtl'].rtl\:inset-y-0\.5 {
  top: 0.05rem;
  bottom: 0.05rem
}

[dir='rtl'] .rtl\:inset-x-0\.5,[dir='rtl'].rtl\:inset-x-0\.5 {
  right: 0.05rem;
  left: 0.05rem
}

[dir='rtl'] .rtl\:inset-y-1\.5,[dir='rtl'].rtl\:inset-y-1\.5 {
  top: 0.15rem;
  bottom: 0.15rem
}

[dir='rtl'] .rtl\:inset-x-1\.5,[dir='rtl'].rtl\:inset-x-1\.5 {
  right: 0.15rem;
  left: 0.15rem
}

[dir='rtl'] .rtl\:inset-y-2\.5,[dir='rtl'].rtl\:inset-y-2\.5 {
  top: 0.25rem;
  bottom: 0.25rem
}

[dir='rtl'] .rtl\:inset-x-2\.5,[dir='rtl'].rtl\:inset-x-2\.5 {
  right: 0.25rem;
  left: 0.25rem
}

[dir='rtl'] .rtl\:inset-y-3\.5,[dir='rtl'].rtl\:inset-y-3\.5 {
  top: 0.35rem;
  bottom: 0.35rem
}

[dir='rtl'] .rtl\:inset-x-3\.5,[dir='rtl'].rtl\:inset-x-3\.5 {
  right: 0.35rem;
  left: 0.35rem
}

[dir='rtl'] .rtl\:-inset-y-1,[dir='rtl'].rtl\:-inset-y-1 {
  top: -0.1rem;
  bottom: -0.1rem
}

[dir='rtl'] .rtl\:-inset-x-1,[dir='rtl'].rtl\:-inset-x-1 {
  right: -0.1rem;
  left: -0.1rem
}

[dir='rtl'] .rtl\:-inset-y-2,[dir='rtl'].rtl\:-inset-y-2 {
  top: -0.2rem;
  bottom: -0.2rem
}

[dir='rtl'] .rtl\:-inset-x-2,[dir='rtl'].rtl\:-inset-x-2 {
  right: -0.2rem;
  left: -0.2rem
}

[dir='rtl'] .rtl\:-inset-y-3,[dir='rtl'].rtl\:-inset-y-3 {
  top: -0.3rem;
  bottom: -0.3rem
}

[dir='rtl'] .rtl\:-inset-x-3,[dir='rtl'].rtl\:-inset-x-3 {
  right: -0.3rem;
  left: -0.3rem
}

[dir='rtl'] .rtl\:-inset-y-4,[dir='rtl'].rtl\:-inset-y-4 {
  top: -0.4rem;
  bottom: -0.4rem
}

[dir='rtl'] .rtl\:-inset-x-4,[dir='rtl'].rtl\:-inset-x-4 {
  right: -0.4rem;
  left: -0.4rem
}

[dir='rtl'] .rtl\:-inset-y-5,[dir='rtl'].rtl\:-inset-y-5 {
  top: -0.5rem;
  bottom: -0.5rem
}

[dir='rtl'] .rtl\:-inset-x-5,[dir='rtl'].rtl\:-inset-x-5 {
  right: -0.5rem;
  left: -0.5rem
}

[dir='rtl'] .rtl\:-inset-y-6,[dir='rtl'].rtl\:-inset-y-6 {
  top: -0.6rem;
  bottom: -0.6rem
}

[dir='rtl'] .rtl\:-inset-x-6,[dir='rtl'].rtl\:-inset-x-6 {
  right: -0.6rem;
  left: -0.6rem
}

[dir='rtl'] .rtl\:-inset-y-7,[dir='rtl'].rtl\:-inset-y-7 {
  top: -0.7rem;
  bottom: -0.7rem
}

[dir='rtl'] .rtl\:-inset-x-7,[dir='rtl'].rtl\:-inset-x-7 {
  right: -0.7rem;
  left: -0.7rem
}

[dir='rtl'] .rtl\:-inset-y-8,[dir='rtl'].rtl\:-inset-y-8 {
  top: -0.8rem;
  bottom: -0.8rem
}

[dir='rtl'] .rtl\:-inset-x-8,[dir='rtl'].rtl\:-inset-x-8 {
  right: -0.8rem;
  left: -0.8rem
}

[dir='rtl'] .rtl\:-inset-y-9,[dir='rtl'].rtl\:-inset-y-9 {
  top: -0.9rem;
  bottom: -0.9rem
}

[dir='rtl'] .rtl\:-inset-x-9,[dir='rtl'].rtl\:-inset-x-9 {
  right: -0.9rem;
  left: -0.9rem
}

[dir='rtl'] .rtl\:-inset-y-10,[dir='rtl'].rtl\:-inset-y-10 {
  top: -1rem;
  bottom: -1rem
}

[dir='rtl'] .rtl\:-inset-x-10,[dir='rtl'].rtl\:-inset-x-10 {
  right: -1rem;
  left: -1rem
}

[dir='rtl'] .rtl\:-inset-y-12,[dir='rtl'].rtl\:-inset-y-12 {
  top: -1.2rem;
  bottom: -1.2rem
}

[dir='rtl'] .rtl\:-inset-x-12,[dir='rtl'].rtl\:-inset-x-12 {
  right: -1.2rem;
  left: -1.2rem
}

[dir='rtl'] .rtl\:-inset-y-14,[dir='rtl'].rtl\:-inset-y-14 {
  top: -1.4rem;
  bottom: -1.4rem
}

[dir='rtl'] .rtl\:-inset-x-14,[dir='rtl'].rtl\:-inset-x-14 {
  right: -1.4rem;
  left: -1.4rem
}

[dir='rtl'] .rtl\:-inset-y-16,[dir='rtl'].rtl\:-inset-y-16 {
  top: -1.6rem;
  bottom: -1.6rem
}

[dir='rtl'] .rtl\:-inset-x-16,[dir='rtl'].rtl\:-inset-x-16 {
  right: -1.6rem;
  left: -1.6rem
}

[dir='rtl'] .rtl\:-inset-y-20,[dir='rtl'].rtl\:-inset-y-20 {
  top: -2rem;
  bottom: -2rem
}

[dir='rtl'] .rtl\:-inset-x-20,[dir='rtl'].rtl\:-inset-x-20 {
  right: -2rem;
  left: -2rem
}

[dir='rtl'] .rtl\:-inset-y-24,[dir='rtl'].rtl\:-inset-y-24 {
  top: -2.4rem;
  bottom: -2.4rem
}

[dir='rtl'] .rtl\:-inset-x-24,[dir='rtl'].rtl\:-inset-x-24 {
  right: -2.4rem;
  left: -2.4rem
}

[dir='rtl'] .rtl\:-inset-y-28,[dir='rtl'].rtl\:-inset-y-28 {
  top: -2.8rem;
  bottom: -2.8rem
}

[dir='rtl'] .rtl\:-inset-x-28,[dir='rtl'].rtl\:-inset-x-28 {
  right: -2.8rem;
  left: -2.8rem
}

[dir='rtl'] .rtl\:-inset-y-32,[dir='rtl'].rtl\:-inset-y-32 {
  top: -3.2rem;
  bottom: -3.2rem
}

[dir='rtl'] .rtl\:-inset-x-32,[dir='rtl'].rtl\:-inset-x-32 {
  right: -3.2rem;
  left: -3.2rem
}

[dir='rtl'] .rtl\:-inset-y-36,[dir='rtl'].rtl\:-inset-y-36 {
  top: -3.6rem;
  bottom: -3.6rem
}

[dir='rtl'] .rtl\:-inset-x-36,[dir='rtl'].rtl\:-inset-x-36 {
  right: -3.6rem;
  left: -3.6rem
}

[dir='rtl'] .rtl\:-inset-y-40,[dir='rtl'].rtl\:-inset-y-40 {
  top: -4rem;
  bottom: -4rem
}

[dir='rtl'] .rtl\:-inset-x-40,[dir='rtl'].rtl\:-inset-x-40 {
  right: -4rem;
  left: -4rem
}

[dir='rtl'] .rtl\:-inset-y-44,[dir='rtl'].rtl\:-inset-y-44 {
  top: -4.4rem;
  bottom: -4.4rem
}

[dir='rtl'] .rtl\:-inset-x-44,[dir='rtl'].rtl\:-inset-x-44 {
  right: -4.4rem;
  left: -4.4rem
}

[dir='rtl'] .rtl\:-inset-y-48,[dir='rtl'].rtl\:-inset-y-48 {
  top: -4.8rem;
  bottom: -4.8rem
}

[dir='rtl'] .rtl\:-inset-x-48,[dir='rtl'].rtl\:-inset-x-48 {
  right: -4.8rem;
  left: -4.8rem
}

[dir='rtl'] .rtl\:-inset-y-52,[dir='rtl'].rtl\:-inset-y-52 {
  top: -5.2rem;
  bottom: -5.2rem
}

[dir='rtl'] .rtl\:-inset-x-52,[dir='rtl'].rtl\:-inset-x-52 {
  right: -5.2rem;
  left: -5.2rem
}

[dir='rtl'] .rtl\:-inset-y-56,[dir='rtl'].rtl\:-inset-y-56 {
  top: -5.6rem;
  bottom: -5.6rem
}

[dir='rtl'] .rtl\:-inset-x-56,[dir='rtl'].rtl\:-inset-x-56 {
  right: -5.6rem;
  left: -5.6rem
}

[dir='rtl'] .rtl\:-inset-y-60,[dir='rtl'].rtl\:-inset-y-60 {
  top: -6rem;
  bottom: -6rem
}

[dir='rtl'] .rtl\:-inset-x-60,[dir='rtl'].rtl\:-inset-x-60 {
  right: -6rem;
  left: -6rem
}

[dir='rtl'] .rtl\:-inset-y-64,[dir='rtl'].rtl\:-inset-y-64 {
  top: -6.4rem;
  bottom: -6.4rem
}

[dir='rtl'] .rtl\:-inset-x-64,[dir='rtl'].rtl\:-inset-x-64 {
  right: -6.4rem;
  left: -6.4rem
}

[dir='rtl'] .rtl\:-inset-y-68,[dir='rtl'].rtl\:-inset-y-68 {
  top: -6.8rem;
  bottom: -6.8rem
}

[dir='rtl'] .rtl\:-inset-x-68,[dir='rtl'].rtl\:-inset-x-68 {
  right: -6.8rem;
  left: -6.8rem
}

[dir='rtl'] .rtl\:-inset-y-72,[dir='rtl'].rtl\:-inset-y-72 {
  top: -7.2rem;
  bottom: -7.2rem
}

[dir='rtl'] .rtl\:-inset-x-72,[dir='rtl'].rtl\:-inset-x-72 {
  right: -7.2rem;
  left: -7.2rem
}

[dir='rtl'] .rtl\:-inset-y-76,[dir='rtl'].rtl\:-inset-y-76 {
  top: -7.6rem;
  bottom: -7.6rem
}

[dir='rtl'] .rtl\:-inset-x-76,[dir='rtl'].rtl\:-inset-x-76 {
  right: -7.6rem;
  left: -7.6rem
}

[dir='rtl'] .rtl\:-inset-y-80,[dir='rtl'].rtl\:-inset-y-80 {
  top: -8rem;
  bottom: -8rem
}

[dir='rtl'] .rtl\:-inset-x-80,[dir='rtl'].rtl\:-inset-x-80 {
  right: -8rem;
  left: -8rem
}

[dir='rtl'] .rtl\:-inset-y-84,[dir='rtl'].rtl\:-inset-y-84 {
  top: -8.4rem;
  bottom: -8.4rem
}

[dir='rtl'] .rtl\:-inset-x-84,[dir='rtl'].rtl\:-inset-x-84 {
  right: -8.4rem;
  left: -8.4rem
}

[dir='rtl'] .rtl\:-inset-y-88,[dir='rtl'].rtl\:-inset-y-88 {
  top: -8.8rem;
  bottom: -8.8rem
}

[dir='rtl'] .rtl\:-inset-x-88,[dir='rtl'].rtl\:-inset-x-88 {
  right: -8.8rem;
  left: -8.8rem
}

[dir='rtl'] .rtl\:-inset-y-92,[dir='rtl'].rtl\:-inset-y-92 {
  top: -9.2rem;
  bottom: -9.2rem
}

[dir='rtl'] .rtl\:-inset-x-92,[dir='rtl'].rtl\:-inset-x-92 {
  right: -9.2rem;
  left: -9.2rem
}

[dir='rtl'] .rtl\:-inset-y-96,[dir='rtl'].rtl\:-inset-y-96 {
  top: -9.6rem;
  bottom: -9.6rem
}

[dir='rtl'] .rtl\:-inset-x-96,[dir='rtl'].rtl\:-inset-x-96 {
  right: -9.6rem;
  left: -9.6rem
}

[dir='rtl'] .rtl\:-inset-y-128,[dir='rtl'].rtl\:-inset-y-128 {
  top: -12.8rem;
  bottom: -12.8rem
}

[dir='rtl'] .rtl\:-inset-x-128,[dir='rtl'].rtl\:-inset-x-128 {
  right: -12.8rem;
  left: -12.8rem
}

[dir='rtl'] .rtl\:-inset-y-136,[dir='rtl'].rtl\:-inset-y-136 {
  top: -13.6rem;
  bottom: -13.6rem
}

[dir='rtl'] .rtl\:-inset-x-136,[dir='rtl'].rtl\:-inset-x-136 {
  right: -13.6rem;
  left: -13.6rem
}

[dir='rtl'] .rtl\:-inset-y-160,[dir='rtl'].rtl\:-inset-y-160 {
  top: -16rem;
  bottom: -16rem
}

[dir='rtl'] .rtl\:-inset-x-160,[dir='rtl'].rtl\:-inset-x-160 {
  right: -16rem;
  left: -16rem
}

[dir='rtl'] .rtl\:-inset-y-192,[dir='rtl'].rtl\:-inset-y-192 {
  top: -19.2rem;
  bottom: -19.2rem
}

[dir='rtl'] .rtl\:-inset-x-192,[dir='rtl'].rtl\:-inset-x-192 {
  right: -19.2rem;
  left: -19.2rem
}

[dir='rtl'] .rtl\:-inset-y-200,[dir='rtl'].rtl\:-inset-y-200 {
  top: -20rem;
  bottom: -20rem
}

[dir='rtl'] .rtl\:-inset-x-200,[dir='rtl'].rtl\:-inset-x-200 {
  right: -20rem;
  left: -20rem
}

[dir='rtl'] .rtl\:-inset-y-208,[dir='rtl'].rtl\:-inset-y-208 {
  top: -20.8rem;
  bottom: -20.8rem
}

[dir='rtl'] .rtl\:-inset-x-208,[dir='rtl'].rtl\:-inset-x-208 {
  right: -20.8rem;
  left: -20.8rem
}

[dir='rtl'] .rtl\:-inset-y-216,[dir='rtl'].rtl\:-inset-y-216 {
  top: -21.6rem;
  bottom: -21.6rem
}

[dir='rtl'] .rtl\:-inset-x-216,[dir='rtl'].rtl\:-inset-x-216 {
  right: -21.6rem;
  left: -21.6rem
}

[dir='rtl'] .rtl\:-inset-y-224,[dir='rtl'].rtl\:-inset-y-224 {
  top: -22.4rem;
  bottom: -22.4rem
}

[dir='rtl'] .rtl\:-inset-x-224,[dir='rtl'].rtl\:-inset-x-224 {
  right: -22.4rem;
  left: -22.4rem
}

[dir='rtl'] .rtl\:-inset-y-256,[dir='rtl'].rtl\:-inset-y-256 {
  top: -25.6rem;
  bottom: -25.6rem
}

[dir='rtl'] .rtl\:-inset-x-256,[dir='rtl'].rtl\:-inset-x-256 {
  right: -25.6rem;
  left: -25.6rem
}

[dir='rtl'] .rtl\:-inset-y-288,[dir='rtl'].rtl\:-inset-y-288 {
  top: -28.8rem;
  bottom: -28.8rem
}

[dir='rtl'] .rtl\:-inset-x-288,[dir='rtl'].rtl\:-inset-x-288 {
  right: -28.8rem;
  left: -28.8rem
}

[dir='rtl'] .rtl\:-inset-y-320,[dir='rtl'].rtl\:-inset-y-320 {
  top: -32rem;
  bottom: -32rem
}

[dir='rtl'] .rtl\:-inset-x-320,[dir='rtl'].rtl\:-inset-x-320 {
  right: -32rem;
  left: -32rem
}

[dir='rtl'] .rtl\:-inset-y-360,[dir='rtl'].rtl\:-inset-y-360 {
  top: -36rem;
  bottom: -36rem
}

[dir='rtl'] .rtl\:-inset-x-360,[dir='rtl'].rtl\:-inset-x-360 {
  right: -36rem;
  left: -36rem
}

[dir='rtl'] .rtl\:-inset-y-384,[dir='rtl'].rtl\:-inset-y-384 {
  top: -38.4rem;
  bottom: -38.4rem
}

[dir='rtl'] .rtl\:-inset-x-384,[dir='rtl'].rtl\:-inset-x-384 {
  right: -38.4rem;
  left: -38.4rem
}

[dir='rtl'] .rtl\:-inset-y-400,[dir='rtl'].rtl\:-inset-y-400 {
  top: -40rem;
  bottom: -40rem
}

[dir='rtl'] .rtl\:-inset-x-400,[dir='rtl'].rtl\:-inset-x-400 {
  right: -40rem;
  left: -40rem
}

[dir='rtl'] .rtl\:-inset-y-512,[dir='rtl'].rtl\:-inset-y-512 {
  top: -51.2rem;
  bottom: -51.2rem
}

[dir='rtl'] .rtl\:-inset-x-512,[dir='rtl'].rtl\:-inset-x-512 {
  right: -51.2rem;
  left: -51.2rem
}

[dir='rtl'] .rtl\:-inset-y-640,[dir='rtl'].rtl\:-inset-y-640 {
  top: -64rem;
  bottom: -64rem
}

[dir='rtl'] .rtl\:-inset-x-640,[dir='rtl'].rtl\:-inset-x-640 {
  right: -64rem;
  left: -64rem
}

[dir='rtl'] .rtl\:-inset-y-xs,[dir='rtl'].rtl\:-inset-y-xs {
  top: -32rem;
  bottom: -32rem
}

[dir='rtl'] .rtl\:-inset-x-xs,[dir='rtl'].rtl\:-inset-x-xs {
  right: -32rem;
  left: -32rem
}

[dir='rtl'] .rtl\:-inset-y-sm,[dir='rtl'].rtl\:-inset-y-sm {
  top: -48rem;
  bottom: -48rem
}

[dir='rtl'] .rtl\:-inset-x-sm,[dir='rtl'].rtl\:-inset-x-sm {
  right: -48rem;
  left: -48rem
}

[dir='rtl'] .rtl\:-inset-y-md,[dir='rtl'].rtl\:-inset-y-md {
  top: -64rem;
  bottom: -64rem
}

[dir='rtl'] .rtl\:-inset-x-md,[dir='rtl'].rtl\:-inset-x-md {
  right: -64rem;
  left: -64rem
}

[dir='rtl'] .rtl\:-inset-y-lg,[dir='rtl'].rtl\:-inset-y-lg {
  top: -80rem;
  bottom: -80rem
}

[dir='rtl'] .rtl\:-inset-x-lg,[dir='rtl'].rtl\:-inset-x-lg {
  right: -80rem;
  left: -80rem
}

[dir='rtl'] .rtl\:-inset-y-xl,[dir='rtl'].rtl\:-inset-y-xl {
  top: -96rem;
  bottom: -96rem
}

[dir='rtl'] .rtl\:-inset-x-xl,[dir='rtl'].rtl\:-inset-x-xl {
  right: -96rem;
  left: -96rem
}

[dir='rtl'] .rtl\:-inset-y-2xl,[dir='rtl'].rtl\:-inset-y-2xl {
  top: -112rem;
  bottom: -112rem
}

[dir='rtl'] .rtl\:-inset-x-2xl,[dir='rtl'].rtl\:-inset-x-2xl {
  right: -112rem;
  left: -112rem
}

[dir='rtl'] .rtl\:-inset-y-3xl,[dir='rtl'].rtl\:-inset-y-3xl {
  top: -128rem;
  bottom: -128rem
}

[dir='rtl'] .rtl\:-inset-x-3xl,[dir='rtl'].rtl\:-inset-x-3xl {
  right: -128rem;
  left: -128rem
}

[dir='rtl'] .rtl\:-inset-y-4xl,[dir='rtl'].rtl\:-inset-y-4xl {
  top: -144rem;
  bottom: -144rem
}

[dir='rtl'] .rtl\:-inset-x-4xl,[dir='rtl'].rtl\:-inset-x-4xl {
  right: -144rem;
  left: -144rem
}

[dir='rtl'] .rtl\:-inset-y-5xl,[dir='rtl'].rtl\:-inset-y-5xl {
  top: -160rem;
  bottom: -160rem
}

[dir='rtl'] .rtl\:-inset-x-5xl,[dir='rtl'].rtl\:-inset-x-5xl {
  right: -160rem;
  left: -160rem
}

[dir='rtl'] .rtl\:-inset-y-px,[dir='rtl'].rtl\:-inset-y-px {
  top: -1px;
  bottom: -1px
}

[dir='rtl'] .rtl\:-inset-x-px,[dir='rtl'].rtl\:-inset-x-px {
  right: -1px;
  left: -1px
}

[dir='rtl'] .rtl\:-inset-y-0\.5,[dir='rtl'].rtl\:-inset-y-0\.5 {
  top: -0.05rem;
  bottom: -0.05rem
}

[dir='rtl'] .rtl\:-inset-x-0\.5,[dir='rtl'].rtl\:-inset-x-0\.5 {
  right: -0.05rem;
  left: -0.05rem
}

[dir='rtl'] .rtl\:-inset-y-1\.5,[dir='rtl'].rtl\:-inset-y-1\.5 {
  top: -0.15rem;
  bottom: -0.15rem
}

[dir='rtl'] .rtl\:-inset-x-1\.5,[dir='rtl'].rtl\:-inset-x-1\.5 {
  right: -0.15rem;
  left: -0.15rem
}

[dir='rtl'] .rtl\:-inset-y-2\.5,[dir='rtl'].rtl\:-inset-y-2\.5 {
  top: -0.25rem;
  bottom: -0.25rem
}

[dir='rtl'] .rtl\:-inset-x-2\.5,[dir='rtl'].rtl\:-inset-x-2\.5 {
  right: -0.25rem;
  left: -0.25rem
}

[dir='rtl'] .rtl\:-inset-y-3\.5,[dir='rtl'].rtl\:-inset-y-3\.5 {
  top: -0.35rem;
  bottom: -0.35rem
}

[dir='rtl'] .rtl\:-inset-x-3\.5,[dir='rtl'].rtl\:-inset-x-3\.5 {
  right: -0.35rem;
  left: -0.35rem
}

[dir='rtl'] .rtl\:inset-y-1\/2,[dir='rtl'].rtl\:inset-y-1\/2 {
  top: 50%;
  bottom: 50%
}

[dir='rtl'] .rtl\:inset-x-1\/2,[dir='rtl'].rtl\:inset-x-1\/2 {
  right: 50%;
  left: 50%
}

[dir='rtl'] .rtl\:inset-y-1\/3,[dir='rtl'].rtl\:inset-y-1\/3 {
  top: 33.333333%;
  bottom: 33.333333%
}

[dir='rtl'] .rtl\:inset-x-1\/3,[dir='rtl'].rtl\:inset-x-1\/3 {
  right: 33.333333%;
  left: 33.333333%
}

[dir='rtl'] .rtl\:inset-y-2\/3,[dir='rtl'].rtl\:inset-y-2\/3 {
  top: 66.666667%;
  bottom: 66.666667%
}

[dir='rtl'] .rtl\:inset-x-2\/3,[dir='rtl'].rtl\:inset-x-2\/3 {
  right: 66.666667%;
  left: 66.666667%
}

[dir='rtl'] .rtl\:inset-y-1\/4,[dir='rtl'].rtl\:inset-y-1\/4 {
  top: 25%;
  bottom: 25%
}

[dir='rtl'] .rtl\:inset-x-1\/4,[dir='rtl'].rtl\:inset-x-1\/4 {
  right: 25%;
  left: 25%
}

[dir='rtl'] .rtl\:inset-y-2\/4,[dir='rtl'].rtl\:inset-y-2\/4 {
  top: 50%;
  bottom: 50%
}

[dir='rtl'] .rtl\:inset-x-2\/4,[dir='rtl'].rtl\:inset-x-2\/4 {
  right: 50%;
  left: 50%
}

[dir='rtl'] .rtl\:inset-y-3\/4,[dir='rtl'].rtl\:inset-y-3\/4 {
  top: 75%;
  bottom: 75%
}

[dir='rtl'] .rtl\:inset-x-3\/4,[dir='rtl'].rtl\:inset-x-3\/4 {
  right: 75%;
  left: 75%
}

[dir='rtl'] .rtl\:inset-y-full,[dir='rtl'].rtl\:inset-y-full {
  top: 100%;
  bottom: 100%
}

[dir='rtl'] .rtl\:inset-x-full,[dir='rtl'].rtl\:inset-x-full {
  right: 100%;
  left: 100%
}

[dir='rtl'] .rtl\:-inset-y-1\/2,[dir='rtl'].rtl\:-inset-y-1\/2 {
  top: -50%;
  bottom: -50%
}

[dir='rtl'] .rtl\:-inset-x-1\/2,[dir='rtl'].rtl\:-inset-x-1\/2 {
  right: -50%;
  left: -50%
}

[dir='rtl'] .rtl\:-inset-y-1\/3,[dir='rtl'].rtl\:-inset-y-1\/3 {
  top: -33.333333%;
  bottom: -33.333333%
}

[dir='rtl'] .rtl\:-inset-x-1\/3,[dir='rtl'].rtl\:-inset-x-1\/3 {
  right: -33.333333%;
  left: -33.333333%
}

[dir='rtl'] .rtl\:-inset-y-2\/3,[dir='rtl'].rtl\:-inset-y-2\/3 {
  top: -66.666667%;
  bottom: -66.666667%
}

[dir='rtl'] .rtl\:-inset-x-2\/3,[dir='rtl'].rtl\:-inset-x-2\/3 {
  right: -66.666667%;
  left: -66.666667%
}

[dir='rtl'] .rtl\:-inset-y-1\/4,[dir='rtl'].rtl\:-inset-y-1\/4 {
  top: -25%;
  bottom: -25%
}

[dir='rtl'] .rtl\:-inset-x-1\/4,[dir='rtl'].rtl\:-inset-x-1\/4 {
  right: -25%;
  left: -25%
}

[dir='rtl'] .rtl\:-inset-y-2\/4,[dir='rtl'].rtl\:-inset-y-2\/4 {
  top: -50%;
  bottom: -50%
}

[dir='rtl'] .rtl\:-inset-x-2\/4,[dir='rtl'].rtl\:-inset-x-2\/4 {
  right: -50%;
  left: -50%
}

[dir='rtl'] .rtl\:-inset-y-3\/4,[dir='rtl'].rtl\:-inset-y-3\/4 {
  top: -75%;
  bottom: -75%
}

[dir='rtl'] .rtl\:-inset-x-3\/4,[dir='rtl'].rtl\:-inset-x-3\/4 {
  right: -75%;
  left: -75%
}

[dir='rtl'] .rtl\:-inset-y-full,[dir='rtl'].rtl\:-inset-y-full {
  top: -100%;
  bottom: -100%
}

[dir='rtl'] .rtl\:-inset-x-full,[dir='rtl'].rtl\:-inset-x-full {
  right: -100%;
  left: -100%
}

[dir='rtl'] .rtl\:top-0,[dir='rtl'].rtl\:top-0 {
  top: 0
}

[dir='rtl'] .rtl\:right-0,[dir='rtl'].rtl\:right-0 {
  right: 0
}

[dir='rtl'] .rtl\:bottom-0,[dir='rtl'].rtl\:bottom-0 {
  bottom: 0
}

[dir='rtl'] .rtl\:left-0,[dir='rtl'].rtl\:left-0 {
  left: 0
}

[dir='rtl'] .rtl\:top-1,[dir='rtl'].rtl\:top-1 {
  top: 0.1rem
}

[dir='rtl'] .rtl\:right-1,[dir='rtl'].rtl\:right-1 {
  right: 0.1rem
}

[dir='rtl'] .rtl\:bottom-1,[dir='rtl'].rtl\:bottom-1 {
  bottom: 0.1rem
}

[dir='rtl'] .rtl\:left-1,[dir='rtl'].rtl\:left-1 {
  left: 0.1rem
}

[dir='rtl'] .rtl\:top-2,[dir='rtl'].rtl\:top-2 {
  top: 0.2rem
}

[dir='rtl'] .rtl\:right-2,[dir='rtl'].rtl\:right-2 {
  right: 0.2rem
}

[dir='rtl'] .rtl\:bottom-2,[dir='rtl'].rtl\:bottom-2 {
  bottom: 0.2rem
}

[dir='rtl'] .rtl\:left-2,[dir='rtl'].rtl\:left-2 {
  left: 0.2rem
}

[dir='rtl'] .rtl\:top-3,[dir='rtl'].rtl\:top-3 {
  top: 0.3rem
}

[dir='rtl'] .rtl\:right-3,[dir='rtl'].rtl\:right-3 {
  right: 0.3rem
}

[dir='rtl'] .rtl\:bottom-3,[dir='rtl'].rtl\:bottom-3 {
  bottom: 0.3rem
}

[dir='rtl'] .rtl\:left-3,[dir='rtl'].rtl\:left-3 {
  left: 0.3rem
}

[dir='rtl'] .rtl\:top-4,[dir='rtl'].rtl\:top-4 {
  top: 0.4rem
}

[dir='rtl'] .rtl\:right-4,[dir='rtl'].rtl\:right-4 {
  right: 0.4rem
}

[dir='rtl'] .rtl\:bottom-4,[dir='rtl'].rtl\:bottom-4 {
  bottom: 0.4rem
}

[dir='rtl'] .rtl\:left-4,[dir='rtl'].rtl\:left-4 {
  left: 0.4rem
}

[dir='rtl'] .rtl\:top-5,[dir='rtl'].rtl\:top-5 {
  top: 0.5rem
}

[dir='rtl'] .rtl\:right-5,[dir='rtl'].rtl\:right-5 {
  right: 0.5rem
}

[dir='rtl'] .rtl\:bottom-5,[dir='rtl'].rtl\:bottom-5 {
  bottom: 0.5rem
}

[dir='rtl'] .rtl\:left-5,[dir='rtl'].rtl\:left-5 {
  left: 0.5rem
}

[dir='rtl'] .rtl\:top-6,[dir='rtl'].rtl\:top-6 {
  top: 0.6rem
}

[dir='rtl'] .rtl\:right-6,[dir='rtl'].rtl\:right-6 {
  right: 0.6rem
}

[dir='rtl'] .rtl\:bottom-6,[dir='rtl'].rtl\:bottom-6 {
  bottom: 0.6rem
}

[dir='rtl'] .rtl\:left-6,[dir='rtl'].rtl\:left-6 {
  left: 0.6rem
}

[dir='rtl'] .rtl\:top-7,[dir='rtl'].rtl\:top-7 {
  top: 0.7rem
}

[dir='rtl'] .rtl\:right-7,[dir='rtl'].rtl\:right-7 {
  right: 0.7rem
}

[dir='rtl'] .rtl\:bottom-7,[dir='rtl'].rtl\:bottom-7 {
  bottom: 0.7rem
}

[dir='rtl'] .rtl\:left-7,[dir='rtl'].rtl\:left-7 {
  left: 0.7rem
}

[dir='rtl'] .rtl\:top-8,[dir='rtl'].rtl\:top-8 {
  top: 0.8rem
}

[dir='rtl'] .rtl\:right-8,[dir='rtl'].rtl\:right-8 {
  right: 0.8rem
}

[dir='rtl'] .rtl\:bottom-8,[dir='rtl'].rtl\:bottom-8 {
  bottom: 0.8rem
}

[dir='rtl'] .rtl\:left-8,[dir='rtl'].rtl\:left-8 {
  left: 0.8rem
}

[dir='rtl'] .rtl\:top-9,[dir='rtl'].rtl\:top-9 {
  top: 0.9rem
}

[dir='rtl'] .rtl\:right-9,[dir='rtl'].rtl\:right-9 {
  right: 0.9rem
}

[dir='rtl'] .rtl\:bottom-9,[dir='rtl'].rtl\:bottom-9 {
  bottom: 0.9rem
}

[dir='rtl'] .rtl\:left-9,[dir='rtl'].rtl\:left-9 {
  left: 0.9rem
}

[dir='rtl'] .rtl\:top-10,[dir='rtl'].rtl\:top-10 {
  top: 1.0rem
}

[dir='rtl'] .rtl\:right-10,[dir='rtl'].rtl\:right-10 {
  right: 1.0rem
}

[dir='rtl'] .rtl\:bottom-10,[dir='rtl'].rtl\:bottom-10 {
  bottom: 1.0rem
}

[dir='rtl'] .rtl\:left-10,[dir='rtl'].rtl\:left-10 {
  left: 1.0rem
}

[dir='rtl'] .rtl\:top-12,[dir='rtl'].rtl\:top-12 {
  top: 1.2rem
}

[dir='rtl'] .rtl\:right-12,[dir='rtl'].rtl\:right-12 {
  right: 1.2rem
}

[dir='rtl'] .rtl\:bottom-12,[dir='rtl'].rtl\:bottom-12 {
  bottom: 1.2rem
}

[dir='rtl'] .rtl\:left-12,[dir='rtl'].rtl\:left-12 {
  left: 1.2rem
}

[dir='rtl'] .rtl\:top-14,[dir='rtl'].rtl\:top-14 {
  top: 1.4rem
}

[dir='rtl'] .rtl\:right-14,[dir='rtl'].rtl\:right-14 {
  right: 1.4rem
}

[dir='rtl'] .rtl\:bottom-14,[dir='rtl'].rtl\:bottom-14 {
  bottom: 1.4rem
}

[dir='rtl'] .rtl\:left-14,[dir='rtl'].rtl\:left-14 {
  left: 1.4rem
}

[dir='rtl'] .rtl\:top-16,[dir='rtl'].rtl\:top-16 {
  top: 1.6rem
}

[dir='rtl'] .rtl\:right-16,[dir='rtl'].rtl\:right-16 {
  right: 1.6rem
}

[dir='rtl'] .rtl\:bottom-16,[dir='rtl'].rtl\:bottom-16 {
  bottom: 1.6rem
}

[dir='rtl'] .rtl\:left-16,[dir='rtl'].rtl\:left-16 {
  left: 1.6rem
}

[dir='rtl'] .rtl\:top-20,[dir='rtl'].rtl\:top-20 {
  top: 2rem
}

[dir='rtl'] .rtl\:right-20,[dir='rtl'].rtl\:right-20 {
  right: 2rem
}

[dir='rtl'] .rtl\:bottom-20,[dir='rtl'].rtl\:bottom-20 {
  bottom: 2rem
}

[dir='rtl'] .rtl\:left-20,[dir='rtl'].rtl\:left-20 {
  left: 2rem
}

[dir='rtl'] .rtl\:top-24,[dir='rtl'].rtl\:top-24 {
  top: 2.4rem
}

[dir='rtl'] .rtl\:right-24,[dir='rtl'].rtl\:right-24 {
  right: 2.4rem
}

[dir='rtl'] .rtl\:bottom-24,[dir='rtl'].rtl\:bottom-24 {
  bottom: 2.4rem
}

[dir='rtl'] .rtl\:left-24,[dir='rtl'].rtl\:left-24 {
  left: 2.4rem
}

[dir='rtl'] .rtl\:top-28,[dir='rtl'].rtl\:top-28 {
  top: 2.8rem
}

[dir='rtl'] .rtl\:right-28,[dir='rtl'].rtl\:right-28 {
  right: 2.8rem
}

[dir='rtl'] .rtl\:bottom-28,[dir='rtl'].rtl\:bottom-28 {
  bottom: 2.8rem
}

[dir='rtl'] .rtl\:left-28,[dir='rtl'].rtl\:left-28 {
  left: 2.8rem
}

[dir='rtl'] .rtl\:top-32,[dir='rtl'].rtl\:top-32 {
  top: 3.2rem
}

[dir='rtl'] .rtl\:right-32,[dir='rtl'].rtl\:right-32 {
  right: 3.2rem
}

[dir='rtl'] .rtl\:bottom-32,[dir='rtl'].rtl\:bottom-32 {
  bottom: 3.2rem
}

[dir='rtl'] .rtl\:left-32,[dir='rtl'].rtl\:left-32 {
  left: 3.2rem
}

[dir='rtl'] .rtl\:top-36,[dir='rtl'].rtl\:top-36 {
  top: 3.6rem
}

[dir='rtl'] .rtl\:right-36,[dir='rtl'].rtl\:right-36 {
  right: 3.6rem
}

[dir='rtl'] .rtl\:bottom-36,[dir='rtl'].rtl\:bottom-36 {
  bottom: 3.6rem
}

[dir='rtl'] .rtl\:left-36,[dir='rtl'].rtl\:left-36 {
  left: 3.6rem
}

[dir='rtl'] .rtl\:top-40,[dir='rtl'].rtl\:top-40 {
  top: 4rem
}

[dir='rtl'] .rtl\:right-40,[dir='rtl'].rtl\:right-40 {
  right: 4rem
}

[dir='rtl'] .rtl\:bottom-40,[dir='rtl'].rtl\:bottom-40 {
  bottom: 4rem
}

[dir='rtl'] .rtl\:left-40,[dir='rtl'].rtl\:left-40 {
  left: 4rem
}

[dir='rtl'] .rtl\:top-44,[dir='rtl'].rtl\:top-44 {
  top: 4.4rem
}

[dir='rtl'] .rtl\:right-44,[dir='rtl'].rtl\:right-44 {
  right: 4.4rem
}

[dir='rtl'] .rtl\:bottom-44,[dir='rtl'].rtl\:bottom-44 {
  bottom: 4.4rem
}

[dir='rtl'] .rtl\:left-44,[dir='rtl'].rtl\:left-44 {
  left: 4.4rem
}

[dir='rtl'] .rtl\:top-48,[dir='rtl'].rtl\:top-48 {
  top: 4.8rem
}

[dir='rtl'] .rtl\:right-48,[dir='rtl'].rtl\:right-48 {
  right: 4.8rem
}

[dir='rtl'] .rtl\:bottom-48,[dir='rtl'].rtl\:bottom-48 {
  bottom: 4.8rem
}

[dir='rtl'] .rtl\:left-48,[dir='rtl'].rtl\:left-48 {
  left: 4.8rem
}

[dir='rtl'] .rtl\:top-52,[dir='rtl'].rtl\:top-52 {
  top: 5.2rem
}

[dir='rtl'] .rtl\:right-52,[dir='rtl'].rtl\:right-52 {
  right: 5.2rem
}

[dir='rtl'] .rtl\:bottom-52,[dir='rtl'].rtl\:bottom-52 {
  bottom: 5.2rem
}

[dir='rtl'] .rtl\:left-52,[dir='rtl'].rtl\:left-52 {
  left: 5.2rem
}

[dir='rtl'] .rtl\:top-56,[dir='rtl'].rtl\:top-56 {
  top: 5.6rem
}

[dir='rtl'] .rtl\:right-56,[dir='rtl'].rtl\:right-56 {
  right: 5.6rem
}

[dir='rtl'] .rtl\:bottom-56,[dir='rtl'].rtl\:bottom-56 {
  bottom: 5.6rem
}

[dir='rtl'] .rtl\:left-56,[dir='rtl'].rtl\:left-56 {
  left: 5.6rem
}

[dir='rtl'] .rtl\:top-60,[dir='rtl'].rtl\:top-60 {
  top: 6rem
}

[dir='rtl'] .rtl\:right-60,[dir='rtl'].rtl\:right-60 {
  right: 6rem
}

[dir='rtl'] .rtl\:bottom-60,[dir='rtl'].rtl\:bottom-60 {
  bottom: 6rem
}

[dir='rtl'] .rtl\:left-60,[dir='rtl'].rtl\:left-60 {
  left: 6rem
}

[dir='rtl'] .rtl\:top-64,[dir='rtl'].rtl\:top-64 {
  top: 6.4rem
}

[dir='rtl'] .rtl\:right-64,[dir='rtl'].rtl\:right-64 {
  right: 6.4rem
}

[dir='rtl'] .rtl\:bottom-64,[dir='rtl'].rtl\:bottom-64 {
  bottom: 6.4rem
}

[dir='rtl'] .rtl\:left-64,[dir='rtl'].rtl\:left-64 {
  left: 6.4rem
}

[dir='rtl'] .rtl\:top-68,[dir='rtl'].rtl\:top-68 {
  top: 6.8rem
}

[dir='rtl'] .rtl\:right-68,[dir='rtl'].rtl\:right-68 {
  right: 6.8rem
}

[dir='rtl'] .rtl\:bottom-68,[dir='rtl'].rtl\:bottom-68 {
  bottom: 6.8rem
}

[dir='rtl'] .rtl\:left-68,[dir='rtl'].rtl\:left-68 {
  left: 6.8rem
}

[dir='rtl'] .rtl\:top-72,[dir='rtl'].rtl\:top-72 {
  top: 7.2rem
}

[dir='rtl'] .rtl\:right-72,[dir='rtl'].rtl\:right-72 {
  right: 7.2rem
}

[dir='rtl'] .rtl\:bottom-72,[dir='rtl'].rtl\:bottom-72 {
  bottom: 7.2rem
}

[dir='rtl'] .rtl\:left-72,[dir='rtl'].rtl\:left-72 {
  left: 7.2rem
}

[dir='rtl'] .rtl\:top-76,[dir='rtl'].rtl\:top-76 {
  top: 7.6rem
}

[dir='rtl'] .rtl\:right-76,[dir='rtl'].rtl\:right-76 {
  right: 7.6rem
}

[dir='rtl'] .rtl\:bottom-76,[dir='rtl'].rtl\:bottom-76 {
  bottom: 7.6rem
}

[dir='rtl'] .rtl\:left-76,[dir='rtl'].rtl\:left-76 {
  left: 7.6rem
}

[dir='rtl'] .rtl\:top-80,[dir='rtl'].rtl\:top-80 {
  top: 8rem
}

[dir='rtl'] .rtl\:right-80,[dir='rtl'].rtl\:right-80 {
  right: 8rem
}

[dir='rtl'] .rtl\:bottom-80,[dir='rtl'].rtl\:bottom-80 {
  bottom: 8rem
}

[dir='rtl'] .rtl\:left-80,[dir='rtl'].rtl\:left-80 {
  left: 8rem
}

[dir='rtl'] .rtl\:top-84,[dir='rtl'].rtl\:top-84 {
  top: 8.4rem
}

[dir='rtl'] .rtl\:right-84,[dir='rtl'].rtl\:right-84 {
  right: 8.4rem
}

[dir='rtl'] .rtl\:bottom-84,[dir='rtl'].rtl\:bottom-84 {
  bottom: 8.4rem
}

[dir='rtl'] .rtl\:left-84,[dir='rtl'].rtl\:left-84 {
  left: 8.4rem
}

[dir='rtl'] .rtl\:top-88,[dir='rtl'].rtl\:top-88 {
  top: 8.8rem
}

[dir='rtl'] .rtl\:right-88,[dir='rtl'].rtl\:right-88 {
  right: 8.8rem
}

[dir='rtl'] .rtl\:bottom-88,[dir='rtl'].rtl\:bottom-88 {
  bottom: 8.8rem
}

[dir='rtl'] .rtl\:left-88,[dir='rtl'].rtl\:left-88 {
  left: 8.8rem
}

[dir='rtl'] .rtl\:top-92,[dir='rtl'].rtl\:top-92 {
  top: 9.2rem
}

[dir='rtl'] .rtl\:right-92,[dir='rtl'].rtl\:right-92 {
  right: 9.2rem
}

[dir='rtl'] .rtl\:bottom-92,[dir='rtl'].rtl\:bottom-92 {
  bottom: 9.2rem
}

[dir='rtl'] .rtl\:left-92,[dir='rtl'].rtl\:left-92 {
  left: 9.2rem
}

[dir='rtl'] .rtl\:top-96,[dir='rtl'].rtl\:top-96 {
  top: 9.6rem
}

[dir='rtl'] .rtl\:right-96,[dir='rtl'].rtl\:right-96 {
  right: 9.6rem
}

[dir='rtl'] .rtl\:bottom-96,[dir='rtl'].rtl\:bottom-96 {
  bottom: 9.6rem
}

[dir='rtl'] .rtl\:left-96,[dir='rtl'].rtl\:left-96 {
  left: 9.6rem
}

[dir='rtl'] .rtl\:top-128,[dir='rtl'].rtl\:top-128 {
  top: 12.8rem
}

[dir='rtl'] .rtl\:right-128,[dir='rtl'].rtl\:right-128 {
  right: 12.8rem
}

[dir='rtl'] .rtl\:bottom-128,[dir='rtl'].rtl\:bottom-128 {
  bottom: 12.8rem
}

[dir='rtl'] .rtl\:left-128,[dir='rtl'].rtl\:left-128 {
  left: 12.8rem
}

[dir='rtl'] .rtl\:top-136,[dir='rtl'].rtl\:top-136 {
  top: 13.6rem
}

[dir='rtl'] .rtl\:right-136,[dir='rtl'].rtl\:right-136 {
  right: 13.6rem
}

[dir='rtl'] .rtl\:bottom-136,[dir='rtl'].rtl\:bottom-136 {
  bottom: 13.6rem
}

[dir='rtl'] .rtl\:left-136,[dir='rtl'].rtl\:left-136 {
  left: 13.6rem
}

[dir='rtl'] .rtl\:top-160,[dir='rtl'].rtl\:top-160 {
  top: 16rem
}

[dir='rtl'] .rtl\:right-160,[dir='rtl'].rtl\:right-160 {
  right: 16rem
}

[dir='rtl'] .rtl\:bottom-160,[dir='rtl'].rtl\:bottom-160 {
  bottom: 16rem
}

[dir='rtl'] .rtl\:left-160,[dir='rtl'].rtl\:left-160 {
  left: 16rem
}

[dir='rtl'] .rtl\:top-192,[dir='rtl'].rtl\:top-192 {
  top: 19.2rem
}

[dir='rtl'] .rtl\:right-192,[dir='rtl'].rtl\:right-192 {
  right: 19.2rem
}

[dir='rtl'] .rtl\:bottom-192,[dir='rtl'].rtl\:bottom-192 {
  bottom: 19.2rem
}

[dir='rtl'] .rtl\:left-192,[dir='rtl'].rtl\:left-192 {
  left: 19.2rem
}

[dir='rtl'] .rtl\:top-200,[dir='rtl'].rtl\:top-200 {
  top: 20rem
}

[dir='rtl'] .rtl\:right-200,[dir='rtl'].rtl\:right-200 {
  right: 20rem
}

[dir='rtl'] .rtl\:bottom-200,[dir='rtl'].rtl\:bottom-200 {
  bottom: 20rem
}

[dir='rtl'] .rtl\:left-200,[dir='rtl'].rtl\:left-200 {
  left: 20rem
}

[dir='rtl'] .rtl\:top-208,[dir='rtl'].rtl\:top-208 {
  top: 20.8rem
}

[dir='rtl'] .rtl\:right-208,[dir='rtl'].rtl\:right-208 {
  right: 20.8rem
}

[dir='rtl'] .rtl\:bottom-208,[dir='rtl'].rtl\:bottom-208 {
  bottom: 20.8rem
}

[dir='rtl'] .rtl\:left-208,[dir='rtl'].rtl\:left-208 {
  left: 20.8rem
}

[dir='rtl'] .rtl\:top-216,[dir='rtl'].rtl\:top-216 {
  top: 21.6rem
}

[dir='rtl'] .rtl\:right-216,[dir='rtl'].rtl\:right-216 {
  right: 21.6rem
}

[dir='rtl'] .rtl\:bottom-216,[dir='rtl'].rtl\:bottom-216 {
  bottom: 21.6rem
}

[dir='rtl'] .rtl\:left-216,[dir='rtl'].rtl\:left-216 {
  left: 21.6rem
}

[dir='rtl'] .rtl\:top-224,[dir='rtl'].rtl\:top-224 {
  top: 22.4rem
}

[dir='rtl'] .rtl\:right-224,[dir='rtl'].rtl\:right-224 {
  right: 22.4rem
}

[dir='rtl'] .rtl\:bottom-224,[dir='rtl'].rtl\:bottom-224 {
  bottom: 22.4rem
}

[dir='rtl'] .rtl\:left-224,[dir='rtl'].rtl\:left-224 {
  left: 22.4rem
}

[dir='rtl'] .rtl\:top-256,[dir='rtl'].rtl\:top-256 {
  top: 25.6rem
}

[dir='rtl'] .rtl\:right-256,[dir='rtl'].rtl\:right-256 {
  right: 25.6rem
}

[dir='rtl'] .rtl\:bottom-256,[dir='rtl'].rtl\:bottom-256 {
  bottom: 25.6rem
}

[dir='rtl'] .rtl\:left-256,[dir='rtl'].rtl\:left-256 {
  left: 25.6rem
}

[dir='rtl'] .rtl\:top-288,[dir='rtl'].rtl\:top-288 {
  top: 28.8rem
}

[dir='rtl'] .rtl\:right-288,[dir='rtl'].rtl\:right-288 {
  right: 28.8rem
}

[dir='rtl'] .rtl\:bottom-288,[dir='rtl'].rtl\:bottom-288 {
  bottom: 28.8rem
}

[dir='rtl'] .rtl\:left-288,[dir='rtl'].rtl\:left-288 {
  left: 28.8rem
}

[dir='rtl'] .rtl\:top-320,[dir='rtl'].rtl\:top-320 {
  top: 32rem
}

[dir='rtl'] .rtl\:right-320,[dir='rtl'].rtl\:right-320 {
  right: 32rem
}

[dir='rtl'] .rtl\:bottom-320,[dir='rtl'].rtl\:bottom-320 {
  bottom: 32rem
}

[dir='rtl'] .rtl\:left-320,[dir='rtl'].rtl\:left-320 {
  left: 32rem
}

[dir='rtl'] .rtl\:top-360,[dir='rtl'].rtl\:top-360 {
  top: 36rem
}

[dir='rtl'] .rtl\:right-360,[dir='rtl'].rtl\:right-360 {
  right: 36rem
}

[dir='rtl'] .rtl\:bottom-360,[dir='rtl'].rtl\:bottom-360 {
  bottom: 36rem
}

[dir='rtl'] .rtl\:left-360,[dir='rtl'].rtl\:left-360 {
  left: 36rem
}

[dir='rtl'] .rtl\:top-384,[dir='rtl'].rtl\:top-384 {
  top: 38.4rem
}

[dir='rtl'] .rtl\:right-384,[dir='rtl'].rtl\:right-384 {
  right: 38.4rem
}

[dir='rtl'] .rtl\:bottom-384,[dir='rtl'].rtl\:bottom-384 {
  bottom: 38.4rem
}

[dir='rtl'] .rtl\:left-384,[dir='rtl'].rtl\:left-384 {
  left: 38.4rem
}

[dir='rtl'] .rtl\:top-400,[dir='rtl'].rtl\:top-400 {
  top: 40rem
}

[dir='rtl'] .rtl\:right-400,[dir='rtl'].rtl\:right-400 {
  right: 40rem
}

[dir='rtl'] .rtl\:bottom-400,[dir='rtl'].rtl\:bottom-400 {
  bottom: 40rem
}

[dir='rtl'] .rtl\:left-400,[dir='rtl'].rtl\:left-400 {
  left: 40rem
}

[dir='rtl'] .rtl\:top-512,[dir='rtl'].rtl\:top-512 {
  top: 51.2rem
}

[dir='rtl'] .rtl\:right-512,[dir='rtl'].rtl\:right-512 {
  right: 51.2rem
}

[dir='rtl'] .rtl\:bottom-512,[dir='rtl'].rtl\:bottom-512 {
  bottom: 51.2rem
}

[dir='rtl'] .rtl\:left-512,[dir='rtl'].rtl\:left-512 {
  left: 51.2rem
}

[dir='rtl'] .rtl\:top-640,[dir='rtl'].rtl\:top-640 {
  top: 64rem
}

[dir='rtl'] .rtl\:right-640,[dir='rtl'].rtl\:right-640 {
  right: 64rem
}

[dir='rtl'] .rtl\:bottom-640,[dir='rtl'].rtl\:bottom-640 {
  bottom: 64rem
}

[dir='rtl'] .rtl\:left-640,[dir='rtl'].rtl\:left-640 {
  left: 64rem
}

[dir='rtl'] .rtl\:top-auto,[dir='rtl'].rtl\:top-auto {
  top: auto
}

[dir='rtl'] .rtl\:right-auto,[dir='rtl'].rtl\:right-auto {
  right: auto
}

[dir='rtl'] .rtl\:bottom-auto,[dir='rtl'].rtl\:bottom-auto {
  bottom: auto
}

[dir='rtl'] .rtl\:left-auto,[dir='rtl'].rtl\:left-auto {
  left: auto
}

[dir='rtl'] .rtl\:top-xs,[dir='rtl'].rtl\:top-xs {
  top: 32rem
}

[dir='rtl'] .rtl\:right-xs,[dir='rtl'].rtl\:right-xs {
  right: 32rem
}

[dir='rtl'] .rtl\:bottom-xs,[dir='rtl'].rtl\:bottom-xs {
  bottom: 32rem
}

[dir='rtl'] .rtl\:left-xs,[dir='rtl'].rtl\:left-xs {
  left: 32rem
}

[dir='rtl'] .rtl\:top-sm,[dir='rtl'].rtl\:top-sm {
  top: 48rem
}

[dir='rtl'] .rtl\:right-sm,[dir='rtl'].rtl\:right-sm {
  right: 48rem
}

[dir='rtl'] .rtl\:bottom-sm,[dir='rtl'].rtl\:bottom-sm {
  bottom: 48rem
}

[dir='rtl'] .rtl\:left-sm,[dir='rtl'].rtl\:left-sm {
  left: 48rem
}

[dir='rtl'] .rtl\:top-md,[dir='rtl'].rtl\:top-md {
  top: 64rem
}

[dir='rtl'] .rtl\:right-md,[dir='rtl'].rtl\:right-md {
  right: 64rem
}

[dir='rtl'] .rtl\:bottom-md,[dir='rtl'].rtl\:bottom-md {
  bottom: 64rem
}

[dir='rtl'] .rtl\:left-md,[dir='rtl'].rtl\:left-md {
  left: 64rem
}

[dir='rtl'] .rtl\:top-lg,[dir='rtl'].rtl\:top-lg {
  top: 80rem
}

[dir='rtl'] .rtl\:right-lg,[dir='rtl'].rtl\:right-lg {
  right: 80rem
}

[dir='rtl'] .rtl\:bottom-lg,[dir='rtl'].rtl\:bottom-lg {
  bottom: 80rem
}

[dir='rtl'] .rtl\:left-lg,[dir='rtl'].rtl\:left-lg {
  left: 80rem
}

[dir='rtl'] .rtl\:top-xl,[dir='rtl'].rtl\:top-xl {
  top: 96rem
}

[dir='rtl'] .rtl\:right-xl,[dir='rtl'].rtl\:right-xl {
  right: 96rem
}

[dir='rtl'] .rtl\:bottom-xl,[dir='rtl'].rtl\:bottom-xl {
  bottom: 96rem
}

[dir='rtl'] .rtl\:left-xl,[dir='rtl'].rtl\:left-xl {
  left: 96rem
}

[dir='rtl'] .rtl\:top-2xl,[dir='rtl'].rtl\:top-2xl {
  top: 112rem
}

[dir='rtl'] .rtl\:right-2xl,[dir='rtl'].rtl\:right-2xl {
  right: 112rem
}

[dir='rtl'] .rtl\:bottom-2xl,[dir='rtl'].rtl\:bottom-2xl {
  bottom: 112rem
}

[dir='rtl'] .rtl\:left-2xl,[dir='rtl'].rtl\:left-2xl {
  left: 112rem
}

[dir='rtl'] .rtl\:top-3xl,[dir='rtl'].rtl\:top-3xl {
  top: 128rem
}

[dir='rtl'] .rtl\:right-3xl,[dir='rtl'].rtl\:right-3xl {
  right: 128rem
}

[dir='rtl'] .rtl\:bottom-3xl,[dir='rtl'].rtl\:bottom-3xl {
  bottom: 128rem
}

[dir='rtl'] .rtl\:left-3xl,[dir='rtl'].rtl\:left-3xl {
  left: 128rem
}

[dir='rtl'] .rtl\:top-4xl,[dir='rtl'].rtl\:top-4xl {
  top: 144rem
}

[dir='rtl'] .rtl\:right-4xl,[dir='rtl'].rtl\:right-4xl {
  right: 144rem
}

[dir='rtl'] .rtl\:bottom-4xl,[dir='rtl'].rtl\:bottom-4xl {
  bottom: 144rem
}

[dir='rtl'] .rtl\:left-4xl,[dir='rtl'].rtl\:left-4xl {
  left: 144rem
}

[dir='rtl'] .rtl\:top-5xl,[dir='rtl'].rtl\:top-5xl {
  top: 160rem
}

[dir='rtl'] .rtl\:right-5xl,[dir='rtl'].rtl\:right-5xl {
  right: 160rem
}

[dir='rtl'] .rtl\:bottom-5xl,[dir='rtl'].rtl\:bottom-5xl {
  bottom: 160rem
}

[dir='rtl'] .rtl\:left-5xl,[dir='rtl'].rtl\:left-5xl {
  left: 160rem
}

[dir='rtl'] .rtl\:top-px,[dir='rtl'].rtl\:top-px {
  top: 1px
}

[dir='rtl'] .rtl\:right-px,[dir='rtl'].rtl\:right-px {
  right: 1px
}

[dir='rtl'] .rtl\:bottom-px,[dir='rtl'].rtl\:bottom-px {
  bottom: 1px
}

[dir='rtl'] .rtl\:left-px,[dir='rtl'].rtl\:left-px {
  left: 1px
}

[dir='rtl'] .rtl\:top-0\.5,[dir='rtl'].rtl\:top-0\.5 {
  top: 0.05rem
}

[dir='rtl'] .rtl\:right-0\.5,[dir='rtl'].rtl\:right-0\.5 {
  right: 0.05rem
}

[dir='rtl'] .rtl\:bottom-0\.5,[dir='rtl'].rtl\:bottom-0\.5 {
  bottom: 0.05rem
}

[dir='rtl'] .rtl\:left-0\.5,[dir='rtl'].rtl\:left-0\.5 {
  left: 0.05rem
}

[dir='rtl'] .rtl\:top-1\.5,[dir='rtl'].rtl\:top-1\.5 {
  top: 0.15rem
}

[dir='rtl'] .rtl\:right-1\.5,[dir='rtl'].rtl\:right-1\.5 {
  right: 0.15rem
}

[dir='rtl'] .rtl\:bottom-1\.5,[dir='rtl'].rtl\:bottom-1\.5 {
  bottom: 0.15rem
}

[dir='rtl'] .rtl\:left-1\.5,[dir='rtl'].rtl\:left-1\.5 {
  left: 0.15rem
}

[dir='rtl'] .rtl\:top-2\.5,[dir='rtl'].rtl\:top-2\.5 {
  top: 0.25rem
}

[dir='rtl'] .rtl\:right-2\.5,[dir='rtl'].rtl\:right-2\.5 {
  right: 0.25rem
}

[dir='rtl'] .rtl\:bottom-2\.5,[dir='rtl'].rtl\:bottom-2\.5 {
  bottom: 0.25rem
}

[dir='rtl'] .rtl\:left-2\.5,[dir='rtl'].rtl\:left-2\.5 {
  left: 0.25rem
}

[dir='rtl'] .rtl\:top-3\.5,[dir='rtl'].rtl\:top-3\.5 {
  top: 0.35rem
}

[dir='rtl'] .rtl\:right-3\.5,[dir='rtl'].rtl\:right-3\.5 {
  right: 0.35rem
}

[dir='rtl'] .rtl\:bottom-3\.5,[dir='rtl'].rtl\:bottom-3\.5 {
  bottom: 0.35rem
}

[dir='rtl'] .rtl\:left-3\.5,[dir='rtl'].rtl\:left-3\.5 {
  left: 0.35rem
}

[dir='rtl'] .rtl\:-top-1,[dir='rtl'].rtl\:-top-1 {
  top: -0.1rem
}

[dir='rtl'] .rtl\:-right-1,[dir='rtl'].rtl\:-right-1 {
  right: -0.1rem
}

[dir='rtl'] .rtl\:-bottom-1,[dir='rtl'].rtl\:-bottom-1 {
  bottom: -0.1rem
}

[dir='rtl'] .rtl\:-left-1,[dir='rtl'].rtl\:-left-1 {
  left: -0.1rem
}

[dir='rtl'] .rtl\:-top-2,[dir='rtl'].rtl\:-top-2 {
  top: -0.2rem
}

[dir='rtl'] .rtl\:-right-2,[dir='rtl'].rtl\:-right-2 {
  right: -0.2rem
}

[dir='rtl'] .rtl\:-bottom-2,[dir='rtl'].rtl\:-bottom-2 {
  bottom: -0.2rem
}

[dir='rtl'] .rtl\:-left-2,[dir='rtl'].rtl\:-left-2 {
  left: -0.2rem
}

[dir='rtl'] .rtl\:-top-3,[dir='rtl'].rtl\:-top-3 {
  top: -0.3rem
}

[dir='rtl'] .rtl\:-right-3,[dir='rtl'].rtl\:-right-3 {
  right: -0.3rem
}

[dir='rtl'] .rtl\:-bottom-3,[dir='rtl'].rtl\:-bottom-3 {
  bottom: -0.3rem
}

[dir='rtl'] .rtl\:-left-3,[dir='rtl'].rtl\:-left-3 {
  left: -0.3rem
}

[dir='rtl'] .rtl\:-top-4,[dir='rtl'].rtl\:-top-4 {
  top: -0.4rem
}

[dir='rtl'] .rtl\:-right-4,[dir='rtl'].rtl\:-right-4 {
  right: -0.4rem
}

[dir='rtl'] .rtl\:-bottom-4,[dir='rtl'].rtl\:-bottom-4 {
  bottom: -0.4rem
}

[dir='rtl'] .rtl\:-left-4,[dir='rtl'].rtl\:-left-4 {
  left: -0.4rem
}

[dir='rtl'] .rtl\:-top-5,[dir='rtl'].rtl\:-top-5 {
  top: -0.5rem
}

[dir='rtl'] .rtl\:-right-5,[dir='rtl'].rtl\:-right-5 {
  right: -0.5rem
}

[dir='rtl'] .rtl\:-bottom-5,[dir='rtl'].rtl\:-bottom-5 {
  bottom: -0.5rem
}

[dir='rtl'] .rtl\:-left-5,[dir='rtl'].rtl\:-left-5 {
  left: -0.5rem
}

[dir='rtl'] .rtl\:-top-6,[dir='rtl'].rtl\:-top-6 {
  top: -0.6rem
}

[dir='rtl'] .rtl\:-right-6,[dir='rtl'].rtl\:-right-6 {
  right: -0.6rem
}

[dir='rtl'] .rtl\:-bottom-6,[dir='rtl'].rtl\:-bottom-6 {
  bottom: -0.6rem
}

[dir='rtl'] .rtl\:-left-6,[dir='rtl'].rtl\:-left-6 {
  left: -0.6rem
}

[dir='rtl'] .rtl\:-top-7,[dir='rtl'].rtl\:-top-7 {
  top: -0.7rem
}

[dir='rtl'] .rtl\:-right-7,[dir='rtl'].rtl\:-right-7 {
  right: -0.7rem
}

[dir='rtl'] .rtl\:-bottom-7,[dir='rtl'].rtl\:-bottom-7 {
  bottom: -0.7rem
}

[dir='rtl'] .rtl\:-left-7,[dir='rtl'].rtl\:-left-7 {
  left: -0.7rem
}

[dir='rtl'] .rtl\:-top-8,[dir='rtl'].rtl\:-top-8 {
  top: -0.8rem
}

[dir='rtl'] .rtl\:-right-8,[dir='rtl'].rtl\:-right-8 {
  right: -0.8rem
}

[dir='rtl'] .rtl\:-bottom-8,[dir='rtl'].rtl\:-bottom-8 {
  bottom: -0.8rem
}

[dir='rtl'] .rtl\:-left-8,[dir='rtl'].rtl\:-left-8 {
  left: -0.8rem
}

[dir='rtl'] .rtl\:-top-9,[dir='rtl'].rtl\:-top-9 {
  top: -0.9rem
}

[dir='rtl'] .rtl\:-right-9,[dir='rtl'].rtl\:-right-9 {
  right: -0.9rem
}

[dir='rtl'] .rtl\:-bottom-9,[dir='rtl'].rtl\:-bottom-9 {
  bottom: -0.9rem
}

[dir='rtl'] .rtl\:-left-9,[dir='rtl'].rtl\:-left-9 {
  left: -0.9rem
}

[dir='rtl'] .rtl\:-top-10,[dir='rtl'].rtl\:-top-10 {
  top: -1rem
}

[dir='rtl'] .rtl\:-right-10,[dir='rtl'].rtl\:-right-10 {
  right: -1rem
}

[dir='rtl'] .rtl\:-bottom-10,[dir='rtl'].rtl\:-bottom-10 {
  bottom: -1rem
}

[dir='rtl'] .rtl\:-left-10,[dir='rtl'].rtl\:-left-10 {
  left: -1rem
}

[dir='rtl'] .rtl\:-top-12,[dir='rtl'].rtl\:-top-12 {
  top: -1.2rem
}

[dir='rtl'] .rtl\:-right-12,[dir='rtl'].rtl\:-right-12 {
  right: -1.2rem
}

[dir='rtl'] .rtl\:-bottom-12,[dir='rtl'].rtl\:-bottom-12 {
  bottom: -1.2rem
}

[dir='rtl'] .rtl\:-left-12,[dir='rtl'].rtl\:-left-12 {
  left: -1.2rem
}

[dir='rtl'] .rtl\:-top-14,[dir='rtl'].rtl\:-top-14 {
  top: -1.4rem
}

[dir='rtl'] .rtl\:-right-14,[dir='rtl'].rtl\:-right-14 {
  right: -1.4rem
}

[dir='rtl'] .rtl\:-bottom-14,[dir='rtl'].rtl\:-bottom-14 {
  bottom: -1.4rem
}

[dir='rtl'] .rtl\:-left-14,[dir='rtl'].rtl\:-left-14 {
  left: -1.4rem
}

[dir='rtl'] .rtl\:-top-16,[dir='rtl'].rtl\:-top-16 {
  top: -1.6rem
}

[dir='rtl'] .rtl\:-right-16,[dir='rtl'].rtl\:-right-16 {
  right: -1.6rem
}

[dir='rtl'] .rtl\:-bottom-16,[dir='rtl'].rtl\:-bottom-16 {
  bottom: -1.6rem
}

[dir='rtl'] .rtl\:-left-16,[dir='rtl'].rtl\:-left-16 {
  left: -1.6rem
}

[dir='rtl'] .rtl\:-top-20,[dir='rtl'].rtl\:-top-20 {
  top: -2rem
}

[dir='rtl'] .rtl\:-right-20,[dir='rtl'].rtl\:-right-20 {
  right: -2rem
}

[dir='rtl'] .rtl\:-bottom-20,[dir='rtl'].rtl\:-bottom-20 {
  bottom: -2rem
}

[dir='rtl'] .rtl\:-left-20,[dir='rtl'].rtl\:-left-20 {
  left: -2rem
}

[dir='rtl'] .rtl\:-top-24,[dir='rtl'].rtl\:-top-24 {
  top: -2.4rem
}

[dir='rtl'] .rtl\:-right-24,[dir='rtl'].rtl\:-right-24 {
  right: -2.4rem
}

[dir='rtl'] .rtl\:-bottom-24,[dir='rtl'].rtl\:-bottom-24 {
  bottom: -2.4rem
}

[dir='rtl'] .rtl\:-left-24,[dir='rtl'].rtl\:-left-24 {
  left: -2.4rem
}

[dir='rtl'] .rtl\:-top-28,[dir='rtl'].rtl\:-top-28 {
  top: -2.8rem
}

[dir='rtl'] .rtl\:-right-28,[dir='rtl'].rtl\:-right-28 {
  right: -2.8rem
}

[dir='rtl'] .rtl\:-bottom-28,[dir='rtl'].rtl\:-bottom-28 {
  bottom: -2.8rem
}

[dir='rtl'] .rtl\:-left-28,[dir='rtl'].rtl\:-left-28 {
  left: -2.8rem
}

[dir='rtl'] .rtl\:-top-32,[dir='rtl'].rtl\:-top-32 {
  top: -3.2rem
}

[dir='rtl'] .rtl\:-right-32,[dir='rtl'].rtl\:-right-32 {
  right: -3.2rem
}

[dir='rtl'] .rtl\:-bottom-32,[dir='rtl'].rtl\:-bottom-32 {
  bottom: -3.2rem
}

[dir='rtl'] .rtl\:-left-32,[dir='rtl'].rtl\:-left-32 {
  left: -3.2rem
}

[dir='rtl'] .rtl\:-top-36,[dir='rtl'].rtl\:-top-36 {
  top: -3.6rem
}

[dir='rtl'] .rtl\:-right-36,[dir='rtl'].rtl\:-right-36 {
  right: -3.6rem
}

[dir='rtl'] .rtl\:-bottom-36,[dir='rtl'].rtl\:-bottom-36 {
  bottom: -3.6rem
}

[dir='rtl'] .rtl\:-left-36,[dir='rtl'].rtl\:-left-36 {
  left: -3.6rem
}

[dir='rtl'] .rtl\:-top-40,[dir='rtl'].rtl\:-top-40 {
  top: -4rem
}

[dir='rtl'] .rtl\:-right-40,[dir='rtl'].rtl\:-right-40 {
  right: -4rem
}

[dir='rtl'] .rtl\:-bottom-40,[dir='rtl'].rtl\:-bottom-40 {
  bottom: -4rem
}

[dir='rtl'] .rtl\:-left-40,[dir='rtl'].rtl\:-left-40 {
  left: -4rem
}

[dir='rtl'] .rtl\:-top-44,[dir='rtl'].rtl\:-top-44 {
  top: -4.4rem
}

[dir='rtl'] .rtl\:-right-44,[dir='rtl'].rtl\:-right-44 {
  right: -4.4rem
}

[dir='rtl'] .rtl\:-bottom-44,[dir='rtl'].rtl\:-bottom-44 {
  bottom: -4.4rem
}

[dir='rtl'] .rtl\:-left-44,[dir='rtl'].rtl\:-left-44 {
  left: -4.4rem
}

[dir='rtl'] .rtl\:-top-48,[dir='rtl'].rtl\:-top-48 {
  top: -4.8rem
}

[dir='rtl'] .rtl\:-right-48,[dir='rtl'].rtl\:-right-48 {
  right: -4.8rem
}

[dir='rtl'] .rtl\:-bottom-48,[dir='rtl'].rtl\:-bottom-48 {
  bottom: -4.8rem
}

[dir='rtl'] .rtl\:-left-48,[dir='rtl'].rtl\:-left-48 {
  left: -4.8rem
}

[dir='rtl'] .rtl\:-top-52,[dir='rtl'].rtl\:-top-52 {
  top: -5.2rem
}

[dir='rtl'] .rtl\:-right-52,[dir='rtl'].rtl\:-right-52 {
  right: -5.2rem
}

[dir='rtl'] .rtl\:-bottom-52,[dir='rtl'].rtl\:-bottom-52 {
  bottom: -5.2rem
}

[dir='rtl'] .rtl\:-left-52,[dir='rtl'].rtl\:-left-52 {
  left: -5.2rem
}

[dir='rtl'] .rtl\:-top-56,[dir='rtl'].rtl\:-top-56 {
  top: -5.6rem
}

[dir='rtl'] .rtl\:-right-56,[dir='rtl'].rtl\:-right-56 {
  right: -5.6rem
}

[dir='rtl'] .rtl\:-bottom-56,[dir='rtl'].rtl\:-bottom-56 {
  bottom: -5.6rem
}

[dir='rtl'] .rtl\:-left-56,[dir='rtl'].rtl\:-left-56 {
  left: -5.6rem
}

[dir='rtl'] .rtl\:-top-60,[dir='rtl'].rtl\:-top-60 {
  top: -6rem
}

[dir='rtl'] .rtl\:-right-60,[dir='rtl'].rtl\:-right-60 {
  right: -6rem
}

[dir='rtl'] .rtl\:-bottom-60,[dir='rtl'].rtl\:-bottom-60 {
  bottom: -6rem
}

[dir='rtl'] .rtl\:-left-60,[dir='rtl'].rtl\:-left-60 {
  left: -6rem
}

[dir='rtl'] .rtl\:-top-64,[dir='rtl'].rtl\:-top-64 {
  top: -6.4rem
}

[dir='rtl'] .rtl\:-right-64,[dir='rtl'].rtl\:-right-64 {
  right: -6.4rem
}

[dir='rtl'] .rtl\:-bottom-64,[dir='rtl'].rtl\:-bottom-64 {
  bottom: -6.4rem
}

[dir='rtl'] .rtl\:-left-64,[dir='rtl'].rtl\:-left-64 {
  left: -6.4rem
}

[dir='rtl'] .rtl\:-top-68,[dir='rtl'].rtl\:-top-68 {
  top: -6.8rem
}

[dir='rtl'] .rtl\:-right-68,[dir='rtl'].rtl\:-right-68 {
  right: -6.8rem
}

[dir='rtl'] .rtl\:-bottom-68,[dir='rtl'].rtl\:-bottom-68 {
  bottom: -6.8rem
}

[dir='rtl'] .rtl\:-left-68,[dir='rtl'].rtl\:-left-68 {
  left: -6.8rem
}

[dir='rtl'] .rtl\:-top-72,[dir='rtl'].rtl\:-top-72 {
  top: -7.2rem
}

[dir='rtl'] .rtl\:-right-72,[dir='rtl'].rtl\:-right-72 {
  right: -7.2rem
}

[dir='rtl'] .rtl\:-bottom-72,[dir='rtl'].rtl\:-bottom-72 {
  bottom: -7.2rem
}

[dir='rtl'] .rtl\:-left-72,[dir='rtl'].rtl\:-left-72 {
  left: -7.2rem
}

[dir='rtl'] .rtl\:-top-76,[dir='rtl'].rtl\:-top-76 {
  top: -7.6rem
}

[dir='rtl'] .rtl\:-right-76,[dir='rtl'].rtl\:-right-76 {
  right: -7.6rem
}

[dir='rtl'] .rtl\:-bottom-76,[dir='rtl'].rtl\:-bottom-76 {
  bottom: -7.6rem
}

[dir='rtl'] .rtl\:-left-76,[dir='rtl'].rtl\:-left-76 {
  left: -7.6rem
}

[dir='rtl'] .rtl\:-top-80,[dir='rtl'].rtl\:-top-80 {
  top: -8rem
}

[dir='rtl'] .rtl\:-right-80,[dir='rtl'].rtl\:-right-80 {
  right: -8rem
}

[dir='rtl'] .rtl\:-bottom-80,[dir='rtl'].rtl\:-bottom-80 {
  bottom: -8rem
}

[dir='rtl'] .rtl\:-left-80,[dir='rtl'].rtl\:-left-80 {
  left: -8rem
}

[dir='rtl'] .rtl\:-top-84,[dir='rtl'].rtl\:-top-84 {
  top: -8.4rem
}

[dir='rtl'] .rtl\:-right-84,[dir='rtl'].rtl\:-right-84 {
  right: -8.4rem
}

[dir='rtl'] .rtl\:-bottom-84,[dir='rtl'].rtl\:-bottom-84 {
  bottom: -8.4rem
}

[dir='rtl'] .rtl\:-left-84,[dir='rtl'].rtl\:-left-84 {
  left: -8.4rem
}

[dir='rtl'] .rtl\:-top-88,[dir='rtl'].rtl\:-top-88 {
  top: -8.8rem
}

[dir='rtl'] .rtl\:-right-88,[dir='rtl'].rtl\:-right-88 {
  right: -8.8rem
}

[dir='rtl'] .rtl\:-bottom-88,[dir='rtl'].rtl\:-bottom-88 {
  bottom: -8.8rem
}

[dir='rtl'] .rtl\:-left-88,[dir='rtl'].rtl\:-left-88 {
  left: -8.8rem
}

[dir='rtl'] .rtl\:-top-92,[dir='rtl'].rtl\:-top-92 {
  top: -9.2rem
}

[dir='rtl'] .rtl\:-right-92,[dir='rtl'].rtl\:-right-92 {
  right: -9.2rem
}

[dir='rtl'] .rtl\:-bottom-92,[dir='rtl'].rtl\:-bottom-92 {
  bottom: -9.2rem
}

[dir='rtl'] .rtl\:-left-92,[dir='rtl'].rtl\:-left-92 {
  left: -9.2rem
}

[dir='rtl'] .rtl\:-top-96,[dir='rtl'].rtl\:-top-96 {
  top: -9.6rem
}

[dir='rtl'] .rtl\:-right-96,[dir='rtl'].rtl\:-right-96 {
  right: -9.6rem
}

[dir='rtl'] .rtl\:-bottom-96,[dir='rtl'].rtl\:-bottom-96 {
  bottom: -9.6rem
}

[dir='rtl'] .rtl\:-left-96,[dir='rtl'].rtl\:-left-96 {
  left: -9.6rem
}

[dir='rtl'] .rtl\:-top-128,[dir='rtl'].rtl\:-top-128 {
  top: -12.8rem
}

[dir='rtl'] .rtl\:-right-128,[dir='rtl'].rtl\:-right-128 {
  right: -12.8rem
}

[dir='rtl'] .rtl\:-bottom-128,[dir='rtl'].rtl\:-bottom-128 {
  bottom: -12.8rem
}

[dir='rtl'] .rtl\:-left-128,[dir='rtl'].rtl\:-left-128 {
  left: -12.8rem
}

[dir='rtl'] .rtl\:-top-136,[dir='rtl'].rtl\:-top-136 {
  top: -13.6rem
}

[dir='rtl'] .rtl\:-right-136,[dir='rtl'].rtl\:-right-136 {
  right: -13.6rem
}

[dir='rtl'] .rtl\:-bottom-136,[dir='rtl'].rtl\:-bottom-136 {
  bottom: -13.6rem
}

[dir='rtl'] .rtl\:-left-136,[dir='rtl'].rtl\:-left-136 {
  left: -13.6rem
}

[dir='rtl'] .rtl\:-top-160,[dir='rtl'].rtl\:-top-160 {
  top: -16rem
}

[dir='rtl'] .rtl\:-right-160,[dir='rtl'].rtl\:-right-160 {
  right: -16rem
}

[dir='rtl'] .rtl\:-bottom-160,[dir='rtl'].rtl\:-bottom-160 {
  bottom: -16rem
}

[dir='rtl'] .rtl\:-left-160,[dir='rtl'].rtl\:-left-160 {
  left: -16rem
}

[dir='rtl'] .rtl\:-top-192,[dir='rtl'].rtl\:-top-192 {
  top: -19.2rem
}

[dir='rtl'] .rtl\:-right-192,[dir='rtl'].rtl\:-right-192 {
  right: -19.2rem
}

[dir='rtl'] .rtl\:-bottom-192,[dir='rtl'].rtl\:-bottom-192 {
  bottom: -19.2rem
}

[dir='rtl'] .rtl\:-left-192,[dir='rtl'].rtl\:-left-192 {
  left: -19.2rem
}

[dir='rtl'] .rtl\:-top-200,[dir='rtl'].rtl\:-top-200 {
  top: -20rem
}

[dir='rtl'] .rtl\:-right-200,[dir='rtl'].rtl\:-right-200 {
  right: -20rem
}

[dir='rtl'] .rtl\:-bottom-200,[dir='rtl'].rtl\:-bottom-200 {
  bottom: -20rem
}

[dir='rtl'] .rtl\:-left-200,[dir='rtl'].rtl\:-left-200 {
  left: -20rem
}

[dir='rtl'] .rtl\:-top-208,[dir='rtl'].rtl\:-top-208 {
  top: -20.8rem
}

[dir='rtl'] .rtl\:-right-208,[dir='rtl'].rtl\:-right-208 {
  right: -20.8rem
}

[dir='rtl'] .rtl\:-bottom-208,[dir='rtl'].rtl\:-bottom-208 {
  bottom: -20.8rem
}

[dir='rtl'] .rtl\:-left-208,[dir='rtl'].rtl\:-left-208 {
  left: -20.8rem
}

[dir='rtl'] .rtl\:-top-216,[dir='rtl'].rtl\:-top-216 {
  top: -21.6rem
}

[dir='rtl'] .rtl\:-right-216,[dir='rtl'].rtl\:-right-216 {
  right: -21.6rem
}

[dir='rtl'] .rtl\:-bottom-216,[dir='rtl'].rtl\:-bottom-216 {
  bottom: -21.6rem
}

[dir='rtl'] .rtl\:-left-216,[dir='rtl'].rtl\:-left-216 {
  left: -21.6rem
}

[dir='rtl'] .rtl\:-top-224,[dir='rtl'].rtl\:-top-224 {
  top: -22.4rem
}

[dir='rtl'] .rtl\:-right-224,[dir='rtl'].rtl\:-right-224 {
  right: -22.4rem
}

[dir='rtl'] .rtl\:-bottom-224,[dir='rtl'].rtl\:-bottom-224 {
  bottom: -22.4rem
}

[dir='rtl'] .rtl\:-left-224,[dir='rtl'].rtl\:-left-224 {
  left: -22.4rem
}

[dir='rtl'] .rtl\:-top-256,[dir='rtl'].rtl\:-top-256 {
  top: -25.6rem
}

[dir='rtl'] .rtl\:-right-256,[dir='rtl'].rtl\:-right-256 {
  right: -25.6rem
}

[dir='rtl'] .rtl\:-bottom-256,[dir='rtl'].rtl\:-bottom-256 {
  bottom: -25.6rem
}

[dir='rtl'] .rtl\:-left-256,[dir='rtl'].rtl\:-left-256 {
  left: -25.6rem
}

[dir='rtl'] .rtl\:-top-288,[dir='rtl'].rtl\:-top-288 {
  top: -28.8rem
}

[dir='rtl'] .rtl\:-right-288,[dir='rtl'].rtl\:-right-288 {
  right: -28.8rem
}

[dir='rtl'] .rtl\:-bottom-288,[dir='rtl'].rtl\:-bottom-288 {
  bottom: -28.8rem
}

[dir='rtl'] .rtl\:-left-288,[dir='rtl'].rtl\:-left-288 {
  left: -28.8rem
}

[dir='rtl'] .rtl\:-top-320,[dir='rtl'].rtl\:-top-320 {
  top: -32rem
}

[dir='rtl'] .rtl\:-right-320,[dir='rtl'].rtl\:-right-320 {
  right: -32rem
}

[dir='rtl'] .rtl\:-bottom-320,[dir='rtl'].rtl\:-bottom-320 {
  bottom: -32rem
}

[dir='rtl'] .rtl\:-left-320,[dir='rtl'].rtl\:-left-320 {
  left: -32rem
}

[dir='rtl'] .rtl\:-top-360,[dir='rtl'].rtl\:-top-360 {
  top: -36rem
}

[dir='rtl'] .rtl\:-right-360,[dir='rtl'].rtl\:-right-360 {
  right: -36rem
}

[dir='rtl'] .rtl\:-bottom-360,[dir='rtl'].rtl\:-bottom-360 {
  bottom: -36rem
}

[dir='rtl'] .rtl\:-left-360,[dir='rtl'].rtl\:-left-360 {
  left: -36rem
}

[dir='rtl'] .rtl\:-top-384,[dir='rtl'].rtl\:-top-384 {
  top: -38.4rem
}

[dir='rtl'] .rtl\:-right-384,[dir='rtl'].rtl\:-right-384 {
  right: -38.4rem
}

[dir='rtl'] .rtl\:-bottom-384,[dir='rtl'].rtl\:-bottom-384 {
  bottom: -38.4rem
}

[dir='rtl'] .rtl\:-left-384,[dir='rtl'].rtl\:-left-384 {
  left: -38.4rem
}

[dir='rtl'] .rtl\:-top-400,[dir='rtl'].rtl\:-top-400 {
  top: -40rem
}

[dir='rtl'] .rtl\:-right-400,[dir='rtl'].rtl\:-right-400 {
  right: -40rem
}

[dir='rtl'] .rtl\:-bottom-400,[dir='rtl'].rtl\:-bottom-400 {
  bottom: -40rem
}

[dir='rtl'] .rtl\:-left-400,[dir='rtl'].rtl\:-left-400 {
  left: -40rem
}

[dir='rtl'] .rtl\:-top-512,[dir='rtl'].rtl\:-top-512 {
  top: -51.2rem
}

[dir='rtl'] .rtl\:-right-512,[dir='rtl'].rtl\:-right-512 {
  right: -51.2rem
}

[dir='rtl'] .rtl\:-bottom-512,[dir='rtl'].rtl\:-bottom-512 {
  bottom: -51.2rem
}

[dir='rtl'] .rtl\:-left-512,[dir='rtl'].rtl\:-left-512 {
  left: -51.2rem
}

[dir='rtl'] .rtl\:-top-640,[dir='rtl'].rtl\:-top-640 {
  top: -64rem
}

[dir='rtl'] .rtl\:-right-640,[dir='rtl'].rtl\:-right-640 {
  right: -64rem
}

[dir='rtl'] .rtl\:-bottom-640,[dir='rtl'].rtl\:-bottom-640 {
  bottom: -64rem
}

[dir='rtl'] .rtl\:-left-640,[dir='rtl'].rtl\:-left-640 {
  left: -64rem
}

[dir='rtl'] .rtl\:-top-xs,[dir='rtl'].rtl\:-top-xs {
  top: -32rem
}

[dir='rtl'] .rtl\:-right-xs,[dir='rtl'].rtl\:-right-xs {
  right: -32rem
}

[dir='rtl'] .rtl\:-bottom-xs,[dir='rtl'].rtl\:-bottom-xs {
  bottom: -32rem
}

[dir='rtl'] .rtl\:-left-xs,[dir='rtl'].rtl\:-left-xs {
  left: -32rem
}

[dir='rtl'] .rtl\:-top-sm,[dir='rtl'].rtl\:-top-sm {
  top: -48rem
}

[dir='rtl'] .rtl\:-right-sm,[dir='rtl'].rtl\:-right-sm {
  right: -48rem
}

[dir='rtl'] .rtl\:-bottom-sm,[dir='rtl'].rtl\:-bottom-sm {
  bottom: -48rem
}

[dir='rtl'] .rtl\:-left-sm,[dir='rtl'].rtl\:-left-sm {
  left: -48rem
}

[dir='rtl'] .rtl\:-top-md,[dir='rtl'].rtl\:-top-md {
  top: -64rem
}

[dir='rtl'] .rtl\:-right-md,[dir='rtl'].rtl\:-right-md {
  right: -64rem
}

[dir='rtl'] .rtl\:-bottom-md,[dir='rtl'].rtl\:-bottom-md {
  bottom: -64rem
}

[dir='rtl'] .rtl\:-left-md,[dir='rtl'].rtl\:-left-md {
  left: -64rem
}

[dir='rtl'] .rtl\:-top-lg,[dir='rtl'].rtl\:-top-lg {
  top: -80rem
}

[dir='rtl'] .rtl\:-right-lg,[dir='rtl'].rtl\:-right-lg {
  right: -80rem
}

[dir='rtl'] .rtl\:-bottom-lg,[dir='rtl'].rtl\:-bottom-lg {
  bottom: -80rem
}

[dir='rtl'] .rtl\:-left-lg,[dir='rtl'].rtl\:-left-lg {
  left: -80rem
}

[dir='rtl'] .rtl\:-top-xl,[dir='rtl'].rtl\:-top-xl {
  top: -96rem
}

[dir='rtl'] .rtl\:-right-xl,[dir='rtl'].rtl\:-right-xl {
  right: -96rem
}

[dir='rtl'] .rtl\:-bottom-xl,[dir='rtl'].rtl\:-bottom-xl {
  bottom: -96rem
}

[dir='rtl'] .rtl\:-left-xl,[dir='rtl'].rtl\:-left-xl {
  left: -96rem
}

[dir='rtl'] .rtl\:-top-2xl,[dir='rtl'].rtl\:-top-2xl {
  top: -112rem
}

[dir='rtl'] .rtl\:-right-2xl,[dir='rtl'].rtl\:-right-2xl {
  right: -112rem
}

[dir='rtl'] .rtl\:-bottom-2xl,[dir='rtl'].rtl\:-bottom-2xl {
  bottom: -112rem
}

[dir='rtl'] .rtl\:-left-2xl,[dir='rtl'].rtl\:-left-2xl {
  left: -112rem
}

[dir='rtl'] .rtl\:-top-3xl,[dir='rtl'].rtl\:-top-3xl {
  top: -128rem
}

[dir='rtl'] .rtl\:-right-3xl,[dir='rtl'].rtl\:-right-3xl {
  right: -128rem
}

[dir='rtl'] .rtl\:-bottom-3xl,[dir='rtl'].rtl\:-bottom-3xl {
  bottom: -128rem
}

[dir='rtl'] .rtl\:-left-3xl,[dir='rtl'].rtl\:-left-3xl {
  left: -128rem
}

[dir='rtl'] .rtl\:-top-4xl,[dir='rtl'].rtl\:-top-4xl {
  top: -144rem
}

[dir='rtl'] .rtl\:-right-4xl,[dir='rtl'].rtl\:-right-4xl {
  right: -144rem
}

[dir='rtl'] .rtl\:-bottom-4xl,[dir='rtl'].rtl\:-bottom-4xl {
  bottom: -144rem
}

[dir='rtl'] .rtl\:-left-4xl,[dir='rtl'].rtl\:-left-4xl {
  left: -144rem
}

[dir='rtl'] .rtl\:-top-5xl,[dir='rtl'].rtl\:-top-5xl {
  top: -160rem
}

[dir='rtl'] .rtl\:-right-5xl,[dir='rtl'].rtl\:-right-5xl {
  right: -160rem
}

[dir='rtl'] .rtl\:-bottom-5xl,[dir='rtl'].rtl\:-bottom-5xl {
  bottom: -160rem
}

[dir='rtl'] .rtl\:-left-5xl,[dir='rtl'].rtl\:-left-5xl {
  left: -160rem
}

[dir='rtl'] .rtl\:-top-px,[dir='rtl'].rtl\:-top-px {
  top: -1px
}

[dir='rtl'] .rtl\:-right-px,[dir='rtl'].rtl\:-right-px {
  right: -1px
}

[dir='rtl'] .rtl\:-bottom-px,[dir='rtl'].rtl\:-bottom-px {
  bottom: -1px
}

[dir='rtl'] .rtl\:-left-px,[dir='rtl'].rtl\:-left-px {
  left: -1px
}

[dir='rtl'] .rtl\:-top-0\.5,[dir='rtl'].rtl\:-top-0\.5 {
  top: -0.05rem
}

[dir='rtl'] .rtl\:-right-0\.5,[dir='rtl'].rtl\:-right-0\.5 {
  right: -0.05rem
}

[dir='rtl'] .rtl\:-bottom-0\.5,[dir='rtl'].rtl\:-bottom-0\.5 {
  bottom: -0.05rem
}

[dir='rtl'] .rtl\:-left-0\.5,[dir='rtl'].rtl\:-left-0\.5 {
  left: -0.05rem
}

[dir='rtl'] .rtl\:-top-1\.5,[dir='rtl'].rtl\:-top-1\.5 {
  top: -0.15rem
}

[dir='rtl'] .rtl\:-right-1\.5,[dir='rtl'].rtl\:-right-1\.5 {
  right: -0.15rem
}

[dir='rtl'] .rtl\:-bottom-1\.5,[dir='rtl'].rtl\:-bottom-1\.5 {
  bottom: -0.15rem
}

[dir='rtl'] .rtl\:-left-1\.5,[dir='rtl'].rtl\:-left-1\.5 {
  left: -0.15rem
}

[dir='rtl'] .rtl\:-top-2\.5,[dir='rtl'].rtl\:-top-2\.5 {
  top: -0.25rem
}

[dir='rtl'] .rtl\:-right-2\.5,[dir='rtl'].rtl\:-right-2\.5 {
  right: -0.25rem
}

[dir='rtl'] .rtl\:-bottom-2\.5,[dir='rtl'].rtl\:-bottom-2\.5 {
  bottom: -0.25rem
}

[dir='rtl'] .rtl\:-left-2\.5,[dir='rtl'].rtl\:-left-2\.5 {
  left: -0.25rem
}

[dir='rtl'] .rtl\:-top-3\.5,[dir='rtl'].rtl\:-top-3\.5 {
  top: -0.35rem
}

[dir='rtl'] .rtl\:-right-3\.5,[dir='rtl'].rtl\:-right-3\.5 {
  right: -0.35rem
}

[dir='rtl'] .rtl\:-bottom-3\.5,[dir='rtl'].rtl\:-bottom-3\.5 {
  bottom: -0.35rem
}

[dir='rtl'] .rtl\:-left-3\.5,[dir='rtl'].rtl\:-left-3\.5 {
  left: -0.35rem
}

[dir='rtl'] .rtl\:top-1\/2,[dir='rtl'].rtl\:top-1\/2 {
  top: 50%
}

[dir='rtl'] .rtl\:right-1\/2,[dir='rtl'].rtl\:right-1\/2 {
  right: 50%
}

[dir='rtl'] .rtl\:bottom-1\/2,[dir='rtl'].rtl\:bottom-1\/2 {
  bottom: 50%
}

[dir='rtl'] .rtl\:left-1\/2,[dir='rtl'].rtl\:left-1\/2 {
  left: 50%
}

[dir='rtl'] .rtl\:top-1\/3,[dir='rtl'].rtl\:top-1\/3 {
  top: 33.333333%
}

[dir='rtl'] .rtl\:right-1\/3,[dir='rtl'].rtl\:right-1\/3 {
  right: 33.333333%
}

[dir='rtl'] .rtl\:bottom-1\/3,[dir='rtl'].rtl\:bottom-1\/3 {
  bottom: 33.333333%
}

[dir='rtl'] .rtl\:left-1\/3,[dir='rtl'].rtl\:left-1\/3 {
  left: 33.333333%
}

[dir='rtl'] .rtl\:top-2\/3,[dir='rtl'].rtl\:top-2\/3 {
  top: 66.666667%
}

[dir='rtl'] .rtl\:right-2\/3,[dir='rtl'].rtl\:right-2\/3 {
  right: 66.666667%
}

[dir='rtl'] .rtl\:bottom-2\/3,[dir='rtl'].rtl\:bottom-2\/3 {
  bottom: 66.666667%
}

[dir='rtl'] .rtl\:left-2\/3,[dir='rtl'].rtl\:left-2\/3 {
  left: 66.666667%
}

[dir='rtl'] .rtl\:top-1\/4,[dir='rtl'].rtl\:top-1\/4 {
  top: 25%
}

[dir='rtl'] .rtl\:right-1\/4,[dir='rtl'].rtl\:right-1\/4 {
  right: 25%
}

[dir='rtl'] .rtl\:bottom-1\/4,[dir='rtl'].rtl\:bottom-1\/4 {
  bottom: 25%
}

[dir='rtl'] .rtl\:left-1\/4,[dir='rtl'].rtl\:left-1\/4 {
  left: 25%
}

[dir='rtl'] .rtl\:top-2\/4,[dir='rtl'].rtl\:top-2\/4 {
  top: 50%
}

[dir='rtl'] .rtl\:right-2\/4,[dir='rtl'].rtl\:right-2\/4 {
  right: 50%
}

[dir='rtl'] .rtl\:bottom-2\/4,[dir='rtl'].rtl\:bottom-2\/4 {
  bottom: 50%
}

[dir='rtl'] .rtl\:left-2\/4,[dir='rtl'].rtl\:left-2\/4 {
  left: 50%
}

[dir='rtl'] .rtl\:top-3\/4,[dir='rtl'].rtl\:top-3\/4 {
  top: 75%
}

[dir='rtl'] .rtl\:right-3\/4,[dir='rtl'].rtl\:right-3\/4 {
  right: 75%
}

[dir='rtl'] .rtl\:bottom-3\/4,[dir='rtl'].rtl\:bottom-3\/4 {
  bottom: 75%
}

[dir='rtl'] .rtl\:left-3\/4,[dir='rtl'].rtl\:left-3\/4 {
  left: 75%
}

[dir='rtl'] .rtl\:top-full,[dir='rtl'].rtl\:top-full {
  top: 100%
}

[dir='rtl'] .rtl\:right-full,[dir='rtl'].rtl\:right-full {
  right: 100%
}

[dir='rtl'] .rtl\:bottom-full,[dir='rtl'].rtl\:bottom-full {
  bottom: 100%
}

[dir='rtl'] .rtl\:left-full,[dir='rtl'].rtl\:left-full {
  left: 100%
}

[dir='rtl'] .rtl\:-top-1\/2,[dir='rtl'].rtl\:-top-1\/2 {
  top: -50%
}

[dir='rtl'] .rtl\:-right-1\/2,[dir='rtl'].rtl\:-right-1\/2 {
  right: -50%
}

[dir='rtl'] .rtl\:-bottom-1\/2,[dir='rtl'].rtl\:-bottom-1\/2 {
  bottom: -50%
}

[dir='rtl'] .rtl\:-left-1\/2,[dir='rtl'].rtl\:-left-1\/2 {
  left: -50%
}

[dir='rtl'] .rtl\:-top-1\/3,[dir='rtl'].rtl\:-top-1\/3 {
  top: -33.333333%
}

[dir='rtl'] .rtl\:-right-1\/3,[dir='rtl'].rtl\:-right-1\/3 {
  right: -33.333333%
}

[dir='rtl'] .rtl\:-bottom-1\/3,[dir='rtl'].rtl\:-bottom-1\/3 {
  bottom: -33.333333%
}

[dir='rtl'] .rtl\:-left-1\/3,[dir='rtl'].rtl\:-left-1\/3 {
  left: -33.333333%
}

[dir='rtl'] .rtl\:-top-2\/3,[dir='rtl'].rtl\:-top-2\/3 {
  top: -66.666667%
}

[dir='rtl'] .rtl\:-right-2\/3,[dir='rtl'].rtl\:-right-2\/3 {
  right: -66.666667%
}

[dir='rtl'] .rtl\:-bottom-2\/3,[dir='rtl'].rtl\:-bottom-2\/3 {
  bottom: -66.666667%
}

[dir='rtl'] .rtl\:-left-2\/3,[dir='rtl'].rtl\:-left-2\/3 {
  left: -66.666667%
}

[dir='rtl'] .rtl\:-top-1\/4,[dir='rtl'].rtl\:-top-1\/4 {
  top: -25%
}

[dir='rtl'] .rtl\:-right-1\/4,[dir='rtl'].rtl\:-right-1\/4 {
  right: -25%
}

[dir='rtl'] .rtl\:-bottom-1\/4,[dir='rtl'].rtl\:-bottom-1\/4 {
  bottom: -25%
}

[dir='rtl'] .rtl\:-left-1\/4,[dir='rtl'].rtl\:-left-1\/4 {
  left: -25%
}

[dir='rtl'] .rtl\:-top-2\/4,[dir='rtl'].rtl\:-top-2\/4 {
  top: -50%
}

[dir='rtl'] .rtl\:-right-2\/4,[dir='rtl'].rtl\:-right-2\/4 {
  right: -50%
}

[dir='rtl'] .rtl\:-bottom-2\/4,[dir='rtl'].rtl\:-bottom-2\/4 {
  bottom: -50%
}

[dir='rtl'] .rtl\:-left-2\/4,[dir='rtl'].rtl\:-left-2\/4 {
  left: -50%
}

[dir='rtl'] .rtl\:-top-3\/4,[dir='rtl'].rtl\:-top-3\/4 {
  top: -75%
}

[dir='rtl'] .rtl\:-right-3\/4,[dir='rtl'].rtl\:-right-3\/4 {
  right: -75%
}

[dir='rtl'] .rtl\:-bottom-3\/4,[dir='rtl'].rtl\:-bottom-3\/4 {
  bottom: -75%
}

[dir='rtl'] .rtl\:-left-3\/4,[dir='rtl'].rtl\:-left-3\/4 {
  left: -75%
}

[dir='rtl'] .rtl\:-top-full,[dir='rtl'].rtl\:-top-full {
  top: -100%
}

[dir='rtl'] .rtl\:-right-full,[dir='rtl'].rtl\:-right-full {
  right: -100%
}

[dir='rtl'] .rtl\:-bottom-full,[dir='rtl'].rtl\:-bottom-full {
  bottom: -100%
}

[dir='rtl'] .rtl\:-left-full,[dir='rtl'].rtl\:-left-full {
  left: -100%
}

.resize-none {
  resize: none
}

.resize-y {
  resize: vertical
}

.resize-x {
  resize: horizontal
}

.resize {
  resize: both
}

* {
  --tw-shadow: 0 0 #0000
}

.shadow-0 {
  --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-1 {
  --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-2 {
  --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-3 {
  --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-4 {
  --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-5 {
  --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-6 {
  --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-7 {
  --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-8 {
  --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-9 {
  --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-10 {
  --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-11 {
  --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-12 {
  --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-13 {
  --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-14 {
  --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-15 {
  --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-16 {
  --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-17 {
  --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-18 {
  --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-19 {
  --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-20 {
  --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-21 {
  --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-22 {
  --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-23 {
  --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-24 {
  --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-0 {
  --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-1 {
  --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-2 {
  --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-3 {
  --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-4 {
  --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-5 {
  --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-6 {
  --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-7 {
  --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-8 {
  --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-9 {
  --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-10 {
  --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-11 {
  --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-12 {
  --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-13 {
  --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-14 {
  --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-15 {
  --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-16 {
  --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-17 {
  --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-18 {
  --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-19 {
  --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-20 {
  --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-21 {
  --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-22 {
  --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-23 {
  --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-24 {
  --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-0:focus-within {
  --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-1:focus-within {
  --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-2:focus-within {
  --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-3:focus-within {
  --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-4:focus-within {
  --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-5:focus-within {
  --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-6:focus-within {
  --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-7:focus-within {
  --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-8:focus-within {
  --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-9:focus-within {
  --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-10:focus-within {
  --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-11:focus-within {
  --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-12:focus-within {
  --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-13:focus-within {
  --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-14:focus-within {
  --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-15:focus-within {
  --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-16:focus-within {
  --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-17:focus-within {
  --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-18:focus-within {
  --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-19:focus-within {
  --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-20:focus-within {
  --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-21:focus-within {
  --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-22:focus-within {
  --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-23:focus-within {
  --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-24:focus-within {
  --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-sm:focus-within {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow:focus-within {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-md:focus-within {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-lg:focus-within {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-xl:focus-within {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-2xl:focus-within {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-inner:focus-within {
  --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-none:focus-within {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-0:hover {
  --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-1:hover {
  --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-2:hover {
  --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-3:hover {
  --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-4:hover {
  --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-5:hover {
  --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-6:hover {
  --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-7:hover {
  --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-8:hover {
  --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-9:hover {
  --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-10:hover {
  --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-11:hover {
  --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-12:hover {
  --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-13:hover {
  --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-14:hover {
  --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-15:hover {
  --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-16:hover {
  --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-17:hover {
  --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-18:hover {
  --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-19:hover {
  --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-20:hover {
  --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-21:hover {
  --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-22:hover {
  --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-23:hover {
  --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-24:hover {
  --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-sm:hover {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow:hover {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-2xl:hover {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-inner:hover {
  --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-none:hover {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-0:focus {
  --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-1:focus {
  --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-2:focus {
  --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-3:focus {
  --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-4:focus {
  --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-5:focus {
  --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-6:focus {
  --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-7:focus {
  --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-8:focus {
  --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-9:focus {
  --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-10:focus {
  --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-11:focus {
  --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-12:focus {
  --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-13:focus {
  --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-14:focus {
  --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-15:focus {
  --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-16:focus {
  --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-17:focus {
  --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-18:focus {
  --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-19:focus {
  --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-20:focus {
  --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-21:focus {
  --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-22:focus {
  --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-23:focus {
  --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-24:focus {
  --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-sm:focus {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow:focus {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-md:focus {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-lg:focus {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-xl:focus {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-2xl:focus {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-inner:focus {
  --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(33, 150, 243, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-8 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-inset {
  --tw-ring-inset: inset
}

.focus-within\:ring-0:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus-within\:ring-1:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus-within\:ring-4:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus-within\:ring-8:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus-within\:ring:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus-within\:ring-inset:focus-within {
  --tw-ring-inset: inset
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-8:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset
}

.ring-offset-transparent {
  --tw-ring-offset-color: transparent
}

.ring-offset-current {
  --tw-ring-offset-color: currentColor
}

.ring-offset-black {
  --tw-ring-offset-color: #22292F
}

.ring-offset-white {
  --tw-ring-offset-color: #fff
}

.ring-offset-grey-50 {
  --tw-ring-offset-color: #FAFAFA
}

.ring-offset-grey-100 {
  --tw-ring-offset-color: #F5F5F5
}

.ring-offset-grey-200 {
  --tw-ring-offset-color: #EEEEEE
}

.ring-offset-grey-300 {
  --tw-ring-offset-color: #E0E0E0
}

.ring-offset-grey-400 {
  --tw-ring-offset-color: #BDBDBD
}

.ring-offset-grey-500 {
  --tw-ring-offset-color: #9E9E9E
}

.ring-offset-grey-600 {
  --tw-ring-offset-color: #757575
}

.ring-offset-grey-700 {
  --tw-ring-offset-color: #616161
}

.ring-offset-grey-800 {
  --tw-ring-offset-color: #424242
}

.ring-offset-grey-900 {
  --tw-ring-offset-color: #212121
}

.ring-offset-grey {
  --tw-ring-offset-color: #9E9E9E
}

.ring-offset-grey-A100 {
  --tw-ring-offset-color: #D5D5D5
}

.ring-offset-grey-A200 {
  --tw-ring-offset-color: #AAAAAA
}

.ring-offset-grey-A400 {
  --tw-ring-offset-color: #303030
}

.ring-offset-grey-A700 {
  --tw-ring-offset-color: #616161
}

.ring-offset-gray-50 {
  --tw-ring-offset-color: #FAFAFA
}

.ring-offset-gray-100 {
  --tw-ring-offset-color: #F5F5F5
}

.ring-offset-gray-200 {
  --tw-ring-offset-color: #EEEEEE
}

.ring-offset-gray-300 {
  --tw-ring-offset-color: #E0E0E0
}

.ring-offset-gray-400 {
  --tw-ring-offset-color: #BDBDBD
}

.ring-offset-gray-500 {
  --tw-ring-offset-color: #9E9E9E
}

.ring-offset-gray-600 {
  --tw-ring-offset-color: #757575
}

.ring-offset-gray-700 {
  --tw-ring-offset-color: #616161
}

.ring-offset-gray-800 {
  --tw-ring-offset-color: #424242
}

.ring-offset-gray-900 {
  --tw-ring-offset-color: #212121
}

.ring-offset-gray {
  --tw-ring-offset-color: #9E9E9E
}

.ring-offset-gray-hover {
  --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
}

.ring-offset-gray-A100 {
  --tw-ring-offset-color: #D5D5D5
}

.ring-offset-gray-A200 {
  --tw-ring-offset-color: #AAAAAA
}

.ring-offset-gray-A400 {
  --tw-ring-offset-color: #303030
}

.ring-offset-gray-A700 {
  --tw-ring-offset-color: #616161
}

.ring-offset-red-50 {
  --tw-ring-offset-color: #FFEBEE
}

.ring-offset-red-100 {
  --tw-ring-offset-color: #FFCDD2
}

.ring-offset-red-200 {
  --tw-ring-offset-color: #EF9A9A
}

.ring-offset-red-300 {
  --tw-ring-offset-color: #E57373
}

.ring-offset-red-400 {
  --tw-ring-offset-color: #EF5350
}

.ring-offset-red-500 {
  --tw-ring-offset-color: #F44336
}

.ring-offset-red-600 {
  --tw-ring-offset-color: #E53935
}

.ring-offset-red-700 {
  --tw-ring-offset-color: #D32F2F
}

.ring-offset-red-800 {
  --tw-ring-offset-color: #C62828
}

.ring-offset-red-900 {
  --tw-ring-offset-color: #B71C1C
}

.ring-offset-red {
  --tw-ring-offset-color: #F44336
}

.ring-offset-red-A100 {
  --tw-ring-offset-color: #FF8A80
}

.ring-offset-red-A200 {
  --tw-ring-offset-color: #FF5252
}

.ring-offset-red-A400 {
  --tw-ring-offset-color: #FF1744
}

.ring-offset-red-A700 {
  --tw-ring-offset-color: #D50000
}

.ring-offset-orange-50 {
  --tw-ring-offset-color: #FFF3E0
}

.ring-offset-orange-100 {
  --tw-ring-offset-color: #FFE0B2
}

.ring-offset-orange-200 {
  --tw-ring-offset-color: #FFCC80
}

.ring-offset-orange-300 {
  --tw-ring-offset-color: #FFB74D
}

.ring-offset-orange-400 {
  --tw-ring-offset-color: #FFA726
}

.ring-offset-orange-500 {
  --tw-ring-offset-color: #FF9800
}

.ring-offset-orange-600 {
  --tw-ring-offset-color: #FB8C00
}

.ring-offset-orange-700 {
  --tw-ring-offset-color: #F57C00
}

.ring-offset-orange-800 {
  --tw-ring-offset-color: #EF6C00
}

.ring-offset-orange-900 {
  --tw-ring-offset-color: #E65100
}

.ring-offset-orange {
  --tw-ring-offset-color: #FF9800
}

.ring-offset-orange-A100 {
  --tw-ring-offset-color: #FFD180
}

.ring-offset-orange-A200 {
  --tw-ring-offset-color: #FFAB40
}

.ring-offset-orange-A400 {
  --tw-ring-offset-color: #FF9100
}

.ring-offset-orange-A700 {
  --tw-ring-offset-color: #FF6D00
}

.ring-offset-deep-orange-50 {
  --tw-ring-offset-color: #FBE9E7
}

.ring-offset-deep-orange-100 {
  --tw-ring-offset-color: #FFCCBC
}

.ring-offset-deep-orange-200 {
  --tw-ring-offset-color: #FFAB91
}

.ring-offset-deep-orange-300 {
  --tw-ring-offset-color: #FF8A65
}

.ring-offset-deep-orange-400 {
  --tw-ring-offset-color: #FF7043
}

.ring-offset-deep-orange-500 {
  --tw-ring-offset-color: #FF5722
}

.ring-offset-deep-orange-600 {
  --tw-ring-offset-color: #F4511E
}

.ring-offset-deep-orange-700 {
  --tw-ring-offset-color: #E64A19
}

.ring-offset-deep-orange-800 {
  --tw-ring-offset-color: #D84315
}

.ring-offset-deep-orange-900 {
  --tw-ring-offset-color: #BF360C
}

.ring-offset-deep-orange {
  --tw-ring-offset-color: #FF5722
}

.ring-offset-deep-orange-A100 {
  --tw-ring-offset-color: #FF9E80
}

.ring-offset-deep-orange-A200 {
  --tw-ring-offset-color: #FF6E40
}

.ring-offset-deep-orange-A400 {
  --tw-ring-offset-color: #FF3D00
}

.ring-offset-deep-orange-A700 {
  --tw-ring-offset-color: #DD2C00
}

.ring-offset-yellow-50 {
  --tw-ring-offset-color: #FFFDE7
}

.ring-offset-yellow-100 {
  --tw-ring-offset-color: #FFF9C4
}

.ring-offset-yellow-200 {
  --tw-ring-offset-color: #FFF59D
}

.ring-offset-yellow-300 {
  --tw-ring-offset-color: #FFF176
}

.ring-offset-yellow-400 {
  --tw-ring-offset-color: #FFEE58
}

.ring-offset-yellow-500 {
  --tw-ring-offset-color: #FFEB3B
}

.ring-offset-yellow-600 {
  --tw-ring-offset-color: #FDD835
}

.ring-offset-yellow-700 {
  --tw-ring-offset-color: #FBC02D
}

.ring-offset-yellow-800 {
  --tw-ring-offset-color: #F9A825
}

.ring-offset-yellow-900 {
  --tw-ring-offset-color: #F57F17
}

.ring-offset-yellow {
  --tw-ring-offset-color: #FFEB3B
}

.ring-offset-yellow-A100 {
  --tw-ring-offset-color: #FFFF8D
}

.ring-offset-yellow-A200 {
  --tw-ring-offset-color: #FFFF00
}

.ring-offset-yellow-A400 {
  --tw-ring-offset-color: #FFEA00
}

.ring-offset-yellow-A700 {
  --tw-ring-offset-color: #FFD600
}

.ring-offset-green-50 {
  --tw-ring-offset-color: #E8F5E9
}

.ring-offset-green-100 {
  --tw-ring-offset-color: #C8E6C9
}

.ring-offset-green-200 {
  --tw-ring-offset-color: #A5D6A7
}

.ring-offset-green-300 {
  --tw-ring-offset-color: #81C784
}

.ring-offset-green-400 {
  --tw-ring-offset-color: #66BB6A
}

.ring-offset-green-500 {
  --tw-ring-offset-color: #4CAF50
}

.ring-offset-green-600 {
  --tw-ring-offset-color: #43A047
}

.ring-offset-green-700 {
  --tw-ring-offset-color: #388E3C
}

.ring-offset-green-800 {
  --tw-ring-offset-color: #2E7D32
}

.ring-offset-green-900 {
  --tw-ring-offset-color: #1B5E20
}

.ring-offset-green {
  --tw-ring-offset-color: #4CAF50
}

.ring-offset-green-A100 {
  --tw-ring-offset-color: #B9F6CA
}

.ring-offset-green-A200 {
  --tw-ring-offset-color: #69F0AE
}

.ring-offset-green-A400 {
  --tw-ring-offset-color: #00E676
}

.ring-offset-green-A700 {
  --tw-ring-offset-color: #00C853
}

.ring-offset-light-green-50 {
  --tw-ring-offset-color: #F1F8E9
}

.ring-offset-light-green-100 {
  --tw-ring-offset-color: #DCEDC8
}

.ring-offset-light-green-200 {
  --tw-ring-offset-color: #C5E1A5
}

.ring-offset-light-green-300 {
  --tw-ring-offset-color: #AED581
}

.ring-offset-light-green-400 {
  --tw-ring-offset-color: #9CCC65
}

.ring-offset-light-green-500 {
  --tw-ring-offset-color: #8BC34A
}

.ring-offset-light-green-600 {
  --tw-ring-offset-color: #7CB342
}

.ring-offset-light-green-700 {
  --tw-ring-offset-color: #689F38
}

.ring-offset-light-green-800 {
  --tw-ring-offset-color: #558B2F
}

.ring-offset-light-green-900 {
  --tw-ring-offset-color: #33691E
}

.ring-offset-light-green {
  --tw-ring-offset-color: #8BC34A
}

.ring-offset-light-green-A100 {
  --tw-ring-offset-color: #CCFF90
}

.ring-offset-light-green-A200 {
  --tw-ring-offset-color: #B2FF59
}

.ring-offset-light-green-A400 {
  --tw-ring-offset-color: #76FF03
}

.ring-offset-light-green-A700 {
  --tw-ring-offset-color: #64DD17
}

.ring-offset-teal-50 {
  --tw-ring-offset-color: #E0F2F1
}

.ring-offset-teal-100 {
  --tw-ring-offset-color: #B2DFDB
}

.ring-offset-teal-200 {
  --tw-ring-offset-color: #80CBC4
}

.ring-offset-teal-300 {
  --tw-ring-offset-color: #4DB6AC
}

.ring-offset-teal-400 {
  --tw-ring-offset-color: #26A69A
}

.ring-offset-teal-500 {
  --tw-ring-offset-color: #009688
}

.ring-offset-teal-600 {
  --tw-ring-offset-color: #00897B
}

.ring-offset-teal-700 {
  --tw-ring-offset-color: #00796B
}

.ring-offset-teal-800 {
  --tw-ring-offset-color: #00695C
}

.ring-offset-teal-900 {
  --tw-ring-offset-color: #004D40
}

.ring-offset-teal {
  --tw-ring-offset-color: #009688
}

.ring-offset-teal-A100 {
  --tw-ring-offset-color: #A7FFEB
}

.ring-offset-teal-A200 {
  --tw-ring-offset-color: #64FFDA
}

.ring-offset-teal-A400 {
  --tw-ring-offset-color: #1DE9B6
}

.ring-offset-teal-A700 {
  --tw-ring-offset-color: #00BFA5
}

.ring-offset-blue-50 {
  --tw-ring-offset-color: #E3F2FD
}

.ring-offset-blue-100 {
  --tw-ring-offset-color: #BBDEFB
}

.ring-offset-blue-200 {
  --tw-ring-offset-color: #90CAF9
}

.ring-offset-blue-300 {
  --tw-ring-offset-color: #64B5F6
}

.ring-offset-blue-400 {
  --tw-ring-offset-color: #42A5F5
}

.ring-offset-blue-500 {
  --tw-ring-offset-color: #2196F3
}

.ring-offset-blue-600 {
  --tw-ring-offset-color: #1E88E5
}

.ring-offset-blue-700 {
  --tw-ring-offset-color: #1976D2
}

.ring-offset-blue-800 {
  --tw-ring-offset-color: #1565C0
}

.ring-offset-blue-900 {
  --tw-ring-offset-color: #0D47A1
}

.ring-offset-blue {
  --tw-ring-offset-color: #2196F3
}

.ring-offset-blue-A100 {
  --tw-ring-offset-color: #82B1FF
}

.ring-offset-blue-A200 {
  --tw-ring-offset-color: #448AFF
}

.ring-offset-blue-A400 {
  --tw-ring-offset-color: #2979FF
}

.ring-offset-blue-A700 {
  --tw-ring-offset-color: #2962FF
}

.ring-offset-light-blue-50 {
  --tw-ring-offset-color: #E1F5FE
}

.ring-offset-light-blue-100 {
  --tw-ring-offset-color: #B3E5FC
}

.ring-offset-light-blue-200 {
  --tw-ring-offset-color: #81D4FA
}

.ring-offset-light-blue-300 {
  --tw-ring-offset-color: #4FC3F7
}

.ring-offset-light-blue-400 {
  --tw-ring-offset-color: #29B6F6
}

.ring-offset-light-blue-500 {
  --tw-ring-offset-color: #03A9F4
}

.ring-offset-light-blue-600 {
  --tw-ring-offset-color: #039BE5
}

.ring-offset-light-blue-700 {
  --tw-ring-offset-color: #0288D1
}

.ring-offset-light-blue-800 {
  --tw-ring-offset-color: #0277BD
}

.ring-offset-light-blue-900 {
  --tw-ring-offset-color: #01579B
}

.ring-offset-light-blue {
  --tw-ring-offset-color: #03A9F4
}

.ring-offset-light-blue-A100 {
  --tw-ring-offset-color: #80D8FF
}

.ring-offset-light-blue-A200 {
  --tw-ring-offset-color: #40C4FF
}

.ring-offset-light-blue-A400 {
  --tw-ring-offset-color: #00B0FF
}

.ring-offset-light-blue-A700 {
  --tw-ring-offset-color: #0091EA
}

.ring-offset-indigo-50 {
  --tw-ring-offset-color: #E8EAF6
}

.ring-offset-indigo-100 {
  --tw-ring-offset-color: #C5CAE9
}

.ring-offset-indigo-200 {
  --tw-ring-offset-color: #9FA8DA
}

.ring-offset-indigo-300 {
  --tw-ring-offset-color: #7986CB
}

.ring-offset-indigo-400 {
  --tw-ring-offset-color: #5C6BC0
}

.ring-offset-indigo-500 {
  --tw-ring-offset-color: #3F51B5
}

.ring-offset-indigo-600 {
  --tw-ring-offset-color: #3949AB
}

.ring-offset-indigo-700 {
  --tw-ring-offset-color: #303F9F
}

.ring-offset-indigo-800 {
  --tw-ring-offset-color: #283593
}

.ring-offset-indigo-900 {
  --tw-ring-offset-color: #1A237E
}

.ring-offset-indigo {
  --tw-ring-offset-color: #3F51B5
}

.ring-offset-indigo-A100 {
  --tw-ring-offset-color: #8C9EFF
}

.ring-offset-indigo-A200 {
  --tw-ring-offset-color: #536DFE
}

.ring-offset-indigo-A400 {
  --tw-ring-offset-color: #3D5AFE
}

.ring-offset-indigo-A700 {
  --tw-ring-offset-color: #304FFE
}

.ring-offset-purple-50 {
  --tw-ring-offset-color: #F3E5F5
}

.ring-offset-purple-100 {
  --tw-ring-offset-color: #E1BEE7
}

.ring-offset-purple-200 {
  --tw-ring-offset-color: #CE93D8
}

.ring-offset-purple-300 {
  --tw-ring-offset-color: #BA68C8
}

.ring-offset-purple-400 {
  --tw-ring-offset-color: #AB47BC
}

.ring-offset-purple-500 {
  --tw-ring-offset-color: #9C27B0
}

.ring-offset-purple-600 {
  --tw-ring-offset-color: #8E24AA
}

.ring-offset-purple-700 {
  --tw-ring-offset-color: #7B1FA2
}

.ring-offset-purple-800 {
  --tw-ring-offset-color: #6A1B9A
}

.ring-offset-purple-900 {
  --tw-ring-offset-color: #4A148C
}

.ring-offset-purple {
  --tw-ring-offset-color: #9C27B0
}

.ring-offset-purple-A100 {
  --tw-ring-offset-color: #EA80FC
}

.ring-offset-purple-A200 {
  --tw-ring-offset-color: #E040FB
}

.ring-offset-purple-A400 {
  --tw-ring-offset-color: #D500F9
}

.ring-offset-purple-A700 {
  --tw-ring-offset-color: #AA00FF
}

.ring-offset-deep-purple-50 {
  --tw-ring-offset-color: #EDE7F6
}

.ring-offset-deep-purple-100 {
  --tw-ring-offset-color: #D1C4E9
}

.ring-offset-deep-purple-200 {
  --tw-ring-offset-color: #B39DDB
}

.ring-offset-deep-purple-300 {
  --tw-ring-offset-color: #9575CD
}

.ring-offset-deep-purple-400 {
  --tw-ring-offset-color: #7E57C2
}

.ring-offset-deep-purple-500 {
  --tw-ring-offset-color: #673AB7
}

.ring-offset-deep-purple-600 {
  --tw-ring-offset-color: #5E35B1
}

.ring-offset-deep-purple-700 {
  --tw-ring-offset-color: #512DA8
}

.ring-offset-deep-purple-800 {
  --tw-ring-offset-color: #4527A0
}

.ring-offset-deep-purple-900 {
  --tw-ring-offset-color: #311B92
}

.ring-offset-deep-purple {
  --tw-ring-offset-color: #673AB7
}

.ring-offset-deep-purple-A100 {
  --tw-ring-offset-color: #B388FF
}

.ring-offset-deep-purple-A200 {
  --tw-ring-offset-color: #7C4DFF
}

.ring-offset-deep-purple-A400 {
  --tw-ring-offset-color: #651FFF
}

.ring-offset-deep-purple-A700 {
  --tw-ring-offset-color: #6200EA
}

.ring-offset-pink-50 {
  --tw-ring-offset-color: #FCE4EC
}

.ring-offset-pink-100 {
  --tw-ring-offset-color: #F8BBD0
}

.ring-offset-pink-200 {
  --tw-ring-offset-color: #F48FB1
}

.ring-offset-pink-300 {
  --tw-ring-offset-color: #F06292
}

.ring-offset-pink-400 {
  --tw-ring-offset-color: #EC407A
}

.ring-offset-pink-500 {
  --tw-ring-offset-color: #E91E63
}

.ring-offset-pink-600 {
  --tw-ring-offset-color: #D81B60
}

.ring-offset-pink-700 {
  --tw-ring-offset-color: #C2185B
}

.ring-offset-pink-800 {
  --tw-ring-offset-color: #AD1457
}

.ring-offset-pink-900 {
  --tw-ring-offset-color: #880E4F
}

.ring-offset-pink {
  --tw-ring-offset-color: #E91E63
}

.ring-offset-pink-A100 {
  --tw-ring-offset-color: #FF80AB
}

.ring-offset-pink-A200 {
  --tw-ring-offset-color: #FF4081
}

.ring-offset-pink-A400 {
  --tw-ring-offset-color: #F50057
}

.ring-offset-pink-A700 {
  --tw-ring-offset-color: #C51162
}

.ring-offset-lime-50 {
  --tw-ring-offset-color: #F9FBE7
}

.ring-offset-lime-100 {
  --tw-ring-offset-color: #F0F4C3
}

.ring-offset-lime-200 {
  --tw-ring-offset-color: #E6EE9C
}

.ring-offset-lime-300 {
  --tw-ring-offset-color: #DCE775
}

.ring-offset-lime-400 {
  --tw-ring-offset-color: #D4E157
}

.ring-offset-lime-500 {
  --tw-ring-offset-color: #CDDC39
}

.ring-offset-lime-600 {
  --tw-ring-offset-color: #C0CA33
}

.ring-offset-lime-700 {
  --tw-ring-offset-color: #AFB42B
}

.ring-offset-lime-800 {
  --tw-ring-offset-color: #9E9D24
}

.ring-offset-lime-900 {
  --tw-ring-offset-color: #827717
}

.ring-offset-lime {
  --tw-ring-offset-color: #CDDC39
}

.ring-offset-lime-A100 {
  --tw-ring-offset-color: #F4FF81
}

.ring-offset-lime-A200 {
  --tw-ring-offset-color: #EEFF41
}

.ring-offset-lime-A400 {
  --tw-ring-offset-color: #C6FF00
}

.ring-offset-lime-A700 {
  --tw-ring-offset-color: #AEEA00
}

.ring-offset-amber-50 {
  --tw-ring-offset-color: #FFF8E1
}

.ring-offset-amber-100 {
  --tw-ring-offset-color: #FFECB3
}

.ring-offset-amber-200 {
  --tw-ring-offset-color: #FFE082
}

.ring-offset-amber-300 {
  --tw-ring-offset-color: #FFD54F
}

.ring-offset-amber-400 {
  --tw-ring-offset-color: #FFCA28
}

.ring-offset-amber-500 {
  --tw-ring-offset-color: #FFC107
}

.ring-offset-amber-600 {
  --tw-ring-offset-color: #FFB300
}

.ring-offset-amber-700 {
  --tw-ring-offset-color: #FFA000
}

.ring-offset-amber-800 {
  --tw-ring-offset-color: #FF8F00
}

.ring-offset-amber-900 {
  --tw-ring-offset-color: #FF6F00
}

.ring-offset-amber {
  --tw-ring-offset-color: #FFC107
}

.ring-offset-amber-A100 {
  --tw-ring-offset-color: #FFE57F
}

.ring-offset-amber-A200 {
  --tw-ring-offset-color: #FFD740
}

.ring-offset-amber-A400 {
  --tw-ring-offset-color: #FFC400
}

.ring-offset-amber-A700 {
  --tw-ring-offset-color: #FFAB00
}

.ring-offset-brown-50 {
  --tw-ring-offset-color: #EFEBE9
}

.ring-offset-brown-100 {
  --tw-ring-offset-color: #D7CCC8
}

.ring-offset-brown-200 {
  --tw-ring-offset-color: #BCAAA4
}

.ring-offset-brown-300 {
  --tw-ring-offset-color: #A1887F
}

.ring-offset-brown-400 {
  --tw-ring-offset-color: #8D6E63
}

.ring-offset-brown-500 {
  --tw-ring-offset-color: #795548
}

.ring-offset-brown-600 {
  --tw-ring-offset-color: #6D4C41
}

.ring-offset-brown-700 {
  --tw-ring-offset-color: #5D4037
}

.ring-offset-brown-800 {
  --tw-ring-offset-color: #4E342E
}

.ring-offset-brown-900 {
  --tw-ring-offset-color: #3E2723
}

.ring-offset-brown {
  --tw-ring-offset-color: #795548
}

.ring-offset-brown-A100 {
  --tw-ring-offset-color: #D7CCC8
}

.ring-offset-brown-A200 {
  --tw-ring-offset-color: #BCAAA4
}

.ring-offset-brown-A400 {
  --tw-ring-offset-color: #8D6E63
}

.ring-offset-brown-A700 {
  --tw-ring-offset-color: #5D4037
}

.ring-offset-blue-gray-50 {
  --tw-ring-offset-color: #ECEFF1
}

.ring-offset-blue-gray-100 {
  --tw-ring-offset-color: #CFD8DC
}

.ring-offset-blue-gray-200 {
  --tw-ring-offset-color: #B0BEC5
}

.ring-offset-blue-gray-300 {
  --tw-ring-offset-color: #90A4AE
}

.ring-offset-blue-gray-400 {
  --tw-ring-offset-color: #78909C
}

.ring-offset-blue-gray-500 {
  --tw-ring-offset-color: #607D8B
}

.ring-offset-blue-gray-600 {
  --tw-ring-offset-color: #546E7A
}

.ring-offset-blue-gray-700 {
  --tw-ring-offset-color: #455A64
}

.ring-offset-blue-gray-800 {
  --tw-ring-offset-color: #37474F
}

.ring-offset-blue-gray-900 {
  --tw-ring-offset-color: #263238
}

.ring-offset-blue-gray {
  --tw-ring-offset-color: #607D8B
}

.ring-offset-blue-gray-A100 {
  --tw-ring-offset-color: #CFD8DC
}

.ring-offset-blue-gray-A200 {
  --tw-ring-offset-color: #B0BEC5
}

.ring-offset-blue-gray-A400 {
  --tw-ring-offset-color: #78909C
}

.ring-offset-blue-gray-A700 {
  --tw-ring-offset-color: #455A64
}

.ring-offset-cyan-50 {
  --tw-ring-offset-color: #E0F7FA
}

.ring-offset-cyan-100 {
  --tw-ring-offset-color: #B2EBF2
}

.ring-offset-cyan-200 {
  --tw-ring-offset-color: #80DEEA
}

.ring-offset-cyan-300 {
  --tw-ring-offset-color: #4DD0E1
}

.ring-offset-cyan-400 {
  --tw-ring-offset-color: #26C6DA
}

.ring-offset-cyan-500 {
  --tw-ring-offset-color: #00BCD4
}

.ring-offset-cyan-600 {
  --tw-ring-offset-color: #00ACC1
}

.ring-offset-cyan-700 {
  --tw-ring-offset-color: #0097A7
}

.ring-offset-cyan-800 {
  --tw-ring-offset-color: #00838F
}

.ring-offset-cyan-900 {
  --tw-ring-offset-color: #006064
}

.ring-offset-cyan {
  --tw-ring-offset-color: #00BCD4
}

.ring-offset-cyan-A100 {
  --tw-ring-offset-color: #84FFFF
}

.ring-offset-cyan-A200 {
  --tw-ring-offset-color: #18FFFF
}

.ring-offset-cyan-A400 {
  --tw-ring-offset-color: #00E5FF
}

.ring-offset-cyan-A700 {
  --tw-ring-offset-color: #00B8D4
}

.focus-within\:ring-offset-transparent:focus-within {
  --tw-ring-offset-color: transparent
}

.focus-within\:ring-offset-current:focus-within {
  --tw-ring-offset-color: currentColor
}

.focus-within\:ring-offset-black:focus-within {
  --tw-ring-offset-color: #22292F
}

.focus-within\:ring-offset-white:focus-within {
  --tw-ring-offset-color: #fff
}

.focus-within\:ring-offset-grey-50:focus-within {
  --tw-ring-offset-color: #FAFAFA
}

.focus-within\:ring-offset-grey-100:focus-within {
  --tw-ring-offset-color: #F5F5F5
}

.focus-within\:ring-offset-grey-200:focus-within {
  --tw-ring-offset-color: #EEEEEE
}

.focus-within\:ring-offset-grey-300:focus-within {
  --tw-ring-offset-color: #E0E0E0
}

.focus-within\:ring-offset-grey-400:focus-within {
  --tw-ring-offset-color: #BDBDBD
}

.focus-within\:ring-offset-grey-500:focus-within {
  --tw-ring-offset-color: #9E9E9E
}

.focus-within\:ring-offset-grey-600:focus-within {
  --tw-ring-offset-color: #757575
}

.focus-within\:ring-offset-grey-700:focus-within {
  --tw-ring-offset-color: #616161
}

.focus-within\:ring-offset-grey-800:focus-within {
  --tw-ring-offset-color: #424242
}

.focus-within\:ring-offset-grey-900:focus-within {
  --tw-ring-offset-color: #212121
}

.focus-within\:ring-offset-grey:focus-within {
  --tw-ring-offset-color: #9E9E9E
}

.focus-within\:ring-offset-grey-A100:focus-within {
  --tw-ring-offset-color: #D5D5D5
}

.focus-within\:ring-offset-grey-A200:focus-within {
  --tw-ring-offset-color: #AAAAAA
}

.focus-within\:ring-offset-grey-A400:focus-within {
  --tw-ring-offset-color: #303030
}

.focus-within\:ring-offset-grey-A700:focus-within {
  --tw-ring-offset-color: #616161
}

.focus-within\:ring-offset-gray-50:focus-within {
  --tw-ring-offset-color: #FAFAFA
}

.focus-within\:ring-offset-gray-100:focus-within {
  --tw-ring-offset-color: #F5F5F5
}

.focus-within\:ring-offset-gray-200:focus-within {
  --tw-ring-offset-color: #EEEEEE
}

.focus-within\:ring-offset-gray-300:focus-within {
  --tw-ring-offset-color: #E0E0E0
}

.focus-within\:ring-offset-gray-400:focus-within {
  --tw-ring-offset-color: #BDBDBD
}

.focus-within\:ring-offset-gray-500:focus-within {
  --tw-ring-offset-color: #9E9E9E
}

.focus-within\:ring-offset-gray-600:focus-within {
  --tw-ring-offset-color: #757575
}

.focus-within\:ring-offset-gray-700:focus-within {
  --tw-ring-offset-color: #616161
}

.focus-within\:ring-offset-gray-800:focus-within {
  --tw-ring-offset-color: #424242
}

.focus-within\:ring-offset-gray-900:focus-within {
  --tw-ring-offset-color: #212121
}

.focus-within\:ring-offset-gray:focus-within {
  --tw-ring-offset-color: #9E9E9E
}

.focus-within\:ring-offset-gray-hover:focus-within {
  --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
}

.focus-within\:ring-offset-gray-A100:focus-within {
  --tw-ring-offset-color: #D5D5D5
}

.focus-within\:ring-offset-gray-A200:focus-within {
  --tw-ring-offset-color: #AAAAAA
}

.focus-within\:ring-offset-gray-A400:focus-within {
  --tw-ring-offset-color: #303030
}

.focus-within\:ring-offset-gray-A700:focus-within {
  --tw-ring-offset-color: #616161
}

.focus-within\:ring-offset-red-50:focus-within {
  --tw-ring-offset-color: #FFEBEE
}

.focus-within\:ring-offset-red-100:focus-within {
  --tw-ring-offset-color: #FFCDD2
}

.focus-within\:ring-offset-red-200:focus-within {
  --tw-ring-offset-color: #EF9A9A
}

.focus-within\:ring-offset-red-300:focus-within {
  --tw-ring-offset-color: #E57373
}

.focus-within\:ring-offset-red-400:focus-within {
  --tw-ring-offset-color: #EF5350
}

.focus-within\:ring-offset-red-500:focus-within {
  --tw-ring-offset-color: #F44336
}

.focus-within\:ring-offset-red-600:focus-within {
  --tw-ring-offset-color: #E53935
}

.focus-within\:ring-offset-red-700:focus-within {
  --tw-ring-offset-color: #D32F2F
}

.focus-within\:ring-offset-red-800:focus-within {
  --tw-ring-offset-color: #C62828
}

.focus-within\:ring-offset-red-900:focus-within {
  --tw-ring-offset-color: #B71C1C
}

.focus-within\:ring-offset-red:focus-within {
  --tw-ring-offset-color: #F44336
}

.focus-within\:ring-offset-red-A100:focus-within {
  --tw-ring-offset-color: #FF8A80
}

.focus-within\:ring-offset-red-A200:focus-within {
  --tw-ring-offset-color: #FF5252
}

.focus-within\:ring-offset-red-A400:focus-within {
  --tw-ring-offset-color: #FF1744
}

.focus-within\:ring-offset-red-A700:focus-within {
  --tw-ring-offset-color: #D50000
}

.focus-within\:ring-offset-orange-50:focus-within {
  --tw-ring-offset-color: #FFF3E0
}

.focus-within\:ring-offset-orange-100:focus-within {
  --tw-ring-offset-color: #FFE0B2
}

.focus-within\:ring-offset-orange-200:focus-within {
  --tw-ring-offset-color: #FFCC80
}

.focus-within\:ring-offset-orange-300:focus-within {
  --tw-ring-offset-color: #FFB74D
}

.focus-within\:ring-offset-orange-400:focus-within {
  --tw-ring-offset-color: #FFA726
}

.focus-within\:ring-offset-orange-500:focus-within {
  --tw-ring-offset-color: #FF9800
}

.focus-within\:ring-offset-orange-600:focus-within {
  --tw-ring-offset-color: #FB8C00
}

.focus-within\:ring-offset-orange-700:focus-within {
  --tw-ring-offset-color: #F57C00
}

.focus-within\:ring-offset-orange-800:focus-within {
  --tw-ring-offset-color: #EF6C00
}

.focus-within\:ring-offset-orange-900:focus-within {
  --tw-ring-offset-color: #E65100
}

.focus-within\:ring-offset-orange:focus-within {
  --tw-ring-offset-color: #FF9800
}

.focus-within\:ring-offset-orange-A100:focus-within {
  --tw-ring-offset-color: #FFD180
}

.focus-within\:ring-offset-orange-A200:focus-within {
  --tw-ring-offset-color: #FFAB40
}

.focus-within\:ring-offset-orange-A400:focus-within {
  --tw-ring-offset-color: #FF9100
}

.focus-within\:ring-offset-orange-A700:focus-within {
  --tw-ring-offset-color: #FF6D00
}

.focus-within\:ring-offset-deep-orange-50:focus-within {
  --tw-ring-offset-color: #FBE9E7
}

.focus-within\:ring-offset-deep-orange-100:focus-within {
  --tw-ring-offset-color: #FFCCBC
}

.focus-within\:ring-offset-deep-orange-200:focus-within {
  --tw-ring-offset-color: #FFAB91
}

.focus-within\:ring-offset-deep-orange-300:focus-within {
  --tw-ring-offset-color: #FF8A65
}

.focus-within\:ring-offset-deep-orange-400:focus-within {
  --tw-ring-offset-color: #FF7043
}

.focus-within\:ring-offset-deep-orange-500:focus-within {
  --tw-ring-offset-color: #FF5722
}

.focus-within\:ring-offset-deep-orange-600:focus-within {
  --tw-ring-offset-color: #F4511E
}

.focus-within\:ring-offset-deep-orange-700:focus-within {
  --tw-ring-offset-color: #E64A19
}

.focus-within\:ring-offset-deep-orange-800:focus-within {
  --tw-ring-offset-color: #D84315
}

.focus-within\:ring-offset-deep-orange-900:focus-within {
  --tw-ring-offset-color: #BF360C
}

.focus-within\:ring-offset-deep-orange:focus-within {
  --tw-ring-offset-color: #FF5722
}

.focus-within\:ring-offset-deep-orange-A100:focus-within {
  --tw-ring-offset-color: #FF9E80
}

.focus-within\:ring-offset-deep-orange-A200:focus-within {
  --tw-ring-offset-color: #FF6E40
}

.focus-within\:ring-offset-deep-orange-A400:focus-within {
  --tw-ring-offset-color: #FF3D00
}

.focus-within\:ring-offset-deep-orange-A700:focus-within {
  --tw-ring-offset-color: #DD2C00
}

.focus-within\:ring-offset-yellow-50:focus-within {
  --tw-ring-offset-color: #FFFDE7
}

.focus-within\:ring-offset-yellow-100:focus-within {
  --tw-ring-offset-color: #FFF9C4
}

.focus-within\:ring-offset-yellow-200:focus-within {
  --tw-ring-offset-color: #FFF59D
}

.focus-within\:ring-offset-yellow-300:focus-within {
  --tw-ring-offset-color: #FFF176
}

.focus-within\:ring-offset-yellow-400:focus-within {
  --tw-ring-offset-color: #FFEE58
}

.focus-within\:ring-offset-yellow-500:focus-within {
  --tw-ring-offset-color: #FFEB3B
}

.focus-within\:ring-offset-yellow-600:focus-within {
  --tw-ring-offset-color: #FDD835
}

.focus-within\:ring-offset-yellow-700:focus-within {
  --tw-ring-offset-color: #FBC02D
}

.focus-within\:ring-offset-yellow-800:focus-within {
  --tw-ring-offset-color: #F9A825
}

.focus-within\:ring-offset-yellow-900:focus-within {
  --tw-ring-offset-color: #F57F17
}

.focus-within\:ring-offset-yellow:focus-within {
  --tw-ring-offset-color: #FFEB3B
}

.focus-within\:ring-offset-yellow-A100:focus-within {
  --tw-ring-offset-color: #FFFF8D
}

.focus-within\:ring-offset-yellow-A200:focus-within {
  --tw-ring-offset-color: #FFFF00
}

.focus-within\:ring-offset-yellow-A400:focus-within {
  --tw-ring-offset-color: #FFEA00
}

.focus-within\:ring-offset-yellow-A700:focus-within {
  --tw-ring-offset-color: #FFD600
}

.focus-within\:ring-offset-green-50:focus-within {
  --tw-ring-offset-color: #E8F5E9
}

.focus-within\:ring-offset-green-100:focus-within {
  --tw-ring-offset-color: #C8E6C9
}

.focus-within\:ring-offset-green-200:focus-within {
  --tw-ring-offset-color: #A5D6A7
}

.focus-within\:ring-offset-green-300:focus-within {
  --tw-ring-offset-color: #81C784
}

.focus-within\:ring-offset-green-400:focus-within {
  --tw-ring-offset-color: #66BB6A
}

.focus-within\:ring-offset-green-500:focus-within {
  --tw-ring-offset-color: #4CAF50
}

.focus-within\:ring-offset-green-600:focus-within {
  --tw-ring-offset-color: #43A047
}

.focus-within\:ring-offset-green-700:focus-within {
  --tw-ring-offset-color: #388E3C
}

.focus-within\:ring-offset-green-800:focus-within {
  --tw-ring-offset-color: #2E7D32
}

.focus-within\:ring-offset-green-900:focus-within {
  --tw-ring-offset-color: #1B5E20
}

.focus-within\:ring-offset-green:focus-within {
  --tw-ring-offset-color: #4CAF50
}

.focus-within\:ring-offset-green-A100:focus-within {
  --tw-ring-offset-color: #B9F6CA
}

.focus-within\:ring-offset-green-A200:focus-within {
  --tw-ring-offset-color: #69F0AE
}

.focus-within\:ring-offset-green-A400:focus-within {
  --tw-ring-offset-color: #00E676
}

.focus-within\:ring-offset-green-A700:focus-within {
  --tw-ring-offset-color: #00C853
}

.focus-within\:ring-offset-light-green-50:focus-within {
  --tw-ring-offset-color: #F1F8E9
}

.focus-within\:ring-offset-light-green-100:focus-within {
  --tw-ring-offset-color: #DCEDC8
}

.focus-within\:ring-offset-light-green-200:focus-within {
  --tw-ring-offset-color: #C5E1A5
}

.focus-within\:ring-offset-light-green-300:focus-within {
  --tw-ring-offset-color: #AED581
}

.focus-within\:ring-offset-light-green-400:focus-within {
  --tw-ring-offset-color: #9CCC65
}

.focus-within\:ring-offset-light-green-500:focus-within {
  --tw-ring-offset-color: #8BC34A
}

.focus-within\:ring-offset-light-green-600:focus-within {
  --tw-ring-offset-color: #7CB342
}

.focus-within\:ring-offset-light-green-700:focus-within {
  --tw-ring-offset-color: #689F38
}

.focus-within\:ring-offset-light-green-800:focus-within {
  --tw-ring-offset-color: #558B2F
}

.focus-within\:ring-offset-light-green-900:focus-within {
  --tw-ring-offset-color: #33691E
}

.focus-within\:ring-offset-light-green:focus-within {
  --tw-ring-offset-color: #8BC34A
}

.focus-within\:ring-offset-light-green-A100:focus-within {
  --tw-ring-offset-color: #CCFF90
}

.focus-within\:ring-offset-light-green-A200:focus-within {
  --tw-ring-offset-color: #B2FF59
}

.focus-within\:ring-offset-light-green-A400:focus-within {
  --tw-ring-offset-color: #76FF03
}

.focus-within\:ring-offset-light-green-A700:focus-within {
  --tw-ring-offset-color: #64DD17
}

.focus-within\:ring-offset-teal-50:focus-within {
  --tw-ring-offset-color: #E0F2F1
}

.focus-within\:ring-offset-teal-100:focus-within {
  --tw-ring-offset-color: #B2DFDB
}

.focus-within\:ring-offset-teal-200:focus-within {
  --tw-ring-offset-color: #80CBC4
}

.focus-within\:ring-offset-teal-300:focus-within {
  --tw-ring-offset-color: #4DB6AC
}

.focus-within\:ring-offset-teal-400:focus-within {
  --tw-ring-offset-color: #26A69A
}

.focus-within\:ring-offset-teal-500:focus-within {
  --tw-ring-offset-color: #009688
}

.focus-within\:ring-offset-teal-600:focus-within {
  --tw-ring-offset-color: #00897B
}

.focus-within\:ring-offset-teal-700:focus-within {
  --tw-ring-offset-color: #00796B
}

.focus-within\:ring-offset-teal-800:focus-within {
  --tw-ring-offset-color: #00695C
}

.focus-within\:ring-offset-teal-900:focus-within {
  --tw-ring-offset-color: #004D40
}

.focus-within\:ring-offset-teal:focus-within {
  --tw-ring-offset-color: #009688
}

.focus-within\:ring-offset-teal-A100:focus-within {
  --tw-ring-offset-color: #A7FFEB
}

.focus-within\:ring-offset-teal-A200:focus-within {
  --tw-ring-offset-color: #64FFDA
}

.focus-within\:ring-offset-teal-A400:focus-within {
  --tw-ring-offset-color: #1DE9B6
}

.focus-within\:ring-offset-teal-A700:focus-within {
  --tw-ring-offset-color: #00BFA5
}

.focus-within\:ring-offset-blue-50:focus-within {
  --tw-ring-offset-color: #E3F2FD
}

.focus-within\:ring-offset-blue-100:focus-within {
  --tw-ring-offset-color: #BBDEFB
}

.focus-within\:ring-offset-blue-200:focus-within {
  --tw-ring-offset-color: #90CAF9
}

.focus-within\:ring-offset-blue-300:focus-within {
  --tw-ring-offset-color: #64B5F6
}

.focus-within\:ring-offset-blue-400:focus-within {
  --tw-ring-offset-color: #42A5F5
}

.focus-within\:ring-offset-blue-500:focus-within {
  --tw-ring-offset-color: #2196F3
}

.focus-within\:ring-offset-blue-600:focus-within {
  --tw-ring-offset-color: #1E88E5
}

.focus-within\:ring-offset-blue-700:focus-within {
  --tw-ring-offset-color: #1976D2
}

.focus-within\:ring-offset-blue-800:focus-within {
  --tw-ring-offset-color: #1565C0
}

.focus-within\:ring-offset-blue-900:focus-within {
  --tw-ring-offset-color: #0D47A1
}

.focus-within\:ring-offset-blue:focus-within {
  --tw-ring-offset-color: #2196F3
}

.focus-within\:ring-offset-blue-A100:focus-within {
  --tw-ring-offset-color: #82B1FF
}

.focus-within\:ring-offset-blue-A200:focus-within {
  --tw-ring-offset-color: #448AFF
}

.focus-within\:ring-offset-blue-A400:focus-within {
  --tw-ring-offset-color: #2979FF
}

.focus-within\:ring-offset-blue-A700:focus-within {
  --tw-ring-offset-color: #2962FF
}

.focus-within\:ring-offset-light-blue-50:focus-within {
  --tw-ring-offset-color: #E1F5FE
}

.focus-within\:ring-offset-light-blue-100:focus-within {
  --tw-ring-offset-color: #B3E5FC
}

.focus-within\:ring-offset-light-blue-200:focus-within {
  --tw-ring-offset-color: #81D4FA
}

.focus-within\:ring-offset-light-blue-300:focus-within {
  --tw-ring-offset-color: #4FC3F7
}

.focus-within\:ring-offset-light-blue-400:focus-within {
  --tw-ring-offset-color: #29B6F6
}

.focus-within\:ring-offset-light-blue-500:focus-within {
  --tw-ring-offset-color: #03A9F4
}

.focus-within\:ring-offset-light-blue-600:focus-within {
  --tw-ring-offset-color: #039BE5
}

.focus-within\:ring-offset-light-blue-700:focus-within {
  --tw-ring-offset-color: #0288D1
}

.focus-within\:ring-offset-light-blue-800:focus-within {
  --tw-ring-offset-color: #0277BD
}

.focus-within\:ring-offset-light-blue-900:focus-within {
  --tw-ring-offset-color: #01579B
}

.focus-within\:ring-offset-light-blue:focus-within {
  --tw-ring-offset-color: #03A9F4
}

.focus-within\:ring-offset-light-blue-A100:focus-within {
  --tw-ring-offset-color: #80D8FF
}

.focus-within\:ring-offset-light-blue-A200:focus-within {
  --tw-ring-offset-color: #40C4FF
}

.focus-within\:ring-offset-light-blue-A400:focus-within {
  --tw-ring-offset-color: #00B0FF
}

.focus-within\:ring-offset-light-blue-A700:focus-within {
  --tw-ring-offset-color: #0091EA
}

.focus-within\:ring-offset-indigo-50:focus-within {
  --tw-ring-offset-color: #E8EAF6
}

.focus-within\:ring-offset-indigo-100:focus-within {
  --tw-ring-offset-color: #C5CAE9
}

.focus-within\:ring-offset-indigo-200:focus-within {
  --tw-ring-offset-color: #9FA8DA
}

.focus-within\:ring-offset-indigo-300:focus-within {
  --tw-ring-offset-color: #7986CB
}

.focus-within\:ring-offset-indigo-400:focus-within {
  --tw-ring-offset-color: #5C6BC0
}

.focus-within\:ring-offset-indigo-500:focus-within {
  --tw-ring-offset-color: #3F51B5
}

.focus-within\:ring-offset-indigo-600:focus-within {
  --tw-ring-offset-color: #3949AB
}

.focus-within\:ring-offset-indigo-700:focus-within {
  --tw-ring-offset-color: #303F9F
}

.focus-within\:ring-offset-indigo-800:focus-within {
  --tw-ring-offset-color: #283593
}

.focus-within\:ring-offset-indigo-900:focus-within {
  --tw-ring-offset-color: #1A237E
}

.focus-within\:ring-offset-indigo:focus-within {
  --tw-ring-offset-color: #3F51B5
}

.focus-within\:ring-offset-indigo-A100:focus-within {
  --tw-ring-offset-color: #8C9EFF
}

.focus-within\:ring-offset-indigo-A200:focus-within {
  --tw-ring-offset-color: #536DFE
}

.focus-within\:ring-offset-indigo-A400:focus-within {
  --tw-ring-offset-color: #3D5AFE
}

.focus-within\:ring-offset-indigo-A700:focus-within {
  --tw-ring-offset-color: #304FFE
}

.focus-within\:ring-offset-purple-50:focus-within {
  --tw-ring-offset-color: #F3E5F5
}

.focus-within\:ring-offset-purple-100:focus-within {
  --tw-ring-offset-color: #E1BEE7
}

.focus-within\:ring-offset-purple-200:focus-within {
  --tw-ring-offset-color: #CE93D8
}

.focus-within\:ring-offset-purple-300:focus-within {
  --tw-ring-offset-color: #BA68C8
}

.focus-within\:ring-offset-purple-400:focus-within {
  --tw-ring-offset-color: #AB47BC
}

.focus-within\:ring-offset-purple-500:focus-within {
  --tw-ring-offset-color: #9C27B0
}

.focus-within\:ring-offset-purple-600:focus-within {
  --tw-ring-offset-color: #8E24AA
}

.focus-within\:ring-offset-purple-700:focus-within {
  --tw-ring-offset-color: #7B1FA2
}

.focus-within\:ring-offset-purple-800:focus-within {
  --tw-ring-offset-color: #6A1B9A
}

.focus-within\:ring-offset-purple-900:focus-within {
  --tw-ring-offset-color: #4A148C
}

.focus-within\:ring-offset-purple:focus-within {
  --tw-ring-offset-color: #9C27B0
}

.focus-within\:ring-offset-purple-A100:focus-within {
  --tw-ring-offset-color: #EA80FC
}

.focus-within\:ring-offset-purple-A200:focus-within {
  --tw-ring-offset-color: #E040FB
}

.focus-within\:ring-offset-purple-A400:focus-within {
  --tw-ring-offset-color: #D500F9
}

.focus-within\:ring-offset-purple-A700:focus-within {
  --tw-ring-offset-color: #AA00FF
}

.focus-within\:ring-offset-deep-purple-50:focus-within {
  --tw-ring-offset-color: #EDE7F6
}

.focus-within\:ring-offset-deep-purple-100:focus-within {
  --tw-ring-offset-color: #D1C4E9
}

.focus-within\:ring-offset-deep-purple-200:focus-within {
  --tw-ring-offset-color: #B39DDB
}

.focus-within\:ring-offset-deep-purple-300:focus-within {
  --tw-ring-offset-color: #9575CD
}

.focus-within\:ring-offset-deep-purple-400:focus-within {
  --tw-ring-offset-color: #7E57C2
}

.focus-within\:ring-offset-deep-purple-500:focus-within {
  --tw-ring-offset-color: #673AB7
}

.focus-within\:ring-offset-deep-purple-600:focus-within {
  --tw-ring-offset-color: #5E35B1
}

.focus-within\:ring-offset-deep-purple-700:focus-within {
  --tw-ring-offset-color: #512DA8
}

.focus-within\:ring-offset-deep-purple-800:focus-within {
  --tw-ring-offset-color: #4527A0
}

.focus-within\:ring-offset-deep-purple-900:focus-within {
  --tw-ring-offset-color: #311B92
}

.focus-within\:ring-offset-deep-purple:focus-within {
  --tw-ring-offset-color: #673AB7
}

.focus-within\:ring-offset-deep-purple-A100:focus-within {
  --tw-ring-offset-color: #B388FF
}

.focus-within\:ring-offset-deep-purple-A200:focus-within {
  --tw-ring-offset-color: #7C4DFF
}

.focus-within\:ring-offset-deep-purple-A400:focus-within {
  --tw-ring-offset-color: #651FFF
}

.focus-within\:ring-offset-deep-purple-A700:focus-within {
  --tw-ring-offset-color: #6200EA
}

.focus-within\:ring-offset-pink-50:focus-within {
  --tw-ring-offset-color: #FCE4EC
}

.focus-within\:ring-offset-pink-100:focus-within {
  --tw-ring-offset-color: #F8BBD0
}

.focus-within\:ring-offset-pink-200:focus-within {
  --tw-ring-offset-color: #F48FB1
}

.focus-within\:ring-offset-pink-300:focus-within {
  --tw-ring-offset-color: #F06292
}

.focus-within\:ring-offset-pink-400:focus-within {
  --tw-ring-offset-color: #EC407A
}

.focus-within\:ring-offset-pink-500:focus-within {
  --tw-ring-offset-color: #E91E63
}

.focus-within\:ring-offset-pink-600:focus-within {
  --tw-ring-offset-color: #D81B60
}

.focus-within\:ring-offset-pink-700:focus-within {
  --tw-ring-offset-color: #C2185B
}

.focus-within\:ring-offset-pink-800:focus-within {
  --tw-ring-offset-color: #AD1457
}

.focus-within\:ring-offset-pink-900:focus-within {
  --tw-ring-offset-color: #880E4F
}

.focus-within\:ring-offset-pink:focus-within {
  --tw-ring-offset-color: #E91E63
}

.focus-within\:ring-offset-pink-A100:focus-within {
  --tw-ring-offset-color: #FF80AB
}

.focus-within\:ring-offset-pink-A200:focus-within {
  --tw-ring-offset-color: #FF4081
}

.focus-within\:ring-offset-pink-A400:focus-within {
  --tw-ring-offset-color: #F50057
}

.focus-within\:ring-offset-pink-A700:focus-within {
  --tw-ring-offset-color: #C51162
}

.focus-within\:ring-offset-lime-50:focus-within {
  --tw-ring-offset-color: #F9FBE7
}

.focus-within\:ring-offset-lime-100:focus-within {
  --tw-ring-offset-color: #F0F4C3
}

.focus-within\:ring-offset-lime-200:focus-within {
  --tw-ring-offset-color: #E6EE9C
}

.focus-within\:ring-offset-lime-300:focus-within {
  --tw-ring-offset-color: #DCE775
}

.focus-within\:ring-offset-lime-400:focus-within {
  --tw-ring-offset-color: #D4E157
}

.focus-within\:ring-offset-lime-500:focus-within {
  --tw-ring-offset-color: #CDDC39
}

.focus-within\:ring-offset-lime-600:focus-within {
  --tw-ring-offset-color: #C0CA33
}

.focus-within\:ring-offset-lime-700:focus-within {
  --tw-ring-offset-color: #AFB42B
}

.focus-within\:ring-offset-lime-800:focus-within {
  --tw-ring-offset-color: #9E9D24
}

.focus-within\:ring-offset-lime-900:focus-within {
  --tw-ring-offset-color: #827717
}

.focus-within\:ring-offset-lime:focus-within {
  --tw-ring-offset-color: #CDDC39
}

.focus-within\:ring-offset-lime-A100:focus-within {
  --tw-ring-offset-color: #F4FF81
}

.focus-within\:ring-offset-lime-A200:focus-within {
  --tw-ring-offset-color: #EEFF41
}

.focus-within\:ring-offset-lime-A400:focus-within {
  --tw-ring-offset-color: #C6FF00
}

.focus-within\:ring-offset-lime-A700:focus-within {
  --tw-ring-offset-color: #AEEA00
}

.focus-within\:ring-offset-amber-50:focus-within {
  --tw-ring-offset-color: #FFF8E1
}

.focus-within\:ring-offset-amber-100:focus-within {
  --tw-ring-offset-color: #FFECB3
}

.focus-within\:ring-offset-amber-200:focus-within {
  --tw-ring-offset-color: #FFE082
}

.focus-within\:ring-offset-amber-300:focus-within {
  --tw-ring-offset-color: #FFD54F
}

.focus-within\:ring-offset-amber-400:focus-within {
  --tw-ring-offset-color: #FFCA28
}

.focus-within\:ring-offset-amber-500:focus-within {
  --tw-ring-offset-color: #FFC107
}

.focus-within\:ring-offset-amber-600:focus-within {
  --tw-ring-offset-color: #FFB300
}

.focus-within\:ring-offset-amber-700:focus-within {
  --tw-ring-offset-color: #FFA000
}

.focus-within\:ring-offset-amber-800:focus-within {
  --tw-ring-offset-color: #FF8F00
}

.focus-within\:ring-offset-amber-900:focus-within {
  --tw-ring-offset-color: #FF6F00
}

.focus-within\:ring-offset-amber:focus-within {
  --tw-ring-offset-color: #FFC107
}

.focus-within\:ring-offset-amber-A100:focus-within {
  --tw-ring-offset-color: #FFE57F
}

.focus-within\:ring-offset-amber-A200:focus-within {
  --tw-ring-offset-color: #FFD740
}

.focus-within\:ring-offset-amber-A400:focus-within {
  --tw-ring-offset-color: #FFC400
}

.focus-within\:ring-offset-amber-A700:focus-within {
  --tw-ring-offset-color: #FFAB00
}

.focus-within\:ring-offset-brown-50:focus-within {
  --tw-ring-offset-color: #EFEBE9
}

.focus-within\:ring-offset-brown-100:focus-within {
  --tw-ring-offset-color: #D7CCC8
}

.focus-within\:ring-offset-brown-200:focus-within {
  --tw-ring-offset-color: #BCAAA4
}

.focus-within\:ring-offset-brown-300:focus-within {
  --tw-ring-offset-color: #A1887F
}

.focus-within\:ring-offset-brown-400:focus-within {
  --tw-ring-offset-color: #8D6E63
}

.focus-within\:ring-offset-brown-500:focus-within {
  --tw-ring-offset-color: #795548
}

.focus-within\:ring-offset-brown-600:focus-within {
  --tw-ring-offset-color: #6D4C41
}

.focus-within\:ring-offset-brown-700:focus-within {
  --tw-ring-offset-color: #5D4037
}

.focus-within\:ring-offset-brown-800:focus-within {
  --tw-ring-offset-color: #4E342E
}

.focus-within\:ring-offset-brown-900:focus-within {
  --tw-ring-offset-color: #3E2723
}

.focus-within\:ring-offset-brown:focus-within {
  --tw-ring-offset-color: #795548
}

.focus-within\:ring-offset-brown-A100:focus-within {
  --tw-ring-offset-color: #D7CCC8
}

.focus-within\:ring-offset-brown-A200:focus-within {
  --tw-ring-offset-color: #BCAAA4
}

.focus-within\:ring-offset-brown-A400:focus-within {
  --tw-ring-offset-color: #8D6E63
}

.focus-within\:ring-offset-brown-A700:focus-within {
  --tw-ring-offset-color: #5D4037
}

.focus-within\:ring-offset-blue-gray-50:focus-within {
  --tw-ring-offset-color: #ECEFF1
}

.focus-within\:ring-offset-blue-gray-100:focus-within {
  --tw-ring-offset-color: #CFD8DC
}

.focus-within\:ring-offset-blue-gray-200:focus-within {
  --tw-ring-offset-color: #B0BEC5
}

.focus-within\:ring-offset-blue-gray-300:focus-within {
  --tw-ring-offset-color: #90A4AE
}

.focus-within\:ring-offset-blue-gray-400:focus-within {
  --tw-ring-offset-color: #78909C
}

.focus-within\:ring-offset-blue-gray-500:focus-within {
  --tw-ring-offset-color: #607D8B
}

.focus-within\:ring-offset-blue-gray-600:focus-within {
  --tw-ring-offset-color: #546E7A
}

.focus-within\:ring-offset-blue-gray-700:focus-within {
  --tw-ring-offset-color: #455A64
}

.focus-within\:ring-offset-blue-gray-800:focus-within {
  --tw-ring-offset-color: #37474F
}

.focus-within\:ring-offset-blue-gray-900:focus-within {
  --tw-ring-offset-color: #263238
}

.focus-within\:ring-offset-blue-gray:focus-within {
  --tw-ring-offset-color: #607D8B
}

.focus-within\:ring-offset-blue-gray-A100:focus-within {
  --tw-ring-offset-color: #CFD8DC
}

.focus-within\:ring-offset-blue-gray-A200:focus-within {
  --tw-ring-offset-color: #B0BEC5
}

.focus-within\:ring-offset-blue-gray-A400:focus-within {
  --tw-ring-offset-color: #78909C
}

.focus-within\:ring-offset-blue-gray-A700:focus-within {
  --tw-ring-offset-color: #455A64
}

.focus-within\:ring-offset-cyan-50:focus-within {
  --tw-ring-offset-color: #E0F7FA
}

.focus-within\:ring-offset-cyan-100:focus-within {
  --tw-ring-offset-color: #B2EBF2
}

.focus-within\:ring-offset-cyan-200:focus-within {
  --tw-ring-offset-color: #80DEEA
}

.focus-within\:ring-offset-cyan-300:focus-within {
  --tw-ring-offset-color: #4DD0E1
}

.focus-within\:ring-offset-cyan-400:focus-within {
  --tw-ring-offset-color: #26C6DA
}

.focus-within\:ring-offset-cyan-500:focus-within {
  --tw-ring-offset-color: #00BCD4
}

.focus-within\:ring-offset-cyan-600:focus-within {
  --tw-ring-offset-color: #00ACC1
}

.focus-within\:ring-offset-cyan-700:focus-within {
  --tw-ring-offset-color: #0097A7
}

.focus-within\:ring-offset-cyan-800:focus-within {
  --tw-ring-offset-color: #00838F
}

.focus-within\:ring-offset-cyan-900:focus-within {
  --tw-ring-offset-color: #006064
}

.focus-within\:ring-offset-cyan:focus-within {
  --tw-ring-offset-color: #00BCD4
}

.focus-within\:ring-offset-cyan-A100:focus-within {
  --tw-ring-offset-color: #84FFFF
}

.focus-within\:ring-offset-cyan-A200:focus-within {
  --tw-ring-offset-color: #18FFFF
}

.focus-within\:ring-offset-cyan-A400:focus-within {
  --tw-ring-offset-color: #00E5FF
}

.focus-within\:ring-offset-cyan-A700:focus-within {
  --tw-ring-offset-color: #00B8D4
}

.focus\:ring-offset-transparent:focus {
  --tw-ring-offset-color: transparent
}

.focus\:ring-offset-current:focus {
  --tw-ring-offset-color: currentColor
}

.focus\:ring-offset-black:focus {
  --tw-ring-offset-color: #22292F
}

.focus\:ring-offset-white:focus {
  --tw-ring-offset-color: #fff
}

.focus\:ring-offset-grey-50:focus {
  --tw-ring-offset-color: #FAFAFA
}

.focus\:ring-offset-grey-100:focus {
  --tw-ring-offset-color: #F5F5F5
}

.focus\:ring-offset-grey-200:focus {
  --tw-ring-offset-color: #EEEEEE
}

.focus\:ring-offset-grey-300:focus {
  --tw-ring-offset-color: #E0E0E0
}

.focus\:ring-offset-grey-400:focus {
  --tw-ring-offset-color: #BDBDBD
}

.focus\:ring-offset-grey-500:focus {
  --tw-ring-offset-color: #9E9E9E
}

.focus\:ring-offset-grey-600:focus {
  --tw-ring-offset-color: #757575
}

.focus\:ring-offset-grey-700:focus {
  --tw-ring-offset-color: #616161
}

.focus\:ring-offset-grey-800:focus {
  --tw-ring-offset-color: #424242
}

.focus\:ring-offset-grey-900:focus {
  --tw-ring-offset-color: #212121
}

.focus\:ring-offset-grey:focus {
  --tw-ring-offset-color: #9E9E9E
}

.focus\:ring-offset-grey-A100:focus {
  --tw-ring-offset-color: #D5D5D5
}

.focus\:ring-offset-grey-A200:focus {
  --tw-ring-offset-color: #AAAAAA
}

.focus\:ring-offset-grey-A400:focus {
  --tw-ring-offset-color: #303030
}

.focus\:ring-offset-grey-A700:focus {
  --tw-ring-offset-color: #616161
}

.focus\:ring-offset-gray-50:focus {
  --tw-ring-offset-color: #FAFAFA
}

.focus\:ring-offset-gray-100:focus {
  --tw-ring-offset-color: #F5F5F5
}

.focus\:ring-offset-gray-200:focus {
  --tw-ring-offset-color: #EEEEEE
}

.focus\:ring-offset-gray-300:focus {
  --tw-ring-offset-color: #E0E0E0
}

.focus\:ring-offset-gray-400:focus {
  --tw-ring-offset-color: #BDBDBD
}

.focus\:ring-offset-gray-500:focus {
  --tw-ring-offset-color: #9E9E9E
}

.focus\:ring-offset-gray-600:focus {
  --tw-ring-offset-color: #757575
}

.focus\:ring-offset-gray-700:focus {
  --tw-ring-offset-color: #616161
}

.focus\:ring-offset-gray-800:focus {
  --tw-ring-offset-color: #424242
}

.focus\:ring-offset-gray-900:focus {
  --tw-ring-offset-color: #212121
}

.focus\:ring-offset-gray:focus {
  --tw-ring-offset-color: #9E9E9E
}

.focus\:ring-offset-gray-hover:focus {
  --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
}

.focus\:ring-offset-gray-A100:focus {
  --tw-ring-offset-color: #D5D5D5
}

.focus\:ring-offset-gray-A200:focus {
  --tw-ring-offset-color: #AAAAAA
}

.focus\:ring-offset-gray-A400:focus {
  --tw-ring-offset-color: #303030
}

.focus\:ring-offset-gray-A700:focus {
  --tw-ring-offset-color: #616161
}

.focus\:ring-offset-red-50:focus {
  --tw-ring-offset-color: #FFEBEE
}

.focus\:ring-offset-red-100:focus {
  --tw-ring-offset-color: #FFCDD2
}

.focus\:ring-offset-red-200:focus {
  --tw-ring-offset-color: #EF9A9A
}

.focus\:ring-offset-red-300:focus {
  --tw-ring-offset-color: #E57373
}

.focus\:ring-offset-red-400:focus {
  --tw-ring-offset-color: #EF5350
}

.focus\:ring-offset-red-500:focus {
  --tw-ring-offset-color: #F44336
}

.focus\:ring-offset-red-600:focus {
  --tw-ring-offset-color: #E53935
}

.focus\:ring-offset-red-700:focus {
  --tw-ring-offset-color: #D32F2F
}

.focus\:ring-offset-red-800:focus {
  --tw-ring-offset-color: #C62828
}

.focus\:ring-offset-red-900:focus {
  --tw-ring-offset-color: #B71C1C
}

.focus\:ring-offset-red:focus {
  --tw-ring-offset-color: #F44336
}

.focus\:ring-offset-red-A100:focus {
  --tw-ring-offset-color: #FF8A80
}

.focus\:ring-offset-red-A200:focus {
  --tw-ring-offset-color: #FF5252
}

.focus\:ring-offset-red-A400:focus {
  --tw-ring-offset-color: #FF1744
}

.focus\:ring-offset-red-A700:focus {
  --tw-ring-offset-color: #D50000
}

.focus\:ring-offset-orange-50:focus {
  --tw-ring-offset-color: #FFF3E0
}

.focus\:ring-offset-orange-100:focus {
  --tw-ring-offset-color: #FFE0B2
}

.focus\:ring-offset-orange-200:focus {
  --tw-ring-offset-color: #FFCC80
}

.focus\:ring-offset-orange-300:focus {
  --tw-ring-offset-color: #FFB74D
}

.focus\:ring-offset-orange-400:focus {
  --tw-ring-offset-color: #FFA726
}

.focus\:ring-offset-orange-500:focus {
  --tw-ring-offset-color: #FF9800
}

.focus\:ring-offset-orange-600:focus {
  --tw-ring-offset-color: #FB8C00
}

.focus\:ring-offset-orange-700:focus {
  --tw-ring-offset-color: #F57C00
}

.focus\:ring-offset-orange-800:focus {
  --tw-ring-offset-color: #EF6C00
}

.focus\:ring-offset-orange-900:focus {
  --tw-ring-offset-color: #E65100
}

.focus\:ring-offset-orange:focus {
  --tw-ring-offset-color: #FF9800
}

.focus\:ring-offset-orange-A100:focus {
  --tw-ring-offset-color: #FFD180
}

.focus\:ring-offset-orange-A200:focus {
  --tw-ring-offset-color: #FFAB40
}

.focus\:ring-offset-orange-A400:focus {
  --tw-ring-offset-color: #FF9100
}

.focus\:ring-offset-orange-A700:focus {
  --tw-ring-offset-color: #FF6D00
}

.focus\:ring-offset-deep-orange-50:focus {
  --tw-ring-offset-color: #FBE9E7
}

.focus\:ring-offset-deep-orange-100:focus {
  --tw-ring-offset-color: #FFCCBC
}

.focus\:ring-offset-deep-orange-200:focus {
  --tw-ring-offset-color: #FFAB91
}

.focus\:ring-offset-deep-orange-300:focus {
  --tw-ring-offset-color: #FF8A65
}

.focus\:ring-offset-deep-orange-400:focus {
  --tw-ring-offset-color: #FF7043
}

.focus\:ring-offset-deep-orange-500:focus {
  --tw-ring-offset-color: #FF5722
}

.focus\:ring-offset-deep-orange-600:focus {
  --tw-ring-offset-color: #F4511E
}

.focus\:ring-offset-deep-orange-700:focus {
  --tw-ring-offset-color: #E64A19
}

.focus\:ring-offset-deep-orange-800:focus {
  --tw-ring-offset-color: #D84315
}

.focus\:ring-offset-deep-orange-900:focus {
  --tw-ring-offset-color: #BF360C
}

.focus\:ring-offset-deep-orange:focus {
  --tw-ring-offset-color: #FF5722
}

.focus\:ring-offset-deep-orange-A100:focus {
  --tw-ring-offset-color: #FF9E80
}

.focus\:ring-offset-deep-orange-A200:focus {
  --tw-ring-offset-color: #FF6E40
}

.focus\:ring-offset-deep-orange-A400:focus {
  --tw-ring-offset-color: #FF3D00
}

.focus\:ring-offset-deep-orange-A700:focus {
  --tw-ring-offset-color: #DD2C00
}

.focus\:ring-offset-yellow-50:focus {
  --tw-ring-offset-color: #FFFDE7
}

.focus\:ring-offset-yellow-100:focus {
  --tw-ring-offset-color: #FFF9C4
}

.focus\:ring-offset-yellow-200:focus {
  --tw-ring-offset-color: #FFF59D
}

.focus\:ring-offset-yellow-300:focus {
  --tw-ring-offset-color: #FFF176
}

.focus\:ring-offset-yellow-400:focus {
  --tw-ring-offset-color: #FFEE58
}

.focus\:ring-offset-yellow-500:focus {
  --tw-ring-offset-color: #FFEB3B
}

.focus\:ring-offset-yellow-600:focus {
  --tw-ring-offset-color: #FDD835
}

.focus\:ring-offset-yellow-700:focus {
  --tw-ring-offset-color: #FBC02D
}

.focus\:ring-offset-yellow-800:focus {
  --tw-ring-offset-color: #F9A825
}

.focus\:ring-offset-yellow-900:focus {
  --tw-ring-offset-color: #F57F17
}

.focus\:ring-offset-yellow:focus {
  --tw-ring-offset-color: #FFEB3B
}

.focus\:ring-offset-yellow-A100:focus {
  --tw-ring-offset-color: #FFFF8D
}

.focus\:ring-offset-yellow-A200:focus {
  --tw-ring-offset-color: #FFFF00
}

.focus\:ring-offset-yellow-A400:focus {
  --tw-ring-offset-color: #FFEA00
}

.focus\:ring-offset-yellow-A700:focus {
  --tw-ring-offset-color: #FFD600
}

.focus\:ring-offset-green-50:focus {
  --tw-ring-offset-color: #E8F5E9
}

.focus\:ring-offset-green-100:focus {
  --tw-ring-offset-color: #C8E6C9
}

.focus\:ring-offset-green-200:focus {
  --tw-ring-offset-color: #A5D6A7
}

.focus\:ring-offset-green-300:focus {
  --tw-ring-offset-color: #81C784
}

.focus\:ring-offset-green-400:focus {
  --tw-ring-offset-color: #66BB6A
}

.focus\:ring-offset-green-500:focus {
  --tw-ring-offset-color: #4CAF50
}

.focus\:ring-offset-green-600:focus {
  --tw-ring-offset-color: #43A047
}

.focus\:ring-offset-green-700:focus {
  --tw-ring-offset-color: #388E3C
}

.focus\:ring-offset-green-800:focus {
  --tw-ring-offset-color: #2E7D32
}

.focus\:ring-offset-green-900:focus {
  --tw-ring-offset-color: #1B5E20
}

.focus\:ring-offset-green:focus {
  --tw-ring-offset-color: #4CAF50
}

.focus\:ring-offset-green-A100:focus {
  --tw-ring-offset-color: #B9F6CA
}

.focus\:ring-offset-green-A200:focus {
  --tw-ring-offset-color: #69F0AE
}

.focus\:ring-offset-green-A400:focus {
  --tw-ring-offset-color: #00E676
}

.focus\:ring-offset-green-A700:focus {
  --tw-ring-offset-color: #00C853
}

.focus\:ring-offset-light-green-50:focus {
  --tw-ring-offset-color: #F1F8E9
}

.focus\:ring-offset-light-green-100:focus {
  --tw-ring-offset-color: #DCEDC8
}

.focus\:ring-offset-light-green-200:focus {
  --tw-ring-offset-color: #C5E1A5
}

.focus\:ring-offset-light-green-300:focus {
  --tw-ring-offset-color: #AED581
}

.focus\:ring-offset-light-green-400:focus {
  --tw-ring-offset-color: #9CCC65
}

.focus\:ring-offset-light-green-500:focus {
  --tw-ring-offset-color: #8BC34A
}

.focus\:ring-offset-light-green-600:focus {
  --tw-ring-offset-color: #7CB342
}

.focus\:ring-offset-light-green-700:focus {
  --tw-ring-offset-color: #689F38
}

.focus\:ring-offset-light-green-800:focus {
  --tw-ring-offset-color: #558B2F
}

.focus\:ring-offset-light-green-900:focus {
  --tw-ring-offset-color: #33691E
}

.focus\:ring-offset-light-green:focus {
  --tw-ring-offset-color: #8BC34A
}

.focus\:ring-offset-light-green-A100:focus {
  --tw-ring-offset-color: #CCFF90
}

.focus\:ring-offset-light-green-A200:focus {
  --tw-ring-offset-color: #B2FF59
}

.focus\:ring-offset-light-green-A400:focus {
  --tw-ring-offset-color: #76FF03
}

.focus\:ring-offset-light-green-A700:focus {
  --tw-ring-offset-color: #64DD17
}

.focus\:ring-offset-teal-50:focus {
  --tw-ring-offset-color: #E0F2F1
}

.focus\:ring-offset-teal-100:focus {
  --tw-ring-offset-color: #B2DFDB
}

.focus\:ring-offset-teal-200:focus {
  --tw-ring-offset-color: #80CBC4
}

.focus\:ring-offset-teal-300:focus {
  --tw-ring-offset-color: #4DB6AC
}

.focus\:ring-offset-teal-400:focus {
  --tw-ring-offset-color: #26A69A
}

.focus\:ring-offset-teal-500:focus {
  --tw-ring-offset-color: #009688
}

.focus\:ring-offset-teal-600:focus {
  --tw-ring-offset-color: #00897B
}

.focus\:ring-offset-teal-700:focus {
  --tw-ring-offset-color: #00796B
}

.focus\:ring-offset-teal-800:focus {
  --tw-ring-offset-color: #00695C
}

.focus\:ring-offset-teal-900:focus {
  --tw-ring-offset-color: #004D40
}

.focus\:ring-offset-teal:focus {
  --tw-ring-offset-color: #009688
}

.focus\:ring-offset-teal-A100:focus {
  --tw-ring-offset-color: #A7FFEB
}

.focus\:ring-offset-teal-A200:focus {
  --tw-ring-offset-color: #64FFDA
}

.focus\:ring-offset-teal-A400:focus {
  --tw-ring-offset-color: #1DE9B6
}

.focus\:ring-offset-teal-A700:focus {
  --tw-ring-offset-color: #00BFA5
}

.focus\:ring-offset-blue-50:focus {
  --tw-ring-offset-color: #E3F2FD
}

.focus\:ring-offset-blue-100:focus {
  --tw-ring-offset-color: #BBDEFB
}

.focus\:ring-offset-blue-200:focus {
  --tw-ring-offset-color: #90CAF9
}

.focus\:ring-offset-blue-300:focus {
  --tw-ring-offset-color: #64B5F6
}

.focus\:ring-offset-blue-400:focus {
  --tw-ring-offset-color: #42A5F5
}

.focus\:ring-offset-blue-500:focus {
  --tw-ring-offset-color: #2196F3
}

.focus\:ring-offset-blue-600:focus {
  --tw-ring-offset-color: #1E88E5
}

.focus\:ring-offset-blue-700:focus {
  --tw-ring-offset-color: #1976D2
}

.focus\:ring-offset-blue-800:focus {
  --tw-ring-offset-color: #1565C0
}

.focus\:ring-offset-blue-900:focus {
  --tw-ring-offset-color: #0D47A1
}

.focus\:ring-offset-blue:focus {
  --tw-ring-offset-color: #2196F3
}

.focus\:ring-offset-blue-A100:focus {
  --tw-ring-offset-color: #82B1FF
}

.focus\:ring-offset-blue-A200:focus {
  --tw-ring-offset-color: #448AFF
}

.focus\:ring-offset-blue-A400:focus {
  --tw-ring-offset-color: #2979FF
}

.focus\:ring-offset-blue-A700:focus {
  --tw-ring-offset-color: #2962FF
}

.focus\:ring-offset-light-blue-50:focus {
  --tw-ring-offset-color: #E1F5FE
}

.focus\:ring-offset-light-blue-100:focus {
  --tw-ring-offset-color: #B3E5FC
}

.focus\:ring-offset-light-blue-200:focus {
  --tw-ring-offset-color: #81D4FA
}

.focus\:ring-offset-light-blue-300:focus {
  --tw-ring-offset-color: #4FC3F7
}

.focus\:ring-offset-light-blue-400:focus {
  --tw-ring-offset-color: #29B6F6
}

.focus\:ring-offset-light-blue-500:focus {
  --tw-ring-offset-color: #03A9F4
}

.focus\:ring-offset-light-blue-600:focus {
  --tw-ring-offset-color: #039BE5
}

.focus\:ring-offset-light-blue-700:focus {
  --tw-ring-offset-color: #0288D1
}

.focus\:ring-offset-light-blue-800:focus {
  --tw-ring-offset-color: #0277BD
}

.focus\:ring-offset-light-blue-900:focus {
  --tw-ring-offset-color: #01579B
}

.focus\:ring-offset-light-blue:focus {
  --tw-ring-offset-color: #03A9F4
}

.focus\:ring-offset-light-blue-A100:focus {
  --tw-ring-offset-color: #80D8FF
}

.focus\:ring-offset-light-blue-A200:focus {
  --tw-ring-offset-color: #40C4FF
}

.focus\:ring-offset-light-blue-A400:focus {
  --tw-ring-offset-color: #00B0FF
}

.focus\:ring-offset-light-blue-A700:focus {
  --tw-ring-offset-color: #0091EA
}

.focus\:ring-offset-indigo-50:focus {
  --tw-ring-offset-color: #E8EAF6
}

.focus\:ring-offset-indigo-100:focus {
  --tw-ring-offset-color: #C5CAE9
}

.focus\:ring-offset-indigo-200:focus {
  --tw-ring-offset-color: #9FA8DA
}

.focus\:ring-offset-indigo-300:focus {
  --tw-ring-offset-color: #7986CB
}

.focus\:ring-offset-indigo-400:focus {
  --tw-ring-offset-color: #5C6BC0
}

.focus\:ring-offset-indigo-500:focus {
  --tw-ring-offset-color: #3F51B5
}

.focus\:ring-offset-indigo-600:focus {
  --tw-ring-offset-color: #3949AB
}

.focus\:ring-offset-indigo-700:focus {
  --tw-ring-offset-color: #303F9F
}

.focus\:ring-offset-indigo-800:focus {
  --tw-ring-offset-color: #283593
}

.focus\:ring-offset-indigo-900:focus {
  --tw-ring-offset-color: #1A237E
}

.focus\:ring-offset-indigo:focus {
  --tw-ring-offset-color: #3F51B5
}

.focus\:ring-offset-indigo-A100:focus {
  --tw-ring-offset-color: #8C9EFF
}

.focus\:ring-offset-indigo-A200:focus {
  --tw-ring-offset-color: #536DFE
}

.focus\:ring-offset-indigo-A400:focus {
  --tw-ring-offset-color: #3D5AFE
}

.focus\:ring-offset-indigo-A700:focus {
  --tw-ring-offset-color: #304FFE
}

.focus\:ring-offset-purple-50:focus {
  --tw-ring-offset-color: #F3E5F5
}

.focus\:ring-offset-purple-100:focus {
  --tw-ring-offset-color: #E1BEE7
}

.focus\:ring-offset-purple-200:focus {
  --tw-ring-offset-color: #CE93D8
}

.focus\:ring-offset-purple-300:focus {
  --tw-ring-offset-color: #BA68C8
}

.focus\:ring-offset-purple-400:focus {
  --tw-ring-offset-color: #AB47BC
}

.focus\:ring-offset-purple-500:focus {
  --tw-ring-offset-color: #9C27B0
}

.focus\:ring-offset-purple-600:focus {
  --tw-ring-offset-color: #8E24AA
}

.focus\:ring-offset-purple-700:focus {
  --tw-ring-offset-color: #7B1FA2
}

.focus\:ring-offset-purple-800:focus {
  --tw-ring-offset-color: #6A1B9A
}

.focus\:ring-offset-purple-900:focus {
  --tw-ring-offset-color: #4A148C
}

.focus\:ring-offset-purple:focus {
  --tw-ring-offset-color: #9C27B0
}

.focus\:ring-offset-purple-A100:focus {
  --tw-ring-offset-color: #EA80FC
}

.focus\:ring-offset-purple-A200:focus {
  --tw-ring-offset-color: #E040FB
}

.focus\:ring-offset-purple-A400:focus {
  --tw-ring-offset-color: #D500F9
}

.focus\:ring-offset-purple-A700:focus {
  --tw-ring-offset-color: #AA00FF
}

.focus\:ring-offset-deep-purple-50:focus {
  --tw-ring-offset-color: #EDE7F6
}

.focus\:ring-offset-deep-purple-100:focus {
  --tw-ring-offset-color: #D1C4E9
}

.focus\:ring-offset-deep-purple-200:focus {
  --tw-ring-offset-color: #B39DDB
}

.focus\:ring-offset-deep-purple-300:focus {
  --tw-ring-offset-color: #9575CD
}

.focus\:ring-offset-deep-purple-400:focus {
  --tw-ring-offset-color: #7E57C2
}

.focus\:ring-offset-deep-purple-500:focus {
  --tw-ring-offset-color: #673AB7
}

.focus\:ring-offset-deep-purple-600:focus {
  --tw-ring-offset-color: #5E35B1
}

.focus\:ring-offset-deep-purple-700:focus {
  --tw-ring-offset-color: #512DA8
}

.focus\:ring-offset-deep-purple-800:focus {
  --tw-ring-offset-color: #4527A0
}

.focus\:ring-offset-deep-purple-900:focus {
  --tw-ring-offset-color: #311B92
}

.focus\:ring-offset-deep-purple:focus {
  --tw-ring-offset-color: #673AB7
}

.focus\:ring-offset-deep-purple-A100:focus {
  --tw-ring-offset-color: #B388FF
}

.focus\:ring-offset-deep-purple-A200:focus {
  --tw-ring-offset-color: #7C4DFF
}

.focus\:ring-offset-deep-purple-A400:focus {
  --tw-ring-offset-color: #651FFF
}

.focus\:ring-offset-deep-purple-A700:focus {
  --tw-ring-offset-color: #6200EA
}

.focus\:ring-offset-pink-50:focus {
  --tw-ring-offset-color: #FCE4EC
}

.focus\:ring-offset-pink-100:focus {
  --tw-ring-offset-color: #F8BBD0
}

.focus\:ring-offset-pink-200:focus {
  --tw-ring-offset-color: #F48FB1
}

.focus\:ring-offset-pink-300:focus {
  --tw-ring-offset-color: #F06292
}

.focus\:ring-offset-pink-400:focus {
  --tw-ring-offset-color: #EC407A
}

.focus\:ring-offset-pink-500:focus {
  --tw-ring-offset-color: #E91E63
}

.focus\:ring-offset-pink-600:focus {
  --tw-ring-offset-color: #D81B60
}

.focus\:ring-offset-pink-700:focus {
  --tw-ring-offset-color: #C2185B
}

.focus\:ring-offset-pink-800:focus {
  --tw-ring-offset-color: #AD1457
}

.focus\:ring-offset-pink-900:focus {
  --tw-ring-offset-color: #880E4F
}

.focus\:ring-offset-pink:focus {
  --tw-ring-offset-color: #E91E63
}

.focus\:ring-offset-pink-A100:focus {
  --tw-ring-offset-color: #FF80AB
}

.focus\:ring-offset-pink-A200:focus {
  --tw-ring-offset-color: #FF4081
}

.focus\:ring-offset-pink-A400:focus {
  --tw-ring-offset-color: #F50057
}

.focus\:ring-offset-pink-A700:focus {
  --tw-ring-offset-color: #C51162
}

.focus\:ring-offset-lime-50:focus {
  --tw-ring-offset-color: #F9FBE7
}

.focus\:ring-offset-lime-100:focus {
  --tw-ring-offset-color: #F0F4C3
}

.focus\:ring-offset-lime-200:focus {
  --tw-ring-offset-color: #E6EE9C
}

.focus\:ring-offset-lime-300:focus {
  --tw-ring-offset-color: #DCE775
}

.focus\:ring-offset-lime-400:focus {
  --tw-ring-offset-color: #D4E157
}

.focus\:ring-offset-lime-500:focus {
  --tw-ring-offset-color: #CDDC39
}

.focus\:ring-offset-lime-600:focus {
  --tw-ring-offset-color: #C0CA33
}

.focus\:ring-offset-lime-700:focus {
  --tw-ring-offset-color: #AFB42B
}

.focus\:ring-offset-lime-800:focus {
  --tw-ring-offset-color: #9E9D24
}

.focus\:ring-offset-lime-900:focus {
  --tw-ring-offset-color: #827717
}

.focus\:ring-offset-lime:focus {
  --tw-ring-offset-color: #CDDC39
}

.focus\:ring-offset-lime-A100:focus {
  --tw-ring-offset-color: #F4FF81
}

.focus\:ring-offset-lime-A200:focus {
  --tw-ring-offset-color: #EEFF41
}

.focus\:ring-offset-lime-A400:focus {
  --tw-ring-offset-color: #C6FF00
}

.focus\:ring-offset-lime-A700:focus {
  --tw-ring-offset-color: #AEEA00
}

.focus\:ring-offset-amber-50:focus {
  --tw-ring-offset-color: #FFF8E1
}

.focus\:ring-offset-amber-100:focus {
  --tw-ring-offset-color: #FFECB3
}

.focus\:ring-offset-amber-200:focus {
  --tw-ring-offset-color: #FFE082
}

.focus\:ring-offset-amber-300:focus {
  --tw-ring-offset-color: #FFD54F
}

.focus\:ring-offset-amber-400:focus {
  --tw-ring-offset-color: #FFCA28
}

.focus\:ring-offset-amber-500:focus {
  --tw-ring-offset-color: #FFC107
}

.focus\:ring-offset-amber-600:focus {
  --tw-ring-offset-color: #FFB300
}

.focus\:ring-offset-amber-700:focus {
  --tw-ring-offset-color: #FFA000
}

.focus\:ring-offset-amber-800:focus {
  --tw-ring-offset-color: #FF8F00
}

.focus\:ring-offset-amber-900:focus {
  --tw-ring-offset-color: #FF6F00
}

.focus\:ring-offset-amber:focus {
  --tw-ring-offset-color: #FFC107
}

.focus\:ring-offset-amber-A100:focus {
  --tw-ring-offset-color: #FFE57F
}

.focus\:ring-offset-amber-A200:focus {
  --tw-ring-offset-color: #FFD740
}

.focus\:ring-offset-amber-A400:focus {
  --tw-ring-offset-color: #FFC400
}

.focus\:ring-offset-amber-A700:focus {
  --tw-ring-offset-color: #FFAB00
}

.focus\:ring-offset-brown-50:focus {
  --tw-ring-offset-color: #EFEBE9
}

.focus\:ring-offset-brown-100:focus {
  --tw-ring-offset-color: #D7CCC8
}

.focus\:ring-offset-brown-200:focus {
  --tw-ring-offset-color: #BCAAA4
}

.focus\:ring-offset-brown-300:focus {
  --tw-ring-offset-color: #A1887F
}

.focus\:ring-offset-brown-400:focus {
  --tw-ring-offset-color: #8D6E63
}

.focus\:ring-offset-brown-500:focus {
  --tw-ring-offset-color: #795548
}

.focus\:ring-offset-brown-600:focus {
  --tw-ring-offset-color: #6D4C41
}

.focus\:ring-offset-brown-700:focus {
  --tw-ring-offset-color: #5D4037
}

.focus\:ring-offset-brown-800:focus {
  --tw-ring-offset-color: #4E342E
}

.focus\:ring-offset-brown-900:focus {
  --tw-ring-offset-color: #3E2723
}

.focus\:ring-offset-brown:focus {
  --tw-ring-offset-color: #795548
}

.focus\:ring-offset-brown-A100:focus {
  --tw-ring-offset-color: #D7CCC8
}

.focus\:ring-offset-brown-A200:focus {
  --tw-ring-offset-color: #BCAAA4
}

.focus\:ring-offset-brown-A400:focus {
  --tw-ring-offset-color: #8D6E63
}

.focus\:ring-offset-brown-A700:focus {
  --tw-ring-offset-color: #5D4037
}

.focus\:ring-offset-blue-gray-50:focus {
  --tw-ring-offset-color: #ECEFF1
}

.focus\:ring-offset-blue-gray-100:focus {
  --tw-ring-offset-color: #CFD8DC
}

.focus\:ring-offset-blue-gray-200:focus {
  --tw-ring-offset-color: #B0BEC5
}

.focus\:ring-offset-blue-gray-300:focus {
  --tw-ring-offset-color: #90A4AE
}

.focus\:ring-offset-blue-gray-400:focus {
  --tw-ring-offset-color: #78909C
}

.focus\:ring-offset-blue-gray-500:focus {
  --tw-ring-offset-color: #607D8B
}

.focus\:ring-offset-blue-gray-600:focus {
  --tw-ring-offset-color: #546E7A
}

.focus\:ring-offset-blue-gray-700:focus {
  --tw-ring-offset-color: #455A64
}

.focus\:ring-offset-blue-gray-800:focus {
  --tw-ring-offset-color: #37474F
}

.focus\:ring-offset-blue-gray-900:focus {
  --tw-ring-offset-color: #263238
}

.focus\:ring-offset-blue-gray:focus {
  --tw-ring-offset-color: #607D8B
}

.focus\:ring-offset-blue-gray-A100:focus {
  --tw-ring-offset-color: #CFD8DC
}

.focus\:ring-offset-blue-gray-A200:focus {
  --tw-ring-offset-color: #B0BEC5
}

.focus\:ring-offset-blue-gray-A400:focus {
  --tw-ring-offset-color: #78909C
}

.focus\:ring-offset-blue-gray-A700:focus {
  --tw-ring-offset-color: #455A64
}

.focus\:ring-offset-cyan-50:focus {
  --tw-ring-offset-color: #E0F7FA
}

.focus\:ring-offset-cyan-100:focus {
  --tw-ring-offset-color: #B2EBF2
}

.focus\:ring-offset-cyan-200:focus {
  --tw-ring-offset-color: #80DEEA
}

.focus\:ring-offset-cyan-300:focus {
  --tw-ring-offset-color: #4DD0E1
}

.focus\:ring-offset-cyan-400:focus {
  --tw-ring-offset-color: #26C6DA
}

.focus\:ring-offset-cyan-500:focus {
  --tw-ring-offset-color: #00BCD4
}

.focus\:ring-offset-cyan-600:focus {
  --tw-ring-offset-color: #00ACC1
}

.focus\:ring-offset-cyan-700:focus {
  --tw-ring-offset-color: #0097A7
}

.focus\:ring-offset-cyan-800:focus {
  --tw-ring-offset-color: #00838F
}

.focus\:ring-offset-cyan-900:focus {
  --tw-ring-offset-color: #006064
}

.focus\:ring-offset-cyan:focus {
  --tw-ring-offset-color: #00BCD4
}

.focus\:ring-offset-cyan-A100:focus {
  --tw-ring-offset-color: #84FFFF
}

.focus\:ring-offset-cyan-A200:focus {
  --tw-ring-offset-color: #18FFFF
}

.focus\:ring-offset-cyan-A400:focus {
  --tw-ring-offset-color: #00E5FF
}

.focus\:ring-offset-cyan-A700:focus {
  --tw-ring-offset-color: #00B8D4
}

.ring-offset-0 {
  --tw-ring-offset-width: 0px
}

.ring-offset-1 {
  --tw-ring-offset-width: 1px
}

.ring-offset-2 {
  --tw-ring-offset-width: 2px
}

.ring-offset-4 {
  --tw-ring-offset-width: 4px
}

.ring-offset-8 {
  --tw-ring-offset-width: 8px
}

.focus-within\:ring-offset-0:focus-within {
  --tw-ring-offset-width: 0px
}

.focus-within\:ring-offset-1:focus-within {
  --tw-ring-offset-width: 1px
}

.focus-within\:ring-offset-2:focus-within {
  --tw-ring-offset-width: 2px
}

.focus-within\:ring-offset-4:focus-within {
  --tw-ring-offset-width: 4px
}

.focus-within\:ring-offset-8:focus-within {
  --tw-ring-offset-width: 8px
}

.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px
}

.focus\:ring-offset-1:focus {
  --tw-ring-offset-width: 1px
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px
}

.focus\:ring-offset-4:focus {
  --tw-ring-offset-width: 4px
}

.focus\:ring-offset-8:focus {
  --tw-ring-offset-width: 8px
}

.ring-transparent {
  --tw-ring-color: transparent
}

.ring-current {
  --tw-ring-color: currentColor
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
}

.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
}

.ring-grey-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
}

.ring-grey-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
}

.ring-grey-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
}

.ring-grey-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
}

.ring-grey-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
}

.ring-grey-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
}

.ring-grey-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
}

.ring-grey-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
}

.ring-grey-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
}

.ring-grey-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
}

.ring-grey {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
}

.ring-grey-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
}

.ring-grey-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
}

.ring-grey-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
}

.ring-grey-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
}

.ring-gray-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
}

.ring-gray-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
}

.ring-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
}

.ring-gray-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
}

.ring-gray-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
}

.ring-gray-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
}

.ring-gray-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
}

.ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
}

.ring-gray-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
}

.ring-gray {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
}

.ring-gray-hover {
  --tw-ring-color: rgba(0, 0, 0, 0.04)
}

.ring-gray-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
}

.ring-gray-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
}

.ring-gray-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
}

.ring-gray-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
}

.ring-red-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
}

.ring-red-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
}

.ring-red-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
}

.ring-red-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
}

.ring-red-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
}

.ring-red-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
}

.ring-red-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
}

.ring-red-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
}

.ring-red-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
}

.ring-red-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
}

.ring-red {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
}

.ring-red-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
}

.ring-red-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
}

.ring-red-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
}

.ring-red-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
}

.ring-orange-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
}

.ring-orange-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
}

.ring-orange-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
}

.ring-orange-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
}

.ring-orange-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
}

.ring-orange-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
}

.ring-orange-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
}

.ring-orange-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
}

.ring-orange-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
}

.ring-orange-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
}

.ring-orange {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
}

.ring-orange-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
}

.ring-orange-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
}

.ring-orange-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
}

.ring-orange-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
}

.ring-deep-orange-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
}

.ring-deep-orange-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
}

.ring-deep-orange-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
}

.ring-deep-orange-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
}

.ring-deep-orange-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
}

.ring-deep-orange-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
}

.ring-deep-orange-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
}

.ring-deep-orange-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
}

.ring-deep-orange-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
}

.ring-deep-orange-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
}

.ring-deep-orange {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
}

.ring-deep-orange-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
}

.ring-deep-orange-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
}

.ring-deep-orange-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
}

.ring-deep-orange-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
}

.ring-yellow-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
}

.ring-yellow-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
}

.ring-yellow-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
}

.ring-yellow-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
}

.ring-yellow-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
}

.ring-yellow-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
}

.ring-yellow-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
}

.ring-yellow-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
}

.ring-yellow-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
}

.ring-yellow-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
}

.ring-yellow {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
}

.ring-yellow-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
}

.ring-yellow-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
}

.ring-yellow-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
}

.ring-yellow-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
}

.ring-green-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
}

.ring-green-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
}

.ring-green-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
}

.ring-green-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
}

.ring-green-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
}

.ring-green-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
}

.ring-green-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
}

.ring-green-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
}

.ring-green-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
}

.ring-green-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
}

.ring-green {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
}

.ring-green-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
}

.ring-green-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
}

.ring-green-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
}

.ring-green-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
}

.ring-light-green-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
}

.ring-light-green-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
}

.ring-light-green-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
}

.ring-light-green-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
}

.ring-light-green-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
}

.ring-light-green-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
}

.ring-light-green-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
}

.ring-light-green-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
}

.ring-light-green-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
}

.ring-light-green-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
}

.ring-light-green {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
}

.ring-light-green-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
}

.ring-light-green-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
}

.ring-light-green-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
}

.ring-light-green-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
}

.ring-teal-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
}

.ring-teal-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
}

.ring-teal-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
}

.ring-teal-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
}

.ring-teal-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
}

.ring-teal-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
}

.ring-teal-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
}

.ring-teal-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
}

.ring-teal-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
}

.ring-teal-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
}

.ring-teal {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
}

.ring-teal-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
}

.ring-teal-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
}

.ring-teal-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
}

.ring-teal-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
}

.ring-blue-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
}

.ring-blue-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
}

.ring-blue-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
}

.ring-blue-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
}

.ring-blue-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
}

.ring-blue-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
}

.ring-blue-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
}

.ring-blue-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
}

.ring-blue-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
}

.ring-blue-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
}

.ring-blue {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
}

.ring-blue-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
}

.ring-blue-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
}

.ring-blue-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
}

.ring-blue-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
}

.ring-light-blue-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
}

.ring-light-blue-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
}

.ring-light-blue-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
}

.ring-light-blue-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
}

.ring-light-blue-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
}

.ring-light-blue-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
}

.ring-light-blue-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
}

.ring-light-blue-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
}

.ring-light-blue-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
}

.ring-light-blue-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
}

.ring-light-blue {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
}

.ring-light-blue-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
}

.ring-light-blue-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
}

.ring-light-blue-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
}

.ring-light-blue-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
}

.ring-indigo-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
}

.ring-indigo-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
}

.ring-indigo-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
}

.ring-indigo-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
}

.ring-indigo-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
}

.ring-indigo-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
}

.ring-indigo-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
}

.ring-indigo-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
}

.ring-indigo-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
}

.ring-indigo-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
}

.ring-indigo {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
}

.ring-indigo-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
}

.ring-indigo-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
}

.ring-indigo-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
}

.ring-indigo-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
}

.ring-purple-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
}

.ring-purple-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
}

.ring-purple-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
}

.ring-purple-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
}

.ring-purple-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
}

.ring-purple-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
}

.ring-purple-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
}

.ring-purple-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
}

.ring-purple-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
}

.ring-purple-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
}

.ring-purple {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
}

.ring-purple-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
}

.ring-purple-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
}

.ring-purple-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
}

.ring-purple-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
}

.ring-deep-purple-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
}

.ring-deep-purple-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
}

.ring-deep-purple-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
}

.ring-deep-purple-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
}

.ring-deep-purple-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
}

.ring-deep-purple-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
}

.ring-deep-purple-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
}

.ring-deep-purple-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
}

.ring-deep-purple-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
}

.ring-deep-purple-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
}

.ring-deep-purple {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
}

.ring-deep-purple-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
}

.ring-deep-purple-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
}

.ring-deep-purple-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
}

.ring-deep-purple-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
}

.ring-pink-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
}

.ring-pink-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
}

.ring-pink-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
}

.ring-pink-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
}

.ring-pink-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
}

.ring-pink-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
}

.ring-pink-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
}

.ring-pink-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
}

.ring-pink-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
}

.ring-pink-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
}

.ring-pink {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
}

.ring-pink-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
}

.ring-pink-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
}

.ring-pink-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
}

.ring-pink-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
}

.ring-lime-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
}

.ring-lime-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
}

.ring-lime-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
}

.ring-lime-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
}

.ring-lime-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
}

.ring-lime-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
}

.ring-lime-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
}

.ring-lime-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
}

.ring-lime-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
}

.ring-lime-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
}

.ring-lime {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
}

.ring-lime-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
}

.ring-lime-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
}

.ring-lime-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
}

.ring-lime-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
}

.ring-amber-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
}

.ring-amber-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
}

.ring-amber-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
}

.ring-amber-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
}

.ring-amber-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
}

.ring-amber-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
}

.ring-amber-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
}

.ring-amber-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
}

.ring-amber-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
}

.ring-amber-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
}

.ring-amber {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
}

.ring-amber-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
}

.ring-amber-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
}

.ring-amber-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
}

.ring-amber-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
}

.ring-brown-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
}

.ring-brown-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
}

.ring-brown-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
}

.ring-brown-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
}

.ring-brown-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
}

.ring-brown-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
}

.ring-brown-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
}

.ring-brown-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
}

.ring-brown-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
}

.ring-brown-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
}

.ring-brown {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
}

.ring-brown-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
}

.ring-brown-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
}

.ring-brown-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
}

.ring-brown-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
}

.ring-blue-gray-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
}

.ring-blue-gray-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
}

.ring-blue-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
}

.ring-blue-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
}

.ring-blue-gray-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
}

.ring-blue-gray-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
}

.ring-blue-gray-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
}

.ring-blue-gray-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
}

.ring-blue-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
}

.ring-blue-gray-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
}

.ring-blue-gray {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
}

.ring-blue-gray-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
}

.ring-blue-gray-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
}

.ring-blue-gray-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
}

.ring-blue-gray-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
}

.ring-cyan-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
}

.ring-cyan-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
}

.ring-cyan-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
}

.ring-cyan-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
}

.ring-cyan-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
}

.ring-cyan-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
}

.ring-cyan-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
}

.ring-cyan-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
}

.ring-cyan-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
}

.ring-cyan-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
}

.ring-cyan {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
}

.ring-cyan-A100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
}

.ring-cyan-A200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
}

.ring-cyan-A400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
}

.ring-cyan-A700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
}

.focus-within\:ring-transparent:focus-within {
  --tw-ring-color: transparent
}

.focus-within\:ring-current:focus-within {
  --tw-ring-color: currentColor
}

.focus-within\:ring-black:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
}

.focus-within\:ring-white:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-grey-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
}

.focus-within\:ring-grey-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
}

.focus-within\:ring-grey-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
}

.focus-within\:ring-grey-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
}

.focus-within\:ring-grey-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
}

.focus-within\:ring-grey-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
}

.focus-within\:ring-grey-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
}

.focus-within\:ring-grey-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
}

.focus-within\:ring-grey-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
}

.focus-within\:ring-grey-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
}

.focus-within\:ring-grey:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
}

.focus-within\:ring-grey-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
}

.focus-within\:ring-grey-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
}

.focus-within\:ring-grey-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
}

.focus-within\:ring-grey-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
}

.focus-within\:ring-gray-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
}

.focus-within\:ring-gray-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
}

.focus-within\:ring-gray-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
}

.focus-within\:ring-gray-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
}

.focus-within\:ring-gray-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
}

.focus-within\:ring-gray-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
}

.focus-within\:ring-gray-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
}

.focus-within\:ring-gray-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
}

.focus-within\:ring-gray-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
}

.focus-within\:ring-gray-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
}

.focus-within\:ring-gray:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
}

.focus-within\:ring-gray-hover:focus-within {
  --tw-ring-color: rgba(0, 0, 0, 0.04)
}

.focus-within\:ring-gray-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
}

.focus-within\:ring-gray-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
}

.focus-within\:ring-gray-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
}

.focus-within\:ring-gray-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
}

.focus-within\:ring-red-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
}

.focus-within\:ring-red-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
}

.focus-within\:ring-red-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
}

.focus-within\:ring-red-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
}

.focus-within\:ring-red-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
}

.focus-within\:ring-red-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
}

.focus-within\:ring-red-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
}

.focus-within\:ring-red-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
}

.focus-within\:ring-red-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
}

.focus-within\:ring-red-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
}

.focus-within\:ring-red:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
}

.focus-within\:ring-red-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
}

.focus-within\:ring-red-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
}

.focus-within\:ring-red-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
}

.focus-within\:ring-red-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-orange-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
}

.focus-within\:ring-orange-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
}

.focus-within\:ring-orange-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
}

.focus-within\:ring-orange-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
}

.focus-within\:ring-orange-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
}

.focus-within\:ring-orange-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-orange-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-orange-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-orange-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-orange-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-orange:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-orange-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
}

.focus-within\:ring-orange-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
}

.focus-within\:ring-orange-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-orange-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-orange-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-orange-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-orange-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-orange-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-orange-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-orange-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-orange-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-orange-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-orange-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-orange-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-orange:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-orange-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-orange-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-orange-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-orange-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-yellow-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
}

.focus-within\:ring-yellow-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
}

.focus-within\:ring-yellow-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
}

.focus-within\:ring-yellow-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
}

.focus-within\:ring-yellow-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
}

.focus-within\:ring-yellow-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
}

.focus-within\:ring-yellow-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
}

.focus-within\:ring-yellow-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
}

.focus-within\:ring-yellow-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
}

.focus-within\:ring-yellow-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
}

.focus-within\:ring-yellow:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
}

.focus-within\:ring-yellow-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
}

.focus-within\:ring-yellow-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-yellow-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-yellow-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-green-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
}

.focus-within\:ring-green-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
}

.focus-within\:ring-green-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
}

.focus-within\:ring-green-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
}

.focus-within\:ring-green-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
}

.focus-within\:ring-green-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
}

.focus-within\:ring-green-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
}

.focus-within\:ring-green-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
}

.focus-within\:ring-green-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
}

.focus-within\:ring-green-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
}

.focus-within\:ring-green:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
}

.focus-within\:ring-green-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
}

.focus-within\:ring-green-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
}

.focus-within\:ring-green-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
}

.focus-within\:ring-green-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
}

.focus-within\:ring-light-green-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
}

.focus-within\:ring-light-green-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
}

.focus-within\:ring-light-green-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
}

.focus-within\:ring-light-green-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
}

.focus-within\:ring-light-green-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
}

.focus-within\:ring-light-green-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
}

.focus-within\:ring-light-green-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
}

.focus-within\:ring-light-green-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
}

.focus-within\:ring-light-green-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
}

.focus-within\:ring-light-green-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
}

.focus-within\:ring-light-green:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
}

.focus-within\:ring-light-green-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
}

.focus-within\:ring-light-green-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
}

.focus-within\:ring-light-green-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
}

.focus-within\:ring-light-green-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
}

.focus-within\:ring-teal-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
}

.focus-within\:ring-teal-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
}

.focus-within\:ring-teal-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
}

.focus-within\:ring-teal-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
}

.focus-within\:ring-teal-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
}

.focus-within\:ring-teal-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
}

.focus-within\:ring-teal-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
}

.focus-within\:ring-teal-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
}

.focus-within\:ring-teal-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
}

.focus-within\:ring-teal-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
}

.focus-within\:ring-teal:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
}

.focus-within\:ring-teal-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
}

.focus-within\:ring-teal-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
}

.focus-within\:ring-teal-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
}

.focus-within\:ring-teal-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
}

.focus-within\:ring-blue:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-light-blue-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
}

.focus-within\:ring-light-blue-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
}

.focus-within\:ring-light-blue-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
}

.focus-within\:ring-light-blue-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
}

.focus-within\:ring-light-blue-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
}

.focus-within\:ring-light-blue-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
}

.focus-within\:ring-light-blue-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
}

.focus-within\:ring-light-blue-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
}

.focus-within\:ring-light-blue-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
}

.focus-within\:ring-light-blue-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
}

.focus-within\:ring-light-blue:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
}

.focus-within\:ring-light-blue-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-light-blue-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-light-blue-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-light-blue-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
}

.focus-within\:ring-purple-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
}

.focus-within\:ring-purple-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
}

.focus-within\:ring-purple-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
}

.focus-within\:ring-purple-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
}

.focus-within\:ring-purple-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
}

.focus-within\:ring-purple-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
}

.focus-within\:ring-purple-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
}

.focus-within\:ring-purple-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
}

.focus-within\:ring-purple-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
}

.focus-within\:ring-purple-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
}

.focus-within\:ring-purple:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
}

.focus-within\:ring-purple-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
}

.focus-within\:ring-purple-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
}

.focus-within\:ring-purple-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
}

.focus-within\:ring-purple-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-purple-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-purple-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-purple-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-purple-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-purple-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-purple-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-purple-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-purple-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-purple-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-purple-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-purple:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-purple-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-purple-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-purple-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-deep-purple-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
}

.focus-within\:ring-pink-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
}

.focus-within\:ring-pink-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
}

.focus-within\:ring-pink-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
}

.focus-within\:ring-pink-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
}

.focus-within\:ring-pink-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
}

.focus-within\:ring-pink-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
}

.focus-within\:ring-pink-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
}

.focus-within\:ring-pink-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
}

.focus-within\:ring-pink-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
}

.focus-within\:ring-pink-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
}

.focus-within\:ring-pink:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
}

.focus-within\:ring-pink-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
}

.focus-within\:ring-pink-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
}

.focus-within\:ring-pink-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
}

.focus-within\:ring-pink-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
}

.focus-within\:ring-lime-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
}

.focus-within\:ring-lime-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
}

.focus-within\:ring-lime-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
}

.focus-within\:ring-lime-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
}

.focus-within\:ring-lime-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
}

.focus-within\:ring-lime-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
}

.focus-within\:ring-lime-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
}

.focus-within\:ring-lime-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
}

.focus-within\:ring-lime-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
}

.focus-within\:ring-lime-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
}

.focus-within\:ring-lime:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
}

.focus-within\:ring-lime-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
}

.focus-within\:ring-lime-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
}

.focus-within\:ring-lime-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-lime-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-amber-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
}

.focus-within\:ring-amber-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
}

.focus-within\:ring-amber-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
}

.focus-within\:ring-amber-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
}

.focus-within\:ring-amber-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
}

.focus-within\:ring-amber-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
}

.focus-within\:ring-amber-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-amber-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-amber-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-amber-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-amber:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
}

.focus-within\:ring-amber-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
}

.focus-within\:ring-amber-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
}

.focus-within\:ring-amber-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-amber-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-brown-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
}

.focus-within\:ring-brown-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
}

.focus-within\:ring-brown-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
}

.focus-within\:ring-brown-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
}

.focus-within\:ring-brown-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
}

.focus-within\:ring-brown-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
}

.focus-within\:ring-brown-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
}

.focus-within\:ring-brown-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
}

.focus-within\:ring-brown-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
}

.focus-within\:ring-brown-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
}

.focus-within\:ring-brown:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
}

.focus-within\:ring-brown-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
}

.focus-within\:ring-brown-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
}

.focus-within\:ring-brown-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
}

.focus-within\:ring-brown-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-gray-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-gray-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-gray-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-gray-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-gray-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-gray-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-gray-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-gray-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-gray-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-gray-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-gray:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-gray-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-gray-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-gray-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
}

.focus-within\:ring-blue-gray-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan-A100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan-A200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan-A400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan-A700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
}

.focus\:ring-transparent:focus {
  --tw-ring-color: transparent
}

.focus\:ring-current:focus {
  --tw-ring-color: currentColor
}

.focus\:ring-black:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
}

.focus\:ring-grey-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
}

.focus\:ring-grey-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
}

.focus\:ring-grey-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
}

.focus\:ring-grey-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
}

.focus\:ring-grey-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
}

.focus\:ring-grey-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
}

.focus\:ring-grey-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
}

.focus\:ring-grey-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
}

.focus\:ring-grey-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
}

.focus\:ring-grey-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
}

.focus\:ring-grey:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
}

.focus\:ring-grey-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
}

.focus\:ring-grey-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
}

.focus\:ring-grey-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
}

.focus\:ring-grey-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
}

.focus\:ring-gray-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
}

.focus\:ring-gray-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
}

.focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
}

.focus\:ring-gray-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
}

.focus\:ring-gray-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
}

.focus\:ring-gray-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
}

.focus\:ring-gray-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
}

.focus\:ring-gray-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
}

.focus\:ring-gray-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
}

.focus\:ring-gray:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
}

.focus\:ring-gray-hover:focus {
  --tw-ring-color: rgba(0, 0, 0, 0.04)
}

.focus\:ring-gray-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
}

.focus\:ring-gray-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
}

.focus\:ring-gray-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
}

.focus\:ring-gray-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
}

.focus\:ring-red-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
}

.focus\:ring-red-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
}

.focus\:ring-red-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
}

.focus\:ring-red-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
}

.focus\:ring-red-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
}

.focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
}

.focus\:ring-red-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
}

.focus\:ring-red-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
}

.focus\:ring-red-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
}

.focus\:ring-red-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
}

.focus\:ring-red:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
}

.focus\:ring-red-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
}

.focus\:ring-red-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
}

.focus\:ring-red-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
}

.focus\:ring-red-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
}

.focus\:ring-orange-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
}

.focus\:ring-orange-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
}

.focus\:ring-orange-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
}

.focus\:ring-orange-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
}

.focus\:ring-orange-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
}

.focus\:ring-orange-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
}

.focus\:ring-orange-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
}

.focus\:ring-orange-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
}

.focus\:ring-orange-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
}

.focus\:ring-orange-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
}

.focus\:ring-orange:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
}

.focus\:ring-orange-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
}

.focus\:ring-orange-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
}

.focus\:ring-orange-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
}

.focus\:ring-orange-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
}

.focus\:ring-deep-orange-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
}

.focus\:ring-deep-orange-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
}

.focus\:ring-deep-orange-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
}

.focus\:ring-deep-orange-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
}

.focus\:ring-deep-orange-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
}

.focus\:ring-deep-orange-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
}

.focus\:ring-deep-orange-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
}

.focus\:ring-deep-orange-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
}

.focus\:ring-deep-orange-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
}

.focus\:ring-deep-orange-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
}

.focus\:ring-deep-orange:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
}

.focus\:ring-deep-orange-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
}

.focus\:ring-deep-orange-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
}

.focus\:ring-deep-orange-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
}

.focus\:ring-deep-orange-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
}

.focus\:ring-yellow-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
}

.focus\:ring-yellow-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
}

.focus\:ring-yellow-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
}

.focus\:ring-yellow-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
}

.focus\:ring-yellow-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
}

.focus\:ring-yellow-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
}

.focus\:ring-yellow-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
}

.focus\:ring-yellow-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
}

.focus\:ring-yellow-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
}

.focus\:ring-yellow-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
}

.focus\:ring-yellow:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
}

.focus\:ring-yellow-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
}

.focus\:ring-yellow-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
}

.focus\:ring-yellow-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
}

.focus\:ring-yellow-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
}

.focus\:ring-green-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
}

.focus\:ring-green-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
}

.focus\:ring-green-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
}

.focus\:ring-green-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
}

.focus\:ring-green-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
}

.focus\:ring-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
}

.focus\:ring-green-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
}

.focus\:ring-green-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
}

.focus\:ring-green-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
}

.focus\:ring-green-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
}

.focus\:ring-green:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
}

.focus\:ring-green-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
}

.focus\:ring-green-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
}

.focus\:ring-green-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
}

.focus\:ring-green-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
}

.focus\:ring-light-green-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
}

.focus\:ring-light-green-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
}

.focus\:ring-light-green-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
}

.focus\:ring-light-green-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
}

.focus\:ring-light-green-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
}

.focus\:ring-light-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
}

.focus\:ring-light-green-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
}

.focus\:ring-light-green-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
}

.focus\:ring-light-green-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
}

.focus\:ring-light-green-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
}

.focus\:ring-light-green:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
}

.focus\:ring-light-green-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
}

.focus\:ring-light-green-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
}

.focus\:ring-light-green-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
}

.focus\:ring-light-green-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
}

.focus\:ring-teal-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
}

.focus\:ring-teal-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
}

.focus\:ring-teal-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
}

.focus\:ring-teal-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
}

.focus\:ring-teal-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
}

.focus\:ring-teal-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
}

.focus\:ring-teal-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
}

.focus\:ring-teal-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
}

.focus\:ring-teal-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
}

.focus\:ring-teal-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
}

.focus\:ring-teal:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
}

.focus\:ring-teal-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
}

.focus\:ring-teal-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
}

.focus\:ring-teal-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
}

.focus\:ring-teal-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
}

.focus\:ring-blue-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
}

.focus\:ring-blue-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
}

.focus\:ring-blue-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
}

.focus\:ring-blue-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
}

.focus\:ring-blue-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
}

.focus\:ring-blue-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
}

.focus\:ring-blue-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
}

.focus\:ring-blue-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
}

.focus\:ring-blue:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
}

.focus\:ring-blue-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
}

.focus\:ring-blue-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
}

.focus\:ring-blue-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
}

.focus\:ring-blue-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
}

.focus\:ring-light-blue-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
}

.focus\:ring-light-blue-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
}

.focus\:ring-light-blue-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
}

.focus\:ring-light-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
}

.focus\:ring-light-blue-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
}

.focus\:ring-light-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
}

.focus\:ring-light-blue-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
}

.focus\:ring-light-blue-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
}

.focus\:ring-light-blue-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
}

.focus\:ring-light-blue-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
}

.focus\:ring-light-blue:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
}

.focus\:ring-light-blue-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
}

.focus\:ring-light-blue-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
}

.focus\:ring-light-blue-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
}

.focus\:ring-light-blue-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
}

.focus\:ring-indigo-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
}

.focus\:ring-indigo-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
}

.focus\:ring-indigo-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
}

.focus\:ring-indigo-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
}

.focus\:ring-indigo-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
}

.focus\:ring-indigo-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
}

.focus\:ring-indigo-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
}

.focus\:ring-indigo-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
}

.focus\:ring-indigo-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
}

.focus\:ring-indigo:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
}

.focus\:ring-indigo-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
}

.focus\:ring-indigo-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
}

.focus\:ring-indigo-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
}

.focus\:ring-indigo-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
}

.focus\:ring-purple-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
}

.focus\:ring-purple-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
}

.focus\:ring-purple-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
}

.focus\:ring-purple-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
}

.focus\:ring-purple-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
}

.focus\:ring-purple-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
}

.focus\:ring-purple-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
}

.focus\:ring-purple-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
}

.focus\:ring-purple-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
}

.focus\:ring-purple-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
}

.focus\:ring-purple:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
}

.focus\:ring-purple-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
}

.focus\:ring-purple-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
}

.focus\:ring-purple-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
}

.focus\:ring-purple-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
}

.focus\:ring-deep-purple-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
}

.focus\:ring-deep-purple-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
}

.focus\:ring-deep-purple-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
}

.focus\:ring-deep-purple-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
}

.focus\:ring-deep-purple-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
}

.focus\:ring-deep-purple-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
}

.focus\:ring-deep-purple-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
}

.focus\:ring-deep-purple-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
}

.focus\:ring-deep-purple-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
}

.focus\:ring-deep-purple-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
}

.focus\:ring-deep-purple:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
}

.focus\:ring-deep-purple-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
}

.focus\:ring-deep-purple-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
}

.focus\:ring-deep-purple-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
}

.focus\:ring-deep-purple-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
}

.focus\:ring-pink-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
}

.focus\:ring-pink-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
}

.focus\:ring-pink-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
}

.focus\:ring-pink-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
}

.focus\:ring-pink-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
}

.focus\:ring-pink-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
}

.focus\:ring-pink-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
}

.focus\:ring-pink-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
}

.focus\:ring-pink-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
}

.focus\:ring-pink-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
}

.focus\:ring-pink:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
}

.focus\:ring-pink-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
}

.focus\:ring-pink-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
}

.focus\:ring-pink-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
}

.focus\:ring-pink-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
}

.focus\:ring-lime-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
}

.focus\:ring-lime-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
}

.focus\:ring-lime-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
}

.focus\:ring-lime-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
}

.focus\:ring-lime-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
}

.focus\:ring-lime-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
}

.focus\:ring-lime-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
}

.focus\:ring-lime-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
}

.focus\:ring-lime-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
}

.focus\:ring-lime-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
}

.focus\:ring-lime:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
}

.focus\:ring-lime-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
}

.focus\:ring-lime-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
}

.focus\:ring-lime-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
}

.focus\:ring-lime-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
}

.focus\:ring-amber-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
}

.focus\:ring-amber-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
}

.focus\:ring-amber-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
}

.focus\:ring-amber-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
}

.focus\:ring-amber-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
}

.focus\:ring-amber-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
}

.focus\:ring-amber-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
}

.focus\:ring-amber-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
}

.focus\:ring-amber-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
}

.focus\:ring-amber-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
}

.focus\:ring-amber:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
}

.focus\:ring-amber-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
}

.focus\:ring-amber-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
}

.focus\:ring-amber-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
}

.focus\:ring-amber-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
}

.focus\:ring-brown-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
}

.focus\:ring-brown-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
}

.focus\:ring-brown-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
}

.focus\:ring-brown-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
}

.focus\:ring-brown-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
}

.focus\:ring-brown-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
}

.focus\:ring-brown-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
}

.focus\:ring-brown-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
}

.focus\:ring-brown-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
}

.focus\:ring-brown-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
}

.focus\:ring-brown:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
}

.focus\:ring-brown-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
}

.focus\:ring-brown-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
}

.focus\:ring-brown-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
}

.focus\:ring-brown-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
}

.focus\:ring-blue-gray-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
}

.focus\:ring-blue-gray-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
}

.focus\:ring-blue-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
}

.focus\:ring-blue-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
}

.focus\:ring-blue-gray-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
}

.focus\:ring-blue-gray-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
}

.focus\:ring-blue-gray-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
}

.focus\:ring-blue-gray-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
}

.focus\:ring-blue-gray-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
}

.focus\:ring-blue-gray-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
}

.focus\:ring-blue-gray:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
}

.focus\:ring-blue-gray-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
}

.focus\:ring-blue-gray-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
}

.focus\:ring-blue-gray-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
}

.focus\:ring-blue-gray-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
}

.focus\:ring-cyan-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
}

.focus\:ring-cyan-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
}

.focus\:ring-cyan-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
}

.focus\:ring-cyan-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
}

.focus\:ring-cyan-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
}

.focus\:ring-cyan-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
}

.focus\:ring-cyan-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
}

.focus\:ring-cyan-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
}

.focus\:ring-cyan-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
}

.focus\:ring-cyan-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
}

.focus\:ring-cyan:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
}

.focus\:ring-cyan-A100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
}

.focus\:ring-cyan-A200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
}

.focus\:ring-cyan-A400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
}

.focus\:ring-cyan-A700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
}

.ring-opacity-0 {
  --tw-ring-opacity: 0
}

.ring-opacity-5 {
  --tw-ring-opacity: 0.05
}

.ring-opacity-10 {
  --tw-ring-opacity: 0.1
}

.ring-opacity-20 {
  --tw-ring-opacity: 0.2
}

.ring-opacity-25 {
  --tw-ring-opacity: 0.25
}

.ring-opacity-30 {
  --tw-ring-opacity: 0.3
}

.ring-opacity-40 {
  --tw-ring-opacity: 0.4
}

.ring-opacity-50 {
  --tw-ring-opacity: 0.5
}

.ring-opacity-60 {
  --tw-ring-opacity: 0.6
}

.ring-opacity-70 {
  --tw-ring-opacity: 0.7
}

.ring-opacity-75 {
  --tw-ring-opacity: 0.75
}

.ring-opacity-80 {
  --tw-ring-opacity: 0.8
}

.ring-opacity-90 {
  --tw-ring-opacity: 0.9
}

.ring-opacity-95 {
  --tw-ring-opacity: 0.95
}

.ring-opacity-100 {
  --tw-ring-opacity: 1
}

.focus-within\:ring-opacity-0:focus-within {
  --tw-ring-opacity: 0
}

.focus-within\:ring-opacity-5:focus-within {
  --tw-ring-opacity: 0.05
}

.focus-within\:ring-opacity-10:focus-within {
  --tw-ring-opacity: 0.1
}

.focus-within\:ring-opacity-20:focus-within {
  --tw-ring-opacity: 0.2
}

.focus-within\:ring-opacity-25:focus-within {
  --tw-ring-opacity: 0.25
}

.focus-within\:ring-opacity-30:focus-within {
  --tw-ring-opacity: 0.3
}

.focus-within\:ring-opacity-40:focus-within {
  --tw-ring-opacity: 0.4
}

.focus-within\:ring-opacity-50:focus-within {
  --tw-ring-opacity: 0.5
}

.focus-within\:ring-opacity-60:focus-within {
  --tw-ring-opacity: 0.6
}

.focus-within\:ring-opacity-70:focus-within {
  --tw-ring-opacity: 0.7
}

.focus-within\:ring-opacity-75:focus-within {
  --tw-ring-opacity: 0.75
}

.focus-within\:ring-opacity-80:focus-within {
  --tw-ring-opacity: 0.8
}

.focus-within\:ring-opacity-90:focus-within {
  --tw-ring-opacity: 0.9
}

.focus-within\:ring-opacity-95:focus-within {
  --tw-ring-opacity: 0.95
}

.focus-within\:ring-opacity-100:focus-within {
  --tw-ring-opacity: 1
}

.focus\:ring-opacity-0:focus {
  --tw-ring-opacity: 0
}

.focus\:ring-opacity-5:focus {
  --tw-ring-opacity: 0.05
}

.focus\:ring-opacity-10:focus {
  --tw-ring-opacity: 0.1
}

.focus\:ring-opacity-20:focus {
  --tw-ring-opacity: 0.2
}

.focus\:ring-opacity-25:focus {
  --tw-ring-opacity: 0.25
}

.focus\:ring-opacity-30:focus {
  --tw-ring-opacity: 0.3
}

.focus\:ring-opacity-40:focus {
  --tw-ring-opacity: 0.4
}

.focus\:ring-opacity-50:focus {
  --tw-ring-opacity: 0.5
}

.focus\:ring-opacity-60:focus {
  --tw-ring-opacity: 0.6
}

.focus\:ring-opacity-70:focus {
  --tw-ring-opacity: 0.7
}

.focus\:ring-opacity-75:focus {
  --tw-ring-opacity: 0.75
}

.focus\:ring-opacity-80:focus {
  --tw-ring-opacity: 0.8
}

.focus\:ring-opacity-90:focus {
  --tw-ring-opacity: 0.9
}

.focus\:ring-opacity-95:focus {
  --tw-ring-opacity: 0.95
}

.focus\:ring-opacity-100:focus {
  --tw-ring-opacity: 1
}

.fill-current {
  fill: currentColor
}

.stroke-current {
  stroke: currentColor
}

.stroke-0 {
  stroke-width: 0
}

.stroke-1 {
  stroke-width: 1
}

.stroke-2 {
  stroke-width: 2
}

.table-auto {
  table-layout: auto
}

.table-fixed {
  table-layout: fixed
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-justify {
  text-align: justify
}

[dir='ltr'] .ltr\:text-left,[dir='ltr'].ltr\:text-left {
  text-align: left
}

[dir='ltr'] .ltr\:text-center,[dir='ltr'].ltr\:text-center {
  text-align: center
}

[dir='ltr'] .ltr\:text-right,[dir='ltr'].ltr\:text-right {
  text-align: right
}

[dir='ltr'] .ltr\:text-justify,[dir='ltr'].ltr\:text-justify {
  text-align: justify
}

[dir='rtl'] .rtl\:text-left,[dir='rtl'].rtl\:text-left {
  text-align: left
}

[dir='rtl'] .rtl\:text-center,[dir='rtl'].rtl\:text-center {
  text-align: center
}

[dir='rtl'] .rtl\:text-right,[dir='rtl'].rtl\:text-right {
  text-align: right
}

[dir='rtl'] .rtl\:text-justify,[dir='rtl'].rtl\:text-justify {
  text-align: justify
}

.text-transparent {
  color: transparent
}

.text-current {
  color: currentColor
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(34, 41, 47, var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.text-grey-50 {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity))
}

.text-grey-100 {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-text-opacity))
}

.text-grey-200 {
  --tw-text-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-text-opacity))
}

.text-grey-300 {
  --tw-text-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-text-opacity))
}

.text-grey-400 {
  --tw-text-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-text-opacity))
}

.text-grey-500 {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.text-grey-600 {
  --tw-text-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-text-opacity))
}

.text-grey-700 {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.text-grey-800 {
  --tw-text-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-text-opacity))
}

.text-grey-900 {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity))
}

.text-grey {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.text-grey-A100 {
  --tw-text-opacity: 1;
  color: rgba(213, 213, 213, var(--tw-text-opacity))
}

.text-grey-A200 {
  --tw-text-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-text-opacity))
}

.text-grey-A400 {
  --tw-text-opacity: 1;
  color: rgba(48, 48, 48, var(--tw-text-opacity))
}

.text-grey-A700 {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity))
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-text-opacity))
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-text-opacity))
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-text-opacity))
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-text-opacity))
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-text-opacity))
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-text-opacity))
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity))
}

.text-gray {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.text-gray-hover {
  color: rgba(0, 0, 0, 0.04)
}

.text-gray-A100 {
  --tw-text-opacity: 1;
  color: rgba(213, 213, 213, var(--tw-text-opacity))
}

.text-gray-A200 {
  --tw-text-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-text-opacity))
}

.text-gray-A400 {
  --tw-text-opacity: 1;
  color: rgba(48, 48, 48, var(--tw-text-opacity))
}

.text-gray-A700 {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.text-red-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 235, 238, var(--tw-text-opacity))
}

.text-red-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 205, 210, var(--tw-text-opacity))
}

.text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(239, 154, 154, var(--tw-text-opacity))
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(229, 115, 115, var(--tw-text-opacity))
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(239, 83, 80, var(--tw-text-opacity))
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity))
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(229, 57, 53, var(--tw-text-opacity))
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(211, 47, 47, var(--tw-text-opacity))
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(198, 40, 40, var(--tw-text-opacity))
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(183, 28, 28, var(--tw-text-opacity))
}

.text-red {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity))
}

.text-red-A100 {
  --tw-text-opacity: 1;
  color: rgba(255, 138, 128, var(--tw-text-opacity))
}

.text-red-A200 {
  --tw-text-opacity: 1;
  color: rgba(255, 82, 82, var(--tw-text-opacity))
}

.text-red-A400 {
  --tw-text-opacity: 1;
  color: rgba(255, 23, 68, var(--tw-text-opacity))
}

.text-red-A700 {
  --tw-text-opacity: 1;
  color: rgba(213, 0, 0, var(--tw-text-opacity))
}

.text-orange-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 243, 224, var(--tw-text-opacity))
}

.text-orange-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 224, 178, var(--tw-text-opacity))
}

.text-orange-200 {
  --tw-text-opacity: 1;
  color: rgba(255, 204, 128, var(--tw-text-opacity))
}

.text-orange-300 {
  --tw-text-opacity: 1;
  color: rgba(255, 183, 77, var(--tw-text-opacity))
}

.text-orange-400 {
  --tw-text-opacity: 1;
  color: rgba(255, 167, 38, var(--tw-text-opacity))
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-text-opacity))
}

.text-orange-600 {
  --tw-text-opacity: 1;
  color: rgba(251, 140, 0, var(--tw-text-opacity))
}

.text-orange-700 {
  --tw-text-opacity: 1;
  color: rgba(245, 124, 0, var(--tw-text-opacity))
}

.text-orange-800 {
  --tw-text-opacity: 1;
  color: rgba(239, 108, 0, var(--tw-text-opacity))
}

.text-orange-900 {
  --tw-text-opacity: 1;
  color: rgba(230, 81, 0, var(--tw-text-opacity))
}

.text-orange {
  --tw-text-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-text-opacity))
}

.text-orange-A100 {
  --tw-text-opacity: 1;
  color: rgba(255, 209, 128, var(--tw-text-opacity))
}

.text-orange-A200 {
  --tw-text-opacity: 1;
  color: rgba(255, 171, 64, var(--tw-text-opacity))
}

.text-orange-A400 {
  --tw-text-opacity: 1;
  color: rgba(255, 145, 0, var(--tw-text-opacity))
}

.text-orange-A700 {
  --tw-text-opacity: 1;
  color: rgba(255, 109, 0, var(--tw-text-opacity))
}

.text-deep-orange-50 {
  --tw-text-opacity: 1;
  color: rgba(251, 233, 231, var(--tw-text-opacity))
}

.text-deep-orange-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 204, 188, var(--tw-text-opacity))
}

.text-deep-orange-200 {
  --tw-text-opacity: 1;
  color: rgba(255, 171, 145, var(--tw-text-opacity))
}

.text-deep-orange-300 {
  --tw-text-opacity: 1;
  color: rgba(255, 138, 101, var(--tw-text-opacity))
}

.text-deep-orange-400 {
  --tw-text-opacity: 1;
  color: rgba(255, 112, 67, var(--tw-text-opacity))
}

.text-deep-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-text-opacity))
}

.text-deep-orange-600 {
  --tw-text-opacity: 1;
  color: rgba(244, 81, 30, var(--tw-text-opacity))
}

.text-deep-orange-700 {
  --tw-text-opacity: 1;
  color: rgba(230, 74, 25, var(--tw-text-opacity))
}

.text-deep-orange-800 {
  --tw-text-opacity: 1;
  color: rgba(216, 67, 21, var(--tw-text-opacity))
}

.text-deep-orange-900 {
  --tw-text-opacity: 1;
  color: rgba(191, 54, 12, var(--tw-text-opacity))
}

.text-deep-orange {
  --tw-text-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-text-opacity))
}

.text-deep-orange-A100 {
  --tw-text-opacity: 1;
  color: rgba(255, 158, 128, var(--tw-text-opacity))
}

.text-deep-orange-A200 {
  --tw-text-opacity: 1;
  color: rgba(255, 110, 64, var(--tw-text-opacity))
}

.text-deep-orange-A400 {
  --tw-text-opacity: 1;
  color: rgba(255, 61, 0, var(--tw-text-opacity))
}

.text-deep-orange-A700 {
  --tw-text-opacity: 1;
  color: rgba(221, 44, 0, var(--tw-text-opacity))
}

.text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 253, 231, var(--tw-text-opacity))
}

.text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 249, 196, var(--tw-text-opacity))
}

.text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgba(255, 245, 157, var(--tw-text-opacity))
}

.text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgba(255, 241, 118, var(--tw-text-opacity))
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(255, 238, 88, var(--tw-text-opacity))
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 235, 59, var(--tw-text-opacity))
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(253, 216, 53, var(--tw-text-opacity))
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(251, 192, 45, var(--tw-text-opacity))
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 37, var(--tw-text-opacity))
}

.text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgba(245, 127, 23, var(--tw-text-opacity))
}

.text-yellow {
  --tw-text-opacity: 1;
  color: rgba(255, 235, 59, var(--tw-text-opacity))
}

.text-yellow-A100 {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 141, var(--tw-text-opacity))
}

.text-yellow-A200 {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 0, var(--tw-text-opacity))
}

.text-yellow-A400 {
  --tw-text-opacity: 1;
  color: rgba(255, 234, 0, var(--tw-text-opacity))
}

.text-yellow-A700 {
  --tw-text-opacity: 1;
  color: rgba(255, 214, 0, var(--tw-text-opacity))
}

.text-green-50 {
  --tw-text-opacity: 1;
  color: rgba(232, 245, 233, var(--tw-text-opacity))
}

.text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(200, 230, 201, var(--tw-text-opacity))
}

.text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(165, 214, 167, var(--tw-text-opacity))
}

.text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(129, 199, 132, var(--tw-text-opacity))
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(102, 187, 106, var(--tw-text-opacity))
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity))
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(67, 160, 71, var(--tw-text-opacity))
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(56, 142, 60, var(--tw-text-opacity))
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(46, 125, 50, var(--tw-text-opacity))
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(27, 94, 32, var(--tw-text-opacity))
}

.text-green {
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity))
}

.text-green-A100 {
  --tw-text-opacity: 1;
  color: rgba(185, 246, 202, var(--tw-text-opacity))
}

.text-green-A200 {
  --tw-text-opacity: 1;
  color: rgba(105, 240, 174, var(--tw-text-opacity))
}

.text-green-A400 {
  --tw-text-opacity: 1;
  color: rgba(0, 230, 118, var(--tw-text-opacity))
}

.text-green-A700 {
  --tw-text-opacity: 1;
  color: rgba(0, 200, 83, var(--tw-text-opacity))
}

.text-light-green-50 {
  --tw-text-opacity: 1;
  color: rgba(241, 248, 233, var(--tw-text-opacity))
}

.text-light-green-100 {
  --tw-text-opacity: 1;
  color: rgba(220, 237, 200, var(--tw-text-opacity))
}

.text-light-green-200 {
  --tw-text-opacity: 1;
  color: rgba(197, 225, 165, var(--tw-text-opacity))
}

.text-light-green-300 {
  --tw-text-opacity: 1;
  color: rgba(174, 213, 129, var(--tw-text-opacity))
}

.text-light-green-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 204, 101, var(--tw-text-opacity))
}

.text-light-green-500 {
  --tw-text-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-text-opacity))
}

.text-light-green-600 {
  --tw-text-opacity: 1;
  color: rgba(124, 179, 66, var(--tw-text-opacity))
}

.text-light-green-700 {
  --tw-text-opacity: 1;
  color: rgba(104, 159, 56, var(--tw-text-opacity))
}

.text-light-green-800 {
  --tw-text-opacity: 1;
  color: rgba(85, 139, 47, var(--tw-text-opacity))
}

.text-light-green-900 {
  --tw-text-opacity: 1;
  color: rgba(51, 105, 30, var(--tw-text-opacity))
}

.text-light-green {
  --tw-text-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-text-opacity))
}

.text-light-green-A100 {
  --tw-text-opacity: 1;
  color: rgba(204, 255, 144, var(--tw-text-opacity))
}

.text-light-green-A200 {
  --tw-text-opacity: 1;
  color: rgba(178, 255, 89, var(--tw-text-opacity))
}

.text-light-green-A400 {
  --tw-text-opacity: 1;
  color: rgba(118, 255, 3, var(--tw-text-opacity))
}

.text-light-green-A700 {
  --tw-text-opacity: 1;
  color: rgba(100, 221, 23, var(--tw-text-opacity))
}

.text-teal-50 {
  --tw-text-opacity: 1;
  color: rgba(224, 242, 241, var(--tw-text-opacity))
}

.text-teal-100 {
  --tw-text-opacity: 1;
  color: rgba(178, 223, 219, var(--tw-text-opacity))
}

.text-teal-200 {
  --tw-text-opacity: 1;
  color: rgba(128, 203, 196, var(--tw-text-opacity))
}

.text-teal-300 {
  --tw-text-opacity: 1;
  color: rgba(77, 182, 172, var(--tw-text-opacity))
}

.text-teal-400 {
  --tw-text-opacity: 1;
  color: rgba(38, 166, 154, var(--tw-text-opacity))
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-text-opacity))
}

.text-teal-600 {
  --tw-text-opacity: 1;
  color: rgba(0, 137, 123, var(--tw-text-opacity))
}

.text-teal-700 {
  --tw-text-opacity: 1;
  color: rgba(0, 121, 107, var(--tw-text-opacity))
}

.text-teal-800 {
  --tw-text-opacity: 1;
  color: rgba(0, 105, 92, var(--tw-text-opacity))
}

.text-teal-900 {
  --tw-text-opacity: 1;
  color: rgba(0, 77, 64, var(--tw-text-opacity))
}

.text-teal {
  --tw-text-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-text-opacity))
}

.text-teal-A100 {
  --tw-text-opacity: 1;
  color: rgba(167, 255, 235, var(--tw-text-opacity))
}

.text-teal-A200 {
  --tw-text-opacity: 1;
  color: rgba(100, 255, 218, var(--tw-text-opacity))
}

.text-teal-A400 {
  --tw-text-opacity: 1;
  color: rgba(29, 233, 182, var(--tw-text-opacity))
}

.text-teal-A700 {
  --tw-text-opacity: 1;
  color: rgba(0, 191, 165, var(--tw-text-opacity))
}

.text-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(227, 242, 253, var(--tw-text-opacity))
}

.text-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(187, 222, 251, var(--tw-text-opacity))
}

.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(144, 202, 249, var(--tw-text-opacity))
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(100, 181, 246, var(--tw-text-opacity))
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(66, 165, 245, var(--tw-text-opacity))
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-text-opacity))
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(30, 136, 229, var(--tw-text-opacity))
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(25, 118, 210, var(--tw-text-opacity))
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(21, 101, 192, var(--tw-text-opacity))
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(13, 71, 161, var(--tw-text-opacity))
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-text-opacity))
}

.text-blue-A100 {
  --tw-text-opacity: 1;
  color: rgba(130, 177, 255, var(--tw-text-opacity))
}

.text-blue-A200 {
  --tw-text-opacity: 1;
  color: rgba(68, 138, 255, var(--tw-text-opacity))
}

.text-blue-A400 {
  --tw-text-opacity: 1;
  color: rgba(41, 121, 255, var(--tw-text-opacity))
}

.text-blue-A700 {
  --tw-text-opacity: 1;
  color: rgba(41, 98, 255, var(--tw-text-opacity))
}

.text-light-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(225, 245, 254, var(--tw-text-opacity))
}

.text-light-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(179, 229, 252, var(--tw-text-opacity))
}

.text-light-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(129, 212, 250, var(--tw-text-opacity))
}

.text-light-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(79, 195, 247, var(--tw-text-opacity))
}

.text-light-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(41, 182, 246, var(--tw-text-opacity))
}

.text-light-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-text-opacity))
}

.text-light-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(3, 155, 229, var(--tw-text-opacity))
}

.text-light-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(2, 136, 209, var(--tw-text-opacity))
}

.text-light-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(2, 119, 189, var(--tw-text-opacity))
}

.text-light-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(1, 87, 155, var(--tw-text-opacity))
}

.text-light-blue {
  --tw-text-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-text-opacity))
}

.text-light-blue-A100 {
  --tw-text-opacity: 1;
  color: rgba(128, 216, 255, var(--tw-text-opacity))
}

.text-light-blue-A200 {
  --tw-text-opacity: 1;
  color: rgba(64, 196, 255, var(--tw-text-opacity))
}

.text-light-blue-A400 {
  --tw-text-opacity: 1;
  color: rgba(0, 176, 255, var(--tw-text-opacity))
}

.text-light-blue-A700 {
  --tw-text-opacity: 1;
  color: rgba(0, 145, 234, var(--tw-text-opacity))
}

.text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgba(232, 234, 246, var(--tw-text-opacity))
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgba(197, 202, 233, var(--tw-text-opacity))
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(159, 168, 218, var(--tw-text-opacity))
}

.text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgba(121, 134, 203, var(--tw-text-opacity))
}

.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(92, 107, 192, var(--tw-text-opacity))
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-text-opacity))
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(57, 73, 171, var(--tw-text-opacity))
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(48, 63, 159, var(--tw-text-opacity))
}

.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgba(40, 53, 147, var(--tw-text-opacity))
}

.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgba(26, 35, 126, var(--tw-text-opacity))
}

.text-indigo {
  --tw-text-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-text-opacity))
}

.text-indigo-A100 {
  --tw-text-opacity: 1;
  color: rgba(140, 158, 255, var(--tw-text-opacity))
}

.text-indigo-A200 {
  --tw-text-opacity: 1;
  color: rgba(83, 109, 254, var(--tw-text-opacity))
}

.text-indigo-A400 {
  --tw-text-opacity: 1;
  color: rgba(61, 90, 254, var(--tw-text-opacity))
}

.text-indigo-A700 {
  --tw-text-opacity: 1;
  color: rgba(48, 79, 254, var(--tw-text-opacity))
}

.text-purple-50 {
  --tw-text-opacity: 1;
  color: rgba(243, 229, 245, var(--tw-text-opacity))
}

.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(225, 190, 231, var(--tw-text-opacity))
}

.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(206, 147, 216, var(--tw-text-opacity))
}

.text-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(186, 104, 200, var(--tw-text-opacity))
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(171, 71, 188, var(--tw-text-opacity))
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-text-opacity))
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(142, 36, 170, var(--tw-text-opacity))
}

.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(123, 31, 162, var(--tw-text-opacity))
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(106, 27, 154, var(--tw-text-opacity))
}

.text-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(74, 20, 140, var(--tw-text-opacity))
}

.text-purple {
  --tw-text-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-text-opacity))
}

.text-purple-A100 {
  --tw-text-opacity: 1;
  color: rgba(234, 128, 252, var(--tw-text-opacity))
}

.text-purple-A200 {
  --tw-text-opacity: 1;
  color: rgba(224, 64, 251, var(--tw-text-opacity))
}

.text-purple-A400 {
  --tw-text-opacity: 1;
  color: rgba(213, 0, 249, var(--tw-text-opacity))
}

.text-purple-A700 {
  --tw-text-opacity: 1;
  color: rgba(170, 0, 255, var(--tw-text-opacity))
}

.text-deep-purple-50 {
  --tw-text-opacity: 1;
  color: rgba(237, 231, 246, var(--tw-text-opacity))
}

.text-deep-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 196, 233, var(--tw-text-opacity))
}

.text-deep-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(179, 157, 219, var(--tw-text-opacity))
}

.text-deep-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(149, 117, 205, var(--tw-text-opacity))
}

.text-deep-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(126, 87, 194, var(--tw-text-opacity))
}

.text-deep-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-text-opacity))
}

.text-deep-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(94, 53, 177, var(--tw-text-opacity))
}

.text-deep-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(81, 45, 168, var(--tw-text-opacity))
}

.text-deep-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(69, 39, 160, var(--tw-text-opacity))
}

.text-deep-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(49, 27, 146, var(--tw-text-opacity))
}

.text-deep-purple {
  --tw-text-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-text-opacity))
}

.text-deep-purple-A100 {
  --tw-text-opacity: 1;
  color: rgba(179, 136, 255, var(--tw-text-opacity))
}

.text-deep-purple-A200 {
  --tw-text-opacity: 1;
  color: rgba(124, 77, 255, var(--tw-text-opacity))
}

.text-deep-purple-A400 {
  --tw-text-opacity: 1;
  color: rgba(101, 31, 255, var(--tw-text-opacity))
}

.text-deep-purple-A700 {
  --tw-text-opacity: 1;
  color: rgba(98, 0, 234, var(--tw-text-opacity))
}

.text-pink-50 {
  --tw-text-opacity: 1;
  color: rgba(252, 228, 236, var(--tw-text-opacity))
}

.text-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(248, 187, 208, var(--tw-text-opacity))
}

.text-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(244, 143, 177, var(--tw-text-opacity))
}

.text-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(240, 98, 146, var(--tw-text-opacity))
}

.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(236, 64, 122, var(--tw-text-opacity))
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-text-opacity))
}

.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(216, 27, 96, var(--tw-text-opacity))
}

.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(194, 24, 91, var(--tw-text-opacity))
}

.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(173, 20, 87, var(--tw-text-opacity))
}

.text-pink-900 {
  --tw-text-opacity: 1;
  color: rgba(136, 14, 79, var(--tw-text-opacity))
}

.text-pink {
  --tw-text-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-text-opacity))
}

.text-pink-A100 {
  --tw-text-opacity: 1;
  color: rgba(255, 128, 171, var(--tw-text-opacity))
}

.text-pink-A200 {
  --tw-text-opacity: 1;
  color: rgba(255, 64, 129, var(--tw-text-opacity))
}

.text-pink-A400 {
  --tw-text-opacity: 1;
  color: rgba(245, 0, 87, var(--tw-text-opacity))
}

.text-pink-A700 {
  --tw-text-opacity: 1;
  color: rgba(197, 17, 98, var(--tw-text-opacity))
}

.text-lime-50 {
  --tw-text-opacity: 1;
  color: rgba(249, 251, 231, var(--tw-text-opacity))
}

.text-lime-100 {
  --tw-text-opacity: 1;
  color: rgba(240, 244, 195, var(--tw-text-opacity))
}

.text-lime-200 {
  --tw-text-opacity: 1;
  color: rgba(230, 238, 156, var(--tw-text-opacity))
}

.text-lime-300 {
  --tw-text-opacity: 1;
  color: rgba(220, 231, 117, var(--tw-text-opacity))
}

.text-lime-400 {
  --tw-text-opacity: 1;
  color: rgba(212, 225, 87, var(--tw-text-opacity))
}

.text-lime-500 {
  --tw-text-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-text-opacity))
}

.text-lime-600 {
  --tw-text-opacity: 1;
  color: rgba(192, 202, 51, var(--tw-text-opacity))
}

.text-lime-700 {
  --tw-text-opacity: 1;
  color: rgba(175, 180, 43, var(--tw-text-opacity))
}

.text-lime-800 {
  --tw-text-opacity: 1;
  color: rgba(158, 157, 36, var(--tw-text-opacity))
}

.text-lime-900 {
  --tw-text-opacity: 1;
  color: rgba(130, 119, 23, var(--tw-text-opacity))
}

.text-lime {
  --tw-text-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-text-opacity))
}

.text-lime-A100 {
  --tw-text-opacity: 1;
  color: rgba(244, 255, 129, var(--tw-text-opacity))
}

.text-lime-A200 {
  --tw-text-opacity: 1;
  color: rgba(238, 255, 65, var(--tw-text-opacity))
}

.text-lime-A400 {
  --tw-text-opacity: 1;
  color: rgba(198, 255, 0, var(--tw-text-opacity))
}

.text-lime-A700 {
  --tw-text-opacity: 1;
  color: rgba(174, 234, 0, var(--tw-text-opacity))
}

.text-amber-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 248, 225, var(--tw-text-opacity))
}

.text-amber-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 236, 179, var(--tw-text-opacity))
}

.text-amber-200 {
  --tw-text-opacity: 1;
  color: rgba(255, 224, 130, var(--tw-text-opacity))
}

.text-amber-300 {
  --tw-text-opacity: 1;
  color: rgba(255, 213, 79, var(--tw-text-opacity))
}

.text-amber-400 {
  --tw-text-opacity: 1;
  color: rgba(255, 202, 40, var(--tw-text-opacity))
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-text-opacity))
}

.text-amber-600 {
  --tw-text-opacity: 1;
  color: rgba(255, 179, 0, var(--tw-text-opacity))
}

.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgba(255, 160, 0, var(--tw-text-opacity))
}

.text-amber-800 {
  --tw-text-opacity: 1;
  color: rgba(255, 143, 0, var(--tw-text-opacity))
}

.text-amber-900 {
  --tw-text-opacity: 1;
  color: rgba(255, 111, 0, var(--tw-text-opacity))
}

.text-amber {
  --tw-text-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-text-opacity))
}

.text-amber-A100 {
  --tw-text-opacity: 1;
  color: rgba(255, 229, 127, var(--tw-text-opacity))
}

.text-amber-A200 {
  --tw-text-opacity: 1;
  color: rgba(255, 215, 64, var(--tw-text-opacity))
}

.text-amber-A400 {
  --tw-text-opacity: 1;
  color: rgba(255, 196, 0, var(--tw-text-opacity))
}

.text-amber-A700 {
  --tw-text-opacity: 1;
  color: rgba(255, 171, 0, var(--tw-text-opacity))
}

.text-brown-50 {
  --tw-text-opacity: 1;
  color: rgba(239, 235, 233, var(--tw-text-opacity))
}

.text-brown-100 {
  --tw-text-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-text-opacity))
}

.text-brown-200 {
  --tw-text-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-text-opacity))
}

.text-brown-300 {
  --tw-text-opacity: 1;
  color: rgba(161, 136, 127, var(--tw-text-opacity))
}

.text-brown-400 {
  --tw-text-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-text-opacity))
}

.text-brown-500 {
  --tw-text-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-text-opacity))
}

.text-brown-600 {
  --tw-text-opacity: 1;
  color: rgba(109, 76, 65, var(--tw-text-opacity))
}

.text-brown-700 {
  --tw-text-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-text-opacity))
}

.text-brown-800 {
  --tw-text-opacity: 1;
  color: rgba(78, 52, 46, var(--tw-text-opacity))
}

.text-brown-900 {
  --tw-text-opacity: 1;
  color: rgba(62, 39, 35, var(--tw-text-opacity))
}

.text-brown {
  --tw-text-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-text-opacity))
}

.text-brown-A100 {
  --tw-text-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-text-opacity))
}

.text-brown-A200 {
  --tw-text-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-text-opacity))
}

.text-brown-A400 {
  --tw-text-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-text-opacity))
}

.text-brown-A700 {
  --tw-text-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-text-opacity))
}

.text-blue-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 239, 241, var(--tw-text-opacity))
}

.text-blue-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-text-opacity))
}

.text-blue-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-text-opacity))
}

.text-blue-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(144, 164, 174, var(--tw-text-opacity))
}

.text-blue-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-text-opacity))
}

.text-blue-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-text-opacity))
}

.text-blue-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(84, 110, 122, var(--tw-text-opacity))
}

.text-blue-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-text-opacity))
}

.text-blue-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 71, 79, var(--tw-text-opacity))
}

.text-blue-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(38, 50, 56, var(--tw-text-opacity))
}

.text-blue-gray {
  --tw-text-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-text-opacity))
}

.text-blue-gray-A100 {
  --tw-text-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-text-opacity))
}

.text-blue-gray-A200 {
  --tw-text-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-text-opacity))
}

.text-blue-gray-A400 {
  --tw-text-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-text-opacity))
}

.text-blue-gray-A700 {
  --tw-text-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-text-opacity))
}

.text-cyan-50 {
  --tw-text-opacity: 1;
  color: rgba(224, 247, 250, var(--tw-text-opacity))
}

.text-cyan-100 {
  --tw-text-opacity: 1;
  color: rgba(178, 235, 242, var(--tw-text-opacity))
}

.text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgba(128, 222, 234, var(--tw-text-opacity))
}

.text-cyan-300 {
  --tw-text-opacity: 1;
  color: rgba(77, 208, 225, var(--tw-text-opacity))
}

.text-cyan-400 {
  --tw-text-opacity: 1;
  color: rgba(38, 198, 218, var(--tw-text-opacity))
}

.text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-text-opacity))
}

.text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgba(0, 172, 193, var(--tw-text-opacity))
}

.text-cyan-700 {
  --tw-text-opacity: 1;
  color: rgba(0, 151, 167, var(--tw-text-opacity))
}

.text-cyan-800 {
  --tw-text-opacity: 1;
  color: rgba(0, 131, 143, var(--tw-text-opacity))
}

.text-cyan-900 {
  --tw-text-opacity: 1;
  color: rgba(0, 96, 100, var(--tw-text-opacity))
}

.text-cyan {
  --tw-text-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-text-opacity))
}

.text-cyan-A100 {
  --tw-text-opacity: 1;
  color: rgba(132, 255, 255, var(--tw-text-opacity))
}

.text-cyan-A200 {
  --tw-text-opacity: 1;
  color: rgba(24, 255, 255, var(--tw-text-opacity))
}

.text-cyan-A400 {
  --tw-text-opacity: 1;
  color: rgba(0, 229, 255, var(--tw-text-opacity))
}

.text-cyan-A700 {
  --tw-text-opacity: 1;
  color: rgba(0, 184, 212, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-transparent {
  color: transparent
}

.group:hover .group-hover\:text-current {
  color: currentColor
}

.group:hover .group-hover\:text-black {
  --tw-text-opacity: 1;
  color: rgba(34, 41, 47, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-grey-50 {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-grey-100 {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-grey-200 {
  --tw-text-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-grey-300 {
  --tw-text-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-grey-400 {
  --tw-text-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-grey-500 {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-grey-600 {
  --tw-text-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-grey-700 {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-grey-800 {
  --tw-text-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-grey-900 {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-grey {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-grey-A100 {
  --tw-text-opacity: 1;
  color: rgba(213, 213, 213, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-grey-A200 {
  --tw-text-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-grey-A400 {
  --tw-text-opacity: 1;
  color: rgba(48, 48, 48, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-grey-A700 {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-hover {
  color: rgba(0, 0, 0, 0.04)
}

.group:hover .group-hover\:text-gray-A100 {
  --tw-text-opacity: 1;
  color: rgba(213, 213, 213, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-A200 {
  --tw-text-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-A400 {
  --tw-text-opacity: 1;
  color: rgba(48, 48, 48, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-A700 {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-red-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 235, 238, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-red-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 205, 210, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(239, 154, 154, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(229, 115, 115, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(239, 83, 80, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(229, 57, 53, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(211, 47, 47, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(198, 40, 40, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(183, 28, 28, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-red {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-red-A100 {
  --tw-text-opacity: 1;
  color: rgba(255, 138, 128, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-red-A200 {
  --tw-text-opacity: 1;
  color: rgba(255, 82, 82, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-red-A400 {
  --tw-text-opacity: 1;
  color: rgba(255, 23, 68, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-red-A700 {
  --tw-text-opacity: 1;
  color: rgba(213, 0, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-orange-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 243, 224, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-orange-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 224, 178, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-orange-200 {
  --tw-text-opacity: 1;
  color: rgba(255, 204, 128, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-orange-300 {
  --tw-text-opacity: 1;
  color: rgba(255, 183, 77, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-orange-400 {
  --tw-text-opacity: 1;
  color: rgba(255, 167, 38, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-orange-600 {
  --tw-text-opacity: 1;
  color: rgba(251, 140, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-orange-700 {
  --tw-text-opacity: 1;
  color: rgba(245, 124, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-orange-800 {
  --tw-text-opacity: 1;
  color: rgba(239, 108, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-orange-900 {
  --tw-text-opacity: 1;
  color: rgba(230, 81, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-orange {
  --tw-text-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-orange-A100 {
  --tw-text-opacity: 1;
  color: rgba(255, 209, 128, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-orange-A200 {
  --tw-text-opacity: 1;
  color: rgba(255, 171, 64, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-orange-A400 {
  --tw-text-opacity: 1;
  color: rgba(255, 145, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-orange-A700 {
  --tw-text-opacity: 1;
  color: rgba(255, 109, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-orange-50 {
  --tw-text-opacity: 1;
  color: rgba(251, 233, 231, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-orange-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 204, 188, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-orange-200 {
  --tw-text-opacity: 1;
  color: rgba(255, 171, 145, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-orange-300 {
  --tw-text-opacity: 1;
  color: rgba(255, 138, 101, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-orange-400 {
  --tw-text-opacity: 1;
  color: rgba(255, 112, 67, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-orange-600 {
  --tw-text-opacity: 1;
  color: rgba(244, 81, 30, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-orange-700 {
  --tw-text-opacity: 1;
  color: rgba(230, 74, 25, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-orange-800 {
  --tw-text-opacity: 1;
  color: rgba(216, 67, 21, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-orange-900 {
  --tw-text-opacity: 1;
  color: rgba(191, 54, 12, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-orange {
  --tw-text-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-orange-A100 {
  --tw-text-opacity: 1;
  color: rgba(255, 158, 128, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-orange-A200 {
  --tw-text-opacity: 1;
  color: rgba(255, 110, 64, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-orange-A400 {
  --tw-text-opacity: 1;
  color: rgba(255, 61, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-orange-A700 {
  --tw-text-opacity: 1;
  color: rgba(221, 44, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 253, 231, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 249, 196, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgba(255, 245, 157, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgba(255, 241, 118, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(255, 238, 88, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 235, 59, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(253, 216, 53, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(251, 192, 45, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 37, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgba(245, 127, 23, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-yellow {
  --tw-text-opacity: 1;
  color: rgba(255, 235, 59, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-yellow-A100 {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 141, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-yellow-A200 {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-yellow-A400 {
  --tw-text-opacity: 1;
  color: rgba(255, 234, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-yellow-A700 {
  --tw-text-opacity: 1;
  color: rgba(255, 214, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-green-50 {
  --tw-text-opacity: 1;
  color: rgba(232, 245, 233, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(200, 230, 201, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(165, 214, 167, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(129, 199, 132, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(102, 187, 106, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(67, 160, 71, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(56, 142, 60, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(46, 125, 50, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(27, 94, 32, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-green {
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-green-A100 {
  --tw-text-opacity: 1;
  color: rgba(185, 246, 202, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-green-A200 {
  --tw-text-opacity: 1;
  color: rgba(105, 240, 174, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-green-A400 {
  --tw-text-opacity: 1;
  color: rgba(0, 230, 118, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-green-A700 {
  --tw-text-opacity: 1;
  color: rgba(0, 200, 83, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-green-50 {
  --tw-text-opacity: 1;
  color: rgba(241, 248, 233, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-green-100 {
  --tw-text-opacity: 1;
  color: rgba(220, 237, 200, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-green-200 {
  --tw-text-opacity: 1;
  color: rgba(197, 225, 165, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-green-300 {
  --tw-text-opacity: 1;
  color: rgba(174, 213, 129, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-green-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 204, 101, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-green-500 {
  --tw-text-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-green-600 {
  --tw-text-opacity: 1;
  color: rgba(124, 179, 66, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-green-700 {
  --tw-text-opacity: 1;
  color: rgba(104, 159, 56, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-green-800 {
  --tw-text-opacity: 1;
  color: rgba(85, 139, 47, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-green-900 {
  --tw-text-opacity: 1;
  color: rgba(51, 105, 30, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-green {
  --tw-text-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-green-A100 {
  --tw-text-opacity: 1;
  color: rgba(204, 255, 144, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-green-A200 {
  --tw-text-opacity: 1;
  color: rgba(178, 255, 89, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-green-A400 {
  --tw-text-opacity: 1;
  color: rgba(118, 255, 3, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-green-A700 {
  --tw-text-opacity: 1;
  color: rgba(100, 221, 23, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-teal-50 {
  --tw-text-opacity: 1;
  color: rgba(224, 242, 241, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-teal-100 {
  --tw-text-opacity: 1;
  color: rgba(178, 223, 219, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-teal-200 {
  --tw-text-opacity: 1;
  color: rgba(128, 203, 196, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-teal-300 {
  --tw-text-opacity: 1;
  color: rgba(77, 182, 172, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-teal-400 {
  --tw-text-opacity: 1;
  color: rgba(38, 166, 154, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-teal-500 {
  --tw-text-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-teal-600 {
  --tw-text-opacity: 1;
  color: rgba(0, 137, 123, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-teal-700 {
  --tw-text-opacity: 1;
  color: rgba(0, 121, 107, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-teal-800 {
  --tw-text-opacity: 1;
  color: rgba(0, 105, 92, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-teal-900 {
  --tw-text-opacity: 1;
  color: rgba(0, 77, 64, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-teal {
  --tw-text-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-teal-A100 {
  --tw-text-opacity: 1;
  color: rgba(167, 255, 235, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-teal-A200 {
  --tw-text-opacity: 1;
  color: rgba(100, 255, 218, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-teal-A400 {
  --tw-text-opacity: 1;
  color: rgba(29, 233, 182, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-teal-A700 {
  --tw-text-opacity: 1;
  color: rgba(0, 191, 165, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(227, 242, 253, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(187, 222, 251, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(144, 202, 249, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(100, 181, 246, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(66, 165, 245, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(30, 136, 229, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(25, 118, 210, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(21, 101, 192, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(13, 71, 161, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue {
  --tw-text-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-A100 {
  --tw-text-opacity: 1;
  color: rgba(130, 177, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-A200 {
  --tw-text-opacity: 1;
  color: rgba(68, 138, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-A400 {
  --tw-text-opacity: 1;
  color: rgba(41, 121, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-A700 {
  --tw-text-opacity: 1;
  color: rgba(41, 98, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(225, 245, 254, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(179, 229, 252, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(129, 212, 250, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(79, 195, 247, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(41, 182, 246, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(3, 155, 229, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(2, 136, 209, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(2, 119, 189, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(1, 87, 155, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-blue {
  --tw-text-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-blue-A100 {
  --tw-text-opacity: 1;
  color: rgba(128, 216, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-blue-A200 {
  --tw-text-opacity: 1;
  color: rgba(64, 196, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-blue-A400 {
  --tw-text-opacity: 1;
  color: rgba(0, 176, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-light-blue-A700 {
  --tw-text-opacity: 1;
  color: rgba(0, 145, 234, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgba(232, 234, 246, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgba(197, 202, 233, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(159, 168, 218, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgba(121, 134, 203, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(92, 107, 192, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(57, 73, 171, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(48, 63, 159, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgba(40, 53, 147, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgba(26, 35, 126, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-indigo {
  --tw-text-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-indigo-A100 {
  --tw-text-opacity: 1;
  color: rgba(140, 158, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-indigo-A200 {
  --tw-text-opacity: 1;
  color: rgba(83, 109, 254, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-indigo-A400 {
  --tw-text-opacity: 1;
  color: rgba(61, 90, 254, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-indigo-A700 {
  --tw-text-opacity: 1;
  color: rgba(48, 79, 254, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-purple-50 {
  --tw-text-opacity: 1;
  color: rgba(243, 229, 245, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(225, 190, 231, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(206, 147, 216, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(186, 104, 200, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(171, 71, 188, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(142, 36, 170, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(123, 31, 162, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(106, 27, 154, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(74, 20, 140, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-purple {
  --tw-text-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-purple-A100 {
  --tw-text-opacity: 1;
  color: rgba(234, 128, 252, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-purple-A200 {
  --tw-text-opacity: 1;
  color: rgba(224, 64, 251, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-purple-A400 {
  --tw-text-opacity: 1;
  color: rgba(213, 0, 249, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-purple-A700 {
  --tw-text-opacity: 1;
  color: rgba(170, 0, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-purple-50 {
  --tw-text-opacity: 1;
  color: rgba(237, 231, 246, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 196, 233, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(179, 157, 219, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(149, 117, 205, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(126, 87, 194, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(94, 53, 177, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(81, 45, 168, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(69, 39, 160, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(49, 27, 146, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-purple {
  --tw-text-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-purple-A100 {
  --tw-text-opacity: 1;
  color: rgba(179, 136, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-purple-A200 {
  --tw-text-opacity: 1;
  color: rgba(124, 77, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-purple-A400 {
  --tw-text-opacity: 1;
  color: rgba(101, 31, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-deep-purple-A700 {
  --tw-text-opacity: 1;
  color: rgba(98, 0, 234, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-pink-50 {
  --tw-text-opacity: 1;
  color: rgba(252, 228, 236, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(248, 187, 208, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(244, 143, 177, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(240, 98, 146, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(236, 64, 122, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(216, 27, 96, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(194, 24, 91, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(173, 20, 87, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-pink-900 {
  --tw-text-opacity: 1;
  color: rgba(136, 14, 79, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-pink {
  --tw-text-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-pink-A100 {
  --tw-text-opacity: 1;
  color: rgba(255, 128, 171, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-pink-A200 {
  --tw-text-opacity: 1;
  color: rgba(255, 64, 129, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-pink-A400 {
  --tw-text-opacity: 1;
  color: rgba(245, 0, 87, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-pink-A700 {
  --tw-text-opacity: 1;
  color: rgba(197, 17, 98, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-lime-50 {
  --tw-text-opacity: 1;
  color: rgba(249, 251, 231, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-lime-100 {
  --tw-text-opacity: 1;
  color: rgba(240, 244, 195, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-lime-200 {
  --tw-text-opacity: 1;
  color: rgba(230, 238, 156, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-lime-300 {
  --tw-text-opacity: 1;
  color: rgba(220, 231, 117, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-lime-400 {
  --tw-text-opacity: 1;
  color: rgba(212, 225, 87, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-lime-500 {
  --tw-text-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-lime-600 {
  --tw-text-opacity: 1;
  color: rgba(192, 202, 51, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-lime-700 {
  --tw-text-opacity: 1;
  color: rgba(175, 180, 43, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-lime-800 {
  --tw-text-opacity: 1;
  color: rgba(158, 157, 36, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-lime-900 {
  --tw-text-opacity: 1;
  color: rgba(130, 119, 23, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-lime {
  --tw-text-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-lime-A100 {
  --tw-text-opacity: 1;
  color: rgba(244, 255, 129, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-lime-A200 {
  --tw-text-opacity: 1;
  color: rgba(238, 255, 65, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-lime-A400 {
  --tw-text-opacity: 1;
  color: rgba(198, 255, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-lime-A700 {
  --tw-text-opacity: 1;
  color: rgba(174, 234, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-amber-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 248, 225, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-amber-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 236, 179, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-amber-200 {
  --tw-text-opacity: 1;
  color: rgba(255, 224, 130, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-amber-300 {
  --tw-text-opacity: 1;
  color: rgba(255, 213, 79, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-amber-400 {
  --tw-text-opacity: 1;
  color: rgba(255, 202, 40, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-amber-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-amber-600 {
  --tw-text-opacity: 1;
  color: rgba(255, 179, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-amber-700 {
  --tw-text-opacity: 1;
  color: rgba(255, 160, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-amber-800 {
  --tw-text-opacity: 1;
  color: rgba(255, 143, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-amber-900 {
  --tw-text-opacity: 1;
  color: rgba(255, 111, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-amber {
  --tw-text-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-amber-A100 {
  --tw-text-opacity: 1;
  color: rgba(255, 229, 127, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-amber-A200 {
  --tw-text-opacity: 1;
  color: rgba(255, 215, 64, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-amber-A400 {
  --tw-text-opacity: 1;
  color: rgba(255, 196, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-amber-A700 {
  --tw-text-opacity: 1;
  color: rgba(255, 171, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-brown-50 {
  --tw-text-opacity: 1;
  color: rgba(239, 235, 233, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-brown-100 {
  --tw-text-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-brown-200 {
  --tw-text-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-brown-300 {
  --tw-text-opacity: 1;
  color: rgba(161, 136, 127, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-brown-400 {
  --tw-text-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-brown-500 {
  --tw-text-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-brown-600 {
  --tw-text-opacity: 1;
  color: rgba(109, 76, 65, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-brown-700 {
  --tw-text-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-brown-800 {
  --tw-text-opacity: 1;
  color: rgba(78, 52, 46, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-brown-900 {
  --tw-text-opacity: 1;
  color: rgba(62, 39, 35, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-brown {
  --tw-text-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-brown-A100 {
  --tw-text-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-brown-A200 {
  --tw-text-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-brown-A400 {
  --tw-text-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-brown-A700 {
  --tw-text-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 239, 241, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(144, 164, 174, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(84, 110, 122, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 71, 79, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(38, 50, 56, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-gray {
  --tw-text-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-gray-A100 {
  --tw-text-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-gray-A200 {
  --tw-text-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-gray-A400 {
  --tw-text-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-gray-A700 {
  --tw-text-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-cyan-50 {
  --tw-text-opacity: 1;
  color: rgba(224, 247, 250, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-cyan-100 {
  --tw-text-opacity: 1;
  color: rgba(178, 235, 242, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgba(128, 222, 234, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-cyan-300 {
  --tw-text-opacity: 1;
  color: rgba(77, 208, 225, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-cyan-400 {
  --tw-text-opacity: 1;
  color: rgba(38, 198, 218, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgba(0, 172, 193, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-cyan-700 {
  --tw-text-opacity: 1;
  color: rgba(0, 151, 167, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-cyan-800 {
  --tw-text-opacity: 1;
  color: rgba(0, 131, 143, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-cyan-900 {
  --tw-text-opacity: 1;
  color: rgba(0, 96, 100, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-cyan {
  --tw-text-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-cyan-A100 {
  --tw-text-opacity: 1;
  color: rgba(132, 255, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-cyan-A200 {
  --tw-text-opacity: 1;
  color: rgba(24, 255, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-cyan-A400 {
  --tw-text-opacity: 1;
  color: rgba(0, 229, 255, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-cyan-A700 {
  --tw-text-opacity: 1;
  color: rgba(0, 184, 212, var(--tw-text-opacity))
}

.focus-within\:text-transparent:focus-within {
  color: transparent
}

.focus-within\:text-current:focus-within {
  color: currentColor
}

.focus-within\:text-black:focus-within {
  --tw-text-opacity: 1;
  color: rgba(34, 41, 47, var(--tw-text-opacity))
}

.focus-within\:text-white:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.focus-within\:text-grey-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity))
}

.focus-within\:text-grey-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-text-opacity))
}

.focus-within\:text-grey-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-text-opacity))
}

.focus-within\:text-grey-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-text-opacity))
}

.focus-within\:text-grey-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-text-opacity))
}

.focus-within\:text-grey-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.focus-within\:text-grey-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-text-opacity))
}

.focus-within\:text-grey-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.focus-within\:text-grey-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-text-opacity))
}

.focus-within\:text-grey-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity))
}

.focus-within\:text-grey:focus-within {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.focus-within\:text-grey-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(213, 213, 213, var(--tw-text-opacity))
}

.focus-within\:text-grey-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-text-opacity))
}

.focus-within\:text-grey-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(48, 48, 48, var(--tw-text-opacity))
}

.focus-within\:text-grey-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.focus-within\:text-gray-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity))
}

.focus-within\:text-gray-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-text-opacity))
}

.focus-within\:text-gray-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-text-opacity))
}

.focus-within\:text-gray-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-text-opacity))
}

.focus-within\:text-gray-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-text-opacity))
}

.focus-within\:text-gray-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.focus-within\:text-gray-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-text-opacity))
}

.focus-within\:text-gray-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.focus-within\:text-gray-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-text-opacity))
}

.focus-within\:text-gray-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity))
}

.focus-within\:text-gray:focus-within {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.focus-within\:text-gray-hover:focus-within {
  color: rgba(0, 0, 0, 0.04)
}

.focus-within\:text-gray-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(213, 213, 213, var(--tw-text-opacity))
}

.focus-within\:text-gray-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-text-opacity))
}

.focus-within\:text-gray-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(48, 48, 48, var(--tw-text-opacity))
}

.focus-within\:text-gray-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.focus-within\:text-red-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 235, 238, var(--tw-text-opacity))
}

.focus-within\:text-red-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 205, 210, var(--tw-text-opacity))
}

.focus-within\:text-red-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(239, 154, 154, var(--tw-text-opacity))
}

.focus-within\:text-red-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(229, 115, 115, var(--tw-text-opacity))
}

.focus-within\:text-red-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(239, 83, 80, var(--tw-text-opacity))
}

.focus-within\:text-red-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity))
}

.focus-within\:text-red-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(229, 57, 53, var(--tw-text-opacity))
}

.focus-within\:text-red-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(211, 47, 47, var(--tw-text-opacity))
}

.focus-within\:text-red-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(198, 40, 40, var(--tw-text-opacity))
}

.focus-within\:text-red-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(183, 28, 28, var(--tw-text-opacity))
}

.focus-within\:text-red:focus-within {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity))
}

.focus-within\:text-red-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 138, 128, var(--tw-text-opacity))
}

.focus-within\:text-red-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 82, 82, var(--tw-text-opacity))
}

.focus-within\:text-red-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 23, 68, var(--tw-text-opacity))
}

.focus-within\:text-red-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(213, 0, 0, var(--tw-text-opacity))
}

.focus-within\:text-orange-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 243, 224, var(--tw-text-opacity))
}

.focus-within\:text-orange-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 224, 178, var(--tw-text-opacity))
}

.focus-within\:text-orange-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 204, 128, var(--tw-text-opacity))
}

.focus-within\:text-orange-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 183, 77, var(--tw-text-opacity))
}

.focus-within\:text-orange-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 167, 38, var(--tw-text-opacity))
}

.focus-within\:text-orange-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-text-opacity))
}

.focus-within\:text-orange-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(251, 140, 0, var(--tw-text-opacity))
}

.focus-within\:text-orange-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(245, 124, 0, var(--tw-text-opacity))
}

.focus-within\:text-orange-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(239, 108, 0, var(--tw-text-opacity))
}

.focus-within\:text-orange-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(230, 81, 0, var(--tw-text-opacity))
}

.focus-within\:text-orange:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-text-opacity))
}

.focus-within\:text-orange-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 209, 128, var(--tw-text-opacity))
}

.focus-within\:text-orange-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 171, 64, var(--tw-text-opacity))
}

.focus-within\:text-orange-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 145, 0, var(--tw-text-opacity))
}

.focus-within\:text-orange-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 109, 0, var(--tw-text-opacity))
}

.focus-within\:text-deep-orange-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(251, 233, 231, var(--tw-text-opacity))
}

.focus-within\:text-deep-orange-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 204, 188, var(--tw-text-opacity))
}

.focus-within\:text-deep-orange-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 171, 145, var(--tw-text-opacity))
}

.focus-within\:text-deep-orange-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 138, 101, var(--tw-text-opacity))
}

.focus-within\:text-deep-orange-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 112, 67, var(--tw-text-opacity))
}

.focus-within\:text-deep-orange-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-text-opacity))
}

.focus-within\:text-deep-orange-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(244, 81, 30, var(--tw-text-opacity))
}

.focus-within\:text-deep-orange-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(230, 74, 25, var(--tw-text-opacity))
}

.focus-within\:text-deep-orange-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(216, 67, 21, var(--tw-text-opacity))
}

.focus-within\:text-deep-orange-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(191, 54, 12, var(--tw-text-opacity))
}

.focus-within\:text-deep-orange:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-text-opacity))
}

.focus-within\:text-deep-orange-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 158, 128, var(--tw-text-opacity))
}

.focus-within\:text-deep-orange-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 110, 64, var(--tw-text-opacity))
}

.focus-within\:text-deep-orange-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 61, 0, var(--tw-text-opacity))
}

.focus-within\:text-deep-orange-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(221, 44, 0, var(--tw-text-opacity))
}

.focus-within\:text-yellow-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 253, 231, var(--tw-text-opacity))
}

.focus-within\:text-yellow-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 249, 196, var(--tw-text-opacity))
}

.focus-within\:text-yellow-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 245, 157, var(--tw-text-opacity))
}

.focus-within\:text-yellow-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 241, 118, var(--tw-text-opacity))
}

.focus-within\:text-yellow-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 238, 88, var(--tw-text-opacity))
}

.focus-within\:text-yellow-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 235, 59, var(--tw-text-opacity))
}

.focus-within\:text-yellow-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(253, 216, 53, var(--tw-text-opacity))
}

.focus-within\:text-yellow-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(251, 192, 45, var(--tw-text-opacity))
}

.focus-within\:text-yellow-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 37, var(--tw-text-opacity))
}

.focus-within\:text-yellow-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(245, 127, 23, var(--tw-text-opacity))
}

.focus-within\:text-yellow:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 235, 59, var(--tw-text-opacity))
}

.focus-within\:text-yellow-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 141, var(--tw-text-opacity))
}

.focus-within\:text-yellow-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 0, var(--tw-text-opacity))
}

.focus-within\:text-yellow-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 234, 0, var(--tw-text-opacity))
}

.focus-within\:text-yellow-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 214, 0, var(--tw-text-opacity))
}

.focus-within\:text-green-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(232, 245, 233, var(--tw-text-opacity))
}

.focus-within\:text-green-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(200, 230, 201, var(--tw-text-opacity))
}

.focus-within\:text-green-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(165, 214, 167, var(--tw-text-opacity))
}

.focus-within\:text-green-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(129, 199, 132, var(--tw-text-opacity))
}

.focus-within\:text-green-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(102, 187, 106, var(--tw-text-opacity))
}

.focus-within\:text-green-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity))
}

.focus-within\:text-green-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(67, 160, 71, var(--tw-text-opacity))
}

.focus-within\:text-green-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(56, 142, 60, var(--tw-text-opacity))
}

.focus-within\:text-green-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(46, 125, 50, var(--tw-text-opacity))
}

.focus-within\:text-green-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(27, 94, 32, var(--tw-text-opacity))
}

.focus-within\:text-green:focus-within {
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity))
}

.focus-within\:text-green-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(185, 246, 202, var(--tw-text-opacity))
}

.focus-within\:text-green-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(105, 240, 174, var(--tw-text-opacity))
}

.focus-within\:text-green-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 230, 118, var(--tw-text-opacity))
}

.focus-within\:text-green-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 200, 83, var(--tw-text-opacity))
}

.focus-within\:text-light-green-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(241, 248, 233, var(--tw-text-opacity))
}

.focus-within\:text-light-green-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(220, 237, 200, var(--tw-text-opacity))
}

.focus-within\:text-light-green-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(197, 225, 165, var(--tw-text-opacity))
}

.focus-within\:text-light-green-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(174, 213, 129, var(--tw-text-opacity))
}

.focus-within\:text-light-green-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(156, 204, 101, var(--tw-text-opacity))
}

.focus-within\:text-light-green-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-text-opacity))
}

.focus-within\:text-light-green-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(124, 179, 66, var(--tw-text-opacity))
}

.focus-within\:text-light-green-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(104, 159, 56, var(--tw-text-opacity))
}

.focus-within\:text-light-green-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(85, 139, 47, var(--tw-text-opacity))
}

.focus-within\:text-light-green-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(51, 105, 30, var(--tw-text-opacity))
}

.focus-within\:text-light-green:focus-within {
  --tw-text-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-text-opacity))
}

.focus-within\:text-light-green-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(204, 255, 144, var(--tw-text-opacity))
}

.focus-within\:text-light-green-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(178, 255, 89, var(--tw-text-opacity))
}

.focus-within\:text-light-green-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(118, 255, 3, var(--tw-text-opacity))
}

.focus-within\:text-light-green-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(100, 221, 23, var(--tw-text-opacity))
}

.focus-within\:text-teal-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(224, 242, 241, var(--tw-text-opacity))
}

.focus-within\:text-teal-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(178, 223, 219, var(--tw-text-opacity))
}

.focus-within\:text-teal-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(128, 203, 196, var(--tw-text-opacity))
}

.focus-within\:text-teal-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(77, 182, 172, var(--tw-text-opacity))
}

.focus-within\:text-teal-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(38, 166, 154, var(--tw-text-opacity))
}

.focus-within\:text-teal-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-text-opacity))
}

.focus-within\:text-teal-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 137, 123, var(--tw-text-opacity))
}

.focus-within\:text-teal-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 121, 107, var(--tw-text-opacity))
}

.focus-within\:text-teal-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 105, 92, var(--tw-text-opacity))
}

.focus-within\:text-teal-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 77, 64, var(--tw-text-opacity))
}

.focus-within\:text-teal:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-text-opacity))
}

.focus-within\:text-teal-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(167, 255, 235, var(--tw-text-opacity))
}

.focus-within\:text-teal-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(100, 255, 218, var(--tw-text-opacity))
}

.focus-within\:text-teal-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(29, 233, 182, var(--tw-text-opacity))
}

.focus-within\:text-teal-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 191, 165, var(--tw-text-opacity))
}

.focus-within\:text-blue-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(227, 242, 253, var(--tw-text-opacity))
}

.focus-within\:text-blue-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(187, 222, 251, var(--tw-text-opacity))
}

.focus-within\:text-blue-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(144, 202, 249, var(--tw-text-opacity))
}

.focus-within\:text-blue-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(100, 181, 246, var(--tw-text-opacity))
}

.focus-within\:text-blue-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(66, 165, 245, var(--tw-text-opacity))
}

.focus-within\:text-blue-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-text-opacity))
}

.focus-within\:text-blue-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(30, 136, 229, var(--tw-text-opacity))
}

.focus-within\:text-blue-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(25, 118, 210, var(--tw-text-opacity))
}

.focus-within\:text-blue-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(21, 101, 192, var(--tw-text-opacity))
}

.focus-within\:text-blue-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(13, 71, 161, var(--tw-text-opacity))
}

.focus-within\:text-blue:focus-within {
  --tw-text-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-text-opacity))
}

.focus-within\:text-blue-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(130, 177, 255, var(--tw-text-opacity))
}

.focus-within\:text-blue-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(68, 138, 255, var(--tw-text-opacity))
}

.focus-within\:text-blue-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(41, 121, 255, var(--tw-text-opacity))
}

.focus-within\:text-blue-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(41, 98, 255, var(--tw-text-opacity))
}

.focus-within\:text-light-blue-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(225, 245, 254, var(--tw-text-opacity))
}

.focus-within\:text-light-blue-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(179, 229, 252, var(--tw-text-opacity))
}

.focus-within\:text-light-blue-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(129, 212, 250, var(--tw-text-opacity))
}

.focus-within\:text-light-blue-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(79, 195, 247, var(--tw-text-opacity))
}

.focus-within\:text-light-blue-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(41, 182, 246, var(--tw-text-opacity))
}

.focus-within\:text-light-blue-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-text-opacity))
}

.focus-within\:text-light-blue-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(3, 155, 229, var(--tw-text-opacity))
}

.focus-within\:text-light-blue-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(2, 136, 209, var(--tw-text-opacity))
}

.focus-within\:text-light-blue-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(2, 119, 189, var(--tw-text-opacity))
}

.focus-within\:text-light-blue-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(1, 87, 155, var(--tw-text-opacity))
}

.focus-within\:text-light-blue:focus-within {
  --tw-text-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-text-opacity))
}

.focus-within\:text-light-blue-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(128, 216, 255, var(--tw-text-opacity))
}

.focus-within\:text-light-blue-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(64, 196, 255, var(--tw-text-opacity))
}

.focus-within\:text-light-blue-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 176, 255, var(--tw-text-opacity))
}

.focus-within\:text-light-blue-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 145, 234, var(--tw-text-opacity))
}

.focus-within\:text-indigo-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(232, 234, 246, var(--tw-text-opacity))
}

.focus-within\:text-indigo-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(197, 202, 233, var(--tw-text-opacity))
}

.focus-within\:text-indigo-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(159, 168, 218, var(--tw-text-opacity))
}

.focus-within\:text-indigo-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(121, 134, 203, var(--tw-text-opacity))
}

.focus-within\:text-indigo-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(92, 107, 192, var(--tw-text-opacity))
}

.focus-within\:text-indigo-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-text-opacity))
}

.focus-within\:text-indigo-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(57, 73, 171, var(--tw-text-opacity))
}

.focus-within\:text-indigo-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(48, 63, 159, var(--tw-text-opacity))
}

.focus-within\:text-indigo-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(40, 53, 147, var(--tw-text-opacity))
}

.focus-within\:text-indigo-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(26, 35, 126, var(--tw-text-opacity))
}

.focus-within\:text-indigo:focus-within {
  --tw-text-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-text-opacity))
}

.focus-within\:text-indigo-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(140, 158, 255, var(--tw-text-opacity))
}

.focus-within\:text-indigo-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(83, 109, 254, var(--tw-text-opacity))
}

.focus-within\:text-indigo-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(61, 90, 254, var(--tw-text-opacity))
}

.focus-within\:text-indigo-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(48, 79, 254, var(--tw-text-opacity))
}

.focus-within\:text-purple-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(243, 229, 245, var(--tw-text-opacity))
}

.focus-within\:text-purple-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(225, 190, 231, var(--tw-text-opacity))
}

.focus-within\:text-purple-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(206, 147, 216, var(--tw-text-opacity))
}

.focus-within\:text-purple-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(186, 104, 200, var(--tw-text-opacity))
}

.focus-within\:text-purple-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(171, 71, 188, var(--tw-text-opacity))
}

.focus-within\:text-purple-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-text-opacity))
}

.focus-within\:text-purple-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(142, 36, 170, var(--tw-text-opacity))
}

.focus-within\:text-purple-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(123, 31, 162, var(--tw-text-opacity))
}

.focus-within\:text-purple-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(106, 27, 154, var(--tw-text-opacity))
}

.focus-within\:text-purple-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(74, 20, 140, var(--tw-text-opacity))
}

.focus-within\:text-purple:focus-within {
  --tw-text-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-text-opacity))
}

.focus-within\:text-purple-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(234, 128, 252, var(--tw-text-opacity))
}

.focus-within\:text-purple-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(224, 64, 251, var(--tw-text-opacity))
}

.focus-within\:text-purple-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(213, 0, 249, var(--tw-text-opacity))
}

.focus-within\:text-purple-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(170, 0, 255, var(--tw-text-opacity))
}

.focus-within\:text-deep-purple-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(237, 231, 246, var(--tw-text-opacity))
}

.focus-within\:text-deep-purple-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(209, 196, 233, var(--tw-text-opacity))
}

.focus-within\:text-deep-purple-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(179, 157, 219, var(--tw-text-opacity))
}

.focus-within\:text-deep-purple-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(149, 117, 205, var(--tw-text-opacity))
}

.focus-within\:text-deep-purple-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(126, 87, 194, var(--tw-text-opacity))
}

.focus-within\:text-deep-purple-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-text-opacity))
}

.focus-within\:text-deep-purple-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(94, 53, 177, var(--tw-text-opacity))
}

.focus-within\:text-deep-purple-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(81, 45, 168, var(--tw-text-opacity))
}

.focus-within\:text-deep-purple-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(69, 39, 160, var(--tw-text-opacity))
}

.focus-within\:text-deep-purple-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(49, 27, 146, var(--tw-text-opacity))
}

.focus-within\:text-deep-purple:focus-within {
  --tw-text-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-text-opacity))
}

.focus-within\:text-deep-purple-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(179, 136, 255, var(--tw-text-opacity))
}

.focus-within\:text-deep-purple-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(124, 77, 255, var(--tw-text-opacity))
}

.focus-within\:text-deep-purple-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(101, 31, 255, var(--tw-text-opacity))
}

.focus-within\:text-deep-purple-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(98, 0, 234, var(--tw-text-opacity))
}

.focus-within\:text-pink-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(252, 228, 236, var(--tw-text-opacity))
}

.focus-within\:text-pink-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(248, 187, 208, var(--tw-text-opacity))
}

.focus-within\:text-pink-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(244, 143, 177, var(--tw-text-opacity))
}

.focus-within\:text-pink-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(240, 98, 146, var(--tw-text-opacity))
}

.focus-within\:text-pink-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(236, 64, 122, var(--tw-text-opacity))
}

.focus-within\:text-pink-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-text-opacity))
}

.focus-within\:text-pink-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(216, 27, 96, var(--tw-text-opacity))
}

.focus-within\:text-pink-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(194, 24, 91, var(--tw-text-opacity))
}

.focus-within\:text-pink-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(173, 20, 87, var(--tw-text-opacity))
}

.focus-within\:text-pink-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(136, 14, 79, var(--tw-text-opacity))
}

.focus-within\:text-pink:focus-within {
  --tw-text-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-text-opacity))
}

.focus-within\:text-pink-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 128, 171, var(--tw-text-opacity))
}

.focus-within\:text-pink-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 64, 129, var(--tw-text-opacity))
}

.focus-within\:text-pink-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(245, 0, 87, var(--tw-text-opacity))
}

.focus-within\:text-pink-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(197, 17, 98, var(--tw-text-opacity))
}

.focus-within\:text-lime-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(249, 251, 231, var(--tw-text-opacity))
}

.focus-within\:text-lime-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(240, 244, 195, var(--tw-text-opacity))
}

.focus-within\:text-lime-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(230, 238, 156, var(--tw-text-opacity))
}

.focus-within\:text-lime-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(220, 231, 117, var(--tw-text-opacity))
}

.focus-within\:text-lime-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(212, 225, 87, var(--tw-text-opacity))
}

.focus-within\:text-lime-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-text-opacity))
}

.focus-within\:text-lime-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(192, 202, 51, var(--tw-text-opacity))
}

.focus-within\:text-lime-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(175, 180, 43, var(--tw-text-opacity))
}

.focus-within\:text-lime-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(158, 157, 36, var(--tw-text-opacity))
}

.focus-within\:text-lime-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(130, 119, 23, var(--tw-text-opacity))
}

.focus-within\:text-lime:focus-within {
  --tw-text-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-text-opacity))
}

.focus-within\:text-lime-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(244, 255, 129, var(--tw-text-opacity))
}

.focus-within\:text-lime-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(238, 255, 65, var(--tw-text-opacity))
}

.focus-within\:text-lime-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(198, 255, 0, var(--tw-text-opacity))
}

.focus-within\:text-lime-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(174, 234, 0, var(--tw-text-opacity))
}

.focus-within\:text-amber-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 248, 225, var(--tw-text-opacity))
}

.focus-within\:text-amber-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 236, 179, var(--tw-text-opacity))
}

.focus-within\:text-amber-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 224, 130, var(--tw-text-opacity))
}

.focus-within\:text-amber-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 213, 79, var(--tw-text-opacity))
}

.focus-within\:text-amber-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 202, 40, var(--tw-text-opacity))
}

.focus-within\:text-amber-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-text-opacity))
}

.focus-within\:text-amber-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 179, 0, var(--tw-text-opacity))
}

.focus-within\:text-amber-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 160, 0, var(--tw-text-opacity))
}

.focus-within\:text-amber-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 143, 0, var(--tw-text-opacity))
}

.focus-within\:text-amber-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 111, 0, var(--tw-text-opacity))
}

.focus-within\:text-amber:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-text-opacity))
}

.focus-within\:text-amber-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 229, 127, var(--tw-text-opacity))
}

.focus-within\:text-amber-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 215, 64, var(--tw-text-opacity))
}

.focus-within\:text-amber-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 196, 0, var(--tw-text-opacity))
}

.focus-within\:text-amber-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 171, 0, var(--tw-text-opacity))
}

.focus-within\:text-brown-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(239, 235, 233, var(--tw-text-opacity))
}

.focus-within\:text-brown-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-text-opacity))
}

.focus-within\:text-brown-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-text-opacity))
}

.focus-within\:text-brown-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(161, 136, 127, var(--tw-text-opacity))
}

.focus-within\:text-brown-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-text-opacity))
}

.focus-within\:text-brown-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-text-opacity))
}

.focus-within\:text-brown-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(109, 76, 65, var(--tw-text-opacity))
}

.focus-within\:text-brown-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-text-opacity))
}

.focus-within\:text-brown-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(78, 52, 46, var(--tw-text-opacity))
}

.focus-within\:text-brown-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(62, 39, 35, var(--tw-text-opacity))
}

.focus-within\:text-brown:focus-within {
  --tw-text-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-text-opacity))
}

.focus-within\:text-brown-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-text-opacity))
}

.focus-within\:text-brown-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-text-opacity))
}

.focus-within\:text-brown-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-text-opacity))
}

.focus-within\:text-brown-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-text-opacity))
}

.focus-within\:text-blue-gray-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(236, 239, 241, var(--tw-text-opacity))
}

.focus-within\:text-blue-gray-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-text-opacity))
}

.focus-within\:text-blue-gray-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-text-opacity))
}

.focus-within\:text-blue-gray-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(144, 164, 174, var(--tw-text-opacity))
}

.focus-within\:text-blue-gray-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-text-opacity))
}

.focus-within\:text-blue-gray-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-text-opacity))
}

.focus-within\:text-blue-gray-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(84, 110, 122, var(--tw-text-opacity))
}

.focus-within\:text-blue-gray-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-text-opacity))
}

.focus-within\:text-blue-gray-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(55, 71, 79, var(--tw-text-opacity))
}

.focus-within\:text-blue-gray-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(38, 50, 56, var(--tw-text-opacity))
}

.focus-within\:text-blue-gray:focus-within {
  --tw-text-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-text-opacity))
}

.focus-within\:text-blue-gray-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-text-opacity))
}

.focus-within\:text-blue-gray-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-text-opacity))
}

.focus-within\:text-blue-gray-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-text-opacity))
}

.focus-within\:text-blue-gray-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-text-opacity))
}

.focus-within\:text-cyan-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(224, 247, 250, var(--tw-text-opacity))
}

.focus-within\:text-cyan-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(178, 235, 242, var(--tw-text-opacity))
}

.focus-within\:text-cyan-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(128, 222, 234, var(--tw-text-opacity))
}

.focus-within\:text-cyan-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(77, 208, 225, var(--tw-text-opacity))
}

.focus-within\:text-cyan-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(38, 198, 218, var(--tw-text-opacity))
}

.focus-within\:text-cyan-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-text-opacity))
}

.focus-within\:text-cyan-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 172, 193, var(--tw-text-opacity))
}

.focus-within\:text-cyan-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 151, 167, var(--tw-text-opacity))
}

.focus-within\:text-cyan-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 131, 143, var(--tw-text-opacity))
}

.focus-within\:text-cyan-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 96, 100, var(--tw-text-opacity))
}

.focus-within\:text-cyan:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-text-opacity))
}

.focus-within\:text-cyan-A100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(132, 255, 255, var(--tw-text-opacity))
}

.focus-within\:text-cyan-A200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(24, 255, 255, var(--tw-text-opacity))
}

.focus-within\:text-cyan-A400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 229, 255, var(--tw-text-opacity))
}

.focus-within\:text-cyan-A700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 184, 212, var(--tw-text-opacity))
}

.hover\:text-transparent:hover {
  color: transparent
}

.hover\:text-current:hover {
  color: currentColor
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgba(34, 41, 47, var(--tw-text-opacity))
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.hover\:text-grey-50:hover {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity))
}

.hover\:text-grey-100:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-text-opacity))
}

.hover\:text-grey-200:hover {
  --tw-text-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-text-opacity))
}

.hover\:text-grey-300:hover {
  --tw-text-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-text-opacity))
}

.hover\:text-grey-400:hover {
  --tw-text-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-text-opacity))
}

.hover\:text-grey-500:hover {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.hover\:text-grey-600:hover {
  --tw-text-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-text-opacity))
}

.hover\:text-grey-700:hover {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.hover\:text-grey-800:hover {
  --tw-text-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-text-opacity))
}

.hover\:text-grey-900:hover {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity))
}

.hover\:text-grey:hover {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.hover\:text-grey-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(213, 213, 213, var(--tw-text-opacity))
}

.hover\:text-grey-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-text-opacity))
}

.hover\:text-grey-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(48, 48, 48, var(--tw-text-opacity))
}

.hover\:text-grey-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.hover\:text-gray-50:hover {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity))
}

.hover\:text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-text-opacity))
}

.hover\:text-gray-200:hover {
  --tw-text-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-text-opacity))
}

.hover\:text-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-text-opacity))
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-text-opacity))
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-text-opacity))
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-text-opacity))
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity))
}

.hover\:text-gray:hover {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.hover\:text-gray-hover:hover {
  color: rgba(0, 0, 0, 0.04)
}

.hover\:text-gray-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(213, 213, 213, var(--tw-text-opacity))
}

.hover\:text-gray-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-text-opacity))
}

.hover\:text-gray-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(48, 48, 48, var(--tw-text-opacity))
}

.hover\:text-gray-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.hover\:text-red-50:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 235, 238, var(--tw-text-opacity))
}

.hover\:text-red-100:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 205, 210, var(--tw-text-opacity))
}

.hover\:text-red-200:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 154, 154, var(--tw-text-opacity))
}

.hover\:text-red-300:hover {
  --tw-text-opacity: 1;
  color: rgba(229, 115, 115, var(--tw-text-opacity))
}

.hover\:text-red-400:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 83, 80, var(--tw-text-opacity))
}

.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity))
}

.hover\:text-red-600:hover {
  --tw-text-opacity: 1;
  color: rgba(229, 57, 53, var(--tw-text-opacity))
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgba(211, 47, 47, var(--tw-text-opacity))
}

.hover\:text-red-800:hover {
  --tw-text-opacity: 1;
  color: rgba(198, 40, 40, var(--tw-text-opacity))
}

.hover\:text-red-900:hover {
  --tw-text-opacity: 1;
  color: rgba(183, 28, 28, var(--tw-text-opacity))
}

.hover\:text-red:hover {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity))
}

.hover\:text-red-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 138, 128, var(--tw-text-opacity))
}

.hover\:text-red-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 82, 82, var(--tw-text-opacity))
}

.hover\:text-red-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 23, 68, var(--tw-text-opacity))
}

.hover\:text-red-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(213, 0, 0, var(--tw-text-opacity))
}

.hover\:text-orange-50:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 243, 224, var(--tw-text-opacity))
}

.hover\:text-orange-100:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 224, 178, var(--tw-text-opacity))
}

.hover\:text-orange-200:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 204, 128, var(--tw-text-opacity))
}

.hover\:text-orange-300:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 183, 77, var(--tw-text-opacity))
}

.hover\:text-orange-400:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 167, 38, var(--tw-text-opacity))
}

.hover\:text-orange-500:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-text-opacity))
}

.hover\:text-orange-600:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 140, 0, var(--tw-text-opacity))
}

.hover\:text-orange-700:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 124, 0, var(--tw-text-opacity))
}

.hover\:text-orange-800:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 108, 0, var(--tw-text-opacity))
}

.hover\:text-orange-900:hover {
  --tw-text-opacity: 1;
  color: rgba(230, 81, 0, var(--tw-text-opacity))
}

.hover\:text-orange:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-text-opacity))
}

.hover\:text-orange-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 209, 128, var(--tw-text-opacity))
}

.hover\:text-orange-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 171, 64, var(--tw-text-opacity))
}

.hover\:text-orange-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 145, 0, var(--tw-text-opacity))
}

.hover\:text-orange-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 109, 0, var(--tw-text-opacity))
}

.hover\:text-deep-orange-50:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 233, 231, var(--tw-text-opacity))
}

.hover\:text-deep-orange-100:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 204, 188, var(--tw-text-opacity))
}

.hover\:text-deep-orange-200:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 171, 145, var(--tw-text-opacity))
}

.hover\:text-deep-orange-300:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 138, 101, var(--tw-text-opacity))
}

.hover\:text-deep-orange-400:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 112, 67, var(--tw-text-opacity))
}

.hover\:text-deep-orange-500:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-text-opacity))
}

.hover\:text-deep-orange-600:hover {
  --tw-text-opacity: 1;
  color: rgba(244, 81, 30, var(--tw-text-opacity))
}

.hover\:text-deep-orange-700:hover {
  --tw-text-opacity: 1;
  color: rgba(230, 74, 25, var(--tw-text-opacity))
}

.hover\:text-deep-orange-800:hover {
  --tw-text-opacity: 1;
  color: rgba(216, 67, 21, var(--tw-text-opacity))
}

.hover\:text-deep-orange-900:hover {
  --tw-text-opacity: 1;
  color: rgba(191, 54, 12, var(--tw-text-opacity))
}

.hover\:text-deep-orange:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-text-opacity))
}

.hover\:text-deep-orange-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 158, 128, var(--tw-text-opacity))
}

.hover\:text-deep-orange-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 110, 64, var(--tw-text-opacity))
}

.hover\:text-deep-orange-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 61, 0, var(--tw-text-opacity))
}

.hover\:text-deep-orange-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(221, 44, 0, var(--tw-text-opacity))
}

.hover\:text-yellow-50:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 253, 231, var(--tw-text-opacity))
}

.hover\:text-yellow-100:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 249, 196, var(--tw-text-opacity))
}

.hover\:text-yellow-200:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 245, 157, var(--tw-text-opacity))
}

.hover\:text-yellow-300:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 241, 118, var(--tw-text-opacity))
}

.hover\:text-yellow-400:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 238, 88, var(--tw-text-opacity))
}

.hover\:text-yellow-500:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 235, 59, var(--tw-text-opacity))
}

.hover\:text-yellow-600:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 216, 53, var(--tw-text-opacity))
}

.hover\:text-yellow-700:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 192, 45, var(--tw-text-opacity))
}

.hover\:text-yellow-800:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 37, var(--tw-text-opacity))
}

.hover\:text-yellow-900:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 127, 23, var(--tw-text-opacity))
}

.hover\:text-yellow:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 235, 59, var(--tw-text-opacity))
}

.hover\:text-yellow-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 141, var(--tw-text-opacity))
}

.hover\:text-yellow-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 0, var(--tw-text-opacity))
}

.hover\:text-yellow-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 234, 0, var(--tw-text-opacity))
}

.hover\:text-yellow-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 214, 0, var(--tw-text-opacity))
}

.hover\:text-green-50:hover {
  --tw-text-opacity: 1;
  color: rgba(232, 245, 233, var(--tw-text-opacity))
}

.hover\:text-green-100:hover {
  --tw-text-opacity: 1;
  color: rgba(200, 230, 201, var(--tw-text-opacity))
}

.hover\:text-green-200:hover {
  --tw-text-opacity: 1;
  color: rgba(165, 214, 167, var(--tw-text-opacity))
}

.hover\:text-green-300:hover {
  --tw-text-opacity: 1;
  color: rgba(129, 199, 132, var(--tw-text-opacity))
}

.hover\:text-green-400:hover {
  --tw-text-opacity: 1;
  color: rgba(102, 187, 106, var(--tw-text-opacity))
}

.hover\:text-green-500:hover {
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity))
}

.hover\:text-green-600:hover {
  --tw-text-opacity: 1;
  color: rgba(67, 160, 71, var(--tw-text-opacity))
}

.hover\:text-green-700:hover {
  --tw-text-opacity: 1;
  color: rgba(56, 142, 60, var(--tw-text-opacity))
}

.hover\:text-green-800:hover {
  --tw-text-opacity: 1;
  color: rgba(46, 125, 50, var(--tw-text-opacity))
}

.hover\:text-green-900:hover {
  --tw-text-opacity: 1;
  color: rgba(27, 94, 32, var(--tw-text-opacity))
}

.hover\:text-green:hover {
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity))
}

.hover\:text-green-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(185, 246, 202, var(--tw-text-opacity))
}

.hover\:text-green-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(105, 240, 174, var(--tw-text-opacity))
}

.hover\:text-green-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 230, 118, var(--tw-text-opacity))
}

.hover\:text-green-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 200, 83, var(--tw-text-opacity))
}

.hover\:text-light-green-50:hover {
  --tw-text-opacity: 1;
  color: rgba(241, 248, 233, var(--tw-text-opacity))
}

.hover\:text-light-green-100:hover {
  --tw-text-opacity: 1;
  color: rgba(220, 237, 200, var(--tw-text-opacity))
}

.hover\:text-light-green-200:hover {
  --tw-text-opacity: 1;
  color: rgba(197, 225, 165, var(--tw-text-opacity))
}

.hover\:text-light-green-300:hover {
  --tw-text-opacity: 1;
  color: rgba(174, 213, 129, var(--tw-text-opacity))
}

.hover\:text-light-green-400:hover {
  --tw-text-opacity: 1;
  color: rgba(156, 204, 101, var(--tw-text-opacity))
}

.hover\:text-light-green-500:hover {
  --tw-text-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-text-opacity))
}

.hover\:text-light-green-600:hover {
  --tw-text-opacity: 1;
  color: rgba(124, 179, 66, var(--tw-text-opacity))
}

.hover\:text-light-green-700:hover {
  --tw-text-opacity: 1;
  color: rgba(104, 159, 56, var(--tw-text-opacity))
}

.hover\:text-light-green-800:hover {
  --tw-text-opacity: 1;
  color: rgba(85, 139, 47, var(--tw-text-opacity))
}

.hover\:text-light-green-900:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 105, 30, var(--tw-text-opacity))
}

.hover\:text-light-green:hover {
  --tw-text-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-text-opacity))
}

.hover\:text-light-green-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(204, 255, 144, var(--tw-text-opacity))
}

.hover\:text-light-green-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(178, 255, 89, var(--tw-text-opacity))
}

.hover\:text-light-green-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(118, 255, 3, var(--tw-text-opacity))
}

.hover\:text-light-green-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(100, 221, 23, var(--tw-text-opacity))
}

.hover\:text-teal-50:hover {
  --tw-text-opacity: 1;
  color: rgba(224, 242, 241, var(--tw-text-opacity))
}

.hover\:text-teal-100:hover {
  --tw-text-opacity: 1;
  color: rgba(178, 223, 219, var(--tw-text-opacity))
}

.hover\:text-teal-200:hover {
  --tw-text-opacity: 1;
  color: rgba(128, 203, 196, var(--tw-text-opacity))
}

.hover\:text-teal-300:hover {
  --tw-text-opacity: 1;
  color: rgba(77, 182, 172, var(--tw-text-opacity))
}

.hover\:text-teal-400:hover {
  --tw-text-opacity: 1;
  color: rgba(38, 166, 154, var(--tw-text-opacity))
}

.hover\:text-teal-500:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-text-opacity))
}

.hover\:text-teal-600:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 137, 123, var(--tw-text-opacity))
}

.hover\:text-teal-700:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 121, 107, var(--tw-text-opacity))
}

.hover\:text-teal-800:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 105, 92, var(--tw-text-opacity))
}

.hover\:text-teal-900:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 77, 64, var(--tw-text-opacity))
}

.hover\:text-teal:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-text-opacity))
}

.hover\:text-teal-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(167, 255, 235, var(--tw-text-opacity))
}

.hover\:text-teal-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(100, 255, 218, var(--tw-text-opacity))
}

.hover\:text-teal-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(29, 233, 182, var(--tw-text-opacity))
}

.hover\:text-teal-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 191, 165, var(--tw-text-opacity))
}

.hover\:text-blue-50:hover {
  --tw-text-opacity: 1;
  color: rgba(227, 242, 253, var(--tw-text-opacity))
}

.hover\:text-blue-100:hover {
  --tw-text-opacity: 1;
  color: rgba(187, 222, 251, var(--tw-text-opacity))
}

.hover\:text-blue-200:hover {
  --tw-text-opacity: 1;
  color: rgba(144, 202, 249, var(--tw-text-opacity))
}

.hover\:text-blue-300:hover {
  --tw-text-opacity: 1;
  color: rgba(100, 181, 246, var(--tw-text-opacity))
}

.hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgba(66, 165, 245, var(--tw-text-opacity))
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-text-opacity))
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 136, 229, var(--tw-text-opacity))
}

.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgba(25, 118, 210, var(--tw-text-opacity))
}

.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgba(21, 101, 192, var(--tw-text-opacity))
}

.hover\:text-blue-900:hover {
  --tw-text-opacity: 1;
  color: rgba(13, 71, 161, var(--tw-text-opacity))
}

.hover\:text-blue:hover {
  --tw-text-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-text-opacity))
}

.hover\:text-blue-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(130, 177, 255, var(--tw-text-opacity))
}

.hover\:text-blue-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(68, 138, 255, var(--tw-text-opacity))
}

.hover\:text-blue-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(41, 121, 255, var(--tw-text-opacity))
}

.hover\:text-blue-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(41, 98, 255, var(--tw-text-opacity))
}

.hover\:text-light-blue-50:hover {
  --tw-text-opacity: 1;
  color: rgba(225, 245, 254, var(--tw-text-opacity))
}

.hover\:text-light-blue-100:hover {
  --tw-text-opacity: 1;
  color: rgba(179, 229, 252, var(--tw-text-opacity))
}

.hover\:text-light-blue-200:hover {
  --tw-text-opacity: 1;
  color: rgba(129, 212, 250, var(--tw-text-opacity))
}

.hover\:text-light-blue-300:hover {
  --tw-text-opacity: 1;
  color: rgba(79, 195, 247, var(--tw-text-opacity))
}

.hover\:text-light-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgba(41, 182, 246, var(--tw-text-opacity))
}

.hover\:text-light-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-text-opacity))
}

.hover\:text-light-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(3, 155, 229, var(--tw-text-opacity))
}

.hover\:text-light-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgba(2, 136, 209, var(--tw-text-opacity))
}

.hover\:text-light-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgba(2, 119, 189, var(--tw-text-opacity))
}

.hover\:text-light-blue-900:hover {
  --tw-text-opacity: 1;
  color: rgba(1, 87, 155, var(--tw-text-opacity))
}

.hover\:text-light-blue:hover {
  --tw-text-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-text-opacity))
}

.hover\:text-light-blue-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(128, 216, 255, var(--tw-text-opacity))
}

.hover\:text-light-blue-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(64, 196, 255, var(--tw-text-opacity))
}

.hover\:text-light-blue-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 176, 255, var(--tw-text-opacity))
}

.hover\:text-light-blue-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 145, 234, var(--tw-text-opacity))
}

.hover\:text-indigo-50:hover {
  --tw-text-opacity: 1;
  color: rgba(232, 234, 246, var(--tw-text-opacity))
}

.hover\:text-indigo-100:hover {
  --tw-text-opacity: 1;
  color: rgba(197, 202, 233, var(--tw-text-opacity))
}

.hover\:text-indigo-200:hover {
  --tw-text-opacity: 1;
  color: rgba(159, 168, 218, var(--tw-text-opacity))
}

.hover\:text-indigo-300:hover {
  --tw-text-opacity: 1;
  color: rgba(121, 134, 203, var(--tw-text-opacity))
}

.hover\:text-indigo-400:hover {
  --tw-text-opacity: 1;
  color: rgba(92, 107, 192, var(--tw-text-opacity))
}

.hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-text-opacity))
}

.hover\:text-indigo-600:hover {
  --tw-text-opacity: 1;
  color: rgba(57, 73, 171, var(--tw-text-opacity))
}

.hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgba(48, 63, 159, var(--tw-text-opacity))
}

.hover\:text-indigo-800:hover {
  --tw-text-opacity: 1;
  color: rgba(40, 53, 147, var(--tw-text-opacity))
}

.hover\:text-indigo-900:hover {
  --tw-text-opacity: 1;
  color: rgba(26, 35, 126, var(--tw-text-opacity))
}

.hover\:text-indigo:hover {
  --tw-text-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-text-opacity))
}

.hover\:text-indigo-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(140, 158, 255, var(--tw-text-opacity))
}

.hover\:text-indigo-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(83, 109, 254, var(--tw-text-opacity))
}

.hover\:text-indigo-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(61, 90, 254, var(--tw-text-opacity))
}

.hover\:text-indigo-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(48, 79, 254, var(--tw-text-opacity))
}

.hover\:text-purple-50:hover {
  --tw-text-opacity: 1;
  color: rgba(243, 229, 245, var(--tw-text-opacity))
}

.hover\:text-purple-100:hover {
  --tw-text-opacity: 1;
  color: rgba(225, 190, 231, var(--tw-text-opacity))
}

.hover\:text-purple-200:hover {
  --tw-text-opacity: 1;
  color: rgba(206, 147, 216, var(--tw-text-opacity))
}

.hover\:text-purple-300:hover {
  --tw-text-opacity: 1;
  color: rgba(186, 104, 200, var(--tw-text-opacity))
}

.hover\:text-purple-400:hover {
  --tw-text-opacity: 1;
  color: rgba(171, 71, 188, var(--tw-text-opacity))
}

.hover\:text-purple-500:hover {
  --tw-text-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-text-opacity))
}

.hover\:text-purple-600:hover {
  --tw-text-opacity: 1;
  color: rgba(142, 36, 170, var(--tw-text-opacity))
}

.hover\:text-purple-700:hover {
  --tw-text-opacity: 1;
  color: rgba(123, 31, 162, var(--tw-text-opacity))
}

.hover\:text-purple-800:hover {
  --tw-text-opacity: 1;
  color: rgba(106, 27, 154, var(--tw-text-opacity))
}

.hover\:text-purple-900:hover {
  --tw-text-opacity: 1;
  color: rgba(74, 20, 140, var(--tw-text-opacity))
}

.hover\:text-purple:hover {
  --tw-text-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-text-opacity))
}

.hover\:text-purple-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(234, 128, 252, var(--tw-text-opacity))
}

.hover\:text-purple-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(224, 64, 251, var(--tw-text-opacity))
}

.hover\:text-purple-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(213, 0, 249, var(--tw-text-opacity))
}

.hover\:text-purple-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(170, 0, 255, var(--tw-text-opacity))
}

.hover\:text-deep-purple-50:hover {
  --tw-text-opacity: 1;
  color: rgba(237, 231, 246, var(--tw-text-opacity))
}

.hover\:text-deep-purple-100:hover {
  --tw-text-opacity: 1;
  color: rgba(209, 196, 233, var(--tw-text-opacity))
}

.hover\:text-deep-purple-200:hover {
  --tw-text-opacity: 1;
  color: rgba(179, 157, 219, var(--tw-text-opacity))
}

.hover\:text-deep-purple-300:hover {
  --tw-text-opacity: 1;
  color: rgba(149, 117, 205, var(--tw-text-opacity))
}

.hover\:text-deep-purple-400:hover {
  --tw-text-opacity: 1;
  color: rgba(126, 87, 194, var(--tw-text-opacity))
}

.hover\:text-deep-purple-500:hover {
  --tw-text-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-text-opacity))
}

.hover\:text-deep-purple-600:hover {
  --tw-text-opacity: 1;
  color: rgba(94, 53, 177, var(--tw-text-opacity))
}

.hover\:text-deep-purple-700:hover {
  --tw-text-opacity: 1;
  color: rgba(81, 45, 168, var(--tw-text-opacity))
}

.hover\:text-deep-purple-800:hover {
  --tw-text-opacity: 1;
  color: rgba(69, 39, 160, var(--tw-text-opacity))
}

.hover\:text-deep-purple-900:hover {
  --tw-text-opacity: 1;
  color: rgba(49, 27, 146, var(--tw-text-opacity))
}

.hover\:text-deep-purple:hover {
  --tw-text-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-text-opacity))
}

.hover\:text-deep-purple-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(179, 136, 255, var(--tw-text-opacity))
}

.hover\:text-deep-purple-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(124, 77, 255, var(--tw-text-opacity))
}

.hover\:text-deep-purple-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(101, 31, 255, var(--tw-text-opacity))
}

.hover\:text-deep-purple-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(98, 0, 234, var(--tw-text-opacity))
}

.hover\:text-pink-50:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 228, 236, var(--tw-text-opacity))
}

.hover\:text-pink-100:hover {
  --tw-text-opacity: 1;
  color: rgba(248, 187, 208, var(--tw-text-opacity))
}

.hover\:text-pink-200:hover {
  --tw-text-opacity: 1;
  color: rgba(244, 143, 177, var(--tw-text-opacity))
}

.hover\:text-pink-300:hover {
  --tw-text-opacity: 1;
  color: rgba(240, 98, 146, var(--tw-text-opacity))
}

.hover\:text-pink-400:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 64, 122, var(--tw-text-opacity))
}

.hover\:text-pink-500:hover {
  --tw-text-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-text-opacity))
}

.hover\:text-pink-600:hover {
  --tw-text-opacity: 1;
  color: rgba(216, 27, 96, var(--tw-text-opacity))
}

.hover\:text-pink-700:hover {
  --tw-text-opacity: 1;
  color: rgba(194, 24, 91, var(--tw-text-opacity))
}

.hover\:text-pink-800:hover {
  --tw-text-opacity: 1;
  color: rgba(173, 20, 87, var(--tw-text-opacity))
}

.hover\:text-pink-900:hover {
  --tw-text-opacity: 1;
  color: rgba(136, 14, 79, var(--tw-text-opacity))
}

.hover\:text-pink:hover {
  --tw-text-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-text-opacity))
}

.hover\:text-pink-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 128, 171, var(--tw-text-opacity))
}

.hover\:text-pink-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 64, 129, var(--tw-text-opacity))
}

.hover\:text-pink-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 0, 87, var(--tw-text-opacity))
}

.hover\:text-pink-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(197, 17, 98, var(--tw-text-opacity))
}

.hover\:text-lime-50:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 251, 231, var(--tw-text-opacity))
}

.hover\:text-lime-100:hover {
  --tw-text-opacity: 1;
  color: rgba(240, 244, 195, var(--tw-text-opacity))
}

.hover\:text-lime-200:hover {
  --tw-text-opacity: 1;
  color: rgba(230, 238, 156, var(--tw-text-opacity))
}

.hover\:text-lime-300:hover {
  --tw-text-opacity: 1;
  color: rgba(220, 231, 117, var(--tw-text-opacity))
}

.hover\:text-lime-400:hover {
  --tw-text-opacity: 1;
  color: rgba(212, 225, 87, var(--tw-text-opacity))
}

.hover\:text-lime-500:hover {
  --tw-text-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-text-opacity))
}

.hover\:text-lime-600:hover {
  --tw-text-opacity: 1;
  color: rgba(192, 202, 51, var(--tw-text-opacity))
}

.hover\:text-lime-700:hover {
  --tw-text-opacity: 1;
  color: rgba(175, 180, 43, var(--tw-text-opacity))
}

.hover\:text-lime-800:hover {
  --tw-text-opacity: 1;
  color: rgba(158, 157, 36, var(--tw-text-opacity))
}

.hover\:text-lime-900:hover {
  --tw-text-opacity: 1;
  color: rgba(130, 119, 23, var(--tw-text-opacity))
}

.hover\:text-lime:hover {
  --tw-text-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-text-opacity))
}

.hover\:text-lime-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(244, 255, 129, var(--tw-text-opacity))
}

.hover\:text-lime-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(238, 255, 65, var(--tw-text-opacity))
}

.hover\:text-lime-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(198, 255, 0, var(--tw-text-opacity))
}

.hover\:text-lime-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(174, 234, 0, var(--tw-text-opacity))
}

.hover\:text-amber-50:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 248, 225, var(--tw-text-opacity))
}

.hover\:text-amber-100:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 236, 179, var(--tw-text-opacity))
}

.hover\:text-amber-200:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 224, 130, var(--tw-text-opacity))
}

.hover\:text-amber-300:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 213, 79, var(--tw-text-opacity))
}

.hover\:text-amber-400:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 202, 40, var(--tw-text-opacity))
}

.hover\:text-amber-500:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-text-opacity))
}

.hover\:text-amber-600:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 179, 0, var(--tw-text-opacity))
}

.hover\:text-amber-700:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 160, 0, var(--tw-text-opacity))
}

.hover\:text-amber-800:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 143, 0, var(--tw-text-opacity))
}

.hover\:text-amber-900:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 111, 0, var(--tw-text-opacity))
}

.hover\:text-amber:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-text-opacity))
}

.hover\:text-amber-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 229, 127, var(--tw-text-opacity))
}

.hover\:text-amber-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 215, 64, var(--tw-text-opacity))
}

.hover\:text-amber-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 196, 0, var(--tw-text-opacity))
}

.hover\:text-amber-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 171, 0, var(--tw-text-opacity))
}

.hover\:text-brown-50:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 235, 233, var(--tw-text-opacity))
}

.hover\:text-brown-100:hover {
  --tw-text-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-text-opacity))
}

.hover\:text-brown-200:hover {
  --tw-text-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-text-opacity))
}

.hover\:text-brown-300:hover {
  --tw-text-opacity: 1;
  color: rgba(161, 136, 127, var(--tw-text-opacity))
}

.hover\:text-brown-400:hover {
  --tw-text-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-text-opacity))
}

.hover\:text-brown-500:hover {
  --tw-text-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-text-opacity))
}

.hover\:text-brown-600:hover {
  --tw-text-opacity: 1;
  color: rgba(109, 76, 65, var(--tw-text-opacity))
}

.hover\:text-brown-700:hover {
  --tw-text-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-text-opacity))
}

.hover\:text-brown-800:hover {
  --tw-text-opacity: 1;
  color: rgba(78, 52, 46, var(--tw-text-opacity))
}

.hover\:text-brown-900:hover {
  --tw-text-opacity: 1;
  color: rgba(62, 39, 35, var(--tw-text-opacity))
}

.hover\:text-brown:hover {
  --tw-text-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-text-opacity))
}

.hover\:text-brown-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-text-opacity))
}

.hover\:text-brown-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-text-opacity))
}

.hover\:text-brown-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-text-opacity))
}

.hover\:text-brown-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-text-opacity))
}

.hover\:text-blue-gray-50:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 239, 241, var(--tw-text-opacity))
}

.hover\:text-blue-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-text-opacity))
}

.hover\:text-blue-gray-200:hover {
  --tw-text-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-text-opacity))
}

.hover\:text-blue-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgba(144, 164, 174, var(--tw-text-opacity))
}

.hover\:text-blue-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-text-opacity))
}

.hover\:text-blue-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-text-opacity))
}

.hover\:text-blue-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgba(84, 110, 122, var(--tw-text-opacity))
}

.hover\:text-blue-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-text-opacity))
}

.hover\:text-blue-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 71, 79, var(--tw-text-opacity))
}

.hover\:text-blue-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(38, 50, 56, var(--tw-text-opacity))
}

.hover\:text-blue-gray:hover {
  --tw-text-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-text-opacity))
}

.hover\:text-blue-gray-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-text-opacity))
}

.hover\:text-blue-gray-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-text-opacity))
}

.hover\:text-blue-gray-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-text-opacity))
}

.hover\:text-blue-gray-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-text-opacity))
}

.hover\:text-cyan-50:hover {
  --tw-text-opacity: 1;
  color: rgba(224, 247, 250, var(--tw-text-opacity))
}

.hover\:text-cyan-100:hover {
  --tw-text-opacity: 1;
  color: rgba(178, 235, 242, var(--tw-text-opacity))
}

.hover\:text-cyan-200:hover {
  --tw-text-opacity: 1;
  color: rgba(128, 222, 234, var(--tw-text-opacity))
}

.hover\:text-cyan-300:hover {
  --tw-text-opacity: 1;
  color: rgba(77, 208, 225, var(--tw-text-opacity))
}

.hover\:text-cyan-400:hover {
  --tw-text-opacity: 1;
  color: rgba(38, 198, 218, var(--tw-text-opacity))
}

.hover\:text-cyan-500:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-text-opacity))
}

.hover\:text-cyan-600:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 172, 193, var(--tw-text-opacity))
}

.hover\:text-cyan-700:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 151, 167, var(--tw-text-opacity))
}

.hover\:text-cyan-800:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 131, 143, var(--tw-text-opacity))
}

.hover\:text-cyan-900:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 96, 100, var(--tw-text-opacity))
}

.hover\:text-cyan:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-text-opacity))
}

.hover\:text-cyan-A100:hover {
  --tw-text-opacity: 1;
  color: rgba(132, 255, 255, var(--tw-text-opacity))
}

.hover\:text-cyan-A200:hover {
  --tw-text-opacity: 1;
  color: rgba(24, 255, 255, var(--tw-text-opacity))
}

.hover\:text-cyan-A400:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 229, 255, var(--tw-text-opacity))
}

.hover\:text-cyan-A700:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 184, 212, var(--tw-text-opacity))
}

.focus\:text-transparent:focus {
  color: transparent
}

.focus\:text-current:focus {
  color: currentColor
}

.focus\:text-black:focus {
  --tw-text-opacity: 1;
  color: rgba(34, 41, 47, var(--tw-text-opacity))
}

.focus\:text-white:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.focus\:text-grey-50:focus {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity))
}

.focus\:text-grey-100:focus {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-text-opacity))
}

.focus\:text-grey-200:focus {
  --tw-text-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-text-opacity))
}

.focus\:text-grey-300:focus {
  --tw-text-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-text-opacity))
}

.focus\:text-grey-400:focus {
  --tw-text-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-text-opacity))
}

.focus\:text-grey-500:focus {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.focus\:text-grey-600:focus {
  --tw-text-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-text-opacity))
}

.focus\:text-grey-700:focus {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.focus\:text-grey-800:focus {
  --tw-text-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-text-opacity))
}

.focus\:text-grey-900:focus {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity))
}

.focus\:text-grey:focus {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.focus\:text-grey-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(213, 213, 213, var(--tw-text-opacity))
}

.focus\:text-grey-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-text-opacity))
}

.focus\:text-grey-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(48, 48, 48, var(--tw-text-opacity))
}

.focus\:text-grey-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.focus\:text-gray-50:focus {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity))
}

.focus\:text-gray-100:focus {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-text-opacity))
}

.focus\:text-gray-200:focus {
  --tw-text-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-text-opacity))
}

.focus\:text-gray-300:focus {
  --tw-text-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-text-opacity))
}

.focus\:text-gray-400:focus {
  --tw-text-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-text-opacity))
}

.focus\:text-gray-500:focus {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.focus\:text-gray-600:focus {
  --tw-text-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-text-opacity))
}

.focus\:text-gray-700:focus {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.focus\:text-gray-800:focus {
  --tw-text-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-text-opacity))
}

.focus\:text-gray-900:focus {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity))
}

.focus\:text-gray:focus {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity))
}

.focus\:text-gray-hover:focus {
  color: rgba(0, 0, 0, 0.04)
}

.focus\:text-gray-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(213, 213, 213, var(--tw-text-opacity))
}

.focus\:text-gray-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-text-opacity))
}

.focus\:text-gray-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(48, 48, 48, var(--tw-text-opacity))
}

.focus\:text-gray-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity))
}

.focus\:text-red-50:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 235, 238, var(--tw-text-opacity))
}

.focus\:text-red-100:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 205, 210, var(--tw-text-opacity))
}

.focus\:text-red-200:focus {
  --tw-text-opacity: 1;
  color: rgba(239, 154, 154, var(--tw-text-opacity))
}

.focus\:text-red-300:focus {
  --tw-text-opacity: 1;
  color: rgba(229, 115, 115, var(--tw-text-opacity))
}

.focus\:text-red-400:focus {
  --tw-text-opacity: 1;
  color: rgba(239, 83, 80, var(--tw-text-opacity))
}

.focus\:text-red-500:focus {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity))
}

.focus\:text-red-600:focus {
  --tw-text-opacity: 1;
  color: rgba(229, 57, 53, var(--tw-text-opacity))
}

.focus\:text-red-700:focus {
  --tw-text-opacity: 1;
  color: rgba(211, 47, 47, var(--tw-text-opacity))
}

.focus\:text-red-800:focus {
  --tw-text-opacity: 1;
  color: rgba(198, 40, 40, var(--tw-text-opacity))
}

.focus\:text-red-900:focus {
  --tw-text-opacity: 1;
  color: rgba(183, 28, 28, var(--tw-text-opacity))
}

.focus\:text-red:focus {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity))
}

.focus\:text-red-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 138, 128, var(--tw-text-opacity))
}

.focus\:text-red-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 82, 82, var(--tw-text-opacity))
}

.focus\:text-red-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 23, 68, var(--tw-text-opacity))
}

.focus\:text-red-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(213, 0, 0, var(--tw-text-opacity))
}

.focus\:text-orange-50:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 243, 224, var(--tw-text-opacity))
}

.focus\:text-orange-100:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 224, 178, var(--tw-text-opacity))
}

.focus\:text-orange-200:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 204, 128, var(--tw-text-opacity))
}

.focus\:text-orange-300:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 183, 77, var(--tw-text-opacity))
}

.focus\:text-orange-400:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 167, 38, var(--tw-text-opacity))
}

.focus\:text-orange-500:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-text-opacity))
}

.focus\:text-orange-600:focus {
  --tw-text-opacity: 1;
  color: rgba(251, 140, 0, var(--tw-text-opacity))
}

.focus\:text-orange-700:focus {
  --tw-text-opacity: 1;
  color: rgba(245, 124, 0, var(--tw-text-opacity))
}

.focus\:text-orange-800:focus {
  --tw-text-opacity: 1;
  color: rgba(239, 108, 0, var(--tw-text-opacity))
}

.focus\:text-orange-900:focus {
  --tw-text-opacity: 1;
  color: rgba(230, 81, 0, var(--tw-text-opacity))
}

.focus\:text-orange:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-text-opacity))
}

.focus\:text-orange-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 209, 128, var(--tw-text-opacity))
}

.focus\:text-orange-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 171, 64, var(--tw-text-opacity))
}

.focus\:text-orange-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 145, 0, var(--tw-text-opacity))
}

.focus\:text-orange-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 109, 0, var(--tw-text-opacity))
}

.focus\:text-deep-orange-50:focus {
  --tw-text-opacity: 1;
  color: rgba(251, 233, 231, var(--tw-text-opacity))
}

.focus\:text-deep-orange-100:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 204, 188, var(--tw-text-opacity))
}

.focus\:text-deep-orange-200:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 171, 145, var(--tw-text-opacity))
}

.focus\:text-deep-orange-300:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 138, 101, var(--tw-text-opacity))
}

.focus\:text-deep-orange-400:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 112, 67, var(--tw-text-opacity))
}

.focus\:text-deep-orange-500:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-text-opacity))
}

.focus\:text-deep-orange-600:focus {
  --tw-text-opacity: 1;
  color: rgba(244, 81, 30, var(--tw-text-opacity))
}

.focus\:text-deep-orange-700:focus {
  --tw-text-opacity: 1;
  color: rgba(230, 74, 25, var(--tw-text-opacity))
}

.focus\:text-deep-orange-800:focus {
  --tw-text-opacity: 1;
  color: rgba(216, 67, 21, var(--tw-text-opacity))
}

.focus\:text-deep-orange-900:focus {
  --tw-text-opacity: 1;
  color: rgba(191, 54, 12, var(--tw-text-opacity))
}

.focus\:text-deep-orange:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-text-opacity))
}

.focus\:text-deep-orange-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 158, 128, var(--tw-text-opacity))
}

.focus\:text-deep-orange-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 110, 64, var(--tw-text-opacity))
}

.focus\:text-deep-orange-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 61, 0, var(--tw-text-opacity))
}

.focus\:text-deep-orange-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(221, 44, 0, var(--tw-text-opacity))
}

.focus\:text-yellow-50:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 253, 231, var(--tw-text-opacity))
}

.focus\:text-yellow-100:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 249, 196, var(--tw-text-opacity))
}

.focus\:text-yellow-200:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 245, 157, var(--tw-text-opacity))
}

.focus\:text-yellow-300:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 241, 118, var(--tw-text-opacity))
}

.focus\:text-yellow-400:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 238, 88, var(--tw-text-opacity))
}

.focus\:text-yellow-500:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 235, 59, var(--tw-text-opacity))
}

.focus\:text-yellow-600:focus {
  --tw-text-opacity: 1;
  color: rgba(253, 216, 53, var(--tw-text-opacity))
}

.focus\:text-yellow-700:focus {
  --tw-text-opacity: 1;
  color: rgba(251, 192, 45, var(--tw-text-opacity))
}

.focus\:text-yellow-800:focus {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 37, var(--tw-text-opacity))
}

.focus\:text-yellow-900:focus {
  --tw-text-opacity: 1;
  color: rgba(245, 127, 23, var(--tw-text-opacity))
}

.focus\:text-yellow:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 235, 59, var(--tw-text-opacity))
}

.focus\:text-yellow-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 141, var(--tw-text-opacity))
}

.focus\:text-yellow-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 0, var(--tw-text-opacity))
}

.focus\:text-yellow-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 234, 0, var(--tw-text-opacity))
}

.focus\:text-yellow-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 214, 0, var(--tw-text-opacity))
}

.focus\:text-green-50:focus {
  --tw-text-opacity: 1;
  color: rgba(232, 245, 233, var(--tw-text-opacity))
}

.focus\:text-green-100:focus {
  --tw-text-opacity: 1;
  color: rgba(200, 230, 201, var(--tw-text-opacity))
}

.focus\:text-green-200:focus {
  --tw-text-opacity: 1;
  color: rgba(165, 214, 167, var(--tw-text-opacity))
}

.focus\:text-green-300:focus {
  --tw-text-opacity: 1;
  color: rgba(129, 199, 132, var(--tw-text-opacity))
}

.focus\:text-green-400:focus {
  --tw-text-opacity: 1;
  color: rgba(102, 187, 106, var(--tw-text-opacity))
}

.focus\:text-green-500:focus {
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity))
}

.focus\:text-green-600:focus {
  --tw-text-opacity: 1;
  color: rgba(67, 160, 71, var(--tw-text-opacity))
}

.focus\:text-green-700:focus {
  --tw-text-opacity: 1;
  color: rgba(56, 142, 60, var(--tw-text-opacity))
}

.focus\:text-green-800:focus {
  --tw-text-opacity: 1;
  color: rgba(46, 125, 50, var(--tw-text-opacity))
}

.focus\:text-green-900:focus {
  --tw-text-opacity: 1;
  color: rgba(27, 94, 32, var(--tw-text-opacity))
}

.focus\:text-green:focus {
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity))
}

.focus\:text-green-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(185, 246, 202, var(--tw-text-opacity))
}

.focus\:text-green-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(105, 240, 174, var(--tw-text-opacity))
}

.focus\:text-green-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 230, 118, var(--tw-text-opacity))
}

.focus\:text-green-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 200, 83, var(--tw-text-opacity))
}

.focus\:text-light-green-50:focus {
  --tw-text-opacity: 1;
  color: rgba(241, 248, 233, var(--tw-text-opacity))
}

.focus\:text-light-green-100:focus {
  --tw-text-opacity: 1;
  color: rgba(220, 237, 200, var(--tw-text-opacity))
}

.focus\:text-light-green-200:focus {
  --tw-text-opacity: 1;
  color: rgba(197, 225, 165, var(--tw-text-opacity))
}

.focus\:text-light-green-300:focus {
  --tw-text-opacity: 1;
  color: rgba(174, 213, 129, var(--tw-text-opacity))
}

.focus\:text-light-green-400:focus {
  --tw-text-opacity: 1;
  color: rgba(156, 204, 101, var(--tw-text-opacity))
}

.focus\:text-light-green-500:focus {
  --tw-text-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-text-opacity))
}

.focus\:text-light-green-600:focus {
  --tw-text-opacity: 1;
  color: rgba(124, 179, 66, var(--tw-text-opacity))
}

.focus\:text-light-green-700:focus {
  --tw-text-opacity: 1;
  color: rgba(104, 159, 56, var(--tw-text-opacity))
}

.focus\:text-light-green-800:focus {
  --tw-text-opacity: 1;
  color: rgba(85, 139, 47, var(--tw-text-opacity))
}

.focus\:text-light-green-900:focus {
  --tw-text-opacity: 1;
  color: rgba(51, 105, 30, var(--tw-text-opacity))
}

.focus\:text-light-green:focus {
  --tw-text-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-text-opacity))
}

.focus\:text-light-green-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(204, 255, 144, var(--tw-text-opacity))
}

.focus\:text-light-green-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(178, 255, 89, var(--tw-text-opacity))
}

.focus\:text-light-green-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(118, 255, 3, var(--tw-text-opacity))
}

.focus\:text-light-green-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(100, 221, 23, var(--tw-text-opacity))
}

.focus\:text-teal-50:focus {
  --tw-text-opacity: 1;
  color: rgba(224, 242, 241, var(--tw-text-opacity))
}

.focus\:text-teal-100:focus {
  --tw-text-opacity: 1;
  color: rgba(178, 223, 219, var(--tw-text-opacity))
}

.focus\:text-teal-200:focus {
  --tw-text-opacity: 1;
  color: rgba(128, 203, 196, var(--tw-text-opacity))
}

.focus\:text-teal-300:focus {
  --tw-text-opacity: 1;
  color: rgba(77, 182, 172, var(--tw-text-opacity))
}

.focus\:text-teal-400:focus {
  --tw-text-opacity: 1;
  color: rgba(38, 166, 154, var(--tw-text-opacity))
}

.focus\:text-teal-500:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-text-opacity))
}

.focus\:text-teal-600:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 137, 123, var(--tw-text-opacity))
}

.focus\:text-teal-700:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 121, 107, var(--tw-text-opacity))
}

.focus\:text-teal-800:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 105, 92, var(--tw-text-opacity))
}

.focus\:text-teal-900:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 77, 64, var(--tw-text-opacity))
}

.focus\:text-teal:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-text-opacity))
}

.focus\:text-teal-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(167, 255, 235, var(--tw-text-opacity))
}

.focus\:text-teal-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(100, 255, 218, var(--tw-text-opacity))
}

.focus\:text-teal-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(29, 233, 182, var(--tw-text-opacity))
}

.focus\:text-teal-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 191, 165, var(--tw-text-opacity))
}

.focus\:text-blue-50:focus {
  --tw-text-opacity: 1;
  color: rgba(227, 242, 253, var(--tw-text-opacity))
}

.focus\:text-blue-100:focus {
  --tw-text-opacity: 1;
  color: rgba(187, 222, 251, var(--tw-text-opacity))
}

.focus\:text-blue-200:focus {
  --tw-text-opacity: 1;
  color: rgba(144, 202, 249, var(--tw-text-opacity))
}

.focus\:text-blue-300:focus {
  --tw-text-opacity: 1;
  color: rgba(100, 181, 246, var(--tw-text-opacity))
}

.focus\:text-blue-400:focus {
  --tw-text-opacity: 1;
  color: rgba(66, 165, 245, var(--tw-text-opacity))
}

.focus\:text-blue-500:focus {
  --tw-text-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-text-opacity))
}

.focus\:text-blue-600:focus {
  --tw-text-opacity: 1;
  color: rgba(30, 136, 229, var(--tw-text-opacity))
}

.focus\:text-blue-700:focus {
  --tw-text-opacity: 1;
  color: rgba(25, 118, 210, var(--tw-text-opacity))
}

.focus\:text-blue-800:focus {
  --tw-text-opacity: 1;
  color: rgba(21, 101, 192, var(--tw-text-opacity))
}

.focus\:text-blue-900:focus {
  --tw-text-opacity: 1;
  color: rgba(13, 71, 161, var(--tw-text-opacity))
}

.focus\:text-blue:focus {
  --tw-text-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-text-opacity))
}

.focus\:text-blue-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(130, 177, 255, var(--tw-text-opacity))
}

.focus\:text-blue-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(68, 138, 255, var(--tw-text-opacity))
}

.focus\:text-blue-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(41, 121, 255, var(--tw-text-opacity))
}

.focus\:text-blue-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(41, 98, 255, var(--tw-text-opacity))
}

.focus\:text-light-blue-50:focus {
  --tw-text-opacity: 1;
  color: rgba(225, 245, 254, var(--tw-text-opacity))
}

.focus\:text-light-blue-100:focus {
  --tw-text-opacity: 1;
  color: rgba(179, 229, 252, var(--tw-text-opacity))
}

.focus\:text-light-blue-200:focus {
  --tw-text-opacity: 1;
  color: rgba(129, 212, 250, var(--tw-text-opacity))
}

.focus\:text-light-blue-300:focus {
  --tw-text-opacity: 1;
  color: rgba(79, 195, 247, var(--tw-text-opacity))
}

.focus\:text-light-blue-400:focus {
  --tw-text-opacity: 1;
  color: rgba(41, 182, 246, var(--tw-text-opacity))
}

.focus\:text-light-blue-500:focus {
  --tw-text-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-text-opacity))
}

.focus\:text-light-blue-600:focus {
  --tw-text-opacity: 1;
  color: rgba(3, 155, 229, var(--tw-text-opacity))
}

.focus\:text-light-blue-700:focus {
  --tw-text-opacity: 1;
  color: rgba(2, 136, 209, var(--tw-text-opacity))
}

.focus\:text-light-blue-800:focus {
  --tw-text-opacity: 1;
  color: rgba(2, 119, 189, var(--tw-text-opacity))
}

.focus\:text-light-blue-900:focus {
  --tw-text-opacity: 1;
  color: rgba(1, 87, 155, var(--tw-text-opacity))
}

.focus\:text-light-blue:focus {
  --tw-text-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-text-opacity))
}

.focus\:text-light-blue-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(128, 216, 255, var(--tw-text-opacity))
}

.focus\:text-light-blue-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(64, 196, 255, var(--tw-text-opacity))
}

.focus\:text-light-blue-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 176, 255, var(--tw-text-opacity))
}

.focus\:text-light-blue-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 145, 234, var(--tw-text-opacity))
}

.focus\:text-indigo-50:focus {
  --tw-text-opacity: 1;
  color: rgba(232, 234, 246, var(--tw-text-opacity))
}

.focus\:text-indigo-100:focus {
  --tw-text-opacity: 1;
  color: rgba(197, 202, 233, var(--tw-text-opacity))
}

.focus\:text-indigo-200:focus {
  --tw-text-opacity: 1;
  color: rgba(159, 168, 218, var(--tw-text-opacity))
}

.focus\:text-indigo-300:focus {
  --tw-text-opacity: 1;
  color: rgba(121, 134, 203, var(--tw-text-opacity))
}

.focus\:text-indigo-400:focus {
  --tw-text-opacity: 1;
  color: rgba(92, 107, 192, var(--tw-text-opacity))
}

.focus\:text-indigo-500:focus {
  --tw-text-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-text-opacity))
}

.focus\:text-indigo-600:focus {
  --tw-text-opacity: 1;
  color: rgba(57, 73, 171, var(--tw-text-opacity))
}

.focus\:text-indigo-700:focus {
  --tw-text-opacity: 1;
  color: rgba(48, 63, 159, var(--tw-text-opacity))
}

.focus\:text-indigo-800:focus {
  --tw-text-opacity: 1;
  color: rgba(40, 53, 147, var(--tw-text-opacity))
}

.focus\:text-indigo-900:focus {
  --tw-text-opacity: 1;
  color: rgba(26, 35, 126, var(--tw-text-opacity))
}

.focus\:text-indigo:focus {
  --tw-text-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-text-opacity))
}

.focus\:text-indigo-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(140, 158, 255, var(--tw-text-opacity))
}

.focus\:text-indigo-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(83, 109, 254, var(--tw-text-opacity))
}

.focus\:text-indigo-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(61, 90, 254, var(--tw-text-opacity))
}

.focus\:text-indigo-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(48, 79, 254, var(--tw-text-opacity))
}

.focus\:text-purple-50:focus {
  --tw-text-opacity: 1;
  color: rgba(243, 229, 245, var(--tw-text-opacity))
}

.focus\:text-purple-100:focus {
  --tw-text-opacity: 1;
  color: rgba(225, 190, 231, var(--tw-text-opacity))
}

.focus\:text-purple-200:focus {
  --tw-text-opacity: 1;
  color: rgba(206, 147, 216, var(--tw-text-opacity))
}

.focus\:text-purple-300:focus {
  --tw-text-opacity: 1;
  color: rgba(186, 104, 200, var(--tw-text-opacity))
}

.focus\:text-purple-400:focus {
  --tw-text-opacity: 1;
  color: rgba(171, 71, 188, var(--tw-text-opacity))
}

.focus\:text-purple-500:focus {
  --tw-text-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-text-opacity))
}

.focus\:text-purple-600:focus {
  --tw-text-opacity: 1;
  color: rgba(142, 36, 170, var(--tw-text-opacity))
}

.focus\:text-purple-700:focus {
  --tw-text-opacity: 1;
  color: rgba(123, 31, 162, var(--tw-text-opacity))
}

.focus\:text-purple-800:focus {
  --tw-text-opacity: 1;
  color: rgba(106, 27, 154, var(--tw-text-opacity))
}

.focus\:text-purple-900:focus {
  --tw-text-opacity: 1;
  color: rgba(74, 20, 140, var(--tw-text-opacity))
}

.focus\:text-purple:focus {
  --tw-text-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-text-opacity))
}

.focus\:text-purple-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(234, 128, 252, var(--tw-text-opacity))
}

.focus\:text-purple-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(224, 64, 251, var(--tw-text-opacity))
}

.focus\:text-purple-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(213, 0, 249, var(--tw-text-opacity))
}

.focus\:text-purple-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(170, 0, 255, var(--tw-text-opacity))
}

.focus\:text-deep-purple-50:focus {
  --tw-text-opacity: 1;
  color: rgba(237, 231, 246, var(--tw-text-opacity))
}

.focus\:text-deep-purple-100:focus {
  --tw-text-opacity: 1;
  color: rgba(209, 196, 233, var(--tw-text-opacity))
}

.focus\:text-deep-purple-200:focus {
  --tw-text-opacity: 1;
  color: rgba(179, 157, 219, var(--tw-text-opacity))
}

.focus\:text-deep-purple-300:focus {
  --tw-text-opacity: 1;
  color: rgba(149, 117, 205, var(--tw-text-opacity))
}

.focus\:text-deep-purple-400:focus {
  --tw-text-opacity: 1;
  color: rgba(126, 87, 194, var(--tw-text-opacity))
}

.focus\:text-deep-purple-500:focus {
  --tw-text-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-text-opacity))
}

.focus\:text-deep-purple-600:focus {
  --tw-text-opacity: 1;
  color: rgba(94, 53, 177, var(--tw-text-opacity))
}

.focus\:text-deep-purple-700:focus {
  --tw-text-opacity: 1;
  color: rgba(81, 45, 168, var(--tw-text-opacity))
}

.focus\:text-deep-purple-800:focus {
  --tw-text-opacity: 1;
  color: rgba(69, 39, 160, var(--tw-text-opacity))
}

.focus\:text-deep-purple-900:focus {
  --tw-text-opacity: 1;
  color: rgba(49, 27, 146, var(--tw-text-opacity))
}

.focus\:text-deep-purple:focus {
  --tw-text-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-text-opacity))
}

.focus\:text-deep-purple-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(179, 136, 255, var(--tw-text-opacity))
}

.focus\:text-deep-purple-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(124, 77, 255, var(--tw-text-opacity))
}

.focus\:text-deep-purple-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(101, 31, 255, var(--tw-text-opacity))
}

.focus\:text-deep-purple-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(98, 0, 234, var(--tw-text-opacity))
}

.focus\:text-pink-50:focus {
  --tw-text-opacity: 1;
  color: rgba(252, 228, 236, var(--tw-text-opacity))
}

.focus\:text-pink-100:focus {
  --tw-text-opacity: 1;
  color: rgba(248, 187, 208, var(--tw-text-opacity))
}

.focus\:text-pink-200:focus {
  --tw-text-opacity: 1;
  color: rgba(244, 143, 177, var(--tw-text-opacity))
}

.focus\:text-pink-300:focus {
  --tw-text-opacity: 1;
  color: rgba(240, 98, 146, var(--tw-text-opacity))
}

.focus\:text-pink-400:focus {
  --tw-text-opacity: 1;
  color: rgba(236, 64, 122, var(--tw-text-opacity))
}

.focus\:text-pink-500:focus {
  --tw-text-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-text-opacity))
}

.focus\:text-pink-600:focus {
  --tw-text-opacity: 1;
  color: rgba(216, 27, 96, var(--tw-text-opacity))
}

.focus\:text-pink-700:focus {
  --tw-text-opacity: 1;
  color: rgba(194, 24, 91, var(--tw-text-opacity))
}

.focus\:text-pink-800:focus {
  --tw-text-opacity: 1;
  color: rgba(173, 20, 87, var(--tw-text-opacity))
}

.focus\:text-pink-900:focus {
  --tw-text-opacity: 1;
  color: rgba(136, 14, 79, var(--tw-text-opacity))
}

.focus\:text-pink:focus {
  --tw-text-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-text-opacity))
}

.focus\:text-pink-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 128, 171, var(--tw-text-opacity))
}

.focus\:text-pink-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 64, 129, var(--tw-text-opacity))
}

.focus\:text-pink-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(245, 0, 87, var(--tw-text-opacity))
}

.focus\:text-pink-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(197, 17, 98, var(--tw-text-opacity))
}

.focus\:text-lime-50:focus {
  --tw-text-opacity: 1;
  color: rgba(249, 251, 231, var(--tw-text-opacity))
}

.focus\:text-lime-100:focus {
  --tw-text-opacity: 1;
  color: rgba(240, 244, 195, var(--tw-text-opacity))
}

.focus\:text-lime-200:focus {
  --tw-text-opacity: 1;
  color: rgba(230, 238, 156, var(--tw-text-opacity))
}

.focus\:text-lime-300:focus {
  --tw-text-opacity: 1;
  color: rgba(220, 231, 117, var(--tw-text-opacity))
}

.focus\:text-lime-400:focus {
  --tw-text-opacity: 1;
  color: rgba(212, 225, 87, var(--tw-text-opacity))
}

.focus\:text-lime-500:focus {
  --tw-text-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-text-opacity))
}

.focus\:text-lime-600:focus {
  --tw-text-opacity: 1;
  color: rgba(192, 202, 51, var(--tw-text-opacity))
}

.focus\:text-lime-700:focus {
  --tw-text-opacity: 1;
  color: rgba(175, 180, 43, var(--tw-text-opacity))
}

.focus\:text-lime-800:focus {
  --tw-text-opacity: 1;
  color: rgba(158, 157, 36, var(--tw-text-opacity))
}

.focus\:text-lime-900:focus {
  --tw-text-opacity: 1;
  color: rgba(130, 119, 23, var(--tw-text-opacity))
}

.focus\:text-lime:focus {
  --tw-text-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-text-opacity))
}

.focus\:text-lime-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(244, 255, 129, var(--tw-text-opacity))
}

.focus\:text-lime-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(238, 255, 65, var(--tw-text-opacity))
}

.focus\:text-lime-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(198, 255, 0, var(--tw-text-opacity))
}

.focus\:text-lime-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(174, 234, 0, var(--tw-text-opacity))
}

.focus\:text-amber-50:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 248, 225, var(--tw-text-opacity))
}

.focus\:text-amber-100:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 236, 179, var(--tw-text-opacity))
}

.focus\:text-amber-200:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 224, 130, var(--tw-text-opacity))
}

.focus\:text-amber-300:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 213, 79, var(--tw-text-opacity))
}

.focus\:text-amber-400:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 202, 40, var(--tw-text-opacity))
}

.focus\:text-amber-500:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-text-opacity))
}

.focus\:text-amber-600:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 179, 0, var(--tw-text-opacity))
}

.focus\:text-amber-700:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 160, 0, var(--tw-text-opacity))
}

.focus\:text-amber-800:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 143, 0, var(--tw-text-opacity))
}

.focus\:text-amber-900:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 111, 0, var(--tw-text-opacity))
}

.focus\:text-amber:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-text-opacity))
}

.focus\:text-amber-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 229, 127, var(--tw-text-opacity))
}

.focus\:text-amber-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 215, 64, var(--tw-text-opacity))
}

.focus\:text-amber-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 196, 0, var(--tw-text-opacity))
}

.focus\:text-amber-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 171, 0, var(--tw-text-opacity))
}

.focus\:text-brown-50:focus {
  --tw-text-opacity: 1;
  color: rgba(239, 235, 233, var(--tw-text-opacity))
}

.focus\:text-brown-100:focus {
  --tw-text-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-text-opacity))
}

.focus\:text-brown-200:focus {
  --tw-text-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-text-opacity))
}

.focus\:text-brown-300:focus {
  --tw-text-opacity: 1;
  color: rgba(161, 136, 127, var(--tw-text-opacity))
}

.focus\:text-brown-400:focus {
  --tw-text-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-text-opacity))
}

.focus\:text-brown-500:focus {
  --tw-text-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-text-opacity))
}

.focus\:text-brown-600:focus {
  --tw-text-opacity: 1;
  color: rgba(109, 76, 65, var(--tw-text-opacity))
}

.focus\:text-brown-700:focus {
  --tw-text-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-text-opacity))
}

.focus\:text-brown-800:focus {
  --tw-text-opacity: 1;
  color: rgba(78, 52, 46, var(--tw-text-opacity))
}

.focus\:text-brown-900:focus {
  --tw-text-opacity: 1;
  color: rgba(62, 39, 35, var(--tw-text-opacity))
}

.focus\:text-brown:focus {
  --tw-text-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-text-opacity))
}

.focus\:text-brown-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-text-opacity))
}

.focus\:text-brown-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-text-opacity))
}

.focus\:text-brown-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-text-opacity))
}

.focus\:text-brown-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-text-opacity))
}

.focus\:text-blue-gray-50:focus {
  --tw-text-opacity: 1;
  color: rgba(236, 239, 241, var(--tw-text-opacity))
}

.focus\:text-blue-gray-100:focus {
  --tw-text-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-text-opacity))
}

.focus\:text-blue-gray-200:focus {
  --tw-text-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-text-opacity))
}

.focus\:text-blue-gray-300:focus {
  --tw-text-opacity: 1;
  color: rgba(144, 164, 174, var(--tw-text-opacity))
}

.focus\:text-blue-gray-400:focus {
  --tw-text-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-text-opacity))
}

.focus\:text-blue-gray-500:focus {
  --tw-text-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-text-opacity))
}

.focus\:text-blue-gray-600:focus {
  --tw-text-opacity: 1;
  color: rgba(84, 110, 122, var(--tw-text-opacity))
}

.focus\:text-blue-gray-700:focus {
  --tw-text-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-text-opacity))
}

.focus\:text-blue-gray-800:focus {
  --tw-text-opacity: 1;
  color: rgba(55, 71, 79, var(--tw-text-opacity))
}

.focus\:text-blue-gray-900:focus {
  --tw-text-opacity: 1;
  color: rgba(38, 50, 56, var(--tw-text-opacity))
}

.focus\:text-blue-gray:focus {
  --tw-text-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-text-opacity))
}

.focus\:text-blue-gray-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-text-opacity))
}

.focus\:text-blue-gray-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-text-opacity))
}

.focus\:text-blue-gray-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-text-opacity))
}

.focus\:text-blue-gray-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-text-opacity))
}

.focus\:text-cyan-50:focus {
  --tw-text-opacity: 1;
  color: rgba(224, 247, 250, var(--tw-text-opacity))
}

.focus\:text-cyan-100:focus {
  --tw-text-opacity: 1;
  color: rgba(178, 235, 242, var(--tw-text-opacity))
}

.focus\:text-cyan-200:focus {
  --tw-text-opacity: 1;
  color: rgba(128, 222, 234, var(--tw-text-opacity))
}

.focus\:text-cyan-300:focus {
  --tw-text-opacity: 1;
  color: rgba(77, 208, 225, var(--tw-text-opacity))
}

.focus\:text-cyan-400:focus {
  --tw-text-opacity: 1;
  color: rgba(38, 198, 218, var(--tw-text-opacity))
}

.focus\:text-cyan-500:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-text-opacity))
}

.focus\:text-cyan-600:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 172, 193, var(--tw-text-opacity))
}

.focus\:text-cyan-700:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 151, 167, var(--tw-text-opacity))
}

.focus\:text-cyan-800:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 131, 143, var(--tw-text-opacity))
}

.focus\:text-cyan-900:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 96, 100, var(--tw-text-opacity))
}

.focus\:text-cyan:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-text-opacity))
}

.focus\:text-cyan-A100:focus {
  --tw-text-opacity: 1;
  color: rgba(132, 255, 255, var(--tw-text-opacity))
}

.focus\:text-cyan-A200:focus {
  --tw-text-opacity: 1;
  color: rgba(24, 255, 255, var(--tw-text-opacity))
}

.focus\:text-cyan-A400:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 229, 255, var(--tw-text-opacity))
}

.focus\:text-cyan-A700:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 184, 212, var(--tw-text-opacity))
}

.text-opacity-0 {
  --tw-text-opacity: 0
}

.text-opacity-5 {
  --tw-text-opacity: 0.05
}

.text-opacity-10 {
  --tw-text-opacity: 0.1
}

.text-opacity-20 {
  --tw-text-opacity: 0.2
}

.text-opacity-25 {
  --tw-text-opacity: 0.25
}

.text-opacity-30 {
  --tw-text-opacity: 0.3
}

.text-opacity-40 {
  --tw-text-opacity: 0.4
}

.text-opacity-50 {
  --tw-text-opacity: 0.5
}

.text-opacity-60 {
  --tw-text-opacity: 0.6
}

.text-opacity-70 {
  --tw-text-opacity: 0.7
}

.text-opacity-75 {
  --tw-text-opacity: 0.75
}

.text-opacity-80 {
  --tw-text-opacity: 0.8
}

.text-opacity-90 {
  --tw-text-opacity: 0.9
}

.text-opacity-95 {
  --tw-text-opacity: 0.95
}

.text-opacity-100 {
  --tw-text-opacity: 1
}

.group:hover .group-hover\:text-opacity-0 {
  --tw-text-opacity: 0
}

.group:hover .group-hover\:text-opacity-5 {
  --tw-text-opacity: 0.05
}

.group:hover .group-hover\:text-opacity-10 {
  --tw-text-opacity: 0.1
}

.group:hover .group-hover\:text-opacity-20 {
  --tw-text-opacity: 0.2
}

.group:hover .group-hover\:text-opacity-25 {
  --tw-text-opacity: 0.25
}

.group:hover .group-hover\:text-opacity-30 {
  --tw-text-opacity: 0.3
}

.group:hover .group-hover\:text-opacity-40 {
  --tw-text-opacity: 0.4
}

.group:hover .group-hover\:text-opacity-50 {
  --tw-text-opacity: 0.5
}

.group:hover .group-hover\:text-opacity-60 {
  --tw-text-opacity: 0.6
}

.group:hover .group-hover\:text-opacity-70 {
  --tw-text-opacity: 0.7
}

.group:hover .group-hover\:text-opacity-75 {
  --tw-text-opacity: 0.75
}

.group:hover .group-hover\:text-opacity-80 {
  --tw-text-opacity: 0.8
}

.group:hover .group-hover\:text-opacity-90 {
  --tw-text-opacity: 0.9
}

.group:hover .group-hover\:text-opacity-95 {
  --tw-text-opacity: 0.95
}

.group:hover .group-hover\:text-opacity-100 {
  --tw-text-opacity: 1
}

.focus-within\:text-opacity-0:focus-within {
  --tw-text-opacity: 0
}

.focus-within\:text-opacity-5:focus-within {
  --tw-text-opacity: 0.05
}

.focus-within\:text-opacity-10:focus-within {
  --tw-text-opacity: 0.1
}

.focus-within\:text-opacity-20:focus-within {
  --tw-text-opacity: 0.2
}

.focus-within\:text-opacity-25:focus-within {
  --tw-text-opacity: 0.25
}

.focus-within\:text-opacity-30:focus-within {
  --tw-text-opacity: 0.3
}

.focus-within\:text-opacity-40:focus-within {
  --tw-text-opacity: 0.4
}

.focus-within\:text-opacity-50:focus-within {
  --tw-text-opacity: 0.5
}

.focus-within\:text-opacity-60:focus-within {
  --tw-text-opacity: 0.6
}

.focus-within\:text-opacity-70:focus-within {
  --tw-text-opacity: 0.7
}

.focus-within\:text-opacity-75:focus-within {
  --tw-text-opacity: 0.75
}

.focus-within\:text-opacity-80:focus-within {
  --tw-text-opacity: 0.8
}

.focus-within\:text-opacity-90:focus-within {
  --tw-text-opacity: 0.9
}

.focus-within\:text-opacity-95:focus-within {
  --tw-text-opacity: 0.95
}

.focus-within\:text-opacity-100:focus-within {
  --tw-text-opacity: 1
}

.hover\:text-opacity-0:hover {
  --tw-text-opacity: 0
}

.hover\:text-opacity-5:hover {
  --tw-text-opacity: 0.05
}

.hover\:text-opacity-10:hover {
  --tw-text-opacity: 0.1
}

.hover\:text-opacity-20:hover {
  --tw-text-opacity: 0.2
}

.hover\:text-opacity-25:hover {
  --tw-text-opacity: 0.25
}

.hover\:text-opacity-30:hover {
  --tw-text-opacity: 0.3
}

.hover\:text-opacity-40:hover {
  --tw-text-opacity: 0.4
}

.hover\:text-opacity-50:hover {
  --tw-text-opacity: 0.5
}

.hover\:text-opacity-60:hover {
  --tw-text-opacity: 0.6
}

.hover\:text-opacity-70:hover {
  --tw-text-opacity: 0.7
}

.hover\:text-opacity-75:hover {
  --tw-text-opacity: 0.75
}

.hover\:text-opacity-80:hover {
  --tw-text-opacity: 0.8
}

.hover\:text-opacity-90:hover {
  --tw-text-opacity: 0.9
}

.hover\:text-opacity-95:hover {
  --tw-text-opacity: 0.95
}

.hover\:text-opacity-100:hover {
  --tw-text-opacity: 1
}

.focus\:text-opacity-0:focus {
  --tw-text-opacity: 0
}

.focus\:text-opacity-5:focus {
  --tw-text-opacity: 0.05
}

.focus\:text-opacity-10:focus {
  --tw-text-opacity: 0.1
}

.focus\:text-opacity-20:focus {
  --tw-text-opacity: 0.2
}

.focus\:text-opacity-25:focus {
  --tw-text-opacity: 0.25
}

.focus\:text-opacity-30:focus {
  --tw-text-opacity: 0.3
}

.focus\:text-opacity-40:focus {
  --tw-text-opacity: 0.4
}

.focus\:text-opacity-50:focus {
  --tw-text-opacity: 0.5
}

.focus\:text-opacity-60:focus {
  --tw-text-opacity: 0.6
}

.focus\:text-opacity-70:focus {
  --tw-text-opacity: 0.7
}

.focus\:text-opacity-75:focus {
  --tw-text-opacity: 0.75
}

.focus\:text-opacity-80:focus {
  --tw-text-opacity: 0.8
}

.focus\:text-opacity-90:focus {
  --tw-text-opacity: 0.9
}

.focus\:text-opacity-95:focus {
  --tw-text-opacity: 0.95
}

.focus\:text-opacity-100:focus {
  --tw-text-opacity: 1
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.overflow-ellipsis {
  text-overflow: ellipsis
}

.overflow-clip {
  text-overflow: clip
}

.italic {
  font-style: italic
}

.not-italic {
  font-style: normal
}

.hover\:italic:hover {
  font-style: italic
}

.hover\:not-italic:hover {
  font-style: normal
}

.focus\:italic:focus {
  font-style: italic
}

.focus\:not-italic:focus {
  font-style: normal
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.line-through {
  text-decoration: line-through
}

.no-underline {
  text-decoration: none
}

.group:hover .group-hover\:underline {
  text-decoration: underline
}

.group:hover .group-hover\:line-through {
  text-decoration: line-through
}

.group:hover .group-hover\:no-underline {
  text-decoration: none
}

.focus-within\:underline:focus-within {
  text-decoration: underline
}

.focus-within\:line-through:focus-within {
  text-decoration: line-through
}

.focus-within\:no-underline:focus-within {
  text-decoration: none
}

.hover\:underline:hover {
  text-decoration: underline
}

.hover\:line-through:hover {
  text-decoration: line-through
}

.hover\:no-underline:hover {
  text-decoration: none
}

.focus\:underline:focus {
  text-decoration: underline
}

.focus\:line-through:focus {
  text-decoration: line-through
}

.focus\:no-underline:focus {
  text-decoration: none
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto
}

.ordinal, .slashed-zero, .lining-nums, .oldstyle-nums, .proportional-nums, .tabular-nums, .diagonal-fractions, .stacked-fractions {
  --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
  --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.normal-nums {
  font-variant-numeric: normal
}

.ordinal {
  --tw-ordinal: ordinal
}

.slashed-zero {
  --tw-slashed-zero: slashed-zero
}

.lining-nums {
  --tw-numeric-figure: lining-nums
}

.oldstyle-nums {
  --tw-numeric-figure: oldstyle-nums
}

.proportional-nums {
  --tw-numeric-spacing: proportional-nums
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums
}

.diagonal-fractions {
  --tw-numeric-fraction: diagonal-fractions
}

.stacked-fractions {
  --tw-numeric-fraction: stacked-fractions
}

.tracking-tighter {
  letter-spacing: -0.05em
}

.tracking-tight {
  letter-spacing: -0.025em
}

.tracking-normal {
  letter-spacing: 0em
}

.tracking-wide {
  letter-spacing: 0.025em
}

.tracking-wider {
  letter-spacing: 0.05em
}

.tracking-widest {
  letter-spacing: 0.1em
}

.select-none {
  -webkit-user-select: none;
          user-select: none
}

.select-text {
  -webkit-user-select: text;
          user-select: text
}

.select-all {
  -webkit-user-select: all;
          user-select: all
}

.select-auto {
  -webkit-user-select: auto;
          user-select: auto
}

.align-baseline {
  vertical-align: baseline
}

.align-top {
  vertical-align: top
}

.align-middle {
  vertical-align: middle
}

.align-bottom {
  vertical-align: bottom
}

.align-text-top {
  vertical-align: text-top
}

.align-text-bottom {
  vertical-align: text-bottom
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.whitespace-normal {
  white-space: normal
}

.whitespace-nowrap {
  white-space: nowrap
}

.whitespace-pre {
  white-space: pre
}

.whitespace-pre-line {
  white-space: pre-line
}

.whitespace-pre-wrap {
  white-space: pre-wrap
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal
}

.break-words {
  overflow-wrap: break-word
}

.break-all {
  word-break: break-all
}

.w-0 {
  width: 0
}

.w-1 {
  width: 0.1rem
}

.w-2 {
  width: 0.2rem
}

.w-3 {
  width: 0.3rem
}

.w-4 {
  width: 0.4rem
}

.w-5 {
  width: 0.5rem
}

.w-6 {
  width: 0.6rem
}

.w-7 {
  width: 0.7rem
}

.w-8 {
  width: 0.8rem
}

.w-9 {
  width: 0.9rem
}

.w-10 {
  width: 1.0rem
}

.w-12 {
  width: 1.2rem
}

.w-14 {
  width: 1.4rem
}

.w-16 {
  width: 1.6rem
}

.w-20 {
  width: 2rem
}

.w-24 {
  width: 2.4rem
}

.w-28 {
  width: 2.8rem
}

.w-32 {
  width: 3.2rem
}

.w-36 {
  width: 3.6rem
}

.w-40 {
  width: 4rem
}

.w-44 {
  width: 4.4rem
}

.w-48 {
  width: 4.8rem
}

.w-52 {
  width: 5.2rem
}

.w-56 {
  width: 5.6rem
}

.w-60 {
  width: 6rem
}

.w-64 {
  width: 6.4rem
}

.w-68 {
  width: 6.8rem
}

.w-72 {
  width: 7.2rem
}

.w-76 {
  width: 7.6rem
}

.w-80 {
  width: 8rem
}

.w-84 {
  width: 8.4rem
}

.w-88 {
  width: 8.8rem
}

.w-92 {
  width: 9.2rem
}

.w-96 {
  width: 9.6rem
}

.w-128 {
  width: 12.8rem
}

.w-136 {
  width: 13.6rem
}

.w-160 {
  width: 16rem
}

.w-192 {
  width: 19.2rem
}

.w-200 {
  width: 20rem
}

.w-208 {
  width: 20.8rem
}

.w-216 {
  width: 21.6rem
}

.w-224 {
  width: 22.4rem
}

.w-256 {
  width: 25.6rem
}

.w-288 {
  width: 28.8rem
}

.w-320 {
  width: 32rem
}

.w-360 {
  width: 36rem
}

.w-384 {
  width: 38.4rem
}

.w-400 {
  width: 40rem
}

.w-512 {
  width: 51.2rem
}

.w-640 {
  width: 64rem
}

.w-auto {
  width: auto
}

.w-xs {
  width: 32rem
}

.w-sm {
  width: 48rem
}

.w-md {
  width: 64rem
}

.w-lg {
  width: 80rem
}

.w-xl {
  width: 96rem
}

.w-2xl {
  width: 112rem
}

.w-3xl {
  width: 128rem
}

.w-4xl {
  width: 144rem
}

.w-5xl {
  width: 160rem
}

.w-px {
  width: 1px
}

.w-0\.5 {
  width: 0.05rem
}

.w-1\.5 {
  width: 0.15rem
}

.w-2\.5 {
  width: 0.25rem
}

.w-3\.5 {
  width: 0.35rem
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-2\/3 {
  width: 66.666667%
}

.w-1\/4 {
  width: 25%
}

.w-2\/4 {
  width: 50%
}

.w-3\/4 {
  width: 75%
}

.w-1\/5 {
  width: 20%
}

.w-2\/5 {
  width: 40%
}

.w-3\/5 {
  width: 60%
}

.w-4\/5 {
  width: 80%
}

.w-1\/6 {
  width: 16.666667%
}

.w-2\/6 {
  width: 33.333333%
}

.w-3\/6 {
  width: 50%
}

.w-4\/6 {
  width: 66.666667%
}

.w-5\/6 {
  width: 83.333333%
}

.w-1\/12 {
  width: 8.333333%
}

.w-2\/12 {
  width: 16.666667%
}

.w-3\/12 {
  width: 25%
}

.w-4\/12 {
  width: 33.333333%
}

.w-5\/12 {
  width: 41.666667%
}

.w-6\/12 {
  width: 50%
}

.w-7\/12 {
  width: 58.333333%
}

.w-8\/12 {
  width: 66.666667%
}

.w-9\/12 {
  width: 75%
}

.w-10\/12 {
  width: 83.333333%
}

.w-11\/12 {
  width: 91.666667%
}

.w-full {
  width: 100%
}

.w-screen {
  width: 100vw
}

.w-min {
  width: min-content
}

.w-max {
  width: max-content
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-20 {
  z-index: 20
}

.z-30 {
  z-index: 30
}

.z-40 {
  z-index: 40
}

.z-50 {
  z-index: 50
}

.z-99 {
  z-index: 99
}

.z-999 {
  z-index: 999
}

.z-9999 {
  z-index: 9999
}

.z-auto {
  z-index: auto
}

.focus-within\:z-0:focus-within {
  z-index: 0
}

.focus-within\:z-10:focus-within {
  z-index: 10
}

.focus-within\:z-20:focus-within {
  z-index: 20
}

.focus-within\:z-30:focus-within {
  z-index: 30
}

.focus-within\:z-40:focus-within {
  z-index: 40
}

.focus-within\:z-50:focus-within {
  z-index: 50
}

.focus-within\:z-99:focus-within {
  z-index: 99
}

.focus-within\:z-999:focus-within {
  z-index: 999
}

.focus-within\:z-9999:focus-within {
  z-index: 9999
}

.focus-within\:z-auto:focus-within {
  z-index: auto
}

.focus\:z-0:focus {
  z-index: 0
}

.focus\:z-10:focus {
  z-index: 10
}

.focus\:z-20:focus {
  z-index: 20
}

.focus\:z-30:focus {
  z-index: 30
}

.focus\:z-40:focus {
  z-index: 40
}

.focus\:z-50:focus {
  z-index: 50
}

.focus\:z-99:focus {
  z-index: 99
}

.focus\:z-999:focus {
  z-index: 999
}

.focus\:z-9999:focus {
  z-index: 9999
}

.focus\:z-auto:focus {
  z-index: auto
}

.gap-0 {
  gap: 0
}

.gap-1 {
  gap: 0.1rem
}

.gap-2 {
  gap: 0.2rem
}

.gap-3 {
  gap: 0.3rem
}

.gap-4 {
  gap: 0.4rem
}

.gap-5 {
  gap: 0.5rem
}

.gap-6 {
  gap: 0.6rem
}

.gap-7 {
  gap: 0.7rem
}

.gap-8 {
  gap: 0.8rem
}

.gap-9 {
  gap: 0.9rem
}

.gap-10 {
  gap: 1.0rem
}

.gap-12 {
  gap: 1.2rem
}

.gap-14 {
  gap: 1.4rem
}

.gap-16 {
  gap: 1.6rem
}

.gap-20 {
  gap: 2rem
}

.gap-24 {
  gap: 2.4rem
}

.gap-28 {
  gap: 2.8rem
}

.gap-32 {
  gap: 3.2rem
}

.gap-36 {
  gap: 3.6rem
}

.gap-40 {
  gap: 4rem
}

.gap-44 {
  gap: 4.4rem
}

.gap-48 {
  gap: 4.8rem
}

.gap-52 {
  gap: 5.2rem
}

.gap-56 {
  gap: 5.6rem
}

.gap-60 {
  gap: 6rem
}

.gap-64 {
  gap: 6.4rem
}

.gap-68 {
  gap: 6.8rem
}

.gap-72 {
  gap: 7.2rem
}

.gap-76 {
  gap: 7.6rem
}

.gap-80 {
  gap: 8rem
}

.gap-84 {
  gap: 8.4rem
}

.gap-88 {
  gap: 8.8rem
}

.gap-92 {
  gap: 9.2rem
}

.gap-96 {
  gap: 9.6rem
}

.gap-128 {
  gap: 12.8rem
}

.gap-136 {
  gap: 13.6rem
}

.gap-160 {
  gap: 16rem
}

.gap-192 {
  gap: 19.2rem
}

.gap-200 {
  gap: 20rem
}

.gap-208 {
  gap: 20.8rem
}

.gap-216 {
  gap: 21.6rem
}

.gap-224 {
  gap: 22.4rem
}

.gap-256 {
  gap: 25.6rem
}

.gap-288 {
  gap: 28.8rem
}

.gap-320 {
  gap: 32rem
}

.gap-360 {
  gap: 36rem
}

.gap-384 {
  gap: 38.4rem
}

.gap-400 {
  gap: 40rem
}

.gap-512 {
  gap: 51.2rem
}

.gap-640 {
  gap: 64rem
}

.gap-xs {
  gap: 32rem
}

.gap-sm {
  gap: 48rem
}

.gap-md {
  gap: 64rem
}

.gap-lg {
  gap: 80rem
}

.gap-xl {
  gap: 96rem
}

.gap-2xl {
  gap: 112rem
}

.gap-3xl {
  gap: 128rem
}

.gap-4xl {
  gap: 144rem
}

.gap-5xl {
  gap: 160rem
}

.gap-px {
  gap: 1px
}

.gap-0\.5 {
  gap: 0.05rem
}

.gap-1\.5 {
  gap: 0.15rem
}

.gap-2\.5 {
  gap: 0.25rem
}

.gap-3\.5 {
  gap: 0.35rem
}

.gap-x-0 {
  column-gap: 0
}

.gap-x-1 {
  column-gap: 0.1rem
}

.gap-x-2 {
  column-gap: 0.2rem
}

.gap-x-3 {
  column-gap: 0.3rem
}

.gap-x-4 {
  column-gap: 0.4rem
}

.gap-x-5 {
  column-gap: 0.5rem
}

.gap-x-6 {
  column-gap: 0.6rem
}

.gap-x-7 {
  column-gap: 0.7rem
}

.gap-x-8 {
  column-gap: 0.8rem
}

.gap-x-9 {
  column-gap: 0.9rem
}

.gap-x-10 {
  column-gap: 1.0rem
}

.gap-x-12 {
  column-gap: 1.2rem
}

.gap-x-14 {
  column-gap: 1.4rem
}

.gap-x-16 {
  column-gap: 1.6rem
}

.gap-x-20 {
  column-gap: 2rem
}

.gap-x-24 {
  column-gap: 2.4rem
}

.gap-x-28 {
  column-gap: 2.8rem
}

.gap-x-32 {
  column-gap: 3.2rem
}

.gap-x-36 {
  column-gap: 3.6rem
}

.gap-x-40 {
  column-gap: 4rem
}

.gap-x-44 {
  column-gap: 4.4rem
}

.gap-x-48 {
  column-gap: 4.8rem
}

.gap-x-52 {
  column-gap: 5.2rem
}

.gap-x-56 {
  column-gap: 5.6rem
}

.gap-x-60 {
  column-gap: 6rem
}

.gap-x-64 {
  column-gap: 6.4rem
}

.gap-x-68 {
  column-gap: 6.8rem
}

.gap-x-72 {
  column-gap: 7.2rem
}

.gap-x-76 {
  column-gap: 7.6rem
}

.gap-x-80 {
  column-gap: 8rem
}

.gap-x-84 {
  column-gap: 8.4rem
}

.gap-x-88 {
  column-gap: 8.8rem
}

.gap-x-92 {
  column-gap: 9.2rem
}

.gap-x-96 {
  column-gap: 9.6rem
}

.gap-x-128 {
  column-gap: 12.8rem
}

.gap-x-136 {
  column-gap: 13.6rem
}

.gap-x-160 {
  column-gap: 16rem
}

.gap-x-192 {
  column-gap: 19.2rem
}

.gap-x-200 {
  column-gap: 20rem
}

.gap-x-208 {
  column-gap: 20.8rem
}

.gap-x-216 {
  column-gap: 21.6rem
}

.gap-x-224 {
  column-gap: 22.4rem
}

.gap-x-256 {
  column-gap: 25.6rem
}

.gap-x-288 {
  column-gap: 28.8rem
}

.gap-x-320 {
  column-gap: 32rem
}

.gap-x-360 {
  column-gap: 36rem
}

.gap-x-384 {
  column-gap: 38.4rem
}

.gap-x-400 {
  column-gap: 40rem
}

.gap-x-512 {
  column-gap: 51.2rem
}

.gap-x-640 {
  column-gap: 64rem
}

.gap-x-xs {
  column-gap: 32rem
}

.gap-x-sm {
  column-gap: 48rem
}

.gap-x-md {
  column-gap: 64rem
}

.gap-x-lg {
  column-gap: 80rem
}

.gap-x-xl {
  column-gap: 96rem
}

.gap-x-2xl {
  column-gap: 112rem
}

.gap-x-3xl {
  column-gap: 128rem
}

.gap-x-4xl {
  column-gap: 144rem
}

.gap-x-5xl {
  column-gap: 160rem
}

.gap-x-px {
  column-gap: 1px
}

.gap-x-0\.5 {
  column-gap: 0.05rem
}

.gap-x-1\.5 {
  column-gap: 0.15rem
}

.gap-x-2\.5 {
  column-gap: 0.25rem
}

.gap-x-3\.5 {
  column-gap: 0.35rem
}

.gap-y-0 {
  row-gap: 0
}

.gap-y-1 {
  row-gap: 0.1rem
}

.gap-y-2 {
  row-gap: 0.2rem
}

.gap-y-3 {
  row-gap: 0.3rem
}

.gap-y-4 {
  row-gap: 0.4rem
}

.gap-y-5 {
  row-gap: 0.5rem
}

.gap-y-6 {
  row-gap: 0.6rem
}

.gap-y-7 {
  row-gap: 0.7rem
}

.gap-y-8 {
  row-gap: 0.8rem
}

.gap-y-9 {
  row-gap: 0.9rem
}

.gap-y-10 {
  row-gap: 1.0rem
}

.gap-y-12 {
  row-gap: 1.2rem
}

.gap-y-14 {
  row-gap: 1.4rem
}

.gap-y-16 {
  row-gap: 1.6rem
}

.gap-y-20 {
  row-gap: 2rem
}

.gap-y-24 {
  row-gap: 2.4rem
}

.gap-y-28 {
  row-gap: 2.8rem
}

.gap-y-32 {
  row-gap: 3.2rem
}

.gap-y-36 {
  row-gap: 3.6rem
}

.gap-y-40 {
  row-gap: 4rem
}

.gap-y-44 {
  row-gap: 4.4rem
}

.gap-y-48 {
  row-gap: 4.8rem
}

.gap-y-52 {
  row-gap: 5.2rem
}

.gap-y-56 {
  row-gap: 5.6rem
}

.gap-y-60 {
  row-gap: 6rem
}

.gap-y-64 {
  row-gap: 6.4rem
}

.gap-y-68 {
  row-gap: 6.8rem
}

.gap-y-72 {
  row-gap: 7.2rem
}

.gap-y-76 {
  row-gap: 7.6rem
}

.gap-y-80 {
  row-gap: 8rem
}

.gap-y-84 {
  row-gap: 8.4rem
}

.gap-y-88 {
  row-gap: 8.8rem
}

.gap-y-92 {
  row-gap: 9.2rem
}

.gap-y-96 {
  row-gap: 9.6rem
}

.gap-y-128 {
  row-gap: 12.8rem
}

.gap-y-136 {
  row-gap: 13.6rem
}

.gap-y-160 {
  row-gap: 16rem
}

.gap-y-192 {
  row-gap: 19.2rem
}

.gap-y-200 {
  row-gap: 20rem
}

.gap-y-208 {
  row-gap: 20.8rem
}

.gap-y-216 {
  row-gap: 21.6rem
}

.gap-y-224 {
  row-gap: 22.4rem
}

.gap-y-256 {
  row-gap: 25.6rem
}

.gap-y-288 {
  row-gap: 28.8rem
}

.gap-y-320 {
  row-gap: 32rem
}

.gap-y-360 {
  row-gap: 36rem
}

.gap-y-384 {
  row-gap: 38.4rem
}

.gap-y-400 {
  row-gap: 40rem
}

.gap-y-512 {
  row-gap: 51.2rem
}

.gap-y-640 {
  row-gap: 64rem
}

.gap-y-xs {
  row-gap: 32rem
}

.gap-y-sm {
  row-gap: 48rem
}

.gap-y-md {
  row-gap: 64rem
}

.gap-y-lg {
  row-gap: 80rem
}

.gap-y-xl {
  row-gap: 96rem
}

.gap-y-2xl {
  row-gap: 112rem
}

.gap-y-3xl {
  row-gap: 128rem
}

.gap-y-4xl {
  row-gap: 144rem
}

.gap-y-5xl {
  row-gap: 160rem
}

.gap-y-px {
  row-gap: 1px
}

.gap-y-0\.5 {
  row-gap: 0.05rem
}

.gap-y-1\.5 {
  row-gap: 0.15rem
}

.gap-y-2\.5 {
  row-gap: 0.25rem
}

.gap-y-3\.5 {
  row-gap: 0.35rem
}

.grid-flow-row {
  grid-auto-flow: row
}

.grid-flow-col {
  grid-auto-flow: column
}

.grid-flow-row-dense {
  grid-auto-flow: row dense
}

.grid-flow-col-dense {
  grid-auto-flow: column dense
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr))
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr))
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr))
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr))
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr))
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr))
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr))
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr))
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr))
}

.grid-cols-none {
  grid-template-columns: none
}

.auto-cols-auto {
  grid-auto-columns: auto
}

.auto-cols-min {
  grid-auto-columns: min-content
}

.auto-cols-max {
  grid-auto-columns: max-content
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr)
}

.col-auto {
  grid-column: auto
}

.col-span-1 {
  grid-column: span 1 / span 1
}

.col-span-2 {
  grid-column: span 2 / span 2
}

.col-span-3 {
  grid-column: span 3 / span 3
}

.col-span-4 {
  grid-column: span 4 / span 4
}

.col-span-5 {
  grid-column: span 5 / span 5
}

.col-span-6 {
  grid-column: span 6 / span 6
}

.col-span-7 {
  grid-column: span 7 / span 7
}

.col-span-8 {
  grid-column: span 8 / span 8
}

.col-span-9 {
  grid-column: span 9 / span 9
}

.col-span-10 {
  grid-column: span 10 / span 10
}

.col-span-11 {
  grid-column: span 11 / span 11
}

.col-span-12 {
  grid-column: span 12 / span 12
}

.col-span-full {
  grid-column: 1 / -1
}

.col-start-1 {
  grid-column-start: 1
}

.col-start-2 {
  grid-column-start: 2
}

.col-start-3 {
  grid-column-start: 3
}

.col-start-4 {
  grid-column-start: 4
}

.col-start-5 {
  grid-column-start: 5
}

.col-start-6 {
  grid-column-start: 6
}

.col-start-7 {
  grid-column-start: 7
}

.col-start-8 {
  grid-column-start: 8
}

.col-start-9 {
  grid-column-start: 9
}

.col-start-10 {
  grid-column-start: 10
}

.col-start-11 {
  grid-column-start: 11
}

.col-start-12 {
  grid-column-start: 12
}

.col-start-13 {
  grid-column-start: 13
}

.col-start-auto {
  grid-column-start: auto
}

.col-end-1 {
  grid-column-end: 1
}

.col-end-2 {
  grid-column-end: 2
}

.col-end-3 {
  grid-column-end: 3
}

.col-end-4 {
  grid-column-end: 4
}

.col-end-5 {
  grid-column-end: 5
}

.col-end-6 {
  grid-column-end: 6
}

.col-end-7 {
  grid-column-end: 7
}

.col-end-8 {
  grid-column-end: 8
}

.col-end-9 {
  grid-column-end: 9
}

.col-end-10 {
  grid-column-end: 10
}

.col-end-11 {
  grid-column-end: 11
}

.col-end-12 {
  grid-column-end: 12
}

.col-end-13 {
  grid-column-end: 13
}

.col-end-auto {
  grid-column-end: auto
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr))
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr))
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr))
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr))
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr))
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr))
}

.grid-rows-none {
  grid-template-rows: none
}

.auto-rows-auto {
  grid-auto-rows: auto
}

.auto-rows-min {
  grid-auto-rows: min-content
}

.auto-rows-max {
  grid-auto-rows: max-content
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr)
}

.row-auto {
  grid-row: auto
}

.row-span-1 {
  grid-row: span 1 / span 1
}

.row-span-2 {
  grid-row: span 2 / span 2
}

.row-span-3 {
  grid-row: span 3 / span 3
}

.row-span-4 {
  grid-row: span 4 / span 4
}

.row-span-5 {
  grid-row: span 5 / span 5
}

.row-span-6 {
  grid-row: span 6 / span 6
}

.row-span-full {
  grid-row: 1 / -1
}

.row-start-1 {
  grid-row-start: 1
}

.row-start-2 {
  grid-row-start: 2
}

.row-start-3 {
  grid-row-start: 3
}

.row-start-4 {
  grid-row-start: 4
}

.row-start-5 {
  grid-row-start: 5
}

.row-start-6 {
  grid-row-start: 6
}

.row-start-7 {
  grid-row-start: 7
}

.row-start-auto {
  grid-row-start: auto
}

.row-end-1 {
  grid-row-end: 1
}

.row-end-2 {
  grid-row-end: 2
}

.row-end-3 {
  grid-row-end: 3
}

.row-end-4 {
  grid-row-end: 4
}

.row-end-5 {
  grid-row-end: 5
}

.row-end-6 {
  grid-row-end: 6
}

.row-end-7 {
  grid-row-end: 7
}

.row-end-auto {
  grid-row-end: auto
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform-gpu {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform-none {
  transform: none
}

.origin-center {
  transform-origin: center
}

.origin-top {
  transform-origin: top
}

.origin-top-right {
  transform-origin: top right
}

.origin-right {
  transform-origin: right
}

.origin-bottom-right {
  transform-origin: bottom right
}

.origin-bottom {
  transform-origin: bottom
}

.origin-bottom-left {
  transform-origin: bottom left
}

.origin-left {
  transform-origin: left
}

.origin-top-left {
  transform-origin: top left
}

.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0
}

.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1
}

.scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05
}

.scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1
}

.scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5
}

.scale-x-0 {
  --tw-scale-x: 0
}

.scale-x-50 {
  --tw-scale-x: .5
}

.scale-x-75 {
  --tw-scale-x: .75
}

.scale-x-90 {
  --tw-scale-x: .9
}

.scale-x-95 {
  --tw-scale-x: .95
}

.scale-x-100 {
  --tw-scale-x: 1
}

.scale-x-105 {
  --tw-scale-x: 1.05
}

.scale-x-110 {
  --tw-scale-x: 1.1
}

.scale-x-125 {
  --tw-scale-x: 1.25
}

.scale-x-150 {
  --tw-scale-x: 1.5
}

.scale-y-0 {
  --tw-scale-y: 0
}

.scale-y-50 {
  --tw-scale-y: .5
}

.scale-y-75 {
  --tw-scale-y: .75
}

.scale-y-90 {
  --tw-scale-y: .9
}

.scale-y-95 {
  --tw-scale-y: .95
}

.scale-y-100 {
  --tw-scale-y: 1
}

.scale-y-105 {
  --tw-scale-y: 1.05
}

.scale-y-110 {
  --tw-scale-y: 1.1
}

.scale-y-125 {
  --tw-scale-y: 1.25
}

.scale-y-150 {
  --tw-scale-y: 1.5
}

.hover\:scale-0:hover {
  --tw-scale-x: 0;
  --tw-scale-y: 0
}

.hover\:scale-50:hover {
  --tw-scale-x: .5;
  --tw-scale-y: .5
}

.hover\:scale-75:hover {
  --tw-scale-x: .75;
  --tw-scale-y: .75
}

.hover\:scale-90:hover {
  --tw-scale-x: .9;
  --tw-scale-y: .9
}

.hover\:scale-95:hover {
  --tw-scale-x: .95;
  --tw-scale-y: .95
}

.hover\:scale-100:hover {
  --tw-scale-x: 1;
  --tw-scale-y: 1
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1
}

.hover\:scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25
}

.hover\:scale-150:hover {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5
}

.hover\:scale-x-0:hover {
  --tw-scale-x: 0
}

.hover\:scale-x-50:hover {
  --tw-scale-x: .5
}

.hover\:scale-x-75:hover {
  --tw-scale-x: .75
}

.hover\:scale-x-90:hover {
  --tw-scale-x: .9
}

.hover\:scale-x-95:hover {
  --tw-scale-x: .95
}

.hover\:scale-x-100:hover {
  --tw-scale-x: 1
}

.hover\:scale-x-105:hover {
  --tw-scale-x: 1.05
}

.hover\:scale-x-110:hover {
  --tw-scale-x: 1.1
}

.hover\:scale-x-125:hover {
  --tw-scale-x: 1.25
}

.hover\:scale-x-150:hover {
  --tw-scale-x: 1.5
}

.hover\:scale-y-0:hover {
  --tw-scale-y: 0
}

.hover\:scale-y-50:hover {
  --tw-scale-y: .5
}

.hover\:scale-y-75:hover {
  --tw-scale-y: .75
}

.hover\:scale-y-90:hover {
  --tw-scale-y: .9
}

.hover\:scale-y-95:hover {
  --tw-scale-y: .95
}

.hover\:scale-y-100:hover {
  --tw-scale-y: 1
}

.hover\:scale-y-105:hover {
  --tw-scale-y: 1.05
}

.hover\:scale-y-110:hover {
  --tw-scale-y: 1.1
}

.hover\:scale-y-125:hover {
  --tw-scale-y: 1.25
}

.hover\:scale-y-150:hover {
  --tw-scale-y: 1.5
}

.focus\:scale-0:focus {
  --tw-scale-x: 0;
  --tw-scale-y: 0
}

.focus\:scale-50:focus {
  --tw-scale-x: .5;
  --tw-scale-y: .5
}

.focus\:scale-75:focus {
  --tw-scale-x: .75;
  --tw-scale-y: .75
}

.focus\:scale-90:focus {
  --tw-scale-x: .9;
  --tw-scale-y: .9
}

.focus\:scale-95:focus {
  --tw-scale-x: .95;
  --tw-scale-y: .95
}

.focus\:scale-100:focus {
  --tw-scale-x: 1;
  --tw-scale-y: 1
}

.focus\:scale-105:focus {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05
}

.focus\:scale-110:focus {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1
}

.focus\:scale-125:focus {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25
}

.focus\:scale-150:focus {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5
}

.focus\:scale-x-0:focus {
  --tw-scale-x: 0
}

.focus\:scale-x-50:focus {
  --tw-scale-x: .5
}

.focus\:scale-x-75:focus {
  --tw-scale-x: .75
}

.focus\:scale-x-90:focus {
  --tw-scale-x: .9
}

.focus\:scale-x-95:focus {
  --tw-scale-x: .95
}

.focus\:scale-x-100:focus {
  --tw-scale-x: 1
}

.focus\:scale-x-105:focus {
  --tw-scale-x: 1.05
}

.focus\:scale-x-110:focus {
  --tw-scale-x: 1.1
}

.focus\:scale-x-125:focus {
  --tw-scale-x: 1.25
}

.focus\:scale-x-150:focus {
  --tw-scale-x: 1.5
}

.focus\:scale-y-0:focus {
  --tw-scale-y: 0
}

.focus\:scale-y-50:focus {
  --tw-scale-y: .5
}

.focus\:scale-y-75:focus {
  --tw-scale-y: .75
}

.focus\:scale-y-90:focus {
  --tw-scale-y: .9
}

.focus\:scale-y-95:focus {
  --tw-scale-y: .95
}

.focus\:scale-y-100:focus {
  --tw-scale-y: 1
}

.focus\:scale-y-105:focus {
  --tw-scale-y: 1.05
}

.focus\:scale-y-110:focus {
  --tw-scale-y: 1.1
}

.focus\:scale-y-125:focus {
  --tw-scale-y: 1.25
}

.focus\:scale-y-150:focus {
  --tw-scale-y: 1.5
}

.rotate-0 {
  --tw-rotate: 0deg
}

.rotate-1 {
  --tw-rotate: 1deg
}

.rotate-2 {
  --tw-rotate: 2deg
}

.rotate-3 {
  --tw-rotate: 3deg
}

.rotate-6 {
  --tw-rotate: 6deg
}

.rotate-12 {
  --tw-rotate: 12deg
}

.rotate-45 {
  --tw-rotate: 45deg
}

.rotate-90 {
  --tw-rotate: 90deg
}

.rotate-180 {
  --tw-rotate: 180deg
}

.-rotate-180 {
  --tw-rotate: -180deg
}

.-rotate-90 {
  --tw-rotate: -90deg
}

.-rotate-45 {
  --tw-rotate: -45deg
}

.-rotate-12 {
  --tw-rotate: -12deg
}

.-rotate-6 {
  --tw-rotate: -6deg
}

.-rotate-3 {
  --tw-rotate: -3deg
}

.-rotate-2 {
  --tw-rotate: -2deg
}

.-rotate-1 {
  --tw-rotate: -1deg
}

.hover\:rotate-0:hover {
  --tw-rotate: 0deg
}

.hover\:rotate-1:hover {
  --tw-rotate: 1deg
}

.hover\:rotate-2:hover {
  --tw-rotate: 2deg
}

.hover\:rotate-3:hover {
  --tw-rotate: 3deg
}

.hover\:rotate-6:hover {
  --tw-rotate: 6deg
}

.hover\:rotate-12:hover {
  --tw-rotate: 12deg
}

.hover\:rotate-45:hover {
  --tw-rotate: 45deg
}

.hover\:rotate-90:hover {
  --tw-rotate: 90deg
}

.hover\:rotate-180:hover {
  --tw-rotate: 180deg
}

.hover\:-rotate-180:hover {
  --tw-rotate: -180deg
}

.hover\:-rotate-90:hover {
  --tw-rotate: -90deg
}

.hover\:-rotate-45:hover {
  --tw-rotate: -45deg
}

.hover\:-rotate-12:hover {
  --tw-rotate: -12deg
}

.hover\:-rotate-6:hover {
  --tw-rotate: -6deg
}

.hover\:-rotate-3:hover {
  --tw-rotate: -3deg
}

.hover\:-rotate-2:hover {
  --tw-rotate: -2deg
}

.hover\:-rotate-1:hover {
  --tw-rotate: -1deg
}

.focus\:rotate-0:focus {
  --tw-rotate: 0deg
}

.focus\:rotate-1:focus {
  --tw-rotate: 1deg
}

.focus\:rotate-2:focus {
  --tw-rotate: 2deg
}

.focus\:rotate-3:focus {
  --tw-rotate: 3deg
}

.focus\:rotate-6:focus {
  --tw-rotate: 6deg
}

.focus\:rotate-12:focus {
  --tw-rotate: 12deg
}

.focus\:rotate-45:focus {
  --tw-rotate: 45deg
}

.focus\:rotate-90:focus {
  --tw-rotate: 90deg
}

.focus\:rotate-180:focus {
  --tw-rotate: 180deg
}

.focus\:-rotate-180:focus {
  --tw-rotate: -180deg
}

.focus\:-rotate-90:focus {
  --tw-rotate: -90deg
}

.focus\:-rotate-45:focus {
  --tw-rotate: -45deg
}

.focus\:-rotate-12:focus {
  --tw-rotate: -12deg
}

.focus\:-rotate-6:focus {
  --tw-rotate: -6deg
}

.focus\:-rotate-3:focus {
  --tw-rotate: -3deg
}

.focus\:-rotate-2:focus {
  --tw-rotate: -2deg
}

.focus\:-rotate-1:focus {
  --tw-rotate: -1deg
}

.translate-x-0 {
  --tw-translate-x: 0
}

.translate-x-1 {
  --tw-translate-x: 0.1rem
}

.translate-x-2 {
  --tw-translate-x: 0.2rem
}

.translate-x-3 {
  --tw-translate-x: 0.3rem
}

.translate-x-4 {
  --tw-translate-x: 0.4rem
}

.translate-x-5 {
  --tw-translate-x: 0.5rem
}

.translate-x-6 {
  --tw-translate-x: 0.6rem
}

.translate-x-7 {
  --tw-translate-x: 0.7rem
}

.translate-x-8 {
  --tw-translate-x: 0.8rem
}

.translate-x-9 {
  --tw-translate-x: 0.9rem
}

.translate-x-10 {
  --tw-translate-x: 1.0rem
}

.translate-x-12 {
  --tw-translate-x: 1.2rem
}

.translate-x-14 {
  --tw-translate-x: 1.4rem
}

.translate-x-16 {
  --tw-translate-x: 1.6rem
}

.translate-x-20 {
  --tw-translate-x: 2rem
}

.translate-x-24 {
  --tw-translate-x: 2.4rem
}

.translate-x-28 {
  --tw-translate-x: 2.8rem
}

.translate-x-32 {
  --tw-translate-x: 3.2rem
}

.translate-x-36 {
  --tw-translate-x: 3.6rem
}

.translate-x-40 {
  --tw-translate-x: 4rem
}

.translate-x-44 {
  --tw-translate-x: 4.4rem
}

.translate-x-48 {
  --tw-translate-x: 4.8rem
}

.translate-x-52 {
  --tw-translate-x: 5.2rem
}

.translate-x-56 {
  --tw-translate-x: 5.6rem
}

.translate-x-60 {
  --tw-translate-x: 6rem
}

.translate-x-64 {
  --tw-translate-x: 6.4rem
}

.translate-x-68 {
  --tw-translate-x: 6.8rem
}

.translate-x-72 {
  --tw-translate-x: 7.2rem
}

.translate-x-76 {
  --tw-translate-x: 7.6rem
}

.translate-x-80 {
  --tw-translate-x: 8rem
}

.translate-x-84 {
  --tw-translate-x: 8.4rem
}

.translate-x-88 {
  --tw-translate-x: 8.8rem
}

.translate-x-92 {
  --tw-translate-x: 9.2rem
}

.translate-x-96 {
  --tw-translate-x: 9.6rem
}

.translate-x-128 {
  --tw-translate-x: 12.8rem
}

.translate-x-136 {
  --tw-translate-x: 13.6rem
}

.translate-x-160 {
  --tw-translate-x: 16rem
}

.translate-x-192 {
  --tw-translate-x: 19.2rem
}

.translate-x-200 {
  --tw-translate-x: 20rem
}

.translate-x-208 {
  --tw-translate-x: 20.8rem
}

.translate-x-216 {
  --tw-translate-x: 21.6rem
}

.translate-x-224 {
  --tw-translate-x: 22.4rem
}

.translate-x-256 {
  --tw-translate-x: 25.6rem
}

.translate-x-288 {
  --tw-translate-x: 28.8rem
}

.translate-x-320 {
  --tw-translate-x: 32rem
}

.translate-x-360 {
  --tw-translate-x: 36rem
}

.translate-x-384 {
  --tw-translate-x: 38.4rem
}

.translate-x-400 {
  --tw-translate-x: 40rem
}

.translate-x-512 {
  --tw-translate-x: 51.2rem
}

.translate-x-640 {
  --tw-translate-x: 64rem
}

.translate-x-xs {
  --tw-translate-x: 32rem
}

.translate-x-sm {
  --tw-translate-x: 48rem
}

.translate-x-md {
  --tw-translate-x: 64rem
}

.translate-x-lg {
  --tw-translate-x: 80rem
}

.translate-x-xl {
  --tw-translate-x: 96rem
}

.translate-x-2xl {
  --tw-translate-x: 112rem
}

.translate-x-3xl {
  --tw-translate-x: 128rem
}

.translate-x-4xl {
  --tw-translate-x: 144rem
}

.translate-x-5xl {
  --tw-translate-x: 160rem
}

.translate-x-px {
  --tw-translate-x: 1px
}

.translate-x-0\.5 {
  --tw-translate-x: 0.05rem
}

.translate-x-1\.5 {
  --tw-translate-x: 0.15rem
}

.translate-x-2\.5 {
  --tw-translate-x: 0.25rem
}

.translate-x-3\.5 {
  --tw-translate-x: 0.35rem
}

.-translate-x-1 {
  --tw-translate-x: -0.1rem
}

.-translate-x-2 {
  --tw-translate-x: -0.2rem
}

.-translate-x-3 {
  --tw-translate-x: -0.3rem
}

.-translate-x-4 {
  --tw-translate-x: -0.4rem
}

.-translate-x-5 {
  --tw-translate-x: -0.5rem
}

.-translate-x-6 {
  --tw-translate-x: -0.6rem
}

.-translate-x-7 {
  --tw-translate-x: -0.7rem
}

.-translate-x-8 {
  --tw-translate-x: -0.8rem
}

.-translate-x-9 {
  --tw-translate-x: -0.9rem
}

.-translate-x-10 {
  --tw-translate-x: -1rem
}

.-translate-x-12 {
  --tw-translate-x: -1.2rem
}

.-translate-x-14 {
  --tw-translate-x: -1.4rem
}

.-translate-x-16 {
  --tw-translate-x: -1.6rem
}

.-translate-x-20 {
  --tw-translate-x: -2rem
}

.-translate-x-24 {
  --tw-translate-x: -2.4rem
}

.-translate-x-28 {
  --tw-translate-x: -2.8rem
}

.-translate-x-32 {
  --tw-translate-x: -3.2rem
}

.-translate-x-36 {
  --tw-translate-x: -3.6rem
}

.-translate-x-40 {
  --tw-translate-x: -4rem
}

.-translate-x-44 {
  --tw-translate-x: -4.4rem
}

.-translate-x-48 {
  --tw-translate-x: -4.8rem
}

.-translate-x-52 {
  --tw-translate-x: -5.2rem
}

.-translate-x-56 {
  --tw-translate-x: -5.6rem
}

.-translate-x-60 {
  --tw-translate-x: -6rem
}

.-translate-x-64 {
  --tw-translate-x: -6.4rem
}

.-translate-x-68 {
  --tw-translate-x: -6.8rem
}

.-translate-x-72 {
  --tw-translate-x: -7.2rem
}

.-translate-x-76 {
  --tw-translate-x: -7.6rem
}

.-translate-x-80 {
  --tw-translate-x: -8rem
}

.-translate-x-84 {
  --tw-translate-x: -8.4rem
}

.-translate-x-88 {
  --tw-translate-x: -8.8rem
}

.-translate-x-92 {
  --tw-translate-x: -9.2rem
}

.-translate-x-96 {
  --tw-translate-x: -9.6rem
}

.-translate-x-128 {
  --tw-translate-x: -12.8rem
}

.-translate-x-136 {
  --tw-translate-x: -13.6rem
}

.-translate-x-160 {
  --tw-translate-x: -16rem
}

.-translate-x-192 {
  --tw-translate-x: -19.2rem
}

.-translate-x-200 {
  --tw-translate-x: -20rem
}

.-translate-x-208 {
  --tw-translate-x: -20.8rem
}

.-translate-x-216 {
  --tw-translate-x: -21.6rem
}

.-translate-x-224 {
  --tw-translate-x: -22.4rem
}

.-translate-x-256 {
  --tw-translate-x: -25.6rem
}

.-translate-x-288 {
  --tw-translate-x: -28.8rem
}

.-translate-x-320 {
  --tw-translate-x: -32rem
}

.-translate-x-360 {
  --tw-translate-x: -36rem
}

.-translate-x-384 {
  --tw-translate-x: -38.4rem
}

.-translate-x-400 {
  --tw-translate-x: -40rem
}

.-translate-x-512 {
  --tw-translate-x: -51.2rem
}

.-translate-x-640 {
  --tw-translate-x: -64rem
}

.-translate-x-xs {
  --tw-translate-x: -32rem
}

.-translate-x-sm {
  --tw-translate-x: -48rem
}

.-translate-x-md {
  --tw-translate-x: -64rem
}

.-translate-x-lg {
  --tw-translate-x: -80rem
}

.-translate-x-xl {
  --tw-translate-x: -96rem
}

.-translate-x-2xl {
  --tw-translate-x: -112rem
}

.-translate-x-3xl {
  --tw-translate-x: -128rem
}

.-translate-x-4xl {
  --tw-translate-x: -144rem
}

.-translate-x-5xl {
  --tw-translate-x: -160rem
}

.-translate-x-px {
  --tw-translate-x: -1px
}

.-translate-x-0\.5 {
  --tw-translate-x: -0.05rem
}

.-translate-x-1\.5 {
  --tw-translate-x: -0.15rem
}

.-translate-x-2\.5 {
  --tw-translate-x: -0.25rem
}

.-translate-x-3\.5 {
  --tw-translate-x: -0.35rem
}

.translate-x-1\/2 {
  --tw-translate-x: 50%
}

.translate-x-1\/3 {
  --tw-translate-x: 33.333333%
}

.translate-x-2\/3 {
  --tw-translate-x: 66.666667%
}

.translate-x-1\/4 {
  --tw-translate-x: 25%
}

.translate-x-2\/4 {
  --tw-translate-x: 50%
}

.translate-x-3\/4 {
  --tw-translate-x: 75%
}

.translate-x-full {
  --tw-translate-x: 100%
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%
}

.-translate-x-1\/3 {
  --tw-translate-x: -33.333333%
}

.-translate-x-2\/3 {
  --tw-translate-x: -66.666667%
}

.-translate-x-1\/4 {
  --tw-translate-x: -25%
}

.-translate-x-2\/4 {
  --tw-translate-x: -50%
}

.-translate-x-3\/4 {
  --tw-translate-x: -75%
}

.-translate-x-full {
  --tw-translate-x: -100%
}

.translate-y-0 {
  --tw-translate-y: 0
}

.translate-y-1 {
  --tw-translate-y: 0.1rem
}

.translate-y-2 {
  --tw-translate-y: 0.2rem
}

.translate-y-3 {
  --tw-translate-y: 0.3rem
}

.translate-y-4 {
  --tw-translate-y: 0.4rem
}

.translate-y-5 {
  --tw-translate-y: 0.5rem
}

.translate-y-6 {
  --tw-translate-y: 0.6rem
}

.translate-y-7 {
  --tw-translate-y: 0.7rem
}

.translate-y-8 {
  --tw-translate-y: 0.8rem
}

.translate-y-9 {
  --tw-translate-y: 0.9rem
}

.translate-y-10 {
  --tw-translate-y: 1.0rem
}

.translate-y-12 {
  --tw-translate-y: 1.2rem
}

.translate-y-14 {
  --tw-translate-y: 1.4rem
}

.translate-y-16 {
  --tw-translate-y: 1.6rem
}

.translate-y-20 {
  --tw-translate-y: 2rem
}

.translate-y-24 {
  --tw-translate-y: 2.4rem
}

.translate-y-28 {
  --tw-translate-y: 2.8rem
}

.translate-y-32 {
  --tw-translate-y: 3.2rem
}

.translate-y-36 {
  --tw-translate-y: 3.6rem
}

.translate-y-40 {
  --tw-translate-y: 4rem
}

.translate-y-44 {
  --tw-translate-y: 4.4rem
}

.translate-y-48 {
  --tw-translate-y: 4.8rem
}

.translate-y-52 {
  --tw-translate-y: 5.2rem
}

.translate-y-56 {
  --tw-translate-y: 5.6rem
}

.translate-y-60 {
  --tw-translate-y: 6rem
}

.translate-y-64 {
  --tw-translate-y: 6.4rem
}

.translate-y-68 {
  --tw-translate-y: 6.8rem
}

.translate-y-72 {
  --tw-translate-y: 7.2rem
}

.translate-y-76 {
  --tw-translate-y: 7.6rem
}

.translate-y-80 {
  --tw-translate-y: 8rem
}

.translate-y-84 {
  --tw-translate-y: 8.4rem
}

.translate-y-88 {
  --tw-translate-y: 8.8rem
}

.translate-y-92 {
  --tw-translate-y: 9.2rem
}

.translate-y-96 {
  --tw-translate-y: 9.6rem
}

.translate-y-128 {
  --tw-translate-y: 12.8rem
}

.translate-y-136 {
  --tw-translate-y: 13.6rem
}

.translate-y-160 {
  --tw-translate-y: 16rem
}

.translate-y-192 {
  --tw-translate-y: 19.2rem
}

.translate-y-200 {
  --tw-translate-y: 20rem
}

.translate-y-208 {
  --tw-translate-y: 20.8rem
}

.translate-y-216 {
  --tw-translate-y: 21.6rem
}

.translate-y-224 {
  --tw-translate-y: 22.4rem
}

.translate-y-256 {
  --tw-translate-y: 25.6rem
}

.translate-y-288 {
  --tw-translate-y: 28.8rem
}

.translate-y-320 {
  --tw-translate-y: 32rem
}

.translate-y-360 {
  --tw-translate-y: 36rem
}

.translate-y-384 {
  --tw-translate-y: 38.4rem
}

.translate-y-400 {
  --tw-translate-y: 40rem
}

.translate-y-512 {
  --tw-translate-y: 51.2rem
}

.translate-y-640 {
  --tw-translate-y: 64rem
}

.translate-y-xs {
  --tw-translate-y: 32rem
}

.translate-y-sm {
  --tw-translate-y: 48rem
}

.translate-y-md {
  --tw-translate-y: 64rem
}

.translate-y-lg {
  --tw-translate-y: 80rem
}

.translate-y-xl {
  --tw-translate-y: 96rem
}

.translate-y-2xl {
  --tw-translate-y: 112rem
}

.translate-y-3xl {
  --tw-translate-y: 128rem
}

.translate-y-4xl {
  --tw-translate-y: 144rem
}

.translate-y-5xl {
  --tw-translate-y: 160rem
}

.translate-y-px {
  --tw-translate-y: 1px
}

.translate-y-0\.5 {
  --tw-translate-y: 0.05rem
}

.translate-y-1\.5 {
  --tw-translate-y: 0.15rem
}

.translate-y-2\.5 {
  --tw-translate-y: 0.25rem
}

.translate-y-3\.5 {
  --tw-translate-y: 0.35rem
}

.-translate-y-1 {
  --tw-translate-y: -0.1rem
}

.-translate-y-2 {
  --tw-translate-y: -0.2rem
}

.-translate-y-3 {
  --tw-translate-y: -0.3rem
}

.-translate-y-4 {
  --tw-translate-y: -0.4rem
}

.-translate-y-5 {
  --tw-translate-y: -0.5rem
}

.-translate-y-6 {
  --tw-translate-y: -0.6rem
}

.-translate-y-7 {
  --tw-translate-y: -0.7rem
}

.-translate-y-8 {
  --tw-translate-y: -0.8rem
}

.-translate-y-9 {
  --tw-translate-y: -0.9rem
}

.-translate-y-10 {
  --tw-translate-y: -1rem
}

.-translate-y-12 {
  --tw-translate-y: -1.2rem
}

.-translate-y-14 {
  --tw-translate-y: -1.4rem
}

.-translate-y-16 {
  --tw-translate-y: -1.6rem
}

.-translate-y-20 {
  --tw-translate-y: -2rem
}

.-translate-y-24 {
  --tw-translate-y: -2.4rem
}

.-translate-y-28 {
  --tw-translate-y: -2.8rem
}

.-translate-y-32 {
  --tw-translate-y: -3.2rem
}

.-translate-y-36 {
  --tw-translate-y: -3.6rem
}

.-translate-y-40 {
  --tw-translate-y: -4rem
}

.-translate-y-44 {
  --tw-translate-y: -4.4rem
}

.-translate-y-48 {
  --tw-translate-y: -4.8rem
}

.-translate-y-52 {
  --tw-translate-y: -5.2rem
}

.-translate-y-56 {
  --tw-translate-y: -5.6rem
}

.-translate-y-60 {
  --tw-translate-y: -6rem
}

.-translate-y-64 {
  --tw-translate-y: -6.4rem
}

.-translate-y-68 {
  --tw-translate-y: -6.8rem
}

.-translate-y-72 {
  --tw-translate-y: -7.2rem
}

.-translate-y-76 {
  --tw-translate-y: -7.6rem
}

.-translate-y-80 {
  --tw-translate-y: -8rem
}

.-translate-y-84 {
  --tw-translate-y: -8.4rem
}

.-translate-y-88 {
  --tw-translate-y: -8.8rem
}

.-translate-y-92 {
  --tw-translate-y: -9.2rem
}

.-translate-y-96 {
  --tw-translate-y: -9.6rem
}

.-translate-y-128 {
  --tw-translate-y: -12.8rem
}

.-translate-y-136 {
  --tw-translate-y: -13.6rem
}

.-translate-y-160 {
  --tw-translate-y: -16rem
}

.-translate-y-192 {
  --tw-translate-y: -19.2rem
}

.-translate-y-200 {
  --tw-translate-y: -20rem
}

.-translate-y-208 {
  --tw-translate-y: -20.8rem
}

.-translate-y-216 {
  --tw-translate-y: -21.6rem
}

.-translate-y-224 {
  --tw-translate-y: -22.4rem
}

.-translate-y-256 {
  --tw-translate-y: -25.6rem
}

.-translate-y-288 {
  --tw-translate-y: -28.8rem
}

.-translate-y-320 {
  --tw-translate-y: -32rem
}

.-translate-y-360 {
  --tw-translate-y: -36rem
}

.-translate-y-384 {
  --tw-translate-y: -38.4rem
}

.-translate-y-400 {
  --tw-translate-y: -40rem
}

.-translate-y-512 {
  --tw-translate-y: -51.2rem
}

.-translate-y-640 {
  --tw-translate-y: -64rem
}

.-translate-y-xs {
  --tw-translate-y: -32rem
}

.-translate-y-sm {
  --tw-translate-y: -48rem
}

.-translate-y-md {
  --tw-translate-y: -64rem
}

.-translate-y-lg {
  --tw-translate-y: -80rem
}

.-translate-y-xl {
  --tw-translate-y: -96rem
}

.-translate-y-2xl {
  --tw-translate-y: -112rem
}

.-translate-y-3xl {
  --tw-translate-y: -128rem
}

.-translate-y-4xl {
  --tw-translate-y: -144rem
}

.-translate-y-5xl {
  --tw-translate-y: -160rem
}

.-translate-y-px {
  --tw-translate-y: -1px
}

.-translate-y-0\.5 {
  --tw-translate-y: -0.05rem
}

.-translate-y-1\.5 {
  --tw-translate-y: -0.15rem
}

.-translate-y-2\.5 {
  --tw-translate-y: -0.25rem
}

.-translate-y-3\.5 {
  --tw-translate-y: -0.35rem
}

.translate-y-1\/2 {
  --tw-translate-y: 50%
}

.translate-y-1\/3 {
  --tw-translate-y: 33.333333%
}

.translate-y-2\/3 {
  --tw-translate-y: 66.666667%
}

.translate-y-1\/4 {
  --tw-translate-y: 25%
}

.translate-y-2\/4 {
  --tw-translate-y: 50%
}

.translate-y-3\/4 {
  --tw-translate-y: 75%
}

.translate-y-full {
  --tw-translate-y: 100%
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%
}

.-translate-y-1\/3 {
  --tw-translate-y: -33.333333%
}

.-translate-y-2\/3 {
  --tw-translate-y: -66.666667%
}

.-translate-y-1\/4 {
  --tw-translate-y: -25%
}

.-translate-y-2\/4 {
  --tw-translate-y: -50%
}

.-translate-y-3\/4 {
  --tw-translate-y: -75%
}

.-translate-y-full {
  --tw-translate-y: -100%
}

.hover\:translate-x-0:hover {
  --tw-translate-x: 0
}

.hover\:translate-x-1:hover {
  --tw-translate-x: 0.1rem
}

.hover\:translate-x-2:hover {
  --tw-translate-x: 0.2rem
}

.hover\:translate-x-3:hover {
  --tw-translate-x: 0.3rem
}

.hover\:translate-x-4:hover {
  --tw-translate-x: 0.4rem
}

.hover\:translate-x-5:hover {
  --tw-translate-x: 0.5rem
}

.hover\:translate-x-6:hover {
  --tw-translate-x: 0.6rem
}

.hover\:translate-x-7:hover {
  --tw-translate-x: 0.7rem
}

.hover\:translate-x-8:hover {
  --tw-translate-x: 0.8rem
}

.hover\:translate-x-9:hover {
  --tw-translate-x: 0.9rem
}

.hover\:translate-x-10:hover {
  --tw-translate-x: 1.0rem
}

.hover\:translate-x-12:hover {
  --tw-translate-x: 1.2rem
}

.hover\:translate-x-14:hover {
  --tw-translate-x: 1.4rem
}

.hover\:translate-x-16:hover {
  --tw-translate-x: 1.6rem
}

.hover\:translate-x-20:hover {
  --tw-translate-x: 2rem
}

.hover\:translate-x-24:hover {
  --tw-translate-x: 2.4rem
}

.hover\:translate-x-28:hover {
  --tw-translate-x: 2.8rem
}

.hover\:translate-x-32:hover {
  --tw-translate-x: 3.2rem
}

.hover\:translate-x-36:hover {
  --tw-translate-x: 3.6rem
}

.hover\:translate-x-40:hover {
  --tw-translate-x: 4rem
}

.hover\:translate-x-44:hover {
  --tw-translate-x: 4.4rem
}

.hover\:translate-x-48:hover {
  --tw-translate-x: 4.8rem
}

.hover\:translate-x-52:hover {
  --tw-translate-x: 5.2rem
}

.hover\:translate-x-56:hover {
  --tw-translate-x: 5.6rem
}

.hover\:translate-x-60:hover {
  --tw-translate-x: 6rem
}

.hover\:translate-x-64:hover {
  --tw-translate-x: 6.4rem
}

.hover\:translate-x-68:hover {
  --tw-translate-x: 6.8rem
}

.hover\:translate-x-72:hover {
  --tw-translate-x: 7.2rem
}

.hover\:translate-x-76:hover {
  --tw-translate-x: 7.6rem
}

.hover\:translate-x-80:hover {
  --tw-translate-x: 8rem
}

.hover\:translate-x-84:hover {
  --tw-translate-x: 8.4rem
}

.hover\:translate-x-88:hover {
  --tw-translate-x: 8.8rem
}

.hover\:translate-x-92:hover {
  --tw-translate-x: 9.2rem
}

.hover\:translate-x-96:hover {
  --tw-translate-x: 9.6rem
}

.hover\:translate-x-128:hover {
  --tw-translate-x: 12.8rem
}

.hover\:translate-x-136:hover {
  --tw-translate-x: 13.6rem
}

.hover\:translate-x-160:hover {
  --tw-translate-x: 16rem
}

.hover\:translate-x-192:hover {
  --tw-translate-x: 19.2rem
}

.hover\:translate-x-200:hover {
  --tw-translate-x: 20rem
}

.hover\:translate-x-208:hover {
  --tw-translate-x: 20.8rem
}

.hover\:translate-x-216:hover {
  --tw-translate-x: 21.6rem
}

.hover\:translate-x-224:hover {
  --tw-translate-x: 22.4rem
}

.hover\:translate-x-256:hover {
  --tw-translate-x: 25.6rem
}

.hover\:translate-x-288:hover {
  --tw-translate-x: 28.8rem
}

.hover\:translate-x-320:hover {
  --tw-translate-x: 32rem
}

.hover\:translate-x-360:hover {
  --tw-translate-x: 36rem
}

.hover\:translate-x-384:hover {
  --tw-translate-x: 38.4rem
}

.hover\:translate-x-400:hover {
  --tw-translate-x: 40rem
}

.hover\:translate-x-512:hover {
  --tw-translate-x: 51.2rem
}

.hover\:translate-x-640:hover {
  --tw-translate-x: 64rem
}

.hover\:translate-x-xs:hover {
  --tw-translate-x: 32rem
}

.hover\:translate-x-sm:hover {
  --tw-translate-x: 48rem
}

.hover\:translate-x-md:hover {
  --tw-translate-x: 64rem
}

.hover\:translate-x-lg:hover {
  --tw-translate-x: 80rem
}

.hover\:translate-x-xl:hover {
  --tw-translate-x: 96rem
}

.hover\:translate-x-2xl:hover {
  --tw-translate-x: 112rem
}

.hover\:translate-x-3xl:hover {
  --tw-translate-x: 128rem
}

.hover\:translate-x-4xl:hover {
  --tw-translate-x: 144rem
}

.hover\:translate-x-5xl:hover {
  --tw-translate-x: 160rem
}

.hover\:translate-x-px:hover {
  --tw-translate-x: 1px
}

.hover\:translate-x-0\.5:hover {
  --tw-translate-x: 0.05rem
}

.hover\:translate-x-1\.5:hover {
  --tw-translate-x: 0.15rem
}

.hover\:translate-x-2\.5:hover {
  --tw-translate-x: 0.25rem
}

.hover\:translate-x-3\.5:hover {
  --tw-translate-x: 0.35rem
}

.hover\:-translate-x-1:hover {
  --tw-translate-x: -0.1rem
}

.hover\:-translate-x-2:hover {
  --tw-translate-x: -0.2rem
}

.hover\:-translate-x-3:hover {
  --tw-translate-x: -0.3rem
}

.hover\:-translate-x-4:hover {
  --tw-translate-x: -0.4rem
}

.hover\:-translate-x-5:hover {
  --tw-translate-x: -0.5rem
}

.hover\:-translate-x-6:hover {
  --tw-translate-x: -0.6rem
}

.hover\:-translate-x-7:hover {
  --tw-translate-x: -0.7rem
}

.hover\:-translate-x-8:hover {
  --tw-translate-x: -0.8rem
}

.hover\:-translate-x-9:hover {
  --tw-translate-x: -0.9rem
}

.hover\:-translate-x-10:hover {
  --tw-translate-x: -1rem
}

.hover\:-translate-x-12:hover {
  --tw-translate-x: -1.2rem
}

.hover\:-translate-x-14:hover {
  --tw-translate-x: -1.4rem
}

.hover\:-translate-x-16:hover {
  --tw-translate-x: -1.6rem
}

.hover\:-translate-x-20:hover {
  --tw-translate-x: -2rem
}

.hover\:-translate-x-24:hover {
  --tw-translate-x: -2.4rem
}

.hover\:-translate-x-28:hover {
  --tw-translate-x: -2.8rem
}

.hover\:-translate-x-32:hover {
  --tw-translate-x: -3.2rem
}

.hover\:-translate-x-36:hover {
  --tw-translate-x: -3.6rem
}

.hover\:-translate-x-40:hover {
  --tw-translate-x: -4rem
}

.hover\:-translate-x-44:hover {
  --tw-translate-x: -4.4rem
}

.hover\:-translate-x-48:hover {
  --tw-translate-x: -4.8rem
}

.hover\:-translate-x-52:hover {
  --tw-translate-x: -5.2rem
}

.hover\:-translate-x-56:hover {
  --tw-translate-x: -5.6rem
}

.hover\:-translate-x-60:hover {
  --tw-translate-x: -6rem
}

.hover\:-translate-x-64:hover {
  --tw-translate-x: -6.4rem
}

.hover\:-translate-x-68:hover {
  --tw-translate-x: -6.8rem
}

.hover\:-translate-x-72:hover {
  --tw-translate-x: -7.2rem
}

.hover\:-translate-x-76:hover {
  --tw-translate-x: -7.6rem
}

.hover\:-translate-x-80:hover {
  --tw-translate-x: -8rem
}

.hover\:-translate-x-84:hover {
  --tw-translate-x: -8.4rem
}

.hover\:-translate-x-88:hover {
  --tw-translate-x: -8.8rem
}

.hover\:-translate-x-92:hover {
  --tw-translate-x: -9.2rem
}

.hover\:-translate-x-96:hover {
  --tw-translate-x: -9.6rem
}

.hover\:-translate-x-128:hover {
  --tw-translate-x: -12.8rem
}

.hover\:-translate-x-136:hover {
  --tw-translate-x: -13.6rem
}

.hover\:-translate-x-160:hover {
  --tw-translate-x: -16rem
}

.hover\:-translate-x-192:hover {
  --tw-translate-x: -19.2rem
}

.hover\:-translate-x-200:hover {
  --tw-translate-x: -20rem
}

.hover\:-translate-x-208:hover {
  --tw-translate-x: -20.8rem
}

.hover\:-translate-x-216:hover {
  --tw-translate-x: -21.6rem
}

.hover\:-translate-x-224:hover {
  --tw-translate-x: -22.4rem
}

.hover\:-translate-x-256:hover {
  --tw-translate-x: -25.6rem
}

.hover\:-translate-x-288:hover {
  --tw-translate-x: -28.8rem
}

.hover\:-translate-x-320:hover {
  --tw-translate-x: -32rem
}

.hover\:-translate-x-360:hover {
  --tw-translate-x: -36rem
}

.hover\:-translate-x-384:hover {
  --tw-translate-x: -38.4rem
}

.hover\:-translate-x-400:hover {
  --tw-translate-x: -40rem
}

.hover\:-translate-x-512:hover {
  --tw-translate-x: -51.2rem
}

.hover\:-translate-x-640:hover {
  --tw-translate-x: -64rem
}

.hover\:-translate-x-xs:hover {
  --tw-translate-x: -32rem
}

.hover\:-translate-x-sm:hover {
  --tw-translate-x: -48rem
}

.hover\:-translate-x-md:hover {
  --tw-translate-x: -64rem
}

.hover\:-translate-x-lg:hover {
  --tw-translate-x: -80rem
}

.hover\:-translate-x-xl:hover {
  --tw-translate-x: -96rem
}

.hover\:-translate-x-2xl:hover {
  --tw-translate-x: -112rem
}

.hover\:-translate-x-3xl:hover {
  --tw-translate-x: -128rem
}

.hover\:-translate-x-4xl:hover {
  --tw-translate-x: -144rem
}

.hover\:-translate-x-5xl:hover {
  --tw-translate-x: -160rem
}

.hover\:-translate-x-px:hover {
  --tw-translate-x: -1px
}

.hover\:-translate-x-0\.5:hover {
  --tw-translate-x: -0.05rem
}

.hover\:-translate-x-1\.5:hover {
  --tw-translate-x: -0.15rem
}

.hover\:-translate-x-2\.5:hover {
  --tw-translate-x: -0.25rem
}

.hover\:-translate-x-3\.5:hover {
  --tw-translate-x: -0.35rem
}

.hover\:translate-x-1\/2:hover {
  --tw-translate-x: 50%
}

.hover\:translate-x-1\/3:hover {
  --tw-translate-x: 33.333333%
}

.hover\:translate-x-2\/3:hover {
  --tw-translate-x: 66.666667%
}

.hover\:translate-x-1\/4:hover {
  --tw-translate-x: 25%
}

.hover\:translate-x-2\/4:hover {
  --tw-translate-x: 50%
}

.hover\:translate-x-3\/4:hover {
  --tw-translate-x: 75%
}

.hover\:translate-x-full:hover {
  --tw-translate-x: 100%
}

.hover\:-translate-x-1\/2:hover {
  --tw-translate-x: -50%
}

.hover\:-translate-x-1\/3:hover {
  --tw-translate-x: -33.333333%
}

.hover\:-translate-x-2\/3:hover {
  --tw-translate-x: -66.666667%
}

.hover\:-translate-x-1\/4:hover {
  --tw-translate-x: -25%
}

.hover\:-translate-x-2\/4:hover {
  --tw-translate-x: -50%
}

.hover\:-translate-x-3\/4:hover {
  --tw-translate-x: -75%
}

.hover\:-translate-x-full:hover {
  --tw-translate-x: -100%
}

.hover\:translate-y-0:hover {
  --tw-translate-y: 0
}

.hover\:translate-y-1:hover {
  --tw-translate-y: 0.1rem
}

.hover\:translate-y-2:hover {
  --tw-translate-y: 0.2rem
}

.hover\:translate-y-3:hover {
  --tw-translate-y: 0.3rem
}

.hover\:translate-y-4:hover {
  --tw-translate-y: 0.4rem
}

.hover\:translate-y-5:hover {
  --tw-translate-y: 0.5rem
}

.hover\:translate-y-6:hover {
  --tw-translate-y: 0.6rem
}

.hover\:translate-y-7:hover {
  --tw-translate-y: 0.7rem
}

.hover\:translate-y-8:hover {
  --tw-translate-y: 0.8rem
}

.hover\:translate-y-9:hover {
  --tw-translate-y: 0.9rem
}

.hover\:translate-y-10:hover {
  --tw-translate-y: 1.0rem
}

.hover\:translate-y-12:hover {
  --tw-translate-y: 1.2rem
}

.hover\:translate-y-14:hover {
  --tw-translate-y: 1.4rem
}

.hover\:translate-y-16:hover {
  --tw-translate-y: 1.6rem
}

.hover\:translate-y-20:hover {
  --tw-translate-y: 2rem
}

.hover\:translate-y-24:hover {
  --tw-translate-y: 2.4rem
}

.hover\:translate-y-28:hover {
  --tw-translate-y: 2.8rem
}

.hover\:translate-y-32:hover {
  --tw-translate-y: 3.2rem
}

.hover\:translate-y-36:hover {
  --tw-translate-y: 3.6rem
}

.hover\:translate-y-40:hover {
  --tw-translate-y: 4rem
}

.hover\:translate-y-44:hover {
  --tw-translate-y: 4.4rem
}

.hover\:translate-y-48:hover {
  --tw-translate-y: 4.8rem
}

.hover\:translate-y-52:hover {
  --tw-translate-y: 5.2rem
}

.hover\:translate-y-56:hover {
  --tw-translate-y: 5.6rem
}

.hover\:translate-y-60:hover {
  --tw-translate-y: 6rem
}

.hover\:translate-y-64:hover {
  --tw-translate-y: 6.4rem
}

.hover\:translate-y-68:hover {
  --tw-translate-y: 6.8rem
}

.hover\:translate-y-72:hover {
  --tw-translate-y: 7.2rem
}

.hover\:translate-y-76:hover {
  --tw-translate-y: 7.6rem
}

.hover\:translate-y-80:hover {
  --tw-translate-y: 8rem
}

.hover\:translate-y-84:hover {
  --tw-translate-y: 8.4rem
}

.hover\:translate-y-88:hover {
  --tw-translate-y: 8.8rem
}

.hover\:translate-y-92:hover {
  --tw-translate-y: 9.2rem
}

.hover\:translate-y-96:hover {
  --tw-translate-y: 9.6rem
}

.hover\:translate-y-128:hover {
  --tw-translate-y: 12.8rem
}

.hover\:translate-y-136:hover {
  --tw-translate-y: 13.6rem
}

.hover\:translate-y-160:hover {
  --tw-translate-y: 16rem
}

.hover\:translate-y-192:hover {
  --tw-translate-y: 19.2rem
}

.hover\:translate-y-200:hover {
  --tw-translate-y: 20rem
}

.hover\:translate-y-208:hover {
  --tw-translate-y: 20.8rem
}

.hover\:translate-y-216:hover {
  --tw-translate-y: 21.6rem
}

.hover\:translate-y-224:hover {
  --tw-translate-y: 22.4rem
}

.hover\:translate-y-256:hover {
  --tw-translate-y: 25.6rem
}

.hover\:translate-y-288:hover {
  --tw-translate-y: 28.8rem
}

.hover\:translate-y-320:hover {
  --tw-translate-y: 32rem
}

.hover\:translate-y-360:hover {
  --tw-translate-y: 36rem
}

.hover\:translate-y-384:hover {
  --tw-translate-y: 38.4rem
}

.hover\:translate-y-400:hover {
  --tw-translate-y: 40rem
}

.hover\:translate-y-512:hover {
  --tw-translate-y: 51.2rem
}

.hover\:translate-y-640:hover {
  --tw-translate-y: 64rem
}

.hover\:translate-y-xs:hover {
  --tw-translate-y: 32rem
}

.hover\:translate-y-sm:hover {
  --tw-translate-y: 48rem
}

.hover\:translate-y-md:hover {
  --tw-translate-y: 64rem
}

.hover\:translate-y-lg:hover {
  --tw-translate-y: 80rem
}

.hover\:translate-y-xl:hover {
  --tw-translate-y: 96rem
}

.hover\:translate-y-2xl:hover {
  --tw-translate-y: 112rem
}

.hover\:translate-y-3xl:hover {
  --tw-translate-y: 128rem
}

.hover\:translate-y-4xl:hover {
  --tw-translate-y: 144rem
}

.hover\:translate-y-5xl:hover {
  --tw-translate-y: 160rem
}

.hover\:translate-y-px:hover {
  --tw-translate-y: 1px
}

.hover\:translate-y-0\.5:hover {
  --tw-translate-y: 0.05rem
}

.hover\:translate-y-1\.5:hover {
  --tw-translate-y: 0.15rem
}

.hover\:translate-y-2\.5:hover {
  --tw-translate-y: 0.25rem
}

.hover\:translate-y-3\.5:hover {
  --tw-translate-y: 0.35rem
}

.hover\:-translate-y-1:hover {
  --tw-translate-y: -0.1rem
}

.hover\:-translate-y-2:hover {
  --tw-translate-y: -0.2rem
}

.hover\:-translate-y-3:hover {
  --tw-translate-y: -0.3rem
}

.hover\:-translate-y-4:hover {
  --tw-translate-y: -0.4rem
}

.hover\:-translate-y-5:hover {
  --tw-translate-y: -0.5rem
}

.hover\:-translate-y-6:hover {
  --tw-translate-y: -0.6rem
}

.hover\:-translate-y-7:hover {
  --tw-translate-y: -0.7rem
}

.hover\:-translate-y-8:hover {
  --tw-translate-y: -0.8rem
}

.hover\:-translate-y-9:hover {
  --tw-translate-y: -0.9rem
}

.hover\:-translate-y-10:hover {
  --tw-translate-y: -1rem
}

.hover\:-translate-y-12:hover {
  --tw-translate-y: -1.2rem
}

.hover\:-translate-y-14:hover {
  --tw-translate-y: -1.4rem
}

.hover\:-translate-y-16:hover {
  --tw-translate-y: -1.6rem
}

.hover\:-translate-y-20:hover {
  --tw-translate-y: -2rem
}

.hover\:-translate-y-24:hover {
  --tw-translate-y: -2.4rem
}

.hover\:-translate-y-28:hover {
  --tw-translate-y: -2.8rem
}

.hover\:-translate-y-32:hover {
  --tw-translate-y: -3.2rem
}

.hover\:-translate-y-36:hover {
  --tw-translate-y: -3.6rem
}

.hover\:-translate-y-40:hover {
  --tw-translate-y: -4rem
}

.hover\:-translate-y-44:hover {
  --tw-translate-y: -4.4rem
}

.hover\:-translate-y-48:hover {
  --tw-translate-y: -4.8rem
}

.hover\:-translate-y-52:hover {
  --tw-translate-y: -5.2rem
}

.hover\:-translate-y-56:hover {
  --tw-translate-y: -5.6rem
}

.hover\:-translate-y-60:hover {
  --tw-translate-y: -6rem
}

.hover\:-translate-y-64:hover {
  --tw-translate-y: -6.4rem
}

.hover\:-translate-y-68:hover {
  --tw-translate-y: -6.8rem
}

.hover\:-translate-y-72:hover {
  --tw-translate-y: -7.2rem
}

.hover\:-translate-y-76:hover {
  --tw-translate-y: -7.6rem
}

.hover\:-translate-y-80:hover {
  --tw-translate-y: -8rem
}

.hover\:-translate-y-84:hover {
  --tw-translate-y: -8.4rem
}

.hover\:-translate-y-88:hover {
  --tw-translate-y: -8.8rem
}

.hover\:-translate-y-92:hover {
  --tw-translate-y: -9.2rem
}

.hover\:-translate-y-96:hover {
  --tw-translate-y: -9.6rem
}

.hover\:-translate-y-128:hover {
  --tw-translate-y: -12.8rem
}

.hover\:-translate-y-136:hover {
  --tw-translate-y: -13.6rem
}

.hover\:-translate-y-160:hover {
  --tw-translate-y: -16rem
}

.hover\:-translate-y-192:hover {
  --tw-translate-y: -19.2rem
}

.hover\:-translate-y-200:hover {
  --tw-translate-y: -20rem
}

.hover\:-translate-y-208:hover {
  --tw-translate-y: -20.8rem
}

.hover\:-translate-y-216:hover {
  --tw-translate-y: -21.6rem
}

.hover\:-translate-y-224:hover {
  --tw-translate-y: -22.4rem
}

.hover\:-translate-y-256:hover {
  --tw-translate-y: -25.6rem
}

.hover\:-translate-y-288:hover {
  --tw-translate-y: -28.8rem
}

.hover\:-translate-y-320:hover {
  --tw-translate-y: -32rem
}

.hover\:-translate-y-360:hover {
  --tw-translate-y: -36rem
}

.hover\:-translate-y-384:hover {
  --tw-translate-y: -38.4rem
}

.hover\:-translate-y-400:hover {
  --tw-translate-y: -40rem
}

.hover\:-translate-y-512:hover {
  --tw-translate-y: -51.2rem
}

.hover\:-translate-y-640:hover {
  --tw-translate-y: -64rem
}

.hover\:-translate-y-xs:hover {
  --tw-translate-y: -32rem
}

.hover\:-translate-y-sm:hover {
  --tw-translate-y: -48rem
}

.hover\:-translate-y-md:hover {
  --tw-translate-y: -64rem
}

.hover\:-translate-y-lg:hover {
  --tw-translate-y: -80rem
}

.hover\:-translate-y-xl:hover {
  --tw-translate-y: -96rem
}

.hover\:-translate-y-2xl:hover {
  --tw-translate-y: -112rem
}

.hover\:-translate-y-3xl:hover {
  --tw-translate-y: -128rem
}

.hover\:-translate-y-4xl:hover {
  --tw-translate-y: -144rem
}

.hover\:-translate-y-5xl:hover {
  --tw-translate-y: -160rem
}

.hover\:-translate-y-px:hover {
  --tw-translate-y: -1px
}

.hover\:-translate-y-0\.5:hover {
  --tw-translate-y: -0.05rem
}

.hover\:-translate-y-1\.5:hover {
  --tw-translate-y: -0.15rem
}

.hover\:-translate-y-2\.5:hover {
  --tw-translate-y: -0.25rem
}

.hover\:-translate-y-3\.5:hover {
  --tw-translate-y: -0.35rem
}

.hover\:translate-y-1\/2:hover {
  --tw-translate-y: 50%
}

.hover\:translate-y-1\/3:hover {
  --tw-translate-y: 33.333333%
}

.hover\:translate-y-2\/3:hover {
  --tw-translate-y: 66.666667%
}

.hover\:translate-y-1\/4:hover {
  --tw-translate-y: 25%
}

.hover\:translate-y-2\/4:hover {
  --tw-translate-y: 50%
}

.hover\:translate-y-3\/4:hover {
  --tw-translate-y: 75%
}

.hover\:translate-y-full:hover {
  --tw-translate-y: 100%
}

.hover\:-translate-y-1\/2:hover {
  --tw-translate-y: -50%
}

.hover\:-translate-y-1\/3:hover {
  --tw-translate-y: -33.333333%
}

.hover\:-translate-y-2\/3:hover {
  --tw-translate-y: -66.666667%
}

.hover\:-translate-y-1\/4:hover {
  --tw-translate-y: -25%
}

.hover\:-translate-y-2\/4:hover {
  --tw-translate-y: -50%
}

.hover\:-translate-y-3\/4:hover {
  --tw-translate-y: -75%
}

.hover\:-translate-y-full:hover {
  --tw-translate-y: -100%
}

.focus\:translate-x-0:focus {
  --tw-translate-x: 0
}

.focus\:translate-x-1:focus {
  --tw-translate-x: 0.1rem
}

.focus\:translate-x-2:focus {
  --tw-translate-x: 0.2rem
}

.focus\:translate-x-3:focus {
  --tw-translate-x: 0.3rem
}

.focus\:translate-x-4:focus {
  --tw-translate-x: 0.4rem
}

.focus\:translate-x-5:focus {
  --tw-translate-x: 0.5rem
}

.focus\:translate-x-6:focus {
  --tw-translate-x: 0.6rem
}

.focus\:translate-x-7:focus {
  --tw-translate-x: 0.7rem
}

.focus\:translate-x-8:focus {
  --tw-translate-x: 0.8rem
}

.focus\:translate-x-9:focus {
  --tw-translate-x: 0.9rem
}

.focus\:translate-x-10:focus {
  --tw-translate-x: 1.0rem
}

.focus\:translate-x-12:focus {
  --tw-translate-x: 1.2rem
}

.focus\:translate-x-14:focus {
  --tw-translate-x: 1.4rem
}

.focus\:translate-x-16:focus {
  --tw-translate-x: 1.6rem
}

.focus\:translate-x-20:focus {
  --tw-translate-x: 2rem
}

.focus\:translate-x-24:focus {
  --tw-translate-x: 2.4rem
}

.focus\:translate-x-28:focus {
  --tw-translate-x: 2.8rem
}

.focus\:translate-x-32:focus {
  --tw-translate-x: 3.2rem
}

.focus\:translate-x-36:focus {
  --tw-translate-x: 3.6rem
}

.focus\:translate-x-40:focus {
  --tw-translate-x: 4rem
}

.focus\:translate-x-44:focus {
  --tw-translate-x: 4.4rem
}

.focus\:translate-x-48:focus {
  --tw-translate-x: 4.8rem
}

.focus\:translate-x-52:focus {
  --tw-translate-x: 5.2rem
}

.focus\:translate-x-56:focus {
  --tw-translate-x: 5.6rem
}

.focus\:translate-x-60:focus {
  --tw-translate-x: 6rem
}

.focus\:translate-x-64:focus {
  --tw-translate-x: 6.4rem
}

.focus\:translate-x-68:focus {
  --tw-translate-x: 6.8rem
}

.focus\:translate-x-72:focus {
  --tw-translate-x: 7.2rem
}

.focus\:translate-x-76:focus {
  --tw-translate-x: 7.6rem
}

.focus\:translate-x-80:focus {
  --tw-translate-x: 8rem
}

.focus\:translate-x-84:focus {
  --tw-translate-x: 8.4rem
}

.focus\:translate-x-88:focus {
  --tw-translate-x: 8.8rem
}

.focus\:translate-x-92:focus {
  --tw-translate-x: 9.2rem
}

.focus\:translate-x-96:focus {
  --tw-translate-x: 9.6rem
}

.focus\:translate-x-128:focus {
  --tw-translate-x: 12.8rem
}

.focus\:translate-x-136:focus {
  --tw-translate-x: 13.6rem
}

.focus\:translate-x-160:focus {
  --tw-translate-x: 16rem
}

.focus\:translate-x-192:focus {
  --tw-translate-x: 19.2rem
}

.focus\:translate-x-200:focus {
  --tw-translate-x: 20rem
}

.focus\:translate-x-208:focus {
  --tw-translate-x: 20.8rem
}

.focus\:translate-x-216:focus {
  --tw-translate-x: 21.6rem
}

.focus\:translate-x-224:focus {
  --tw-translate-x: 22.4rem
}

.focus\:translate-x-256:focus {
  --tw-translate-x: 25.6rem
}

.focus\:translate-x-288:focus {
  --tw-translate-x: 28.8rem
}

.focus\:translate-x-320:focus {
  --tw-translate-x: 32rem
}

.focus\:translate-x-360:focus {
  --tw-translate-x: 36rem
}

.focus\:translate-x-384:focus {
  --tw-translate-x: 38.4rem
}

.focus\:translate-x-400:focus {
  --tw-translate-x: 40rem
}

.focus\:translate-x-512:focus {
  --tw-translate-x: 51.2rem
}

.focus\:translate-x-640:focus {
  --tw-translate-x: 64rem
}

.focus\:translate-x-xs:focus {
  --tw-translate-x: 32rem
}

.focus\:translate-x-sm:focus {
  --tw-translate-x: 48rem
}

.focus\:translate-x-md:focus {
  --tw-translate-x: 64rem
}

.focus\:translate-x-lg:focus {
  --tw-translate-x: 80rem
}

.focus\:translate-x-xl:focus {
  --tw-translate-x: 96rem
}

.focus\:translate-x-2xl:focus {
  --tw-translate-x: 112rem
}

.focus\:translate-x-3xl:focus {
  --tw-translate-x: 128rem
}

.focus\:translate-x-4xl:focus {
  --tw-translate-x: 144rem
}

.focus\:translate-x-5xl:focus {
  --tw-translate-x: 160rem
}

.focus\:translate-x-px:focus {
  --tw-translate-x: 1px
}

.focus\:translate-x-0\.5:focus {
  --tw-translate-x: 0.05rem
}

.focus\:translate-x-1\.5:focus {
  --tw-translate-x: 0.15rem
}

.focus\:translate-x-2\.5:focus {
  --tw-translate-x: 0.25rem
}

.focus\:translate-x-3\.5:focus {
  --tw-translate-x: 0.35rem
}

.focus\:-translate-x-1:focus {
  --tw-translate-x: -0.1rem
}

.focus\:-translate-x-2:focus {
  --tw-translate-x: -0.2rem
}

.focus\:-translate-x-3:focus {
  --tw-translate-x: -0.3rem
}

.focus\:-translate-x-4:focus {
  --tw-translate-x: -0.4rem
}

.focus\:-translate-x-5:focus {
  --tw-translate-x: -0.5rem
}

.focus\:-translate-x-6:focus {
  --tw-translate-x: -0.6rem
}

.focus\:-translate-x-7:focus {
  --tw-translate-x: -0.7rem
}

.focus\:-translate-x-8:focus {
  --tw-translate-x: -0.8rem
}

.focus\:-translate-x-9:focus {
  --tw-translate-x: -0.9rem
}

.focus\:-translate-x-10:focus {
  --tw-translate-x: -1rem
}

.focus\:-translate-x-12:focus {
  --tw-translate-x: -1.2rem
}

.focus\:-translate-x-14:focus {
  --tw-translate-x: -1.4rem
}

.focus\:-translate-x-16:focus {
  --tw-translate-x: -1.6rem
}

.focus\:-translate-x-20:focus {
  --tw-translate-x: -2rem
}

.focus\:-translate-x-24:focus {
  --tw-translate-x: -2.4rem
}

.focus\:-translate-x-28:focus {
  --tw-translate-x: -2.8rem
}

.focus\:-translate-x-32:focus {
  --tw-translate-x: -3.2rem
}

.focus\:-translate-x-36:focus {
  --tw-translate-x: -3.6rem
}

.focus\:-translate-x-40:focus {
  --tw-translate-x: -4rem
}

.focus\:-translate-x-44:focus {
  --tw-translate-x: -4.4rem
}

.focus\:-translate-x-48:focus {
  --tw-translate-x: -4.8rem
}

.focus\:-translate-x-52:focus {
  --tw-translate-x: -5.2rem
}

.focus\:-translate-x-56:focus {
  --tw-translate-x: -5.6rem
}

.focus\:-translate-x-60:focus {
  --tw-translate-x: -6rem
}

.focus\:-translate-x-64:focus {
  --tw-translate-x: -6.4rem
}

.focus\:-translate-x-68:focus {
  --tw-translate-x: -6.8rem
}

.focus\:-translate-x-72:focus {
  --tw-translate-x: -7.2rem
}

.focus\:-translate-x-76:focus {
  --tw-translate-x: -7.6rem
}

.focus\:-translate-x-80:focus {
  --tw-translate-x: -8rem
}

.focus\:-translate-x-84:focus {
  --tw-translate-x: -8.4rem
}

.focus\:-translate-x-88:focus {
  --tw-translate-x: -8.8rem
}

.focus\:-translate-x-92:focus {
  --tw-translate-x: -9.2rem
}

.focus\:-translate-x-96:focus {
  --tw-translate-x: -9.6rem
}

.focus\:-translate-x-128:focus {
  --tw-translate-x: -12.8rem
}

.focus\:-translate-x-136:focus {
  --tw-translate-x: -13.6rem
}

.focus\:-translate-x-160:focus {
  --tw-translate-x: -16rem
}

.focus\:-translate-x-192:focus {
  --tw-translate-x: -19.2rem
}

.focus\:-translate-x-200:focus {
  --tw-translate-x: -20rem
}

.focus\:-translate-x-208:focus {
  --tw-translate-x: -20.8rem
}

.focus\:-translate-x-216:focus {
  --tw-translate-x: -21.6rem
}

.focus\:-translate-x-224:focus {
  --tw-translate-x: -22.4rem
}

.focus\:-translate-x-256:focus {
  --tw-translate-x: -25.6rem
}

.focus\:-translate-x-288:focus {
  --tw-translate-x: -28.8rem
}

.focus\:-translate-x-320:focus {
  --tw-translate-x: -32rem
}

.focus\:-translate-x-360:focus {
  --tw-translate-x: -36rem
}

.focus\:-translate-x-384:focus {
  --tw-translate-x: -38.4rem
}

.focus\:-translate-x-400:focus {
  --tw-translate-x: -40rem
}

.focus\:-translate-x-512:focus {
  --tw-translate-x: -51.2rem
}

.focus\:-translate-x-640:focus {
  --tw-translate-x: -64rem
}

.focus\:-translate-x-xs:focus {
  --tw-translate-x: -32rem
}

.focus\:-translate-x-sm:focus {
  --tw-translate-x: -48rem
}

.focus\:-translate-x-md:focus {
  --tw-translate-x: -64rem
}

.focus\:-translate-x-lg:focus {
  --tw-translate-x: -80rem
}

.focus\:-translate-x-xl:focus {
  --tw-translate-x: -96rem
}

.focus\:-translate-x-2xl:focus {
  --tw-translate-x: -112rem
}

.focus\:-translate-x-3xl:focus {
  --tw-translate-x: -128rem
}

.focus\:-translate-x-4xl:focus {
  --tw-translate-x: -144rem
}

.focus\:-translate-x-5xl:focus {
  --tw-translate-x: -160rem
}

.focus\:-translate-x-px:focus {
  --tw-translate-x: -1px
}

.focus\:-translate-x-0\.5:focus {
  --tw-translate-x: -0.05rem
}

.focus\:-translate-x-1\.5:focus {
  --tw-translate-x: -0.15rem
}

.focus\:-translate-x-2\.5:focus {
  --tw-translate-x: -0.25rem
}

.focus\:-translate-x-3\.5:focus {
  --tw-translate-x: -0.35rem
}

.focus\:translate-x-1\/2:focus {
  --tw-translate-x: 50%
}

.focus\:translate-x-1\/3:focus {
  --tw-translate-x: 33.333333%
}

.focus\:translate-x-2\/3:focus {
  --tw-translate-x: 66.666667%
}

.focus\:translate-x-1\/4:focus {
  --tw-translate-x: 25%
}

.focus\:translate-x-2\/4:focus {
  --tw-translate-x: 50%
}

.focus\:translate-x-3\/4:focus {
  --tw-translate-x: 75%
}

.focus\:translate-x-full:focus {
  --tw-translate-x: 100%
}

.focus\:-translate-x-1\/2:focus {
  --tw-translate-x: -50%
}

.focus\:-translate-x-1\/3:focus {
  --tw-translate-x: -33.333333%
}

.focus\:-translate-x-2\/3:focus {
  --tw-translate-x: -66.666667%
}

.focus\:-translate-x-1\/4:focus {
  --tw-translate-x: -25%
}

.focus\:-translate-x-2\/4:focus {
  --tw-translate-x: -50%
}

.focus\:-translate-x-3\/4:focus {
  --tw-translate-x: -75%
}

.focus\:-translate-x-full:focus {
  --tw-translate-x: -100%
}

.focus\:translate-y-0:focus {
  --tw-translate-y: 0
}

.focus\:translate-y-1:focus {
  --tw-translate-y: 0.1rem
}

.focus\:translate-y-2:focus {
  --tw-translate-y: 0.2rem
}

.focus\:translate-y-3:focus {
  --tw-translate-y: 0.3rem
}

.focus\:translate-y-4:focus {
  --tw-translate-y: 0.4rem
}

.focus\:translate-y-5:focus {
  --tw-translate-y: 0.5rem
}

.focus\:translate-y-6:focus {
  --tw-translate-y: 0.6rem
}

.focus\:translate-y-7:focus {
  --tw-translate-y: 0.7rem
}

.focus\:translate-y-8:focus {
  --tw-translate-y: 0.8rem
}

.focus\:translate-y-9:focus {
  --tw-translate-y: 0.9rem
}

.focus\:translate-y-10:focus {
  --tw-translate-y: 1.0rem
}

.focus\:translate-y-12:focus {
  --tw-translate-y: 1.2rem
}

.focus\:translate-y-14:focus {
  --tw-translate-y: 1.4rem
}

.focus\:translate-y-16:focus {
  --tw-translate-y: 1.6rem
}

.focus\:translate-y-20:focus {
  --tw-translate-y: 2rem
}

.focus\:translate-y-24:focus {
  --tw-translate-y: 2.4rem
}

.focus\:translate-y-28:focus {
  --tw-translate-y: 2.8rem
}

.focus\:translate-y-32:focus {
  --tw-translate-y: 3.2rem
}

.focus\:translate-y-36:focus {
  --tw-translate-y: 3.6rem
}

.focus\:translate-y-40:focus {
  --tw-translate-y: 4rem
}

.focus\:translate-y-44:focus {
  --tw-translate-y: 4.4rem
}

.focus\:translate-y-48:focus {
  --tw-translate-y: 4.8rem
}

.focus\:translate-y-52:focus {
  --tw-translate-y: 5.2rem
}

.focus\:translate-y-56:focus {
  --tw-translate-y: 5.6rem
}

.focus\:translate-y-60:focus {
  --tw-translate-y: 6rem
}

.focus\:translate-y-64:focus {
  --tw-translate-y: 6.4rem
}

.focus\:translate-y-68:focus {
  --tw-translate-y: 6.8rem
}

.focus\:translate-y-72:focus {
  --tw-translate-y: 7.2rem
}

.focus\:translate-y-76:focus {
  --tw-translate-y: 7.6rem
}

.focus\:translate-y-80:focus {
  --tw-translate-y: 8rem
}

.focus\:translate-y-84:focus {
  --tw-translate-y: 8.4rem
}

.focus\:translate-y-88:focus {
  --tw-translate-y: 8.8rem
}

.focus\:translate-y-92:focus {
  --tw-translate-y: 9.2rem
}

.focus\:translate-y-96:focus {
  --tw-translate-y: 9.6rem
}

.focus\:translate-y-128:focus {
  --tw-translate-y: 12.8rem
}

.focus\:translate-y-136:focus {
  --tw-translate-y: 13.6rem
}

.focus\:translate-y-160:focus {
  --tw-translate-y: 16rem
}

.focus\:translate-y-192:focus {
  --tw-translate-y: 19.2rem
}

.focus\:translate-y-200:focus {
  --tw-translate-y: 20rem
}

.focus\:translate-y-208:focus {
  --tw-translate-y: 20.8rem
}

.focus\:translate-y-216:focus {
  --tw-translate-y: 21.6rem
}

.focus\:translate-y-224:focus {
  --tw-translate-y: 22.4rem
}

.focus\:translate-y-256:focus {
  --tw-translate-y: 25.6rem
}

.focus\:translate-y-288:focus {
  --tw-translate-y: 28.8rem
}

.focus\:translate-y-320:focus {
  --tw-translate-y: 32rem
}

.focus\:translate-y-360:focus {
  --tw-translate-y: 36rem
}

.focus\:translate-y-384:focus {
  --tw-translate-y: 38.4rem
}

.focus\:translate-y-400:focus {
  --tw-translate-y: 40rem
}

.focus\:translate-y-512:focus {
  --tw-translate-y: 51.2rem
}

.focus\:translate-y-640:focus {
  --tw-translate-y: 64rem
}

.focus\:translate-y-xs:focus {
  --tw-translate-y: 32rem
}

.focus\:translate-y-sm:focus {
  --tw-translate-y: 48rem
}

.focus\:translate-y-md:focus {
  --tw-translate-y: 64rem
}

.focus\:translate-y-lg:focus {
  --tw-translate-y: 80rem
}

.focus\:translate-y-xl:focus {
  --tw-translate-y: 96rem
}

.focus\:translate-y-2xl:focus {
  --tw-translate-y: 112rem
}

.focus\:translate-y-3xl:focus {
  --tw-translate-y: 128rem
}

.focus\:translate-y-4xl:focus {
  --tw-translate-y: 144rem
}

.focus\:translate-y-5xl:focus {
  --tw-translate-y: 160rem
}

.focus\:translate-y-px:focus {
  --tw-translate-y: 1px
}

.focus\:translate-y-0\.5:focus {
  --tw-translate-y: 0.05rem
}

.focus\:translate-y-1\.5:focus {
  --tw-translate-y: 0.15rem
}

.focus\:translate-y-2\.5:focus {
  --tw-translate-y: 0.25rem
}

.focus\:translate-y-3\.5:focus {
  --tw-translate-y: 0.35rem
}

.focus\:-translate-y-1:focus {
  --tw-translate-y: -0.1rem
}

.focus\:-translate-y-2:focus {
  --tw-translate-y: -0.2rem
}

.focus\:-translate-y-3:focus {
  --tw-translate-y: -0.3rem
}

.focus\:-translate-y-4:focus {
  --tw-translate-y: -0.4rem
}

.focus\:-translate-y-5:focus {
  --tw-translate-y: -0.5rem
}

.focus\:-translate-y-6:focus {
  --tw-translate-y: -0.6rem
}

.focus\:-translate-y-7:focus {
  --tw-translate-y: -0.7rem
}

.focus\:-translate-y-8:focus {
  --tw-translate-y: -0.8rem
}

.focus\:-translate-y-9:focus {
  --tw-translate-y: -0.9rem
}

.focus\:-translate-y-10:focus {
  --tw-translate-y: -1rem
}

.focus\:-translate-y-12:focus {
  --tw-translate-y: -1.2rem
}

.focus\:-translate-y-14:focus {
  --tw-translate-y: -1.4rem
}

.focus\:-translate-y-16:focus {
  --tw-translate-y: -1.6rem
}

.focus\:-translate-y-20:focus {
  --tw-translate-y: -2rem
}

.focus\:-translate-y-24:focus {
  --tw-translate-y: -2.4rem
}

.focus\:-translate-y-28:focus {
  --tw-translate-y: -2.8rem
}

.focus\:-translate-y-32:focus {
  --tw-translate-y: -3.2rem
}

.focus\:-translate-y-36:focus {
  --tw-translate-y: -3.6rem
}

.focus\:-translate-y-40:focus {
  --tw-translate-y: -4rem
}

.focus\:-translate-y-44:focus {
  --tw-translate-y: -4.4rem
}

.focus\:-translate-y-48:focus {
  --tw-translate-y: -4.8rem
}

.focus\:-translate-y-52:focus {
  --tw-translate-y: -5.2rem
}

.focus\:-translate-y-56:focus {
  --tw-translate-y: -5.6rem
}

.focus\:-translate-y-60:focus {
  --tw-translate-y: -6rem
}

.focus\:-translate-y-64:focus {
  --tw-translate-y: -6.4rem
}

.focus\:-translate-y-68:focus {
  --tw-translate-y: -6.8rem
}

.focus\:-translate-y-72:focus {
  --tw-translate-y: -7.2rem
}

.focus\:-translate-y-76:focus {
  --tw-translate-y: -7.6rem
}

.focus\:-translate-y-80:focus {
  --tw-translate-y: -8rem
}

.focus\:-translate-y-84:focus {
  --tw-translate-y: -8.4rem
}

.focus\:-translate-y-88:focus {
  --tw-translate-y: -8.8rem
}

.focus\:-translate-y-92:focus {
  --tw-translate-y: -9.2rem
}

.focus\:-translate-y-96:focus {
  --tw-translate-y: -9.6rem
}

.focus\:-translate-y-128:focus {
  --tw-translate-y: -12.8rem
}

.focus\:-translate-y-136:focus {
  --tw-translate-y: -13.6rem
}

.focus\:-translate-y-160:focus {
  --tw-translate-y: -16rem
}

.focus\:-translate-y-192:focus {
  --tw-translate-y: -19.2rem
}

.focus\:-translate-y-200:focus {
  --tw-translate-y: -20rem
}

.focus\:-translate-y-208:focus {
  --tw-translate-y: -20.8rem
}

.focus\:-translate-y-216:focus {
  --tw-translate-y: -21.6rem
}

.focus\:-translate-y-224:focus {
  --tw-translate-y: -22.4rem
}

.focus\:-translate-y-256:focus {
  --tw-translate-y: -25.6rem
}

.focus\:-translate-y-288:focus {
  --tw-translate-y: -28.8rem
}

.focus\:-translate-y-320:focus {
  --tw-translate-y: -32rem
}

.focus\:-translate-y-360:focus {
  --tw-translate-y: -36rem
}

.focus\:-translate-y-384:focus {
  --tw-translate-y: -38.4rem
}

.focus\:-translate-y-400:focus {
  --tw-translate-y: -40rem
}

.focus\:-translate-y-512:focus {
  --tw-translate-y: -51.2rem
}

.focus\:-translate-y-640:focus {
  --tw-translate-y: -64rem
}

.focus\:-translate-y-xs:focus {
  --tw-translate-y: -32rem
}

.focus\:-translate-y-sm:focus {
  --tw-translate-y: -48rem
}

.focus\:-translate-y-md:focus {
  --tw-translate-y: -64rem
}

.focus\:-translate-y-lg:focus {
  --tw-translate-y: -80rem
}

.focus\:-translate-y-xl:focus {
  --tw-translate-y: -96rem
}

.focus\:-translate-y-2xl:focus {
  --tw-translate-y: -112rem
}

.focus\:-translate-y-3xl:focus {
  --tw-translate-y: -128rem
}

.focus\:-translate-y-4xl:focus {
  --tw-translate-y: -144rem
}

.focus\:-translate-y-5xl:focus {
  --tw-translate-y: -160rem
}

.focus\:-translate-y-px:focus {
  --tw-translate-y: -1px
}

.focus\:-translate-y-0\.5:focus {
  --tw-translate-y: -0.05rem
}

.focus\:-translate-y-1\.5:focus {
  --tw-translate-y: -0.15rem
}

.focus\:-translate-y-2\.5:focus {
  --tw-translate-y: -0.25rem
}

.focus\:-translate-y-3\.5:focus {
  --tw-translate-y: -0.35rem
}

.focus\:translate-y-1\/2:focus {
  --tw-translate-y: 50%
}

.focus\:translate-y-1\/3:focus {
  --tw-translate-y: 33.333333%
}

.focus\:translate-y-2\/3:focus {
  --tw-translate-y: 66.666667%
}

.focus\:translate-y-1\/4:focus {
  --tw-translate-y: 25%
}

.focus\:translate-y-2\/4:focus {
  --tw-translate-y: 50%
}

.focus\:translate-y-3\/4:focus {
  --tw-translate-y: 75%
}

.focus\:translate-y-full:focus {
  --tw-translate-y: 100%
}

.focus\:-translate-y-1\/2:focus {
  --tw-translate-y: -50%
}

.focus\:-translate-y-1\/3:focus {
  --tw-translate-y: -33.333333%
}

.focus\:-translate-y-2\/3:focus {
  --tw-translate-y: -66.666667%
}

.focus\:-translate-y-1\/4:focus {
  --tw-translate-y: -25%
}

.focus\:-translate-y-2\/4:focus {
  --tw-translate-y: -50%
}

.focus\:-translate-y-3\/4:focus {
  --tw-translate-y: -75%
}

.focus\:-translate-y-full:focus {
  --tw-translate-y: -100%
}

.skew-x-0 {
  --tw-skew-x: 0deg
}

.skew-x-1 {
  --tw-skew-x: 1deg
}

.skew-x-2 {
  --tw-skew-x: 2deg
}

.skew-x-3 {
  --tw-skew-x: 3deg
}

.skew-x-6 {
  --tw-skew-x: 6deg
}

.skew-x-12 {
  --tw-skew-x: 12deg
}

.-skew-x-12 {
  --tw-skew-x: -12deg
}

.-skew-x-6 {
  --tw-skew-x: -6deg
}

.-skew-x-3 {
  --tw-skew-x: -3deg
}

.-skew-x-2 {
  --tw-skew-x: -2deg
}

.-skew-x-1 {
  --tw-skew-x: -1deg
}

.skew-y-0 {
  --tw-skew-y: 0deg
}

.skew-y-1 {
  --tw-skew-y: 1deg
}

.skew-y-2 {
  --tw-skew-y: 2deg
}

.skew-y-3 {
  --tw-skew-y: 3deg
}

.skew-y-6 {
  --tw-skew-y: 6deg
}

.skew-y-12 {
  --tw-skew-y: 12deg
}

.-skew-y-12 {
  --tw-skew-y: -12deg
}

.-skew-y-6 {
  --tw-skew-y: -6deg
}

.-skew-y-3 {
  --tw-skew-y: -3deg
}

.-skew-y-2 {
  --tw-skew-y: -2deg
}

.-skew-y-1 {
  --tw-skew-y: -1deg
}

.hover\:skew-x-0:hover {
  --tw-skew-x: 0deg
}

.hover\:skew-x-1:hover {
  --tw-skew-x: 1deg
}

.hover\:skew-x-2:hover {
  --tw-skew-x: 2deg
}

.hover\:skew-x-3:hover {
  --tw-skew-x: 3deg
}

.hover\:skew-x-6:hover {
  --tw-skew-x: 6deg
}

.hover\:skew-x-12:hover {
  --tw-skew-x: 12deg
}

.hover\:-skew-x-12:hover {
  --tw-skew-x: -12deg
}

.hover\:-skew-x-6:hover {
  --tw-skew-x: -6deg
}

.hover\:-skew-x-3:hover {
  --tw-skew-x: -3deg
}

.hover\:-skew-x-2:hover {
  --tw-skew-x: -2deg
}

.hover\:-skew-x-1:hover {
  --tw-skew-x: -1deg
}

.hover\:skew-y-0:hover {
  --tw-skew-y: 0deg
}

.hover\:skew-y-1:hover {
  --tw-skew-y: 1deg
}

.hover\:skew-y-2:hover {
  --tw-skew-y: 2deg
}

.hover\:skew-y-3:hover {
  --tw-skew-y: 3deg
}

.hover\:skew-y-6:hover {
  --tw-skew-y: 6deg
}

.hover\:skew-y-12:hover {
  --tw-skew-y: 12deg
}

.hover\:-skew-y-12:hover {
  --tw-skew-y: -12deg
}

.hover\:-skew-y-6:hover {
  --tw-skew-y: -6deg
}

.hover\:-skew-y-3:hover {
  --tw-skew-y: -3deg
}

.hover\:-skew-y-2:hover {
  --tw-skew-y: -2deg
}

.hover\:-skew-y-1:hover {
  --tw-skew-y: -1deg
}

.focus\:skew-x-0:focus {
  --tw-skew-x: 0deg
}

.focus\:skew-x-1:focus {
  --tw-skew-x: 1deg
}

.focus\:skew-x-2:focus {
  --tw-skew-x: 2deg
}

.focus\:skew-x-3:focus {
  --tw-skew-x: 3deg
}

.focus\:skew-x-6:focus {
  --tw-skew-x: 6deg
}

.focus\:skew-x-12:focus {
  --tw-skew-x: 12deg
}

.focus\:-skew-x-12:focus {
  --tw-skew-x: -12deg
}

.focus\:-skew-x-6:focus {
  --tw-skew-x: -6deg
}

.focus\:-skew-x-3:focus {
  --tw-skew-x: -3deg
}

.focus\:-skew-x-2:focus {
  --tw-skew-x: -2deg
}

.focus\:-skew-x-1:focus {
  --tw-skew-x: -1deg
}

.focus\:skew-y-0:focus {
  --tw-skew-y: 0deg
}

.focus\:skew-y-1:focus {
  --tw-skew-y: 1deg
}

.focus\:skew-y-2:focus {
  --tw-skew-y: 2deg
}

.focus\:skew-y-3:focus {
  --tw-skew-y: 3deg
}

.focus\:skew-y-6:focus {
  --tw-skew-y: 6deg
}

.focus\:skew-y-12:focus {
  --tw-skew-y: 12deg
}

.focus\:-skew-y-12:focus {
  --tw-skew-y: -12deg
}

.focus\:-skew-y-6:focus {
  --tw-skew-y: -6deg
}

.focus\:-skew-y-3:focus {
  --tw-skew-y: -3deg
}

.focus\:-skew-y-2:focus {
  --tw-skew-y: -2deg
}

.focus\:-skew-y-1:focus {
  --tw-skew-y: -1deg
}

.transition-none {
  transition-property: none
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-shadow {
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ease-linear {
  transition-timing-function: linear
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.duration-75 {
  transition-duration: 75ms
}

.duration-100 {
  transition-duration: 100ms
}

.duration-150 {
  transition-duration: 150ms
}

.duration-200 {
  transition-duration: 200ms
}

.duration-300 {
  transition-duration: 300ms
}

.duration-500 {
  transition-duration: 500ms
}

.duration-700 {
  transition-duration: 700ms
}

.duration-1000 {
  transition-duration: 1000ms
}

.delay-75 {
  transition-delay: 75ms
}

.delay-100 {
  transition-delay: 100ms
}

.delay-150 {
  transition-delay: 150ms
}

.delay-200 {
  transition-delay: 200ms
}

.delay-300 {
  transition-delay: 300ms
}

.delay-500 {
  transition-delay: 500ms
}

.delay-700 {
  transition-delay: 700ms
}

.delay-1000 {
  transition-delay: 1000ms
}

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.animate-none {
  animation: none
}

.animate-spin {
  animation: spin 1s linear infinite
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}

.animate-bounce {
  animation: bounce 1s infinite
}

@media (min-width: 600px) {
  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  .sm\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.1rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.1rem * var(--tw-space-x-reverse));
    margin-left: calc(0.1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.2rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.2rem * var(--tw-space-x-reverse));
    margin-left: calc(0.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.3rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.3rem * var(--tw-space-x-reverse));
    margin-left: calc(0.3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.4rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.4rem * var(--tw-space-x-reverse));
    margin-left: calc(0.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.6rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.6rem * var(--tw-space-x-reverse));
    margin-left: calc(0.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.7rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.7rem * var(--tw-space-x-reverse));
    margin-left: calc(0.7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.8rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.8rem * var(--tw-space-x-reverse));
    margin-left: calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.9rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.9rem * var(--tw-space-x-reverse));
    margin-left: calc(0.9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.0rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.0rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.0rem * var(--tw-space-x-reverse));
    margin-left: calc(1.0rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.2rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.2rem * var(--tw-space-x-reverse));
    margin-left: calc(1.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.4rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.4rem * var(--tw-space-x-reverse));
    margin-left: calc(1.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.6rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.4rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.4rem * var(--tw-space-x-reverse));
    margin-left: calc(2.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.8rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.8rem * var(--tw-space-x-reverse));
    margin-left: calc(2.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.2rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.2rem * var(--tw-space-x-reverse));
    margin-left: calc(3.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.6rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.6rem * var(--tw-space-x-reverse));
    margin-left: calc(3.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4.4rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4.4rem * var(--tw-space-x-reverse));
    margin-left: calc(4.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4.8rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4.8rem * var(--tw-space-x-reverse));
    margin-left: calc(4.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5.2rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5.2rem * var(--tw-space-x-reverse));
    margin-left: calc(5.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5.6rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5.6rem * var(--tw-space-x-reverse));
    margin-left: calc(5.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6.4rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6.4rem * var(--tw-space-x-reverse));
    margin-left: calc(6.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6.8rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6.8rem * var(--tw-space-x-reverse));
    margin-left: calc(6.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7.2rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7.2rem * var(--tw-space-x-reverse));
    margin-left: calc(7.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7.6rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7.6rem * var(--tw-space-x-reverse));
    margin-left: calc(7.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8.4rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8.4rem * var(--tw-space-x-reverse));
    margin-left: calc(8.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8.8rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8.8rem * var(--tw-space-x-reverse));
    margin-left: calc(8.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9.2rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9.2rem * var(--tw-space-x-reverse));
    margin-left: calc(9.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9.6rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9.6rem * var(--tw-space-x-reverse));
    margin-left: calc(9.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12.8rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12.8rem * var(--tw-space-x-reverse));
    margin-left: calc(12.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13.6rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13.6rem * var(--tw-space-x-reverse));
    margin-left: calc(13.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(19.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(19.2rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(19.2rem * var(--tw-space-x-reverse));
    margin-left: calc(19.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20.8rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20.8rem * var(--tw-space-x-reverse));
    margin-left: calc(20.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(21.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(21.6rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(21.6rem * var(--tw-space-x-reverse));
    margin-left: calc(21.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(22.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(22.4rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(22.4rem * var(--tw-space-x-reverse));
    margin-left: calc(22.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(25.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(25.6rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(25.6rem * var(--tw-space-x-reverse));
    margin-left: calc(25.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(28.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(28.8rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(28.8rem * var(--tw-space-x-reverse));
    margin-left: calc(28.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(32rem * var(--tw-space-x-reverse));
    margin-left: calc(32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(36rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(36rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(36rem * var(--tw-space-x-reverse));
    margin-left: calc(36rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(38.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(38.4rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(38.4rem * var(--tw-space-x-reverse));
    margin-left: calc(38.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(40rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(40rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(40rem * var(--tw-space-x-reverse));
    margin-left: calc(40rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(51.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(51.2rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(51.2rem * var(--tw-space-x-reverse));
    margin-left: calc(51.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(64rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(64rem * var(--tw-space-x-reverse));
    margin-left: calc(64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(32rem * var(--tw-space-x-reverse));
    margin-left: calc(32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(48rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(48rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(48rem * var(--tw-space-x-reverse));
    margin-left: calc(48rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(64rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(64rem * var(--tw-space-x-reverse));
    margin-left: calc(64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(80rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(80rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(80rem * var(--tw-space-x-reverse));
    margin-left: calc(80rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(96rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(96rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(96rem * var(--tw-space-x-reverse));
    margin-left: calc(96rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(112rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(112rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(112rem * var(--tw-space-x-reverse));
    margin-left: calc(112rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(128rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(128rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(128rem * var(--tw-space-x-reverse));
    margin-left: calc(128rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(144rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(144rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(144rem * var(--tw-space-x-reverse));
    margin-left: calc(144rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(160rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(160rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(160rem * var(--tw-space-x-reverse));
    margin-left: calc(160rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse))
  }

  .sm\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.05rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.05rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.05rem * var(--tw-space-x-reverse));
    margin-left: calc(0.05rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.15rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.15rem * var(--tw-space-x-reverse));
    margin-left: calc(0.15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.35rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.35rem * var(--tw-space-y-reverse))
  }

  .sm\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.35rem * var(--tw-space-x-reverse));
    margin-left: calc(0.35rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.1rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.1rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.2rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.3rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.3rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.4rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.6rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.7rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.7rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.8rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.9rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.9rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.2rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.4rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.6rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.4rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.8rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.2rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.6rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4.4rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4.8rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-4.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5.2rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-5.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5.6rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-5.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6.4rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-6.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6.8rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-6.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7.2rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-7.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7.6rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-7.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8.4rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-8.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8.8rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9.2rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-9.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9.6rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-9.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12.8rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-12.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13.6rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-13.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-19.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-19.2rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-19.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-19.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20.8rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-20.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-21.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-21.6rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-21.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-21.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-22.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-22.4rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-22.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-22.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-25.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-25.6rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-25.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-25.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-28.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-28.8rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-28.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-28.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-32rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-32rem * var(--tw-space-x-reverse));
    margin-left: calc(-32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-36rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-36rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-36rem * var(--tw-space-x-reverse));
    margin-left: calc(-36rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-38.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-38.4rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-38.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-38.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-40rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-40rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-40rem * var(--tw-space-x-reverse));
    margin-left: calc(-40rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-51.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-51.2rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-51.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-51.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-64rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-64rem * var(--tw-space-x-reverse));
    margin-left: calc(-64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-32rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-32rem * var(--tw-space-x-reverse));
    margin-left: calc(-32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-48rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-48rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-48rem * var(--tw-space-x-reverse));
    margin-left: calc(-48rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-64rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-64rem * var(--tw-space-x-reverse));
    margin-left: calc(-64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-80rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-80rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-80rem * var(--tw-space-x-reverse));
    margin-left: calc(-80rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-96rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-96rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-96rem * var(--tw-space-x-reverse));
    margin-left: calc(-96rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-112rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-112rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-112rem * var(--tw-space-x-reverse));
    margin-left: calc(-112rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-128rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-128rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-128rem * var(--tw-space-x-reverse));
    margin-left: calc(-128rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-144rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-144rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-144rem * var(--tw-space-x-reverse));
    margin-left: calc(-144rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-160rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-160rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-160rem * var(--tw-space-x-reverse));
    margin-left: calc(-160rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.05rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.05rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.05rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.05rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.15rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.15rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.35rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.35rem * var(--tw-space-y-reverse))
  }

  .sm\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.35rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.35rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1
  }

  .sm\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1
  }

  .sm\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse))
  }

  .sm\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .sm\:divide-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  .sm\:divide-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .sm\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse))
  }

  .sm\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .sm\:divide-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(3px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(3px * var(--tw-divide-y-reverse))
  }

  .sm\:divide-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(3px * var(--tw-divide-x-reverse));
    border-left-width: calc(3px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .sm\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse))
  }

  .sm\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .sm\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse))
  }

  .sm\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .sm\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  .sm\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .sm\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1
  }

  .sm\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1
  }

  .sm\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent
  }

  .sm\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor
  }

  .sm\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-divide-opacity))
  }

  .sm\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-grey-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-divide-opacity))
  }

  .sm\:divide-grey-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-divide-opacity))
  }

  .sm\:divide-grey-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-divide-opacity))
  }

  .sm\:divide-grey-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-divide-opacity))
  }

  .sm\:divide-grey-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-divide-opacity))
  }

  .sm\:divide-grey-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .sm\:divide-grey-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-divide-opacity))
  }

  .sm\:divide-grey-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .sm\:divide-grey-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-divide-opacity))
  }

  .sm\:divide-grey-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity))
  }

  .sm\:divide-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .sm\:divide-grey-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-divide-opacity))
  }

  .sm\:divide-grey-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-divide-opacity))
  }

  .sm\:divide-grey-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-divide-opacity))
  }

  .sm\:divide-grey-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .sm\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-divide-opacity))
  }

  .sm\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-divide-opacity))
  }

  .sm\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-divide-opacity))
  }

  .sm\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-divide-opacity))
  }

  .sm\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-divide-opacity))
  }

  .sm\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .sm\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-divide-opacity))
  }

  .sm\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .sm\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-divide-opacity))
  }

  .sm\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity))
  }

  .sm\:divide-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .sm\:divide-gray-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .sm\:divide-gray-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-divide-opacity))
  }

  .sm\:divide-gray-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-divide-opacity))
  }

  .sm\:divide-gray-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-divide-opacity))
  }

  .sm\:divide-gray-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .sm\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-divide-opacity))
  }

  .sm\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-divide-opacity))
  }

  .sm\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-divide-opacity))
  }

  .sm\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-divide-opacity))
  }

  .sm\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-divide-opacity))
  }

  .sm\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-divide-opacity))
  }

  .sm\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-divide-opacity))
  }

  .sm\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-divide-opacity))
  }

  .sm\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-divide-opacity))
  }

  .sm\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-divide-opacity))
  }

  .sm\:divide-red > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-divide-opacity))
  }

  .sm\:divide-red-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-divide-opacity))
  }

  .sm\:divide-red-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-divide-opacity))
  }

  .sm\:divide-red-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-divide-opacity))
  }

  .sm\:divide-red-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-orange-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-divide-opacity))
  }

  .sm\:divide-orange-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-divide-opacity))
  }

  .sm\:divide-orange-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-divide-opacity))
  }

  .sm\:divide-orange-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-divide-opacity))
  }

  .sm\:divide-orange-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-divide-opacity))
  }

  .sm\:divide-orange-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-orange-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-orange-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-orange-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-orange-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-orange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-orange-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-divide-opacity))
  }

  .sm\:divide-orange-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-divide-opacity))
  }

  .sm\:divide-orange-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-orange-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-orange-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-orange-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-orange-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-orange-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-orange-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-orange-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-orange-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-orange-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-orange-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-orange-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-orange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-orange-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-orange-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-orange-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-orange-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-divide-opacity))
  }

  .sm\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-divide-opacity))
  }

  .sm\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-divide-opacity))
  }

  .sm\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-divide-opacity))
  }

  .sm\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-divide-opacity))
  }

  .sm\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-divide-opacity))
  }

  .sm\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-divide-opacity))
  }

  .sm\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-divide-opacity))
  }

  .sm\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-divide-opacity))
  }

  .sm\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-divide-opacity))
  }

  .sm\:divide-yellow > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-divide-opacity))
  }

  .sm\:divide-yellow-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-divide-opacity))
  }

  .sm\:divide-yellow-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-yellow-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-yellow-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-divide-opacity))
  }

  .sm\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-divide-opacity))
  }

  .sm\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-divide-opacity))
  }

  .sm\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-divide-opacity))
  }

  .sm\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-divide-opacity))
  }

  .sm\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-divide-opacity))
  }

  .sm\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-divide-opacity))
  }

  .sm\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-divide-opacity))
  }

  .sm\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-divide-opacity))
  }

  .sm\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-divide-opacity))
  }

  .sm\:divide-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-divide-opacity))
  }

  .sm\:divide-green-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-divide-opacity))
  }

  .sm\:divide-green-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-divide-opacity))
  }

  .sm\:divide-green-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-divide-opacity))
  }

  .sm\:divide-green-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-divide-opacity))
  }

  .sm\:divide-light-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-divide-opacity))
  }

  .sm\:divide-light-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-divide-opacity))
  }

  .sm\:divide-light-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-divide-opacity))
  }

  .sm\:divide-light-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-divide-opacity))
  }

  .sm\:divide-light-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-divide-opacity))
  }

  .sm\:divide-light-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-divide-opacity))
  }

  .sm\:divide-light-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-divide-opacity))
  }

  .sm\:divide-light-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-divide-opacity))
  }

  .sm\:divide-light-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-divide-opacity))
  }

  .sm\:divide-light-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-divide-opacity))
  }

  .sm\:divide-light-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-divide-opacity))
  }

  .sm\:divide-light-green-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-divide-opacity))
  }

  .sm\:divide-light-green-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-divide-opacity))
  }

  .sm\:divide-light-green-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-divide-opacity))
  }

  .sm\:divide-light-green-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-divide-opacity))
  }

  .sm\:divide-teal-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-divide-opacity))
  }

  .sm\:divide-teal-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-divide-opacity))
  }

  .sm\:divide-teal-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-divide-opacity))
  }

  .sm\:divide-teal-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-divide-opacity))
  }

  .sm\:divide-teal-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-divide-opacity))
  }

  .sm\:divide-teal-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-divide-opacity))
  }

  .sm\:divide-teal-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-divide-opacity))
  }

  .sm\:divide-teal-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-divide-opacity))
  }

  .sm\:divide-teal-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-divide-opacity))
  }

  .sm\:divide-teal-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-divide-opacity))
  }

  .sm\:divide-teal > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-divide-opacity))
  }

  .sm\:divide-teal-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-divide-opacity))
  }

  .sm\:divide-teal-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-divide-opacity))
  }

  .sm\:divide-teal-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-divide-opacity))
  }

  .sm\:divide-teal-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-divide-opacity))
  }

  .sm\:divide-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-light-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-divide-opacity))
  }

  .sm\:divide-light-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-divide-opacity))
  }

  .sm\:divide-light-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-divide-opacity))
  }

  .sm\:divide-light-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-divide-opacity))
  }

  .sm\:divide-light-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-divide-opacity))
  }

  .sm\:divide-light-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-divide-opacity))
  }

  .sm\:divide-light-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-divide-opacity))
  }

  .sm\:divide-light-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-divide-opacity))
  }

  .sm\:divide-light-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-divide-opacity))
  }

  .sm\:divide-light-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-divide-opacity))
  }

  .sm\:divide-light-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-divide-opacity))
  }

  .sm\:divide-light-blue-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-light-blue-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-light-blue-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-light-blue-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-divide-opacity))
  }

  .sm\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-divide-opacity))
  }

  .sm\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-divide-opacity))
  }

  .sm\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-divide-opacity))
  }

  .sm\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-divide-opacity))
  }

  .sm\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-divide-opacity))
  }

  .sm\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-divide-opacity))
  }

  .sm\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-divide-opacity))
  }

  .sm\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-divide-opacity))
  }

  .sm\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-divide-opacity))
  }

  .sm\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-divide-opacity))
  }

  .sm\:divide-indigo > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-divide-opacity))
  }

  .sm\:divide-indigo-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-indigo-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-divide-opacity))
  }

  .sm\:divide-indigo-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-divide-opacity))
  }

  .sm\:divide-indigo-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-divide-opacity))
  }

  .sm\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-divide-opacity))
  }

  .sm\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-divide-opacity))
  }

  .sm\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-divide-opacity))
  }

  .sm\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-divide-opacity))
  }

  .sm\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-divide-opacity))
  }

  .sm\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-divide-opacity))
  }

  .sm\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-divide-opacity))
  }

  .sm\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-divide-opacity))
  }

  .sm\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-divide-opacity))
  }

  .sm\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-divide-opacity))
  }

  .sm\:divide-purple > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-divide-opacity))
  }

  .sm\:divide-purple-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-divide-opacity))
  }

  .sm\:divide-purple-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-divide-opacity))
  }

  .sm\:divide-purple-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-divide-opacity))
  }

  .sm\:divide-purple-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-purple > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-purple-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-purple-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-purple-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-deep-purple-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-divide-opacity))
  }

  .sm\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-divide-opacity))
  }

  .sm\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-divide-opacity))
  }

  .sm\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-divide-opacity))
  }

  .sm\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-divide-opacity))
  }

  .sm\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-divide-opacity))
  }

  .sm\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-divide-opacity))
  }

  .sm\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-divide-opacity))
  }

  .sm\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-divide-opacity))
  }

  .sm\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-divide-opacity))
  }

  .sm\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-divide-opacity))
  }

  .sm\:divide-pink > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-divide-opacity))
  }

  .sm\:divide-pink-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-divide-opacity))
  }

  .sm\:divide-pink-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-divide-opacity))
  }

  .sm\:divide-pink-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-divide-opacity))
  }

  .sm\:divide-pink-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-divide-opacity))
  }

  .sm\:divide-lime-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-divide-opacity))
  }

  .sm\:divide-lime-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-divide-opacity))
  }

  .sm\:divide-lime-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-divide-opacity))
  }

  .sm\:divide-lime-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-divide-opacity))
  }

  .sm\:divide-lime-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-divide-opacity))
  }

  .sm\:divide-lime-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-divide-opacity))
  }

  .sm\:divide-lime-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-divide-opacity))
  }

  .sm\:divide-lime-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-divide-opacity))
  }

  .sm\:divide-lime-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-divide-opacity))
  }

  .sm\:divide-lime-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-divide-opacity))
  }

  .sm\:divide-lime > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-divide-opacity))
  }

  .sm\:divide-lime-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-divide-opacity))
  }

  .sm\:divide-lime-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-divide-opacity))
  }

  .sm\:divide-lime-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-lime-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-amber-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-divide-opacity))
  }

  .sm\:divide-amber-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-divide-opacity))
  }

  .sm\:divide-amber-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-divide-opacity))
  }

  .sm\:divide-amber-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-divide-opacity))
  }

  .sm\:divide-amber-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-divide-opacity))
  }

  .sm\:divide-amber-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-divide-opacity))
  }

  .sm\:divide-amber-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-amber-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-amber-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-amber-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-amber > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-divide-opacity))
  }

  .sm\:divide-amber-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-divide-opacity))
  }

  .sm\:divide-amber-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-divide-opacity))
  }

  .sm\:divide-amber-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-amber-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-divide-opacity))
  }

  .sm\:divide-brown-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-divide-opacity))
  }

  .sm\:divide-brown-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-divide-opacity))
  }

  .sm\:divide-brown-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-divide-opacity))
  }

  .sm\:divide-brown-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-divide-opacity))
  }

  .sm\:divide-brown-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-divide-opacity))
  }

  .sm\:divide-brown-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-divide-opacity))
  }

  .sm\:divide-brown-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-divide-opacity))
  }

  .sm\:divide-brown-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-divide-opacity))
  }

  .sm\:divide-brown-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-divide-opacity))
  }

  .sm\:divide-brown-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-divide-opacity))
  }

  .sm\:divide-brown > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-divide-opacity))
  }

  .sm\:divide-brown-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-divide-opacity))
  }

  .sm\:divide-brown-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-divide-opacity))
  }

  .sm\:divide-brown-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-divide-opacity))
  }

  .sm\:divide-brown-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-gray-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-gray-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-gray-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-divide-opacity))
  }

  .sm\:divide-blue-gray-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-divide-opacity))
  }

  .sm\:divide-cyan-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-divide-opacity))
  }

  .sm\:divide-cyan-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-divide-opacity))
  }

  .sm\:divide-cyan-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-divide-opacity))
  }

  .sm\:divide-cyan-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-divide-opacity))
  }

  .sm\:divide-cyan-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-divide-opacity))
  }

  .sm\:divide-cyan-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-divide-opacity))
  }

  .sm\:divide-cyan-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-divide-opacity))
  }

  .sm\:divide-cyan-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-divide-opacity))
  }

  .sm\:divide-cyan-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-divide-opacity))
  }

  .sm\:divide-cyan-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-divide-opacity))
  }

  .sm\:divide-cyan > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-divide-opacity))
  }

  .sm\:divide-cyan-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-cyan-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-cyan-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-divide-opacity))
  }

  .sm\:divide-cyan-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-divide-opacity))
  }

  .sm\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid
  }

  .sm\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed
  }

  .sm\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted
  }

  .sm\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double
  }

  .sm\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none
  }

  .sm\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0
  }

  .sm\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05
  }

  .sm\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1
  }

  .sm\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2
  }

  .sm\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25
  }

  .sm\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3
  }

  .sm\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4
  }

  .sm\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5
  }

  .sm\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6
  }

  .sm\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7
  }

  .sm\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75
  }

  .sm\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8
  }

  .sm\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9
  }

  .sm\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95
  }

  .sm\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1
  }

  .sm\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .sm\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .sm\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .sm\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .sm\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .sm\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .sm\:appearance-none {
    -webkit-appearance: none;
            appearance: none
  }

  .sm\:bg-fixed {
    background-attachment: fixed
  }

  .sm\:bg-local {
    background-attachment: local
  }

  .sm\:bg-scroll {
    background-attachment: scroll
  }

  .sm\:bg-clip-border {
    background-clip: border-box
  }

  .sm\:bg-clip-padding {
    background-clip: padding-box
  }

  .sm\:bg-clip-content {
    background-clip: content-box
  }

  .sm\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text
  }

  .sm\:bg-transparent {
    background-color: transparent
  }

  .sm\:bg-current {
    background-color: currentColor
  }

  .sm\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .sm\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-grey-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .sm\:bg-grey-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .sm\:bg-grey-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .sm\:bg-grey-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .sm\:bg-grey-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .sm\:bg-grey-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:bg-grey-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .sm\:bg-grey-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:bg-grey-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .sm\:bg-grey-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .sm\:bg-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:bg-grey-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .sm\:bg-grey-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .sm\:bg-grey-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .sm\:bg-grey-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .sm\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .sm\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .sm\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .sm\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .sm\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .sm\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .sm\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .sm\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:bg-gray-hover {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .sm\:bg-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .sm\:bg-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .sm\:bg-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .sm\:bg-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .sm\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .sm\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .sm\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .sm\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .sm\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .sm\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .sm\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .sm\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .sm\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .sm\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .sm\:bg-red-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .sm\:bg-red-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .sm\:bg-red-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .sm\:bg-red-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .sm\:bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .sm\:bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .sm\:bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .sm\:bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .sm\:bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .sm\:bg-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .sm\:bg-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .sm\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .sm\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .sm\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .sm\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .sm\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .sm\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .sm\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .sm\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .sm\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .sm\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .sm\:bg-yellow-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .sm\:bg-yellow-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-yellow-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-yellow-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .sm\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .sm\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .sm\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .sm\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .sm\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .sm\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .sm\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .sm\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .sm\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .sm\:bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .sm\:bg-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .sm\:bg-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .sm\:bg-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .sm\:bg-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .sm\:bg-light-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .sm\:bg-light-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .sm\:bg-light-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .sm\:bg-light-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .sm\:bg-light-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .sm\:bg-light-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .sm\:bg-light-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .sm\:bg-light-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .sm\:bg-light-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .sm\:bg-light-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .sm\:bg-light-green {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .sm\:bg-light-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .sm\:bg-light-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .sm\:bg-light-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .sm\:bg-light-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .sm\:bg-teal-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .sm\:bg-teal-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .sm\:bg-teal-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .sm\:bg-teal-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .sm\:bg-teal-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .sm\:bg-teal-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .sm\:bg-teal-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .sm\:bg-teal-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .sm\:bg-teal-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .sm\:bg-teal-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .sm\:bg-teal {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .sm\:bg-teal-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .sm\:bg-teal-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .sm\:bg-teal-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .sm\:bg-teal-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .sm\:bg-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-light-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .sm\:bg-light-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .sm\:bg-light-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .sm\:bg-light-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .sm\:bg-light-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .sm\:bg-light-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .sm\:bg-light-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .sm\:bg-light-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .sm\:bg-light-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .sm\:bg-light-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .sm\:bg-light-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .sm\:bg-light-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-light-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-light-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-light-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .sm\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .sm\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .sm\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .sm\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .sm\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .sm\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .sm\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .sm\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .sm\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .sm\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .sm\:bg-indigo {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .sm\:bg-indigo-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-indigo-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .sm\:bg-indigo-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .sm\:bg-indigo-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .sm\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .sm\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .sm\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .sm\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .sm\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .sm\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .sm\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .sm\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .sm\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .sm\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .sm\:bg-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .sm\:bg-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .sm\:bg-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .sm\:bg-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .sm\:bg-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-deep-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .sm\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .sm\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .sm\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .sm\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .sm\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .sm\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .sm\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .sm\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .sm\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .sm\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .sm\:bg-pink {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .sm\:bg-pink-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .sm\:bg-pink-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .sm\:bg-pink-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .sm\:bg-pink-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .sm\:bg-lime-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .sm\:bg-lime-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .sm\:bg-lime-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .sm\:bg-lime-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .sm\:bg-lime-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .sm\:bg-lime-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .sm\:bg-lime-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .sm\:bg-lime-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .sm\:bg-lime-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .sm\:bg-lime-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .sm\:bg-lime {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .sm\:bg-lime-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .sm\:bg-lime-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .sm\:bg-lime-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-lime-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-amber-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .sm\:bg-amber-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .sm\:bg-amber-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .sm\:bg-amber-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .sm\:bg-amber-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .sm\:bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .sm\:bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-amber-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-amber-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-amber-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-amber {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .sm\:bg-amber-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .sm\:bg-amber-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .sm\:bg-amber-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-amber-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-brown-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .sm\:bg-brown-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .sm\:bg-brown-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .sm\:bg-brown-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .sm\:bg-brown-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .sm\:bg-brown-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .sm\:bg-brown-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .sm\:bg-brown-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .sm\:bg-brown-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .sm\:bg-brown-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .sm\:bg-brown {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .sm\:bg-brown-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .sm\:bg-brown-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .sm\:bg-brown-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .sm\:bg-brown-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .sm\:bg-blue-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-transparent {
    background-color: transparent
  }

  .group:hover .sm\:group-hover\:bg-current {
    background-color: currentColor
  }

  .group:hover .sm\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-grey-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-grey-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-grey-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-grey-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-grey-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-grey-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-grey-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-grey-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-grey-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-grey-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-grey-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-grey-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-grey-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-grey-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray-hover {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .group:hover .sm\:group-hover\:bg-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-yellow-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-yellow-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-yellow-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-yellow-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-green {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-teal-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-teal-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-teal-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-teal-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-teal-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-teal-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-teal-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-teal-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-teal-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-teal-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-teal {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-teal-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-teal-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-teal-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-teal-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-light-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-indigo {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-indigo-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-indigo-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-indigo-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-indigo-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-deep-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-pink {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-pink-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-pink-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-pink-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-pink-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-lime-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-lime-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-lime-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-lime-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-lime-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-lime-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-lime-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-lime-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-lime-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-lime-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-lime {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-lime-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-lime-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-lime-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-lime-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-amber-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-amber-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-amber-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-amber-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-amber-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-amber-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-amber-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-amber-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-amber {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-amber-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-amber-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-amber-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-amber-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-brown-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-brown-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-brown-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-brown-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-brown-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-brown-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-brown-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-brown-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-brown-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-brown-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-brown {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-brown-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-brown-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-brown-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-brown-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-blue-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-transparent:focus-within {
    background-color: transparent
  }

  .sm\:focus-within\:bg-current:focus-within {
    background-color: currentColor
  }

  .sm\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-grey-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-grey-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-grey-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-grey-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-grey-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-grey-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-grey-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-grey-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-grey-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-grey-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-grey-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-grey-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-grey-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-grey-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-gray-hover:focus-within {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .sm\:focus-within\:bg-gray-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-gray-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-gray-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-gray-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-red:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-red-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-red-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-red-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-red-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-orange-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-orange-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-orange-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-orange-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-orange-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-orange-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-orange-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-orange-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-orange-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-orange-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-orange:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-orange-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-orange-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-orange-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-orange-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-orange-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-orange-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-orange-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-orange-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-orange-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-orange-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-orange-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-orange-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-orange-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-orange-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-orange:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-orange-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-orange-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-orange-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-orange-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-yellow:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-yellow-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-yellow-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-yellow-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-yellow-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-green-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-green-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-green-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-green-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-green-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-green-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-green-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-green-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-teal-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-teal-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-teal-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-teal-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-teal-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-teal-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-teal-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-teal-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-teal-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-teal-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-teal:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-teal-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-teal-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-teal-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-teal-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-blue-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-blue-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-blue-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-light-blue-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-indigo:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-indigo-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-indigo-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-indigo-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-indigo-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-purple:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-purple-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-purple-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-purple-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-purple-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-purple:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-purple-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-purple-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-purple-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-deep-purple-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-pink:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-pink-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-pink-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-pink-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-pink-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-lime-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-lime-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-lime-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-lime-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-lime-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-lime-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-lime-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-lime-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-lime-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-lime-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-lime:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-lime-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-lime-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-lime-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-lime-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-amber-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-amber-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-amber-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-amber-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-amber-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-amber-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-amber-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-amber-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-amber-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-amber-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-amber:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-amber-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-amber-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-amber-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-amber-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-brown-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-brown-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-brown-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-brown-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-brown-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-brown-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-brown-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-brown-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-brown-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-brown-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-brown:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-brown-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-brown-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-brown-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-brown-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-gray-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-gray-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-gray-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-blue-gray-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-cyan-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-cyan-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-cyan-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-cyan-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-cyan-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-cyan-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-cyan-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-cyan-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-cyan-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-cyan-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-cyan:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-cyan-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-cyan-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-cyan-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .sm\:focus-within\:bg-cyan-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-transparent:hover {
    background-color: transparent
  }

  .sm\:hover\:bg-current:hover {
    background-color: currentColor
  }

  .sm\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-grey-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-grey-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-grey-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-grey-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-grey-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-grey-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-grey-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-grey-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-grey-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-grey-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-grey-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-grey-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-grey-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-grey-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray-hover:hover {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .sm\:hover\:bg-gray-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-orange-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-orange-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-orange-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-orange-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-orange-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-orange-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-orange-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-orange-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-orange-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-orange-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-orange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-orange-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-orange-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-orange-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-orange-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-orange-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-orange-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-orange-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-orange-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-orange-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-orange-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-orange-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-orange-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-orange-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-orange-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-orange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-orange-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-orange-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-orange-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-orange-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-yellow:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-yellow-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-yellow-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-yellow-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-yellow-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-green-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-green-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-green-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-green-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-teal-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-teal-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-teal-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-teal-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-teal-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-teal-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-teal-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-teal-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-teal-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-teal-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-teal:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-teal-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-teal-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-teal-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-teal-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-blue-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-blue-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-blue-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-light-blue-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-indigo:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-indigo-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-indigo-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-indigo-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-indigo-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-purple:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-purple-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-purple-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-purple-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-purple-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-purple:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-purple-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-purple-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-purple-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-deep-purple-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-pink:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-pink-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-pink-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-pink-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-pink-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-lime-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-lime-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-lime-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-lime-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-lime-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-lime-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-lime-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-lime-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-lime-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-lime-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-lime:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-lime-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-lime-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-lime-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-lime-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-amber-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-amber-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-amber-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-amber-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-amber-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-amber-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-amber-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-amber-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-amber-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-amber-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-amber:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-amber-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-amber-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-amber-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-amber-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-brown-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-brown-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-brown-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-brown-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-brown-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-brown-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-brown-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-brown-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-brown-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-brown-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-brown:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-brown-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-brown-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-brown-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-brown-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-gray-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-gray-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-gray-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-blue-gray-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-transparent:focus {
    background-color: transparent
  }

  .sm\:focus\:bg-current:focus {
    background-color: currentColor
  }

  .sm\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-grey-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-grey-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-grey-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-grey-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-grey-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-grey-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-grey-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-grey-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-grey-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-grey-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-grey-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-grey-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-grey-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-grey-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray-hover:focus {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .sm\:focus\:bg-gray-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-orange-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-orange-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-orange-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-orange-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-orange-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-orange-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-orange-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-orange-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-orange-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-orange-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-orange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-orange-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-orange-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-orange-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-orange-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-orange-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-orange-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-orange-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-orange-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-orange-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-orange-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-orange-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-orange-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-orange-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-orange-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-orange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-orange-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-orange-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-orange-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-orange-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-yellow:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-yellow-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-yellow-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-yellow-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-yellow-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-green-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-green-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-green-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-green-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-teal-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-teal-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-teal-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-teal-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-teal-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-teal-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-teal-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-teal-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-teal-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-teal-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-teal:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-teal-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-teal-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-teal-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-teal-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-blue-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-blue-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-blue-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-light-blue-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-indigo:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-indigo-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-indigo-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-indigo-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-indigo-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-purple:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-purple-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-purple-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-purple-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-purple-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-purple:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-purple-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-purple-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-purple-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-deep-purple-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-pink:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-pink-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-pink-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-pink-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-pink-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-lime-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-lime-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-lime-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-lime-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-lime-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-lime-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-lime-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-lime-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-lime-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-lime-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-lime:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-lime-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-lime-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-lime-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-lime-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-amber-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-amber-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-amber-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-amber-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-amber-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-amber-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-amber-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-amber-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-amber-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-amber-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-amber:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-amber-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-amber-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-amber-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-amber-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-brown-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-brown-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-brown-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-brown-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-brown-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-brown-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-brown-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-brown-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-brown-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-brown-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-brown:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-brown-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-brown-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-brown-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-brown-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-gray-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-gray-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-gray-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-blue-gray-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .sm\:bg-none {
    background-image: none
  }

  .sm\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .sm\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:from-black {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .sm\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:from-grey-50 {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .sm\:from-grey-100 {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .sm\:from-grey-200 {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .sm\:from-grey-300 {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .sm\:from-grey-400 {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .sm\:from-grey-500 {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:from-grey-600 {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .sm\:from-grey-700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:from-grey-800 {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .sm\:from-grey-900 {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .sm\:from-grey {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:from-grey-A100 {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .sm\:from-grey-A200 {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .sm\:from-grey-A400 {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .sm\:from-grey-A700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:from-gray-50 {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .sm\:from-gray-100 {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .sm\:from-gray-200 {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .sm\:from-gray-300 {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .sm\:from-gray-400 {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .sm\:from-gray-500 {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:from-gray-600 {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .sm\:from-gray-700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:from-gray-800 {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .sm\:from-gray-900 {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .sm\:from-gray {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:from-gray-hover {
    --tw-gradient-from: rgba(0, 0, 0, 0.04);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:from-gray-A100 {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .sm\:from-gray-A200 {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .sm\:from-gray-A400 {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .sm\:from-gray-A700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:from-red-50 {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .sm\:from-red-100 {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .sm\:from-red-200 {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .sm\:from-red-300 {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .sm\:from-red-400 {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .sm\:from-red-500 {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .sm\:from-red-600 {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .sm\:from-red-700 {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .sm\:from-red-800 {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .sm\:from-red-900 {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .sm\:from-red {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .sm\:from-red-A100 {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .sm\:from-red-A200 {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .sm\:from-red-A400 {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .sm\:from-red-A700 {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .sm\:from-orange-50 {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .sm\:from-orange-100 {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .sm\:from-orange-200 {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .sm\:from-orange-300 {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .sm\:from-orange-400 {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .sm\:from-orange-500 {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .sm\:from-orange-600 {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .sm\:from-orange-700 {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .sm\:from-orange-800 {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .sm\:from-orange-900 {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .sm\:from-orange {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .sm\:from-orange-A100 {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .sm\:from-orange-A200 {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .sm\:from-orange-A400 {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .sm\:from-orange-A700 {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .sm\:from-deep-orange-50 {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .sm\:from-deep-orange-100 {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .sm\:from-deep-orange-200 {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .sm\:from-deep-orange-300 {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .sm\:from-deep-orange-400 {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .sm\:from-deep-orange-500 {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .sm\:from-deep-orange-600 {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .sm\:from-deep-orange-700 {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .sm\:from-deep-orange-800 {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .sm\:from-deep-orange-900 {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .sm\:from-deep-orange {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .sm\:from-deep-orange-A100 {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .sm\:from-deep-orange-A200 {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .sm\:from-deep-orange-A400 {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .sm\:from-deep-orange-A700 {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .sm\:from-yellow-50 {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .sm\:from-yellow-100 {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .sm\:from-yellow-200 {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .sm\:from-yellow-300 {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .sm\:from-yellow-400 {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .sm\:from-yellow-500 {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .sm\:from-yellow-600 {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .sm\:from-yellow-700 {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .sm\:from-yellow-800 {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .sm\:from-yellow-900 {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .sm\:from-yellow {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .sm\:from-yellow-A100 {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .sm\:from-yellow-A200 {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .sm\:from-yellow-A400 {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .sm\:from-yellow-A700 {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .sm\:from-green-50 {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .sm\:from-green-100 {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .sm\:from-green-200 {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .sm\:from-green-300 {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .sm\:from-green-400 {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .sm\:from-green-500 {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .sm\:from-green-600 {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .sm\:from-green-700 {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .sm\:from-green-800 {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .sm\:from-green-900 {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .sm\:from-green {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .sm\:from-green-A100 {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .sm\:from-green-A200 {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .sm\:from-green-A400 {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .sm\:from-green-A700 {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .sm\:from-light-green-50 {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .sm\:from-light-green-100 {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .sm\:from-light-green-200 {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .sm\:from-light-green-300 {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .sm\:from-light-green-400 {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .sm\:from-light-green-500 {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .sm\:from-light-green-600 {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .sm\:from-light-green-700 {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .sm\:from-light-green-800 {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .sm\:from-light-green-900 {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .sm\:from-light-green {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .sm\:from-light-green-A100 {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .sm\:from-light-green-A200 {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .sm\:from-light-green-A400 {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .sm\:from-light-green-A700 {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .sm\:from-teal-50 {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .sm\:from-teal-100 {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .sm\:from-teal-200 {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .sm\:from-teal-300 {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .sm\:from-teal-400 {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .sm\:from-teal-500 {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .sm\:from-teal-600 {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .sm\:from-teal-700 {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .sm\:from-teal-800 {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .sm\:from-teal-900 {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .sm\:from-teal {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .sm\:from-teal-A100 {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .sm\:from-teal-A200 {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .sm\:from-teal-A400 {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .sm\:from-teal-A700 {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .sm\:from-blue-50 {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .sm\:from-blue-100 {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .sm\:from-blue-200 {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .sm\:from-blue-300 {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .sm\:from-blue-400 {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .sm\:from-blue-500 {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .sm\:from-blue-600 {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .sm\:from-blue-700 {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .sm\:from-blue-800 {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .sm\:from-blue-900 {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .sm\:from-blue {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .sm\:from-blue-A100 {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .sm\:from-blue-A200 {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .sm\:from-blue-A400 {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .sm\:from-blue-A700 {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .sm\:from-light-blue-50 {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .sm\:from-light-blue-100 {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .sm\:from-light-blue-200 {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .sm\:from-light-blue-300 {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .sm\:from-light-blue-400 {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .sm\:from-light-blue-500 {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .sm\:from-light-blue-600 {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .sm\:from-light-blue-700 {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .sm\:from-light-blue-800 {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .sm\:from-light-blue-900 {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .sm\:from-light-blue {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .sm\:from-light-blue-A100 {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .sm\:from-light-blue-A200 {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .sm\:from-light-blue-A400 {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .sm\:from-light-blue-A700 {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .sm\:from-indigo-50 {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .sm\:from-indigo-100 {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .sm\:from-indigo-200 {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .sm\:from-indigo-300 {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .sm\:from-indigo-400 {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .sm\:from-indigo-500 {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .sm\:from-indigo-600 {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .sm\:from-indigo-700 {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .sm\:from-indigo-800 {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .sm\:from-indigo-900 {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .sm\:from-indigo {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .sm\:from-indigo-A100 {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .sm\:from-indigo-A200 {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .sm\:from-indigo-A400 {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .sm\:from-indigo-A700 {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .sm\:from-purple-50 {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .sm\:from-purple-100 {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .sm\:from-purple-200 {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .sm\:from-purple-300 {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .sm\:from-purple-400 {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .sm\:from-purple-500 {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .sm\:from-purple-600 {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .sm\:from-purple-700 {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .sm\:from-purple-800 {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .sm\:from-purple-900 {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .sm\:from-purple {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .sm\:from-purple-A100 {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .sm\:from-purple-A200 {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .sm\:from-purple-A400 {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .sm\:from-purple-A700 {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .sm\:from-deep-purple-50 {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .sm\:from-deep-purple-100 {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .sm\:from-deep-purple-200 {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .sm\:from-deep-purple-300 {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .sm\:from-deep-purple-400 {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .sm\:from-deep-purple-500 {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .sm\:from-deep-purple-600 {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .sm\:from-deep-purple-700 {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .sm\:from-deep-purple-800 {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .sm\:from-deep-purple-900 {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .sm\:from-deep-purple {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .sm\:from-deep-purple-A100 {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .sm\:from-deep-purple-A200 {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .sm\:from-deep-purple-A400 {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .sm\:from-deep-purple-A700 {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .sm\:from-pink-50 {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .sm\:from-pink-100 {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .sm\:from-pink-200 {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .sm\:from-pink-300 {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .sm\:from-pink-400 {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .sm\:from-pink-500 {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .sm\:from-pink-600 {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .sm\:from-pink-700 {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .sm\:from-pink-800 {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .sm\:from-pink-900 {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .sm\:from-pink {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .sm\:from-pink-A100 {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .sm\:from-pink-A200 {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .sm\:from-pink-A400 {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .sm\:from-pink-A700 {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .sm\:from-lime-50 {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .sm\:from-lime-100 {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .sm\:from-lime-200 {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .sm\:from-lime-300 {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .sm\:from-lime-400 {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .sm\:from-lime-500 {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .sm\:from-lime-600 {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .sm\:from-lime-700 {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .sm\:from-lime-800 {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .sm\:from-lime-900 {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .sm\:from-lime {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .sm\:from-lime-A100 {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .sm\:from-lime-A200 {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .sm\:from-lime-A400 {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .sm\:from-lime-A700 {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .sm\:from-amber-50 {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .sm\:from-amber-100 {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .sm\:from-amber-200 {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .sm\:from-amber-300 {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .sm\:from-amber-400 {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .sm\:from-amber-500 {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .sm\:from-amber-600 {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .sm\:from-amber-700 {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .sm\:from-amber-800 {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .sm\:from-amber-900 {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .sm\:from-amber {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .sm\:from-amber-A100 {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .sm\:from-amber-A200 {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .sm\:from-amber-A400 {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .sm\:from-amber-A700 {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .sm\:from-brown-50 {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .sm\:from-brown-100 {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .sm\:from-brown-200 {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .sm\:from-brown-300 {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .sm\:from-brown-400 {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .sm\:from-brown-500 {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .sm\:from-brown-600 {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .sm\:from-brown-700 {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .sm\:from-brown-800 {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .sm\:from-brown-900 {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .sm\:from-brown {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .sm\:from-brown-A100 {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .sm\:from-brown-A200 {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .sm\:from-brown-A400 {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .sm\:from-brown-A700 {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .sm\:from-blue-gray-50 {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .sm\:from-blue-gray-100 {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .sm\:from-blue-gray-200 {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .sm\:from-blue-gray-300 {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .sm\:from-blue-gray-400 {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .sm\:from-blue-gray-500 {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .sm\:from-blue-gray-600 {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .sm\:from-blue-gray-700 {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .sm\:from-blue-gray-800 {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .sm\:from-blue-gray-900 {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .sm\:from-blue-gray {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .sm\:from-blue-gray-A100 {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .sm\:from-blue-gray-A200 {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .sm\:from-blue-gray-A400 {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .sm\:from-blue-gray-A700 {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .sm\:from-cyan-50 {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .sm\:from-cyan-100 {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .sm\:from-cyan-200 {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .sm\:from-cyan-300 {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .sm\:from-cyan-400 {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .sm\:from-cyan-500 {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .sm\:from-cyan-600 {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .sm\:from-cyan-700 {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .sm\:from-cyan-800 {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .sm\:from-cyan-900 {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .sm\:from-cyan {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .sm\:from-cyan-A100 {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .sm\:from-cyan-A200 {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .sm\:from-cyan-A400 {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .sm\:from-cyan-A700 {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .sm\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .sm\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:via-grey-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .sm\:via-grey-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .sm\:via-grey-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .sm\:via-grey-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .sm\:via-grey-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .sm\:via-grey-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:via-grey-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .sm\:via-grey-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:via-grey-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .sm\:via-grey-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .sm\:via-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:via-grey-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .sm\:via-grey-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .sm\:via-grey-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .sm\:via-grey-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .sm\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .sm\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .sm\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .sm\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .sm\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .sm\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .sm\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .sm\:via-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:via-gray-hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:via-gray-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .sm\:via-gray-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .sm\:via-gray-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .sm\:via-gray-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .sm\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .sm\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .sm\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .sm\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .sm\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .sm\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .sm\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .sm\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .sm\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .sm\:via-red {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .sm\:via-red-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .sm\:via-red-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .sm\:via-red-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .sm\:via-red-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .sm\:via-orange-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .sm\:via-orange-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .sm\:via-orange-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .sm\:via-orange-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .sm\:via-orange-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .sm\:via-orange-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .sm\:via-orange-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .sm\:via-orange-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .sm\:via-orange-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .sm\:via-orange-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .sm\:via-orange {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .sm\:via-orange-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .sm\:via-orange-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .sm\:via-orange-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .sm\:via-orange-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .sm\:via-deep-orange-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .sm\:via-deep-orange-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .sm\:via-deep-orange-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .sm\:via-deep-orange-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .sm\:via-deep-orange-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .sm\:via-deep-orange-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .sm\:via-deep-orange-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .sm\:via-deep-orange-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .sm\:via-deep-orange-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .sm\:via-deep-orange-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .sm\:via-deep-orange {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .sm\:via-deep-orange-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .sm\:via-deep-orange-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .sm\:via-deep-orange-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .sm\:via-deep-orange-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .sm\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .sm\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .sm\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .sm\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .sm\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .sm\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .sm\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .sm\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .sm\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .sm\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .sm\:via-yellow {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .sm\:via-yellow-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .sm\:via-yellow-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .sm\:via-yellow-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .sm\:via-yellow-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .sm\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .sm\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .sm\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .sm\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .sm\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .sm\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .sm\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .sm\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .sm\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .sm\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .sm\:via-green {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .sm\:via-green-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .sm\:via-green-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .sm\:via-green-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .sm\:via-green-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .sm\:via-light-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .sm\:via-light-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .sm\:via-light-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .sm\:via-light-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .sm\:via-light-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .sm\:via-light-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .sm\:via-light-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .sm\:via-light-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .sm\:via-light-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .sm\:via-light-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .sm\:via-light-green {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .sm\:via-light-green-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .sm\:via-light-green-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .sm\:via-light-green-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .sm\:via-light-green-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .sm\:via-teal-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .sm\:via-teal-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .sm\:via-teal-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .sm\:via-teal-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .sm\:via-teal-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .sm\:via-teal-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .sm\:via-teal-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .sm\:via-teal-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .sm\:via-teal-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .sm\:via-teal-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .sm\:via-teal {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .sm\:via-teal-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .sm\:via-teal-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .sm\:via-teal-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .sm\:via-teal-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .sm\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .sm\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .sm\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .sm\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .sm\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .sm\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .sm\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .sm\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .sm\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .sm\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .sm\:via-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .sm\:via-blue-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .sm\:via-blue-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .sm\:via-blue-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .sm\:via-blue-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .sm\:via-light-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .sm\:via-light-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .sm\:via-light-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .sm\:via-light-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .sm\:via-light-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .sm\:via-light-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .sm\:via-light-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .sm\:via-light-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .sm\:via-light-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .sm\:via-light-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .sm\:via-light-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .sm\:via-light-blue-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .sm\:via-light-blue-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .sm\:via-light-blue-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .sm\:via-light-blue-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .sm\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .sm\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .sm\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .sm\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .sm\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .sm\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .sm\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .sm\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .sm\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .sm\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .sm\:via-indigo {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .sm\:via-indigo-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .sm\:via-indigo-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .sm\:via-indigo-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .sm\:via-indigo-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .sm\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .sm\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .sm\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .sm\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .sm\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .sm\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .sm\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .sm\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .sm\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .sm\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .sm\:via-purple {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .sm\:via-purple-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .sm\:via-purple-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .sm\:via-purple-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .sm\:via-purple-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .sm\:via-deep-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .sm\:via-deep-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .sm\:via-deep-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .sm\:via-deep-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .sm\:via-deep-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .sm\:via-deep-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .sm\:via-deep-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .sm\:via-deep-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .sm\:via-deep-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .sm\:via-deep-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .sm\:via-deep-purple {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .sm\:via-deep-purple-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .sm\:via-deep-purple-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .sm\:via-deep-purple-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .sm\:via-deep-purple-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .sm\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .sm\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .sm\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .sm\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .sm\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .sm\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .sm\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .sm\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .sm\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .sm\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .sm\:via-pink {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .sm\:via-pink-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .sm\:via-pink-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .sm\:via-pink-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .sm\:via-pink-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .sm\:via-lime-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .sm\:via-lime-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .sm\:via-lime-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .sm\:via-lime-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .sm\:via-lime-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .sm\:via-lime-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .sm\:via-lime-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .sm\:via-lime-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .sm\:via-lime-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .sm\:via-lime-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .sm\:via-lime {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .sm\:via-lime-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .sm\:via-lime-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .sm\:via-lime-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .sm\:via-lime-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .sm\:via-amber-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .sm\:via-amber-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .sm\:via-amber-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .sm\:via-amber-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .sm\:via-amber-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .sm\:via-amber-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .sm\:via-amber-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .sm\:via-amber-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .sm\:via-amber-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .sm\:via-amber-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .sm\:via-amber {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .sm\:via-amber-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .sm\:via-amber-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .sm\:via-amber-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .sm\:via-amber-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .sm\:via-brown-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .sm\:via-brown-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .sm\:via-brown-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .sm\:via-brown-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .sm\:via-brown-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .sm\:via-brown-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .sm\:via-brown-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .sm\:via-brown-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .sm\:via-brown-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .sm\:via-brown-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .sm\:via-brown {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .sm\:via-brown-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .sm\:via-brown-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .sm\:via-brown-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .sm\:via-brown-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .sm\:via-blue-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .sm\:via-blue-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .sm\:via-blue-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .sm\:via-blue-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .sm\:via-blue-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .sm\:via-blue-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .sm\:via-blue-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .sm\:via-blue-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .sm\:via-blue-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .sm\:via-blue-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .sm\:via-blue-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .sm\:via-blue-gray-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .sm\:via-blue-gray-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .sm\:via-blue-gray-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .sm\:via-blue-gray-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .sm\:via-cyan-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .sm\:via-cyan-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .sm\:via-cyan-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .sm\:via-cyan-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .sm\:via-cyan-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .sm\:via-cyan-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .sm\:via-cyan-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .sm\:via-cyan-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .sm\:via-cyan-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .sm\:via-cyan-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .sm\:via-cyan {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .sm\:via-cyan-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .sm\:via-cyan-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .sm\:via-cyan-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .sm\:via-cyan-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .sm\:to-transparent {
    --tw-gradient-to: transparent
  }

  .sm\:to-current {
    --tw-gradient-to: currentColor
  }

  .sm\:to-black {
    --tw-gradient-to: #22292F
  }

  .sm\:to-white {
    --tw-gradient-to: #fff
  }

  .sm\:to-grey-50 {
    --tw-gradient-to: #FAFAFA
  }

  .sm\:to-grey-100 {
    --tw-gradient-to: #F5F5F5
  }

  .sm\:to-grey-200 {
    --tw-gradient-to: #EEEEEE
  }

  .sm\:to-grey-300 {
    --tw-gradient-to: #E0E0E0
  }

  .sm\:to-grey-400 {
    --tw-gradient-to: #BDBDBD
  }

  .sm\:to-grey-500 {
    --tw-gradient-to: #9E9E9E
  }

  .sm\:to-grey-600 {
    --tw-gradient-to: #757575
  }

  .sm\:to-grey-700 {
    --tw-gradient-to: #616161
  }

  .sm\:to-grey-800 {
    --tw-gradient-to: #424242
  }

  .sm\:to-grey-900 {
    --tw-gradient-to: #212121
  }

  .sm\:to-grey {
    --tw-gradient-to: #9E9E9E
  }

  .sm\:to-grey-A100 {
    --tw-gradient-to: #D5D5D5
  }

  .sm\:to-grey-A200 {
    --tw-gradient-to: #AAAAAA
  }

  .sm\:to-grey-A400 {
    --tw-gradient-to: #303030
  }

  .sm\:to-grey-A700 {
    --tw-gradient-to: #616161
  }

  .sm\:to-gray-50 {
    --tw-gradient-to: #FAFAFA
  }

  .sm\:to-gray-100 {
    --tw-gradient-to: #F5F5F5
  }

  .sm\:to-gray-200 {
    --tw-gradient-to: #EEEEEE
  }

  .sm\:to-gray-300 {
    --tw-gradient-to: #E0E0E0
  }

  .sm\:to-gray-400 {
    --tw-gradient-to: #BDBDBD
  }

  .sm\:to-gray-500 {
    --tw-gradient-to: #9E9E9E
  }

  .sm\:to-gray-600 {
    --tw-gradient-to: #757575
  }

  .sm\:to-gray-700 {
    --tw-gradient-to: #616161
  }

  .sm\:to-gray-800 {
    --tw-gradient-to: #424242
  }

  .sm\:to-gray-900 {
    --tw-gradient-to: #212121
  }

  .sm\:to-gray {
    --tw-gradient-to: #9E9E9E
  }

  .sm\:to-gray-hover {
    --tw-gradient-to: rgba(0, 0, 0, 0.04)
  }

  .sm\:to-gray-A100 {
    --tw-gradient-to: #D5D5D5
  }

  .sm\:to-gray-A200 {
    --tw-gradient-to: #AAAAAA
  }

  .sm\:to-gray-A400 {
    --tw-gradient-to: #303030
  }

  .sm\:to-gray-A700 {
    --tw-gradient-to: #616161
  }

  .sm\:to-red-50 {
    --tw-gradient-to: #FFEBEE
  }

  .sm\:to-red-100 {
    --tw-gradient-to: #FFCDD2
  }

  .sm\:to-red-200 {
    --tw-gradient-to: #EF9A9A
  }

  .sm\:to-red-300 {
    --tw-gradient-to: #E57373
  }

  .sm\:to-red-400 {
    --tw-gradient-to: #EF5350
  }

  .sm\:to-red-500 {
    --tw-gradient-to: #F44336
  }

  .sm\:to-red-600 {
    --tw-gradient-to: #E53935
  }

  .sm\:to-red-700 {
    --tw-gradient-to: #D32F2F
  }

  .sm\:to-red-800 {
    --tw-gradient-to: #C62828
  }

  .sm\:to-red-900 {
    --tw-gradient-to: #B71C1C
  }

  .sm\:to-red {
    --tw-gradient-to: #F44336
  }

  .sm\:to-red-A100 {
    --tw-gradient-to: #FF8A80
  }

  .sm\:to-red-A200 {
    --tw-gradient-to: #FF5252
  }

  .sm\:to-red-A400 {
    --tw-gradient-to: #FF1744
  }

  .sm\:to-red-A700 {
    --tw-gradient-to: #D50000
  }

  .sm\:to-orange-50 {
    --tw-gradient-to: #FFF3E0
  }

  .sm\:to-orange-100 {
    --tw-gradient-to: #FFE0B2
  }

  .sm\:to-orange-200 {
    --tw-gradient-to: #FFCC80
  }

  .sm\:to-orange-300 {
    --tw-gradient-to: #FFB74D
  }

  .sm\:to-orange-400 {
    --tw-gradient-to: #FFA726
  }

  .sm\:to-orange-500 {
    --tw-gradient-to: #FF9800
  }

  .sm\:to-orange-600 {
    --tw-gradient-to: #FB8C00
  }

  .sm\:to-orange-700 {
    --tw-gradient-to: #F57C00
  }

  .sm\:to-orange-800 {
    --tw-gradient-to: #EF6C00
  }

  .sm\:to-orange-900 {
    --tw-gradient-to: #E65100
  }

  .sm\:to-orange {
    --tw-gradient-to: #FF9800
  }

  .sm\:to-orange-A100 {
    --tw-gradient-to: #FFD180
  }

  .sm\:to-orange-A200 {
    --tw-gradient-to: #FFAB40
  }

  .sm\:to-orange-A400 {
    --tw-gradient-to: #FF9100
  }

  .sm\:to-orange-A700 {
    --tw-gradient-to: #FF6D00
  }

  .sm\:to-deep-orange-50 {
    --tw-gradient-to: #FBE9E7
  }

  .sm\:to-deep-orange-100 {
    --tw-gradient-to: #FFCCBC
  }

  .sm\:to-deep-orange-200 {
    --tw-gradient-to: #FFAB91
  }

  .sm\:to-deep-orange-300 {
    --tw-gradient-to: #FF8A65
  }

  .sm\:to-deep-orange-400 {
    --tw-gradient-to: #FF7043
  }

  .sm\:to-deep-orange-500 {
    --tw-gradient-to: #FF5722
  }

  .sm\:to-deep-orange-600 {
    --tw-gradient-to: #F4511E
  }

  .sm\:to-deep-orange-700 {
    --tw-gradient-to: #E64A19
  }

  .sm\:to-deep-orange-800 {
    --tw-gradient-to: #D84315
  }

  .sm\:to-deep-orange-900 {
    --tw-gradient-to: #BF360C
  }

  .sm\:to-deep-orange {
    --tw-gradient-to: #FF5722
  }

  .sm\:to-deep-orange-A100 {
    --tw-gradient-to: #FF9E80
  }

  .sm\:to-deep-orange-A200 {
    --tw-gradient-to: #FF6E40
  }

  .sm\:to-deep-orange-A400 {
    --tw-gradient-to: #FF3D00
  }

  .sm\:to-deep-orange-A700 {
    --tw-gradient-to: #DD2C00
  }

  .sm\:to-yellow-50 {
    --tw-gradient-to: #FFFDE7
  }

  .sm\:to-yellow-100 {
    --tw-gradient-to: #FFF9C4
  }

  .sm\:to-yellow-200 {
    --tw-gradient-to: #FFF59D
  }

  .sm\:to-yellow-300 {
    --tw-gradient-to: #FFF176
  }

  .sm\:to-yellow-400 {
    --tw-gradient-to: #FFEE58
  }

  .sm\:to-yellow-500 {
    --tw-gradient-to: #FFEB3B
  }

  .sm\:to-yellow-600 {
    --tw-gradient-to: #FDD835
  }

  .sm\:to-yellow-700 {
    --tw-gradient-to: #FBC02D
  }

  .sm\:to-yellow-800 {
    --tw-gradient-to: #F9A825
  }

  .sm\:to-yellow-900 {
    --tw-gradient-to: #F57F17
  }

  .sm\:to-yellow {
    --tw-gradient-to: #FFEB3B
  }

  .sm\:to-yellow-A100 {
    --tw-gradient-to: #FFFF8D
  }

  .sm\:to-yellow-A200 {
    --tw-gradient-to: #FFFF00
  }

  .sm\:to-yellow-A400 {
    --tw-gradient-to: #FFEA00
  }

  .sm\:to-yellow-A700 {
    --tw-gradient-to: #FFD600
  }

  .sm\:to-green-50 {
    --tw-gradient-to: #E8F5E9
  }

  .sm\:to-green-100 {
    --tw-gradient-to: #C8E6C9
  }

  .sm\:to-green-200 {
    --tw-gradient-to: #A5D6A7
  }

  .sm\:to-green-300 {
    --tw-gradient-to: #81C784
  }

  .sm\:to-green-400 {
    --tw-gradient-to: #66BB6A
  }

  .sm\:to-green-500 {
    --tw-gradient-to: #4CAF50
  }

  .sm\:to-green-600 {
    --tw-gradient-to: #43A047
  }

  .sm\:to-green-700 {
    --tw-gradient-to: #388E3C
  }

  .sm\:to-green-800 {
    --tw-gradient-to: #2E7D32
  }

  .sm\:to-green-900 {
    --tw-gradient-to: #1B5E20
  }

  .sm\:to-green {
    --tw-gradient-to: #4CAF50
  }

  .sm\:to-green-A100 {
    --tw-gradient-to: #B9F6CA
  }

  .sm\:to-green-A200 {
    --tw-gradient-to: #69F0AE
  }

  .sm\:to-green-A400 {
    --tw-gradient-to: #00E676
  }

  .sm\:to-green-A700 {
    --tw-gradient-to: #00C853
  }

  .sm\:to-light-green-50 {
    --tw-gradient-to: #F1F8E9
  }

  .sm\:to-light-green-100 {
    --tw-gradient-to: #DCEDC8
  }

  .sm\:to-light-green-200 {
    --tw-gradient-to: #C5E1A5
  }

  .sm\:to-light-green-300 {
    --tw-gradient-to: #AED581
  }

  .sm\:to-light-green-400 {
    --tw-gradient-to: #9CCC65
  }

  .sm\:to-light-green-500 {
    --tw-gradient-to: #8BC34A
  }

  .sm\:to-light-green-600 {
    --tw-gradient-to: #7CB342
  }

  .sm\:to-light-green-700 {
    --tw-gradient-to: #689F38
  }

  .sm\:to-light-green-800 {
    --tw-gradient-to: #558B2F
  }

  .sm\:to-light-green-900 {
    --tw-gradient-to: #33691E
  }

  .sm\:to-light-green {
    --tw-gradient-to: #8BC34A
  }

  .sm\:to-light-green-A100 {
    --tw-gradient-to: #CCFF90
  }

  .sm\:to-light-green-A200 {
    --tw-gradient-to: #B2FF59
  }

  .sm\:to-light-green-A400 {
    --tw-gradient-to: #76FF03
  }

  .sm\:to-light-green-A700 {
    --tw-gradient-to: #64DD17
  }

  .sm\:to-teal-50 {
    --tw-gradient-to: #E0F2F1
  }

  .sm\:to-teal-100 {
    --tw-gradient-to: #B2DFDB
  }

  .sm\:to-teal-200 {
    --tw-gradient-to: #80CBC4
  }

  .sm\:to-teal-300 {
    --tw-gradient-to: #4DB6AC
  }

  .sm\:to-teal-400 {
    --tw-gradient-to: #26A69A
  }

  .sm\:to-teal-500 {
    --tw-gradient-to: #009688
  }

  .sm\:to-teal-600 {
    --tw-gradient-to: #00897B
  }

  .sm\:to-teal-700 {
    --tw-gradient-to: #00796B
  }

  .sm\:to-teal-800 {
    --tw-gradient-to: #00695C
  }

  .sm\:to-teal-900 {
    --tw-gradient-to: #004D40
  }

  .sm\:to-teal {
    --tw-gradient-to: #009688
  }

  .sm\:to-teal-A100 {
    --tw-gradient-to: #A7FFEB
  }

  .sm\:to-teal-A200 {
    --tw-gradient-to: #64FFDA
  }

  .sm\:to-teal-A400 {
    --tw-gradient-to: #1DE9B6
  }

  .sm\:to-teal-A700 {
    --tw-gradient-to: #00BFA5
  }

  .sm\:to-blue-50 {
    --tw-gradient-to: #E3F2FD
  }

  .sm\:to-blue-100 {
    --tw-gradient-to: #BBDEFB
  }

  .sm\:to-blue-200 {
    --tw-gradient-to: #90CAF9
  }

  .sm\:to-blue-300 {
    --tw-gradient-to: #64B5F6
  }

  .sm\:to-blue-400 {
    --tw-gradient-to: #42A5F5
  }

  .sm\:to-blue-500 {
    --tw-gradient-to: #2196F3
  }

  .sm\:to-blue-600 {
    --tw-gradient-to: #1E88E5
  }

  .sm\:to-blue-700 {
    --tw-gradient-to: #1976D2
  }

  .sm\:to-blue-800 {
    --tw-gradient-to: #1565C0
  }

  .sm\:to-blue-900 {
    --tw-gradient-to: #0D47A1
  }

  .sm\:to-blue {
    --tw-gradient-to: #2196F3
  }

  .sm\:to-blue-A100 {
    --tw-gradient-to: #82B1FF
  }

  .sm\:to-blue-A200 {
    --tw-gradient-to: #448AFF
  }

  .sm\:to-blue-A400 {
    --tw-gradient-to: #2979FF
  }

  .sm\:to-blue-A700 {
    --tw-gradient-to: #2962FF
  }

  .sm\:to-light-blue-50 {
    --tw-gradient-to: #E1F5FE
  }

  .sm\:to-light-blue-100 {
    --tw-gradient-to: #B3E5FC
  }

  .sm\:to-light-blue-200 {
    --tw-gradient-to: #81D4FA
  }

  .sm\:to-light-blue-300 {
    --tw-gradient-to: #4FC3F7
  }

  .sm\:to-light-blue-400 {
    --tw-gradient-to: #29B6F6
  }

  .sm\:to-light-blue-500 {
    --tw-gradient-to: #03A9F4
  }

  .sm\:to-light-blue-600 {
    --tw-gradient-to: #039BE5
  }

  .sm\:to-light-blue-700 {
    --tw-gradient-to: #0288D1
  }

  .sm\:to-light-blue-800 {
    --tw-gradient-to: #0277BD
  }

  .sm\:to-light-blue-900 {
    --tw-gradient-to: #01579B
  }

  .sm\:to-light-blue {
    --tw-gradient-to: #03A9F4
  }

  .sm\:to-light-blue-A100 {
    --tw-gradient-to: #80D8FF
  }

  .sm\:to-light-blue-A200 {
    --tw-gradient-to: #40C4FF
  }

  .sm\:to-light-blue-A400 {
    --tw-gradient-to: #00B0FF
  }

  .sm\:to-light-blue-A700 {
    --tw-gradient-to: #0091EA
  }

  .sm\:to-indigo-50 {
    --tw-gradient-to: #E8EAF6
  }

  .sm\:to-indigo-100 {
    --tw-gradient-to: #C5CAE9
  }

  .sm\:to-indigo-200 {
    --tw-gradient-to: #9FA8DA
  }

  .sm\:to-indigo-300 {
    --tw-gradient-to: #7986CB
  }

  .sm\:to-indigo-400 {
    --tw-gradient-to: #5C6BC0
  }

  .sm\:to-indigo-500 {
    --tw-gradient-to: #3F51B5
  }

  .sm\:to-indigo-600 {
    --tw-gradient-to: #3949AB
  }

  .sm\:to-indigo-700 {
    --tw-gradient-to: #303F9F
  }

  .sm\:to-indigo-800 {
    --tw-gradient-to: #283593
  }

  .sm\:to-indigo-900 {
    --tw-gradient-to: #1A237E
  }

  .sm\:to-indigo {
    --tw-gradient-to: #3F51B5
  }

  .sm\:to-indigo-A100 {
    --tw-gradient-to: #8C9EFF
  }

  .sm\:to-indigo-A200 {
    --tw-gradient-to: #536DFE
  }

  .sm\:to-indigo-A400 {
    --tw-gradient-to: #3D5AFE
  }

  .sm\:to-indigo-A700 {
    --tw-gradient-to: #304FFE
  }

  .sm\:to-purple-50 {
    --tw-gradient-to: #F3E5F5
  }

  .sm\:to-purple-100 {
    --tw-gradient-to: #E1BEE7
  }

  .sm\:to-purple-200 {
    --tw-gradient-to: #CE93D8
  }

  .sm\:to-purple-300 {
    --tw-gradient-to: #BA68C8
  }

  .sm\:to-purple-400 {
    --tw-gradient-to: #AB47BC
  }

  .sm\:to-purple-500 {
    --tw-gradient-to: #9C27B0
  }

  .sm\:to-purple-600 {
    --tw-gradient-to: #8E24AA
  }

  .sm\:to-purple-700 {
    --tw-gradient-to: #7B1FA2
  }

  .sm\:to-purple-800 {
    --tw-gradient-to: #6A1B9A
  }

  .sm\:to-purple-900 {
    --tw-gradient-to: #4A148C
  }

  .sm\:to-purple {
    --tw-gradient-to: #9C27B0
  }

  .sm\:to-purple-A100 {
    --tw-gradient-to: #EA80FC
  }

  .sm\:to-purple-A200 {
    --tw-gradient-to: #E040FB
  }

  .sm\:to-purple-A400 {
    --tw-gradient-to: #D500F9
  }

  .sm\:to-purple-A700 {
    --tw-gradient-to: #AA00FF
  }

  .sm\:to-deep-purple-50 {
    --tw-gradient-to: #EDE7F6
  }

  .sm\:to-deep-purple-100 {
    --tw-gradient-to: #D1C4E9
  }

  .sm\:to-deep-purple-200 {
    --tw-gradient-to: #B39DDB
  }

  .sm\:to-deep-purple-300 {
    --tw-gradient-to: #9575CD
  }

  .sm\:to-deep-purple-400 {
    --tw-gradient-to: #7E57C2
  }

  .sm\:to-deep-purple-500 {
    --tw-gradient-to: #673AB7
  }

  .sm\:to-deep-purple-600 {
    --tw-gradient-to: #5E35B1
  }

  .sm\:to-deep-purple-700 {
    --tw-gradient-to: #512DA8
  }

  .sm\:to-deep-purple-800 {
    --tw-gradient-to: #4527A0
  }

  .sm\:to-deep-purple-900 {
    --tw-gradient-to: #311B92
  }

  .sm\:to-deep-purple {
    --tw-gradient-to: #673AB7
  }

  .sm\:to-deep-purple-A100 {
    --tw-gradient-to: #B388FF
  }

  .sm\:to-deep-purple-A200 {
    --tw-gradient-to: #7C4DFF
  }

  .sm\:to-deep-purple-A400 {
    --tw-gradient-to: #651FFF
  }

  .sm\:to-deep-purple-A700 {
    --tw-gradient-to: #6200EA
  }

  .sm\:to-pink-50 {
    --tw-gradient-to: #FCE4EC
  }

  .sm\:to-pink-100 {
    --tw-gradient-to: #F8BBD0
  }

  .sm\:to-pink-200 {
    --tw-gradient-to: #F48FB1
  }

  .sm\:to-pink-300 {
    --tw-gradient-to: #F06292
  }

  .sm\:to-pink-400 {
    --tw-gradient-to: #EC407A
  }

  .sm\:to-pink-500 {
    --tw-gradient-to: #E91E63
  }

  .sm\:to-pink-600 {
    --tw-gradient-to: #D81B60
  }

  .sm\:to-pink-700 {
    --tw-gradient-to: #C2185B
  }

  .sm\:to-pink-800 {
    --tw-gradient-to: #AD1457
  }

  .sm\:to-pink-900 {
    --tw-gradient-to: #880E4F
  }

  .sm\:to-pink {
    --tw-gradient-to: #E91E63
  }

  .sm\:to-pink-A100 {
    --tw-gradient-to: #FF80AB
  }

  .sm\:to-pink-A200 {
    --tw-gradient-to: #FF4081
  }

  .sm\:to-pink-A400 {
    --tw-gradient-to: #F50057
  }

  .sm\:to-pink-A700 {
    --tw-gradient-to: #C51162
  }

  .sm\:to-lime-50 {
    --tw-gradient-to: #F9FBE7
  }

  .sm\:to-lime-100 {
    --tw-gradient-to: #F0F4C3
  }

  .sm\:to-lime-200 {
    --tw-gradient-to: #E6EE9C
  }

  .sm\:to-lime-300 {
    --tw-gradient-to: #DCE775
  }

  .sm\:to-lime-400 {
    --tw-gradient-to: #D4E157
  }

  .sm\:to-lime-500 {
    --tw-gradient-to: #CDDC39
  }

  .sm\:to-lime-600 {
    --tw-gradient-to: #C0CA33
  }

  .sm\:to-lime-700 {
    --tw-gradient-to: #AFB42B
  }

  .sm\:to-lime-800 {
    --tw-gradient-to: #9E9D24
  }

  .sm\:to-lime-900 {
    --tw-gradient-to: #827717
  }

  .sm\:to-lime {
    --tw-gradient-to: #CDDC39
  }

  .sm\:to-lime-A100 {
    --tw-gradient-to: #F4FF81
  }

  .sm\:to-lime-A200 {
    --tw-gradient-to: #EEFF41
  }

  .sm\:to-lime-A400 {
    --tw-gradient-to: #C6FF00
  }

  .sm\:to-lime-A700 {
    --tw-gradient-to: #AEEA00
  }

  .sm\:to-amber-50 {
    --tw-gradient-to: #FFF8E1
  }

  .sm\:to-amber-100 {
    --tw-gradient-to: #FFECB3
  }

  .sm\:to-amber-200 {
    --tw-gradient-to: #FFE082
  }

  .sm\:to-amber-300 {
    --tw-gradient-to: #FFD54F
  }

  .sm\:to-amber-400 {
    --tw-gradient-to: #FFCA28
  }

  .sm\:to-amber-500 {
    --tw-gradient-to: #FFC107
  }

  .sm\:to-amber-600 {
    --tw-gradient-to: #FFB300
  }

  .sm\:to-amber-700 {
    --tw-gradient-to: #FFA000
  }

  .sm\:to-amber-800 {
    --tw-gradient-to: #FF8F00
  }

  .sm\:to-amber-900 {
    --tw-gradient-to: #FF6F00
  }

  .sm\:to-amber {
    --tw-gradient-to: #FFC107
  }

  .sm\:to-amber-A100 {
    --tw-gradient-to: #FFE57F
  }

  .sm\:to-amber-A200 {
    --tw-gradient-to: #FFD740
  }

  .sm\:to-amber-A400 {
    --tw-gradient-to: #FFC400
  }

  .sm\:to-amber-A700 {
    --tw-gradient-to: #FFAB00
  }

  .sm\:to-brown-50 {
    --tw-gradient-to: #EFEBE9
  }

  .sm\:to-brown-100 {
    --tw-gradient-to: #D7CCC8
  }

  .sm\:to-brown-200 {
    --tw-gradient-to: #BCAAA4
  }

  .sm\:to-brown-300 {
    --tw-gradient-to: #A1887F
  }

  .sm\:to-brown-400 {
    --tw-gradient-to: #8D6E63
  }

  .sm\:to-brown-500 {
    --tw-gradient-to: #795548
  }

  .sm\:to-brown-600 {
    --tw-gradient-to: #6D4C41
  }

  .sm\:to-brown-700 {
    --tw-gradient-to: #5D4037
  }

  .sm\:to-brown-800 {
    --tw-gradient-to: #4E342E
  }

  .sm\:to-brown-900 {
    --tw-gradient-to: #3E2723
  }

  .sm\:to-brown {
    --tw-gradient-to: #795548
  }

  .sm\:to-brown-A100 {
    --tw-gradient-to: #D7CCC8
  }

  .sm\:to-brown-A200 {
    --tw-gradient-to: #BCAAA4
  }

  .sm\:to-brown-A400 {
    --tw-gradient-to: #8D6E63
  }

  .sm\:to-brown-A700 {
    --tw-gradient-to: #5D4037
  }

  .sm\:to-blue-gray-50 {
    --tw-gradient-to: #ECEFF1
  }

  .sm\:to-blue-gray-100 {
    --tw-gradient-to: #CFD8DC
  }

  .sm\:to-blue-gray-200 {
    --tw-gradient-to: #B0BEC5
  }

  .sm\:to-blue-gray-300 {
    --tw-gradient-to: #90A4AE
  }

  .sm\:to-blue-gray-400 {
    --tw-gradient-to: #78909C
  }

  .sm\:to-blue-gray-500 {
    --tw-gradient-to: #607D8B
  }

  .sm\:to-blue-gray-600 {
    --tw-gradient-to: #546E7A
  }

  .sm\:to-blue-gray-700 {
    --tw-gradient-to: #455A64
  }

  .sm\:to-blue-gray-800 {
    --tw-gradient-to: #37474F
  }

  .sm\:to-blue-gray-900 {
    --tw-gradient-to: #263238
  }

  .sm\:to-blue-gray {
    --tw-gradient-to: #607D8B
  }

  .sm\:to-blue-gray-A100 {
    --tw-gradient-to: #CFD8DC
  }

  .sm\:to-blue-gray-A200 {
    --tw-gradient-to: #B0BEC5
  }

  .sm\:to-blue-gray-A400 {
    --tw-gradient-to: #78909C
  }

  .sm\:to-blue-gray-A700 {
    --tw-gradient-to: #455A64
  }

  .sm\:to-cyan-50 {
    --tw-gradient-to: #E0F7FA
  }

  .sm\:to-cyan-100 {
    --tw-gradient-to: #B2EBF2
  }

  .sm\:to-cyan-200 {
    --tw-gradient-to: #80DEEA
  }

  .sm\:to-cyan-300 {
    --tw-gradient-to: #4DD0E1
  }

  .sm\:to-cyan-400 {
    --tw-gradient-to: #26C6DA
  }

  .sm\:to-cyan-500 {
    --tw-gradient-to: #00BCD4
  }

  .sm\:to-cyan-600 {
    --tw-gradient-to: #00ACC1
  }

  .sm\:to-cyan-700 {
    --tw-gradient-to: #0097A7
  }

  .sm\:to-cyan-800 {
    --tw-gradient-to: #00838F
  }

  .sm\:to-cyan-900 {
    --tw-gradient-to: #006064
  }

  .sm\:to-cyan {
    --tw-gradient-to: #00BCD4
  }

  .sm\:to-cyan-A100 {
    --tw-gradient-to: #84FFFF
  }

  .sm\:to-cyan-A200 {
    --tw-gradient-to: #18FFFF
  }

  .sm\:to-cyan-A400 {
    --tw-gradient-to: #00E5FF
  }

  .sm\:to-cyan-A700 {
    --tw-gradient-to: #00B8D4
  }

  .sm\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-black:hover {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .sm\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-grey-50:hover {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .sm\:hover\:from-grey-100:hover {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .sm\:hover\:from-grey-200:hover {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .sm\:hover\:from-grey-300:hover {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .sm\:hover\:from-grey-400:hover {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .sm\:hover\:from-grey-500:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:hover\:from-grey-600:hover {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .sm\:hover\:from-grey-700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:hover\:from-grey-800:hover {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .sm\:hover\:from-grey-900:hover {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .sm\:hover\:from-grey:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:hover\:from-grey-A100:hover {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .sm\:hover\:from-grey-A200:hover {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .sm\:hover\:from-grey-A400:hover {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .sm\:hover\:from-grey-A700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:hover\:from-gray-50:hover {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .sm\:hover\:from-gray-100:hover {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .sm\:hover\:from-gray-200:hover {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .sm\:hover\:from-gray-300:hover {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .sm\:hover\:from-gray-400:hover {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .sm\:hover\:from-gray-500:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:hover\:from-gray-600:hover {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .sm\:hover\:from-gray-700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:hover\:from-gray-800:hover {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .sm\:hover\:from-gray-900:hover {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .sm\:hover\:from-gray:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:hover\:from-gray-hover:hover {
    --tw-gradient-from: rgba(0, 0, 0, 0.04);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:from-gray-A100:hover {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .sm\:hover\:from-gray-A200:hover {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .sm\:hover\:from-gray-A400:hover {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .sm\:hover\:from-gray-A700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:hover\:from-red-50:hover {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .sm\:hover\:from-red-100:hover {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .sm\:hover\:from-red-200:hover {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .sm\:hover\:from-red-300:hover {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .sm\:hover\:from-red-400:hover {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .sm\:hover\:from-red-500:hover {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .sm\:hover\:from-red-600:hover {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .sm\:hover\:from-red-700:hover {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .sm\:hover\:from-red-800:hover {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .sm\:hover\:from-red-900:hover {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .sm\:hover\:from-red:hover {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .sm\:hover\:from-red-A100:hover {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .sm\:hover\:from-red-A200:hover {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .sm\:hover\:from-red-A400:hover {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .sm\:hover\:from-red-A700:hover {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .sm\:hover\:from-orange-50:hover {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .sm\:hover\:from-orange-100:hover {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .sm\:hover\:from-orange-200:hover {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .sm\:hover\:from-orange-300:hover {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .sm\:hover\:from-orange-400:hover {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .sm\:hover\:from-orange-500:hover {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .sm\:hover\:from-orange-600:hover {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .sm\:hover\:from-orange-700:hover {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .sm\:hover\:from-orange-800:hover {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .sm\:hover\:from-orange-900:hover {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .sm\:hover\:from-orange:hover {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .sm\:hover\:from-orange-A100:hover {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .sm\:hover\:from-orange-A200:hover {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .sm\:hover\:from-orange-A400:hover {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .sm\:hover\:from-orange-A700:hover {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .sm\:hover\:from-deep-orange-50:hover {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .sm\:hover\:from-deep-orange-100:hover {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .sm\:hover\:from-deep-orange-200:hover {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .sm\:hover\:from-deep-orange-300:hover {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .sm\:hover\:from-deep-orange-400:hover {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .sm\:hover\:from-deep-orange-500:hover {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .sm\:hover\:from-deep-orange-600:hover {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .sm\:hover\:from-deep-orange-700:hover {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .sm\:hover\:from-deep-orange-800:hover {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .sm\:hover\:from-deep-orange-900:hover {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .sm\:hover\:from-deep-orange:hover {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .sm\:hover\:from-deep-orange-A100:hover {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .sm\:hover\:from-deep-orange-A200:hover {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .sm\:hover\:from-deep-orange-A400:hover {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .sm\:hover\:from-deep-orange-A700:hover {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .sm\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .sm\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .sm\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .sm\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .sm\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .sm\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .sm\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .sm\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .sm\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .sm\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .sm\:hover\:from-yellow:hover {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .sm\:hover\:from-yellow-A100:hover {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .sm\:hover\:from-yellow-A200:hover {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .sm\:hover\:from-yellow-A400:hover {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .sm\:hover\:from-yellow-A700:hover {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .sm\:hover\:from-green-50:hover {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .sm\:hover\:from-green-100:hover {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .sm\:hover\:from-green-200:hover {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .sm\:hover\:from-green-300:hover {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .sm\:hover\:from-green-400:hover {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .sm\:hover\:from-green-500:hover {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .sm\:hover\:from-green-600:hover {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .sm\:hover\:from-green-700:hover {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .sm\:hover\:from-green-800:hover {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .sm\:hover\:from-green-900:hover {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .sm\:hover\:from-green:hover {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .sm\:hover\:from-green-A100:hover {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .sm\:hover\:from-green-A200:hover {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .sm\:hover\:from-green-A400:hover {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .sm\:hover\:from-green-A700:hover {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .sm\:hover\:from-light-green-50:hover {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .sm\:hover\:from-light-green-100:hover {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .sm\:hover\:from-light-green-200:hover {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .sm\:hover\:from-light-green-300:hover {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .sm\:hover\:from-light-green-400:hover {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .sm\:hover\:from-light-green-500:hover {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .sm\:hover\:from-light-green-600:hover {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .sm\:hover\:from-light-green-700:hover {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .sm\:hover\:from-light-green-800:hover {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .sm\:hover\:from-light-green-900:hover {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .sm\:hover\:from-light-green:hover {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .sm\:hover\:from-light-green-A100:hover {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .sm\:hover\:from-light-green-A200:hover {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .sm\:hover\:from-light-green-A400:hover {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .sm\:hover\:from-light-green-A700:hover {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .sm\:hover\:from-teal-50:hover {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .sm\:hover\:from-teal-100:hover {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .sm\:hover\:from-teal-200:hover {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .sm\:hover\:from-teal-300:hover {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .sm\:hover\:from-teal-400:hover {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .sm\:hover\:from-teal-500:hover {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .sm\:hover\:from-teal-600:hover {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .sm\:hover\:from-teal-700:hover {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .sm\:hover\:from-teal-800:hover {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .sm\:hover\:from-teal-900:hover {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .sm\:hover\:from-teal:hover {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .sm\:hover\:from-teal-A100:hover {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .sm\:hover\:from-teal-A200:hover {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .sm\:hover\:from-teal-A400:hover {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .sm\:hover\:from-teal-A700:hover {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .sm\:hover\:from-blue-50:hover {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .sm\:hover\:from-blue-100:hover {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .sm\:hover\:from-blue-200:hover {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .sm\:hover\:from-blue-300:hover {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .sm\:hover\:from-blue-400:hover {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .sm\:hover\:from-blue-500:hover {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .sm\:hover\:from-blue-600:hover {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .sm\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .sm\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .sm\:hover\:from-blue-900:hover {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .sm\:hover\:from-blue:hover {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .sm\:hover\:from-blue-A100:hover {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .sm\:hover\:from-blue-A200:hover {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .sm\:hover\:from-blue-A400:hover {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .sm\:hover\:from-blue-A700:hover {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .sm\:hover\:from-light-blue-50:hover {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .sm\:hover\:from-light-blue-100:hover {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .sm\:hover\:from-light-blue-200:hover {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .sm\:hover\:from-light-blue-300:hover {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .sm\:hover\:from-light-blue-400:hover {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .sm\:hover\:from-light-blue-500:hover {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .sm\:hover\:from-light-blue-600:hover {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .sm\:hover\:from-light-blue-700:hover {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .sm\:hover\:from-light-blue-800:hover {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .sm\:hover\:from-light-blue-900:hover {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .sm\:hover\:from-light-blue:hover {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .sm\:hover\:from-light-blue-A100:hover {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .sm\:hover\:from-light-blue-A200:hover {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .sm\:hover\:from-light-blue-A400:hover {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .sm\:hover\:from-light-blue-A700:hover {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .sm\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .sm\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .sm\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .sm\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .sm\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .sm\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .sm\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .sm\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .sm\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .sm\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .sm\:hover\:from-indigo:hover {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .sm\:hover\:from-indigo-A100:hover {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .sm\:hover\:from-indigo-A200:hover {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .sm\:hover\:from-indigo-A400:hover {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .sm\:hover\:from-indigo-A700:hover {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .sm\:hover\:from-purple-50:hover {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .sm\:hover\:from-purple-100:hover {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .sm\:hover\:from-purple-200:hover {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .sm\:hover\:from-purple-300:hover {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .sm\:hover\:from-purple-400:hover {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .sm\:hover\:from-purple-500:hover {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .sm\:hover\:from-purple-600:hover {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .sm\:hover\:from-purple-700:hover {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .sm\:hover\:from-purple-800:hover {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .sm\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .sm\:hover\:from-purple:hover {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .sm\:hover\:from-purple-A100:hover {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .sm\:hover\:from-purple-A200:hover {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .sm\:hover\:from-purple-A400:hover {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .sm\:hover\:from-purple-A700:hover {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .sm\:hover\:from-deep-purple-50:hover {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .sm\:hover\:from-deep-purple-100:hover {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .sm\:hover\:from-deep-purple-200:hover {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .sm\:hover\:from-deep-purple-300:hover {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .sm\:hover\:from-deep-purple-400:hover {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .sm\:hover\:from-deep-purple-500:hover {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .sm\:hover\:from-deep-purple-600:hover {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .sm\:hover\:from-deep-purple-700:hover {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .sm\:hover\:from-deep-purple-800:hover {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .sm\:hover\:from-deep-purple-900:hover {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .sm\:hover\:from-deep-purple:hover {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .sm\:hover\:from-deep-purple-A100:hover {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .sm\:hover\:from-deep-purple-A200:hover {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .sm\:hover\:from-deep-purple-A400:hover {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .sm\:hover\:from-deep-purple-A700:hover {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .sm\:hover\:from-pink-50:hover {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .sm\:hover\:from-pink-100:hover {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .sm\:hover\:from-pink-200:hover {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .sm\:hover\:from-pink-300:hover {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .sm\:hover\:from-pink-400:hover {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .sm\:hover\:from-pink-500:hover {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .sm\:hover\:from-pink-600:hover {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .sm\:hover\:from-pink-700:hover {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .sm\:hover\:from-pink-800:hover {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .sm\:hover\:from-pink-900:hover {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .sm\:hover\:from-pink:hover {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .sm\:hover\:from-pink-A100:hover {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .sm\:hover\:from-pink-A200:hover {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .sm\:hover\:from-pink-A400:hover {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .sm\:hover\:from-pink-A700:hover {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .sm\:hover\:from-lime-50:hover {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .sm\:hover\:from-lime-100:hover {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .sm\:hover\:from-lime-200:hover {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .sm\:hover\:from-lime-300:hover {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .sm\:hover\:from-lime-400:hover {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .sm\:hover\:from-lime-500:hover {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .sm\:hover\:from-lime-600:hover {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .sm\:hover\:from-lime-700:hover {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .sm\:hover\:from-lime-800:hover {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .sm\:hover\:from-lime-900:hover {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .sm\:hover\:from-lime:hover {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .sm\:hover\:from-lime-A100:hover {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .sm\:hover\:from-lime-A200:hover {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .sm\:hover\:from-lime-A400:hover {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .sm\:hover\:from-lime-A700:hover {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .sm\:hover\:from-amber-50:hover {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .sm\:hover\:from-amber-100:hover {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .sm\:hover\:from-amber-200:hover {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .sm\:hover\:from-amber-300:hover {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .sm\:hover\:from-amber-400:hover {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .sm\:hover\:from-amber-500:hover {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .sm\:hover\:from-amber-600:hover {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .sm\:hover\:from-amber-700:hover {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .sm\:hover\:from-amber-800:hover {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .sm\:hover\:from-amber-900:hover {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .sm\:hover\:from-amber:hover {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .sm\:hover\:from-amber-A100:hover {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .sm\:hover\:from-amber-A200:hover {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .sm\:hover\:from-amber-A400:hover {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .sm\:hover\:from-amber-A700:hover {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .sm\:hover\:from-brown-50:hover {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .sm\:hover\:from-brown-100:hover {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .sm\:hover\:from-brown-200:hover {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .sm\:hover\:from-brown-300:hover {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .sm\:hover\:from-brown-400:hover {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .sm\:hover\:from-brown-500:hover {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .sm\:hover\:from-brown-600:hover {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .sm\:hover\:from-brown-700:hover {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .sm\:hover\:from-brown-800:hover {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .sm\:hover\:from-brown-900:hover {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .sm\:hover\:from-brown:hover {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .sm\:hover\:from-brown-A100:hover {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .sm\:hover\:from-brown-A200:hover {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .sm\:hover\:from-brown-A400:hover {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .sm\:hover\:from-brown-A700:hover {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .sm\:hover\:from-blue-gray-50:hover {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .sm\:hover\:from-blue-gray-100:hover {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .sm\:hover\:from-blue-gray-200:hover {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .sm\:hover\:from-blue-gray-300:hover {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .sm\:hover\:from-blue-gray-400:hover {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .sm\:hover\:from-blue-gray-500:hover {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .sm\:hover\:from-blue-gray-600:hover {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .sm\:hover\:from-blue-gray-700:hover {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .sm\:hover\:from-blue-gray-800:hover {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .sm\:hover\:from-blue-gray-900:hover {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .sm\:hover\:from-blue-gray:hover {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .sm\:hover\:from-blue-gray-A100:hover {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .sm\:hover\:from-blue-gray-A200:hover {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .sm\:hover\:from-blue-gray-A400:hover {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .sm\:hover\:from-blue-gray-A700:hover {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .sm\:hover\:from-cyan-50:hover {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .sm\:hover\:from-cyan-100:hover {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .sm\:hover\:from-cyan-200:hover {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .sm\:hover\:from-cyan-300:hover {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .sm\:hover\:from-cyan-400:hover {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .sm\:hover\:from-cyan-500:hover {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .sm\:hover\:from-cyan-600:hover {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .sm\:hover\:from-cyan-700:hover {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .sm\:hover\:from-cyan-800:hover {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .sm\:hover\:from-cyan-900:hover {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .sm\:hover\:from-cyan:hover {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .sm\:hover\:from-cyan-A100:hover {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .sm\:hover\:from-cyan-A200:hover {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .sm\:hover\:from-cyan-A400:hover {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .sm\:hover\:from-cyan-A700:hover {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .sm\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .sm\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-grey-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .sm\:hover\:via-grey-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .sm\:hover\:via-grey-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .sm\:hover\:via-grey-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .sm\:hover\:via-grey-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .sm\:hover\:via-grey-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:hover\:via-grey-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .sm\:hover\:via-grey-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:hover\:via-grey-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .sm\:hover\:via-grey-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .sm\:hover\:via-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:hover\:via-grey-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .sm\:hover\:via-grey-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .sm\:hover\:via-grey-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .sm\:hover\:via-grey-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .sm\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .sm\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .sm\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .sm\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .sm\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .sm\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .sm\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .sm\:hover\:via-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:hover\:via-gray-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:via-gray-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .sm\:hover\:via-gray-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .sm\:hover\:via-gray-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .sm\:hover\:via-gray-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .sm\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .sm\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .sm\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .sm\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .sm\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .sm\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .sm\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .sm\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .sm\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .sm\:hover\:via-red:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .sm\:hover\:via-red-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .sm\:hover\:via-red-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .sm\:hover\:via-red-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .sm\:hover\:via-red-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .sm\:hover\:via-orange-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .sm\:hover\:via-orange-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .sm\:hover\:via-orange-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .sm\:hover\:via-orange-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .sm\:hover\:via-orange-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .sm\:hover\:via-orange-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .sm\:hover\:via-orange-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .sm\:hover\:via-orange-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .sm\:hover\:via-orange-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .sm\:hover\:via-orange-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .sm\:hover\:via-orange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .sm\:hover\:via-orange-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .sm\:hover\:via-orange-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .sm\:hover\:via-orange-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .sm\:hover\:via-orange-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .sm\:hover\:via-deep-orange-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .sm\:hover\:via-deep-orange-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .sm\:hover\:via-deep-orange-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .sm\:hover\:via-deep-orange-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .sm\:hover\:via-deep-orange-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .sm\:hover\:via-deep-orange-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .sm\:hover\:via-deep-orange-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .sm\:hover\:via-deep-orange-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .sm\:hover\:via-deep-orange-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .sm\:hover\:via-deep-orange-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .sm\:hover\:via-deep-orange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .sm\:hover\:via-deep-orange-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .sm\:hover\:via-deep-orange-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .sm\:hover\:via-deep-orange-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .sm\:hover\:via-deep-orange-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .sm\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .sm\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .sm\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .sm\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .sm\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .sm\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .sm\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .sm\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .sm\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .sm\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .sm\:hover\:via-yellow:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .sm\:hover\:via-yellow-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .sm\:hover\:via-yellow-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .sm\:hover\:via-yellow-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .sm\:hover\:via-yellow-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .sm\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .sm\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .sm\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .sm\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .sm\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .sm\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .sm\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .sm\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .sm\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .sm\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .sm\:hover\:via-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .sm\:hover\:via-green-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .sm\:hover\:via-green-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .sm\:hover\:via-green-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .sm\:hover\:via-green-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .sm\:hover\:via-light-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .sm\:hover\:via-light-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .sm\:hover\:via-light-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .sm\:hover\:via-light-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .sm\:hover\:via-light-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .sm\:hover\:via-light-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .sm\:hover\:via-light-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .sm\:hover\:via-light-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .sm\:hover\:via-light-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .sm\:hover\:via-light-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .sm\:hover\:via-light-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .sm\:hover\:via-light-green-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .sm\:hover\:via-light-green-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .sm\:hover\:via-light-green-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .sm\:hover\:via-light-green-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .sm\:hover\:via-teal-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .sm\:hover\:via-teal-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .sm\:hover\:via-teal-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .sm\:hover\:via-teal-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .sm\:hover\:via-teal-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .sm\:hover\:via-teal-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .sm\:hover\:via-teal-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .sm\:hover\:via-teal-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .sm\:hover\:via-teal-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .sm\:hover\:via-teal-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .sm\:hover\:via-teal:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .sm\:hover\:via-teal-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .sm\:hover\:via-teal-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .sm\:hover\:via-teal-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .sm\:hover\:via-teal-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .sm\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .sm\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .sm\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .sm\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .sm\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .sm\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .sm\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .sm\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .sm\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .sm\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .sm\:hover\:via-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .sm\:hover\:via-blue-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .sm\:hover\:via-blue-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .sm\:hover\:via-blue-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .sm\:hover\:via-blue-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .sm\:hover\:via-light-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .sm\:hover\:via-light-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .sm\:hover\:via-light-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .sm\:hover\:via-light-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .sm\:hover\:via-light-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .sm\:hover\:via-light-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .sm\:hover\:via-light-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .sm\:hover\:via-light-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .sm\:hover\:via-light-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .sm\:hover\:via-light-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .sm\:hover\:via-light-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .sm\:hover\:via-light-blue-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .sm\:hover\:via-light-blue-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .sm\:hover\:via-light-blue-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .sm\:hover\:via-light-blue-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .sm\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .sm\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .sm\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .sm\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .sm\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .sm\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .sm\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .sm\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .sm\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .sm\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .sm\:hover\:via-indigo:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .sm\:hover\:via-indigo-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .sm\:hover\:via-indigo-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .sm\:hover\:via-indigo-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .sm\:hover\:via-indigo-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .sm\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .sm\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .sm\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .sm\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .sm\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .sm\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .sm\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .sm\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .sm\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .sm\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .sm\:hover\:via-purple:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .sm\:hover\:via-purple-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .sm\:hover\:via-purple-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .sm\:hover\:via-purple-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .sm\:hover\:via-purple-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .sm\:hover\:via-deep-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .sm\:hover\:via-deep-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .sm\:hover\:via-deep-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .sm\:hover\:via-deep-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .sm\:hover\:via-deep-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .sm\:hover\:via-deep-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .sm\:hover\:via-deep-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .sm\:hover\:via-deep-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .sm\:hover\:via-deep-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .sm\:hover\:via-deep-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .sm\:hover\:via-deep-purple:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .sm\:hover\:via-deep-purple-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .sm\:hover\:via-deep-purple-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .sm\:hover\:via-deep-purple-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .sm\:hover\:via-deep-purple-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .sm\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .sm\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .sm\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .sm\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .sm\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .sm\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .sm\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .sm\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .sm\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .sm\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .sm\:hover\:via-pink:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .sm\:hover\:via-pink-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .sm\:hover\:via-pink-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .sm\:hover\:via-pink-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .sm\:hover\:via-pink-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .sm\:hover\:via-lime-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .sm\:hover\:via-lime-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .sm\:hover\:via-lime-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .sm\:hover\:via-lime-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .sm\:hover\:via-lime-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .sm\:hover\:via-lime-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .sm\:hover\:via-lime-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .sm\:hover\:via-lime-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .sm\:hover\:via-lime-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .sm\:hover\:via-lime-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .sm\:hover\:via-lime:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .sm\:hover\:via-lime-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .sm\:hover\:via-lime-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .sm\:hover\:via-lime-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .sm\:hover\:via-lime-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .sm\:hover\:via-amber-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .sm\:hover\:via-amber-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .sm\:hover\:via-amber-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .sm\:hover\:via-amber-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .sm\:hover\:via-amber-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .sm\:hover\:via-amber-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .sm\:hover\:via-amber-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .sm\:hover\:via-amber-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .sm\:hover\:via-amber-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .sm\:hover\:via-amber-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .sm\:hover\:via-amber:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .sm\:hover\:via-amber-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .sm\:hover\:via-amber-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .sm\:hover\:via-amber-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .sm\:hover\:via-amber-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .sm\:hover\:via-brown-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .sm\:hover\:via-brown-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .sm\:hover\:via-brown-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .sm\:hover\:via-brown-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .sm\:hover\:via-brown-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .sm\:hover\:via-brown-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .sm\:hover\:via-brown-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .sm\:hover\:via-brown-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .sm\:hover\:via-brown-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .sm\:hover\:via-brown-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .sm\:hover\:via-brown:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .sm\:hover\:via-brown-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .sm\:hover\:via-brown-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .sm\:hover\:via-brown-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .sm\:hover\:via-brown-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .sm\:hover\:via-blue-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .sm\:hover\:via-blue-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .sm\:hover\:via-blue-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .sm\:hover\:via-blue-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .sm\:hover\:via-blue-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .sm\:hover\:via-blue-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .sm\:hover\:via-blue-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .sm\:hover\:via-blue-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .sm\:hover\:via-blue-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .sm\:hover\:via-blue-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .sm\:hover\:via-blue-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .sm\:hover\:via-blue-gray-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .sm\:hover\:via-blue-gray-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .sm\:hover\:via-blue-gray-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .sm\:hover\:via-blue-gray-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .sm\:hover\:via-cyan-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .sm\:hover\:via-cyan-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .sm\:hover\:via-cyan-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .sm\:hover\:via-cyan-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .sm\:hover\:via-cyan-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .sm\:hover\:via-cyan-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .sm\:hover\:via-cyan-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .sm\:hover\:via-cyan-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .sm\:hover\:via-cyan-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .sm\:hover\:via-cyan-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .sm\:hover\:via-cyan:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .sm\:hover\:via-cyan-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .sm\:hover\:via-cyan-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .sm\:hover\:via-cyan-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .sm\:hover\:via-cyan-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .sm\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent
  }

  .sm\:hover\:to-current:hover {
    --tw-gradient-to: currentColor
  }

  .sm\:hover\:to-black:hover {
    --tw-gradient-to: #22292F
  }

  .sm\:hover\:to-white:hover {
    --tw-gradient-to: #fff
  }

  .sm\:hover\:to-grey-50:hover {
    --tw-gradient-to: #FAFAFA
  }

  .sm\:hover\:to-grey-100:hover {
    --tw-gradient-to: #F5F5F5
  }

  .sm\:hover\:to-grey-200:hover {
    --tw-gradient-to: #EEEEEE
  }

  .sm\:hover\:to-grey-300:hover {
    --tw-gradient-to: #E0E0E0
  }

  .sm\:hover\:to-grey-400:hover {
    --tw-gradient-to: #BDBDBD
  }

  .sm\:hover\:to-grey-500:hover {
    --tw-gradient-to: #9E9E9E
  }

  .sm\:hover\:to-grey-600:hover {
    --tw-gradient-to: #757575
  }

  .sm\:hover\:to-grey-700:hover {
    --tw-gradient-to: #616161
  }

  .sm\:hover\:to-grey-800:hover {
    --tw-gradient-to: #424242
  }

  .sm\:hover\:to-grey-900:hover {
    --tw-gradient-to: #212121
  }

  .sm\:hover\:to-grey:hover {
    --tw-gradient-to: #9E9E9E
  }

  .sm\:hover\:to-grey-A100:hover {
    --tw-gradient-to: #D5D5D5
  }

  .sm\:hover\:to-grey-A200:hover {
    --tw-gradient-to: #AAAAAA
  }

  .sm\:hover\:to-grey-A400:hover {
    --tw-gradient-to: #303030
  }

  .sm\:hover\:to-grey-A700:hover {
    --tw-gradient-to: #616161
  }

  .sm\:hover\:to-gray-50:hover {
    --tw-gradient-to: #FAFAFA
  }

  .sm\:hover\:to-gray-100:hover {
    --tw-gradient-to: #F5F5F5
  }

  .sm\:hover\:to-gray-200:hover {
    --tw-gradient-to: #EEEEEE
  }

  .sm\:hover\:to-gray-300:hover {
    --tw-gradient-to: #E0E0E0
  }

  .sm\:hover\:to-gray-400:hover {
    --tw-gradient-to: #BDBDBD
  }

  .sm\:hover\:to-gray-500:hover {
    --tw-gradient-to: #9E9E9E
  }

  .sm\:hover\:to-gray-600:hover {
    --tw-gradient-to: #757575
  }

  .sm\:hover\:to-gray-700:hover {
    --tw-gradient-to: #616161
  }

  .sm\:hover\:to-gray-800:hover {
    --tw-gradient-to: #424242
  }

  .sm\:hover\:to-gray-900:hover {
    --tw-gradient-to: #212121
  }

  .sm\:hover\:to-gray:hover {
    --tw-gradient-to: #9E9E9E
  }

  .sm\:hover\:to-gray-hover:hover {
    --tw-gradient-to: rgba(0, 0, 0, 0.04)
  }

  .sm\:hover\:to-gray-A100:hover {
    --tw-gradient-to: #D5D5D5
  }

  .sm\:hover\:to-gray-A200:hover {
    --tw-gradient-to: #AAAAAA
  }

  .sm\:hover\:to-gray-A400:hover {
    --tw-gradient-to: #303030
  }

  .sm\:hover\:to-gray-A700:hover {
    --tw-gradient-to: #616161
  }

  .sm\:hover\:to-red-50:hover {
    --tw-gradient-to: #FFEBEE
  }

  .sm\:hover\:to-red-100:hover {
    --tw-gradient-to: #FFCDD2
  }

  .sm\:hover\:to-red-200:hover {
    --tw-gradient-to: #EF9A9A
  }

  .sm\:hover\:to-red-300:hover {
    --tw-gradient-to: #E57373
  }

  .sm\:hover\:to-red-400:hover {
    --tw-gradient-to: #EF5350
  }

  .sm\:hover\:to-red-500:hover {
    --tw-gradient-to: #F44336
  }

  .sm\:hover\:to-red-600:hover {
    --tw-gradient-to: #E53935
  }

  .sm\:hover\:to-red-700:hover {
    --tw-gradient-to: #D32F2F
  }

  .sm\:hover\:to-red-800:hover {
    --tw-gradient-to: #C62828
  }

  .sm\:hover\:to-red-900:hover {
    --tw-gradient-to: #B71C1C
  }

  .sm\:hover\:to-red:hover {
    --tw-gradient-to: #F44336
  }

  .sm\:hover\:to-red-A100:hover {
    --tw-gradient-to: #FF8A80
  }

  .sm\:hover\:to-red-A200:hover {
    --tw-gradient-to: #FF5252
  }

  .sm\:hover\:to-red-A400:hover {
    --tw-gradient-to: #FF1744
  }

  .sm\:hover\:to-red-A700:hover {
    --tw-gradient-to: #D50000
  }

  .sm\:hover\:to-orange-50:hover {
    --tw-gradient-to: #FFF3E0
  }

  .sm\:hover\:to-orange-100:hover {
    --tw-gradient-to: #FFE0B2
  }

  .sm\:hover\:to-orange-200:hover {
    --tw-gradient-to: #FFCC80
  }

  .sm\:hover\:to-orange-300:hover {
    --tw-gradient-to: #FFB74D
  }

  .sm\:hover\:to-orange-400:hover {
    --tw-gradient-to: #FFA726
  }

  .sm\:hover\:to-orange-500:hover {
    --tw-gradient-to: #FF9800
  }

  .sm\:hover\:to-orange-600:hover {
    --tw-gradient-to: #FB8C00
  }

  .sm\:hover\:to-orange-700:hover {
    --tw-gradient-to: #F57C00
  }

  .sm\:hover\:to-orange-800:hover {
    --tw-gradient-to: #EF6C00
  }

  .sm\:hover\:to-orange-900:hover {
    --tw-gradient-to: #E65100
  }

  .sm\:hover\:to-orange:hover {
    --tw-gradient-to: #FF9800
  }

  .sm\:hover\:to-orange-A100:hover {
    --tw-gradient-to: #FFD180
  }

  .sm\:hover\:to-orange-A200:hover {
    --tw-gradient-to: #FFAB40
  }

  .sm\:hover\:to-orange-A400:hover {
    --tw-gradient-to: #FF9100
  }

  .sm\:hover\:to-orange-A700:hover {
    --tw-gradient-to: #FF6D00
  }

  .sm\:hover\:to-deep-orange-50:hover {
    --tw-gradient-to: #FBE9E7
  }

  .sm\:hover\:to-deep-orange-100:hover {
    --tw-gradient-to: #FFCCBC
  }

  .sm\:hover\:to-deep-orange-200:hover {
    --tw-gradient-to: #FFAB91
  }

  .sm\:hover\:to-deep-orange-300:hover {
    --tw-gradient-to: #FF8A65
  }

  .sm\:hover\:to-deep-orange-400:hover {
    --tw-gradient-to: #FF7043
  }

  .sm\:hover\:to-deep-orange-500:hover {
    --tw-gradient-to: #FF5722
  }

  .sm\:hover\:to-deep-orange-600:hover {
    --tw-gradient-to: #F4511E
  }

  .sm\:hover\:to-deep-orange-700:hover {
    --tw-gradient-to: #E64A19
  }

  .sm\:hover\:to-deep-orange-800:hover {
    --tw-gradient-to: #D84315
  }

  .sm\:hover\:to-deep-orange-900:hover {
    --tw-gradient-to: #BF360C
  }

  .sm\:hover\:to-deep-orange:hover {
    --tw-gradient-to: #FF5722
  }

  .sm\:hover\:to-deep-orange-A100:hover {
    --tw-gradient-to: #FF9E80
  }

  .sm\:hover\:to-deep-orange-A200:hover {
    --tw-gradient-to: #FF6E40
  }

  .sm\:hover\:to-deep-orange-A400:hover {
    --tw-gradient-to: #FF3D00
  }

  .sm\:hover\:to-deep-orange-A700:hover {
    --tw-gradient-to: #DD2C00
  }

  .sm\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #FFFDE7
  }

  .sm\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #FFF9C4
  }

  .sm\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #FFF59D
  }

  .sm\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #FFF176
  }

  .sm\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #FFEE58
  }

  .sm\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #FFEB3B
  }

  .sm\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #FDD835
  }

  .sm\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #FBC02D
  }

  .sm\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #F9A825
  }

  .sm\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #F57F17
  }

  .sm\:hover\:to-yellow:hover {
    --tw-gradient-to: #FFEB3B
  }

  .sm\:hover\:to-yellow-A100:hover {
    --tw-gradient-to: #FFFF8D
  }

  .sm\:hover\:to-yellow-A200:hover {
    --tw-gradient-to: #FFFF00
  }

  .sm\:hover\:to-yellow-A400:hover {
    --tw-gradient-to: #FFEA00
  }

  .sm\:hover\:to-yellow-A700:hover {
    --tw-gradient-to: #FFD600
  }

  .sm\:hover\:to-green-50:hover {
    --tw-gradient-to: #E8F5E9
  }

  .sm\:hover\:to-green-100:hover {
    --tw-gradient-to: #C8E6C9
  }

  .sm\:hover\:to-green-200:hover {
    --tw-gradient-to: #A5D6A7
  }

  .sm\:hover\:to-green-300:hover {
    --tw-gradient-to: #81C784
  }

  .sm\:hover\:to-green-400:hover {
    --tw-gradient-to: #66BB6A
  }

  .sm\:hover\:to-green-500:hover {
    --tw-gradient-to: #4CAF50
  }

  .sm\:hover\:to-green-600:hover {
    --tw-gradient-to: #43A047
  }

  .sm\:hover\:to-green-700:hover {
    --tw-gradient-to: #388E3C
  }

  .sm\:hover\:to-green-800:hover {
    --tw-gradient-to: #2E7D32
  }

  .sm\:hover\:to-green-900:hover {
    --tw-gradient-to: #1B5E20
  }

  .sm\:hover\:to-green:hover {
    --tw-gradient-to: #4CAF50
  }

  .sm\:hover\:to-green-A100:hover {
    --tw-gradient-to: #B9F6CA
  }

  .sm\:hover\:to-green-A200:hover {
    --tw-gradient-to: #69F0AE
  }

  .sm\:hover\:to-green-A400:hover {
    --tw-gradient-to: #00E676
  }

  .sm\:hover\:to-green-A700:hover {
    --tw-gradient-to: #00C853
  }

  .sm\:hover\:to-light-green-50:hover {
    --tw-gradient-to: #F1F8E9
  }

  .sm\:hover\:to-light-green-100:hover {
    --tw-gradient-to: #DCEDC8
  }

  .sm\:hover\:to-light-green-200:hover {
    --tw-gradient-to: #C5E1A5
  }

  .sm\:hover\:to-light-green-300:hover {
    --tw-gradient-to: #AED581
  }

  .sm\:hover\:to-light-green-400:hover {
    --tw-gradient-to: #9CCC65
  }

  .sm\:hover\:to-light-green-500:hover {
    --tw-gradient-to: #8BC34A
  }

  .sm\:hover\:to-light-green-600:hover {
    --tw-gradient-to: #7CB342
  }

  .sm\:hover\:to-light-green-700:hover {
    --tw-gradient-to: #689F38
  }

  .sm\:hover\:to-light-green-800:hover {
    --tw-gradient-to: #558B2F
  }

  .sm\:hover\:to-light-green-900:hover {
    --tw-gradient-to: #33691E
  }

  .sm\:hover\:to-light-green:hover {
    --tw-gradient-to: #8BC34A
  }

  .sm\:hover\:to-light-green-A100:hover {
    --tw-gradient-to: #CCFF90
  }

  .sm\:hover\:to-light-green-A200:hover {
    --tw-gradient-to: #B2FF59
  }

  .sm\:hover\:to-light-green-A400:hover {
    --tw-gradient-to: #76FF03
  }

  .sm\:hover\:to-light-green-A700:hover {
    --tw-gradient-to: #64DD17
  }

  .sm\:hover\:to-teal-50:hover {
    --tw-gradient-to: #E0F2F1
  }

  .sm\:hover\:to-teal-100:hover {
    --tw-gradient-to: #B2DFDB
  }

  .sm\:hover\:to-teal-200:hover {
    --tw-gradient-to: #80CBC4
  }

  .sm\:hover\:to-teal-300:hover {
    --tw-gradient-to: #4DB6AC
  }

  .sm\:hover\:to-teal-400:hover {
    --tw-gradient-to: #26A69A
  }

  .sm\:hover\:to-teal-500:hover {
    --tw-gradient-to: #009688
  }

  .sm\:hover\:to-teal-600:hover {
    --tw-gradient-to: #00897B
  }

  .sm\:hover\:to-teal-700:hover {
    --tw-gradient-to: #00796B
  }

  .sm\:hover\:to-teal-800:hover {
    --tw-gradient-to: #00695C
  }

  .sm\:hover\:to-teal-900:hover {
    --tw-gradient-to: #004D40
  }

  .sm\:hover\:to-teal:hover {
    --tw-gradient-to: #009688
  }

  .sm\:hover\:to-teal-A100:hover {
    --tw-gradient-to: #A7FFEB
  }

  .sm\:hover\:to-teal-A200:hover {
    --tw-gradient-to: #64FFDA
  }

  .sm\:hover\:to-teal-A400:hover {
    --tw-gradient-to: #1DE9B6
  }

  .sm\:hover\:to-teal-A700:hover {
    --tw-gradient-to: #00BFA5
  }

  .sm\:hover\:to-blue-50:hover {
    --tw-gradient-to: #E3F2FD
  }

  .sm\:hover\:to-blue-100:hover {
    --tw-gradient-to: #BBDEFB
  }

  .sm\:hover\:to-blue-200:hover {
    --tw-gradient-to: #90CAF9
  }

  .sm\:hover\:to-blue-300:hover {
    --tw-gradient-to: #64B5F6
  }

  .sm\:hover\:to-blue-400:hover {
    --tw-gradient-to: #42A5F5
  }

  .sm\:hover\:to-blue-500:hover {
    --tw-gradient-to: #2196F3
  }

  .sm\:hover\:to-blue-600:hover {
    --tw-gradient-to: #1E88E5
  }

  .sm\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1976D2
  }

  .sm\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1565C0
  }

  .sm\:hover\:to-blue-900:hover {
    --tw-gradient-to: #0D47A1
  }

  .sm\:hover\:to-blue:hover {
    --tw-gradient-to: #2196F3
  }

  .sm\:hover\:to-blue-A100:hover {
    --tw-gradient-to: #82B1FF
  }

  .sm\:hover\:to-blue-A200:hover {
    --tw-gradient-to: #448AFF
  }

  .sm\:hover\:to-blue-A400:hover {
    --tw-gradient-to: #2979FF
  }

  .sm\:hover\:to-blue-A700:hover {
    --tw-gradient-to: #2962FF
  }

  .sm\:hover\:to-light-blue-50:hover {
    --tw-gradient-to: #E1F5FE
  }

  .sm\:hover\:to-light-blue-100:hover {
    --tw-gradient-to: #B3E5FC
  }

  .sm\:hover\:to-light-blue-200:hover {
    --tw-gradient-to: #81D4FA
  }

  .sm\:hover\:to-light-blue-300:hover {
    --tw-gradient-to: #4FC3F7
  }

  .sm\:hover\:to-light-blue-400:hover {
    --tw-gradient-to: #29B6F6
  }

  .sm\:hover\:to-light-blue-500:hover {
    --tw-gradient-to: #03A9F4
  }

  .sm\:hover\:to-light-blue-600:hover {
    --tw-gradient-to: #039BE5
  }

  .sm\:hover\:to-light-blue-700:hover {
    --tw-gradient-to: #0288D1
  }

  .sm\:hover\:to-light-blue-800:hover {
    --tw-gradient-to: #0277BD
  }

  .sm\:hover\:to-light-blue-900:hover {
    --tw-gradient-to: #01579B
  }

  .sm\:hover\:to-light-blue:hover {
    --tw-gradient-to: #03A9F4
  }

  .sm\:hover\:to-light-blue-A100:hover {
    --tw-gradient-to: #80D8FF
  }

  .sm\:hover\:to-light-blue-A200:hover {
    --tw-gradient-to: #40C4FF
  }

  .sm\:hover\:to-light-blue-A400:hover {
    --tw-gradient-to: #00B0FF
  }

  .sm\:hover\:to-light-blue-A700:hover {
    --tw-gradient-to: #0091EA
  }

  .sm\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #E8EAF6
  }

  .sm\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #C5CAE9
  }

  .sm\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #9FA8DA
  }

  .sm\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #7986CB
  }

  .sm\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #5C6BC0
  }

  .sm\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #3F51B5
  }

  .sm\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #3949AB
  }

  .sm\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #303F9F
  }

  .sm\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #283593
  }

  .sm\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #1A237E
  }

  .sm\:hover\:to-indigo:hover {
    --tw-gradient-to: #3F51B5
  }

  .sm\:hover\:to-indigo-A100:hover {
    --tw-gradient-to: #8C9EFF
  }

  .sm\:hover\:to-indigo-A200:hover {
    --tw-gradient-to: #536DFE
  }

  .sm\:hover\:to-indigo-A400:hover {
    --tw-gradient-to: #3D5AFE
  }

  .sm\:hover\:to-indigo-A700:hover {
    --tw-gradient-to: #304FFE
  }

  .sm\:hover\:to-purple-50:hover {
    --tw-gradient-to: #F3E5F5
  }

  .sm\:hover\:to-purple-100:hover {
    --tw-gradient-to: #E1BEE7
  }

  .sm\:hover\:to-purple-200:hover {
    --tw-gradient-to: #CE93D8
  }

  .sm\:hover\:to-purple-300:hover {
    --tw-gradient-to: #BA68C8
  }

  .sm\:hover\:to-purple-400:hover {
    --tw-gradient-to: #AB47BC
  }

  .sm\:hover\:to-purple-500:hover {
    --tw-gradient-to: #9C27B0
  }

  .sm\:hover\:to-purple-600:hover {
    --tw-gradient-to: #8E24AA
  }

  .sm\:hover\:to-purple-700:hover {
    --tw-gradient-to: #7B1FA2
  }

  .sm\:hover\:to-purple-800:hover {
    --tw-gradient-to: #6A1B9A
  }

  .sm\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4A148C
  }

  .sm\:hover\:to-purple:hover {
    --tw-gradient-to: #9C27B0
  }

  .sm\:hover\:to-purple-A100:hover {
    --tw-gradient-to: #EA80FC
  }

  .sm\:hover\:to-purple-A200:hover {
    --tw-gradient-to: #E040FB
  }

  .sm\:hover\:to-purple-A400:hover {
    --tw-gradient-to: #D500F9
  }

  .sm\:hover\:to-purple-A700:hover {
    --tw-gradient-to: #AA00FF
  }

  .sm\:hover\:to-deep-purple-50:hover {
    --tw-gradient-to: #EDE7F6
  }

  .sm\:hover\:to-deep-purple-100:hover {
    --tw-gradient-to: #D1C4E9
  }

  .sm\:hover\:to-deep-purple-200:hover {
    --tw-gradient-to: #B39DDB
  }

  .sm\:hover\:to-deep-purple-300:hover {
    --tw-gradient-to: #9575CD
  }

  .sm\:hover\:to-deep-purple-400:hover {
    --tw-gradient-to: #7E57C2
  }

  .sm\:hover\:to-deep-purple-500:hover {
    --tw-gradient-to: #673AB7
  }

  .sm\:hover\:to-deep-purple-600:hover {
    --tw-gradient-to: #5E35B1
  }

  .sm\:hover\:to-deep-purple-700:hover {
    --tw-gradient-to: #512DA8
  }

  .sm\:hover\:to-deep-purple-800:hover {
    --tw-gradient-to: #4527A0
  }

  .sm\:hover\:to-deep-purple-900:hover {
    --tw-gradient-to: #311B92
  }

  .sm\:hover\:to-deep-purple:hover {
    --tw-gradient-to: #673AB7
  }

  .sm\:hover\:to-deep-purple-A100:hover {
    --tw-gradient-to: #B388FF
  }

  .sm\:hover\:to-deep-purple-A200:hover {
    --tw-gradient-to: #7C4DFF
  }

  .sm\:hover\:to-deep-purple-A400:hover {
    --tw-gradient-to: #651FFF
  }

  .sm\:hover\:to-deep-purple-A700:hover {
    --tw-gradient-to: #6200EA
  }

  .sm\:hover\:to-pink-50:hover {
    --tw-gradient-to: #FCE4EC
  }

  .sm\:hover\:to-pink-100:hover {
    --tw-gradient-to: #F8BBD0
  }

  .sm\:hover\:to-pink-200:hover {
    --tw-gradient-to: #F48FB1
  }

  .sm\:hover\:to-pink-300:hover {
    --tw-gradient-to: #F06292
  }

  .sm\:hover\:to-pink-400:hover {
    --tw-gradient-to: #EC407A
  }

  .sm\:hover\:to-pink-500:hover {
    --tw-gradient-to: #E91E63
  }

  .sm\:hover\:to-pink-600:hover {
    --tw-gradient-to: #D81B60
  }

  .sm\:hover\:to-pink-700:hover {
    --tw-gradient-to: #C2185B
  }

  .sm\:hover\:to-pink-800:hover {
    --tw-gradient-to: #AD1457
  }

  .sm\:hover\:to-pink-900:hover {
    --tw-gradient-to: #880E4F
  }

  .sm\:hover\:to-pink:hover {
    --tw-gradient-to: #E91E63
  }

  .sm\:hover\:to-pink-A100:hover {
    --tw-gradient-to: #FF80AB
  }

  .sm\:hover\:to-pink-A200:hover {
    --tw-gradient-to: #FF4081
  }

  .sm\:hover\:to-pink-A400:hover {
    --tw-gradient-to: #F50057
  }

  .sm\:hover\:to-pink-A700:hover {
    --tw-gradient-to: #C51162
  }

  .sm\:hover\:to-lime-50:hover {
    --tw-gradient-to: #F9FBE7
  }

  .sm\:hover\:to-lime-100:hover {
    --tw-gradient-to: #F0F4C3
  }

  .sm\:hover\:to-lime-200:hover {
    --tw-gradient-to: #E6EE9C
  }

  .sm\:hover\:to-lime-300:hover {
    --tw-gradient-to: #DCE775
  }

  .sm\:hover\:to-lime-400:hover {
    --tw-gradient-to: #D4E157
  }

  .sm\:hover\:to-lime-500:hover {
    --tw-gradient-to: #CDDC39
  }

  .sm\:hover\:to-lime-600:hover {
    --tw-gradient-to: #C0CA33
  }

  .sm\:hover\:to-lime-700:hover {
    --tw-gradient-to: #AFB42B
  }

  .sm\:hover\:to-lime-800:hover {
    --tw-gradient-to: #9E9D24
  }

  .sm\:hover\:to-lime-900:hover {
    --tw-gradient-to: #827717
  }

  .sm\:hover\:to-lime:hover {
    --tw-gradient-to: #CDDC39
  }

  .sm\:hover\:to-lime-A100:hover {
    --tw-gradient-to: #F4FF81
  }

  .sm\:hover\:to-lime-A200:hover {
    --tw-gradient-to: #EEFF41
  }

  .sm\:hover\:to-lime-A400:hover {
    --tw-gradient-to: #C6FF00
  }

  .sm\:hover\:to-lime-A700:hover {
    --tw-gradient-to: #AEEA00
  }

  .sm\:hover\:to-amber-50:hover {
    --tw-gradient-to: #FFF8E1
  }

  .sm\:hover\:to-amber-100:hover {
    --tw-gradient-to: #FFECB3
  }

  .sm\:hover\:to-amber-200:hover {
    --tw-gradient-to: #FFE082
  }

  .sm\:hover\:to-amber-300:hover {
    --tw-gradient-to: #FFD54F
  }

  .sm\:hover\:to-amber-400:hover {
    --tw-gradient-to: #FFCA28
  }

  .sm\:hover\:to-amber-500:hover {
    --tw-gradient-to: #FFC107
  }

  .sm\:hover\:to-amber-600:hover {
    --tw-gradient-to: #FFB300
  }

  .sm\:hover\:to-amber-700:hover {
    --tw-gradient-to: #FFA000
  }

  .sm\:hover\:to-amber-800:hover {
    --tw-gradient-to: #FF8F00
  }

  .sm\:hover\:to-amber-900:hover {
    --tw-gradient-to: #FF6F00
  }

  .sm\:hover\:to-amber:hover {
    --tw-gradient-to: #FFC107
  }

  .sm\:hover\:to-amber-A100:hover {
    --tw-gradient-to: #FFE57F
  }

  .sm\:hover\:to-amber-A200:hover {
    --tw-gradient-to: #FFD740
  }

  .sm\:hover\:to-amber-A400:hover {
    --tw-gradient-to: #FFC400
  }

  .sm\:hover\:to-amber-A700:hover {
    --tw-gradient-to: #FFAB00
  }

  .sm\:hover\:to-brown-50:hover {
    --tw-gradient-to: #EFEBE9
  }

  .sm\:hover\:to-brown-100:hover {
    --tw-gradient-to: #D7CCC8
  }

  .sm\:hover\:to-brown-200:hover {
    --tw-gradient-to: #BCAAA4
  }

  .sm\:hover\:to-brown-300:hover {
    --tw-gradient-to: #A1887F
  }

  .sm\:hover\:to-brown-400:hover {
    --tw-gradient-to: #8D6E63
  }

  .sm\:hover\:to-brown-500:hover {
    --tw-gradient-to: #795548
  }

  .sm\:hover\:to-brown-600:hover {
    --tw-gradient-to: #6D4C41
  }

  .sm\:hover\:to-brown-700:hover {
    --tw-gradient-to: #5D4037
  }

  .sm\:hover\:to-brown-800:hover {
    --tw-gradient-to: #4E342E
  }

  .sm\:hover\:to-brown-900:hover {
    --tw-gradient-to: #3E2723
  }

  .sm\:hover\:to-brown:hover {
    --tw-gradient-to: #795548
  }

  .sm\:hover\:to-brown-A100:hover {
    --tw-gradient-to: #D7CCC8
  }

  .sm\:hover\:to-brown-A200:hover {
    --tw-gradient-to: #BCAAA4
  }

  .sm\:hover\:to-brown-A400:hover {
    --tw-gradient-to: #8D6E63
  }

  .sm\:hover\:to-brown-A700:hover {
    --tw-gradient-to: #5D4037
  }

  .sm\:hover\:to-blue-gray-50:hover {
    --tw-gradient-to: #ECEFF1
  }

  .sm\:hover\:to-blue-gray-100:hover {
    --tw-gradient-to: #CFD8DC
  }

  .sm\:hover\:to-blue-gray-200:hover {
    --tw-gradient-to: #B0BEC5
  }

  .sm\:hover\:to-blue-gray-300:hover {
    --tw-gradient-to: #90A4AE
  }

  .sm\:hover\:to-blue-gray-400:hover {
    --tw-gradient-to: #78909C
  }

  .sm\:hover\:to-blue-gray-500:hover {
    --tw-gradient-to: #607D8B
  }

  .sm\:hover\:to-blue-gray-600:hover {
    --tw-gradient-to: #546E7A
  }

  .sm\:hover\:to-blue-gray-700:hover {
    --tw-gradient-to: #455A64
  }

  .sm\:hover\:to-blue-gray-800:hover {
    --tw-gradient-to: #37474F
  }

  .sm\:hover\:to-blue-gray-900:hover {
    --tw-gradient-to: #263238
  }

  .sm\:hover\:to-blue-gray:hover {
    --tw-gradient-to: #607D8B
  }

  .sm\:hover\:to-blue-gray-A100:hover {
    --tw-gradient-to: #CFD8DC
  }

  .sm\:hover\:to-blue-gray-A200:hover {
    --tw-gradient-to: #B0BEC5
  }

  .sm\:hover\:to-blue-gray-A400:hover {
    --tw-gradient-to: #78909C
  }

  .sm\:hover\:to-blue-gray-A700:hover {
    --tw-gradient-to: #455A64
  }

  .sm\:hover\:to-cyan-50:hover {
    --tw-gradient-to: #E0F7FA
  }

  .sm\:hover\:to-cyan-100:hover {
    --tw-gradient-to: #B2EBF2
  }

  .sm\:hover\:to-cyan-200:hover {
    --tw-gradient-to: #80DEEA
  }

  .sm\:hover\:to-cyan-300:hover {
    --tw-gradient-to: #4DD0E1
  }

  .sm\:hover\:to-cyan-400:hover {
    --tw-gradient-to: #26C6DA
  }

  .sm\:hover\:to-cyan-500:hover {
    --tw-gradient-to: #00BCD4
  }

  .sm\:hover\:to-cyan-600:hover {
    --tw-gradient-to: #00ACC1
  }

  .sm\:hover\:to-cyan-700:hover {
    --tw-gradient-to: #0097A7
  }

  .sm\:hover\:to-cyan-800:hover {
    --tw-gradient-to: #00838F
  }

  .sm\:hover\:to-cyan-900:hover {
    --tw-gradient-to: #006064
  }

  .sm\:hover\:to-cyan:hover {
    --tw-gradient-to: #00BCD4
  }

  .sm\:hover\:to-cyan-A100:hover {
    --tw-gradient-to: #84FFFF
  }

  .sm\:hover\:to-cyan-A200:hover {
    --tw-gradient-to: #18FFFF
  }

  .sm\:hover\:to-cyan-A400:hover {
    --tw-gradient-to: #00E5FF
  }

  .sm\:hover\:to-cyan-A700:hover {
    --tw-gradient-to: #00B8D4
  }

  .sm\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-black:focus {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .sm\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-grey-50:focus {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .sm\:focus\:from-grey-100:focus {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .sm\:focus\:from-grey-200:focus {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .sm\:focus\:from-grey-300:focus {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .sm\:focus\:from-grey-400:focus {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .sm\:focus\:from-grey-500:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:focus\:from-grey-600:focus {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .sm\:focus\:from-grey-700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:focus\:from-grey-800:focus {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .sm\:focus\:from-grey-900:focus {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .sm\:focus\:from-grey:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:focus\:from-grey-A100:focus {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .sm\:focus\:from-grey-A200:focus {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .sm\:focus\:from-grey-A400:focus {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .sm\:focus\:from-grey-A700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:focus\:from-gray-50:focus {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .sm\:focus\:from-gray-100:focus {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .sm\:focus\:from-gray-200:focus {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .sm\:focus\:from-gray-300:focus {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .sm\:focus\:from-gray-400:focus {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .sm\:focus\:from-gray-500:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:focus\:from-gray-600:focus {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .sm\:focus\:from-gray-700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:focus\:from-gray-800:focus {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .sm\:focus\:from-gray-900:focus {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .sm\:focus\:from-gray:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:focus\:from-gray-hover:focus {
    --tw-gradient-from: rgba(0, 0, 0, 0.04);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:from-gray-A100:focus {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .sm\:focus\:from-gray-A200:focus {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .sm\:focus\:from-gray-A400:focus {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .sm\:focus\:from-gray-A700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:focus\:from-red-50:focus {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .sm\:focus\:from-red-100:focus {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .sm\:focus\:from-red-200:focus {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .sm\:focus\:from-red-300:focus {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .sm\:focus\:from-red-400:focus {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .sm\:focus\:from-red-500:focus {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .sm\:focus\:from-red-600:focus {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .sm\:focus\:from-red-700:focus {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .sm\:focus\:from-red-800:focus {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .sm\:focus\:from-red-900:focus {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .sm\:focus\:from-red:focus {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .sm\:focus\:from-red-A100:focus {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .sm\:focus\:from-red-A200:focus {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .sm\:focus\:from-red-A400:focus {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .sm\:focus\:from-red-A700:focus {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .sm\:focus\:from-orange-50:focus {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .sm\:focus\:from-orange-100:focus {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .sm\:focus\:from-orange-200:focus {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .sm\:focus\:from-orange-300:focus {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .sm\:focus\:from-orange-400:focus {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .sm\:focus\:from-orange-500:focus {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .sm\:focus\:from-orange-600:focus {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .sm\:focus\:from-orange-700:focus {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .sm\:focus\:from-orange-800:focus {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .sm\:focus\:from-orange-900:focus {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .sm\:focus\:from-orange:focus {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .sm\:focus\:from-orange-A100:focus {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .sm\:focus\:from-orange-A200:focus {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .sm\:focus\:from-orange-A400:focus {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .sm\:focus\:from-orange-A700:focus {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .sm\:focus\:from-deep-orange-50:focus {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .sm\:focus\:from-deep-orange-100:focus {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .sm\:focus\:from-deep-orange-200:focus {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .sm\:focus\:from-deep-orange-300:focus {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .sm\:focus\:from-deep-orange-400:focus {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .sm\:focus\:from-deep-orange-500:focus {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .sm\:focus\:from-deep-orange-600:focus {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .sm\:focus\:from-deep-orange-700:focus {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .sm\:focus\:from-deep-orange-800:focus {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .sm\:focus\:from-deep-orange-900:focus {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .sm\:focus\:from-deep-orange:focus {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .sm\:focus\:from-deep-orange-A100:focus {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .sm\:focus\:from-deep-orange-A200:focus {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .sm\:focus\:from-deep-orange-A400:focus {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .sm\:focus\:from-deep-orange-A700:focus {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .sm\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .sm\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .sm\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .sm\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .sm\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .sm\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .sm\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .sm\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .sm\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .sm\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .sm\:focus\:from-yellow:focus {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .sm\:focus\:from-yellow-A100:focus {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .sm\:focus\:from-yellow-A200:focus {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .sm\:focus\:from-yellow-A400:focus {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .sm\:focus\:from-yellow-A700:focus {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .sm\:focus\:from-green-50:focus {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .sm\:focus\:from-green-100:focus {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .sm\:focus\:from-green-200:focus {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .sm\:focus\:from-green-300:focus {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .sm\:focus\:from-green-400:focus {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .sm\:focus\:from-green-500:focus {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .sm\:focus\:from-green-600:focus {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .sm\:focus\:from-green-700:focus {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .sm\:focus\:from-green-800:focus {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .sm\:focus\:from-green-900:focus {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .sm\:focus\:from-green:focus {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .sm\:focus\:from-green-A100:focus {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .sm\:focus\:from-green-A200:focus {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .sm\:focus\:from-green-A400:focus {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .sm\:focus\:from-green-A700:focus {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .sm\:focus\:from-light-green-50:focus {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .sm\:focus\:from-light-green-100:focus {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .sm\:focus\:from-light-green-200:focus {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .sm\:focus\:from-light-green-300:focus {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .sm\:focus\:from-light-green-400:focus {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .sm\:focus\:from-light-green-500:focus {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .sm\:focus\:from-light-green-600:focus {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .sm\:focus\:from-light-green-700:focus {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .sm\:focus\:from-light-green-800:focus {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .sm\:focus\:from-light-green-900:focus {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .sm\:focus\:from-light-green:focus {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .sm\:focus\:from-light-green-A100:focus {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .sm\:focus\:from-light-green-A200:focus {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .sm\:focus\:from-light-green-A400:focus {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .sm\:focus\:from-light-green-A700:focus {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .sm\:focus\:from-teal-50:focus {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .sm\:focus\:from-teal-100:focus {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .sm\:focus\:from-teal-200:focus {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .sm\:focus\:from-teal-300:focus {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .sm\:focus\:from-teal-400:focus {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .sm\:focus\:from-teal-500:focus {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .sm\:focus\:from-teal-600:focus {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .sm\:focus\:from-teal-700:focus {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .sm\:focus\:from-teal-800:focus {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .sm\:focus\:from-teal-900:focus {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .sm\:focus\:from-teal:focus {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .sm\:focus\:from-teal-A100:focus {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .sm\:focus\:from-teal-A200:focus {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .sm\:focus\:from-teal-A400:focus {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .sm\:focus\:from-teal-A700:focus {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .sm\:focus\:from-blue-50:focus {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .sm\:focus\:from-blue-100:focus {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .sm\:focus\:from-blue-200:focus {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .sm\:focus\:from-blue-300:focus {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .sm\:focus\:from-blue-400:focus {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .sm\:focus\:from-blue-500:focus {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .sm\:focus\:from-blue-600:focus {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .sm\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .sm\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .sm\:focus\:from-blue-900:focus {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .sm\:focus\:from-blue:focus {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .sm\:focus\:from-blue-A100:focus {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .sm\:focus\:from-blue-A200:focus {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .sm\:focus\:from-blue-A400:focus {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .sm\:focus\:from-blue-A700:focus {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .sm\:focus\:from-light-blue-50:focus {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .sm\:focus\:from-light-blue-100:focus {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .sm\:focus\:from-light-blue-200:focus {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .sm\:focus\:from-light-blue-300:focus {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .sm\:focus\:from-light-blue-400:focus {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .sm\:focus\:from-light-blue-500:focus {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .sm\:focus\:from-light-blue-600:focus {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .sm\:focus\:from-light-blue-700:focus {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .sm\:focus\:from-light-blue-800:focus {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .sm\:focus\:from-light-blue-900:focus {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .sm\:focus\:from-light-blue:focus {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .sm\:focus\:from-light-blue-A100:focus {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .sm\:focus\:from-light-blue-A200:focus {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .sm\:focus\:from-light-blue-A400:focus {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .sm\:focus\:from-light-blue-A700:focus {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .sm\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .sm\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .sm\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .sm\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .sm\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .sm\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .sm\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .sm\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .sm\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .sm\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .sm\:focus\:from-indigo:focus {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .sm\:focus\:from-indigo-A100:focus {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .sm\:focus\:from-indigo-A200:focus {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .sm\:focus\:from-indigo-A400:focus {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .sm\:focus\:from-indigo-A700:focus {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .sm\:focus\:from-purple-50:focus {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .sm\:focus\:from-purple-100:focus {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .sm\:focus\:from-purple-200:focus {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .sm\:focus\:from-purple-300:focus {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .sm\:focus\:from-purple-400:focus {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .sm\:focus\:from-purple-500:focus {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .sm\:focus\:from-purple-600:focus {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .sm\:focus\:from-purple-700:focus {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .sm\:focus\:from-purple-800:focus {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .sm\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .sm\:focus\:from-purple:focus {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .sm\:focus\:from-purple-A100:focus {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .sm\:focus\:from-purple-A200:focus {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .sm\:focus\:from-purple-A400:focus {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .sm\:focus\:from-purple-A700:focus {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .sm\:focus\:from-deep-purple-50:focus {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .sm\:focus\:from-deep-purple-100:focus {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .sm\:focus\:from-deep-purple-200:focus {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .sm\:focus\:from-deep-purple-300:focus {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .sm\:focus\:from-deep-purple-400:focus {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .sm\:focus\:from-deep-purple-500:focus {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .sm\:focus\:from-deep-purple-600:focus {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .sm\:focus\:from-deep-purple-700:focus {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .sm\:focus\:from-deep-purple-800:focus {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .sm\:focus\:from-deep-purple-900:focus {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .sm\:focus\:from-deep-purple:focus {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .sm\:focus\:from-deep-purple-A100:focus {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .sm\:focus\:from-deep-purple-A200:focus {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .sm\:focus\:from-deep-purple-A400:focus {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .sm\:focus\:from-deep-purple-A700:focus {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .sm\:focus\:from-pink-50:focus {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .sm\:focus\:from-pink-100:focus {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .sm\:focus\:from-pink-200:focus {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .sm\:focus\:from-pink-300:focus {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .sm\:focus\:from-pink-400:focus {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .sm\:focus\:from-pink-500:focus {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .sm\:focus\:from-pink-600:focus {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .sm\:focus\:from-pink-700:focus {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .sm\:focus\:from-pink-800:focus {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .sm\:focus\:from-pink-900:focus {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .sm\:focus\:from-pink:focus {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .sm\:focus\:from-pink-A100:focus {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .sm\:focus\:from-pink-A200:focus {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .sm\:focus\:from-pink-A400:focus {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .sm\:focus\:from-pink-A700:focus {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .sm\:focus\:from-lime-50:focus {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .sm\:focus\:from-lime-100:focus {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .sm\:focus\:from-lime-200:focus {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .sm\:focus\:from-lime-300:focus {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .sm\:focus\:from-lime-400:focus {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .sm\:focus\:from-lime-500:focus {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .sm\:focus\:from-lime-600:focus {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .sm\:focus\:from-lime-700:focus {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .sm\:focus\:from-lime-800:focus {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .sm\:focus\:from-lime-900:focus {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .sm\:focus\:from-lime:focus {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .sm\:focus\:from-lime-A100:focus {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .sm\:focus\:from-lime-A200:focus {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .sm\:focus\:from-lime-A400:focus {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .sm\:focus\:from-lime-A700:focus {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .sm\:focus\:from-amber-50:focus {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .sm\:focus\:from-amber-100:focus {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .sm\:focus\:from-amber-200:focus {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .sm\:focus\:from-amber-300:focus {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .sm\:focus\:from-amber-400:focus {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .sm\:focus\:from-amber-500:focus {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .sm\:focus\:from-amber-600:focus {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .sm\:focus\:from-amber-700:focus {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .sm\:focus\:from-amber-800:focus {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .sm\:focus\:from-amber-900:focus {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .sm\:focus\:from-amber:focus {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .sm\:focus\:from-amber-A100:focus {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .sm\:focus\:from-amber-A200:focus {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .sm\:focus\:from-amber-A400:focus {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .sm\:focus\:from-amber-A700:focus {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .sm\:focus\:from-brown-50:focus {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .sm\:focus\:from-brown-100:focus {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .sm\:focus\:from-brown-200:focus {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .sm\:focus\:from-brown-300:focus {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .sm\:focus\:from-brown-400:focus {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .sm\:focus\:from-brown-500:focus {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .sm\:focus\:from-brown-600:focus {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .sm\:focus\:from-brown-700:focus {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .sm\:focus\:from-brown-800:focus {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .sm\:focus\:from-brown-900:focus {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .sm\:focus\:from-brown:focus {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .sm\:focus\:from-brown-A100:focus {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .sm\:focus\:from-brown-A200:focus {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .sm\:focus\:from-brown-A400:focus {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .sm\:focus\:from-brown-A700:focus {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .sm\:focus\:from-blue-gray-50:focus {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .sm\:focus\:from-blue-gray-100:focus {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .sm\:focus\:from-blue-gray-200:focus {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .sm\:focus\:from-blue-gray-300:focus {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .sm\:focus\:from-blue-gray-400:focus {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .sm\:focus\:from-blue-gray-500:focus {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .sm\:focus\:from-blue-gray-600:focus {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .sm\:focus\:from-blue-gray-700:focus {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .sm\:focus\:from-blue-gray-800:focus {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .sm\:focus\:from-blue-gray-900:focus {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .sm\:focus\:from-blue-gray:focus {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .sm\:focus\:from-blue-gray-A100:focus {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .sm\:focus\:from-blue-gray-A200:focus {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .sm\:focus\:from-blue-gray-A400:focus {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .sm\:focus\:from-blue-gray-A700:focus {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .sm\:focus\:from-cyan-50:focus {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .sm\:focus\:from-cyan-100:focus {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .sm\:focus\:from-cyan-200:focus {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .sm\:focus\:from-cyan-300:focus {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .sm\:focus\:from-cyan-400:focus {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .sm\:focus\:from-cyan-500:focus {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .sm\:focus\:from-cyan-600:focus {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .sm\:focus\:from-cyan-700:focus {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .sm\:focus\:from-cyan-800:focus {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .sm\:focus\:from-cyan-900:focus {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .sm\:focus\:from-cyan:focus {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .sm\:focus\:from-cyan-A100:focus {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .sm\:focus\:from-cyan-A200:focus {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .sm\:focus\:from-cyan-A400:focus {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .sm\:focus\:from-cyan-A700:focus {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .sm\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .sm\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-grey-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .sm\:focus\:via-grey-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .sm\:focus\:via-grey-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .sm\:focus\:via-grey-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .sm\:focus\:via-grey-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .sm\:focus\:via-grey-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:focus\:via-grey-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .sm\:focus\:via-grey-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:focus\:via-grey-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .sm\:focus\:via-grey-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .sm\:focus\:via-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:focus\:via-grey-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .sm\:focus\:via-grey-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .sm\:focus\:via-grey-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .sm\:focus\:via-grey-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .sm\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .sm\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .sm\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .sm\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .sm\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .sm\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .sm\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .sm\:focus\:via-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .sm\:focus\:via-gray-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:via-gray-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .sm\:focus\:via-gray-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .sm\:focus\:via-gray-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .sm\:focus\:via-gray-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .sm\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .sm\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .sm\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .sm\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .sm\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .sm\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .sm\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .sm\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .sm\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .sm\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .sm\:focus\:via-red:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .sm\:focus\:via-red-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .sm\:focus\:via-red-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .sm\:focus\:via-red-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .sm\:focus\:via-red-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .sm\:focus\:via-orange-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .sm\:focus\:via-orange-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .sm\:focus\:via-orange-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .sm\:focus\:via-orange-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .sm\:focus\:via-orange-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .sm\:focus\:via-orange-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .sm\:focus\:via-orange-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .sm\:focus\:via-orange-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .sm\:focus\:via-orange-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .sm\:focus\:via-orange-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .sm\:focus\:via-orange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .sm\:focus\:via-orange-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .sm\:focus\:via-orange-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .sm\:focus\:via-orange-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .sm\:focus\:via-orange-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .sm\:focus\:via-deep-orange-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .sm\:focus\:via-deep-orange-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .sm\:focus\:via-deep-orange-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .sm\:focus\:via-deep-orange-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .sm\:focus\:via-deep-orange-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .sm\:focus\:via-deep-orange-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .sm\:focus\:via-deep-orange-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .sm\:focus\:via-deep-orange-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .sm\:focus\:via-deep-orange-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .sm\:focus\:via-deep-orange-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .sm\:focus\:via-deep-orange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .sm\:focus\:via-deep-orange-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .sm\:focus\:via-deep-orange-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .sm\:focus\:via-deep-orange-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .sm\:focus\:via-deep-orange-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .sm\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .sm\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .sm\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .sm\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .sm\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .sm\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .sm\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .sm\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .sm\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .sm\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .sm\:focus\:via-yellow:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .sm\:focus\:via-yellow-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .sm\:focus\:via-yellow-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .sm\:focus\:via-yellow-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .sm\:focus\:via-yellow-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .sm\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .sm\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .sm\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .sm\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .sm\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .sm\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .sm\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .sm\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .sm\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .sm\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .sm\:focus\:via-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .sm\:focus\:via-green-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .sm\:focus\:via-green-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .sm\:focus\:via-green-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .sm\:focus\:via-green-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .sm\:focus\:via-light-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .sm\:focus\:via-light-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .sm\:focus\:via-light-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .sm\:focus\:via-light-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .sm\:focus\:via-light-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .sm\:focus\:via-light-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .sm\:focus\:via-light-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .sm\:focus\:via-light-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .sm\:focus\:via-light-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .sm\:focus\:via-light-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .sm\:focus\:via-light-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .sm\:focus\:via-light-green-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .sm\:focus\:via-light-green-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .sm\:focus\:via-light-green-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .sm\:focus\:via-light-green-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .sm\:focus\:via-teal-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .sm\:focus\:via-teal-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .sm\:focus\:via-teal-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .sm\:focus\:via-teal-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .sm\:focus\:via-teal-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .sm\:focus\:via-teal-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .sm\:focus\:via-teal-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .sm\:focus\:via-teal-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .sm\:focus\:via-teal-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .sm\:focus\:via-teal-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .sm\:focus\:via-teal:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .sm\:focus\:via-teal-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .sm\:focus\:via-teal-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .sm\:focus\:via-teal-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .sm\:focus\:via-teal-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .sm\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .sm\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .sm\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .sm\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .sm\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .sm\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .sm\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .sm\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .sm\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .sm\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .sm\:focus\:via-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .sm\:focus\:via-blue-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .sm\:focus\:via-blue-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .sm\:focus\:via-blue-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .sm\:focus\:via-blue-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .sm\:focus\:via-light-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .sm\:focus\:via-light-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .sm\:focus\:via-light-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .sm\:focus\:via-light-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .sm\:focus\:via-light-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .sm\:focus\:via-light-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .sm\:focus\:via-light-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .sm\:focus\:via-light-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .sm\:focus\:via-light-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .sm\:focus\:via-light-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .sm\:focus\:via-light-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .sm\:focus\:via-light-blue-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .sm\:focus\:via-light-blue-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .sm\:focus\:via-light-blue-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .sm\:focus\:via-light-blue-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .sm\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .sm\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .sm\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .sm\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .sm\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .sm\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .sm\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .sm\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .sm\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .sm\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .sm\:focus\:via-indigo:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .sm\:focus\:via-indigo-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .sm\:focus\:via-indigo-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .sm\:focus\:via-indigo-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .sm\:focus\:via-indigo-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .sm\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .sm\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .sm\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .sm\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .sm\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .sm\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .sm\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .sm\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .sm\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .sm\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .sm\:focus\:via-purple:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .sm\:focus\:via-purple-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .sm\:focus\:via-purple-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .sm\:focus\:via-purple-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .sm\:focus\:via-purple-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .sm\:focus\:via-deep-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .sm\:focus\:via-deep-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .sm\:focus\:via-deep-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .sm\:focus\:via-deep-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .sm\:focus\:via-deep-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .sm\:focus\:via-deep-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .sm\:focus\:via-deep-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .sm\:focus\:via-deep-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .sm\:focus\:via-deep-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .sm\:focus\:via-deep-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .sm\:focus\:via-deep-purple:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .sm\:focus\:via-deep-purple-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .sm\:focus\:via-deep-purple-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .sm\:focus\:via-deep-purple-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .sm\:focus\:via-deep-purple-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .sm\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .sm\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .sm\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .sm\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .sm\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .sm\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .sm\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .sm\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .sm\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .sm\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .sm\:focus\:via-pink:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .sm\:focus\:via-pink-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .sm\:focus\:via-pink-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .sm\:focus\:via-pink-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .sm\:focus\:via-pink-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .sm\:focus\:via-lime-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .sm\:focus\:via-lime-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .sm\:focus\:via-lime-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .sm\:focus\:via-lime-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .sm\:focus\:via-lime-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .sm\:focus\:via-lime-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .sm\:focus\:via-lime-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .sm\:focus\:via-lime-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .sm\:focus\:via-lime-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .sm\:focus\:via-lime-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .sm\:focus\:via-lime:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .sm\:focus\:via-lime-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .sm\:focus\:via-lime-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .sm\:focus\:via-lime-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .sm\:focus\:via-lime-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .sm\:focus\:via-amber-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .sm\:focus\:via-amber-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .sm\:focus\:via-amber-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .sm\:focus\:via-amber-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .sm\:focus\:via-amber-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .sm\:focus\:via-amber-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .sm\:focus\:via-amber-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .sm\:focus\:via-amber-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .sm\:focus\:via-amber-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .sm\:focus\:via-amber-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .sm\:focus\:via-amber:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .sm\:focus\:via-amber-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .sm\:focus\:via-amber-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .sm\:focus\:via-amber-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .sm\:focus\:via-amber-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .sm\:focus\:via-brown-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .sm\:focus\:via-brown-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .sm\:focus\:via-brown-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .sm\:focus\:via-brown-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .sm\:focus\:via-brown-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .sm\:focus\:via-brown-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .sm\:focus\:via-brown-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .sm\:focus\:via-brown-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .sm\:focus\:via-brown-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .sm\:focus\:via-brown-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .sm\:focus\:via-brown:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .sm\:focus\:via-brown-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .sm\:focus\:via-brown-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .sm\:focus\:via-brown-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .sm\:focus\:via-brown-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .sm\:focus\:via-blue-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .sm\:focus\:via-blue-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .sm\:focus\:via-blue-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .sm\:focus\:via-blue-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .sm\:focus\:via-blue-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .sm\:focus\:via-blue-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .sm\:focus\:via-blue-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .sm\:focus\:via-blue-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .sm\:focus\:via-blue-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .sm\:focus\:via-blue-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .sm\:focus\:via-blue-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .sm\:focus\:via-blue-gray-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .sm\:focus\:via-blue-gray-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .sm\:focus\:via-blue-gray-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .sm\:focus\:via-blue-gray-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .sm\:focus\:via-cyan-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .sm\:focus\:via-cyan-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .sm\:focus\:via-cyan-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .sm\:focus\:via-cyan-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .sm\:focus\:via-cyan-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .sm\:focus\:via-cyan-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .sm\:focus\:via-cyan-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .sm\:focus\:via-cyan-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .sm\:focus\:via-cyan-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .sm\:focus\:via-cyan-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .sm\:focus\:via-cyan:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .sm\:focus\:via-cyan-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .sm\:focus\:via-cyan-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .sm\:focus\:via-cyan-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .sm\:focus\:via-cyan-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .sm\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent
  }

  .sm\:focus\:to-current:focus {
    --tw-gradient-to: currentColor
  }

  .sm\:focus\:to-black:focus {
    --tw-gradient-to: #22292F
  }

  .sm\:focus\:to-white:focus {
    --tw-gradient-to: #fff
  }

  .sm\:focus\:to-grey-50:focus {
    --tw-gradient-to: #FAFAFA
  }

  .sm\:focus\:to-grey-100:focus {
    --tw-gradient-to: #F5F5F5
  }

  .sm\:focus\:to-grey-200:focus {
    --tw-gradient-to: #EEEEEE
  }

  .sm\:focus\:to-grey-300:focus {
    --tw-gradient-to: #E0E0E0
  }

  .sm\:focus\:to-grey-400:focus {
    --tw-gradient-to: #BDBDBD
  }

  .sm\:focus\:to-grey-500:focus {
    --tw-gradient-to: #9E9E9E
  }

  .sm\:focus\:to-grey-600:focus {
    --tw-gradient-to: #757575
  }

  .sm\:focus\:to-grey-700:focus {
    --tw-gradient-to: #616161
  }

  .sm\:focus\:to-grey-800:focus {
    --tw-gradient-to: #424242
  }

  .sm\:focus\:to-grey-900:focus {
    --tw-gradient-to: #212121
  }

  .sm\:focus\:to-grey:focus {
    --tw-gradient-to: #9E9E9E
  }

  .sm\:focus\:to-grey-A100:focus {
    --tw-gradient-to: #D5D5D5
  }

  .sm\:focus\:to-grey-A200:focus {
    --tw-gradient-to: #AAAAAA
  }

  .sm\:focus\:to-grey-A400:focus {
    --tw-gradient-to: #303030
  }

  .sm\:focus\:to-grey-A700:focus {
    --tw-gradient-to: #616161
  }

  .sm\:focus\:to-gray-50:focus {
    --tw-gradient-to: #FAFAFA
  }

  .sm\:focus\:to-gray-100:focus {
    --tw-gradient-to: #F5F5F5
  }

  .sm\:focus\:to-gray-200:focus {
    --tw-gradient-to: #EEEEEE
  }

  .sm\:focus\:to-gray-300:focus {
    --tw-gradient-to: #E0E0E0
  }

  .sm\:focus\:to-gray-400:focus {
    --tw-gradient-to: #BDBDBD
  }

  .sm\:focus\:to-gray-500:focus {
    --tw-gradient-to: #9E9E9E
  }

  .sm\:focus\:to-gray-600:focus {
    --tw-gradient-to: #757575
  }

  .sm\:focus\:to-gray-700:focus {
    --tw-gradient-to: #616161
  }

  .sm\:focus\:to-gray-800:focus {
    --tw-gradient-to: #424242
  }

  .sm\:focus\:to-gray-900:focus {
    --tw-gradient-to: #212121
  }

  .sm\:focus\:to-gray:focus {
    --tw-gradient-to: #9E9E9E
  }

  .sm\:focus\:to-gray-hover:focus {
    --tw-gradient-to: rgba(0, 0, 0, 0.04)
  }

  .sm\:focus\:to-gray-A100:focus {
    --tw-gradient-to: #D5D5D5
  }

  .sm\:focus\:to-gray-A200:focus {
    --tw-gradient-to: #AAAAAA
  }

  .sm\:focus\:to-gray-A400:focus {
    --tw-gradient-to: #303030
  }

  .sm\:focus\:to-gray-A700:focus {
    --tw-gradient-to: #616161
  }

  .sm\:focus\:to-red-50:focus {
    --tw-gradient-to: #FFEBEE
  }

  .sm\:focus\:to-red-100:focus {
    --tw-gradient-to: #FFCDD2
  }

  .sm\:focus\:to-red-200:focus {
    --tw-gradient-to: #EF9A9A
  }

  .sm\:focus\:to-red-300:focus {
    --tw-gradient-to: #E57373
  }

  .sm\:focus\:to-red-400:focus {
    --tw-gradient-to: #EF5350
  }

  .sm\:focus\:to-red-500:focus {
    --tw-gradient-to: #F44336
  }

  .sm\:focus\:to-red-600:focus {
    --tw-gradient-to: #E53935
  }

  .sm\:focus\:to-red-700:focus {
    --tw-gradient-to: #D32F2F
  }

  .sm\:focus\:to-red-800:focus {
    --tw-gradient-to: #C62828
  }

  .sm\:focus\:to-red-900:focus {
    --tw-gradient-to: #B71C1C
  }

  .sm\:focus\:to-red:focus {
    --tw-gradient-to: #F44336
  }

  .sm\:focus\:to-red-A100:focus {
    --tw-gradient-to: #FF8A80
  }

  .sm\:focus\:to-red-A200:focus {
    --tw-gradient-to: #FF5252
  }

  .sm\:focus\:to-red-A400:focus {
    --tw-gradient-to: #FF1744
  }

  .sm\:focus\:to-red-A700:focus {
    --tw-gradient-to: #D50000
  }

  .sm\:focus\:to-orange-50:focus {
    --tw-gradient-to: #FFF3E0
  }

  .sm\:focus\:to-orange-100:focus {
    --tw-gradient-to: #FFE0B2
  }

  .sm\:focus\:to-orange-200:focus {
    --tw-gradient-to: #FFCC80
  }

  .sm\:focus\:to-orange-300:focus {
    --tw-gradient-to: #FFB74D
  }

  .sm\:focus\:to-orange-400:focus {
    --tw-gradient-to: #FFA726
  }

  .sm\:focus\:to-orange-500:focus {
    --tw-gradient-to: #FF9800
  }

  .sm\:focus\:to-orange-600:focus {
    --tw-gradient-to: #FB8C00
  }

  .sm\:focus\:to-orange-700:focus {
    --tw-gradient-to: #F57C00
  }

  .sm\:focus\:to-orange-800:focus {
    --tw-gradient-to: #EF6C00
  }

  .sm\:focus\:to-orange-900:focus {
    --tw-gradient-to: #E65100
  }

  .sm\:focus\:to-orange:focus {
    --tw-gradient-to: #FF9800
  }

  .sm\:focus\:to-orange-A100:focus {
    --tw-gradient-to: #FFD180
  }

  .sm\:focus\:to-orange-A200:focus {
    --tw-gradient-to: #FFAB40
  }

  .sm\:focus\:to-orange-A400:focus {
    --tw-gradient-to: #FF9100
  }

  .sm\:focus\:to-orange-A700:focus {
    --tw-gradient-to: #FF6D00
  }

  .sm\:focus\:to-deep-orange-50:focus {
    --tw-gradient-to: #FBE9E7
  }

  .sm\:focus\:to-deep-orange-100:focus {
    --tw-gradient-to: #FFCCBC
  }

  .sm\:focus\:to-deep-orange-200:focus {
    --tw-gradient-to: #FFAB91
  }

  .sm\:focus\:to-deep-orange-300:focus {
    --tw-gradient-to: #FF8A65
  }

  .sm\:focus\:to-deep-orange-400:focus {
    --tw-gradient-to: #FF7043
  }

  .sm\:focus\:to-deep-orange-500:focus {
    --tw-gradient-to: #FF5722
  }

  .sm\:focus\:to-deep-orange-600:focus {
    --tw-gradient-to: #F4511E
  }

  .sm\:focus\:to-deep-orange-700:focus {
    --tw-gradient-to: #E64A19
  }

  .sm\:focus\:to-deep-orange-800:focus {
    --tw-gradient-to: #D84315
  }

  .sm\:focus\:to-deep-orange-900:focus {
    --tw-gradient-to: #BF360C
  }

  .sm\:focus\:to-deep-orange:focus {
    --tw-gradient-to: #FF5722
  }

  .sm\:focus\:to-deep-orange-A100:focus {
    --tw-gradient-to: #FF9E80
  }

  .sm\:focus\:to-deep-orange-A200:focus {
    --tw-gradient-to: #FF6E40
  }

  .sm\:focus\:to-deep-orange-A400:focus {
    --tw-gradient-to: #FF3D00
  }

  .sm\:focus\:to-deep-orange-A700:focus {
    --tw-gradient-to: #DD2C00
  }

  .sm\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #FFFDE7
  }

  .sm\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #FFF9C4
  }

  .sm\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #FFF59D
  }

  .sm\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #FFF176
  }

  .sm\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #FFEE58
  }

  .sm\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #FFEB3B
  }

  .sm\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #FDD835
  }

  .sm\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #FBC02D
  }

  .sm\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #F9A825
  }

  .sm\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #F57F17
  }

  .sm\:focus\:to-yellow:focus {
    --tw-gradient-to: #FFEB3B
  }

  .sm\:focus\:to-yellow-A100:focus {
    --tw-gradient-to: #FFFF8D
  }

  .sm\:focus\:to-yellow-A200:focus {
    --tw-gradient-to: #FFFF00
  }

  .sm\:focus\:to-yellow-A400:focus {
    --tw-gradient-to: #FFEA00
  }

  .sm\:focus\:to-yellow-A700:focus {
    --tw-gradient-to: #FFD600
  }

  .sm\:focus\:to-green-50:focus {
    --tw-gradient-to: #E8F5E9
  }

  .sm\:focus\:to-green-100:focus {
    --tw-gradient-to: #C8E6C9
  }

  .sm\:focus\:to-green-200:focus {
    --tw-gradient-to: #A5D6A7
  }

  .sm\:focus\:to-green-300:focus {
    --tw-gradient-to: #81C784
  }

  .sm\:focus\:to-green-400:focus {
    --tw-gradient-to: #66BB6A
  }

  .sm\:focus\:to-green-500:focus {
    --tw-gradient-to: #4CAF50
  }

  .sm\:focus\:to-green-600:focus {
    --tw-gradient-to: #43A047
  }

  .sm\:focus\:to-green-700:focus {
    --tw-gradient-to: #388E3C
  }

  .sm\:focus\:to-green-800:focus {
    --tw-gradient-to: #2E7D32
  }

  .sm\:focus\:to-green-900:focus {
    --tw-gradient-to: #1B5E20
  }

  .sm\:focus\:to-green:focus {
    --tw-gradient-to: #4CAF50
  }

  .sm\:focus\:to-green-A100:focus {
    --tw-gradient-to: #B9F6CA
  }

  .sm\:focus\:to-green-A200:focus {
    --tw-gradient-to: #69F0AE
  }

  .sm\:focus\:to-green-A400:focus {
    --tw-gradient-to: #00E676
  }

  .sm\:focus\:to-green-A700:focus {
    --tw-gradient-to: #00C853
  }

  .sm\:focus\:to-light-green-50:focus {
    --tw-gradient-to: #F1F8E9
  }

  .sm\:focus\:to-light-green-100:focus {
    --tw-gradient-to: #DCEDC8
  }

  .sm\:focus\:to-light-green-200:focus {
    --tw-gradient-to: #C5E1A5
  }

  .sm\:focus\:to-light-green-300:focus {
    --tw-gradient-to: #AED581
  }

  .sm\:focus\:to-light-green-400:focus {
    --tw-gradient-to: #9CCC65
  }

  .sm\:focus\:to-light-green-500:focus {
    --tw-gradient-to: #8BC34A
  }

  .sm\:focus\:to-light-green-600:focus {
    --tw-gradient-to: #7CB342
  }

  .sm\:focus\:to-light-green-700:focus {
    --tw-gradient-to: #689F38
  }

  .sm\:focus\:to-light-green-800:focus {
    --tw-gradient-to: #558B2F
  }

  .sm\:focus\:to-light-green-900:focus {
    --tw-gradient-to: #33691E
  }

  .sm\:focus\:to-light-green:focus {
    --tw-gradient-to: #8BC34A
  }

  .sm\:focus\:to-light-green-A100:focus {
    --tw-gradient-to: #CCFF90
  }

  .sm\:focus\:to-light-green-A200:focus {
    --tw-gradient-to: #B2FF59
  }

  .sm\:focus\:to-light-green-A400:focus {
    --tw-gradient-to: #76FF03
  }

  .sm\:focus\:to-light-green-A700:focus {
    --tw-gradient-to: #64DD17
  }

  .sm\:focus\:to-teal-50:focus {
    --tw-gradient-to: #E0F2F1
  }

  .sm\:focus\:to-teal-100:focus {
    --tw-gradient-to: #B2DFDB
  }

  .sm\:focus\:to-teal-200:focus {
    --tw-gradient-to: #80CBC4
  }

  .sm\:focus\:to-teal-300:focus {
    --tw-gradient-to: #4DB6AC
  }

  .sm\:focus\:to-teal-400:focus {
    --tw-gradient-to: #26A69A
  }

  .sm\:focus\:to-teal-500:focus {
    --tw-gradient-to: #009688
  }

  .sm\:focus\:to-teal-600:focus {
    --tw-gradient-to: #00897B
  }

  .sm\:focus\:to-teal-700:focus {
    --tw-gradient-to: #00796B
  }

  .sm\:focus\:to-teal-800:focus {
    --tw-gradient-to: #00695C
  }

  .sm\:focus\:to-teal-900:focus {
    --tw-gradient-to: #004D40
  }

  .sm\:focus\:to-teal:focus {
    --tw-gradient-to: #009688
  }

  .sm\:focus\:to-teal-A100:focus {
    --tw-gradient-to: #A7FFEB
  }

  .sm\:focus\:to-teal-A200:focus {
    --tw-gradient-to: #64FFDA
  }

  .sm\:focus\:to-teal-A400:focus {
    --tw-gradient-to: #1DE9B6
  }

  .sm\:focus\:to-teal-A700:focus {
    --tw-gradient-to: #00BFA5
  }

  .sm\:focus\:to-blue-50:focus {
    --tw-gradient-to: #E3F2FD
  }

  .sm\:focus\:to-blue-100:focus {
    --tw-gradient-to: #BBDEFB
  }

  .sm\:focus\:to-blue-200:focus {
    --tw-gradient-to: #90CAF9
  }

  .sm\:focus\:to-blue-300:focus {
    --tw-gradient-to: #64B5F6
  }

  .sm\:focus\:to-blue-400:focus {
    --tw-gradient-to: #42A5F5
  }

  .sm\:focus\:to-blue-500:focus {
    --tw-gradient-to: #2196F3
  }

  .sm\:focus\:to-blue-600:focus {
    --tw-gradient-to: #1E88E5
  }

  .sm\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1976D2
  }

  .sm\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1565C0
  }

  .sm\:focus\:to-blue-900:focus {
    --tw-gradient-to: #0D47A1
  }

  .sm\:focus\:to-blue:focus {
    --tw-gradient-to: #2196F3
  }

  .sm\:focus\:to-blue-A100:focus {
    --tw-gradient-to: #82B1FF
  }

  .sm\:focus\:to-blue-A200:focus {
    --tw-gradient-to: #448AFF
  }

  .sm\:focus\:to-blue-A400:focus {
    --tw-gradient-to: #2979FF
  }

  .sm\:focus\:to-blue-A700:focus {
    --tw-gradient-to: #2962FF
  }

  .sm\:focus\:to-light-blue-50:focus {
    --tw-gradient-to: #E1F5FE
  }

  .sm\:focus\:to-light-blue-100:focus {
    --tw-gradient-to: #B3E5FC
  }

  .sm\:focus\:to-light-blue-200:focus {
    --tw-gradient-to: #81D4FA
  }

  .sm\:focus\:to-light-blue-300:focus {
    --tw-gradient-to: #4FC3F7
  }

  .sm\:focus\:to-light-blue-400:focus {
    --tw-gradient-to: #29B6F6
  }

  .sm\:focus\:to-light-blue-500:focus {
    --tw-gradient-to: #03A9F4
  }

  .sm\:focus\:to-light-blue-600:focus {
    --tw-gradient-to: #039BE5
  }

  .sm\:focus\:to-light-blue-700:focus {
    --tw-gradient-to: #0288D1
  }

  .sm\:focus\:to-light-blue-800:focus {
    --tw-gradient-to: #0277BD
  }

  .sm\:focus\:to-light-blue-900:focus {
    --tw-gradient-to: #01579B
  }

  .sm\:focus\:to-light-blue:focus {
    --tw-gradient-to: #03A9F4
  }

  .sm\:focus\:to-light-blue-A100:focus {
    --tw-gradient-to: #80D8FF
  }

  .sm\:focus\:to-light-blue-A200:focus {
    --tw-gradient-to: #40C4FF
  }

  .sm\:focus\:to-light-blue-A400:focus {
    --tw-gradient-to: #00B0FF
  }

  .sm\:focus\:to-light-blue-A700:focus {
    --tw-gradient-to: #0091EA
  }

  .sm\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #E8EAF6
  }

  .sm\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #C5CAE9
  }

  .sm\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #9FA8DA
  }

  .sm\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #7986CB
  }

  .sm\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #5C6BC0
  }

  .sm\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #3F51B5
  }

  .sm\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #3949AB
  }

  .sm\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #303F9F
  }

  .sm\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #283593
  }

  .sm\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #1A237E
  }

  .sm\:focus\:to-indigo:focus {
    --tw-gradient-to: #3F51B5
  }

  .sm\:focus\:to-indigo-A100:focus {
    --tw-gradient-to: #8C9EFF
  }

  .sm\:focus\:to-indigo-A200:focus {
    --tw-gradient-to: #536DFE
  }

  .sm\:focus\:to-indigo-A400:focus {
    --tw-gradient-to: #3D5AFE
  }

  .sm\:focus\:to-indigo-A700:focus {
    --tw-gradient-to: #304FFE
  }

  .sm\:focus\:to-purple-50:focus {
    --tw-gradient-to: #F3E5F5
  }

  .sm\:focus\:to-purple-100:focus {
    --tw-gradient-to: #E1BEE7
  }

  .sm\:focus\:to-purple-200:focus {
    --tw-gradient-to: #CE93D8
  }

  .sm\:focus\:to-purple-300:focus {
    --tw-gradient-to: #BA68C8
  }

  .sm\:focus\:to-purple-400:focus {
    --tw-gradient-to: #AB47BC
  }

  .sm\:focus\:to-purple-500:focus {
    --tw-gradient-to: #9C27B0
  }

  .sm\:focus\:to-purple-600:focus {
    --tw-gradient-to: #8E24AA
  }

  .sm\:focus\:to-purple-700:focus {
    --tw-gradient-to: #7B1FA2
  }

  .sm\:focus\:to-purple-800:focus {
    --tw-gradient-to: #6A1B9A
  }

  .sm\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4A148C
  }

  .sm\:focus\:to-purple:focus {
    --tw-gradient-to: #9C27B0
  }

  .sm\:focus\:to-purple-A100:focus {
    --tw-gradient-to: #EA80FC
  }

  .sm\:focus\:to-purple-A200:focus {
    --tw-gradient-to: #E040FB
  }

  .sm\:focus\:to-purple-A400:focus {
    --tw-gradient-to: #D500F9
  }

  .sm\:focus\:to-purple-A700:focus {
    --tw-gradient-to: #AA00FF
  }

  .sm\:focus\:to-deep-purple-50:focus {
    --tw-gradient-to: #EDE7F6
  }

  .sm\:focus\:to-deep-purple-100:focus {
    --tw-gradient-to: #D1C4E9
  }

  .sm\:focus\:to-deep-purple-200:focus {
    --tw-gradient-to: #B39DDB
  }

  .sm\:focus\:to-deep-purple-300:focus {
    --tw-gradient-to: #9575CD
  }

  .sm\:focus\:to-deep-purple-400:focus {
    --tw-gradient-to: #7E57C2
  }

  .sm\:focus\:to-deep-purple-500:focus {
    --tw-gradient-to: #673AB7
  }

  .sm\:focus\:to-deep-purple-600:focus {
    --tw-gradient-to: #5E35B1
  }

  .sm\:focus\:to-deep-purple-700:focus {
    --tw-gradient-to: #512DA8
  }

  .sm\:focus\:to-deep-purple-800:focus {
    --tw-gradient-to: #4527A0
  }

  .sm\:focus\:to-deep-purple-900:focus {
    --tw-gradient-to: #311B92
  }

  .sm\:focus\:to-deep-purple:focus {
    --tw-gradient-to: #673AB7
  }

  .sm\:focus\:to-deep-purple-A100:focus {
    --tw-gradient-to: #B388FF
  }

  .sm\:focus\:to-deep-purple-A200:focus {
    --tw-gradient-to: #7C4DFF
  }

  .sm\:focus\:to-deep-purple-A400:focus {
    --tw-gradient-to: #651FFF
  }

  .sm\:focus\:to-deep-purple-A700:focus {
    --tw-gradient-to: #6200EA
  }

  .sm\:focus\:to-pink-50:focus {
    --tw-gradient-to: #FCE4EC
  }

  .sm\:focus\:to-pink-100:focus {
    --tw-gradient-to: #F8BBD0
  }

  .sm\:focus\:to-pink-200:focus {
    --tw-gradient-to: #F48FB1
  }

  .sm\:focus\:to-pink-300:focus {
    --tw-gradient-to: #F06292
  }

  .sm\:focus\:to-pink-400:focus {
    --tw-gradient-to: #EC407A
  }

  .sm\:focus\:to-pink-500:focus {
    --tw-gradient-to: #E91E63
  }

  .sm\:focus\:to-pink-600:focus {
    --tw-gradient-to: #D81B60
  }

  .sm\:focus\:to-pink-700:focus {
    --tw-gradient-to: #C2185B
  }

  .sm\:focus\:to-pink-800:focus {
    --tw-gradient-to: #AD1457
  }

  .sm\:focus\:to-pink-900:focus {
    --tw-gradient-to: #880E4F
  }

  .sm\:focus\:to-pink:focus {
    --tw-gradient-to: #E91E63
  }

  .sm\:focus\:to-pink-A100:focus {
    --tw-gradient-to: #FF80AB
  }

  .sm\:focus\:to-pink-A200:focus {
    --tw-gradient-to: #FF4081
  }

  .sm\:focus\:to-pink-A400:focus {
    --tw-gradient-to: #F50057
  }

  .sm\:focus\:to-pink-A700:focus {
    --tw-gradient-to: #C51162
  }

  .sm\:focus\:to-lime-50:focus {
    --tw-gradient-to: #F9FBE7
  }

  .sm\:focus\:to-lime-100:focus {
    --tw-gradient-to: #F0F4C3
  }

  .sm\:focus\:to-lime-200:focus {
    --tw-gradient-to: #E6EE9C
  }

  .sm\:focus\:to-lime-300:focus {
    --tw-gradient-to: #DCE775
  }

  .sm\:focus\:to-lime-400:focus {
    --tw-gradient-to: #D4E157
  }

  .sm\:focus\:to-lime-500:focus {
    --tw-gradient-to: #CDDC39
  }

  .sm\:focus\:to-lime-600:focus {
    --tw-gradient-to: #C0CA33
  }

  .sm\:focus\:to-lime-700:focus {
    --tw-gradient-to: #AFB42B
  }

  .sm\:focus\:to-lime-800:focus {
    --tw-gradient-to: #9E9D24
  }

  .sm\:focus\:to-lime-900:focus {
    --tw-gradient-to: #827717
  }

  .sm\:focus\:to-lime:focus {
    --tw-gradient-to: #CDDC39
  }

  .sm\:focus\:to-lime-A100:focus {
    --tw-gradient-to: #F4FF81
  }

  .sm\:focus\:to-lime-A200:focus {
    --tw-gradient-to: #EEFF41
  }

  .sm\:focus\:to-lime-A400:focus {
    --tw-gradient-to: #C6FF00
  }

  .sm\:focus\:to-lime-A700:focus {
    --tw-gradient-to: #AEEA00
  }

  .sm\:focus\:to-amber-50:focus {
    --tw-gradient-to: #FFF8E1
  }

  .sm\:focus\:to-amber-100:focus {
    --tw-gradient-to: #FFECB3
  }

  .sm\:focus\:to-amber-200:focus {
    --tw-gradient-to: #FFE082
  }

  .sm\:focus\:to-amber-300:focus {
    --tw-gradient-to: #FFD54F
  }

  .sm\:focus\:to-amber-400:focus {
    --tw-gradient-to: #FFCA28
  }

  .sm\:focus\:to-amber-500:focus {
    --tw-gradient-to: #FFC107
  }

  .sm\:focus\:to-amber-600:focus {
    --tw-gradient-to: #FFB300
  }

  .sm\:focus\:to-amber-700:focus {
    --tw-gradient-to: #FFA000
  }

  .sm\:focus\:to-amber-800:focus {
    --tw-gradient-to: #FF8F00
  }

  .sm\:focus\:to-amber-900:focus {
    --tw-gradient-to: #FF6F00
  }

  .sm\:focus\:to-amber:focus {
    --tw-gradient-to: #FFC107
  }

  .sm\:focus\:to-amber-A100:focus {
    --tw-gradient-to: #FFE57F
  }

  .sm\:focus\:to-amber-A200:focus {
    --tw-gradient-to: #FFD740
  }

  .sm\:focus\:to-amber-A400:focus {
    --tw-gradient-to: #FFC400
  }

  .sm\:focus\:to-amber-A700:focus {
    --tw-gradient-to: #FFAB00
  }

  .sm\:focus\:to-brown-50:focus {
    --tw-gradient-to: #EFEBE9
  }

  .sm\:focus\:to-brown-100:focus {
    --tw-gradient-to: #D7CCC8
  }

  .sm\:focus\:to-brown-200:focus {
    --tw-gradient-to: #BCAAA4
  }

  .sm\:focus\:to-brown-300:focus {
    --tw-gradient-to: #A1887F
  }

  .sm\:focus\:to-brown-400:focus {
    --tw-gradient-to: #8D6E63
  }

  .sm\:focus\:to-brown-500:focus {
    --tw-gradient-to: #795548
  }

  .sm\:focus\:to-brown-600:focus {
    --tw-gradient-to: #6D4C41
  }

  .sm\:focus\:to-brown-700:focus {
    --tw-gradient-to: #5D4037
  }

  .sm\:focus\:to-brown-800:focus {
    --tw-gradient-to: #4E342E
  }

  .sm\:focus\:to-brown-900:focus {
    --tw-gradient-to: #3E2723
  }

  .sm\:focus\:to-brown:focus {
    --tw-gradient-to: #795548
  }

  .sm\:focus\:to-brown-A100:focus {
    --tw-gradient-to: #D7CCC8
  }

  .sm\:focus\:to-brown-A200:focus {
    --tw-gradient-to: #BCAAA4
  }

  .sm\:focus\:to-brown-A400:focus {
    --tw-gradient-to: #8D6E63
  }

  .sm\:focus\:to-brown-A700:focus {
    --tw-gradient-to: #5D4037
  }

  .sm\:focus\:to-blue-gray-50:focus {
    --tw-gradient-to: #ECEFF1
  }

  .sm\:focus\:to-blue-gray-100:focus {
    --tw-gradient-to: #CFD8DC
  }

  .sm\:focus\:to-blue-gray-200:focus {
    --tw-gradient-to: #B0BEC5
  }

  .sm\:focus\:to-blue-gray-300:focus {
    --tw-gradient-to: #90A4AE
  }

  .sm\:focus\:to-blue-gray-400:focus {
    --tw-gradient-to: #78909C
  }

  .sm\:focus\:to-blue-gray-500:focus {
    --tw-gradient-to: #607D8B
  }

  .sm\:focus\:to-blue-gray-600:focus {
    --tw-gradient-to: #546E7A
  }

  .sm\:focus\:to-blue-gray-700:focus {
    --tw-gradient-to: #455A64
  }

  .sm\:focus\:to-blue-gray-800:focus {
    --tw-gradient-to: #37474F
  }

  .sm\:focus\:to-blue-gray-900:focus {
    --tw-gradient-to: #263238
  }

  .sm\:focus\:to-blue-gray:focus {
    --tw-gradient-to: #607D8B
  }

  .sm\:focus\:to-blue-gray-A100:focus {
    --tw-gradient-to: #CFD8DC
  }

  .sm\:focus\:to-blue-gray-A200:focus {
    --tw-gradient-to: #B0BEC5
  }

  .sm\:focus\:to-blue-gray-A400:focus {
    --tw-gradient-to: #78909C
  }

  .sm\:focus\:to-blue-gray-A700:focus {
    --tw-gradient-to: #455A64
  }

  .sm\:focus\:to-cyan-50:focus {
    --tw-gradient-to: #E0F7FA
  }

  .sm\:focus\:to-cyan-100:focus {
    --tw-gradient-to: #B2EBF2
  }

  .sm\:focus\:to-cyan-200:focus {
    --tw-gradient-to: #80DEEA
  }

  .sm\:focus\:to-cyan-300:focus {
    --tw-gradient-to: #4DD0E1
  }

  .sm\:focus\:to-cyan-400:focus {
    --tw-gradient-to: #26C6DA
  }

  .sm\:focus\:to-cyan-500:focus {
    --tw-gradient-to: #00BCD4
  }

  .sm\:focus\:to-cyan-600:focus {
    --tw-gradient-to: #00ACC1
  }

  .sm\:focus\:to-cyan-700:focus {
    --tw-gradient-to: #0097A7
  }

  .sm\:focus\:to-cyan-800:focus {
    --tw-gradient-to: #00838F
  }

  .sm\:focus\:to-cyan-900:focus {
    --tw-gradient-to: #006064
  }

  .sm\:focus\:to-cyan:focus {
    --tw-gradient-to: #00BCD4
  }

  .sm\:focus\:to-cyan-A100:focus {
    --tw-gradient-to: #84FFFF
  }

  .sm\:focus\:to-cyan-A200:focus {
    --tw-gradient-to: #18FFFF
  }

  .sm\:focus\:to-cyan-A400:focus {
    --tw-gradient-to: #00E5FF
  }

  .sm\:focus\:to-cyan-A700:focus {
    --tw-gradient-to: #00B8D4
  }

  .sm\:bg-opacity-0 {
    --tw-bg-opacity: 0
  }

  .sm\:bg-opacity-5 {
    --tw-bg-opacity: 0.05
  }

  .sm\:bg-opacity-10 {
    --tw-bg-opacity: 0.1
  }

  .sm\:bg-opacity-20 {
    --tw-bg-opacity: 0.2
  }

  .sm\:bg-opacity-25 {
    --tw-bg-opacity: 0.25
  }

  .sm\:bg-opacity-30 {
    --tw-bg-opacity: 0.3
  }

  .sm\:bg-opacity-40 {
    --tw-bg-opacity: 0.4
  }

  .sm\:bg-opacity-50 {
    --tw-bg-opacity: 0.5
  }

  .sm\:bg-opacity-60 {
    --tw-bg-opacity: 0.6
  }

  .sm\:bg-opacity-70 {
    --tw-bg-opacity: 0.7
  }

  .sm\:bg-opacity-75 {
    --tw-bg-opacity: 0.75
  }

  .sm\:bg-opacity-80 {
    --tw-bg-opacity: 0.8
  }

  .sm\:bg-opacity-90 {
    --tw-bg-opacity: 0.9
  }

  .sm\:bg-opacity-95 {
    --tw-bg-opacity: 0.95
  }

  .sm\:bg-opacity-100 {
    --tw-bg-opacity: 1
  }

  .group:hover .sm\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0
  }

  .group:hover .sm\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05
  }

  .group:hover .sm\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1
  }

  .group:hover .sm\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2
  }

  .group:hover .sm\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25
  }

  .group:hover .sm\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3
  }

  .group:hover .sm\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4
  }

  .group:hover .sm\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5
  }

  .group:hover .sm\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6
  }

  .group:hover .sm\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7
  }

  .group:hover .sm\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75
  }

  .group:hover .sm\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8
  }

  .group:hover .sm\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9
  }

  .group:hover .sm\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95
  }

  .group:hover .sm\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1
  }

  .sm\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0
  }

  .sm\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05
  }

  .sm\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1
  }

  .sm\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2
  }

  .sm\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25
  }

  .sm\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3
  }

  .sm\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4
  }

  .sm\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5
  }

  .sm\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6
  }

  .sm\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7
  }

  .sm\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75
  }

  .sm\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8
  }

  .sm\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9
  }

  .sm\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95
  }

  .sm\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1
  }

  .sm\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0
  }

  .sm\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05
  }

  .sm\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1
  }

  .sm\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2
  }

  .sm\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25
  }

  .sm\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3
  }

  .sm\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4
  }

  .sm\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5
  }

  .sm\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6
  }

  .sm\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7
  }

  .sm\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75
  }

  .sm\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8
  }

  .sm\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9
  }

  .sm\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95
  }

  .sm\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1
  }

  .sm\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0
  }

  .sm\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05
  }

  .sm\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1
  }

  .sm\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2
  }

  .sm\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25
  }

  .sm\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3
  }

  .sm\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4
  }

  .sm\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5
  }

  .sm\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6
  }

  .sm\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7
  }

  .sm\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75
  }

  .sm\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8
  }

  .sm\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9
  }

  .sm\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95
  }

  .sm\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1
  }

  .sm\:bg-bottom {
    background-position: bottom
  }

  .sm\:bg-center {
    background-position: center
  }

  .sm\:bg-left {
    background-position: left
  }

  .sm\:bg-left-bottom {
    background-position: left bottom
  }

  .sm\:bg-left-top {
    background-position: left top
  }

  .sm\:bg-right {
    background-position: right
  }

  .sm\:bg-right-bottom {
    background-position: right bottom
  }

  .sm\:bg-right-top {
    background-position: right top
  }

  .sm\:bg-top {
    background-position: top
  }

  .sm\:bg-repeat {
    background-repeat: repeat
  }

  .sm\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .sm\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .sm\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .sm\:bg-repeat-round {
    background-repeat: round
  }

  .sm\:bg-repeat-space {
    background-repeat: space
  }

  .sm\:bg-auto {
    background-size: auto
  }

  .sm\:bg-cover {
    background-size: cover
  }

  .sm\:bg-contain {
    background-size: contain
  }

  .sm\:border-collapse {
    border-collapse: collapse
  }

  .sm\:border-separate {
    border-collapse: separate
  }

  .sm\:border-transparent {
    border-color: transparent
  }

  .sm\:border-current {
    border-color: currentColor
  }

  .sm\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .sm\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .sm\:border-grey-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .sm\:border-grey-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .sm\:border-grey-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .sm\:border-grey-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .sm\:border-grey-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .sm\:border-grey-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:border-grey-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .sm\:border-grey-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:border-grey-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .sm\:border-grey-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .sm\:border-grey {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:border-grey-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .sm\:border-grey-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .sm\:border-grey-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .sm\:border-grey-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .sm\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .sm\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .sm\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .sm\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .sm\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .sm\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .sm\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .sm\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:border-gray-hover {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .sm\:border-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .sm\:border-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .sm\:border-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .sm\:border-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .sm\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .sm\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .sm\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .sm\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .sm\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .sm\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .sm\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .sm\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .sm\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .sm\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .sm\:border-red-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .sm\:border-red-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .sm\:border-red-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .sm\:border-red-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .sm\:border-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .sm\:border-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .sm\:border-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .sm\:border-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .sm\:border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .sm\:border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .sm\:border-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .sm\:border-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .sm\:border-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .sm\:border-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .sm\:border-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .sm\:border-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .sm\:border-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .sm\:border-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .sm\:border-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .sm\:border-deep-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .sm\:border-deep-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .sm\:border-deep-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .sm\:border-deep-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .sm\:border-deep-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .sm\:border-deep-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .sm\:border-deep-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .sm\:border-deep-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .sm\:border-deep-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .sm\:border-deep-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .sm\:border-deep-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .sm\:border-deep-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .sm\:border-deep-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .sm\:border-deep-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .sm\:border-deep-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .sm\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .sm\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .sm\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .sm\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .sm\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .sm\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .sm\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .sm\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .sm\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .sm\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .sm\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .sm\:border-yellow-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .sm\:border-yellow-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .sm\:border-yellow-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .sm\:border-yellow-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .sm\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .sm\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .sm\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .sm\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .sm\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .sm\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .sm\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .sm\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .sm\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .sm\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .sm\:border-green {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .sm\:border-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .sm\:border-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .sm\:border-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .sm\:border-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .sm\:border-light-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .sm\:border-light-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .sm\:border-light-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .sm\:border-light-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .sm\:border-light-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .sm\:border-light-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .sm\:border-light-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .sm\:border-light-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .sm\:border-light-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .sm\:border-light-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .sm\:border-light-green {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .sm\:border-light-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .sm\:border-light-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .sm\:border-light-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .sm\:border-light-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .sm\:border-teal-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .sm\:border-teal-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .sm\:border-teal-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .sm\:border-teal-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .sm\:border-teal-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .sm\:border-teal-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .sm\:border-teal-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .sm\:border-teal-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .sm\:border-teal-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .sm\:border-teal-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .sm\:border-teal {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .sm\:border-teal-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .sm\:border-teal-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .sm\:border-teal-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .sm\:border-teal-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .sm\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .sm\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .sm\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .sm\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .sm\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .sm\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .sm\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .sm\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .sm\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .sm\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .sm\:border-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .sm\:border-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .sm\:border-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .sm\:border-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .sm\:border-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .sm\:border-light-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .sm\:border-light-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .sm\:border-light-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .sm\:border-light-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .sm\:border-light-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .sm\:border-light-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .sm\:border-light-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .sm\:border-light-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .sm\:border-light-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .sm\:border-light-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .sm\:border-light-blue {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .sm\:border-light-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .sm\:border-light-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .sm\:border-light-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .sm\:border-light-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .sm\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .sm\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .sm\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .sm\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .sm\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .sm\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .sm\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .sm\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .sm\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .sm\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .sm\:border-indigo {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .sm\:border-indigo-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .sm\:border-indigo-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .sm\:border-indigo-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .sm\:border-indigo-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .sm\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .sm\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .sm\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .sm\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .sm\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .sm\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .sm\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .sm\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .sm\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .sm\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .sm\:border-purple {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .sm\:border-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .sm\:border-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .sm\:border-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .sm\:border-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .sm\:border-deep-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .sm\:border-deep-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .sm\:border-deep-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .sm\:border-deep-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .sm\:border-deep-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .sm\:border-deep-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .sm\:border-deep-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .sm\:border-deep-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .sm\:border-deep-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .sm\:border-deep-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .sm\:border-deep-purple {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .sm\:border-deep-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .sm\:border-deep-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .sm\:border-deep-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .sm\:border-deep-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .sm\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .sm\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .sm\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .sm\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .sm\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .sm\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .sm\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .sm\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .sm\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .sm\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .sm\:border-pink {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .sm\:border-pink-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .sm\:border-pink-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .sm\:border-pink-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .sm\:border-pink-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .sm\:border-lime-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .sm\:border-lime-100 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .sm\:border-lime-200 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .sm\:border-lime-300 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .sm\:border-lime-400 {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .sm\:border-lime-500 {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .sm\:border-lime-600 {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .sm\:border-lime-700 {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .sm\:border-lime-800 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .sm\:border-lime-900 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .sm\:border-lime {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .sm\:border-lime-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .sm\:border-lime-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .sm\:border-lime-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .sm\:border-lime-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .sm\:border-amber-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .sm\:border-amber-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .sm\:border-amber-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .sm\:border-amber-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .sm\:border-amber-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .sm\:border-amber-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .sm\:border-amber-600 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .sm\:border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .sm\:border-amber-800 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .sm\:border-amber-900 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .sm\:border-amber {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .sm\:border-amber-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .sm\:border-amber-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .sm\:border-amber-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .sm\:border-amber-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .sm\:border-brown-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .sm\:border-brown-100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .sm\:border-brown-200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .sm\:border-brown-300 {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .sm\:border-brown-400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .sm\:border-brown-500 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .sm\:border-brown-600 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .sm\:border-brown-700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .sm\:border-brown-800 {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .sm\:border-brown-900 {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .sm\:border-brown {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .sm\:border-brown-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .sm\:border-brown-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .sm\:border-brown-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .sm\:border-brown-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .sm\:border-blue-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .sm\:border-blue-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .sm\:border-blue-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .sm\:border-blue-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .sm\:border-blue-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .sm\:border-blue-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .sm\:border-blue-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .sm\:border-blue-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .sm\:border-blue-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .sm\:border-blue-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .sm\:border-blue-gray {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .sm\:border-blue-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .sm\:border-blue-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .sm\:border-blue-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .sm\:border-blue-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .sm\:border-cyan-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .sm\:border-cyan-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .sm\:border-cyan-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .sm\:border-cyan-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .sm\:border-cyan-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .sm\:border-cyan-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .sm\:border-cyan-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .sm\:border-cyan-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .sm\:border-cyan-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .sm\:border-cyan-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .sm\:border-cyan {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .sm\:border-cyan-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .sm\:border-cyan-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .sm\:border-cyan-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .sm\:border-cyan-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-transparent {
    border-color: transparent
  }

  .group:hover .sm\:group-hover\:border-current {
    border-color: currentColor
  }

  .group:hover .sm\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-grey-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-grey-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-grey-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-grey-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-grey-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-grey-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-grey-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-grey-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-grey-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-grey-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-grey {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-grey-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-grey-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-grey-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-grey-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-gray-hover {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .group:hover .sm\:group-hover\:border-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-red-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-red-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-red-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-red-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-yellow-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-yellow-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-yellow-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-yellow-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-green {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-green {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-teal-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-teal-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-teal-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-teal-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-teal-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-teal-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-teal-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-teal-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-teal-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-teal-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-teal {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-teal-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-teal-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-teal-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-teal-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-blue {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-light-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-indigo {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-indigo-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-indigo-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-indigo-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-indigo-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-purple {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-purple {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-deep-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-pink {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-pink-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-pink-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-pink-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-pink-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-lime-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-lime-100 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-lime-200 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-lime-300 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-lime-400 {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-lime-500 {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-lime-600 {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-lime-700 {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-lime-800 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-lime-900 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-lime {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-lime-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-lime-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-lime-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-lime-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-amber-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-amber-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-amber-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-amber-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-amber-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-amber-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-amber-600 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-amber-800 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-amber-900 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-amber {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-amber-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-amber-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-amber-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-amber-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-brown-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-brown-100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-brown-200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-brown-300 {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-brown-400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-brown-500 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-brown-600 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-brown-700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-brown-800 {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-brown-900 {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-brown {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-brown-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-brown-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-brown-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-brown-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-gray {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-blue-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-cyan-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-cyan-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-cyan-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-cyan-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-cyan-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-cyan-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-cyan-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-cyan-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-cyan-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-cyan-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-cyan {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-cyan-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-cyan-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-cyan-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .group:hover .sm\:group-hover\:border-cyan-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-transparent:focus-within {
    border-color: transparent
  }

  .sm\:focus-within\:border-current:focus-within {
    border-color: currentColor
  }

  .sm\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-grey-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-grey-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-grey-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-grey-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-grey-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-grey-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-grey-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-grey-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-grey-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-grey-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-grey-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-grey-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-grey-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-grey-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-gray-hover:focus-within {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .sm\:focus-within\:border-gray-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-gray-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-gray-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-gray-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-red:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-red-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-red-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-red-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-red-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-orange-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-orange-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-orange-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-orange-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-orange-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-orange-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-orange-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-orange-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-orange-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-orange-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-orange:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-orange-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-orange-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-orange-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-orange-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-orange-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-orange-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-orange-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-orange-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-orange-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-orange-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-orange-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-orange-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-orange-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-orange-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-orange:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-orange-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-orange-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-orange-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-orange-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-yellow:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-yellow-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-yellow-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-yellow-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-yellow-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-green-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-green-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-green-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-green-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-green-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-green-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-green-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-green-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-teal-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-teal-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-teal-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-teal-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-teal-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-teal-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-teal-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-teal-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-teal-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-teal-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-teal:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-teal-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-teal-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-teal-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-teal-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-blue-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-blue-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-blue-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-light-blue-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-indigo:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-indigo-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-indigo-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-indigo-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-indigo-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-purple:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-purple-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-purple-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-purple-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-purple-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-purple:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-purple-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-purple-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-purple-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-deep-purple-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-pink:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-pink-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-pink-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-pink-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-pink-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-lime-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-lime-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-lime-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-lime-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-lime-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-lime-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-lime-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-lime-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-lime-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-lime-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-lime:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-lime-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-lime-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-lime-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-lime-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-amber-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-amber-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-amber-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-amber-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-amber-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-amber-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-amber-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-amber-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-amber-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-amber-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-amber:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-amber-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-amber-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-amber-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-amber-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-brown-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-brown-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-brown-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-brown-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-brown-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-brown-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-brown-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-brown-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-brown-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-brown-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-brown:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-brown-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-brown-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-brown-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-brown-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-gray-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-gray-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-gray-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-blue-gray-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-cyan-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-cyan-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-cyan-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-cyan-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-cyan-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-cyan-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-cyan-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-cyan-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-cyan-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-cyan-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-cyan:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-cyan-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-cyan-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-cyan-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .sm\:focus-within\:border-cyan-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .sm\:hover\:border-transparent:hover {
    border-color: transparent
  }

  .sm\:hover\:border-current:hover {
    border-color: currentColor
  }

  .sm\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .sm\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-grey-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .sm\:hover\:border-grey-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .sm\:hover\:border-grey-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .sm\:hover\:border-grey-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .sm\:hover\:border-grey-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .sm\:hover\:border-grey-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:hover\:border-grey-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .sm\:hover\:border-grey-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:hover\:border-grey-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .sm\:hover\:border-grey-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .sm\:hover\:border-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:hover\:border-grey-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .sm\:hover\:border-grey-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .sm\:hover\:border-grey-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .sm\:hover\:border-grey-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray-hover:hover {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .sm\:hover\:border-gray-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-orange-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .sm\:hover\:border-orange-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .sm\:hover\:border-orange-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .sm\:hover\:border-orange-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .sm\:hover\:border-orange-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .sm\:hover\:border-orange-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-orange-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-orange-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-orange-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-orange-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-orange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-orange-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .sm\:hover\:border-orange-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .sm\:hover\:border-orange-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-orange-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-orange-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-orange-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-orange-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-orange-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-orange-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-orange-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-orange-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-orange-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-orange-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-orange-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-orange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-orange-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-orange-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-orange-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-orange-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .sm\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .sm\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .sm\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .sm\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .sm\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .sm\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .sm\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .sm\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .sm\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .sm\:hover\:border-yellow:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .sm\:hover\:border-yellow-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .sm\:hover\:border-yellow-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-yellow-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-yellow-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-green-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-green-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-green-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-green-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .sm\:hover\:border-teal-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .sm\:hover\:border-teal-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .sm\:hover\:border-teal-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .sm\:hover\:border-teal-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .sm\:hover\:border-teal-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .sm\:hover\:border-teal-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .sm\:hover\:border-teal-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .sm\:hover\:border-teal-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .sm\:hover\:border-teal-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .sm\:hover\:border-teal-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .sm\:hover\:border-teal:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .sm\:hover\:border-teal-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .sm\:hover\:border-teal-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .sm\:hover\:border-teal-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .sm\:hover\:border-teal-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-blue-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-blue-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-blue-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-light-blue-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .sm\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .sm\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .sm\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .sm\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .sm\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .sm\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .sm\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .sm\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .sm\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .sm\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .sm\:hover\:border-indigo:hover {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .sm\:hover\:border-indigo-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-indigo-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .sm\:hover\:border-indigo-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .sm\:hover\:border-indigo-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .sm\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .sm\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .sm\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .sm\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .sm\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .sm\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .sm\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .sm\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .sm\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .sm\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .sm\:hover\:border-purple:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .sm\:hover\:border-purple-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .sm\:hover\:border-purple-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .sm\:hover\:border-purple-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .sm\:hover\:border-purple-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-purple:hover {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-purple-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-purple-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-purple-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-deep-purple-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .sm\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .sm\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .sm\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .sm\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .sm\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .sm\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .sm\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .sm\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .sm\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .sm\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .sm\:hover\:border-pink:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .sm\:hover\:border-pink-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .sm\:hover\:border-pink-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .sm\:hover\:border-pink-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .sm\:hover\:border-pink-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .sm\:hover\:border-lime-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .sm\:hover\:border-lime-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .sm\:hover\:border-lime-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .sm\:hover\:border-lime-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .sm\:hover\:border-lime-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .sm\:hover\:border-lime-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .sm\:hover\:border-lime-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .sm\:hover\:border-lime-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .sm\:hover\:border-lime-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .sm\:hover\:border-lime-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .sm\:hover\:border-lime:hover {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .sm\:hover\:border-lime-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .sm\:hover\:border-lime-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .sm\:hover\:border-lime-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-lime-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-amber-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .sm\:hover\:border-amber-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .sm\:hover\:border-amber-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .sm\:hover\:border-amber-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .sm\:hover\:border-amber-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .sm\:hover\:border-amber-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .sm\:hover\:border-amber-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-amber-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-amber-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-amber-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-amber:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .sm\:hover\:border-amber-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .sm\:hover\:border-amber-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .sm\:hover\:border-amber-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-amber-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-brown-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .sm\:hover\:border-brown-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .sm\:hover\:border-brown-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .sm\:hover\:border-brown-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .sm\:hover\:border-brown-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .sm\:hover\:border-brown-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .sm\:hover\:border-brown-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .sm\:hover\:border-brown-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .sm\:hover\:border-brown-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .sm\:hover\:border-brown-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .sm\:hover\:border-brown:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .sm\:hover\:border-brown-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .sm\:hover\:border-brown-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .sm\:hover\:border-brown-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .sm\:hover\:border-brown-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-gray-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-gray-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-gray-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .sm\:hover\:border-blue-gray-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .sm\:focus\:border-transparent:focus {
    border-color: transparent
  }

  .sm\:focus\:border-current:focus {
    border-color: currentColor
  }

  .sm\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .sm\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-grey-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .sm\:focus\:border-grey-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .sm\:focus\:border-grey-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .sm\:focus\:border-grey-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .sm\:focus\:border-grey-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .sm\:focus\:border-grey-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:focus\:border-grey-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .sm\:focus\:border-grey-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:focus\:border-grey-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .sm\:focus\:border-grey-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .sm\:focus\:border-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:focus\:border-grey-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .sm\:focus\:border-grey-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .sm\:focus\:border-grey-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .sm\:focus\:border-grey-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray-hover:focus {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .sm\:focus\:border-gray-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-orange-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .sm\:focus\:border-orange-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .sm\:focus\:border-orange-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .sm\:focus\:border-orange-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .sm\:focus\:border-orange-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .sm\:focus\:border-orange-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-orange-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-orange-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-orange-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-orange-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-orange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-orange-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .sm\:focus\:border-orange-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .sm\:focus\:border-orange-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-orange-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-orange-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-orange-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-orange-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-orange-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-orange-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-orange-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-orange-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-orange-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-orange-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-orange-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-orange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-orange-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-orange-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-orange-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-orange-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .sm\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .sm\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .sm\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .sm\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .sm\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .sm\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .sm\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .sm\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .sm\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .sm\:focus\:border-yellow:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .sm\:focus\:border-yellow-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .sm\:focus\:border-yellow-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-yellow-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-yellow-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-green-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-green-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-green-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-green-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .sm\:focus\:border-teal-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .sm\:focus\:border-teal-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .sm\:focus\:border-teal-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .sm\:focus\:border-teal-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .sm\:focus\:border-teal-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .sm\:focus\:border-teal-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .sm\:focus\:border-teal-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .sm\:focus\:border-teal-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .sm\:focus\:border-teal-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .sm\:focus\:border-teal-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .sm\:focus\:border-teal:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .sm\:focus\:border-teal-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .sm\:focus\:border-teal-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .sm\:focus\:border-teal-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .sm\:focus\:border-teal-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-blue-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-blue-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-blue-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-light-blue-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .sm\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .sm\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .sm\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .sm\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .sm\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .sm\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .sm\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .sm\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .sm\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .sm\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .sm\:focus\:border-indigo:focus {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .sm\:focus\:border-indigo-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-indigo-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .sm\:focus\:border-indigo-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .sm\:focus\:border-indigo-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .sm\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .sm\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .sm\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .sm\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .sm\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .sm\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .sm\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .sm\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .sm\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .sm\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .sm\:focus\:border-purple:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .sm\:focus\:border-purple-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .sm\:focus\:border-purple-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .sm\:focus\:border-purple-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .sm\:focus\:border-purple-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-purple:focus {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-purple-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-purple-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-purple-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-deep-purple-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .sm\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .sm\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .sm\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .sm\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .sm\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .sm\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .sm\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .sm\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .sm\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .sm\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .sm\:focus\:border-pink:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .sm\:focus\:border-pink-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .sm\:focus\:border-pink-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .sm\:focus\:border-pink-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .sm\:focus\:border-pink-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .sm\:focus\:border-lime-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .sm\:focus\:border-lime-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .sm\:focus\:border-lime-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .sm\:focus\:border-lime-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .sm\:focus\:border-lime-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .sm\:focus\:border-lime-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .sm\:focus\:border-lime-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .sm\:focus\:border-lime-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .sm\:focus\:border-lime-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .sm\:focus\:border-lime-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .sm\:focus\:border-lime:focus {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .sm\:focus\:border-lime-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .sm\:focus\:border-lime-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .sm\:focus\:border-lime-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-lime-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-amber-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .sm\:focus\:border-amber-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .sm\:focus\:border-amber-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .sm\:focus\:border-amber-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .sm\:focus\:border-amber-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .sm\:focus\:border-amber-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .sm\:focus\:border-amber-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-amber-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-amber-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-amber-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-amber:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .sm\:focus\:border-amber-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .sm\:focus\:border-amber-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .sm\:focus\:border-amber-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-amber-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-brown-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .sm\:focus\:border-brown-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .sm\:focus\:border-brown-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .sm\:focus\:border-brown-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .sm\:focus\:border-brown-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .sm\:focus\:border-brown-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .sm\:focus\:border-brown-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .sm\:focus\:border-brown-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .sm\:focus\:border-brown-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .sm\:focus\:border-brown-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .sm\:focus\:border-brown:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .sm\:focus\:border-brown-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .sm\:focus\:border-brown-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .sm\:focus\:border-brown-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .sm\:focus\:border-brown-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-gray-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-gray-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-gray-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .sm\:focus\:border-blue-gray-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .sm\:border-opacity-0 {
    --tw-border-opacity: 0
  }

  .sm\:border-opacity-5 {
    --tw-border-opacity: 0.05
  }

  .sm\:border-opacity-10 {
    --tw-border-opacity: 0.1
  }

  .sm\:border-opacity-20 {
    --tw-border-opacity: 0.2
  }

  .sm\:border-opacity-25 {
    --tw-border-opacity: 0.25
  }

  .sm\:border-opacity-30 {
    --tw-border-opacity: 0.3
  }

  .sm\:border-opacity-40 {
    --tw-border-opacity: 0.4
  }

  .sm\:border-opacity-50 {
    --tw-border-opacity: 0.5
  }

  .sm\:border-opacity-60 {
    --tw-border-opacity: 0.6
  }

  .sm\:border-opacity-70 {
    --tw-border-opacity: 0.7
  }

  .sm\:border-opacity-75 {
    --tw-border-opacity: 0.75
  }

  .sm\:border-opacity-80 {
    --tw-border-opacity: 0.8
  }

  .sm\:border-opacity-90 {
    --tw-border-opacity: 0.9
  }

  .sm\:border-opacity-95 {
    --tw-border-opacity: 0.95
  }

  .sm\:border-opacity-100 {
    --tw-border-opacity: 1
  }

  .group:hover .sm\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0
  }

  .group:hover .sm\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05
  }

  .group:hover .sm\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1
  }

  .group:hover .sm\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2
  }

  .group:hover .sm\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25
  }

  .group:hover .sm\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3
  }

  .group:hover .sm\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4
  }

  .group:hover .sm\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5
  }

  .group:hover .sm\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6
  }

  .group:hover .sm\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7
  }

  .group:hover .sm\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75
  }

  .group:hover .sm\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8
  }

  .group:hover .sm\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9
  }

  .group:hover .sm\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95
  }

  .group:hover .sm\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1
  }

  .sm\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0
  }

  .sm\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05
  }

  .sm\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1
  }

  .sm\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2
  }

  .sm\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25
  }

  .sm\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3
  }

  .sm\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4
  }

  .sm\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5
  }

  .sm\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6
  }

  .sm\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7
  }

  .sm\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75
  }

  .sm\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8
  }

  .sm\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9
  }

  .sm\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95
  }

  .sm\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1
  }

  .sm\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0
  }

  .sm\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05
  }

  .sm\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1
  }

  .sm\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2
  }

  .sm\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25
  }

  .sm\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3
  }

  .sm\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4
  }

  .sm\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5
  }

  .sm\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6
  }

  .sm\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7
  }

  .sm\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75
  }

  .sm\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8
  }

  .sm\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9
  }

  .sm\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95
  }

  .sm\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1
  }

  .sm\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0
  }

  .sm\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05
  }

  .sm\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1
  }

  .sm\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2
  }

  .sm\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25
  }

  .sm\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3
  }

  .sm\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4
  }

  .sm\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5
  }

  .sm\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6
  }

  .sm\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7
  }

  .sm\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75
  }

  .sm\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8
  }

  .sm\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9
  }

  .sm\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95
  }

  .sm\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1
  }

  .sm\:rounded-2 {
    border-radius: .2rem
  }

  .sm\:rounded-4 {
    border-radius: .4rem
  }

  .sm\:rounded-6 {
    border-radius: .6rem
  }

  .sm\:rounded-8 {
    border-radius: .8rem
  }

  .sm\:rounded-12 {
    border-radius: 1.2rem
  }

  .sm\:rounded-16 {
    border-radius: 1.6rem
  }

  .sm\:rounded-20 {
    border-radius: 2rem
  }

  .sm\:rounded-24 {
    border-radius: 2.4rem
  }

  .sm\:rounded-28 {
    border-radius: 2.8rem
  }

  .sm\:rounded-32 {
    border-radius: 3.2rem
  }

  .sm\:rounded-none {
    border-radius: 0
  }

  .sm\:rounded-sm {
    border-radius: .2rem
  }

  .sm\:rounded {
    border-radius: .4rem
  }

  .sm\:rounded-md {
    border-radius: .6rem
  }

  .sm\:rounded-lg {
    border-radius: .8rem
  }

  .sm\:rounded-xl {
    border-radius: 1.2rem
  }

  .sm\:rounded-2xl {
    border-radius: 1.6rem
  }

  .sm\:rounded-3xl {
    border-radius: 2.4rem
  }

  .sm\:rounded-full {
    border-radius: 9999px
  }

  .sm\:rounded-t-2 {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem
  }

  .sm\:rounded-r-2 {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
  }

  .sm\:rounded-b-2 {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .sm\:rounded-l-2 {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .sm\:rounded-t-4 {
    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem
  }

  .sm\:rounded-r-4 {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem
  }

  .sm\:rounded-b-4 {
    border-bottom-right-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .sm\:rounded-l-4 {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .sm\:rounded-t-6 {
    border-top-left-radius: .6rem;
    border-top-right-radius: .6rem
  }

  .sm\:rounded-r-6 {
    border-top-right-radius: .6rem;
    border-bottom-right-radius: .6rem
  }

  .sm\:rounded-b-6 {
    border-bottom-right-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .sm\:rounded-l-6 {
    border-top-left-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .sm\:rounded-t-8 {
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem
  }

  .sm\:rounded-r-8 {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem
  }

  .sm\:rounded-b-8 {
    border-bottom-right-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .sm\:rounded-l-8 {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .sm\:rounded-t-12 {
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem
  }

  .sm\:rounded-r-12 {
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem
  }

  .sm\:rounded-b-12 {
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .sm\:rounded-l-12 {
    border-top-left-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .sm\:rounded-t-16 {
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem
  }

  .sm\:rounded-r-16 {
    border-top-right-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem
  }

  .sm\:rounded-b-16 {
    border-bottom-right-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .sm\:rounded-l-16 {
    border-top-left-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .sm\:rounded-t-20 {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem
  }

  .sm\:rounded-r-20 {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem
  }

  .sm\:rounded-b-20 {
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem
  }

  .sm\:rounded-l-20 {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem
  }

  .sm\:rounded-t-24 {
    border-top-left-radius: 2.4rem;
    border-top-right-radius: 2.4rem
  }

  .sm\:rounded-r-24 {
    border-top-right-radius: 2.4rem;
    border-bottom-right-radius: 2.4rem
  }

  .sm\:rounded-b-24 {
    border-bottom-right-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .sm\:rounded-l-24 {
    border-top-left-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .sm\:rounded-t-28 {
    border-top-left-radius: 2.8rem;
    border-top-right-radius: 2.8rem
  }

  .sm\:rounded-r-28 {
    border-top-right-radius: 2.8rem;
    border-bottom-right-radius: 2.8rem
  }

  .sm\:rounded-b-28 {
    border-bottom-right-radius: 2.8rem;
    border-bottom-left-radius: 2.8rem
  }

  .sm\:rounded-l-28 {
    border-top-left-radius: 2.8rem;
    border-bottom-left-radius: 2.8rem
  }

  .sm\:rounded-t-32 {
    border-top-left-radius: 3.2rem;
    border-top-right-radius: 3.2rem
  }

  .sm\:rounded-r-32 {
    border-top-right-radius: 3.2rem;
    border-bottom-right-radius: 3.2rem
  }

  .sm\:rounded-b-32 {
    border-bottom-right-radius: 3.2rem;
    border-bottom-left-radius: 3.2rem
  }

  .sm\:rounded-l-32 {
    border-top-left-radius: 3.2rem;
    border-bottom-left-radius: 3.2rem
  }

  .sm\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .sm\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .sm\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .sm\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .sm\:rounded-t-sm {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem
  }

  .sm\:rounded-r-sm {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
  }

  .sm\:rounded-b-sm {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .sm\:rounded-l-sm {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .sm\:rounded-t {
    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem
  }

  .sm\:rounded-r {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem
  }

  .sm\:rounded-b {
    border-bottom-right-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .sm\:rounded-l {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .sm\:rounded-t-md {
    border-top-left-radius: .6rem;
    border-top-right-radius: .6rem
  }

  .sm\:rounded-r-md {
    border-top-right-radius: .6rem;
    border-bottom-right-radius: .6rem
  }

  .sm\:rounded-b-md {
    border-bottom-right-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .sm\:rounded-l-md {
    border-top-left-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .sm\:rounded-t-lg {
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem
  }

  .sm\:rounded-r-lg {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem
  }

  .sm\:rounded-b-lg {
    border-bottom-right-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .sm\:rounded-l-lg {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .sm\:rounded-t-xl {
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem
  }

  .sm\:rounded-r-xl {
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem
  }

  .sm\:rounded-b-xl {
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .sm\:rounded-l-xl {
    border-top-left-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .sm\:rounded-t-2xl {
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem
  }

  .sm\:rounded-r-2xl {
    border-top-right-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem
  }

  .sm\:rounded-b-2xl {
    border-bottom-right-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .sm\:rounded-l-2xl {
    border-top-left-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .sm\:rounded-t-3xl {
    border-top-left-radius: 2.4rem;
    border-top-right-radius: 2.4rem
  }

  .sm\:rounded-r-3xl {
    border-top-right-radius: 2.4rem;
    border-bottom-right-radius: 2.4rem
  }

  .sm\:rounded-b-3xl {
    border-bottom-right-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .sm\:rounded-l-3xl {
    border-top-left-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .sm\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .sm\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .sm\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .sm\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .sm\:rounded-tl-2 {
    border-top-left-radius: .2rem
  }

  .sm\:rounded-tr-2 {
    border-top-right-radius: .2rem
  }

  .sm\:rounded-br-2 {
    border-bottom-right-radius: .2rem
  }

  .sm\:rounded-bl-2 {
    border-bottom-left-radius: .2rem
  }

  .sm\:rounded-tl-4 {
    border-top-left-radius: .4rem
  }

  .sm\:rounded-tr-4 {
    border-top-right-radius: .4rem
  }

  .sm\:rounded-br-4 {
    border-bottom-right-radius: .4rem
  }

  .sm\:rounded-bl-4 {
    border-bottom-left-radius: .4rem
  }

  .sm\:rounded-tl-6 {
    border-top-left-radius: .6rem
  }

  .sm\:rounded-tr-6 {
    border-top-right-radius: .6rem
  }

  .sm\:rounded-br-6 {
    border-bottom-right-radius: .6rem
  }

  .sm\:rounded-bl-6 {
    border-bottom-left-radius: .6rem
  }

  .sm\:rounded-tl-8 {
    border-top-left-radius: .8rem
  }

  .sm\:rounded-tr-8 {
    border-top-right-radius: .8rem
  }

  .sm\:rounded-br-8 {
    border-bottom-right-radius: .8rem
  }

  .sm\:rounded-bl-8 {
    border-bottom-left-radius: .8rem
  }

  .sm\:rounded-tl-12 {
    border-top-left-radius: 1.2rem
  }

  .sm\:rounded-tr-12 {
    border-top-right-radius: 1.2rem
  }

  .sm\:rounded-br-12 {
    border-bottom-right-radius: 1.2rem
  }

  .sm\:rounded-bl-12 {
    border-bottom-left-radius: 1.2rem
  }

  .sm\:rounded-tl-16 {
    border-top-left-radius: 1.6rem
  }

  .sm\:rounded-tr-16 {
    border-top-right-radius: 1.6rem
  }

  .sm\:rounded-br-16 {
    border-bottom-right-radius: 1.6rem
  }

  .sm\:rounded-bl-16 {
    border-bottom-left-radius: 1.6rem
  }

  .sm\:rounded-tl-20 {
    border-top-left-radius: 2rem
  }

  .sm\:rounded-tr-20 {
    border-top-right-radius: 2rem
  }

  .sm\:rounded-br-20 {
    border-bottom-right-radius: 2rem
  }

  .sm\:rounded-bl-20 {
    border-bottom-left-radius: 2rem
  }

  .sm\:rounded-tl-24 {
    border-top-left-radius: 2.4rem
  }

  .sm\:rounded-tr-24 {
    border-top-right-radius: 2.4rem
  }

  .sm\:rounded-br-24 {
    border-bottom-right-radius: 2.4rem
  }

  .sm\:rounded-bl-24 {
    border-bottom-left-radius: 2.4rem
  }

  .sm\:rounded-tl-28 {
    border-top-left-radius: 2.8rem
  }

  .sm\:rounded-tr-28 {
    border-top-right-radius: 2.8rem
  }

  .sm\:rounded-br-28 {
    border-bottom-right-radius: 2.8rem
  }

  .sm\:rounded-bl-28 {
    border-bottom-left-radius: 2.8rem
  }

  .sm\:rounded-tl-32 {
    border-top-left-radius: 3.2rem
  }

  .sm\:rounded-tr-32 {
    border-top-right-radius: 3.2rem
  }

  .sm\:rounded-br-32 {
    border-bottom-right-radius: 3.2rem
  }

  .sm\:rounded-bl-32 {
    border-bottom-left-radius: 3.2rem
  }

  .sm\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .sm\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .sm\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .sm\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .sm\:rounded-tl-sm {
    border-top-left-radius: .2rem
  }

  .sm\:rounded-tr-sm {
    border-top-right-radius: .2rem
  }

  .sm\:rounded-br-sm {
    border-bottom-right-radius: .2rem
  }

  .sm\:rounded-bl-sm {
    border-bottom-left-radius: .2rem
  }

  .sm\:rounded-tl {
    border-top-left-radius: .4rem
  }

  .sm\:rounded-tr {
    border-top-right-radius: .4rem
  }

  .sm\:rounded-br {
    border-bottom-right-radius: .4rem
  }

  .sm\:rounded-bl {
    border-bottom-left-radius: .4rem
  }

  .sm\:rounded-tl-md {
    border-top-left-radius: .6rem
  }

  .sm\:rounded-tr-md {
    border-top-right-radius: .6rem
  }

  .sm\:rounded-br-md {
    border-bottom-right-radius: .6rem
  }

  .sm\:rounded-bl-md {
    border-bottom-left-radius: .6rem
  }

  .sm\:rounded-tl-lg {
    border-top-left-radius: .8rem
  }

  .sm\:rounded-tr-lg {
    border-top-right-radius: .8rem
  }

  .sm\:rounded-br-lg {
    border-bottom-right-radius: .8rem
  }

  .sm\:rounded-bl-lg {
    border-bottom-left-radius: .8rem
  }

  .sm\:rounded-tl-xl {
    border-top-left-radius: 1.2rem
  }

  .sm\:rounded-tr-xl {
    border-top-right-radius: 1.2rem
  }

  .sm\:rounded-br-xl {
    border-bottom-right-radius: 1.2rem
  }

  .sm\:rounded-bl-xl {
    border-bottom-left-radius: 1.2rem
  }

  .sm\:rounded-tl-2xl {
    border-top-left-radius: 1.6rem
  }

  .sm\:rounded-tr-2xl {
    border-top-right-radius: 1.6rem
  }

  .sm\:rounded-br-2xl {
    border-bottom-right-radius: 1.6rem
  }

  .sm\:rounded-bl-2xl {
    border-bottom-left-radius: 1.6rem
  }

  .sm\:rounded-tl-3xl {
    border-top-left-radius: 2.4rem
  }

  .sm\:rounded-tr-3xl {
    border-top-right-radius: 2.4rem
  }

  .sm\:rounded-br-3xl {
    border-bottom-right-radius: 2.4rem
  }

  .sm\:rounded-bl-3xl {
    border-bottom-left-radius: 2.4rem
  }

  .sm\:rounded-tl-full {
    border-top-left-radius: 9999px
  }

  .sm\:rounded-tr-full {
    border-top-right-radius: 9999px
  }

  .sm\:rounded-br-full {
    border-bottom-right-radius: 9999px
  }

  .sm\:rounded-bl-full {
    border-bottom-left-radius: 9999px
  }

  .sm\:border-solid {
    border-style: solid
  }

  .sm\:border-dashed {
    border-style: dashed
  }

  .sm\:border-dotted {
    border-style: dotted
  }

  .sm\:border-double {
    border-style: double
  }

  .sm\:border-none {
    border-style: none
  }

  .sm\:border-0 {
    border-width: 0px
  }

  .sm\:border-1 {
    border-width: 1px
  }

  .sm\:border-2 {
    border-width: 2px
  }

  .sm\:border-3 {
    border-width: 3px
  }

  .sm\:border-4 {
    border-width: 4px
  }

  .sm\:border-8 {
    border-width: 8px
  }

  .sm\:border {
    border-width: 1px
  }

  .sm\:border-t-0 {
    border-top-width: 0px
  }

  .sm\:border-r-0 {
    border-right-width: 0px
  }

  .sm\:border-b-0 {
    border-bottom-width: 0px
  }

  .sm\:border-l-0 {
    border-left-width: 0px
  }

  .sm\:border-t-1 {
    border-top-width: 1px
  }

  .sm\:border-r-1 {
    border-right-width: 1px
  }

  .sm\:border-b-1 {
    border-bottom-width: 1px
  }

  .sm\:border-l-1 {
    border-left-width: 1px
  }

  .sm\:border-t-2 {
    border-top-width: 2px
  }

  .sm\:border-r-2 {
    border-right-width: 2px
  }

  .sm\:border-b-2 {
    border-bottom-width: 2px
  }

  .sm\:border-l-2 {
    border-left-width: 2px
  }

  .sm\:border-t-3 {
    border-top-width: 3px
  }

  .sm\:border-r-3 {
    border-right-width: 3px
  }

  .sm\:border-b-3 {
    border-bottom-width: 3px
  }

  .sm\:border-l-3 {
    border-left-width: 3px
  }

  .sm\:border-t-4 {
    border-top-width: 4px
  }

  .sm\:border-r-4 {
    border-right-width: 4px
  }

  .sm\:border-b-4 {
    border-bottom-width: 4px
  }

  .sm\:border-l-4 {
    border-left-width: 4px
  }

  .sm\:border-t-8 {
    border-top-width: 8px
  }

  .sm\:border-r-8 {
    border-right-width: 8px
  }

  .sm\:border-b-8 {
    border-bottom-width: 8px
  }

  .sm\:border-l-8 {
    border-left-width: 8px
  }

  .sm\:border-t {
    border-top-width: 1px
  }

  .sm\:border-r {
    border-right-width: 1px
  }

  .sm\:border-b {
    border-bottom-width: 1px
  }

  .sm\:border-l {
    border-left-width: 1px
  }

  .sm\:box-border {
    box-sizing: border-box
  }

  .sm\:box-content {
    box-sizing: content-box
  }

  .sm\:cursor-auto {
    cursor: auto
  }

  .sm\:cursor-default {
    cursor: default
  }

  .sm\:cursor-pointer {
    cursor: pointer
  }

  .sm\:cursor-wait {
    cursor: wait
  }

  .sm\:cursor-text {
    cursor: text
  }

  .sm\:cursor-move {
    cursor: move
  }

  .sm\:cursor-not-allowed {
    cursor: not-allowed
  }

  .sm\:block {
    display: block
  }

  .sm\:inline-block {
    display: inline-block
  }

  .sm\:inline {
    display: inline
  }

  .sm\:flex {
    display: flex
  }

  .sm\:inline-flex {
    display: inline-flex
  }

  .sm\:table {
    display: table
  }

  .sm\:table-caption {
    display: table-caption
  }

  .sm\:table-cell {
    display: table-cell
  }

  .sm\:table-column {
    display: table-column
  }

  .sm\:table-column-group {
    display: table-column-group
  }

  .sm\:table-footer-group {
    display: table-footer-group
  }

  .sm\:table-header-group {
    display: table-header-group
  }

  .sm\:table-row-group {
    display: table-row-group
  }

  .sm\:table-row {
    display: table-row
  }

  .sm\:flow-root {
    display: flow-root
  }

  .sm\:grid {
    display: grid
  }

  .sm\:inline-grid {
    display: inline-grid
  }

  .sm\:contents {
    display: contents
  }

  .sm\:hidden {
    display: none
  }

  .sm\:hover\:block:hover {
    display: block
  }

  .sm\:hover\:inline-block:hover {
    display: inline-block
  }

  .sm\:hover\:inline:hover {
    display: inline
  }

  .sm\:hover\:flex:hover {
    display: flex
  }

  .sm\:hover\:inline-flex:hover {
    display: inline-flex
  }

  .sm\:hover\:table:hover {
    display: table
  }

  .sm\:hover\:table-caption:hover {
    display: table-caption
  }

  .sm\:hover\:table-cell:hover {
    display: table-cell
  }

  .sm\:hover\:table-column:hover {
    display: table-column
  }

  .sm\:hover\:table-column-group:hover {
    display: table-column-group
  }

  .sm\:hover\:table-footer-group:hover {
    display: table-footer-group
  }

  .sm\:hover\:table-header-group:hover {
    display: table-header-group
  }

  .sm\:hover\:table-row-group:hover {
    display: table-row-group
  }

  .sm\:hover\:table-row:hover {
    display: table-row
  }

  .sm\:hover\:flow-root:hover {
    display: flow-root
  }

  .sm\:hover\:grid:hover {
    display: grid
  }

  .sm\:hover\:inline-grid:hover {
    display: inline-grid
  }

  .sm\:hover\:contents:hover {
    display: contents
  }

  .sm\:hover\:hidden:hover {
    display: none
  }

  .sm\:focus\:block:focus {
    display: block
  }

  .sm\:focus\:inline-block:focus {
    display: inline-block
  }

  .sm\:focus\:inline:focus {
    display: inline
  }

  .sm\:focus\:flex:focus {
    display: flex
  }

  .sm\:focus\:inline-flex:focus {
    display: inline-flex
  }

  .sm\:focus\:table:focus {
    display: table
  }

  .sm\:focus\:table-caption:focus {
    display: table-caption
  }

  .sm\:focus\:table-cell:focus {
    display: table-cell
  }

  .sm\:focus\:table-column:focus {
    display: table-column
  }

  .sm\:focus\:table-column-group:focus {
    display: table-column-group
  }

  .sm\:focus\:table-footer-group:focus {
    display: table-footer-group
  }

  .sm\:focus\:table-header-group:focus {
    display: table-header-group
  }

  .sm\:focus\:table-row-group:focus {
    display: table-row-group
  }

  .sm\:focus\:table-row:focus {
    display: table-row
  }

  .sm\:focus\:flow-root:focus {
    display: flow-root
  }

  .sm\:focus\:grid:focus {
    display: grid
  }

  .sm\:focus\:inline-grid:focus {
    display: inline-grid
  }

  .sm\:focus\:contents:focus {
    display: contents
  }

  .sm\:focus\:hidden:focus {
    display: none
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .sm\:flex-col {
    flex-direction: column
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .sm\:flex-wrap {
    flex-wrap: wrap
  }

  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap
  }

  .sm\:place-items-auto {
    place-items: auto
  }

  .sm\:place-items-start {
    place-items: start
  }

  .sm\:place-items-end {
    place-items: end
  }

  .sm\:place-items-center {
    place-items: center
  }

  .sm\:place-items-stretch {
    place-items: stretch
  }

  .sm\:place-content-center {
    place-content: center
  }

  .sm\:place-content-start {
    place-content: start
  }

  .sm\:place-content-end {
    place-content: end
  }

  .sm\:place-content-between {
    place-content: space-between
  }

  .sm\:place-content-around {
    place-content: space-around
  }

  .sm\:place-content-evenly {
    place-content: space-evenly
  }

  .sm\:place-content-stretch {
    place-content: stretch
  }

  .sm\:place-self-auto {
    place-self: auto
  }

  .sm\:place-self-start {
    place-self: start
  }

  .sm\:place-self-end {
    place-self: end
  }

  .sm\:place-self-center {
    place-self: center
  }

  .sm\:place-self-stretch {
    place-self: stretch
  }

  .sm\:items-start {
    align-items: flex-start
  }

  .sm\:items-end {
    align-items: flex-end
  }

  .sm\:items-center {
    align-items: center
  }

  .sm\:items-baseline {
    align-items: baseline
  }

  .sm\:items-stretch {
    align-items: stretch
  }

  .sm\:content-center {
    align-content: center
  }

  .sm\:content-start {
    align-content: flex-start
  }

  .sm\:content-end {
    align-content: flex-end
  }

  .sm\:content-between {
    align-content: space-between
  }

  .sm\:content-around {
    align-content: space-around
  }

  .sm\:content-evenly {
    align-content: space-evenly
  }

  .sm\:self-auto {
    align-self: auto
  }

  .sm\:self-start {
    align-self: flex-start
  }

  .sm\:self-end {
    align-self: flex-end
  }

  .sm\:self-center {
    align-self: center
  }

  .sm\:self-stretch {
    align-self: stretch
  }

  .sm\:justify-items-auto {
    justify-items: auto
  }

  .sm\:justify-items-start {
    justify-items: start
  }

  .sm\:justify-items-end {
    justify-items: end
  }

  .sm\:justify-items-center {
    justify-items: center
  }

  .sm\:justify-items-stretch {
    justify-items: stretch
  }

  .sm\:justify-start {
    justify-content: flex-start
  }

  .sm\:justify-end {
    justify-content: flex-end
  }

  .sm\:justify-center {
    justify-content: center
  }

  .sm\:justify-between {
    justify-content: space-between
  }

  .sm\:justify-around {
    justify-content: space-around
  }

  .sm\:justify-evenly {
    justify-content: space-evenly
  }

  .sm\:justify-self-auto {
    justify-self: auto
  }

  .sm\:justify-self-start {
    justify-self: start
  }

  .sm\:justify-self-end {
    justify-self: end
  }

  .sm\:justify-self-center {
    justify-self: center
  }

  .sm\:justify-self-stretch {
    justify-self: stretch
  }

  .sm\:flex-1 {
    flex: 1 1 0%
  }

  .sm\:flex-auto {
    flex: 1 1 auto
  }

  .sm\:flex-initial {
    flex: 0 1 auto
  }

  .sm\:flex-none {
    flex: none
  }

  .sm\:flex-grow-0 {
    flex-grow: 0
  }

  .sm\:flex-grow {
    flex-grow: 1
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0
  }

  .sm\:flex-shrink {
    flex-shrink: 1
  }

  .sm\:order-1 {
    order: 1
  }

  .sm\:order-2 {
    order: 2
  }

  .sm\:order-3 {
    order: 3
  }

  .sm\:order-4 {
    order: 4
  }

  .sm\:order-5 {
    order: 5
  }

  .sm\:order-6 {
    order: 6
  }

  .sm\:order-7 {
    order: 7
  }

  .sm\:order-8 {
    order: 8
  }

  .sm\:order-9 {
    order: 9
  }

  .sm\:order-10 {
    order: 10
  }

  .sm\:order-11 {
    order: 11
  }

  .sm\:order-12 {
    order: 12
  }

  .sm\:order-first {
    order: -9999
  }

  .sm\:order-last {
    order: 9999
  }

  .sm\:order-none {
    order: 0
  }

  .sm\:float-right {
    float: right
  }

  .sm\:float-left {
    float: left
  }

  .sm\:float-none {
    float: none
  }

  [dir='ltr'] .sm\:ltr\:float-right,[dir='ltr'].sm\:ltr\:float-right {
    float: right
  }

  [dir='ltr'] .sm\:ltr\:float-left,[dir='ltr'].sm\:ltr\:float-left {
    float: left
  }

  [dir='ltr'] .sm\:ltr\:float-none,[dir='ltr'].sm\:ltr\:float-none {
    float: none
  }

  [dir='rtl'] .sm\:rtl\:float-right,[dir='rtl'].sm\:rtl\:float-right {
    float: right
  }

  [dir='rtl'] .sm\:rtl\:float-left,[dir='rtl'].sm\:rtl\:float-left {
    float: left
  }

  [dir='rtl'] .sm\:rtl\:float-none,[dir='rtl'].sm\:rtl\:float-none {
    float: none
  }

  .sm\:clear-left {
    clear: left
  }

  .sm\:clear-right {
    clear: right
  }

  .sm\:clear-both {
    clear: both
  }

  .sm\:clear-none {
    clear: none
  }

  .sm\:font-sans {
    font-family: Muli, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .sm\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .sm\:font-mono {
    font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace
  }

  .sm\:font-100 {
    font-weight: 100
  }

  .sm\:font-200 {
    font-weight: 200
  }

  .sm\:font-300 {
    font-weight: 300
  }

  .sm\:font-400 {
    font-weight: 400
  }

  .sm\:font-500 {
    font-weight: 500
  }

  .sm\:font-600 {
    font-weight: 600
  }

  .sm\:font-700 {
    font-weight: 700
  }

  .sm\:font-800 {
    font-weight: 800
  }

  .sm\:font-900 {
    font-weight: 900
  }

  .sm\:font-thin {
    font-weight: 100
  }

  .sm\:font-extralight {
    font-weight: 200
  }

  .sm\:font-light {
    font-weight: 300
  }

  .sm\:font-normal {
    font-weight: 400
  }

  .sm\:font-medium {
    font-weight: 500
  }

  .sm\:font-semibold {
    font-weight: 600
  }

  .sm\:font-bold {
    font-weight: 700
  }

  .sm\:font-extrabold {
    font-weight: 800
  }

  .sm\:font-black {
    font-weight: 900
  }

  .sm\:hover\:font-100:hover {
    font-weight: 100
  }

  .sm\:hover\:font-200:hover {
    font-weight: 200
  }

  .sm\:hover\:font-300:hover {
    font-weight: 300
  }

  .sm\:hover\:font-400:hover {
    font-weight: 400
  }

  .sm\:hover\:font-500:hover {
    font-weight: 500
  }

  .sm\:hover\:font-600:hover {
    font-weight: 600
  }

  .sm\:hover\:font-700:hover {
    font-weight: 700
  }

  .sm\:hover\:font-800:hover {
    font-weight: 800
  }

  .sm\:hover\:font-900:hover {
    font-weight: 900
  }

  .sm\:hover\:font-thin:hover {
    font-weight: 100
  }

  .sm\:hover\:font-extralight:hover {
    font-weight: 200
  }

  .sm\:hover\:font-light:hover {
    font-weight: 300
  }

  .sm\:hover\:font-normal:hover {
    font-weight: 400
  }

  .sm\:hover\:font-medium:hover {
    font-weight: 500
  }

  .sm\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .sm\:hover\:font-bold:hover {
    font-weight: 700
  }

  .sm\:hover\:font-extrabold:hover {
    font-weight: 800
  }

  .sm\:hover\:font-black:hover {
    font-weight: 900
  }

  .sm\:focus\:font-100:focus {
    font-weight: 100
  }

  .sm\:focus\:font-200:focus {
    font-weight: 200
  }

  .sm\:focus\:font-300:focus {
    font-weight: 300
  }

  .sm\:focus\:font-400:focus {
    font-weight: 400
  }

  .sm\:focus\:font-500:focus {
    font-weight: 500
  }

  .sm\:focus\:font-600:focus {
    font-weight: 600
  }

  .sm\:focus\:font-700:focus {
    font-weight: 700
  }

  .sm\:focus\:font-800:focus {
    font-weight: 800
  }

  .sm\:focus\:font-900:focus {
    font-weight: 900
  }

  .sm\:focus\:font-thin:focus {
    font-weight: 100
  }

  .sm\:focus\:font-extralight:focus {
    font-weight: 200
  }

  .sm\:focus\:font-light:focus {
    font-weight: 300
  }

  .sm\:focus\:font-normal:focus {
    font-weight: 400
  }

  .sm\:focus\:font-medium:focus {
    font-weight: 500
  }

  .sm\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .sm\:focus\:font-bold:focus {
    font-weight: 700
  }

  .sm\:focus\:font-extrabold:focus {
    font-weight: 800
  }

  .sm\:focus\:font-black:focus {
    font-weight: 900
  }

  .sm\:h-0 {
    height: 0
  }

  .sm\:h-1 {
    height: 0.1rem
  }

  .sm\:h-2 {
    height: 0.2rem
  }

  .sm\:h-3 {
    height: 0.3rem
  }

  .sm\:h-4 {
    height: 0.4rem
  }

  .sm\:h-5 {
    height: 0.5rem
  }

  .sm\:h-6 {
    height: 0.6rem
  }

  .sm\:h-7 {
    height: 0.7rem
  }

  .sm\:h-8 {
    height: 0.8rem
  }

  .sm\:h-9 {
    height: 0.9rem
  }

  .sm\:h-10 {
    height: 1.0rem
  }

  .sm\:h-12 {
    height: 1.2rem
  }

  .sm\:h-14 {
    height: 1.4rem
  }

  .sm\:h-16 {
    height: 1.6rem
  }

  .sm\:h-20 {
    height: 2rem
  }

  .sm\:h-24 {
    height: 2.4rem
  }

  .sm\:h-28 {
    height: 2.8rem
  }

  .sm\:h-32 {
    height: 3.2rem
  }

  .sm\:h-36 {
    height: 3.6rem
  }

  .sm\:h-40 {
    height: 4rem
  }

  .sm\:h-44 {
    height: 4.4rem
  }

  .sm\:h-48 {
    height: 4.8rem
  }

  .sm\:h-52 {
    height: 5.2rem
  }

  .sm\:h-56 {
    height: 5.6rem
  }

  .sm\:h-60 {
    height: 6rem
  }

  .sm\:h-64 {
    height: 6.4rem
  }

  .sm\:h-68 {
    height: 6.8rem
  }

  .sm\:h-72 {
    height: 7.2rem
  }

  .sm\:h-76 {
    height: 7.6rem
  }

  .sm\:h-80 {
    height: 8rem
  }

  .sm\:h-84 {
    height: 8.4rem
  }

  .sm\:h-88 {
    height: 8.8rem
  }

  .sm\:h-92 {
    height: 9.2rem
  }

  .sm\:h-96 {
    height: 9.6rem
  }

  .sm\:h-128 {
    height: 12.8rem
  }

  .sm\:h-136 {
    height: 13.6rem
  }

  .sm\:h-160 {
    height: 16rem
  }

  .sm\:h-192 {
    height: 19.2rem
  }

  .sm\:h-200 {
    height: 20rem
  }

  .sm\:h-208 {
    height: 20.8rem
  }

  .sm\:h-216 {
    height: 21.6rem
  }

  .sm\:h-224 {
    height: 22.4rem
  }

  .sm\:h-256 {
    height: 25.6rem
  }

  .sm\:h-288 {
    height: 28.8rem
  }

  .sm\:h-320 {
    height: 32rem
  }

  .sm\:h-360 {
    height: 36rem
  }

  .sm\:h-384 {
    height: 38.4rem
  }

  .sm\:h-400 {
    height: 40rem
  }

  .sm\:h-512 {
    height: 51.2rem
  }

  .sm\:h-640 {
    height: 64rem
  }

  .sm\:h-auto {
    height: auto
  }

  .sm\:h-xs {
    height: 32rem
  }

  .sm\:h-sm {
    height: 48rem
  }

  .sm\:h-md {
    height: 64rem
  }

  .sm\:h-lg {
    height: 80rem
  }

  .sm\:h-xl {
    height: 96rem
  }

  .sm\:h-2xl {
    height: 112rem
  }

  .sm\:h-3xl {
    height: 128rem
  }

  .sm\:h-4xl {
    height: 144rem
  }

  .sm\:h-5xl {
    height: 160rem
  }

  .sm\:h-px {
    height: 1px
  }

  .sm\:h-0\.5 {
    height: 0.05rem
  }

  .sm\:h-1\.5 {
    height: 0.15rem
  }

  .sm\:h-2\.5 {
    height: 0.25rem
  }

  .sm\:h-3\.5 {
    height: 0.35rem
  }

  .sm\:h-1\/2 {
    height: 50%
  }

  .sm\:h-1\/3 {
    height: 33.333333%
  }

  .sm\:h-2\/3 {
    height: 66.666667%
  }

  .sm\:h-1\/4 {
    height: 25%
  }

  .sm\:h-2\/4 {
    height: 50%
  }

  .sm\:h-3\/4 {
    height: 75%
  }

  .sm\:h-1\/5 {
    height: 20%
  }

  .sm\:h-2\/5 {
    height: 40%
  }

  .sm\:h-3\/5 {
    height: 60%
  }

  .sm\:h-4\/5 {
    height: 80%
  }

  .sm\:h-1\/6 {
    height: 16.666667%
  }

  .sm\:h-2\/6 {
    height: 33.333333%
  }

  .sm\:h-3\/6 {
    height: 50%
  }

  .sm\:h-4\/6 {
    height: 66.666667%
  }

  .sm\:h-5\/6 {
    height: 83.333333%
  }

  .sm\:h-full {
    height: 100%
  }

  .sm\:h-screen {
    height: 100vh
  }

  .sm\:text-10 {
    font-size: 1rem
  }

  .sm\:text-11 {
    font-size: 1.1rem
  }

  .sm\:text-12 {
    font-size: 1.2rem
  }

  .sm\:text-13 {
    font-size: 1.3rem
  }

  .sm\:text-14 {
    font-size: 1.4rem
  }

  .sm\:text-15 {
    font-size: 1.5rem
  }

  .sm\:text-16 {
    font-size: 1.6rem
  }

  .sm\:text-17 {
    font-size: 1.7rem
  }

  .sm\:text-18 {
    font-size: 1.8rem
  }

  .sm\:text-19 {
    font-size: 1.9rem
  }

  .sm\:text-20 {
    font-size: 2rem
  }

  .sm\:text-24 {
    font-size: 2.4rem
  }

  .sm\:text-28 {
    font-size: 2.8rem
  }

  .sm\:text-32 {
    font-size: 3.2rem
  }

  .sm\:text-36 {
    font-size: 3.6rem
  }

  .sm\:text-40 {
    font-size: 4rem
  }

  .sm\:text-44 {
    font-size: 4.4rem
  }

  .sm\:text-48 {
    font-size: 4.8rem
  }

  .sm\:text-52 {
    font-size: 5.2rem
  }

  .sm\:text-56 {
    font-size: 5.6rem
  }

  .sm\:text-60 {
    font-size: 6rem
  }

  .sm\:text-64 {
    font-size: 6.4rem
  }

  .sm\:text-68 {
    font-size: 6.8rem
  }

  .sm\:text-72 {
    font-size: 7.2rem
  }

  .sm\:text-96 {
    font-size: 9.6rem
  }

  .sm\:text-128 {
    font-size: 12.8rem
  }

  .sm\:text-xs {
    font-size: 1.2rem;
    line-height: 1.6rem
  }

  .sm\:text-sm {
    font-size: 1.4rem;
    line-height: 2rem
  }

  .sm\:text-base {
    font-size: 1.6rem;
    line-height: 2.4rem
  }

  .sm\:text-lg {
    font-size: 1.8rem;
    line-height: 2.8em
  }

  .sm\:text-xl {
    font-size: 2rem;
    line-height: 2.8rem
  }

  .sm\:text-2xl {
    font-size: 2.4rem;
    line-height: 3.2rem
  }

  .sm\:text-3xl {
    font-size: 3rem;
    line-height: 3.6rem
  }

  .sm\:text-4xl {
    font-size: 3.6rem;
    line-height: 4rem
  }

  .sm\:text-5xl {
    font-size: 4.8rem;
    line-height: 1
  }

  .sm\:text-6xl {
    font-size: 6rem;
    line-height: 1
  }

  .sm\:text-7xl {
    font-size: 7.2rem;
    line-height: 1
  }

  .sm\:text-8xl {
    font-size: 9.6rem;
    line-height: 1
  }

  .sm\:text-9xl {
    font-size: 12.8rem;
    line-height: 1
  }

  .sm\:leading-3 {
    line-height: 1.2rem
  }

  .sm\:leading-4 {
    line-height: 1.6rem
  }

  .sm\:leading-5 {
    line-height: 2rem
  }

  .sm\:leading-6 {
    line-height: 2.4rem
  }

  .sm\:leading-7 {
    line-height: 2.8rem
  }

  .sm\:leading-8 {
    line-height: 3.2rem
  }

  .sm\:leading-9 {
    line-height: 3.6rem
  }

  .sm\:leading-10 {
    line-height: 4rem
  }

  .sm\:leading-none {
    line-height: 1
  }

  .sm\:leading-tight {
    line-height: 1.25
  }

  .sm\:leading-snug {
    line-height: 1.375
  }

  .sm\:leading-normal {
    line-height: 1.5
  }

  .sm\:leading-relaxed {
    line-height: 1.625
  }

  .sm\:leading-loose {
    line-height: 2
  }

  .sm\:list-inside {
    list-style-position: inside
  }

  .sm\:list-outside {
    list-style-position: outside
  }

  .sm\:list-none {
    list-style-type: none
  }

  .sm\:list-disc {
    list-style-type: disc
  }

  .sm\:list-decimal {
    list-style-type: decimal
  }

  .sm\:m-0 {
    margin: 0
  }

  .sm\:m-1 {
    margin: 0.1rem
  }

  .sm\:m-2 {
    margin: 0.2rem
  }

  .sm\:m-3 {
    margin: 0.3rem
  }

  .sm\:m-4 {
    margin: 0.4rem
  }

  .sm\:m-5 {
    margin: 0.5rem
  }

  .sm\:m-6 {
    margin: 0.6rem
  }

  .sm\:m-7 {
    margin: 0.7rem
  }

  .sm\:m-8 {
    margin: 0.8rem
  }

  .sm\:m-9 {
    margin: 0.9rem
  }

  .sm\:m-10 {
    margin: 1.0rem
  }

  .sm\:m-12 {
    margin: 1.2rem
  }

  .sm\:m-14 {
    margin: 1.4rem
  }

  .sm\:m-16 {
    margin: 1.6rem
  }

  .sm\:m-20 {
    margin: 2rem
  }

  .sm\:m-24 {
    margin: 2.4rem
  }

  .sm\:m-28 {
    margin: 2.8rem
  }

  .sm\:m-32 {
    margin: 3.2rem
  }

  .sm\:m-36 {
    margin: 3.6rem
  }

  .sm\:m-40 {
    margin: 4rem
  }

  .sm\:m-44 {
    margin: 4.4rem
  }

  .sm\:m-48 {
    margin: 4.8rem
  }

  .sm\:m-52 {
    margin: 5.2rem
  }

  .sm\:m-56 {
    margin: 5.6rem
  }

  .sm\:m-60 {
    margin: 6rem
  }

  .sm\:m-64 {
    margin: 6.4rem
  }

  .sm\:m-68 {
    margin: 6.8rem
  }

  .sm\:m-72 {
    margin: 7.2rem
  }

  .sm\:m-76 {
    margin: 7.6rem
  }

  .sm\:m-80 {
    margin: 8rem
  }

  .sm\:m-84 {
    margin: 8.4rem
  }

  .sm\:m-88 {
    margin: 8.8rem
  }

  .sm\:m-92 {
    margin: 9.2rem
  }

  .sm\:m-96 {
    margin: 9.6rem
  }

  .sm\:m-128 {
    margin: 12.8rem
  }

  .sm\:m-136 {
    margin: 13.6rem
  }

  .sm\:m-160 {
    margin: 16rem
  }

  .sm\:m-192 {
    margin: 19.2rem
  }

  .sm\:m-200 {
    margin: 20rem
  }

  .sm\:m-208 {
    margin: 20.8rem
  }

  .sm\:m-216 {
    margin: 21.6rem
  }

  .sm\:m-224 {
    margin: 22.4rem
  }

  .sm\:m-256 {
    margin: 25.6rem
  }

  .sm\:m-288 {
    margin: 28.8rem
  }

  .sm\:m-320 {
    margin: 32rem
  }

  .sm\:m-360 {
    margin: 36rem
  }

  .sm\:m-384 {
    margin: 38.4rem
  }

  .sm\:m-400 {
    margin: 40rem
  }

  .sm\:m-512 {
    margin: 51.2rem
  }

  .sm\:m-640 {
    margin: 64rem
  }

  .sm\:m-auto {
    margin: auto
  }

  .sm\:m-xs {
    margin: 32rem
  }

  .sm\:m-sm {
    margin: 48rem
  }

  .sm\:m-md {
    margin: 64rem
  }

  .sm\:m-lg {
    margin: 80rem
  }

  .sm\:m-xl {
    margin: 96rem
  }

  .sm\:m-2xl {
    margin: 112rem
  }

  .sm\:m-3xl {
    margin: 128rem
  }

  .sm\:m-4xl {
    margin: 144rem
  }

  .sm\:m-5xl {
    margin: 160rem
  }

  .sm\:m-px {
    margin: 1px
  }

  .sm\:m-0\.5 {
    margin: 0.05rem
  }

  .sm\:m-1\.5 {
    margin: 0.15rem
  }

  .sm\:m-2\.5 {
    margin: 0.25rem
  }

  .sm\:m-3\.5 {
    margin: 0.35rem
  }

  .sm\:-m-1 {
    margin: -0.1rem
  }

  .sm\:-m-2 {
    margin: -0.2rem
  }

  .sm\:-m-3 {
    margin: -0.3rem
  }

  .sm\:-m-4 {
    margin: -0.4rem
  }

  .sm\:-m-5 {
    margin: -0.5rem
  }

  .sm\:-m-6 {
    margin: -0.6rem
  }

  .sm\:-m-7 {
    margin: -0.7rem
  }

  .sm\:-m-8 {
    margin: -0.8rem
  }

  .sm\:-m-9 {
    margin: -0.9rem
  }

  .sm\:-m-10 {
    margin: -1rem
  }

  .sm\:-m-12 {
    margin: -1.2rem
  }

  .sm\:-m-14 {
    margin: -1.4rem
  }

  .sm\:-m-16 {
    margin: -1.6rem
  }

  .sm\:-m-20 {
    margin: -2rem
  }

  .sm\:-m-24 {
    margin: -2.4rem
  }

  .sm\:-m-28 {
    margin: -2.8rem
  }

  .sm\:-m-32 {
    margin: -3.2rem
  }

  .sm\:-m-36 {
    margin: -3.6rem
  }

  .sm\:-m-40 {
    margin: -4rem
  }

  .sm\:-m-44 {
    margin: -4.4rem
  }

  .sm\:-m-48 {
    margin: -4.8rem
  }

  .sm\:-m-52 {
    margin: -5.2rem
  }

  .sm\:-m-56 {
    margin: -5.6rem
  }

  .sm\:-m-60 {
    margin: -6rem
  }

  .sm\:-m-64 {
    margin: -6.4rem
  }

  .sm\:-m-68 {
    margin: -6.8rem
  }

  .sm\:-m-72 {
    margin: -7.2rem
  }

  .sm\:-m-76 {
    margin: -7.6rem
  }

  .sm\:-m-80 {
    margin: -8rem
  }

  .sm\:-m-84 {
    margin: -8.4rem
  }

  .sm\:-m-88 {
    margin: -8.8rem
  }

  .sm\:-m-92 {
    margin: -9.2rem
  }

  .sm\:-m-96 {
    margin: -9.6rem
  }

  .sm\:-m-128 {
    margin: -12.8rem
  }

  .sm\:-m-136 {
    margin: -13.6rem
  }

  .sm\:-m-160 {
    margin: -16rem
  }

  .sm\:-m-192 {
    margin: -19.2rem
  }

  .sm\:-m-200 {
    margin: -20rem
  }

  .sm\:-m-208 {
    margin: -20.8rem
  }

  .sm\:-m-216 {
    margin: -21.6rem
  }

  .sm\:-m-224 {
    margin: -22.4rem
  }

  .sm\:-m-256 {
    margin: -25.6rem
  }

  .sm\:-m-288 {
    margin: -28.8rem
  }

  .sm\:-m-320 {
    margin: -32rem
  }

  .sm\:-m-360 {
    margin: -36rem
  }

  .sm\:-m-384 {
    margin: -38.4rem
  }

  .sm\:-m-400 {
    margin: -40rem
  }

  .sm\:-m-512 {
    margin: -51.2rem
  }

  .sm\:-m-640 {
    margin: -64rem
  }

  .sm\:-m-xs {
    margin: -32rem
  }

  .sm\:-m-sm {
    margin: -48rem
  }

  .sm\:-m-md {
    margin: -64rem
  }

  .sm\:-m-lg {
    margin: -80rem
  }

  .sm\:-m-xl {
    margin: -96rem
  }

  .sm\:-m-2xl {
    margin: -112rem
  }

  .sm\:-m-3xl {
    margin: -128rem
  }

  .sm\:-m-4xl {
    margin: -144rem
  }

  .sm\:-m-5xl {
    margin: -160rem
  }

  .sm\:-m-px {
    margin: -1px
  }

  .sm\:-m-0\.5 {
    margin: -0.05rem
  }

  .sm\:-m-1\.5 {
    margin: -0.15rem
  }

  .sm\:-m-2\.5 {
    margin: -0.25rem
  }

  .sm\:-m-3\.5 {
    margin: -0.35rem
  }

  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .sm\:my-1 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem
  }

  .sm\:mx-1 {
    margin-left: 0.1rem;
    margin-right: 0.1rem
  }

  .sm\:my-2 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem
  }

  .sm\:mx-2 {
    margin-left: 0.2rem;
    margin-right: 0.2rem
  }

  .sm\:my-3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem
  }

  .sm\:mx-3 {
    margin-left: 0.3rem;
    margin-right: 0.3rem
  }

  .sm\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  .sm\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  .sm\:my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .sm\:mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .sm\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  .sm\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  .sm\:my-7 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem
  }

  .sm\:mx-7 {
    margin-left: 0.7rem;
    margin-right: 0.7rem
  }

  .sm\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  .sm\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  .sm\:my-9 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem
  }

  .sm\:mx-9 {
    margin-left: 0.9rem;
    margin-right: 0.9rem
  }

  .sm\:my-10 {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem
  }

  .sm\:mx-10 {
    margin-left: 1.0rem;
    margin-right: 1.0rem
  }

  .sm\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  .sm\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  .sm\:my-14 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem
  }

  .sm\:mx-14 {
    margin-left: 1.4rem;
    margin-right: 1.4rem
  }

  .sm\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  .sm\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  .sm\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .sm\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .sm\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  .sm\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .sm\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  .sm\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  .sm\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  .sm\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  .sm\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  .sm\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  .sm\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .sm\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .sm\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  .sm\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  .sm\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  .sm\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  .sm\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  .sm\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  .sm\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  .sm\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  .sm\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .sm\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .sm\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  .sm\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  .sm\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  .sm\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  .sm\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  .sm\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  .sm\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  .sm\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  .sm\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .sm\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .sm\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  .sm\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  .sm\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  .sm\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  .sm\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  .sm\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  .sm\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  .sm\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  .sm\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  .sm\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  .sm\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  .sm\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  .sm\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .sm\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .sm\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  .sm\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  .sm\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  .sm\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  .sm\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  .sm\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  .sm\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  .sm\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  .sm\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  .sm\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  .sm\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  .sm\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  .sm\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  .sm\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  .sm\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  .sm\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  .sm\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  .sm\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  .sm\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  .sm\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  .sm\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  .sm\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  .sm\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  .sm\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  .sm\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  .sm\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .sm\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  .sm\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  .sm\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  .sm\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  .sm\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  .sm\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  .sm\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  .sm\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  .sm\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  .sm\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  .sm\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  .sm\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  .sm\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  .sm\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  .sm\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  .sm\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  .sm\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  .sm\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  .sm\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .sm\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .sm\:my-0\.5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem
  }

  .sm\:mx-0\.5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem
  }

  .sm\:my-1\.5 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem
  }

  .sm\:mx-1\.5 {
    margin-left: 0.15rem;
    margin-right: 0.15rem
  }

  .sm\:my-2\.5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .sm\:mx-2\.5 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .sm\:my-3\.5 {
    margin-top: 0.35rem;
    margin-bottom: 0.35rem
  }

  .sm\:mx-3\.5 {
    margin-left: 0.35rem;
    margin-right: 0.35rem
  }

  .sm\:-my-1 {
    margin-top: -0.1rem;
    margin-bottom: -0.1rem
  }

  .sm\:-mx-1 {
    margin-left: -0.1rem;
    margin-right: -0.1rem
  }

  .sm\:-my-2 {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem
  }

  .sm\:-mx-2 {
    margin-left: -0.2rem;
    margin-right: -0.2rem
  }

  .sm\:-my-3 {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem
  }

  .sm\:-mx-3 {
    margin-left: -0.3rem;
    margin-right: -0.3rem
  }

  .sm\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  .sm\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  .sm\:-my-5 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .sm\:-mx-5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .sm\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  .sm\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  .sm\:-my-7 {
    margin-top: -0.7rem;
    margin-bottom: -0.7rem
  }

  .sm\:-mx-7 {
    margin-left: -0.7rem;
    margin-right: -0.7rem
  }

  .sm\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  .sm\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  .sm\:-my-9 {
    margin-top: -0.9rem;
    margin-bottom: -0.9rem
  }

  .sm\:-mx-9 {
    margin-left: -0.9rem;
    margin-right: -0.9rem
  }

  .sm\:-my-10 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .sm\:-mx-10 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .sm\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  .sm\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  .sm\:-my-14 {
    margin-top: -1.4rem;
    margin-bottom: -1.4rem
  }

  .sm\:-mx-14 {
    margin-left: -1.4rem;
    margin-right: -1.4rem
  }

  .sm\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  .sm\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  .sm\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .sm\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .sm\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  .sm\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  .sm\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  .sm\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  .sm\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  .sm\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  .sm\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  .sm\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  .sm\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .sm\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .sm\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  .sm\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  .sm\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  .sm\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  .sm\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  .sm\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  .sm\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  .sm\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  .sm\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .sm\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .sm\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  .sm\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  .sm\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  .sm\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  .sm\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  .sm\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  .sm\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  .sm\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  .sm\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .sm\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .sm\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  .sm\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  .sm\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  .sm\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  .sm\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  .sm\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  .sm\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  .sm\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  .sm\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  .sm\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  .sm\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  .sm\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  .sm\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .sm\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .sm\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  .sm\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  .sm\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  .sm\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  .sm\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  .sm\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  .sm\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  .sm\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  .sm\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  .sm\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  .sm\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  .sm\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  .sm\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  .sm\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  .sm\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  .sm\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  .sm\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  .sm\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  .sm\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  .sm\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  .sm\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  .sm\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  .sm\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  .sm\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  .sm\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  .sm\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  .sm\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  .sm\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  .sm\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  .sm\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  .sm\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  .sm\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  .sm\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  .sm\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  .sm\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  .sm\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  .sm\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  .sm\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  .sm\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  .sm\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  .sm\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  .sm\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  .sm\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  .sm\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  .sm\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .sm\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .sm\:-my-0\.5 {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem
  }

  .sm\:-mx-0\.5 {
    margin-left: -0.05rem;
    margin-right: -0.05rem
  }

  .sm\:-my-1\.5 {
    margin-top: -0.15rem;
    margin-bottom: -0.15rem
  }

  .sm\:-mx-1\.5 {
    margin-left: -0.15rem;
    margin-right: -0.15rem
  }

  .sm\:-my-2\.5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .sm\:-mx-2\.5 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .sm\:-my-3\.5 {
    margin-top: -0.35rem;
    margin-bottom: -0.35rem
  }

  .sm\:-mx-3\.5 {
    margin-left: -0.35rem;
    margin-right: -0.35rem
  }

  .sm\:mt-0 {
    margin-top: 0
  }

  .sm\:mr-0 {
    margin-right: 0
  }

  .sm\:mb-0 {
    margin-bottom: 0
  }

  .sm\:ml-0 {
    margin-left: 0
  }

  .sm\:mt-1 {
    margin-top: 0.1rem
  }

  .sm\:mr-1 {
    margin-right: 0.1rem
  }

  .sm\:mb-1 {
    margin-bottom: 0.1rem
  }

  .sm\:ml-1 {
    margin-left: 0.1rem
  }

  .sm\:mt-2 {
    margin-top: 0.2rem
  }

  .sm\:mr-2 {
    margin-right: 0.2rem
  }

  .sm\:mb-2 {
    margin-bottom: 0.2rem
  }

  .sm\:ml-2 {
    margin-left: 0.2rem
  }

  .sm\:mt-3 {
    margin-top: 0.3rem
  }

  .sm\:mr-3 {
    margin-right: 0.3rem
  }

  .sm\:mb-3 {
    margin-bottom: 0.3rem
  }

  .sm\:ml-3 {
    margin-left: 0.3rem
  }

  .sm\:mt-4 {
    margin-top: 0.4rem
  }

  .sm\:mr-4 {
    margin-right: 0.4rem
  }

  .sm\:mb-4 {
    margin-bottom: 0.4rem
  }

  .sm\:ml-4 {
    margin-left: 0.4rem
  }

  .sm\:mt-5 {
    margin-top: 0.5rem
  }

  .sm\:mr-5 {
    margin-right: 0.5rem
  }

  .sm\:mb-5 {
    margin-bottom: 0.5rem
  }

  .sm\:ml-5 {
    margin-left: 0.5rem
  }

  .sm\:mt-6 {
    margin-top: 0.6rem
  }

  .sm\:mr-6 {
    margin-right: 0.6rem
  }

  .sm\:mb-6 {
    margin-bottom: 0.6rem
  }

  .sm\:ml-6 {
    margin-left: 0.6rem
  }

  .sm\:mt-7 {
    margin-top: 0.7rem
  }

  .sm\:mr-7 {
    margin-right: 0.7rem
  }

  .sm\:mb-7 {
    margin-bottom: 0.7rem
  }

  .sm\:ml-7 {
    margin-left: 0.7rem
  }

  .sm\:mt-8 {
    margin-top: 0.8rem
  }

  .sm\:mr-8 {
    margin-right: 0.8rem
  }

  .sm\:mb-8 {
    margin-bottom: 0.8rem
  }

  .sm\:ml-8 {
    margin-left: 0.8rem
  }

  .sm\:mt-9 {
    margin-top: 0.9rem
  }

  .sm\:mr-9 {
    margin-right: 0.9rem
  }

  .sm\:mb-9 {
    margin-bottom: 0.9rem
  }

  .sm\:ml-9 {
    margin-left: 0.9rem
  }

  .sm\:mt-10 {
    margin-top: 1.0rem
  }

  .sm\:mr-10 {
    margin-right: 1.0rem
  }

  .sm\:mb-10 {
    margin-bottom: 1.0rem
  }

  .sm\:ml-10 {
    margin-left: 1.0rem
  }

  .sm\:mt-12 {
    margin-top: 1.2rem
  }

  .sm\:mr-12 {
    margin-right: 1.2rem
  }

  .sm\:mb-12 {
    margin-bottom: 1.2rem
  }

  .sm\:ml-12 {
    margin-left: 1.2rem
  }

  .sm\:mt-14 {
    margin-top: 1.4rem
  }

  .sm\:mr-14 {
    margin-right: 1.4rem
  }

  .sm\:mb-14 {
    margin-bottom: 1.4rem
  }

  .sm\:ml-14 {
    margin-left: 1.4rem
  }

  .sm\:mt-16 {
    margin-top: 1.6rem
  }

  .sm\:mr-16 {
    margin-right: 1.6rem
  }

  .sm\:mb-16 {
    margin-bottom: 1.6rem
  }

  .sm\:ml-16 {
    margin-left: 1.6rem
  }

  .sm\:mt-20 {
    margin-top: 2rem
  }

  .sm\:mr-20 {
    margin-right: 2rem
  }

  .sm\:mb-20 {
    margin-bottom: 2rem
  }

  .sm\:ml-20 {
    margin-left: 2rem
  }

  .sm\:mt-24 {
    margin-top: 2.4rem
  }

  .sm\:mr-24 {
    margin-right: 2.4rem
  }

  .sm\:mb-24 {
    margin-bottom: 2.4rem
  }

  .sm\:ml-24 {
    margin-left: 2.4rem
  }

  .sm\:mt-28 {
    margin-top: 2.8rem
  }

  .sm\:mr-28 {
    margin-right: 2.8rem
  }

  .sm\:mb-28 {
    margin-bottom: 2.8rem
  }

  .sm\:ml-28 {
    margin-left: 2.8rem
  }

  .sm\:mt-32 {
    margin-top: 3.2rem
  }

  .sm\:mr-32 {
    margin-right: 3.2rem
  }

  .sm\:mb-32 {
    margin-bottom: 3.2rem
  }

  .sm\:ml-32 {
    margin-left: 3.2rem
  }

  .sm\:mt-36 {
    margin-top: 3.6rem
  }

  .sm\:mr-36 {
    margin-right: 3.6rem
  }

  .sm\:mb-36 {
    margin-bottom: 3.6rem
  }

  .sm\:ml-36 {
    margin-left: 3.6rem
  }

  .sm\:mt-40 {
    margin-top: 4rem
  }

  .sm\:mr-40 {
    margin-right: 4rem
  }

  .sm\:mb-40 {
    margin-bottom: 4rem
  }

  .sm\:ml-40 {
    margin-left: 4rem
  }

  .sm\:mt-44 {
    margin-top: 4.4rem
  }

  .sm\:mr-44 {
    margin-right: 4.4rem
  }

  .sm\:mb-44 {
    margin-bottom: 4.4rem
  }

  .sm\:ml-44 {
    margin-left: 4.4rem
  }

  .sm\:mt-48 {
    margin-top: 4.8rem
  }

  .sm\:mr-48 {
    margin-right: 4.8rem
  }

  .sm\:mb-48 {
    margin-bottom: 4.8rem
  }

  .sm\:ml-48 {
    margin-left: 4.8rem
  }

  .sm\:mt-52 {
    margin-top: 5.2rem
  }

  .sm\:mr-52 {
    margin-right: 5.2rem
  }

  .sm\:mb-52 {
    margin-bottom: 5.2rem
  }

  .sm\:ml-52 {
    margin-left: 5.2rem
  }

  .sm\:mt-56 {
    margin-top: 5.6rem
  }

  .sm\:mr-56 {
    margin-right: 5.6rem
  }

  .sm\:mb-56 {
    margin-bottom: 5.6rem
  }

  .sm\:ml-56 {
    margin-left: 5.6rem
  }

  .sm\:mt-60 {
    margin-top: 6rem
  }

  .sm\:mr-60 {
    margin-right: 6rem
  }

  .sm\:mb-60 {
    margin-bottom: 6rem
  }

  .sm\:ml-60 {
    margin-left: 6rem
  }

  .sm\:mt-64 {
    margin-top: 6.4rem
  }

  .sm\:mr-64 {
    margin-right: 6.4rem
  }

  .sm\:mb-64 {
    margin-bottom: 6.4rem
  }

  .sm\:ml-64 {
    margin-left: 6.4rem
  }

  .sm\:mt-68 {
    margin-top: 6.8rem
  }

  .sm\:mr-68 {
    margin-right: 6.8rem
  }

  .sm\:mb-68 {
    margin-bottom: 6.8rem
  }

  .sm\:ml-68 {
    margin-left: 6.8rem
  }

  .sm\:mt-72 {
    margin-top: 7.2rem
  }

  .sm\:mr-72 {
    margin-right: 7.2rem
  }

  .sm\:mb-72 {
    margin-bottom: 7.2rem
  }

  .sm\:ml-72 {
    margin-left: 7.2rem
  }

  .sm\:mt-76 {
    margin-top: 7.6rem
  }

  .sm\:mr-76 {
    margin-right: 7.6rem
  }

  .sm\:mb-76 {
    margin-bottom: 7.6rem
  }

  .sm\:ml-76 {
    margin-left: 7.6rem
  }

  .sm\:mt-80 {
    margin-top: 8rem
  }

  .sm\:mr-80 {
    margin-right: 8rem
  }

  .sm\:mb-80 {
    margin-bottom: 8rem
  }

  .sm\:ml-80 {
    margin-left: 8rem
  }

  .sm\:mt-84 {
    margin-top: 8.4rem
  }

  .sm\:mr-84 {
    margin-right: 8.4rem
  }

  .sm\:mb-84 {
    margin-bottom: 8.4rem
  }

  .sm\:ml-84 {
    margin-left: 8.4rem
  }

  .sm\:mt-88 {
    margin-top: 8.8rem
  }

  .sm\:mr-88 {
    margin-right: 8.8rem
  }

  .sm\:mb-88 {
    margin-bottom: 8.8rem
  }

  .sm\:ml-88 {
    margin-left: 8.8rem
  }

  .sm\:mt-92 {
    margin-top: 9.2rem
  }

  .sm\:mr-92 {
    margin-right: 9.2rem
  }

  .sm\:mb-92 {
    margin-bottom: 9.2rem
  }

  .sm\:ml-92 {
    margin-left: 9.2rem
  }

  .sm\:mt-96 {
    margin-top: 9.6rem
  }

  .sm\:mr-96 {
    margin-right: 9.6rem
  }

  .sm\:mb-96 {
    margin-bottom: 9.6rem
  }

  .sm\:ml-96 {
    margin-left: 9.6rem
  }

  .sm\:mt-128 {
    margin-top: 12.8rem
  }

  .sm\:mr-128 {
    margin-right: 12.8rem
  }

  .sm\:mb-128 {
    margin-bottom: 12.8rem
  }

  .sm\:ml-128 {
    margin-left: 12.8rem
  }

  .sm\:mt-136 {
    margin-top: 13.6rem
  }

  .sm\:mr-136 {
    margin-right: 13.6rem
  }

  .sm\:mb-136 {
    margin-bottom: 13.6rem
  }

  .sm\:ml-136 {
    margin-left: 13.6rem
  }

  .sm\:mt-160 {
    margin-top: 16rem
  }

  .sm\:mr-160 {
    margin-right: 16rem
  }

  .sm\:mb-160 {
    margin-bottom: 16rem
  }

  .sm\:ml-160 {
    margin-left: 16rem
  }

  .sm\:mt-192 {
    margin-top: 19.2rem
  }

  .sm\:mr-192 {
    margin-right: 19.2rem
  }

  .sm\:mb-192 {
    margin-bottom: 19.2rem
  }

  .sm\:ml-192 {
    margin-left: 19.2rem
  }

  .sm\:mt-200 {
    margin-top: 20rem
  }

  .sm\:mr-200 {
    margin-right: 20rem
  }

  .sm\:mb-200 {
    margin-bottom: 20rem
  }

  .sm\:ml-200 {
    margin-left: 20rem
  }

  .sm\:mt-208 {
    margin-top: 20.8rem
  }

  .sm\:mr-208 {
    margin-right: 20.8rem
  }

  .sm\:mb-208 {
    margin-bottom: 20.8rem
  }

  .sm\:ml-208 {
    margin-left: 20.8rem
  }

  .sm\:mt-216 {
    margin-top: 21.6rem
  }

  .sm\:mr-216 {
    margin-right: 21.6rem
  }

  .sm\:mb-216 {
    margin-bottom: 21.6rem
  }

  .sm\:ml-216 {
    margin-left: 21.6rem
  }

  .sm\:mt-224 {
    margin-top: 22.4rem
  }

  .sm\:mr-224 {
    margin-right: 22.4rem
  }

  .sm\:mb-224 {
    margin-bottom: 22.4rem
  }

  .sm\:ml-224 {
    margin-left: 22.4rem
  }

  .sm\:mt-256 {
    margin-top: 25.6rem
  }

  .sm\:mr-256 {
    margin-right: 25.6rem
  }

  .sm\:mb-256 {
    margin-bottom: 25.6rem
  }

  .sm\:ml-256 {
    margin-left: 25.6rem
  }

  .sm\:mt-288 {
    margin-top: 28.8rem
  }

  .sm\:mr-288 {
    margin-right: 28.8rem
  }

  .sm\:mb-288 {
    margin-bottom: 28.8rem
  }

  .sm\:ml-288 {
    margin-left: 28.8rem
  }

  .sm\:mt-320 {
    margin-top: 32rem
  }

  .sm\:mr-320 {
    margin-right: 32rem
  }

  .sm\:mb-320 {
    margin-bottom: 32rem
  }

  .sm\:ml-320 {
    margin-left: 32rem
  }

  .sm\:mt-360 {
    margin-top: 36rem
  }

  .sm\:mr-360 {
    margin-right: 36rem
  }

  .sm\:mb-360 {
    margin-bottom: 36rem
  }

  .sm\:ml-360 {
    margin-left: 36rem
  }

  .sm\:mt-384 {
    margin-top: 38.4rem
  }

  .sm\:mr-384 {
    margin-right: 38.4rem
  }

  .sm\:mb-384 {
    margin-bottom: 38.4rem
  }

  .sm\:ml-384 {
    margin-left: 38.4rem
  }

  .sm\:mt-400 {
    margin-top: 40rem
  }

  .sm\:mr-400 {
    margin-right: 40rem
  }

  .sm\:mb-400 {
    margin-bottom: 40rem
  }

  .sm\:ml-400 {
    margin-left: 40rem
  }

  .sm\:mt-512 {
    margin-top: 51.2rem
  }

  .sm\:mr-512 {
    margin-right: 51.2rem
  }

  .sm\:mb-512 {
    margin-bottom: 51.2rem
  }

  .sm\:ml-512 {
    margin-left: 51.2rem
  }

  .sm\:mt-640 {
    margin-top: 64rem
  }

  .sm\:mr-640 {
    margin-right: 64rem
  }

  .sm\:mb-640 {
    margin-bottom: 64rem
  }

  .sm\:ml-640 {
    margin-left: 64rem
  }

  .sm\:mt-auto {
    margin-top: auto
  }

  .sm\:mr-auto {
    margin-right: auto
  }

  .sm\:mb-auto {
    margin-bottom: auto
  }

  .sm\:ml-auto {
    margin-left: auto
  }

  .sm\:mt-xs {
    margin-top: 32rem
  }

  .sm\:mr-xs {
    margin-right: 32rem
  }

  .sm\:mb-xs {
    margin-bottom: 32rem
  }

  .sm\:ml-xs {
    margin-left: 32rem
  }

  .sm\:mt-sm {
    margin-top: 48rem
  }

  .sm\:mr-sm {
    margin-right: 48rem
  }

  .sm\:mb-sm {
    margin-bottom: 48rem
  }

  .sm\:ml-sm {
    margin-left: 48rem
  }

  .sm\:mt-md {
    margin-top: 64rem
  }

  .sm\:mr-md {
    margin-right: 64rem
  }

  .sm\:mb-md {
    margin-bottom: 64rem
  }

  .sm\:ml-md {
    margin-left: 64rem
  }

  .sm\:mt-lg {
    margin-top: 80rem
  }

  .sm\:mr-lg {
    margin-right: 80rem
  }

  .sm\:mb-lg {
    margin-bottom: 80rem
  }

  .sm\:ml-lg {
    margin-left: 80rem
  }

  .sm\:mt-xl {
    margin-top: 96rem
  }

  .sm\:mr-xl {
    margin-right: 96rem
  }

  .sm\:mb-xl {
    margin-bottom: 96rem
  }

  .sm\:ml-xl {
    margin-left: 96rem
  }

  .sm\:mt-2xl {
    margin-top: 112rem
  }

  .sm\:mr-2xl {
    margin-right: 112rem
  }

  .sm\:mb-2xl {
    margin-bottom: 112rem
  }

  .sm\:ml-2xl {
    margin-left: 112rem
  }

  .sm\:mt-3xl {
    margin-top: 128rem
  }

  .sm\:mr-3xl {
    margin-right: 128rem
  }

  .sm\:mb-3xl {
    margin-bottom: 128rem
  }

  .sm\:ml-3xl {
    margin-left: 128rem
  }

  .sm\:mt-4xl {
    margin-top: 144rem
  }

  .sm\:mr-4xl {
    margin-right: 144rem
  }

  .sm\:mb-4xl {
    margin-bottom: 144rem
  }

  .sm\:ml-4xl {
    margin-left: 144rem
  }

  .sm\:mt-5xl {
    margin-top: 160rem
  }

  .sm\:mr-5xl {
    margin-right: 160rem
  }

  .sm\:mb-5xl {
    margin-bottom: 160rem
  }

  .sm\:ml-5xl {
    margin-left: 160rem
  }

  .sm\:mt-px {
    margin-top: 1px
  }

  .sm\:mr-px {
    margin-right: 1px
  }

  .sm\:mb-px {
    margin-bottom: 1px
  }

  .sm\:ml-px {
    margin-left: 1px
  }

  .sm\:mt-0\.5 {
    margin-top: 0.05rem
  }

  .sm\:mr-0\.5 {
    margin-right: 0.05rem
  }

  .sm\:mb-0\.5 {
    margin-bottom: 0.05rem
  }

  .sm\:ml-0\.5 {
    margin-left: 0.05rem
  }

  .sm\:mt-1\.5 {
    margin-top: 0.15rem
  }

  .sm\:mr-1\.5 {
    margin-right: 0.15rem
  }

  .sm\:mb-1\.5 {
    margin-bottom: 0.15rem
  }

  .sm\:ml-1\.5 {
    margin-left: 0.15rem
  }

  .sm\:mt-2\.5 {
    margin-top: 0.25rem
  }

  .sm\:mr-2\.5 {
    margin-right: 0.25rem
  }

  .sm\:mb-2\.5 {
    margin-bottom: 0.25rem
  }

  .sm\:ml-2\.5 {
    margin-left: 0.25rem
  }

  .sm\:mt-3\.5 {
    margin-top: 0.35rem
  }

  .sm\:mr-3\.5 {
    margin-right: 0.35rem
  }

  .sm\:mb-3\.5 {
    margin-bottom: 0.35rem
  }

  .sm\:ml-3\.5 {
    margin-left: 0.35rem
  }

  .sm\:-mt-1 {
    margin-top: -0.1rem
  }

  .sm\:-mr-1 {
    margin-right: -0.1rem
  }

  .sm\:-mb-1 {
    margin-bottom: -0.1rem
  }

  .sm\:-ml-1 {
    margin-left: -0.1rem
  }

  .sm\:-mt-2 {
    margin-top: -0.2rem
  }

  .sm\:-mr-2 {
    margin-right: -0.2rem
  }

  .sm\:-mb-2 {
    margin-bottom: -0.2rem
  }

  .sm\:-ml-2 {
    margin-left: -0.2rem
  }

  .sm\:-mt-3 {
    margin-top: -0.3rem
  }

  .sm\:-mr-3 {
    margin-right: -0.3rem
  }

  .sm\:-mb-3 {
    margin-bottom: -0.3rem
  }

  .sm\:-ml-3 {
    margin-left: -0.3rem
  }

  .sm\:-mt-4 {
    margin-top: -0.4rem
  }

  .sm\:-mr-4 {
    margin-right: -0.4rem
  }

  .sm\:-mb-4 {
    margin-bottom: -0.4rem
  }

  .sm\:-ml-4 {
    margin-left: -0.4rem
  }

  .sm\:-mt-5 {
    margin-top: -0.5rem
  }

  .sm\:-mr-5 {
    margin-right: -0.5rem
  }

  .sm\:-mb-5 {
    margin-bottom: -0.5rem
  }

  .sm\:-ml-5 {
    margin-left: -0.5rem
  }

  .sm\:-mt-6 {
    margin-top: -0.6rem
  }

  .sm\:-mr-6 {
    margin-right: -0.6rem
  }

  .sm\:-mb-6 {
    margin-bottom: -0.6rem
  }

  .sm\:-ml-6 {
    margin-left: -0.6rem
  }

  .sm\:-mt-7 {
    margin-top: -0.7rem
  }

  .sm\:-mr-7 {
    margin-right: -0.7rem
  }

  .sm\:-mb-7 {
    margin-bottom: -0.7rem
  }

  .sm\:-ml-7 {
    margin-left: -0.7rem
  }

  .sm\:-mt-8 {
    margin-top: -0.8rem
  }

  .sm\:-mr-8 {
    margin-right: -0.8rem
  }

  .sm\:-mb-8 {
    margin-bottom: -0.8rem
  }

  .sm\:-ml-8 {
    margin-left: -0.8rem
  }

  .sm\:-mt-9 {
    margin-top: -0.9rem
  }

  .sm\:-mr-9 {
    margin-right: -0.9rem
  }

  .sm\:-mb-9 {
    margin-bottom: -0.9rem
  }

  .sm\:-ml-9 {
    margin-left: -0.9rem
  }

  .sm\:-mt-10 {
    margin-top: -1rem
  }

  .sm\:-mr-10 {
    margin-right: -1rem
  }

  .sm\:-mb-10 {
    margin-bottom: -1rem
  }

  .sm\:-ml-10 {
    margin-left: -1rem
  }

  .sm\:-mt-12 {
    margin-top: -1.2rem
  }

  .sm\:-mr-12 {
    margin-right: -1.2rem
  }

  .sm\:-mb-12 {
    margin-bottom: -1.2rem
  }

  .sm\:-ml-12 {
    margin-left: -1.2rem
  }

  .sm\:-mt-14 {
    margin-top: -1.4rem
  }

  .sm\:-mr-14 {
    margin-right: -1.4rem
  }

  .sm\:-mb-14 {
    margin-bottom: -1.4rem
  }

  .sm\:-ml-14 {
    margin-left: -1.4rem
  }

  .sm\:-mt-16 {
    margin-top: -1.6rem
  }

  .sm\:-mr-16 {
    margin-right: -1.6rem
  }

  .sm\:-mb-16 {
    margin-bottom: -1.6rem
  }

  .sm\:-ml-16 {
    margin-left: -1.6rem
  }

  .sm\:-mt-20 {
    margin-top: -2rem
  }

  .sm\:-mr-20 {
    margin-right: -2rem
  }

  .sm\:-mb-20 {
    margin-bottom: -2rem
  }

  .sm\:-ml-20 {
    margin-left: -2rem
  }

  .sm\:-mt-24 {
    margin-top: -2.4rem
  }

  .sm\:-mr-24 {
    margin-right: -2.4rem
  }

  .sm\:-mb-24 {
    margin-bottom: -2.4rem
  }

  .sm\:-ml-24 {
    margin-left: -2.4rem
  }

  .sm\:-mt-28 {
    margin-top: -2.8rem
  }

  .sm\:-mr-28 {
    margin-right: -2.8rem
  }

  .sm\:-mb-28 {
    margin-bottom: -2.8rem
  }

  .sm\:-ml-28 {
    margin-left: -2.8rem
  }

  .sm\:-mt-32 {
    margin-top: -3.2rem
  }

  .sm\:-mr-32 {
    margin-right: -3.2rem
  }

  .sm\:-mb-32 {
    margin-bottom: -3.2rem
  }

  .sm\:-ml-32 {
    margin-left: -3.2rem
  }

  .sm\:-mt-36 {
    margin-top: -3.6rem
  }

  .sm\:-mr-36 {
    margin-right: -3.6rem
  }

  .sm\:-mb-36 {
    margin-bottom: -3.6rem
  }

  .sm\:-ml-36 {
    margin-left: -3.6rem
  }

  .sm\:-mt-40 {
    margin-top: -4rem
  }

  .sm\:-mr-40 {
    margin-right: -4rem
  }

  .sm\:-mb-40 {
    margin-bottom: -4rem
  }

  .sm\:-ml-40 {
    margin-left: -4rem
  }

  .sm\:-mt-44 {
    margin-top: -4.4rem
  }

  .sm\:-mr-44 {
    margin-right: -4.4rem
  }

  .sm\:-mb-44 {
    margin-bottom: -4.4rem
  }

  .sm\:-ml-44 {
    margin-left: -4.4rem
  }

  .sm\:-mt-48 {
    margin-top: -4.8rem
  }

  .sm\:-mr-48 {
    margin-right: -4.8rem
  }

  .sm\:-mb-48 {
    margin-bottom: -4.8rem
  }

  .sm\:-ml-48 {
    margin-left: -4.8rem
  }

  .sm\:-mt-52 {
    margin-top: -5.2rem
  }

  .sm\:-mr-52 {
    margin-right: -5.2rem
  }

  .sm\:-mb-52 {
    margin-bottom: -5.2rem
  }

  .sm\:-ml-52 {
    margin-left: -5.2rem
  }

  .sm\:-mt-56 {
    margin-top: -5.6rem
  }

  .sm\:-mr-56 {
    margin-right: -5.6rem
  }

  .sm\:-mb-56 {
    margin-bottom: -5.6rem
  }

  .sm\:-ml-56 {
    margin-left: -5.6rem
  }

  .sm\:-mt-60 {
    margin-top: -6rem
  }

  .sm\:-mr-60 {
    margin-right: -6rem
  }

  .sm\:-mb-60 {
    margin-bottom: -6rem
  }

  .sm\:-ml-60 {
    margin-left: -6rem
  }

  .sm\:-mt-64 {
    margin-top: -6.4rem
  }

  .sm\:-mr-64 {
    margin-right: -6.4rem
  }

  .sm\:-mb-64 {
    margin-bottom: -6.4rem
  }

  .sm\:-ml-64 {
    margin-left: -6.4rem
  }

  .sm\:-mt-68 {
    margin-top: -6.8rem
  }

  .sm\:-mr-68 {
    margin-right: -6.8rem
  }

  .sm\:-mb-68 {
    margin-bottom: -6.8rem
  }

  .sm\:-ml-68 {
    margin-left: -6.8rem
  }

  .sm\:-mt-72 {
    margin-top: -7.2rem
  }

  .sm\:-mr-72 {
    margin-right: -7.2rem
  }

  .sm\:-mb-72 {
    margin-bottom: -7.2rem
  }

  .sm\:-ml-72 {
    margin-left: -7.2rem
  }

  .sm\:-mt-76 {
    margin-top: -7.6rem
  }

  .sm\:-mr-76 {
    margin-right: -7.6rem
  }

  .sm\:-mb-76 {
    margin-bottom: -7.6rem
  }

  .sm\:-ml-76 {
    margin-left: -7.6rem
  }

  .sm\:-mt-80 {
    margin-top: -8rem
  }

  .sm\:-mr-80 {
    margin-right: -8rem
  }

  .sm\:-mb-80 {
    margin-bottom: -8rem
  }

  .sm\:-ml-80 {
    margin-left: -8rem
  }

  .sm\:-mt-84 {
    margin-top: -8.4rem
  }

  .sm\:-mr-84 {
    margin-right: -8.4rem
  }

  .sm\:-mb-84 {
    margin-bottom: -8.4rem
  }

  .sm\:-ml-84 {
    margin-left: -8.4rem
  }

  .sm\:-mt-88 {
    margin-top: -8.8rem
  }

  .sm\:-mr-88 {
    margin-right: -8.8rem
  }

  .sm\:-mb-88 {
    margin-bottom: -8.8rem
  }

  .sm\:-ml-88 {
    margin-left: -8.8rem
  }

  .sm\:-mt-92 {
    margin-top: -9.2rem
  }

  .sm\:-mr-92 {
    margin-right: -9.2rem
  }

  .sm\:-mb-92 {
    margin-bottom: -9.2rem
  }

  .sm\:-ml-92 {
    margin-left: -9.2rem
  }

  .sm\:-mt-96 {
    margin-top: -9.6rem
  }

  .sm\:-mr-96 {
    margin-right: -9.6rem
  }

  .sm\:-mb-96 {
    margin-bottom: -9.6rem
  }

  .sm\:-ml-96 {
    margin-left: -9.6rem
  }

  .sm\:-mt-128 {
    margin-top: -12.8rem
  }

  .sm\:-mr-128 {
    margin-right: -12.8rem
  }

  .sm\:-mb-128 {
    margin-bottom: -12.8rem
  }

  .sm\:-ml-128 {
    margin-left: -12.8rem
  }

  .sm\:-mt-136 {
    margin-top: -13.6rem
  }

  .sm\:-mr-136 {
    margin-right: -13.6rem
  }

  .sm\:-mb-136 {
    margin-bottom: -13.6rem
  }

  .sm\:-ml-136 {
    margin-left: -13.6rem
  }

  .sm\:-mt-160 {
    margin-top: -16rem
  }

  .sm\:-mr-160 {
    margin-right: -16rem
  }

  .sm\:-mb-160 {
    margin-bottom: -16rem
  }

  .sm\:-ml-160 {
    margin-left: -16rem
  }

  .sm\:-mt-192 {
    margin-top: -19.2rem
  }

  .sm\:-mr-192 {
    margin-right: -19.2rem
  }

  .sm\:-mb-192 {
    margin-bottom: -19.2rem
  }

  .sm\:-ml-192 {
    margin-left: -19.2rem
  }

  .sm\:-mt-200 {
    margin-top: -20rem
  }

  .sm\:-mr-200 {
    margin-right: -20rem
  }

  .sm\:-mb-200 {
    margin-bottom: -20rem
  }

  .sm\:-ml-200 {
    margin-left: -20rem
  }

  .sm\:-mt-208 {
    margin-top: -20.8rem
  }

  .sm\:-mr-208 {
    margin-right: -20.8rem
  }

  .sm\:-mb-208 {
    margin-bottom: -20.8rem
  }

  .sm\:-ml-208 {
    margin-left: -20.8rem
  }

  .sm\:-mt-216 {
    margin-top: -21.6rem
  }

  .sm\:-mr-216 {
    margin-right: -21.6rem
  }

  .sm\:-mb-216 {
    margin-bottom: -21.6rem
  }

  .sm\:-ml-216 {
    margin-left: -21.6rem
  }

  .sm\:-mt-224 {
    margin-top: -22.4rem
  }

  .sm\:-mr-224 {
    margin-right: -22.4rem
  }

  .sm\:-mb-224 {
    margin-bottom: -22.4rem
  }

  .sm\:-ml-224 {
    margin-left: -22.4rem
  }

  .sm\:-mt-256 {
    margin-top: -25.6rem
  }

  .sm\:-mr-256 {
    margin-right: -25.6rem
  }

  .sm\:-mb-256 {
    margin-bottom: -25.6rem
  }

  .sm\:-ml-256 {
    margin-left: -25.6rem
  }

  .sm\:-mt-288 {
    margin-top: -28.8rem
  }

  .sm\:-mr-288 {
    margin-right: -28.8rem
  }

  .sm\:-mb-288 {
    margin-bottom: -28.8rem
  }

  .sm\:-ml-288 {
    margin-left: -28.8rem
  }

  .sm\:-mt-320 {
    margin-top: -32rem
  }

  .sm\:-mr-320 {
    margin-right: -32rem
  }

  .sm\:-mb-320 {
    margin-bottom: -32rem
  }

  .sm\:-ml-320 {
    margin-left: -32rem
  }

  .sm\:-mt-360 {
    margin-top: -36rem
  }

  .sm\:-mr-360 {
    margin-right: -36rem
  }

  .sm\:-mb-360 {
    margin-bottom: -36rem
  }

  .sm\:-ml-360 {
    margin-left: -36rem
  }

  .sm\:-mt-384 {
    margin-top: -38.4rem
  }

  .sm\:-mr-384 {
    margin-right: -38.4rem
  }

  .sm\:-mb-384 {
    margin-bottom: -38.4rem
  }

  .sm\:-ml-384 {
    margin-left: -38.4rem
  }

  .sm\:-mt-400 {
    margin-top: -40rem
  }

  .sm\:-mr-400 {
    margin-right: -40rem
  }

  .sm\:-mb-400 {
    margin-bottom: -40rem
  }

  .sm\:-ml-400 {
    margin-left: -40rem
  }

  .sm\:-mt-512 {
    margin-top: -51.2rem
  }

  .sm\:-mr-512 {
    margin-right: -51.2rem
  }

  .sm\:-mb-512 {
    margin-bottom: -51.2rem
  }

  .sm\:-ml-512 {
    margin-left: -51.2rem
  }

  .sm\:-mt-640 {
    margin-top: -64rem
  }

  .sm\:-mr-640 {
    margin-right: -64rem
  }

  .sm\:-mb-640 {
    margin-bottom: -64rem
  }

  .sm\:-ml-640 {
    margin-left: -64rem
  }

  .sm\:-mt-xs {
    margin-top: -32rem
  }

  .sm\:-mr-xs {
    margin-right: -32rem
  }

  .sm\:-mb-xs {
    margin-bottom: -32rem
  }

  .sm\:-ml-xs {
    margin-left: -32rem
  }

  .sm\:-mt-sm {
    margin-top: -48rem
  }

  .sm\:-mr-sm {
    margin-right: -48rem
  }

  .sm\:-mb-sm {
    margin-bottom: -48rem
  }

  .sm\:-ml-sm {
    margin-left: -48rem
  }

  .sm\:-mt-md {
    margin-top: -64rem
  }

  .sm\:-mr-md {
    margin-right: -64rem
  }

  .sm\:-mb-md {
    margin-bottom: -64rem
  }

  .sm\:-ml-md {
    margin-left: -64rem
  }

  .sm\:-mt-lg {
    margin-top: -80rem
  }

  .sm\:-mr-lg {
    margin-right: -80rem
  }

  .sm\:-mb-lg {
    margin-bottom: -80rem
  }

  .sm\:-ml-lg {
    margin-left: -80rem
  }

  .sm\:-mt-xl {
    margin-top: -96rem
  }

  .sm\:-mr-xl {
    margin-right: -96rem
  }

  .sm\:-mb-xl {
    margin-bottom: -96rem
  }

  .sm\:-ml-xl {
    margin-left: -96rem
  }

  .sm\:-mt-2xl {
    margin-top: -112rem
  }

  .sm\:-mr-2xl {
    margin-right: -112rem
  }

  .sm\:-mb-2xl {
    margin-bottom: -112rem
  }

  .sm\:-ml-2xl {
    margin-left: -112rem
  }

  .sm\:-mt-3xl {
    margin-top: -128rem
  }

  .sm\:-mr-3xl {
    margin-right: -128rem
  }

  .sm\:-mb-3xl {
    margin-bottom: -128rem
  }

  .sm\:-ml-3xl {
    margin-left: -128rem
  }

  .sm\:-mt-4xl {
    margin-top: -144rem
  }

  .sm\:-mr-4xl {
    margin-right: -144rem
  }

  .sm\:-mb-4xl {
    margin-bottom: -144rem
  }

  .sm\:-ml-4xl {
    margin-left: -144rem
  }

  .sm\:-mt-5xl {
    margin-top: -160rem
  }

  .sm\:-mr-5xl {
    margin-right: -160rem
  }

  .sm\:-mb-5xl {
    margin-bottom: -160rem
  }

  .sm\:-ml-5xl {
    margin-left: -160rem
  }

  .sm\:-mt-px {
    margin-top: -1px
  }

  .sm\:-mr-px {
    margin-right: -1px
  }

  .sm\:-mb-px {
    margin-bottom: -1px
  }

  .sm\:-ml-px {
    margin-left: -1px
  }

  .sm\:-mt-0\.5 {
    margin-top: -0.05rem
  }

  .sm\:-mr-0\.5 {
    margin-right: -0.05rem
  }

  .sm\:-mb-0\.5 {
    margin-bottom: -0.05rem
  }

  .sm\:-ml-0\.5 {
    margin-left: -0.05rem
  }

  .sm\:-mt-1\.5 {
    margin-top: -0.15rem
  }

  .sm\:-mr-1\.5 {
    margin-right: -0.15rem
  }

  .sm\:-mb-1\.5 {
    margin-bottom: -0.15rem
  }

  .sm\:-ml-1\.5 {
    margin-left: -0.15rem
  }

  .sm\:-mt-2\.5 {
    margin-top: -0.25rem
  }

  .sm\:-mr-2\.5 {
    margin-right: -0.25rem
  }

  .sm\:-mb-2\.5 {
    margin-bottom: -0.25rem
  }

  .sm\:-ml-2\.5 {
    margin-left: -0.25rem
  }

  .sm\:-mt-3\.5 {
    margin-top: -0.35rem
  }

  .sm\:-mr-3\.5 {
    margin-right: -0.35rem
  }

  .sm\:-mb-3\.5 {
    margin-bottom: -0.35rem
  }

  .sm\:-ml-3\.5 {
    margin-left: -0.35rem
  }

  [dir='ltr'] .sm\:ltr\:m-0,[dir='ltr'].sm\:ltr\:m-0 {
    margin: 0
  }

  [dir='ltr'] .sm\:ltr\:m-1,[dir='ltr'].sm\:ltr\:m-1 {
    margin: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:m-2,[dir='ltr'].sm\:ltr\:m-2 {
    margin: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:m-3,[dir='ltr'].sm\:ltr\:m-3 {
    margin: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:m-4,[dir='ltr'].sm\:ltr\:m-4 {
    margin: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:m-5,[dir='ltr'].sm\:ltr\:m-5 {
    margin: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:m-6,[dir='ltr'].sm\:ltr\:m-6 {
    margin: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:m-7,[dir='ltr'].sm\:ltr\:m-7 {
    margin: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:m-8,[dir='ltr'].sm\:ltr\:m-8 {
    margin: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:m-9,[dir='ltr'].sm\:ltr\:m-9 {
    margin: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:m-10,[dir='ltr'].sm\:ltr\:m-10 {
    margin: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:m-12,[dir='ltr'].sm\:ltr\:m-12 {
    margin: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:m-14,[dir='ltr'].sm\:ltr\:m-14 {
    margin: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:m-16,[dir='ltr'].sm\:ltr\:m-16 {
    margin: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:m-20,[dir='ltr'].sm\:ltr\:m-20 {
    margin: 2rem
  }

  [dir='ltr'] .sm\:ltr\:m-24,[dir='ltr'].sm\:ltr\:m-24 {
    margin: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:m-28,[dir='ltr'].sm\:ltr\:m-28 {
    margin: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:m-32,[dir='ltr'].sm\:ltr\:m-32 {
    margin: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:m-36,[dir='ltr'].sm\:ltr\:m-36 {
    margin: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:m-40,[dir='ltr'].sm\:ltr\:m-40 {
    margin: 4rem
  }

  [dir='ltr'] .sm\:ltr\:m-44,[dir='ltr'].sm\:ltr\:m-44 {
    margin: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:m-48,[dir='ltr'].sm\:ltr\:m-48 {
    margin: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:m-52,[dir='ltr'].sm\:ltr\:m-52 {
    margin: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:m-56,[dir='ltr'].sm\:ltr\:m-56 {
    margin: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:m-60,[dir='ltr'].sm\:ltr\:m-60 {
    margin: 6rem
  }

  [dir='ltr'] .sm\:ltr\:m-64,[dir='ltr'].sm\:ltr\:m-64 {
    margin: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:m-68,[dir='ltr'].sm\:ltr\:m-68 {
    margin: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:m-72,[dir='ltr'].sm\:ltr\:m-72 {
    margin: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:m-76,[dir='ltr'].sm\:ltr\:m-76 {
    margin: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:m-80,[dir='ltr'].sm\:ltr\:m-80 {
    margin: 8rem
  }

  [dir='ltr'] .sm\:ltr\:m-84,[dir='ltr'].sm\:ltr\:m-84 {
    margin: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:m-88,[dir='ltr'].sm\:ltr\:m-88 {
    margin: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:m-92,[dir='ltr'].sm\:ltr\:m-92 {
    margin: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:m-96,[dir='ltr'].sm\:ltr\:m-96 {
    margin: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:m-128,[dir='ltr'].sm\:ltr\:m-128 {
    margin: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:m-136,[dir='ltr'].sm\:ltr\:m-136 {
    margin: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:m-160,[dir='ltr'].sm\:ltr\:m-160 {
    margin: 16rem
  }

  [dir='ltr'] .sm\:ltr\:m-192,[dir='ltr'].sm\:ltr\:m-192 {
    margin: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:m-200,[dir='ltr'].sm\:ltr\:m-200 {
    margin: 20rem
  }

  [dir='ltr'] .sm\:ltr\:m-208,[dir='ltr'].sm\:ltr\:m-208 {
    margin: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:m-216,[dir='ltr'].sm\:ltr\:m-216 {
    margin: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:m-224,[dir='ltr'].sm\:ltr\:m-224 {
    margin: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:m-256,[dir='ltr'].sm\:ltr\:m-256 {
    margin: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:m-288,[dir='ltr'].sm\:ltr\:m-288 {
    margin: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:m-320,[dir='ltr'].sm\:ltr\:m-320 {
    margin: 32rem
  }

  [dir='ltr'] .sm\:ltr\:m-360,[dir='ltr'].sm\:ltr\:m-360 {
    margin: 36rem
  }

  [dir='ltr'] .sm\:ltr\:m-384,[dir='ltr'].sm\:ltr\:m-384 {
    margin: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:m-400,[dir='ltr'].sm\:ltr\:m-400 {
    margin: 40rem
  }

  [dir='ltr'] .sm\:ltr\:m-512,[dir='ltr'].sm\:ltr\:m-512 {
    margin: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:m-640,[dir='ltr'].sm\:ltr\:m-640 {
    margin: 64rem
  }

  [dir='ltr'] .sm\:ltr\:m-auto,[dir='ltr'].sm\:ltr\:m-auto {
    margin: auto
  }

  [dir='ltr'] .sm\:ltr\:m-xs,[dir='ltr'].sm\:ltr\:m-xs {
    margin: 32rem
  }

  [dir='ltr'] .sm\:ltr\:m-sm,[dir='ltr'].sm\:ltr\:m-sm {
    margin: 48rem
  }

  [dir='ltr'] .sm\:ltr\:m-md,[dir='ltr'].sm\:ltr\:m-md {
    margin: 64rem
  }

  [dir='ltr'] .sm\:ltr\:m-lg,[dir='ltr'].sm\:ltr\:m-lg {
    margin: 80rem
  }

  [dir='ltr'] .sm\:ltr\:m-xl,[dir='ltr'].sm\:ltr\:m-xl {
    margin: 96rem
  }

  [dir='ltr'] .sm\:ltr\:m-2xl,[dir='ltr'].sm\:ltr\:m-2xl {
    margin: 112rem
  }

  [dir='ltr'] .sm\:ltr\:m-3xl,[dir='ltr'].sm\:ltr\:m-3xl {
    margin: 128rem
  }

  [dir='ltr'] .sm\:ltr\:m-4xl,[dir='ltr'].sm\:ltr\:m-4xl {
    margin: 144rem
  }

  [dir='ltr'] .sm\:ltr\:m-5xl,[dir='ltr'].sm\:ltr\:m-5xl {
    margin: 160rem
  }

  [dir='ltr'] .sm\:ltr\:m-px,[dir='ltr'].sm\:ltr\:m-px {
    margin: 1px
  }

  [dir='ltr'] .sm\:ltr\:m-0\.5,[dir='ltr'].sm\:ltr\:m-0\.5 {
    margin: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:m-1\.5,[dir='ltr'].sm\:ltr\:m-1\.5 {
    margin: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:m-2\.5,[dir='ltr'].sm\:ltr\:m-2\.5 {
    margin: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:m-3\.5,[dir='ltr'].sm\:ltr\:m-3\.5 {
    margin: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:-m-1,[dir='ltr'].sm\:ltr\:-m-1 {
    margin: -0.1rem
  }

  [dir='ltr'] .sm\:ltr\:-m-2,[dir='ltr'].sm\:ltr\:-m-2 {
    margin: -0.2rem
  }

  [dir='ltr'] .sm\:ltr\:-m-3,[dir='ltr'].sm\:ltr\:-m-3 {
    margin: -0.3rem
  }

  [dir='ltr'] .sm\:ltr\:-m-4,[dir='ltr'].sm\:ltr\:-m-4 {
    margin: -0.4rem
  }

  [dir='ltr'] .sm\:ltr\:-m-5,[dir='ltr'].sm\:ltr\:-m-5 {
    margin: -0.5rem
  }

  [dir='ltr'] .sm\:ltr\:-m-6,[dir='ltr'].sm\:ltr\:-m-6 {
    margin: -0.6rem
  }

  [dir='ltr'] .sm\:ltr\:-m-7,[dir='ltr'].sm\:ltr\:-m-7 {
    margin: -0.7rem
  }

  [dir='ltr'] .sm\:ltr\:-m-8,[dir='ltr'].sm\:ltr\:-m-8 {
    margin: -0.8rem
  }

  [dir='ltr'] .sm\:ltr\:-m-9,[dir='ltr'].sm\:ltr\:-m-9 {
    margin: -0.9rem
  }

  [dir='ltr'] .sm\:ltr\:-m-10,[dir='ltr'].sm\:ltr\:-m-10 {
    margin: -1rem
  }

  [dir='ltr'] .sm\:ltr\:-m-12,[dir='ltr'].sm\:ltr\:-m-12 {
    margin: -1.2rem
  }

  [dir='ltr'] .sm\:ltr\:-m-14,[dir='ltr'].sm\:ltr\:-m-14 {
    margin: -1.4rem
  }

  [dir='ltr'] .sm\:ltr\:-m-16,[dir='ltr'].sm\:ltr\:-m-16 {
    margin: -1.6rem
  }

  [dir='ltr'] .sm\:ltr\:-m-20,[dir='ltr'].sm\:ltr\:-m-20 {
    margin: -2rem
  }

  [dir='ltr'] .sm\:ltr\:-m-24,[dir='ltr'].sm\:ltr\:-m-24 {
    margin: -2.4rem
  }

  [dir='ltr'] .sm\:ltr\:-m-28,[dir='ltr'].sm\:ltr\:-m-28 {
    margin: -2.8rem
  }

  [dir='ltr'] .sm\:ltr\:-m-32,[dir='ltr'].sm\:ltr\:-m-32 {
    margin: -3.2rem
  }

  [dir='ltr'] .sm\:ltr\:-m-36,[dir='ltr'].sm\:ltr\:-m-36 {
    margin: -3.6rem
  }

  [dir='ltr'] .sm\:ltr\:-m-40,[dir='ltr'].sm\:ltr\:-m-40 {
    margin: -4rem
  }

  [dir='ltr'] .sm\:ltr\:-m-44,[dir='ltr'].sm\:ltr\:-m-44 {
    margin: -4.4rem
  }

  [dir='ltr'] .sm\:ltr\:-m-48,[dir='ltr'].sm\:ltr\:-m-48 {
    margin: -4.8rem
  }

  [dir='ltr'] .sm\:ltr\:-m-52,[dir='ltr'].sm\:ltr\:-m-52 {
    margin: -5.2rem
  }

  [dir='ltr'] .sm\:ltr\:-m-56,[dir='ltr'].sm\:ltr\:-m-56 {
    margin: -5.6rem
  }

  [dir='ltr'] .sm\:ltr\:-m-60,[dir='ltr'].sm\:ltr\:-m-60 {
    margin: -6rem
  }

  [dir='ltr'] .sm\:ltr\:-m-64,[dir='ltr'].sm\:ltr\:-m-64 {
    margin: -6.4rem
  }

  [dir='ltr'] .sm\:ltr\:-m-68,[dir='ltr'].sm\:ltr\:-m-68 {
    margin: -6.8rem
  }

  [dir='ltr'] .sm\:ltr\:-m-72,[dir='ltr'].sm\:ltr\:-m-72 {
    margin: -7.2rem
  }

  [dir='ltr'] .sm\:ltr\:-m-76,[dir='ltr'].sm\:ltr\:-m-76 {
    margin: -7.6rem
  }

  [dir='ltr'] .sm\:ltr\:-m-80,[dir='ltr'].sm\:ltr\:-m-80 {
    margin: -8rem
  }

  [dir='ltr'] .sm\:ltr\:-m-84,[dir='ltr'].sm\:ltr\:-m-84 {
    margin: -8.4rem
  }

  [dir='ltr'] .sm\:ltr\:-m-88,[dir='ltr'].sm\:ltr\:-m-88 {
    margin: -8.8rem
  }

  [dir='ltr'] .sm\:ltr\:-m-92,[dir='ltr'].sm\:ltr\:-m-92 {
    margin: -9.2rem
  }

  [dir='ltr'] .sm\:ltr\:-m-96,[dir='ltr'].sm\:ltr\:-m-96 {
    margin: -9.6rem
  }

  [dir='ltr'] .sm\:ltr\:-m-128,[dir='ltr'].sm\:ltr\:-m-128 {
    margin: -12.8rem
  }

  [dir='ltr'] .sm\:ltr\:-m-136,[dir='ltr'].sm\:ltr\:-m-136 {
    margin: -13.6rem
  }

  [dir='ltr'] .sm\:ltr\:-m-160,[dir='ltr'].sm\:ltr\:-m-160 {
    margin: -16rem
  }

  [dir='ltr'] .sm\:ltr\:-m-192,[dir='ltr'].sm\:ltr\:-m-192 {
    margin: -19.2rem
  }

  [dir='ltr'] .sm\:ltr\:-m-200,[dir='ltr'].sm\:ltr\:-m-200 {
    margin: -20rem
  }

  [dir='ltr'] .sm\:ltr\:-m-208,[dir='ltr'].sm\:ltr\:-m-208 {
    margin: -20.8rem
  }

  [dir='ltr'] .sm\:ltr\:-m-216,[dir='ltr'].sm\:ltr\:-m-216 {
    margin: -21.6rem
  }

  [dir='ltr'] .sm\:ltr\:-m-224,[dir='ltr'].sm\:ltr\:-m-224 {
    margin: -22.4rem
  }

  [dir='ltr'] .sm\:ltr\:-m-256,[dir='ltr'].sm\:ltr\:-m-256 {
    margin: -25.6rem
  }

  [dir='ltr'] .sm\:ltr\:-m-288,[dir='ltr'].sm\:ltr\:-m-288 {
    margin: -28.8rem
  }

  [dir='ltr'] .sm\:ltr\:-m-320,[dir='ltr'].sm\:ltr\:-m-320 {
    margin: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-m-360,[dir='ltr'].sm\:ltr\:-m-360 {
    margin: -36rem
  }

  [dir='ltr'] .sm\:ltr\:-m-384,[dir='ltr'].sm\:ltr\:-m-384 {
    margin: -38.4rem
  }

  [dir='ltr'] .sm\:ltr\:-m-400,[dir='ltr'].sm\:ltr\:-m-400 {
    margin: -40rem
  }

  [dir='ltr'] .sm\:ltr\:-m-512,[dir='ltr'].sm\:ltr\:-m-512 {
    margin: -51.2rem
  }

  [dir='ltr'] .sm\:ltr\:-m-640,[dir='ltr'].sm\:ltr\:-m-640 {
    margin: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-m-xs,[dir='ltr'].sm\:ltr\:-m-xs {
    margin: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-m-sm,[dir='ltr'].sm\:ltr\:-m-sm {
    margin: -48rem
  }

  [dir='ltr'] .sm\:ltr\:-m-md,[dir='ltr'].sm\:ltr\:-m-md {
    margin: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-m-lg,[dir='ltr'].sm\:ltr\:-m-lg {
    margin: -80rem
  }

  [dir='ltr'] .sm\:ltr\:-m-xl,[dir='ltr'].sm\:ltr\:-m-xl {
    margin: -96rem
  }

  [dir='ltr'] .sm\:ltr\:-m-2xl,[dir='ltr'].sm\:ltr\:-m-2xl {
    margin: -112rem
  }

  [dir='ltr'] .sm\:ltr\:-m-3xl,[dir='ltr'].sm\:ltr\:-m-3xl {
    margin: -128rem
  }

  [dir='ltr'] .sm\:ltr\:-m-4xl,[dir='ltr'].sm\:ltr\:-m-4xl {
    margin: -144rem
  }

  [dir='ltr'] .sm\:ltr\:-m-5xl,[dir='ltr'].sm\:ltr\:-m-5xl {
    margin: -160rem
  }

  [dir='ltr'] .sm\:ltr\:-m-px,[dir='ltr'].sm\:ltr\:-m-px {
    margin: -1px
  }

  [dir='ltr'] .sm\:ltr\:-m-0\.5,[dir='ltr'].sm\:ltr\:-m-0\.5 {
    margin: -0.05rem
  }

  [dir='ltr'] .sm\:ltr\:-m-1\.5,[dir='ltr'].sm\:ltr\:-m-1\.5 {
    margin: -0.15rem
  }

  [dir='ltr'] .sm\:ltr\:-m-2\.5,[dir='ltr'].sm\:ltr\:-m-2\.5 {
    margin: -0.25rem
  }

  [dir='ltr'] .sm\:ltr\:-m-3\.5,[dir='ltr'].sm\:ltr\:-m-3\.5 {
    margin: -0.35rem
  }

  [dir='ltr'] .sm\:ltr\:my-0,[dir='ltr'].sm\:ltr\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  [dir='ltr'] .sm\:ltr\:mx-0,[dir='ltr'].sm\:ltr\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  [dir='ltr'] .sm\:ltr\:my-1,[dir='ltr'].sm\:ltr\:my-1 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:mx-1,[dir='ltr'].sm\:ltr\:mx-1 {
    margin-left: 0.1rem;
    margin-right: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:my-2,[dir='ltr'].sm\:ltr\:my-2 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:mx-2,[dir='ltr'].sm\:ltr\:mx-2 {
    margin-left: 0.2rem;
    margin-right: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:my-3,[dir='ltr'].sm\:ltr\:my-3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:mx-3,[dir='ltr'].sm\:ltr\:mx-3 {
    margin-left: 0.3rem;
    margin-right: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:my-4,[dir='ltr'].sm\:ltr\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:mx-4,[dir='ltr'].sm\:ltr\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:my-5,[dir='ltr'].sm\:ltr\:my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:mx-5,[dir='ltr'].sm\:ltr\:mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:my-6,[dir='ltr'].sm\:ltr\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:mx-6,[dir='ltr'].sm\:ltr\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:my-7,[dir='ltr'].sm\:ltr\:my-7 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:mx-7,[dir='ltr'].sm\:ltr\:mx-7 {
    margin-left: 0.7rem;
    margin-right: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:my-8,[dir='ltr'].sm\:ltr\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:mx-8,[dir='ltr'].sm\:ltr\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:my-9,[dir='ltr'].sm\:ltr\:my-9 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:mx-9,[dir='ltr'].sm\:ltr\:mx-9 {
    margin-left: 0.9rem;
    margin-right: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:my-10,[dir='ltr'].sm\:ltr\:my-10 {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:mx-10,[dir='ltr'].sm\:ltr\:mx-10 {
    margin-left: 1.0rem;
    margin-right: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:my-12,[dir='ltr'].sm\:ltr\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:mx-12,[dir='ltr'].sm\:ltr\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:my-14,[dir='ltr'].sm\:ltr\:my-14 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:mx-14,[dir='ltr'].sm\:ltr\:mx-14 {
    margin-left: 1.4rem;
    margin-right: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:my-16,[dir='ltr'].sm\:ltr\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:mx-16,[dir='ltr'].sm\:ltr\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:my-20,[dir='ltr'].sm\:ltr\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  [dir='ltr'] .sm\:ltr\:mx-20,[dir='ltr'].sm\:ltr\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  [dir='ltr'] .sm\:ltr\:my-24,[dir='ltr'].sm\:ltr\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:mx-24,[dir='ltr'].sm\:ltr\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:my-28,[dir='ltr'].sm\:ltr\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:mx-28,[dir='ltr'].sm\:ltr\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:my-32,[dir='ltr'].sm\:ltr\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:mx-32,[dir='ltr'].sm\:ltr\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:my-36,[dir='ltr'].sm\:ltr\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:mx-36,[dir='ltr'].sm\:ltr\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:my-40,[dir='ltr'].sm\:ltr\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  [dir='ltr'] .sm\:ltr\:mx-40,[dir='ltr'].sm\:ltr\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  [dir='ltr'] .sm\:ltr\:my-44,[dir='ltr'].sm\:ltr\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:mx-44,[dir='ltr'].sm\:ltr\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:my-48,[dir='ltr'].sm\:ltr\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:mx-48,[dir='ltr'].sm\:ltr\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:my-52,[dir='ltr'].sm\:ltr\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:mx-52,[dir='ltr'].sm\:ltr\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:my-56,[dir='ltr'].sm\:ltr\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:mx-56,[dir='ltr'].sm\:ltr\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:my-60,[dir='ltr'].sm\:ltr\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  [dir='ltr'] .sm\:ltr\:mx-60,[dir='ltr'].sm\:ltr\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  [dir='ltr'] .sm\:ltr\:my-64,[dir='ltr'].sm\:ltr\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:mx-64,[dir='ltr'].sm\:ltr\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:my-68,[dir='ltr'].sm\:ltr\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:mx-68,[dir='ltr'].sm\:ltr\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:my-72,[dir='ltr'].sm\:ltr\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:mx-72,[dir='ltr'].sm\:ltr\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:my-76,[dir='ltr'].sm\:ltr\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:mx-76,[dir='ltr'].sm\:ltr\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:my-80,[dir='ltr'].sm\:ltr\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  [dir='ltr'] .sm\:ltr\:mx-80,[dir='ltr'].sm\:ltr\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  [dir='ltr'] .sm\:ltr\:my-84,[dir='ltr'].sm\:ltr\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:mx-84,[dir='ltr'].sm\:ltr\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:my-88,[dir='ltr'].sm\:ltr\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:mx-88,[dir='ltr'].sm\:ltr\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:my-92,[dir='ltr'].sm\:ltr\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:mx-92,[dir='ltr'].sm\:ltr\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:my-96,[dir='ltr'].sm\:ltr\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:mx-96,[dir='ltr'].sm\:ltr\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:my-128,[dir='ltr'].sm\:ltr\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:mx-128,[dir='ltr'].sm\:ltr\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:my-136,[dir='ltr'].sm\:ltr\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:mx-136,[dir='ltr'].sm\:ltr\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:my-160,[dir='ltr'].sm\:ltr\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  [dir='ltr'] .sm\:ltr\:mx-160,[dir='ltr'].sm\:ltr\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  [dir='ltr'] .sm\:ltr\:my-192,[dir='ltr'].sm\:ltr\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:mx-192,[dir='ltr'].sm\:ltr\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:my-200,[dir='ltr'].sm\:ltr\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  [dir='ltr'] .sm\:ltr\:mx-200,[dir='ltr'].sm\:ltr\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  [dir='ltr'] .sm\:ltr\:my-208,[dir='ltr'].sm\:ltr\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:mx-208,[dir='ltr'].sm\:ltr\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:my-216,[dir='ltr'].sm\:ltr\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:mx-216,[dir='ltr'].sm\:ltr\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:my-224,[dir='ltr'].sm\:ltr\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:mx-224,[dir='ltr'].sm\:ltr\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:my-256,[dir='ltr'].sm\:ltr\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:mx-256,[dir='ltr'].sm\:ltr\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:my-288,[dir='ltr'].sm\:ltr\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:mx-288,[dir='ltr'].sm\:ltr\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:my-320,[dir='ltr'].sm\:ltr\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='ltr'] .sm\:ltr\:mx-320,[dir='ltr'].sm\:ltr\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='ltr'] .sm\:ltr\:my-360,[dir='ltr'].sm\:ltr\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  [dir='ltr'] .sm\:ltr\:mx-360,[dir='ltr'].sm\:ltr\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  [dir='ltr'] .sm\:ltr\:my-384,[dir='ltr'].sm\:ltr\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:mx-384,[dir='ltr'].sm\:ltr\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:my-400,[dir='ltr'].sm\:ltr\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  [dir='ltr'] .sm\:ltr\:mx-400,[dir='ltr'].sm\:ltr\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  [dir='ltr'] .sm\:ltr\:my-512,[dir='ltr'].sm\:ltr\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:mx-512,[dir='ltr'].sm\:ltr\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:my-640,[dir='ltr'].sm\:ltr\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='ltr'] .sm\:ltr\:mx-640,[dir='ltr'].sm\:ltr\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='ltr'] .sm\:ltr\:my-auto,[dir='ltr'].sm\:ltr\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  [dir='ltr'] .sm\:ltr\:mx-auto,[dir='ltr'].sm\:ltr\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  [dir='ltr'] .sm\:ltr\:my-xs,[dir='ltr'].sm\:ltr\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='ltr'] .sm\:ltr\:mx-xs,[dir='ltr'].sm\:ltr\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='ltr'] .sm\:ltr\:my-sm,[dir='ltr'].sm\:ltr\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  [dir='ltr'] .sm\:ltr\:mx-sm,[dir='ltr'].sm\:ltr\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  [dir='ltr'] .sm\:ltr\:my-md,[dir='ltr'].sm\:ltr\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='ltr'] .sm\:ltr\:mx-md,[dir='ltr'].sm\:ltr\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='ltr'] .sm\:ltr\:my-lg,[dir='ltr'].sm\:ltr\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  [dir='ltr'] .sm\:ltr\:mx-lg,[dir='ltr'].sm\:ltr\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  [dir='ltr'] .sm\:ltr\:my-xl,[dir='ltr'].sm\:ltr\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  [dir='ltr'] .sm\:ltr\:mx-xl,[dir='ltr'].sm\:ltr\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  [dir='ltr'] .sm\:ltr\:my-2xl,[dir='ltr'].sm\:ltr\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  [dir='ltr'] .sm\:ltr\:mx-2xl,[dir='ltr'].sm\:ltr\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  [dir='ltr'] .sm\:ltr\:my-3xl,[dir='ltr'].sm\:ltr\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  [dir='ltr'] .sm\:ltr\:mx-3xl,[dir='ltr'].sm\:ltr\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  [dir='ltr'] .sm\:ltr\:my-4xl,[dir='ltr'].sm\:ltr\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  [dir='ltr'] .sm\:ltr\:mx-4xl,[dir='ltr'].sm\:ltr\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  [dir='ltr'] .sm\:ltr\:my-5xl,[dir='ltr'].sm\:ltr\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  [dir='ltr'] .sm\:ltr\:mx-5xl,[dir='ltr'].sm\:ltr\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  [dir='ltr'] .sm\:ltr\:my-px,[dir='ltr'].sm\:ltr\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  [dir='ltr'] .sm\:ltr\:mx-px,[dir='ltr'].sm\:ltr\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  [dir='ltr'] .sm\:ltr\:my-0\.5,[dir='ltr'].sm\:ltr\:my-0\.5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:mx-0\.5,[dir='ltr'].sm\:ltr\:mx-0\.5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:my-1\.5,[dir='ltr'].sm\:ltr\:my-1\.5 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:mx-1\.5,[dir='ltr'].sm\:ltr\:mx-1\.5 {
    margin-left: 0.15rem;
    margin-right: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:my-2\.5,[dir='ltr'].sm\:ltr\:my-2\.5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:mx-2\.5,[dir='ltr'].sm\:ltr\:mx-2\.5 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:my-3\.5,[dir='ltr'].sm\:ltr\:my-3\.5 {
    margin-top: 0.35rem;
    margin-bottom: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:mx-3\.5,[dir='ltr'].sm\:ltr\:mx-3\.5 {
    margin-left: 0.35rem;
    margin-right: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:-my-1,[dir='ltr'].sm\:ltr\:-my-1 {
    margin-top: -0.1rem;
    margin-bottom: -0.1rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-1,[dir='ltr'].sm\:ltr\:-mx-1 {
    margin-left: -0.1rem;
    margin-right: -0.1rem
  }

  [dir='ltr'] .sm\:ltr\:-my-2,[dir='ltr'].sm\:ltr\:-my-2 {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-2,[dir='ltr'].sm\:ltr\:-mx-2 {
    margin-left: -0.2rem;
    margin-right: -0.2rem
  }

  [dir='ltr'] .sm\:ltr\:-my-3,[dir='ltr'].sm\:ltr\:-my-3 {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-3,[dir='ltr'].sm\:ltr\:-mx-3 {
    margin-left: -0.3rem;
    margin-right: -0.3rem
  }

  [dir='ltr'] .sm\:ltr\:-my-4,[dir='ltr'].sm\:ltr\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-4,[dir='ltr'].sm\:ltr\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  [dir='ltr'] .sm\:ltr\:-my-5,[dir='ltr'].sm\:ltr\:-my-5 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-5,[dir='ltr'].sm\:ltr\:-mx-5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  [dir='ltr'] .sm\:ltr\:-my-6,[dir='ltr'].sm\:ltr\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-6,[dir='ltr'].sm\:ltr\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  [dir='ltr'] .sm\:ltr\:-my-7,[dir='ltr'].sm\:ltr\:-my-7 {
    margin-top: -0.7rem;
    margin-bottom: -0.7rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-7,[dir='ltr'].sm\:ltr\:-mx-7 {
    margin-left: -0.7rem;
    margin-right: -0.7rem
  }

  [dir='ltr'] .sm\:ltr\:-my-8,[dir='ltr'].sm\:ltr\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-8,[dir='ltr'].sm\:ltr\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  [dir='ltr'] .sm\:ltr\:-my-9,[dir='ltr'].sm\:ltr\:-my-9 {
    margin-top: -0.9rem;
    margin-bottom: -0.9rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-9,[dir='ltr'].sm\:ltr\:-mx-9 {
    margin-left: -0.9rem;
    margin-right: -0.9rem
  }

  [dir='ltr'] .sm\:ltr\:-my-10,[dir='ltr'].sm\:ltr\:-my-10 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-10,[dir='ltr'].sm\:ltr\:-mx-10 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  [dir='ltr'] .sm\:ltr\:-my-12,[dir='ltr'].sm\:ltr\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-12,[dir='ltr'].sm\:ltr\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  [dir='ltr'] .sm\:ltr\:-my-14,[dir='ltr'].sm\:ltr\:-my-14 {
    margin-top: -1.4rem;
    margin-bottom: -1.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-14,[dir='ltr'].sm\:ltr\:-mx-14 {
    margin-left: -1.4rem;
    margin-right: -1.4rem
  }

  [dir='ltr'] .sm\:ltr\:-my-16,[dir='ltr'].sm\:ltr\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-16,[dir='ltr'].sm\:ltr\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  [dir='ltr'] .sm\:ltr\:-my-20,[dir='ltr'].sm\:ltr\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-20,[dir='ltr'].sm\:ltr\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  [dir='ltr'] .sm\:ltr\:-my-24,[dir='ltr'].sm\:ltr\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-24,[dir='ltr'].sm\:ltr\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  [dir='ltr'] .sm\:ltr\:-my-28,[dir='ltr'].sm\:ltr\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-28,[dir='ltr'].sm\:ltr\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  [dir='ltr'] .sm\:ltr\:-my-32,[dir='ltr'].sm\:ltr\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-32,[dir='ltr'].sm\:ltr\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  [dir='ltr'] .sm\:ltr\:-my-36,[dir='ltr'].sm\:ltr\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-36,[dir='ltr'].sm\:ltr\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  [dir='ltr'] .sm\:ltr\:-my-40,[dir='ltr'].sm\:ltr\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-40,[dir='ltr'].sm\:ltr\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  [dir='ltr'] .sm\:ltr\:-my-44,[dir='ltr'].sm\:ltr\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-44,[dir='ltr'].sm\:ltr\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  [dir='ltr'] .sm\:ltr\:-my-48,[dir='ltr'].sm\:ltr\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-48,[dir='ltr'].sm\:ltr\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  [dir='ltr'] .sm\:ltr\:-my-52,[dir='ltr'].sm\:ltr\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-52,[dir='ltr'].sm\:ltr\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  [dir='ltr'] .sm\:ltr\:-my-56,[dir='ltr'].sm\:ltr\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-56,[dir='ltr'].sm\:ltr\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  [dir='ltr'] .sm\:ltr\:-my-60,[dir='ltr'].sm\:ltr\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-60,[dir='ltr'].sm\:ltr\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  [dir='ltr'] .sm\:ltr\:-my-64,[dir='ltr'].sm\:ltr\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-64,[dir='ltr'].sm\:ltr\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  [dir='ltr'] .sm\:ltr\:-my-68,[dir='ltr'].sm\:ltr\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-68,[dir='ltr'].sm\:ltr\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  [dir='ltr'] .sm\:ltr\:-my-72,[dir='ltr'].sm\:ltr\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-72,[dir='ltr'].sm\:ltr\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  [dir='ltr'] .sm\:ltr\:-my-76,[dir='ltr'].sm\:ltr\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-76,[dir='ltr'].sm\:ltr\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  [dir='ltr'] .sm\:ltr\:-my-80,[dir='ltr'].sm\:ltr\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-80,[dir='ltr'].sm\:ltr\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  [dir='ltr'] .sm\:ltr\:-my-84,[dir='ltr'].sm\:ltr\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-84,[dir='ltr'].sm\:ltr\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  [dir='ltr'] .sm\:ltr\:-my-88,[dir='ltr'].sm\:ltr\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-88,[dir='ltr'].sm\:ltr\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  [dir='ltr'] .sm\:ltr\:-my-92,[dir='ltr'].sm\:ltr\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-92,[dir='ltr'].sm\:ltr\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  [dir='ltr'] .sm\:ltr\:-my-96,[dir='ltr'].sm\:ltr\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-96,[dir='ltr'].sm\:ltr\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  [dir='ltr'] .sm\:ltr\:-my-128,[dir='ltr'].sm\:ltr\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-128,[dir='ltr'].sm\:ltr\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  [dir='ltr'] .sm\:ltr\:-my-136,[dir='ltr'].sm\:ltr\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-136,[dir='ltr'].sm\:ltr\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  [dir='ltr'] .sm\:ltr\:-my-160,[dir='ltr'].sm\:ltr\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-160,[dir='ltr'].sm\:ltr\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  [dir='ltr'] .sm\:ltr\:-my-192,[dir='ltr'].sm\:ltr\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-192,[dir='ltr'].sm\:ltr\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  [dir='ltr'] .sm\:ltr\:-my-200,[dir='ltr'].sm\:ltr\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-200,[dir='ltr'].sm\:ltr\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  [dir='ltr'] .sm\:ltr\:-my-208,[dir='ltr'].sm\:ltr\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-208,[dir='ltr'].sm\:ltr\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  [dir='ltr'] .sm\:ltr\:-my-216,[dir='ltr'].sm\:ltr\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-216,[dir='ltr'].sm\:ltr\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  [dir='ltr'] .sm\:ltr\:-my-224,[dir='ltr'].sm\:ltr\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-224,[dir='ltr'].sm\:ltr\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  [dir='ltr'] .sm\:ltr\:-my-256,[dir='ltr'].sm\:ltr\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-256,[dir='ltr'].sm\:ltr\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  [dir='ltr'] .sm\:ltr\:-my-288,[dir='ltr'].sm\:ltr\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-288,[dir='ltr'].sm\:ltr\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  [dir='ltr'] .sm\:ltr\:-my-320,[dir='ltr'].sm\:ltr\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-320,[dir='ltr'].sm\:ltr\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-my-360,[dir='ltr'].sm\:ltr\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-360,[dir='ltr'].sm\:ltr\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  [dir='ltr'] .sm\:ltr\:-my-384,[dir='ltr'].sm\:ltr\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-384,[dir='ltr'].sm\:ltr\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  [dir='ltr'] .sm\:ltr\:-my-400,[dir='ltr'].sm\:ltr\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-400,[dir='ltr'].sm\:ltr\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  [dir='ltr'] .sm\:ltr\:-my-512,[dir='ltr'].sm\:ltr\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-512,[dir='ltr'].sm\:ltr\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  [dir='ltr'] .sm\:ltr\:-my-640,[dir='ltr'].sm\:ltr\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-640,[dir='ltr'].sm\:ltr\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-my-xs,[dir='ltr'].sm\:ltr\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-xs,[dir='ltr'].sm\:ltr\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-my-sm,[dir='ltr'].sm\:ltr\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-sm,[dir='ltr'].sm\:ltr\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  [dir='ltr'] .sm\:ltr\:-my-md,[dir='ltr'].sm\:ltr\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-md,[dir='ltr'].sm\:ltr\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-my-lg,[dir='ltr'].sm\:ltr\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-lg,[dir='ltr'].sm\:ltr\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  [dir='ltr'] .sm\:ltr\:-my-xl,[dir='ltr'].sm\:ltr\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-xl,[dir='ltr'].sm\:ltr\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  [dir='ltr'] .sm\:ltr\:-my-2xl,[dir='ltr'].sm\:ltr\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-2xl,[dir='ltr'].sm\:ltr\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  [dir='ltr'] .sm\:ltr\:-my-3xl,[dir='ltr'].sm\:ltr\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-3xl,[dir='ltr'].sm\:ltr\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  [dir='ltr'] .sm\:ltr\:-my-4xl,[dir='ltr'].sm\:ltr\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-4xl,[dir='ltr'].sm\:ltr\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  [dir='ltr'] .sm\:ltr\:-my-5xl,[dir='ltr'].sm\:ltr\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-5xl,[dir='ltr'].sm\:ltr\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  [dir='ltr'] .sm\:ltr\:-my-px,[dir='ltr'].sm\:ltr\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  [dir='ltr'] .sm\:ltr\:-mx-px,[dir='ltr'].sm\:ltr\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  [dir='ltr'] .sm\:ltr\:-my-0\.5,[dir='ltr'].sm\:ltr\:-my-0\.5 {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-0\.5,[dir='ltr'].sm\:ltr\:-mx-0\.5 {
    margin-left: -0.05rem;
    margin-right: -0.05rem
  }

  [dir='ltr'] .sm\:ltr\:-my-1\.5,[dir='ltr'].sm\:ltr\:-my-1\.5 {
    margin-top: -0.15rem;
    margin-bottom: -0.15rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-1\.5,[dir='ltr'].sm\:ltr\:-mx-1\.5 {
    margin-left: -0.15rem;
    margin-right: -0.15rem
  }

  [dir='ltr'] .sm\:ltr\:-my-2\.5,[dir='ltr'].sm\:ltr\:-my-2\.5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-2\.5,[dir='ltr'].sm\:ltr\:-mx-2\.5 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  [dir='ltr'] .sm\:ltr\:-my-3\.5,[dir='ltr'].sm\:ltr\:-my-3\.5 {
    margin-top: -0.35rem;
    margin-bottom: -0.35rem
  }

  [dir='ltr'] .sm\:ltr\:-mx-3\.5,[dir='ltr'].sm\:ltr\:-mx-3\.5 {
    margin-left: -0.35rem;
    margin-right: -0.35rem
  }

  [dir='ltr'] .sm\:ltr\:mt-0,[dir='ltr'].sm\:ltr\:mt-0 {
    margin-top: 0
  }

  [dir='ltr'] .sm\:ltr\:mr-0,[dir='ltr'].sm\:ltr\:mr-0 {
    margin-right: 0
  }

  [dir='ltr'] .sm\:ltr\:mb-0,[dir='ltr'].sm\:ltr\:mb-0 {
    margin-bottom: 0
  }

  [dir='ltr'] .sm\:ltr\:ml-0,[dir='ltr'].sm\:ltr\:ml-0 {
    margin-left: 0
  }

  [dir='ltr'] .sm\:ltr\:mt-1,[dir='ltr'].sm\:ltr\:mt-1 {
    margin-top: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:mr-1,[dir='ltr'].sm\:ltr\:mr-1 {
    margin-right: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:mb-1,[dir='ltr'].sm\:ltr\:mb-1 {
    margin-bottom: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:ml-1,[dir='ltr'].sm\:ltr\:ml-1 {
    margin-left: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:mt-2,[dir='ltr'].sm\:ltr\:mt-2 {
    margin-top: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:mr-2,[dir='ltr'].sm\:ltr\:mr-2 {
    margin-right: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:mb-2,[dir='ltr'].sm\:ltr\:mb-2 {
    margin-bottom: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:ml-2,[dir='ltr'].sm\:ltr\:ml-2 {
    margin-left: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:mt-3,[dir='ltr'].sm\:ltr\:mt-3 {
    margin-top: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:mr-3,[dir='ltr'].sm\:ltr\:mr-3 {
    margin-right: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:mb-3,[dir='ltr'].sm\:ltr\:mb-3 {
    margin-bottom: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:ml-3,[dir='ltr'].sm\:ltr\:ml-3 {
    margin-left: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:mt-4,[dir='ltr'].sm\:ltr\:mt-4 {
    margin-top: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:mr-4,[dir='ltr'].sm\:ltr\:mr-4 {
    margin-right: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:mb-4,[dir='ltr'].sm\:ltr\:mb-4 {
    margin-bottom: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:ml-4,[dir='ltr'].sm\:ltr\:ml-4 {
    margin-left: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:mt-5,[dir='ltr'].sm\:ltr\:mt-5 {
    margin-top: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:mr-5,[dir='ltr'].sm\:ltr\:mr-5 {
    margin-right: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:mb-5,[dir='ltr'].sm\:ltr\:mb-5 {
    margin-bottom: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:ml-5,[dir='ltr'].sm\:ltr\:ml-5 {
    margin-left: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:mt-6,[dir='ltr'].sm\:ltr\:mt-6 {
    margin-top: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:mr-6,[dir='ltr'].sm\:ltr\:mr-6 {
    margin-right: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:mb-6,[dir='ltr'].sm\:ltr\:mb-6 {
    margin-bottom: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:ml-6,[dir='ltr'].sm\:ltr\:ml-6 {
    margin-left: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:mt-7,[dir='ltr'].sm\:ltr\:mt-7 {
    margin-top: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:mr-7,[dir='ltr'].sm\:ltr\:mr-7 {
    margin-right: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:mb-7,[dir='ltr'].sm\:ltr\:mb-7 {
    margin-bottom: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:ml-7,[dir='ltr'].sm\:ltr\:ml-7 {
    margin-left: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:mt-8,[dir='ltr'].sm\:ltr\:mt-8 {
    margin-top: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:mr-8,[dir='ltr'].sm\:ltr\:mr-8 {
    margin-right: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:mb-8,[dir='ltr'].sm\:ltr\:mb-8 {
    margin-bottom: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:ml-8,[dir='ltr'].sm\:ltr\:ml-8 {
    margin-left: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:mt-9,[dir='ltr'].sm\:ltr\:mt-9 {
    margin-top: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:mr-9,[dir='ltr'].sm\:ltr\:mr-9 {
    margin-right: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:mb-9,[dir='ltr'].sm\:ltr\:mb-9 {
    margin-bottom: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:ml-9,[dir='ltr'].sm\:ltr\:ml-9 {
    margin-left: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:mt-10,[dir='ltr'].sm\:ltr\:mt-10 {
    margin-top: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:mr-10,[dir='ltr'].sm\:ltr\:mr-10 {
    margin-right: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:mb-10,[dir='ltr'].sm\:ltr\:mb-10 {
    margin-bottom: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:ml-10,[dir='ltr'].sm\:ltr\:ml-10 {
    margin-left: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:mt-12,[dir='ltr'].sm\:ltr\:mt-12 {
    margin-top: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:mr-12,[dir='ltr'].sm\:ltr\:mr-12 {
    margin-right: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:mb-12,[dir='ltr'].sm\:ltr\:mb-12 {
    margin-bottom: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:ml-12,[dir='ltr'].sm\:ltr\:ml-12 {
    margin-left: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:mt-14,[dir='ltr'].sm\:ltr\:mt-14 {
    margin-top: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:mr-14,[dir='ltr'].sm\:ltr\:mr-14 {
    margin-right: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:mb-14,[dir='ltr'].sm\:ltr\:mb-14 {
    margin-bottom: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:ml-14,[dir='ltr'].sm\:ltr\:ml-14 {
    margin-left: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:mt-16,[dir='ltr'].sm\:ltr\:mt-16 {
    margin-top: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:mr-16,[dir='ltr'].sm\:ltr\:mr-16 {
    margin-right: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:mb-16,[dir='ltr'].sm\:ltr\:mb-16 {
    margin-bottom: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:ml-16,[dir='ltr'].sm\:ltr\:ml-16 {
    margin-left: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:mt-20,[dir='ltr'].sm\:ltr\:mt-20 {
    margin-top: 2rem
  }

  [dir='ltr'] .sm\:ltr\:mr-20,[dir='ltr'].sm\:ltr\:mr-20 {
    margin-right: 2rem
  }

  [dir='ltr'] .sm\:ltr\:mb-20,[dir='ltr'].sm\:ltr\:mb-20 {
    margin-bottom: 2rem
  }

  [dir='ltr'] .sm\:ltr\:ml-20,[dir='ltr'].sm\:ltr\:ml-20 {
    margin-left: 2rem
  }

  [dir='ltr'] .sm\:ltr\:mt-24,[dir='ltr'].sm\:ltr\:mt-24 {
    margin-top: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:mr-24,[dir='ltr'].sm\:ltr\:mr-24 {
    margin-right: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:mb-24,[dir='ltr'].sm\:ltr\:mb-24 {
    margin-bottom: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:ml-24,[dir='ltr'].sm\:ltr\:ml-24 {
    margin-left: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:mt-28,[dir='ltr'].sm\:ltr\:mt-28 {
    margin-top: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:mr-28,[dir='ltr'].sm\:ltr\:mr-28 {
    margin-right: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:mb-28,[dir='ltr'].sm\:ltr\:mb-28 {
    margin-bottom: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:ml-28,[dir='ltr'].sm\:ltr\:ml-28 {
    margin-left: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:mt-32,[dir='ltr'].sm\:ltr\:mt-32 {
    margin-top: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:mr-32,[dir='ltr'].sm\:ltr\:mr-32 {
    margin-right: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:mb-32,[dir='ltr'].sm\:ltr\:mb-32 {
    margin-bottom: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:ml-32,[dir='ltr'].sm\:ltr\:ml-32 {
    margin-left: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:mt-36,[dir='ltr'].sm\:ltr\:mt-36 {
    margin-top: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:mr-36,[dir='ltr'].sm\:ltr\:mr-36 {
    margin-right: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:mb-36,[dir='ltr'].sm\:ltr\:mb-36 {
    margin-bottom: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:ml-36,[dir='ltr'].sm\:ltr\:ml-36 {
    margin-left: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:mt-40,[dir='ltr'].sm\:ltr\:mt-40 {
    margin-top: 4rem
  }

  [dir='ltr'] .sm\:ltr\:mr-40,[dir='ltr'].sm\:ltr\:mr-40 {
    margin-right: 4rem
  }

  [dir='ltr'] .sm\:ltr\:mb-40,[dir='ltr'].sm\:ltr\:mb-40 {
    margin-bottom: 4rem
  }

  [dir='ltr'] .sm\:ltr\:ml-40,[dir='ltr'].sm\:ltr\:ml-40 {
    margin-left: 4rem
  }

  [dir='ltr'] .sm\:ltr\:mt-44,[dir='ltr'].sm\:ltr\:mt-44 {
    margin-top: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:mr-44,[dir='ltr'].sm\:ltr\:mr-44 {
    margin-right: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:mb-44,[dir='ltr'].sm\:ltr\:mb-44 {
    margin-bottom: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:ml-44,[dir='ltr'].sm\:ltr\:ml-44 {
    margin-left: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:mt-48,[dir='ltr'].sm\:ltr\:mt-48 {
    margin-top: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:mr-48,[dir='ltr'].sm\:ltr\:mr-48 {
    margin-right: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:mb-48,[dir='ltr'].sm\:ltr\:mb-48 {
    margin-bottom: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:ml-48,[dir='ltr'].sm\:ltr\:ml-48 {
    margin-left: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:mt-52,[dir='ltr'].sm\:ltr\:mt-52 {
    margin-top: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:mr-52,[dir='ltr'].sm\:ltr\:mr-52 {
    margin-right: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:mb-52,[dir='ltr'].sm\:ltr\:mb-52 {
    margin-bottom: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:ml-52,[dir='ltr'].sm\:ltr\:ml-52 {
    margin-left: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:mt-56,[dir='ltr'].sm\:ltr\:mt-56 {
    margin-top: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:mr-56,[dir='ltr'].sm\:ltr\:mr-56 {
    margin-right: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:mb-56,[dir='ltr'].sm\:ltr\:mb-56 {
    margin-bottom: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:ml-56,[dir='ltr'].sm\:ltr\:ml-56 {
    margin-left: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:mt-60,[dir='ltr'].sm\:ltr\:mt-60 {
    margin-top: 6rem
  }

  [dir='ltr'] .sm\:ltr\:mr-60,[dir='ltr'].sm\:ltr\:mr-60 {
    margin-right: 6rem
  }

  [dir='ltr'] .sm\:ltr\:mb-60,[dir='ltr'].sm\:ltr\:mb-60 {
    margin-bottom: 6rem
  }

  [dir='ltr'] .sm\:ltr\:ml-60,[dir='ltr'].sm\:ltr\:ml-60 {
    margin-left: 6rem
  }

  [dir='ltr'] .sm\:ltr\:mt-64,[dir='ltr'].sm\:ltr\:mt-64 {
    margin-top: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:mr-64,[dir='ltr'].sm\:ltr\:mr-64 {
    margin-right: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:mb-64,[dir='ltr'].sm\:ltr\:mb-64 {
    margin-bottom: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:ml-64,[dir='ltr'].sm\:ltr\:ml-64 {
    margin-left: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:mt-68,[dir='ltr'].sm\:ltr\:mt-68 {
    margin-top: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:mr-68,[dir='ltr'].sm\:ltr\:mr-68 {
    margin-right: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:mb-68,[dir='ltr'].sm\:ltr\:mb-68 {
    margin-bottom: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:ml-68,[dir='ltr'].sm\:ltr\:ml-68 {
    margin-left: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:mt-72,[dir='ltr'].sm\:ltr\:mt-72 {
    margin-top: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:mr-72,[dir='ltr'].sm\:ltr\:mr-72 {
    margin-right: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:mb-72,[dir='ltr'].sm\:ltr\:mb-72 {
    margin-bottom: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:ml-72,[dir='ltr'].sm\:ltr\:ml-72 {
    margin-left: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:mt-76,[dir='ltr'].sm\:ltr\:mt-76 {
    margin-top: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:mr-76,[dir='ltr'].sm\:ltr\:mr-76 {
    margin-right: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:mb-76,[dir='ltr'].sm\:ltr\:mb-76 {
    margin-bottom: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:ml-76,[dir='ltr'].sm\:ltr\:ml-76 {
    margin-left: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:mt-80,[dir='ltr'].sm\:ltr\:mt-80 {
    margin-top: 8rem
  }

  [dir='ltr'] .sm\:ltr\:mr-80,[dir='ltr'].sm\:ltr\:mr-80 {
    margin-right: 8rem
  }

  [dir='ltr'] .sm\:ltr\:mb-80,[dir='ltr'].sm\:ltr\:mb-80 {
    margin-bottom: 8rem
  }

  [dir='ltr'] .sm\:ltr\:ml-80,[dir='ltr'].sm\:ltr\:ml-80 {
    margin-left: 8rem
  }

  [dir='ltr'] .sm\:ltr\:mt-84,[dir='ltr'].sm\:ltr\:mt-84 {
    margin-top: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:mr-84,[dir='ltr'].sm\:ltr\:mr-84 {
    margin-right: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:mb-84,[dir='ltr'].sm\:ltr\:mb-84 {
    margin-bottom: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:ml-84,[dir='ltr'].sm\:ltr\:ml-84 {
    margin-left: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:mt-88,[dir='ltr'].sm\:ltr\:mt-88 {
    margin-top: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:mr-88,[dir='ltr'].sm\:ltr\:mr-88 {
    margin-right: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:mb-88,[dir='ltr'].sm\:ltr\:mb-88 {
    margin-bottom: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:ml-88,[dir='ltr'].sm\:ltr\:ml-88 {
    margin-left: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:mt-92,[dir='ltr'].sm\:ltr\:mt-92 {
    margin-top: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:mr-92,[dir='ltr'].sm\:ltr\:mr-92 {
    margin-right: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:mb-92,[dir='ltr'].sm\:ltr\:mb-92 {
    margin-bottom: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:ml-92,[dir='ltr'].sm\:ltr\:ml-92 {
    margin-left: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:mt-96,[dir='ltr'].sm\:ltr\:mt-96 {
    margin-top: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:mr-96,[dir='ltr'].sm\:ltr\:mr-96 {
    margin-right: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:mb-96,[dir='ltr'].sm\:ltr\:mb-96 {
    margin-bottom: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:ml-96,[dir='ltr'].sm\:ltr\:ml-96 {
    margin-left: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:mt-128,[dir='ltr'].sm\:ltr\:mt-128 {
    margin-top: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:mr-128,[dir='ltr'].sm\:ltr\:mr-128 {
    margin-right: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:mb-128,[dir='ltr'].sm\:ltr\:mb-128 {
    margin-bottom: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:ml-128,[dir='ltr'].sm\:ltr\:ml-128 {
    margin-left: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:mt-136,[dir='ltr'].sm\:ltr\:mt-136 {
    margin-top: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:mr-136,[dir='ltr'].sm\:ltr\:mr-136 {
    margin-right: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:mb-136,[dir='ltr'].sm\:ltr\:mb-136 {
    margin-bottom: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:ml-136,[dir='ltr'].sm\:ltr\:ml-136 {
    margin-left: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:mt-160,[dir='ltr'].sm\:ltr\:mt-160 {
    margin-top: 16rem
  }

  [dir='ltr'] .sm\:ltr\:mr-160,[dir='ltr'].sm\:ltr\:mr-160 {
    margin-right: 16rem
  }

  [dir='ltr'] .sm\:ltr\:mb-160,[dir='ltr'].sm\:ltr\:mb-160 {
    margin-bottom: 16rem
  }

  [dir='ltr'] .sm\:ltr\:ml-160,[dir='ltr'].sm\:ltr\:ml-160 {
    margin-left: 16rem
  }

  [dir='ltr'] .sm\:ltr\:mt-192,[dir='ltr'].sm\:ltr\:mt-192 {
    margin-top: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:mr-192,[dir='ltr'].sm\:ltr\:mr-192 {
    margin-right: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:mb-192,[dir='ltr'].sm\:ltr\:mb-192 {
    margin-bottom: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:ml-192,[dir='ltr'].sm\:ltr\:ml-192 {
    margin-left: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:mt-200,[dir='ltr'].sm\:ltr\:mt-200 {
    margin-top: 20rem
  }

  [dir='ltr'] .sm\:ltr\:mr-200,[dir='ltr'].sm\:ltr\:mr-200 {
    margin-right: 20rem
  }

  [dir='ltr'] .sm\:ltr\:mb-200,[dir='ltr'].sm\:ltr\:mb-200 {
    margin-bottom: 20rem
  }

  [dir='ltr'] .sm\:ltr\:ml-200,[dir='ltr'].sm\:ltr\:ml-200 {
    margin-left: 20rem
  }

  [dir='ltr'] .sm\:ltr\:mt-208,[dir='ltr'].sm\:ltr\:mt-208 {
    margin-top: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:mr-208,[dir='ltr'].sm\:ltr\:mr-208 {
    margin-right: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:mb-208,[dir='ltr'].sm\:ltr\:mb-208 {
    margin-bottom: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:ml-208,[dir='ltr'].sm\:ltr\:ml-208 {
    margin-left: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:mt-216,[dir='ltr'].sm\:ltr\:mt-216 {
    margin-top: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:mr-216,[dir='ltr'].sm\:ltr\:mr-216 {
    margin-right: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:mb-216,[dir='ltr'].sm\:ltr\:mb-216 {
    margin-bottom: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:ml-216,[dir='ltr'].sm\:ltr\:ml-216 {
    margin-left: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:mt-224,[dir='ltr'].sm\:ltr\:mt-224 {
    margin-top: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:mr-224,[dir='ltr'].sm\:ltr\:mr-224 {
    margin-right: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:mb-224,[dir='ltr'].sm\:ltr\:mb-224 {
    margin-bottom: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:ml-224,[dir='ltr'].sm\:ltr\:ml-224 {
    margin-left: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:mt-256,[dir='ltr'].sm\:ltr\:mt-256 {
    margin-top: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:mr-256,[dir='ltr'].sm\:ltr\:mr-256 {
    margin-right: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:mb-256,[dir='ltr'].sm\:ltr\:mb-256 {
    margin-bottom: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:ml-256,[dir='ltr'].sm\:ltr\:ml-256 {
    margin-left: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:mt-288,[dir='ltr'].sm\:ltr\:mt-288 {
    margin-top: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:mr-288,[dir='ltr'].sm\:ltr\:mr-288 {
    margin-right: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:mb-288,[dir='ltr'].sm\:ltr\:mb-288 {
    margin-bottom: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:ml-288,[dir='ltr'].sm\:ltr\:ml-288 {
    margin-left: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:mt-320,[dir='ltr'].sm\:ltr\:mt-320 {
    margin-top: 32rem
  }

  [dir='ltr'] .sm\:ltr\:mr-320,[dir='ltr'].sm\:ltr\:mr-320 {
    margin-right: 32rem
  }

  [dir='ltr'] .sm\:ltr\:mb-320,[dir='ltr'].sm\:ltr\:mb-320 {
    margin-bottom: 32rem
  }

  [dir='ltr'] .sm\:ltr\:ml-320,[dir='ltr'].sm\:ltr\:ml-320 {
    margin-left: 32rem
  }

  [dir='ltr'] .sm\:ltr\:mt-360,[dir='ltr'].sm\:ltr\:mt-360 {
    margin-top: 36rem
  }

  [dir='ltr'] .sm\:ltr\:mr-360,[dir='ltr'].sm\:ltr\:mr-360 {
    margin-right: 36rem
  }

  [dir='ltr'] .sm\:ltr\:mb-360,[dir='ltr'].sm\:ltr\:mb-360 {
    margin-bottom: 36rem
  }

  [dir='ltr'] .sm\:ltr\:ml-360,[dir='ltr'].sm\:ltr\:ml-360 {
    margin-left: 36rem
  }

  [dir='ltr'] .sm\:ltr\:mt-384,[dir='ltr'].sm\:ltr\:mt-384 {
    margin-top: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:mr-384,[dir='ltr'].sm\:ltr\:mr-384 {
    margin-right: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:mb-384,[dir='ltr'].sm\:ltr\:mb-384 {
    margin-bottom: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:ml-384,[dir='ltr'].sm\:ltr\:ml-384 {
    margin-left: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:mt-400,[dir='ltr'].sm\:ltr\:mt-400 {
    margin-top: 40rem
  }

  [dir='ltr'] .sm\:ltr\:mr-400,[dir='ltr'].sm\:ltr\:mr-400 {
    margin-right: 40rem
  }

  [dir='ltr'] .sm\:ltr\:mb-400,[dir='ltr'].sm\:ltr\:mb-400 {
    margin-bottom: 40rem
  }

  [dir='ltr'] .sm\:ltr\:ml-400,[dir='ltr'].sm\:ltr\:ml-400 {
    margin-left: 40rem
  }

  [dir='ltr'] .sm\:ltr\:mt-512,[dir='ltr'].sm\:ltr\:mt-512 {
    margin-top: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:mr-512,[dir='ltr'].sm\:ltr\:mr-512 {
    margin-right: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:mb-512,[dir='ltr'].sm\:ltr\:mb-512 {
    margin-bottom: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:ml-512,[dir='ltr'].sm\:ltr\:ml-512 {
    margin-left: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:mt-640,[dir='ltr'].sm\:ltr\:mt-640 {
    margin-top: 64rem
  }

  [dir='ltr'] .sm\:ltr\:mr-640,[dir='ltr'].sm\:ltr\:mr-640 {
    margin-right: 64rem
  }

  [dir='ltr'] .sm\:ltr\:mb-640,[dir='ltr'].sm\:ltr\:mb-640 {
    margin-bottom: 64rem
  }

  [dir='ltr'] .sm\:ltr\:ml-640,[dir='ltr'].sm\:ltr\:ml-640 {
    margin-left: 64rem
  }

  [dir='ltr'] .sm\:ltr\:mt-auto,[dir='ltr'].sm\:ltr\:mt-auto {
    margin-top: auto
  }

  [dir='ltr'] .sm\:ltr\:mr-auto,[dir='ltr'].sm\:ltr\:mr-auto {
    margin-right: auto
  }

  [dir='ltr'] .sm\:ltr\:mb-auto,[dir='ltr'].sm\:ltr\:mb-auto {
    margin-bottom: auto
  }

  [dir='ltr'] .sm\:ltr\:ml-auto,[dir='ltr'].sm\:ltr\:ml-auto {
    margin-left: auto
  }

  [dir='ltr'] .sm\:ltr\:mt-xs,[dir='ltr'].sm\:ltr\:mt-xs {
    margin-top: 32rem
  }

  [dir='ltr'] .sm\:ltr\:mr-xs,[dir='ltr'].sm\:ltr\:mr-xs {
    margin-right: 32rem
  }

  [dir='ltr'] .sm\:ltr\:mb-xs,[dir='ltr'].sm\:ltr\:mb-xs {
    margin-bottom: 32rem
  }

  [dir='ltr'] .sm\:ltr\:ml-xs,[dir='ltr'].sm\:ltr\:ml-xs {
    margin-left: 32rem
  }

  [dir='ltr'] .sm\:ltr\:mt-sm,[dir='ltr'].sm\:ltr\:mt-sm {
    margin-top: 48rem
  }

  [dir='ltr'] .sm\:ltr\:mr-sm,[dir='ltr'].sm\:ltr\:mr-sm {
    margin-right: 48rem
  }

  [dir='ltr'] .sm\:ltr\:mb-sm,[dir='ltr'].sm\:ltr\:mb-sm {
    margin-bottom: 48rem
  }

  [dir='ltr'] .sm\:ltr\:ml-sm,[dir='ltr'].sm\:ltr\:ml-sm {
    margin-left: 48rem
  }

  [dir='ltr'] .sm\:ltr\:mt-md,[dir='ltr'].sm\:ltr\:mt-md {
    margin-top: 64rem
  }

  [dir='ltr'] .sm\:ltr\:mr-md,[dir='ltr'].sm\:ltr\:mr-md {
    margin-right: 64rem
  }

  [dir='ltr'] .sm\:ltr\:mb-md,[dir='ltr'].sm\:ltr\:mb-md {
    margin-bottom: 64rem
  }

  [dir='ltr'] .sm\:ltr\:ml-md,[dir='ltr'].sm\:ltr\:ml-md {
    margin-left: 64rem
  }

  [dir='ltr'] .sm\:ltr\:mt-lg,[dir='ltr'].sm\:ltr\:mt-lg {
    margin-top: 80rem
  }

  [dir='ltr'] .sm\:ltr\:mr-lg,[dir='ltr'].sm\:ltr\:mr-lg {
    margin-right: 80rem
  }

  [dir='ltr'] .sm\:ltr\:mb-lg,[dir='ltr'].sm\:ltr\:mb-lg {
    margin-bottom: 80rem
  }

  [dir='ltr'] .sm\:ltr\:ml-lg,[dir='ltr'].sm\:ltr\:ml-lg {
    margin-left: 80rem
  }

  [dir='ltr'] .sm\:ltr\:mt-xl,[dir='ltr'].sm\:ltr\:mt-xl {
    margin-top: 96rem
  }

  [dir='ltr'] .sm\:ltr\:mr-xl,[dir='ltr'].sm\:ltr\:mr-xl {
    margin-right: 96rem
  }

  [dir='ltr'] .sm\:ltr\:mb-xl,[dir='ltr'].sm\:ltr\:mb-xl {
    margin-bottom: 96rem
  }

  [dir='ltr'] .sm\:ltr\:ml-xl,[dir='ltr'].sm\:ltr\:ml-xl {
    margin-left: 96rem
  }

  [dir='ltr'] .sm\:ltr\:mt-2xl,[dir='ltr'].sm\:ltr\:mt-2xl {
    margin-top: 112rem
  }

  [dir='ltr'] .sm\:ltr\:mr-2xl,[dir='ltr'].sm\:ltr\:mr-2xl {
    margin-right: 112rem
  }

  [dir='ltr'] .sm\:ltr\:mb-2xl,[dir='ltr'].sm\:ltr\:mb-2xl {
    margin-bottom: 112rem
  }

  [dir='ltr'] .sm\:ltr\:ml-2xl,[dir='ltr'].sm\:ltr\:ml-2xl {
    margin-left: 112rem
  }

  [dir='ltr'] .sm\:ltr\:mt-3xl,[dir='ltr'].sm\:ltr\:mt-3xl {
    margin-top: 128rem
  }

  [dir='ltr'] .sm\:ltr\:mr-3xl,[dir='ltr'].sm\:ltr\:mr-3xl {
    margin-right: 128rem
  }

  [dir='ltr'] .sm\:ltr\:mb-3xl,[dir='ltr'].sm\:ltr\:mb-3xl {
    margin-bottom: 128rem
  }

  [dir='ltr'] .sm\:ltr\:ml-3xl,[dir='ltr'].sm\:ltr\:ml-3xl {
    margin-left: 128rem
  }

  [dir='ltr'] .sm\:ltr\:mt-4xl,[dir='ltr'].sm\:ltr\:mt-4xl {
    margin-top: 144rem
  }

  [dir='ltr'] .sm\:ltr\:mr-4xl,[dir='ltr'].sm\:ltr\:mr-4xl {
    margin-right: 144rem
  }

  [dir='ltr'] .sm\:ltr\:mb-4xl,[dir='ltr'].sm\:ltr\:mb-4xl {
    margin-bottom: 144rem
  }

  [dir='ltr'] .sm\:ltr\:ml-4xl,[dir='ltr'].sm\:ltr\:ml-4xl {
    margin-left: 144rem
  }

  [dir='ltr'] .sm\:ltr\:mt-5xl,[dir='ltr'].sm\:ltr\:mt-5xl {
    margin-top: 160rem
  }

  [dir='ltr'] .sm\:ltr\:mr-5xl,[dir='ltr'].sm\:ltr\:mr-5xl {
    margin-right: 160rem
  }

  [dir='ltr'] .sm\:ltr\:mb-5xl,[dir='ltr'].sm\:ltr\:mb-5xl {
    margin-bottom: 160rem
  }

  [dir='ltr'] .sm\:ltr\:ml-5xl,[dir='ltr'].sm\:ltr\:ml-5xl {
    margin-left: 160rem
  }

  [dir='ltr'] .sm\:ltr\:mt-px,[dir='ltr'].sm\:ltr\:mt-px {
    margin-top: 1px
  }

  [dir='ltr'] .sm\:ltr\:mr-px,[dir='ltr'].sm\:ltr\:mr-px {
    margin-right: 1px
  }

  [dir='ltr'] .sm\:ltr\:mb-px,[dir='ltr'].sm\:ltr\:mb-px {
    margin-bottom: 1px
  }

  [dir='ltr'] .sm\:ltr\:ml-px,[dir='ltr'].sm\:ltr\:ml-px {
    margin-left: 1px
  }

  [dir='ltr'] .sm\:ltr\:mt-0\.5,[dir='ltr'].sm\:ltr\:mt-0\.5 {
    margin-top: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:mr-0\.5,[dir='ltr'].sm\:ltr\:mr-0\.5 {
    margin-right: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:mb-0\.5,[dir='ltr'].sm\:ltr\:mb-0\.5 {
    margin-bottom: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:ml-0\.5,[dir='ltr'].sm\:ltr\:ml-0\.5 {
    margin-left: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:mt-1\.5,[dir='ltr'].sm\:ltr\:mt-1\.5 {
    margin-top: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:mr-1\.5,[dir='ltr'].sm\:ltr\:mr-1\.5 {
    margin-right: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:mb-1\.5,[dir='ltr'].sm\:ltr\:mb-1\.5 {
    margin-bottom: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:ml-1\.5,[dir='ltr'].sm\:ltr\:ml-1\.5 {
    margin-left: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:mt-2\.5,[dir='ltr'].sm\:ltr\:mt-2\.5 {
    margin-top: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:mr-2\.5,[dir='ltr'].sm\:ltr\:mr-2\.5 {
    margin-right: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:mb-2\.5,[dir='ltr'].sm\:ltr\:mb-2\.5 {
    margin-bottom: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:ml-2\.5,[dir='ltr'].sm\:ltr\:ml-2\.5 {
    margin-left: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:mt-3\.5,[dir='ltr'].sm\:ltr\:mt-3\.5 {
    margin-top: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:mr-3\.5,[dir='ltr'].sm\:ltr\:mr-3\.5 {
    margin-right: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:mb-3\.5,[dir='ltr'].sm\:ltr\:mb-3\.5 {
    margin-bottom: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:ml-3\.5,[dir='ltr'].sm\:ltr\:ml-3\.5 {
    margin-left: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-1,[dir='ltr'].sm\:ltr\:-mt-1 {
    margin-top: -0.1rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-1,[dir='ltr'].sm\:ltr\:-mr-1 {
    margin-right: -0.1rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-1,[dir='ltr'].sm\:ltr\:-mb-1 {
    margin-bottom: -0.1rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-1,[dir='ltr'].sm\:ltr\:-ml-1 {
    margin-left: -0.1rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-2,[dir='ltr'].sm\:ltr\:-mt-2 {
    margin-top: -0.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-2,[dir='ltr'].sm\:ltr\:-mr-2 {
    margin-right: -0.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-2,[dir='ltr'].sm\:ltr\:-mb-2 {
    margin-bottom: -0.2rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-2,[dir='ltr'].sm\:ltr\:-ml-2 {
    margin-left: -0.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-3,[dir='ltr'].sm\:ltr\:-mt-3 {
    margin-top: -0.3rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-3,[dir='ltr'].sm\:ltr\:-mr-3 {
    margin-right: -0.3rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-3,[dir='ltr'].sm\:ltr\:-mb-3 {
    margin-bottom: -0.3rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-3,[dir='ltr'].sm\:ltr\:-ml-3 {
    margin-left: -0.3rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-4,[dir='ltr'].sm\:ltr\:-mt-4 {
    margin-top: -0.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-4,[dir='ltr'].sm\:ltr\:-mr-4 {
    margin-right: -0.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-4,[dir='ltr'].sm\:ltr\:-mb-4 {
    margin-bottom: -0.4rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-4,[dir='ltr'].sm\:ltr\:-ml-4 {
    margin-left: -0.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-5,[dir='ltr'].sm\:ltr\:-mt-5 {
    margin-top: -0.5rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-5,[dir='ltr'].sm\:ltr\:-mr-5 {
    margin-right: -0.5rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-5,[dir='ltr'].sm\:ltr\:-mb-5 {
    margin-bottom: -0.5rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-5,[dir='ltr'].sm\:ltr\:-ml-5 {
    margin-left: -0.5rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-6,[dir='ltr'].sm\:ltr\:-mt-6 {
    margin-top: -0.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-6,[dir='ltr'].sm\:ltr\:-mr-6 {
    margin-right: -0.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-6,[dir='ltr'].sm\:ltr\:-mb-6 {
    margin-bottom: -0.6rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-6,[dir='ltr'].sm\:ltr\:-ml-6 {
    margin-left: -0.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-7,[dir='ltr'].sm\:ltr\:-mt-7 {
    margin-top: -0.7rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-7,[dir='ltr'].sm\:ltr\:-mr-7 {
    margin-right: -0.7rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-7,[dir='ltr'].sm\:ltr\:-mb-7 {
    margin-bottom: -0.7rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-7,[dir='ltr'].sm\:ltr\:-ml-7 {
    margin-left: -0.7rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-8,[dir='ltr'].sm\:ltr\:-mt-8 {
    margin-top: -0.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-8,[dir='ltr'].sm\:ltr\:-mr-8 {
    margin-right: -0.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-8,[dir='ltr'].sm\:ltr\:-mb-8 {
    margin-bottom: -0.8rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-8,[dir='ltr'].sm\:ltr\:-ml-8 {
    margin-left: -0.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-9,[dir='ltr'].sm\:ltr\:-mt-9 {
    margin-top: -0.9rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-9,[dir='ltr'].sm\:ltr\:-mr-9 {
    margin-right: -0.9rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-9,[dir='ltr'].sm\:ltr\:-mb-9 {
    margin-bottom: -0.9rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-9,[dir='ltr'].sm\:ltr\:-ml-9 {
    margin-left: -0.9rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-10,[dir='ltr'].sm\:ltr\:-mt-10 {
    margin-top: -1rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-10,[dir='ltr'].sm\:ltr\:-mr-10 {
    margin-right: -1rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-10,[dir='ltr'].sm\:ltr\:-mb-10 {
    margin-bottom: -1rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-10,[dir='ltr'].sm\:ltr\:-ml-10 {
    margin-left: -1rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-12,[dir='ltr'].sm\:ltr\:-mt-12 {
    margin-top: -1.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-12,[dir='ltr'].sm\:ltr\:-mr-12 {
    margin-right: -1.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-12,[dir='ltr'].sm\:ltr\:-mb-12 {
    margin-bottom: -1.2rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-12,[dir='ltr'].sm\:ltr\:-ml-12 {
    margin-left: -1.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-14,[dir='ltr'].sm\:ltr\:-mt-14 {
    margin-top: -1.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-14,[dir='ltr'].sm\:ltr\:-mr-14 {
    margin-right: -1.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-14,[dir='ltr'].sm\:ltr\:-mb-14 {
    margin-bottom: -1.4rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-14,[dir='ltr'].sm\:ltr\:-ml-14 {
    margin-left: -1.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-16,[dir='ltr'].sm\:ltr\:-mt-16 {
    margin-top: -1.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-16,[dir='ltr'].sm\:ltr\:-mr-16 {
    margin-right: -1.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-16,[dir='ltr'].sm\:ltr\:-mb-16 {
    margin-bottom: -1.6rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-16,[dir='ltr'].sm\:ltr\:-ml-16 {
    margin-left: -1.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-20,[dir='ltr'].sm\:ltr\:-mt-20 {
    margin-top: -2rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-20,[dir='ltr'].sm\:ltr\:-mr-20 {
    margin-right: -2rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-20,[dir='ltr'].sm\:ltr\:-mb-20 {
    margin-bottom: -2rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-20,[dir='ltr'].sm\:ltr\:-ml-20 {
    margin-left: -2rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-24,[dir='ltr'].sm\:ltr\:-mt-24 {
    margin-top: -2.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-24,[dir='ltr'].sm\:ltr\:-mr-24 {
    margin-right: -2.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-24,[dir='ltr'].sm\:ltr\:-mb-24 {
    margin-bottom: -2.4rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-24,[dir='ltr'].sm\:ltr\:-ml-24 {
    margin-left: -2.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-28,[dir='ltr'].sm\:ltr\:-mt-28 {
    margin-top: -2.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-28,[dir='ltr'].sm\:ltr\:-mr-28 {
    margin-right: -2.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-28,[dir='ltr'].sm\:ltr\:-mb-28 {
    margin-bottom: -2.8rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-28,[dir='ltr'].sm\:ltr\:-ml-28 {
    margin-left: -2.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-32,[dir='ltr'].sm\:ltr\:-mt-32 {
    margin-top: -3.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-32,[dir='ltr'].sm\:ltr\:-mr-32 {
    margin-right: -3.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-32,[dir='ltr'].sm\:ltr\:-mb-32 {
    margin-bottom: -3.2rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-32,[dir='ltr'].sm\:ltr\:-ml-32 {
    margin-left: -3.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-36,[dir='ltr'].sm\:ltr\:-mt-36 {
    margin-top: -3.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-36,[dir='ltr'].sm\:ltr\:-mr-36 {
    margin-right: -3.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-36,[dir='ltr'].sm\:ltr\:-mb-36 {
    margin-bottom: -3.6rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-36,[dir='ltr'].sm\:ltr\:-ml-36 {
    margin-left: -3.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-40,[dir='ltr'].sm\:ltr\:-mt-40 {
    margin-top: -4rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-40,[dir='ltr'].sm\:ltr\:-mr-40 {
    margin-right: -4rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-40,[dir='ltr'].sm\:ltr\:-mb-40 {
    margin-bottom: -4rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-40,[dir='ltr'].sm\:ltr\:-ml-40 {
    margin-left: -4rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-44,[dir='ltr'].sm\:ltr\:-mt-44 {
    margin-top: -4.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-44,[dir='ltr'].sm\:ltr\:-mr-44 {
    margin-right: -4.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-44,[dir='ltr'].sm\:ltr\:-mb-44 {
    margin-bottom: -4.4rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-44,[dir='ltr'].sm\:ltr\:-ml-44 {
    margin-left: -4.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-48,[dir='ltr'].sm\:ltr\:-mt-48 {
    margin-top: -4.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-48,[dir='ltr'].sm\:ltr\:-mr-48 {
    margin-right: -4.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-48,[dir='ltr'].sm\:ltr\:-mb-48 {
    margin-bottom: -4.8rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-48,[dir='ltr'].sm\:ltr\:-ml-48 {
    margin-left: -4.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-52,[dir='ltr'].sm\:ltr\:-mt-52 {
    margin-top: -5.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-52,[dir='ltr'].sm\:ltr\:-mr-52 {
    margin-right: -5.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-52,[dir='ltr'].sm\:ltr\:-mb-52 {
    margin-bottom: -5.2rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-52,[dir='ltr'].sm\:ltr\:-ml-52 {
    margin-left: -5.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-56,[dir='ltr'].sm\:ltr\:-mt-56 {
    margin-top: -5.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-56,[dir='ltr'].sm\:ltr\:-mr-56 {
    margin-right: -5.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-56,[dir='ltr'].sm\:ltr\:-mb-56 {
    margin-bottom: -5.6rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-56,[dir='ltr'].sm\:ltr\:-ml-56 {
    margin-left: -5.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-60,[dir='ltr'].sm\:ltr\:-mt-60 {
    margin-top: -6rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-60,[dir='ltr'].sm\:ltr\:-mr-60 {
    margin-right: -6rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-60,[dir='ltr'].sm\:ltr\:-mb-60 {
    margin-bottom: -6rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-60,[dir='ltr'].sm\:ltr\:-ml-60 {
    margin-left: -6rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-64,[dir='ltr'].sm\:ltr\:-mt-64 {
    margin-top: -6.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-64,[dir='ltr'].sm\:ltr\:-mr-64 {
    margin-right: -6.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-64,[dir='ltr'].sm\:ltr\:-mb-64 {
    margin-bottom: -6.4rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-64,[dir='ltr'].sm\:ltr\:-ml-64 {
    margin-left: -6.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-68,[dir='ltr'].sm\:ltr\:-mt-68 {
    margin-top: -6.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-68,[dir='ltr'].sm\:ltr\:-mr-68 {
    margin-right: -6.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-68,[dir='ltr'].sm\:ltr\:-mb-68 {
    margin-bottom: -6.8rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-68,[dir='ltr'].sm\:ltr\:-ml-68 {
    margin-left: -6.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-72,[dir='ltr'].sm\:ltr\:-mt-72 {
    margin-top: -7.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-72,[dir='ltr'].sm\:ltr\:-mr-72 {
    margin-right: -7.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-72,[dir='ltr'].sm\:ltr\:-mb-72 {
    margin-bottom: -7.2rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-72,[dir='ltr'].sm\:ltr\:-ml-72 {
    margin-left: -7.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-76,[dir='ltr'].sm\:ltr\:-mt-76 {
    margin-top: -7.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-76,[dir='ltr'].sm\:ltr\:-mr-76 {
    margin-right: -7.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-76,[dir='ltr'].sm\:ltr\:-mb-76 {
    margin-bottom: -7.6rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-76,[dir='ltr'].sm\:ltr\:-ml-76 {
    margin-left: -7.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-80,[dir='ltr'].sm\:ltr\:-mt-80 {
    margin-top: -8rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-80,[dir='ltr'].sm\:ltr\:-mr-80 {
    margin-right: -8rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-80,[dir='ltr'].sm\:ltr\:-mb-80 {
    margin-bottom: -8rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-80,[dir='ltr'].sm\:ltr\:-ml-80 {
    margin-left: -8rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-84,[dir='ltr'].sm\:ltr\:-mt-84 {
    margin-top: -8.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-84,[dir='ltr'].sm\:ltr\:-mr-84 {
    margin-right: -8.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-84,[dir='ltr'].sm\:ltr\:-mb-84 {
    margin-bottom: -8.4rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-84,[dir='ltr'].sm\:ltr\:-ml-84 {
    margin-left: -8.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-88,[dir='ltr'].sm\:ltr\:-mt-88 {
    margin-top: -8.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-88,[dir='ltr'].sm\:ltr\:-mr-88 {
    margin-right: -8.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-88,[dir='ltr'].sm\:ltr\:-mb-88 {
    margin-bottom: -8.8rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-88,[dir='ltr'].sm\:ltr\:-ml-88 {
    margin-left: -8.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-92,[dir='ltr'].sm\:ltr\:-mt-92 {
    margin-top: -9.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-92,[dir='ltr'].sm\:ltr\:-mr-92 {
    margin-right: -9.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-92,[dir='ltr'].sm\:ltr\:-mb-92 {
    margin-bottom: -9.2rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-92,[dir='ltr'].sm\:ltr\:-ml-92 {
    margin-left: -9.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-96,[dir='ltr'].sm\:ltr\:-mt-96 {
    margin-top: -9.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-96,[dir='ltr'].sm\:ltr\:-mr-96 {
    margin-right: -9.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-96,[dir='ltr'].sm\:ltr\:-mb-96 {
    margin-bottom: -9.6rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-96,[dir='ltr'].sm\:ltr\:-ml-96 {
    margin-left: -9.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-128,[dir='ltr'].sm\:ltr\:-mt-128 {
    margin-top: -12.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-128,[dir='ltr'].sm\:ltr\:-mr-128 {
    margin-right: -12.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-128,[dir='ltr'].sm\:ltr\:-mb-128 {
    margin-bottom: -12.8rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-128,[dir='ltr'].sm\:ltr\:-ml-128 {
    margin-left: -12.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-136,[dir='ltr'].sm\:ltr\:-mt-136 {
    margin-top: -13.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-136,[dir='ltr'].sm\:ltr\:-mr-136 {
    margin-right: -13.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-136,[dir='ltr'].sm\:ltr\:-mb-136 {
    margin-bottom: -13.6rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-136,[dir='ltr'].sm\:ltr\:-ml-136 {
    margin-left: -13.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-160,[dir='ltr'].sm\:ltr\:-mt-160 {
    margin-top: -16rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-160,[dir='ltr'].sm\:ltr\:-mr-160 {
    margin-right: -16rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-160,[dir='ltr'].sm\:ltr\:-mb-160 {
    margin-bottom: -16rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-160,[dir='ltr'].sm\:ltr\:-ml-160 {
    margin-left: -16rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-192,[dir='ltr'].sm\:ltr\:-mt-192 {
    margin-top: -19.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-192,[dir='ltr'].sm\:ltr\:-mr-192 {
    margin-right: -19.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-192,[dir='ltr'].sm\:ltr\:-mb-192 {
    margin-bottom: -19.2rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-192,[dir='ltr'].sm\:ltr\:-ml-192 {
    margin-left: -19.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-200,[dir='ltr'].sm\:ltr\:-mt-200 {
    margin-top: -20rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-200,[dir='ltr'].sm\:ltr\:-mr-200 {
    margin-right: -20rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-200,[dir='ltr'].sm\:ltr\:-mb-200 {
    margin-bottom: -20rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-200,[dir='ltr'].sm\:ltr\:-ml-200 {
    margin-left: -20rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-208,[dir='ltr'].sm\:ltr\:-mt-208 {
    margin-top: -20.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-208,[dir='ltr'].sm\:ltr\:-mr-208 {
    margin-right: -20.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-208,[dir='ltr'].sm\:ltr\:-mb-208 {
    margin-bottom: -20.8rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-208,[dir='ltr'].sm\:ltr\:-ml-208 {
    margin-left: -20.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-216,[dir='ltr'].sm\:ltr\:-mt-216 {
    margin-top: -21.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-216,[dir='ltr'].sm\:ltr\:-mr-216 {
    margin-right: -21.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-216,[dir='ltr'].sm\:ltr\:-mb-216 {
    margin-bottom: -21.6rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-216,[dir='ltr'].sm\:ltr\:-ml-216 {
    margin-left: -21.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-224,[dir='ltr'].sm\:ltr\:-mt-224 {
    margin-top: -22.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-224,[dir='ltr'].sm\:ltr\:-mr-224 {
    margin-right: -22.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-224,[dir='ltr'].sm\:ltr\:-mb-224 {
    margin-bottom: -22.4rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-224,[dir='ltr'].sm\:ltr\:-ml-224 {
    margin-left: -22.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-256,[dir='ltr'].sm\:ltr\:-mt-256 {
    margin-top: -25.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-256,[dir='ltr'].sm\:ltr\:-mr-256 {
    margin-right: -25.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-256,[dir='ltr'].sm\:ltr\:-mb-256 {
    margin-bottom: -25.6rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-256,[dir='ltr'].sm\:ltr\:-ml-256 {
    margin-left: -25.6rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-288,[dir='ltr'].sm\:ltr\:-mt-288 {
    margin-top: -28.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-288,[dir='ltr'].sm\:ltr\:-mr-288 {
    margin-right: -28.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-288,[dir='ltr'].sm\:ltr\:-mb-288 {
    margin-bottom: -28.8rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-288,[dir='ltr'].sm\:ltr\:-ml-288 {
    margin-left: -28.8rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-320,[dir='ltr'].sm\:ltr\:-mt-320 {
    margin-top: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-320,[dir='ltr'].sm\:ltr\:-mr-320 {
    margin-right: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-320,[dir='ltr'].sm\:ltr\:-mb-320 {
    margin-bottom: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-320,[dir='ltr'].sm\:ltr\:-ml-320 {
    margin-left: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-360,[dir='ltr'].sm\:ltr\:-mt-360 {
    margin-top: -36rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-360,[dir='ltr'].sm\:ltr\:-mr-360 {
    margin-right: -36rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-360,[dir='ltr'].sm\:ltr\:-mb-360 {
    margin-bottom: -36rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-360,[dir='ltr'].sm\:ltr\:-ml-360 {
    margin-left: -36rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-384,[dir='ltr'].sm\:ltr\:-mt-384 {
    margin-top: -38.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-384,[dir='ltr'].sm\:ltr\:-mr-384 {
    margin-right: -38.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-384,[dir='ltr'].sm\:ltr\:-mb-384 {
    margin-bottom: -38.4rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-384,[dir='ltr'].sm\:ltr\:-ml-384 {
    margin-left: -38.4rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-400,[dir='ltr'].sm\:ltr\:-mt-400 {
    margin-top: -40rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-400,[dir='ltr'].sm\:ltr\:-mr-400 {
    margin-right: -40rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-400,[dir='ltr'].sm\:ltr\:-mb-400 {
    margin-bottom: -40rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-400,[dir='ltr'].sm\:ltr\:-ml-400 {
    margin-left: -40rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-512,[dir='ltr'].sm\:ltr\:-mt-512 {
    margin-top: -51.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-512,[dir='ltr'].sm\:ltr\:-mr-512 {
    margin-right: -51.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-512,[dir='ltr'].sm\:ltr\:-mb-512 {
    margin-bottom: -51.2rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-512,[dir='ltr'].sm\:ltr\:-ml-512 {
    margin-left: -51.2rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-640,[dir='ltr'].sm\:ltr\:-mt-640 {
    margin-top: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-640,[dir='ltr'].sm\:ltr\:-mr-640 {
    margin-right: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-640,[dir='ltr'].sm\:ltr\:-mb-640 {
    margin-bottom: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-640,[dir='ltr'].sm\:ltr\:-ml-640 {
    margin-left: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-xs,[dir='ltr'].sm\:ltr\:-mt-xs {
    margin-top: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-xs,[dir='ltr'].sm\:ltr\:-mr-xs {
    margin-right: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-xs,[dir='ltr'].sm\:ltr\:-mb-xs {
    margin-bottom: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-xs,[dir='ltr'].sm\:ltr\:-ml-xs {
    margin-left: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-sm,[dir='ltr'].sm\:ltr\:-mt-sm {
    margin-top: -48rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-sm,[dir='ltr'].sm\:ltr\:-mr-sm {
    margin-right: -48rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-sm,[dir='ltr'].sm\:ltr\:-mb-sm {
    margin-bottom: -48rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-sm,[dir='ltr'].sm\:ltr\:-ml-sm {
    margin-left: -48rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-md,[dir='ltr'].sm\:ltr\:-mt-md {
    margin-top: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-md,[dir='ltr'].sm\:ltr\:-mr-md {
    margin-right: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-md,[dir='ltr'].sm\:ltr\:-mb-md {
    margin-bottom: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-md,[dir='ltr'].sm\:ltr\:-ml-md {
    margin-left: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-lg,[dir='ltr'].sm\:ltr\:-mt-lg {
    margin-top: -80rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-lg,[dir='ltr'].sm\:ltr\:-mr-lg {
    margin-right: -80rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-lg,[dir='ltr'].sm\:ltr\:-mb-lg {
    margin-bottom: -80rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-lg,[dir='ltr'].sm\:ltr\:-ml-lg {
    margin-left: -80rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-xl,[dir='ltr'].sm\:ltr\:-mt-xl {
    margin-top: -96rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-xl,[dir='ltr'].sm\:ltr\:-mr-xl {
    margin-right: -96rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-xl,[dir='ltr'].sm\:ltr\:-mb-xl {
    margin-bottom: -96rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-xl,[dir='ltr'].sm\:ltr\:-ml-xl {
    margin-left: -96rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-2xl,[dir='ltr'].sm\:ltr\:-mt-2xl {
    margin-top: -112rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-2xl,[dir='ltr'].sm\:ltr\:-mr-2xl {
    margin-right: -112rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-2xl,[dir='ltr'].sm\:ltr\:-mb-2xl {
    margin-bottom: -112rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-2xl,[dir='ltr'].sm\:ltr\:-ml-2xl {
    margin-left: -112rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-3xl,[dir='ltr'].sm\:ltr\:-mt-3xl {
    margin-top: -128rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-3xl,[dir='ltr'].sm\:ltr\:-mr-3xl {
    margin-right: -128rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-3xl,[dir='ltr'].sm\:ltr\:-mb-3xl {
    margin-bottom: -128rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-3xl,[dir='ltr'].sm\:ltr\:-ml-3xl {
    margin-left: -128rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-4xl,[dir='ltr'].sm\:ltr\:-mt-4xl {
    margin-top: -144rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-4xl,[dir='ltr'].sm\:ltr\:-mr-4xl {
    margin-right: -144rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-4xl,[dir='ltr'].sm\:ltr\:-mb-4xl {
    margin-bottom: -144rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-4xl,[dir='ltr'].sm\:ltr\:-ml-4xl {
    margin-left: -144rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-5xl,[dir='ltr'].sm\:ltr\:-mt-5xl {
    margin-top: -160rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-5xl,[dir='ltr'].sm\:ltr\:-mr-5xl {
    margin-right: -160rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-5xl,[dir='ltr'].sm\:ltr\:-mb-5xl {
    margin-bottom: -160rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-5xl,[dir='ltr'].sm\:ltr\:-ml-5xl {
    margin-left: -160rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-px,[dir='ltr'].sm\:ltr\:-mt-px {
    margin-top: -1px
  }

  [dir='ltr'] .sm\:ltr\:-mr-px,[dir='ltr'].sm\:ltr\:-mr-px {
    margin-right: -1px
  }

  [dir='ltr'] .sm\:ltr\:-mb-px,[dir='ltr'].sm\:ltr\:-mb-px {
    margin-bottom: -1px
  }

  [dir='ltr'] .sm\:ltr\:-ml-px,[dir='ltr'].sm\:ltr\:-ml-px {
    margin-left: -1px
  }

  [dir='ltr'] .sm\:ltr\:-mt-0\.5,[dir='ltr'].sm\:ltr\:-mt-0\.5 {
    margin-top: -0.05rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-0\.5,[dir='ltr'].sm\:ltr\:-mr-0\.5 {
    margin-right: -0.05rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-0\.5,[dir='ltr'].sm\:ltr\:-mb-0\.5 {
    margin-bottom: -0.05rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-0\.5,[dir='ltr'].sm\:ltr\:-ml-0\.5 {
    margin-left: -0.05rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-1\.5,[dir='ltr'].sm\:ltr\:-mt-1\.5 {
    margin-top: -0.15rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-1\.5,[dir='ltr'].sm\:ltr\:-mr-1\.5 {
    margin-right: -0.15rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-1\.5,[dir='ltr'].sm\:ltr\:-mb-1\.5 {
    margin-bottom: -0.15rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-1\.5,[dir='ltr'].sm\:ltr\:-ml-1\.5 {
    margin-left: -0.15rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-2\.5,[dir='ltr'].sm\:ltr\:-mt-2\.5 {
    margin-top: -0.25rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-2\.5,[dir='ltr'].sm\:ltr\:-mr-2\.5 {
    margin-right: -0.25rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-2\.5,[dir='ltr'].sm\:ltr\:-mb-2\.5 {
    margin-bottom: -0.25rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-2\.5,[dir='ltr'].sm\:ltr\:-ml-2\.5 {
    margin-left: -0.25rem
  }

  [dir='ltr'] .sm\:ltr\:-mt-3\.5,[dir='ltr'].sm\:ltr\:-mt-3\.5 {
    margin-top: -0.35rem
  }

  [dir='ltr'] .sm\:ltr\:-mr-3\.5,[dir='ltr'].sm\:ltr\:-mr-3\.5 {
    margin-right: -0.35rem
  }

  [dir='ltr'] .sm\:ltr\:-mb-3\.5,[dir='ltr'].sm\:ltr\:-mb-3\.5 {
    margin-bottom: -0.35rem
  }

  [dir='ltr'] .sm\:ltr\:-ml-3\.5,[dir='ltr'].sm\:ltr\:-ml-3\.5 {
    margin-left: -0.35rem
  }

  [dir='rtl'] .sm\:rtl\:m-0,[dir='rtl'].sm\:rtl\:m-0 {
    margin: 0
  }

  [dir='rtl'] .sm\:rtl\:m-1,[dir='rtl'].sm\:rtl\:m-1 {
    margin: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:m-2,[dir='rtl'].sm\:rtl\:m-2 {
    margin: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:m-3,[dir='rtl'].sm\:rtl\:m-3 {
    margin: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:m-4,[dir='rtl'].sm\:rtl\:m-4 {
    margin: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:m-5,[dir='rtl'].sm\:rtl\:m-5 {
    margin: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:m-6,[dir='rtl'].sm\:rtl\:m-6 {
    margin: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:m-7,[dir='rtl'].sm\:rtl\:m-7 {
    margin: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:m-8,[dir='rtl'].sm\:rtl\:m-8 {
    margin: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:m-9,[dir='rtl'].sm\:rtl\:m-9 {
    margin: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:m-10,[dir='rtl'].sm\:rtl\:m-10 {
    margin: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:m-12,[dir='rtl'].sm\:rtl\:m-12 {
    margin: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:m-14,[dir='rtl'].sm\:rtl\:m-14 {
    margin: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:m-16,[dir='rtl'].sm\:rtl\:m-16 {
    margin: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:m-20,[dir='rtl'].sm\:rtl\:m-20 {
    margin: 2rem
  }

  [dir='rtl'] .sm\:rtl\:m-24,[dir='rtl'].sm\:rtl\:m-24 {
    margin: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:m-28,[dir='rtl'].sm\:rtl\:m-28 {
    margin: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:m-32,[dir='rtl'].sm\:rtl\:m-32 {
    margin: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:m-36,[dir='rtl'].sm\:rtl\:m-36 {
    margin: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:m-40,[dir='rtl'].sm\:rtl\:m-40 {
    margin: 4rem
  }

  [dir='rtl'] .sm\:rtl\:m-44,[dir='rtl'].sm\:rtl\:m-44 {
    margin: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:m-48,[dir='rtl'].sm\:rtl\:m-48 {
    margin: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:m-52,[dir='rtl'].sm\:rtl\:m-52 {
    margin: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:m-56,[dir='rtl'].sm\:rtl\:m-56 {
    margin: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:m-60,[dir='rtl'].sm\:rtl\:m-60 {
    margin: 6rem
  }

  [dir='rtl'] .sm\:rtl\:m-64,[dir='rtl'].sm\:rtl\:m-64 {
    margin: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:m-68,[dir='rtl'].sm\:rtl\:m-68 {
    margin: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:m-72,[dir='rtl'].sm\:rtl\:m-72 {
    margin: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:m-76,[dir='rtl'].sm\:rtl\:m-76 {
    margin: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:m-80,[dir='rtl'].sm\:rtl\:m-80 {
    margin: 8rem
  }

  [dir='rtl'] .sm\:rtl\:m-84,[dir='rtl'].sm\:rtl\:m-84 {
    margin: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:m-88,[dir='rtl'].sm\:rtl\:m-88 {
    margin: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:m-92,[dir='rtl'].sm\:rtl\:m-92 {
    margin: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:m-96,[dir='rtl'].sm\:rtl\:m-96 {
    margin: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:m-128,[dir='rtl'].sm\:rtl\:m-128 {
    margin: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:m-136,[dir='rtl'].sm\:rtl\:m-136 {
    margin: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:m-160,[dir='rtl'].sm\:rtl\:m-160 {
    margin: 16rem
  }

  [dir='rtl'] .sm\:rtl\:m-192,[dir='rtl'].sm\:rtl\:m-192 {
    margin: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:m-200,[dir='rtl'].sm\:rtl\:m-200 {
    margin: 20rem
  }

  [dir='rtl'] .sm\:rtl\:m-208,[dir='rtl'].sm\:rtl\:m-208 {
    margin: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:m-216,[dir='rtl'].sm\:rtl\:m-216 {
    margin: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:m-224,[dir='rtl'].sm\:rtl\:m-224 {
    margin: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:m-256,[dir='rtl'].sm\:rtl\:m-256 {
    margin: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:m-288,[dir='rtl'].sm\:rtl\:m-288 {
    margin: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:m-320,[dir='rtl'].sm\:rtl\:m-320 {
    margin: 32rem
  }

  [dir='rtl'] .sm\:rtl\:m-360,[dir='rtl'].sm\:rtl\:m-360 {
    margin: 36rem
  }

  [dir='rtl'] .sm\:rtl\:m-384,[dir='rtl'].sm\:rtl\:m-384 {
    margin: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:m-400,[dir='rtl'].sm\:rtl\:m-400 {
    margin: 40rem
  }

  [dir='rtl'] .sm\:rtl\:m-512,[dir='rtl'].sm\:rtl\:m-512 {
    margin: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:m-640,[dir='rtl'].sm\:rtl\:m-640 {
    margin: 64rem
  }

  [dir='rtl'] .sm\:rtl\:m-auto,[dir='rtl'].sm\:rtl\:m-auto {
    margin: auto
  }

  [dir='rtl'] .sm\:rtl\:m-xs,[dir='rtl'].sm\:rtl\:m-xs {
    margin: 32rem
  }

  [dir='rtl'] .sm\:rtl\:m-sm,[dir='rtl'].sm\:rtl\:m-sm {
    margin: 48rem
  }

  [dir='rtl'] .sm\:rtl\:m-md,[dir='rtl'].sm\:rtl\:m-md {
    margin: 64rem
  }

  [dir='rtl'] .sm\:rtl\:m-lg,[dir='rtl'].sm\:rtl\:m-lg {
    margin: 80rem
  }

  [dir='rtl'] .sm\:rtl\:m-xl,[dir='rtl'].sm\:rtl\:m-xl {
    margin: 96rem
  }

  [dir='rtl'] .sm\:rtl\:m-2xl,[dir='rtl'].sm\:rtl\:m-2xl {
    margin: 112rem
  }

  [dir='rtl'] .sm\:rtl\:m-3xl,[dir='rtl'].sm\:rtl\:m-3xl {
    margin: 128rem
  }

  [dir='rtl'] .sm\:rtl\:m-4xl,[dir='rtl'].sm\:rtl\:m-4xl {
    margin: 144rem
  }

  [dir='rtl'] .sm\:rtl\:m-5xl,[dir='rtl'].sm\:rtl\:m-5xl {
    margin: 160rem
  }

  [dir='rtl'] .sm\:rtl\:m-px,[dir='rtl'].sm\:rtl\:m-px {
    margin: 1px
  }

  [dir='rtl'] .sm\:rtl\:m-0\.5,[dir='rtl'].sm\:rtl\:m-0\.5 {
    margin: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:m-1\.5,[dir='rtl'].sm\:rtl\:m-1\.5 {
    margin: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:m-2\.5,[dir='rtl'].sm\:rtl\:m-2\.5 {
    margin: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:m-3\.5,[dir='rtl'].sm\:rtl\:m-3\.5 {
    margin: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:-m-1,[dir='rtl'].sm\:rtl\:-m-1 {
    margin: -0.1rem
  }

  [dir='rtl'] .sm\:rtl\:-m-2,[dir='rtl'].sm\:rtl\:-m-2 {
    margin: -0.2rem
  }

  [dir='rtl'] .sm\:rtl\:-m-3,[dir='rtl'].sm\:rtl\:-m-3 {
    margin: -0.3rem
  }

  [dir='rtl'] .sm\:rtl\:-m-4,[dir='rtl'].sm\:rtl\:-m-4 {
    margin: -0.4rem
  }

  [dir='rtl'] .sm\:rtl\:-m-5,[dir='rtl'].sm\:rtl\:-m-5 {
    margin: -0.5rem
  }

  [dir='rtl'] .sm\:rtl\:-m-6,[dir='rtl'].sm\:rtl\:-m-6 {
    margin: -0.6rem
  }

  [dir='rtl'] .sm\:rtl\:-m-7,[dir='rtl'].sm\:rtl\:-m-7 {
    margin: -0.7rem
  }

  [dir='rtl'] .sm\:rtl\:-m-8,[dir='rtl'].sm\:rtl\:-m-8 {
    margin: -0.8rem
  }

  [dir='rtl'] .sm\:rtl\:-m-9,[dir='rtl'].sm\:rtl\:-m-9 {
    margin: -0.9rem
  }

  [dir='rtl'] .sm\:rtl\:-m-10,[dir='rtl'].sm\:rtl\:-m-10 {
    margin: -1rem
  }

  [dir='rtl'] .sm\:rtl\:-m-12,[dir='rtl'].sm\:rtl\:-m-12 {
    margin: -1.2rem
  }

  [dir='rtl'] .sm\:rtl\:-m-14,[dir='rtl'].sm\:rtl\:-m-14 {
    margin: -1.4rem
  }

  [dir='rtl'] .sm\:rtl\:-m-16,[dir='rtl'].sm\:rtl\:-m-16 {
    margin: -1.6rem
  }

  [dir='rtl'] .sm\:rtl\:-m-20,[dir='rtl'].sm\:rtl\:-m-20 {
    margin: -2rem
  }

  [dir='rtl'] .sm\:rtl\:-m-24,[dir='rtl'].sm\:rtl\:-m-24 {
    margin: -2.4rem
  }

  [dir='rtl'] .sm\:rtl\:-m-28,[dir='rtl'].sm\:rtl\:-m-28 {
    margin: -2.8rem
  }

  [dir='rtl'] .sm\:rtl\:-m-32,[dir='rtl'].sm\:rtl\:-m-32 {
    margin: -3.2rem
  }

  [dir='rtl'] .sm\:rtl\:-m-36,[dir='rtl'].sm\:rtl\:-m-36 {
    margin: -3.6rem
  }

  [dir='rtl'] .sm\:rtl\:-m-40,[dir='rtl'].sm\:rtl\:-m-40 {
    margin: -4rem
  }

  [dir='rtl'] .sm\:rtl\:-m-44,[dir='rtl'].sm\:rtl\:-m-44 {
    margin: -4.4rem
  }

  [dir='rtl'] .sm\:rtl\:-m-48,[dir='rtl'].sm\:rtl\:-m-48 {
    margin: -4.8rem
  }

  [dir='rtl'] .sm\:rtl\:-m-52,[dir='rtl'].sm\:rtl\:-m-52 {
    margin: -5.2rem
  }

  [dir='rtl'] .sm\:rtl\:-m-56,[dir='rtl'].sm\:rtl\:-m-56 {
    margin: -5.6rem
  }

  [dir='rtl'] .sm\:rtl\:-m-60,[dir='rtl'].sm\:rtl\:-m-60 {
    margin: -6rem
  }

  [dir='rtl'] .sm\:rtl\:-m-64,[dir='rtl'].sm\:rtl\:-m-64 {
    margin: -6.4rem
  }

  [dir='rtl'] .sm\:rtl\:-m-68,[dir='rtl'].sm\:rtl\:-m-68 {
    margin: -6.8rem
  }

  [dir='rtl'] .sm\:rtl\:-m-72,[dir='rtl'].sm\:rtl\:-m-72 {
    margin: -7.2rem
  }

  [dir='rtl'] .sm\:rtl\:-m-76,[dir='rtl'].sm\:rtl\:-m-76 {
    margin: -7.6rem
  }

  [dir='rtl'] .sm\:rtl\:-m-80,[dir='rtl'].sm\:rtl\:-m-80 {
    margin: -8rem
  }

  [dir='rtl'] .sm\:rtl\:-m-84,[dir='rtl'].sm\:rtl\:-m-84 {
    margin: -8.4rem
  }

  [dir='rtl'] .sm\:rtl\:-m-88,[dir='rtl'].sm\:rtl\:-m-88 {
    margin: -8.8rem
  }

  [dir='rtl'] .sm\:rtl\:-m-92,[dir='rtl'].sm\:rtl\:-m-92 {
    margin: -9.2rem
  }

  [dir='rtl'] .sm\:rtl\:-m-96,[dir='rtl'].sm\:rtl\:-m-96 {
    margin: -9.6rem
  }

  [dir='rtl'] .sm\:rtl\:-m-128,[dir='rtl'].sm\:rtl\:-m-128 {
    margin: -12.8rem
  }

  [dir='rtl'] .sm\:rtl\:-m-136,[dir='rtl'].sm\:rtl\:-m-136 {
    margin: -13.6rem
  }

  [dir='rtl'] .sm\:rtl\:-m-160,[dir='rtl'].sm\:rtl\:-m-160 {
    margin: -16rem
  }

  [dir='rtl'] .sm\:rtl\:-m-192,[dir='rtl'].sm\:rtl\:-m-192 {
    margin: -19.2rem
  }

  [dir='rtl'] .sm\:rtl\:-m-200,[dir='rtl'].sm\:rtl\:-m-200 {
    margin: -20rem
  }

  [dir='rtl'] .sm\:rtl\:-m-208,[dir='rtl'].sm\:rtl\:-m-208 {
    margin: -20.8rem
  }

  [dir='rtl'] .sm\:rtl\:-m-216,[dir='rtl'].sm\:rtl\:-m-216 {
    margin: -21.6rem
  }

  [dir='rtl'] .sm\:rtl\:-m-224,[dir='rtl'].sm\:rtl\:-m-224 {
    margin: -22.4rem
  }

  [dir='rtl'] .sm\:rtl\:-m-256,[dir='rtl'].sm\:rtl\:-m-256 {
    margin: -25.6rem
  }

  [dir='rtl'] .sm\:rtl\:-m-288,[dir='rtl'].sm\:rtl\:-m-288 {
    margin: -28.8rem
  }

  [dir='rtl'] .sm\:rtl\:-m-320,[dir='rtl'].sm\:rtl\:-m-320 {
    margin: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-m-360,[dir='rtl'].sm\:rtl\:-m-360 {
    margin: -36rem
  }

  [dir='rtl'] .sm\:rtl\:-m-384,[dir='rtl'].sm\:rtl\:-m-384 {
    margin: -38.4rem
  }

  [dir='rtl'] .sm\:rtl\:-m-400,[dir='rtl'].sm\:rtl\:-m-400 {
    margin: -40rem
  }

  [dir='rtl'] .sm\:rtl\:-m-512,[dir='rtl'].sm\:rtl\:-m-512 {
    margin: -51.2rem
  }

  [dir='rtl'] .sm\:rtl\:-m-640,[dir='rtl'].sm\:rtl\:-m-640 {
    margin: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-m-xs,[dir='rtl'].sm\:rtl\:-m-xs {
    margin: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-m-sm,[dir='rtl'].sm\:rtl\:-m-sm {
    margin: -48rem
  }

  [dir='rtl'] .sm\:rtl\:-m-md,[dir='rtl'].sm\:rtl\:-m-md {
    margin: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-m-lg,[dir='rtl'].sm\:rtl\:-m-lg {
    margin: -80rem
  }

  [dir='rtl'] .sm\:rtl\:-m-xl,[dir='rtl'].sm\:rtl\:-m-xl {
    margin: -96rem
  }

  [dir='rtl'] .sm\:rtl\:-m-2xl,[dir='rtl'].sm\:rtl\:-m-2xl {
    margin: -112rem
  }

  [dir='rtl'] .sm\:rtl\:-m-3xl,[dir='rtl'].sm\:rtl\:-m-3xl {
    margin: -128rem
  }

  [dir='rtl'] .sm\:rtl\:-m-4xl,[dir='rtl'].sm\:rtl\:-m-4xl {
    margin: -144rem
  }

  [dir='rtl'] .sm\:rtl\:-m-5xl,[dir='rtl'].sm\:rtl\:-m-5xl {
    margin: -160rem
  }

  [dir='rtl'] .sm\:rtl\:-m-px,[dir='rtl'].sm\:rtl\:-m-px {
    margin: -1px
  }

  [dir='rtl'] .sm\:rtl\:-m-0\.5,[dir='rtl'].sm\:rtl\:-m-0\.5 {
    margin: -0.05rem
  }

  [dir='rtl'] .sm\:rtl\:-m-1\.5,[dir='rtl'].sm\:rtl\:-m-1\.5 {
    margin: -0.15rem
  }

  [dir='rtl'] .sm\:rtl\:-m-2\.5,[dir='rtl'].sm\:rtl\:-m-2\.5 {
    margin: -0.25rem
  }

  [dir='rtl'] .sm\:rtl\:-m-3\.5,[dir='rtl'].sm\:rtl\:-m-3\.5 {
    margin: -0.35rem
  }

  [dir='rtl'] .sm\:rtl\:my-0,[dir='rtl'].sm\:rtl\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  [dir='rtl'] .sm\:rtl\:mx-0,[dir='rtl'].sm\:rtl\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  [dir='rtl'] .sm\:rtl\:my-1,[dir='rtl'].sm\:rtl\:my-1 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:mx-1,[dir='rtl'].sm\:rtl\:mx-1 {
    margin-left: 0.1rem;
    margin-right: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:my-2,[dir='rtl'].sm\:rtl\:my-2 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:mx-2,[dir='rtl'].sm\:rtl\:mx-2 {
    margin-left: 0.2rem;
    margin-right: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:my-3,[dir='rtl'].sm\:rtl\:my-3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:mx-3,[dir='rtl'].sm\:rtl\:mx-3 {
    margin-left: 0.3rem;
    margin-right: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:my-4,[dir='rtl'].sm\:rtl\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:mx-4,[dir='rtl'].sm\:rtl\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:my-5,[dir='rtl'].sm\:rtl\:my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:mx-5,[dir='rtl'].sm\:rtl\:mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:my-6,[dir='rtl'].sm\:rtl\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:mx-6,[dir='rtl'].sm\:rtl\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:my-7,[dir='rtl'].sm\:rtl\:my-7 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:mx-7,[dir='rtl'].sm\:rtl\:mx-7 {
    margin-left: 0.7rem;
    margin-right: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:my-8,[dir='rtl'].sm\:rtl\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:mx-8,[dir='rtl'].sm\:rtl\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:my-9,[dir='rtl'].sm\:rtl\:my-9 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:mx-9,[dir='rtl'].sm\:rtl\:mx-9 {
    margin-left: 0.9rem;
    margin-right: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:my-10,[dir='rtl'].sm\:rtl\:my-10 {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:mx-10,[dir='rtl'].sm\:rtl\:mx-10 {
    margin-left: 1.0rem;
    margin-right: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:my-12,[dir='rtl'].sm\:rtl\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:mx-12,[dir='rtl'].sm\:rtl\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:my-14,[dir='rtl'].sm\:rtl\:my-14 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:mx-14,[dir='rtl'].sm\:rtl\:mx-14 {
    margin-left: 1.4rem;
    margin-right: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:my-16,[dir='rtl'].sm\:rtl\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:mx-16,[dir='rtl'].sm\:rtl\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:my-20,[dir='rtl'].sm\:rtl\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  [dir='rtl'] .sm\:rtl\:mx-20,[dir='rtl'].sm\:rtl\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  [dir='rtl'] .sm\:rtl\:my-24,[dir='rtl'].sm\:rtl\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:mx-24,[dir='rtl'].sm\:rtl\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:my-28,[dir='rtl'].sm\:rtl\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:mx-28,[dir='rtl'].sm\:rtl\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:my-32,[dir='rtl'].sm\:rtl\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:mx-32,[dir='rtl'].sm\:rtl\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:my-36,[dir='rtl'].sm\:rtl\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:mx-36,[dir='rtl'].sm\:rtl\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:my-40,[dir='rtl'].sm\:rtl\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  [dir='rtl'] .sm\:rtl\:mx-40,[dir='rtl'].sm\:rtl\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  [dir='rtl'] .sm\:rtl\:my-44,[dir='rtl'].sm\:rtl\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:mx-44,[dir='rtl'].sm\:rtl\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:my-48,[dir='rtl'].sm\:rtl\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:mx-48,[dir='rtl'].sm\:rtl\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:my-52,[dir='rtl'].sm\:rtl\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:mx-52,[dir='rtl'].sm\:rtl\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:my-56,[dir='rtl'].sm\:rtl\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:mx-56,[dir='rtl'].sm\:rtl\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:my-60,[dir='rtl'].sm\:rtl\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  [dir='rtl'] .sm\:rtl\:mx-60,[dir='rtl'].sm\:rtl\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  [dir='rtl'] .sm\:rtl\:my-64,[dir='rtl'].sm\:rtl\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:mx-64,[dir='rtl'].sm\:rtl\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:my-68,[dir='rtl'].sm\:rtl\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:mx-68,[dir='rtl'].sm\:rtl\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:my-72,[dir='rtl'].sm\:rtl\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:mx-72,[dir='rtl'].sm\:rtl\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:my-76,[dir='rtl'].sm\:rtl\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:mx-76,[dir='rtl'].sm\:rtl\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:my-80,[dir='rtl'].sm\:rtl\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  [dir='rtl'] .sm\:rtl\:mx-80,[dir='rtl'].sm\:rtl\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  [dir='rtl'] .sm\:rtl\:my-84,[dir='rtl'].sm\:rtl\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:mx-84,[dir='rtl'].sm\:rtl\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:my-88,[dir='rtl'].sm\:rtl\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:mx-88,[dir='rtl'].sm\:rtl\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:my-92,[dir='rtl'].sm\:rtl\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:mx-92,[dir='rtl'].sm\:rtl\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:my-96,[dir='rtl'].sm\:rtl\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:mx-96,[dir='rtl'].sm\:rtl\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:my-128,[dir='rtl'].sm\:rtl\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:mx-128,[dir='rtl'].sm\:rtl\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:my-136,[dir='rtl'].sm\:rtl\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:mx-136,[dir='rtl'].sm\:rtl\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:my-160,[dir='rtl'].sm\:rtl\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  [dir='rtl'] .sm\:rtl\:mx-160,[dir='rtl'].sm\:rtl\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  [dir='rtl'] .sm\:rtl\:my-192,[dir='rtl'].sm\:rtl\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:mx-192,[dir='rtl'].sm\:rtl\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:my-200,[dir='rtl'].sm\:rtl\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  [dir='rtl'] .sm\:rtl\:mx-200,[dir='rtl'].sm\:rtl\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  [dir='rtl'] .sm\:rtl\:my-208,[dir='rtl'].sm\:rtl\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:mx-208,[dir='rtl'].sm\:rtl\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:my-216,[dir='rtl'].sm\:rtl\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:mx-216,[dir='rtl'].sm\:rtl\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:my-224,[dir='rtl'].sm\:rtl\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:mx-224,[dir='rtl'].sm\:rtl\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:my-256,[dir='rtl'].sm\:rtl\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:mx-256,[dir='rtl'].sm\:rtl\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:my-288,[dir='rtl'].sm\:rtl\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:mx-288,[dir='rtl'].sm\:rtl\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:my-320,[dir='rtl'].sm\:rtl\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='rtl'] .sm\:rtl\:mx-320,[dir='rtl'].sm\:rtl\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='rtl'] .sm\:rtl\:my-360,[dir='rtl'].sm\:rtl\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  [dir='rtl'] .sm\:rtl\:mx-360,[dir='rtl'].sm\:rtl\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  [dir='rtl'] .sm\:rtl\:my-384,[dir='rtl'].sm\:rtl\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:mx-384,[dir='rtl'].sm\:rtl\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:my-400,[dir='rtl'].sm\:rtl\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  [dir='rtl'] .sm\:rtl\:mx-400,[dir='rtl'].sm\:rtl\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  [dir='rtl'] .sm\:rtl\:my-512,[dir='rtl'].sm\:rtl\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:mx-512,[dir='rtl'].sm\:rtl\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:my-640,[dir='rtl'].sm\:rtl\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='rtl'] .sm\:rtl\:mx-640,[dir='rtl'].sm\:rtl\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='rtl'] .sm\:rtl\:my-auto,[dir='rtl'].sm\:rtl\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  [dir='rtl'] .sm\:rtl\:mx-auto,[dir='rtl'].sm\:rtl\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  [dir='rtl'] .sm\:rtl\:my-xs,[dir='rtl'].sm\:rtl\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='rtl'] .sm\:rtl\:mx-xs,[dir='rtl'].sm\:rtl\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='rtl'] .sm\:rtl\:my-sm,[dir='rtl'].sm\:rtl\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  [dir='rtl'] .sm\:rtl\:mx-sm,[dir='rtl'].sm\:rtl\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  [dir='rtl'] .sm\:rtl\:my-md,[dir='rtl'].sm\:rtl\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='rtl'] .sm\:rtl\:mx-md,[dir='rtl'].sm\:rtl\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='rtl'] .sm\:rtl\:my-lg,[dir='rtl'].sm\:rtl\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  [dir='rtl'] .sm\:rtl\:mx-lg,[dir='rtl'].sm\:rtl\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  [dir='rtl'] .sm\:rtl\:my-xl,[dir='rtl'].sm\:rtl\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  [dir='rtl'] .sm\:rtl\:mx-xl,[dir='rtl'].sm\:rtl\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  [dir='rtl'] .sm\:rtl\:my-2xl,[dir='rtl'].sm\:rtl\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  [dir='rtl'] .sm\:rtl\:mx-2xl,[dir='rtl'].sm\:rtl\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  [dir='rtl'] .sm\:rtl\:my-3xl,[dir='rtl'].sm\:rtl\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  [dir='rtl'] .sm\:rtl\:mx-3xl,[dir='rtl'].sm\:rtl\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  [dir='rtl'] .sm\:rtl\:my-4xl,[dir='rtl'].sm\:rtl\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  [dir='rtl'] .sm\:rtl\:mx-4xl,[dir='rtl'].sm\:rtl\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  [dir='rtl'] .sm\:rtl\:my-5xl,[dir='rtl'].sm\:rtl\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  [dir='rtl'] .sm\:rtl\:mx-5xl,[dir='rtl'].sm\:rtl\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  [dir='rtl'] .sm\:rtl\:my-px,[dir='rtl'].sm\:rtl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  [dir='rtl'] .sm\:rtl\:mx-px,[dir='rtl'].sm\:rtl\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  [dir='rtl'] .sm\:rtl\:my-0\.5,[dir='rtl'].sm\:rtl\:my-0\.5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:mx-0\.5,[dir='rtl'].sm\:rtl\:mx-0\.5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:my-1\.5,[dir='rtl'].sm\:rtl\:my-1\.5 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:mx-1\.5,[dir='rtl'].sm\:rtl\:mx-1\.5 {
    margin-left: 0.15rem;
    margin-right: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:my-2\.5,[dir='rtl'].sm\:rtl\:my-2\.5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:mx-2\.5,[dir='rtl'].sm\:rtl\:mx-2\.5 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:my-3\.5,[dir='rtl'].sm\:rtl\:my-3\.5 {
    margin-top: 0.35rem;
    margin-bottom: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:mx-3\.5,[dir='rtl'].sm\:rtl\:mx-3\.5 {
    margin-left: 0.35rem;
    margin-right: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:-my-1,[dir='rtl'].sm\:rtl\:-my-1 {
    margin-top: -0.1rem;
    margin-bottom: -0.1rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-1,[dir='rtl'].sm\:rtl\:-mx-1 {
    margin-left: -0.1rem;
    margin-right: -0.1rem
  }

  [dir='rtl'] .sm\:rtl\:-my-2,[dir='rtl'].sm\:rtl\:-my-2 {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-2,[dir='rtl'].sm\:rtl\:-mx-2 {
    margin-left: -0.2rem;
    margin-right: -0.2rem
  }

  [dir='rtl'] .sm\:rtl\:-my-3,[dir='rtl'].sm\:rtl\:-my-3 {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-3,[dir='rtl'].sm\:rtl\:-mx-3 {
    margin-left: -0.3rem;
    margin-right: -0.3rem
  }

  [dir='rtl'] .sm\:rtl\:-my-4,[dir='rtl'].sm\:rtl\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-4,[dir='rtl'].sm\:rtl\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  [dir='rtl'] .sm\:rtl\:-my-5,[dir='rtl'].sm\:rtl\:-my-5 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-5,[dir='rtl'].sm\:rtl\:-mx-5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  [dir='rtl'] .sm\:rtl\:-my-6,[dir='rtl'].sm\:rtl\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-6,[dir='rtl'].sm\:rtl\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  [dir='rtl'] .sm\:rtl\:-my-7,[dir='rtl'].sm\:rtl\:-my-7 {
    margin-top: -0.7rem;
    margin-bottom: -0.7rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-7,[dir='rtl'].sm\:rtl\:-mx-7 {
    margin-left: -0.7rem;
    margin-right: -0.7rem
  }

  [dir='rtl'] .sm\:rtl\:-my-8,[dir='rtl'].sm\:rtl\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-8,[dir='rtl'].sm\:rtl\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  [dir='rtl'] .sm\:rtl\:-my-9,[dir='rtl'].sm\:rtl\:-my-9 {
    margin-top: -0.9rem;
    margin-bottom: -0.9rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-9,[dir='rtl'].sm\:rtl\:-mx-9 {
    margin-left: -0.9rem;
    margin-right: -0.9rem
  }

  [dir='rtl'] .sm\:rtl\:-my-10,[dir='rtl'].sm\:rtl\:-my-10 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-10,[dir='rtl'].sm\:rtl\:-mx-10 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  [dir='rtl'] .sm\:rtl\:-my-12,[dir='rtl'].sm\:rtl\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-12,[dir='rtl'].sm\:rtl\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  [dir='rtl'] .sm\:rtl\:-my-14,[dir='rtl'].sm\:rtl\:-my-14 {
    margin-top: -1.4rem;
    margin-bottom: -1.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-14,[dir='rtl'].sm\:rtl\:-mx-14 {
    margin-left: -1.4rem;
    margin-right: -1.4rem
  }

  [dir='rtl'] .sm\:rtl\:-my-16,[dir='rtl'].sm\:rtl\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-16,[dir='rtl'].sm\:rtl\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  [dir='rtl'] .sm\:rtl\:-my-20,[dir='rtl'].sm\:rtl\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-20,[dir='rtl'].sm\:rtl\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  [dir='rtl'] .sm\:rtl\:-my-24,[dir='rtl'].sm\:rtl\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-24,[dir='rtl'].sm\:rtl\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  [dir='rtl'] .sm\:rtl\:-my-28,[dir='rtl'].sm\:rtl\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-28,[dir='rtl'].sm\:rtl\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  [dir='rtl'] .sm\:rtl\:-my-32,[dir='rtl'].sm\:rtl\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-32,[dir='rtl'].sm\:rtl\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  [dir='rtl'] .sm\:rtl\:-my-36,[dir='rtl'].sm\:rtl\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-36,[dir='rtl'].sm\:rtl\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  [dir='rtl'] .sm\:rtl\:-my-40,[dir='rtl'].sm\:rtl\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-40,[dir='rtl'].sm\:rtl\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  [dir='rtl'] .sm\:rtl\:-my-44,[dir='rtl'].sm\:rtl\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-44,[dir='rtl'].sm\:rtl\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  [dir='rtl'] .sm\:rtl\:-my-48,[dir='rtl'].sm\:rtl\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-48,[dir='rtl'].sm\:rtl\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  [dir='rtl'] .sm\:rtl\:-my-52,[dir='rtl'].sm\:rtl\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-52,[dir='rtl'].sm\:rtl\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  [dir='rtl'] .sm\:rtl\:-my-56,[dir='rtl'].sm\:rtl\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-56,[dir='rtl'].sm\:rtl\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  [dir='rtl'] .sm\:rtl\:-my-60,[dir='rtl'].sm\:rtl\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-60,[dir='rtl'].sm\:rtl\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  [dir='rtl'] .sm\:rtl\:-my-64,[dir='rtl'].sm\:rtl\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-64,[dir='rtl'].sm\:rtl\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  [dir='rtl'] .sm\:rtl\:-my-68,[dir='rtl'].sm\:rtl\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-68,[dir='rtl'].sm\:rtl\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  [dir='rtl'] .sm\:rtl\:-my-72,[dir='rtl'].sm\:rtl\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-72,[dir='rtl'].sm\:rtl\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  [dir='rtl'] .sm\:rtl\:-my-76,[dir='rtl'].sm\:rtl\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-76,[dir='rtl'].sm\:rtl\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  [dir='rtl'] .sm\:rtl\:-my-80,[dir='rtl'].sm\:rtl\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-80,[dir='rtl'].sm\:rtl\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  [dir='rtl'] .sm\:rtl\:-my-84,[dir='rtl'].sm\:rtl\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-84,[dir='rtl'].sm\:rtl\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  [dir='rtl'] .sm\:rtl\:-my-88,[dir='rtl'].sm\:rtl\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-88,[dir='rtl'].sm\:rtl\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  [dir='rtl'] .sm\:rtl\:-my-92,[dir='rtl'].sm\:rtl\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-92,[dir='rtl'].sm\:rtl\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  [dir='rtl'] .sm\:rtl\:-my-96,[dir='rtl'].sm\:rtl\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-96,[dir='rtl'].sm\:rtl\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  [dir='rtl'] .sm\:rtl\:-my-128,[dir='rtl'].sm\:rtl\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-128,[dir='rtl'].sm\:rtl\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  [dir='rtl'] .sm\:rtl\:-my-136,[dir='rtl'].sm\:rtl\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-136,[dir='rtl'].sm\:rtl\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  [dir='rtl'] .sm\:rtl\:-my-160,[dir='rtl'].sm\:rtl\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-160,[dir='rtl'].sm\:rtl\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  [dir='rtl'] .sm\:rtl\:-my-192,[dir='rtl'].sm\:rtl\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-192,[dir='rtl'].sm\:rtl\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  [dir='rtl'] .sm\:rtl\:-my-200,[dir='rtl'].sm\:rtl\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-200,[dir='rtl'].sm\:rtl\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  [dir='rtl'] .sm\:rtl\:-my-208,[dir='rtl'].sm\:rtl\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-208,[dir='rtl'].sm\:rtl\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  [dir='rtl'] .sm\:rtl\:-my-216,[dir='rtl'].sm\:rtl\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-216,[dir='rtl'].sm\:rtl\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  [dir='rtl'] .sm\:rtl\:-my-224,[dir='rtl'].sm\:rtl\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-224,[dir='rtl'].sm\:rtl\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  [dir='rtl'] .sm\:rtl\:-my-256,[dir='rtl'].sm\:rtl\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-256,[dir='rtl'].sm\:rtl\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  [dir='rtl'] .sm\:rtl\:-my-288,[dir='rtl'].sm\:rtl\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-288,[dir='rtl'].sm\:rtl\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  [dir='rtl'] .sm\:rtl\:-my-320,[dir='rtl'].sm\:rtl\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-320,[dir='rtl'].sm\:rtl\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-my-360,[dir='rtl'].sm\:rtl\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-360,[dir='rtl'].sm\:rtl\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  [dir='rtl'] .sm\:rtl\:-my-384,[dir='rtl'].sm\:rtl\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-384,[dir='rtl'].sm\:rtl\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  [dir='rtl'] .sm\:rtl\:-my-400,[dir='rtl'].sm\:rtl\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-400,[dir='rtl'].sm\:rtl\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  [dir='rtl'] .sm\:rtl\:-my-512,[dir='rtl'].sm\:rtl\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-512,[dir='rtl'].sm\:rtl\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  [dir='rtl'] .sm\:rtl\:-my-640,[dir='rtl'].sm\:rtl\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-640,[dir='rtl'].sm\:rtl\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-my-xs,[dir='rtl'].sm\:rtl\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-xs,[dir='rtl'].sm\:rtl\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-my-sm,[dir='rtl'].sm\:rtl\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-sm,[dir='rtl'].sm\:rtl\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  [dir='rtl'] .sm\:rtl\:-my-md,[dir='rtl'].sm\:rtl\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-md,[dir='rtl'].sm\:rtl\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-my-lg,[dir='rtl'].sm\:rtl\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-lg,[dir='rtl'].sm\:rtl\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  [dir='rtl'] .sm\:rtl\:-my-xl,[dir='rtl'].sm\:rtl\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-xl,[dir='rtl'].sm\:rtl\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  [dir='rtl'] .sm\:rtl\:-my-2xl,[dir='rtl'].sm\:rtl\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-2xl,[dir='rtl'].sm\:rtl\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  [dir='rtl'] .sm\:rtl\:-my-3xl,[dir='rtl'].sm\:rtl\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-3xl,[dir='rtl'].sm\:rtl\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  [dir='rtl'] .sm\:rtl\:-my-4xl,[dir='rtl'].sm\:rtl\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-4xl,[dir='rtl'].sm\:rtl\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  [dir='rtl'] .sm\:rtl\:-my-5xl,[dir='rtl'].sm\:rtl\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-5xl,[dir='rtl'].sm\:rtl\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  [dir='rtl'] .sm\:rtl\:-my-px,[dir='rtl'].sm\:rtl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  [dir='rtl'] .sm\:rtl\:-mx-px,[dir='rtl'].sm\:rtl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  [dir='rtl'] .sm\:rtl\:-my-0\.5,[dir='rtl'].sm\:rtl\:-my-0\.5 {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-0\.5,[dir='rtl'].sm\:rtl\:-mx-0\.5 {
    margin-left: -0.05rem;
    margin-right: -0.05rem
  }

  [dir='rtl'] .sm\:rtl\:-my-1\.5,[dir='rtl'].sm\:rtl\:-my-1\.5 {
    margin-top: -0.15rem;
    margin-bottom: -0.15rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-1\.5,[dir='rtl'].sm\:rtl\:-mx-1\.5 {
    margin-left: -0.15rem;
    margin-right: -0.15rem
  }

  [dir='rtl'] .sm\:rtl\:-my-2\.5,[dir='rtl'].sm\:rtl\:-my-2\.5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-2\.5,[dir='rtl'].sm\:rtl\:-mx-2\.5 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  [dir='rtl'] .sm\:rtl\:-my-3\.5,[dir='rtl'].sm\:rtl\:-my-3\.5 {
    margin-top: -0.35rem;
    margin-bottom: -0.35rem
  }

  [dir='rtl'] .sm\:rtl\:-mx-3\.5,[dir='rtl'].sm\:rtl\:-mx-3\.5 {
    margin-left: -0.35rem;
    margin-right: -0.35rem
  }

  [dir='rtl'] .sm\:rtl\:mt-0,[dir='rtl'].sm\:rtl\:mt-0 {
    margin-top: 0
  }

  [dir='rtl'] .sm\:rtl\:mr-0,[dir='rtl'].sm\:rtl\:mr-0 {
    margin-right: 0
  }

  [dir='rtl'] .sm\:rtl\:mb-0,[dir='rtl'].sm\:rtl\:mb-0 {
    margin-bottom: 0
  }

  [dir='rtl'] .sm\:rtl\:ml-0,[dir='rtl'].sm\:rtl\:ml-0 {
    margin-left: 0
  }

  [dir='rtl'] .sm\:rtl\:mt-1,[dir='rtl'].sm\:rtl\:mt-1 {
    margin-top: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:mr-1,[dir='rtl'].sm\:rtl\:mr-1 {
    margin-right: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:mb-1,[dir='rtl'].sm\:rtl\:mb-1 {
    margin-bottom: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:ml-1,[dir='rtl'].sm\:rtl\:ml-1 {
    margin-left: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:mt-2,[dir='rtl'].sm\:rtl\:mt-2 {
    margin-top: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:mr-2,[dir='rtl'].sm\:rtl\:mr-2 {
    margin-right: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:mb-2,[dir='rtl'].sm\:rtl\:mb-2 {
    margin-bottom: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:ml-2,[dir='rtl'].sm\:rtl\:ml-2 {
    margin-left: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:mt-3,[dir='rtl'].sm\:rtl\:mt-3 {
    margin-top: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:mr-3,[dir='rtl'].sm\:rtl\:mr-3 {
    margin-right: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:mb-3,[dir='rtl'].sm\:rtl\:mb-3 {
    margin-bottom: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:ml-3,[dir='rtl'].sm\:rtl\:ml-3 {
    margin-left: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:mt-4,[dir='rtl'].sm\:rtl\:mt-4 {
    margin-top: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:mr-4,[dir='rtl'].sm\:rtl\:mr-4 {
    margin-right: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:mb-4,[dir='rtl'].sm\:rtl\:mb-4 {
    margin-bottom: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:ml-4,[dir='rtl'].sm\:rtl\:ml-4 {
    margin-left: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:mt-5,[dir='rtl'].sm\:rtl\:mt-5 {
    margin-top: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:mr-5,[dir='rtl'].sm\:rtl\:mr-5 {
    margin-right: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:mb-5,[dir='rtl'].sm\:rtl\:mb-5 {
    margin-bottom: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:ml-5,[dir='rtl'].sm\:rtl\:ml-5 {
    margin-left: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:mt-6,[dir='rtl'].sm\:rtl\:mt-6 {
    margin-top: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:mr-6,[dir='rtl'].sm\:rtl\:mr-6 {
    margin-right: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:mb-6,[dir='rtl'].sm\:rtl\:mb-6 {
    margin-bottom: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:ml-6,[dir='rtl'].sm\:rtl\:ml-6 {
    margin-left: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:mt-7,[dir='rtl'].sm\:rtl\:mt-7 {
    margin-top: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:mr-7,[dir='rtl'].sm\:rtl\:mr-7 {
    margin-right: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:mb-7,[dir='rtl'].sm\:rtl\:mb-7 {
    margin-bottom: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:ml-7,[dir='rtl'].sm\:rtl\:ml-7 {
    margin-left: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:mt-8,[dir='rtl'].sm\:rtl\:mt-8 {
    margin-top: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:mr-8,[dir='rtl'].sm\:rtl\:mr-8 {
    margin-right: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:mb-8,[dir='rtl'].sm\:rtl\:mb-8 {
    margin-bottom: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:ml-8,[dir='rtl'].sm\:rtl\:ml-8 {
    margin-left: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:mt-9,[dir='rtl'].sm\:rtl\:mt-9 {
    margin-top: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:mr-9,[dir='rtl'].sm\:rtl\:mr-9 {
    margin-right: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:mb-9,[dir='rtl'].sm\:rtl\:mb-9 {
    margin-bottom: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:ml-9,[dir='rtl'].sm\:rtl\:ml-9 {
    margin-left: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:mt-10,[dir='rtl'].sm\:rtl\:mt-10 {
    margin-top: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:mr-10,[dir='rtl'].sm\:rtl\:mr-10 {
    margin-right: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:mb-10,[dir='rtl'].sm\:rtl\:mb-10 {
    margin-bottom: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:ml-10,[dir='rtl'].sm\:rtl\:ml-10 {
    margin-left: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:mt-12,[dir='rtl'].sm\:rtl\:mt-12 {
    margin-top: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:mr-12,[dir='rtl'].sm\:rtl\:mr-12 {
    margin-right: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:mb-12,[dir='rtl'].sm\:rtl\:mb-12 {
    margin-bottom: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:ml-12,[dir='rtl'].sm\:rtl\:ml-12 {
    margin-left: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:mt-14,[dir='rtl'].sm\:rtl\:mt-14 {
    margin-top: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:mr-14,[dir='rtl'].sm\:rtl\:mr-14 {
    margin-right: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:mb-14,[dir='rtl'].sm\:rtl\:mb-14 {
    margin-bottom: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:ml-14,[dir='rtl'].sm\:rtl\:ml-14 {
    margin-left: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:mt-16,[dir='rtl'].sm\:rtl\:mt-16 {
    margin-top: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:mr-16,[dir='rtl'].sm\:rtl\:mr-16 {
    margin-right: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:mb-16,[dir='rtl'].sm\:rtl\:mb-16 {
    margin-bottom: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:ml-16,[dir='rtl'].sm\:rtl\:ml-16 {
    margin-left: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:mt-20,[dir='rtl'].sm\:rtl\:mt-20 {
    margin-top: 2rem
  }

  [dir='rtl'] .sm\:rtl\:mr-20,[dir='rtl'].sm\:rtl\:mr-20 {
    margin-right: 2rem
  }

  [dir='rtl'] .sm\:rtl\:mb-20,[dir='rtl'].sm\:rtl\:mb-20 {
    margin-bottom: 2rem
  }

  [dir='rtl'] .sm\:rtl\:ml-20,[dir='rtl'].sm\:rtl\:ml-20 {
    margin-left: 2rem
  }

  [dir='rtl'] .sm\:rtl\:mt-24,[dir='rtl'].sm\:rtl\:mt-24 {
    margin-top: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:mr-24,[dir='rtl'].sm\:rtl\:mr-24 {
    margin-right: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:mb-24,[dir='rtl'].sm\:rtl\:mb-24 {
    margin-bottom: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:ml-24,[dir='rtl'].sm\:rtl\:ml-24 {
    margin-left: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:mt-28,[dir='rtl'].sm\:rtl\:mt-28 {
    margin-top: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:mr-28,[dir='rtl'].sm\:rtl\:mr-28 {
    margin-right: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:mb-28,[dir='rtl'].sm\:rtl\:mb-28 {
    margin-bottom: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:ml-28,[dir='rtl'].sm\:rtl\:ml-28 {
    margin-left: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:mt-32,[dir='rtl'].sm\:rtl\:mt-32 {
    margin-top: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:mr-32,[dir='rtl'].sm\:rtl\:mr-32 {
    margin-right: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:mb-32,[dir='rtl'].sm\:rtl\:mb-32 {
    margin-bottom: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:ml-32,[dir='rtl'].sm\:rtl\:ml-32 {
    margin-left: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:mt-36,[dir='rtl'].sm\:rtl\:mt-36 {
    margin-top: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:mr-36,[dir='rtl'].sm\:rtl\:mr-36 {
    margin-right: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:mb-36,[dir='rtl'].sm\:rtl\:mb-36 {
    margin-bottom: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:ml-36,[dir='rtl'].sm\:rtl\:ml-36 {
    margin-left: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:mt-40,[dir='rtl'].sm\:rtl\:mt-40 {
    margin-top: 4rem
  }

  [dir='rtl'] .sm\:rtl\:mr-40,[dir='rtl'].sm\:rtl\:mr-40 {
    margin-right: 4rem
  }

  [dir='rtl'] .sm\:rtl\:mb-40,[dir='rtl'].sm\:rtl\:mb-40 {
    margin-bottom: 4rem
  }

  [dir='rtl'] .sm\:rtl\:ml-40,[dir='rtl'].sm\:rtl\:ml-40 {
    margin-left: 4rem
  }

  [dir='rtl'] .sm\:rtl\:mt-44,[dir='rtl'].sm\:rtl\:mt-44 {
    margin-top: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:mr-44,[dir='rtl'].sm\:rtl\:mr-44 {
    margin-right: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:mb-44,[dir='rtl'].sm\:rtl\:mb-44 {
    margin-bottom: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:ml-44,[dir='rtl'].sm\:rtl\:ml-44 {
    margin-left: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:mt-48,[dir='rtl'].sm\:rtl\:mt-48 {
    margin-top: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:mr-48,[dir='rtl'].sm\:rtl\:mr-48 {
    margin-right: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:mb-48,[dir='rtl'].sm\:rtl\:mb-48 {
    margin-bottom: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:ml-48,[dir='rtl'].sm\:rtl\:ml-48 {
    margin-left: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:mt-52,[dir='rtl'].sm\:rtl\:mt-52 {
    margin-top: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:mr-52,[dir='rtl'].sm\:rtl\:mr-52 {
    margin-right: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:mb-52,[dir='rtl'].sm\:rtl\:mb-52 {
    margin-bottom: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:ml-52,[dir='rtl'].sm\:rtl\:ml-52 {
    margin-left: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:mt-56,[dir='rtl'].sm\:rtl\:mt-56 {
    margin-top: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:mr-56,[dir='rtl'].sm\:rtl\:mr-56 {
    margin-right: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:mb-56,[dir='rtl'].sm\:rtl\:mb-56 {
    margin-bottom: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:ml-56,[dir='rtl'].sm\:rtl\:ml-56 {
    margin-left: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:mt-60,[dir='rtl'].sm\:rtl\:mt-60 {
    margin-top: 6rem
  }

  [dir='rtl'] .sm\:rtl\:mr-60,[dir='rtl'].sm\:rtl\:mr-60 {
    margin-right: 6rem
  }

  [dir='rtl'] .sm\:rtl\:mb-60,[dir='rtl'].sm\:rtl\:mb-60 {
    margin-bottom: 6rem
  }

  [dir='rtl'] .sm\:rtl\:ml-60,[dir='rtl'].sm\:rtl\:ml-60 {
    margin-left: 6rem
  }

  [dir='rtl'] .sm\:rtl\:mt-64,[dir='rtl'].sm\:rtl\:mt-64 {
    margin-top: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:mr-64,[dir='rtl'].sm\:rtl\:mr-64 {
    margin-right: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:mb-64,[dir='rtl'].sm\:rtl\:mb-64 {
    margin-bottom: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:ml-64,[dir='rtl'].sm\:rtl\:ml-64 {
    margin-left: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:mt-68,[dir='rtl'].sm\:rtl\:mt-68 {
    margin-top: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:mr-68,[dir='rtl'].sm\:rtl\:mr-68 {
    margin-right: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:mb-68,[dir='rtl'].sm\:rtl\:mb-68 {
    margin-bottom: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:ml-68,[dir='rtl'].sm\:rtl\:ml-68 {
    margin-left: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:mt-72,[dir='rtl'].sm\:rtl\:mt-72 {
    margin-top: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:mr-72,[dir='rtl'].sm\:rtl\:mr-72 {
    margin-right: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:mb-72,[dir='rtl'].sm\:rtl\:mb-72 {
    margin-bottom: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:ml-72,[dir='rtl'].sm\:rtl\:ml-72 {
    margin-left: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:mt-76,[dir='rtl'].sm\:rtl\:mt-76 {
    margin-top: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:mr-76,[dir='rtl'].sm\:rtl\:mr-76 {
    margin-right: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:mb-76,[dir='rtl'].sm\:rtl\:mb-76 {
    margin-bottom: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:ml-76,[dir='rtl'].sm\:rtl\:ml-76 {
    margin-left: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:mt-80,[dir='rtl'].sm\:rtl\:mt-80 {
    margin-top: 8rem
  }

  [dir='rtl'] .sm\:rtl\:mr-80,[dir='rtl'].sm\:rtl\:mr-80 {
    margin-right: 8rem
  }

  [dir='rtl'] .sm\:rtl\:mb-80,[dir='rtl'].sm\:rtl\:mb-80 {
    margin-bottom: 8rem
  }

  [dir='rtl'] .sm\:rtl\:ml-80,[dir='rtl'].sm\:rtl\:ml-80 {
    margin-left: 8rem
  }

  [dir='rtl'] .sm\:rtl\:mt-84,[dir='rtl'].sm\:rtl\:mt-84 {
    margin-top: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:mr-84,[dir='rtl'].sm\:rtl\:mr-84 {
    margin-right: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:mb-84,[dir='rtl'].sm\:rtl\:mb-84 {
    margin-bottom: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:ml-84,[dir='rtl'].sm\:rtl\:ml-84 {
    margin-left: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:mt-88,[dir='rtl'].sm\:rtl\:mt-88 {
    margin-top: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:mr-88,[dir='rtl'].sm\:rtl\:mr-88 {
    margin-right: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:mb-88,[dir='rtl'].sm\:rtl\:mb-88 {
    margin-bottom: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:ml-88,[dir='rtl'].sm\:rtl\:ml-88 {
    margin-left: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:mt-92,[dir='rtl'].sm\:rtl\:mt-92 {
    margin-top: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:mr-92,[dir='rtl'].sm\:rtl\:mr-92 {
    margin-right: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:mb-92,[dir='rtl'].sm\:rtl\:mb-92 {
    margin-bottom: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:ml-92,[dir='rtl'].sm\:rtl\:ml-92 {
    margin-left: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:mt-96,[dir='rtl'].sm\:rtl\:mt-96 {
    margin-top: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:mr-96,[dir='rtl'].sm\:rtl\:mr-96 {
    margin-right: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:mb-96,[dir='rtl'].sm\:rtl\:mb-96 {
    margin-bottom: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:ml-96,[dir='rtl'].sm\:rtl\:ml-96 {
    margin-left: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:mt-128,[dir='rtl'].sm\:rtl\:mt-128 {
    margin-top: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:mr-128,[dir='rtl'].sm\:rtl\:mr-128 {
    margin-right: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:mb-128,[dir='rtl'].sm\:rtl\:mb-128 {
    margin-bottom: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:ml-128,[dir='rtl'].sm\:rtl\:ml-128 {
    margin-left: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:mt-136,[dir='rtl'].sm\:rtl\:mt-136 {
    margin-top: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:mr-136,[dir='rtl'].sm\:rtl\:mr-136 {
    margin-right: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:mb-136,[dir='rtl'].sm\:rtl\:mb-136 {
    margin-bottom: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:ml-136,[dir='rtl'].sm\:rtl\:ml-136 {
    margin-left: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:mt-160,[dir='rtl'].sm\:rtl\:mt-160 {
    margin-top: 16rem
  }

  [dir='rtl'] .sm\:rtl\:mr-160,[dir='rtl'].sm\:rtl\:mr-160 {
    margin-right: 16rem
  }

  [dir='rtl'] .sm\:rtl\:mb-160,[dir='rtl'].sm\:rtl\:mb-160 {
    margin-bottom: 16rem
  }

  [dir='rtl'] .sm\:rtl\:ml-160,[dir='rtl'].sm\:rtl\:ml-160 {
    margin-left: 16rem
  }

  [dir='rtl'] .sm\:rtl\:mt-192,[dir='rtl'].sm\:rtl\:mt-192 {
    margin-top: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:mr-192,[dir='rtl'].sm\:rtl\:mr-192 {
    margin-right: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:mb-192,[dir='rtl'].sm\:rtl\:mb-192 {
    margin-bottom: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:ml-192,[dir='rtl'].sm\:rtl\:ml-192 {
    margin-left: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:mt-200,[dir='rtl'].sm\:rtl\:mt-200 {
    margin-top: 20rem
  }

  [dir='rtl'] .sm\:rtl\:mr-200,[dir='rtl'].sm\:rtl\:mr-200 {
    margin-right: 20rem
  }

  [dir='rtl'] .sm\:rtl\:mb-200,[dir='rtl'].sm\:rtl\:mb-200 {
    margin-bottom: 20rem
  }

  [dir='rtl'] .sm\:rtl\:ml-200,[dir='rtl'].sm\:rtl\:ml-200 {
    margin-left: 20rem
  }

  [dir='rtl'] .sm\:rtl\:mt-208,[dir='rtl'].sm\:rtl\:mt-208 {
    margin-top: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:mr-208,[dir='rtl'].sm\:rtl\:mr-208 {
    margin-right: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:mb-208,[dir='rtl'].sm\:rtl\:mb-208 {
    margin-bottom: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:ml-208,[dir='rtl'].sm\:rtl\:ml-208 {
    margin-left: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:mt-216,[dir='rtl'].sm\:rtl\:mt-216 {
    margin-top: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:mr-216,[dir='rtl'].sm\:rtl\:mr-216 {
    margin-right: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:mb-216,[dir='rtl'].sm\:rtl\:mb-216 {
    margin-bottom: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:ml-216,[dir='rtl'].sm\:rtl\:ml-216 {
    margin-left: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:mt-224,[dir='rtl'].sm\:rtl\:mt-224 {
    margin-top: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:mr-224,[dir='rtl'].sm\:rtl\:mr-224 {
    margin-right: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:mb-224,[dir='rtl'].sm\:rtl\:mb-224 {
    margin-bottom: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:ml-224,[dir='rtl'].sm\:rtl\:ml-224 {
    margin-left: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:mt-256,[dir='rtl'].sm\:rtl\:mt-256 {
    margin-top: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:mr-256,[dir='rtl'].sm\:rtl\:mr-256 {
    margin-right: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:mb-256,[dir='rtl'].sm\:rtl\:mb-256 {
    margin-bottom: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:ml-256,[dir='rtl'].sm\:rtl\:ml-256 {
    margin-left: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:mt-288,[dir='rtl'].sm\:rtl\:mt-288 {
    margin-top: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:mr-288,[dir='rtl'].sm\:rtl\:mr-288 {
    margin-right: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:mb-288,[dir='rtl'].sm\:rtl\:mb-288 {
    margin-bottom: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:ml-288,[dir='rtl'].sm\:rtl\:ml-288 {
    margin-left: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:mt-320,[dir='rtl'].sm\:rtl\:mt-320 {
    margin-top: 32rem
  }

  [dir='rtl'] .sm\:rtl\:mr-320,[dir='rtl'].sm\:rtl\:mr-320 {
    margin-right: 32rem
  }

  [dir='rtl'] .sm\:rtl\:mb-320,[dir='rtl'].sm\:rtl\:mb-320 {
    margin-bottom: 32rem
  }

  [dir='rtl'] .sm\:rtl\:ml-320,[dir='rtl'].sm\:rtl\:ml-320 {
    margin-left: 32rem
  }

  [dir='rtl'] .sm\:rtl\:mt-360,[dir='rtl'].sm\:rtl\:mt-360 {
    margin-top: 36rem
  }

  [dir='rtl'] .sm\:rtl\:mr-360,[dir='rtl'].sm\:rtl\:mr-360 {
    margin-right: 36rem
  }

  [dir='rtl'] .sm\:rtl\:mb-360,[dir='rtl'].sm\:rtl\:mb-360 {
    margin-bottom: 36rem
  }

  [dir='rtl'] .sm\:rtl\:ml-360,[dir='rtl'].sm\:rtl\:ml-360 {
    margin-left: 36rem
  }

  [dir='rtl'] .sm\:rtl\:mt-384,[dir='rtl'].sm\:rtl\:mt-384 {
    margin-top: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:mr-384,[dir='rtl'].sm\:rtl\:mr-384 {
    margin-right: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:mb-384,[dir='rtl'].sm\:rtl\:mb-384 {
    margin-bottom: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:ml-384,[dir='rtl'].sm\:rtl\:ml-384 {
    margin-left: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:mt-400,[dir='rtl'].sm\:rtl\:mt-400 {
    margin-top: 40rem
  }

  [dir='rtl'] .sm\:rtl\:mr-400,[dir='rtl'].sm\:rtl\:mr-400 {
    margin-right: 40rem
  }

  [dir='rtl'] .sm\:rtl\:mb-400,[dir='rtl'].sm\:rtl\:mb-400 {
    margin-bottom: 40rem
  }

  [dir='rtl'] .sm\:rtl\:ml-400,[dir='rtl'].sm\:rtl\:ml-400 {
    margin-left: 40rem
  }

  [dir='rtl'] .sm\:rtl\:mt-512,[dir='rtl'].sm\:rtl\:mt-512 {
    margin-top: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:mr-512,[dir='rtl'].sm\:rtl\:mr-512 {
    margin-right: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:mb-512,[dir='rtl'].sm\:rtl\:mb-512 {
    margin-bottom: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:ml-512,[dir='rtl'].sm\:rtl\:ml-512 {
    margin-left: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:mt-640,[dir='rtl'].sm\:rtl\:mt-640 {
    margin-top: 64rem
  }

  [dir='rtl'] .sm\:rtl\:mr-640,[dir='rtl'].sm\:rtl\:mr-640 {
    margin-right: 64rem
  }

  [dir='rtl'] .sm\:rtl\:mb-640,[dir='rtl'].sm\:rtl\:mb-640 {
    margin-bottom: 64rem
  }

  [dir='rtl'] .sm\:rtl\:ml-640,[dir='rtl'].sm\:rtl\:ml-640 {
    margin-left: 64rem
  }

  [dir='rtl'] .sm\:rtl\:mt-auto,[dir='rtl'].sm\:rtl\:mt-auto {
    margin-top: auto
  }

  [dir='rtl'] .sm\:rtl\:mr-auto,[dir='rtl'].sm\:rtl\:mr-auto {
    margin-right: auto
  }

  [dir='rtl'] .sm\:rtl\:mb-auto,[dir='rtl'].sm\:rtl\:mb-auto {
    margin-bottom: auto
  }

  [dir='rtl'] .sm\:rtl\:ml-auto,[dir='rtl'].sm\:rtl\:ml-auto {
    margin-left: auto
  }

  [dir='rtl'] .sm\:rtl\:mt-xs,[dir='rtl'].sm\:rtl\:mt-xs {
    margin-top: 32rem
  }

  [dir='rtl'] .sm\:rtl\:mr-xs,[dir='rtl'].sm\:rtl\:mr-xs {
    margin-right: 32rem
  }

  [dir='rtl'] .sm\:rtl\:mb-xs,[dir='rtl'].sm\:rtl\:mb-xs {
    margin-bottom: 32rem
  }

  [dir='rtl'] .sm\:rtl\:ml-xs,[dir='rtl'].sm\:rtl\:ml-xs {
    margin-left: 32rem
  }

  [dir='rtl'] .sm\:rtl\:mt-sm,[dir='rtl'].sm\:rtl\:mt-sm {
    margin-top: 48rem
  }

  [dir='rtl'] .sm\:rtl\:mr-sm,[dir='rtl'].sm\:rtl\:mr-sm {
    margin-right: 48rem
  }

  [dir='rtl'] .sm\:rtl\:mb-sm,[dir='rtl'].sm\:rtl\:mb-sm {
    margin-bottom: 48rem
  }

  [dir='rtl'] .sm\:rtl\:ml-sm,[dir='rtl'].sm\:rtl\:ml-sm {
    margin-left: 48rem
  }

  [dir='rtl'] .sm\:rtl\:mt-md,[dir='rtl'].sm\:rtl\:mt-md {
    margin-top: 64rem
  }

  [dir='rtl'] .sm\:rtl\:mr-md,[dir='rtl'].sm\:rtl\:mr-md {
    margin-right: 64rem
  }

  [dir='rtl'] .sm\:rtl\:mb-md,[dir='rtl'].sm\:rtl\:mb-md {
    margin-bottom: 64rem
  }

  [dir='rtl'] .sm\:rtl\:ml-md,[dir='rtl'].sm\:rtl\:ml-md {
    margin-left: 64rem
  }

  [dir='rtl'] .sm\:rtl\:mt-lg,[dir='rtl'].sm\:rtl\:mt-lg {
    margin-top: 80rem
  }

  [dir='rtl'] .sm\:rtl\:mr-lg,[dir='rtl'].sm\:rtl\:mr-lg {
    margin-right: 80rem
  }

  [dir='rtl'] .sm\:rtl\:mb-lg,[dir='rtl'].sm\:rtl\:mb-lg {
    margin-bottom: 80rem
  }

  [dir='rtl'] .sm\:rtl\:ml-lg,[dir='rtl'].sm\:rtl\:ml-lg {
    margin-left: 80rem
  }

  [dir='rtl'] .sm\:rtl\:mt-xl,[dir='rtl'].sm\:rtl\:mt-xl {
    margin-top: 96rem
  }

  [dir='rtl'] .sm\:rtl\:mr-xl,[dir='rtl'].sm\:rtl\:mr-xl {
    margin-right: 96rem
  }

  [dir='rtl'] .sm\:rtl\:mb-xl,[dir='rtl'].sm\:rtl\:mb-xl {
    margin-bottom: 96rem
  }

  [dir='rtl'] .sm\:rtl\:ml-xl,[dir='rtl'].sm\:rtl\:ml-xl {
    margin-left: 96rem
  }

  [dir='rtl'] .sm\:rtl\:mt-2xl,[dir='rtl'].sm\:rtl\:mt-2xl {
    margin-top: 112rem
  }

  [dir='rtl'] .sm\:rtl\:mr-2xl,[dir='rtl'].sm\:rtl\:mr-2xl {
    margin-right: 112rem
  }

  [dir='rtl'] .sm\:rtl\:mb-2xl,[dir='rtl'].sm\:rtl\:mb-2xl {
    margin-bottom: 112rem
  }

  [dir='rtl'] .sm\:rtl\:ml-2xl,[dir='rtl'].sm\:rtl\:ml-2xl {
    margin-left: 112rem
  }

  [dir='rtl'] .sm\:rtl\:mt-3xl,[dir='rtl'].sm\:rtl\:mt-3xl {
    margin-top: 128rem
  }

  [dir='rtl'] .sm\:rtl\:mr-3xl,[dir='rtl'].sm\:rtl\:mr-3xl {
    margin-right: 128rem
  }

  [dir='rtl'] .sm\:rtl\:mb-3xl,[dir='rtl'].sm\:rtl\:mb-3xl {
    margin-bottom: 128rem
  }

  [dir='rtl'] .sm\:rtl\:ml-3xl,[dir='rtl'].sm\:rtl\:ml-3xl {
    margin-left: 128rem
  }

  [dir='rtl'] .sm\:rtl\:mt-4xl,[dir='rtl'].sm\:rtl\:mt-4xl {
    margin-top: 144rem
  }

  [dir='rtl'] .sm\:rtl\:mr-4xl,[dir='rtl'].sm\:rtl\:mr-4xl {
    margin-right: 144rem
  }

  [dir='rtl'] .sm\:rtl\:mb-4xl,[dir='rtl'].sm\:rtl\:mb-4xl {
    margin-bottom: 144rem
  }

  [dir='rtl'] .sm\:rtl\:ml-4xl,[dir='rtl'].sm\:rtl\:ml-4xl {
    margin-left: 144rem
  }

  [dir='rtl'] .sm\:rtl\:mt-5xl,[dir='rtl'].sm\:rtl\:mt-5xl {
    margin-top: 160rem
  }

  [dir='rtl'] .sm\:rtl\:mr-5xl,[dir='rtl'].sm\:rtl\:mr-5xl {
    margin-right: 160rem
  }

  [dir='rtl'] .sm\:rtl\:mb-5xl,[dir='rtl'].sm\:rtl\:mb-5xl {
    margin-bottom: 160rem
  }

  [dir='rtl'] .sm\:rtl\:ml-5xl,[dir='rtl'].sm\:rtl\:ml-5xl {
    margin-left: 160rem
  }

  [dir='rtl'] .sm\:rtl\:mt-px,[dir='rtl'].sm\:rtl\:mt-px {
    margin-top: 1px
  }

  [dir='rtl'] .sm\:rtl\:mr-px,[dir='rtl'].sm\:rtl\:mr-px {
    margin-right: 1px
  }

  [dir='rtl'] .sm\:rtl\:mb-px,[dir='rtl'].sm\:rtl\:mb-px {
    margin-bottom: 1px
  }

  [dir='rtl'] .sm\:rtl\:ml-px,[dir='rtl'].sm\:rtl\:ml-px {
    margin-left: 1px
  }

  [dir='rtl'] .sm\:rtl\:mt-0\.5,[dir='rtl'].sm\:rtl\:mt-0\.5 {
    margin-top: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:mr-0\.5,[dir='rtl'].sm\:rtl\:mr-0\.5 {
    margin-right: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:mb-0\.5,[dir='rtl'].sm\:rtl\:mb-0\.5 {
    margin-bottom: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:ml-0\.5,[dir='rtl'].sm\:rtl\:ml-0\.5 {
    margin-left: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:mt-1\.5,[dir='rtl'].sm\:rtl\:mt-1\.5 {
    margin-top: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:mr-1\.5,[dir='rtl'].sm\:rtl\:mr-1\.5 {
    margin-right: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:mb-1\.5,[dir='rtl'].sm\:rtl\:mb-1\.5 {
    margin-bottom: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:ml-1\.5,[dir='rtl'].sm\:rtl\:ml-1\.5 {
    margin-left: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:mt-2\.5,[dir='rtl'].sm\:rtl\:mt-2\.5 {
    margin-top: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:mr-2\.5,[dir='rtl'].sm\:rtl\:mr-2\.5 {
    margin-right: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:mb-2\.5,[dir='rtl'].sm\:rtl\:mb-2\.5 {
    margin-bottom: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:ml-2\.5,[dir='rtl'].sm\:rtl\:ml-2\.5 {
    margin-left: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:mt-3\.5,[dir='rtl'].sm\:rtl\:mt-3\.5 {
    margin-top: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:mr-3\.5,[dir='rtl'].sm\:rtl\:mr-3\.5 {
    margin-right: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:mb-3\.5,[dir='rtl'].sm\:rtl\:mb-3\.5 {
    margin-bottom: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:ml-3\.5,[dir='rtl'].sm\:rtl\:ml-3\.5 {
    margin-left: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-1,[dir='rtl'].sm\:rtl\:-mt-1 {
    margin-top: -0.1rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-1,[dir='rtl'].sm\:rtl\:-mr-1 {
    margin-right: -0.1rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-1,[dir='rtl'].sm\:rtl\:-mb-1 {
    margin-bottom: -0.1rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-1,[dir='rtl'].sm\:rtl\:-ml-1 {
    margin-left: -0.1rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-2,[dir='rtl'].sm\:rtl\:-mt-2 {
    margin-top: -0.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-2,[dir='rtl'].sm\:rtl\:-mr-2 {
    margin-right: -0.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-2,[dir='rtl'].sm\:rtl\:-mb-2 {
    margin-bottom: -0.2rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-2,[dir='rtl'].sm\:rtl\:-ml-2 {
    margin-left: -0.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-3,[dir='rtl'].sm\:rtl\:-mt-3 {
    margin-top: -0.3rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-3,[dir='rtl'].sm\:rtl\:-mr-3 {
    margin-right: -0.3rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-3,[dir='rtl'].sm\:rtl\:-mb-3 {
    margin-bottom: -0.3rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-3,[dir='rtl'].sm\:rtl\:-ml-3 {
    margin-left: -0.3rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-4,[dir='rtl'].sm\:rtl\:-mt-4 {
    margin-top: -0.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-4,[dir='rtl'].sm\:rtl\:-mr-4 {
    margin-right: -0.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-4,[dir='rtl'].sm\:rtl\:-mb-4 {
    margin-bottom: -0.4rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-4,[dir='rtl'].sm\:rtl\:-ml-4 {
    margin-left: -0.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-5,[dir='rtl'].sm\:rtl\:-mt-5 {
    margin-top: -0.5rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-5,[dir='rtl'].sm\:rtl\:-mr-5 {
    margin-right: -0.5rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-5,[dir='rtl'].sm\:rtl\:-mb-5 {
    margin-bottom: -0.5rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-5,[dir='rtl'].sm\:rtl\:-ml-5 {
    margin-left: -0.5rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-6,[dir='rtl'].sm\:rtl\:-mt-6 {
    margin-top: -0.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-6,[dir='rtl'].sm\:rtl\:-mr-6 {
    margin-right: -0.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-6,[dir='rtl'].sm\:rtl\:-mb-6 {
    margin-bottom: -0.6rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-6,[dir='rtl'].sm\:rtl\:-ml-6 {
    margin-left: -0.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-7,[dir='rtl'].sm\:rtl\:-mt-7 {
    margin-top: -0.7rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-7,[dir='rtl'].sm\:rtl\:-mr-7 {
    margin-right: -0.7rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-7,[dir='rtl'].sm\:rtl\:-mb-7 {
    margin-bottom: -0.7rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-7,[dir='rtl'].sm\:rtl\:-ml-7 {
    margin-left: -0.7rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-8,[dir='rtl'].sm\:rtl\:-mt-8 {
    margin-top: -0.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-8,[dir='rtl'].sm\:rtl\:-mr-8 {
    margin-right: -0.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-8,[dir='rtl'].sm\:rtl\:-mb-8 {
    margin-bottom: -0.8rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-8,[dir='rtl'].sm\:rtl\:-ml-8 {
    margin-left: -0.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-9,[dir='rtl'].sm\:rtl\:-mt-9 {
    margin-top: -0.9rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-9,[dir='rtl'].sm\:rtl\:-mr-9 {
    margin-right: -0.9rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-9,[dir='rtl'].sm\:rtl\:-mb-9 {
    margin-bottom: -0.9rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-9,[dir='rtl'].sm\:rtl\:-ml-9 {
    margin-left: -0.9rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-10,[dir='rtl'].sm\:rtl\:-mt-10 {
    margin-top: -1rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-10,[dir='rtl'].sm\:rtl\:-mr-10 {
    margin-right: -1rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-10,[dir='rtl'].sm\:rtl\:-mb-10 {
    margin-bottom: -1rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-10,[dir='rtl'].sm\:rtl\:-ml-10 {
    margin-left: -1rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-12,[dir='rtl'].sm\:rtl\:-mt-12 {
    margin-top: -1.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-12,[dir='rtl'].sm\:rtl\:-mr-12 {
    margin-right: -1.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-12,[dir='rtl'].sm\:rtl\:-mb-12 {
    margin-bottom: -1.2rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-12,[dir='rtl'].sm\:rtl\:-ml-12 {
    margin-left: -1.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-14,[dir='rtl'].sm\:rtl\:-mt-14 {
    margin-top: -1.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-14,[dir='rtl'].sm\:rtl\:-mr-14 {
    margin-right: -1.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-14,[dir='rtl'].sm\:rtl\:-mb-14 {
    margin-bottom: -1.4rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-14,[dir='rtl'].sm\:rtl\:-ml-14 {
    margin-left: -1.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-16,[dir='rtl'].sm\:rtl\:-mt-16 {
    margin-top: -1.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-16,[dir='rtl'].sm\:rtl\:-mr-16 {
    margin-right: -1.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-16,[dir='rtl'].sm\:rtl\:-mb-16 {
    margin-bottom: -1.6rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-16,[dir='rtl'].sm\:rtl\:-ml-16 {
    margin-left: -1.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-20,[dir='rtl'].sm\:rtl\:-mt-20 {
    margin-top: -2rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-20,[dir='rtl'].sm\:rtl\:-mr-20 {
    margin-right: -2rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-20,[dir='rtl'].sm\:rtl\:-mb-20 {
    margin-bottom: -2rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-20,[dir='rtl'].sm\:rtl\:-ml-20 {
    margin-left: -2rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-24,[dir='rtl'].sm\:rtl\:-mt-24 {
    margin-top: -2.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-24,[dir='rtl'].sm\:rtl\:-mr-24 {
    margin-right: -2.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-24,[dir='rtl'].sm\:rtl\:-mb-24 {
    margin-bottom: -2.4rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-24,[dir='rtl'].sm\:rtl\:-ml-24 {
    margin-left: -2.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-28,[dir='rtl'].sm\:rtl\:-mt-28 {
    margin-top: -2.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-28,[dir='rtl'].sm\:rtl\:-mr-28 {
    margin-right: -2.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-28,[dir='rtl'].sm\:rtl\:-mb-28 {
    margin-bottom: -2.8rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-28,[dir='rtl'].sm\:rtl\:-ml-28 {
    margin-left: -2.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-32,[dir='rtl'].sm\:rtl\:-mt-32 {
    margin-top: -3.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-32,[dir='rtl'].sm\:rtl\:-mr-32 {
    margin-right: -3.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-32,[dir='rtl'].sm\:rtl\:-mb-32 {
    margin-bottom: -3.2rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-32,[dir='rtl'].sm\:rtl\:-ml-32 {
    margin-left: -3.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-36,[dir='rtl'].sm\:rtl\:-mt-36 {
    margin-top: -3.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-36,[dir='rtl'].sm\:rtl\:-mr-36 {
    margin-right: -3.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-36,[dir='rtl'].sm\:rtl\:-mb-36 {
    margin-bottom: -3.6rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-36,[dir='rtl'].sm\:rtl\:-ml-36 {
    margin-left: -3.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-40,[dir='rtl'].sm\:rtl\:-mt-40 {
    margin-top: -4rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-40,[dir='rtl'].sm\:rtl\:-mr-40 {
    margin-right: -4rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-40,[dir='rtl'].sm\:rtl\:-mb-40 {
    margin-bottom: -4rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-40,[dir='rtl'].sm\:rtl\:-ml-40 {
    margin-left: -4rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-44,[dir='rtl'].sm\:rtl\:-mt-44 {
    margin-top: -4.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-44,[dir='rtl'].sm\:rtl\:-mr-44 {
    margin-right: -4.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-44,[dir='rtl'].sm\:rtl\:-mb-44 {
    margin-bottom: -4.4rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-44,[dir='rtl'].sm\:rtl\:-ml-44 {
    margin-left: -4.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-48,[dir='rtl'].sm\:rtl\:-mt-48 {
    margin-top: -4.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-48,[dir='rtl'].sm\:rtl\:-mr-48 {
    margin-right: -4.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-48,[dir='rtl'].sm\:rtl\:-mb-48 {
    margin-bottom: -4.8rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-48,[dir='rtl'].sm\:rtl\:-ml-48 {
    margin-left: -4.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-52,[dir='rtl'].sm\:rtl\:-mt-52 {
    margin-top: -5.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-52,[dir='rtl'].sm\:rtl\:-mr-52 {
    margin-right: -5.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-52,[dir='rtl'].sm\:rtl\:-mb-52 {
    margin-bottom: -5.2rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-52,[dir='rtl'].sm\:rtl\:-ml-52 {
    margin-left: -5.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-56,[dir='rtl'].sm\:rtl\:-mt-56 {
    margin-top: -5.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-56,[dir='rtl'].sm\:rtl\:-mr-56 {
    margin-right: -5.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-56,[dir='rtl'].sm\:rtl\:-mb-56 {
    margin-bottom: -5.6rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-56,[dir='rtl'].sm\:rtl\:-ml-56 {
    margin-left: -5.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-60,[dir='rtl'].sm\:rtl\:-mt-60 {
    margin-top: -6rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-60,[dir='rtl'].sm\:rtl\:-mr-60 {
    margin-right: -6rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-60,[dir='rtl'].sm\:rtl\:-mb-60 {
    margin-bottom: -6rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-60,[dir='rtl'].sm\:rtl\:-ml-60 {
    margin-left: -6rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-64,[dir='rtl'].sm\:rtl\:-mt-64 {
    margin-top: -6.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-64,[dir='rtl'].sm\:rtl\:-mr-64 {
    margin-right: -6.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-64,[dir='rtl'].sm\:rtl\:-mb-64 {
    margin-bottom: -6.4rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-64,[dir='rtl'].sm\:rtl\:-ml-64 {
    margin-left: -6.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-68,[dir='rtl'].sm\:rtl\:-mt-68 {
    margin-top: -6.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-68,[dir='rtl'].sm\:rtl\:-mr-68 {
    margin-right: -6.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-68,[dir='rtl'].sm\:rtl\:-mb-68 {
    margin-bottom: -6.8rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-68,[dir='rtl'].sm\:rtl\:-ml-68 {
    margin-left: -6.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-72,[dir='rtl'].sm\:rtl\:-mt-72 {
    margin-top: -7.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-72,[dir='rtl'].sm\:rtl\:-mr-72 {
    margin-right: -7.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-72,[dir='rtl'].sm\:rtl\:-mb-72 {
    margin-bottom: -7.2rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-72,[dir='rtl'].sm\:rtl\:-ml-72 {
    margin-left: -7.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-76,[dir='rtl'].sm\:rtl\:-mt-76 {
    margin-top: -7.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-76,[dir='rtl'].sm\:rtl\:-mr-76 {
    margin-right: -7.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-76,[dir='rtl'].sm\:rtl\:-mb-76 {
    margin-bottom: -7.6rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-76,[dir='rtl'].sm\:rtl\:-ml-76 {
    margin-left: -7.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-80,[dir='rtl'].sm\:rtl\:-mt-80 {
    margin-top: -8rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-80,[dir='rtl'].sm\:rtl\:-mr-80 {
    margin-right: -8rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-80,[dir='rtl'].sm\:rtl\:-mb-80 {
    margin-bottom: -8rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-80,[dir='rtl'].sm\:rtl\:-ml-80 {
    margin-left: -8rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-84,[dir='rtl'].sm\:rtl\:-mt-84 {
    margin-top: -8.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-84,[dir='rtl'].sm\:rtl\:-mr-84 {
    margin-right: -8.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-84,[dir='rtl'].sm\:rtl\:-mb-84 {
    margin-bottom: -8.4rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-84,[dir='rtl'].sm\:rtl\:-ml-84 {
    margin-left: -8.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-88,[dir='rtl'].sm\:rtl\:-mt-88 {
    margin-top: -8.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-88,[dir='rtl'].sm\:rtl\:-mr-88 {
    margin-right: -8.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-88,[dir='rtl'].sm\:rtl\:-mb-88 {
    margin-bottom: -8.8rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-88,[dir='rtl'].sm\:rtl\:-ml-88 {
    margin-left: -8.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-92,[dir='rtl'].sm\:rtl\:-mt-92 {
    margin-top: -9.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-92,[dir='rtl'].sm\:rtl\:-mr-92 {
    margin-right: -9.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-92,[dir='rtl'].sm\:rtl\:-mb-92 {
    margin-bottom: -9.2rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-92,[dir='rtl'].sm\:rtl\:-ml-92 {
    margin-left: -9.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-96,[dir='rtl'].sm\:rtl\:-mt-96 {
    margin-top: -9.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-96,[dir='rtl'].sm\:rtl\:-mr-96 {
    margin-right: -9.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-96,[dir='rtl'].sm\:rtl\:-mb-96 {
    margin-bottom: -9.6rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-96,[dir='rtl'].sm\:rtl\:-ml-96 {
    margin-left: -9.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-128,[dir='rtl'].sm\:rtl\:-mt-128 {
    margin-top: -12.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-128,[dir='rtl'].sm\:rtl\:-mr-128 {
    margin-right: -12.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-128,[dir='rtl'].sm\:rtl\:-mb-128 {
    margin-bottom: -12.8rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-128,[dir='rtl'].sm\:rtl\:-ml-128 {
    margin-left: -12.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-136,[dir='rtl'].sm\:rtl\:-mt-136 {
    margin-top: -13.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-136,[dir='rtl'].sm\:rtl\:-mr-136 {
    margin-right: -13.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-136,[dir='rtl'].sm\:rtl\:-mb-136 {
    margin-bottom: -13.6rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-136,[dir='rtl'].sm\:rtl\:-ml-136 {
    margin-left: -13.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-160,[dir='rtl'].sm\:rtl\:-mt-160 {
    margin-top: -16rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-160,[dir='rtl'].sm\:rtl\:-mr-160 {
    margin-right: -16rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-160,[dir='rtl'].sm\:rtl\:-mb-160 {
    margin-bottom: -16rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-160,[dir='rtl'].sm\:rtl\:-ml-160 {
    margin-left: -16rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-192,[dir='rtl'].sm\:rtl\:-mt-192 {
    margin-top: -19.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-192,[dir='rtl'].sm\:rtl\:-mr-192 {
    margin-right: -19.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-192,[dir='rtl'].sm\:rtl\:-mb-192 {
    margin-bottom: -19.2rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-192,[dir='rtl'].sm\:rtl\:-ml-192 {
    margin-left: -19.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-200,[dir='rtl'].sm\:rtl\:-mt-200 {
    margin-top: -20rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-200,[dir='rtl'].sm\:rtl\:-mr-200 {
    margin-right: -20rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-200,[dir='rtl'].sm\:rtl\:-mb-200 {
    margin-bottom: -20rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-200,[dir='rtl'].sm\:rtl\:-ml-200 {
    margin-left: -20rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-208,[dir='rtl'].sm\:rtl\:-mt-208 {
    margin-top: -20.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-208,[dir='rtl'].sm\:rtl\:-mr-208 {
    margin-right: -20.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-208,[dir='rtl'].sm\:rtl\:-mb-208 {
    margin-bottom: -20.8rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-208,[dir='rtl'].sm\:rtl\:-ml-208 {
    margin-left: -20.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-216,[dir='rtl'].sm\:rtl\:-mt-216 {
    margin-top: -21.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-216,[dir='rtl'].sm\:rtl\:-mr-216 {
    margin-right: -21.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-216,[dir='rtl'].sm\:rtl\:-mb-216 {
    margin-bottom: -21.6rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-216,[dir='rtl'].sm\:rtl\:-ml-216 {
    margin-left: -21.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-224,[dir='rtl'].sm\:rtl\:-mt-224 {
    margin-top: -22.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-224,[dir='rtl'].sm\:rtl\:-mr-224 {
    margin-right: -22.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-224,[dir='rtl'].sm\:rtl\:-mb-224 {
    margin-bottom: -22.4rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-224,[dir='rtl'].sm\:rtl\:-ml-224 {
    margin-left: -22.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-256,[dir='rtl'].sm\:rtl\:-mt-256 {
    margin-top: -25.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-256,[dir='rtl'].sm\:rtl\:-mr-256 {
    margin-right: -25.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-256,[dir='rtl'].sm\:rtl\:-mb-256 {
    margin-bottom: -25.6rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-256,[dir='rtl'].sm\:rtl\:-ml-256 {
    margin-left: -25.6rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-288,[dir='rtl'].sm\:rtl\:-mt-288 {
    margin-top: -28.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-288,[dir='rtl'].sm\:rtl\:-mr-288 {
    margin-right: -28.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-288,[dir='rtl'].sm\:rtl\:-mb-288 {
    margin-bottom: -28.8rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-288,[dir='rtl'].sm\:rtl\:-ml-288 {
    margin-left: -28.8rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-320,[dir='rtl'].sm\:rtl\:-mt-320 {
    margin-top: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-320,[dir='rtl'].sm\:rtl\:-mr-320 {
    margin-right: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-320,[dir='rtl'].sm\:rtl\:-mb-320 {
    margin-bottom: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-320,[dir='rtl'].sm\:rtl\:-ml-320 {
    margin-left: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-360,[dir='rtl'].sm\:rtl\:-mt-360 {
    margin-top: -36rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-360,[dir='rtl'].sm\:rtl\:-mr-360 {
    margin-right: -36rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-360,[dir='rtl'].sm\:rtl\:-mb-360 {
    margin-bottom: -36rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-360,[dir='rtl'].sm\:rtl\:-ml-360 {
    margin-left: -36rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-384,[dir='rtl'].sm\:rtl\:-mt-384 {
    margin-top: -38.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-384,[dir='rtl'].sm\:rtl\:-mr-384 {
    margin-right: -38.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-384,[dir='rtl'].sm\:rtl\:-mb-384 {
    margin-bottom: -38.4rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-384,[dir='rtl'].sm\:rtl\:-ml-384 {
    margin-left: -38.4rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-400,[dir='rtl'].sm\:rtl\:-mt-400 {
    margin-top: -40rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-400,[dir='rtl'].sm\:rtl\:-mr-400 {
    margin-right: -40rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-400,[dir='rtl'].sm\:rtl\:-mb-400 {
    margin-bottom: -40rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-400,[dir='rtl'].sm\:rtl\:-ml-400 {
    margin-left: -40rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-512,[dir='rtl'].sm\:rtl\:-mt-512 {
    margin-top: -51.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-512,[dir='rtl'].sm\:rtl\:-mr-512 {
    margin-right: -51.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-512,[dir='rtl'].sm\:rtl\:-mb-512 {
    margin-bottom: -51.2rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-512,[dir='rtl'].sm\:rtl\:-ml-512 {
    margin-left: -51.2rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-640,[dir='rtl'].sm\:rtl\:-mt-640 {
    margin-top: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-640,[dir='rtl'].sm\:rtl\:-mr-640 {
    margin-right: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-640,[dir='rtl'].sm\:rtl\:-mb-640 {
    margin-bottom: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-640,[dir='rtl'].sm\:rtl\:-ml-640 {
    margin-left: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-xs,[dir='rtl'].sm\:rtl\:-mt-xs {
    margin-top: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-xs,[dir='rtl'].sm\:rtl\:-mr-xs {
    margin-right: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-xs,[dir='rtl'].sm\:rtl\:-mb-xs {
    margin-bottom: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-xs,[dir='rtl'].sm\:rtl\:-ml-xs {
    margin-left: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-sm,[dir='rtl'].sm\:rtl\:-mt-sm {
    margin-top: -48rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-sm,[dir='rtl'].sm\:rtl\:-mr-sm {
    margin-right: -48rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-sm,[dir='rtl'].sm\:rtl\:-mb-sm {
    margin-bottom: -48rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-sm,[dir='rtl'].sm\:rtl\:-ml-sm {
    margin-left: -48rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-md,[dir='rtl'].sm\:rtl\:-mt-md {
    margin-top: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-md,[dir='rtl'].sm\:rtl\:-mr-md {
    margin-right: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-md,[dir='rtl'].sm\:rtl\:-mb-md {
    margin-bottom: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-md,[dir='rtl'].sm\:rtl\:-ml-md {
    margin-left: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-lg,[dir='rtl'].sm\:rtl\:-mt-lg {
    margin-top: -80rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-lg,[dir='rtl'].sm\:rtl\:-mr-lg {
    margin-right: -80rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-lg,[dir='rtl'].sm\:rtl\:-mb-lg {
    margin-bottom: -80rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-lg,[dir='rtl'].sm\:rtl\:-ml-lg {
    margin-left: -80rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-xl,[dir='rtl'].sm\:rtl\:-mt-xl {
    margin-top: -96rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-xl,[dir='rtl'].sm\:rtl\:-mr-xl {
    margin-right: -96rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-xl,[dir='rtl'].sm\:rtl\:-mb-xl {
    margin-bottom: -96rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-xl,[dir='rtl'].sm\:rtl\:-ml-xl {
    margin-left: -96rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-2xl,[dir='rtl'].sm\:rtl\:-mt-2xl {
    margin-top: -112rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-2xl,[dir='rtl'].sm\:rtl\:-mr-2xl {
    margin-right: -112rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-2xl,[dir='rtl'].sm\:rtl\:-mb-2xl {
    margin-bottom: -112rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-2xl,[dir='rtl'].sm\:rtl\:-ml-2xl {
    margin-left: -112rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-3xl,[dir='rtl'].sm\:rtl\:-mt-3xl {
    margin-top: -128rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-3xl,[dir='rtl'].sm\:rtl\:-mr-3xl {
    margin-right: -128rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-3xl,[dir='rtl'].sm\:rtl\:-mb-3xl {
    margin-bottom: -128rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-3xl,[dir='rtl'].sm\:rtl\:-ml-3xl {
    margin-left: -128rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-4xl,[dir='rtl'].sm\:rtl\:-mt-4xl {
    margin-top: -144rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-4xl,[dir='rtl'].sm\:rtl\:-mr-4xl {
    margin-right: -144rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-4xl,[dir='rtl'].sm\:rtl\:-mb-4xl {
    margin-bottom: -144rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-4xl,[dir='rtl'].sm\:rtl\:-ml-4xl {
    margin-left: -144rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-5xl,[dir='rtl'].sm\:rtl\:-mt-5xl {
    margin-top: -160rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-5xl,[dir='rtl'].sm\:rtl\:-mr-5xl {
    margin-right: -160rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-5xl,[dir='rtl'].sm\:rtl\:-mb-5xl {
    margin-bottom: -160rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-5xl,[dir='rtl'].sm\:rtl\:-ml-5xl {
    margin-left: -160rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-px,[dir='rtl'].sm\:rtl\:-mt-px {
    margin-top: -1px
  }

  [dir='rtl'] .sm\:rtl\:-mr-px,[dir='rtl'].sm\:rtl\:-mr-px {
    margin-right: -1px
  }

  [dir='rtl'] .sm\:rtl\:-mb-px,[dir='rtl'].sm\:rtl\:-mb-px {
    margin-bottom: -1px
  }

  [dir='rtl'] .sm\:rtl\:-ml-px,[dir='rtl'].sm\:rtl\:-ml-px {
    margin-left: -1px
  }

  [dir='rtl'] .sm\:rtl\:-mt-0\.5,[dir='rtl'].sm\:rtl\:-mt-0\.5 {
    margin-top: -0.05rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-0\.5,[dir='rtl'].sm\:rtl\:-mr-0\.5 {
    margin-right: -0.05rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-0\.5,[dir='rtl'].sm\:rtl\:-mb-0\.5 {
    margin-bottom: -0.05rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-0\.5,[dir='rtl'].sm\:rtl\:-ml-0\.5 {
    margin-left: -0.05rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-1\.5,[dir='rtl'].sm\:rtl\:-mt-1\.5 {
    margin-top: -0.15rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-1\.5,[dir='rtl'].sm\:rtl\:-mr-1\.5 {
    margin-right: -0.15rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-1\.5,[dir='rtl'].sm\:rtl\:-mb-1\.5 {
    margin-bottom: -0.15rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-1\.5,[dir='rtl'].sm\:rtl\:-ml-1\.5 {
    margin-left: -0.15rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-2\.5,[dir='rtl'].sm\:rtl\:-mt-2\.5 {
    margin-top: -0.25rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-2\.5,[dir='rtl'].sm\:rtl\:-mr-2\.5 {
    margin-right: -0.25rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-2\.5,[dir='rtl'].sm\:rtl\:-mb-2\.5 {
    margin-bottom: -0.25rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-2\.5,[dir='rtl'].sm\:rtl\:-ml-2\.5 {
    margin-left: -0.25rem
  }

  [dir='rtl'] .sm\:rtl\:-mt-3\.5,[dir='rtl'].sm\:rtl\:-mt-3\.5 {
    margin-top: -0.35rem
  }

  [dir='rtl'] .sm\:rtl\:-mr-3\.5,[dir='rtl'].sm\:rtl\:-mr-3\.5 {
    margin-right: -0.35rem
  }

  [dir='rtl'] .sm\:rtl\:-mb-3\.5,[dir='rtl'].sm\:rtl\:-mb-3\.5 {
    margin-bottom: -0.35rem
  }

  [dir='rtl'] .sm\:rtl\:-ml-3\.5,[dir='rtl'].sm\:rtl\:-ml-3\.5 {
    margin-left: -0.35rem
  }

  .sm\:max-h-0 {
    max-height: 0
  }

  .sm\:max-h-1 {
    max-height: 0.1rem
  }

  .sm\:max-h-2 {
    max-height: 0.2rem
  }

  .sm\:max-h-3 {
    max-height: 0.3rem
  }

  .sm\:max-h-4 {
    max-height: 0.4rem
  }

  .sm\:max-h-5 {
    max-height: 0.5rem
  }

  .sm\:max-h-6 {
    max-height: 0.6rem
  }

  .sm\:max-h-7 {
    max-height: 0.7rem
  }

  .sm\:max-h-8 {
    max-height: 0.8rem
  }

  .sm\:max-h-9 {
    max-height: 0.9rem
  }

  .sm\:max-h-10 {
    max-height: 1.0rem
  }

  .sm\:max-h-12 {
    max-height: 1.2rem
  }

  .sm\:max-h-14 {
    max-height: 1.4rem
  }

  .sm\:max-h-16 {
    max-height: 1.6rem
  }

  .sm\:max-h-20 {
    max-height: 2rem
  }

  .sm\:max-h-24 {
    max-height: 2.4rem
  }

  .sm\:max-h-28 {
    max-height: 2.8rem
  }

  .sm\:max-h-32 {
    max-height: 3.2rem
  }

  .sm\:max-h-36 {
    max-height: 3.6rem
  }

  .sm\:max-h-40 {
    max-height: 4rem
  }

  .sm\:max-h-44 {
    max-height: 4.4rem
  }

  .sm\:max-h-48 {
    max-height: 4.8rem
  }

  .sm\:max-h-52 {
    max-height: 5.2rem
  }

  .sm\:max-h-56 {
    max-height: 5.6rem
  }

  .sm\:max-h-60 {
    max-height: 6rem
  }

  .sm\:max-h-64 {
    max-height: 6.4rem
  }

  .sm\:max-h-68 {
    max-height: 6.8rem
  }

  .sm\:max-h-72 {
    max-height: 7.2rem
  }

  .sm\:max-h-76 {
    max-height: 7.6rem
  }

  .sm\:max-h-80 {
    max-height: 8rem
  }

  .sm\:max-h-84 {
    max-height: 8.4rem
  }

  .sm\:max-h-88 {
    max-height: 8.8rem
  }

  .sm\:max-h-92 {
    max-height: 9.2rem
  }

  .sm\:max-h-96 {
    max-height: 9.6rem
  }

  .sm\:max-h-128 {
    max-height: 12.8rem
  }

  .sm\:max-h-136 {
    max-height: 13.6rem
  }

  .sm\:max-h-160 {
    max-height: 16rem
  }

  .sm\:max-h-192 {
    max-height: 19.2rem
  }

  .sm\:max-h-200 {
    max-height: 20rem
  }

  .sm\:max-h-208 {
    max-height: 20.8rem
  }

  .sm\:max-h-216 {
    max-height: 21.6rem
  }

  .sm\:max-h-224 {
    max-height: 22.4rem
  }

  .sm\:max-h-256 {
    max-height: 25.6rem
  }

  .sm\:max-h-288 {
    max-height: 28.8rem
  }

  .sm\:max-h-320 {
    max-height: 32rem
  }

  .sm\:max-h-360 {
    max-height: 36rem
  }

  .sm\:max-h-384 {
    max-height: 38.4rem
  }

  .sm\:max-h-400 {
    max-height: 40rem
  }

  .sm\:max-h-512 {
    max-height: 51.2rem
  }

  .sm\:max-h-640 {
    max-height: 64rem
  }

  .sm\:max-h-xs {
    max-height: 32rem
  }

  .sm\:max-h-sm {
    max-height: 48rem
  }

  .sm\:max-h-md {
    max-height: 64rem
  }

  .sm\:max-h-lg {
    max-height: 80rem
  }

  .sm\:max-h-xl {
    max-height: 96rem
  }

  .sm\:max-h-2xl {
    max-height: 112rem
  }

  .sm\:max-h-3xl {
    max-height: 128rem
  }

  .sm\:max-h-4xl {
    max-height: 144rem
  }

  .sm\:max-h-5xl {
    max-height: 160rem
  }

  .sm\:max-h-px {
    max-height: 1px
  }

  .sm\:max-h-0\.5 {
    max-height: 0.05rem
  }

  .sm\:max-h-1\.5 {
    max-height: 0.15rem
  }

  .sm\:max-h-2\.5 {
    max-height: 0.25rem
  }

  .sm\:max-h-3\.5 {
    max-height: 0.35rem
  }

  .sm\:max-h-full {
    max-height: 100%
  }

  .sm\:max-h-screen {
    max-height: 100vh
  }

  .sm\:max-h-auto {
    max-height: auto
  }

  .sm\:max-w-0 {
    max-width: 0
  }

  .sm\:max-w-1 {
    max-width: 0.1rem
  }

  .sm\:max-w-2 {
    max-width: 0.2rem
  }

  .sm\:max-w-3 {
    max-width: 0.3rem
  }

  .sm\:max-w-4 {
    max-width: 0.4rem
  }

  .sm\:max-w-5 {
    max-width: 0.5rem
  }

  .sm\:max-w-6 {
    max-width: 0.6rem
  }

  .sm\:max-w-7 {
    max-width: 0.7rem
  }

  .sm\:max-w-8 {
    max-width: 0.8rem
  }

  .sm\:max-w-9 {
    max-width: 0.9rem
  }

  .sm\:max-w-10 {
    max-width: 1.0rem
  }

  .sm\:max-w-12 {
    max-width: 1.2rem
  }

  .sm\:max-w-14 {
    max-width: 1.4rem
  }

  .sm\:max-w-16 {
    max-width: 1.6rem
  }

  .sm\:max-w-20 {
    max-width: 2rem
  }

  .sm\:max-w-24 {
    max-width: 2.4rem
  }

  .sm\:max-w-28 {
    max-width: 2.8rem
  }

  .sm\:max-w-32 {
    max-width: 3.2rem
  }

  .sm\:max-w-36 {
    max-width: 3.6rem
  }

  .sm\:max-w-40 {
    max-width: 4rem
  }

  .sm\:max-w-44 {
    max-width: 4.4rem
  }

  .sm\:max-w-48 {
    max-width: 4.8rem
  }

  .sm\:max-w-52 {
    max-width: 5.2rem
  }

  .sm\:max-w-56 {
    max-width: 5.6rem
  }

  .sm\:max-w-60 {
    max-width: 6rem
  }

  .sm\:max-w-64 {
    max-width: 6.4rem
  }

  .sm\:max-w-68 {
    max-width: 6.8rem
  }

  .sm\:max-w-72 {
    max-width: 7.2rem
  }

  .sm\:max-w-76 {
    max-width: 7.6rem
  }

  .sm\:max-w-80 {
    max-width: 8rem
  }

  .sm\:max-w-84 {
    max-width: 8.4rem
  }

  .sm\:max-w-88 {
    max-width: 8.8rem
  }

  .sm\:max-w-92 {
    max-width: 9.2rem
  }

  .sm\:max-w-96 {
    max-width: 9.6rem
  }

  .sm\:max-w-128 {
    max-width: 12.8rem
  }

  .sm\:max-w-136 {
    max-width: 13.6rem
  }

  .sm\:max-w-160 {
    max-width: 16rem
  }

  .sm\:max-w-192 {
    max-width: 19.2rem
  }

  .sm\:max-w-200 {
    max-width: 20rem
  }

  .sm\:max-w-208 {
    max-width: 20.8rem
  }

  .sm\:max-w-216 {
    max-width: 21.6rem
  }

  .sm\:max-w-224 {
    max-width: 22.4rem
  }

  .sm\:max-w-256 {
    max-width: 25.6rem
  }

  .sm\:max-w-288 {
    max-width: 28.8rem
  }

  .sm\:max-w-320 {
    max-width: 32rem
  }

  .sm\:max-w-360 {
    max-width: 36rem
  }

  .sm\:max-w-384 {
    max-width: 38.4rem
  }

  .sm\:max-w-400 {
    max-width: 40rem
  }

  .sm\:max-w-512 {
    max-width: 51.2rem
  }

  .sm\:max-w-640 {
    max-width: 64rem
  }

  .sm\:max-w-none {
    max-width: none
  }

  .sm\:max-w-xs {
    max-width: 32rem
  }

  .sm\:max-w-sm {
    max-width: 48rem
  }

  .sm\:max-w-md {
    max-width: 64rem
  }

  .sm\:max-w-lg {
    max-width: 80rem
  }

  .sm\:max-w-xl {
    max-width: 96rem
  }

  .sm\:max-w-2xl {
    max-width: 112rem
  }

  .sm\:max-w-3xl {
    max-width: 128rem
  }

  .sm\:max-w-4xl {
    max-width: 144rem
  }

  .sm\:max-w-5xl {
    max-width: 160rem
  }

  .sm\:max-w-px {
    max-width: 1px
  }

  .sm\:max-w-0\.5 {
    max-width: 0.05rem
  }

  .sm\:max-w-1\.5 {
    max-width: 0.15rem
  }

  .sm\:max-w-2\.5 {
    max-width: 0.25rem
  }

  .sm\:max-w-3\.5 {
    max-width: 0.35rem
  }

  .sm\:max-w-full {
    max-width: 100%
  }

  .sm\:max-w-min {
    max-width: min-content
  }

  .sm\:max-w-max {
    max-width: max-content
  }

  .sm\:max-w-prose {
    max-width: 65ch
  }

  .sm\:max-w-screen-sm {
    max-width: 600px
  }

  .sm\:max-w-screen-md {
    max-width: 960px
  }

  .sm\:max-w-screen-lg {
    max-width: 1280px
  }

  .sm\:max-w-screen-xl {
    max-width: 1920px
  }

  .sm\:min-h-0 {
    min-height: 0
  }

  .sm\:min-h-1 {
    min-height: 0.1rem
  }

  .sm\:min-h-2 {
    min-height: 0.2rem
  }

  .sm\:min-h-3 {
    min-height: 0.3rem
  }

  .sm\:min-h-4 {
    min-height: 0.4rem
  }

  .sm\:min-h-5 {
    min-height: 0.5rem
  }

  .sm\:min-h-6 {
    min-height: 0.6rem
  }

  .sm\:min-h-7 {
    min-height: 0.7rem
  }

  .sm\:min-h-8 {
    min-height: 0.8rem
  }

  .sm\:min-h-9 {
    min-height: 0.9rem
  }

  .sm\:min-h-10 {
    min-height: 1.0rem
  }

  .sm\:min-h-12 {
    min-height: 1.2rem
  }

  .sm\:min-h-14 {
    min-height: 1.4rem
  }

  .sm\:min-h-16 {
    min-height: 1.6rem
  }

  .sm\:min-h-20 {
    min-height: 2rem
  }

  .sm\:min-h-24 {
    min-height: 2.4rem
  }

  .sm\:min-h-28 {
    min-height: 2.8rem
  }

  .sm\:min-h-32 {
    min-height: 3.2rem
  }

  .sm\:min-h-36 {
    min-height: 3.6rem
  }

  .sm\:min-h-40 {
    min-height: 4rem
  }

  .sm\:min-h-44 {
    min-height: 4.4rem
  }

  .sm\:min-h-48 {
    min-height: 4.8rem
  }

  .sm\:min-h-52 {
    min-height: 5.2rem
  }

  .sm\:min-h-56 {
    min-height: 5.6rem
  }

  .sm\:min-h-60 {
    min-height: 6rem
  }

  .sm\:min-h-64 {
    min-height: 6.4rem
  }

  .sm\:min-h-68 {
    min-height: 6.8rem
  }

  .sm\:min-h-72 {
    min-height: 7.2rem
  }

  .sm\:min-h-76 {
    min-height: 7.6rem
  }

  .sm\:min-h-80 {
    min-height: 8rem
  }

  .sm\:min-h-84 {
    min-height: 8.4rem
  }

  .sm\:min-h-88 {
    min-height: 8.8rem
  }

  .sm\:min-h-92 {
    min-height: 9.2rem
  }

  .sm\:min-h-96 {
    min-height: 9.6rem
  }

  .sm\:min-h-128 {
    min-height: 12.8rem
  }

  .sm\:min-h-136 {
    min-height: 13.6rem
  }

  .sm\:min-h-160 {
    min-height: 16rem
  }

  .sm\:min-h-192 {
    min-height: 19.2rem
  }

  .sm\:min-h-200 {
    min-height: 20rem
  }

  .sm\:min-h-208 {
    min-height: 20.8rem
  }

  .sm\:min-h-216 {
    min-height: 21.6rem
  }

  .sm\:min-h-224 {
    min-height: 22.4rem
  }

  .sm\:min-h-256 {
    min-height: 25.6rem
  }

  .sm\:min-h-288 {
    min-height: 28.8rem
  }

  .sm\:min-h-320 {
    min-height: 32rem
  }

  .sm\:min-h-360 {
    min-height: 36rem
  }

  .sm\:min-h-384 {
    min-height: 38.4rem
  }

  .sm\:min-h-400 {
    min-height: 40rem
  }

  .sm\:min-h-512 {
    min-height: 51.2rem
  }

  .sm\:min-h-640 {
    min-height: 64rem
  }

  .sm\:min-h-auto {
    min-height: auto
  }

  .sm\:min-h-xs {
    min-height: 32rem
  }

  .sm\:min-h-sm {
    min-height: 48rem
  }

  .sm\:min-h-md {
    min-height: 64rem
  }

  .sm\:min-h-lg {
    min-height: 80rem
  }

  .sm\:min-h-xl {
    min-height: 96rem
  }

  .sm\:min-h-2xl {
    min-height: 112rem
  }

  .sm\:min-h-3xl {
    min-height: 128rem
  }

  .sm\:min-h-4xl {
    min-height: 144rem
  }

  .sm\:min-h-5xl {
    min-height: 160rem
  }

  .sm\:min-h-px {
    min-height: 1px
  }

  .sm\:min-h-0\.5 {
    min-height: 0.05rem
  }

  .sm\:min-h-1\.5 {
    min-height: 0.15rem
  }

  .sm\:min-h-2\.5 {
    min-height: 0.25rem
  }

  .sm\:min-h-3\.5 {
    min-height: 0.35rem
  }

  .sm\:min-h-full {
    min-height: 100%
  }

  .sm\:min-h-screen {
    min-height: 100vh
  }

  .sm\:min-w-0 {
    min-width: 0
  }

  .sm\:min-w-1 {
    min-width: 0.1rem
  }

  .sm\:min-w-2 {
    min-width: 0.2rem
  }

  .sm\:min-w-3 {
    min-width: 0.3rem
  }

  .sm\:min-w-4 {
    min-width: 0.4rem
  }

  .sm\:min-w-5 {
    min-width: 0.5rem
  }

  .sm\:min-w-6 {
    min-width: 0.6rem
  }

  .sm\:min-w-7 {
    min-width: 0.7rem
  }

  .sm\:min-w-8 {
    min-width: 0.8rem
  }

  .sm\:min-w-9 {
    min-width: 0.9rem
  }

  .sm\:min-w-10 {
    min-width: 1.0rem
  }

  .sm\:min-w-12 {
    min-width: 1.2rem
  }

  .sm\:min-w-14 {
    min-width: 1.4rem
  }

  .sm\:min-w-16 {
    min-width: 1.6rem
  }

  .sm\:min-w-20 {
    min-width: 2rem
  }

  .sm\:min-w-24 {
    min-width: 2.4rem
  }

  .sm\:min-w-28 {
    min-width: 2.8rem
  }

  .sm\:min-w-32 {
    min-width: 3.2rem
  }

  .sm\:min-w-36 {
    min-width: 3.6rem
  }

  .sm\:min-w-40 {
    min-width: 4rem
  }

  .sm\:min-w-44 {
    min-width: 4.4rem
  }

  .sm\:min-w-48 {
    min-width: 4.8rem
  }

  .sm\:min-w-52 {
    min-width: 5.2rem
  }

  .sm\:min-w-56 {
    min-width: 5.6rem
  }

  .sm\:min-w-60 {
    min-width: 6rem
  }

  .sm\:min-w-64 {
    min-width: 6.4rem
  }

  .sm\:min-w-68 {
    min-width: 6.8rem
  }

  .sm\:min-w-72 {
    min-width: 7.2rem
  }

  .sm\:min-w-76 {
    min-width: 7.6rem
  }

  .sm\:min-w-80 {
    min-width: 8rem
  }

  .sm\:min-w-84 {
    min-width: 8.4rem
  }

  .sm\:min-w-88 {
    min-width: 8.8rem
  }

  .sm\:min-w-92 {
    min-width: 9.2rem
  }

  .sm\:min-w-96 {
    min-width: 9.6rem
  }

  .sm\:min-w-128 {
    min-width: 12.8rem
  }

  .sm\:min-w-136 {
    min-width: 13.6rem
  }

  .sm\:min-w-160 {
    min-width: 16rem
  }

  .sm\:min-w-192 {
    min-width: 19.2rem
  }

  .sm\:min-w-200 {
    min-width: 20rem
  }

  .sm\:min-w-208 {
    min-width: 20.8rem
  }

  .sm\:min-w-216 {
    min-width: 21.6rem
  }

  .sm\:min-w-224 {
    min-width: 22.4rem
  }

  .sm\:min-w-256 {
    min-width: 25.6rem
  }

  .sm\:min-w-288 {
    min-width: 28.8rem
  }

  .sm\:min-w-320 {
    min-width: 32rem
  }

  .sm\:min-w-360 {
    min-width: 36rem
  }

  .sm\:min-w-384 {
    min-width: 38.4rem
  }

  .sm\:min-w-400 {
    min-width: 40rem
  }

  .sm\:min-w-512 {
    min-width: 51.2rem
  }

  .sm\:min-w-640 {
    min-width: 64rem
  }

  .sm\:min-w-xs {
    min-width: 32rem
  }

  .sm\:min-w-sm {
    min-width: 48rem
  }

  .sm\:min-w-md {
    min-width: 64rem
  }

  .sm\:min-w-lg {
    min-width: 80rem
  }

  .sm\:min-w-xl {
    min-width: 96rem
  }

  .sm\:min-w-2xl {
    min-width: 112rem
  }

  .sm\:min-w-3xl {
    min-width: 128rem
  }

  .sm\:min-w-4xl {
    min-width: 144rem
  }

  .sm\:min-w-5xl {
    min-width: 160rem
  }

  .sm\:min-w-px {
    min-width: 1px
  }

  .sm\:min-w-0\.5 {
    min-width: 0.05rem
  }

  .sm\:min-w-1\.5 {
    min-width: 0.15rem
  }

  .sm\:min-w-2\.5 {
    min-width: 0.25rem
  }

  .sm\:min-w-3\.5 {
    min-width: 0.35rem
  }

  .sm\:min-w-full {
    min-width: 100%
  }

  .sm\:min-w-min {
    min-width: min-content
  }

  .sm\:min-w-max {
    min-width: max-content
  }

  .sm\:min-w-screen {
    min-width: 100vw
  }

  .sm\:object-contain {
    object-fit: contain
  }

  .sm\:object-cover {
    object-fit: cover
  }

  .sm\:object-fill {
    object-fit: fill
  }

  .sm\:object-none {
    object-fit: none
  }

  .sm\:object-scale-down {
    object-fit: scale-down
  }

  .sm\:object-bottom {
    object-position: bottom
  }

  .sm\:object-center {
    object-position: center
  }

  .sm\:object-left {
    object-position: left
  }

  .sm\:object-left-bottom {
    object-position: left bottom
  }

  .sm\:object-left-top {
    object-position: left top
  }

  .sm\:object-right {
    object-position: right
  }

  .sm\:object-right-bottom {
    object-position: right bottom
  }

  .sm\:object-right-top {
    object-position: right top
  }

  .sm\:object-top {
    object-position: top
  }

  .sm\:opacity-0 {
    opacity: 0
  }

  .sm\:opacity-5 {
    opacity: 0.05
  }

  .sm\:opacity-10 {
    opacity: 0.1
  }

  .sm\:opacity-20 {
    opacity: 0.2
  }

  .sm\:opacity-25 {
    opacity: 0.25
  }

  .sm\:opacity-30 {
    opacity: 0.3
  }

  .sm\:opacity-40 {
    opacity: 0.4
  }

  .sm\:opacity-50 {
    opacity: 0.5
  }

  .sm\:opacity-60 {
    opacity: 0.6
  }

  .sm\:opacity-70 {
    opacity: 0.7
  }

  .sm\:opacity-75 {
    opacity: 0.75
  }

  .sm\:opacity-80 {
    opacity: 0.8
  }

  .sm\:opacity-90 {
    opacity: 0.9
  }

  .sm\:opacity-95 {
    opacity: 0.95
  }

  .sm\:opacity-100 {
    opacity: 1
  }

  .group:hover .sm\:group-hover\:opacity-0 {
    opacity: 0
  }

  .group:hover .sm\:group-hover\:opacity-5 {
    opacity: 0.05
  }

  .group:hover .sm\:group-hover\:opacity-10 {
    opacity: 0.1
  }

  .group:hover .sm\:group-hover\:opacity-20 {
    opacity: 0.2
  }

  .group:hover .sm\:group-hover\:opacity-25 {
    opacity: 0.25
  }

  .group:hover .sm\:group-hover\:opacity-30 {
    opacity: 0.3
  }

  .group:hover .sm\:group-hover\:opacity-40 {
    opacity: 0.4
  }

  .group:hover .sm\:group-hover\:opacity-50 {
    opacity: 0.5
  }

  .group:hover .sm\:group-hover\:opacity-60 {
    opacity: 0.6
  }

  .group:hover .sm\:group-hover\:opacity-70 {
    opacity: 0.7
  }

  .group:hover .sm\:group-hover\:opacity-75 {
    opacity: 0.75
  }

  .group:hover .sm\:group-hover\:opacity-80 {
    opacity: 0.8
  }

  .group:hover .sm\:group-hover\:opacity-90 {
    opacity: 0.9
  }

  .group:hover .sm\:group-hover\:opacity-95 {
    opacity: 0.95
  }

  .group:hover .sm\:group-hover\:opacity-100 {
    opacity: 1
  }

  .sm\:focus-within\:opacity-0:focus-within {
    opacity: 0
  }

  .sm\:focus-within\:opacity-5:focus-within {
    opacity: 0.05
  }

  .sm\:focus-within\:opacity-10:focus-within {
    opacity: 0.1
  }

  .sm\:focus-within\:opacity-20:focus-within {
    opacity: 0.2
  }

  .sm\:focus-within\:opacity-25:focus-within {
    opacity: 0.25
  }

  .sm\:focus-within\:opacity-30:focus-within {
    opacity: 0.3
  }

  .sm\:focus-within\:opacity-40:focus-within {
    opacity: 0.4
  }

  .sm\:focus-within\:opacity-50:focus-within {
    opacity: 0.5
  }

  .sm\:focus-within\:opacity-60:focus-within {
    opacity: 0.6
  }

  .sm\:focus-within\:opacity-70:focus-within {
    opacity: 0.7
  }

  .sm\:focus-within\:opacity-75:focus-within {
    opacity: 0.75
  }

  .sm\:focus-within\:opacity-80:focus-within {
    opacity: 0.8
  }

  .sm\:focus-within\:opacity-90:focus-within {
    opacity: 0.9
  }

  .sm\:focus-within\:opacity-95:focus-within {
    opacity: 0.95
  }

  .sm\:focus-within\:opacity-100:focus-within {
    opacity: 1
  }

  .sm\:hover\:opacity-0:hover {
    opacity: 0
  }

  .sm\:hover\:opacity-5:hover {
    opacity: 0.05
  }

  .sm\:hover\:opacity-10:hover {
    opacity: 0.1
  }

  .sm\:hover\:opacity-20:hover {
    opacity: 0.2
  }

  .sm\:hover\:opacity-25:hover {
    opacity: 0.25
  }

  .sm\:hover\:opacity-30:hover {
    opacity: 0.3
  }

  .sm\:hover\:opacity-40:hover {
    opacity: 0.4
  }

  .sm\:hover\:opacity-50:hover {
    opacity: 0.5
  }

  .sm\:hover\:opacity-60:hover {
    opacity: 0.6
  }

  .sm\:hover\:opacity-70:hover {
    opacity: 0.7
  }

  .sm\:hover\:opacity-75:hover {
    opacity: 0.75
  }

  .sm\:hover\:opacity-80:hover {
    opacity: 0.8
  }

  .sm\:hover\:opacity-90:hover {
    opacity: 0.9
  }

  .sm\:hover\:opacity-95:hover {
    opacity: 0.95
  }

  .sm\:hover\:opacity-100:hover {
    opacity: 1
  }

  .sm\:focus\:opacity-0:focus {
    opacity: 0
  }

  .sm\:focus\:opacity-5:focus {
    opacity: 0.05
  }

  .sm\:focus\:opacity-10:focus {
    opacity: 0.1
  }

  .sm\:focus\:opacity-20:focus {
    opacity: 0.2
  }

  .sm\:focus\:opacity-25:focus {
    opacity: 0.25
  }

  .sm\:focus\:opacity-30:focus {
    opacity: 0.3
  }

  .sm\:focus\:opacity-40:focus {
    opacity: 0.4
  }

  .sm\:focus\:opacity-50:focus {
    opacity: 0.5
  }

  .sm\:focus\:opacity-60:focus {
    opacity: 0.6
  }

  .sm\:focus\:opacity-70:focus {
    opacity: 0.7
  }

  .sm\:focus\:opacity-75:focus {
    opacity: 0.75
  }

  .sm\:focus\:opacity-80:focus {
    opacity: 0.8
  }

  .sm\:focus\:opacity-90:focus {
    opacity: 0.9
  }

  .sm\:focus\:opacity-95:focus {
    opacity: 0.95
  }

  .sm\:focus\:opacity-100:focus {
    opacity: 1
  }

  .sm\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .sm\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .sm\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .sm\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .sm\:focus-within\:outline-white:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .sm\:focus-within\:outline-black:focus-within {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .sm\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .sm\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .sm\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .sm\:overflow-auto {
    overflow: auto
  }

  .sm\:overflow-hidden {
    overflow: hidden
  }

  .sm\:overflow-visible {
    overflow: visible
  }

  .sm\:overflow-scroll {
    overflow: scroll
  }

  .sm\:overflow-x-auto {
    overflow-x: auto
  }

  .sm\:overflow-y-auto {
    overflow-y: auto
  }

  .sm\:overflow-x-hidden {
    overflow-x: hidden
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden
  }

  .sm\:overflow-x-visible {
    overflow-x: visible
  }

  .sm\:overflow-y-visible {
    overflow-y: visible
  }

  .sm\:overflow-x-scroll {
    overflow-x: scroll
  }

  .sm\:overflow-y-scroll {
    overflow-y: scroll
  }

  .sm\:overscroll-auto {
    overscroll-behavior: auto
  }

  .sm\:overscroll-contain {
    overscroll-behavior: contain
  }

  .sm\:overscroll-none {
    overscroll-behavior: none
  }

  .sm\:overscroll-y-auto {
    overscroll-behavior-y: auto
  }

  .sm\:overscroll-y-contain {
    overscroll-behavior-y: contain
  }

  .sm\:overscroll-y-none {
    overscroll-behavior-y: none
  }

  .sm\:overscroll-x-auto {
    overscroll-behavior-x: auto
  }

  .sm\:overscroll-x-contain {
    overscroll-behavior-x: contain
  }

  .sm\:overscroll-x-none {
    overscroll-behavior-x: none
  }

  .sm\:p-0 {
    padding: 0
  }

  .sm\:p-1 {
    padding: 0.1rem
  }

  .sm\:p-2 {
    padding: 0.2rem
  }

  .sm\:p-3 {
    padding: 0.3rem
  }

  .sm\:p-4 {
    padding: 0.4rem
  }

  .sm\:p-5 {
    padding: 0.5rem
  }

  .sm\:p-6 {
    padding: 0.6rem
  }

  .sm\:p-7 {
    padding: 0.7rem
  }

  .sm\:p-8 {
    padding: 0.8rem
  }

  .sm\:p-9 {
    padding: 0.9rem
  }

  .sm\:p-10 {
    padding: 1.0rem
  }

  .sm\:p-12 {
    padding: 1.2rem
  }

  .sm\:p-14 {
    padding: 1.4rem
  }

  .sm\:p-16 {
    padding: 1.6rem
  }

  .sm\:p-20 {
    padding: 2rem
  }

  .sm\:p-24 {
    padding: 2.4rem
  }

  .sm\:p-28 {
    padding: 2.8rem
  }

  .sm\:p-32 {
    padding: 3.2rem
  }

  .sm\:p-36 {
    padding: 3.6rem
  }

  .sm\:p-40 {
    padding: 4rem
  }

  .sm\:p-44 {
    padding: 4.4rem
  }

  .sm\:p-48 {
    padding: 4.8rem
  }

  .sm\:p-52 {
    padding: 5.2rem
  }

  .sm\:p-56 {
    padding: 5.6rem
  }

  .sm\:p-60 {
    padding: 6rem
  }

  .sm\:p-64 {
    padding: 6.4rem
  }

  .sm\:p-68 {
    padding: 6.8rem
  }

  .sm\:p-72 {
    padding: 7.2rem
  }

  .sm\:p-76 {
    padding: 7.6rem
  }

  .sm\:p-80 {
    padding: 8rem
  }

  .sm\:p-84 {
    padding: 8.4rem
  }

  .sm\:p-88 {
    padding: 8.8rem
  }

  .sm\:p-92 {
    padding: 9.2rem
  }

  .sm\:p-96 {
    padding: 9.6rem
  }

  .sm\:p-128 {
    padding: 12.8rem
  }

  .sm\:p-136 {
    padding: 13.6rem
  }

  .sm\:p-160 {
    padding: 16rem
  }

  .sm\:p-192 {
    padding: 19.2rem
  }

  .sm\:p-200 {
    padding: 20rem
  }

  .sm\:p-208 {
    padding: 20.8rem
  }

  .sm\:p-216 {
    padding: 21.6rem
  }

  .sm\:p-224 {
    padding: 22.4rem
  }

  .sm\:p-256 {
    padding: 25.6rem
  }

  .sm\:p-288 {
    padding: 28.8rem
  }

  .sm\:p-320 {
    padding: 32rem
  }

  .sm\:p-360 {
    padding: 36rem
  }

  .sm\:p-384 {
    padding: 38.4rem
  }

  .sm\:p-400 {
    padding: 40rem
  }

  .sm\:p-512 {
    padding: 51.2rem
  }

  .sm\:p-640 {
    padding: 64rem
  }

  .sm\:p-xs {
    padding: 32rem
  }

  .sm\:p-sm {
    padding: 48rem
  }

  .sm\:p-md {
    padding: 64rem
  }

  .sm\:p-lg {
    padding: 80rem
  }

  .sm\:p-xl {
    padding: 96rem
  }

  .sm\:p-2xl {
    padding: 112rem
  }

  .sm\:p-3xl {
    padding: 128rem
  }

  .sm\:p-4xl {
    padding: 144rem
  }

  .sm\:p-5xl {
    padding: 160rem
  }

  .sm\:p-px {
    padding: 1px
  }

  .sm\:p-0\.5 {
    padding: 0.05rem
  }

  .sm\:p-1\.5 {
    padding: 0.15rem
  }

  .sm\:p-2\.5 {
    padding: 0.25rem
  }

  .sm\:p-3\.5 {
    padding: 0.35rem
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .sm\:py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem
  }

  .sm\:px-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem
  }

  .sm\:py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem
  }

  .sm\:px-2 {
    padding-left: 0.2rem;
    padding-right: 0.2rem
  }

  .sm\:py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem
  }

  .sm\:px-3 {
    padding-left: 0.3rem;
    padding-right: 0.3rem
  }

  .sm\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  .sm\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  .sm\:py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .sm\:px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .sm\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  .sm\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  .sm\:py-7 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem
  }

  .sm\:px-7 {
    padding-left: 0.7rem;
    padding-right: 0.7rem
  }

  .sm\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  .sm\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  .sm\:py-9 {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem
  }

  .sm\:px-9 {
    padding-left: 0.9rem;
    padding-right: 0.9rem
  }

  .sm\:py-10 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
  }

  .sm\:px-10 {
    padding-left: 1.0rem;
    padding-right: 1.0rem
  }

  .sm\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  .sm\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .sm\:py-14 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem
  }

  .sm\:px-14 {
    padding-left: 1.4rem;
    padding-right: 1.4rem
  }

  .sm\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  .sm\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  .sm\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .sm\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .sm\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  .sm\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .sm\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  .sm\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  .sm\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  .sm\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  .sm\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  .sm\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  .sm\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .sm\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .sm\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  .sm\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  .sm\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  .sm\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  .sm\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  .sm\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  .sm\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  .sm\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  .sm\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .sm\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .sm\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  .sm\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  .sm\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  .sm\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  .sm\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  .sm\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  .sm\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  .sm\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  .sm\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .sm\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .sm\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  .sm\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  .sm\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  .sm\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  .sm\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  .sm\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  .sm\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  .sm\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  .sm\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  .sm\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  .sm\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  .sm\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  .sm\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .sm\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .sm\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  .sm\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  .sm\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  .sm\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  .sm\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  .sm\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  .sm\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  .sm\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  .sm\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  .sm\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  .sm\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  .sm\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  .sm\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  .sm\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  .sm\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  .sm\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  .sm\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  .sm\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  .sm\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  .sm\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  .sm\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  .sm\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  .sm\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  .sm\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  .sm\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  .sm\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  .sm\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  .sm\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  .sm\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  .sm\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  .sm\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  .sm\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  .sm\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  .sm\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  .sm\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  .sm\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  .sm\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  .sm\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  .sm\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  .sm\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  .sm\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  .sm\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  .sm\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  .sm\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  .sm\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .sm\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .sm\:py-0\.5 {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem
  }

  .sm\:px-0\.5 {
    padding-left: 0.05rem;
    padding-right: 0.05rem
  }

  .sm\:py-1\.5 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem
  }

  .sm\:px-1\.5 {
    padding-left: 0.15rem;
    padding-right: 0.15rem
  }

  .sm\:py-2\.5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .sm\:px-2\.5 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .sm\:py-3\.5 {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem
  }

  .sm\:px-3\.5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem
  }

  .sm\:pt-0 {
    padding-top: 0
  }

  .sm\:pr-0 {
    padding-right: 0
  }

  .sm\:pb-0 {
    padding-bottom: 0
  }

  .sm\:pl-0 {
    padding-left: 0
  }

  .sm\:pt-1 {
    padding-top: 0.1rem
  }

  .sm\:pr-1 {
    padding-right: 0.1rem
  }

  .sm\:pb-1 {
    padding-bottom: 0.1rem
  }

  .sm\:pl-1 {
    padding-left: 0.1rem
  }

  .sm\:pt-2 {
    padding-top: 0.2rem
  }

  .sm\:pr-2 {
    padding-right: 0.2rem
  }

  .sm\:pb-2 {
    padding-bottom: 0.2rem
  }

  .sm\:pl-2 {
    padding-left: 0.2rem
  }

  .sm\:pt-3 {
    padding-top: 0.3rem
  }

  .sm\:pr-3 {
    padding-right: 0.3rem
  }

  .sm\:pb-3 {
    padding-bottom: 0.3rem
  }

  .sm\:pl-3 {
    padding-left: 0.3rem
  }

  .sm\:pt-4 {
    padding-top: 0.4rem
  }

  .sm\:pr-4 {
    padding-right: 0.4rem
  }

  .sm\:pb-4 {
    padding-bottom: 0.4rem
  }

  .sm\:pl-4 {
    padding-left: 0.4rem
  }

  .sm\:pt-5 {
    padding-top: 0.5rem
  }

  .sm\:pr-5 {
    padding-right: 0.5rem
  }

  .sm\:pb-5 {
    padding-bottom: 0.5rem
  }

  .sm\:pl-5 {
    padding-left: 0.5rem
  }

  .sm\:pt-6 {
    padding-top: 0.6rem
  }

  .sm\:pr-6 {
    padding-right: 0.6rem
  }

  .sm\:pb-6 {
    padding-bottom: 0.6rem
  }

  .sm\:pl-6 {
    padding-left: 0.6rem
  }

  .sm\:pt-7 {
    padding-top: 0.7rem
  }

  .sm\:pr-7 {
    padding-right: 0.7rem
  }

  .sm\:pb-7 {
    padding-bottom: 0.7rem
  }

  .sm\:pl-7 {
    padding-left: 0.7rem
  }

  .sm\:pt-8 {
    padding-top: 0.8rem
  }

  .sm\:pr-8 {
    padding-right: 0.8rem
  }

  .sm\:pb-8 {
    padding-bottom: 0.8rem
  }

  .sm\:pl-8 {
    padding-left: 0.8rem
  }

  .sm\:pt-9 {
    padding-top: 0.9rem
  }

  .sm\:pr-9 {
    padding-right: 0.9rem
  }

  .sm\:pb-9 {
    padding-bottom: 0.9rem
  }

  .sm\:pl-9 {
    padding-left: 0.9rem
  }

  .sm\:pt-10 {
    padding-top: 1.0rem
  }

  .sm\:pr-10 {
    padding-right: 1.0rem
  }

  .sm\:pb-10 {
    padding-bottom: 1.0rem
  }

  .sm\:pl-10 {
    padding-left: 1.0rem
  }

  .sm\:pt-12 {
    padding-top: 1.2rem
  }

  .sm\:pr-12 {
    padding-right: 1.2rem
  }

  .sm\:pb-12 {
    padding-bottom: 1.2rem
  }

  .sm\:pl-12 {
    padding-left: 1.2rem
  }

  .sm\:pt-14 {
    padding-top: 1.4rem
  }

  .sm\:pr-14 {
    padding-right: 1.4rem
  }

  .sm\:pb-14 {
    padding-bottom: 1.4rem
  }

  .sm\:pl-14 {
    padding-left: 1.4rem
  }

  .sm\:pt-16 {
    padding-top: 1.6rem
  }

  .sm\:pr-16 {
    padding-right: 1.6rem
  }

  .sm\:pb-16 {
    padding-bottom: 1.6rem
  }

  .sm\:pl-16 {
    padding-left: 1.6rem
  }

  .sm\:pt-20 {
    padding-top: 2rem
  }

  .sm\:pr-20 {
    padding-right: 2rem
  }

  .sm\:pb-20 {
    padding-bottom: 2rem
  }

  .sm\:pl-20 {
    padding-left: 2rem
  }

  .sm\:pt-24 {
    padding-top: 2.4rem
  }

  .sm\:pr-24 {
    padding-right: 2.4rem
  }

  .sm\:pb-24 {
    padding-bottom: 2.4rem
  }

  .sm\:pl-24 {
    padding-left: 2.4rem
  }

  .sm\:pt-28 {
    padding-top: 2.8rem
  }

  .sm\:pr-28 {
    padding-right: 2.8rem
  }

  .sm\:pb-28 {
    padding-bottom: 2.8rem
  }

  .sm\:pl-28 {
    padding-left: 2.8rem
  }

  .sm\:pt-32 {
    padding-top: 3.2rem
  }

  .sm\:pr-32 {
    padding-right: 3.2rem
  }

  .sm\:pb-32 {
    padding-bottom: 3.2rem
  }

  .sm\:pl-32 {
    padding-left: 3.2rem
  }

  .sm\:pt-36 {
    padding-top: 3.6rem
  }

  .sm\:pr-36 {
    padding-right: 3.6rem
  }

  .sm\:pb-36 {
    padding-bottom: 3.6rem
  }

  .sm\:pl-36 {
    padding-left: 3.6rem
  }

  .sm\:pt-40 {
    padding-top: 4rem
  }

  .sm\:pr-40 {
    padding-right: 4rem
  }

  .sm\:pb-40 {
    padding-bottom: 4rem
  }

  .sm\:pl-40 {
    padding-left: 4rem
  }

  .sm\:pt-44 {
    padding-top: 4.4rem
  }

  .sm\:pr-44 {
    padding-right: 4.4rem
  }

  .sm\:pb-44 {
    padding-bottom: 4.4rem
  }

  .sm\:pl-44 {
    padding-left: 4.4rem
  }

  .sm\:pt-48 {
    padding-top: 4.8rem
  }

  .sm\:pr-48 {
    padding-right: 4.8rem
  }

  .sm\:pb-48 {
    padding-bottom: 4.8rem
  }

  .sm\:pl-48 {
    padding-left: 4.8rem
  }

  .sm\:pt-52 {
    padding-top: 5.2rem
  }

  .sm\:pr-52 {
    padding-right: 5.2rem
  }

  .sm\:pb-52 {
    padding-bottom: 5.2rem
  }

  .sm\:pl-52 {
    padding-left: 5.2rem
  }

  .sm\:pt-56 {
    padding-top: 5.6rem
  }

  .sm\:pr-56 {
    padding-right: 5.6rem
  }

  .sm\:pb-56 {
    padding-bottom: 5.6rem
  }

  .sm\:pl-56 {
    padding-left: 5.6rem
  }

  .sm\:pt-60 {
    padding-top: 6rem
  }

  .sm\:pr-60 {
    padding-right: 6rem
  }

  .sm\:pb-60 {
    padding-bottom: 6rem
  }

  .sm\:pl-60 {
    padding-left: 6rem
  }

  .sm\:pt-64 {
    padding-top: 6.4rem
  }

  .sm\:pr-64 {
    padding-right: 6.4rem
  }

  .sm\:pb-64 {
    padding-bottom: 6.4rem
  }

  .sm\:pl-64 {
    padding-left: 6.4rem
  }

  .sm\:pt-68 {
    padding-top: 6.8rem
  }

  .sm\:pr-68 {
    padding-right: 6.8rem
  }

  .sm\:pb-68 {
    padding-bottom: 6.8rem
  }

  .sm\:pl-68 {
    padding-left: 6.8rem
  }

  .sm\:pt-72 {
    padding-top: 7.2rem
  }

  .sm\:pr-72 {
    padding-right: 7.2rem
  }

  .sm\:pb-72 {
    padding-bottom: 7.2rem
  }

  .sm\:pl-72 {
    padding-left: 7.2rem
  }

  .sm\:pt-76 {
    padding-top: 7.6rem
  }

  .sm\:pr-76 {
    padding-right: 7.6rem
  }

  .sm\:pb-76 {
    padding-bottom: 7.6rem
  }

  .sm\:pl-76 {
    padding-left: 7.6rem
  }

  .sm\:pt-80 {
    padding-top: 8rem
  }

  .sm\:pr-80 {
    padding-right: 8rem
  }

  .sm\:pb-80 {
    padding-bottom: 8rem
  }

  .sm\:pl-80 {
    padding-left: 8rem
  }

  .sm\:pt-84 {
    padding-top: 8.4rem
  }

  .sm\:pr-84 {
    padding-right: 8.4rem
  }

  .sm\:pb-84 {
    padding-bottom: 8.4rem
  }

  .sm\:pl-84 {
    padding-left: 8.4rem
  }

  .sm\:pt-88 {
    padding-top: 8.8rem
  }

  .sm\:pr-88 {
    padding-right: 8.8rem
  }

  .sm\:pb-88 {
    padding-bottom: 8.8rem
  }

  .sm\:pl-88 {
    padding-left: 8.8rem
  }

  .sm\:pt-92 {
    padding-top: 9.2rem
  }

  .sm\:pr-92 {
    padding-right: 9.2rem
  }

  .sm\:pb-92 {
    padding-bottom: 9.2rem
  }

  .sm\:pl-92 {
    padding-left: 9.2rem
  }

  .sm\:pt-96 {
    padding-top: 9.6rem
  }

  .sm\:pr-96 {
    padding-right: 9.6rem
  }

  .sm\:pb-96 {
    padding-bottom: 9.6rem
  }

  .sm\:pl-96 {
    padding-left: 9.6rem
  }

  .sm\:pt-128 {
    padding-top: 12.8rem
  }

  .sm\:pr-128 {
    padding-right: 12.8rem
  }

  .sm\:pb-128 {
    padding-bottom: 12.8rem
  }

  .sm\:pl-128 {
    padding-left: 12.8rem
  }

  .sm\:pt-136 {
    padding-top: 13.6rem
  }

  .sm\:pr-136 {
    padding-right: 13.6rem
  }

  .sm\:pb-136 {
    padding-bottom: 13.6rem
  }

  .sm\:pl-136 {
    padding-left: 13.6rem
  }

  .sm\:pt-160 {
    padding-top: 16rem
  }

  .sm\:pr-160 {
    padding-right: 16rem
  }

  .sm\:pb-160 {
    padding-bottom: 16rem
  }

  .sm\:pl-160 {
    padding-left: 16rem
  }

  .sm\:pt-192 {
    padding-top: 19.2rem
  }

  .sm\:pr-192 {
    padding-right: 19.2rem
  }

  .sm\:pb-192 {
    padding-bottom: 19.2rem
  }

  .sm\:pl-192 {
    padding-left: 19.2rem
  }

  .sm\:pt-200 {
    padding-top: 20rem
  }

  .sm\:pr-200 {
    padding-right: 20rem
  }

  .sm\:pb-200 {
    padding-bottom: 20rem
  }

  .sm\:pl-200 {
    padding-left: 20rem
  }

  .sm\:pt-208 {
    padding-top: 20.8rem
  }

  .sm\:pr-208 {
    padding-right: 20.8rem
  }

  .sm\:pb-208 {
    padding-bottom: 20.8rem
  }

  .sm\:pl-208 {
    padding-left: 20.8rem
  }

  .sm\:pt-216 {
    padding-top: 21.6rem
  }

  .sm\:pr-216 {
    padding-right: 21.6rem
  }

  .sm\:pb-216 {
    padding-bottom: 21.6rem
  }

  .sm\:pl-216 {
    padding-left: 21.6rem
  }

  .sm\:pt-224 {
    padding-top: 22.4rem
  }

  .sm\:pr-224 {
    padding-right: 22.4rem
  }

  .sm\:pb-224 {
    padding-bottom: 22.4rem
  }

  .sm\:pl-224 {
    padding-left: 22.4rem
  }

  .sm\:pt-256 {
    padding-top: 25.6rem
  }

  .sm\:pr-256 {
    padding-right: 25.6rem
  }

  .sm\:pb-256 {
    padding-bottom: 25.6rem
  }

  .sm\:pl-256 {
    padding-left: 25.6rem
  }

  .sm\:pt-288 {
    padding-top: 28.8rem
  }

  .sm\:pr-288 {
    padding-right: 28.8rem
  }

  .sm\:pb-288 {
    padding-bottom: 28.8rem
  }

  .sm\:pl-288 {
    padding-left: 28.8rem
  }

  .sm\:pt-320 {
    padding-top: 32rem
  }

  .sm\:pr-320 {
    padding-right: 32rem
  }

  .sm\:pb-320 {
    padding-bottom: 32rem
  }

  .sm\:pl-320 {
    padding-left: 32rem
  }

  .sm\:pt-360 {
    padding-top: 36rem
  }

  .sm\:pr-360 {
    padding-right: 36rem
  }

  .sm\:pb-360 {
    padding-bottom: 36rem
  }

  .sm\:pl-360 {
    padding-left: 36rem
  }

  .sm\:pt-384 {
    padding-top: 38.4rem
  }

  .sm\:pr-384 {
    padding-right: 38.4rem
  }

  .sm\:pb-384 {
    padding-bottom: 38.4rem
  }

  .sm\:pl-384 {
    padding-left: 38.4rem
  }

  .sm\:pt-400 {
    padding-top: 40rem
  }

  .sm\:pr-400 {
    padding-right: 40rem
  }

  .sm\:pb-400 {
    padding-bottom: 40rem
  }

  .sm\:pl-400 {
    padding-left: 40rem
  }

  .sm\:pt-512 {
    padding-top: 51.2rem
  }

  .sm\:pr-512 {
    padding-right: 51.2rem
  }

  .sm\:pb-512 {
    padding-bottom: 51.2rem
  }

  .sm\:pl-512 {
    padding-left: 51.2rem
  }

  .sm\:pt-640 {
    padding-top: 64rem
  }

  .sm\:pr-640 {
    padding-right: 64rem
  }

  .sm\:pb-640 {
    padding-bottom: 64rem
  }

  .sm\:pl-640 {
    padding-left: 64rem
  }

  .sm\:pt-xs {
    padding-top: 32rem
  }

  .sm\:pr-xs {
    padding-right: 32rem
  }

  .sm\:pb-xs {
    padding-bottom: 32rem
  }

  .sm\:pl-xs {
    padding-left: 32rem
  }

  .sm\:pt-sm {
    padding-top: 48rem
  }

  .sm\:pr-sm {
    padding-right: 48rem
  }

  .sm\:pb-sm {
    padding-bottom: 48rem
  }

  .sm\:pl-sm {
    padding-left: 48rem
  }

  .sm\:pt-md {
    padding-top: 64rem
  }

  .sm\:pr-md {
    padding-right: 64rem
  }

  .sm\:pb-md {
    padding-bottom: 64rem
  }

  .sm\:pl-md {
    padding-left: 64rem
  }

  .sm\:pt-lg {
    padding-top: 80rem
  }

  .sm\:pr-lg {
    padding-right: 80rem
  }

  .sm\:pb-lg {
    padding-bottom: 80rem
  }

  .sm\:pl-lg {
    padding-left: 80rem
  }

  .sm\:pt-xl {
    padding-top: 96rem
  }

  .sm\:pr-xl {
    padding-right: 96rem
  }

  .sm\:pb-xl {
    padding-bottom: 96rem
  }

  .sm\:pl-xl {
    padding-left: 96rem
  }

  .sm\:pt-2xl {
    padding-top: 112rem
  }

  .sm\:pr-2xl {
    padding-right: 112rem
  }

  .sm\:pb-2xl {
    padding-bottom: 112rem
  }

  .sm\:pl-2xl {
    padding-left: 112rem
  }

  .sm\:pt-3xl {
    padding-top: 128rem
  }

  .sm\:pr-3xl {
    padding-right: 128rem
  }

  .sm\:pb-3xl {
    padding-bottom: 128rem
  }

  .sm\:pl-3xl {
    padding-left: 128rem
  }

  .sm\:pt-4xl {
    padding-top: 144rem
  }

  .sm\:pr-4xl {
    padding-right: 144rem
  }

  .sm\:pb-4xl {
    padding-bottom: 144rem
  }

  .sm\:pl-4xl {
    padding-left: 144rem
  }

  .sm\:pt-5xl {
    padding-top: 160rem
  }

  .sm\:pr-5xl {
    padding-right: 160rem
  }

  .sm\:pb-5xl {
    padding-bottom: 160rem
  }

  .sm\:pl-5xl {
    padding-left: 160rem
  }

  .sm\:pt-px {
    padding-top: 1px
  }

  .sm\:pr-px {
    padding-right: 1px
  }

  .sm\:pb-px {
    padding-bottom: 1px
  }

  .sm\:pl-px {
    padding-left: 1px
  }

  .sm\:pt-0\.5 {
    padding-top: 0.05rem
  }

  .sm\:pr-0\.5 {
    padding-right: 0.05rem
  }

  .sm\:pb-0\.5 {
    padding-bottom: 0.05rem
  }

  .sm\:pl-0\.5 {
    padding-left: 0.05rem
  }

  .sm\:pt-1\.5 {
    padding-top: 0.15rem
  }

  .sm\:pr-1\.5 {
    padding-right: 0.15rem
  }

  .sm\:pb-1\.5 {
    padding-bottom: 0.15rem
  }

  .sm\:pl-1\.5 {
    padding-left: 0.15rem
  }

  .sm\:pt-2\.5 {
    padding-top: 0.25rem
  }

  .sm\:pr-2\.5 {
    padding-right: 0.25rem
  }

  .sm\:pb-2\.5 {
    padding-bottom: 0.25rem
  }

  .sm\:pl-2\.5 {
    padding-left: 0.25rem
  }

  .sm\:pt-3\.5 {
    padding-top: 0.35rem
  }

  .sm\:pr-3\.5 {
    padding-right: 0.35rem
  }

  .sm\:pb-3\.5 {
    padding-bottom: 0.35rem
  }

  .sm\:pl-3\.5 {
    padding-left: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:p-0,[dir='ltr'].sm\:ltr\:p-0 {
    padding: 0
  }

  [dir='ltr'] .sm\:ltr\:p-1,[dir='ltr'].sm\:ltr\:p-1 {
    padding: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:p-2,[dir='ltr'].sm\:ltr\:p-2 {
    padding: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:p-3,[dir='ltr'].sm\:ltr\:p-3 {
    padding: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:p-4,[dir='ltr'].sm\:ltr\:p-4 {
    padding: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:p-5,[dir='ltr'].sm\:ltr\:p-5 {
    padding: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:p-6,[dir='ltr'].sm\:ltr\:p-6 {
    padding: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:p-7,[dir='ltr'].sm\:ltr\:p-7 {
    padding: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:p-8,[dir='ltr'].sm\:ltr\:p-8 {
    padding: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:p-9,[dir='ltr'].sm\:ltr\:p-9 {
    padding: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:p-10,[dir='ltr'].sm\:ltr\:p-10 {
    padding: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:p-12,[dir='ltr'].sm\:ltr\:p-12 {
    padding: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:p-14,[dir='ltr'].sm\:ltr\:p-14 {
    padding: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:p-16,[dir='ltr'].sm\:ltr\:p-16 {
    padding: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:p-20,[dir='ltr'].sm\:ltr\:p-20 {
    padding: 2rem
  }

  [dir='ltr'] .sm\:ltr\:p-24,[dir='ltr'].sm\:ltr\:p-24 {
    padding: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:p-28,[dir='ltr'].sm\:ltr\:p-28 {
    padding: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:p-32,[dir='ltr'].sm\:ltr\:p-32 {
    padding: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:p-36,[dir='ltr'].sm\:ltr\:p-36 {
    padding: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:p-40,[dir='ltr'].sm\:ltr\:p-40 {
    padding: 4rem
  }

  [dir='ltr'] .sm\:ltr\:p-44,[dir='ltr'].sm\:ltr\:p-44 {
    padding: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:p-48,[dir='ltr'].sm\:ltr\:p-48 {
    padding: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:p-52,[dir='ltr'].sm\:ltr\:p-52 {
    padding: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:p-56,[dir='ltr'].sm\:ltr\:p-56 {
    padding: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:p-60,[dir='ltr'].sm\:ltr\:p-60 {
    padding: 6rem
  }

  [dir='ltr'] .sm\:ltr\:p-64,[dir='ltr'].sm\:ltr\:p-64 {
    padding: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:p-68,[dir='ltr'].sm\:ltr\:p-68 {
    padding: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:p-72,[dir='ltr'].sm\:ltr\:p-72 {
    padding: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:p-76,[dir='ltr'].sm\:ltr\:p-76 {
    padding: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:p-80,[dir='ltr'].sm\:ltr\:p-80 {
    padding: 8rem
  }

  [dir='ltr'] .sm\:ltr\:p-84,[dir='ltr'].sm\:ltr\:p-84 {
    padding: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:p-88,[dir='ltr'].sm\:ltr\:p-88 {
    padding: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:p-92,[dir='ltr'].sm\:ltr\:p-92 {
    padding: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:p-96,[dir='ltr'].sm\:ltr\:p-96 {
    padding: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:p-128,[dir='ltr'].sm\:ltr\:p-128 {
    padding: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:p-136,[dir='ltr'].sm\:ltr\:p-136 {
    padding: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:p-160,[dir='ltr'].sm\:ltr\:p-160 {
    padding: 16rem
  }

  [dir='ltr'] .sm\:ltr\:p-192,[dir='ltr'].sm\:ltr\:p-192 {
    padding: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:p-200,[dir='ltr'].sm\:ltr\:p-200 {
    padding: 20rem
  }

  [dir='ltr'] .sm\:ltr\:p-208,[dir='ltr'].sm\:ltr\:p-208 {
    padding: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:p-216,[dir='ltr'].sm\:ltr\:p-216 {
    padding: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:p-224,[dir='ltr'].sm\:ltr\:p-224 {
    padding: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:p-256,[dir='ltr'].sm\:ltr\:p-256 {
    padding: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:p-288,[dir='ltr'].sm\:ltr\:p-288 {
    padding: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:p-320,[dir='ltr'].sm\:ltr\:p-320 {
    padding: 32rem
  }

  [dir='ltr'] .sm\:ltr\:p-360,[dir='ltr'].sm\:ltr\:p-360 {
    padding: 36rem
  }

  [dir='ltr'] .sm\:ltr\:p-384,[dir='ltr'].sm\:ltr\:p-384 {
    padding: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:p-400,[dir='ltr'].sm\:ltr\:p-400 {
    padding: 40rem
  }

  [dir='ltr'] .sm\:ltr\:p-512,[dir='ltr'].sm\:ltr\:p-512 {
    padding: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:p-640,[dir='ltr'].sm\:ltr\:p-640 {
    padding: 64rem
  }

  [dir='ltr'] .sm\:ltr\:p-xs,[dir='ltr'].sm\:ltr\:p-xs {
    padding: 32rem
  }

  [dir='ltr'] .sm\:ltr\:p-sm,[dir='ltr'].sm\:ltr\:p-sm {
    padding: 48rem
  }

  [dir='ltr'] .sm\:ltr\:p-md,[dir='ltr'].sm\:ltr\:p-md {
    padding: 64rem
  }

  [dir='ltr'] .sm\:ltr\:p-lg,[dir='ltr'].sm\:ltr\:p-lg {
    padding: 80rem
  }

  [dir='ltr'] .sm\:ltr\:p-xl,[dir='ltr'].sm\:ltr\:p-xl {
    padding: 96rem
  }

  [dir='ltr'] .sm\:ltr\:p-2xl,[dir='ltr'].sm\:ltr\:p-2xl {
    padding: 112rem
  }

  [dir='ltr'] .sm\:ltr\:p-3xl,[dir='ltr'].sm\:ltr\:p-3xl {
    padding: 128rem
  }

  [dir='ltr'] .sm\:ltr\:p-4xl,[dir='ltr'].sm\:ltr\:p-4xl {
    padding: 144rem
  }

  [dir='ltr'] .sm\:ltr\:p-5xl,[dir='ltr'].sm\:ltr\:p-5xl {
    padding: 160rem
  }

  [dir='ltr'] .sm\:ltr\:p-px,[dir='ltr'].sm\:ltr\:p-px {
    padding: 1px
  }

  [dir='ltr'] .sm\:ltr\:p-0\.5,[dir='ltr'].sm\:ltr\:p-0\.5 {
    padding: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:p-1\.5,[dir='ltr'].sm\:ltr\:p-1\.5 {
    padding: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:p-2\.5,[dir='ltr'].sm\:ltr\:p-2\.5 {
    padding: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:p-3\.5,[dir='ltr'].sm\:ltr\:p-3\.5 {
    padding: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:py-0,[dir='ltr'].sm\:ltr\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  [dir='ltr'] .sm\:ltr\:px-0,[dir='ltr'].sm\:ltr\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  [dir='ltr'] .sm\:ltr\:py-1,[dir='ltr'].sm\:ltr\:py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:px-1,[dir='ltr'].sm\:ltr\:px-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:py-2,[dir='ltr'].sm\:ltr\:py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:px-2,[dir='ltr'].sm\:ltr\:px-2 {
    padding-left: 0.2rem;
    padding-right: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:py-3,[dir='ltr'].sm\:ltr\:py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:px-3,[dir='ltr'].sm\:ltr\:px-3 {
    padding-left: 0.3rem;
    padding-right: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:py-4,[dir='ltr'].sm\:ltr\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:px-4,[dir='ltr'].sm\:ltr\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:py-5,[dir='ltr'].sm\:ltr\:py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:px-5,[dir='ltr'].sm\:ltr\:px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:py-6,[dir='ltr'].sm\:ltr\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:px-6,[dir='ltr'].sm\:ltr\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:py-7,[dir='ltr'].sm\:ltr\:py-7 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:px-7,[dir='ltr'].sm\:ltr\:px-7 {
    padding-left: 0.7rem;
    padding-right: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:py-8,[dir='ltr'].sm\:ltr\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:px-8,[dir='ltr'].sm\:ltr\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:py-9,[dir='ltr'].sm\:ltr\:py-9 {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:px-9,[dir='ltr'].sm\:ltr\:px-9 {
    padding-left: 0.9rem;
    padding-right: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:py-10,[dir='ltr'].sm\:ltr\:py-10 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:px-10,[dir='ltr'].sm\:ltr\:px-10 {
    padding-left: 1.0rem;
    padding-right: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:py-12,[dir='ltr'].sm\:ltr\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:px-12,[dir='ltr'].sm\:ltr\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:py-14,[dir='ltr'].sm\:ltr\:py-14 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:px-14,[dir='ltr'].sm\:ltr\:px-14 {
    padding-left: 1.4rem;
    padding-right: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:py-16,[dir='ltr'].sm\:ltr\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:px-16,[dir='ltr'].sm\:ltr\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:py-20,[dir='ltr'].sm\:ltr\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  [dir='ltr'] .sm\:ltr\:px-20,[dir='ltr'].sm\:ltr\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  [dir='ltr'] .sm\:ltr\:py-24,[dir='ltr'].sm\:ltr\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:px-24,[dir='ltr'].sm\:ltr\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:py-28,[dir='ltr'].sm\:ltr\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:px-28,[dir='ltr'].sm\:ltr\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:py-32,[dir='ltr'].sm\:ltr\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:px-32,[dir='ltr'].sm\:ltr\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:py-36,[dir='ltr'].sm\:ltr\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:px-36,[dir='ltr'].sm\:ltr\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:py-40,[dir='ltr'].sm\:ltr\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  [dir='ltr'] .sm\:ltr\:px-40,[dir='ltr'].sm\:ltr\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  [dir='ltr'] .sm\:ltr\:py-44,[dir='ltr'].sm\:ltr\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:px-44,[dir='ltr'].sm\:ltr\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:py-48,[dir='ltr'].sm\:ltr\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:px-48,[dir='ltr'].sm\:ltr\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:py-52,[dir='ltr'].sm\:ltr\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:px-52,[dir='ltr'].sm\:ltr\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:py-56,[dir='ltr'].sm\:ltr\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:px-56,[dir='ltr'].sm\:ltr\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:py-60,[dir='ltr'].sm\:ltr\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  [dir='ltr'] .sm\:ltr\:px-60,[dir='ltr'].sm\:ltr\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  [dir='ltr'] .sm\:ltr\:py-64,[dir='ltr'].sm\:ltr\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:px-64,[dir='ltr'].sm\:ltr\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:py-68,[dir='ltr'].sm\:ltr\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:px-68,[dir='ltr'].sm\:ltr\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:py-72,[dir='ltr'].sm\:ltr\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:px-72,[dir='ltr'].sm\:ltr\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:py-76,[dir='ltr'].sm\:ltr\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:px-76,[dir='ltr'].sm\:ltr\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:py-80,[dir='ltr'].sm\:ltr\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  [dir='ltr'] .sm\:ltr\:px-80,[dir='ltr'].sm\:ltr\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  [dir='ltr'] .sm\:ltr\:py-84,[dir='ltr'].sm\:ltr\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:px-84,[dir='ltr'].sm\:ltr\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:py-88,[dir='ltr'].sm\:ltr\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:px-88,[dir='ltr'].sm\:ltr\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:py-92,[dir='ltr'].sm\:ltr\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:px-92,[dir='ltr'].sm\:ltr\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:py-96,[dir='ltr'].sm\:ltr\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:px-96,[dir='ltr'].sm\:ltr\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:py-128,[dir='ltr'].sm\:ltr\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:px-128,[dir='ltr'].sm\:ltr\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:py-136,[dir='ltr'].sm\:ltr\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:px-136,[dir='ltr'].sm\:ltr\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:py-160,[dir='ltr'].sm\:ltr\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  [dir='ltr'] .sm\:ltr\:px-160,[dir='ltr'].sm\:ltr\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  [dir='ltr'] .sm\:ltr\:py-192,[dir='ltr'].sm\:ltr\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:px-192,[dir='ltr'].sm\:ltr\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:py-200,[dir='ltr'].sm\:ltr\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  [dir='ltr'] .sm\:ltr\:px-200,[dir='ltr'].sm\:ltr\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  [dir='ltr'] .sm\:ltr\:py-208,[dir='ltr'].sm\:ltr\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:px-208,[dir='ltr'].sm\:ltr\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:py-216,[dir='ltr'].sm\:ltr\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:px-216,[dir='ltr'].sm\:ltr\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:py-224,[dir='ltr'].sm\:ltr\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:px-224,[dir='ltr'].sm\:ltr\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:py-256,[dir='ltr'].sm\:ltr\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:px-256,[dir='ltr'].sm\:ltr\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:py-288,[dir='ltr'].sm\:ltr\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:px-288,[dir='ltr'].sm\:ltr\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:py-320,[dir='ltr'].sm\:ltr\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='ltr'] .sm\:ltr\:px-320,[dir='ltr'].sm\:ltr\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='ltr'] .sm\:ltr\:py-360,[dir='ltr'].sm\:ltr\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  [dir='ltr'] .sm\:ltr\:px-360,[dir='ltr'].sm\:ltr\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  [dir='ltr'] .sm\:ltr\:py-384,[dir='ltr'].sm\:ltr\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:px-384,[dir='ltr'].sm\:ltr\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:py-400,[dir='ltr'].sm\:ltr\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  [dir='ltr'] .sm\:ltr\:px-400,[dir='ltr'].sm\:ltr\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  [dir='ltr'] .sm\:ltr\:py-512,[dir='ltr'].sm\:ltr\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:px-512,[dir='ltr'].sm\:ltr\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:py-640,[dir='ltr'].sm\:ltr\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='ltr'] .sm\:ltr\:px-640,[dir='ltr'].sm\:ltr\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='ltr'] .sm\:ltr\:py-xs,[dir='ltr'].sm\:ltr\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='ltr'] .sm\:ltr\:px-xs,[dir='ltr'].sm\:ltr\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='ltr'] .sm\:ltr\:py-sm,[dir='ltr'].sm\:ltr\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  [dir='ltr'] .sm\:ltr\:px-sm,[dir='ltr'].sm\:ltr\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  [dir='ltr'] .sm\:ltr\:py-md,[dir='ltr'].sm\:ltr\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='ltr'] .sm\:ltr\:px-md,[dir='ltr'].sm\:ltr\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='ltr'] .sm\:ltr\:py-lg,[dir='ltr'].sm\:ltr\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  [dir='ltr'] .sm\:ltr\:px-lg,[dir='ltr'].sm\:ltr\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  [dir='ltr'] .sm\:ltr\:py-xl,[dir='ltr'].sm\:ltr\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  [dir='ltr'] .sm\:ltr\:px-xl,[dir='ltr'].sm\:ltr\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  [dir='ltr'] .sm\:ltr\:py-2xl,[dir='ltr'].sm\:ltr\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  [dir='ltr'] .sm\:ltr\:px-2xl,[dir='ltr'].sm\:ltr\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  [dir='ltr'] .sm\:ltr\:py-3xl,[dir='ltr'].sm\:ltr\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  [dir='ltr'] .sm\:ltr\:px-3xl,[dir='ltr'].sm\:ltr\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  [dir='ltr'] .sm\:ltr\:py-4xl,[dir='ltr'].sm\:ltr\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  [dir='ltr'] .sm\:ltr\:px-4xl,[dir='ltr'].sm\:ltr\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  [dir='ltr'] .sm\:ltr\:py-5xl,[dir='ltr'].sm\:ltr\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  [dir='ltr'] .sm\:ltr\:px-5xl,[dir='ltr'].sm\:ltr\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  [dir='ltr'] .sm\:ltr\:py-px,[dir='ltr'].sm\:ltr\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  [dir='ltr'] .sm\:ltr\:px-px,[dir='ltr'].sm\:ltr\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  [dir='ltr'] .sm\:ltr\:py-0\.5,[dir='ltr'].sm\:ltr\:py-0\.5 {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:px-0\.5,[dir='ltr'].sm\:ltr\:px-0\.5 {
    padding-left: 0.05rem;
    padding-right: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:py-1\.5,[dir='ltr'].sm\:ltr\:py-1\.5 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:px-1\.5,[dir='ltr'].sm\:ltr\:px-1\.5 {
    padding-left: 0.15rem;
    padding-right: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:py-2\.5,[dir='ltr'].sm\:ltr\:py-2\.5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:px-2\.5,[dir='ltr'].sm\:ltr\:px-2\.5 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:py-3\.5,[dir='ltr'].sm\:ltr\:py-3\.5 {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:px-3\.5,[dir='ltr'].sm\:ltr\:px-3\.5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:pt-0,[dir='ltr'].sm\:ltr\:pt-0 {
    padding-top: 0
  }

  [dir='ltr'] .sm\:ltr\:pr-0,[dir='ltr'].sm\:ltr\:pr-0 {
    padding-right: 0
  }

  [dir='ltr'] .sm\:ltr\:pb-0,[dir='ltr'].sm\:ltr\:pb-0 {
    padding-bottom: 0
  }

  [dir='ltr'] .sm\:ltr\:pl-0,[dir='ltr'].sm\:ltr\:pl-0 {
    padding-left: 0
  }

  [dir='ltr'] .sm\:ltr\:pt-1,[dir='ltr'].sm\:ltr\:pt-1 {
    padding-top: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:pr-1,[dir='ltr'].sm\:ltr\:pr-1 {
    padding-right: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:pb-1,[dir='ltr'].sm\:ltr\:pb-1 {
    padding-bottom: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:pl-1,[dir='ltr'].sm\:ltr\:pl-1 {
    padding-left: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:pt-2,[dir='ltr'].sm\:ltr\:pt-2 {
    padding-top: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:pr-2,[dir='ltr'].sm\:ltr\:pr-2 {
    padding-right: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:pb-2,[dir='ltr'].sm\:ltr\:pb-2 {
    padding-bottom: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:pl-2,[dir='ltr'].sm\:ltr\:pl-2 {
    padding-left: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:pt-3,[dir='ltr'].sm\:ltr\:pt-3 {
    padding-top: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:pr-3,[dir='ltr'].sm\:ltr\:pr-3 {
    padding-right: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:pb-3,[dir='ltr'].sm\:ltr\:pb-3 {
    padding-bottom: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:pl-3,[dir='ltr'].sm\:ltr\:pl-3 {
    padding-left: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:pt-4,[dir='ltr'].sm\:ltr\:pt-4 {
    padding-top: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:pr-4,[dir='ltr'].sm\:ltr\:pr-4 {
    padding-right: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:pb-4,[dir='ltr'].sm\:ltr\:pb-4 {
    padding-bottom: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:pl-4,[dir='ltr'].sm\:ltr\:pl-4 {
    padding-left: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:pt-5,[dir='ltr'].sm\:ltr\:pt-5 {
    padding-top: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:pr-5,[dir='ltr'].sm\:ltr\:pr-5 {
    padding-right: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:pb-5,[dir='ltr'].sm\:ltr\:pb-5 {
    padding-bottom: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:pl-5,[dir='ltr'].sm\:ltr\:pl-5 {
    padding-left: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:pt-6,[dir='ltr'].sm\:ltr\:pt-6 {
    padding-top: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:pr-6,[dir='ltr'].sm\:ltr\:pr-6 {
    padding-right: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:pb-6,[dir='ltr'].sm\:ltr\:pb-6 {
    padding-bottom: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:pl-6,[dir='ltr'].sm\:ltr\:pl-6 {
    padding-left: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:pt-7,[dir='ltr'].sm\:ltr\:pt-7 {
    padding-top: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:pr-7,[dir='ltr'].sm\:ltr\:pr-7 {
    padding-right: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:pb-7,[dir='ltr'].sm\:ltr\:pb-7 {
    padding-bottom: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:pl-7,[dir='ltr'].sm\:ltr\:pl-7 {
    padding-left: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:pt-8,[dir='ltr'].sm\:ltr\:pt-8 {
    padding-top: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:pr-8,[dir='ltr'].sm\:ltr\:pr-8 {
    padding-right: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:pb-8,[dir='ltr'].sm\:ltr\:pb-8 {
    padding-bottom: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:pl-8,[dir='ltr'].sm\:ltr\:pl-8 {
    padding-left: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:pt-9,[dir='ltr'].sm\:ltr\:pt-9 {
    padding-top: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:pr-9,[dir='ltr'].sm\:ltr\:pr-9 {
    padding-right: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:pb-9,[dir='ltr'].sm\:ltr\:pb-9 {
    padding-bottom: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:pl-9,[dir='ltr'].sm\:ltr\:pl-9 {
    padding-left: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:pt-10,[dir='ltr'].sm\:ltr\:pt-10 {
    padding-top: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:pr-10,[dir='ltr'].sm\:ltr\:pr-10 {
    padding-right: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:pb-10,[dir='ltr'].sm\:ltr\:pb-10 {
    padding-bottom: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:pl-10,[dir='ltr'].sm\:ltr\:pl-10 {
    padding-left: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:pt-12,[dir='ltr'].sm\:ltr\:pt-12 {
    padding-top: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:pr-12,[dir='ltr'].sm\:ltr\:pr-12 {
    padding-right: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:pb-12,[dir='ltr'].sm\:ltr\:pb-12 {
    padding-bottom: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:pl-12,[dir='ltr'].sm\:ltr\:pl-12 {
    padding-left: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:pt-14,[dir='ltr'].sm\:ltr\:pt-14 {
    padding-top: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:pr-14,[dir='ltr'].sm\:ltr\:pr-14 {
    padding-right: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:pb-14,[dir='ltr'].sm\:ltr\:pb-14 {
    padding-bottom: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:pl-14,[dir='ltr'].sm\:ltr\:pl-14 {
    padding-left: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:pt-16,[dir='ltr'].sm\:ltr\:pt-16 {
    padding-top: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:pr-16,[dir='ltr'].sm\:ltr\:pr-16 {
    padding-right: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:pb-16,[dir='ltr'].sm\:ltr\:pb-16 {
    padding-bottom: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:pl-16,[dir='ltr'].sm\:ltr\:pl-16 {
    padding-left: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:pt-20,[dir='ltr'].sm\:ltr\:pt-20 {
    padding-top: 2rem
  }

  [dir='ltr'] .sm\:ltr\:pr-20,[dir='ltr'].sm\:ltr\:pr-20 {
    padding-right: 2rem
  }

  [dir='ltr'] .sm\:ltr\:pb-20,[dir='ltr'].sm\:ltr\:pb-20 {
    padding-bottom: 2rem
  }

  [dir='ltr'] .sm\:ltr\:pl-20,[dir='ltr'].sm\:ltr\:pl-20 {
    padding-left: 2rem
  }

  [dir='ltr'] .sm\:ltr\:pt-24,[dir='ltr'].sm\:ltr\:pt-24 {
    padding-top: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:pr-24,[dir='ltr'].sm\:ltr\:pr-24 {
    padding-right: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:pb-24,[dir='ltr'].sm\:ltr\:pb-24 {
    padding-bottom: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:pl-24,[dir='ltr'].sm\:ltr\:pl-24 {
    padding-left: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:pt-28,[dir='ltr'].sm\:ltr\:pt-28 {
    padding-top: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:pr-28,[dir='ltr'].sm\:ltr\:pr-28 {
    padding-right: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:pb-28,[dir='ltr'].sm\:ltr\:pb-28 {
    padding-bottom: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:pl-28,[dir='ltr'].sm\:ltr\:pl-28 {
    padding-left: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:pt-32,[dir='ltr'].sm\:ltr\:pt-32 {
    padding-top: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:pr-32,[dir='ltr'].sm\:ltr\:pr-32 {
    padding-right: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:pb-32,[dir='ltr'].sm\:ltr\:pb-32 {
    padding-bottom: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:pl-32,[dir='ltr'].sm\:ltr\:pl-32 {
    padding-left: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:pt-36,[dir='ltr'].sm\:ltr\:pt-36 {
    padding-top: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:pr-36,[dir='ltr'].sm\:ltr\:pr-36 {
    padding-right: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:pb-36,[dir='ltr'].sm\:ltr\:pb-36 {
    padding-bottom: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:pl-36,[dir='ltr'].sm\:ltr\:pl-36 {
    padding-left: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:pt-40,[dir='ltr'].sm\:ltr\:pt-40 {
    padding-top: 4rem
  }

  [dir='ltr'] .sm\:ltr\:pr-40,[dir='ltr'].sm\:ltr\:pr-40 {
    padding-right: 4rem
  }

  [dir='ltr'] .sm\:ltr\:pb-40,[dir='ltr'].sm\:ltr\:pb-40 {
    padding-bottom: 4rem
  }

  [dir='ltr'] .sm\:ltr\:pl-40,[dir='ltr'].sm\:ltr\:pl-40 {
    padding-left: 4rem
  }

  [dir='ltr'] .sm\:ltr\:pt-44,[dir='ltr'].sm\:ltr\:pt-44 {
    padding-top: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:pr-44,[dir='ltr'].sm\:ltr\:pr-44 {
    padding-right: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:pb-44,[dir='ltr'].sm\:ltr\:pb-44 {
    padding-bottom: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:pl-44,[dir='ltr'].sm\:ltr\:pl-44 {
    padding-left: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:pt-48,[dir='ltr'].sm\:ltr\:pt-48 {
    padding-top: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:pr-48,[dir='ltr'].sm\:ltr\:pr-48 {
    padding-right: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:pb-48,[dir='ltr'].sm\:ltr\:pb-48 {
    padding-bottom: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:pl-48,[dir='ltr'].sm\:ltr\:pl-48 {
    padding-left: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:pt-52,[dir='ltr'].sm\:ltr\:pt-52 {
    padding-top: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:pr-52,[dir='ltr'].sm\:ltr\:pr-52 {
    padding-right: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:pb-52,[dir='ltr'].sm\:ltr\:pb-52 {
    padding-bottom: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:pl-52,[dir='ltr'].sm\:ltr\:pl-52 {
    padding-left: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:pt-56,[dir='ltr'].sm\:ltr\:pt-56 {
    padding-top: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:pr-56,[dir='ltr'].sm\:ltr\:pr-56 {
    padding-right: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:pb-56,[dir='ltr'].sm\:ltr\:pb-56 {
    padding-bottom: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:pl-56,[dir='ltr'].sm\:ltr\:pl-56 {
    padding-left: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:pt-60,[dir='ltr'].sm\:ltr\:pt-60 {
    padding-top: 6rem
  }

  [dir='ltr'] .sm\:ltr\:pr-60,[dir='ltr'].sm\:ltr\:pr-60 {
    padding-right: 6rem
  }

  [dir='ltr'] .sm\:ltr\:pb-60,[dir='ltr'].sm\:ltr\:pb-60 {
    padding-bottom: 6rem
  }

  [dir='ltr'] .sm\:ltr\:pl-60,[dir='ltr'].sm\:ltr\:pl-60 {
    padding-left: 6rem
  }

  [dir='ltr'] .sm\:ltr\:pt-64,[dir='ltr'].sm\:ltr\:pt-64 {
    padding-top: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:pr-64,[dir='ltr'].sm\:ltr\:pr-64 {
    padding-right: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:pb-64,[dir='ltr'].sm\:ltr\:pb-64 {
    padding-bottom: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:pl-64,[dir='ltr'].sm\:ltr\:pl-64 {
    padding-left: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:pt-68,[dir='ltr'].sm\:ltr\:pt-68 {
    padding-top: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:pr-68,[dir='ltr'].sm\:ltr\:pr-68 {
    padding-right: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:pb-68,[dir='ltr'].sm\:ltr\:pb-68 {
    padding-bottom: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:pl-68,[dir='ltr'].sm\:ltr\:pl-68 {
    padding-left: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:pt-72,[dir='ltr'].sm\:ltr\:pt-72 {
    padding-top: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:pr-72,[dir='ltr'].sm\:ltr\:pr-72 {
    padding-right: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:pb-72,[dir='ltr'].sm\:ltr\:pb-72 {
    padding-bottom: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:pl-72,[dir='ltr'].sm\:ltr\:pl-72 {
    padding-left: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:pt-76,[dir='ltr'].sm\:ltr\:pt-76 {
    padding-top: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:pr-76,[dir='ltr'].sm\:ltr\:pr-76 {
    padding-right: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:pb-76,[dir='ltr'].sm\:ltr\:pb-76 {
    padding-bottom: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:pl-76,[dir='ltr'].sm\:ltr\:pl-76 {
    padding-left: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:pt-80,[dir='ltr'].sm\:ltr\:pt-80 {
    padding-top: 8rem
  }

  [dir='ltr'] .sm\:ltr\:pr-80,[dir='ltr'].sm\:ltr\:pr-80 {
    padding-right: 8rem
  }

  [dir='ltr'] .sm\:ltr\:pb-80,[dir='ltr'].sm\:ltr\:pb-80 {
    padding-bottom: 8rem
  }

  [dir='ltr'] .sm\:ltr\:pl-80,[dir='ltr'].sm\:ltr\:pl-80 {
    padding-left: 8rem
  }

  [dir='ltr'] .sm\:ltr\:pt-84,[dir='ltr'].sm\:ltr\:pt-84 {
    padding-top: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:pr-84,[dir='ltr'].sm\:ltr\:pr-84 {
    padding-right: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:pb-84,[dir='ltr'].sm\:ltr\:pb-84 {
    padding-bottom: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:pl-84,[dir='ltr'].sm\:ltr\:pl-84 {
    padding-left: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:pt-88,[dir='ltr'].sm\:ltr\:pt-88 {
    padding-top: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:pr-88,[dir='ltr'].sm\:ltr\:pr-88 {
    padding-right: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:pb-88,[dir='ltr'].sm\:ltr\:pb-88 {
    padding-bottom: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:pl-88,[dir='ltr'].sm\:ltr\:pl-88 {
    padding-left: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:pt-92,[dir='ltr'].sm\:ltr\:pt-92 {
    padding-top: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:pr-92,[dir='ltr'].sm\:ltr\:pr-92 {
    padding-right: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:pb-92,[dir='ltr'].sm\:ltr\:pb-92 {
    padding-bottom: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:pl-92,[dir='ltr'].sm\:ltr\:pl-92 {
    padding-left: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:pt-96,[dir='ltr'].sm\:ltr\:pt-96 {
    padding-top: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:pr-96,[dir='ltr'].sm\:ltr\:pr-96 {
    padding-right: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:pb-96,[dir='ltr'].sm\:ltr\:pb-96 {
    padding-bottom: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:pl-96,[dir='ltr'].sm\:ltr\:pl-96 {
    padding-left: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:pt-128,[dir='ltr'].sm\:ltr\:pt-128 {
    padding-top: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:pr-128,[dir='ltr'].sm\:ltr\:pr-128 {
    padding-right: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:pb-128,[dir='ltr'].sm\:ltr\:pb-128 {
    padding-bottom: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:pl-128,[dir='ltr'].sm\:ltr\:pl-128 {
    padding-left: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:pt-136,[dir='ltr'].sm\:ltr\:pt-136 {
    padding-top: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:pr-136,[dir='ltr'].sm\:ltr\:pr-136 {
    padding-right: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:pb-136,[dir='ltr'].sm\:ltr\:pb-136 {
    padding-bottom: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:pl-136,[dir='ltr'].sm\:ltr\:pl-136 {
    padding-left: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:pt-160,[dir='ltr'].sm\:ltr\:pt-160 {
    padding-top: 16rem
  }

  [dir='ltr'] .sm\:ltr\:pr-160,[dir='ltr'].sm\:ltr\:pr-160 {
    padding-right: 16rem
  }

  [dir='ltr'] .sm\:ltr\:pb-160,[dir='ltr'].sm\:ltr\:pb-160 {
    padding-bottom: 16rem
  }

  [dir='ltr'] .sm\:ltr\:pl-160,[dir='ltr'].sm\:ltr\:pl-160 {
    padding-left: 16rem
  }

  [dir='ltr'] .sm\:ltr\:pt-192,[dir='ltr'].sm\:ltr\:pt-192 {
    padding-top: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:pr-192,[dir='ltr'].sm\:ltr\:pr-192 {
    padding-right: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:pb-192,[dir='ltr'].sm\:ltr\:pb-192 {
    padding-bottom: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:pl-192,[dir='ltr'].sm\:ltr\:pl-192 {
    padding-left: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:pt-200,[dir='ltr'].sm\:ltr\:pt-200 {
    padding-top: 20rem
  }

  [dir='ltr'] .sm\:ltr\:pr-200,[dir='ltr'].sm\:ltr\:pr-200 {
    padding-right: 20rem
  }

  [dir='ltr'] .sm\:ltr\:pb-200,[dir='ltr'].sm\:ltr\:pb-200 {
    padding-bottom: 20rem
  }

  [dir='ltr'] .sm\:ltr\:pl-200,[dir='ltr'].sm\:ltr\:pl-200 {
    padding-left: 20rem
  }

  [dir='ltr'] .sm\:ltr\:pt-208,[dir='ltr'].sm\:ltr\:pt-208 {
    padding-top: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:pr-208,[dir='ltr'].sm\:ltr\:pr-208 {
    padding-right: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:pb-208,[dir='ltr'].sm\:ltr\:pb-208 {
    padding-bottom: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:pl-208,[dir='ltr'].sm\:ltr\:pl-208 {
    padding-left: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:pt-216,[dir='ltr'].sm\:ltr\:pt-216 {
    padding-top: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:pr-216,[dir='ltr'].sm\:ltr\:pr-216 {
    padding-right: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:pb-216,[dir='ltr'].sm\:ltr\:pb-216 {
    padding-bottom: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:pl-216,[dir='ltr'].sm\:ltr\:pl-216 {
    padding-left: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:pt-224,[dir='ltr'].sm\:ltr\:pt-224 {
    padding-top: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:pr-224,[dir='ltr'].sm\:ltr\:pr-224 {
    padding-right: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:pb-224,[dir='ltr'].sm\:ltr\:pb-224 {
    padding-bottom: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:pl-224,[dir='ltr'].sm\:ltr\:pl-224 {
    padding-left: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:pt-256,[dir='ltr'].sm\:ltr\:pt-256 {
    padding-top: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:pr-256,[dir='ltr'].sm\:ltr\:pr-256 {
    padding-right: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:pb-256,[dir='ltr'].sm\:ltr\:pb-256 {
    padding-bottom: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:pl-256,[dir='ltr'].sm\:ltr\:pl-256 {
    padding-left: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:pt-288,[dir='ltr'].sm\:ltr\:pt-288 {
    padding-top: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:pr-288,[dir='ltr'].sm\:ltr\:pr-288 {
    padding-right: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:pb-288,[dir='ltr'].sm\:ltr\:pb-288 {
    padding-bottom: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:pl-288,[dir='ltr'].sm\:ltr\:pl-288 {
    padding-left: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:pt-320,[dir='ltr'].sm\:ltr\:pt-320 {
    padding-top: 32rem
  }

  [dir='ltr'] .sm\:ltr\:pr-320,[dir='ltr'].sm\:ltr\:pr-320 {
    padding-right: 32rem
  }

  [dir='ltr'] .sm\:ltr\:pb-320,[dir='ltr'].sm\:ltr\:pb-320 {
    padding-bottom: 32rem
  }

  [dir='ltr'] .sm\:ltr\:pl-320,[dir='ltr'].sm\:ltr\:pl-320 {
    padding-left: 32rem
  }

  [dir='ltr'] .sm\:ltr\:pt-360,[dir='ltr'].sm\:ltr\:pt-360 {
    padding-top: 36rem
  }

  [dir='ltr'] .sm\:ltr\:pr-360,[dir='ltr'].sm\:ltr\:pr-360 {
    padding-right: 36rem
  }

  [dir='ltr'] .sm\:ltr\:pb-360,[dir='ltr'].sm\:ltr\:pb-360 {
    padding-bottom: 36rem
  }

  [dir='ltr'] .sm\:ltr\:pl-360,[dir='ltr'].sm\:ltr\:pl-360 {
    padding-left: 36rem
  }

  [dir='ltr'] .sm\:ltr\:pt-384,[dir='ltr'].sm\:ltr\:pt-384 {
    padding-top: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:pr-384,[dir='ltr'].sm\:ltr\:pr-384 {
    padding-right: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:pb-384,[dir='ltr'].sm\:ltr\:pb-384 {
    padding-bottom: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:pl-384,[dir='ltr'].sm\:ltr\:pl-384 {
    padding-left: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:pt-400,[dir='ltr'].sm\:ltr\:pt-400 {
    padding-top: 40rem
  }

  [dir='ltr'] .sm\:ltr\:pr-400,[dir='ltr'].sm\:ltr\:pr-400 {
    padding-right: 40rem
  }

  [dir='ltr'] .sm\:ltr\:pb-400,[dir='ltr'].sm\:ltr\:pb-400 {
    padding-bottom: 40rem
  }

  [dir='ltr'] .sm\:ltr\:pl-400,[dir='ltr'].sm\:ltr\:pl-400 {
    padding-left: 40rem
  }

  [dir='ltr'] .sm\:ltr\:pt-512,[dir='ltr'].sm\:ltr\:pt-512 {
    padding-top: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:pr-512,[dir='ltr'].sm\:ltr\:pr-512 {
    padding-right: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:pb-512,[dir='ltr'].sm\:ltr\:pb-512 {
    padding-bottom: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:pl-512,[dir='ltr'].sm\:ltr\:pl-512 {
    padding-left: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:pt-640,[dir='ltr'].sm\:ltr\:pt-640 {
    padding-top: 64rem
  }

  [dir='ltr'] .sm\:ltr\:pr-640,[dir='ltr'].sm\:ltr\:pr-640 {
    padding-right: 64rem
  }

  [dir='ltr'] .sm\:ltr\:pb-640,[dir='ltr'].sm\:ltr\:pb-640 {
    padding-bottom: 64rem
  }

  [dir='ltr'] .sm\:ltr\:pl-640,[dir='ltr'].sm\:ltr\:pl-640 {
    padding-left: 64rem
  }

  [dir='ltr'] .sm\:ltr\:pt-xs,[dir='ltr'].sm\:ltr\:pt-xs {
    padding-top: 32rem
  }

  [dir='ltr'] .sm\:ltr\:pr-xs,[dir='ltr'].sm\:ltr\:pr-xs {
    padding-right: 32rem
  }

  [dir='ltr'] .sm\:ltr\:pb-xs,[dir='ltr'].sm\:ltr\:pb-xs {
    padding-bottom: 32rem
  }

  [dir='ltr'] .sm\:ltr\:pl-xs,[dir='ltr'].sm\:ltr\:pl-xs {
    padding-left: 32rem
  }

  [dir='ltr'] .sm\:ltr\:pt-sm,[dir='ltr'].sm\:ltr\:pt-sm {
    padding-top: 48rem
  }

  [dir='ltr'] .sm\:ltr\:pr-sm,[dir='ltr'].sm\:ltr\:pr-sm {
    padding-right: 48rem
  }

  [dir='ltr'] .sm\:ltr\:pb-sm,[dir='ltr'].sm\:ltr\:pb-sm {
    padding-bottom: 48rem
  }

  [dir='ltr'] .sm\:ltr\:pl-sm,[dir='ltr'].sm\:ltr\:pl-sm {
    padding-left: 48rem
  }

  [dir='ltr'] .sm\:ltr\:pt-md,[dir='ltr'].sm\:ltr\:pt-md {
    padding-top: 64rem
  }

  [dir='ltr'] .sm\:ltr\:pr-md,[dir='ltr'].sm\:ltr\:pr-md {
    padding-right: 64rem
  }

  [dir='ltr'] .sm\:ltr\:pb-md,[dir='ltr'].sm\:ltr\:pb-md {
    padding-bottom: 64rem
  }

  [dir='ltr'] .sm\:ltr\:pl-md,[dir='ltr'].sm\:ltr\:pl-md {
    padding-left: 64rem
  }

  [dir='ltr'] .sm\:ltr\:pt-lg,[dir='ltr'].sm\:ltr\:pt-lg {
    padding-top: 80rem
  }

  [dir='ltr'] .sm\:ltr\:pr-lg,[dir='ltr'].sm\:ltr\:pr-lg {
    padding-right: 80rem
  }

  [dir='ltr'] .sm\:ltr\:pb-lg,[dir='ltr'].sm\:ltr\:pb-lg {
    padding-bottom: 80rem
  }

  [dir='ltr'] .sm\:ltr\:pl-lg,[dir='ltr'].sm\:ltr\:pl-lg {
    padding-left: 80rem
  }

  [dir='ltr'] .sm\:ltr\:pt-xl,[dir='ltr'].sm\:ltr\:pt-xl {
    padding-top: 96rem
  }

  [dir='ltr'] .sm\:ltr\:pr-xl,[dir='ltr'].sm\:ltr\:pr-xl {
    padding-right: 96rem
  }

  [dir='ltr'] .sm\:ltr\:pb-xl,[dir='ltr'].sm\:ltr\:pb-xl {
    padding-bottom: 96rem
  }

  [dir='ltr'] .sm\:ltr\:pl-xl,[dir='ltr'].sm\:ltr\:pl-xl {
    padding-left: 96rem
  }

  [dir='ltr'] .sm\:ltr\:pt-2xl,[dir='ltr'].sm\:ltr\:pt-2xl {
    padding-top: 112rem
  }

  [dir='ltr'] .sm\:ltr\:pr-2xl,[dir='ltr'].sm\:ltr\:pr-2xl {
    padding-right: 112rem
  }

  [dir='ltr'] .sm\:ltr\:pb-2xl,[dir='ltr'].sm\:ltr\:pb-2xl {
    padding-bottom: 112rem
  }

  [dir='ltr'] .sm\:ltr\:pl-2xl,[dir='ltr'].sm\:ltr\:pl-2xl {
    padding-left: 112rem
  }

  [dir='ltr'] .sm\:ltr\:pt-3xl,[dir='ltr'].sm\:ltr\:pt-3xl {
    padding-top: 128rem
  }

  [dir='ltr'] .sm\:ltr\:pr-3xl,[dir='ltr'].sm\:ltr\:pr-3xl {
    padding-right: 128rem
  }

  [dir='ltr'] .sm\:ltr\:pb-3xl,[dir='ltr'].sm\:ltr\:pb-3xl {
    padding-bottom: 128rem
  }

  [dir='ltr'] .sm\:ltr\:pl-3xl,[dir='ltr'].sm\:ltr\:pl-3xl {
    padding-left: 128rem
  }

  [dir='ltr'] .sm\:ltr\:pt-4xl,[dir='ltr'].sm\:ltr\:pt-4xl {
    padding-top: 144rem
  }

  [dir='ltr'] .sm\:ltr\:pr-4xl,[dir='ltr'].sm\:ltr\:pr-4xl {
    padding-right: 144rem
  }

  [dir='ltr'] .sm\:ltr\:pb-4xl,[dir='ltr'].sm\:ltr\:pb-4xl {
    padding-bottom: 144rem
  }

  [dir='ltr'] .sm\:ltr\:pl-4xl,[dir='ltr'].sm\:ltr\:pl-4xl {
    padding-left: 144rem
  }

  [dir='ltr'] .sm\:ltr\:pt-5xl,[dir='ltr'].sm\:ltr\:pt-5xl {
    padding-top: 160rem
  }

  [dir='ltr'] .sm\:ltr\:pr-5xl,[dir='ltr'].sm\:ltr\:pr-5xl {
    padding-right: 160rem
  }

  [dir='ltr'] .sm\:ltr\:pb-5xl,[dir='ltr'].sm\:ltr\:pb-5xl {
    padding-bottom: 160rem
  }

  [dir='ltr'] .sm\:ltr\:pl-5xl,[dir='ltr'].sm\:ltr\:pl-5xl {
    padding-left: 160rem
  }

  [dir='ltr'] .sm\:ltr\:pt-px,[dir='ltr'].sm\:ltr\:pt-px {
    padding-top: 1px
  }

  [dir='ltr'] .sm\:ltr\:pr-px,[dir='ltr'].sm\:ltr\:pr-px {
    padding-right: 1px
  }

  [dir='ltr'] .sm\:ltr\:pb-px,[dir='ltr'].sm\:ltr\:pb-px {
    padding-bottom: 1px
  }

  [dir='ltr'] .sm\:ltr\:pl-px,[dir='ltr'].sm\:ltr\:pl-px {
    padding-left: 1px
  }

  [dir='ltr'] .sm\:ltr\:pt-0\.5,[dir='ltr'].sm\:ltr\:pt-0\.5 {
    padding-top: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:pr-0\.5,[dir='ltr'].sm\:ltr\:pr-0\.5 {
    padding-right: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:pb-0\.5,[dir='ltr'].sm\:ltr\:pb-0\.5 {
    padding-bottom: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:pl-0\.5,[dir='ltr'].sm\:ltr\:pl-0\.5 {
    padding-left: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:pt-1\.5,[dir='ltr'].sm\:ltr\:pt-1\.5 {
    padding-top: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:pr-1\.5,[dir='ltr'].sm\:ltr\:pr-1\.5 {
    padding-right: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:pb-1\.5,[dir='ltr'].sm\:ltr\:pb-1\.5 {
    padding-bottom: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:pl-1\.5,[dir='ltr'].sm\:ltr\:pl-1\.5 {
    padding-left: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:pt-2\.5,[dir='ltr'].sm\:ltr\:pt-2\.5 {
    padding-top: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:pr-2\.5,[dir='ltr'].sm\:ltr\:pr-2\.5 {
    padding-right: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:pb-2\.5,[dir='ltr'].sm\:ltr\:pb-2\.5 {
    padding-bottom: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:pl-2\.5,[dir='ltr'].sm\:ltr\:pl-2\.5 {
    padding-left: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:pt-3\.5,[dir='ltr'].sm\:ltr\:pt-3\.5 {
    padding-top: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:pr-3\.5,[dir='ltr'].sm\:ltr\:pr-3\.5 {
    padding-right: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:pb-3\.5,[dir='ltr'].sm\:ltr\:pb-3\.5 {
    padding-bottom: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:pl-3\.5,[dir='ltr'].sm\:ltr\:pl-3\.5 {
    padding-left: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:p-0,[dir='rtl'].sm\:rtl\:p-0 {
    padding: 0
  }

  [dir='rtl'] .sm\:rtl\:p-1,[dir='rtl'].sm\:rtl\:p-1 {
    padding: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:p-2,[dir='rtl'].sm\:rtl\:p-2 {
    padding: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:p-3,[dir='rtl'].sm\:rtl\:p-3 {
    padding: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:p-4,[dir='rtl'].sm\:rtl\:p-4 {
    padding: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:p-5,[dir='rtl'].sm\:rtl\:p-5 {
    padding: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:p-6,[dir='rtl'].sm\:rtl\:p-6 {
    padding: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:p-7,[dir='rtl'].sm\:rtl\:p-7 {
    padding: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:p-8,[dir='rtl'].sm\:rtl\:p-8 {
    padding: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:p-9,[dir='rtl'].sm\:rtl\:p-9 {
    padding: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:p-10,[dir='rtl'].sm\:rtl\:p-10 {
    padding: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:p-12,[dir='rtl'].sm\:rtl\:p-12 {
    padding: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:p-14,[dir='rtl'].sm\:rtl\:p-14 {
    padding: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:p-16,[dir='rtl'].sm\:rtl\:p-16 {
    padding: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:p-20,[dir='rtl'].sm\:rtl\:p-20 {
    padding: 2rem
  }

  [dir='rtl'] .sm\:rtl\:p-24,[dir='rtl'].sm\:rtl\:p-24 {
    padding: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:p-28,[dir='rtl'].sm\:rtl\:p-28 {
    padding: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:p-32,[dir='rtl'].sm\:rtl\:p-32 {
    padding: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:p-36,[dir='rtl'].sm\:rtl\:p-36 {
    padding: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:p-40,[dir='rtl'].sm\:rtl\:p-40 {
    padding: 4rem
  }

  [dir='rtl'] .sm\:rtl\:p-44,[dir='rtl'].sm\:rtl\:p-44 {
    padding: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:p-48,[dir='rtl'].sm\:rtl\:p-48 {
    padding: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:p-52,[dir='rtl'].sm\:rtl\:p-52 {
    padding: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:p-56,[dir='rtl'].sm\:rtl\:p-56 {
    padding: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:p-60,[dir='rtl'].sm\:rtl\:p-60 {
    padding: 6rem
  }

  [dir='rtl'] .sm\:rtl\:p-64,[dir='rtl'].sm\:rtl\:p-64 {
    padding: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:p-68,[dir='rtl'].sm\:rtl\:p-68 {
    padding: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:p-72,[dir='rtl'].sm\:rtl\:p-72 {
    padding: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:p-76,[dir='rtl'].sm\:rtl\:p-76 {
    padding: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:p-80,[dir='rtl'].sm\:rtl\:p-80 {
    padding: 8rem
  }

  [dir='rtl'] .sm\:rtl\:p-84,[dir='rtl'].sm\:rtl\:p-84 {
    padding: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:p-88,[dir='rtl'].sm\:rtl\:p-88 {
    padding: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:p-92,[dir='rtl'].sm\:rtl\:p-92 {
    padding: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:p-96,[dir='rtl'].sm\:rtl\:p-96 {
    padding: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:p-128,[dir='rtl'].sm\:rtl\:p-128 {
    padding: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:p-136,[dir='rtl'].sm\:rtl\:p-136 {
    padding: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:p-160,[dir='rtl'].sm\:rtl\:p-160 {
    padding: 16rem
  }

  [dir='rtl'] .sm\:rtl\:p-192,[dir='rtl'].sm\:rtl\:p-192 {
    padding: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:p-200,[dir='rtl'].sm\:rtl\:p-200 {
    padding: 20rem
  }

  [dir='rtl'] .sm\:rtl\:p-208,[dir='rtl'].sm\:rtl\:p-208 {
    padding: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:p-216,[dir='rtl'].sm\:rtl\:p-216 {
    padding: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:p-224,[dir='rtl'].sm\:rtl\:p-224 {
    padding: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:p-256,[dir='rtl'].sm\:rtl\:p-256 {
    padding: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:p-288,[dir='rtl'].sm\:rtl\:p-288 {
    padding: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:p-320,[dir='rtl'].sm\:rtl\:p-320 {
    padding: 32rem
  }

  [dir='rtl'] .sm\:rtl\:p-360,[dir='rtl'].sm\:rtl\:p-360 {
    padding: 36rem
  }

  [dir='rtl'] .sm\:rtl\:p-384,[dir='rtl'].sm\:rtl\:p-384 {
    padding: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:p-400,[dir='rtl'].sm\:rtl\:p-400 {
    padding: 40rem
  }

  [dir='rtl'] .sm\:rtl\:p-512,[dir='rtl'].sm\:rtl\:p-512 {
    padding: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:p-640,[dir='rtl'].sm\:rtl\:p-640 {
    padding: 64rem
  }

  [dir='rtl'] .sm\:rtl\:p-xs,[dir='rtl'].sm\:rtl\:p-xs {
    padding: 32rem
  }

  [dir='rtl'] .sm\:rtl\:p-sm,[dir='rtl'].sm\:rtl\:p-sm {
    padding: 48rem
  }

  [dir='rtl'] .sm\:rtl\:p-md,[dir='rtl'].sm\:rtl\:p-md {
    padding: 64rem
  }

  [dir='rtl'] .sm\:rtl\:p-lg,[dir='rtl'].sm\:rtl\:p-lg {
    padding: 80rem
  }

  [dir='rtl'] .sm\:rtl\:p-xl,[dir='rtl'].sm\:rtl\:p-xl {
    padding: 96rem
  }

  [dir='rtl'] .sm\:rtl\:p-2xl,[dir='rtl'].sm\:rtl\:p-2xl {
    padding: 112rem
  }

  [dir='rtl'] .sm\:rtl\:p-3xl,[dir='rtl'].sm\:rtl\:p-3xl {
    padding: 128rem
  }

  [dir='rtl'] .sm\:rtl\:p-4xl,[dir='rtl'].sm\:rtl\:p-4xl {
    padding: 144rem
  }

  [dir='rtl'] .sm\:rtl\:p-5xl,[dir='rtl'].sm\:rtl\:p-5xl {
    padding: 160rem
  }

  [dir='rtl'] .sm\:rtl\:p-px,[dir='rtl'].sm\:rtl\:p-px {
    padding: 1px
  }

  [dir='rtl'] .sm\:rtl\:p-0\.5,[dir='rtl'].sm\:rtl\:p-0\.5 {
    padding: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:p-1\.5,[dir='rtl'].sm\:rtl\:p-1\.5 {
    padding: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:p-2\.5,[dir='rtl'].sm\:rtl\:p-2\.5 {
    padding: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:p-3\.5,[dir='rtl'].sm\:rtl\:p-3\.5 {
    padding: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:py-0,[dir='rtl'].sm\:rtl\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  [dir='rtl'] .sm\:rtl\:px-0,[dir='rtl'].sm\:rtl\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  [dir='rtl'] .sm\:rtl\:py-1,[dir='rtl'].sm\:rtl\:py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:px-1,[dir='rtl'].sm\:rtl\:px-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:py-2,[dir='rtl'].sm\:rtl\:py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:px-2,[dir='rtl'].sm\:rtl\:px-2 {
    padding-left: 0.2rem;
    padding-right: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:py-3,[dir='rtl'].sm\:rtl\:py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:px-3,[dir='rtl'].sm\:rtl\:px-3 {
    padding-left: 0.3rem;
    padding-right: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:py-4,[dir='rtl'].sm\:rtl\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:px-4,[dir='rtl'].sm\:rtl\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:py-5,[dir='rtl'].sm\:rtl\:py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:px-5,[dir='rtl'].sm\:rtl\:px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:py-6,[dir='rtl'].sm\:rtl\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:px-6,[dir='rtl'].sm\:rtl\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:py-7,[dir='rtl'].sm\:rtl\:py-7 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:px-7,[dir='rtl'].sm\:rtl\:px-7 {
    padding-left: 0.7rem;
    padding-right: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:py-8,[dir='rtl'].sm\:rtl\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:px-8,[dir='rtl'].sm\:rtl\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:py-9,[dir='rtl'].sm\:rtl\:py-9 {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:px-9,[dir='rtl'].sm\:rtl\:px-9 {
    padding-left: 0.9rem;
    padding-right: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:py-10,[dir='rtl'].sm\:rtl\:py-10 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:px-10,[dir='rtl'].sm\:rtl\:px-10 {
    padding-left: 1.0rem;
    padding-right: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:py-12,[dir='rtl'].sm\:rtl\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:px-12,[dir='rtl'].sm\:rtl\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:py-14,[dir='rtl'].sm\:rtl\:py-14 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:px-14,[dir='rtl'].sm\:rtl\:px-14 {
    padding-left: 1.4rem;
    padding-right: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:py-16,[dir='rtl'].sm\:rtl\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:px-16,[dir='rtl'].sm\:rtl\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:py-20,[dir='rtl'].sm\:rtl\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  [dir='rtl'] .sm\:rtl\:px-20,[dir='rtl'].sm\:rtl\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  [dir='rtl'] .sm\:rtl\:py-24,[dir='rtl'].sm\:rtl\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:px-24,[dir='rtl'].sm\:rtl\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:py-28,[dir='rtl'].sm\:rtl\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:px-28,[dir='rtl'].sm\:rtl\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:py-32,[dir='rtl'].sm\:rtl\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:px-32,[dir='rtl'].sm\:rtl\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:py-36,[dir='rtl'].sm\:rtl\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:px-36,[dir='rtl'].sm\:rtl\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:py-40,[dir='rtl'].sm\:rtl\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  [dir='rtl'] .sm\:rtl\:px-40,[dir='rtl'].sm\:rtl\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  [dir='rtl'] .sm\:rtl\:py-44,[dir='rtl'].sm\:rtl\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:px-44,[dir='rtl'].sm\:rtl\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:py-48,[dir='rtl'].sm\:rtl\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:px-48,[dir='rtl'].sm\:rtl\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:py-52,[dir='rtl'].sm\:rtl\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:px-52,[dir='rtl'].sm\:rtl\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:py-56,[dir='rtl'].sm\:rtl\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:px-56,[dir='rtl'].sm\:rtl\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:py-60,[dir='rtl'].sm\:rtl\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  [dir='rtl'] .sm\:rtl\:px-60,[dir='rtl'].sm\:rtl\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  [dir='rtl'] .sm\:rtl\:py-64,[dir='rtl'].sm\:rtl\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:px-64,[dir='rtl'].sm\:rtl\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:py-68,[dir='rtl'].sm\:rtl\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:px-68,[dir='rtl'].sm\:rtl\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:py-72,[dir='rtl'].sm\:rtl\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:px-72,[dir='rtl'].sm\:rtl\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:py-76,[dir='rtl'].sm\:rtl\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:px-76,[dir='rtl'].sm\:rtl\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:py-80,[dir='rtl'].sm\:rtl\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  [dir='rtl'] .sm\:rtl\:px-80,[dir='rtl'].sm\:rtl\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  [dir='rtl'] .sm\:rtl\:py-84,[dir='rtl'].sm\:rtl\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:px-84,[dir='rtl'].sm\:rtl\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:py-88,[dir='rtl'].sm\:rtl\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:px-88,[dir='rtl'].sm\:rtl\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:py-92,[dir='rtl'].sm\:rtl\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:px-92,[dir='rtl'].sm\:rtl\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:py-96,[dir='rtl'].sm\:rtl\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:px-96,[dir='rtl'].sm\:rtl\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:py-128,[dir='rtl'].sm\:rtl\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:px-128,[dir='rtl'].sm\:rtl\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:py-136,[dir='rtl'].sm\:rtl\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:px-136,[dir='rtl'].sm\:rtl\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:py-160,[dir='rtl'].sm\:rtl\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  [dir='rtl'] .sm\:rtl\:px-160,[dir='rtl'].sm\:rtl\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  [dir='rtl'] .sm\:rtl\:py-192,[dir='rtl'].sm\:rtl\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:px-192,[dir='rtl'].sm\:rtl\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:py-200,[dir='rtl'].sm\:rtl\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  [dir='rtl'] .sm\:rtl\:px-200,[dir='rtl'].sm\:rtl\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  [dir='rtl'] .sm\:rtl\:py-208,[dir='rtl'].sm\:rtl\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:px-208,[dir='rtl'].sm\:rtl\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:py-216,[dir='rtl'].sm\:rtl\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:px-216,[dir='rtl'].sm\:rtl\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:py-224,[dir='rtl'].sm\:rtl\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:px-224,[dir='rtl'].sm\:rtl\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:py-256,[dir='rtl'].sm\:rtl\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:px-256,[dir='rtl'].sm\:rtl\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:py-288,[dir='rtl'].sm\:rtl\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:px-288,[dir='rtl'].sm\:rtl\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:py-320,[dir='rtl'].sm\:rtl\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='rtl'] .sm\:rtl\:px-320,[dir='rtl'].sm\:rtl\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='rtl'] .sm\:rtl\:py-360,[dir='rtl'].sm\:rtl\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  [dir='rtl'] .sm\:rtl\:px-360,[dir='rtl'].sm\:rtl\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  [dir='rtl'] .sm\:rtl\:py-384,[dir='rtl'].sm\:rtl\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:px-384,[dir='rtl'].sm\:rtl\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:py-400,[dir='rtl'].sm\:rtl\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  [dir='rtl'] .sm\:rtl\:px-400,[dir='rtl'].sm\:rtl\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  [dir='rtl'] .sm\:rtl\:py-512,[dir='rtl'].sm\:rtl\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:px-512,[dir='rtl'].sm\:rtl\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:py-640,[dir='rtl'].sm\:rtl\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='rtl'] .sm\:rtl\:px-640,[dir='rtl'].sm\:rtl\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='rtl'] .sm\:rtl\:py-xs,[dir='rtl'].sm\:rtl\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='rtl'] .sm\:rtl\:px-xs,[dir='rtl'].sm\:rtl\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='rtl'] .sm\:rtl\:py-sm,[dir='rtl'].sm\:rtl\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  [dir='rtl'] .sm\:rtl\:px-sm,[dir='rtl'].sm\:rtl\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  [dir='rtl'] .sm\:rtl\:py-md,[dir='rtl'].sm\:rtl\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='rtl'] .sm\:rtl\:px-md,[dir='rtl'].sm\:rtl\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='rtl'] .sm\:rtl\:py-lg,[dir='rtl'].sm\:rtl\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  [dir='rtl'] .sm\:rtl\:px-lg,[dir='rtl'].sm\:rtl\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  [dir='rtl'] .sm\:rtl\:py-xl,[dir='rtl'].sm\:rtl\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  [dir='rtl'] .sm\:rtl\:px-xl,[dir='rtl'].sm\:rtl\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  [dir='rtl'] .sm\:rtl\:py-2xl,[dir='rtl'].sm\:rtl\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  [dir='rtl'] .sm\:rtl\:px-2xl,[dir='rtl'].sm\:rtl\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  [dir='rtl'] .sm\:rtl\:py-3xl,[dir='rtl'].sm\:rtl\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  [dir='rtl'] .sm\:rtl\:px-3xl,[dir='rtl'].sm\:rtl\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  [dir='rtl'] .sm\:rtl\:py-4xl,[dir='rtl'].sm\:rtl\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  [dir='rtl'] .sm\:rtl\:px-4xl,[dir='rtl'].sm\:rtl\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  [dir='rtl'] .sm\:rtl\:py-5xl,[dir='rtl'].sm\:rtl\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  [dir='rtl'] .sm\:rtl\:px-5xl,[dir='rtl'].sm\:rtl\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  [dir='rtl'] .sm\:rtl\:py-px,[dir='rtl'].sm\:rtl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  [dir='rtl'] .sm\:rtl\:px-px,[dir='rtl'].sm\:rtl\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  [dir='rtl'] .sm\:rtl\:py-0\.5,[dir='rtl'].sm\:rtl\:py-0\.5 {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:px-0\.5,[dir='rtl'].sm\:rtl\:px-0\.5 {
    padding-left: 0.05rem;
    padding-right: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:py-1\.5,[dir='rtl'].sm\:rtl\:py-1\.5 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:px-1\.5,[dir='rtl'].sm\:rtl\:px-1\.5 {
    padding-left: 0.15rem;
    padding-right: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:py-2\.5,[dir='rtl'].sm\:rtl\:py-2\.5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:px-2\.5,[dir='rtl'].sm\:rtl\:px-2\.5 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:py-3\.5,[dir='rtl'].sm\:rtl\:py-3\.5 {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:px-3\.5,[dir='rtl'].sm\:rtl\:px-3\.5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:pt-0,[dir='rtl'].sm\:rtl\:pt-0 {
    padding-top: 0
  }

  [dir='rtl'] .sm\:rtl\:pr-0,[dir='rtl'].sm\:rtl\:pr-0 {
    padding-right: 0
  }

  [dir='rtl'] .sm\:rtl\:pb-0,[dir='rtl'].sm\:rtl\:pb-0 {
    padding-bottom: 0
  }

  [dir='rtl'] .sm\:rtl\:pl-0,[dir='rtl'].sm\:rtl\:pl-0 {
    padding-left: 0
  }

  [dir='rtl'] .sm\:rtl\:pt-1,[dir='rtl'].sm\:rtl\:pt-1 {
    padding-top: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:pr-1,[dir='rtl'].sm\:rtl\:pr-1 {
    padding-right: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:pb-1,[dir='rtl'].sm\:rtl\:pb-1 {
    padding-bottom: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:pl-1,[dir='rtl'].sm\:rtl\:pl-1 {
    padding-left: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:pt-2,[dir='rtl'].sm\:rtl\:pt-2 {
    padding-top: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:pr-2,[dir='rtl'].sm\:rtl\:pr-2 {
    padding-right: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:pb-2,[dir='rtl'].sm\:rtl\:pb-2 {
    padding-bottom: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:pl-2,[dir='rtl'].sm\:rtl\:pl-2 {
    padding-left: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:pt-3,[dir='rtl'].sm\:rtl\:pt-3 {
    padding-top: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:pr-3,[dir='rtl'].sm\:rtl\:pr-3 {
    padding-right: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:pb-3,[dir='rtl'].sm\:rtl\:pb-3 {
    padding-bottom: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:pl-3,[dir='rtl'].sm\:rtl\:pl-3 {
    padding-left: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:pt-4,[dir='rtl'].sm\:rtl\:pt-4 {
    padding-top: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:pr-4,[dir='rtl'].sm\:rtl\:pr-4 {
    padding-right: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:pb-4,[dir='rtl'].sm\:rtl\:pb-4 {
    padding-bottom: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:pl-4,[dir='rtl'].sm\:rtl\:pl-4 {
    padding-left: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:pt-5,[dir='rtl'].sm\:rtl\:pt-5 {
    padding-top: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:pr-5,[dir='rtl'].sm\:rtl\:pr-5 {
    padding-right: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:pb-5,[dir='rtl'].sm\:rtl\:pb-5 {
    padding-bottom: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:pl-5,[dir='rtl'].sm\:rtl\:pl-5 {
    padding-left: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:pt-6,[dir='rtl'].sm\:rtl\:pt-6 {
    padding-top: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:pr-6,[dir='rtl'].sm\:rtl\:pr-6 {
    padding-right: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:pb-6,[dir='rtl'].sm\:rtl\:pb-6 {
    padding-bottom: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:pl-6,[dir='rtl'].sm\:rtl\:pl-6 {
    padding-left: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:pt-7,[dir='rtl'].sm\:rtl\:pt-7 {
    padding-top: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:pr-7,[dir='rtl'].sm\:rtl\:pr-7 {
    padding-right: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:pb-7,[dir='rtl'].sm\:rtl\:pb-7 {
    padding-bottom: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:pl-7,[dir='rtl'].sm\:rtl\:pl-7 {
    padding-left: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:pt-8,[dir='rtl'].sm\:rtl\:pt-8 {
    padding-top: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:pr-8,[dir='rtl'].sm\:rtl\:pr-8 {
    padding-right: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:pb-8,[dir='rtl'].sm\:rtl\:pb-8 {
    padding-bottom: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:pl-8,[dir='rtl'].sm\:rtl\:pl-8 {
    padding-left: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:pt-9,[dir='rtl'].sm\:rtl\:pt-9 {
    padding-top: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:pr-9,[dir='rtl'].sm\:rtl\:pr-9 {
    padding-right: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:pb-9,[dir='rtl'].sm\:rtl\:pb-9 {
    padding-bottom: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:pl-9,[dir='rtl'].sm\:rtl\:pl-9 {
    padding-left: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:pt-10,[dir='rtl'].sm\:rtl\:pt-10 {
    padding-top: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:pr-10,[dir='rtl'].sm\:rtl\:pr-10 {
    padding-right: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:pb-10,[dir='rtl'].sm\:rtl\:pb-10 {
    padding-bottom: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:pl-10,[dir='rtl'].sm\:rtl\:pl-10 {
    padding-left: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:pt-12,[dir='rtl'].sm\:rtl\:pt-12 {
    padding-top: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:pr-12,[dir='rtl'].sm\:rtl\:pr-12 {
    padding-right: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:pb-12,[dir='rtl'].sm\:rtl\:pb-12 {
    padding-bottom: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:pl-12,[dir='rtl'].sm\:rtl\:pl-12 {
    padding-left: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:pt-14,[dir='rtl'].sm\:rtl\:pt-14 {
    padding-top: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:pr-14,[dir='rtl'].sm\:rtl\:pr-14 {
    padding-right: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:pb-14,[dir='rtl'].sm\:rtl\:pb-14 {
    padding-bottom: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:pl-14,[dir='rtl'].sm\:rtl\:pl-14 {
    padding-left: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:pt-16,[dir='rtl'].sm\:rtl\:pt-16 {
    padding-top: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:pr-16,[dir='rtl'].sm\:rtl\:pr-16 {
    padding-right: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:pb-16,[dir='rtl'].sm\:rtl\:pb-16 {
    padding-bottom: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:pl-16,[dir='rtl'].sm\:rtl\:pl-16 {
    padding-left: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:pt-20,[dir='rtl'].sm\:rtl\:pt-20 {
    padding-top: 2rem
  }

  [dir='rtl'] .sm\:rtl\:pr-20,[dir='rtl'].sm\:rtl\:pr-20 {
    padding-right: 2rem
  }

  [dir='rtl'] .sm\:rtl\:pb-20,[dir='rtl'].sm\:rtl\:pb-20 {
    padding-bottom: 2rem
  }

  [dir='rtl'] .sm\:rtl\:pl-20,[dir='rtl'].sm\:rtl\:pl-20 {
    padding-left: 2rem
  }

  [dir='rtl'] .sm\:rtl\:pt-24,[dir='rtl'].sm\:rtl\:pt-24 {
    padding-top: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:pr-24,[dir='rtl'].sm\:rtl\:pr-24 {
    padding-right: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:pb-24,[dir='rtl'].sm\:rtl\:pb-24 {
    padding-bottom: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:pl-24,[dir='rtl'].sm\:rtl\:pl-24 {
    padding-left: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:pt-28,[dir='rtl'].sm\:rtl\:pt-28 {
    padding-top: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:pr-28,[dir='rtl'].sm\:rtl\:pr-28 {
    padding-right: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:pb-28,[dir='rtl'].sm\:rtl\:pb-28 {
    padding-bottom: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:pl-28,[dir='rtl'].sm\:rtl\:pl-28 {
    padding-left: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:pt-32,[dir='rtl'].sm\:rtl\:pt-32 {
    padding-top: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:pr-32,[dir='rtl'].sm\:rtl\:pr-32 {
    padding-right: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:pb-32,[dir='rtl'].sm\:rtl\:pb-32 {
    padding-bottom: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:pl-32,[dir='rtl'].sm\:rtl\:pl-32 {
    padding-left: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:pt-36,[dir='rtl'].sm\:rtl\:pt-36 {
    padding-top: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:pr-36,[dir='rtl'].sm\:rtl\:pr-36 {
    padding-right: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:pb-36,[dir='rtl'].sm\:rtl\:pb-36 {
    padding-bottom: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:pl-36,[dir='rtl'].sm\:rtl\:pl-36 {
    padding-left: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:pt-40,[dir='rtl'].sm\:rtl\:pt-40 {
    padding-top: 4rem
  }

  [dir='rtl'] .sm\:rtl\:pr-40,[dir='rtl'].sm\:rtl\:pr-40 {
    padding-right: 4rem
  }

  [dir='rtl'] .sm\:rtl\:pb-40,[dir='rtl'].sm\:rtl\:pb-40 {
    padding-bottom: 4rem
  }

  [dir='rtl'] .sm\:rtl\:pl-40,[dir='rtl'].sm\:rtl\:pl-40 {
    padding-left: 4rem
  }

  [dir='rtl'] .sm\:rtl\:pt-44,[dir='rtl'].sm\:rtl\:pt-44 {
    padding-top: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:pr-44,[dir='rtl'].sm\:rtl\:pr-44 {
    padding-right: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:pb-44,[dir='rtl'].sm\:rtl\:pb-44 {
    padding-bottom: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:pl-44,[dir='rtl'].sm\:rtl\:pl-44 {
    padding-left: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:pt-48,[dir='rtl'].sm\:rtl\:pt-48 {
    padding-top: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:pr-48,[dir='rtl'].sm\:rtl\:pr-48 {
    padding-right: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:pb-48,[dir='rtl'].sm\:rtl\:pb-48 {
    padding-bottom: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:pl-48,[dir='rtl'].sm\:rtl\:pl-48 {
    padding-left: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:pt-52,[dir='rtl'].sm\:rtl\:pt-52 {
    padding-top: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:pr-52,[dir='rtl'].sm\:rtl\:pr-52 {
    padding-right: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:pb-52,[dir='rtl'].sm\:rtl\:pb-52 {
    padding-bottom: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:pl-52,[dir='rtl'].sm\:rtl\:pl-52 {
    padding-left: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:pt-56,[dir='rtl'].sm\:rtl\:pt-56 {
    padding-top: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:pr-56,[dir='rtl'].sm\:rtl\:pr-56 {
    padding-right: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:pb-56,[dir='rtl'].sm\:rtl\:pb-56 {
    padding-bottom: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:pl-56,[dir='rtl'].sm\:rtl\:pl-56 {
    padding-left: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:pt-60,[dir='rtl'].sm\:rtl\:pt-60 {
    padding-top: 6rem
  }

  [dir='rtl'] .sm\:rtl\:pr-60,[dir='rtl'].sm\:rtl\:pr-60 {
    padding-right: 6rem
  }

  [dir='rtl'] .sm\:rtl\:pb-60,[dir='rtl'].sm\:rtl\:pb-60 {
    padding-bottom: 6rem
  }

  [dir='rtl'] .sm\:rtl\:pl-60,[dir='rtl'].sm\:rtl\:pl-60 {
    padding-left: 6rem
  }

  [dir='rtl'] .sm\:rtl\:pt-64,[dir='rtl'].sm\:rtl\:pt-64 {
    padding-top: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:pr-64,[dir='rtl'].sm\:rtl\:pr-64 {
    padding-right: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:pb-64,[dir='rtl'].sm\:rtl\:pb-64 {
    padding-bottom: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:pl-64,[dir='rtl'].sm\:rtl\:pl-64 {
    padding-left: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:pt-68,[dir='rtl'].sm\:rtl\:pt-68 {
    padding-top: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:pr-68,[dir='rtl'].sm\:rtl\:pr-68 {
    padding-right: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:pb-68,[dir='rtl'].sm\:rtl\:pb-68 {
    padding-bottom: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:pl-68,[dir='rtl'].sm\:rtl\:pl-68 {
    padding-left: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:pt-72,[dir='rtl'].sm\:rtl\:pt-72 {
    padding-top: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:pr-72,[dir='rtl'].sm\:rtl\:pr-72 {
    padding-right: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:pb-72,[dir='rtl'].sm\:rtl\:pb-72 {
    padding-bottom: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:pl-72,[dir='rtl'].sm\:rtl\:pl-72 {
    padding-left: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:pt-76,[dir='rtl'].sm\:rtl\:pt-76 {
    padding-top: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:pr-76,[dir='rtl'].sm\:rtl\:pr-76 {
    padding-right: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:pb-76,[dir='rtl'].sm\:rtl\:pb-76 {
    padding-bottom: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:pl-76,[dir='rtl'].sm\:rtl\:pl-76 {
    padding-left: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:pt-80,[dir='rtl'].sm\:rtl\:pt-80 {
    padding-top: 8rem
  }

  [dir='rtl'] .sm\:rtl\:pr-80,[dir='rtl'].sm\:rtl\:pr-80 {
    padding-right: 8rem
  }

  [dir='rtl'] .sm\:rtl\:pb-80,[dir='rtl'].sm\:rtl\:pb-80 {
    padding-bottom: 8rem
  }

  [dir='rtl'] .sm\:rtl\:pl-80,[dir='rtl'].sm\:rtl\:pl-80 {
    padding-left: 8rem
  }

  [dir='rtl'] .sm\:rtl\:pt-84,[dir='rtl'].sm\:rtl\:pt-84 {
    padding-top: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:pr-84,[dir='rtl'].sm\:rtl\:pr-84 {
    padding-right: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:pb-84,[dir='rtl'].sm\:rtl\:pb-84 {
    padding-bottom: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:pl-84,[dir='rtl'].sm\:rtl\:pl-84 {
    padding-left: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:pt-88,[dir='rtl'].sm\:rtl\:pt-88 {
    padding-top: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:pr-88,[dir='rtl'].sm\:rtl\:pr-88 {
    padding-right: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:pb-88,[dir='rtl'].sm\:rtl\:pb-88 {
    padding-bottom: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:pl-88,[dir='rtl'].sm\:rtl\:pl-88 {
    padding-left: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:pt-92,[dir='rtl'].sm\:rtl\:pt-92 {
    padding-top: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:pr-92,[dir='rtl'].sm\:rtl\:pr-92 {
    padding-right: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:pb-92,[dir='rtl'].sm\:rtl\:pb-92 {
    padding-bottom: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:pl-92,[dir='rtl'].sm\:rtl\:pl-92 {
    padding-left: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:pt-96,[dir='rtl'].sm\:rtl\:pt-96 {
    padding-top: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:pr-96,[dir='rtl'].sm\:rtl\:pr-96 {
    padding-right: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:pb-96,[dir='rtl'].sm\:rtl\:pb-96 {
    padding-bottom: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:pl-96,[dir='rtl'].sm\:rtl\:pl-96 {
    padding-left: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:pt-128,[dir='rtl'].sm\:rtl\:pt-128 {
    padding-top: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:pr-128,[dir='rtl'].sm\:rtl\:pr-128 {
    padding-right: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:pb-128,[dir='rtl'].sm\:rtl\:pb-128 {
    padding-bottom: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:pl-128,[dir='rtl'].sm\:rtl\:pl-128 {
    padding-left: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:pt-136,[dir='rtl'].sm\:rtl\:pt-136 {
    padding-top: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:pr-136,[dir='rtl'].sm\:rtl\:pr-136 {
    padding-right: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:pb-136,[dir='rtl'].sm\:rtl\:pb-136 {
    padding-bottom: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:pl-136,[dir='rtl'].sm\:rtl\:pl-136 {
    padding-left: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:pt-160,[dir='rtl'].sm\:rtl\:pt-160 {
    padding-top: 16rem
  }

  [dir='rtl'] .sm\:rtl\:pr-160,[dir='rtl'].sm\:rtl\:pr-160 {
    padding-right: 16rem
  }

  [dir='rtl'] .sm\:rtl\:pb-160,[dir='rtl'].sm\:rtl\:pb-160 {
    padding-bottom: 16rem
  }

  [dir='rtl'] .sm\:rtl\:pl-160,[dir='rtl'].sm\:rtl\:pl-160 {
    padding-left: 16rem
  }

  [dir='rtl'] .sm\:rtl\:pt-192,[dir='rtl'].sm\:rtl\:pt-192 {
    padding-top: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:pr-192,[dir='rtl'].sm\:rtl\:pr-192 {
    padding-right: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:pb-192,[dir='rtl'].sm\:rtl\:pb-192 {
    padding-bottom: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:pl-192,[dir='rtl'].sm\:rtl\:pl-192 {
    padding-left: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:pt-200,[dir='rtl'].sm\:rtl\:pt-200 {
    padding-top: 20rem
  }

  [dir='rtl'] .sm\:rtl\:pr-200,[dir='rtl'].sm\:rtl\:pr-200 {
    padding-right: 20rem
  }

  [dir='rtl'] .sm\:rtl\:pb-200,[dir='rtl'].sm\:rtl\:pb-200 {
    padding-bottom: 20rem
  }

  [dir='rtl'] .sm\:rtl\:pl-200,[dir='rtl'].sm\:rtl\:pl-200 {
    padding-left: 20rem
  }

  [dir='rtl'] .sm\:rtl\:pt-208,[dir='rtl'].sm\:rtl\:pt-208 {
    padding-top: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:pr-208,[dir='rtl'].sm\:rtl\:pr-208 {
    padding-right: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:pb-208,[dir='rtl'].sm\:rtl\:pb-208 {
    padding-bottom: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:pl-208,[dir='rtl'].sm\:rtl\:pl-208 {
    padding-left: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:pt-216,[dir='rtl'].sm\:rtl\:pt-216 {
    padding-top: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:pr-216,[dir='rtl'].sm\:rtl\:pr-216 {
    padding-right: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:pb-216,[dir='rtl'].sm\:rtl\:pb-216 {
    padding-bottom: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:pl-216,[dir='rtl'].sm\:rtl\:pl-216 {
    padding-left: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:pt-224,[dir='rtl'].sm\:rtl\:pt-224 {
    padding-top: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:pr-224,[dir='rtl'].sm\:rtl\:pr-224 {
    padding-right: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:pb-224,[dir='rtl'].sm\:rtl\:pb-224 {
    padding-bottom: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:pl-224,[dir='rtl'].sm\:rtl\:pl-224 {
    padding-left: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:pt-256,[dir='rtl'].sm\:rtl\:pt-256 {
    padding-top: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:pr-256,[dir='rtl'].sm\:rtl\:pr-256 {
    padding-right: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:pb-256,[dir='rtl'].sm\:rtl\:pb-256 {
    padding-bottom: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:pl-256,[dir='rtl'].sm\:rtl\:pl-256 {
    padding-left: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:pt-288,[dir='rtl'].sm\:rtl\:pt-288 {
    padding-top: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:pr-288,[dir='rtl'].sm\:rtl\:pr-288 {
    padding-right: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:pb-288,[dir='rtl'].sm\:rtl\:pb-288 {
    padding-bottom: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:pl-288,[dir='rtl'].sm\:rtl\:pl-288 {
    padding-left: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:pt-320,[dir='rtl'].sm\:rtl\:pt-320 {
    padding-top: 32rem
  }

  [dir='rtl'] .sm\:rtl\:pr-320,[dir='rtl'].sm\:rtl\:pr-320 {
    padding-right: 32rem
  }

  [dir='rtl'] .sm\:rtl\:pb-320,[dir='rtl'].sm\:rtl\:pb-320 {
    padding-bottom: 32rem
  }

  [dir='rtl'] .sm\:rtl\:pl-320,[dir='rtl'].sm\:rtl\:pl-320 {
    padding-left: 32rem
  }

  [dir='rtl'] .sm\:rtl\:pt-360,[dir='rtl'].sm\:rtl\:pt-360 {
    padding-top: 36rem
  }

  [dir='rtl'] .sm\:rtl\:pr-360,[dir='rtl'].sm\:rtl\:pr-360 {
    padding-right: 36rem
  }

  [dir='rtl'] .sm\:rtl\:pb-360,[dir='rtl'].sm\:rtl\:pb-360 {
    padding-bottom: 36rem
  }

  [dir='rtl'] .sm\:rtl\:pl-360,[dir='rtl'].sm\:rtl\:pl-360 {
    padding-left: 36rem
  }

  [dir='rtl'] .sm\:rtl\:pt-384,[dir='rtl'].sm\:rtl\:pt-384 {
    padding-top: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:pr-384,[dir='rtl'].sm\:rtl\:pr-384 {
    padding-right: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:pb-384,[dir='rtl'].sm\:rtl\:pb-384 {
    padding-bottom: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:pl-384,[dir='rtl'].sm\:rtl\:pl-384 {
    padding-left: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:pt-400,[dir='rtl'].sm\:rtl\:pt-400 {
    padding-top: 40rem
  }

  [dir='rtl'] .sm\:rtl\:pr-400,[dir='rtl'].sm\:rtl\:pr-400 {
    padding-right: 40rem
  }

  [dir='rtl'] .sm\:rtl\:pb-400,[dir='rtl'].sm\:rtl\:pb-400 {
    padding-bottom: 40rem
  }

  [dir='rtl'] .sm\:rtl\:pl-400,[dir='rtl'].sm\:rtl\:pl-400 {
    padding-left: 40rem
  }

  [dir='rtl'] .sm\:rtl\:pt-512,[dir='rtl'].sm\:rtl\:pt-512 {
    padding-top: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:pr-512,[dir='rtl'].sm\:rtl\:pr-512 {
    padding-right: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:pb-512,[dir='rtl'].sm\:rtl\:pb-512 {
    padding-bottom: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:pl-512,[dir='rtl'].sm\:rtl\:pl-512 {
    padding-left: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:pt-640,[dir='rtl'].sm\:rtl\:pt-640 {
    padding-top: 64rem
  }

  [dir='rtl'] .sm\:rtl\:pr-640,[dir='rtl'].sm\:rtl\:pr-640 {
    padding-right: 64rem
  }

  [dir='rtl'] .sm\:rtl\:pb-640,[dir='rtl'].sm\:rtl\:pb-640 {
    padding-bottom: 64rem
  }

  [dir='rtl'] .sm\:rtl\:pl-640,[dir='rtl'].sm\:rtl\:pl-640 {
    padding-left: 64rem
  }

  [dir='rtl'] .sm\:rtl\:pt-xs,[dir='rtl'].sm\:rtl\:pt-xs {
    padding-top: 32rem
  }

  [dir='rtl'] .sm\:rtl\:pr-xs,[dir='rtl'].sm\:rtl\:pr-xs {
    padding-right: 32rem
  }

  [dir='rtl'] .sm\:rtl\:pb-xs,[dir='rtl'].sm\:rtl\:pb-xs {
    padding-bottom: 32rem
  }

  [dir='rtl'] .sm\:rtl\:pl-xs,[dir='rtl'].sm\:rtl\:pl-xs {
    padding-left: 32rem
  }

  [dir='rtl'] .sm\:rtl\:pt-sm,[dir='rtl'].sm\:rtl\:pt-sm {
    padding-top: 48rem
  }

  [dir='rtl'] .sm\:rtl\:pr-sm,[dir='rtl'].sm\:rtl\:pr-sm {
    padding-right: 48rem
  }

  [dir='rtl'] .sm\:rtl\:pb-sm,[dir='rtl'].sm\:rtl\:pb-sm {
    padding-bottom: 48rem
  }

  [dir='rtl'] .sm\:rtl\:pl-sm,[dir='rtl'].sm\:rtl\:pl-sm {
    padding-left: 48rem
  }

  [dir='rtl'] .sm\:rtl\:pt-md,[dir='rtl'].sm\:rtl\:pt-md {
    padding-top: 64rem
  }

  [dir='rtl'] .sm\:rtl\:pr-md,[dir='rtl'].sm\:rtl\:pr-md {
    padding-right: 64rem
  }

  [dir='rtl'] .sm\:rtl\:pb-md,[dir='rtl'].sm\:rtl\:pb-md {
    padding-bottom: 64rem
  }

  [dir='rtl'] .sm\:rtl\:pl-md,[dir='rtl'].sm\:rtl\:pl-md {
    padding-left: 64rem
  }

  [dir='rtl'] .sm\:rtl\:pt-lg,[dir='rtl'].sm\:rtl\:pt-lg {
    padding-top: 80rem
  }

  [dir='rtl'] .sm\:rtl\:pr-lg,[dir='rtl'].sm\:rtl\:pr-lg {
    padding-right: 80rem
  }

  [dir='rtl'] .sm\:rtl\:pb-lg,[dir='rtl'].sm\:rtl\:pb-lg {
    padding-bottom: 80rem
  }

  [dir='rtl'] .sm\:rtl\:pl-lg,[dir='rtl'].sm\:rtl\:pl-lg {
    padding-left: 80rem
  }

  [dir='rtl'] .sm\:rtl\:pt-xl,[dir='rtl'].sm\:rtl\:pt-xl {
    padding-top: 96rem
  }

  [dir='rtl'] .sm\:rtl\:pr-xl,[dir='rtl'].sm\:rtl\:pr-xl {
    padding-right: 96rem
  }

  [dir='rtl'] .sm\:rtl\:pb-xl,[dir='rtl'].sm\:rtl\:pb-xl {
    padding-bottom: 96rem
  }

  [dir='rtl'] .sm\:rtl\:pl-xl,[dir='rtl'].sm\:rtl\:pl-xl {
    padding-left: 96rem
  }

  [dir='rtl'] .sm\:rtl\:pt-2xl,[dir='rtl'].sm\:rtl\:pt-2xl {
    padding-top: 112rem
  }

  [dir='rtl'] .sm\:rtl\:pr-2xl,[dir='rtl'].sm\:rtl\:pr-2xl {
    padding-right: 112rem
  }

  [dir='rtl'] .sm\:rtl\:pb-2xl,[dir='rtl'].sm\:rtl\:pb-2xl {
    padding-bottom: 112rem
  }

  [dir='rtl'] .sm\:rtl\:pl-2xl,[dir='rtl'].sm\:rtl\:pl-2xl {
    padding-left: 112rem
  }

  [dir='rtl'] .sm\:rtl\:pt-3xl,[dir='rtl'].sm\:rtl\:pt-3xl {
    padding-top: 128rem
  }

  [dir='rtl'] .sm\:rtl\:pr-3xl,[dir='rtl'].sm\:rtl\:pr-3xl {
    padding-right: 128rem
  }

  [dir='rtl'] .sm\:rtl\:pb-3xl,[dir='rtl'].sm\:rtl\:pb-3xl {
    padding-bottom: 128rem
  }

  [dir='rtl'] .sm\:rtl\:pl-3xl,[dir='rtl'].sm\:rtl\:pl-3xl {
    padding-left: 128rem
  }

  [dir='rtl'] .sm\:rtl\:pt-4xl,[dir='rtl'].sm\:rtl\:pt-4xl {
    padding-top: 144rem
  }

  [dir='rtl'] .sm\:rtl\:pr-4xl,[dir='rtl'].sm\:rtl\:pr-4xl {
    padding-right: 144rem
  }

  [dir='rtl'] .sm\:rtl\:pb-4xl,[dir='rtl'].sm\:rtl\:pb-4xl {
    padding-bottom: 144rem
  }

  [dir='rtl'] .sm\:rtl\:pl-4xl,[dir='rtl'].sm\:rtl\:pl-4xl {
    padding-left: 144rem
  }

  [dir='rtl'] .sm\:rtl\:pt-5xl,[dir='rtl'].sm\:rtl\:pt-5xl {
    padding-top: 160rem
  }

  [dir='rtl'] .sm\:rtl\:pr-5xl,[dir='rtl'].sm\:rtl\:pr-5xl {
    padding-right: 160rem
  }

  [dir='rtl'] .sm\:rtl\:pb-5xl,[dir='rtl'].sm\:rtl\:pb-5xl {
    padding-bottom: 160rem
  }

  [dir='rtl'] .sm\:rtl\:pl-5xl,[dir='rtl'].sm\:rtl\:pl-5xl {
    padding-left: 160rem
  }

  [dir='rtl'] .sm\:rtl\:pt-px,[dir='rtl'].sm\:rtl\:pt-px {
    padding-top: 1px
  }

  [dir='rtl'] .sm\:rtl\:pr-px,[dir='rtl'].sm\:rtl\:pr-px {
    padding-right: 1px
  }

  [dir='rtl'] .sm\:rtl\:pb-px,[dir='rtl'].sm\:rtl\:pb-px {
    padding-bottom: 1px
  }

  [dir='rtl'] .sm\:rtl\:pl-px,[dir='rtl'].sm\:rtl\:pl-px {
    padding-left: 1px
  }

  [dir='rtl'] .sm\:rtl\:pt-0\.5,[dir='rtl'].sm\:rtl\:pt-0\.5 {
    padding-top: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:pr-0\.5,[dir='rtl'].sm\:rtl\:pr-0\.5 {
    padding-right: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:pb-0\.5,[dir='rtl'].sm\:rtl\:pb-0\.5 {
    padding-bottom: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:pl-0\.5,[dir='rtl'].sm\:rtl\:pl-0\.5 {
    padding-left: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:pt-1\.5,[dir='rtl'].sm\:rtl\:pt-1\.5 {
    padding-top: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:pr-1\.5,[dir='rtl'].sm\:rtl\:pr-1\.5 {
    padding-right: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:pb-1\.5,[dir='rtl'].sm\:rtl\:pb-1\.5 {
    padding-bottom: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:pl-1\.5,[dir='rtl'].sm\:rtl\:pl-1\.5 {
    padding-left: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:pt-2\.5,[dir='rtl'].sm\:rtl\:pt-2\.5 {
    padding-top: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:pr-2\.5,[dir='rtl'].sm\:rtl\:pr-2\.5 {
    padding-right: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:pb-2\.5,[dir='rtl'].sm\:rtl\:pb-2\.5 {
    padding-bottom: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:pl-2\.5,[dir='rtl'].sm\:rtl\:pl-2\.5 {
    padding-left: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:pt-3\.5,[dir='rtl'].sm\:rtl\:pt-3\.5 {
    padding-top: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:pr-3\.5,[dir='rtl'].sm\:rtl\:pr-3\.5 {
    padding-right: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:pb-3\.5,[dir='rtl'].sm\:rtl\:pb-3\.5 {
    padding-bottom: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:pl-3\.5,[dir='rtl'].sm\:rtl\:pl-3\.5 {
    padding-left: 0.35rem
  }

  .sm\:placeholder-transparent::placeholder {
    color: transparent
  }

  .sm\:placeholder-current::placeholder {
    color: currentColor
  }

  .sm\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-grey-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-grey-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-grey-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-grey-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-grey-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-grey-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-grey-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-grey-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-grey-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-grey-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-grey-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-grey-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-grey-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-grey-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-gray-hover::placeholder {
    color: rgba(0, 0, 0, 0.04)
  }

  .sm\:placeholder-gray-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-gray-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-gray-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-gray-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-red::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-red-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-red-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-red-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-red-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-orange-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-orange-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-orange-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-orange-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-orange-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-orange-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-orange-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-orange-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-orange-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-orange-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-orange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-orange-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-orange-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-orange-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-orange-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-orange-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-orange-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-orange-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-orange-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-orange-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-orange-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-orange-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-orange-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-orange-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-orange-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-orange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-orange-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-orange-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-orange-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-orange-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-yellow::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-yellow-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-yellow-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-yellow-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-yellow-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-green-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-green-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-green-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-green-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-green-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-green-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-green-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-green-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-teal-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-teal-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-teal-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-teal-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-teal-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-teal-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-teal-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-teal-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-teal-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-teal-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-teal::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-teal-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-teal-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-teal-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-teal-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-blue-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-blue-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-blue-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-light-blue-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-indigo::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-indigo-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-indigo-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-indigo-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-indigo-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-purple::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-purple-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-purple-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-purple-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-purple-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-purple::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-purple-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-purple-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-purple-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-deep-purple-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-pink::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-pink-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-pink-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-pink-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-pink-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-lime-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-lime-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-lime-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-lime-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-lime-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-lime-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-lime-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-lime-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-lime-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-lime-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-lime::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-lime-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-lime-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-lime-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-lime-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-amber-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-amber-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-amber-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-amber-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-amber-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-amber-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-amber-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-amber-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-amber-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-amber-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-amber::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-amber-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-amber-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-amber-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-amber-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-brown-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-brown-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-brown-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-brown-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-brown-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-brown-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-brown-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-brown-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-brown-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-brown-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-brown::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-brown-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-brown-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-brown-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-brown-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-gray-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-gray-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-gray-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-blue-gray-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-cyan-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-cyan-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-cyan-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-cyan-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-cyan-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-cyan-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-cyan-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-cyan-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-cyan-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-cyan-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-cyan::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-cyan-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-cyan-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-cyan-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-cyan-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent
  }

  .sm\:focus\:placeholder-current:focus::placeholder {
    color: currentColor
  }

  .sm\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-grey-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-grey-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-grey-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-grey-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-grey-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-grey-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-grey-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-grey-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-grey-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-grey-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-grey-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-grey-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-grey-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-grey-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-hover:focus::placeholder {
    color: rgba(0, 0, 0, 0.04)
  }

  .sm\:focus\:placeholder-gray-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-red:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-orange-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-orange-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-orange-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-orange-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-orange-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-orange-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-orange-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-orange-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-orange-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-orange-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-orange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-orange-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-orange-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-orange-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-orange-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-green-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-green-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-green-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-green-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-blue-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-blue-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-blue-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-light-blue-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-purple:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-purple-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-purple-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-purple-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-deep-purple-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-lime-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-lime-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-lime-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-lime-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-lime-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-lime-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-lime-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-lime-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-lime-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-lime-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-lime:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-lime-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-lime-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-lime-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-lime-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-amber-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-amber-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-amber-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-amber-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-amber-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-amber-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-amber-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-amber-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-amber-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-amber-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-amber:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-amber-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-amber-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-amber-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-amber-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-brown-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-brown-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-brown-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-brown-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-brown-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-brown-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-brown-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-brown-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-brown-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-brown-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-brown:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-brown-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-brown-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-brown-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-brown-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-gray-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-gray-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-gray-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-gray-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-cyan-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-cyan-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-cyan-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-cyan-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-cyan-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-cyan-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-cyan-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-cyan-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-cyan-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-cyan-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-cyan:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-cyan-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-cyan-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-cyan-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
  }

  .sm\:focus\:placeholder-cyan-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
  }

  .sm\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0
  }

  .sm\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05
  }

  .sm\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1
  }

  .sm\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2
  }

  .sm\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25
  }

  .sm\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3
  }

  .sm\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4
  }

  .sm\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5
  }

  .sm\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6
  }

  .sm\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7
  }

  .sm\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75
  }

  .sm\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8
  }

  .sm\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9
  }

  .sm\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95
  }

  .sm\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1
  }

  .sm\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0
  }

  .sm\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05
  }

  .sm\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1
  }

  .sm\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2
  }

  .sm\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25
  }

  .sm\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3
  }

  .sm\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4
  }

  .sm\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5
  }

  .sm\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6
  }

  .sm\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7
  }

  .sm\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75
  }

  .sm\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8
  }

  .sm\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9
  }

  .sm\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95
  }

  .sm\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1
  }

  .sm\:pointer-events-none {
    pointer-events: none
  }

  .sm\:pointer-events-auto {
    pointer-events: auto
  }

  .sm\:static {
    position: static
  }

  .sm\:fixed {
    position: fixed
  }

  .sm\:absolute {
    position: absolute
  }

  .sm\:relative {
    position: relative
  }

  .sm\:sticky {
    position: sticky
  }

  .sm\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .sm\:inset-1 {
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem
  }

  .sm\:inset-2 {
    top: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem
  }

  .sm\:inset-3 {
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem
  }

  .sm\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  .sm\:inset-5 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  .sm\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  .sm\:inset-7 {
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
    left: 0.7rem
  }

  .sm\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  .sm\:inset-9 {
    top: 0.9rem;
    right: 0.9rem;
    bottom: 0.9rem;
    left: 0.9rem
  }

  .sm\:inset-10 {
    top: 1.0rem;
    right: 1.0rem;
    bottom: 1.0rem;
    left: 1.0rem
  }

  .sm\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  .sm\:inset-14 {
    top: 1.4rem;
    right: 1.4rem;
    bottom: 1.4rem;
    left: 1.4rem
  }

  .sm\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  .sm\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  .sm\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  .sm\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  .sm\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  .sm\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  .sm\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  .sm\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  .sm\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  .sm\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  .sm\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  .sm\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  .sm\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  .sm\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  .sm\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  .sm\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  .sm\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  .sm\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  .sm\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  .sm\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  .sm\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  .sm\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  .sm\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  .sm\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  .sm\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  .sm\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  .sm\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  .sm\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  .sm\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  .sm\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  .sm\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  .sm\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  .sm\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  .sm\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  .sm\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  .sm\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  .sm\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  .sm\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .sm\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  .sm\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  .sm\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  .sm\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  .sm\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  .sm\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  .sm\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  .sm\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  .sm\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  .sm\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  .sm\:inset-0\.5 {
    top: 0.05rem;
    right: 0.05rem;
    bottom: 0.05rem;
    left: 0.05rem
  }

  .sm\:inset-1\.5 {
    top: 0.15rem;
    right: 0.15rem;
    bottom: 0.15rem;
    left: 0.15rem
  }

  .sm\:inset-2\.5 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  .sm\:inset-3\.5 {
    top: 0.35rem;
    right: 0.35rem;
    bottom: 0.35rem;
    left: 0.35rem
  }

  .sm\:-inset-1 {
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem
  }

  .sm\:-inset-2 {
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem
  }

  .sm\:-inset-3 {
    top: -0.3rem;
    right: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem
  }

  .sm\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  .sm\:-inset-5 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  .sm\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  .sm\:-inset-7 {
    top: -0.7rem;
    right: -0.7rem;
    bottom: -0.7rem;
    left: -0.7rem
  }

  .sm\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  .sm\:-inset-9 {
    top: -0.9rem;
    right: -0.9rem;
    bottom: -0.9rem;
    left: -0.9rem
  }

  .sm\:-inset-10 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  .sm\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  .sm\:-inset-14 {
    top: -1.4rem;
    right: -1.4rem;
    bottom: -1.4rem;
    left: -1.4rem
  }

  .sm\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  .sm\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  .sm\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  .sm\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  .sm\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  .sm\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  .sm\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  .sm\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  .sm\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  .sm\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  .sm\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  .sm\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  .sm\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  .sm\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  .sm\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  .sm\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  .sm\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  .sm\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  .sm\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  .sm\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  .sm\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  .sm\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  .sm\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  .sm\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  .sm\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  .sm\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  .sm\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  .sm\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  .sm\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  .sm\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  .sm\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  .sm\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  .sm\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  .sm\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  .sm\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  .sm\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  .sm\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  .sm\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  .sm\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  .sm\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  .sm\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  .sm\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  .sm\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  .sm\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  .sm\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  .sm\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  .sm\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  .sm\:-inset-0\.5 {
    top: -0.05rem;
    right: -0.05rem;
    bottom: -0.05rem;
    left: -0.05rem
  }

  .sm\:-inset-1\.5 {
    top: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    left: -0.15rem
  }

  .sm\:-inset-2\.5 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  .sm\:-inset-3\.5 {
    top: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    left: -0.35rem
  }

  .sm\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  .sm\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  .sm\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  .sm\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  .sm\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  .sm\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  .sm\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  .sm\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  .sm\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  .sm\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  .sm\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  .sm\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  .sm\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  .sm\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  .sm\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .sm\:inset-x-0 {
    right: 0;
    left: 0
  }

  .sm\:inset-y-1 {
    top: 0.1rem;
    bottom: 0.1rem
  }

  .sm\:inset-x-1 {
    right: 0.1rem;
    left: 0.1rem
  }

  .sm\:inset-y-2 {
    top: 0.2rem;
    bottom: 0.2rem
  }

  .sm\:inset-x-2 {
    right: 0.2rem;
    left: 0.2rem
  }

  .sm\:inset-y-3 {
    top: 0.3rem;
    bottom: 0.3rem
  }

  .sm\:inset-x-3 {
    right: 0.3rem;
    left: 0.3rem
  }

  .sm\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  .sm\:inset-x-4 {
    right: 0.4rem;
    left: 0.4rem
  }

  .sm\:inset-y-5 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  .sm\:inset-x-5 {
    right: 0.5rem;
    left: 0.5rem
  }

  .sm\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  .sm\:inset-x-6 {
    right: 0.6rem;
    left: 0.6rem
  }

  .sm\:inset-y-7 {
    top: 0.7rem;
    bottom: 0.7rem
  }

  .sm\:inset-x-7 {
    right: 0.7rem;
    left: 0.7rem
  }

  .sm\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  .sm\:inset-x-8 {
    right: 0.8rem;
    left: 0.8rem
  }

  .sm\:inset-y-9 {
    top: 0.9rem;
    bottom: 0.9rem
  }

  .sm\:inset-x-9 {
    right: 0.9rem;
    left: 0.9rem
  }

  .sm\:inset-y-10 {
    top: 1.0rem;
    bottom: 1.0rem
  }

  .sm\:inset-x-10 {
    right: 1.0rem;
    left: 1.0rem
  }

  .sm\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  .sm\:inset-x-12 {
    right: 1.2rem;
    left: 1.2rem
  }

  .sm\:inset-y-14 {
    top: 1.4rem;
    bottom: 1.4rem
  }

  .sm\:inset-x-14 {
    right: 1.4rem;
    left: 1.4rem
  }

  .sm\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  .sm\:inset-x-16 {
    right: 1.6rem;
    left: 1.6rem
  }

  .sm\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  .sm\:inset-x-20 {
    right: 2rem;
    left: 2rem
  }

  .sm\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  .sm\:inset-x-24 {
    right: 2.4rem;
    left: 2.4rem
  }

  .sm\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  .sm\:inset-x-28 {
    right: 2.8rem;
    left: 2.8rem
  }

  .sm\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  .sm\:inset-x-32 {
    right: 3.2rem;
    left: 3.2rem
  }

  .sm\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  .sm\:inset-x-36 {
    right: 3.6rem;
    left: 3.6rem
  }

  .sm\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  .sm\:inset-x-40 {
    right: 4rem;
    left: 4rem
  }

  .sm\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  .sm\:inset-x-44 {
    right: 4.4rem;
    left: 4.4rem
  }

  .sm\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  .sm\:inset-x-48 {
    right: 4.8rem;
    left: 4.8rem
  }

  .sm\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  .sm\:inset-x-52 {
    right: 5.2rem;
    left: 5.2rem
  }

  .sm\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  .sm\:inset-x-56 {
    right: 5.6rem;
    left: 5.6rem
  }

  .sm\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  .sm\:inset-x-60 {
    right: 6rem;
    left: 6rem
  }

  .sm\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  .sm\:inset-x-64 {
    right: 6.4rem;
    left: 6.4rem
  }

  .sm\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  .sm\:inset-x-68 {
    right: 6.8rem;
    left: 6.8rem
  }

  .sm\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  .sm\:inset-x-72 {
    right: 7.2rem;
    left: 7.2rem
  }

  .sm\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  .sm\:inset-x-76 {
    right: 7.6rem;
    left: 7.6rem
  }

  .sm\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  .sm\:inset-x-80 {
    right: 8rem;
    left: 8rem
  }

  .sm\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  .sm\:inset-x-84 {
    right: 8.4rem;
    left: 8.4rem
  }

  .sm\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  .sm\:inset-x-88 {
    right: 8.8rem;
    left: 8.8rem
  }

  .sm\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  .sm\:inset-x-92 {
    right: 9.2rem;
    left: 9.2rem
  }

  .sm\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  .sm\:inset-x-96 {
    right: 9.6rem;
    left: 9.6rem
  }

  .sm\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  .sm\:inset-x-128 {
    right: 12.8rem;
    left: 12.8rem
  }

  .sm\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  .sm\:inset-x-136 {
    right: 13.6rem;
    left: 13.6rem
  }

  .sm\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  .sm\:inset-x-160 {
    right: 16rem;
    left: 16rem
  }

  .sm\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  .sm\:inset-x-192 {
    right: 19.2rem;
    left: 19.2rem
  }

  .sm\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  .sm\:inset-x-200 {
    right: 20rem;
    left: 20rem
  }

  .sm\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  .sm\:inset-x-208 {
    right: 20.8rem;
    left: 20.8rem
  }

  .sm\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  .sm\:inset-x-216 {
    right: 21.6rem;
    left: 21.6rem
  }

  .sm\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  .sm\:inset-x-224 {
    right: 22.4rem;
    left: 22.4rem
  }

  .sm\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  .sm\:inset-x-256 {
    right: 25.6rem;
    left: 25.6rem
  }

  .sm\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  .sm\:inset-x-288 {
    right: 28.8rem;
    left: 28.8rem
  }

  .sm\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  .sm\:inset-x-320 {
    right: 32rem;
    left: 32rem
  }

  .sm\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  .sm\:inset-x-360 {
    right: 36rem;
    left: 36rem
  }

  .sm\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  .sm\:inset-x-384 {
    right: 38.4rem;
    left: 38.4rem
  }

  .sm\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  .sm\:inset-x-400 {
    right: 40rem;
    left: 40rem
  }

  .sm\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  .sm\:inset-x-512 {
    right: 51.2rem;
    left: 51.2rem
  }

  .sm\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  .sm\:inset-x-640 {
    right: 64rem;
    left: 64rem
  }

  .sm\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .sm\:inset-x-auto {
    right: auto;
    left: auto
  }

  .sm\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  .sm\:inset-x-xs {
    right: 32rem;
    left: 32rem
  }

  .sm\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  .sm\:inset-x-sm {
    right: 48rem;
    left: 48rem
  }

  .sm\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  .sm\:inset-x-md {
    right: 64rem;
    left: 64rem
  }

  .sm\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  .sm\:inset-x-lg {
    right: 80rem;
    left: 80rem
  }

  .sm\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  .sm\:inset-x-xl {
    right: 96rem;
    left: 96rem
  }

  .sm\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  .sm\:inset-x-2xl {
    right: 112rem;
    left: 112rem
  }

  .sm\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  .sm\:inset-x-3xl {
    right: 128rem;
    left: 128rem
  }

  .sm\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  .sm\:inset-x-4xl {
    right: 144rem;
    left: 144rem
  }

  .sm\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  .sm\:inset-x-5xl {
    right: 160rem;
    left: 160rem
  }

  .sm\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  .sm\:inset-x-px {
    right: 1px;
    left: 1px
  }

  .sm\:inset-y-0\.5 {
    top: 0.05rem;
    bottom: 0.05rem
  }

  .sm\:inset-x-0\.5 {
    right: 0.05rem;
    left: 0.05rem
  }

  .sm\:inset-y-1\.5 {
    top: 0.15rem;
    bottom: 0.15rem
  }

  .sm\:inset-x-1\.5 {
    right: 0.15rem;
    left: 0.15rem
  }

  .sm\:inset-y-2\.5 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  .sm\:inset-x-2\.5 {
    right: 0.25rem;
    left: 0.25rem
  }

  .sm\:inset-y-3\.5 {
    top: 0.35rem;
    bottom: 0.35rem
  }

  .sm\:inset-x-3\.5 {
    right: 0.35rem;
    left: 0.35rem
  }

  .sm\:-inset-y-1 {
    top: -0.1rem;
    bottom: -0.1rem
  }

  .sm\:-inset-x-1 {
    right: -0.1rem;
    left: -0.1rem
  }

  .sm\:-inset-y-2 {
    top: -0.2rem;
    bottom: -0.2rem
  }

  .sm\:-inset-x-2 {
    right: -0.2rem;
    left: -0.2rem
  }

  .sm\:-inset-y-3 {
    top: -0.3rem;
    bottom: -0.3rem
  }

  .sm\:-inset-x-3 {
    right: -0.3rem;
    left: -0.3rem
  }

  .sm\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  .sm\:-inset-x-4 {
    right: -0.4rem;
    left: -0.4rem
  }

  .sm\:-inset-y-5 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  .sm\:-inset-x-5 {
    right: -0.5rem;
    left: -0.5rem
  }

  .sm\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  .sm\:-inset-x-6 {
    right: -0.6rem;
    left: -0.6rem
  }

  .sm\:-inset-y-7 {
    top: -0.7rem;
    bottom: -0.7rem
  }

  .sm\:-inset-x-7 {
    right: -0.7rem;
    left: -0.7rem
  }

  .sm\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  .sm\:-inset-x-8 {
    right: -0.8rem;
    left: -0.8rem
  }

  .sm\:-inset-y-9 {
    top: -0.9rem;
    bottom: -0.9rem
  }

  .sm\:-inset-x-9 {
    right: -0.9rem;
    left: -0.9rem
  }

  .sm\:-inset-y-10 {
    top: -1rem;
    bottom: -1rem
  }

  .sm\:-inset-x-10 {
    right: -1rem;
    left: -1rem
  }

  .sm\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  .sm\:-inset-x-12 {
    right: -1.2rem;
    left: -1.2rem
  }

  .sm\:-inset-y-14 {
    top: -1.4rem;
    bottom: -1.4rem
  }

  .sm\:-inset-x-14 {
    right: -1.4rem;
    left: -1.4rem
  }

  .sm\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  .sm\:-inset-x-16 {
    right: -1.6rem;
    left: -1.6rem
  }

  .sm\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  .sm\:-inset-x-20 {
    right: -2rem;
    left: -2rem
  }

  .sm\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  .sm\:-inset-x-24 {
    right: -2.4rem;
    left: -2.4rem
  }

  .sm\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  .sm\:-inset-x-28 {
    right: -2.8rem;
    left: -2.8rem
  }

  .sm\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  .sm\:-inset-x-32 {
    right: -3.2rem;
    left: -3.2rem
  }

  .sm\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  .sm\:-inset-x-36 {
    right: -3.6rem;
    left: -3.6rem
  }

  .sm\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  .sm\:-inset-x-40 {
    right: -4rem;
    left: -4rem
  }

  .sm\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  .sm\:-inset-x-44 {
    right: -4.4rem;
    left: -4.4rem
  }

  .sm\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  .sm\:-inset-x-48 {
    right: -4.8rem;
    left: -4.8rem
  }

  .sm\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  .sm\:-inset-x-52 {
    right: -5.2rem;
    left: -5.2rem
  }

  .sm\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  .sm\:-inset-x-56 {
    right: -5.6rem;
    left: -5.6rem
  }

  .sm\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  .sm\:-inset-x-60 {
    right: -6rem;
    left: -6rem
  }

  .sm\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  .sm\:-inset-x-64 {
    right: -6.4rem;
    left: -6.4rem
  }

  .sm\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  .sm\:-inset-x-68 {
    right: -6.8rem;
    left: -6.8rem
  }

  .sm\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  .sm\:-inset-x-72 {
    right: -7.2rem;
    left: -7.2rem
  }

  .sm\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  .sm\:-inset-x-76 {
    right: -7.6rem;
    left: -7.6rem
  }

  .sm\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  .sm\:-inset-x-80 {
    right: -8rem;
    left: -8rem
  }

  .sm\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  .sm\:-inset-x-84 {
    right: -8.4rem;
    left: -8.4rem
  }

  .sm\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  .sm\:-inset-x-88 {
    right: -8.8rem;
    left: -8.8rem
  }

  .sm\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  .sm\:-inset-x-92 {
    right: -9.2rem;
    left: -9.2rem
  }

  .sm\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  .sm\:-inset-x-96 {
    right: -9.6rem;
    left: -9.6rem
  }

  .sm\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  .sm\:-inset-x-128 {
    right: -12.8rem;
    left: -12.8rem
  }

  .sm\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  .sm\:-inset-x-136 {
    right: -13.6rem;
    left: -13.6rem
  }

  .sm\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  .sm\:-inset-x-160 {
    right: -16rem;
    left: -16rem
  }

  .sm\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  .sm\:-inset-x-192 {
    right: -19.2rem;
    left: -19.2rem
  }

  .sm\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  .sm\:-inset-x-200 {
    right: -20rem;
    left: -20rem
  }

  .sm\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  .sm\:-inset-x-208 {
    right: -20.8rem;
    left: -20.8rem
  }

  .sm\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  .sm\:-inset-x-216 {
    right: -21.6rem;
    left: -21.6rem
  }

  .sm\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  .sm\:-inset-x-224 {
    right: -22.4rem;
    left: -22.4rem
  }

  .sm\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  .sm\:-inset-x-256 {
    right: -25.6rem;
    left: -25.6rem
  }

  .sm\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  .sm\:-inset-x-288 {
    right: -28.8rem;
    left: -28.8rem
  }

  .sm\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  .sm\:-inset-x-320 {
    right: -32rem;
    left: -32rem
  }

  .sm\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  .sm\:-inset-x-360 {
    right: -36rem;
    left: -36rem
  }

  .sm\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  .sm\:-inset-x-384 {
    right: -38.4rem;
    left: -38.4rem
  }

  .sm\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  .sm\:-inset-x-400 {
    right: -40rem;
    left: -40rem
  }

  .sm\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  .sm\:-inset-x-512 {
    right: -51.2rem;
    left: -51.2rem
  }

  .sm\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  .sm\:-inset-x-640 {
    right: -64rem;
    left: -64rem
  }

  .sm\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  .sm\:-inset-x-xs {
    right: -32rem;
    left: -32rem
  }

  .sm\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  .sm\:-inset-x-sm {
    right: -48rem;
    left: -48rem
  }

  .sm\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  .sm\:-inset-x-md {
    right: -64rem;
    left: -64rem
  }

  .sm\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  .sm\:-inset-x-lg {
    right: -80rem;
    left: -80rem
  }

  .sm\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  .sm\:-inset-x-xl {
    right: -96rem;
    left: -96rem
  }

  .sm\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  .sm\:-inset-x-2xl {
    right: -112rem;
    left: -112rem
  }

  .sm\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  .sm\:-inset-x-3xl {
    right: -128rem;
    left: -128rem
  }

  .sm\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  .sm\:-inset-x-4xl {
    right: -144rem;
    left: -144rem
  }

  .sm\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  .sm\:-inset-x-5xl {
    right: -160rem;
    left: -160rem
  }

  .sm\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  .sm\:-inset-x-px {
    right: -1px;
    left: -1px
  }

  .sm\:-inset-y-0\.5 {
    top: -0.05rem;
    bottom: -0.05rem
  }

  .sm\:-inset-x-0\.5 {
    right: -0.05rem;
    left: -0.05rem
  }

  .sm\:-inset-y-1\.5 {
    top: -0.15rem;
    bottom: -0.15rem
  }

  .sm\:-inset-x-1\.5 {
    right: -0.15rem;
    left: -0.15rem
  }

  .sm\:-inset-y-2\.5 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  .sm\:-inset-x-2\.5 {
    right: -0.25rem;
    left: -0.25rem
  }

  .sm\:-inset-y-3\.5 {
    top: -0.35rem;
    bottom: -0.35rem
  }

  .sm\:-inset-x-3\.5 {
    right: -0.35rem;
    left: -0.35rem
  }

  .sm\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  .sm\:inset-x-1\/2 {
    right: 50%;
    left: 50%
  }

  .sm\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  .sm\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%
  }

  .sm\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  .sm\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%
  }

  .sm\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  .sm\:inset-x-1\/4 {
    right: 25%;
    left: 25%
  }

  .sm\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  .sm\:inset-x-2\/4 {
    right: 50%;
    left: 50%
  }

  .sm\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  .sm\:inset-x-3\/4 {
    right: 75%;
    left: 75%
  }

  .sm\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  .sm\:inset-x-full {
    right: 100%;
    left: 100%
  }

  .sm\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  .sm\:-inset-x-1\/2 {
    right: -50%;
    left: -50%
  }

  .sm\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  .sm\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%
  }

  .sm\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  .sm\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%
  }

  .sm\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  .sm\:-inset-x-1\/4 {
    right: -25%;
    left: -25%
  }

  .sm\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  .sm\:-inset-x-2\/4 {
    right: -50%;
    left: -50%
  }

  .sm\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  .sm\:-inset-x-3\/4 {
    right: -75%;
    left: -75%
  }

  .sm\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  .sm\:-inset-x-full {
    right: -100%;
    left: -100%
  }

  .sm\:top-0 {
    top: 0
  }

  .sm\:right-0 {
    right: 0
  }

  .sm\:bottom-0 {
    bottom: 0
  }

  .sm\:left-0 {
    left: 0
  }

  .sm\:top-1 {
    top: 0.1rem
  }

  .sm\:right-1 {
    right: 0.1rem
  }

  .sm\:bottom-1 {
    bottom: 0.1rem
  }

  .sm\:left-1 {
    left: 0.1rem
  }

  .sm\:top-2 {
    top: 0.2rem
  }

  .sm\:right-2 {
    right: 0.2rem
  }

  .sm\:bottom-2 {
    bottom: 0.2rem
  }

  .sm\:left-2 {
    left: 0.2rem
  }

  .sm\:top-3 {
    top: 0.3rem
  }

  .sm\:right-3 {
    right: 0.3rem
  }

  .sm\:bottom-3 {
    bottom: 0.3rem
  }

  .sm\:left-3 {
    left: 0.3rem
  }

  .sm\:top-4 {
    top: 0.4rem
  }

  .sm\:right-4 {
    right: 0.4rem
  }

  .sm\:bottom-4 {
    bottom: 0.4rem
  }

  .sm\:left-4 {
    left: 0.4rem
  }

  .sm\:top-5 {
    top: 0.5rem
  }

  .sm\:right-5 {
    right: 0.5rem
  }

  .sm\:bottom-5 {
    bottom: 0.5rem
  }

  .sm\:left-5 {
    left: 0.5rem
  }

  .sm\:top-6 {
    top: 0.6rem
  }

  .sm\:right-6 {
    right: 0.6rem
  }

  .sm\:bottom-6 {
    bottom: 0.6rem
  }

  .sm\:left-6 {
    left: 0.6rem
  }

  .sm\:top-7 {
    top: 0.7rem
  }

  .sm\:right-7 {
    right: 0.7rem
  }

  .sm\:bottom-7 {
    bottom: 0.7rem
  }

  .sm\:left-7 {
    left: 0.7rem
  }

  .sm\:top-8 {
    top: 0.8rem
  }

  .sm\:right-8 {
    right: 0.8rem
  }

  .sm\:bottom-8 {
    bottom: 0.8rem
  }

  .sm\:left-8 {
    left: 0.8rem
  }

  .sm\:top-9 {
    top: 0.9rem
  }

  .sm\:right-9 {
    right: 0.9rem
  }

  .sm\:bottom-9 {
    bottom: 0.9rem
  }

  .sm\:left-9 {
    left: 0.9rem
  }

  .sm\:top-10 {
    top: 1.0rem
  }

  .sm\:right-10 {
    right: 1.0rem
  }

  .sm\:bottom-10 {
    bottom: 1.0rem
  }

  .sm\:left-10 {
    left: 1.0rem
  }

  .sm\:top-12 {
    top: 1.2rem
  }

  .sm\:right-12 {
    right: 1.2rem
  }

  .sm\:bottom-12 {
    bottom: 1.2rem
  }

  .sm\:left-12 {
    left: 1.2rem
  }

  .sm\:top-14 {
    top: 1.4rem
  }

  .sm\:right-14 {
    right: 1.4rem
  }

  .sm\:bottom-14 {
    bottom: 1.4rem
  }

  .sm\:left-14 {
    left: 1.4rem
  }

  .sm\:top-16 {
    top: 1.6rem
  }

  .sm\:right-16 {
    right: 1.6rem
  }

  .sm\:bottom-16 {
    bottom: 1.6rem
  }

  .sm\:left-16 {
    left: 1.6rem
  }

  .sm\:top-20 {
    top: 2rem
  }

  .sm\:right-20 {
    right: 2rem
  }

  .sm\:bottom-20 {
    bottom: 2rem
  }

  .sm\:left-20 {
    left: 2rem
  }

  .sm\:top-24 {
    top: 2.4rem
  }

  .sm\:right-24 {
    right: 2.4rem
  }

  .sm\:bottom-24 {
    bottom: 2.4rem
  }

  .sm\:left-24 {
    left: 2.4rem
  }

  .sm\:top-28 {
    top: 2.8rem
  }

  .sm\:right-28 {
    right: 2.8rem
  }

  .sm\:bottom-28 {
    bottom: 2.8rem
  }

  .sm\:left-28 {
    left: 2.8rem
  }

  .sm\:top-32 {
    top: 3.2rem
  }

  .sm\:right-32 {
    right: 3.2rem
  }

  .sm\:bottom-32 {
    bottom: 3.2rem
  }

  .sm\:left-32 {
    left: 3.2rem
  }

  .sm\:top-36 {
    top: 3.6rem
  }

  .sm\:right-36 {
    right: 3.6rem
  }

  .sm\:bottom-36 {
    bottom: 3.6rem
  }

  .sm\:left-36 {
    left: 3.6rem
  }

  .sm\:top-40 {
    top: 4rem
  }

  .sm\:right-40 {
    right: 4rem
  }

  .sm\:bottom-40 {
    bottom: 4rem
  }

  .sm\:left-40 {
    left: 4rem
  }

  .sm\:top-44 {
    top: 4.4rem
  }

  .sm\:right-44 {
    right: 4.4rem
  }

  .sm\:bottom-44 {
    bottom: 4.4rem
  }

  .sm\:left-44 {
    left: 4.4rem
  }

  .sm\:top-48 {
    top: 4.8rem
  }

  .sm\:right-48 {
    right: 4.8rem
  }

  .sm\:bottom-48 {
    bottom: 4.8rem
  }

  .sm\:left-48 {
    left: 4.8rem
  }

  .sm\:top-52 {
    top: 5.2rem
  }

  .sm\:right-52 {
    right: 5.2rem
  }

  .sm\:bottom-52 {
    bottom: 5.2rem
  }

  .sm\:left-52 {
    left: 5.2rem
  }

  .sm\:top-56 {
    top: 5.6rem
  }

  .sm\:right-56 {
    right: 5.6rem
  }

  .sm\:bottom-56 {
    bottom: 5.6rem
  }

  .sm\:left-56 {
    left: 5.6rem
  }

  .sm\:top-60 {
    top: 6rem
  }

  .sm\:right-60 {
    right: 6rem
  }

  .sm\:bottom-60 {
    bottom: 6rem
  }

  .sm\:left-60 {
    left: 6rem
  }

  .sm\:top-64 {
    top: 6.4rem
  }

  .sm\:right-64 {
    right: 6.4rem
  }

  .sm\:bottom-64 {
    bottom: 6.4rem
  }

  .sm\:left-64 {
    left: 6.4rem
  }

  .sm\:top-68 {
    top: 6.8rem
  }

  .sm\:right-68 {
    right: 6.8rem
  }

  .sm\:bottom-68 {
    bottom: 6.8rem
  }

  .sm\:left-68 {
    left: 6.8rem
  }

  .sm\:top-72 {
    top: 7.2rem
  }

  .sm\:right-72 {
    right: 7.2rem
  }

  .sm\:bottom-72 {
    bottom: 7.2rem
  }

  .sm\:left-72 {
    left: 7.2rem
  }

  .sm\:top-76 {
    top: 7.6rem
  }

  .sm\:right-76 {
    right: 7.6rem
  }

  .sm\:bottom-76 {
    bottom: 7.6rem
  }

  .sm\:left-76 {
    left: 7.6rem
  }

  .sm\:top-80 {
    top: 8rem
  }

  .sm\:right-80 {
    right: 8rem
  }

  .sm\:bottom-80 {
    bottom: 8rem
  }

  .sm\:left-80 {
    left: 8rem
  }

  .sm\:top-84 {
    top: 8.4rem
  }

  .sm\:right-84 {
    right: 8.4rem
  }

  .sm\:bottom-84 {
    bottom: 8.4rem
  }

  .sm\:left-84 {
    left: 8.4rem
  }

  .sm\:top-88 {
    top: 8.8rem
  }

  .sm\:right-88 {
    right: 8.8rem
  }

  .sm\:bottom-88 {
    bottom: 8.8rem
  }

  .sm\:left-88 {
    left: 8.8rem
  }

  .sm\:top-92 {
    top: 9.2rem
  }

  .sm\:right-92 {
    right: 9.2rem
  }

  .sm\:bottom-92 {
    bottom: 9.2rem
  }

  .sm\:left-92 {
    left: 9.2rem
  }

  .sm\:top-96 {
    top: 9.6rem
  }

  .sm\:right-96 {
    right: 9.6rem
  }

  .sm\:bottom-96 {
    bottom: 9.6rem
  }

  .sm\:left-96 {
    left: 9.6rem
  }

  .sm\:top-128 {
    top: 12.8rem
  }

  .sm\:right-128 {
    right: 12.8rem
  }

  .sm\:bottom-128 {
    bottom: 12.8rem
  }

  .sm\:left-128 {
    left: 12.8rem
  }

  .sm\:top-136 {
    top: 13.6rem
  }

  .sm\:right-136 {
    right: 13.6rem
  }

  .sm\:bottom-136 {
    bottom: 13.6rem
  }

  .sm\:left-136 {
    left: 13.6rem
  }

  .sm\:top-160 {
    top: 16rem
  }

  .sm\:right-160 {
    right: 16rem
  }

  .sm\:bottom-160 {
    bottom: 16rem
  }

  .sm\:left-160 {
    left: 16rem
  }

  .sm\:top-192 {
    top: 19.2rem
  }

  .sm\:right-192 {
    right: 19.2rem
  }

  .sm\:bottom-192 {
    bottom: 19.2rem
  }

  .sm\:left-192 {
    left: 19.2rem
  }

  .sm\:top-200 {
    top: 20rem
  }

  .sm\:right-200 {
    right: 20rem
  }

  .sm\:bottom-200 {
    bottom: 20rem
  }

  .sm\:left-200 {
    left: 20rem
  }

  .sm\:top-208 {
    top: 20.8rem
  }

  .sm\:right-208 {
    right: 20.8rem
  }

  .sm\:bottom-208 {
    bottom: 20.8rem
  }

  .sm\:left-208 {
    left: 20.8rem
  }

  .sm\:top-216 {
    top: 21.6rem
  }

  .sm\:right-216 {
    right: 21.6rem
  }

  .sm\:bottom-216 {
    bottom: 21.6rem
  }

  .sm\:left-216 {
    left: 21.6rem
  }

  .sm\:top-224 {
    top: 22.4rem
  }

  .sm\:right-224 {
    right: 22.4rem
  }

  .sm\:bottom-224 {
    bottom: 22.4rem
  }

  .sm\:left-224 {
    left: 22.4rem
  }

  .sm\:top-256 {
    top: 25.6rem
  }

  .sm\:right-256 {
    right: 25.6rem
  }

  .sm\:bottom-256 {
    bottom: 25.6rem
  }

  .sm\:left-256 {
    left: 25.6rem
  }

  .sm\:top-288 {
    top: 28.8rem
  }

  .sm\:right-288 {
    right: 28.8rem
  }

  .sm\:bottom-288 {
    bottom: 28.8rem
  }

  .sm\:left-288 {
    left: 28.8rem
  }

  .sm\:top-320 {
    top: 32rem
  }

  .sm\:right-320 {
    right: 32rem
  }

  .sm\:bottom-320 {
    bottom: 32rem
  }

  .sm\:left-320 {
    left: 32rem
  }

  .sm\:top-360 {
    top: 36rem
  }

  .sm\:right-360 {
    right: 36rem
  }

  .sm\:bottom-360 {
    bottom: 36rem
  }

  .sm\:left-360 {
    left: 36rem
  }

  .sm\:top-384 {
    top: 38.4rem
  }

  .sm\:right-384 {
    right: 38.4rem
  }

  .sm\:bottom-384 {
    bottom: 38.4rem
  }

  .sm\:left-384 {
    left: 38.4rem
  }

  .sm\:top-400 {
    top: 40rem
  }

  .sm\:right-400 {
    right: 40rem
  }

  .sm\:bottom-400 {
    bottom: 40rem
  }

  .sm\:left-400 {
    left: 40rem
  }

  .sm\:top-512 {
    top: 51.2rem
  }

  .sm\:right-512 {
    right: 51.2rem
  }

  .sm\:bottom-512 {
    bottom: 51.2rem
  }

  .sm\:left-512 {
    left: 51.2rem
  }

  .sm\:top-640 {
    top: 64rem
  }

  .sm\:right-640 {
    right: 64rem
  }

  .sm\:bottom-640 {
    bottom: 64rem
  }

  .sm\:left-640 {
    left: 64rem
  }

  .sm\:top-auto {
    top: auto
  }

  .sm\:right-auto {
    right: auto
  }

  .sm\:bottom-auto {
    bottom: auto
  }

  .sm\:left-auto {
    left: auto
  }

  .sm\:top-xs {
    top: 32rem
  }

  .sm\:right-xs {
    right: 32rem
  }

  .sm\:bottom-xs {
    bottom: 32rem
  }

  .sm\:left-xs {
    left: 32rem
  }

  .sm\:top-sm {
    top: 48rem
  }

  .sm\:right-sm {
    right: 48rem
  }

  .sm\:bottom-sm {
    bottom: 48rem
  }

  .sm\:left-sm {
    left: 48rem
  }

  .sm\:top-md {
    top: 64rem
  }

  .sm\:right-md {
    right: 64rem
  }

  .sm\:bottom-md {
    bottom: 64rem
  }

  .sm\:left-md {
    left: 64rem
  }

  .sm\:top-lg {
    top: 80rem
  }

  .sm\:right-lg {
    right: 80rem
  }

  .sm\:bottom-lg {
    bottom: 80rem
  }

  .sm\:left-lg {
    left: 80rem
  }

  .sm\:top-xl {
    top: 96rem
  }

  .sm\:right-xl {
    right: 96rem
  }

  .sm\:bottom-xl {
    bottom: 96rem
  }

  .sm\:left-xl {
    left: 96rem
  }

  .sm\:top-2xl {
    top: 112rem
  }

  .sm\:right-2xl {
    right: 112rem
  }

  .sm\:bottom-2xl {
    bottom: 112rem
  }

  .sm\:left-2xl {
    left: 112rem
  }

  .sm\:top-3xl {
    top: 128rem
  }

  .sm\:right-3xl {
    right: 128rem
  }

  .sm\:bottom-3xl {
    bottom: 128rem
  }

  .sm\:left-3xl {
    left: 128rem
  }

  .sm\:top-4xl {
    top: 144rem
  }

  .sm\:right-4xl {
    right: 144rem
  }

  .sm\:bottom-4xl {
    bottom: 144rem
  }

  .sm\:left-4xl {
    left: 144rem
  }

  .sm\:top-5xl {
    top: 160rem
  }

  .sm\:right-5xl {
    right: 160rem
  }

  .sm\:bottom-5xl {
    bottom: 160rem
  }

  .sm\:left-5xl {
    left: 160rem
  }

  .sm\:top-px {
    top: 1px
  }

  .sm\:right-px {
    right: 1px
  }

  .sm\:bottom-px {
    bottom: 1px
  }

  .sm\:left-px {
    left: 1px
  }

  .sm\:top-0\.5 {
    top: 0.05rem
  }

  .sm\:right-0\.5 {
    right: 0.05rem
  }

  .sm\:bottom-0\.5 {
    bottom: 0.05rem
  }

  .sm\:left-0\.5 {
    left: 0.05rem
  }

  .sm\:top-1\.5 {
    top: 0.15rem
  }

  .sm\:right-1\.5 {
    right: 0.15rem
  }

  .sm\:bottom-1\.5 {
    bottom: 0.15rem
  }

  .sm\:left-1\.5 {
    left: 0.15rem
  }

  .sm\:top-2\.5 {
    top: 0.25rem
  }

  .sm\:right-2\.5 {
    right: 0.25rem
  }

  .sm\:bottom-2\.5 {
    bottom: 0.25rem
  }

  .sm\:left-2\.5 {
    left: 0.25rem
  }

  .sm\:top-3\.5 {
    top: 0.35rem
  }

  .sm\:right-3\.5 {
    right: 0.35rem
  }

  .sm\:bottom-3\.5 {
    bottom: 0.35rem
  }

  .sm\:left-3\.5 {
    left: 0.35rem
  }

  .sm\:-top-1 {
    top: -0.1rem
  }

  .sm\:-right-1 {
    right: -0.1rem
  }

  .sm\:-bottom-1 {
    bottom: -0.1rem
  }

  .sm\:-left-1 {
    left: -0.1rem
  }

  .sm\:-top-2 {
    top: -0.2rem
  }

  .sm\:-right-2 {
    right: -0.2rem
  }

  .sm\:-bottom-2 {
    bottom: -0.2rem
  }

  .sm\:-left-2 {
    left: -0.2rem
  }

  .sm\:-top-3 {
    top: -0.3rem
  }

  .sm\:-right-3 {
    right: -0.3rem
  }

  .sm\:-bottom-3 {
    bottom: -0.3rem
  }

  .sm\:-left-3 {
    left: -0.3rem
  }

  .sm\:-top-4 {
    top: -0.4rem
  }

  .sm\:-right-4 {
    right: -0.4rem
  }

  .sm\:-bottom-4 {
    bottom: -0.4rem
  }

  .sm\:-left-4 {
    left: -0.4rem
  }

  .sm\:-top-5 {
    top: -0.5rem
  }

  .sm\:-right-5 {
    right: -0.5rem
  }

  .sm\:-bottom-5 {
    bottom: -0.5rem
  }

  .sm\:-left-5 {
    left: -0.5rem
  }

  .sm\:-top-6 {
    top: -0.6rem
  }

  .sm\:-right-6 {
    right: -0.6rem
  }

  .sm\:-bottom-6 {
    bottom: -0.6rem
  }

  .sm\:-left-6 {
    left: -0.6rem
  }

  .sm\:-top-7 {
    top: -0.7rem
  }

  .sm\:-right-7 {
    right: -0.7rem
  }

  .sm\:-bottom-7 {
    bottom: -0.7rem
  }

  .sm\:-left-7 {
    left: -0.7rem
  }

  .sm\:-top-8 {
    top: -0.8rem
  }

  .sm\:-right-8 {
    right: -0.8rem
  }

  .sm\:-bottom-8 {
    bottom: -0.8rem
  }

  .sm\:-left-8 {
    left: -0.8rem
  }

  .sm\:-top-9 {
    top: -0.9rem
  }

  .sm\:-right-9 {
    right: -0.9rem
  }

  .sm\:-bottom-9 {
    bottom: -0.9rem
  }

  .sm\:-left-9 {
    left: -0.9rem
  }

  .sm\:-top-10 {
    top: -1rem
  }

  .sm\:-right-10 {
    right: -1rem
  }

  .sm\:-bottom-10 {
    bottom: -1rem
  }

  .sm\:-left-10 {
    left: -1rem
  }

  .sm\:-top-12 {
    top: -1.2rem
  }

  .sm\:-right-12 {
    right: -1.2rem
  }

  .sm\:-bottom-12 {
    bottom: -1.2rem
  }

  .sm\:-left-12 {
    left: -1.2rem
  }

  .sm\:-top-14 {
    top: -1.4rem
  }

  .sm\:-right-14 {
    right: -1.4rem
  }

  .sm\:-bottom-14 {
    bottom: -1.4rem
  }

  .sm\:-left-14 {
    left: -1.4rem
  }

  .sm\:-top-16 {
    top: -1.6rem
  }

  .sm\:-right-16 {
    right: -1.6rem
  }

  .sm\:-bottom-16 {
    bottom: -1.6rem
  }

  .sm\:-left-16 {
    left: -1.6rem
  }

  .sm\:-top-20 {
    top: -2rem
  }

  .sm\:-right-20 {
    right: -2rem
  }

  .sm\:-bottom-20 {
    bottom: -2rem
  }

  .sm\:-left-20 {
    left: -2rem
  }

  .sm\:-top-24 {
    top: -2.4rem
  }

  .sm\:-right-24 {
    right: -2.4rem
  }

  .sm\:-bottom-24 {
    bottom: -2.4rem
  }

  .sm\:-left-24 {
    left: -2.4rem
  }

  .sm\:-top-28 {
    top: -2.8rem
  }

  .sm\:-right-28 {
    right: -2.8rem
  }

  .sm\:-bottom-28 {
    bottom: -2.8rem
  }

  .sm\:-left-28 {
    left: -2.8rem
  }

  .sm\:-top-32 {
    top: -3.2rem
  }

  .sm\:-right-32 {
    right: -3.2rem
  }

  .sm\:-bottom-32 {
    bottom: -3.2rem
  }

  .sm\:-left-32 {
    left: -3.2rem
  }

  .sm\:-top-36 {
    top: -3.6rem
  }

  .sm\:-right-36 {
    right: -3.6rem
  }

  .sm\:-bottom-36 {
    bottom: -3.6rem
  }

  .sm\:-left-36 {
    left: -3.6rem
  }

  .sm\:-top-40 {
    top: -4rem
  }

  .sm\:-right-40 {
    right: -4rem
  }

  .sm\:-bottom-40 {
    bottom: -4rem
  }

  .sm\:-left-40 {
    left: -4rem
  }

  .sm\:-top-44 {
    top: -4.4rem
  }

  .sm\:-right-44 {
    right: -4.4rem
  }

  .sm\:-bottom-44 {
    bottom: -4.4rem
  }

  .sm\:-left-44 {
    left: -4.4rem
  }

  .sm\:-top-48 {
    top: -4.8rem
  }

  .sm\:-right-48 {
    right: -4.8rem
  }

  .sm\:-bottom-48 {
    bottom: -4.8rem
  }

  .sm\:-left-48 {
    left: -4.8rem
  }

  .sm\:-top-52 {
    top: -5.2rem
  }

  .sm\:-right-52 {
    right: -5.2rem
  }

  .sm\:-bottom-52 {
    bottom: -5.2rem
  }

  .sm\:-left-52 {
    left: -5.2rem
  }

  .sm\:-top-56 {
    top: -5.6rem
  }

  .sm\:-right-56 {
    right: -5.6rem
  }

  .sm\:-bottom-56 {
    bottom: -5.6rem
  }

  .sm\:-left-56 {
    left: -5.6rem
  }

  .sm\:-top-60 {
    top: -6rem
  }

  .sm\:-right-60 {
    right: -6rem
  }

  .sm\:-bottom-60 {
    bottom: -6rem
  }

  .sm\:-left-60 {
    left: -6rem
  }

  .sm\:-top-64 {
    top: -6.4rem
  }

  .sm\:-right-64 {
    right: -6.4rem
  }

  .sm\:-bottom-64 {
    bottom: -6.4rem
  }

  .sm\:-left-64 {
    left: -6.4rem
  }

  .sm\:-top-68 {
    top: -6.8rem
  }

  .sm\:-right-68 {
    right: -6.8rem
  }

  .sm\:-bottom-68 {
    bottom: -6.8rem
  }

  .sm\:-left-68 {
    left: -6.8rem
  }

  .sm\:-top-72 {
    top: -7.2rem
  }

  .sm\:-right-72 {
    right: -7.2rem
  }

  .sm\:-bottom-72 {
    bottom: -7.2rem
  }

  .sm\:-left-72 {
    left: -7.2rem
  }

  .sm\:-top-76 {
    top: -7.6rem
  }

  .sm\:-right-76 {
    right: -7.6rem
  }

  .sm\:-bottom-76 {
    bottom: -7.6rem
  }

  .sm\:-left-76 {
    left: -7.6rem
  }

  .sm\:-top-80 {
    top: -8rem
  }

  .sm\:-right-80 {
    right: -8rem
  }

  .sm\:-bottom-80 {
    bottom: -8rem
  }

  .sm\:-left-80 {
    left: -8rem
  }

  .sm\:-top-84 {
    top: -8.4rem
  }

  .sm\:-right-84 {
    right: -8.4rem
  }

  .sm\:-bottom-84 {
    bottom: -8.4rem
  }

  .sm\:-left-84 {
    left: -8.4rem
  }

  .sm\:-top-88 {
    top: -8.8rem
  }

  .sm\:-right-88 {
    right: -8.8rem
  }

  .sm\:-bottom-88 {
    bottom: -8.8rem
  }

  .sm\:-left-88 {
    left: -8.8rem
  }

  .sm\:-top-92 {
    top: -9.2rem
  }

  .sm\:-right-92 {
    right: -9.2rem
  }

  .sm\:-bottom-92 {
    bottom: -9.2rem
  }

  .sm\:-left-92 {
    left: -9.2rem
  }

  .sm\:-top-96 {
    top: -9.6rem
  }

  .sm\:-right-96 {
    right: -9.6rem
  }

  .sm\:-bottom-96 {
    bottom: -9.6rem
  }

  .sm\:-left-96 {
    left: -9.6rem
  }

  .sm\:-top-128 {
    top: -12.8rem
  }

  .sm\:-right-128 {
    right: -12.8rem
  }

  .sm\:-bottom-128 {
    bottom: -12.8rem
  }

  .sm\:-left-128 {
    left: -12.8rem
  }

  .sm\:-top-136 {
    top: -13.6rem
  }

  .sm\:-right-136 {
    right: -13.6rem
  }

  .sm\:-bottom-136 {
    bottom: -13.6rem
  }

  .sm\:-left-136 {
    left: -13.6rem
  }

  .sm\:-top-160 {
    top: -16rem
  }

  .sm\:-right-160 {
    right: -16rem
  }

  .sm\:-bottom-160 {
    bottom: -16rem
  }

  .sm\:-left-160 {
    left: -16rem
  }

  .sm\:-top-192 {
    top: -19.2rem
  }

  .sm\:-right-192 {
    right: -19.2rem
  }

  .sm\:-bottom-192 {
    bottom: -19.2rem
  }

  .sm\:-left-192 {
    left: -19.2rem
  }

  .sm\:-top-200 {
    top: -20rem
  }

  .sm\:-right-200 {
    right: -20rem
  }

  .sm\:-bottom-200 {
    bottom: -20rem
  }

  .sm\:-left-200 {
    left: -20rem
  }

  .sm\:-top-208 {
    top: -20.8rem
  }

  .sm\:-right-208 {
    right: -20.8rem
  }

  .sm\:-bottom-208 {
    bottom: -20.8rem
  }

  .sm\:-left-208 {
    left: -20.8rem
  }

  .sm\:-top-216 {
    top: -21.6rem
  }

  .sm\:-right-216 {
    right: -21.6rem
  }

  .sm\:-bottom-216 {
    bottom: -21.6rem
  }

  .sm\:-left-216 {
    left: -21.6rem
  }

  .sm\:-top-224 {
    top: -22.4rem
  }

  .sm\:-right-224 {
    right: -22.4rem
  }

  .sm\:-bottom-224 {
    bottom: -22.4rem
  }

  .sm\:-left-224 {
    left: -22.4rem
  }

  .sm\:-top-256 {
    top: -25.6rem
  }

  .sm\:-right-256 {
    right: -25.6rem
  }

  .sm\:-bottom-256 {
    bottom: -25.6rem
  }

  .sm\:-left-256 {
    left: -25.6rem
  }

  .sm\:-top-288 {
    top: -28.8rem
  }

  .sm\:-right-288 {
    right: -28.8rem
  }

  .sm\:-bottom-288 {
    bottom: -28.8rem
  }

  .sm\:-left-288 {
    left: -28.8rem
  }

  .sm\:-top-320 {
    top: -32rem
  }

  .sm\:-right-320 {
    right: -32rem
  }

  .sm\:-bottom-320 {
    bottom: -32rem
  }

  .sm\:-left-320 {
    left: -32rem
  }

  .sm\:-top-360 {
    top: -36rem
  }

  .sm\:-right-360 {
    right: -36rem
  }

  .sm\:-bottom-360 {
    bottom: -36rem
  }

  .sm\:-left-360 {
    left: -36rem
  }

  .sm\:-top-384 {
    top: -38.4rem
  }

  .sm\:-right-384 {
    right: -38.4rem
  }

  .sm\:-bottom-384 {
    bottom: -38.4rem
  }

  .sm\:-left-384 {
    left: -38.4rem
  }

  .sm\:-top-400 {
    top: -40rem
  }

  .sm\:-right-400 {
    right: -40rem
  }

  .sm\:-bottom-400 {
    bottom: -40rem
  }

  .sm\:-left-400 {
    left: -40rem
  }

  .sm\:-top-512 {
    top: -51.2rem
  }

  .sm\:-right-512 {
    right: -51.2rem
  }

  .sm\:-bottom-512 {
    bottom: -51.2rem
  }

  .sm\:-left-512 {
    left: -51.2rem
  }

  .sm\:-top-640 {
    top: -64rem
  }

  .sm\:-right-640 {
    right: -64rem
  }

  .sm\:-bottom-640 {
    bottom: -64rem
  }

  .sm\:-left-640 {
    left: -64rem
  }

  .sm\:-top-xs {
    top: -32rem
  }

  .sm\:-right-xs {
    right: -32rem
  }

  .sm\:-bottom-xs {
    bottom: -32rem
  }

  .sm\:-left-xs {
    left: -32rem
  }

  .sm\:-top-sm {
    top: -48rem
  }

  .sm\:-right-sm {
    right: -48rem
  }

  .sm\:-bottom-sm {
    bottom: -48rem
  }

  .sm\:-left-sm {
    left: -48rem
  }

  .sm\:-top-md {
    top: -64rem
  }

  .sm\:-right-md {
    right: -64rem
  }

  .sm\:-bottom-md {
    bottom: -64rem
  }

  .sm\:-left-md {
    left: -64rem
  }

  .sm\:-top-lg {
    top: -80rem
  }

  .sm\:-right-lg {
    right: -80rem
  }

  .sm\:-bottom-lg {
    bottom: -80rem
  }

  .sm\:-left-lg {
    left: -80rem
  }

  .sm\:-top-xl {
    top: -96rem
  }

  .sm\:-right-xl {
    right: -96rem
  }

  .sm\:-bottom-xl {
    bottom: -96rem
  }

  .sm\:-left-xl {
    left: -96rem
  }

  .sm\:-top-2xl {
    top: -112rem
  }

  .sm\:-right-2xl {
    right: -112rem
  }

  .sm\:-bottom-2xl {
    bottom: -112rem
  }

  .sm\:-left-2xl {
    left: -112rem
  }

  .sm\:-top-3xl {
    top: -128rem
  }

  .sm\:-right-3xl {
    right: -128rem
  }

  .sm\:-bottom-3xl {
    bottom: -128rem
  }

  .sm\:-left-3xl {
    left: -128rem
  }

  .sm\:-top-4xl {
    top: -144rem
  }

  .sm\:-right-4xl {
    right: -144rem
  }

  .sm\:-bottom-4xl {
    bottom: -144rem
  }

  .sm\:-left-4xl {
    left: -144rem
  }

  .sm\:-top-5xl {
    top: -160rem
  }

  .sm\:-right-5xl {
    right: -160rem
  }

  .sm\:-bottom-5xl {
    bottom: -160rem
  }

  .sm\:-left-5xl {
    left: -160rem
  }

  .sm\:-top-px {
    top: -1px
  }

  .sm\:-right-px {
    right: -1px
  }

  .sm\:-bottom-px {
    bottom: -1px
  }

  .sm\:-left-px {
    left: -1px
  }

  .sm\:-top-0\.5 {
    top: -0.05rem
  }

  .sm\:-right-0\.5 {
    right: -0.05rem
  }

  .sm\:-bottom-0\.5 {
    bottom: -0.05rem
  }

  .sm\:-left-0\.5 {
    left: -0.05rem
  }

  .sm\:-top-1\.5 {
    top: -0.15rem
  }

  .sm\:-right-1\.5 {
    right: -0.15rem
  }

  .sm\:-bottom-1\.5 {
    bottom: -0.15rem
  }

  .sm\:-left-1\.5 {
    left: -0.15rem
  }

  .sm\:-top-2\.5 {
    top: -0.25rem
  }

  .sm\:-right-2\.5 {
    right: -0.25rem
  }

  .sm\:-bottom-2\.5 {
    bottom: -0.25rem
  }

  .sm\:-left-2\.5 {
    left: -0.25rem
  }

  .sm\:-top-3\.5 {
    top: -0.35rem
  }

  .sm\:-right-3\.5 {
    right: -0.35rem
  }

  .sm\:-bottom-3\.5 {
    bottom: -0.35rem
  }

  .sm\:-left-3\.5 {
    left: -0.35rem
  }

  .sm\:top-1\/2 {
    top: 50%
  }

  .sm\:right-1\/2 {
    right: 50%
  }

  .sm\:bottom-1\/2 {
    bottom: 50%
  }

  .sm\:left-1\/2 {
    left: 50%
  }

  .sm\:top-1\/3 {
    top: 33.333333%
  }

  .sm\:right-1\/3 {
    right: 33.333333%
  }

  .sm\:bottom-1\/3 {
    bottom: 33.333333%
  }

  .sm\:left-1\/3 {
    left: 33.333333%
  }

  .sm\:top-2\/3 {
    top: 66.666667%
  }

  .sm\:right-2\/3 {
    right: 66.666667%
  }

  .sm\:bottom-2\/3 {
    bottom: 66.666667%
  }

  .sm\:left-2\/3 {
    left: 66.666667%
  }

  .sm\:top-1\/4 {
    top: 25%
  }

  .sm\:right-1\/4 {
    right: 25%
  }

  .sm\:bottom-1\/4 {
    bottom: 25%
  }

  .sm\:left-1\/4 {
    left: 25%
  }

  .sm\:top-2\/4 {
    top: 50%
  }

  .sm\:right-2\/4 {
    right: 50%
  }

  .sm\:bottom-2\/4 {
    bottom: 50%
  }

  .sm\:left-2\/4 {
    left: 50%
  }

  .sm\:top-3\/4 {
    top: 75%
  }

  .sm\:right-3\/4 {
    right: 75%
  }

  .sm\:bottom-3\/4 {
    bottom: 75%
  }

  .sm\:left-3\/4 {
    left: 75%
  }

  .sm\:top-full {
    top: 100%
  }

  .sm\:right-full {
    right: 100%
  }

  .sm\:bottom-full {
    bottom: 100%
  }

  .sm\:left-full {
    left: 100%
  }

  .sm\:-top-1\/2 {
    top: -50%
  }

  .sm\:-right-1\/2 {
    right: -50%
  }

  .sm\:-bottom-1\/2 {
    bottom: -50%
  }

  .sm\:-left-1\/2 {
    left: -50%
  }

  .sm\:-top-1\/3 {
    top: -33.333333%
  }

  .sm\:-right-1\/3 {
    right: -33.333333%
  }

  .sm\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  .sm\:-left-1\/3 {
    left: -33.333333%
  }

  .sm\:-top-2\/3 {
    top: -66.666667%
  }

  .sm\:-right-2\/3 {
    right: -66.666667%
  }

  .sm\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  .sm\:-left-2\/3 {
    left: -66.666667%
  }

  .sm\:-top-1\/4 {
    top: -25%
  }

  .sm\:-right-1\/4 {
    right: -25%
  }

  .sm\:-bottom-1\/4 {
    bottom: -25%
  }

  .sm\:-left-1\/4 {
    left: -25%
  }

  .sm\:-top-2\/4 {
    top: -50%
  }

  .sm\:-right-2\/4 {
    right: -50%
  }

  .sm\:-bottom-2\/4 {
    bottom: -50%
  }

  .sm\:-left-2\/4 {
    left: -50%
  }

  .sm\:-top-3\/4 {
    top: -75%
  }

  .sm\:-right-3\/4 {
    right: -75%
  }

  .sm\:-bottom-3\/4 {
    bottom: -75%
  }

  .sm\:-left-3\/4 {
    left: -75%
  }

  .sm\:-top-full {
    top: -100%
  }

  .sm\:-right-full {
    right: -100%
  }

  .sm\:-bottom-full {
    bottom: -100%
  }

  .sm\:-left-full {
    left: -100%
  }

  [dir='ltr'] .sm\:ltr\:inset-0,[dir='ltr'].sm\:ltr\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  [dir='ltr'] .sm\:ltr\:inset-1,[dir='ltr'].sm\:ltr\:inset-1 {
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:inset-2,[dir='ltr'].sm\:ltr\:inset-2 {
    top: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-3,[dir='ltr'].sm\:ltr\:inset-3 {
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:inset-4,[dir='ltr'].sm\:ltr\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-5,[dir='ltr'].sm\:ltr\:inset-5 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:inset-6,[dir='ltr'].sm\:ltr\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-7,[dir='ltr'].sm\:ltr\:inset-7 {
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
    left: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:inset-8,[dir='ltr'].sm\:ltr\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-9,[dir='ltr'].sm\:ltr\:inset-9 {
    top: 0.9rem;
    right: 0.9rem;
    bottom: 0.9rem;
    left: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:inset-10,[dir='ltr'].sm\:ltr\:inset-10 {
    top: 1.0rem;
    right: 1.0rem;
    bottom: 1.0rem;
    left: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:inset-12,[dir='ltr'].sm\:ltr\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-14,[dir='ltr'].sm\:ltr\:inset-14 {
    top: 1.4rem;
    right: 1.4rem;
    bottom: 1.4rem;
    left: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-16,[dir='ltr'].sm\:ltr\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-20,[dir='ltr'].sm\:ltr\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-24,[dir='ltr'].sm\:ltr\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-28,[dir='ltr'].sm\:ltr\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-32,[dir='ltr'].sm\:ltr\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-36,[dir='ltr'].sm\:ltr\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-40,[dir='ltr'].sm\:ltr\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-44,[dir='ltr'].sm\:ltr\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-48,[dir='ltr'].sm\:ltr\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-52,[dir='ltr'].sm\:ltr\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-56,[dir='ltr'].sm\:ltr\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-60,[dir='ltr'].sm\:ltr\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-64,[dir='ltr'].sm\:ltr\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-68,[dir='ltr'].sm\:ltr\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-72,[dir='ltr'].sm\:ltr\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-76,[dir='ltr'].sm\:ltr\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-80,[dir='ltr'].sm\:ltr\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-84,[dir='ltr'].sm\:ltr\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-88,[dir='ltr'].sm\:ltr\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-92,[dir='ltr'].sm\:ltr\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-96,[dir='ltr'].sm\:ltr\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-128,[dir='ltr'].sm\:ltr\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-136,[dir='ltr'].sm\:ltr\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-160,[dir='ltr'].sm\:ltr\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  [dir='ltr'] .sm\:ltr\:inset-192,[dir='ltr'].sm\:ltr\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-200,[dir='ltr'].sm\:ltr\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  [dir='ltr'] .sm\:ltr\:inset-208,[dir='ltr'].sm\:ltr\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-216,[dir='ltr'].sm\:ltr\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-224,[dir='ltr'].sm\:ltr\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-256,[dir='ltr'].sm\:ltr\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-288,[dir='ltr'].sm\:ltr\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-320,[dir='ltr'].sm\:ltr\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='ltr'] .sm\:ltr\:inset-360,[dir='ltr'].sm\:ltr\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  [dir='ltr'] .sm\:ltr\:inset-384,[dir='ltr'].sm\:ltr\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-400,[dir='ltr'].sm\:ltr\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  [dir='ltr'] .sm\:ltr\:inset-512,[dir='ltr'].sm\:ltr\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-640,[dir='ltr'].sm\:ltr\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='ltr'] .sm\:ltr\:inset-auto,[dir='ltr'].sm\:ltr\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  [dir='ltr'] .sm\:ltr\:inset-xs,[dir='ltr'].sm\:ltr\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='ltr'] .sm\:ltr\:inset-sm,[dir='ltr'].sm\:ltr\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  [dir='ltr'] .sm\:ltr\:inset-md,[dir='ltr'].sm\:ltr\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='ltr'] .sm\:ltr\:inset-lg,[dir='ltr'].sm\:ltr\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  [dir='ltr'] .sm\:ltr\:inset-xl,[dir='ltr'].sm\:ltr\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  [dir='ltr'] .sm\:ltr\:inset-2xl,[dir='ltr'].sm\:ltr\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  [dir='ltr'] .sm\:ltr\:inset-3xl,[dir='ltr'].sm\:ltr\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  [dir='ltr'] .sm\:ltr\:inset-4xl,[dir='ltr'].sm\:ltr\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  [dir='ltr'] .sm\:ltr\:inset-5xl,[dir='ltr'].sm\:ltr\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  [dir='ltr'] .sm\:ltr\:inset-px,[dir='ltr'].sm\:ltr\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  [dir='ltr'] .sm\:ltr\:inset-0\.5,[dir='ltr'].sm\:ltr\:inset-0\.5 {
    top: 0.05rem;
    right: 0.05rem;
    bottom: 0.05rem;
    left: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:inset-1\.5,[dir='ltr'].sm\:ltr\:inset-1\.5 {
    top: 0.15rem;
    right: 0.15rem;
    bottom: 0.15rem;
    left: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:inset-2\.5,[dir='ltr'].sm\:ltr\:inset-2\.5 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:inset-3\.5,[dir='ltr'].sm\:ltr\:inset-3\.5 {
    top: 0.35rem;
    right: 0.35rem;
    bottom: 0.35rem;
    left: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-1,[dir='ltr'].sm\:ltr\:-inset-1 {
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-2,[dir='ltr'].sm\:ltr\:-inset-2 {
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-3,[dir='ltr'].sm\:ltr\:-inset-3 {
    top: -0.3rem;
    right: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-4,[dir='ltr'].sm\:ltr\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-5,[dir='ltr'].sm\:ltr\:-inset-5 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-6,[dir='ltr'].sm\:ltr\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-7,[dir='ltr'].sm\:ltr\:-inset-7 {
    top: -0.7rem;
    right: -0.7rem;
    bottom: -0.7rem;
    left: -0.7rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-8,[dir='ltr'].sm\:ltr\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-9,[dir='ltr'].sm\:ltr\:-inset-9 {
    top: -0.9rem;
    right: -0.9rem;
    bottom: -0.9rem;
    left: -0.9rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-10,[dir='ltr'].sm\:ltr\:-inset-10 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-12,[dir='ltr'].sm\:ltr\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-14,[dir='ltr'].sm\:ltr\:-inset-14 {
    top: -1.4rem;
    right: -1.4rem;
    bottom: -1.4rem;
    left: -1.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-16,[dir='ltr'].sm\:ltr\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-20,[dir='ltr'].sm\:ltr\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-24,[dir='ltr'].sm\:ltr\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-28,[dir='ltr'].sm\:ltr\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-32,[dir='ltr'].sm\:ltr\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-36,[dir='ltr'].sm\:ltr\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-40,[dir='ltr'].sm\:ltr\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-44,[dir='ltr'].sm\:ltr\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-48,[dir='ltr'].sm\:ltr\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-52,[dir='ltr'].sm\:ltr\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-56,[dir='ltr'].sm\:ltr\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-60,[dir='ltr'].sm\:ltr\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-64,[dir='ltr'].sm\:ltr\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-68,[dir='ltr'].sm\:ltr\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-72,[dir='ltr'].sm\:ltr\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-76,[dir='ltr'].sm\:ltr\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-80,[dir='ltr'].sm\:ltr\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-84,[dir='ltr'].sm\:ltr\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-88,[dir='ltr'].sm\:ltr\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-92,[dir='ltr'].sm\:ltr\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-96,[dir='ltr'].sm\:ltr\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-128,[dir='ltr'].sm\:ltr\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-136,[dir='ltr'].sm\:ltr\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-160,[dir='ltr'].sm\:ltr\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-192,[dir='ltr'].sm\:ltr\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-200,[dir='ltr'].sm\:ltr\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-208,[dir='ltr'].sm\:ltr\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-216,[dir='ltr'].sm\:ltr\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-224,[dir='ltr'].sm\:ltr\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-256,[dir='ltr'].sm\:ltr\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-288,[dir='ltr'].sm\:ltr\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-320,[dir='ltr'].sm\:ltr\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-360,[dir='ltr'].sm\:ltr\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-384,[dir='ltr'].sm\:ltr\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-400,[dir='ltr'].sm\:ltr\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-512,[dir='ltr'].sm\:ltr\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-640,[dir='ltr'].sm\:ltr\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-xs,[dir='ltr'].sm\:ltr\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-sm,[dir='ltr'].sm\:ltr\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-md,[dir='ltr'].sm\:ltr\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-lg,[dir='ltr'].sm\:ltr\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-xl,[dir='ltr'].sm\:ltr\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-2xl,[dir='ltr'].sm\:ltr\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-3xl,[dir='ltr'].sm\:ltr\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-4xl,[dir='ltr'].sm\:ltr\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-5xl,[dir='ltr'].sm\:ltr\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-px,[dir='ltr'].sm\:ltr\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  [dir='ltr'] .sm\:ltr\:-inset-0\.5,[dir='ltr'].sm\:ltr\:-inset-0\.5 {
    top: -0.05rem;
    right: -0.05rem;
    bottom: -0.05rem;
    left: -0.05rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-1\.5,[dir='ltr'].sm\:ltr\:-inset-1\.5 {
    top: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    left: -0.15rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-2\.5,[dir='ltr'].sm\:ltr\:-inset-2\.5 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-3\.5,[dir='ltr'].sm\:ltr\:-inset-3\.5 {
    top: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    left: -0.35rem
  }

  [dir='ltr'] .sm\:ltr\:inset-1\/2,[dir='ltr'].sm\:ltr\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='ltr'] .sm\:ltr\:inset-1\/3,[dir='ltr'].sm\:ltr\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  [dir='ltr'] .sm\:ltr\:inset-2\/3,[dir='ltr'].sm\:ltr\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  [dir='ltr'] .sm\:ltr\:inset-1\/4,[dir='ltr'].sm\:ltr\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  [dir='ltr'] .sm\:ltr\:inset-2\/4,[dir='ltr'].sm\:ltr\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='ltr'] .sm\:ltr\:inset-3\/4,[dir='ltr'].sm\:ltr\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  [dir='ltr'] .sm\:ltr\:inset-full,[dir='ltr'].sm\:ltr\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  [dir='ltr'] .sm\:ltr\:-inset-1\/2,[dir='ltr'].sm\:ltr\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='ltr'] .sm\:ltr\:-inset-1\/3,[dir='ltr'].sm\:ltr\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  [dir='ltr'] .sm\:ltr\:-inset-2\/3,[dir='ltr'].sm\:ltr\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  [dir='ltr'] .sm\:ltr\:-inset-1\/4,[dir='ltr'].sm\:ltr\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  [dir='ltr'] .sm\:ltr\:-inset-2\/4,[dir='ltr'].sm\:ltr\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='ltr'] .sm\:ltr\:-inset-3\/4,[dir='ltr'].sm\:ltr\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  [dir='ltr'] .sm\:ltr\:-inset-full,[dir='ltr'].sm\:ltr\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  [dir='ltr'] .sm\:ltr\:inset-y-0,[dir='ltr'].sm\:ltr\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  [dir='ltr'] .sm\:ltr\:inset-x-0,[dir='ltr'].sm\:ltr\:inset-x-0 {
    right: 0;
    left: 0
  }

  [dir='ltr'] .sm\:ltr\:inset-y-1,[dir='ltr'].sm\:ltr\:inset-y-1 {
    top: 0.1rem;
    bottom: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-1,[dir='ltr'].sm\:ltr\:inset-x-1 {
    right: 0.1rem;
    left: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-2,[dir='ltr'].sm\:ltr\:inset-y-2 {
    top: 0.2rem;
    bottom: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-2,[dir='ltr'].sm\:ltr\:inset-x-2 {
    right: 0.2rem;
    left: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-3,[dir='ltr'].sm\:ltr\:inset-y-3 {
    top: 0.3rem;
    bottom: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-3,[dir='ltr'].sm\:ltr\:inset-x-3 {
    right: 0.3rem;
    left: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-4,[dir='ltr'].sm\:ltr\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-4,[dir='ltr'].sm\:ltr\:inset-x-4 {
    right: 0.4rem;
    left: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-5,[dir='ltr'].sm\:ltr\:inset-y-5 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-5,[dir='ltr'].sm\:ltr\:inset-x-5 {
    right: 0.5rem;
    left: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-6,[dir='ltr'].sm\:ltr\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-6,[dir='ltr'].sm\:ltr\:inset-x-6 {
    right: 0.6rem;
    left: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-7,[dir='ltr'].sm\:ltr\:inset-y-7 {
    top: 0.7rem;
    bottom: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-7,[dir='ltr'].sm\:ltr\:inset-x-7 {
    right: 0.7rem;
    left: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-8,[dir='ltr'].sm\:ltr\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-8,[dir='ltr'].sm\:ltr\:inset-x-8 {
    right: 0.8rem;
    left: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-9,[dir='ltr'].sm\:ltr\:inset-y-9 {
    top: 0.9rem;
    bottom: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-9,[dir='ltr'].sm\:ltr\:inset-x-9 {
    right: 0.9rem;
    left: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-10,[dir='ltr'].sm\:ltr\:inset-y-10 {
    top: 1.0rem;
    bottom: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-10,[dir='ltr'].sm\:ltr\:inset-x-10 {
    right: 1.0rem;
    left: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-12,[dir='ltr'].sm\:ltr\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-12,[dir='ltr'].sm\:ltr\:inset-x-12 {
    right: 1.2rem;
    left: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-14,[dir='ltr'].sm\:ltr\:inset-y-14 {
    top: 1.4rem;
    bottom: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-14,[dir='ltr'].sm\:ltr\:inset-x-14 {
    right: 1.4rem;
    left: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-16,[dir='ltr'].sm\:ltr\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-16,[dir='ltr'].sm\:ltr\:inset-x-16 {
    right: 1.6rem;
    left: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-20,[dir='ltr'].sm\:ltr\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-20,[dir='ltr'].sm\:ltr\:inset-x-20 {
    right: 2rem;
    left: 2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-24,[dir='ltr'].sm\:ltr\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-24,[dir='ltr'].sm\:ltr\:inset-x-24 {
    right: 2.4rem;
    left: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-28,[dir='ltr'].sm\:ltr\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-28,[dir='ltr'].sm\:ltr\:inset-x-28 {
    right: 2.8rem;
    left: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-32,[dir='ltr'].sm\:ltr\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-32,[dir='ltr'].sm\:ltr\:inset-x-32 {
    right: 3.2rem;
    left: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-36,[dir='ltr'].sm\:ltr\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-36,[dir='ltr'].sm\:ltr\:inset-x-36 {
    right: 3.6rem;
    left: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-40,[dir='ltr'].sm\:ltr\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-40,[dir='ltr'].sm\:ltr\:inset-x-40 {
    right: 4rem;
    left: 4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-44,[dir='ltr'].sm\:ltr\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-44,[dir='ltr'].sm\:ltr\:inset-x-44 {
    right: 4.4rem;
    left: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-48,[dir='ltr'].sm\:ltr\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-48,[dir='ltr'].sm\:ltr\:inset-x-48 {
    right: 4.8rem;
    left: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-52,[dir='ltr'].sm\:ltr\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-52,[dir='ltr'].sm\:ltr\:inset-x-52 {
    right: 5.2rem;
    left: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-56,[dir='ltr'].sm\:ltr\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-56,[dir='ltr'].sm\:ltr\:inset-x-56 {
    right: 5.6rem;
    left: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-60,[dir='ltr'].sm\:ltr\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-60,[dir='ltr'].sm\:ltr\:inset-x-60 {
    right: 6rem;
    left: 6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-64,[dir='ltr'].sm\:ltr\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-64,[dir='ltr'].sm\:ltr\:inset-x-64 {
    right: 6.4rem;
    left: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-68,[dir='ltr'].sm\:ltr\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-68,[dir='ltr'].sm\:ltr\:inset-x-68 {
    right: 6.8rem;
    left: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-72,[dir='ltr'].sm\:ltr\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-72,[dir='ltr'].sm\:ltr\:inset-x-72 {
    right: 7.2rem;
    left: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-76,[dir='ltr'].sm\:ltr\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-76,[dir='ltr'].sm\:ltr\:inset-x-76 {
    right: 7.6rem;
    left: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-80,[dir='ltr'].sm\:ltr\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-80,[dir='ltr'].sm\:ltr\:inset-x-80 {
    right: 8rem;
    left: 8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-84,[dir='ltr'].sm\:ltr\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-84,[dir='ltr'].sm\:ltr\:inset-x-84 {
    right: 8.4rem;
    left: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-88,[dir='ltr'].sm\:ltr\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-88,[dir='ltr'].sm\:ltr\:inset-x-88 {
    right: 8.8rem;
    left: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-92,[dir='ltr'].sm\:ltr\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-92,[dir='ltr'].sm\:ltr\:inset-x-92 {
    right: 9.2rem;
    left: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-96,[dir='ltr'].sm\:ltr\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-96,[dir='ltr'].sm\:ltr\:inset-x-96 {
    right: 9.6rem;
    left: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-128,[dir='ltr'].sm\:ltr\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-128,[dir='ltr'].sm\:ltr\:inset-x-128 {
    right: 12.8rem;
    left: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-136,[dir='ltr'].sm\:ltr\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-136,[dir='ltr'].sm\:ltr\:inset-x-136 {
    right: 13.6rem;
    left: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-160,[dir='ltr'].sm\:ltr\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-160,[dir='ltr'].sm\:ltr\:inset-x-160 {
    right: 16rem;
    left: 16rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-192,[dir='ltr'].sm\:ltr\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-192,[dir='ltr'].sm\:ltr\:inset-x-192 {
    right: 19.2rem;
    left: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-200,[dir='ltr'].sm\:ltr\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-200,[dir='ltr'].sm\:ltr\:inset-x-200 {
    right: 20rem;
    left: 20rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-208,[dir='ltr'].sm\:ltr\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-208,[dir='ltr'].sm\:ltr\:inset-x-208 {
    right: 20.8rem;
    left: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-216,[dir='ltr'].sm\:ltr\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-216,[dir='ltr'].sm\:ltr\:inset-x-216 {
    right: 21.6rem;
    left: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-224,[dir='ltr'].sm\:ltr\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-224,[dir='ltr'].sm\:ltr\:inset-x-224 {
    right: 22.4rem;
    left: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-256,[dir='ltr'].sm\:ltr\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-256,[dir='ltr'].sm\:ltr\:inset-x-256 {
    right: 25.6rem;
    left: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-288,[dir='ltr'].sm\:ltr\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-288,[dir='ltr'].sm\:ltr\:inset-x-288 {
    right: 28.8rem;
    left: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-320,[dir='ltr'].sm\:ltr\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-320,[dir='ltr'].sm\:ltr\:inset-x-320 {
    right: 32rem;
    left: 32rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-360,[dir='ltr'].sm\:ltr\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-360,[dir='ltr'].sm\:ltr\:inset-x-360 {
    right: 36rem;
    left: 36rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-384,[dir='ltr'].sm\:ltr\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-384,[dir='ltr'].sm\:ltr\:inset-x-384 {
    right: 38.4rem;
    left: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-400,[dir='ltr'].sm\:ltr\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-400,[dir='ltr'].sm\:ltr\:inset-x-400 {
    right: 40rem;
    left: 40rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-512,[dir='ltr'].sm\:ltr\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-512,[dir='ltr'].sm\:ltr\:inset-x-512 {
    right: 51.2rem;
    left: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-640,[dir='ltr'].sm\:ltr\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-640,[dir='ltr'].sm\:ltr\:inset-x-640 {
    right: 64rem;
    left: 64rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-auto,[dir='ltr'].sm\:ltr\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  [dir='ltr'] .sm\:ltr\:inset-x-auto,[dir='ltr'].sm\:ltr\:inset-x-auto {
    right: auto;
    left: auto
  }

  [dir='ltr'] .sm\:ltr\:inset-y-xs,[dir='ltr'].sm\:ltr\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-xs,[dir='ltr'].sm\:ltr\:inset-x-xs {
    right: 32rem;
    left: 32rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-sm,[dir='ltr'].sm\:ltr\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-sm,[dir='ltr'].sm\:ltr\:inset-x-sm {
    right: 48rem;
    left: 48rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-md,[dir='ltr'].sm\:ltr\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-md,[dir='ltr'].sm\:ltr\:inset-x-md {
    right: 64rem;
    left: 64rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-lg,[dir='ltr'].sm\:ltr\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-lg,[dir='ltr'].sm\:ltr\:inset-x-lg {
    right: 80rem;
    left: 80rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-xl,[dir='ltr'].sm\:ltr\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-xl,[dir='ltr'].sm\:ltr\:inset-x-xl {
    right: 96rem;
    left: 96rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-2xl,[dir='ltr'].sm\:ltr\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-2xl,[dir='ltr'].sm\:ltr\:inset-x-2xl {
    right: 112rem;
    left: 112rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-3xl,[dir='ltr'].sm\:ltr\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-3xl,[dir='ltr'].sm\:ltr\:inset-x-3xl {
    right: 128rem;
    left: 128rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-4xl,[dir='ltr'].sm\:ltr\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-4xl,[dir='ltr'].sm\:ltr\:inset-x-4xl {
    right: 144rem;
    left: 144rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-5xl,[dir='ltr'].sm\:ltr\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-5xl,[dir='ltr'].sm\:ltr\:inset-x-5xl {
    right: 160rem;
    left: 160rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-px,[dir='ltr'].sm\:ltr\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  [dir='ltr'] .sm\:ltr\:inset-x-px,[dir='ltr'].sm\:ltr\:inset-x-px {
    right: 1px;
    left: 1px
  }

  [dir='ltr'] .sm\:ltr\:inset-y-0\.5,[dir='ltr'].sm\:ltr\:inset-y-0\.5 {
    top: 0.05rem;
    bottom: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-0\.5,[dir='ltr'].sm\:ltr\:inset-x-0\.5 {
    right: 0.05rem;
    left: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-1\.5,[dir='ltr'].sm\:ltr\:inset-y-1\.5 {
    top: 0.15rem;
    bottom: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-1\.5,[dir='ltr'].sm\:ltr\:inset-x-1\.5 {
    right: 0.15rem;
    left: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-2\.5,[dir='ltr'].sm\:ltr\:inset-y-2\.5 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-2\.5,[dir='ltr'].sm\:ltr\:inset-x-2\.5 {
    right: 0.25rem;
    left: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-3\.5,[dir='ltr'].sm\:ltr\:inset-y-3\.5 {
    top: 0.35rem;
    bottom: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:inset-x-3\.5,[dir='ltr'].sm\:ltr\:inset-x-3\.5 {
    right: 0.35rem;
    left: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-1,[dir='ltr'].sm\:ltr\:-inset-y-1 {
    top: -0.1rem;
    bottom: -0.1rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-1,[dir='ltr'].sm\:ltr\:-inset-x-1 {
    right: -0.1rem;
    left: -0.1rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-2,[dir='ltr'].sm\:ltr\:-inset-y-2 {
    top: -0.2rem;
    bottom: -0.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-2,[dir='ltr'].sm\:ltr\:-inset-x-2 {
    right: -0.2rem;
    left: -0.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-3,[dir='ltr'].sm\:ltr\:-inset-y-3 {
    top: -0.3rem;
    bottom: -0.3rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-3,[dir='ltr'].sm\:ltr\:-inset-x-3 {
    right: -0.3rem;
    left: -0.3rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-4,[dir='ltr'].sm\:ltr\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-4,[dir='ltr'].sm\:ltr\:-inset-x-4 {
    right: -0.4rem;
    left: -0.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-5,[dir='ltr'].sm\:ltr\:-inset-y-5 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-5,[dir='ltr'].sm\:ltr\:-inset-x-5 {
    right: -0.5rem;
    left: -0.5rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-6,[dir='ltr'].sm\:ltr\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-6,[dir='ltr'].sm\:ltr\:-inset-x-6 {
    right: -0.6rem;
    left: -0.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-7,[dir='ltr'].sm\:ltr\:-inset-y-7 {
    top: -0.7rem;
    bottom: -0.7rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-7,[dir='ltr'].sm\:ltr\:-inset-x-7 {
    right: -0.7rem;
    left: -0.7rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-8,[dir='ltr'].sm\:ltr\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-8,[dir='ltr'].sm\:ltr\:-inset-x-8 {
    right: -0.8rem;
    left: -0.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-9,[dir='ltr'].sm\:ltr\:-inset-y-9 {
    top: -0.9rem;
    bottom: -0.9rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-9,[dir='ltr'].sm\:ltr\:-inset-x-9 {
    right: -0.9rem;
    left: -0.9rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-10,[dir='ltr'].sm\:ltr\:-inset-y-10 {
    top: -1rem;
    bottom: -1rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-10,[dir='ltr'].sm\:ltr\:-inset-x-10 {
    right: -1rem;
    left: -1rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-12,[dir='ltr'].sm\:ltr\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-12,[dir='ltr'].sm\:ltr\:-inset-x-12 {
    right: -1.2rem;
    left: -1.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-14,[dir='ltr'].sm\:ltr\:-inset-y-14 {
    top: -1.4rem;
    bottom: -1.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-14,[dir='ltr'].sm\:ltr\:-inset-x-14 {
    right: -1.4rem;
    left: -1.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-16,[dir='ltr'].sm\:ltr\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-16,[dir='ltr'].sm\:ltr\:-inset-x-16 {
    right: -1.6rem;
    left: -1.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-20,[dir='ltr'].sm\:ltr\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-20,[dir='ltr'].sm\:ltr\:-inset-x-20 {
    right: -2rem;
    left: -2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-24,[dir='ltr'].sm\:ltr\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-24,[dir='ltr'].sm\:ltr\:-inset-x-24 {
    right: -2.4rem;
    left: -2.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-28,[dir='ltr'].sm\:ltr\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-28,[dir='ltr'].sm\:ltr\:-inset-x-28 {
    right: -2.8rem;
    left: -2.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-32,[dir='ltr'].sm\:ltr\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-32,[dir='ltr'].sm\:ltr\:-inset-x-32 {
    right: -3.2rem;
    left: -3.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-36,[dir='ltr'].sm\:ltr\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-36,[dir='ltr'].sm\:ltr\:-inset-x-36 {
    right: -3.6rem;
    left: -3.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-40,[dir='ltr'].sm\:ltr\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-40,[dir='ltr'].sm\:ltr\:-inset-x-40 {
    right: -4rem;
    left: -4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-44,[dir='ltr'].sm\:ltr\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-44,[dir='ltr'].sm\:ltr\:-inset-x-44 {
    right: -4.4rem;
    left: -4.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-48,[dir='ltr'].sm\:ltr\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-48,[dir='ltr'].sm\:ltr\:-inset-x-48 {
    right: -4.8rem;
    left: -4.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-52,[dir='ltr'].sm\:ltr\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-52,[dir='ltr'].sm\:ltr\:-inset-x-52 {
    right: -5.2rem;
    left: -5.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-56,[dir='ltr'].sm\:ltr\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-56,[dir='ltr'].sm\:ltr\:-inset-x-56 {
    right: -5.6rem;
    left: -5.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-60,[dir='ltr'].sm\:ltr\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-60,[dir='ltr'].sm\:ltr\:-inset-x-60 {
    right: -6rem;
    left: -6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-64,[dir='ltr'].sm\:ltr\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-64,[dir='ltr'].sm\:ltr\:-inset-x-64 {
    right: -6.4rem;
    left: -6.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-68,[dir='ltr'].sm\:ltr\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-68,[dir='ltr'].sm\:ltr\:-inset-x-68 {
    right: -6.8rem;
    left: -6.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-72,[dir='ltr'].sm\:ltr\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-72,[dir='ltr'].sm\:ltr\:-inset-x-72 {
    right: -7.2rem;
    left: -7.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-76,[dir='ltr'].sm\:ltr\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-76,[dir='ltr'].sm\:ltr\:-inset-x-76 {
    right: -7.6rem;
    left: -7.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-80,[dir='ltr'].sm\:ltr\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-80,[dir='ltr'].sm\:ltr\:-inset-x-80 {
    right: -8rem;
    left: -8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-84,[dir='ltr'].sm\:ltr\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-84,[dir='ltr'].sm\:ltr\:-inset-x-84 {
    right: -8.4rem;
    left: -8.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-88,[dir='ltr'].sm\:ltr\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-88,[dir='ltr'].sm\:ltr\:-inset-x-88 {
    right: -8.8rem;
    left: -8.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-92,[dir='ltr'].sm\:ltr\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-92,[dir='ltr'].sm\:ltr\:-inset-x-92 {
    right: -9.2rem;
    left: -9.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-96,[dir='ltr'].sm\:ltr\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-96,[dir='ltr'].sm\:ltr\:-inset-x-96 {
    right: -9.6rem;
    left: -9.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-128,[dir='ltr'].sm\:ltr\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-128,[dir='ltr'].sm\:ltr\:-inset-x-128 {
    right: -12.8rem;
    left: -12.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-136,[dir='ltr'].sm\:ltr\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-136,[dir='ltr'].sm\:ltr\:-inset-x-136 {
    right: -13.6rem;
    left: -13.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-160,[dir='ltr'].sm\:ltr\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-160,[dir='ltr'].sm\:ltr\:-inset-x-160 {
    right: -16rem;
    left: -16rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-192,[dir='ltr'].sm\:ltr\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-192,[dir='ltr'].sm\:ltr\:-inset-x-192 {
    right: -19.2rem;
    left: -19.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-200,[dir='ltr'].sm\:ltr\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-200,[dir='ltr'].sm\:ltr\:-inset-x-200 {
    right: -20rem;
    left: -20rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-208,[dir='ltr'].sm\:ltr\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-208,[dir='ltr'].sm\:ltr\:-inset-x-208 {
    right: -20.8rem;
    left: -20.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-216,[dir='ltr'].sm\:ltr\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-216,[dir='ltr'].sm\:ltr\:-inset-x-216 {
    right: -21.6rem;
    left: -21.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-224,[dir='ltr'].sm\:ltr\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-224,[dir='ltr'].sm\:ltr\:-inset-x-224 {
    right: -22.4rem;
    left: -22.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-256,[dir='ltr'].sm\:ltr\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-256,[dir='ltr'].sm\:ltr\:-inset-x-256 {
    right: -25.6rem;
    left: -25.6rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-288,[dir='ltr'].sm\:ltr\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-288,[dir='ltr'].sm\:ltr\:-inset-x-288 {
    right: -28.8rem;
    left: -28.8rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-320,[dir='ltr'].sm\:ltr\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-320,[dir='ltr'].sm\:ltr\:-inset-x-320 {
    right: -32rem;
    left: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-360,[dir='ltr'].sm\:ltr\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-360,[dir='ltr'].sm\:ltr\:-inset-x-360 {
    right: -36rem;
    left: -36rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-384,[dir='ltr'].sm\:ltr\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-384,[dir='ltr'].sm\:ltr\:-inset-x-384 {
    right: -38.4rem;
    left: -38.4rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-400,[dir='ltr'].sm\:ltr\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-400,[dir='ltr'].sm\:ltr\:-inset-x-400 {
    right: -40rem;
    left: -40rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-512,[dir='ltr'].sm\:ltr\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-512,[dir='ltr'].sm\:ltr\:-inset-x-512 {
    right: -51.2rem;
    left: -51.2rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-640,[dir='ltr'].sm\:ltr\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-640,[dir='ltr'].sm\:ltr\:-inset-x-640 {
    right: -64rem;
    left: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-xs,[dir='ltr'].sm\:ltr\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-xs,[dir='ltr'].sm\:ltr\:-inset-x-xs {
    right: -32rem;
    left: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-sm,[dir='ltr'].sm\:ltr\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-sm,[dir='ltr'].sm\:ltr\:-inset-x-sm {
    right: -48rem;
    left: -48rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-md,[dir='ltr'].sm\:ltr\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-md,[dir='ltr'].sm\:ltr\:-inset-x-md {
    right: -64rem;
    left: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-lg,[dir='ltr'].sm\:ltr\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-lg,[dir='ltr'].sm\:ltr\:-inset-x-lg {
    right: -80rem;
    left: -80rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-xl,[dir='ltr'].sm\:ltr\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-xl,[dir='ltr'].sm\:ltr\:-inset-x-xl {
    right: -96rem;
    left: -96rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-2xl,[dir='ltr'].sm\:ltr\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-2xl,[dir='ltr'].sm\:ltr\:-inset-x-2xl {
    right: -112rem;
    left: -112rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-3xl,[dir='ltr'].sm\:ltr\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-3xl,[dir='ltr'].sm\:ltr\:-inset-x-3xl {
    right: -128rem;
    left: -128rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-4xl,[dir='ltr'].sm\:ltr\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-4xl,[dir='ltr'].sm\:ltr\:-inset-x-4xl {
    right: -144rem;
    left: -144rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-5xl,[dir='ltr'].sm\:ltr\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-5xl,[dir='ltr'].sm\:ltr\:-inset-x-5xl {
    right: -160rem;
    left: -160rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-px,[dir='ltr'].sm\:ltr\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-px,[dir='ltr'].sm\:ltr\:-inset-x-px {
    right: -1px;
    left: -1px
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-0\.5,[dir='ltr'].sm\:ltr\:-inset-y-0\.5 {
    top: -0.05rem;
    bottom: -0.05rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-0\.5,[dir='ltr'].sm\:ltr\:-inset-x-0\.5 {
    right: -0.05rem;
    left: -0.05rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-1\.5,[dir='ltr'].sm\:ltr\:-inset-y-1\.5 {
    top: -0.15rem;
    bottom: -0.15rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-1\.5,[dir='ltr'].sm\:ltr\:-inset-x-1\.5 {
    right: -0.15rem;
    left: -0.15rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-2\.5,[dir='ltr'].sm\:ltr\:-inset-y-2\.5 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-2\.5,[dir='ltr'].sm\:ltr\:-inset-x-2\.5 {
    right: -0.25rem;
    left: -0.25rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-3\.5,[dir='ltr'].sm\:ltr\:-inset-y-3\.5 {
    top: -0.35rem;
    bottom: -0.35rem
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-3\.5,[dir='ltr'].sm\:ltr\:-inset-x-3\.5 {
    right: -0.35rem;
    left: -0.35rem
  }

  [dir='ltr'] .sm\:ltr\:inset-y-1\/2,[dir='ltr'].sm\:ltr\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  [dir='ltr'] .sm\:ltr\:inset-x-1\/2,[dir='ltr'].sm\:ltr\:inset-x-1\/2 {
    right: 50%;
    left: 50%
  }

  [dir='ltr'] .sm\:ltr\:inset-y-1\/3,[dir='ltr'].sm\:ltr\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  [dir='ltr'] .sm\:ltr\:inset-x-1\/3,[dir='ltr'].sm\:ltr\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%
  }

  [dir='ltr'] .sm\:ltr\:inset-y-2\/3,[dir='ltr'].sm\:ltr\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  [dir='ltr'] .sm\:ltr\:inset-x-2\/3,[dir='ltr'].sm\:ltr\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%
  }

  [dir='ltr'] .sm\:ltr\:inset-y-1\/4,[dir='ltr'].sm\:ltr\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  [dir='ltr'] .sm\:ltr\:inset-x-1\/4,[dir='ltr'].sm\:ltr\:inset-x-1\/4 {
    right: 25%;
    left: 25%
  }

  [dir='ltr'] .sm\:ltr\:inset-y-2\/4,[dir='ltr'].sm\:ltr\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  [dir='ltr'] .sm\:ltr\:inset-x-2\/4,[dir='ltr'].sm\:ltr\:inset-x-2\/4 {
    right: 50%;
    left: 50%
  }

  [dir='ltr'] .sm\:ltr\:inset-y-3\/4,[dir='ltr'].sm\:ltr\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  [dir='ltr'] .sm\:ltr\:inset-x-3\/4,[dir='ltr'].sm\:ltr\:inset-x-3\/4 {
    right: 75%;
    left: 75%
  }

  [dir='ltr'] .sm\:ltr\:inset-y-full,[dir='ltr'].sm\:ltr\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  [dir='ltr'] .sm\:ltr\:inset-x-full,[dir='ltr'].sm\:ltr\:inset-x-full {
    right: 100%;
    left: 100%
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-1\/2,[dir='ltr'].sm\:ltr\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-1\/2,[dir='ltr'].sm\:ltr\:-inset-x-1\/2 {
    right: -50%;
    left: -50%
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-1\/3,[dir='ltr'].sm\:ltr\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-1\/3,[dir='ltr'].sm\:ltr\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-2\/3,[dir='ltr'].sm\:ltr\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-2\/3,[dir='ltr'].sm\:ltr\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-1\/4,[dir='ltr'].sm\:ltr\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-1\/4,[dir='ltr'].sm\:ltr\:-inset-x-1\/4 {
    right: -25%;
    left: -25%
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-2\/4,[dir='ltr'].sm\:ltr\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-2\/4,[dir='ltr'].sm\:ltr\:-inset-x-2\/4 {
    right: -50%;
    left: -50%
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-3\/4,[dir='ltr'].sm\:ltr\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-3\/4,[dir='ltr'].sm\:ltr\:-inset-x-3\/4 {
    right: -75%;
    left: -75%
  }

  [dir='ltr'] .sm\:ltr\:-inset-y-full,[dir='ltr'].sm\:ltr\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  [dir='ltr'] .sm\:ltr\:-inset-x-full,[dir='ltr'].sm\:ltr\:-inset-x-full {
    right: -100%;
    left: -100%
  }

  [dir='ltr'] .sm\:ltr\:top-0,[dir='ltr'].sm\:ltr\:top-0 {
    top: 0
  }

  [dir='ltr'] .sm\:ltr\:right-0,[dir='ltr'].sm\:ltr\:right-0 {
    right: 0
  }

  [dir='ltr'] .sm\:ltr\:bottom-0,[dir='ltr'].sm\:ltr\:bottom-0 {
    bottom: 0
  }

  [dir='ltr'] .sm\:ltr\:left-0,[dir='ltr'].sm\:ltr\:left-0 {
    left: 0
  }

  [dir='ltr'] .sm\:ltr\:top-1,[dir='ltr'].sm\:ltr\:top-1 {
    top: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:right-1,[dir='ltr'].sm\:ltr\:right-1 {
    right: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-1,[dir='ltr'].sm\:ltr\:bottom-1 {
    bottom: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:left-1,[dir='ltr'].sm\:ltr\:left-1 {
    left: 0.1rem
  }

  [dir='ltr'] .sm\:ltr\:top-2,[dir='ltr'].sm\:ltr\:top-2 {
    top: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:right-2,[dir='ltr'].sm\:ltr\:right-2 {
    right: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-2,[dir='ltr'].sm\:ltr\:bottom-2 {
    bottom: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:left-2,[dir='ltr'].sm\:ltr\:left-2 {
    left: 0.2rem
  }

  [dir='ltr'] .sm\:ltr\:top-3,[dir='ltr'].sm\:ltr\:top-3 {
    top: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:right-3,[dir='ltr'].sm\:ltr\:right-3 {
    right: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-3,[dir='ltr'].sm\:ltr\:bottom-3 {
    bottom: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:left-3,[dir='ltr'].sm\:ltr\:left-3 {
    left: 0.3rem
  }

  [dir='ltr'] .sm\:ltr\:top-4,[dir='ltr'].sm\:ltr\:top-4 {
    top: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:right-4,[dir='ltr'].sm\:ltr\:right-4 {
    right: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-4,[dir='ltr'].sm\:ltr\:bottom-4 {
    bottom: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:left-4,[dir='ltr'].sm\:ltr\:left-4 {
    left: 0.4rem
  }

  [dir='ltr'] .sm\:ltr\:top-5,[dir='ltr'].sm\:ltr\:top-5 {
    top: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:right-5,[dir='ltr'].sm\:ltr\:right-5 {
    right: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-5,[dir='ltr'].sm\:ltr\:bottom-5 {
    bottom: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:left-5,[dir='ltr'].sm\:ltr\:left-5 {
    left: 0.5rem
  }

  [dir='ltr'] .sm\:ltr\:top-6,[dir='ltr'].sm\:ltr\:top-6 {
    top: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:right-6,[dir='ltr'].sm\:ltr\:right-6 {
    right: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-6,[dir='ltr'].sm\:ltr\:bottom-6 {
    bottom: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:left-6,[dir='ltr'].sm\:ltr\:left-6 {
    left: 0.6rem
  }

  [dir='ltr'] .sm\:ltr\:top-7,[dir='ltr'].sm\:ltr\:top-7 {
    top: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:right-7,[dir='ltr'].sm\:ltr\:right-7 {
    right: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-7,[dir='ltr'].sm\:ltr\:bottom-7 {
    bottom: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:left-7,[dir='ltr'].sm\:ltr\:left-7 {
    left: 0.7rem
  }

  [dir='ltr'] .sm\:ltr\:top-8,[dir='ltr'].sm\:ltr\:top-8 {
    top: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:right-8,[dir='ltr'].sm\:ltr\:right-8 {
    right: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-8,[dir='ltr'].sm\:ltr\:bottom-8 {
    bottom: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:left-8,[dir='ltr'].sm\:ltr\:left-8 {
    left: 0.8rem
  }

  [dir='ltr'] .sm\:ltr\:top-9,[dir='ltr'].sm\:ltr\:top-9 {
    top: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:right-9,[dir='ltr'].sm\:ltr\:right-9 {
    right: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-9,[dir='ltr'].sm\:ltr\:bottom-9 {
    bottom: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:left-9,[dir='ltr'].sm\:ltr\:left-9 {
    left: 0.9rem
  }

  [dir='ltr'] .sm\:ltr\:top-10,[dir='ltr'].sm\:ltr\:top-10 {
    top: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:right-10,[dir='ltr'].sm\:ltr\:right-10 {
    right: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-10,[dir='ltr'].sm\:ltr\:bottom-10 {
    bottom: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:left-10,[dir='ltr'].sm\:ltr\:left-10 {
    left: 1.0rem
  }

  [dir='ltr'] .sm\:ltr\:top-12,[dir='ltr'].sm\:ltr\:top-12 {
    top: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:right-12,[dir='ltr'].sm\:ltr\:right-12 {
    right: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-12,[dir='ltr'].sm\:ltr\:bottom-12 {
    bottom: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:left-12,[dir='ltr'].sm\:ltr\:left-12 {
    left: 1.2rem
  }

  [dir='ltr'] .sm\:ltr\:top-14,[dir='ltr'].sm\:ltr\:top-14 {
    top: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:right-14,[dir='ltr'].sm\:ltr\:right-14 {
    right: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-14,[dir='ltr'].sm\:ltr\:bottom-14 {
    bottom: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:left-14,[dir='ltr'].sm\:ltr\:left-14 {
    left: 1.4rem
  }

  [dir='ltr'] .sm\:ltr\:top-16,[dir='ltr'].sm\:ltr\:top-16 {
    top: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:right-16,[dir='ltr'].sm\:ltr\:right-16 {
    right: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-16,[dir='ltr'].sm\:ltr\:bottom-16 {
    bottom: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:left-16,[dir='ltr'].sm\:ltr\:left-16 {
    left: 1.6rem
  }

  [dir='ltr'] .sm\:ltr\:top-20,[dir='ltr'].sm\:ltr\:top-20 {
    top: 2rem
  }

  [dir='ltr'] .sm\:ltr\:right-20,[dir='ltr'].sm\:ltr\:right-20 {
    right: 2rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-20,[dir='ltr'].sm\:ltr\:bottom-20 {
    bottom: 2rem
  }

  [dir='ltr'] .sm\:ltr\:left-20,[dir='ltr'].sm\:ltr\:left-20 {
    left: 2rem
  }

  [dir='ltr'] .sm\:ltr\:top-24,[dir='ltr'].sm\:ltr\:top-24 {
    top: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:right-24,[dir='ltr'].sm\:ltr\:right-24 {
    right: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-24,[dir='ltr'].sm\:ltr\:bottom-24 {
    bottom: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:left-24,[dir='ltr'].sm\:ltr\:left-24 {
    left: 2.4rem
  }

  [dir='ltr'] .sm\:ltr\:top-28,[dir='ltr'].sm\:ltr\:top-28 {
    top: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:right-28,[dir='ltr'].sm\:ltr\:right-28 {
    right: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-28,[dir='ltr'].sm\:ltr\:bottom-28 {
    bottom: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:left-28,[dir='ltr'].sm\:ltr\:left-28 {
    left: 2.8rem
  }

  [dir='ltr'] .sm\:ltr\:top-32,[dir='ltr'].sm\:ltr\:top-32 {
    top: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:right-32,[dir='ltr'].sm\:ltr\:right-32 {
    right: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-32,[dir='ltr'].sm\:ltr\:bottom-32 {
    bottom: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:left-32,[dir='ltr'].sm\:ltr\:left-32 {
    left: 3.2rem
  }

  [dir='ltr'] .sm\:ltr\:top-36,[dir='ltr'].sm\:ltr\:top-36 {
    top: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:right-36,[dir='ltr'].sm\:ltr\:right-36 {
    right: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-36,[dir='ltr'].sm\:ltr\:bottom-36 {
    bottom: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:left-36,[dir='ltr'].sm\:ltr\:left-36 {
    left: 3.6rem
  }

  [dir='ltr'] .sm\:ltr\:top-40,[dir='ltr'].sm\:ltr\:top-40 {
    top: 4rem
  }

  [dir='ltr'] .sm\:ltr\:right-40,[dir='ltr'].sm\:ltr\:right-40 {
    right: 4rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-40,[dir='ltr'].sm\:ltr\:bottom-40 {
    bottom: 4rem
  }

  [dir='ltr'] .sm\:ltr\:left-40,[dir='ltr'].sm\:ltr\:left-40 {
    left: 4rem
  }

  [dir='ltr'] .sm\:ltr\:top-44,[dir='ltr'].sm\:ltr\:top-44 {
    top: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:right-44,[dir='ltr'].sm\:ltr\:right-44 {
    right: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-44,[dir='ltr'].sm\:ltr\:bottom-44 {
    bottom: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:left-44,[dir='ltr'].sm\:ltr\:left-44 {
    left: 4.4rem
  }

  [dir='ltr'] .sm\:ltr\:top-48,[dir='ltr'].sm\:ltr\:top-48 {
    top: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:right-48,[dir='ltr'].sm\:ltr\:right-48 {
    right: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-48,[dir='ltr'].sm\:ltr\:bottom-48 {
    bottom: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:left-48,[dir='ltr'].sm\:ltr\:left-48 {
    left: 4.8rem
  }

  [dir='ltr'] .sm\:ltr\:top-52,[dir='ltr'].sm\:ltr\:top-52 {
    top: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:right-52,[dir='ltr'].sm\:ltr\:right-52 {
    right: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-52,[dir='ltr'].sm\:ltr\:bottom-52 {
    bottom: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:left-52,[dir='ltr'].sm\:ltr\:left-52 {
    left: 5.2rem
  }

  [dir='ltr'] .sm\:ltr\:top-56,[dir='ltr'].sm\:ltr\:top-56 {
    top: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:right-56,[dir='ltr'].sm\:ltr\:right-56 {
    right: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-56,[dir='ltr'].sm\:ltr\:bottom-56 {
    bottom: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:left-56,[dir='ltr'].sm\:ltr\:left-56 {
    left: 5.6rem
  }

  [dir='ltr'] .sm\:ltr\:top-60,[dir='ltr'].sm\:ltr\:top-60 {
    top: 6rem
  }

  [dir='ltr'] .sm\:ltr\:right-60,[dir='ltr'].sm\:ltr\:right-60 {
    right: 6rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-60,[dir='ltr'].sm\:ltr\:bottom-60 {
    bottom: 6rem
  }

  [dir='ltr'] .sm\:ltr\:left-60,[dir='ltr'].sm\:ltr\:left-60 {
    left: 6rem
  }

  [dir='ltr'] .sm\:ltr\:top-64,[dir='ltr'].sm\:ltr\:top-64 {
    top: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:right-64,[dir='ltr'].sm\:ltr\:right-64 {
    right: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-64,[dir='ltr'].sm\:ltr\:bottom-64 {
    bottom: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:left-64,[dir='ltr'].sm\:ltr\:left-64 {
    left: 6.4rem
  }

  [dir='ltr'] .sm\:ltr\:top-68,[dir='ltr'].sm\:ltr\:top-68 {
    top: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:right-68,[dir='ltr'].sm\:ltr\:right-68 {
    right: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-68,[dir='ltr'].sm\:ltr\:bottom-68 {
    bottom: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:left-68,[dir='ltr'].sm\:ltr\:left-68 {
    left: 6.8rem
  }

  [dir='ltr'] .sm\:ltr\:top-72,[dir='ltr'].sm\:ltr\:top-72 {
    top: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:right-72,[dir='ltr'].sm\:ltr\:right-72 {
    right: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-72,[dir='ltr'].sm\:ltr\:bottom-72 {
    bottom: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:left-72,[dir='ltr'].sm\:ltr\:left-72 {
    left: 7.2rem
  }

  [dir='ltr'] .sm\:ltr\:top-76,[dir='ltr'].sm\:ltr\:top-76 {
    top: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:right-76,[dir='ltr'].sm\:ltr\:right-76 {
    right: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-76,[dir='ltr'].sm\:ltr\:bottom-76 {
    bottom: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:left-76,[dir='ltr'].sm\:ltr\:left-76 {
    left: 7.6rem
  }

  [dir='ltr'] .sm\:ltr\:top-80,[dir='ltr'].sm\:ltr\:top-80 {
    top: 8rem
  }

  [dir='ltr'] .sm\:ltr\:right-80,[dir='ltr'].sm\:ltr\:right-80 {
    right: 8rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-80,[dir='ltr'].sm\:ltr\:bottom-80 {
    bottom: 8rem
  }

  [dir='ltr'] .sm\:ltr\:left-80,[dir='ltr'].sm\:ltr\:left-80 {
    left: 8rem
  }

  [dir='ltr'] .sm\:ltr\:top-84,[dir='ltr'].sm\:ltr\:top-84 {
    top: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:right-84,[dir='ltr'].sm\:ltr\:right-84 {
    right: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-84,[dir='ltr'].sm\:ltr\:bottom-84 {
    bottom: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:left-84,[dir='ltr'].sm\:ltr\:left-84 {
    left: 8.4rem
  }

  [dir='ltr'] .sm\:ltr\:top-88,[dir='ltr'].sm\:ltr\:top-88 {
    top: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:right-88,[dir='ltr'].sm\:ltr\:right-88 {
    right: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-88,[dir='ltr'].sm\:ltr\:bottom-88 {
    bottom: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:left-88,[dir='ltr'].sm\:ltr\:left-88 {
    left: 8.8rem
  }

  [dir='ltr'] .sm\:ltr\:top-92,[dir='ltr'].sm\:ltr\:top-92 {
    top: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:right-92,[dir='ltr'].sm\:ltr\:right-92 {
    right: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-92,[dir='ltr'].sm\:ltr\:bottom-92 {
    bottom: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:left-92,[dir='ltr'].sm\:ltr\:left-92 {
    left: 9.2rem
  }

  [dir='ltr'] .sm\:ltr\:top-96,[dir='ltr'].sm\:ltr\:top-96 {
    top: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:right-96,[dir='ltr'].sm\:ltr\:right-96 {
    right: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-96,[dir='ltr'].sm\:ltr\:bottom-96 {
    bottom: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:left-96,[dir='ltr'].sm\:ltr\:left-96 {
    left: 9.6rem
  }

  [dir='ltr'] .sm\:ltr\:top-128,[dir='ltr'].sm\:ltr\:top-128 {
    top: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:right-128,[dir='ltr'].sm\:ltr\:right-128 {
    right: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-128,[dir='ltr'].sm\:ltr\:bottom-128 {
    bottom: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:left-128,[dir='ltr'].sm\:ltr\:left-128 {
    left: 12.8rem
  }

  [dir='ltr'] .sm\:ltr\:top-136,[dir='ltr'].sm\:ltr\:top-136 {
    top: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:right-136,[dir='ltr'].sm\:ltr\:right-136 {
    right: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-136,[dir='ltr'].sm\:ltr\:bottom-136 {
    bottom: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:left-136,[dir='ltr'].sm\:ltr\:left-136 {
    left: 13.6rem
  }

  [dir='ltr'] .sm\:ltr\:top-160,[dir='ltr'].sm\:ltr\:top-160 {
    top: 16rem
  }

  [dir='ltr'] .sm\:ltr\:right-160,[dir='ltr'].sm\:ltr\:right-160 {
    right: 16rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-160,[dir='ltr'].sm\:ltr\:bottom-160 {
    bottom: 16rem
  }

  [dir='ltr'] .sm\:ltr\:left-160,[dir='ltr'].sm\:ltr\:left-160 {
    left: 16rem
  }

  [dir='ltr'] .sm\:ltr\:top-192,[dir='ltr'].sm\:ltr\:top-192 {
    top: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:right-192,[dir='ltr'].sm\:ltr\:right-192 {
    right: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-192,[dir='ltr'].sm\:ltr\:bottom-192 {
    bottom: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:left-192,[dir='ltr'].sm\:ltr\:left-192 {
    left: 19.2rem
  }

  [dir='ltr'] .sm\:ltr\:top-200,[dir='ltr'].sm\:ltr\:top-200 {
    top: 20rem
  }

  [dir='ltr'] .sm\:ltr\:right-200,[dir='ltr'].sm\:ltr\:right-200 {
    right: 20rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-200,[dir='ltr'].sm\:ltr\:bottom-200 {
    bottom: 20rem
  }

  [dir='ltr'] .sm\:ltr\:left-200,[dir='ltr'].sm\:ltr\:left-200 {
    left: 20rem
  }

  [dir='ltr'] .sm\:ltr\:top-208,[dir='ltr'].sm\:ltr\:top-208 {
    top: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:right-208,[dir='ltr'].sm\:ltr\:right-208 {
    right: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-208,[dir='ltr'].sm\:ltr\:bottom-208 {
    bottom: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:left-208,[dir='ltr'].sm\:ltr\:left-208 {
    left: 20.8rem
  }

  [dir='ltr'] .sm\:ltr\:top-216,[dir='ltr'].sm\:ltr\:top-216 {
    top: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:right-216,[dir='ltr'].sm\:ltr\:right-216 {
    right: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-216,[dir='ltr'].sm\:ltr\:bottom-216 {
    bottom: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:left-216,[dir='ltr'].sm\:ltr\:left-216 {
    left: 21.6rem
  }

  [dir='ltr'] .sm\:ltr\:top-224,[dir='ltr'].sm\:ltr\:top-224 {
    top: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:right-224,[dir='ltr'].sm\:ltr\:right-224 {
    right: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-224,[dir='ltr'].sm\:ltr\:bottom-224 {
    bottom: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:left-224,[dir='ltr'].sm\:ltr\:left-224 {
    left: 22.4rem
  }

  [dir='ltr'] .sm\:ltr\:top-256,[dir='ltr'].sm\:ltr\:top-256 {
    top: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:right-256,[dir='ltr'].sm\:ltr\:right-256 {
    right: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-256,[dir='ltr'].sm\:ltr\:bottom-256 {
    bottom: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:left-256,[dir='ltr'].sm\:ltr\:left-256 {
    left: 25.6rem
  }

  [dir='ltr'] .sm\:ltr\:top-288,[dir='ltr'].sm\:ltr\:top-288 {
    top: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:right-288,[dir='ltr'].sm\:ltr\:right-288 {
    right: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-288,[dir='ltr'].sm\:ltr\:bottom-288 {
    bottom: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:left-288,[dir='ltr'].sm\:ltr\:left-288 {
    left: 28.8rem
  }

  [dir='ltr'] .sm\:ltr\:top-320,[dir='ltr'].sm\:ltr\:top-320 {
    top: 32rem
  }

  [dir='ltr'] .sm\:ltr\:right-320,[dir='ltr'].sm\:ltr\:right-320 {
    right: 32rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-320,[dir='ltr'].sm\:ltr\:bottom-320 {
    bottom: 32rem
  }

  [dir='ltr'] .sm\:ltr\:left-320,[dir='ltr'].sm\:ltr\:left-320 {
    left: 32rem
  }

  [dir='ltr'] .sm\:ltr\:top-360,[dir='ltr'].sm\:ltr\:top-360 {
    top: 36rem
  }

  [dir='ltr'] .sm\:ltr\:right-360,[dir='ltr'].sm\:ltr\:right-360 {
    right: 36rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-360,[dir='ltr'].sm\:ltr\:bottom-360 {
    bottom: 36rem
  }

  [dir='ltr'] .sm\:ltr\:left-360,[dir='ltr'].sm\:ltr\:left-360 {
    left: 36rem
  }

  [dir='ltr'] .sm\:ltr\:top-384,[dir='ltr'].sm\:ltr\:top-384 {
    top: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:right-384,[dir='ltr'].sm\:ltr\:right-384 {
    right: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-384,[dir='ltr'].sm\:ltr\:bottom-384 {
    bottom: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:left-384,[dir='ltr'].sm\:ltr\:left-384 {
    left: 38.4rem
  }

  [dir='ltr'] .sm\:ltr\:top-400,[dir='ltr'].sm\:ltr\:top-400 {
    top: 40rem
  }

  [dir='ltr'] .sm\:ltr\:right-400,[dir='ltr'].sm\:ltr\:right-400 {
    right: 40rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-400,[dir='ltr'].sm\:ltr\:bottom-400 {
    bottom: 40rem
  }

  [dir='ltr'] .sm\:ltr\:left-400,[dir='ltr'].sm\:ltr\:left-400 {
    left: 40rem
  }

  [dir='ltr'] .sm\:ltr\:top-512,[dir='ltr'].sm\:ltr\:top-512 {
    top: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:right-512,[dir='ltr'].sm\:ltr\:right-512 {
    right: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-512,[dir='ltr'].sm\:ltr\:bottom-512 {
    bottom: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:left-512,[dir='ltr'].sm\:ltr\:left-512 {
    left: 51.2rem
  }

  [dir='ltr'] .sm\:ltr\:top-640,[dir='ltr'].sm\:ltr\:top-640 {
    top: 64rem
  }

  [dir='ltr'] .sm\:ltr\:right-640,[dir='ltr'].sm\:ltr\:right-640 {
    right: 64rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-640,[dir='ltr'].sm\:ltr\:bottom-640 {
    bottom: 64rem
  }

  [dir='ltr'] .sm\:ltr\:left-640,[dir='ltr'].sm\:ltr\:left-640 {
    left: 64rem
  }

  [dir='ltr'] .sm\:ltr\:top-auto,[dir='ltr'].sm\:ltr\:top-auto {
    top: auto
  }

  [dir='ltr'] .sm\:ltr\:right-auto,[dir='ltr'].sm\:ltr\:right-auto {
    right: auto
  }

  [dir='ltr'] .sm\:ltr\:bottom-auto,[dir='ltr'].sm\:ltr\:bottom-auto {
    bottom: auto
  }

  [dir='ltr'] .sm\:ltr\:left-auto,[dir='ltr'].sm\:ltr\:left-auto {
    left: auto
  }

  [dir='ltr'] .sm\:ltr\:top-xs,[dir='ltr'].sm\:ltr\:top-xs {
    top: 32rem
  }

  [dir='ltr'] .sm\:ltr\:right-xs,[dir='ltr'].sm\:ltr\:right-xs {
    right: 32rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-xs,[dir='ltr'].sm\:ltr\:bottom-xs {
    bottom: 32rem
  }

  [dir='ltr'] .sm\:ltr\:left-xs,[dir='ltr'].sm\:ltr\:left-xs {
    left: 32rem
  }

  [dir='ltr'] .sm\:ltr\:top-sm,[dir='ltr'].sm\:ltr\:top-sm {
    top: 48rem
  }

  [dir='ltr'] .sm\:ltr\:right-sm,[dir='ltr'].sm\:ltr\:right-sm {
    right: 48rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-sm,[dir='ltr'].sm\:ltr\:bottom-sm {
    bottom: 48rem
  }

  [dir='ltr'] .sm\:ltr\:left-sm,[dir='ltr'].sm\:ltr\:left-sm {
    left: 48rem
  }

  [dir='ltr'] .sm\:ltr\:top-md,[dir='ltr'].sm\:ltr\:top-md {
    top: 64rem
  }

  [dir='ltr'] .sm\:ltr\:right-md,[dir='ltr'].sm\:ltr\:right-md {
    right: 64rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-md,[dir='ltr'].sm\:ltr\:bottom-md {
    bottom: 64rem
  }

  [dir='ltr'] .sm\:ltr\:left-md,[dir='ltr'].sm\:ltr\:left-md {
    left: 64rem
  }

  [dir='ltr'] .sm\:ltr\:top-lg,[dir='ltr'].sm\:ltr\:top-lg {
    top: 80rem
  }

  [dir='ltr'] .sm\:ltr\:right-lg,[dir='ltr'].sm\:ltr\:right-lg {
    right: 80rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-lg,[dir='ltr'].sm\:ltr\:bottom-lg {
    bottom: 80rem
  }

  [dir='ltr'] .sm\:ltr\:left-lg,[dir='ltr'].sm\:ltr\:left-lg {
    left: 80rem
  }

  [dir='ltr'] .sm\:ltr\:top-xl,[dir='ltr'].sm\:ltr\:top-xl {
    top: 96rem
  }

  [dir='ltr'] .sm\:ltr\:right-xl,[dir='ltr'].sm\:ltr\:right-xl {
    right: 96rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-xl,[dir='ltr'].sm\:ltr\:bottom-xl {
    bottom: 96rem
  }

  [dir='ltr'] .sm\:ltr\:left-xl,[dir='ltr'].sm\:ltr\:left-xl {
    left: 96rem
  }

  [dir='ltr'] .sm\:ltr\:top-2xl,[dir='ltr'].sm\:ltr\:top-2xl {
    top: 112rem
  }

  [dir='ltr'] .sm\:ltr\:right-2xl,[dir='ltr'].sm\:ltr\:right-2xl {
    right: 112rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-2xl,[dir='ltr'].sm\:ltr\:bottom-2xl {
    bottom: 112rem
  }

  [dir='ltr'] .sm\:ltr\:left-2xl,[dir='ltr'].sm\:ltr\:left-2xl {
    left: 112rem
  }

  [dir='ltr'] .sm\:ltr\:top-3xl,[dir='ltr'].sm\:ltr\:top-3xl {
    top: 128rem
  }

  [dir='ltr'] .sm\:ltr\:right-3xl,[dir='ltr'].sm\:ltr\:right-3xl {
    right: 128rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-3xl,[dir='ltr'].sm\:ltr\:bottom-3xl {
    bottom: 128rem
  }

  [dir='ltr'] .sm\:ltr\:left-3xl,[dir='ltr'].sm\:ltr\:left-3xl {
    left: 128rem
  }

  [dir='ltr'] .sm\:ltr\:top-4xl,[dir='ltr'].sm\:ltr\:top-4xl {
    top: 144rem
  }

  [dir='ltr'] .sm\:ltr\:right-4xl,[dir='ltr'].sm\:ltr\:right-4xl {
    right: 144rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-4xl,[dir='ltr'].sm\:ltr\:bottom-4xl {
    bottom: 144rem
  }

  [dir='ltr'] .sm\:ltr\:left-4xl,[dir='ltr'].sm\:ltr\:left-4xl {
    left: 144rem
  }

  [dir='ltr'] .sm\:ltr\:top-5xl,[dir='ltr'].sm\:ltr\:top-5xl {
    top: 160rem
  }

  [dir='ltr'] .sm\:ltr\:right-5xl,[dir='ltr'].sm\:ltr\:right-5xl {
    right: 160rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-5xl,[dir='ltr'].sm\:ltr\:bottom-5xl {
    bottom: 160rem
  }

  [dir='ltr'] .sm\:ltr\:left-5xl,[dir='ltr'].sm\:ltr\:left-5xl {
    left: 160rem
  }

  [dir='ltr'] .sm\:ltr\:top-px,[dir='ltr'].sm\:ltr\:top-px {
    top: 1px
  }

  [dir='ltr'] .sm\:ltr\:right-px,[dir='ltr'].sm\:ltr\:right-px {
    right: 1px
  }

  [dir='ltr'] .sm\:ltr\:bottom-px,[dir='ltr'].sm\:ltr\:bottom-px {
    bottom: 1px
  }

  [dir='ltr'] .sm\:ltr\:left-px,[dir='ltr'].sm\:ltr\:left-px {
    left: 1px
  }

  [dir='ltr'] .sm\:ltr\:top-0\.5,[dir='ltr'].sm\:ltr\:top-0\.5 {
    top: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:right-0\.5,[dir='ltr'].sm\:ltr\:right-0\.5 {
    right: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-0\.5,[dir='ltr'].sm\:ltr\:bottom-0\.5 {
    bottom: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:left-0\.5,[dir='ltr'].sm\:ltr\:left-0\.5 {
    left: 0.05rem
  }

  [dir='ltr'] .sm\:ltr\:top-1\.5,[dir='ltr'].sm\:ltr\:top-1\.5 {
    top: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:right-1\.5,[dir='ltr'].sm\:ltr\:right-1\.5 {
    right: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-1\.5,[dir='ltr'].sm\:ltr\:bottom-1\.5 {
    bottom: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:left-1\.5,[dir='ltr'].sm\:ltr\:left-1\.5 {
    left: 0.15rem
  }

  [dir='ltr'] .sm\:ltr\:top-2\.5,[dir='ltr'].sm\:ltr\:top-2\.5 {
    top: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:right-2\.5,[dir='ltr'].sm\:ltr\:right-2\.5 {
    right: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-2\.5,[dir='ltr'].sm\:ltr\:bottom-2\.5 {
    bottom: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:left-2\.5,[dir='ltr'].sm\:ltr\:left-2\.5 {
    left: 0.25rem
  }

  [dir='ltr'] .sm\:ltr\:top-3\.5,[dir='ltr'].sm\:ltr\:top-3\.5 {
    top: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:right-3\.5,[dir='ltr'].sm\:ltr\:right-3\.5 {
    right: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:bottom-3\.5,[dir='ltr'].sm\:ltr\:bottom-3\.5 {
    bottom: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:left-3\.5,[dir='ltr'].sm\:ltr\:left-3\.5 {
    left: 0.35rem
  }

  [dir='ltr'] .sm\:ltr\:-top-1,[dir='ltr'].sm\:ltr\:-top-1 {
    top: -0.1rem
  }

  [dir='ltr'] .sm\:ltr\:-right-1,[dir='ltr'].sm\:ltr\:-right-1 {
    right: -0.1rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-1,[dir='ltr'].sm\:ltr\:-bottom-1 {
    bottom: -0.1rem
  }

  [dir='ltr'] .sm\:ltr\:-left-1,[dir='ltr'].sm\:ltr\:-left-1 {
    left: -0.1rem
  }

  [dir='ltr'] .sm\:ltr\:-top-2,[dir='ltr'].sm\:ltr\:-top-2 {
    top: -0.2rem
  }

  [dir='ltr'] .sm\:ltr\:-right-2,[dir='ltr'].sm\:ltr\:-right-2 {
    right: -0.2rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-2,[dir='ltr'].sm\:ltr\:-bottom-2 {
    bottom: -0.2rem
  }

  [dir='ltr'] .sm\:ltr\:-left-2,[dir='ltr'].sm\:ltr\:-left-2 {
    left: -0.2rem
  }

  [dir='ltr'] .sm\:ltr\:-top-3,[dir='ltr'].sm\:ltr\:-top-3 {
    top: -0.3rem
  }

  [dir='ltr'] .sm\:ltr\:-right-3,[dir='ltr'].sm\:ltr\:-right-3 {
    right: -0.3rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-3,[dir='ltr'].sm\:ltr\:-bottom-3 {
    bottom: -0.3rem
  }

  [dir='ltr'] .sm\:ltr\:-left-3,[dir='ltr'].sm\:ltr\:-left-3 {
    left: -0.3rem
  }

  [dir='ltr'] .sm\:ltr\:-top-4,[dir='ltr'].sm\:ltr\:-top-4 {
    top: -0.4rem
  }

  [dir='ltr'] .sm\:ltr\:-right-4,[dir='ltr'].sm\:ltr\:-right-4 {
    right: -0.4rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-4,[dir='ltr'].sm\:ltr\:-bottom-4 {
    bottom: -0.4rem
  }

  [dir='ltr'] .sm\:ltr\:-left-4,[dir='ltr'].sm\:ltr\:-left-4 {
    left: -0.4rem
  }

  [dir='ltr'] .sm\:ltr\:-top-5,[dir='ltr'].sm\:ltr\:-top-5 {
    top: -0.5rem
  }

  [dir='ltr'] .sm\:ltr\:-right-5,[dir='ltr'].sm\:ltr\:-right-5 {
    right: -0.5rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-5,[dir='ltr'].sm\:ltr\:-bottom-5 {
    bottom: -0.5rem
  }

  [dir='ltr'] .sm\:ltr\:-left-5,[dir='ltr'].sm\:ltr\:-left-5 {
    left: -0.5rem
  }

  [dir='ltr'] .sm\:ltr\:-top-6,[dir='ltr'].sm\:ltr\:-top-6 {
    top: -0.6rem
  }

  [dir='ltr'] .sm\:ltr\:-right-6,[dir='ltr'].sm\:ltr\:-right-6 {
    right: -0.6rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-6,[dir='ltr'].sm\:ltr\:-bottom-6 {
    bottom: -0.6rem
  }

  [dir='ltr'] .sm\:ltr\:-left-6,[dir='ltr'].sm\:ltr\:-left-6 {
    left: -0.6rem
  }

  [dir='ltr'] .sm\:ltr\:-top-7,[dir='ltr'].sm\:ltr\:-top-7 {
    top: -0.7rem
  }

  [dir='ltr'] .sm\:ltr\:-right-7,[dir='ltr'].sm\:ltr\:-right-7 {
    right: -0.7rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-7,[dir='ltr'].sm\:ltr\:-bottom-7 {
    bottom: -0.7rem
  }

  [dir='ltr'] .sm\:ltr\:-left-7,[dir='ltr'].sm\:ltr\:-left-7 {
    left: -0.7rem
  }

  [dir='ltr'] .sm\:ltr\:-top-8,[dir='ltr'].sm\:ltr\:-top-8 {
    top: -0.8rem
  }

  [dir='ltr'] .sm\:ltr\:-right-8,[dir='ltr'].sm\:ltr\:-right-8 {
    right: -0.8rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-8,[dir='ltr'].sm\:ltr\:-bottom-8 {
    bottom: -0.8rem
  }

  [dir='ltr'] .sm\:ltr\:-left-8,[dir='ltr'].sm\:ltr\:-left-8 {
    left: -0.8rem
  }

  [dir='ltr'] .sm\:ltr\:-top-9,[dir='ltr'].sm\:ltr\:-top-9 {
    top: -0.9rem
  }

  [dir='ltr'] .sm\:ltr\:-right-9,[dir='ltr'].sm\:ltr\:-right-9 {
    right: -0.9rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-9,[dir='ltr'].sm\:ltr\:-bottom-9 {
    bottom: -0.9rem
  }

  [dir='ltr'] .sm\:ltr\:-left-9,[dir='ltr'].sm\:ltr\:-left-9 {
    left: -0.9rem
  }

  [dir='ltr'] .sm\:ltr\:-top-10,[dir='ltr'].sm\:ltr\:-top-10 {
    top: -1rem
  }

  [dir='ltr'] .sm\:ltr\:-right-10,[dir='ltr'].sm\:ltr\:-right-10 {
    right: -1rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-10,[dir='ltr'].sm\:ltr\:-bottom-10 {
    bottom: -1rem
  }

  [dir='ltr'] .sm\:ltr\:-left-10,[dir='ltr'].sm\:ltr\:-left-10 {
    left: -1rem
  }

  [dir='ltr'] .sm\:ltr\:-top-12,[dir='ltr'].sm\:ltr\:-top-12 {
    top: -1.2rem
  }

  [dir='ltr'] .sm\:ltr\:-right-12,[dir='ltr'].sm\:ltr\:-right-12 {
    right: -1.2rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-12,[dir='ltr'].sm\:ltr\:-bottom-12 {
    bottom: -1.2rem
  }

  [dir='ltr'] .sm\:ltr\:-left-12,[dir='ltr'].sm\:ltr\:-left-12 {
    left: -1.2rem
  }

  [dir='ltr'] .sm\:ltr\:-top-14,[dir='ltr'].sm\:ltr\:-top-14 {
    top: -1.4rem
  }

  [dir='ltr'] .sm\:ltr\:-right-14,[dir='ltr'].sm\:ltr\:-right-14 {
    right: -1.4rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-14,[dir='ltr'].sm\:ltr\:-bottom-14 {
    bottom: -1.4rem
  }

  [dir='ltr'] .sm\:ltr\:-left-14,[dir='ltr'].sm\:ltr\:-left-14 {
    left: -1.4rem
  }

  [dir='ltr'] .sm\:ltr\:-top-16,[dir='ltr'].sm\:ltr\:-top-16 {
    top: -1.6rem
  }

  [dir='ltr'] .sm\:ltr\:-right-16,[dir='ltr'].sm\:ltr\:-right-16 {
    right: -1.6rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-16,[dir='ltr'].sm\:ltr\:-bottom-16 {
    bottom: -1.6rem
  }

  [dir='ltr'] .sm\:ltr\:-left-16,[dir='ltr'].sm\:ltr\:-left-16 {
    left: -1.6rem
  }

  [dir='ltr'] .sm\:ltr\:-top-20,[dir='ltr'].sm\:ltr\:-top-20 {
    top: -2rem
  }

  [dir='ltr'] .sm\:ltr\:-right-20,[dir='ltr'].sm\:ltr\:-right-20 {
    right: -2rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-20,[dir='ltr'].sm\:ltr\:-bottom-20 {
    bottom: -2rem
  }

  [dir='ltr'] .sm\:ltr\:-left-20,[dir='ltr'].sm\:ltr\:-left-20 {
    left: -2rem
  }

  [dir='ltr'] .sm\:ltr\:-top-24,[dir='ltr'].sm\:ltr\:-top-24 {
    top: -2.4rem
  }

  [dir='ltr'] .sm\:ltr\:-right-24,[dir='ltr'].sm\:ltr\:-right-24 {
    right: -2.4rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-24,[dir='ltr'].sm\:ltr\:-bottom-24 {
    bottom: -2.4rem
  }

  [dir='ltr'] .sm\:ltr\:-left-24,[dir='ltr'].sm\:ltr\:-left-24 {
    left: -2.4rem
  }

  [dir='ltr'] .sm\:ltr\:-top-28,[dir='ltr'].sm\:ltr\:-top-28 {
    top: -2.8rem
  }

  [dir='ltr'] .sm\:ltr\:-right-28,[dir='ltr'].sm\:ltr\:-right-28 {
    right: -2.8rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-28,[dir='ltr'].sm\:ltr\:-bottom-28 {
    bottom: -2.8rem
  }

  [dir='ltr'] .sm\:ltr\:-left-28,[dir='ltr'].sm\:ltr\:-left-28 {
    left: -2.8rem
  }

  [dir='ltr'] .sm\:ltr\:-top-32,[dir='ltr'].sm\:ltr\:-top-32 {
    top: -3.2rem
  }

  [dir='ltr'] .sm\:ltr\:-right-32,[dir='ltr'].sm\:ltr\:-right-32 {
    right: -3.2rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-32,[dir='ltr'].sm\:ltr\:-bottom-32 {
    bottom: -3.2rem
  }

  [dir='ltr'] .sm\:ltr\:-left-32,[dir='ltr'].sm\:ltr\:-left-32 {
    left: -3.2rem
  }

  [dir='ltr'] .sm\:ltr\:-top-36,[dir='ltr'].sm\:ltr\:-top-36 {
    top: -3.6rem
  }

  [dir='ltr'] .sm\:ltr\:-right-36,[dir='ltr'].sm\:ltr\:-right-36 {
    right: -3.6rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-36,[dir='ltr'].sm\:ltr\:-bottom-36 {
    bottom: -3.6rem
  }

  [dir='ltr'] .sm\:ltr\:-left-36,[dir='ltr'].sm\:ltr\:-left-36 {
    left: -3.6rem
  }

  [dir='ltr'] .sm\:ltr\:-top-40,[dir='ltr'].sm\:ltr\:-top-40 {
    top: -4rem
  }

  [dir='ltr'] .sm\:ltr\:-right-40,[dir='ltr'].sm\:ltr\:-right-40 {
    right: -4rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-40,[dir='ltr'].sm\:ltr\:-bottom-40 {
    bottom: -4rem
  }

  [dir='ltr'] .sm\:ltr\:-left-40,[dir='ltr'].sm\:ltr\:-left-40 {
    left: -4rem
  }

  [dir='ltr'] .sm\:ltr\:-top-44,[dir='ltr'].sm\:ltr\:-top-44 {
    top: -4.4rem
  }

  [dir='ltr'] .sm\:ltr\:-right-44,[dir='ltr'].sm\:ltr\:-right-44 {
    right: -4.4rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-44,[dir='ltr'].sm\:ltr\:-bottom-44 {
    bottom: -4.4rem
  }

  [dir='ltr'] .sm\:ltr\:-left-44,[dir='ltr'].sm\:ltr\:-left-44 {
    left: -4.4rem
  }

  [dir='ltr'] .sm\:ltr\:-top-48,[dir='ltr'].sm\:ltr\:-top-48 {
    top: -4.8rem
  }

  [dir='ltr'] .sm\:ltr\:-right-48,[dir='ltr'].sm\:ltr\:-right-48 {
    right: -4.8rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-48,[dir='ltr'].sm\:ltr\:-bottom-48 {
    bottom: -4.8rem
  }

  [dir='ltr'] .sm\:ltr\:-left-48,[dir='ltr'].sm\:ltr\:-left-48 {
    left: -4.8rem
  }

  [dir='ltr'] .sm\:ltr\:-top-52,[dir='ltr'].sm\:ltr\:-top-52 {
    top: -5.2rem
  }

  [dir='ltr'] .sm\:ltr\:-right-52,[dir='ltr'].sm\:ltr\:-right-52 {
    right: -5.2rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-52,[dir='ltr'].sm\:ltr\:-bottom-52 {
    bottom: -5.2rem
  }

  [dir='ltr'] .sm\:ltr\:-left-52,[dir='ltr'].sm\:ltr\:-left-52 {
    left: -5.2rem
  }

  [dir='ltr'] .sm\:ltr\:-top-56,[dir='ltr'].sm\:ltr\:-top-56 {
    top: -5.6rem
  }

  [dir='ltr'] .sm\:ltr\:-right-56,[dir='ltr'].sm\:ltr\:-right-56 {
    right: -5.6rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-56,[dir='ltr'].sm\:ltr\:-bottom-56 {
    bottom: -5.6rem
  }

  [dir='ltr'] .sm\:ltr\:-left-56,[dir='ltr'].sm\:ltr\:-left-56 {
    left: -5.6rem
  }

  [dir='ltr'] .sm\:ltr\:-top-60,[dir='ltr'].sm\:ltr\:-top-60 {
    top: -6rem
  }

  [dir='ltr'] .sm\:ltr\:-right-60,[dir='ltr'].sm\:ltr\:-right-60 {
    right: -6rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-60,[dir='ltr'].sm\:ltr\:-bottom-60 {
    bottom: -6rem
  }

  [dir='ltr'] .sm\:ltr\:-left-60,[dir='ltr'].sm\:ltr\:-left-60 {
    left: -6rem
  }

  [dir='ltr'] .sm\:ltr\:-top-64,[dir='ltr'].sm\:ltr\:-top-64 {
    top: -6.4rem
  }

  [dir='ltr'] .sm\:ltr\:-right-64,[dir='ltr'].sm\:ltr\:-right-64 {
    right: -6.4rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-64,[dir='ltr'].sm\:ltr\:-bottom-64 {
    bottom: -6.4rem
  }

  [dir='ltr'] .sm\:ltr\:-left-64,[dir='ltr'].sm\:ltr\:-left-64 {
    left: -6.4rem
  }

  [dir='ltr'] .sm\:ltr\:-top-68,[dir='ltr'].sm\:ltr\:-top-68 {
    top: -6.8rem
  }

  [dir='ltr'] .sm\:ltr\:-right-68,[dir='ltr'].sm\:ltr\:-right-68 {
    right: -6.8rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-68,[dir='ltr'].sm\:ltr\:-bottom-68 {
    bottom: -6.8rem
  }

  [dir='ltr'] .sm\:ltr\:-left-68,[dir='ltr'].sm\:ltr\:-left-68 {
    left: -6.8rem
  }

  [dir='ltr'] .sm\:ltr\:-top-72,[dir='ltr'].sm\:ltr\:-top-72 {
    top: -7.2rem
  }

  [dir='ltr'] .sm\:ltr\:-right-72,[dir='ltr'].sm\:ltr\:-right-72 {
    right: -7.2rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-72,[dir='ltr'].sm\:ltr\:-bottom-72 {
    bottom: -7.2rem
  }

  [dir='ltr'] .sm\:ltr\:-left-72,[dir='ltr'].sm\:ltr\:-left-72 {
    left: -7.2rem
  }

  [dir='ltr'] .sm\:ltr\:-top-76,[dir='ltr'].sm\:ltr\:-top-76 {
    top: -7.6rem
  }

  [dir='ltr'] .sm\:ltr\:-right-76,[dir='ltr'].sm\:ltr\:-right-76 {
    right: -7.6rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-76,[dir='ltr'].sm\:ltr\:-bottom-76 {
    bottom: -7.6rem
  }

  [dir='ltr'] .sm\:ltr\:-left-76,[dir='ltr'].sm\:ltr\:-left-76 {
    left: -7.6rem
  }

  [dir='ltr'] .sm\:ltr\:-top-80,[dir='ltr'].sm\:ltr\:-top-80 {
    top: -8rem
  }

  [dir='ltr'] .sm\:ltr\:-right-80,[dir='ltr'].sm\:ltr\:-right-80 {
    right: -8rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-80,[dir='ltr'].sm\:ltr\:-bottom-80 {
    bottom: -8rem
  }

  [dir='ltr'] .sm\:ltr\:-left-80,[dir='ltr'].sm\:ltr\:-left-80 {
    left: -8rem
  }

  [dir='ltr'] .sm\:ltr\:-top-84,[dir='ltr'].sm\:ltr\:-top-84 {
    top: -8.4rem
  }

  [dir='ltr'] .sm\:ltr\:-right-84,[dir='ltr'].sm\:ltr\:-right-84 {
    right: -8.4rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-84,[dir='ltr'].sm\:ltr\:-bottom-84 {
    bottom: -8.4rem
  }

  [dir='ltr'] .sm\:ltr\:-left-84,[dir='ltr'].sm\:ltr\:-left-84 {
    left: -8.4rem
  }

  [dir='ltr'] .sm\:ltr\:-top-88,[dir='ltr'].sm\:ltr\:-top-88 {
    top: -8.8rem
  }

  [dir='ltr'] .sm\:ltr\:-right-88,[dir='ltr'].sm\:ltr\:-right-88 {
    right: -8.8rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-88,[dir='ltr'].sm\:ltr\:-bottom-88 {
    bottom: -8.8rem
  }

  [dir='ltr'] .sm\:ltr\:-left-88,[dir='ltr'].sm\:ltr\:-left-88 {
    left: -8.8rem
  }

  [dir='ltr'] .sm\:ltr\:-top-92,[dir='ltr'].sm\:ltr\:-top-92 {
    top: -9.2rem
  }

  [dir='ltr'] .sm\:ltr\:-right-92,[dir='ltr'].sm\:ltr\:-right-92 {
    right: -9.2rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-92,[dir='ltr'].sm\:ltr\:-bottom-92 {
    bottom: -9.2rem
  }

  [dir='ltr'] .sm\:ltr\:-left-92,[dir='ltr'].sm\:ltr\:-left-92 {
    left: -9.2rem
  }

  [dir='ltr'] .sm\:ltr\:-top-96,[dir='ltr'].sm\:ltr\:-top-96 {
    top: -9.6rem
  }

  [dir='ltr'] .sm\:ltr\:-right-96,[dir='ltr'].sm\:ltr\:-right-96 {
    right: -9.6rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-96,[dir='ltr'].sm\:ltr\:-bottom-96 {
    bottom: -9.6rem
  }

  [dir='ltr'] .sm\:ltr\:-left-96,[dir='ltr'].sm\:ltr\:-left-96 {
    left: -9.6rem
  }

  [dir='ltr'] .sm\:ltr\:-top-128,[dir='ltr'].sm\:ltr\:-top-128 {
    top: -12.8rem
  }

  [dir='ltr'] .sm\:ltr\:-right-128,[dir='ltr'].sm\:ltr\:-right-128 {
    right: -12.8rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-128,[dir='ltr'].sm\:ltr\:-bottom-128 {
    bottom: -12.8rem
  }

  [dir='ltr'] .sm\:ltr\:-left-128,[dir='ltr'].sm\:ltr\:-left-128 {
    left: -12.8rem
  }

  [dir='ltr'] .sm\:ltr\:-top-136,[dir='ltr'].sm\:ltr\:-top-136 {
    top: -13.6rem
  }

  [dir='ltr'] .sm\:ltr\:-right-136,[dir='ltr'].sm\:ltr\:-right-136 {
    right: -13.6rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-136,[dir='ltr'].sm\:ltr\:-bottom-136 {
    bottom: -13.6rem
  }

  [dir='ltr'] .sm\:ltr\:-left-136,[dir='ltr'].sm\:ltr\:-left-136 {
    left: -13.6rem
  }

  [dir='ltr'] .sm\:ltr\:-top-160,[dir='ltr'].sm\:ltr\:-top-160 {
    top: -16rem
  }

  [dir='ltr'] .sm\:ltr\:-right-160,[dir='ltr'].sm\:ltr\:-right-160 {
    right: -16rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-160,[dir='ltr'].sm\:ltr\:-bottom-160 {
    bottom: -16rem
  }

  [dir='ltr'] .sm\:ltr\:-left-160,[dir='ltr'].sm\:ltr\:-left-160 {
    left: -16rem
  }

  [dir='ltr'] .sm\:ltr\:-top-192,[dir='ltr'].sm\:ltr\:-top-192 {
    top: -19.2rem
  }

  [dir='ltr'] .sm\:ltr\:-right-192,[dir='ltr'].sm\:ltr\:-right-192 {
    right: -19.2rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-192,[dir='ltr'].sm\:ltr\:-bottom-192 {
    bottom: -19.2rem
  }

  [dir='ltr'] .sm\:ltr\:-left-192,[dir='ltr'].sm\:ltr\:-left-192 {
    left: -19.2rem
  }

  [dir='ltr'] .sm\:ltr\:-top-200,[dir='ltr'].sm\:ltr\:-top-200 {
    top: -20rem
  }

  [dir='ltr'] .sm\:ltr\:-right-200,[dir='ltr'].sm\:ltr\:-right-200 {
    right: -20rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-200,[dir='ltr'].sm\:ltr\:-bottom-200 {
    bottom: -20rem
  }

  [dir='ltr'] .sm\:ltr\:-left-200,[dir='ltr'].sm\:ltr\:-left-200 {
    left: -20rem
  }

  [dir='ltr'] .sm\:ltr\:-top-208,[dir='ltr'].sm\:ltr\:-top-208 {
    top: -20.8rem
  }

  [dir='ltr'] .sm\:ltr\:-right-208,[dir='ltr'].sm\:ltr\:-right-208 {
    right: -20.8rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-208,[dir='ltr'].sm\:ltr\:-bottom-208 {
    bottom: -20.8rem
  }

  [dir='ltr'] .sm\:ltr\:-left-208,[dir='ltr'].sm\:ltr\:-left-208 {
    left: -20.8rem
  }

  [dir='ltr'] .sm\:ltr\:-top-216,[dir='ltr'].sm\:ltr\:-top-216 {
    top: -21.6rem
  }

  [dir='ltr'] .sm\:ltr\:-right-216,[dir='ltr'].sm\:ltr\:-right-216 {
    right: -21.6rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-216,[dir='ltr'].sm\:ltr\:-bottom-216 {
    bottom: -21.6rem
  }

  [dir='ltr'] .sm\:ltr\:-left-216,[dir='ltr'].sm\:ltr\:-left-216 {
    left: -21.6rem
  }

  [dir='ltr'] .sm\:ltr\:-top-224,[dir='ltr'].sm\:ltr\:-top-224 {
    top: -22.4rem
  }

  [dir='ltr'] .sm\:ltr\:-right-224,[dir='ltr'].sm\:ltr\:-right-224 {
    right: -22.4rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-224,[dir='ltr'].sm\:ltr\:-bottom-224 {
    bottom: -22.4rem
  }

  [dir='ltr'] .sm\:ltr\:-left-224,[dir='ltr'].sm\:ltr\:-left-224 {
    left: -22.4rem
  }

  [dir='ltr'] .sm\:ltr\:-top-256,[dir='ltr'].sm\:ltr\:-top-256 {
    top: -25.6rem
  }

  [dir='ltr'] .sm\:ltr\:-right-256,[dir='ltr'].sm\:ltr\:-right-256 {
    right: -25.6rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-256,[dir='ltr'].sm\:ltr\:-bottom-256 {
    bottom: -25.6rem
  }

  [dir='ltr'] .sm\:ltr\:-left-256,[dir='ltr'].sm\:ltr\:-left-256 {
    left: -25.6rem
  }

  [dir='ltr'] .sm\:ltr\:-top-288,[dir='ltr'].sm\:ltr\:-top-288 {
    top: -28.8rem
  }

  [dir='ltr'] .sm\:ltr\:-right-288,[dir='ltr'].sm\:ltr\:-right-288 {
    right: -28.8rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-288,[dir='ltr'].sm\:ltr\:-bottom-288 {
    bottom: -28.8rem
  }

  [dir='ltr'] .sm\:ltr\:-left-288,[dir='ltr'].sm\:ltr\:-left-288 {
    left: -28.8rem
  }

  [dir='ltr'] .sm\:ltr\:-top-320,[dir='ltr'].sm\:ltr\:-top-320 {
    top: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-right-320,[dir='ltr'].sm\:ltr\:-right-320 {
    right: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-320,[dir='ltr'].sm\:ltr\:-bottom-320 {
    bottom: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-left-320,[dir='ltr'].sm\:ltr\:-left-320 {
    left: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-top-360,[dir='ltr'].sm\:ltr\:-top-360 {
    top: -36rem
  }

  [dir='ltr'] .sm\:ltr\:-right-360,[dir='ltr'].sm\:ltr\:-right-360 {
    right: -36rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-360,[dir='ltr'].sm\:ltr\:-bottom-360 {
    bottom: -36rem
  }

  [dir='ltr'] .sm\:ltr\:-left-360,[dir='ltr'].sm\:ltr\:-left-360 {
    left: -36rem
  }

  [dir='ltr'] .sm\:ltr\:-top-384,[dir='ltr'].sm\:ltr\:-top-384 {
    top: -38.4rem
  }

  [dir='ltr'] .sm\:ltr\:-right-384,[dir='ltr'].sm\:ltr\:-right-384 {
    right: -38.4rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-384,[dir='ltr'].sm\:ltr\:-bottom-384 {
    bottom: -38.4rem
  }

  [dir='ltr'] .sm\:ltr\:-left-384,[dir='ltr'].sm\:ltr\:-left-384 {
    left: -38.4rem
  }

  [dir='ltr'] .sm\:ltr\:-top-400,[dir='ltr'].sm\:ltr\:-top-400 {
    top: -40rem
  }

  [dir='ltr'] .sm\:ltr\:-right-400,[dir='ltr'].sm\:ltr\:-right-400 {
    right: -40rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-400,[dir='ltr'].sm\:ltr\:-bottom-400 {
    bottom: -40rem
  }

  [dir='ltr'] .sm\:ltr\:-left-400,[dir='ltr'].sm\:ltr\:-left-400 {
    left: -40rem
  }

  [dir='ltr'] .sm\:ltr\:-top-512,[dir='ltr'].sm\:ltr\:-top-512 {
    top: -51.2rem
  }

  [dir='ltr'] .sm\:ltr\:-right-512,[dir='ltr'].sm\:ltr\:-right-512 {
    right: -51.2rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-512,[dir='ltr'].sm\:ltr\:-bottom-512 {
    bottom: -51.2rem
  }

  [dir='ltr'] .sm\:ltr\:-left-512,[dir='ltr'].sm\:ltr\:-left-512 {
    left: -51.2rem
  }

  [dir='ltr'] .sm\:ltr\:-top-640,[dir='ltr'].sm\:ltr\:-top-640 {
    top: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-right-640,[dir='ltr'].sm\:ltr\:-right-640 {
    right: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-640,[dir='ltr'].sm\:ltr\:-bottom-640 {
    bottom: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-left-640,[dir='ltr'].sm\:ltr\:-left-640 {
    left: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-top-xs,[dir='ltr'].sm\:ltr\:-top-xs {
    top: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-right-xs,[dir='ltr'].sm\:ltr\:-right-xs {
    right: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-xs,[dir='ltr'].sm\:ltr\:-bottom-xs {
    bottom: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-left-xs,[dir='ltr'].sm\:ltr\:-left-xs {
    left: -32rem
  }

  [dir='ltr'] .sm\:ltr\:-top-sm,[dir='ltr'].sm\:ltr\:-top-sm {
    top: -48rem
  }

  [dir='ltr'] .sm\:ltr\:-right-sm,[dir='ltr'].sm\:ltr\:-right-sm {
    right: -48rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-sm,[dir='ltr'].sm\:ltr\:-bottom-sm {
    bottom: -48rem
  }

  [dir='ltr'] .sm\:ltr\:-left-sm,[dir='ltr'].sm\:ltr\:-left-sm {
    left: -48rem
  }

  [dir='ltr'] .sm\:ltr\:-top-md,[dir='ltr'].sm\:ltr\:-top-md {
    top: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-right-md,[dir='ltr'].sm\:ltr\:-right-md {
    right: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-md,[dir='ltr'].sm\:ltr\:-bottom-md {
    bottom: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-left-md,[dir='ltr'].sm\:ltr\:-left-md {
    left: -64rem
  }

  [dir='ltr'] .sm\:ltr\:-top-lg,[dir='ltr'].sm\:ltr\:-top-lg {
    top: -80rem
  }

  [dir='ltr'] .sm\:ltr\:-right-lg,[dir='ltr'].sm\:ltr\:-right-lg {
    right: -80rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-lg,[dir='ltr'].sm\:ltr\:-bottom-lg {
    bottom: -80rem
  }

  [dir='ltr'] .sm\:ltr\:-left-lg,[dir='ltr'].sm\:ltr\:-left-lg {
    left: -80rem
  }

  [dir='ltr'] .sm\:ltr\:-top-xl,[dir='ltr'].sm\:ltr\:-top-xl {
    top: -96rem
  }

  [dir='ltr'] .sm\:ltr\:-right-xl,[dir='ltr'].sm\:ltr\:-right-xl {
    right: -96rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-xl,[dir='ltr'].sm\:ltr\:-bottom-xl {
    bottom: -96rem
  }

  [dir='ltr'] .sm\:ltr\:-left-xl,[dir='ltr'].sm\:ltr\:-left-xl {
    left: -96rem
  }

  [dir='ltr'] .sm\:ltr\:-top-2xl,[dir='ltr'].sm\:ltr\:-top-2xl {
    top: -112rem
  }

  [dir='ltr'] .sm\:ltr\:-right-2xl,[dir='ltr'].sm\:ltr\:-right-2xl {
    right: -112rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-2xl,[dir='ltr'].sm\:ltr\:-bottom-2xl {
    bottom: -112rem
  }

  [dir='ltr'] .sm\:ltr\:-left-2xl,[dir='ltr'].sm\:ltr\:-left-2xl {
    left: -112rem
  }

  [dir='ltr'] .sm\:ltr\:-top-3xl,[dir='ltr'].sm\:ltr\:-top-3xl {
    top: -128rem
  }

  [dir='ltr'] .sm\:ltr\:-right-3xl,[dir='ltr'].sm\:ltr\:-right-3xl {
    right: -128rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-3xl,[dir='ltr'].sm\:ltr\:-bottom-3xl {
    bottom: -128rem
  }

  [dir='ltr'] .sm\:ltr\:-left-3xl,[dir='ltr'].sm\:ltr\:-left-3xl {
    left: -128rem
  }

  [dir='ltr'] .sm\:ltr\:-top-4xl,[dir='ltr'].sm\:ltr\:-top-4xl {
    top: -144rem
  }

  [dir='ltr'] .sm\:ltr\:-right-4xl,[dir='ltr'].sm\:ltr\:-right-4xl {
    right: -144rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-4xl,[dir='ltr'].sm\:ltr\:-bottom-4xl {
    bottom: -144rem
  }

  [dir='ltr'] .sm\:ltr\:-left-4xl,[dir='ltr'].sm\:ltr\:-left-4xl {
    left: -144rem
  }

  [dir='ltr'] .sm\:ltr\:-top-5xl,[dir='ltr'].sm\:ltr\:-top-5xl {
    top: -160rem
  }

  [dir='ltr'] .sm\:ltr\:-right-5xl,[dir='ltr'].sm\:ltr\:-right-5xl {
    right: -160rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-5xl,[dir='ltr'].sm\:ltr\:-bottom-5xl {
    bottom: -160rem
  }

  [dir='ltr'] .sm\:ltr\:-left-5xl,[dir='ltr'].sm\:ltr\:-left-5xl {
    left: -160rem
  }

  [dir='ltr'] .sm\:ltr\:-top-px,[dir='ltr'].sm\:ltr\:-top-px {
    top: -1px
  }

  [dir='ltr'] .sm\:ltr\:-right-px,[dir='ltr'].sm\:ltr\:-right-px {
    right: -1px
  }

  [dir='ltr'] .sm\:ltr\:-bottom-px,[dir='ltr'].sm\:ltr\:-bottom-px {
    bottom: -1px
  }

  [dir='ltr'] .sm\:ltr\:-left-px,[dir='ltr'].sm\:ltr\:-left-px {
    left: -1px
  }

  [dir='ltr'] .sm\:ltr\:-top-0\.5,[dir='ltr'].sm\:ltr\:-top-0\.5 {
    top: -0.05rem
  }

  [dir='ltr'] .sm\:ltr\:-right-0\.5,[dir='ltr'].sm\:ltr\:-right-0\.5 {
    right: -0.05rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-0\.5,[dir='ltr'].sm\:ltr\:-bottom-0\.5 {
    bottom: -0.05rem
  }

  [dir='ltr'] .sm\:ltr\:-left-0\.5,[dir='ltr'].sm\:ltr\:-left-0\.5 {
    left: -0.05rem
  }

  [dir='ltr'] .sm\:ltr\:-top-1\.5,[dir='ltr'].sm\:ltr\:-top-1\.5 {
    top: -0.15rem
  }

  [dir='ltr'] .sm\:ltr\:-right-1\.5,[dir='ltr'].sm\:ltr\:-right-1\.5 {
    right: -0.15rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-1\.5,[dir='ltr'].sm\:ltr\:-bottom-1\.5 {
    bottom: -0.15rem
  }

  [dir='ltr'] .sm\:ltr\:-left-1\.5,[dir='ltr'].sm\:ltr\:-left-1\.5 {
    left: -0.15rem
  }

  [dir='ltr'] .sm\:ltr\:-top-2\.5,[dir='ltr'].sm\:ltr\:-top-2\.5 {
    top: -0.25rem
  }

  [dir='ltr'] .sm\:ltr\:-right-2\.5,[dir='ltr'].sm\:ltr\:-right-2\.5 {
    right: -0.25rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-2\.5,[dir='ltr'].sm\:ltr\:-bottom-2\.5 {
    bottom: -0.25rem
  }

  [dir='ltr'] .sm\:ltr\:-left-2\.5,[dir='ltr'].sm\:ltr\:-left-2\.5 {
    left: -0.25rem
  }

  [dir='ltr'] .sm\:ltr\:-top-3\.5,[dir='ltr'].sm\:ltr\:-top-3\.5 {
    top: -0.35rem
  }

  [dir='ltr'] .sm\:ltr\:-right-3\.5,[dir='ltr'].sm\:ltr\:-right-3\.5 {
    right: -0.35rem
  }

  [dir='ltr'] .sm\:ltr\:-bottom-3\.5,[dir='ltr'].sm\:ltr\:-bottom-3\.5 {
    bottom: -0.35rem
  }

  [dir='ltr'] .sm\:ltr\:-left-3\.5,[dir='ltr'].sm\:ltr\:-left-3\.5 {
    left: -0.35rem
  }

  [dir='ltr'] .sm\:ltr\:top-1\/2,[dir='ltr'].sm\:ltr\:top-1\/2 {
    top: 50%
  }

  [dir='ltr'] .sm\:ltr\:right-1\/2,[dir='ltr'].sm\:ltr\:right-1\/2 {
    right: 50%
  }

  [dir='ltr'] .sm\:ltr\:bottom-1\/2,[dir='ltr'].sm\:ltr\:bottom-1\/2 {
    bottom: 50%
  }

  [dir='ltr'] .sm\:ltr\:left-1\/2,[dir='ltr'].sm\:ltr\:left-1\/2 {
    left: 50%
  }

  [dir='ltr'] .sm\:ltr\:top-1\/3,[dir='ltr'].sm\:ltr\:top-1\/3 {
    top: 33.333333%
  }

  [dir='ltr'] .sm\:ltr\:right-1\/3,[dir='ltr'].sm\:ltr\:right-1\/3 {
    right: 33.333333%
  }

  [dir='ltr'] .sm\:ltr\:bottom-1\/3,[dir='ltr'].sm\:ltr\:bottom-1\/3 {
    bottom: 33.333333%
  }

  [dir='ltr'] .sm\:ltr\:left-1\/3,[dir='ltr'].sm\:ltr\:left-1\/3 {
    left: 33.333333%
  }

  [dir='ltr'] .sm\:ltr\:top-2\/3,[dir='ltr'].sm\:ltr\:top-2\/3 {
    top: 66.666667%
  }

  [dir='ltr'] .sm\:ltr\:right-2\/3,[dir='ltr'].sm\:ltr\:right-2\/3 {
    right: 66.666667%
  }

  [dir='ltr'] .sm\:ltr\:bottom-2\/3,[dir='ltr'].sm\:ltr\:bottom-2\/3 {
    bottom: 66.666667%
  }

  [dir='ltr'] .sm\:ltr\:left-2\/3,[dir='ltr'].sm\:ltr\:left-2\/3 {
    left: 66.666667%
  }

  [dir='ltr'] .sm\:ltr\:top-1\/4,[dir='ltr'].sm\:ltr\:top-1\/4 {
    top: 25%
  }

  [dir='ltr'] .sm\:ltr\:right-1\/4,[dir='ltr'].sm\:ltr\:right-1\/4 {
    right: 25%
  }

  [dir='ltr'] .sm\:ltr\:bottom-1\/4,[dir='ltr'].sm\:ltr\:bottom-1\/4 {
    bottom: 25%
  }

  [dir='ltr'] .sm\:ltr\:left-1\/4,[dir='ltr'].sm\:ltr\:left-1\/4 {
    left: 25%
  }

  [dir='ltr'] .sm\:ltr\:top-2\/4,[dir='ltr'].sm\:ltr\:top-2\/4 {
    top: 50%
  }

  [dir='ltr'] .sm\:ltr\:right-2\/4,[dir='ltr'].sm\:ltr\:right-2\/4 {
    right: 50%
  }

  [dir='ltr'] .sm\:ltr\:bottom-2\/4,[dir='ltr'].sm\:ltr\:bottom-2\/4 {
    bottom: 50%
  }

  [dir='ltr'] .sm\:ltr\:left-2\/4,[dir='ltr'].sm\:ltr\:left-2\/4 {
    left: 50%
  }

  [dir='ltr'] .sm\:ltr\:top-3\/4,[dir='ltr'].sm\:ltr\:top-3\/4 {
    top: 75%
  }

  [dir='ltr'] .sm\:ltr\:right-3\/4,[dir='ltr'].sm\:ltr\:right-3\/4 {
    right: 75%
  }

  [dir='ltr'] .sm\:ltr\:bottom-3\/4,[dir='ltr'].sm\:ltr\:bottom-3\/4 {
    bottom: 75%
  }

  [dir='ltr'] .sm\:ltr\:left-3\/4,[dir='ltr'].sm\:ltr\:left-3\/4 {
    left: 75%
  }

  [dir='ltr'] .sm\:ltr\:top-full,[dir='ltr'].sm\:ltr\:top-full {
    top: 100%
  }

  [dir='ltr'] .sm\:ltr\:right-full,[dir='ltr'].sm\:ltr\:right-full {
    right: 100%
  }

  [dir='ltr'] .sm\:ltr\:bottom-full,[dir='ltr'].sm\:ltr\:bottom-full {
    bottom: 100%
  }

  [dir='ltr'] .sm\:ltr\:left-full,[dir='ltr'].sm\:ltr\:left-full {
    left: 100%
  }

  [dir='ltr'] .sm\:ltr\:-top-1\/2,[dir='ltr'].sm\:ltr\:-top-1\/2 {
    top: -50%
  }

  [dir='ltr'] .sm\:ltr\:-right-1\/2,[dir='ltr'].sm\:ltr\:-right-1\/2 {
    right: -50%
  }

  [dir='ltr'] .sm\:ltr\:-bottom-1\/2,[dir='ltr'].sm\:ltr\:-bottom-1\/2 {
    bottom: -50%
  }

  [dir='ltr'] .sm\:ltr\:-left-1\/2,[dir='ltr'].sm\:ltr\:-left-1\/2 {
    left: -50%
  }

  [dir='ltr'] .sm\:ltr\:-top-1\/3,[dir='ltr'].sm\:ltr\:-top-1\/3 {
    top: -33.333333%
  }

  [dir='ltr'] .sm\:ltr\:-right-1\/3,[dir='ltr'].sm\:ltr\:-right-1\/3 {
    right: -33.333333%
  }

  [dir='ltr'] .sm\:ltr\:-bottom-1\/3,[dir='ltr'].sm\:ltr\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  [dir='ltr'] .sm\:ltr\:-left-1\/3,[dir='ltr'].sm\:ltr\:-left-1\/3 {
    left: -33.333333%
  }

  [dir='ltr'] .sm\:ltr\:-top-2\/3,[dir='ltr'].sm\:ltr\:-top-2\/3 {
    top: -66.666667%
  }

  [dir='ltr'] .sm\:ltr\:-right-2\/3,[dir='ltr'].sm\:ltr\:-right-2\/3 {
    right: -66.666667%
  }

  [dir='ltr'] .sm\:ltr\:-bottom-2\/3,[dir='ltr'].sm\:ltr\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  [dir='ltr'] .sm\:ltr\:-left-2\/3,[dir='ltr'].sm\:ltr\:-left-2\/3 {
    left: -66.666667%
  }

  [dir='ltr'] .sm\:ltr\:-top-1\/4,[dir='ltr'].sm\:ltr\:-top-1\/4 {
    top: -25%
  }

  [dir='ltr'] .sm\:ltr\:-right-1\/4,[dir='ltr'].sm\:ltr\:-right-1\/4 {
    right: -25%
  }

  [dir='ltr'] .sm\:ltr\:-bottom-1\/4,[dir='ltr'].sm\:ltr\:-bottom-1\/4 {
    bottom: -25%
  }

  [dir='ltr'] .sm\:ltr\:-left-1\/4,[dir='ltr'].sm\:ltr\:-left-1\/4 {
    left: -25%
  }

  [dir='ltr'] .sm\:ltr\:-top-2\/4,[dir='ltr'].sm\:ltr\:-top-2\/4 {
    top: -50%
  }

  [dir='ltr'] .sm\:ltr\:-right-2\/4,[dir='ltr'].sm\:ltr\:-right-2\/4 {
    right: -50%
  }

  [dir='ltr'] .sm\:ltr\:-bottom-2\/4,[dir='ltr'].sm\:ltr\:-bottom-2\/4 {
    bottom: -50%
  }

  [dir='ltr'] .sm\:ltr\:-left-2\/4,[dir='ltr'].sm\:ltr\:-left-2\/4 {
    left: -50%
  }

  [dir='ltr'] .sm\:ltr\:-top-3\/4,[dir='ltr'].sm\:ltr\:-top-3\/4 {
    top: -75%
  }

  [dir='ltr'] .sm\:ltr\:-right-3\/4,[dir='ltr'].sm\:ltr\:-right-3\/4 {
    right: -75%
  }

  [dir='ltr'] .sm\:ltr\:-bottom-3\/4,[dir='ltr'].sm\:ltr\:-bottom-3\/4 {
    bottom: -75%
  }

  [dir='ltr'] .sm\:ltr\:-left-3\/4,[dir='ltr'].sm\:ltr\:-left-3\/4 {
    left: -75%
  }

  [dir='ltr'] .sm\:ltr\:-top-full,[dir='ltr'].sm\:ltr\:-top-full {
    top: -100%
  }

  [dir='ltr'] .sm\:ltr\:-right-full,[dir='ltr'].sm\:ltr\:-right-full {
    right: -100%
  }

  [dir='ltr'] .sm\:ltr\:-bottom-full,[dir='ltr'].sm\:ltr\:-bottom-full {
    bottom: -100%
  }

  [dir='ltr'] .sm\:ltr\:-left-full,[dir='ltr'].sm\:ltr\:-left-full {
    left: -100%
  }

  [dir='rtl'] .sm\:rtl\:inset-0,[dir='rtl'].sm\:rtl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  [dir='rtl'] .sm\:rtl\:inset-1,[dir='rtl'].sm\:rtl\:inset-1 {
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:inset-2,[dir='rtl'].sm\:rtl\:inset-2 {
    top: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-3,[dir='rtl'].sm\:rtl\:inset-3 {
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:inset-4,[dir='rtl'].sm\:rtl\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-5,[dir='rtl'].sm\:rtl\:inset-5 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:inset-6,[dir='rtl'].sm\:rtl\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-7,[dir='rtl'].sm\:rtl\:inset-7 {
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
    left: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:inset-8,[dir='rtl'].sm\:rtl\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-9,[dir='rtl'].sm\:rtl\:inset-9 {
    top: 0.9rem;
    right: 0.9rem;
    bottom: 0.9rem;
    left: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:inset-10,[dir='rtl'].sm\:rtl\:inset-10 {
    top: 1.0rem;
    right: 1.0rem;
    bottom: 1.0rem;
    left: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:inset-12,[dir='rtl'].sm\:rtl\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-14,[dir='rtl'].sm\:rtl\:inset-14 {
    top: 1.4rem;
    right: 1.4rem;
    bottom: 1.4rem;
    left: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-16,[dir='rtl'].sm\:rtl\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-20,[dir='rtl'].sm\:rtl\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-24,[dir='rtl'].sm\:rtl\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-28,[dir='rtl'].sm\:rtl\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-32,[dir='rtl'].sm\:rtl\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-36,[dir='rtl'].sm\:rtl\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-40,[dir='rtl'].sm\:rtl\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-44,[dir='rtl'].sm\:rtl\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-48,[dir='rtl'].sm\:rtl\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-52,[dir='rtl'].sm\:rtl\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-56,[dir='rtl'].sm\:rtl\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-60,[dir='rtl'].sm\:rtl\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-64,[dir='rtl'].sm\:rtl\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-68,[dir='rtl'].sm\:rtl\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-72,[dir='rtl'].sm\:rtl\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-76,[dir='rtl'].sm\:rtl\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-80,[dir='rtl'].sm\:rtl\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-84,[dir='rtl'].sm\:rtl\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-88,[dir='rtl'].sm\:rtl\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-92,[dir='rtl'].sm\:rtl\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-96,[dir='rtl'].sm\:rtl\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-128,[dir='rtl'].sm\:rtl\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-136,[dir='rtl'].sm\:rtl\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-160,[dir='rtl'].sm\:rtl\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  [dir='rtl'] .sm\:rtl\:inset-192,[dir='rtl'].sm\:rtl\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-200,[dir='rtl'].sm\:rtl\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  [dir='rtl'] .sm\:rtl\:inset-208,[dir='rtl'].sm\:rtl\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-216,[dir='rtl'].sm\:rtl\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-224,[dir='rtl'].sm\:rtl\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-256,[dir='rtl'].sm\:rtl\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-288,[dir='rtl'].sm\:rtl\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-320,[dir='rtl'].sm\:rtl\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='rtl'] .sm\:rtl\:inset-360,[dir='rtl'].sm\:rtl\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  [dir='rtl'] .sm\:rtl\:inset-384,[dir='rtl'].sm\:rtl\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-400,[dir='rtl'].sm\:rtl\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  [dir='rtl'] .sm\:rtl\:inset-512,[dir='rtl'].sm\:rtl\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-640,[dir='rtl'].sm\:rtl\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='rtl'] .sm\:rtl\:inset-auto,[dir='rtl'].sm\:rtl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  [dir='rtl'] .sm\:rtl\:inset-xs,[dir='rtl'].sm\:rtl\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='rtl'] .sm\:rtl\:inset-sm,[dir='rtl'].sm\:rtl\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  [dir='rtl'] .sm\:rtl\:inset-md,[dir='rtl'].sm\:rtl\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='rtl'] .sm\:rtl\:inset-lg,[dir='rtl'].sm\:rtl\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  [dir='rtl'] .sm\:rtl\:inset-xl,[dir='rtl'].sm\:rtl\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  [dir='rtl'] .sm\:rtl\:inset-2xl,[dir='rtl'].sm\:rtl\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  [dir='rtl'] .sm\:rtl\:inset-3xl,[dir='rtl'].sm\:rtl\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  [dir='rtl'] .sm\:rtl\:inset-4xl,[dir='rtl'].sm\:rtl\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  [dir='rtl'] .sm\:rtl\:inset-5xl,[dir='rtl'].sm\:rtl\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  [dir='rtl'] .sm\:rtl\:inset-px,[dir='rtl'].sm\:rtl\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  [dir='rtl'] .sm\:rtl\:inset-0\.5,[dir='rtl'].sm\:rtl\:inset-0\.5 {
    top: 0.05rem;
    right: 0.05rem;
    bottom: 0.05rem;
    left: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:inset-1\.5,[dir='rtl'].sm\:rtl\:inset-1\.5 {
    top: 0.15rem;
    right: 0.15rem;
    bottom: 0.15rem;
    left: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:inset-2\.5,[dir='rtl'].sm\:rtl\:inset-2\.5 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:inset-3\.5,[dir='rtl'].sm\:rtl\:inset-3\.5 {
    top: 0.35rem;
    right: 0.35rem;
    bottom: 0.35rem;
    left: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-1,[dir='rtl'].sm\:rtl\:-inset-1 {
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-2,[dir='rtl'].sm\:rtl\:-inset-2 {
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-3,[dir='rtl'].sm\:rtl\:-inset-3 {
    top: -0.3rem;
    right: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-4,[dir='rtl'].sm\:rtl\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-5,[dir='rtl'].sm\:rtl\:-inset-5 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-6,[dir='rtl'].sm\:rtl\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-7,[dir='rtl'].sm\:rtl\:-inset-7 {
    top: -0.7rem;
    right: -0.7rem;
    bottom: -0.7rem;
    left: -0.7rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-8,[dir='rtl'].sm\:rtl\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-9,[dir='rtl'].sm\:rtl\:-inset-9 {
    top: -0.9rem;
    right: -0.9rem;
    bottom: -0.9rem;
    left: -0.9rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-10,[dir='rtl'].sm\:rtl\:-inset-10 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-12,[dir='rtl'].sm\:rtl\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-14,[dir='rtl'].sm\:rtl\:-inset-14 {
    top: -1.4rem;
    right: -1.4rem;
    bottom: -1.4rem;
    left: -1.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-16,[dir='rtl'].sm\:rtl\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-20,[dir='rtl'].sm\:rtl\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-24,[dir='rtl'].sm\:rtl\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-28,[dir='rtl'].sm\:rtl\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-32,[dir='rtl'].sm\:rtl\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-36,[dir='rtl'].sm\:rtl\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-40,[dir='rtl'].sm\:rtl\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-44,[dir='rtl'].sm\:rtl\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-48,[dir='rtl'].sm\:rtl\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-52,[dir='rtl'].sm\:rtl\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-56,[dir='rtl'].sm\:rtl\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-60,[dir='rtl'].sm\:rtl\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-64,[dir='rtl'].sm\:rtl\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-68,[dir='rtl'].sm\:rtl\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-72,[dir='rtl'].sm\:rtl\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-76,[dir='rtl'].sm\:rtl\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-80,[dir='rtl'].sm\:rtl\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-84,[dir='rtl'].sm\:rtl\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-88,[dir='rtl'].sm\:rtl\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-92,[dir='rtl'].sm\:rtl\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-96,[dir='rtl'].sm\:rtl\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-128,[dir='rtl'].sm\:rtl\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-136,[dir='rtl'].sm\:rtl\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-160,[dir='rtl'].sm\:rtl\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-192,[dir='rtl'].sm\:rtl\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-200,[dir='rtl'].sm\:rtl\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-208,[dir='rtl'].sm\:rtl\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-216,[dir='rtl'].sm\:rtl\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-224,[dir='rtl'].sm\:rtl\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-256,[dir='rtl'].sm\:rtl\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-288,[dir='rtl'].sm\:rtl\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-320,[dir='rtl'].sm\:rtl\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-360,[dir='rtl'].sm\:rtl\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-384,[dir='rtl'].sm\:rtl\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-400,[dir='rtl'].sm\:rtl\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-512,[dir='rtl'].sm\:rtl\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-640,[dir='rtl'].sm\:rtl\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-xs,[dir='rtl'].sm\:rtl\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-sm,[dir='rtl'].sm\:rtl\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-md,[dir='rtl'].sm\:rtl\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-lg,[dir='rtl'].sm\:rtl\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-xl,[dir='rtl'].sm\:rtl\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-2xl,[dir='rtl'].sm\:rtl\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-3xl,[dir='rtl'].sm\:rtl\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-4xl,[dir='rtl'].sm\:rtl\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-5xl,[dir='rtl'].sm\:rtl\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-px,[dir='rtl'].sm\:rtl\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  [dir='rtl'] .sm\:rtl\:-inset-0\.5,[dir='rtl'].sm\:rtl\:-inset-0\.5 {
    top: -0.05rem;
    right: -0.05rem;
    bottom: -0.05rem;
    left: -0.05rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-1\.5,[dir='rtl'].sm\:rtl\:-inset-1\.5 {
    top: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    left: -0.15rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-2\.5,[dir='rtl'].sm\:rtl\:-inset-2\.5 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-3\.5,[dir='rtl'].sm\:rtl\:-inset-3\.5 {
    top: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    left: -0.35rem
  }

  [dir='rtl'] .sm\:rtl\:inset-1\/2,[dir='rtl'].sm\:rtl\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='rtl'] .sm\:rtl\:inset-1\/3,[dir='rtl'].sm\:rtl\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  [dir='rtl'] .sm\:rtl\:inset-2\/3,[dir='rtl'].sm\:rtl\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  [dir='rtl'] .sm\:rtl\:inset-1\/4,[dir='rtl'].sm\:rtl\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  [dir='rtl'] .sm\:rtl\:inset-2\/4,[dir='rtl'].sm\:rtl\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='rtl'] .sm\:rtl\:inset-3\/4,[dir='rtl'].sm\:rtl\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  [dir='rtl'] .sm\:rtl\:inset-full,[dir='rtl'].sm\:rtl\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  [dir='rtl'] .sm\:rtl\:-inset-1\/2,[dir='rtl'].sm\:rtl\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='rtl'] .sm\:rtl\:-inset-1\/3,[dir='rtl'].sm\:rtl\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  [dir='rtl'] .sm\:rtl\:-inset-2\/3,[dir='rtl'].sm\:rtl\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  [dir='rtl'] .sm\:rtl\:-inset-1\/4,[dir='rtl'].sm\:rtl\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  [dir='rtl'] .sm\:rtl\:-inset-2\/4,[dir='rtl'].sm\:rtl\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='rtl'] .sm\:rtl\:-inset-3\/4,[dir='rtl'].sm\:rtl\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  [dir='rtl'] .sm\:rtl\:-inset-full,[dir='rtl'].sm\:rtl\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  [dir='rtl'] .sm\:rtl\:inset-y-0,[dir='rtl'].sm\:rtl\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  [dir='rtl'] .sm\:rtl\:inset-x-0,[dir='rtl'].sm\:rtl\:inset-x-0 {
    right: 0;
    left: 0
  }

  [dir='rtl'] .sm\:rtl\:inset-y-1,[dir='rtl'].sm\:rtl\:inset-y-1 {
    top: 0.1rem;
    bottom: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-1,[dir='rtl'].sm\:rtl\:inset-x-1 {
    right: 0.1rem;
    left: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-2,[dir='rtl'].sm\:rtl\:inset-y-2 {
    top: 0.2rem;
    bottom: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-2,[dir='rtl'].sm\:rtl\:inset-x-2 {
    right: 0.2rem;
    left: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-3,[dir='rtl'].sm\:rtl\:inset-y-3 {
    top: 0.3rem;
    bottom: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-3,[dir='rtl'].sm\:rtl\:inset-x-3 {
    right: 0.3rem;
    left: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-4,[dir='rtl'].sm\:rtl\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-4,[dir='rtl'].sm\:rtl\:inset-x-4 {
    right: 0.4rem;
    left: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-5,[dir='rtl'].sm\:rtl\:inset-y-5 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-5,[dir='rtl'].sm\:rtl\:inset-x-5 {
    right: 0.5rem;
    left: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-6,[dir='rtl'].sm\:rtl\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-6,[dir='rtl'].sm\:rtl\:inset-x-6 {
    right: 0.6rem;
    left: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-7,[dir='rtl'].sm\:rtl\:inset-y-7 {
    top: 0.7rem;
    bottom: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-7,[dir='rtl'].sm\:rtl\:inset-x-7 {
    right: 0.7rem;
    left: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-8,[dir='rtl'].sm\:rtl\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-8,[dir='rtl'].sm\:rtl\:inset-x-8 {
    right: 0.8rem;
    left: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-9,[dir='rtl'].sm\:rtl\:inset-y-9 {
    top: 0.9rem;
    bottom: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-9,[dir='rtl'].sm\:rtl\:inset-x-9 {
    right: 0.9rem;
    left: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-10,[dir='rtl'].sm\:rtl\:inset-y-10 {
    top: 1.0rem;
    bottom: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-10,[dir='rtl'].sm\:rtl\:inset-x-10 {
    right: 1.0rem;
    left: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-12,[dir='rtl'].sm\:rtl\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-12,[dir='rtl'].sm\:rtl\:inset-x-12 {
    right: 1.2rem;
    left: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-14,[dir='rtl'].sm\:rtl\:inset-y-14 {
    top: 1.4rem;
    bottom: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-14,[dir='rtl'].sm\:rtl\:inset-x-14 {
    right: 1.4rem;
    left: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-16,[dir='rtl'].sm\:rtl\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-16,[dir='rtl'].sm\:rtl\:inset-x-16 {
    right: 1.6rem;
    left: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-20,[dir='rtl'].sm\:rtl\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-20,[dir='rtl'].sm\:rtl\:inset-x-20 {
    right: 2rem;
    left: 2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-24,[dir='rtl'].sm\:rtl\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-24,[dir='rtl'].sm\:rtl\:inset-x-24 {
    right: 2.4rem;
    left: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-28,[dir='rtl'].sm\:rtl\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-28,[dir='rtl'].sm\:rtl\:inset-x-28 {
    right: 2.8rem;
    left: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-32,[dir='rtl'].sm\:rtl\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-32,[dir='rtl'].sm\:rtl\:inset-x-32 {
    right: 3.2rem;
    left: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-36,[dir='rtl'].sm\:rtl\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-36,[dir='rtl'].sm\:rtl\:inset-x-36 {
    right: 3.6rem;
    left: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-40,[dir='rtl'].sm\:rtl\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-40,[dir='rtl'].sm\:rtl\:inset-x-40 {
    right: 4rem;
    left: 4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-44,[dir='rtl'].sm\:rtl\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-44,[dir='rtl'].sm\:rtl\:inset-x-44 {
    right: 4.4rem;
    left: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-48,[dir='rtl'].sm\:rtl\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-48,[dir='rtl'].sm\:rtl\:inset-x-48 {
    right: 4.8rem;
    left: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-52,[dir='rtl'].sm\:rtl\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-52,[dir='rtl'].sm\:rtl\:inset-x-52 {
    right: 5.2rem;
    left: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-56,[dir='rtl'].sm\:rtl\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-56,[dir='rtl'].sm\:rtl\:inset-x-56 {
    right: 5.6rem;
    left: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-60,[dir='rtl'].sm\:rtl\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-60,[dir='rtl'].sm\:rtl\:inset-x-60 {
    right: 6rem;
    left: 6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-64,[dir='rtl'].sm\:rtl\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-64,[dir='rtl'].sm\:rtl\:inset-x-64 {
    right: 6.4rem;
    left: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-68,[dir='rtl'].sm\:rtl\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-68,[dir='rtl'].sm\:rtl\:inset-x-68 {
    right: 6.8rem;
    left: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-72,[dir='rtl'].sm\:rtl\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-72,[dir='rtl'].sm\:rtl\:inset-x-72 {
    right: 7.2rem;
    left: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-76,[dir='rtl'].sm\:rtl\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-76,[dir='rtl'].sm\:rtl\:inset-x-76 {
    right: 7.6rem;
    left: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-80,[dir='rtl'].sm\:rtl\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-80,[dir='rtl'].sm\:rtl\:inset-x-80 {
    right: 8rem;
    left: 8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-84,[dir='rtl'].sm\:rtl\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-84,[dir='rtl'].sm\:rtl\:inset-x-84 {
    right: 8.4rem;
    left: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-88,[dir='rtl'].sm\:rtl\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-88,[dir='rtl'].sm\:rtl\:inset-x-88 {
    right: 8.8rem;
    left: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-92,[dir='rtl'].sm\:rtl\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-92,[dir='rtl'].sm\:rtl\:inset-x-92 {
    right: 9.2rem;
    left: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-96,[dir='rtl'].sm\:rtl\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-96,[dir='rtl'].sm\:rtl\:inset-x-96 {
    right: 9.6rem;
    left: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-128,[dir='rtl'].sm\:rtl\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-128,[dir='rtl'].sm\:rtl\:inset-x-128 {
    right: 12.8rem;
    left: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-136,[dir='rtl'].sm\:rtl\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-136,[dir='rtl'].sm\:rtl\:inset-x-136 {
    right: 13.6rem;
    left: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-160,[dir='rtl'].sm\:rtl\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-160,[dir='rtl'].sm\:rtl\:inset-x-160 {
    right: 16rem;
    left: 16rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-192,[dir='rtl'].sm\:rtl\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-192,[dir='rtl'].sm\:rtl\:inset-x-192 {
    right: 19.2rem;
    left: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-200,[dir='rtl'].sm\:rtl\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-200,[dir='rtl'].sm\:rtl\:inset-x-200 {
    right: 20rem;
    left: 20rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-208,[dir='rtl'].sm\:rtl\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-208,[dir='rtl'].sm\:rtl\:inset-x-208 {
    right: 20.8rem;
    left: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-216,[dir='rtl'].sm\:rtl\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-216,[dir='rtl'].sm\:rtl\:inset-x-216 {
    right: 21.6rem;
    left: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-224,[dir='rtl'].sm\:rtl\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-224,[dir='rtl'].sm\:rtl\:inset-x-224 {
    right: 22.4rem;
    left: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-256,[dir='rtl'].sm\:rtl\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-256,[dir='rtl'].sm\:rtl\:inset-x-256 {
    right: 25.6rem;
    left: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-288,[dir='rtl'].sm\:rtl\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-288,[dir='rtl'].sm\:rtl\:inset-x-288 {
    right: 28.8rem;
    left: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-320,[dir='rtl'].sm\:rtl\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-320,[dir='rtl'].sm\:rtl\:inset-x-320 {
    right: 32rem;
    left: 32rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-360,[dir='rtl'].sm\:rtl\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-360,[dir='rtl'].sm\:rtl\:inset-x-360 {
    right: 36rem;
    left: 36rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-384,[dir='rtl'].sm\:rtl\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-384,[dir='rtl'].sm\:rtl\:inset-x-384 {
    right: 38.4rem;
    left: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-400,[dir='rtl'].sm\:rtl\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-400,[dir='rtl'].sm\:rtl\:inset-x-400 {
    right: 40rem;
    left: 40rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-512,[dir='rtl'].sm\:rtl\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-512,[dir='rtl'].sm\:rtl\:inset-x-512 {
    right: 51.2rem;
    left: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-640,[dir='rtl'].sm\:rtl\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-640,[dir='rtl'].sm\:rtl\:inset-x-640 {
    right: 64rem;
    left: 64rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-auto,[dir='rtl'].sm\:rtl\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  [dir='rtl'] .sm\:rtl\:inset-x-auto,[dir='rtl'].sm\:rtl\:inset-x-auto {
    right: auto;
    left: auto
  }

  [dir='rtl'] .sm\:rtl\:inset-y-xs,[dir='rtl'].sm\:rtl\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-xs,[dir='rtl'].sm\:rtl\:inset-x-xs {
    right: 32rem;
    left: 32rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-sm,[dir='rtl'].sm\:rtl\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-sm,[dir='rtl'].sm\:rtl\:inset-x-sm {
    right: 48rem;
    left: 48rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-md,[dir='rtl'].sm\:rtl\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-md,[dir='rtl'].sm\:rtl\:inset-x-md {
    right: 64rem;
    left: 64rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-lg,[dir='rtl'].sm\:rtl\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-lg,[dir='rtl'].sm\:rtl\:inset-x-lg {
    right: 80rem;
    left: 80rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-xl,[dir='rtl'].sm\:rtl\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-xl,[dir='rtl'].sm\:rtl\:inset-x-xl {
    right: 96rem;
    left: 96rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-2xl,[dir='rtl'].sm\:rtl\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-2xl,[dir='rtl'].sm\:rtl\:inset-x-2xl {
    right: 112rem;
    left: 112rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-3xl,[dir='rtl'].sm\:rtl\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-3xl,[dir='rtl'].sm\:rtl\:inset-x-3xl {
    right: 128rem;
    left: 128rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-4xl,[dir='rtl'].sm\:rtl\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-4xl,[dir='rtl'].sm\:rtl\:inset-x-4xl {
    right: 144rem;
    left: 144rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-5xl,[dir='rtl'].sm\:rtl\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-5xl,[dir='rtl'].sm\:rtl\:inset-x-5xl {
    right: 160rem;
    left: 160rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-px,[dir='rtl'].sm\:rtl\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  [dir='rtl'] .sm\:rtl\:inset-x-px,[dir='rtl'].sm\:rtl\:inset-x-px {
    right: 1px;
    left: 1px
  }

  [dir='rtl'] .sm\:rtl\:inset-y-0\.5,[dir='rtl'].sm\:rtl\:inset-y-0\.5 {
    top: 0.05rem;
    bottom: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-0\.5,[dir='rtl'].sm\:rtl\:inset-x-0\.5 {
    right: 0.05rem;
    left: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-1\.5,[dir='rtl'].sm\:rtl\:inset-y-1\.5 {
    top: 0.15rem;
    bottom: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-1\.5,[dir='rtl'].sm\:rtl\:inset-x-1\.5 {
    right: 0.15rem;
    left: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-2\.5,[dir='rtl'].sm\:rtl\:inset-y-2\.5 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-2\.5,[dir='rtl'].sm\:rtl\:inset-x-2\.5 {
    right: 0.25rem;
    left: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-3\.5,[dir='rtl'].sm\:rtl\:inset-y-3\.5 {
    top: 0.35rem;
    bottom: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:inset-x-3\.5,[dir='rtl'].sm\:rtl\:inset-x-3\.5 {
    right: 0.35rem;
    left: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-1,[dir='rtl'].sm\:rtl\:-inset-y-1 {
    top: -0.1rem;
    bottom: -0.1rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-1,[dir='rtl'].sm\:rtl\:-inset-x-1 {
    right: -0.1rem;
    left: -0.1rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-2,[dir='rtl'].sm\:rtl\:-inset-y-2 {
    top: -0.2rem;
    bottom: -0.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-2,[dir='rtl'].sm\:rtl\:-inset-x-2 {
    right: -0.2rem;
    left: -0.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-3,[dir='rtl'].sm\:rtl\:-inset-y-3 {
    top: -0.3rem;
    bottom: -0.3rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-3,[dir='rtl'].sm\:rtl\:-inset-x-3 {
    right: -0.3rem;
    left: -0.3rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-4,[dir='rtl'].sm\:rtl\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-4,[dir='rtl'].sm\:rtl\:-inset-x-4 {
    right: -0.4rem;
    left: -0.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-5,[dir='rtl'].sm\:rtl\:-inset-y-5 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-5,[dir='rtl'].sm\:rtl\:-inset-x-5 {
    right: -0.5rem;
    left: -0.5rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-6,[dir='rtl'].sm\:rtl\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-6,[dir='rtl'].sm\:rtl\:-inset-x-6 {
    right: -0.6rem;
    left: -0.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-7,[dir='rtl'].sm\:rtl\:-inset-y-7 {
    top: -0.7rem;
    bottom: -0.7rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-7,[dir='rtl'].sm\:rtl\:-inset-x-7 {
    right: -0.7rem;
    left: -0.7rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-8,[dir='rtl'].sm\:rtl\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-8,[dir='rtl'].sm\:rtl\:-inset-x-8 {
    right: -0.8rem;
    left: -0.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-9,[dir='rtl'].sm\:rtl\:-inset-y-9 {
    top: -0.9rem;
    bottom: -0.9rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-9,[dir='rtl'].sm\:rtl\:-inset-x-9 {
    right: -0.9rem;
    left: -0.9rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-10,[dir='rtl'].sm\:rtl\:-inset-y-10 {
    top: -1rem;
    bottom: -1rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-10,[dir='rtl'].sm\:rtl\:-inset-x-10 {
    right: -1rem;
    left: -1rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-12,[dir='rtl'].sm\:rtl\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-12,[dir='rtl'].sm\:rtl\:-inset-x-12 {
    right: -1.2rem;
    left: -1.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-14,[dir='rtl'].sm\:rtl\:-inset-y-14 {
    top: -1.4rem;
    bottom: -1.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-14,[dir='rtl'].sm\:rtl\:-inset-x-14 {
    right: -1.4rem;
    left: -1.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-16,[dir='rtl'].sm\:rtl\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-16,[dir='rtl'].sm\:rtl\:-inset-x-16 {
    right: -1.6rem;
    left: -1.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-20,[dir='rtl'].sm\:rtl\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-20,[dir='rtl'].sm\:rtl\:-inset-x-20 {
    right: -2rem;
    left: -2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-24,[dir='rtl'].sm\:rtl\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-24,[dir='rtl'].sm\:rtl\:-inset-x-24 {
    right: -2.4rem;
    left: -2.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-28,[dir='rtl'].sm\:rtl\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-28,[dir='rtl'].sm\:rtl\:-inset-x-28 {
    right: -2.8rem;
    left: -2.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-32,[dir='rtl'].sm\:rtl\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-32,[dir='rtl'].sm\:rtl\:-inset-x-32 {
    right: -3.2rem;
    left: -3.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-36,[dir='rtl'].sm\:rtl\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-36,[dir='rtl'].sm\:rtl\:-inset-x-36 {
    right: -3.6rem;
    left: -3.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-40,[dir='rtl'].sm\:rtl\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-40,[dir='rtl'].sm\:rtl\:-inset-x-40 {
    right: -4rem;
    left: -4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-44,[dir='rtl'].sm\:rtl\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-44,[dir='rtl'].sm\:rtl\:-inset-x-44 {
    right: -4.4rem;
    left: -4.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-48,[dir='rtl'].sm\:rtl\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-48,[dir='rtl'].sm\:rtl\:-inset-x-48 {
    right: -4.8rem;
    left: -4.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-52,[dir='rtl'].sm\:rtl\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-52,[dir='rtl'].sm\:rtl\:-inset-x-52 {
    right: -5.2rem;
    left: -5.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-56,[dir='rtl'].sm\:rtl\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-56,[dir='rtl'].sm\:rtl\:-inset-x-56 {
    right: -5.6rem;
    left: -5.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-60,[dir='rtl'].sm\:rtl\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-60,[dir='rtl'].sm\:rtl\:-inset-x-60 {
    right: -6rem;
    left: -6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-64,[dir='rtl'].sm\:rtl\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-64,[dir='rtl'].sm\:rtl\:-inset-x-64 {
    right: -6.4rem;
    left: -6.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-68,[dir='rtl'].sm\:rtl\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-68,[dir='rtl'].sm\:rtl\:-inset-x-68 {
    right: -6.8rem;
    left: -6.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-72,[dir='rtl'].sm\:rtl\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-72,[dir='rtl'].sm\:rtl\:-inset-x-72 {
    right: -7.2rem;
    left: -7.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-76,[dir='rtl'].sm\:rtl\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-76,[dir='rtl'].sm\:rtl\:-inset-x-76 {
    right: -7.6rem;
    left: -7.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-80,[dir='rtl'].sm\:rtl\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-80,[dir='rtl'].sm\:rtl\:-inset-x-80 {
    right: -8rem;
    left: -8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-84,[dir='rtl'].sm\:rtl\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-84,[dir='rtl'].sm\:rtl\:-inset-x-84 {
    right: -8.4rem;
    left: -8.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-88,[dir='rtl'].sm\:rtl\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-88,[dir='rtl'].sm\:rtl\:-inset-x-88 {
    right: -8.8rem;
    left: -8.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-92,[dir='rtl'].sm\:rtl\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-92,[dir='rtl'].sm\:rtl\:-inset-x-92 {
    right: -9.2rem;
    left: -9.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-96,[dir='rtl'].sm\:rtl\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-96,[dir='rtl'].sm\:rtl\:-inset-x-96 {
    right: -9.6rem;
    left: -9.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-128,[dir='rtl'].sm\:rtl\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-128,[dir='rtl'].sm\:rtl\:-inset-x-128 {
    right: -12.8rem;
    left: -12.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-136,[dir='rtl'].sm\:rtl\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-136,[dir='rtl'].sm\:rtl\:-inset-x-136 {
    right: -13.6rem;
    left: -13.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-160,[dir='rtl'].sm\:rtl\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-160,[dir='rtl'].sm\:rtl\:-inset-x-160 {
    right: -16rem;
    left: -16rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-192,[dir='rtl'].sm\:rtl\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-192,[dir='rtl'].sm\:rtl\:-inset-x-192 {
    right: -19.2rem;
    left: -19.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-200,[dir='rtl'].sm\:rtl\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-200,[dir='rtl'].sm\:rtl\:-inset-x-200 {
    right: -20rem;
    left: -20rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-208,[dir='rtl'].sm\:rtl\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-208,[dir='rtl'].sm\:rtl\:-inset-x-208 {
    right: -20.8rem;
    left: -20.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-216,[dir='rtl'].sm\:rtl\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-216,[dir='rtl'].sm\:rtl\:-inset-x-216 {
    right: -21.6rem;
    left: -21.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-224,[dir='rtl'].sm\:rtl\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-224,[dir='rtl'].sm\:rtl\:-inset-x-224 {
    right: -22.4rem;
    left: -22.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-256,[dir='rtl'].sm\:rtl\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-256,[dir='rtl'].sm\:rtl\:-inset-x-256 {
    right: -25.6rem;
    left: -25.6rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-288,[dir='rtl'].sm\:rtl\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-288,[dir='rtl'].sm\:rtl\:-inset-x-288 {
    right: -28.8rem;
    left: -28.8rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-320,[dir='rtl'].sm\:rtl\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-320,[dir='rtl'].sm\:rtl\:-inset-x-320 {
    right: -32rem;
    left: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-360,[dir='rtl'].sm\:rtl\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-360,[dir='rtl'].sm\:rtl\:-inset-x-360 {
    right: -36rem;
    left: -36rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-384,[dir='rtl'].sm\:rtl\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-384,[dir='rtl'].sm\:rtl\:-inset-x-384 {
    right: -38.4rem;
    left: -38.4rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-400,[dir='rtl'].sm\:rtl\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-400,[dir='rtl'].sm\:rtl\:-inset-x-400 {
    right: -40rem;
    left: -40rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-512,[dir='rtl'].sm\:rtl\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-512,[dir='rtl'].sm\:rtl\:-inset-x-512 {
    right: -51.2rem;
    left: -51.2rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-640,[dir='rtl'].sm\:rtl\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-640,[dir='rtl'].sm\:rtl\:-inset-x-640 {
    right: -64rem;
    left: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-xs,[dir='rtl'].sm\:rtl\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-xs,[dir='rtl'].sm\:rtl\:-inset-x-xs {
    right: -32rem;
    left: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-sm,[dir='rtl'].sm\:rtl\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-sm,[dir='rtl'].sm\:rtl\:-inset-x-sm {
    right: -48rem;
    left: -48rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-md,[dir='rtl'].sm\:rtl\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-md,[dir='rtl'].sm\:rtl\:-inset-x-md {
    right: -64rem;
    left: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-lg,[dir='rtl'].sm\:rtl\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-lg,[dir='rtl'].sm\:rtl\:-inset-x-lg {
    right: -80rem;
    left: -80rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-xl,[dir='rtl'].sm\:rtl\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-xl,[dir='rtl'].sm\:rtl\:-inset-x-xl {
    right: -96rem;
    left: -96rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-2xl,[dir='rtl'].sm\:rtl\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-2xl,[dir='rtl'].sm\:rtl\:-inset-x-2xl {
    right: -112rem;
    left: -112rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-3xl,[dir='rtl'].sm\:rtl\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-3xl,[dir='rtl'].sm\:rtl\:-inset-x-3xl {
    right: -128rem;
    left: -128rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-4xl,[dir='rtl'].sm\:rtl\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-4xl,[dir='rtl'].sm\:rtl\:-inset-x-4xl {
    right: -144rem;
    left: -144rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-5xl,[dir='rtl'].sm\:rtl\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-5xl,[dir='rtl'].sm\:rtl\:-inset-x-5xl {
    right: -160rem;
    left: -160rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-px,[dir='rtl'].sm\:rtl\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-px,[dir='rtl'].sm\:rtl\:-inset-x-px {
    right: -1px;
    left: -1px
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-0\.5,[dir='rtl'].sm\:rtl\:-inset-y-0\.5 {
    top: -0.05rem;
    bottom: -0.05rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-0\.5,[dir='rtl'].sm\:rtl\:-inset-x-0\.5 {
    right: -0.05rem;
    left: -0.05rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-1\.5,[dir='rtl'].sm\:rtl\:-inset-y-1\.5 {
    top: -0.15rem;
    bottom: -0.15rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-1\.5,[dir='rtl'].sm\:rtl\:-inset-x-1\.5 {
    right: -0.15rem;
    left: -0.15rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-2\.5,[dir='rtl'].sm\:rtl\:-inset-y-2\.5 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-2\.5,[dir='rtl'].sm\:rtl\:-inset-x-2\.5 {
    right: -0.25rem;
    left: -0.25rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-3\.5,[dir='rtl'].sm\:rtl\:-inset-y-3\.5 {
    top: -0.35rem;
    bottom: -0.35rem
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-3\.5,[dir='rtl'].sm\:rtl\:-inset-x-3\.5 {
    right: -0.35rem;
    left: -0.35rem
  }

  [dir='rtl'] .sm\:rtl\:inset-y-1\/2,[dir='rtl'].sm\:rtl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  [dir='rtl'] .sm\:rtl\:inset-x-1\/2,[dir='rtl'].sm\:rtl\:inset-x-1\/2 {
    right: 50%;
    left: 50%
  }

  [dir='rtl'] .sm\:rtl\:inset-y-1\/3,[dir='rtl'].sm\:rtl\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  [dir='rtl'] .sm\:rtl\:inset-x-1\/3,[dir='rtl'].sm\:rtl\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%
  }

  [dir='rtl'] .sm\:rtl\:inset-y-2\/3,[dir='rtl'].sm\:rtl\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  [dir='rtl'] .sm\:rtl\:inset-x-2\/3,[dir='rtl'].sm\:rtl\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%
  }

  [dir='rtl'] .sm\:rtl\:inset-y-1\/4,[dir='rtl'].sm\:rtl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  [dir='rtl'] .sm\:rtl\:inset-x-1\/4,[dir='rtl'].sm\:rtl\:inset-x-1\/4 {
    right: 25%;
    left: 25%
  }

  [dir='rtl'] .sm\:rtl\:inset-y-2\/4,[dir='rtl'].sm\:rtl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  [dir='rtl'] .sm\:rtl\:inset-x-2\/4,[dir='rtl'].sm\:rtl\:inset-x-2\/4 {
    right: 50%;
    left: 50%
  }

  [dir='rtl'] .sm\:rtl\:inset-y-3\/4,[dir='rtl'].sm\:rtl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  [dir='rtl'] .sm\:rtl\:inset-x-3\/4,[dir='rtl'].sm\:rtl\:inset-x-3\/4 {
    right: 75%;
    left: 75%
  }

  [dir='rtl'] .sm\:rtl\:inset-y-full,[dir='rtl'].sm\:rtl\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  [dir='rtl'] .sm\:rtl\:inset-x-full,[dir='rtl'].sm\:rtl\:inset-x-full {
    right: 100%;
    left: 100%
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-1\/2,[dir='rtl'].sm\:rtl\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-1\/2,[dir='rtl'].sm\:rtl\:-inset-x-1\/2 {
    right: -50%;
    left: -50%
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-1\/3,[dir='rtl'].sm\:rtl\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-1\/3,[dir='rtl'].sm\:rtl\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-2\/3,[dir='rtl'].sm\:rtl\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-2\/3,[dir='rtl'].sm\:rtl\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-1\/4,[dir='rtl'].sm\:rtl\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-1\/4,[dir='rtl'].sm\:rtl\:-inset-x-1\/4 {
    right: -25%;
    left: -25%
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-2\/4,[dir='rtl'].sm\:rtl\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-2\/4,[dir='rtl'].sm\:rtl\:-inset-x-2\/4 {
    right: -50%;
    left: -50%
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-3\/4,[dir='rtl'].sm\:rtl\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-3\/4,[dir='rtl'].sm\:rtl\:-inset-x-3\/4 {
    right: -75%;
    left: -75%
  }

  [dir='rtl'] .sm\:rtl\:-inset-y-full,[dir='rtl'].sm\:rtl\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  [dir='rtl'] .sm\:rtl\:-inset-x-full,[dir='rtl'].sm\:rtl\:-inset-x-full {
    right: -100%;
    left: -100%
  }

  [dir='rtl'] .sm\:rtl\:top-0,[dir='rtl'].sm\:rtl\:top-0 {
    top: 0
  }

  [dir='rtl'] .sm\:rtl\:right-0,[dir='rtl'].sm\:rtl\:right-0 {
    right: 0
  }

  [dir='rtl'] .sm\:rtl\:bottom-0,[dir='rtl'].sm\:rtl\:bottom-0 {
    bottom: 0
  }

  [dir='rtl'] .sm\:rtl\:left-0,[dir='rtl'].sm\:rtl\:left-0 {
    left: 0
  }

  [dir='rtl'] .sm\:rtl\:top-1,[dir='rtl'].sm\:rtl\:top-1 {
    top: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:right-1,[dir='rtl'].sm\:rtl\:right-1 {
    right: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-1,[dir='rtl'].sm\:rtl\:bottom-1 {
    bottom: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:left-1,[dir='rtl'].sm\:rtl\:left-1 {
    left: 0.1rem
  }

  [dir='rtl'] .sm\:rtl\:top-2,[dir='rtl'].sm\:rtl\:top-2 {
    top: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:right-2,[dir='rtl'].sm\:rtl\:right-2 {
    right: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-2,[dir='rtl'].sm\:rtl\:bottom-2 {
    bottom: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:left-2,[dir='rtl'].sm\:rtl\:left-2 {
    left: 0.2rem
  }

  [dir='rtl'] .sm\:rtl\:top-3,[dir='rtl'].sm\:rtl\:top-3 {
    top: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:right-3,[dir='rtl'].sm\:rtl\:right-3 {
    right: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-3,[dir='rtl'].sm\:rtl\:bottom-3 {
    bottom: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:left-3,[dir='rtl'].sm\:rtl\:left-3 {
    left: 0.3rem
  }

  [dir='rtl'] .sm\:rtl\:top-4,[dir='rtl'].sm\:rtl\:top-4 {
    top: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:right-4,[dir='rtl'].sm\:rtl\:right-4 {
    right: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-4,[dir='rtl'].sm\:rtl\:bottom-4 {
    bottom: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:left-4,[dir='rtl'].sm\:rtl\:left-4 {
    left: 0.4rem
  }

  [dir='rtl'] .sm\:rtl\:top-5,[dir='rtl'].sm\:rtl\:top-5 {
    top: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:right-5,[dir='rtl'].sm\:rtl\:right-5 {
    right: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-5,[dir='rtl'].sm\:rtl\:bottom-5 {
    bottom: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:left-5,[dir='rtl'].sm\:rtl\:left-5 {
    left: 0.5rem
  }

  [dir='rtl'] .sm\:rtl\:top-6,[dir='rtl'].sm\:rtl\:top-6 {
    top: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:right-6,[dir='rtl'].sm\:rtl\:right-6 {
    right: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-6,[dir='rtl'].sm\:rtl\:bottom-6 {
    bottom: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:left-6,[dir='rtl'].sm\:rtl\:left-6 {
    left: 0.6rem
  }

  [dir='rtl'] .sm\:rtl\:top-7,[dir='rtl'].sm\:rtl\:top-7 {
    top: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:right-7,[dir='rtl'].sm\:rtl\:right-7 {
    right: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-7,[dir='rtl'].sm\:rtl\:bottom-7 {
    bottom: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:left-7,[dir='rtl'].sm\:rtl\:left-7 {
    left: 0.7rem
  }

  [dir='rtl'] .sm\:rtl\:top-8,[dir='rtl'].sm\:rtl\:top-8 {
    top: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:right-8,[dir='rtl'].sm\:rtl\:right-8 {
    right: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-8,[dir='rtl'].sm\:rtl\:bottom-8 {
    bottom: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:left-8,[dir='rtl'].sm\:rtl\:left-8 {
    left: 0.8rem
  }

  [dir='rtl'] .sm\:rtl\:top-9,[dir='rtl'].sm\:rtl\:top-9 {
    top: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:right-9,[dir='rtl'].sm\:rtl\:right-9 {
    right: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-9,[dir='rtl'].sm\:rtl\:bottom-9 {
    bottom: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:left-9,[dir='rtl'].sm\:rtl\:left-9 {
    left: 0.9rem
  }

  [dir='rtl'] .sm\:rtl\:top-10,[dir='rtl'].sm\:rtl\:top-10 {
    top: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:right-10,[dir='rtl'].sm\:rtl\:right-10 {
    right: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-10,[dir='rtl'].sm\:rtl\:bottom-10 {
    bottom: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:left-10,[dir='rtl'].sm\:rtl\:left-10 {
    left: 1.0rem
  }

  [dir='rtl'] .sm\:rtl\:top-12,[dir='rtl'].sm\:rtl\:top-12 {
    top: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:right-12,[dir='rtl'].sm\:rtl\:right-12 {
    right: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-12,[dir='rtl'].sm\:rtl\:bottom-12 {
    bottom: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:left-12,[dir='rtl'].sm\:rtl\:left-12 {
    left: 1.2rem
  }

  [dir='rtl'] .sm\:rtl\:top-14,[dir='rtl'].sm\:rtl\:top-14 {
    top: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:right-14,[dir='rtl'].sm\:rtl\:right-14 {
    right: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-14,[dir='rtl'].sm\:rtl\:bottom-14 {
    bottom: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:left-14,[dir='rtl'].sm\:rtl\:left-14 {
    left: 1.4rem
  }

  [dir='rtl'] .sm\:rtl\:top-16,[dir='rtl'].sm\:rtl\:top-16 {
    top: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:right-16,[dir='rtl'].sm\:rtl\:right-16 {
    right: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-16,[dir='rtl'].sm\:rtl\:bottom-16 {
    bottom: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:left-16,[dir='rtl'].sm\:rtl\:left-16 {
    left: 1.6rem
  }

  [dir='rtl'] .sm\:rtl\:top-20,[dir='rtl'].sm\:rtl\:top-20 {
    top: 2rem
  }

  [dir='rtl'] .sm\:rtl\:right-20,[dir='rtl'].sm\:rtl\:right-20 {
    right: 2rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-20,[dir='rtl'].sm\:rtl\:bottom-20 {
    bottom: 2rem
  }

  [dir='rtl'] .sm\:rtl\:left-20,[dir='rtl'].sm\:rtl\:left-20 {
    left: 2rem
  }

  [dir='rtl'] .sm\:rtl\:top-24,[dir='rtl'].sm\:rtl\:top-24 {
    top: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:right-24,[dir='rtl'].sm\:rtl\:right-24 {
    right: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-24,[dir='rtl'].sm\:rtl\:bottom-24 {
    bottom: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:left-24,[dir='rtl'].sm\:rtl\:left-24 {
    left: 2.4rem
  }

  [dir='rtl'] .sm\:rtl\:top-28,[dir='rtl'].sm\:rtl\:top-28 {
    top: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:right-28,[dir='rtl'].sm\:rtl\:right-28 {
    right: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-28,[dir='rtl'].sm\:rtl\:bottom-28 {
    bottom: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:left-28,[dir='rtl'].sm\:rtl\:left-28 {
    left: 2.8rem
  }

  [dir='rtl'] .sm\:rtl\:top-32,[dir='rtl'].sm\:rtl\:top-32 {
    top: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:right-32,[dir='rtl'].sm\:rtl\:right-32 {
    right: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-32,[dir='rtl'].sm\:rtl\:bottom-32 {
    bottom: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:left-32,[dir='rtl'].sm\:rtl\:left-32 {
    left: 3.2rem
  }

  [dir='rtl'] .sm\:rtl\:top-36,[dir='rtl'].sm\:rtl\:top-36 {
    top: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:right-36,[dir='rtl'].sm\:rtl\:right-36 {
    right: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-36,[dir='rtl'].sm\:rtl\:bottom-36 {
    bottom: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:left-36,[dir='rtl'].sm\:rtl\:left-36 {
    left: 3.6rem
  }

  [dir='rtl'] .sm\:rtl\:top-40,[dir='rtl'].sm\:rtl\:top-40 {
    top: 4rem
  }

  [dir='rtl'] .sm\:rtl\:right-40,[dir='rtl'].sm\:rtl\:right-40 {
    right: 4rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-40,[dir='rtl'].sm\:rtl\:bottom-40 {
    bottom: 4rem
  }

  [dir='rtl'] .sm\:rtl\:left-40,[dir='rtl'].sm\:rtl\:left-40 {
    left: 4rem
  }

  [dir='rtl'] .sm\:rtl\:top-44,[dir='rtl'].sm\:rtl\:top-44 {
    top: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:right-44,[dir='rtl'].sm\:rtl\:right-44 {
    right: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-44,[dir='rtl'].sm\:rtl\:bottom-44 {
    bottom: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:left-44,[dir='rtl'].sm\:rtl\:left-44 {
    left: 4.4rem
  }

  [dir='rtl'] .sm\:rtl\:top-48,[dir='rtl'].sm\:rtl\:top-48 {
    top: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:right-48,[dir='rtl'].sm\:rtl\:right-48 {
    right: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-48,[dir='rtl'].sm\:rtl\:bottom-48 {
    bottom: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:left-48,[dir='rtl'].sm\:rtl\:left-48 {
    left: 4.8rem
  }

  [dir='rtl'] .sm\:rtl\:top-52,[dir='rtl'].sm\:rtl\:top-52 {
    top: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:right-52,[dir='rtl'].sm\:rtl\:right-52 {
    right: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-52,[dir='rtl'].sm\:rtl\:bottom-52 {
    bottom: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:left-52,[dir='rtl'].sm\:rtl\:left-52 {
    left: 5.2rem
  }

  [dir='rtl'] .sm\:rtl\:top-56,[dir='rtl'].sm\:rtl\:top-56 {
    top: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:right-56,[dir='rtl'].sm\:rtl\:right-56 {
    right: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-56,[dir='rtl'].sm\:rtl\:bottom-56 {
    bottom: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:left-56,[dir='rtl'].sm\:rtl\:left-56 {
    left: 5.6rem
  }

  [dir='rtl'] .sm\:rtl\:top-60,[dir='rtl'].sm\:rtl\:top-60 {
    top: 6rem
  }

  [dir='rtl'] .sm\:rtl\:right-60,[dir='rtl'].sm\:rtl\:right-60 {
    right: 6rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-60,[dir='rtl'].sm\:rtl\:bottom-60 {
    bottom: 6rem
  }

  [dir='rtl'] .sm\:rtl\:left-60,[dir='rtl'].sm\:rtl\:left-60 {
    left: 6rem
  }

  [dir='rtl'] .sm\:rtl\:top-64,[dir='rtl'].sm\:rtl\:top-64 {
    top: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:right-64,[dir='rtl'].sm\:rtl\:right-64 {
    right: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-64,[dir='rtl'].sm\:rtl\:bottom-64 {
    bottom: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:left-64,[dir='rtl'].sm\:rtl\:left-64 {
    left: 6.4rem
  }

  [dir='rtl'] .sm\:rtl\:top-68,[dir='rtl'].sm\:rtl\:top-68 {
    top: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:right-68,[dir='rtl'].sm\:rtl\:right-68 {
    right: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-68,[dir='rtl'].sm\:rtl\:bottom-68 {
    bottom: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:left-68,[dir='rtl'].sm\:rtl\:left-68 {
    left: 6.8rem
  }

  [dir='rtl'] .sm\:rtl\:top-72,[dir='rtl'].sm\:rtl\:top-72 {
    top: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:right-72,[dir='rtl'].sm\:rtl\:right-72 {
    right: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-72,[dir='rtl'].sm\:rtl\:bottom-72 {
    bottom: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:left-72,[dir='rtl'].sm\:rtl\:left-72 {
    left: 7.2rem
  }

  [dir='rtl'] .sm\:rtl\:top-76,[dir='rtl'].sm\:rtl\:top-76 {
    top: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:right-76,[dir='rtl'].sm\:rtl\:right-76 {
    right: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-76,[dir='rtl'].sm\:rtl\:bottom-76 {
    bottom: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:left-76,[dir='rtl'].sm\:rtl\:left-76 {
    left: 7.6rem
  }

  [dir='rtl'] .sm\:rtl\:top-80,[dir='rtl'].sm\:rtl\:top-80 {
    top: 8rem
  }

  [dir='rtl'] .sm\:rtl\:right-80,[dir='rtl'].sm\:rtl\:right-80 {
    right: 8rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-80,[dir='rtl'].sm\:rtl\:bottom-80 {
    bottom: 8rem
  }

  [dir='rtl'] .sm\:rtl\:left-80,[dir='rtl'].sm\:rtl\:left-80 {
    left: 8rem
  }

  [dir='rtl'] .sm\:rtl\:top-84,[dir='rtl'].sm\:rtl\:top-84 {
    top: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:right-84,[dir='rtl'].sm\:rtl\:right-84 {
    right: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-84,[dir='rtl'].sm\:rtl\:bottom-84 {
    bottom: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:left-84,[dir='rtl'].sm\:rtl\:left-84 {
    left: 8.4rem
  }

  [dir='rtl'] .sm\:rtl\:top-88,[dir='rtl'].sm\:rtl\:top-88 {
    top: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:right-88,[dir='rtl'].sm\:rtl\:right-88 {
    right: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-88,[dir='rtl'].sm\:rtl\:bottom-88 {
    bottom: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:left-88,[dir='rtl'].sm\:rtl\:left-88 {
    left: 8.8rem
  }

  [dir='rtl'] .sm\:rtl\:top-92,[dir='rtl'].sm\:rtl\:top-92 {
    top: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:right-92,[dir='rtl'].sm\:rtl\:right-92 {
    right: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-92,[dir='rtl'].sm\:rtl\:bottom-92 {
    bottom: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:left-92,[dir='rtl'].sm\:rtl\:left-92 {
    left: 9.2rem
  }

  [dir='rtl'] .sm\:rtl\:top-96,[dir='rtl'].sm\:rtl\:top-96 {
    top: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:right-96,[dir='rtl'].sm\:rtl\:right-96 {
    right: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-96,[dir='rtl'].sm\:rtl\:bottom-96 {
    bottom: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:left-96,[dir='rtl'].sm\:rtl\:left-96 {
    left: 9.6rem
  }

  [dir='rtl'] .sm\:rtl\:top-128,[dir='rtl'].sm\:rtl\:top-128 {
    top: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:right-128,[dir='rtl'].sm\:rtl\:right-128 {
    right: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-128,[dir='rtl'].sm\:rtl\:bottom-128 {
    bottom: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:left-128,[dir='rtl'].sm\:rtl\:left-128 {
    left: 12.8rem
  }

  [dir='rtl'] .sm\:rtl\:top-136,[dir='rtl'].sm\:rtl\:top-136 {
    top: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:right-136,[dir='rtl'].sm\:rtl\:right-136 {
    right: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-136,[dir='rtl'].sm\:rtl\:bottom-136 {
    bottom: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:left-136,[dir='rtl'].sm\:rtl\:left-136 {
    left: 13.6rem
  }

  [dir='rtl'] .sm\:rtl\:top-160,[dir='rtl'].sm\:rtl\:top-160 {
    top: 16rem
  }

  [dir='rtl'] .sm\:rtl\:right-160,[dir='rtl'].sm\:rtl\:right-160 {
    right: 16rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-160,[dir='rtl'].sm\:rtl\:bottom-160 {
    bottom: 16rem
  }

  [dir='rtl'] .sm\:rtl\:left-160,[dir='rtl'].sm\:rtl\:left-160 {
    left: 16rem
  }

  [dir='rtl'] .sm\:rtl\:top-192,[dir='rtl'].sm\:rtl\:top-192 {
    top: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:right-192,[dir='rtl'].sm\:rtl\:right-192 {
    right: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-192,[dir='rtl'].sm\:rtl\:bottom-192 {
    bottom: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:left-192,[dir='rtl'].sm\:rtl\:left-192 {
    left: 19.2rem
  }

  [dir='rtl'] .sm\:rtl\:top-200,[dir='rtl'].sm\:rtl\:top-200 {
    top: 20rem
  }

  [dir='rtl'] .sm\:rtl\:right-200,[dir='rtl'].sm\:rtl\:right-200 {
    right: 20rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-200,[dir='rtl'].sm\:rtl\:bottom-200 {
    bottom: 20rem
  }

  [dir='rtl'] .sm\:rtl\:left-200,[dir='rtl'].sm\:rtl\:left-200 {
    left: 20rem
  }

  [dir='rtl'] .sm\:rtl\:top-208,[dir='rtl'].sm\:rtl\:top-208 {
    top: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:right-208,[dir='rtl'].sm\:rtl\:right-208 {
    right: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-208,[dir='rtl'].sm\:rtl\:bottom-208 {
    bottom: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:left-208,[dir='rtl'].sm\:rtl\:left-208 {
    left: 20.8rem
  }

  [dir='rtl'] .sm\:rtl\:top-216,[dir='rtl'].sm\:rtl\:top-216 {
    top: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:right-216,[dir='rtl'].sm\:rtl\:right-216 {
    right: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-216,[dir='rtl'].sm\:rtl\:bottom-216 {
    bottom: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:left-216,[dir='rtl'].sm\:rtl\:left-216 {
    left: 21.6rem
  }

  [dir='rtl'] .sm\:rtl\:top-224,[dir='rtl'].sm\:rtl\:top-224 {
    top: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:right-224,[dir='rtl'].sm\:rtl\:right-224 {
    right: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-224,[dir='rtl'].sm\:rtl\:bottom-224 {
    bottom: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:left-224,[dir='rtl'].sm\:rtl\:left-224 {
    left: 22.4rem
  }

  [dir='rtl'] .sm\:rtl\:top-256,[dir='rtl'].sm\:rtl\:top-256 {
    top: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:right-256,[dir='rtl'].sm\:rtl\:right-256 {
    right: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-256,[dir='rtl'].sm\:rtl\:bottom-256 {
    bottom: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:left-256,[dir='rtl'].sm\:rtl\:left-256 {
    left: 25.6rem
  }

  [dir='rtl'] .sm\:rtl\:top-288,[dir='rtl'].sm\:rtl\:top-288 {
    top: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:right-288,[dir='rtl'].sm\:rtl\:right-288 {
    right: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-288,[dir='rtl'].sm\:rtl\:bottom-288 {
    bottom: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:left-288,[dir='rtl'].sm\:rtl\:left-288 {
    left: 28.8rem
  }

  [dir='rtl'] .sm\:rtl\:top-320,[dir='rtl'].sm\:rtl\:top-320 {
    top: 32rem
  }

  [dir='rtl'] .sm\:rtl\:right-320,[dir='rtl'].sm\:rtl\:right-320 {
    right: 32rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-320,[dir='rtl'].sm\:rtl\:bottom-320 {
    bottom: 32rem
  }

  [dir='rtl'] .sm\:rtl\:left-320,[dir='rtl'].sm\:rtl\:left-320 {
    left: 32rem
  }

  [dir='rtl'] .sm\:rtl\:top-360,[dir='rtl'].sm\:rtl\:top-360 {
    top: 36rem
  }

  [dir='rtl'] .sm\:rtl\:right-360,[dir='rtl'].sm\:rtl\:right-360 {
    right: 36rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-360,[dir='rtl'].sm\:rtl\:bottom-360 {
    bottom: 36rem
  }

  [dir='rtl'] .sm\:rtl\:left-360,[dir='rtl'].sm\:rtl\:left-360 {
    left: 36rem
  }

  [dir='rtl'] .sm\:rtl\:top-384,[dir='rtl'].sm\:rtl\:top-384 {
    top: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:right-384,[dir='rtl'].sm\:rtl\:right-384 {
    right: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-384,[dir='rtl'].sm\:rtl\:bottom-384 {
    bottom: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:left-384,[dir='rtl'].sm\:rtl\:left-384 {
    left: 38.4rem
  }

  [dir='rtl'] .sm\:rtl\:top-400,[dir='rtl'].sm\:rtl\:top-400 {
    top: 40rem
  }

  [dir='rtl'] .sm\:rtl\:right-400,[dir='rtl'].sm\:rtl\:right-400 {
    right: 40rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-400,[dir='rtl'].sm\:rtl\:bottom-400 {
    bottom: 40rem
  }

  [dir='rtl'] .sm\:rtl\:left-400,[dir='rtl'].sm\:rtl\:left-400 {
    left: 40rem
  }

  [dir='rtl'] .sm\:rtl\:top-512,[dir='rtl'].sm\:rtl\:top-512 {
    top: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:right-512,[dir='rtl'].sm\:rtl\:right-512 {
    right: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-512,[dir='rtl'].sm\:rtl\:bottom-512 {
    bottom: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:left-512,[dir='rtl'].sm\:rtl\:left-512 {
    left: 51.2rem
  }

  [dir='rtl'] .sm\:rtl\:top-640,[dir='rtl'].sm\:rtl\:top-640 {
    top: 64rem
  }

  [dir='rtl'] .sm\:rtl\:right-640,[dir='rtl'].sm\:rtl\:right-640 {
    right: 64rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-640,[dir='rtl'].sm\:rtl\:bottom-640 {
    bottom: 64rem
  }

  [dir='rtl'] .sm\:rtl\:left-640,[dir='rtl'].sm\:rtl\:left-640 {
    left: 64rem
  }

  [dir='rtl'] .sm\:rtl\:top-auto,[dir='rtl'].sm\:rtl\:top-auto {
    top: auto
  }

  [dir='rtl'] .sm\:rtl\:right-auto,[dir='rtl'].sm\:rtl\:right-auto {
    right: auto
  }

  [dir='rtl'] .sm\:rtl\:bottom-auto,[dir='rtl'].sm\:rtl\:bottom-auto {
    bottom: auto
  }

  [dir='rtl'] .sm\:rtl\:left-auto,[dir='rtl'].sm\:rtl\:left-auto {
    left: auto
  }

  [dir='rtl'] .sm\:rtl\:top-xs,[dir='rtl'].sm\:rtl\:top-xs {
    top: 32rem
  }

  [dir='rtl'] .sm\:rtl\:right-xs,[dir='rtl'].sm\:rtl\:right-xs {
    right: 32rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-xs,[dir='rtl'].sm\:rtl\:bottom-xs {
    bottom: 32rem
  }

  [dir='rtl'] .sm\:rtl\:left-xs,[dir='rtl'].sm\:rtl\:left-xs {
    left: 32rem
  }

  [dir='rtl'] .sm\:rtl\:top-sm,[dir='rtl'].sm\:rtl\:top-sm {
    top: 48rem
  }

  [dir='rtl'] .sm\:rtl\:right-sm,[dir='rtl'].sm\:rtl\:right-sm {
    right: 48rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-sm,[dir='rtl'].sm\:rtl\:bottom-sm {
    bottom: 48rem
  }

  [dir='rtl'] .sm\:rtl\:left-sm,[dir='rtl'].sm\:rtl\:left-sm {
    left: 48rem
  }

  [dir='rtl'] .sm\:rtl\:top-md,[dir='rtl'].sm\:rtl\:top-md {
    top: 64rem
  }

  [dir='rtl'] .sm\:rtl\:right-md,[dir='rtl'].sm\:rtl\:right-md {
    right: 64rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-md,[dir='rtl'].sm\:rtl\:bottom-md {
    bottom: 64rem
  }

  [dir='rtl'] .sm\:rtl\:left-md,[dir='rtl'].sm\:rtl\:left-md {
    left: 64rem
  }

  [dir='rtl'] .sm\:rtl\:top-lg,[dir='rtl'].sm\:rtl\:top-lg {
    top: 80rem
  }

  [dir='rtl'] .sm\:rtl\:right-lg,[dir='rtl'].sm\:rtl\:right-lg {
    right: 80rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-lg,[dir='rtl'].sm\:rtl\:bottom-lg {
    bottom: 80rem
  }

  [dir='rtl'] .sm\:rtl\:left-lg,[dir='rtl'].sm\:rtl\:left-lg {
    left: 80rem
  }

  [dir='rtl'] .sm\:rtl\:top-xl,[dir='rtl'].sm\:rtl\:top-xl {
    top: 96rem
  }

  [dir='rtl'] .sm\:rtl\:right-xl,[dir='rtl'].sm\:rtl\:right-xl {
    right: 96rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-xl,[dir='rtl'].sm\:rtl\:bottom-xl {
    bottom: 96rem
  }

  [dir='rtl'] .sm\:rtl\:left-xl,[dir='rtl'].sm\:rtl\:left-xl {
    left: 96rem
  }

  [dir='rtl'] .sm\:rtl\:top-2xl,[dir='rtl'].sm\:rtl\:top-2xl {
    top: 112rem
  }

  [dir='rtl'] .sm\:rtl\:right-2xl,[dir='rtl'].sm\:rtl\:right-2xl {
    right: 112rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-2xl,[dir='rtl'].sm\:rtl\:bottom-2xl {
    bottom: 112rem
  }

  [dir='rtl'] .sm\:rtl\:left-2xl,[dir='rtl'].sm\:rtl\:left-2xl {
    left: 112rem
  }

  [dir='rtl'] .sm\:rtl\:top-3xl,[dir='rtl'].sm\:rtl\:top-3xl {
    top: 128rem
  }

  [dir='rtl'] .sm\:rtl\:right-3xl,[dir='rtl'].sm\:rtl\:right-3xl {
    right: 128rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-3xl,[dir='rtl'].sm\:rtl\:bottom-3xl {
    bottom: 128rem
  }

  [dir='rtl'] .sm\:rtl\:left-3xl,[dir='rtl'].sm\:rtl\:left-3xl {
    left: 128rem
  }

  [dir='rtl'] .sm\:rtl\:top-4xl,[dir='rtl'].sm\:rtl\:top-4xl {
    top: 144rem
  }

  [dir='rtl'] .sm\:rtl\:right-4xl,[dir='rtl'].sm\:rtl\:right-4xl {
    right: 144rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-4xl,[dir='rtl'].sm\:rtl\:bottom-4xl {
    bottom: 144rem
  }

  [dir='rtl'] .sm\:rtl\:left-4xl,[dir='rtl'].sm\:rtl\:left-4xl {
    left: 144rem
  }

  [dir='rtl'] .sm\:rtl\:top-5xl,[dir='rtl'].sm\:rtl\:top-5xl {
    top: 160rem
  }

  [dir='rtl'] .sm\:rtl\:right-5xl,[dir='rtl'].sm\:rtl\:right-5xl {
    right: 160rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-5xl,[dir='rtl'].sm\:rtl\:bottom-5xl {
    bottom: 160rem
  }

  [dir='rtl'] .sm\:rtl\:left-5xl,[dir='rtl'].sm\:rtl\:left-5xl {
    left: 160rem
  }

  [dir='rtl'] .sm\:rtl\:top-px,[dir='rtl'].sm\:rtl\:top-px {
    top: 1px
  }

  [dir='rtl'] .sm\:rtl\:right-px,[dir='rtl'].sm\:rtl\:right-px {
    right: 1px
  }

  [dir='rtl'] .sm\:rtl\:bottom-px,[dir='rtl'].sm\:rtl\:bottom-px {
    bottom: 1px
  }

  [dir='rtl'] .sm\:rtl\:left-px,[dir='rtl'].sm\:rtl\:left-px {
    left: 1px
  }

  [dir='rtl'] .sm\:rtl\:top-0\.5,[dir='rtl'].sm\:rtl\:top-0\.5 {
    top: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:right-0\.5,[dir='rtl'].sm\:rtl\:right-0\.5 {
    right: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-0\.5,[dir='rtl'].sm\:rtl\:bottom-0\.5 {
    bottom: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:left-0\.5,[dir='rtl'].sm\:rtl\:left-0\.5 {
    left: 0.05rem
  }

  [dir='rtl'] .sm\:rtl\:top-1\.5,[dir='rtl'].sm\:rtl\:top-1\.5 {
    top: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:right-1\.5,[dir='rtl'].sm\:rtl\:right-1\.5 {
    right: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-1\.5,[dir='rtl'].sm\:rtl\:bottom-1\.5 {
    bottom: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:left-1\.5,[dir='rtl'].sm\:rtl\:left-1\.5 {
    left: 0.15rem
  }

  [dir='rtl'] .sm\:rtl\:top-2\.5,[dir='rtl'].sm\:rtl\:top-2\.5 {
    top: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:right-2\.5,[dir='rtl'].sm\:rtl\:right-2\.5 {
    right: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-2\.5,[dir='rtl'].sm\:rtl\:bottom-2\.5 {
    bottom: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:left-2\.5,[dir='rtl'].sm\:rtl\:left-2\.5 {
    left: 0.25rem
  }

  [dir='rtl'] .sm\:rtl\:top-3\.5,[dir='rtl'].sm\:rtl\:top-3\.5 {
    top: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:right-3\.5,[dir='rtl'].sm\:rtl\:right-3\.5 {
    right: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:bottom-3\.5,[dir='rtl'].sm\:rtl\:bottom-3\.5 {
    bottom: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:left-3\.5,[dir='rtl'].sm\:rtl\:left-3\.5 {
    left: 0.35rem
  }

  [dir='rtl'] .sm\:rtl\:-top-1,[dir='rtl'].sm\:rtl\:-top-1 {
    top: -0.1rem
  }

  [dir='rtl'] .sm\:rtl\:-right-1,[dir='rtl'].sm\:rtl\:-right-1 {
    right: -0.1rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-1,[dir='rtl'].sm\:rtl\:-bottom-1 {
    bottom: -0.1rem
  }

  [dir='rtl'] .sm\:rtl\:-left-1,[dir='rtl'].sm\:rtl\:-left-1 {
    left: -0.1rem
  }

  [dir='rtl'] .sm\:rtl\:-top-2,[dir='rtl'].sm\:rtl\:-top-2 {
    top: -0.2rem
  }

  [dir='rtl'] .sm\:rtl\:-right-2,[dir='rtl'].sm\:rtl\:-right-2 {
    right: -0.2rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-2,[dir='rtl'].sm\:rtl\:-bottom-2 {
    bottom: -0.2rem
  }

  [dir='rtl'] .sm\:rtl\:-left-2,[dir='rtl'].sm\:rtl\:-left-2 {
    left: -0.2rem
  }

  [dir='rtl'] .sm\:rtl\:-top-3,[dir='rtl'].sm\:rtl\:-top-3 {
    top: -0.3rem
  }

  [dir='rtl'] .sm\:rtl\:-right-3,[dir='rtl'].sm\:rtl\:-right-3 {
    right: -0.3rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-3,[dir='rtl'].sm\:rtl\:-bottom-3 {
    bottom: -0.3rem
  }

  [dir='rtl'] .sm\:rtl\:-left-3,[dir='rtl'].sm\:rtl\:-left-3 {
    left: -0.3rem
  }

  [dir='rtl'] .sm\:rtl\:-top-4,[dir='rtl'].sm\:rtl\:-top-4 {
    top: -0.4rem
  }

  [dir='rtl'] .sm\:rtl\:-right-4,[dir='rtl'].sm\:rtl\:-right-4 {
    right: -0.4rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-4,[dir='rtl'].sm\:rtl\:-bottom-4 {
    bottom: -0.4rem
  }

  [dir='rtl'] .sm\:rtl\:-left-4,[dir='rtl'].sm\:rtl\:-left-4 {
    left: -0.4rem
  }

  [dir='rtl'] .sm\:rtl\:-top-5,[dir='rtl'].sm\:rtl\:-top-5 {
    top: -0.5rem
  }

  [dir='rtl'] .sm\:rtl\:-right-5,[dir='rtl'].sm\:rtl\:-right-5 {
    right: -0.5rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-5,[dir='rtl'].sm\:rtl\:-bottom-5 {
    bottom: -0.5rem
  }

  [dir='rtl'] .sm\:rtl\:-left-5,[dir='rtl'].sm\:rtl\:-left-5 {
    left: -0.5rem
  }

  [dir='rtl'] .sm\:rtl\:-top-6,[dir='rtl'].sm\:rtl\:-top-6 {
    top: -0.6rem
  }

  [dir='rtl'] .sm\:rtl\:-right-6,[dir='rtl'].sm\:rtl\:-right-6 {
    right: -0.6rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-6,[dir='rtl'].sm\:rtl\:-bottom-6 {
    bottom: -0.6rem
  }

  [dir='rtl'] .sm\:rtl\:-left-6,[dir='rtl'].sm\:rtl\:-left-6 {
    left: -0.6rem
  }

  [dir='rtl'] .sm\:rtl\:-top-7,[dir='rtl'].sm\:rtl\:-top-7 {
    top: -0.7rem
  }

  [dir='rtl'] .sm\:rtl\:-right-7,[dir='rtl'].sm\:rtl\:-right-7 {
    right: -0.7rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-7,[dir='rtl'].sm\:rtl\:-bottom-7 {
    bottom: -0.7rem
  }

  [dir='rtl'] .sm\:rtl\:-left-7,[dir='rtl'].sm\:rtl\:-left-7 {
    left: -0.7rem
  }

  [dir='rtl'] .sm\:rtl\:-top-8,[dir='rtl'].sm\:rtl\:-top-8 {
    top: -0.8rem
  }

  [dir='rtl'] .sm\:rtl\:-right-8,[dir='rtl'].sm\:rtl\:-right-8 {
    right: -0.8rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-8,[dir='rtl'].sm\:rtl\:-bottom-8 {
    bottom: -0.8rem
  }

  [dir='rtl'] .sm\:rtl\:-left-8,[dir='rtl'].sm\:rtl\:-left-8 {
    left: -0.8rem
  }

  [dir='rtl'] .sm\:rtl\:-top-9,[dir='rtl'].sm\:rtl\:-top-9 {
    top: -0.9rem
  }

  [dir='rtl'] .sm\:rtl\:-right-9,[dir='rtl'].sm\:rtl\:-right-9 {
    right: -0.9rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-9,[dir='rtl'].sm\:rtl\:-bottom-9 {
    bottom: -0.9rem
  }

  [dir='rtl'] .sm\:rtl\:-left-9,[dir='rtl'].sm\:rtl\:-left-9 {
    left: -0.9rem
  }

  [dir='rtl'] .sm\:rtl\:-top-10,[dir='rtl'].sm\:rtl\:-top-10 {
    top: -1rem
  }

  [dir='rtl'] .sm\:rtl\:-right-10,[dir='rtl'].sm\:rtl\:-right-10 {
    right: -1rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-10,[dir='rtl'].sm\:rtl\:-bottom-10 {
    bottom: -1rem
  }

  [dir='rtl'] .sm\:rtl\:-left-10,[dir='rtl'].sm\:rtl\:-left-10 {
    left: -1rem
  }

  [dir='rtl'] .sm\:rtl\:-top-12,[dir='rtl'].sm\:rtl\:-top-12 {
    top: -1.2rem
  }

  [dir='rtl'] .sm\:rtl\:-right-12,[dir='rtl'].sm\:rtl\:-right-12 {
    right: -1.2rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-12,[dir='rtl'].sm\:rtl\:-bottom-12 {
    bottom: -1.2rem
  }

  [dir='rtl'] .sm\:rtl\:-left-12,[dir='rtl'].sm\:rtl\:-left-12 {
    left: -1.2rem
  }

  [dir='rtl'] .sm\:rtl\:-top-14,[dir='rtl'].sm\:rtl\:-top-14 {
    top: -1.4rem
  }

  [dir='rtl'] .sm\:rtl\:-right-14,[dir='rtl'].sm\:rtl\:-right-14 {
    right: -1.4rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-14,[dir='rtl'].sm\:rtl\:-bottom-14 {
    bottom: -1.4rem
  }

  [dir='rtl'] .sm\:rtl\:-left-14,[dir='rtl'].sm\:rtl\:-left-14 {
    left: -1.4rem
  }

  [dir='rtl'] .sm\:rtl\:-top-16,[dir='rtl'].sm\:rtl\:-top-16 {
    top: -1.6rem
  }

  [dir='rtl'] .sm\:rtl\:-right-16,[dir='rtl'].sm\:rtl\:-right-16 {
    right: -1.6rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-16,[dir='rtl'].sm\:rtl\:-bottom-16 {
    bottom: -1.6rem
  }

  [dir='rtl'] .sm\:rtl\:-left-16,[dir='rtl'].sm\:rtl\:-left-16 {
    left: -1.6rem
  }

  [dir='rtl'] .sm\:rtl\:-top-20,[dir='rtl'].sm\:rtl\:-top-20 {
    top: -2rem
  }

  [dir='rtl'] .sm\:rtl\:-right-20,[dir='rtl'].sm\:rtl\:-right-20 {
    right: -2rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-20,[dir='rtl'].sm\:rtl\:-bottom-20 {
    bottom: -2rem
  }

  [dir='rtl'] .sm\:rtl\:-left-20,[dir='rtl'].sm\:rtl\:-left-20 {
    left: -2rem
  }

  [dir='rtl'] .sm\:rtl\:-top-24,[dir='rtl'].sm\:rtl\:-top-24 {
    top: -2.4rem
  }

  [dir='rtl'] .sm\:rtl\:-right-24,[dir='rtl'].sm\:rtl\:-right-24 {
    right: -2.4rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-24,[dir='rtl'].sm\:rtl\:-bottom-24 {
    bottom: -2.4rem
  }

  [dir='rtl'] .sm\:rtl\:-left-24,[dir='rtl'].sm\:rtl\:-left-24 {
    left: -2.4rem
  }

  [dir='rtl'] .sm\:rtl\:-top-28,[dir='rtl'].sm\:rtl\:-top-28 {
    top: -2.8rem
  }

  [dir='rtl'] .sm\:rtl\:-right-28,[dir='rtl'].sm\:rtl\:-right-28 {
    right: -2.8rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-28,[dir='rtl'].sm\:rtl\:-bottom-28 {
    bottom: -2.8rem
  }

  [dir='rtl'] .sm\:rtl\:-left-28,[dir='rtl'].sm\:rtl\:-left-28 {
    left: -2.8rem
  }

  [dir='rtl'] .sm\:rtl\:-top-32,[dir='rtl'].sm\:rtl\:-top-32 {
    top: -3.2rem
  }

  [dir='rtl'] .sm\:rtl\:-right-32,[dir='rtl'].sm\:rtl\:-right-32 {
    right: -3.2rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-32,[dir='rtl'].sm\:rtl\:-bottom-32 {
    bottom: -3.2rem
  }

  [dir='rtl'] .sm\:rtl\:-left-32,[dir='rtl'].sm\:rtl\:-left-32 {
    left: -3.2rem
  }

  [dir='rtl'] .sm\:rtl\:-top-36,[dir='rtl'].sm\:rtl\:-top-36 {
    top: -3.6rem
  }

  [dir='rtl'] .sm\:rtl\:-right-36,[dir='rtl'].sm\:rtl\:-right-36 {
    right: -3.6rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-36,[dir='rtl'].sm\:rtl\:-bottom-36 {
    bottom: -3.6rem
  }

  [dir='rtl'] .sm\:rtl\:-left-36,[dir='rtl'].sm\:rtl\:-left-36 {
    left: -3.6rem
  }

  [dir='rtl'] .sm\:rtl\:-top-40,[dir='rtl'].sm\:rtl\:-top-40 {
    top: -4rem
  }

  [dir='rtl'] .sm\:rtl\:-right-40,[dir='rtl'].sm\:rtl\:-right-40 {
    right: -4rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-40,[dir='rtl'].sm\:rtl\:-bottom-40 {
    bottom: -4rem
  }

  [dir='rtl'] .sm\:rtl\:-left-40,[dir='rtl'].sm\:rtl\:-left-40 {
    left: -4rem
  }

  [dir='rtl'] .sm\:rtl\:-top-44,[dir='rtl'].sm\:rtl\:-top-44 {
    top: -4.4rem
  }

  [dir='rtl'] .sm\:rtl\:-right-44,[dir='rtl'].sm\:rtl\:-right-44 {
    right: -4.4rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-44,[dir='rtl'].sm\:rtl\:-bottom-44 {
    bottom: -4.4rem
  }

  [dir='rtl'] .sm\:rtl\:-left-44,[dir='rtl'].sm\:rtl\:-left-44 {
    left: -4.4rem
  }

  [dir='rtl'] .sm\:rtl\:-top-48,[dir='rtl'].sm\:rtl\:-top-48 {
    top: -4.8rem
  }

  [dir='rtl'] .sm\:rtl\:-right-48,[dir='rtl'].sm\:rtl\:-right-48 {
    right: -4.8rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-48,[dir='rtl'].sm\:rtl\:-bottom-48 {
    bottom: -4.8rem
  }

  [dir='rtl'] .sm\:rtl\:-left-48,[dir='rtl'].sm\:rtl\:-left-48 {
    left: -4.8rem
  }

  [dir='rtl'] .sm\:rtl\:-top-52,[dir='rtl'].sm\:rtl\:-top-52 {
    top: -5.2rem
  }

  [dir='rtl'] .sm\:rtl\:-right-52,[dir='rtl'].sm\:rtl\:-right-52 {
    right: -5.2rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-52,[dir='rtl'].sm\:rtl\:-bottom-52 {
    bottom: -5.2rem
  }

  [dir='rtl'] .sm\:rtl\:-left-52,[dir='rtl'].sm\:rtl\:-left-52 {
    left: -5.2rem
  }

  [dir='rtl'] .sm\:rtl\:-top-56,[dir='rtl'].sm\:rtl\:-top-56 {
    top: -5.6rem
  }

  [dir='rtl'] .sm\:rtl\:-right-56,[dir='rtl'].sm\:rtl\:-right-56 {
    right: -5.6rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-56,[dir='rtl'].sm\:rtl\:-bottom-56 {
    bottom: -5.6rem
  }

  [dir='rtl'] .sm\:rtl\:-left-56,[dir='rtl'].sm\:rtl\:-left-56 {
    left: -5.6rem
  }

  [dir='rtl'] .sm\:rtl\:-top-60,[dir='rtl'].sm\:rtl\:-top-60 {
    top: -6rem
  }

  [dir='rtl'] .sm\:rtl\:-right-60,[dir='rtl'].sm\:rtl\:-right-60 {
    right: -6rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-60,[dir='rtl'].sm\:rtl\:-bottom-60 {
    bottom: -6rem
  }

  [dir='rtl'] .sm\:rtl\:-left-60,[dir='rtl'].sm\:rtl\:-left-60 {
    left: -6rem
  }

  [dir='rtl'] .sm\:rtl\:-top-64,[dir='rtl'].sm\:rtl\:-top-64 {
    top: -6.4rem
  }

  [dir='rtl'] .sm\:rtl\:-right-64,[dir='rtl'].sm\:rtl\:-right-64 {
    right: -6.4rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-64,[dir='rtl'].sm\:rtl\:-bottom-64 {
    bottom: -6.4rem
  }

  [dir='rtl'] .sm\:rtl\:-left-64,[dir='rtl'].sm\:rtl\:-left-64 {
    left: -6.4rem
  }

  [dir='rtl'] .sm\:rtl\:-top-68,[dir='rtl'].sm\:rtl\:-top-68 {
    top: -6.8rem
  }

  [dir='rtl'] .sm\:rtl\:-right-68,[dir='rtl'].sm\:rtl\:-right-68 {
    right: -6.8rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-68,[dir='rtl'].sm\:rtl\:-bottom-68 {
    bottom: -6.8rem
  }

  [dir='rtl'] .sm\:rtl\:-left-68,[dir='rtl'].sm\:rtl\:-left-68 {
    left: -6.8rem
  }

  [dir='rtl'] .sm\:rtl\:-top-72,[dir='rtl'].sm\:rtl\:-top-72 {
    top: -7.2rem
  }

  [dir='rtl'] .sm\:rtl\:-right-72,[dir='rtl'].sm\:rtl\:-right-72 {
    right: -7.2rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-72,[dir='rtl'].sm\:rtl\:-bottom-72 {
    bottom: -7.2rem
  }

  [dir='rtl'] .sm\:rtl\:-left-72,[dir='rtl'].sm\:rtl\:-left-72 {
    left: -7.2rem
  }

  [dir='rtl'] .sm\:rtl\:-top-76,[dir='rtl'].sm\:rtl\:-top-76 {
    top: -7.6rem
  }

  [dir='rtl'] .sm\:rtl\:-right-76,[dir='rtl'].sm\:rtl\:-right-76 {
    right: -7.6rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-76,[dir='rtl'].sm\:rtl\:-bottom-76 {
    bottom: -7.6rem
  }

  [dir='rtl'] .sm\:rtl\:-left-76,[dir='rtl'].sm\:rtl\:-left-76 {
    left: -7.6rem
  }

  [dir='rtl'] .sm\:rtl\:-top-80,[dir='rtl'].sm\:rtl\:-top-80 {
    top: -8rem
  }

  [dir='rtl'] .sm\:rtl\:-right-80,[dir='rtl'].sm\:rtl\:-right-80 {
    right: -8rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-80,[dir='rtl'].sm\:rtl\:-bottom-80 {
    bottom: -8rem
  }

  [dir='rtl'] .sm\:rtl\:-left-80,[dir='rtl'].sm\:rtl\:-left-80 {
    left: -8rem
  }

  [dir='rtl'] .sm\:rtl\:-top-84,[dir='rtl'].sm\:rtl\:-top-84 {
    top: -8.4rem
  }

  [dir='rtl'] .sm\:rtl\:-right-84,[dir='rtl'].sm\:rtl\:-right-84 {
    right: -8.4rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-84,[dir='rtl'].sm\:rtl\:-bottom-84 {
    bottom: -8.4rem
  }

  [dir='rtl'] .sm\:rtl\:-left-84,[dir='rtl'].sm\:rtl\:-left-84 {
    left: -8.4rem
  }

  [dir='rtl'] .sm\:rtl\:-top-88,[dir='rtl'].sm\:rtl\:-top-88 {
    top: -8.8rem
  }

  [dir='rtl'] .sm\:rtl\:-right-88,[dir='rtl'].sm\:rtl\:-right-88 {
    right: -8.8rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-88,[dir='rtl'].sm\:rtl\:-bottom-88 {
    bottom: -8.8rem
  }

  [dir='rtl'] .sm\:rtl\:-left-88,[dir='rtl'].sm\:rtl\:-left-88 {
    left: -8.8rem
  }

  [dir='rtl'] .sm\:rtl\:-top-92,[dir='rtl'].sm\:rtl\:-top-92 {
    top: -9.2rem
  }

  [dir='rtl'] .sm\:rtl\:-right-92,[dir='rtl'].sm\:rtl\:-right-92 {
    right: -9.2rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-92,[dir='rtl'].sm\:rtl\:-bottom-92 {
    bottom: -9.2rem
  }

  [dir='rtl'] .sm\:rtl\:-left-92,[dir='rtl'].sm\:rtl\:-left-92 {
    left: -9.2rem
  }

  [dir='rtl'] .sm\:rtl\:-top-96,[dir='rtl'].sm\:rtl\:-top-96 {
    top: -9.6rem
  }

  [dir='rtl'] .sm\:rtl\:-right-96,[dir='rtl'].sm\:rtl\:-right-96 {
    right: -9.6rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-96,[dir='rtl'].sm\:rtl\:-bottom-96 {
    bottom: -9.6rem
  }

  [dir='rtl'] .sm\:rtl\:-left-96,[dir='rtl'].sm\:rtl\:-left-96 {
    left: -9.6rem
  }

  [dir='rtl'] .sm\:rtl\:-top-128,[dir='rtl'].sm\:rtl\:-top-128 {
    top: -12.8rem
  }

  [dir='rtl'] .sm\:rtl\:-right-128,[dir='rtl'].sm\:rtl\:-right-128 {
    right: -12.8rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-128,[dir='rtl'].sm\:rtl\:-bottom-128 {
    bottom: -12.8rem
  }

  [dir='rtl'] .sm\:rtl\:-left-128,[dir='rtl'].sm\:rtl\:-left-128 {
    left: -12.8rem
  }

  [dir='rtl'] .sm\:rtl\:-top-136,[dir='rtl'].sm\:rtl\:-top-136 {
    top: -13.6rem
  }

  [dir='rtl'] .sm\:rtl\:-right-136,[dir='rtl'].sm\:rtl\:-right-136 {
    right: -13.6rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-136,[dir='rtl'].sm\:rtl\:-bottom-136 {
    bottom: -13.6rem
  }

  [dir='rtl'] .sm\:rtl\:-left-136,[dir='rtl'].sm\:rtl\:-left-136 {
    left: -13.6rem
  }

  [dir='rtl'] .sm\:rtl\:-top-160,[dir='rtl'].sm\:rtl\:-top-160 {
    top: -16rem
  }

  [dir='rtl'] .sm\:rtl\:-right-160,[dir='rtl'].sm\:rtl\:-right-160 {
    right: -16rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-160,[dir='rtl'].sm\:rtl\:-bottom-160 {
    bottom: -16rem
  }

  [dir='rtl'] .sm\:rtl\:-left-160,[dir='rtl'].sm\:rtl\:-left-160 {
    left: -16rem
  }

  [dir='rtl'] .sm\:rtl\:-top-192,[dir='rtl'].sm\:rtl\:-top-192 {
    top: -19.2rem
  }

  [dir='rtl'] .sm\:rtl\:-right-192,[dir='rtl'].sm\:rtl\:-right-192 {
    right: -19.2rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-192,[dir='rtl'].sm\:rtl\:-bottom-192 {
    bottom: -19.2rem
  }

  [dir='rtl'] .sm\:rtl\:-left-192,[dir='rtl'].sm\:rtl\:-left-192 {
    left: -19.2rem
  }

  [dir='rtl'] .sm\:rtl\:-top-200,[dir='rtl'].sm\:rtl\:-top-200 {
    top: -20rem
  }

  [dir='rtl'] .sm\:rtl\:-right-200,[dir='rtl'].sm\:rtl\:-right-200 {
    right: -20rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-200,[dir='rtl'].sm\:rtl\:-bottom-200 {
    bottom: -20rem
  }

  [dir='rtl'] .sm\:rtl\:-left-200,[dir='rtl'].sm\:rtl\:-left-200 {
    left: -20rem
  }

  [dir='rtl'] .sm\:rtl\:-top-208,[dir='rtl'].sm\:rtl\:-top-208 {
    top: -20.8rem
  }

  [dir='rtl'] .sm\:rtl\:-right-208,[dir='rtl'].sm\:rtl\:-right-208 {
    right: -20.8rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-208,[dir='rtl'].sm\:rtl\:-bottom-208 {
    bottom: -20.8rem
  }

  [dir='rtl'] .sm\:rtl\:-left-208,[dir='rtl'].sm\:rtl\:-left-208 {
    left: -20.8rem
  }

  [dir='rtl'] .sm\:rtl\:-top-216,[dir='rtl'].sm\:rtl\:-top-216 {
    top: -21.6rem
  }

  [dir='rtl'] .sm\:rtl\:-right-216,[dir='rtl'].sm\:rtl\:-right-216 {
    right: -21.6rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-216,[dir='rtl'].sm\:rtl\:-bottom-216 {
    bottom: -21.6rem
  }

  [dir='rtl'] .sm\:rtl\:-left-216,[dir='rtl'].sm\:rtl\:-left-216 {
    left: -21.6rem
  }

  [dir='rtl'] .sm\:rtl\:-top-224,[dir='rtl'].sm\:rtl\:-top-224 {
    top: -22.4rem
  }

  [dir='rtl'] .sm\:rtl\:-right-224,[dir='rtl'].sm\:rtl\:-right-224 {
    right: -22.4rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-224,[dir='rtl'].sm\:rtl\:-bottom-224 {
    bottom: -22.4rem
  }

  [dir='rtl'] .sm\:rtl\:-left-224,[dir='rtl'].sm\:rtl\:-left-224 {
    left: -22.4rem
  }

  [dir='rtl'] .sm\:rtl\:-top-256,[dir='rtl'].sm\:rtl\:-top-256 {
    top: -25.6rem
  }

  [dir='rtl'] .sm\:rtl\:-right-256,[dir='rtl'].sm\:rtl\:-right-256 {
    right: -25.6rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-256,[dir='rtl'].sm\:rtl\:-bottom-256 {
    bottom: -25.6rem
  }

  [dir='rtl'] .sm\:rtl\:-left-256,[dir='rtl'].sm\:rtl\:-left-256 {
    left: -25.6rem
  }

  [dir='rtl'] .sm\:rtl\:-top-288,[dir='rtl'].sm\:rtl\:-top-288 {
    top: -28.8rem
  }

  [dir='rtl'] .sm\:rtl\:-right-288,[dir='rtl'].sm\:rtl\:-right-288 {
    right: -28.8rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-288,[dir='rtl'].sm\:rtl\:-bottom-288 {
    bottom: -28.8rem
  }

  [dir='rtl'] .sm\:rtl\:-left-288,[dir='rtl'].sm\:rtl\:-left-288 {
    left: -28.8rem
  }

  [dir='rtl'] .sm\:rtl\:-top-320,[dir='rtl'].sm\:rtl\:-top-320 {
    top: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-right-320,[dir='rtl'].sm\:rtl\:-right-320 {
    right: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-320,[dir='rtl'].sm\:rtl\:-bottom-320 {
    bottom: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-left-320,[dir='rtl'].sm\:rtl\:-left-320 {
    left: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-top-360,[dir='rtl'].sm\:rtl\:-top-360 {
    top: -36rem
  }

  [dir='rtl'] .sm\:rtl\:-right-360,[dir='rtl'].sm\:rtl\:-right-360 {
    right: -36rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-360,[dir='rtl'].sm\:rtl\:-bottom-360 {
    bottom: -36rem
  }

  [dir='rtl'] .sm\:rtl\:-left-360,[dir='rtl'].sm\:rtl\:-left-360 {
    left: -36rem
  }

  [dir='rtl'] .sm\:rtl\:-top-384,[dir='rtl'].sm\:rtl\:-top-384 {
    top: -38.4rem
  }

  [dir='rtl'] .sm\:rtl\:-right-384,[dir='rtl'].sm\:rtl\:-right-384 {
    right: -38.4rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-384,[dir='rtl'].sm\:rtl\:-bottom-384 {
    bottom: -38.4rem
  }

  [dir='rtl'] .sm\:rtl\:-left-384,[dir='rtl'].sm\:rtl\:-left-384 {
    left: -38.4rem
  }

  [dir='rtl'] .sm\:rtl\:-top-400,[dir='rtl'].sm\:rtl\:-top-400 {
    top: -40rem
  }

  [dir='rtl'] .sm\:rtl\:-right-400,[dir='rtl'].sm\:rtl\:-right-400 {
    right: -40rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-400,[dir='rtl'].sm\:rtl\:-bottom-400 {
    bottom: -40rem
  }

  [dir='rtl'] .sm\:rtl\:-left-400,[dir='rtl'].sm\:rtl\:-left-400 {
    left: -40rem
  }

  [dir='rtl'] .sm\:rtl\:-top-512,[dir='rtl'].sm\:rtl\:-top-512 {
    top: -51.2rem
  }

  [dir='rtl'] .sm\:rtl\:-right-512,[dir='rtl'].sm\:rtl\:-right-512 {
    right: -51.2rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-512,[dir='rtl'].sm\:rtl\:-bottom-512 {
    bottom: -51.2rem
  }

  [dir='rtl'] .sm\:rtl\:-left-512,[dir='rtl'].sm\:rtl\:-left-512 {
    left: -51.2rem
  }

  [dir='rtl'] .sm\:rtl\:-top-640,[dir='rtl'].sm\:rtl\:-top-640 {
    top: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-right-640,[dir='rtl'].sm\:rtl\:-right-640 {
    right: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-640,[dir='rtl'].sm\:rtl\:-bottom-640 {
    bottom: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-left-640,[dir='rtl'].sm\:rtl\:-left-640 {
    left: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-top-xs,[dir='rtl'].sm\:rtl\:-top-xs {
    top: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-right-xs,[dir='rtl'].sm\:rtl\:-right-xs {
    right: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-xs,[dir='rtl'].sm\:rtl\:-bottom-xs {
    bottom: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-left-xs,[dir='rtl'].sm\:rtl\:-left-xs {
    left: -32rem
  }

  [dir='rtl'] .sm\:rtl\:-top-sm,[dir='rtl'].sm\:rtl\:-top-sm {
    top: -48rem
  }

  [dir='rtl'] .sm\:rtl\:-right-sm,[dir='rtl'].sm\:rtl\:-right-sm {
    right: -48rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-sm,[dir='rtl'].sm\:rtl\:-bottom-sm {
    bottom: -48rem
  }

  [dir='rtl'] .sm\:rtl\:-left-sm,[dir='rtl'].sm\:rtl\:-left-sm {
    left: -48rem
  }

  [dir='rtl'] .sm\:rtl\:-top-md,[dir='rtl'].sm\:rtl\:-top-md {
    top: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-right-md,[dir='rtl'].sm\:rtl\:-right-md {
    right: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-md,[dir='rtl'].sm\:rtl\:-bottom-md {
    bottom: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-left-md,[dir='rtl'].sm\:rtl\:-left-md {
    left: -64rem
  }

  [dir='rtl'] .sm\:rtl\:-top-lg,[dir='rtl'].sm\:rtl\:-top-lg {
    top: -80rem
  }

  [dir='rtl'] .sm\:rtl\:-right-lg,[dir='rtl'].sm\:rtl\:-right-lg {
    right: -80rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-lg,[dir='rtl'].sm\:rtl\:-bottom-lg {
    bottom: -80rem
  }

  [dir='rtl'] .sm\:rtl\:-left-lg,[dir='rtl'].sm\:rtl\:-left-lg {
    left: -80rem
  }

  [dir='rtl'] .sm\:rtl\:-top-xl,[dir='rtl'].sm\:rtl\:-top-xl {
    top: -96rem
  }

  [dir='rtl'] .sm\:rtl\:-right-xl,[dir='rtl'].sm\:rtl\:-right-xl {
    right: -96rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-xl,[dir='rtl'].sm\:rtl\:-bottom-xl {
    bottom: -96rem
  }

  [dir='rtl'] .sm\:rtl\:-left-xl,[dir='rtl'].sm\:rtl\:-left-xl {
    left: -96rem
  }

  [dir='rtl'] .sm\:rtl\:-top-2xl,[dir='rtl'].sm\:rtl\:-top-2xl {
    top: -112rem
  }

  [dir='rtl'] .sm\:rtl\:-right-2xl,[dir='rtl'].sm\:rtl\:-right-2xl {
    right: -112rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-2xl,[dir='rtl'].sm\:rtl\:-bottom-2xl {
    bottom: -112rem
  }

  [dir='rtl'] .sm\:rtl\:-left-2xl,[dir='rtl'].sm\:rtl\:-left-2xl {
    left: -112rem
  }

  [dir='rtl'] .sm\:rtl\:-top-3xl,[dir='rtl'].sm\:rtl\:-top-3xl {
    top: -128rem
  }

  [dir='rtl'] .sm\:rtl\:-right-3xl,[dir='rtl'].sm\:rtl\:-right-3xl {
    right: -128rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-3xl,[dir='rtl'].sm\:rtl\:-bottom-3xl {
    bottom: -128rem
  }

  [dir='rtl'] .sm\:rtl\:-left-3xl,[dir='rtl'].sm\:rtl\:-left-3xl {
    left: -128rem
  }

  [dir='rtl'] .sm\:rtl\:-top-4xl,[dir='rtl'].sm\:rtl\:-top-4xl {
    top: -144rem
  }

  [dir='rtl'] .sm\:rtl\:-right-4xl,[dir='rtl'].sm\:rtl\:-right-4xl {
    right: -144rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-4xl,[dir='rtl'].sm\:rtl\:-bottom-4xl {
    bottom: -144rem
  }

  [dir='rtl'] .sm\:rtl\:-left-4xl,[dir='rtl'].sm\:rtl\:-left-4xl {
    left: -144rem
  }

  [dir='rtl'] .sm\:rtl\:-top-5xl,[dir='rtl'].sm\:rtl\:-top-5xl {
    top: -160rem
  }

  [dir='rtl'] .sm\:rtl\:-right-5xl,[dir='rtl'].sm\:rtl\:-right-5xl {
    right: -160rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-5xl,[dir='rtl'].sm\:rtl\:-bottom-5xl {
    bottom: -160rem
  }

  [dir='rtl'] .sm\:rtl\:-left-5xl,[dir='rtl'].sm\:rtl\:-left-5xl {
    left: -160rem
  }

  [dir='rtl'] .sm\:rtl\:-top-px,[dir='rtl'].sm\:rtl\:-top-px {
    top: -1px
  }

  [dir='rtl'] .sm\:rtl\:-right-px,[dir='rtl'].sm\:rtl\:-right-px {
    right: -1px
  }

  [dir='rtl'] .sm\:rtl\:-bottom-px,[dir='rtl'].sm\:rtl\:-bottom-px {
    bottom: -1px
  }

  [dir='rtl'] .sm\:rtl\:-left-px,[dir='rtl'].sm\:rtl\:-left-px {
    left: -1px
  }

  [dir='rtl'] .sm\:rtl\:-top-0\.5,[dir='rtl'].sm\:rtl\:-top-0\.5 {
    top: -0.05rem
  }

  [dir='rtl'] .sm\:rtl\:-right-0\.5,[dir='rtl'].sm\:rtl\:-right-0\.5 {
    right: -0.05rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-0\.5,[dir='rtl'].sm\:rtl\:-bottom-0\.5 {
    bottom: -0.05rem
  }

  [dir='rtl'] .sm\:rtl\:-left-0\.5,[dir='rtl'].sm\:rtl\:-left-0\.5 {
    left: -0.05rem
  }

  [dir='rtl'] .sm\:rtl\:-top-1\.5,[dir='rtl'].sm\:rtl\:-top-1\.5 {
    top: -0.15rem
  }

  [dir='rtl'] .sm\:rtl\:-right-1\.5,[dir='rtl'].sm\:rtl\:-right-1\.5 {
    right: -0.15rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-1\.5,[dir='rtl'].sm\:rtl\:-bottom-1\.5 {
    bottom: -0.15rem
  }

  [dir='rtl'] .sm\:rtl\:-left-1\.5,[dir='rtl'].sm\:rtl\:-left-1\.5 {
    left: -0.15rem
  }

  [dir='rtl'] .sm\:rtl\:-top-2\.5,[dir='rtl'].sm\:rtl\:-top-2\.5 {
    top: -0.25rem
  }

  [dir='rtl'] .sm\:rtl\:-right-2\.5,[dir='rtl'].sm\:rtl\:-right-2\.5 {
    right: -0.25rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-2\.5,[dir='rtl'].sm\:rtl\:-bottom-2\.5 {
    bottom: -0.25rem
  }

  [dir='rtl'] .sm\:rtl\:-left-2\.5,[dir='rtl'].sm\:rtl\:-left-2\.5 {
    left: -0.25rem
  }

  [dir='rtl'] .sm\:rtl\:-top-3\.5,[dir='rtl'].sm\:rtl\:-top-3\.5 {
    top: -0.35rem
  }

  [dir='rtl'] .sm\:rtl\:-right-3\.5,[dir='rtl'].sm\:rtl\:-right-3\.5 {
    right: -0.35rem
  }

  [dir='rtl'] .sm\:rtl\:-bottom-3\.5,[dir='rtl'].sm\:rtl\:-bottom-3\.5 {
    bottom: -0.35rem
  }

  [dir='rtl'] .sm\:rtl\:-left-3\.5,[dir='rtl'].sm\:rtl\:-left-3\.5 {
    left: -0.35rem
  }

  [dir='rtl'] .sm\:rtl\:top-1\/2,[dir='rtl'].sm\:rtl\:top-1\/2 {
    top: 50%
  }

  [dir='rtl'] .sm\:rtl\:right-1\/2,[dir='rtl'].sm\:rtl\:right-1\/2 {
    right: 50%
  }

  [dir='rtl'] .sm\:rtl\:bottom-1\/2,[dir='rtl'].sm\:rtl\:bottom-1\/2 {
    bottom: 50%
  }

  [dir='rtl'] .sm\:rtl\:left-1\/2,[dir='rtl'].sm\:rtl\:left-1\/2 {
    left: 50%
  }

  [dir='rtl'] .sm\:rtl\:top-1\/3,[dir='rtl'].sm\:rtl\:top-1\/3 {
    top: 33.333333%
  }

  [dir='rtl'] .sm\:rtl\:right-1\/3,[dir='rtl'].sm\:rtl\:right-1\/3 {
    right: 33.333333%
  }

  [dir='rtl'] .sm\:rtl\:bottom-1\/3,[dir='rtl'].sm\:rtl\:bottom-1\/3 {
    bottom: 33.333333%
  }

  [dir='rtl'] .sm\:rtl\:left-1\/3,[dir='rtl'].sm\:rtl\:left-1\/3 {
    left: 33.333333%
  }

  [dir='rtl'] .sm\:rtl\:top-2\/3,[dir='rtl'].sm\:rtl\:top-2\/3 {
    top: 66.666667%
  }

  [dir='rtl'] .sm\:rtl\:right-2\/3,[dir='rtl'].sm\:rtl\:right-2\/3 {
    right: 66.666667%
  }

  [dir='rtl'] .sm\:rtl\:bottom-2\/3,[dir='rtl'].sm\:rtl\:bottom-2\/3 {
    bottom: 66.666667%
  }

  [dir='rtl'] .sm\:rtl\:left-2\/3,[dir='rtl'].sm\:rtl\:left-2\/3 {
    left: 66.666667%
  }

  [dir='rtl'] .sm\:rtl\:top-1\/4,[dir='rtl'].sm\:rtl\:top-1\/4 {
    top: 25%
  }

  [dir='rtl'] .sm\:rtl\:right-1\/4,[dir='rtl'].sm\:rtl\:right-1\/4 {
    right: 25%
  }

  [dir='rtl'] .sm\:rtl\:bottom-1\/4,[dir='rtl'].sm\:rtl\:bottom-1\/4 {
    bottom: 25%
  }

  [dir='rtl'] .sm\:rtl\:left-1\/4,[dir='rtl'].sm\:rtl\:left-1\/4 {
    left: 25%
  }

  [dir='rtl'] .sm\:rtl\:top-2\/4,[dir='rtl'].sm\:rtl\:top-2\/4 {
    top: 50%
  }

  [dir='rtl'] .sm\:rtl\:right-2\/4,[dir='rtl'].sm\:rtl\:right-2\/4 {
    right: 50%
  }

  [dir='rtl'] .sm\:rtl\:bottom-2\/4,[dir='rtl'].sm\:rtl\:bottom-2\/4 {
    bottom: 50%
  }

  [dir='rtl'] .sm\:rtl\:left-2\/4,[dir='rtl'].sm\:rtl\:left-2\/4 {
    left: 50%
  }

  [dir='rtl'] .sm\:rtl\:top-3\/4,[dir='rtl'].sm\:rtl\:top-3\/4 {
    top: 75%
  }

  [dir='rtl'] .sm\:rtl\:right-3\/4,[dir='rtl'].sm\:rtl\:right-3\/4 {
    right: 75%
  }

  [dir='rtl'] .sm\:rtl\:bottom-3\/4,[dir='rtl'].sm\:rtl\:bottom-3\/4 {
    bottom: 75%
  }

  [dir='rtl'] .sm\:rtl\:left-3\/4,[dir='rtl'].sm\:rtl\:left-3\/4 {
    left: 75%
  }

  [dir='rtl'] .sm\:rtl\:top-full,[dir='rtl'].sm\:rtl\:top-full {
    top: 100%
  }

  [dir='rtl'] .sm\:rtl\:right-full,[dir='rtl'].sm\:rtl\:right-full {
    right: 100%
  }

  [dir='rtl'] .sm\:rtl\:bottom-full,[dir='rtl'].sm\:rtl\:bottom-full {
    bottom: 100%
  }

  [dir='rtl'] .sm\:rtl\:left-full,[dir='rtl'].sm\:rtl\:left-full {
    left: 100%
  }

  [dir='rtl'] .sm\:rtl\:-top-1\/2,[dir='rtl'].sm\:rtl\:-top-1\/2 {
    top: -50%
  }

  [dir='rtl'] .sm\:rtl\:-right-1\/2,[dir='rtl'].sm\:rtl\:-right-1\/2 {
    right: -50%
  }

  [dir='rtl'] .sm\:rtl\:-bottom-1\/2,[dir='rtl'].sm\:rtl\:-bottom-1\/2 {
    bottom: -50%
  }

  [dir='rtl'] .sm\:rtl\:-left-1\/2,[dir='rtl'].sm\:rtl\:-left-1\/2 {
    left: -50%
  }

  [dir='rtl'] .sm\:rtl\:-top-1\/3,[dir='rtl'].sm\:rtl\:-top-1\/3 {
    top: -33.333333%
  }

  [dir='rtl'] .sm\:rtl\:-right-1\/3,[dir='rtl'].sm\:rtl\:-right-1\/3 {
    right: -33.333333%
  }

  [dir='rtl'] .sm\:rtl\:-bottom-1\/3,[dir='rtl'].sm\:rtl\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  [dir='rtl'] .sm\:rtl\:-left-1\/3,[dir='rtl'].sm\:rtl\:-left-1\/3 {
    left: -33.333333%
  }

  [dir='rtl'] .sm\:rtl\:-top-2\/3,[dir='rtl'].sm\:rtl\:-top-2\/3 {
    top: -66.666667%
  }

  [dir='rtl'] .sm\:rtl\:-right-2\/3,[dir='rtl'].sm\:rtl\:-right-2\/3 {
    right: -66.666667%
  }

  [dir='rtl'] .sm\:rtl\:-bottom-2\/3,[dir='rtl'].sm\:rtl\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  [dir='rtl'] .sm\:rtl\:-left-2\/3,[dir='rtl'].sm\:rtl\:-left-2\/3 {
    left: -66.666667%
  }

  [dir='rtl'] .sm\:rtl\:-top-1\/4,[dir='rtl'].sm\:rtl\:-top-1\/4 {
    top: -25%
  }

  [dir='rtl'] .sm\:rtl\:-right-1\/4,[dir='rtl'].sm\:rtl\:-right-1\/4 {
    right: -25%
  }

  [dir='rtl'] .sm\:rtl\:-bottom-1\/4,[dir='rtl'].sm\:rtl\:-bottom-1\/4 {
    bottom: -25%
  }

  [dir='rtl'] .sm\:rtl\:-left-1\/4,[dir='rtl'].sm\:rtl\:-left-1\/4 {
    left: -25%
  }

  [dir='rtl'] .sm\:rtl\:-top-2\/4,[dir='rtl'].sm\:rtl\:-top-2\/4 {
    top: -50%
  }

  [dir='rtl'] .sm\:rtl\:-right-2\/4,[dir='rtl'].sm\:rtl\:-right-2\/4 {
    right: -50%
  }

  [dir='rtl'] .sm\:rtl\:-bottom-2\/4,[dir='rtl'].sm\:rtl\:-bottom-2\/4 {
    bottom: -50%
  }

  [dir='rtl'] .sm\:rtl\:-left-2\/4,[dir='rtl'].sm\:rtl\:-left-2\/4 {
    left: -50%
  }

  [dir='rtl'] .sm\:rtl\:-top-3\/4,[dir='rtl'].sm\:rtl\:-top-3\/4 {
    top: -75%
  }

  [dir='rtl'] .sm\:rtl\:-right-3\/4,[dir='rtl'].sm\:rtl\:-right-3\/4 {
    right: -75%
  }

  [dir='rtl'] .sm\:rtl\:-bottom-3\/4,[dir='rtl'].sm\:rtl\:-bottom-3\/4 {
    bottom: -75%
  }

  [dir='rtl'] .sm\:rtl\:-left-3\/4,[dir='rtl'].sm\:rtl\:-left-3\/4 {
    left: -75%
  }

  [dir='rtl'] .sm\:rtl\:-top-full,[dir='rtl'].sm\:rtl\:-top-full {
    top: -100%
  }

  [dir='rtl'] .sm\:rtl\:-right-full,[dir='rtl'].sm\:rtl\:-right-full {
    right: -100%
  }

  [dir='rtl'] .sm\:rtl\:-bottom-full,[dir='rtl'].sm\:rtl\:-bottom-full {
    bottom: -100%
  }

  [dir='rtl'] .sm\:rtl\:-left-full,[dir='rtl'].sm\:rtl\:-left-full {
    left: -100%
  }

  .sm\:resize-none {
    resize: none
  }

  .sm\:resize-y {
    resize: vertical
  }

  .sm\:resize-x {
    resize: horizontal
  }

  .sm\:resize {
    resize: both
  }

  .sm\:shadow-0 {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-1 {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-2 {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-3 {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-4 {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-5 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-6 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-7 {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-8 {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-9 {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-10 {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-11 {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-12 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-13 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-14 {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-15 {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-16 {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-17 {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-18 {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-19 {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-20 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-21 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-22 {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-23 {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-24 {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-0 {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-1 {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-2 {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-3 {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-4 {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-5 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-6 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-7 {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-8 {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-9 {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-10 {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-11 {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-12 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-13 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-14 {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-15 {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-16 {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-17 {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-18 {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-19 {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-20 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-21 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-22 {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-23 {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-24 {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-0:focus-within {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-1:focus-within {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-2:focus-within {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-3:focus-within {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-4:focus-within {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-5:focus-within {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-6:focus-within {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-7:focus-within {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-8:focus-within {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-9:focus-within {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-10:focus-within {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-11:focus-within {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-12:focus-within {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-13:focus-within {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-14:focus-within {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-15:focus-within {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-16:focus-within {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-17:focus-within {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-18:focus-within {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-19:focus-within {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-20:focus-within {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-21:focus-within {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-22:focus-within {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-23:focus-within {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-24:focus-within {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-0:hover {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-1:hover {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-2:hover {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-3:hover {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-4:hover {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-5:hover {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-6:hover {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-7:hover {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-8:hover {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-9:hover {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-10:hover {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-11:hover {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-12:hover {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-13:hover {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-14:hover {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-15:hover {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-16:hover {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-17:hover {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-18:hover {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-19:hover {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-20:hover {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-21:hover {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-22:hover {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-23:hover {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-24:hover {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-0:focus {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-1:focus {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-2:focus {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-3:focus {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-4:focus {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-5:focus {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-6:focus {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-7:focus {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-8:focus {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-9:focus {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-10:focus {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-11:focus {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-12:focus {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-13:focus {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-14:focus {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-15:focus {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-16:focus {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-17:focus {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-18:focus {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-19:focus {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-20:focus {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-21:focus {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-22:focus {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-23:focus {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-24:focus {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:ring-inset {
    --tw-ring-inset: inset
  }

  .sm\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset
  }

  .sm\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus\:ring-inset:focus {
    --tw-ring-inset: inset
  }

  .sm\:ring-offset-transparent {
    --tw-ring-offset-color: transparent
  }

  .sm\:ring-offset-current {
    --tw-ring-offset-color: currentColor
  }

  .sm\:ring-offset-black {
    --tw-ring-offset-color: #22292F
  }

  .sm\:ring-offset-white {
    --tw-ring-offset-color: #fff
  }

  .sm\:ring-offset-grey-50 {
    --tw-ring-offset-color: #FAFAFA
  }

  .sm\:ring-offset-grey-100 {
    --tw-ring-offset-color: #F5F5F5
  }

  .sm\:ring-offset-grey-200 {
    --tw-ring-offset-color: #EEEEEE
  }

  .sm\:ring-offset-grey-300 {
    --tw-ring-offset-color: #E0E0E0
  }

  .sm\:ring-offset-grey-400 {
    --tw-ring-offset-color: #BDBDBD
  }

  .sm\:ring-offset-grey-500 {
    --tw-ring-offset-color: #9E9E9E
  }

  .sm\:ring-offset-grey-600 {
    --tw-ring-offset-color: #757575
  }

  .sm\:ring-offset-grey-700 {
    --tw-ring-offset-color: #616161
  }

  .sm\:ring-offset-grey-800 {
    --tw-ring-offset-color: #424242
  }

  .sm\:ring-offset-grey-900 {
    --tw-ring-offset-color: #212121
  }

  .sm\:ring-offset-grey {
    --tw-ring-offset-color: #9E9E9E
  }

  .sm\:ring-offset-grey-A100 {
    --tw-ring-offset-color: #D5D5D5
  }

  .sm\:ring-offset-grey-A200 {
    --tw-ring-offset-color: #AAAAAA
  }

  .sm\:ring-offset-grey-A400 {
    --tw-ring-offset-color: #303030
  }

  .sm\:ring-offset-grey-A700 {
    --tw-ring-offset-color: #616161
  }

  .sm\:ring-offset-gray-50 {
    --tw-ring-offset-color: #FAFAFA
  }

  .sm\:ring-offset-gray-100 {
    --tw-ring-offset-color: #F5F5F5
  }

  .sm\:ring-offset-gray-200 {
    --tw-ring-offset-color: #EEEEEE
  }

  .sm\:ring-offset-gray-300 {
    --tw-ring-offset-color: #E0E0E0
  }

  .sm\:ring-offset-gray-400 {
    --tw-ring-offset-color: #BDBDBD
  }

  .sm\:ring-offset-gray-500 {
    --tw-ring-offset-color: #9E9E9E
  }

  .sm\:ring-offset-gray-600 {
    --tw-ring-offset-color: #757575
  }

  .sm\:ring-offset-gray-700 {
    --tw-ring-offset-color: #616161
  }

  .sm\:ring-offset-gray-800 {
    --tw-ring-offset-color: #424242
  }

  .sm\:ring-offset-gray-900 {
    --tw-ring-offset-color: #212121
  }

  .sm\:ring-offset-gray {
    --tw-ring-offset-color: #9E9E9E
  }

  .sm\:ring-offset-gray-hover {
    --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
  }

  .sm\:ring-offset-gray-A100 {
    --tw-ring-offset-color: #D5D5D5
  }

  .sm\:ring-offset-gray-A200 {
    --tw-ring-offset-color: #AAAAAA
  }

  .sm\:ring-offset-gray-A400 {
    --tw-ring-offset-color: #303030
  }

  .sm\:ring-offset-gray-A700 {
    --tw-ring-offset-color: #616161
  }

  .sm\:ring-offset-red-50 {
    --tw-ring-offset-color: #FFEBEE
  }

  .sm\:ring-offset-red-100 {
    --tw-ring-offset-color: #FFCDD2
  }

  .sm\:ring-offset-red-200 {
    --tw-ring-offset-color: #EF9A9A
  }

  .sm\:ring-offset-red-300 {
    --tw-ring-offset-color: #E57373
  }

  .sm\:ring-offset-red-400 {
    --tw-ring-offset-color: #EF5350
  }

  .sm\:ring-offset-red-500 {
    --tw-ring-offset-color: #F44336
  }

  .sm\:ring-offset-red-600 {
    --tw-ring-offset-color: #E53935
  }

  .sm\:ring-offset-red-700 {
    --tw-ring-offset-color: #D32F2F
  }

  .sm\:ring-offset-red-800 {
    --tw-ring-offset-color: #C62828
  }

  .sm\:ring-offset-red-900 {
    --tw-ring-offset-color: #B71C1C
  }

  .sm\:ring-offset-red {
    --tw-ring-offset-color: #F44336
  }

  .sm\:ring-offset-red-A100 {
    --tw-ring-offset-color: #FF8A80
  }

  .sm\:ring-offset-red-A200 {
    --tw-ring-offset-color: #FF5252
  }

  .sm\:ring-offset-red-A400 {
    --tw-ring-offset-color: #FF1744
  }

  .sm\:ring-offset-red-A700 {
    --tw-ring-offset-color: #D50000
  }

  .sm\:ring-offset-orange-50 {
    --tw-ring-offset-color: #FFF3E0
  }

  .sm\:ring-offset-orange-100 {
    --tw-ring-offset-color: #FFE0B2
  }

  .sm\:ring-offset-orange-200 {
    --tw-ring-offset-color: #FFCC80
  }

  .sm\:ring-offset-orange-300 {
    --tw-ring-offset-color: #FFB74D
  }

  .sm\:ring-offset-orange-400 {
    --tw-ring-offset-color: #FFA726
  }

  .sm\:ring-offset-orange-500 {
    --tw-ring-offset-color: #FF9800
  }

  .sm\:ring-offset-orange-600 {
    --tw-ring-offset-color: #FB8C00
  }

  .sm\:ring-offset-orange-700 {
    --tw-ring-offset-color: #F57C00
  }

  .sm\:ring-offset-orange-800 {
    --tw-ring-offset-color: #EF6C00
  }

  .sm\:ring-offset-orange-900 {
    --tw-ring-offset-color: #E65100
  }

  .sm\:ring-offset-orange {
    --tw-ring-offset-color: #FF9800
  }

  .sm\:ring-offset-orange-A100 {
    --tw-ring-offset-color: #FFD180
  }

  .sm\:ring-offset-orange-A200 {
    --tw-ring-offset-color: #FFAB40
  }

  .sm\:ring-offset-orange-A400 {
    --tw-ring-offset-color: #FF9100
  }

  .sm\:ring-offset-orange-A700 {
    --tw-ring-offset-color: #FF6D00
  }

  .sm\:ring-offset-deep-orange-50 {
    --tw-ring-offset-color: #FBE9E7
  }

  .sm\:ring-offset-deep-orange-100 {
    --tw-ring-offset-color: #FFCCBC
  }

  .sm\:ring-offset-deep-orange-200 {
    --tw-ring-offset-color: #FFAB91
  }

  .sm\:ring-offset-deep-orange-300 {
    --tw-ring-offset-color: #FF8A65
  }

  .sm\:ring-offset-deep-orange-400 {
    --tw-ring-offset-color: #FF7043
  }

  .sm\:ring-offset-deep-orange-500 {
    --tw-ring-offset-color: #FF5722
  }

  .sm\:ring-offset-deep-orange-600 {
    --tw-ring-offset-color: #F4511E
  }

  .sm\:ring-offset-deep-orange-700 {
    --tw-ring-offset-color: #E64A19
  }

  .sm\:ring-offset-deep-orange-800 {
    --tw-ring-offset-color: #D84315
  }

  .sm\:ring-offset-deep-orange-900 {
    --tw-ring-offset-color: #BF360C
  }

  .sm\:ring-offset-deep-orange {
    --tw-ring-offset-color: #FF5722
  }

  .sm\:ring-offset-deep-orange-A100 {
    --tw-ring-offset-color: #FF9E80
  }

  .sm\:ring-offset-deep-orange-A200 {
    --tw-ring-offset-color: #FF6E40
  }

  .sm\:ring-offset-deep-orange-A400 {
    --tw-ring-offset-color: #FF3D00
  }

  .sm\:ring-offset-deep-orange-A700 {
    --tw-ring-offset-color: #DD2C00
  }

  .sm\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #FFFDE7
  }

  .sm\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #FFF9C4
  }

  .sm\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #FFF59D
  }

  .sm\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #FFF176
  }

  .sm\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #FFEE58
  }

  .sm\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #FFEB3B
  }

  .sm\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #FDD835
  }

  .sm\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #FBC02D
  }

  .sm\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #F9A825
  }

  .sm\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #F57F17
  }

  .sm\:ring-offset-yellow {
    --tw-ring-offset-color: #FFEB3B
  }

  .sm\:ring-offset-yellow-A100 {
    --tw-ring-offset-color: #FFFF8D
  }

  .sm\:ring-offset-yellow-A200 {
    --tw-ring-offset-color: #FFFF00
  }

  .sm\:ring-offset-yellow-A400 {
    --tw-ring-offset-color: #FFEA00
  }

  .sm\:ring-offset-yellow-A700 {
    --tw-ring-offset-color: #FFD600
  }

  .sm\:ring-offset-green-50 {
    --tw-ring-offset-color: #E8F5E9
  }

  .sm\:ring-offset-green-100 {
    --tw-ring-offset-color: #C8E6C9
  }

  .sm\:ring-offset-green-200 {
    --tw-ring-offset-color: #A5D6A7
  }

  .sm\:ring-offset-green-300 {
    --tw-ring-offset-color: #81C784
  }

  .sm\:ring-offset-green-400 {
    --tw-ring-offset-color: #66BB6A
  }

  .sm\:ring-offset-green-500 {
    --tw-ring-offset-color: #4CAF50
  }

  .sm\:ring-offset-green-600 {
    --tw-ring-offset-color: #43A047
  }

  .sm\:ring-offset-green-700 {
    --tw-ring-offset-color: #388E3C
  }

  .sm\:ring-offset-green-800 {
    --tw-ring-offset-color: #2E7D32
  }

  .sm\:ring-offset-green-900 {
    --tw-ring-offset-color: #1B5E20
  }

  .sm\:ring-offset-green {
    --tw-ring-offset-color: #4CAF50
  }

  .sm\:ring-offset-green-A100 {
    --tw-ring-offset-color: #B9F6CA
  }

  .sm\:ring-offset-green-A200 {
    --tw-ring-offset-color: #69F0AE
  }

  .sm\:ring-offset-green-A400 {
    --tw-ring-offset-color: #00E676
  }

  .sm\:ring-offset-green-A700 {
    --tw-ring-offset-color: #00C853
  }

  .sm\:ring-offset-light-green-50 {
    --tw-ring-offset-color: #F1F8E9
  }

  .sm\:ring-offset-light-green-100 {
    --tw-ring-offset-color: #DCEDC8
  }

  .sm\:ring-offset-light-green-200 {
    --tw-ring-offset-color: #C5E1A5
  }

  .sm\:ring-offset-light-green-300 {
    --tw-ring-offset-color: #AED581
  }

  .sm\:ring-offset-light-green-400 {
    --tw-ring-offset-color: #9CCC65
  }

  .sm\:ring-offset-light-green-500 {
    --tw-ring-offset-color: #8BC34A
  }

  .sm\:ring-offset-light-green-600 {
    --tw-ring-offset-color: #7CB342
  }

  .sm\:ring-offset-light-green-700 {
    --tw-ring-offset-color: #689F38
  }

  .sm\:ring-offset-light-green-800 {
    --tw-ring-offset-color: #558B2F
  }

  .sm\:ring-offset-light-green-900 {
    --tw-ring-offset-color: #33691E
  }

  .sm\:ring-offset-light-green {
    --tw-ring-offset-color: #8BC34A
  }

  .sm\:ring-offset-light-green-A100 {
    --tw-ring-offset-color: #CCFF90
  }

  .sm\:ring-offset-light-green-A200 {
    --tw-ring-offset-color: #B2FF59
  }

  .sm\:ring-offset-light-green-A400 {
    --tw-ring-offset-color: #76FF03
  }

  .sm\:ring-offset-light-green-A700 {
    --tw-ring-offset-color: #64DD17
  }

  .sm\:ring-offset-teal-50 {
    --tw-ring-offset-color: #E0F2F1
  }

  .sm\:ring-offset-teal-100 {
    --tw-ring-offset-color: #B2DFDB
  }

  .sm\:ring-offset-teal-200 {
    --tw-ring-offset-color: #80CBC4
  }

  .sm\:ring-offset-teal-300 {
    --tw-ring-offset-color: #4DB6AC
  }

  .sm\:ring-offset-teal-400 {
    --tw-ring-offset-color: #26A69A
  }

  .sm\:ring-offset-teal-500 {
    --tw-ring-offset-color: #009688
  }

  .sm\:ring-offset-teal-600 {
    --tw-ring-offset-color: #00897B
  }

  .sm\:ring-offset-teal-700 {
    --tw-ring-offset-color: #00796B
  }

  .sm\:ring-offset-teal-800 {
    --tw-ring-offset-color: #00695C
  }

  .sm\:ring-offset-teal-900 {
    --tw-ring-offset-color: #004D40
  }

  .sm\:ring-offset-teal {
    --tw-ring-offset-color: #009688
  }

  .sm\:ring-offset-teal-A100 {
    --tw-ring-offset-color: #A7FFEB
  }

  .sm\:ring-offset-teal-A200 {
    --tw-ring-offset-color: #64FFDA
  }

  .sm\:ring-offset-teal-A400 {
    --tw-ring-offset-color: #1DE9B6
  }

  .sm\:ring-offset-teal-A700 {
    --tw-ring-offset-color: #00BFA5
  }

  .sm\:ring-offset-blue-50 {
    --tw-ring-offset-color: #E3F2FD
  }

  .sm\:ring-offset-blue-100 {
    --tw-ring-offset-color: #BBDEFB
  }

  .sm\:ring-offset-blue-200 {
    --tw-ring-offset-color: #90CAF9
  }

  .sm\:ring-offset-blue-300 {
    --tw-ring-offset-color: #64B5F6
  }

  .sm\:ring-offset-blue-400 {
    --tw-ring-offset-color: #42A5F5
  }

  .sm\:ring-offset-blue-500 {
    --tw-ring-offset-color: #2196F3
  }

  .sm\:ring-offset-blue-600 {
    --tw-ring-offset-color: #1E88E5
  }

  .sm\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1976D2
  }

  .sm\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1565C0
  }

  .sm\:ring-offset-blue-900 {
    --tw-ring-offset-color: #0D47A1
  }

  .sm\:ring-offset-blue {
    --tw-ring-offset-color: #2196F3
  }

  .sm\:ring-offset-blue-A100 {
    --tw-ring-offset-color: #82B1FF
  }

  .sm\:ring-offset-blue-A200 {
    --tw-ring-offset-color: #448AFF
  }

  .sm\:ring-offset-blue-A400 {
    --tw-ring-offset-color: #2979FF
  }

  .sm\:ring-offset-blue-A700 {
    --tw-ring-offset-color: #2962FF
  }

  .sm\:ring-offset-light-blue-50 {
    --tw-ring-offset-color: #E1F5FE
  }

  .sm\:ring-offset-light-blue-100 {
    --tw-ring-offset-color: #B3E5FC
  }

  .sm\:ring-offset-light-blue-200 {
    --tw-ring-offset-color: #81D4FA
  }

  .sm\:ring-offset-light-blue-300 {
    --tw-ring-offset-color: #4FC3F7
  }

  .sm\:ring-offset-light-blue-400 {
    --tw-ring-offset-color: #29B6F6
  }

  .sm\:ring-offset-light-blue-500 {
    --tw-ring-offset-color: #03A9F4
  }

  .sm\:ring-offset-light-blue-600 {
    --tw-ring-offset-color: #039BE5
  }

  .sm\:ring-offset-light-blue-700 {
    --tw-ring-offset-color: #0288D1
  }

  .sm\:ring-offset-light-blue-800 {
    --tw-ring-offset-color: #0277BD
  }

  .sm\:ring-offset-light-blue-900 {
    --tw-ring-offset-color: #01579B
  }

  .sm\:ring-offset-light-blue {
    --tw-ring-offset-color: #03A9F4
  }

  .sm\:ring-offset-light-blue-A100 {
    --tw-ring-offset-color: #80D8FF
  }

  .sm\:ring-offset-light-blue-A200 {
    --tw-ring-offset-color: #40C4FF
  }

  .sm\:ring-offset-light-blue-A400 {
    --tw-ring-offset-color: #00B0FF
  }

  .sm\:ring-offset-light-blue-A700 {
    --tw-ring-offset-color: #0091EA
  }

  .sm\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #E8EAF6
  }

  .sm\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #C5CAE9
  }

  .sm\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #9FA8DA
  }

  .sm\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #7986CB
  }

  .sm\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #5C6BC0
  }

  .sm\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #3F51B5
  }

  .sm\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #3949AB
  }

  .sm\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #303F9F
  }

  .sm\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #283593
  }

  .sm\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #1A237E
  }

  .sm\:ring-offset-indigo {
    --tw-ring-offset-color: #3F51B5
  }

  .sm\:ring-offset-indigo-A100 {
    --tw-ring-offset-color: #8C9EFF
  }

  .sm\:ring-offset-indigo-A200 {
    --tw-ring-offset-color: #536DFE
  }

  .sm\:ring-offset-indigo-A400 {
    --tw-ring-offset-color: #3D5AFE
  }

  .sm\:ring-offset-indigo-A700 {
    --tw-ring-offset-color: #304FFE
  }

  .sm\:ring-offset-purple-50 {
    --tw-ring-offset-color: #F3E5F5
  }

  .sm\:ring-offset-purple-100 {
    --tw-ring-offset-color: #E1BEE7
  }

  .sm\:ring-offset-purple-200 {
    --tw-ring-offset-color: #CE93D8
  }

  .sm\:ring-offset-purple-300 {
    --tw-ring-offset-color: #BA68C8
  }

  .sm\:ring-offset-purple-400 {
    --tw-ring-offset-color: #AB47BC
  }

  .sm\:ring-offset-purple-500 {
    --tw-ring-offset-color: #9C27B0
  }

  .sm\:ring-offset-purple-600 {
    --tw-ring-offset-color: #8E24AA
  }

  .sm\:ring-offset-purple-700 {
    --tw-ring-offset-color: #7B1FA2
  }

  .sm\:ring-offset-purple-800 {
    --tw-ring-offset-color: #6A1B9A
  }

  .sm\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4A148C
  }

  .sm\:ring-offset-purple {
    --tw-ring-offset-color: #9C27B0
  }

  .sm\:ring-offset-purple-A100 {
    --tw-ring-offset-color: #EA80FC
  }

  .sm\:ring-offset-purple-A200 {
    --tw-ring-offset-color: #E040FB
  }

  .sm\:ring-offset-purple-A400 {
    --tw-ring-offset-color: #D500F9
  }

  .sm\:ring-offset-purple-A700 {
    --tw-ring-offset-color: #AA00FF
  }

  .sm\:ring-offset-deep-purple-50 {
    --tw-ring-offset-color: #EDE7F6
  }

  .sm\:ring-offset-deep-purple-100 {
    --tw-ring-offset-color: #D1C4E9
  }

  .sm\:ring-offset-deep-purple-200 {
    --tw-ring-offset-color: #B39DDB
  }

  .sm\:ring-offset-deep-purple-300 {
    --tw-ring-offset-color: #9575CD
  }

  .sm\:ring-offset-deep-purple-400 {
    --tw-ring-offset-color: #7E57C2
  }

  .sm\:ring-offset-deep-purple-500 {
    --tw-ring-offset-color: #673AB7
  }

  .sm\:ring-offset-deep-purple-600 {
    --tw-ring-offset-color: #5E35B1
  }

  .sm\:ring-offset-deep-purple-700 {
    --tw-ring-offset-color: #512DA8
  }

  .sm\:ring-offset-deep-purple-800 {
    --tw-ring-offset-color: #4527A0
  }

  .sm\:ring-offset-deep-purple-900 {
    --tw-ring-offset-color: #311B92
  }

  .sm\:ring-offset-deep-purple {
    --tw-ring-offset-color: #673AB7
  }

  .sm\:ring-offset-deep-purple-A100 {
    --tw-ring-offset-color: #B388FF
  }

  .sm\:ring-offset-deep-purple-A200 {
    --tw-ring-offset-color: #7C4DFF
  }

  .sm\:ring-offset-deep-purple-A400 {
    --tw-ring-offset-color: #651FFF
  }

  .sm\:ring-offset-deep-purple-A700 {
    --tw-ring-offset-color: #6200EA
  }

  .sm\:ring-offset-pink-50 {
    --tw-ring-offset-color: #FCE4EC
  }

  .sm\:ring-offset-pink-100 {
    --tw-ring-offset-color: #F8BBD0
  }

  .sm\:ring-offset-pink-200 {
    --tw-ring-offset-color: #F48FB1
  }

  .sm\:ring-offset-pink-300 {
    --tw-ring-offset-color: #F06292
  }

  .sm\:ring-offset-pink-400 {
    --tw-ring-offset-color: #EC407A
  }

  .sm\:ring-offset-pink-500 {
    --tw-ring-offset-color: #E91E63
  }

  .sm\:ring-offset-pink-600 {
    --tw-ring-offset-color: #D81B60
  }

  .sm\:ring-offset-pink-700 {
    --tw-ring-offset-color: #C2185B
  }

  .sm\:ring-offset-pink-800 {
    --tw-ring-offset-color: #AD1457
  }

  .sm\:ring-offset-pink-900 {
    --tw-ring-offset-color: #880E4F
  }

  .sm\:ring-offset-pink {
    --tw-ring-offset-color: #E91E63
  }

  .sm\:ring-offset-pink-A100 {
    --tw-ring-offset-color: #FF80AB
  }

  .sm\:ring-offset-pink-A200 {
    --tw-ring-offset-color: #FF4081
  }

  .sm\:ring-offset-pink-A400 {
    --tw-ring-offset-color: #F50057
  }

  .sm\:ring-offset-pink-A700 {
    --tw-ring-offset-color: #C51162
  }

  .sm\:ring-offset-lime-50 {
    --tw-ring-offset-color: #F9FBE7
  }

  .sm\:ring-offset-lime-100 {
    --tw-ring-offset-color: #F0F4C3
  }

  .sm\:ring-offset-lime-200 {
    --tw-ring-offset-color: #E6EE9C
  }

  .sm\:ring-offset-lime-300 {
    --tw-ring-offset-color: #DCE775
  }

  .sm\:ring-offset-lime-400 {
    --tw-ring-offset-color: #D4E157
  }

  .sm\:ring-offset-lime-500 {
    --tw-ring-offset-color: #CDDC39
  }

  .sm\:ring-offset-lime-600 {
    --tw-ring-offset-color: #C0CA33
  }

  .sm\:ring-offset-lime-700 {
    --tw-ring-offset-color: #AFB42B
  }

  .sm\:ring-offset-lime-800 {
    --tw-ring-offset-color: #9E9D24
  }

  .sm\:ring-offset-lime-900 {
    --tw-ring-offset-color: #827717
  }

  .sm\:ring-offset-lime {
    --tw-ring-offset-color: #CDDC39
  }

  .sm\:ring-offset-lime-A100 {
    --tw-ring-offset-color: #F4FF81
  }

  .sm\:ring-offset-lime-A200 {
    --tw-ring-offset-color: #EEFF41
  }

  .sm\:ring-offset-lime-A400 {
    --tw-ring-offset-color: #C6FF00
  }

  .sm\:ring-offset-lime-A700 {
    --tw-ring-offset-color: #AEEA00
  }

  .sm\:ring-offset-amber-50 {
    --tw-ring-offset-color: #FFF8E1
  }

  .sm\:ring-offset-amber-100 {
    --tw-ring-offset-color: #FFECB3
  }

  .sm\:ring-offset-amber-200 {
    --tw-ring-offset-color: #FFE082
  }

  .sm\:ring-offset-amber-300 {
    --tw-ring-offset-color: #FFD54F
  }

  .sm\:ring-offset-amber-400 {
    --tw-ring-offset-color: #FFCA28
  }

  .sm\:ring-offset-amber-500 {
    --tw-ring-offset-color: #FFC107
  }

  .sm\:ring-offset-amber-600 {
    --tw-ring-offset-color: #FFB300
  }

  .sm\:ring-offset-amber-700 {
    --tw-ring-offset-color: #FFA000
  }

  .sm\:ring-offset-amber-800 {
    --tw-ring-offset-color: #FF8F00
  }

  .sm\:ring-offset-amber-900 {
    --tw-ring-offset-color: #FF6F00
  }

  .sm\:ring-offset-amber {
    --tw-ring-offset-color: #FFC107
  }

  .sm\:ring-offset-amber-A100 {
    --tw-ring-offset-color: #FFE57F
  }

  .sm\:ring-offset-amber-A200 {
    --tw-ring-offset-color: #FFD740
  }

  .sm\:ring-offset-amber-A400 {
    --tw-ring-offset-color: #FFC400
  }

  .sm\:ring-offset-amber-A700 {
    --tw-ring-offset-color: #FFAB00
  }

  .sm\:ring-offset-brown-50 {
    --tw-ring-offset-color: #EFEBE9
  }

  .sm\:ring-offset-brown-100 {
    --tw-ring-offset-color: #D7CCC8
  }

  .sm\:ring-offset-brown-200 {
    --tw-ring-offset-color: #BCAAA4
  }

  .sm\:ring-offset-brown-300 {
    --tw-ring-offset-color: #A1887F
  }

  .sm\:ring-offset-brown-400 {
    --tw-ring-offset-color: #8D6E63
  }

  .sm\:ring-offset-brown-500 {
    --tw-ring-offset-color: #795548
  }

  .sm\:ring-offset-brown-600 {
    --tw-ring-offset-color: #6D4C41
  }

  .sm\:ring-offset-brown-700 {
    --tw-ring-offset-color: #5D4037
  }

  .sm\:ring-offset-brown-800 {
    --tw-ring-offset-color: #4E342E
  }

  .sm\:ring-offset-brown-900 {
    --tw-ring-offset-color: #3E2723
  }

  .sm\:ring-offset-brown {
    --tw-ring-offset-color: #795548
  }

  .sm\:ring-offset-brown-A100 {
    --tw-ring-offset-color: #D7CCC8
  }

  .sm\:ring-offset-brown-A200 {
    --tw-ring-offset-color: #BCAAA4
  }

  .sm\:ring-offset-brown-A400 {
    --tw-ring-offset-color: #8D6E63
  }

  .sm\:ring-offset-brown-A700 {
    --tw-ring-offset-color: #5D4037
  }

  .sm\:ring-offset-blue-gray-50 {
    --tw-ring-offset-color: #ECEFF1
  }

  .sm\:ring-offset-blue-gray-100 {
    --tw-ring-offset-color: #CFD8DC
  }

  .sm\:ring-offset-blue-gray-200 {
    --tw-ring-offset-color: #B0BEC5
  }

  .sm\:ring-offset-blue-gray-300 {
    --tw-ring-offset-color: #90A4AE
  }

  .sm\:ring-offset-blue-gray-400 {
    --tw-ring-offset-color: #78909C
  }

  .sm\:ring-offset-blue-gray-500 {
    --tw-ring-offset-color: #607D8B
  }

  .sm\:ring-offset-blue-gray-600 {
    --tw-ring-offset-color: #546E7A
  }

  .sm\:ring-offset-blue-gray-700 {
    --tw-ring-offset-color: #455A64
  }

  .sm\:ring-offset-blue-gray-800 {
    --tw-ring-offset-color: #37474F
  }

  .sm\:ring-offset-blue-gray-900 {
    --tw-ring-offset-color: #263238
  }

  .sm\:ring-offset-blue-gray {
    --tw-ring-offset-color: #607D8B
  }

  .sm\:ring-offset-blue-gray-A100 {
    --tw-ring-offset-color: #CFD8DC
  }

  .sm\:ring-offset-blue-gray-A200 {
    --tw-ring-offset-color: #B0BEC5
  }

  .sm\:ring-offset-blue-gray-A400 {
    --tw-ring-offset-color: #78909C
  }

  .sm\:ring-offset-blue-gray-A700 {
    --tw-ring-offset-color: #455A64
  }

  .sm\:ring-offset-cyan-50 {
    --tw-ring-offset-color: #E0F7FA
  }

  .sm\:ring-offset-cyan-100 {
    --tw-ring-offset-color: #B2EBF2
  }

  .sm\:ring-offset-cyan-200 {
    --tw-ring-offset-color: #80DEEA
  }

  .sm\:ring-offset-cyan-300 {
    --tw-ring-offset-color: #4DD0E1
  }

  .sm\:ring-offset-cyan-400 {
    --tw-ring-offset-color: #26C6DA
  }

  .sm\:ring-offset-cyan-500 {
    --tw-ring-offset-color: #00BCD4
  }

  .sm\:ring-offset-cyan-600 {
    --tw-ring-offset-color: #00ACC1
  }

  .sm\:ring-offset-cyan-700 {
    --tw-ring-offset-color: #0097A7
  }

  .sm\:ring-offset-cyan-800 {
    --tw-ring-offset-color: #00838F
  }

  .sm\:ring-offset-cyan-900 {
    --tw-ring-offset-color: #006064
  }

  .sm\:ring-offset-cyan {
    --tw-ring-offset-color: #00BCD4
  }

  .sm\:ring-offset-cyan-A100 {
    --tw-ring-offset-color: #84FFFF
  }

  .sm\:ring-offset-cyan-A200 {
    --tw-ring-offset-color: #18FFFF
  }

  .sm\:ring-offset-cyan-A400 {
    --tw-ring-offset-color: #00E5FF
  }

  .sm\:ring-offset-cyan-A700 {
    --tw-ring-offset-color: #00B8D4
  }

  .sm\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent
  }

  .sm\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor
  }

  .sm\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #22292F
  }

  .sm\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff
  }

  .sm\:focus-within\:ring-offset-grey-50:focus-within {
    --tw-ring-offset-color: #FAFAFA
  }

  .sm\:focus-within\:ring-offset-grey-100:focus-within {
    --tw-ring-offset-color: #F5F5F5
  }

  .sm\:focus-within\:ring-offset-grey-200:focus-within {
    --tw-ring-offset-color: #EEEEEE
  }

  .sm\:focus-within\:ring-offset-grey-300:focus-within {
    --tw-ring-offset-color: #E0E0E0
  }

  .sm\:focus-within\:ring-offset-grey-400:focus-within {
    --tw-ring-offset-color: #BDBDBD
  }

  .sm\:focus-within\:ring-offset-grey-500:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .sm\:focus-within\:ring-offset-grey-600:focus-within {
    --tw-ring-offset-color: #757575
  }

  .sm\:focus-within\:ring-offset-grey-700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .sm\:focus-within\:ring-offset-grey-800:focus-within {
    --tw-ring-offset-color: #424242
  }

  .sm\:focus-within\:ring-offset-grey-900:focus-within {
    --tw-ring-offset-color: #212121
  }

  .sm\:focus-within\:ring-offset-grey:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .sm\:focus-within\:ring-offset-grey-A100:focus-within {
    --tw-ring-offset-color: #D5D5D5
  }

  .sm\:focus-within\:ring-offset-grey-A200:focus-within {
    --tw-ring-offset-color: #AAAAAA
  }

  .sm\:focus-within\:ring-offset-grey-A400:focus-within {
    --tw-ring-offset-color: #303030
  }

  .sm\:focus-within\:ring-offset-grey-A700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .sm\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #FAFAFA
  }

  .sm\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #F5F5F5
  }

  .sm\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #EEEEEE
  }

  .sm\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #E0E0E0
  }

  .sm\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #BDBDBD
  }

  .sm\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .sm\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #757575
  }

  .sm\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .sm\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #424242
  }

  .sm\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #212121
  }

  .sm\:focus-within\:ring-offset-gray:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .sm\:focus-within\:ring-offset-gray-hover:focus-within {
    --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
  }

  .sm\:focus-within\:ring-offset-gray-A100:focus-within {
    --tw-ring-offset-color: #D5D5D5
  }

  .sm\:focus-within\:ring-offset-gray-A200:focus-within {
    --tw-ring-offset-color: #AAAAAA
  }

  .sm\:focus-within\:ring-offset-gray-A400:focus-within {
    --tw-ring-offset-color: #303030
  }

  .sm\:focus-within\:ring-offset-gray-A700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .sm\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #FFEBEE
  }

  .sm\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #FFCDD2
  }

  .sm\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #EF9A9A
  }

  .sm\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #E57373
  }

  .sm\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #EF5350
  }

  .sm\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #F44336
  }

  .sm\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #E53935
  }

  .sm\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #D32F2F
  }

  .sm\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #C62828
  }

  .sm\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #B71C1C
  }

  .sm\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: #F44336
  }

  .sm\:focus-within\:ring-offset-red-A100:focus-within {
    --tw-ring-offset-color: #FF8A80
  }

  .sm\:focus-within\:ring-offset-red-A200:focus-within {
    --tw-ring-offset-color: #FF5252
  }

  .sm\:focus-within\:ring-offset-red-A400:focus-within {
    --tw-ring-offset-color: #FF1744
  }

  .sm\:focus-within\:ring-offset-red-A700:focus-within {
    --tw-ring-offset-color: #D50000
  }

  .sm\:focus-within\:ring-offset-orange-50:focus-within {
    --tw-ring-offset-color: #FFF3E0
  }

  .sm\:focus-within\:ring-offset-orange-100:focus-within {
    --tw-ring-offset-color: #FFE0B2
  }

  .sm\:focus-within\:ring-offset-orange-200:focus-within {
    --tw-ring-offset-color: #FFCC80
  }

  .sm\:focus-within\:ring-offset-orange-300:focus-within {
    --tw-ring-offset-color: #FFB74D
  }

  .sm\:focus-within\:ring-offset-orange-400:focus-within {
    --tw-ring-offset-color: #FFA726
  }

  .sm\:focus-within\:ring-offset-orange-500:focus-within {
    --tw-ring-offset-color: #FF9800
  }

  .sm\:focus-within\:ring-offset-orange-600:focus-within {
    --tw-ring-offset-color: #FB8C00
  }

  .sm\:focus-within\:ring-offset-orange-700:focus-within {
    --tw-ring-offset-color: #F57C00
  }

  .sm\:focus-within\:ring-offset-orange-800:focus-within {
    --tw-ring-offset-color: #EF6C00
  }

  .sm\:focus-within\:ring-offset-orange-900:focus-within {
    --tw-ring-offset-color: #E65100
  }

  .sm\:focus-within\:ring-offset-orange:focus-within {
    --tw-ring-offset-color: #FF9800
  }

  .sm\:focus-within\:ring-offset-orange-A100:focus-within {
    --tw-ring-offset-color: #FFD180
  }

  .sm\:focus-within\:ring-offset-orange-A200:focus-within {
    --tw-ring-offset-color: #FFAB40
  }

  .sm\:focus-within\:ring-offset-orange-A400:focus-within {
    --tw-ring-offset-color: #FF9100
  }

  .sm\:focus-within\:ring-offset-orange-A700:focus-within {
    --tw-ring-offset-color: #FF6D00
  }

  .sm\:focus-within\:ring-offset-deep-orange-50:focus-within {
    --tw-ring-offset-color: #FBE9E7
  }

  .sm\:focus-within\:ring-offset-deep-orange-100:focus-within {
    --tw-ring-offset-color: #FFCCBC
  }

  .sm\:focus-within\:ring-offset-deep-orange-200:focus-within {
    --tw-ring-offset-color: #FFAB91
  }

  .sm\:focus-within\:ring-offset-deep-orange-300:focus-within {
    --tw-ring-offset-color: #FF8A65
  }

  .sm\:focus-within\:ring-offset-deep-orange-400:focus-within {
    --tw-ring-offset-color: #FF7043
  }

  .sm\:focus-within\:ring-offset-deep-orange-500:focus-within {
    --tw-ring-offset-color: #FF5722
  }

  .sm\:focus-within\:ring-offset-deep-orange-600:focus-within {
    --tw-ring-offset-color: #F4511E
  }

  .sm\:focus-within\:ring-offset-deep-orange-700:focus-within {
    --tw-ring-offset-color: #E64A19
  }

  .sm\:focus-within\:ring-offset-deep-orange-800:focus-within {
    --tw-ring-offset-color: #D84315
  }

  .sm\:focus-within\:ring-offset-deep-orange-900:focus-within {
    --tw-ring-offset-color: #BF360C
  }

  .sm\:focus-within\:ring-offset-deep-orange:focus-within {
    --tw-ring-offset-color: #FF5722
  }

  .sm\:focus-within\:ring-offset-deep-orange-A100:focus-within {
    --tw-ring-offset-color: #FF9E80
  }

  .sm\:focus-within\:ring-offset-deep-orange-A200:focus-within {
    --tw-ring-offset-color: #FF6E40
  }

  .sm\:focus-within\:ring-offset-deep-orange-A400:focus-within {
    --tw-ring-offset-color: #FF3D00
  }

  .sm\:focus-within\:ring-offset-deep-orange-A700:focus-within {
    --tw-ring-offset-color: #DD2C00
  }

  .sm\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #FFFDE7
  }

  .sm\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #FFF9C4
  }

  .sm\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #FFF59D
  }

  .sm\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #FFF176
  }

  .sm\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #FFEE58
  }

  .sm\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #FFEB3B
  }

  .sm\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #FDD835
  }

  .sm\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #FBC02D
  }

  .sm\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #F9A825
  }

  .sm\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #F57F17
  }

  .sm\:focus-within\:ring-offset-yellow:focus-within {
    --tw-ring-offset-color: #FFEB3B
  }

  .sm\:focus-within\:ring-offset-yellow-A100:focus-within {
    --tw-ring-offset-color: #FFFF8D
  }

  .sm\:focus-within\:ring-offset-yellow-A200:focus-within {
    --tw-ring-offset-color: #FFFF00
  }

  .sm\:focus-within\:ring-offset-yellow-A400:focus-within {
    --tw-ring-offset-color: #FFEA00
  }

  .sm\:focus-within\:ring-offset-yellow-A700:focus-within {
    --tw-ring-offset-color: #FFD600
  }

  .sm\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #E8F5E9
  }

  .sm\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #C8E6C9
  }

  .sm\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #A5D6A7
  }

  .sm\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #81C784
  }

  .sm\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #66BB6A
  }

  .sm\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #4CAF50
  }

  .sm\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #43A047
  }

  .sm\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #388E3C
  }

  .sm\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #2E7D32
  }

  .sm\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #1B5E20
  }

  .sm\:focus-within\:ring-offset-green:focus-within {
    --tw-ring-offset-color: #4CAF50
  }

  .sm\:focus-within\:ring-offset-green-A100:focus-within {
    --tw-ring-offset-color: #B9F6CA
  }

  .sm\:focus-within\:ring-offset-green-A200:focus-within {
    --tw-ring-offset-color: #69F0AE
  }

  .sm\:focus-within\:ring-offset-green-A400:focus-within {
    --tw-ring-offset-color: #00E676
  }

  .sm\:focus-within\:ring-offset-green-A700:focus-within {
    --tw-ring-offset-color: #00C853
  }

  .sm\:focus-within\:ring-offset-light-green-50:focus-within {
    --tw-ring-offset-color: #F1F8E9
  }

  .sm\:focus-within\:ring-offset-light-green-100:focus-within {
    --tw-ring-offset-color: #DCEDC8
  }

  .sm\:focus-within\:ring-offset-light-green-200:focus-within {
    --tw-ring-offset-color: #C5E1A5
  }

  .sm\:focus-within\:ring-offset-light-green-300:focus-within {
    --tw-ring-offset-color: #AED581
  }

  .sm\:focus-within\:ring-offset-light-green-400:focus-within {
    --tw-ring-offset-color: #9CCC65
  }

  .sm\:focus-within\:ring-offset-light-green-500:focus-within {
    --tw-ring-offset-color: #8BC34A
  }

  .sm\:focus-within\:ring-offset-light-green-600:focus-within {
    --tw-ring-offset-color: #7CB342
  }

  .sm\:focus-within\:ring-offset-light-green-700:focus-within {
    --tw-ring-offset-color: #689F38
  }

  .sm\:focus-within\:ring-offset-light-green-800:focus-within {
    --tw-ring-offset-color: #558B2F
  }

  .sm\:focus-within\:ring-offset-light-green-900:focus-within {
    --tw-ring-offset-color: #33691E
  }

  .sm\:focus-within\:ring-offset-light-green:focus-within {
    --tw-ring-offset-color: #8BC34A
  }

  .sm\:focus-within\:ring-offset-light-green-A100:focus-within {
    --tw-ring-offset-color: #CCFF90
  }

  .sm\:focus-within\:ring-offset-light-green-A200:focus-within {
    --tw-ring-offset-color: #B2FF59
  }

  .sm\:focus-within\:ring-offset-light-green-A400:focus-within {
    --tw-ring-offset-color: #76FF03
  }

  .sm\:focus-within\:ring-offset-light-green-A700:focus-within {
    --tw-ring-offset-color: #64DD17
  }

  .sm\:focus-within\:ring-offset-teal-50:focus-within {
    --tw-ring-offset-color: #E0F2F1
  }

  .sm\:focus-within\:ring-offset-teal-100:focus-within {
    --tw-ring-offset-color: #B2DFDB
  }

  .sm\:focus-within\:ring-offset-teal-200:focus-within {
    --tw-ring-offset-color: #80CBC4
  }

  .sm\:focus-within\:ring-offset-teal-300:focus-within {
    --tw-ring-offset-color: #4DB6AC
  }

  .sm\:focus-within\:ring-offset-teal-400:focus-within {
    --tw-ring-offset-color: #26A69A
  }

  .sm\:focus-within\:ring-offset-teal-500:focus-within {
    --tw-ring-offset-color: #009688
  }

  .sm\:focus-within\:ring-offset-teal-600:focus-within {
    --tw-ring-offset-color: #00897B
  }

  .sm\:focus-within\:ring-offset-teal-700:focus-within {
    --tw-ring-offset-color: #00796B
  }

  .sm\:focus-within\:ring-offset-teal-800:focus-within {
    --tw-ring-offset-color: #00695C
  }

  .sm\:focus-within\:ring-offset-teal-900:focus-within {
    --tw-ring-offset-color: #004D40
  }

  .sm\:focus-within\:ring-offset-teal:focus-within {
    --tw-ring-offset-color: #009688
  }

  .sm\:focus-within\:ring-offset-teal-A100:focus-within {
    --tw-ring-offset-color: #A7FFEB
  }

  .sm\:focus-within\:ring-offset-teal-A200:focus-within {
    --tw-ring-offset-color: #64FFDA
  }

  .sm\:focus-within\:ring-offset-teal-A400:focus-within {
    --tw-ring-offset-color: #1DE9B6
  }

  .sm\:focus-within\:ring-offset-teal-A700:focus-within {
    --tw-ring-offset-color: #00BFA5
  }

  .sm\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #E3F2FD
  }

  .sm\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #BBDEFB
  }

  .sm\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #90CAF9
  }

  .sm\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #64B5F6
  }

  .sm\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #42A5F5
  }

  .sm\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #2196F3
  }

  .sm\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #1E88E5
  }

  .sm\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1976D2
  }

  .sm\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1565C0
  }

  .sm\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #0D47A1
  }

  .sm\:focus-within\:ring-offset-blue:focus-within {
    --tw-ring-offset-color: #2196F3
  }

  .sm\:focus-within\:ring-offset-blue-A100:focus-within {
    --tw-ring-offset-color: #82B1FF
  }

  .sm\:focus-within\:ring-offset-blue-A200:focus-within {
    --tw-ring-offset-color: #448AFF
  }

  .sm\:focus-within\:ring-offset-blue-A400:focus-within {
    --tw-ring-offset-color: #2979FF
  }

  .sm\:focus-within\:ring-offset-blue-A700:focus-within {
    --tw-ring-offset-color: #2962FF
  }

  .sm\:focus-within\:ring-offset-light-blue-50:focus-within {
    --tw-ring-offset-color: #E1F5FE
  }

  .sm\:focus-within\:ring-offset-light-blue-100:focus-within {
    --tw-ring-offset-color: #B3E5FC
  }

  .sm\:focus-within\:ring-offset-light-blue-200:focus-within {
    --tw-ring-offset-color: #81D4FA
  }

  .sm\:focus-within\:ring-offset-light-blue-300:focus-within {
    --tw-ring-offset-color: #4FC3F7
  }

  .sm\:focus-within\:ring-offset-light-blue-400:focus-within {
    --tw-ring-offset-color: #29B6F6
  }

  .sm\:focus-within\:ring-offset-light-blue-500:focus-within {
    --tw-ring-offset-color: #03A9F4
  }

  .sm\:focus-within\:ring-offset-light-blue-600:focus-within {
    --tw-ring-offset-color: #039BE5
  }

  .sm\:focus-within\:ring-offset-light-blue-700:focus-within {
    --tw-ring-offset-color: #0288D1
  }

  .sm\:focus-within\:ring-offset-light-blue-800:focus-within {
    --tw-ring-offset-color: #0277BD
  }

  .sm\:focus-within\:ring-offset-light-blue-900:focus-within {
    --tw-ring-offset-color: #01579B
  }

  .sm\:focus-within\:ring-offset-light-blue:focus-within {
    --tw-ring-offset-color: #03A9F4
  }

  .sm\:focus-within\:ring-offset-light-blue-A100:focus-within {
    --tw-ring-offset-color: #80D8FF
  }

  .sm\:focus-within\:ring-offset-light-blue-A200:focus-within {
    --tw-ring-offset-color: #40C4FF
  }

  .sm\:focus-within\:ring-offset-light-blue-A400:focus-within {
    --tw-ring-offset-color: #00B0FF
  }

  .sm\:focus-within\:ring-offset-light-blue-A700:focus-within {
    --tw-ring-offset-color: #0091EA
  }

  .sm\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #E8EAF6
  }

  .sm\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #C5CAE9
  }

  .sm\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #9FA8DA
  }

  .sm\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #7986CB
  }

  .sm\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #5C6BC0
  }

  .sm\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #3F51B5
  }

  .sm\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #3949AB
  }

  .sm\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #303F9F
  }

  .sm\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #283593
  }

  .sm\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #1A237E
  }

  .sm\:focus-within\:ring-offset-indigo:focus-within {
    --tw-ring-offset-color: #3F51B5
  }

  .sm\:focus-within\:ring-offset-indigo-A100:focus-within {
    --tw-ring-offset-color: #8C9EFF
  }

  .sm\:focus-within\:ring-offset-indigo-A200:focus-within {
    --tw-ring-offset-color: #536DFE
  }

  .sm\:focus-within\:ring-offset-indigo-A400:focus-within {
    --tw-ring-offset-color: #3D5AFE
  }

  .sm\:focus-within\:ring-offset-indigo-A700:focus-within {
    --tw-ring-offset-color: #304FFE
  }

  .sm\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #F3E5F5
  }

  .sm\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #E1BEE7
  }

  .sm\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #CE93D8
  }

  .sm\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #BA68C8
  }

  .sm\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #AB47BC
  }

  .sm\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #9C27B0
  }

  .sm\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #8E24AA
  }

  .sm\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #7B1FA2
  }

  .sm\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #6A1B9A
  }

  .sm\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4A148C
  }

  .sm\:focus-within\:ring-offset-purple:focus-within {
    --tw-ring-offset-color: #9C27B0
  }

  .sm\:focus-within\:ring-offset-purple-A100:focus-within {
    --tw-ring-offset-color: #EA80FC
  }

  .sm\:focus-within\:ring-offset-purple-A200:focus-within {
    --tw-ring-offset-color: #E040FB
  }

  .sm\:focus-within\:ring-offset-purple-A400:focus-within {
    --tw-ring-offset-color: #D500F9
  }

  .sm\:focus-within\:ring-offset-purple-A700:focus-within {
    --tw-ring-offset-color: #AA00FF
  }

  .sm\:focus-within\:ring-offset-deep-purple-50:focus-within {
    --tw-ring-offset-color: #EDE7F6
  }

  .sm\:focus-within\:ring-offset-deep-purple-100:focus-within {
    --tw-ring-offset-color: #D1C4E9
  }

  .sm\:focus-within\:ring-offset-deep-purple-200:focus-within {
    --tw-ring-offset-color: #B39DDB
  }

  .sm\:focus-within\:ring-offset-deep-purple-300:focus-within {
    --tw-ring-offset-color: #9575CD
  }

  .sm\:focus-within\:ring-offset-deep-purple-400:focus-within {
    --tw-ring-offset-color: #7E57C2
  }

  .sm\:focus-within\:ring-offset-deep-purple-500:focus-within {
    --tw-ring-offset-color: #673AB7
  }

  .sm\:focus-within\:ring-offset-deep-purple-600:focus-within {
    --tw-ring-offset-color: #5E35B1
  }

  .sm\:focus-within\:ring-offset-deep-purple-700:focus-within {
    --tw-ring-offset-color: #512DA8
  }

  .sm\:focus-within\:ring-offset-deep-purple-800:focus-within {
    --tw-ring-offset-color: #4527A0
  }

  .sm\:focus-within\:ring-offset-deep-purple-900:focus-within {
    --tw-ring-offset-color: #311B92
  }

  .sm\:focus-within\:ring-offset-deep-purple:focus-within {
    --tw-ring-offset-color: #673AB7
  }

  .sm\:focus-within\:ring-offset-deep-purple-A100:focus-within {
    --tw-ring-offset-color: #B388FF
  }

  .sm\:focus-within\:ring-offset-deep-purple-A200:focus-within {
    --tw-ring-offset-color: #7C4DFF
  }

  .sm\:focus-within\:ring-offset-deep-purple-A400:focus-within {
    --tw-ring-offset-color: #651FFF
  }

  .sm\:focus-within\:ring-offset-deep-purple-A700:focus-within {
    --tw-ring-offset-color: #6200EA
  }

  .sm\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #FCE4EC
  }

  .sm\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #F8BBD0
  }

  .sm\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #F48FB1
  }

  .sm\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #F06292
  }

  .sm\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #EC407A
  }

  .sm\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #E91E63
  }

  .sm\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #D81B60
  }

  .sm\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #C2185B
  }

  .sm\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #AD1457
  }

  .sm\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #880E4F
  }

  .sm\:focus-within\:ring-offset-pink:focus-within {
    --tw-ring-offset-color: #E91E63
  }

  .sm\:focus-within\:ring-offset-pink-A100:focus-within {
    --tw-ring-offset-color: #FF80AB
  }

  .sm\:focus-within\:ring-offset-pink-A200:focus-within {
    --tw-ring-offset-color: #FF4081
  }

  .sm\:focus-within\:ring-offset-pink-A400:focus-within {
    --tw-ring-offset-color: #F50057
  }

  .sm\:focus-within\:ring-offset-pink-A700:focus-within {
    --tw-ring-offset-color: #C51162
  }

  .sm\:focus-within\:ring-offset-lime-50:focus-within {
    --tw-ring-offset-color: #F9FBE7
  }

  .sm\:focus-within\:ring-offset-lime-100:focus-within {
    --tw-ring-offset-color: #F0F4C3
  }

  .sm\:focus-within\:ring-offset-lime-200:focus-within {
    --tw-ring-offset-color: #E6EE9C
  }

  .sm\:focus-within\:ring-offset-lime-300:focus-within {
    --tw-ring-offset-color: #DCE775
  }

  .sm\:focus-within\:ring-offset-lime-400:focus-within {
    --tw-ring-offset-color: #D4E157
  }

  .sm\:focus-within\:ring-offset-lime-500:focus-within {
    --tw-ring-offset-color: #CDDC39
  }

  .sm\:focus-within\:ring-offset-lime-600:focus-within {
    --tw-ring-offset-color: #C0CA33
  }

  .sm\:focus-within\:ring-offset-lime-700:focus-within {
    --tw-ring-offset-color: #AFB42B
  }

  .sm\:focus-within\:ring-offset-lime-800:focus-within {
    --tw-ring-offset-color: #9E9D24
  }

  .sm\:focus-within\:ring-offset-lime-900:focus-within {
    --tw-ring-offset-color: #827717
  }

  .sm\:focus-within\:ring-offset-lime:focus-within {
    --tw-ring-offset-color: #CDDC39
  }

  .sm\:focus-within\:ring-offset-lime-A100:focus-within {
    --tw-ring-offset-color: #F4FF81
  }

  .sm\:focus-within\:ring-offset-lime-A200:focus-within {
    --tw-ring-offset-color: #EEFF41
  }

  .sm\:focus-within\:ring-offset-lime-A400:focus-within {
    --tw-ring-offset-color: #C6FF00
  }

  .sm\:focus-within\:ring-offset-lime-A700:focus-within {
    --tw-ring-offset-color: #AEEA00
  }

  .sm\:focus-within\:ring-offset-amber-50:focus-within {
    --tw-ring-offset-color: #FFF8E1
  }

  .sm\:focus-within\:ring-offset-amber-100:focus-within {
    --tw-ring-offset-color: #FFECB3
  }

  .sm\:focus-within\:ring-offset-amber-200:focus-within {
    --tw-ring-offset-color: #FFE082
  }

  .sm\:focus-within\:ring-offset-amber-300:focus-within {
    --tw-ring-offset-color: #FFD54F
  }

  .sm\:focus-within\:ring-offset-amber-400:focus-within {
    --tw-ring-offset-color: #FFCA28
  }

  .sm\:focus-within\:ring-offset-amber-500:focus-within {
    --tw-ring-offset-color: #FFC107
  }

  .sm\:focus-within\:ring-offset-amber-600:focus-within {
    --tw-ring-offset-color: #FFB300
  }

  .sm\:focus-within\:ring-offset-amber-700:focus-within {
    --tw-ring-offset-color: #FFA000
  }

  .sm\:focus-within\:ring-offset-amber-800:focus-within {
    --tw-ring-offset-color: #FF8F00
  }

  .sm\:focus-within\:ring-offset-amber-900:focus-within {
    --tw-ring-offset-color: #FF6F00
  }

  .sm\:focus-within\:ring-offset-amber:focus-within {
    --tw-ring-offset-color: #FFC107
  }

  .sm\:focus-within\:ring-offset-amber-A100:focus-within {
    --tw-ring-offset-color: #FFE57F
  }

  .sm\:focus-within\:ring-offset-amber-A200:focus-within {
    --tw-ring-offset-color: #FFD740
  }

  .sm\:focus-within\:ring-offset-amber-A400:focus-within {
    --tw-ring-offset-color: #FFC400
  }

  .sm\:focus-within\:ring-offset-amber-A700:focus-within {
    --tw-ring-offset-color: #FFAB00
  }

  .sm\:focus-within\:ring-offset-brown-50:focus-within {
    --tw-ring-offset-color: #EFEBE9
  }

  .sm\:focus-within\:ring-offset-brown-100:focus-within {
    --tw-ring-offset-color: #D7CCC8
  }

  .sm\:focus-within\:ring-offset-brown-200:focus-within {
    --tw-ring-offset-color: #BCAAA4
  }

  .sm\:focus-within\:ring-offset-brown-300:focus-within {
    --tw-ring-offset-color: #A1887F
  }

  .sm\:focus-within\:ring-offset-brown-400:focus-within {
    --tw-ring-offset-color: #8D6E63
  }

  .sm\:focus-within\:ring-offset-brown-500:focus-within {
    --tw-ring-offset-color: #795548
  }

  .sm\:focus-within\:ring-offset-brown-600:focus-within {
    --tw-ring-offset-color: #6D4C41
  }

  .sm\:focus-within\:ring-offset-brown-700:focus-within {
    --tw-ring-offset-color: #5D4037
  }

  .sm\:focus-within\:ring-offset-brown-800:focus-within {
    --tw-ring-offset-color: #4E342E
  }

  .sm\:focus-within\:ring-offset-brown-900:focus-within {
    --tw-ring-offset-color: #3E2723
  }

  .sm\:focus-within\:ring-offset-brown:focus-within {
    --tw-ring-offset-color: #795548
  }

  .sm\:focus-within\:ring-offset-brown-A100:focus-within {
    --tw-ring-offset-color: #D7CCC8
  }

  .sm\:focus-within\:ring-offset-brown-A200:focus-within {
    --tw-ring-offset-color: #BCAAA4
  }

  .sm\:focus-within\:ring-offset-brown-A400:focus-within {
    --tw-ring-offset-color: #8D6E63
  }

  .sm\:focus-within\:ring-offset-brown-A700:focus-within {
    --tw-ring-offset-color: #5D4037
  }

  .sm\:focus-within\:ring-offset-blue-gray-50:focus-within {
    --tw-ring-offset-color: #ECEFF1
  }

  .sm\:focus-within\:ring-offset-blue-gray-100:focus-within {
    --tw-ring-offset-color: #CFD8DC
  }

  .sm\:focus-within\:ring-offset-blue-gray-200:focus-within {
    --tw-ring-offset-color: #B0BEC5
  }

  .sm\:focus-within\:ring-offset-blue-gray-300:focus-within {
    --tw-ring-offset-color: #90A4AE
  }

  .sm\:focus-within\:ring-offset-blue-gray-400:focus-within {
    --tw-ring-offset-color: #78909C
  }

  .sm\:focus-within\:ring-offset-blue-gray-500:focus-within {
    --tw-ring-offset-color: #607D8B
  }

  .sm\:focus-within\:ring-offset-blue-gray-600:focus-within {
    --tw-ring-offset-color: #546E7A
  }

  .sm\:focus-within\:ring-offset-blue-gray-700:focus-within {
    --tw-ring-offset-color: #455A64
  }

  .sm\:focus-within\:ring-offset-blue-gray-800:focus-within {
    --tw-ring-offset-color: #37474F
  }

  .sm\:focus-within\:ring-offset-blue-gray-900:focus-within {
    --tw-ring-offset-color: #263238
  }

  .sm\:focus-within\:ring-offset-blue-gray:focus-within {
    --tw-ring-offset-color: #607D8B
  }

  .sm\:focus-within\:ring-offset-blue-gray-A100:focus-within {
    --tw-ring-offset-color: #CFD8DC
  }

  .sm\:focus-within\:ring-offset-blue-gray-A200:focus-within {
    --tw-ring-offset-color: #B0BEC5
  }

  .sm\:focus-within\:ring-offset-blue-gray-A400:focus-within {
    --tw-ring-offset-color: #78909C
  }

  .sm\:focus-within\:ring-offset-blue-gray-A700:focus-within {
    --tw-ring-offset-color: #455A64
  }

  .sm\:focus-within\:ring-offset-cyan-50:focus-within {
    --tw-ring-offset-color: #E0F7FA
  }

  .sm\:focus-within\:ring-offset-cyan-100:focus-within {
    --tw-ring-offset-color: #B2EBF2
  }

  .sm\:focus-within\:ring-offset-cyan-200:focus-within {
    --tw-ring-offset-color: #80DEEA
  }

  .sm\:focus-within\:ring-offset-cyan-300:focus-within {
    --tw-ring-offset-color: #4DD0E1
  }

  .sm\:focus-within\:ring-offset-cyan-400:focus-within {
    --tw-ring-offset-color: #26C6DA
  }

  .sm\:focus-within\:ring-offset-cyan-500:focus-within {
    --tw-ring-offset-color: #00BCD4
  }

  .sm\:focus-within\:ring-offset-cyan-600:focus-within {
    --tw-ring-offset-color: #00ACC1
  }

  .sm\:focus-within\:ring-offset-cyan-700:focus-within {
    --tw-ring-offset-color: #0097A7
  }

  .sm\:focus-within\:ring-offset-cyan-800:focus-within {
    --tw-ring-offset-color: #00838F
  }

  .sm\:focus-within\:ring-offset-cyan-900:focus-within {
    --tw-ring-offset-color: #006064
  }

  .sm\:focus-within\:ring-offset-cyan:focus-within {
    --tw-ring-offset-color: #00BCD4
  }

  .sm\:focus-within\:ring-offset-cyan-A100:focus-within {
    --tw-ring-offset-color: #84FFFF
  }

  .sm\:focus-within\:ring-offset-cyan-A200:focus-within {
    --tw-ring-offset-color: #18FFFF
  }

  .sm\:focus-within\:ring-offset-cyan-A400:focus-within {
    --tw-ring-offset-color: #00E5FF
  }

  .sm\:focus-within\:ring-offset-cyan-A700:focus-within {
    --tw-ring-offset-color: #00B8D4
  }

  .sm\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent
  }

  .sm\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor
  }

  .sm\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #22292F
  }

  .sm\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff
  }

  .sm\:focus\:ring-offset-grey-50:focus {
    --tw-ring-offset-color: #FAFAFA
  }

  .sm\:focus\:ring-offset-grey-100:focus {
    --tw-ring-offset-color: #F5F5F5
  }

  .sm\:focus\:ring-offset-grey-200:focus {
    --tw-ring-offset-color: #EEEEEE
  }

  .sm\:focus\:ring-offset-grey-300:focus {
    --tw-ring-offset-color: #E0E0E0
  }

  .sm\:focus\:ring-offset-grey-400:focus {
    --tw-ring-offset-color: #BDBDBD
  }

  .sm\:focus\:ring-offset-grey-500:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .sm\:focus\:ring-offset-grey-600:focus {
    --tw-ring-offset-color: #757575
  }

  .sm\:focus\:ring-offset-grey-700:focus {
    --tw-ring-offset-color: #616161
  }

  .sm\:focus\:ring-offset-grey-800:focus {
    --tw-ring-offset-color: #424242
  }

  .sm\:focus\:ring-offset-grey-900:focus {
    --tw-ring-offset-color: #212121
  }

  .sm\:focus\:ring-offset-grey:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .sm\:focus\:ring-offset-grey-A100:focus {
    --tw-ring-offset-color: #D5D5D5
  }

  .sm\:focus\:ring-offset-grey-A200:focus {
    --tw-ring-offset-color: #AAAAAA
  }

  .sm\:focus\:ring-offset-grey-A400:focus {
    --tw-ring-offset-color: #303030
  }

  .sm\:focus\:ring-offset-grey-A700:focus {
    --tw-ring-offset-color: #616161
  }

  .sm\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #FAFAFA
  }

  .sm\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #F5F5F5
  }

  .sm\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #EEEEEE
  }

  .sm\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #E0E0E0
  }

  .sm\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #BDBDBD
  }

  .sm\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .sm\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #757575
  }

  .sm\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #616161
  }

  .sm\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #424242
  }

  .sm\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #212121
  }

  .sm\:focus\:ring-offset-gray:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .sm\:focus\:ring-offset-gray-hover:focus {
    --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
  }

  .sm\:focus\:ring-offset-gray-A100:focus {
    --tw-ring-offset-color: #D5D5D5
  }

  .sm\:focus\:ring-offset-gray-A200:focus {
    --tw-ring-offset-color: #AAAAAA
  }

  .sm\:focus\:ring-offset-gray-A400:focus {
    --tw-ring-offset-color: #303030
  }

  .sm\:focus\:ring-offset-gray-A700:focus {
    --tw-ring-offset-color: #616161
  }

  .sm\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #FFEBEE
  }

  .sm\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #FFCDD2
  }

  .sm\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #EF9A9A
  }

  .sm\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #E57373
  }

  .sm\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #EF5350
  }

  .sm\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #F44336
  }

  .sm\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #E53935
  }

  .sm\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #D32F2F
  }

  .sm\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #C62828
  }

  .sm\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #B71C1C
  }

  .sm\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: #F44336
  }

  .sm\:focus\:ring-offset-red-A100:focus {
    --tw-ring-offset-color: #FF8A80
  }

  .sm\:focus\:ring-offset-red-A200:focus {
    --tw-ring-offset-color: #FF5252
  }

  .sm\:focus\:ring-offset-red-A400:focus {
    --tw-ring-offset-color: #FF1744
  }

  .sm\:focus\:ring-offset-red-A700:focus {
    --tw-ring-offset-color: #D50000
  }

  .sm\:focus\:ring-offset-orange-50:focus {
    --tw-ring-offset-color: #FFF3E0
  }

  .sm\:focus\:ring-offset-orange-100:focus {
    --tw-ring-offset-color: #FFE0B2
  }

  .sm\:focus\:ring-offset-orange-200:focus {
    --tw-ring-offset-color: #FFCC80
  }

  .sm\:focus\:ring-offset-orange-300:focus {
    --tw-ring-offset-color: #FFB74D
  }

  .sm\:focus\:ring-offset-orange-400:focus {
    --tw-ring-offset-color: #FFA726
  }

  .sm\:focus\:ring-offset-orange-500:focus {
    --tw-ring-offset-color: #FF9800
  }

  .sm\:focus\:ring-offset-orange-600:focus {
    --tw-ring-offset-color: #FB8C00
  }

  .sm\:focus\:ring-offset-orange-700:focus {
    --tw-ring-offset-color: #F57C00
  }

  .sm\:focus\:ring-offset-orange-800:focus {
    --tw-ring-offset-color: #EF6C00
  }

  .sm\:focus\:ring-offset-orange-900:focus {
    --tw-ring-offset-color: #E65100
  }

  .sm\:focus\:ring-offset-orange:focus {
    --tw-ring-offset-color: #FF9800
  }

  .sm\:focus\:ring-offset-orange-A100:focus {
    --tw-ring-offset-color: #FFD180
  }

  .sm\:focus\:ring-offset-orange-A200:focus {
    --tw-ring-offset-color: #FFAB40
  }

  .sm\:focus\:ring-offset-orange-A400:focus {
    --tw-ring-offset-color: #FF9100
  }

  .sm\:focus\:ring-offset-orange-A700:focus {
    --tw-ring-offset-color: #FF6D00
  }

  .sm\:focus\:ring-offset-deep-orange-50:focus {
    --tw-ring-offset-color: #FBE9E7
  }

  .sm\:focus\:ring-offset-deep-orange-100:focus {
    --tw-ring-offset-color: #FFCCBC
  }

  .sm\:focus\:ring-offset-deep-orange-200:focus {
    --tw-ring-offset-color: #FFAB91
  }

  .sm\:focus\:ring-offset-deep-orange-300:focus {
    --tw-ring-offset-color: #FF8A65
  }

  .sm\:focus\:ring-offset-deep-orange-400:focus {
    --tw-ring-offset-color: #FF7043
  }

  .sm\:focus\:ring-offset-deep-orange-500:focus {
    --tw-ring-offset-color: #FF5722
  }

  .sm\:focus\:ring-offset-deep-orange-600:focus {
    --tw-ring-offset-color: #F4511E
  }

  .sm\:focus\:ring-offset-deep-orange-700:focus {
    --tw-ring-offset-color: #E64A19
  }

  .sm\:focus\:ring-offset-deep-orange-800:focus {
    --tw-ring-offset-color: #D84315
  }

  .sm\:focus\:ring-offset-deep-orange-900:focus {
    --tw-ring-offset-color: #BF360C
  }

  .sm\:focus\:ring-offset-deep-orange:focus {
    --tw-ring-offset-color: #FF5722
  }

  .sm\:focus\:ring-offset-deep-orange-A100:focus {
    --tw-ring-offset-color: #FF9E80
  }

  .sm\:focus\:ring-offset-deep-orange-A200:focus {
    --tw-ring-offset-color: #FF6E40
  }

  .sm\:focus\:ring-offset-deep-orange-A400:focus {
    --tw-ring-offset-color: #FF3D00
  }

  .sm\:focus\:ring-offset-deep-orange-A700:focus {
    --tw-ring-offset-color: #DD2C00
  }

  .sm\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #FFFDE7
  }

  .sm\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #FFF9C4
  }

  .sm\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #FFF59D
  }

  .sm\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #FFF176
  }

  .sm\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #FFEE58
  }

  .sm\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #FFEB3B
  }

  .sm\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #FDD835
  }

  .sm\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #FBC02D
  }

  .sm\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #F9A825
  }

  .sm\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #F57F17
  }

  .sm\:focus\:ring-offset-yellow:focus {
    --tw-ring-offset-color: #FFEB3B
  }

  .sm\:focus\:ring-offset-yellow-A100:focus {
    --tw-ring-offset-color: #FFFF8D
  }

  .sm\:focus\:ring-offset-yellow-A200:focus {
    --tw-ring-offset-color: #FFFF00
  }

  .sm\:focus\:ring-offset-yellow-A400:focus {
    --tw-ring-offset-color: #FFEA00
  }

  .sm\:focus\:ring-offset-yellow-A700:focus {
    --tw-ring-offset-color: #FFD600
  }

  .sm\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #E8F5E9
  }

  .sm\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #C8E6C9
  }

  .sm\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #A5D6A7
  }

  .sm\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #81C784
  }

  .sm\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #66BB6A
  }

  .sm\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #4CAF50
  }

  .sm\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #43A047
  }

  .sm\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #388E3C
  }

  .sm\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #2E7D32
  }

  .sm\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #1B5E20
  }

  .sm\:focus\:ring-offset-green:focus {
    --tw-ring-offset-color: #4CAF50
  }

  .sm\:focus\:ring-offset-green-A100:focus {
    --tw-ring-offset-color: #B9F6CA
  }

  .sm\:focus\:ring-offset-green-A200:focus {
    --tw-ring-offset-color: #69F0AE
  }

  .sm\:focus\:ring-offset-green-A400:focus {
    --tw-ring-offset-color: #00E676
  }

  .sm\:focus\:ring-offset-green-A700:focus {
    --tw-ring-offset-color: #00C853
  }

  .sm\:focus\:ring-offset-light-green-50:focus {
    --tw-ring-offset-color: #F1F8E9
  }

  .sm\:focus\:ring-offset-light-green-100:focus {
    --tw-ring-offset-color: #DCEDC8
  }

  .sm\:focus\:ring-offset-light-green-200:focus {
    --tw-ring-offset-color: #C5E1A5
  }

  .sm\:focus\:ring-offset-light-green-300:focus {
    --tw-ring-offset-color: #AED581
  }

  .sm\:focus\:ring-offset-light-green-400:focus {
    --tw-ring-offset-color: #9CCC65
  }

  .sm\:focus\:ring-offset-light-green-500:focus {
    --tw-ring-offset-color: #8BC34A
  }

  .sm\:focus\:ring-offset-light-green-600:focus {
    --tw-ring-offset-color: #7CB342
  }

  .sm\:focus\:ring-offset-light-green-700:focus {
    --tw-ring-offset-color: #689F38
  }

  .sm\:focus\:ring-offset-light-green-800:focus {
    --tw-ring-offset-color: #558B2F
  }

  .sm\:focus\:ring-offset-light-green-900:focus {
    --tw-ring-offset-color: #33691E
  }

  .sm\:focus\:ring-offset-light-green:focus {
    --tw-ring-offset-color: #8BC34A
  }

  .sm\:focus\:ring-offset-light-green-A100:focus {
    --tw-ring-offset-color: #CCFF90
  }

  .sm\:focus\:ring-offset-light-green-A200:focus {
    --tw-ring-offset-color: #B2FF59
  }

  .sm\:focus\:ring-offset-light-green-A400:focus {
    --tw-ring-offset-color: #76FF03
  }

  .sm\:focus\:ring-offset-light-green-A700:focus {
    --tw-ring-offset-color: #64DD17
  }

  .sm\:focus\:ring-offset-teal-50:focus {
    --tw-ring-offset-color: #E0F2F1
  }

  .sm\:focus\:ring-offset-teal-100:focus {
    --tw-ring-offset-color: #B2DFDB
  }

  .sm\:focus\:ring-offset-teal-200:focus {
    --tw-ring-offset-color: #80CBC4
  }

  .sm\:focus\:ring-offset-teal-300:focus {
    --tw-ring-offset-color: #4DB6AC
  }

  .sm\:focus\:ring-offset-teal-400:focus {
    --tw-ring-offset-color: #26A69A
  }

  .sm\:focus\:ring-offset-teal-500:focus {
    --tw-ring-offset-color: #009688
  }

  .sm\:focus\:ring-offset-teal-600:focus {
    --tw-ring-offset-color: #00897B
  }

  .sm\:focus\:ring-offset-teal-700:focus {
    --tw-ring-offset-color: #00796B
  }

  .sm\:focus\:ring-offset-teal-800:focus {
    --tw-ring-offset-color: #00695C
  }

  .sm\:focus\:ring-offset-teal-900:focus {
    --tw-ring-offset-color: #004D40
  }

  .sm\:focus\:ring-offset-teal:focus {
    --tw-ring-offset-color: #009688
  }

  .sm\:focus\:ring-offset-teal-A100:focus {
    --tw-ring-offset-color: #A7FFEB
  }

  .sm\:focus\:ring-offset-teal-A200:focus {
    --tw-ring-offset-color: #64FFDA
  }

  .sm\:focus\:ring-offset-teal-A400:focus {
    --tw-ring-offset-color: #1DE9B6
  }

  .sm\:focus\:ring-offset-teal-A700:focus {
    --tw-ring-offset-color: #00BFA5
  }

  .sm\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #E3F2FD
  }

  .sm\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #BBDEFB
  }

  .sm\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #90CAF9
  }

  .sm\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #64B5F6
  }

  .sm\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #42A5F5
  }

  .sm\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #2196F3
  }

  .sm\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #1E88E5
  }

  .sm\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1976D2
  }

  .sm\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1565C0
  }

  .sm\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #0D47A1
  }

  .sm\:focus\:ring-offset-blue:focus {
    --tw-ring-offset-color: #2196F3
  }

  .sm\:focus\:ring-offset-blue-A100:focus {
    --tw-ring-offset-color: #82B1FF
  }

  .sm\:focus\:ring-offset-blue-A200:focus {
    --tw-ring-offset-color: #448AFF
  }

  .sm\:focus\:ring-offset-blue-A400:focus {
    --tw-ring-offset-color: #2979FF
  }

  .sm\:focus\:ring-offset-blue-A700:focus {
    --tw-ring-offset-color: #2962FF
  }

  .sm\:focus\:ring-offset-light-blue-50:focus {
    --tw-ring-offset-color: #E1F5FE
  }

  .sm\:focus\:ring-offset-light-blue-100:focus {
    --tw-ring-offset-color: #B3E5FC
  }

  .sm\:focus\:ring-offset-light-blue-200:focus {
    --tw-ring-offset-color: #81D4FA
  }

  .sm\:focus\:ring-offset-light-blue-300:focus {
    --tw-ring-offset-color: #4FC3F7
  }

  .sm\:focus\:ring-offset-light-blue-400:focus {
    --tw-ring-offset-color: #29B6F6
  }

  .sm\:focus\:ring-offset-light-blue-500:focus {
    --tw-ring-offset-color: #03A9F4
  }

  .sm\:focus\:ring-offset-light-blue-600:focus {
    --tw-ring-offset-color: #039BE5
  }

  .sm\:focus\:ring-offset-light-blue-700:focus {
    --tw-ring-offset-color: #0288D1
  }

  .sm\:focus\:ring-offset-light-blue-800:focus {
    --tw-ring-offset-color: #0277BD
  }

  .sm\:focus\:ring-offset-light-blue-900:focus {
    --tw-ring-offset-color: #01579B
  }

  .sm\:focus\:ring-offset-light-blue:focus {
    --tw-ring-offset-color: #03A9F4
  }

  .sm\:focus\:ring-offset-light-blue-A100:focus {
    --tw-ring-offset-color: #80D8FF
  }

  .sm\:focus\:ring-offset-light-blue-A200:focus {
    --tw-ring-offset-color: #40C4FF
  }

  .sm\:focus\:ring-offset-light-blue-A400:focus {
    --tw-ring-offset-color: #00B0FF
  }

  .sm\:focus\:ring-offset-light-blue-A700:focus {
    --tw-ring-offset-color: #0091EA
  }

  .sm\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #E8EAF6
  }

  .sm\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #C5CAE9
  }

  .sm\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #9FA8DA
  }

  .sm\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #7986CB
  }

  .sm\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #5C6BC0
  }

  .sm\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #3F51B5
  }

  .sm\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #3949AB
  }

  .sm\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #303F9F
  }

  .sm\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #283593
  }

  .sm\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #1A237E
  }

  .sm\:focus\:ring-offset-indigo:focus {
    --tw-ring-offset-color: #3F51B5
  }

  .sm\:focus\:ring-offset-indigo-A100:focus {
    --tw-ring-offset-color: #8C9EFF
  }

  .sm\:focus\:ring-offset-indigo-A200:focus {
    --tw-ring-offset-color: #536DFE
  }

  .sm\:focus\:ring-offset-indigo-A400:focus {
    --tw-ring-offset-color: #3D5AFE
  }

  .sm\:focus\:ring-offset-indigo-A700:focus {
    --tw-ring-offset-color: #304FFE
  }

  .sm\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #F3E5F5
  }

  .sm\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #E1BEE7
  }

  .sm\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #CE93D8
  }

  .sm\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #BA68C8
  }

  .sm\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #AB47BC
  }

  .sm\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #9C27B0
  }

  .sm\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #8E24AA
  }

  .sm\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #7B1FA2
  }

  .sm\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #6A1B9A
  }

  .sm\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4A148C
  }

  .sm\:focus\:ring-offset-purple:focus {
    --tw-ring-offset-color: #9C27B0
  }

  .sm\:focus\:ring-offset-purple-A100:focus {
    --tw-ring-offset-color: #EA80FC
  }

  .sm\:focus\:ring-offset-purple-A200:focus {
    --tw-ring-offset-color: #E040FB
  }

  .sm\:focus\:ring-offset-purple-A400:focus {
    --tw-ring-offset-color: #D500F9
  }

  .sm\:focus\:ring-offset-purple-A700:focus {
    --tw-ring-offset-color: #AA00FF
  }

  .sm\:focus\:ring-offset-deep-purple-50:focus {
    --tw-ring-offset-color: #EDE7F6
  }

  .sm\:focus\:ring-offset-deep-purple-100:focus {
    --tw-ring-offset-color: #D1C4E9
  }

  .sm\:focus\:ring-offset-deep-purple-200:focus {
    --tw-ring-offset-color: #B39DDB
  }

  .sm\:focus\:ring-offset-deep-purple-300:focus {
    --tw-ring-offset-color: #9575CD
  }

  .sm\:focus\:ring-offset-deep-purple-400:focus {
    --tw-ring-offset-color: #7E57C2
  }

  .sm\:focus\:ring-offset-deep-purple-500:focus {
    --tw-ring-offset-color: #673AB7
  }

  .sm\:focus\:ring-offset-deep-purple-600:focus {
    --tw-ring-offset-color: #5E35B1
  }

  .sm\:focus\:ring-offset-deep-purple-700:focus {
    --tw-ring-offset-color: #512DA8
  }

  .sm\:focus\:ring-offset-deep-purple-800:focus {
    --tw-ring-offset-color: #4527A0
  }

  .sm\:focus\:ring-offset-deep-purple-900:focus {
    --tw-ring-offset-color: #311B92
  }

  .sm\:focus\:ring-offset-deep-purple:focus {
    --tw-ring-offset-color: #673AB7
  }

  .sm\:focus\:ring-offset-deep-purple-A100:focus {
    --tw-ring-offset-color: #B388FF
  }

  .sm\:focus\:ring-offset-deep-purple-A200:focus {
    --tw-ring-offset-color: #7C4DFF
  }

  .sm\:focus\:ring-offset-deep-purple-A400:focus {
    --tw-ring-offset-color: #651FFF
  }

  .sm\:focus\:ring-offset-deep-purple-A700:focus {
    --tw-ring-offset-color: #6200EA
  }

  .sm\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #FCE4EC
  }

  .sm\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #F8BBD0
  }

  .sm\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #F48FB1
  }

  .sm\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #F06292
  }

  .sm\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #EC407A
  }

  .sm\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #E91E63
  }

  .sm\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #D81B60
  }

  .sm\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #C2185B
  }

  .sm\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #AD1457
  }

  .sm\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #880E4F
  }

  .sm\:focus\:ring-offset-pink:focus {
    --tw-ring-offset-color: #E91E63
  }

  .sm\:focus\:ring-offset-pink-A100:focus {
    --tw-ring-offset-color: #FF80AB
  }

  .sm\:focus\:ring-offset-pink-A200:focus {
    --tw-ring-offset-color: #FF4081
  }

  .sm\:focus\:ring-offset-pink-A400:focus {
    --tw-ring-offset-color: #F50057
  }

  .sm\:focus\:ring-offset-pink-A700:focus {
    --tw-ring-offset-color: #C51162
  }

  .sm\:focus\:ring-offset-lime-50:focus {
    --tw-ring-offset-color: #F9FBE7
  }

  .sm\:focus\:ring-offset-lime-100:focus {
    --tw-ring-offset-color: #F0F4C3
  }

  .sm\:focus\:ring-offset-lime-200:focus {
    --tw-ring-offset-color: #E6EE9C
  }

  .sm\:focus\:ring-offset-lime-300:focus {
    --tw-ring-offset-color: #DCE775
  }

  .sm\:focus\:ring-offset-lime-400:focus {
    --tw-ring-offset-color: #D4E157
  }

  .sm\:focus\:ring-offset-lime-500:focus {
    --tw-ring-offset-color: #CDDC39
  }

  .sm\:focus\:ring-offset-lime-600:focus {
    --tw-ring-offset-color: #C0CA33
  }

  .sm\:focus\:ring-offset-lime-700:focus {
    --tw-ring-offset-color: #AFB42B
  }

  .sm\:focus\:ring-offset-lime-800:focus {
    --tw-ring-offset-color: #9E9D24
  }

  .sm\:focus\:ring-offset-lime-900:focus {
    --tw-ring-offset-color: #827717
  }

  .sm\:focus\:ring-offset-lime:focus {
    --tw-ring-offset-color: #CDDC39
  }

  .sm\:focus\:ring-offset-lime-A100:focus {
    --tw-ring-offset-color: #F4FF81
  }

  .sm\:focus\:ring-offset-lime-A200:focus {
    --tw-ring-offset-color: #EEFF41
  }

  .sm\:focus\:ring-offset-lime-A400:focus {
    --tw-ring-offset-color: #C6FF00
  }

  .sm\:focus\:ring-offset-lime-A700:focus {
    --tw-ring-offset-color: #AEEA00
  }

  .sm\:focus\:ring-offset-amber-50:focus {
    --tw-ring-offset-color: #FFF8E1
  }

  .sm\:focus\:ring-offset-amber-100:focus {
    --tw-ring-offset-color: #FFECB3
  }

  .sm\:focus\:ring-offset-amber-200:focus {
    --tw-ring-offset-color: #FFE082
  }

  .sm\:focus\:ring-offset-amber-300:focus {
    --tw-ring-offset-color: #FFD54F
  }

  .sm\:focus\:ring-offset-amber-400:focus {
    --tw-ring-offset-color: #FFCA28
  }

  .sm\:focus\:ring-offset-amber-500:focus {
    --tw-ring-offset-color: #FFC107
  }

  .sm\:focus\:ring-offset-amber-600:focus {
    --tw-ring-offset-color: #FFB300
  }

  .sm\:focus\:ring-offset-amber-700:focus {
    --tw-ring-offset-color: #FFA000
  }

  .sm\:focus\:ring-offset-amber-800:focus {
    --tw-ring-offset-color: #FF8F00
  }

  .sm\:focus\:ring-offset-amber-900:focus {
    --tw-ring-offset-color: #FF6F00
  }

  .sm\:focus\:ring-offset-amber:focus {
    --tw-ring-offset-color: #FFC107
  }

  .sm\:focus\:ring-offset-amber-A100:focus {
    --tw-ring-offset-color: #FFE57F
  }

  .sm\:focus\:ring-offset-amber-A200:focus {
    --tw-ring-offset-color: #FFD740
  }

  .sm\:focus\:ring-offset-amber-A400:focus {
    --tw-ring-offset-color: #FFC400
  }

  .sm\:focus\:ring-offset-amber-A700:focus {
    --tw-ring-offset-color: #FFAB00
  }

  .sm\:focus\:ring-offset-brown-50:focus {
    --tw-ring-offset-color: #EFEBE9
  }

  .sm\:focus\:ring-offset-brown-100:focus {
    --tw-ring-offset-color: #D7CCC8
  }

  .sm\:focus\:ring-offset-brown-200:focus {
    --tw-ring-offset-color: #BCAAA4
  }

  .sm\:focus\:ring-offset-brown-300:focus {
    --tw-ring-offset-color: #A1887F
  }

  .sm\:focus\:ring-offset-brown-400:focus {
    --tw-ring-offset-color: #8D6E63
  }

  .sm\:focus\:ring-offset-brown-500:focus {
    --tw-ring-offset-color: #795548
  }

  .sm\:focus\:ring-offset-brown-600:focus {
    --tw-ring-offset-color: #6D4C41
  }

  .sm\:focus\:ring-offset-brown-700:focus {
    --tw-ring-offset-color: #5D4037
  }

  .sm\:focus\:ring-offset-brown-800:focus {
    --tw-ring-offset-color: #4E342E
  }

  .sm\:focus\:ring-offset-brown-900:focus {
    --tw-ring-offset-color: #3E2723
  }

  .sm\:focus\:ring-offset-brown:focus {
    --tw-ring-offset-color: #795548
  }

  .sm\:focus\:ring-offset-brown-A100:focus {
    --tw-ring-offset-color: #D7CCC8
  }

  .sm\:focus\:ring-offset-brown-A200:focus {
    --tw-ring-offset-color: #BCAAA4
  }

  .sm\:focus\:ring-offset-brown-A400:focus {
    --tw-ring-offset-color: #8D6E63
  }

  .sm\:focus\:ring-offset-brown-A700:focus {
    --tw-ring-offset-color: #5D4037
  }

  .sm\:focus\:ring-offset-blue-gray-50:focus {
    --tw-ring-offset-color: #ECEFF1
  }

  .sm\:focus\:ring-offset-blue-gray-100:focus {
    --tw-ring-offset-color: #CFD8DC
  }

  .sm\:focus\:ring-offset-blue-gray-200:focus {
    --tw-ring-offset-color: #B0BEC5
  }

  .sm\:focus\:ring-offset-blue-gray-300:focus {
    --tw-ring-offset-color: #90A4AE
  }

  .sm\:focus\:ring-offset-blue-gray-400:focus {
    --tw-ring-offset-color: #78909C
  }

  .sm\:focus\:ring-offset-blue-gray-500:focus {
    --tw-ring-offset-color: #607D8B
  }

  .sm\:focus\:ring-offset-blue-gray-600:focus {
    --tw-ring-offset-color: #546E7A
  }

  .sm\:focus\:ring-offset-blue-gray-700:focus {
    --tw-ring-offset-color: #455A64
  }

  .sm\:focus\:ring-offset-blue-gray-800:focus {
    --tw-ring-offset-color: #37474F
  }

  .sm\:focus\:ring-offset-blue-gray-900:focus {
    --tw-ring-offset-color: #263238
  }

  .sm\:focus\:ring-offset-blue-gray:focus {
    --tw-ring-offset-color: #607D8B
  }

  .sm\:focus\:ring-offset-blue-gray-A100:focus {
    --tw-ring-offset-color: #CFD8DC
  }

  .sm\:focus\:ring-offset-blue-gray-A200:focus {
    --tw-ring-offset-color: #B0BEC5
  }

  .sm\:focus\:ring-offset-blue-gray-A400:focus {
    --tw-ring-offset-color: #78909C
  }

  .sm\:focus\:ring-offset-blue-gray-A700:focus {
    --tw-ring-offset-color: #455A64
  }

  .sm\:focus\:ring-offset-cyan-50:focus {
    --tw-ring-offset-color: #E0F7FA
  }

  .sm\:focus\:ring-offset-cyan-100:focus {
    --tw-ring-offset-color: #B2EBF2
  }

  .sm\:focus\:ring-offset-cyan-200:focus {
    --tw-ring-offset-color: #80DEEA
  }

  .sm\:focus\:ring-offset-cyan-300:focus {
    --tw-ring-offset-color: #4DD0E1
  }

  .sm\:focus\:ring-offset-cyan-400:focus {
    --tw-ring-offset-color: #26C6DA
  }

  .sm\:focus\:ring-offset-cyan-500:focus {
    --tw-ring-offset-color: #00BCD4
  }

  .sm\:focus\:ring-offset-cyan-600:focus {
    --tw-ring-offset-color: #00ACC1
  }

  .sm\:focus\:ring-offset-cyan-700:focus {
    --tw-ring-offset-color: #0097A7
  }

  .sm\:focus\:ring-offset-cyan-800:focus {
    --tw-ring-offset-color: #00838F
  }

  .sm\:focus\:ring-offset-cyan-900:focus {
    --tw-ring-offset-color: #006064
  }

  .sm\:focus\:ring-offset-cyan:focus {
    --tw-ring-offset-color: #00BCD4
  }

  .sm\:focus\:ring-offset-cyan-A100:focus {
    --tw-ring-offset-color: #84FFFF
  }

  .sm\:focus\:ring-offset-cyan-A200:focus {
    --tw-ring-offset-color: #18FFFF
  }

  .sm\:focus\:ring-offset-cyan-A400:focus {
    --tw-ring-offset-color: #00E5FF
  }

  .sm\:focus\:ring-offset-cyan-A700:focus {
    --tw-ring-offset-color: #00B8D4
  }

  .sm\:ring-offset-0 {
    --tw-ring-offset-width: 0px
  }

  .sm\:ring-offset-1 {
    --tw-ring-offset-width: 1px
  }

  .sm\:ring-offset-2 {
    --tw-ring-offset-width: 2px
  }

  .sm\:ring-offset-4 {
    --tw-ring-offset-width: 4px
  }

  .sm\:ring-offset-8 {
    --tw-ring-offset-width: 8px
  }

  .sm\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px
  }

  .sm\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px
  }

  .sm\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px
  }

  .sm\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px
  }

  .sm\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px
  }

  .sm\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px
  }

  .sm\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px
  }

  .sm\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px
  }

  .sm\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px
  }

  .sm\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px
  }

  .sm\:ring-transparent {
    --tw-ring-color: transparent
  }

  .sm\:ring-current {
    --tw-ring-color: currentColor
  }

  .sm\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .sm\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-grey-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .sm\:ring-grey-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .sm\:ring-grey-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .sm\:ring-grey-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .sm\:ring-grey-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .sm\:ring-grey-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .sm\:ring-grey-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .sm\:ring-grey-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .sm\:ring-grey-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .sm\:ring-grey-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .sm\:ring-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .sm\:ring-grey-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .sm\:ring-grey-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .sm\:ring-grey-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .sm\:ring-grey-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .sm\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .sm\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .sm\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .sm\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .sm\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .sm\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .sm\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .sm\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .sm\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .sm\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .sm\:ring-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .sm\:ring-gray-hover {
    --tw-ring-color: rgba(0, 0, 0, 0.04)
  }

  .sm\:ring-gray-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .sm\:ring-gray-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .sm\:ring-gray-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .sm\:ring-gray-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .sm\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .sm\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .sm\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .sm\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .sm\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .sm\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .sm\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .sm\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .sm\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .sm\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .sm\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .sm\:ring-red-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .sm\:ring-red-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .sm\:ring-red-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .sm\:ring-red-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-orange-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .sm\:ring-orange-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .sm\:ring-orange-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .sm\:ring-orange-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .sm\:ring-orange-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .sm\:ring-orange-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-orange-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-orange-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-orange-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-orange-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-orange {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-orange-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .sm\:ring-orange-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .sm\:ring-orange-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-orange-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-orange-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-orange-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-orange-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-orange-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-orange-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-orange-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-orange-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-orange-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-orange-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-orange-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-orange {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-orange-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-orange-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-orange-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-orange-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .sm\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .sm\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .sm\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .sm\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .sm\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .sm\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .sm\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .sm\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .sm\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .sm\:ring-yellow {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .sm\:ring-yellow-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .sm\:ring-yellow-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-yellow-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-yellow-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .sm\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .sm\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .sm\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .sm\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .sm\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .sm\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .sm\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .sm\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .sm\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .sm\:ring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .sm\:ring-green-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .sm\:ring-green-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .sm\:ring-green-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .sm\:ring-green-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .sm\:ring-light-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .sm\:ring-light-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .sm\:ring-light-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .sm\:ring-light-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .sm\:ring-light-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .sm\:ring-light-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .sm\:ring-light-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .sm\:ring-light-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .sm\:ring-light-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .sm\:ring-light-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .sm\:ring-light-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .sm\:ring-light-green-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .sm\:ring-light-green-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .sm\:ring-light-green-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .sm\:ring-light-green-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .sm\:ring-teal-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .sm\:ring-teal-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .sm\:ring-teal-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .sm\:ring-teal-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .sm\:ring-teal-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .sm\:ring-teal-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .sm\:ring-teal-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .sm\:ring-teal-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .sm\:ring-teal-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .sm\:ring-teal-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .sm\:ring-teal {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .sm\:ring-teal-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .sm\:ring-teal-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .sm\:ring-teal-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .sm\:ring-teal-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .sm\:ring-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-light-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .sm\:ring-light-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .sm\:ring-light-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .sm\:ring-light-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .sm\:ring-light-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .sm\:ring-light-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .sm\:ring-light-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .sm\:ring-light-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .sm\:ring-light-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .sm\:ring-light-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .sm\:ring-light-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .sm\:ring-light-blue-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-light-blue-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-light-blue-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-light-blue-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .sm\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .sm\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .sm\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .sm\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .sm\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .sm\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .sm\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .sm\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .sm\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .sm\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .sm\:ring-indigo {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .sm\:ring-indigo-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-indigo-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .sm\:ring-indigo-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .sm\:ring-indigo-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .sm\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .sm\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .sm\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .sm\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .sm\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .sm\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .sm\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .sm\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .sm\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .sm\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .sm\:ring-purple {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .sm\:ring-purple-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .sm\:ring-purple-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .sm\:ring-purple-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .sm\:ring-purple-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-purple {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-purple-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-purple-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-purple-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-deep-purple-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .sm\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .sm\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .sm\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .sm\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .sm\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .sm\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .sm\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .sm\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .sm\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .sm\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .sm\:ring-pink {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .sm\:ring-pink-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .sm\:ring-pink-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .sm\:ring-pink-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .sm\:ring-pink-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .sm\:ring-lime-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .sm\:ring-lime-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .sm\:ring-lime-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .sm\:ring-lime-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .sm\:ring-lime-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .sm\:ring-lime-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .sm\:ring-lime-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .sm\:ring-lime-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .sm\:ring-lime-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .sm\:ring-lime-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .sm\:ring-lime {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .sm\:ring-lime-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .sm\:ring-lime-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .sm\:ring-lime-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-lime-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-amber-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .sm\:ring-amber-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .sm\:ring-amber-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .sm\:ring-amber-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .sm\:ring-amber-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .sm\:ring-amber-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .sm\:ring-amber-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-amber-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-amber-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-amber-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-amber {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .sm\:ring-amber-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .sm\:ring-amber-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .sm\:ring-amber-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-amber-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-brown-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .sm\:ring-brown-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .sm\:ring-brown-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .sm\:ring-brown-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .sm\:ring-brown-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .sm\:ring-brown-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .sm\:ring-brown-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .sm\:ring-brown-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .sm\:ring-brown-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .sm\:ring-brown-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .sm\:ring-brown {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .sm\:ring-brown-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .sm\:ring-brown-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .sm\:ring-brown-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .sm\:ring-brown-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-gray-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-gray-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-gray-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .sm\:ring-blue-gray-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent
  }

  .sm\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor
  }

  .sm\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-grey-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-grey-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-grey-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-grey-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-grey-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-grey-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-grey-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-grey-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-grey-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-grey-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-grey-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-grey-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-grey-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-grey-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray-hover:focus-within {
    --tw-ring-color: rgba(0, 0, 0, 0.04)
  }

  .sm\:focus-within\:ring-gray-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-orange-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-orange-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-orange-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-orange-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-orange-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-orange-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-orange-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-orange-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-orange-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-orange-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-orange:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-orange-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-orange-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-orange-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-orange-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-orange-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-orange-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-orange-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-orange-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-orange-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-orange-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-orange-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-orange-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-orange-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-orange-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-orange:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-orange-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-orange-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-orange-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-orange-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-yellow:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-yellow-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-yellow-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-yellow-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-yellow-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-green-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-green-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-green-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-green-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-teal-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-teal-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-teal-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-teal-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-teal-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-teal-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-teal-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-teal-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-teal-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-teal-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-teal:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-teal-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-teal-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-teal-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-teal-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-blue-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-blue-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-blue-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-light-blue-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-indigo:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-indigo-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-indigo-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-indigo-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-indigo-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-purple:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-purple-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-purple-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-purple-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-purple-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-purple:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-purple-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-purple-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-purple-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-deep-purple-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-pink:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-pink-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-pink-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-pink-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-pink-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-lime-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-lime-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-lime-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-lime-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-lime-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-lime-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-lime-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-lime-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-lime-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-lime-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-lime:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-lime-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-lime-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-lime-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-lime-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-amber-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-amber-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-amber-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-amber-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-amber-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-amber-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-amber-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-amber-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-amber-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-amber-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-amber:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-amber-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-amber-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-amber-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-amber-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-brown-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-brown-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-brown-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-brown-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-brown-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-brown-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-brown-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-brown-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-brown-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-brown-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-brown:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-brown-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-brown-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-brown-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-brown-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-gray-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-gray-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-gray-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-blue-gray-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent
  }

  .sm\:focus\:ring-current:focus {
    --tw-ring-color: currentColor
  }

  .sm\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-grey-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-grey-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-grey-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-grey-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-grey-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-grey-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-grey-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-grey-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-grey-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-grey-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-grey-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-grey-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-grey-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-grey-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray-hover:focus {
    --tw-ring-color: rgba(0, 0, 0, 0.04)
  }

  .sm\:focus\:ring-gray-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-orange-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-orange-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-orange-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-orange-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-orange-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-orange-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-orange-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-orange-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-orange-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-orange-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-orange:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-orange-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-orange-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-orange-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-orange-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-orange-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-orange-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-orange-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-orange-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-orange-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-orange-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-orange-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-orange-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-orange-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-orange-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-orange:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-orange-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-orange-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-orange-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-orange-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-yellow:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-yellow-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-yellow-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-yellow-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-yellow-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-green-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-green-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-green-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-green-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-teal-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-teal-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-teal-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-teal-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-teal-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-teal-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-teal-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-teal-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-teal-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-teal-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-teal:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-teal-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-teal-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-teal-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-teal-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-blue-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-blue-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-blue-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-light-blue-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-indigo:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-indigo-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-indigo-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-indigo-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-indigo-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-purple:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-purple-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-purple-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-purple-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-purple-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-purple:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-purple-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-purple-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-purple-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-deep-purple-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-pink:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-pink-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-pink-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-pink-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-pink-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-lime-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-lime-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-lime-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-lime-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-lime-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-lime-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-lime-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-lime-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-lime-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-lime-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-lime:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-lime-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-lime-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-lime-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-lime-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-amber-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-amber-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-amber-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-amber-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-amber-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-amber-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-amber-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-amber-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-amber-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-amber-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-amber:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-amber-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-amber-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-amber-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-amber-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-brown-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-brown-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-brown-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-brown-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-brown-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-brown-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-brown-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-brown-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-brown-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-brown-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-brown:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-brown-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-brown-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-brown-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-brown-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-gray-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-gray-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-gray-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-blue-gray-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .sm\:ring-opacity-0 {
    --tw-ring-opacity: 0
  }

  .sm\:ring-opacity-5 {
    --tw-ring-opacity: 0.05
  }

  .sm\:ring-opacity-10 {
    --tw-ring-opacity: 0.1
  }

  .sm\:ring-opacity-20 {
    --tw-ring-opacity: 0.2
  }

  .sm\:ring-opacity-25 {
    --tw-ring-opacity: 0.25
  }

  .sm\:ring-opacity-30 {
    --tw-ring-opacity: 0.3
  }

  .sm\:ring-opacity-40 {
    --tw-ring-opacity: 0.4
  }

  .sm\:ring-opacity-50 {
    --tw-ring-opacity: 0.5
  }

  .sm\:ring-opacity-60 {
    --tw-ring-opacity: 0.6
  }

  .sm\:ring-opacity-70 {
    --tw-ring-opacity: 0.7
  }

  .sm\:ring-opacity-75 {
    --tw-ring-opacity: 0.75
  }

  .sm\:ring-opacity-80 {
    --tw-ring-opacity: 0.8
  }

  .sm\:ring-opacity-90 {
    --tw-ring-opacity: 0.9
  }

  .sm\:ring-opacity-95 {
    --tw-ring-opacity: 0.95
  }

  .sm\:ring-opacity-100 {
    --tw-ring-opacity: 1
  }

  .sm\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0
  }

  .sm\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05
  }

  .sm\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1
  }

  .sm\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2
  }

  .sm\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25
  }

  .sm\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3
  }

  .sm\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4
  }

  .sm\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5
  }

  .sm\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6
  }

  .sm\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7
  }

  .sm\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75
  }

  .sm\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8
  }

  .sm\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9
  }

  .sm\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95
  }

  .sm\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1
  }

  .sm\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0
  }

  .sm\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05
  }

  .sm\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1
  }

  .sm\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2
  }

  .sm\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25
  }

  .sm\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3
  }

  .sm\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4
  }

  .sm\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5
  }

  .sm\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6
  }

  .sm\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7
  }

  .sm\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75
  }

  .sm\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8
  }

  .sm\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9
  }

  .sm\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95
  }

  .sm\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1
  }

  .sm\:fill-current {
    fill: currentColor
  }

  .sm\:stroke-current {
    stroke: currentColor
  }

  .sm\:stroke-0 {
    stroke-width: 0
  }

  .sm\:stroke-1 {
    stroke-width: 1
  }

  .sm\:stroke-2 {
    stroke-width: 2
  }

  .sm\:table-auto {
    table-layout: auto
  }

  .sm\:table-fixed {
    table-layout: fixed
  }

  .sm\:text-left {
    text-align: left
  }

  .sm\:text-center {
    text-align: center
  }

  .sm\:text-right {
    text-align: right
  }

  .sm\:text-justify {
    text-align: justify
  }

  [dir='ltr'] .sm\:ltr\:text-left,[dir='ltr'].sm\:ltr\:text-left {
    text-align: left
  }

  [dir='ltr'] .sm\:ltr\:text-center,[dir='ltr'].sm\:ltr\:text-center {
    text-align: center
  }

  [dir='ltr'] .sm\:ltr\:text-right,[dir='ltr'].sm\:ltr\:text-right {
    text-align: right
  }

  [dir='ltr'] .sm\:ltr\:text-justify,[dir='ltr'].sm\:ltr\:text-justify {
    text-align: justify
  }

  [dir='rtl'] .sm\:rtl\:text-left,[dir='rtl'].sm\:rtl\:text-left {
    text-align: left
  }

  [dir='rtl'] .sm\:rtl\:text-center,[dir='rtl'].sm\:rtl\:text-center {
    text-align: center
  }

  [dir='rtl'] .sm\:rtl\:text-right,[dir='rtl'].sm\:rtl\:text-right {
    text-align: right
  }

  [dir='rtl'] .sm\:rtl\:text-justify,[dir='rtl'].sm\:rtl\:text-justify {
    text-align: justify
  }

  .sm\:text-transparent {
    color: transparent
  }

  .sm\:text-current {
    color: currentColor
  }

  .sm\:text-black {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .sm\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .sm\:text-grey-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .sm\:text-grey-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .sm\:text-grey-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .sm\:text-grey-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .sm\:text-grey-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .sm\:text-grey-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:text-grey-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .sm\:text-grey-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:text-grey-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .sm\:text-grey-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .sm\:text-grey {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:text-grey-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .sm\:text-grey-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .sm\:text-grey-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .sm\:text-grey-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .sm\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .sm\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .sm\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .sm\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .sm\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .sm\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .sm\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .sm\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:text-gray-hover {
    color: rgba(0, 0, 0, 0.04)
  }

  .sm\:text-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .sm\:text-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .sm\:text-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .sm\:text-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .sm\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .sm\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .sm\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .sm\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .sm\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .sm\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .sm\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .sm\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .sm\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .sm\:text-red {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .sm\:text-red-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .sm\:text-red-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .sm\:text-red-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .sm\:text-red-A700 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .sm\:text-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .sm\:text-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .sm\:text-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .sm\:text-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .sm\:text-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .sm\:text-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .sm\:text-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .sm\:text-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .sm\:text-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .sm\:text-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .sm\:text-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .sm\:text-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .sm\:text-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .sm\:text-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .sm\:text-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .sm\:text-deep-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .sm\:text-deep-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .sm\:text-deep-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .sm\:text-deep-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .sm\:text-deep-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .sm\:text-deep-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .sm\:text-deep-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .sm\:text-deep-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .sm\:text-deep-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .sm\:text-deep-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .sm\:text-deep-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .sm\:text-deep-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .sm\:text-deep-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .sm\:text-deep-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .sm\:text-deep-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .sm\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .sm\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .sm\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .sm\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .sm\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .sm\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .sm\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .sm\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .sm\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .sm\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .sm\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .sm\:text-yellow-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .sm\:text-yellow-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .sm\:text-yellow-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .sm\:text-yellow-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .sm\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .sm\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .sm\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .sm\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .sm\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .sm\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .sm\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .sm\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .sm\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .sm\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .sm\:text-green {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .sm\:text-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .sm\:text-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .sm\:text-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .sm\:text-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .sm\:text-light-green-50 {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .sm\:text-light-green-100 {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .sm\:text-light-green-200 {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .sm\:text-light-green-300 {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .sm\:text-light-green-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .sm\:text-light-green-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .sm\:text-light-green-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .sm\:text-light-green-700 {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .sm\:text-light-green-800 {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .sm\:text-light-green-900 {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .sm\:text-light-green {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .sm\:text-light-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .sm\:text-light-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .sm\:text-light-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .sm\:text-light-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .sm\:text-teal-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .sm\:text-teal-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .sm\:text-teal-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .sm\:text-teal-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .sm\:text-teal-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .sm\:text-teal-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .sm\:text-teal-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .sm\:text-teal-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .sm\:text-teal-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .sm\:text-teal-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .sm\:text-teal {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .sm\:text-teal-A100 {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .sm\:text-teal-A200 {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .sm\:text-teal-A400 {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .sm\:text-teal-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .sm\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .sm\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .sm\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .sm\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .sm\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .sm\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .sm\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .sm\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .sm\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .sm\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .sm\:text-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .sm\:text-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .sm\:text-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .sm\:text-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .sm\:text-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .sm\:text-light-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .sm\:text-light-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .sm\:text-light-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .sm\:text-light-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .sm\:text-light-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .sm\:text-light-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .sm\:text-light-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .sm\:text-light-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .sm\:text-light-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .sm\:text-light-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .sm\:text-light-blue {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .sm\:text-light-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .sm\:text-light-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .sm\:text-light-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .sm\:text-light-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .sm\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .sm\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .sm\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .sm\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .sm\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .sm\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .sm\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .sm\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .sm\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .sm\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .sm\:text-indigo {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .sm\:text-indigo-A100 {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .sm\:text-indigo-A200 {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .sm\:text-indigo-A400 {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .sm\:text-indigo-A700 {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .sm\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .sm\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .sm\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .sm\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .sm\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .sm\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .sm\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .sm\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .sm\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .sm\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .sm\:text-purple {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .sm\:text-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .sm\:text-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .sm\:text-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .sm\:text-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .sm\:text-deep-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .sm\:text-deep-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .sm\:text-deep-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .sm\:text-deep-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .sm\:text-deep-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .sm\:text-deep-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .sm\:text-deep-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .sm\:text-deep-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .sm\:text-deep-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .sm\:text-deep-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .sm\:text-deep-purple {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .sm\:text-deep-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .sm\:text-deep-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .sm\:text-deep-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .sm\:text-deep-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .sm\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .sm\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .sm\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .sm\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .sm\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .sm\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .sm\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .sm\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .sm\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .sm\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .sm\:text-pink {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .sm\:text-pink-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .sm\:text-pink-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .sm\:text-pink-A400 {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .sm\:text-pink-A700 {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .sm\:text-lime-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .sm\:text-lime-100 {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .sm\:text-lime-200 {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .sm\:text-lime-300 {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .sm\:text-lime-400 {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .sm\:text-lime-500 {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .sm\:text-lime-600 {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .sm\:text-lime-700 {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .sm\:text-lime-800 {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .sm\:text-lime-900 {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .sm\:text-lime {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .sm\:text-lime-A100 {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .sm\:text-lime-A200 {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .sm\:text-lime-A400 {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .sm\:text-lime-A700 {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .sm\:text-amber-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .sm\:text-amber-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .sm\:text-amber-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .sm\:text-amber-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .sm\:text-amber-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .sm\:text-amber-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .sm\:text-amber-600 {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .sm\:text-amber-700 {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .sm\:text-amber-800 {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .sm\:text-amber-900 {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .sm\:text-amber {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .sm\:text-amber-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .sm\:text-amber-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .sm\:text-amber-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .sm\:text-amber-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .sm\:text-brown-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .sm\:text-brown-100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .sm\:text-brown-200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .sm\:text-brown-300 {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .sm\:text-brown-400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .sm\:text-brown-500 {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .sm\:text-brown-600 {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .sm\:text-brown-700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .sm\:text-brown-800 {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .sm\:text-brown-900 {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .sm\:text-brown {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .sm\:text-brown-A100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .sm\:text-brown-A200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .sm\:text-brown-A400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .sm\:text-brown-A700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .sm\:text-blue-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .sm\:text-blue-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .sm\:text-blue-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .sm\:text-blue-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .sm\:text-blue-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .sm\:text-blue-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .sm\:text-blue-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .sm\:text-blue-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .sm\:text-blue-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .sm\:text-blue-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .sm\:text-blue-gray {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .sm\:text-blue-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .sm\:text-blue-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .sm\:text-blue-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .sm\:text-blue-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .sm\:text-cyan-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .sm\:text-cyan-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .sm\:text-cyan-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .sm\:text-cyan-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .sm\:text-cyan-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .sm\:text-cyan-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .sm\:text-cyan-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .sm\:text-cyan-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .sm\:text-cyan-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .sm\:text-cyan-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .sm\:text-cyan {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .sm\:text-cyan-A100 {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .sm\:text-cyan-A200 {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .sm\:text-cyan-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .sm\:text-cyan-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-transparent {
    color: transparent
  }

  .group:hover .sm\:group-hover\:text-current {
    color: currentColor
  }

  .group:hover .sm\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-grey-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-grey-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-grey-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-grey-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-grey-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-grey-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-grey-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-grey-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-grey-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-grey-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-grey {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-grey-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-grey-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-grey-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-grey-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-gray-hover {
    color: rgba(0, 0, 0, 0.04)
  }

  .group:hover .sm\:group-hover\:text-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-red {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-red-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-red-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-red-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-red-A700 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-yellow-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-yellow-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-yellow-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-yellow-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-green {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-green-50 {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-green-100 {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-green-200 {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-green-300 {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-green-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-green-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-green-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-green-700 {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-green-800 {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-green-900 {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-green {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-teal-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-teal-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-teal-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-teal-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-teal-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-teal-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-teal-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-teal-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-teal-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-teal-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-teal {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-teal-A100 {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-teal-A200 {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-teal-A400 {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-teal-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-blue {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-light-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-indigo {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-indigo-A100 {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-indigo-A200 {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-indigo-A400 {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-indigo-A700 {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-purple {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-purple {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-deep-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-pink {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-pink-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-pink-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-pink-A400 {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-pink-A700 {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-lime-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-lime-100 {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-lime-200 {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-lime-300 {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-lime-400 {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-lime-500 {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-lime-600 {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-lime-700 {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-lime-800 {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-lime-900 {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-lime {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-lime-A100 {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-lime-A200 {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-lime-A400 {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-lime-A700 {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-amber-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-amber-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-amber-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-amber-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-amber-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-amber-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-amber-600 {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-amber-700 {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-amber-800 {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-amber-900 {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-amber {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-amber-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-amber-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-amber-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-amber-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-brown-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-brown-100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-brown-200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-brown-300 {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-brown-400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-brown-500 {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-brown-600 {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-brown-700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-brown-800 {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-brown-900 {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-brown {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-brown-A100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-brown-A200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-brown-A400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-brown-A700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-gray {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-blue-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-cyan-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-cyan-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-cyan-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-cyan-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-cyan-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-cyan-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-cyan-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-cyan-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-cyan-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-cyan-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-cyan {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-cyan-A100 {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-cyan-A200 {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-cyan-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .group:hover .sm\:group-hover\:text-cyan-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-transparent:focus-within {
    color: transparent
  }

  .sm\:focus-within\:text-current:focus-within {
    color: currentColor
  }

  .sm\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-grey-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-grey-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-grey-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-grey-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-grey-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-grey-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-grey-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-grey-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-grey-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-grey-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-grey-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-grey-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-grey-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-grey-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-gray-hover:focus-within {
    color: rgba(0, 0, 0, 0.04)
  }

  .sm\:focus-within\:text-gray-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-gray-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-gray-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-gray-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-red:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-red-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-red-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-red-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-red-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-orange-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-orange-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-orange-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-orange-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-orange-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-orange-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-orange-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-orange-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-orange-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-orange-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-orange:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-orange-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-orange-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-orange-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-orange-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-orange-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-orange-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-orange-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-orange-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-orange-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-orange-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-orange-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-orange-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-orange-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-orange-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-orange:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-orange-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-orange-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-orange-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-orange-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-yellow:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-yellow-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-yellow-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-yellow-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-yellow-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-green-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-green-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-green-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-green-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-green-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-green-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-green-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-green-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-teal-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-teal-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-teal-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-teal-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-teal-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-teal-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-teal-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-teal-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-teal-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-teal-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-teal:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-teal-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-teal-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-teal-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-teal-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-blue-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-blue-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-blue-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-light-blue-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-indigo:focus-within {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-indigo-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-indigo-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-indigo-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-indigo-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-purple:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-purple-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-purple-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-purple-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-purple-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-purple:focus-within {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-purple-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-purple-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-purple-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-deep-purple-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-pink:focus-within {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-pink-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-pink-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-pink-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-pink-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-lime-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-lime-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-lime-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-lime-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-lime-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-lime-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-lime-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-lime-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-lime-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-lime-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-lime:focus-within {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-lime-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-lime-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-lime-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-lime-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-amber-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-amber-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-amber-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-amber-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-amber-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-amber-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-amber-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-amber-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-amber-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-amber-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-amber:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-amber-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-amber-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-amber-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-amber-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-brown-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-brown-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-brown-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-brown-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-brown-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-brown-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-brown-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-brown-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-brown-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-brown-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-brown:focus-within {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-brown-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-brown-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-brown-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-brown-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-gray-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-gray-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-gray-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-blue-gray-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-cyan-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-cyan-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-cyan-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-cyan-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-cyan-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-cyan-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-cyan-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-cyan-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-cyan-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-cyan-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-cyan:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-cyan-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-cyan-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-cyan-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .sm\:focus-within\:text-cyan-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .sm\:hover\:text-transparent:hover {
    color: transparent
  }

  .sm\:hover\:text-current:hover {
    color: currentColor
  }

  .sm\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .sm\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-grey-50:hover {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .sm\:hover\:text-grey-100:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .sm\:hover\:text-grey-200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .sm\:hover\:text-grey-300:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .sm\:hover\:text-grey-400:hover {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .sm\:hover\:text-grey-500:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:hover\:text-grey-600:hover {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .sm\:hover\:text-grey-700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:hover\:text-grey-800:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .sm\:hover\:text-grey-900:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .sm\:hover\:text-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:hover\:text-grey-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .sm\:hover\:text-grey-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .sm\:hover\:text-grey-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .sm\:hover\:text-grey-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .sm\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .sm\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .sm\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .sm\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .sm\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .sm\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .sm\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .sm\:hover\:text-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:hover\:text-gray-hover:hover {
    color: rgba(0, 0, 0, 0.04)
  }

  .sm\:hover\:text-gray-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .sm\:hover\:text-gray-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .sm\:hover\:text-gray-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .sm\:hover\:text-gray-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .sm\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .sm\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .sm\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .sm\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .sm\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .sm\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .sm\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .sm\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .sm\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .sm\:hover\:text-red:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .sm\:hover\:text-red-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .sm\:hover\:text-red-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .sm\:hover\:text-red-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .sm\:hover\:text-red-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-orange-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .sm\:hover\:text-orange-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .sm\:hover\:text-orange-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .sm\:hover\:text-orange-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .sm\:hover\:text-orange-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .sm\:hover\:text-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-orange-600:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-orange-700:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-orange-800:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-orange-900:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-orange:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-orange-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .sm\:hover\:text-orange-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .sm\:hover\:text-orange-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-orange-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-orange-50:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-orange-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-orange-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-orange-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-orange-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-orange-600:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-orange-700:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-orange-800:hover {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-orange-900:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-orange:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-orange-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-orange-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-orange-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-orange-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .sm\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .sm\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .sm\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .sm\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .sm\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .sm\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .sm\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .sm\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .sm\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .sm\:hover\:text-yellow:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .sm\:hover\:text-yellow-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .sm\:hover\:text-yellow-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-yellow-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-yellow-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .sm\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .sm\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .sm\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .sm\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .sm\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .sm\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .sm\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .sm\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .sm\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .sm\:hover\:text-green:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .sm\:hover\:text-green-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .sm\:hover\:text-green-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .sm\:hover\:text-green-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .sm\:hover\:text-green-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-green:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-green-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-green-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-green-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-green-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .sm\:hover\:text-teal-50:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .sm\:hover\:text-teal-100:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .sm\:hover\:text-teal-200:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .sm\:hover\:text-teal-300:hover {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .sm\:hover\:text-teal-400:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .sm\:hover\:text-teal-500:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .sm\:hover\:text-teal-600:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .sm\:hover\:text-teal-700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .sm\:hover\:text-teal-800:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .sm\:hover\:text-teal-900:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .sm\:hover\:text-teal:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .sm\:hover\:text-teal-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .sm\:hover\:text-teal-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .sm\:hover\:text-teal-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .sm\:hover\:text-teal-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-blue-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-blue-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-blue-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-light-blue-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .sm\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .sm\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .sm\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .sm\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .sm\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .sm\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .sm\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .sm\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .sm\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .sm\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .sm\:hover\:text-indigo:hover {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .sm\:hover\:text-indigo-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-indigo-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .sm\:hover\:text-indigo-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .sm\:hover\:text-indigo-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .sm\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .sm\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .sm\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .sm\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .sm\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .sm\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .sm\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .sm\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .sm\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .sm\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .sm\:hover\:text-purple:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .sm\:hover\:text-purple-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .sm\:hover\:text-purple-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .sm\:hover\:text-purple-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .sm\:hover\:text-purple-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-purple:hover {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-purple-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-purple-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-purple-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-deep-purple-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .sm\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .sm\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .sm\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .sm\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .sm\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .sm\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .sm\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .sm\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .sm\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .sm\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .sm\:hover\:text-pink:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .sm\:hover\:text-pink-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .sm\:hover\:text-pink-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .sm\:hover\:text-pink-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .sm\:hover\:text-pink-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .sm\:hover\:text-lime-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .sm\:hover\:text-lime-100:hover {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .sm\:hover\:text-lime-200:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .sm\:hover\:text-lime-300:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .sm\:hover\:text-lime-400:hover {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .sm\:hover\:text-lime-500:hover {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .sm\:hover\:text-lime-600:hover {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .sm\:hover\:text-lime-700:hover {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .sm\:hover\:text-lime-800:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .sm\:hover\:text-lime-900:hover {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .sm\:hover\:text-lime:hover {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .sm\:hover\:text-lime-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .sm\:hover\:text-lime-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .sm\:hover\:text-lime-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-lime-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-amber-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .sm\:hover\:text-amber-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .sm\:hover\:text-amber-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .sm\:hover\:text-amber-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .sm\:hover\:text-amber-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .sm\:hover\:text-amber-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .sm\:hover\:text-amber-600:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-amber-700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-amber-800:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-amber-900:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-amber:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .sm\:hover\:text-amber-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .sm\:hover\:text-amber-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .sm\:hover\:text-amber-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-amber-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .sm\:hover\:text-brown-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .sm\:hover\:text-brown-100:hover {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .sm\:hover\:text-brown-200:hover {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .sm\:hover\:text-brown-300:hover {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .sm\:hover\:text-brown-400:hover {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .sm\:hover\:text-brown-500:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .sm\:hover\:text-brown-600:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .sm\:hover\:text-brown-700:hover {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .sm\:hover\:text-brown-800:hover {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .sm\:hover\:text-brown-900:hover {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .sm\:hover\:text-brown:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .sm\:hover\:text-brown-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .sm\:hover\:text-brown-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .sm\:hover\:text-brown-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .sm\:hover\:text-brown-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-gray-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-gray-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-gray-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .sm\:hover\:text-blue-gray-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .sm\:hover\:text-cyan-50:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .sm\:hover\:text-cyan-100:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .sm\:hover\:text-cyan-200:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .sm\:hover\:text-cyan-300:hover {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .sm\:hover\:text-cyan-400:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .sm\:hover\:text-cyan-500:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .sm\:hover\:text-cyan-600:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .sm\:hover\:text-cyan-700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .sm\:hover\:text-cyan-800:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .sm\:hover\:text-cyan-900:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .sm\:hover\:text-cyan:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .sm\:hover\:text-cyan-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-cyan-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-cyan-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .sm\:hover\:text-cyan-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .sm\:focus\:text-transparent:focus {
    color: transparent
  }

  .sm\:focus\:text-current:focus {
    color: currentColor
  }

  .sm\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .sm\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-grey-50:focus {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .sm\:focus\:text-grey-100:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .sm\:focus\:text-grey-200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .sm\:focus\:text-grey-300:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .sm\:focus\:text-grey-400:focus {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .sm\:focus\:text-grey-500:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:focus\:text-grey-600:focus {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .sm\:focus\:text-grey-700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:focus\:text-grey-800:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .sm\:focus\:text-grey-900:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .sm\:focus\:text-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:focus\:text-grey-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .sm\:focus\:text-grey-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .sm\:focus\:text-grey-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .sm\:focus\:text-grey-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .sm\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .sm\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .sm\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .sm\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .sm\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .sm\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .sm\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .sm\:focus\:text-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .sm\:focus\:text-gray-hover:focus {
    color: rgba(0, 0, 0, 0.04)
  }

  .sm\:focus\:text-gray-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .sm\:focus\:text-gray-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .sm\:focus\:text-gray-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .sm\:focus\:text-gray-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .sm\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .sm\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .sm\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .sm\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .sm\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .sm\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .sm\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .sm\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .sm\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .sm\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .sm\:focus\:text-red:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .sm\:focus\:text-red-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .sm\:focus\:text-red-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .sm\:focus\:text-red-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .sm\:focus\:text-red-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-orange-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .sm\:focus\:text-orange-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .sm\:focus\:text-orange-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .sm\:focus\:text-orange-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .sm\:focus\:text-orange-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .sm\:focus\:text-orange-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-orange-600:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-orange-700:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-orange-800:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-orange-900:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-orange:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-orange-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .sm\:focus\:text-orange-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .sm\:focus\:text-orange-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-orange-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-orange-50:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-orange-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-orange-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-orange-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-orange-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-orange-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-orange-600:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-orange-700:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-orange-800:focus {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-orange-900:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-orange:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-orange-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-orange-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-orange-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-orange-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .sm\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .sm\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .sm\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .sm\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .sm\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .sm\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .sm\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .sm\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .sm\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .sm\:focus\:text-yellow:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .sm\:focus\:text-yellow-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .sm\:focus\:text-yellow-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-yellow-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-yellow-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .sm\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .sm\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .sm\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .sm\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .sm\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .sm\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .sm\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .sm\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .sm\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .sm\:focus\:text-green:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .sm\:focus\:text-green-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .sm\:focus\:text-green-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .sm\:focus\:text-green-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .sm\:focus\:text-green-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-green:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-green-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-green-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-green-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-green-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .sm\:focus\:text-teal-50:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .sm\:focus\:text-teal-100:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .sm\:focus\:text-teal-200:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .sm\:focus\:text-teal-300:focus {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .sm\:focus\:text-teal-400:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .sm\:focus\:text-teal-500:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .sm\:focus\:text-teal-600:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .sm\:focus\:text-teal-700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .sm\:focus\:text-teal-800:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .sm\:focus\:text-teal-900:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .sm\:focus\:text-teal:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .sm\:focus\:text-teal-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .sm\:focus\:text-teal-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .sm\:focus\:text-teal-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .sm\:focus\:text-teal-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-blue-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-blue-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-blue-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-light-blue-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .sm\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .sm\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .sm\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .sm\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .sm\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .sm\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .sm\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .sm\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .sm\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .sm\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .sm\:focus\:text-indigo:focus {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .sm\:focus\:text-indigo-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-indigo-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .sm\:focus\:text-indigo-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .sm\:focus\:text-indigo-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .sm\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .sm\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .sm\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .sm\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .sm\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .sm\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .sm\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .sm\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .sm\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .sm\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .sm\:focus\:text-purple:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .sm\:focus\:text-purple-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .sm\:focus\:text-purple-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .sm\:focus\:text-purple-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .sm\:focus\:text-purple-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-purple:focus {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-purple-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-purple-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-purple-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-deep-purple-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .sm\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .sm\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .sm\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .sm\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .sm\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .sm\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .sm\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .sm\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .sm\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .sm\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .sm\:focus\:text-pink:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .sm\:focus\:text-pink-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .sm\:focus\:text-pink-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .sm\:focus\:text-pink-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .sm\:focus\:text-pink-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .sm\:focus\:text-lime-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .sm\:focus\:text-lime-100:focus {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .sm\:focus\:text-lime-200:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .sm\:focus\:text-lime-300:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .sm\:focus\:text-lime-400:focus {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .sm\:focus\:text-lime-500:focus {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .sm\:focus\:text-lime-600:focus {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .sm\:focus\:text-lime-700:focus {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .sm\:focus\:text-lime-800:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .sm\:focus\:text-lime-900:focus {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .sm\:focus\:text-lime:focus {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .sm\:focus\:text-lime-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .sm\:focus\:text-lime-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .sm\:focus\:text-lime-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-lime-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-amber-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .sm\:focus\:text-amber-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .sm\:focus\:text-amber-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .sm\:focus\:text-amber-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .sm\:focus\:text-amber-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .sm\:focus\:text-amber-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .sm\:focus\:text-amber-600:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-amber-700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-amber-800:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-amber-900:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-amber:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .sm\:focus\:text-amber-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .sm\:focus\:text-amber-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .sm\:focus\:text-amber-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-amber-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .sm\:focus\:text-brown-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .sm\:focus\:text-brown-100:focus {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .sm\:focus\:text-brown-200:focus {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .sm\:focus\:text-brown-300:focus {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .sm\:focus\:text-brown-400:focus {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .sm\:focus\:text-brown-500:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .sm\:focus\:text-brown-600:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .sm\:focus\:text-brown-700:focus {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .sm\:focus\:text-brown-800:focus {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .sm\:focus\:text-brown-900:focus {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .sm\:focus\:text-brown:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .sm\:focus\:text-brown-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .sm\:focus\:text-brown-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .sm\:focus\:text-brown-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .sm\:focus\:text-brown-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-gray-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-gray-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-gray-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .sm\:focus\:text-blue-gray-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .sm\:focus\:text-cyan-50:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .sm\:focus\:text-cyan-100:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .sm\:focus\:text-cyan-200:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .sm\:focus\:text-cyan-300:focus {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .sm\:focus\:text-cyan-400:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .sm\:focus\:text-cyan-500:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .sm\:focus\:text-cyan-600:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .sm\:focus\:text-cyan-700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .sm\:focus\:text-cyan-800:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .sm\:focus\:text-cyan-900:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .sm\:focus\:text-cyan:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .sm\:focus\:text-cyan-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-cyan-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-cyan-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .sm\:focus\:text-cyan-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .sm\:text-opacity-0 {
    --tw-text-opacity: 0
  }

  .sm\:text-opacity-5 {
    --tw-text-opacity: 0.05
  }

  .sm\:text-opacity-10 {
    --tw-text-opacity: 0.1
  }

  .sm\:text-opacity-20 {
    --tw-text-opacity: 0.2
  }

  .sm\:text-opacity-25 {
    --tw-text-opacity: 0.25
  }

  .sm\:text-opacity-30 {
    --tw-text-opacity: 0.3
  }

  .sm\:text-opacity-40 {
    --tw-text-opacity: 0.4
  }

  .sm\:text-opacity-50 {
    --tw-text-opacity: 0.5
  }

  .sm\:text-opacity-60 {
    --tw-text-opacity: 0.6
  }

  .sm\:text-opacity-70 {
    --tw-text-opacity: 0.7
  }

  .sm\:text-opacity-75 {
    --tw-text-opacity: 0.75
  }

  .sm\:text-opacity-80 {
    --tw-text-opacity: 0.8
  }

  .sm\:text-opacity-90 {
    --tw-text-opacity: 0.9
  }

  .sm\:text-opacity-95 {
    --tw-text-opacity: 0.95
  }

  .sm\:text-opacity-100 {
    --tw-text-opacity: 1
  }

  .group:hover .sm\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0
  }

  .group:hover .sm\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05
  }

  .group:hover .sm\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1
  }

  .group:hover .sm\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2
  }

  .group:hover .sm\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25
  }

  .group:hover .sm\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3
  }

  .group:hover .sm\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4
  }

  .group:hover .sm\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5
  }

  .group:hover .sm\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6
  }

  .group:hover .sm\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7
  }

  .group:hover .sm\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75
  }

  .group:hover .sm\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8
  }

  .group:hover .sm\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9
  }

  .group:hover .sm\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95
  }

  .group:hover .sm\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1
  }

  .sm\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0
  }

  .sm\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05
  }

  .sm\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1
  }

  .sm\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2
  }

  .sm\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25
  }

  .sm\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3
  }

  .sm\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4
  }

  .sm\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5
  }

  .sm\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6
  }

  .sm\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7
  }

  .sm\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75
  }

  .sm\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8
  }

  .sm\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9
  }

  .sm\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95
  }

  .sm\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1
  }

  .sm\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0
  }

  .sm\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05
  }

  .sm\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1
  }

  .sm\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2
  }

  .sm\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25
  }

  .sm\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3
  }

  .sm\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4
  }

  .sm\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5
  }

  .sm\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6
  }

  .sm\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7
  }

  .sm\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75
  }

  .sm\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8
  }

  .sm\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9
  }

  .sm\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95
  }

  .sm\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1
  }

  .sm\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0
  }

  .sm\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05
  }

  .sm\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1
  }

  .sm\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2
  }

  .sm\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25
  }

  .sm\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3
  }

  .sm\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4
  }

  .sm\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5
  }

  .sm\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6
  }

  .sm\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7
  }

  .sm\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75
  }

  .sm\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8
  }

  .sm\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9
  }

  .sm\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95
  }

  .sm\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1
  }

  .sm\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .sm\:overflow-ellipsis {
    text-overflow: ellipsis
  }

  .sm\:overflow-clip {
    text-overflow: clip
  }

  .sm\:italic {
    font-style: italic
  }

  .sm\:not-italic {
    font-style: normal
  }

  .sm\:hover\:italic:hover {
    font-style: italic
  }

  .sm\:hover\:not-italic:hover {
    font-style: normal
  }

  .sm\:focus\:italic:focus {
    font-style: italic
  }

  .sm\:focus\:not-italic:focus {
    font-style: normal
  }

  .sm\:uppercase {
    text-transform: uppercase
  }

  .sm\:lowercase {
    text-transform: lowercase
  }

  .sm\:capitalize {
    text-transform: capitalize
  }

  .sm\:normal-case {
    text-transform: none
  }

  .sm\:underline {
    text-decoration: underline
  }

  .sm\:line-through {
    text-decoration: line-through
  }

  .sm\:no-underline {
    text-decoration: none
  }

  .group:hover .sm\:group-hover\:underline {
    text-decoration: underline
  }

  .group:hover .sm\:group-hover\:line-through {
    text-decoration: line-through
  }

  .group:hover .sm\:group-hover\:no-underline {
    text-decoration: none
  }

  .sm\:focus-within\:underline:focus-within {
    text-decoration: underline
  }

  .sm\:focus-within\:line-through:focus-within {
    text-decoration: line-through
  }

  .sm\:focus-within\:no-underline:focus-within {
    text-decoration: none
  }

  .sm\:hover\:underline:hover {
    text-decoration: underline
  }

  .sm\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .sm\:hover\:no-underline:hover {
    text-decoration: none
  }

  .sm\:focus\:underline:focus {
    text-decoration: underline
  }

  .sm\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .sm\:focus\:no-underline:focus {
    text-decoration: none
  }

  .sm\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .sm\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .sm\:ordinal, .sm\:slashed-zero, .sm\:lining-nums, .sm\:oldstyle-nums, .sm\:proportional-nums, .sm\:tabular-nums, .sm\:diagonal-fractions, .sm\:stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  .sm\:normal-nums {
    font-variant-numeric: normal
  }

  .sm\:ordinal {
    --tw-ordinal: ordinal
  }

  .sm\:slashed-zero {
    --tw-slashed-zero: slashed-zero
  }

  .sm\:lining-nums {
    --tw-numeric-figure: lining-nums
  }

  .sm\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums
  }

  .sm\:proportional-nums {
    --tw-numeric-spacing: proportional-nums
  }

  .sm\:tabular-nums {
    --tw-numeric-spacing: tabular-nums
  }

  .sm\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions
  }

  .sm\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions
  }

  .sm\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .sm\:tracking-tight {
    letter-spacing: -0.025em
  }

  .sm\:tracking-normal {
    letter-spacing: 0em
  }

  .sm\:tracking-wide {
    letter-spacing: 0.025em
  }

  .sm\:tracking-wider {
    letter-spacing: 0.05em
  }

  .sm\:tracking-widest {
    letter-spacing: 0.1em
  }

  .sm\:select-none {
    -webkit-user-select: none;
            user-select: none
  }

  .sm\:select-text {
    -webkit-user-select: text;
            user-select: text
  }

  .sm\:select-all {
    -webkit-user-select: all;
            user-select: all
  }

  .sm\:select-auto {
    -webkit-user-select: auto;
            user-select: auto
  }

  .sm\:align-baseline {
    vertical-align: baseline
  }

  .sm\:align-top {
    vertical-align: top
  }

  .sm\:align-middle {
    vertical-align: middle
  }

  .sm\:align-bottom {
    vertical-align: bottom
  }

  .sm\:align-text-top {
    vertical-align: text-top
  }

  .sm\:align-text-bottom {
    vertical-align: text-bottom
  }

  .sm\:visible {
    visibility: visible
  }

  .sm\:invisible {
    visibility: hidden
  }

  .sm\:whitespace-normal {
    white-space: normal
  }

  .sm\:whitespace-nowrap {
    white-space: nowrap
  }

  .sm\:whitespace-pre {
    white-space: pre
  }

  .sm\:whitespace-pre-line {
    white-space: pre-line
  }

  .sm\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .sm\:break-normal {
    overflow-wrap: normal;
    word-break: normal
  }

  .sm\:break-words {
    overflow-wrap: break-word
  }

  .sm\:break-all {
    word-break: break-all
  }

  .sm\:w-0 {
    width: 0
  }

  .sm\:w-1 {
    width: 0.1rem
  }

  .sm\:w-2 {
    width: 0.2rem
  }

  .sm\:w-3 {
    width: 0.3rem
  }

  .sm\:w-4 {
    width: 0.4rem
  }

  .sm\:w-5 {
    width: 0.5rem
  }

  .sm\:w-6 {
    width: 0.6rem
  }

  .sm\:w-7 {
    width: 0.7rem
  }

  .sm\:w-8 {
    width: 0.8rem
  }

  .sm\:w-9 {
    width: 0.9rem
  }

  .sm\:w-10 {
    width: 1.0rem
  }

  .sm\:w-12 {
    width: 1.2rem
  }

  .sm\:w-14 {
    width: 1.4rem
  }

  .sm\:w-16 {
    width: 1.6rem
  }

  .sm\:w-20 {
    width: 2rem
  }

  .sm\:w-24 {
    width: 2.4rem
  }

  .sm\:w-28 {
    width: 2.8rem
  }

  .sm\:w-32 {
    width: 3.2rem
  }

  .sm\:w-36 {
    width: 3.6rem
  }

  .sm\:w-40 {
    width: 4rem
  }

  .sm\:w-44 {
    width: 4.4rem
  }

  .sm\:w-48 {
    width: 4.8rem
  }

  .sm\:w-52 {
    width: 5.2rem
  }

  .sm\:w-56 {
    width: 5.6rem
  }

  .sm\:w-60 {
    width: 6rem
  }

  .sm\:w-64 {
    width: 6.4rem
  }

  .sm\:w-68 {
    width: 6.8rem
  }

  .sm\:w-72 {
    width: 7.2rem
  }

  .sm\:w-76 {
    width: 7.6rem
  }

  .sm\:w-80 {
    width: 8rem
  }

  .sm\:w-84 {
    width: 8.4rem
  }

  .sm\:w-88 {
    width: 8.8rem
  }

  .sm\:w-92 {
    width: 9.2rem
  }

  .sm\:w-96 {
    width: 9.6rem
  }

  .sm\:w-128 {
    width: 12.8rem
  }

  .sm\:w-136 {
    width: 13.6rem
  }

  .sm\:w-160 {
    width: 16rem
  }

  .sm\:w-192 {
    width: 19.2rem
  }

  .sm\:w-200 {
    width: 20rem
  }

  .sm\:w-208 {
    width: 20.8rem
  }

  .sm\:w-216 {
    width: 21.6rem
  }

  .sm\:w-224 {
    width: 22.4rem
  }

  .sm\:w-256 {
    width: 25.6rem
  }

  .sm\:w-288 {
    width: 28.8rem
  }

  .sm\:w-320 {
    width: 32rem
  }

  .sm\:w-360 {
    width: 36rem
  }

  .sm\:w-384 {
    width: 38.4rem
  }

  .sm\:w-400 {
    width: 40rem
  }

  .sm\:w-512 {
    width: 51.2rem
  }

  .sm\:w-640 {
    width: 64rem
  }

  .sm\:w-auto {
    width: auto
  }

  .sm\:w-xs {
    width: 32rem
  }

  .sm\:w-sm {
    width: 48rem
  }

  .sm\:w-md {
    width: 64rem
  }

  .sm\:w-lg {
    width: 80rem
  }

  .sm\:w-xl {
    width: 96rem
  }

  .sm\:w-2xl {
    width: 112rem
  }

  .sm\:w-3xl {
    width: 128rem
  }

  .sm\:w-4xl {
    width: 144rem
  }

  .sm\:w-5xl {
    width: 160rem
  }

  .sm\:w-px {
    width: 1px
  }

  .sm\:w-0\.5 {
    width: 0.05rem
  }

  .sm\:w-1\.5 {
    width: 0.15rem
  }

  .sm\:w-2\.5 {
    width: 0.25rem
  }

  .sm\:w-3\.5 {
    width: 0.35rem
  }

  .sm\:w-1\/2 {
    width: 50%
  }

  .sm\:w-1\/3 {
    width: 33.333333%
  }

  .sm\:w-2\/3 {
    width: 66.666667%
  }

  .sm\:w-1\/4 {
    width: 25%
  }

  .sm\:w-2\/4 {
    width: 50%
  }

  .sm\:w-3\/4 {
    width: 75%
  }

  .sm\:w-1\/5 {
    width: 20%
  }

  .sm\:w-2\/5 {
    width: 40%
  }

  .sm\:w-3\/5 {
    width: 60%
  }

  .sm\:w-4\/5 {
    width: 80%
  }

  .sm\:w-1\/6 {
    width: 16.666667%
  }

  .sm\:w-2\/6 {
    width: 33.333333%
  }

  .sm\:w-3\/6 {
    width: 50%
  }

  .sm\:w-4\/6 {
    width: 66.666667%
  }

  .sm\:w-5\/6 {
    width: 83.333333%
  }

  .sm\:w-1\/12 {
    width: 8.333333%
  }

  .sm\:w-2\/12 {
    width: 16.666667%
  }

  .sm\:w-3\/12 {
    width: 25%
  }

  .sm\:w-4\/12 {
    width: 33.333333%
  }

  .sm\:w-5\/12 {
    width: 41.666667%
  }

  .sm\:w-6\/12 {
    width: 50%
  }

  .sm\:w-7\/12 {
    width: 58.333333%
  }

  .sm\:w-8\/12 {
    width: 66.666667%
  }

  .sm\:w-9\/12 {
    width: 75%
  }

  .sm\:w-10\/12 {
    width: 83.333333%
  }

  .sm\:w-11\/12 {
    width: 91.666667%
  }

  .sm\:w-full {
    width: 100%
  }

  .sm\:w-screen {
    width: 100vw
  }

  .sm\:w-min {
    width: min-content
  }

  .sm\:w-max {
    width: max-content
  }

  .sm\:z-0 {
    z-index: 0
  }

  .sm\:z-10 {
    z-index: 10
  }

  .sm\:z-20 {
    z-index: 20
  }

  .sm\:z-30 {
    z-index: 30
  }

  .sm\:z-40 {
    z-index: 40
  }

  .sm\:z-50 {
    z-index: 50
  }

  .sm\:z-99 {
    z-index: 99
  }

  .sm\:z-999 {
    z-index: 999
  }

  .sm\:z-9999 {
    z-index: 9999
  }

  .sm\:z-auto {
    z-index: auto
  }

  .sm\:focus-within\:z-0:focus-within {
    z-index: 0
  }

  .sm\:focus-within\:z-10:focus-within {
    z-index: 10
  }

  .sm\:focus-within\:z-20:focus-within {
    z-index: 20
  }

  .sm\:focus-within\:z-30:focus-within {
    z-index: 30
  }

  .sm\:focus-within\:z-40:focus-within {
    z-index: 40
  }

  .sm\:focus-within\:z-50:focus-within {
    z-index: 50
  }

  .sm\:focus-within\:z-99:focus-within {
    z-index: 99
  }

  .sm\:focus-within\:z-999:focus-within {
    z-index: 999
  }

  .sm\:focus-within\:z-9999:focus-within {
    z-index: 9999
  }

  .sm\:focus-within\:z-auto:focus-within {
    z-index: auto
  }

  .sm\:focus\:z-0:focus {
    z-index: 0
  }

  .sm\:focus\:z-10:focus {
    z-index: 10
  }

  .sm\:focus\:z-20:focus {
    z-index: 20
  }

  .sm\:focus\:z-30:focus {
    z-index: 30
  }

  .sm\:focus\:z-40:focus {
    z-index: 40
  }

  .sm\:focus\:z-50:focus {
    z-index: 50
  }

  .sm\:focus\:z-99:focus {
    z-index: 99
  }

  .sm\:focus\:z-999:focus {
    z-index: 999
  }

  .sm\:focus\:z-9999:focus {
    z-index: 9999
  }

  .sm\:focus\:z-auto:focus {
    z-index: auto
  }

  .sm\:gap-0 {
    gap: 0
  }

  .sm\:gap-1 {
    gap: 0.1rem
  }

  .sm\:gap-2 {
    gap: 0.2rem
  }

  .sm\:gap-3 {
    gap: 0.3rem
  }

  .sm\:gap-4 {
    gap: 0.4rem
  }

  .sm\:gap-5 {
    gap: 0.5rem
  }

  .sm\:gap-6 {
    gap: 0.6rem
  }

  .sm\:gap-7 {
    gap: 0.7rem
  }

  .sm\:gap-8 {
    gap: 0.8rem
  }

  .sm\:gap-9 {
    gap: 0.9rem
  }

  .sm\:gap-10 {
    gap: 1.0rem
  }

  .sm\:gap-12 {
    gap: 1.2rem
  }

  .sm\:gap-14 {
    gap: 1.4rem
  }

  .sm\:gap-16 {
    gap: 1.6rem
  }

  .sm\:gap-20 {
    gap: 2rem
  }

  .sm\:gap-24 {
    gap: 2.4rem
  }

  .sm\:gap-28 {
    gap: 2.8rem
  }

  .sm\:gap-32 {
    gap: 3.2rem
  }

  .sm\:gap-36 {
    gap: 3.6rem
  }

  .sm\:gap-40 {
    gap: 4rem
  }

  .sm\:gap-44 {
    gap: 4.4rem
  }

  .sm\:gap-48 {
    gap: 4.8rem
  }

  .sm\:gap-52 {
    gap: 5.2rem
  }

  .sm\:gap-56 {
    gap: 5.6rem
  }

  .sm\:gap-60 {
    gap: 6rem
  }

  .sm\:gap-64 {
    gap: 6.4rem
  }

  .sm\:gap-68 {
    gap: 6.8rem
  }

  .sm\:gap-72 {
    gap: 7.2rem
  }

  .sm\:gap-76 {
    gap: 7.6rem
  }

  .sm\:gap-80 {
    gap: 8rem
  }

  .sm\:gap-84 {
    gap: 8.4rem
  }

  .sm\:gap-88 {
    gap: 8.8rem
  }

  .sm\:gap-92 {
    gap: 9.2rem
  }

  .sm\:gap-96 {
    gap: 9.6rem
  }

  .sm\:gap-128 {
    gap: 12.8rem
  }

  .sm\:gap-136 {
    gap: 13.6rem
  }

  .sm\:gap-160 {
    gap: 16rem
  }

  .sm\:gap-192 {
    gap: 19.2rem
  }

  .sm\:gap-200 {
    gap: 20rem
  }

  .sm\:gap-208 {
    gap: 20.8rem
  }

  .sm\:gap-216 {
    gap: 21.6rem
  }

  .sm\:gap-224 {
    gap: 22.4rem
  }

  .sm\:gap-256 {
    gap: 25.6rem
  }

  .sm\:gap-288 {
    gap: 28.8rem
  }

  .sm\:gap-320 {
    gap: 32rem
  }

  .sm\:gap-360 {
    gap: 36rem
  }

  .sm\:gap-384 {
    gap: 38.4rem
  }

  .sm\:gap-400 {
    gap: 40rem
  }

  .sm\:gap-512 {
    gap: 51.2rem
  }

  .sm\:gap-640 {
    gap: 64rem
  }

  .sm\:gap-xs {
    gap: 32rem
  }

  .sm\:gap-sm {
    gap: 48rem
  }

  .sm\:gap-md {
    gap: 64rem
  }

  .sm\:gap-lg {
    gap: 80rem
  }

  .sm\:gap-xl {
    gap: 96rem
  }

  .sm\:gap-2xl {
    gap: 112rem
  }

  .sm\:gap-3xl {
    gap: 128rem
  }

  .sm\:gap-4xl {
    gap: 144rem
  }

  .sm\:gap-5xl {
    gap: 160rem
  }

  .sm\:gap-px {
    gap: 1px
  }

  .sm\:gap-0\.5 {
    gap: 0.05rem
  }

  .sm\:gap-1\.5 {
    gap: 0.15rem
  }

  .sm\:gap-2\.5 {
    gap: 0.25rem
  }

  .sm\:gap-3\.5 {
    gap: 0.35rem
  }

  .sm\:gap-x-0 {
    column-gap: 0
  }

  .sm\:gap-x-1 {
    column-gap: 0.1rem
  }

  .sm\:gap-x-2 {
    column-gap: 0.2rem
  }

  .sm\:gap-x-3 {
    column-gap: 0.3rem
  }

  .sm\:gap-x-4 {
    column-gap: 0.4rem
  }

  .sm\:gap-x-5 {
    column-gap: 0.5rem
  }

  .sm\:gap-x-6 {
    column-gap: 0.6rem
  }

  .sm\:gap-x-7 {
    column-gap: 0.7rem
  }

  .sm\:gap-x-8 {
    column-gap: 0.8rem
  }

  .sm\:gap-x-9 {
    column-gap: 0.9rem
  }

  .sm\:gap-x-10 {
    column-gap: 1.0rem
  }

  .sm\:gap-x-12 {
    column-gap: 1.2rem
  }

  .sm\:gap-x-14 {
    column-gap: 1.4rem
  }

  .sm\:gap-x-16 {
    column-gap: 1.6rem
  }

  .sm\:gap-x-20 {
    column-gap: 2rem
  }

  .sm\:gap-x-24 {
    column-gap: 2.4rem
  }

  .sm\:gap-x-28 {
    column-gap: 2.8rem
  }

  .sm\:gap-x-32 {
    column-gap: 3.2rem
  }

  .sm\:gap-x-36 {
    column-gap: 3.6rem
  }

  .sm\:gap-x-40 {
    column-gap: 4rem
  }

  .sm\:gap-x-44 {
    column-gap: 4.4rem
  }

  .sm\:gap-x-48 {
    column-gap: 4.8rem
  }

  .sm\:gap-x-52 {
    column-gap: 5.2rem
  }

  .sm\:gap-x-56 {
    column-gap: 5.6rem
  }

  .sm\:gap-x-60 {
    column-gap: 6rem
  }

  .sm\:gap-x-64 {
    column-gap: 6.4rem
  }

  .sm\:gap-x-68 {
    column-gap: 6.8rem
  }

  .sm\:gap-x-72 {
    column-gap: 7.2rem
  }

  .sm\:gap-x-76 {
    column-gap: 7.6rem
  }

  .sm\:gap-x-80 {
    column-gap: 8rem
  }

  .sm\:gap-x-84 {
    column-gap: 8.4rem
  }

  .sm\:gap-x-88 {
    column-gap: 8.8rem
  }

  .sm\:gap-x-92 {
    column-gap: 9.2rem
  }

  .sm\:gap-x-96 {
    column-gap: 9.6rem
  }

  .sm\:gap-x-128 {
    column-gap: 12.8rem
  }

  .sm\:gap-x-136 {
    column-gap: 13.6rem
  }

  .sm\:gap-x-160 {
    column-gap: 16rem
  }

  .sm\:gap-x-192 {
    column-gap: 19.2rem
  }

  .sm\:gap-x-200 {
    column-gap: 20rem
  }

  .sm\:gap-x-208 {
    column-gap: 20.8rem
  }

  .sm\:gap-x-216 {
    column-gap: 21.6rem
  }

  .sm\:gap-x-224 {
    column-gap: 22.4rem
  }

  .sm\:gap-x-256 {
    column-gap: 25.6rem
  }

  .sm\:gap-x-288 {
    column-gap: 28.8rem
  }

  .sm\:gap-x-320 {
    column-gap: 32rem
  }

  .sm\:gap-x-360 {
    column-gap: 36rem
  }

  .sm\:gap-x-384 {
    column-gap: 38.4rem
  }

  .sm\:gap-x-400 {
    column-gap: 40rem
  }

  .sm\:gap-x-512 {
    column-gap: 51.2rem
  }

  .sm\:gap-x-640 {
    column-gap: 64rem
  }

  .sm\:gap-x-xs {
    column-gap: 32rem
  }

  .sm\:gap-x-sm {
    column-gap: 48rem
  }

  .sm\:gap-x-md {
    column-gap: 64rem
  }

  .sm\:gap-x-lg {
    column-gap: 80rem
  }

  .sm\:gap-x-xl {
    column-gap: 96rem
  }

  .sm\:gap-x-2xl {
    column-gap: 112rem
  }

  .sm\:gap-x-3xl {
    column-gap: 128rem
  }

  .sm\:gap-x-4xl {
    column-gap: 144rem
  }

  .sm\:gap-x-5xl {
    column-gap: 160rem
  }

  .sm\:gap-x-px {
    column-gap: 1px
  }

  .sm\:gap-x-0\.5 {
    column-gap: 0.05rem
  }

  .sm\:gap-x-1\.5 {
    column-gap: 0.15rem
  }

  .sm\:gap-x-2\.5 {
    column-gap: 0.25rem
  }

  .sm\:gap-x-3\.5 {
    column-gap: 0.35rem
  }

  .sm\:gap-y-0 {
    row-gap: 0
  }

  .sm\:gap-y-1 {
    row-gap: 0.1rem
  }

  .sm\:gap-y-2 {
    row-gap: 0.2rem
  }

  .sm\:gap-y-3 {
    row-gap: 0.3rem
  }

  .sm\:gap-y-4 {
    row-gap: 0.4rem
  }

  .sm\:gap-y-5 {
    row-gap: 0.5rem
  }

  .sm\:gap-y-6 {
    row-gap: 0.6rem
  }

  .sm\:gap-y-7 {
    row-gap: 0.7rem
  }

  .sm\:gap-y-8 {
    row-gap: 0.8rem
  }

  .sm\:gap-y-9 {
    row-gap: 0.9rem
  }

  .sm\:gap-y-10 {
    row-gap: 1.0rem
  }

  .sm\:gap-y-12 {
    row-gap: 1.2rem
  }

  .sm\:gap-y-14 {
    row-gap: 1.4rem
  }

  .sm\:gap-y-16 {
    row-gap: 1.6rem
  }

  .sm\:gap-y-20 {
    row-gap: 2rem
  }

  .sm\:gap-y-24 {
    row-gap: 2.4rem
  }

  .sm\:gap-y-28 {
    row-gap: 2.8rem
  }

  .sm\:gap-y-32 {
    row-gap: 3.2rem
  }

  .sm\:gap-y-36 {
    row-gap: 3.6rem
  }

  .sm\:gap-y-40 {
    row-gap: 4rem
  }

  .sm\:gap-y-44 {
    row-gap: 4.4rem
  }

  .sm\:gap-y-48 {
    row-gap: 4.8rem
  }

  .sm\:gap-y-52 {
    row-gap: 5.2rem
  }

  .sm\:gap-y-56 {
    row-gap: 5.6rem
  }

  .sm\:gap-y-60 {
    row-gap: 6rem
  }

  .sm\:gap-y-64 {
    row-gap: 6.4rem
  }

  .sm\:gap-y-68 {
    row-gap: 6.8rem
  }

  .sm\:gap-y-72 {
    row-gap: 7.2rem
  }

  .sm\:gap-y-76 {
    row-gap: 7.6rem
  }

  .sm\:gap-y-80 {
    row-gap: 8rem
  }

  .sm\:gap-y-84 {
    row-gap: 8.4rem
  }

  .sm\:gap-y-88 {
    row-gap: 8.8rem
  }

  .sm\:gap-y-92 {
    row-gap: 9.2rem
  }

  .sm\:gap-y-96 {
    row-gap: 9.6rem
  }

  .sm\:gap-y-128 {
    row-gap: 12.8rem
  }

  .sm\:gap-y-136 {
    row-gap: 13.6rem
  }

  .sm\:gap-y-160 {
    row-gap: 16rem
  }

  .sm\:gap-y-192 {
    row-gap: 19.2rem
  }

  .sm\:gap-y-200 {
    row-gap: 20rem
  }

  .sm\:gap-y-208 {
    row-gap: 20.8rem
  }

  .sm\:gap-y-216 {
    row-gap: 21.6rem
  }

  .sm\:gap-y-224 {
    row-gap: 22.4rem
  }

  .sm\:gap-y-256 {
    row-gap: 25.6rem
  }

  .sm\:gap-y-288 {
    row-gap: 28.8rem
  }

  .sm\:gap-y-320 {
    row-gap: 32rem
  }

  .sm\:gap-y-360 {
    row-gap: 36rem
  }

  .sm\:gap-y-384 {
    row-gap: 38.4rem
  }

  .sm\:gap-y-400 {
    row-gap: 40rem
  }

  .sm\:gap-y-512 {
    row-gap: 51.2rem
  }

  .sm\:gap-y-640 {
    row-gap: 64rem
  }

  .sm\:gap-y-xs {
    row-gap: 32rem
  }

  .sm\:gap-y-sm {
    row-gap: 48rem
  }

  .sm\:gap-y-md {
    row-gap: 64rem
  }

  .sm\:gap-y-lg {
    row-gap: 80rem
  }

  .sm\:gap-y-xl {
    row-gap: 96rem
  }

  .sm\:gap-y-2xl {
    row-gap: 112rem
  }

  .sm\:gap-y-3xl {
    row-gap: 128rem
  }

  .sm\:gap-y-4xl {
    row-gap: 144rem
  }

  .sm\:gap-y-5xl {
    row-gap: 160rem
  }

  .sm\:gap-y-px {
    row-gap: 1px
  }

  .sm\:gap-y-0\.5 {
    row-gap: 0.05rem
  }

  .sm\:gap-y-1\.5 {
    row-gap: 0.15rem
  }

  .sm\:gap-y-2\.5 {
    row-gap: 0.25rem
  }

  .sm\:gap-y-3\.5 {
    row-gap: 0.35rem
  }

  .sm\:grid-flow-row {
    grid-auto-flow: row
  }

  .sm\:grid-flow-col {
    grid-auto-flow: column
  }

  .sm\:grid-flow-row-dense {
    grid-auto-flow: row dense
  }

  .sm\:grid-flow-col-dense {
    grid-auto-flow: column dense
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .sm\:grid-cols-none {
    grid-template-columns: none
  }

  .sm\:auto-cols-auto {
    grid-auto-columns: auto
  }

  .sm\:auto-cols-min {
    grid-auto-columns: min-content
  }

  .sm\:auto-cols-max {
    grid-auto-columns: max-content
  }

  .sm\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr)
  }

  .sm\:col-auto {
    grid-column: auto
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .sm\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .sm\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .sm\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .sm\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .sm\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .sm\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .sm\:col-span-full {
    grid-column: 1 / -1
  }

  .sm\:col-start-1 {
    grid-column-start: 1
  }

  .sm\:col-start-2 {
    grid-column-start: 2
  }

  .sm\:col-start-3 {
    grid-column-start: 3
  }

  .sm\:col-start-4 {
    grid-column-start: 4
  }

  .sm\:col-start-5 {
    grid-column-start: 5
  }

  .sm\:col-start-6 {
    grid-column-start: 6
  }

  .sm\:col-start-7 {
    grid-column-start: 7
  }

  .sm\:col-start-8 {
    grid-column-start: 8
  }

  .sm\:col-start-9 {
    grid-column-start: 9
  }

  .sm\:col-start-10 {
    grid-column-start: 10
  }

  .sm\:col-start-11 {
    grid-column-start: 11
  }

  .sm\:col-start-12 {
    grid-column-start: 12
  }

  .sm\:col-start-13 {
    grid-column-start: 13
  }

  .sm\:col-start-auto {
    grid-column-start: auto
  }

  .sm\:col-end-1 {
    grid-column-end: 1
  }

  .sm\:col-end-2 {
    grid-column-end: 2
  }

  .sm\:col-end-3 {
    grid-column-end: 3
  }

  .sm\:col-end-4 {
    grid-column-end: 4
  }

  .sm\:col-end-5 {
    grid-column-end: 5
  }

  .sm\:col-end-6 {
    grid-column-end: 6
  }

  .sm\:col-end-7 {
    grid-column-end: 7
  }

  .sm\:col-end-8 {
    grid-column-end: 8
  }

  .sm\:col-end-9 {
    grid-column-end: 9
  }

  .sm\:col-end-10 {
    grid-column-end: 10
  }

  .sm\:col-end-11 {
    grid-column-end: 11
  }

  .sm\:col-end-12 {
    grid-column-end: 12
  }

  .sm\:col-end-13 {
    grid-column-end: 13
  }

  .sm\:col-end-auto {
    grid-column-end: auto
  }

  .sm\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .sm\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .sm\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .sm\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .sm\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .sm\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .sm\:grid-rows-none {
    grid-template-rows: none
  }

  .sm\:auto-rows-auto {
    grid-auto-rows: auto
  }

  .sm\:auto-rows-min {
    grid-auto-rows: min-content
  }

  .sm\:auto-rows-max {
    grid-auto-rows: max-content
  }

  .sm\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr)
  }

  .sm\:row-auto {
    grid-row: auto
  }

  .sm\:row-span-1 {
    grid-row: span 1 / span 1
  }

  .sm\:row-span-2 {
    grid-row: span 2 / span 2
  }

  .sm\:row-span-3 {
    grid-row: span 3 / span 3
  }

  .sm\:row-span-4 {
    grid-row: span 4 / span 4
  }

  .sm\:row-span-5 {
    grid-row: span 5 / span 5
  }

  .sm\:row-span-6 {
    grid-row: span 6 / span 6
  }

  .sm\:row-span-full {
    grid-row: 1 / -1
  }

  .sm\:row-start-1 {
    grid-row-start: 1
  }

  .sm\:row-start-2 {
    grid-row-start: 2
  }

  .sm\:row-start-3 {
    grid-row-start: 3
  }

  .sm\:row-start-4 {
    grid-row-start: 4
  }

  .sm\:row-start-5 {
    grid-row-start: 5
  }

  .sm\:row-start-6 {
    grid-row-start: 6
  }

  .sm\:row-start-7 {
    grid-row-start: 7
  }

  .sm\:row-start-auto {
    grid-row-start: auto
  }

  .sm\:row-end-1 {
    grid-row-end: 1
  }

  .sm\:row-end-2 {
    grid-row-end: 2
  }

  .sm\:row-end-3 {
    grid-row-end: 3
  }

  .sm\:row-end-4 {
    grid-row-end: 4
  }

  .sm\:row-end-5 {
    grid-row-end: 5
  }

  .sm\:row-end-6 {
    grid-row-end: 6
  }

  .sm\:row-end-7 {
    grid-row-end: 7
  }

  .sm\:row-end-auto {
    grid-row-end: auto
  }

  .sm\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .sm\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .sm\:transform-none {
    transform: none
  }

  .sm\:origin-center {
    transform-origin: center
  }

  .sm\:origin-top {
    transform-origin: top
  }

  .sm\:origin-top-right {
    transform-origin: top right
  }

  .sm\:origin-right {
    transform-origin: right
  }

  .sm\:origin-bottom-right {
    transform-origin: bottom right
  }

  .sm\:origin-bottom {
    transform-origin: bottom
  }

  .sm\:origin-bottom-left {
    transform-origin: bottom left
  }

  .sm\:origin-left {
    transform-origin: left
  }

  .sm\:origin-top-left {
    transform-origin: top left
  }

  .sm\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .sm\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .sm\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .sm\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .sm\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .sm\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .sm\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .sm\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .sm\:scale-x-0 {
    --tw-scale-x: 0
  }

  .sm\:scale-x-50 {
    --tw-scale-x: .5
  }

  .sm\:scale-x-75 {
    --tw-scale-x: .75
  }

  .sm\:scale-x-90 {
    --tw-scale-x: .9
  }

  .sm\:scale-x-95 {
    --tw-scale-x: .95
  }

  .sm\:scale-x-100 {
    --tw-scale-x: 1
  }

  .sm\:scale-x-105 {
    --tw-scale-x: 1.05
  }

  .sm\:scale-x-110 {
    --tw-scale-x: 1.1
  }

  .sm\:scale-x-125 {
    --tw-scale-x: 1.25
  }

  .sm\:scale-x-150 {
    --tw-scale-x: 1.5
  }

  .sm\:scale-y-0 {
    --tw-scale-y: 0
  }

  .sm\:scale-y-50 {
    --tw-scale-y: .5
  }

  .sm\:scale-y-75 {
    --tw-scale-y: .75
  }

  .sm\:scale-y-90 {
    --tw-scale-y: .9
  }

  .sm\:scale-y-95 {
    --tw-scale-y: .95
  }

  .sm\:scale-y-100 {
    --tw-scale-y: 1
  }

  .sm\:scale-y-105 {
    --tw-scale-y: 1.05
  }

  .sm\:scale-y-110 {
    --tw-scale-y: 1.1
  }

  .sm\:scale-y-125 {
    --tw-scale-y: 1.25
  }

  .sm\:scale-y-150 {
    --tw-scale-y: 1.5
  }

  .sm\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .sm\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .sm\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .sm\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .sm\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .sm\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .sm\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .sm\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .sm\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .sm\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .sm\:hover\:scale-x-0:hover {
    --tw-scale-x: 0
  }

  .sm\:hover\:scale-x-50:hover {
    --tw-scale-x: .5
  }

  .sm\:hover\:scale-x-75:hover {
    --tw-scale-x: .75
  }

  .sm\:hover\:scale-x-90:hover {
    --tw-scale-x: .9
  }

  .sm\:hover\:scale-x-95:hover {
    --tw-scale-x: .95
  }

  .sm\:hover\:scale-x-100:hover {
    --tw-scale-x: 1
  }

  .sm\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05
  }

  .sm\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1
  }

  .sm\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25
  }

  .sm\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5
  }

  .sm\:hover\:scale-y-0:hover {
    --tw-scale-y: 0
  }

  .sm\:hover\:scale-y-50:hover {
    --tw-scale-y: .5
  }

  .sm\:hover\:scale-y-75:hover {
    --tw-scale-y: .75
  }

  .sm\:hover\:scale-y-90:hover {
    --tw-scale-y: .9
  }

  .sm\:hover\:scale-y-95:hover {
    --tw-scale-y: .95
  }

  .sm\:hover\:scale-y-100:hover {
    --tw-scale-y: 1
  }

  .sm\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05
  }

  .sm\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1
  }

  .sm\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25
  }

  .sm\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5
  }

  .sm\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .sm\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .sm\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .sm\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .sm\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .sm\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .sm\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .sm\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .sm\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .sm\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .sm\:focus\:scale-x-0:focus {
    --tw-scale-x: 0
  }

  .sm\:focus\:scale-x-50:focus {
    --tw-scale-x: .5
  }

  .sm\:focus\:scale-x-75:focus {
    --tw-scale-x: .75
  }

  .sm\:focus\:scale-x-90:focus {
    --tw-scale-x: .9
  }

  .sm\:focus\:scale-x-95:focus {
    --tw-scale-x: .95
  }

  .sm\:focus\:scale-x-100:focus {
    --tw-scale-x: 1
  }

  .sm\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05
  }

  .sm\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1
  }

  .sm\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25
  }

  .sm\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5
  }

  .sm\:focus\:scale-y-0:focus {
    --tw-scale-y: 0
  }

  .sm\:focus\:scale-y-50:focus {
    --tw-scale-y: .5
  }

  .sm\:focus\:scale-y-75:focus {
    --tw-scale-y: .75
  }

  .sm\:focus\:scale-y-90:focus {
    --tw-scale-y: .9
  }

  .sm\:focus\:scale-y-95:focus {
    --tw-scale-y: .95
  }

  .sm\:focus\:scale-y-100:focus {
    --tw-scale-y: 1
  }

  .sm\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05
  }

  .sm\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1
  }

  .sm\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25
  }

  .sm\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5
  }

  .sm\:rotate-0 {
    --tw-rotate: 0deg
  }

  .sm\:rotate-1 {
    --tw-rotate: 1deg
  }

  .sm\:rotate-2 {
    --tw-rotate: 2deg
  }

  .sm\:rotate-3 {
    --tw-rotate: 3deg
  }

  .sm\:rotate-6 {
    --tw-rotate: 6deg
  }

  .sm\:rotate-12 {
    --tw-rotate: 12deg
  }

  .sm\:rotate-45 {
    --tw-rotate: 45deg
  }

  .sm\:rotate-90 {
    --tw-rotate: 90deg
  }

  .sm\:rotate-180 {
    --tw-rotate: 180deg
  }

  .sm\:-rotate-180 {
    --tw-rotate: -180deg
  }

  .sm\:-rotate-90 {
    --tw-rotate: -90deg
  }

  .sm\:-rotate-45 {
    --tw-rotate: -45deg
  }

  .sm\:-rotate-12 {
    --tw-rotate: -12deg
  }

  .sm\:-rotate-6 {
    --tw-rotate: -6deg
  }

  .sm\:-rotate-3 {
    --tw-rotate: -3deg
  }

  .sm\:-rotate-2 {
    --tw-rotate: -2deg
  }

  .sm\:-rotate-1 {
    --tw-rotate: -1deg
  }

  .sm\:hover\:rotate-0:hover {
    --tw-rotate: 0deg
  }

  .sm\:hover\:rotate-1:hover {
    --tw-rotate: 1deg
  }

  .sm\:hover\:rotate-2:hover {
    --tw-rotate: 2deg
  }

  .sm\:hover\:rotate-3:hover {
    --tw-rotate: 3deg
  }

  .sm\:hover\:rotate-6:hover {
    --tw-rotate: 6deg
  }

  .sm\:hover\:rotate-12:hover {
    --tw-rotate: 12deg
  }

  .sm\:hover\:rotate-45:hover {
    --tw-rotate: 45deg
  }

  .sm\:hover\:rotate-90:hover {
    --tw-rotate: 90deg
  }

  .sm\:hover\:rotate-180:hover {
    --tw-rotate: 180deg
  }

  .sm\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg
  }

  .sm\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg
  }

  .sm\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg
  }

  .sm\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg
  }

  .sm\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg
  }

  .sm\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg
  }

  .sm\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg
  }

  .sm\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg
  }

  .sm\:focus\:rotate-0:focus {
    --tw-rotate: 0deg
  }

  .sm\:focus\:rotate-1:focus {
    --tw-rotate: 1deg
  }

  .sm\:focus\:rotate-2:focus {
    --tw-rotate: 2deg
  }

  .sm\:focus\:rotate-3:focus {
    --tw-rotate: 3deg
  }

  .sm\:focus\:rotate-6:focus {
    --tw-rotate: 6deg
  }

  .sm\:focus\:rotate-12:focus {
    --tw-rotate: 12deg
  }

  .sm\:focus\:rotate-45:focus {
    --tw-rotate: 45deg
  }

  .sm\:focus\:rotate-90:focus {
    --tw-rotate: 90deg
  }

  .sm\:focus\:rotate-180:focus {
    --tw-rotate: 180deg
  }

  .sm\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg
  }

  .sm\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg
  }

  .sm\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg
  }

  .sm\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg
  }

  .sm\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg
  }

  .sm\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg
  }

  .sm\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg
  }

  .sm\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg
  }

  .sm\:translate-x-0 {
    --tw-translate-x: 0
  }

  .sm\:translate-x-1 {
    --tw-translate-x: 0.1rem
  }

  .sm\:translate-x-2 {
    --tw-translate-x: 0.2rem
  }

  .sm\:translate-x-3 {
    --tw-translate-x: 0.3rem
  }

  .sm\:translate-x-4 {
    --tw-translate-x: 0.4rem
  }

  .sm\:translate-x-5 {
    --tw-translate-x: 0.5rem
  }

  .sm\:translate-x-6 {
    --tw-translate-x: 0.6rem
  }

  .sm\:translate-x-7 {
    --tw-translate-x: 0.7rem
  }

  .sm\:translate-x-8 {
    --tw-translate-x: 0.8rem
  }

  .sm\:translate-x-9 {
    --tw-translate-x: 0.9rem
  }

  .sm\:translate-x-10 {
    --tw-translate-x: 1.0rem
  }

  .sm\:translate-x-12 {
    --tw-translate-x: 1.2rem
  }

  .sm\:translate-x-14 {
    --tw-translate-x: 1.4rem
  }

  .sm\:translate-x-16 {
    --tw-translate-x: 1.6rem
  }

  .sm\:translate-x-20 {
    --tw-translate-x: 2rem
  }

  .sm\:translate-x-24 {
    --tw-translate-x: 2.4rem
  }

  .sm\:translate-x-28 {
    --tw-translate-x: 2.8rem
  }

  .sm\:translate-x-32 {
    --tw-translate-x: 3.2rem
  }

  .sm\:translate-x-36 {
    --tw-translate-x: 3.6rem
  }

  .sm\:translate-x-40 {
    --tw-translate-x: 4rem
  }

  .sm\:translate-x-44 {
    --tw-translate-x: 4.4rem
  }

  .sm\:translate-x-48 {
    --tw-translate-x: 4.8rem
  }

  .sm\:translate-x-52 {
    --tw-translate-x: 5.2rem
  }

  .sm\:translate-x-56 {
    --tw-translate-x: 5.6rem
  }

  .sm\:translate-x-60 {
    --tw-translate-x: 6rem
  }

  .sm\:translate-x-64 {
    --tw-translate-x: 6.4rem
  }

  .sm\:translate-x-68 {
    --tw-translate-x: 6.8rem
  }

  .sm\:translate-x-72 {
    --tw-translate-x: 7.2rem
  }

  .sm\:translate-x-76 {
    --tw-translate-x: 7.6rem
  }

  .sm\:translate-x-80 {
    --tw-translate-x: 8rem
  }

  .sm\:translate-x-84 {
    --tw-translate-x: 8.4rem
  }

  .sm\:translate-x-88 {
    --tw-translate-x: 8.8rem
  }

  .sm\:translate-x-92 {
    --tw-translate-x: 9.2rem
  }

  .sm\:translate-x-96 {
    --tw-translate-x: 9.6rem
  }

  .sm\:translate-x-128 {
    --tw-translate-x: 12.8rem
  }

  .sm\:translate-x-136 {
    --tw-translate-x: 13.6rem
  }

  .sm\:translate-x-160 {
    --tw-translate-x: 16rem
  }

  .sm\:translate-x-192 {
    --tw-translate-x: 19.2rem
  }

  .sm\:translate-x-200 {
    --tw-translate-x: 20rem
  }

  .sm\:translate-x-208 {
    --tw-translate-x: 20.8rem
  }

  .sm\:translate-x-216 {
    --tw-translate-x: 21.6rem
  }

  .sm\:translate-x-224 {
    --tw-translate-x: 22.4rem
  }

  .sm\:translate-x-256 {
    --tw-translate-x: 25.6rem
  }

  .sm\:translate-x-288 {
    --tw-translate-x: 28.8rem
  }

  .sm\:translate-x-320 {
    --tw-translate-x: 32rem
  }

  .sm\:translate-x-360 {
    --tw-translate-x: 36rem
  }

  .sm\:translate-x-384 {
    --tw-translate-x: 38.4rem
  }

  .sm\:translate-x-400 {
    --tw-translate-x: 40rem
  }

  .sm\:translate-x-512 {
    --tw-translate-x: 51.2rem
  }

  .sm\:translate-x-640 {
    --tw-translate-x: 64rem
  }

  .sm\:translate-x-xs {
    --tw-translate-x: 32rem
  }

  .sm\:translate-x-sm {
    --tw-translate-x: 48rem
  }

  .sm\:translate-x-md {
    --tw-translate-x: 64rem
  }

  .sm\:translate-x-lg {
    --tw-translate-x: 80rem
  }

  .sm\:translate-x-xl {
    --tw-translate-x: 96rem
  }

  .sm\:translate-x-2xl {
    --tw-translate-x: 112rem
  }

  .sm\:translate-x-3xl {
    --tw-translate-x: 128rem
  }

  .sm\:translate-x-4xl {
    --tw-translate-x: 144rem
  }

  .sm\:translate-x-5xl {
    --tw-translate-x: 160rem
  }

  .sm\:translate-x-px {
    --tw-translate-x: 1px
  }

  .sm\:translate-x-0\.5 {
    --tw-translate-x: 0.05rem
  }

  .sm\:translate-x-1\.5 {
    --tw-translate-x: 0.15rem
  }

  .sm\:translate-x-2\.5 {
    --tw-translate-x: 0.25rem
  }

  .sm\:translate-x-3\.5 {
    --tw-translate-x: 0.35rem
  }

  .sm\:-translate-x-1 {
    --tw-translate-x: -0.1rem
  }

  .sm\:-translate-x-2 {
    --tw-translate-x: -0.2rem
  }

  .sm\:-translate-x-3 {
    --tw-translate-x: -0.3rem
  }

  .sm\:-translate-x-4 {
    --tw-translate-x: -0.4rem
  }

  .sm\:-translate-x-5 {
    --tw-translate-x: -0.5rem
  }

  .sm\:-translate-x-6 {
    --tw-translate-x: -0.6rem
  }

  .sm\:-translate-x-7 {
    --tw-translate-x: -0.7rem
  }

  .sm\:-translate-x-8 {
    --tw-translate-x: -0.8rem
  }

  .sm\:-translate-x-9 {
    --tw-translate-x: -0.9rem
  }

  .sm\:-translate-x-10 {
    --tw-translate-x: -1rem
  }

  .sm\:-translate-x-12 {
    --tw-translate-x: -1.2rem
  }

  .sm\:-translate-x-14 {
    --tw-translate-x: -1.4rem
  }

  .sm\:-translate-x-16 {
    --tw-translate-x: -1.6rem
  }

  .sm\:-translate-x-20 {
    --tw-translate-x: -2rem
  }

  .sm\:-translate-x-24 {
    --tw-translate-x: -2.4rem
  }

  .sm\:-translate-x-28 {
    --tw-translate-x: -2.8rem
  }

  .sm\:-translate-x-32 {
    --tw-translate-x: -3.2rem
  }

  .sm\:-translate-x-36 {
    --tw-translate-x: -3.6rem
  }

  .sm\:-translate-x-40 {
    --tw-translate-x: -4rem
  }

  .sm\:-translate-x-44 {
    --tw-translate-x: -4.4rem
  }

  .sm\:-translate-x-48 {
    --tw-translate-x: -4.8rem
  }

  .sm\:-translate-x-52 {
    --tw-translate-x: -5.2rem
  }

  .sm\:-translate-x-56 {
    --tw-translate-x: -5.6rem
  }

  .sm\:-translate-x-60 {
    --tw-translate-x: -6rem
  }

  .sm\:-translate-x-64 {
    --tw-translate-x: -6.4rem
  }

  .sm\:-translate-x-68 {
    --tw-translate-x: -6.8rem
  }

  .sm\:-translate-x-72 {
    --tw-translate-x: -7.2rem
  }

  .sm\:-translate-x-76 {
    --tw-translate-x: -7.6rem
  }

  .sm\:-translate-x-80 {
    --tw-translate-x: -8rem
  }

  .sm\:-translate-x-84 {
    --tw-translate-x: -8.4rem
  }

  .sm\:-translate-x-88 {
    --tw-translate-x: -8.8rem
  }

  .sm\:-translate-x-92 {
    --tw-translate-x: -9.2rem
  }

  .sm\:-translate-x-96 {
    --tw-translate-x: -9.6rem
  }

  .sm\:-translate-x-128 {
    --tw-translate-x: -12.8rem
  }

  .sm\:-translate-x-136 {
    --tw-translate-x: -13.6rem
  }

  .sm\:-translate-x-160 {
    --tw-translate-x: -16rem
  }

  .sm\:-translate-x-192 {
    --tw-translate-x: -19.2rem
  }

  .sm\:-translate-x-200 {
    --tw-translate-x: -20rem
  }

  .sm\:-translate-x-208 {
    --tw-translate-x: -20.8rem
  }

  .sm\:-translate-x-216 {
    --tw-translate-x: -21.6rem
  }

  .sm\:-translate-x-224 {
    --tw-translate-x: -22.4rem
  }

  .sm\:-translate-x-256 {
    --tw-translate-x: -25.6rem
  }

  .sm\:-translate-x-288 {
    --tw-translate-x: -28.8rem
  }

  .sm\:-translate-x-320 {
    --tw-translate-x: -32rem
  }

  .sm\:-translate-x-360 {
    --tw-translate-x: -36rem
  }

  .sm\:-translate-x-384 {
    --tw-translate-x: -38.4rem
  }

  .sm\:-translate-x-400 {
    --tw-translate-x: -40rem
  }

  .sm\:-translate-x-512 {
    --tw-translate-x: -51.2rem
  }

  .sm\:-translate-x-640 {
    --tw-translate-x: -64rem
  }

  .sm\:-translate-x-xs {
    --tw-translate-x: -32rem
  }

  .sm\:-translate-x-sm {
    --tw-translate-x: -48rem
  }

  .sm\:-translate-x-md {
    --tw-translate-x: -64rem
  }

  .sm\:-translate-x-lg {
    --tw-translate-x: -80rem
  }

  .sm\:-translate-x-xl {
    --tw-translate-x: -96rem
  }

  .sm\:-translate-x-2xl {
    --tw-translate-x: -112rem
  }

  .sm\:-translate-x-3xl {
    --tw-translate-x: -128rem
  }

  .sm\:-translate-x-4xl {
    --tw-translate-x: -144rem
  }

  .sm\:-translate-x-5xl {
    --tw-translate-x: -160rem
  }

  .sm\:-translate-x-px {
    --tw-translate-x: -1px
  }

  .sm\:-translate-x-0\.5 {
    --tw-translate-x: -0.05rem
  }

  .sm\:-translate-x-1\.5 {
    --tw-translate-x: -0.15rem
  }

  .sm\:-translate-x-2\.5 {
    --tw-translate-x: -0.25rem
  }

  .sm\:-translate-x-3\.5 {
    --tw-translate-x: -0.35rem
  }

  .sm\:translate-x-1\/2 {
    --tw-translate-x: 50%
  }

  .sm\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%
  }

  .sm\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%
  }

  .sm\:translate-x-1\/4 {
    --tw-translate-x: 25%
  }

  .sm\:translate-x-2\/4 {
    --tw-translate-x: 50%
  }

  .sm\:translate-x-3\/4 {
    --tw-translate-x: 75%
  }

  .sm\:translate-x-full {
    --tw-translate-x: 100%
  }

  .sm\:-translate-x-1\/2 {
    --tw-translate-x: -50%
  }

  .sm\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%
  }

  .sm\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%
  }

  .sm\:-translate-x-1\/4 {
    --tw-translate-x: -25%
  }

  .sm\:-translate-x-2\/4 {
    --tw-translate-x: -50%
  }

  .sm\:-translate-x-3\/4 {
    --tw-translate-x: -75%
  }

  .sm\:-translate-x-full {
    --tw-translate-x: -100%
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0
  }

  .sm\:translate-y-1 {
    --tw-translate-y: 0.1rem
  }

  .sm\:translate-y-2 {
    --tw-translate-y: 0.2rem
  }

  .sm\:translate-y-3 {
    --tw-translate-y: 0.3rem
  }

  .sm\:translate-y-4 {
    --tw-translate-y: 0.4rem
  }

  .sm\:translate-y-5 {
    --tw-translate-y: 0.5rem
  }

  .sm\:translate-y-6 {
    --tw-translate-y: 0.6rem
  }

  .sm\:translate-y-7 {
    --tw-translate-y: 0.7rem
  }

  .sm\:translate-y-8 {
    --tw-translate-y: 0.8rem
  }

  .sm\:translate-y-9 {
    --tw-translate-y: 0.9rem
  }

  .sm\:translate-y-10 {
    --tw-translate-y: 1.0rem
  }

  .sm\:translate-y-12 {
    --tw-translate-y: 1.2rem
  }

  .sm\:translate-y-14 {
    --tw-translate-y: 1.4rem
  }

  .sm\:translate-y-16 {
    --tw-translate-y: 1.6rem
  }

  .sm\:translate-y-20 {
    --tw-translate-y: 2rem
  }

  .sm\:translate-y-24 {
    --tw-translate-y: 2.4rem
  }

  .sm\:translate-y-28 {
    --tw-translate-y: 2.8rem
  }

  .sm\:translate-y-32 {
    --tw-translate-y: 3.2rem
  }

  .sm\:translate-y-36 {
    --tw-translate-y: 3.6rem
  }

  .sm\:translate-y-40 {
    --tw-translate-y: 4rem
  }

  .sm\:translate-y-44 {
    --tw-translate-y: 4.4rem
  }

  .sm\:translate-y-48 {
    --tw-translate-y: 4.8rem
  }

  .sm\:translate-y-52 {
    --tw-translate-y: 5.2rem
  }

  .sm\:translate-y-56 {
    --tw-translate-y: 5.6rem
  }

  .sm\:translate-y-60 {
    --tw-translate-y: 6rem
  }

  .sm\:translate-y-64 {
    --tw-translate-y: 6.4rem
  }

  .sm\:translate-y-68 {
    --tw-translate-y: 6.8rem
  }

  .sm\:translate-y-72 {
    --tw-translate-y: 7.2rem
  }

  .sm\:translate-y-76 {
    --tw-translate-y: 7.6rem
  }

  .sm\:translate-y-80 {
    --tw-translate-y: 8rem
  }

  .sm\:translate-y-84 {
    --tw-translate-y: 8.4rem
  }

  .sm\:translate-y-88 {
    --tw-translate-y: 8.8rem
  }

  .sm\:translate-y-92 {
    --tw-translate-y: 9.2rem
  }

  .sm\:translate-y-96 {
    --tw-translate-y: 9.6rem
  }

  .sm\:translate-y-128 {
    --tw-translate-y: 12.8rem
  }

  .sm\:translate-y-136 {
    --tw-translate-y: 13.6rem
  }

  .sm\:translate-y-160 {
    --tw-translate-y: 16rem
  }

  .sm\:translate-y-192 {
    --tw-translate-y: 19.2rem
  }

  .sm\:translate-y-200 {
    --tw-translate-y: 20rem
  }

  .sm\:translate-y-208 {
    --tw-translate-y: 20.8rem
  }

  .sm\:translate-y-216 {
    --tw-translate-y: 21.6rem
  }

  .sm\:translate-y-224 {
    --tw-translate-y: 22.4rem
  }

  .sm\:translate-y-256 {
    --tw-translate-y: 25.6rem
  }

  .sm\:translate-y-288 {
    --tw-translate-y: 28.8rem
  }

  .sm\:translate-y-320 {
    --tw-translate-y: 32rem
  }

  .sm\:translate-y-360 {
    --tw-translate-y: 36rem
  }

  .sm\:translate-y-384 {
    --tw-translate-y: 38.4rem
  }

  .sm\:translate-y-400 {
    --tw-translate-y: 40rem
  }

  .sm\:translate-y-512 {
    --tw-translate-y: 51.2rem
  }

  .sm\:translate-y-640 {
    --tw-translate-y: 64rem
  }

  .sm\:translate-y-xs {
    --tw-translate-y: 32rem
  }

  .sm\:translate-y-sm {
    --tw-translate-y: 48rem
  }

  .sm\:translate-y-md {
    --tw-translate-y: 64rem
  }

  .sm\:translate-y-lg {
    --tw-translate-y: 80rem
  }

  .sm\:translate-y-xl {
    --tw-translate-y: 96rem
  }

  .sm\:translate-y-2xl {
    --tw-translate-y: 112rem
  }

  .sm\:translate-y-3xl {
    --tw-translate-y: 128rem
  }

  .sm\:translate-y-4xl {
    --tw-translate-y: 144rem
  }

  .sm\:translate-y-5xl {
    --tw-translate-y: 160rem
  }

  .sm\:translate-y-px {
    --tw-translate-y: 1px
  }

  .sm\:translate-y-0\.5 {
    --tw-translate-y: 0.05rem
  }

  .sm\:translate-y-1\.5 {
    --tw-translate-y: 0.15rem
  }

  .sm\:translate-y-2\.5 {
    --tw-translate-y: 0.25rem
  }

  .sm\:translate-y-3\.5 {
    --tw-translate-y: 0.35rem
  }

  .sm\:-translate-y-1 {
    --tw-translate-y: -0.1rem
  }

  .sm\:-translate-y-2 {
    --tw-translate-y: -0.2rem
  }

  .sm\:-translate-y-3 {
    --tw-translate-y: -0.3rem
  }

  .sm\:-translate-y-4 {
    --tw-translate-y: -0.4rem
  }

  .sm\:-translate-y-5 {
    --tw-translate-y: -0.5rem
  }

  .sm\:-translate-y-6 {
    --tw-translate-y: -0.6rem
  }

  .sm\:-translate-y-7 {
    --tw-translate-y: -0.7rem
  }

  .sm\:-translate-y-8 {
    --tw-translate-y: -0.8rem
  }

  .sm\:-translate-y-9 {
    --tw-translate-y: -0.9rem
  }

  .sm\:-translate-y-10 {
    --tw-translate-y: -1rem
  }

  .sm\:-translate-y-12 {
    --tw-translate-y: -1.2rem
  }

  .sm\:-translate-y-14 {
    --tw-translate-y: -1.4rem
  }

  .sm\:-translate-y-16 {
    --tw-translate-y: -1.6rem
  }

  .sm\:-translate-y-20 {
    --tw-translate-y: -2rem
  }

  .sm\:-translate-y-24 {
    --tw-translate-y: -2.4rem
  }

  .sm\:-translate-y-28 {
    --tw-translate-y: -2.8rem
  }

  .sm\:-translate-y-32 {
    --tw-translate-y: -3.2rem
  }

  .sm\:-translate-y-36 {
    --tw-translate-y: -3.6rem
  }

  .sm\:-translate-y-40 {
    --tw-translate-y: -4rem
  }

  .sm\:-translate-y-44 {
    --tw-translate-y: -4.4rem
  }

  .sm\:-translate-y-48 {
    --tw-translate-y: -4.8rem
  }

  .sm\:-translate-y-52 {
    --tw-translate-y: -5.2rem
  }

  .sm\:-translate-y-56 {
    --tw-translate-y: -5.6rem
  }

  .sm\:-translate-y-60 {
    --tw-translate-y: -6rem
  }

  .sm\:-translate-y-64 {
    --tw-translate-y: -6.4rem
  }

  .sm\:-translate-y-68 {
    --tw-translate-y: -6.8rem
  }

  .sm\:-translate-y-72 {
    --tw-translate-y: -7.2rem
  }

  .sm\:-translate-y-76 {
    --tw-translate-y: -7.6rem
  }

  .sm\:-translate-y-80 {
    --tw-translate-y: -8rem
  }

  .sm\:-translate-y-84 {
    --tw-translate-y: -8.4rem
  }

  .sm\:-translate-y-88 {
    --tw-translate-y: -8.8rem
  }

  .sm\:-translate-y-92 {
    --tw-translate-y: -9.2rem
  }

  .sm\:-translate-y-96 {
    --tw-translate-y: -9.6rem
  }

  .sm\:-translate-y-128 {
    --tw-translate-y: -12.8rem
  }

  .sm\:-translate-y-136 {
    --tw-translate-y: -13.6rem
  }

  .sm\:-translate-y-160 {
    --tw-translate-y: -16rem
  }

  .sm\:-translate-y-192 {
    --tw-translate-y: -19.2rem
  }

  .sm\:-translate-y-200 {
    --tw-translate-y: -20rem
  }

  .sm\:-translate-y-208 {
    --tw-translate-y: -20.8rem
  }

  .sm\:-translate-y-216 {
    --tw-translate-y: -21.6rem
  }

  .sm\:-translate-y-224 {
    --tw-translate-y: -22.4rem
  }

  .sm\:-translate-y-256 {
    --tw-translate-y: -25.6rem
  }

  .sm\:-translate-y-288 {
    --tw-translate-y: -28.8rem
  }

  .sm\:-translate-y-320 {
    --tw-translate-y: -32rem
  }

  .sm\:-translate-y-360 {
    --tw-translate-y: -36rem
  }

  .sm\:-translate-y-384 {
    --tw-translate-y: -38.4rem
  }

  .sm\:-translate-y-400 {
    --tw-translate-y: -40rem
  }

  .sm\:-translate-y-512 {
    --tw-translate-y: -51.2rem
  }

  .sm\:-translate-y-640 {
    --tw-translate-y: -64rem
  }

  .sm\:-translate-y-xs {
    --tw-translate-y: -32rem
  }

  .sm\:-translate-y-sm {
    --tw-translate-y: -48rem
  }

  .sm\:-translate-y-md {
    --tw-translate-y: -64rem
  }

  .sm\:-translate-y-lg {
    --tw-translate-y: -80rem
  }

  .sm\:-translate-y-xl {
    --tw-translate-y: -96rem
  }

  .sm\:-translate-y-2xl {
    --tw-translate-y: -112rem
  }

  .sm\:-translate-y-3xl {
    --tw-translate-y: -128rem
  }

  .sm\:-translate-y-4xl {
    --tw-translate-y: -144rem
  }

  .sm\:-translate-y-5xl {
    --tw-translate-y: -160rem
  }

  .sm\:-translate-y-px {
    --tw-translate-y: -1px
  }

  .sm\:-translate-y-0\.5 {
    --tw-translate-y: -0.05rem
  }

  .sm\:-translate-y-1\.5 {
    --tw-translate-y: -0.15rem
  }

  .sm\:-translate-y-2\.5 {
    --tw-translate-y: -0.25rem
  }

  .sm\:-translate-y-3\.5 {
    --tw-translate-y: -0.35rem
  }

  .sm\:translate-y-1\/2 {
    --tw-translate-y: 50%
  }

  .sm\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%
  }

  .sm\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%
  }

  .sm\:translate-y-1\/4 {
    --tw-translate-y: 25%
  }

  .sm\:translate-y-2\/4 {
    --tw-translate-y: 50%
  }

  .sm\:translate-y-3\/4 {
    --tw-translate-y: 75%
  }

  .sm\:translate-y-full {
    --tw-translate-y: 100%
  }

  .sm\:-translate-y-1\/2 {
    --tw-translate-y: -50%
  }

  .sm\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%
  }

  .sm\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%
  }

  .sm\:-translate-y-1\/4 {
    --tw-translate-y: -25%
  }

  .sm\:-translate-y-2\/4 {
    --tw-translate-y: -50%
  }

  .sm\:-translate-y-3\/4 {
    --tw-translate-y: -75%
  }

  .sm\:-translate-y-full {
    --tw-translate-y: -100%
  }

  .sm\:hover\:translate-x-0:hover {
    --tw-translate-x: 0
  }

  .sm\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.1rem
  }

  .sm\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.2rem
  }

  .sm\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.3rem
  }

  .sm\:hover\:translate-x-4:hover {
    --tw-translate-x: 0.4rem
  }

  .sm\:hover\:translate-x-5:hover {
    --tw-translate-x: 0.5rem
  }

  .sm\:hover\:translate-x-6:hover {
    --tw-translate-x: 0.6rem
  }

  .sm\:hover\:translate-x-7:hover {
    --tw-translate-x: 0.7rem
  }

  .sm\:hover\:translate-x-8:hover {
    --tw-translate-x: 0.8rem
  }

  .sm\:hover\:translate-x-9:hover {
    --tw-translate-x: 0.9rem
  }

  .sm\:hover\:translate-x-10:hover {
    --tw-translate-x: 1.0rem
  }

  .sm\:hover\:translate-x-12:hover {
    --tw-translate-x: 1.2rem
  }

  .sm\:hover\:translate-x-14:hover {
    --tw-translate-x: 1.4rem
  }

  .sm\:hover\:translate-x-16:hover {
    --tw-translate-x: 1.6rem
  }

  .sm\:hover\:translate-x-20:hover {
    --tw-translate-x: 2rem
  }

  .sm\:hover\:translate-x-24:hover {
    --tw-translate-x: 2.4rem
  }

  .sm\:hover\:translate-x-28:hover {
    --tw-translate-x: 2.8rem
  }

  .sm\:hover\:translate-x-32:hover {
    --tw-translate-x: 3.2rem
  }

  .sm\:hover\:translate-x-36:hover {
    --tw-translate-x: 3.6rem
  }

  .sm\:hover\:translate-x-40:hover {
    --tw-translate-x: 4rem
  }

  .sm\:hover\:translate-x-44:hover {
    --tw-translate-x: 4.4rem
  }

  .sm\:hover\:translate-x-48:hover {
    --tw-translate-x: 4.8rem
  }

  .sm\:hover\:translate-x-52:hover {
    --tw-translate-x: 5.2rem
  }

  .sm\:hover\:translate-x-56:hover {
    --tw-translate-x: 5.6rem
  }

  .sm\:hover\:translate-x-60:hover {
    --tw-translate-x: 6rem
  }

  .sm\:hover\:translate-x-64:hover {
    --tw-translate-x: 6.4rem
  }

  .sm\:hover\:translate-x-68:hover {
    --tw-translate-x: 6.8rem
  }

  .sm\:hover\:translate-x-72:hover {
    --tw-translate-x: 7.2rem
  }

  .sm\:hover\:translate-x-76:hover {
    --tw-translate-x: 7.6rem
  }

  .sm\:hover\:translate-x-80:hover {
    --tw-translate-x: 8rem
  }

  .sm\:hover\:translate-x-84:hover {
    --tw-translate-x: 8.4rem
  }

  .sm\:hover\:translate-x-88:hover {
    --tw-translate-x: 8.8rem
  }

  .sm\:hover\:translate-x-92:hover {
    --tw-translate-x: 9.2rem
  }

  .sm\:hover\:translate-x-96:hover {
    --tw-translate-x: 9.6rem
  }

  .sm\:hover\:translate-x-128:hover {
    --tw-translate-x: 12.8rem
  }

  .sm\:hover\:translate-x-136:hover {
    --tw-translate-x: 13.6rem
  }

  .sm\:hover\:translate-x-160:hover {
    --tw-translate-x: 16rem
  }

  .sm\:hover\:translate-x-192:hover {
    --tw-translate-x: 19.2rem
  }

  .sm\:hover\:translate-x-200:hover {
    --tw-translate-x: 20rem
  }

  .sm\:hover\:translate-x-208:hover {
    --tw-translate-x: 20.8rem
  }

  .sm\:hover\:translate-x-216:hover {
    --tw-translate-x: 21.6rem
  }

  .sm\:hover\:translate-x-224:hover {
    --tw-translate-x: 22.4rem
  }

  .sm\:hover\:translate-x-256:hover {
    --tw-translate-x: 25.6rem
  }

  .sm\:hover\:translate-x-288:hover {
    --tw-translate-x: 28.8rem
  }

  .sm\:hover\:translate-x-320:hover {
    --tw-translate-x: 32rem
  }

  .sm\:hover\:translate-x-360:hover {
    --tw-translate-x: 36rem
  }

  .sm\:hover\:translate-x-384:hover {
    --tw-translate-x: 38.4rem
  }

  .sm\:hover\:translate-x-400:hover {
    --tw-translate-x: 40rem
  }

  .sm\:hover\:translate-x-512:hover {
    --tw-translate-x: 51.2rem
  }

  .sm\:hover\:translate-x-640:hover {
    --tw-translate-x: 64rem
  }

  .sm\:hover\:translate-x-xs:hover {
    --tw-translate-x: 32rem
  }

  .sm\:hover\:translate-x-sm:hover {
    --tw-translate-x: 48rem
  }

  .sm\:hover\:translate-x-md:hover {
    --tw-translate-x: 64rem
  }

  .sm\:hover\:translate-x-lg:hover {
    --tw-translate-x: 80rem
  }

  .sm\:hover\:translate-x-xl:hover {
    --tw-translate-x: 96rem
  }

  .sm\:hover\:translate-x-2xl:hover {
    --tw-translate-x: 112rem
  }

  .sm\:hover\:translate-x-3xl:hover {
    --tw-translate-x: 128rem
  }

  .sm\:hover\:translate-x-4xl:hover {
    --tw-translate-x: 144rem
  }

  .sm\:hover\:translate-x-5xl:hover {
    --tw-translate-x: 160rem
  }

  .sm\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px
  }

  .sm\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.05rem
  }

  .sm\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.15rem
  }

  .sm\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.25rem
  }

  .sm\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.35rem
  }

  .sm\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.1rem
  }

  .sm\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.2rem
  }

  .sm\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.3rem
  }

  .sm\:hover\:-translate-x-4:hover {
    --tw-translate-x: -0.4rem
  }

  .sm\:hover\:-translate-x-5:hover {
    --tw-translate-x: -0.5rem
  }

  .sm\:hover\:-translate-x-6:hover {
    --tw-translate-x: -0.6rem
  }

  .sm\:hover\:-translate-x-7:hover {
    --tw-translate-x: -0.7rem
  }

  .sm\:hover\:-translate-x-8:hover {
    --tw-translate-x: -0.8rem
  }

  .sm\:hover\:-translate-x-9:hover {
    --tw-translate-x: -0.9rem
  }

  .sm\:hover\:-translate-x-10:hover {
    --tw-translate-x: -1rem
  }

  .sm\:hover\:-translate-x-12:hover {
    --tw-translate-x: -1.2rem
  }

  .sm\:hover\:-translate-x-14:hover {
    --tw-translate-x: -1.4rem
  }

  .sm\:hover\:-translate-x-16:hover {
    --tw-translate-x: -1.6rem
  }

  .sm\:hover\:-translate-x-20:hover {
    --tw-translate-x: -2rem
  }

  .sm\:hover\:-translate-x-24:hover {
    --tw-translate-x: -2.4rem
  }

  .sm\:hover\:-translate-x-28:hover {
    --tw-translate-x: -2.8rem
  }

  .sm\:hover\:-translate-x-32:hover {
    --tw-translate-x: -3.2rem
  }

  .sm\:hover\:-translate-x-36:hover {
    --tw-translate-x: -3.6rem
  }

  .sm\:hover\:-translate-x-40:hover {
    --tw-translate-x: -4rem
  }

  .sm\:hover\:-translate-x-44:hover {
    --tw-translate-x: -4.4rem
  }

  .sm\:hover\:-translate-x-48:hover {
    --tw-translate-x: -4.8rem
  }

  .sm\:hover\:-translate-x-52:hover {
    --tw-translate-x: -5.2rem
  }

  .sm\:hover\:-translate-x-56:hover {
    --tw-translate-x: -5.6rem
  }

  .sm\:hover\:-translate-x-60:hover {
    --tw-translate-x: -6rem
  }

  .sm\:hover\:-translate-x-64:hover {
    --tw-translate-x: -6.4rem
  }

  .sm\:hover\:-translate-x-68:hover {
    --tw-translate-x: -6.8rem
  }

  .sm\:hover\:-translate-x-72:hover {
    --tw-translate-x: -7.2rem
  }

  .sm\:hover\:-translate-x-76:hover {
    --tw-translate-x: -7.6rem
  }

  .sm\:hover\:-translate-x-80:hover {
    --tw-translate-x: -8rem
  }

  .sm\:hover\:-translate-x-84:hover {
    --tw-translate-x: -8.4rem
  }

  .sm\:hover\:-translate-x-88:hover {
    --tw-translate-x: -8.8rem
  }

  .sm\:hover\:-translate-x-92:hover {
    --tw-translate-x: -9.2rem
  }

  .sm\:hover\:-translate-x-96:hover {
    --tw-translate-x: -9.6rem
  }

  .sm\:hover\:-translate-x-128:hover {
    --tw-translate-x: -12.8rem
  }

  .sm\:hover\:-translate-x-136:hover {
    --tw-translate-x: -13.6rem
  }

  .sm\:hover\:-translate-x-160:hover {
    --tw-translate-x: -16rem
  }

  .sm\:hover\:-translate-x-192:hover {
    --tw-translate-x: -19.2rem
  }

  .sm\:hover\:-translate-x-200:hover {
    --tw-translate-x: -20rem
  }

  .sm\:hover\:-translate-x-208:hover {
    --tw-translate-x: -20.8rem
  }

  .sm\:hover\:-translate-x-216:hover {
    --tw-translate-x: -21.6rem
  }

  .sm\:hover\:-translate-x-224:hover {
    --tw-translate-x: -22.4rem
  }

  .sm\:hover\:-translate-x-256:hover {
    --tw-translate-x: -25.6rem
  }

  .sm\:hover\:-translate-x-288:hover {
    --tw-translate-x: -28.8rem
  }

  .sm\:hover\:-translate-x-320:hover {
    --tw-translate-x: -32rem
  }

  .sm\:hover\:-translate-x-360:hover {
    --tw-translate-x: -36rem
  }

  .sm\:hover\:-translate-x-384:hover {
    --tw-translate-x: -38.4rem
  }

  .sm\:hover\:-translate-x-400:hover {
    --tw-translate-x: -40rem
  }

  .sm\:hover\:-translate-x-512:hover {
    --tw-translate-x: -51.2rem
  }

  .sm\:hover\:-translate-x-640:hover {
    --tw-translate-x: -64rem
  }

  .sm\:hover\:-translate-x-xs:hover {
    --tw-translate-x: -32rem
  }

  .sm\:hover\:-translate-x-sm:hover {
    --tw-translate-x: -48rem
  }

  .sm\:hover\:-translate-x-md:hover {
    --tw-translate-x: -64rem
  }

  .sm\:hover\:-translate-x-lg:hover {
    --tw-translate-x: -80rem
  }

  .sm\:hover\:-translate-x-xl:hover {
    --tw-translate-x: -96rem
  }

  .sm\:hover\:-translate-x-2xl:hover {
    --tw-translate-x: -112rem
  }

  .sm\:hover\:-translate-x-3xl:hover {
    --tw-translate-x: -128rem
  }

  .sm\:hover\:-translate-x-4xl:hover {
    --tw-translate-x: -144rem
  }

  .sm\:hover\:-translate-x-5xl:hover {
    --tw-translate-x: -160rem
  }

  .sm\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px
  }

  .sm\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.05rem
  }

  .sm\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.15rem
  }

  .sm\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.25rem
  }

  .sm\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.35rem
  }

  .sm\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%
  }

  .sm\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%
  }

  .sm\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%
  }

  .sm\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%
  }

  .sm\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%
  }

  .sm\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%
  }

  .sm\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%
  }

  .sm\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%
  }

  .sm\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%
  }

  .sm\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%
  }

  .sm\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%
  }

  .sm\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%
  }

  .sm\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%
  }

  .sm\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%
  }

  .sm\:hover\:translate-y-0:hover {
    --tw-translate-y: 0
  }

  .sm\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.1rem
  }

  .sm\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.2rem
  }

  .sm\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.3rem
  }

  .sm\:hover\:translate-y-4:hover {
    --tw-translate-y: 0.4rem
  }

  .sm\:hover\:translate-y-5:hover {
    --tw-translate-y: 0.5rem
  }

  .sm\:hover\:translate-y-6:hover {
    --tw-translate-y: 0.6rem
  }

  .sm\:hover\:translate-y-7:hover {
    --tw-translate-y: 0.7rem
  }

  .sm\:hover\:translate-y-8:hover {
    --tw-translate-y: 0.8rem
  }

  .sm\:hover\:translate-y-9:hover {
    --tw-translate-y: 0.9rem
  }

  .sm\:hover\:translate-y-10:hover {
    --tw-translate-y: 1.0rem
  }

  .sm\:hover\:translate-y-12:hover {
    --tw-translate-y: 1.2rem
  }

  .sm\:hover\:translate-y-14:hover {
    --tw-translate-y: 1.4rem
  }

  .sm\:hover\:translate-y-16:hover {
    --tw-translate-y: 1.6rem
  }

  .sm\:hover\:translate-y-20:hover {
    --tw-translate-y: 2rem
  }

  .sm\:hover\:translate-y-24:hover {
    --tw-translate-y: 2.4rem
  }

  .sm\:hover\:translate-y-28:hover {
    --tw-translate-y: 2.8rem
  }

  .sm\:hover\:translate-y-32:hover {
    --tw-translate-y: 3.2rem
  }

  .sm\:hover\:translate-y-36:hover {
    --tw-translate-y: 3.6rem
  }

  .sm\:hover\:translate-y-40:hover {
    --tw-translate-y: 4rem
  }

  .sm\:hover\:translate-y-44:hover {
    --tw-translate-y: 4.4rem
  }

  .sm\:hover\:translate-y-48:hover {
    --tw-translate-y: 4.8rem
  }

  .sm\:hover\:translate-y-52:hover {
    --tw-translate-y: 5.2rem
  }

  .sm\:hover\:translate-y-56:hover {
    --tw-translate-y: 5.6rem
  }

  .sm\:hover\:translate-y-60:hover {
    --tw-translate-y: 6rem
  }

  .sm\:hover\:translate-y-64:hover {
    --tw-translate-y: 6.4rem
  }

  .sm\:hover\:translate-y-68:hover {
    --tw-translate-y: 6.8rem
  }

  .sm\:hover\:translate-y-72:hover {
    --tw-translate-y: 7.2rem
  }

  .sm\:hover\:translate-y-76:hover {
    --tw-translate-y: 7.6rem
  }

  .sm\:hover\:translate-y-80:hover {
    --tw-translate-y: 8rem
  }

  .sm\:hover\:translate-y-84:hover {
    --tw-translate-y: 8.4rem
  }

  .sm\:hover\:translate-y-88:hover {
    --tw-translate-y: 8.8rem
  }

  .sm\:hover\:translate-y-92:hover {
    --tw-translate-y: 9.2rem
  }

  .sm\:hover\:translate-y-96:hover {
    --tw-translate-y: 9.6rem
  }

  .sm\:hover\:translate-y-128:hover {
    --tw-translate-y: 12.8rem
  }

  .sm\:hover\:translate-y-136:hover {
    --tw-translate-y: 13.6rem
  }

  .sm\:hover\:translate-y-160:hover {
    --tw-translate-y: 16rem
  }

  .sm\:hover\:translate-y-192:hover {
    --tw-translate-y: 19.2rem
  }

  .sm\:hover\:translate-y-200:hover {
    --tw-translate-y: 20rem
  }

  .sm\:hover\:translate-y-208:hover {
    --tw-translate-y: 20.8rem
  }

  .sm\:hover\:translate-y-216:hover {
    --tw-translate-y: 21.6rem
  }

  .sm\:hover\:translate-y-224:hover {
    --tw-translate-y: 22.4rem
  }

  .sm\:hover\:translate-y-256:hover {
    --tw-translate-y: 25.6rem
  }

  .sm\:hover\:translate-y-288:hover {
    --tw-translate-y: 28.8rem
  }

  .sm\:hover\:translate-y-320:hover {
    --tw-translate-y: 32rem
  }

  .sm\:hover\:translate-y-360:hover {
    --tw-translate-y: 36rem
  }

  .sm\:hover\:translate-y-384:hover {
    --tw-translate-y: 38.4rem
  }

  .sm\:hover\:translate-y-400:hover {
    --tw-translate-y: 40rem
  }

  .sm\:hover\:translate-y-512:hover {
    --tw-translate-y: 51.2rem
  }

  .sm\:hover\:translate-y-640:hover {
    --tw-translate-y: 64rem
  }

  .sm\:hover\:translate-y-xs:hover {
    --tw-translate-y: 32rem
  }

  .sm\:hover\:translate-y-sm:hover {
    --tw-translate-y: 48rem
  }

  .sm\:hover\:translate-y-md:hover {
    --tw-translate-y: 64rem
  }

  .sm\:hover\:translate-y-lg:hover {
    --tw-translate-y: 80rem
  }

  .sm\:hover\:translate-y-xl:hover {
    --tw-translate-y: 96rem
  }

  .sm\:hover\:translate-y-2xl:hover {
    --tw-translate-y: 112rem
  }

  .sm\:hover\:translate-y-3xl:hover {
    --tw-translate-y: 128rem
  }

  .sm\:hover\:translate-y-4xl:hover {
    --tw-translate-y: 144rem
  }

  .sm\:hover\:translate-y-5xl:hover {
    --tw-translate-y: 160rem
  }

  .sm\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px
  }

  .sm\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.05rem
  }

  .sm\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.15rem
  }

  .sm\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.25rem
  }

  .sm\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.35rem
  }

  .sm\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.1rem
  }

  .sm\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.2rem
  }

  .sm\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.3rem
  }

  .sm\:hover\:-translate-y-4:hover {
    --tw-translate-y: -0.4rem
  }

  .sm\:hover\:-translate-y-5:hover {
    --tw-translate-y: -0.5rem
  }

  .sm\:hover\:-translate-y-6:hover {
    --tw-translate-y: -0.6rem
  }

  .sm\:hover\:-translate-y-7:hover {
    --tw-translate-y: -0.7rem
  }

  .sm\:hover\:-translate-y-8:hover {
    --tw-translate-y: -0.8rem
  }

  .sm\:hover\:-translate-y-9:hover {
    --tw-translate-y: -0.9rem
  }

  .sm\:hover\:-translate-y-10:hover {
    --tw-translate-y: -1rem
  }

  .sm\:hover\:-translate-y-12:hover {
    --tw-translate-y: -1.2rem
  }

  .sm\:hover\:-translate-y-14:hover {
    --tw-translate-y: -1.4rem
  }

  .sm\:hover\:-translate-y-16:hover {
    --tw-translate-y: -1.6rem
  }

  .sm\:hover\:-translate-y-20:hover {
    --tw-translate-y: -2rem
  }

  .sm\:hover\:-translate-y-24:hover {
    --tw-translate-y: -2.4rem
  }

  .sm\:hover\:-translate-y-28:hover {
    --tw-translate-y: -2.8rem
  }

  .sm\:hover\:-translate-y-32:hover {
    --tw-translate-y: -3.2rem
  }

  .sm\:hover\:-translate-y-36:hover {
    --tw-translate-y: -3.6rem
  }

  .sm\:hover\:-translate-y-40:hover {
    --tw-translate-y: -4rem
  }

  .sm\:hover\:-translate-y-44:hover {
    --tw-translate-y: -4.4rem
  }

  .sm\:hover\:-translate-y-48:hover {
    --tw-translate-y: -4.8rem
  }

  .sm\:hover\:-translate-y-52:hover {
    --tw-translate-y: -5.2rem
  }

  .sm\:hover\:-translate-y-56:hover {
    --tw-translate-y: -5.6rem
  }

  .sm\:hover\:-translate-y-60:hover {
    --tw-translate-y: -6rem
  }

  .sm\:hover\:-translate-y-64:hover {
    --tw-translate-y: -6.4rem
  }

  .sm\:hover\:-translate-y-68:hover {
    --tw-translate-y: -6.8rem
  }

  .sm\:hover\:-translate-y-72:hover {
    --tw-translate-y: -7.2rem
  }

  .sm\:hover\:-translate-y-76:hover {
    --tw-translate-y: -7.6rem
  }

  .sm\:hover\:-translate-y-80:hover {
    --tw-translate-y: -8rem
  }

  .sm\:hover\:-translate-y-84:hover {
    --tw-translate-y: -8.4rem
  }

  .sm\:hover\:-translate-y-88:hover {
    --tw-translate-y: -8.8rem
  }

  .sm\:hover\:-translate-y-92:hover {
    --tw-translate-y: -9.2rem
  }

  .sm\:hover\:-translate-y-96:hover {
    --tw-translate-y: -9.6rem
  }

  .sm\:hover\:-translate-y-128:hover {
    --tw-translate-y: -12.8rem
  }

  .sm\:hover\:-translate-y-136:hover {
    --tw-translate-y: -13.6rem
  }

  .sm\:hover\:-translate-y-160:hover {
    --tw-translate-y: -16rem
  }

  .sm\:hover\:-translate-y-192:hover {
    --tw-translate-y: -19.2rem
  }

  .sm\:hover\:-translate-y-200:hover {
    --tw-translate-y: -20rem
  }

  .sm\:hover\:-translate-y-208:hover {
    --tw-translate-y: -20.8rem
  }

  .sm\:hover\:-translate-y-216:hover {
    --tw-translate-y: -21.6rem
  }

  .sm\:hover\:-translate-y-224:hover {
    --tw-translate-y: -22.4rem
  }

  .sm\:hover\:-translate-y-256:hover {
    --tw-translate-y: -25.6rem
  }

  .sm\:hover\:-translate-y-288:hover {
    --tw-translate-y: -28.8rem
  }

  .sm\:hover\:-translate-y-320:hover {
    --tw-translate-y: -32rem
  }

  .sm\:hover\:-translate-y-360:hover {
    --tw-translate-y: -36rem
  }

  .sm\:hover\:-translate-y-384:hover {
    --tw-translate-y: -38.4rem
  }

  .sm\:hover\:-translate-y-400:hover {
    --tw-translate-y: -40rem
  }

  .sm\:hover\:-translate-y-512:hover {
    --tw-translate-y: -51.2rem
  }

  .sm\:hover\:-translate-y-640:hover {
    --tw-translate-y: -64rem
  }

  .sm\:hover\:-translate-y-xs:hover {
    --tw-translate-y: -32rem
  }

  .sm\:hover\:-translate-y-sm:hover {
    --tw-translate-y: -48rem
  }

  .sm\:hover\:-translate-y-md:hover {
    --tw-translate-y: -64rem
  }

  .sm\:hover\:-translate-y-lg:hover {
    --tw-translate-y: -80rem
  }

  .sm\:hover\:-translate-y-xl:hover {
    --tw-translate-y: -96rem
  }

  .sm\:hover\:-translate-y-2xl:hover {
    --tw-translate-y: -112rem
  }

  .sm\:hover\:-translate-y-3xl:hover {
    --tw-translate-y: -128rem
  }

  .sm\:hover\:-translate-y-4xl:hover {
    --tw-translate-y: -144rem
  }

  .sm\:hover\:-translate-y-5xl:hover {
    --tw-translate-y: -160rem
  }

  .sm\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px
  }

  .sm\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.05rem
  }

  .sm\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.15rem
  }

  .sm\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.25rem
  }

  .sm\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.35rem
  }

  .sm\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%
  }

  .sm\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%
  }

  .sm\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%
  }

  .sm\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%
  }

  .sm\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%
  }

  .sm\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%
  }

  .sm\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%
  }

  .sm\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%
  }

  .sm\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%
  }

  .sm\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%
  }

  .sm\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%
  }

  .sm\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%
  }

  .sm\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%
  }

  .sm\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%
  }

  .sm\:focus\:translate-x-0:focus {
    --tw-translate-x: 0
  }

  .sm\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.1rem
  }

  .sm\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.2rem
  }

  .sm\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.3rem
  }

  .sm\:focus\:translate-x-4:focus {
    --tw-translate-x: 0.4rem
  }

  .sm\:focus\:translate-x-5:focus {
    --tw-translate-x: 0.5rem
  }

  .sm\:focus\:translate-x-6:focus {
    --tw-translate-x: 0.6rem
  }

  .sm\:focus\:translate-x-7:focus {
    --tw-translate-x: 0.7rem
  }

  .sm\:focus\:translate-x-8:focus {
    --tw-translate-x: 0.8rem
  }

  .sm\:focus\:translate-x-9:focus {
    --tw-translate-x: 0.9rem
  }

  .sm\:focus\:translate-x-10:focus {
    --tw-translate-x: 1.0rem
  }

  .sm\:focus\:translate-x-12:focus {
    --tw-translate-x: 1.2rem
  }

  .sm\:focus\:translate-x-14:focus {
    --tw-translate-x: 1.4rem
  }

  .sm\:focus\:translate-x-16:focus {
    --tw-translate-x: 1.6rem
  }

  .sm\:focus\:translate-x-20:focus {
    --tw-translate-x: 2rem
  }

  .sm\:focus\:translate-x-24:focus {
    --tw-translate-x: 2.4rem
  }

  .sm\:focus\:translate-x-28:focus {
    --tw-translate-x: 2.8rem
  }

  .sm\:focus\:translate-x-32:focus {
    --tw-translate-x: 3.2rem
  }

  .sm\:focus\:translate-x-36:focus {
    --tw-translate-x: 3.6rem
  }

  .sm\:focus\:translate-x-40:focus {
    --tw-translate-x: 4rem
  }

  .sm\:focus\:translate-x-44:focus {
    --tw-translate-x: 4.4rem
  }

  .sm\:focus\:translate-x-48:focus {
    --tw-translate-x: 4.8rem
  }

  .sm\:focus\:translate-x-52:focus {
    --tw-translate-x: 5.2rem
  }

  .sm\:focus\:translate-x-56:focus {
    --tw-translate-x: 5.6rem
  }

  .sm\:focus\:translate-x-60:focus {
    --tw-translate-x: 6rem
  }

  .sm\:focus\:translate-x-64:focus {
    --tw-translate-x: 6.4rem
  }

  .sm\:focus\:translate-x-68:focus {
    --tw-translate-x: 6.8rem
  }

  .sm\:focus\:translate-x-72:focus {
    --tw-translate-x: 7.2rem
  }

  .sm\:focus\:translate-x-76:focus {
    --tw-translate-x: 7.6rem
  }

  .sm\:focus\:translate-x-80:focus {
    --tw-translate-x: 8rem
  }

  .sm\:focus\:translate-x-84:focus {
    --tw-translate-x: 8.4rem
  }

  .sm\:focus\:translate-x-88:focus {
    --tw-translate-x: 8.8rem
  }

  .sm\:focus\:translate-x-92:focus {
    --tw-translate-x: 9.2rem
  }

  .sm\:focus\:translate-x-96:focus {
    --tw-translate-x: 9.6rem
  }

  .sm\:focus\:translate-x-128:focus {
    --tw-translate-x: 12.8rem
  }

  .sm\:focus\:translate-x-136:focus {
    --tw-translate-x: 13.6rem
  }

  .sm\:focus\:translate-x-160:focus {
    --tw-translate-x: 16rem
  }

  .sm\:focus\:translate-x-192:focus {
    --tw-translate-x: 19.2rem
  }

  .sm\:focus\:translate-x-200:focus {
    --tw-translate-x: 20rem
  }

  .sm\:focus\:translate-x-208:focus {
    --tw-translate-x: 20.8rem
  }

  .sm\:focus\:translate-x-216:focus {
    --tw-translate-x: 21.6rem
  }

  .sm\:focus\:translate-x-224:focus {
    --tw-translate-x: 22.4rem
  }

  .sm\:focus\:translate-x-256:focus {
    --tw-translate-x: 25.6rem
  }

  .sm\:focus\:translate-x-288:focus {
    --tw-translate-x: 28.8rem
  }

  .sm\:focus\:translate-x-320:focus {
    --tw-translate-x: 32rem
  }

  .sm\:focus\:translate-x-360:focus {
    --tw-translate-x: 36rem
  }

  .sm\:focus\:translate-x-384:focus {
    --tw-translate-x: 38.4rem
  }

  .sm\:focus\:translate-x-400:focus {
    --tw-translate-x: 40rem
  }

  .sm\:focus\:translate-x-512:focus {
    --tw-translate-x: 51.2rem
  }

  .sm\:focus\:translate-x-640:focus {
    --tw-translate-x: 64rem
  }

  .sm\:focus\:translate-x-xs:focus {
    --tw-translate-x: 32rem
  }

  .sm\:focus\:translate-x-sm:focus {
    --tw-translate-x: 48rem
  }

  .sm\:focus\:translate-x-md:focus {
    --tw-translate-x: 64rem
  }

  .sm\:focus\:translate-x-lg:focus {
    --tw-translate-x: 80rem
  }

  .sm\:focus\:translate-x-xl:focus {
    --tw-translate-x: 96rem
  }

  .sm\:focus\:translate-x-2xl:focus {
    --tw-translate-x: 112rem
  }

  .sm\:focus\:translate-x-3xl:focus {
    --tw-translate-x: 128rem
  }

  .sm\:focus\:translate-x-4xl:focus {
    --tw-translate-x: 144rem
  }

  .sm\:focus\:translate-x-5xl:focus {
    --tw-translate-x: 160rem
  }

  .sm\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px
  }

  .sm\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.05rem
  }

  .sm\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.15rem
  }

  .sm\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.25rem
  }

  .sm\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.35rem
  }

  .sm\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.1rem
  }

  .sm\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.2rem
  }

  .sm\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.3rem
  }

  .sm\:focus\:-translate-x-4:focus {
    --tw-translate-x: -0.4rem
  }

  .sm\:focus\:-translate-x-5:focus {
    --tw-translate-x: -0.5rem
  }

  .sm\:focus\:-translate-x-6:focus {
    --tw-translate-x: -0.6rem
  }

  .sm\:focus\:-translate-x-7:focus {
    --tw-translate-x: -0.7rem
  }

  .sm\:focus\:-translate-x-8:focus {
    --tw-translate-x: -0.8rem
  }

  .sm\:focus\:-translate-x-9:focus {
    --tw-translate-x: -0.9rem
  }

  .sm\:focus\:-translate-x-10:focus {
    --tw-translate-x: -1rem
  }

  .sm\:focus\:-translate-x-12:focus {
    --tw-translate-x: -1.2rem
  }

  .sm\:focus\:-translate-x-14:focus {
    --tw-translate-x: -1.4rem
  }

  .sm\:focus\:-translate-x-16:focus {
    --tw-translate-x: -1.6rem
  }

  .sm\:focus\:-translate-x-20:focus {
    --tw-translate-x: -2rem
  }

  .sm\:focus\:-translate-x-24:focus {
    --tw-translate-x: -2.4rem
  }

  .sm\:focus\:-translate-x-28:focus {
    --tw-translate-x: -2.8rem
  }

  .sm\:focus\:-translate-x-32:focus {
    --tw-translate-x: -3.2rem
  }

  .sm\:focus\:-translate-x-36:focus {
    --tw-translate-x: -3.6rem
  }

  .sm\:focus\:-translate-x-40:focus {
    --tw-translate-x: -4rem
  }

  .sm\:focus\:-translate-x-44:focus {
    --tw-translate-x: -4.4rem
  }

  .sm\:focus\:-translate-x-48:focus {
    --tw-translate-x: -4.8rem
  }

  .sm\:focus\:-translate-x-52:focus {
    --tw-translate-x: -5.2rem
  }

  .sm\:focus\:-translate-x-56:focus {
    --tw-translate-x: -5.6rem
  }

  .sm\:focus\:-translate-x-60:focus {
    --tw-translate-x: -6rem
  }

  .sm\:focus\:-translate-x-64:focus {
    --tw-translate-x: -6.4rem
  }

  .sm\:focus\:-translate-x-68:focus {
    --tw-translate-x: -6.8rem
  }

  .sm\:focus\:-translate-x-72:focus {
    --tw-translate-x: -7.2rem
  }

  .sm\:focus\:-translate-x-76:focus {
    --tw-translate-x: -7.6rem
  }

  .sm\:focus\:-translate-x-80:focus {
    --tw-translate-x: -8rem
  }

  .sm\:focus\:-translate-x-84:focus {
    --tw-translate-x: -8.4rem
  }

  .sm\:focus\:-translate-x-88:focus {
    --tw-translate-x: -8.8rem
  }

  .sm\:focus\:-translate-x-92:focus {
    --tw-translate-x: -9.2rem
  }

  .sm\:focus\:-translate-x-96:focus {
    --tw-translate-x: -9.6rem
  }

  .sm\:focus\:-translate-x-128:focus {
    --tw-translate-x: -12.8rem
  }

  .sm\:focus\:-translate-x-136:focus {
    --tw-translate-x: -13.6rem
  }

  .sm\:focus\:-translate-x-160:focus {
    --tw-translate-x: -16rem
  }

  .sm\:focus\:-translate-x-192:focus {
    --tw-translate-x: -19.2rem
  }

  .sm\:focus\:-translate-x-200:focus {
    --tw-translate-x: -20rem
  }

  .sm\:focus\:-translate-x-208:focus {
    --tw-translate-x: -20.8rem
  }

  .sm\:focus\:-translate-x-216:focus {
    --tw-translate-x: -21.6rem
  }

  .sm\:focus\:-translate-x-224:focus {
    --tw-translate-x: -22.4rem
  }

  .sm\:focus\:-translate-x-256:focus {
    --tw-translate-x: -25.6rem
  }

  .sm\:focus\:-translate-x-288:focus {
    --tw-translate-x: -28.8rem
  }

  .sm\:focus\:-translate-x-320:focus {
    --tw-translate-x: -32rem
  }

  .sm\:focus\:-translate-x-360:focus {
    --tw-translate-x: -36rem
  }

  .sm\:focus\:-translate-x-384:focus {
    --tw-translate-x: -38.4rem
  }

  .sm\:focus\:-translate-x-400:focus {
    --tw-translate-x: -40rem
  }

  .sm\:focus\:-translate-x-512:focus {
    --tw-translate-x: -51.2rem
  }

  .sm\:focus\:-translate-x-640:focus {
    --tw-translate-x: -64rem
  }

  .sm\:focus\:-translate-x-xs:focus {
    --tw-translate-x: -32rem
  }

  .sm\:focus\:-translate-x-sm:focus {
    --tw-translate-x: -48rem
  }

  .sm\:focus\:-translate-x-md:focus {
    --tw-translate-x: -64rem
  }

  .sm\:focus\:-translate-x-lg:focus {
    --tw-translate-x: -80rem
  }

  .sm\:focus\:-translate-x-xl:focus {
    --tw-translate-x: -96rem
  }

  .sm\:focus\:-translate-x-2xl:focus {
    --tw-translate-x: -112rem
  }

  .sm\:focus\:-translate-x-3xl:focus {
    --tw-translate-x: -128rem
  }

  .sm\:focus\:-translate-x-4xl:focus {
    --tw-translate-x: -144rem
  }

  .sm\:focus\:-translate-x-5xl:focus {
    --tw-translate-x: -160rem
  }

  .sm\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px
  }

  .sm\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.05rem
  }

  .sm\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.15rem
  }

  .sm\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.25rem
  }

  .sm\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.35rem
  }

  .sm\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%
  }

  .sm\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%
  }

  .sm\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%
  }

  .sm\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%
  }

  .sm\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%
  }

  .sm\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%
  }

  .sm\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%
  }

  .sm\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%
  }

  .sm\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%
  }

  .sm\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%
  }

  .sm\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%
  }

  .sm\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%
  }

  .sm\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%
  }

  .sm\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%
  }

  .sm\:focus\:translate-y-0:focus {
    --tw-translate-y: 0
  }

  .sm\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.1rem
  }

  .sm\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.2rem
  }

  .sm\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.3rem
  }

  .sm\:focus\:translate-y-4:focus {
    --tw-translate-y: 0.4rem
  }

  .sm\:focus\:translate-y-5:focus {
    --tw-translate-y: 0.5rem
  }

  .sm\:focus\:translate-y-6:focus {
    --tw-translate-y: 0.6rem
  }

  .sm\:focus\:translate-y-7:focus {
    --tw-translate-y: 0.7rem
  }

  .sm\:focus\:translate-y-8:focus {
    --tw-translate-y: 0.8rem
  }

  .sm\:focus\:translate-y-9:focus {
    --tw-translate-y: 0.9rem
  }

  .sm\:focus\:translate-y-10:focus {
    --tw-translate-y: 1.0rem
  }

  .sm\:focus\:translate-y-12:focus {
    --tw-translate-y: 1.2rem
  }

  .sm\:focus\:translate-y-14:focus {
    --tw-translate-y: 1.4rem
  }

  .sm\:focus\:translate-y-16:focus {
    --tw-translate-y: 1.6rem
  }

  .sm\:focus\:translate-y-20:focus {
    --tw-translate-y: 2rem
  }

  .sm\:focus\:translate-y-24:focus {
    --tw-translate-y: 2.4rem
  }

  .sm\:focus\:translate-y-28:focus {
    --tw-translate-y: 2.8rem
  }

  .sm\:focus\:translate-y-32:focus {
    --tw-translate-y: 3.2rem
  }

  .sm\:focus\:translate-y-36:focus {
    --tw-translate-y: 3.6rem
  }

  .sm\:focus\:translate-y-40:focus {
    --tw-translate-y: 4rem
  }

  .sm\:focus\:translate-y-44:focus {
    --tw-translate-y: 4.4rem
  }

  .sm\:focus\:translate-y-48:focus {
    --tw-translate-y: 4.8rem
  }

  .sm\:focus\:translate-y-52:focus {
    --tw-translate-y: 5.2rem
  }

  .sm\:focus\:translate-y-56:focus {
    --tw-translate-y: 5.6rem
  }

  .sm\:focus\:translate-y-60:focus {
    --tw-translate-y: 6rem
  }

  .sm\:focus\:translate-y-64:focus {
    --tw-translate-y: 6.4rem
  }

  .sm\:focus\:translate-y-68:focus {
    --tw-translate-y: 6.8rem
  }

  .sm\:focus\:translate-y-72:focus {
    --tw-translate-y: 7.2rem
  }

  .sm\:focus\:translate-y-76:focus {
    --tw-translate-y: 7.6rem
  }

  .sm\:focus\:translate-y-80:focus {
    --tw-translate-y: 8rem
  }

  .sm\:focus\:translate-y-84:focus {
    --tw-translate-y: 8.4rem
  }

  .sm\:focus\:translate-y-88:focus {
    --tw-translate-y: 8.8rem
  }

  .sm\:focus\:translate-y-92:focus {
    --tw-translate-y: 9.2rem
  }

  .sm\:focus\:translate-y-96:focus {
    --tw-translate-y: 9.6rem
  }

  .sm\:focus\:translate-y-128:focus {
    --tw-translate-y: 12.8rem
  }

  .sm\:focus\:translate-y-136:focus {
    --tw-translate-y: 13.6rem
  }

  .sm\:focus\:translate-y-160:focus {
    --tw-translate-y: 16rem
  }

  .sm\:focus\:translate-y-192:focus {
    --tw-translate-y: 19.2rem
  }

  .sm\:focus\:translate-y-200:focus {
    --tw-translate-y: 20rem
  }

  .sm\:focus\:translate-y-208:focus {
    --tw-translate-y: 20.8rem
  }

  .sm\:focus\:translate-y-216:focus {
    --tw-translate-y: 21.6rem
  }

  .sm\:focus\:translate-y-224:focus {
    --tw-translate-y: 22.4rem
  }

  .sm\:focus\:translate-y-256:focus {
    --tw-translate-y: 25.6rem
  }

  .sm\:focus\:translate-y-288:focus {
    --tw-translate-y: 28.8rem
  }

  .sm\:focus\:translate-y-320:focus {
    --tw-translate-y: 32rem
  }

  .sm\:focus\:translate-y-360:focus {
    --tw-translate-y: 36rem
  }

  .sm\:focus\:translate-y-384:focus {
    --tw-translate-y: 38.4rem
  }

  .sm\:focus\:translate-y-400:focus {
    --tw-translate-y: 40rem
  }

  .sm\:focus\:translate-y-512:focus {
    --tw-translate-y: 51.2rem
  }

  .sm\:focus\:translate-y-640:focus {
    --tw-translate-y: 64rem
  }

  .sm\:focus\:translate-y-xs:focus {
    --tw-translate-y: 32rem
  }

  .sm\:focus\:translate-y-sm:focus {
    --tw-translate-y: 48rem
  }

  .sm\:focus\:translate-y-md:focus {
    --tw-translate-y: 64rem
  }

  .sm\:focus\:translate-y-lg:focus {
    --tw-translate-y: 80rem
  }

  .sm\:focus\:translate-y-xl:focus {
    --tw-translate-y: 96rem
  }

  .sm\:focus\:translate-y-2xl:focus {
    --tw-translate-y: 112rem
  }

  .sm\:focus\:translate-y-3xl:focus {
    --tw-translate-y: 128rem
  }

  .sm\:focus\:translate-y-4xl:focus {
    --tw-translate-y: 144rem
  }

  .sm\:focus\:translate-y-5xl:focus {
    --tw-translate-y: 160rem
  }

  .sm\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px
  }

  .sm\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.05rem
  }

  .sm\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.15rem
  }

  .sm\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.25rem
  }

  .sm\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.35rem
  }

  .sm\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.1rem
  }

  .sm\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.2rem
  }

  .sm\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.3rem
  }

  .sm\:focus\:-translate-y-4:focus {
    --tw-translate-y: -0.4rem
  }

  .sm\:focus\:-translate-y-5:focus {
    --tw-translate-y: -0.5rem
  }

  .sm\:focus\:-translate-y-6:focus {
    --tw-translate-y: -0.6rem
  }

  .sm\:focus\:-translate-y-7:focus {
    --tw-translate-y: -0.7rem
  }

  .sm\:focus\:-translate-y-8:focus {
    --tw-translate-y: -0.8rem
  }

  .sm\:focus\:-translate-y-9:focus {
    --tw-translate-y: -0.9rem
  }

  .sm\:focus\:-translate-y-10:focus {
    --tw-translate-y: -1rem
  }

  .sm\:focus\:-translate-y-12:focus {
    --tw-translate-y: -1.2rem
  }

  .sm\:focus\:-translate-y-14:focus {
    --tw-translate-y: -1.4rem
  }

  .sm\:focus\:-translate-y-16:focus {
    --tw-translate-y: -1.6rem
  }

  .sm\:focus\:-translate-y-20:focus {
    --tw-translate-y: -2rem
  }

  .sm\:focus\:-translate-y-24:focus {
    --tw-translate-y: -2.4rem
  }

  .sm\:focus\:-translate-y-28:focus {
    --tw-translate-y: -2.8rem
  }

  .sm\:focus\:-translate-y-32:focus {
    --tw-translate-y: -3.2rem
  }

  .sm\:focus\:-translate-y-36:focus {
    --tw-translate-y: -3.6rem
  }

  .sm\:focus\:-translate-y-40:focus {
    --tw-translate-y: -4rem
  }

  .sm\:focus\:-translate-y-44:focus {
    --tw-translate-y: -4.4rem
  }

  .sm\:focus\:-translate-y-48:focus {
    --tw-translate-y: -4.8rem
  }

  .sm\:focus\:-translate-y-52:focus {
    --tw-translate-y: -5.2rem
  }

  .sm\:focus\:-translate-y-56:focus {
    --tw-translate-y: -5.6rem
  }

  .sm\:focus\:-translate-y-60:focus {
    --tw-translate-y: -6rem
  }

  .sm\:focus\:-translate-y-64:focus {
    --tw-translate-y: -6.4rem
  }

  .sm\:focus\:-translate-y-68:focus {
    --tw-translate-y: -6.8rem
  }

  .sm\:focus\:-translate-y-72:focus {
    --tw-translate-y: -7.2rem
  }

  .sm\:focus\:-translate-y-76:focus {
    --tw-translate-y: -7.6rem
  }

  .sm\:focus\:-translate-y-80:focus {
    --tw-translate-y: -8rem
  }

  .sm\:focus\:-translate-y-84:focus {
    --tw-translate-y: -8.4rem
  }

  .sm\:focus\:-translate-y-88:focus {
    --tw-translate-y: -8.8rem
  }

  .sm\:focus\:-translate-y-92:focus {
    --tw-translate-y: -9.2rem
  }

  .sm\:focus\:-translate-y-96:focus {
    --tw-translate-y: -9.6rem
  }

  .sm\:focus\:-translate-y-128:focus {
    --tw-translate-y: -12.8rem
  }

  .sm\:focus\:-translate-y-136:focus {
    --tw-translate-y: -13.6rem
  }

  .sm\:focus\:-translate-y-160:focus {
    --tw-translate-y: -16rem
  }

  .sm\:focus\:-translate-y-192:focus {
    --tw-translate-y: -19.2rem
  }

  .sm\:focus\:-translate-y-200:focus {
    --tw-translate-y: -20rem
  }

  .sm\:focus\:-translate-y-208:focus {
    --tw-translate-y: -20.8rem
  }

  .sm\:focus\:-translate-y-216:focus {
    --tw-translate-y: -21.6rem
  }

  .sm\:focus\:-translate-y-224:focus {
    --tw-translate-y: -22.4rem
  }

  .sm\:focus\:-translate-y-256:focus {
    --tw-translate-y: -25.6rem
  }

  .sm\:focus\:-translate-y-288:focus {
    --tw-translate-y: -28.8rem
  }

  .sm\:focus\:-translate-y-320:focus {
    --tw-translate-y: -32rem
  }

  .sm\:focus\:-translate-y-360:focus {
    --tw-translate-y: -36rem
  }

  .sm\:focus\:-translate-y-384:focus {
    --tw-translate-y: -38.4rem
  }

  .sm\:focus\:-translate-y-400:focus {
    --tw-translate-y: -40rem
  }

  .sm\:focus\:-translate-y-512:focus {
    --tw-translate-y: -51.2rem
  }

  .sm\:focus\:-translate-y-640:focus {
    --tw-translate-y: -64rem
  }

  .sm\:focus\:-translate-y-xs:focus {
    --tw-translate-y: -32rem
  }

  .sm\:focus\:-translate-y-sm:focus {
    --tw-translate-y: -48rem
  }

  .sm\:focus\:-translate-y-md:focus {
    --tw-translate-y: -64rem
  }

  .sm\:focus\:-translate-y-lg:focus {
    --tw-translate-y: -80rem
  }

  .sm\:focus\:-translate-y-xl:focus {
    --tw-translate-y: -96rem
  }

  .sm\:focus\:-translate-y-2xl:focus {
    --tw-translate-y: -112rem
  }

  .sm\:focus\:-translate-y-3xl:focus {
    --tw-translate-y: -128rem
  }

  .sm\:focus\:-translate-y-4xl:focus {
    --tw-translate-y: -144rem
  }

  .sm\:focus\:-translate-y-5xl:focus {
    --tw-translate-y: -160rem
  }

  .sm\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px
  }

  .sm\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.05rem
  }

  .sm\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.15rem
  }

  .sm\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.25rem
  }

  .sm\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.35rem
  }

  .sm\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%
  }

  .sm\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%
  }

  .sm\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%
  }

  .sm\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%
  }

  .sm\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%
  }

  .sm\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%
  }

  .sm\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%
  }

  .sm\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%
  }

  .sm\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%
  }

  .sm\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%
  }

  .sm\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%
  }

  .sm\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%
  }

  .sm\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%
  }

  .sm\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%
  }

  .sm\:skew-x-0 {
    --tw-skew-x: 0deg
  }

  .sm\:skew-x-1 {
    --tw-skew-x: 1deg
  }

  .sm\:skew-x-2 {
    --tw-skew-x: 2deg
  }

  .sm\:skew-x-3 {
    --tw-skew-x: 3deg
  }

  .sm\:skew-x-6 {
    --tw-skew-x: 6deg
  }

  .sm\:skew-x-12 {
    --tw-skew-x: 12deg
  }

  .sm\:-skew-x-12 {
    --tw-skew-x: -12deg
  }

  .sm\:-skew-x-6 {
    --tw-skew-x: -6deg
  }

  .sm\:-skew-x-3 {
    --tw-skew-x: -3deg
  }

  .sm\:-skew-x-2 {
    --tw-skew-x: -2deg
  }

  .sm\:-skew-x-1 {
    --tw-skew-x: -1deg
  }

  .sm\:skew-y-0 {
    --tw-skew-y: 0deg
  }

  .sm\:skew-y-1 {
    --tw-skew-y: 1deg
  }

  .sm\:skew-y-2 {
    --tw-skew-y: 2deg
  }

  .sm\:skew-y-3 {
    --tw-skew-y: 3deg
  }

  .sm\:skew-y-6 {
    --tw-skew-y: 6deg
  }

  .sm\:skew-y-12 {
    --tw-skew-y: 12deg
  }

  .sm\:-skew-y-12 {
    --tw-skew-y: -12deg
  }

  .sm\:-skew-y-6 {
    --tw-skew-y: -6deg
  }

  .sm\:-skew-y-3 {
    --tw-skew-y: -3deg
  }

  .sm\:-skew-y-2 {
    --tw-skew-y: -2deg
  }

  .sm\:-skew-y-1 {
    --tw-skew-y: -1deg
  }

  .sm\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg
  }

  .sm\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg
  }

  .sm\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg
  }

  .sm\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg
  }

  .sm\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg
  }

  .sm\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg
  }

  .sm\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg
  }

  .sm\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg
  }

  .sm\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg
  }

  .sm\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg
  }

  .sm\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg
  }

  .sm\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg
  }

  .sm\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg
  }

  .sm\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg
  }

  .sm\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg
  }

  .sm\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg
  }

  .sm\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg
  }

  .sm\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg
  }

  .sm\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg
  }

  .sm\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg
  }

  .sm\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg
  }

  .sm\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg
  }

  .sm\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg
  }

  .sm\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg
  }

  .sm\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg
  }

  .sm\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg
  }

  .sm\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg
  }

  .sm\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg
  }

  .sm\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg
  }

  .sm\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg
  }

  .sm\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg
  }

  .sm\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg
  }

  .sm\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg
  }

  .sm\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg
  }

  .sm\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg
  }

  .sm\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg
  }

  .sm\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg
  }

  .sm\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg
  }

  .sm\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg
  }

  .sm\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg
  }

  .sm\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg
  }

  .sm\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg
  }

  .sm\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg
  }

  .sm\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg
  }

  .sm\:transition-none {
    transition-property: none
  }

  .sm\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .sm\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .sm\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .sm\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .sm\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .sm\:transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .sm\:ease-linear {
    transition-timing-function: linear
  }

  .sm\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .sm\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .sm\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .sm\:duration-75 {
    transition-duration: 75ms
  }

  .sm\:duration-100 {
    transition-duration: 100ms
  }

  .sm\:duration-150 {
    transition-duration: 150ms
  }

  .sm\:duration-200 {
    transition-duration: 200ms
  }

  .sm\:duration-300 {
    transition-duration: 300ms
  }

  .sm\:duration-500 {
    transition-duration: 500ms
  }

  .sm\:duration-700 {
    transition-duration: 700ms
  }

  .sm\:duration-1000 {
    transition-duration: 1000ms
  }

  .sm\:delay-75 {
    transition-delay: 75ms
  }

  .sm\:delay-100 {
    transition-delay: 100ms
  }

  .sm\:delay-150 {
    transition-delay: 150ms
  }

  .sm\:delay-200 {
    transition-delay: 200ms
  }

  .sm\:delay-300 {
    transition-delay: 300ms
  }

  .sm\:delay-500 {
    transition-delay: 500ms
  }

  .sm\:delay-700 {
    transition-delay: 700ms
  }

  .sm\:delay-1000 {
    transition-delay: 1000ms
  }

  .sm\:animate-none {
    animation: none
  }

  .sm\:animate-spin {
    animation: spin 1s linear infinite
  }

  .sm\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .sm\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .sm\:animate-bounce {
    animation: bounce 1s infinite
  }
}

@media (min-width: 960px) {
  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  .md\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.1rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.1rem * var(--tw-space-x-reverse));
    margin-left: calc(0.1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.2rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.2rem * var(--tw-space-x-reverse));
    margin-left: calc(0.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.3rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.3rem * var(--tw-space-x-reverse));
    margin-left: calc(0.3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.4rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.4rem * var(--tw-space-x-reverse));
    margin-left: calc(0.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.6rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.6rem * var(--tw-space-x-reverse));
    margin-left: calc(0.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.7rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.7rem * var(--tw-space-x-reverse));
    margin-left: calc(0.7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.8rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.8rem * var(--tw-space-x-reverse));
    margin-left: calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.9rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.9rem * var(--tw-space-x-reverse));
    margin-left: calc(0.9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.0rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.0rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.0rem * var(--tw-space-x-reverse));
    margin-left: calc(1.0rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.2rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.2rem * var(--tw-space-x-reverse));
    margin-left: calc(1.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.4rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.4rem * var(--tw-space-x-reverse));
    margin-left: calc(1.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.6rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.4rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.4rem * var(--tw-space-x-reverse));
    margin-left: calc(2.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.8rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.8rem * var(--tw-space-x-reverse));
    margin-left: calc(2.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.2rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.2rem * var(--tw-space-x-reverse));
    margin-left: calc(3.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.6rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.6rem * var(--tw-space-x-reverse));
    margin-left: calc(3.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4.4rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4.4rem * var(--tw-space-x-reverse));
    margin-left: calc(4.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4.8rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4.8rem * var(--tw-space-x-reverse));
    margin-left: calc(4.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5.2rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5.2rem * var(--tw-space-x-reverse));
    margin-left: calc(5.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5.6rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5.6rem * var(--tw-space-x-reverse));
    margin-left: calc(5.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6.4rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6.4rem * var(--tw-space-x-reverse));
    margin-left: calc(6.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6.8rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6.8rem * var(--tw-space-x-reverse));
    margin-left: calc(6.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7.2rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7.2rem * var(--tw-space-x-reverse));
    margin-left: calc(7.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7.6rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7.6rem * var(--tw-space-x-reverse));
    margin-left: calc(7.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8.4rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8.4rem * var(--tw-space-x-reverse));
    margin-left: calc(8.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8.8rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8.8rem * var(--tw-space-x-reverse));
    margin-left: calc(8.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9.2rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9.2rem * var(--tw-space-x-reverse));
    margin-left: calc(9.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9.6rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9.6rem * var(--tw-space-x-reverse));
    margin-left: calc(9.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12.8rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12.8rem * var(--tw-space-x-reverse));
    margin-left: calc(12.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13.6rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13.6rem * var(--tw-space-x-reverse));
    margin-left: calc(13.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(19.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(19.2rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(19.2rem * var(--tw-space-x-reverse));
    margin-left: calc(19.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20.8rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20.8rem * var(--tw-space-x-reverse));
    margin-left: calc(20.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(21.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(21.6rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(21.6rem * var(--tw-space-x-reverse));
    margin-left: calc(21.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(22.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(22.4rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(22.4rem * var(--tw-space-x-reverse));
    margin-left: calc(22.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(25.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(25.6rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(25.6rem * var(--tw-space-x-reverse));
    margin-left: calc(25.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(28.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(28.8rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(28.8rem * var(--tw-space-x-reverse));
    margin-left: calc(28.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(32rem * var(--tw-space-x-reverse));
    margin-left: calc(32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(36rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(36rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(36rem * var(--tw-space-x-reverse));
    margin-left: calc(36rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(38.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(38.4rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(38.4rem * var(--tw-space-x-reverse));
    margin-left: calc(38.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(40rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(40rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(40rem * var(--tw-space-x-reverse));
    margin-left: calc(40rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(51.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(51.2rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(51.2rem * var(--tw-space-x-reverse));
    margin-left: calc(51.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(64rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(64rem * var(--tw-space-x-reverse));
    margin-left: calc(64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(32rem * var(--tw-space-x-reverse));
    margin-left: calc(32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(48rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(48rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(48rem * var(--tw-space-x-reverse));
    margin-left: calc(48rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(64rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(64rem * var(--tw-space-x-reverse));
    margin-left: calc(64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(80rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(80rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(80rem * var(--tw-space-x-reverse));
    margin-left: calc(80rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(96rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(96rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(96rem * var(--tw-space-x-reverse));
    margin-left: calc(96rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(112rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(112rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(112rem * var(--tw-space-x-reverse));
    margin-left: calc(112rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(128rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(128rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(128rem * var(--tw-space-x-reverse));
    margin-left: calc(128rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(144rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(144rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(144rem * var(--tw-space-x-reverse));
    margin-left: calc(144rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(160rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(160rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(160rem * var(--tw-space-x-reverse));
    margin-left: calc(160rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse))
  }

  .md\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.05rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.05rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.05rem * var(--tw-space-x-reverse));
    margin-left: calc(0.05rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.15rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.15rem * var(--tw-space-x-reverse));
    margin-left: calc(0.15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.35rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.35rem * var(--tw-space-y-reverse))
  }

  .md\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.35rem * var(--tw-space-x-reverse));
    margin-left: calc(0.35rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.1rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.1rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.2rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.3rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.3rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.4rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.6rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.7rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.7rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.8rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.9rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.9rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.2rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.4rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.6rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.4rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.8rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.2rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.6rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4.4rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4.8rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-4.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5.2rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-5.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5.6rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-5.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6.4rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-6.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6.8rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-6.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7.2rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-7.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7.6rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-7.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8.4rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-8.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8.8rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9.2rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-9.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9.6rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-9.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12.8rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-12.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13.6rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-13.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-19.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-19.2rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-19.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-19.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20.8rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-20.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-21.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-21.6rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-21.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-21.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-22.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-22.4rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-22.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-22.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-25.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-25.6rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-25.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-25.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-28.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-28.8rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-28.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-28.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-32rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-32rem * var(--tw-space-x-reverse));
    margin-left: calc(-32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-36rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-36rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-36rem * var(--tw-space-x-reverse));
    margin-left: calc(-36rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-38.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-38.4rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-38.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-38.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-40rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-40rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-40rem * var(--tw-space-x-reverse));
    margin-left: calc(-40rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-51.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-51.2rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-51.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-51.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-64rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-64rem * var(--tw-space-x-reverse));
    margin-left: calc(-64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-32rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-32rem * var(--tw-space-x-reverse));
    margin-left: calc(-32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-48rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-48rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-48rem * var(--tw-space-x-reverse));
    margin-left: calc(-48rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-64rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-64rem * var(--tw-space-x-reverse));
    margin-left: calc(-64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-80rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-80rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-80rem * var(--tw-space-x-reverse));
    margin-left: calc(-80rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-96rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-96rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-96rem * var(--tw-space-x-reverse));
    margin-left: calc(-96rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-112rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-112rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-112rem * var(--tw-space-x-reverse));
    margin-left: calc(-112rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-128rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-128rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-128rem * var(--tw-space-x-reverse));
    margin-left: calc(-128rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-144rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-144rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-144rem * var(--tw-space-x-reverse));
    margin-left: calc(-144rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-160rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-160rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-160rem * var(--tw-space-x-reverse));
    margin-left: calc(-160rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse))
  }

  .md\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.05rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.05rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.05rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.05rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.15rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.15rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.35rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.35rem * var(--tw-space-y-reverse))
  }

  .md\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.35rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.35rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1
  }

  .md\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1
  }

  .md\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse))
  }

  .md\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .md\:divide-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  .md\:divide-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .md\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse))
  }

  .md\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .md\:divide-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(3px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(3px * var(--tw-divide-y-reverse))
  }

  .md\:divide-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(3px * var(--tw-divide-x-reverse));
    border-left-width: calc(3px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .md\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse))
  }

  .md\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .md\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse))
  }

  .md\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .md\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  .md\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .md\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1
  }

  .md\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1
  }

  .md\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent
  }

  .md\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor
  }

  .md\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-divide-opacity))
  }

  .md\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity))
  }

  .md\:divide-grey-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-divide-opacity))
  }

  .md\:divide-grey-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-divide-opacity))
  }

  .md\:divide-grey-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-divide-opacity))
  }

  .md\:divide-grey-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-divide-opacity))
  }

  .md\:divide-grey-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-divide-opacity))
  }

  .md\:divide-grey-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .md\:divide-grey-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-divide-opacity))
  }

  .md\:divide-grey-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .md\:divide-grey-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-divide-opacity))
  }

  .md\:divide-grey-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity))
  }

  .md\:divide-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .md\:divide-grey-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-divide-opacity))
  }

  .md\:divide-grey-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-divide-opacity))
  }

  .md\:divide-grey-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-divide-opacity))
  }

  .md\:divide-grey-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .md\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-divide-opacity))
  }

  .md\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-divide-opacity))
  }

  .md\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-divide-opacity))
  }

  .md\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-divide-opacity))
  }

  .md\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-divide-opacity))
  }

  .md\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .md\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-divide-opacity))
  }

  .md\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .md\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-divide-opacity))
  }

  .md\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity))
  }

  .md\:divide-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .md\:divide-gray-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .md\:divide-gray-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-divide-opacity))
  }

  .md\:divide-gray-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-divide-opacity))
  }

  .md\:divide-gray-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-divide-opacity))
  }

  .md\:divide-gray-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .md\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-divide-opacity))
  }

  .md\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-divide-opacity))
  }

  .md\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-divide-opacity))
  }

  .md\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-divide-opacity))
  }

  .md\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-divide-opacity))
  }

  .md\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-divide-opacity))
  }

  .md\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-divide-opacity))
  }

  .md\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-divide-opacity))
  }

  .md\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-divide-opacity))
  }

  .md\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-divide-opacity))
  }

  .md\:divide-red > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-divide-opacity))
  }

  .md\:divide-red-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-divide-opacity))
  }

  .md\:divide-red-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-divide-opacity))
  }

  .md\:divide-red-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-divide-opacity))
  }

  .md\:divide-red-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-divide-opacity))
  }

  .md\:divide-orange-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-divide-opacity))
  }

  .md\:divide-orange-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-divide-opacity))
  }

  .md\:divide-orange-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-divide-opacity))
  }

  .md\:divide-orange-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-divide-opacity))
  }

  .md\:divide-orange-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-divide-opacity))
  }

  .md\:divide-orange-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-divide-opacity))
  }

  .md\:divide-orange-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-divide-opacity))
  }

  .md\:divide-orange-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-divide-opacity))
  }

  .md\:divide-orange-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-divide-opacity))
  }

  .md\:divide-orange-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-divide-opacity))
  }

  .md\:divide-orange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-divide-opacity))
  }

  .md\:divide-orange-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-divide-opacity))
  }

  .md\:divide-orange-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-divide-opacity))
  }

  .md\:divide-orange-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-divide-opacity))
  }

  .md\:divide-orange-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-divide-opacity))
  }

  .md\:divide-deep-orange-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-divide-opacity))
  }

  .md\:divide-deep-orange-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-divide-opacity))
  }

  .md\:divide-deep-orange-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-divide-opacity))
  }

  .md\:divide-deep-orange-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-divide-opacity))
  }

  .md\:divide-deep-orange-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-divide-opacity))
  }

  .md\:divide-deep-orange-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-divide-opacity))
  }

  .md\:divide-deep-orange-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-divide-opacity))
  }

  .md\:divide-deep-orange-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-divide-opacity))
  }

  .md\:divide-deep-orange-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-divide-opacity))
  }

  .md\:divide-deep-orange-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-divide-opacity))
  }

  .md\:divide-deep-orange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-divide-opacity))
  }

  .md\:divide-deep-orange-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-divide-opacity))
  }

  .md\:divide-deep-orange-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-divide-opacity))
  }

  .md\:divide-deep-orange-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-divide-opacity))
  }

  .md\:divide-deep-orange-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-divide-opacity))
  }

  .md\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-divide-opacity))
  }

  .md\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-divide-opacity))
  }

  .md\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-divide-opacity))
  }

  .md\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-divide-opacity))
  }

  .md\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-divide-opacity))
  }

  .md\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-divide-opacity))
  }

  .md\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-divide-opacity))
  }

  .md\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-divide-opacity))
  }

  .md\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-divide-opacity))
  }

  .md\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-divide-opacity))
  }

  .md\:divide-yellow > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-divide-opacity))
  }

  .md\:divide-yellow-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-divide-opacity))
  }

  .md\:divide-yellow-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-divide-opacity))
  }

  .md\:divide-yellow-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-divide-opacity))
  }

  .md\:divide-yellow-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-divide-opacity))
  }

  .md\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-divide-opacity))
  }

  .md\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-divide-opacity))
  }

  .md\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-divide-opacity))
  }

  .md\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-divide-opacity))
  }

  .md\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-divide-opacity))
  }

  .md\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-divide-opacity))
  }

  .md\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-divide-opacity))
  }

  .md\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-divide-opacity))
  }

  .md\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-divide-opacity))
  }

  .md\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-divide-opacity))
  }

  .md\:divide-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-divide-opacity))
  }

  .md\:divide-green-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-divide-opacity))
  }

  .md\:divide-green-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-divide-opacity))
  }

  .md\:divide-green-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-divide-opacity))
  }

  .md\:divide-green-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-divide-opacity))
  }

  .md\:divide-light-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-divide-opacity))
  }

  .md\:divide-light-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-divide-opacity))
  }

  .md\:divide-light-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-divide-opacity))
  }

  .md\:divide-light-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-divide-opacity))
  }

  .md\:divide-light-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-divide-opacity))
  }

  .md\:divide-light-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-divide-opacity))
  }

  .md\:divide-light-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-divide-opacity))
  }

  .md\:divide-light-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-divide-opacity))
  }

  .md\:divide-light-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-divide-opacity))
  }

  .md\:divide-light-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-divide-opacity))
  }

  .md\:divide-light-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-divide-opacity))
  }

  .md\:divide-light-green-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-divide-opacity))
  }

  .md\:divide-light-green-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-divide-opacity))
  }

  .md\:divide-light-green-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-divide-opacity))
  }

  .md\:divide-light-green-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-divide-opacity))
  }

  .md\:divide-teal-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-divide-opacity))
  }

  .md\:divide-teal-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-divide-opacity))
  }

  .md\:divide-teal-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-divide-opacity))
  }

  .md\:divide-teal-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-divide-opacity))
  }

  .md\:divide-teal-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-divide-opacity))
  }

  .md\:divide-teal-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-divide-opacity))
  }

  .md\:divide-teal-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-divide-opacity))
  }

  .md\:divide-teal-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-divide-opacity))
  }

  .md\:divide-teal-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-divide-opacity))
  }

  .md\:divide-teal-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-divide-opacity))
  }

  .md\:divide-teal > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-divide-opacity))
  }

  .md\:divide-teal-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-divide-opacity))
  }

  .md\:divide-teal-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-divide-opacity))
  }

  .md\:divide-teal-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-divide-opacity))
  }

  .md\:divide-teal-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-divide-opacity))
  }

  .md\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-divide-opacity))
  }

  .md\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-divide-opacity))
  }

  .md\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-divide-opacity))
  }

  .md\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-divide-opacity))
  }

  .md\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-divide-opacity))
  }

  .md\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-divide-opacity))
  }

  .md\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-divide-opacity))
  }

  .md\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-divide-opacity))
  }

  .md\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-divide-opacity))
  }

  .md\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-divide-opacity))
  }

  .md\:divide-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-divide-opacity))
  }

  .md\:divide-blue-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-divide-opacity))
  }

  .md\:divide-blue-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-divide-opacity))
  }

  .md\:divide-blue-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-divide-opacity))
  }

  .md\:divide-blue-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-divide-opacity))
  }

  .md\:divide-light-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-divide-opacity))
  }

  .md\:divide-light-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-divide-opacity))
  }

  .md\:divide-light-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-divide-opacity))
  }

  .md\:divide-light-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-divide-opacity))
  }

  .md\:divide-light-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-divide-opacity))
  }

  .md\:divide-light-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-divide-opacity))
  }

  .md\:divide-light-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-divide-opacity))
  }

  .md\:divide-light-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-divide-opacity))
  }

  .md\:divide-light-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-divide-opacity))
  }

  .md\:divide-light-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-divide-opacity))
  }

  .md\:divide-light-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-divide-opacity))
  }

  .md\:divide-light-blue-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-divide-opacity))
  }

  .md\:divide-light-blue-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-divide-opacity))
  }

  .md\:divide-light-blue-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-divide-opacity))
  }

  .md\:divide-light-blue-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-divide-opacity))
  }

  .md\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-divide-opacity))
  }

  .md\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-divide-opacity))
  }

  .md\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-divide-opacity))
  }

  .md\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-divide-opacity))
  }

  .md\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-divide-opacity))
  }

  .md\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-divide-opacity))
  }

  .md\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-divide-opacity))
  }

  .md\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-divide-opacity))
  }

  .md\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-divide-opacity))
  }

  .md\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-divide-opacity))
  }

  .md\:divide-indigo > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-divide-opacity))
  }

  .md\:divide-indigo-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-divide-opacity))
  }

  .md\:divide-indigo-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-divide-opacity))
  }

  .md\:divide-indigo-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-divide-opacity))
  }

  .md\:divide-indigo-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-divide-opacity))
  }

  .md\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-divide-opacity))
  }

  .md\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-divide-opacity))
  }

  .md\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-divide-opacity))
  }

  .md\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-divide-opacity))
  }

  .md\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-divide-opacity))
  }

  .md\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-divide-opacity))
  }

  .md\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-divide-opacity))
  }

  .md\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-divide-opacity))
  }

  .md\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-divide-opacity))
  }

  .md\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-divide-opacity))
  }

  .md\:divide-purple > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-divide-opacity))
  }

  .md\:divide-purple-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-divide-opacity))
  }

  .md\:divide-purple-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-divide-opacity))
  }

  .md\:divide-purple-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-divide-opacity))
  }

  .md\:divide-purple-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-divide-opacity))
  }

  .md\:divide-deep-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-divide-opacity))
  }

  .md\:divide-deep-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-divide-opacity))
  }

  .md\:divide-deep-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-divide-opacity))
  }

  .md\:divide-deep-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-divide-opacity))
  }

  .md\:divide-deep-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-divide-opacity))
  }

  .md\:divide-deep-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-divide-opacity))
  }

  .md\:divide-deep-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-divide-opacity))
  }

  .md\:divide-deep-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-divide-opacity))
  }

  .md\:divide-deep-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-divide-opacity))
  }

  .md\:divide-deep-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-divide-opacity))
  }

  .md\:divide-deep-purple > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-divide-opacity))
  }

  .md\:divide-deep-purple-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-divide-opacity))
  }

  .md\:divide-deep-purple-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-divide-opacity))
  }

  .md\:divide-deep-purple-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-divide-opacity))
  }

  .md\:divide-deep-purple-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-divide-opacity))
  }

  .md\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-divide-opacity))
  }

  .md\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-divide-opacity))
  }

  .md\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-divide-opacity))
  }

  .md\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-divide-opacity))
  }

  .md\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-divide-opacity))
  }

  .md\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-divide-opacity))
  }

  .md\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-divide-opacity))
  }

  .md\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-divide-opacity))
  }

  .md\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-divide-opacity))
  }

  .md\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-divide-opacity))
  }

  .md\:divide-pink > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-divide-opacity))
  }

  .md\:divide-pink-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-divide-opacity))
  }

  .md\:divide-pink-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-divide-opacity))
  }

  .md\:divide-pink-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-divide-opacity))
  }

  .md\:divide-pink-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-divide-opacity))
  }

  .md\:divide-lime-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-divide-opacity))
  }

  .md\:divide-lime-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-divide-opacity))
  }

  .md\:divide-lime-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-divide-opacity))
  }

  .md\:divide-lime-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-divide-opacity))
  }

  .md\:divide-lime-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-divide-opacity))
  }

  .md\:divide-lime-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-divide-opacity))
  }

  .md\:divide-lime-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-divide-opacity))
  }

  .md\:divide-lime-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-divide-opacity))
  }

  .md\:divide-lime-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-divide-opacity))
  }

  .md\:divide-lime-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-divide-opacity))
  }

  .md\:divide-lime > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-divide-opacity))
  }

  .md\:divide-lime-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-divide-opacity))
  }

  .md\:divide-lime-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-divide-opacity))
  }

  .md\:divide-lime-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-divide-opacity))
  }

  .md\:divide-lime-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-divide-opacity))
  }

  .md\:divide-amber-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-divide-opacity))
  }

  .md\:divide-amber-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-divide-opacity))
  }

  .md\:divide-amber-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-divide-opacity))
  }

  .md\:divide-amber-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-divide-opacity))
  }

  .md\:divide-amber-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-divide-opacity))
  }

  .md\:divide-amber-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-divide-opacity))
  }

  .md\:divide-amber-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-divide-opacity))
  }

  .md\:divide-amber-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-divide-opacity))
  }

  .md\:divide-amber-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-divide-opacity))
  }

  .md\:divide-amber-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-divide-opacity))
  }

  .md\:divide-amber > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-divide-opacity))
  }

  .md\:divide-amber-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-divide-opacity))
  }

  .md\:divide-amber-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-divide-opacity))
  }

  .md\:divide-amber-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-divide-opacity))
  }

  .md\:divide-amber-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-divide-opacity))
  }

  .md\:divide-brown-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-divide-opacity))
  }

  .md\:divide-brown-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-divide-opacity))
  }

  .md\:divide-brown-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-divide-opacity))
  }

  .md\:divide-brown-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-divide-opacity))
  }

  .md\:divide-brown-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-divide-opacity))
  }

  .md\:divide-brown-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-divide-opacity))
  }

  .md\:divide-brown-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-divide-opacity))
  }

  .md\:divide-brown-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-divide-opacity))
  }

  .md\:divide-brown-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-divide-opacity))
  }

  .md\:divide-brown-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-divide-opacity))
  }

  .md\:divide-brown > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-divide-opacity))
  }

  .md\:divide-brown-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-divide-opacity))
  }

  .md\:divide-brown-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-divide-opacity))
  }

  .md\:divide-brown-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-divide-opacity))
  }

  .md\:divide-brown-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-divide-opacity))
  }

  .md\:divide-blue-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-divide-opacity))
  }

  .md\:divide-blue-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-divide-opacity))
  }

  .md\:divide-blue-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-divide-opacity))
  }

  .md\:divide-blue-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-divide-opacity))
  }

  .md\:divide-blue-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-divide-opacity))
  }

  .md\:divide-blue-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-divide-opacity))
  }

  .md\:divide-blue-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-divide-opacity))
  }

  .md\:divide-blue-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-divide-opacity))
  }

  .md\:divide-blue-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-divide-opacity))
  }

  .md\:divide-blue-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-divide-opacity))
  }

  .md\:divide-blue-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-divide-opacity))
  }

  .md\:divide-blue-gray-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-divide-opacity))
  }

  .md\:divide-blue-gray-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-divide-opacity))
  }

  .md\:divide-blue-gray-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-divide-opacity))
  }

  .md\:divide-blue-gray-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-divide-opacity))
  }

  .md\:divide-cyan-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-divide-opacity))
  }

  .md\:divide-cyan-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-divide-opacity))
  }

  .md\:divide-cyan-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-divide-opacity))
  }

  .md\:divide-cyan-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-divide-opacity))
  }

  .md\:divide-cyan-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-divide-opacity))
  }

  .md\:divide-cyan-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-divide-opacity))
  }

  .md\:divide-cyan-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-divide-opacity))
  }

  .md\:divide-cyan-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-divide-opacity))
  }

  .md\:divide-cyan-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-divide-opacity))
  }

  .md\:divide-cyan-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-divide-opacity))
  }

  .md\:divide-cyan > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-divide-opacity))
  }

  .md\:divide-cyan-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-divide-opacity))
  }

  .md\:divide-cyan-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-divide-opacity))
  }

  .md\:divide-cyan-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-divide-opacity))
  }

  .md\:divide-cyan-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-divide-opacity))
  }

  .md\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid
  }

  .md\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed
  }

  .md\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted
  }

  .md\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double
  }

  .md\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none
  }

  .md\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0
  }

  .md\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05
  }

  .md\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1
  }

  .md\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2
  }

  .md\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25
  }

  .md\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3
  }

  .md\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4
  }

  .md\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5
  }

  .md\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6
  }

  .md\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7
  }

  .md\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75
  }

  .md\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8
  }

  .md\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9
  }

  .md\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95
  }

  .md\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1
  }

  .md\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .md\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .md\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .md\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .md\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .md\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .md\:appearance-none {
    -webkit-appearance: none;
            appearance: none
  }

  .md\:bg-fixed {
    background-attachment: fixed
  }

  .md\:bg-local {
    background-attachment: local
  }

  .md\:bg-scroll {
    background-attachment: scroll
  }

  .md\:bg-clip-border {
    background-clip: border-box
  }

  .md\:bg-clip-padding {
    background-clip: padding-box
  }

  .md\:bg-clip-content {
    background-clip: content-box
  }

  .md\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text
  }

  .md\:bg-transparent {
    background-color: transparent
  }

  .md\:bg-current {
    background-color: currentColor
  }

  .md\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .md\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .md\:bg-grey-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .md\:bg-grey-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .md\:bg-grey-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .md\:bg-grey-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .md\:bg-grey-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .md\:bg-grey-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:bg-grey-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .md\:bg-grey-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:bg-grey-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .md\:bg-grey-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .md\:bg-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:bg-grey-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .md\:bg-grey-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .md\:bg-grey-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .md\:bg-grey-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .md\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .md\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .md\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .md\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .md\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .md\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .md\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .md\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:bg-gray-hover {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .md\:bg-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .md\:bg-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .md\:bg-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .md\:bg-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .md\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .md\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .md\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .md\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .md\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .md\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .md\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .md\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .md\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .md\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .md\:bg-red-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .md\:bg-red-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .md\:bg-red-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .md\:bg-red-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .md\:bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .md\:bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .md\:bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .md\:bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .md\:bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .md\:bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .md\:bg-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .md\:bg-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .md\:bg-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .md\:bg-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .md\:bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .md\:bg-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .md\:bg-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .md\:bg-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .md\:bg-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .md\:bg-deep-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .md\:bg-deep-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .md\:bg-deep-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .md\:bg-deep-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .md\:bg-deep-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .md\:bg-deep-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .md\:bg-deep-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .md\:bg-deep-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .md\:bg-deep-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .md\:bg-deep-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .md\:bg-deep-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .md\:bg-deep-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .md\:bg-deep-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .md\:bg-deep-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .md\:bg-deep-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .md\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .md\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .md\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .md\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .md\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .md\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .md\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .md\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .md\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .md\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .md\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .md\:bg-yellow-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .md\:bg-yellow-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .md\:bg-yellow-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .md\:bg-yellow-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .md\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .md\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .md\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .md\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .md\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .md\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .md\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .md\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .md\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .md\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .md\:bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .md\:bg-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .md\:bg-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .md\:bg-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .md\:bg-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .md\:bg-light-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .md\:bg-light-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .md\:bg-light-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .md\:bg-light-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .md\:bg-light-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .md\:bg-light-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .md\:bg-light-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .md\:bg-light-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .md\:bg-light-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .md\:bg-light-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .md\:bg-light-green {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .md\:bg-light-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .md\:bg-light-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .md\:bg-light-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .md\:bg-light-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .md\:bg-teal-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .md\:bg-teal-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .md\:bg-teal-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .md\:bg-teal-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .md\:bg-teal-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .md\:bg-teal-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .md\:bg-teal-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .md\:bg-teal-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .md\:bg-teal-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .md\:bg-teal-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .md\:bg-teal {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .md\:bg-teal-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .md\:bg-teal-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .md\:bg-teal-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .md\:bg-teal-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .md\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .md\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .md\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .md\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .md\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .md\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .md\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .md\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .md\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .md\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .md\:bg-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .md\:bg-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .md\:bg-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .md\:bg-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .md\:bg-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .md\:bg-light-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .md\:bg-light-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .md\:bg-light-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .md\:bg-light-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .md\:bg-light-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .md\:bg-light-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .md\:bg-light-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .md\:bg-light-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .md\:bg-light-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .md\:bg-light-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .md\:bg-light-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .md\:bg-light-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .md\:bg-light-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .md\:bg-light-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .md\:bg-light-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .md\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .md\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .md\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .md\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .md\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .md\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .md\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .md\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .md\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .md\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .md\:bg-indigo {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .md\:bg-indigo-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .md\:bg-indigo-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .md\:bg-indigo-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .md\:bg-indigo-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .md\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .md\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .md\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .md\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .md\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .md\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .md\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .md\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .md\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .md\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .md\:bg-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .md\:bg-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .md\:bg-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .md\:bg-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .md\:bg-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .md\:bg-deep-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .md\:bg-deep-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .md\:bg-deep-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .md\:bg-deep-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .md\:bg-deep-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .md\:bg-deep-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .md\:bg-deep-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .md\:bg-deep-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .md\:bg-deep-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .md\:bg-deep-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .md\:bg-deep-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .md\:bg-deep-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .md\:bg-deep-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .md\:bg-deep-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .md\:bg-deep-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .md\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .md\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .md\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .md\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .md\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .md\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .md\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .md\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .md\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .md\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .md\:bg-pink {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .md\:bg-pink-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .md\:bg-pink-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .md\:bg-pink-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .md\:bg-pink-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .md\:bg-lime-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .md\:bg-lime-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .md\:bg-lime-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .md\:bg-lime-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .md\:bg-lime-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .md\:bg-lime-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .md\:bg-lime-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .md\:bg-lime-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .md\:bg-lime-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .md\:bg-lime-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .md\:bg-lime {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .md\:bg-lime-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .md\:bg-lime-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .md\:bg-lime-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .md\:bg-lime-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .md\:bg-amber-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .md\:bg-amber-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .md\:bg-amber-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .md\:bg-amber-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .md\:bg-amber-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .md\:bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .md\:bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .md\:bg-amber-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .md\:bg-amber-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .md\:bg-amber-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .md\:bg-amber {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .md\:bg-amber-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .md\:bg-amber-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .md\:bg-amber-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .md\:bg-amber-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .md\:bg-brown-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .md\:bg-brown-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .md\:bg-brown-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .md\:bg-brown-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .md\:bg-brown-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .md\:bg-brown-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .md\:bg-brown-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .md\:bg-brown-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .md\:bg-brown-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .md\:bg-brown-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .md\:bg-brown {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .md\:bg-brown-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .md\:bg-brown-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .md\:bg-brown-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .md\:bg-brown-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .md\:bg-blue-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .md\:bg-blue-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .md\:bg-blue-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .md\:bg-blue-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .md\:bg-blue-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .md\:bg-blue-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .md\:bg-blue-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .md\:bg-blue-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .md\:bg-blue-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .md\:bg-blue-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .md\:bg-blue-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .md\:bg-blue-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .md\:bg-blue-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .md\:bg-blue-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .md\:bg-blue-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .md\:bg-cyan-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .md\:bg-cyan-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .md\:bg-cyan-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .md\:bg-cyan-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .md\:bg-cyan-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .md\:bg-cyan-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .md\:bg-cyan-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .md\:bg-cyan-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .md\:bg-cyan-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .md\:bg-cyan-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .md\:bg-cyan {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .md\:bg-cyan-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .md\:bg-cyan-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .md\:bg-cyan-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .md\:bg-cyan-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-transparent {
    background-color: transparent
  }

  .group:hover .md\:group-hover\:bg-current {
    background-color: currentColor
  }

  .group:hover .md\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-grey-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-grey-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-grey-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-grey-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-grey-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-grey-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-grey-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-grey-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-grey-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-grey-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-grey-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-grey-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-grey-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-grey-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray-hover {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .group:hover .md\:group-hover\:bg-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-yellow-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-yellow-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-yellow-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-yellow-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-green {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-teal-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-teal-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-teal-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-teal-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-teal-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-teal-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-teal-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-teal-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-teal-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-teal-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-teal {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-teal-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-teal-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-teal-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-teal-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-light-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-indigo {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-indigo-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-indigo-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-indigo-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-indigo-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-deep-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-pink {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-pink-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-pink-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-pink-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-pink-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-lime-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-lime-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-lime-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-lime-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-lime-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-lime-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-lime-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-lime-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-lime-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-lime-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-lime {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-lime-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-lime-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-lime-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-lime-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-amber-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-amber-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-amber-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-amber-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-amber-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-amber-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-amber-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-amber-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-amber {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-amber-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-amber-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-amber-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-amber-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-brown-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-brown-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-brown-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-brown-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-brown-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-brown-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-brown-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-brown-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-brown-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-brown-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-brown {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-brown-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-brown-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-brown-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-brown-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-blue-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-transparent:focus-within {
    background-color: transparent
  }

  .md\:focus-within\:bg-current:focus-within {
    background-color: currentColor
  }

  .md\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-grey-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-grey-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-grey-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-grey-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-grey-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-grey-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-grey-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-grey-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-grey-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-grey-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-grey-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-grey-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-grey-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-grey-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-gray-hover:focus-within {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .md\:focus-within\:bg-gray-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-gray-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-gray-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-gray-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-red:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-red-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-red-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-red-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-red-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-orange-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-orange-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-orange-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-orange-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-orange-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-orange-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-orange-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-orange-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-orange-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-orange-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-orange:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-orange-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-orange-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-orange-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-orange-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-orange-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-orange-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-orange-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-orange-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-orange-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-orange-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-orange-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-orange-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-orange-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-orange-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-orange:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-orange-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-orange-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-orange-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-orange-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-yellow:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-yellow-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-yellow-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-yellow-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-yellow-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-green-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-green-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-green-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-green-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-green-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-green-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-green-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-green-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-teal-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-teal-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-teal-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-teal-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-teal-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-teal-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-teal-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-teal-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-teal-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-teal-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-teal:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-teal-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-teal-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-teal-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-teal-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-blue-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-blue-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-blue-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-light-blue-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-indigo:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-indigo-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-indigo-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-indigo-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-indigo-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-purple:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-purple-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-purple-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-purple-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-purple-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-purple:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-purple-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-purple-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-purple-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-deep-purple-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-pink:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-pink-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-pink-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-pink-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-pink-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-lime-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-lime-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-lime-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-lime-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-lime-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-lime-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-lime-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-lime-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-lime-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-lime-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-lime:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-lime-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-lime-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-lime-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-lime-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-amber-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-amber-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-amber-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-amber-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-amber-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-amber-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-amber-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-amber-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-amber-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-amber-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-amber:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-amber-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-amber-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-amber-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-amber-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-brown-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-brown-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-brown-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-brown-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-brown-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-brown-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-brown-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-brown-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-brown-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-brown-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-brown:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-brown-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-brown-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-brown-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-brown-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-gray-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-gray-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-gray-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-blue-gray-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-cyan-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-cyan-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-cyan-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-cyan-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-cyan-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-cyan-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-cyan-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-cyan-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-cyan-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-cyan-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-cyan:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-cyan-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-cyan-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-cyan-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .md\:focus-within\:bg-cyan-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-transparent:hover {
    background-color: transparent
  }

  .md\:hover\:bg-current:hover {
    background-color: currentColor
  }

  .md\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-grey-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-grey-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-grey-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-grey-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-grey-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-grey-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-grey-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-grey-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-grey-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-grey-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-grey-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-grey-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-grey-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-grey-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray-hover:hover {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .md\:hover\:bg-gray-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-orange-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-orange-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-orange-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-orange-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-orange-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-orange-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-orange-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-orange-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-orange-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-orange-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-orange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-orange-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-orange-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-orange-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-orange-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-orange-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-orange-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-orange-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-orange-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-orange-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-orange-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-orange-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-orange-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-orange-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-orange-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-orange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-orange-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-orange-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-orange-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-orange-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-yellow:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-yellow-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-yellow-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-yellow-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-yellow-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-green-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-green-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-green-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-green-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-teal-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-teal-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-teal-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-teal-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-teal-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-teal-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-teal-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-teal-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-teal-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-teal-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-teal:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-teal-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-teal-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-teal-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-teal-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-blue-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-blue-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-blue-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-light-blue-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-indigo:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-indigo-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-indigo-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-indigo-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-indigo-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-purple:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-purple-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-purple-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-purple-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-purple-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-purple:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-purple-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-purple-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-purple-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-deep-purple-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-pink:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-pink-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-pink-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-pink-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-pink-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-lime-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-lime-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-lime-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-lime-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-lime-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-lime-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-lime-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-lime-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-lime-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-lime-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-lime:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-lime-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-lime-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-lime-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-lime-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-amber-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-amber-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-amber-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-amber-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-amber-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-amber-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-amber-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-amber-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-amber-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-amber-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-amber:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-amber-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-amber-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-amber-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-amber-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-brown-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-brown-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-brown-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-brown-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-brown-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-brown-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-brown-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-brown-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-brown-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-brown-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-brown:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-brown-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-brown-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-brown-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-brown-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-gray-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-gray-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-gray-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-blue-gray-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-transparent:focus {
    background-color: transparent
  }

  .md\:focus\:bg-current:focus {
    background-color: currentColor
  }

  .md\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-grey-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-grey-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-grey-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-grey-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-grey-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-grey-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-grey-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-grey-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-grey-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-grey-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-grey-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-grey-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-grey-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-grey-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray-hover:focus {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .md\:focus\:bg-gray-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-orange-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-orange-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-orange-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-orange-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-orange-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-orange-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-orange-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-orange-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-orange-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-orange-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-orange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-orange-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-orange-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-orange-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-orange-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-orange-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-orange-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-orange-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-orange-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-orange-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-orange-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-orange-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-orange-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-orange-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-orange-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-orange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-orange-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-orange-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-orange-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-orange-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-yellow:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-yellow-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-yellow-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-yellow-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-yellow-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-green-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-green-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-green-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-green-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-teal-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-teal-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-teal-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-teal-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-teal-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-teal-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-teal-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-teal-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-teal-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-teal-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-teal:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-teal-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-teal-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-teal-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-teal-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-blue-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-blue-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-blue-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-light-blue-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-indigo:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-indigo-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-indigo-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-indigo-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-indigo-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-purple:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-purple-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-purple-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-purple-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-purple-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-purple:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-purple-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-purple-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-purple-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-deep-purple-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-pink:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-pink-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-pink-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-pink-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-pink-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-lime-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-lime-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-lime-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-lime-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-lime-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-lime-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-lime-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-lime-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-lime-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-lime-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-lime:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-lime-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-lime-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-lime-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-lime-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-amber-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-amber-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-amber-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-amber-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-amber-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-amber-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-amber-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-amber-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-amber-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-amber-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-amber:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-amber-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-amber-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-amber-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-amber-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-brown-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-brown-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-brown-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-brown-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-brown-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-brown-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-brown-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-brown-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-brown-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-brown-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-brown:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-brown-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-brown-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-brown-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-brown-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-gray-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-gray-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-gray-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-blue-gray-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .md\:bg-none {
    background-image: none
  }

  .md\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .md\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:from-black {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .md\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:from-grey-50 {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .md\:from-grey-100 {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .md\:from-grey-200 {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .md\:from-grey-300 {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .md\:from-grey-400 {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .md\:from-grey-500 {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:from-grey-600 {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .md\:from-grey-700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:from-grey-800 {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .md\:from-grey-900 {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .md\:from-grey {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:from-grey-A100 {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .md\:from-grey-A200 {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .md\:from-grey-A400 {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .md\:from-grey-A700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:from-gray-50 {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .md\:from-gray-100 {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .md\:from-gray-200 {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .md\:from-gray-300 {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .md\:from-gray-400 {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .md\:from-gray-500 {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:from-gray-600 {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .md\:from-gray-700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:from-gray-800 {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .md\:from-gray-900 {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .md\:from-gray {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:from-gray-hover {
    --tw-gradient-from: rgba(0, 0, 0, 0.04);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:from-gray-A100 {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .md\:from-gray-A200 {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .md\:from-gray-A400 {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .md\:from-gray-A700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:from-red-50 {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .md\:from-red-100 {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .md\:from-red-200 {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .md\:from-red-300 {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .md\:from-red-400 {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .md\:from-red-500 {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .md\:from-red-600 {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .md\:from-red-700 {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .md\:from-red-800 {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .md\:from-red-900 {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .md\:from-red {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .md\:from-red-A100 {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .md\:from-red-A200 {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .md\:from-red-A400 {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .md\:from-red-A700 {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .md\:from-orange-50 {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .md\:from-orange-100 {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .md\:from-orange-200 {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .md\:from-orange-300 {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .md\:from-orange-400 {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .md\:from-orange-500 {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .md\:from-orange-600 {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .md\:from-orange-700 {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .md\:from-orange-800 {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .md\:from-orange-900 {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .md\:from-orange {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .md\:from-orange-A100 {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .md\:from-orange-A200 {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .md\:from-orange-A400 {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .md\:from-orange-A700 {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .md\:from-deep-orange-50 {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .md\:from-deep-orange-100 {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .md\:from-deep-orange-200 {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .md\:from-deep-orange-300 {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .md\:from-deep-orange-400 {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .md\:from-deep-orange-500 {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .md\:from-deep-orange-600 {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .md\:from-deep-orange-700 {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .md\:from-deep-orange-800 {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .md\:from-deep-orange-900 {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .md\:from-deep-orange {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .md\:from-deep-orange-A100 {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .md\:from-deep-orange-A200 {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .md\:from-deep-orange-A400 {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .md\:from-deep-orange-A700 {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .md\:from-yellow-50 {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .md\:from-yellow-100 {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .md\:from-yellow-200 {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .md\:from-yellow-300 {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .md\:from-yellow-400 {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .md\:from-yellow-500 {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .md\:from-yellow-600 {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .md\:from-yellow-700 {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .md\:from-yellow-800 {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .md\:from-yellow-900 {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .md\:from-yellow {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .md\:from-yellow-A100 {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .md\:from-yellow-A200 {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .md\:from-yellow-A400 {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .md\:from-yellow-A700 {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .md\:from-green-50 {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .md\:from-green-100 {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .md\:from-green-200 {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .md\:from-green-300 {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .md\:from-green-400 {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .md\:from-green-500 {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .md\:from-green-600 {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .md\:from-green-700 {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .md\:from-green-800 {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .md\:from-green-900 {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .md\:from-green {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .md\:from-green-A100 {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .md\:from-green-A200 {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .md\:from-green-A400 {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .md\:from-green-A700 {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .md\:from-light-green-50 {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .md\:from-light-green-100 {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .md\:from-light-green-200 {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .md\:from-light-green-300 {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .md\:from-light-green-400 {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .md\:from-light-green-500 {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .md\:from-light-green-600 {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .md\:from-light-green-700 {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .md\:from-light-green-800 {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .md\:from-light-green-900 {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .md\:from-light-green {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .md\:from-light-green-A100 {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .md\:from-light-green-A200 {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .md\:from-light-green-A400 {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .md\:from-light-green-A700 {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .md\:from-teal-50 {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .md\:from-teal-100 {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .md\:from-teal-200 {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .md\:from-teal-300 {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .md\:from-teal-400 {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .md\:from-teal-500 {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .md\:from-teal-600 {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .md\:from-teal-700 {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .md\:from-teal-800 {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .md\:from-teal-900 {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .md\:from-teal {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .md\:from-teal-A100 {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .md\:from-teal-A200 {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .md\:from-teal-A400 {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .md\:from-teal-A700 {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .md\:from-blue-50 {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .md\:from-blue-100 {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .md\:from-blue-200 {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .md\:from-blue-300 {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .md\:from-blue-400 {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .md\:from-blue-500 {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .md\:from-blue-600 {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .md\:from-blue-700 {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .md\:from-blue-800 {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .md\:from-blue-900 {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .md\:from-blue {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .md\:from-blue-A100 {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .md\:from-blue-A200 {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .md\:from-blue-A400 {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .md\:from-blue-A700 {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .md\:from-light-blue-50 {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .md\:from-light-blue-100 {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .md\:from-light-blue-200 {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .md\:from-light-blue-300 {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .md\:from-light-blue-400 {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .md\:from-light-blue-500 {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .md\:from-light-blue-600 {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .md\:from-light-blue-700 {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .md\:from-light-blue-800 {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .md\:from-light-blue-900 {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .md\:from-light-blue {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .md\:from-light-blue-A100 {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .md\:from-light-blue-A200 {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .md\:from-light-blue-A400 {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .md\:from-light-blue-A700 {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .md\:from-indigo-50 {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .md\:from-indigo-100 {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .md\:from-indigo-200 {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .md\:from-indigo-300 {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .md\:from-indigo-400 {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .md\:from-indigo-500 {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .md\:from-indigo-600 {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .md\:from-indigo-700 {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .md\:from-indigo-800 {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .md\:from-indigo-900 {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .md\:from-indigo {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .md\:from-indigo-A100 {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .md\:from-indigo-A200 {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .md\:from-indigo-A400 {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .md\:from-indigo-A700 {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .md\:from-purple-50 {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .md\:from-purple-100 {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .md\:from-purple-200 {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .md\:from-purple-300 {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .md\:from-purple-400 {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .md\:from-purple-500 {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .md\:from-purple-600 {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .md\:from-purple-700 {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .md\:from-purple-800 {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .md\:from-purple-900 {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .md\:from-purple {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .md\:from-purple-A100 {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .md\:from-purple-A200 {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .md\:from-purple-A400 {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .md\:from-purple-A700 {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .md\:from-deep-purple-50 {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .md\:from-deep-purple-100 {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .md\:from-deep-purple-200 {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .md\:from-deep-purple-300 {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .md\:from-deep-purple-400 {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .md\:from-deep-purple-500 {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .md\:from-deep-purple-600 {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .md\:from-deep-purple-700 {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .md\:from-deep-purple-800 {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .md\:from-deep-purple-900 {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .md\:from-deep-purple {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .md\:from-deep-purple-A100 {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .md\:from-deep-purple-A200 {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .md\:from-deep-purple-A400 {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .md\:from-deep-purple-A700 {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .md\:from-pink-50 {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .md\:from-pink-100 {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .md\:from-pink-200 {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .md\:from-pink-300 {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .md\:from-pink-400 {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .md\:from-pink-500 {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .md\:from-pink-600 {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .md\:from-pink-700 {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .md\:from-pink-800 {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .md\:from-pink-900 {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .md\:from-pink {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .md\:from-pink-A100 {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .md\:from-pink-A200 {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .md\:from-pink-A400 {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .md\:from-pink-A700 {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .md\:from-lime-50 {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .md\:from-lime-100 {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .md\:from-lime-200 {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .md\:from-lime-300 {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .md\:from-lime-400 {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .md\:from-lime-500 {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .md\:from-lime-600 {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .md\:from-lime-700 {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .md\:from-lime-800 {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .md\:from-lime-900 {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .md\:from-lime {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .md\:from-lime-A100 {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .md\:from-lime-A200 {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .md\:from-lime-A400 {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .md\:from-lime-A700 {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .md\:from-amber-50 {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .md\:from-amber-100 {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .md\:from-amber-200 {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .md\:from-amber-300 {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .md\:from-amber-400 {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .md\:from-amber-500 {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .md\:from-amber-600 {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .md\:from-amber-700 {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .md\:from-amber-800 {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .md\:from-amber-900 {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .md\:from-amber {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .md\:from-amber-A100 {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .md\:from-amber-A200 {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .md\:from-amber-A400 {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .md\:from-amber-A700 {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .md\:from-brown-50 {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .md\:from-brown-100 {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .md\:from-brown-200 {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .md\:from-brown-300 {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .md\:from-brown-400 {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .md\:from-brown-500 {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .md\:from-brown-600 {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .md\:from-brown-700 {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .md\:from-brown-800 {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .md\:from-brown-900 {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .md\:from-brown {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .md\:from-brown-A100 {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .md\:from-brown-A200 {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .md\:from-brown-A400 {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .md\:from-brown-A700 {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .md\:from-blue-gray-50 {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .md\:from-blue-gray-100 {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .md\:from-blue-gray-200 {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .md\:from-blue-gray-300 {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .md\:from-blue-gray-400 {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .md\:from-blue-gray-500 {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .md\:from-blue-gray-600 {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .md\:from-blue-gray-700 {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .md\:from-blue-gray-800 {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .md\:from-blue-gray-900 {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .md\:from-blue-gray {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .md\:from-blue-gray-A100 {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .md\:from-blue-gray-A200 {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .md\:from-blue-gray-A400 {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .md\:from-blue-gray-A700 {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .md\:from-cyan-50 {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .md\:from-cyan-100 {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .md\:from-cyan-200 {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .md\:from-cyan-300 {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .md\:from-cyan-400 {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .md\:from-cyan-500 {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .md\:from-cyan-600 {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .md\:from-cyan-700 {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .md\:from-cyan-800 {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .md\:from-cyan-900 {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .md\:from-cyan {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .md\:from-cyan-A100 {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .md\:from-cyan-A200 {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .md\:from-cyan-A400 {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .md\:from-cyan-A700 {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .md\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .md\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:via-grey-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .md\:via-grey-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .md\:via-grey-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .md\:via-grey-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .md\:via-grey-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .md\:via-grey-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:via-grey-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .md\:via-grey-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:via-grey-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .md\:via-grey-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .md\:via-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:via-grey-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .md\:via-grey-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .md\:via-grey-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .md\:via-grey-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .md\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .md\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .md\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .md\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .md\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .md\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .md\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .md\:via-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:via-gray-hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:via-gray-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .md\:via-gray-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .md\:via-gray-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .md\:via-gray-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .md\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .md\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .md\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .md\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .md\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .md\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .md\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .md\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .md\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .md\:via-red {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .md\:via-red-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .md\:via-red-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .md\:via-red-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .md\:via-red-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .md\:via-orange-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .md\:via-orange-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .md\:via-orange-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .md\:via-orange-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .md\:via-orange-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .md\:via-orange-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .md\:via-orange-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .md\:via-orange-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .md\:via-orange-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .md\:via-orange-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .md\:via-orange {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .md\:via-orange-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .md\:via-orange-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .md\:via-orange-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .md\:via-orange-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .md\:via-deep-orange-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .md\:via-deep-orange-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .md\:via-deep-orange-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .md\:via-deep-orange-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .md\:via-deep-orange-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .md\:via-deep-orange-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .md\:via-deep-orange-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .md\:via-deep-orange-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .md\:via-deep-orange-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .md\:via-deep-orange-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .md\:via-deep-orange {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .md\:via-deep-orange-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .md\:via-deep-orange-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .md\:via-deep-orange-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .md\:via-deep-orange-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .md\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .md\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .md\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .md\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .md\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .md\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .md\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .md\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .md\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .md\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .md\:via-yellow {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .md\:via-yellow-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .md\:via-yellow-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .md\:via-yellow-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .md\:via-yellow-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .md\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .md\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .md\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .md\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .md\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .md\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .md\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .md\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .md\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .md\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .md\:via-green {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .md\:via-green-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .md\:via-green-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .md\:via-green-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .md\:via-green-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .md\:via-light-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .md\:via-light-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .md\:via-light-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .md\:via-light-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .md\:via-light-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .md\:via-light-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .md\:via-light-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .md\:via-light-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .md\:via-light-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .md\:via-light-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .md\:via-light-green {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .md\:via-light-green-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .md\:via-light-green-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .md\:via-light-green-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .md\:via-light-green-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .md\:via-teal-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .md\:via-teal-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .md\:via-teal-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .md\:via-teal-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .md\:via-teal-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .md\:via-teal-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .md\:via-teal-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .md\:via-teal-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .md\:via-teal-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .md\:via-teal-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .md\:via-teal {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .md\:via-teal-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .md\:via-teal-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .md\:via-teal-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .md\:via-teal-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .md\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .md\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .md\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .md\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .md\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .md\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .md\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .md\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .md\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .md\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .md\:via-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .md\:via-blue-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .md\:via-blue-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .md\:via-blue-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .md\:via-blue-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .md\:via-light-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .md\:via-light-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .md\:via-light-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .md\:via-light-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .md\:via-light-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .md\:via-light-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .md\:via-light-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .md\:via-light-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .md\:via-light-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .md\:via-light-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .md\:via-light-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .md\:via-light-blue-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .md\:via-light-blue-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .md\:via-light-blue-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .md\:via-light-blue-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .md\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .md\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .md\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .md\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .md\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .md\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .md\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .md\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .md\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .md\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .md\:via-indigo {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .md\:via-indigo-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .md\:via-indigo-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .md\:via-indigo-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .md\:via-indigo-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .md\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .md\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .md\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .md\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .md\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .md\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .md\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .md\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .md\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .md\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .md\:via-purple {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .md\:via-purple-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .md\:via-purple-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .md\:via-purple-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .md\:via-purple-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .md\:via-deep-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .md\:via-deep-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .md\:via-deep-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .md\:via-deep-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .md\:via-deep-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .md\:via-deep-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .md\:via-deep-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .md\:via-deep-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .md\:via-deep-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .md\:via-deep-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .md\:via-deep-purple {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .md\:via-deep-purple-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .md\:via-deep-purple-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .md\:via-deep-purple-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .md\:via-deep-purple-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .md\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .md\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .md\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .md\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .md\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .md\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .md\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .md\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .md\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .md\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .md\:via-pink {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .md\:via-pink-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .md\:via-pink-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .md\:via-pink-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .md\:via-pink-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .md\:via-lime-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .md\:via-lime-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .md\:via-lime-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .md\:via-lime-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .md\:via-lime-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .md\:via-lime-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .md\:via-lime-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .md\:via-lime-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .md\:via-lime-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .md\:via-lime-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .md\:via-lime {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .md\:via-lime-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .md\:via-lime-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .md\:via-lime-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .md\:via-lime-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .md\:via-amber-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .md\:via-amber-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .md\:via-amber-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .md\:via-amber-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .md\:via-amber-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .md\:via-amber-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .md\:via-amber-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .md\:via-amber-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .md\:via-amber-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .md\:via-amber-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .md\:via-amber {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .md\:via-amber-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .md\:via-amber-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .md\:via-amber-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .md\:via-amber-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .md\:via-brown-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .md\:via-brown-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .md\:via-brown-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .md\:via-brown-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .md\:via-brown-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .md\:via-brown-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .md\:via-brown-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .md\:via-brown-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .md\:via-brown-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .md\:via-brown-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .md\:via-brown {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .md\:via-brown-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .md\:via-brown-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .md\:via-brown-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .md\:via-brown-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .md\:via-blue-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .md\:via-blue-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .md\:via-blue-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .md\:via-blue-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .md\:via-blue-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .md\:via-blue-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .md\:via-blue-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .md\:via-blue-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .md\:via-blue-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .md\:via-blue-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .md\:via-blue-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .md\:via-blue-gray-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .md\:via-blue-gray-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .md\:via-blue-gray-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .md\:via-blue-gray-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .md\:via-cyan-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .md\:via-cyan-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .md\:via-cyan-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .md\:via-cyan-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .md\:via-cyan-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .md\:via-cyan-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .md\:via-cyan-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .md\:via-cyan-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .md\:via-cyan-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .md\:via-cyan-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .md\:via-cyan {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .md\:via-cyan-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .md\:via-cyan-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .md\:via-cyan-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .md\:via-cyan-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .md\:to-transparent {
    --tw-gradient-to: transparent
  }

  .md\:to-current {
    --tw-gradient-to: currentColor
  }

  .md\:to-black {
    --tw-gradient-to: #22292F
  }

  .md\:to-white {
    --tw-gradient-to: #fff
  }

  .md\:to-grey-50 {
    --tw-gradient-to: #FAFAFA
  }

  .md\:to-grey-100 {
    --tw-gradient-to: #F5F5F5
  }

  .md\:to-grey-200 {
    --tw-gradient-to: #EEEEEE
  }

  .md\:to-grey-300 {
    --tw-gradient-to: #E0E0E0
  }

  .md\:to-grey-400 {
    --tw-gradient-to: #BDBDBD
  }

  .md\:to-grey-500 {
    --tw-gradient-to: #9E9E9E
  }

  .md\:to-grey-600 {
    --tw-gradient-to: #757575
  }

  .md\:to-grey-700 {
    --tw-gradient-to: #616161
  }

  .md\:to-grey-800 {
    --tw-gradient-to: #424242
  }

  .md\:to-grey-900 {
    --tw-gradient-to: #212121
  }

  .md\:to-grey {
    --tw-gradient-to: #9E9E9E
  }

  .md\:to-grey-A100 {
    --tw-gradient-to: #D5D5D5
  }

  .md\:to-grey-A200 {
    --tw-gradient-to: #AAAAAA
  }

  .md\:to-grey-A400 {
    --tw-gradient-to: #303030
  }

  .md\:to-grey-A700 {
    --tw-gradient-to: #616161
  }

  .md\:to-gray-50 {
    --tw-gradient-to: #FAFAFA
  }

  .md\:to-gray-100 {
    --tw-gradient-to: #F5F5F5
  }

  .md\:to-gray-200 {
    --tw-gradient-to: #EEEEEE
  }

  .md\:to-gray-300 {
    --tw-gradient-to: #E0E0E0
  }

  .md\:to-gray-400 {
    --tw-gradient-to: #BDBDBD
  }

  .md\:to-gray-500 {
    --tw-gradient-to: #9E9E9E
  }

  .md\:to-gray-600 {
    --tw-gradient-to: #757575
  }

  .md\:to-gray-700 {
    --tw-gradient-to: #616161
  }

  .md\:to-gray-800 {
    --tw-gradient-to: #424242
  }

  .md\:to-gray-900 {
    --tw-gradient-to: #212121
  }

  .md\:to-gray {
    --tw-gradient-to: #9E9E9E
  }

  .md\:to-gray-hover {
    --tw-gradient-to: rgba(0, 0, 0, 0.04)
  }

  .md\:to-gray-A100 {
    --tw-gradient-to: #D5D5D5
  }

  .md\:to-gray-A200 {
    --tw-gradient-to: #AAAAAA
  }

  .md\:to-gray-A400 {
    --tw-gradient-to: #303030
  }

  .md\:to-gray-A700 {
    --tw-gradient-to: #616161
  }

  .md\:to-red-50 {
    --tw-gradient-to: #FFEBEE
  }

  .md\:to-red-100 {
    --tw-gradient-to: #FFCDD2
  }

  .md\:to-red-200 {
    --tw-gradient-to: #EF9A9A
  }

  .md\:to-red-300 {
    --tw-gradient-to: #E57373
  }

  .md\:to-red-400 {
    --tw-gradient-to: #EF5350
  }

  .md\:to-red-500 {
    --tw-gradient-to: #F44336
  }

  .md\:to-red-600 {
    --tw-gradient-to: #E53935
  }

  .md\:to-red-700 {
    --tw-gradient-to: #D32F2F
  }

  .md\:to-red-800 {
    --tw-gradient-to: #C62828
  }

  .md\:to-red-900 {
    --tw-gradient-to: #B71C1C
  }

  .md\:to-red {
    --tw-gradient-to: #F44336
  }

  .md\:to-red-A100 {
    --tw-gradient-to: #FF8A80
  }

  .md\:to-red-A200 {
    --tw-gradient-to: #FF5252
  }

  .md\:to-red-A400 {
    --tw-gradient-to: #FF1744
  }

  .md\:to-red-A700 {
    --tw-gradient-to: #D50000
  }

  .md\:to-orange-50 {
    --tw-gradient-to: #FFF3E0
  }

  .md\:to-orange-100 {
    --tw-gradient-to: #FFE0B2
  }

  .md\:to-orange-200 {
    --tw-gradient-to: #FFCC80
  }

  .md\:to-orange-300 {
    --tw-gradient-to: #FFB74D
  }

  .md\:to-orange-400 {
    --tw-gradient-to: #FFA726
  }

  .md\:to-orange-500 {
    --tw-gradient-to: #FF9800
  }

  .md\:to-orange-600 {
    --tw-gradient-to: #FB8C00
  }

  .md\:to-orange-700 {
    --tw-gradient-to: #F57C00
  }

  .md\:to-orange-800 {
    --tw-gradient-to: #EF6C00
  }

  .md\:to-orange-900 {
    --tw-gradient-to: #E65100
  }

  .md\:to-orange {
    --tw-gradient-to: #FF9800
  }

  .md\:to-orange-A100 {
    --tw-gradient-to: #FFD180
  }

  .md\:to-orange-A200 {
    --tw-gradient-to: #FFAB40
  }

  .md\:to-orange-A400 {
    --tw-gradient-to: #FF9100
  }

  .md\:to-orange-A700 {
    --tw-gradient-to: #FF6D00
  }

  .md\:to-deep-orange-50 {
    --tw-gradient-to: #FBE9E7
  }

  .md\:to-deep-orange-100 {
    --tw-gradient-to: #FFCCBC
  }

  .md\:to-deep-orange-200 {
    --tw-gradient-to: #FFAB91
  }

  .md\:to-deep-orange-300 {
    --tw-gradient-to: #FF8A65
  }

  .md\:to-deep-orange-400 {
    --tw-gradient-to: #FF7043
  }

  .md\:to-deep-orange-500 {
    --tw-gradient-to: #FF5722
  }

  .md\:to-deep-orange-600 {
    --tw-gradient-to: #F4511E
  }

  .md\:to-deep-orange-700 {
    --tw-gradient-to: #E64A19
  }

  .md\:to-deep-orange-800 {
    --tw-gradient-to: #D84315
  }

  .md\:to-deep-orange-900 {
    --tw-gradient-to: #BF360C
  }

  .md\:to-deep-orange {
    --tw-gradient-to: #FF5722
  }

  .md\:to-deep-orange-A100 {
    --tw-gradient-to: #FF9E80
  }

  .md\:to-deep-orange-A200 {
    --tw-gradient-to: #FF6E40
  }

  .md\:to-deep-orange-A400 {
    --tw-gradient-to: #FF3D00
  }

  .md\:to-deep-orange-A700 {
    --tw-gradient-to: #DD2C00
  }

  .md\:to-yellow-50 {
    --tw-gradient-to: #FFFDE7
  }

  .md\:to-yellow-100 {
    --tw-gradient-to: #FFF9C4
  }

  .md\:to-yellow-200 {
    --tw-gradient-to: #FFF59D
  }

  .md\:to-yellow-300 {
    --tw-gradient-to: #FFF176
  }

  .md\:to-yellow-400 {
    --tw-gradient-to: #FFEE58
  }

  .md\:to-yellow-500 {
    --tw-gradient-to: #FFEB3B
  }

  .md\:to-yellow-600 {
    --tw-gradient-to: #FDD835
  }

  .md\:to-yellow-700 {
    --tw-gradient-to: #FBC02D
  }

  .md\:to-yellow-800 {
    --tw-gradient-to: #F9A825
  }

  .md\:to-yellow-900 {
    --tw-gradient-to: #F57F17
  }

  .md\:to-yellow {
    --tw-gradient-to: #FFEB3B
  }

  .md\:to-yellow-A100 {
    --tw-gradient-to: #FFFF8D
  }

  .md\:to-yellow-A200 {
    --tw-gradient-to: #FFFF00
  }

  .md\:to-yellow-A400 {
    --tw-gradient-to: #FFEA00
  }

  .md\:to-yellow-A700 {
    --tw-gradient-to: #FFD600
  }

  .md\:to-green-50 {
    --tw-gradient-to: #E8F5E9
  }

  .md\:to-green-100 {
    --tw-gradient-to: #C8E6C9
  }

  .md\:to-green-200 {
    --tw-gradient-to: #A5D6A7
  }

  .md\:to-green-300 {
    --tw-gradient-to: #81C784
  }

  .md\:to-green-400 {
    --tw-gradient-to: #66BB6A
  }

  .md\:to-green-500 {
    --tw-gradient-to: #4CAF50
  }

  .md\:to-green-600 {
    --tw-gradient-to: #43A047
  }

  .md\:to-green-700 {
    --tw-gradient-to: #388E3C
  }

  .md\:to-green-800 {
    --tw-gradient-to: #2E7D32
  }

  .md\:to-green-900 {
    --tw-gradient-to: #1B5E20
  }

  .md\:to-green {
    --tw-gradient-to: #4CAF50
  }

  .md\:to-green-A100 {
    --tw-gradient-to: #B9F6CA
  }

  .md\:to-green-A200 {
    --tw-gradient-to: #69F0AE
  }

  .md\:to-green-A400 {
    --tw-gradient-to: #00E676
  }

  .md\:to-green-A700 {
    --tw-gradient-to: #00C853
  }

  .md\:to-light-green-50 {
    --tw-gradient-to: #F1F8E9
  }

  .md\:to-light-green-100 {
    --tw-gradient-to: #DCEDC8
  }

  .md\:to-light-green-200 {
    --tw-gradient-to: #C5E1A5
  }

  .md\:to-light-green-300 {
    --tw-gradient-to: #AED581
  }

  .md\:to-light-green-400 {
    --tw-gradient-to: #9CCC65
  }

  .md\:to-light-green-500 {
    --tw-gradient-to: #8BC34A
  }

  .md\:to-light-green-600 {
    --tw-gradient-to: #7CB342
  }

  .md\:to-light-green-700 {
    --tw-gradient-to: #689F38
  }

  .md\:to-light-green-800 {
    --tw-gradient-to: #558B2F
  }

  .md\:to-light-green-900 {
    --tw-gradient-to: #33691E
  }

  .md\:to-light-green {
    --tw-gradient-to: #8BC34A
  }

  .md\:to-light-green-A100 {
    --tw-gradient-to: #CCFF90
  }

  .md\:to-light-green-A200 {
    --tw-gradient-to: #B2FF59
  }

  .md\:to-light-green-A400 {
    --tw-gradient-to: #76FF03
  }

  .md\:to-light-green-A700 {
    --tw-gradient-to: #64DD17
  }

  .md\:to-teal-50 {
    --tw-gradient-to: #E0F2F1
  }

  .md\:to-teal-100 {
    --tw-gradient-to: #B2DFDB
  }

  .md\:to-teal-200 {
    --tw-gradient-to: #80CBC4
  }

  .md\:to-teal-300 {
    --tw-gradient-to: #4DB6AC
  }

  .md\:to-teal-400 {
    --tw-gradient-to: #26A69A
  }

  .md\:to-teal-500 {
    --tw-gradient-to: #009688
  }

  .md\:to-teal-600 {
    --tw-gradient-to: #00897B
  }

  .md\:to-teal-700 {
    --tw-gradient-to: #00796B
  }

  .md\:to-teal-800 {
    --tw-gradient-to: #00695C
  }

  .md\:to-teal-900 {
    --tw-gradient-to: #004D40
  }

  .md\:to-teal {
    --tw-gradient-to: #009688
  }

  .md\:to-teal-A100 {
    --tw-gradient-to: #A7FFEB
  }

  .md\:to-teal-A200 {
    --tw-gradient-to: #64FFDA
  }

  .md\:to-teal-A400 {
    --tw-gradient-to: #1DE9B6
  }

  .md\:to-teal-A700 {
    --tw-gradient-to: #00BFA5
  }

  .md\:to-blue-50 {
    --tw-gradient-to: #E3F2FD
  }

  .md\:to-blue-100 {
    --tw-gradient-to: #BBDEFB
  }

  .md\:to-blue-200 {
    --tw-gradient-to: #90CAF9
  }

  .md\:to-blue-300 {
    --tw-gradient-to: #64B5F6
  }

  .md\:to-blue-400 {
    --tw-gradient-to: #42A5F5
  }

  .md\:to-blue-500 {
    --tw-gradient-to: #2196F3
  }

  .md\:to-blue-600 {
    --tw-gradient-to: #1E88E5
  }

  .md\:to-blue-700 {
    --tw-gradient-to: #1976D2
  }

  .md\:to-blue-800 {
    --tw-gradient-to: #1565C0
  }

  .md\:to-blue-900 {
    --tw-gradient-to: #0D47A1
  }

  .md\:to-blue {
    --tw-gradient-to: #2196F3
  }

  .md\:to-blue-A100 {
    --tw-gradient-to: #82B1FF
  }

  .md\:to-blue-A200 {
    --tw-gradient-to: #448AFF
  }

  .md\:to-blue-A400 {
    --tw-gradient-to: #2979FF
  }

  .md\:to-blue-A700 {
    --tw-gradient-to: #2962FF
  }

  .md\:to-light-blue-50 {
    --tw-gradient-to: #E1F5FE
  }

  .md\:to-light-blue-100 {
    --tw-gradient-to: #B3E5FC
  }

  .md\:to-light-blue-200 {
    --tw-gradient-to: #81D4FA
  }

  .md\:to-light-blue-300 {
    --tw-gradient-to: #4FC3F7
  }

  .md\:to-light-blue-400 {
    --tw-gradient-to: #29B6F6
  }

  .md\:to-light-blue-500 {
    --tw-gradient-to: #03A9F4
  }

  .md\:to-light-blue-600 {
    --tw-gradient-to: #039BE5
  }

  .md\:to-light-blue-700 {
    --tw-gradient-to: #0288D1
  }

  .md\:to-light-blue-800 {
    --tw-gradient-to: #0277BD
  }

  .md\:to-light-blue-900 {
    --tw-gradient-to: #01579B
  }

  .md\:to-light-blue {
    --tw-gradient-to: #03A9F4
  }

  .md\:to-light-blue-A100 {
    --tw-gradient-to: #80D8FF
  }

  .md\:to-light-blue-A200 {
    --tw-gradient-to: #40C4FF
  }

  .md\:to-light-blue-A400 {
    --tw-gradient-to: #00B0FF
  }

  .md\:to-light-blue-A700 {
    --tw-gradient-to: #0091EA
  }

  .md\:to-indigo-50 {
    --tw-gradient-to: #E8EAF6
  }

  .md\:to-indigo-100 {
    --tw-gradient-to: #C5CAE9
  }

  .md\:to-indigo-200 {
    --tw-gradient-to: #9FA8DA
  }

  .md\:to-indigo-300 {
    --tw-gradient-to: #7986CB
  }

  .md\:to-indigo-400 {
    --tw-gradient-to: #5C6BC0
  }

  .md\:to-indigo-500 {
    --tw-gradient-to: #3F51B5
  }

  .md\:to-indigo-600 {
    --tw-gradient-to: #3949AB
  }

  .md\:to-indigo-700 {
    --tw-gradient-to: #303F9F
  }

  .md\:to-indigo-800 {
    --tw-gradient-to: #283593
  }

  .md\:to-indigo-900 {
    --tw-gradient-to: #1A237E
  }

  .md\:to-indigo {
    --tw-gradient-to: #3F51B5
  }

  .md\:to-indigo-A100 {
    --tw-gradient-to: #8C9EFF
  }

  .md\:to-indigo-A200 {
    --tw-gradient-to: #536DFE
  }

  .md\:to-indigo-A400 {
    --tw-gradient-to: #3D5AFE
  }

  .md\:to-indigo-A700 {
    --tw-gradient-to: #304FFE
  }

  .md\:to-purple-50 {
    --tw-gradient-to: #F3E5F5
  }

  .md\:to-purple-100 {
    --tw-gradient-to: #E1BEE7
  }

  .md\:to-purple-200 {
    --tw-gradient-to: #CE93D8
  }

  .md\:to-purple-300 {
    --tw-gradient-to: #BA68C8
  }

  .md\:to-purple-400 {
    --tw-gradient-to: #AB47BC
  }

  .md\:to-purple-500 {
    --tw-gradient-to: #9C27B0
  }

  .md\:to-purple-600 {
    --tw-gradient-to: #8E24AA
  }

  .md\:to-purple-700 {
    --tw-gradient-to: #7B1FA2
  }

  .md\:to-purple-800 {
    --tw-gradient-to: #6A1B9A
  }

  .md\:to-purple-900 {
    --tw-gradient-to: #4A148C
  }

  .md\:to-purple {
    --tw-gradient-to: #9C27B0
  }

  .md\:to-purple-A100 {
    --tw-gradient-to: #EA80FC
  }

  .md\:to-purple-A200 {
    --tw-gradient-to: #E040FB
  }

  .md\:to-purple-A400 {
    --tw-gradient-to: #D500F9
  }

  .md\:to-purple-A700 {
    --tw-gradient-to: #AA00FF
  }

  .md\:to-deep-purple-50 {
    --tw-gradient-to: #EDE7F6
  }

  .md\:to-deep-purple-100 {
    --tw-gradient-to: #D1C4E9
  }

  .md\:to-deep-purple-200 {
    --tw-gradient-to: #B39DDB
  }

  .md\:to-deep-purple-300 {
    --tw-gradient-to: #9575CD
  }

  .md\:to-deep-purple-400 {
    --tw-gradient-to: #7E57C2
  }

  .md\:to-deep-purple-500 {
    --tw-gradient-to: #673AB7
  }

  .md\:to-deep-purple-600 {
    --tw-gradient-to: #5E35B1
  }

  .md\:to-deep-purple-700 {
    --tw-gradient-to: #512DA8
  }

  .md\:to-deep-purple-800 {
    --tw-gradient-to: #4527A0
  }

  .md\:to-deep-purple-900 {
    --tw-gradient-to: #311B92
  }

  .md\:to-deep-purple {
    --tw-gradient-to: #673AB7
  }

  .md\:to-deep-purple-A100 {
    --tw-gradient-to: #B388FF
  }

  .md\:to-deep-purple-A200 {
    --tw-gradient-to: #7C4DFF
  }

  .md\:to-deep-purple-A400 {
    --tw-gradient-to: #651FFF
  }

  .md\:to-deep-purple-A700 {
    --tw-gradient-to: #6200EA
  }

  .md\:to-pink-50 {
    --tw-gradient-to: #FCE4EC
  }

  .md\:to-pink-100 {
    --tw-gradient-to: #F8BBD0
  }

  .md\:to-pink-200 {
    --tw-gradient-to: #F48FB1
  }

  .md\:to-pink-300 {
    --tw-gradient-to: #F06292
  }

  .md\:to-pink-400 {
    --tw-gradient-to: #EC407A
  }

  .md\:to-pink-500 {
    --tw-gradient-to: #E91E63
  }

  .md\:to-pink-600 {
    --tw-gradient-to: #D81B60
  }

  .md\:to-pink-700 {
    --tw-gradient-to: #C2185B
  }

  .md\:to-pink-800 {
    --tw-gradient-to: #AD1457
  }

  .md\:to-pink-900 {
    --tw-gradient-to: #880E4F
  }

  .md\:to-pink {
    --tw-gradient-to: #E91E63
  }

  .md\:to-pink-A100 {
    --tw-gradient-to: #FF80AB
  }

  .md\:to-pink-A200 {
    --tw-gradient-to: #FF4081
  }

  .md\:to-pink-A400 {
    --tw-gradient-to: #F50057
  }

  .md\:to-pink-A700 {
    --tw-gradient-to: #C51162
  }

  .md\:to-lime-50 {
    --tw-gradient-to: #F9FBE7
  }

  .md\:to-lime-100 {
    --tw-gradient-to: #F0F4C3
  }

  .md\:to-lime-200 {
    --tw-gradient-to: #E6EE9C
  }

  .md\:to-lime-300 {
    --tw-gradient-to: #DCE775
  }

  .md\:to-lime-400 {
    --tw-gradient-to: #D4E157
  }

  .md\:to-lime-500 {
    --tw-gradient-to: #CDDC39
  }

  .md\:to-lime-600 {
    --tw-gradient-to: #C0CA33
  }

  .md\:to-lime-700 {
    --tw-gradient-to: #AFB42B
  }

  .md\:to-lime-800 {
    --tw-gradient-to: #9E9D24
  }

  .md\:to-lime-900 {
    --tw-gradient-to: #827717
  }

  .md\:to-lime {
    --tw-gradient-to: #CDDC39
  }

  .md\:to-lime-A100 {
    --tw-gradient-to: #F4FF81
  }

  .md\:to-lime-A200 {
    --tw-gradient-to: #EEFF41
  }

  .md\:to-lime-A400 {
    --tw-gradient-to: #C6FF00
  }

  .md\:to-lime-A700 {
    --tw-gradient-to: #AEEA00
  }

  .md\:to-amber-50 {
    --tw-gradient-to: #FFF8E1
  }

  .md\:to-amber-100 {
    --tw-gradient-to: #FFECB3
  }

  .md\:to-amber-200 {
    --tw-gradient-to: #FFE082
  }

  .md\:to-amber-300 {
    --tw-gradient-to: #FFD54F
  }

  .md\:to-amber-400 {
    --tw-gradient-to: #FFCA28
  }

  .md\:to-amber-500 {
    --tw-gradient-to: #FFC107
  }

  .md\:to-amber-600 {
    --tw-gradient-to: #FFB300
  }

  .md\:to-amber-700 {
    --tw-gradient-to: #FFA000
  }

  .md\:to-amber-800 {
    --tw-gradient-to: #FF8F00
  }

  .md\:to-amber-900 {
    --tw-gradient-to: #FF6F00
  }

  .md\:to-amber {
    --tw-gradient-to: #FFC107
  }

  .md\:to-amber-A100 {
    --tw-gradient-to: #FFE57F
  }

  .md\:to-amber-A200 {
    --tw-gradient-to: #FFD740
  }

  .md\:to-amber-A400 {
    --tw-gradient-to: #FFC400
  }

  .md\:to-amber-A700 {
    --tw-gradient-to: #FFAB00
  }

  .md\:to-brown-50 {
    --tw-gradient-to: #EFEBE9
  }

  .md\:to-brown-100 {
    --tw-gradient-to: #D7CCC8
  }

  .md\:to-brown-200 {
    --tw-gradient-to: #BCAAA4
  }

  .md\:to-brown-300 {
    --tw-gradient-to: #A1887F
  }

  .md\:to-brown-400 {
    --tw-gradient-to: #8D6E63
  }

  .md\:to-brown-500 {
    --tw-gradient-to: #795548
  }

  .md\:to-brown-600 {
    --tw-gradient-to: #6D4C41
  }

  .md\:to-brown-700 {
    --tw-gradient-to: #5D4037
  }

  .md\:to-brown-800 {
    --tw-gradient-to: #4E342E
  }

  .md\:to-brown-900 {
    --tw-gradient-to: #3E2723
  }

  .md\:to-brown {
    --tw-gradient-to: #795548
  }

  .md\:to-brown-A100 {
    --tw-gradient-to: #D7CCC8
  }

  .md\:to-brown-A200 {
    --tw-gradient-to: #BCAAA4
  }

  .md\:to-brown-A400 {
    --tw-gradient-to: #8D6E63
  }

  .md\:to-brown-A700 {
    --tw-gradient-to: #5D4037
  }

  .md\:to-blue-gray-50 {
    --tw-gradient-to: #ECEFF1
  }

  .md\:to-blue-gray-100 {
    --tw-gradient-to: #CFD8DC
  }

  .md\:to-blue-gray-200 {
    --tw-gradient-to: #B0BEC5
  }

  .md\:to-blue-gray-300 {
    --tw-gradient-to: #90A4AE
  }

  .md\:to-blue-gray-400 {
    --tw-gradient-to: #78909C
  }

  .md\:to-blue-gray-500 {
    --tw-gradient-to: #607D8B
  }

  .md\:to-blue-gray-600 {
    --tw-gradient-to: #546E7A
  }

  .md\:to-blue-gray-700 {
    --tw-gradient-to: #455A64
  }

  .md\:to-blue-gray-800 {
    --tw-gradient-to: #37474F
  }

  .md\:to-blue-gray-900 {
    --tw-gradient-to: #263238
  }

  .md\:to-blue-gray {
    --tw-gradient-to: #607D8B
  }

  .md\:to-blue-gray-A100 {
    --tw-gradient-to: #CFD8DC
  }

  .md\:to-blue-gray-A200 {
    --tw-gradient-to: #B0BEC5
  }

  .md\:to-blue-gray-A400 {
    --tw-gradient-to: #78909C
  }

  .md\:to-blue-gray-A700 {
    --tw-gradient-to: #455A64
  }

  .md\:to-cyan-50 {
    --tw-gradient-to: #E0F7FA
  }

  .md\:to-cyan-100 {
    --tw-gradient-to: #B2EBF2
  }

  .md\:to-cyan-200 {
    --tw-gradient-to: #80DEEA
  }

  .md\:to-cyan-300 {
    --tw-gradient-to: #4DD0E1
  }

  .md\:to-cyan-400 {
    --tw-gradient-to: #26C6DA
  }

  .md\:to-cyan-500 {
    --tw-gradient-to: #00BCD4
  }

  .md\:to-cyan-600 {
    --tw-gradient-to: #00ACC1
  }

  .md\:to-cyan-700 {
    --tw-gradient-to: #0097A7
  }

  .md\:to-cyan-800 {
    --tw-gradient-to: #00838F
  }

  .md\:to-cyan-900 {
    --tw-gradient-to: #006064
  }

  .md\:to-cyan {
    --tw-gradient-to: #00BCD4
  }

  .md\:to-cyan-A100 {
    --tw-gradient-to: #84FFFF
  }

  .md\:to-cyan-A200 {
    --tw-gradient-to: #18FFFF
  }

  .md\:to-cyan-A400 {
    --tw-gradient-to: #00E5FF
  }

  .md\:to-cyan-A700 {
    --tw-gradient-to: #00B8D4
  }

  .md\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-black:hover {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .md\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-grey-50:hover {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .md\:hover\:from-grey-100:hover {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .md\:hover\:from-grey-200:hover {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .md\:hover\:from-grey-300:hover {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .md\:hover\:from-grey-400:hover {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .md\:hover\:from-grey-500:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:hover\:from-grey-600:hover {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .md\:hover\:from-grey-700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:hover\:from-grey-800:hover {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .md\:hover\:from-grey-900:hover {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .md\:hover\:from-grey:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:hover\:from-grey-A100:hover {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .md\:hover\:from-grey-A200:hover {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .md\:hover\:from-grey-A400:hover {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .md\:hover\:from-grey-A700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:hover\:from-gray-50:hover {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .md\:hover\:from-gray-100:hover {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .md\:hover\:from-gray-200:hover {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .md\:hover\:from-gray-300:hover {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .md\:hover\:from-gray-400:hover {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .md\:hover\:from-gray-500:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:hover\:from-gray-600:hover {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .md\:hover\:from-gray-700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:hover\:from-gray-800:hover {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .md\:hover\:from-gray-900:hover {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .md\:hover\:from-gray:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:hover\:from-gray-hover:hover {
    --tw-gradient-from: rgba(0, 0, 0, 0.04);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:hover\:from-gray-A100:hover {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .md\:hover\:from-gray-A200:hover {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .md\:hover\:from-gray-A400:hover {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .md\:hover\:from-gray-A700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:hover\:from-red-50:hover {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .md\:hover\:from-red-100:hover {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .md\:hover\:from-red-200:hover {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .md\:hover\:from-red-300:hover {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .md\:hover\:from-red-400:hover {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .md\:hover\:from-red-500:hover {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .md\:hover\:from-red-600:hover {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .md\:hover\:from-red-700:hover {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .md\:hover\:from-red-800:hover {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .md\:hover\:from-red-900:hover {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .md\:hover\:from-red:hover {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .md\:hover\:from-red-A100:hover {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .md\:hover\:from-red-A200:hover {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .md\:hover\:from-red-A400:hover {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .md\:hover\:from-red-A700:hover {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .md\:hover\:from-orange-50:hover {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .md\:hover\:from-orange-100:hover {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .md\:hover\:from-orange-200:hover {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .md\:hover\:from-orange-300:hover {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .md\:hover\:from-orange-400:hover {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .md\:hover\:from-orange-500:hover {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .md\:hover\:from-orange-600:hover {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .md\:hover\:from-orange-700:hover {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .md\:hover\:from-orange-800:hover {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .md\:hover\:from-orange-900:hover {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .md\:hover\:from-orange:hover {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .md\:hover\:from-orange-A100:hover {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .md\:hover\:from-orange-A200:hover {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .md\:hover\:from-orange-A400:hover {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .md\:hover\:from-orange-A700:hover {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .md\:hover\:from-deep-orange-50:hover {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .md\:hover\:from-deep-orange-100:hover {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .md\:hover\:from-deep-orange-200:hover {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .md\:hover\:from-deep-orange-300:hover {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .md\:hover\:from-deep-orange-400:hover {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .md\:hover\:from-deep-orange-500:hover {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .md\:hover\:from-deep-orange-600:hover {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .md\:hover\:from-deep-orange-700:hover {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .md\:hover\:from-deep-orange-800:hover {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .md\:hover\:from-deep-orange-900:hover {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .md\:hover\:from-deep-orange:hover {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .md\:hover\:from-deep-orange-A100:hover {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .md\:hover\:from-deep-orange-A200:hover {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .md\:hover\:from-deep-orange-A400:hover {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .md\:hover\:from-deep-orange-A700:hover {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .md\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .md\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .md\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .md\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .md\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .md\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .md\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .md\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .md\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .md\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .md\:hover\:from-yellow:hover {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .md\:hover\:from-yellow-A100:hover {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .md\:hover\:from-yellow-A200:hover {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .md\:hover\:from-yellow-A400:hover {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .md\:hover\:from-yellow-A700:hover {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .md\:hover\:from-green-50:hover {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .md\:hover\:from-green-100:hover {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .md\:hover\:from-green-200:hover {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .md\:hover\:from-green-300:hover {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .md\:hover\:from-green-400:hover {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .md\:hover\:from-green-500:hover {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .md\:hover\:from-green-600:hover {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .md\:hover\:from-green-700:hover {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .md\:hover\:from-green-800:hover {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .md\:hover\:from-green-900:hover {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .md\:hover\:from-green:hover {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .md\:hover\:from-green-A100:hover {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .md\:hover\:from-green-A200:hover {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .md\:hover\:from-green-A400:hover {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .md\:hover\:from-green-A700:hover {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .md\:hover\:from-light-green-50:hover {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .md\:hover\:from-light-green-100:hover {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .md\:hover\:from-light-green-200:hover {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .md\:hover\:from-light-green-300:hover {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .md\:hover\:from-light-green-400:hover {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .md\:hover\:from-light-green-500:hover {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .md\:hover\:from-light-green-600:hover {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .md\:hover\:from-light-green-700:hover {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .md\:hover\:from-light-green-800:hover {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .md\:hover\:from-light-green-900:hover {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .md\:hover\:from-light-green:hover {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .md\:hover\:from-light-green-A100:hover {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .md\:hover\:from-light-green-A200:hover {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .md\:hover\:from-light-green-A400:hover {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .md\:hover\:from-light-green-A700:hover {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .md\:hover\:from-teal-50:hover {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .md\:hover\:from-teal-100:hover {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .md\:hover\:from-teal-200:hover {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .md\:hover\:from-teal-300:hover {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .md\:hover\:from-teal-400:hover {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .md\:hover\:from-teal-500:hover {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .md\:hover\:from-teal-600:hover {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .md\:hover\:from-teal-700:hover {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .md\:hover\:from-teal-800:hover {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .md\:hover\:from-teal-900:hover {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .md\:hover\:from-teal:hover {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .md\:hover\:from-teal-A100:hover {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .md\:hover\:from-teal-A200:hover {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .md\:hover\:from-teal-A400:hover {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .md\:hover\:from-teal-A700:hover {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .md\:hover\:from-blue-50:hover {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .md\:hover\:from-blue-100:hover {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .md\:hover\:from-blue-200:hover {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .md\:hover\:from-blue-300:hover {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .md\:hover\:from-blue-400:hover {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .md\:hover\:from-blue-500:hover {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .md\:hover\:from-blue-600:hover {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .md\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .md\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .md\:hover\:from-blue-900:hover {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .md\:hover\:from-blue:hover {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .md\:hover\:from-blue-A100:hover {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .md\:hover\:from-blue-A200:hover {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .md\:hover\:from-blue-A400:hover {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .md\:hover\:from-blue-A700:hover {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .md\:hover\:from-light-blue-50:hover {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .md\:hover\:from-light-blue-100:hover {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .md\:hover\:from-light-blue-200:hover {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .md\:hover\:from-light-blue-300:hover {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .md\:hover\:from-light-blue-400:hover {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .md\:hover\:from-light-blue-500:hover {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .md\:hover\:from-light-blue-600:hover {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .md\:hover\:from-light-blue-700:hover {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .md\:hover\:from-light-blue-800:hover {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .md\:hover\:from-light-blue-900:hover {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .md\:hover\:from-light-blue:hover {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .md\:hover\:from-light-blue-A100:hover {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .md\:hover\:from-light-blue-A200:hover {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .md\:hover\:from-light-blue-A400:hover {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .md\:hover\:from-light-blue-A700:hover {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .md\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .md\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .md\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .md\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .md\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .md\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .md\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .md\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .md\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .md\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .md\:hover\:from-indigo:hover {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .md\:hover\:from-indigo-A100:hover {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .md\:hover\:from-indigo-A200:hover {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .md\:hover\:from-indigo-A400:hover {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .md\:hover\:from-indigo-A700:hover {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .md\:hover\:from-purple-50:hover {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .md\:hover\:from-purple-100:hover {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .md\:hover\:from-purple-200:hover {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .md\:hover\:from-purple-300:hover {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .md\:hover\:from-purple-400:hover {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .md\:hover\:from-purple-500:hover {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .md\:hover\:from-purple-600:hover {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .md\:hover\:from-purple-700:hover {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .md\:hover\:from-purple-800:hover {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .md\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .md\:hover\:from-purple:hover {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .md\:hover\:from-purple-A100:hover {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .md\:hover\:from-purple-A200:hover {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .md\:hover\:from-purple-A400:hover {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .md\:hover\:from-purple-A700:hover {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .md\:hover\:from-deep-purple-50:hover {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .md\:hover\:from-deep-purple-100:hover {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .md\:hover\:from-deep-purple-200:hover {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .md\:hover\:from-deep-purple-300:hover {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .md\:hover\:from-deep-purple-400:hover {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .md\:hover\:from-deep-purple-500:hover {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .md\:hover\:from-deep-purple-600:hover {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .md\:hover\:from-deep-purple-700:hover {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .md\:hover\:from-deep-purple-800:hover {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .md\:hover\:from-deep-purple-900:hover {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .md\:hover\:from-deep-purple:hover {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .md\:hover\:from-deep-purple-A100:hover {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .md\:hover\:from-deep-purple-A200:hover {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .md\:hover\:from-deep-purple-A400:hover {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .md\:hover\:from-deep-purple-A700:hover {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .md\:hover\:from-pink-50:hover {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .md\:hover\:from-pink-100:hover {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .md\:hover\:from-pink-200:hover {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .md\:hover\:from-pink-300:hover {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .md\:hover\:from-pink-400:hover {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .md\:hover\:from-pink-500:hover {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .md\:hover\:from-pink-600:hover {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .md\:hover\:from-pink-700:hover {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .md\:hover\:from-pink-800:hover {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .md\:hover\:from-pink-900:hover {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .md\:hover\:from-pink:hover {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .md\:hover\:from-pink-A100:hover {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .md\:hover\:from-pink-A200:hover {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .md\:hover\:from-pink-A400:hover {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .md\:hover\:from-pink-A700:hover {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .md\:hover\:from-lime-50:hover {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .md\:hover\:from-lime-100:hover {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .md\:hover\:from-lime-200:hover {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .md\:hover\:from-lime-300:hover {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .md\:hover\:from-lime-400:hover {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .md\:hover\:from-lime-500:hover {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .md\:hover\:from-lime-600:hover {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .md\:hover\:from-lime-700:hover {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .md\:hover\:from-lime-800:hover {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .md\:hover\:from-lime-900:hover {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .md\:hover\:from-lime:hover {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .md\:hover\:from-lime-A100:hover {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .md\:hover\:from-lime-A200:hover {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .md\:hover\:from-lime-A400:hover {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .md\:hover\:from-lime-A700:hover {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .md\:hover\:from-amber-50:hover {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .md\:hover\:from-amber-100:hover {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .md\:hover\:from-amber-200:hover {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .md\:hover\:from-amber-300:hover {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .md\:hover\:from-amber-400:hover {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .md\:hover\:from-amber-500:hover {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .md\:hover\:from-amber-600:hover {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .md\:hover\:from-amber-700:hover {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .md\:hover\:from-amber-800:hover {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .md\:hover\:from-amber-900:hover {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .md\:hover\:from-amber:hover {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .md\:hover\:from-amber-A100:hover {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .md\:hover\:from-amber-A200:hover {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .md\:hover\:from-amber-A400:hover {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .md\:hover\:from-amber-A700:hover {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .md\:hover\:from-brown-50:hover {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .md\:hover\:from-brown-100:hover {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .md\:hover\:from-brown-200:hover {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .md\:hover\:from-brown-300:hover {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .md\:hover\:from-brown-400:hover {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .md\:hover\:from-brown-500:hover {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .md\:hover\:from-brown-600:hover {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .md\:hover\:from-brown-700:hover {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .md\:hover\:from-brown-800:hover {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .md\:hover\:from-brown-900:hover {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .md\:hover\:from-brown:hover {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .md\:hover\:from-brown-A100:hover {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .md\:hover\:from-brown-A200:hover {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .md\:hover\:from-brown-A400:hover {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .md\:hover\:from-brown-A700:hover {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .md\:hover\:from-blue-gray-50:hover {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .md\:hover\:from-blue-gray-100:hover {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .md\:hover\:from-blue-gray-200:hover {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .md\:hover\:from-blue-gray-300:hover {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .md\:hover\:from-blue-gray-400:hover {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .md\:hover\:from-blue-gray-500:hover {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .md\:hover\:from-blue-gray-600:hover {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .md\:hover\:from-blue-gray-700:hover {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .md\:hover\:from-blue-gray-800:hover {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .md\:hover\:from-blue-gray-900:hover {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .md\:hover\:from-blue-gray:hover {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .md\:hover\:from-blue-gray-A100:hover {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .md\:hover\:from-blue-gray-A200:hover {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .md\:hover\:from-blue-gray-A400:hover {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .md\:hover\:from-blue-gray-A700:hover {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .md\:hover\:from-cyan-50:hover {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .md\:hover\:from-cyan-100:hover {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .md\:hover\:from-cyan-200:hover {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .md\:hover\:from-cyan-300:hover {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .md\:hover\:from-cyan-400:hover {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .md\:hover\:from-cyan-500:hover {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .md\:hover\:from-cyan-600:hover {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .md\:hover\:from-cyan-700:hover {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .md\:hover\:from-cyan-800:hover {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .md\:hover\:from-cyan-900:hover {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .md\:hover\:from-cyan:hover {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .md\:hover\:from-cyan-A100:hover {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .md\:hover\:from-cyan-A200:hover {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .md\:hover\:from-cyan-A400:hover {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .md\:hover\:from-cyan-A700:hover {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .md\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .md\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-grey-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .md\:hover\:via-grey-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .md\:hover\:via-grey-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .md\:hover\:via-grey-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .md\:hover\:via-grey-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .md\:hover\:via-grey-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:hover\:via-grey-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .md\:hover\:via-grey-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:hover\:via-grey-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .md\:hover\:via-grey-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .md\:hover\:via-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:hover\:via-grey-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .md\:hover\:via-grey-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .md\:hover\:via-grey-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .md\:hover\:via-grey-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .md\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .md\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .md\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .md\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .md\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .md\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .md\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .md\:hover\:via-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:hover\:via-gray-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:hover\:via-gray-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .md\:hover\:via-gray-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .md\:hover\:via-gray-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .md\:hover\:via-gray-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .md\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .md\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .md\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .md\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .md\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .md\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .md\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .md\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .md\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .md\:hover\:via-red:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .md\:hover\:via-red-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .md\:hover\:via-red-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .md\:hover\:via-red-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .md\:hover\:via-red-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .md\:hover\:via-orange-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .md\:hover\:via-orange-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .md\:hover\:via-orange-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .md\:hover\:via-orange-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .md\:hover\:via-orange-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .md\:hover\:via-orange-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .md\:hover\:via-orange-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .md\:hover\:via-orange-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .md\:hover\:via-orange-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .md\:hover\:via-orange-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .md\:hover\:via-orange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .md\:hover\:via-orange-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .md\:hover\:via-orange-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .md\:hover\:via-orange-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .md\:hover\:via-orange-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .md\:hover\:via-deep-orange-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .md\:hover\:via-deep-orange-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .md\:hover\:via-deep-orange-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .md\:hover\:via-deep-orange-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .md\:hover\:via-deep-orange-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .md\:hover\:via-deep-orange-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .md\:hover\:via-deep-orange-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .md\:hover\:via-deep-orange-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .md\:hover\:via-deep-orange-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .md\:hover\:via-deep-orange-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .md\:hover\:via-deep-orange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .md\:hover\:via-deep-orange-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .md\:hover\:via-deep-orange-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .md\:hover\:via-deep-orange-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .md\:hover\:via-deep-orange-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .md\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .md\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .md\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .md\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .md\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .md\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .md\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .md\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .md\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .md\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .md\:hover\:via-yellow:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .md\:hover\:via-yellow-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .md\:hover\:via-yellow-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .md\:hover\:via-yellow-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .md\:hover\:via-yellow-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .md\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .md\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .md\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .md\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .md\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .md\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .md\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .md\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .md\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .md\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .md\:hover\:via-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .md\:hover\:via-green-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .md\:hover\:via-green-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .md\:hover\:via-green-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .md\:hover\:via-green-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .md\:hover\:via-light-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .md\:hover\:via-light-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .md\:hover\:via-light-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .md\:hover\:via-light-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .md\:hover\:via-light-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .md\:hover\:via-light-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .md\:hover\:via-light-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .md\:hover\:via-light-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .md\:hover\:via-light-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .md\:hover\:via-light-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .md\:hover\:via-light-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .md\:hover\:via-light-green-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .md\:hover\:via-light-green-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .md\:hover\:via-light-green-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .md\:hover\:via-light-green-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .md\:hover\:via-teal-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .md\:hover\:via-teal-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .md\:hover\:via-teal-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .md\:hover\:via-teal-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .md\:hover\:via-teal-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .md\:hover\:via-teal-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .md\:hover\:via-teal-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .md\:hover\:via-teal-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .md\:hover\:via-teal-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .md\:hover\:via-teal-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .md\:hover\:via-teal:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .md\:hover\:via-teal-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .md\:hover\:via-teal-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .md\:hover\:via-teal-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .md\:hover\:via-teal-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .md\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .md\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .md\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .md\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .md\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .md\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .md\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .md\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .md\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .md\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .md\:hover\:via-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .md\:hover\:via-blue-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .md\:hover\:via-blue-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .md\:hover\:via-blue-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .md\:hover\:via-blue-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .md\:hover\:via-light-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .md\:hover\:via-light-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .md\:hover\:via-light-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .md\:hover\:via-light-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .md\:hover\:via-light-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .md\:hover\:via-light-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .md\:hover\:via-light-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .md\:hover\:via-light-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .md\:hover\:via-light-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .md\:hover\:via-light-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .md\:hover\:via-light-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .md\:hover\:via-light-blue-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .md\:hover\:via-light-blue-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .md\:hover\:via-light-blue-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .md\:hover\:via-light-blue-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .md\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .md\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .md\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .md\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .md\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .md\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .md\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .md\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .md\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .md\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .md\:hover\:via-indigo:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .md\:hover\:via-indigo-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .md\:hover\:via-indigo-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .md\:hover\:via-indigo-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .md\:hover\:via-indigo-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .md\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .md\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .md\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .md\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .md\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .md\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .md\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .md\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .md\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .md\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .md\:hover\:via-purple:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .md\:hover\:via-purple-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .md\:hover\:via-purple-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .md\:hover\:via-purple-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .md\:hover\:via-purple-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .md\:hover\:via-deep-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .md\:hover\:via-deep-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .md\:hover\:via-deep-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .md\:hover\:via-deep-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .md\:hover\:via-deep-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .md\:hover\:via-deep-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .md\:hover\:via-deep-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .md\:hover\:via-deep-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .md\:hover\:via-deep-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .md\:hover\:via-deep-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .md\:hover\:via-deep-purple:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .md\:hover\:via-deep-purple-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .md\:hover\:via-deep-purple-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .md\:hover\:via-deep-purple-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .md\:hover\:via-deep-purple-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .md\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .md\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .md\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .md\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .md\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .md\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .md\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .md\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .md\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .md\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .md\:hover\:via-pink:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .md\:hover\:via-pink-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .md\:hover\:via-pink-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .md\:hover\:via-pink-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .md\:hover\:via-pink-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .md\:hover\:via-lime-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .md\:hover\:via-lime-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .md\:hover\:via-lime-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .md\:hover\:via-lime-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .md\:hover\:via-lime-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .md\:hover\:via-lime-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .md\:hover\:via-lime-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .md\:hover\:via-lime-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .md\:hover\:via-lime-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .md\:hover\:via-lime-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .md\:hover\:via-lime:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .md\:hover\:via-lime-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .md\:hover\:via-lime-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .md\:hover\:via-lime-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .md\:hover\:via-lime-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .md\:hover\:via-amber-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .md\:hover\:via-amber-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .md\:hover\:via-amber-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .md\:hover\:via-amber-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .md\:hover\:via-amber-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .md\:hover\:via-amber-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .md\:hover\:via-amber-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .md\:hover\:via-amber-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .md\:hover\:via-amber-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .md\:hover\:via-amber-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .md\:hover\:via-amber:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .md\:hover\:via-amber-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .md\:hover\:via-amber-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .md\:hover\:via-amber-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .md\:hover\:via-amber-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .md\:hover\:via-brown-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .md\:hover\:via-brown-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .md\:hover\:via-brown-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .md\:hover\:via-brown-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .md\:hover\:via-brown-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .md\:hover\:via-brown-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .md\:hover\:via-brown-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .md\:hover\:via-brown-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .md\:hover\:via-brown-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .md\:hover\:via-brown-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .md\:hover\:via-brown:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .md\:hover\:via-brown-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .md\:hover\:via-brown-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .md\:hover\:via-brown-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .md\:hover\:via-brown-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .md\:hover\:via-blue-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .md\:hover\:via-blue-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .md\:hover\:via-blue-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .md\:hover\:via-blue-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .md\:hover\:via-blue-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .md\:hover\:via-blue-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .md\:hover\:via-blue-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .md\:hover\:via-blue-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .md\:hover\:via-blue-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .md\:hover\:via-blue-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .md\:hover\:via-blue-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .md\:hover\:via-blue-gray-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .md\:hover\:via-blue-gray-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .md\:hover\:via-blue-gray-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .md\:hover\:via-blue-gray-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .md\:hover\:via-cyan-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .md\:hover\:via-cyan-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .md\:hover\:via-cyan-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .md\:hover\:via-cyan-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .md\:hover\:via-cyan-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .md\:hover\:via-cyan-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .md\:hover\:via-cyan-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .md\:hover\:via-cyan-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .md\:hover\:via-cyan-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .md\:hover\:via-cyan-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .md\:hover\:via-cyan:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .md\:hover\:via-cyan-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .md\:hover\:via-cyan-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .md\:hover\:via-cyan-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .md\:hover\:via-cyan-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .md\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent
  }

  .md\:hover\:to-current:hover {
    --tw-gradient-to: currentColor
  }

  .md\:hover\:to-black:hover {
    --tw-gradient-to: #22292F
  }

  .md\:hover\:to-white:hover {
    --tw-gradient-to: #fff
  }

  .md\:hover\:to-grey-50:hover {
    --tw-gradient-to: #FAFAFA
  }

  .md\:hover\:to-grey-100:hover {
    --tw-gradient-to: #F5F5F5
  }

  .md\:hover\:to-grey-200:hover {
    --tw-gradient-to: #EEEEEE
  }

  .md\:hover\:to-grey-300:hover {
    --tw-gradient-to: #E0E0E0
  }

  .md\:hover\:to-grey-400:hover {
    --tw-gradient-to: #BDBDBD
  }

  .md\:hover\:to-grey-500:hover {
    --tw-gradient-to: #9E9E9E
  }

  .md\:hover\:to-grey-600:hover {
    --tw-gradient-to: #757575
  }

  .md\:hover\:to-grey-700:hover {
    --tw-gradient-to: #616161
  }

  .md\:hover\:to-grey-800:hover {
    --tw-gradient-to: #424242
  }

  .md\:hover\:to-grey-900:hover {
    --tw-gradient-to: #212121
  }

  .md\:hover\:to-grey:hover {
    --tw-gradient-to: #9E9E9E
  }

  .md\:hover\:to-grey-A100:hover {
    --tw-gradient-to: #D5D5D5
  }

  .md\:hover\:to-grey-A200:hover {
    --tw-gradient-to: #AAAAAA
  }

  .md\:hover\:to-grey-A400:hover {
    --tw-gradient-to: #303030
  }

  .md\:hover\:to-grey-A700:hover {
    --tw-gradient-to: #616161
  }

  .md\:hover\:to-gray-50:hover {
    --tw-gradient-to: #FAFAFA
  }

  .md\:hover\:to-gray-100:hover {
    --tw-gradient-to: #F5F5F5
  }

  .md\:hover\:to-gray-200:hover {
    --tw-gradient-to: #EEEEEE
  }

  .md\:hover\:to-gray-300:hover {
    --tw-gradient-to: #E0E0E0
  }

  .md\:hover\:to-gray-400:hover {
    --tw-gradient-to: #BDBDBD
  }

  .md\:hover\:to-gray-500:hover {
    --tw-gradient-to: #9E9E9E
  }

  .md\:hover\:to-gray-600:hover {
    --tw-gradient-to: #757575
  }

  .md\:hover\:to-gray-700:hover {
    --tw-gradient-to: #616161
  }

  .md\:hover\:to-gray-800:hover {
    --tw-gradient-to: #424242
  }

  .md\:hover\:to-gray-900:hover {
    --tw-gradient-to: #212121
  }

  .md\:hover\:to-gray:hover {
    --tw-gradient-to: #9E9E9E
  }

  .md\:hover\:to-gray-hover:hover {
    --tw-gradient-to: rgba(0, 0, 0, 0.04)
  }

  .md\:hover\:to-gray-A100:hover {
    --tw-gradient-to: #D5D5D5
  }

  .md\:hover\:to-gray-A200:hover {
    --tw-gradient-to: #AAAAAA
  }

  .md\:hover\:to-gray-A400:hover {
    --tw-gradient-to: #303030
  }

  .md\:hover\:to-gray-A700:hover {
    --tw-gradient-to: #616161
  }

  .md\:hover\:to-red-50:hover {
    --tw-gradient-to: #FFEBEE
  }

  .md\:hover\:to-red-100:hover {
    --tw-gradient-to: #FFCDD2
  }

  .md\:hover\:to-red-200:hover {
    --tw-gradient-to: #EF9A9A
  }

  .md\:hover\:to-red-300:hover {
    --tw-gradient-to: #E57373
  }

  .md\:hover\:to-red-400:hover {
    --tw-gradient-to: #EF5350
  }

  .md\:hover\:to-red-500:hover {
    --tw-gradient-to: #F44336
  }

  .md\:hover\:to-red-600:hover {
    --tw-gradient-to: #E53935
  }

  .md\:hover\:to-red-700:hover {
    --tw-gradient-to: #D32F2F
  }

  .md\:hover\:to-red-800:hover {
    --tw-gradient-to: #C62828
  }

  .md\:hover\:to-red-900:hover {
    --tw-gradient-to: #B71C1C
  }

  .md\:hover\:to-red:hover {
    --tw-gradient-to: #F44336
  }

  .md\:hover\:to-red-A100:hover {
    --tw-gradient-to: #FF8A80
  }

  .md\:hover\:to-red-A200:hover {
    --tw-gradient-to: #FF5252
  }

  .md\:hover\:to-red-A400:hover {
    --tw-gradient-to: #FF1744
  }

  .md\:hover\:to-red-A700:hover {
    --tw-gradient-to: #D50000
  }

  .md\:hover\:to-orange-50:hover {
    --tw-gradient-to: #FFF3E0
  }

  .md\:hover\:to-orange-100:hover {
    --tw-gradient-to: #FFE0B2
  }

  .md\:hover\:to-orange-200:hover {
    --tw-gradient-to: #FFCC80
  }

  .md\:hover\:to-orange-300:hover {
    --tw-gradient-to: #FFB74D
  }

  .md\:hover\:to-orange-400:hover {
    --tw-gradient-to: #FFA726
  }

  .md\:hover\:to-orange-500:hover {
    --tw-gradient-to: #FF9800
  }

  .md\:hover\:to-orange-600:hover {
    --tw-gradient-to: #FB8C00
  }

  .md\:hover\:to-orange-700:hover {
    --tw-gradient-to: #F57C00
  }

  .md\:hover\:to-orange-800:hover {
    --tw-gradient-to: #EF6C00
  }

  .md\:hover\:to-orange-900:hover {
    --tw-gradient-to: #E65100
  }

  .md\:hover\:to-orange:hover {
    --tw-gradient-to: #FF9800
  }

  .md\:hover\:to-orange-A100:hover {
    --tw-gradient-to: #FFD180
  }

  .md\:hover\:to-orange-A200:hover {
    --tw-gradient-to: #FFAB40
  }

  .md\:hover\:to-orange-A400:hover {
    --tw-gradient-to: #FF9100
  }

  .md\:hover\:to-orange-A700:hover {
    --tw-gradient-to: #FF6D00
  }

  .md\:hover\:to-deep-orange-50:hover {
    --tw-gradient-to: #FBE9E7
  }

  .md\:hover\:to-deep-orange-100:hover {
    --tw-gradient-to: #FFCCBC
  }

  .md\:hover\:to-deep-orange-200:hover {
    --tw-gradient-to: #FFAB91
  }

  .md\:hover\:to-deep-orange-300:hover {
    --tw-gradient-to: #FF8A65
  }

  .md\:hover\:to-deep-orange-400:hover {
    --tw-gradient-to: #FF7043
  }

  .md\:hover\:to-deep-orange-500:hover {
    --tw-gradient-to: #FF5722
  }

  .md\:hover\:to-deep-orange-600:hover {
    --tw-gradient-to: #F4511E
  }

  .md\:hover\:to-deep-orange-700:hover {
    --tw-gradient-to: #E64A19
  }

  .md\:hover\:to-deep-orange-800:hover {
    --tw-gradient-to: #D84315
  }

  .md\:hover\:to-deep-orange-900:hover {
    --tw-gradient-to: #BF360C
  }

  .md\:hover\:to-deep-orange:hover {
    --tw-gradient-to: #FF5722
  }

  .md\:hover\:to-deep-orange-A100:hover {
    --tw-gradient-to: #FF9E80
  }

  .md\:hover\:to-deep-orange-A200:hover {
    --tw-gradient-to: #FF6E40
  }

  .md\:hover\:to-deep-orange-A400:hover {
    --tw-gradient-to: #FF3D00
  }

  .md\:hover\:to-deep-orange-A700:hover {
    --tw-gradient-to: #DD2C00
  }

  .md\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #FFFDE7
  }

  .md\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #FFF9C4
  }

  .md\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #FFF59D
  }

  .md\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #FFF176
  }

  .md\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #FFEE58
  }

  .md\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #FFEB3B
  }

  .md\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #FDD835
  }

  .md\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #FBC02D
  }

  .md\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #F9A825
  }

  .md\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #F57F17
  }

  .md\:hover\:to-yellow:hover {
    --tw-gradient-to: #FFEB3B
  }

  .md\:hover\:to-yellow-A100:hover {
    --tw-gradient-to: #FFFF8D
  }

  .md\:hover\:to-yellow-A200:hover {
    --tw-gradient-to: #FFFF00
  }

  .md\:hover\:to-yellow-A400:hover {
    --tw-gradient-to: #FFEA00
  }

  .md\:hover\:to-yellow-A700:hover {
    --tw-gradient-to: #FFD600
  }

  .md\:hover\:to-green-50:hover {
    --tw-gradient-to: #E8F5E9
  }

  .md\:hover\:to-green-100:hover {
    --tw-gradient-to: #C8E6C9
  }

  .md\:hover\:to-green-200:hover {
    --tw-gradient-to: #A5D6A7
  }

  .md\:hover\:to-green-300:hover {
    --tw-gradient-to: #81C784
  }

  .md\:hover\:to-green-400:hover {
    --tw-gradient-to: #66BB6A
  }

  .md\:hover\:to-green-500:hover {
    --tw-gradient-to: #4CAF50
  }

  .md\:hover\:to-green-600:hover {
    --tw-gradient-to: #43A047
  }

  .md\:hover\:to-green-700:hover {
    --tw-gradient-to: #388E3C
  }

  .md\:hover\:to-green-800:hover {
    --tw-gradient-to: #2E7D32
  }

  .md\:hover\:to-green-900:hover {
    --tw-gradient-to: #1B5E20
  }

  .md\:hover\:to-green:hover {
    --tw-gradient-to: #4CAF50
  }

  .md\:hover\:to-green-A100:hover {
    --tw-gradient-to: #B9F6CA
  }

  .md\:hover\:to-green-A200:hover {
    --tw-gradient-to: #69F0AE
  }

  .md\:hover\:to-green-A400:hover {
    --tw-gradient-to: #00E676
  }

  .md\:hover\:to-green-A700:hover {
    --tw-gradient-to: #00C853
  }

  .md\:hover\:to-light-green-50:hover {
    --tw-gradient-to: #F1F8E9
  }

  .md\:hover\:to-light-green-100:hover {
    --tw-gradient-to: #DCEDC8
  }

  .md\:hover\:to-light-green-200:hover {
    --tw-gradient-to: #C5E1A5
  }

  .md\:hover\:to-light-green-300:hover {
    --tw-gradient-to: #AED581
  }

  .md\:hover\:to-light-green-400:hover {
    --tw-gradient-to: #9CCC65
  }

  .md\:hover\:to-light-green-500:hover {
    --tw-gradient-to: #8BC34A
  }

  .md\:hover\:to-light-green-600:hover {
    --tw-gradient-to: #7CB342
  }

  .md\:hover\:to-light-green-700:hover {
    --tw-gradient-to: #689F38
  }

  .md\:hover\:to-light-green-800:hover {
    --tw-gradient-to: #558B2F
  }

  .md\:hover\:to-light-green-900:hover {
    --tw-gradient-to: #33691E
  }

  .md\:hover\:to-light-green:hover {
    --tw-gradient-to: #8BC34A
  }

  .md\:hover\:to-light-green-A100:hover {
    --tw-gradient-to: #CCFF90
  }

  .md\:hover\:to-light-green-A200:hover {
    --tw-gradient-to: #B2FF59
  }

  .md\:hover\:to-light-green-A400:hover {
    --tw-gradient-to: #76FF03
  }

  .md\:hover\:to-light-green-A700:hover {
    --tw-gradient-to: #64DD17
  }

  .md\:hover\:to-teal-50:hover {
    --tw-gradient-to: #E0F2F1
  }

  .md\:hover\:to-teal-100:hover {
    --tw-gradient-to: #B2DFDB
  }

  .md\:hover\:to-teal-200:hover {
    --tw-gradient-to: #80CBC4
  }

  .md\:hover\:to-teal-300:hover {
    --tw-gradient-to: #4DB6AC
  }

  .md\:hover\:to-teal-400:hover {
    --tw-gradient-to: #26A69A
  }

  .md\:hover\:to-teal-500:hover {
    --tw-gradient-to: #009688
  }

  .md\:hover\:to-teal-600:hover {
    --tw-gradient-to: #00897B
  }

  .md\:hover\:to-teal-700:hover {
    --tw-gradient-to: #00796B
  }

  .md\:hover\:to-teal-800:hover {
    --tw-gradient-to: #00695C
  }

  .md\:hover\:to-teal-900:hover {
    --tw-gradient-to: #004D40
  }

  .md\:hover\:to-teal:hover {
    --tw-gradient-to: #009688
  }

  .md\:hover\:to-teal-A100:hover {
    --tw-gradient-to: #A7FFEB
  }

  .md\:hover\:to-teal-A200:hover {
    --tw-gradient-to: #64FFDA
  }

  .md\:hover\:to-teal-A400:hover {
    --tw-gradient-to: #1DE9B6
  }

  .md\:hover\:to-teal-A700:hover {
    --tw-gradient-to: #00BFA5
  }

  .md\:hover\:to-blue-50:hover {
    --tw-gradient-to: #E3F2FD
  }

  .md\:hover\:to-blue-100:hover {
    --tw-gradient-to: #BBDEFB
  }

  .md\:hover\:to-blue-200:hover {
    --tw-gradient-to: #90CAF9
  }

  .md\:hover\:to-blue-300:hover {
    --tw-gradient-to: #64B5F6
  }

  .md\:hover\:to-blue-400:hover {
    --tw-gradient-to: #42A5F5
  }

  .md\:hover\:to-blue-500:hover {
    --tw-gradient-to: #2196F3
  }

  .md\:hover\:to-blue-600:hover {
    --tw-gradient-to: #1E88E5
  }

  .md\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1976D2
  }

  .md\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1565C0
  }

  .md\:hover\:to-blue-900:hover {
    --tw-gradient-to: #0D47A1
  }

  .md\:hover\:to-blue:hover {
    --tw-gradient-to: #2196F3
  }

  .md\:hover\:to-blue-A100:hover {
    --tw-gradient-to: #82B1FF
  }

  .md\:hover\:to-blue-A200:hover {
    --tw-gradient-to: #448AFF
  }

  .md\:hover\:to-blue-A400:hover {
    --tw-gradient-to: #2979FF
  }

  .md\:hover\:to-blue-A700:hover {
    --tw-gradient-to: #2962FF
  }

  .md\:hover\:to-light-blue-50:hover {
    --tw-gradient-to: #E1F5FE
  }

  .md\:hover\:to-light-blue-100:hover {
    --tw-gradient-to: #B3E5FC
  }

  .md\:hover\:to-light-blue-200:hover {
    --tw-gradient-to: #81D4FA
  }

  .md\:hover\:to-light-blue-300:hover {
    --tw-gradient-to: #4FC3F7
  }

  .md\:hover\:to-light-blue-400:hover {
    --tw-gradient-to: #29B6F6
  }

  .md\:hover\:to-light-blue-500:hover {
    --tw-gradient-to: #03A9F4
  }

  .md\:hover\:to-light-blue-600:hover {
    --tw-gradient-to: #039BE5
  }

  .md\:hover\:to-light-blue-700:hover {
    --tw-gradient-to: #0288D1
  }

  .md\:hover\:to-light-blue-800:hover {
    --tw-gradient-to: #0277BD
  }

  .md\:hover\:to-light-blue-900:hover {
    --tw-gradient-to: #01579B
  }

  .md\:hover\:to-light-blue:hover {
    --tw-gradient-to: #03A9F4
  }

  .md\:hover\:to-light-blue-A100:hover {
    --tw-gradient-to: #80D8FF
  }

  .md\:hover\:to-light-blue-A200:hover {
    --tw-gradient-to: #40C4FF
  }

  .md\:hover\:to-light-blue-A400:hover {
    --tw-gradient-to: #00B0FF
  }

  .md\:hover\:to-light-blue-A700:hover {
    --tw-gradient-to: #0091EA
  }

  .md\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #E8EAF6
  }

  .md\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #C5CAE9
  }

  .md\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #9FA8DA
  }

  .md\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #7986CB
  }

  .md\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #5C6BC0
  }

  .md\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #3F51B5
  }

  .md\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #3949AB
  }

  .md\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #303F9F
  }

  .md\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #283593
  }

  .md\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #1A237E
  }

  .md\:hover\:to-indigo:hover {
    --tw-gradient-to: #3F51B5
  }

  .md\:hover\:to-indigo-A100:hover {
    --tw-gradient-to: #8C9EFF
  }

  .md\:hover\:to-indigo-A200:hover {
    --tw-gradient-to: #536DFE
  }

  .md\:hover\:to-indigo-A400:hover {
    --tw-gradient-to: #3D5AFE
  }

  .md\:hover\:to-indigo-A700:hover {
    --tw-gradient-to: #304FFE
  }

  .md\:hover\:to-purple-50:hover {
    --tw-gradient-to: #F3E5F5
  }

  .md\:hover\:to-purple-100:hover {
    --tw-gradient-to: #E1BEE7
  }

  .md\:hover\:to-purple-200:hover {
    --tw-gradient-to: #CE93D8
  }

  .md\:hover\:to-purple-300:hover {
    --tw-gradient-to: #BA68C8
  }

  .md\:hover\:to-purple-400:hover {
    --tw-gradient-to: #AB47BC
  }

  .md\:hover\:to-purple-500:hover {
    --tw-gradient-to: #9C27B0
  }

  .md\:hover\:to-purple-600:hover {
    --tw-gradient-to: #8E24AA
  }

  .md\:hover\:to-purple-700:hover {
    --tw-gradient-to: #7B1FA2
  }

  .md\:hover\:to-purple-800:hover {
    --tw-gradient-to: #6A1B9A
  }

  .md\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4A148C
  }

  .md\:hover\:to-purple:hover {
    --tw-gradient-to: #9C27B0
  }

  .md\:hover\:to-purple-A100:hover {
    --tw-gradient-to: #EA80FC
  }

  .md\:hover\:to-purple-A200:hover {
    --tw-gradient-to: #E040FB
  }

  .md\:hover\:to-purple-A400:hover {
    --tw-gradient-to: #D500F9
  }

  .md\:hover\:to-purple-A700:hover {
    --tw-gradient-to: #AA00FF
  }

  .md\:hover\:to-deep-purple-50:hover {
    --tw-gradient-to: #EDE7F6
  }

  .md\:hover\:to-deep-purple-100:hover {
    --tw-gradient-to: #D1C4E9
  }

  .md\:hover\:to-deep-purple-200:hover {
    --tw-gradient-to: #B39DDB
  }

  .md\:hover\:to-deep-purple-300:hover {
    --tw-gradient-to: #9575CD
  }

  .md\:hover\:to-deep-purple-400:hover {
    --tw-gradient-to: #7E57C2
  }

  .md\:hover\:to-deep-purple-500:hover {
    --tw-gradient-to: #673AB7
  }

  .md\:hover\:to-deep-purple-600:hover {
    --tw-gradient-to: #5E35B1
  }

  .md\:hover\:to-deep-purple-700:hover {
    --tw-gradient-to: #512DA8
  }

  .md\:hover\:to-deep-purple-800:hover {
    --tw-gradient-to: #4527A0
  }

  .md\:hover\:to-deep-purple-900:hover {
    --tw-gradient-to: #311B92
  }

  .md\:hover\:to-deep-purple:hover {
    --tw-gradient-to: #673AB7
  }

  .md\:hover\:to-deep-purple-A100:hover {
    --tw-gradient-to: #B388FF
  }

  .md\:hover\:to-deep-purple-A200:hover {
    --tw-gradient-to: #7C4DFF
  }

  .md\:hover\:to-deep-purple-A400:hover {
    --tw-gradient-to: #651FFF
  }

  .md\:hover\:to-deep-purple-A700:hover {
    --tw-gradient-to: #6200EA
  }

  .md\:hover\:to-pink-50:hover {
    --tw-gradient-to: #FCE4EC
  }

  .md\:hover\:to-pink-100:hover {
    --tw-gradient-to: #F8BBD0
  }

  .md\:hover\:to-pink-200:hover {
    --tw-gradient-to: #F48FB1
  }

  .md\:hover\:to-pink-300:hover {
    --tw-gradient-to: #F06292
  }

  .md\:hover\:to-pink-400:hover {
    --tw-gradient-to: #EC407A
  }

  .md\:hover\:to-pink-500:hover {
    --tw-gradient-to: #E91E63
  }

  .md\:hover\:to-pink-600:hover {
    --tw-gradient-to: #D81B60
  }

  .md\:hover\:to-pink-700:hover {
    --tw-gradient-to: #C2185B
  }

  .md\:hover\:to-pink-800:hover {
    --tw-gradient-to: #AD1457
  }

  .md\:hover\:to-pink-900:hover {
    --tw-gradient-to: #880E4F
  }

  .md\:hover\:to-pink:hover {
    --tw-gradient-to: #E91E63
  }

  .md\:hover\:to-pink-A100:hover {
    --tw-gradient-to: #FF80AB
  }

  .md\:hover\:to-pink-A200:hover {
    --tw-gradient-to: #FF4081
  }

  .md\:hover\:to-pink-A400:hover {
    --tw-gradient-to: #F50057
  }

  .md\:hover\:to-pink-A700:hover {
    --tw-gradient-to: #C51162
  }

  .md\:hover\:to-lime-50:hover {
    --tw-gradient-to: #F9FBE7
  }

  .md\:hover\:to-lime-100:hover {
    --tw-gradient-to: #F0F4C3
  }

  .md\:hover\:to-lime-200:hover {
    --tw-gradient-to: #E6EE9C
  }

  .md\:hover\:to-lime-300:hover {
    --tw-gradient-to: #DCE775
  }

  .md\:hover\:to-lime-400:hover {
    --tw-gradient-to: #D4E157
  }

  .md\:hover\:to-lime-500:hover {
    --tw-gradient-to: #CDDC39
  }

  .md\:hover\:to-lime-600:hover {
    --tw-gradient-to: #C0CA33
  }

  .md\:hover\:to-lime-700:hover {
    --tw-gradient-to: #AFB42B
  }

  .md\:hover\:to-lime-800:hover {
    --tw-gradient-to: #9E9D24
  }

  .md\:hover\:to-lime-900:hover {
    --tw-gradient-to: #827717
  }

  .md\:hover\:to-lime:hover {
    --tw-gradient-to: #CDDC39
  }

  .md\:hover\:to-lime-A100:hover {
    --tw-gradient-to: #F4FF81
  }

  .md\:hover\:to-lime-A200:hover {
    --tw-gradient-to: #EEFF41
  }

  .md\:hover\:to-lime-A400:hover {
    --tw-gradient-to: #C6FF00
  }

  .md\:hover\:to-lime-A700:hover {
    --tw-gradient-to: #AEEA00
  }

  .md\:hover\:to-amber-50:hover {
    --tw-gradient-to: #FFF8E1
  }

  .md\:hover\:to-amber-100:hover {
    --tw-gradient-to: #FFECB3
  }

  .md\:hover\:to-amber-200:hover {
    --tw-gradient-to: #FFE082
  }

  .md\:hover\:to-amber-300:hover {
    --tw-gradient-to: #FFD54F
  }

  .md\:hover\:to-amber-400:hover {
    --tw-gradient-to: #FFCA28
  }

  .md\:hover\:to-amber-500:hover {
    --tw-gradient-to: #FFC107
  }

  .md\:hover\:to-amber-600:hover {
    --tw-gradient-to: #FFB300
  }

  .md\:hover\:to-amber-700:hover {
    --tw-gradient-to: #FFA000
  }

  .md\:hover\:to-amber-800:hover {
    --tw-gradient-to: #FF8F00
  }

  .md\:hover\:to-amber-900:hover {
    --tw-gradient-to: #FF6F00
  }

  .md\:hover\:to-amber:hover {
    --tw-gradient-to: #FFC107
  }

  .md\:hover\:to-amber-A100:hover {
    --tw-gradient-to: #FFE57F
  }

  .md\:hover\:to-amber-A200:hover {
    --tw-gradient-to: #FFD740
  }

  .md\:hover\:to-amber-A400:hover {
    --tw-gradient-to: #FFC400
  }

  .md\:hover\:to-amber-A700:hover {
    --tw-gradient-to: #FFAB00
  }

  .md\:hover\:to-brown-50:hover {
    --tw-gradient-to: #EFEBE9
  }

  .md\:hover\:to-brown-100:hover {
    --tw-gradient-to: #D7CCC8
  }

  .md\:hover\:to-brown-200:hover {
    --tw-gradient-to: #BCAAA4
  }

  .md\:hover\:to-brown-300:hover {
    --tw-gradient-to: #A1887F
  }

  .md\:hover\:to-brown-400:hover {
    --tw-gradient-to: #8D6E63
  }

  .md\:hover\:to-brown-500:hover {
    --tw-gradient-to: #795548
  }

  .md\:hover\:to-brown-600:hover {
    --tw-gradient-to: #6D4C41
  }

  .md\:hover\:to-brown-700:hover {
    --tw-gradient-to: #5D4037
  }

  .md\:hover\:to-brown-800:hover {
    --tw-gradient-to: #4E342E
  }

  .md\:hover\:to-brown-900:hover {
    --tw-gradient-to: #3E2723
  }

  .md\:hover\:to-brown:hover {
    --tw-gradient-to: #795548
  }

  .md\:hover\:to-brown-A100:hover {
    --tw-gradient-to: #D7CCC8
  }

  .md\:hover\:to-brown-A200:hover {
    --tw-gradient-to: #BCAAA4
  }

  .md\:hover\:to-brown-A400:hover {
    --tw-gradient-to: #8D6E63
  }

  .md\:hover\:to-brown-A700:hover {
    --tw-gradient-to: #5D4037
  }

  .md\:hover\:to-blue-gray-50:hover {
    --tw-gradient-to: #ECEFF1
  }

  .md\:hover\:to-blue-gray-100:hover {
    --tw-gradient-to: #CFD8DC
  }

  .md\:hover\:to-blue-gray-200:hover {
    --tw-gradient-to: #B0BEC5
  }

  .md\:hover\:to-blue-gray-300:hover {
    --tw-gradient-to: #90A4AE
  }

  .md\:hover\:to-blue-gray-400:hover {
    --tw-gradient-to: #78909C
  }

  .md\:hover\:to-blue-gray-500:hover {
    --tw-gradient-to: #607D8B
  }

  .md\:hover\:to-blue-gray-600:hover {
    --tw-gradient-to: #546E7A
  }

  .md\:hover\:to-blue-gray-700:hover {
    --tw-gradient-to: #455A64
  }

  .md\:hover\:to-blue-gray-800:hover {
    --tw-gradient-to: #37474F
  }

  .md\:hover\:to-blue-gray-900:hover {
    --tw-gradient-to: #263238
  }

  .md\:hover\:to-blue-gray:hover {
    --tw-gradient-to: #607D8B
  }

  .md\:hover\:to-blue-gray-A100:hover {
    --tw-gradient-to: #CFD8DC
  }

  .md\:hover\:to-blue-gray-A200:hover {
    --tw-gradient-to: #B0BEC5
  }

  .md\:hover\:to-blue-gray-A400:hover {
    --tw-gradient-to: #78909C
  }

  .md\:hover\:to-blue-gray-A700:hover {
    --tw-gradient-to: #455A64
  }

  .md\:hover\:to-cyan-50:hover {
    --tw-gradient-to: #E0F7FA
  }

  .md\:hover\:to-cyan-100:hover {
    --tw-gradient-to: #B2EBF2
  }

  .md\:hover\:to-cyan-200:hover {
    --tw-gradient-to: #80DEEA
  }

  .md\:hover\:to-cyan-300:hover {
    --tw-gradient-to: #4DD0E1
  }

  .md\:hover\:to-cyan-400:hover {
    --tw-gradient-to: #26C6DA
  }

  .md\:hover\:to-cyan-500:hover {
    --tw-gradient-to: #00BCD4
  }

  .md\:hover\:to-cyan-600:hover {
    --tw-gradient-to: #00ACC1
  }

  .md\:hover\:to-cyan-700:hover {
    --tw-gradient-to: #0097A7
  }

  .md\:hover\:to-cyan-800:hover {
    --tw-gradient-to: #00838F
  }

  .md\:hover\:to-cyan-900:hover {
    --tw-gradient-to: #006064
  }

  .md\:hover\:to-cyan:hover {
    --tw-gradient-to: #00BCD4
  }

  .md\:hover\:to-cyan-A100:hover {
    --tw-gradient-to: #84FFFF
  }

  .md\:hover\:to-cyan-A200:hover {
    --tw-gradient-to: #18FFFF
  }

  .md\:hover\:to-cyan-A400:hover {
    --tw-gradient-to: #00E5FF
  }

  .md\:hover\:to-cyan-A700:hover {
    --tw-gradient-to: #00B8D4
  }

  .md\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-black:focus {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .md\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-grey-50:focus {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .md\:focus\:from-grey-100:focus {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .md\:focus\:from-grey-200:focus {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .md\:focus\:from-grey-300:focus {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .md\:focus\:from-grey-400:focus {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .md\:focus\:from-grey-500:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:focus\:from-grey-600:focus {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .md\:focus\:from-grey-700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:focus\:from-grey-800:focus {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .md\:focus\:from-grey-900:focus {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .md\:focus\:from-grey:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:focus\:from-grey-A100:focus {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .md\:focus\:from-grey-A200:focus {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .md\:focus\:from-grey-A400:focus {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .md\:focus\:from-grey-A700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:focus\:from-gray-50:focus {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .md\:focus\:from-gray-100:focus {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .md\:focus\:from-gray-200:focus {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .md\:focus\:from-gray-300:focus {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .md\:focus\:from-gray-400:focus {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .md\:focus\:from-gray-500:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:focus\:from-gray-600:focus {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .md\:focus\:from-gray-700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:focus\:from-gray-800:focus {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .md\:focus\:from-gray-900:focus {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .md\:focus\:from-gray:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:focus\:from-gray-hover:focus {
    --tw-gradient-from: rgba(0, 0, 0, 0.04);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:focus\:from-gray-A100:focus {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .md\:focus\:from-gray-A200:focus {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .md\:focus\:from-gray-A400:focus {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .md\:focus\:from-gray-A700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:focus\:from-red-50:focus {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .md\:focus\:from-red-100:focus {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .md\:focus\:from-red-200:focus {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .md\:focus\:from-red-300:focus {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .md\:focus\:from-red-400:focus {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .md\:focus\:from-red-500:focus {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .md\:focus\:from-red-600:focus {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .md\:focus\:from-red-700:focus {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .md\:focus\:from-red-800:focus {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .md\:focus\:from-red-900:focus {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .md\:focus\:from-red:focus {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .md\:focus\:from-red-A100:focus {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .md\:focus\:from-red-A200:focus {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .md\:focus\:from-red-A400:focus {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .md\:focus\:from-red-A700:focus {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .md\:focus\:from-orange-50:focus {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .md\:focus\:from-orange-100:focus {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .md\:focus\:from-orange-200:focus {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .md\:focus\:from-orange-300:focus {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .md\:focus\:from-orange-400:focus {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .md\:focus\:from-orange-500:focus {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .md\:focus\:from-orange-600:focus {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .md\:focus\:from-orange-700:focus {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .md\:focus\:from-orange-800:focus {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .md\:focus\:from-orange-900:focus {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .md\:focus\:from-orange:focus {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .md\:focus\:from-orange-A100:focus {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .md\:focus\:from-orange-A200:focus {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .md\:focus\:from-orange-A400:focus {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .md\:focus\:from-orange-A700:focus {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .md\:focus\:from-deep-orange-50:focus {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .md\:focus\:from-deep-orange-100:focus {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .md\:focus\:from-deep-orange-200:focus {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .md\:focus\:from-deep-orange-300:focus {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .md\:focus\:from-deep-orange-400:focus {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .md\:focus\:from-deep-orange-500:focus {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .md\:focus\:from-deep-orange-600:focus {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .md\:focus\:from-deep-orange-700:focus {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .md\:focus\:from-deep-orange-800:focus {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .md\:focus\:from-deep-orange-900:focus {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .md\:focus\:from-deep-orange:focus {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .md\:focus\:from-deep-orange-A100:focus {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .md\:focus\:from-deep-orange-A200:focus {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .md\:focus\:from-deep-orange-A400:focus {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .md\:focus\:from-deep-orange-A700:focus {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .md\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .md\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .md\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .md\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .md\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .md\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .md\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .md\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .md\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .md\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .md\:focus\:from-yellow:focus {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .md\:focus\:from-yellow-A100:focus {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .md\:focus\:from-yellow-A200:focus {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .md\:focus\:from-yellow-A400:focus {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .md\:focus\:from-yellow-A700:focus {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .md\:focus\:from-green-50:focus {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .md\:focus\:from-green-100:focus {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .md\:focus\:from-green-200:focus {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .md\:focus\:from-green-300:focus {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .md\:focus\:from-green-400:focus {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .md\:focus\:from-green-500:focus {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .md\:focus\:from-green-600:focus {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .md\:focus\:from-green-700:focus {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .md\:focus\:from-green-800:focus {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .md\:focus\:from-green-900:focus {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .md\:focus\:from-green:focus {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .md\:focus\:from-green-A100:focus {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .md\:focus\:from-green-A200:focus {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .md\:focus\:from-green-A400:focus {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .md\:focus\:from-green-A700:focus {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .md\:focus\:from-light-green-50:focus {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .md\:focus\:from-light-green-100:focus {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .md\:focus\:from-light-green-200:focus {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .md\:focus\:from-light-green-300:focus {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .md\:focus\:from-light-green-400:focus {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .md\:focus\:from-light-green-500:focus {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .md\:focus\:from-light-green-600:focus {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .md\:focus\:from-light-green-700:focus {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .md\:focus\:from-light-green-800:focus {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .md\:focus\:from-light-green-900:focus {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .md\:focus\:from-light-green:focus {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .md\:focus\:from-light-green-A100:focus {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .md\:focus\:from-light-green-A200:focus {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .md\:focus\:from-light-green-A400:focus {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .md\:focus\:from-light-green-A700:focus {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .md\:focus\:from-teal-50:focus {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .md\:focus\:from-teal-100:focus {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .md\:focus\:from-teal-200:focus {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .md\:focus\:from-teal-300:focus {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .md\:focus\:from-teal-400:focus {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .md\:focus\:from-teal-500:focus {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .md\:focus\:from-teal-600:focus {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .md\:focus\:from-teal-700:focus {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .md\:focus\:from-teal-800:focus {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .md\:focus\:from-teal-900:focus {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .md\:focus\:from-teal:focus {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .md\:focus\:from-teal-A100:focus {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .md\:focus\:from-teal-A200:focus {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .md\:focus\:from-teal-A400:focus {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .md\:focus\:from-teal-A700:focus {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .md\:focus\:from-blue-50:focus {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .md\:focus\:from-blue-100:focus {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .md\:focus\:from-blue-200:focus {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .md\:focus\:from-blue-300:focus {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .md\:focus\:from-blue-400:focus {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .md\:focus\:from-blue-500:focus {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .md\:focus\:from-blue-600:focus {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .md\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .md\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .md\:focus\:from-blue-900:focus {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .md\:focus\:from-blue:focus {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .md\:focus\:from-blue-A100:focus {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .md\:focus\:from-blue-A200:focus {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .md\:focus\:from-blue-A400:focus {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .md\:focus\:from-blue-A700:focus {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .md\:focus\:from-light-blue-50:focus {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .md\:focus\:from-light-blue-100:focus {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .md\:focus\:from-light-blue-200:focus {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .md\:focus\:from-light-blue-300:focus {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .md\:focus\:from-light-blue-400:focus {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .md\:focus\:from-light-blue-500:focus {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .md\:focus\:from-light-blue-600:focus {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .md\:focus\:from-light-blue-700:focus {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .md\:focus\:from-light-blue-800:focus {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .md\:focus\:from-light-blue-900:focus {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .md\:focus\:from-light-blue:focus {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .md\:focus\:from-light-blue-A100:focus {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .md\:focus\:from-light-blue-A200:focus {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .md\:focus\:from-light-blue-A400:focus {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .md\:focus\:from-light-blue-A700:focus {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .md\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .md\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .md\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .md\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .md\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .md\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .md\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .md\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .md\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .md\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .md\:focus\:from-indigo:focus {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .md\:focus\:from-indigo-A100:focus {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .md\:focus\:from-indigo-A200:focus {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .md\:focus\:from-indigo-A400:focus {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .md\:focus\:from-indigo-A700:focus {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .md\:focus\:from-purple-50:focus {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .md\:focus\:from-purple-100:focus {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .md\:focus\:from-purple-200:focus {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .md\:focus\:from-purple-300:focus {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .md\:focus\:from-purple-400:focus {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .md\:focus\:from-purple-500:focus {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .md\:focus\:from-purple-600:focus {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .md\:focus\:from-purple-700:focus {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .md\:focus\:from-purple-800:focus {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .md\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .md\:focus\:from-purple:focus {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .md\:focus\:from-purple-A100:focus {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .md\:focus\:from-purple-A200:focus {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .md\:focus\:from-purple-A400:focus {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .md\:focus\:from-purple-A700:focus {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .md\:focus\:from-deep-purple-50:focus {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .md\:focus\:from-deep-purple-100:focus {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .md\:focus\:from-deep-purple-200:focus {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .md\:focus\:from-deep-purple-300:focus {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .md\:focus\:from-deep-purple-400:focus {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .md\:focus\:from-deep-purple-500:focus {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .md\:focus\:from-deep-purple-600:focus {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .md\:focus\:from-deep-purple-700:focus {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .md\:focus\:from-deep-purple-800:focus {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .md\:focus\:from-deep-purple-900:focus {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .md\:focus\:from-deep-purple:focus {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .md\:focus\:from-deep-purple-A100:focus {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .md\:focus\:from-deep-purple-A200:focus {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .md\:focus\:from-deep-purple-A400:focus {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .md\:focus\:from-deep-purple-A700:focus {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .md\:focus\:from-pink-50:focus {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .md\:focus\:from-pink-100:focus {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .md\:focus\:from-pink-200:focus {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .md\:focus\:from-pink-300:focus {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .md\:focus\:from-pink-400:focus {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .md\:focus\:from-pink-500:focus {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .md\:focus\:from-pink-600:focus {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .md\:focus\:from-pink-700:focus {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .md\:focus\:from-pink-800:focus {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .md\:focus\:from-pink-900:focus {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .md\:focus\:from-pink:focus {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .md\:focus\:from-pink-A100:focus {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .md\:focus\:from-pink-A200:focus {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .md\:focus\:from-pink-A400:focus {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .md\:focus\:from-pink-A700:focus {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .md\:focus\:from-lime-50:focus {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .md\:focus\:from-lime-100:focus {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .md\:focus\:from-lime-200:focus {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .md\:focus\:from-lime-300:focus {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .md\:focus\:from-lime-400:focus {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .md\:focus\:from-lime-500:focus {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .md\:focus\:from-lime-600:focus {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .md\:focus\:from-lime-700:focus {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .md\:focus\:from-lime-800:focus {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .md\:focus\:from-lime-900:focus {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .md\:focus\:from-lime:focus {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .md\:focus\:from-lime-A100:focus {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .md\:focus\:from-lime-A200:focus {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .md\:focus\:from-lime-A400:focus {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .md\:focus\:from-lime-A700:focus {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .md\:focus\:from-amber-50:focus {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .md\:focus\:from-amber-100:focus {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .md\:focus\:from-amber-200:focus {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .md\:focus\:from-amber-300:focus {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .md\:focus\:from-amber-400:focus {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .md\:focus\:from-amber-500:focus {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .md\:focus\:from-amber-600:focus {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .md\:focus\:from-amber-700:focus {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .md\:focus\:from-amber-800:focus {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .md\:focus\:from-amber-900:focus {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .md\:focus\:from-amber:focus {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .md\:focus\:from-amber-A100:focus {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .md\:focus\:from-amber-A200:focus {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .md\:focus\:from-amber-A400:focus {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .md\:focus\:from-amber-A700:focus {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .md\:focus\:from-brown-50:focus {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .md\:focus\:from-brown-100:focus {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .md\:focus\:from-brown-200:focus {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .md\:focus\:from-brown-300:focus {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .md\:focus\:from-brown-400:focus {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .md\:focus\:from-brown-500:focus {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .md\:focus\:from-brown-600:focus {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .md\:focus\:from-brown-700:focus {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .md\:focus\:from-brown-800:focus {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .md\:focus\:from-brown-900:focus {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .md\:focus\:from-brown:focus {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .md\:focus\:from-brown-A100:focus {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .md\:focus\:from-brown-A200:focus {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .md\:focus\:from-brown-A400:focus {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .md\:focus\:from-brown-A700:focus {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .md\:focus\:from-blue-gray-50:focus {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .md\:focus\:from-blue-gray-100:focus {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .md\:focus\:from-blue-gray-200:focus {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .md\:focus\:from-blue-gray-300:focus {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .md\:focus\:from-blue-gray-400:focus {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .md\:focus\:from-blue-gray-500:focus {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .md\:focus\:from-blue-gray-600:focus {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .md\:focus\:from-blue-gray-700:focus {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .md\:focus\:from-blue-gray-800:focus {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .md\:focus\:from-blue-gray-900:focus {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .md\:focus\:from-blue-gray:focus {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .md\:focus\:from-blue-gray-A100:focus {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .md\:focus\:from-blue-gray-A200:focus {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .md\:focus\:from-blue-gray-A400:focus {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .md\:focus\:from-blue-gray-A700:focus {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .md\:focus\:from-cyan-50:focus {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .md\:focus\:from-cyan-100:focus {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .md\:focus\:from-cyan-200:focus {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .md\:focus\:from-cyan-300:focus {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .md\:focus\:from-cyan-400:focus {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .md\:focus\:from-cyan-500:focus {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .md\:focus\:from-cyan-600:focus {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .md\:focus\:from-cyan-700:focus {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .md\:focus\:from-cyan-800:focus {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .md\:focus\:from-cyan-900:focus {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .md\:focus\:from-cyan:focus {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .md\:focus\:from-cyan-A100:focus {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .md\:focus\:from-cyan-A200:focus {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .md\:focus\:from-cyan-A400:focus {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .md\:focus\:from-cyan-A700:focus {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .md\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .md\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-grey-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .md\:focus\:via-grey-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .md\:focus\:via-grey-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .md\:focus\:via-grey-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .md\:focus\:via-grey-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .md\:focus\:via-grey-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:focus\:via-grey-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .md\:focus\:via-grey-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:focus\:via-grey-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .md\:focus\:via-grey-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .md\:focus\:via-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:focus\:via-grey-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .md\:focus\:via-grey-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .md\:focus\:via-grey-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .md\:focus\:via-grey-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .md\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .md\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .md\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .md\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .md\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .md\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .md\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .md\:focus\:via-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .md\:focus\:via-gray-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:focus\:via-gray-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .md\:focus\:via-gray-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .md\:focus\:via-gray-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .md\:focus\:via-gray-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .md\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .md\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .md\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .md\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .md\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .md\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .md\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .md\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .md\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .md\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .md\:focus\:via-red:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .md\:focus\:via-red-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .md\:focus\:via-red-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .md\:focus\:via-red-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .md\:focus\:via-red-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .md\:focus\:via-orange-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .md\:focus\:via-orange-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .md\:focus\:via-orange-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .md\:focus\:via-orange-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .md\:focus\:via-orange-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .md\:focus\:via-orange-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .md\:focus\:via-orange-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .md\:focus\:via-orange-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .md\:focus\:via-orange-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .md\:focus\:via-orange-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .md\:focus\:via-orange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .md\:focus\:via-orange-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .md\:focus\:via-orange-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .md\:focus\:via-orange-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .md\:focus\:via-orange-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .md\:focus\:via-deep-orange-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .md\:focus\:via-deep-orange-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .md\:focus\:via-deep-orange-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .md\:focus\:via-deep-orange-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .md\:focus\:via-deep-orange-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .md\:focus\:via-deep-orange-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .md\:focus\:via-deep-orange-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .md\:focus\:via-deep-orange-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .md\:focus\:via-deep-orange-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .md\:focus\:via-deep-orange-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .md\:focus\:via-deep-orange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .md\:focus\:via-deep-orange-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .md\:focus\:via-deep-orange-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .md\:focus\:via-deep-orange-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .md\:focus\:via-deep-orange-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .md\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .md\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .md\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .md\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .md\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .md\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .md\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .md\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .md\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .md\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .md\:focus\:via-yellow:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .md\:focus\:via-yellow-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .md\:focus\:via-yellow-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .md\:focus\:via-yellow-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .md\:focus\:via-yellow-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .md\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .md\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .md\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .md\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .md\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .md\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .md\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .md\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .md\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .md\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .md\:focus\:via-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .md\:focus\:via-green-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .md\:focus\:via-green-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .md\:focus\:via-green-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .md\:focus\:via-green-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .md\:focus\:via-light-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .md\:focus\:via-light-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .md\:focus\:via-light-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .md\:focus\:via-light-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .md\:focus\:via-light-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .md\:focus\:via-light-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .md\:focus\:via-light-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .md\:focus\:via-light-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .md\:focus\:via-light-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .md\:focus\:via-light-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .md\:focus\:via-light-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .md\:focus\:via-light-green-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .md\:focus\:via-light-green-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .md\:focus\:via-light-green-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .md\:focus\:via-light-green-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .md\:focus\:via-teal-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .md\:focus\:via-teal-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .md\:focus\:via-teal-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .md\:focus\:via-teal-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .md\:focus\:via-teal-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .md\:focus\:via-teal-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .md\:focus\:via-teal-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .md\:focus\:via-teal-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .md\:focus\:via-teal-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .md\:focus\:via-teal-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .md\:focus\:via-teal:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .md\:focus\:via-teal-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .md\:focus\:via-teal-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .md\:focus\:via-teal-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .md\:focus\:via-teal-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .md\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .md\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .md\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .md\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .md\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .md\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .md\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .md\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .md\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .md\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .md\:focus\:via-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .md\:focus\:via-blue-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .md\:focus\:via-blue-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .md\:focus\:via-blue-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .md\:focus\:via-blue-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .md\:focus\:via-light-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .md\:focus\:via-light-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .md\:focus\:via-light-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .md\:focus\:via-light-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .md\:focus\:via-light-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .md\:focus\:via-light-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .md\:focus\:via-light-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .md\:focus\:via-light-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .md\:focus\:via-light-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .md\:focus\:via-light-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .md\:focus\:via-light-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .md\:focus\:via-light-blue-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .md\:focus\:via-light-blue-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .md\:focus\:via-light-blue-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .md\:focus\:via-light-blue-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .md\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .md\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .md\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .md\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .md\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .md\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .md\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .md\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .md\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .md\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .md\:focus\:via-indigo:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .md\:focus\:via-indigo-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .md\:focus\:via-indigo-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .md\:focus\:via-indigo-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .md\:focus\:via-indigo-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .md\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .md\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .md\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .md\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .md\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .md\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .md\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .md\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .md\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .md\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .md\:focus\:via-purple:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .md\:focus\:via-purple-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .md\:focus\:via-purple-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .md\:focus\:via-purple-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .md\:focus\:via-purple-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .md\:focus\:via-deep-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .md\:focus\:via-deep-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .md\:focus\:via-deep-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .md\:focus\:via-deep-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .md\:focus\:via-deep-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .md\:focus\:via-deep-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .md\:focus\:via-deep-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .md\:focus\:via-deep-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .md\:focus\:via-deep-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .md\:focus\:via-deep-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .md\:focus\:via-deep-purple:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .md\:focus\:via-deep-purple-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .md\:focus\:via-deep-purple-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .md\:focus\:via-deep-purple-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .md\:focus\:via-deep-purple-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .md\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .md\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .md\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .md\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .md\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .md\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .md\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .md\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .md\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .md\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .md\:focus\:via-pink:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .md\:focus\:via-pink-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .md\:focus\:via-pink-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .md\:focus\:via-pink-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .md\:focus\:via-pink-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .md\:focus\:via-lime-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .md\:focus\:via-lime-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .md\:focus\:via-lime-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .md\:focus\:via-lime-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .md\:focus\:via-lime-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .md\:focus\:via-lime-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .md\:focus\:via-lime-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .md\:focus\:via-lime-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .md\:focus\:via-lime-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .md\:focus\:via-lime-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .md\:focus\:via-lime:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .md\:focus\:via-lime-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .md\:focus\:via-lime-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .md\:focus\:via-lime-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .md\:focus\:via-lime-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .md\:focus\:via-amber-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .md\:focus\:via-amber-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .md\:focus\:via-amber-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .md\:focus\:via-amber-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .md\:focus\:via-amber-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .md\:focus\:via-amber-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .md\:focus\:via-amber-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .md\:focus\:via-amber-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .md\:focus\:via-amber-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .md\:focus\:via-amber-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .md\:focus\:via-amber:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .md\:focus\:via-amber-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .md\:focus\:via-amber-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .md\:focus\:via-amber-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .md\:focus\:via-amber-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .md\:focus\:via-brown-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .md\:focus\:via-brown-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .md\:focus\:via-brown-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .md\:focus\:via-brown-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .md\:focus\:via-brown-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .md\:focus\:via-brown-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .md\:focus\:via-brown-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .md\:focus\:via-brown-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .md\:focus\:via-brown-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .md\:focus\:via-brown-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .md\:focus\:via-brown:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .md\:focus\:via-brown-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .md\:focus\:via-brown-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .md\:focus\:via-brown-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .md\:focus\:via-brown-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .md\:focus\:via-blue-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .md\:focus\:via-blue-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .md\:focus\:via-blue-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .md\:focus\:via-blue-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .md\:focus\:via-blue-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .md\:focus\:via-blue-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .md\:focus\:via-blue-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .md\:focus\:via-blue-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .md\:focus\:via-blue-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .md\:focus\:via-blue-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .md\:focus\:via-blue-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .md\:focus\:via-blue-gray-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .md\:focus\:via-blue-gray-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .md\:focus\:via-blue-gray-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .md\:focus\:via-blue-gray-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .md\:focus\:via-cyan-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .md\:focus\:via-cyan-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .md\:focus\:via-cyan-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .md\:focus\:via-cyan-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .md\:focus\:via-cyan-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .md\:focus\:via-cyan-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .md\:focus\:via-cyan-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .md\:focus\:via-cyan-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .md\:focus\:via-cyan-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .md\:focus\:via-cyan-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .md\:focus\:via-cyan:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .md\:focus\:via-cyan-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .md\:focus\:via-cyan-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .md\:focus\:via-cyan-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .md\:focus\:via-cyan-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .md\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent
  }

  .md\:focus\:to-current:focus {
    --tw-gradient-to: currentColor
  }

  .md\:focus\:to-black:focus {
    --tw-gradient-to: #22292F
  }

  .md\:focus\:to-white:focus {
    --tw-gradient-to: #fff
  }

  .md\:focus\:to-grey-50:focus {
    --tw-gradient-to: #FAFAFA
  }

  .md\:focus\:to-grey-100:focus {
    --tw-gradient-to: #F5F5F5
  }

  .md\:focus\:to-grey-200:focus {
    --tw-gradient-to: #EEEEEE
  }

  .md\:focus\:to-grey-300:focus {
    --tw-gradient-to: #E0E0E0
  }

  .md\:focus\:to-grey-400:focus {
    --tw-gradient-to: #BDBDBD
  }

  .md\:focus\:to-grey-500:focus {
    --tw-gradient-to: #9E9E9E
  }

  .md\:focus\:to-grey-600:focus {
    --tw-gradient-to: #757575
  }

  .md\:focus\:to-grey-700:focus {
    --tw-gradient-to: #616161
  }

  .md\:focus\:to-grey-800:focus {
    --tw-gradient-to: #424242
  }

  .md\:focus\:to-grey-900:focus {
    --tw-gradient-to: #212121
  }

  .md\:focus\:to-grey:focus {
    --tw-gradient-to: #9E9E9E
  }

  .md\:focus\:to-grey-A100:focus {
    --tw-gradient-to: #D5D5D5
  }

  .md\:focus\:to-grey-A200:focus {
    --tw-gradient-to: #AAAAAA
  }

  .md\:focus\:to-grey-A400:focus {
    --tw-gradient-to: #303030
  }

  .md\:focus\:to-grey-A700:focus {
    --tw-gradient-to: #616161
  }

  .md\:focus\:to-gray-50:focus {
    --tw-gradient-to: #FAFAFA
  }

  .md\:focus\:to-gray-100:focus {
    --tw-gradient-to: #F5F5F5
  }

  .md\:focus\:to-gray-200:focus {
    --tw-gradient-to: #EEEEEE
  }

  .md\:focus\:to-gray-300:focus {
    --tw-gradient-to: #E0E0E0
  }

  .md\:focus\:to-gray-400:focus {
    --tw-gradient-to: #BDBDBD
  }

  .md\:focus\:to-gray-500:focus {
    --tw-gradient-to: #9E9E9E
  }

  .md\:focus\:to-gray-600:focus {
    --tw-gradient-to: #757575
  }

  .md\:focus\:to-gray-700:focus {
    --tw-gradient-to: #616161
  }

  .md\:focus\:to-gray-800:focus {
    --tw-gradient-to: #424242
  }

  .md\:focus\:to-gray-900:focus {
    --tw-gradient-to: #212121
  }

  .md\:focus\:to-gray:focus {
    --tw-gradient-to: #9E9E9E
  }

  .md\:focus\:to-gray-hover:focus {
    --tw-gradient-to: rgba(0, 0, 0, 0.04)
  }

  .md\:focus\:to-gray-A100:focus {
    --tw-gradient-to: #D5D5D5
  }

  .md\:focus\:to-gray-A200:focus {
    --tw-gradient-to: #AAAAAA
  }

  .md\:focus\:to-gray-A400:focus {
    --tw-gradient-to: #303030
  }

  .md\:focus\:to-gray-A700:focus {
    --tw-gradient-to: #616161
  }

  .md\:focus\:to-red-50:focus {
    --tw-gradient-to: #FFEBEE
  }

  .md\:focus\:to-red-100:focus {
    --tw-gradient-to: #FFCDD2
  }

  .md\:focus\:to-red-200:focus {
    --tw-gradient-to: #EF9A9A
  }

  .md\:focus\:to-red-300:focus {
    --tw-gradient-to: #E57373
  }

  .md\:focus\:to-red-400:focus {
    --tw-gradient-to: #EF5350
  }

  .md\:focus\:to-red-500:focus {
    --tw-gradient-to: #F44336
  }

  .md\:focus\:to-red-600:focus {
    --tw-gradient-to: #E53935
  }

  .md\:focus\:to-red-700:focus {
    --tw-gradient-to: #D32F2F
  }

  .md\:focus\:to-red-800:focus {
    --tw-gradient-to: #C62828
  }

  .md\:focus\:to-red-900:focus {
    --tw-gradient-to: #B71C1C
  }

  .md\:focus\:to-red:focus {
    --tw-gradient-to: #F44336
  }

  .md\:focus\:to-red-A100:focus {
    --tw-gradient-to: #FF8A80
  }

  .md\:focus\:to-red-A200:focus {
    --tw-gradient-to: #FF5252
  }

  .md\:focus\:to-red-A400:focus {
    --tw-gradient-to: #FF1744
  }

  .md\:focus\:to-red-A700:focus {
    --tw-gradient-to: #D50000
  }

  .md\:focus\:to-orange-50:focus {
    --tw-gradient-to: #FFF3E0
  }

  .md\:focus\:to-orange-100:focus {
    --tw-gradient-to: #FFE0B2
  }

  .md\:focus\:to-orange-200:focus {
    --tw-gradient-to: #FFCC80
  }

  .md\:focus\:to-orange-300:focus {
    --tw-gradient-to: #FFB74D
  }

  .md\:focus\:to-orange-400:focus {
    --tw-gradient-to: #FFA726
  }

  .md\:focus\:to-orange-500:focus {
    --tw-gradient-to: #FF9800
  }

  .md\:focus\:to-orange-600:focus {
    --tw-gradient-to: #FB8C00
  }

  .md\:focus\:to-orange-700:focus {
    --tw-gradient-to: #F57C00
  }

  .md\:focus\:to-orange-800:focus {
    --tw-gradient-to: #EF6C00
  }

  .md\:focus\:to-orange-900:focus {
    --tw-gradient-to: #E65100
  }

  .md\:focus\:to-orange:focus {
    --tw-gradient-to: #FF9800
  }

  .md\:focus\:to-orange-A100:focus {
    --tw-gradient-to: #FFD180
  }

  .md\:focus\:to-orange-A200:focus {
    --tw-gradient-to: #FFAB40
  }

  .md\:focus\:to-orange-A400:focus {
    --tw-gradient-to: #FF9100
  }

  .md\:focus\:to-orange-A700:focus {
    --tw-gradient-to: #FF6D00
  }

  .md\:focus\:to-deep-orange-50:focus {
    --tw-gradient-to: #FBE9E7
  }

  .md\:focus\:to-deep-orange-100:focus {
    --tw-gradient-to: #FFCCBC
  }

  .md\:focus\:to-deep-orange-200:focus {
    --tw-gradient-to: #FFAB91
  }

  .md\:focus\:to-deep-orange-300:focus {
    --tw-gradient-to: #FF8A65
  }

  .md\:focus\:to-deep-orange-400:focus {
    --tw-gradient-to: #FF7043
  }

  .md\:focus\:to-deep-orange-500:focus {
    --tw-gradient-to: #FF5722
  }

  .md\:focus\:to-deep-orange-600:focus {
    --tw-gradient-to: #F4511E
  }

  .md\:focus\:to-deep-orange-700:focus {
    --tw-gradient-to: #E64A19
  }

  .md\:focus\:to-deep-orange-800:focus {
    --tw-gradient-to: #D84315
  }

  .md\:focus\:to-deep-orange-900:focus {
    --tw-gradient-to: #BF360C
  }

  .md\:focus\:to-deep-orange:focus {
    --tw-gradient-to: #FF5722
  }

  .md\:focus\:to-deep-orange-A100:focus {
    --tw-gradient-to: #FF9E80
  }

  .md\:focus\:to-deep-orange-A200:focus {
    --tw-gradient-to: #FF6E40
  }

  .md\:focus\:to-deep-orange-A400:focus {
    --tw-gradient-to: #FF3D00
  }

  .md\:focus\:to-deep-orange-A700:focus {
    --tw-gradient-to: #DD2C00
  }

  .md\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #FFFDE7
  }

  .md\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #FFF9C4
  }

  .md\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #FFF59D
  }

  .md\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #FFF176
  }

  .md\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #FFEE58
  }

  .md\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #FFEB3B
  }

  .md\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #FDD835
  }

  .md\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #FBC02D
  }

  .md\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #F9A825
  }

  .md\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #F57F17
  }

  .md\:focus\:to-yellow:focus {
    --tw-gradient-to: #FFEB3B
  }

  .md\:focus\:to-yellow-A100:focus {
    --tw-gradient-to: #FFFF8D
  }

  .md\:focus\:to-yellow-A200:focus {
    --tw-gradient-to: #FFFF00
  }

  .md\:focus\:to-yellow-A400:focus {
    --tw-gradient-to: #FFEA00
  }

  .md\:focus\:to-yellow-A700:focus {
    --tw-gradient-to: #FFD600
  }

  .md\:focus\:to-green-50:focus {
    --tw-gradient-to: #E8F5E9
  }

  .md\:focus\:to-green-100:focus {
    --tw-gradient-to: #C8E6C9
  }

  .md\:focus\:to-green-200:focus {
    --tw-gradient-to: #A5D6A7
  }

  .md\:focus\:to-green-300:focus {
    --tw-gradient-to: #81C784
  }

  .md\:focus\:to-green-400:focus {
    --tw-gradient-to: #66BB6A
  }

  .md\:focus\:to-green-500:focus {
    --tw-gradient-to: #4CAF50
  }

  .md\:focus\:to-green-600:focus {
    --tw-gradient-to: #43A047
  }

  .md\:focus\:to-green-700:focus {
    --tw-gradient-to: #388E3C
  }

  .md\:focus\:to-green-800:focus {
    --tw-gradient-to: #2E7D32
  }

  .md\:focus\:to-green-900:focus {
    --tw-gradient-to: #1B5E20
  }

  .md\:focus\:to-green:focus {
    --tw-gradient-to: #4CAF50
  }

  .md\:focus\:to-green-A100:focus {
    --tw-gradient-to: #B9F6CA
  }

  .md\:focus\:to-green-A200:focus {
    --tw-gradient-to: #69F0AE
  }

  .md\:focus\:to-green-A400:focus {
    --tw-gradient-to: #00E676
  }

  .md\:focus\:to-green-A700:focus {
    --tw-gradient-to: #00C853
  }

  .md\:focus\:to-light-green-50:focus {
    --tw-gradient-to: #F1F8E9
  }

  .md\:focus\:to-light-green-100:focus {
    --tw-gradient-to: #DCEDC8
  }

  .md\:focus\:to-light-green-200:focus {
    --tw-gradient-to: #C5E1A5
  }

  .md\:focus\:to-light-green-300:focus {
    --tw-gradient-to: #AED581
  }

  .md\:focus\:to-light-green-400:focus {
    --tw-gradient-to: #9CCC65
  }

  .md\:focus\:to-light-green-500:focus {
    --tw-gradient-to: #8BC34A
  }

  .md\:focus\:to-light-green-600:focus {
    --tw-gradient-to: #7CB342
  }

  .md\:focus\:to-light-green-700:focus {
    --tw-gradient-to: #689F38
  }

  .md\:focus\:to-light-green-800:focus {
    --tw-gradient-to: #558B2F
  }

  .md\:focus\:to-light-green-900:focus {
    --tw-gradient-to: #33691E
  }

  .md\:focus\:to-light-green:focus {
    --tw-gradient-to: #8BC34A
  }

  .md\:focus\:to-light-green-A100:focus {
    --tw-gradient-to: #CCFF90
  }

  .md\:focus\:to-light-green-A200:focus {
    --tw-gradient-to: #B2FF59
  }

  .md\:focus\:to-light-green-A400:focus {
    --tw-gradient-to: #76FF03
  }

  .md\:focus\:to-light-green-A700:focus {
    --tw-gradient-to: #64DD17
  }

  .md\:focus\:to-teal-50:focus {
    --tw-gradient-to: #E0F2F1
  }

  .md\:focus\:to-teal-100:focus {
    --tw-gradient-to: #B2DFDB
  }

  .md\:focus\:to-teal-200:focus {
    --tw-gradient-to: #80CBC4
  }

  .md\:focus\:to-teal-300:focus {
    --tw-gradient-to: #4DB6AC
  }

  .md\:focus\:to-teal-400:focus {
    --tw-gradient-to: #26A69A
  }

  .md\:focus\:to-teal-500:focus {
    --tw-gradient-to: #009688
  }

  .md\:focus\:to-teal-600:focus {
    --tw-gradient-to: #00897B
  }

  .md\:focus\:to-teal-700:focus {
    --tw-gradient-to: #00796B
  }

  .md\:focus\:to-teal-800:focus {
    --tw-gradient-to: #00695C
  }

  .md\:focus\:to-teal-900:focus {
    --tw-gradient-to: #004D40
  }

  .md\:focus\:to-teal:focus {
    --tw-gradient-to: #009688
  }

  .md\:focus\:to-teal-A100:focus {
    --tw-gradient-to: #A7FFEB
  }

  .md\:focus\:to-teal-A200:focus {
    --tw-gradient-to: #64FFDA
  }

  .md\:focus\:to-teal-A400:focus {
    --tw-gradient-to: #1DE9B6
  }

  .md\:focus\:to-teal-A700:focus {
    --tw-gradient-to: #00BFA5
  }

  .md\:focus\:to-blue-50:focus {
    --tw-gradient-to: #E3F2FD
  }

  .md\:focus\:to-blue-100:focus {
    --tw-gradient-to: #BBDEFB
  }

  .md\:focus\:to-blue-200:focus {
    --tw-gradient-to: #90CAF9
  }

  .md\:focus\:to-blue-300:focus {
    --tw-gradient-to: #64B5F6
  }

  .md\:focus\:to-blue-400:focus {
    --tw-gradient-to: #42A5F5
  }

  .md\:focus\:to-blue-500:focus {
    --tw-gradient-to: #2196F3
  }

  .md\:focus\:to-blue-600:focus {
    --tw-gradient-to: #1E88E5
  }

  .md\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1976D2
  }

  .md\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1565C0
  }

  .md\:focus\:to-blue-900:focus {
    --tw-gradient-to: #0D47A1
  }

  .md\:focus\:to-blue:focus {
    --tw-gradient-to: #2196F3
  }

  .md\:focus\:to-blue-A100:focus {
    --tw-gradient-to: #82B1FF
  }

  .md\:focus\:to-blue-A200:focus {
    --tw-gradient-to: #448AFF
  }

  .md\:focus\:to-blue-A400:focus {
    --tw-gradient-to: #2979FF
  }

  .md\:focus\:to-blue-A700:focus {
    --tw-gradient-to: #2962FF
  }

  .md\:focus\:to-light-blue-50:focus {
    --tw-gradient-to: #E1F5FE
  }

  .md\:focus\:to-light-blue-100:focus {
    --tw-gradient-to: #B3E5FC
  }

  .md\:focus\:to-light-blue-200:focus {
    --tw-gradient-to: #81D4FA
  }

  .md\:focus\:to-light-blue-300:focus {
    --tw-gradient-to: #4FC3F7
  }

  .md\:focus\:to-light-blue-400:focus {
    --tw-gradient-to: #29B6F6
  }

  .md\:focus\:to-light-blue-500:focus {
    --tw-gradient-to: #03A9F4
  }

  .md\:focus\:to-light-blue-600:focus {
    --tw-gradient-to: #039BE5
  }

  .md\:focus\:to-light-blue-700:focus {
    --tw-gradient-to: #0288D1
  }

  .md\:focus\:to-light-blue-800:focus {
    --tw-gradient-to: #0277BD
  }

  .md\:focus\:to-light-blue-900:focus {
    --tw-gradient-to: #01579B
  }

  .md\:focus\:to-light-blue:focus {
    --tw-gradient-to: #03A9F4
  }

  .md\:focus\:to-light-blue-A100:focus {
    --tw-gradient-to: #80D8FF
  }

  .md\:focus\:to-light-blue-A200:focus {
    --tw-gradient-to: #40C4FF
  }

  .md\:focus\:to-light-blue-A400:focus {
    --tw-gradient-to: #00B0FF
  }

  .md\:focus\:to-light-blue-A700:focus {
    --tw-gradient-to: #0091EA
  }

  .md\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #E8EAF6
  }

  .md\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #C5CAE9
  }

  .md\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #9FA8DA
  }

  .md\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #7986CB
  }

  .md\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #5C6BC0
  }

  .md\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #3F51B5
  }

  .md\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #3949AB
  }

  .md\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #303F9F
  }

  .md\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #283593
  }

  .md\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #1A237E
  }

  .md\:focus\:to-indigo:focus {
    --tw-gradient-to: #3F51B5
  }

  .md\:focus\:to-indigo-A100:focus {
    --tw-gradient-to: #8C9EFF
  }

  .md\:focus\:to-indigo-A200:focus {
    --tw-gradient-to: #536DFE
  }

  .md\:focus\:to-indigo-A400:focus {
    --tw-gradient-to: #3D5AFE
  }

  .md\:focus\:to-indigo-A700:focus {
    --tw-gradient-to: #304FFE
  }

  .md\:focus\:to-purple-50:focus {
    --tw-gradient-to: #F3E5F5
  }

  .md\:focus\:to-purple-100:focus {
    --tw-gradient-to: #E1BEE7
  }

  .md\:focus\:to-purple-200:focus {
    --tw-gradient-to: #CE93D8
  }

  .md\:focus\:to-purple-300:focus {
    --tw-gradient-to: #BA68C8
  }

  .md\:focus\:to-purple-400:focus {
    --tw-gradient-to: #AB47BC
  }

  .md\:focus\:to-purple-500:focus {
    --tw-gradient-to: #9C27B0
  }

  .md\:focus\:to-purple-600:focus {
    --tw-gradient-to: #8E24AA
  }

  .md\:focus\:to-purple-700:focus {
    --tw-gradient-to: #7B1FA2
  }

  .md\:focus\:to-purple-800:focus {
    --tw-gradient-to: #6A1B9A
  }

  .md\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4A148C
  }

  .md\:focus\:to-purple:focus {
    --tw-gradient-to: #9C27B0
  }

  .md\:focus\:to-purple-A100:focus {
    --tw-gradient-to: #EA80FC
  }

  .md\:focus\:to-purple-A200:focus {
    --tw-gradient-to: #E040FB
  }

  .md\:focus\:to-purple-A400:focus {
    --tw-gradient-to: #D500F9
  }

  .md\:focus\:to-purple-A700:focus {
    --tw-gradient-to: #AA00FF
  }

  .md\:focus\:to-deep-purple-50:focus {
    --tw-gradient-to: #EDE7F6
  }

  .md\:focus\:to-deep-purple-100:focus {
    --tw-gradient-to: #D1C4E9
  }

  .md\:focus\:to-deep-purple-200:focus {
    --tw-gradient-to: #B39DDB
  }

  .md\:focus\:to-deep-purple-300:focus {
    --tw-gradient-to: #9575CD
  }

  .md\:focus\:to-deep-purple-400:focus {
    --tw-gradient-to: #7E57C2
  }

  .md\:focus\:to-deep-purple-500:focus {
    --tw-gradient-to: #673AB7
  }

  .md\:focus\:to-deep-purple-600:focus {
    --tw-gradient-to: #5E35B1
  }

  .md\:focus\:to-deep-purple-700:focus {
    --tw-gradient-to: #512DA8
  }

  .md\:focus\:to-deep-purple-800:focus {
    --tw-gradient-to: #4527A0
  }

  .md\:focus\:to-deep-purple-900:focus {
    --tw-gradient-to: #311B92
  }

  .md\:focus\:to-deep-purple:focus {
    --tw-gradient-to: #673AB7
  }

  .md\:focus\:to-deep-purple-A100:focus {
    --tw-gradient-to: #B388FF
  }

  .md\:focus\:to-deep-purple-A200:focus {
    --tw-gradient-to: #7C4DFF
  }

  .md\:focus\:to-deep-purple-A400:focus {
    --tw-gradient-to: #651FFF
  }

  .md\:focus\:to-deep-purple-A700:focus {
    --tw-gradient-to: #6200EA
  }

  .md\:focus\:to-pink-50:focus {
    --tw-gradient-to: #FCE4EC
  }

  .md\:focus\:to-pink-100:focus {
    --tw-gradient-to: #F8BBD0
  }

  .md\:focus\:to-pink-200:focus {
    --tw-gradient-to: #F48FB1
  }

  .md\:focus\:to-pink-300:focus {
    --tw-gradient-to: #F06292
  }

  .md\:focus\:to-pink-400:focus {
    --tw-gradient-to: #EC407A
  }

  .md\:focus\:to-pink-500:focus {
    --tw-gradient-to: #E91E63
  }

  .md\:focus\:to-pink-600:focus {
    --tw-gradient-to: #D81B60
  }

  .md\:focus\:to-pink-700:focus {
    --tw-gradient-to: #C2185B
  }

  .md\:focus\:to-pink-800:focus {
    --tw-gradient-to: #AD1457
  }

  .md\:focus\:to-pink-900:focus {
    --tw-gradient-to: #880E4F
  }

  .md\:focus\:to-pink:focus {
    --tw-gradient-to: #E91E63
  }

  .md\:focus\:to-pink-A100:focus {
    --tw-gradient-to: #FF80AB
  }

  .md\:focus\:to-pink-A200:focus {
    --tw-gradient-to: #FF4081
  }

  .md\:focus\:to-pink-A400:focus {
    --tw-gradient-to: #F50057
  }

  .md\:focus\:to-pink-A700:focus {
    --tw-gradient-to: #C51162
  }

  .md\:focus\:to-lime-50:focus {
    --tw-gradient-to: #F9FBE7
  }

  .md\:focus\:to-lime-100:focus {
    --tw-gradient-to: #F0F4C3
  }

  .md\:focus\:to-lime-200:focus {
    --tw-gradient-to: #E6EE9C
  }

  .md\:focus\:to-lime-300:focus {
    --tw-gradient-to: #DCE775
  }

  .md\:focus\:to-lime-400:focus {
    --tw-gradient-to: #D4E157
  }

  .md\:focus\:to-lime-500:focus {
    --tw-gradient-to: #CDDC39
  }

  .md\:focus\:to-lime-600:focus {
    --tw-gradient-to: #C0CA33
  }

  .md\:focus\:to-lime-700:focus {
    --tw-gradient-to: #AFB42B
  }

  .md\:focus\:to-lime-800:focus {
    --tw-gradient-to: #9E9D24
  }

  .md\:focus\:to-lime-900:focus {
    --tw-gradient-to: #827717
  }

  .md\:focus\:to-lime:focus {
    --tw-gradient-to: #CDDC39
  }

  .md\:focus\:to-lime-A100:focus {
    --tw-gradient-to: #F4FF81
  }

  .md\:focus\:to-lime-A200:focus {
    --tw-gradient-to: #EEFF41
  }

  .md\:focus\:to-lime-A400:focus {
    --tw-gradient-to: #C6FF00
  }

  .md\:focus\:to-lime-A700:focus {
    --tw-gradient-to: #AEEA00
  }

  .md\:focus\:to-amber-50:focus {
    --tw-gradient-to: #FFF8E1
  }

  .md\:focus\:to-amber-100:focus {
    --tw-gradient-to: #FFECB3
  }

  .md\:focus\:to-amber-200:focus {
    --tw-gradient-to: #FFE082
  }

  .md\:focus\:to-amber-300:focus {
    --tw-gradient-to: #FFD54F
  }

  .md\:focus\:to-amber-400:focus {
    --tw-gradient-to: #FFCA28
  }

  .md\:focus\:to-amber-500:focus {
    --tw-gradient-to: #FFC107
  }

  .md\:focus\:to-amber-600:focus {
    --tw-gradient-to: #FFB300
  }

  .md\:focus\:to-amber-700:focus {
    --tw-gradient-to: #FFA000
  }

  .md\:focus\:to-amber-800:focus {
    --tw-gradient-to: #FF8F00
  }

  .md\:focus\:to-amber-900:focus {
    --tw-gradient-to: #FF6F00
  }

  .md\:focus\:to-amber:focus {
    --tw-gradient-to: #FFC107
  }

  .md\:focus\:to-amber-A100:focus {
    --tw-gradient-to: #FFE57F
  }

  .md\:focus\:to-amber-A200:focus {
    --tw-gradient-to: #FFD740
  }

  .md\:focus\:to-amber-A400:focus {
    --tw-gradient-to: #FFC400
  }

  .md\:focus\:to-amber-A700:focus {
    --tw-gradient-to: #FFAB00
  }

  .md\:focus\:to-brown-50:focus {
    --tw-gradient-to: #EFEBE9
  }

  .md\:focus\:to-brown-100:focus {
    --tw-gradient-to: #D7CCC8
  }

  .md\:focus\:to-brown-200:focus {
    --tw-gradient-to: #BCAAA4
  }

  .md\:focus\:to-brown-300:focus {
    --tw-gradient-to: #A1887F
  }

  .md\:focus\:to-brown-400:focus {
    --tw-gradient-to: #8D6E63
  }

  .md\:focus\:to-brown-500:focus {
    --tw-gradient-to: #795548
  }

  .md\:focus\:to-brown-600:focus {
    --tw-gradient-to: #6D4C41
  }

  .md\:focus\:to-brown-700:focus {
    --tw-gradient-to: #5D4037
  }

  .md\:focus\:to-brown-800:focus {
    --tw-gradient-to: #4E342E
  }

  .md\:focus\:to-brown-900:focus {
    --tw-gradient-to: #3E2723
  }

  .md\:focus\:to-brown:focus {
    --tw-gradient-to: #795548
  }

  .md\:focus\:to-brown-A100:focus {
    --tw-gradient-to: #D7CCC8
  }

  .md\:focus\:to-brown-A200:focus {
    --tw-gradient-to: #BCAAA4
  }

  .md\:focus\:to-brown-A400:focus {
    --tw-gradient-to: #8D6E63
  }

  .md\:focus\:to-brown-A700:focus {
    --tw-gradient-to: #5D4037
  }

  .md\:focus\:to-blue-gray-50:focus {
    --tw-gradient-to: #ECEFF1
  }

  .md\:focus\:to-blue-gray-100:focus {
    --tw-gradient-to: #CFD8DC
  }

  .md\:focus\:to-blue-gray-200:focus {
    --tw-gradient-to: #B0BEC5
  }

  .md\:focus\:to-blue-gray-300:focus {
    --tw-gradient-to: #90A4AE
  }

  .md\:focus\:to-blue-gray-400:focus {
    --tw-gradient-to: #78909C
  }

  .md\:focus\:to-blue-gray-500:focus {
    --tw-gradient-to: #607D8B
  }

  .md\:focus\:to-blue-gray-600:focus {
    --tw-gradient-to: #546E7A
  }

  .md\:focus\:to-blue-gray-700:focus {
    --tw-gradient-to: #455A64
  }

  .md\:focus\:to-blue-gray-800:focus {
    --tw-gradient-to: #37474F
  }

  .md\:focus\:to-blue-gray-900:focus {
    --tw-gradient-to: #263238
  }

  .md\:focus\:to-blue-gray:focus {
    --tw-gradient-to: #607D8B
  }

  .md\:focus\:to-blue-gray-A100:focus {
    --tw-gradient-to: #CFD8DC
  }

  .md\:focus\:to-blue-gray-A200:focus {
    --tw-gradient-to: #B0BEC5
  }

  .md\:focus\:to-blue-gray-A400:focus {
    --tw-gradient-to: #78909C
  }

  .md\:focus\:to-blue-gray-A700:focus {
    --tw-gradient-to: #455A64
  }

  .md\:focus\:to-cyan-50:focus {
    --tw-gradient-to: #E0F7FA
  }

  .md\:focus\:to-cyan-100:focus {
    --tw-gradient-to: #B2EBF2
  }

  .md\:focus\:to-cyan-200:focus {
    --tw-gradient-to: #80DEEA
  }

  .md\:focus\:to-cyan-300:focus {
    --tw-gradient-to: #4DD0E1
  }

  .md\:focus\:to-cyan-400:focus {
    --tw-gradient-to: #26C6DA
  }

  .md\:focus\:to-cyan-500:focus {
    --tw-gradient-to: #00BCD4
  }

  .md\:focus\:to-cyan-600:focus {
    --tw-gradient-to: #00ACC1
  }

  .md\:focus\:to-cyan-700:focus {
    --tw-gradient-to: #0097A7
  }

  .md\:focus\:to-cyan-800:focus {
    --tw-gradient-to: #00838F
  }

  .md\:focus\:to-cyan-900:focus {
    --tw-gradient-to: #006064
  }

  .md\:focus\:to-cyan:focus {
    --tw-gradient-to: #00BCD4
  }

  .md\:focus\:to-cyan-A100:focus {
    --tw-gradient-to: #84FFFF
  }

  .md\:focus\:to-cyan-A200:focus {
    --tw-gradient-to: #18FFFF
  }

  .md\:focus\:to-cyan-A400:focus {
    --tw-gradient-to: #00E5FF
  }

  .md\:focus\:to-cyan-A700:focus {
    --tw-gradient-to: #00B8D4
  }

  .md\:bg-opacity-0 {
    --tw-bg-opacity: 0
  }

  .md\:bg-opacity-5 {
    --tw-bg-opacity: 0.05
  }

  .md\:bg-opacity-10 {
    --tw-bg-opacity: 0.1
  }

  .md\:bg-opacity-20 {
    --tw-bg-opacity: 0.2
  }

  .md\:bg-opacity-25 {
    --tw-bg-opacity: 0.25
  }

  .md\:bg-opacity-30 {
    --tw-bg-opacity: 0.3
  }

  .md\:bg-opacity-40 {
    --tw-bg-opacity: 0.4
  }

  .md\:bg-opacity-50 {
    --tw-bg-opacity: 0.5
  }

  .md\:bg-opacity-60 {
    --tw-bg-opacity: 0.6
  }

  .md\:bg-opacity-70 {
    --tw-bg-opacity: 0.7
  }

  .md\:bg-opacity-75 {
    --tw-bg-opacity: 0.75
  }

  .md\:bg-opacity-80 {
    --tw-bg-opacity: 0.8
  }

  .md\:bg-opacity-90 {
    --tw-bg-opacity: 0.9
  }

  .md\:bg-opacity-95 {
    --tw-bg-opacity: 0.95
  }

  .md\:bg-opacity-100 {
    --tw-bg-opacity: 1
  }

  .group:hover .md\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0
  }

  .group:hover .md\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05
  }

  .group:hover .md\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1
  }

  .group:hover .md\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2
  }

  .group:hover .md\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25
  }

  .group:hover .md\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3
  }

  .group:hover .md\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4
  }

  .group:hover .md\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5
  }

  .group:hover .md\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6
  }

  .group:hover .md\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7
  }

  .group:hover .md\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75
  }

  .group:hover .md\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8
  }

  .group:hover .md\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9
  }

  .group:hover .md\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95
  }

  .group:hover .md\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1
  }

  .md\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0
  }

  .md\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05
  }

  .md\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1
  }

  .md\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2
  }

  .md\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25
  }

  .md\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3
  }

  .md\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4
  }

  .md\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5
  }

  .md\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6
  }

  .md\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7
  }

  .md\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75
  }

  .md\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8
  }

  .md\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9
  }

  .md\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95
  }

  .md\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1
  }

  .md\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0
  }

  .md\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05
  }

  .md\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1
  }

  .md\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2
  }

  .md\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25
  }

  .md\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3
  }

  .md\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4
  }

  .md\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5
  }

  .md\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6
  }

  .md\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7
  }

  .md\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75
  }

  .md\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8
  }

  .md\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9
  }

  .md\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95
  }

  .md\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1
  }

  .md\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0
  }

  .md\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05
  }

  .md\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1
  }

  .md\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2
  }

  .md\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25
  }

  .md\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3
  }

  .md\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4
  }

  .md\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5
  }

  .md\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6
  }

  .md\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7
  }

  .md\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75
  }

  .md\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8
  }

  .md\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9
  }

  .md\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95
  }

  .md\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1
  }

  .md\:bg-bottom {
    background-position: bottom
  }

  .md\:bg-center {
    background-position: center
  }

  .md\:bg-left {
    background-position: left
  }

  .md\:bg-left-bottom {
    background-position: left bottom
  }

  .md\:bg-left-top {
    background-position: left top
  }

  .md\:bg-right {
    background-position: right
  }

  .md\:bg-right-bottom {
    background-position: right bottom
  }

  .md\:bg-right-top {
    background-position: right top
  }

  .md\:bg-top {
    background-position: top
  }

  .md\:bg-repeat {
    background-repeat: repeat
  }

  .md\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .md\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .md\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .md\:bg-repeat-round {
    background-repeat: round
  }

  .md\:bg-repeat-space {
    background-repeat: space
  }

  .md\:bg-auto {
    background-size: auto
  }

  .md\:bg-cover {
    background-size: cover
  }

  .md\:bg-contain {
    background-size: contain
  }

  .md\:border-collapse {
    border-collapse: collapse
  }

  .md\:border-separate {
    border-collapse: separate
  }

  .md\:border-transparent {
    border-color: transparent
  }

  .md\:border-current {
    border-color: currentColor
  }

  .md\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .md\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .md\:border-grey-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .md\:border-grey-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .md\:border-grey-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .md\:border-grey-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .md\:border-grey-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .md\:border-grey-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:border-grey-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .md\:border-grey-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:border-grey-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .md\:border-grey-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .md\:border-grey {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:border-grey-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .md\:border-grey-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .md\:border-grey-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .md\:border-grey-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .md\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .md\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .md\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .md\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .md\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .md\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .md\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .md\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:border-gray-hover {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .md\:border-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .md\:border-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .md\:border-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .md\:border-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .md\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .md\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .md\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .md\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .md\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .md\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .md\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .md\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .md\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .md\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .md\:border-red-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .md\:border-red-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .md\:border-red-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .md\:border-red-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .md\:border-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .md\:border-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .md\:border-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .md\:border-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .md\:border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .md\:border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .md\:border-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .md\:border-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .md\:border-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .md\:border-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .md\:border-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .md\:border-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .md\:border-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .md\:border-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .md\:border-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .md\:border-deep-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .md\:border-deep-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .md\:border-deep-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .md\:border-deep-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .md\:border-deep-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .md\:border-deep-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .md\:border-deep-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .md\:border-deep-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .md\:border-deep-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .md\:border-deep-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .md\:border-deep-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .md\:border-deep-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .md\:border-deep-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .md\:border-deep-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .md\:border-deep-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .md\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .md\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .md\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .md\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .md\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .md\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .md\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .md\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .md\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .md\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .md\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .md\:border-yellow-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .md\:border-yellow-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .md\:border-yellow-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .md\:border-yellow-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .md\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .md\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .md\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .md\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .md\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .md\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .md\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .md\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .md\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .md\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .md\:border-green {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .md\:border-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .md\:border-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .md\:border-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .md\:border-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .md\:border-light-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .md\:border-light-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .md\:border-light-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .md\:border-light-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .md\:border-light-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .md\:border-light-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .md\:border-light-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .md\:border-light-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .md\:border-light-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .md\:border-light-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .md\:border-light-green {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .md\:border-light-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .md\:border-light-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .md\:border-light-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .md\:border-light-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .md\:border-teal-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .md\:border-teal-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .md\:border-teal-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .md\:border-teal-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .md\:border-teal-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .md\:border-teal-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .md\:border-teal-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .md\:border-teal-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .md\:border-teal-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .md\:border-teal-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .md\:border-teal {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .md\:border-teal-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .md\:border-teal-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .md\:border-teal-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .md\:border-teal-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .md\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .md\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .md\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .md\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .md\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .md\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .md\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .md\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .md\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .md\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .md\:border-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .md\:border-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .md\:border-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .md\:border-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .md\:border-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .md\:border-light-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .md\:border-light-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .md\:border-light-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .md\:border-light-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .md\:border-light-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .md\:border-light-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .md\:border-light-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .md\:border-light-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .md\:border-light-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .md\:border-light-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .md\:border-light-blue {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .md\:border-light-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .md\:border-light-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .md\:border-light-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .md\:border-light-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .md\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .md\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .md\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .md\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .md\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .md\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .md\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .md\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .md\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .md\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .md\:border-indigo {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .md\:border-indigo-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .md\:border-indigo-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .md\:border-indigo-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .md\:border-indigo-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .md\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .md\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .md\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .md\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .md\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .md\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .md\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .md\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .md\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .md\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .md\:border-purple {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .md\:border-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .md\:border-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .md\:border-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .md\:border-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .md\:border-deep-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .md\:border-deep-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .md\:border-deep-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .md\:border-deep-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .md\:border-deep-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .md\:border-deep-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .md\:border-deep-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .md\:border-deep-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .md\:border-deep-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .md\:border-deep-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .md\:border-deep-purple {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .md\:border-deep-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .md\:border-deep-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .md\:border-deep-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .md\:border-deep-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .md\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .md\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .md\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .md\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .md\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .md\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .md\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .md\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .md\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .md\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .md\:border-pink {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .md\:border-pink-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .md\:border-pink-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .md\:border-pink-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .md\:border-pink-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .md\:border-lime-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .md\:border-lime-100 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .md\:border-lime-200 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .md\:border-lime-300 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .md\:border-lime-400 {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .md\:border-lime-500 {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .md\:border-lime-600 {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .md\:border-lime-700 {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .md\:border-lime-800 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .md\:border-lime-900 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .md\:border-lime {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .md\:border-lime-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .md\:border-lime-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .md\:border-lime-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .md\:border-lime-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .md\:border-amber-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .md\:border-amber-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .md\:border-amber-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .md\:border-amber-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .md\:border-amber-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .md\:border-amber-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .md\:border-amber-600 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .md\:border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .md\:border-amber-800 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .md\:border-amber-900 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .md\:border-amber {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .md\:border-amber-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .md\:border-amber-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .md\:border-amber-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .md\:border-amber-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .md\:border-brown-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .md\:border-brown-100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .md\:border-brown-200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .md\:border-brown-300 {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .md\:border-brown-400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .md\:border-brown-500 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .md\:border-brown-600 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .md\:border-brown-700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .md\:border-brown-800 {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .md\:border-brown-900 {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .md\:border-brown {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .md\:border-brown-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .md\:border-brown-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .md\:border-brown-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .md\:border-brown-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .md\:border-blue-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .md\:border-blue-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .md\:border-blue-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .md\:border-blue-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .md\:border-blue-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .md\:border-blue-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .md\:border-blue-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .md\:border-blue-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .md\:border-blue-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .md\:border-blue-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .md\:border-blue-gray {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .md\:border-blue-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .md\:border-blue-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .md\:border-blue-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .md\:border-blue-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .md\:border-cyan-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .md\:border-cyan-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .md\:border-cyan-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .md\:border-cyan-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .md\:border-cyan-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .md\:border-cyan-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .md\:border-cyan-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .md\:border-cyan-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .md\:border-cyan-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .md\:border-cyan-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .md\:border-cyan {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .md\:border-cyan-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .md\:border-cyan-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .md\:border-cyan-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .md\:border-cyan-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-transparent {
    border-color: transparent
  }

  .group:hover .md\:group-hover\:border-current {
    border-color: currentColor
  }

  .group:hover .md\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-grey-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-grey-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-grey-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-grey-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-grey-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-grey-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-grey-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-grey-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-grey-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-grey-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-grey {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-grey-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-grey-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-grey-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-grey-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-gray-hover {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .group:hover .md\:group-hover\:border-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-red-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-red-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-red-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-red-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-yellow-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-yellow-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-yellow-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-yellow-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-green {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-green {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-teal-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-teal-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-teal-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-teal-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-teal-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-teal-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-teal-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-teal-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-teal-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-teal-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-teal {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-teal-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-teal-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-teal-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-teal-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-blue {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-light-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-indigo {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-indigo-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-indigo-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-indigo-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-indigo-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-purple {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-purple {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-deep-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-pink {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-pink-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-pink-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-pink-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-pink-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-lime-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-lime-100 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-lime-200 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-lime-300 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-lime-400 {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-lime-500 {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-lime-600 {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-lime-700 {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-lime-800 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-lime-900 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-lime {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-lime-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-lime-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-lime-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-lime-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-amber-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-amber-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-amber-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-amber-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-amber-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-amber-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-amber-600 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-amber-800 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-amber-900 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-amber {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-amber-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-amber-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-amber-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-amber-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-brown-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-brown-100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-brown-200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-brown-300 {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-brown-400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-brown-500 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-brown-600 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-brown-700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-brown-800 {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-brown-900 {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-brown {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-brown-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-brown-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-brown-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-brown-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-gray {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-blue-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-cyan-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-cyan-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-cyan-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-cyan-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-cyan-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-cyan-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-cyan-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-cyan-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-cyan-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-cyan-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-cyan {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-cyan-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-cyan-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-cyan-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .group:hover .md\:group-hover\:border-cyan-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-transparent:focus-within {
    border-color: transparent
  }

  .md\:focus-within\:border-current:focus-within {
    border-color: currentColor
  }

  .md\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-grey-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-grey-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-grey-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-grey-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-grey-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-grey-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-grey-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-grey-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-grey-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-grey-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-grey-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-grey-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-grey-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-grey-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-gray-hover:focus-within {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .md\:focus-within\:border-gray-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-gray-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-gray-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-gray-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-red:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-red-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-red-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-red-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-red-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-orange-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-orange-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-orange-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-orange-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-orange-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-orange-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-orange-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-orange-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-orange-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-orange-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-orange:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-orange-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-orange-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-orange-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-orange-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-orange-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-orange-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-orange-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-orange-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-orange-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-orange-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-orange-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-orange-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-orange-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-orange-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-orange:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-orange-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-orange-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-orange-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-orange-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-yellow:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-yellow-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-yellow-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-yellow-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-yellow-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-green-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-green-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-green-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-green-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-green-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-green-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-green-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-green-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-teal-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-teal-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-teal-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-teal-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-teal-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-teal-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-teal-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-teal-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-teal-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-teal-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-teal:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-teal-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-teal-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-teal-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-teal-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-blue-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-blue-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-blue-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-light-blue-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-indigo:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-indigo-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-indigo-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-indigo-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-indigo-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-purple:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-purple-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-purple-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-purple-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-purple-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-purple:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-purple-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-purple-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-purple-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-deep-purple-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-pink:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-pink-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-pink-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-pink-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-pink-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-lime-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-lime-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-lime-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-lime-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-lime-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-lime-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-lime-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-lime-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-lime-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-lime-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-lime:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-lime-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-lime-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-lime-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-lime-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-amber-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-amber-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-amber-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-amber-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-amber-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-amber-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-amber-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-amber-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-amber-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-amber-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-amber:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-amber-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-amber-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-amber-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-amber-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-brown-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-brown-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-brown-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-brown-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-brown-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-brown-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-brown-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-brown-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-brown-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-brown-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-brown:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-brown-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-brown-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-brown-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-brown-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-gray-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-gray-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-gray-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-blue-gray-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-cyan-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-cyan-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-cyan-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-cyan-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-cyan-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-cyan-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-cyan-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-cyan-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-cyan-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-cyan-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-cyan:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-cyan-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-cyan-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-cyan-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .md\:focus-within\:border-cyan-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .md\:hover\:border-transparent:hover {
    border-color: transparent
  }

  .md\:hover\:border-current:hover {
    border-color: currentColor
  }

  .md\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .md\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-grey-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .md\:hover\:border-grey-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .md\:hover\:border-grey-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .md\:hover\:border-grey-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .md\:hover\:border-grey-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .md\:hover\:border-grey-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:hover\:border-grey-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .md\:hover\:border-grey-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:hover\:border-grey-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .md\:hover\:border-grey-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .md\:hover\:border-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:hover\:border-grey-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .md\:hover\:border-grey-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .md\:hover\:border-grey-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .md\:hover\:border-grey-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray-hover:hover {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .md\:hover\:border-gray-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .md\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .md\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .md\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .md\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .md\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .md\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .md\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .md\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .md\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .md\:hover\:border-red:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .md\:hover\:border-red-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .md\:hover\:border-red-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .md\:hover\:border-red-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .md\:hover\:border-red-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-orange-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .md\:hover\:border-orange-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .md\:hover\:border-orange-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .md\:hover\:border-orange-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .md\:hover\:border-orange-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .md\:hover\:border-orange-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-orange-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-orange-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-orange-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-orange-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-orange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-orange-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .md\:hover\:border-orange-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .md\:hover\:border-orange-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-orange-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-orange-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-orange-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-orange-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-orange-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-orange-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-orange-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-orange-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-orange-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-orange-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-orange-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-orange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-orange-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-orange-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-orange-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-orange-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .md\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .md\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .md\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .md\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .md\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .md\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .md\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .md\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .md\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .md\:hover\:border-yellow:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .md\:hover\:border-yellow-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .md\:hover\:border-yellow-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-yellow-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-yellow-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .md\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .md\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .md\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .md\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .md\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .md\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .md\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .md\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .md\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .md\:hover\:border-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .md\:hover\:border-green-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .md\:hover\:border-green-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .md\:hover\:border-green-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .md\:hover\:border-green-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-green-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-green-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-green-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-green-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .md\:hover\:border-teal-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .md\:hover\:border-teal-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .md\:hover\:border-teal-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .md\:hover\:border-teal-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .md\:hover\:border-teal-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .md\:hover\:border-teal-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .md\:hover\:border-teal-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .md\:hover\:border-teal-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .md\:hover\:border-teal-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .md\:hover\:border-teal-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .md\:hover\:border-teal:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .md\:hover\:border-teal-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .md\:hover\:border-teal-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .md\:hover\:border-teal-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .md\:hover\:border-teal-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-blue-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-blue-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-blue-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-light-blue-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .md\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .md\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .md\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .md\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .md\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .md\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .md\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .md\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .md\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .md\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .md\:hover\:border-indigo:hover {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .md\:hover\:border-indigo-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-indigo-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .md\:hover\:border-indigo-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .md\:hover\:border-indigo-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .md\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .md\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .md\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .md\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .md\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .md\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .md\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .md\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .md\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .md\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .md\:hover\:border-purple:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .md\:hover\:border-purple-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .md\:hover\:border-purple-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .md\:hover\:border-purple-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .md\:hover\:border-purple-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-purple:hover {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-purple-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-purple-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-purple-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-deep-purple-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .md\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .md\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .md\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .md\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .md\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .md\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .md\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .md\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .md\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .md\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .md\:hover\:border-pink:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .md\:hover\:border-pink-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .md\:hover\:border-pink-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .md\:hover\:border-pink-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .md\:hover\:border-pink-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .md\:hover\:border-lime-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .md\:hover\:border-lime-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .md\:hover\:border-lime-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .md\:hover\:border-lime-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .md\:hover\:border-lime-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .md\:hover\:border-lime-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .md\:hover\:border-lime-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .md\:hover\:border-lime-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .md\:hover\:border-lime-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .md\:hover\:border-lime-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .md\:hover\:border-lime:hover {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .md\:hover\:border-lime-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .md\:hover\:border-lime-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .md\:hover\:border-lime-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-lime-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-amber-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .md\:hover\:border-amber-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .md\:hover\:border-amber-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .md\:hover\:border-amber-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .md\:hover\:border-amber-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .md\:hover\:border-amber-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .md\:hover\:border-amber-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-amber-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-amber-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-amber-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-amber:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .md\:hover\:border-amber-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .md\:hover\:border-amber-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .md\:hover\:border-amber-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-amber-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-brown-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .md\:hover\:border-brown-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .md\:hover\:border-brown-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .md\:hover\:border-brown-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .md\:hover\:border-brown-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .md\:hover\:border-brown-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .md\:hover\:border-brown-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .md\:hover\:border-brown-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .md\:hover\:border-brown-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .md\:hover\:border-brown-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .md\:hover\:border-brown:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .md\:hover\:border-brown-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .md\:hover\:border-brown-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .md\:hover\:border-brown-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .md\:hover\:border-brown-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-gray-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-gray-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-gray-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .md\:hover\:border-blue-gray-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .md\:focus\:border-transparent:focus {
    border-color: transparent
  }

  .md\:focus\:border-current:focus {
    border-color: currentColor
  }

  .md\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .md\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-grey-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .md\:focus\:border-grey-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .md\:focus\:border-grey-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .md\:focus\:border-grey-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .md\:focus\:border-grey-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .md\:focus\:border-grey-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:focus\:border-grey-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .md\:focus\:border-grey-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:focus\:border-grey-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .md\:focus\:border-grey-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .md\:focus\:border-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:focus\:border-grey-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .md\:focus\:border-grey-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .md\:focus\:border-grey-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .md\:focus\:border-grey-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray-hover:focus {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .md\:focus\:border-gray-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .md\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .md\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .md\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .md\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .md\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .md\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .md\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .md\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .md\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .md\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .md\:focus\:border-red:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .md\:focus\:border-red-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .md\:focus\:border-red-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .md\:focus\:border-red-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .md\:focus\:border-red-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-orange-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .md\:focus\:border-orange-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .md\:focus\:border-orange-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .md\:focus\:border-orange-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .md\:focus\:border-orange-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .md\:focus\:border-orange-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-orange-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-orange-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-orange-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-orange-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-orange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-orange-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .md\:focus\:border-orange-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .md\:focus\:border-orange-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-orange-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-orange-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-orange-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-orange-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-orange-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-orange-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-orange-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-orange-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-orange-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-orange-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-orange-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-orange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-orange-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-orange-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-orange-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-orange-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .md\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .md\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .md\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .md\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .md\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .md\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .md\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .md\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .md\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .md\:focus\:border-yellow:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .md\:focus\:border-yellow-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .md\:focus\:border-yellow-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-yellow-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-yellow-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .md\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .md\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .md\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .md\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .md\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .md\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .md\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .md\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .md\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .md\:focus\:border-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .md\:focus\:border-green-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .md\:focus\:border-green-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .md\:focus\:border-green-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .md\:focus\:border-green-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-green-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-green-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-green-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-green-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .md\:focus\:border-teal-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .md\:focus\:border-teal-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .md\:focus\:border-teal-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .md\:focus\:border-teal-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .md\:focus\:border-teal-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .md\:focus\:border-teal-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .md\:focus\:border-teal-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .md\:focus\:border-teal-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .md\:focus\:border-teal-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .md\:focus\:border-teal-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .md\:focus\:border-teal:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .md\:focus\:border-teal-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .md\:focus\:border-teal-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .md\:focus\:border-teal-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .md\:focus\:border-teal-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-blue-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-blue-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-blue-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-light-blue-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .md\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .md\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .md\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .md\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .md\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .md\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .md\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .md\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .md\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .md\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .md\:focus\:border-indigo:focus {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .md\:focus\:border-indigo-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-indigo-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .md\:focus\:border-indigo-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .md\:focus\:border-indigo-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .md\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .md\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .md\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .md\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .md\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .md\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .md\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .md\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .md\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .md\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .md\:focus\:border-purple:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .md\:focus\:border-purple-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .md\:focus\:border-purple-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .md\:focus\:border-purple-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .md\:focus\:border-purple-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-purple:focus {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-purple-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-purple-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-purple-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-deep-purple-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .md\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .md\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .md\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .md\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .md\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .md\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .md\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .md\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .md\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .md\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .md\:focus\:border-pink:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .md\:focus\:border-pink-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .md\:focus\:border-pink-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .md\:focus\:border-pink-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .md\:focus\:border-pink-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .md\:focus\:border-lime-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .md\:focus\:border-lime-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .md\:focus\:border-lime-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .md\:focus\:border-lime-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .md\:focus\:border-lime-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .md\:focus\:border-lime-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .md\:focus\:border-lime-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .md\:focus\:border-lime-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .md\:focus\:border-lime-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .md\:focus\:border-lime-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .md\:focus\:border-lime:focus {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .md\:focus\:border-lime-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .md\:focus\:border-lime-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .md\:focus\:border-lime-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-lime-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-amber-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .md\:focus\:border-amber-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .md\:focus\:border-amber-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .md\:focus\:border-amber-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .md\:focus\:border-amber-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .md\:focus\:border-amber-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .md\:focus\:border-amber-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-amber-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-amber-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-amber-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-amber:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .md\:focus\:border-amber-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .md\:focus\:border-amber-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .md\:focus\:border-amber-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-amber-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-brown-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .md\:focus\:border-brown-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .md\:focus\:border-brown-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .md\:focus\:border-brown-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .md\:focus\:border-brown-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .md\:focus\:border-brown-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .md\:focus\:border-brown-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .md\:focus\:border-brown-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .md\:focus\:border-brown-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .md\:focus\:border-brown-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .md\:focus\:border-brown:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .md\:focus\:border-brown-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .md\:focus\:border-brown-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .md\:focus\:border-brown-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .md\:focus\:border-brown-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-gray-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-gray-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-gray-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .md\:focus\:border-blue-gray-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .md\:border-opacity-0 {
    --tw-border-opacity: 0
  }

  .md\:border-opacity-5 {
    --tw-border-opacity: 0.05
  }

  .md\:border-opacity-10 {
    --tw-border-opacity: 0.1
  }

  .md\:border-opacity-20 {
    --tw-border-opacity: 0.2
  }

  .md\:border-opacity-25 {
    --tw-border-opacity: 0.25
  }

  .md\:border-opacity-30 {
    --tw-border-opacity: 0.3
  }

  .md\:border-opacity-40 {
    --tw-border-opacity: 0.4
  }

  .md\:border-opacity-50 {
    --tw-border-opacity: 0.5
  }

  .md\:border-opacity-60 {
    --tw-border-opacity: 0.6
  }

  .md\:border-opacity-70 {
    --tw-border-opacity: 0.7
  }

  .md\:border-opacity-75 {
    --tw-border-opacity: 0.75
  }

  .md\:border-opacity-80 {
    --tw-border-opacity: 0.8
  }

  .md\:border-opacity-90 {
    --tw-border-opacity: 0.9
  }

  .md\:border-opacity-95 {
    --tw-border-opacity: 0.95
  }

  .md\:border-opacity-100 {
    --tw-border-opacity: 1
  }

  .group:hover .md\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0
  }

  .group:hover .md\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05
  }

  .group:hover .md\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1
  }

  .group:hover .md\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2
  }

  .group:hover .md\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25
  }

  .group:hover .md\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3
  }

  .group:hover .md\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4
  }

  .group:hover .md\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5
  }

  .group:hover .md\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6
  }

  .group:hover .md\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7
  }

  .group:hover .md\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75
  }

  .group:hover .md\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8
  }

  .group:hover .md\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9
  }

  .group:hover .md\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95
  }

  .group:hover .md\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1
  }

  .md\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0
  }

  .md\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05
  }

  .md\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1
  }

  .md\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2
  }

  .md\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25
  }

  .md\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3
  }

  .md\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4
  }

  .md\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5
  }

  .md\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6
  }

  .md\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7
  }

  .md\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75
  }

  .md\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8
  }

  .md\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9
  }

  .md\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95
  }

  .md\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1
  }

  .md\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0
  }

  .md\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05
  }

  .md\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1
  }

  .md\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2
  }

  .md\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25
  }

  .md\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3
  }

  .md\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4
  }

  .md\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5
  }

  .md\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6
  }

  .md\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7
  }

  .md\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75
  }

  .md\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8
  }

  .md\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9
  }

  .md\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95
  }

  .md\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1
  }

  .md\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0
  }

  .md\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05
  }

  .md\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1
  }

  .md\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2
  }

  .md\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25
  }

  .md\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3
  }

  .md\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4
  }

  .md\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5
  }

  .md\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6
  }

  .md\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7
  }

  .md\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75
  }

  .md\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8
  }

  .md\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9
  }

  .md\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95
  }

  .md\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1
  }

  .md\:rounded-2 {
    border-radius: .2rem
  }

  .md\:rounded-4 {
    border-radius: .4rem
  }

  .md\:rounded-6 {
    border-radius: .6rem
  }

  .md\:rounded-8 {
    border-radius: .8rem
  }

  .md\:rounded-12 {
    border-radius: 1.2rem
  }

  .md\:rounded-16 {
    border-radius: 1.6rem
  }

  .md\:rounded-20 {
    border-radius: 2rem
  }

  .md\:rounded-24 {
    border-radius: 2.4rem
  }

  .md\:rounded-28 {
    border-radius: 2.8rem
  }

  .md\:rounded-32 {
    border-radius: 3.2rem
  }

  .md\:rounded-none {
    border-radius: 0
  }

  .md\:rounded-sm {
    border-radius: .2rem
  }

  .md\:rounded {
    border-radius: .4rem
  }

  .md\:rounded-md {
    border-radius: .6rem
  }

  .md\:rounded-lg {
    border-radius: .8rem
  }

  .md\:rounded-xl {
    border-radius: 1.2rem
  }

  .md\:rounded-2xl {
    border-radius: 1.6rem
  }

  .md\:rounded-3xl {
    border-radius: 2.4rem
  }

  .md\:rounded-full {
    border-radius: 9999px
  }

  .md\:rounded-t-2 {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem
  }

  .md\:rounded-r-2 {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
  }

  .md\:rounded-b-2 {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .md\:rounded-l-2 {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .md\:rounded-t-4 {
    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem
  }

  .md\:rounded-r-4 {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem
  }

  .md\:rounded-b-4 {
    border-bottom-right-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .md\:rounded-l-4 {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .md\:rounded-t-6 {
    border-top-left-radius: .6rem;
    border-top-right-radius: .6rem
  }

  .md\:rounded-r-6 {
    border-top-right-radius: .6rem;
    border-bottom-right-radius: .6rem
  }

  .md\:rounded-b-6 {
    border-bottom-right-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .md\:rounded-l-6 {
    border-top-left-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .md\:rounded-t-8 {
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem
  }

  .md\:rounded-r-8 {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem
  }

  .md\:rounded-b-8 {
    border-bottom-right-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .md\:rounded-l-8 {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .md\:rounded-t-12 {
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem
  }

  .md\:rounded-r-12 {
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem
  }

  .md\:rounded-b-12 {
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .md\:rounded-l-12 {
    border-top-left-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .md\:rounded-t-16 {
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem
  }

  .md\:rounded-r-16 {
    border-top-right-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem
  }

  .md\:rounded-b-16 {
    border-bottom-right-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .md\:rounded-l-16 {
    border-top-left-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .md\:rounded-t-20 {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem
  }

  .md\:rounded-r-20 {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem
  }

  .md\:rounded-b-20 {
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem
  }

  .md\:rounded-l-20 {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem
  }

  .md\:rounded-t-24 {
    border-top-left-radius: 2.4rem;
    border-top-right-radius: 2.4rem
  }

  .md\:rounded-r-24 {
    border-top-right-radius: 2.4rem;
    border-bottom-right-radius: 2.4rem
  }

  .md\:rounded-b-24 {
    border-bottom-right-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .md\:rounded-l-24 {
    border-top-left-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .md\:rounded-t-28 {
    border-top-left-radius: 2.8rem;
    border-top-right-radius: 2.8rem
  }

  .md\:rounded-r-28 {
    border-top-right-radius: 2.8rem;
    border-bottom-right-radius: 2.8rem
  }

  .md\:rounded-b-28 {
    border-bottom-right-radius: 2.8rem;
    border-bottom-left-radius: 2.8rem
  }

  .md\:rounded-l-28 {
    border-top-left-radius: 2.8rem;
    border-bottom-left-radius: 2.8rem
  }

  .md\:rounded-t-32 {
    border-top-left-radius: 3.2rem;
    border-top-right-radius: 3.2rem
  }

  .md\:rounded-r-32 {
    border-top-right-radius: 3.2rem;
    border-bottom-right-radius: 3.2rem
  }

  .md\:rounded-b-32 {
    border-bottom-right-radius: 3.2rem;
    border-bottom-left-radius: 3.2rem
  }

  .md\:rounded-l-32 {
    border-top-left-radius: 3.2rem;
    border-bottom-left-radius: 3.2rem
  }

  .md\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .md\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .md\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .md\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .md\:rounded-t-sm {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem
  }

  .md\:rounded-r-sm {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
  }

  .md\:rounded-b-sm {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .md\:rounded-l-sm {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .md\:rounded-t {
    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem
  }

  .md\:rounded-r {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem
  }

  .md\:rounded-b {
    border-bottom-right-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .md\:rounded-l {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .md\:rounded-t-md {
    border-top-left-radius: .6rem;
    border-top-right-radius: .6rem
  }

  .md\:rounded-r-md {
    border-top-right-radius: .6rem;
    border-bottom-right-radius: .6rem
  }

  .md\:rounded-b-md {
    border-bottom-right-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .md\:rounded-l-md {
    border-top-left-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .md\:rounded-t-lg {
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem
  }

  .md\:rounded-r-lg {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem
  }

  .md\:rounded-b-lg {
    border-bottom-right-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .md\:rounded-l-lg {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .md\:rounded-t-xl {
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem
  }

  .md\:rounded-r-xl {
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem
  }

  .md\:rounded-b-xl {
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .md\:rounded-l-xl {
    border-top-left-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .md\:rounded-t-2xl {
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem
  }

  .md\:rounded-r-2xl {
    border-top-right-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem
  }

  .md\:rounded-b-2xl {
    border-bottom-right-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .md\:rounded-l-2xl {
    border-top-left-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .md\:rounded-t-3xl {
    border-top-left-radius: 2.4rem;
    border-top-right-radius: 2.4rem
  }

  .md\:rounded-r-3xl {
    border-top-right-radius: 2.4rem;
    border-bottom-right-radius: 2.4rem
  }

  .md\:rounded-b-3xl {
    border-bottom-right-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .md\:rounded-l-3xl {
    border-top-left-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .md\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .md\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .md\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .md\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .md\:rounded-tl-2 {
    border-top-left-radius: .2rem
  }

  .md\:rounded-tr-2 {
    border-top-right-radius: .2rem
  }

  .md\:rounded-br-2 {
    border-bottom-right-radius: .2rem
  }

  .md\:rounded-bl-2 {
    border-bottom-left-radius: .2rem
  }

  .md\:rounded-tl-4 {
    border-top-left-radius: .4rem
  }

  .md\:rounded-tr-4 {
    border-top-right-radius: .4rem
  }

  .md\:rounded-br-4 {
    border-bottom-right-radius: .4rem
  }

  .md\:rounded-bl-4 {
    border-bottom-left-radius: .4rem
  }

  .md\:rounded-tl-6 {
    border-top-left-radius: .6rem
  }

  .md\:rounded-tr-6 {
    border-top-right-radius: .6rem
  }

  .md\:rounded-br-6 {
    border-bottom-right-radius: .6rem
  }

  .md\:rounded-bl-6 {
    border-bottom-left-radius: .6rem
  }

  .md\:rounded-tl-8 {
    border-top-left-radius: .8rem
  }

  .md\:rounded-tr-8 {
    border-top-right-radius: .8rem
  }

  .md\:rounded-br-8 {
    border-bottom-right-radius: .8rem
  }

  .md\:rounded-bl-8 {
    border-bottom-left-radius: .8rem
  }

  .md\:rounded-tl-12 {
    border-top-left-radius: 1.2rem
  }

  .md\:rounded-tr-12 {
    border-top-right-radius: 1.2rem
  }

  .md\:rounded-br-12 {
    border-bottom-right-radius: 1.2rem
  }

  .md\:rounded-bl-12 {
    border-bottom-left-radius: 1.2rem
  }

  .md\:rounded-tl-16 {
    border-top-left-radius: 1.6rem
  }

  .md\:rounded-tr-16 {
    border-top-right-radius: 1.6rem
  }

  .md\:rounded-br-16 {
    border-bottom-right-radius: 1.6rem
  }

  .md\:rounded-bl-16 {
    border-bottom-left-radius: 1.6rem
  }

  .md\:rounded-tl-20 {
    border-top-left-radius: 2rem
  }

  .md\:rounded-tr-20 {
    border-top-right-radius: 2rem
  }

  .md\:rounded-br-20 {
    border-bottom-right-radius: 2rem
  }

  .md\:rounded-bl-20 {
    border-bottom-left-radius: 2rem
  }

  .md\:rounded-tl-24 {
    border-top-left-radius: 2.4rem
  }

  .md\:rounded-tr-24 {
    border-top-right-radius: 2.4rem
  }

  .md\:rounded-br-24 {
    border-bottom-right-radius: 2.4rem
  }

  .md\:rounded-bl-24 {
    border-bottom-left-radius: 2.4rem
  }

  .md\:rounded-tl-28 {
    border-top-left-radius: 2.8rem
  }

  .md\:rounded-tr-28 {
    border-top-right-radius: 2.8rem
  }

  .md\:rounded-br-28 {
    border-bottom-right-radius: 2.8rem
  }

  .md\:rounded-bl-28 {
    border-bottom-left-radius: 2.8rem
  }

  .md\:rounded-tl-32 {
    border-top-left-radius: 3.2rem
  }

  .md\:rounded-tr-32 {
    border-top-right-radius: 3.2rem
  }

  .md\:rounded-br-32 {
    border-bottom-right-radius: 3.2rem
  }

  .md\:rounded-bl-32 {
    border-bottom-left-radius: 3.2rem
  }

  .md\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .md\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .md\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .md\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .md\:rounded-tl-sm {
    border-top-left-radius: .2rem
  }

  .md\:rounded-tr-sm {
    border-top-right-radius: .2rem
  }

  .md\:rounded-br-sm {
    border-bottom-right-radius: .2rem
  }

  .md\:rounded-bl-sm {
    border-bottom-left-radius: .2rem
  }

  .md\:rounded-tl {
    border-top-left-radius: .4rem
  }

  .md\:rounded-tr {
    border-top-right-radius: .4rem
  }

  .md\:rounded-br {
    border-bottom-right-radius: .4rem
  }

  .md\:rounded-bl {
    border-bottom-left-radius: .4rem
  }

  .md\:rounded-tl-md {
    border-top-left-radius: .6rem
  }

  .md\:rounded-tr-md {
    border-top-right-radius: .6rem
  }

  .md\:rounded-br-md {
    border-bottom-right-radius: .6rem
  }

  .md\:rounded-bl-md {
    border-bottom-left-radius: .6rem
  }

  .md\:rounded-tl-lg {
    border-top-left-radius: .8rem
  }

  .md\:rounded-tr-lg {
    border-top-right-radius: .8rem
  }

  .md\:rounded-br-lg {
    border-bottom-right-radius: .8rem
  }

  .md\:rounded-bl-lg {
    border-bottom-left-radius: .8rem
  }

  .md\:rounded-tl-xl {
    border-top-left-radius: 1.2rem
  }

  .md\:rounded-tr-xl {
    border-top-right-radius: 1.2rem
  }

  .md\:rounded-br-xl {
    border-bottom-right-radius: 1.2rem
  }

  .md\:rounded-bl-xl {
    border-bottom-left-radius: 1.2rem
  }

  .md\:rounded-tl-2xl {
    border-top-left-radius: 1.6rem
  }

  .md\:rounded-tr-2xl {
    border-top-right-radius: 1.6rem
  }

  .md\:rounded-br-2xl {
    border-bottom-right-radius: 1.6rem
  }

  .md\:rounded-bl-2xl {
    border-bottom-left-radius: 1.6rem
  }

  .md\:rounded-tl-3xl {
    border-top-left-radius: 2.4rem
  }

  .md\:rounded-tr-3xl {
    border-top-right-radius: 2.4rem
  }

  .md\:rounded-br-3xl {
    border-bottom-right-radius: 2.4rem
  }

  .md\:rounded-bl-3xl {
    border-bottom-left-radius: 2.4rem
  }

  .md\:rounded-tl-full {
    border-top-left-radius: 9999px
  }

  .md\:rounded-tr-full {
    border-top-right-radius: 9999px
  }

  .md\:rounded-br-full {
    border-bottom-right-radius: 9999px
  }

  .md\:rounded-bl-full {
    border-bottom-left-radius: 9999px
  }

  .md\:border-solid {
    border-style: solid
  }

  .md\:border-dashed {
    border-style: dashed
  }

  .md\:border-dotted {
    border-style: dotted
  }

  .md\:border-double {
    border-style: double
  }

  .md\:border-none {
    border-style: none
  }

  .md\:border-0 {
    border-width: 0px
  }

  .md\:border-1 {
    border-width: 1px
  }

  .md\:border-2 {
    border-width: 2px
  }

  .md\:border-3 {
    border-width: 3px
  }

  .md\:border-4 {
    border-width: 4px
  }

  .md\:border-8 {
    border-width: 8px
  }

  .md\:border {
    border-width: 1px
  }

  .md\:border-t-0 {
    border-top-width: 0px
  }

  .md\:border-r-0 {
    border-right-width: 0px
  }

  .md\:border-b-0 {
    border-bottom-width: 0px
  }

  .md\:border-l-0 {
    border-left-width: 0px
  }

  .md\:border-t-1 {
    border-top-width: 1px
  }

  .md\:border-r-1 {
    border-right-width: 1px
  }

  .md\:border-b-1 {
    border-bottom-width: 1px
  }

  .md\:border-l-1 {
    border-left-width: 1px
  }

  .md\:border-t-2 {
    border-top-width: 2px
  }

  .md\:border-r-2 {
    border-right-width: 2px
  }

  .md\:border-b-2 {
    border-bottom-width: 2px
  }

  .md\:border-l-2 {
    border-left-width: 2px
  }

  .md\:border-t-3 {
    border-top-width: 3px
  }

  .md\:border-r-3 {
    border-right-width: 3px
  }

  .md\:border-b-3 {
    border-bottom-width: 3px
  }

  .md\:border-l-3 {
    border-left-width: 3px
  }

  .md\:border-t-4 {
    border-top-width: 4px
  }

  .md\:border-r-4 {
    border-right-width: 4px
  }

  .md\:border-b-4 {
    border-bottom-width: 4px
  }

  .md\:border-l-4 {
    border-left-width: 4px
  }

  .md\:border-t-8 {
    border-top-width: 8px
  }

  .md\:border-r-8 {
    border-right-width: 8px
  }

  .md\:border-b-8 {
    border-bottom-width: 8px
  }

  .md\:border-l-8 {
    border-left-width: 8px
  }

  .md\:border-t {
    border-top-width: 1px
  }

  .md\:border-r {
    border-right-width: 1px
  }

  .md\:border-b {
    border-bottom-width: 1px
  }

  .md\:border-l {
    border-left-width: 1px
  }

  .md\:box-border {
    box-sizing: border-box
  }

  .md\:box-content {
    box-sizing: content-box
  }

  .md\:cursor-auto {
    cursor: auto
  }

  .md\:cursor-default {
    cursor: default
  }

  .md\:cursor-pointer {
    cursor: pointer
  }

  .md\:cursor-wait {
    cursor: wait
  }

  .md\:cursor-text {
    cursor: text
  }

  .md\:cursor-move {
    cursor: move
  }

  .md\:cursor-not-allowed {
    cursor: not-allowed
  }

  .md\:block {
    display: block
  }

  .md\:inline-block {
    display: inline-block
  }

  .md\:inline {
    display: inline
  }

  .md\:flex {
    display: flex
  }

  .md\:inline-flex {
    display: inline-flex
  }

  .md\:table {
    display: table
  }

  .md\:table-caption {
    display: table-caption
  }

  .md\:table-cell {
    display: table-cell
  }

  .md\:table-column {
    display: table-column
  }

  .md\:table-column-group {
    display: table-column-group
  }

  .md\:table-footer-group {
    display: table-footer-group
  }

  .md\:table-header-group {
    display: table-header-group
  }

  .md\:table-row-group {
    display: table-row-group
  }

  .md\:table-row {
    display: table-row
  }

  .md\:flow-root {
    display: flow-root
  }

  .md\:grid {
    display: grid
  }

  .md\:inline-grid {
    display: inline-grid
  }

  .md\:contents {
    display: contents
  }

  .md\:hidden {
    display: none
  }

  .md\:hover\:block:hover {
    display: block
  }

  .md\:hover\:inline-block:hover {
    display: inline-block
  }

  .md\:hover\:inline:hover {
    display: inline
  }

  .md\:hover\:flex:hover {
    display: flex
  }

  .md\:hover\:inline-flex:hover {
    display: inline-flex
  }

  .md\:hover\:table:hover {
    display: table
  }

  .md\:hover\:table-caption:hover {
    display: table-caption
  }

  .md\:hover\:table-cell:hover {
    display: table-cell
  }

  .md\:hover\:table-column:hover {
    display: table-column
  }

  .md\:hover\:table-column-group:hover {
    display: table-column-group
  }

  .md\:hover\:table-footer-group:hover {
    display: table-footer-group
  }

  .md\:hover\:table-header-group:hover {
    display: table-header-group
  }

  .md\:hover\:table-row-group:hover {
    display: table-row-group
  }

  .md\:hover\:table-row:hover {
    display: table-row
  }

  .md\:hover\:flow-root:hover {
    display: flow-root
  }

  .md\:hover\:grid:hover {
    display: grid
  }

  .md\:hover\:inline-grid:hover {
    display: inline-grid
  }

  .md\:hover\:contents:hover {
    display: contents
  }

  .md\:hover\:hidden:hover {
    display: none
  }

  .md\:focus\:block:focus {
    display: block
  }

  .md\:focus\:inline-block:focus {
    display: inline-block
  }

  .md\:focus\:inline:focus {
    display: inline
  }

  .md\:focus\:flex:focus {
    display: flex
  }

  .md\:focus\:inline-flex:focus {
    display: inline-flex
  }

  .md\:focus\:table:focus {
    display: table
  }

  .md\:focus\:table-caption:focus {
    display: table-caption
  }

  .md\:focus\:table-cell:focus {
    display: table-cell
  }

  .md\:focus\:table-column:focus {
    display: table-column
  }

  .md\:focus\:table-column-group:focus {
    display: table-column-group
  }

  .md\:focus\:table-footer-group:focus {
    display: table-footer-group
  }

  .md\:focus\:table-header-group:focus {
    display: table-header-group
  }

  .md\:focus\:table-row-group:focus {
    display: table-row-group
  }

  .md\:focus\:table-row:focus {
    display: table-row
  }

  .md\:focus\:flow-root:focus {
    display: flow-root
  }

  .md\:focus\:grid:focus {
    display: grid
  }

  .md\:focus\:inline-grid:focus {
    display: inline-grid
  }

  .md\:focus\:contents:focus {
    display: contents
  }

  .md\:focus\:hidden:focus {
    display: none
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .md\:flex-col {
    flex-direction: column
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .md\:flex-wrap {
    flex-wrap: wrap
  }

  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap
  }

  .md\:place-items-auto {
    place-items: auto
  }

  .md\:place-items-start {
    place-items: start
  }

  .md\:place-items-end {
    place-items: end
  }

  .md\:place-items-center {
    place-items: center
  }

  .md\:place-items-stretch {
    place-items: stretch
  }

  .md\:place-content-center {
    place-content: center
  }

  .md\:place-content-start {
    place-content: start
  }

  .md\:place-content-end {
    place-content: end
  }

  .md\:place-content-between {
    place-content: space-between
  }

  .md\:place-content-around {
    place-content: space-around
  }

  .md\:place-content-evenly {
    place-content: space-evenly
  }

  .md\:place-content-stretch {
    place-content: stretch
  }

  .md\:place-self-auto {
    place-self: auto
  }

  .md\:place-self-start {
    place-self: start
  }

  .md\:place-self-end {
    place-self: end
  }

  .md\:place-self-center {
    place-self: center
  }

  .md\:place-self-stretch {
    place-self: stretch
  }

  .md\:items-start {
    align-items: flex-start
  }

  .md\:items-end {
    align-items: flex-end
  }

  .md\:items-center {
    align-items: center
  }

  .md\:items-baseline {
    align-items: baseline
  }

  .md\:items-stretch {
    align-items: stretch
  }

  .md\:content-center {
    align-content: center
  }

  .md\:content-start {
    align-content: flex-start
  }

  .md\:content-end {
    align-content: flex-end
  }

  .md\:content-between {
    align-content: space-between
  }

  .md\:content-around {
    align-content: space-around
  }

  .md\:content-evenly {
    align-content: space-evenly
  }

  .md\:self-auto {
    align-self: auto
  }

  .md\:self-start {
    align-self: flex-start
  }

  .md\:self-end {
    align-self: flex-end
  }

  .md\:self-center {
    align-self: center
  }

  .md\:self-stretch {
    align-self: stretch
  }

  .md\:justify-items-auto {
    justify-items: auto
  }

  .md\:justify-items-start {
    justify-items: start
  }

  .md\:justify-items-end {
    justify-items: end
  }

  .md\:justify-items-center {
    justify-items: center
  }

  .md\:justify-items-stretch {
    justify-items: stretch
  }

  .md\:justify-start {
    justify-content: flex-start
  }

  .md\:justify-end {
    justify-content: flex-end
  }

  .md\:justify-center {
    justify-content: center
  }

  .md\:justify-between {
    justify-content: space-between
  }

  .md\:justify-around {
    justify-content: space-around
  }

  .md\:justify-evenly {
    justify-content: space-evenly
  }

  .md\:justify-self-auto {
    justify-self: auto
  }

  .md\:justify-self-start {
    justify-self: start
  }

  .md\:justify-self-end {
    justify-self: end
  }

  .md\:justify-self-center {
    justify-self: center
  }

  .md\:justify-self-stretch {
    justify-self: stretch
  }

  .md\:flex-1 {
    flex: 1 1 0%
  }

  .md\:flex-auto {
    flex: 1 1 auto
  }

  .md\:flex-initial {
    flex: 0 1 auto
  }

  .md\:flex-none {
    flex: none
  }

  .md\:flex-grow-0 {
    flex-grow: 0
  }

  .md\:flex-grow {
    flex-grow: 1
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0
  }

  .md\:flex-shrink {
    flex-shrink: 1
  }

  .md\:order-1 {
    order: 1
  }

  .md\:order-2 {
    order: 2
  }

  .md\:order-3 {
    order: 3
  }

  .md\:order-4 {
    order: 4
  }

  .md\:order-5 {
    order: 5
  }

  .md\:order-6 {
    order: 6
  }

  .md\:order-7 {
    order: 7
  }

  .md\:order-8 {
    order: 8
  }

  .md\:order-9 {
    order: 9
  }

  .md\:order-10 {
    order: 10
  }

  .md\:order-11 {
    order: 11
  }

  .md\:order-12 {
    order: 12
  }

  .md\:order-first {
    order: -9999
  }

  .md\:order-last {
    order: 9999
  }

  .md\:order-none {
    order: 0
  }

  .md\:float-right {
    float: right
  }

  .md\:float-left {
    float: left
  }

  .md\:float-none {
    float: none
  }

  [dir='ltr'] .md\:ltr\:float-right,[dir='ltr'].md\:ltr\:float-right {
    float: right
  }

  [dir='ltr'] .md\:ltr\:float-left,[dir='ltr'].md\:ltr\:float-left {
    float: left
  }

  [dir='ltr'] .md\:ltr\:float-none,[dir='ltr'].md\:ltr\:float-none {
    float: none
  }

  [dir='rtl'] .md\:rtl\:float-right,[dir='rtl'].md\:rtl\:float-right {
    float: right
  }

  [dir='rtl'] .md\:rtl\:float-left,[dir='rtl'].md\:rtl\:float-left {
    float: left
  }

  [dir='rtl'] .md\:rtl\:float-none,[dir='rtl'].md\:rtl\:float-none {
    float: none
  }

  .md\:clear-left {
    clear: left
  }

  .md\:clear-right {
    clear: right
  }

  .md\:clear-both {
    clear: both
  }

  .md\:clear-none {
    clear: none
  }

  .md\:font-sans {
    font-family: Muli, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .md\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .md\:font-mono {
    font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace
  }

  .md\:font-100 {
    font-weight: 100
  }

  .md\:font-200 {
    font-weight: 200
  }

  .md\:font-300 {
    font-weight: 300
  }

  .md\:font-400 {
    font-weight: 400
  }

  .md\:font-500 {
    font-weight: 500
  }

  .md\:font-600 {
    font-weight: 600
  }

  .md\:font-700 {
    font-weight: 700
  }

  .md\:font-800 {
    font-weight: 800
  }

  .md\:font-900 {
    font-weight: 900
  }

  .md\:font-thin {
    font-weight: 100
  }

  .md\:font-extralight {
    font-weight: 200
  }

  .md\:font-light {
    font-weight: 300
  }

  .md\:font-normal {
    font-weight: 400
  }

  .md\:font-medium {
    font-weight: 500
  }

  .md\:font-semibold {
    font-weight: 600
  }

  .md\:font-bold {
    font-weight: 700
  }

  .md\:font-extrabold {
    font-weight: 800
  }

  .md\:font-black {
    font-weight: 900
  }

  .md\:hover\:font-100:hover {
    font-weight: 100
  }

  .md\:hover\:font-200:hover {
    font-weight: 200
  }

  .md\:hover\:font-300:hover {
    font-weight: 300
  }

  .md\:hover\:font-400:hover {
    font-weight: 400
  }

  .md\:hover\:font-500:hover {
    font-weight: 500
  }

  .md\:hover\:font-600:hover {
    font-weight: 600
  }

  .md\:hover\:font-700:hover {
    font-weight: 700
  }

  .md\:hover\:font-800:hover {
    font-weight: 800
  }

  .md\:hover\:font-900:hover {
    font-weight: 900
  }

  .md\:hover\:font-thin:hover {
    font-weight: 100
  }

  .md\:hover\:font-extralight:hover {
    font-weight: 200
  }

  .md\:hover\:font-light:hover {
    font-weight: 300
  }

  .md\:hover\:font-normal:hover {
    font-weight: 400
  }

  .md\:hover\:font-medium:hover {
    font-weight: 500
  }

  .md\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .md\:hover\:font-bold:hover {
    font-weight: 700
  }

  .md\:hover\:font-extrabold:hover {
    font-weight: 800
  }

  .md\:hover\:font-black:hover {
    font-weight: 900
  }

  .md\:focus\:font-100:focus {
    font-weight: 100
  }

  .md\:focus\:font-200:focus {
    font-weight: 200
  }

  .md\:focus\:font-300:focus {
    font-weight: 300
  }

  .md\:focus\:font-400:focus {
    font-weight: 400
  }

  .md\:focus\:font-500:focus {
    font-weight: 500
  }

  .md\:focus\:font-600:focus {
    font-weight: 600
  }

  .md\:focus\:font-700:focus {
    font-weight: 700
  }

  .md\:focus\:font-800:focus {
    font-weight: 800
  }

  .md\:focus\:font-900:focus {
    font-weight: 900
  }

  .md\:focus\:font-thin:focus {
    font-weight: 100
  }

  .md\:focus\:font-extralight:focus {
    font-weight: 200
  }

  .md\:focus\:font-light:focus {
    font-weight: 300
  }

  .md\:focus\:font-normal:focus {
    font-weight: 400
  }

  .md\:focus\:font-medium:focus {
    font-weight: 500
  }

  .md\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .md\:focus\:font-bold:focus {
    font-weight: 700
  }

  .md\:focus\:font-extrabold:focus {
    font-weight: 800
  }

  .md\:focus\:font-black:focus {
    font-weight: 900
  }

  .md\:h-0 {
    height: 0
  }

  .md\:h-1 {
    height: 0.1rem
  }

  .md\:h-2 {
    height: 0.2rem
  }

  .md\:h-3 {
    height: 0.3rem
  }

  .md\:h-4 {
    height: 0.4rem
  }

  .md\:h-5 {
    height: 0.5rem
  }

  .md\:h-6 {
    height: 0.6rem
  }

  .md\:h-7 {
    height: 0.7rem
  }

  .md\:h-8 {
    height: 0.8rem
  }

  .md\:h-9 {
    height: 0.9rem
  }

  .md\:h-10 {
    height: 1.0rem
  }

  .md\:h-12 {
    height: 1.2rem
  }

  .md\:h-14 {
    height: 1.4rem
  }

  .md\:h-16 {
    height: 1.6rem
  }

  .md\:h-20 {
    height: 2rem
  }

  .md\:h-24 {
    height: 2.4rem
  }

  .md\:h-28 {
    height: 2.8rem
  }

  .md\:h-32 {
    height: 3.2rem
  }

  .md\:h-36 {
    height: 3.6rem
  }

  .md\:h-40 {
    height: 4rem
  }

  .md\:h-44 {
    height: 4.4rem
  }

  .md\:h-48 {
    height: 4.8rem
  }

  .md\:h-52 {
    height: 5.2rem
  }

  .md\:h-56 {
    height: 5.6rem
  }

  .md\:h-60 {
    height: 6rem
  }

  .md\:h-64 {
    height: 6.4rem
  }

  .md\:h-68 {
    height: 6.8rem
  }

  .md\:h-72 {
    height: 7.2rem
  }

  .md\:h-76 {
    height: 7.6rem
  }

  .md\:h-80 {
    height: 8rem
  }

  .md\:h-84 {
    height: 8.4rem
  }

  .md\:h-88 {
    height: 8.8rem
  }

  .md\:h-92 {
    height: 9.2rem
  }

  .md\:h-96 {
    height: 9.6rem
  }

  .md\:h-128 {
    height: 12.8rem
  }

  .md\:h-136 {
    height: 13.6rem
  }

  .md\:h-160 {
    height: 16rem
  }

  .md\:h-192 {
    height: 19.2rem
  }

  .md\:h-200 {
    height: 20rem
  }

  .md\:h-208 {
    height: 20.8rem
  }

  .md\:h-216 {
    height: 21.6rem
  }

  .md\:h-224 {
    height: 22.4rem
  }

  .md\:h-256 {
    height: 25.6rem
  }

  .md\:h-288 {
    height: 28.8rem
  }

  .md\:h-320 {
    height: 32rem
  }

  .md\:h-360 {
    height: 36rem
  }

  .md\:h-384 {
    height: 38.4rem
  }

  .md\:h-400 {
    height: 40rem
  }

  .md\:h-512 {
    height: 51.2rem
  }

  .md\:h-640 {
    height: 64rem
  }

  .md\:h-auto {
    height: auto
  }

  .md\:h-xs {
    height: 32rem
  }

  .md\:h-sm {
    height: 48rem
  }

  .md\:h-md {
    height: 64rem
  }

  .md\:h-lg {
    height: 80rem
  }

  .md\:h-xl {
    height: 96rem
  }

  .md\:h-2xl {
    height: 112rem
  }

  .md\:h-3xl {
    height: 128rem
  }

  .md\:h-4xl {
    height: 144rem
  }

  .md\:h-5xl {
    height: 160rem
  }

  .md\:h-px {
    height: 1px
  }

  .md\:h-0\.5 {
    height: 0.05rem
  }

  .md\:h-1\.5 {
    height: 0.15rem
  }

  .md\:h-2\.5 {
    height: 0.25rem
  }

  .md\:h-3\.5 {
    height: 0.35rem
  }

  .md\:h-1\/2 {
    height: 50%
  }

  .md\:h-1\/3 {
    height: 33.333333%
  }

  .md\:h-2\/3 {
    height: 66.666667%
  }

  .md\:h-1\/4 {
    height: 25%
  }

  .md\:h-2\/4 {
    height: 50%
  }

  .md\:h-3\/4 {
    height: 75%
  }

  .md\:h-1\/5 {
    height: 20%
  }

  .md\:h-2\/5 {
    height: 40%
  }

  .md\:h-3\/5 {
    height: 60%
  }

  .md\:h-4\/5 {
    height: 80%
  }

  .md\:h-1\/6 {
    height: 16.666667%
  }

  .md\:h-2\/6 {
    height: 33.333333%
  }

  .md\:h-3\/6 {
    height: 50%
  }

  .md\:h-4\/6 {
    height: 66.666667%
  }

  .md\:h-5\/6 {
    height: 83.333333%
  }

  .md\:h-full {
    height: 100%
  }

  .md\:h-screen {
    height: 100vh
  }

  .md\:text-10 {
    font-size: 1rem
  }

  .md\:text-11 {
    font-size: 1.1rem
  }

  .md\:text-12 {
    font-size: 1.2rem
  }

  .md\:text-13 {
    font-size: 1.3rem
  }

  .md\:text-14 {
    font-size: 1.4rem
  }

  .md\:text-15 {
    font-size: 1.5rem
  }

  .md\:text-16 {
    font-size: 1.6rem
  }

  .md\:text-17 {
    font-size: 1.7rem
  }

  .md\:text-18 {
    font-size: 1.8rem
  }

  .md\:text-19 {
    font-size: 1.9rem
  }

  .md\:text-20 {
    font-size: 2rem
  }

  .md\:text-24 {
    font-size: 2.4rem
  }

  .md\:text-28 {
    font-size: 2.8rem
  }

  .md\:text-32 {
    font-size: 3.2rem
  }

  .md\:text-36 {
    font-size: 3.6rem
  }

  .md\:text-40 {
    font-size: 4rem
  }

  .md\:text-44 {
    font-size: 4.4rem
  }

  .md\:text-48 {
    font-size: 4.8rem
  }

  .md\:text-52 {
    font-size: 5.2rem
  }

  .md\:text-56 {
    font-size: 5.6rem
  }

  .md\:text-60 {
    font-size: 6rem
  }

  .md\:text-64 {
    font-size: 6.4rem
  }

  .md\:text-68 {
    font-size: 6.8rem
  }

  .md\:text-72 {
    font-size: 7.2rem
  }

  .md\:text-96 {
    font-size: 9.6rem
  }

  .md\:text-128 {
    font-size: 12.8rem
  }

  .md\:text-xs {
    font-size: 1.2rem;
    line-height: 1.6rem
  }

  .md\:text-sm {
    font-size: 1.4rem;
    line-height: 2rem
  }

  .md\:text-base {
    font-size: 1.6rem;
    line-height: 2.4rem
  }

  .md\:text-lg {
    font-size: 1.8rem;
    line-height: 2.8em
  }

  .md\:text-xl {
    font-size: 2rem;
    line-height: 2.8rem
  }

  .md\:text-2xl {
    font-size: 2.4rem;
    line-height: 3.2rem
  }

  .md\:text-3xl {
    font-size: 3rem;
    line-height: 3.6rem
  }

  .md\:text-4xl {
    font-size: 3.6rem;
    line-height: 4rem
  }

  .md\:text-5xl {
    font-size: 4.8rem;
    line-height: 1
  }

  .md\:text-6xl {
    font-size: 6rem;
    line-height: 1
  }

  .md\:text-7xl {
    font-size: 7.2rem;
    line-height: 1
  }

  .md\:text-8xl {
    font-size: 9.6rem;
    line-height: 1
  }

  .md\:text-9xl {
    font-size: 12.8rem;
    line-height: 1
  }

  .md\:leading-3 {
    line-height: 1.2rem
  }

  .md\:leading-4 {
    line-height: 1.6rem
  }

  .md\:leading-5 {
    line-height: 2rem
  }

  .md\:leading-6 {
    line-height: 2.4rem
  }

  .md\:leading-7 {
    line-height: 2.8rem
  }

  .md\:leading-8 {
    line-height: 3.2rem
  }

  .md\:leading-9 {
    line-height: 3.6rem
  }

  .md\:leading-10 {
    line-height: 4rem
  }

  .md\:leading-none {
    line-height: 1
  }

  .md\:leading-tight {
    line-height: 1.25
  }

  .md\:leading-snug {
    line-height: 1.375
  }

  .md\:leading-normal {
    line-height: 1.5
  }

  .md\:leading-relaxed {
    line-height: 1.625
  }

  .md\:leading-loose {
    line-height: 2
  }

  .md\:list-inside {
    list-style-position: inside
  }

  .md\:list-outside {
    list-style-position: outside
  }

  .md\:list-none {
    list-style-type: none
  }

  .md\:list-disc {
    list-style-type: disc
  }

  .md\:list-decimal {
    list-style-type: decimal
  }

  .md\:m-0 {
    margin: 0
  }

  .md\:m-1 {
    margin: 0.1rem
  }

  .md\:m-2 {
    margin: 0.2rem
  }

  .md\:m-3 {
    margin: 0.3rem
  }

  .md\:m-4 {
    margin: 0.4rem
  }

  .md\:m-5 {
    margin: 0.5rem
  }

  .md\:m-6 {
    margin: 0.6rem
  }

  .md\:m-7 {
    margin: 0.7rem
  }

  .md\:m-8 {
    margin: 0.8rem
  }

  .md\:m-9 {
    margin: 0.9rem
  }

  .md\:m-10 {
    margin: 1.0rem
  }

  .md\:m-12 {
    margin: 1.2rem
  }

  .md\:m-14 {
    margin: 1.4rem
  }

  .md\:m-16 {
    margin: 1.6rem
  }

  .md\:m-20 {
    margin: 2rem
  }

  .md\:m-24 {
    margin: 2.4rem
  }

  .md\:m-28 {
    margin: 2.8rem
  }

  .md\:m-32 {
    margin: 3.2rem
  }

  .md\:m-36 {
    margin: 3.6rem
  }

  .md\:m-40 {
    margin: 4rem
  }

  .md\:m-44 {
    margin: 4.4rem
  }

  .md\:m-48 {
    margin: 4.8rem
  }

  .md\:m-52 {
    margin: 5.2rem
  }

  .md\:m-56 {
    margin: 5.6rem
  }

  .md\:m-60 {
    margin: 6rem
  }

  .md\:m-64 {
    margin: 6.4rem
  }

  .md\:m-68 {
    margin: 6.8rem
  }

  .md\:m-72 {
    margin: 7.2rem
  }

  .md\:m-76 {
    margin: 7.6rem
  }

  .md\:m-80 {
    margin: 8rem
  }

  .md\:m-84 {
    margin: 8.4rem
  }

  .md\:m-88 {
    margin: 8.8rem
  }

  .md\:m-92 {
    margin: 9.2rem
  }

  .md\:m-96 {
    margin: 9.6rem
  }

  .md\:m-128 {
    margin: 12.8rem
  }

  .md\:m-136 {
    margin: 13.6rem
  }

  .md\:m-160 {
    margin: 16rem
  }

  .md\:m-192 {
    margin: 19.2rem
  }

  .md\:m-200 {
    margin: 20rem
  }

  .md\:m-208 {
    margin: 20.8rem
  }

  .md\:m-216 {
    margin: 21.6rem
  }

  .md\:m-224 {
    margin: 22.4rem
  }

  .md\:m-256 {
    margin: 25.6rem
  }

  .md\:m-288 {
    margin: 28.8rem
  }

  .md\:m-320 {
    margin: 32rem
  }

  .md\:m-360 {
    margin: 36rem
  }

  .md\:m-384 {
    margin: 38.4rem
  }

  .md\:m-400 {
    margin: 40rem
  }

  .md\:m-512 {
    margin: 51.2rem
  }

  .md\:m-640 {
    margin: 64rem
  }

  .md\:m-auto {
    margin: auto
  }

  .md\:m-xs {
    margin: 32rem
  }

  .md\:m-sm {
    margin: 48rem
  }

  .md\:m-md {
    margin: 64rem
  }

  .md\:m-lg {
    margin: 80rem
  }

  .md\:m-xl {
    margin: 96rem
  }

  .md\:m-2xl {
    margin: 112rem
  }

  .md\:m-3xl {
    margin: 128rem
  }

  .md\:m-4xl {
    margin: 144rem
  }

  .md\:m-5xl {
    margin: 160rem
  }

  .md\:m-px {
    margin: 1px
  }

  .md\:m-0\.5 {
    margin: 0.05rem
  }

  .md\:m-1\.5 {
    margin: 0.15rem
  }

  .md\:m-2\.5 {
    margin: 0.25rem
  }

  .md\:m-3\.5 {
    margin: 0.35rem
  }

  .md\:-m-1 {
    margin: -0.1rem
  }

  .md\:-m-2 {
    margin: -0.2rem
  }

  .md\:-m-3 {
    margin: -0.3rem
  }

  .md\:-m-4 {
    margin: -0.4rem
  }

  .md\:-m-5 {
    margin: -0.5rem
  }

  .md\:-m-6 {
    margin: -0.6rem
  }

  .md\:-m-7 {
    margin: -0.7rem
  }

  .md\:-m-8 {
    margin: -0.8rem
  }

  .md\:-m-9 {
    margin: -0.9rem
  }

  .md\:-m-10 {
    margin: -1rem
  }

  .md\:-m-12 {
    margin: -1.2rem
  }

  .md\:-m-14 {
    margin: -1.4rem
  }

  .md\:-m-16 {
    margin: -1.6rem
  }

  .md\:-m-20 {
    margin: -2rem
  }

  .md\:-m-24 {
    margin: -2.4rem
  }

  .md\:-m-28 {
    margin: -2.8rem
  }

  .md\:-m-32 {
    margin: -3.2rem
  }

  .md\:-m-36 {
    margin: -3.6rem
  }

  .md\:-m-40 {
    margin: -4rem
  }

  .md\:-m-44 {
    margin: -4.4rem
  }

  .md\:-m-48 {
    margin: -4.8rem
  }

  .md\:-m-52 {
    margin: -5.2rem
  }

  .md\:-m-56 {
    margin: -5.6rem
  }

  .md\:-m-60 {
    margin: -6rem
  }

  .md\:-m-64 {
    margin: -6.4rem
  }

  .md\:-m-68 {
    margin: -6.8rem
  }

  .md\:-m-72 {
    margin: -7.2rem
  }

  .md\:-m-76 {
    margin: -7.6rem
  }

  .md\:-m-80 {
    margin: -8rem
  }

  .md\:-m-84 {
    margin: -8.4rem
  }

  .md\:-m-88 {
    margin: -8.8rem
  }

  .md\:-m-92 {
    margin: -9.2rem
  }

  .md\:-m-96 {
    margin: -9.6rem
  }

  .md\:-m-128 {
    margin: -12.8rem
  }

  .md\:-m-136 {
    margin: -13.6rem
  }

  .md\:-m-160 {
    margin: -16rem
  }

  .md\:-m-192 {
    margin: -19.2rem
  }

  .md\:-m-200 {
    margin: -20rem
  }

  .md\:-m-208 {
    margin: -20.8rem
  }

  .md\:-m-216 {
    margin: -21.6rem
  }

  .md\:-m-224 {
    margin: -22.4rem
  }

  .md\:-m-256 {
    margin: -25.6rem
  }

  .md\:-m-288 {
    margin: -28.8rem
  }

  .md\:-m-320 {
    margin: -32rem
  }

  .md\:-m-360 {
    margin: -36rem
  }

  .md\:-m-384 {
    margin: -38.4rem
  }

  .md\:-m-400 {
    margin: -40rem
  }

  .md\:-m-512 {
    margin: -51.2rem
  }

  .md\:-m-640 {
    margin: -64rem
  }

  .md\:-m-xs {
    margin: -32rem
  }

  .md\:-m-sm {
    margin: -48rem
  }

  .md\:-m-md {
    margin: -64rem
  }

  .md\:-m-lg {
    margin: -80rem
  }

  .md\:-m-xl {
    margin: -96rem
  }

  .md\:-m-2xl {
    margin: -112rem
  }

  .md\:-m-3xl {
    margin: -128rem
  }

  .md\:-m-4xl {
    margin: -144rem
  }

  .md\:-m-5xl {
    margin: -160rem
  }

  .md\:-m-px {
    margin: -1px
  }

  .md\:-m-0\.5 {
    margin: -0.05rem
  }

  .md\:-m-1\.5 {
    margin: -0.15rem
  }

  .md\:-m-2\.5 {
    margin: -0.25rem
  }

  .md\:-m-3\.5 {
    margin: -0.35rem
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .md\:my-1 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem
  }

  .md\:mx-1 {
    margin-left: 0.1rem;
    margin-right: 0.1rem
  }

  .md\:my-2 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem
  }

  .md\:mx-2 {
    margin-left: 0.2rem;
    margin-right: 0.2rem
  }

  .md\:my-3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem
  }

  .md\:mx-3 {
    margin-left: 0.3rem;
    margin-right: 0.3rem
  }

  .md\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  .md\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  .md\:my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .md\:mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .md\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  .md\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  .md\:my-7 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem
  }

  .md\:mx-7 {
    margin-left: 0.7rem;
    margin-right: 0.7rem
  }

  .md\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  .md\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  .md\:my-9 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem
  }

  .md\:mx-9 {
    margin-left: 0.9rem;
    margin-right: 0.9rem
  }

  .md\:my-10 {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem
  }

  .md\:mx-10 {
    margin-left: 1.0rem;
    margin-right: 1.0rem
  }

  .md\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  .md\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  .md\:my-14 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem
  }

  .md\:mx-14 {
    margin-left: 1.4rem;
    margin-right: 1.4rem
  }

  .md\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  .md\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  .md\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .md\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .md\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  .md\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .md\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  .md\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  .md\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  .md\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  .md\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  .md\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  .md\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .md\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .md\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  .md\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  .md\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  .md\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  .md\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  .md\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  .md\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  .md\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  .md\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .md\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .md\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  .md\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  .md\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  .md\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  .md\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  .md\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  .md\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  .md\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  .md\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .md\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .md\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  .md\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  .md\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  .md\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  .md\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  .md\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  .md\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  .md\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  .md\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  .md\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  .md\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  .md\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  .md\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .md\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .md\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  .md\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  .md\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  .md\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  .md\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  .md\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  .md\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  .md\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  .md\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  .md\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  .md\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  .md\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  .md\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  .md\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  .md\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  .md\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  .md\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  .md\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  .md\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  .md\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  .md\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  .md\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  .md\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  .md\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  .md\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  .md\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .md\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  .md\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  .md\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  .md\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  .md\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  .md\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  .md\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  .md\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  .md\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  .md\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  .md\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  .md\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  .md\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  .md\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  .md\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  .md\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  .md\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  .md\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  .md\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .md\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .md\:my-0\.5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem
  }

  .md\:mx-0\.5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem
  }

  .md\:my-1\.5 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem
  }

  .md\:mx-1\.5 {
    margin-left: 0.15rem;
    margin-right: 0.15rem
  }

  .md\:my-2\.5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .md\:mx-2\.5 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .md\:my-3\.5 {
    margin-top: 0.35rem;
    margin-bottom: 0.35rem
  }

  .md\:mx-3\.5 {
    margin-left: 0.35rem;
    margin-right: 0.35rem
  }

  .md\:-my-1 {
    margin-top: -0.1rem;
    margin-bottom: -0.1rem
  }

  .md\:-mx-1 {
    margin-left: -0.1rem;
    margin-right: -0.1rem
  }

  .md\:-my-2 {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem
  }

  .md\:-mx-2 {
    margin-left: -0.2rem;
    margin-right: -0.2rem
  }

  .md\:-my-3 {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem
  }

  .md\:-mx-3 {
    margin-left: -0.3rem;
    margin-right: -0.3rem
  }

  .md\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  .md\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  .md\:-my-5 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .md\:-mx-5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .md\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  .md\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  .md\:-my-7 {
    margin-top: -0.7rem;
    margin-bottom: -0.7rem
  }

  .md\:-mx-7 {
    margin-left: -0.7rem;
    margin-right: -0.7rem
  }

  .md\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  .md\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  .md\:-my-9 {
    margin-top: -0.9rem;
    margin-bottom: -0.9rem
  }

  .md\:-mx-9 {
    margin-left: -0.9rem;
    margin-right: -0.9rem
  }

  .md\:-my-10 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .md\:-mx-10 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .md\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  .md\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  .md\:-my-14 {
    margin-top: -1.4rem;
    margin-bottom: -1.4rem
  }

  .md\:-mx-14 {
    margin-left: -1.4rem;
    margin-right: -1.4rem
  }

  .md\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  .md\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  .md\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .md\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .md\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  .md\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  .md\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  .md\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  .md\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  .md\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  .md\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  .md\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  .md\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .md\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .md\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  .md\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  .md\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  .md\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  .md\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  .md\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  .md\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  .md\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  .md\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .md\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .md\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  .md\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  .md\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  .md\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  .md\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  .md\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  .md\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  .md\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  .md\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .md\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .md\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  .md\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  .md\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  .md\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  .md\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  .md\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  .md\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  .md\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  .md\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  .md\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  .md\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  .md\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  .md\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .md\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .md\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  .md\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  .md\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  .md\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  .md\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  .md\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  .md\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  .md\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  .md\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  .md\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  .md\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  .md\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  .md\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  .md\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  .md\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  .md\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  .md\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  .md\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  .md\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  .md\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  .md\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  .md\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  .md\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  .md\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  .md\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  .md\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  .md\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  .md\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  .md\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  .md\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  .md\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  .md\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  .md\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  .md\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  .md\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  .md\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  .md\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  .md\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  .md\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  .md\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  .md\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  .md\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  .md\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  .md\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  .md\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .md\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .md\:-my-0\.5 {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem
  }

  .md\:-mx-0\.5 {
    margin-left: -0.05rem;
    margin-right: -0.05rem
  }

  .md\:-my-1\.5 {
    margin-top: -0.15rem;
    margin-bottom: -0.15rem
  }

  .md\:-mx-1\.5 {
    margin-left: -0.15rem;
    margin-right: -0.15rem
  }

  .md\:-my-2\.5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .md\:-mx-2\.5 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .md\:-my-3\.5 {
    margin-top: -0.35rem;
    margin-bottom: -0.35rem
  }

  .md\:-mx-3\.5 {
    margin-left: -0.35rem;
    margin-right: -0.35rem
  }

  .md\:mt-0 {
    margin-top: 0
  }

  .md\:mr-0 {
    margin-right: 0
  }

  .md\:mb-0 {
    margin-bottom: 0
  }

  .md\:ml-0 {
    margin-left: 0
  }

  .md\:mt-1 {
    margin-top: 0.1rem
  }

  .md\:mr-1 {
    margin-right: 0.1rem
  }

  .md\:mb-1 {
    margin-bottom: 0.1rem
  }

  .md\:ml-1 {
    margin-left: 0.1rem
  }

  .md\:mt-2 {
    margin-top: 0.2rem
  }

  .md\:mr-2 {
    margin-right: 0.2rem
  }

  .md\:mb-2 {
    margin-bottom: 0.2rem
  }

  .md\:ml-2 {
    margin-left: 0.2rem
  }

  .md\:mt-3 {
    margin-top: 0.3rem
  }

  .md\:mr-3 {
    margin-right: 0.3rem
  }

  .md\:mb-3 {
    margin-bottom: 0.3rem
  }

  .md\:ml-3 {
    margin-left: 0.3rem
  }

  .md\:mt-4 {
    margin-top: 0.4rem
  }

  .md\:mr-4 {
    margin-right: 0.4rem
  }

  .md\:mb-4 {
    margin-bottom: 0.4rem
  }

  .md\:ml-4 {
    margin-left: 0.4rem
  }

  .md\:mt-5 {
    margin-top: 0.5rem
  }

  .md\:mr-5 {
    margin-right: 0.5rem
  }

  .md\:mb-5 {
    margin-bottom: 0.5rem
  }

  .md\:ml-5 {
    margin-left: 0.5rem
  }

  .md\:mt-6 {
    margin-top: 0.6rem
  }

  .md\:mr-6 {
    margin-right: 0.6rem
  }

  .md\:mb-6 {
    margin-bottom: 0.6rem
  }

  .md\:ml-6 {
    margin-left: 0.6rem
  }

  .md\:mt-7 {
    margin-top: 0.7rem
  }

  .md\:mr-7 {
    margin-right: 0.7rem
  }

  .md\:mb-7 {
    margin-bottom: 0.7rem
  }

  .md\:ml-7 {
    margin-left: 0.7rem
  }

  .md\:mt-8 {
    margin-top: 0.8rem
  }

  .md\:mr-8 {
    margin-right: 0.8rem
  }

  .md\:mb-8 {
    margin-bottom: 0.8rem
  }

  .md\:ml-8 {
    margin-left: 0.8rem
  }

  .md\:mt-9 {
    margin-top: 0.9rem
  }

  .md\:mr-9 {
    margin-right: 0.9rem
  }

  .md\:mb-9 {
    margin-bottom: 0.9rem
  }

  .md\:ml-9 {
    margin-left: 0.9rem
  }

  .md\:mt-10 {
    margin-top: 1.0rem
  }

  .md\:mr-10 {
    margin-right: 1.0rem
  }

  .md\:mb-10 {
    margin-bottom: 1.0rem
  }

  .md\:ml-10 {
    margin-left: 1.0rem
  }

  .md\:mt-12 {
    margin-top: 1.2rem
  }

  .md\:mr-12 {
    margin-right: 1.2rem
  }

  .md\:mb-12 {
    margin-bottom: 1.2rem
  }

  .md\:ml-12 {
    margin-left: 1.2rem
  }

  .md\:mt-14 {
    margin-top: 1.4rem
  }

  .md\:mr-14 {
    margin-right: 1.4rem
  }

  .md\:mb-14 {
    margin-bottom: 1.4rem
  }

  .md\:ml-14 {
    margin-left: 1.4rem
  }

  .md\:mt-16 {
    margin-top: 1.6rem
  }

  .md\:mr-16 {
    margin-right: 1.6rem
  }

  .md\:mb-16 {
    margin-bottom: 1.6rem
  }

  .md\:ml-16 {
    margin-left: 1.6rem
  }

  .md\:mt-20 {
    margin-top: 2rem
  }

  .md\:mr-20 {
    margin-right: 2rem
  }

  .md\:mb-20 {
    margin-bottom: 2rem
  }

  .md\:ml-20 {
    margin-left: 2rem
  }

  .md\:mt-24 {
    margin-top: 2.4rem
  }

  .md\:mr-24 {
    margin-right: 2.4rem
  }

  .md\:mb-24 {
    margin-bottom: 2.4rem
  }

  .md\:ml-24 {
    margin-left: 2.4rem
  }

  .md\:mt-28 {
    margin-top: 2.8rem
  }

  .md\:mr-28 {
    margin-right: 2.8rem
  }

  .md\:mb-28 {
    margin-bottom: 2.8rem
  }

  .md\:ml-28 {
    margin-left: 2.8rem
  }

  .md\:mt-32 {
    margin-top: 3.2rem
  }

  .md\:mr-32 {
    margin-right: 3.2rem
  }

  .md\:mb-32 {
    margin-bottom: 3.2rem
  }

  .md\:ml-32 {
    margin-left: 3.2rem
  }

  .md\:mt-36 {
    margin-top: 3.6rem
  }

  .md\:mr-36 {
    margin-right: 3.6rem
  }

  .md\:mb-36 {
    margin-bottom: 3.6rem
  }

  .md\:ml-36 {
    margin-left: 3.6rem
  }

  .md\:mt-40 {
    margin-top: 4rem
  }

  .md\:mr-40 {
    margin-right: 4rem
  }

  .md\:mb-40 {
    margin-bottom: 4rem
  }

  .md\:ml-40 {
    margin-left: 4rem
  }

  .md\:mt-44 {
    margin-top: 4.4rem
  }

  .md\:mr-44 {
    margin-right: 4.4rem
  }

  .md\:mb-44 {
    margin-bottom: 4.4rem
  }

  .md\:ml-44 {
    margin-left: 4.4rem
  }

  .md\:mt-48 {
    margin-top: 4.8rem
  }

  .md\:mr-48 {
    margin-right: 4.8rem
  }

  .md\:mb-48 {
    margin-bottom: 4.8rem
  }

  .md\:ml-48 {
    margin-left: 4.8rem
  }

  .md\:mt-52 {
    margin-top: 5.2rem
  }

  .md\:mr-52 {
    margin-right: 5.2rem
  }

  .md\:mb-52 {
    margin-bottom: 5.2rem
  }

  .md\:ml-52 {
    margin-left: 5.2rem
  }

  .md\:mt-56 {
    margin-top: 5.6rem
  }

  .md\:mr-56 {
    margin-right: 5.6rem
  }

  .md\:mb-56 {
    margin-bottom: 5.6rem
  }

  .md\:ml-56 {
    margin-left: 5.6rem
  }

  .md\:mt-60 {
    margin-top: 6rem
  }

  .md\:mr-60 {
    margin-right: 6rem
  }

  .md\:mb-60 {
    margin-bottom: 6rem
  }

  .md\:ml-60 {
    margin-left: 6rem
  }

  .md\:mt-64 {
    margin-top: 6.4rem
  }

  .md\:mr-64 {
    margin-right: 6.4rem
  }

  .md\:mb-64 {
    margin-bottom: 6.4rem
  }

  .md\:ml-64 {
    margin-left: 6.4rem
  }

  .md\:mt-68 {
    margin-top: 6.8rem
  }

  .md\:mr-68 {
    margin-right: 6.8rem
  }

  .md\:mb-68 {
    margin-bottom: 6.8rem
  }

  .md\:ml-68 {
    margin-left: 6.8rem
  }

  .md\:mt-72 {
    margin-top: 7.2rem
  }

  .md\:mr-72 {
    margin-right: 7.2rem
  }

  .md\:mb-72 {
    margin-bottom: 7.2rem
  }

  .md\:ml-72 {
    margin-left: 7.2rem
  }

  .md\:mt-76 {
    margin-top: 7.6rem
  }

  .md\:mr-76 {
    margin-right: 7.6rem
  }

  .md\:mb-76 {
    margin-bottom: 7.6rem
  }

  .md\:ml-76 {
    margin-left: 7.6rem
  }

  .md\:mt-80 {
    margin-top: 8rem
  }

  .md\:mr-80 {
    margin-right: 8rem
  }

  .md\:mb-80 {
    margin-bottom: 8rem
  }

  .md\:ml-80 {
    margin-left: 8rem
  }

  .md\:mt-84 {
    margin-top: 8.4rem
  }

  .md\:mr-84 {
    margin-right: 8.4rem
  }

  .md\:mb-84 {
    margin-bottom: 8.4rem
  }

  .md\:ml-84 {
    margin-left: 8.4rem
  }

  .md\:mt-88 {
    margin-top: 8.8rem
  }

  .md\:mr-88 {
    margin-right: 8.8rem
  }

  .md\:mb-88 {
    margin-bottom: 8.8rem
  }

  .md\:ml-88 {
    margin-left: 8.8rem
  }

  .md\:mt-92 {
    margin-top: 9.2rem
  }

  .md\:mr-92 {
    margin-right: 9.2rem
  }

  .md\:mb-92 {
    margin-bottom: 9.2rem
  }

  .md\:ml-92 {
    margin-left: 9.2rem
  }

  .md\:mt-96 {
    margin-top: 9.6rem
  }

  .md\:mr-96 {
    margin-right: 9.6rem
  }

  .md\:mb-96 {
    margin-bottom: 9.6rem
  }

  .md\:ml-96 {
    margin-left: 9.6rem
  }

  .md\:mt-128 {
    margin-top: 12.8rem
  }

  .md\:mr-128 {
    margin-right: 12.8rem
  }

  .md\:mb-128 {
    margin-bottom: 12.8rem
  }

  .md\:ml-128 {
    margin-left: 12.8rem
  }

  .md\:mt-136 {
    margin-top: 13.6rem
  }

  .md\:mr-136 {
    margin-right: 13.6rem
  }

  .md\:mb-136 {
    margin-bottom: 13.6rem
  }

  .md\:ml-136 {
    margin-left: 13.6rem
  }

  .md\:mt-160 {
    margin-top: 16rem
  }

  .md\:mr-160 {
    margin-right: 16rem
  }

  .md\:mb-160 {
    margin-bottom: 16rem
  }

  .md\:ml-160 {
    margin-left: 16rem
  }

  .md\:mt-192 {
    margin-top: 19.2rem
  }

  .md\:mr-192 {
    margin-right: 19.2rem
  }

  .md\:mb-192 {
    margin-bottom: 19.2rem
  }

  .md\:ml-192 {
    margin-left: 19.2rem
  }

  .md\:mt-200 {
    margin-top: 20rem
  }

  .md\:mr-200 {
    margin-right: 20rem
  }

  .md\:mb-200 {
    margin-bottom: 20rem
  }

  .md\:ml-200 {
    margin-left: 20rem
  }

  .md\:mt-208 {
    margin-top: 20.8rem
  }

  .md\:mr-208 {
    margin-right: 20.8rem
  }

  .md\:mb-208 {
    margin-bottom: 20.8rem
  }

  .md\:ml-208 {
    margin-left: 20.8rem
  }

  .md\:mt-216 {
    margin-top: 21.6rem
  }

  .md\:mr-216 {
    margin-right: 21.6rem
  }

  .md\:mb-216 {
    margin-bottom: 21.6rem
  }

  .md\:ml-216 {
    margin-left: 21.6rem
  }

  .md\:mt-224 {
    margin-top: 22.4rem
  }

  .md\:mr-224 {
    margin-right: 22.4rem
  }

  .md\:mb-224 {
    margin-bottom: 22.4rem
  }

  .md\:ml-224 {
    margin-left: 22.4rem
  }

  .md\:mt-256 {
    margin-top: 25.6rem
  }

  .md\:mr-256 {
    margin-right: 25.6rem
  }

  .md\:mb-256 {
    margin-bottom: 25.6rem
  }

  .md\:ml-256 {
    margin-left: 25.6rem
  }

  .md\:mt-288 {
    margin-top: 28.8rem
  }

  .md\:mr-288 {
    margin-right: 28.8rem
  }

  .md\:mb-288 {
    margin-bottom: 28.8rem
  }

  .md\:ml-288 {
    margin-left: 28.8rem
  }

  .md\:mt-320 {
    margin-top: 32rem
  }

  .md\:mr-320 {
    margin-right: 32rem
  }

  .md\:mb-320 {
    margin-bottom: 32rem
  }

  .md\:ml-320 {
    margin-left: 32rem
  }

  .md\:mt-360 {
    margin-top: 36rem
  }

  .md\:mr-360 {
    margin-right: 36rem
  }

  .md\:mb-360 {
    margin-bottom: 36rem
  }

  .md\:ml-360 {
    margin-left: 36rem
  }

  .md\:mt-384 {
    margin-top: 38.4rem
  }

  .md\:mr-384 {
    margin-right: 38.4rem
  }

  .md\:mb-384 {
    margin-bottom: 38.4rem
  }

  .md\:ml-384 {
    margin-left: 38.4rem
  }

  .md\:mt-400 {
    margin-top: 40rem
  }

  .md\:mr-400 {
    margin-right: 40rem
  }

  .md\:mb-400 {
    margin-bottom: 40rem
  }

  .md\:ml-400 {
    margin-left: 40rem
  }

  .md\:mt-512 {
    margin-top: 51.2rem
  }

  .md\:mr-512 {
    margin-right: 51.2rem
  }

  .md\:mb-512 {
    margin-bottom: 51.2rem
  }

  .md\:ml-512 {
    margin-left: 51.2rem
  }

  .md\:mt-640 {
    margin-top: 64rem
  }

  .md\:mr-640 {
    margin-right: 64rem
  }

  .md\:mb-640 {
    margin-bottom: 64rem
  }

  .md\:ml-640 {
    margin-left: 64rem
  }

  .md\:mt-auto {
    margin-top: auto
  }

  .md\:mr-auto {
    margin-right: auto
  }

  .md\:mb-auto {
    margin-bottom: auto
  }

  .md\:ml-auto {
    margin-left: auto
  }

  .md\:mt-xs {
    margin-top: 32rem
  }

  .md\:mr-xs {
    margin-right: 32rem
  }

  .md\:mb-xs {
    margin-bottom: 32rem
  }

  .md\:ml-xs {
    margin-left: 32rem
  }

  .md\:mt-sm {
    margin-top: 48rem
  }

  .md\:mr-sm {
    margin-right: 48rem
  }

  .md\:mb-sm {
    margin-bottom: 48rem
  }

  .md\:ml-sm {
    margin-left: 48rem
  }

  .md\:mt-md {
    margin-top: 64rem
  }

  .md\:mr-md {
    margin-right: 64rem
  }

  .md\:mb-md {
    margin-bottom: 64rem
  }

  .md\:ml-md {
    margin-left: 64rem
  }

  .md\:mt-lg {
    margin-top: 80rem
  }

  .md\:mr-lg {
    margin-right: 80rem
  }

  .md\:mb-lg {
    margin-bottom: 80rem
  }

  .md\:ml-lg {
    margin-left: 80rem
  }

  .md\:mt-xl {
    margin-top: 96rem
  }

  .md\:mr-xl {
    margin-right: 96rem
  }

  .md\:mb-xl {
    margin-bottom: 96rem
  }

  .md\:ml-xl {
    margin-left: 96rem
  }

  .md\:mt-2xl {
    margin-top: 112rem
  }

  .md\:mr-2xl {
    margin-right: 112rem
  }

  .md\:mb-2xl {
    margin-bottom: 112rem
  }

  .md\:ml-2xl {
    margin-left: 112rem
  }

  .md\:mt-3xl {
    margin-top: 128rem
  }

  .md\:mr-3xl {
    margin-right: 128rem
  }

  .md\:mb-3xl {
    margin-bottom: 128rem
  }

  .md\:ml-3xl {
    margin-left: 128rem
  }

  .md\:mt-4xl {
    margin-top: 144rem
  }

  .md\:mr-4xl {
    margin-right: 144rem
  }

  .md\:mb-4xl {
    margin-bottom: 144rem
  }

  .md\:ml-4xl {
    margin-left: 144rem
  }

  .md\:mt-5xl {
    margin-top: 160rem
  }

  .md\:mr-5xl {
    margin-right: 160rem
  }

  .md\:mb-5xl {
    margin-bottom: 160rem
  }

  .md\:ml-5xl {
    margin-left: 160rem
  }

  .md\:mt-px {
    margin-top: 1px
  }

  .md\:mr-px {
    margin-right: 1px
  }

  .md\:mb-px {
    margin-bottom: 1px
  }

  .md\:ml-px {
    margin-left: 1px
  }

  .md\:mt-0\.5 {
    margin-top: 0.05rem
  }

  .md\:mr-0\.5 {
    margin-right: 0.05rem
  }

  .md\:mb-0\.5 {
    margin-bottom: 0.05rem
  }

  .md\:ml-0\.5 {
    margin-left: 0.05rem
  }

  .md\:mt-1\.5 {
    margin-top: 0.15rem
  }

  .md\:mr-1\.5 {
    margin-right: 0.15rem
  }

  .md\:mb-1\.5 {
    margin-bottom: 0.15rem
  }

  .md\:ml-1\.5 {
    margin-left: 0.15rem
  }

  .md\:mt-2\.5 {
    margin-top: 0.25rem
  }

  .md\:mr-2\.5 {
    margin-right: 0.25rem
  }

  .md\:mb-2\.5 {
    margin-bottom: 0.25rem
  }

  .md\:ml-2\.5 {
    margin-left: 0.25rem
  }

  .md\:mt-3\.5 {
    margin-top: 0.35rem
  }

  .md\:mr-3\.5 {
    margin-right: 0.35rem
  }

  .md\:mb-3\.5 {
    margin-bottom: 0.35rem
  }

  .md\:ml-3\.5 {
    margin-left: 0.35rem
  }

  .md\:-mt-1 {
    margin-top: -0.1rem
  }

  .md\:-mr-1 {
    margin-right: -0.1rem
  }

  .md\:-mb-1 {
    margin-bottom: -0.1rem
  }

  .md\:-ml-1 {
    margin-left: -0.1rem
  }

  .md\:-mt-2 {
    margin-top: -0.2rem
  }

  .md\:-mr-2 {
    margin-right: -0.2rem
  }

  .md\:-mb-2 {
    margin-bottom: -0.2rem
  }

  .md\:-ml-2 {
    margin-left: -0.2rem
  }

  .md\:-mt-3 {
    margin-top: -0.3rem
  }

  .md\:-mr-3 {
    margin-right: -0.3rem
  }

  .md\:-mb-3 {
    margin-bottom: -0.3rem
  }

  .md\:-ml-3 {
    margin-left: -0.3rem
  }

  .md\:-mt-4 {
    margin-top: -0.4rem
  }

  .md\:-mr-4 {
    margin-right: -0.4rem
  }

  .md\:-mb-4 {
    margin-bottom: -0.4rem
  }

  .md\:-ml-4 {
    margin-left: -0.4rem
  }

  .md\:-mt-5 {
    margin-top: -0.5rem
  }

  .md\:-mr-5 {
    margin-right: -0.5rem
  }

  .md\:-mb-5 {
    margin-bottom: -0.5rem
  }

  .md\:-ml-5 {
    margin-left: -0.5rem
  }

  .md\:-mt-6 {
    margin-top: -0.6rem
  }

  .md\:-mr-6 {
    margin-right: -0.6rem
  }

  .md\:-mb-6 {
    margin-bottom: -0.6rem
  }

  .md\:-ml-6 {
    margin-left: -0.6rem
  }

  .md\:-mt-7 {
    margin-top: -0.7rem
  }

  .md\:-mr-7 {
    margin-right: -0.7rem
  }

  .md\:-mb-7 {
    margin-bottom: -0.7rem
  }

  .md\:-ml-7 {
    margin-left: -0.7rem
  }

  .md\:-mt-8 {
    margin-top: -0.8rem
  }

  .md\:-mr-8 {
    margin-right: -0.8rem
  }

  .md\:-mb-8 {
    margin-bottom: -0.8rem
  }

  .md\:-ml-8 {
    margin-left: -0.8rem
  }

  .md\:-mt-9 {
    margin-top: -0.9rem
  }

  .md\:-mr-9 {
    margin-right: -0.9rem
  }

  .md\:-mb-9 {
    margin-bottom: -0.9rem
  }

  .md\:-ml-9 {
    margin-left: -0.9rem
  }

  .md\:-mt-10 {
    margin-top: -1rem
  }

  .md\:-mr-10 {
    margin-right: -1rem
  }

  .md\:-mb-10 {
    margin-bottom: -1rem
  }

  .md\:-ml-10 {
    margin-left: -1rem
  }

  .md\:-mt-12 {
    margin-top: -1.2rem
  }

  .md\:-mr-12 {
    margin-right: -1.2rem
  }

  .md\:-mb-12 {
    margin-bottom: -1.2rem
  }

  .md\:-ml-12 {
    margin-left: -1.2rem
  }

  .md\:-mt-14 {
    margin-top: -1.4rem
  }

  .md\:-mr-14 {
    margin-right: -1.4rem
  }

  .md\:-mb-14 {
    margin-bottom: -1.4rem
  }

  .md\:-ml-14 {
    margin-left: -1.4rem
  }

  .md\:-mt-16 {
    margin-top: -1.6rem
  }

  .md\:-mr-16 {
    margin-right: -1.6rem
  }

  .md\:-mb-16 {
    margin-bottom: -1.6rem
  }

  .md\:-ml-16 {
    margin-left: -1.6rem
  }

  .md\:-mt-20 {
    margin-top: -2rem
  }

  .md\:-mr-20 {
    margin-right: -2rem
  }

  .md\:-mb-20 {
    margin-bottom: -2rem
  }

  .md\:-ml-20 {
    margin-left: -2rem
  }

  .md\:-mt-24 {
    margin-top: -2.4rem
  }

  .md\:-mr-24 {
    margin-right: -2.4rem
  }

  .md\:-mb-24 {
    margin-bottom: -2.4rem
  }

  .md\:-ml-24 {
    margin-left: -2.4rem
  }

  .md\:-mt-28 {
    margin-top: -2.8rem
  }

  .md\:-mr-28 {
    margin-right: -2.8rem
  }

  .md\:-mb-28 {
    margin-bottom: -2.8rem
  }

  .md\:-ml-28 {
    margin-left: -2.8rem
  }

  .md\:-mt-32 {
    margin-top: -3.2rem
  }

  .md\:-mr-32 {
    margin-right: -3.2rem
  }

  .md\:-mb-32 {
    margin-bottom: -3.2rem
  }

  .md\:-ml-32 {
    margin-left: -3.2rem
  }

  .md\:-mt-36 {
    margin-top: -3.6rem
  }

  .md\:-mr-36 {
    margin-right: -3.6rem
  }

  .md\:-mb-36 {
    margin-bottom: -3.6rem
  }

  .md\:-ml-36 {
    margin-left: -3.6rem
  }

  .md\:-mt-40 {
    margin-top: -4rem
  }

  .md\:-mr-40 {
    margin-right: -4rem
  }

  .md\:-mb-40 {
    margin-bottom: -4rem
  }

  .md\:-ml-40 {
    margin-left: -4rem
  }

  .md\:-mt-44 {
    margin-top: -4.4rem
  }

  .md\:-mr-44 {
    margin-right: -4.4rem
  }

  .md\:-mb-44 {
    margin-bottom: -4.4rem
  }

  .md\:-ml-44 {
    margin-left: -4.4rem
  }

  .md\:-mt-48 {
    margin-top: -4.8rem
  }

  .md\:-mr-48 {
    margin-right: -4.8rem
  }

  .md\:-mb-48 {
    margin-bottom: -4.8rem
  }

  .md\:-ml-48 {
    margin-left: -4.8rem
  }

  .md\:-mt-52 {
    margin-top: -5.2rem
  }

  .md\:-mr-52 {
    margin-right: -5.2rem
  }

  .md\:-mb-52 {
    margin-bottom: -5.2rem
  }

  .md\:-ml-52 {
    margin-left: -5.2rem
  }

  .md\:-mt-56 {
    margin-top: -5.6rem
  }

  .md\:-mr-56 {
    margin-right: -5.6rem
  }

  .md\:-mb-56 {
    margin-bottom: -5.6rem
  }

  .md\:-ml-56 {
    margin-left: -5.6rem
  }

  .md\:-mt-60 {
    margin-top: -6rem
  }

  .md\:-mr-60 {
    margin-right: -6rem
  }

  .md\:-mb-60 {
    margin-bottom: -6rem
  }

  .md\:-ml-60 {
    margin-left: -6rem
  }

  .md\:-mt-64 {
    margin-top: -6.4rem
  }

  .md\:-mr-64 {
    margin-right: -6.4rem
  }

  .md\:-mb-64 {
    margin-bottom: -6.4rem
  }

  .md\:-ml-64 {
    margin-left: -6.4rem
  }

  .md\:-mt-68 {
    margin-top: -6.8rem
  }

  .md\:-mr-68 {
    margin-right: -6.8rem
  }

  .md\:-mb-68 {
    margin-bottom: -6.8rem
  }

  .md\:-ml-68 {
    margin-left: -6.8rem
  }

  .md\:-mt-72 {
    margin-top: -7.2rem
  }

  .md\:-mr-72 {
    margin-right: -7.2rem
  }

  .md\:-mb-72 {
    margin-bottom: -7.2rem
  }

  .md\:-ml-72 {
    margin-left: -7.2rem
  }

  .md\:-mt-76 {
    margin-top: -7.6rem
  }

  .md\:-mr-76 {
    margin-right: -7.6rem
  }

  .md\:-mb-76 {
    margin-bottom: -7.6rem
  }

  .md\:-ml-76 {
    margin-left: -7.6rem
  }

  .md\:-mt-80 {
    margin-top: -8rem
  }

  .md\:-mr-80 {
    margin-right: -8rem
  }

  .md\:-mb-80 {
    margin-bottom: -8rem
  }

  .md\:-ml-80 {
    margin-left: -8rem
  }

  .md\:-mt-84 {
    margin-top: -8.4rem
  }

  .md\:-mr-84 {
    margin-right: -8.4rem
  }

  .md\:-mb-84 {
    margin-bottom: -8.4rem
  }

  .md\:-ml-84 {
    margin-left: -8.4rem
  }

  .md\:-mt-88 {
    margin-top: -8.8rem
  }

  .md\:-mr-88 {
    margin-right: -8.8rem
  }

  .md\:-mb-88 {
    margin-bottom: -8.8rem
  }

  .md\:-ml-88 {
    margin-left: -8.8rem
  }

  .md\:-mt-92 {
    margin-top: -9.2rem
  }

  .md\:-mr-92 {
    margin-right: -9.2rem
  }

  .md\:-mb-92 {
    margin-bottom: -9.2rem
  }

  .md\:-ml-92 {
    margin-left: -9.2rem
  }

  .md\:-mt-96 {
    margin-top: -9.6rem
  }

  .md\:-mr-96 {
    margin-right: -9.6rem
  }

  .md\:-mb-96 {
    margin-bottom: -9.6rem
  }

  .md\:-ml-96 {
    margin-left: -9.6rem
  }

  .md\:-mt-128 {
    margin-top: -12.8rem
  }

  .md\:-mr-128 {
    margin-right: -12.8rem
  }

  .md\:-mb-128 {
    margin-bottom: -12.8rem
  }

  .md\:-ml-128 {
    margin-left: -12.8rem
  }

  .md\:-mt-136 {
    margin-top: -13.6rem
  }

  .md\:-mr-136 {
    margin-right: -13.6rem
  }

  .md\:-mb-136 {
    margin-bottom: -13.6rem
  }

  .md\:-ml-136 {
    margin-left: -13.6rem
  }

  .md\:-mt-160 {
    margin-top: -16rem
  }

  .md\:-mr-160 {
    margin-right: -16rem
  }

  .md\:-mb-160 {
    margin-bottom: -16rem
  }

  .md\:-ml-160 {
    margin-left: -16rem
  }

  .md\:-mt-192 {
    margin-top: -19.2rem
  }

  .md\:-mr-192 {
    margin-right: -19.2rem
  }

  .md\:-mb-192 {
    margin-bottom: -19.2rem
  }

  .md\:-ml-192 {
    margin-left: -19.2rem
  }

  .md\:-mt-200 {
    margin-top: -20rem
  }

  .md\:-mr-200 {
    margin-right: -20rem
  }

  .md\:-mb-200 {
    margin-bottom: -20rem
  }

  .md\:-ml-200 {
    margin-left: -20rem
  }

  .md\:-mt-208 {
    margin-top: -20.8rem
  }

  .md\:-mr-208 {
    margin-right: -20.8rem
  }

  .md\:-mb-208 {
    margin-bottom: -20.8rem
  }

  .md\:-ml-208 {
    margin-left: -20.8rem
  }

  .md\:-mt-216 {
    margin-top: -21.6rem
  }

  .md\:-mr-216 {
    margin-right: -21.6rem
  }

  .md\:-mb-216 {
    margin-bottom: -21.6rem
  }

  .md\:-ml-216 {
    margin-left: -21.6rem
  }

  .md\:-mt-224 {
    margin-top: -22.4rem
  }

  .md\:-mr-224 {
    margin-right: -22.4rem
  }

  .md\:-mb-224 {
    margin-bottom: -22.4rem
  }

  .md\:-ml-224 {
    margin-left: -22.4rem
  }

  .md\:-mt-256 {
    margin-top: -25.6rem
  }

  .md\:-mr-256 {
    margin-right: -25.6rem
  }

  .md\:-mb-256 {
    margin-bottom: -25.6rem
  }

  .md\:-ml-256 {
    margin-left: -25.6rem
  }

  .md\:-mt-288 {
    margin-top: -28.8rem
  }

  .md\:-mr-288 {
    margin-right: -28.8rem
  }

  .md\:-mb-288 {
    margin-bottom: -28.8rem
  }

  .md\:-ml-288 {
    margin-left: -28.8rem
  }

  .md\:-mt-320 {
    margin-top: -32rem
  }

  .md\:-mr-320 {
    margin-right: -32rem
  }

  .md\:-mb-320 {
    margin-bottom: -32rem
  }

  .md\:-ml-320 {
    margin-left: -32rem
  }

  .md\:-mt-360 {
    margin-top: -36rem
  }

  .md\:-mr-360 {
    margin-right: -36rem
  }

  .md\:-mb-360 {
    margin-bottom: -36rem
  }

  .md\:-ml-360 {
    margin-left: -36rem
  }

  .md\:-mt-384 {
    margin-top: -38.4rem
  }

  .md\:-mr-384 {
    margin-right: -38.4rem
  }

  .md\:-mb-384 {
    margin-bottom: -38.4rem
  }

  .md\:-ml-384 {
    margin-left: -38.4rem
  }

  .md\:-mt-400 {
    margin-top: -40rem
  }

  .md\:-mr-400 {
    margin-right: -40rem
  }

  .md\:-mb-400 {
    margin-bottom: -40rem
  }

  .md\:-ml-400 {
    margin-left: -40rem
  }

  .md\:-mt-512 {
    margin-top: -51.2rem
  }

  .md\:-mr-512 {
    margin-right: -51.2rem
  }

  .md\:-mb-512 {
    margin-bottom: -51.2rem
  }

  .md\:-ml-512 {
    margin-left: -51.2rem
  }

  .md\:-mt-640 {
    margin-top: -64rem
  }

  .md\:-mr-640 {
    margin-right: -64rem
  }

  .md\:-mb-640 {
    margin-bottom: -64rem
  }

  .md\:-ml-640 {
    margin-left: -64rem
  }

  .md\:-mt-xs {
    margin-top: -32rem
  }

  .md\:-mr-xs {
    margin-right: -32rem
  }

  .md\:-mb-xs {
    margin-bottom: -32rem
  }

  .md\:-ml-xs {
    margin-left: -32rem
  }

  .md\:-mt-sm {
    margin-top: -48rem
  }

  .md\:-mr-sm {
    margin-right: -48rem
  }

  .md\:-mb-sm {
    margin-bottom: -48rem
  }

  .md\:-ml-sm {
    margin-left: -48rem
  }

  .md\:-mt-md {
    margin-top: -64rem
  }

  .md\:-mr-md {
    margin-right: -64rem
  }

  .md\:-mb-md {
    margin-bottom: -64rem
  }

  .md\:-ml-md {
    margin-left: -64rem
  }

  .md\:-mt-lg {
    margin-top: -80rem
  }

  .md\:-mr-lg {
    margin-right: -80rem
  }

  .md\:-mb-lg {
    margin-bottom: -80rem
  }

  .md\:-ml-lg {
    margin-left: -80rem
  }

  .md\:-mt-xl {
    margin-top: -96rem
  }

  .md\:-mr-xl {
    margin-right: -96rem
  }

  .md\:-mb-xl {
    margin-bottom: -96rem
  }

  .md\:-ml-xl {
    margin-left: -96rem
  }

  .md\:-mt-2xl {
    margin-top: -112rem
  }

  .md\:-mr-2xl {
    margin-right: -112rem
  }

  .md\:-mb-2xl {
    margin-bottom: -112rem
  }

  .md\:-ml-2xl {
    margin-left: -112rem
  }

  .md\:-mt-3xl {
    margin-top: -128rem
  }

  .md\:-mr-3xl {
    margin-right: -128rem
  }

  .md\:-mb-3xl {
    margin-bottom: -128rem
  }

  .md\:-ml-3xl {
    margin-left: -128rem
  }

  .md\:-mt-4xl {
    margin-top: -144rem
  }

  .md\:-mr-4xl {
    margin-right: -144rem
  }

  .md\:-mb-4xl {
    margin-bottom: -144rem
  }

  .md\:-ml-4xl {
    margin-left: -144rem
  }

  .md\:-mt-5xl {
    margin-top: -160rem
  }

  .md\:-mr-5xl {
    margin-right: -160rem
  }

  .md\:-mb-5xl {
    margin-bottom: -160rem
  }

  .md\:-ml-5xl {
    margin-left: -160rem
  }

  .md\:-mt-px {
    margin-top: -1px
  }

  .md\:-mr-px {
    margin-right: -1px
  }

  .md\:-mb-px {
    margin-bottom: -1px
  }

  .md\:-ml-px {
    margin-left: -1px
  }

  .md\:-mt-0\.5 {
    margin-top: -0.05rem
  }

  .md\:-mr-0\.5 {
    margin-right: -0.05rem
  }

  .md\:-mb-0\.5 {
    margin-bottom: -0.05rem
  }

  .md\:-ml-0\.5 {
    margin-left: -0.05rem
  }

  .md\:-mt-1\.5 {
    margin-top: -0.15rem
  }

  .md\:-mr-1\.5 {
    margin-right: -0.15rem
  }

  .md\:-mb-1\.5 {
    margin-bottom: -0.15rem
  }

  .md\:-ml-1\.5 {
    margin-left: -0.15rem
  }

  .md\:-mt-2\.5 {
    margin-top: -0.25rem
  }

  .md\:-mr-2\.5 {
    margin-right: -0.25rem
  }

  .md\:-mb-2\.5 {
    margin-bottom: -0.25rem
  }

  .md\:-ml-2\.5 {
    margin-left: -0.25rem
  }

  .md\:-mt-3\.5 {
    margin-top: -0.35rem
  }

  .md\:-mr-3\.5 {
    margin-right: -0.35rem
  }

  .md\:-mb-3\.5 {
    margin-bottom: -0.35rem
  }

  .md\:-ml-3\.5 {
    margin-left: -0.35rem
  }

  [dir='ltr'] .md\:ltr\:m-0,[dir='ltr'].md\:ltr\:m-0 {
    margin: 0
  }

  [dir='ltr'] .md\:ltr\:m-1,[dir='ltr'].md\:ltr\:m-1 {
    margin: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:m-2,[dir='ltr'].md\:ltr\:m-2 {
    margin: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:m-3,[dir='ltr'].md\:ltr\:m-3 {
    margin: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:m-4,[dir='ltr'].md\:ltr\:m-4 {
    margin: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:m-5,[dir='ltr'].md\:ltr\:m-5 {
    margin: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:m-6,[dir='ltr'].md\:ltr\:m-6 {
    margin: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:m-7,[dir='ltr'].md\:ltr\:m-7 {
    margin: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:m-8,[dir='ltr'].md\:ltr\:m-8 {
    margin: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:m-9,[dir='ltr'].md\:ltr\:m-9 {
    margin: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:m-10,[dir='ltr'].md\:ltr\:m-10 {
    margin: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:m-12,[dir='ltr'].md\:ltr\:m-12 {
    margin: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:m-14,[dir='ltr'].md\:ltr\:m-14 {
    margin: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:m-16,[dir='ltr'].md\:ltr\:m-16 {
    margin: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:m-20,[dir='ltr'].md\:ltr\:m-20 {
    margin: 2rem
  }

  [dir='ltr'] .md\:ltr\:m-24,[dir='ltr'].md\:ltr\:m-24 {
    margin: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:m-28,[dir='ltr'].md\:ltr\:m-28 {
    margin: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:m-32,[dir='ltr'].md\:ltr\:m-32 {
    margin: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:m-36,[dir='ltr'].md\:ltr\:m-36 {
    margin: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:m-40,[dir='ltr'].md\:ltr\:m-40 {
    margin: 4rem
  }

  [dir='ltr'] .md\:ltr\:m-44,[dir='ltr'].md\:ltr\:m-44 {
    margin: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:m-48,[dir='ltr'].md\:ltr\:m-48 {
    margin: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:m-52,[dir='ltr'].md\:ltr\:m-52 {
    margin: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:m-56,[dir='ltr'].md\:ltr\:m-56 {
    margin: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:m-60,[dir='ltr'].md\:ltr\:m-60 {
    margin: 6rem
  }

  [dir='ltr'] .md\:ltr\:m-64,[dir='ltr'].md\:ltr\:m-64 {
    margin: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:m-68,[dir='ltr'].md\:ltr\:m-68 {
    margin: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:m-72,[dir='ltr'].md\:ltr\:m-72 {
    margin: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:m-76,[dir='ltr'].md\:ltr\:m-76 {
    margin: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:m-80,[dir='ltr'].md\:ltr\:m-80 {
    margin: 8rem
  }

  [dir='ltr'] .md\:ltr\:m-84,[dir='ltr'].md\:ltr\:m-84 {
    margin: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:m-88,[dir='ltr'].md\:ltr\:m-88 {
    margin: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:m-92,[dir='ltr'].md\:ltr\:m-92 {
    margin: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:m-96,[dir='ltr'].md\:ltr\:m-96 {
    margin: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:m-128,[dir='ltr'].md\:ltr\:m-128 {
    margin: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:m-136,[dir='ltr'].md\:ltr\:m-136 {
    margin: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:m-160,[dir='ltr'].md\:ltr\:m-160 {
    margin: 16rem
  }

  [dir='ltr'] .md\:ltr\:m-192,[dir='ltr'].md\:ltr\:m-192 {
    margin: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:m-200,[dir='ltr'].md\:ltr\:m-200 {
    margin: 20rem
  }

  [dir='ltr'] .md\:ltr\:m-208,[dir='ltr'].md\:ltr\:m-208 {
    margin: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:m-216,[dir='ltr'].md\:ltr\:m-216 {
    margin: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:m-224,[dir='ltr'].md\:ltr\:m-224 {
    margin: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:m-256,[dir='ltr'].md\:ltr\:m-256 {
    margin: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:m-288,[dir='ltr'].md\:ltr\:m-288 {
    margin: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:m-320,[dir='ltr'].md\:ltr\:m-320 {
    margin: 32rem
  }

  [dir='ltr'] .md\:ltr\:m-360,[dir='ltr'].md\:ltr\:m-360 {
    margin: 36rem
  }

  [dir='ltr'] .md\:ltr\:m-384,[dir='ltr'].md\:ltr\:m-384 {
    margin: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:m-400,[dir='ltr'].md\:ltr\:m-400 {
    margin: 40rem
  }

  [dir='ltr'] .md\:ltr\:m-512,[dir='ltr'].md\:ltr\:m-512 {
    margin: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:m-640,[dir='ltr'].md\:ltr\:m-640 {
    margin: 64rem
  }

  [dir='ltr'] .md\:ltr\:m-auto,[dir='ltr'].md\:ltr\:m-auto {
    margin: auto
  }

  [dir='ltr'] .md\:ltr\:m-xs,[dir='ltr'].md\:ltr\:m-xs {
    margin: 32rem
  }

  [dir='ltr'] .md\:ltr\:m-sm,[dir='ltr'].md\:ltr\:m-sm {
    margin: 48rem
  }

  [dir='ltr'] .md\:ltr\:m-md,[dir='ltr'].md\:ltr\:m-md {
    margin: 64rem
  }

  [dir='ltr'] .md\:ltr\:m-lg,[dir='ltr'].md\:ltr\:m-lg {
    margin: 80rem
  }

  [dir='ltr'] .md\:ltr\:m-xl,[dir='ltr'].md\:ltr\:m-xl {
    margin: 96rem
  }

  [dir='ltr'] .md\:ltr\:m-2xl,[dir='ltr'].md\:ltr\:m-2xl {
    margin: 112rem
  }

  [dir='ltr'] .md\:ltr\:m-3xl,[dir='ltr'].md\:ltr\:m-3xl {
    margin: 128rem
  }

  [dir='ltr'] .md\:ltr\:m-4xl,[dir='ltr'].md\:ltr\:m-4xl {
    margin: 144rem
  }

  [dir='ltr'] .md\:ltr\:m-5xl,[dir='ltr'].md\:ltr\:m-5xl {
    margin: 160rem
  }

  [dir='ltr'] .md\:ltr\:m-px,[dir='ltr'].md\:ltr\:m-px {
    margin: 1px
  }

  [dir='ltr'] .md\:ltr\:m-0\.5,[dir='ltr'].md\:ltr\:m-0\.5 {
    margin: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:m-1\.5,[dir='ltr'].md\:ltr\:m-1\.5 {
    margin: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:m-2\.5,[dir='ltr'].md\:ltr\:m-2\.5 {
    margin: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:m-3\.5,[dir='ltr'].md\:ltr\:m-3\.5 {
    margin: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:-m-1,[dir='ltr'].md\:ltr\:-m-1 {
    margin: -0.1rem
  }

  [dir='ltr'] .md\:ltr\:-m-2,[dir='ltr'].md\:ltr\:-m-2 {
    margin: -0.2rem
  }

  [dir='ltr'] .md\:ltr\:-m-3,[dir='ltr'].md\:ltr\:-m-3 {
    margin: -0.3rem
  }

  [dir='ltr'] .md\:ltr\:-m-4,[dir='ltr'].md\:ltr\:-m-4 {
    margin: -0.4rem
  }

  [dir='ltr'] .md\:ltr\:-m-5,[dir='ltr'].md\:ltr\:-m-5 {
    margin: -0.5rem
  }

  [dir='ltr'] .md\:ltr\:-m-6,[dir='ltr'].md\:ltr\:-m-6 {
    margin: -0.6rem
  }

  [dir='ltr'] .md\:ltr\:-m-7,[dir='ltr'].md\:ltr\:-m-7 {
    margin: -0.7rem
  }

  [dir='ltr'] .md\:ltr\:-m-8,[dir='ltr'].md\:ltr\:-m-8 {
    margin: -0.8rem
  }

  [dir='ltr'] .md\:ltr\:-m-9,[dir='ltr'].md\:ltr\:-m-9 {
    margin: -0.9rem
  }

  [dir='ltr'] .md\:ltr\:-m-10,[dir='ltr'].md\:ltr\:-m-10 {
    margin: -1rem
  }

  [dir='ltr'] .md\:ltr\:-m-12,[dir='ltr'].md\:ltr\:-m-12 {
    margin: -1.2rem
  }

  [dir='ltr'] .md\:ltr\:-m-14,[dir='ltr'].md\:ltr\:-m-14 {
    margin: -1.4rem
  }

  [dir='ltr'] .md\:ltr\:-m-16,[dir='ltr'].md\:ltr\:-m-16 {
    margin: -1.6rem
  }

  [dir='ltr'] .md\:ltr\:-m-20,[dir='ltr'].md\:ltr\:-m-20 {
    margin: -2rem
  }

  [dir='ltr'] .md\:ltr\:-m-24,[dir='ltr'].md\:ltr\:-m-24 {
    margin: -2.4rem
  }

  [dir='ltr'] .md\:ltr\:-m-28,[dir='ltr'].md\:ltr\:-m-28 {
    margin: -2.8rem
  }

  [dir='ltr'] .md\:ltr\:-m-32,[dir='ltr'].md\:ltr\:-m-32 {
    margin: -3.2rem
  }

  [dir='ltr'] .md\:ltr\:-m-36,[dir='ltr'].md\:ltr\:-m-36 {
    margin: -3.6rem
  }

  [dir='ltr'] .md\:ltr\:-m-40,[dir='ltr'].md\:ltr\:-m-40 {
    margin: -4rem
  }

  [dir='ltr'] .md\:ltr\:-m-44,[dir='ltr'].md\:ltr\:-m-44 {
    margin: -4.4rem
  }

  [dir='ltr'] .md\:ltr\:-m-48,[dir='ltr'].md\:ltr\:-m-48 {
    margin: -4.8rem
  }

  [dir='ltr'] .md\:ltr\:-m-52,[dir='ltr'].md\:ltr\:-m-52 {
    margin: -5.2rem
  }

  [dir='ltr'] .md\:ltr\:-m-56,[dir='ltr'].md\:ltr\:-m-56 {
    margin: -5.6rem
  }

  [dir='ltr'] .md\:ltr\:-m-60,[dir='ltr'].md\:ltr\:-m-60 {
    margin: -6rem
  }

  [dir='ltr'] .md\:ltr\:-m-64,[dir='ltr'].md\:ltr\:-m-64 {
    margin: -6.4rem
  }

  [dir='ltr'] .md\:ltr\:-m-68,[dir='ltr'].md\:ltr\:-m-68 {
    margin: -6.8rem
  }

  [dir='ltr'] .md\:ltr\:-m-72,[dir='ltr'].md\:ltr\:-m-72 {
    margin: -7.2rem
  }

  [dir='ltr'] .md\:ltr\:-m-76,[dir='ltr'].md\:ltr\:-m-76 {
    margin: -7.6rem
  }

  [dir='ltr'] .md\:ltr\:-m-80,[dir='ltr'].md\:ltr\:-m-80 {
    margin: -8rem
  }

  [dir='ltr'] .md\:ltr\:-m-84,[dir='ltr'].md\:ltr\:-m-84 {
    margin: -8.4rem
  }

  [dir='ltr'] .md\:ltr\:-m-88,[dir='ltr'].md\:ltr\:-m-88 {
    margin: -8.8rem
  }

  [dir='ltr'] .md\:ltr\:-m-92,[dir='ltr'].md\:ltr\:-m-92 {
    margin: -9.2rem
  }

  [dir='ltr'] .md\:ltr\:-m-96,[dir='ltr'].md\:ltr\:-m-96 {
    margin: -9.6rem
  }

  [dir='ltr'] .md\:ltr\:-m-128,[dir='ltr'].md\:ltr\:-m-128 {
    margin: -12.8rem
  }

  [dir='ltr'] .md\:ltr\:-m-136,[dir='ltr'].md\:ltr\:-m-136 {
    margin: -13.6rem
  }

  [dir='ltr'] .md\:ltr\:-m-160,[dir='ltr'].md\:ltr\:-m-160 {
    margin: -16rem
  }

  [dir='ltr'] .md\:ltr\:-m-192,[dir='ltr'].md\:ltr\:-m-192 {
    margin: -19.2rem
  }

  [dir='ltr'] .md\:ltr\:-m-200,[dir='ltr'].md\:ltr\:-m-200 {
    margin: -20rem
  }

  [dir='ltr'] .md\:ltr\:-m-208,[dir='ltr'].md\:ltr\:-m-208 {
    margin: -20.8rem
  }

  [dir='ltr'] .md\:ltr\:-m-216,[dir='ltr'].md\:ltr\:-m-216 {
    margin: -21.6rem
  }

  [dir='ltr'] .md\:ltr\:-m-224,[dir='ltr'].md\:ltr\:-m-224 {
    margin: -22.4rem
  }

  [dir='ltr'] .md\:ltr\:-m-256,[dir='ltr'].md\:ltr\:-m-256 {
    margin: -25.6rem
  }

  [dir='ltr'] .md\:ltr\:-m-288,[dir='ltr'].md\:ltr\:-m-288 {
    margin: -28.8rem
  }

  [dir='ltr'] .md\:ltr\:-m-320,[dir='ltr'].md\:ltr\:-m-320 {
    margin: -32rem
  }

  [dir='ltr'] .md\:ltr\:-m-360,[dir='ltr'].md\:ltr\:-m-360 {
    margin: -36rem
  }

  [dir='ltr'] .md\:ltr\:-m-384,[dir='ltr'].md\:ltr\:-m-384 {
    margin: -38.4rem
  }

  [dir='ltr'] .md\:ltr\:-m-400,[dir='ltr'].md\:ltr\:-m-400 {
    margin: -40rem
  }

  [dir='ltr'] .md\:ltr\:-m-512,[dir='ltr'].md\:ltr\:-m-512 {
    margin: -51.2rem
  }

  [dir='ltr'] .md\:ltr\:-m-640,[dir='ltr'].md\:ltr\:-m-640 {
    margin: -64rem
  }

  [dir='ltr'] .md\:ltr\:-m-xs,[dir='ltr'].md\:ltr\:-m-xs {
    margin: -32rem
  }

  [dir='ltr'] .md\:ltr\:-m-sm,[dir='ltr'].md\:ltr\:-m-sm {
    margin: -48rem
  }

  [dir='ltr'] .md\:ltr\:-m-md,[dir='ltr'].md\:ltr\:-m-md {
    margin: -64rem
  }

  [dir='ltr'] .md\:ltr\:-m-lg,[dir='ltr'].md\:ltr\:-m-lg {
    margin: -80rem
  }

  [dir='ltr'] .md\:ltr\:-m-xl,[dir='ltr'].md\:ltr\:-m-xl {
    margin: -96rem
  }

  [dir='ltr'] .md\:ltr\:-m-2xl,[dir='ltr'].md\:ltr\:-m-2xl {
    margin: -112rem
  }

  [dir='ltr'] .md\:ltr\:-m-3xl,[dir='ltr'].md\:ltr\:-m-3xl {
    margin: -128rem
  }

  [dir='ltr'] .md\:ltr\:-m-4xl,[dir='ltr'].md\:ltr\:-m-4xl {
    margin: -144rem
  }

  [dir='ltr'] .md\:ltr\:-m-5xl,[dir='ltr'].md\:ltr\:-m-5xl {
    margin: -160rem
  }

  [dir='ltr'] .md\:ltr\:-m-px,[dir='ltr'].md\:ltr\:-m-px {
    margin: -1px
  }

  [dir='ltr'] .md\:ltr\:-m-0\.5,[dir='ltr'].md\:ltr\:-m-0\.5 {
    margin: -0.05rem
  }

  [dir='ltr'] .md\:ltr\:-m-1\.5,[dir='ltr'].md\:ltr\:-m-1\.5 {
    margin: -0.15rem
  }

  [dir='ltr'] .md\:ltr\:-m-2\.5,[dir='ltr'].md\:ltr\:-m-2\.5 {
    margin: -0.25rem
  }

  [dir='ltr'] .md\:ltr\:-m-3\.5,[dir='ltr'].md\:ltr\:-m-3\.5 {
    margin: -0.35rem
  }

  [dir='ltr'] .md\:ltr\:my-0,[dir='ltr'].md\:ltr\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  [dir='ltr'] .md\:ltr\:mx-0,[dir='ltr'].md\:ltr\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  [dir='ltr'] .md\:ltr\:my-1,[dir='ltr'].md\:ltr\:my-1 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:mx-1,[dir='ltr'].md\:ltr\:mx-1 {
    margin-left: 0.1rem;
    margin-right: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:my-2,[dir='ltr'].md\:ltr\:my-2 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:mx-2,[dir='ltr'].md\:ltr\:mx-2 {
    margin-left: 0.2rem;
    margin-right: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:my-3,[dir='ltr'].md\:ltr\:my-3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:mx-3,[dir='ltr'].md\:ltr\:mx-3 {
    margin-left: 0.3rem;
    margin-right: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:my-4,[dir='ltr'].md\:ltr\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:mx-4,[dir='ltr'].md\:ltr\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:my-5,[dir='ltr'].md\:ltr\:my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:mx-5,[dir='ltr'].md\:ltr\:mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:my-6,[dir='ltr'].md\:ltr\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:mx-6,[dir='ltr'].md\:ltr\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:my-7,[dir='ltr'].md\:ltr\:my-7 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:mx-7,[dir='ltr'].md\:ltr\:mx-7 {
    margin-left: 0.7rem;
    margin-right: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:my-8,[dir='ltr'].md\:ltr\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:mx-8,[dir='ltr'].md\:ltr\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:my-9,[dir='ltr'].md\:ltr\:my-9 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:mx-9,[dir='ltr'].md\:ltr\:mx-9 {
    margin-left: 0.9rem;
    margin-right: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:my-10,[dir='ltr'].md\:ltr\:my-10 {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:mx-10,[dir='ltr'].md\:ltr\:mx-10 {
    margin-left: 1.0rem;
    margin-right: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:my-12,[dir='ltr'].md\:ltr\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:mx-12,[dir='ltr'].md\:ltr\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:my-14,[dir='ltr'].md\:ltr\:my-14 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:mx-14,[dir='ltr'].md\:ltr\:mx-14 {
    margin-left: 1.4rem;
    margin-right: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:my-16,[dir='ltr'].md\:ltr\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:mx-16,[dir='ltr'].md\:ltr\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:my-20,[dir='ltr'].md\:ltr\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  [dir='ltr'] .md\:ltr\:mx-20,[dir='ltr'].md\:ltr\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  [dir='ltr'] .md\:ltr\:my-24,[dir='ltr'].md\:ltr\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:mx-24,[dir='ltr'].md\:ltr\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:my-28,[dir='ltr'].md\:ltr\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:mx-28,[dir='ltr'].md\:ltr\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:my-32,[dir='ltr'].md\:ltr\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:mx-32,[dir='ltr'].md\:ltr\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:my-36,[dir='ltr'].md\:ltr\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:mx-36,[dir='ltr'].md\:ltr\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:my-40,[dir='ltr'].md\:ltr\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  [dir='ltr'] .md\:ltr\:mx-40,[dir='ltr'].md\:ltr\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  [dir='ltr'] .md\:ltr\:my-44,[dir='ltr'].md\:ltr\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:mx-44,[dir='ltr'].md\:ltr\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:my-48,[dir='ltr'].md\:ltr\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:mx-48,[dir='ltr'].md\:ltr\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:my-52,[dir='ltr'].md\:ltr\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:mx-52,[dir='ltr'].md\:ltr\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:my-56,[dir='ltr'].md\:ltr\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:mx-56,[dir='ltr'].md\:ltr\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:my-60,[dir='ltr'].md\:ltr\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  [dir='ltr'] .md\:ltr\:mx-60,[dir='ltr'].md\:ltr\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  [dir='ltr'] .md\:ltr\:my-64,[dir='ltr'].md\:ltr\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:mx-64,[dir='ltr'].md\:ltr\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:my-68,[dir='ltr'].md\:ltr\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:mx-68,[dir='ltr'].md\:ltr\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:my-72,[dir='ltr'].md\:ltr\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:mx-72,[dir='ltr'].md\:ltr\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:my-76,[dir='ltr'].md\:ltr\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:mx-76,[dir='ltr'].md\:ltr\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:my-80,[dir='ltr'].md\:ltr\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  [dir='ltr'] .md\:ltr\:mx-80,[dir='ltr'].md\:ltr\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  [dir='ltr'] .md\:ltr\:my-84,[dir='ltr'].md\:ltr\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:mx-84,[dir='ltr'].md\:ltr\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:my-88,[dir='ltr'].md\:ltr\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:mx-88,[dir='ltr'].md\:ltr\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:my-92,[dir='ltr'].md\:ltr\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:mx-92,[dir='ltr'].md\:ltr\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:my-96,[dir='ltr'].md\:ltr\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:mx-96,[dir='ltr'].md\:ltr\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:my-128,[dir='ltr'].md\:ltr\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:mx-128,[dir='ltr'].md\:ltr\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:my-136,[dir='ltr'].md\:ltr\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:mx-136,[dir='ltr'].md\:ltr\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:my-160,[dir='ltr'].md\:ltr\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  [dir='ltr'] .md\:ltr\:mx-160,[dir='ltr'].md\:ltr\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  [dir='ltr'] .md\:ltr\:my-192,[dir='ltr'].md\:ltr\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:mx-192,[dir='ltr'].md\:ltr\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:my-200,[dir='ltr'].md\:ltr\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  [dir='ltr'] .md\:ltr\:mx-200,[dir='ltr'].md\:ltr\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  [dir='ltr'] .md\:ltr\:my-208,[dir='ltr'].md\:ltr\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:mx-208,[dir='ltr'].md\:ltr\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:my-216,[dir='ltr'].md\:ltr\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:mx-216,[dir='ltr'].md\:ltr\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:my-224,[dir='ltr'].md\:ltr\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:mx-224,[dir='ltr'].md\:ltr\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:my-256,[dir='ltr'].md\:ltr\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:mx-256,[dir='ltr'].md\:ltr\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:my-288,[dir='ltr'].md\:ltr\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:mx-288,[dir='ltr'].md\:ltr\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:my-320,[dir='ltr'].md\:ltr\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='ltr'] .md\:ltr\:mx-320,[dir='ltr'].md\:ltr\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='ltr'] .md\:ltr\:my-360,[dir='ltr'].md\:ltr\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  [dir='ltr'] .md\:ltr\:mx-360,[dir='ltr'].md\:ltr\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  [dir='ltr'] .md\:ltr\:my-384,[dir='ltr'].md\:ltr\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:mx-384,[dir='ltr'].md\:ltr\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:my-400,[dir='ltr'].md\:ltr\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  [dir='ltr'] .md\:ltr\:mx-400,[dir='ltr'].md\:ltr\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  [dir='ltr'] .md\:ltr\:my-512,[dir='ltr'].md\:ltr\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:mx-512,[dir='ltr'].md\:ltr\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:my-640,[dir='ltr'].md\:ltr\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='ltr'] .md\:ltr\:mx-640,[dir='ltr'].md\:ltr\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='ltr'] .md\:ltr\:my-auto,[dir='ltr'].md\:ltr\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  [dir='ltr'] .md\:ltr\:mx-auto,[dir='ltr'].md\:ltr\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  [dir='ltr'] .md\:ltr\:my-xs,[dir='ltr'].md\:ltr\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='ltr'] .md\:ltr\:mx-xs,[dir='ltr'].md\:ltr\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='ltr'] .md\:ltr\:my-sm,[dir='ltr'].md\:ltr\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  [dir='ltr'] .md\:ltr\:mx-sm,[dir='ltr'].md\:ltr\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  [dir='ltr'] .md\:ltr\:my-md,[dir='ltr'].md\:ltr\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='ltr'] .md\:ltr\:mx-md,[dir='ltr'].md\:ltr\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='ltr'] .md\:ltr\:my-lg,[dir='ltr'].md\:ltr\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  [dir='ltr'] .md\:ltr\:mx-lg,[dir='ltr'].md\:ltr\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  [dir='ltr'] .md\:ltr\:my-xl,[dir='ltr'].md\:ltr\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  [dir='ltr'] .md\:ltr\:mx-xl,[dir='ltr'].md\:ltr\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  [dir='ltr'] .md\:ltr\:my-2xl,[dir='ltr'].md\:ltr\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  [dir='ltr'] .md\:ltr\:mx-2xl,[dir='ltr'].md\:ltr\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  [dir='ltr'] .md\:ltr\:my-3xl,[dir='ltr'].md\:ltr\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  [dir='ltr'] .md\:ltr\:mx-3xl,[dir='ltr'].md\:ltr\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  [dir='ltr'] .md\:ltr\:my-4xl,[dir='ltr'].md\:ltr\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  [dir='ltr'] .md\:ltr\:mx-4xl,[dir='ltr'].md\:ltr\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  [dir='ltr'] .md\:ltr\:my-5xl,[dir='ltr'].md\:ltr\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  [dir='ltr'] .md\:ltr\:mx-5xl,[dir='ltr'].md\:ltr\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  [dir='ltr'] .md\:ltr\:my-px,[dir='ltr'].md\:ltr\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  [dir='ltr'] .md\:ltr\:mx-px,[dir='ltr'].md\:ltr\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  [dir='ltr'] .md\:ltr\:my-0\.5,[dir='ltr'].md\:ltr\:my-0\.5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:mx-0\.5,[dir='ltr'].md\:ltr\:mx-0\.5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:my-1\.5,[dir='ltr'].md\:ltr\:my-1\.5 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:mx-1\.5,[dir='ltr'].md\:ltr\:mx-1\.5 {
    margin-left: 0.15rem;
    margin-right: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:my-2\.5,[dir='ltr'].md\:ltr\:my-2\.5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:mx-2\.5,[dir='ltr'].md\:ltr\:mx-2\.5 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:my-3\.5,[dir='ltr'].md\:ltr\:my-3\.5 {
    margin-top: 0.35rem;
    margin-bottom: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:mx-3\.5,[dir='ltr'].md\:ltr\:mx-3\.5 {
    margin-left: 0.35rem;
    margin-right: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:-my-1,[dir='ltr'].md\:ltr\:-my-1 {
    margin-top: -0.1rem;
    margin-bottom: -0.1rem
  }

  [dir='ltr'] .md\:ltr\:-mx-1,[dir='ltr'].md\:ltr\:-mx-1 {
    margin-left: -0.1rem;
    margin-right: -0.1rem
  }

  [dir='ltr'] .md\:ltr\:-my-2,[dir='ltr'].md\:ltr\:-my-2 {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem
  }

  [dir='ltr'] .md\:ltr\:-mx-2,[dir='ltr'].md\:ltr\:-mx-2 {
    margin-left: -0.2rem;
    margin-right: -0.2rem
  }

  [dir='ltr'] .md\:ltr\:-my-3,[dir='ltr'].md\:ltr\:-my-3 {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem
  }

  [dir='ltr'] .md\:ltr\:-mx-3,[dir='ltr'].md\:ltr\:-mx-3 {
    margin-left: -0.3rem;
    margin-right: -0.3rem
  }

  [dir='ltr'] .md\:ltr\:-my-4,[dir='ltr'].md\:ltr\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  [dir='ltr'] .md\:ltr\:-mx-4,[dir='ltr'].md\:ltr\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  [dir='ltr'] .md\:ltr\:-my-5,[dir='ltr'].md\:ltr\:-my-5 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  [dir='ltr'] .md\:ltr\:-mx-5,[dir='ltr'].md\:ltr\:-mx-5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  [dir='ltr'] .md\:ltr\:-my-6,[dir='ltr'].md\:ltr\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  [dir='ltr'] .md\:ltr\:-mx-6,[dir='ltr'].md\:ltr\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  [dir='ltr'] .md\:ltr\:-my-7,[dir='ltr'].md\:ltr\:-my-7 {
    margin-top: -0.7rem;
    margin-bottom: -0.7rem
  }

  [dir='ltr'] .md\:ltr\:-mx-7,[dir='ltr'].md\:ltr\:-mx-7 {
    margin-left: -0.7rem;
    margin-right: -0.7rem
  }

  [dir='ltr'] .md\:ltr\:-my-8,[dir='ltr'].md\:ltr\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  [dir='ltr'] .md\:ltr\:-mx-8,[dir='ltr'].md\:ltr\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  [dir='ltr'] .md\:ltr\:-my-9,[dir='ltr'].md\:ltr\:-my-9 {
    margin-top: -0.9rem;
    margin-bottom: -0.9rem
  }

  [dir='ltr'] .md\:ltr\:-mx-9,[dir='ltr'].md\:ltr\:-mx-9 {
    margin-left: -0.9rem;
    margin-right: -0.9rem
  }

  [dir='ltr'] .md\:ltr\:-my-10,[dir='ltr'].md\:ltr\:-my-10 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  [dir='ltr'] .md\:ltr\:-mx-10,[dir='ltr'].md\:ltr\:-mx-10 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  [dir='ltr'] .md\:ltr\:-my-12,[dir='ltr'].md\:ltr\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  [dir='ltr'] .md\:ltr\:-mx-12,[dir='ltr'].md\:ltr\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  [dir='ltr'] .md\:ltr\:-my-14,[dir='ltr'].md\:ltr\:-my-14 {
    margin-top: -1.4rem;
    margin-bottom: -1.4rem
  }

  [dir='ltr'] .md\:ltr\:-mx-14,[dir='ltr'].md\:ltr\:-mx-14 {
    margin-left: -1.4rem;
    margin-right: -1.4rem
  }

  [dir='ltr'] .md\:ltr\:-my-16,[dir='ltr'].md\:ltr\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  [dir='ltr'] .md\:ltr\:-mx-16,[dir='ltr'].md\:ltr\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  [dir='ltr'] .md\:ltr\:-my-20,[dir='ltr'].md\:ltr\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  [dir='ltr'] .md\:ltr\:-mx-20,[dir='ltr'].md\:ltr\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  [dir='ltr'] .md\:ltr\:-my-24,[dir='ltr'].md\:ltr\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  [dir='ltr'] .md\:ltr\:-mx-24,[dir='ltr'].md\:ltr\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  [dir='ltr'] .md\:ltr\:-my-28,[dir='ltr'].md\:ltr\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  [dir='ltr'] .md\:ltr\:-mx-28,[dir='ltr'].md\:ltr\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  [dir='ltr'] .md\:ltr\:-my-32,[dir='ltr'].md\:ltr\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  [dir='ltr'] .md\:ltr\:-mx-32,[dir='ltr'].md\:ltr\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  [dir='ltr'] .md\:ltr\:-my-36,[dir='ltr'].md\:ltr\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  [dir='ltr'] .md\:ltr\:-mx-36,[dir='ltr'].md\:ltr\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  [dir='ltr'] .md\:ltr\:-my-40,[dir='ltr'].md\:ltr\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  [dir='ltr'] .md\:ltr\:-mx-40,[dir='ltr'].md\:ltr\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  [dir='ltr'] .md\:ltr\:-my-44,[dir='ltr'].md\:ltr\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  [dir='ltr'] .md\:ltr\:-mx-44,[dir='ltr'].md\:ltr\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  [dir='ltr'] .md\:ltr\:-my-48,[dir='ltr'].md\:ltr\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  [dir='ltr'] .md\:ltr\:-mx-48,[dir='ltr'].md\:ltr\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  [dir='ltr'] .md\:ltr\:-my-52,[dir='ltr'].md\:ltr\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  [dir='ltr'] .md\:ltr\:-mx-52,[dir='ltr'].md\:ltr\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  [dir='ltr'] .md\:ltr\:-my-56,[dir='ltr'].md\:ltr\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  [dir='ltr'] .md\:ltr\:-mx-56,[dir='ltr'].md\:ltr\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  [dir='ltr'] .md\:ltr\:-my-60,[dir='ltr'].md\:ltr\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  [dir='ltr'] .md\:ltr\:-mx-60,[dir='ltr'].md\:ltr\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  [dir='ltr'] .md\:ltr\:-my-64,[dir='ltr'].md\:ltr\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  [dir='ltr'] .md\:ltr\:-mx-64,[dir='ltr'].md\:ltr\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  [dir='ltr'] .md\:ltr\:-my-68,[dir='ltr'].md\:ltr\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  [dir='ltr'] .md\:ltr\:-mx-68,[dir='ltr'].md\:ltr\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  [dir='ltr'] .md\:ltr\:-my-72,[dir='ltr'].md\:ltr\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  [dir='ltr'] .md\:ltr\:-mx-72,[dir='ltr'].md\:ltr\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  [dir='ltr'] .md\:ltr\:-my-76,[dir='ltr'].md\:ltr\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  [dir='ltr'] .md\:ltr\:-mx-76,[dir='ltr'].md\:ltr\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  [dir='ltr'] .md\:ltr\:-my-80,[dir='ltr'].md\:ltr\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  [dir='ltr'] .md\:ltr\:-mx-80,[dir='ltr'].md\:ltr\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  [dir='ltr'] .md\:ltr\:-my-84,[dir='ltr'].md\:ltr\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  [dir='ltr'] .md\:ltr\:-mx-84,[dir='ltr'].md\:ltr\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  [dir='ltr'] .md\:ltr\:-my-88,[dir='ltr'].md\:ltr\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  [dir='ltr'] .md\:ltr\:-mx-88,[dir='ltr'].md\:ltr\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  [dir='ltr'] .md\:ltr\:-my-92,[dir='ltr'].md\:ltr\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  [dir='ltr'] .md\:ltr\:-mx-92,[dir='ltr'].md\:ltr\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  [dir='ltr'] .md\:ltr\:-my-96,[dir='ltr'].md\:ltr\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  [dir='ltr'] .md\:ltr\:-mx-96,[dir='ltr'].md\:ltr\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  [dir='ltr'] .md\:ltr\:-my-128,[dir='ltr'].md\:ltr\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  [dir='ltr'] .md\:ltr\:-mx-128,[dir='ltr'].md\:ltr\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  [dir='ltr'] .md\:ltr\:-my-136,[dir='ltr'].md\:ltr\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  [dir='ltr'] .md\:ltr\:-mx-136,[dir='ltr'].md\:ltr\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  [dir='ltr'] .md\:ltr\:-my-160,[dir='ltr'].md\:ltr\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  [dir='ltr'] .md\:ltr\:-mx-160,[dir='ltr'].md\:ltr\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  [dir='ltr'] .md\:ltr\:-my-192,[dir='ltr'].md\:ltr\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  [dir='ltr'] .md\:ltr\:-mx-192,[dir='ltr'].md\:ltr\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  [dir='ltr'] .md\:ltr\:-my-200,[dir='ltr'].md\:ltr\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  [dir='ltr'] .md\:ltr\:-mx-200,[dir='ltr'].md\:ltr\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  [dir='ltr'] .md\:ltr\:-my-208,[dir='ltr'].md\:ltr\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  [dir='ltr'] .md\:ltr\:-mx-208,[dir='ltr'].md\:ltr\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  [dir='ltr'] .md\:ltr\:-my-216,[dir='ltr'].md\:ltr\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  [dir='ltr'] .md\:ltr\:-mx-216,[dir='ltr'].md\:ltr\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  [dir='ltr'] .md\:ltr\:-my-224,[dir='ltr'].md\:ltr\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  [dir='ltr'] .md\:ltr\:-mx-224,[dir='ltr'].md\:ltr\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  [dir='ltr'] .md\:ltr\:-my-256,[dir='ltr'].md\:ltr\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  [dir='ltr'] .md\:ltr\:-mx-256,[dir='ltr'].md\:ltr\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  [dir='ltr'] .md\:ltr\:-my-288,[dir='ltr'].md\:ltr\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  [dir='ltr'] .md\:ltr\:-mx-288,[dir='ltr'].md\:ltr\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  [dir='ltr'] .md\:ltr\:-my-320,[dir='ltr'].md\:ltr\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='ltr'] .md\:ltr\:-mx-320,[dir='ltr'].md\:ltr\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='ltr'] .md\:ltr\:-my-360,[dir='ltr'].md\:ltr\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  [dir='ltr'] .md\:ltr\:-mx-360,[dir='ltr'].md\:ltr\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  [dir='ltr'] .md\:ltr\:-my-384,[dir='ltr'].md\:ltr\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  [dir='ltr'] .md\:ltr\:-mx-384,[dir='ltr'].md\:ltr\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  [dir='ltr'] .md\:ltr\:-my-400,[dir='ltr'].md\:ltr\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  [dir='ltr'] .md\:ltr\:-mx-400,[dir='ltr'].md\:ltr\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  [dir='ltr'] .md\:ltr\:-my-512,[dir='ltr'].md\:ltr\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  [dir='ltr'] .md\:ltr\:-mx-512,[dir='ltr'].md\:ltr\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  [dir='ltr'] .md\:ltr\:-my-640,[dir='ltr'].md\:ltr\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='ltr'] .md\:ltr\:-mx-640,[dir='ltr'].md\:ltr\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='ltr'] .md\:ltr\:-my-xs,[dir='ltr'].md\:ltr\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='ltr'] .md\:ltr\:-mx-xs,[dir='ltr'].md\:ltr\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='ltr'] .md\:ltr\:-my-sm,[dir='ltr'].md\:ltr\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  [dir='ltr'] .md\:ltr\:-mx-sm,[dir='ltr'].md\:ltr\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  [dir='ltr'] .md\:ltr\:-my-md,[dir='ltr'].md\:ltr\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='ltr'] .md\:ltr\:-mx-md,[dir='ltr'].md\:ltr\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='ltr'] .md\:ltr\:-my-lg,[dir='ltr'].md\:ltr\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  [dir='ltr'] .md\:ltr\:-mx-lg,[dir='ltr'].md\:ltr\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  [dir='ltr'] .md\:ltr\:-my-xl,[dir='ltr'].md\:ltr\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  [dir='ltr'] .md\:ltr\:-mx-xl,[dir='ltr'].md\:ltr\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  [dir='ltr'] .md\:ltr\:-my-2xl,[dir='ltr'].md\:ltr\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  [dir='ltr'] .md\:ltr\:-mx-2xl,[dir='ltr'].md\:ltr\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  [dir='ltr'] .md\:ltr\:-my-3xl,[dir='ltr'].md\:ltr\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  [dir='ltr'] .md\:ltr\:-mx-3xl,[dir='ltr'].md\:ltr\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  [dir='ltr'] .md\:ltr\:-my-4xl,[dir='ltr'].md\:ltr\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  [dir='ltr'] .md\:ltr\:-mx-4xl,[dir='ltr'].md\:ltr\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  [dir='ltr'] .md\:ltr\:-my-5xl,[dir='ltr'].md\:ltr\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  [dir='ltr'] .md\:ltr\:-mx-5xl,[dir='ltr'].md\:ltr\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  [dir='ltr'] .md\:ltr\:-my-px,[dir='ltr'].md\:ltr\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  [dir='ltr'] .md\:ltr\:-mx-px,[dir='ltr'].md\:ltr\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  [dir='ltr'] .md\:ltr\:-my-0\.5,[dir='ltr'].md\:ltr\:-my-0\.5 {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem
  }

  [dir='ltr'] .md\:ltr\:-mx-0\.5,[dir='ltr'].md\:ltr\:-mx-0\.5 {
    margin-left: -0.05rem;
    margin-right: -0.05rem
  }

  [dir='ltr'] .md\:ltr\:-my-1\.5,[dir='ltr'].md\:ltr\:-my-1\.5 {
    margin-top: -0.15rem;
    margin-bottom: -0.15rem
  }

  [dir='ltr'] .md\:ltr\:-mx-1\.5,[dir='ltr'].md\:ltr\:-mx-1\.5 {
    margin-left: -0.15rem;
    margin-right: -0.15rem
  }

  [dir='ltr'] .md\:ltr\:-my-2\.5,[dir='ltr'].md\:ltr\:-my-2\.5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  [dir='ltr'] .md\:ltr\:-mx-2\.5,[dir='ltr'].md\:ltr\:-mx-2\.5 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  [dir='ltr'] .md\:ltr\:-my-3\.5,[dir='ltr'].md\:ltr\:-my-3\.5 {
    margin-top: -0.35rem;
    margin-bottom: -0.35rem
  }

  [dir='ltr'] .md\:ltr\:-mx-3\.5,[dir='ltr'].md\:ltr\:-mx-3\.5 {
    margin-left: -0.35rem;
    margin-right: -0.35rem
  }

  [dir='ltr'] .md\:ltr\:mt-0,[dir='ltr'].md\:ltr\:mt-0 {
    margin-top: 0
  }

  [dir='ltr'] .md\:ltr\:mr-0,[dir='ltr'].md\:ltr\:mr-0 {
    margin-right: 0
  }

  [dir='ltr'] .md\:ltr\:mb-0,[dir='ltr'].md\:ltr\:mb-0 {
    margin-bottom: 0
  }

  [dir='ltr'] .md\:ltr\:ml-0,[dir='ltr'].md\:ltr\:ml-0 {
    margin-left: 0
  }

  [dir='ltr'] .md\:ltr\:mt-1,[dir='ltr'].md\:ltr\:mt-1 {
    margin-top: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:mr-1,[dir='ltr'].md\:ltr\:mr-1 {
    margin-right: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:mb-1,[dir='ltr'].md\:ltr\:mb-1 {
    margin-bottom: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:ml-1,[dir='ltr'].md\:ltr\:ml-1 {
    margin-left: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:mt-2,[dir='ltr'].md\:ltr\:mt-2 {
    margin-top: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:mr-2,[dir='ltr'].md\:ltr\:mr-2 {
    margin-right: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:mb-2,[dir='ltr'].md\:ltr\:mb-2 {
    margin-bottom: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:ml-2,[dir='ltr'].md\:ltr\:ml-2 {
    margin-left: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:mt-3,[dir='ltr'].md\:ltr\:mt-3 {
    margin-top: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:mr-3,[dir='ltr'].md\:ltr\:mr-3 {
    margin-right: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:mb-3,[dir='ltr'].md\:ltr\:mb-3 {
    margin-bottom: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:ml-3,[dir='ltr'].md\:ltr\:ml-3 {
    margin-left: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:mt-4,[dir='ltr'].md\:ltr\:mt-4 {
    margin-top: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:mr-4,[dir='ltr'].md\:ltr\:mr-4 {
    margin-right: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:mb-4,[dir='ltr'].md\:ltr\:mb-4 {
    margin-bottom: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:ml-4,[dir='ltr'].md\:ltr\:ml-4 {
    margin-left: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:mt-5,[dir='ltr'].md\:ltr\:mt-5 {
    margin-top: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:mr-5,[dir='ltr'].md\:ltr\:mr-5 {
    margin-right: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:mb-5,[dir='ltr'].md\:ltr\:mb-5 {
    margin-bottom: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:ml-5,[dir='ltr'].md\:ltr\:ml-5 {
    margin-left: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:mt-6,[dir='ltr'].md\:ltr\:mt-6 {
    margin-top: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:mr-6,[dir='ltr'].md\:ltr\:mr-6 {
    margin-right: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:mb-6,[dir='ltr'].md\:ltr\:mb-6 {
    margin-bottom: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:ml-6,[dir='ltr'].md\:ltr\:ml-6 {
    margin-left: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:mt-7,[dir='ltr'].md\:ltr\:mt-7 {
    margin-top: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:mr-7,[dir='ltr'].md\:ltr\:mr-7 {
    margin-right: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:mb-7,[dir='ltr'].md\:ltr\:mb-7 {
    margin-bottom: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:ml-7,[dir='ltr'].md\:ltr\:ml-7 {
    margin-left: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:mt-8,[dir='ltr'].md\:ltr\:mt-8 {
    margin-top: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:mr-8,[dir='ltr'].md\:ltr\:mr-8 {
    margin-right: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:mb-8,[dir='ltr'].md\:ltr\:mb-8 {
    margin-bottom: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:ml-8,[dir='ltr'].md\:ltr\:ml-8 {
    margin-left: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:mt-9,[dir='ltr'].md\:ltr\:mt-9 {
    margin-top: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:mr-9,[dir='ltr'].md\:ltr\:mr-9 {
    margin-right: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:mb-9,[dir='ltr'].md\:ltr\:mb-9 {
    margin-bottom: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:ml-9,[dir='ltr'].md\:ltr\:ml-9 {
    margin-left: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:mt-10,[dir='ltr'].md\:ltr\:mt-10 {
    margin-top: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:mr-10,[dir='ltr'].md\:ltr\:mr-10 {
    margin-right: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:mb-10,[dir='ltr'].md\:ltr\:mb-10 {
    margin-bottom: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:ml-10,[dir='ltr'].md\:ltr\:ml-10 {
    margin-left: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:mt-12,[dir='ltr'].md\:ltr\:mt-12 {
    margin-top: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:mr-12,[dir='ltr'].md\:ltr\:mr-12 {
    margin-right: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:mb-12,[dir='ltr'].md\:ltr\:mb-12 {
    margin-bottom: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:ml-12,[dir='ltr'].md\:ltr\:ml-12 {
    margin-left: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:mt-14,[dir='ltr'].md\:ltr\:mt-14 {
    margin-top: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:mr-14,[dir='ltr'].md\:ltr\:mr-14 {
    margin-right: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:mb-14,[dir='ltr'].md\:ltr\:mb-14 {
    margin-bottom: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:ml-14,[dir='ltr'].md\:ltr\:ml-14 {
    margin-left: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:mt-16,[dir='ltr'].md\:ltr\:mt-16 {
    margin-top: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:mr-16,[dir='ltr'].md\:ltr\:mr-16 {
    margin-right: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:mb-16,[dir='ltr'].md\:ltr\:mb-16 {
    margin-bottom: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:ml-16,[dir='ltr'].md\:ltr\:ml-16 {
    margin-left: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:mt-20,[dir='ltr'].md\:ltr\:mt-20 {
    margin-top: 2rem
  }

  [dir='ltr'] .md\:ltr\:mr-20,[dir='ltr'].md\:ltr\:mr-20 {
    margin-right: 2rem
  }

  [dir='ltr'] .md\:ltr\:mb-20,[dir='ltr'].md\:ltr\:mb-20 {
    margin-bottom: 2rem
  }

  [dir='ltr'] .md\:ltr\:ml-20,[dir='ltr'].md\:ltr\:ml-20 {
    margin-left: 2rem
  }

  [dir='ltr'] .md\:ltr\:mt-24,[dir='ltr'].md\:ltr\:mt-24 {
    margin-top: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:mr-24,[dir='ltr'].md\:ltr\:mr-24 {
    margin-right: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:mb-24,[dir='ltr'].md\:ltr\:mb-24 {
    margin-bottom: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:ml-24,[dir='ltr'].md\:ltr\:ml-24 {
    margin-left: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:mt-28,[dir='ltr'].md\:ltr\:mt-28 {
    margin-top: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:mr-28,[dir='ltr'].md\:ltr\:mr-28 {
    margin-right: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:mb-28,[dir='ltr'].md\:ltr\:mb-28 {
    margin-bottom: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:ml-28,[dir='ltr'].md\:ltr\:ml-28 {
    margin-left: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:mt-32,[dir='ltr'].md\:ltr\:mt-32 {
    margin-top: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:mr-32,[dir='ltr'].md\:ltr\:mr-32 {
    margin-right: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:mb-32,[dir='ltr'].md\:ltr\:mb-32 {
    margin-bottom: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:ml-32,[dir='ltr'].md\:ltr\:ml-32 {
    margin-left: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:mt-36,[dir='ltr'].md\:ltr\:mt-36 {
    margin-top: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:mr-36,[dir='ltr'].md\:ltr\:mr-36 {
    margin-right: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:mb-36,[dir='ltr'].md\:ltr\:mb-36 {
    margin-bottom: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:ml-36,[dir='ltr'].md\:ltr\:ml-36 {
    margin-left: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:mt-40,[dir='ltr'].md\:ltr\:mt-40 {
    margin-top: 4rem
  }

  [dir='ltr'] .md\:ltr\:mr-40,[dir='ltr'].md\:ltr\:mr-40 {
    margin-right: 4rem
  }

  [dir='ltr'] .md\:ltr\:mb-40,[dir='ltr'].md\:ltr\:mb-40 {
    margin-bottom: 4rem
  }

  [dir='ltr'] .md\:ltr\:ml-40,[dir='ltr'].md\:ltr\:ml-40 {
    margin-left: 4rem
  }

  [dir='ltr'] .md\:ltr\:mt-44,[dir='ltr'].md\:ltr\:mt-44 {
    margin-top: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:mr-44,[dir='ltr'].md\:ltr\:mr-44 {
    margin-right: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:mb-44,[dir='ltr'].md\:ltr\:mb-44 {
    margin-bottom: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:ml-44,[dir='ltr'].md\:ltr\:ml-44 {
    margin-left: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:mt-48,[dir='ltr'].md\:ltr\:mt-48 {
    margin-top: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:mr-48,[dir='ltr'].md\:ltr\:mr-48 {
    margin-right: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:mb-48,[dir='ltr'].md\:ltr\:mb-48 {
    margin-bottom: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:ml-48,[dir='ltr'].md\:ltr\:ml-48 {
    margin-left: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:mt-52,[dir='ltr'].md\:ltr\:mt-52 {
    margin-top: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:mr-52,[dir='ltr'].md\:ltr\:mr-52 {
    margin-right: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:mb-52,[dir='ltr'].md\:ltr\:mb-52 {
    margin-bottom: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:ml-52,[dir='ltr'].md\:ltr\:ml-52 {
    margin-left: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:mt-56,[dir='ltr'].md\:ltr\:mt-56 {
    margin-top: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:mr-56,[dir='ltr'].md\:ltr\:mr-56 {
    margin-right: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:mb-56,[dir='ltr'].md\:ltr\:mb-56 {
    margin-bottom: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:ml-56,[dir='ltr'].md\:ltr\:ml-56 {
    margin-left: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:mt-60,[dir='ltr'].md\:ltr\:mt-60 {
    margin-top: 6rem
  }

  [dir='ltr'] .md\:ltr\:mr-60,[dir='ltr'].md\:ltr\:mr-60 {
    margin-right: 6rem
  }

  [dir='ltr'] .md\:ltr\:mb-60,[dir='ltr'].md\:ltr\:mb-60 {
    margin-bottom: 6rem
  }

  [dir='ltr'] .md\:ltr\:ml-60,[dir='ltr'].md\:ltr\:ml-60 {
    margin-left: 6rem
  }

  [dir='ltr'] .md\:ltr\:mt-64,[dir='ltr'].md\:ltr\:mt-64 {
    margin-top: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:mr-64,[dir='ltr'].md\:ltr\:mr-64 {
    margin-right: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:mb-64,[dir='ltr'].md\:ltr\:mb-64 {
    margin-bottom: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:ml-64,[dir='ltr'].md\:ltr\:ml-64 {
    margin-left: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:mt-68,[dir='ltr'].md\:ltr\:mt-68 {
    margin-top: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:mr-68,[dir='ltr'].md\:ltr\:mr-68 {
    margin-right: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:mb-68,[dir='ltr'].md\:ltr\:mb-68 {
    margin-bottom: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:ml-68,[dir='ltr'].md\:ltr\:ml-68 {
    margin-left: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:mt-72,[dir='ltr'].md\:ltr\:mt-72 {
    margin-top: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:mr-72,[dir='ltr'].md\:ltr\:mr-72 {
    margin-right: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:mb-72,[dir='ltr'].md\:ltr\:mb-72 {
    margin-bottom: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:ml-72,[dir='ltr'].md\:ltr\:ml-72 {
    margin-left: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:mt-76,[dir='ltr'].md\:ltr\:mt-76 {
    margin-top: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:mr-76,[dir='ltr'].md\:ltr\:mr-76 {
    margin-right: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:mb-76,[dir='ltr'].md\:ltr\:mb-76 {
    margin-bottom: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:ml-76,[dir='ltr'].md\:ltr\:ml-76 {
    margin-left: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:mt-80,[dir='ltr'].md\:ltr\:mt-80 {
    margin-top: 8rem
  }

  [dir='ltr'] .md\:ltr\:mr-80,[dir='ltr'].md\:ltr\:mr-80 {
    margin-right: 8rem
  }

  [dir='ltr'] .md\:ltr\:mb-80,[dir='ltr'].md\:ltr\:mb-80 {
    margin-bottom: 8rem
  }

  [dir='ltr'] .md\:ltr\:ml-80,[dir='ltr'].md\:ltr\:ml-80 {
    margin-left: 8rem
  }

  [dir='ltr'] .md\:ltr\:mt-84,[dir='ltr'].md\:ltr\:mt-84 {
    margin-top: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:mr-84,[dir='ltr'].md\:ltr\:mr-84 {
    margin-right: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:mb-84,[dir='ltr'].md\:ltr\:mb-84 {
    margin-bottom: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:ml-84,[dir='ltr'].md\:ltr\:ml-84 {
    margin-left: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:mt-88,[dir='ltr'].md\:ltr\:mt-88 {
    margin-top: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:mr-88,[dir='ltr'].md\:ltr\:mr-88 {
    margin-right: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:mb-88,[dir='ltr'].md\:ltr\:mb-88 {
    margin-bottom: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:ml-88,[dir='ltr'].md\:ltr\:ml-88 {
    margin-left: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:mt-92,[dir='ltr'].md\:ltr\:mt-92 {
    margin-top: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:mr-92,[dir='ltr'].md\:ltr\:mr-92 {
    margin-right: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:mb-92,[dir='ltr'].md\:ltr\:mb-92 {
    margin-bottom: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:ml-92,[dir='ltr'].md\:ltr\:ml-92 {
    margin-left: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:mt-96,[dir='ltr'].md\:ltr\:mt-96 {
    margin-top: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:mr-96,[dir='ltr'].md\:ltr\:mr-96 {
    margin-right: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:mb-96,[dir='ltr'].md\:ltr\:mb-96 {
    margin-bottom: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:ml-96,[dir='ltr'].md\:ltr\:ml-96 {
    margin-left: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:mt-128,[dir='ltr'].md\:ltr\:mt-128 {
    margin-top: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:mr-128,[dir='ltr'].md\:ltr\:mr-128 {
    margin-right: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:mb-128,[dir='ltr'].md\:ltr\:mb-128 {
    margin-bottom: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:ml-128,[dir='ltr'].md\:ltr\:ml-128 {
    margin-left: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:mt-136,[dir='ltr'].md\:ltr\:mt-136 {
    margin-top: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:mr-136,[dir='ltr'].md\:ltr\:mr-136 {
    margin-right: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:mb-136,[dir='ltr'].md\:ltr\:mb-136 {
    margin-bottom: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:ml-136,[dir='ltr'].md\:ltr\:ml-136 {
    margin-left: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:mt-160,[dir='ltr'].md\:ltr\:mt-160 {
    margin-top: 16rem
  }

  [dir='ltr'] .md\:ltr\:mr-160,[dir='ltr'].md\:ltr\:mr-160 {
    margin-right: 16rem
  }

  [dir='ltr'] .md\:ltr\:mb-160,[dir='ltr'].md\:ltr\:mb-160 {
    margin-bottom: 16rem
  }

  [dir='ltr'] .md\:ltr\:ml-160,[dir='ltr'].md\:ltr\:ml-160 {
    margin-left: 16rem
  }

  [dir='ltr'] .md\:ltr\:mt-192,[dir='ltr'].md\:ltr\:mt-192 {
    margin-top: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:mr-192,[dir='ltr'].md\:ltr\:mr-192 {
    margin-right: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:mb-192,[dir='ltr'].md\:ltr\:mb-192 {
    margin-bottom: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:ml-192,[dir='ltr'].md\:ltr\:ml-192 {
    margin-left: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:mt-200,[dir='ltr'].md\:ltr\:mt-200 {
    margin-top: 20rem
  }

  [dir='ltr'] .md\:ltr\:mr-200,[dir='ltr'].md\:ltr\:mr-200 {
    margin-right: 20rem
  }

  [dir='ltr'] .md\:ltr\:mb-200,[dir='ltr'].md\:ltr\:mb-200 {
    margin-bottom: 20rem
  }

  [dir='ltr'] .md\:ltr\:ml-200,[dir='ltr'].md\:ltr\:ml-200 {
    margin-left: 20rem
  }

  [dir='ltr'] .md\:ltr\:mt-208,[dir='ltr'].md\:ltr\:mt-208 {
    margin-top: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:mr-208,[dir='ltr'].md\:ltr\:mr-208 {
    margin-right: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:mb-208,[dir='ltr'].md\:ltr\:mb-208 {
    margin-bottom: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:ml-208,[dir='ltr'].md\:ltr\:ml-208 {
    margin-left: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:mt-216,[dir='ltr'].md\:ltr\:mt-216 {
    margin-top: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:mr-216,[dir='ltr'].md\:ltr\:mr-216 {
    margin-right: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:mb-216,[dir='ltr'].md\:ltr\:mb-216 {
    margin-bottom: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:ml-216,[dir='ltr'].md\:ltr\:ml-216 {
    margin-left: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:mt-224,[dir='ltr'].md\:ltr\:mt-224 {
    margin-top: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:mr-224,[dir='ltr'].md\:ltr\:mr-224 {
    margin-right: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:mb-224,[dir='ltr'].md\:ltr\:mb-224 {
    margin-bottom: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:ml-224,[dir='ltr'].md\:ltr\:ml-224 {
    margin-left: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:mt-256,[dir='ltr'].md\:ltr\:mt-256 {
    margin-top: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:mr-256,[dir='ltr'].md\:ltr\:mr-256 {
    margin-right: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:mb-256,[dir='ltr'].md\:ltr\:mb-256 {
    margin-bottom: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:ml-256,[dir='ltr'].md\:ltr\:ml-256 {
    margin-left: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:mt-288,[dir='ltr'].md\:ltr\:mt-288 {
    margin-top: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:mr-288,[dir='ltr'].md\:ltr\:mr-288 {
    margin-right: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:mb-288,[dir='ltr'].md\:ltr\:mb-288 {
    margin-bottom: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:ml-288,[dir='ltr'].md\:ltr\:ml-288 {
    margin-left: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:mt-320,[dir='ltr'].md\:ltr\:mt-320 {
    margin-top: 32rem
  }

  [dir='ltr'] .md\:ltr\:mr-320,[dir='ltr'].md\:ltr\:mr-320 {
    margin-right: 32rem
  }

  [dir='ltr'] .md\:ltr\:mb-320,[dir='ltr'].md\:ltr\:mb-320 {
    margin-bottom: 32rem
  }

  [dir='ltr'] .md\:ltr\:ml-320,[dir='ltr'].md\:ltr\:ml-320 {
    margin-left: 32rem
  }

  [dir='ltr'] .md\:ltr\:mt-360,[dir='ltr'].md\:ltr\:mt-360 {
    margin-top: 36rem
  }

  [dir='ltr'] .md\:ltr\:mr-360,[dir='ltr'].md\:ltr\:mr-360 {
    margin-right: 36rem
  }

  [dir='ltr'] .md\:ltr\:mb-360,[dir='ltr'].md\:ltr\:mb-360 {
    margin-bottom: 36rem
  }

  [dir='ltr'] .md\:ltr\:ml-360,[dir='ltr'].md\:ltr\:ml-360 {
    margin-left: 36rem
  }

  [dir='ltr'] .md\:ltr\:mt-384,[dir='ltr'].md\:ltr\:mt-384 {
    margin-top: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:mr-384,[dir='ltr'].md\:ltr\:mr-384 {
    margin-right: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:mb-384,[dir='ltr'].md\:ltr\:mb-384 {
    margin-bottom: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:ml-384,[dir='ltr'].md\:ltr\:ml-384 {
    margin-left: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:mt-400,[dir='ltr'].md\:ltr\:mt-400 {
    margin-top: 40rem
  }

  [dir='ltr'] .md\:ltr\:mr-400,[dir='ltr'].md\:ltr\:mr-400 {
    margin-right: 40rem
  }

  [dir='ltr'] .md\:ltr\:mb-400,[dir='ltr'].md\:ltr\:mb-400 {
    margin-bottom: 40rem
  }

  [dir='ltr'] .md\:ltr\:ml-400,[dir='ltr'].md\:ltr\:ml-400 {
    margin-left: 40rem
  }

  [dir='ltr'] .md\:ltr\:mt-512,[dir='ltr'].md\:ltr\:mt-512 {
    margin-top: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:mr-512,[dir='ltr'].md\:ltr\:mr-512 {
    margin-right: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:mb-512,[dir='ltr'].md\:ltr\:mb-512 {
    margin-bottom: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:ml-512,[dir='ltr'].md\:ltr\:ml-512 {
    margin-left: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:mt-640,[dir='ltr'].md\:ltr\:mt-640 {
    margin-top: 64rem
  }

  [dir='ltr'] .md\:ltr\:mr-640,[dir='ltr'].md\:ltr\:mr-640 {
    margin-right: 64rem
  }

  [dir='ltr'] .md\:ltr\:mb-640,[dir='ltr'].md\:ltr\:mb-640 {
    margin-bottom: 64rem
  }

  [dir='ltr'] .md\:ltr\:ml-640,[dir='ltr'].md\:ltr\:ml-640 {
    margin-left: 64rem
  }

  [dir='ltr'] .md\:ltr\:mt-auto,[dir='ltr'].md\:ltr\:mt-auto {
    margin-top: auto
  }

  [dir='ltr'] .md\:ltr\:mr-auto,[dir='ltr'].md\:ltr\:mr-auto {
    margin-right: auto
  }

  [dir='ltr'] .md\:ltr\:mb-auto,[dir='ltr'].md\:ltr\:mb-auto {
    margin-bottom: auto
  }

  [dir='ltr'] .md\:ltr\:ml-auto,[dir='ltr'].md\:ltr\:ml-auto {
    margin-left: auto
  }

  [dir='ltr'] .md\:ltr\:mt-xs,[dir='ltr'].md\:ltr\:mt-xs {
    margin-top: 32rem
  }

  [dir='ltr'] .md\:ltr\:mr-xs,[dir='ltr'].md\:ltr\:mr-xs {
    margin-right: 32rem
  }

  [dir='ltr'] .md\:ltr\:mb-xs,[dir='ltr'].md\:ltr\:mb-xs {
    margin-bottom: 32rem
  }

  [dir='ltr'] .md\:ltr\:ml-xs,[dir='ltr'].md\:ltr\:ml-xs {
    margin-left: 32rem
  }

  [dir='ltr'] .md\:ltr\:mt-sm,[dir='ltr'].md\:ltr\:mt-sm {
    margin-top: 48rem
  }

  [dir='ltr'] .md\:ltr\:mr-sm,[dir='ltr'].md\:ltr\:mr-sm {
    margin-right: 48rem
  }

  [dir='ltr'] .md\:ltr\:mb-sm,[dir='ltr'].md\:ltr\:mb-sm {
    margin-bottom: 48rem
  }

  [dir='ltr'] .md\:ltr\:ml-sm,[dir='ltr'].md\:ltr\:ml-sm {
    margin-left: 48rem
  }

  [dir='ltr'] .md\:ltr\:mt-md,[dir='ltr'].md\:ltr\:mt-md {
    margin-top: 64rem
  }

  [dir='ltr'] .md\:ltr\:mr-md,[dir='ltr'].md\:ltr\:mr-md {
    margin-right: 64rem
  }

  [dir='ltr'] .md\:ltr\:mb-md,[dir='ltr'].md\:ltr\:mb-md {
    margin-bottom: 64rem
  }

  [dir='ltr'] .md\:ltr\:ml-md,[dir='ltr'].md\:ltr\:ml-md {
    margin-left: 64rem
  }

  [dir='ltr'] .md\:ltr\:mt-lg,[dir='ltr'].md\:ltr\:mt-lg {
    margin-top: 80rem
  }

  [dir='ltr'] .md\:ltr\:mr-lg,[dir='ltr'].md\:ltr\:mr-lg {
    margin-right: 80rem
  }

  [dir='ltr'] .md\:ltr\:mb-lg,[dir='ltr'].md\:ltr\:mb-lg {
    margin-bottom: 80rem
  }

  [dir='ltr'] .md\:ltr\:ml-lg,[dir='ltr'].md\:ltr\:ml-lg {
    margin-left: 80rem
  }

  [dir='ltr'] .md\:ltr\:mt-xl,[dir='ltr'].md\:ltr\:mt-xl {
    margin-top: 96rem
  }

  [dir='ltr'] .md\:ltr\:mr-xl,[dir='ltr'].md\:ltr\:mr-xl {
    margin-right: 96rem
  }

  [dir='ltr'] .md\:ltr\:mb-xl,[dir='ltr'].md\:ltr\:mb-xl {
    margin-bottom: 96rem
  }

  [dir='ltr'] .md\:ltr\:ml-xl,[dir='ltr'].md\:ltr\:ml-xl {
    margin-left: 96rem
  }

  [dir='ltr'] .md\:ltr\:mt-2xl,[dir='ltr'].md\:ltr\:mt-2xl {
    margin-top: 112rem
  }

  [dir='ltr'] .md\:ltr\:mr-2xl,[dir='ltr'].md\:ltr\:mr-2xl {
    margin-right: 112rem
  }

  [dir='ltr'] .md\:ltr\:mb-2xl,[dir='ltr'].md\:ltr\:mb-2xl {
    margin-bottom: 112rem
  }

  [dir='ltr'] .md\:ltr\:ml-2xl,[dir='ltr'].md\:ltr\:ml-2xl {
    margin-left: 112rem
  }

  [dir='ltr'] .md\:ltr\:mt-3xl,[dir='ltr'].md\:ltr\:mt-3xl {
    margin-top: 128rem
  }

  [dir='ltr'] .md\:ltr\:mr-3xl,[dir='ltr'].md\:ltr\:mr-3xl {
    margin-right: 128rem
  }

  [dir='ltr'] .md\:ltr\:mb-3xl,[dir='ltr'].md\:ltr\:mb-3xl {
    margin-bottom: 128rem
  }

  [dir='ltr'] .md\:ltr\:ml-3xl,[dir='ltr'].md\:ltr\:ml-3xl {
    margin-left: 128rem
  }

  [dir='ltr'] .md\:ltr\:mt-4xl,[dir='ltr'].md\:ltr\:mt-4xl {
    margin-top: 144rem
  }

  [dir='ltr'] .md\:ltr\:mr-4xl,[dir='ltr'].md\:ltr\:mr-4xl {
    margin-right: 144rem
  }

  [dir='ltr'] .md\:ltr\:mb-4xl,[dir='ltr'].md\:ltr\:mb-4xl {
    margin-bottom: 144rem
  }

  [dir='ltr'] .md\:ltr\:ml-4xl,[dir='ltr'].md\:ltr\:ml-4xl {
    margin-left: 144rem
  }

  [dir='ltr'] .md\:ltr\:mt-5xl,[dir='ltr'].md\:ltr\:mt-5xl {
    margin-top: 160rem
  }

  [dir='ltr'] .md\:ltr\:mr-5xl,[dir='ltr'].md\:ltr\:mr-5xl {
    margin-right: 160rem
  }

  [dir='ltr'] .md\:ltr\:mb-5xl,[dir='ltr'].md\:ltr\:mb-5xl {
    margin-bottom: 160rem
  }

  [dir='ltr'] .md\:ltr\:ml-5xl,[dir='ltr'].md\:ltr\:ml-5xl {
    margin-left: 160rem
  }

  [dir='ltr'] .md\:ltr\:mt-px,[dir='ltr'].md\:ltr\:mt-px {
    margin-top: 1px
  }

  [dir='ltr'] .md\:ltr\:mr-px,[dir='ltr'].md\:ltr\:mr-px {
    margin-right: 1px
  }

  [dir='ltr'] .md\:ltr\:mb-px,[dir='ltr'].md\:ltr\:mb-px {
    margin-bottom: 1px
  }

  [dir='ltr'] .md\:ltr\:ml-px,[dir='ltr'].md\:ltr\:ml-px {
    margin-left: 1px
  }

  [dir='ltr'] .md\:ltr\:mt-0\.5,[dir='ltr'].md\:ltr\:mt-0\.5 {
    margin-top: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:mr-0\.5,[dir='ltr'].md\:ltr\:mr-0\.5 {
    margin-right: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:mb-0\.5,[dir='ltr'].md\:ltr\:mb-0\.5 {
    margin-bottom: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:ml-0\.5,[dir='ltr'].md\:ltr\:ml-0\.5 {
    margin-left: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:mt-1\.5,[dir='ltr'].md\:ltr\:mt-1\.5 {
    margin-top: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:mr-1\.5,[dir='ltr'].md\:ltr\:mr-1\.5 {
    margin-right: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:mb-1\.5,[dir='ltr'].md\:ltr\:mb-1\.5 {
    margin-bottom: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:ml-1\.5,[dir='ltr'].md\:ltr\:ml-1\.5 {
    margin-left: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:mt-2\.5,[dir='ltr'].md\:ltr\:mt-2\.5 {
    margin-top: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:mr-2\.5,[dir='ltr'].md\:ltr\:mr-2\.5 {
    margin-right: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:mb-2\.5,[dir='ltr'].md\:ltr\:mb-2\.5 {
    margin-bottom: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:ml-2\.5,[dir='ltr'].md\:ltr\:ml-2\.5 {
    margin-left: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:mt-3\.5,[dir='ltr'].md\:ltr\:mt-3\.5 {
    margin-top: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:mr-3\.5,[dir='ltr'].md\:ltr\:mr-3\.5 {
    margin-right: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:mb-3\.5,[dir='ltr'].md\:ltr\:mb-3\.5 {
    margin-bottom: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:ml-3\.5,[dir='ltr'].md\:ltr\:ml-3\.5 {
    margin-left: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:-mt-1,[dir='ltr'].md\:ltr\:-mt-1 {
    margin-top: -0.1rem
  }

  [dir='ltr'] .md\:ltr\:-mr-1,[dir='ltr'].md\:ltr\:-mr-1 {
    margin-right: -0.1rem
  }

  [dir='ltr'] .md\:ltr\:-mb-1,[dir='ltr'].md\:ltr\:-mb-1 {
    margin-bottom: -0.1rem
  }

  [dir='ltr'] .md\:ltr\:-ml-1,[dir='ltr'].md\:ltr\:-ml-1 {
    margin-left: -0.1rem
  }

  [dir='ltr'] .md\:ltr\:-mt-2,[dir='ltr'].md\:ltr\:-mt-2 {
    margin-top: -0.2rem
  }

  [dir='ltr'] .md\:ltr\:-mr-2,[dir='ltr'].md\:ltr\:-mr-2 {
    margin-right: -0.2rem
  }

  [dir='ltr'] .md\:ltr\:-mb-2,[dir='ltr'].md\:ltr\:-mb-2 {
    margin-bottom: -0.2rem
  }

  [dir='ltr'] .md\:ltr\:-ml-2,[dir='ltr'].md\:ltr\:-ml-2 {
    margin-left: -0.2rem
  }

  [dir='ltr'] .md\:ltr\:-mt-3,[dir='ltr'].md\:ltr\:-mt-3 {
    margin-top: -0.3rem
  }

  [dir='ltr'] .md\:ltr\:-mr-3,[dir='ltr'].md\:ltr\:-mr-3 {
    margin-right: -0.3rem
  }

  [dir='ltr'] .md\:ltr\:-mb-3,[dir='ltr'].md\:ltr\:-mb-3 {
    margin-bottom: -0.3rem
  }

  [dir='ltr'] .md\:ltr\:-ml-3,[dir='ltr'].md\:ltr\:-ml-3 {
    margin-left: -0.3rem
  }

  [dir='ltr'] .md\:ltr\:-mt-4,[dir='ltr'].md\:ltr\:-mt-4 {
    margin-top: -0.4rem
  }

  [dir='ltr'] .md\:ltr\:-mr-4,[dir='ltr'].md\:ltr\:-mr-4 {
    margin-right: -0.4rem
  }

  [dir='ltr'] .md\:ltr\:-mb-4,[dir='ltr'].md\:ltr\:-mb-4 {
    margin-bottom: -0.4rem
  }

  [dir='ltr'] .md\:ltr\:-ml-4,[dir='ltr'].md\:ltr\:-ml-4 {
    margin-left: -0.4rem
  }

  [dir='ltr'] .md\:ltr\:-mt-5,[dir='ltr'].md\:ltr\:-mt-5 {
    margin-top: -0.5rem
  }

  [dir='ltr'] .md\:ltr\:-mr-5,[dir='ltr'].md\:ltr\:-mr-5 {
    margin-right: -0.5rem
  }

  [dir='ltr'] .md\:ltr\:-mb-5,[dir='ltr'].md\:ltr\:-mb-5 {
    margin-bottom: -0.5rem
  }

  [dir='ltr'] .md\:ltr\:-ml-5,[dir='ltr'].md\:ltr\:-ml-5 {
    margin-left: -0.5rem
  }

  [dir='ltr'] .md\:ltr\:-mt-6,[dir='ltr'].md\:ltr\:-mt-6 {
    margin-top: -0.6rem
  }

  [dir='ltr'] .md\:ltr\:-mr-6,[dir='ltr'].md\:ltr\:-mr-6 {
    margin-right: -0.6rem
  }

  [dir='ltr'] .md\:ltr\:-mb-6,[dir='ltr'].md\:ltr\:-mb-6 {
    margin-bottom: -0.6rem
  }

  [dir='ltr'] .md\:ltr\:-ml-6,[dir='ltr'].md\:ltr\:-ml-6 {
    margin-left: -0.6rem
  }

  [dir='ltr'] .md\:ltr\:-mt-7,[dir='ltr'].md\:ltr\:-mt-7 {
    margin-top: -0.7rem
  }

  [dir='ltr'] .md\:ltr\:-mr-7,[dir='ltr'].md\:ltr\:-mr-7 {
    margin-right: -0.7rem
  }

  [dir='ltr'] .md\:ltr\:-mb-7,[dir='ltr'].md\:ltr\:-mb-7 {
    margin-bottom: -0.7rem
  }

  [dir='ltr'] .md\:ltr\:-ml-7,[dir='ltr'].md\:ltr\:-ml-7 {
    margin-left: -0.7rem
  }

  [dir='ltr'] .md\:ltr\:-mt-8,[dir='ltr'].md\:ltr\:-mt-8 {
    margin-top: -0.8rem
  }

  [dir='ltr'] .md\:ltr\:-mr-8,[dir='ltr'].md\:ltr\:-mr-8 {
    margin-right: -0.8rem
  }

  [dir='ltr'] .md\:ltr\:-mb-8,[dir='ltr'].md\:ltr\:-mb-8 {
    margin-bottom: -0.8rem
  }

  [dir='ltr'] .md\:ltr\:-ml-8,[dir='ltr'].md\:ltr\:-ml-8 {
    margin-left: -0.8rem
  }

  [dir='ltr'] .md\:ltr\:-mt-9,[dir='ltr'].md\:ltr\:-mt-9 {
    margin-top: -0.9rem
  }

  [dir='ltr'] .md\:ltr\:-mr-9,[dir='ltr'].md\:ltr\:-mr-9 {
    margin-right: -0.9rem
  }

  [dir='ltr'] .md\:ltr\:-mb-9,[dir='ltr'].md\:ltr\:-mb-9 {
    margin-bottom: -0.9rem
  }

  [dir='ltr'] .md\:ltr\:-ml-9,[dir='ltr'].md\:ltr\:-ml-9 {
    margin-left: -0.9rem
  }

  [dir='ltr'] .md\:ltr\:-mt-10,[dir='ltr'].md\:ltr\:-mt-10 {
    margin-top: -1rem
  }

  [dir='ltr'] .md\:ltr\:-mr-10,[dir='ltr'].md\:ltr\:-mr-10 {
    margin-right: -1rem
  }

  [dir='ltr'] .md\:ltr\:-mb-10,[dir='ltr'].md\:ltr\:-mb-10 {
    margin-bottom: -1rem
  }

  [dir='ltr'] .md\:ltr\:-ml-10,[dir='ltr'].md\:ltr\:-ml-10 {
    margin-left: -1rem
  }

  [dir='ltr'] .md\:ltr\:-mt-12,[dir='ltr'].md\:ltr\:-mt-12 {
    margin-top: -1.2rem
  }

  [dir='ltr'] .md\:ltr\:-mr-12,[dir='ltr'].md\:ltr\:-mr-12 {
    margin-right: -1.2rem
  }

  [dir='ltr'] .md\:ltr\:-mb-12,[dir='ltr'].md\:ltr\:-mb-12 {
    margin-bottom: -1.2rem
  }

  [dir='ltr'] .md\:ltr\:-ml-12,[dir='ltr'].md\:ltr\:-ml-12 {
    margin-left: -1.2rem
  }

  [dir='ltr'] .md\:ltr\:-mt-14,[dir='ltr'].md\:ltr\:-mt-14 {
    margin-top: -1.4rem
  }

  [dir='ltr'] .md\:ltr\:-mr-14,[dir='ltr'].md\:ltr\:-mr-14 {
    margin-right: -1.4rem
  }

  [dir='ltr'] .md\:ltr\:-mb-14,[dir='ltr'].md\:ltr\:-mb-14 {
    margin-bottom: -1.4rem
  }

  [dir='ltr'] .md\:ltr\:-ml-14,[dir='ltr'].md\:ltr\:-ml-14 {
    margin-left: -1.4rem
  }

  [dir='ltr'] .md\:ltr\:-mt-16,[dir='ltr'].md\:ltr\:-mt-16 {
    margin-top: -1.6rem
  }

  [dir='ltr'] .md\:ltr\:-mr-16,[dir='ltr'].md\:ltr\:-mr-16 {
    margin-right: -1.6rem
  }

  [dir='ltr'] .md\:ltr\:-mb-16,[dir='ltr'].md\:ltr\:-mb-16 {
    margin-bottom: -1.6rem
  }

  [dir='ltr'] .md\:ltr\:-ml-16,[dir='ltr'].md\:ltr\:-ml-16 {
    margin-left: -1.6rem
  }

  [dir='ltr'] .md\:ltr\:-mt-20,[dir='ltr'].md\:ltr\:-mt-20 {
    margin-top: -2rem
  }

  [dir='ltr'] .md\:ltr\:-mr-20,[dir='ltr'].md\:ltr\:-mr-20 {
    margin-right: -2rem
  }

  [dir='ltr'] .md\:ltr\:-mb-20,[dir='ltr'].md\:ltr\:-mb-20 {
    margin-bottom: -2rem
  }

  [dir='ltr'] .md\:ltr\:-ml-20,[dir='ltr'].md\:ltr\:-ml-20 {
    margin-left: -2rem
  }

  [dir='ltr'] .md\:ltr\:-mt-24,[dir='ltr'].md\:ltr\:-mt-24 {
    margin-top: -2.4rem
  }

  [dir='ltr'] .md\:ltr\:-mr-24,[dir='ltr'].md\:ltr\:-mr-24 {
    margin-right: -2.4rem
  }

  [dir='ltr'] .md\:ltr\:-mb-24,[dir='ltr'].md\:ltr\:-mb-24 {
    margin-bottom: -2.4rem
  }

  [dir='ltr'] .md\:ltr\:-ml-24,[dir='ltr'].md\:ltr\:-ml-24 {
    margin-left: -2.4rem
  }

  [dir='ltr'] .md\:ltr\:-mt-28,[dir='ltr'].md\:ltr\:-mt-28 {
    margin-top: -2.8rem
  }

  [dir='ltr'] .md\:ltr\:-mr-28,[dir='ltr'].md\:ltr\:-mr-28 {
    margin-right: -2.8rem
  }

  [dir='ltr'] .md\:ltr\:-mb-28,[dir='ltr'].md\:ltr\:-mb-28 {
    margin-bottom: -2.8rem
  }

  [dir='ltr'] .md\:ltr\:-ml-28,[dir='ltr'].md\:ltr\:-ml-28 {
    margin-left: -2.8rem
  }

  [dir='ltr'] .md\:ltr\:-mt-32,[dir='ltr'].md\:ltr\:-mt-32 {
    margin-top: -3.2rem
  }

  [dir='ltr'] .md\:ltr\:-mr-32,[dir='ltr'].md\:ltr\:-mr-32 {
    margin-right: -3.2rem
  }

  [dir='ltr'] .md\:ltr\:-mb-32,[dir='ltr'].md\:ltr\:-mb-32 {
    margin-bottom: -3.2rem
  }

  [dir='ltr'] .md\:ltr\:-ml-32,[dir='ltr'].md\:ltr\:-ml-32 {
    margin-left: -3.2rem
  }

  [dir='ltr'] .md\:ltr\:-mt-36,[dir='ltr'].md\:ltr\:-mt-36 {
    margin-top: -3.6rem
  }

  [dir='ltr'] .md\:ltr\:-mr-36,[dir='ltr'].md\:ltr\:-mr-36 {
    margin-right: -3.6rem
  }

  [dir='ltr'] .md\:ltr\:-mb-36,[dir='ltr'].md\:ltr\:-mb-36 {
    margin-bottom: -3.6rem
  }

  [dir='ltr'] .md\:ltr\:-ml-36,[dir='ltr'].md\:ltr\:-ml-36 {
    margin-left: -3.6rem
  }

  [dir='ltr'] .md\:ltr\:-mt-40,[dir='ltr'].md\:ltr\:-mt-40 {
    margin-top: -4rem
  }

  [dir='ltr'] .md\:ltr\:-mr-40,[dir='ltr'].md\:ltr\:-mr-40 {
    margin-right: -4rem
  }

  [dir='ltr'] .md\:ltr\:-mb-40,[dir='ltr'].md\:ltr\:-mb-40 {
    margin-bottom: -4rem
  }

  [dir='ltr'] .md\:ltr\:-ml-40,[dir='ltr'].md\:ltr\:-ml-40 {
    margin-left: -4rem
  }

  [dir='ltr'] .md\:ltr\:-mt-44,[dir='ltr'].md\:ltr\:-mt-44 {
    margin-top: -4.4rem
  }

  [dir='ltr'] .md\:ltr\:-mr-44,[dir='ltr'].md\:ltr\:-mr-44 {
    margin-right: -4.4rem
  }

  [dir='ltr'] .md\:ltr\:-mb-44,[dir='ltr'].md\:ltr\:-mb-44 {
    margin-bottom: -4.4rem
  }

  [dir='ltr'] .md\:ltr\:-ml-44,[dir='ltr'].md\:ltr\:-ml-44 {
    margin-left: -4.4rem
  }

  [dir='ltr'] .md\:ltr\:-mt-48,[dir='ltr'].md\:ltr\:-mt-48 {
    margin-top: -4.8rem
  }

  [dir='ltr'] .md\:ltr\:-mr-48,[dir='ltr'].md\:ltr\:-mr-48 {
    margin-right: -4.8rem
  }

  [dir='ltr'] .md\:ltr\:-mb-48,[dir='ltr'].md\:ltr\:-mb-48 {
    margin-bottom: -4.8rem
  }

  [dir='ltr'] .md\:ltr\:-ml-48,[dir='ltr'].md\:ltr\:-ml-48 {
    margin-left: -4.8rem
  }

  [dir='ltr'] .md\:ltr\:-mt-52,[dir='ltr'].md\:ltr\:-mt-52 {
    margin-top: -5.2rem
  }

  [dir='ltr'] .md\:ltr\:-mr-52,[dir='ltr'].md\:ltr\:-mr-52 {
    margin-right: -5.2rem
  }

  [dir='ltr'] .md\:ltr\:-mb-52,[dir='ltr'].md\:ltr\:-mb-52 {
    margin-bottom: -5.2rem
  }

  [dir='ltr'] .md\:ltr\:-ml-52,[dir='ltr'].md\:ltr\:-ml-52 {
    margin-left: -5.2rem
  }

  [dir='ltr'] .md\:ltr\:-mt-56,[dir='ltr'].md\:ltr\:-mt-56 {
    margin-top: -5.6rem
  }

  [dir='ltr'] .md\:ltr\:-mr-56,[dir='ltr'].md\:ltr\:-mr-56 {
    margin-right: -5.6rem
  }

  [dir='ltr'] .md\:ltr\:-mb-56,[dir='ltr'].md\:ltr\:-mb-56 {
    margin-bottom: -5.6rem
  }

  [dir='ltr'] .md\:ltr\:-ml-56,[dir='ltr'].md\:ltr\:-ml-56 {
    margin-left: -5.6rem
  }

  [dir='ltr'] .md\:ltr\:-mt-60,[dir='ltr'].md\:ltr\:-mt-60 {
    margin-top: -6rem
  }

  [dir='ltr'] .md\:ltr\:-mr-60,[dir='ltr'].md\:ltr\:-mr-60 {
    margin-right: -6rem
  }

  [dir='ltr'] .md\:ltr\:-mb-60,[dir='ltr'].md\:ltr\:-mb-60 {
    margin-bottom: -6rem
  }

  [dir='ltr'] .md\:ltr\:-ml-60,[dir='ltr'].md\:ltr\:-ml-60 {
    margin-left: -6rem
  }

  [dir='ltr'] .md\:ltr\:-mt-64,[dir='ltr'].md\:ltr\:-mt-64 {
    margin-top: -6.4rem
  }

  [dir='ltr'] .md\:ltr\:-mr-64,[dir='ltr'].md\:ltr\:-mr-64 {
    margin-right: -6.4rem
  }

  [dir='ltr'] .md\:ltr\:-mb-64,[dir='ltr'].md\:ltr\:-mb-64 {
    margin-bottom: -6.4rem
  }

  [dir='ltr'] .md\:ltr\:-ml-64,[dir='ltr'].md\:ltr\:-ml-64 {
    margin-left: -6.4rem
  }

  [dir='ltr'] .md\:ltr\:-mt-68,[dir='ltr'].md\:ltr\:-mt-68 {
    margin-top: -6.8rem
  }

  [dir='ltr'] .md\:ltr\:-mr-68,[dir='ltr'].md\:ltr\:-mr-68 {
    margin-right: -6.8rem
  }

  [dir='ltr'] .md\:ltr\:-mb-68,[dir='ltr'].md\:ltr\:-mb-68 {
    margin-bottom: -6.8rem
  }

  [dir='ltr'] .md\:ltr\:-ml-68,[dir='ltr'].md\:ltr\:-ml-68 {
    margin-left: -6.8rem
  }

  [dir='ltr'] .md\:ltr\:-mt-72,[dir='ltr'].md\:ltr\:-mt-72 {
    margin-top: -7.2rem
  }

  [dir='ltr'] .md\:ltr\:-mr-72,[dir='ltr'].md\:ltr\:-mr-72 {
    margin-right: -7.2rem
  }

  [dir='ltr'] .md\:ltr\:-mb-72,[dir='ltr'].md\:ltr\:-mb-72 {
    margin-bottom: -7.2rem
  }

  [dir='ltr'] .md\:ltr\:-ml-72,[dir='ltr'].md\:ltr\:-ml-72 {
    margin-left: -7.2rem
  }

  [dir='ltr'] .md\:ltr\:-mt-76,[dir='ltr'].md\:ltr\:-mt-76 {
    margin-top: -7.6rem
  }

  [dir='ltr'] .md\:ltr\:-mr-76,[dir='ltr'].md\:ltr\:-mr-76 {
    margin-right: -7.6rem
  }

  [dir='ltr'] .md\:ltr\:-mb-76,[dir='ltr'].md\:ltr\:-mb-76 {
    margin-bottom: -7.6rem
  }

  [dir='ltr'] .md\:ltr\:-ml-76,[dir='ltr'].md\:ltr\:-ml-76 {
    margin-left: -7.6rem
  }

  [dir='ltr'] .md\:ltr\:-mt-80,[dir='ltr'].md\:ltr\:-mt-80 {
    margin-top: -8rem
  }

  [dir='ltr'] .md\:ltr\:-mr-80,[dir='ltr'].md\:ltr\:-mr-80 {
    margin-right: -8rem
  }

  [dir='ltr'] .md\:ltr\:-mb-80,[dir='ltr'].md\:ltr\:-mb-80 {
    margin-bottom: -8rem
  }

  [dir='ltr'] .md\:ltr\:-ml-80,[dir='ltr'].md\:ltr\:-ml-80 {
    margin-left: -8rem
  }

  [dir='ltr'] .md\:ltr\:-mt-84,[dir='ltr'].md\:ltr\:-mt-84 {
    margin-top: -8.4rem
  }

  [dir='ltr'] .md\:ltr\:-mr-84,[dir='ltr'].md\:ltr\:-mr-84 {
    margin-right: -8.4rem
  }

  [dir='ltr'] .md\:ltr\:-mb-84,[dir='ltr'].md\:ltr\:-mb-84 {
    margin-bottom: -8.4rem
  }

  [dir='ltr'] .md\:ltr\:-ml-84,[dir='ltr'].md\:ltr\:-ml-84 {
    margin-left: -8.4rem
  }

  [dir='ltr'] .md\:ltr\:-mt-88,[dir='ltr'].md\:ltr\:-mt-88 {
    margin-top: -8.8rem
  }

  [dir='ltr'] .md\:ltr\:-mr-88,[dir='ltr'].md\:ltr\:-mr-88 {
    margin-right: -8.8rem
  }

  [dir='ltr'] .md\:ltr\:-mb-88,[dir='ltr'].md\:ltr\:-mb-88 {
    margin-bottom: -8.8rem
  }

  [dir='ltr'] .md\:ltr\:-ml-88,[dir='ltr'].md\:ltr\:-ml-88 {
    margin-left: -8.8rem
  }

  [dir='ltr'] .md\:ltr\:-mt-92,[dir='ltr'].md\:ltr\:-mt-92 {
    margin-top: -9.2rem
  }

  [dir='ltr'] .md\:ltr\:-mr-92,[dir='ltr'].md\:ltr\:-mr-92 {
    margin-right: -9.2rem
  }

  [dir='ltr'] .md\:ltr\:-mb-92,[dir='ltr'].md\:ltr\:-mb-92 {
    margin-bottom: -9.2rem
  }

  [dir='ltr'] .md\:ltr\:-ml-92,[dir='ltr'].md\:ltr\:-ml-92 {
    margin-left: -9.2rem
  }

  [dir='ltr'] .md\:ltr\:-mt-96,[dir='ltr'].md\:ltr\:-mt-96 {
    margin-top: -9.6rem
  }

  [dir='ltr'] .md\:ltr\:-mr-96,[dir='ltr'].md\:ltr\:-mr-96 {
    margin-right: -9.6rem
  }

  [dir='ltr'] .md\:ltr\:-mb-96,[dir='ltr'].md\:ltr\:-mb-96 {
    margin-bottom: -9.6rem
  }

  [dir='ltr'] .md\:ltr\:-ml-96,[dir='ltr'].md\:ltr\:-ml-96 {
    margin-left: -9.6rem
  }

  [dir='ltr'] .md\:ltr\:-mt-128,[dir='ltr'].md\:ltr\:-mt-128 {
    margin-top: -12.8rem
  }

  [dir='ltr'] .md\:ltr\:-mr-128,[dir='ltr'].md\:ltr\:-mr-128 {
    margin-right: -12.8rem
  }

  [dir='ltr'] .md\:ltr\:-mb-128,[dir='ltr'].md\:ltr\:-mb-128 {
    margin-bottom: -12.8rem
  }

  [dir='ltr'] .md\:ltr\:-ml-128,[dir='ltr'].md\:ltr\:-ml-128 {
    margin-left: -12.8rem
  }

  [dir='ltr'] .md\:ltr\:-mt-136,[dir='ltr'].md\:ltr\:-mt-136 {
    margin-top: -13.6rem
  }

  [dir='ltr'] .md\:ltr\:-mr-136,[dir='ltr'].md\:ltr\:-mr-136 {
    margin-right: -13.6rem
  }

  [dir='ltr'] .md\:ltr\:-mb-136,[dir='ltr'].md\:ltr\:-mb-136 {
    margin-bottom: -13.6rem
  }

  [dir='ltr'] .md\:ltr\:-ml-136,[dir='ltr'].md\:ltr\:-ml-136 {
    margin-left: -13.6rem
  }

  [dir='ltr'] .md\:ltr\:-mt-160,[dir='ltr'].md\:ltr\:-mt-160 {
    margin-top: -16rem
  }

  [dir='ltr'] .md\:ltr\:-mr-160,[dir='ltr'].md\:ltr\:-mr-160 {
    margin-right: -16rem
  }

  [dir='ltr'] .md\:ltr\:-mb-160,[dir='ltr'].md\:ltr\:-mb-160 {
    margin-bottom: -16rem
  }

  [dir='ltr'] .md\:ltr\:-ml-160,[dir='ltr'].md\:ltr\:-ml-160 {
    margin-left: -16rem
  }

  [dir='ltr'] .md\:ltr\:-mt-192,[dir='ltr'].md\:ltr\:-mt-192 {
    margin-top: -19.2rem
  }

  [dir='ltr'] .md\:ltr\:-mr-192,[dir='ltr'].md\:ltr\:-mr-192 {
    margin-right: -19.2rem
  }

  [dir='ltr'] .md\:ltr\:-mb-192,[dir='ltr'].md\:ltr\:-mb-192 {
    margin-bottom: -19.2rem
  }

  [dir='ltr'] .md\:ltr\:-ml-192,[dir='ltr'].md\:ltr\:-ml-192 {
    margin-left: -19.2rem
  }

  [dir='ltr'] .md\:ltr\:-mt-200,[dir='ltr'].md\:ltr\:-mt-200 {
    margin-top: -20rem
  }

  [dir='ltr'] .md\:ltr\:-mr-200,[dir='ltr'].md\:ltr\:-mr-200 {
    margin-right: -20rem
  }

  [dir='ltr'] .md\:ltr\:-mb-200,[dir='ltr'].md\:ltr\:-mb-200 {
    margin-bottom: -20rem
  }

  [dir='ltr'] .md\:ltr\:-ml-200,[dir='ltr'].md\:ltr\:-ml-200 {
    margin-left: -20rem
  }

  [dir='ltr'] .md\:ltr\:-mt-208,[dir='ltr'].md\:ltr\:-mt-208 {
    margin-top: -20.8rem
  }

  [dir='ltr'] .md\:ltr\:-mr-208,[dir='ltr'].md\:ltr\:-mr-208 {
    margin-right: -20.8rem
  }

  [dir='ltr'] .md\:ltr\:-mb-208,[dir='ltr'].md\:ltr\:-mb-208 {
    margin-bottom: -20.8rem
  }

  [dir='ltr'] .md\:ltr\:-ml-208,[dir='ltr'].md\:ltr\:-ml-208 {
    margin-left: -20.8rem
  }

  [dir='ltr'] .md\:ltr\:-mt-216,[dir='ltr'].md\:ltr\:-mt-216 {
    margin-top: -21.6rem
  }

  [dir='ltr'] .md\:ltr\:-mr-216,[dir='ltr'].md\:ltr\:-mr-216 {
    margin-right: -21.6rem
  }

  [dir='ltr'] .md\:ltr\:-mb-216,[dir='ltr'].md\:ltr\:-mb-216 {
    margin-bottom: -21.6rem
  }

  [dir='ltr'] .md\:ltr\:-ml-216,[dir='ltr'].md\:ltr\:-ml-216 {
    margin-left: -21.6rem
  }

  [dir='ltr'] .md\:ltr\:-mt-224,[dir='ltr'].md\:ltr\:-mt-224 {
    margin-top: -22.4rem
  }

  [dir='ltr'] .md\:ltr\:-mr-224,[dir='ltr'].md\:ltr\:-mr-224 {
    margin-right: -22.4rem
  }

  [dir='ltr'] .md\:ltr\:-mb-224,[dir='ltr'].md\:ltr\:-mb-224 {
    margin-bottom: -22.4rem
  }

  [dir='ltr'] .md\:ltr\:-ml-224,[dir='ltr'].md\:ltr\:-ml-224 {
    margin-left: -22.4rem
  }

  [dir='ltr'] .md\:ltr\:-mt-256,[dir='ltr'].md\:ltr\:-mt-256 {
    margin-top: -25.6rem
  }

  [dir='ltr'] .md\:ltr\:-mr-256,[dir='ltr'].md\:ltr\:-mr-256 {
    margin-right: -25.6rem
  }

  [dir='ltr'] .md\:ltr\:-mb-256,[dir='ltr'].md\:ltr\:-mb-256 {
    margin-bottom: -25.6rem
  }

  [dir='ltr'] .md\:ltr\:-ml-256,[dir='ltr'].md\:ltr\:-ml-256 {
    margin-left: -25.6rem
  }

  [dir='ltr'] .md\:ltr\:-mt-288,[dir='ltr'].md\:ltr\:-mt-288 {
    margin-top: -28.8rem
  }

  [dir='ltr'] .md\:ltr\:-mr-288,[dir='ltr'].md\:ltr\:-mr-288 {
    margin-right: -28.8rem
  }

  [dir='ltr'] .md\:ltr\:-mb-288,[dir='ltr'].md\:ltr\:-mb-288 {
    margin-bottom: -28.8rem
  }

  [dir='ltr'] .md\:ltr\:-ml-288,[dir='ltr'].md\:ltr\:-ml-288 {
    margin-left: -28.8rem
  }

  [dir='ltr'] .md\:ltr\:-mt-320,[dir='ltr'].md\:ltr\:-mt-320 {
    margin-top: -32rem
  }

  [dir='ltr'] .md\:ltr\:-mr-320,[dir='ltr'].md\:ltr\:-mr-320 {
    margin-right: -32rem
  }

  [dir='ltr'] .md\:ltr\:-mb-320,[dir='ltr'].md\:ltr\:-mb-320 {
    margin-bottom: -32rem
  }

  [dir='ltr'] .md\:ltr\:-ml-320,[dir='ltr'].md\:ltr\:-ml-320 {
    margin-left: -32rem
  }

  [dir='ltr'] .md\:ltr\:-mt-360,[dir='ltr'].md\:ltr\:-mt-360 {
    margin-top: -36rem
  }

  [dir='ltr'] .md\:ltr\:-mr-360,[dir='ltr'].md\:ltr\:-mr-360 {
    margin-right: -36rem
  }

  [dir='ltr'] .md\:ltr\:-mb-360,[dir='ltr'].md\:ltr\:-mb-360 {
    margin-bottom: -36rem
  }

  [dir='ltr'] .md\:ltr\:-ml-360,[dir='ltr'].md\:ltr\:-ml-360 {
    margin-left: -36rem
  }

  [dir='ltr'] .md\:ltr\:-mt-384,[dir='ltr'].md\:ltr\:-mt-384 {
    margin-top: -38.4rem
  }

  [dir='ltr'] .md\:ltr\:-mr-384,[dir='ltr'].md\:ltr\:-mr-384 {
    margin-right: -38.4rem
  }

  [dir='ltr'] .md\:ltr\:-mb-384,[dir='ltr'].md\:ltr\:-mb-384 {
    margin-bottom: -38.4rem
  }

  [dir='ltr'] .md\:ltr\:-ml-384,[dir='ltr'].md\:ltr\:-ml-384 {
    margin-left: -38.4rem
  }

  [dir='ltr'] .md\:ltr\:-mt-400,[dir='ltr'].md\:ltr\:-mt-400 {
    margin-top: -40rem
  }

  [dir='ltr'] .md\:ltr\:-mr-400,[dir='ltr'].md\:ltr\:-mr-400 {
    margin-right: -40rem
  }

  [dir='ltr'] .md\:ltr\:-mb-400,[dir='ltr'].md\:ltr\:-mb-400 {
    margin-bottom: -40rem
  }

  [dir='ltr'] .md\:ltr\:-ml-400,[dir='ltr'].md\:ltr\:-ml-400 {
    margin-left: -40rem
  }

  [dir='ltr'] .md\:ltr\:-mt-512,[dir='ltr'].md\:ltr\:-mt-512 {
    margin-top: -51.2rem
  }

  [dir='ltr'] .md\:ltr\:-mr-512,[dir='ltr'].md\:ltr\:-mr-512 {
    margin-right: -51.2rem
  }

  [dir='ltr'] .md\:ltr\:-mb-512,[dir='ltr'].md\:ltr\:-mb-512 {
    margin-bottom: -51.2rem
  }

  [dir='ltr'] .md\:ltr\:-ml-512,[dir='ltr'].md\:ltr\:-ml-512 {
    margin-left: -51.2rem
  }

  [dir='ltr'] .md\:ltr\:-mt-640,[dir='ltr'].md\:ltr\:-mt-640 {
    margin-top: -64rem
  }

  [dir='ltr'] .md\:ltr\:-mr-640,[dir='ltr'].md\:ltr\:-mr-640 {
    margin-right: -64rem
  }

  [dir='ltr'] .md\:ltr\:-mb-640,[dir='ltr'].md\:ltr\:-mb-640 {
    margin-bottom: -64rem
  }

  [dir='ltr'] .md\:ltr\:-ml-640,[dir='ltr'].md\:ltr\:-ml-640 {
    margin-left: -64rem
  }

  [dir='ltr'] .md\:ltr\:-mt-xs,[dir='ltr'].md\:ltr\:-mt-xs {
    margin-top: -32rem
  }

  [dir='ltr'] .md\:ltr\:-mr-xs,[dir='ltr'].md\:ltr\:-mr-xs {
    margin-right: -32rem
  }

  [dir='ltr'] .md\:ltr\:-mb-xs,[dir='ltr'].md\:ltr\:-mb-xs {
    margin-bottom: -32rem
  }

  [dir='ltr'] .md\:ltr\:-ml-xs,[dir='ltr'].md\:ltr\:-ml-xs {
    margin-left: -32rem
  }

  [dir='ltr'] .md\:ltr\:-mt-sm,[dir='ltr'].md\:ltr\:-mt-sm {
    margin-top: -48rem
  }

  [dir='ltr'] .md\:ltr\:-mr-sm,[dir='ltr'].md\:ltr\:-mr-sm {
    margin-right: -48rem
  }

  [dir='ltr'] .md\:ltr\:-mb-sm,[dir='ltr'].md\:ltr\:-mb-sm {
    margin-bottom: -48rem
  }

  [dir='ltr'] .md\:ltr\:-ml-sm,[dir='ltr'].md\:ltr\:-ml-sm {
    margin-left: -48rem
  }

  [dir='ltr'] .md\:ltr\:-mt-md,[dir='ltr'].md\:ltr\:-mt-md {
    margin-top: -64rem
  }

  [dir='ltr'] .md\:ltr\:-mr-md,[dir='ltr'].md\:ltr\:-mr-md {
    margin-right: -64rem
  }

  [dir='ltr'] .md\:ltr\:-mb-md,[dir='ltr'].md\:ltr\:-mb-md {
    margin-bottom: -64rem
  }

  [dir='ltr'] .md\:ltr\:-ml-md,[dir='ltr'].md\:ltr\:-ml-md {
    margin-left: -64rem
  }

  [dir='ltr'] .md\:ltr\:-mt-lg,[dir='ltr'].md\:ltr\:-mt-lg {
    margin-top: -80rem
  }

  [dir='ltr'] .md\:ltr\:-mr-lg,[dir='ltr'].md\:ltr\:-mr-lg {
    margin-right: -80rem
  }

  [dir='ltr'] .md\:ltr\:-mb-lg,[dir='ltr'].md\:ltr\:-mb-lg {
    margin-bottom: -80rem
  }

  [dir='ltr'] .md\:ltr\:-ml-lg,[dir='ltr'].md\:ltr\:-ml-lg {
    margin-left: -80rem
  }

  [dir='ltr'] .md\:ltr\:-mt-xl,[dir='ltr'].md\:ltr\:-mt-xl {
    margin-top: -96rem
  }

  [dir='ltr'] .md\:ltr\:-mr-xl,[dir='ltr'].md\:ltr\:-mr-xl {
    margin-right: -96rem
  }

  [dir='ltr'] .md\:ltr\:-mb-xl,[dir='ltr'].md\:ltr\:-mb-xl {
    margin-bottom: -96rem
  }

  [dir='ltr'] .md\:ltr\:-ml-xl,[dir='ltr'].md\:ltr\:-ml-xl {
    margin-left: -96rem
  }

  [dir='ltr'] .md\:ltr\:-mt-2xl,[dir='ltr'].md\:ltr\:-mt-2xl {
    margin-top: -112rem
  }

  [dir='ltr'] .md\:ltr\:-mr-2xl,[dir='ltr'].md\:ltr\:-mr-2xl {
    margin-right: -112rem
  }

  [dir='ltr'] .md\:ltr\:-mb-2xl,[dir='ltr'].md\:ltr\:-mb-2xl {
    margin-bottom: -112rem
  }

  [dir='ltr'] .md\:ltr\:-ml-2xl,[dir='ltr'].md\:ltr\:-ml-2xl {
    margin-left: -112rem
  }

  [dir='ltr'] .md\:ltr\:-mt-3xl,[dir='ltr'].md\:ltr\:-mt-3xl {
    margin-top: -128rem
  }

  [dir='ltr'] .md\:ltr\:-mr-3xl,[dir='ltr'].md\:ltr\:-mr-3xl {
    margin-right: -128rem
  }

  [dir='ltr'] .md\:ltr\:-mb-3xl,[dir='ltr'].md\:ltr\:-mb-3xl {
    margin-bottom: -128rem
  }

  [dir='ltr'] .md\:ltr\:-ml-3xl,[dir='ltr'].md\:ltr\:-ml-3xl {
    margin-left: -128rem
  }

  [dir='ltr'] .md\:ltr\:-mt-4xl,[dir='ltr'].md\:ltr\:-mt-4xl {
    margin-top: -144rem
  }

  [dir='ltr'] .md\:ltr\:-mr-4xl,[dir='ltr'].md\:ltr\:-mr-4xl {
    margin-right: -144rem
  }

  [dir='ltr'] .md\:ltr\:-mb-4xl,[dir='ltr'].md\:ltr\:-mb-4xl {
    margin-bottom: -144rem
  }

  [dir='ltr'] .md\:ltr\:-ml-4xl,[dir='ltr'].md\:ltr\:-ml-4xl {
    margin-left: -144rem
  }

  [dir='ltr'] .md\:ltr\:-mt-5xl,[dir='ltr'].md\:ltr\:-mt-5xl {
    margin-top: -160rem
  }

  [dir='ltr'] .md\:ltr\:-mr-5xl,[dir='ltr'].md\:ltr\:-mr-5xl {
    margin-right: -160rem
  }

  [dir='ltr'] .md\:ltr\:-mb-5xl,[dir='ltr'].md\:ltr\:-mb-5xl {
    margin-bottom: -160rem
  }

  [dir='ltr'] .md\:ltr\:-ml-5xl,[dir='ltr'].md\:ltr\:-ml-5xl {
    margin-left: -160rem
  }

  [dir='ltr'] .md\:ltr\:-mt-px,[dir='ltr'].md\:ltr\:-mt-px {
    margin-top: -1px
  }

  [dir='ltr'] .md\:ltr\:-mr-px,[dir='ltr'].md\:ltr\:-mr-px {
    margin-right: -1px
  }

  [dir='ltr'] .md\:ltr\:-mb-px,[dir='ltr'].md\:ltr\:-mb-px {
    margin-bottom: -1px
  }

  [dir='ltr'] .md\:ltr\:-ml-px,[dir='ltr'].md\:ltr\:-ml-px {
    margin-left: -1px
  }

  [dir='ltr'] .md\:ltr\:-mt-0\.5,[dir='ltr'].md\:ltr\:-mt-0\.5 {
    margin-top: -0.05rem
  }

  [dir='ltr'] .md\:ltr\:-mr-0\.5,[dir='ltr'].md\:ltr\:-mr-0\.5 {
    margin-right: -0.05rem
  }

  [dir='ltr'] .md\:ltr\:-mb-0\.5,[dir='ltr'].md\:ltr\:-mb-0\.5 {
    margin-bottom: -0.05rem
  }

  [dir='ltr'] .md\:ltr\:-ml-0\.5,[dir='ltr'].md\:ltr\:-ml-0\.5 {
    margin-left: -0.05rem
  }

  [dir='ltr'] .md\:ltr\:-mt-1\.5,[dir='ltr'].md\:ltr\:-mt-1\.5 {
    margin-top: -0.15rem
  }

  [dir='ltr'] .md\:ltr\:-mr-1\.5,[dir='ltr'].md\:ltr\:-mr-1\.5 {
    margin-right: -0.15rem
  }

  [dir='ltr'] .md\:ltr\:-mb-1\.5,[dir='ltr'].md\:ltr\:-mb-1\.5 {
    margin-bottom: -0.15rem
  }

  [dir='ltr'] .md\:ltr\:-ml-1\.5,[dir='ltr'].md\:ltr\:-ml-1\.5 {
    margin-left: -0.15rem
  }

  [dir='ltr'] .md\:ltr\:-mt-2\.5,[dir='ltr'].md\:ltr\:-mt-2\.5 {
    margin-top: -0.25rem
  }

  [dir='ltr'] .md\:ltr\:-mr-2\.5,[dir='ltr'].md\:ltr\:-mr-2\.5 {
    margin-right: -0.25rem
  }

  [dir='ltr'] .md\:ltr\:-mb-2\.5,[dir='ltr'].md\:ltr\:-mb-2\.5 {
    margin-bottom: -0.25rem
  }

  [dir='ltr'] .md\:ltr\:-ml-2\.5,[dir='ltr'].md\:ltr\:-ml-2\.5 {
    margin-left: -0.25rem
  }

  [dir='ltr'] .md\:ltr\:-mt-3\.5,[dir='ltr'].md\:ltr\:-mt-3\.5 {
    margin-top: -0.35rem
  }

  [dir='ltr'] .md\:ltr\:-mr-3\.5,[dir='ltr'].md\:ltr\:-mr-3\.5 {
    margin-right: -0.35rem
  }

  [dir='ltr'] .md\:ltr\:-mb-3\.5,[dir='ltr'].md\:ltr\:-mb-3\.5 {
    margin-bottom: -0.35rem
  }

  [dir='ltr'] .md\:ltr\:-ml-3\.5,[dir='ltr'].md\:ltr\:-ml-3\.5 {
    margin-left: -0.35rem
  }

  [dir='rtl'] .md\:rtl\:m-0,[dir='rtl'].md\:rtl\:m-0 {
    margin: 0
  }

  [dir='rtl'] .md\:rtl\:m-1,[dir='rtl'].md\:rtl\:m-1 {
    margin: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:m-2,[dir='rtl'].md\:rtl\:m-2 {
    margin: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:m-3,[dir='rtl'].md\:rtl\:m-3 {
    margin: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:m-4,[dir='rtl'].md\:rtl\:m-4 {
    margin: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:m-5,[dir='rtl'].md\:rtl\:m-5 {
    margin: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:m-6,[dir='rtl'].md\:rtl\:m-6 {
    margin: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:m-7,[dir='rtl'].md\:rtl\:m-7 {
    margin: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:m-8,[dir='rtl'].md\:rtl\:m-8 {
    margin: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:m-9,[dir='rtl'].md\:rtl\:m-9 {
    margin: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:m-10,[dir='rtl'].md\:rtl\:m-10 {
    margin: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:m-12,[dir='rtl'].md\:rtl\:m-12 {
    margin: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:m-14,[dir='rtl'].md\:rtl\:m-14 {
    margin: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:m-16,[dir='rtl'].md\:rtl\:m-16 {
    margin: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:m-20,[dir='rtl'].md\:rtl\:m-20 {
    margin: 2rem
  }

  [dir='rtl'] .md\:rtl\:m-24,[dir='rtl'].md\:rtl\:m-24 {
    margin: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:m-28,[dir='rtl'].md\:rtl\:m-28 {
    margin: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:m-32,[dir='rtl'].md\:rtl\:m-32 {
    margin: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:m-36,[dir='rtl'].md\:rtl\:m-36 {
    margin: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:m-40,[dir='rtl'].md\:rtl\:m-40 {
    margin: 4rem
  }

  [dir='rtl'] .md\:rtl\:m-44,[dir='rtl'].md\:rtl\:m-44 {
    margin: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:m-48,[dir='rtl'].md\:rtl\:m-48 {
    margin: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:m-52,[dir='rtl'].md\:rtl\:m-52 {
    margin: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:m-56,[dir='rtl'].md\:rtl\:m-56 {
    margin: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:m-60,[dir='rtl'].md\:rtl\:m-60 {
    margin: 6rem
  }

  [dir='rtl'] .md\:rtl\:m-64,[dir='rtl'].md\:rtl\:m-64 {
    margin: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:m-68,[dir='rtl'].md\:rtl\:m-68 {
    margin: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:m-72,[dir='rtl'].md\:rtl\:m-72 {
    margin: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:m-76,[dir='rtl'].md\:rtl\:m-76 {
    margin: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:m-80,[dir='rtl'].md\:rtl\:m-80 {
    margin: 8rem
  }

  [dir='rtl'] .md\:rtl\:m-84,[dir='rtl'].md\:rtl\:m-84 {
    margin: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:m-88,[dir='rtl'].md\:rtl\:m-88 {
    margin: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:m-92,[dir='rtl'].md\:rtl\:m-92 {
    margin: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:m-96,[dir='rtl'].md\:rtl\:m-96 {
    margin: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:m-128,[dir='rtl'].md\:rtl\:m-128 {
    margin: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:m-136,[dir='rtl'].md\:rtl\:m-136 {
    margin: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:m-160,[dir='rtl'].md\:rtl\:m-160 {
    margin: 16rem
  }

  [dir='rtl'] .md\:rtl\:m-192,[dir='rtl'].md\:rtl\:m-192 {
    margin: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:m-200,[dir='rtl'].md\:rtl\:m-200 {
    margin: 20rem
  }

  [dir='rtl'] .md\:rtl\:m-208,[dir='rtl'].md\:rtl\:m-208 {
    margin: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:m-216,[dir='rtl'].md\:rtl\:m-216 {
    margin: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:m-224,[dir='rtl'].md\:rtl\:m-224 {
    margin: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:m-256,[dir='rtl'].md\:rtl\:m-256 {
    margin: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:m-288,[dir='rtl'].md\:rtl\:m-288 {
    margin: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:m-320,[dir='rtl'].md\:rtl\:m-320 {
    margin: 32rem
  }

  [dir='rtl'] .md\:rtl\:m-360,[dir='rtl'].md\:rtl\:m-360 {
    margin: 36rem
  }

  [dir='rtl'] .md\:rtl\:m-384,[dir='rtl'].md\:rtl\:m-384 {
    margin: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:m-400,[dir='rtl'].md\:rtl\:m-400 {
    margin: 40rem
  }

  [dir='rtl'] .md\:rtl\:m-512,[dir='rtl'].md\:rtl\:m-512 {
    margin: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:m-640,[dir='rtl'].md\:rtl\:m-640 {
    margin: 64rem
  }

  [dir='rtl'] .md\:rtl\:m-auto,[dir='rtl'].md\:rtl\:m-auto {
    margin: auto
  }

  [dir='rtl'] .md\:rtl\:m-xs,[dir='rtl'].md\:rtl\:m-xs {
    margin: 32rem
  }

  [dir='rtl'] .md\:rtl\:m-sm,[dir='rtl'].md\:rtl\:m-sm {
    margin: 48rem
  }

  [dir='rtl'] .md\:rtl\:m-md,[dir='rtl'].md\:rtl\:m-md {
    margin: 64rem
  }

  [dir='rtl'] .md\:rtl\:m-lg,[dir='rtl'].md\:rtl\:m-lg {
    margin: 80rem
  }

  [dir='rtl'] .md\:rtl\:m-xl,[dir='rtl'].md\:rtl\:m-xl {
    margin: 96rem
  }

  [dir='rtl'] .md\:rtl\:m-2xl,[dir='rtl'].md\:rtl\:m-2xl {
    margin: 112rem
  }

  [dir='rtl'] .md\:rtl\:m-3xl,[dir='rtl'].md\:rtl\:m-3xl {
    margin: 128rem
  }

  [dir='rtl'] .md\:rtl\:m-4xl,[dir='rtl'].md\:rtl\:m-4xl {
    margin: 144rem
  }

  [dir='rtl'] .md\:rtl\:m-5xl,[dir='rtl'].md\:rtl\:m-5xl {
    margin: 160rem
  }

  [dir='rtl'] .md\:rtl\:m-px,[dir='rtl'].md\:rtl\:m-px {
    margin: 1px
  }

  [dir='rtl'] .md\:rtl\:m-0\.5,[dir='rtl'].md\:rtl\:m-0\.5 {
    margin: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:m-1\.5,[dir='rtl'].md\:rtl\:m-1\.5 {
    margin: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:m-2\.5,[dir='rtl'].md\:rtl\:m-2\.5 {
    margin: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:m-3\.5,[dir='rtl'].md\:rtl\:m-3\.5 {
    margin: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:-m-1,[dir='rtl'].md\:rtl\:-m-1 {
    margin: -0.1rem
  }

  [dir='rtl'] .md\:rtl\:-m-2,[dir='rtl'].md\:rtl\:-m-2 {
    margin: -0.2rem
  }

  [dir='rtl'] .md\:rtl\:-m-3,[dir='rtl'].md\:rtl\:-m-3 {
    margin: -0.3rem
  }

  [dir='rtl'] .md\:rtl\:-m-4,[dir='rtl'].md\:rtl\:-m-4 {
    margin: -0.4rem
  }

  [dir='rtl'] .md\:rtl\:-m-5,[dir='rtl'].md\:rtl\:-m-5 {
    margin: -0.5rem
  }

  [dir='rtl'] .md\:rtl\:-m-6,[dir='rtl'].md\:rtl\:-m-6 {
    margin: -0.6rem
  }

  [dir='rtl'] .md\:rtl\:-m-7,[dir='rtl'].md\:rtl\:-m-7 {
    margin: -0.7rem
  }

  [dir='rtl'] .md\:rtl\:-m-8,[dir='rtl'].md\:rtl\:-m-8 {
    margin: -0.8rem
  }

  [dir='rtl'] .md\:rtl\:-m-9,[dir='rtl'].md\:rtl\:-m-9 {
    margin: -0.9rem
  }

  [dir='rtl'] .md\:rtl\:-m-10,[dir='rtl'].md\:rtl\:-m-10 {
    margin: -1rem
  }

  [dir='rtl'] .md\:rtl\:-m-12,[dir='rtl'].md\:rtl\:-m-12 {
    margin: -1.2rem
  }

  [dir='rtl'] .md\:rtl\:-m-14,[dir='rtl'].md\:rtl\:-m-14 {
    margin: -1.4rem
  }

  [dir='rtl'] .md\:rtl\:-m-16,[dir='rtl'].md\:rtl\:-m-16 {
    margin: -1.6rem
  }

  [dir='rtl'] .md\:rtl\:-m-20,[dir='rtl'].md\:rtl\:-m-20 {
    margin: -2rem
  }

  [dir='rtl'] .md\:rtl\:-m-24,[dir='rtl'].md\:rtl\:-m-24 {
    margin: -2.4rem
  }

  [dir='rtl'] .md\:rtl\:-m-28,[dir='rtl'].md\:rtl\:-m-28 {
    margin: -2.8rem
  }

  [dir='rtl'] .md\:rtl\:-m-32,[dir='rtl'].md\:rtl\:-m-32 {
    margin: -3.2rem
  }

  [dir='rtl'] .md\:rtl\:-m-36,[dir='rtl'].md\:rtl\:-m-36 {
    margin: -3.6rem
  }

  [dir='rtl'] .md\:rtl\:-m-40,[dir='rtl'].md\:rtl\:-m-40 {
    margin: -4rem
  }

  [dir='rtl'] .md\:rtl\:-m-44,[dir='rtl'].md\:rtl\:-m-44 {
    margin: -4.4rem
  }

  [dir='rtl'] .md\:rtl\:-m-48,[dir='rtl'].md\:rtl\:-m-48 {
    margin: -4.8rem
  }

  [dir='rtl'] .md\:rtl\:-m-52,[dir='rtl'].md\:rtl\:-m-52 {
    margin: -5.2rem
  }

  [dir='rtl'] .md\:rtl\:-m-56,[dir='rtl'].md\:rtl\:-m-56 {
    margin: -5.6rem
  }

  [dir='rtl'] .md\:rtl\:-m-60,[dir='rtl'].md\:rtl\:-m-60 {
    margin: -6rem
  }

  [dir='rtl'] .md\:rtl\:-m-64,[dir='rtl'].md\:rtl\:-m-64 {
    margin: -6.4rem
  }

  [dir='rtl'] .md\:rtl\:-m-68,[dir='rtl'].md\:rtl\:-m-68 {
    margin: -6.8rem
  }

  [dir='rtl'] .md\:rtl\:-m-72,[dir='rtl'].md\:rtl\:-m-72 {
    margin: -7.2rem
  }

  [dir='rtl'] .md\:rtl\:-m-76,[dir='rtl'].md\:rtl\:-m-76 {
    margin: -7.6rem
  }

  [dir='rtl'] .md\:rtl\:-m-80,[dir='rtl'].md\:rtl\:-m-80 {
    margin: -8rem
  }

  [dir='rtl'] .md\:rtl\:-m-84,[dir='rtl'].md\:rtl\:-m-84 {
    margin: -8.4rem
  }

  [dir='rtl'] .md\:rtl\:-m-88,[dir='rtl'].md\:rtl\:-m-88 {
    margin: -8.8rem
  }

  [dir='rtl'] .md\:rtl\:-m-92,[dir='rtl'].md\:rtl\:-m-92 {
    margin: -9.2rem
  }

  [dir='rtl'] .md\:rtl\:-m-96,[dir='rtl'].md\:rtl\:-m-96 {
    margin: -9.6rem
  }

  [dir='rtl'] .md\:rtl\:-m-128,[dir='rtl'].md\:rtl\:-m-128 {
    margin: -12.8rem
  }

  [dir='rtl'] .md\:rtl\:-m-136,[dir='rtl'].md\:rtl\:-m-136 {
    margin: -13.6rem
  }

  [dir='rtl'] .md\:rtl\:-m-160,[dir='rtl'].md\:rtl\:-m-160 {
    margin: -16rem
  }

  [dir='rtl'] .md\:rtl\:-m-192,[dir='rtl'].md\:rtl\:-m-192 {
    margin: -19.2rem
  }

  [dir='rtl'] .md\:rtl\:-m-200,[dir='rtl'].md\:rtl\:-m-200 {
    margin: -20rem
  }

  [dir='rtl'] .md\:rtl\:-m-208,[dir='rtl'].md\:rtl\:-m-208 {
    margin: -20.8rem
  }

  [dir='rtl'] .md\:rtl\:-m-216,[dir='rtl'].md\:rtl\:-m-216 {
    margin: -21.6rem
  }

  [dir='rtl'] .md\:rtl\:-m-224,[dir='rtl'].md\:rtl\:-m-224 {
    margin: -22.4rem
  }

  [dir='rtl'] .md\:rtl\:-m-256,[dir='rtl'].md\:rtl\:-m-256 {
    margin: -25.6rem
  }

  [dir='rtl'] .md\:rtl\:-m-288,[dir='rtl'].md\:rtl\:-m-288 {
    margin: -28.8rem
  }

  [dir='rtl'] .md\:rtl\:-m-320,[dir='rtl'].md\:rtl\:-m-320 {
    margin: -32rem
  }

  [dir='rtl'] .md\:rtl\:-m-360,[dir='rtl'].md\:rtl\:-m-360 {
    margin: -36rem
  }

  [dir='rtl'] .md\:rtl\:-m-384,[dir='rtl'].md\:rtl\:-m-384 {
    margin: -38.4rem
  }

  [dir='rtl'] .md\:rtl\:-m-400,[dir='rtl'].md\:rtl\:-m-400 {
    margin: -40rem
  }

  [dir='rtl'] .md\:rtl\:-m-512,[dir='rtl'].md\:rtl\:-m-512 {
    margin: -51.2rem
  }

  [dir='rtl'] .md\:rtl\:-m-640,[dir='rtl'].md\:rtl\:-m-640 {
    margin: -64rem
  }

  [dir='rtl'] .md\:rtl\:-m-xs,[dir='rtl'].md\:rtl\:-m-xs {
    margin: -32rem
  }

  [dir='rtl'] .md\:rtl\:-m-sm,[dir='rtl'].md\:rtl\:-m-sm {
    margin: -48rem
  }

  [dir='rtl'] .md\:rtl\:-m-md,[dir='rtl'].md\:rtl\:-m-md {
    margin: -64rem
  }

  [dir='rtl'] .md\:rtl\:-m-lg,[dir='rtl'].md\:rtl\:-m-lg {
    margin: -80rem
  }

  [dir='rtl'] .md\:rtl\:-m-xl,[dir='rtl'].md\:rtl\:-m-xl {
    margin: -96rem
  }

  [dir='rtl'] .md\:rtl\:-m-2xl,[dir='rtl'].md\:rtl\:-m-2xl {
    margin: -112rem
  }

  [dir='rtl'] .md\:rtl\:-m-3xl,[dir='rtl'].md\:rtl\:-m-3xl {
    margin: -128rem
  }

  [dir='rtl'] .md\:rtl\:-m-4xl,[dir='rtl'].md\:rtl\:-m-4xl {
    margin: -144rem
  }

  [dir='rtl'] .md\:rtl\:-m-5xl,[dir='rtl'].md\:rtl\:-m-5xl {
    margin: -160rem
  }

  [dir='rtl'] .md\:rtl\:-m-px,[dir='rtl'].md\:rtl\:-m-px {
    margin: -1px
  }

  [dir='rtl'] .md\:rtl\:-m-0\.5,[dir='rtl'].md\:rtl\:-m-0\.5 {
    margin: -0.05rem
  }

  [dir='rtl'] .md\:rtl\:-m-1\.5,[dir='rtl'].md\:rtl\:-m-1\.5 {
    margin: -0.15rem
  }

  [dir='rtl'] .md\:rtl\:-m-2\.5,[dir='rtl'].md\:rtl\:-m-2\.5 {
    margin: -0.25rem
  }

  [dir='rtl'] .md\:rtl\:-m-3\.5,[dir='rtl'].md\:rtl\:-m-3\.5 {
    margin: -0.35rem
  }

  [dir='rtl'] .md\:rtl\:my-0,[dir='rtl'].md\:rtl\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  [dir='rtl'] .md\:rtl\:mx-0,[dir='rtl'].md\:rtl\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  [dir='rtl'] .md\:rtl\:my-1,[dir='rtl'].md\:rtl\:my-1 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:mx-1,[dir='rtl'].md\:rtl\:mx-1 {
    margin-left: 0.1rem;
    margin-right: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:my-2,[dir='rtl'].md\:rtl\:my-2 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:mx-2,[dir='rtl'].md\:rtl\:mx-2 {
    margin-left: 0.2rem;
    margin-right: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:my-3,[dir='rtl'].md\:rtl\:my-3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:mx-3,[dir='rtl'].md\:rtl\:mx-3 {
    margin-left: 0.3rem;
    margin-right: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:my-4,[dir='rtl'].md\:rtl\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:mx-4,[dir='rtl'].md\:rtl\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:my-5,[dir='rtl'].md\:rtl\:my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:mx-5,[dir='rtl'].md\:rtl\:mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:my-6,[dir='rtl'].md\:rtl\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:mx-6,[dir='rtl'].md\:rtl\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:my-7,[dir='rtl'].md\:rtl\:my-7 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:mx-7,[dir='rtl'].md\:rtl\:mx-7 {
    margin-left: 0.7rem;
    margin-right: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:my-8,[dir='rtl'].md\:rtl\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:mx-8,[dir='rtl'].md\:rtl\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:my-9,[dir='rtl'].md\:rtl\:my-9 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:mx-9,[dir='rtl'].md\:rtl\:mx-9 {
    margin-left: 0.9rem;
    margin-right: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:my-10,[dir='rtl'].md\:rtl\:my-10 {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:mx-10,[dir='rtl'].md\:rtl\:mx-10 {
    margin-left: 1.0rem;
    margin-right: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:my-12,[dir='rtl'].md\:rtl\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:mx-12,[dir='rtl'].md\:rtl\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:my-14,[dir='rtl'].md\:rtl\:my-14 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:mx-14,[dir='rtl'].md\:rtl\:mx-14 {
    margin-left: 1.4rem;
    margin-right: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:my-16,[dir='rtl'].md\:rtl\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:mx-16,[dir='rtl'].md\:rtl\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:my-20,[dir='rtl'].md\:rtl\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  [dir='rtl'] .md\:rtl\:mx-20,[dir='rtl'].md\:rtl\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  [dir='rtl'] .md\:rtl\:my-24,[dir='rtl'].md\:rtl\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:mx-24,[dir='rtl'].md\:rtl\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:my-28,[dir='rtl'].md\:rtl\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:mx-28,[dir='rtl'].md\:rtl\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:my-32,[dir='rtl'].md\:rtl\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:mx-32,[dir='rtl'].md\:rtl\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:my-36,[dir='rtl'].md\:rtl\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:mx-36,[dir='rtl'].md\:rtl\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:my-40,[dir='rtl'].md\:rtl\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  [dir='rtl'] .md\:rtl\:mx-40,[dir='rtl'].md\:rtl\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  [dir='rtl'] .md\:rtl\:my-44,[dir='rtl'].md\:rtl\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:mx-44,[dir='rtl'].md\:rtl\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:my-48,[dir='rtl'].md\:rtl\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:mx-48,[dir='rtl'].md\:rtl\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:my-52,[dir='rtl'].md\:rtl\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:mx-52,[dir='rtl'].md\:rtl\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:my-56,[dir='rtl'].md\:rtl\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:mx-56,[dir='rtl'].md\:rtl\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:my-60,[dir='rtl'].md\:rtl\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  [dir='rtl'] .md\:rtl\:mx-60,[dir='rtl'].md\:rtl\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  [dir='rtl'] .md\:rtl\:my-64,[dir='rtl'].md\:rtl\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:mx-64,[dir='rtl'].md\:rtl\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:my-68,[dir='rtl'].md\:rtl\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:mx-68,[dir='rtl'].md\:rtl\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:my-72,[dir='rtl'].md\:rtl\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:mx-72,[dir='rtl'].md\:rtl\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:my-76,[dir='rtl'].md\:rtl\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:mx-76,[dir='rtl'].md\:rtl\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:my-80,[dir='rtl'].md\:rtl\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  [dir='rtl'] .md\:rtl\:mx-80,[dir='rtl'].md\:rtl\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  [dir='rtl'] .md\:rtl\:my-84,[dir='rtl'].md\:rtl\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:mx-84,[dir='rtl'].md\:rtl\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:my-88,[dir='rtl'].md\:rtl\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:mx-88,[dir='rtl'].md\:rtl\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:my-92,[dir='rtl'].md\:rtl\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:mx-92,[dir='rtl'].md\:rtl\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:my-96,[dir='rtl'].md\:rtl\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:mx-96,[dir='rtl'].md\:rtl\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:my-128,[dir='rtl'].md\:rtl\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:mx-128,[dir='rtl'].md\:rtl\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:my-136,[dir='rtl'].md\:rtl\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:mx-136,[dir='rtl'].md\:rtl\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:my-160,[dir='rtl'].md\:rtl\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  [dir='rtl'] .md\:rtl\:mx-160,[dir='rtl'].md\:rtl\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  [dir='rtl'] .md\:rtl\:my-192,[dir='rtl'].md\:rtl\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:mx-192,[dir='rtl'].md\:rtl\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:my-200,[dir='rtl'].md\:rtl\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  [dir='rtl'] .md\:rtl\:mx-200,[dir='rtl'].md\:rtl\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  [dir='rtl'] .md\:rtl\:my-208,[dir='rtl'].md\:rtl\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:mx-208,[dir='rtl'].md\:rtl\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:my-216,[dir='rtl'].md\:rtl\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:mx-216,[dir='rtl'].md\:rtl\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:my-224,[dir='rtl'].md\:rtl\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:mx-224,[dir='rtl'].md\:rtl\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:my-256,[dir='rtl'].md\:rtl\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:mx-256,[dir='rtl'].md\:rtl\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:my-288,[dir='rtl'].md\:rtl\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:mx-288,[dir='rtl'].md\:rtl\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:my-320,[dir='rtl'].md\:rtl\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='rtl'] .md\:rtl\:mx-320,[dir='rtl'].md\:rtl\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='rtl'] .md\:rtl\:my-360,[dir='rtl'].md\:rtl\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  [dir='rtl'] .md\:rtl\:mx-360,[dir='rtl'].md\:rtl\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  [dir='rtl'] .md\:rtl\:my-384,[dir='rtl'].md\:rtl\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:mx-384,[dir='rtl'].md\:rtl\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:my-400,[dir='rtl'].md\:rtl\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  [dir='rtl'] .md\:rtl\:mx-400,[dir='rtl'].md\:rtl\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  [dir='rtl'] .md\:rtl\:my-512,[dir='rtl'].md\:rtl\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:mx-512,[dir='rtl'].md\:rtl\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:my-640,[dir='rtl'].md\:rtl\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='rtl'] .md\:rtl\:mx-640,[dir='rtl'].md\:rtl\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='rtl'] .md\:rtl\:my-auto,[dir='rtl'].md\:rtl\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  [dir='rtl'] .md\:rtl\:mx-auto,[dir='rtl'].md\:rtl\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  [dir='rtl'] .md\:rtl\:my-xs,[dir='rtl'].md\:rtl\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='rtl'] .md\:rtl\:mx-xs,[dir='rtl'].md\:rtl\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='rtl'] .md\:rtl\:my-sm,[dir='rtl'].md\:rtl\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  [dir='rtl'] .md\:rtl\:mx-sm,[dir='rtl'].md\:rtl\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  [dir='rtl'] .md\:rtl\:my-md,[dir='rtl'].md\:rtl\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='rtl'] .md\:rtl\:mx-md,[dir='rtl'].md\:rtl\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='rtl'] .md\:rtl\:my-lg,[dir='rtl'].md\:rtl\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  [dir='rtl'] .md\:rtl\:mx-lg,[dir='rtl'].md\:rtl\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  [dir='rtl'] .md\:rtl\:my-xl,[dir='rtl'].md\:rtl\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  [dir='rtl'] .md\:rtl\:mx-xl,[dir='rtl'].md\:rtl\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  [dir='rtl'] .md\:rtl\:my-2xl,[dir='rtl'].md\:rtl\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  [dir='rtl'] .md\:rtl\:mx-2xl,[dir='rtl'].md\:rtl\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  [dir='rtl'] .md\:rtl\:my-3xl,[dir='rtl'].md\:rtl\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  [dir='rtl'] .md\:rtl\:mx-3xl,[dir='rtl'].md\:rtl\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  [dir='rtl'] .md\:rtl\:my-4xl,[dir='rtl'].md\:rtl\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  [dir='rtl'] .md\:rtl\:mx-4xl,[dir='rtl'].md\:rtl\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  [dir='rtl'] .md\:rtl\:my-5xl,[dir='rtl'].md\:rtl\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  [dir='rtl'] .md\:rtl\:mx-5xl,[dir='rtl'].md\:rtl\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  [dir='rtl'] .md\:rtl\:my-px,[dir='rtl'].md\:rtl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  [dir='rtl'] .md\:rtl\:mx-px,[dir='rtl'].md\:rtl\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  [dir='rtl'] .md\:rtl\:my-0\.5,[dir='rtl'].md\:rtl\:my-0\.5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:mx-0\.5,[dir='rtl'].md\:rtl\:mx-0\.5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:my-1\.5,[dir='rtl'].md\:rtl\:my-1\.5 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:mx-1\.5,[dir='rtl'].md\:rtl\:mx-1\.5 {
    margin-left: 0.15rem;
    margin-right: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:my-2\.5,[dir='rtl'].md\:rtl\:my-2\.5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:mx-2\.5,[dir='rtl'].md\:rtl\:mx-2\.5 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:my-3\.5,[dir='rtl'].md\:rtl\:my-3\.5 {
    margin-top: 0.35rem;
    margin-bottom: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:mx-3\.5,[dir='rtl'].md\:rtl\:mx-3\.5 {
    margin-left: 0.35rem;
    margin-right: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:-my-1,[dir='rtl'].md\:rtl\:-my-1 {
    margin-top: -0.1rem;
    margin-bottom: -0.1rem
  }

  [dir='rtl'] .md\:rtl\:-mx-1,[dir='rtl'].md\:rtl\:-mx-1 {
    margin-left: -0.1rem;
    margin-right: -0.1rem
  }

  [dir='rtl'] .md\:rtl\:-my-2,[dir='rtl'].md\:rtl\:-my-2 {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem
  }

  [dir='rtl'] .md\:rtl\:-mx-2,[dir='rtl'].md\:rtl\:-mx-2 {
    margin-left: -0.2rem;
    margin-right: -0.2rem
  }

  [dir='rtl'] .md\:rtl\:-my-3,[dir='rtl'].md\:rtl\:-my-3 {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem
  }

  [dir='rtl'] .md\:rtl\:-mx-3,[dir='rtl'].md\:rtl\:-mx-3 {
    margin-left: -0.3rem;
    margin-right: -0.3rem
  }

  [dir='rtl'] .md\:rtl\:-my-4,[dir='rtl'].md\:rtl\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  [dir='rtl'] .md\:rtl\:-mx-4,[dir='rtl'].md\:rtl\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  [dir='rtl'] .md\:rtl\:-my-5,[dir='rtl'].md\:rtl\:-my-5 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  [dir='rtl'] .md\:rtl\:-mx-5,[dir='rtl'].md\:rtl\:-mx-5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  [dir='rtl'] .md\:rtl\:-my-6,[dir='rtl'].md\:rtl\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  [dir='rtl'] .md\:rtl\:-mx-6,[dir='rtl'].md\:rtl\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  [dir='rtl'] .md\:rtl\:-my-7,[dir='rtl'].md\:rtl\:-my-7 {
    margin-top: -0.7rem;
    margin-bottom: -0.7rem
  }

  [dir='rtl'] .md\:rtl\:-mx-7,[dir='rtl'].md\:rtl\:-mx-7 {
    margin-left: -0.7rem;
    margin-right: -0.7rem
  }

  [dir='rtl'] .md\:rtl\:-my-8,[dir='rtl'].md\:rtl\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  [dir='rtl'] .md\:rtl\:-mx-8,[dir='rtl'].md\:rtl\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  [dir='rtl'] .md\:rtl\:-my-9,[dir='rtl'].md\:rtl\:-my-9 {
    margin-top: -0.9rem;
    margin-bottom: -0.9rem
  }

  [dir='rtl'] .md\:rtl\:-mx-9,[dir='rtl'].md\:rtl\:-mx-9 {
    margin-left: -0.9rem;
    margin-right: -0.9rem
  }

  [dir='rtl'] .md\:rtl\:-my-10,[dir='rtl'].md\:rtl\:-my-10 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  [dir='rtl'] .md\:rtl\:-mx-10,[dir='rtl'].md\:rtl\:-mx-10 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  [dir='rtl'] .md\:rtl\:-my-12,[dir='rtl'].md\:rtl\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  [dir='rtl'] .md\:rtl\:-mx-12,[dir='rtl'].md\:rtl\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  [dir='rtl'] .md\:rtl\:-my-14,[dir='rtl'].md\:rtl\:-my-14 {
    margin-top: -1.4rem;
    margin-bottom: -1.4rem
  }

  [dir='rtl'] .md\:rtl\:-mx-14,[dir='rtl'].md\:rtl\:-mx-14 {
    margin-left: -1.4rem;
    margin-right: -1.4rem
  }

  [dir='rtl'] .md\:rtl\:-my-16,[dir='rtl'].md\:rtl\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  [dir='rtl'] .md\:rtl\:-mx-16,[dir='rtl'].md\:rtl\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  [dir='rtl'] .md\:rtl\:-my-20,[dir='rtl'].md\:rtl\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  [dir='rtl'] .md\:rtl\:-mx-20,[dir='rtl'].md\:rtl\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  [dir='rtl'] .md\:rtl\:-my-24,[dir='rtl'].md\:rtl\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  [dir='rtl'] .md\:rtl\:-mx-24,[dir='rtl'].md\:rtl\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  [dir='rtl'] .md\:rtl\:-my-28,[dir='rtl'].md\:rtl\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  [dir='rtl'] .md\:rtl\:-mx-28,[dir='rtl'].md\:rtl\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  [dir='rtl'] .md\:rtl\:-my-32,[dir='rtl'].md\:rtl\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  [dir='rtl'] .md\:rtl\:-mx-32,[dir='rtl'].md\:rtl\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  [dir='rtl'] .md\:rtl\:-my-36,[dir='rtl'].md\:rtl\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  [dir='rtl'] .md\:rtl\:-mx-36,[dir='rtl'].md\:rtl\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  [dir='rtl'] .md\:rtl\:-my-40,[dir='rtl'].md\:rtl\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  [dir='rtl'] .md\:rtl\:-mx-40,[dir='rtl'].md\:rtl\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  [dir='rtl'] .md\:rtl\:-my-44,[dir='rtl'].md\:rtl\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  [dir='rtl'] .md\:rtl\:-mx-44,[dir='rtl'].md\:rtl\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  [dir='rtl'] .md\:rtl\:-my-48,[dir='rtl'].md\:rtl\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  [dir='rtl'] .md\:rtl\:-mx-48,[dir='rtl'].md\:rtl\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  [dir='rtl'] .md\:rtl\:-my-52,[dir='rtl'].md\:rtl\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  [dir='rtl'] .md\:rtl\:-mx-52,[dir='rtl'].md\:rtl\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  [dir='rtl'] .md\:rtl\:-my-56,[dir='rtl'].md\:rtl\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  [dir='rtl'] .md\:rtl\:-mx-56,[dir='rtl'].md\:rtl\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  [dir='rtl'] .md\:rtl\:-my-60,[dir='rtl'].md\:rtl\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  [dir='rtl'] .md\:rtl\:-mx-60,[dir='rtl'].md\:rtl\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  [dir='rtl'] .md\:rtl\:-my-64,[dir='rtl'].md\:rtl\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  [dir='rtl'] .md\:rtl\:-mx-64,[dir='rtl'].md\:rtl\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  [dir='rtl'] .md\:rtl\:-my-68,[dir='rtl'].md\:rtl\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  [dir='rtl'] .md\:rtl\:-mx-68,[dir='rtl'].md\:rtl\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  [dir='rtl'] .md\:rtl\:-my-72,[dir='rtl'].md\:rtl\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  [dir='rtl'] .md\:rtl\:-mx-72,[dir='rtl'].md\:rtl\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  [dir='rtl'] .md\:rtl\:-my-76,[dir='rtl'].md\:rtl\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  [dir='rtl'] .md\:rtl\:-mx-76,[dir='rtl'].md\:rtl\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  [dir='rtl'] .md\:rtl\:-my-80,[dir='rtl'].md\:rtl\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  [dir='rtl'] .md\:rtl\:-mx-80,[dir='rtl'].md\:rtl\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  [dir='rtl'] .md\:rtl\:-my-84,[dir='rtl'].md\:rtl\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  [dir='rtl'] .md\:rtl\:-mx-84,[dir='rtl'].md\:rtl\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  [dir='rtl'] .md\:rtl\:-my-88,[dir='rtl'].md\:rtl\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  [dir='rtl'] .md\:rtl\:-mx-88,[dir='rtl'].md\:rtl\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  [dir='rtl'] .md\:rtl\:-my-92,[dir='rtl'].md\:rtl\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  [dir='rtl'] .md\:rtl\:-mx-92,[dir='rtl'].md\:rtl\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  [dir='rtl'] .md\:rtl\:-my-96,[dir='rtl'].md\:rtl\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  [dir='rtl'] .md\:rtl\:-mx-96,[dir='rtl'].md\:rtl\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  [dir='rtl'] .md\:rtl\:-my-128,[dir='rtl'].md\:rtl\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  [dir='rtl'] .md\:rtl\:-mx-128,[dir='rtl'].md\:rtl\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  [dir='rtl'] .md\:rtl\:-my-136,[dir='rtl'].md\:rtl\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  [dir='rtl'] .md\:rtl\:-mx-136,[dir='rtl'].md\:rtl\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  [dir='rtl'] .md\:rtl\:-my-160,[dir='rtl'].md\:rtl\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  [dir='rtl'] .md\:rtl\:-mx-160,[dir='rtl'].md\:rtl\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  [dir='rtl'] .md\:rtl\:-my-192,[dir='rtl'].md\:rtl\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  [dir='rtl'] .md\:rtl\:-mx-192,[dir='rtl'].md\:rtl\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  [dir='rtl'] .md\:rtl\:-my-200,[dir='rtl'].md\:rtl\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  [dir='rtl'] .md\:rtl\:-mx-200,[dir='rtl'].md\:rtl\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  [dir='rtl'] .md\:rtl\:-my-208,[dir='rtl'].md\:rtl\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  [dir='rtl'] .md\:rtl\:-mx-208,[dir='rtl'].md\:rtl\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  [dir='rtl'] .md\:rtl\:-my-216,[dir='rtl'].md\:rtl\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  [dir='rtl'] .md\:rtl\:-mx-216,[dir='rtl'].md\:rtl\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  [dir='rtl'] .md\:rtl\:-my-224,[dir='rtl'].md\:rtl\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  [dir='rtl'] .md\:rtl\:-mx-224,[dir='rtl'].md\:rtl\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  [dir='rtl'] .md\:rtl\:-my-256,[dir='rtl'].md\:rtl\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  [dir='rtl'] .md\:rtl\:-mx-256,[dir='rtl'].md\:rtl\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  [dir='rtl'] .md\:rtl\:-my-288,[dir='rtl'].md\:rtl\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  [dir='rtl'] .md\:rtl\:-mx-288,[dir='rtl'].md\:rtl\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  [dir='rtl'] .md\:rtl\:-my-320,[dir='rtl'].md\:rtl\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='rtl'] .md\:rtl\:-mx-320,[dir='rtl'].md\:rtl\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='rtl'] .md\:rtl\:-my-360,[dir='rtl'].md\:rtl\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  [dir='rtl'] .md\:rtl\:-mx-360,[dir='rtl'].md\:rtl\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  [dir='rtl'] .md\:rtl\:-my-384,[dir='rtl'].md\:rtl\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  [dir='rtl'] .md\:rtl\:-mx-384,[dir='rtl'].md\:rtl\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  [dir='rtl'] .md\:rtl\:-my-400,[dir='rtl'].md\:rtl\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  [dir='rtl'] .md\:rtl\:-mx-400,[dir='rtl'].md\:rtl\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  [dir='rtl'] .md\:rtl\:-my-512,[dir='rtl'].md\:rtl\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  [dir='rtl'] .md\:rtl\:-mx-512,[dir='rtl'].md\:rtl\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  [dir='rtl'] .md\:rtl\:-my-640,[dir='rtl'].md\:rtl\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='rtl'] .md\:rtl\:-mx-640,[dir='rtl'].md\:rtl\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='rtl'] .md\:rtl\:-my-xs,[dir='rtl'].md\:rtl\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='rtl'] .md\:rtl\:-mx-xs,[dir='rtl'].md\:rtl\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='rtl'] .md\:rtl\:-my-sm,[dir='rtl'].md\:rtl\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  [dir='rtl'] .md\:rtl\:-mx-sm,[dir='rtl'].md\:rtl\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  [dir='rtl'] .md\:rtl\:-my-md,[dir='rtl'].md\:rtl\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='rtl'] .md\:rtl\:-mx-md,[dir='rtl'].md\:rtl\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='rtl'] .md\:rtl\:-my-lg,[dir='rtl'].md\:rtl\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  [dir='rtl'] .md\:rtl\:-mx-lg,[dir='rtl'].md\:rtl\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  [dir='rtl'] .md\:rtl\:-my-xl,[dir='rtl'].md\:rtl\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  [dir='rtl'] .md\:rtl\:-mx-xl,[dir='rtl'].md\:rtl\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  [dir='rtl'] .md\:rtl\:-my-2xl,[dir='rtl'].md\:rtl\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  [dir='rtl'] .md\:rtl\:-mx-2xl,[dir='rtl'].md\:rtl\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  [dir='rtl'] .md\:rtl\:-my-3xl,[dir='rtl'].md\:rtl\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  [dir='rtl'] .md\:rtl\:-mx-3xl,[dir='rtl'].md\:rtl\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  [dir='rtl'] .md\:rtl\:-my-4xl,[dir='rtl'].md\:rtl\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  [dir='rtl'] .md\:rtl\:-mx-4xl,[dir='rtl'].md\:rtl\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  [dir='rtl'] .md\:rtl\:-my-5xl,[dir='rtl'].md\:rtl\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  [dir='rtl'] .md\:rtl\:-mx-5xl,[dir='rtl'].md\:rtl\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  [dir='rtl'] .md\:rtl\:-my-px,[dir='rtl'].md\:rtl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  [dir='rtl'] .md\:rtl\:-mx-px,[dir='rtl'].md\:rtl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  [dir='rtl'] .md\:rtl\:-my-0\.5,[dir='rtl'].md\:rtl\:-my-0\.5 {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem
  }

  [dir='rtl'] .md\:rtl\:-mx-0\.5,[dir='rtl'].md\:rtl\:-mx-0\.5 {
    margin-left: -0.05rem;
    margin-right: -0.05rem
  }

  [dir='rtl'] .md\:rtl\:-my-1\.5,[dir='rtl'].md\:rtl\:-my-1\.5 {
    margin-top: -0.15rem;
    margin-bottom: -0.15rem
  }

  [dir='rtl'] .md\:rtl\:-mx-1\.5,[dir='rtl'].md\:rtl\:-mx-1\.5 {
    margin-left: -0.15rem;
    margin-right: -0.15rem
  }

  [dir='rtl'] .md\:rtl\:-my-2\.5,[dir='rtl'].md\:rtl\:-my-2\.5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  [dir='rtl'] .md\:rtl\:-mx-2\.5,[dir='rtl'].md\:rtl\:-mx-2\.5 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  [dir='rtl'] .md\:rtl\:-my-3\.5,[dir='rtl'].md\:rtl\:-my-3\.5 {
    margin-top: -0.35rem;
    margin-bottom: -0.35rem
  }

  [dir='rtl'] .md\:rtl\:-mx-3\.5,[dir='rtl'].md\:rtl\:-mx-3\.5 {
    margin-left: -0.35rem;
    margin-right: -0.35rem
  }

  [dir='rtl'] .md\:rtl\:mt-0,[dir='rtl'].md\:rtl\:mt-0 {
    margin-top: 0
  }

  [dir='rtl'] .md\:rtl\:mr-0,[dir='rtl'].md\:rtl\:mr-0 {
    margin-right: 0
  }

  [dir='rtl'] .md\:rtl\:mb-0,[dir='rtl'].md\:rtl\:mb-0 {
    margin-bottom: 0
  }

  [dir='rtl'] .md\:rtl\:ml-0,[dir='rtl'].md\:rtl\:ml-0 {
    margin-left: 0
  }

  [dir='rtl'] .md\:rtl\:mt-1,[dir='rtl'].md\:rtl\:mt-1 {
    margin-top: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:mr-1,[dir='rtl'].md\:rtl\:mr-1 {
    margin-right: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:mb-1,[dir='rtl'].md\:rtl\:mb-1 {
    margin-bottom: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:ml-1,[dir='rtl'].md\:rtl\:ml-1 {
    margin-left: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:mt-2,[dir='rtl'].md\:rtl\:mt-2 {
    margin-top: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:mr-2,[dir='rtl'].md\:rtl\:mr-2 {
    margin-right: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:mb-2,[dir='rtl'].md\:rtl\:mb-2 {
    margin-bottom: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:ml-2,[dir='rtl'].md\:rtl\:ml-2 {
    margin-left: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:mt-3,[dir='rtl'].md\:rtl\:mt-3 {
    margin-top: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:mr-3,[dir='rtl'].md\:rtl\:mr-3 {
    margin-right: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:mb-3,[dir='rtl'].md\:rtl\:mb-3 {
    margin-bottom: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:ml-3,[dir='rtl'].md\:rtl\:ml-3 {
    margin-left: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:mt-4,[dir='rtl'].md\:rtl\:mt-4 {
    margin-top: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:mr-4,[dir='rtl'].md\:rtl\:mr-4 {
    margin-right: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:mb-4,[dir='rtl'].md\:rtl\:mb-4 {
    margin-bottom: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:ml-4,[dir='rtl'].md\:rtl\:ml-4 {
    margin-left: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:mt-5,[dir='rtl'].md\:rtl\:mt-5 {
    margin-top: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:mr-5,[dir='rtl'].md\:rtl\:mr-5 {
    margin-right: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:mb-5,[dir='rtl'].md\:rtl\:mb-5 {
    margin-bottom: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:ml-5,[dir='rtl'].md\:rtl\:ml-5 {
    margin-left: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:mt-6,[dir='rtl'].md\:rtl\:mt-6 {
    margin-top: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:mr-6,[dir='rtl'].md\:rtl\:mr-6 {
    margin-right: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:mb-6,[dir='rtl'].md\:rtl\:mb-6 {
    margin-bottom: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:ml-6,[dir='rtl'].md\:rtl\:ml-6 {
    margin-left: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:mt-7,[dir='rtl'].md\:rtl\:mt-7 {
    margin-top: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:mr-7,[dir='rtl'].md\:rtl\:mr-7 {
    margin-right: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:mb-7,[dir='rtl'].md\:rtl\:mb-7 {
    margin-bottom: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:ml-7,[dir='rtl'].md\:rtl\:ml-7 {
    margin-left: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:mt-8,[dir='rtl'].md\:rtl\:mt-8 {
    margin-top: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:mr-8,[dir='rtl'].md\:rtl\:mr-8 {
    margin-right: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:mb-8,[dir='rtl'].md\:rtl\:mb-8 {
    margin-bottom: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:ml-8,[dir='rtl'].md\:rtl\:ml-8 {
    margin-left: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:mt-9,[dir='rtl'].md\:rtl\:mt-9 {
    margin-top: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:mr-9,[dir='rtl'].md\:rtl\:mr-9 {
    margin-right: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:mb-9,[dir='rtl'].md\:rtl\:mb-9 {
    margin-bottom: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:ml-9,[dir='rtl'].md\:rtl\:ml-9 {
    margin-left: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:mt-10,[dir='rtl'].md\:rtl\:mt-10 {
    margin-top: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:mr-10,[dir='rtl'].md\:rtl\:mr-10 {
    margin-right: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:mb-10,[dir='rtl'].md\:rtl\:mb-10 {
    margin-bottom: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:ml-10,[dir='rtl'].md\:rtl\:ml-10 {
    margin-left: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:mt-12,[dir='rtl'].md\:rtl\:mt-12 {
    margin-top: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:mr-12,[dir='rtl'].md\:rtl\:mr-12 {
    margin-right: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:mb-12,[dir='rtl'].md\:rtl\:mb-12 {
    margin-bottom: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:ml-12,[dir='rtl'].md\:rtl\:ml-12 {
    margin-left: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:mt-14,[dir='rtl'].md\:rtl\:mt-14 {
    margin-top: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:mr-14,[dir='rtl'].md\:rtl\:mr-14 {
    margin-right: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:mb-14,[dir='rtl'].md\:rtl\:mb-14 {
    margin-bottom: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:ml-14,[dir='rtl'].md\:rtl\:ml-14 {
    margin-left: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:mt-16,[dir='rtl'].md\:rtl\:mt-16 {
    margin-top: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:mr-16,[dir='rtl'].md\:rtl\:mr-16 {
    margin-right: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:mb-16,[dir='rtl'].md\:rtl\:mb-16 {
    margin-bottom: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:ml-16,[dir='rtl'].md\:rtl\:ml-16 {
    margin-left: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:mt-20,[dir='rtl'].md\:rtl\:mt-20 {
    margin-top: 2rem
  }

  [dir='rtl'] .md\:rtl\:mr-20,[dir='rtl'].md\:rtl\:mr-20 {
    margin-right: 2rem
  }

  [dir='rtl'] .md\:rtl\:mb-20,[dir='rtl'].md\:rtl\:mb-20 {
    margin-bottom: 2rem
  }

  [dir='rtl'] .md\:rtl\:ml-20,[dir='rtl'].md\:rtl\:ml-20 {
    margin-left: 2rem
  }

  [dir='rtl'] .md\:rtl\:mt-24,[dir='rtl'].md\:rtl\:mt-24 {
    margin-top: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:mr-24,[dir='rtl'].md\:rtl\:mr-24 {
    margin-right: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:mb-24,[dir='rtl'].md\:rtl\:mb-24 {
    margin-bottom: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:ml-24,[dir='rtl'].md\:rtl\:ml-24 {
    margin-left: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:mt-28,[dir='rtl'].md\:rtl\:mt-28 {
    margin-top: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:mr-28,[dir='rtl'].md\:rtl\:mr-28 {
    margin-right: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:mb-28,[dir='rtl'].md\:rtl\:mb-28 {
    margin-bottom: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:ml-28,[dir='rtl'].md\:rtl\:ml-28 {
    margin-left: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:mt-32,[dir='rtl'].md\:rtl\:mt-32 {
    margin-top: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:mr-32,[dir='rtl'].md\:rtl\:mr-32 {
    margin-right: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:mb-32,[dir='rtl'].md\:rtl\:mb-32 {
    margin-bottom: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:ml-32,[dir='rtl'].md\:rtl\:ml-32 {
    margin-left: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:mt-36,[dir='rtl'].md\:rtl\:mt-36 {
    margin-top: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:mr-36,[dir='rtl'].md\:rtl\:mr-36 {
    margin-right: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:mb-36,[dir='rtl'].md\:rtl\:mb-36 {
    margin-bottom: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:ml-36,[dir='rtl'].md\:rtl\:ml-36 {
    margin-left: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:mt-40,[dir='rtl'].md\:rtl\:mt-40 {
    margin-top: 4rem
  }

  [dir='rtl'] .md\:rtl\:mr-40,[dir='rtl'].md\:rtl\:mr-40 {
    margin-right: 4rem
  }

  [dir='rtl'] .md\:rtl\:mb-40,[dir='rtl'].md\:rtl\:mb-40 {
    margin-bottom: 4rem
  }

  [dir='rtl'] .md\:rtl\:ml-40,[dir='rtl'].md\:rtl\:ml-40 {
    margin-left: 4rem
  }

  [dir='rtl'] .md\:rtl\:mt-44,[dir='rtl'].md\:rtl\:mt-44 {
    margin-top: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:mr-44,[dir='rtl'].md\:rtl\:mr-44 {
    margin-right: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:mb-44,[dir='rtl'].md\:rtl\:mb-44 {
    margin-bottom: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:ml-44,[dir='rtl'].md\:rtl\:ml-44 {
    margin-left: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:mt-48,[dir='rtl'].md\:rtl\:mt-48 {
    margin-top: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:mr-48,[dir='rtl'].md\:rtl\:mr-48 {
    margin-right: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:mb-48,[dir='rtl'].md\:rtl\:mb-48 {
    margin-bottom: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:ml-48,[dir='rtl'].md\:rtl\:ml-48 {
    margin-left: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:mt-52,[dir='rtl'].md\:rtl\:mt-52 {
    margin-top: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:mr-52,[dir='rtl'].md\:rtl\:mr-52 {
    margin-right: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:mb-52,[dir='rtl'].md\:rtl\:mb-52 {
    margin-bottom: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:ml-52,[dir='rtl'].md\:rtl\:ml-52 {
    margin-left: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:mt-56,[dir='rtl'].md\:rtl\:mt-56 {
    margin-top: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:mr-56,[dir='rtl'].md\:rtl\:mr-56 {
    margin-right: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:mb-56,[dir='rtl'].md\:rtl\:mb-56 {
    margin-bottom: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:ml-56,[dir='rtl'].md\:rtl\:ml-56 {
    margin-left: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:mt-60,[dir='rtl'].md\:rtl\:mt-60 {
    margin-top: 6rem
  }

  [dir='rtl'] .md\:rtl\:mr-60,[dir='rtl'].md\:rtl\:mr-60 {
    margin-right: 6rem
  }

  [dir='rtl'] .md\:rtl\:mb-60,[dir='rtl'].md\:rtl\:mb-60 {
    margin-bottom: 6rem
  }

  [dir='rtl'] .md\:rtl\:ml-60,[dir='rtl'].md\:rtl\:ml-60 {
    margin-left: 6rem
  }

  [dir='rtl'] .md\:rtl\:mt-64,[dir='rtl'].md\:rtl\:mt-64 {
    margin-top: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:mr-64,[dir='rtl'].md\:rtl\:mr-64 {
    margin-right: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:mb-64,[dir='rtl'].md\:rtl\:mb-64 {
    margin-bottom: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:ml-64,[dir='rtl'].md\:rtl\:ml-64 {
    margin-left: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:mt-68,[dir='rtl'].md\:rtl\:mt-68 {
    margin-top: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:mr-68,[dir='rtl'].md\:rtl\:mr-68 {
    margin-right: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:mb-68,[dir='rtl'].md\:rtl\:mb-68 {
    margin-bottom: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:ml-68,[dir='rtl'].md\:rtl\:ml-68 {
    margin-left: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:mt-72,[dir='rtl'].md\:rtl\:mt-72 {
    margin-top: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:mr-72,[dir='rtl'].md\:rtl\:mr-72 {
    margin-right: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:mb-72,[dir='rtl'].md\:rtl\:mb-72 {
    margin-bottom: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:ml-72,[dir='rtl'].md\:rtl\:ml-72 {
    margin-left: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:mt-76,[dir='rtl'].md\:rtl\:mt-76 {
    margin-top: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:mr-76,[dir='rtl'].md\:rtl\:mr-76 {
    margin-right: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:mb-76,[dir='rtl'].md\:rtl\:mb-76 {
    margin-bottom: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:ml-76,[dir='rtl'].md\:rtl\:ml-76 {
    margin-left: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:mt-80,[dir='rtl'].md\:rtl\:mt-80 {
    margin-top: 8rem
  }

  [dir='rtl'] .md\:rtl\:mr-80,[dir='rtl'].md\:rtl\:mr-80 {
    margin-right: 8rem
  }

  [dir='rtl'] .md\:rtl\:mb-80,[dir='rtl'].md\:rtl\:mb-80 {
    margin-bottom: 8rem
  }

  [dir='rtl'] .md\:rtl\:ml-80,[dir='rtl'].md\:rtl\:ml-80 {
    margin-left: 8rem
  }

  [dir='rtl'] .md\:rtl\:mt-84,[dir='rtl'].md\:rtl\:mt-84 {
    margin-top: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:mr-84,[dir='rtl'].md\:rtl\:mr-84 {
    margin-right: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:mb-84,[dir='rtl'].md\:rtl\:mb-84 {
    margin-bottom: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:ml-84,[dir='rtl'].md\:rtl\:ml-84 {
    margin-left: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:mt-88,[dir='rtl'].md\:rtl\:mt-88 {
    margin-top: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:mr-88,[dir='rtl'].md\:rtl\:mr-88 {
    margin-right: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:mb-88,[dir='rtl'].md\:rtl\:mb-88 {
    margin-bottom: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:ml-88,[dir='rtl'].md\:rtl\:ml-88 {
    margin-left: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:mt-92,[dir='rtl'].md\:rtl\:mt-92 {
    margin-top: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:mr-92,[dir='rtl'].md\:rtl\:mr-92 {
    margin-right: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:mb-92,[dir='rtl'].md\:rtl\:mb-92 {
    margin-bottom: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:ml-92,[dir='rtl'].md\:rtl\:ml-92 {
    margin-left: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:mt-96,[dir='rtl'].md\:rtl\:mt-96 {
    margin-top: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:mr-96,[dir='rtl'].md\:rtl\:mr-96 {
    margin-right: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:mb-96,[dir='rtl'].md\:rtl\:mb-96 {
    margin-bottom: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:ml-96,[dir='rtl'].md\:rtl\:ml-96 {
    margin-left: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:mt-128,[dir='rtl'].md\:rtl\:mt-128 {
    margin-top: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:mr-128,[dir='rtl'].md\:rtl\:mr-128 {
    margin-right: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:mb-128,[dir='rtl'].md\:rtl\:mb-128 {
    margin-bottom: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:ml-128,[dir='rtl'].md\:rtl\:ml-128 {
    margin-left: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:mt-136,[dir='rtl'].md\:rtl\:mt-136 {
    margin-top: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:mr-136,[dir='rtl'].md\:rtl\:mr-136 {
    margin-right: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:mb-136,[dir='rtl'].md\:rtl\:mb-136 {
    margin-bottom: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:ml-136,[dir='rtl'].md\:rtl\:ml-136 {
    margin-left: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:mt-160,[dir='rtl'].md\:rtl\:mt-160 {
    margin-top: 16rem
  }

  [dir='rtl'] .md\:rtl\:mr-160,[dir='rtl'].md\:rtl\:mr-160 {
    margin-right: 16rem
  }

  [dir='rtl'] .md\:rtl\:mb-160,[dir='rtl'].md\:rtl\:mb-160 {
    margin-bottom: 16rem
  }

  [dir='rtl'] .md\:rtl\:ml-160,[dir='rtl'].md\:rtl\:ml-160 {
    margin-left: 16rem
  }

  [dir='rtl'] .md\:rtl\:mt-192,[dir='rtl'].md\:rtl\:mt-192 {
    margin-top: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:mr-192,[dir='rtl'].md\:rtl\:mr-192 {
    margin-right: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:mb-192,[dir='rtl'].md\:rtl\:mb-192 {
    margin-bottom: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:ml-192,[dir='rtl'].md\:rtl\:ml-192 {
    margin-left: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:mt-200,[dir='rtl'].md\:rtl\:mt-200 {
    margin-top: 20rem
  }

  [dir='rtl'] .md\:rtl\:mr-200,[dir='rtl'].md\:rtl\:mr-200 {
    margin-right: 20rem
  }

  [dir='rtl'] .md\:rtl\:mb-200,[dir='rtl'].md\:rtl\:mb-200 {
    margin-bottom: 20rem
  }

  [dir='rtl'] .md\:rtl\:ml-200,[dir='rtl'].md\:rtl\:ml-200 {
    margin-left: 20rem
  }

  [dir='rtl'] .md\:rtl\:mt-208,[dir='rtl'].md\:rtl\:mt-208 {
    margin-top: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:mr-208,[dir='rtl'].md\:rtl\:mr-208 {
    margin-right: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:mb-208,[dir='rtl'].md\:rtl\:mb-208 {
    margin-bottom: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:ml-208,[dir='rtl'].md\:rtl\:ml-208 {
    margin-left: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:mt-216,[dir='rtl'].md\:rtl\:mt-216 {
    margin-top: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:mr-216,[dir='rtl'].md\:rtl\:mr-216 {
    margin-right: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:mb-216,[dir='rtl'].md\:rtl\:mb-216 {
    margin-bottom: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:ml-216,[dir='rtl'].md\:rtl\:ml-216 {
    margin-left: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:mt-224,[dir='rtl'].md\:rtl\:mt-224 {
    margin-top: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:mr-224,[dir='rtl'].md\:rtl\:mr-224 {
    margin-right: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:mb-224,[dir='rtl'].md\:rtl\:mb-224 {
    margin-bottom: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:ml-224,[dir='rtl'].md\:rtl\:ml-224 {
    margin-left: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:mt-256,[dir='rtl'].md\:rtl\:mt-256 {
    margin-top: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:mr-256,[dir='rtl'].md\:rtl\:mr-256 {
    margin-right: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:mb-256,[dir='rtl'].md\:rtl\:mb-256 {
    margin-bottom: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:ml-256,[dir='rtl'].md\:rtl\:ml-256 {
    margin-left: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:mt-288,[dir='rtl'].md\:rtl\:mt-288 {
    margin-top: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:mr-288,[dir='rtl'].md\:rtl\:mr-288 {
    margin-right: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:mb-288,[dir='rtl'].md\:rtl\:mb-288 {
    margin-bottom: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:ml-288,[dir='rtl'].md\:rtl\:ml-288 {
    margin-left: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:mt-320,[dir='rtl'].md\:rtl\:mt-320 {
    margin-top: 32rem
  }

  [dir='rtl'] .md\:rtl\:mr-320,[dir='rtl'].md\:rtl\:mr-320 {
    margin-right: 32rem
  }

  [dir='rtl'] .md\:rtl\:mb-320,[dir='rtl'].md\:rtl\:mb-320 {
    margin-bottom: 32rem
  }

  [dir='rtl'] .md\:rtl\:ml-320,[dir='rtl'].md\:rtl\:ml-320 {
    margin-left: 32rem
  }

  [dir='rtl'] .md\:rtl\:mt-360,[dir='rtl'].md\:rtl\:mt-360 {
    margin-top: 36rem
  }

  [dir='rtl'] .md\:rtl\:mr-360,[dir='rtl'].md\:rtl\:mr-360 {
    margin-right: 36rem
  }

  [dir='rtl'] .md\:rtl\:mb-360,[dir='rtl'].md\:rtl\:mb-360 {
    margin-bottom: 36rem
  }

  [dir='rtl'] .md\:rtl\:ml-360,[dir='rtl'].md\:rtl\:ml-360 {
    margin-left: 36rem
  }

  [dir='rtl'] .md\:rtl\:mt-384,[dir='rtl'].md\:rtl\:mt-384 {
    margin-top: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:mr-384,[dir='rtl'].md\:rtl\:mr-384 {
    margin-right: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:mb-384,[dir='rtl'].md\:rtl\:mb-384 {
    margin-bottom: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:ml-384,[dir='rtl'].md\:rtl\:ml-384 {
    margin-left: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:mt-400,[dir='rtl'].md\:rtl\:mt-400 {
    margin-top: 40rem
  }

  [dir='rtl'] .md\:rtl\:mr-400,[dir='rtl'].md\:rtl\:mr-400 {
    margin-right: 40rem
  }

  [dir='rtl'] .md\:rtl\:mb-400,[dir='rtl'].md\:rtl\:mb-400 {
    margin-bottom: 40rem
  }

  [dir='rtl'] .md\:rtl\:ml-400,[dir='rtl'].md\:rtl\:ml-400 {
    margin-left: 40rem
  }

  [dir='rtl'] .md\:rtl\:mt-512,[dir='rtl'].md\:rtl\:mt-512 {
    margin-top: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:mr-512,[dir='rtl'].md\:rtl\:mr-512 {
    margin-right: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:mb-512,[dir='rtl'].md\:rtl\:mb-512 {
    margin-bottom: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:ml-512,[dir='rtl'].md\:rtl\:ml-512 {
    margin-left: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:mt-640,[dir='rtl'].md\:rtl\:mt-640 {
    margin-top: 64rem
  }

  [dir='rtl'] .md\:rtl\:mr-640,[dir='rtl'].md\:rtl\:mr-640 {
    margin-right: 64rem
  }

  [dir='rtl'] .md\:rtl\:mb-640,[dir='rtl'].md\:rtl\:mb-640 {
    margin-bottom: 64rem
  }

  [dir='rtl'] .md\:rtl\:ml-640,[dir='rtl'].md\:rtl\:ml-640 {
    margin-left: 64rem
  }

  [dir='rtl'] .md\:rtl\:mt-auto,[dir='rtl'].md\:rtl\:mt-auto {
    margin-top: auto
  }

  [dir='rtl'] .md\:rtl\:mr-auto,[dir='rtl'].md\:rtl\:mr-auto {
    margin-right: auto
  }

  [dir='rtl'] .md\:rtl\:mb-auto,[dir='rtl'].md\:rtl\:mb-auto {
    margin-bottom: auto
  }

  [dir='rtl'] .md\:rtl\:ml-auto,[dir='rtl'].md\:rtl\:ml-auto {
    margin-left: auto
  }

  [dir='rtl'] .md\:rtl\:mt-xs,[dir='rtl'].md\:rtl\:mt-xs {
    margin-top: 32rem
  }

  [dir='rtl'] .md\:rtl\:mr-xs,[dir='rtl'].md\:rtl\:mr-xs {
    margin-right: 32rem
  }

  [dir='rtl'] .md\:rtl\:mb-xs,[dir='rtl'].md\:rtl\:mb-xs {
    margin-bottom: 32rem
  }

  [dir='rtl'] .md\:rtl\:ml-xs,[dir='rtl'].md\:rtl\:ml-xs {
    margin-left: 32rem
  }

  [dir='rtl'] .md\:rtl\:mt-sm,[dir='rtl'].md\:rtl\:mt-sm {
    margin-top: 48rem
  }

  [dir='rtl'] .md\:rtl\:mr-sm,[dir='rtl'].md\:rtl\:mr-sm {
    margin-right: 48rem
  }

  [dir='rtl'] .md\:rtl\:mb-sm,[dir='rtl'].md\:rtl\:mb-sm {
    margin-bottom: 48rem
  }

  [dir='rtl'] .md\:rtl\:ml-sm,[dir='rtl'].md\:rtl\:ml-sm {
    margin-left: 48rem
  }

  [dir='rtl'] .md\:rtl\:mt-md,[dir='rtl'].md\:rtl\:mt-md {
    margin-top: 64rem
  }

  [dir='rtl'] .md\:rtl\:mr-md,[dir='rtl'].md\:rtl\:mr-md {
    margin-right: 64rem
  }

  [dir='rtl'] .md\:rtl\:mb-md,[dir='rtl'].md\:rtl\:mb-md {
    margin-bottom: 64rem
  }

  [dir='rtl'] .md\:rtl\:ml-md,[dir='rtl'].md\:rtl\:ml-md {
    margin-left: 64rem
  }

  [dir='rtl'] .md\:rtl\:mt-lg,[dir='rtl'].md\:rtl\:mt-lg {
    margin-top: 80rem
  }

  [dir='rtl'] .md\:rtl\:mr-lg,[dir='rtl'].md\:rtl\:mr-lg {
    margin-right: 80rem
  }

  [dir='rtl'] .md\:rtl\:mb-lg,[dir='rtl'].md\:rtl\:mb-lg {
    margin-bottom: 80rem
  }

  [dir='rtl'] .md\:rtl\:ml-lg,[dir='rtl'].md\:rtl\:ml-lg {
    margin-left: 80rem
  }

  [dir='rtl'] .md\:rtl\:mt-xl,[dir='rtl'].md\:rtl\:mt-xl {
    margin-top: 96rem
  }

  [dir='rtl'] .md\:rtl\:mr-xl,[dir='rtl'].md\:rtl\:mr-xl {
    margin-right: 96rem
  }

  [dir='rtl'] .md\:rtl\:mb-xl,[dir='rtl'].md\:rtl\:mb-xl {
    margin-bottom: 96rem
  }

  [dir='rtl'] .md\:rtl\:ml-xl,[dir='rtl'].md\:rtl\:ml-xl {
    margin-left: 96rem
  }

  [dir='rtl'] .md\:rtl\:mt-2xl,[dir='rtl'].md\:rtl\:mt-2xl {
    margin-top: 112rem
  }

  [dir='rtl'] .md\:rtl\:mr-2xl,[dir='rtl'].md\:rtl\:mr-2xl {
    margin-right: 112rem
  }

  [dir='rtl'] .md\:rtl\:mb-2xl,[dir='rtl'].md\:rtl\:mb-2xl {
    margin-bottom: 112rem
  }

  [dir='rtl'] .md\:rtl\:ml-2xl,[dir='rtl'].md\:rtl\:ml-2xl {
    margin-left: 112rem
  }

  [dir='rtl'] .md\:rtl\:mt-3xl,[dir='rtl'].md\:rtl\:mt-3xl {
    margin-top: 128rem
  }

  [dir='rtl'] .md\:rtl\:mr-3xl,[dir='rtl'].md\:rtl\:mr-3xl {
    margin-right: 128rem
  }

  [dir='rtl'] .md\:rtl\:mb-3xl,[dir='rtl'].md\:rtl\:mb-3xl {
    margin-bottom: 128rem
  }

  [dir='rtl'] .md\:rtl\:ml-3xl,[dir='rtl'].md\:rtl\:ml-3xl {
    margin-left: 128rem
  }

  [dir='rtl'] .md\:rtl\:mt-4xl,[dir='rtl'].md\:rtl\:mt-4xl {
    margin-top: 144rem
  }

  [dir='rtl'] .md\:rtl\:mr-4xl,[dir='rtl'].md\:rtl\:mr-4xl {
    margin-right: 144rem
  }

  [dir='rtl'] .md\:rtl\:mb-4xl,[dir='rtl'].md\:rtl\:mb-4xl {
    margin-bottom: 144rem
  }

  [dir='rtl'] .md\:rtl\:ml-4xl,[dir='rtl'].md\:rtl\:ml-4xl {
    margin-left: 144rem
  }

  [dir='rtl'] .md\:rtl\:mt-5xl,[dir='rtl'].md\:rtl\:mt-5xl {
    margin-top: 160rem
  }

  [dir='rtl'] .md\:rtl\:mr-5xl,[dir='rtl'].md\:rtl\:mr-5xl {
    margin-right: 160rem
  }

  [dir='rtl'] .md\:rtl\:mb-5xl,[dir='rtl'].md\:rtl\:mb-5xl {
    margin-bottom: 160rem
  }

  [dir='rtl'] .md\:rtl\:ml-5xl,[dir='rtl'].md\:rtl\:ml-5xl {
    margin-left: 160rem
  }

  [dir='rtl'] .md\:rtl\:mt-px,[dir='rtl'].md\:rtl\:mt-px {
    margin-top: 1px
  }

  [dir='rtl'] .md\:rtl\:mr-px,[dir='rtl'].md\:rtl\:mr-px {
    margin-right: 1px
  }

  [dir='rtl'] .md\:rtl\:mb-px,[dir='rtl'].md\:rtl\:mb-px {
    margin-bottom: 1px
  }

  [dir='rtl'] .md\:rtl\:ml-px,[dir='rtl'].md\:rtl\:ml-px {
    margin-left: 1px
  }

  [dir='rtl'] .md\:rtl\:mt-0\.5,[dir='rtl'].md\:rtl\:mt-0\.5 {
    margin-top: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:mr-0\.5,[dir='rtl'].md\:rtl\:mr-0\.5 {
    margin-right: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:mb-0\.5,[dir='rtl'].md\:rtl\:mb-0\.5 {
    margin-bottom: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:ml-0\.5,[dir='rtl'].md\:rtl\:ml-0\.5 {
    margin-left: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:mt-1\.5,[dir='rtl'].md\:rtl\:mt-1\.5 {
    margin-top: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:mr-1\.5,[dir='rtl'].md\:rtl\:mr-1\.5 {
    margin-right: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:mb-1\.5,[dir='rtl'].md\:rtl\:mb-1\.5 {
    margin-bottom: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:ml-1\.5,[dir='rtl'].md\:rtl\:ml-1\.5 {
    margin-left: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:mt-2\.5,[dir='rtl'].md\:rtl\:mt-2\.5 {
    margin-top: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:mr-2\.5,[dir='rtl'].md\:rtl\:mr-2\.5 {
    margin-right: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:mb-2\.5,[dir='rtl'].md\:rtl\:mb-2\.5 {
    margin-bottom: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:ml-2\.5,[dir='rtl'].md\:rtl\:ml-2\.5 {
    margin-left: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:mt-3\.5,[dir='rtl'].md\:rtl\:mt-3\.5 {
    margin-top: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:mr-3\.5,[dir='rtl'].md\:rtl\:mr-3\.5 {
    margin-right: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:mb-3\.5,[dir='rtl'].md\:rtl\:mb-3\.5 {
    margin-bottom: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:ml-3\.5,[dir='rtl'].md\:rtl\:ml-3\.5 {
    margin-left: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:-mt-1,[dir='rtl'].md\:rtl\:-mt-1 {
    margin-top: -0.1rem
  }

  [dir='rtl'] .md\:rtl\:-mr-1,[dir='rtl'].md\:rtl\:-mr-1 {
    margin-right: -0.1rem
  }

  [dir='rtl'] .md\:rtl\:-mb-1,[dir='rtl'].md\:rtl\:-mb-1 {
    margin-bottom: -0.1rem
  }

  [dir='rtl'] .md\:rtl\:-ml-1,[dir='rtl'].md\:rtl\:-ml-1 {
    margin-left: -0.1rem
  }

  [dir='rtl'] .md\:rtl\:-mt-2,[dir='rtl'].md\:rtl\:-mt-2 {
    margin-top: -0.2rem
  }

  [dir='rtl'] .md\:rtl\:-mr-2,[dir='rtl'].md\:rtl\:-mr-2 {
    margin-right: -0.2rem
  }

  [dir='rtl'] .md\:rtl\:-mb-2,[dir='rtl'].md\:rtl\:-mb-2 {
    margin-bottom: -0.2rem
  }

  [dir='rtl'] .md\:rtl\:-ml-2,[dir='rtl'].md\:rtl\:-ml-2 {
    margin-left: -0.2rem
  }

  [dir='rtl'] .md\:rtl\:-mt-3,[dir='rtl'].md\:rtl\:-mt-3 {
    margin-top: -0.3rem
  }

  [dir='rtl'] .md\:rtl\:-mr-3,[dir='rtl'].md\:rtl\:-mr-3 {
    margin-right: -0.3rem
  }

  [dir='rtl'] .md\:rtl\:-mb-3,[dir='rtl'].md\:rtl\:-mb-3 {
    margin-bottom: -0.3rem
  }

  [dir='rtl'] .md\:rtl\:-ml-3,[dir='rtl'].md\:rtl\:-ml-3 {
    margin-left: -0.3rem
  }

  [dir='rtl'] .md\:rtl\:-mt-4,[dir='rtl'].md\:rtl\:-mt-4 {
    margin-top: -0.4rem
  }

  [dir='rtl'] .md\:rtl\:-mr-4,[dir='rtl'].md\:rtl\:-mr-4 {
    margin-right: -0.4rem
  }

  [dir='rtl'] .md\:rtl\:-mb-4,[dir='rtl'].md\:rtl\:-mb-4 {
    margin-bottom: -0.4rem
  }

  [dir='rtl'] .md\:rtl\:-ml-4,[dir='rtl'].md\:rtl\:-ml-4 {
    margin-left: -0.4rem
  }

  [dir='rtl'] .md\:rtl\:-mt-5,[dir='rtl'].md\:rtl\:-mt-5 {
    margin-top: -0.5rem
  }

  [dir='rtl'] .md\:rtl\:-mr-5,[dir='rtl'].md\:rtl\:-mr-5 {
    margin-right: -0.5rem
  }

  [dir='rtl'] .md\:rtl\:-mb-5,[dir='rtl'].md\:rtl\:-mb-5 {
    margin-bottom: -0.5rem
  }

  [dir='rtl'] .md\:rtl\:-ml-5,[dir='rtl'].md\:rtl\:-ml-5 {
    margin-left: -0.5rem
  }

  [dir='rtl'] .md\:rtl\:-mt-6,[dir='rtl'].md\:rtl\:-mt-6 {
    margin-top: -0.6rem
  }

  [dir='rtl'] .md\:rtl\:-mr-6,[dir='rtl'].md\:rtl\:-mr-6 {
    margin-right: -0.6rem
  }

  [dir='rtl'] .md\:rtl\:-mb-6,[dir='rtl'].md\:rtl\:-mb-6 {
    margin-bottom: -0.6rem
  }

  [dir='rtl'] .md\:rtl\:-ml-6,[dir='rtl'].md\:rtl\:-ml-6 {
    margin-left: -0.6rem
  }

  [dir='rtl'] .md\:rtl\:-mt-7,[dir='rtl'].md\:rtl\:-mt-7 {
    margin-top: -0.7rem
  }

  [dir='rtl'] .md\:rtl\:-mr-7,[dir='rtl'].md\:rtl\:-mr-7 {
    margin-right: -0.7rem
  }

  [dir='rtl'] .md\:rtl\:-mb-7,[dir='rtl'].md\:rtl\:-mb-7 {
    margin-bottom: -0.7rem
  }

  [dir='rtl'] .md\:rtl\:-ml-7,[dir='rtl'].md\:rtl\:-ml-7 {
    margin-left: -0.7rem
  }

  [dir='rtl'] .md\:rtl\:-mt-8,[dir='rtl'].md\:rtl\:-mt-8 {
    margin-top: -0.8rem
  }

  [dir='rtl'] .md\:rtl\:-mr-8,[dir='rtl'].md\:rtl\:-mr-8 {
    margin-right: -0.8rem
  }

  [dir='rtl'] .md\:rtl\:-mb-8,[dir='rtl'].md\:rtl\:-mb-8 {
    margin-bottom: -0.8rem
  }

  [dir='rtl'] .md\:rtl\:-ml-8,[dir='rtl'].md\:rtl\:-ml-8 {
    margin-left: -0.8rem
  }

  [dir='rtl'] .md\:rtl\:-mt-9,[dir='rtl'].md\:rtl\:-mt-9 {
    margin-top: -0.9rem
  }

  [dir='rtl'] .md\:rtl\:-mr-9,[dir='rtl'].md\:rtl\:-mr-9 {
    margin-right: -0.9rem
  }

  [dir='rtl'] .md\:rtl\:-mb-9,[dir='rtl'].md\:rtl\:-mb-9 {
    margin-bottom: -0.9rem
  }

  [dir='rtl'] .md\:rtl\:-ml-9,[dir='rtl'].md\:rtl\:-ml-9 {
    margin-left: -0.9rem
  }

  [dir='rtl'] .md\:rtl\:-mt-10,[dir='rtl'].md\:rtl\:-mt-10 {
    margin-top: -1rem
  }

  [dir='rtl'] .md\:rtl\:-mr-10,[dir='rtl'].md\:rtl\:-mr-10 {
    margin-right: -1rem
  }

  [dir='rtl'] .md\:rtl\:-mb-10,[dir='rtl'].md\:rtl\:-mb-10 {
    margin-bottom: -1rem
  }

  [dir='rtl'] .md\:rtl\:-ml-10,[dir='rtl'].md\:rtl\:-ml-10 {
    margin-left: -1rem
  }

  [dir='rtl'] .md\:rtl\:-mt-12,[dir='rtl'].md\:rtl\:-mt-12 {
    margin-top: -1.2rem
  }

  [dir='rtl'] .md\:rtl\:-mr-12,[dir='rtl'].md\:rtl\:-mr-12 {
    margin-right: -1.2rem
  }

  [dir='rtl'] .md\:rtl\:-mb-12,[dir='rtl'].md\:rtl\:-mb-12 {
    margin-bottom: -1.2rem
  }

  [dir='rtl'] .md\:rtl\:-ml-12,[dir='rtl'].md\:rtl\:-ml-12 {
    margin-left: -1.2rem
  }

  [dir='rtl'] .md\:rtl\:-mt-14,[dir='rtl'].md\:rtl\:-mt-14 {
    margin-top: -1.4rem
  }

  [dir='rtl'] .md\:rtl\:-mr-14,[dir='rtl'].md\:rtl\:-mr-14 {
    margin-right: -1.4rem
  }

  [dir='rtl'] .md\:rtl\:-mb-14,[dir='rtl'].md\:rtl\:-mb-14 {
    margin-bottom: -1.4rem
  }

  [dir='rtl'] .md\:rtl\:-ml-14,[dir='rtl'].md\:rtl\:-ml-14 {
    margin-left: -1.4rem
  }

  [dir='rtl'] .md\:rtl\:-mt-16,[dir='rtl'].md\:rtl\:-mt-16 {
    margin-top: -1.6rem
  }

  [dir='rtl'] .md\:rtl\:-mr-16,[dir='rtl'].md\:rtl\:-mr-16 {
    margin-right: -1.6rem
  }

  [dir='rtl'] .md\:rtl\:-mb-16,[dir='rtl'].md\:rtl\:-mb-16 {
    margin-bottom: -1.6rem
  }

  [dir='rtl'] .md\:rtl\:-ml-16,[dir='rtl'].md\:rtl\:-ml-16 {
    margin-left: -1.6rem
  }

  [dir='rtl'] .md\:rtl\:-mt-20,[dir='rtl'].md\:rtl\:-mt-20 {
    margin-top: -2rem
  }

  [dir='rtl'] .md\:rtl\:-mr-20,[dir='rtl'].md\:rtl\:-mr-20 {
    margin-right: -2rem
  }

  [dir='rtl'] .md\:rtl\:-mb-20,[dir='rtl'].md\:rtl\:-mb-20 {
    margin-bottom: -2rem
  }

  [dir='rtl'] .md\:rtl\:-ml-20,[dir='rtl'].md\:rtl\:-ml-20 {
    margin-left: -2rem
  }

  [dir='rtl'] .md\:rtl\:-mt-24,[dir='rtl'].md\:rtl\:-mt-24 {
    margin-top: -2.4rem
  }

  [dir='rtl'] .md\:rtl\:-mr-24,[dir='rtl'].md\:rtl\:-mr-24 {
    margin-right: -2.4rem
  }

  [dir='rtl'] .md\:rtl\:-mb-24,[dir='rtl'].md\:rtl\:-mb-24 {
    margin-bottom: -2.4rem
  }

  [dir='rtl'] .md\:rtl\:-ml-24,[dir='rtl'].md\:rtl\:-ml-24 {
    margin-left: -2.4rem
  }

  [dir='rtl'] .md\:rtl\:-mt-28,[dir='rtl'].md\:rtl\:-mt-28 {
    margin-top: -2.8rem
  }

  [dir='rtl'] .md\:rtl\:-mr-28,[dir='rtl'].md\:rtl\:-mr-28 {
    margin-right: -2.8rem
  }

  [dir='rtl'] .md\:rtl\:-mb-28,[dir='rtl'].md\:rtl\:-mb-28 {
    margin-bottom: -2.8rem
  }

  [dir='rtl'] .md\:rtl\:-ml-28,[dir='rtl'].md\:rtl\:-ml-28 {
    margin-left: -2.8rem
  }

  [dir='rtl'] .md\:rtl\:-mt-32,[dir='rtl'].md\:rtl\:-mt-32 {
    margin-top: -3.2rem
  }

  [dir='rtl'] .md\:rtl\:-mr-32,[dir='rtl'].md\:rtl\:-mr-32 {
    margin-right: -3.2rem
  }

  [dir='rtl'] .md\:rtl\:-mb-32,[dir='rtl'].md\:rtl\:-mb-32 {
    margin-bottom: -3.2rem
  }

  [dir='rtl'] .md\:rtl\:-ml-32,[dir='rtl'].md\:rtl\:-ml-32 {
    margin-left: -3.2rem
  }

  [dir='rtl'] .md\:rtl\:-mt-36,[dir='rtl'].md\:rtl\:-mt-36 {
    margin-top: -3.6rem
  }

  [dir='rtl'] .md\:rtl\:-mr-36,[dir='rtl'].md\:rtl\:-mr-36 {
    margin-right: -3.6rem
  }

  [dir='rtl'] .md\:rtl\:-mb-36,[dir='rtl'].md\:rtl\:-mb-36 {
    margin-bottom: -3.6rem
  }

  [dir='rtl'] .md\:rtl\:-ml-36,[dir='rtl'].md\:rtl\:-ml-36 {
    margin-left: -3.6rem
  }

  [dir='rtl'] .md\:rtl\:-mt-40,[dir='rtl'].md\:rtl\:-mt-40 {
    margin-top: -4rem
  }

  [dir='rtl'] .md\:rtl\:-mr-40,[dir='rtl'].md\:rtl\:-mr-40 {
    margin-right: -4rem
  }

  [dir='rtl'] .md\:rtl\:-mb-40,[dir='rtl'].md\:rtl\:-mb-40 {
    margin-bottom: -4rem
  }

  [dir='rtl'] .md\:rtl\:-ml-40,[dir='rtl'].md\:rtl\:-ml-40 {
    margin-left: -4rem
  }

  [dir='rtl'] .md\:rtl\:-mt-44,[dir='rtl'].md\:rtl\:-mt-44 {
    margin-top: -4.4rem
  }

  [dir='rtl'] .md\:rtl\:-mr-44,[dir='rtl'].md\:rtl\:-mr-44 {
    margin-right: -4.4rem
  }

  [dir='rtl'] .md\:rtl\:-mb-44,[dir='rtl'].md\:rtl\:-mb-44 {
    margin-bottom: -4.4rem
  }

  [dir='rtl'] .md\:rtl\:-ml-44,[dir='rtl'].md\:rtl\:-ml-44 {
    margin-left: -4.4rem
  }

  [dir='rtl'] .md\:rtl\:-mt-48,[dir='rtl'].md\:rtl\:-mt-48 {
    margin-top: -4.8rem
  }

  [dir='rtl'] .md\:rtl\:-mr-48,[dir='rtl'].md\:rtl\:-mr-48 {
    margin-right: -4.8rem
  }

  [dir='rtl'] .md\:rtl\:-mb-48,[dir='rtl'].md\:rtl\:-mb-48 {
    margin-bottom: -4.8rem
  }

  [dir='rtl'] .md\:rtl\:-ml-48,[dir='rtl'].md\:rtl\:-ml-48 {
    margin-left: -4.8rem
  }

  [dir='rtl'] .md\:rtl\:-mt-52,[dir='rtl'].md\:rtl\:-mt-52 {
    margin-top: -5.2rem
  }

  [dir='rtl'] .md\:rtl\:-mr-52,[dir='rtl'].md\:rtl\:-mr-52 {
    margin-right: -5.2rem
  }

  [dir='rtl'] .md\:rtl\:-mb-52,[dir='rtl'].md\:rtl\:-mb-52 {
    margin-bottom: -5.2rem
  }

  [dir='rtl'] .md\:rtl\:-ml-52,[dir='rtl'].md\:rtl\:-ml-52 {
    margin-left: -5.2rem
  }

  [dir='rtl'] .md\:rtl\:-mt-56,[dir='rtl'].md\:rtl\:-mt-56 {
    margin-top: -5.6rem
  }

  [dir='rtl'] .md\:rtl\:-mr-56,[dir='rtl'].md\:rtl\:-mr-56 {
    margin-right: -5.6rem
  }

  [dir='rtl'] .md\:rtl\:-mb-56,[dir='rtl'].md\:rtl\:-mb-56 {
    margin-bottom: -5.6rem
  }

  [dir='rtl'] .md\:rtl\:-ml-56,[dir='rtl'].md\:rtl\:-ml-56 {
    margin-left: -5.6rem
  }

  [dir='rtl'] .md\:rtl\:-mt-60,[dir='rtl'].md\:rtl\:-mt-60 {
    margin-top: -6rem
  }

  [dir='rtl'] .md\:rtl\:-mr-60,[dir='rtl'].md\:rtl\:-mr-60 {
    margin-right: -6rem
  }

  [dir='rtl'] .md\:rtl\:-mb-60,[dir='rtl'].md\:rtl\:-mb-60 {
    margin-bottom: -6rem
  }

  [dir='rtl'] .md\:rtl\:-ml-60,[dir='rtl'].md\:rtl\:-ml-60 {
    margin-left: -6rem
  }

  [dir='rtl'] .md\:rtl\:-mt-64,[dir='rtl'].md\:rtl\:-mt-64 {
    margin-top: -6.4rem
  }

  [dir='rtl'] .md\:rtl\:-mr-64,[dir='rtl'].md\:rtl\:-mr-64 {
    margin-right: -6.4rem
  }

  [dir='rtl'] .md\:rtl\:-mb-64,[dir='rtl'].md\:rtl\:-mb-64 {
    margin-bottom: -6.4rem
  }

  [dir='rtl'] .md\:rtl\:-ml-64,[dir='rtl'].md\:rtl\:-ml-64 {
    margin-left: -6.4rem
  }

  [dir='rtl'] .md\:rtl\:-mt-68,[dir='rtl'].md\:rtl\:-mt-68 {
    margin-top: -6.8rem
  }

  [dir='rtl'] .md\:rtl\:-mr-68,[dir='rtl'].md\:rtl\:-mr-68 {
    margin-right: -6.8rem
  }

  [dir='rtl'] .md\:rtl\:-mb-68,[dir='rtl'].md\:rtl\:-mb-68 {
    margin-bottom: -6.8rem
  }

  [dir='rtl'] .md\:rtl\:-ml-68,[dir='rtl'].md\:rtl\:-ml-68 {
    margin-left: -6.8rem
  }

  [dir='rtl'] .md\:rtl\:-mt-72,[dir='rtl'].md\:rtl\:-mt-72 {
    margin-top: -7.2rem
  }

  [dir='rtl'] .md\:rtl\:-mr-72,[dir='rtl'].md\:rtl\:-mr-72 {
    margin-right: -7.2rem
  }

  [dir='rtl'] .md\:rtl\:-mb-72,[dir='rtl'].md\:rtl\:-mb-72 {
    margin-bottom: -7.2rem
  }

  [dir='rtl'] .md\:rtl\:-ml-72,[dir='rtl'].md\:rtl\:-ml-72 {
    margin-left: -7.2rem
  }

  [dir='rtl'] .md\:rtl\:-mt-76,[dir='rtl'].md\:rtl\:-mt-76 {
    margin-top: -7.6rem
  }

  [dir='rtl'] .md\:rtl\:-mr-76,[dir='rtl'].md\:rtl\:-mr-76 {
    margin-right: -7.6rem
  }

  [dir='rtl'] .md\:rtl\:-mb-76,[dir='rtl'].md\:rtl\:-mb-76 {
    margin-bottom: -7.6rem
  }

  [dir='rtl'] .md\:rtl\:-ml-76,[dir='rtl'].md\:rtl\:-ml-76 {
    margin-left: -7.6rem
  }

  [dir='rtl'] .md\:rtl\:-mt-80,[dir='rtl'].md\:rtl\:-mt-80 {
    margin-top: -8rem
  }

  [dir='rtl'] .md\:rtl\:-mr-80,[dir='rtl'].md\:rtl\:-mr-80 {
    margin-right: -8rem
  }

  [dir='rtl'] .md\:rtl\:-mb-80,[dir='rtl'].md\:rtl\:-mb-80 {
    margin-bottom: -8rem
  }

  [dir='rtl'] .md\:rtl\:-ml-80,[dir='rtl'].md\:rtl\:-ml-80 {
    margin-left: -8rem
  }

  [dir='rtl'] .md\:rtl\:-mt-84,[dir='rtl'].md\:rtl\:-mt-84 {
    margin-top: -8.4rem
  }

  [dir='rtl'] .md\:rtl\:-mr-84,[dir='rtl'].md\:rtl\:-mr-84 {
    margin-right: -8.4rem
  }

  [dir='rtl'] .md\:rtl\:-mb-84,[dir='rtl'].md\:rtl\:-mb-84 {
    margin-bottom: -8.4rem
  }

  [dir='rtl'] .md\:rtl\:-ml-84,[dir='rtl'].md\:rtl\:-ml-84 {
    margin-left: -8.4rem
  }

  [dir='rtl'] .md\:rtl\:-mt-88,[dir='rtl'].md\:rtl\:-mt-88 {
    margin-top: -8.8rem
  }

  [dir='rtl'] .md\:rtl\:-mr-88,[dir='rtl'].md\:rtl\:-mr-88 {
    margin-right: -8.8rem
  }

  [dir='rtl'] .md\:rtl\:-mb-88,[dir='rtl'].md\:rtl\:-mb-88 {
    margin-bottom: -8.8rem
  }

  [dir='rtl'] .md\:rtl\:-ml-88,[dir='rtl'].md\:rtl\:-ml-88 {
    margin-left: -8.8rem
  }

  [dir='rtl'] .md\:rtl\:-mt-92,[dir='rtl'].md\:rtl\:-mt-92 {
    margin-top: -9.2rem
  }

  [dir='rtl'] .md\:rtl\:-mr-92,[dir='rtl'].md\:rtl\:-mr-92 {
    margin-right: -9.2rem
  }

  [dir='rtl'] .md\:rtl\:-mb-92,[dir='rtl'].md\:rtl\:-mb-92 {
    margin-bottom: -9.2rem
  }

  [dir='rtl'] .md\:rtl\:-ml-92,[dir='rtl'].md\:rtl\:-ml-92 {
    margin-left: -9.2rem
  }

  [dir='rtl'] .md\:rtl\:-mt-96,[dir='rtl'].md\:rtl\:-mt-96 {
    margin-top: -9.6rem
  }

  [dir='rtl'] .md\:rtl\:-mr-96,[dir='rtl'].md\:rtl\:-mr-96 {
    margin-right: -9.6rem
  }

  [dir='rtl'] .md\:rtl\:-mb-96,[dir='rtl'].md\:rtl\:-mb-96 {
    margin-bottom: -9.6rem
  }

  [dir='rtl'] .md\:rtl\:-ml-96,[dir='rtl'].md\:rtl\:-ml-96 {
    margin-left: -9.6rem
  }

  [dir='rtl'] .md\:rtl\:-mt-128,[dir='rtl'].md\:rtl\:-mt-128 {
    margin-top: -12.8rem
  }

  [dir='rtl'] .md\:rtl\:-mr-128,[dir='rtl'].md\:rtl\:-mr-128 {
    margin-right: -12.8rem
  }

  [dir='rtl'] .md\:rtl\:-mb-128,[dir='rtl'].md\:rtl\:-mb-128 {
    margin-bottom: -12.8rem
  }

  [dir='rtl'] .md\:rtl\:-ml-128,[dir='rtl'].md\:rtl\:-ml-128 {
    margin-left: -12.8rem
  }

  [dir='rtl'] .md\:rtl\:-mt-136,[dir='rtl'].md\:rtl\:-mt-136 {
    margin-top: -13.6rem
  }

  [dir='rtl'] .md\:rtl\:-mr-136,[dir='rtl'].md\:rtl\:-mr-136 {
    margin-right: -13.6rem
  }

  [dir='rtl'] .md\:rtl\:-mb-136,[dir='rtl'].md\:rtl\:-mb-136 {
    margin-bottom: -13.6rem
  }

  [dir='rtl'] .md\:rtl\:-ml-136,[dir='rtl'].md\:rtl\:-ml-136 {
    margin-left: -13.6rem
  }

  [dir='rtl'] .md\:rtl\:-mt-160,[dir='rtl'].md\:rtl\:-mt-160 {
    margin-top: -16rem
  }

  [dir='rtl'] .md\:rtl\:-mr-160,[dir='rtl'].md\:rtl\:-mr-160 {
    margin-right: -16rem
  }

  [dir='rtl'] .md\:rtl\:-mb-160,[dir='rtl'].md\:rtl\:-mb-160 {
    margin-bottom: -16rem
  }

  [dir='rtl'] .md\:rtl\:-ml-160,[dir='rtl'].md\:rtl\:-ml-160 {
    margin-left: -16rem
  }

  [dir='rtl'] .md\:rtl\:-mt-192,[dir='rtl'].md\:rtl\:-mt-192 {
    margin-top: -19.2rem
  }

  [dir='rtl'] .md\:rtl\:-mr-192,[dir='rtl'].md\:rtl\:-mr-192 {
    margin-right: -19.2rem
  }

  [dir='rtl'] .md\:rtl\:-mb-192,[dir='rtl'].md\:rtl\:-mb-192 {
    margin-bottom: -19.2rem
  }

  [dir='rtl'] .md\:rtl\:-ml-192,[dir='rtl'].md\:rtl\:-ml-192 {
    margin-left: -19.2rem
  }

  [dir='rtl'] .md\:rtl\:-mt-200,[dir='rtl'].md\:rtl\:-mt-200 {
    margin-top: -20rem
  }

  [dir='rtl'] .md\:rtl\:-mr-200,[dir='rtl'].md\:rtl\:-mr-200 {
    margin-right: -20rem
  }

  [dir='rtl'] .md\:rtl\:-mb-200,[dir='rtl'].md\:rtl\:-mb-200 {
    margin-bottom: -20rem
  }

  [dir='rtl'] .md\:rtl\:-ml-200,[dir='rtl'].md\:rtl\:-ml-200 {
    margin-left: -20rem
  }

  [dir='rtl'] .md\:rtl\:-mt-208,[dir='rtl'].md\:rtl\:-mt-208 {
    margin-top: -20.8rem
  }

  [dir='rtl'] .md\:rtl\:-mr-208,[dir='rtl'].md\:rtl\:-mr-208 {
    margin-right: -20.8rem
  }

  [dir='rtl'] .md\:rtl\:-mb-208,[dir='rtl'].md\:rtl\:-mb-208 {
    margin-bottom: -20.8rem
  }

  [dir='rtl'] .md\:rtl\:-ml-208,[dir='rtl'].md\:rtl\:-ml-208 {
    margin-left: -20.8rem
  }

  [dir='rtl'] .md\:rtl\:-mt-216,[dir='rtl'].md\:rtl\:-mt-216 {
    margin-top: -21.6rem
  }

  [dir='rtl'] .md\:rtl\:-mr-216,[dir='rtl'].md\:rtl\:-mr-216 {
    margin-right: -21.6rem
  }

  [dir='rtl'] .md\:rtl\:-mb-216,[dir='rtl'].md\:rtl\:-mb-216 {
    margin-bottom: -21.6rem
  }

  [dir='rtl'] .md\:rtl\:-ml-216,[dir='rtl'].md\:rtl\:-ml-216 {
    margin-left: -21.6rem
  }

  [dir='rtl'] .md\:rtl\:-mt-224,[dir='rtl'].md\:rtl\:-mt-224 {
    margin-top: -22.4rem
  }

  [dir='rtl'] .md\:rtl\:-mr-224,[dir='rtl'].md\:rtl\:-mr-224 {
    margin-right: -22.4rem
  }

  [dir='rtl'] .md\:rtl\:-mb-224,[dir='rtl'].md\:rtl\:-mb-224 {
    margin-bottom: -22.4rem
  }

  [dir='rtl'] .md\:rtl\:-ml-224,[dir='rtl'].md\:rtl\:-ml-224 {
    margin-left: -22.4rem
  }

  [dir='rtl'] .md\:rtl\:-mt-256,[dir='rtl'].md\:rtl\:-mt-256 {
    margin-top: -25.6rem
  }

  [dir='rtl'] .md\:rtl\:-mr-256,[dir='rtl'].md\:rtl\:-mr-256 {
    margin-right: -25.6rem
  }

  [dir='rtl'] .md\:rtl\:-mb-256,[dir='rtl'].md\:rtl\:-mb-256 {
    margin-bottom: -25.6rem
  }

  [dir='rtl'] .md\:rtl\:-ml-256,[dir='rtl'].md\:rtl\:-ml-256 {
    margin-left: -25.6rem
  }

  [dir='rtl'] .md\:rtl\:-mt-288,[dir='rtl'].md\:rtl\:-mt-288 {
    margin-top: -28.8rem
  }

  [dir='rtl'] .md\:rtl\:-mr-288,[dir='rtl'].md\:rtl\:-mr-288 {
    margin-right: -28.8rem
  }

  [dir='rtl'] .md\:rtl\:-mb-288,[dir='rtl'].md\:rtl\:-mb-288 {
    margin-bottom: -28.8rem
  }

  [dir='rtl'] .md\:rtl\:-ml-288,[dir='rtl'].md\:rtl\:-ml-288 {
    margin-left: -28.8rem
  }

  [dir='rtl'] .md\:rtl\:-mt-320,[dir='rtl'].md\:rtl\:-mt-320 {
    margin-top: -32rem
  }

  [dir='rtl'] .md\:rtl\:-mr-320,[dir='rtl'].md\:rtl\:-mr-320 {
    margin-right: -32rem
  }

  [dir='rtl'] .md\:rtl\:-mb-320,[dir='rtl'].md\:rtl\:-mb-320 {
    margin-bottom: -32rem
  }

  [dir='rtl'] .md\:rtl\:-ml-320,[dir='rtl'].md\:rtl\:-ml-320 {
    margin-left: -32rem
  }

  [dir='rtl'] .md\:rtl\:-mt-360,[dir='rtl'].md\:rtl\:-mt-360 {
    margin-top: -36rem
  }

  [dir='rtl'] .md\:rtl\:-mr-360,[dir='rtl'].md\:rtl\:-mr-360 {
    margin-right: -36rem
  }

  [dir='rtl'] .md\:rtl\:-mb-360,[dir='rtl'].md\:rtl\:-mb-360 {
    margin-bottom: -36rem
  }

  [dir='rtl'] .md\:rtl\:-ml-360,[dir='rtl'].md\:rtl\:-ml-360 {
    margin-left: -36rem
  }

  [dir='rtl'] .md\:rtl\:-mt-384,[dir='rtl'].md\:rtl\:-mt-384 {
    margin-top: -38.4rem
  }

  [dir='rtl'] .md\:rtl\:-mr-384,[dir='rtl'].md\:rtl\:-mr-384 {
    margin-right: -38.4rem
  }

  [dir='rtl'] .md\:rtl\:-mb-384,[dir='rtl'].md\:rtl\:-mb-384 {
    margin-bottom: -38.4rem
  }

  [dir='rtl'] .md\:rtl\:-ml-384,[dir='rtl'].md\:rtl\:-ml-384 {
    margin-left: -38.4rem
  }

  [dir='rtl'] .md\:rtl\:-mt-400,[dir='rtl'].md\:rtl\:-mt-400 {
    margin-top: -40rem
  }

  [dir='rtl'] .md\:rtl\:-mr-400,[dir='rtl'].md\:rtl\:-mr-400 {
    margin-right: -40rem
  }

  [dir='rtl'] .md\:rtl\:-mb-400,[dir='rtl'].md\:rtl\:-mb-400 {
    margin-bottom: -40rem
  }

  [dir='rtl'] .md\:rtl\:-ml-400,[dir='rtl'].md\:rtl\:-ml-400 {
    margin-left: -40rem
  }

  [dir='rtl'] .md\:rtl\:-mt-512,[dir='rtl'].md\:rtl\:-mt-512 {
    margin-top: -51.2rem
  }

  [dir='rtl'] .md\:rtl\:-mr-512,[dir='rtl'].md\:rtl\:-mr-512 {
    margin-right: -51.2rem
  }

  [dir='rtl'] .md\:rtl\:-mb-512,[dir='rtl'].md\:rtl\:-mb-512 {
    margin-bottom: -51.2rem
  }

  [dir='rtl'] .md\:rtl\:-ml-512,[dir='rtl'].md\:rtl\:-ml-512 {
    margin-left: -51.2rem
  }

  [dir='rtl'] .md\:rtl\:-mt-640,[dir='rtl'].md\:rtl\:-mt-640 {
    margin-top: -64rem
  }

  [dir='rtl'] .md\:rtl\:-mr-640,[dir='rtl'].md\:rtl\:-mr-640 {
    margin-right: -64rem
  }

  [dir='rtl'] .md\:rtl\:-mb-640,[dir='rtl'].md\:rtl\:-mb-640 {
    margin-bottom: -64rem
  }

  [dir='rtl'] .md\:rtl\:-ml-640,[dir='rtl'].md\:rtl\:-ml-640 {
    margin-left: -64rem
  }

  [dir='rtl'] .md\:rtl\:-mt-xs,[dir='rtl'].md\:rtl\:-mt-xs {
    margin-top: -32rem
  }

  [dir='rtl'] .md\:rtl\:-mr-xs,[dir='rtl'].md\:rtl\:-mr-xs {
    margin-right: -32rem
  }

  [dir='rtl'] .md\:rtl\:-mb-xs,[dir='rtl'].md\:rtl\:-mb-xs {
    margin-bottom: -32rem
  }

  [dir='rtl'] .md\:rtl\:-ml-xs,[dir='rtl'].md\:rtl\:-ml-xs {
    margin-left: -32rem
  }

  [dir='rtl'] .md\:rtl\:-mt-sm,[dir='rtl'].md\:rtl\:-mt-sm {
    margin-top: -48rem
  }

  [dir='rtl'] .md\:rtl\:-mr-sm,[dir='rtl'].md\:rtl\:-mr-sm {
    margin-right: -48rem
  }

  [dir='rtl'] .md\:rtl\:-mb-sm,[dir='rtl'].md\:rtl\:-mb-sm {
    margin-bottom: -48rem
  }

  [dir='rtl'] .md\:rtl\:-ml-sm,[dir='rtl'].md\:rtl\:-ml-sm {
    margin-left: -48rem
  }

  [dir='rtl'] .md\:rtl\:-mt-md,[dir='rtl'].md\:rtl\:-mt-md {
    margin-top: -64rem
  }

  [dir='rtl'] .md\:rtl\:-mr-md,[dir='rtl'].md\:rtl\:-mr-md {
    margin-right: -64rem
  }

  [dir='rtl'] .md\:rtl\:-mb-md,[dir='rtl'].md\:rtl\:-mb-md {
    margin-bottom: -64rem
  }

  [dir='rtl'] .md\:rtl\:-ml-md,[dir='rtl'].md\:rtl\:-ml-md {
    margin-left: -64rem
  }

  [dir='rtl'] .md\:rtl\:-mt-lg,[dir='rtl'].md\:rtl\:-mt-lg {
    margin-top: -80rem
  }

  [dir='rtl'] .md\:rtl\:-mr-lg,[dir='rtl'].md\:rtl\:-mr-lg {
    margin-right: -80rem
  }

  [dir='rtl'] .md\:rtl\:-mb-lg,[dir='rtl'].md\:rtl\:-mb-lg {
    margin-bottom: -80rem
  }

  [dir='rtl'] .md\:rtl\:-ml-lg,[dir='rtl'].md\:rtl\:-ml-lg {
    margin-left: -80rem
  }

  [dir='rtl'] .md\:rtl\:-mt-xl,[dir='rtl'].md\:rtl\:-mt-xl {
    margin-top: -96rem
  }

  [dir='rtl'] .md\:rtl\:-mr-xl,[dir='rtl'].md\:rtl\:-mr-xl {
    margin-right: -96rem
  }

  [dir='rtl'] .md\:rtl\:-mb-xl,[dir='rtl'].md\:rtl\:-mb-xl {
    margin-bottom: -96rem
  }

  [dir='rtl'] .md\:rtl\:-ml-xl,[dir='rtl'].md\:rtl\:-ml-xl {
    margin-left: -96rem
  }

  [dir='rtl'] .md\:rtl\:-mt-2xl,[dir='rtl'].md\:rtl\:-mt-2xl {
    margin-top: -112rem
  }

  [dir='rtl'] .md\:rtl\:-mr-2xl,[dir='rtl'].md\:rtl\:-mr-2xl {
    margin-right: -112rem
  }

  [dir='rtl'] .md\:rtl\:-mb-2xl,[dir='rtl'].md\:rtl\:-mb-2xl {
    margin-bottom: -112rem
  }

  [dir='rtl'] .md\:rtl\:-ml-2xl,[dir='rtl'].md\:rtl\:-ml-2xl {
    margin-left: -112rem
  }

  [dir='rtl'] .md\:rtl\:-mt-3xl,[dir='rtl'].md\:rtl\:-mt-3xl {
    margin-top: -128rem
  }

  [dir='rtl'] .md\:rtl\:-mr-3xl,[dir='rtl'].md\:rtl\:-mr-3xl {
    margin-right: -128rem
  }

  [dir='rtl'] .md\:rtl\:-mb-3xl,[dir='rtl'].md\:rtl\:-mb-3xl {
    margin-bottom: -128rem
  }

  [dir='rtl'] .md\:rtl\:-ml-3xl,[dir='rtl'].md\:rtl\:-ml-3xl {
    margin-left: -128rem
  }

  [dir='rtl'] .md\:rtl\:-mt-4xl,[dir='rtl'].md\:rtl\:-mt-4xl {
    margin-top: -144rem
  }

  [dir='rtl'] .md\:rtl\:-mr-4xl,[dir='rtl'].md\:rtl\:-mr-4xl {
    margin-right: -144rem
  }

  [dir='rtl'] .md\:rtl\:-mb-4xl,[dir='rtl'].md\:rtl\:-mb-4xl {
    margin-bottom: -144rem
  }

  [dir='rtl'] .md\:rtl\:-ml-4xl,[dir='rtl'].md\:rtl\:-ml-4xl {
    margin-left: -144rem
  }

  [dir='rtl'] .md\:rtl\:-mt-5xl,[dir='rtl'].md\:rtl\:-mt-5xl {
    margin-top: -160rem
  }

  [dir='rtl'] .md\:rtl\:-mr-5xl,[dir='rtl'].md\:rtl\:-mr-5xl {
    margin-right: -160rem
  }

  [dir='rtl'] .md\:rtl\:-mb-5xl,[dir='rtl'].md\:rtl\:-mb-5xl {
    margin-bottom: -160rem
  }

  [dir='rtl'] .md\:rtl\:-ml-5xl,[dir='rtl'].md\:rtl\:-ml-5xl {
    margin-left: -160rem
  }

  [dir='rtl'] .md\:rtl\:-mt-px,[dir='rtl'].md\:rtl\:-mt-px {
    margin-top: -1px
  }

  [dir='rtl'] .md\:rtl\:-mr-px,[dir='rtl'].md\:rtl\:-mr-px {
    margin-right: -1px
  }

  [dir='rtl'] .md\:rtl\:-mb-px,[dir='rtl'].md\:rtl\:-mb-px {
    margin-bottom: -1px
  }

  [dir='rtl'] .md\:rtl\:-ml-px,[dir='rtl'].md\:rtl\:-ml-px {
    margin-left: -1px
  }

  [dir='rtl'] .md\:rtl\:-mt-0\.5,[dir='rtl'].md\:rtl\:-mt-0\.5 {
    margin-top: -0.05rem
  }

  [dir='rtl'] .md\:rtl\:-mr-0\.5,[dir='rtl'].md\:rtl\:-mr-0\.5 {
    margin-right: -0.05rem
  }

  [dir='rtl'] .md\:rtl\:-mb-0\.5,[dir='rtl'].md\:rtl\:-mb-0\.5 {
    margin-bottom: -0.05rem
  }

  [dir='rtl'] .md\:rtl\:-ml-0\.5,[dir='rtl'].md\:rtl\:-ml-0\.5 {
    margin-left: -0.05rem
  }

  [dir='rtl'] .md\:rtl\:-mt-1\.5,[dir='rtl'].md\:rtl\:-mt-1\.5 {
    margin-top: -0.15rem
  }

  [dir='rtl'] .md\:rtl\:-mr-1\.5,[dir='rtl'].md\:rtl\:-mr-1\.5 {
    margin-right: -0.15rem
  }

  [dir='rtl'] .md\:rtl\:-mb-1\.5,[dir='rtl'].md\:rtl\:-mb-1\.5 {
    margin-bottom: -0.15rem
  }

  [dir='rtl'] .md\:rtl\:-ml-1\.5,[dir='rtl'].md\:rtl\:-ml-1\.5 {
    margin-left: -0.15rem
  }

  [dir='rtl'] .md\:rtl\:-mt-2\.5,[dir='rtl'].md\:rtl\:-mt-2\.5 {
    margin-top: -0.25rem
  }

  [dir='rtl'] .md\:rtl\:-mr-2\.5,[dir='rtl'].md\:rtl\:-mr-2\.5 {
    margin-right: -0.25rem
  }

  [dir='rtl'] .md\:rtl\:-mb-2\.5,[dir='rtl'].md\:rtl\:-mb-2\.5 {
    margin-bottom: -0.25rem
  }

  [dir='rtl'] .md\:rtl\:-ml-2\.5,[dir='rtl'].md\:rtl\:-ml-2\.5 {
    margin-left: -0.25rem
  }

  [dir='rtl'] .md\:rtl\:-mt-3\.5,[dir='rtl'].md\:rtl\:-mt-3\.5 {
    margin-top: -0.35rem
  }

  [dir='rtl'] .md\:rtl\:-mr-3\.5,[dir='rtl'].md\:rtl\:-mr-3\.5 {
    margin-right: -0.35rem
  }

  [dir='rtl'] .md\:rtl\:-mb-3\.5,[dir='rtl'].md\:rtl\:-mb-3\.5 {
    margin-bottom: -0.35rem
  }

  [dir='rtl'] .md\:rtl\:-ml-3\.5,[dir='rtl'].md\:rtl\:-ml-3\.5 {
    margin-left: -0.35rem
  }

  .md\:max-h-0 {
    max-height: 0
  }

  .md\:max-h-1 {
    max-height: 0.1rem
  }

  .md\:max-h-2 {
    max-height: 0.2rem
  }

  .md\:max-h-3 {
    max-height: 0.3rem
  }

  .md\:max-h-4 {
    max-height: 0.4rem
  }

  .md\:max-h-5 {
    max-height: 0.5rem
  }

  .md\:max-h-6 {
    max-height: 0.6rem
  }

  .md\:max-h-7 {
    max-height: 0.7rem
  }

  .md\:max-h-8 {
    max-height: 0.8rem
  }

  .md\:max-h-9 {
    max-height: 0.9rem
  }

  .md\:max-h-10 {
    max-height: 1.0rem
  }

  .md\:max-h-12 {
    max-height: 1.2rem
  }

  .md\:max-h-14 {
    max-height: 1.4rem
  }

  .md\:max-h-16 {
    max-height: 1.6rem
  }

  .md\:max-h-20 {
    max-height: 2rem
  }

  .md\:max-h-24 {
    max-height: 2.4rem
  }

  .md\:max-h-28 {
    max-height: 2.8rem
  }

  .md\:max-h-32 {
    max-height: 3.2rem
  }

  .md\:max-h-36 {
    max-height: 3.6rem
  }

  .md\:max-h-40 {
    max-height: 4rem
  }

  .md\:max-h-44 {
    max-height: 4.4rem
  }

  .md\:max-h-48 {
    max-height: 4.8rem
  }

  .md\:max-h-52 {
    max-height: 5.2rem
  }

  .md\:max-h-56 {
    max-height: 5.6rem
  }

  .md\:max-h-60 {
    max-height: 6rem
  }

  .md\:max-h-64 {
    max-height: 6.4rem
  }

  .md\:max-h-68 {
    max-height: 6.8rem
  }

  .md\:max-h-72 {
    max-height: 7.2rem
  }

  .md\:max-h-76 {
    max-height: 7.6rem
  }

  .md\:max-h-80 {
    max-height: 8rem
  }

  .md\:max-h-84 {
    max-height: 8.4rem
  }

  .md\:max-h-88 {
    max-height: 8.8rem
  }

  .md\:max-h-92 {
    max-height: 9.2rem
  }

  .md\:max-h-96 {
    max-height: 9.6rem
  }

  .md\:max-h-128 {
    max-height: 12.8rem
  }

  .md\:max-h-136 {
    max-height: 13.6rem
  }

  .md\:max-h-160 {
    max-height: 16rem
  }

  .md\:max-h-192 {
    max-height: 19.2rem
  }

  .md\:max-h-200 {
    max-height: 20rem
  }

  .md\:max-h-208 {
    max-height: 20.8rem
  }

  .md\:max-h-216 {
    max-height: 21.6rem
  }

  .md\:max-h-224 {
    max-height: 22.4rem
  }

  .md\:max-h-256 {
    max-height: 25.6rem
  }

  .md\:max-h-288 {
    max-height: 28.8rem
  }

  .md\:max-h-320 {
    max-height: 32rem
  }

  .md\:max-h-360 {
    max-height: 36rem
  }

  .md\:max-h-384 {
    max-height: 38.4rem
  }

  .md\:max-h-400 {
    max-height: 40rem
  }

  .md\:max-h-512 {
    max-height: 51.2rem
  }

  .md\:max-h-640 {
    max-height: 64rem
  }

  .md\:max-h-xs {
    max-height: 32rem
  }

  .md\:max-h-sm {
    max-height: 48rem
  }

  .md\:max-h-md {
    max-height: 64rem
  }

  .md\:max-h-lg {
    max-height: 80rem
  }

  .md\:max-h-xl {
    max-height: 96rem
  }

  .md\:max-h-2xl {
    max-height: 112rem
  }

  .md\:max-h-3xl {
    max-height: 128rem
  }

  .md\:max-h-4xl {
    max-height: 144rem
  }

  .md\:max-h-5xl {
    max-height: 160rem
  }

  .md\:max-h-px {
    max-height: 1px
  }

  .md\:max-h-0\.5 {
    max-height: 0.05rem
  }

  .md\:max-h-1\.5 {
    max-height: 0.15rem
  }

  .md\:max-h-2\.5 {
    max-height: 0.25rem
  }

  .md\:max-h-3\.5 {
    max-height: 0.35rem
  }

  .md\:max-h-full {
    max-height: 100%
  }

  .md\:max-h-screen {
    max-height: 100vh
  }

  .md\:max-h-auto {
    max-height: auto
  }

  .md\:max-w-0 {
    max-width: 0
  }

  .md\:max-w-1 {
    max-width: 0.1rem
  }

  .md\:max-w-2 {
    max-width: 0.2rem
  }

  .md\:max-w-3 {
    max-width: 0.3rem
  }

  .md\:max-w-4 {
    max-width: 0.4rem
  }

  .md\:max-w-5 {
    max-width: 0.5rem
  }

  .md\:max-w-6 {
    max-width: 0.6rem
  }

  .md\:max-w-7 {
    max-width: 0.7rem
  }

  .md\:max-w-8 {
    max-width: 0.8rem
  }

  .md\:max-w-9 {
    max-width: 0.9rem
  }

  .md\:max-w-10 {
    max-width: 1.0rem
  }

  .md\:max-w-12 {
    max-width: 1.2rem
  }

  .md\:max-w-14 {
    max-width: 1.4rem
  }

  .md\:max-w-16 {
    max-width: 1.6rem
  }

  .md\:max-w-20 {
    max-width: 2rem
  }

  .md\:max-w-24 {
    max-width: 2.4rem
  }

  .md\:max-w-28 {
    max-width: 2.8rem
  }

  .md\:max-w-32 {
    max-width: 3.2rem
  }

  .md\:max-w-36 {
    max-width: 3.6rem
  }

  .md\:max-w-40 {
    max-width: 4rem
  }

  .md\:max-w-44 {
    max-width: 4.4rem
  }

  .md\:max-w-48 {
    max-width: 4.8rem
  }

  .md\:max-w-52 {
    max-width: 5.2rem
  }

  .md\:max-w-56 {
    max-width: 5.6rem
  }

  .md\:max-w-60 {
    max-width: 6rem
  }

  .md\:max-w-64 {
    max-width: 6.4rem
  }

  .md\:max-w-68 {
    max-width: 6.8rem
  }

  .md\:max-w-72 {
    max-width: 7.2rem
  }

  .md\:max-w-76 {
    max-width: 7.6rem
  }

  .md\:max-w-80 {
    max-width: 8rem
  }

  .md\:max-w-84 {
    max-width: 8.4rem
  }

  .md\:max-w-88 {
    max-width: 8.8rem
  }

  .md\:max-w-92 {
    max-width: 9.2rem
  }

  .md\:max-w-96 {
    max-width: 9.6rem
  }

  .md\:max-w-128 {
    max-width: 12.8rem
  }

  .md\:max-w-136 {
    max-width: 13.6rem
  }

  .md\:max-w-160 {
    max-width: 16rem
  }

  .md\:max-w-192 {
    max-width: 19.2rem
  }

  .md\:max-w-200 {
    max-width: 20rem
  }

  .md\:max-w-208 {
    max-width: 20.8rem
  }

  .md\:max-w-216 {
    max-width: 21.6rem
  }

  .md\:max-w-224 {
    max-width: 22.4rem
  }

  .md\:max-w-256 {
    max-width: 25.6rem
  }

  .md\:max-w-288 {
    max-width: 28.8rem
  }

  .md\:max-w-320 {
    max-width: 32rem
  }

  .md\:max-w-360 {
    max-width: 36rem
  }

  .md\:max-w-384 {
    max-width: 38.4rem
  }

  .md\:max-w-400 {
    max-width: 40rem
  }

  .md\:max-w-512 {
    max-width: 51.2rem
  }

  .md\:max-w-640 {
    max-width: 64rem
  }

  .md\:max-w-none {
    max-width: none
  }

  .md\:max-w-xs {
    max-width: 32rem
  }

  .md\:max-w-sm {
    max-width: 48rem
  }

  .md\:max-w-md {
    max-width: 64rem
  }

  .md\:max-w-lg {
    max-width: 80rem
  }

  .md\:max-w-xl {
    max-width: 96rem
  }

  .md\:max-w-2xl {
    max-width: 112rem
  }

  .md\:max-w-3xl {
    max-width: 128rem
  }

  .md\:max-w-4xl {
    max-width: 144rem
  }

  .md\:max-w-5xl {
    max-width: 160rem
  }

  .md\:max-w-px {
    max-width: 1px
  }

  .md\:max-w-0\.5 {
    max-width: 0.05rem
  }

  .md\:max-w-1\.5 {
    max-width: 0.15rem
  }

  .md\:max-w-2\.5 {
    max-width: 0.25rem
  }

  .md\:max-w-3\.5 {
    max-width: 0.35rem
  }

  .md\:max-w-full {
    max-width: 100%
  }

  .md\:max-w-min {
    max-width: min-content
  }

  .md\:max-w-max {
    max-width: max-content
  }

  .md\:max-w-prose {
    max-width: 65ch
  }

  .md\:max-w-screen-sm {
    max-width: 600px
  }

  .md\:max-w-screen-md {
    max-width: 960px
  }

  .md\:max-w-screen-lg {
    max-width: 1280px
  }

  .md\:max-w-screen-xl {
    max-width: 1920px
  }

  .md\:min-h-0 {
    min-height: 0
  }

  .md\:min-h-1 {
    min-height: 0.1rem
  }

  .md\:min-h-2 {
    min-height: 0.2rem
  }

  .md\:min-h-3 {
    min-height: 0.3rem
  }

  .md\:min-h-4 {
    min-height: 0.4rem
  }

  .md\:min-h-5 {
    min-height: 0.5rem
  }

  .md\:min-h-6 {
    min-height: 0.6rem
  }

  .md\:min-h-7 {
    min-height: 0.7rem
  }

  .md\:min-h-8 {
    min-height: 0.8rem
  }

  .md\:min-h-9 {
    min-height: 0.9rem
  }

  .md\:min-h-10 {
    min-height: 1.0rem
  }

  .md\:min-h-12 {
    min-height: 1.2rem
  }

  .md\:min-h-14 {
    min-height: 1.4rem
  }

  .md\:min-h-16 {
    min-height: 1.6rem
  }

  .md\:min-h-20 {
    min-height: 2rem
  }

  .md\:min-h-24 {
    min-height: 2.4rem
  }

  .md\:min-h-28 {
    min-height: 2.8rem
  }

  .md\:min-h-32 {
    min-height: 3.2rem
  }

  .md\:min-h-36 {
    min-height: 3.6rem
  }

  .md\:min-h-40 {
    min-height: 4rem
  }

  .md\:min-h-44 {
    min-height: 4.4rem
  }

  .md\:min-h-48 {
    min-height: 4.8rem
  }

  .md\:min-h-52 {
    min-height: 5.2rem
  }

  .md\:min-h-56 {
    min-height: 5.6rem
  }

  .md\:min-h-60 {
    min-height: 6rem
  }

  .md\:min-h-64 {
    min-height: 6.4rem
  }

  .md\:min-h-68 {
    min-height: 6.8rem
  }

  .md\:min-h-72 {
    min-height: 7.2rem
  }

  .md\:min-h-76 {
    min-height: 7.6rem
  }

  .md\:min-h-80 {
    min-height: 8rem
  }

  .md\:min-h-84 {
    min-height: 8.4rem
  }

  .md\:min-h-88 {
    min-height: 8.8rem
  }

  .md\:min-h-92 {
    min-height: 9.2rem
  }

  .md\:min-h-96 {
    min-height: 9.6rem
  }

  .md\:min-h-128 {
    min-height: 12.8rem
  }

  .md\:min-h-136 {
    min-height: 13.6rem
  }

  .md\:min-h-160 {
    min-height: 16rem
  }

  .md\:min-h-192 {
    min-height: 19.2rem
  }

  .md\:min-h-200 {
    min-height: 20rem
  }

  .md\:min-h-208 {
    min-height: 20.8rem
  }

  .md\:min-h-216 {
    min-height: 21.6rem
  }

  .md\:min-h-224 {
    min-height: 22.4rem
  }

  .md\:min-h-256 {
    min-height: 25.6rem
  }

  .md\:min-h-288 {
    min-height: 28.8rem
  }

  .md\:min-h-320 {
    min-height: 32rem
  }

  .md\:min-h-360 {
    min-height: 36rem
  }

  .md\:min-h-384 {
    min-height: 38.4rem
  }

  .md\:min-h-400 {
    min-height: 40rem
  }

  .md\:min-h-512 {
    min-height: 51.2rem
  }

  .md\:min-h-640 {
    min-height: 64rem
  }

  .md\:min-h-auto {
    min-height: auto
  }

  .md\:min-h-xs {
    min-height: 32rem
  }

  .md\:min-h-sm {
    min-height: 48rem
  }

  .md\:min-h-md {
    min-height: 64rem
  }

  .md\:min-h-lg {
    min-height: 80rem
  }

  .md\:min-h-xl {
    min-height: 96rem
  }

  .md\:min-h-2xl {
    min-height: 112rem
  }

  .md\:min-h-3xl {
    min-height: 128rem
  }

  .md\:min-h-4xl {
    min-height: 144rem
  }

  .md\:min-h-5xl {
    min-height: 160rem
  }

  .md\:min-h-px {
    min-height: 1px
  }

  .md\:min-h-0\.5 {
    min-height: 0.05rem
  }

  .md\:min-h-1\.5 {
    min-height: 0.15rem
  }

  .md\:min-h-2\.5 {
    min-height: 0.25rem
  }

  .md\:min-h-3\.5 {
    min-height: 0.35rem
  }

  .md\:min-h-full {
    min-height: 100%
  }

  .md\:min-h-screen {
    min-height: 100vh
  }

  .md\:min-w-0 {
    min-width: 0
  }

  .md\:min-w-1 {
    min-width: 0.1rem
  }

  .md\:min-w-2 {
    min-width: 0.2rem
  }

  .md\:min-w-3 {
    min-width: 0.3rem
  }

  .md\:min-w-4 {
    min-width: 0.4rem
  }

  .md\:min-w-5 {
    min-width: 0.5rem
  }

  .md\:min-w-6 {
    min-width: 0.6rem
  }

  .md\:min-w-7 {
    min-width: 0.7rem
  }

  .md\:min-w-8 {
    min-width: 0.8rem
  }

  .md\:min-w-9 {
    min-width: 0.9rem
  }

  .md\:min-w-10 {
    min-width: 1.0rem
  }

  .md\:min-w-12 {
    min-width: 1.2rem
  }

  .md\:min-w-14 {
    min-width: 1.4rem
  }

  .md\:min-w-16 {
    min-width: 1.6rem
  }

  .md\:min-w-20 {
    min-width: 2rem
  }

  .md\:min-w-24 {
    min-width: 2.4rem
  }

  .md\:min-w-28 {
    min-width: 2.8rem
  }

  .md\:min-w-32 {
    min-width: 3.2rem
  }

  .md\:min-w-36 {
    min-width: 3.6rem
  }

  .md\:min-w-40 {
    min-width: 4rem
  }

  .md\:min-w-44 {
    min-width: 4.4rem
  }

  .md\:min-w-48 {
    min-width: 4.8rem
  }

  .md\:min-w-52 {
    min-width: 5.2rem
  }

  .md\:min-w-56 {
    min-width: 5.6rem
  }

  .md\:min-w-60 {
    min-width: 6rem
  }

  .md\:min-w-64 {
    min-width: 6.4rem
  }

  .md\:min-w-68 {
    min-width: 6.8rem
  }

  .md\:min-w-72 {
    min-width: 7.2rem
  }

  .md\:min-w-76 {
    min-width: 7.6rem
  }

  .md\:min-w-80 {
    min-width: 8rem
  }

  .md\:min-w-84 {
    min-width: 8.4rem
  }

  .md\:min-w-88 {
    min-width: 8.8rem
  }

  .md\:min-w-92 {
    min-width: 9.2rem
  }

  .md\:min-w-96 {
    min-width: 9.6rem
  }

  .md\:min-w-128 {
    min-width: 12.8rem
  }

  .md\:min-w-136 {
    min-width: 13.6rem
  }

  .md\:min-w-160 {
    min-width: 16rem
  }

  .md\:min-w-192 {
    min-width: 19.2rem
  }

  .md\:min-w-200 {
    min-width: 20rem
  }

  .md\:min-w-208 {
    min-width: 20.8rem
  }

  .md\:min-w-216 {
    min-width: 21.6rem
  }

  .md\:min-w-224 {
    min-width: 22.4rem
  }

  .md\:min-w-256 {
    min-width: 25.6rem
  }

  .md\:min-w-288 {
    min-width: 28.8rem
  }

  .md\:min-w-320 {
    min-width: 32rem
  }

  .md\:min-w-360 {
    min-width: 36rem
  }

  .md\:min-w-384 {
    min-width: 38.4rem
  }

  .md\:min-w-400 {
    min-width: 40rem
  }

  .md\:min-w-512 {
    min-width: 51.2rem
  }

  .md\:min-w-640 {
    min-width: 64rem
  }

  .md\:min-w-xs {
    min-width: 32rem
  }

  .md\:min-w-sm {
    min-width: 48rem
  }

  .md\:min-w-md {
    min-width: 64rem
  }

  .md\:min-w-lg {
    min-width: 80rem
  }

  .md\:min-w-xl {
    min-width: 96rem
  }

  .md\:min-w-2xl {
    min-width: 112rem
  }

  .md\:min-w-3xl {
    min-width: 128rem
  }

  .md\:min-w-4xl {
    min-width: 144rem
  }

  .md\:min-w-5xl {
    min-width: 160rem
  }

  .md\:min-w-px {
    min-width: 1px
  }

  .md\:min-w-0\.5 {
    min-width: 0.05rem
  }

  .md\:min-w-1\.5 {
    min-width: 0.15rem
  }

  .md\:min-w-2\.5 {
    min-width: 0.25rem
  }

  .md\:min-w-3\.5 {
    min-width: 0.35rem
  }

  .md\:min-w-full {
    min-width: 100%
  }

  .md\:min-w-min {
    min-width: min-content
  }

  .md\:min-w-max {
    min-width: max-content
  }

  .md\:min-w-screen {
    min-width: 100vw
  }

  .md\:object-contain {
    object-fit: contain
  }

  .md\:object-cover {
    object-fit: cover
  }

  .md\:object-fill {
    object-fit: fill
  }

  .md\:object-none {
    object-fit: none
  }

  .md\:object-scale-down {
    object-fit: scale-down
  }

  .md\:object-bottom {
    object-position: bottom
  }

  .md\:object-center {
    object-position: center
  }

  .md\:object-left {
    object-position: left
  }

  .md\:object-left-bottom {
    object-position: left bottom
  }

  .md\:object-left-top {
    object-position: left top
  }

  .md\:object-right {
    object-position: right
  }

  .md\:object-right-bottom {
    object-position: right bottom
  }

  .md\:object-right-top {
    object-position: right top
  }

  .md\:object-top {
    object-position: top
  }

  .md\:opacity-0 {
    opacity: 0
  }

  .md\:opacity-5 {
    opacity: 0.05
  }

  .md\:opacity-10 {
    opacity: 0.1
  }

  .md\:opacity-20 {
    opacity: 0.2
  }

  .md\:opacity-25 {
    opacity: 0.25
  }

  .md\:opacity-30 {
    opacity: 0.3
  }

  .md\:opacity-40 {
    opacity: 0.4
  }

  .md\:opacity-50 {
    opacity: 0.5
  }

  .md\:opacity-60 {
    opacity: 0.6
  }

  .md\:opacity-70 {
    opacity: 0.7
  }

  .md\:opacity-75 {
    opacity: 0.75
  }

  .md\:opacity-80 {
    opacity: 0.8
  }

  .md\:opacity-90 {
    opacity: 0.9
  }

  .md\:opacity-95 {
    opacity: 0.95
  }

  .md\:opacity-100 {
    opacity: 1
  }

  .group:hover .md\:group-hover\:opacity-0 {
    opacity: 0
  }

  .group:hover .md\:group-hover\:opacity-5 {
    opacity: 0.05
  }

  .group:hover .md\:group-hover\:opacity-10 {
    opacity: 0.1
  }

  .group:hover .md\:group-hover\:opacity-20 {
    opacity: 0.2
  }

  .group:hover .md\:group-hover\:opacity-25 {
    opacity: 0.25
  }

  .group:hover .md\:group-hover\:opacity-30 {
    opacity: 0.3
  }

  .group:hover .md\:group-hover\:opacity-40 {
    opacity: 0.4
  }

  .group:hover .md\:group-hover\:opacity-50 {
    opacity: 0.5
  }

  .group:hover .md\:group-hover\:opacity-60 {
    opacity: 0.6
  }

  .group:hover .md\:group-hover\:opacity-70 {
    opacity: 0.7
  }

  .group:hover .md\:group-hover\:opacity-75 {
    opacity: 0.75
  }

  .group:hover .md\:group-hover\:opacity-80 {
    opacity: 0.8
  }

  .group:hover .md\:group-hover\:opacity-90 {
    opacity: 0.9
  }

  .group:hover .md\:group-hover\:opacity-95 {
    opacity: 0.95
  }

  .group:hover .md\:group-hover\:opacity-100 {
    opacity: 1
  }

  .md\:focus-within\:opacity-0:focus-within {
    opacity: 0
  }

  .md\:focus-within\:opacity-5:focus-within {
    opacity: 0.05
  }

  .md\:focus-within\:opacity-10:focus-within {
    opacity: 0.1
  }

  .md\:focus-within\:opacity-20:focus-within {
    opacity: 0.2
  }

  .md\:focus-within\:opacity-25:focus-within {
    opacity: 0.25
  }

  .md\:focus-within\:opacity-30:focus-within {
    opacity: 0.3
  }

  .md\:focus-within\:opacity-40:focus-within {
    opacity: 0.4
  }

  .md\:focus-within\:opacity-50:focus-within {
    opacity: 0.5
  }

  .md\:focus-within\:opacity-60:focus-within {
    opacity: 0.6
  }

  .md\:focus-within\:opacity-70:focus-within {
    opacity: 0.7
  }

  .md\:focus-within\:opacity-75:focus-within {
    opacity: 0.75
  }

  .md\:focus-within\:opacity-80:focus-within {
    opacity: 0.8
  }

  .md\:focus-within\:opacity-90:focus-within {
    opacity: 0.9
  }

  .md\:focus-within\:opacity-95:focus-within {
    opacity: 0.95
  }

  .md\:focus-within\:opacity-100:focus-within {
    opacity: 1
  }

  .md\:hover\:opacity-0:hover {
    opacity: 0
  }

  .md\:hover\:opacity-5:hover {
    opacity: 0.05
  }

  .md\:hover\:opacity-10:hover {
    opacity: 0.1
  }

  .md\:hover\:opacity-20:hover {
    opacity: 0.2
  }

  .md\:hover\:opacity-25:hover {
    opacity: 0.25
  }

  .md\:hover\:opacity-30:hover {
    opacity: 0.3
  }

  .md\:hover\:opacity-40:hover {
    opacity: 0.4
  }

  .md\:hover\:opacity-50:hover {
    opacity: 0.5
  }

  .md\:hover\:opacity-60:hover {
    opacity: 0.6
  }

  .md\:hover\:opacity-70:hover {
    opacity: 0.7
  }

  .md\:hover\:opacity-75:hover {
    opacity: 0.75
  }

  .md\:hover\:opacity-80:hover {
    opacity: 0.8
  }

  .md\:hover\:opacity-90:hover {
    opacity: 0.9
  }

  .md\:hover\:opacity-95:hover {
    opacity: 0.95
  }

  .md\:hover\:opacity-100:hover {
    opacity: 1
  }

  .md\:focus\:opacity-0:focus {
    opacity: 0
  }

  .md\:focus\:opacity-5:focus {
    opacity: 0.05
  }

  .md\:focus\:opacity-10:focus {
    opacity: 0.1
  }

  .md\:focus\:opacity-20:focus {
    opacity: 0.2
  }

  .md\:focus\:opacity-25:focus {
    opacity: 0.25
  }

  .md\:focus\:opacity-30:focus {
    opacity: 0.3
  }

  .md\:focus\:opacity-40:focus {
    opacity: 0.4
  }

  .md\:focus\:opacity-50:focus {
    opacity: 0.5
  }

  .md\:focus\:opacity-60:focus {
    opacity: 0.6
  }

  .md\:focus\:opacity-70:focus {
    opacity: 0.7
  }

  .md\:focus\:opacity-75:focus {
    opacity: 0.75
  }

  .md\:focus\:opacity-80:focus {
    opacity: 0.8
  }

  .md\:focus\:opacity-90:focus {
    opacity: 0.9
  }

  .md\:focus\:opacity-95:focus {
    opacity: 0.95
  }

  .md\:focus\:opacity-100:focus {
    opacity: 1
  }

  .md\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .md\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .md\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .md\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .md\:focus-within\:outline-white:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .md\:focus-within\:outline-black:focus-within {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .md\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .md\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .md\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .md\:overflow-auto {
    overflow: auto
  }

  .md\:overflow-hidden {
    overflow: hidden
  }

  .md\:overflow-visible {
    overflow: visible
  }

  .md\:overflow-scroll {
    overflow: scroll
  }

  .md\:overflow-x-auto {
    overflow-x: auto
  }

  .md\:overflow-y-auto {
    overflow-y: auto
  }

  .md\:overflow-x-hidden {
    overflow-x: hidden
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden
  }

  .md\:overflow-x-visible {
    overflow-x: visible
  }

  .md\:overflow-y-visible {
    overflow-y: visible
  }

  .md\:overflow-x-scroll {
    overflow-x: scroll
  }

  .md\:overflow-y-scroll {
    overflow-y: scroll
  }

  .md\:overscroll-auto {
    overscroll-behavior: auto
  }

  .md\:overscroll-contain {
    overscroll-behavior: contain
  }

  .md\:overscroll-none {
    overscroll-behavior: none
  }

  .md\:overscroll-y-auto {
    overscroll-behavior-y: auto
  }

  .md\:overscroll-y-contain {
    overscroll-behavior-y: contain
  }

  .md\:overscroll-y-none {
    overscroll-behavior-y: none
  }

  .md\:overscroll-x-auto {
    overscroll-behavior-x: auto
  }

  .md\:overscroll-x-contain {
    overscroll-behavior-x: contain
  }

  .md\:overscroll-x-none {
    overscroll-behavior-x: none
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:p-1 {
    padding: 0.1rem
  }

  .md\:p-2 {
    padding: 0.2rem
  }

  .md\:p-3 {
    padding: 0.3rem
  }

  .md\:p-4 {
    padding: 0.4rem
  }

  .md\:p-5 {
    padding: 0.5rem
  }

  .md\:p-6 {
    padding: 0.6rem
  }

  .md\:p-7 {
    padding: 0.7rem
  }

  .md\:p-8 {
    padding: 0.8rem
  }

  .md\:p-9 {
    padding: 0.9rem
  }

  .md\:p-10 {
    padding: 1.0rem
  }

  .md\:p-12 {
    padding: 1.2rem
  }

  .md\:p-14 {
    padding: 1.4rem
  }

  .md\:p-16 {
    padding: 1.6rem
  }

  .md\:p-20 {
    padding: 2rem
  }

  .md\:p-24 {
    padding: 2.4rem
  }

  .md\:p-28 {
    padding: 2.8rem
  }

  .md\:p-32 {
    padding: 3.2rem
  }

  .md\:p-36 {
    padding: 3.6rem
  }

  .md\:p-40 {
    padding: 4rem
  }

  .md\:p-44 {
    padding: 4.4rem
  }

  .md\:p-48 {
    padding: 4.8rem
  }

  .md\:p-52 {
    padding: 5.2rem
  }

  .md\:p-56 {
    padding: 5.6rem
  }

  .md\:p-60 {
    padding: 6rem
  }

  .md\:p-64 {
    padding: 6.4rem
  }

  .md\:p-68 {
    padding: 6.8rem
  }

  .md\:p-72 {
    padding: 7.2rem
  }

  .md\:p-76 {
    padding: 7.6rem
  }

  .md\:p-80 {
    padding: 8rem
  }

  .md\:p-84 {
    padding: 8.4rem
  }

  .md\:p-88 {
    padding: 8.8rem
  }

  .md\:p-92 {
    padding: 9.2rem
  }

  .md\:p-96 {
    padding: 9.6rem
  }

  .md\:p-128 {
    padding: 12.8rem
  }

  .md\:p-136 {
    padding: 13.6rem
  }

  .md\:p-160 {
    padding: 16rem
  }

  .md\:p-192 {
    padding: 19.2rem
  }

  .md\:p-200 {
    padding: 20rem
  }

  .md\:p-208 {
    padding: 20.8rem
  }

  .md\:p-216 {
    padding: 21.6rem
  }

  .md\:p-224 {
    padding: 22.4rem
  }

  .md\:p-256 {
    padding: 25.6rem
  }

  .md\:p-288 {
    padding: 28.8rem
  }

  .md\:p-320 {
    padding: 32rem
  }

  .md\:p-360 {
    padding: 36rem
  }

  .md\:p-384 {
    padding: 38.4rem
  }

  .md\:p-400 {
    padding: 40rem
  }

  .md\:p-512 {
    padding: 51.2rem
  }

  .md\:p-640 {
    padding: 64rem
  }

  .md\:p-xs {
    padding: 32rem
  }

  .md\:p-sm {
    padding: 48rem
  }

  .md\:p-md {
    padding: 64rem
  }

  .md\:p-lg {
    padding: 80rem
  }

  .md\:p-xl {
    padding: 96rem
  }

  .md\:p-2xl {
    padding: 112rem
  }

  .md\:p-3xl {
    padding: 128rem
  }

  .md\:p-4xl {
    padding: 144rem
  }

  .md\:p-5xl {
    padding: 160rem
  }

  .md\:p-px {
    padding: 1px
  }

  .md\:p-0\.5 {
    padding: 0.05rem
  }

  .md\:p-1\.5 {
    padding: 0.15rem
  }

  .md\:p-2\.5 {
    padding: 0.25rem
  }

  .md\:p-3\.5 {
    padding: 0.35rem
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .md\:py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem
  }

  .md\:px-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem
  }

  .md\:py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem
  }

  .md\:px-2 {
    padding-left: 0.2rem;
    padding-right: 0.2rem
  }

  .md\:py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem
  }

  .md\:px-3 {
    padding-left: 0.3rem;
    padding-right: 0.3rem
  }

  .md\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  .md\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  .md\:py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .md\:px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .md\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  .md\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  .md\:py-7 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem
  }

  .md\:px-7 {
    padding-left: 0.7rem;
    padding-right: 0.7rem
  }

  .md\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  .md\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  .md\:py-9 {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem
  }

  .md\:px-9 {
    padding-left: 0.9rem;
    padding-right: 0.9rem
  }

  .md\:py-10 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
  }

  .md\:px-10 {
    padding-left: 1.0rem;
    padding-right: 1.0rem
  }

  .md\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  .md\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .md\:py-14 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem
  }

  .md\:px-14 {
    padding-left: 1.4rem;
    padding-right: 1.4rem
  }

  .md\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  .md\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  .md\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .md\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .md\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  .md\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .md\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  .md\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  .md\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  .md\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  .md\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  .md\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  .md\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .md\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .md\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  .md\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  .md\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  .md\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  .md\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  .md\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  .md\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  .md\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  .md\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .md\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .md\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  .md\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  .md\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  .md\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  .md\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  .md\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  .md\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  .md\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  .md\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .md\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .md\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  .md\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  .md\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  .md\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  .md\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  .md\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  .md\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  .md\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  .md\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  .md\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  .md\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  .md\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  .md\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .md\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .md\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  .md\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  .md\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  .md\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  .md\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  .md\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  .md\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  .md\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  .md\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  .md\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  .md\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  .md\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  .md\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  .md\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  .md\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  .md\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  .md\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  .md\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  .md\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  .md\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  .md\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  .md\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  .md\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  .md\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  .md\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  .md\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  .md\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  .md\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  .md\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  .md\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  .md\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  .md\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  .md\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  .md\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  .md\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  .md\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  .md\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  .md\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  .md\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  .md\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  .md\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  .md\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  .md\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  .md\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  .md\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .md\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .md\:py-0\.5 {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem
  }

  .md\:px-0\.5 {
    padding-left: 0.05rem;
    padding-right: 0.05rem
  }

  .md\:py-1\.5 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem
  }

  .md\:px-1\.5 {
    padding-left: 0.15rem;
    padding-right: 0.15rem
  }

  .md\:py-2\.5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .md\:px-2\.5 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .md\:py-3\.5 {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem
  }

  .md\:px-3\.5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem
  }

  .md\:pt-0 {
    padding-top: 0
  }

  .md\:pr-0 {
    padding-right: 0
  }

  .md\:pb-0 {
    padding-bottom: 0
  }

  .md\:pl-0 {
    padding-left: 0
  }

  .md\:pt-1 {
    padding-top: 0.1rem
  }

  .md\:pr-1 {
    padding-right: 0.1rem
  }

  .md\:pb-1 {
    padding-bottom: 0.1rem
  }

  .md\:pl-1 {
    padding-left: 0.1rem
  }

  .md\:pt-2 {
    padding-top: 0.2rem
  }

  .md\:pr-2 {
    padding-right: 0.2rem
  }

  .md\:pb-2 {
    padding-bottom: 0.2rem
  }

  .md\:pl-2 {
    padding-left: 0.2rem
  }

  .md\:pt-3 {
    padding-top: 0.3rem
  }

  .md\:pr-3 {
    padding-right: 0.3rem
  }

  .md\:pb-3 {
    padding-bottom: 0.3rem
  }

  .md\:pl-3 {
    padding-left: 0.3rem
  }

  .md\:pt-4 {
    padding-top: 0.4rem
  }

  .md\:pr-4 {
    padding-right: 0.4rem
  }

  .md\:pb-4 {
    padding-bottom: 0.4rem
  }

  .md\:pl-4 {
    padding-left: 0.4rem
  }

  .md\:pt-5 {
    padding-top: 0.5rem
  }

  .md\:pr-5 {
    padding-right: 0.5rem
  }

  .md\:pb-5 {
    padding-bottom: 0.5rem
  }

  .md\:pl-5 {
    padding-left: 0.5rem
  }

  .md\:pt-6 {
    padding-top: 0.6rem
  }

  .md\:pr-6 {
    padding-right: 0.6rem
  }

  .md\:pb-6 {
    padding-bottom: 0.6rem
  }

  .md\:pl-6 {
    padding-left: 0.6rem
  }

  .md\:pt-7 {
    padding-top: 0.7rem
  }

  .md\:pr-7 {
    padding-right: 0.7rem
  }

  .md\:pb-7 {
    padding-bottom: 0.7rem
  }

  .md\:pl-7 {
    padding-left: 0.7rem
  }

  .md\:pt-8 {
    padding-top: 0.8rem
  }

  .md\:pr-8 {
    padding-right: 0.8rem
  }

  .md\:pb-8 {
    padding-bottom: 0.8rem
  }

  .md\:pl-8 {
    padding-left: 0.8rem
  }

  .md\:pt-9 {
    padding-top: 0.9rem
  }

  .md\:pr-9 {
    padding-right: 0.9rem
  }

  .md\:pb-9 {
    padding-bottom: 0.9rem
  }

  .md\:pl-9 {
    padding-left: 0.9rem
  }

  .md\:pt-10 {
    padding-top: 1.0rem
  }

  .md\:pr-10 {
    padding-right: 1.0rem
  }

  .md\:pb-10 {
    padding-bottom: 1.0rem
  }

  .md\:pl-10 {
    padding-left: 1.0rem
  }

  .md\:pt-12 {
    padding-top: 1.2rem
  }

  .md\:pr-12 {
    padding-right: 1.2rem
  }

  .md\:pb-12 {
    padding-bottom: 1.2rem
  }

  .md\:pl-12 {
    padding-left: 1.2rem
  }

  .md\:pt-14 {
    padding-top: 1.4rem
  }

  .md\:pr-14 {
    padding-right: 1.4rem
  }

  .md\:pb-14 {
    padding-bottom: 1.4rem
  }

  .md\:pl-14 {
    padding-left: 1.4rem
  }

  .md\:pt-16 {
    padding-top: 1.6rem
  }

  .md\:pr-16 {
    padding-right: 1.6rem
  }

  .md\:pb-16 {
    padding-bottom: 1.6rem
  }

  .md\:pl-16 {
    padding-left: 1.6rem
  }

  .md\:pt-20 {
    padding-top: 2rem
  }

  .md\:pr-20 {
    padding-right: 2rem
  }

  .md\:pb-20 {
    padding-bottom: 2rem
  }

  .md\:pl-20 {
    padding-left: 2rem
  }

  .md\:pt-24 {
    padding-top: 2.4rem
  }

  .md\:pr-24 {
    padding-right: 2.4rem
  }

  .md\:pb-24 {
    padding-bottom: 2.4rem
  }

  .md\:pl-24 {
    padding-left: 2.4rem
  }

  .md\:pt-28 {
    padding-top: 2.8rem
  }

  .md\:pr-28 {
    padding-right: 2.8rem
  }

  .md\:pb-28 {
    padding-bottom: 2.8rem
  }

  .md\:pl-28 {
    padding-left: 2.8rem
  }

  .md\:pt-32 {
    padding-top: 3.2rem
  }

  .md\:pr-32 {
    padding-right: 3.2rem
  }

  .md\:pb-32 {
    padding-bottom: 3.2rem
  }

  .md\:pl-32 {
    padding-left: 3.2rem
  }

  .md\:pt-36 {
    padding-top: 3.6rem
  }

  .md\:pr-36 {
    padding-right: 3.6rem
  }

  .md\:pb-36 {
    padding-bottom: 3.6rem
  }

  .md\:pl-36 {
    padding-left: 3.6rem
  }

  .md\:pt-40 {
    padding-top: 4rem
  }

  .md\:pr-40 {
    padding-right: 4rem
  }

  .md\:pb-40 {
    padding-bottom: 4rem
  }

  .md\:pl-40 {
    padding-left: 4rem
  }

  .md\:pt-44 {
    padding-top: 4.4rem
  }

  .md\:pr-44 {
    padding-right: 4.4rem
  }

  .md\:pb-44 {
    padding-bottom: 4.4rem
  }

  .md\:pl-44 {
    padding-left: 4.4rem
  }

  .md\:pt-48 {
    padding-top: 4.8rem
  }

  .md\:pr-48 {
    padding-right: 4.8rem
  }

  .md\:pb-48 {
    padding-bottom: 4.8rem
  }

  .md\:pl-48 {
    padding-left: 4.8rem
  }

  .md\:pt-52 {
    padding-top: 5.2rem
  }

  .md\:pr-52 {
    padding-right: 5.2rem
  }

  .md\:pb-52 {
    padding-bottom: 5.2rem
  }

  .md\:pl-52 {
    padding-left: 5.2rem
  }

  .md\:pt-56 {
    padding-top: 5.6rem
  }

  .md\:pr-56 {
    padding-right: 5.6rem
  }

  .md\:pb-56 {
    padding-bottom: 5.6rem
  }

  .md\:pl-56 {
    padding-left: 5.6rem
  }

  .md\:pt-60 {
    padding-top: 6rem
  }

  .md\:pr-60 {
    padding-right: 6rem
  }

  .md\:pb-60 {
    padding-bottom: 6rem
  }

  .md\:pl-60 {
    padding-left: 6rem
  }

  .md\:pt-64 {
    padding-top: 6.4rem
  }

  .md\:pr-64 {
    padding-right: 6.4rem
  }

  .md\:pb-64 {
    padding-bottom: 6.4rem
  }

  .md\:pl-64 {
    padding-left: 6.4rem
  }

  .md\:pt-68 {
    padding-top: 6.8rem
  }

  .md\:pr-68 {
    padding-right: 6.8rem
  }

  .md\:pb-68 {
    padding-bottom: 6.8rem
  }

  .md\:pl-68 {
    padding-left: 6.8rem
  }

  .md\:pt-72 {
    padding-top: 7.2rem
  }

  .md\:pr-72 {
    padding-right: 7.2rem
  }

  .md\:pb-72 {
    padding-bottom: 7.2rem
  }

  .md\:pl-72 {
    padding-left: 7.2rem
  }

  .md\:pt-76 {
    padding-top: 7.6rem
  }

  .md\:pr-76 {
    padding-right: 7.6rem
  }

  .md\:pb-76 {
    padding-bottom: 7.6rem
  }

  .md\:pl-76 {
    padding-left: 7.6rem
  }

  .md\:pt-80 {
    padding-top: 8rem
  }

  .md\:pr-80 {
    padding-right: 8rem
  }

  .md\:pb-80 {
    padding-bottom: 8rem
  }

  .md\:pl-80 {
    padding-left: 8rem
  }

  .md\:pt-84 {
    padding-top: 8.4rem
  }

  .md\:pr-84 {
    padding-right: 8.4rem
  }

  .md\:pb-84 {
    padding-bottom: 8.4rem
  }

  .md\:pl-84 {
    padding-left: 8.4rem
  }

  .md\:pt-88 {
    padding-top: 8.8rem
  }

  .md\:pr-88 {
    padding-right: 8.8rem
  }

  .md\:pb-88 {
    padding-bottom: 8.8rem
  }

  .md\:pl-88 {
    padding-left: 8.8rem
  }

  .md\:pt-92 {
    padding-top: 9.2rem
  }

  .md\:pr-92 {
    padding-right: 9.2rem
  }

  .md\:pb-92 {
    padding-bottom: 9.2rem
  }

  .md\:pl-92 {
    padding-left: 9.2rem
  }

  .md\:pt-96 {
    padding-top: 9.6rem
  }

  .md\:pr-96 {
    padding-right: 9.6rem
  }

  .md\:pb-96 {
    padding-bottom: 9.6rem
  }

  .md\:pl-96 {
    padding-left: 9.6rem
  }

  .md\:pt-128 {
    padding-top: 12.8rem
  }

  .md\:pr-128 {
    padding-right: 12.8rem
  }

  .md\:pb-128 {
    padding-bottom: 12.8rem
  }

  .md\:pl-128 {
    padding-left: 12.8rem
  }

  .md\:pt-136 {
    padding-top: 13.6rem
  }

  .md\:pr-136 {
    padding-right: 13.6rem
  }

  .md\:pb-136 {
    padding-bottom: 13.6rem
  }

  .md\:pl-136 {
    padding-left: 13.6rem
  }

  .md\:pt-160 {
    padding-top: 16rem
  }

  .md\:pr-160 {
    padding-right: 16rem
  }

  .md\:pb-160 {
    padding-bottom: 16rem
  }

  .md\:pl-160 {
    padding-left: 16rem
  }

  .md\:pt-192 {
    padding-top: 19.2rem
  }

  .md\:pr-192 {
    padding-right: 19.2rem
  }

  .md\:pb-192 {
    padding-bottom: 19.2rem
  }

  .md\:pl-192 {
    padding-left: 19.2rem
  }

  .md\:pt-200 {
    padding-top: 20rem
  }

  .md\:pr-200 {
    padding-right: 20rem
  }

  .md\:pb-200 {
    padding-bottom: 20rem
  }

  .md\:pl-200 {
    padding-left: 20rem
  }

  .md\:pt-208 {
    padding-top: 20.8rem
  }

  .md\:pr-208 {
    padding-right: 20.8rem
  }

  .md\:pb-208 {
    padding-bottom: 20.8rem
  }

  .md\:pl-208 {
    padding-left: 20.8rem
  }

  .md\:pt-216 {
    padding-top: 21.6rem
  }

  .md\:pr-216 {
    padding-right: 21.6rem
  }

  .md\:pb-216 {
    padding-bottom: 21.6rem
  }

  .md\:pl-216 {
    padding-left: 21.6rem
  }

  .md\:pt-224 {
    padding-top: 22.4rem
  }

  .md\:pr-224 {
    padding-right: 22.4rem
  }

  .md\:pb-224 {
    padding-bottom: 22.4rem
  }

  .md\:pl-224 {
    padding-left: 22.4rem
  }

  .md\:pt-256 {
    padding-top: 25.6rem
  }

  .md\:pr-256 {
    padding-right: 25.6rem
  }

  .md\:pb-256 {
    padding-bottom: 25.6rem
  }

  .md\:pl-256 {
    padding-left: 25.6rem
  }

  .md\:pt-288 {
    padding-top: 28.8rem
  }

  .md\:pr-288 {
    padding-right: 28.8rem
  }

  .md\:pb-288 {
    padding-bottom: 28.8rem
  }

  .md\:pl-288 {
    padding-left: 28.8rem
  }

  .md\:pt-320 {
    padding-top: 32rem
  }

  .md\:pr-320 {
    padding-right: 32rem
  }

  .md\:pb-320 {
    padding-bottom: 32rem
  }

  .md\:pl-320 {
    padding-left: 32rem
  }

  .md\:pt-360 {
    padding-top: 36rem
  }

  .md\:pr-360 {
    padding-right: 36rem
  }

  .md\:pb-360 {
    padding-bottom: 36rem
  }

  .md\:pl-360 {
    padding-left: 36rem
  }

  .md\:pt-384 {
    padding-top: 38.4rem
  }

  .md\:pr-384 {
    padding-right: 38.4rem
  }

  .md\:pb-384 {
    padding-bottom: 38.4rem
  }

  .md\:pl-384 {
    padding-left: 38.4rem
  }

  .md\:pt-400 {
    padding-top: 40rem
  }

  .md\:pr-400 {
    padding-right: 40rem
  }

  .md\:pb-400 {
    padding-bottom: 40rem
  }

  .md\:pl-400 {
    padding-left: 40rem
  }

  .md\:pt-512 {
    padding-top: 51.2rem
  }

  .md\:pr-512 {
    padding-right: 51.2rem
  }

  .md\:pb-512 {
    padding-bottom: 51.2rem
  }

  .md\:pl-512 {
    padding-left: 51.2rem
  }

  .md\:pt-640 {
    padding-top: 64rem
  }

  .md\:pr-640 {
    padding-right: 64rem
  }

  .md\:pb-640 {
    padding-bottom: 64rem
  }

  .md\:pl-640 {
    padding-left: 64rem
  }

  .md\:pt-xs {
    padding-top: 32rem
  }

  .md\:pr-xs {
    padding-right: 32rem
  }

  .md\:pb-xs {
    padding-bottom: 32rem
  }

  .md\:pl-xs {
    padding-left: 32rem
  }

  .md\:pt-sm {
    padding-top: 48rem
  }

  .md\:pr-sm {
    padding-right: 48rem
  }

  .md\:pb-sm {
    padding-bottom: 48rem
  }

  .md\:pl-sm {
    padding-left: 48rem
  }

  .md\:pt-md {
    padding-top: 64rem
  }

  .md\:pr-md {
    padding-right: 64rem
  }

  .md\:pb-md {
    padding-bottom: 64rem
  }

  .md\:pl-md {
    padding-left: 64rem
  }

  .md\:pt-lg {
    padding-top: 80rem
  }

  .md\:pr-lg {
    padding-right: 80rem
  }

  .md\:pb-lg {
    padding-bottom: 80rem
  }

  .md\:pl-lg {
    padding-left: 80rem
  }

  .md\:pt-xl {
    padding-top: 96rem
  }

  .md\:pr-xl {
    padding-right: 96rem
  }

  .md\:pb-xl {
    padding-bottom: 96rem
  }

  .md\:pl-xl {
    padding-left: 96rem
  }

  .md\:pt-2xl {
    padding-top: 112rem
  }

  .md\:pr-2xl {
    padding-right: 112rem
  }

  .md\:pb-2xl {
    padding-bottom: 112rem
  }

  .md\:pl-2xl {
    padding-left: 112rem
  }

  .md\:pt-3xl {
    padding-top: 128rem
  }

  .md\:pr-3xl {
    padding-right: 128rem
  }

  .md\:pb-3xl {
    padding-bottom: 128rem
  }

  .md\:pl-3xl {
    padding-left: 128rem
  }

  .md\:pt-4xl {
    padding-top: 144rem
  }

  .md\:pr-4xl {
    padding-right: 144rem
  }

  .md\:pb-4xl {
    padding-bottom: 144rem
  }

  .md\:pl-4xl {
    padding-left: 144rem
  }

  .md\:pt-5xl {
    padding-top: 160rem
  }

  .md\:pr-5xl {
    padding-right: 160rem
  }

  .md\:pb-5xl {
    padding-bottom: 160rem
  }

  .md\:pl-5xl {
    padding-left: 160rem
  }

  .md\:pt-px {
    padding-top: 1px
  }

  .md\:pr-px {
    padding-right: 1px
  }

  .md\:pb-px {
    padding-bottom: 1px
  }

  .md\:pl-px {
    padding-left: 1px
  }

  .md\:pt-0\.5 {
    padding-top: 0.05rem
  }

  .md\:pr-0\.5 {
    padding-right: 0.05rem
  }

  .md\:pb-0\.5 {
    padding-bottom: 0.05rem
  }

  .md\:pl-0\.5 {
    padding-left: 0.05rem
  }

  .md\:pt-1\.5 {
    padding-top: 0.15rem
  }

  .md\:pr-1\.5 {
    padding-right: 0.15rem
  }

  .md\:pb-1\.5 {
    padding-bottom: 0.15rem
  }

  .md\:pl-1\.5 {
    padding-left: 0.15rem
  }

  .md\:pt-2\.5 {
    padding-top: 0.25rem
  }

  .md\:pr-2\.5 {
    padding-right: 0.25rem
  }

  .md\:pb-2\.5 {
    padding-bottom: 0.25rem
  }

  .md\:pl-2\.5 {
    padding-left: 0.25rem
  }

  .md\:pt-3\.5 {
    padding-top: 0.35rem
  }

  .md\:pr-3\.5 {
    padding-right: 0.35rem
  }

  .md\:pb-3\.5 {
    padding-bottom: 0.35rem
  }

  .md\:pl-3\.5 {
    padding-left: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:p-0,[dir='ltr'].md\:ltr\:p-0 {
    padding: 0
  }

  [dir='ltr'] .md\:ltr\:p-1,[dir='ltr'].md\:ltr\:p-1 {
    padding: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:p-2,[dir='ltr'].md\:ltr\:p-2 {
    padding: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:p-3,[dir='ltr'].md\:ltr\:p-3 {
    padding: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:p-4,[dir='ltr'].md\:ltr\:p-4 {
    padding: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:p-5,[dir='ltr'].md\:ltr\:p-5 {
    padding: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:p-6,[dir='ltr'].md\:ltr\:p-6 {
    padding: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:p-7,[dir='ltr'].md\:ltr\:p-7 {
    padding: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:p-8,[dir='ltr'].md\:ltr\:p-8 {
    padding: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:p-9,[dir='ltr'].md\:ltr\:p-9 {
    padding: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:p-10,[dir='ltr'].md\:ltr\:p-10 {
    padding: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:p-12,[dir='ltr'].md\:ltr\:p-12 {
    padding: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:p-14,[dir='ltr'].md\:ltr\:p-14 {
    padding: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:p-16,[dir='ltr'].md\:ltr\:p-16 {
    padding: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:p-20,[dir='ltr'].md\:ltr\:p-20 {
    padding: 2rem
  }

  [dir='ltr'] .md\:ltr\:p-24,[dir='ltr'].md\:ltr\:p-24 {
    padding: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:p-28,[dir='ltr'].md\:ltr\:p-28 {
    padding: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:p-32,[dir='ltr'].md\:ltr\:p-32 {
    padding: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:p-36,[dir='ltr'].md\:ltr\:p-36 {
    padding: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:p-40,[dir='ltr'].md\:ltr\:p-40 {
    padding: 4rem
  }

  [dir='ltr'] .md\:ltr\:p-44,[dir='ltr'].md\:ltr\:p-44 {
    padding: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:p-48,[dir='ltr'].md\:ltr\:p-48 {
    padding: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:p-52,[dir='ltr'].md\:ltr\:p-52 {
    padding: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:p-56,[dir='ltr'].md\:ltr\:p-56 {
    padding: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:p-60,[dir='ltr'].md\:ltr\:p-60 {
    padding: 6rem
  }

  [dir='ltr'] .md\:ltr\:p-64,[dir='ltr'].md\:ltr\:p-64 {
    padding: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:p-68,[dir='ltr'].md\:ltr\:p-68 {
    padding: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:p-72,[dir='ltr'].md\:ltr\:p-72 {
    padding: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:p-76,[dir='ltr'].md\:ltr\:p-76 {
    padding: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:p-80,[dir='ltr'].md\:ltr\:p-80 {
    padding: 8rem
  }

  [dir='ltr'] .md\:ltr\:p-84,[dir='ltr'].md\:ltr\:p-84 {
    padding: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:p-88,[dir='ltr'].md\:ltr\:p-88 {
    padding: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:p-92,[dir='ltr'].md\:ltr\:p-92 {
    padding: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:p-96,[dir='ltr'].md\:ltr\:p-96 {
    padding: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:p-128,[dir='ltr'].md\:ltr\:p-128 {
    padding: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:p-136,[dir='ltr'].md\:ltr\:p-136 {
    padding: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:p-160,[dir='ltr'].md\:ltr\:p-160 {
    padding: 16rem
  }

  [dir='ltr'] .md\:ltr\:p-192,[dir='ltr'].md\:ltr\:p-192 {
    padding: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:p-200,[dir='ltr'].md\:ltr\:p-200 {
    padding: 20rem
  }

  [dir='ltr'] .md\:ltr\:p-208,[dir='ltr'].md\:ltr\:p-208 {
    padding: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:p-216,[dir='ltr'].md\:ltr\:p-216 {
    padding: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:p-224,[dir='ltr'].md\:ltr\:p-224 {
    padding: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:p-256,[dir='ltr'].md\:ltr\:p-256 {
    padding: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:p-288,[dir='ltr'].md\:ltr\:p-288 {
    padding: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:p-320,[dir='ltr'].md\:ltr\:p-320 {
    padding: 32rem
  }

  [dir='ltr'] .md\:ltr\:p-360,[dir='ltr'].md\:ltr\:p-360 {
    padding: 36rem
  }

  [dir='ltr'] .md\:ltr\:p-384,[dir='ltr'].md\:ltr\:p-384 {
    padding: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:p-400,[dir='ltr'].md\:ltr\:p-400 {
    padding: 40rem
  }

  [dir='ltr'] .md\:ltr\:p-512,[dir='ltr'].md\:ltr\:p-512 {
    padding: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:p-640,[dir='ltr'].md\:ltr\:p-640 {
    padding: 64rem
  }

  [dir='ltr'] .md\:ltr\:p-xs,[dir='ltr'].md\:ltr\:p-xs {
    padding: 32rem
  }

  [dir='ltr'] .md\:ltr\:p-sm,[dir='ltr'].md\:ltr\:p-sm {
    padding: 48rem
  }

  [dir='ltr'] .md\:ltr\:p-md,[dir='ltr'].md\:ltr\:p-md {
    padding: 64rem
  }

  [dir='ltr'] .md\:ltr\:p-lg,[dir='ltr'].md\:ltr\:p-lg {
    padding: 80rem
  }

  [dir='ltr'] .md\:ltr\:p-xl,[dir='ltr'].md\:ltr\:p-xl {
    padding: 96rem
  }

  [dir='ltr'] .md\:ltr\:p-2xl,[dir='ltr'].md\:ltr\:p-2xl {
    padding: 112rem
  }

  [dir='ltr'] .md\:ltr\:p-3xl,[dir='ltr'].md\:ltr\:p-3xl {
    padding: 128rem
  }

  [dir='ltr'] .md\:ltr\:p-4xl,[dir='ltr'].md\:ltr\:p-4xl {
    padding: 144rem
  }

  [dir='ltr'] .md\:ltr\:p-5xl,[dir='ltr'].md\:ltr\:p-5xl {
    padding: 160rem
  }

  [dir='ltr'] .md\:ltr\:p-px,[dir='ltr'].md\:ltr\:p-px {
    padding: 1px
  }

  [dir='ltr'] .md\:ltr\:p-0\.5,[dir='ltr'].md\:ltr\:p-0\.5 {
    padding: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:p-1\.5,[dir='ltr'].md\:ltr\:p-1\.5 {
    padding: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:p-2\.5,[dir='ltr'].md\:ltr\:p-2\.5 {
    padding: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:p-3\.5,[dir='ltr'].md\:ltr\:p-3\.5 {
    padding: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:py-0,[dir='ltr'].md\:ltr\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  [dir='ltr'] .md\:ltr\:px-0,[dir='ltr'].md\:ltr\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  [dir='ltr'] .md\:ltr\:py-1,[dir='ltr'].md\:ltr\:py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:px-1,[dir='ltr'].md\:ltr\:px-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:py-2,[dir='ltr'].md\:ltr\:py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:px-2,[dir='ltr'].md\:ltr\:px-2 {
    padding-left: 0.2rem;
    padding-right: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:py-3,[dir='ltr'].md\:ltr\:py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:px-3,[dir='ltr'].md\:ltr\:px-3 {
    padding-left: 0.3rem;
    padding-right: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:py-4,[dir='ltr'].md\:ltr\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:px-4,[dir='ltr'].md\:ltr\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:py-5,[dir='ltr'].md\:ltr\:py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:px-5,[dir='ltr'].md\:ltr\:px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:py-6,[dir='ltr'].md\:ltr\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:px-6,[dir='ltr'].md\:ltr\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:py-7,[dir='ltr'].md\:ltr\:py-7 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:px-7,[dir='ltr'].md\:ltr\:px-7 {
    padding-left: 0.7rem;
    padding-right: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:py-8,[dir='ltr'].md\:ltr\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:px-8,[dir='ltr'].md\:ltr\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:py-9,[dir='ltr'].md\:ltr\:py-9 {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:px-9,[dir='ltr'].md\:ltr\:px-9 {
    padding-left: 0.9rem;
    padding-right: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:py-10,[dir='ltr'].md\:ltr\:py-10 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:px-10,[dir='ltr'].md\:ltr\:px-10 {
    padding-left: 1.0rem;
    padding-right: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:py-12,[dir='ltr'].md\:ltr\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:px-12,[dir='ltr'].md\:ltr\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:py-14,[dir='ltr'].md\:ltr\:py-14 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:px-14,[dir='ltr'].md\:ltr\:px-14 {
    padding-left: 1.4rem;
    padding-right: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:py-16,[dir='ltr'].md\:ltr\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:px-16,[dir='ltr'].md\:ltr\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:py-20,[dir='ltr'].md\:ltr\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  [dir='ltr'] .md\:ltr\:px-20,[dir='ltr'].md\:ltr\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  [dir='ltr'] .md\:ltr\:py-24,[dir='ltr'].md\:ltr\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:px-24,[dir='ltr'].md\:ltr\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:py-28,[dir='ltr'].md\:ltr\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:px-28,[dir='ltr'].md\:ltr\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:py-32,[dir='ltr'].md\:ltr\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:px-32,[dir='ltr'].md\:ltr\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:py-36,[dir='ltr'].md\:ltr\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:px-36,[dir='ltr'].md\:ltr\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:py-40,[dir='ltr'].md\:ltr\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  [dir='ltr'] .md\:ltr\:px-40,[dir='ltr'].md\:ltr\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  [dir='ltr'] .md\:ltr\:py-44,[dir='ltr'].md\:ltr\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:px-44,[dir='ltr'].md\:ltr\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:py-48,[dir='ltr'].md\:ltr\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:px-48,[dir='ltr'].md\:ltr\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:py-52,[dir='ltr'].md\:ltr\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:px-52,[dir='ltr'].md\:ltr\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:py-56,[dir='ltr'].md\:ltr\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:px-56,[dir='ltr'].md\:ltr\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:py-60,[dir='ltr'].md\:ltr\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  [dir='ltr'] .md\:ltr\:px-60,[dir='ltr'].md\:ltr\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  [dir='ltr'] .md\:ltr\:py-64,[dir='ltr'].md\:ltr\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:px-64,[dir='ltr'].md\:ltr\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:py-68,[dir='ltr'].md\:ltr\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:px-68,[dir='ltr'].md\:ltr\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:py-72,[dir='ltr'].md\:ltr\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:px-72,[dir='ltr'].md\:ltr\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:py-76,[dir='ltr'].md\:ltr\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:px-76,[dir='ltr'].md\:ltr\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:py-80,[dir='ltr'].md\:ltr\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  [dir='ltr'] .md\:ltr\:px-80,[dir='ltr'].md\:ltr\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  [dir='ltr'] .md\:ltr\:py-84,[dir='ltr'].md\:ltr\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:px-84,[dir='ltr'].md\:ltr\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:py-88,[dir='ltr'].md\:ltr\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:px-88,[dir='ltr'].md\:ltr\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:py-92,[dir='ltr'].md\:ltr\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:px-92,[dir='ltr'].md\:ltr\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:py-96,[dir='ltr'].md\:ltr\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:px-96,[dir='ltr'].md\:ltr\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:py-128,[dir='ltr'].md\:ltr\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:px-128,[dir='ltr'].md\:ltr\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:py-136,[dir='ltr'].md\:ltr\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:px-136,[dir='ltr'].md\:ltr\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:py-160,[dir='ltr'].md\:ltr\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  [dir='ltr'] .md\:ltr\:px-160,[dir='ltr'].md\:ltr\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  [dir='ltr'] .md\:ltr\:py-192,[dir='ltr'].md\:ltr\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:px-192,[dir='ltr'].md\:ltr\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:py-200,[dir='ltr'].md\:ltr\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  [dir='ltr'] .md\:ltr\:px-200,[dir='ltr'].md\:ltr\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  [dir='ltr'] .md\:ltr\:py-208,[dir='ltr'].md\:ltr\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:px-208,[dir='ltr'].md\:ltr\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:py-216,[dir='ltr'].md\:ltr\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:px-216,[dir='ltr'].md\:ltr\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:py-224,[dir='ltr'].md\:ltr\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:px-224,[dir='ltr'].md\:ltr\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:py-256,[dir='ltr'].md\:ltr\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:px-256,[dir='ltr'].md\:ltr\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:py-288,[dir='ltr'].md\:ltr\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:px-288,[dir='ltr'].md\:ltr\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:py-320,[dir='ltr'].md\:ltr\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='ltr'] .md\:ltr\:px-320,[dir='ltr'].md\:ltr\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='ltr'] .md\:ltr\:py-360,[dir='ltr'].md\:ltr\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  [dir='ltr'] .md\:ltr\:px-360,[dir='ltr'].md\:ltr\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  [dir='ltr'] .md\:ltr\:py-384,[dir='ltr'].md\:ltr\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:px-384,[dir='ltr'].md\:ltr\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:py-400,[dir='ltr'].md\:ltr\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  [dir='ltr'] .md\:ltr\:px-400,[dir='ltr'].md\:ltr\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  [dir='ltr'] .md\:ltr\:py-512,[dir='ltr'].md\:ltr\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:px-512,[dir='ltr'].md\:ltr\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:py-640,[dir='ltr'].md\:ltr\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='ltr'] .md\:ltr\:px-640,[dir='ltr'].md\:ltr\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='ltr'] .md\:ltr\:py-xs,[dir='ltr'].md\:ltr\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='ltr'] .md\:ltr\:px-xs,[dir='ltr'].md\:ltr\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='ltr'] .md\:ltr\:py-sm,[dir='ltr'].md\:ltr\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  [dir='ltr'] .md\:ltr\:px-sm,[dir='ltr'].md\:ltr\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  [dir='ltr'] .md\:ltr\:py-md,[dir='ltr'].md\:ltr\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='ltr'] .md\:ltr\:px-md,[dir='ltr'].md\:ltr\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='ltr'] .md\:ltr\:py-lg,[dir='ltr'].md\:ltr\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  [dir='ltr'] .md\:ltr\:px-lg,[dir='ltr'].md\:ltr\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  [dir='ltr'] .md\:ltr\:py-xl,[dir='ltr'].md\:ltr\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  [dir='ltr'] .md\:ltr\:px-xl,[dir='ltr'].md\:ltr\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  [dir='ltr'] .md\:ltr\:py-2xl,[dir='ltr'].md\:ltr\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  [dir='ltr'] .md\:ltr\:px-2xl,[dir='ltr'].md\:ltr\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  [dir='ltr'] .md\:ltr\:py-3xl,[dir='ltr'].md\:ltr\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  [dir='ltr'] .md\:ltr\:px-3xl,[dir='ltr'].md\:ltr\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  [dir='ltr'] .md\:ltr\:py-4xl,[dir='ltr'].md\:ltr\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  [dir='ltr'] .md\:ltr\:px-4xl,[dir='ltr'].md\:ltr\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  [dir='ltr'] .md\:ltr\:py-5xl,[dir='ltr'].md\:ltr\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  [dir='ltr'] .md\:ltr\:px-5xl,[dir='ltr'].md\:ltr\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  [dir='ltr'] .md\:ltr\:py-px,[dir='ltr'].md\:ltr\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  [dir='ltr'] .md\:ltr\:px-px,[dir='ltr'].md\:ltr\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  [dir='ltr'] .md\:ltr\:py-0\.5,[dir='ltr'].md\:ltr\:py-0\.5 {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:px-0\.5,[dir='ltr'].md\:ltr\:px-0\.5 {
    padding-left: 0.05rem;
    padding-right: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:py-1\.5,[dir='ltr'].md\:ltr\:py-1\.5 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:px-1\.5,[dir='ltr'].md\:ltr\:px-1\.5 {
    padding-left: 0.15rem;
    padding-right: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:py-2\.5,[dir='ltr'].md\:ltr\:py-2\.5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:px-2\.5,[dir='ltr'].md\:ltr\:px-2\.5 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:py-3\.5,[dir='ltr'].md\:ltr\:py-3\.5 {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:px-3\.5,[dir='ltr'].md\:ltr\:px-3\.5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:pt-0,[dir='ltr'].md\:ltr\:pt-0 {
    padding-top: 0
  }

  [dir='ltr'] .md\:ltr\:pr-0,[dir='ltr'].md\:ltr\:pr-0 {
    padding-right: 0
  }

  [dir='ltr'] .md\:ltr\:pb-0,[dir='ltr'].md\:ltr\:pb-0 {
    padding-bottom: 0
  }

  [dir='ltr'] .md\:ltr\:pl-0,[dir='ltr'].md\:ltr\:pl-0 {
    padding-left: 0
  }

  [dir='ltr'] .md\:ltr\:pt-1,[dir='ltr'].md\:ltr\:pt-1 {
    padding-top: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:pr-1,[dir='ltr'].md\:ltr\:pr-1 {
    padding-right: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:pb-1,[dir='ltr'].md\:ltr\:pb-1 {
    padding-bottom: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:pl-1,[dir='ltr'].md\:ltr\:pl-1 {
    padding-left: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:pt-2,[dir='ltr'].md\:ltr\:pt-2 {
    padding-top: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:pr-2,[dir='ltr'].md\:ltr\:pr-2 {
    padding-right: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:pb-2,[dir='ltr'].md\:ltr\:pb-2 {
    padding-bottom: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:pl-2,[dir='ltr'].md\:ltr\:pl-2 {
    padding-left: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:pt-3,[dir='ltr'].md\:ltr\:pt-3 {
    padding-top: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:pr-3,[dir='ltr'].md\:ltr\:pr-3 {
    padding-right: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:pb-3,[dir='ltr'].md\:ltr\:pb-3 {
    padding-bottom: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:pl-3,[dir='ltr'].md\:ltr\:pl-3 {
    padding-left: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:pt-4,[dir='ltr'].md\:ltr\:pt-4 {
    padding-top: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:pr-4,[dir='ltr'].md\:ltr\:pr-4 {
    padding-right: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:pb-4,[dir='ltr'].md\:ltr\:pb-4 {
    padding-bottom: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:pl-4,[dir='ltr'].md\:ltr\:pl-4 {
    padding-left: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:pt-5,[dir='ltr'].md\:ltr\:pt-5 {
    padding-top: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:pr-5,[dir='ltr'].md\:ltr\:pr-5 {
    padding-right: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:pb-5,[dir='ltr'].md\:ltr\:pb-5 {
    padding-bottom: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:pl-5,[dir='ltr'].md\:ltr\:pl-5 {
    padding-left: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:pt-6,[dir='ltr'].md\:ltr\:pt-6 {
    padding-top: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:pr-6,[dir='ltr'].md\:ltr\:pr-6 {
    padding-right: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:pb-6,[dir='ltr'].md\:ltr\:pb-6 {
    padding-bottom: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:pl-6,[dir='ltr'].md\:ltr\:pl-6 {
    padding-left: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:pt-7,[dir='ltr'].md\:ltr\:pt-7 {
    padding-top: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:pr-7,[dir='ltr'].md\:ltr\:pr-7 {
    padding-right: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:pb-7,[dir='ltr'].md\:ltr\:pb-7 {
    padding-bottom: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:pl-7,[dir='ltr'].md\:ltr\:pl-7 {
    padding-left: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:pt-8,[dir='ltr'].md\:ltr\:pt-8 {
    padding-top: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:pr-8,[dir='ltr'].md\:ltr\:pr-8 {
    padding-right: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:pb-8,[dir='ltr'].md\:ltr\:pb-8 {
    padding-bottom: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:pl-8,[dir='ltr'].md\:ltr\:pl-8 {
    padding-left: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:pt-9,[dir='ltr'].md\:ltr\:pt-9 {
    padding-top: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:pr-9,[dir='ltr'].md\:ltr\:pr-9 {
    padding-right: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:pb-9,[dir='ltr'].md\:ltr\:pb-9 {
    padding-bottom: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:pl-9,[dir='ltr'].md\:ltr\:pl-9 {
    padding-left: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:pt-10,[dir='ltr'].md\:ltr\:pt-10 {
    padding-top: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:pr-10,[dir='ltr'].md\:ltr\:pr-10 {
    padding-right: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:pb-10,[dir='ltr'].md\:ltr\:pb-10 {
    padding-bottom: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:pl-10,[dir='ltr'].md\:ltr\:pl-10 {
    padding-left: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:pt-12,[dir='ltr'].md\:ltr\:pt-12 {
    padding-top: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:pr-12,[dir='ltr'].md\:ltr\:pr-12 {
    padding-right: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:pb-12,[dir='ltr'].md\:ltr\:pb-12 {
    padding-bottom: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:pl-12,[dir='ltr'].md\:ltr\:pl-12 {
    padding-left: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:pt-14,[dir='ltr'].md\:ltr\:pt-14 {
    padding-top: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:pr-14,[dir='ltr'].md\:ltr\:pr-14 {
    padding-right: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:pb-14,[dir='ltr'].md\:ltr\:pb-14 {
    padding-bottom: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:pl-14,[dir='ltr'].md\:ltr\:pl-14 {
    padding-left: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:pt-16,[dir='ltr'].md\:ltr\:pt-16 {
    padding-top: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:pr-16,[dir='ltr'].md\:ltr\:pr-16 {
    padding-right: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:pb-16,[dir='ltr'].md\:ltr\:pb-16 {
    padding-bottom: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:pl-16,[dir='ltr'].md\:ltr\:pl-16 {
    padding-left: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:pt-20,[dir='ltr'].md\:ltr\:pt-20 {
    padding-top: 2rem
  }

  [dir='ltr'] .md\:ltr\:pr-20,[dir='ltr'].md\:ltr\:pr-20 {
    padding-right: 2rem
  }

  [dir='ltr'] .md\:ltr\:pb-20,[dir='ltr'].md\:ltr\:pb-20 {
    padding-bottom: 2rem
  }

  [dir='ltr'] .md\:ltr\:pl-20,[dir='ltr'].md\:ltr\:pl-20 {
    padding-left: 2rem
  }

  [dir='ltr'] .md\:ltr\:pt-24,[dir='ltr'].md\:ltr\:pt-24 {
    padding-top: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:pr-24,[dir='ltr'].md\:ltr\:pr-24 {
    padding-right: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:pb-24,[dir='ltr'].md\:ltr\:pb-24 {
    padding-bottom: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:pl-24,[dir='ltr'].md\:ltr\:pl-24 {
    padding-left: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:pt-28,[dir='ltr'].md\:ltr\:pt-28 {
    padding-top: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:pr-28,[dir='ltr'].md\:ltr\:pr-28 {
    padding-right: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:pb-28,[dir='ltr'].md\:ltr\:pb-28 {
    padding-bottom: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:pl-28,[dir='ltr'].md\:ltr\:pl-28 {
    padding-left: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:pt-32,[dir='ltr'].md\:ltr\:pt-32 {
    padding-top: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:pr-32,[dir='ltr'].md\:ltr\:pr-32 {
    padding-right: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:pb-32,[dir='ltr'].md\:ltr\:pb-32 {
    padding-bottom: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:pl-32,[dir='ltr'].md\:ltr\:pl-32 {
    padding-left: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:pt-36,[dir='ltr'].md\:ltr\:pt-36 {
    padding-top: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:pr-36,[dir='ltr'].md\:ltr\:pr-36 {
    padding-right: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:pb-36,[dir='ltr'].md\:ltr\:pb-36 {
    padding-bottom: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:pl-36,[dir='ltr'].md\:ltr\:pl-36 {
    padding-left: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:pt-40,[dir='ltr'].md\:ltr\:pt-40 {
    padding-top: 4rem
  }

  [dir='ltr'] .md\:ltr\:pr-40,[dir='ltr'].md\:ltr\:pr-40 {
    padding-right: 4rem
  }

  [dir='ltr'] .md\:ltr\:pb-40,[dir='ltr'].md\:ltr\:pb-40 {
    padding-bottom: 4rem
  }

  [dir='ltr'] .md\:ltr\:pl-40,[dir='ltr'].md\:ltr\:pl-40 {
    padding-left: 4rem
  }

  [dir='ltr'] .md\:ltr\:pt-44,[dir='ltr'].md\:ltr\:pt-44 {
    padding-top: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:pr-44,[dir='ltr'].md\:ltr\:pr-44 {
    padding-right: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:pb-44,[dir='ltr'].md\:ltr\:pb-44 {
    padding-bottom: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:pl-44,[dir='ltr'].md\:ltr\:pl-44 {
    padding-left: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:pt-48,[dir='ltr'].md\:ltr\:pt-48 {
    padding-top: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:pr-48,[dir='ltr'].md\:ltr\:pr-48 {
    padding-right: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:pb-48,[dir='ltr'].md\:ltr\:pb-48 {
    padding-bottom: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:pl-48,[dir='ltr'].md\:ltr\:pl-48 {
    padding-left: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:pt-52,[dir='ltr'].md\:ltr\:pt-52 {
    padding-top: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:pr-52,[dir='ltr'].md\:ltr\:pr-52 {
    padding-right: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:pb-52,[dir='ltr'].md\:ltr\:pb-52 {
    padding-bottom: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:pl-52,[dir='ltr'].md\:ltr\:pl-52 {
    padding-left: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:pt-56,[dir='ltr'].md\:ltr\:pt-56 {
    padding-top: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:pr-56,[dir='ltr'].md\:ltr\:pr-56 {
    padding-right: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:pb-56,[dir='ltr'].md\:ltr\:pb-56 {
    padding-bottom: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:pl-56,[dir='ltr'].md\:ltr\:pl-56 {
    padding-left: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:pt-60,[dir='ltr'].md\:ltr\:pt-60 {
    padding-top: 6rem
  }

  [dir='ltr'] .md\:ltr\:pr-60,[dir='ltr'].md\:ltr\:pr-60 {
    padding-right: 6rem
  }

  [dir='ltr'] .md\:ltr\:pb-60,[dir='ltr'].md\:ltr\:pb-60 {
    padding-bottom: 6rem
  }

  [dir='ltr'] .md\:ltr\:pl-60,[dir='ltr'].md\:ltr\:pl-60 {
    padding-left: 6rem
  }

  [dir='ltr'] .md\:ltr\:pt-64,[dir='ltr'].md\:ltr\:pt-64 {
    padding-top: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:pr-64,[dir='ltr'].md\:ltr\:pr-64 {
    padding-right: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:pb-64,[dir='ltr'].md\:ltr\:pb-64 {
    padding-bottom: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:pl-64,[dir='ltr'].md\:ltr\:pl-64 {
    padding-left: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:pt-68,[dir='ltr'].md\:ltr\:pt-68 {
    padding-top: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:pr-68,[dir='ltr'].md\:ltr\:pr-68 {
    padding-right: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:pb-68,[dir='ltr'].md\:ltr\:pb-68 {
    padding-bottom: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:pl-68,[dir='ltr'].md\:ltr\:pl-68 {
    padding-left: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:pt-72,[dir='ltr'].md\:ltr\:pt-72 {
    padding-top: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:pr-72,[dir='ltr'].md\:ltr\:pr-72 {
    padding-right: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:pb-72,[dir='ltr'].md\:ltr\:pb-72 {
    padding-bottom: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:pl-72,[dir='ltr'].md\:ltr\:pl-72 {
    padding-left: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:pt-76,[dir='ltr'].md\:ltr\:pt-76 {
    padding-top: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:pr-76,[dir='ltr'].md\:ltr\:pr-76 {
    padding-right: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:pb-76,[dir='ltr'].md\:ltr\:pb-76 {
    padding-bottom: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:pl-76,[dir='ltr'].md\:ltr\:pl-76 {
    padding-left: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:pt-80,[dir='ltr'].md\:ltr\:pt-80 {
    padding-top: 8rem
  }

  [dir='ltr'] .md\:ltr\:pr-80,[dir='ltr'].md\:ltr\:pr-80 {
    padding-right: 8rem
  }

  [dir='ltr'] .md\:ltr\:pb-80,[dir='ltr'].md\:ltr\:pb-80 {
    padding-bottom: 8rem
  }

  [dir='ltr'] .md\:ltr\:pl-80,[dir='ltr'].md\:ltr\:pl-80 {
    padding-left: 8rem
  }

  [dir='ltr'] .md\:ltr\:pt-84,[dir='ltr'].md\:ltr\:pt-84 {
    padding-top: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:pr-84,[dir='ltr'].md\:ltr\:pr-84 {
    padding-right: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:pb-84,[dir='ltr'].md\:ltr\:pb-84 {
    padding-bottom: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:pl-84,[dir='ltr'].md\:ltr\:pl-84 {
    padding-left: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:pt-88,[dir='ltr'].md\:ltr\:pt-88 {
    padding-top: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:pr-88,[dir='ltr'].md\:ltr\:pr-88 {
    padding-right: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:pb-88,[dir='ltr'].md\:ltr\:pb-88 {
    padding-bottom: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:pl-88,[dir='ltr'].md\:ltr\:pl-88 {
    padding-left: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:pt-92,[dir='ltr'].md\:ltr\:pt-92 {
    padding-top: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:pr-92,[dir='ltr'].md\:ltr\:pr-92 {
    padding-right: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:pb-92,[dir='ltr'].md\:ltr\:pb-92 {
    padding-bottom: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:pl-92,[dir='ltr'].md\:ltr\:pl-92 {
    padding-left: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:pt-96,[dir='ltr'].md\:ltr\:pt-96 {
    padding-top: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:pr-96,[dir='ltr'].md\:ltr\:pr-96 {
    padding-right: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:pb-96,[dir='ltr'].md\:ltr\:pb-96 {
    padding-bottom: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:pl-96,[dir='ltr'].md\:ltr\:pl-96 {
    padding-left: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:pt-128,[dir='ltr'].md\:ltr\:pt-128 {
    padding-top: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:pr-128,[dir='ltr'].md\:ltr\:pr-128 {
    padding-right: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:pb-128,[dir='ltr'].md\:ltr\:pb-128 {
    padding-bottom: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:pl-128,[dir='ltr'].md\:ltr\:pl-128 {
    padding-left: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:pt-136,[dir='ltr'].md\:ltr\:pt-136 {
    padding-top: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:pr-136,[dir='ltr'].md\:ltr\:pr-136 {
    padding-right: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:pb-136,[dir='ltr'].md\:ltr\:pb-136 {
    padding-bottom: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:pl-136,[dir='ltr'].md\:ltr\:pl-136 {
    padding-left: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:pt-160,[dir='ltr'].md\:ltr\:pt-160 {
    padding-top: 16rem
  }

  [dir='ltr'] .md\:ltr\:pr-160,[dir='ltr'].md\:ltr\:pr-160 {
    padding-right: 16rem
  }

  [dir='ltr'] .md\:ltr\:pb-160,[dir='ltr'].md\:ltr\:pb-160 {
    padding-bottom: 16rem
  }

  [dir='ltr'] .md\:ltr\:pl-160,[dir='ltr'].md\:ltr\:pl-160 {
    padding-left: 16rem
  }

  [dir='ltr'] .md\:ltr\:pt-192,[dir='ltr'].md\:ltr\:pt-192 {
    padding-top: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:pr-192,[dir='ltr'].md\:ltr\:pr-192 {
    padding-right: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:pb-192,[dir='ltr'].md\:ltr\:pb-192 {
    padding-bottom: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:pl-192,[dir='ltr'].md\:ltr\:pl-192 {
    padding-left: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:pt-200,[dir='ltr'].md\:ltr\:pt-200 {
    padding-top: 20rem
  }

  [dir='ltr'] .md\:ltr\:pr-200,[dir='ltr'].md\:ltr\:pr-200 {
    padding-right: 20rem
  }

  [dir='ltr'] .md\:ltr\:pb-200,[dir='ltr'].md\:ltr\:pb-200 {
    padding-bottom: 20rem
  }

  [dir='ltr'] .md\:ltr\:pl-200,[dir='ltr'].md\:ltr\:pl-200 {
    padding-left: 20rem
  }

  [dir='ltr'] .md\:ltr\:pt-208,[dir='ltr'].md\:ltr\:pt-208 {
    padding-top: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:pr-208,[dir='ltr'].md\:ltr\:pr-208 {
    padding-right: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:pb-208,[dir='ltr'].md\:ltr\:pb-208 {
    padding-bottom: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:pl-208,[dir='ltr'].md\:ltr\:pl-208 {
    padding-left: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:pt-216,[dir='ltr'].md\:ltr\:pt-216 {
    padding-top: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:pr-216,[dir='ltr'].md\:ltr\:pr-216 {
    padding-right: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:pb-216,[dir='ltr'].md\:ltr\:pb-216 {
    padding-bottom: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:pl-216,[dir='ltr'].md\:ltr\:pl-216 {
    padding-left: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:pt-224,[dir='ltr'].md\:ltr\:pt-224 {
    padding-top: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:pr-224,[dir='ltr'].md\:ltr\:pr-224 {
    padding-right: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:pb-224,[dir='ltr'].md\:ltr\:pb-224 {
    padding-bottom: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:pl-224,[dir='ltr'].md\:ltr\:pl-224 {
    padding-left: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:pt-256,[dir='ltr'].md\:ltr\:pt-256 {
    padding-top: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:pr-256,[dir='ltr'].md\:ltr\:pr-256 {
    padding-right: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:pb-256,[dir='ltr'].md\:ltr\:pb-256 {
    padding-bottom: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:pl-256,[dir='ltr'].md\:ltr\:pl-256 {
    padding-left: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:pt-288,[dir='ltr'].md\:ltr\:pt-288 {
    padding-top: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:pr-288,[dir='ltr'].md\:ltr\:pr-288 {
    padding-right: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:pb-288,[dir='ltr'].md\:ltr\:pb-288 {
    padding-bottom: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:pl-288,[dir='ltr'].md\:ltr\:pl-288 {
    padding-left: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:pt-320,[dir='ltr'].md\:ltr\:pt-320 {
    padding-top: 32rem
  }

  [dir='ltr'] .md\:ltr\:pr-320,[dir='ltr'].md\:ltr\:pr-320 {
    padding-right: 32rem
  }

  [dir='ltr'] .md\:ltr\:pb-320,[dir='ltr'].md\:ltr\:pb-320 {
    padding-bottom: 32rem
  }

  [dir='ltr'] .md\:ltr\:pl-320,[dir='ltr'].md\:ltr\:pl-320 {
    padding-left: 32rem
  }

  [dir='ltr'] .md\:ltr\:pt-360,[dir='ltr'].md\:ltr\:pt-360 {
    padding-top: 36rem
  }

  [dir='ltr'] .md\:ltr\:pr-360,[dir='ltr'].md\:ltr\:pr-360 {
    padding-right: 36rem
  }

  [dir='ltr'] .md\:ltr\:pb-360,[dir='ltr'].md\:ltr\:pb-360 {
    padding-bottom: 36rem
  }

  [dir='ltr'] .md\:ltr\:pl-360,[dir='ltr'].md\:ltr\:pl-360 {
    padding-left: 36rem
  }

  [dir='ltr'] .md\:ltr\:pt-384,[dir='ltr'].md\:ltr\:pt-384 {
    padding-top: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:pr-384,[dir='ltr'].md\:ltr\:pr-384 {
    padding-right: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:pb-384,[dir='ltr'].md\:ltr\:pb-384 {
    padding-bottom: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:pl-384,[dir='ltr'].md\:ltr\:pl-384 {
    padding-left: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:pt-400,[dir='ltr'].md\:ltr\:pt-400 {
    padding-top: 40rem
  }

  [dir='ltr'] .md\:ltr\:pr-400,[dir='ltr'].md\:ltr\:pr-400 {
    padding-right: 40rem
  }

  [dir='ltr'] .md\:ltr\:pb-400,[dir='ltr'].md\:ltr\:pb-400 {
    padding-bottom: 40rem
  }

  [dir='ltr'] .md\:ltr\:pl-400,[dir='ltr'].md\:ltr\:pl-400 {
    padding-left: 40rem
  }

  [dir='ltr'] .md\:ltr\:pt-512,[dir='ltr'].md\:ltr\:pt-512 {
    padding-top: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:pr-512,[dir='ltr'].md\:ltr\:pr-512 {
    padding-right: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:pb-512,[dir='ltr'].md\:ltr\:pb-512 {
    padding-bottom: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:pl-512,[dir='ltr'].md\:ltr\:pl-512 {
    padding-left: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:pt-640,[dir='ltr'].md\:ltr\:pt-640 {
    padding-top: 64rem
  }

  [dir='ltr'] .md\:ltr\:pr-640,[dir='ltr'].md\:ltr\:pr-640 {
    padding-right: 64rem
  }

  [dir='ltr'] .md\:ltr\:pb-640,[dir='ltr'].md\:ltr\:pb-640 {
    padding-bottom: 64rem
  }

  [dir='ltr'] .md\:ltr\:pl-640,[dir='ltr'].md\:ltr\:pl-640 {
    padding-left: 64rem
  }

  [dir='ltr'] .md\:ltr\:pt-xs,[dir='ltr'].md\:ltr\:pt-xs {
    padding-top: 32rem
  }

  [dir='ltr'] .md\:ltr\:pr-xs,[dir='ltr'].md\:ltr\:pr-xs {
    padding-right: 32rem
  }

  [dir='ltr'] .md\:ltr\:pb-xs,[dir='ltr'].md\:ltr\:pb-xs {
    padding-bottom: 32rem
  }

  [dir='ltr'] .md\:ltr\:pl-xs,[dir='ltr'].md\:ltr\:pl-xs {
    padding-left: 32rem
  }

  [dir='ltr'] .md\:ltr\:pt-sm,[dir='ltr'].md\:ltr\:pt-sm {
    padding-top: 48rem
  }

  [dir='ltr'] .md\:ltr\:pr-sm,[dir='ltr'].md\:ltr\:pr-sm {
    padding-right: 48rem
  }

  [dir='ltr'] .md\:ltr\:pb-sm,[dir='ltr'].md\:ltr\:pb-sm {
    padding-bottom: 48rem
  }

  [dir='ltr'] .md\:ltr\:pl-sm,[dir='ltr'].md\:ltr\:pl-sm {
    padding-left: 48rem
  }

  [dir='ltr'] .md\:ltr\:pt-md,[dir='ltr'].md\:ltr\:pt-md {
    padding-top: 64rem
  }

  [dir='ltr'] .md\:ltr\:pr-md,[dir='ltr'].md\:ltr\:pr-md {
    padding-right: 64rem
  }

  [dir='ltr'] .md\:ltr\:pb-md,[dir='ltr'].md\:ltr\:pb-md {
    padding-bottom: 64rem
  }

  [dir='ltr'] .md\:ltr\:pl-md,[dir='ltr'].md\:ltr\:pl-md {
    padding-left: 64rem
  }

  [dir='ltr'] .md\:ltr\:pt-lg,[dir='ltr'].md\:ltr\:pt-lg {
    padding-top: 80rem
  }

  [dir='ltr'] .md\:ltr\:pr-lg,[dir='ltr'].md\:ltr\:pr-lg {
    padding-right: 80rem
  }

  [dir='ltr'] .md\:ltr\:pb-lg,[dir='ltr'].md\:ltr\:pb-lg {
    padding-bottom: 80rem
  }

  [dir='ltr'] .md\:ltr\:pl-lg,[dir='ltr'].md\:ltr\:pl-lg {
    padding-left: 80rem
  }

  [dir='ltr'] .md\:ltr\:pt-xl,[dir='ltr'].md\:ltr\:pt-xl {
    padding-top: 96rem
  }

  [dir='ltr'] .md\:ltr\:pr-xl,[dir='ltr'].md\:ltr\:pr-xl {
    padding-right: 96rem
  }

  [dir='ltr'] .md\:ltr\:pb-xl,[dir='ltr'].md\:ltr\:pb-xl {
    padding-bottom: 96rem
  }

  [dir='ltr'] .md\:ltr\:pl-xl,[dir='ltr'].md\:ltr\:pl-xl {
    padding-left: 96rem
  }

  [dir='ltr'] .md\:ltr\:pt-2xl,[dir='ltr'].md\:ltr\:pt-2xl {
    padding-top: 112rem
  }

  [dir='ltr'] .md\:ltr\:pr-2xl,[dir='ltr'].md\:ltr\:pr-2xl {
    padding-right: 112rem
  }

  [dir='ltr'] .md\:ltr\:pb-2xl,[dir='ltr'].md\:ltr\:pb-2xl {
    padding-bottom: 112rem
  }

  [dir='ltr'] .md\:ltr\:pl-2xl,[dir='ltr'].md\:ltr\:pl-2xl {
    padding-left: 112rem
  }

  [dir='ltr'] .md\:ltr\:pt-3xl,[dir='ltr'].md\:ltr\:pt-3xl {
    padding-top: 128rem
  }

  [dir='ltr'] .md\:ltr\:pr-3xl,[dir='ltr'].md\:ltr\:pr-3xl {
    padding-right: 128rem
  }

  [dir='ltr'] .md\:ltr\:pb-3xl,[dir='ltr'].md\:ltr\:pb-3xl {
    padding-bottom: 128rem
  }

  [dir='ltr'] .md\:ltr\:pl-3xl,[dir='ltr'].md\:ltr\:pl-3xl {
    padding-left: 128rem
  }

  [dir='ltr'] .md\:ltr\:pt-4xl,[dir='ltr'].md\:ltr\:pt-4xl {
    padding-top: 144rem
  }

  [dir='ltr'] .md\:ltr\:pr-4xl,[dir='ltr'].md\:ltr\:pr-4xl {
    padding-right: 144rem
  }

  [dir='ltr'] .md\:ltr\:pb-4xl,[dir='ltr'].md\:ltr\:pb-4xl {
    padding-bottom: 144rem
  }

  [dir='ltr'] .md\:ltr\:pl-4xl,[dir='ltr'].md\:ltr\:pl-4xl {
    padding-left: 144rem
  }

  [dir='ltr'] .md\:ltr\:pt-5xl,[dir='ltr'].md\:ltr\:pt-5xl {
    padding-top: 160rem
  }

  [dir='ltr'] .md\:ltr\:pr-5xl,[dir='ltr'].md\:ltr\:pr-5xl {
    padding-right: 160rem
  }

  [dir='ltr'] .md\:ltr\:pb-5xl,[dir='ltr'].md\:ltr\:pb-5xl {
    padding-bottom: 160rem
  }

  [dir='ltr'] .md\:ltr\:pl-5xl,[dir='ltr'].md\:ltr\:pl-5xl {
    padding-left: 160rem
  }

  [dir='ltr'] .md\:ltr\:pt-px,[dir='ltr'].md\:ltr\:pt-px {
    padding-top: 1px
  }

  [dir='ltr'] .md\:ltr\:pr-px,[dir='ltr'].md\:ltr\:pr-px {
    padding-right: 1px
  }

  [dir='ltr'] .md\:ltr\:pb-px,[dir='ltr'].md\:ltr\:pb-px {
    padding-bottom: 1px
  }

  [dir='ltr'] .md\:ltr\:pl-px,[dir='ltr'].md\:ltr\:pl-px {
    padding-left: 1px
  }

  [dir='ltr'] .md\:ltr\:pt-0\.5,[dir='ltr'].md\:ltr\:pt-0\.5 {
    padding-top: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:pr-0\.5,[dir='ltr'].md\:ltr\:pr-0\.5 {
    padding-right: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:pb-0\.5,[dir='ltr'].md\:ltr\:pb-0\.5 {
    padding-bottom: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:pl-0\.5,[dir='ltr'].md\:ltr\:pl-0\.5 {
    padding-left: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:pt-1\.5,[dir='ltr'].md\:ltr\:pt-1\.5 {
    padding-top: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:pr-1\.5,[dir='ltr'].md\:ltr\:pr-1\.5 {
    padding-right: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:pb-1\.5,[dir='ltr'].md\:ltr\:pb-1\.5 {
    padding-bottom: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:pl-1\.5,[dir='ltr'].md\:ltr\:pl-1\.5 {
    padding-left: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:pt-2\.5,[dir='ltr'].md\:ltr\:pt-2\.5 {
    padding-top: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:pr-2\.5,[dir='ltr'].md\:ltr\:pr-2\.5 {
    padding-right: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:pb-2\.5,[dir='ltr'].md\:ltr\:pb-2\.5 {
    padding-bottom: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:pl-2\.5,[dir='ltr'].md\:ltr\:pl-2\.5 {
    padding-left: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:pt-3\.5,[dir='ltr'].md\:ltr\:pt-3\.5 {
    padding-top: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:pr-3\.5,[dir='ltr'].md\:ltr\:pr-3\.5 {
    padding-right: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:pb-3\.5,[dir='ltr'].md\:ltr\:pb-3\.5 {
    padding-bottom: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:pl-3\.5,[dir='ltr'].md\:ltr\:pl-3\.5 {
    padding-left: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:p-0,[dir='rtl'].md\:rtl\:p-0 {
    padding: 0
  }

  [dir='rtl'] .md\:rtl\:p-1,[dir='rtl'].md\:rtl\:p-1 {
    padding: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:p-2,[dir='rtl'].md\:rtl\:p-2 {
    padding: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:p-3,[dir='rtl'].md\:rtl\:p-3 {
    padding: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:p-4,[dir='rtl'].md\:rtl\:p-4 {
    padding: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:p-5,[dir='rtl'].md\:rtl\:p-5 {
    padding: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:p-6,[dir='rtl'].md\:rtl\:p-6 {
    padding: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:p-7,[dir='rtl'].md\:rtl\:p-7 {
    padding: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:p-8,[dir='rtl'].md\:rtl\:p-8 {
    padding: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:p-9,[dir='rtl'].md\:rtl\:p-9 {
    padding: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:p-10,[dir='rtl'].md\:rtl\:p-10 {
    padding: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:p-12,[dir='rtl'].md\:rtl\:p-12 {
    padding: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:p-14,[dir='rtl'].md\:rtl\:p-14 {
    padding: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:p-16,[dir='rtl'].md\:rtl\:p-16 {
    padding: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:p-20,[dir='rtl'].md\:rtl\:p-20 {
    padding: 2rem
  }

  [dir='rtl'] .md\:rtl\:p-24,[dir='rtl'].md\:rtl\:p-24 {
    padding: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:p-28,[dir='rtl'].md\:rtl\:p-28 {
    padding: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:p-32,[dir='rtl'].md\:rtl\:p-32 {
    padding: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:p-36,[dir='rtl'].md\:rtl\:p-36 {
    padding: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:p-40,[dir='rtl'].md\:rtl\:p-40 {
    padding: 4rem
  }

  [dir='rtl'] .md\:rtl\:p-44,[dir='rtl'].md\:rtl\:p-44 {
    padding: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:p-48,[dir='rtl'].md\:rtl\:p-48 {
    padding: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:p-52,[dir='rtl'].md\:rtl\:p-52 {
    padding: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:p-56,[dir='rtl'].md\:rtl\:p-56 {
    padding: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:p-60,[dir='rtl'].md\:rtl\:p-60 {
    padding: 6rem
  }

  [dir='rtl'] .md\:rtl\:p-64,[dir='rtl'].md\:rtl\:p-64 {
    padding: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:p-68,[dir='rtl'].md\:rtl\:p-68 {
    padding: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:p-72,[dir='rtl'].md\:rtl\:p-72 {
    padding: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:p-76,[dir='rtl'].md\:rtl\:p-76 {
    padding: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:p-80,[dir='rtl'].md\:rtl\:p-80 {
    padding: 8rem
  }

  [dir='rtl'] .md\:rtl\:p-84,[dir='rtl'].md\:rtl\:p-84 {
    padding: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:p-88,[dir='rtl'].md\:rtl\:p-88 {
    padding: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:p-92,[dir='rtl'].md\:rtl\:p-92 {
    padding: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:p-96,[dir='rtl'].md\:rtl\:p-96 {
    padding: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:p-128,[dir='rtl'].md\:rtl\:p-128 {
    padding: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:p-136,[dir='rtl'].md\:rtl\:p-136 {
    padding: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:p-160,[dir='rtl'].md\:rtl\:p-160 {
    padding: 16rem
  }

  [dir='rtl'] .md\:rtl\:p-192,[dir='rtl'].md\:rtl\:p-192 {
    padding: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:p-200,[dir='rtl'].md\:rtl\:p-200 {
    padding: 20rem
  }

  [dir='rtl'] .md\:rtl\:p-208,[dir='rtl'].md\:rtl\:p-208 {
    padding: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:p-216,[dir='rtl'].md\:rtl\:p-216 {
    padding: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:p-224,[dir='rtl'].md\:rtl\:p-224 {
    padding: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:p-256,[dir='rtl'].md\:rtl\:p-256 {
    padding: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:p-288,[dir='rtl'].md\:rtl\:p-288 {
    padding: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:p-320,[dir='rtl'].md\:rtl\:p-320 {
    padding: 32rem
  }

  [dir='rtl'] .md\:rtl\:p-360,[dir='rtl'].md\:rtl\:p-360 {
    padding: 36rem
  }

  [dir='rtl'] .md\:rtl\:p-384,[dir='rtl'].md\:rtl\:p-384 {
    padding: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:p-400,[dir='rtl'].md\:rtl\:p-400 {
    padding: 40rem
  }

  [dir='rtl'] .md\:rtl\:p-512,[dir='rtl'].md\:rtl\:p-512 {
    padding: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:p-640,[dir='rtl'].md\:rtl\:p-640 {
    padding: 64rem
  }

  [dir='rtl'] .md\:rtl\:p-xs,[dir='rtl'].md\:rtl\:p-xs {
    padding: 32rem
  }

  [dir='rtl'] .md\:rtl\:p-sm,[dir='rtl'].md\:rtl\:p-sm {
    padding: 48rem
  }

  [dir='rtl'] .md\:rtl\:p-md,[dir='rtl'].md\:rtl\:p-md {
    padding: 64rem
  }

  [dir='rtl'] .md\:rtl\:p-lg,[dir='rtl'].md\:rtl\:p-lg {
    padding: 80rem
  }

  [dir='rtl'] .md\:rtl\:p-xl,[dir='rtl'].md\:rtl\:p-xl {
    padding: 96rem
  }

  [dir='rtl'] .md\:rtl\:p-2xl,[dir='rtl'].md\:rtl\:p-2xl {
    padding: 112rem
  }

  [dir='rtl'] .md\:rtl\:p-3xl,[dir='rtl'].md\:rtl\:p-3xl {
    padding: 128rem
  }

  [dir='rtl'] .md\:rtl\:p-4xl,[dir='rtl'].md\:rtl\:p-4xl {
    padding: 144rem
  }

  [dir='rtl'] .md\:rtl\:p-5xl,[dir='rtl'].md\:rtl\:p-5xl {
    padding: 160rem
  }

  [dir='rtl'] .md\:rtl\:p-px,[dir='rtl'].md\:rtl\:p-px {
    padding: 1px
  }

  [dir='rtl'] .md\:rtl\:p-0\.5,[dir='rtl'].md\:rtl\:p-0\.5 {
    padding: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:p-1\.5,[dir='rtl'].md\:rtl\:p-1\.5 {
    padding: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:p-2\.5,[dir='rtl'].md\:rtl\:p-2\.5 {
    padding: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:p-3\.5,[dir='rtl'].md\:rtl\:p-3\.5 {
    padding: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:py-0,[dir='rtl'].md\:rtl\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  [dir='rtl'] .md\:rtl\:px-0,[dir='rtl'].md\:rtl\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  [dir='rtl'] .md\:rtl\:py-1,[dir='rtl'].md\:rtl\:py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:px-1,[dir='rtl'].md\:rtl\:px-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:py-2,[dir='rtl'].md\:rtl\:py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:px-2,[dir='rtl'].md\:rtl\:px-2 {
    padding-left: 0.2rem;
    padding-right: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:py-3,[dir='rtl'].md\:rtl\:py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:px-3,[dir='rtl'].md\:rtl\:px-3 {
    padding-left: 0.3rem;
    padding-right: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:py-4,[dir='rtl'].md\:rtl\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:px-4,[dir='rtl'].md\:rtl\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:py-5,[dir='rtl'].md\:rtl\:py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:px-5,[dir='rtl'].md\:rtl\:px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:py-6,[dir='rtl'].md\:rtl\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:px-6,[dir='rtl'].md\:rtl\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:py-7,[dir='rtl'].md\:rtl\:py-7 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:px-7,[dir='rtl'].md\:rtl\:px-7 {
    padding-left: 0.7rem;
    padding-right: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:py-8,[dir='rtl'].md\:rtl\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:px-8,[dir='rtl'].md\:rtl\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:py-9,[dir='rtl'].md\:rtl\:py-9 {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:px-9,[dir='rtl'].md\:rtl\:px-9 {
    padding-left: 0.9rem;
    padding-right: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:py-10,[dir='rtl'].md\:rtl\:py-10 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:px-10,[dir='rtl'].md\:rtl\:px-10 {
    padding-left: 1.0rem;
    padding-right: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:py-12,[dir='rtl'].md\:rtl\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:px-12,[dir='rtl'].md\:rtl\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:py-14,[dir='rtl'].md\:rtl\:py-14 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:px-14,[dir='rtl'].md\:rtl\:px-14 {
    padding-left: 1.4rem;
    padding-right: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:py-16,[dir='rtl'].md\:rtl\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:px-16,[dir='rtl'].md\:rtl\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:py-20,[dir='rtl'].md\:rtl\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  [dir='rtl'] .md\:rtl\:px-20,[dir='rtl'].md\:rtl\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  [dir='rtl'] .md\:rtl\:py-24,[dir='rtl'].md\:rtl\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:px-24,[dir='rtl'].md\:rtl\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:py-28,[dir='rtl'].md\:rtl\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:px-28,[dir='rtl'].md\:rtl\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:py-32,[dir='rtl'].md\:rtl\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:px-32,[dir='rtl'].md\:rtl\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:py-36,[dir='rtl'].md\:rtl\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:px-36,[dir='rtl'].md\:rtl\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:py-40,[dir='rtl'].md\:rtl\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  [dir='rtl'] .md\:rtl\:px-40,[dir='rtl'].md\:rtl\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  [dir='rtl'] .md\:rtl\:py-44,[dir='rtl'].md\:rtl\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:px-44,[dir='rtl'].md\:rtl\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:py-48,[dir='rtl'].md\:rtl\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:px-48,[dir='rtl'].md\:rtl\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:py-52,[dir='rtl'].md\:rtl\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:px-52,[dir='rtl'].md\:rtl\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:py-56,[dir='rtl'].md\:rtl\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:px-56,[dir='rtl'].md\:rtl\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:py-60,[dir='rtl'].md\:rtl\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  [dir='rtl'] .md\:rtl\:px-60,[dir='rtl'].md\:rtl\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  [dir='rtl'] .md\:rtl\:py-64,[dir='rtl'].md\:rtl\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:px-64,[dir='rtl'].md\:rtl\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:py-68,[dir='rtl'].md\:rtl\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:px-68,[dir='rtl'].md\:rtl\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:py-72,[dir='rtl'].md\:rtl\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:px-72,[dir='rtl'].md\:rtl\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:py-76,[dir='rtl'].md\:rtl\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:px-76,[dir='rtl'].md\:rtl\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:py-80,[dir='rtl'].md\:rtl\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  [dir='rtl'] .md\:rtl\:px-80,[dir='rtl'].md\:rtl\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  [dir='rtl'] .md\:rtl\:py-84,[dir='rtl'].md\:rtl\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:px-84,[dir='rtl'].md\:rtl\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:py-88,[dir='rtl'].md\:rtl\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:px-88,[dir='rtl'].md\:rtl\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:py-92,[dir='rtl'].md\:rtl\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:px-92,[dir='rtl'].md\:rtl\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:py-96,[dir='rtl'].md\:rtl\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:px-96,[dir='rtl'].md\:rtl\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:py-128,[dir='rtl'].md\:rtl\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:px-128,[dir='rtl'].md\:rtl\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:py-136,[dir='rtl'].md\:rtl\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:px-136,[dir='rtl'].md\:rtl\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:py-160,[dir='rtl'].md\:rtl\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  [dir='rtl'] .md\:rtl\:px-160,[dir='rtl'].md\:rtl\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  [dir='rtl'] .md\:rtl\:py-192,[dir='rtl'].md\:rtl\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:px-192,[dir='rtl'].md\:rtl\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:py-200,[dir='rtl'].md\:rtl\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  [dir='rtl'] .md\:rtl\:px-200,[dir='rtl'].md\:rtl\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  [dir='rtl'] .md\:rtl\:py-208,[dir='rtl'].md\:rtl\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:px-208,[dir='rtl'].md\:rtl\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:py-216,[dir='rtl'].md\:rtl\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:px-216,[dir='rtl'].md\:rtl\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:py-224,[dir='rtl'].md\:rtl\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:px-224,[dir='rtl'].md\:rtl\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:py-256,[dir='rtl'].md\:rtl\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:px-256,[dir='rtl'].md\:rtl\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:py-288,[dir='rtl'].md\:rtl\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:px-288,[dir='rtl'].md\:rtl\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:py-320,[dir='rtl'].md\:rtl\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='rtl'] .md\:rtl\:px-320,[dir='rtl'].md\:rtl\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='rtl'] .md\:rtl\:py-360,[dir='rtl'].md\:rtl\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  [dir='rtl'] .md\:rtl\:px-360,[dir='rtl'].md\:rtl\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  [dir='rtl'] .md\:rtl\:py-384,[dir='rtl'].md\:rtl\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:px-384,[dir='rtl'].md\:rtl\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:py-400,[dir='rtl'].md\:rtl\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  [dir='rtl'] .md\:rtl\:px-400,[dir='rtl'].md\:rtl\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  [dir='rtl'] .md\:rtl\:py-512,[dir='rtl'].md\:rtl\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:px-512,[dir='rtl'].md\:rtl\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:py-640,[dir='rtl'].md\:rtl\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='rtl'] .md\:rtl\:px-640,[dir='rtl'].md\:rtl\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='rtl'] .md\:rtl\:py-xs,[dir='rtl'].md\:rtl\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='rtl'] .md\:rtl\:px-xs,[dir='rtl'].md\:rtl\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='rtl'] .md\:rtl\:py-sm,[dir='rtl'].md\:rtl\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  [dir='rtl'] .md\:rtl\:px-sm,[dir='rtl'].md\:rtl\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  [dir='rtl'] .md\:rtl\:py-md,[dir='rtl'].md\:rtl\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='rtl'] .md\:rtl\:px-md,[dir='rtl'].md\:rtl\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='rtl'] .md\:rtl\:py-lg,[dir='rtl'].md\:rtl\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  [dir='rtl'] .md\:rtl\:px-lg,[dir='rtl'].md\:rtl\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  [dir='rtl'] .md\:rtl\:py-xl,[dir='rtl'].md\:rtl\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  [dir='rtl'] .md\:rtl\:px-xl,[dir='rtl'].md\:rtl\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  [dir='rtl'] .md\:rtl\:py-2xl,[dir='rtl'].md\:rtl\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  [dir='rtl'] .md\:rtl\:px-2xl,[dir='rtl'].md\:rtl\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  [dir='rtl'] .md\:rtl\:py-3xl,[dir='rtl'].md\:rtl\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  [dir='rtl'] .md\:rtl\:px-3xl,[dir='rtl'].md\:rtl\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  [dir='rtl'] .md\:rtl\:py-4xl,[dir='rtl'].md\:rtl\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  [dir='rtl'] .md\:rtl\:px-4xl,[dir='rtl'].md\:rtl\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  [dir='rtl'] .md\:rtl\:py-5xl,[dir='rtl'].md\:rtl\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  [dir='rtl'] .md\:rtl\:px-5xl,[dir='rtl'].md\:rtl\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  [dir='rtl'] .md\:rtl\:py-px,[dir='rtl'].md\:rtl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  [dir='rtl'] .md\:rtl\:px-px,[dir='rtl'].md\:rtl\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  [dir='rtl'] .md\:rtl\:py-0\.5,[dir='rtl'].md\:rtl\:py-0\.5 {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:px-0\.5,[dir='rtl'].md\:rtl\:px-0\.5 {
    padding-left: 0.05rem;
    padding-right: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:py-1\.5,[dir='rtl'].md\:rtl\:py-1\.5 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:px-1\.5,[dir='rtl'].md\:rtl\:px-1\.5 {
    padding-left: 0.15rem;
    padding-right: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:py-2\.5,[dir='rtl'].md\:rtl\:py-2\.5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:px-2\.5,[dir='rtl'].md\:rtl\:px-2\.5 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:py-3\.5,[dir='rtl'].md\:rtl\:py-3\.5 {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:px-3\.5,[dir='rtl'].md\:rtl\:px-3\.5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:pt-0,[dir='rtl'].md\:rtl\:pt-0 {
    padding-top: 0
  }

  [dir='rtl'] .md\:rtl\:pr-0,[dir='rtl'].md\:rtl\:pr-0 {
    padding-right: 0
  }

  [dir='rtl'] .md\:rtl\:pb-0,[dir='rtl'].md\:rtl\:pb-0 {
    padding-bottom: 0
  }

  [dir='rtl'] .md\:rtl\:pl-0,[dir='rtl'].md\:rtl\:pl-0 {
    padding-left: 0
  }

  [dir='rtl'] .md\:rtl\:pt-1,[dir='rtl'].md\:rtl\:pt-1 {
    padding-top: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:pr-1,[dir='rtl'].md\:rtl\:pr-1 {
    padding-right: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:pb-1,[dir='rtl'].md\:rtl\:pb-1 {
    padding-bottom: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:pl-1,[dir='rtl'].md\:rtl\:pl-1 {
    padding-left: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:pt-2,[dir='rtl'].md\:rtl\:pt-2 {
    padding-top: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:pr-2,[dir='rtl'].md\:rtl\:pr-2 {
    padding-right: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:pb-2,[dir='rtl'].md\:rtl\:pb-2 {
    padding-bottom: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:pl-2,[dir='rtl'].md\:rtl\:pl-2 {
    padding-left: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:pt-3,[dir='rtl'].md\:rtl\:pt-3 {
    padding-top: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:pr-3,[dir='rtl'].md\:rtl\:pr-3 {
    padding-right: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:pb-3,[dir='rtl'].md\:rtl\:pb-3 {
    padding-bottom: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:pl-3,[dir='rtl'].md\:rtl\:pl-3 {
    padding-left: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:pt-4,[dir='rtl'].md\:rtl\:pt-4 {
    padding-top: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:pr-4,[dir='rtl'].md\:rtl\:pr-4 {
    padding-right: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:pb-4,[dir='rtl'].md\:rtl\:pb-4 {
    padding-bottom: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:pl-4,[dir='rtl'].md\:rtl\:pl-4 {
    padding-left: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:pt-5,[dir='rtl'].md\:rtl\:pt-5 {
    padding-top: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:pr-5,[dir='rtl'].md\:rtl\:pr-5 {
    padding-right: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:pb-5,[dir='rtl'].md\:rtl\:pb-5 {
    padding-bottom: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:pl-5,[dir='rtl'].md\:rtl\:pl-5 {
    padding-left: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:pt-6,[dir='rtl'].md\:rtl\:pt-6 {
    padding-top: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:pr-6,[dir='rtl'].md\:rtl\:pr-6 {
    padding-right: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:pb-6,[dir='rtl'].md\:rtl\:pb-6 {
    padding-bottom: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:pl-6,[dir='rtl'].md\:rtl\:pl-6 {
    padding-left: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:pt-7,[dir='rtl'].md\:rtl\:pt-7 {
    padding-top: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:pr-7,[dir='rtl'].md\:rtl\:pr-7 {
    padding-right: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:pb-7,[dir='rtl'].md\:rtl\:pb-7 {
    padding-bottom: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:pl-7,[dir='rtl'].md\:rtl\:pl-7 {
    padding-left: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:pt-8,[dir='rtl'].md\:rtl\:pt-8 {
    padding-top: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:pr-8,[dir='rtl'].md\:rtl\:pr-8 {
    padding-right: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:pb-8,[dir='rtl'].md\:rtl\:pb-8 {
    padding-bottom: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:pl-8,[dir='rtl'].md\:rtl\:pl-8 {
    padding-left: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:pt-9,[dir='rtl'].md\:rtl\:pt-9 {
    padding-top: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:pr-9,[dir='rtl'].md\:rtl\:pr-9 {
    padding-right: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:pb-9,[dir='rtl'].md\:rtl\:pb-9 {
    padding-bottom: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:pl-9,[dir='rtl'].md\:rtl\:pl-9 {
    padding-left: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:pt-10,[dir='rtl'].md\:rtl\:pt-10 {
    padding-top: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:pr-10,[dir='rtl'].md\:rtl\:pr-10 {
    padding-right: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:pb-10,[dir='rtl'].md\:rtl\:pb-10 {
    padding-bottom: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:pl-10,[dir='rtl'].md\:rtl\:pl-10 {
    padding-left: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:pt-12,[dir='rtl'].md\:rtl\:pt-12 {
    padding-top: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:pr-12,[dir='rtl'].md\:rtl\:pr-12 {
    padding-right: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:pb-12,[dir='rtl'].md\:rtl\:pb-12 {
    padding-bottom: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:pl-12,[dir='rtl'].md\:rtl\:pl-12 {
    padding-left: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:pt-14,[dir='rtl'].md\:rtl\:pt-14 {
    padding-top: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:pr-14,[dir='rtl'].md\:rtl\:pr-14 {
    padding-right: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:pb-14,[dir='rtl'].md\:rtl\:pb-14 {
    padding-bottom: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:pl-14,[dir='rtl'].md\:rtl\:pl-14 {
    padding-left: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:pt-16,[dir='rtl'].md\:rtl\:pt-16 {
    padding-top: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:pr-16,[dir='rtl'].md\:rtl\:pr-16 {
    padding-right: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:pb-16,[dir='rtl'].md\:rtl\:pb-16 {
    padding-bottom: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:pl-16,[dir='rtl'].md\:rtl\:pl-16 {
    padding-left: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:pt-20,[dir='rtl'].md\:rtl\:pt-20 {
    padding-top: 2rem
  }

  [dir='rtl'] .md\:rtl\:pr-20,[dir='rtl'].md\:rtl\:pr-20 {
    padding-right: 2rem
  }

  [dir='rtl'] .md\:rtl\:pb-20,[dir='rtl'].md\:rtl\:pb-20 {
    padding-bottom: 2rem
  }

  [dir='rtl'] .md\:rtl\:pl-20,[dir='rtl'].md\:rtl\:pl-20 {
    padding-left: 2rem
  }

  [dir='rtl'] .md\:rtl\:pt-24,[dir='rtl'].md\:rtl\:pt-24 {
    padding-top: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:pr-24,[dir='rtl'].md\:rtl\:pr-24 {
    padding-right: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:pb-24,[dir='rtl'].md\:rtl\:pb-24 {
    padding-bottom: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:pl-24,[dir='rtl'].md\:rtl\:pl-24 {
    padding-left: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:pt-28,[dir='rtl'].md\:rtl\:pt-28 {
    padding-top: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:pr-28,[dir='rtl'].md\:rtl\:pr-28 {
    padding-right: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:pb-28,[dir='rtl'].md\:rtl\:pb-28 {
    padding-bottom: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:pl-28,[dir='rtl'].md\:rtl\:pl-28 {
    padding-left: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:pt-32,[dir='rtl'].md\:rtl\:pt-32 {
    padding-top: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:pr-32,[dir='rtl'].md\:rtl\:pr-32 {
    padding-right: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:pb-32,[dir='rtl'].md\:rtl\:pb-32 {
    padding-bottom: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:pl-32,[dir='rtl'].md\:rtl\:pl-32 {
    padding-left: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:pt-36,[dir='rtl'].md\:rtl\:pt-36 {
    padding-top: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:pr-36,[dir='rtl'].md\:rtl\:pr-36 {
    padding-right: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:pb-36,[dir='rtl'].md\:rtl\:pb-36 {
    padding-bottom: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:pl-36,[dir='rtl'].md\:rtl\:pl-36 {
    padding-left: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:pt-40,[dir='rtl'].md\:rtl\:pt-40 {
    padding-top: 4rem
  }

  [dir='rtl'] .md\:rtl\:pr-40,[dir='rtl'].md\:rtl\:pr-40 {
    padding-right: 4rem
  }

  [dir='rtl'] .md\:rtl\:pb-40,[dir='rtl'].md\:rtl\:pb-40 {
    padding-bottom: 4rem
  }

  [dir='rtl'] .md\:rtl\:pl-40,[dir='rtl'].md\:rtl\:pl-40 {
    padding-left: 4rem
  }

  [dir='rtl'] .md\:rtl\:pt-44,[dir='rtl'].md\:rtl\:pt-44 {
    padding-top: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:pr-44,[dir='rtl'].md\:rtl\:pr-44 {
    padding-right: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:pb-44,[dir='rtl'].md\:rtl\:pb-44 {
    padding-bottom: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:pl-44,[dir='rtl'].md\:rtl\:pl-44 {
    padding-left: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:pt-48,[dir='rtl'].md\:rtl\:pt-48 {
    padding-top: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:pr-48,[dir='rtl'].md\:rtl\:pr-48 {
    padding-right: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:pb-48,[dir='rtl'].md\:rtl\:pb-48 {
    padding-bottom: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:pl-48,[dir='rtl'].md\:rtl\:pl-48 {
    padding-left: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:pt-52,[dir='rtl'].md\:rtl\:pt-52 {
    padding-top: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:pr-52,[dir='rtl'].md\:rtl\:pr-52 {
    padding-right: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:pb-52,[dir='rtl'].md\:rtl\:pb-52 {
    padding-bottom: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:pl-52,[dir='rtl'].md\:rtl\:pl-52 {
    padding-left: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:pt-56,[dir='rtl'].md\:rtl\:pt-56 {
    padding-top: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:pr-56,[dir='rtl'].md\:rtl\:pr-56 {
    padding-right: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:pb-56,[dir='rtl'].md\:rtl\:pb-56 {
    padding-bottom: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:pl-56,[dir='rtl'].md\:rtl\:pl-56 {
    padding-left: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:pt-60,[dir='rtl'].md\:rtl\:pt-60 {
    padding-top: 6rem
  }

  [dir='rtl'] .md\:rtl\:pr-60,[dir='rtl'].md\:rtl\:pr-60 {
    padding-right: 6rem
  }

  [dir='rtl'] .md\:rtl\:pb-60,[dir='rtl'].md\:rtl\:pb-60 {
    padding-bottom: 6rem
  }

  [dir='rtl'] .md\:rtl\:pl-60,[dir='rtl'].md\:rtl\:pl-60 {
    padding-left: 6rem
  }

  [dir='rtl'] .md\:rtl\:pt-64,[dir='rtl'].md\:rtl\:pt-64 {
    padding-top: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:pr-64,[dir='rtl'].md\:rtl\:pr-64 {
    padding-right: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:pb-64,[dir='rtl'].md\:rtl\:pb-64 {
    padding-bottom: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:pl-64,[dir='rtl'].md\:rtl\:pl-64 {
    padding-left: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:pt-68,[dir='rtl'].md\:rtl\:pt-68 {
    padding-top: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:pr-68,[dir='rtl'].md\:rtl\:pr-68 {
    padding-right: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:pb-68,[dir='rtl'].md\:rtl\:pb-68 {
    padding-bottom: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:pl-68,[dir='rtl'].md\:rtl\:pl-68 {
    padding-left: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:pt-72,[dir='rtl'].md\:rtl\:pt-72 {
    padding-top: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:pr-72,[dir='rtl'].md\:rtl\:pr-72 {
    padding-right: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:pb-72,[dir='rtl'].md\:rtl\:pb-72 {
    padding-bottom: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:pl-72,[dir='rtl'].md\:rtl\:pl-72 {
    padding-left: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:pt-76,[dir='rtl'].md\:rtl\:pt-76 {
    padding-top: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:pr-76,[dir='rtl'].md\:rtl\:pr-76 {
    padding-right: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:pb-76,[dir='rtl'].md\:rtl\:pb-76 {
    padding-bottom: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:pl-76,[dir='rtl'].md\:rtl\:pl-76 {
    padding-left: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:pt-80,[dir='rtl'].md\:rtl\:pt-80 {
    padding-top: 8rem
  }

  [dir='rtl'] .md\:rtl\:pr-80,[dir='rtl'].md\:rtl\:pr-80 {
    padding-right: 8rem
  }

  [dir='rtl'] .md\:rtl\:pb-80,[dir='rtl'].md\:rtl\:pb-80 {
    padding-bottom: 8rem
  }

  [dir='rtl'] .md\:rtl\:pl-80,[dir='rtl'].md\:rtl\:pl-80 {
    padding-left: 8rem
  }

  [dir='rtl'] .md\:rtl\:pt-84,[dir='rtl'].md\:rtl\:pt-84 {
    padding-top: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:pr-84,[dir='rtl'].md\:rtl\:pr-84 {
    padding-right: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:pb-84,[dir='rtl'].md\:rtl\:pb-84 {
    padding-bottom: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:pl-84,[dir='rtl'].md\:rtl\:pl-84 {
    padding-left: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:pt-88,[dir='rtl'].md\:rtl\:pt-88 {
    padding-top: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:pr-88,[dir='rtl'].md\:rtl\:pr-88 {
    padding-right: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:pb-88,[dir='rtl'].md\:rtl\:pb-88 {
    padding-bottom: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:pl-88,[dir='rtl'].md\:rtl\:pl-88 {
    padding-left: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:pt-92,[dir='rtl'].md\:rtl\:pt-92 {
    padding-top: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:pr-92,[dir='rtl'].md\:rtl\:pr-92 {
    padding-right: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:pb-92,[dir='rtl'].md\:rtl\:pb-92 {
    padding-bottom: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:pl-92,[dir='rtl'].md\:rtl\:pl-92 {
    padding-left: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:pt-96,[dir='rtl'].md\:rtl\:pt-96 {
    padding-top: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:pr-96,[dir='rtl'].md\:rtl\:pr-96 {
    padding-right: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:pb-96,[dir='rtl'].md\:rtl\:pb-96 {
    padding-bottom: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:pl-96,[dir='rtl'].md\:rtl\:pl-96 {
    padding-left: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:pt-128,[dir='rtl'].md\:rtl\:pt-128 {
    padding-top: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:pr-128,[dir='rtl'].md\:rtl\:pr-128 {
    padding-right: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:pb-128,[dir='rtl'].md\:rtl\:pb-128 {
    padding-bottom: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:pl-128,[dir='rtl'].md\:rtl\:pl-128 {
    padding-left: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:pt-136,[dir='rtl'].md\:rtl\:pt-136 {
    padding-top: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:pr-136,[dir='rtl'].md\:rtl\:pr-136 {
    padding-right: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:pb-136,[dir='rtl'].md\:rtl\:pb-136 {
    padding-bottom: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:pl-136,[dir='rtl'].md\:rtl\:pl-136 {
    padding-left: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:pt-160,[dir='rtl'].md\:rtl\:pt-160 {
    padding-top: 16rem
  }

  [dir='rtl'] .md\:rtl\:pr-160,[dir='rtl'].md\:rtl\:pr-160 {
    padding-right: 16rem
  }

  [dir='rtl'] .md\:rtl\:pb-160,[dir='rtl'].md\:rtl\:pb-160 {
    padding-bottom: 16rem
  }

  [dir='rtl'] .md\:rtl\:pl-160,[dir='rtl'].md\:rtl\:pl-160 {
    padding-left: 16rem
  }

  [dir='rtl'] .md\:rtl\:pt-192,[dir='rtl'].md\:rtl\:pt-192 {
    padding-top: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:pr-192,[dir='rtl'].md\:rtl\:pr-192 {
    padding-right: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:pb-192,[dir='rtl'].md\:rtl\:pb-192 {
    padding-bottom: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:pl-192,[dir='rtl'].md\:rtl\:pl-192 {
    padding-left: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:pt-200,[dir='rtl'].md\:rtl\:pt-200 {
    padding-top: 20rem
  }

  [dir='rtl'] .md\:rtl\:pr-200,[dir='rtl'].md\:rtl\:pr-200 {
    padding-right: 20rem
  }

  [dir='rtl'] .md\:rtl\:pb-200,[dir='rtl'].md\:rtl\:pb-200 {
    padding-bottom: 20rem
  }

  [dir='rtl'] .md\:rtl\:pl-200,[dir='rtl'].md\:rtl\:pl-200 {
    padding-left: 20rem
  }

  [dir='rtl'] .md\:rtl\:pt-208,[dir='rtl'].md\:rtl\:pt-208 {
    padding-top: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:pr-208,[dir='rtl'].md\:rtl\:pr-208 {
    padding-right: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:pb-208,[dir='rtl'].md\:rtl\:pb-208 {
    padding-bottom: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:pl-208,[dir='rtl'].md\:rtl\:pl-208 {
    padding-left: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:pt-216,[dir='rtl'].md\:rtl\:pt-216 {
    padding-top: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:pr-216,[dir='rtl'].md\:rtl\:pr-216 {
    padding-right: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:pb-216,[dir='rtl'].md\:rtl\:pb-216 {
    padding-bottom: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:pl-216,[dir='rtl'].md\:rtl\:pl-216 {
    padding-left: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:pt-224,[dir='rtl'].md\:rtl\:pt-224 {
    padding-top: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:pr-224,[dir='rtl'].md\:rtl\:pr-224 {
    padding-right: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:pb-224,[dir='rtl'].md\:rtl\:pb-224 {
    padding-bottom: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:pl-224,[dir='rtl'].md\:rtl\:pl-224 {
    padding-left: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:pt-256,[dir='rtl'].md\:rtl\:pt-256 {
    padding-top: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:pr-256,[dir='rtl'].md\:rtl\:pr-256 {
    padding-right: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:pb-256,[dir='rtl'].md\:rtl\:pb-256 {
    padding-bottom: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:pl-256,[dir='rtl'].md\:rtl\:pl-256 {
    padding-left: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:pt-288,[dir='rtl'].md\:rtl\:pt-288 {
    padding-top: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:pr-288,[dir='rtl'].md\:rtl\:pr-288 {
    padding-right: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:pb-288,[dir='rtl'].md\:rtl\:pb-288 {
    padding-bottom: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:pl-288,[dir='rtl'].md\:rtl\:pl-288 {
    padding-left: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:pt-320,[dir='rtl'].md\:rtl\:pt-320 {
    padding-top: 32rem
  }

  [dir='rtl'] .md\:rtl\:pr-320,[dir='rtl'].md\:rtl\:pr-320 {
    padding-right: 32rem
  }

  [dir='rtl'] .md\:rtl\:pb-320,[dir='rtl'].md\:rtl\:pb-320 {
    padding-bottom: 32rem
  }

  [dir='rtl'] .md\:rtl\:pl-320,[dir='rtl'].md\:rtl\:pl-320 {
    padding-left: 32rem
  }

  [dir='rtl'] .md\:rtl\:pt-360,[dir='rtl'].md\:rtl\:pt-360 {
    padding-top: 36rem
  }

  [dir='rtl'] .md\:rtl\:pr-360,[dir='rtl'].md\:rtl\:pr-360 {
    padding-right: 36rem
  }

  [dir='rtl'] .md\:rtl\:pb-360,[dir='rtl'].md\:rtl\:pb-360 {
    padding-bottom: 36rem
  }

  [dir='rtl'] .md\:rtl\:pl-360,[dir='rtl'].md\:rtl\:pl-360 {
    padding-left: 36rem
  }

  [dir='rtl'] .md\:rtl\:pt-384,[dir='rtl'].md\:rtl\:pt-384 {
    padding-top: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:pr-384,[dir='rtl'].md\:rtl\:pr-384 {
    padding-right: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:pb-384,[dir='rtl'].md\:rtl\:pb-384 {
    padding-bottom: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:pl-384,[dir='rtl'].md\:rtl\:pl-384 {
    padding-left: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:pt-400,[dir='rtl'].md\:rtl\:pt-400 {
    padding-top: 40rem
  }

  [dir='rtl'] .md\:rtl\:pr-400,[dir='rtl'].md\:rtl\:pr-400 {
    padding-right: 40rem
  }

  [dir='rtl'] .md\:rtl\:pb-400,[dir='rtl'].md\:rtl\:pb-400 {
    padding-bottom: 40rem
  }

  [dir='rtl'] .md\:rtl\:pl-400,[dir='rtl'].md\:rtl\:pl-400 {
    padding-left: 40rem
  }

  [dir='rtl'] .md\:rtl\:pt-512,[dir='rtl'].md\:rtl\:pt-512 {
    padding-top: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:pr-512,[dir='rtl'].md\:rtl\:pr-512 {
    padding-right: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:pb-512,[dir='rtl'].md\:rtl\:pb-512 {
    padding-bottom: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:pl-512,[dir='rtl'].md\:rtl\:pl-512 {
    padding-left: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:pt-640,[dir='rtl'].md\:rtl\:pt-640 {
    padding-top: 64rem
  }

  [dir='rtl'] .md\:rtl\:pr-640,[dir='rtl'].md\:rtl\:pr-640 {
    padding-right: 64rem
  }

  [dir='rtl'] .md\:rtl\:pb-640,[dir='rtl'].md\:rtl\:pb-640 {
    padding-bottom: 64rem
  }

  [dir='rtl'] .md\:rtl\:pl-640,[dir='rtl'].md\:rtl\:pl-640 {
    padding-left: 64rem
  }

  [dir='rtl'] .md\:rtl\:pt-xs,[dir='rtl'].md\:rtl\:pt-xs {
    padding-top: 32rem
  }

  [dir='rtl'] .md\:rtl\:pr-xs,[dir='rtl'].md\:rtl\:pr-xs {
    padding-right: 32rem
  }

  [dir='rtl'] .md\:rtl\:pb-xs,[dir='rtl'].md\:rtl\:pb-xs {
    padding-bottom: 32rem
  }

  [dir='rtl'] .md\:rtl\:pl-xs,[dir='rtl'].md\:rtl\:pl-xs {
    padding-left: 32rem
  }

  [dir='rtl'] .md\:rtl\:pt-sm,[dir='rtl'].md\:rtl\:pt-sm {
    padding-top: 48rem
  }

  [dir='rtl'] .md\:rtl\:pr-sm,[dir='rtl'].md\:rtl\:pr-sm {
    padding-right: 48rem
  }

  [dir='rtl'] .md\:rtl\:pb-sm,[dir='rtl'].md\:rtl\:pb-sm {
    padding-bottom: 48rem
  }

  [dir='rtl'] .md\:rtl\:pl-sm,[dir='rtl'].md\:rtl\:pl-sm {
    padding-left: 48rem
  }

  [dir='rtl'] .md\:rtl\:pt-md,[dir='rtl'].md\:rtl\:pt-md {
    padding-top: 64rem
  }

  [dir='rtl'] .md\:rtl\:pr-md,[dir='rtl'].md\:rtl\:pr-md {
    padding-right: 64rem
  }

  [dir='rtl'] .md\:rtl\:pb-md,[dir='rtl'].md\:rtl\:pb-md {
    padding-bottom: 64rem
  }

  [dir='rtl'] .md\:rtl\:pl-md,[dir='rtl'].md\:rtl\:pl-md {
    padding-left: 64rem
  }

  [dir='rtl'] .md\:rtl\:pt-lg,[dir='rtl'].md\:rtl\:pt-lg {
    padding-top: 80rem
  }

  [dir='rtl'] .md\:rtl\:pr-lg,[dir='rtl'].md\:rtl\:pr-lg {
    padding-right: 80rem
  }

  [dir='rtl'] .md\:rtl\:pb-lg,[dir='rtl'].md\:rtl\:pb-lg {
    padding-bottom: 80rem
  }

  [dir='rtl'] .md\:rtl\:pl-lg,[dir='rtl'].md\:rtl\:pl-lg {
    padding-left: 80rem
  }

  [dir='rtl'] .md\:rtl\:pt-xl,[dir='rtl'].md\:rtl\:pt-xl {
    padding-top: 96rem
  }

  [dir='rtl'] .md\:rtl\:pr-xl,[dir='rtl'].md\:rtl\:pr-xl {
    padding-right: 96rem
  }

  [dir='rtl'] .md\:rtl\:pb-xl,[dir='rtl'].md\:rtl\:pb-xl {
    padding-bottom: 96rem
  }

  [dir='rtl'] .md\:rtl\:pl-xl,[dir='rtl'].md\:rtl\:pl-xl {
    padding-left: 96rem
  }

  [dir='rtl'] .md\:rtl\:pt-2xl,[dir='rtl'].md\:rtl\:pt-2xl {
    padding-top: 112rem
  }

  [dir='rtl'] .md\:rtl\:pr-2xl,[dir='rtl'].md\:rtl\:pr-2xl {
    padding-right: 112rem
  }

  [dir='rtl'] .md\:rtl\:pb-2xl,[dir='rtl'].md\:rtl\:pb-2xl {
    padding-bottom: 112rem
  }

  [dir='rtl'] .md\:rtl\:pl-2xl,[dir='rtl'].md\:rtl\:pl-2xl {
    padding-left: 112rem
  }

  [dir='rtl'] .md\:rtl\:pt-3xl,[dir='rtl'].md\:rtl\:pt-3xl {
    padding-top: 128rem
  }

  [dir='rtl'] .md\:rtl\:pr-3xl,[dir='rtl'].md\:rtl\:pr-3xl {
    padding-right: 128rem
  }

  [dir='rtl'] .md\:rtl\:pb-3xl,[dir='rtl'].md\:rtl\:pb-3xl {
    padding-bottom: 128rem
  }

  [dir='rtl'] .md\:rtl\:pl-3xl,[dir='rtl'].md\:rtl\:pl-3xl {
    padding-left: 128rem
  }

  [dir='rtl'] .md\:rtl\:pt-4xl,[dir='rtl'].md\:rtl\:pt-4xl {
    padding-top: 144rem
  }

  [dir='rtl'] .md\:rtl\:pr-4xl,[dir='rtl'].md\:rtl\:pr-4xl {
    padding-right: 144rem
  }

  [dir='rtl'] .md\:rtl\:pb-4xl,[dir='rtl'].md\:rtl\:pb-4xl {
    padding-bottom: 144rem
  }

  [dir='rtl'] .md\:rtl\:pl-4xl,[dir='rtl'].md\:rtl\:pl-4xl {
    padding-left: 144rem
  }

  [dir='rtl'] .md\:rtl\:pt-5xl,[dir='rtl'].md\:rtl\:pt-5xl {
    padding-top: 160rem
  }

  [dir='rtl'] .md\:rtl\:pr-5xl,[dir='rtl'].md\:rtl\:pr-5xl {
    padding-right: 160rem
  }

  [dir='rtl'] .md\:rtl\:pb-5xl,[dir='rtl'].md\:rtl\:pb-5xl {
    padding-bottom: 160rem
  }

  [dir='rtl'] .md\:rtl\:pl-5xl,[dir='rtl'].md\:rtl\:pl-5xl {
    padding-left: 160rem
  }

  [dir='rtl'] .md\:rtl\:pt-px,[dir='rtl'].md\:rtl\:pt-px {
    padding-top: 1px
  }

  [dir='rtl'] .md\:rtl\:pr-px,[dir='rtl'].md\:rtl\:pr-px {
    padding-right: 1px
  }

  [dir='rtl'] .md\:rtl\:pb-px,[dir='rtl'].md\:rtl\:pb-px {
    padding-bottom: 1px
  }

  [dir='rtl'] .md\:rtl\:pl-px,[dir='rtl'].md\:rtl\:pl-px {
    padding-left: 1px
  }

  [dir='rtl'] .md\:rtl\:pt-0\.5,[dir='rtl'].md\:rtl\:pt-0\.5 {
    padding-top: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:pr-0\.5,[dir='rtl'].md\:rtl\:pr-0\.5 {
    padding-right: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:pb-0\.5,[dir='rtl'].md\:rtl\:pb-0\.5 {
    padding-bottom: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:pl-0\.5,[dir='rtl'].md\:rtl\:pl-0\.5 {
    padding-left: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:pt-1\.5,[dir='rtl'].md\:rtl\:pt-1\.5 {
    padding-top: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:pr-1\.5,[dir='rtl'].md\:rtl\:pr-1\.5 {
    padding-right: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:pb-1\.5,[dir='rtl'].md\:rtl\:pb-1\.5 {
    padding-bottom: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:pl-1\.5,[dir='rtl'].md\:rtl\:pl-1\.5 {
    padding-left: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:pt-2\.5,[dir='rtl'].md\:rtl\:pt-2\.5 {
    padding-top: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:pr-2\.5,[dir='rtl'].md\:rtl\:pr-2\.5 {
    padding-right: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:pb-2\.5,[dir='rtl'].md\:rtl\:pb-2\.5 {
    padding-bottom: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:pl-2\.5,[dir='rtl'].md\:rtl\:pl-2\.5 {
    padding-left: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:pt-3\.5,[dir='rtl'].md\:rtl\:pt-3\.5 {
    padding-top: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:pr-3\.5,[dir='rtl'].md\:rtl\:pr-3\.5 {
    padding-right: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:pb-3\.5,[dir='rtl'].md\:rtl\:pb-3\.5 {
    padding-bottom: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:pl-3\.5,[dir='rtl'].md\:rtl\:pl-3\.5 {
    padding-left: 0.35rem
  }

  .md\:placeholder-transparent::placeholder {
    color: transparent
  }

  .md\:placeholder-current::placeholder {
    color: currentColor
  }

  .md\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-grey-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-grey-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-grey-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-grey-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-grey-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-grey-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-grey-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-grey-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-grey-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-grey-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-grey-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-grey-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-grey-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-grey-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-gray-hover::placeholder {
    color: rgba(0, 0, 0, 0.04)
  }

  .md\:placeholder-gray-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-gray-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-gray-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-gray-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-red::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-red-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-red-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-red-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-red-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-orange-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-orange-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-orange-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-orange-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-orange-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-orange-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-orange-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-orange-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-orange-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-orange-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-orange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-orange-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-orange-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-orange-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-orange-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-orange-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-orange-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-orange-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-orange-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-orange-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-orange-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-orange-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-orange-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-orange-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-orange-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-orange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-orange-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-orange-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-orange-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-orange-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-yellow::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-yellow-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-yellow-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-yellow-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-yellow-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-green-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-green-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-green-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-green-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-green-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-green-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-green-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-green-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-teal-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-teal-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-teal-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-teal-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-teal-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-teal-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-teal-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-teal-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-teal-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-teal-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-teal::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-teal-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-teal-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-teal-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-teal-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-blue-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-blue-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-blue-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-light-blue-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-indigo::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-indigo-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-indigo-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-indigo-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-indigo-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-purple::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-purple-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-purple-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-purple-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-purple-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-purple::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-purple-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-purple-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-purple-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-deep-purple-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-pink::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-pink-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-pink-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-pink-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-pink-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-lime-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-lime-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-lime-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-lime-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-lime-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-lime-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-lime-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-lime-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-lime-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-lime-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-lime::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-lime-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-lime-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-lime-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-lime-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-amber-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-amber-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-amber-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-amber-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-amber-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-amber-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-amber-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-amber-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-amber-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-amber-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-amber::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-amber-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-amber-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-amber-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-amber-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-brown-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-brown-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-brown-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-brown-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-brown-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-brown-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-brown-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-brown-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-brown-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-brown-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-brown::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-brown-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-brown-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-brown-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-brown-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-gray-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-gray-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-gray-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-blue-gray-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-cyan-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-cyan-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-cyan-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-cyan-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-cyan-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-cyan-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-cyan-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-cyan-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-cyan-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-cyan-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-cyan::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-cyan-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-cyan-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-cyan-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-cyan-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent
  }

  .md\:focus\:placeholder-current:focus::placeholder {
    color: currentColor
  }

  .md\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-grey-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-grey-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-grey-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-grey-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-grey-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-grey-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-grey-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-grey-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-grey-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-grey-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-grey-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-grey-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-grey-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-grey-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-hover:focus::placeholder {
    color: rgba(0, 0, 0, 0.04)
  }

  .md\:focus\:placeholder-gray-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-red:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-red-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-red-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-red-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-red-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-orange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-orange-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-orange-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-orange-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-orange-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-orange-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-orange-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-orange-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-orange-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-orange-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-orange-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-orange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-orange-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-orange-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-orange-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-orange-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-green-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-green-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-green-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-green-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-green-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-green-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-green-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-green-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-teal:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-blue-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-blue-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-blue-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-light-blue-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-purple:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-purple:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-purple-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-purple-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-purple-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-deep-purple-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-pink:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-lime-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-lime-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-lime-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-lime-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-lime-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-lime-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-lime-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-lime-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-lime-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-lime-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-lime:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-lime-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-lime-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-lime-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-lime-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-amber-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-amber-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-amber-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-amber-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-amber-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-amber-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-amber-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-amber-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-amber-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-amber-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-amber:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-amber-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-amber-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-amber-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-amber-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-brown-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-brown-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-brown-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-brown-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-brown-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-brown-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-brown-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-brown-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-brown-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-brown-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-brown:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-brown-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-brown-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-brown-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-brown-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-gray-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-gray-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-gray-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-gray-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-cyan-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-cyan-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-cyan-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-cyan-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-cyan-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-cyan-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-cyan-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-cyan-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-cyan-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-cyan-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-cyan:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-cyan-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-cyan-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-cyan-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
  }

  .md\:focus\:placeholder-cyan-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
  }

  .md\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0
  }

  .md\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05
  }

  .md\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1
  }

  .md\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2
  }

  .md\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25
  }

  .md\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3
  }

  .md\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4
  }

  .md\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5
  }

  .md\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6
  }

  .md\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7
  }

  .md\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75
  }

  .md\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8
  }

  .md\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9
  }

  .md\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95
  }

  .md\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1
  }

  .md\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0
  }

  .md\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05
  }

  .md\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1
  }

  .md\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2
  }

  .md\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25
  }

  .md\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3
  }

  .md\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4
  }

  .md\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5
  }

  .md\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6
  }

  .md\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7
  }

  .md\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75
  }

  .md\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8
  }

  .md\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9
  }

  .md\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95
  }

  .md\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1
  }

  .md\:pointer-events-none {
    pointer-events: none
  }

  .md\:pointer-events-auto {
    pointer-events: auto
  }

  .md\:static {
    position: static
  }

  .md\:fixed {
    position: fixed
  }

  .md\:absolute {
    position: absolute
  }

  .md\:relative {
    position: relative
  }

  .md\:sticky {
    position: sticky
  }

  .md\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .md\:inset-1 {
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem
  }

  .md\:inset-2 {
    top: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem
  }

  .md\:inset-3 {
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem
  }

  .md\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  .md\:inset-5 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  .md\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  .md\:inset-7 {
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
    left: 0.7rem
  }

  .md\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  .md\:inset-9 {
    top: 0.9rem;
    right: 0.9rem;
    bottom: 0.9rem;
    left: 0.9rem
  }

  .md\:inset-10 {
    top: 1.0rem;
    right: 1.0rem;
    bottom: 1.0rem;
    left: 1.0rem
  }

  .md\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  .md\:inset-14 {
    top: 1.4rem;
    right: 1.4rem;
    bottom: 1.4rem;
    left: 1.4rem
  }

  .md\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  .md\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  .md\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  .md\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  .md\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  .md\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  .md\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  .md\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  .md\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  .md\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  .md\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  .md\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  .md\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  .md\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  .md\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  .md\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  .md\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  .md\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  .md\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  .md\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  .md\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  .md\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  .md\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  .md\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  .md\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  .md\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  .md\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  .md\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  .md\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  .md\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  .md\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  .md\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  .md\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  .md\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  .md\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  .md\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  .md\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  .md\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .md\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  .md\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  .md\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  .md\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  .md\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  .md\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  .md\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  .md\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  .md\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  .md\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  .md\:inset-0\.5 {
    top: 0.05rem;
    right: 0.05rem;
    bottom: 0.05rem;
    left: 0.05rem
  }

  .md\:inset-1\.5 {
    top: 0.15rem;
    right: 0.15rem;
    bottom: 0.15rem;
    left: 0.15rem
  }

  .md\:inset-2\.5 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  .md\:inset-3\.5 {
    top: 0.35rem;
    right: 0.35rem;
    bottom: 0.35rem;
    left: 0.35rem
  }

  .md\:-inset-1 {
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem
  }

  .md\:-inset-2 {
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem
  }

  .md\:-inset-3 {
    top: -0.3rem;
    right: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem
  }

  .md\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  .md\:-inset-5 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  .md\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  .md\:-inset-7 {
    top: -0.7rem;
    right: -0.7rem;
    bottom: -0.7rem;
    left: -0.7rem
  }

  .md\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  .md\:-inset-9 {
    top: -0.9rem;
    right: -0.9rem;
    bottom: -0.9rem;
    left: -0.9rem
  }

  .md\:-inset-10 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  .md\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  .md\:-inset-14 {
    top: -1.4rem;
    right: -1.4rem;
    bottom: -1.4rem;
    left: -1.4rem
  }

  .md\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  .md\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  .md\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  .md\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  .md\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  .md\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  .md\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  .md\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  .md\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  .md\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  .md\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  .md\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  .md\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  .md\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  .md\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  .md\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  .md\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  .md\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  .md\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  .md\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  .md\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  .md\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  .md\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  .md\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  .md\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  .md\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  .md\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  .md\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  .md\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  .md\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  .md\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  .md\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  .md\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  .md\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  .md\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  .md\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  .md\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  .md\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  .md\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  .md\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  .md\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  .md\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  .md\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  .md\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  .md\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  .md\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  .md\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  .md\:-inset-0\.5 {
    top: -0.05rem;
    right: -0.05rem;
    bottom: -0.05rem;
    left: -0.05rem
  }

  .md\:-inset-1\.5 {
    top: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    left: -0.15rem
  }

  .md\:-inset-2\.5 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  .md\:-inset-3\.5 {
    top: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    left: -0.35rem
  }

  .md\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  .md\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  .md\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  .md\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  .md\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  .md\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  .md\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  .md\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  .md\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  .md\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  .md\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  .md\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  .md\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  .md\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  .md\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .md\:inset-x-0 {
    right: 0;
    left: 0
  }

  .md\:inset-y-1 {
    top: 0.1rem;
    bottom: 0.1rem
  }

  .md\:inset-x-1 {
    right: 0.1rem;
    left: 0.1rem
  }

  .md\:inset-y-2 {
    top: 0.2rem;
    bottom: 0.2rem
  }

  .md\:inset-x-2 {
    right: 0.2rem;
    left: 0.2rem
  }

  .md\:inset-y-3 {
    top: 0.3rem;
    bottom: 0.3rem
  }

  .md\:inset-x-3 {
    right: 0.3rem;
    left: 0.3rem
  }

  .md\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  .md\:inset-x-4 {
    right: 0.4rem;
    left: 0.4rem
  }

  .md\:inset-y-5 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  .md\:inset-x-5 {
    right: 0.5rem;
    left: 0.5rem
  }

  .md\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  .md\:inset-x-6 {
    right: 0.6rem;
    left: 0.6rem
  }

  .md\:inset-y-7 {
    top: 0.7rem;
    bottom: 0.7rem
  }

  .md\:inset-x-7 {
    right: 0.7rem;
    left: 0.7rem
  }

  .md\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  .md\:inset-x-8 {
    right: 0.8rem;
    left: 0.8rem
  }

  .md\:inset-y-9 {
    top: 0.9rem;
    bottom: 0.9rem
  }

  .md\:inset-x-9 {
    right: 0.9rem;
    left: 0.9rem
  }

  .md\:inset-y-10 {
    top: 1.0rem;
    bottom: 1.0rem
  }

  .md\:inset-x-10 {
    right: 1.0rem;
    left: 1.0rem
  }

  .md\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  .md\:inset-x-12 {
    right: 1.2rem;
    left: 1.2rem
  }

  .md\:inset-y-14 {
    top: 1.4rem;
    bottom: 1.4rem
  }

  .md\:inset-x-14 {
    right: 1.4rem;
    left: 1.4rem
  }

  .md\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  .md\:inset-x-16 {
    right: 1.6rem;
    left: 1.6rem
  }

  .md\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  .md\:inset-x-20 {
    right: 2rem;
    left: 2rem
  }

  .md\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  .md\:inset-x-24 {
    right: 2.4rem;
    left: 2.4rem
  }

  .md\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  .md\:inset-x-28 {
    right: 2.8rem;
    left: 2.8rem
  }

  .md\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  .md\:inset-x-32 {
    right: 3.2rem;
    left: 3.2rem
  }

  .md\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  .md\:inset-x-36 {
    right: 3.6rem;
    left: 3.6rem
  }

  .md\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  .md\:inset-x-40 {
    right: 4rem;
    left: 4rem
  }

  .md\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  .md\:inset-x-44 {
    right: 4.4rem;
    left: 4.4rem
  }

  .md\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  .md\:inset-x-48 {
    right: 4.8rem;
    left: 4.8rem
  }

  .md\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  .md\:inset-x-52 {
    right: 5.2rem;
    left: 5.2rem
  }

  .md\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  .md\:inset-x-56 {
    right: 5.6rem;
    left: 5.6rem
  }

  .md\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  .md\:inset-x-60 {
    right: 6rem;
    left: 6rem
  }

  .md\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  .md\:inset-x-64 {
    right: 6.4rem;
    left: 6.4rem
  }

  .md\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  .md\:inset-x-68 {
    right: 6.8rem;
    left: 6.8rem
  }

  .md\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  .md\:inset-x-72 {
    right: 7.2rem;
    left: 7.2rem
  }

  .md\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  .md\:inset-x-76 {
    right: 7.6rem;
    left: 7.6rem
  }

  .md\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  .md\:inset-x-80 {
    right: 8rem;
    left: 8rem
  }

  .md\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  .md\:inset-x-84 {
    right: 8.4rem;
    left: 8.4rem
  }

  .md\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  .md\:inset-x-88 {
    right: 8.8rem;
    left: 8.8rem
  }

  .md\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  .md\:inset-x-92 {
    right: 9.2rem;
    left: 9.2rem
  }

  .md\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  .md\:inset-x-96 {
    right: 9.6rem;
    left: 9.6rem
  }

  .md\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  .md\:inset-x-128 {
    right: 12.8rem;
    left: 12.8rem
  }

  .md\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  .md\:inset-x-136 {
    right: 13.6rem;
    left: 13.6rem
  }

  .md\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  .md\:inset-x-160 {
    right: 16rem;
    left: 16rem
  }

  .md\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  .md\:inset-x-192 {
    right: 19.2rem;
    left: 19.2rem
  }

  .md\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  .md\:inset-x-200 {
    right: 20rem;
    left: 20rem
  }

  .md\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  .md\:inset-x-208 {
    right: 20.8rem;
    left: 20.8rem
  }

  .md\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  .md\:inset-x-216 {
    right: 21.6rem;
    left: 21.6rem
  }

  .md\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  .md\:inset-x-224 {
    right: 22.4rem;
    left: 22.4rem
  }

  .md\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  .md\:inset-x-256 {
    right: 25.6rem;
    left: 25.6rem
  }

  .md\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  .md\:inset-x-288 {
    right: 28.8rem;
    left: 28.8rem
  }

  .md\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  .md\:inset-x-320 {
    right: 32rem;
    left: 32rem
  }

  .md\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  .md\:inset-x-360 {
    right: 36rem;
    left: 36rem
  }

  .md\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  .md\:inset-x-384 {
    right: 38.4rem;
    left: 38.4rem
  }

  .md\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  .md\:inset-x-400 {
    right: 40rem;
    left: 40rem
  }

  .md\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  .md\:inset-x-512 {
    right: 51.2rem;
    left: 51.2rem
  }

  .md\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  .md\:inset-x-640 {
    right: 64rem;
    left: 64rem
  }

  .md\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .md\:inset-x-auto {
    right: auto;
    left: auto
  }

  .md\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  .md\:inset-x-xs {
    right: 32rem;
    left: 32rem
  }

  .md\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  .md\:inset-x-sm {
    right: 48rem;
    left: 48rem
  }

  .md\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  .md\:inset-x-md {
    right: 64rem;
    left: 64rem
  }

  .md\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  .md\:inset-x-lg {
    right: 80rem;
    left: 80rem
  }

  .md\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  .md\:inset-x-xl {
    right: 96rem;
    left: 96rem
  }

  .md\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  .md\:inset-x-2xl {
    right: 112rem;
    left: 112rem
  }

  .md\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  .md\:inset-x-3xl {
    right: 128rem;
    left: 128rem
  }

  .md\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  .md\:inset-x-4xl {
    right: 144rem;
    left: 144rem
  }

  .md\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  .md\:inset-x-5xl {
    right: 160rem;
    left: 160rem
  }

  .md\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  .md\:inset-x-px {
    right: 1px;
    left: 1px
  }

  .md\:inset-y-0\.5 {
    top: 0.05rem;
    bottom: 0.05rem
  }

  .md\:inset-x-0\.5 {
    right: 0.05rem;
    left: 0.05rem
  }

  .md\:inset-y-1\.5 {
    top: 0.15rem;
    bottom: 0.15rem
  }

  .md\:inset-x-1\.5 {
    right: 0.15rem;
    left: 0.15rem
  }

  .md\:inset-y-2\.5 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  .md\:inset-x-2\.5 {
    right: 0.25rem;
    left: 0.25rem
  }

  .md\:inset-y-3\.5 {
    top: 0.35rem;
    bottom: 0.35rem
  }

  .md\:inset-x-3\.5 {
    right: 0.35rem;
    left: 0.35rem
  }

  .md\:-inset-y-1 {
    top: -0.1rem;
    bottom: -0.1rem
  }

  .md\:-inset-x-1 {
    right: -0.1rem;
    left: -0.1rem
  }

  .md\:-inset-y-2 {
    top: -0.2rem;
    bottom: -0.2rem
  }

  .md\:-inset-x-2 {
    right: -0.2rem;
    left: -0.2rem
  }

  .md\:-inset-y-3 {
    top: -0.3rem;
    bottom: -0.3rem
  }

  .md\:-inset-x-3 {
    right: -0.3rem;
    left: -0.3rem
  }

  .md\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  .md\:-inset-x-4 {
    right: -0.4rem;
    left: -0.4rem
  }

  .md\:-inset-y-5 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  .md\:-inset-x-5 {
    right: -0.5rem;
    left: -0.5rem
  }

  .md\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  .md\:-inset-x-6 {
    right: -0.6rem;
    left: -0.6rem
  }

  .md\:-inset-y-7 {
    top: -0.7rem;
    bottom: -0.7rem
  }

  .md\:-inset-x-7 {
    right: -0.7rem;
    left: -0.7rem
  }

  .md\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  .md\:-inset-x-8 {
    right: -0.8rem;
    left: -0.8rem
  }

  .md\:-inset-y-9 {
    top: -0.9rem;
    bottom: -0.9rem
  }

  .md\:-inset-x-9 {
    right: -0.9rem;
    left: -0.9rem
  }

  .md\:-inset-y-10 {
    top: -1rem;
    bottom: -1rem
  }

  .md\:-inset-x-10 {
    right: -1rem;
    left: -1rem
  }

  .md\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  .md\:-inset-x-12 {
    right: -1.2rem;
    left: -1.2rem
  }

  .md\:-inset-y-14 {
    top: -1.4rem;
    bottom: -1.4rem
  }

  .md\:-inset-x-14 {
    right: -1.4rem;
    left: -1.4rem
  }

  .md\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  .md\:-inset-x-16 {
    right: -1.6rem;
    left: -1.6rem
  }

  .md\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  .md\:-inset-x-20 {
    right: -2rem;
    left: -2rem
  }

  .md\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  .md\:-inset-x-24 {
    right: -2.4rem;
    left: -2.4rem
  }

  .md\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  .md\:-inset-x-28 {
    right: -2.8rem;
    left: -2.8rem
  }

  .md\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  .md\:-inset-x-32 {
    right: -3.2rem;
    left: -3.2rem
  }

  .md\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  .md\:-inset-x-36 {
    right: -3.6rem;
    left: -3.6rem
  }

  .md\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  .md\:-inset-x-40 {
    right: -4rem;
    left: -4rem
  }

  .md\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  .md\:-inset-x-44 {
    right: -4.4rem;
    left: -4.4rem
  }

  .md\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  .md\:-inset-x-48 {
    right: -4.8rem;
    left: -4.8rem
  }

  .md\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  .md\:-inset-x-52 {
    right: -5.2rem;
    left: -5.2rem
  }

  .md\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  .md\:-inset-x-56 {
    right: -5.6rem;
    left: -5.6rem
  }

  .md\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  .md\:-inset-x-60 {
    right: -6rem;
    left: -6rem
  }

  .md\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  .md\:-inset-x-64 {
    right: -6.4rem;
    left: -6.4rem
  }

  .md\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  .md\:-inset-x-68 {
    right: -6.8rem;
    left: -6.8rem
  }

  .md\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  .md\:-inset-x-72 {
    right: -7.2rem;
    left: -7.2rem
  }

  .md\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  .md\:-inset-x-76 {
    right: -7.6rem;
    left: -7.6rem
  }

  .md\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  .md\:-inset-x-80 {
    right: -8rem;
    left: -8rem
  }

  .md\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  .md\:-inset-x-84 {
    right: -8.4rem;
    left: -8.4rem
  }

  .md\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  .md\:-inset-x-88 {
    right: -8.8rem;
    left: -8.8rem
  }

  .md\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  .md\:-inset-x-92 {
    right: -9.2rem;
    left: -9.2rem
  }

  .md\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  .md\:-inset-x-96 {
    right: -9.6rem;
    left: -9.6rem
  }

  .md\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  .md\:-inset-x-128 {
    right: -12.8rem;
    left: -12.8rem
  }

  .md\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  .md\:-inset-x-136 {
    right: -13.6rem;
    left: -13.6rem
  }

  .md\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  .md\:-inset-x-160 {
    right: -16rem;
    left: -16rem
  }

  .md\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  .md\:-inset-x-192 {
    right: -19.2rem;
    left: -19.2rem
  }

  .md\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  .md\:-inset-x-200 {
    right: -20rem;
    left: -20rem
  }

  .md\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  .md\:-inset-x-208 {
    right: -20.8rem;
    left: -20.8rem
  }

  .md\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  .md\:-inset-x-216 {
    right: -21.6rem;
    left: -21.6rem
  }

  .md\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  .md\:-inset-x-224 {
    right: -22.4rem;
    left: -22.4rem
  }

  .md\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  .md\:-inset-x-256 {
    right: -25.6rem;
    left: -25.6rem
  }

  .md\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  .md\:-inset-x-288 {
    right: -28.8rem;
    left: -28.8rem
  }

  .md\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  .md\:-inset-x-320 {
    right: -32rem;
    left: -32rem
  }

  .md\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  .md\:-inset-x-360 {
    right: -36rem;
    left: -36rem
  }

  .md\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  .md\:-inset-x-384 {
    right: -38.4rem;
    left: -38.4rem
  }

  .md\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  .md\:-inset-x-400 {
    right: -40rem;
    left: -40rem
  }

  .md\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  .md\:-inset-x-512 {
    right: -51.2rem;
    left: -51.2rem
  }

  .md\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  .md\:-inset-x-640 {
    right: -64rem;
    left: -64rem
  }

  .md\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  .md\:-inset-x-xs {
    right: -32rem;
    left: -32rem
  }

  .md\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  .md\:-inset-x-sm {
    right: -48rem;
    left: -48rem
  }

  .md\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  .md\:-inset-x-md {
    right: -64rem;
    left: -64rem
  }

  .md\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  .md\:-inset-x-lg {
    right: -80rem;
    left: -80rem
  }

  .md\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  .md\:-inset-x-xl {
    right: -96rem;
    left: -96rem
  }

  .md\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  .md\:-inset-x-2xl {
    right: -112rem;
    left: -112rem
  }

  .md\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  .md\:-inset-x-3xl {
    right: -128rem;
    left: -128rem
  }

  .md\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  .md\:-inset-x-4xl {
    right: -144rem;
    left: -144rem
  }

  .md\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  .md\:-inset-x-5xl {
    right: -160rem;
    left: -160rem
  }

  .md\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  .md\:-inset-x-px {
    right: -1px;
    left: -1px
  }

  .md\:-inset-y-0\.5 {
    top: -0.05rem;
    bottom: -0.05rem
  }

  .md\:-inset-x-0\.5 {
    right: -0.05rem;
    left: -0.05rem
  }

  .md\:-inset-y-1\.5 {
    top: -0.15rem;
    bottom: -0.15rem
  }

  .md\:-inset-x-1\.5 {
    right: -0.15rem;
    left: -0.15rem
  }

  .md\:-inset-y-2\.5 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  .md\:-inset-x-2\.5 {
    right: -0.25rem;
    left: -0.25rem
  }

  .md\:-inset-y-3\.5 {
    top: -0.35rem;
    bottom: -0.35rem
  }

  .md\:-inset-x-3\.5 {
    right: -0.35rem;
    left: -0.35rem
  }

  .md\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  .md\:inset-x-1\/2 {
    right: 50%;
    left: 50%
  }

  .md\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  .md\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%
  }

  .md\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  .md\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%
  }

  .md\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  .md\:inset-x-1\/4 {
    right: 25%;
    left: 25%
  }

  .md\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  .md\:inset-x-2\/4 {
    right: 50%;
    left: 50%
  }

  .md\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  .md\:inset-x-3\/4 {
    right: 75%;
    left: 75%
  }

  .md\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  .md\:inset-x-full {
    right: 100%;
    left: 100%
  }

  .md\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  .md\:-inset-x-1\/2 {
    right: -50%;
    left: -50%
  }

  .md\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  .md\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%
  }

  .md\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  .md\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%
  }

  .md\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  .md\:-inset-x-1\/4 {
    right: -25%;
    left: -25%
  }

  .md\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  .md\:-inset-x-2\/4 {
    right: -50%;
    left: -50%
  }

  .md\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  .md\:-inset-x-3\/4 {
    right: -75%;
    left: -75%
  }

  .md\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  .md\:-inset-x-full {
    right: -100%;
    left: -100%
  }

  .md\:top-0 {
    top: 0
  }

  .md\:right-0 {
    right: 0
  }

  .md\:bottom-0 {
    bottom: 0
  }

  .md\:left-0 {
    left: 0
  }

  .md\:top-1 {
    top: 0.1rem
  }

  .md\:right-1 {
    right: 0.1rem
  }

  .md\:bottom-1 {
    bottom: 0.1rem
  }

  .md\:left-1 {
    left: 0.1rem
  }

  .md\:top-2 {
    top: 0.2rem
  }

  .md\:right-2 {
    right: 0.2rem
  }

  .md\:bottom-2 {
    bottom: 0.2rem
  }

  .md\:left-2 {
    left: 0.2rem
  }

  .md\:top-3 {
    top: 0.3rem
  }

  .md\:right-3 {
    right: 0.3rem
  }

  .md\:bottom-3 {
    bottom: 0.3rem
  }

  .md\:left-3 {
    left: 0.3rem
  }

  .md\:top-4 {
    top: 0.4rem
  }

  .md\:right-4 {
    right: 0.4rem
  }

  .md\:bottom-4 {
    bottom: 0.4rem
  }

  .md\:left-4 {
    left: 0.4rem
  }

  .md\:top-5 {
    top: 0.5rem
  }

  .md\:right-5 {
    right: 0.5rem
  }

  .md\:bottom-5 {
    bottom: 0.5rem
  }

  .md\:left-5 {
    left: 0.5rem
  }

  .md\:top-6 {
    top: 0.6rem
  }

  .md\:right-6 {
    right: 0.6rem
  }

  .md\:bottom-6 {
    bottom: 0.6rem
  }

  .md\:left-6 {
    left: 0.6rem
  }

  .md\:top-7 {
    top: 0.7rem
  }

  .md\:right-7 {
    right: 0.7rem
  }

  .md\:bottom-7 {
    bottom: 0.7rem
  }

  .md\:left-7 {
    left: 0.7rem
  }

  .md\:top-8 {
    top: 0.8rem
  }

  .md\:right-8 {
    right: 0.8rem
  }

  .md\:bottom-8 {
    bottom: 0.8rem
  }

  .md\:left-8 {
    left: 0.8rem
  }

  .md\:top-9 {
    top: 0.9rem
  }

  .md\:right-9 {
    right: 0.9rem
  }

  .md\:bottom-9 {
    bottom: 0.9rem
  }

  .md\:left-9 {
    left: 0.9rem
  }

  .md\:top-10 {
    top: 1.0rem
  }

  .md\:right-10 {
    right: 1.0rem
  }

  .md\:bottom-10 {
    bottom: 1.0rem
  }

  .md\:left-10 {
    left: 1.0rem
  }

  .md\:top-12 {
    top: 1.2rem
  }

  .md\:right-12 {
    right: 1.2rem
  }

  .md\:bottom-12 {
    bottom: 1.2rem
  }

  .md\:left-12 {
    left: 1.2rem
  }

  .md\:top-14 {
    top: 1.4rem
  }

  .md\:right-14 {
    right: 1.4rem
  }

  .md\:bottom-14 {
    bottom: 1.4rem
  }

  .md\:left-14 {
    left: 1.4rem
  }

  .md\:top-16 {
    top: 1.6rem
  }

  .md\:right-16 {
    right: 1.6rem
  }

  .md\:bottom-16 {
    bottom: 1.6rem
  }

  .md\:left-16 {
    left: 1.6rem
  }

  .md\:top-20 {
    top: 2rem
  }

  .md\:right-20 {
    right: 2rem
  }

  .md\:bottom-20 {
    bottom: 2rem
  }

  .md\:left-20 {
    left: 2rem
  }

  .md\:top-24 {
    top: 2.4rem
  }

  .md\:right-24 {
    right: 2.4rem
  }

  .md\:bottom-24 {
    bottom: 2.4rem
  }

  .md\:left-24 {
    left: 2.4rem
  }

  .md\:top-28 {
    top: 2.8rem
  }

  .md\:right-28 {
    right: 2.8rem
  }

  .md\:bottom-28 {
    bottom: 2.8rem
  }

  .md\:left-28 {
    left: 2.8rem
  }

  .md\:top-32 {
    top: 3.2rem
  }

  .md\:right-32 {
    right: 3.2rem
  }

  .md\:bottom-32 {
    bottom: 3.2rem
  }

  .md\:left-32 {
    left: 3.2rem
  }

  .md\:top-36 {
    top: 3.6rem
  }

  .md\:right-36 {
    right: 3.6rem
  }

  .md\:bottom-36 {
    bottom: 3.6rem
  }

  .md\:left-36 {
    left: 3.6rem
  }

  .md\:top-40 {
    top: 4rem
  }

  .md\:right-40 {
    right: 4rem
  }

  .md\:bottom-40 {
    bottom: 4rem
  }

  .md\:left-40 {
    left: 4rem
  }

  .md\:top-44 {
    top: 4.4rem
  }

  .md\:right-44 {
    right: 4.4rem
  }

  .md\:bottom-44 {
    bottom: 4.4rem
  }

  .md\:left-44 {
    left: 4.4rem
  }

  .md\:top-48 {
    top: 4.8rem
  }

  .md\:right-48 {
    right: 4.8rem
  }

  .md\:bottom-48 {
    bottom: 4.8rem
  }

  .md\:left-48 {
    left: 4.8rem
  }

  .md\:top-52 {
    top: 5.2rem
  }

  .md\:right-52 {
    right: 5.2rem
  }

  .md\:bottom-52 {
    bottom: 5.2rem
  }

  .md\:left-52 {
    left: 5.2rem
  }

  .md\:top-56 {
    top: 5.6rem
  }

  .md\:right-56 {
    right: 5.6rem
  }

  .md\:bottom-56 {
    bottom: 5.6rem
  }

  .md\:left-56 {
    left: 5.6rem
  }

  .md\:top-60 {
    top: 6rem
  }

  .md\:right-60 {
    right: 6rem
  }

  .md\:bottom-60 {
    bottom: 6rem
  }

  .md\:left-60 {
    left: 6rem
  }

  .md\:top-64 {
    top: 6.4rem
  }

  .md\:right-64 {
    right: 6.4rem
  }

  .md\:bottom-64 {
    bottom: 6.4rem
  }

  .md\:left-64 {
    left: 6.4rem
  }

  .md\:top-68 {
    top: 6.8rem
  }

  .md\:right-68 {
    right: 6.8rem
  }

  .md\:bottom-68 {
    bottom: 6.8rem
  }

  .md\:left-68 {
    left: 6.8rem
  }

  .md\:top-72 {
    top: 7.2rem
  }

  .md\:right-72 {
    right: 7.2rem
  }

  .md\:bottom-72 {
    bottom: 7.2rem
  }

  .md\:left-72 {
    left: 7.2rem
  }

  .md\:top-76 {
    top: 7.6rem
  }

  .md\:right-76 {
    right: 7.6rem
  }

  .md\:bottom-76 {
    bottom: 7.6rem
  }

  .md\:left-76 {
    left: 7.6rem
  }

  .md\:top-80 {
    top: 8rem
  }

  .md\:right-80 {
    right: 8rem
  }

  .md\:bottom-80 {
    bottom: 8rem
  }

  .md\:left-80 {
    left: 8rem
  }

  .md\:top-84 {
    top: 8.4rem
  }

  .md\:right-84 {
    right: 8.4rem
  }

  .md\:bottom-84 {
    bottom: 8.4rem
  }

  .md\:left-84 {
    left: 8.4rem
  }

  .md\:top-88 {
    top: 8.8rem
  }

  .md\:right-88 {
    right: 8.8rem
  }

  .md\:bottom-88 {
    bottom: 8.8rem
  }

  .md\:left-88 {
    left: 8.8rem
  }

  .md\:top-92 {
    top: 9.2rem
  }

  .md\:right-92 {
    right: 9.2rem
  }

  .md\:bottom-92 {
    bottom: 9.2rem
  }

  .md\:left-92 {
    left: 9.2rem
  }

  .md\:top-96 {
    top: 9.6rem
  }

  .md\:right-96 {
    right: 9.6rem
  }

  .md\:bottom-96 {
    bottom: 9.6rem
  }

  .md\:left-96 {
    left: 9.6rem
  }

  .md\:top-128 {
    top: 12.8rem
  }

  .md\:right-128 {
    right: 12.8rem
  }

  .md\:bottom-128 {
    bottom: 12.8rem
  }

  .md\:left-128 {
    left: 12.8rem
  }

  .md\:top-136 {
    top: 13.6rem
  }

  .md\:right-136 {
    right: 13.6rem
  }

  .md\:bottom-136 {
    bottom: 13.6rem
  }

  .md\:left-136 {
    left: 13.6rem
  }

  .md\:top-160 {
    top: 16rem
  }

  .md\:right-160 {
    right: 16rem
  }

  .md\:bottom-160 {
    bottom: 16rem
  }

  .md\:left-160 {
    left: 16rem
  }

  .md\:top-192 {
    top: 19.2rem
  }

  .md\:right-192 {
    right: 19.2rem
  }

  .md\:bottom-192 {
    bottom: 19.2rem
  }

  .md\:left-192 {
    left: 19.2rem
  }

  .md\:top-200 {
    top: 20rem
  }

  .md\:right-200 {
    right: 20rem
  }

  .md\:bottom-200 {
    bottom: 20rem
  }

  .md\:left-200 {
    left: 20rem
  }

  .md\:top-208 {
    top: 20.8rem
  }

  .md\:right-208 {
    right: 20.8rem
  }

  .md\:bottom-208 {
    bottom: 20.8rem
  }

  .md\:left-208 {
    left: 20.8rem
  }

  .md\:top-216 {
    top: 21.6rem
  }

  .md\:right-216 {
    right: 21.6rem
  }

  .md\:bottom-216 {
    bottom: 21.6rem
  }

  .md\:left-216 {
    left: 21.6rem
  }

  .md\:top-224 {
    top: 22.4rem
  }

  .md\:right-224 {
    right: 22.4rem
  }

  .md\:bottom-224 {
    bottom: 22.4rem
  }

  .md\:left-224 {
    left: 22.4rem
  }

  .md\:top-256 {
    top: 25.6rem
  }

  .md\:right-256 {
    right: 25.6rem
  }

  .md\:bottom-256 {
    bottom: 25.6rem
  }

  .md\:left-256 {
    left: 25.6rem
  }

  .md\:top-288 {
    top: 28.8rem
  }

  .md\:right-288 {
    right: 28.8rem
  }

  .md\:bottom-288 {
    bottom: 28.8rem
  }

  .md\:left-288 {
    left: 28.8rem
  }

  .md\:top-320 {
    top: 32rem
  }

  .md\:right-320 {
    right: 32rem
  }

  .md\:bottom-320 {
    bottom: 32rem
  }

  .md\:left-320 {
    left: 32rem
  }

  .md\:top-360 {
    top: 36rem
  }

  .md\:right-360 {
    right: 36rem
  }

  .md\:bottom-360 {
    bottom: 36rem
  }

  .md\:left-360 {
    left: 36rem
  }

  .md\:top-384 {
    top: 38.4rem
  }

  .md\:right-384 {
    right: 38.4rem
  }

  .md\:bottom-384 {
    bottom: 38.4rem
  }

  .md\:left-384 {
    left: 38.4rem
  }

  .md\:top-400 {
    top: 40rem
  }

  .md\:right-400 {
    right: 40rem
  }

  .md\:bottom-400 {
    bottom: 40rem
  }

  .md\:left-400 {
    left: 40rem
  }

  .md\:top-512 {
    top: 51.2rem
  }

  .md\:right-512 {
    right: 51.2rem
  }

  .md\:bottom-512 {
    bottom: 51.2rem
  }

  .md\:left-512 {
    left: 51.2rem
  }

  .md\:top-640 {
    top: 64rem
  }

  .md\:right-640 {
    right: 64rem
  }

  .md\:bottom-640 {
    bottom: 64rem
  }

  .md\:left-640 {
    left: 64rem
  }

  .md\:top-auto {
    top: auto
  }

  .md\:right-auto {
    right: auto
  }

  .md\:bottom-auto {
    bottom: auto
  }

  .md\:left-auto {
    left: auto
  }

  .md\:top-xs {
    top: 32rem
  }

  .md\:right-xs {
    right: 32rem
  }

  .md\:bottom-xs {
    bottom: 32rem
  }

  .md\:left-xs {
    left: 32rem
  }

  .md\:top-sm {
    top: 48rem
  }

  .md\:right-sm {
    right: 48rem
  }

  .md\:bottom-sm {
    bottom: 48rem
  }

  .md\:left-sm {
    left: 48rem
  }

  .md\:top-md {
    top: 64rem
  }

  .md\:right-md {
    right: 64rem
  }

  .md\:bottom-md {
    bottom: 64rem
  }

  .md\:left-md {
    left: 64rem
  }

  .md\:top-lg {
    top: 80rem
  }

  .md\:right-lg {
    right: 80rem
  }

  .md\:bottom-lg {
    bottom: 80rem
  }

  .md\:left-lg {
    left: 80rem
  }

  .md\:top-xl {
    top: 96rem
  }

  .md\:right-xl {
    right: 96rem
  }

  .md\:bottom-xl {
    bottom: 96rem
  }

  .md\:left-xl {
    left: 96rem
  }

  .md\:top-2xl {
    top: 112rem
  }

  .md\:right-2xl {
    right: 112rem
  }

  .md\:bottom-2xl {
    bottom: 112rem
  }

  .md\:left-2xl {
    left: 112rem
  }

  .md\:top-3xl {
    top: 128rem
  }

  .md\:right-3xl {
    right: 128rem
  }

  .md\:bottom-3xl {
    bottom: 128rem
  }

  .md\:left-3xl {
    left: 128rem
  }

  .md\:top-4xl {
    top: 144rem
  }

  .md\:right-4xl {
    right: 144rem
  }

  .md\:bottom-4xl {
    bottom: 144rem
  }

  .md\:left-4xl {
    left: 144rem
  }

  .md\:top-5xl {
    top: 160rem
  }

  .md\:right-5xl {
    right: 160rem
  }

  .md\:bottom-5xl {
    bottom: 160rem
  }

  .md\:left-5xl {
    left: 160rem
  }

  .md\:top-px {
    top: 1px
  }

  .md\:right-px {
    right: 1px
  }

  .md\:bottom-px {
    bottom: 1px
  }

  .md\:left-px {
    left: 1px
  }

  .md\:top-0\.5 {
    top: 0.05rem
  }

  .md\:right-0\.5 {
    right: 0.05rem
  }

  .md\:bottom-0\.5 {
    bottom: 0.05rem
  }

  .md\:left-0\.5 {
    left: 0.05rem
  }

  .md\:top-1\.5 {
    top: 0.15rem
  }

  .md\:right-1\.5 {
    right: 0.15rem
  }

  .md\:bottom-1\.5 {
    bottom: 0.15rem
  }

  .md\:left-1\.5 {
    left: 0.15rem
  }

  .md\:top-2\.5 {
    top: 0.25rem
  }

  .md\:right-2\.5 {
    right: 0.25rem
  }

  .md\:bottom-2\.5 {
    bottom: 0.25rem
  }

  .md\:left-2\.5 {
    left: 0.25rem
  }

  .md\:top-3\.5 {
    top: 0.35rem
  }

  .md\:right-3\.5 {
    right: 0.35rem
  }

  .md\:bottom-3\.5 {
    bottom: 0.35rem
  }

  .md\:left-3\.5 {
    left: 0.35rem
  }

  .md\:-top-1 {
    top: -0.1rem
  }

  .md\:-right-1 {
    right: -0.1rem
  }

  .md\:-bottom-1 {
    bottom: -0.1rem
  }

  .md\:-left-1 {
    left: -0.1rem
  }

  .md\:-top-2 {
    top: -0.2rem
  }

  .md\:-right-2 {
    right: -0.2rem
  }

  .md\:-bottom-2 {
    bottom: -0.2rem
  }

  .md\:-left-2 {
    left: -0.2rem
  }

  .md\:-top-3 {
    top: -0.3rem
  }

  .md\:-right-3 {
    right: -0.3rem
  }

  .md\:-bottom-3 {
    bottom: -0.3rem
  }

  .md\:-left-3 {
    left: -0.3rem
  }

  .md\:-top-4 {
    top: -0.4rem
  }

  .md\:-right-4 {
    right: -0.4rem
  }

  .md\:-bottom-4 {
    bottom: -0.4rem
  }

  .md\:-left-4 {
    left: -0.4rem
  }

  .md\:-top-5 {
    top: -0.5rem
  }

  .md\:-right-5 {
    right: -0.5rem
  }

  .md\:-bottom-5 {
    bottom: -0.5rem
  }

  .md\:-left-5 {
    left: -0.5rem
  }

  .md\:-top-6 {
    top: -0.6rem
  }

  .md\:-right-6 {
    right: -0.6rem
  }

  .md\:-bottom-6 {
    bottom: -0.6rem
  }

  .md\:-left-6 {
    left: -0.6rem
  }

  .md\:-top-7 {
    top: -0.7rem
  }

  .md\:-right-7 {
    right: -0.7rem
  }

  .md\:-bottom-7 {
    bottom: -0.7rem
  }

  .md\:-left-7 {
    left: -0.7rem
  }

  .md\:-top-8 {
    top: -0.8rem
  }

  .md\:-right-8 {
    right: -0.8rem
  }

  .md\:-bottom-8 {
    bottom: -0.8rem
  }

  .md\:-left-8 {
    left: -0.8rem
  }

  .md\:-top-9 {
    top: -0.9rem
  }

  .md\:-right-9 {
    right: -0.9rem
  }

  .md\:-bottom-9 {
    bottom: -0.9rem
  }

  .md\:-left-9 {
    left: -0.9rem
  }

  .md\:-top-10 {
    top: -1rem
  }

  .md\:-right-10 {
    right: -1rem
  }

  .md\:-bottom-10 {
    bottom: -1rem
  }

  .md\:-left-10 {
    left: -1rem
  }

  .md\:-top-12 {
    top: -1.2rem
  }

  .md\:-right-12 {
    right: -1.2rem
  }

  .md\:-bottom-12 {
    bottom: -1.2rem
  }

  .md\:-left-12 {
    left: -1.2rem
  }

  .md\:-top-14 {
    top: -1.4rem
  }

  .md\:-right-14 {
    right: -1.4rem
  }

  .md\:-bottom-14 {
    bottom: -1.4rem
  }

  .md\:-left-14 {
    left: -1.4rem
  }

  .md\:-top-16 {
    top: -1.6rem
  }

  .md\:-right-16 {
    right: -1.6rem
  }

  .md\:-bottom-16 {
    bottom: -1.6rem
  }

  .md\:-left-16 {
    left: -1.6rem
  }

  .md\:-top-20 {
    top: -2rem
  }

  .md\:-right-20 {
    right: -2rem
  }

  .md\:-bottom-20 {
    bottom: -2rem
  }

  .md\:-left-20 {
    left: -2rem
  }

  .md\:-top-24 {
    top: -2.4rem
  }

  .md\:-right-24 {
    right: -2.4rem
  }

  .md\:-bottom-24 {
    bottom: -2.4rem
  }

  .md\:-left-24 {
    left: -2.4rem
  }

  .md\:-top-28 {
    top: -2.8rem
  }

  .md\:-right-28 {
    right: -2.8rem
  }

  .md\:-bottom-28 {
    bottom: -2.8rem
  }

  .md\:-left-28 {
    left: -2.8rem
  }

  .md\:-top-32 {
    top: -3.2rem
  }

  .md\:-right-32 {
    right: -3.2rem
  }

  .md\:-bottom-32 {
    bottom: -3.2rem
  }

  .md\:-left-32 {
    left: -3.2rem
  }

  .md\:-top-36 {
    top: -3.6rem
  }

  .md\:-right-36 {
    right: -3.6rem
  }

  .md\:-bottom-36 {
    bottom: -3.6rem
  }

  .md\:-left-36 {
    left: -3.6rem
  }

  .md\:-top-40 {
    top: -4rem
  }

  .md\:-right-40 {
    right: -4rem
  }

  .md\:-bottom-40 {
    bottom: -4rem
  }

  .md\:-left-40 {
    left: -4rem
  }

  .md\:-top-44 {
    top: -4.4rem
  }

  .md\:-right-44 {
    right: -4.4rem
  }

  .md\:-bottom-44 {
    bottom: -4.4rem
  }

  .md\:-left-44 {
    left: -4.4rem
  }

  .md\:-top-48 {
    top: -4.8rem
  }

  .md\:-right-48 {
    right: -4.8rem
  }

  .md\:-bottom-48 {
    bottom: -4.8rem
  }

  .md\:-left-48 {
    left: -4.8rem
  }

  .md\:-top-52 {
    top: -5.2rem
  }

  .md\:-right-52 {
    right: -5.2rem
  }

  .md\:-bottom-52 {
    bottom: -5.2rem
  }

  .md\:-left-52 {
    left: -5.2rem
  }

  .md\:-top-56 {
    top: -5.6rem
  }

  .md\:-right-56 {
    right: -5.6rem
  }

  .md\:-bottom-56 {
    bottom: -5.6rem
  }

  .md\:-left-56 {
    left: -5.6rem
  }

  .md\:-top-60 {
    top: -6rem
  }

  .md\:-right-60 {
    right: -6rem
  }

  .md\:-bottom-60 {
    bottom: -6rem
  }

  .md\:-left-60 {
    left: -6rem
  }

  .md\:-top-64 {
    top: -6.4rem
  }

  .md\:-right-64 {
    right: -6.4rem
  }

  .md\:-bottom-64 {
    bottom: -6.4rem
  }

  .md\:-left-64 {
    left: -6.4rem
  }

  .md\:-top-68 {
    top: -6.8rem
  }

  .md\:-right-68 {
    right: -6.8rem
  }

  .md\:-bottom-68 {
    bottom: -6.8rem
  }

  .md\:-left-68 {
    left: -6.8rem
  }

  .md\:-top-72 {
    top: -7.2rem
  }

  .md\:-right-72 {
    right: -7.2rem
  }

  .md\:-bottom-72 {
    bottom: -7.2rem
  }

  .md\:-left-72 {
    left: -7.2rem
  }

  .md\:-top-76 {
    top: -7.6rem
  }

  .md\:-right-76 {
    right: -7.6rem
  }

  .md\:-bottom-76 {
    bottom: -7.6rem
  }

  .md\:-left-76 {
    left: -7.6rem
  }

  .md\:-top-80 {
    top: -8rem
  }

  .md\:-right-80 {
    right: -8rem
  }

  .md\:-bottom-80 {
    bottom: -8rem
  }

  .md\:-left-80 {
    left: -8rem
  }

  .md\:-top-84 {
    top: -8.4rem
  }

  .md\:-right-84 {
    right: -8.4rem
  }

  .md\:-bottom-84 {
    bottom: -8.4rem
  }

  .md\:-left-84 {
    left: -8.4rem
  }

  .md\:-top-88 {
    top: -8.8rem
  }

  .md\:-right-88 {
    right: -8.8rem
  }

  .md\:-bottom-88 {
    bottom: -8.8rem
  }

  .md\:-left-88 {
    left: -8.8rem
  }

  .md\:-top-92 {
    top: -9.2rem
  }

  .md\:-right-92 {
    right: -9.2rem
  }

  .md\:-bottom-92 {
    bottom: -9.2rem
  }

  .md\:-left-92 {
    left: -9.2rem
  }

  .md\:-top-96 {
    top: -9.6rem
  }

  .md\:-right-96 {
    right: -9.6rem
  }

  .md\:-bottom-96 {
    bottom: -9.6rem
  }

  .md\:-left-96 {
    left: -9.6rem
  }

  .md\:-top-128 {
    top: -12.8rem
  }

  .md\:-right-128 {
    right: -12.8rem
  }

  .md\:-bottom-128 {
    bottom: -12.8rem
  }

  .md\:-left-128 {
    left: -12.8rem
  }

  .md\:-top-136 {
    top: -13.6rem
  }

  .md\:-right-136 {
    right: -13.6rem
  }

  .md\:-bottom-136 {
    bottom: -13.6rem
  }

  .md\:-left-136 {
    left: -13.6rem
  }

  .md\:-top-160 {
    top: -16rem
  }

  .md\:-right-160 {
    right: -16rem
  }

  .md\:-bottom-160 {
    bottom: -16rem
  }

  .md\:-left-160 {
    left: -16rem
  }

  .md\:-top-192 {
    top: -19.2rem
  }

  .md\:-right-192 {
    right: -19.2rem
  }

  .md\:-bottom-192 {
    bottom: -19.2rem
  }

  .md\:-left-192 {
    left: -19.2rem
  }

  .md\:-top-200 {
    top: -20rem
  }

  .md\:-right-200 {
    right: -20rem
  }

  .md\:-bottom-200 {
    bottom: -20rem
  }

  .md\:-left-200 {
    left: -20rem
  }

  .md\:-top-208 {
    top: -20.8rem
  }

  .md\:-right-208 {
    right: -20.8rem
  }

  .md\:-bottom-208 {
    bottom: -20.8rem
  }

  .md\:-left-208 {
    left: -20.8rem
  }

  .md\:-top-216 {
    top: -21.6rem
  }

  .md\:-right-216 {
    right: -21.6rem
  }

  .md\:-bottom-216 {
    bottom: -21.6rem
  }

  .md\:-left-216 {
    left: -21.6rem
  }

  .md\:-top-224 {
    top: -22.4rem
  }

  .md\:-right-224 {
    right: -22.4rem
  }

  .md\:-bottom-224 {
    bottom: -22.4rem
  }

  .md\:-left-224 {
    left: -22.4rem
  }

  .md\:-top-256 {
    top: -25.6rem
  }

  .md\:-right-256 {
    right: -25.6rem
  }

  .md\:-bottom-256 {
    bottom: -25.6rem
  }

  .md\:-left-256 {
    left: -25.6rem
  }

  .md\:-top-288 {
    top: -28.8rem
  }

  .md\:-right-288 {
    right: -28.8rem
  }

  .md\:-bottom-288 {
    bottom: -28.8rem
  }

  .md\:-left-288 {
    left: -28.8rem
  }

  .md\:-top-320 {
    top: -32rem
  }

  .md\:-right-320 {
    right: -32rem
  }

  .md\:-bottom-320 {
    bottom: -32rem
  }

  .md\:-left-320 {
    left: -32rem
  }

  .md\:-top-360 {
    top: -36rem
  }

  .md\:-right-360 {
    right: -36rem
  }

  .md\:-bottom-360 {
    bottom: -36rem
  }

  .md\:-left-360 {
    left: -36rem
  }

  .md\:-top-384 {
    top: -38.4rem
  }

  .md\:-right-384 {
    right: -38.4rem
  }

  .md\:-bottom-384 {
    bottom: -38.4rem
  }

  .md\:-left-384 {
    left: -38.4rem
  }

  .md\:-top-400 {
    top: -40rem
  }

  .md\:-right-400 {
    right: -40rem
  }

  .md\:-bottom-400 {
    bottom: -40rem
  }

  .md\:-left-400 {
    left: -40rem
  }

  .md\:-top-512 {
    top: -51.2rem
  }

  .md\:-right-512 {
    right: -51.2rem
  }

  .md\:-bottom-512 {
    bottom: -51.2rem
  }

  .md\:-left-512 {
    left: -51.2rem
  }

  .md\:-top-640 {
    top: -64rem
  }

  .md\:-right-640 {
    right: -64rem
  }

  .md\:-bottom-640 {
    bottom: -64rem
  }

  .md\:-left-640 {
    left: -64rem
  }

  .md\:-top-xs {
    top: -32rem
  }

  .md\:-right-xs {
    right: -32rem
  }

  .md\:-bottom-xs {
    bottom: -32rem
  }

  .md\:-left-xs {
    left: -32rem
  }

  .md\:-top-sm {
    top: -48rem
  }

  .md\:-right-sm {
    right: -48rem
  }

  .md\:-bottom-sm {
    bottom: -48rem
  }

  .md\:-left-sm {
    left: -48rem
  }

  .md\:-top-md {
    top: -64rem
  }

  .md\:-right-md {
    right: -64rem
  }

  .md\:-bottom-md {
    bottom: -64rem
  }

  .md\:-left-md {
    left: -64rem
  }

  .md\:-top-lg {
    top: -80rem
  }

  .md\:-right-lg {
    right: -80rem
  }

  .md\:-bottom-lg {
    bottom: -80rem
  }

  .md\:-left-lg {
    left: -80rem
  }

  .md\:-top-xl {
    top: -96rem
  }

  .md\:-right-xl {
    right: -96rem
  }

  .md\:-bottom-xl {
    bottom: -96rem
  }

  .md\:-left-xl {
    left: -96rem
  }

  .md\:-top-2xl {
    top: -112rem
  }

  .md\:-right-2xl {
    right: -112rem
  }

  .md\:-bottom-2xl {
    bottom: -112rem
  }

  .md\:-left-2xl {
    left: -112rem
  }

  .md\:-top-3xl {
    top: -128rem
  }

  .md\:-right-3xl {
    right: -128rem
  }

  .md\:-bottom-3xl {
    bottom: -128rem
  }

  .md\:-left-3xl {
    left: -128rem
  }

  .md\:-top-4xl {
    top: -144rem
  }

  .md\:-right-4xl {
    right: -144rem
  }

  .md\:-bottom-4xl {
    bottom: -144rem
  }

  .md\:-left-4xl {
    left: -144rem
  }

  .md\:-top-5xl {
    top: -160rem
  }

  .md\:-right-5xl {
    right: -160rem
  }

  .md\:-bottom-5xl {
    bottom: -160rem
  }

  .md\:-left-5xl {
    left: -160rem
  }

  .md\:-top-px {
    top: -1px
  }

  .md\:-right-px {
    right: -1px
  }

  .md\:-bottom-px {
    bottom: -1px
  }

  .md\:-left-px {
    left: -1px
  }

  .md\:-top-0\.5 {
    top: -0.05rem
  }

  .md\:-right-0\.5 {
    right: -0.05rem
  }

  .md\:-bottom-0\.5 {
    bottom: -0.05rem
  }

  .md\:-left-0\.5 {
    left: -0.05rem
  }

  .md\:-top-1\.5 {
    top: -0.15rem
  }

  .md\:-right-1\.5 {
    right: -0.15rem
  }

  .md\:-bottom-1\.5 {
    bottom: -0.15rem
  }

  .md\:-left-1\.5 {
    left: -0.15rem
  }

  .md\:-top-2\.5 {
    top: -0.25rem
  }

  .md\:-right-2\.5 {
    right: -0.25rem
  }

  .md\:-bottom-2\.5 {
    bottom: -0.25rem
  }

  .md\:-left-2\.5 {
    left: -0.25rem
  }

  .md\:-top-3\.5 {
    top: -0.35rem
  }

  .md\:-right-3\.5 {
    right: -0.35rem
  }

  .md\:-bottom-3\.5 {
    bottom: -0.35rem
  }

  .md\:-left-3\.5 {
    left: -0.35rem
  }

  .md\:top-1\/2 {
    top: 50%
  }

  .md\:right-1\/2 {
    right: 50%
  }

  .md\:bottom-1\/2 {
    bottom: 50%
  }

  .md\:left-1\/2 {
    left: 50%
  }

  .md\:top-1\/3 {
    top: 33.333333%
  }

  .md\:right-1\/3 {
    right: 33.333333%
  }

  .md\:bottom-1\/3 {
    bottom: 33.333333%
  }

  .md\:left-1\/3 {
    left: 33.333333%
  }

  .md\:top-2\/3 {
    top: 66.666667%
  }

  .md\:right-2\/3 {
    right: 66.666667%
  }

  .md\:bottom-2\/3 {
    bottom: 66.666667%
  }

  .md\:left-2\/3 {
    left: 66.666667%
  }

  .md\:top-1\/4 {
    top: 25%
  }

  .md\:right-1\/4 {
    right: 25%
  }

  .md\:bottom-1\/4 {
    bottom: 25%
  }

  .md\:left-1\/4 {
    left: 25%
  }

  .md\:top-2\/4 {
    top: 50%
  }

  .md\:right-2\/4 {
    right: 50%
  }

  .md\:bottom-2\/4 {
    bottom: 50%
  }

  .md\:left-2\/4 {
    left: 50%
  }

  .md\:top-3\/4 {
    top: 75%
  }

  .md\:right-3\/4 {
    right: 75%
  }

  .md\:bottom-3\/4 {
    bottom: 75%
  }

  .md\:left-3\/4 {
    left: 75%
  }

  .md\:top-full {
    top: 100%
  }

  .md\:right-full {
    right: 100%
  }

  .md\:bottom-full {
    bottom: 100%
  }

  .md\:left-full {
    left: 100%
  }

  .md\:-top-1\/2 {
    top: -50%
  }

  .md\:-right-1\/2 {
    right: -50%
  }

  .md\:-bottom-1\/2 {
    bottom: -50%
  }

  .md\:-left-1\/2 {
    left: -50%
  }

  .md\:-top-1\/3 {
    top: -33.333333%
  }

  .md\:-right-1\/3 {
    right: -33.333333%
  }

  .md\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  .md\:-left-1\/3 {
    left: -33.333333%
  }

  .md\:-top-2\/3 {
    top: -66.666667%
  }

  .md\:-right-2\/3 {
    right: -66.666667%
  }

  .md\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  .md\:-left-2\/3 {
    left: -66.666667%
  }

  .md\:-top-1\/4 {
    top: -25%
  }

  .md\:-right-1\/4 {
    right: -25%
  }

  .md\:-bottom-1\/4 {
    bottom: -25%
  }

  .md\:-left-1\/4 {
    left: -25%
  }

  .md\:-top-2\/4 {
    top: -50%
  }

  .md\:-right-2\/4 {
    right: -50%
  }

  .md\:-bottom-2\/4 {
    bottom: -50%
  }

  .md\:-left-2\/4 {
    left: -50%
  }

  .md\:-top-3\/4 {
    top: -75%
  }

  .md\:-right-3\/4 {
    right: -75%
  }

  .md\:-bottom-3\/4 {
    bottom: -75%
  }

  .md\:-left-3\/4 {
    left: -75%
  }

  .md\:-top-full {
    top: -100%
  }

  .md\:-right-full {
    right: -100%
  }

  .md\:-bottom-full {
    bottom: -100%
  }

  .md\:-left-full {
    left: -100%
  }

  [dir='ltr'] .md\:ltr\:inset-0,[dir='ltr'].md\:ltr\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  [dir='ltr'] .md\:ltr\:inset-1,[dir='ltr'].md\:ltr\:inset-1 {
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:inset-2,[dir='ltr'].md\:ltr\:inset-2 {
    top: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-3,[dir='ltr'].md\:ltr\:inset-3 {
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:inset-4,[dir='ltr'].md\:ltr\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-5,[dir='ltr'].md\:ltr\:inset-5 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:inset-6,[dir='ltr'].md\:ltr\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-7,[dir='ltr'].md\:ltr\:inset-7 {
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
    left: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:inset-8,[dir='ltr'].md\:ltr\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-9,[dir='ltr'].md\:ltr\:inset-9 {
    top: 0.9rem;
    right: 0.9rem;
    bottom: 0.9rem;
    left: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:inset-10,[dir='ltr'].md\:ltr\:inset-10 {
    top: 1.0rem;
    right: 1.0rem;
    bottom: 1.0rem;
    left: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:inset-12,[dir='ltr'].md\:ltr\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-14,[dir='ltr'].md\:ltr\:inset-14 {
    top: 1.4rem;
    right: 1.4rem;
    bottom: 1.4rem;
    left: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-16,[dir='ltr'].md\:ltr\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-20,[dir='ltr'].md\:ltr\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  [dir='ltr'] .md\:ltr\:inset-24,[dir='ltr'].md\:ltr\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-28,[dir='ltr'].md\:ltr\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-32,[dir='ltr'].md\:ltr\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-36,[dir='ltr'].md\:ltr\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-40,[dir='ltr'].md\:ltr\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  [dir='ltr'] .md\:ltr\:inset-44,[dir='ltr'].md\:ltr\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-48,[dir='ltr'].md\:ltr\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-52,[dir='ltr'].md\:ltr\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-56,[dir='ltr'].md\:ltr\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-60,[dir='ltr'].md\:ltr\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  [dir='ltr'] .md\:ltr\:inset-64,[dir='ltr'].md\:ltr\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-68,[dir='ltr'].md\:ltr\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-72,[dir='ltr'].md\:ltr\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-76,[dir='ltr'].md\:ltr\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-80,[dir='ltr'].md\:ltr\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  [dir='ltr'] .md\:ltr\:inset-84,[dir='ltr'].md\:ltr\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-88,[dir='ltr'].md\:ltr\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-92,[dir='ltr'].md\:ltr\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-96,[dir='ltr'].md\:ltr\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-128,[dir='ltr'].md\:ltr\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-136,[dir='ltr'].md\:ltr\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-160,[dir='ltr'].md\:ltr\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  [dir='ltr'] .md\:ltr\:inset-192,[dir='ltr'].md\:ltr\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-200,[dir='ltr'].md\:ltr\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  [dir='ltr'] .md\:ltr\:inset-208,[dir='ltr'].md\:ltr\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-216,[dir='ltr'].md\:ltr\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-224,[dir='ltr'].md\:ltr\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-256,[dir='ltr'].md\:ltr\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-288,[dir='ltr'].md\:ltr\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-320,[dir='ltr'].md\:ltr\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='ltr'] .md\:ltr\:inset-360,[dir='ltr'].md\:ltr\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  [dir='ltr'] .md\:ltr\:inset-384,[dir='ltr'].md\:ltr\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-400,[dir='ltr'].md\:ltr\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  [dir='ltr'] .md\:ltr\:inset-512,[dir='ltr'].md\:ltr\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-640,[dir='ltr'].md\:ltr\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='ltr'] .md\:ltr\:inset-auto,[dir='ltr'].md\:ltr\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  [dir='ltr'] .md\:ltr\:inset-xs,[dir='ltr'].md\:ltr\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='ltr'] .md\:ltr\:inset-sm,[dir='ltr'].md\:ltr\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  [dir='ltr'] .md\:ltr\:inset-md,[dir='ltr'].md\:ltr\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='ltr'] .md\:ltr\:inset-lg,[dir='ltr'].md\:ltr\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  [dir='ltr'] .md\:ltr\:inset-xl,[dir='ltr'].md\:ltr\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  [dir='ltr'] .md\:ltr\:inset-2xl,[dir='ltr'].md\:ltr\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  [dir='ltr'] .md\:ltr\:inset-3xl,[dir='ltr'].md\:ltr\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  [dir='ltr'] .md\:ltr\:inset-4xl,[dir='ltr'].md\:ltr\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  [dir='ltr'] .md\:ltr\:inset-5xl,[dir='ltr'].md\:ltr\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  [dir='ltr'] .md\:ltr\:inset-px,[dir='ltr'].md\:ltr\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  [dir='ltr'] .md\:ltr\:inset-0\.5,[dir='ltr'].md\:ltr\:inset-0\.5 {
    top: 0.05rem;
    right: 0.05rem;
    bottom: 0.05rem;
    left: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:inset-1\.5,[dir='ltr'].md\:ltr\:inset-1\.5 {
    top: 0.15rem;
    right: 0.15rem;
    bottom: 0.15rem;
    left: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:inset-2\.5,[dir='ltr'].md\:ltr\:inset-2\.5 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:inset-3\.5,[dir='ltr'].md\:ltr\:inset-3\.5 {
    top: 0.35rem;
    right: 0.35rem;
    bottom: 0.35rem;
    left: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:-inset-1,[dir='ltr'].md\:ltr\:-inset-1 {
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem
  }

  [dir='ltr'] .md\:ltr\:-inset-2,[dir='ltr'].md\:ltr\:-inset-2 {
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-3,[dir='ltr'].md\:ltr\:-inset-3 {
    top: -0.3rem;
    right: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem
  }

  [dir='ltr'] .md\:ltr\:-inset-4,[dir='ltr'].md\:ltr\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-5,[dir='ltr'].md\:ltr\:-inset-5 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  [dir='ltr'] .md\:ltr\:-inset-6,[dir='ltr'].md\:ltr\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-7,[dir='ltr'].md\:ltr\:-inset-7 {
    top: -0.7rem;
    right: -0.7rem;
    bottom: -0.7rem;
    left: -0.7rem
  }

  [dir='ltr'] .md\:ltr\:-inset-8,[dir='ltr'].md\:ltr\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-9,[dir='ltr'].md\:ltr\:-inset-9 {
    top: -0.9rem;
    right: -0.9rem;
    bottom: -0.9rem;
    left: -0.9rem
  }

  [dir='ltr'] .md\:ltr\:-inset-10,[dir='ltr'].md\:ltr\:-inset-10 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  [dir='ltr'] .md\:ltr\:-inset-12,[dir='ltr'].md\:ltr\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-14,[dir='ltr'].md\:ltr\:-inset-14 {
    top: -1.4rem;
    right: -1.4rem;
    bottom: -1.4rem;
    left: -1.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-16,[dir='ltr'].md\:ltr\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-20,[dir='ltr'].md\:ltr\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-24,[dir='ltr'].md\:ltr\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-28,[dir='ltr'].md\:ltr\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-32,[dir='ltr'].md\:ltr\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-36,[dir='ltr'].md\:ltr\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-40,[dir='ltr'].md\:ltr\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-44,[dir='ltr'].md\:ltr\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-48,[dir='ltr'].md\:ltr\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-52,[dir='ltr'].md\:ltr\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-56,[dir='ltr'].md\:ltr\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-60,[dir='ltr'].md\:ltr\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-64,[dir='ltr'].md\:ltr\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-68,[dir='ltr'].md\:ltr\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-72,[dir='ltr'].md\:ltr\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-76,[dir='ltr'].md\:ltr\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-80,[dir='ltr'].md\:ltr\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-84,[dir='ltr'].md\:ltr\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-88,[dir='ltr'].md\:ltr\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-92,[dir='ltr'].md\:ltr\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-96,[dir='ltr'].md\:ltr\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-128,[dir='ltr'].md\:ltr\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-136,[dir='ltr'].md\:ltr\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-160,[dir='ltr'].md\:ltr\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  [dir='ltr'] .md\:ltr\:-inset-192,[dir='ltr'].md\:ltr\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-200,[dir='ltr'].md\:ltr\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  [dir='ltr'] .md\:ltr\:-inset-208,[dir='ltr'].md\:ltr\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-216,[dir='ltr'].md\:ltr\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-224,[dir='ltr'].md\:ltr\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-256,[dir='ltr'].md\:ltr\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-288,[dir='ltr'].md\:ltr\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-320,[dir='ltr'].md\:ltr\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='ltr'] .md\:ltr\:-inset-360,[dir='ltr'].md\:ltr\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  [dir='ltr'] .md\:ltr\:-inset-384,[dir='ltr'].md\:ltr\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-400,[dir='ltr'].md\:ltr\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  [dir='ltr'] .md\:ltr\:-inset-512,[dir='ltr'].md\:ltr\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-640,[dir='ltr'].md\:ltr\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='ltr'] .md\:ltr\:-inset-xs,[dir='ltr'].md\:ltr\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='ltr'] .md\:ltr\:-inset-sm,[dir='ltr'].md\:ltr\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  [dir='ltr'] .md\:ltr\:-inset-md,[dir='ltr'].md\:ltr\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='ltr'] .md\:ltr\:-inset-lg,[dir='ltr'].md\:ltr\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  [dir='ltr'] .md\:ltr\:-inset-xl,[dir='ltr'].md\:ltr\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  [dir='ltr'] .md\:ltr\:-inset-2xl,[dir='ltr'].md\:ltr\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  [dir='ltr'] .md\:ltr\:-inset-3xl,[dir='ltr'].md\:ltr\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  [dir='ltr'] .md\:ltr\:-inset-4xl,[dir='ltr'].md\:ltr\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  [dir='ltr'] .md\:ltr\:-inset-5xl,[dir='ltr'].md\:ltr\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  [dir='ltr'] .md\:ltr\:-inset-px,[dir='ltr'].md\:ltr\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  [dir='ltr'] .md\:ltr\:-inset-0\.5,[dir='ltr'].md\:ltr\:-inset-0\.5 {
    top: -0.05rem;
    right: -0.05rem;
    bottom: -0.05rem;
    left: -0.05rem
  }

  [dir='ltr'] .md\:ltr\:-inset-1\.5,[dir='ltr'].md\:ltr\:-inset-1\.5 {
    top: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    left: -0.15rem
  }

  [dir='ltr'] .md\:ltr\:-inset-2\.5,[dir='ltr'].md\:ltr\:-inset-2\.5 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  [dir='ltr'] .md\:ltr\:-inset-3\.5,[dir='ltr'].md\:ltr\:-inset-3\.5 {
    top: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    left: -0.35rem
  }

  [dir='ltr'] .md\:ltr\:inset-1\/2,[dir='ltr'].md\:ltr\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='ltr'] .md\:ltr\:inset-1\/3,[dir='ltr'].md\:ltr\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  [dir='ltr'] .md\:ltr\:inset-2\/3,[dir='ltr'].md\:ltr\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  [dir='ltr'] .md\:ltr\:inset-1\/4,[dir='ltr'].md\:ltr\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  [dir='ltr'] .md\:ltr\:inset-2\/4,[dir='ltr'].md\:ltr\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='ltr'] .md\:ltr\:inset-3\/4,[dir='ltr'].md\:ltr\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  [dir='ltr'] .md\:ltr\:inset-full,[dir='ltr'].md\:ltr\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  [dir='ltr'] .md\:ltr\:-inset-1\/2,[dir='ltr'].md\:ltr\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='ltr'] .md\:ltr\:-inset-1\/3,[dir='ltr'].md\:ltr\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  [dir='ltr'] .md\:ltr\:-inset-2\/3,[dir='ltr'].md\:ltr\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  [dir='ltr'] .md\:ltr\:-inset-1\/4,[dir='ltr'].md\:ltr\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  [dir='ltr'] .md\:ltr\:-inset-2\/4,[dir='ltr'].md\:ltr\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='ltr'] .md\:ltr\:-inset-3\/4,[dir='ltr'].md\:ltr\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  [dir='ltr'] .md\:ltr\:-inset-full,[dir='ltr'].md\:ltr\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  [dir='ltr'] .md\:ltr\:inset-y-0,[dir='ltr'].md\:ltr\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  [dir='ltr'] .md\:ltr\:inset-x-0,[dir='ltr'].md\:ltr\:inset-x-0 {
    right: 0;
    left: 0
  }

  [dir='ltr'] .md\:ltr\:inset-y-1,[dir='ltr'].md\:ltr\:inset-y-1 {
    top: 0.1rem;
    bottom: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-1,[dir='ltr'].md\:ltr\:inset-x-1 {
    right: 0.1rem;
    left: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-2,[dir='ltr'].md\:ltr\:inset-y-2 {
    top: 0.2rem;
    bottom: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-2,[dir='ltr'].md\:ltr\:inset-x-2 {
    right: 0.2rem;
    left: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-3,[dir='ltr'].md\:ltr\:inset-y-3 {
    top: 0.3rem;
    bottom: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-3,[dir='ltr'].md\:ltr\:inset-x-3 {
    right: 0.3rem;
    left: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-4,[dir='ltr'].md\:ltr\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-4,[dir='ltr'].md\:ltr\:inset-x-4 {
    right: 0.4rem;
    left: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-5,[dir='ltr'].md\:ltr\:inset-y-5 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-5,[dir='ltr'].md\:ltr\:inset-x-5 {
    right: 0.5rem;
    left: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-6,[dir='ltr'].md\:ltr\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-6,[dir='ltr'].md\:ltr\:inset-x-6 {
    right: 0.6rem;
    left: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-7,[dir='ltr'].md\:ltr\:inset-y-7 {
    top: 0.7rem;
    bottom: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-7,[dir='ltr'].md\:ltr\:inset-x-7 {
    right: 0.7rem;
    left: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-8,[dir='ltr'].md\:ltr\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-8,[dir='ltr'].md\:ltr\:inset-x-8 {
    right: 0.8rem;
    left: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-9,[dir='ltr'].md\:ltr\:inset-y-9 {
    top: 0.9rem;
    bottom: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-9,[dir='ltr'].md\:ltr\:inset-x-9 {
    right: 0.9rem;
    left: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-10,[dir='ltr'].md\:ltr\:inset-y-10 {
    top: 1.0rem;
    bottom: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-10,[dir='ltr'].md\:ltr\:inset-x-10 {
    right: 1.0rem;
    left: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-12,[dir='ltr'].md\:ltr\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-12,[dir='ltr'].md\:ltr\:inset-x-12 {
    right: 1.2rem;
    left: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-14,[dir='ltr'].md\:ltr\:inset-y-14 {
    top: 1.4rem;
    bottom: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-14,[dir='ltr'].md\:ltr\:inset-x-14 {
    right: 1.4rem;
    left: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-16,[dir='ltr'].md\:ltr\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-16,[dir='ltr'].md\:ltr\:inset-x-16 {
    right: 1.6rem;
    left: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-20,[dir='ltr'].md\:ltr\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-20,[dir='ltr'].md\:ltr\:inset-x-20 {
    right: 2rem;
    left: 2rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-24,[dir='ltr'].md\:ltr\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-24,[dir='ltr'].md\:ltr\:inset-x-24 {
    right: 2.4rem;
    left: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-28,[dir='ltr'].md\:ltr\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-28,[dir='ltr'].md\:ltr\:inset-x-28 {
    right: 2.8rem;
    left: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-32,[dir='ltr'].md\:ltr\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-32,[dir='ltr'].md\:ltr\:inset-x-32 {
    right: 3.2rem;
    left: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-36,[dir='ltr'].md\:ltr\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-36,[dir='ltr'].md\:ltr\:inset-x-36 {
    right: 3.6rem;
    left: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-40,[dir='ltr'].md\:ltr\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-40,[dir='ltr'].md\:ltr\:inset-x-40 {
    right: 4rem;
    left: 4rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-44,[dir='ltr'].md\:ltr\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-44,[dir='ltr'].md\:ltr\:inset-x-44 {
    right: 4.4rem;
    left: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-48,[dir='ltr'].md\:ltr\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-48,[dir='ltr'].md\:ltr\:inset-x-48 {
    right: 4.8rem;
    left: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-52,[dir='ltr'].md\:ltr\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-52,[dir='ltr'].md\:ltr\:inset-x-52 {
    right: 5.2rem;
    left: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-56,[dir='ltr'].md\:ltr\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-56,[dir='ltr'].md\:ltr\:inset-x-56 {
    right: 5.6rem;
    left: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-60,[dir='ltr'].md\:ltr\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-60,[dir='ltr'].md\:ltr\:inset-x-60 {
    right: 6rem;
    left: 6rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-64,[dir='ltr'].md\:ltr\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-64,[dir='ltr'].md\:ltr\:inset-x-64 {
    right: 6.4rem;
    left: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-68,[dir='ltr'].md\:ltr\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-68,[dir='ltr'].md\:ltr\:inset-x-68 {
    right: 6.8rem;
    left: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-72,[dir='ltr'].md\:ltr\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-72,[dir='ltr'].md\:ltr\:inset-x-72 {
    right: 7.2rem;
    left: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-76,[dir='ltr'].md\:ltr\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-76,[dir='ltr'].md\:ltr\:inset-x-76 {
    right: 7.6rem;
    left: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-80,[dir='ltr'].md\:ltr\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-80,[dir='ltr'].md\:ltr\:inset-x-80 {
    right: 8rem;
    left: 8rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-84,[dir='ltr'].md\:ltr\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-84,[dir='ltr'].md\:ltr\:inset-x-84 {
    right: 8.4rem;
    left: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-88,[dir='ltr'].md\:ltr\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-88,[dir='ltr'].md\:ltr\:inset-x-88 {
    right: 8.8rem;
    left: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-92,[dir='ltr'].md\:ltr\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-92,[dir='ltr'].md\:ltr\:inset-x-92 {
    right: 9.2rem;
    left: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-96,[dir='ltr'].md\:ltr\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-96,[dir='ltr'].md\:ltr\:inset-x-96 {
    right: 9.6rem;
    left: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-128,[dir='ltr'].md\:ltr\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-128,[dir='ltr'].md\:ltr\:inset-x-128 {
    right: 12.8rem;
    left: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-136,[dir='ltr'].md\:ltr\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-136,[dir='ltr'].md\:ltr\:inset-x-136 {
    right: 13.6rem;
    left: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-160,[dir='ltr'].md\:ltr\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-160,[dir='ltr'].md\:ltr\:inset-x-160 {
    right: 16rem;
    left: 16rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-192,[dir='ltr'].md\:ltr\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-192,[dir='ltr'].md\:ltr\:inset-x-192 {
    right: 19.2rem;
    left: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-200,[dir='ltr'].md\:ltr\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-200,[dir='ltr'].md\:ltr\:inset-x-200 {
    right: 20rem;
    left: 20rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-208,[dir='ltr'].md\:ltr\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-208,[dir='ltr'].md\:ltr\:inset-x-208 {
    right: 20.8rem;
    left: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-216,[dir='ltr'].md\:ltr\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-216,[dir='ltr'].md\:ltr\:inset-x-216 {
    right: 21.6rem;
    left: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-224,[dir='ltr'].md\:ltr\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-224,[dir='ltr'].md\:ltr\:inset-x-224 {
    right: 22.4rem;
    left: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-256,[dir='ltr'].md\:ltr\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-256,[dir='ltr'].md\:ltr\:inset-x-256 {
    right: 25.6rem;
    left: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-288,[dir='ltr'].md\:ltr\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-288,[dir='ltr'].md\:ltr\:inset-x-288 {
    right: 28.8rem;
    left: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-320,[dir='ltr'].md\:ltr\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-320,[dir='ltr'].md\:ltr\:inset-x-320 {
    right: 32rem;
    left: 32rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-360,[dir='ltr'].md\:ltr\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-360,[dir='ltr'].md\:ltr\:inset-x-360 {
    right: 36rem;
    left: 36rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-384,[dir='ltr'].md\:ltr\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-384,[dir='ltr'].md\:ltr\:inset-x-384 {
    right: 38.4rem;
    left: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-400,[dir='ltr'].md\:ltr\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-400,[dir='ltr'].md\:ltr\:inset-x-400 {
    right: 40rem;
    left: 40rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-512,[dir='ltr'].md\:ltr\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-512,[dir='ltr'].md\:ltr\:inset-x-512 {
    right: 51.2rem;
    left: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-640,[dir='ltr'].md\:ltr\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-640,[dir='ltr'].md\:ltr\:inset-x-640 {
    right: 64rem;
    left: 64rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-auto,[dir='ltr'].md\:ltr\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  [dir='ltr'] .md\:ltr\:inset-x-auto,[dir='ltr'].md\:ltr\:inset-x-auto {
    right: auto;
    left: auto
  }

  [dir='ltr'] .md\:ltr\:inset-y-xs,[dir='ltr'].md\:ltr\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-xs,[dir='ltr'].md\:ltr\:inset-x-xs {
    right: 32rem;
    left: 32rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-sm,[dir='ltr'].md\:ltr\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-sm,[dir='ltr'].md\:ltr\:inset-x-sm {
    right: 48rem;
    left: 48rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-md,[dir='ltr'].md\:ltr\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-md,[dir='ltr'].md\:ltr\:inset-x-md {
    right: 64rem;
    left: 64rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-lg,[dir='ltr'].md\:ltr\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-lg,[dir='ltr'].md\:ltr\:inset-x-lg {
    right: 80rem;
    left: 80rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-xl,[dir='ltr'].md\:ltr\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-xl,[dir='ltr'].md\:ltr\:inset-x-xl {
    right: 96rem;
    left: 96rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-2xl,[dir='ltr'].md\:ltr\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-2xl,[dir='ltr'].md\:ltr\:inset-x-2xl {
    right: 112rem;
    left: 112rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-3xl,[dir='ltr'].md\:ltr\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-3xl,[dir='ltr'].md\:ltr\:inset-x-3xl {
    right: 128rem;
    left: 128rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-4xl,[dir='ltr'].md\:ltr\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-4xl,[dir='ltr'].md\:ltr\:inset-x-4xl {
    right: 144rem;
    left: 144rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-5xl,[dir='ltr'].md\:ltr\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-5xl,[dir='ltr'].md\:ltr\:inset-x-5xl {
    right: 160rem;
    left: 160rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-px,[dir='ltr'].md\:ltr\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  [dir='ltr'] .md\:ltr\:inset-x-px,[dir='ltr'].md\:ltr\:inset-x-px {
    right: 1px;
    left: 1px
  }

  [dir='ltr'] .md\:ltr\:inset-y-0\.5,[dir='ltr'].md\:ltr\:inset-y-0\.5 {
    top: 0.05rem;
    bottom: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-0\.5,[dir='ltr'].md\:ltr\:inset-x-0\.5 {
    right: 0.05rem;
    left: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-1\.5,[dir='ltr'].md\:ltr\:inset-y-1\.5 {
    top: 0.15rem;
    bottom: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-1\.5,[dir='ltr'].md\:ltr\:inset-x-1\.5 {
    right: 0.15rem;
    left: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-2\.5,[dir='ltr'].md\:ltr\:inset-y-2\.5 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-2\.5,[dir='ltr'].md\:ltr\:inset-x-2\.5 {
    right: 0.25rem;
    left: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-3\.5,[dir='ltr'].md\:ltr\:inset-y-3\.5 {
    top: 0.35rem;
    bottom: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:inset-x-3\.5,[dir='ltr'].md\:ltr\:inset-x-3\.5 {
    right: 0.35rem;
    left: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-1,[dir='ltr'].md\:ltr\:-inset-y-1 {
    top: -0.1rem;
    bottom: -0.1rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-1,[dir='ltr'].md\:ltr\:-inset-x-1 {
    right: -0.1rem;
    left: -0.1rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-2,[dir='ltr'].md\:ltr\:-inset-y-2 {
    top: -0.2rem;
    bottom: -0.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-2,[dir='ltr'].md\:ltr\:-inset-x-2 {
    right: -0.2rem;
    left: -0.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-3,[dir='ltr'].md\:ltr\:-inset-y-3 {
    top: -0.3rem;
    bottom: -0.3rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-3,[dir='ltr'].md\:ltr\:-inset-x-3 {
    right: -0.3rem;
    left: -0.3rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-4,[dir='ltr'].md\:ltr\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-4,[dir='ltr'].md\:ltr\:-inset-x-4 {
    right: -0.4rem;
    left: -0.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-5,[dir='ltr'].md\:ltr\:-inset-y-5 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-5,[dir='ltr'].md\:ltr\:-inset-x-5 {
    right: -0.5rem;
    left: -0.5rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-6,[dir='ltr'].md\:ltr\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-6,[dir='ltr'].md\:ltr\:-inset-x-6 {
    right: -0.6rem;
    left: -0.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-7,[dir='ltr'].md\:ltr\:-inset-y-7 {
    top: -0.7rem;
    bottom: -0.7rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-7,[dir='ltr'].md\:ltr\:-inset-x-7 {
    right: -0.7rem;
    left: -0.7rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-8,[dir='ltr'].md\:ltr\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-8,[dir='ltr'].md\:ltr\:-inset-x-8 {
    right: -0.8rem;
    left: -0.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-9,[dir='ltr'].md\:ltr\:-inset-y-9 {
    top: -0.9rem;
    bottom: -0.9rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-9,[dir='ltr'].md\:ltr\:-inset-x-9 {
    right: -0.9rem;
    left: -0.9rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-10,[dir='ltr'].md\:ltr\:-inset-y-10 {
    top: -1rem;
    bottom: -1rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-10,[dir='ltr'].md\:ltr\:-inset-x-10 {
    right: -1rem;
    left: -1rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-12,[dir='ltr'].md\:ltr\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-12,[dir='ltr'].md\:ltr\:-inset-x-12 {
    right: -1.2rem;
    left: -1.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-14,[dir='ltr'].md\:ltr\:-inset-y-14 {
    top: -1.4rem;
    bottom: -1.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-14,[dir='ltr'].md\:ltr\:-inset-x-14 {
    right: -1.4rem;
    left: -1.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-16,[dir='ltr'].md\:ltr\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-16,[dir='ltr'].md\:ltr\:-inset-x-16 {
    right: -1.6rem;
    left: -1.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-20,[dir='ltr'].md\:ltr\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-20,[dir='ltr'].md\:ltr\:-inset-x-20 {
    right: -2rem;
    left: -2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-24,[dir='ltr'].md\:ltr\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-24,[dir='ltr'].md\:ltr\:-inset-x-24 {
    right: -2.4rem;
    left: -2.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-28,[dir='ltr'].md\:ltr\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-28,[dir='ltr'].md\:ltr\:-inset-x-28 {
    right: -2.8rem;
    left: -2.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-32,[dir='ltr'].md\:ltr\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-32,[dir='ltr'].md\:ltr\:-inset-x-32 {
    right: -3.2rem;
    left: -3.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-36,[dir='ltr'].md\:ltr\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-36,[dir='ltr'].md\:ltr\:-inset-x-36 {
    right: -3.6rem;
    left: -3.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-40,[dir='ltr'].md\:ltr\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-40,[dir='ltr'].md\:ltr\:-inset-x-40 {
    right: -4rem;
    left: -4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-44,[dir='ltr'].md\:ltr\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-44,[dir='ltr'].md\:ltr\:-inset-x-44 {
    right: -4.4rem;
    left: -4.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-48,[dir='ltr'].md\:ltr\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-48,[dir='ltr'].md\:ltr\:-inset-x-48 {
    right: -4.8rem;
    left: -4.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-52,[dir='ltr'].md\:ltr\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-52,[dir='ltr'].md\:ltr\:-inset-x-52 {
    right: -5.2rem;
    left: -5.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-56,[dir='ltr'].md\:ltr\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-56,[dir='ltr'].md\:ltr\:-inset-x-56 {
    right: -5.6rem;
    left: -5.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-60,[dir='ltr'].md\:ltr\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-60,[dir='ltr'].md\:ltr\:-inset-x-60 {
    right: -6rem;
    left: -6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-64,[dir='ltr'].md\:ltr\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-64,[dir='ltr'].md\:ltr\:-inset-x-64 {
    right: -6.4rem;
    left: -6.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-68,[dir='ltr'].md\:ltr\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-68,[dir='ltr'].md\:ltr\:-inset-x-68 {
    right: -6.8rem;
    left: -6.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-72,[dir='ltr'].md\:ltr\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-72,[dir='ltr'].md\:ltr\:-inset-x-72 {
    right: -7.2rem;
    left: -7.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-76,[dir='ltr'].md\:ltr\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-76,[dir='ltr'].md\:ltr\:-inset-x-76 {
    right: -7.6rem;
    left: -7.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-80,[dir='ltr'].md\:ltr\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-80,[dir='ltr'].md\:ltr\:-inset-x-80 {
    right: -8rem;
    left: -8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-84,[dir='ltr'].md\:ltr\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-84,[dir='ltr'].md\:ltr\:-inset-x-84 {
    right: -8.4rem;
    left: -8.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-88,[dir='ltr'].md\:ltr\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-88,[dir='ltr'].md\:ltr\:-inset-x-88 {
    right: -8.8rem;
    left: -8.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-92,[dir='ltr'].md\:ltr\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-92,[dir='ltr'].md\:ltr\:-inset-x-92 {
    right: -9.2rem;
    left: -9.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-96,[dir='ltr'].md\:ltr\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-96,[dir='ltr'].md\:ltr\:-inset-x-96 {
    right: -9.6rem;
    left: -9.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-128,[dir='ltr'].md\:ltr\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-128,[dir='ltr'].md\:ltr\:-inset-x-128 {
    right: -12.8rem;
    left: -12.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-136,[dir='ltr'].md\:ltr\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-136,[dir='ltr'].md\:ltr\:-inset-x-136 {
    right: -13.6rem;
    left: -13.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-160,[dir='ltr'].md\:ltr\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-160,[dir='ltr'].md\:ltr\:-inset-x-160 {
    right: -16rem;
    left: -16rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-192,[dir='ltr'].md\:ltr\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-192,[dir='ltr'].md\:ltr\:-inset-x-192 {
    right: -19.2rem;
    left: -19.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-200,[dir='ltr'].md\:ltr\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-200,[dir='ltr'].md\:ltr\:-inset-x-200 {
    right: -20rem;
    left: -20rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-208,[dir='ltr'].md\:ltr\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-208,[dir='ltr'].md\:ltr\:-inset-x-208 {
    right: -20.8rem;
    left: -20.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-216,[dir='ltr'].md\:ltr\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-216,[dir='ltr'].md\:ltr\:-inset-x-216 {
    right: -21.6rem;
    left: -21.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-224,[dir='ltr'].md\:ltr\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-224,[dir='ltr'].md\:ltr\:-inset-x-224 {
    right: -22.4rem;
    left: -22.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-256,[dir='ltr'].md\:ltr\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-256,[dir='ltr'].md\:ltr\:-inset-x-256 {
    right: -25.6rem;
    left: -25.6rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-288,[dir='ltr'].md\:ltr\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-288,[dir='ltr'].md\:ltr\:-inset-x-288 {
    right: -28.8rem;
    left: -28.8rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-320,[dir='ltr'].md\:ltr\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-320,[dir='ltr'].md\:ltr\:-inset-x-320 {
    right: -32rem;
    left: -32rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-360,[dir='ltr'].md\:ltr\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-360,[dir='ltr'].md\:ltr\:-inset-x-360 {
    right: -36rem;
    left: -36rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-384,[dir='ltr'].md\:ltr\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-384,[dir='ltr'].md\:ltr\:-inset-x-384 {
    right: -38.4rem;
    left: -38.4rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-400,[dir='ltr'].md\:ltr\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-400,[dir='ltr'].md\:ltr\:-inset-x-400 {
    right: -40rem;
    left: -40rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-512,[dir='ltr'].md\:ltr\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-512,[dir='ltr'].md\:ltr\:-inset-x-512 {
    right: -51.2rem;
    left: -51.2rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-640,[dir='ltr'].md\:ltr\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-640,[dir='ltr'].md\:ltr\:-inset-x-640 {
    right: -64rem;
    left: -64rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-xs,[dir='ltr'].md\:ltr\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-xs,[dir='ltr'].md\:ltr\:-inset-x-xs {
    right: -32rem;
    left: -32rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-sm,[dir='ltr'].md\:ltr\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-sm,[dir='ltr'].md\:ltr\:-inset-x-sm {
    right: -48rem;
    left: -48rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-md,[dir='ltr'].md\:ltr\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-md,[dir='ltr'].md\:ltr\:-inset-x-md {
    right: -64rem;
    left: -64rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-lg,[dir='ltr'].md\:ltr\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-lg,[dir='ltr'].md\:ltr\:-inset-x-lg {
    right: -80rem;
    left: -80rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-xl,[dir='ltr'].md\:ltr\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-xl,[dir='ltr'].md\:ltr\:-inset-x-xl {
    right: -96rem;
    left: -96rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-2xl,[dir='ltr'].md\:ltr\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-2xl,[dir='ltr'].md\:ltr\:-inset-x-2xl {
    right: -112rem;
    left: -112rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-3xl,[dir='ltr'].md\:ltr\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-3xl,[dir='ltr'].md\:ltr\:-inset-x-3xl {
    right: -128rem;
    left: -128rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-4xl,[dir='ltr'].md\:ltr\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-4xl,[dir='ltr'].md\:ltr\:-inset-x-4xl {
    right: -144rem;
    left: -144rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-5xl,[dir='ltr'].md\:ltr\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-5xl,[dir='ltr'].md\:ltr\:-inset-x-5xl {
    right: -160rem;
    left: -160rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-px,[dir='ltr'].md\:ltr\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  [dir='ltr'] .md\:ltr\:-inset-x-px,[dir='ltr'].md\:ltr\:-inset-x-px {
    right: -1px;
    left: -1px
  }

  [dir='ltr'] .md\:ltr\:-inset-y-0\.5,[dir='ltr'].md\:ltr\:-inset-y-0\.5 {
    top: -0.05rem;
    bottom: -0.05rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-0\.5,[dir='ltr'].md\:ltr\:-inset-x-0\.5 {
    right: -0.05rem;
    left: -0.05rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-1\.5,[dir='ltr'].md\:ltr\:-inset-y-1\.5 {
    top: -0.15rem;
    bottom: -0.15rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-1\.5,[dir='ltr'].md\:ltr\:-inset-x-1\.5 {
    right: -0.15rem;
    left: -0.15rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-2\.5,[dir='ltr'].md\:ltr\:-inset-y-2\.5 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-2\.5,[dir='ltr'].md\:ltr\:-inset-x-2\.5 {
    right: -0.25rem;
    left: -0.25rem
  }

  [dir='ltr'] .md\:ltr\:-inset-y-3\.5,[dir='ltr'].md\:ltr\:-inset-y-3\.5 {
    top: -0.35rem;
    bottom: -0.35rem
  }

  [dir='ltr'] .md\:ltr\:-inset-x-3\.5,[dir='ltr'].md\:ltr\:-inset-x-3\.5 {
    right: -0.35rem;
    left: -0.35rem
  }

  [dir='ltr'] .md\:ltr\:inset-y-1\/2,[dir='ltr'].md\:ltr\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  [dir='ltr'] .md\:ltr\:inset-x-1\/2,[dir='ltr'].md\:ltr\:inset-x-1\/2 {
    right: 50%;
    left: 50%
  }

  [dir='ltr'] .md\:ltr\:inset-y-1\/3,[dir='ltr'].md\:ltr\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  [dir='ltr'] .md\:ltr\:inset-x-1\/3,[dir='ltr'].md\:ltr\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%
  }

  [dir='ltr'] .md\:ltr\:inset-y-2\/3,[dir='ltr'].md\:ltr\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  [dir='ltr'] .md\:ltr\:inset-x-2\/3,[dir='ltr'].md\:ltr\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%
  }

  [dir='ltr'] .md\:ltr\:inset-y-1\/4,[dir='ltr'].md\:ltr\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  [dir='ltr'] .md\:ltr\:inset-x-1\/4,[dir='ltr'].md\:ltr\:inset-x-1\/4 {
    right: 25%;
    left: 25%
  }

  [dir='ltr'] .md\:ltr\:inset-y-2\/4,[dir='ltr'].md\:ltr\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  [dir='ltr'] .md\:ltr\:inset-x-2\/4,[dir='ltr'].md\:ltr\:inset-x-2\/4 {
    right: 50%;
    left: 50%
  }

  [dir='ltr'] .md\:ltr\:inset-y-3\/4,[dir='ltr'].md\:ltr\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  [dir='ltr'] .md\:ltr\:inset-x-3\/4,[dir='ltr'].md\:ltr\:inset-x-3\/4 {
    right: 75%;
    left: 75%
  }

  [dir='ltr'] .md\:ltr\:inset-y-full,[dir='ltr'].md\:ltr\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  [dir='ltr'] .md\:ltr\:inset-x-full,[dir='ltr'].md\:ltr\:inset-x-full {
    right: 100%;
    left: 100%
  }

  [dir='ltr'] .md\:ltr\:-inset-y-1\/2,[dir='ltr'].md\:ltr\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  [dir='ltr'] .md\:ltr\:-inset-x-1\/2,[dir='ltr'].md\:ltr\:-inset-x-1\/2 {
    right: -50%;
    left: -50%
  }

  [dir='ltr'] .md\:ltr\:-inset-y-1\/3,[dir='ltr'].md\:ltr\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  [dir='ltr'] .md\:ltr\:-inset-x-1\/3,[dir='ltr'].md\:ltr\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%
  }

  [dir='ltr'] .md\:ltr\:-inset-y-2\/3,[dir='ltr'].md\:ltr\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  [dir='ltr'] .md\:ltr\:-inset-x-2\/3,[dir='ltr'].md\:ltr\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%
  }

  [dir='ltr'] .md\:ltr\:-inset-y-1\/4,[dir='ltr'].md\:ltr\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  [dir='ltr'] .md\:ltr\:-inset-x-1\/4,[dir='ltr'].md\:ltr\:-inset-x-1\/4 {
    right: -25%;
    left: -25%
  }

  [dir='ltr'] .md\:ltr\:-inset-y-2\/4,[dir='ltr'].md\:ltr\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  [dir='ltr'] .md\:ltr\:-inset-x-2\/4,[dir='ltr'].md\:ltr\:-inset-x-2\/4 {
    right: -50%;
    left: -50%
  }

  [dir='ltr'] .md\:ltr\:-inset-y-3\/4,[dir='ltr'].md\:ltr\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  [dir='ltr'] .md\:ltr\:-inset-x-3\/4,[dir='ltr'].md\:ltr\:-inset-x-3\/4 {
    right: -75%;
    left: -75%
  }

  [dir='ltr'] .md\:ltr\:-inset-y-full,[dir='ltr'].md\:ltr\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  [dir='ltr'] .md\:ltr\:-inset-x-full,[dir='ltr'].md\:ltr\:-inset-x-full {
    right: -100%;
    left: -100%
  }

  [dir='ltr'] .md\:ltr\:top-0,[dir='ltr'].md\:ltr\:top-0 {
    top: 0
  }

  [dir='ltr'] .md\:ltr\:right-0,[dir='ltr'].md\:ltr\:right-0 {
    right: 0
  }

  [dir='ltr'] .md\:ltr\:bottom-0,[dir='ltr'].md\:ltr\:bottom-0 {
    bottom: 0
  }

  [dir='ltr'] .md\:ltr\:left-0,[dir='ltr'].md\:ltr\:left-0 {
    left: 0
  }

  [dir='ltr'] .md\:ltr\:top-1,[dir='ltr'].md\:ltr\:top-1 {
    top: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:right-1,[dir='ltr'].md\:ltr\:right-1 {
    right: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:bottom-1,[dir='ltr'].md\:ltr\:bottom-1 {
    bottom: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:left-1,[dir='ltr'].md\:ltr\:left-1 {
    left: 0.1rem
  }

  [dir='ltr'] .md\:ltr\:top-2,[dir='ltr'].md\:ltr\:top-2 {
    top: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:right-2,[dir='ltr'].md\:ltr\:right-2 {
    right: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:bottom-2,[dir='ltr'].md\:ltr\:bottom-2 {
    bottom: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:left-2,[dir='ltr'].md\:ltr\:left-2 {
    left: 0.2rem
  }

  [dir='ltr'] .md\:ltr\:top-3,[dir='ltr'].md\:ltr\:top-3 {
    top: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:right-3,[dir='ltr'].md\:ltr\:right-3 {
    right: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:bottom-3,[dir='ltr'].md\:ltr\:bottom-3 {
    bottom: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:left-3,[dir='ltr'].md\:ltr\:left-3 {
    left: 0.3rem
  }

  [dir='ltr'] .md\:ltr\:top-4,[dir='ltr'].md\:ltr\:top-4 {
    top: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:right-4,[dir='ltr'].md\:ltr\:right-4 {
    right: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:bottom-4,[dir='ltr'].md\:ltr\:bottom-4 {
    bottom: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:left-4,[dir='ltr'].md\:ltr\:left-4 {
    left: 0.4rem
  }

  [dir='ltr'] .md\:ltr\:top-5,[dir='ltr'].md\:ltr\:top-5 {
    top: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:right-5,[dir='ltr'].md\:ltr\:right-5 {
    right: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:bottom-5,[dir='ltr'].md\:ltr\:bottom-5 {
    bottom: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:left-5,[dir='ltr'].md\:ltr\:left-5 {
    left: 0.5rem
  }

  [dir='ltr'] .md\:ltr\:top-6,[dir='ltr'].md\:ltr\:top-6 {
    top: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:right-6,[dir='ltr'].md\:ltr\:right-6 {
    right: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:bottom-6,[dir='ltr'].md\:ltr\:bottom-6 {
    bottom: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:left-6,[dir='ltr'].md\:ltr\:left-6 {
    left: 0.6rem
  }

  [dir='ltr'] .md\:ltr\:top-7,[dir='ltr'].md\:ltr\:top-7 {
    top: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:right-7,[dir='ltr'].md\:ltr\:right-7 {
    right: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:bottom-7,[dir='ltr'].md\:ltr\:bottom-7 {
    bottom: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:left-7,[dir='ltr'].md\:ltr\:left-7 {
    left: 0.7rem
  }

  [dir='ltr'] .md\:ltr\:top-8,[dir='ltr'].md\:ltr\:top-8 {
    top: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:right-8,[dir='ltr'].md\:ltr\:right-8 {
    right: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:bottom-8,[dir='ltr'].md\:ltr\:bottom-8 {
    bottom: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:left-8,[dir='ltr'].md\:ltr\:left-8 {
    left: 0.8rem
  }

  [dir='ltr'] .md\:ltr\:top-9,[dir='ltr'].md\:ltr\:top-9 {
    top: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:right-9,[dir='ltr'].md\:ltr\:right-9 {
    right: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:bottom-9,[dir='ltr'].md\:ltr\:bottom-9 {
    bottom: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:left-9,[dir='ltr'].md\:ltr\:left-9 {
    left: 0.9rem
  }

  [dir='ltr'] .md\:ltr\:top-10,[dir='ltr'].md\:ltr\:top-10 {
    top: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:right-10,[dir='ltr'].md\:ltr\:right-10 {
    right: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:bottom-10,[dir='ltr'].md\:ltr\:bottom-10 {
    bottom: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:left-10,[dir='ltr'].md\:ltr\:left-10 {
    left: 1.0rem
  }

  [dir='ltr'] .md\:ltr\:top-12,[dir='ltr'].md\:ltr\:top-12 {
    top: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:right-12,[dir='ltr'].md\:ltr\:right-12 {
    right: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:bottom-12,[dir='ltr'].md\:ltr\:bottom-12 {
    bottom: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:left-12,[dir='ltr'].md\:ltr\:left-12 {
    left: 1.2rem
  }

  [dir='ltr'] .md\:ltr\:top-14,[dir='ltr'].md\:ltr\:top-14 {
    top: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:right-14,[dir='ltr'].md\:ltr\:right-14 {
    right: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:bottom-14,[dir='ltr'].md\:ltr\:bottom-14 {
    bottom: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:left-14,[dir='ltr'].md\:ltr\:left-14 {
    left: 1.4rem
  }

  [dir='ltr'] .md\:ltr\:top-16,[dir='ltr'].md\:ltr\:top-16 {
    top: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:right-16,[dir='ltr'].md\:ltr\:right-16 {
    right: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:bottom-16,[dir='ltr'].md\:ltr\:bottom-16 {
    bottom: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:left-16,[dir='ltr'].md\:ltr\:left-16 {
    left: 1.6rem
  }

  [dir='ltr'] .md\:ltr\:top-20,[dir='ltr'].md\:ltr\:top-20 {
    top: 2rem
  }

  [dir='ltr'] .md\:ltr\:right-20,[dir='ltr'].md\:ltr\:right-20 {
    right: 2rem
  }

  [dir='ltr'] .md\:ltr\:bottom-20,[dir='ltr'].md\:ltr\:bottom-20 {
    bottom: 2rem
  }

  [dir='ltr'] .md\:ltr\:left-20,[dir='ltr'].md\:ltr\:left-20 {
    left: 2rem
  }

  [dir='ltr'] .md\:ltr\:top-24,[dir='ltr'].md\:ltr\:top-24 {
    top: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:right-24,[dir='ltr'].md\:ltr\:right-24 {
    right: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:bottom-24,[dir='ltr'].md\:ltr\:bottom-24 {
    bottom: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:left-24,[dir='ltr'].md\:ltr\:left-24 {
    left: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:top-28,[dir='ltr'].md\:ltr\:top-28 {
    top: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:right-28,[dir='ltr'].md\:ltr\:right-28 {
    right: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:bottom-28,[dir='ltr'].md\:ltr\:bottom-28 {
    bottom: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:left-28,[dir='ltr'].md\:ltr\:left-28 {
    left: 2.8rem
  }

  [dir='ltr'] .md\:ltr\:top-32,[dir='ltr'].md\:ltr\:top-32 {
    top: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:right-32,[dir='ltr'].md\:ltr\:right-32 {
    right: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:bottom-32,[dir='ltr'].md\:ltr\:bottom-32 {
    bottom: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:left-32,[dir='ltr'].md\:ltr\:left-32 {
    left: 3.2rem
  }

  [dir='ltr'] .md\:ltr\:top-36,[dir='ltr'].md\:ltr\:top-36 {
    top: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:right-36,[dir='ltr'].md\:ltr\:right-36 {
    right: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:bottom-36,[dir='ltr'].md\:ltr\:bottom-36 {
    bottom: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:left-36,[dir='ltr'].md\:ltr\:left-36 {
    left: 3.6rem
  }

  [dir='ltr'] .md\:ltr\:top-40,[dir='ltr'].md\:ltr\:top-40 {
    top: 4rem
  }

  [dir='ltr'] .md\:ltr\:right-40,[dir='ltr'].md\:ltr\:right-40 {
    right: 4rem
  }

  [dir='ltr'] .md\:ltr\:bottom-40,[dir='ltr'].md\:ltr\:bottom-40 {
    bottom: 4rem
  }

  [dir='ltr'] .md\:ltr\:left-40,[dir='ltr'].md\:ltr\:left-40 {
    left: 4rem
  }

  [dir='ltr'] .md\:ltr\:top-44,[dir='ltr'].md\:ltr\:top-44 {
    top: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:right-44,[dir='ltr'].md\:ltr\:right-44 {
    right: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:bottom-44,[dir='ltr'].md\:ltr\:bottom-44 {
    bottom: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:left-44,[dir='ltr'].md\:ltr\:left-44 {
    left: 4.4rem
  }

  [dir='ltr'] .md\:ltr\:top-48,[dir='ltr'].md\:ltr\:top-48 {
    top: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:right-48,[dir='ltr'].md\:ltr\:right-48 {
    right: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:bottom-48,[dir='ltr'].md\:ltr\:bottom-48 {
    bottom: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:left-48,[dir='ltr'].md\:ltr\:left-48 {
    left: 4.8rem
  }

  [dir='ltr'] .md\:ltr\:top-52,[dir='ltr'].md\:ltr\:top-52 {
    top: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:right-52,[dir='ltr'].md\:ltr\:right-52 {
    right: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:bottom-52,[dir='ltr'].md\:ltr\:bottom-52 {
    bottom: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:left-52,[dir='ltr'].md\:ltr\:left-52 {
    left: 5.2rem
  }

  [dir='ltr'] .md\:ltr\:top-56,[dir='ltr'].md\:ltr\:top-56 {
    top: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:right-56,[dir='ltr'].md\:ltr\:right-56 {
    right: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:bottom-56,[dir='ltr'].md\:ltr\:bottom-56 {
    bottom: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:left-56,[dir='ltr'].md\:ltr\:left-56 {
    left: 5.6rem
  }

  [dir='ltr'] .md\:ltr\:top-60,[dir='ltr'].md\:ltr\:top-60 {
    top: 6rem
  }

  [dir='ltr'] .md\:ltr\:right-60,[dir='ltr'].md\:ltr\:right-60 {
    right: 6rem
  }

  [dir='ltr'] .md\:ltr\:bottom-60,[dir='ltr'].md\:ltr\:bottom-60 {
    bottom: 6rem
  }

  [dir='ltr'] .md\:ltr\:left-60,[dir='ltr'].md\:ltr\:left-60 {
    left: 6rem
  }

  [dir='ltr'] .md\:ltr\:top-64,[dir='ltr'].md\:ltr\:top-64 {
    top: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:right-64,[dir='ltr'].md\:ltr\:right-64 {
    right: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:bottom-64,[dir='ltr'].md\:ltr\:bottom-64 {
    bottom: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:left-64,[dir='ltr'].md\:ltr\:left-64 {
    left: 6.4rem
  }

  [dir='ltr'] .md\:ltr\:top-68,[dir='ltr'].md\:ltr\:top-68 {
    top: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:right-68,[dir='ltr'].md\:ltr\:right-68 {
    right: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:bottom-68,[dir='ltr'].md\:ltr\:bottom-68 {
    bottom: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:left-68,[dir='ltr'].md\:ltr\:left-68 {
    left: 6.8rem
  }

  [dir='ltr'] .md\:ltr\:top-72,[dir='ltr'].md\:ltr\:top-72 {
    top: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:right-72,[dir='ltr'].md\:ltr\:right-72 {
    right: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:bottom-72,[dir='ltr'].md\:ltr\:bottom-72 {
    bottom: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:left-72,[dir='ltr'].md\:ltr\:left-72 {
    left: 7.2rem
  }

  [dir='ltr'] .md\:ltr\:top-76,[dir='ltr'].md\:ltr\:top-76 {
    top: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:right-76,[dir='ltr'].md\:ltr\:right-76 {
    right: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:bottom-76,[dir='ltr'].md\:ltr\:bottom-76 {
    bottom: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:left-76,[dir='ltr'].md\:ltr\:left-76 {
    left: 7.6rem
  }

  [dir='ltr'] .md\:ltr\:top-80,[dir='ltr'].md\:ltr\:top-80 {
    top: 8rem
  }

  [dir='ltr'] .md\:ltr\:right-80,[dir='ltr'].md\:ltr\:right-80 {
    right: 8rem
  }

  [dir='ltr'] .md\:ltr\:bottom-80,[dir='ltr'].md\:ltr\:bottom-80 {
    bottom: 8rem
  }

  [dir='ltr'] .md\:ltr\:left-80,[dir='ltr'].md\:ltr\:left-80 {
    left: 8rem
  }

  [dir='ltr'] .md\:ltr\:top-84,[dir='ltr'].md\:ltr\:top-84 {
    top: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:right-84,[dir='ltr'].md\:ltr\:right-84 {
    right: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:bottom-84,[dir='ltr'].md\:ltr\:bottom-84 {
    bottom: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:left-84,[dir='ltr'].md\:ltr\:left-84 {
    left: 8.4rem
  }

  [dir='ltr'] .md\:ltr\:top-88,[dir='ltr'].md\:ltr\:top-88 {
    top: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:right-88,[dir='ltr'].md\:ltr\:right-88 {
    right: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:bottom-88,[dir='ltr'].md\:ltr\:bottom-88 {
    bottom: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:left-88,[dir='ltr'].md\:ltr\:left-88 {
    left: 8.8rem
  }

  [dir='ltr'] .md\:ltr\:top-92,[dir='ltr'].md\:ltr\:top-92 {
    top: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:right-92,[dir='ltr'].md\:ltr\:right-92 {
    right: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:bottom-92,[dir='ltr'].md\:ltr\:bottom-92 {
    bottom: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:left-92,[dir='ltr'].md\:ltr\:left-92 {
    left: 9.2rem
  }

  [dir='ltr'] .md\:ltr\:top-96,[dir='ltr'].md\:ltr\:top-96 {
    top: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:right-96,[dir='ltr'].md\:ltr\:right-96 {
    right: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:bottom-96,[dir='ltr'].md\:ltr\:bottom-96 {
    bottom: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:left-96,[dir='ltr'].md\:ltr\:left-96 {
    left: 9.6rem
  }

  [dir='ltr'] .md\:ltr\:top-128,[dir='ltr'].md\:ltr\:top-128 {
    top: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:right-128,[dir='ltr'].md\:ltr\:right-128 {
    right: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:bottom-128,[dir='ltr'].md\:ltr\:bottom-128 {
    bottom: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:left-128,[dir='ltr'].md\:ltr\:left-128 {
    left: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:top-136,[dir='ltr'].md\:ltr\:top-136 {
    top: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:right-136,[dir='ltr'].md\:ltr\:right-136 {
    right: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:bottom-136,[dir='ltr'].md\:ltr\:bottom-136 {
    bottom: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:left-136,[dir='ltr'].md\:ltr\:left-136 {
    left: 13.6rem
  }

  [dir='ltr'] .md\:ltr\:top-160,[dir='ltr'].md\:ltr\:top-160 {
    top: 16rem
  }

  [dir='ltr'] .md\:ltr\:right-160,[dir='ltr'].md\:ltr\:right-160 {
    right: 16rem
  }

  [dir='ltr'] .md\:ltr\:bottom-160,[dir='ltr'].md\:ltr\:bottom-160 {
    bottom: 16rem
  }

  [dir='ltr'] .md\:ltr\:left-160,[dir='ltr'].md\:ltr\:left-160 {
    left: 16rem
  }

  [dir='ltr'] .md\:ltr\:top-192,[dir='ltr'].md\:ltr\:top-192 {
    top: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:right-192,[dir='ltr'].md\:ltr\:right-192 {
    right: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:bottom-192,[dir='ltr'].md\:ltr\:bottom-192 {
    bottom: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:left-192,[dir='ltr'].md\:ltr\:left-192 {
    left: 19.2rem
  }

  [dir='ltr'] .md\:ltr\:top-200,[dir='ltr'].md\:ltr\:top-200 {
    top: 20rem
  }

  [dir='ltr'] .md\:ltr\:right-200,[dir='ltr'].md\:ltr\:right-200 {
    right: 20rem
  }

  [dir='ltr'] .md\:ltr\:bottom-200,[dir='ltr'].md\:ltr\:bottom-200 {
    bottom: 20rem
  }

  [dir='ltr'] .md\:ltr\:left-200,[dir='ltr'].md\:ltr\:left-200 {
    left: 20rem
  }

  [dir='ltr'] .md\:ltr\:top-208,[dir='ltr'].md\:ltr\:top-208 {
    top: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:right-208,[dir='ltr'].md\:ltr\:right-208 {
    right: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:bottom-208,[dir='ltr'].md\:ltr\:bottom-208 {
    bottom: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:left-208,[dir='ltr'].md\:ltr\:left-208 {
    left: 20.8rem
  }

  [dir='ltr'] .md\:ltr\:top-216,[dir='ltr'].md\:ltr\:top-216 {
    top: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:right-216,[dir='ltr'].md\:ltr\:right-216 {
    right: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:bottom-216,[dir='ltr'].md\:ltr\:bottom-216 {
    bottom: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:left-216,[dir='ltr'].md\:ltr\:left-216 {
    left: 21.6rem
  }

  [dir='ltr'] .md\:ltr\:top-224,[dir='ltr'].md\:ltr\:top-224 {
    top: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:right-224,[dir='ltr'].md\:ltr\:right-224 {
    right: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:bottom-224,[dir='ltr'].md\:ltr\:bottom-224 {
    bottom: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:left-224,[dir='ltr'].md\:ltr\:left-224 {
    left: 22.4rem
  }

  [dir='ltr'] .md\:ltr\:top-256,[dir='ltr'].md\:ltr\:top-256 {
    top: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:right-256,[dir='ltr'].md\:ltr\:right-256 {
    right: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:bottom-256,[dir='ltr'].md\:ltr\:bottom-256 {
    bottom: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:left-256,[dir='ltr'].md\:ltr\:left-256 {
    left: 25.6rem
  }

  [dir='ltr'] .md\:ltr\:top-288,[dir='ltr'].md\:ltr\:top-288 {
    top: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:right-288,[dir='ltr'].md\:ltr\:right-288 {
    right: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:bottom-288,[dir='ltr'].md\:ltr\:bottom-288 {
    bottom: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:left-288,[dir='ltr'].md\:ltr\:left-288 {
    left: 28.8rem
  }

  [dir='ltr'] .md\:ltr\:top-320,[dir='ltr'].md\:ltr\:top-320 {
    top: 32rem
  }

  [dir='ltr'] .md\:ltr\:right-320,[dir='ltr'].md\:ltr\:right-320 {
    right: 32rem
  }

  [dir='ltr'] .md\:ltr\:bottom-320,[dir='ltr'].md\:ltr\:bottom-320 {
    bottom: 32rem
  }

  [dir='ltr'] .md\:ltr\:left-320,[dir='ltr'].md\:ltr\:left-320 {
    left: 32rem
  }

  [dir='ltr'] .md\:ltr\:top-360,[dir='ltr'].md\:ltr\:top-360 {
    top: 36rem
  }

  [dir='ltr'] .md\:ltr\:right-360,[dir='ltr'].md\:ltr\:right-360 {
    right: 36rem
  }

  [dir='ltr'] .md\:ltr\:bottom-360,[dir='ltr'].md\:ltr\:bottom-360 {
    bottom: 36rem
  }

  [dir='ltr'] .md\:ltr\:left-360,[dir='ltr'].md\:ltr\:left-360 {
    left: 36rem
  }

  [dir='ltr'] .md\:ltr\:top-384,[dir='ltr'].md\:ltr\:top-384 {
    top: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:right-384,[dir='ltr'].md\:ltr\:right-384 {
    right: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:bottom-384,[dir='ltr'].md\:ltr\:bottom-384 {
    bottom: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:left-384,[dir='ltr'].md\:ltr\:left-384 {
    left: 38.4rem
  }

  [dir='ltr'] .md\:ltr\:top-400,[dir='ltr'].md\:ltr\:top-400 {
    top: 40rem
  }

  [dir='ltr'] .md\:ltr\:right-400,[dir='ltr'].md\:ltr\:right-400 {
    right: 40rem
  }

  [dir='ltr'] .md\:ltr\:bottom-400,[dir='ltr'].md\:ltr\:bottom-400 {
    bottom: 40rem
  }

  [dir='ltr'] .md\:ltr\:left-400,[dir='ltr'].md\:ltr\:left-400 {
    left: 40rem
  }

  [dir='ltr'] .md\:ltr\:top-512,[dir='ltr'].md\:ltr\:top-512 {
    top: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:right-512,[dir='ltr'].md\:ltr\:right-512 {
    right: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:bottom-512,[dir='ltr'].md\:ltr\:bottom-512 {
    bottom: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:left-512,[dir='ltr'].md\:ltr\:left-512 {
    left: 51.2rem
  }

  [dir='ltr'] .md\:ltr\:top-640,[dir='ltr'].md\:ltr\:top-640 {
    top: 64rem
  }

  [dir='ltr'] .md\:ltr\:right-640,[dir='ltr'].md\:ltr\:right-640 {
    right: 64rem
  }

  [dir='ltr'] .md\:ltr\:bottom-640,[dir='ltr'].md\:ltr\:bottom-640 {
    bottom: 64rem
  }

  [dir='ltr'] .md\:ltr\:left-640,[dir='ltr'].md\:ltr\:left-640 {
    left: 64rem
  }

  [dir='ltr'] .md\:ltr\:top-auto,[dir='ltr'].md\:ltr\:top-auto {
    top: auto
  }

  [dir='ltr'] .md\:ltr\:right-auto,[dir='ltr'].md\:ltr\:right-auto {
    right: auto
  }

  [dir='ltr'] .md\:ltr\:bottom-auto,[dir='ltr'].md\:ltr\:bottom-auto {
    bottom: auto
  }

  [dir='ltr'] .md\:ltr\:left-auto,[dir='ltr'].md\:ltr\:left-auto {
    left: auto
  }

  [dir='ltr'] .md\:ltr\:top-xs,[dir='ltr'].md\:ltr\:top-xs {
    top: 32rem
  }

  [dir='ltr'] .md\:ltr\:right-xs,[dir='ltr'].md\:ltr\:right-xs {
    right: 32rem
  }

  [dir='ltr'] .md\:ltr\:bottom-xs,[dir='ltr'].md\:ltr\:bottom-xs {
    bottom: 32rem
  }

  [dir='ltr'] .md\:ltr\:left-xs,[dir='ltr'].md\:ltr\:left-xs {
    left: 32rem
  }

  [dir='ltr'] .md\:ltr\:top-sm,[dir='ltr'].md\:ltr\:top-sm {
    top: 48rem
  }

  [dir='ltr'] .md\:ltr\:right-sm,[dir='ltr'].md\:ltr\:right-sm {
    right: 48rem
  }

  [dir='ltr'] .md\:ltr\:bottom-sm,[dir='ltr'].md\:ltr\:bottom-sm {
    bottom: 48rem
  }

  [dir='ltr'] .md\:ltr\:left-sm,[dir='ltr'].md\:ltr\:left-sm {
    left: 48rem
  }

  [dir='ltr'] .md\:ltr\:top-md,[dir='ltr'].md\:ltr\:top-md {
    top: 64rem
  }

  [dir='ltr'] .md\:ltr\:right-md,[dir='ltr'].md\:ltr\:right-md {
    right: 64rem
  }

  [dir='ltr'] .md\:ltr\:bottom-md,[dir='ltr'].md\:ltr\:bottom-md {
    bottom: 64rem
  }

  [dir='ltr'] .md\:ltr\:left-md,[dir='ltr'].md\:ltr\:left-md {
    left: 64rem
  }

  [dir='ltr'] .md\:ltr\:top-lg,[dir='ltr'].md\:ltr\:top-lg {
    top: 80rem
  }

  [dir='ltr'] .md\:ltr\:right-lg,[dir='ltr'].md\:ltr\:right-lg {
    right: 80rem
  }

  [dir='ltr'] .md\:ltr\:bottom-lg,[dir='ltr'].md\:ltr\:bottom-lg {
    bottom: 80rem
  }

  [dir='ltr'] .md\:ltr\:left-lg,[dir='ltr'].md\:ltr\:left-lg {
    left: 80rem
  }

  [dir='ltr'] .md\:ltr\:top-xl,[dir='ltr'].md\:ltr\:top-xl {
    top: 96rem
  }

  [dir='ltr'] .md\:ltr\:right-xl,[dir='ltr'].md\:ltr\:right-xl {
    right: 96rem
  }

  [dir='ltr'] .md\:ltr\:bottom-xl,[dir='ltr'].md\:ltr\:bottom-xl {
    bottom: 96rem
  }

  [dir='ltr'] .md\:ltr\:left-xl,[dir='ltr'].md\:ltr\:left-xl {
    left: 96rem
  }

  [dir='ltr'] .md\:ltr\:top-2xl,[dir='ltr'].md\:ltr\:top-2xl {
    top: 112rem
  }

  [dir='ltr'] .md\:ltr\:right-2xl,[dir='ltr'].md\:ltr\:right-2xl {
    right: 112rem
  }

  [dir='ltr'] .md\:ltr\:bottom-2xl,[dir='ltr'].md\:ltr\:bottom-2xl {
    bottom: 112rem
  }

  [dir='ltr'] .md\:ltr\:left-2xl,[dir='ltr'].md\:ltr\:left-2xl {
    left: 112rem
  }

  [dir='ltr'] .md\:ltr\:top-3xl,[dir='ltr'].md\:ltr\:top-3xl {
    top: 128rem
  }

  [dir='ltr'] .md\:ltr\:right-3xl,[dir='ltr'].md\:ltr\:right-3xl {
    right: 128rem
  }

  [dir='ltr'] .md\:ltr\:bottom-3xl,[dir='ltr'].md\:ltr\:bottom-3xl {
    bottom: 128rem
  }

  [dir='ltr'] .md\:ltr\:left-3xl,[dir='ltr'].md\:ltr\:left-3xl {
    left: 128rem
  }

  [dir='ltr'] .md\:ltr\:top-4xl,[dir='ltr'].md\:ltr\:top-4xl {
    top: 144rem
  }

  [dir='ltr'] .md\:ltr\:right-4xl,[dir='ltr'].md\:ltr\:right-4xl {
    right: 144rem
  }

  [dir='ltr'] .md\:ltr\:bottom-4xl,[dir='ltr'].md\:ltr\:bottom-4xl {
    bottom: 144rem
  }

  [dir='ltr'] .md\:ltr\:left-4xl,[dir='ltr'].md\:ltr\:left-4xl {
    left: 144rem
  }

  [dir='ltr'] .md\:ltr\:top-5xl,[dir='ltr'].md\:ltr\:top-5xl {
    top: 160rem
  }

  [dir='ltr'] .md\:ltr\:right-5xl,[dir='ltr'].md\:ltr\:right-5xl {
    right: 160rem
  }

  [dir='ltr'] .md\:ltr\:bottom-5xl,[dir='ltr'].md\:ltr\:bottom-5xl {
    bottom: 160rem
  }

  [dir='ltr'] .md\:ltr\:left-5xl,[dir='ltr'].md\:ltr\:left-5xl {
    left: 160rem
  }

  [dir='ltr'] .md\:ltr\:top-px,[dir='ltr'].md\:ltr\:top-px {
    top: 1px
  }

  [dir='ltr'] .md\:ltr\:right-px,[dir='ltr'].md\:ltr\:right-px {
    right: 1px
  }

  [dir='ltr'] .md\:ltr\:bottom-px,[dir='ltr'].md\:ltr\:bottom-px {
    bottom: 1px
  }

  [dir='ltr'] .md\:ltr\:left-px,[dir='ltr'].md\:ltr\:left-px {
    left: 1px
  }

  [dir='ltr'] .md\:ltr\:top-0\.5,[dir='ltr'].md\:ltr\:top-0\.5 {
    top: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:right-0\.5,[dir='ltr'].md\:ltr\:right-0\.5 {
    right: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:bottom-0\.5,[dir='ltr'].md\:ltr\:bottom-0\.5 {
    bottom: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:left-0\.5,[dir='ltr'].md\:ltr\:left-0\.5 {
    left: 0.05rem
  }

  [dir='ltr'] .md\:ltr\:top-1\.5,[dir='ltr'].md\:ltr\:top-1\.5 {
    top: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:right-1\.5,[dir='ltr'].md\:ltr\:right-1\.5 {
    right: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:bottom-1\.5,[dir='ltr'].md\:ltr\:bottom-1\.5 {
    bottom: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:left-1\.5,[dir='ltr'].md\:ltr\:left-1\.5 {
    left: 0.15rem
  }

  [dir='ltr'] .md\:ltr\:top-2\.5,[dir='ltr'].md\:ltr\:top-2\.5 {
    top: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:right-2\.5,[dir='ltr'].md\:ltr\:right-2\.5 {
    right: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:bottom-2\.5,[dir='ltr'].md\:ltr\:bottom-2\.5 {
    bottom: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:left-2\.5,[dir='ltr'].md\:ltr\:left-2\.5 {
    left: 0.25rem
  }

  [dir='ltr'] .md\:ltr\:top-3\.5,[dir='ltr'].md\:ltr\:top-3\.5 {
    top: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:right-3\.5,[dir='ltr'].md\:ltr\:right-3\.5 {
    right: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:bottom-3\.5,[dir='ltr'].md\:ltr\:bottom-3\.5 {
    bottom: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:left-3\.5,[dir='ltr'].md\:ltr\:left-3\.5 {
    left: 0.35rem
  }

  [dir='ltr'] .md\:ltr\:-top-1,[dir='ltr'].md\:ltr\:-top-1 {
    top: -0.1rem
  }

  [dir='ltr'] .md\:ltr\:-right-1,[dir='ltr'].md\:ltr\:-right-1 {
    right: -0.1rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-1,[dir='ltr'].md\:ltr\:-bottom-1 {
    bottom: -0.1rem
  }

  [dir='ltr'] .md\:ltr\:-left-1,[dir='ltr'].md\:ltr\:-left-1 {
    left: -0.1rem
  }

  [dir='ltr'] .md\:ltr\:-top-2,[dir='ltr'].md\:ltr\:-top-2 {
    top: -0.2rem
  }

  [dir='ltr'] .md\:ltr\:-right-2,[dir='ltr'].md\:ltr\:-right-2 {
    right: -0.2rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-2,[dir='ltr'].md\:ltr\:-bottom-2 {
    bottom: -0.2rem
  }

  [dir='ltr'] .md\:ltr\:-left-2,[dir='ltr'].md\:ltr\:-left-2 {
    left: -0.2rem
  }

  [dir='ltr'] .md\:ltr\:-top-3,[dir='ltr'].md\:ltr\:-top-3 {
    top: -0.3rem
  }

  [dir='ltr'] .md\:ltr\:-right-3,[dir='ltr'].md\:ltr\:-right-3 {
    right: -0.3rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-3,[dir='ltr'].md\:ltr\:-bottom-3 {
    bottom: -0.3rem
  }

  [dir='ltr'] .md\:ltr\:-left-3,[dir='ltr'].md\:ltr\:-left-3 {
    left: -0.3rem
  }

  [dir='ltr'] .md\:ltr\:-top-4,[dir='ltr'].md\:ltr\:-top-4 {
    top: -0.4rem
  }

  [dir='ltr'] .md\:ltr\:-right-4,[dir='ltr'].md\:ltr\:-right-4 {
    right: -0.4rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-4,[dir='ltr'].md\:ltr\:-bottom-4 {
    bottom: -0.4rem
  }

  [dir='ltr'] .md\:ltr\:-left-4,[dir='ltr'].md\:ltr\:-left-4 {
    left: -0.4rem
  }

  [dir='ltr'] .md\:ltr\:-top-5,[dir='ltr'].md\:ltr\:-top-5 {
    top: -0.5rem
  }

  [dir='ltr'] .md\:ltr\:-right-5,[dir='ltr'].md\:ltr\:-right-5 {
    right: -0.5rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-5,[dir='ltr'].md\:ltr\:-bottom-5 {
    bottom: -0.5rem
  }

  [dir='ltr'] .md\:ltr\:-left-5,[dir='ltr'].md\:ltr\:-left-5 {
    left: -0.5rem
  }

  [dir='ltr'] .md\:ltr\:-top-6,[dir='ltr'].md\:ltr\:-top-6 {
    top: -0.6rem
  }

  [dir='ltr'] .md\:ltr\:-right-6,[dir='ltr'].md\:ltr\:-right-6 {
    right: -0.6rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-6,[dir='ltr'].md\:ltr\:-bottom-6 {
    bottom: -0.6rem
  }

  [dir='ltr'] .md\:ltr\:-left-6,[dir='ltr'].md\:ltr\:-left-6 {
    left: -0.6rem
  }

  [dir='ltr'] .md\:ltr\:-top-7,[dir='ltr'].md\:ltr\:-top-7 {
    top: -0.7rem
  }

  [dir='ltr'] .md\:ltr\:-right-7,[dir='ltr'].md\:ltr\:-right-7 {
    right: -0.7rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-7,[dir='ltr'].md\:ltr\:-bottom-7 {
    bottom: -0.7rem
  }

  [dir='ltr'] .md\:ltr\:-left-7,[dir='ltr'].md\:ltr\:-left-7 {
    left: -0.7rem
  }

  [dir='ltr'] .md\:ltr\:-top-8,[dir='ltr'].md\:ltr\:-top-8 {
    top: -0.8rem
  }

  [dir='ltr'] .md\:ltr\:-right-8,[dir='ltr'].md\:ltr\:-right-8 {
    right: -0.8rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-8,[dir='ltr'].md\:ltr\:-bottom-8 {
    bottom: -0.8rem
  }

  [dir='ltr'] .md\:ltr\:-left-8,[dir='ltr'].md\:ltr\:-left-8 {
    left: -0.8rem
  }

  [dir='ltr'] .md\:ltr\:-top-9,[dir='ltr'].md\:ltr\:-top-9 {
    top: -0.9rem
  }

  [dir='ltr'] .md\:ltr\:-right-9,[dir='ltr'].md\:ltr\:-right-9 {
    right: -0.9rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-9,[dir='ltr'].md\:ltr\:-bottom-9 {
    bottom: -0.9rem
  }

  [dir='ltr'] .md\:ltr\:-left-9,[dir='ltr'].md\:ltr\:-left-9 {
    left: -0.9rem
  }

  [dir='ltr'] .md\:ltr\:-top-10,[dir='ltr'].md\:ltr\:-top-10 {
    top: -1rem
  }

  [dir='ltr'] .md\:ltr\:-right-10,[dir='ltr'].md\:ltr\:-right-10 {
    right: -1rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-10,[dir='ltr'].md\:ltr\:-bottom-10 {
    bottom: -1rem
  }

  [dir='ltr'] .md\:ltr\:-left-10,[dir='ltr'].md\:ltr\:-left-10 {
    left: -1rem
  }

  [dir='ltr'] .md\:ltr\:-top-12,[dir='ltr'].md\:ltr\:-top-12 {
    top: -1.2rem
  }

  [dir='ltr'] .md\:ltr\:-right-12,[dir='ltr'].md\:ltr\:-right-12 {
    right: -1.2rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-12,[dir='ltr'].md\:ltr\:-bottom-12 {
    bottom: -1.2rem
  }

  [dir='ltr'] .md\:ltr\:-left-12,[dir='ltr'].md\:ltr\:-left-12 {
    left: -1.2rem
  }

  [dir='ltr'] .md\:ltr\:-top-14,[dir='ltr'].md\:ltr\:-top-14 {
    top: -1.4rem
  }

  [dir='ltr'] .md\:ltr\:-right-14,[dir='ltr'].md\:ltr\:-right-14 {
    right: -1.4rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-14,[dir='ltr'].md\:ltr\:-bottom-14 {
    bottom: -1.4rem
  }

  [dir='ltr'] .md\:ltr\:-left-14,[dir='ltr'].md\:ltr\:-left-14 {
    left: -1.4rem
  }

  [dir='ltr'] .md\:ltr\:-top-16,[dir='ltr'].md\:ltr\:-top-16 {
    top: -1.6rem
  }

  [dir='ltr'] .md\:ltr\:-right-16,[dir='ltr'].md\:ltr\:-right-16 {
    right: -1.6rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-16,[dir='ltr'].md\:ltr\:-bottom-16 {
    bottom: -1.6rem
  }

  [dir='ltr'] .md\:ltr\:-left-16,[dir='ltr'].md\:ltr\:-left-16 {
    left: -1.6rem
  }

  [dir='ltr'] .md\:ltr\:-top-20,[dir='ltr'].md\:ltr\:-top-20 {
    top: -2rem
  }

  [dir='ltr'] .md\:ltr\:-right-20,[dir='ltr'].md\:ltr\:-right-20 {
    right: -2rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-20,[dir='ltr'].md\:ltr\:-bottom-20 {
    bottom: -2rem
  }

  [dir='ltr'] .md\:ltr\:-left-20,[dir='ltr'].md\:ltr\:-left-20 {
    left: -2rem
  }

  [dir='ltr'] .md\:ltr\:-top-24,[dir='ltr'].md\:ltr\:-top-24 {
    top: -2.4rem
  }

  [dir='ltr'] .md\:ltr\:-right-24,[dir='ltr'].md\:ltr\:-right-24 {
    right: -2.4rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-24,[dir='ltr'].md\:ltr\:-bottom-24 {
    bottom: -2.4rem
  }

  [dir='ltr'] .md\:ltr\:-left-24,[dir='ltr'].md\:ltr\:-left-24 {
    left: -2.4rem
  }

  [dir='ltr'] .md\:ltr\:-top-28,[dir='ltr'].md\:ltr\:-top-28 {
    top: -2.8rem
  }

  [dir='ltr'] .md\:ltr\:-right-28,[dir='ltr'].md\:ltr\:-right-28 {
    right: -2.8rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-28,[dir='ltr'].md\:ltr\:-bottom-28 {
    bottom: -2.8rem
  }

  [dir='ltr'] .md\:ltr\:-left-28,[dir='ltr'].md\:ltr\:-left-28 {
    left: -2.8rem
  }

  [dir='ltr'] .md\:ltr\:-top-32,[dir='ltr'].md\:ltr\:-top-32 {
    top: -3.2rem
  }

  [dir='ltr'] .md\:ltr\:-right-32,[dir='ltr'].md\:ltr\:-right-32 {
    right: -3.2rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-32,[dir='ltr'].md\:ltr\:-bottom-32 {
    bottom: -3.2rem
  }

  [dir='ltr'] .md\:ltr\:-left-32,[dir='ltr'].md\:ltr\:-left-32 {
    left: -3.2rem
  }

  [dir='ltr'] .md\:ltr\:-top-36,[dir='ltr'].md\:ltr\:-top-36 {
    top: -3.6rem
  }

  [dir='ltr'] .md\:ltr\:-right-36,[dir='ltr'].md\:ltr\:-right-36 {
    right: -3.6rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-36,[dir='ltr'].md\:ltr\:-bottom-36 {
    bottom: -3.6rem
  }

  [dir='ltr'] .md\:ltr\:-left-36,[dir='ltr'].md\:ltr\:-left-36 {
    left: -3.6rem
  }

  [dir='ltr'] .md\:ltr\:-top-40,[dir='ltr'].md\:ltr\:-top-40 {
    top: -4rem
  }

  [dir='ltr'] .md\:ltr\:-right-40,[dir='ltr'].md\:ltr\:-right-40 {
    right: -4rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-40,[dir='ltr'].md\:ltr\:-bottom-40 {
    bottom: -4rem
  }

  [dir='ltr'] .md\:ltr\:-left-40,[dir='ltr'].md\:ltr\:-left-40 {
    left: -4rem
  }

  [dir='ltr'] .md\:ltr\:-top-44,[dir='ltr'].md\:ltr\:-top-44 {
    top: -4.4rem
  }

  [dir='ltr'] .md\:ltr\:-right-44,[dir='ltr'].md\:ltr\:-right-44 {
    right: -4.4rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-44,[dir='ltr'].md\:ltr\:-bottom-44 {
    bottom: -4.4rem
  }

  [dir='ltr'] .md\:ltr\:-left-44,[dir='ltr'].md\:ltr\:-left-44 {
    left: -4.4rem
  }

  [dir='ltr'] .md\:ltr\:-top-48,[dir='ltr'].md\:ltr\:-top-48 {
    top: -4.8rem
  }

  [dir='ltr'] .md\:ltr\:-right-48,[dir='ltr'].md\:ltr\:-right-48 {
    right: -4.8rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-48,[dir='ltr'].md\:ltr\:-bottom-48 {
    bottom: -4.8rem
  }

  [dir='ltr'] .md\:ltr\:-left-48,[dir='ltr'].md\:ltr\:-left-48 {
    left: -4.8rem
  }

  [dir='ltr'] .md\:ltr\:-top-52,[dir='ltr'].md\:ltr\:-top-52 {
    top: -5.2rem
  }

  [dir='ltr'] .md\:ltr\:-right-52,[dir='ltr'].md\:ltr\:-right-52 {
    right: -5.2rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-52,[dir='ltr'].md\:ltr\:-bottom-52 {
    bottom: -5.2rem
  }

  [dir='ltr'] .md\:ltr\:-left-52,[dir='ltr'].md\:ltr\:-left-52 {
    left: -5.2rem
  }

  [dir='ltr'] .md\:ltr\:-top-56,[dir='ltr'].md\:ltr\:-top-56 {
    top: -5.6rem
  }

  [dir='ltr'] .md\:ltr\:-right-56,[dir='ltr'].md\:ltr\:-right-56 {
    right: -5.6rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-56,[dir='ltr'].md\:ltr\:-bottom-56 {
    bottom: -5.6rem
  }

  [dir='ltr'] .md\:ltr\:-left-56,[dir='ltr'].md\:ltr\:-left-56 {
    left: -5.6rem
  }

  [dir='ltr'] .md\:ltr\:-top-60,[dir='ltr'].md\:ltr\:-top-60 {
    top: -6rem
  }

  [dir='ltr'] .md\:ltr\:-right-60,[dir='ltr'].md\:ltr\:-right-60 {
    right: -6rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-60,[dir='ltr'].md\:ltr\:-bottom-60 {
    bottom: -6rem
  }

  [dir='ltr'] .md\:ltr\:-left-60,[dir='ltr'].md\:ltr\:-left-60 {
    left: -6rem
  }

  [dir='ltr'] .md\:ltr\:-top-64,[dir='ltr'].md\:ltr\:-top-64 {
    top: -6.4rem
  }

  [dir='ltr'] .md\:ltr\:-right-64,[dir='ltr'].md\:ltr\:-right-64 {
    right: -6.4rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-64,[dir='ltr'].md\:ltr\:-bottom-64 {
    bottom: -6.4rem
  }

  [dir='ltr'] .md\:ltr\:-left-64,[dir='ltr'].md\:ltr\:-left-64 {
    left: -6.4rem
  }

  [dir='ltr'] .md\:ltr\:-top-68,[dir='ltr'].md\:ltr\:-top-68 {
    top: -6.8rem
  }

  [dir='ltr'] .md\:ltr\:-right-68,[dir='ltr'].md\:ltr\:-right-68 {
    right: -6.8rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-68,[dir='ltr'].md\:ltr\:-bottom-68 {
    bottom: -6.8rem
  }

  [dir='ltr'] .md\:ltr\:-left-68,[dir='ltr'].md\:ltr\:-left-68 {
    left: -6.8rem
  }

  [dir='ltr'] .md\:ltr\:-top-72,[dir='ltr'].md\:ltr\:-top-72 {
    top: -7.2rem
  }

  [dir='ltr'] .md\:ltr\:-right-72,[dir='ltr'].md\:ltr\:-right-72 {
    right: -7.2rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-72,[dir='ltr'].md\:ltr\:-bottom-72 {
    bottom: -7.2rem
  }

  [dir='ltr'] .md\:ltr\:-left-72,[dir='ltr'].md\:ltr\:-left-72 {
    left: -7.2rem
  }

  [dir='ltr'] .md\:ltr\:-top-76,[dir='ltr'].md\:ltr\:-top-76 {
    top: -7.6rem
  }

  [dir='ltr'] .md\:ltr\:-right-76,[dir='ltr'].md\:ltr\:-right-76 {
    right: -7.6rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-76,[dir='ltr'].md\:ltr\:-bottom-76 {
    bottom: -7.6rem
  }

  [dir='ltr'] .md\:ltr\:-left-76,[dir='ltr'].md\:ltr\:-left-76 {
    left: -7.6rem
  }

  [dir='ltr'] .md\:ltr\:-top-80,[dir='ltr'].md\:ltr\:-top-80 {
    top: -8rem
  }

  [dir='ltr'] .md\:ltr\:-right-80,[dir='ltr'].md\:ltr\:-right-80 {
    right: -8rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-80,[dir='ltr'].md\:ltr\:-bottom-80 {
    bottom: -8rem
  }

  [dir='ltr'] .md\:ltr\:-left-80,[dir='ltr'].md\:ltr\:-left-80 {
    left: -8rem
  }

  [dir='ltr'] .md\:ltr\:-top-84,[dir='ltr'].md\:ltr\:-top-84 {
    top: -8.4rem
  }

  [dir='ltr'] .md\:ltr\:-right-84,[dir='ltr'].md\:ltr\:-right-84 {
    right: -8.4rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-84,[dir='ltr'].md\:ltr\:-bottom-84 {
    bottom: -8.4rem
  }

  [dir='ltr'] .md\:ltr\:-left-84,[dir='ltr'].md\:ltr\:-left-84 {
    left: -8.4rem
  }

  [dir='ltr'] .md\:ltr\:-top-88,[dir='ltr'].md\:ltr\:-top-88 {
    top: -8.8rem
  }

  [dir='ltr'] .md\:ltr\:-right-88,[dir='ltr'].md\:ltr\:-right-88 {
    right: -8.8rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-88,[dir='ltr'].md\:ltr\:-bottom-88 {
    bottom: -8.8rem
  }

  [dir='ltr'] .md\:ltr\:-left-88,[dir='ltr'].md\:ltr\:-left-88 {
    left: -8.8rem
  }

  [dir='ltr'] .md\:ltr\:-top-92,[dir='ltr'].md\:ltr\:-top-92 {
    top: -9.2rem
  }

  [dir='ltr'] .md\:ltr\:-right-92,[dir='ltr'].md\:ltr\:-right-92 {
    right: -9.2rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-92,[dir='ltr'].md\:ltr\:-bottom-92 {
    bottom: -9.2rem
  }

  [dir='ltr'] .md\:ltr\:-left-92,[dir='ltr'].md\:ltr\:-left-92 {
    left: -9.2rem
  }

  [dir='ltr'] .md\:ltr\:-top-96,[dir='ltr'].md\:ltr\:-top-96 {
    top: -9.6rem
  }

  [dir='ltr'] .md\:ltr\:-right-96,[dir='ltr'].md\:ltr\:-right-96 {
    right: -9.6rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-96,[dir='ltr'].md\:ltr\:-bottom-96 {
    bottom: -9.6rem
  }

  [dir='ltr'] .md\:ltr\:-left-96,[dir='ltr'].md\:ltr\:-left-96 {
    left: -9.6rem
  }

  [dir='ltr'] .md\:ltr\:-top-128,[dir='ltr'].md\:ltr\:-top-128 {
    top: -12.8rem
  }

  [dir='ltr'] .md\:ltr\:-right-128,[dir='ltr'].md\:ltr\:-right-128 {
    right: -12.8rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-128,[dir='ltr'].md\:ltr\:-bottom-128 {
    bottom: -12.8rem
  }

  [dir='ltr'] .md\:ltr\:-left-128,[dir='ltr'].md\:ltr\:-left-128 {
    left: -12.8rem
  }

  [dir='ltr'] .md\:ltr\:-top-136,[dir='ltr'].md\:ltr\:-top-136 {
    top: -13.6rem
  }

  [dir='ltr'] .md\:ltr\:-right-136,[dir='ltr'].md\:ltr\:-right-136 {
    right: -13.6rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-136,[dir='ltr'].md\:ltr\:-bottom-136 {
    bottom: -13.6rem
  }

  [dir='ltr'] .md\:ltr\:-left-136,[dir='ltr'].md\:ltr\:-left-136 {
    left: -13.6rem
  }

  [dir='ltr'] .md\:ltr\:-top-160,[dir='ltr'].md\:ltr\:-top-160 {
    top: -16rem
  }

  [dir='ltr'] .md\:ltr\:-right-160,[dir='ltr'].md\:ltr\:-right-160 {
    right: -16rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-160,[dir='ltr'].md\:ltr\:-bottom-160 {
    bottom: -16rem
  }

  [dir='ltr'] .md\:ltr\:-left-160,[dir='ltr'].md\:ltr\:-left-160 {
    left: -16rem
  }

  [dir='ltr'] .md\:ltr\:-top-192,[dir='ltr'].md\:ltr\:-top-192 {
    top: -19.2rem
  }

  [dir='ltr'] .md\:ltr\:-right-192,[dir='ltr'].md\:ltr\:-right-192 {
    right: -19.2rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-192,[dir='ltr'].md\:ltr\:-bottom-192 {
    bottom: -19.2rem
  }

  [dir='ltr'] .md\:ltr\:-left-192,[dir='ltr'].md\:ltr\:-left-192 {
    left: -19.2rem
  }

  [dir='ltr'] .md\:ltr\:-top-200,[dir='ltr'].md\:ltr\:-top-200 {
    top: -20rem
  }

  [dir='ltr'] .md\:ltr\:-right-200,[dir='ltr'].md\:ltr\:-right-200 {
    right: -20rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-200,[dir='ltr'].md\:ltr\:-bottom-200 {
    bottom: -20rem
  }

  [dir='ltr'] .md\:ltr\:-left-200,[dir='ltr'].md\:ltr\:-left-200 {
    left: -20rem
  }

  [dir='ltr'] .md\:ltr\:-top-208,[dir='ltr'].md\:ltr\:-top-208 {
    top: -20.8rem
  }

  [dir='ltr'] .md\:ltr\:-right-208,[dir='ltr'].md\:ltr\:-right-208 {
    right: -20.8rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-208,[dir='ltr'].md\:ltr\:-bottom-208 {
    bottom: -20.8rem
  }

  [dir='ltr'] .md\:ltr\:-left-208,[dir='ltr'].md\:ltr\:-left-208 {
    left: -20.8rem
  }

  [dir='ltr'] .md\:ltr\:-top-216,[dir='ltr'].md\:ltr\:-top-216 {
    top: -21.6rem
  }

  [dir='ltr'] .md\:ltr\:-right-216,[dir='ltr'].md\:ltr\:-right-216 {
    right: -21.6rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-216,[dir='ltr'].md\:ltr\:-bottom-216 {
    bottom: -21.6rem
  }

  [dir='ltr'] .md\:ltr\:-left-216,[dir='ltr'].md\:ltr\:-left-216 {
    left: -21.6rem
  }

  [dir='ltr'] .md\:ltr\:-top-224,[dir='ltr'].md\:ltr\:-top-224 {
    top: -22.4rem
  }

  [dir='ltr'] .md\:ltr\:-right-224,[dir='ltr'].md\:ltr\:-right-224 {
    right: -22.4rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-224,[dir='ltr'].md\:ltr\:-bottom-224 {
    bottom: -22.4rem
  }

  [dir='ltr'] .md\:ltr\:-left-224,[dir='ltr'].md\:ltr\:-left-224 {
    left: -22.4rem
  }

  [dir='ltr'] .md\:ltr\:-top-256,[dir='ltr'].md\:ltr\:-top-256 {
    top: -25.6rem
  }

  [dir='ltr'] .md\:ltr\:-right-256,[dir='ltr'].md\:ltr\:-right-256 {
    right: -25.6rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-256,[dir='ltr'].md\:ltr\:-bottom-256 {
    bottom: -25.6rem
  }

  [dir='ltr'] .md\:ltr\:-left-256,[dir='ltr'].md\:ltr\:-left-256 {
    left: -25.6rem
  }

  [dir='ltr'] .md\:ltr\:-top-288,[dir='ltr'].md\:ltr\:-top-288 {
    top: -28.8rem
  }

  [dir='ltr'] .md\:ltr\:-right-288,[dir='ltr'].md\:ltr\:-right-288 {
    right: -28.8rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-288,[dir='ltr'].md\:ltr\:-bottom-288 {
    bottom: -28.8rem
  }

  [dir='ltr'] .md\:ltr\:-left-288,[dir='ltr'].md\:ltr\:-left-288 {
    left: -28.8rem
  }

  [dir='ltr'] .md\:ltr\:-top-320,[dir='ltr'].md\:ltr\:-top-320 {
    top: -32rem
  }

  [dir='ltr'] .md\:ltr\:-right-320,[dir='ltr'].md\:ltr\:-right-320 {
    right: -32rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-320,[dir='ltr'].md\:ltr\:-bottom-320 {
    bottom: -32rem
  }

  [dir='ltr'] .md\:ltr\:-left-320,[dir='ltr'].md\:ltr\:-left-320 {
    left: -32rem
  }

  [dir='ltr'] .md\:ltr\:-top-360,[dir='ltr'].md\:ltr\:-top-360 {
    top: -36rem
  }

  [dir='ltr'] .md\:ltr\:-right-360,[dir='ltr'].md\:ltr\:-right-360 {
    right: -36rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-360,[dir='ltr'].md\:ltr\:-bottom-360 {
    bottom: -36rem
  }

  [dir='ltr'] .md\:ltr\:-left-360,[dir='ltr'].md\:ltr\:-left-360 {
    left: -36rem
  }

  [dir='ltr'] .md\:ltr\:-top-384,[dir='ltr'].md\:ltr\:-top-384 {
    top: -38.4rem
  }

  [dir='ltr'] .md\:ltr\:-right-384,[dir='ltr'].md\:ltr\:-right-384 {
    right: -38.4rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-384,[dir='ltr'].md\:ltr\:-bottom-384 {
    bottom: -38.4rem
  }

  [dir='ltr'] .md\:ltr\:-left-384,[dir='ltr'].md\:ltr\:-left-384 {
    left: -38.4rem
  }

  [dir='ltr'] .md\:ltr\:-top-400,[dir='ltr'].md\:ltr\:-top-400 {
    top: -40rem
  }

  [dir='ltr'] .md\:ltr\:-right-400,[dir='ltr'].md\:ltr\:-right-400 {
    right: -40rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-400,[dir='ltr'].md\:ltr\:-bottom-400 {
    bottom: -40rem
  }

  [dir='ltr'] .md\:ltr\:-left-400,[dir='ltr'].md\:ltr\:-left-400 {
    left: -40rem
  }

  [dir='ltr'] .md\:ltr\:-top-512,[dir='ltr'].md\:ltr\:-top-512 {
    top: -51.2rem
  }

  [dir='ltr'] .md\:ltr\:-right-512,[dir='ltr'].md\:ltr\:-right-512 {
    right: -51.2rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-512,[dir='ltr'].md\:ltr\:-bottom-512 {
    bottom: -51.2rem
  }

  [dir='ltr'] .md\:ltr\:-left-512,[dir='ltr'].md\:ltr\:-left-512 {
    left: -51.2rem
  }

  [dir='ltr'] .md\:ltr\:-top-640,[dir='ltr'].md\:ltr\:-top-640 {
    top: -64rem
  }

  [dir='ltr'] .md\:ltr\:-right-640,[dir='ltr'].md\:ltr\:-right-640 {
    right: -64rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-640,[dir='ltr'].md\:ltr\:-bottom-640 {
    bottom: -64rem
  }

  [dir='ltr'] .md\:ltr\:-left-640,[dir='ltr'].md\:ltr\:-left-640 {
    left: -64rem
  }

  [dir='ltr'] .md\:ltr\:-top-xs,[dir='ltr'].md\:ltr\:-top-xs {
    top: -32rem
  }

  [dir='ltr'] .md\:ltr\:-right-xs,[dir='ltr'].md\:ltr\:-right-xs {
    right: -32rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-xs,[dir='ltr'].md\:ltr\:-bottom-xs {
    bottom: -32rem
  }

  [dir='ltr'] .md\:ltr\:-left-xs,[dir='ltr'].md\:ltr\:-left-xs {
    left: -32rem
  }

  [dir='ltr'] .md\:ltr\:-top-sm,[dir='ltr'].md\:ltr\:-top-sm {
    top: -48rem
  }

  [dir='ltr'] .md\:ltr\:-right-sm,[dir='ltr'].md\:ltr\:-right-sm {
    right: -48rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-sm,[dir='ltr'].md\:ltr\:-bottom-sm {
    bottom: -48rem
  }

  [dir='ltr'] .md\:ltr\:-left-sm,[dir='ltr'].md\:ltr\:-left-sm {
    left: -48rem
  }

  [dir='ltr'] .md\:ltr\:-top-md,[dir='ltr'].md\:ltr\:-top-md {
    top: -64rem
  }

  [dir='ltr'] .md\:ltr\:-right-md,[dir='ltr'].md\:ltr\:-right-md {
    right: -64rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-md,[dir='ltr'].md\:ltr\:-bottom-md {
    bottom: -64rem
  }

  [dir='ltr'] .md\:ltr\:-left-md,[dir='ltr'].md\:ltr\:-left-md {
    left: -64rem
  }

  [dir='ltr'] .md\:ltr\:-top-lg,[dir='ltr'].md\:ltr\:-top-lg {
    top: -80rem
  }

  [dir='ltr'] .md\:ltr\:-right-lg,[dir='ltr'].md\:ltr\:-right-lg {
    right: -80rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-lg,[dir='ltr'].md\:ltr\:-bottom-lg {
    bottom: -80rem
  }

  [dir='ltr'] .md\:ltr\:-left-lg,[dir='ltr'].md\:ltr\:-left-lg {
    left: -80rem
  }

  [dir='ltr'] .md\:ltr\:-top-xl,[dir='ltr'].md\:ltr\:-top-xl {
    top: -96rem
  }

  [dir='ltr'] .md\:ltr\:-right-xl,[dir='ltr'].md\:ltr\:-right-xl {
    right: -96rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-xl,[dir='ltr'].md\:ltr\:-bottom-xl {
    bottom: -96rem
  }

  [dir='ltr'] .md\:ltr\:-left-xl,[dir='ltr'].md\:ltr\:-left-xl {
    left: -96rem
  }

  [dir='ltr'] .md\:ltr\:-top-2xl,[dir='ltr'].md\:ltr\:-top-2xl {
    top: -112rem
  }

  [dir='ltr'] .md\:ltr\:-right-2xl,[dir='ltr'].md\:ltr\:-right-2xl {
    right: -112rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-2xl,[dir='ltr'].md\:ltr\:-bottom-2xl {
    bottom: -112rem
  }

  [dir='ltr'] .md\:ltr\:-left-2xl,[dir='ltr'].md\:ltr\:-left-2xl {
    left: -112rem
  }

  [dir='ltr'] .md\:ltr\:-top-3xl,[dir='ltr'].md\:ltr\:-top-3xl {
    top: -128rem
  }

  [dir='ltr'] .md\:ltr\:-right-3xl,[dir='ltr'].md\:ltr\:-right-3xl {
    right: -128rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-3xl,[dir='ltr'].md\:ltr\:-bottom-3xl {
    bottom: -128rem
  }

  [dir='ltr'] .md\:ltr\:-left-3xl,[dir='ltr'].md\:ltr\:-left-3xl {
    left: -128rem
  }

  [dir='ltr'] .md\:ltr\:-top-4xl,[dir='ltr'].md\:ltr\:-top-4xl {
    top: -144rem
  }

  [dir='ltr'] .md\:ltr\:-right-4xl,[dir='ltr'].md\:ltr\:-right-4xl {
    right: -144rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-4xl,[dir='ltr'].md\:ltr\:-bottom-4xl {
    bottom: -144rem
  }

  [dir='ltr'] .md\:ltr\:-left-4xl,[dir='ltr'].md\:ltr\:-left-4xl {
    left: -144rem
  }

  [dir='ltr'] .md\:ltr\:-top-5xl,[dir='ltr'].md\:ltr\:-top-5xl {
    top: -160rem
  }

  [dir='ltr'] .md\:ltr\:-right-5xl,[dir='ltr'].md\:ltr\:-right-5xl {
    right: -160rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-5xl,[dir='ltr'].md\:ltr\:-bottom-5xl {
    bottom: -160rem
  }

  [dir='ltr'] .md\:ltr\:-left-5xl,[dir='ltr'].md\:ltr\:-left-5xl {
    left: -160rem
  }

  [dir='ltr'] .md\:ltr\:-top-px,[dir='ltr'].md\:ltr\:-top-px {
    top: -1px
  }

  [dir='ltr'] .md\:ltr\:-right-px,[dir='ltr'].md\:ltr\:-right-px {
    right: -1px
  }

  [dir='ltr'] .md\:ltr\:-bottom-px,[dir='ltr'].md\:ltr\:-bottom-px {
    bottom: -1px
  }

  [dir='ltr'] .md\:ltr\:-left-px,[dir='ltr'].md\:ltr\:-left-px {
    left: -1px
  }

  [dir='ltr'] .md\:ltr\:-top-0\.5,[dir='ltr'].md\:ltr\:-top-0\.5 {
    top: -0.05rem
  }

  [dir='ltr'] .md\:ltr\:-right-0\.5,[dir='ltr'].md\:ltr\:-right-0\.5 {
    right: -0.05rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-0\.5,[dir='ltr'].md\:ltr\:-bottom-0\.5 {
    bottom: -0.05rem
  }

  [dir='ltr'] .md\:ltr\:-left-0\.5,[dir='ltr'].md\:ltr\:-left-0\.5 {
    left: -0.05rem
  }

  [dir='ltr'] .md\:ltr\:-top-1\.5,[dir='ltr'].md\:ltr\:-top-1\.5 {
    top: -0.15rem
  }

  [dir='ltr'] .md\:ltr\:-right-1\.5,[dir='ltr'].md\:ltr\:-right-1\.5 {
    right: -0.15rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-1\.5,[dir='ltr'].md\:ltr\:-bottom-1\.5 {
    bottom: -0.15rem
  }

  [dir='ltr'] .md\:ltr\:-left-1\.5,[dir='ltr'].md\:ltr\:-left-1\.5 {
    left: -0.15rem
  }

  [dir='ltr'] .md\:ltr\:-top-2\.5,[dir='ltr'].md\:ltr\:-top-2\.5 {
    top: -0.25rem
  }

  [dir='ltr'] .md\:ltr\:-right-2\.5,[dir='ltr'].md\:ltr\:-right-2\.5 {
    right: -0.25rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-2\.5,[dir='ltr'].md\:ltr\:-bottom-2\.5 {
    bottom: -0.25rem
  }

  [dir='ltr'] .md\:ltr\:-left-2\.5,[dir='ltr'].md\:ltr\:-left-2\.5 {
    left: -0.25rem
  }

  [dir='ltr'] .md\:ltr\:-top-3\.5,[dir='ltr'].md\:ltr\:-top-3\.5 {
    top: -0.35rem
  }

  [dir='ltr'] .md\:ltr\:-right-3\.5,[dir='ltr'].md\:ltr\:-right-3\.5 {
    right: -0.35rem
  }

  [dir='ltr'] .md\:ltr\:-bottom-3\.5,[dir='ltr'].md\:ltr\:-bottom-3\.5 {
    bottom: -0.35rem
  }

  [dir='ltr'] .md\:ltr\:-left-3\.5,[dir='ltr'].md\:ltr\:-left-3\.5 {
    left: -0.35rem
  }

  [dir='ltr'] .md\:ltr\:top-1\/2,[dir='ltr'].md\:ltr\:top-1\/2 {
    top: 50%
  }

  [dir='ltr'] .md\:ltr\:right-1\/2,[dir='ltr'].md\:ltr\:right-1\/2 {
    right: 50%
  }

  [dir='ltr'] .md\:ltr\:bottom-1\/2,[dir='ltr'].md\:ltr\:bottom-1\/2 {
    bottom: 50%
  }

  [dir='ltr'] .md\:ltr\:left-1\/2,[dir='ltr'].md\:ltr\:left-1\/2 {
    left: 50%
  }

  [dir='ltr'] .md\:ltr\:top-1\/3,[dir='ltr'].md\:ltr\:top-1\/3 {
    top: 33.333333%
  }

  [dir='ltr'] .md\:ltr\:right-1\/3,[dir='ltr'].md\:ltr\:right-1\/3 {
    right: 33.333333%
  }

  [dir='ltr'] .md\:ltr\:bottom-1\/3,[dir='ltr'].md\:ltr\:bottom-1\/3 {
    bottom: 33.333333%
  }

  [dir='ltr'] .md\:ltr\:left-1\/3,[dir='ltr'].md\:ltr\:left-1\/3 {
    left: 33.333333%
  }

  [dir='ltr'] .md\:ltr\:top-2\/3,[dir='ltr'].md\:ltr\:top-2\/3 {
    top: 66.666667%
  }

  [dir='ltr'] .md\:ltr\:right-2\/3,[dir='ltr'].md\:ltr\:right-2\/3 {
    right: 66.666667%
  }

  [dir='ltr'] .md\:ltr\:bottom-2\/3,[dir='ltr'].md\:ltr\:bottom-2\/3 {
    bottom: 66.666667%
  }

  [dir='ltr'] .md\:ltr\:left-2\/3,[dir='ltr'].md\:ltr\:left-2\/3 {
    left: 66.666667%
  }

  [dir='ltr'] .md\:ltr\:top-1\/4,[dir='ltr'].md\:ltr\:top-1\/4 {
    top: 25%
  }

  [dir='ltr'] .md\:ltr\:right-1\/4,[dir='ltr'].md\:ltr\:right-1\/4 {
    right: 25%
  }

  [dir='ltr'] .md\:ltr\:bottom-1\/4,[dir='ltr'].md\:ltr\:bottom-1\/4 {
    bottom: 25%
  }

  [dir='ltr'] .md\:ltr\:left-1\/4,[dir='ltr'].md\:ltr\:left-1\/4 {
    left: 25%
  }

  [dir='ltr'] .md\:ltr\:top-2\/4,[dir='ltr'].md\:ltr\:top-2\/4 {
    top: 50%
  }

  [dir='ltr'] .md\:ltr\:right-2\/4,[dir='ltr'].md\:ltr\:right-2\/4 {
    right: 50%
  }

  [dir='ltr'] .md\:ltr\:bottom-2\/4,[dir='ltr'].md\:ltr\:bottom-2\/4 {
    bottom: 50%
  }

  [dir='ltr'] .md\:ltr\:left-2\/4,[dir='ltr'].md\:ltr\:left-2\/4 {
    left: 50%
  }

  [dir='ltr'] .md\:ltr\:top-3\/4,[dir='ltr'].md\:ltr\:top-3\/4 {
    top: 75%
  }

  [dir='ltr'] .md\:ltr\:right-3\/4,[dir='ltr'].md\:ltr\:right-3\/4 {
    right: 75%
  }

  [dir='ltr'] .md\:ltr\:bottom-3\/4,[dir='ltr'].md\:ltr\:bottom-3\/4 {
    bottom: 75%
  }

  [dir='ltr'] .md\:ltr\:left-3\/4,[dir='ltr'].md\:ltr\:left-3\/4 {
    left: 75%
  }

  [dir='ltr'] .md\:ltr\:top-full,[dir='ltr'].md\:ltr\:top-full {
    top: 100%
  }

  [dir='ltr'] .md\:ltr\:right-full,[dir='ltr'].md\:ltr\:right-full {
    right: 100%
  }

  [dir='ltr'] .md\:ltr\:bottom-full,[dir='ltr'].md\:ltr\:bottom-full {
    bottom: 100%
  }

  [dir='ltr'] .md\:ltr\:left-full,[dir='ltr'].md\:ltr\:left-full {
    left: 100%
  }

  [dir='ltr'] .md\:ltr\:-top-1\/2,[dir='ltr'].md\:ltr\:-top-1\/2 {
    top: -50%
  }

  [dir='ltr'] .md\:ltr\:-right-1\/2,[dir='ltr'].md\:ltr\:-right-1\/2 {
    right: -50%
  }

  [dir='ltr'] .md\:ltr\:-bottom-1\/2,[dir='ltr'].md\:ltr\:-bottom-1\/2 {
    bottom: -50%
  }

  [dir='ltr'] .md\:ltr\:-left-1\/2,[dir='ltr'].md\:ltr\:-left-1\/2 {
    left: -50%
  }

  [dir='ltr'] .md\:ltr\:-top-1\/3,[dir='ltr'].md\:ltr\:-top-1\/3 {
    top: -33.333333%
  }

  [dir='ltr'] .md\:ltr\:-right-1\/3,[dir='ltr'].md\:ltr\:-right-1\/3 {
    right: -33.333333%
  }

  [dir='ltr'] .md\:ltr\:-bottom-1\/3,[dir='ltr'].md\:ltr\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  [dir='ltr'] .md\:ltr\:-left-1\/3,[dir='ltr'].md\:ltr\:-left-1\/3 {
    left: -33.333333%
  }

  [dir='ltr'] .md\:ltr\:-top-2\/3,[dir='ltr'].md\:ltr\:-top-2\/3 {
    top: -66.666667%
  }

  [dir='ltr'] .md\:ltr\:-right-2\/3,[dir='ltr'].md\:ltr\:-right-2\/3 {
    right: -66.666667%
  }

  [dir='ltr'] .md\:ltr\:-bottom-2\/3,[dir='ltr'].md\:ltr\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  [dir='ltr'] .md\:ltr\:-left-2\/3,[dir='ltr'].md\:ltr\:-left-2\/3 {
    left: -66.666667%
  }

  [dir='ltr'] .md\:ltr\:-top-1\/4,[dir='ltr'].md\:ltr\:-top-1\/4 {
    top: -25%
  }

  [dir='ltr'] .md\:ltr\:-right-1\/4,[dir='ltr'].md\:ltr\:-right-1\/4 {
    right: -25%
  }

  [dir='ltr'] .md\:ltr\:-bottom-1\/4,[dir='ltr'].md\:ltr\:-bottom-1\/4 {
    bottom: -25%
  }

  [dir='ltr'] .md\:ltr\:-left-1\/4,[dir='ltr'].md\:ltr\:-left-1\/4 {
    left: -25%
  }

  [dir='ltr'] .md\:ltr\:-top-2\/4,[dir='ltr'].md\:ltr\:-top-2\/4 {
    top: -50%
  }

  [dir='ltr'] .md\:ltr\:-right-2\/4,[dir='ltr'].md\:ltr\:-right-2\/4 {
    right: -50%
  }

  [dir='ltr'] .md\:ltr\:-bottom-2\/4,[dir='ltr'].md\:ltr\:-bottom-2\/4 {
    bottom: -50%
  }

  [dir='ltr'] .md\:ltr\:-left-2\/4,[dir='ltr'].md\:ltr\:-left-2\/4 {
    left: -50%
  }

  [dir='ltr'] .md\:ltr\:-top-3\/4,[dir='ltr'].md\:ltr\:-top-3\/4 {
    top: -75%
  }

  [dir='ltr'] .md\:ltr\:-right-3\/4,[dir='ltr'].md\:ltr\:-right-3\/4 {
    right: -75%
  }

  [dir='ltr'] .md\:ltr\:-bottom-3\/4,[dir='ltr'].md\:ltr\:-bottom-3\/4 {
    bottom: -75%
  }

  [dir='ltr'] .md\:ltr\:-left-3\/4,[dir='ltr'].md\:ltr\:-left-3\/4 {
    left: -75%
  }

  [dir='ltr'] .md\:ltr\:-top-full,[dir='ltr'].md\:ltr\:-top-full {
    top: -100%
  }

  [dir='ltr'] .md\:ltr\:-right-full,[dir='ltr'].md\:ltr\:-right-full {
    right: -100%
  }

  [dir='ltr'] .md\:ltr\:-bottom-full,[dir='ltr'].md\:ltr\:-bottom-full {
    bottom: -100%
  }

  [dir='ltr'] .md\:ltr\:-left-full,[dir='ltr'].md\:ltr\:-left-full {
    left: -100%
  }

  [dir='rtl'] .md\:rtl\:inset-0,[dir='rtl'].md\:rtl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  [dir='rtl'] .md\:rtl\:inset-1,[dir='rtl'].md\:rtl\:inset-1 {
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:inset-2,[dir='rtl'].md\:rtl\:inset-2 {
    top: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-3,[dir='rtl'].md\:rtl\:inset-3 {
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:inset-4,[dir='rtl'].md\:rtl\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-5,[dir='rtl'].md\:rtl\:inset-5 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:inset-6,[dir='rtl'].md\:rtl\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-7,[dir='rtl'].md\:rtl\:inset-7 {
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
    left: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:inset-8,[dir='rtl'].md\:rtl\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-9,[dir='rtl'].md\:rtl\:inset-9 {
    top: 0.9rem;
    right: 0.9rem;
    bottom: 0.9rem;
    left: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:inset-10,[dir='rtl'].md\:rtl\:inset-10 {
    top: 1.0rem;
    right: 1.0rem;
    bottom: 1.0rem;
    left: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:inset-12,[dir='rtl'].md\:rtl\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-14,[dir='rtl'].md\:rtl\:inset-14 {
    top: 1.4rem;
    right: 1.4rem;
    bottom: 1.4rem;
    left: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-16,[dir='rtl'].md\:rtl\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-20,[dir='rtl'].md\:rtl\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  [dir='rtl'] .md\:rtl\:inset-24,[dir='rtl'].md\:rtl\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-28,[dir='rtl'].md\:rtl\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-32,[dir='rtl'].md\:rtl\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-36,[dir='rtl'].md\:rtl\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-40,[dir='rtl'].md\:rtl\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  [dir='rtl'] .md\:rtl\:inset-44,[dir='rtl'].md\:rtl\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-48,[dir='rtl'].md\:rtl\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-52,[dir='rtl'].md\:rtl\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-56,[dir='rtl'].md\:rtl\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-60,[dir='rtl'].md\:rtl\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  [dir='rtl'] .md\:rtl\:inset-64,[dir='rtl'].md\:rtl\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-68,[dir='rtl'].md\:rtl\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-72,[dir='rtl'].md\:rtl\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-76,[dir='rtl'].md\:rtl\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-80,[dir='rtl'].md\:rtl\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  [dir='rtl'] .md\:rtl\:inset-84,[dir='rtl'].md\:rtl\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-88,[dir='rtl'].md\:rtl\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-92,[dir='rtl'].md\:rtl\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-96,[dir='rtl'].md\:rtl\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-128,[dir='rtl'].md\:rtl\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-136,[dir='rtl'].md\:rtl\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-160,[dir='rtl'].md\:rtl\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  [dir='rtl'] .md\:rtl\:inset-192,[dir='rtl'].md\:rtl\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-200,[dir='rtl'].md\:rtl\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  [dir='rtl'] .md\:rtl\:inset-208,[dir='rtl'].md\:rtl\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-216,[dir='rtl'].md\:rtl\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-224,[dir='rtl'].md\:rtl\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-256,[dir='rtl'].md\:rtl\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-288,[dir='rtl'].md\:rtl\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-320,[dir='rtl'].md\:rtl\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='rtl'] .md\:rtl\:inset-360,[dir='rtl'].md\:rtl\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  [dir='rtl'] .md\:rtl\:inset-384,[dir='rtl'].md\:rtl\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-400,[dir='rtl'].md\:rtl\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  [dir='rtl'] .md\:rtl\:inset-512,[dir='rtl'].md\:rtl\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-640,[dir='rtl'].md\:rtl\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='rtl'] .md\:rtl\:inset-auto,[dir='rtl'].md\:rtl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  [dir='rtl'] .md\:rtl\:inset-xs,[dir='rtl'].md\:rtl\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='rtl'] .md\:rtl\:inset-sm,[dir='rtl'].md\:rtl\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  [dir='rtl'] .md\:rtl\:inset-md,[dir='rtl'].md\:rtl\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='rtl'] .md\:rtl\:inset-lg,[dir='rtl'].md\:rtl\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  [dir='rtl'] .md\:rtl\:inset-xl,[dir='rtl'].md\:rtl\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  [dir='rtl'] .md\:rtl\:inset-2xl,[dir='rtl'].md\:rtl\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  [dir='rtl'] .md\:rtl\:inset-3xl,[dir='rtl'].md\:rtl\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  [dir='rtl'] .md\:rtl\:inset-4xl,[dir='rtl'].md\:rtl\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  [dir='rtl'] .md\:rtl\:inset-5xl,[dir='rtl'].md\:rtl\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  [dir='rtl'] .md\:rtl\:inset-px,[dir='rtl'].md\:rtl\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  [dir='rtl'] .md\:rtl\:inset-0\.5,[dir='rtl'].md\:rtl\:inset-0\.5 {
    top: 0.05rem;
    right: 0.05rem;
    bottom: 0.05rem;
    left: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:inset-1\.5,[dir='rtl'].md\:rtl\:inset-1\.5 {
    top: 0.15rem;
    right: 0.15rem;
    bottom: 0.15rem;
    left: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:inset-2\.5,[dir='rtl'].md\:rtl\:inset-2\.5 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:inset-3\.5,[dir='rtl'].md\:rtl\:inset-3\.5 {
    top: 0.35rem;
    right: 0.35rem;
    bottom: 0.35rem;
    left: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:-inset-1,[dir='rtl'].md\:rtl\:-inset-1 {
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem
  }

  [dir='rtl'] .md\:rtl\:-inset-2,[dir='rtl'].md\:rtl\:-inset-2 {
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-3,[dir='rtl'].md\:rtl\:-inset-3 {
    top: -0.3rem;
    right: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem
  }

  [dir='rtl'] .md\:rtl\:-inset-4,[dir='rtl'].md\:rtl\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-5,[dir='rtl'].md\:rtl\:-inset-5 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  [dir='rtl'] .md\:rtl\:-inset-6,[dir='rtl'].md\:rtl\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-7,[dir='rtl'].md\:rtl\:-inset-7 {
    top: -0.7rem;
    right: -0.7rem;
    bottom: -0.7rem;
    left: -0.7rem
  }

  [dir='rtl'] .md\:rtl\:-inset-8,[dir='rtl'].md\:rtl\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-9,[dir='rtl'].md\:rtl\:-inset-9 {
    top: -0.9rem;
    right: -0.9rem;
    bottom: -0.9rem;
    left: -0.9rem
  }

  [dir='rtl'] .md\:rtl\:-inset-10,[dir='rtl'].md\:rtl\:-inset-10 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  [dir='rtl'] .md\:rtl\:-inset-12,[dir='rtl'].md\:rtl\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-14,[dir='rtl'].md\:rtl\:-inset-14 {
    top: -1.4rem;
    right: -1.4rem;
    bottom: -1.4rem;
    left: -1.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-16,[dir='rtl'].md\:rtl\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-20,[dir='rtl'].md\:rtl\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-24,[dir='rtl'].md\:rtl\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-28,[dir='rtl'].md\:rtl\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-32,[dir='rtl'].md\:rtl\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-36,[dir='rtl'].md\:rtl\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-40,[dir='rtl'].md\:rtl\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-44,[dir='rtl'].md\:rtl\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-48,[dir='rtl'].md\:rtl\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-52,[dir='rtl'].md\:rtl\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-56,[dir='rtl'].md\:rtl\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-60,[dir='rtl'].md\:rtl\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-64,[dir='rtl'].md\:rtl\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-68,[dir='rtl'].md\:rtl\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-72,[dir='rtl'].md\:rtl\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-76,[dir='rtl'].md\:rtl\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-80,[dir='rtl'].md\:rtl\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-84,[dir='rtl'].md\:rtl\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-88,[dir='rtl'].md\:rtl\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-92,[dir='rtl'].md\:rtl\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-96,[dir='rtl'].md\:rtl\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-128,[dir='rtl'].md\:rtl\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-136,[dir='rtl'].md\:rtl\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-160,[dir='rtl'].md\:rtl\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  [dir='rtl'] .md\:rtl\:-inset-192,[dir='rtl'].md\:rtl\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-200,[dir='rtl'].md\:rtl\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  [dir='rtl'] .md\:rtl\:-inset-208,[dir='rtl'].md\:rtl\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-216,[dir='rtl'].md\:rtl\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-224,[dir='rtl'].md\:rtl\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-256,[dir='rtl'].md\:rtl\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-288,[dir='rtl'].md\:rtl\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-320,[dir='rtl'].md\:rtl\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='rtl'] .md\:rtl\:-inset-360,[dir='rtl'].md\:rtl\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  [dir='rtl'] .md\:rtl\:-inset-384,[dir='rtl'].md\:rtl\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-400,[dir='rtl'].md\:rtl\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  [dir='rtl'] .md\:rtl\:-inset-512,[dir='rtl'].md\:rtl\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-640,[dir='rtl'].md\:rtl\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='rtl'] .md\:rtl\:-inset-xs,[dir='rtl'].md\:rtl\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='rtl'] .md\:rtl\:-inset-sm,[dir='rtl'].md\:rtl\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  [dir='rtl'] .md\:rtl\:-inset-md,[dir='rtl'].md\:rtl\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='rtl'] .md\:rtl\:-inset-lg,[dir='rtl'].md\:rtl\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  [dir='rtl'] .md\:rtl\:-inset-xl,[dir='rtl'].md\:rtl\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  [dir='rtl'] .md\:rtl\:-inset-2xl,[dir='rtl'].md\:rtl\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  [dir='rtl'] .md\:rtl\:-inset-3xl,[dir='rtl'].md\:rtl\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  [dir='rtl'] .md\:rtl\:-inset-4xl,[dir='rtl'].md\:rtl\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  [dir='rtl'] .md\:rtl\:-inset-5xl,[dir='rtl'].md\:rtl\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  [dir='rtl'] .md\:rtl\:-inset-px,[dir='rtl'].md\:rtl\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  [dir='rtl'] .md\:rtl\:-inset-0\.5,[dir='rtl'].md\:rtl\:-inset-0\.5 {
    top: -0.05rem;
    right: -0.05rem;
    bottom: -0.05rem;
    left: -0.05rem
  }

  [dir='rtl'] .md\:rtl\:-inset-1\.5,[dir='rtl'].md\:rtl\:-inset-1\.5 {
    top: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    left: -0.15rem
  }

  [dir='rtl'] .md\:rtl\:-inset-2\.5,[dir='rtl'].md\:rtl\:-inset-2\.5 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  [dir='rtl'] .md\:rtl\:-inset-3\.5,[dir='rtl'].md\:rtl\:-inset-3\.5 {
    top: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    left: -0.35rem
  }

  [dir='rtl'] .md\:rtl\:inset-1\/2,[dir='rtl'].md\:rtl\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='rtl'] .md\:rtl\:inset-1\/3,[dir='rtl'].md\:rtl\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  [dir='rtl'] .md\:rtl\:inset-2\/3,[dir='rtl'].md\:rtl\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  [dir='rtl'] .md\:rtl\:inset-1\/4,[dir='rtl'].md\:rtl\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  [dir='rtl'] .md\:rtl\:inset-2\/4,[dir='rtl'].md\:rtl\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='rtl'] .md\:rtl\:inset-3\/4,[dir='rtl'].md\:rtl\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  [dir='rtl'] .md\:rtl\:inset-full,[dir='rtl'].md\:rtl\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  [dir='rtl'] .md\:rtl\:-inset-1\/2,[dir='rtl'].md\:rtl\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='rtl'] .md\:rtl\:-inset-1\/3,[dir='rtl'].md\:rtl\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  [dir='rtl'] .md\:rtl\:-inset-2\/3,[dir='rtl'].md\:rtl\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  [dir='rtl'] .md\:rtl\:-inset-1\/4,[dir='rtl'].md\:rtl\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  [dir='rtl'] .md\:rtl\:-inset-2\/4,[dir='rtl'].md\:rtl\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='rtl'] .md\:rtl\:-inset-3\/4,[dir='rtl'].md\:rtl\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  [dir='rtl'] .md\:rtl\:-inset-full,[dir='rtl'].md\:rtl\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  [dir='rtl'] .md\:rtl\:inset-y-0,[dir='rtl'].md\:rtl\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  [dir='rtl'] .md\:rtl\:inset-x-0,[dir='rtl'].md\:rtl\:inset-x-0 {
    right: 0;
    left: 0
  }

  [dir='rtl'] .md\:rtl\:inset-y-1,[dir='rtl'].md\:rtl\:inset-y-1 {
    top: 0.1rem;
    bottom: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-1,[dir='rtl'].md\:rtl\:inset-x-1 {
    right: 0.1rem;
    left: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-2,[dir='rtl'].md\:rtl\:inset-y-2 {
    top: 0.2rem;
    bottom: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-2,[dir='rtl'].md\:rtl\:inset-x-2 {
    right: 0.2rem;
    left: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-3,[dir='rtl'].md\:rtl\:inset-y-3 {
    top: 0.3rem;
    bottom: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-3,[dir='rtl'].md\:rtl\:inset-x-3 {
    right: 0.3rem;
    left: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-4,[dir='rtl'].md\:rtl\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-4,[dir='rtl'].md\:rtl\:inset-x-4 {
    right: 0.4rem;
    left: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-5,[dir='rtl'].md\:rtl\:inset-y-5 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-5,[dir='rtl'].md\:rtl\:inset-x-5 {
    right: 0.5rem;
    left: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-6,[dir='rtl'].md\:rtl\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-6,[dir='rtl'].md\:rtl\:inset-x-6 {
    right: 0.6rem;
    left: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-7,[dir='rtl'].md\:rtl\:inset-y-7 {
    top: 0.7rem;
    bottom: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-7,[dir='rtl'].md\:rtl\:inset-x-7 {
    right: 0.7rem;
    left: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-8,[dir='rtl'].md\:rtl\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-8,[dir='rtl'].md\:rtl\:inset-x-8 {
    right: 0.8rem;
    left: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-9,[dir='rtl'].md\:rtl\:inset-y-9 {
    top: 0.9rem;
    bottom: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-9,[dir='rtl'].md\:rtl\:inset-x-9 {
    right: 0.9rem;
    left: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-10,[dir='rtl'].md\:rtl\:inset-y-10 {
    top: 1.0rem;
    bottom: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-10,[dir='rtl'].md\:rtl\:inset-x-10 {
    right: 1.0rem;
    left: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-12,[dir='rtl'].md\:rtl\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-12,[dir='rtl'].md\:rtl\:inset-x-12 {
    right: 1.2rem;
    left: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-14,[dir='rtl'].md\:rtl\:inset-y-14 {
    top: 1.4rem;
    bottom: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-14,[dir='rtl'].md\:rtl\:inset-x-14 {
    right: 1.4rem;
    left: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-16,[dir='rtl'].md\:rtl\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-16,[dir='rtl'].md\:rtl\:inset-x-16 {
    right: 1.6rem;
    left: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-20,[dir='rtl'].md\:rtl\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-20,[dir='rtl'].md\:rtl\:inset-x-20 {
    right: 2rem;
    left: 2rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-24,[dir='rtl'].md\:rtl\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-24,[dir='rtl'].md\:rtl\:inset-x-24 {
    right: 2.4rem;
    left: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-28,[dir='rtl'].md\:rtl\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-28,[dir='rtl'].md\:rtl\:inset-x-28 {
    right: 2.8rem;
    left: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-32,[dir='rtl'].md\:rtl\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-32,[dir='rtl'].md\:rtl\:inset-x-32 {
    right: 3.2rem;
    left: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-36,[dir='rtl'].md\:rtl\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-36,[dir='rtl'].md\:rtl\:inset-x-36 {
    right: 3.6rem;
    left: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-40,[dir='rtl'].md\:rtl\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-40,[dir='rtl'].md\:rtl\:inset-x-40 {
    right: 4rem;
    left: 4rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-44,[dir='rtl'].md\:rtl\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-44,[dir='rtl'].md\:rtl\:inset-x-44 {
    right: 4.4rem;
    left: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-48,[dir='rtl'].md\:rtl\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-48,[dir='rtl'].md\:rtl\:inset-x-48 {
    right: 4.8rem;
    left: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-52,[dir='rtl'].md\:rtl\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-52,[dir='rtl'].md\:rtl\:inset-x-52 {
    right: 5.2rem;
    left: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-56,[dir='rtl'].md\:rtl\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-56,[dir='rtl'].md\:rtl\:inset-x-56 {
    right: 5.6rem;
    left: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-60,[dir='rtl'].md\:rtl\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-60,[dir='rtl'].md\:rtl\:inset-x-60 {
    right: 6rem;
    left: 6rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-64,[dir='rtl'].md\:rtl\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-64,[dir='rtl'].md\:rtl\:inset-x-64 {
    right: 6.4rem;
    left: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-68,[dir='rtl'].md\:rtl\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-68,[dir='rtl'].md\:rtl\:inset-x-68 {
    right: 6.8rem;
    left: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-72,[dir='rtl'].md\:rtl\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-72,[dir='rtl'].md\:rtl\:inset-x-72 {
    right: 7.2rem;
    left: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-76,[dir='rtl'].md\:rtl\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-76,[dir='rtl'].md\:rtl\:inset-x-76 {
    right: 7.6rem;
    left: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-80,[dir='rtl'].md\:rtl\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-80,[dir='rtl'].md\:rtl\:inset-x-80 {
    right: 8rem;
    left: 8rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-84,[dir='rtl'].md\:rtl\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-84,[dir='rtl'].md\:rtl\:inset-x-84 {
    right: 8.4rem;
    left: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-88,[dir='rtl'].md\:rtl\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-88,[dir='rtl'].md\:rtl\:inset-x-88 {
    right: 8.8rem;
    left: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-92,[dir='rtl'].md\:rtl\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-92,[dir='rtl'].md\:rtl\:inset-x-92 {
    right: 9.2rem;
    left: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-96,[dir='rtl'].md\:rtl\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-96,[dir='rtl'].md\:rtl\:inset-x-96 {
    right: 9.6rem;
    left: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-128,[dir='rtl'].md\:rtl\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-128,[dir='rtl'].md\:rtl\:inset-x-128 {
    right: 12.8rem;
    left: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-136,[dir='rtl'].md\:rtl\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-136,[dir='rtl'].md\:rtl\:inset-x-136 {
    right: 13.6rem;
    left: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-160,[dir='rtl'].md\:rtl\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-160,[dir='rtl'].md\:rtl\:inset-x-160 {
    right: 16rem;
    left: 16rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-192,[dir='rtl'].md\:rtl\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-192,[dir='rtl'].md\:rtl\:inset-x-192 {
    right: 19.2rem;
    left: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-200,[dir='rtl'].md\:rtl\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-200,[dir='rtl'].md\:rtl\:inset-x-200 {
    right: 20rem;
    left: 20rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-208,[dir='rtl'].md\:rtl\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-208,[dir='rtl'].md\:rtl\:inset-x-208 {
    right: 20.8rem;
    left: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-216,[dir='rtl'].md\:rtl\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-216,[dir='rtl'].md\:rtl\:inset-x-216 {
    right: 21.6rem;
    left: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-224,[dir='rtl'].md\:rtl\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-224,[dir='rtl'].md\:rtl\:inset-x-224 {
    right: 22.4rem;
    left: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-256,[dir='rtl'].md\:rtl\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-256,[dir='rtl'].md\:rtl\:inset-x-256 {
    right: 25.6rem;
    left: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-288,[dir='rtl'].md\:rtl\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-288,[dir='rtl'].md\:rtl\:inset-x-288 {
    right: 28.8rem;
    left: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-320,[dir='rtl'].md\:rtl\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-320,[dir='rtl'].md\:rtl\:inset-x-320 {
    right: 32rem;
    left: 32rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-360,[dir='rtl'].md\:rtl\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-360,[dir='rtl'].md\:rtl\:inset-x-360 {
    right: 36rem;
    left: 36rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-384,[dir='rtl'].md\:rtl\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-384,[dir='rtl'].md\:rtl\:inset-x-384 {
    right: 38.4rem;
    left: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-400,[dir='rtl'].md\:rtl\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-400,[dir='rtl'].md\:rtl\:inset-x-400 {
    right: 40rem;
    left: 40rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-512,[dir='rtl'].md\:rtl\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-512,[dir='rtl'].md\:rtl\:inset-x-512 {
    right: 51.2rem;
    left: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-640,[dir='rtl'].md\:rtl\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-640,[dir='rtl'].md\:rtl\:inset-x-640 {
    right: 64rem;
    left: 64rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-auto,[dir='rtl'].md\:rtl\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  [dir='rtl'] .md\:rtl\:inset-x-auto,[dir='rtl'].md\:rtl\:inset-x-auto {
    right: auto;
    left: auto
  }

  [dir='rtl'] .md\:rtl\:inset-y-xs,[dir='rtl'].md\:rtl\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-xs,[dir='rtl'].md\:rtl\:inset-x-xs {
    right: 32rem;
    left: 32rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-sm,[dir='rtl'].md\:rtl\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-sm,[dir='rtl'].md\:rtl\:inset-x-sm {
    right: 48rem;
    left: 48rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-md,[dir='rtl'].md\:rtl\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-md,[dir='rtl'].md\:rtl\:inset-x-md {
    right: 64rem;
    left: 64rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-lg,[dir='rtl'].md\:rtl\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-lg,[dir='rtl'].md\:rtl\:inset-x-lg {
    right: 80rem;
    left: 80rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-xl,[dir='rtl'].md\:rtl\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-xl,[dir='rtl'].md\:rtl\:inset-x-xl {
    right: 96rem;
    left: 96rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-2xl,[dir='rtl'].md\:rtl\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-2xl,[dir='rtl'].md\:rtl\:inset-x-2xl {
    right: 112rem;
    left: 112rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-3xl,[dir='rtl'].md\:rtl\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-3xl,[dir='rtl'].md\:rtl\:inset-x-3xl {
    right: 128rem;
    left: 128rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-4xl,[dir='rtl'].md\:rtl\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-4xl,[dir='rtl'].md\:rtl\:inset-x-4xl {
    right: 144rem;
    left: 144rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-5xl,[dir='rtl'].md\:rtl\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-5xl,[dir='rtl'].md\:rtl\:inset-x-5xl {
    right: 160rem;
    left: 160rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-px,[dir='rtl'].md\:rtl\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  [dir='rtl'] .md\:rtl\:inset-x-px,[dir='rtl'].md\:rtl\:inset-x-px {
    right: 1px;
    left: 1px
  }

  [dir='rtl'] .md\:rtl\:inset-y-0\.5,[dir='rtl'].md\:rtl\:inset-y-0\.5 {
    top: 0.05rem;
    bottom: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-0\.5,[dir='rtl'].md\:rtl\:inset-x-0\.5 {
    right: 0.05rem;
    left: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-1\.5,[dir='rtl'].md\:rtl\:inset-y-1\.5 {
    top: 0.15rem;
    bottom: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-1\.5,[dir='rtl'].md\:rtl\:inset-x-1\.5 {
    right: 0.15rem;
    left: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-2\.5,[dir='rtl'].md\:rtl\:inset-y-2\.5 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-2\.5,[dir='rtl'].md\:rtl\:inset-x-2\.5 {
    right: 0.25rem;
    left: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-3\.5,[dir='rtl'].md\:rtl\:inset-y-3\.5 {
    top: 0.35rem;
    bottom: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:inset-x-3\.5,[dir='rtl'].md\:rtl\:inset-x-3\.5 {
    right: 0.35rem;
    left: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-1,[dir='rtl'].md\:rtl\:-inset-y-1 {
    top: -0.1rem;
    bottom: -0.1rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-1,[dir='rtl'].md\:rtl\:-inset-x-1 {
    right: -0.1rem;
    left: -0.1rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-2,[dir='rtl'].md\:rtl\:-inset-y-2 {
    top: -0.2rem;
    bottom: -0.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-2,[dir='rtl'].md\:rtl\:-inset-x-2 {
    right: -0.2rem;
    left: -0.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-3,[dir='rtl'].md\:rtl\:-inset-y-3 {
    top: -0.3rem;
    bottom: -0.3rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-3,[dir='rtl'].md\:rtl\:-inset-x-3 {
    right: -0.3rem;
    left: -0.3rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-4,[dir='rtl'].md\:rtl\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-4,[dir='rtl'].md\:rtl\:-inset-x-4 {
    right: -0.4rem;
    left: -0.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-5,[dir='rtl'].md\:rtl\:-inset-y-5 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-5,[dir='rtl'].md\:rtl\:-inset-x-5 {
    right: -0.5rem;
    left: -0.5rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-6,[dir='rtl'].md\:rtl\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-6,[dir='rtl'].md\:rtl\:-inset-x-6 {
    right: -0.6rem;
    left: -0.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-7,[dir='rtl'].md\:rtl\:-inset-y-7 {
    top: -0.7rem;
    bottom: -0.7rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-7,[dir='rtl'].md\:rtl\:-inset-x-7 {
    right: -0.7rem;
    left: -0.7rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-8,[dir='rtl'].md\:rtl\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-8,[dir='rtl'].md\:rtl\:-inset-x-8 {
    right: -0.8rem;
    left: -0.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-9,[dir='rtl'].md\:rtl\:-inset-y-9 {
    top: -0.9rem;
    bottom: -0.9rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-9,[dir='rtl'].md\:rtl\:-inset-x-9 {
    right: -0.9rem;
    left: -0.9rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-10,[dir='rtl'].md\:rtl\:-inset-y-10 {
    top: -1rem;
    bottom: -1rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-10,[dir='rtl'].md\:rtl\:-inset-x-10 {
    right: -1rem;
    left: -1rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-12,[dir='rtl'].md\:rtl\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-12,[dir='rtl'].md\:rtl\:-inset-x-12 {
    right: -1.2rem;
    left: -1.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-14,[dir='rtl'].md\:rtl\:-inset-y-14 {
    top: -1.4rem;
    bottom: -1.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-14,[dir='rtl'].md\:rtl\:-inset-x-14 {
    right: -1.4rem;
    left: -1.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-16,[dir='rtl'].md\:rtl\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-16,[dir='rtl'].md\:rtl\:-inset-x-16 {
    right: -1.6rem;
    left: -1.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-20,[dir='rtl'].md\:rtl\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-20,[dir='rtl'].md\:rtl\:-inset-x-20 {
    right: -2rem;
    left: -2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-24,[dir='rtl'].md\:rtl\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-24,[dir='rtl'].md\:rtl\:-inset-x-24 {
    right: -2.4rem;
    left: -2.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-28,[dir='rtl'].md\:rtl\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-28,[dir='rtl'].md\:rtl\:-inset-x-28 {
    right: -2.8rem;
    left: -2.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-32,[dir='rtl'].md\:rtl\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-32,[dir='rtl'].md\:rtl\:-inset-x-32 {
    right: -3.2rem;
    left: -3.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-36,[dir='rtl'].md\:rtl\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-36,[dir='rtl'].md\:rtl\:-inset-x-36 {
    right: -3.6rem;
    left: -3.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-40,[dir='rtl'].md\:rtl\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-40,[dir='rtl'].md\:rtl\:-inset-x-40 {
    right: -4rem;
    left: -4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-44,[dir='rtl'].md\:rtl\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-44,[dir='rtl'].md\:rtl\:-inset-x-44 {
    right: -4.4rem;
    left: -4.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-48,[dir='rtl'].md\:rtl\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-48,[dir='rtl'].md\:rtl\:-inset-x-48 {
    right: -4.8rem;
    left: -4.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-52,[dir='rtl'].md\:rtl\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-52,[dir='rtl'].md\:rtl\:-inset-x-52 {
    right: -5.2rem;
    left: -5.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-56,[dir='rtl'].md\:rtl\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-56,[dir='rtl'].md\:rtl\:-inset-x-56 {
    right: -5.6rem;
    left: -5.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-60,[dir='rtl'].md\:rtl\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-60,[dir='rtl'].md\:rtl\:-inset-x-60 {
    right: -6rem;
    left: -6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-64,[dir='rtl'].md\:rtl\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-64,[dir='rtl'].md\:rtl\:-inset-x-64 {
    right: -6.4rem;
    left: -6.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-68,[dir='rtl'].md\:rtl\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-68,[dir='rtl'].md\:rtl\:-inset-x-68 {
    right: -6.8rem;
    left: -6.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-72,[dir='rtl'].md\:rtl\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-72,[dir='rtl'].md\:rtl\:-inset-x-72 {
    right: -7.2rem;
    left: -7.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-76,[dir='rtl'].md\:rtl\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-76,[dir='rtl'].md\:rtl\:-inset-x-76 {
    right: -7.6rem;
    left: -7.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-80,[dir='rtl'].md\:rtl\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-80,[dir='rtl'].md\:rtl\:-inset-x-80 {
    right: -8rem;
    left: -8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-84,[dir='rtl'].md\:rtl\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-84,[dir='rtl'].md\:rtl\:-inset-x-84 {
    right: -8.4rem;
    left: -8.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-88,[dir='rtl'].md\:rtl\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-88,[dir='rtl'].md\:rtl\:-inset-x-88 {
    right: -8.8rem;
    left: -8.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-92,[dir='rtl'].md\:rtl\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-92,[dir='rtl'].md\:rtl\:-inset-x-92 {
    right: -9.2rem;
    left: -9.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-96,[dir='rtl'].md\:rtl\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-96,[dir='rtl'].md\:rtl\:-inset-x-96 {
    right: -9.6rem;
    left: -9.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-128,[dir='rtl'].md\:rtl\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-128,[dir='rtl'].md\:rtl\:-inset-x-128 {
    right: -12.8rem;
    left: -12.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-136,[dir='rtl'].md\:rtl\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-136,[dir='rtl'].md\:rtl\:-inset-x-136 {
    right: -13.6rem;
    left: -13.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-160,[dir='rtl'].md\:rtl\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-160,[dir='rtl'].md\:rtl\:-inset-x-160 {
    right: -16rem;
    left: -16rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-192,[dir='rtl'].md\:rtl\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-192,[dir='rtl'].md\:rtl\:-inset-x-192 {
    right: -19.2rem;
    left: -19.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-200,[dir='rtl'].md\:rtl\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-200,[dir='rtl'].md\:rtl\:-inset-x-200 {
    right: -20rem;
    left: -20rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-208,[dir='rtl'].md\:rtl\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-208,[dir='rtl'].md\:rtl\:-inset-x-208 {
    right: -20.8rem;
    left: -20.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-216,[dir='rtl'].md\:rtl\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-216,[dir='rtl'].md\:rtl\:-inset-x-216 {
    right: -21.6rem;
    left: -21.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-224,[dir='rtl'].md\:rtl\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-224,[dir='rtl'].md\:rtl\:-inset-x-224 {
    right: -22.4rem;
    left: -22.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-256,[dir='rtl'].md\:rtl\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-256,[dir='rtl'].md\:rtl\:-inset-x-256 {
    right: -25.6rem;
    left: -25.6rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-288,[dir='rtl'].md\:rtl\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-288,[dir='rtl'].md\:rtl\:-inset-x-288 {
    right: -28.8rem;
    left: -28.8rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-320,[dir='rtl'].md\:rtl\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-320,[dir='rtl'].md\:rtl\:-inset-x-320 {
    right: -32rem;
    left: -32rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-360,[dir='rtl'].md\:rtl\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-360,[dir='rtl'].md\:rtl\:-inset-x-360 {
    right: -36rem;
    left: -36rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-384,[dir='rtl'].md\:rtl\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-384,[dir='rtl'].md\:rtl\:-inset-x-384 {
    right: -38.4rem;
    left: -38.4rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-400,[dir='rtl'].md\:rtl\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-400,[dir='rtl'].md\:rtl\:-inset-x-400 {
    right: -40rem;
    left: -40rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-512,[dir='rtl'].md\:rtl\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-512,[dir='rtl'].md\:rtl\:-inset-x-512 {
    right: -51.2rem;
    left: -51.2rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-640,[dir='rtl'].md\:rtl\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-640,[dir='rtl'].md\:rtl\:-inset-x-640 {
    right: -64rem;
    left: -64rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-xs,[dir='rtl'].md\:rtl\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-xs,[dir='rtl'].md\:rtl\:-inset-x-xs {
    right: -32rem;
    left: -32rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-sm,[dir='rtl'].md\:rtl\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-sm,[dir='rtl'].md\:rtl\:-inset-x-sm {
    right: -48rem;
    left: -48rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-md,[dir='rtl'].md\:rtl\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-md,[dir='rtl'].md\:rtl\:-inset-x-md {
    right: -64rem;
    left: -64rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-lg,[dir='rtl'].md\:rtl\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-lg,[dir='rtl'].md\:rtl\:-inset-x-lg {
    right: -80rem;
    left: -80rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-xl,[dir='rtl'].md\:rtl\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-xl,[dir='rtl'].md\:rtl\:-inset-x-xl {
    right: -96rem;
    left: -96rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-2xl,[dir='rtl'].md\:rtl\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-2xl,[dir='rtl'].md\:rtl\:-inset-x-2xl {
    right: -112rem;
    left: -112rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-3xl,[dir='rtl'].md\:rtl\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-3xl,[dir='rtl'].md\:rtl\:-inset-x-3xl {
    right: -128rem;
    left: -128rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-4xl,[dir='rtl'].md\:rtl\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-4xl,[dir='rtl'].md\:rtl\:-inset-x-4xl {
    right: -144rem;
    left: -144rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-5xl,[dir='rtl'].md\:rtl\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-5xl,[dir='rtl'].md\:rtl\:-inset-x-5xl {
    right: -160rem;
    left: -160rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-px,[dir='rtl'].md\:rtl\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  [dir='rtl'] .md\:rtl\:-inset-x-px,[dir='rtl'].md\:rtl\:-inset-x-px {
    right: -1px;
    left: -1px
  }

  [dir='rtl'] .md\:rtl\:-inset-y-0\.5,[dir='rtl'].md\:rtl\:-inset-y-0\.5 {
    top: -0.05rem;
    bottom: -0.05rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-0\.5,[dir='rtl'].md\:rtl\:-inset-x-0\.5 {
    right: -0.05rem;
    left: -0.05rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-1\.5,[dir='rtl'].md\:rtl\:-inset-y-1\.5 {
    top: -0.15rem;
    bottom: -0.15rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-1\.5,[dir='rtl'].md\:rtl\:-inset-x-1\.5 {
    right: -0.15rem;
    left: -0.15rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-2\.5,[dir='rtl'].md\:rtl\:-inset-y-2\.5 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-2\.5,[dir='rtl'].md\:rtl\:-inset-x-2\.5 {
    right: -0.25rem;
    left: -0.25rem
  }

  [dir='rtl'] .md\:rtl\:-inset-y-3\.5,[dir='rtl'].md\:rtl\:-inset-y-3\.5 {
    top: -0.35rem;
    bottom: -0.35rem
  }

  [dir='rtl'] .md\:rtl\:-inset-x-3\.5,[dir='rtl'].md\:rtl\:-inset-x-3\.5 {
    right: -0.35rem;
    left: -0.35rem
  }

  [dir='rtl'] .md\:rtl\:inset-y-1\/2,[dir='rtl'].md\:rtl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  [dir='rtl'] .md\:rtl\:inset-x-1\/2,[dir='rtl'].md\:rtl\:inset-x-1\/2 {
    right: 50%;
    left: 50%
  }

  [dir='rtl'] .md\:rtl\:inset-y-1\/3,[dir='rtl'].md\:rtl\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  [dir='rtl'] .md\:rtl\:inset-x-1\/3,[dir='rtl'].md\:rtl\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%
  }

  [dir='rtl'] .md\:rtl\:inset-y-2\/3,[dir='rtl'].md\:rtl\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  [dir='rtl'] .md\:rtl\:inset-x-2\/3,[dir='rtl'].md\:rtl\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%
  }

  [dir='rtl'] .md\:rtl\:inset-y-1\/4,[dir='rtl'].md\:rtl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  [dir='rtl'] .md\:rtl\:inset-x-1\/4,[dir='rtl'].md\:rtl\:inset-x-1\/4 {
    right: 25%;
    left: 25%
  }

  [dir='rtl'] .md\:rtl\:inset-y-2\/4,[dir='rtl'].md\:rtl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  [dir='rtl'] .md\:rtl\:inset-x-2\/4,[dir='rtl'].md\:rtl\:inset-x-2\/4 {
    right: 50%;
    left: 50%
  }

  [dir='rtl'] .md\:rtl\:inset-y-3\/4,[dir='rtl'].md\:rtl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  [dir='rtl'] .md\:rtl\:inset-x-3\/4,[dir='rtl'].md\:rtl\:inset-x-3\/4 {
    right: 75%;
    left: 75%
  }

  [dir='rtl'] .md\:rtl\:inset-y-full,[dir='rtl'].md\:rtl\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  [dir='rtl'] .md\:rtl\:inset-x-full,[dir='rtl'].md\:rtl\:inset-x-full {
    right: 100%;
    left: 100%
  }

  [dir='rtl'] .md\:rtl\:-inset-y-1\/2,[dir='rtl'].md\:rtl\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  [dir='rtl'] .md\:rtl\:-inset-x-1\/2,[dir='rtl'].md\:rtl\:-inset-x-1\/2 {
    right: -50%;
    left: -50%
  }

  [dir='rtl'] .md\:rtl\:-inset-y-1\/3,[dir='rtl'].md\:rtl\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  [dir='rtl'] .md\:rtl\:-inset-x-1\/3,[dir='rtl'].md\:rtl\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%
  }

  [dir='rtl'] .md\:rtl\:-inset-y-2\/3,[dir='rtl'].md\:rtl\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  [dir='rtl'] .md\:rtl\:-inset-x-2\/3,[dir='rtl'].md\:rtl\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%
  }

  [dir='rtl'] .md\:rtl\:-inset-y-1\/4,[dir='rtl'].md\:rtl\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  [dir='rtl'] .md\:rtl\:-inset-x-1\/4,[dir='rtl'].md\:rtl\:-inset-x-1\/4 {
    right: -25%;
    left: -25%
  }

  [dir='rtl'] .md\:rtl\:-inset-y-2\/4,[dir='rtl'].md\:rtl\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  [dir='rtl'] .md\:rtl\:-inset-x-2\/4,[dir='rtl'].md\:rtl\:-inset-x-2\/4 {
    right: -50%;
    left: -50%
  }

  [dir='rtl'] .md\:rtl\:-inset-y-3\/4,[dir='rtl'].md\:rtl\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  [dir='rtl'] .md\:rtl\:-inset-x-3\/4,[dir='rtl'].md\:rtl\:-inset-x-3\/4 {
    right: -75%;
    left: -75%
  }

  [dir='rtl'] .md\:rtl\:-inset-y-full,[dir='rtl'].md\:rtl\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  [dir='rtl'] .md\:rtl\:-inset-x-full,[dir='rtl'].md\:rtl\:-inset-x-full {
    right: -100%;
    left: -100%
  }

  [dir='rtl'] .md\:rtl\:top-0,[dir='rtl'].md\:rtl\:top-0 {
    top: 0
  }

  [dir='rtl'] .md\:rtl\:right-0,[dir='rtl'].md\:rtl\:right-0 {
    right: 0
  }

  [dir='rtl'] .md\:rtl\:bottom-0,[dir='rtl'].md\:rtl\:bottom-0 {
    bottom: 0
  }

  [dir='rtl'] .md\:rtl\:left-0,[dir='rtl'].md\:rtl\:left-0 {
    left: 0
  }

  [dir='rtl'] .md\:rtl\:top-1,[dir='rtl'].md\:rtl\:top-1 {
    top: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:right-1,[dir='rtl'].md\:rtl\:right-1 {
    right: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:bottom-1,[dir='rtl'].md\:rtl\:bottom-1 {
    bottom: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:left-1,[dir='rtl'].md\:rtl\:left-1 {
    left: 0.1rem
  }

  [dir='rtl'] .md\:rtl\:top-2,[dir='rtl'].md\:rtl\:top-2 {
    top: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:right-2,[dir='rtl'].md\:rtl\:right-2 {
    right: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:bottom-2,[dir='rtl'].md\:rtl\:bottom-2 {
    bottom: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:left-2,[dir='rtl'].md\:rtl\:left-2 {
    left: 0.2rem
  }

  [dir='rtl'] .md\:rtl\:top-3,[dir='rtl'].md\:rtl\:top-3 {
    top: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:right-3,[dir='rtl'].md\:rtl\:right-3 {
    right: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:bottom-3,[dir='rtl'].md\:rtl\:bottom-3 {
    bottom: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:left-3,[dir='rtl'].md\:rtl\:left-3 {
    left: 0.3rem
  }

  [dir='rtl'] .md\:rtl\:top-4,[dir='rtl'].md\:rtl\:top-4 {
    top: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:right-4,[dir='rtl'].md\:rtl\:right-4 {
    right: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:bottom-4,[dir='rtl'].md\:rtl\:bottom-4 {
    bottom: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:left-4,[dir='rtl'].md\:rtl\:left-4 {
    left: 0.4rem
  }

  [dir='rtl'] .md\:rtl\:top-5,[dir='rtl'].md\:rtl\:top-5 {
    top: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:right-5,[dir='rtl'].md\:rtl\:right-5 {
    right: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:bottom-5,[dir='rtl'].md\:rtl\:bottom-5 {
    bottom: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:left-5,[dir='rtl'].md\:rtl\:left-5 {
    left: 0.5rem
  }

  [dir='rtl'] .md\:rtl\:top-6,[dir='rtl'].md\:rtl\:top-6 {
    top: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:right-6,[dir='rtl'].md\:rtl\:right-6 {
    right: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:bottom-6,[dir='rtl'].md\:rtl\:bottom-6 {
    bottom: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:left-6,[dir='rtl'].md\:rtl\:left-6 {
    left: 0.6rem
  }

  [dir='rtl'] .md\:rtl\:top-7,[dir='rtl'].md\:rtl\:top-7 {
    top: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:right-7,[dir='rtl'].md\:rtl\:right-7 {
    right: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:bottom-7,[dir='rtl'].md\:rtl\:bottom-7 {
    bottom: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:left-7,[dir='rtl'].md\:rtl\:left-7 {
    left: 0.7rem
  }

  [dir='rtl'] .md\:rtl\:top-8,[dir='rtl'].md\:rtl\:top-8 {
    top: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:right-8,[dir='rtl'].md\:rtl\:right-8 {
    right: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:bottom-8,[dir='rtl'].md\:rtl\:bottom-8 {
    bottom: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:left-8,[dir='rtl'].md\:rtl\:left-8 {
    left: 0.8rem
  }

  [dir='rtl'] .md\:rtl\:top-9,[dir='rtl'].md\:rtl\:top-9 {
    top: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:right-9,[dir='rtl'].md\:rtl\:right-9 {
    right: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:bottom-9,[dir='rtl'].md\:rtl\:bottom-9 {
    bottom: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:left-9,[dir='rtl'].md\:rtl\:left-9 {
    left: 0.9rem
  }

  [dir='rtl'] .md\:rtl\:top-10,[dir='rtl'].md\:rtl\:top-10 {
    top: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:right-10,[dir='rtl'].md\:rtl\:right-10 {
    right: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:bottom-10,[dir='rtl'].md\:rtl\:bottom-10 {
    bottom: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:left-10,[dir='rtl'].md\:rtl\:left-10 {
    left: 1.0rem
  }

  [dir='rtl'] .md\:rtl\:top-12,[dir='rtl'].md\:rtl\:top-12 {
    top: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:right-12,[dir='rtl'].md\:rtl\:right-12 {
    right: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:bottom-12,[dir='rtl'].md\:rtl\:bottom-12 {
    bottom: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:left-12,[dir='rtl'].md\:rtl\:left-12 {
    left: 1.2rem
  }

  [dir='rtl'] .md\:rtl\:top-14,[dir='rtl'].md\:rtl\:top-14 {
    top: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:right-14,[dir='rtl'].md\:rtl\:right-14 {
    right: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:bottom-14,[dir='rtl'].md\:rtl\:bottom-14 {
    bottom: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:left-14,[dir='rtl'].md\:rtl\:left-14 {
    left: 1.4rem
  }

  [dir='rtl'] .md\:rtl\:top-16,[dir='rtl'].md\:rtl\:top-16 {
    top: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:right-16,[dir='rtl'].md\:rtl\:right-16 {
    right: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:bottom-16,[dir='rtl'].md\:rtl\:bottom-16 {
    bottom: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:left-16,[dir='rtl'].md\:rtl\:left-16 {
    left: 1.6rem
  }

  [dir='rtl'] .md\:rtl\:top-20,[dir='rtl'].md\:rtl\:top-20 {
    top: 2rem
  }

  [dir='rtl'] .md\:rtl\:right-20,[dir='rtl'].md\:rtl\:right-20 {
    right: 2rem
  }

  [dir='rtl'] .md\:rtl\:bottom-20,[dir='rtl'].md\:rtl\:bottom-20 {
    bottom: 2rem
  }

  [dir='rtl'] .md\:rtl\:left-20,[dir='rtl'].md\:rtl\:left-20 {
    left: 2rem
  }

  [dir='rtl'] .md\:rtl\:top-24,[dir='rtl'].md\:rtl\:top-24 {
    top: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:right-24,[dir='rtl'].md\:rtl\:right-24 {
    right: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:bottom-24,[dir='rtl'].md\:rtl\:bottom-24 {
    bottom: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:left-24,[dir='rtl'].md\:rtl\:left-24 {
    left: 2.4rem
  }

  [dir='rtl'] .md\:rtl\:top-28,[dir='rtl'].md\:rtl\:top-28 {
    top: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:right-28,[dir='rtl'].md\:rtl\:right-28 {
    right: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:bottom-28,[dir='rtl'].md\:rtl\:bottom-28 {
    bottom: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:left-28,[dir='rtl'].md\:rtl\:left-28 {
    left: 2.8rem
  }

  [dir='rtl'] .md\:rtl\:top-32,[dir='rtl'].md\:rtl\:top-32 {
    top: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:right-32,[dir='rtl'].md\:rtl\:right-32 {
    right: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:bottom-32,[dir='rtl'].md\:rtl\:bottom-32 {
    bottom: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:left-32,[dir='rtl'].md\:rtl\:left-32 {
    left: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:top-36,[dir='rtl'].md\:rtl\:top-36 {
    top: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:right-36,[dir='rtl'].md\:rtl\:right-36 {
    right: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:bottom-36,[dir='rtl'].md\:rtl\:bottom-36 {
    bottom: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:left-36,[dir='rtl'].md\:rtl\:left-36 {
    left: 3.6rem
  }

  [dir='rtl'] .md\:rtl\:top-40,[dir='rtl'].md\:rtl\:top-40 {
    top: 4rem
  }

  [dir='rtl'] .md\:rtl\:right-40,[dir='rtl'].md\:rtl\:right-40 {
    right: 4rem
  }

  [dir='rtl'] .md\:rtl\:bottom-40,[dir='rtl'].md\:rtl\:bottom-40 {
    bottom: 4rem
  }

  [dir='rtl'] .md\:rtl\:left-40,[dir='rtl'].md\:rtl\:left-40 {
    left: 4rem
  }

  [dir='rtl'] .md\:rtl\:top-44,[dir='rtl'].md\:rtl\:top-44 {
    top: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:right-44,[dir='rtl'].md\:rtl\:right-44 {
    right: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:bottom-44,[dir='rtl'].md\:rtl\:bottom-44 {
    bottom: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:left-44,[dir='rtl'].md\:rtl\:left-44 {
    left: 4.4rem
  }

  [dir='rtl'] .md\:rtl\:top-48,[dir='rtl'].md\:rtl\:top-48 {
    top: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:right-48,[dir='rtl'].md\:rtl\:right-48 {
    right: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:bottom-48,[dir='rtl'].md\:rtl\:bottom-48 {
    bottom: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:left-48,[dir='rtl'].md\:rtl\:left-48 {
    left: 4.8rem
  }

  [dir='rtl'] .md\:rtl\:top-52,[dir='rtl'].md\:rtl\:top-52 {
    top: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:right-52,[dir='rtl'].md\:rtl\:right-52 {
    right: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:bottom-52,[dir='rtl'].md\:rtl\:bottom-52 {
    bottom: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:left-52,[dir='rtl'].md\:rtl\:left-52 {
    left: 5.2rem
  }

  [dir='rtl'] .md\:rtl\:top-56,[dir='rtl'].md\:rtl\:top-56 {
    top: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:right-56,[dir='rtl'].md\:rtl\:right-56 {
    right: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:bottom-56,[dir='rtl'].md\:rtl\:bottom-56 {
    bottom: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:left-56,[dir='rtl'].md\:rtl\:left-56 {
    left: 5.6rem
  }

  [dir='rtl'] .md\:rtl\:top-60,[dir='rtl'].md\:rtl\:top-60 {
    top: 6rem
  }

  [dir='rtl'] .md\:rtl\:right-60,[dir='rtl'].md\:rtl\:right-60 {
    right: 6rem
  }

  [dir='rtl'] .md\:rtl\:bottom-60,[dir='rtl'].md\:rtl\:bottom-60 {
    bottom: 6rem
  }

  [dir='rtl'] .md\:rtl\:left-60,[dir='rtl'].md\:rtl\:left-60 {
    left: 6rem
  }

  [dir='rtl'] .md\:rtl\:top-64,[dir='rtl'].md\:rtl\:top-64 {
    top: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:right-64,[dir='rtl'].md\:rtl\:right-64 {
    right: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:bottom-64,[dir='rtl'].md\:rtl\:bottom-64 {
    bottom: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:left-64,[dir='rtl'].md\:rtl\:left-64 {
    left: 6.4rem
  }

  [dir='rtl'] .md\:rtl\:top-68,[dir='rtl'].md\:rtl\:top-68 {
    top: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:right-68,[dir='rtl'].md\:rtl\:right-68 {
    right: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:bottom-68,[dir='rtl'].md\:rtl\:bottom-68 {
    bottom: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:left-68,[dir='rtl'].md\:rtl\:left-68 {
    left: 6.8rem
  }

  [dir='rtl'] .md\:rtl\:top-72,[dir='rtl'].md\:rtl\:top-72 {
    top: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:right-72,[dir='rtl'].md\:rtl\:right-72 {
    right: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:bottom-72,[dir='rtl'].md\:rtl\:bottom-72 {
    bottom: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:left-72,[dir='rtl'].md\:rtl\:left-72 {
    left: 7.2rem
  }

  [dir='rtl'] .md\:rtl\:top-76,[dir='rtl'].md\:rtl\:top-76 {
    top: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:right-76,[dir='rtl'].md\:rtl\:right-76 {
    right: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:bottom-76,[dir='rtl'].md\:rtl\:bottom-76 {
    bottom: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:left-76,[dir='rtl'].md\:rtl\:left-76 {
    left: 7.6rem
  }

  [dir='rtl'] .md\:rtl\:top-80,[dir='rtl'].md\:rtl\:top-80 {
    top: 8rem
  }

  [dir='rtl'] .md\:rtl\:right-80,[dir='rtl'].md\:rtl\:right-80 {
    right: 8rem
  }

  [dir='rtl'] .md\:rtl\:bottom-80,[dir='rtl'].md\:rtl\:bottom-80 {
    bottom: 8rem
  }

  [dir='rtl'] .md\:rtl\:left-80,[dir='rtl'].md\:rtl\:left-80 {
    left: 8rem
  }

  [dir='rtl'] .md\:rtl\:top-84,[dir='rtl'].md\:rtl\:top-84 {
    top: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:right-84,[dir='rtl'].md\:rtl\:right-84 {
    right: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:bottom-84,[dir='rtl'].md\:rtl\:bottom-84 {
    bottom: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:left-84,[dir='rtl'].md\:rtl\:left-84 {
    left: 8.4rem
  }

  [dir='rtl'] .md\:rtl\:top-88,[dir='rtl'].md\:rtl\:top-88 {
    top: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:right-88,[dir='rtl'].md\:rtl\:right-88 {
    right: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:bottom-88,[dir='rtl'].md\:rtl\:bottom-88 {
    bottom: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:left-88,[dir='rtl'].md\:rtl\:left-88 {
    left: 8.8rem
  }

  [dir='rtl'] .md\:rtl\:top-92,[dir='rtl'].md\:rtl\:top-92 {
    top: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:right-92,[dir='rtl'].md\:rtl\:right-92 {
    right: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:bottom-92,[dir='rtl'].md\:rtl\:bottom-92 {
    bottom: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:left-92,[dir='rtl'].md\:rtl\:left-92 {
    left: 9.2rem
  }

  [dir='rtl'] .md\:rtl\:top-96,[dir='rtl'].md\:rtl\:top-96 {
    top: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:right-96,[dir='rtl'].md\:rtl\:right-96 {
    right: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:bottom-96,[dir='rtl'].md\:rtl\:bottom-96 {
    bottom: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:left-96,[dir='rtl'].md\:rtl\:left-96 {
    left: 9.6rem
  }

  [dir='rtl'] .md\:rtl\:top-128,[dir='rtl'].md\:rtl\:top-128 {
    top: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:right-128,[dir='rtl'].md\:rtl\:right-128 {
    right: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:bottom-128,[dir='rtl'].md\:rtl\:bottom-128 {
    bottom: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:left-128,[dir='rtl'].md\:rtl\:left-128 {
    left: 12.8rem
  }

  [dir='rtl'] .md\:rtl\:top-136,[dir='rtl'].md\:rtl\:top-136 {
    top: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:right-136,[dir='rtl'].md\:rtl\:right-136 {
    right: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:bottom-136,[dir='rtl'].md\:rtl\:bottom-136 {
    bottom: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:left-136,[dir='rtl'].md\:rtl\:left-136 {
    left: 13.6rem
  }

  [dir='rtl'] .md\:rtl\:top-160,[dir='rtl'].md\:rtl\:top-160 {
    top: 16rem
  }

  [dir='rtl'] .md\:rtl\:right-160,[dir='rtl'].md\:rtl\:right-160 {
    right: 16rem
  }

  [dir='rtl'] .md\:rtl\:bottom-160,[dir='rtl'].md\:rtl\:bottom-160 {
    bottom: 16rem
  }

  [dir='rtl'] .md\:rtl\:left-160,[dir='rtl'].md\:rtl\:left-160 {
    left: 16rem
  }

  [dir='rtl'] .md\:rtl\:top-192,[dir='rtl'].md\:rtl\:top-192 {
    top: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:right-192,[dir='rtl'].md\:rtl\:right-192 {
    right: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:bottom-192,[dir='rtl'].md\:rtl\:bottom-192 {
    bottom: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:left-192,[dir='rtl'].md\:rtl\:left-192 {
    left: 19.2rem
  }

  [dir='rtl'] .md\:rtl\:top-200,[dir='rtl'].md\:rtl\:top-200 {
    top: 20rem
  }

  [dir='rtl'] .md\:rtl\:right-200,[dir='rtl'].md\:rtl\:right-200 {
    right: 20rem
  }

  [dir='rtl'] .md\:rtl\:bottom-200,[dir='rtl'].md\:rtl\:bottom-200 {
    bottom: 20rem
  }

  [dir='rtl'] .md\:rtl\:left-200,[dir='rtl'].md\:rtl\:left-200 {
    left: 20rem
  }

  [dir='rtl'] .md\:rtl\:top-208,[dir='rtl'].md\:rtl\:top-208 {
    top: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:right-208,[dir='rtl'].md\:rtl\:right-208 {
    right: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:bottom-208,[dir='rtl'].md\:rtl\:bottom-208 {
    bottom: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:left-208,[dir='rtl'].md\:rtl\:left-208 {
    left: 20.8rem
  }

  [dir='rtl'] .md\:rtl\:top-216,[dir='rtl'].md\:rtl\:top-216 {
    top: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:right-216,[dir='rtl'].md\:rtl\:right-216 {
    right: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:bottom-216,[dir='rtl'].md\:rtl\:bottom-216 {
    bottom: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:left-216,[dir='rtl'].md\:rtl\:left-216 {
    left: 21.6rem
  }

  [dir='rtl'] .md\:rtl\:top-224,[dir='rtl'].md\:rtl\:top-224 {
    top: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:right-224,[dir='rtl'].md\:rtl\:right-224 {
    right: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:bottom-224,[dir='rtl'].md\:rtl\:bottom-224 {
    bottom: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:left-224,[dir='rtl'].md\:rtl\:left-224 {
    left: 22.4rem
  }

  [dir='rtl'] .md\:rtl\:top-256,[dir='rtl'].md\:rtl\:top-256 {
    top: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:right-256,[dir='rtl'].md\:rtl\:right-256 {
    right: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:bottom-256,[dir='rtl'].md\:rtl\:bottom-256 {
    bottom: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:left-256,[dir='rtl'].md\:rtl\:left-256 {
    left: 25.6rem
  }

  [dir='rtl'] .md\:rtl\:top-288,[dir='rtl'].md\:rtl\:top-288 {
    top: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:right-288,[dir='rtl'].md\:rtl\:right-288 {
    right: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:bottom-288,[dir='rtl'].md\:rtl\:bottom-288 {
    bottom: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:left-288,[dir='rtl'].md\:rtl\:left-288 {
    left: 28.8rem
  }

  [dir='rtl'] .md\:rtl\:top-320,[dir='rtl'].md\:rtl\:top-320 {
    top: 32rem
  }

  [dir='rtl'] .md\:rtl\:right-320,[dir='rtl'].md\:rtl\:right-320 {
    right: 32rem
  }

  [dir='rtl'] .md\:rtl\:bottom-320,[dir='rtl'].md\:rtl\:bottom-320 {
    bottom: 32rem
  }

  [dir='rtl'] .md\:rtl\:left-320,[dir='rtl'].md\:rtl\:left-320 {
    left: 32rem
  }

  [dir='rtl'] .md\:rtl\:top-360,[dir='rtl'].md\:rtl\:top-360 {
    top: 36rem
  }

  [dir='rtl'] .md\:rtl\:right-360,[dir='rtl'].md\:rtl\:right-360 {
    right: 36rem
  }

  [dir='rtl'] .md\:rtl\:bottom-360,[dir='rtl'].md\:rtl\:bottom-360 {
    bottom: 36rem
  }

  [dir='rtl'] .md\:rtl\:left-360,[dir='rtl'].md\:rtl\:left-360 {
    left: 36rem
  }

  [dir='rtl'] .md\:rtl\:top-384,[dir='rtl'].md\:rtl\:top-384 {
    top: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:right-384,[dir='rtl'].md\:rtl\:right-384 {
    right: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:bottom-384,[dir='rtl'].md\:rtl\:bottom-384 {
    bottom: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:left-384,[dir='rtl'].md\:rtl\:left-384 {
    left: 38.4rem
  }

  [dir='rtl'] .md\:rtl\:top-400,[dir='rtl'].md\:rtl\:top-400 {
    top: 40rem
  }

  [dir='rtl'] .md\:rtl\:right-400,[dir='rtl'].md\:rtl\:right-400 {
    right: 40rem
  }

  [dir='rtl'] .md\:rtl\:bottom-400,[dir='rtl'].md\:rtl\:bottom-400 {
    bottom: 40rem
  }

  [dir='rtl'] .md\:rtl\:left-400,[dir='rtl'].md\:rtl\:left-400 {
    left: 40rem
  }

  [dir='rtl'] .md\:rtl\:top-512,[dir='rtl'].md\:rtl\:top-512 {
    top: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:right-512,[dir='rtl'].md\:rtl\:right-512 {
    right: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:bottom-512,[dir='rtl'].md\:rtl\:bottom-512 {
    bottom: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:left-512,[dir='rtl'].md\:rtl\:left-512 {
    left: 51.2rem
  }

  [dir='rtl'] .md\:rtl\:top-640,[dir='rtl'].md\:rtl\:top-640 {
    top: 64rem
  }

  [dir='rtl'] .md\:rtl\:right-640,[dir='rtl'].md\:rtl\:right-640 {
    right: 64rem
  }

  [dir='rtl'] .md\:rtl\:bottom-640,[dir='rtl'].md\:rtl\:bottom-640 {
    bottom: 64rem
  }

  [dir='rtl'] .md\:rtl\:left-640,[dir='rtl'].md\:rtl\:left-640 {
    left: 64rem
  }

  [dir='rtl'] .md\:rtl\:top-auto,[dir='rtl'].md\:rtl\:top-auto {
    top: auto
  }

  [dir='rtl'] .md\:rtl\:right-auto,[dir='rtl'].md\:rtl\:right-auto {
    right: auto
  }

  [dir='rtl'] .md\:rtl\:bottom-auto,[dir='rtl'].md\:rtl\:bottom-auto {
    bottom: auto
  }

  [dir='rtl'] .md\:rtl\:left-auto,[dir='rtl'].md\:rtl\:left-auto {
    left: auto
  }

  [dir='rtl'] .md\:rtl\:top-xs,[dir='rtl'].md\:rtl\:top-xs {
    top: 32rem
  }

  [dir='rtl'] .md\:rtl\:right-xs,[dir='rtl'].md\:rtl\:right-xs {
    right: 32rem
  }

  [dir='rtl'] .md\:rtl\:bottom-xs,[dir='rtl'].md\:rtl\:bottom-xs {
    bottom: 32rem
  }

  [dir='rtl'] .md\:rtl\:left-xs,[dir='rtl'].md\:rtl\:left-xs {
    left: 32rem
  }

  [dir='rtl'] .md\:rtl\:top-sm,[dir='rtl'].md\:rtl\:top-sm {
    top: 48rem
  }

  [dir='rtl'] .md\:rtl\:right-sm,[dir='rtl'].md\:rtl\:right-sm {
    right: 48rem
  }

  [dir='rtl'] .md\:rtl\:bottom-sm,[dir='rtl'].md\:rtl\:bottom-sm {
    bottom: 48rem
  }

  [dir='rtl'] .md\:rtl\:left-sm,[dir='rtl'].md\:rtl\:left-sm {
    left: 48rem
  }

  [dir='rtl'] .md\:rtl\:top-md,[dir='rtl'].md\:rtl\:top-md {
    top: 64rem
  }

  [dir='rtl'] .md\:rtl\:right-md,[dir='rtl'].md\:rtl\:right-md {
    right: 64rem
  }

  [dir='rtl'] .md\:rtl\:bottom-md,[dir='rtl'].md\:rtl\:bottom-md {
    bottom: 64rem
  }

  [dir='rtl'] .md\:rtl\:left-md,[dir='rtl'].md\:rtl\:left-md {
    left: 64rem
  }

  [dir='rtl'] .md\:rtl\:top-lg,[dir='rtl'].md\:rtl\:top-lg {
    top: 80rem
  }

  [dir='rtl'] .md\:rtl\:right-lg,[dir='rtl'].md\:rtl\:right-lg {
    right: 80rem
  }

  [dir='rtl'] .md\:rtl\:bottom-lg,[dir='rtl'].md\:rtl\:bottom-lg {
    bottom: 80rem
  }

  [dir='rtl'] .md\:rtl\:left-lg,[dir='rtl'].md\:rtl\:left-lg {
    left: 80rem
  }

  [dir='rtl'] .md\:rtl\:top-xl,[dir='rtl'].md\:rtl\:top-xl {
    top: 96rem
  }

  [dir='rtl'] .md\:rtl\:right-xl,[dir='rtl'].md\:rtl\:right-xl {
    right: 96rem
  }

  [dir='rtl'] .md\:rtl\:bottom-xl,[dir='rtl'].md\:rtl\:bottom-xl {
    bottom: 96rem
  }

  [dir='rtl'] .md\:rtl\:left-xl,[dir='rtl'].md\:rtl\:left-xl {
    left: 96rem
  }

  [dir='rtl'] .md\:rtl\:top-2xl,[dir='rtl'].md\:rtl\:top-2xl {
    top: 112rem
  }

  [dir='rtl'] .md\:rtl\:right-2xl,[dir='rtl'].md\:rtl\:right-2xl {
    right: 112rem
  }

  [dir='rtl'] .md\:rtl\:bottom-2xl,[dir='rtl'].md\:rtl\:bottom-2xl {
    bottom: 112rem
  }

  [dir='rtl'] .md\:rtl\:left-2xl,[dir='rtl'].md\:rtl\:left-2xl {
    left: 112rem
  }

  [dir='rtl'] .md\:rtl\:top-3xl,[dir='rtl'].md\:rtl\:top-3xl {
    top: 128rem
  }

  [dir='rtl'] .md\:rtl\:right-3xl,[dir='rtl'].md\:rtl\:right-3xl {
    right: 128rem
  }

  [dir='rtl'] .md\:rtl\:bottom-3xl,[dir='rtl'].md\:rtl\:bottom-3xl {
    bottom: 128rem
  }

  [dir='rtl'] .md\:rtl\:left-3xl,[dir='rtl'].md\:rtl\:left-3xl {
    left: 128rem
  }

  [dir='rtl'] .md\:rtl\:top-4xl,[dir='rtl'].md\:rtl\:top-4xl {
    top: 144rem
  }

  [dir='rtl'] .md\:rtl\:right-4xl,[dir='rtl'].md\:rtl\:right-4xl {
    right: 144rem
  }

  [dir='rtl'] .md\:rtl\:bottom-4xl,[dir='rtl'].md\:rtl\:bottom-4xl {
    bottom: 144rem
  }

  [dir='rtl'] .md\:rtl\:left-4xl,[dir='rtl'].md\:rtl\:left-4xl {
    left: 144rem
  }

  [dir='rtl'] .md\:rtl\:top-5xl,[dir='rtl'].md\:rtl\:top-5xl {
    top: 160rem
  }

  [dir='rtl'] .md\:rtl\:right-5xl,[dir='rtl'].md\:rtl\:right-5xl {
    right: 160rem
  }

  [dir='rtl'] .md\:rtl\:bottom-5xl,[dir='rtl'].md\:rtl\:bottom-5xl {
    bottom: 160rem
  }

  [dir='rtl'] .md\:rtl\:left-5xl,[dir='rtl'].md\:rtl\:left-5xl {
    left: 160rem
  }

  [dir='rtl'] .md\:rtl\:top-px,[dir='rtl'].md\:rtl\:top-px {
    top: 1px
  }

  [dir='rtl'] .md\:rtl\:right-px,[dir='rtl'].md\:rtl\:right-px {
    right: 1px
  }

  [dir='rtl'] .md\:rtl\:bottom-px,[dir='rtl'].md\:rtl\:bottom-px {
    bottom: 1px
  }

  [dir='rtl'] .md\:rtl\:left-px,[dir='rtl'].md\:rtl\:left-px {
    left: 1px
  }

  [dir='rtl'] .md\:rtl\:top-0\.5,[dir='rtl'].md\:rtl\:top-0\.5 {
    top: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:right-0\.5,[dir='rtl'].md\:rtl\:right-0\.5 {
    right: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:bottom-0\.5,[dir='rtl'].md\:rtl\:bottom-0\.5 {
    bottom: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:left-0\.5,[dir='rtl'].md\:rtl\:left-0\.5 {
    left: 0.05rem
  }

  [dir='rtl'] .md\:rtl\:top-1\.5,[dir='rtl'].md\:rtl\:top-1\.5 {
    top: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:right-1\.5,[dir='rtl'].md\:rtl\:right-1\.5 {
    right: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:bottom-1\.5,[dir='rtl'].md\:rtl\:bottom-1\.5 {
    bottom: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:left-1\.5,[dir='rtl'].md\:rtl\:left-1\.5 {
    left: 0.15rem
  }

  [dir='rtl'] .md\:rtl\:top-2\.5,[dir='rtl'].md\:rtl\:top-2\.5 {
    top: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:right-2\.5,[dir='rtl'].md\:rtl\:right-2\.5 {
    right: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:bottom-2\.5,[dir='rtl'].md\:rtl\:bottom-2\.5 {
    bottom: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:left-2\.5,[dir='rtl'].md\:rtl\:left-2\.5 {
    left: 0.25rem
  }

  [dir='rtl'] .md\:rtl\:top-3\.5,[dir='rtl'].md\:rtl\:top-3\.5 {
    top: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:right-3\.5,[dir='rtl'].md\:rtl\:right-3\.5 {
    right: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:bottom-3\.5,[dir='rtl'].md\:rtl\:bottom-3\.5 {
    bottom: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:left-3\.5,[dir='rtl'].md\:rtl\:left-3\.5 {
    left: 0.35rem
  }

  [dir='rtl'] .md\:rtl\:-top-1,[dir='rtl'].md\:rtl\:-top-1 {
    top: -0.1rem
  }

  [dir='rtl'] .md\:rtl\:-right-1,[dir='rtl'].md\:rtl\:-right-1 {
    right: -0.1rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-1,[dir='rtl'].md\:rtl\:-bottom-1 {
    bottom: -0.1rem
  }

  [dir='rtl'] .md\:rtl\:-left-1,[dir='rtl'].md\:rtl\:-left-1 {
    left: -0.1rem
  }

  [dir='rtl'] .md\:rtl\:-top-2,[dir='rtl'].md\:rtl\:-top-2 {
    top: -0.2rem
  }

  [dir='rtl'] .md\:rtl\:-right-2,[dir='rtl'].md\:rtl\:-right-2 {
    right: -0.2rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-2,[dir='rtl'].md\:rtl\:-bottom-2 {
    bottom: -0.2rem
  }

  [dir='rtl'] .md\:rtl\:-left-2,[dir='rtl'].md\:rtl\:-left-2 {
    left: -0.2rem
  }

  [dir='rtl'] .md\:rtl\:-top-3,[dir='rtl'].md\:rtl\:-top-3 {
    top: -0.3rem
  }

  [dir='rtl'] .md\:rtl\:-right-3,[dir='rtl'].md\:rtl\:-right-3 {
    right: -0.3rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-3,[dir='rtl'].md\:rtl\:-bottom-3 {
    bottom: -0.3rem
  }

  [dir='rtl'] .md\:rtl\:-left-3,[dir='rtl'].md\:rtl\:-left-3 {
    left: -0.3rem
  }

  [dir='rtl'] .md\:rtl\:-top-4,[dir='rtl'].md\:rtl\:-top-4 {
    top: -0.4rem
  }

  [dir='rtl'] .md\:rtl\:-right-4,[dir='rtl'].md\:rtl\:-right-4 {
    right: -0.4rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-4,[dir='rtl'].md\:rtl\:-bottom-4 {
    bottom: -0.4rem
  }

  [dir='rtl'] .md\:rtl\:-left-4,[dir='rtl'].md\:rtl\:-left-4 {
    left: -0.4rem
  }

  [dir='rtl'] .md\:rtl\:-top-5,[dir='rtl'].md\:rtl\:-top-5 {
    top: -0.5rem
  }

  [dir='rtl'] .md\:rtl\:-right-5,[dir='rtl'].md\:rtl\:-right-5 {
    right: -0.5rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-5,[dir='rtl'].md\:rtl\:-bottom-5 {
    bottom: -0.5rem
  }

  [dir='rtl'] .md\:rtl\:-left-5,[dir='rtl'].md\:rtl\:-left-5 {
    left: -0.5rem
  }

  [dir='rtl'] .md\:rtl\:-top-6,[dir='rtl'].md\:rtl\:-top-6 {
    top: -0.6rem
  }

  [dir='rtl'] .md\:rtl\:-right-6,[dir='rtl'].md\:rtl\:-right-6 {
    right: -0.6rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-6,[dir='rtl'].md\:rtl\:-bottom-6 {
    bottom: -0.6rem
  }

  [dir='rtl'] .md\:rtl\:-left-6,[dir='rtl'].md\:rtl\:-left-6 {
    left: -0.6rem
  }

  [dir='rtl'] .md\:rtl\:-top-7,[dir='rtl'].md\:rtl\:-top-7 {
    top: -0.7rem
  }

  [dir='rtl'] .md\:rtl\:-right-7,[dir='rtl'].md\:rtl\:-right-7 {
    right: -0.7rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-7,[dir='rtl'].md\:rtl\:-bottom-7 {
    bottom: -0.7rem
  }

  [dir='rtl'] .md\:rtl\:-left-7,[dir='rtl'].md\:rtl\:-left-7 {
    left: -0.7rem
  }

  [dir='rtl'] .md\:rtl\:-top-8,[dir='rtl'].md\:rtl\:-top-8 {
    top: -0.8rem
  }

  [dir='rtl'] .md\:rtl\:-right-8,[dir='rtl'].md\:rtl\:-right-8 {
    right: -0.8rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-8,[dir='rtl'].md\:rtl\:-bottom-8 {
    bottom: -0.8rem
  }

  [dir='rtl'] .md\:rtl\:-left-8,[dir='rtl'].md\:rtl\:-left-8 {
    left: -0.8rem
  }

  [dir='rtl'] .md\:rtl\:-top-9,[dir='rtl'].md\:rtl\:-top-9 {
    top: -0.9rem
  }

  [dir='rtl'] .md\:rtl\:-right-9,[dir='rtl'].md\:rtl\:-right-9 {
    right: -0.9rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-9,[dir='rtl'].md\:rtl\:-bottom-9 {
    bottom: -0.9rem
  }

  [dir='rtl'] .md\:rtl\:-left-9,[dir='rtl'].md\:rtl\:-left-9 {
    left: -0.9rem
  }

  [dir='rtl'] .md\:rtl\:-top-10,[dir='rtl'].md\:rtl\:-top-10 {
    top: -1rem
  }

  [dir='rtl'] .md\:rtl\:-right-10,[dir='rtl'].md\:rtl\:-right-10 {
    right: -1rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-10,[dir='rtl'].md\:rtl\:-bottom-10 {
    bottom: -1rem
  }

  [dir='rtl'] .md\:rtl\:-left-10,[dir='rtl'].md\:rtl\:-left-10 {
    left: -1rem
  }

  [dir='rtl'] .md\:rtl\:-top-12,[dir='rtl'].md\:rtl\:-top-12 {
    top: -1.2rem
  }

  [dir='rtl'] .md\:rtl\:-right-12,[dir='rtl'].md\:rtl\:-right-12 {
    right: -1.2rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-12,[dir='rtl'].md\:rtl\:-bottom-12 {
    bottom: -1.2rem
  }

  [dir='rtl'] .md\:rtl\:-left-12,[dir='rtl'].md\:rtl\:-left-12 {
    left: -1.2rem
  }

  [dir='rtl'] .md\:rtl\:-top-14,[dir='rtl'].md\:rtl\:-top-14 {
    top: -1.4rem
  }

  [dir='rtl'] .md\:rtl\:-right-14,[dir='rtl'].md\:rtl\:-right-14 {
    right: -1.4rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-14,[dir='rtl'].md\:rtl\:-bottom-14 {
    bottom: -1.4rem
  }

  [dir='rtl'] .md\:rtl\:-left-14,[dir='rtl'].md\:rtl\:-left-14 {
    left: -1.4rem
  }

  [dir='rtl'] .md\:rtl\:-top-16,[dir='rtl'].md\:rtl\:-top-16 {
    top: -1.6rem
  }

  [dir='rtl'] .md\:rtl\:-right-16,[dir='rtl'].md\:rtl\:-right-16 {
    right: -1.6rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-16,[dir='rtl'].md\:rtl\:-bottom-16 {
    bottom: -1.6rem
  }

  [dir='rtl'] .md\:rtl\:-left-16,[dir='rtl'].md\:rtl\:-left-16 {
    left: -1.6rem
  }

  [dir='rtl'] .md\:rtl\:-top-20,[dir='rtl'].md\:rtl\:-top-20 {
    top: -2rem
  }

  [dir='rtl'] .md\:rtl\:-right-20,[dir='rtl'].md\:rtl\:-right-20 {
    right: -2rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-20,[dir='rtl'].md\:rtl\:-bottom-20 {
    bottom: -2rem
  }

  [dir='rtl'] .md\:rtl\:-left-20,[dir='rtl'].md\:rtl\:-left-20 {
    left: -2rem
  }

  [dir='rtl'] .md\:rtl\:-top-24,[dir='rtl'].md\:rtl\:-top-24 {
    top: -2.4rem
  }

  [dir='rtl'] .md\:rtl\:-right-24,[dir='rtl'].md\:rtl\:-right-24 {
    right: -2.4rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-24,[dir='rtl'].md\:rtl\:-bottom-24 {
    bottom: -2.4rem
  }

  [dir='rtl'] .md\:rtl\:-left-24,[dir='rtl'].md\:rtl\:-left-24 {
    left: -2.4rem
  }

  [dir='rtl'] .md\:rtl\:-top-28,[dir='rtl'].md\:rtl\:-top-28 {
    top: -2.8rem
  }

  [dir='rtl'] .md\:rtl\:-right-28,[dir='rtl'].md\:rtl\:-right-28 {
    right: -2.8rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-28,[dir='rtl'].md\:rtl\:-bottom-28 {
    bottom: -2.8rem
  }

  [dir='rtl'] .md\:rtl\:-left-28,[dir='rtl'].md\:rtl\:-left-28 {
    left: -2.8rem
  }

  [dir='rtl'] .md\:rtl\:-top-32,[dir='rtl'].md\:rtl\:-top-32 {
    top: -3.2rem
  }

  [dir='rtl'] .md\:rtl\:-right-32,[dir='rtl'].md\:rtl\:-right-32 {
    right: -3.2rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-32,[dir='rtl'].md\:rtl\:-bottom-32 {
    bottom: -3.2rem
  }

  [dir='rtl'] .md\:rtl\:-left-32,[dir='rtl'].md\:rtl\:-left-32 {
    left: -3.2rem
  }

  [dir='rtl'] .md\:rtl\:-top-36,[dir='rtl'].md\:rtl\:-top-36 {
    top: -3.6rem
  }

  [dir='rtl'] .md\:rtl\:-right-36,[dir='rtl'].md\:rtl\:-right-36 {
    right: -3.6rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-36,[dir='rtl'].md\:rtl\:-bottom-36 {
    bottom: -3.6rem
  }

  [dir='rtl'] .md\:rtl\:-left-36,[dir='rtl'].md\:rtl\:-left-36 {
    left: -3.6rem
  }

  [dir='rtl'] .md\:rtl\:-top-40,[dir='rtl'].md\:rtl\:-top-40 {
    top: -4rem
  }

  [dir='rtl'] .md\:rtl\:-right-40,[dir='rtl'].md\:rtl\:-right-40 {
    right: -4rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-40,[dir='rtl'].md\:rtl\:-bottom-40 {
    bottom: -4rem
  }

  [dir='rtl'] .md\:rtl\:-left-40,[dir='rtl'].md\:rtl\:-left-40 {
    left: -4rem
  }

  [dir='rtl'] .md\:rtl\:-top-44,[dir='rtl'].md\:rtl\:-top-44 {
    top: -4.4rem
  }

  [dir='rtl'] .md\:rtl\:-right-44,[dir='rtl'].md\:rtl\:-right-44 {
    right: -4.4rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-44,[dir='rtl'].md\:rtl\:-bottom-44 {
    bottom: -4.4rem
  }

  [dir='rtl'] .md\:rtl\:-left-44,[dir='rtl'].md\:rtl\:-left-44 {
    left: -4.4rem
  }

  [dir='rtl'] .md\:rtl\:-top-48,[dir='rtl'].md\:rtl\:-top-48 {
    top: -4.8rem
  }

  [dir='rtl'] .md\:rtl\:-right-48,[dir='rtl'].md\:rtl\:-right-48 {
    right: -4.8rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-48,[dir='rtl'].md\:rtl\:-bottom-48 {
    bottom: -4.8rem
  }

  [dir='rtl'] .md\:rtl\:-left-48,[dir='rtl'].md\:rtl\:-left-48 {
    left: -4.8rem
  }

  [dir='rtl'] .md\:rtl\:-top-52,[dir='rtl'].md\:rtl\:-top-52 {
    top: -5.2rem
  }

  [dir='rtl'] .md\:rtl\:-right-52,[dir='rtl'].md\:rtl\:-right-52 {
    right: -5.2rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-52,[dir='rtl'].md\:rtl\:-bottom-52 {
    bottom: -5.2rem
  }

  [dir='rtl'] .md\:rtl\:-left-52,[dir='rtl'].md\:rtl\:-left-52 {
    left: -5.2rem
  }

  [dir='rtl'] .md\:rtl\:-top-56,[dir='rtl'].md\:rtl\:-top-56 {
    top: -5.6rem
  }

  [dir='rtl'] .md\:rtl\:-right-56,[dir='rtl'].md\:rtl\:-right-56 {
    right: -5.6rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-56,[dir='rtl'].md\:rtl\:-bottom-56 {
    bottom: -5.6rem
  }

  [dir='rtl'] .md\:rtl\:-left-56,[dir='rtl'].md\:rtl\:-left-56 {
    left: -5.6rem
  }

  [dir='rtl'] .md\:rtl\:-top-60,[dir='rtl'].md\:rtl\:-top-60 {
    top: -6rem
  }

  [dir='rtl'] .md\:rtl\:-right-60,[dir='rtl'].md\:rtl\:-right-60 {
    right: -6rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-60,[dir='rtl'].md\:rtl\:-bottom-60 {
    bottom: -6rem
  }

  [dir='rtl'] .md\:rtl\:-left-60,[dir='rtl'].md\:rtl\:-left-60 {
    left: -6rem
  }

  [dir='rtl'] .md\:rtl\:-top-64,[dir='rtl'].md\:rtl\:-top-64 {
    top: -6.4rem
  }

  [dir='rtl'] .md\:rtl\:-right-64,[dir='rtl'].md\:rtl\:-right-64 {
    right: -6.4rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-64,[dir='rtl'].md\:rtl\:-bottom-64 {
    bottom: -6.4rem
  }

  [dir='rtl'] .md\:rtl\:-left-64,[dir='rtl'].md\:rtl\:-left-64 {
    left: -6.4rem
  }

  [dir='rtl'] .md\:rtl\:-top-68,[dir='rtl'].md\:rtl\:-top-68 {
    top: -6.8rem
  }

  [dir='rtl'] .md\:rtl\:-right-68,[dir='rtl'].md\:rtl\:-right-68 {
    right: -6.8rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-68,[dir='rtl'].md\:rtl\:-bottom-68 {
    bottom: -6.8rem
  }

  [dir='rtl'] .md\:rtl\:-left-68,[dir='rtl'].md\:rtl\:-left-68 {
    left: -6.8rem
  }

  [dir='rtl'] .md\:rtl\:-top-72,[dir='rtl'].md\:rtl\:-top-72 {
    top: -7.2rem
  }

  [dir='rtl'] .md\:rtl\:-right-72,[dir='rtl'].md\:rtl\:-right-72 {
    right: -7.2rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-72,[dir='rtl'].md\:rtl\:-bottom-72 {
    bottom: -7.2rem
  }

  [dir='rtl'] .md\:rtl\:-left-72,[dir='rtl'].md\:rtl\:-left-72 {
    left: -7.2rem
  }

  [dir='rtl'] .md\:rtl\:-top-76,[dir='rtl'].md\:rtl\:-top-76 {
    top: -7.6rem
  }

  [dir='rtl'] .md\:rtl\:-right-76,[dir='rtl'].md\:rtl\:-right-76 {
    right: -7.6rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-76,[dir='rtl'].md\:rtl\:-bottom-76 {
    bottom: -7.6rem
  }

  [dir='rtl'] .md\:rtl\:-left-76,[dir='rtl'].md\:rtl\:-left-76 {
    left: -7.6rem
  }

  [dir='rtl'] .md\:rtl\:-top-80,[dir='rtl'].md\:rtl\:-top-80 {
    top: -8rem
  }

  [dir='rtl'] .md\:rtl\:-right-80,[dir='rtl'].md\:rtl\:-right-80 {
    right: -8rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-80,[dir='rtl'].md\:rtl\:-bottom-80 {
    bottom: -8rem
  }

  [dir='rtl'] .md\:rtl\:-left-80,[dir='rtl'].md\:rtl\:-left-80 {
    left: -8rem
  }

  [dir='rtl'] .md\:rtl\:-top-84,[dir='rtl'].md\:rtl\:-top-84 {
    top: -8.4rem
  }

  [dir='rtl'] .md\:rtl\:-right-84,[dir='rtl'].md\:rtl\:-right-84 {
    right: -8.4rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-84,[dir='rtl'].md\:rtl\:-bottom-84 {
    bottom: -8.4rem
  }

  [dir='rtl'] .md\:rtl\:-left-84,[dir='rtl'].md\:rtl\:-left-84 {
    left: -8.4rem
  }

  [dir='rtl'] .md\:rtl\:-top-88,[dir='rtl'].md\:rtl\:-top-88 {
    top: -8.8rem
  }

  [dir='rtl'] .md\:rtl\:-right-88,[dir='rtl'].md\:rtl\:-right-88 {
    right: -8.8rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-88,[dir='rtl'].md\:rtl\:-bottom-88 {
    bottom: -8.8rem
  }

  [dir='rtl'] .md\:rtl\:-left-88,[dir='rtl'].md\:rtl\:-left-88 {
    left: -8.8rem
  }

  [dir='rtl'] .md\:rtl\:-top-92,[dir='rtl'].md\:rtl\:-top-92 {
    top: -9.2rem
  }

  [dir='rtl'] .md\:rtl\:-right-92,[dir='rtl'].md\:rtl\:-right-92 {
    right: -9.2rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-92,[dir='rtl'].md\:rtl\:-bottom-92 {
    bottom: -9.2rem
  }

  [dir='rtl'] .md\:rtl\:-left-92,[dir='rtl'].md\:rtl\:-left-92 {
    left: -9.2rem
  }

  [dir='rtl'] .md\:rtl\:-top-96,[dir='rtl'].md\:rtl\:-top-96 {
    top: -9.6rem
  }

  [dir='rtl'] .md\:rtl\:-right-96,[dir='rtl'].md\:rtl\:-right-96 {
    right: -9.6rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-96,[dir='rtl'].md\:rtl\:-bottom-96 {
    bottom: -9.6rem
  }

  [dir='rtl'] .md\:rtl\:-left-96,[dir='rtl'].md\:rtl\:-left-96 {
    left: -9.6rem
  }

  [dir='rtl'] .md\:rtl\:-top-128,[dir='rtl'].md\:rtl\:-top-128 {
    top: -12.8rem
  }

  [dir='rtl'] .md\:rtl\:-right-128,[dir='rtl'].md\:rtl\:-right-128 {
    right: -12.8rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-128,[dir='rtl'].md\:rtl\:-bottom-128 {
    bottom: -12.8rem
  }

  [dir='rtl'] .md\:rtl\:-left-128,[dir='rtl'].md\:rtl\:-left-128 {
    left: -12.8rem
  }

  [dir='rtl'] .md\:rtl\:-top-136,[dir='rtl'].md\:rtl\:-top-136 {
    top: -13.6rem
  }

  [dir='rtl'] .md\:rtl\:-right-136,[dir='rtl'].md\:rtl\:-right-136 {
    right: -13.6rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-136,[dir='rtl'].md\:rtl\:-bottom-136 {
    bottom: -13.6rem
  }

  [dir='rtl'] .md\:rtl\:-left-136,[dir='rtl'].md\:rtl\:-left-136 {
    left: -13.6rem
  }

  [dir='rtl'] .md\:rtl\:-top-160,[dir='rtl'].md\:rtl\:-top-160 {
    top: -16rem
  }

  [dir='rtl'] .md\:rtl\:-right-160,[dir='rtl'].md\:rtl\:-right-160 {
    right: -16rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-160,[dir='rtl'].md\:rtl\:-bottom-160 {
    bottom: -16rem
  }

  [dir='rtl'] .md\:rtl\:-left-160,[dir='rtl'].md\:rtl\:-left-160 {
    left: -16rem
  }

  [dir='rtl'] .md\:rtl\:-top-192,[dir='rtl'].md\:rtl\:-top-192 {
    top: -19.2rem
  }

  [dir='rtl'] .md\:rtl\:-right-192,[dir='rtl'].md\:rtl\:-right-192 {
    right: -19.2rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-192,[dir='rtl'].md\:rtl\:-bottom-192 {
    bottom: -19.2rem
  }

  [dir='rtl'] .md\:rtl\:-left-192,[dir='rtl'].md\:rtl\:-left-192 {
    left: -19.2rem
  }

  [dir='rtl'] .md\:rtl\:-top-200,[dir='rtl'].md\:rtl\:-top-200 {
    top: -20rem
  }

  [dir='rtl'] .md\:rtl\:-right-200,[dir='rtl'].md\:rtl\:-right-200 {
    right: -20rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-200,[dir='rtl'].md\:rtl\:-bottom-200 {
    bottom: -20rem
  }

  [dir='rtl'] .md\:rtl\:-left-200,[dir='rtl'].md\:rtl\:-left-200 {
    left: -20rem
  }

  [dir='rtl'] .md\:rtl\:-top-208,[dir='rtl'].md\:rtl\:-top-208 {
    top: -20.8rem
  }

  [dir='rtl'] .md\:rtl\:-right-208,[dir='rtl'].md\:rtl\:-right-208 {
    right: -20.8rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-208,[dir='rtl'].md\:rtl\:-bottom-208 {
    bottom: -20.8rem
  }

  [dir='rtl'] .md\:rtl\:-left-208,[dir='rtl'].md\:rtl\:-left-208 {
    left: -20.8rem
  }

  [dir='rtl'] .md\:rtl\:-top-216,[dir='rtl'].md\:rtl\:-top-216 {
    top: -21.6rem
  }

  [dir='rtl'] .md\:rtl\:-right-216,[dir='rtl'].md\:rtl\:-right-216 {
    right: -21.6rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-216,[dir='rtl'].md\:rtl\:-bottom-216 {
    bottom: -21.6rem
  }

  [dir='rtl'] .md\:rtl\:-left-216,[dir='rtl'].md\:rtl\:-left-216 {
    left: -21.6rem
  }

  [dir='rtl'] .md\:rtl\:-top-224,[dir='rtl'].md\:rtl\:-top-224 {
    top: -22.4rem
  }

  [dir='rtl'] .md\:rtl\:-right-224,[dir='rtl'].md\:rtl\:-right-224 {
    right: -22.4rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-224,[dir='rtl'].md\:rtl\:-bottom-224 {
    bottom: -22.4rem
  }

  [dir='rtl'] .md\:rtl\:-left-224,[dir='rtl'].md\:rtl\:-left-224 {
    left: -22.4rem
  }

  [dir='rtl'] .md\:rtl\:-top-256,[dir='rtl'].md\:rtl\:-top-256 {
    top: -25.6rem
  }

  [dir='rtl'] .md\:rtl\:-right-256,[dir='rtl'].md\:rtl\:-right-256 {
    right: -25.6rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-256,[dir='rtl'].md\:rtl\:-bottom-256 {
    bottom: -25.6rem
  }

  [dir='rtl'] .md\:rtl\:-left-256,[dir='rtl'].md\:rtl\:-left-256 {
    left: -25.6rem
  }

  [dir='rtl'] .md\:rtl\:-top-288,[dir='rtl'].md\:rtl\:-top-288 {
    top: -28.8rem
  }

  [dir='rtl'] .md\:rtl\:-right-288,[dir='rtl'].md\:rtl\:-right-288 {
    right: -28.8rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-288,[dir='rtl'].md\:rtl\:-bottom-288 {
    bottom: -28.8rem
  }

  [dir='rtl'] .md\:rtl\:-left-288,[dir='rtl'].md\:rtl\:-left-288 {
    left: -28.8rem
  }

  [dir='rtl'] .md\:rtl\:-top-320,[dir='rtl'].md\:rtl\:-top-320 {
    top: -32rem
  }

  [dir='rtl'] .md\:rtl\:-right-320,[dir='rtl'].md\:rtl\:-right-320 {
    right: -32rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-320,[dir='rtl'].md\:rtl\:-bottom-320 {
    bottom: -32rem
  }

  [dir='rtl'] .md\:rtl\:-left-320,[dir='rtl'].md\:rtl\:-left-320 {
    left: -32rem
  }

  [dir='rtl'] .md\:rtl\:-top-360,[dir='rtl'].md\:rtl\:-top-360 {
    top: -36rem
  }

  [dir='rtl'] .md\:rtl\:-right-360,[dir='rtl'].md\:rtl\:-right-360 {
    right: -36rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-360,[dir='rtl'].md\:rtl\:-bottom-360 {
    bottom: -36rem
  }

  [dir='rtl'] .md\:rtl\:-left-360,[dir='rtl'].md\:rtl\:-left-360 {
    left: -36rem
  }

  [dir='rtl'] .md\:rtl\:-top-384,[dir='rtl'].md\:rtl\:-top-384 {
    top: -38.4rem
  }

  [dir='rtl'] .md\:rtl\:-right-384,[dir='rtl'].md\:rtl\:-right-384 {
    right: -38.4rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-384,[dir='rtl'].md\:rtl\:-bottom-384 {
    bottom: -38.4rem
  }

  [dir='rtl'] .md\:rtl\:-left-384,[dir='rtl'].md\:rtl\:-left-384 {
    left: -38.4rem
  }

  [dir='rtl'] .md\:rtl\:-top-400,[dir='rtl'].md\:rtl\:-top-400 {
    top: -40rem
  }

  [dir='rtl'] .md\:rtl\:-right-400,[dir='rtl'].md\:rtl\:-right-400 {
    right: -40rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-400,[dir='rtl'].md\:rtl\:-bottom-400 {
    bottom: -40rem
  }

  [dir='rtl'] .md\:rtl\:-left-400,[dir='rtl'].md\:rtl\:-left-400 {
    left: -40rem
  }

  [dir='rtl'] .md\:rtl\:-top-512,[dir='rtl'].md\:rtl\:-top-512 {
    top: -51.2rem
  }

  [dir='rtl'] .md\:rtl\:-right-512,[dir='rtl'].md\:rtl\:-right-512 {
    right: -51.2rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-512,[dir='rtl'].md\:rtl\:-bottom-512 {
    bottom: -51.2rem
  }

  [dir='rtl'] .md\:rtl\:-left-512,[dir='rtl'].md\:rtl\:-left-512 {
    left: -51.2rem
  }

  [dir='rtl'] .md\:rtl\:-top-640,[dir='rtl'].md\:rtl\:-top-640 {
    top: -64rem
  }

  [dir='rtl'] .md\:rtl\:-right-640,[dir='rtl'].md\:rtl\:-right-640 {
    right: -64rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-640,[dir='rtl'].md\:rtl\:-bottom-640 {
    bottom: -64rem
  }

  [dir='rtl'] .md\:rtl\:-left-640,[dir='rtl'].md\:rtl\:-left-640 {
    left: -64rem
  }

  [dir='rtl'] .md\:rtl\:-top-xs,[dir='rtl'].md\:rtl\:-top-xs {
    top: -32rem
  }

  [dir='rtl'] .md\:rtl\:-right-xs,[dir='rtl'].md\:rtl\:-right-xs {
    right: -32rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-xs,[dir='rtl'].md\:rtl\:-bottom-xs {
    bottom: -32rem
  }

  [dir='rtl'] .md\:rtl\:-left-xs,[dir='rtl'].md\:rtl\:-left-xs {
    left: -32rem
  }

  [dir='rtl'] .md\:rtl\:-top-sm,[dir='rtl'].md\:rtl\:-top-sm {
    top: -48rem
  }

  [dir='rtl'] .md\:rtl\:-right-sm,[dir='rtl'].md\:rtl\:-right-sm {
    right: -48rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-sm,[dir='rtl'].md\:rtl\:-bottom-sm {
    bottom: -48rem
  }

  [dir='rtl'] .md\:rtl\:-left-sm,[dir='rtl'].md\:rtl\:-left-sm {
    left: -48rem
  }

  [dir='rtl'] .md\:rtl\:-top-md,[dir='rtl'].md\:rtl\:-top-md {
    top: -64rem
  }

  [dir='rtl'] .md\:rtl\:-right-md,[dir='rtl'].md\:rtl\:-right-md {
    right: -64rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-md,[dir='rtl'].md\:rtl\:-bottom-md {
    bottom: -64rem
  }

  [dir='rtl'] .md\:rtl\:-left-md,[dir='rtl'].md\:rtl\:-left-md {
    left: -64rem
  }

  [dir='rtl'] .md\:rtl\:-top-lg,[dir='rtl'].md\:rtl\:-top-lg {
    top: -80rem
  }

  [dir='rtl'] .md\:rtl\:-right-lg,[dir='rtl'].md\:rtl\:-right-lg {
    right: -80rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-lg,[dir='rtl'].md\:rtl\:-bottom-lg {
    bottom: -80rem
  }

  [dir='rtl'] .md\:rtl\:-left-lg,[dir='rtl'].md\:rtl\:-left-lg {
    left: -80rem
  }

  [dir='rtl'] .md\:rtl\:-top-xl,[dir='rtl'].md\:rtl\:-top-xl {
    top: -96rem
  }

  [dir='rtl'] .md\:rtl\:-right-xl,[dir='rtl'].md\:rtl\:-right-xl {
    right: -96rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-xl,[dir='rtl'].md\:rtl\:-bottom-xl {
    bottom: -96rem
  }

  [dir='rtl'] .md\:rtl\:-left-xl,[dir='rtl'].md\:rtl\:-left-xl {
    left: -96rem
  }

  [dir='rtl'] .md\:rtl\:-top-2xl,[dir='rtl'].md\:rtl\:-top-2xl {
    top: -112rem
  }

  [dir='rtl'] .md\:rtl\:-right-2xl,[dir='rtl'].md\:rtl\:-right-2xl {
    right: -112rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-2xl,[dir='rtl'].md\:rtl\:-bottom-2xl {
    bottom: -112rem
  }

  [dir='rtl'] .md\:rtl\:-left-2xl,[dir='rtl'].md\:rtl\:-left-2xl {
    left: -112rem
  }

  [dir='rtl'] .md\:rtl\:-top-3xl,[dir='rtl'].md\:rtl\:-top-3xl {
    top: -128rem
  }

  [dir='rtl'] .md\:rtl\:-right-3xl,[dir='rtl'].md\:rtl\:-right-3xl {
    right: -128rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-3xl,[dir='rtl'].md\:rtl\:-bottom-3xl {
    bottom: -128rem
  }

  [dir='rtl'] .md\:rtl\:-left-3xl,[dir='rtl'].md\:rtl\:-left-3xl {
    left: -128rem
  }

  [dir='rtl'] .md\:rtl\:-top-4xl,[dir='rtl'].md\:rtl\:-top-4xl {
    top: -144rem
  }

  [dir='rtl'] .md\:rtl\:-right-4xl,[dir='rtl'].md\:rtl\:-right-4xl {
    right: -144rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-4xl,[dir='rtl'].md\:rtl\:-bottom-4xl {
    bottom: -144rem
  }

  [dir='rtl'] .md\:rtl\:-left-4xl,[dir='rtl'].md\:rtl\:-left-4xl {
    left: -144rem
  }

  [dir='rtl'] .md\:rtl\:-top-5xl,[dir='rtl'].md\:rtl\:-top-5xl {
    top: -160rem
  }

  [dir='rtl'] .md\:rtl\:-right-5xl,[dir='rtl'].md\:rtl\:-right-5xl {
    right: -160rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-5xl,[dir='rtl'].md\:rtl\:-bottom-5xl {
    bottom: -160rem
  }

  [dir='rtl'] .md\:rtl\:-left-5xl,[dir='rtl'].md\:rtl\:-left-5xl {
    left: -160rem
  }

  [dir='rtl'] .md\:rtl\:-top-px,[dir='rtl'].md\:rtl\:-top-px {
    top: -1px
  }

  [dir='rtl'] .md\:rtl\:-right-px,[dir='rtl'].md\:rtl\:-right-px {
    right: -1px
  }

  [dir='rtl'] .md\:rtl\:-bottom-px,[dir='rtl'].md\:rtl\:-bottom-px {
    bottom: -1px
  }

  [dir='rtl'] .md\:rtl\:-left-px,[dir='rtl'].md\:rtl\:-left-px {
    left: -1px
  }

  [dir='rtl'] .md\:rtl\:-top-0\.5,[dir='rtl'].md\:rtl\:-top-0\.5 {
    top: -0.05rem
  }

  [dir='rtl'] .md\:rtl\:-right-0\.5,[dir='rtl'].md\:rtl\:-right-0\.5 {
    right: -0.05rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-0\.5,[dir='rtl'].md\:rtl\:-bottom-0\.5 {
    bottom: -0.05rem
  }

  [dir='rtl'] .md\:rtl\:-left-0\.5,[dir='rtl'].md\:rtl\:-left-0\.5 {
    left: -0.05rem
  }

  [dir='rtl'] .md\:rtl\:-top-1\.5,[dir='rtl'].md\:rtl\:-top-1\.5 {
    top: -0.15rem
  }

  [dir='rtl'] .md\:rtl\:-right-1\.5,[dir='rtl'].md\:rtl\:-right-1\.5 {
    right: -0.15rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-1\.5,[dir='rtl'].md\:rtl\:-bottom-1\.5 {
    bottom: -0.15rem
  }

  [dir='rtl'] .md\:rtl\:-left-1\.5,[dir='rtl'].md\:rtl\:-left-1\.5 {
    left: -0.15rem
  }

  [dir='rtl'] .md\:rtl\:-top-2\.5,[dir='rtl'].md\:rtl\:-top-2\.5 {
    top: -0.25rem
  }

  [dir='rtl'] .md\:rtl\:-right-2\.5,[dir='rtl'].md\:rtl\:-right-2\.5 {
    right: -0.25rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-2\.5,[dir='rtl'].md\:rtl\:-bottom-2\.5 {
    bottom: -0.25rem
  }

  [dir='rtl'] .md\:rtl\:-left-2\.5,[dir='rtl'].md\:rtl\:-left-2\.5 {
    left: -0.25rem
  }

  [dir='rtl'] .md\:rtl\:-top-3\.5,[dir='rtl'].md\:rtl\:-top-3\.5 {
    top: -0.35rem
  }

  [dir='rtl'] .md\:rtl\:-right-3\.5,[dir='rtl'].md\:rtl\:-right-3\.5 {
    right: -0.35rem
  }

  [dir='rtl'] .md\:rtl\:-bottom-3\.5,[dir='rtl'].md\:rtl\:-bottom-3\.5 {
    bottom: -0.35rem
  }

  [dir='rtl'] .md\:rtl\:-left-3\.5,[dir='rtl'].md\:rtl\:-left-3\.5 {
    left: -0.35rem
  }

  [dir='rtl'] .md\:rtl\:top-1\/2,[dir='rtl'].md\:rtl\:top-1\/2 {
    top: 50%
  }

  [dir='rtl'] .md\:rtl\:right-1\/2,[dir='rtl'].md\:rtl\:right-1\/2 {
    right: 50%
  }

  [dir='rtl'] .md\:rtl\:bottom-1\/2,[dir='rtl'].md\:rtl\:bottom-1\/2 {
    bottom: 50%
  }

  [dir='rtl'] .md\:rtl\:left-1\/2,[dir='rtl'].md\:rtl\:left-1\/2 {
    left: 50%
  }

  [dir='rtl'] .md\:rtl\:top-1\/3,[dir='rtl'].md\:rtl\:top-1\/3 {
    top: 33.333333%
  }

  [dir='rtl'] .md\:rtl\:right-1\/3,[dir='rtl'].md\:rtl\:right-1\/3 {
    right: 33.333333%
  }

  [dir='rtl'] .md\:rtl\:bottom-1\/3,[dir='rtl'].md\:rtl\:bottom-1\/3 {
    bottom: 33.333333%
  }

  [dir='rtl'] .md\:rtl\:left-1\/3,[dir='rtl'].md\:rtl\:left-1\/3 {
    left: 33.333333%
  }

  [dir='rtl'] .md\:rtl\:top-2\/3,[dir='rtl'].md\:rtl\:top-2\/3 {
    top: 66.666667%
  }

  [dir='rtl'] .md\:rtl\:right-2\/3,[dir='rtl'].md\:rtl\:right-2\/3 {
    right: 66.666667%
  }

  [dir='rtl'] .md\:rtl\:bottom-2\/3,[dir='rtl'].md\:rtl\:bottom-2\/3 {
    bottom: 66.666667%
  }

  [dir='rtl'] .md\:rtl\:left-2\/3,[dir='rtl'].md\:rtl\:left-2\/3 {
    left: 66.666667%
  }

  [dir='rtl'] .md\:rtl\:top-1\/4,[dir='rtl'].md\:rtl\:top-1\/4 {
    top: 25%
  }

  [dir='rtl'] .md\:rtl\:right-1\/4,[dir='rtl'].md\:rtl\:right-1\/4 {
    right: 25%
  }

  [dir='rtl'] .md\:rtl\:bottom-1\/4,[dir='rtl'].md\:rtl\:bottom-1\/4 {
    bottom: 25%
  }

  [dir='rtl'] .md\:rtl\:left-1\/4,[dir='rtl'].md\:rtl\:left-1\/4 {
    left: 25%
  }

  [dir='rtl'] .md\:rtl\:top-2\/4,[dir='rtl'].md\:rtl\:top-2\/4 {
    top: 50%
  }

  [dir='rtl'] .md\:rtl\:right-2\/4,[dir='rtl'].md\:rtl\:right-2\/4 {
    right: 50%
  }

  [dir='rtl'] .md\:rtl\:bottom-2\/4,[dir='rtl'].md\:rtl\:bottom-2\/4 {
    bottom: 50%
  }

  [dir='rtl'] .md\:rtl\:left-2\/4,[dir='rtl'].md\:rtl\:left-2\/4 {
    left: 50%
  }

  [dir='rtl'] .md\:rtl\:top-3\/4,[dir='rtl'].md\:rtl\:top-3\/4 {
    top: 75%
  }

  [dir='rtl'] .md\:rtl\:right-3\/4,[dir='rtl'].md\:rtl\:right-3\/4 {
    right: 75%
  }

  [dir='rtl'] .md\:rtl\:bottom-3\/4,[dir='rtl'].md\:rtl\:bottom-3\/4 {
    bottom: 75%
  }

  [dir='rtl'] .md\:rtl\:left-3\/4,[dir='rtl'].md\:rtl\:left-3\/4 {
    left: 75%
  }

  [dir='rtl'] .md\:rtl\:top-full,[dir='rtl'].md\:rtl\:top-full {
    top: 100%
  }

  [dir='rtl'] .md\:rtl\:right-full,[dir='rtl'].md\:rtl\:right-full {
    right: 100%
  }

  [dir='rtl'] .md\:rtl\:bottom-full,[dir='rtl'].md\:rtl\:bottom-full {
    bottom: 100%
  }

  [dir='rtl'] .md\:rtl\:left-full,[dir='rtl'].md\:rtl\:left-full {
    left: 100%
  }

  [dir='rtl'] .md\:rtl\:-top-1\/2,[dir='rtl'].md\:rtl\:-top-1\/2 {
    top: -50%
  }

  [dir='rtl'] .md\:rtl\:-right-1\/2,[dir='rtl'].md\:rtl\:-right-1\/2 {
    right: -50%
  }

  [dir='rtl'] .md\:rtl\:-bottom-1\/2,[dir='rtl'].md\:rtl\:-bottom-1\/2 {
    bottom: -50%
  }

  [dir='rtl'] .md\:rtl\:-left-1\/2,[dir='rtl'].md\:rtl\:-left-1\/2 {
    left: -50%
  }

  [dir='rtl'] .md\:rtl\:-top-1\/3,[dir='rtl'].md\:rtl\:-top-1\/3 {
    top: -33.333333%
  }

  [dir='rtl'] .md\:rtl\:-right-1\/3,[dir='rtl'].md\:rtl\:-right-1\/3 {
    right: -33.333333%
  }

  [dir='rtl'] .md\:rtl\:-bottom-1\/3,[dir='rtl'].md\:rtl\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  [dir='rtl'] .md\:rtl\:-left-1\/3,[dir='rtl'].md\:rtl\:-left-1\/3 {
    left: -33.333333%
  }

  [dir='rtl'] .md\:rtl\:-top-2\/3,[dir='rtl'].md\:rtl\:-top-2\/3 {
    top: -66.666667%
  }

  [dir='rtl'] .md\:rtl\:-right-2\/3,[dir='rtl'].md\:rtl\:-right-2\/3 {
    right: -66.666667%
  }

  [dir='rtl'] .md\:rtl\:-bottom-2\/3,[dir='rtl'].md\:rtl\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  [dir='rtl'] .md\:rtl\:-left-2\/3,[dir='rtl'].md\:rtl\:-left-2\/3 {
    left: -66.666667%
  }

  [dir='rtl'] .md\:rtl\:-top-1\/4,[dir='rtl'].md\:rtl\:-top-1\/4 {
    top: -25%
  }

  [dir='rtl'] .md\:rtl\:-right-1\/4,[dir='rtl'].md\:rtl\:-right-1\/4 {
    right: -25%
  }

  [dir='rtl'] .md\:rtl\:-bottom-1\/4,[dir='rtl'].md\:rtl\:-bottom-1\/4 {
    bottom: -25%
  }

  [dir='rtl'] .md\:rtl\:-left-1\/4,[dir='rtl'].md\:rtl\:-left-1\/4 {
    left: -25%
  }

  [dir='rtl'] .md\:rtl\:-top-2\/4,[dir='rtl'].md\:rtl\:-top-2\/4 {
    top: -50%
  }

  [dir='rtl'] .md\:rtl\:-right-2\/4,[dir='rtl'].md\:rtl\:-right-2\/4 {
    right: -50%
  }

  [dir='rtl'] .md\:rtl\:-bottom-2\/4,[dir='rtl'].md\:rtl\:-bottom-2\/4 {
    bottom: -50%
  }

  [dir='rtl'] .md\:rtl\:-left-2\/4,[dir='rtl'].md\:rtl\:-left-2\/4 {
    left: -50%
  }

  [dir='rtl'] .md\:rtl\:-top-3\/4,[dir='rtl'].md\:rtl\:-top-3\/4 {
    top: -75%
  }

  [dir='rtl'] .md\:rtl\:-right-3\/4,[dir='rtl'].md\:rtl\:-right-3\/4 {
    right: -75%
  }

  [dir='rtl'] .md\:rtl\:-bottom-3\/4,[dir='rtl'].md\:rtl\:-bottom-3\/4 {
    bottom: -75%
  }

  [dir='rtl'] .md\:rtl\:-left-3\/4,[dir='rtl'].md\:rtl\:-left-3\/4 {
    left: -75%
  }

  [dir='rtl'] .md\:rtl\:-top-full,[dir='rtl'].md\:rtl\:-top-full {
    top: -100%
  }

  [dir='rtl'] .md\:rtl\:-right-full,[dir='rtl'].md\:rtl\:-right-full {
    right: -100%
  }

  [dir='rtl'] .md\:rtl\:-bottom-full,[dir='rtl'].md\:rtl\:-bottom-full {
    bottom: -100%
  }

  [dir='rtl'] .md\:rtl\:-left-full,[dir='rtl'].md\:rtl\:-left-full {
    left: -100%
  }

  .md\:resize-none {
    resize: none
  }

  .md\:resize-y {
    resize: vertical
  }

  .md\:resize-x {
    resize: horizontal
  }

  .md\:resize {
    resize: both
  }

  .md\:shadow-0 {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-1 {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-2 {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-3 {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-4 {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-5 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-6 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-7 {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-8 {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-9 {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-10 {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-11 {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-12 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-13 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-14 {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-15 {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-16 {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-17 {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-18 {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-19 {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-20 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-21 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-22 {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-23 {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-24 {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-0 {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-1 {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-2 {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-3 {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-4 {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-5 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-6 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-7 {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-8 {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-9 {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-10 {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-11 {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-12 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-13 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-14 {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-15 {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-16 {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-17 {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-18 {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-19 {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-20 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-21 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-22 {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-23 {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-24 {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-0:focus-within {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-1:focus-within {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-2:focus-within {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-3:focus-within {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-4:focus-within {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-5:focus-within {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-6:focus-within {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-7:focus-within {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-8:focus-within {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-9:focus-within {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-10:focus-within {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-11:focus-within {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-12:focus-within {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-13:focus-within {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-14:focus-within {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-15:focus-within {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-16:focus-within {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-17:focus-within {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-18:focus-within {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-19:focus-within {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-20:focus-within {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-21:focus-within {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-22:focus-within {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-23:focus-within {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-24:focus-within {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-0:hover {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-1:hover {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-2:hover {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-3:hover {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-4:hover {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-5:hover {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-6:hover {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-7:hover {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-8:hover {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-9:hover {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-10:hover {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-11:hover {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-12:hover {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-13:hover {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-14:hover {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-15:hover {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-16:hover {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-17:hover {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-18:hover {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-19:hover {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-20:hover {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-21:hover {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-22:hover {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-23:hover {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-24:hover {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-0:focus {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-1:focus {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-2:focus {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-3:focus {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-4:focus {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-5:focus {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-6:focus {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-7:focus {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-8:focus {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-9:focus {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-10:focus {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-11:focus {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-12:focus {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-13:focus {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-14:focus {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-15:focus {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-16:focus {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-17:focus {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-18:focus {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-19:focus {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-20:focus {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-21:focus {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-22:focus {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-23:focus {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-24:focus {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:ring-inset {
    --tw-ring-inset: inset
  }

  .md\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset
  }

  .md\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus\:ring-inset:focus {
    --tw-ring-inset: inset
  }

  .md\:ring-offset-transparent {
    --tw-ring-offset-color: transparent
  }

  .md\:ring-offset-current {
    --tw-ring-offset-color: currentColor
  }

  .md\:ring-offset-black {
    --tw-ring-offset-color: #22292F
  }

  .md\:ring-offset-white {
    --tw-ring-offset-color: #fff
  }

  .md\:ring-offset-grey-50 {
    --tw-ring-offset-color: #FAFAFA
  }

  .md\:ring-offset-grey-100 {
    --tw-ring-offset-color: #F5F5F5
  }

  .md\:ring-offset-grey-200 {
    --tw-ring-offset-color: #EEEEEE
  }

  .md\:ring-offset-grey-300 {
    --tw-ring-offset-color: #E0E0E0
  }

  .md\:ring-offset-grey-400 {
    --tw-ring-offset-color: #BDBDBD
  }

  .md\:ring-offset-grey-500 {
    --tw-ring-offset-color: #9E9E9E
  }

  .md\:ring-offset-grey-600 {
    --tw-ring-offset-color: #757575
  }

  .md\:ring-offset-grey-700 {
    --tw-ring-offset-color: #616161
  }

  .md\:ring-offset-grey-800 {
    --tw-ring-offset-color: #424242
  }

  .md\:ring-offset-grey-900 {
    --tw-ring-offset-color: #212121
  }

  .md\:ring-offset-grey {
    --tw-ring-offset-color: #9E9E9E
  }

  .md\:ring-offset-grey-A100 {
    --tw-ring-offset-color: #D5D5D5
  }

  .md\:ring-offset-grey-A200 {
    --tw-ring-offset-color: #AAAAAA
  }

  .md\:ring-offset-grey-A400 {
    --tw-ring-offset-color: #303030
  }

  .md\:ring-offset-grey-A700 {
    --tw-ring-offset-color: #616161
  }

  .md\:ring-offset-gray-50 {
    --tw-ring-offset-color: #FAFAFA
  }

  .md\:ring-offset-gray-100 {
    --tw-ring-offset-color: #F5F5F5
  }

  .md\:ring-offset-gray-200 {
    --tw-ring-offset-color: #EEEEEE
  }

  .md\:ring-offset-gray-300 {
    --tw-ring-offset-color: #E0E0E0
  }

  .md\:ring-offset-gray-400 {
    --tw-ring-offset-color: #BDBDBD
  }

  .md\:ring-offset-gray-500 {
    --tw-ring-offset-color: #9E9E9E
  }

  .md\:ring-offset-gray-600 {
    --tw-ring-offset-color: #757575
  }

  .md\:ring-offset-gray-700 {
    --tw-ring-offset-color: #616161
  }

  .md\:ring-offset-gray-800 {
    --tw-ring-offset-color: #424242
  }

  .md\:ring-offset-gray-900 {
    --tw-ring-offset-color: #212121
  }

  .md\:ring-offset-gray {
    --tw-ring-offset-color: #9E9E9E
  }

  .md\:ring-offset-gray-hover {
    --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
  }

  .md\:ring-offset-gray-A100 {
    --tw-ring-offset-color: #D5D5D5
  }

  .md\:ring-offset-gray-A200 {
    --tw-ring-offset-color: #AAAAAA
  }

  .md\:ring-offset-gray-A400 {
    --tw-ring-offset-color: #303030
  }

  .md\:ring-offset-gray-A700 {
    --tw-ring-offset-color: #616161
  }

  .md\:ring-offset-red-50 {
    --tw-ring-offset-color: #FFEBEE
  }

  .md\:ring-offset-red-100 {
    --tw-ring-offset-color: #FFCDD2
  }

  .md\:ring-offset-red-200 {
    --tw-ring-offset-color: #EF9A9A
  }

  .md\:ring-offset-red-300 {
    --tw-ring-offset-color: #E57373
  }

  .md\:ring-offset-red-400 {
    --tw-ring-offset-color: #EF5350
  }

  .md\:ring-offset-red-500 {
    --tw-ring-offset-color: #F44336
  }

  .md\:ring-offset-red-600 {
    --tw-ring-offset-color: #E53935
  }

  .md\:ring-offset-red-700 {
    --tw-ring-offset-color: #D32F2F
  }

  .md\:ring-offset-red-800 {
    --tw-ring-offset-color: #C62828
  }

  .md\:ring-offset-red-900 {
    --tw-ring-offset-color: #B71C1C
  }

  .md\:ring-offset-red {
    --tw-ring-offset-color: #F44336
  }

  .md\:ring-offset-red-A100 {
    --tw-ring-offset-color: #FF8A80
  }

  .md\:ring-offset-red-A200 {
    --tw-ring-offset-color: #FF5252
  }

  .md\:ring-offset-red-A400 {
    --tw-ring-offset-color: #FF1744
  }

  .md\:ring-offset-red-A700 {
    --tw-ring-offset-color: #D50000
  }

  .md\:ring-offset-orange-50 {
    --tw-ring-offset-color: #FFF3E0
  }

  .md\:ring-offset-orange-100 {
    --tw-ring-offset-color: #FFE0B2
  }

  .md\:ring-offset-orange-200 {
    --tw-ring-offset-color: #FFCC80
  }

  .md\:ring-offset-orange-300 {
    --tw-ring-offset-color: #FFB74D
  }

  .md\:ring-offset-orange-400 {
    --tw-ring-offset-color: #FFA726
  }

  .md\:ring-offset-orange-500 {
    --tw-ring-offset-color: #FF9800
  }

  .md\:ring-offset-orange-600 {
    --tw-ring-offset-color: #FB8C00
  }

  .md\:ring-offset-orange-700 {
    --tw-ring-offset-color: #F57C00
  }

  .md\:ring-offset-orange-800 {
    --tw-ring-offset-color: #EF6C00
  }

  .md\:ring-offset-orange-900 {
    --tw-ring-offset-color: #E65100
  }

  .md\:ring-offset-orange {
    --tw-ring-offset-color: #FF9800
  }

  .md\:ring-offset-orange-A100 {
    --tw-ring-offset-color: #FFD180
  }

  .md\:ring-offset-orange-A200 {
    --tw-ring-offset-color: #FFAB40
  }

  .md\:ring-offset-orange-A400 {
    --tw-ring-offset-color: #FF9100
  }

  .md\:ring-offset-orange-A700 {
    --tw-ring-offset-color: #FF6D00
  }

  .md\:ring-offset-deep-orange-50 {
    --tw-ring-offset-color: #FBE9E7
  }

  .md\:ring-offset-deep-orange-100 {
    --tw-ring-offset-color: #FFCCBC
  }

  .md\:ring-offset-deep-orange-200 {
    --tw-ring-offset-color: #FFAB91
  }

  .md\:ring-offset-deep-orange-300 {
    --tw-ring-offset-color: #FF8A65
  }

  .md\:ring-offset-deep-orange-400 {
    --tw-ring-offset-color: #FF7043
  }

  .md\:ring-offset-deep-orange-500 {
    --tw-ring-offset-color: #FF5722
  }

  .md\:ring-offset-deep-orange-600 {
    --tw-ring-offset-color: #F4511E
  }

  .md\:ring-offset-deep-orange-700 {
    --tw-ring-offset-color: #E64A19
  }

  .md\:ring-offset-deep-orange-800 {
    --tw-ring-offset-color: #D84315
  }

  .md\:ring-offset-deep-orange-900 {
    --tw-ring-offset-color: #BF360C
  }

  .md\:ring-offset-deep-orange {
    --tw-ring-offset-color: #FF5722
  }

  .md\:ring-offset-deep-orange-A100 {
    --tw-ring-offset-color: #FF9E80
  }

  .md\:ring-offset-deep-orange-A200 {
    --tw-ring-offset-color: #FF6E40
  }

  .md\:ring-offset-deep-orange-A400 {
    --tw-ring-offset-color: #FF3D00
  }

  .md\:ring-offset-deep-orange-A700 {
    --tw-ring-offset-color: #DD2C00
  }

  .md\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #FFFDE7
  }

  .md\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #FFF9C4
  }

  .md\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #FFF59D
  }

  .md\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #FFF176
  }

  .md\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #FFEE58
  }

  .md\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #FFEB3B
  }

  .md\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #FDD835
  }

  .md\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #FBC02D
  }

  .md\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #F9A825
  }

  .md\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #F57F17
  }

  .md\:ring-offset-yellow {
    --tw-ring-offset-color: #FFEB3B
  }

  .md\:ring-offset-yellow-A100 {
    --tw-ring-offset-color: #FFFF8D
  }

  .md\:ring-offset-yellow-A200 {
    --tw-ring-offset-color: #FFFF00
  }

  .md\:ring-offset-yellow-A400 {
    --tw-ring-offset-color: #FFEA00
  }

  .md\:ring-offset-yellow-A700 {
    --tw-ring-offset-color: #FFD600
  }

  .md\:ring-offset-green-50 {
    --tw-ring-offset-color: #E8F5E9
  }

  .md\:ring-offset-green-100 {
    --tw-ring-offset-color: #C8E6C9
  }

  .md\:ring-offset-green-200 {
    --tw-ring-offset-color: #A5D6A7
  }

  .md\:ring-offset-green-300 {
    --tw-ring-offset-color: #81C784
  }

  .md\:ring-offset-green-400 {
    --tw-ring-offset-color: #66BB6A
  }

  .md\:ring-offset-green-500 {
    --tw-ring-offset-color: #4CAF50
  }

  .md\:ring-offset-green-600 {
    --tw-ring-offset-color: #43A047
  }

  .md\:ring-offset-green-700 {
    --tw-ring-offset-color: #388E3C
  }

  .md\:ring-offset-green-800 {
    --tw-ring-offset-color: #2E7D32
  }

  .md\:ring-offset-green-900 {
    --tw-ring-offset-color: #1B5E20
  }

  .md\:ring-offset-green {
    --tw-ring-offset-color: #4CAF50
  }

  .md\:ring-offset-green-A100 {
    --tw-ring-offset-color: #B9F6CA
  }

  .md\:ring-offset-green-A200 {
    --tw-ring-offset-color: #69F0AE
  }

  .md\:ring-offset-green-A400 {
    --tw-ring-offset-color: #00E676
  }

  .md\:ring-offset-green-A700 {
    --tw-ring-offset-color: #00C853
  }

  .md\:ring-offset-light-green-50 {
    --tw-ring-offset-color: #F1F8E9
  }

  .md\:ring-offset-light-green-100 {
    --tw-ring-offset-color: #DCEDC8
  }

  .md\:ring-offset-light-green-200 {
    --tw-ring-offset-color: #C5E1A5
  }

  .md\:ring-offset-light-green-300 {
    --tw-ring-offset-color: #AED581
  }

  .md\:ring-offset-light-green-400 {
    --tw-ring-offset-color: #9CCC65
  }

  .md\:ring-offset-light-green-500 {
    --tw-ring-offset-color: #8BC34A
  }

  .md\:ring-offset-light-green-600 {
    --tw-ring-offset-color: #7CB342
  }

  .md\:ring-offset-light-green-700 {
    --tw-ring-offset-color: #689F38
  }

  .md\:ring-offset-light-green-800 {
    --tw-ring-offset-color: #558B2F
  }

  .md\:ring-offset-light-green-900 {
    --tw-ring-offset-color: #33691E
  }

  .md\:ring-offset-light-green {
    --tw-ring-offset-color: #8BC34A
  }

  .md\:ring-offset-light-green-A100 {
    --tw-ring-offset-color: #CCFF90
  }

  .md\:ring-offset-light-green-A200 {
    --tw-ring-offset-color: #B2FF59
  }

  .md\:ring-offset-light-green-A400 {
    --tw-ring-offset-color: #76FF03
  }

  .md\:ring-offset-light-green-A700 {
    --tw-ring-offset-color: #64DD17
  }

  .md\:ring-offset-teal-50 {
    --tw-ring-offset-color: #E0F2F1
  }

  .md\:ring-offset-teal-100 {
    --tw-ring-offset-color: #B2DFDB
  }

  .md\:ring-offset-teal-200 {
    --tw-ring-offset-color: #80CBC4
  }

  .md\:ring-offset-teal-300 {
    --tw-ring-offset-color: #4DB6AC
  }

  .md\:ring-offset-teal-400 {
    --tw-ring-offset-color: #26A69A
  }

  .md\:ring-offset-teal-500 {
    --tw-ring-offset-color: #009688
  }

  .md\:ring-offset-teal-600 {
    --tw-ring-offset-color: #00897B
  }

  .md\:ring-offset-teal-700 {
    --tw-ring-offset-color: #00796B
  }

  .md\:ring-offset-teal-800 {
    --tw-ring-offset-color: #00695C
  }

  .md\:ring-offset-teal-900 {
    --tw-ring-offset-color: #004D40
  }

  .md\:ring-offset-teal {
    --tw-ring-offset-color: #009688
  }

  .md\:ring-offset-teal-A100 {
    --tw-ring-offset-color: #A7FFEB
  }

  .md\:ring-offset-teal-A200 {
    --tw-ring-offset-color: #64FFDA
  }

  .md\:ring-offset-teal-A400 {
    --tw-ring-offset-color: #1DE9B6
  }

  .md\:ring-offset-teal-A700 {
    --tw-ring-offset-color: #00BFA5
  }

  .md\:ring-offset-blue-50 {
    --tw-ring-offset-color: #E3F2FD
  }

  .md\:ring-offset-blue-100 {
    --tw-ring-offset-color: #BBDEFB
  }

  .md\:ring-offset-blue-200 {
    --tw-ring-offset-color: #90CAF9
  }

  .md\:ring-offset-blue-300 {
    --tw-ring-offset-color: #64B5F6
  }

  .md\:ring-offset-blue-400 {
    --tw-ring-offset-color: #42A5F5
  }

  .md\:ring-offset-blue-500 {
    --tw-ring-offset-color: #2196F3
  }

  .md\:ring-offset-blue-600 {
    --tw-ring-offset-color: #1E88E5
  }

  .md\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1976D2
  }

  .md\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1565C0
  }

  .md\:ring-offset-blue-900 {
    --tw-ring-offset-color: #0D47A1
  }

  .md\:ring-offset-blue {
    --tw-ring-offset-color: #2196F3
  }

  .md\:ring-offset-blue-A100 {
    --tw-ring-offset-color: #82B1FF
  }

  .md\:ring-offset-blue-A200 {
    --tw-ring-offset-color: #448AFF
  }

  .md\:ring-offset-blue-A400 {
    --tw-ring-offset-color: #2979FF
  }

  .md\:ring-offset-blue-A700 {
    --tw-ring-offset-color: #2962FF
  }

  .md\:ring-offset-light-blue-50 {
    --tw-ring-offset-color: #E1F5FE
  }

  .md\:ring-offset-light-blue-100 {
    --tw-ring-offset-color: #B3E5FC
  }

  .md\:ring-offset-light-blue-200 {
    --tw-ring-offset-color: #81D4FA
  }

  .md\:ring-offset-light-blue-300 {
    --tw-ring-offset-color: #4FC3F7
  }

  .md\:ring-offset-light-blue-400 {
    --tw-ring-offset-color: #29B6F6
  }

  .md\:ring-offset-light-blue-500 {
    --tw-ring-offset-color: #03A9F4
  }

  .md\:ring-offset-light-blue-600 {
    --tw-ring-offset-color: #039BE5
  }

  .md\:ring-offset-light-blue-700 {
    --tw-ring-offset-color: #0288D1
  }

  .md\:ring-offset-light-blue-800 {
    --tw-ring-offset-color: #0277BD
  }

  .md\:ring-offset-light-blue-900 {
    --tw-ring-offset-color: #01579B
  }

  .md\:ring-offset-light-blue {
    --tw-ring-offset-color: #03A9F4
  }

  .md\:ring-offset-light-blue-A100 {
    --tw-ring-offset-color: #80D8FF
  }

  .md\:ring-offset-light-blue-A200 {
    --tw-ring-offset-color: #40C4FF
  }

  .md\:ring-offset-light-blue-A400 {
    --tw-ring-offset-color: #00B0FF
  }

  .md\:ring-offset-light-blue-A700 {
    --tw-ring-offset-color: #0091EA
  }

  .md\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #E8EAF6
  }

  .md\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #C5CAE9
  }

  .md\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #9FA8DA
  }

  .md\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #7986CB
  }

  .md\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #5C6BC0
  }

  .md\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #3F51B5
  }

  .md\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #3949AB
  }

  .md\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #303F9F
  }

  .md\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #283593
  }

  .md\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #1A237E
  }

  .md\:ring-offset-indigo {
    --tw-ring-offset-color: #3F51B5
  }

  .md\:ring-offset-indigo-A100 {
    --tw-ring-offset-color: #8C9EFF
  }

  .md\:ring-offset-indigo-A200 {
    --tw-ring-offset-color: #536DFE
  }

  .md\:ring-offset-indigo-A400 {
    --tw-ring-offset-color: #3D5AFE
  }

  .md\:ring-offset-indigo-A700 {
    --tw-ring-offset-color: #304FFE
  }

  .md\:ring-offset-purple-50 {
    --tw-ring-offset-color: #F3E5F5
  }

  .md\:ring-offset-purple-100 {
    --tw-ring-offset-color: #E1BEE7
  }

  .md\:ring-offset-purple-200 {
    --tw-ring-offset-color: #CE93D8
  }

  .md\:ring-offset-purple-300 {
    --tw-ring-offset-color: #BA68C8
  }

  .md\:ring-offset-purple-400 {
    --tw-ring-offset-color: #AB47BC
  }

  .md\:ring-offset-purple-500 {
    --tw-ring-offset-color: #9C27B0
  }

  .md\:ring-offset-purple-600 {
    --tw-ring-offset-color: #8E24AA
  }

  .md\:ring-offset-purple-700 {
    --tw-ring-offset-color: #7B1FA2
  }

  .md\:ring-offset-purple-800 {
    --tw-ring-offset-color: #6A1B9A
  }

  .md\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4A148C
  }

  .md\:ring-offset-purple {
    --tw-ring-offset-color: #9C27B0
  }

  .md\:ring-offset-purple-A100 {
    --tw-ring-offset-color: #EA80FC
  }

  .md\:ring-offset-purple-A200 {
    --tw-ring-offset-color: #E040FB
  }

  .md\:ring-offset-purple-A400 {
    --tw-ring-offset-color: #D500F9
  }

  .md\:ring-offset-purple-A700 {
    --tw-ring-offset-color: #AA00FF
  }

  .md\:ring-offset-deep-purple-50 {
    --tw-ring-offset-color: #EDE7F6
  }

  .md\:ring-offset-deep-purple-100 {
    --tw-ring-offset-color: #D1C4E9
  }

  .md\:ring-offset-deep-purple-200 {
    --tw-ring-offset-color: #B39DDB
  }

  .md\:ring-offset-deep-purple-300 {
    --tw-ring-offset-color: #9575CD
  }

  .md\:ring-offset-deep-purple-400 {
    --tw-ring-offset-color: #7E57C2
  }

  .md\:ring-offset-deep-purple-500 {
    --tw-ring-offset-color: #673AB7
  }

  .md\:ring-offset-deep-purple-600 {
    --tw-ring-offset-color: #5E35B1
  }

  .md\:ring-offset-deep-purple-700 {
    --tw-ring-offset-color: #512DA8
  }

  .md\:ring-offset-deep-purple-800 {
    --tw-ring-offset-color: #4527A0
  }

  .md\:ring-offset-deep-purple-900 {
    --tw-ring-offset-color: #311B92
  }

  .md\:ring-offset-deep-purple {
    --tw-ring-offset-color: #673AB7
  }

  .md\:ring-offset-deep-purple-A100 {
    --tw-ring-offset-color: #B388FF
  }

  .md\:ring-offset-deep-purple-A200 {
    --tw-ring-offset-color: #7C4DFF
  }

  .md\:ring-offset-deep-purple-A400 {
    --tw-ring-offset-color: #651FFF
  }

  .md\:ring-offset-deep-purple-A700 {
    --tw-ring-offset-color: #6200EA
  }

  .md\:ring-offset-pink-50 {
    --tw-ring-offset-color: #FCE4EC
  }

  .md\:ring-offset-pink-100 {
    --tw-ring-offset-color: #F8BBD0
  }

  .md\:ring-offset-pink-200 {
    --tw-ring-offset-color: #F48FB1
  }

  .md\:ring-offset-pink-300 {
    --tw-ring-offset-color: #F06292
  }

  .md\:ring-offset-pink-400 {
    --tw-ring-offset-color: #EC407A
  }

  .md\:ring-offset-pink-500 {
    --tw-ring-offset-color: #E91E63
  }

  .md\:ring-offset-pink-600 {
    --tw-ring-offset-color: #D81B60
  }

  .md\:ring-offset-pink-700 {
    --tw-ring-offset-color: #C2185B
  }

  .md\:ring-offset-pink-800 {
    --tw-ring-offset-color: #AD1457
  }

  .md\:ring-offset-pink-900 {
    --tw-ring-offset-color: #880E4F
  }

  .md\:ring-offset-pink {
    --tw-ring-offset-color: #E91E63
  }

  .md\:ring-offset-pink-A100 {
    --tw-ring-offset-color: #FF80AB
  }

  .md\:ring-offset-pink-A200 {
    --tw-ring-offset-color: #FF4081
  }

  .md\:ring-offset-pink-A400 {
    --tw-ring-offset-color: #F50057
  }

  .md\:ring-offset-pink-A700 {
    --tw-ring-offset-color: #C51162
  }

  .md\:ring-offset-lime-50 {
    --tw-ring-offset-color: #F9FBE7
  }

  .md\:ring-offset-lime-100 {
    --tw-ring-offset-color: #F0F4C3
  }

  .md\:ring-offset-lime-200 {
    --tw-ring-offset-color: #E6EE9C
  }

  .md\:ring-offset-lime-300 {
    --tw-ring-offset-color: #DCE775
  }

  .md\:ring-offset-lime-400 {
    --tw-ring-offset-color: #D4E157
  }

  .md\:ring-offset-lime-500 {
    --tw-ring-offset-color: #CDDC39
  }

  .md\:ring-offset-lime-600 {
    --tw-ring-offset-color: #C0CA33
  }

  .md\:ring-offset-lime-700 {
    --tw-ring-offset-color: #AFB42B
  }

  .md\:ring-offset-lime-800 {
    --tw-ring-offset-color: #9E9D24
  }

  .md\:ring-offset-lime-900 {
    --tw-ring-offset-color: #827717
  }

  .md\:ring-offset-lime {
    --tw-ring-offset-color: #CDDC39
  }

  .md\:ring-offset-lime-A100 {
    --tw-ring-offset-color: #F4FF81
  }

  .md\:ring-offset-lime-A200 {
    --tw-ring-offset-color: #EEFF41
  }

  .md\:ring-offset-lime-A400 {
    --tw-ring-offset-color: #C6FF00
  }

  .md\:ring-offset-lime-A700 {
    --tw-ring-offset-color: #AEEA00
  }

  .md\:ring-offset-amber-50 {
    --tw-ring-offset-color: #FFF8E1
  }

  .md\:ring-offset-amber-100 {
    --tw-ring-offset-color: #FFECB3
  }

  .md\:ring-offset-amber-200 {
    --tw-ring-offset-color: #FFE082
  }

  .md\:ring-offset-amber-300 {
    --tw-ring-offset-color: #FFD54F
  }

  .md\:ring-offset-amber-400 {
    --tw-ring-offset-color: #FFCA28
  }

  .md\:ring-offset-amber-500 {
    --tw-ring-offset-color: #FFC107
  }

  .md\:ring-offset-amber-600 {
    --tw-ring-offset-color: #FFB300
  }

  .md\:ring-offset-amber-700 {
    --tw-ring-offset-color: #FFA000
  }

  .md\:ring-offset-amber-800 {
    --tw-ring-offset-color: #FF8F00
  }

  .md\:ring-offset-amber-900 {
    --tw-ring-offset-color: #FF6F00
  }

  .md\:ring-offset-amber {
    --tw-ring-offset-color: #FFC107
  }

  .md\:ring-offset-amber-A100 {
    --tw-ring-offset-color: #FFE57F
  }

  .md\:ring-offset-amber-A200 {
    --tw-ring-offset-color: #FFD740
  }

  .md\:ring-offset-amber-A400 {
    --tw-ring-offset-color: #FFC400
  }

  .md\:ring-offset-amber-A700 {
    --tw-ring-offset-color: #FFAB00
  }

  .md\:ring-offset-brown-50 {
    --tw-ring-offset-color: #EFEBE9
  }

  .md\:ring-offset-brown-100 {
    --tw-ring-offset-color: #D7CCC8
  }

  .md\:ring-offset-brown-200 {
    --tw-ring-offset-color: #BCAAA4
  }

  .md\:ring-offset-brown-300 {
    --tw-ring-offset-color: #A1887F
  }

  .md\:ring-offset-brown-400 {
    --tw-ring-offset-color: #8D6E63
  }

  .md\:ring-offset-brown-500 {
    --tw-ring-offset-color: #795548
  }

  .md\:ring-offset-brown-600 {
    --tw-ring-offset-color: #6D4C41
  }

  .md\:ring-offset-brown-700 {
    --tw-ring-offset-color: #5D4037
  }

  .md\:ring-offset-brown-800 {
    --tw-ring-offset-color: #4E342E
  }

  .md\:ring-offset-brown-900 {
    --tw-ring-offset-color: #3E2723
  }

  .md\:ring-offset-brown {
    --tw-ring-offset-color: #795548
  }

  .md\:ring-offset-brown-A100 {
    --tw-ring-offset-color: #D7CCC8
  }

  .md\:ring-offset-brown-A200 {
    --tw-ring-offset-color: #BCAAA4
  }

  .md\:ring-offset-brown-A400 {
    --tw-ring-offset-color: #8D6E63
  }

  .md\:ring-offset-brown-A700 {
    --tw-ring-offset-color: #5D4037
  }

  .md\:ring-offset-blue-gray-50 {
    --tw-ring-offset-color: #ECEFF1
  }

  .md\:ring-offset-blue-gray-100 {
    --tw-ring-offset-color: #CFD8DC
  }

  .md\:ring-offset-blue-gray-200 {
    --tw-ring-offset-color: #B0BEC5
  }

  .md\:ring-offset-blue-gray-300 {
    --tw-ring-offset-color: #90A4AE
  }

  .md\:ring-offset-blue-gray-400 {
    --tw-ring-offset-color: #78909C
  }

  .md\:ring-offset-blue-gray-500 {
    --tw-ring-offset-color: #607D8B
  }

  .md\:ring-offset-blue-gray-600 {
    --tw-ring-offset-color: #546E7A
  }

  .md\:ring-offset-blue-gray-700 {
    --tw-ring-offset-color: #455A64
  }

  .md\:ring-offset-blue-gray-800 {
    --tw-ring-offset-color: #37474F
  }

  .md\:ring-offset-blue-gray-900 {
    --tw-ring-offset-color: #263238
  }

  .md\:ring-offset-blue-gray {
    --tw-ring-offset-color: #607D8B
  }

  .md\:ring-offset-blue-gray-A100 {
    --tw-ring-offset-color: #CFD8DC
  }

  .md\:ring-offset-blue-gray-A200 {
    --tw-ring-offset-color: #B0BEC5
  }

  .md\:ring-offset-blue-gray-A400 {
    --tw-ring-offset-color: #78909C
  }

  .md\:ring-offset-blue-gray-A700 {
    --tw-ring-offset-color: #455A64
  }

  .md\:ring-offset-cyan-50 {
    --tw-ring-offset-color: #E0F7FA
  }

  .md\:ring-offset-cyan-100 {
    --tw-ring-offset-color: #B2EBF2
  }

  .md\:ring-offset-cyan-200 {
    --tw-ring-offset-color: #80DEEA
  }

  .md\:ring-offset-cyan-300 {
    --tw-ring-offset-color: #4DD0E1
  }

  .md\:ring-offset-cyan-400 {
    --tw-ring-offset-color: #26C6DA
  }

  .md\:ring-offset-cyan-500 {
    --tw-ring-offset-color: #00BCD4
  }

  .md\:ring-offset-cyan-600 {
    --tw-ring-offset-color: #00ACC1
  }

  .md\:ring-offset-cyan-700 {
    --tw-ring-offset-color: #0097A7
  }

  .md\:ring-offset-cyan-800 {
    --tw-ring-offset-color: #00838F
  }

  .md\:ring-offset-cyan-900 {
    --tw-ring-offset-color: #006064
  }

  .md\:ring-offset-cyan {
    --tw-ring-offset-color: #00BCD4
  }

  .md\:ring-offset-cyan-A100 {
    --tw-ring-offset-color: #84FFFF
  }

  .md\:ring-offset-cyan-A200 {
    --tw-ring-offset-color: #18FFFF
  }

  .md\:ring-offset-cyan-A400 {
    --tw-ring-offset-color: #00E5FF
  }

  .md\:ring-offset-cyan-A700 {
    --tw-ring-offset-color: #00B8D4
  }

  .md\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent
  }

  .md\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor
  }

  .md\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #22292F
  }

  .md\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff
  }

  .md\:focus-within\:ring-offset-grey-50:focus-within {
    --tw-ring-offset-color: #FAFAFA
  }

  .md\:focus-within\:ring-offset-grey-100:focus-within {
    --tw-ring-offset-color: #F5F5F5
  }

  .md\:focus-within\:ring-offset-grey-200:focus-within {
    --tw-ring-offset-color: #EEEEEE
  }

  .md\:focus-within\:ring-offset-grey-300:focus-within {
    --tw-ring-offset-color: #E0E0E0
  }

  .md\:focus-within\:ring-offset-grey-400:focus-within {
    --tw-ring-offset-color: #BDBDBD
  }

  .md\:focus-within\:ring-offset-grey-500:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .md\:focus-within\:ring-offset-grey-600:focus-within {
    --tw-ring-offset-color: #757575
  }

  .md\:focus-within\:ring-offset-grey-700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .md\:focus-within\:ring-offset-grey-800:focus-within {
    --tw-ring-offset-color: #424242
  }

  .md\:focus-within\:ring-offset-grey-900:focus-within {
    --tw-ring-offset-color: #212121
  }

  .md\:focus-within\:ring-offset-grey:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .md\:focus-within\:ring-offset-grey-A100:focus-within {
    --tw-ring-offset-color: #D5D5D5
  }

  .md\:focus-within\:ring-offset-grey-A200:focus-within {
    --tw-ring-offset-color: #AAAAAA
  }

  .md\:focus-within\:ring-offset-grey-A400:focus-within {
    --tw-ring-offset-color: #303030
  }

  .md\:focus-within\:ring-offset-grey-A700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .md\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #FAFAFA
  }

  .md\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #F5F5F5
  }

  .md\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #EEEEEE
  }

  .md\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #E0E0E0
  }

  .md\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #BDBDBD
  }

  .md\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .md\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #757575
  }

  .md\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .md\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #424242
  }

  .md\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #212121
  }

  .md\:focus-within\:ring-offset-gray:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .md\:focus-within\:ring-offset-gray-hover:focus-within {
    --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
  }

  .md\:focus-within\:ring-offset-gray-A100:focus-within {
    --tw-ring-offset-color: #D5D5D5
  }

  .md\:focus-within\:ring-offset-gray-A200:focus-within {
    --tw-ring-offset-color: #AAAAAA
  }

  .md\:focus-within\:ring-offset-gray-A400:focus-within {
    --tw-ring-offset-color: #303030
  }

  .md\:focus-within\:ring-offset-gray-A700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .md\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #FFEBEE
  }

  .md\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #FFCDD2
  }

  .md\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #EF9A9A
  }

  .md\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #E57373
  }

  .md\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #EF5350
  }

  .md\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #F44336
  }

  .md\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #E53935
  }

  .md\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #D32F2F
  }

  .md\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #C62828
  }

  .md\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #B71C1C
  }

  .md\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: #F44336
  }

  .md\:focus-within\:ring-offset-red-A100:focus-within {
    --tw-ring-offset-color: #FF8A80
  }

  .md\:focus-within\:ring-offset-red-A200:focus-within {
    --tw-ring-offset-color: #FF5252
  }

  .md\:focus-within\:ring-offset-red-A400:focus-within {
    --tw-ring-offset-color: #FF1744
  }

  .md\:focus-within\:ring-offset-red-A700:focus-within {
    --tw-ring-offset-color: #D50000
  }

  .md\:focus-within\:ring-offset-orange-50:focus-within {
    --tw-ring-offset-color: #FFF3E0
  }

  .md\:focus-within\:ring-offset-orange-100:focus-within {
    --tw-ring-offset-color: #FFE0B2
  }

  .md\:focus-within\:ring-offset-orange-200:focus-within {
    --tw-ring-offset-color: #FFCC80
  }

  .md\:focus-within\:ring-offset-orange-300:focus-within {
    --tw-ring-offset-color: #FFB74D
  }

  .md\:focus-within\:ring-offset-orange-400:focus-within {
    --tw-ring-offset-color: #FFA726
  }

  .md\:focus-within\:ring-offset-orange-500:focus-within {
    --tw-ring-offset-color: #FF9800
  }

  .md\:focus-within\:ring-offset-orange-600:focus-within {
    --tw-ring-offset-color: #FB8C00
  }

  .md\:focus-within\:ring-offset-orange-700:focus-within {
    --tw-ring-offset-color: #F57C00
  }

  .md\:focus-within\:ring-offset-orange-800:focus-within {
    --tw-ring-offset-color: #EF6C00
  }

  .md\:focus-within\:ring-offset-orange-900:focus-within {
    --tw-ring-offset-color: #E65100
  }

  .md\:focus-within\:ring-offset-orange:focus-within {
    --tw-ring-offset-color: #FF9800
  }

  .md\:focus-within\:ring-offset-orange-A100:focus-within {
    --tw-ring-offset-color: #FFD180
  }

  .md\:focus-within\:ring-offset-orange-A200:focus-within {
    --tw-ring-offset-color: #FFAB40
  }

  .md\:focus-within\:ring-offset-orange-A400:focus-within {
    --tw-ring-offset-color: #FF9100
  }

  .md\:focus-within\:ring-offset-orange-A700:focus-within {
    --tw-ring-offset-color: #FF6D00
  }

  .md\:focus-within\:ring-offset-deep-orange-50:focus-within {
    --tw-ring-offset-color: #FBE9E7
  }

  .md\:focus-within\:ring-offset-deep-orange-100:focus-within {
    --tw-ring-offset-color: #FFCCBC
  }

  .md\:focus-within\:ring-offset-deep-orange-200:focus-within {
    --tw-ring-offset-color: #FFAB91
  }

  .md\:focus-within\:ring-offset-deep-orange-300:focus-within {
    --tw-ring-offset-color: #FF8A65
  }

  .md\:focus-within\:ring-offset-deep-orange-400:focus-within {
    --tw-ring-offset-color: #FF7043
  }

  .md\:focus-within\:ring-offset-deep-orange-500:focus-within {
    --tw-ring-offset-color: #FF5722
  }

  .md\:focus-within\:ring-offset-deep-orange-600:focus-within {
    --tw-ring-offset-color: #F4511E
  }

  .md\:focus-within\:ring-offset-deep-orange-700:focus-within {
    --tw-ring-offset-color: #E64A19
  }

  .md\:focus-within\:ring-offset-deep-orange-800:focus-within {
    --tw-ring-offset-color: #D84315
  }

  .md\:focus-within\:ring-offset-deep-orange-900:focus-within {
    --tw-ring-offset-color: #BF360C
  }

  .md\:focus-within\:ring-offset-deep-orange:focus-within {
    --tw-ring-offset-color: #FF5722
  }

  .md\:focus-within\:ring-offset-deep-orange-A100:focus-within {
    --tw-ring-offset-color: #FF9E80
  }

  .md\:focus-within\:ring-offset-deep-orange-A200:focus-within {
    --tw-ring-offset-color: #FF6E40
  }

  .md\:focus-within\:ring-offset-deep-orange-A400:focus-within {
    --tw-ring-offset-color: #FF3D00
  }

  .md\:focus-within\:ring-offset-deep-orange-A700:focus-within {
    --tw-ring-offset-color: #DD2C00
  }

  .md\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #FFFDE7
  }

  .md\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #FFF9C4
  }

  .md\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #FFF59D
  }

  .md\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #FFF176
  }

  .md\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #FFEE58
  }

  .md\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #FFEB3B
  }

  .md\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #FDD835
  }

  .md\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #FBC02D
  }

  .md\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #F9A825
  }

  .md\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #F57F17
  }

  .md\:focus-within\:ring-offset-yellow:focus-within {
    --tw-ring-offset-color: #FFEB3B
  }

  .md\:focus-within\:ring-offset-yellow-A100:focus-within {
    --tw-ring-offset-color: #FFFF8D
  }

  .md\:focus-within\:ring-offset-yellow-A200:focus-within {
    --tw-ring-offset-color: #FFFF00
  }

  .md\:focus-within\:ring-offset-yellow-A400:focus-within {
    --tw-ring-offset-color: #FFEA00
  }

  .md\:focus-within\:ring-offset-yellow-A700:focus-within {
    --tw-ring-offset-color: #FFD600
  }

  .md\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #E8F5E9
  }

  .md\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #C8E6C9
  }

  .md\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #A5D6A7
  }

  .md\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #81C784
  }

  .md\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #66BB6A
  }

  .md\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #4CAF50
  }

  .md\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #43A047
  }

  .md\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #388E3C
  }

  .md\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #2E7D32
  }

  .md\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #1B5E20
  }

  .md\:focus-within\:ring-offset-green:focus-within {
    --tw-ring-offset-color: #4CAF50
  }

  .md\:focus-within\:ring-offset-green-A100:focus-within {
    --tw-ring-offset-color: #B9F6CA
  }

  .md\:focus-within\:ring-offset-green-A200:focus-within {
    --tw-ring-offset-color: #69F0AE
  }

  .md\:focus-within\:ring-offset-green-A400:focus-within {
    --tw-ring-offset-color: #00E676
  }

  .md\:focus-within\:ring-offset-green-A700:focus-within {
    --tw-ring-offset-color: #00C853
  }

  .md\:focus-within\:ring-offset-light-green-50:focus-within {
    --tw-ring-offset-color: #F1F8E9
  }

  .md\:focus-within\:ring-offset-light-green-100:focus-within {
    --tw-ring-offset-color: #DCEDC8
  }

  .md\:focus-within\:ring-offset-light-green-200:focus-within {
    --tw-ring-offset-color: #C5E1A5
  }

  .md\:focus-within\:ring-offset-light-green-300:focus-within {
    --tw-ring-offset-color: #AED581
  }

  .md\:focus-within\:ring-offset-light-green-400:focus-within {
    --tw-ring-offset-color: #9CCC65
  }

  .md\:focus-within\:ring-offset-light-green-500:focus-within {
    --tw-ring-offset-color: #8BC34A
  }

  .md\:focus-within\:ring-offset-light-green-600:focus-within {
    --tw-ring-offset-color: #7CB342
  }

  .md\:focus-within\:ring-offset-light-green-700:focus-within {
    --tw-ring-offset-color: #689F38
  }

  .md\:focus-within\:ring-offset-light-green-800:focus-within {
    --tw-ring-offset-color: #558B2F
  }

  .md\:focus-within\:ring-offset-light-green-900:focus-within {
    --tw-ring-offset-color: #33691E
  }

  .md\:focus-within\:ring-offset-light-green:focus-within {
    --tw-ring-offset-color: #8BC34A
  }

  .md\:focus-within\:ring-offset-light-green-A100:focus-within {
    --tw-ring-offset-color: #CCFF90
  }

  .md\:focus-within\:ring-offset-light-green-A200:focus-within {
    --tw-ring-offset-color: #B2FF59
  }

  .md\:focus-within\:ring-offset-light-green-A400:focus-within {
    --tw-ring-offset-color: #76FF03
  }

  .md\:focus-within\:ring-offset-light-green-A700:focus-within {
    --tw-ring-offset-color: #64DD17
  }

  .md\:focus-within\:ring-offset-teal-50:focus-within {
    --tw-ring-offset-color: #E0F2F1
  }

  .md\:focus-within\:ring-offset-teal-100:focus-within {
    --tw-ring-offset-color: #B2DFDB
  }

  .md\:focus-within\:ring-offset-teal-200:focus-within {
    --tw-ring-offset-color: #80CBC4
  }

  .md\:focus-within\:ring-offset-teal-300:focus-within {
    --tw-ring-offset-color: #4DB6AC
  }

  .md\:focus-within\:ring-offset-teal-400:focus-within {
    --tw-ring-offset-color: #26A69A
  }

  .md\:focus-within\:ring-offset-teal-500:focus-within {
    --tw-ring-offset-color: #009688
  }

  .md\:focus-within\:ring-offset-teal-600:focus-within {
    --tw-ring-offset-color: #00897B
  }

  .md\:focus-within\:ring-offset-teal-700:focus-within {
    --tw-ring-offset-color: #00796B
  }

  .md\:focus-within\:ring-offset-teal-800:focus-within {
    --tw-ring-offset-color: #00695C
  }

  .md\:focus-within\:ring-offset-teal-900:focus-within {
    --tw-ring-offset-color: #004D40
  }

  .md\:focus-within\:ring-offset-teal:focus-within {
    --tw-ring-offset-color: #009688
  }

  .md\:focus-within\:ring-offset-teal-A100:focus-within {
    --tw-ring-offset-color: #A7FFEB
  }

  .md\:focus-within\:ring-offset-teal-A200:focus-within {
    --tw-ring-offset-color: #64FFDA
  }

  .md\:focus-within\:ring-offset-teal-A400:focus-within {
    --tw-ring-offset-color: #1DE9B6
  }

  .md\:focus-within\:ring-offset-teal-A700:focus-within {
    --tw-ring-offset-color: #00BFA5
  }

  .md\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #E3F2FD
  }

  .md\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #BBDEFB
  }

  .md\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #90CAF9
  }

  .md\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #64B5F6
  }

  .md\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #42A5F5
  }

  .md\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #2196F3
  }

  .md\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #1E88E5
  }

  .md\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1976D2
  }

  .md\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1565C0
  }

  .md\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #0D47A1
  }

  .md\:focus-within\:ring-offset-blue:focus-within {
    --tw-ring-offset-color: #2196F3
  }

  .md\:focus-within\:ring-offset-blue-A100:focus-within {
    --tw-ring-offset-color: #82B1FF
  }

  .md\:focus-within\:ring-offset-blue-A200:focus-within {
    --tw-ring-offset-color: #448AFF
  }

  .md\:focus-within\:ring-offset-blue-A400:focus-within {
    --tw-ring-offset-color: #2979FF
  }

  .md\:focus-within\:ring-offset-blue-A700:focus-within {
    --tw-ring-offset-color: #2962FF
  }

  .md\:focus-within\:ring-offset-light-blue-50:focus-within {
    --tw-ring-offset-color: #E1F5FE
  }

  .md\:focus-within\:ring-offset-light-blue-100:focus-within {
    --tw-ring-offset-color: #B3E5FC
  }

  .md\:focus-within\:ring-offset-light-blue-200:focus-within {
    --tw-ring-offset-color: #81D4FA
  }

  .md\:focus-within\:ring-offset-light-blue-300:focus-within {
    --tw-ring-offset-color: #4FC3F7
  }

  .md\:focus-within\:ring-offset-light-blue-400:focus-within {
    --tw-ring-offset-color: #29B6F6
  }

  .md\:focus-within\:ring-offset-light-blue-500:focus-within {
    --tw-ring-offset-color: #03A9F4
  }

  .md\:focus-within\:ring-offset-light-blue-600:focus-within {
    --tw-ring-offset-color: #039BE5
  }

  .md\:focus-within\:ring-offset-light-blue-700:focus-within {
    --tw-ring-offset-color: #0288D1
  }

  .md\:focus-within\:ring-offset-light-blue-800:focus-within {
    --tw-ring-offset-color: #0277BD
  }

  .md\:focus-within\:ring-offset-light-blue-900:focus-within {
    --tw-ring-offset-color: #01579B
  }

  .md\:focus-within\:ring-offset-light-blue:focus-within {
    --tw-ring-offset-color: #03A9F4
  }

  .md\:focus-within\:ring-offset-light-blue-A100:focus-within {
    --tw-ring-offset-color: #80D8FF
  }

  .md\:focus-within\:ring-offset-light-blue-A200:focus-within {
    --tw-ring-offset-color: #40C4FF
  }

  .md\:focus-within\:ring-offset-light-blue-A400:focus-within {
    --tw-ring-offset-color: #00B0FF
  }

  .md\:focus-within\:ring-offset-light-blue-A700:focus-within {
    --tw-ring-offset-color: #0091EA
  }

  .md\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #E8EAF6
  }

  .md\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #C5CAE9
  }

  .md\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #9FA8DA
  }

  .md\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #7986CB
  }

  .md\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #5C6BC0
  }

  .md\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #3F51B5
  }

  .md\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #3949AB
  }

  .md\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #303F9F
  }

  .md\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #283593
  }

  .md\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #1A237E
  }

  .md\:focus-within\:ring-offset-indigo:focus-within {
    --tw-ring-offset-color: #3F51B5
  }

  .md\:focus-within\:ring-offset-indigo-A100:focus-within {
    --tw-ring-offset-color: #8C9EFF
  }

  .md\:focus-within\:ring-offset-indigo-A200:focus-within {
    --tw-ring-offset-color: #536DFE
  }

  .md\:focus-within\:ring-offset-indigo-A400:focus-within {
    --tw-ring-offset-color: #3D5AFE
  }

  .md\:focus-within\:ring-offset-indigo-A700:focus-within {
    --tw-ring-offset-color: #304FFE
  }

  .md\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #F3E5F5
  }

  .md\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #E1BEE7
  }

  .md\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #CE93D8
  }

  .md\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #BA68C8
  }

  .md\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #AB47BC
  }

  .md\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #9C27B0
  }

  .md\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #8E24AA
  }

  .md\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #7B1FA2
  }

  .md\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #6A1B9A
  }

  .md\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4A148C
  }

  .md\:focus-within\:ring-offset-purple:focus-within {
    --tw-ring-offset-color: #9C27B0
  }

  .md\:focus-within\:ring-offset-purple-A100:focus-within {
    --tw-ring-offset-color: #EA80FC
  }

  .md\:focus-within\:ring-offset-purple-A200:focus-within {
    --tw-ring-offset-color: #E040FB
  }

  .md\:focus-within\:ring-offset-purple-A400:focus-within {
    --tw-ring-offset-color: #D500F9
  }

  .md\:focus-within\:ring-offset-purple-A700:focus-within {
    --tw-ring-offset-color: #AA00FF
  }

  .md\:focus-within\:ring-offset-deep-purple-50:focus-within {
    --tw-ring-offset-color: #EDE7F6
  }

  .md\:focus-within\:ring-offset-deep-purple-100:focus-within {
    --tw-ring-offset-color: #D1C4E9
  }

  .md\:focus-within\:ring-offset-deep-purple-200:focus-within {
    --tw-ring-offset-color: #B39DDB
  }

  .md\:focus-within\:ring-offset-deep-purple-300:focus-within {
    --tw-ring-offset-color: #9575CD
  }

  .md\:focus-within\:ring-offset-deep-purple-400:focus-within {
    --tw-ring-offset-color: #7E57C2
  }

  .md\:focus-within\:ring-offset-deep-purple-500:focus-within {
    --tw-ring-offset-color: #673AB7
  }

  .md\:focus-within\:ring-offset-deep-purple-600:focus-within {
    --tw-ring-offset-color: #5E35B1
  }

  .md\:focus-within\:ring-offset-deep-purple-700:focus-within {
    --tw-ring-offset-color: #512DA8
  }

  .md\:focus-within\:ring-offset-deep-purple-800:focus-within {
    --tw-ring-offset-color: #4527A0
  }

  .md\:focus-within\:ring-offset-deep-purple-900:focus-within {
    --tw-ring-offset-color: #311B92
  }

  .md\:focus-within\:ring-offset-deep-purple:focus-within {
    --tw-ring-offset-color: #673AB7
  }

  .md\:focus-within\:ring-offset-deep-purple-A100:focus-within {
    --tw-ring-offset-color: #B388FF
  }

  .md\:focus-within\:ring-offset-deep-purple-A200:focus-within {
    --tw-ring-offset-color: #7C4DFF
  }

  .md\:focus-within\:ring-offset-deep-purple-A400:focus-within {
    --tw-ring-offset-color: #651FFF
  }

  .md\:focus-within\:ring-offset-deep-purple-A700:focus-within {
    --tw-ring-offset-color: #6200EA
  }

  .md\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #FCE4EC
  }

  .md\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #F8BBD0
  }

  .md\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #F48FB1
  }

  .md\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #F06292
  }

  .md\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #EC407A
  }

  .md\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #E91E63
  }

  .md\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #D81B60
  }

  .md\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #C2185B
  }

  .md\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #AD1457
  }

  .md\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #880E4F
  }

  .md\:focus-within\:ring-offset-pink:focus-within {
    --tw-ring-offset-color: #E91E63
  }

  .md\:focus-within\:ring-offset-pink-A100:focus-within {
    --tw-ring-offset-color: #FF80AB
  }

  .md\:focus-within\:ring-offset-pink-A200:focus-within {
    --tw-ring-offset-color: #FF4081
  }

  .md\:focus-within\:ring-offset-pink-A400:focus-within {
    --tw-ring-offset-color: #F50057
  }

  .md\:focus-within\:ring-offset-pink-A700:focus-within {
    --tw-ring-offset-color: #C51162
  }

  .md\:focus-within\:ring-offset-lime-50:focus-within {
    --tw-ring-offset-color: #F9FBE7
  }

  .md\:focus-within\:ring-offset-lime-100:focus-within {
    --tw-ring-offset-color: #F0F4C3
  }

  .md\:focus-within\:ring-offset-lime-200:focus-within {
    --tw-ring-offset-color: #E6EE9C
  }

  .md\:focus-within\:ring-offset-lime-300:focus-within {
    --tw-ring-offset-color: #DCE775
  }

  .md\:focus-within\:ring-offset-lime-400:focus-within {
    --tw-ring-offset-color: #D4E157
  }

  .md\:focus-within\:ring-offset-lime-500:focus-within {
    --tw-ring-offset-color: #CDDC39
  }

  .md\:focus-within\:ring-offset-lime-600:focus-within {
    --tw-ring-offset-color: #C0CA33
  }

  .md\:focus-within\:ring-offset-lime-700:focus-within {
    --tw-ring-offset-color: #AFB42B
  }

  .md\:focus-within\:ring-offset-lime-800:focus-within {
    --tw-ring-offset-color: #9E9D24
  }

  .md\:focus-within\:ring-offset-lime-900:focus-within {
    --tw-ring-offset-color: #827717
  }

  .md\:focus-within\:ring-offset-lime:focus-within {
    --tw-ring-offset-color: #CDDC39
  }

  .md\:focus-within\:ring-offset-lime-A100:focus-within {
    --tw-ring-offset-color: #F4FF81
  }

  .md\:focus-within\:ring-offset-lime-A200:focus-within {
    --tw-ring-offset-color: #EEFF41
  }

  .md\:focus-within\:ring-offset-lime-A400:focus-within {
    --tw-ring-offset-color: #C6FF00
  }

  .md\:focus-within\:ring-offset-lime-A700:focus-within {
    --tw-ring-offset-color: #AEEA00
  }

  .md\:focus-within\:ring-offset-amber-50:focus-within {
    --tw-ring-offset-color: #FFF8E1
  }

  .md\:focus-within\:ring-offset-amber-100:focus-within {
    --tw-ring-offset-color: #FFECB3
  }

  .md\:focus-within\:ring-offset-amber-200:focus-within {
    --tw-ring-offset-color: #FFE082
  }

  .md\:focus-within\:ring-offset-amber-300:focus-within {
    --tw-ring-offset-color: #FFD54F
  }

  .md\:focus-within\:ring-offset-amber-400:focus-within {
    --tw-ring-offset-color: #FFCA28
  }

  .md\:focus-within\:ring-offset-amber-500:focus-within {
    --tw-ring-offset-color: #FFC107
  }

  .md\:focus-within\:ring-offset-amber-600:focus-within {
    --tw-ring-offset-color: #FFB300
  }

  .md\:focus-within\:ring-offset-amber-700:focus-within {
    --tw-ring-offset-color: #FFA000
  }

  .md\:focus-within\:ring-offset-amber-800:focus-within {
    --tw-ring-offset-color: #FF8F00
  }

  .md\:focus-within\:ring-offset-amber-900:focus-within {
    --tw-ring-offset-color: #FF6F00
  }

  .md\:focus-within\:ring-offset-amber:focus-within {
    --tw-ring-offset-color: #FFC107
  }

  .md\:focus-within\:ring-offset-amber-A100:focus-within {
    --tw-ring-offset-color: #FFE57F
  }

  .md\:focus-within\:ring-offset-amber-A200:focus-within {
    --tw-ring-offset-color: #FFD740
  }

  .md\:focus-within\:ring-offset-amber-A400:focus-within {
    --tw-ring-offset-color: #FFC400
  }

  .md\:focus-within\:ring-offset-amber-A700:focus-within {
    --tw-ring-offset-color: #FFAB00
  }

  .md\:focus-within\:ring-offset-brown-50:focus-within {
    --tw-ring-offset-color: #EFEBE9
  }

  .md\:focus-within\:ring-offset-brown-100:focus-within {
    --tw-ring-offset-color: #D7CCC8
  }

  .md\:focus-within\:ring-offset-brown-200:focus-within {
    --tw-ring-offset-color: #BCAAA4
  }

  .md\:focus-within\:ring-offset-brown-300:focus-within {
    --tw-ring-offset-color: #A1887F
  }

  .md\:focus-within\:ring-offset-brown-400:focus-within {
    --tw-ring-offset-color: #8D6E63
  }

  .md\:focus-within\:ring-offset-brown-500:focus-within {
    --tw-ring-offset-color: #795548
  }

  .md\:focus-within\:ring-offset-brown-600:focus-within {
    --tw-ring-offset-color: #6D4C41
  }

  .md\:focus-within\:ring-offset-brown-700:focus-within {
    --tw-ring-offset-color: #5D4037
  }

  .md\:focus-within\:ring-offset-brown-800:focus-within {
    --tw-ring-offset-color: #4E342E
  }

  .md\:focus-within\:ring-offset-brown-900:focus-within {
    --tw-ring-offset-color: #3E2723
  }

  .md\:focus-within\:ring-offset-brown:focus-within {
    --tw-ring-offset-color: #795548
  }

  .md\:focus-within\:ring-offset-brown-A100:focus-within {
    --tw-ring-offset-color: #D7CCC8
  }

  .md\:focus-within\:ring-offset-brown-A200:focus-within {
    --tw-ring-offset-color: #BCAAA4
  }

  .md\:focus-within\:ring-offset-brown-A400:focus-within {
    --tw-ring-offset-color: #8D6E63
  }

  .md\:focus-within\:ring-offset-brown-A700:focus-within {
    --tw-ring-offset-color: #5D4037
  }

  .md\:focus-within\:ring-offset-blue-gray-50:focus-within {
    --tw-ring-offset-color: #ECEFF1
  }

  .md\:focus-within\:ring-offset-blue-gray-100:focus-within {
    --tw-ring-offset-color: #CFD8DC
  }

  .md\:focus-within\:ring-offset-blue-gray-200:focus-within {
    --tw-ring-offset-color: #B0BEC5
  }

  .md\:focus-within\:ring-offset-blue-gray-300:focus-within {
    --tw-ring-offset-color: #90A4AE
  }

  .md\:focus-within\:ring-offset-blue-gray-400:focus-within {
    --tw-ring-offset-color: #78909C
  }

  .md\:focus-within\:ring-offset-blue-gray-500:focus-within {
    --tw-ring-offset-color: #607D8B
  }

  .md\:focus-within\:ring-offset-blue-gray-600:focus-within {
    --tw-ring-offset-color: #546E7A
  }

  .md\:focus-within\:ring-offset-blue-gray-700:focus-within {
    --tw-ring-offset-color: #455A64
  }

  .md\:focus-within\:ring-offset-blue-gray-800:focus-within {
    --tw-ring-offset-color: #37474F
  }

  .md\:focus-within\:ring-offset-blue-gray-900:focus-within {
    --tw-ring-offset-color: #263238
  }

  .md\:focus-within\:ring-offset-blue-gray:focus-within {
    --tw-ring-offset-color: #607D8B
  }

  .md\:focus-within\:ring-offset-blue-gray-A100:focus-within {
    --tw-ring-offset-color: #CFD8DC
  }

  .md\:focus-within\:ring-offset-blue-gray-A200:focus-within {
    --tw-ring-offset-color: #B0BEC5
  }

  .md\:focus-within\:ring-offset-blue-gray-A400:focus-within {
    --tw-ring-offset-color: #78909C
  }

  .md\:focus-within\:ring-offset-blue-gray-A700:focus-within {
    --tw-ring-offset-color: #455A64
  }

  .md\:focus-within\:ring-offset-cyan-50:focus-within {
    --tw-ring-offset-color: #E0F7FA
  }

  .md\:focus-within\:ring-offset-cyan-100:focus-within {
    --tw-ring-offset-color: #B2EBF2
  }

  .md\:focus-within\:ring-offset-cyan-200:focus-within {
    --tw-ring-offset-color: #80DEEA
  }

  .md\:focus-within\:ring-offset-cyan-300:focus-within {
    --tw-ring-offset-color: #4DD0E1
  }

  .md\:focus-within\:ring-offset-cyan-400:focus-within {
    --tw-ring-offset-color: #26C6DA
  }

  .md\:focus-within\:ring-offset-cyan-500:focus-within {
    --tw-ring-offset-color: #00BCD4
  }

  .md\:focus-within\:ring-offset-cyan-600:focus-within {
    --tw-ring-offset-color: #00ACC1
  }

  .md\:focus-within\:ring-offset-cyan-700:focus-within {
    --tw-ring-offset-color: #0097A7
  }

  .md\:focus-within\:ring-offset-cyan-800:focus-within {
    --tw-ring-offset-color: #00838F
  }

  .md\:focus-within\:ring-offset-cyan-900:focus-within {
    --tw-ring-offset-color: #006064
  }

  .md\:focus-within\:ring-offset-cyan:focus-within {
    --tw-ring-offset-color: #00BCD4
  }

  .md\:focus-within\:ring-offset-cyan-A100:focus-within {
    --tw-ring-offset-color: #84FFFF
  }

  .md\:focus-within\:ring-offset-cyan-A200:focus-within {
    --tw-ring-offset-color: #18FFFF
  }

  .md\:focus-within\:ring-offset-cyan-A400:focus-within {
    --tw-ring-offset-color: #00E5FF
  }

  .md\:focus-within\:ring-offset-cyan-A700:focus-within {
    --tw-ring-offset-color: #00B8D4
  }

  .md\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent
  }

  .md\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor
  }

  .md\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #22292F
  }

  .md\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff
  }

  .md\:focus\:ring-offset-grey-50:focus {
    --tw-ring-offset-color: #FAFAFA
  }

  .md\:focus\:ring-offset-grey-100:focus {
    --tw-ring-offset-color: #F5F5F5
  }

  .md\:focus\:ring-offset-grey-200:focus {
    --tw-ring-offset-color: #EEEEEE
  }

  .md\:focus\:ring-offset-grey-300:focus {
    --tw-ring-offset-color: #E0E0E0
  }

  .md\:focus\:ring-offset-grey-400:focus {
    --tw-ring-offset-color: #BDBDBD
  }

  .md\:focus\:ring-offset-grey-500:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .md\:focus\:ring-offset-grey-600:focus {
    --tw-ring-offset-color: #757575
  }

  .md\:focus\:ring-offset-grey-700:focus {
    --tw-ring-offset-color: #616161
  }

  .md\:focus\:ring-offset-grey-800:focus {
    --tw-ring-offset-color: #424242
  }

  .md\:focus\:ring-offset-grey-900:focus {
    --tw-ring-offset-color: #212121
  }

  .md\:focus\:ring-offset-grey:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .md\:focus\:ring-offset-grey-A100:focus {
    --tw-ring-offset-color: #D5D5D5
  }

  .md\:focus\:ring-offset-grey-A200:focus {
    --tw-ring-offset-color: #AAAAAA
  }

  .md\:focus\:ring-offset-grey-A400:focus {
    --tw-ring-offset-color: #303030
  }

  .md\:focus\:ring-offset-grey-A700:focus {
    --tw-ring-offset-color: #616161
  }

  .md\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #FAFAFA
  }

  .md\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #F5F5F5
  }

  .md\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #EEEEEE
  }

  .md\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #E0E0E0
  }

  .md\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #BDBDBD
  }

  .md\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .md\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #757575
  }

  .md\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #616161
  }

  .md\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #424242
  }

  .md\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #212121
  }

  .md\:focus\:ring-offset-gray:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .md\:focus\:ring-offset-gray-hover:focus {
    --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
  }

  .md\:focus\:ring-offset-gray-A100:focus {
    --tw-ring-offset-color: #D5D5D5
  }

  .md\:focus\:ring-offset-gray-A200:focus {
    --tw-ring-offset-color: #AAAAAA
  }

  .md\:focus\:ring-offset-gray-A400:focus {
    --tw-ring-offset-color: #303030
  }

  .md\:focus\:ring-offset-gray-A700:focus {
    --tw-ring-offset-color: #616161
  }

  .md\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #FFEBEE
  }

  .md\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #FFCDD2
  }

  .md\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #EF9A9A
  }

  .md\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #E57373
  }

  .md\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #EF5350
  }

  .md\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #F44336
  }

  .md\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #E53935
  }

  .md\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #D32F2F
  }

  .md\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #C62828
  }

  .md\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #B71C1C
  }

  .md\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: #F44336
  }

  .md\:focus\:ring-offset-red-A100:focus {
    --tw-ring-offset-color: #FF8A80
  }

  .md\:focus\:ring-offset-red-A200:focus {
    --tw-ring-offset-color: #FF5252
  }

  .md\:focus\:ring-offset-red-A400:focus {
    --tw-ring-offset-color: #FF1744
  }

  .md\:focus\:ring-offset-red-A700:focus {
    --tw-ring-offset-color: #D50000
  }

  .md\:focus\:ring-offset-orange-50:focus {
    --tw-ring-offset-color: #FFF3E0
  }

  .md\:focus\:ring-offset-orange-100:focus {
    --tw-ring-offset-color: #FFE0B2
  }

  .md\:focus\:ring-offset-orange-200:focus {
    --tw-ring-offset-color: #FFCC80
  }

  .md\:focus\:ring-offset-orange-300:focus {
    --tw-ring-offset-color: #FFB74D
  }

  .md\:focus\:ring-offset-orange-400:focus {
    --tw-ring-offset-color: #FFA726
  }

  .md\:focus\:ring-offset-orange-500:focus {
    --tw-ring-offset-color: #FF9800
  }

  .md\:focus\:ring-offset-orange-600:focus {
    --tw-ring-offset-color: #FB8C00
  }

  .md\:focus\:ring-offset-orange-700:focus {
    --tw-ring-offset-color: #F57C00
  }

  .md\:focus\:ring-offset-orange-800:focus {
    --tw-ring-offset-color: #EF6C00
  }

  .md\:focus\:ring-offset-orange-900:focus {
    --tw-ring-offset-color: #E65100
  }

  .md\:focus\:ring-offset-orange:focus {
    --tw-ring-offset-color: #FF9800
  }

  .md\:focus\:ring-offset-orange-A100:focus {
    --tw-ring-offset-color: #FFD180
  }

  .md\:focus\:ring-offset-orange-A200:focus {
    --tw-ring-offset-color: #FFAB40
  }

  .md\:focus\:ring-offset-orange-A400:focus {
    --tw-ring-offset-color: #FF9100
  }

  .md\:focus\:ring-offset-orange-A700:focus {
    --tw-ring-offset-color: #FF6D00
  }

  .md\:focus\:ring-offset-deep-orange-50:focus {
    --tw-ring-offset-color: #FBE9E7
  }

  .md\:focus\:ring-offset-deep-orange-100:focus {
    --tw-ring-offset-color: #FFCCBC
  }

  .md\:focus\:ring-offset-deep-orange-200:focus {
    --tw-ring-offset-color: #FFAB91
  }

  .md\:focus\:ring-offset-deep-orange-300:focus {
    --tw-ring-offset-color: #FF8A65
  }

  .md\:focus\:ring-offset-deep-orange-400:focus {
    --tw-ring-offset-color: #FF7043
  }

  .md\:focus\:ring-offset-deep-orange-500:focus {
    --tw-ring-offset-color: #FF5722
  }

  .md\:focus\:ring-offset-deep-orange-600:focus {
    --tw-ring-offset-color: #F4511E
  }

  .md\:focus\:ring-offset-deep-orange-700:focus {
    --tw-ring-offset-color: #E64A19
  }

  .md\:focus\:ring-offset-deep-orange-800:focus {
    --tw-ring-offset-color: #D84315
  }

  .md\:focus\:ring-offset-deep-orange-900:focus {
    --tw-ring-offset-color: #BF360C
  }

  .md\:focus\:ring-offset-deep-orange:focus {
    --tw-ring-offset-color: #FF5722
  }

  .md\:focus\:ring-offset-deep-orange-A100:focus {
    --tw-ring-offset-color: #FF9E80
  }

  .md\:focus\:ring-offset-deep-orange-A200:focus {
    --tw-ring-offset-color: #FF6E40
  }

  .md\:focus\:ring-offset-deep-orange-A400:focus {
    --tw-ring-offset-color: #FF3D00
  }

  .md\:focus\:ring-offset-deep-orange-A700:focus {
    --tw-ring-offset-color: #DD2C00
  }

  .md\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #FFFDE7
  }

  .md\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #FFF9C4
  }

  .md\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #FFF59D
  }

  .md\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #FFF176
  }

  .md\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #FFEE58
  }

  .md\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #FFEB3B
  }

  .md\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #FDD835
  }

  .md\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #FBC02D
  }

  .md\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #F9A825
  }

  .md\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #F57F17
  }

  .md\:focus\:ring-offset-yellow:focus {
    --tw-ring-offset-color: #FFEB3B
  }

  .md\:focus\:ring-offset-yellow-A100:focus {
    --tw-ring-offset-color: #FFFF8D
  }

  .md\:focus\:ring-offset-yellow-A200:focus {
    --tw-ring-offset-color: #FFFF00
  }

  .md\:focus\:ring-offset-yellow-A400:focus {
    --tw-ring-offset-color: #FFEA00
  }

  .md\:focus\:ring-offset-yellow-A700:focus {
    --tw-ring-offset-color: #FFD600
  }

  .md\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #E8F5E9
  }

  .md\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #C8E6C9
  }

  .md\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #A5D6A7
  }

  .md\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #81C784
  }

  .md\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #66BB6A
  }

  .md\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #4CAF50
  }

  .md\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #43A047
  }

  .md\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #388E3C
  }

  .md\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #2E7D32
  }

  .md\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #1B5E20
  }

  .md\:focus\:ring-offset-green:focus {
    --tw-ring-offset-color: #4CAF50
  }

  .md\:focus\:ring-offset-green-A100:focus {
    --tw-ring-offset-color: #B9F6CA
  }

  .md\:focus\:ring-offset-green-A200:focus {
    --tw-ring-offset-color: #69F0AE
  }

  .md\:focus\:ring-offset-green-A400:focus {
    --tw-ring-offset-color: #00E676
  }

  .md\:focus\:ring-offset-green-A700:focus {
    --tw-ring-offset-color: #00C853
  }

  .md\:focus\:ring-offset-light-green-50:focus {
    --tw-ring-offset-color: #F1F8E9
  }

  .md\:focus\:ring-offset-light-green-100:focus {
    --tw-ring-offset-color: #DCEDC8
  }

  .md\:focus\:ring-offset-light-green-200:focus {
    --tw-ring-offset-color: #C5E1A5
  }

  .md\:focus\:ring-offset-light-green-300:focus {
    --tw-ring-offset-color: #AED581
  }

  .md\:focus\:ring-offset-light-green-400:focus {
    --tw-ring-offset-color: #9CCC65
  }

  .md\:focus\:ring-offset-light-green-500:focus {
    --tw-ring-offset-color: #8BC34A
  }

  .md\:focus\:ring-offset-light-green-600:focus {
    --tw-ring-offset-color: #7CB342
  }

  .md\:focus\:ring-offset-light-green-700:focus {
    --tw-ring-offset-color: #689F38
  }

  .md\:focus\:ring-offset-light-green-800:focus {
    --tw-ring-offset-color: #558B2F
  }

  .md\:focus\:ring-offset-light-green-900:focus {
    --tw-ring-offset-color: #33691E
  }

  .md\:focus\:ring-offset-light-green:focus {
    --tw-ring-offset-color: #8BC34A
  }

  .md\:focus\:ring-offset-light-green-A100:focus {
    --tw-ring-offset-color: #CCFF90
  }

  .md\:focus\:ring-offset-light-green-A200:focus {
    --tw-ring-offset-color: #B2FF59
  }

  .md\:focus\:ring-offset-light-green-A400:focus {
    --tw-ring-offset-color: #76FF03
  }

  .md\:focus\:ring-offset-light-green-A700:focus {
    --tw-ring-offset-color: #64DD17
  }

  .md\:focus\:ring-offset-teal-50:focus {
    --tw-ring-offset-color: #E0F2F1
  }

  .md\:focus\:ring-offset-teal-100:focus {
    --tw-ring-offset-color: #B2DFDB
  }

  .md\:focus\:ring-offset-teal-200:focus {
    --tw-ring-offset-color: #80CBC4
  }

  .md\:focus\:ring-offset-teal-300:focus {
    --tw-ring-offset-color: #4DB6AC
  }

  .md\:focus\:ring-offset-teal-400:focus {
    --tw-ring-offset-color: #26A69A
  }

  .md\:focus\:ring-offset-teal-500:focus {
    --tw-ring-offset-color: #009688
  }

  .md\:focus\:ring-offset-teal-600:focus {
    --tw-ring-offset-color: #00897B
  }

  .md\:focus\:ring-offset-teal-700:focus {
    --tw-ring-offset-color: #00796B
  }

  .md\:focus\:ring-offset-teal-800:focus {
    --tw-ring-offset-color: #00695C
  }

  .md\:focus\:ring-offset-teal-900:focus {
    --tw-ring-offset-color: #004D40
  }

  .md\:focus\:ring-offset-teal:focus {
    --tw-ring-offset-color: #009688
  }

  .md\:focus\:ring-offset-teal-A100:focus {
    --tw-ring-offset-color: #A7FFEB
  }

  .md\:focus\:ring-offset-teal-A200:focus {
    --tw-ring-offset-color: #64FFDA
  }

  .md\:focus\:ring-offset-teal-A400:focus {
    --tw-ring-offset-color: #1DE9B6
  }

  .md\:focus\:ring-offset-teal-A700:focus {
    --tw-ring-offset-color: #00BFA5
  }

  .md\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #E3F2FD
  }

  .md\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #BBDEFB
  }

  .md\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #90CAF9
  }

  .md\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #64B5F6
  }

  .md\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #42A5F5
  }

  .md\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #2196F3
  }

  .md\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #1E88E5
  }

  .md\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1976D2
  }

  .md\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1565C0
  }

  .md\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #0D47A1
  }

  .md\:focus\:ring-offset-blue:focus {
    --tw-ring-offset-color: #2196F3
  }

  .md\:focus\:ring-offset-blue-A100:focus {
    --tw-ring-offset-color: #82B1FF
  }

  .md\:focus\:ring-offset-blue-A200:focus {
    --tw-ring-offset-color: #448AFF
  }

  .md\:focus\:ring-offset-blue-A400:focus {
    --tw-ring-offset-color: #2979FF
  }

  .md\:focus\:ring-offset-blue-A700:focus {
    --tw-ring-offset-color: #2962FF
  }

  .md\:focus\:ring-offset-light-blue-50:focus {
    --tw-ring-offset-color: #E1F5FE
  }

  .md\:focus\:ring-offset-light-blue-100:focus {
    --tw-ring-offset-color: #B3E5FC
  }

  .md\:focus\:ring-offset-light-blue-200:focus {
    --tw-ring-offset-color: #81D4FA
  }

  .md\:focus\:ring-offset-light-blue-300:focus {
    --tw-ring-offset-color: #4FC3F7
  }

  .md\:focus\:ring-offset-light-blue-400:focus {
    --tw-ring-offset-color: #29B6F6
  }

  .md\:focus\:ring-offset-light-blue-500:focus {
    --tw-ring-offset-color: #03A9F4
  }

  .md\:focus\:ring-offset-light-blue-600:focus {
    --tw-ring-offset-color: #039BE5
  }

  .md\:focus\:ring-offset-light-blue-700:focus {
    --tw-ring-offset-color: #0288D1
  }

  .md\:focus\:ring-offset-light-blue-800:focus {
    --tw-ring-offset-color: #0277BD
  }

  .md\:focus\:ring-offset-light-blue-900:focus {
    --tw-ring-offset-color: #01579B
  }

  .md\:focus\:ring-offset-light-blue:focus {
    --tw-ring-offset-color: #03A9F4
  }

  .md\:focus\:ring-offset-light-blue-A100:focus {
    --tw-ring-offset-color: #80D8FF
  }

  .md\:focus\:ring-offset-light-blue-A200:focus {
    --tw-ring-offset-color: #40C4FF
  }

  .md\:focus\:ring-offset-light-blue-A400:focus {
    --tw-ring-offset-color: #00B0FF
  }

  .md\:focus\:ring-offset-light-blue-A700:focus {
    --tw-ring-offset-color: #0091EA
  }

  .md\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #E8EAF6
  }

  .md\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #C5CAE9
  }

  .md\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #9FA8DA
  }

  .md\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #7986CB
  }

  .md\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #5C6BC0
  }

  .md\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #3F51B5
  }

  .md\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #3949AB
  }

  .md\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #303F9F
  }

  .md\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #283593
  }

  .md\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #1A237E
  }

  .md\:focus\:ring-offset-indigo:focus {
    --tw-ring-offset-color: #3F51B5
  }

  .md\:focus\:ring-offset-indigo-A100:focus {
    --tw-ring-offset-color: #8C9EFF
  }

  .md\:focus\:ring-offset-indigo-A200:focus {
    --tw-ring-offset-color: #536DFE
  }

  .md\:focus\:ring-offset-indigo-A400:focus {
    --tw-ring-offset-color: #3D5AFE
  }

  .md\:focus\:ring-offset-indigo-A700:focus {
    --tw-ring-offset-color: #304FFE
  }

  .md\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #F3E5F5
  }

  .md\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #E1BEE7
  }

  .md\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #CE93D8
  }

  .md\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #BA68C8
  }

  .md\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #AB47BC
  }

  .md\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #9C27B0
  }

  .md\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #8E24AA
  }

  .md\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #7B1FA2
  }

  .md\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #6A1B9A
  }

  .md\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4A148C
  }

  .md\:focus\:ring-offset-purple:focus {
    --tw-ring-offset-color: #9C27B0
  }

  .md\:focus\:ring-offset-purple-A100:focus {
    --tw-ring-offset-color: #EA80FC
  }

  .md\:focus\:ring-offset-purple-A200:focus {
    --tw-ring-offset-color: #E040FB
  }

  .md\:focus\:ring-offset-purple-A400:focus {
    --tw-ring-offset-color: #D500F9
  }

  .md\:focus\:ring-offset-purple-A700:focus {
    --tw-ring-offset-color: #AA00FF
  }

  .md\:focus\:ring-offset-deep-purple-50:focus {
    --tw-ring-offset-color: #EDE7F6
  }

  .md\:focus\:ring-offset-deep-purple-100:focus {
    --tw-ring-offset-color: #D1C4E9
  }

  .md\:focus\:ring-offset-deep-purple-200:focus {
    --tw-ring-offset-color: #B39DDB
  }

  .md\:focus\:ring-offset-deep-purple-300:focus {
    --tw-ring-offset-color: #9575CD
  }

  .md\:focus\:ring-offset-deep-purple-400:focus {
    --tw-ring-offset-color: #7E57C2
  }

  .md\:focus\:ring-offset-deep-purple-500:focus {
    --tw-ring-offset-color: #673AB7
  }

  .md\:focus\:ring-offset-deep-purple-600:focus {
    --tw-ring-offset-color: #5E35B1
  }

  .md\:focus\:ring-offset-deep-purple-700:focus {
    --tw-ring-offset-color: #512DA8
  }

  .md\:focus\:ring-offset-deep-purple-800:focus {
    --tw-ring-offset-color: #4527A0
  }

  .md\:focus\:ring-offset-deep-purple-900:focus {
    --tw-ring-offset-color: #311B92
  }

  .md\:focus\:ring-offset-deep-purple:focus {
    --tw-ring-offset-color: #673AB7
  }

  .md\:focus\:ring-offset-deep-purple-A100:focus {
    --tw-ring-offset-color: #B388FF
  }

  .md\:focus\:ring-offset-deep-purple-A200:focus {
    --tw-ring-offset-color: #7C4DFF
  }

  .md\:focus\:ring-offset-deep-purple-A400:focus {
    --tw-ring-offset-color: #651FFF
  }

  .md\:focus\:ring-offset-deep-purple-A700:focus {
    --tw-ring-offset-color: #6200EA
  }

  .md\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #FCE4EC
  }

  .md\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #F8BBD0
  }

  .md\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #F48FB1
  }

  .md\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #F06292
  }

  .md\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #EC407A
  }

  .md\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #E91E63
  }

  .md\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #D81B60
  }

  .md\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #C2185B
  }

  .md\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #AD1457
  }

  .md\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #880E4F
  }

  .md\:focus\:ring-offset-pink:focus {
    --tw-ring-offset-color: #E91E63
  }

  .md\:focus\:ring-offset-pink-A100:focus {
    --tw-ring-offset-color: #FF80AB
  }

  .md\:focus\:ring-offset-pink-A200:focus {
    --tw-ring-offset-color: #FF4081
  }

  .md\:focus\:ring-offset-pink-A400:focus {
    --tw-ring-offset-color: #F50057
  }

  .md\:focus\:ring-offset-pink-A700:focus {
    --tw-ring-offset-color: #C51162
  }

  .md\:focus\:ring-offset-lime-50:focus {
    --tw-ring-offset-color: #F9FBE7
  }

  .md\:focus\:ring-offset-lime-100:focus {
    --tw-ring-offset-color: #F0F4C3
  }

  .md\:focus\:ring-offset-lime-200:focus {
    --tw-ring-offset-color: #E6EE9C
  }

  .md\:focus\:ring-offset-lime-300:focus {
    --tw-ring-offset-color: #DCE775
  }

  .md\:focus\:ring-offset-lime-400:focus {
    --tw-ring-offset-color: #D4E157
  }

  .md\:focus\:ring-offset-lime-500:focus {
    --tw-ring-offset-color: #CDDC39
  }

  .md\:focus\:ring-offset-lime-600:focus {
    --tw-ring-offset-color: #C0CA33
  }

  .md\:focus\:ring-offset-lime-700:focus {
    --tw-ring-offset-color: #AFB42B
  }

  .md\:focus\:ring-offset-lime-800:focus {
    --tw-ring-offset-color: #9E9D24
  }

  .md\:focus\:ring-offset-lime-900:focus {
    --tw-ring-offset-color: #827717
  }

  .md\:focus\:ring-offset-lime:focus {
    --tw-ring-offset-color: #CDDC39
  }

  .md\:focus\:ring-offset-lime-A100:focus {
    --tw-ring-offset-color: #F4FF81
  }

  .md\:focus\:ring-offset-lime-A200:focus {
    --tw-ring-offset-color: #EEFF41
  }

  .md\:focus\:ring-offset-lime-A400:focus {
    --tw-ring-offset-color: #C6FF00
  }

  .md\:focus\:ring-offset-lime-A700:focus {
    --tw-ring-offset-color: #AEEA00
  }

  .md\:focus\:ring-offset-amber-50:focus {
    --tw-ring-offset-color: #FFF8E1
  }

  .md\:focus\:ring-offset-amber-100:focus {
    --tw-ring-offset-color: #FFECB3
  }

  .md\:focus\:ring-offset-amber-200:focus {
    --tw-ring-offset-color: #FFE082
  }

  .md\:focus\:ring-offset-amber-300:focus {
    --tw-ring-offset-color: #FFD54F
  }

  .md\:focus\:ring-offset-amber-400:focus {
    --tw-ring-offset-color: #FFCA28
  }

  .md\:focus\:ring-offset-amber-500:focus {
    --tw-ring-offset-color: #FFC107
  }

  .md\:focus\:ring-offset-amber-600:focus {
    --tw-ring-offset-color: #FFB300
  }

  .md\:focus\:ring-offset-amber-700:focus {
    --tw-ring-offset-color: #FFA000
  }

  .md\:focus\:ring-offset-amber-800:focus {
    --tw-ring-offset-color: #FF8F00
  }

  .md\:focus\:ring-offset-amber-900:focus {
    --tw-ring-offset-color: #FF6F00
  }

  .md\:focus\:ring-offset-amber:focus {
    --tw-ring-offset-color: #FFC107
  }

  .md\:focus\:ring-offset-amber-A100:focus {
    --tw-ring-offset-color: #FFE57F
  }

  .md\:focus\:ring-offset-amber-A200:focus {
    --tw-ring-offset-color: #FFD740
  }

  .md\:focus\:ring-offset-amber-A400:focus {
    --tw-ring-offset-color: #FFC400
  }

  .md\:focus\:ring-offset-amber-A700:focus {
    --tw-ring-offset-color: #FFAB00
  }

  .md\:focus\:ring-offset-brown-50:focus {
    --tw-ring-offset-color: #EFEBE9
  }

  .md\:focus\:ring-offset-brown-100:focus {
    --tw-ring-offset-color: #D7CCC8
  }

  .md\:focus\:ring-offset-brown-200:focus {
    --tw-ring-offset-color: #BCAAA4
  }

  .md\:focus\:ring-offset-brown-300:focus {
    --tw-ring-offset-color: #A1887F
  }

  .md\:focus\:ring-offset-brown-400:focus {
    --tw-ring-offset-color: #8D6E63
  }

  .md\:focus\:ring-offset-brown-500:focus {
    --tw-ring-offset-color: #795548
  }

  .md\:focus\:ring-offset-brown-600:focus {
    --tw-ring-offset-color: #6D4C41
  }

  .md\:focus\:ring-offset-brown-700:focus {
    --tw-ring-offset-color: #5D4037
  }

  .md\:focus\:ring-offset-brown-800:focus {
    --tw-ring-offset-color: #4E342E
  }

  .md\:focus\:ring-offset-brown-900:focus {
    --tw-ring-offset-color: #3E2723
  }

  .md\:focus\:ring-offset-brown:focus {
    --tw-ring-offset-color: #795548
  }

  .md\:focus\:ring-offset-brown-A100:focus {
    --tw-ring-offset-color: #D7CCC8
  }

  .md\:focus\:ring-offset-brown-A200:focus {
    --tw-ring-offset-color: #BCAAA4
  }

  .md\:focus\:ring-offset-brown-A400:focus {
    --tw-ring-offset-color: #8D6E63
  }

  .md\:focus\:ring-offset-brown-A700:focus {
    --tw-ring-offset-color: #5D4037
  }

  .md\:focus\:ring-offset-blue-gray-50:focus {
    --tw-ring-offset-color: #ECEFF1
  }

  .md\:focus\:ring-offset-blue-gray-100:focus {
    --tw-ring-offset-color: #CFD8DC
  }

  .md\:focus\:ring-offset-blue-gray-200:focus {
    --tw-ring-offset-color: #B0BEC5
  }

  .md\:focus\:ring-offset-blue-gray-300:focus {
    --tw-ring-offset-color: #90A4AE
  }

  .md\:focus\:ring-offset-blue-gray-400:focus {
    --tw-ring-offset-color: #78909C
  }

  .md\:focus\:ring-offset-blue-gray-500:focus {
    --tw-ring-offset-color: #607D8B
  }

  .md\:focus\:ring-offset-blue-gray-600:focus {
    --tw-ring-offset-color: #546E7A
  }

  .md\:focus\:ring-offset-blue-gray-700:focus {
    --tw-ring-offset-color: #455A64
  }

  .md\:focus\:ring-offset-blue-gray-800:focus {
    --tw-ring-offset-color: #37474F
  }

  .md\:focus\:ring-offset-blue-gray-900:focus {
    --tw-ring-offset-color: #263238
  }

  .md\:focus\:ring-offset-blue-gray:focus {
    --tw-ring-offset-color: #607D8B
  }

  .md\:focus\:ring-offset-blue-gray-A100:focus {
    --tw-ring-offset-color: #CFD8DC
  }

  .md\:focus\:ring-offset-blue-gray-A200:focus {
    --tw-ring-offset-color: #B0BEC5
  }

  .md\:focus\:ring-offset-blue-gray-A400:focus {
    --tw-ring-offset-color: #78909C
  }

  .md\:focus\:ring-offset-blue-gray-A700:focus {
    --tw-ring-offset-color: #455A64
  }

  .md\:focus\:ring-offset-cyan-50:focus {
    --tw-ring-offset-color: #E0F7FA
  }

  .md\:focus\:ring-offset-cyan-100:focus {
    --tw-ring-offset-color: #B2EBF2
  }

  .md\:focus\:ring-offset-cyan-200:focus {
    --tw-ring-offset-color: #80DEEA
  }

  .md\:focus\:ring-offset-cyan-300:focus {
    --tw-ring-offset-color: #4DD0E1
  }

  .md\:focus\:ring-offset-cyan-400:focus {
    --tw-ring-offset-color: #26C6DA
  }

  .md\:focus\:ring-offset-cyan-500:focus {
    --tw-ring-offset-color: #00BCD4
  }

  .md\:focus\:ring-offset-cyan-600:focus {
    --tw-ring-offset-color: #00ACC1
  }

  .md\:focus\:ring-offset-cyan-700:focus {
    --tw-ring-offset-color: #0097A7
  }

  .md\:focus\:ring-offset-cyan-800:focus {
    --tw-ring-offset-color: #00838F
  }

  .md\:focus\:ring-offset-cyan-900:focus {
    --tw-ring-offset-color: #006064
  }

  .md\:focus\:ring-offset-cyan:focus {
    --tw-ring-offset-color: #00BCD4
  }

  .md\:focus\:ring-offset-cyan-A100:focus {
    --tw-ring-offset-color: #84FFFF
  }

  .md\:focus\:ring-offset-cyan-A200:focus {
    --tw-ring-offset-color: #18FFFF
  }

  .md\:focus\:ring-offset-cyan-A400:focus {
    --tw-ring-offset-color: #00E5FF
  }

  .md\:focus\:ring-offset-cyan-A700:focus {
    --tw-ring-offset-color: #00B8D4
  }

  .md\:ring-offset-0 {
    --tw-ring-offset-width: 0px
  }

  .md\:ring-offset-1 {
    --tw-ring-offset-width: 1px
  }

  .md\:ring-offset-2 {
    --tw-ring-offset-width: 2px
  }

  .md\:ring-offset-4 {
    --tw-ring-offset-width: 4px
  }

  .md\:ring-offset-8 {
    --tw-ring-offset-width: 8px
  }

  .md\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px
  }

  .md\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px
  }

  .md\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px
  }

  .md\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px
  }

  .md\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px
  }

  .md\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px
  }

  .md\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px
  }

  .md\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px
  }

  .md\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px
  }

  .md\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px
  }

  .md\:ring-transparent {
    --tw-ring-color: transparent
  }

  .md\:ring-current {
    --tw-ring-color: currentColor
  }

  .md\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .md\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .md\:ring-grey-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .md\:ring-grey-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .md\:ring-grey-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .md\:ring-grey-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .md\:ring-grey-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .md\:ring-grey-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .md\:ring-grey-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .md\:ring-grey-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .md\:ring-grey-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .md\:ring-grey-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .md\:ring-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .md\:ring-grey-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .md\:ring-grey-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .md\:ring-grey-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .md\:ring-grey-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .md\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .md\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .md\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .md\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .md\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .md\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .md\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .md\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .md\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .md\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .md\:ring-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .md\:ring-gray-hover {
    --tw-ring-color: rgba(0, 0, 0, 0.04)
  }

  .md\:ring-gray-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .md\:ring-gray-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .md\:ring-gray-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .md\:ring-gray-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .md\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .md\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .md\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .md\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .md\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .md\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .md\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .md\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .md\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .md\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .md\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .md\:ring-red-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .md\:ring-red-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .md\:ring-red-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .md\:ring-red-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .md\:ring-orange-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .md\:ring-orange-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .md\:ring-orange-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .md\:ring-orange-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .md\:ring-orange-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .md\:ring-orange-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .md\:ring-orange-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .md\:ring-orange-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .md\:ring-orange-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .md\:ring-orange-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .md\:ring-orange {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .md\:ring-orange-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .md\:ring-orange-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .md\:ring-orange-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .md\:ring-orange-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .md\:ring-deep-orange-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .md\:ring-deep-orange-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .md\:ring-deep-orange-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .md\:ring-deep-orange-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .md\:ring-deep-orange-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .md\:ring-deep-orange-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .md\:ring-deep-orange-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .md\:ring-deep-orange-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .md\:ring-deep-orange-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .md\:ring-deep-orange-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .md\:ring-deep-orange {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .md\:ring-deep-orange-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .md\:ring-deep-orange-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .md\:ring-deep-orange-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .md\:ring-deep-orange-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .md\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .md\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .md\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .md\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .md\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .md\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .md\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .md\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .md\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .md\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .md\:ring-yellow {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .md\:ring-yellow-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .md\:ring-yellow-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .md\:ring-yellow-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .md\:ring-yellow-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .md\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .md\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .md\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .md\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .md\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .md\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .md\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .md\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .md\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .md\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .md\:ring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .md\:ring-green-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .md\:ring-green-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .md\:ring-green-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .md\:ring-green-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .md\:ring-light-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .md\:ring-light-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .md\:ring-light-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .md\:ring-light-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .md\:ring-light-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .md\:ring-light-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .md\:ring-light-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .md\:ring-light-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .md\:ring-light-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .md\:ring-light-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .md\:ring-light-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .md\:ring-light-green-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .md\:ring-light-green-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .md\:ring-light-green-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .md\:ring-light-green-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .md\:ring-teal-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .md\:ring-teal-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .md\:ring-teal-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .md\:ring-teal-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .md\:ring-teal-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .md\:ring-teal-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .md\:ring-teal-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .md\:ring-teal-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .md\:ring-teal-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .md\:ring-teal-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .md\:ring-teal {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .md\:ring-teal-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .md\:ring-teal-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .md\:ring-teal-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .md\:ring-teal-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .md\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .md\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .md\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .md\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .md\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .md\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .md\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .md\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .md\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .md\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .md\:ring-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .md\:ring-blue-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .md\:ring-blue-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .md\:ring-blue-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .md\:ring-blue-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .md\:ring-light-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .md\:ring-light-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .md\:ring-light-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .md\:ring-light-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .md\:ring-light-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .md\:ring-light-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .md\:ring-light-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .md\:ring-light-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .md\:ring-light-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .md\:ring-light-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .md\:ring-light-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .md\:ring-light-blue-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .md\:ring-light-blue-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .md\:ring-light-blue-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .md\:ring-light-blue-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .md\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .md\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .md\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .md\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .md\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .md\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .md\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .md\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .md\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .md\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .md\:ring-indigo {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .md\:ring-indigo-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .md\:ring-indigo-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .md\:ring-indigo-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .md\:ring-indigo-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .md\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .md\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .md\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .md\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .md\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .md\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .md\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .md\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .md\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .md\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .md\:ring-purple {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .md\:ring-purple-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .md\:ring-purple-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .md\:ring-purple-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .md\:ring-purple-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .md\:ring-deep-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .md\:ring-deep-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .md\:ring-deep-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .md\:ring-deep-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .md\:ring-deep-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .md\:ring-deep-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .md\:ring-deep-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .md\:ring-deep-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .md\:ring-deep-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .md\:ring-deep-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .md\:ring-deep-purple {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .md\:ring-deep-purple-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .md\:ring-deep-purple-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .md\:ring-deep-purple-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .md\:ring-deep-purple-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .md\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .md\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .md\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .md\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .md\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .md\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .md\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .md\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .md\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .md\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .md\:ring-pink {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .md\:ring-pink-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .md\:ring-pink-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .md\:ring-pink-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .md\:ring-pink-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .md\:ring-lime-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .md\:ring-lime-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .md\:ring-lime-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .md\:ring-lime-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .md\:ring-lime-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .md\:ring-lime-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .md\:ring-lime-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .md\:ring-lime-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .md\:ring-lime-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .md\:ring-lime-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .md\:ring-lime {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .md\:ring-lime-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .md\:ring-lime-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .md\:ring-lime-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .md\:ring-lime-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .md\:ring-amber-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .md\:ring-amber-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .md\:ring-amber-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .md\:ring-amber-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .md\:ring-amber-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .md\:ring-amber-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .md\:ring-amber-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .md\:ring-amber-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .md\:ring-amber-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .md\:ring-amber-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .md\:ring-amber {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .md\:ring-amber-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .md\:ring-amber-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .md\:ring-amber-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .md\:ring-amber-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .md\:ring-brown-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .md\:ring-brown-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .md\:ring-brown-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .md\:ring-brown-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .md\:ring-brown-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .md\:ring-brown-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .md\:ring-brown-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .md\:ring-brown-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .md\:ring-brown-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .md\:ring-brown-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .md\:ring-brown {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .md\:ring-brown-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .md\:ring-brown-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .md\:ring-brown-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .md\:ring-brown-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .md\:ring-blue-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .md\:ring-blue-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .md\:ring-blue-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .md\:ring-blue-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .md\:ring-blue-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .md\:ring-blue-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .md\:ring-blue-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .md\:ring-blue-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .md\:ring-blue-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .md\:ring-blue-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .md\:ring-blue-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .md\:ring-blue-gray-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .md\:ring-blue-gray-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .md\:ring-blue-gray-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .md\:ring-blue-gray-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .md\:ring-cyan-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .md\:ring-cyan-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .md\:ring-cyan-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .md\:ring-cyan-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .md\:ring-cyan-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .md\:ring-cyan-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .md\:ring-cyan-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .md\:ring-cyan-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .md\:ring-cyan-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .md\:ring-cyan-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .md\:ring-cyan {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .md\:ring-cyan-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .md\:ring-cyan-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .md\:ring-cyan-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .md\:ring-cyan-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent
  }

  .md\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor
  }

  .md\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-grey-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-grey-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-grey-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-grey-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-grey-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-grey-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-grey-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-grey-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-grey-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-grey-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-grey-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-grey-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-grey-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-grey-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray-hover:focus-within {
    --tw-ring-color: rgba(0, 0, 0, 0.04)
  }

  .md\:focus-within\:ring-gray-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-orange-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-orange-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-orange-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-orange-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-orange-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-orange-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-orange-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-orange-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-orange-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-orange-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-orange:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-orange-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-orange-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-orange-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-orange-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-orange-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-orange-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-orange-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-orange-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-orange-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-orange-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-orange-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-orange-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-orange-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-orange-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-orange:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-orange-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-orange-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-orange-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-orange-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-yellow:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-yellow-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-yellow-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-yellow-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-yellow-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-green-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-green-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-green-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-green-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-teal-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-teal-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-teal-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-teal-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-teal-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-teal-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-teal-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-teal-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-teal-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-teal-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-teal:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-teal-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-teal-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-teal-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-teal-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-blue-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-blue-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-blue-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-light-blue-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-indigo:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-indigo-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-indigo-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-indigo-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-indigo-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-purple:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-purple-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-purple-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-purple-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-purple-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-purple:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-purple-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-purple-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-purple-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-deep-purple-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-pink:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-pink-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-pink-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-pink-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-pink-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-lime-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-lime-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-lime-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-lime-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-lime-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-lime-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-lime-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-lime-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-lime-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-lime-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-lime:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-lime-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-lime-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-lime-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-lime-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-amber-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-amber-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-amber-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-amber-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-amber-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-amber-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-amber-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-amber-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-amber-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-amber-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-amber:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-amber-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-amber-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-amber-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-amber-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-brown-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-brown-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-brown-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-brown-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-brown-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-brown-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-brown-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-brown-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-brown-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-brown-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-brown:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-brown-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-brown-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-brown-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-brown-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-gray-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-gray-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-gray-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-blue-gray-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent
  }

  .md\:focus\:ring-current:focus {
    --tw-ring-color: currentColor
  }

  .md\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-grey-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-grey-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-grey-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-grey-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-grey-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-grey-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-grey-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-grey-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-grey-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-grey-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-grey-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-grey-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-grey-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-grey-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray-hover:focus {
    --tw-ring-color: rgba(0, 0, 0, 0.04)
  }

  .md\:focus\:ring-gray-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-orange-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-orange-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-orange-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-orange-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-orange-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-orange-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-orange-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-orange-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-orange-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-orange-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-orange:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-orange-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-orange-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-orange-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-orange-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-orange-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-orange-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-orange-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-orange-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-orange-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-orange-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-orange-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-orange-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-orange-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-orange-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-orange:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-orange-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-orange-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-orange-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-orange-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-yellow:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-yellow-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-yellow-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-yellow-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-yellow-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-green-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-green-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-green-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-green-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-teal-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-teal-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-teal-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-teal-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-teal-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-teal-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-teal-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-teal-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-teal-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-teal-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-teal:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-teal-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-teal-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-teal-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-teal-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-blue-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-blue-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-blue-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-light-blue-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-indigo:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-indigo-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-indigo-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-indigo-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-indigo-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-purple:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-purple-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-purple-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-purple-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-purple-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-purple:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-purple-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-purple-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-purple-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-deep-purple-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-pink:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-pink-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-pink-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-pink-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-pink-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-lime-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-lime-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-lime-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-lime-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-lime-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-lime-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-lime-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-lime-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-lime-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-lime-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-lime:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-lime-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-lime-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-lime-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-lime-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-amber-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-amber-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-amber-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-amber-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-amber-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-amber-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-amber-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-amber-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-amber-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-amber-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-amber:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-amber-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-amber-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-amber-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-amber-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-brown-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-brown-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-brown-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-brown-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-brown-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-brown-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-brown-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-brown-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-brown-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-brown-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-brown:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-brown-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-brown-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-brown-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-brown-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-gray-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-gray-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-gray-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-blue-gray-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .md\:ring-opacity-0 {
    --tw-ring-opacity: 0
  }

  .md\:ring-opacity-5 {
    --tw-ring-opacity: 0.05
  }

  .md\:ring-opacity-10 {
    --tw-ring-opacity: 0.1
  }

  .md\:ring-opacity-20 {
    --tw-ring-opacity: 0.2
  }

  .md\:ring-opacity-25 {
    --tw-ring-opacity: 0.25
  }

  .md\:ring-opacity-30 {
    --tw-ring-opacity: 0.3
  }

  .md\:ring-opacity-40 {
    --tw-ring-opacity: 0.4
  }

  .md\:ring-opacity-50 {
    --tw-ring-opacity: 0.5
  }

  .md\:ring-opacity-60 {
    --tw-ring-opacity: 0.6
  }

  .md\:ring-opacity-70 {
    --tw-ring-opacity: 0.7
  }

  .md\:ring-opacity-75 {
    --tw-ring-opacity: 0.75
  }

  .md\:ring-opacity-80 {
    --tw-ring-opacity: 0.8
  }

  .md\:ring-opacity-90 {
    --tw-ring-opacity: 0.9
  }

  .md\:ring-opacity-95 {
    --tw-ring-opacity: 0.95
  }

  .md\:ring-opacity-100 {
    --tw-ring-opacity: 1
  }

  .md\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0
  }

  .md\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05
  }

  .md\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1
  }

  .md\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2
  }

  .md\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25
  }

  .md\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3
  }

  .md\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4
  }

  .md\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5
  }

  .md\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6
  }

  .md\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7
  }

  .md\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75
  }

  .md\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8
  }

  .md\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9
  }

  .md\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95
  }

  .md\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1
  }

  .md\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0
  }

  .md\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05
  }

  .md\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1
  }

  .md\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2
  }

  .md\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25
  }

  .md\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3
  }

  .md\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4
  }

  .md\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5
  }

  .md\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6
  }

  .md\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7
  }

  .md\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75
  }

  .md\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8
  }

  .md\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9
  }

  .md\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95
  }

  .md\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1
  }

  .md\:fill-current {
    fill: currentColor
  }

  .md\:stroke-current {
    stroke: currentColor
  }

  .md\:stroke-0 {
    stroke-width: 0
  }

  .md\:stroke-1 {
    stroke-width: 1
  }

  .md\:stroke-2 {
    stroke-width: 2
  }

  .md\:table-auto {
    table-layout: auto
  }

  .md\:table-fixed {
    table-layout: fixed
  }

  .md\:text-left {
    text-align: left
  }

  .md\:text-center {
    text-align: center
  }

  .md\:text-right {
    text-align: right
  }

  .md\:text-justify {
    text-align: justify
  }

  [dir='ltr'] .md\:ltr\:text-left,[dir='ltr'].md\:ltr\:text-left {
    text-align: left
  }

  [dir='ltr'] .md\:ltr\:text-center,[dir='ltr'].md\:ltr\:text-center {
    text-align: center
  }

  [dir='ltr'] .md\:ltr\:text-right,[dir='ltr'].md\:ltr\:text-right {
    text-align: right
  }

  [dir='ltr'] .md\:ltr\:text-justify,[dir='ltr'].md\:ltr\:text-justify {
    text-align: justify
  }

  [dir='rtl'] .md\:rtl\:text-left,[dir='rtl'].md\:rtl\:text-left {
    text-align: left
  }

  [dir='rtl'] .md\:rtl\:text-center,[dir='rtl'].md\:rtl\:text-center {
    text-align: center
  }

  [dir='rtl'] .md\:rtl\:text-right,[dir='rtl'].md\:rtl\:text-right {
    text-align: right
  }

  [dir='rtl'] .md\:rtl\:text-justify,[dir='rtl'].md\:rtl\:text-justify {
    text-align: justify
  }

  .md\:text-transparent {
    color: transparent
  }

  .md\:text-current {
    color: currentColor
  }

  .md\:text-black {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .md\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .md\:text-grey-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .md\:text-grey-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .md\:text-grey-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .md\:text-grey-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .md\:text-grey-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .md\:text-grey-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:text-grey-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .md\:text-grey-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:text-grey-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .md\:text-grey-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .md\:text-grey {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:text-grey-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .md\:text-grey-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .md\:text-grey-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .md\:text-grey-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .md\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .md\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .md\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .md\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .md\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .md\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .md\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .md\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:text-gray-hover {
    color: rgba(0, 0, 0, 0.04)
  }

  .md\:text-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .md\:text-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .md\:text-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .md\:text-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .md\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .md\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .md\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .md\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .md\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .md\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .md\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .md\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .md\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .md\:text-red {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .md\:text-red-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .md\:text-red-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .md\:text-red-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .md\:text-red-A700 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .md\:text-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .md\:text-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .md\:text-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .md\:text-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .md\:text-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .md\:text-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .md\:text-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .md\:text-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .md\:text-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .md\:text-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .md\:text-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .md\:text-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .md\:text-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .md\:text-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .md\:text-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .md\:text-deep-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .md\:text-deep-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .md\:text-deep-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .md\:text-deep-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .md\:text-deep-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .md\:text-deep-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .md\:text-deep-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .md\:text-deep-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .md\:text-deep-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .md\:text-deep-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .md\:text-deep-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .md\:text-deep-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .md\:text-deep-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .md\:text-deep-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .md\:text-deep-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .md\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .md\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .md\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .md\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .md\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .md\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .md\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .md\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .md\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .md\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .md\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .md\:text-yellow-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .md\:text-yellow-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .md\:text-yellow-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .md\:text-yellow-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .md\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .md\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .md\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .md\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .md\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .md\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .md\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .md\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .md\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .md\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .md\:text-green {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .md\:text-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .md\:text-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .md\:text-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .md\:text-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .md\:text-light-green-50 {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .md\:text-light-green-100 {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .md\:text-light-green-200 {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .md\:text-light-green-300 {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .md\:text-light-green-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .md\:text-light-green-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .md\:text-light-green-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .md\:text-light-green-700 {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .md\:text-light-green-800 {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .md\:text-light-green-900 {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .md\:text-light-green {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .md\:text-light-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .md\:text-light-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .md\:text-light-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .md\:text-light-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .md\:text-teal-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .md\:text-teal-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .md\:text-teal-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .md\:text-teal-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .md\:text-teal-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .md\:text-teal-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .md\:text-teal-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .md\:text-teal-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .md\:text-teal-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .md\:text-teal-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .md\:text-teal {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .md\:text-teal-A100 {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .md\:text-teal-A200 {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .md\:text-teal-A400 {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .md\:text-teal-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .md\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .md\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .md\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .md\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .md\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .md\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .md\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .md\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .md\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .md\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .md\:text-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .md\:text-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .md\:text-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .md\:text-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .md\:text-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .md\:text-light-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .md\:text-light-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .md\:text-light-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .md\:text-light-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .md\:text-light-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .md\:text-light-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .md\:text-light-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .md\:text-light-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .md\:text-light-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .md\:text-light-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .md\:text-light-blue {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .md\:text-light-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .md\:text-light-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .md\:text-light-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .md\:text-light-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .md\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .md\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .md\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .md\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .md\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .md\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .md\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .md\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .md\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .md\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .md\:text-indigo {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .md\:text-indigo-A100 {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .md\:text-indigo-A200 {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .md\:text-indigo-A400 {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .md\:text-indigo-A700 {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .md\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .md\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .md\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .md\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .md\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .md\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .md\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .md\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .md\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .md\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .md\:text-purple {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .md\:text-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .md\:text-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .md\:text-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .md\:text-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .md\:text-deep-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .md\:text-deep-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .md\:text-deep-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .md\:text-deep-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .md\:text-deep-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .md\:text-deep-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .md\:text-deep-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .md\:text-deep-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .md\:text-deep-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .md\:text-deep-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .md\:text-deep-purple {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .md\:text-deep-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .md\:text-deep-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .md\:text-deep-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .md\:text-deep-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .md\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .md\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .md\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .md\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .md\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .md\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .md\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .md\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .md\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .md\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .md\:text-pink {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .md\:text-pink-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .md\:text-pink-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .md\:text-pink-A400 {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .md\:text-pink-A700 {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .md\:text-lime-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .md\:text-lime-100 {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .md\:text-lime-200 {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .md\:text-lime-300 {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .md\:text-lime-400 {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .md\:text-lime-500 {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .md\:text-lime-600 {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .md\:text-lime-700 {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .md\:text-lime-800 {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .md\:text-lime-900 {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .md\:text-lime {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .md\:text-lime-A100 {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .md\:text-lime-A200 {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .md\:text-lime-A400 {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .md\:text-lime-A700 {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .md\:text-amber-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .md\:text-amber-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .md\:text-amber-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .md\:text-amber-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .md\:text-amber-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .md\:text-amber-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .md\:text-amber-600 {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .md\:text-amber-700 {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .md\:text-amber-800 {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .md\:text-amber-900 {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .md\:text-amber {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .md\:text-amber-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .md\:text-amber-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .md\:text-amber-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .md\:text-amber-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .md\:text-brown-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .md\:text-brown-100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .md\:text-brown-200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .md\:text-brown-300 {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .md\:text-brown-400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .md\:text-brown-500 {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .md\:text-brown-600 {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .md\:text-brown-700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .md\:text-brown-800 {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .md\:text-brown-900 {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .md\:text-brown {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .md\:text-brown-A100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .md\:text-brown-A200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .md\:text-brown-A400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .md\:text-brown-A700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .md\:text-blue-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .md\:text-blue-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .md\:text-blue-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .md\:text-blue-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .md\:text-blue-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .md\:text-blue-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .md\:text-blue-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .md\:text-blue-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .md\:text-blue-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .md\:text-blue-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .md\:text-blue-gray {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .md\:text-blue-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .md\:text-blue-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .md\:text-blue-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .md\:text-blue-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .md\:text-cyan-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .md\:text-cyan-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .md\:text-cyan-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .md\:text-cyan-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .md\:text-cyan-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .md\:text-cyan-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .md\:text-cyan-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .md\:text-cyan-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .md\:text-cyan-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .md\:text-cyan-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .md\:text-cyan {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .md\:text-cyan-A100 {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .md\:text-cyan-A200 {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .md\:text-cyan-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .md\:text-cyan-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-transparent {
    color: transparent
  }

  .group:hover .md\:group-hover\:text-current {
    color: currentColor
  }

  .group:hover .md\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-grey-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-grey-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-grey-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-grey-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-grey-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-grey-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-grey-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-grey-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-grey-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-grey-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-grey {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-grey-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-grey-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-grey-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-grey-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-gray-hover {
    color: rgba(0, 0, 0, 0.04)
  }

  .group:hover .md\:group-hover\:text-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-red {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-red-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-red-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-red-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-red-A700 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-yellow-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-yellow-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-yellow-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-yellow-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-green {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-green-50 {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-green-100 {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-green-200 {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-green-300 {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-green-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-green-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-green-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-green-700 {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-green-800 {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-green-900 {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-green {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-teal-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-teal-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-teal-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-teal-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-teal-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-teal-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-teal-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-teal-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-teal-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-teal-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-teal {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-teal-A100 {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-teal-A200 {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-teal-A400 {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-teal-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-blue {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-light-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-indigo {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-indigo-A100 {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-indigo-A200 {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-indigo-A400 {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-indigo-A700 {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-purple {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-purple {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-deep-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-pink {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-pink-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-pink-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-pink-A400 {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-pink-A700 {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-lime-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-lime-100 {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-lime-200 {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-lime-300 {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-lime-400 {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-lime-500 {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-lime-600 {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-lime-700 {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-lime-800 {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-lime-900 {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-lime {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-lime-A100 {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-lime-A200 {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-lime-A400 {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-lime-A700 {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-amber-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-amber-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-amber-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-amber-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-amber-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-amber-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-amber-600 {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-amber-700 {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-amber-800 {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-amber-900 {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-amber {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-amber-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-amber-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-amber-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-amber-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-brown-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-brown-100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-brown-200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-brown-300 {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-brown-400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-brown-500 {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-brown-600 {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-brown-700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-brown-800 {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-brown-900 {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-brown {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-brown-A100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-brown-A200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-brown-A400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-brown-A700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-gray {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-blue-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-cyan-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-cyan-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-cyan-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-cyan-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-cyan-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-cyan-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-cyan-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-cyan-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-cyan-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-cyan-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-cyan {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-cyan-A100 {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-cyan-A200 {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-cyan-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .group:hover .md\:group-hover\:text-cyan-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-transparent:focus-within {
    color: transparent
  }

  .md\:focus-within\:text-current:focus-within {
    color: currentColor
  }

  .md\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-grey-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-grey-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-grey-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-grey-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-grey-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-grey-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-grey-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-grey-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-grey-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-grey-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-grey-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-grey-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-grey-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-grey-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-gray-hover:focus-within {
    color: rgba(0, 0, 0, 0.04)
  }

  .md\:focus-within\:text-gray-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-gray-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-gray-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-gray-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-red:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-red-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-red-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-red-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-red-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-orange-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-orange-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-orange-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-orange-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-orange-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-orange-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-orange-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-orange-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-orange-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-orange-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-orange:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-orange-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-orange-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-orange-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-orange-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-orange-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-orange-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-orange-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-orange-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-orange-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-orange-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-orange-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-orange-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-orange-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-orange-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-orange:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-orange-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-orange-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-orange-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-orange-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-yellow:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-yellow-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-yellow-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-yellow-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-yellow-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-green-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-green-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-green-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-green-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-green-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-green-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-green-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-green-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-teal-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-teal-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-teal-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-teal-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-teal-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-teal-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-teal-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-teal-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-teal-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-teal-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-teal:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-teal-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-teal-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-teal-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-teal-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-blue-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-blue-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-blue-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-light-blue-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-indigo:focus-within {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-indigo-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-indigo-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-indigo-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-indigo-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-purple:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-purple-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-purple-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-purple-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-purple-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-purple:focus-within {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-purple-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-purple-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-purple-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-deep-purple-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-pink:focus-within {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-pink-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-pink-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-pink-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-pink-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-lime-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-lime-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-lime-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-lime-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-lime-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-lime-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-lime-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-lime-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-lime-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-lime-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-lime:focus-within {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-lime-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-lime-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-lime-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-lime-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-amber-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-amber-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-amber-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-amber-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-amber-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-amber-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-amber-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-amber-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-amber-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-amber-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-amber:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-amber-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-amber-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-amber-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-amber-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-brown-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-brown-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-brown-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-brown-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-brown-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-brown-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-brown-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-brown-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-brown-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-brown-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-brown:focus-within {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-brown-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-brown-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-brown-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-brown-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-gray-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-gray-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-gray-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-blue-gray-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-cyan-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-cyan-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-cyan-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-cyan-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-cyan-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-cyan-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-cyan-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-cyan-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-cyan-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-cyan-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-cyan:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-cyan-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-cyan-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-cyan-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .md\:focus-within\:text-cyan-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .md\:hover\:text-transparent:hover {
    color: transparent
  }

  .md\:hover\:text-current:hover {
    color: currentColor
  }

  .md\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .md\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-grey-50:hover {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .md\:hover\:text-grey-100:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .md\:hover\:text-grey-200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .md\:hover\:text-grey-300:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .md\:hover\:text-grey-400:hover {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .md\:hover\:text-grey-500:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:hover\:text-grey-600:hover {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .md\:hover\:text-grey-700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:hover\:text-grey-800:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .md\:hover\:text-grey-900:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .md\:hover\:text-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:hover\:text-grey-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .md\:hover\:text-grey-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .md\:hover\:text-grey-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .md\:hover\:text-grey-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .md\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .md\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .md\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .md\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .md\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .md\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .md\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .md\:hover\:text-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:hover\:text-gray-hover:hover {
    color: rgba(0, 0, 0, 0.04)
  }

  .md\:hover\:text-gray-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .md\:hover\:text-gray-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .md\:hover\:text-gray-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .md\:hover\:text-gray-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .md\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .md\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .md\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .md\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .md\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .md\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .md\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .md\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .md\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .md\:hover\:text-red:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .md\:hover\:text-red-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .md\:hover\:text-red-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .md\:hover\:text-red-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .md\:hover\:text-red-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-orange-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .md\:hover\:text-orange-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .md\:hover\:text-orange-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .md\:hover\:text-orange-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .md\:hover\:text-orange-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .md\:hover\:text-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-orange-600:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-orange-700:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-orange-800:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-orange-900:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-orange:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-orange-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .md\:hover\:text-orange-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .md\:hover\:text-orange-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-orange-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-orange-50:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-orange-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-orange-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-orange-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-orange-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-orange-600:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-orange-700:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-orange-800:hover {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-orange-900:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-orange:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-orange-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-orange-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-orange-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-orange-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .md\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .md\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .md\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .md\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .md\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .md\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .md\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .md\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .md\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .md\:hover\:text-yellow:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .md\:hover\:text-yellow-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .md\:hover\:text-yellow-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-yellow-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-yellow-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .md\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .md\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .md\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .md\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .md\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .md\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .md\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .md\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .md\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .md\:hover\:text-green:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .md\:hover\:text-green-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .md\:hover\:text-green-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .md\:hover\:text-green-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .md\:hover\:text-green-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-green:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-green-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-green-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-green-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-green-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .md\:hover\:text-teal-50:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .md\:hover\:text-teal-100:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .md\:hover\:text-teal-200:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .md\:hover\:text-teal-300:hover {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .md\:hover\:text-teal-400:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .md\:hover\:text-teal-500:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .md\:hover\:text-teal-600:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .md\:hover\:text-teal-700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .md\:hover\:text-teal-800:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .md\:hover\:text-teal-900:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .md\:hover\:text-teal:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .md\:hover\:text-teal-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .md\:hover\:text-teal-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .md\:hover\:text-teal-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .md\:hover\:text-teal-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-blue-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-blue-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-blue-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-light-blue-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .md\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .md\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .md\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .md\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .md\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .md\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .md\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .md\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .md\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .md\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .md\:hover\:text-indigo:hover {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .md\:hover\:text-indigo-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-indigo-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .md\:hover\:text-indigo-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .md\:hover\:text-indigo-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .md\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .md\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .md\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .md\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .md\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .md\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .md\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .md\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .md\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .md\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .md\:hover\:text-purple:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .md\:hover\:text-purple-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .md\:hover\:text-purple-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .md\:hover\:text-purple-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .md\:hover\:text-purple-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-purple:hover {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-purple-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-purple-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-purple-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-deep-purple-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .md\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .md\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .md\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .md\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .md\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .md\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .md\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .md\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .md\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .md\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .md\:hover\:text-pink:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .md\:hover\:text-pink-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .md\:hover\:text-pink-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .md\:hover\:text-pink-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .md\:hover\:text-pink-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .md\:hover\:text-lime-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .md\:hover\:text-lime-100:hover {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .md\:hover\:text-lime-200:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .md\:hover\:text-lime-300:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .md\:hover\:text-lime-400:hover {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .md\:hover\:text-lime-500:hover {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .md\:hover\:text-lime-600:hover {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .md\:hover\:text-lime-700:hover {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .md\:hover\:text-lime-800:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .md\:hover\:text-lime-900:hover {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .md\:hover\:text-lime:hover {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .md\:hover\:text-lime-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .md\:hover\:text-lime-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .md\:hover\:text-lime-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-lime-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-amber-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .md\:hover\:text-amber-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .md\:hover\:text-amber-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .md\:hover\:text-amber-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .md\:hover\:text-amber-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .md\:hover\:text-amber-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .md\:hover\:text-amber-600:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-amber-700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-amber-800:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-amber-900:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-amber:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .md\:hover\:text-amber-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .md\:hover\:text-amber-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .md\:hover\:text-amber-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-amber-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .md\:hover\:text-brown-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .md\:hover\:text-brown-100:hover {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .md\:hover\:text-brown-200:hover {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .md\:hover\:text-brown-300:hover {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .md\:hover\:text-brown-400:hover {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .md\:hover\:text-brown-500:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .md\:hover\:text-brown-600:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .md\:hover\:text-brown-700:hover {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .md\:hover\:text-brown-800:hover {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .md\:hover\:text-brown-900:hover {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .md\:hover\:text-brown:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .md\:hover\:text-brown-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .md\:hover\:text-brown-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .md\:hover\:text-brown-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .md\:hover\:text-brown-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-gray-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-gray-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-gray-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .md\:hover\:text-blue-gray-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .md\:hover\:text-cyan-50:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .md\:hover\:text-cyan-100:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .md\:hover\:text-cyan-200:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .md\:hover\:text-cyan-300:hover {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .md\:hover\:text-cyan-400:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .md\:hover\:text-cyan-500:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .md\:hover\:text-cyan-600:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .md\:hover\:text-cyan-700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .md\:hover\:text-cyan-800:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .md\:hover\:text-cyan-900:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .md\:hover\:text-cyan:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .md\:hover\:text-cyan-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-cyan-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-cyan-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .md\:hover\:text-cyan-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .md\:focus\:text-transparent:focus {
    color: transparent
  }

  .md\:focus\:text-current:focus {
    color: currentColor
  }

  .md\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .md\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-grey-50:focus {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .md\:focus\:text-grey-100:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .md\:focus\:text-grey-200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .md\:focus\:text-grey-300:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .md\:focus\:text-grey-400:focus {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .md\:focus\:text-grey-500:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:focus\:text-grey-600:focus {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .md\:focus\:text-grey-700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:focus\:text-grey-800:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .md\:focus\:text-grey-900:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .md\:focus\:text-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:focus\:text-grey-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .md\:focus\:text-grey-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .md\:focus\:text-grey-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .md\:focus\:text-grey-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .md\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .md\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .md\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .md\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .md\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .md\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .md\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .md\:focus\:text-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .md\:focus\:text-gray-hover:focus {
    color: rgba(0, 0, 0, 0.04)
  }

  .md\:focus\:text-gray-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .md\:focus\:text-gray-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .md\:focus\:text-gray-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .md\:focus\:text-gray-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .md\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .md\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .md\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .md\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .md\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .md\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .md\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .md\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .md\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .md\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .md\:focus\:text-red:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .md\:focus\:text-red-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .md\:focus\:text-red-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .md\:focus\:text-red-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .md\:focus\:text-red-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-orange-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .md\:focus\:text-orange-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .md\:focus\:text-orange-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .md\:focus\:text-orange-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .md\:focus\:text-orange-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .md\:focus\:text-orange-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-orange-600:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-orange-700:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-orange-800:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-orange-900:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-orange:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-orange-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .md\:focus\:text-orange-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .md\:focus\:text-orange-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-orange-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-orange-50:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-orange-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-orange-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-orange-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-orange-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-orange-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-orange-600:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-orange-700:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-orange-800:focus {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-orange-900:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-orange:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-orange-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-orange-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-orange-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-orange-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .md\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .md\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .md\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .md\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .md\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .md\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .md\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .md\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .md\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .md\:focus\:text-yellow:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .md\:focus\:text-yellow-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .md\:focus\:text-yellow-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-yellow-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-yellow-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .md\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .md\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .md\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .md\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .md\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .md\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .md\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .md\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .md\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .md\:focus\:text-green:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .md\:focus\:text-green-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .md\:focus\:text-green-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .md\:focus\:text-green-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .md\:focus\:text-green-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-green:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-green-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-green-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-green-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-green-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .md\:focus\:text-teal-50:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .md\:focus\:text-teal-100:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .md\:focus\:text-teal-200:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .md\:focus\:text-teal-300:focus {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .md\:focus\:text-teal-400:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .md\:focus\:text-teal-500:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .md\:focus\:text-teal-600:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .md\:focus\:text-teal-700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .md\:focus\:text-teal-800:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .md\:focus\:text-teal-900:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .md\:focus\:text-teal:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .md\:focus\:text-teal-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .md\:focus\:text-teal-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .md\:focus\:text-teal-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .md\:focus\:text-teal-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-blue-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-blue-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-blue-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-light-blue-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .md\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .md\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .md\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .md\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .md\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .md\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .md\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .md\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .md\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .md\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .md\:focus\:text-indigo:focus {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .md\:focus\:text-indigo-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-indigo-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .md\:focus\:text-indigo-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .md\:focus\:text-indigo-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .md\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .md\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .md\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .md\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .md\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .md\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .md\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .md\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .md\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .md\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .md\:focus\:text-purple:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .md\:focus\:text-purple-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .md\:focus\:text-purple-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .md\:focus\:text-purple-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .md\:focus\:text-purple-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-purple:focus {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-purple-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-purple-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-purple-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-deep-purple-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .md\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .md\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .md\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .md\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .md\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .md\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .md\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .md\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .md\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .md\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .md\:focus\:text-pink:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .md\:focus\:text-pink-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .md\:focus\:text-pink-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .md\:focus\:text-pink-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .md\:focus\:text-pink-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .md\:focus\:text-lime-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .md\:focus\:text-lime-100:focus {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .md\:focus\:text-lime-200:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .md\:focus\:text-lime-300:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .md\:focus\:text-lime-400:focus {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .md\:focus\:text-lime-500:focus {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .md\:focus\:text-lime-600:focus {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .md\:focus\:text-lime-700:focus {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .md\:focus\:text-lime-800:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .md\:focus\:text-lime-900:focus {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .md\:focus\:text-lime:focus {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .md\:focus\:text-lime-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .md\:focus\:text-lime-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .md\:focus\:text-lime-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-lime-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-amber-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .md\:focus\:text-amber-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .md\:focus\:text-amber-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .md\:focus\:text-amber-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .md\:focus\:text-amber-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .md\:focus\:text-amber-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .md\:focus\:text-amber-600:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-amber-700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-amber-800:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-amber-900:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-amber:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .md\:focus\:text-amber-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .md\:focus\:text-amber-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .md\:focus\:text-amber-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-amber-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .md\:focus\:text-brown-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .md\:focus\:text-brown-100:focus {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .md\:focus\:text-brown-200:focus {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .md\:focus\:text-brown-300:focus {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .md\:focus\:text-brown-400:focus {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .md\:focus\:text-brown-500:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .md\:focus\:text-brown-600:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .md\:focus\:text-brown-700:focus {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .md\:focus\:text-brown-800:focus {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .md\:focus\:text-brown-900:focus {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .md\:focus\:text-brown:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .md\:focus\:text-brown-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .md\:focus\:text-brown-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .md\:focus\:text-brown-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .md\:focus\:text-brown-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-gray-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-gray-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-gray-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .md\:focus\:text-blue-gray-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .md\:focus\:text-cyan-50:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .md\:focus\:text-cyan-100:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .md\:focus\:text-cyan-200:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .md\:focus\:text-cyan-300:focus {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .md\:focus\:text-cyan-400:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .md\:focus\:text-cyan-500:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .md\:focus\:text-cyan-600:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .md\:focus\:text-cyan-700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .md\:focus\:text-cyan-800:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .md\:focus\:text-cyan-900:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .md\:focus\:text-cyan:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .md\:focus\:text-cyan-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-cyan-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-cyan-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .md\:focus\:text-cyan-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .md\:text-opacity-0 {
    --tw-text-opacity: 0
  }

  .md\:text-opacity-5 {
    --tw-text-opacity: 0.05
  }

  .md\:text-opacity-10 {
    --tw-text-opacity: 0.1
  }

  .md\:text-opacity-20 {
    --tw-text-opacity: 0.2
  }

  .md\:text-opacity-25 {
    --tw-text-opacity: 0.25
  }

  .md\:text-opacity-30 {
    --tw-text-opacity: 0.3
  }

  .md\:text-opacity-40 {
    --tw-text-opacity: 0.4
  }

  .md\:text-opacity-50 {
    --tw-text-opacity: 0.5
  }

  .md\:text-opacity-60 {
    --tw-text-opacity: 0.6
  }

  .md\:text-opacity-70 {
    --tw-text-opacity: 0.7
  }

  .md\:text-opacity-75 {
    --tw-text-opacity: 0.75
  }

  .md\:text-opacity-80 {
    --tw-text-opacity: 0.8
  }

  .md\:text-opacity-90 {
    --tw-text-opacity: 0.9
  }

  .md\:text-opacity-95 {
    --tw-text-opacity: 0.95
  }

  .md\:text-opacity-100 {
    --tw-text-opacity: 1
  }

  .group:hover .md\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0
  }

  .group:hover .md\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05
  }

  .group:hover .md\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1
  }

  .group:hover .md\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2
  }

  .group:hover .md\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25
  }

  .group:hover .md\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3
  }

  .group:hover .md\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4
  }

  .group:hover .md\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5
  }

  .group:hover .md\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6
  }

  .group:hover .md\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7
  }

  .group:hover .md\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75
  }

  .group:hover .md\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8
  }

  .group:hover .md\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9
  }

  .group:hover .md\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95
  }

  .group:hover .md\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1
  }

  .md\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0
  }

  .md\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05
  }

  .md\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1
  }

  .md\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2
  }

  .md\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25
  }

  .md\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3
  }

  .md\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4
  }

  .md\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5
  }

  .md\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6
  }

  .md\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7
  }

  .md\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75
  }

  .md\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8
  }

  .md\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9
  }

  .md\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95
  }

  .md\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1
  }

  .md\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0
  }

  .md\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05
  }

  .md\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1
  }

  .md\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2
  }

  .md\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25
  }

  .md\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3
  }

  .md\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4
  }

  .md\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5
  }

  .md\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6
  }

  .md\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7
  }

  .md\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75
  }

  .md\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8
  }

  .md\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9
  }

  .md\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95
  }

  .md\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1
  }

  .md\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0
  }

  .md\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05
  }

  .md\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1
  }

  .md\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2
  }

  .md\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25
  }

  .md\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3
  }

  .md\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4
  }

  .md\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5
  }

  .md\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6
  }

  .md\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7
  }

  .md\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75
  }

  .md\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8
  }

  .md\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9
  }

  .md\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95
  }

  .md\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1
  }

  .md\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .md\:overflow-ellipsis {
    text-overflow: ellipsis
  }

  .md\:overflow-clip {
    text-overflow: clip
  }

  .md\:italic {
    font-style: italic
  }

  .md\:not-italic {
    font-style: normal
  }

  .md\:hover\:italic:hover {
    font-style: italic
  }

  .md\:hover\:not-italic:hover {
    font-style: normal
  }

  .md\:focus\:italic:focus {
    font-style: italic
  }

  .md\:focus\:not-italic:focus {
    font-style: normal
  }

  .md\:uppercase {
    text-transform: uppercase
  }

  .md\:lowercase {
    text-transform: lowercase
  }

  .md\:capitalize {
    text-transform: capitalize
  }

  .md\:normal-case {
    text-transform: none
  }

  .md\:underline {
    text-decoration: underline
  }

  .md\:line-through {
    text-decoration: line-through
  }

  .md\:no-underline {
    text-decoration: none
  }

  .group:hover .md\:group-hover\:underline {
    text-decoration: underline
  }

  .group:hover .md\:group-hover\:line-through {
    text-decoration: line-through
  }

  .group:hover .md\:group-hover\:no-underline {
    text-decoration: none
  }

  .md\:focus-within\:underline:focus-within {
    text-decoration: underline
  }

  .md\:focus-within\:line-through:focus-within {
    text-decoration: line-through
  }

  .md\:focus-within\:no-underline:focus-within {
    text-decoration: none
  }

  .md\:hover\:underline:hover {
    text-decoration: underline
  }

  .md\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .md\:hover\:no-underline:hover {
    text-decoration: none
  }

  .md\:focus\:underline:focus {
    text-decoration: underline
  }

  .md\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .md\:focus\:no-underline:focus {
    text-decoration: none
  }

  .md\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .md\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .md\:ordinal, .md\:slashed-zero, .md\:lining-nums, .md\:oldstyle-nums, .md\:proportional-nums, .md\:tabular-nums, .md\:diagonal-fractions, .md\:stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  .md\:normal-nums {
    font-variant-numeric: normal
  }

  .md\:ordinal {
    --tw-ordinal: ordinal
  }

  .md\:slashed-zero {
    --tw-slashed-zero: slashed-zero
  }

  .md\:lining-nums {
    --tw-numeric-figure: lining-nums
  }

  .md\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums
  }

  .md\:proportional-nums {
    --tw-numeric-spacing: proportional-nums
  }

  .md\:tabular-nums {
    --tw-numeric-spacing: tabular-nums
  }

  .md\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions
  }

  .md\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions
  }

  .md\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .md\:tracking-tight {
    letter-spacing: -0.025em
  }

  .md\:tracking-normal {
    letter-spacing: 0em
  }

  .md\:tracking-wide {
    letter-spacing: 0.025em
  }

  .md\:tracking-wider {
    letter-spacing: 0.05em
  }

  .md\:tracking-widest {
    letter-spacing: 0.1em
  }

  .md\:select-none {
    -webkit-user-select: none;
            user-select: none
  }

  .md\:select-text {
    -webkit-user-select: text;
            user-select: text
  }

  .md\:select-all {
    -webkit-user-select: all;
            user-select: all
  }

  .md\:select-auto {
    -webkit-user-select: auto;
            user-select: auto
  }

  .md\:align-baseline {
    vertical-align: baseline
  }

  .md\:align-top {
    vertical-align: top
  }

  .md\:align-middle {
    vertical-align: middle
  }

  .md\:align-bottom {
    vertical-align: bottom
  }

  .md\:align-text-top {
    vertical-align: text-top
  }

  .md\:align-text-bottom {
    vertical-align: text-bottom
  }

  .md\:visible {
    visibility: visible
  }

  .md\:invisible {
    visibility: hidden
  }

  .md\:whitespace-normal {
    white-space: normal
  }

  .md\:whitespace-nowrap {
    white-space: nowrap
  }

  .md\:whitespace-pre {
    white-space: pre
  }

  .md\:whitespace-pre-line {
    white-space: pre-line
  }

  .md\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .md\:break-normal {
    overflow-wrap: normal;
    word-break: normal
  }

  .md\:break-words {
    overflow-wrap: break-word
  }

  .md\:break-all {
    word-break: break-all
  }

  .md\:w-0 {
    width: 0
  }

  .md\:w-1 {
    width: 0.1rem
  }

  .md\:w-2 {
    width: 0.2rem
  }

  .md\:w-3 {
    width: 0.3rem
  }

  .md\:w-4 {
    width: 0.4rem
  }

  .md\:w-5 {
    width: 0.5rem
  }

  .md\:w-6 {
    width: 0.6rem
  }

  .md\:w-7 {
    width: 0.7rem
  }

  .md\:w-8 {
    width: 0.8rem
  }

  .md\:w-9 {
    width: 0.9rem
  }

  .md\:w-10 {
    width: 1.0rem
  }

  .md\:w-12 {
    width: 1.2rem
  }

  .md\:w-14 {
    width: 1.4rem
  }

  .md\:w-16 {
    width: 1.6rem
  }

  .md\:w-20 {
    width: 2rem
  }

  .md\:w-24 {
    width: 2.4rem
  }

  .md\:w-28 {
    width: 2.8rem
  }

  .md\:w-32 {
    width: 3.2rem
  }

  .md\:w-36 {
    width: 3.6rem
  }

  .md\:w-40 {
    width: 4rem
  }

  .md\:w-44 {
    width: 4.4rem
  }

  .md\:w-48 {
    width: 4.8rem
  }

  .md\:w-52 {
    width: 5.2rem
  }

  .md\:w-56 {
    width: 5.6rem
  }

  .md\:w-60 {
    width: 6rem
  }

  .md\:w-64 {
    width: 6.4rem
  }

  .md\:w-68 {
    width: 6.8rem
  }

  .md\:w-72 {
    width: 7.2rem
  }

  .md\:w-76 {
    width: 7.6rem
  }

  .md\:w-80 {
    width: 8rem
  }

  .md\:w-84 {
    width: 8.4rem
  }

  .md\:w-88 {
    width: 8.8rem
  }

  .md\:w-92 {
    width: 9.2rem
  }

  .md\:w-96 {
    width: 9.6rem
  }

  .md\:w-128 {
    width: 12.8rem
  }

  .md\:w-136 {
    width: 13.6rem
  }

  .md\:w-160 {
    width: 16rem
  }

  .md\:w-192 {
    width: 19.2rem
  }

  .md\:w-200 {
    width: 20rem
  }

  .md\:w-208 {
    width: 20.8rem
  }

  .md\:w-216 {
    width: 21.6rem
  }

  .md\:w-224 {
    width: 22.4rem
  }

  .md\:w-256 {
    width: 25.6rem
  }

  .md\:w-288 {
    width: 28.8rem
  }

  .md\:w-320 {
    width: 32rem
  }

  .md\:w-360 {
    width: 36rem
  }

  .md\:w-384 {
    width: 38.4rem
  }

  .md\:w-400 {
    width: 40rem
  }

  .md\:w-512 {
    width: 51.2rem
  }

  .md\:w-640 {
    width: 64rem
  }

  .md\:w-auto {
    width: auto
  }

  .md\:w-xs {
    width: 32rem
  }

  .md\:w-sm {
    width: 48rem
  }

  .md\:w-md {
    width: 64rem
  }

  .md\:w-lg {
    width: 80rem
  }

  .md\:w-xl {
    width: 96rem
  }

  .md\:w-2xl {
    width: 112rem
  }

  .md\:w-3xl {
    width: 128rem
  }

  .md\:w-4xl {
    width: 144rem
  }

  .md\:w-5xl {
    width: 160rem
  }

  .md\:w-px {
    width: 1px
  }

  .md\:w-0\.5 {
    width: 0.05rem
  }

  .md\:w-1\.5 {
    width: 0.15rem
  }

  .md\:w-2\.5 {
    width: 0.25rem
  }

  .md\:w-3\.5 {
    width: 0.35rem
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-1\/3 {
    width: 33.333333%
  }

  .md\:w-2\/3 {
    width: 66.666667%
  }

  .md\:w-1\/4 {
    width: 25%
  }

  .md\:w-2\/4 {
    width: 50%
  }

  .md\:w-3\/4 {
    width: 75%
  }

  .md\:w-1\/5 {
    width: 20%
  }

  .md\:w-2\/5 {
    width: 40%
  }

  .md\:w-3\/5 {
    width: 60%
  }

  .md\:w-4\/5 {
    width: 80%
  }

  .md\:w-1\/6 {
    width: 16.666667%
  }

  .md\:w-2\/6 {
    width: 33.333333%
  }

  .md\:w-3\/6 {
    width: 50%
  }

  .md\:w-4\/6 {
    width: 66.666667%
  }

  .md\:w-5\/6 {
    width: 83.333333%
  }

  .md\:w-1\/12 {
    width: 8.333333%
  }

  .md\:w-2\/12 {
    width: 16.666667%
  }

  .md\:w-3\/12 {
    width: 25%
  }

  .md\:w-4\/12 {
    width: 33.333333%
  }

  .md\:w-5\/12 {
    width: 41.666667%
  }

  .md\:w-6\/12 {
    width: 50%
  }

  .md\:w-7\/12 {
    width: 58.333333%
  }

  .md\:w-8\/12 {
    width: 66.666667%
  }

  .md\:w-9\/12 {
    width: 75%
  }

  .md\:w-10\/12 {
    width: 83.333333%
  }

  .md\:w-11\/12 {
    width: 91.666667%
  }

  .md\:w-full {
    width: 100%
  }

  .md\:w-screen {
    width: 100vw
  }

  .md\:w-min {
    width: min-content
  }

  .md\:w-max {
    width: max-content
  }

  .md\:z-0 {
    z-index: 0
  }

  .md\:z-10 {
    z-index: 10
  }

  .md\:z-20 {
    z-index: 20
  }

  .md\:z-30 {
    z-index: 30
  }

  .md\:z-40 {
    z-index: 40
  }

  .md\:z-50 {
    z-index: 50
  }

  .md\:z-99 {
    z-index: 99
  }

  .md\:z-999 {
    z-index: 999
  }

  .md\:z-9999 {
    z-index: 9999
  }

  .md\:z-auto {
    z-index: auto
  }

  .md\:focus-within\:z-0:focus-within {
    z-index: 0
  }

  .md\:focus-within\:z-10:focus-within {
    z-index: 10
  }

  .md\:focus-within\:z-20:focus-within {
    z-index: 20
  }

  .md\:focus-within\:z-30:focus-within {
    z-index: 30
  }

  .md\:focus-within\:z-40:focus-within {
    z-index: 40
  }

  .md\:focus-within\:z-50:focus-within {
    z-index: 50
  }

  .md\:focus-within\:z-99:focus-within {
    z-index: 99
  }

  .md\:focus-within\:z-999:focus-within {
    z-index: 999
  }

  .md\:focus-within\:z-9999:focus-within {
    z-index: 9999
  }

  .md\:focus-within\:z-auto:focus-within {
    z-index: auto
  }

  .md\:focus\:z-0:focus {
    z-index: 0
  }

  .md\:focus\:z-10:focus {
    z-index: 10
  }

  .md\:focus\:z-20:focus {
    z-index: 20
  }

  .md\:focus\:z-30:focus {
    z-index: 30
  }

  .md\:focus\:z-40:focus {
    z-index: 40
  }

  .md\:focus\:z-50:focus {
    z-index: 50
  }

  .md\:focus\:z-99:focus {
    z-index: 99
  }

  .md\:focus\:z-999:focus {
    z-index: 999
  }

  .md\:focus\:z-9999:focus {
    z-index: 9999
  }

  .md\:focus\:z-auto:focus {
    z-index: auto
  }

  .md\:gap-0 {
    gap: 0
  }

  .md\:gap-1 {
    gap: 0.1rem
  }

  .md\:gap-2 {
    gap: 0.2rem
  }

  .md\:gap-3 {
    gap: 0.3rem
  }

  .md\:gap-4 {
    gap: 0.4rem
  }

  .md\:gap-5 {
    gap: 0.5rem
  }

  .md\:gap-6 {
    gap: 0.6rem
  }

  .md\:gap-7 {
    gap: 0.7rem
  }

  .md\:gap-8 {
    gap: 0.8rem
  }

  .md\:gap-9 {
    gap: 0.9rem
  }

  .md\:gap-10 {
    gap: 1.0rem
  }

  .md\:gap-12 {
    gap: 1.2rem
  }

  .md\:gap-14 {
    gap: 1.4rem
  }

  .md\:gap-16 {
    gap: 1.6rem
  }

  .md\:gap-20 {
    gap: 2rem
  }

  .md\:gap-24 {
    gap: 2.4rem
  }

  .md\:gap-28 {
    gap: 2.8rem
  }

  .md\:gap-32 {
    gap: 3.2rem
  }

  .md\:gap-36 {
    gap: 3.6rem
  }

  .md\:gap-40 {
    gap: 4rem
  }

  .md\:gap-44 {
    gap: 4.4rem
  }

  .md\:gap-48 {
    gap: 4.8rem
  }

  .md\:gap-52 {
    gap: 5.2rem
  }

  .md\:gap-56 {
    gap: 5.6rem
  }

  .md\:gap-60 {
    gap: 6rem
  }

  .md\:gap-64 {
    gap: 6.4rem
  }

  .md\:gap-68 {
    gap: 6.8rem
  }

  .md\:gap-72 {
    gap: 7.2rem
  }

  .md\:gap-76 {
    gap: 7.6rem
  }

  .md\:gap-80 {
    gap: 8rem
  }

  .md\:gap-84 {
    gap: 8.4rem
  }

  .md\:gap-88 {
    gap: 8.8rem
  }

  .md\:gap-92 {
    gap: 9.2rem
  }

  .md\:gap-96 {
    gap: 9.6rem
  }

  .md\:gap-128 {
    gap: 12.8rem
  }

  .md\:gap-136 {
    gap: 13.6rem
  }

  .md\:gap-160 {
    gap: 16rem
  }

  .md\:gap-192 {
    gap: 19.2rem
  }

  .md\:gap-200 {
    gap: 20rem
  }

  .md\:gap-208 {
    gap: 20.8rem
  }

  .md\:gap-216 {
    gap: 21.6rem
  }

  .md\:gap-224 {
    gap: 22.4rem
  }

  .md\:gap-256 {
    gap: 25.6rem
  }

  .md\:gap-288 {
    gap: 28.8rem
  }

  .md\:gap-320 {
    gap: 32rem
  }

  .md\:gap-360 {
    gap: 36rem
  }

  .md\:gap-384 {
    gap: 38.4rem
  }

  .md\:gap-400 {
    gap: 40rem
  }

  .md\:gap-512 {
    gap: 51.2rem
  }

  .md\:gap-640 {
    gap: 64rem
  }

  .md\:gap-xs {
    gap: 32rem
  }

  .md\:gap-sm {
    gap: 48rem
  }

  .md\:gap-md {
    gap: 64rem
  }

  .md\:gap-lg {
    gap: 80rem
  }

  .md\:gap-xl {
    gap: 96rem
  }

  .md\:gap-2xl {
    gap: 112rem
  }

  .md\:gap-3xl {
    gap: 128rem
  }

  .md\:gap-4xl {
    gap: 144rem
  }

  .md\:gap-5xl {
    gap: 160rem
  }

  .md\:gap-px {
    gap: 1px
  }

  .md\:gap-0\.5 {
    gap: 0.05rem
  }

  .md\:gap-1\.5 {
    gap: 0.15rem
  }

  .md\:gap-2\.5 {
    gap: 0.25rem
  }

  .md\:gap-3\.5 {
    gap: 0.35rem
  }

  .md\:gap-x-0 {
    column-gap: 0
  }

  .md\:gap-x-1 {
    column-gap: 0.1rem
  }

  .md\:gap-x-2 {
    column-gap: 0.2rem
  }

  .md\:gap-x-3 {
    column-gap: 0.3rem
  }

  .md\:gap-x-4 {
    column-gap: 0.4rem
  }

  .md\:gap-x-5 {
    column-gap: 0.5rem
  }

  .md\:gap-x-6 {
    column-gap: 0.6rem
  }

  .md\:gap-x-7 {
    column-gap: 0.7rem
  }

  .md\:gap-x-8 {
    column-gap: 0.8rem
  }

  .md\:gap-x-9 {
    column-gap: 0.9rem
  }

  .md\:gap-x-10 {
    column-gap: 1.0rem
  }

  .md\:gap-x-12 {
    column-gap: 1.2rem
  }

  .md\:gap-x-14 {
    column-gap: 1.4rem
  }

  .md\:gap-x-16 {
    column-gap: 1.6rem
  }

  .md\:gap-x-20 {
    column-gap: 2rem
  }

  .md\:gap-x-24 {
    column-gap: 2.4rem
  }

  .md\:gap-x-28 {
    column-gap: 2.8rem
  }

  .md\:gap-x-32 {
    column-gap: 3.2rem
  }

  .md\:gap-x-36 {
    column-gap: 3.6rem
  }

  .md\:gap-x-40 {
    column-gap: 4rem
  }

  .md\:gap-x-44 {
    column-gap: 4.4rem
  }

  .md\:gap-x-48 {
    column-gap: 4.8rem
  }

  .md\:gap-x-52 {
    column-gap: 5.2rem
  }

  .md\:gap-x-56 {
    column-gap: 5.6rem
  }

  .md\:gap-x-60 {
    column-gap: 6rem
  }

  .md\:gap-x-64 {
    column-gap: 6.4rem
  }

  .md\:gap-x-68 {
    column-gap: 6.8rem
  }

  .md\:gap-x-72 {
    column-gap: 7.2rem
  }

  .md\:gap-x-76 {
    column-gap: 7.6rem
  }

  .md\:gap-x-80 {
    column-gap: 8rem
  }

  .md\:gap-x-84 {
    column-gap: 8.4rem
  }

  .md\:gap-x-88 {
    column-gap: 8.8rem
  }

  .md\:gap-x-92 {
    column-gap: 9.2rem
  }

  .md\:gap-x-96 {
    column-gap: 9.6rem
  }

  .md\:gap-x-128 {
    column-gap: 12.8rem
  }

  .md\:gap-x-136 {
    column-gap: 13.6rem
  }

  .md\:gap-x-160 {
    column-gap: 16rem
  }

  .md\:gap-x-192 {
    column-gap: 19.2rem
  }

  .md\:gap-x-200 {
    column-gap: 20rem
  }

  .md\:gap-x-208 {
    column-gap: 20.8rem
  }

  .md\:gap-x-216 {
    column-gap: 21.6rem
  }

  .md\:gap-x-224 {
    column-gap: 22.4rem
  }

  .md\:gap-x-256 {
    column-gap: 25.6rem
  }

  .md\:gap-x-288 {
    column-gap: 28.8rem
  }

  .md\:gap-x-320 {
    column-gap: 32rem
  }

  .md\:gap-x-360 {
    column-gap: 36rem
  }

  .md\:gap-x-384 {
    column-gap: 38.4rem
  }

  .md\:gap-x-400 {
    column-gap: 40rem
  }

  .md\:gap-x-512 {
    column-gap: 51.2rem
  }

  .md\:gap-x-640 {
    column-gap: 64rem
  }

  .md\:gap-x-xs {
    column-gap: 32rem
  }

  .md\:gap-x-sm {
    column-gap: 48rem
  }

  .md\:gap-x-md {
    column-gap: 64rem
  }

  .md\:gap-x-lg {
    column-gap: 80rem
  }

  .md\:gap-x-xl {
    column-gap: 96rem
  }

  .md\:gap-x-2xl {
    column-gap: 112rem
  }

  .md\:gap-x-3xl {
    column-gap: 128rem
  }

  .md\:gap-x-4xl {
    column-gap: 144rem
  }

  .md\:gap-x-5xl {
    column-gap: 160rem
  }

  .md\:gap-x-px {
    column-gap: 1px
  }

  .md\:gap-x-0\.5 {
    column-gap: 0.05rem
  }

  .md\:gap-x-1\.5 {
    column-gap: 0.15rem
  }

  .md\:gap-x-2\.5 {
    column-gap: 0.25rem
  }

  .md\:gap-x-3\.5 {
    column-gap: 0.35rem
  }

  .md\:gap-y-0 {
    row-gap: 0
  }

  .md\:gap-y-1 {
    row-gap: 0.1rem
  }

  .md\:gap-y-2 {
    row-gap: 0.2rem
  }

  .md\:gap-y-3 {
    row-gap: 0.3rem
  }

  .md\:gap-y-4 {
    row-gap: 0.4rem
  }

  .md\:gap-y-5 {
    row-gap: 0.5rem
  }

  .md\:gap-y-6 {
    row-gap: 0.6rem
  }

  .md\:gap-y-7 {
    row-gap: 0.7rem
  }

  .md\:gap-y-8 {
    row-gap: 0.8rem
  }

  .md\:gap-y-9 {
    row-gap: 0.9rem
  }

  .md\:gap-y-10 {
    row-gap: 1.0rem
  }

  .md\:gap-y-12 {
    row-gap: 1.2rem
  }

  .md\:gap-y-14 {
    row-gap: 1.4rem
  }

  .md\:gap-y-16 {
    row-gap: 1.6rem
  }

  .md\:gap-y-20 {
    row-gap: 2rem
  }

  .md\:gap-y-24 {
    row-gap: 2.4rem
  }

  .md\:gap-y-28 {
    row-gap: 2.8rem
  }

  .md\:gap-y-32 {
    row-gap: 3.2rem
  }

  .md\:gap-y-36 {
    row-gap: 3.6rem
  }

  .md\:gap-y-40 {
    row-gap: 4rem
  }

  .md\:gap-y-44 {
    row-gap: 4.4rem
  }

  .md\:gap-y-48 {
    row-gap: 4.8rem
  }

  .md\:gap-y-52 {
    row-gap: 5.2rem
  }

  .md\:gap-y-56 {
    row-gap: 5.6rem
  }

  .md\:gap-y-60 {
    row-gap: 6rem
  }

  .md\:gap-y-64 {
    row-gap: 6.4rem
  }

  .md\:gap-y-68 {
    row-gap: 6.8rem
  }

  .md\:gap-y-72 {
    row-gap: 7.2rem
  }

  .md\:gap-y-76 {
    row-gap: 7.6rem
  }

  .md\:gap-y-80 {
    row-gap: 8rem
  }

  .md\:gap-y-84 {
    row-gap: 8.4rem
  }

  .md\:gap-y-88 {
    row-gap: 8.8rem
  }

  .md\:gap-y-92 {
    row-gap: 9.2rem
  }

  .md\:gap-y-96 {
    row-gap: 9.6rem
  }

  .md\:gap-y-128 {
    row-gap: 12.8rem
  }

  .md\:gap-y-136 {
    row-gap: 13.6rem
  }

  .md\:gap-y-160 {
    row-gap: 16rem
  }

  .md\:gap-y-192 {
    row-gap: 19.2rem
  }

  .md\:gap-y-200 {
    row-gap: 20rem
  }

  .md\:gap-y-208 {
    row-gap: 20.8rem
  }

  .md\:gap-y-216 {
    row-gap: 21.6rem
  }

  .md\:gap-y-224 {
    row-gap: 22.4rem
  }

  .md\:gap-y-256 {
    row-gap: 25.6rem
  }

  .md\:gap-y-288 {
    row-gap: 28.8rem
  }

  .md\:gap-y-320 {
    row-gap: 32rem
  }

  .md\:gap-y-360 {
    row-gap: 36rem
  }

  .md\:gap-y-384 {
    row-gap: 38.4rem
  }

  .md\:gap-y-400 {
    row-gap: 40rem
  }

  .md\:gap-y-512 {
    row-gap: 51.2rem
  }

  .md\:gap-y-640 {
    row-gap: 64rem
  }

  .md\:gap-y-xs {
    row-gap: 32rem
  }

  .md\:gap-y-sm {
    row-gap: 48rem
  }

  .md\:gap-y-md {
    row-gap: 64rem
  }

  .md\:gap-y-lg {
    row-gap: 80rem
  }

  .md\:gap-y-xl {
    row-gap: 96rem
  }

  .md\:gap-y-2xl {
    row-gap: 112rem
  }

  .md\:gap-y-3xl {
    row-gap: 128rem
  }

  .md\:gap-y-4xl {
    row-gap: 144rem
  }

  .md\:gap-y-5xl {
    row-gap: 160rem
  }

  .md\:gap-y-px {
    row-gap: 1px
  }

  .md\:gap-y-0\.5 {
    row-gap: 0.05rem
  }

  .md\:gap-y-1\.5 {
    row-gap: 0.15rem
  }

  .md\:gap-y-2\.5 {
    row-gap: 0.25rem
  }

  .md\:gap-y-3\.5 {
    row-gap: 0.35rem
  }

  .md\:grid-flow-row {
    grid-auto-flow: row
  }

  .md\:grid-flow-col {
    grid-auto-flow: column
  }

  .md\:grid-flow-row-dense {
    grid-auto-flow: row dense
  }

  .md\:grid-flow-col-dense {
    grid-auto-flow: column dense
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .md\:grid-cols-none {
    grid-template-columns: none
  }

  .md\:auto-cols-auto {
    grid-auto-columns: auto
  }

  .md\:auto-cols-min {
    grid-auto-columns: min-content
  }

  .md\:auto-cols-max {
    grid-auto-columns: max-content
  }

  .md\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr)
  }

  .md\:col-auto {
    grid-column: auto
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .md\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .md\:col-span-full {
    grid-column: 1 / -1
  }

  .md\:col-start-1 {
    grid-column-start: 1
  }

  .md\:col-start-2 {
    grid-column-start: 2
  }

  .md\:col-start-3 {
    grid-column-start: 3
  }

  .md\:col-start-4 {
    grid-column-start: 4
  }

  .md\:col-start-5 {
    grid-column-start: 5
  }

  .md\:col-start-6 {
    grid-column-start: 6
  }

  .md\:col-start-7 {
    grid-column-start: 7
  }

  .md\:col-start-8 {
    grid-column-start: 8
  }

  .md\:col-start-9 {
    grid-column-start: 9
  }

  .md\:col-start-10 {
    grid-column-start: 10
  }

  .md\:col-start-11 {
    grid-column-start: 11
  }

  .md\:col-start-12 {
    grid-column-start: 12
  }

  .md\:col-start-13 {
    grid-column-start: 13
  }

  .md\:col-start-auto {
    grid-column-start: auto
  }

  .md\:col-end-1 {
    grid-column-end: 1
  }

  .md\:col-end-2 {
    grid-column-end: 2
  }

  .md\:col-end-3 {
    grid-column-end: 3
  }

  .md\:col-end-4 {
    grid-column-end: 4
  }

  .md\:col-end-5 {
    grid-column-end: 5
  }

  .md\:col-end-6 {
    grid-column-end: 6
  }

  .md\:col-end-7 {
    grid-column-end: 7
  }

  .md\:col-end-8 {
    grid-column-end: 8
  }

  .md\:col-end-9 {
    grid-column-end: 9
  }

  .md\:col-end-10 {
    grid-column-end: 10
  }

  .md\:col-end-11 {
    grid-column-end: 11
  }

  .md\:col-end-12 {
    grid-column-end: 12
  }

  .md\:col-end-13 {
    grid-column-end: 13
  }

  .md\:col-end-auto {
    grid-column-end: auto
  }

  .md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .md\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .md\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .md\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .md\:grid-rows-none {
    grid-template-rows: none
  }

  .md\:auto-rows-auto {
    grid-auto-rows: auto
  }

  .md\:auto-rows-min {
    grid-auto-rows: min-content
  }

  .md\:auto-rows-max {
    grid-auto-rows: max-content
  }

  .md\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr)
  }

  .md\:row-auto {
    grid-row: auto
  }

  .md\:row-span-1 {
    grid-row: span 1 / span 1
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2
  }

  .md\:row-span-3 {
    grid-row: span 3 / span 3
  }

  .md\:row-span-4 {
    grid-row: span 4 / span 4
  }

  .md\:row-span-5 {
    grid-row: span 5 / span 5
  }

  .md\:row-span-6 {
    grid-row: span 6 / span 6
  }

  .md\:row-span-full {
    grid-row: 1 / -1
  }

  .md\:row-start-1 {
    grid-row-start: 1
  }

  .md\:row-start-2 {
    grid-row-start: 2
  }

  .md\:row-start-3 {
    grid-row-start: 3
  }

  .md\:row-start-4 {
    grid-row-start: 4
  }

  .md\:row-start-5 {
    grid-row-start: 5
  }

  .md\:row-start-6 {
    grid-row-start: 6
  }

  .md\:row-start-7 {
    grid-row-start: 7
  }

  .md\:row-start-auto {
    grid-row-start: auto
  }

  .md\:row-end-1 {
    grid-row-end: 1
  }

  .md\:row-end-2 {
    grid-row-end: 2
  }

  .md\:row-end-3 {
    grid-row-end: 3
  }

  .md\:row-end-4 {
    grid-row-end: 4
  }

  .md\:row-end-5 {
    grid-row-end: 5
  }

  .md\:row-end-6 {
    grid-row-end: 6
  }

  .md\:row-end-7 {
    grid-row-end: 7
  }

  .md\:row-end-auto {
    grid-row-end: auto
  }

  .md\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .md\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .md\:transform-none {
    transform: none
  }

  .md\:origin-center {
    transform-origin: center
  }

  .md\:origin-top {
    transform-origin: top
  }

  .md\:origin-top-right {
    transform-origin: top right
  }

  .md\:origin-right {
    transform-origin: right
  }

  .md\:origin-bottom-right {
    transform-origin: bottom right
  }

  .md\:origin-bottom {
    transform-origin: bottom
  }

  .md\:origin-bottom-left {
    transform-origin: bottom left
  }

  .md\:origin-left {
    transform-origin: left
  }

  .md\:origin-top-left {
    transform-origin: top left
  }

  .md\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .md\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .md\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .md\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .md\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .md\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .md\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .md\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .md\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .md\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .md\:scale-x-0 {
    --tw-scale-x: 0
  }

  .md\:scale-x-50 {
    --tw-scale-x: .5
  }

  .md\:scale-x-75 {
    --tw-scale-x: .75
  }

  .md\:scale-x-90 {
    --tw-scale-x: .9
  }

  .md\:scale-x-95 {
    --tw-scale-x: .95
  }

  .md\:scale-x-100 {
    --tw-scale-x: 1
  }

  .md\:scale-x-105 {
    --tw-scale-x: 1.05
  }

  .md\:scale-x-110 {
    --tw-scale-x: 1.1
  }

  .md\:scale-x-125 {
    --tw-scale-x: 1.25
  }

  .md\:scale-x-150 {
    --tw-scale-x: 1.5
  }

  .md\:scale-y-0 {
    --tw-scale-y: 0
  }

  .md\:scale-y-50 {
    --tw-scale-y: .5
  }

  .md\:scale-y-75 {
    --tw-scale-y: .75
  }

  .md\:scale-y-90 {
    --tw-scale-y: .9
  }

  .md\:scale-y-95 {
    --tw-scale-y: .95
  }

  .md\:scale-y-100 {
    --tw-scale-y: 1
  }

  .md\:scale-y-105 {
    --tw-scale-y: 1.05
  }

  .md\:scale-y-110 {
    --tw-scale-y: 1.1
  }

  .md\:scale-y-125 {
    --tw-scale-y: 1.25
  }

  .md\:scale-y-150 {
    --tw-scale-y: 1.5
  }

  .md\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .md\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .md\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .md\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .md\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .md\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .md\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .md\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .md\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .md\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .md\:hover\:scale-x-0:hover {
    --tw-scale-x: 0
  }

  .md\:hover\:scale-x-50:hover {
    --tw-scale-x: .5
  }

  .md\:hover\:scale-x-75:hover {
    --tw-scale-x: .75
  }

  .md\:hover\:scale-x-90:hover {
    --tw-scale-x: .9
  }

  .md\:hover\:scale-x-95:hover {
    --tw-scale-x: .95
  }

  .md\:hover\:scale-x-100:hover {
    --tw-scale-x: 1
  }

  .md\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05
  }

  .md\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1
  }

  .md\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25
  }

  .md\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5
  }

  .md\:hover\:scale-y-0:hover {
    --tw-scale-y: 0
  }

  .md\:hover\:scale-y-50:hover {
    --tw-scale-y: .5
  }

  .md\:hover\:scale-y-75:hover {
    --tw-scale-y: .75
  }

  .md\:hover\:scale-y-90:hover {
    --tw-scale-y: .9
  }

  .md\:hover\:scale-y-95:hover {
    --tw-scale-y: .95
  }

  .md\:hover\:scale-y-100:hover {
    --tw-scale-y: 1
  }

  .md\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05
  }

  .md\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1
  }

  .md\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25
  }

  .md\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5
  }

  .md\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .md\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .md\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .md\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .md\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .md\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .md\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .md\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .md\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .md\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .md\:focus\:scale-x-0:focus {
    --tw-scale-x: 0
  }

  .md\:focus\:scale-x-50:focus {
    --tw-scale-x: .5
  }

  .md\:focus\:scale-x-75:focus {
    --tw-scale-x: .75
  }

  .md\:focus\:scale-x-90:focus {
    --tw-scale-x: .9
  }

  .md\:focus\:scale-x-95:focus {
    --tw-scale-x: .95
  }

  .md\:focus\:scale-x-100:focus {
    --tw-scale-x: 1
  }

  .md\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05
  }

  .md\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1
  }

  .md\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25
  }

  .md\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5
  }

  .md\:focus\:scale-y-0:focus {
    --tw-scale-y: 0
  }

  .md\:focus\:scale-y-50:focus {
    --tw-scale-y: .5
  }

  .md\:focus\:scale-y-75:focus {
    --tw-scale-y: .75
  }

  .md\:focus\:scale-y-90:focus {
    --tw-scale-y: .9
  }

  .md\:focus\:scale-y-95:focus {
    --tw-scale-y: .95
  }

  .md\:focus\:scale-y-100:focus {
    --tw-scale-y: 1
  }

  .md\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05
  }

  .md\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1
  }

  .md\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25
  }

  .md\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5
  }

  .md\:rotate-0 {
    --tw-rotate: 0deg
  }

  .md\:rotate-1 {
    --tw-rotate: 1deg
  }

  .md\:rotate-2 {
    --tw-rotate: 2deg
  }

  .md\:rotate-3 {
    --tw-rotate: 3deg
  }

  .md\:rotate-6 {
    --tw-rotate: 6deg
  }

  .md\:rotate-12 {
    --tw-rotate: 12deg
  }

  .md\:rotate-45 {
    --tw-rotate: 45deg
  }

  .md\:rotate-90 {
    --tw-rotate: 90deg
  }

  .md\:rotate-180 {
    --tw-rotate: 180deg
  }

  .md\:-rotate-180 {
    --tw-rotate: -180deg
  }

  .md\:-rotate-90 {
    --tw-rotate: -90deg
  }

  .md\:-rotate-45 {
    --tw-rotate: -45deg
  }

  .md\:-rotate-12 {
    --tw-rotate: -12deg
  }

  .md\:-rotate-6 {
    --tw-rotate: -6deg
  }

  .md\:-rotate-3 {
    --tw-rotate: -3deg
  }

  .md\:-rotate-2 {
    --tw-rotate: -2deg
  }

  .md\:-rotate-1 {
    --tw-rotate: -1deg
  }

  .md\:hover\:rotate-0:hover {
    --tw-rotate: 0deg
  }

  .md\:hover\:rotate-1:hover {
    --tw-rotate: 1deg
  }

  .md\:hover\:rotate-2:hover {
    --tw-rotate: 2deg
  }

  .md\:hover\:rotate-3:hover {
    --tw-rotate: 3deg
  }

  .md\:hover\:rotate-6:hover {
    --tw-rotate: 6deg
  }

  .md\:hover\:rotate-12:hover {
    --tw-rotate: 12deg
  }

  .md\:hover\:rotate-45:hover {
    --tw-rotate: 45deg
  }

  .md\:hover\:rotate-90:hover {
    --tw-rotate: 90deg
  }

  .md\:hover\:rotate-180:hover {
    --tw-rotate: 180deg
  }

  .md\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg
  }

  .md\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg
  }

  .md\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg
  }

  .md\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg
  }

  .md\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg
  }

  .md\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg
  }

  .md\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg
  }

  .md\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg
  }

  .md\:focus\:rotate-0:focus {
    --tw-rotate: 0deg
  }

  .md\:focus\:rotate-1:focus {
    --tw-rotate: 1deg
  }

  .md\:focus\:rotate-2:focus {
    --tw-rotate: 2deg
  }

  .md\:focus\:rotate-3:focus {
    --tw-rotate: 3deg
  }

  .md\:focus\:rotate-6:focus {
    --tw-rotate: 6deg
  }

  .md\:focus\:rotate-12:focus {
    --tw-rotate: 12deg
  }

  .md\:focus\:rotate-45:focus {
    --tw-rotate: 45deg
  }

  .md\:focus\:rotate-90:focus {
    --tw-rotate: 90deg
  }

  .md\:focus\:rotate-180:focus {
    --tw-rotate: 180deg
  }

  .md\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg
  }

  .md\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg
  }

  .md\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg
  }

  .md\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg
  }

  .md\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg
  }

  .md\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg
  }

  .md\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg
  }

  .md\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg
  }

  .md\:translate-x-0 {
    --tw-translate-x: 0
  }

  .md\:translate-x-1 {
    --tw-translate-x: 0.1rem
  }

  .md\:translate-x-2 {
    --tw-translate-x: 0.2rem
  }

  .md\:translate-x-3 {
    --tw-translate-x: 0.3rem
  }

  .md\:translate-x-4 {
    --tw-translate-x: 0.4rem
  }

  .md\:translate-x-5 {
    --tw-translate-x: 0.5rem
  }

  .md\:translate-x-6 {
    --tw-translate-x: 0.6rem
  }

  .md\:translate-x-7 {
    --tw-translate-x: 0.7rem
  }

  .md\:translate-x-8 {
    --tw-translate-x: 0.8rem
  }

  .md\:translate-x-9 {
    --tw-translate-x: 0.9rem
  }

  .md\:translate-x-10 {
    --tw-translate-x: 1.0rem
  }

  .md\:translate-x-12 {
    --tw-translate-x: 1.2rem
  }

  .md\:translate-x-14 {
    --tw-translate-x: 1.4rem
  }

  .md\:translate-x-16 {
    --tw-translate-x: 1.6rem
  }

  .md\:translate-x-20 {
    --tw-translate-x: 2rem
  }

  .md\:translate-x-24 {
    --tw-translate-x: 2.4rem
  }

  .md\:translate-x-28 {
    --tw-translate-x: 2.8rem
  }

  .md\:translate-x-32 {
    --tw-translate-x: 3.2rem
  }

  .md\:translate-x-36 {
    --tw-translate-x: 3.6rem
  }

  .md\:translate-x-40 {
    --tw-translate-x: 4rem
  }

  .md\:translate-x-44 {
    --tw-translate-x: 4.4rem
  }

  .md\:translate-x-48 {
    --tw-translate-x: 4.8rem
  }

  .md\:translate-x-52 {
    --tw-translate-x: 5.2rem
  }

  .md\:translate-x-56 {
    --tw-translate-x: 5.6rem
  }

  .md\:translate-x-60 {
    --tw-translate-x: 6rem
  }

  .md\:translate-x-64 {
    --tw-translate-x: 6.4rem
  }

  .md\:translate-x-68 {
    --tw-translate-x: 6.8rem
  }

  .md\:translate-x-72 {
    --tw-translate-x: 7.2rem
  }

  .md\:translate-x-76 {
    --tw-translate-x: 7.6rem
  }

  .md\:translate-x-80 {
    --tw-translate-x: 8rem
  }

  .md\:translate-x-84 {
    --tw-translate-x: 8.4rem
  }

  .md\:translate-x-88 {
    --tw-translate-x: 8.8rem
  }

  .md\:translate-x-92 {
    --tw-translate-x: 9.2rem
  }

  .md\:translate-x-96 {
    --tw-translate-x: 9.6rem
  }

  .md\:translate-x-128 {
    --tw-translate-x: 12.8rem
  }

  .md\:translate-x-136 {
    --tw-translate-x: 13.6rem
  }

  .md\:translate-x-160 {
    --tw-translate-x: 16rem
  }

  .md\:translate-x-192 {
    --tw-translate-x: 19.2rem
  }

  .md\:translate-x-200 {
    --tw-translate-x: 20rem
  }

  .md\:translate-x-208 {
    --tw-translate-x: 20.8rem
  }

  .md\:translate-x-216 {
    --tw-translate-x: 21.6rem
  }

  .md\:translate-x-224 {
    --tw-translate-x: 22.4rem
  }

  .md\:translate-x-256 {
    --tw-translate-x: 25.6rem
  }

  .md\:translate-x-288 {
    --tw-translate-x: 28.8rem
  }

  .md\:translate-x-320 {
    --tw-translate-x: 32rem
  }

  .md\:translate-x-360 {
    --tw-translate-x: 36rem
  }

  .md\:translate-x-384 {
    --tw-translate-x: 38.4rem
  }

  .md\:translate-x-400 {
    --tw-translate-x: 40rem
  }

  .md\:translate-x-512 {
    --tw-translate-x: 51.2rem
  }

  .md\:translate-x-640 {
    --tw-translate-x: 64rem
  }

  .md\:translate-x-xs {
    --tw-translate-x: 32rem
  }

  .md\:translate-x-sm {
    --tw-translate-x: 48rem
  }

  .md\:translate-x-md {
    --tw-translate-x: 64rem
  }

  .md\:translate-x-lg {
    --tw-translate-x: 80rem
  }

  .md\:translate-x-xl {
    --tw-translate-x: 96rem
  }

  .md\:translate-x-2xl {
    --tw-translate-x: 112rem
  }

  .md\:translate-x-3xl {
    --tw-translate-x: 128rem
  }

  .md\:translate-x-4xl {
    --tw-translate-x: 144rem
  }

  .md\:translate-x-5xl {
    --tw-translate-x: 160rem
  }

  .md\:translate-x-px {
    --tw-translate-x: 1px
  }

  .md\:translate-x-0\.5 {
    --tw-translate-x: 0.05rem
  }

  .md\:translate-x-1\.5 {
    --tw-translate-x: 0.15rem
  }

  .md\:translate-x-2\.5 {
    --tw-translate-x: 0.25rem
  }

  .md\:translate-x-3\.5 {
    --tw-translate-x: 0.35rem
  }

  .md\:-translate-x-1 {
    --tw-translate-x: -0.1rem
  }

  .md\:-translate-x-2 {
    --tw-translate-x: -0.2rem
  }

  .md\:-translate-x-3 {
    --tw-translate-x: -0.3rem
  }

  .md\:-translate-x-4 {
    --tw-translate-x: -0.4rem
  }

  .md\:-translate-x-5 {
    --tw-translate-x: -0.5rem
  }

  .md\:-translate-x-6 {
    --tw-translate-x: -0.6rem
  }

  .md\:-translate-x-7 {
    --tw-translate-x: -0.7rem
  }

  .md\:-translate-x-8 {
    --tw-translate-x: -0.8rem
  }

  .md\:-translate-x-9 {
    --tw-translate-x: -0.9rem
  }

  .md\:-translate-x-10 {
    --tw-translate-x: -1rem
  }

  .md\:-translate-x-12 {
    --tw-translate-x: -1.2rem
  }

  .md\:-translate-x-14 {
    --tw-translate-x: -1.4rem
  }

  .md\:-translate-x-16 {
    --tw-translate-x: -1.6rem
  }

  .md\:-translate-x-20 {
    --tw-translate-x: -2rem
  }

  .md\:-translate-x-24 {
    --tw-translate-x: -2.4rem
  }

  .md\:-translate-x-28 {
    --tw-translate-x: -2.8rem
  }

  .md\:-translate-x-32 {
    --tw-translate-x: -3.2rem
  }

  .md\:-translate-x-36 {
    --tw-translate-x: -3.6rem
  }

  .md\:-translate-x-40 {
    --tw-translate-x: -4rem
  }

  .md\:-translate-x-44 {
    --tw-translate-x: -4.4rem
  }

  .md\:-translate-x-48 {
    --tw-translate-x: -4.8rem
  }

  .md\:-translate-x-52 {
    --tw-translate-x: -5.2rem
  }

  .md\:-translate-x-56 {
    --tw-translate-x: -5.6rem
  }

  .md\:-translate-x-60 {
    --tw-translate-x: -6rem
  }

  .md\:-translate-x-64 {
    --tw-translate-x: -6.4rem
  }

  .md\:-translate-x-68 {
    --tw-translate-x: -6.8rem
  }

  .md\:-translate-x-72 {
    --tw-translate-x: -7.2rem
  }

  .md\:-translate-x-76 {
    --tw-translate-x: -7.6rem
  }

  .md\:-translate-x-80 {
    --tw-translate-x: -8rem
  }

  .md\:-translate-x-84 {
    --tw-translate-x: -8.4rem
  }

  .md\:-translate-x-88 {
    --tw-translate-x: -8.8rem
  }

  .md\:-translate-x-92 {
    --tw-translate-x: -9.2rem
  }

  .md\:-translate-x-96 {
    --tw-translate-x: -9.6rem
  }

  .md\:-translate-x-128 {
    --tw-translate-x: -12.8rem
  }

  .md\:-translate-x-136 {
    --tw-translate-x: -13.6rem
  }

  .md\:-translate-x-160 {
    --tw-translate-x: -16rem
  }

  .md\:-translate-x-192 {
    --tw-translate-x: -19.2rem
  }

  .md\:-translate-x-200 {
    --tw-translate-x: -20rem
  }

  .md\:-translate-x-208 {
    --tw-translate-x: -20.8rem
  }

  .md\:-translate-x-216 {
    --tw-translate-x: -21.6rem
  }

  .md\:-translate-x-224 {
    --tw-translate-x: -22.4rem
  }

  .md\:-translate-x-256 {
    --tw-translate-x: -25.6rem
  }

  .md\:-translate-x-288 {
    --tw-translate-x: -28.8rem
  }

  .md\:-translate-x-320 {
    --tw-translate-x: -32rem
  }

  .md\:-translate-x-360 {
    --tw-translate-x: -36rem
  }

  .md\:-translate-x-384 {
    --tw-translate-x: -38.4rem
  }

  .md\:-translate-x-400 {
    --tw-translate-x: -40rem
  }

  .md\:-translate-x-512 {
    --tw-translate-x: -51.2rem
  }

  .md\:-translate-x-640 {
    --tw-translate-x: -64rem
  }

  .md\:-translate-x-xs {
    --tw-translate-x: -32rem
  }

  .md\:-translate-x-sm {
    --tw-translate-x: -48rem
  }

  .md\:-translate-x-md {
    --tw-translate-x: -64rem
  }

  .md\:-translate-x-lg {
    --tw-translate-x: -80rem
  }

  .md\:-translate-x-xl {
    --tw-translate-x: -96rem
  }

  .md\:-translate-x-2xl {
    --tw-translate-x: -112rem
  }

  .md\:-translate-x-3xl {
    --tw-translate-x: -128rem
  }

  .md\:-translate-x-4xl {
    --tw-translate-x: -144rem
  }

  .md\:-translate-x-5xl {
    --tw-translate-x: -160rem
  }

  .md\:-translate-x-px {
    --tw-translate-x: -1px
  }

  .md\:-translate-x-0\.5 {
    --tw-translate-x: -0.05rem
  }

  .md\:-translate-x-1\.5 {
    --tw-translate-x: -0.15rem
  }

  .md\:-translate-x-2\.5 {
    --tw-translate-x: -0.25rem
  }

  .md\:-translate-x-3\.5 {
    --tw-translate-x: -0.35rem
  }

  .md\:translate-x-1\/2 {
    --tw-translate-x: 50%
  }

  .md\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%
  }

  .md\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%
  }

  .md\:translate-x-1\/4 {
    --tw-translate-x: 25%
  }

  .md\:translate-x-2\/4 {
    --tw-translate-x: 50%
  }

  .md\:translate-x-3\/4 {
    --tw-translate-x: 75%
  }

  .md\:translate-x-full {
    --tw-translate-x: 100%
  }

  .md\:-translate-x-1\/2 {
    --tw-translate-x: -50%
  }

  .md\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%
  }

  .md\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%
  }

  .md\:-translate-x-1\/4 {
    --tw-translate-x: -25%
  }

  .md\:-translate-x-2\/4 {
    --tw-translate-x: -50%
  }

  .md\:-translate-x-3\/4 {
    --tw-translate-x: -75%
  }

  .md\:-translate-x-full {
    --tw-translate-x: -100%
  }

  .md\:translate-y-0 {
    --tw-translate-y: 0
  }

  .md\:translate-y-1 {
    --tw-translate-y: 0.1rem
  }

  .md\:translate-y-2 {
    --tw-translate-y: 0.2rem
  }

  .md\:translate-y-3 {
    --tw-translate-y: 0.3rem
  }

  .md\:translate-y-4 {
    --tw-translate-y: 0.4rem
  }

  .md\:translate-y-5 {
    --tw-translate-y: 0.5rem
  }

  .md\:translate-y-6 {
    --tw-translate-y: 0.6rem
  }

  .md\:translate-y-7 {
    --tw-translate-y: 0.7rem
  }

  .md\:translate-y-8 {
    --tw-translate-y: 0.8rem
  }

  .md\:translate-y-9 {
    --tw-translate-y: 0.9rem
  }

  .md\:translate-y-10 {
    --tw-translate-y: 1.0rem
  }

  .md\:translate-y-12 {
    --tw-translate-y: 1.2rem
  }

  .md\:translate-y-14 {
    --tw-translate-y: 1.4rem
  }

  .md\:translate-y-16 {
    --tw-translate-y: 1.6rem
  }

  .md\:translate-y-20 {
    --tw-translate-y: 2rem
  }

  .md\:translate-y-24 {
    --tw-translate-y: 2.4rem
  }

  .md\:translate-y-28 {
    --tw-translate-y: 2.8rem
  }

  .md\:translate-y-32 {
    --tw-translate-y: 3.2rem
  }

  .md\:translate-y-36 {
    --tw-translate-y: 3.6rem
  }

  .md\:translate-y-40 {
    --tw-translate-y: 4rem
  }

  .md\:translate-y-44 {
    --tw-translate-y: 4.4rem
  }

  .md\:translate-y-48 {
    --tw-translate-y: 4.8rem
  }

  .md\:translate-y-52 {
    --tw-translate-y: 5.2rem
  }

  .md\:translate-y-56 {
    --tw-translate-y: 5.6rem
  }

  .md\:translate-y-60 {
    --tw-translate-y: 6rem
  }

  .md\:translate-y-64 {
    --tw-translate-y: 6.4rem
  }

  .md\:translate-y-68 {
    --tw-translate-y: 6.8rem
  }

  .md\:translate-y-72 {
    --tw-translate-y: 7.2rem
  }

  .md\:translate-y-76 {
    --tw-translate-y: 7.6rem
  }

  .md\:translate-y-80 {
    --tw-translate-y: 8rem
  }

  .md\:translate-y-84 {
    --tw-translate-y: 8.4rem
  }

  .md\:translate-y-88 {
    --tw-translate-y: 8.8rem
  }

  .md\:translate-y-92 {
    --tw-translate-y: 9.2rem
  }

  .md\:translate-y-96 {
    --tw-translate-y: 9.6rem
  }

  .md\:translate-y-128 {
    --tw-translate-y: 12.8rem
  }

  .md\:translate-y-136 {
    --tw-translate-y: 13.6rem
  }

  .md\:translate-y-160 {
    --tw-translate-y: 16rem
  }

  .md\:translate-y-192 {
    --tw-translate-y: 19.2rem
  }

  .md\:translate-y-200 {
    --tw-translate-y: 20rem
  }

  .md\:translate-y-208 {
    --tw-translate-y: 20.8rem
  }

  .md\:translate-y-216 {
    --tw-translate-y: 21.6rem
  }

  .md\:translate-y-224 {
    --tw-translate-y: 22.4rem
  }

  .md\:translate-y-256 {
    --tw-translate-y: 25.6rem
  }

  .md\:translate-y-288 {
    --tw-translate-y: 28.8rem
  }

  .md\:translate-y-320 {
    --tw-translate-y: 32rem
  }

  .md\:translate-y-360 {
    --tw-translate-y: 36rem
  }

  .md\:translate-y-384 {
    --tw-translate-y: 38.4rem
  }

  .md\:translate-y-400 {
    --tw-translate-y: 40rem
  }

  .md\:translate-y-512 {
    --tw-translate-y: 51.2rem
  }

  .md\:translate-y-640 {
    --tw-translate-y: 64rem
  }

  .md\:translate-y-xs {
    --tw-translate-y: 32rem
  }

  .md\:translate-y-sm {
    --tw-translate-y: 48rem
  }

  .md\:translate-y-md {
    --tw-translate-y: 64rem
  }

  .md\:translate-y-lg {
    --tw-translate-y: 80rem
  }

  .md\:translate-y-xl {
    --tw-translate-y: 96rem
  }

  .md\:translate-y-2xl {
    --tw-translate-y: 112rem
  }

  .md\:translate-y-3xl {
    --tw-translate-y: 128rem
  }

  .md\:translate-y-4xl {
    --tw-translate-y: 144rem
  }

  .md\:translate-y-5xl {
    --tw-translate-y: 160rem
  }

  .md\:translate-y-px {
    --tw-translate-y: 1px
  }

  .md\:translate-y-0\.5 {
    --tw-translate-y: 0.05rem
  }

  .md\:translate-y-1\.5 {
    --tw-translate-y: 0.15rem
  }

  .md\:translate-y-2\.5 {
    --tw-translate-y: 0.25rem
  }

  .md\:translate-y-3\.5 {
    --tw-translate-y: 0.35rem
  }

  .md\:-translate-y-1 {
    --tw-translate-y: -0.1rem
  }

  .md\:-translate-y-2 {
    --tw-translate-y: -0.2rem
  }

  .md\:-translate-y-3 {
    --tw-translate-y: -0.3rem
  }

  .md\:-translate-y-4 {
    --tw-translate-y: -0.4rem
  }

  .md\:-translate-y-5 {
    --tw-translate-y: -0.5rem
  }

  .md\:-translate-y-6 {
    --tw-translate-y: -0.6rem
  }

  .md\:-translate-y-7 {
    --tw-translate-y: -0.7rem
  }

  .md\:-translate-y-8 {
    --tw-translate-y: -0.8rem
  }

  .md\:-translate-y-9 {
    --tw-translate-y: -0.9rem
  }

  .md\:-translate-y-10 {
    --tw-translate-y: -1rem
  }

  .md\:-translate-y-12 {
    --tw-translate-y: -1.2rem
  }

  .md\:-translate-y-14 {
    --tw-translate-y: -1.4rem
  }

  .md\:-translate-y-16 {
    --tw-translate-y: -1.6rem
  }

  .md\:-translate-y-20 {
    --tw-translate-y: -2rem
  }

  .md\:-translate-y-24 {
    --tw-translate-y: -2.4rem
  }

  .md\:-translate-y-28 {
    --tw-translate-y: -2.8rem
  }

  .md\:-translate-y-32 {
    --tw-translate-y: -3.2rem
  }

  .md\:-translate-y-36 {
    --tw-translate-y: -3.6rem
  }

  .md\:-translate-y-40 {
    --tw-translate-y: -4rem
  }

  .md\:-translate-y-44 {
    --tw-translate-y: -4.4rem
  }

  .md\:-translate-y-48 {
    --tw-translate-y: -4.8rem
  }

  .md\:-translate-y-52 {
    --tw-translate-y: -5.2rem
  }

  .md\:-translate-y-56 {
    --tw-translate-y: -5.6rem
  }

  .md\:-translate-y-60 {
    --tw-translate-y: -6rem
  }

  .md\:-translate-y-64 {
    --tw-translate-y: -6.4rem
  }

  .md\:-translate-y-68 {
    --tw-translate-y: -6.8rem
  }

  .md\:-translate-y-72 {
    --tw-translate-y: -7.2rem
  }

  .md\:-translate-y-76 {
    --tw-translate-y: -7.6rem
  }

  .md\:-translate-y-80 {
    --tw-translate-y: -8rem
  }

  .md\:-translate-y-84 {
    --tw-translate-y: -8.4rem
  }

  .md\:-translate-y-88 {
    --tw-translate-y: -8.8rem
  }

  .md\:-translate-y-92 {
    --tw-translate-y: -9.2rem
  }

  .md\:-translate-y-96 {
    --tw-translate-y: -9.6rem
  }

  .md\:-translate-y-128 {
    --tw-translate-y: -12.8rem
  }

  .md\:-translate-y-136 {
    --tw-translate-y: -13.6rem
  }

  .md\:-translate-y-160 {
    --tw-translate-y: -16rem
  }

  .md\:-translate-y-192 {
    --tw-translate-y: -19.2rem
  }

  .md\:-translate-y-200 {
    --tw-translate-y: -20rem
  }

  .md\:-translate-y-208 {
    --tw-translate-y: -20.8rem
  }

  .md\:-translate-y-216 {
    --tw-translate-y: -21.6rem
  }

  .md\:-translate-y-224 {
    --tw-translate-y: -22.4rem
  }

  .md\:-translate-y-256 {
    --tw-translate-y: -25.6rem
  }

  .md\:-translate-y-288 {
    --tw-translate-y: -28.8rem
  }

  .md\:-translate-y-320 {
    --tw-translate-y: -32rem
  }

  .md\:-translate-y-360 {
    --tw-translate-y: -36rem
  }

  .md\:-translate-y-384 {
    --tw-translate-y: -38.4rem
  }

  .md\:-translate-y-400 {
    --tw-translate-y: -40rem
  }

  .md\:-translate-y-512 {
    --tw-translate-y: -51.2rem
  }

  .md\:-translate-y-640 {
    --tw-translate-y: -64rem
  }

  .md\:-translate-y-xs {
    --tw-translate-y: -32rem
  }

  .md\:-translate-y-sm {
    --tw-translate-y: -48rem
  }

  .md\:-translate-y-md {
    --tw-translate-y: -64rem
  }

  .md\:-translate-y-lg {
    --tw-translate-y: -80rem
  }

  .md\:-translate-y-xl {
    --tw-translate-y: -96rem
  }

  .md\:-translate-y-2xl {
    --tw-translate-y: -112rem
  }

  .md\:-translate-y-3xl {
    --tw-translate-y: -128rem
  }

  .md\:-translate-y-4xl {
    --tw-translate-y: -144rem
  }

  .md\:-translate-y-5xl {
    --tw-translate-y: -160rem
  }

  .md\:-translate-y-px {
    --tw-translate-y: -1px
  }

  .md\:-translate-y-0\.5 {
    --tw-translate-y: -0.05rem
  }

  .md\:-translate-y-1\.5 {
    --tw-translate-y: -0.15rem
  }

  .md\:-translate-y-2\.5 {
    --tw-translate-y: -0.25rem
  }

  .md\:-translate-y-3\.5 {
    --tw-translate-y: -0.35rem
  }

  .md\:translate-y-1\/2 {
    --tw-translate-y: 50%
  }

  .md\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%
  }

  .md\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%
  }

  .md\:translate-y-1\/4 {
    --tw-translate-y: 25%
  }

  .md\:translate-y-2\/4 {
    --tw-translate-y: 50%
  }

  .md\:translate-y-3\/4 {
    --tw-translate-y: 75%
  }

  .md\:translate-y-full {
    --tw-translate-y: 100%
  }

  .md\:-translate-y-1\/2 {
    --tw-translate-y: -50%
  }

  .md\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%
  }

  .md\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%
  }

  .md\:-translate-y-1\/4 {
    --tw-translate-y: -25%
  }

  .md\:-translate-y-2\/4 {
    --tw-translate-y: -50%
  }

  .md\:-translate-y-3\/4 {
    --tw-translate-y: -75%
  }

  .md\:-translate-y-full {
    --tw-translate-y: -100%
  }

  .md\:hover\:translate-x-0:hover {
    --tw-translate-x: 0
  }

  .md\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.1rem
  }

  .md\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.2rem
  }

  .md\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.3rem
  }

  .md\:hover\:translate-x-4:hover {
    --tw-translate-x: 0.4rem
  }

  .md\:hover\:translate-x-5:hover {
    --tw-translate-x: 0.5rem
  }

  .md\:hover\:translate-x-6:hover {
    --tw-translate-x: 0.6rem
  }

  .md\:hover\:translate-x-7:hover {
    --tw-translate-x: 0.7rem
  }

  .md\:hover\:translate-x-8:hover {
    --tw-translate-x: 0.8rem
  }

  .md\:hover\:translate-x-9:hover {
    --tw-translate-x: 0.9rem
  }

  .md\:hover\:translate-x-10:hover {
    --tw-translate-x: 1.0rem
  }

  .md\:hover\:translate-x-12:hover {
    --tw-translate-x: 1.2rem
  }

  .md\:hover\:translate-x-14:hover {
    --tw-translate-x: 1.4rem
  }

  .md\:hover\:translate-x-16:hover {
    --tw-translate-x: 1.6rem
  }

  .md\:hover\:translate-x-20:hover {
    --tw-translate-x: 2rem
  }

  .md\:hover\:translate-x-24:hover {
    --tw-translate-x: 2.4rem
  }

  .md\:hover\:translate-x-28:hover {
    --tw-translate-x: 2.8rem
  }

  .md\:hover\:translate-x-32:hover {
    --tw-translate-x: 3.2rem
  }

  .md\:hover\:translate-x-36:hover {
    --tw-translate-x: 3.6rem
  }

  .md\:hover\:translate-x-40:hover {
    --tw-translate-x: 4rem
  }

  .md\:hover\:translate-x-44:hover {
    --tw-translate-x: 4.4rem
  }

  .md\:hover\:translate-x-48:hover {
    --tw-translate-x: 4.8rem
  }

  .md\:hover\:translate-x-52:hover {
    --tw-translate-x: 5.2rem
  }

  .md\:hover\:translate-x-56:hover {
    --tw-translate-x: 5.6rem
  }

  .md\:hover\:translate-x-60:hover {
    --tw-translate-x: 6rem
  }

  .md\:hover\:translate-x-64:hover {
    --tw-translate-x: 6.4rem
  }

  .md\:hover\:translate-x-68:hover {
    --tw-translate-x: 6.8rem
  }

  .md\:hover\:translate-x-72:hover {
    --tw-translate-x: 7.2rem
  }

  .md\:hover\:translate-x-76:hover {
    --tw-translate-x: 7.6rem
  }

  .md\:hover\:translate-x-80:hover {
    --tw-translate-x: 8rem
  }

  .md\:hover\:translate-x-84:hover {
    --tw-translate-x: 8.4rem
  }

  .md\:hover\:translate-x-88:hover {
    --tw-translate-x: 8.8rem
  }

  .md\:hover\:translate-x-92:hover {
    --tw-translate-x: 9.2rem
  }

  .md\:hover\:translate-x-96:hover {
    --tw-translate-x: 9.6rem
  }

  .md\:hover\:translate-x-128:hover {
    --tw-translate-x: 12.8rem
  }

  .md\:hover\:translate-x-136:hover {
    --tw-translate-x: 13.6rem
  }

  .md\:hover\:translate-x-160:hover {
    --tw-translate-x: 16rem
  }

  .md\:hover\:translate-x-192:hover {
    --tw-translate-x: 19.2rem
  }

  .md\:hover\:translate-x-200:hover {
    --tw-translate-x: 20rem
  }

  .md\:hover\:translate-x-208:hover {
    --tw-translate-x: 20.8rem
  }

  .md\:hover\:translate-x-216:hover {
    --tw-translate-x: 21.6rem
  }

  .md\:hover\:translate-x-224:hover {
    --tw-translate-x: 22.4rem
  }

  .md\:hover\:translate-x-256:hover {
    --tw-translate-x: 25.6rem
  }

  .md\:hover\:translate-x-288:hover {
    --tw-translate-x: 28.8rem
  }

  .md\:hover\:translate-x-320:hover {
    --tw-translate-x: 32rem
  }

  .md\:hover\:translate-x-360:hover {
    --tw-translate-x: 36rem
  }

  .md\:hover\:translate-x-384:hover {
    --tw-translate-x: 38.4rem
  }

  .md\:hover\:translate-x-400:hover {
    --tw-translate-x: 40rem
  }

  .md\:hover\:translate-x-512:hover {
    --tw-translate-x: 51.2rem
  }

  .md\:hover\:translate-x-640:hover {
    --tw-translate-x: 64rem
  }

  .md\:hover\:translate-x-xs:hover {
    --tw-translate-x: 32rem
  }

  .md\:hover\:translate-x-sm:hover {
    --tw-translate-x: 48rem
  }

  .md\:hover\:translate-x-md:hover {
    --tw-translate-x: 64rem
  }

  .md\:hover\:translate-x-lg:hover {
    --tw-translate-x: 80rem
  }

  .md\:hover\:translate-x-xl:hover {
    --tw-translate-x: 96rem
  }

  .md\:hover\:translate-x-2xl:hover {
    --tw-translate-x: 112rem
  }

  .md\:hover\:translate-x-3xl:hover {
    --tw-translate-x: 128rem
  }

  .md\:hover\:translate-x-4xl:hover {
    --tw-translate-x: 144rem
  }

  .md\:hover\:translate-x-5xl:hover {
    --tw-translate-x: 160rem
  }

  .md\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px
  }

  .md\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.05rem
  }

  .md\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.15rem
  }

  .md\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.25rem
  }

  .md\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.35rem
  }

  .md\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.1rem
  }

  .md\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.2rem
  }

  .md\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.3rem
  }

  .md\:hover\:-translate-x-4:hover {
    --tw-translate-x: -0.4rem
  }

  .md\:hover\:-translate-x-5:hover {
    --tw-translate-x: -0.5rem
  }

  .md\:hover\:-translate-x-6:hover {
    --tw-translate-x: -0.6rem
  }

  .md\:hover\:-translate-x-7:hover {
    --tw-translate-x: -0.7rem
  }

  .md\:hover\:-translate-x-8:hover {
    --tw-translate-x: -0.8rem
  }

  .md\:hover\:-translate-x-9:hover {
    --tw-translate-x: -0.9rem
  }

  .md\:hover\:-translate-x-10:hover {
    --tw-translate-x: -1rem
  }

  .md\:hover\:-translate-x-12:hover {
    --tw-translate-x: -1.2rem
  }

  .md\:hover\:-translate-x-14:hover {
    --tw-translate-x: -1.4rem
  }

  .md\:hover\:-translate-x-16:hover {
    --tw-translate-x: -1.6rem
  }

  .md\:hover\:-translate-x-20:hover {
    --tw-translate-x: -2rem
  }

  .md\:hover\:-translate-x-24:hover {
    --tw-translate-x: -2.4rem
  }

  .md\:hover\:-translate-x-28:hover {
    --tw-translate-x: -2.8rem
  }

  .md\:hover\:-translate-x-32:hover {
    --tw-translate-x: -3.2rem
  }

  .md\:hover\:-translate-x-36:hover {
    --tw-translate-x: -3.6rem
  }

  .md\:hover\:-translate-x-40:hover {
    --tw-translate-x: -4rem
  }

  .md\:hover\:-translate-x-44:hover {
    --tw-translate-x: -4.4rem
  }

  .md\:hover\:-translate-x-48:hover {
    --tw-translate-x: -4.8rem
  }

  .md\:hover\:-translate-x-52:hover {
    --tw-translate-x: -5.2rem
  }

  .md\:hover\:-translate-x-56:hover {
    --tw-translate-x: -5.6rem
  }

  .md\:hover\:-translate-x-60:hover {
    --tw-translate-x: -6rem
  }

  .md\:hover\:-translate-x-64:hover {
    --tw-translate-x: -6.4rem
  }

  .md\:hover\:-translate-x-68:hover {
    --tw-translate-x: -6.8rem
  }

  .md\:hover\:-translate-x-72:hover {
    --tw-translate-x: -7.2rem
  }

  .md\:hover\:-translate-x-76:hover {
    --tw-translate-x: -7.6rem
  }

  .md\:hover\:-translate-x-80:hover {
    --tw-translate-x: -8rem
  }

  .md\:hover\:-translate-x-84:hover {
    --tw-translate-x: -8.4rem
  }

  .md\:hover\:-translate-x-88:hover {
    --tw-translate-x: -8.8rem
  }

  .md\:hover\:-translate-x-92:hover {
    --tw-translate-x: -9.2rem
  }

  .md\:hover\:-translate-x-96:hover {
    --tw-translate-x: -9.6rem
  }

  .md\:hover\:-translate-x-128:hover {
    --tw-translate-x: -12.8rem
  }

  .md\:hover\:-translate-x-136:hover {
    --tw-translate-x: -13.6rem
  }

  .md\:hover\:-translate-x-160:hover {
    --tw-translate-x: -16rem
  }

  .md\:hover\:-translate-x-192:hover {
    --tw-translate-x: -19.2rem
  }

  .md\:hover\:-translate-x-200:hover {
    --tw-translate-x: -20rem
  }

  .md\:hover\:-translate-x-208:hover {
    --tw-translate-x: -20.8rem
  }

  .md\:hover\:-translate-x-216:hover {
    --tw-translate-x: -21.6rem
  }

  .md\:hover\:-translate-x-224:hover {
    --tw-translate-x: -22.4rem
  }

  .md\:hover\:-translate-x-256:hover {
    --tw-translate-x: -25.6rem
  }

  .md\:hover\:-translate-x-288:hover {
    --tw-translate-x: -28.8rem
  }

  .md\:hover\:-translate-x-320:hover {
    --tw-translate-x: -32rem
  }

  .md\:hover\:-translate-x-360:hover {
    --tw-translate-x: -36rem
  }

  .md\:hover\:-translate-x-384:hover {
    --tw-translate-x: -38.4rem
  }

  .md\:hover\:-translate-x-400:hover {
    --tw-translate-x: -40rem
  }

  .md\:hover\:-translate-x-512:hover {
    --tw-translate-x: -51.2rem
  }

  .md\:hover\:-translate-x-640:hover {
    --tw-translate-x: -64rem
  }

  .md\:hover\:-translate-x-xs:hover {
    --tw-translate-x: -32rem
  }

  .md\:hover\:-translate-x-sm:hover {
    --tw-translate-x: -48rem
  }

  .md\:hover\:-translate-x-md:hover {
    --tw-translate-x: -64rem
  }

  .md\:hover\:-translate-x-lg:hover {
    --tw-translate-x: -80rem
  }

  .md\:hover\:-translate-x-xl:hover {
    --tw-translate-x: -96rem
  }

  .md\:hover\:-translate-x-2xl:hover {
    --tw-translate-x: -112rem
  }

  .md\:hover\:-translate-x-3xl:hover {
    --tw-translate-x: -128rem
  }

  .md\:hover\:-translate-x-4xl:hover {
    --tw-translate-x: -144rem
  }

  .md\:hover\:-translate-x-5xl:hover {
    --tw-translate-x: -160rem
  }

  .md\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px
  }

  .md\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.05rem
  }

  .md\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.15rem
  }

  .md\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.25rem
  }

  .md\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.35rem
  }

  .md\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%
  }

  .md\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%
  }

  .md\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%
  }

  .md\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%
  }

  .md\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%
  }

  .md\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%
  }

  .md\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%
  }

  .md\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%
  }

  .md\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%
  }

  .md\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%
  }

  .md\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%
  }

  .md\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%
  }

  .md\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%
  }

  .md\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%
  }

  .md\:hover\:translate-y-0:hover {
    --tw-translate-y: 0
  }

  .md\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.1rem
  }

  .md\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.2rem
  }

  .md\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.3rem
  }

  .md\:hover\:translate-y-4:hover {
    --tw-translate-y: 0.4rem
  }

  .md\:hover\:translate-y-5:hover {
    --tw-translate-y: 0.5rem
  }

  .md\:hover\:translate-y-6:hover {
    --tw-translate-y: 0.6rem
  }

  .md\:hover\:translate-y-7:hover {
    --tw-translate-y: 0.7rem
  }

  .md\:hover\:translate-y-8:hover {
    --tw-translate-y: 0.8rem
  }

  .md\:hover\:translate-y-9:hover {
    --tw-translate-y: 0.9rem
  }

  .md\:hover\:translate-y-10:hover {
    --tw-translate-y: 1.0rem
  }

  .md\:hover\:translate-y-12:hover {
    --tw-translate-y: 1.2rem
  }

  .md\:hover\:translate-y-14:hover {
    --tw-translate-y: 1.4rem
  }

  .md\:hover\:translate-y-16:hover {
    --tw-translate-y: 1.6rem
  }

  .md\:hover\:translate-y-20:hover {
    --tw-translate-y: 2rem
  }

  .md\:hover\:translate-y-24:hover {
    --tw-translate-y: 2.4rem
  }

  .md\:hover\:translate-y-28:hover {
    --tw-translate-y: 2.8rem
  }

  .md\:hover\:translate-y-32:hover {
    --tw-translate-y: 3.2rem
  }

  .md\:hover\:translate-y-36:hover {
    --tw-translate-y: 3.6rem
  }

  .md\:hover\:translate-y-40:hover {
    --tw-translate-y: 4rem
  }

  .md\:hover\:translate-y-44:hover {
    --tw-translate-y: 4.4rem
  }

  .md\:hover\:translate-y-48:hover {
    --tw-translate-y: 4.8rem
  }

  .md\:hover\:translate-y-52:hover {
    --tw-translate-y: 5.2rem
  }

  .md\:hover\:translate-y-56:hover {
    --tw-translate-y: 5.6rem
  }

  .md\:hover\:translate-y-60:hover {
    --tw-translate-y: 6rem
  }

  .md\:hover\:translate-y-64:hover {
    --tw-translate-y: 6.4rem
  }

  .md\:hover\:translate-y-68:hover {
    --tw-translate-y: 6.8rem
  }

  .md\:hover\:translate-y-72:hover {
    --tw-translate-y: 7.2rem
  }

  .md\:hover\:translate-y-76:hover {
    --tw-translate-y: 7.6rem
  }

  .md\:hover\:translate-y-80:hover {
    --tw-translate-y: 8rem
  }

  .md\:hover\:translate-y-84:hover {
    --tw-translate-y: 8.4rem
  }

  .md\:hover\:translate-y-88:hover {
    --tw-translate-y: 8.8rem
  }

  .md\:hover\:translate-y-92:hover {
    --tw-translate-y: 9.2rem
  }

  .md\:hover\:translate-y-96:hover {
    --tw-translate-y: 9.6rem
  }

  .md\:hover\:translate-y-128:hover {
    --tw-translate-y: 12.8rem
  }

  .md\:hover\:translate-y-136:hover {
    --tw-translate-y: 13.6rem
  }

  .md\:hover\:translate-y-160:hover {
    --tw-translate-y: 16rem
  }

  .md\:hover\:translate-y-192:hover {
    --tw-translate-y: 19.2rem
  }

  .md\:hover\:translate-y-200:hover {
    --tw-translate-y: 20rem
  }

  .md\:hover\:translate-y-208:hover {
    --tw-translate-y: 20.8rem
  }

  .md\:hover\:translate-y-216:hover {
    --tw-translate-y: 21.6rem
  }

  .md\:hover\:translate-y-224:hover {
    --tw-translate-y: 22.4rem
  }

  .md\:hover\:translate-y-256:hover {
    --tw-translate-y: 25.6rem
  }

  .md\:hover\:translate-y-288:hover {
    --tw-translate-y: 28.8rem
  }

  .md\:hover\:translate-y-320:hover {
    --tw-translate-y: 32rem
  }

  .md\:hover\:translate-y-360:hover {
    --tw-translate-y: 36rem
  }

  .md\:hover\:translate-y-384:hover {
    --tw-translate-y: 38.4rem
  }

  .md\:hover\:translate-y-400:hover {
    --tw-translate-y: 40rem
  }

  .md\:hover\:translate-y-512:hover {
    --tw-translate-y: 51.2rem
  }

  .md\:hover\:translate-y-640:hover {
    --tw-translate-y: 64rem
  }

  .md\:hover\:translate-y-xs:hover {
    --tw-translate-y: 32rem
  }

  .md\:hover\:translate-y-sm:hover {
    --tw-translate-y: 48rem
  }

  .md\:hover\:translate-y-md:hover {
    --tw-translate-y: 64rem
  }

  .md\:hover\:translate-y-lg:hover {
    --tw-translate-y: 80rem
  }

  .md\:hover\:translate-y-xl:hover {
    --tw-translate-y: 96rem
  }

  .md\:hover\:translate-y-2xl:hover {
    --tw-translate-y: 112rem
  }

  .md\:hover\:translate-y-3xl:hover {
    --tw-translate-y: 128rem
  }

  .md\:hover\:translate-y-4xl:hover {
    --tw-translate-y: 144rem
  }

  .md\:hover\:translate-y-5xl:hover {
    --tw-translate-y: 160rem
  }

  .md\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px
  }

  .md\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.05rem
  }

  .md\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.15rem
  }

  .md\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.25rem
  }

  .md\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.35rem
  }

  .md\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.1rem
  }

  .md\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.2rem
  }

  .md\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.3rem
  }

  .md\:hover\:-translate-y-4:hover {
    --tw-translate-y: -0.4rem
  }

  .md\:hover\:-translate-y-5:hover {
    --tw-translate-y: -0.5rem
  }

  .md\:hover\:-translate-y-6:hover {
    --tw-translate-y: -0.6rem
  }

  .md\:hover\:-translate-y-7:hover {
    --tw-translate-y: -0.7rem
  }

  .md\:hover\:-translate-y-8:hover {
    --tw-translate-y: -0.8rem
  }

  .md\:hover\:-translate-y-9:hover {
    --tw-translate-y: -0.9rem
  }

  .md\:hover\:-translate-y-10:hover {
    --tw-translate-y: -1rem
  }

  .md\:hover\:-translate-y-12:hover {
    --tw-translate-y: -1.2rem
  }

  .md\:hover\:-translate-y-14:hover {
    --tw-translate-y: -1.4rem
  }

  .md\:hover\:-translate-y-16:hover {
    --tw-translate-y: -1.6rem
  }

  .md\:hover\:-translate-y-20:hover {
    --tw-translate-y: -2rem
  }

  .md\:hover\:-translate-y-24:hover {
    --tw-translate-y: -2.4rem
  }

  .md\:hover\:-translate-y-28:hover {
    --tw-translate-y: -2.8rem
  }

  .md\:hover\:-translate-y-32:hover {
    --tw-translate-y: -3.2rem
  }

  .md\:hover\:-translate-y-36:hover {
    --tw-translate-y: -3.6rem
  }

  .md\:hover\:-translate-y-40:hover {
    --tw-translate-y: -4rem
  }

  .md\:hover\:-translate-y-44:hover {
    --tw-translate-y: -4.4rem
  }

  .md\:hover\:-translate-y-48:hover {
    --tw-translate-y: -4.8rem
  }

  .md\:hover\:-translate-y-52:hover {
    --tw-translate-y: -5.2rem
  }

  .md\:hover\:-translate-y-56:hover {
    --tw-translate-y: -5.6rem
  }

  .md\:hover\:-translate-y-60:hover {
    --tw-translate-y: -6rem
  }

  .md\:hover\:-translate-y-64:hover {
    --tw-translate-y: -6.4rem
  }

  .md\:hover\:-translate-y-68:hover {
    --tw-translate-y: -6.8rem
  }

  .md\:hover\:-translate-y-72:hover {
    --tw-translate-y: -7.2rem
  }

  .md\:hover\:-translate-y-76:hover {
    --tw-translate-y: -7.6rem
  }

  .md\:hover\:-translate-y-80:hover {
    --tw-translate-y: -8rem
  }

  .md\:hover\:-translate-y-84:hover {
    --tw-translate-y: -8.4rem
  }

  .md\:hover\:-translate-y-88:hover {
    --tw-translate-y: -8.8rem
  }

  .md\:hover\:-translate-y-92:hover {
    --tw-translate-y: -9.2rem
  }

  .md\:hover\:-translate-y-96:hover {
    --tw-translate-y: -9.6rem
  }

  .md\:hover\:-translate-y-128:hover {
    --tw-translate-y: -12.8rem
  }

  .md\:hover\:-translate-y-136:hover {
    --tw-translate-y: -13.6rem
  }

  .md\:hover\:-translate-y-160:hover {
    --tw-translate-y: -16rem
  }

  .md\:hover\:-translate-y-192:hover {
    --tw-translate-y: -19.2rem
  }

  .md\:hover\:-translate-y-200:hover {
    --tw-translate-y: -20rem
  }

  .md\:hover\:-translate-y-208:hover {
    --tw-translate-y: -20.8rem
  }

  .md\:hover\:-translate-y-216:hover {
    --tw-translate-y: -21.6rem
  }

  .md\:hover\:-translate-y-224:hover {
    --tw-translate-y: -22.4rem
  }

  .md\:hover\:-translate-y-256:hover {
    --tw-translate-y: -25.6rem
  }

  .md\:hover\:-translate-y-288:hover {
    --tw-translate-y: -28.8rem
  }

  .md\:hover\:-translate-y-320:hover {
    --tw-translate-y: -32rem
  }

  .md\:hover\:-translate-y-360:hover {
    --tw-translate-y: -36rem
  }

  .md\:hover\:-translate-y-384:hover {
    --tw-translate-y: -38.4rem
  }

  .md\:hover\:-translate-y-400:hover {
    --tw-translate-y: -40rem
  }

  .md\:hover\:-translate-y-512:hover {
    --tw-translate-y: -51.2rem
  }

  .md\:hover\:-translate-y-640:hover {
    --tw-translate-y: -64rem
  }

  .md\:hover\:-translate-y-xs:hover {
    --tw-translate-y: -32rem
  }

  .md\:hover\:-translate-y-sm:hover {
    --tw-translate-y: -48rem
  }

  .md\:hover\:-translate-y-md:hover {
    --tw-translate-y: -64rem
  }

  .md\:hover\:-translate-y-lg:hover {
    --tw-translate-y: -80rem
  }

  .md\:hover\:-translate-y-xl:hover {
    --tw-translate-y: -96rem
  }

  .md\:hover\:-translate-y-2xl:hover {
    --tw-translate-y: -112rem
  }

  .md\:hover\:-translate-y-3xl:hover {
    --tw-translate-y: -128rem
  }

  .md\:hover\:-translate-y-4xl:hover {
    --tw-translate-y: -144rem
  }

  .md\:hover\:-translate-y-5xl:hover {
    --tw-translate-y: -160rem
  }

  .md\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px
  }

  .md\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.05rem
  }

  .md\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.15rem
  }

  .md\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.25rem
  }

  .md\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.35rem
  }

  .md\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%
  }

  .md\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%
  }

  .md\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%
  }

  .md\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%
  }

  .md\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%
  }

  .md\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%
  }

  .md\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%
  }

  .md\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%
  }

  .md\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%
  }

  .md\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%
  }

  .md\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%
  }

  .md\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%
  }

  .md\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%
  }

  .md\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%
  }

  .md\:focus\:translate-x-0:focus {
    --tw-translate-x: 0
  }

  .md\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.1rem
  }

  .md\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.2rem
  }

  .md\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.3rem
  }

  .md\:focus\:translate-x-4:focus {
    --tw-translate-x: 0.4rem
  }

  .md\:focus\:translate-x-5:focus {
    --tw-translate-x: 0.5rem
  }

  .md\:focus\:translate-x-6:focus {
    --tw-translate-x: 0.6rem
  }

  .md\:focus\:translate-x-7:focus {
    --tw-translate-x: 0.7rem
  }

  .md\:focus\:translate-x-8:focus {
    --tw-translate-x: 0.8rem
  }

  .md\:focus\:translate-x-9:focus {
    --tw-translate-x: 0.9rem
  }

  .md\:focus\:translate-x-10:focus {
    --tw-translate-x: 1.0rem
  }

  .md\:focus\:translate-x-12:focus {
    --tw-translate-x: 1.2rem
  }

  .md\:focus\:translate-x-14:focus {
    --tw-translate-x: 1.4rem
  }

  .md\:focus\:translate-x-16:focus {
    --tw-translate-x: 1.6rem
  }

  .md\:focus\:translate-x-20:focus {
    --tw-translate-x: 2rem
  }

  .md\:focus\:translate-x-24:focus {
    --tw-translate-x: 2.4rem
  }

  .md\:focus\:translate-x-28:focus {
    --tw-translate-x: 2.8rem
  }

  .md\:focus\:translate-x-32:focus {
    --tw-translate-x: 3.2rem
  }

  .md\:focus\:translate-x-36:focus {
    --tw-translate-x: 3.6rem
  }

  .md\:focus\:translate-x-40:focus {
    --tw-translate-x: 4rem
  }

  .md\:focus\:translate-x-44:focus {
    --tw-translate-x: 4.4rem
  }

  .md\:focus\:translate-x-48:focus {
    --tw-translate-x: 4.8rem
  }

  .md\:focus\:translate-x-52:focus {
    --tw-translate-x: 5.2rem
  }

  .md\:focus\:translate-x-56:focus {
    --tw-translate-x: 5.6rem
  }

  .md\:focus\:translate-x-60:focus {
    --tw-translate-x: 6rem
  }

  .md\:focus\:translate-x-64:focus {
    --tw-translate-x: 6.4rem
  }

  .md\:focus\:translate-x-68:focus {
    --tw-translate-x: 6.8rem
  }

  .md\:focus\:translate-x-72:focus {
    --tw-translate-x: 7.2rem
  }

  .md\:focus\:translate-x-76:focus {
    --tw-translate-x: 7.6rem
  }

  .md\:focus\:translate-x-80:focus {
    --tw-translate-x: 8rem
  }

  .md\:focus\:translate-x-84:focus {
    --tw-translate-x: 8.4rem
  }

  .md\:focus\:translate-x-88:focus {
    --tw-translate-x: 8.8rem
  }

  .md\:focus\:translate-x-92:focus {
    --tw-translate-x: 9.2rem
  }

  .md\:focus\:translate-x-96:focus {
    --tw-translate-x: 9.6rem
  }

  .md\:focus\:translate-x-128:focus {
    --tw-translate-x: 12.8rem
  }

  .md\:focus\:translate-x-136:focus {
    --tw-translate-x: 13.6rem
  }

  .md\:focus\:translate-x-160:focus {
    --tw-translate-x: 16rem
  }

  .md\:focus\:translate-x-192:focus {
    --tw-translate-x: 19.2rem
  }

  .md\:focus\:translate-x-200:focus {
    --tw-translate-x: 20rem
  }

  .md\:focus\:translate-x-208:focus {
    --tw-translate-x: 20.8rem
  }

  .md\:focus\:translate-x-216:focus {
    --tw-translate-x: 21.6rem
  }

  .md\:focus\:translate-x-224:focus {
    --tw-translate-x: 22.4rem
  }

  .md\:focus\:translate-x-256:focus {
    --tw-translate-x: 25.6rem
  }

  .md\:focus\:translate-x-288:focus {
    --tw-translate-x: 28.8rem
  }

  .md\:focus\:translate-x-320:focus {
    --tw-translate-x: 32rem
  }

  .md\:focus\:translate-x-360:focus {
    --tw-translate-x: 36rem
  }

  .md\:focus\:translate-x-384:focus {
    --tw-translate-x: 38.4rem
  }

  .md\:focus\:translate-x-400:focus {
    --tw-translate-x: 40rem
  }

  .md\:focus\:translate-x-512:focus {
    --tw-translate-x: 51.2rem
  }

  .md\:focus\:translate-x-640:focus {
    --tw-translate-x: 64rem
  }

  .md\:focus\:translate-x-xs:focus {
    --tw-translate-x: 32rem
  }

  .md\:focus\:translate-x-sm:focus {
    --tw-translate-x: 48rem
  }

  .md\:focus\:translate-x-md:focus {
    --tw-translate-x: 64rem
  }

  .md\:focus\:translate-x-lg:focus {
    --tw-translate-x: 80rem
  }

  .md\:focus\:translate-x-xl:focus {
    --tw-translate-x: 96rem
  }

  .md\:focus\:translate-x-2xl:focus {
    --tw-translate-x: 112rem
  }

  .md\:focus\:translate-x-3xl:focus {
    --tw-translate-x: 128rem
  }

  .md\:focus\:translate-x-4xl:focus {
    --tw-translate-x: 144rem
  }

  .md\:focus\:translate-x-5xl:focus {
    --tw-translate-x: 160rem
  }

  .md\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px
  }

  .md\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.05rem
  }

  .md\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.15rem
  }

  .md\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.25rem
  }

  .md\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.35rem
  }

  .md\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.1rem
  }

  .md\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.2rem
  }

  .md\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.3rem
  }

  .md\:focus\:-translate-x-4:focus {
    --tw-translate-x: -0.4rem
  }

  .md\:focus\:-translate-x-5:focus {
    --tw-translate-x: -0.5rem
  }

  .md\:focus\:-translate-x-6:focus {
    --tw-translate-x: -0.6rem
  }

  .md\:focus\:-translate-x-7:focus {
    --tw-translate-x: -0.7rem
  }

  .md\:focus\:-translate-x-8:focus {
    --tw-translate-x: -0.8rem
  }

  .md\:focus\:-translate-x-9:focus {
    --tw-translate-x: -0.9rem
  }

  .md\:focus\:-translate-x-10:focus {
    --tw-translate-x: -1rem
  }

  .md\:focus\:-translate-x-12:focus {
    --tw-translate-x: -1.2rem
  }

  .md\:focus\:-translate-x-14:focus {
    --tw-translate-x: -1.4rem
  }

  .md\:focus\:-translate-x-16:focus {
    --tw-translate-x: -1.6rem
  }

  .md\:focus\:-translate-x-20:focus {
    --tw-translate-x: -2rem
  }

  .md\:focus\:-translate-x-24:focus {
    --tw-translate-x: -2.4rem
  }

  .md\:focus\:-translate-x-28:focus {
    --tw-translate-x: -2.8rem
  }

  .md\:focus\:-translate-x-32:focus {
    --tw-translate-x: -3.2rem
  }

  .md\:focus\:-translate-x-36:focus {
    --tw-translate-x: -3.6rem
  }

  .md\:focus\:-translate-x-40:focus {
    --tw-translate-x: -4rem
  }

  .md\:focus\:-translate-x-44:focus {
    --tw-translate-x: -4.4rem
  }

  .md\:focus\:-translate-x-48:focus {
    --tw-translate-x: -4.8rem
  }

  .md\:focus\:-translate-x-52:focus {
    --tw-translate-x: -5.2rem
  }

  .md\:focus\:-translate-x-56:focus {
    --tw-translate-x: -5.6rem
  }

  .md\:focus\:-translate-x-60:focus {
    --tw-translate-x: -6rem
  }

  .md\:focus\:-translate-x-64:focus {
    --tw-translate-x: -6.4rem
  }

  .md\:focus\:-translate-x-68:focus {
    --tw-translate-x: -6.8rem
  }

  .md\:focus\:-translate-x-72:focus {
    --tw-translate-x: -7.2rem
  }

  .md\:focus\:-translate-x-76:focus {
    --tw-translate-x: -7.6rem
  }

  .md\:focus\:-translate-x-80:focus {
    --tw-translate-x: -8rem
  }

  .md\:focus\:-translate-x-84:focus {
    --tw-translate-x: -8.4rem
  }

  .md\:focus\:-translate-x-88:focus {
    --tw-translate-x: -8.8rem
  }

  .md\:focus\:-translate-x-92:focus {
    --tw-translate-x: -9.2rem
  }

  .md\:focus\:-translate-x-96:focus {
    --tw-translate-x: -9.6rem
  }

  .md\:focus\:-translate-x-128:focus {
    --tw-translate-x: -12.8rem
  }

  .md\:focus\:-translate-x-136:focus {
    --tw-translate-x: -13.6rem
  }

  .md\:focus\:-translate-x-160:focus {
    --tw-translate-x: -16rem
  }

  .md\:focus\:-translate-x-192:focus {
    --tw-translate-x: -19.2rem
  }

  .md\:focus\:-translate-x-200:focus {
    --tw-translate-x: -20rem
  }

  .md\:focus\:-translate-x-208:focus {
    --tw-translate-x: -20.8rem
  }

  .md\:focus\:-translate-x-216:focus {
    --tw-translate-x: -21.6rem
  }

  .md\:focus\:-translate-x-224:focus {
    --tw-translate-x: -22.4rem
  }

  .md\:focus\:-translate-x-256:focus {
    --tw-translate-x: -25.6rem
  }

  .md\:focus\:-translate-x-288:focus {
    --tw-translate-x: -28.8rem
  }

  .md\:focus\:-translate-x-320:focus {
    --tw-translate-x: -32rem
  }

  .md\:focus\:-translate-x-360:focus {
    --tw-translate-x: -36rem
  }

  .md\:focus\:-translate-x-384:focus {
    --tw-translate-x: -38.4rem
  }

  .md\:focus\:-translate-x-400:focus {
    --tw-translate-x: -40rem
  }

  .md\:focus\:-translate-x-512:focus {
    --tw-translate-x: -51.2rem
  }

  .md\:focus\:-translate-x-640:focus {
    --tw-translate-x: -64rem
  }

  .md\:focus\:-translate-x-xs:focus {
    --tw-translate-x: -32rem
  }

  .md\:focus\:-translate-x-sm:focus {
    --tw-translate-x: -48rem
  }

  .md\:focus\:-translate-x-md:focus {
    --tw-translate-x: -64rem
  }

  .md\:focus\:-translate-x-lg:focus {
    --tw-translate-x: -80rem
  }

  .md\:focus\:-translate-x-xl:focus {
    --tw-translate-x: -96rem
  }

  .md\:focus\:-translate-x-2xl:focus {
    --tw-translate-x: -112rem
  }

  .md\:focus\:-translate-x-3xl:focus {
    --tw-translate-x: -128rem
  }

  .md\:focus\:-translate-x-4xl:focus {
    --tw-translate-x: -144rem
  }

  .md\:focus\:-translate-x-5xl:focus {
    --tw-translate-x: -160rem
  }

  .md\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px
  }

  .md\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.05rem
  }

  .md\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.15rem
  }

  .md\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.25rem
  }

  .md\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.35rem
  }

  .md\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%
  }

  .md\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%
  }

  .md\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%
  }

  .md\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%
  }

  .md\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%
  }

  .md\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%
  }

  .md\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%
  }

  .md\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%
  }

  .md\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%
  }

  .md\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%
  }

  .md\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%
  }

  .md\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%
  }

  .md\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%
  }

  .md\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%
  }

  .md\:focus\:translate-y-0:focus {
    --tw-translate-y: 0
  }

  .md\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.1rem
  }

  .md\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.2rem
  }

  .md\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.3rem
  }

  .md\:focus\:translate-y-4:focus {
    --tw-translate-y: 0.4rem
  }

  .md\:focus\:translate-y-5:focus {
    --tw-translate-y: 0.5rem
  }

  .md\:focus\:translate-y-6:focus {
    --tw-translate-y: 0.6rem
  }

  .md\:focus\:translate-y-7:focus {
    --tw-translate-y: 0.7rem
  }

  .md\:focus\:translate-y-8:focus {
    --tw-translate-y: 0.8rem
  }

  .md\:focus\:translate-y-9:focus {
    --tw-translate-y: 0.9rem
  }

  .md\:focus\:translate-y-10:focus {
    --tw-translate-y: 1.0rem
  }

  .md\:focus\:translate-y-12:focus {
    --tw-translate-y: 1.2rem
  }

  .md\:focus\:translate-y-14:focus {
    --tw-translate-y: 1.4rem
  }

  .md\:focus\:translate-y-16:focus {
    --tw-translate-y: 1.6rem
  }

  .md\:focus\:translate-y-20:focus {
    --tw-translate-y: 2rem
  }

  .md\:focus\:translate-y-24:focus {
    --tw-translate-y: 2.4rem
  }

  .md\:focus\:translate-y-28:focus {
    --tw-translate-y: 2.8rem
  }

  .md\:focus\:translate-y-32:focus {
    --tw-translate-y: 3.2rem
  }

  .md\:focus\:translate-y-36:focus {
    --tw-translate-y: 3.6rem
  }

  .md\:focus\:translate-y-40:focus {
    --tw-translate-y: 4rem
  }

  .md\:focus\:translate-y-44:focus {
    --tw-translate-y: 4.4rem
  }

  .md\:focus\:translate-y-48:focus {
    --tw-translate-y: 4.8rem
  }

  .md\:focus\:translate-y-52:focus {
    --tw-translate-y: 5.2rem
  }

  .md\:focus\:translate-y-56:focus {
    --tw-translate-y: 5.6rem
  }

  .md\:focus\:translate-y-60:focus {
    --tw-translate-y: 6rem
  }

  .md\:focus\:translate-y-64:focus {
    --tw-translate-y: 6.4rem
  }

  .md\:focus\:translate-y-68:focus {
    --tw-translate-y: 6.8rem
  }

  .md\:focus\:translate-y-72:focus {
    --tw-translate-y: 7.2rem
  }

  .md\:focus\:translate-y-76:focus {
    --tw-translate-y: 7.6rem
  }

  .md\:focus\:translate-y-80:focus {
    --tw-translate-y: 8rem
  }

  .md\:focus\:translate-y-84:focus {
    --tw-translate-y: 8.4rem
  }

  .md\:focus\:translate-y-88:focus {
    --tw-translate-y: 8.8rem
  }

  .md\:focus\:translate-y-92:focus {
    --tw-translate-y: 9.2rem
  }

  .md\:focus\:translate-y-96:focus {
    --tw-translate-y: 9.6rem
  }

  .md\:focus\:translate-y-128:focus {
    --tw-translate-y: 12.8rem
  }

  .md\:focus\:translate-y-136:focus {
    --tw-translate-y: 13.6rem
  }

  .md\:focus\:translate-y-160:focus {
    --tw-translate-y: 16rem
  }

  .md\:focus\:translate-y-192:focus {
    --tw-translate-y: 19.2rem
  }

  .md\:focus\:translate-y-200:focus {
    --tw-translate-y: 20rem
  }

  .md\:focus\:translate-y-208:focus {
    --tw-translate-y: 20.8rem
  }

  .md\:focus\:translate-y-216:focus {
    --tw-translate-y: 21.6rem
  }

  .md\:focus\:translate-y-224:focus {
    --tw-translate-y: 22.4rem
  }

  .md\:focus\:translate-y-256:focus {
    --tw-translate-y: 25.6rem
  }

  .md\:focus\:translate-y-288:focus {
    --tw-translate-y: 28.8rem
  }

  .md\:focus\:translate-y-320:focus {
    --tw-translate-y: 32rem
  }

  .md\:focus\:translate-y-360:focus {
    --tw-translate-y: 36rem
  }

  .md\:focus\:translate-y-384:focus {
    --tw-translate-y: 38.4rem
  }

  .md\:focus\:translate-y-400:focus {
    --tw-translate-y: 40rem
  }

  .md\:focus\:translate-y-512:focus {
    --tw-translate-y: 51.2rem
  }

  .md\:focus\:translate-y-640:focus {
    --tw-translate-y: 64rem
  }

  .md\:focus\:translate-y-xs:focus {
    --tw-translate-y: 32rem
  }

  .md\:focus\:translate-y-sm:focus {
    --tw-translate-y: 48rem
  }

  .md\:focus\:translate-y-md:focus {
    --tw-translate-y: 64rem
  }

  .md\:focus\:translate-y-lg:focus {
    --tw-translate-y: 80rem
  }

  .md\:focus\:translate-y-xl:focus {
    --tw-translate-y: 96rem
  }

  .md\:focus\:translate-y-2xl:focus {
    --tw-translate-y: 112rem
  }

  .md\:focus\:translate-y-3xl:focus {
    --tw-translate-y: 128rem
  }

  .md\:focus\:translate-y-4xl:focus {
    --tw-translate-y: 144rem
  }

  .md\:focus\:translate-y-5xl:focus {
    --tw-translate-y: 160rem
  }

  .md\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px
  }

  .md\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.05rem
  }

  .md\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.15rem
  }

  .md\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.25rem
  }

  .md\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.35rem
  }

  .md\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.1rem
  }

  .md\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.2rem
  }

  .md\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.3rem
  }

  .md\:focus\:-translate-y-4:focus {
    --tw-translate-y: -0.4rem
  }

  .md\:focus\:-translate-y-5:focus {
    --tw-translate-y: -0.5rem
  }

  .md\:focus\:-translate-y-6:focus {
    --tw-translate-y: -0.6rem
  }

  .md\:focus\:-translate-y-7:focus {
    --tw-translate-y: -0.7rem
  }

  .md\:focus\:-translate-y-8:focus {
    --tw-translate-y: -0.8rem
  }

  .md\:focus\:-translate-y-9:focus {
    --tw-translate-y: -0.9rem
  }

  .md\:focus\:-translate-y-10:focus {
    --tw-translate-y: -1rem
  }

  .md\:focus\:-translate-y-12:focus {
    --tw-translate-y: -1.2rem
  }

  .md\:focus\:-translate-y-14:focus {
    --tw-translate-y: -1.4rem
  }

  .md\:focus\:-translate-y-16:focus {
    --tw-translate-y: -1.6rem
  }

  .md\:focus\:-translate-y-20:focus {
    --tw-translate-y: -2rem
  }

  .md\:focus\:-translate-y-24:focus {
    --tw-translate-y: -2.4rem
  }

  .md\:focus\:-translate-y-28:focus {
    --tw-translate-y: -2.8rem
  }

  .md\:focus\:-translate-y-32:focus {
    --tw-translate-y: -3.2rem
  }

  .md\:focus\:-translate-y-36:focus {
    --tw-translate-y: -3.6rem
  }

  .md\:focus\:-translate-y-40:focus {
    --tw-translate-y: -4rem
  }

  .md\:focus\:-translate-y-44:focus {
    --tw-translate-y: -4.4rem
  }

  .md\:focus\:-translate-y-48:focus {
    --tw-translate-y: -4.8rem
  }

  .md\:focus\:-translate-y-52:focus {
    --tw-translate-y: -5.2rem
  }

  .md\:focus\:-translate-y-56:focus {
    --tw-translate-y: -5.6rem
  }

  .md\:focus\:-translate-y-60:focus {
    --tw-translate-y: -6rem
  }

  .md\:focus\:-translate-y-64:focus {
    --tw-translate-y: -6.4rem
  }

  .md\:focus\:-translate-y-68:focus {
    --tw-translate-y: -6.8rem
  }

  .md\:focus\:-translate-y-72:focus {
    --tw-translate-y: -7.2rem
  }

  .md\:focus\:-translate-y-76:focus {
    --tw-translate-y: -7.6rem
  }

  .md\:focus\:-translate-y-80:focus {
    --tw-translate-y: -8rem
  }

  .md\:focus\:-translate-y-84:focus {
    --tw-translate-y: -8.4rem
  }

  .md\:focus\:-translate-y-88:focus {
    --tw-translate-y: -8.8rem
  }

  .md\:focus\:-translate-y-92:focus {
    --tw-translate-y: -9.2rem
  }

  .md\:focus\:-translate-y-96:focus {
    --tw-translate-y: -9.6rem
  }

  .md\:focus\:-translate-y-128:focus {
    --tw-translate-y: -12.8rem
  }

  .md\:focus\:-translate-y-136:focus {
    --tw-translate-y: -13.6rem
  }

  .md\:focus\:-translate-y-160:focus {
    --tw-translate-y: -16rem
  }

  .md\:focus\:-translate-y-192:focus {
    --tw-translate-y: -19.2rem
  }

  .md\:focus\:-translate-y-200:focus {
    --tw-translate-y: -20rem
  }

  .md\:focus\:-translate-y-208:focus {
    --tw-translate-y: -20.8rem
  }

  .md\:focus\:-translate-y-216:focus {
    --tw-translate-y: -21.6rem
  }

  .md\:focus\:-translate-y-224:focus {
    --tw-translate-y: -22.4rem
  }

  .md\:focus\:-translate-y-256:focus {
    --tw-translate-y: -25.6rem
  }

  .md\:focus\:-translate-y-288:focus {
    --tw-translate-y: -28.8rem
  }

  .md\:focus\:-translate-y-320:focus {
    --tw-translate-y: -32rem
  }

  .md\:focus\:-translate-y-360:focus {
    --tw-translate-y: -36rem
  }

  .md\:focus\:-translate-y-384:focus {
    --tw-translate-y: -38.4rem
  }

  .md\:focus\:-translate-y-400:focus {
    --tw-translate-y: -40rem
  }

  .md\:focus\:-translate-y-512:focus {
    --tw-translate-y: -51.2rem
  }

  .md\:focus\:-translate-y-640:focus {
    --tw-translate-y: -64rem
  }

  .md\:focus\:-translate-y-xs:focus {
    --tw-translate-y: -32rem
  }

  .md\:focus\:-translate-y-sm:focus {
    --tw-translate-y: -48rem
  }

  .md\:focus\:-translate-y-md:focus {
    --tw-translate-y: -64rem
  }

  .md\:focus\:-translate-y-lg:focus {
    --tw-translate-y: -80rem
  }

  .md\:focus\:-translate-y-xl:focus {
    --tw-translate-y: -96rem
  }

  .md\:focus\:-translate-y-2xl:focus {
    --tw-translate-y: -112rem
  }

  .md\:focus\:-translate-y-3xl:focus {
    --tw-translate-y: -128rem
  }

  .md\:focus\:-translate-y-4xl:focus {
    --tw-translate-y: -144rem
  }

  .md\:focus\:-translate-y-5xl:focus {
    --tw-translate-y: -160rem
  }

  .md\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px
  }

  .md\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.05rem
  }

  .md\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.15rem
  }

  .md\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.25rem
  }

  .md\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.35rem
  }

  .md\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%
  }

  .md\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%
  }

  .md\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%
  }

  .md\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%
  }

  .md\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%
  }

  .md\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%
  }

  .md\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%
  }

  .md\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%
  }

  .md\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%
  }

  .md\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%
  }

  .md\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%
  }

  .md\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%
  }

  .md\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%
  }

  .md\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%
  }

  .md\:skew-x-0 {
    --tw-skew-x: 0deg
  }

  .md\:skew-x-1 {
    --tw-skew-x: 1deg
  }

  .md\:skew-x-2 {
    --tw-skew-x: 2deg
  }

  .md\:skew-x-3 {
    --tw-skew-x: 3deg
  }

  .md\:skew-x-6 {
    --tw-skew-x: 6deg
  }

  .md\:skew-x-12 {
    --tw-skew-x: 12deg
  }

  .md\:-skew-x-12 {
    --tw-skew-x: -12deg
  }

  .md\:-skew-x-6 {
    --tw-skew-x: -6deg
  }

  .md\:-skew-x-3 {
    --tw-skew-x: -3deg
  }

  .md\:-skew-x-2 {
    --tw-skew-x: -2deg
  }

  .md\:-skew-x-1 {
    --tw-skew-x: -1deg
  }

  .md\:skew-y-0 {
    --tw-skew-y: 0deg
  }

  .md\:skew-y-1 {
    --tw-skew-y: 1deg
  }

  .md\:skew-y-2 {
    --tw-skew-y: 2deg
  }

  .md\:skew-y-3 {
    --tw-skew-y: 3deg
  }

  .md\:skew-y-6 {
    --tw-skew-y: 6deg
  }

  .md\:skew-y-12 {
    --tw-skew-y: 12deg
  }

  .md\:-skew-y-12 {
    --tw-skew-y: -12deg
  }

  .md\:-skew-y-6 {
    --tw-skew-y: -6deg
  }

  .md\:-skew-y-3 {
    --tw-skew-y: -3deg
  }

  .md\:-skew-y-2 {
    --tw-skew-y: -2deg
  }

  .md\:-skew-y-1 {
    --tw-skew-y: -1deg
  }

  .md\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg
  }

  .md\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg
  }

  .md\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg
  }

  .md\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg
  }

  .md\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg
  }

  .md\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg
  }

  .md\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg
  }

  .md\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg
  }

  .md\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg
  }

  .md\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg
  }

  .md\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg
  }

  .md\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg
  }

  .md\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg
  }

  .md\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg
  }

  .md\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg
  }

  .md\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg
  }

  .md\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg
  }

  .md\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg
  }

  .md\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg
  }

  .md\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg
  }

  .md\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg
  }

  .md\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg
  }

  .md\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg
  }

  .md\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg
  }

  .md\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg
  }

  .md\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg
  }

  .md\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg
  }

  .md\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg
  }

  .md\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg
  }

  .md\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg
  }

  .md\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg
  }

  .md\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg
  }

  .md\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg
  }

  .md\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg
  }

  .md\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg
  }

  .md\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg
  }

  .md\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg
  }

  .md\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg
  }

  .md\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg
  }

  .md\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg
  }

  .md\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg
  }

  .md\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg
  }

  .md\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg
  }

  .md\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg
  }

  .md\:transition-none {
    transition-property: none
  }

  .md\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .md\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .md\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .md\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .md\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .md\:transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .md\:ease-linear {
    transition-timing-function: linear
  }

  .md\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .md\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .md\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .md\:duration-75 {
    transition-duration: 75ms
  }

  .md\:duration-100 {
    transition-duration: 100ms
  }

  .md\:duration-150 {
    transition-duration: 150ms
  }

  .md\:duration-200 {
    transition-duration: 200ms
  }

  .md\:duration-300 {
    transition-duration: 300ms
  }

  .md\:duration-500 {
    transition-duration: 500ms
  }

  .md\:duration-700 {
    transition-duration: 700ms
  }

  .md\:duration-1000 {
    transition-duration: 1000ms
  }

  .md\:delay-75 {
    transition-delay: 75ms
  }

  .md\:delay-100 {
    transition-delay: 100ms
  }

  .md\:delay-150 {
    transition-delay: 150ms
  }

  .md\:delay-200 {
    transition-delay: 200ms
  }

  .md\:delay-300 {
    transition-delay: 300ms
  }

  .md\:delay-500 {
    transition-delay: 500ms
  }

  .md\:delay-700 {
    transition-delay: 700ms
  }

  .md\:delay-1000 {
    transition-delay: 1000ms
  }

  .md\:animate-none {
    animation: none
  }

  .md\:animate-spin {
    animation: spin 1s linear infinite
  }

  .md\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .md\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .md\:animate-bounce {
    animation: bounce 1s infinite
  }
}

@media (min-width: 1280px) {
  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  .lg\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.1rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.1rem * var(--tw-space-x-reverse));
    margin-left: calc(0.1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.2rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.2rem * var(--tw-space-x-reverse));
    margin-left: calc(0.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.3rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.3rem * var(--tw-space-x-reverse));
    margin-left: calc(0.3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.4rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.4rem * var(--tw-space-x-reverse));
    margin-left: calc(0.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.6rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.6rem * var(--tw-space-x-reverse));
    margin-left: calc(0.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.7rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.7rem * var(--tw-space-x-reverse));
    margin-left: calc(0.7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.8rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.8rem * var(--tw-space-x-reverse));
    margin-left: calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.9rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.9rem * var(--tw-space-x-reverse));
    margin-left: calc(0.9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.0rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.0rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.0rem * var(--tw-space-x-reverse));
    margin-left: calc(1.0rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.2rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.2rem * var(--tw-space-x-reverse));
    margin-left: calc(1.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.4rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.4rem * var(--tw-space-x-reverse));
    margin-left: calc(1.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.6rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.4rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.4rem * var(--tw-space-x-reverse));
    margin-left: calc(2.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.8rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.8rem * var(--tw-space-x-reverse));
    margin-left: calc(2.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.2rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.2rem * var(--tw-space-x-reverse));
    margin-left: calc(3.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.6rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.6rem * var(--tw-space-x-reverse));
    margin-left: calc(3.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4.4rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4.4rem * var(--tw-space-x-reverse));
    margin-left: calc(4.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4.8rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4.8rem * var(--tw-space-x-reverse));
    margin-left: calc(4.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5.2rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5.2rem * var(--tw-space-x-reverse));
    margin-left: calc(5.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5.6rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5.6rem * var(--tw-space-x-reverse));
    margin-left: calc(5.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6.4rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6.4rem * var(--tw-space-x-reverse));
    margin-left: calc(6.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6.8rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6.8rem * var(--tw-space-x-reverse));
    margin-left: calc(6.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7.2rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7.2rem * var(--tw-space-x-reverse));
    margin-left: calc(7.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7.6rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7.6rem * var(--tw-space-x-reverse));
    margin-left: calc(7.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8.4rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8.4rem * var(--tw-space-x-reverse));
    margin-left: calc(8.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8.8rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8.8rem * var(--tw-space-x-reverse));
    margin-left: calc(8.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9.2rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9.2rem * var(--tw-space-x-reverse));
    margin-left: calc(9.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9.6rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9.6rem * var(--tw-space-x-reverse));
    margin-left: calc(9.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12.8rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12.8rem * var(--tw-space-x-reverse));
    margin-left: calc(12.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13.6rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13.6rem * var(--tw-space-x-reverse));
    margin-left: calc(13.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(19.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(19.2rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(19.2rem * var(--tw-space-x-reverse));
    margin-left: calc(19.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20.8rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20.8rem * var(--tw-space-x-reverse));
    margin-left: calc(20.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(21.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(21.6rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(21.6rem * var(--tw-space-x-reverse));
    margin-left: calc(21.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(22.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(22.4rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(22.4rem * var(--tw-space-x-reverse));
    margin-left: calc(22.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(25.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(25.6rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(25.6rem * var(--tw-space-x-reverse));
    margin-left: calc(25.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(28.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(28.8rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(28.8rem * var(--tw-space-x-reverse));
    margin-left: calc(28.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(32rem * var(--tw-space-x-reverse));
    margin-left: calc(32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(36rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(36rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(36rem * var(--tw-space-x-reverse));
    margin-left: calc(36rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(38.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(38.4rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(38.4rem * var(--tw-space-x-reverse));
    margin-left: calc(38.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(40rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(40rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(40rem * var(--tw-space-x-reverse));
    margin-left: calc(40rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(51.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(51.2rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(51.2rem * var(--tw-space-x-reverse));
    margin-left: calc(51.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(64rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(64rem * var(--tw-space-x-reverse));
    margin-left: calc(64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(32rem * var(--tw-space-x-reverse));
    margin-left: calc(32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(48rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(48rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(48rem * var(--tw-space-x-reverse));
    margin-left: calc(48rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(64rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(64rem * var(--tw-space-x-reverse));
    margin-left: calc(64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(80rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(80rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(80rem * var(--tw-space-x-reverse));
    margin-left: calc(80rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(96rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(96rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(96rem * var(--tw-space-x-reverse));
    margin-left: calc(96rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(112rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(112rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(112rem * var(--tw-space-x-reverse));
    margin-left: calc(112rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(128rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(128rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(128rem * var(--tw-space-x-reverse));
    margin-left: calc(128rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(144rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(144rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(144rem * var(--tw-space-x-reverse));
    margin-left: calc(144rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(160rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(160rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(160rem * var(--tw-space-x-reverse));
    margin-left: calc(160rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse))
  }

  .lg\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.05rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.05rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.05rem * var(--tw-space-x-reverse));
    margin-left: calc(0.05rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.15rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.15rem * var(--tw-space-x-reverse));
    margin-left: calc(0.15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.35rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.35rem * var(--tw-space-y-reverse))
  }

  .lg\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.35rem * var(--tw-space-x-reverse));
    margin-left: calc(0.35rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.1rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.1rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.2rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.3rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.3rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.4rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.6rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.7rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.7rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.8rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.9rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.9rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.2rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.4rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.6rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.4rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.8rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.2rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.6rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4.4rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4.8rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-4.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5.2rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-5.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5.6rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-5.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6.4rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-6.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6.8rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-6.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7.2rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-7.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7.6rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-7.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8.4rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-8.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8.8rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9.2rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-9.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9.6rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-9.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12.8rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-12.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13.6rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-13.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-19.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-19.2rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-19.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-19.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20.8rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-20.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-21.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-21.6rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-21.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-21.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-22.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-22.4rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-22.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-22.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-25.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-25.6rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-25.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-25.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-28.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-28.8rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-28.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-28.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-32rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-32rem * var(--tw-space-x-reverse));
    margin-left: calc(-32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-36rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-36rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-36rem * var(--tw-space-x-reverse));
    margin-left: calc(-36rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-38.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-38.4rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-38.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-38.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-40rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-40rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-40rem * var(--tw-space-x-reverse));
    margin-left: calc(-40rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-51.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-51.2rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-51.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-51.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-64rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-64rem * var(--tw-space-x-reverse));
    margin-left: calc(-64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-32rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-32rem * var(--tw-space-x-reverse));
    margin-left: calc(-32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-48rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-48rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-48rem * var(--tw-space-x-reverse));
    margin-left: calc(-48rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-64rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-64rem * var(--tw-space-x-reverse));
    margin-left: calc(-64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-80rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-80rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-80rem * var(--tw-space-x-reverse));
    margin-left: calc(-80rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-96rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-96rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-96rem * var(--tw-space-x-reverse));
    margin-left: calc(-96rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-112rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-112rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-112rem * var(--tw-space-x-reverse));
    margin-left: calc(-112rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-128rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-128rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-128rem * var(--tw-space-x-reverse));
    margin-left: calc(-128rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-144rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-144rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-144rem * var(--tw-space-x-reverse));
    margin-left: calc(-144rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-160rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-160rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-160rem * var(--tw-space-x-reverse));
    margin-left: calc(-160rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.05rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.05rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.05rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.05rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.15rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.15rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.35rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.35rem * var(--tw-space-y-reverse))
  }

  .lg\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.35rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.35rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1
  }

  .lg\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1
  }

  .lg\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse))
  }

  .lg\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .lg\:divide-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  .lg\:divide-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .lg\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse))
  }

  .lg\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .lg\:divide-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(3px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(3px * var(--tw-divide-y-reverse))
  }

  .lg\:divide-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(3px * var(--tw-divide-x-reverse));
    border-left-width: calc(3px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .lg\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse))
  }

  .lg\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .lg\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse))
  }

  .lg\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .lg\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  .lg\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .lg\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1
  }

  .lg\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1
  }

  .lg\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent
  }

  .lg\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor
  }

  .lg\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-divide-opacity))
  }

  .lg\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-grey-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-divide-opacity))
  }

  .lg\:divide-grey-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-divide-opacity))
  }

  .lg\:divide-grey-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-divide-opacity))
  }

  .lg\:divide-grey-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-divide-opacity))
  }

  .lg\:divide-grey-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-divide-opacity))
  }

  .lg\:divide-grey-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .lg\:divide-grey-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-divide-opacity))
  }

  .lg\:divide-grey-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .lg\:divide-grey-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-divide-opacity))
  }

  .lg\:divide-grey-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity))
  }

  .lg\:divide-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .lg\:divide-grey-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-divide-opacity))
  }

  .lg\:divide-grey-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-divide-opacity))
  }

  .lg\:divide-grey-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-divide-opacity))
  }

  .lg\:divide-grey-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .lg\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-divide-opacity))
  }

  .lg\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-divide-opacity))
  }

  .lg\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-divide-opacity))
  }

  .lg\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-divide-opacity))
  }

  .lg\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-divide-opacity))
  }

  .lg\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .lg\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-divide-opacity))
  }

  .lg\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .lg\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-divide-opacity))
  }

  .lg\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity))
  }

  .lg\:divide-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .lg\:divide-gray-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .lg\:divide-gray-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-divide-opacity))
  }

  .lg\:divide-gray-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-divide-opacity))
  }

  .lg\:divide-gray-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-divide-opacity))
  }

  .lg\:divide-gray-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .lg\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-divide-opacity))
  }

  .lg\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-divide-opacity))
  }

  .lg\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-divide-opacity))
  }

  .lg\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-divide-opacity))
  }

  .lg\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-divide-opacity))
  }

  .lg\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-divide-opacity))
  }

  .lg\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-divide-opacity))
  }

  .lg\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-divide-opacity))
  }

  .lg\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-divide-opacity))
  }

  .lg\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-divide-opacity))
  }

  .lg\:divide-red > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-divide-opacity))
  }

  .lg\:divide-red-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-divide-opacity))
  }

  .lg\:divide-red-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-divide-opacity))
  }

  .lg\:divide-red-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-divide-opacity))
  }

  .lg\:divide-red-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-orange-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-divide-opacity))
  }

  .lg\:divide-orange-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-divide-opacity))
  }

  .lg\:divide-orange-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-divide-opacity))
  }

  .lg\:divide-orange-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-divide-opacity))
  }

  .lg\:divide-orange-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-divide-opacity))
  }

  .lg\:divide-orange-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-orange-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-orange-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-orange-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-orange-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-orange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-orange-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-divide-opacity))
  }

  .lg\:divide-orange-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-divide-opacity))
  }

  .lg\:divide-orange-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-orange-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-orange-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-orange-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-orange-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-orange-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-orange-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-orange-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-orange-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-orange-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-orange-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-orange-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-orange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-orange-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-orange-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-orange-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-orange-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-divide-opacity))
  }

  .lg\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-divide-opacity))
  }

  .lg\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-divide-opacity))
  }

  .lg\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-divide-opacity))
  }

  .lg\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-divide-opacity))
  }

  .lg\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-divide-opacity))
  }

  .lg\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-divide-opacity))
  }

  .lg\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-divide-opacity))
  }

  .lg\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-divide-opacity))
  }

  .lg\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-divide-opacity))
  }

  .lg\:divide-yellow > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-divide-opacity))
  }

  .lg\:divide-yellow-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-divide-opacity))
  }

  .lg\:divide-yellow-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-yellow-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-yellow-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-divide-opacity))
  }

  .lg\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-divide-opacity))
  }

  .lg\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-divide-opacity))
  }

  .lg\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-divide-opacity))
  }

  .lg\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-divide-opacity))
  }

  .lg\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-divide-opacity))
  }

  .lg\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-divide-opacity))
  }

  .lg\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-divide-opacity))
  }

  .lg\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-divide-opacity))
  }

  .lg\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-divide-opacity))
  }

  .lg\:divide-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-divide-opacity))
  }

  .lg\:divide-green-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-divide-opacity))
  }

  .lg\:divide-green-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-divide-opacity))
  }

  .lg\:divide-green-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-divide-opacity))
  }

  .lg\:divide-green-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-divide-opacity))
  }

  .lg\:divide-light-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-divide-opacity))
  }

  .lg\:divide-light-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-divide-opacity))
  }

  .lg\:divide-light-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-divide-opacity))
  }

  .lg\:divide-light-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-divide-opacity))
  }

  .lg\:divide-light-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-divide-opacity))
  }

  .lg\:divide-light-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-divide-opacity))
  }

  .lg\:divide-light-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-divide-opacity))
  }

  .lg\:divide-light-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-divide-opacity))
  }

  .lg\:divide-light-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-divide-opacity))
  }

  .lg\:divide-light-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-divide-opacity))
  }

  .lg\:divide-light-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-divide-opacity))
  }

  .lg\:divide-light-green-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-divide-opacity))
  }

  .lg\:divide-light-green-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-divide-opacity))
  }

  .lg\:divide-light-green-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-divide-opacity))
  }

  .lg\:divide-light-green-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-divide-opacity))
  }

  .lg\:divide-teal-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-divide-opacity))
  }

  .lg\:divide-teal-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-divide-opacity))
  }

  .lg\:divide-teal-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-divide-opacity))
  }

  .lg\:divide-teal-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-divide-opacity))
  }

  .lg\:divide-teal-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-divide-opacity))
  }

  .lg\:divide-teal-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-divide-opacity))
  }

  .lg\:divide-teal-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-divide-opacity))
  }

  .lg\:divide-teal-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-divide-opacity))
  }

  .lg\:divide-teal-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-divide-opacity))
  }

  .lg\:divide-teal-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-divide-opacity))
  }

  .lg\:divide-teal > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-divide-opacity))
  }

  .lg\:divide-teal-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-divide-opacity))
  }

  .lg\:divide-teal-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-divide-opacity))
  }

  .lg\:divide-teal-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-divide-opacity))
  }

  .lg\:divide-teal-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-divide-opacity))
  }

  .lg\:divide-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-light-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-divide-opacity))
  }

  .lg\:divide-light-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-divide-opacity))
  }

  .lg\:divide-light-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-divide-opacity))
  }

  .lg\:divide-light-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-divide-opacity))
  }

  .lg\:divide-light-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-divide-opacity))
  }

  .lg\:divide-light-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-divide-opacity))
  }

  .lg\:divide-light-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-divide-opacity))
  }

  .lg\:divide-light-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-divide-opacity))
  }

  .lg\:divide-light-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-divide-opacity))
  }

  .lg\:divide-light-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-divide-opacity))
  }

  .lg\:divide-light-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-divide-opacity))
  }

  .lg\:divide-light-blue-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-light-blue-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-light-blue-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-light-blue-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-divide-opacity))
  }

  .lg\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-divide-opacity))
  }

  .lg\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-divide-opacity))
  }

  .lg\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-divide-opacity))
  }

  .lg\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-divide-opacity))
  }

  .lg\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-divide-opacity))
  }

  .lg\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-divide-opacity))
  }

  .lg\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-divide-opacity))
  }

  .lg\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-divide-opacity))
  }

  .lg\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-divide-opacity))
  }

  .lg\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-divide-opacity))
  }

  .lg\:divide-indigo > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-divide-opacity))
  }

  .lg\:divide-indigo-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-indigo-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-divide-opacity))
  }

  .lg\:divide-indigo-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-divide-opacity))
  }

  .lg\:divide-indigo-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-divide-opacity))
  }

  .lg\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-divide-opacity))
  }

  .lg\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-divide-opacity))
  }

  .lg\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-divide-opacity))
  }

  .lg\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-divide-opacity))
  }

  .lg\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-divide-opacity))
  }

  .lg\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-divide-opacity))
  }

  .lg\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-divide-opacity))
  }

  .lg\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-divide-opacity))
  }

  .lg\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-divide-opacity))
  }

  .lg\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-divide-opacity))
  }

  .lg\:divide-purple > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-divide-opacity))
  }

  .lg\:divide-purple-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-divide-opacity))
  }

  .lg\:divide-purple-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-divide-opacity))
  }

  .lg\:divide-purple-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-divide-opacity))
  }

  .lg\:divide-purple-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-purple > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-purple-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-purple-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-purple-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-deep-purple-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-divide-opacity))
  }

  .lg\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-divide-opacity))
  }

  .lg\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-divide-opacity))
  }

  .lg\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-divide-opacity))
  }

  .lg\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-divide-opacity))
  }

  .lg\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-divide-opacity))
  }

  .lg\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-divide-opacity))
  }

  .lg\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-divide-opacity))
  }

  .lg\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-divide-opacity))
  }

  .lg\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-divide-opacity))
  }

  .lg\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-divide-opacity))
  }

  .lg\:divide-pink > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-divide-opacity))
  }

  .lg\:divide-pink-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-divide-opacity))
  }

  .lg\:divide-pink-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-divide-opacity))
  }

  .lg\:divide-pink-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-divide-opacity))
  }

  .lg\:divide-pink-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-divide-opacity))
  }

  .lg\:divide-lime-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-divide-opacity))
  }

  .lg\:divide-lime-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-divide-opacity))
  }

  .lg\:divide-lime-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-divide-opacity))
  }

  .lg\:divide-lime-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-divide-opacity))
  }

  .lg\:divide-lime-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-divide-opacity))
  }

  .lg\:divide-lime-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-divide-opacity))
  }

  .lg\:divide-lime-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-divide-opacity))
  }

  .lg\:divide-lime-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-divide-opacity))
  }

  .lg\:divide-lime-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-divide-opacity))
  }

  .lg\:divide-lime-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-divide-opacity))
  }

  .lg\:divide-lime > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-divide-opacity))
  }

  .lg\:divide-lime-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-divide-opacity))
  }

  .lg\:divide-lime-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-divide-opacity))
  }

  .lg\:divide-lime-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-lime-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-amber-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-divide-opacity))
  }

  .lg\:divide-amber-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-divide-opacity))
  }

  .lg\:divide-amber-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-divide-opacity))
  }

  .lg\:divide-amber-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-divide-opacity))
  }

  .lg\:divide-amber-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-divide-opacity))
  }

  .lg\:divide-amber-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-divide-opacity))
  }

  .lg\:divide-amber-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-amber-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-amber-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-amber-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-amber > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-divide-opacity))
  }

  .lg\:divide-amber-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-divide-opacity))
  }

  .lg\:divide-amber-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-divide-opacity))
  }

  .lg\:divide-amber-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-amber-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-divide-opacity))
  }

  .lg\:divide-brown-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-divide-opacity))
  }

  .lg\:divide-brown-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-divide-opacity))
  }

  .lg\:divide-brown-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-divide-opacity))
  }

  .lg\:divide-brown-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-divide-opacity))
  }

  .lg\:divide-brown-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-divide-opacity))
  }

  .lg\:divide-brown-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-divide-opacity))
  }

  .lg\:divide-brown-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-divide-opacity))
  }

  .lg\:divide-brown-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-divide-opacity))
  }

  .lg\:divide-brown-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-divide-opacity))
  }

  .lg\:divide-brown-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-divide-opacity))
  }

  .lg\:divide-brown > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-divide-opacity))
  }

  .lg\:divide-brown-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-divide-opacity))
  }

  .lg\:divide-brown-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-divide-opacity))
  }

  .lg\:divide-brown-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-divide-opacity))
  }

  .lg\:divide-brown-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-gray-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-gray-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-gray-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-divide-opacity))
  }

  .lg\:divide-blue-gray-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-divide-opacity))
  }

  .lg\:divide-cyan-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-divide-opacity))
  }

  .lg\:divide-cyan-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-divide-opacity))
  }

  .lg\:divide-cyan-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-divide-opacity))
  }

  .lg\:divide-cyan-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-divide-opacity))
  }

  .lg\:divide-cyan-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-divide-opacity))
  }

  .lg\:divide-cyan-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-divide-opacity))
  }

  .lg\:divide-cyan-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-divide-opacity))
  }

  .lg\:divide-cyan-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-divide-opacity))
  }

  .lg\:divide-cyan-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-divide-opacity))
  }

  .lg\:divide-cyan-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-divide-opacity))
  }

  .lg\:divide-cyan > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-divide-opacity))
  }

  .lg\:divide-cyan-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-cyan-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-cyan-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-divide-opacity))
  }

  .lg\:divide-cyan-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-divide-opacity))
  }

  .lg\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid
  }

  .lg\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed
  }

  .lg\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted
  }

  .lg\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double
  }

  .lg\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none
  }

  .lg\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0
  }

  .lg\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05
  }

  .lg\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1
  }

  .lg\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2
  }

  .lg\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25
  }

  .lg\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3
  }

  .lg\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4
  }

  .lg\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5
  }

  .lg\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6
  }

  .lg\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7
  }

  .lg\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75
  }

  .lg\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8
  }

  .lg\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9
  }

  .lg\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95
  }

  .lg\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1
  }

  .lg\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .lg\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .lg\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .lg\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .lg\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .lg\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .lg\:appearance-none {
    -webkit-appearance: none;
            appearance: none
  }

  .lg\:bg-fixed {
    background-attachment: fixed
  }

  .lg\:bg-local {
    background-attachment: local
  }

  .lg\:bg-scroll {
    background-attachment: scroll
  }

  .lg\:bg-clip-border {
    background-clip: border-box
  }

  .lg\:bg-clip-padding {
    background-clip: padding-box
  }

  .lg\:bg-clip-content {
    background-clip: content-box
  }

  .lg\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text
  }

  .lg\:bg-transparent {
    background-color: transparent
  }

  .lg\:bg-current {
    background-color: currentColor
  }

  .lg\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-grey-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .lg\:bg-grey-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .lg\:bg-grey-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .lg\:bg-grey-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .lg\:bg-grey-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .lg\:bg-grey-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:bg-grey-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .lg\:bg-grey-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:bg-grey-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .lg\:bg-grey-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .lg\:bg-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:bg-grey-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .lg\:bg-grey-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .lg\:bg-grey-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .lg\:bg-grey-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .lg\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .lg\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .lg\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .lg\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .lg\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .lg\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .lg\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .lg\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:bg-gray-hover {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .lg\:bg-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .lg\:bg-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .lg\:bg-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .lg\:bg-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .lg\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .lg\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .lg\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .lg\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .lg\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .lg\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .lg\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .lg\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .lg\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .lg\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .lg\:bg-red-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .lg\:bg-red-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .lg\:bg-red-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .lg\:bg-red-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .lg\:bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .lg\:bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .lg\:bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .lg\:bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .lg\:bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .lg\:bg-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .lg\:bg-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .lg\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .lg\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .lg\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .lg\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .lg\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .lg\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .lg\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .lg\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .lg\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .lg\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .lg\:bg-yellow-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .lg\:bg-yellow-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-yellow-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-yellow-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .lg\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .lg\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .lg\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .lg\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .lg\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .lg\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .lg\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .lg\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .lg\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .lg\:bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .lg\:bg-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .lg\:bg-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .lg\:bg-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .lg\:bg-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .lg\:bg-light-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .lg\:bg-light-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .lg\:bg-light-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .lg\:bg-light-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .lg\:bg-light-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .lg\:bg-light-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .lg\:bg-light-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .lg\:bg-light-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .lg\:bg-light-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .lg\:bg-light-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .lg\:bg-light-green {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .lg\:bg-light-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .lg\:bg-light-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .lg\:bg-light-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .lg\:bg-light-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .lg\:bg-teal-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .lg\:bg-teal-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .lg\:bg-teal-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .lg\:bg-teal-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .lg\:bg-teal-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .lg\:bg-teal-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .lg\:bg-teal-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .lg\:bg-teal-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .lg\:bg-teal-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .lg\:bg-teal-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .lg\:bg-teal {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .lg\:bg-teal-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .lg\:bg-teal-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .lg\:bg-teal-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .lg\:bg-teal-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .lg\:bg-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-light-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .lg\:bg-light-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .lg\:bg-light-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .lg\:bg-light-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .lg\:bg-light-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .lg\:bg-light-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .lg\:bg-light-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .lg\:bg-light-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .lg\:bg-light-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .lg\:bg-light-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .lg\:bg-light-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .lg\:bg-light-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-light-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-light-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-light-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .lg\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .lg\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .lg\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .lg\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .lg\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .lg\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .lg\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .lg\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .lg\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .lg\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .lg\:bg-indigo {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .lg\:bg-indigo-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-indigo-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .lg\:bg-indigo-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .lg\:bg-indigo-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .lg\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .lg\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .lg\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .lg\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .lg\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .lg\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .lg\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .lg\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .lg\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .lg\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .lg\:bg-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .lg\:bg-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .lg\:bg-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .lg\:bg-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .lg\:bg-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-deep-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .lg\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .lg\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .lg\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .lg\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .lg\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .lg\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .lg\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .lg\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .lg\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .lg\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .lg\:bg-pink {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .lg\:bg-pink-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .lg\:bg-pink-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .lg\:bg-pink-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .lg\:bg-pink-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .lg\:bg-lime-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .lg\:bg-lime-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .lg\:bg-lime-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .lg\:bg-lime-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .lg\:bg-lime-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .lg\:bg-lime-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .lg\:bg-lime-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .lg\:bg-lime-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .lg\:bg-lime-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .lg\:bg-lime-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .lg\:bg-lime {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .lg\:bg-lime-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .lg\:bg-lime-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .lg\:bg-lime-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-lime-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-amber-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .lg\:bg-amber-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .lg\:bg-amber-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .lg\:bg-amber-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .lg\:bg-amber-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .lg\:bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .lg\:bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-amber-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-amber-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-amber-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-amber {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .lg\:bg-amber-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .lg\:bg-amber-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .lg\:bg-amber-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-amber-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-brown-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .lg\:bg-brown-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .lg\:bg-brown-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .lg\:bg-brown-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .lg\:bg-brown-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .lg\:bg-brown-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .lg\:bg-brown-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .lg\:bg-brown-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .lg\:bg-brown-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .lg\:bg-brown-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .lg\:bg-brown {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .lg\:bg-brown-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .lg\:bg-brown-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .lg\:bg-brown-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .lg\:bg-brown-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .lg\:bg-blue-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-transparent {
    background-color: transparent
  }

  .group:hover .lg\:group-hover\:bg-current {
    background-color: currentColor
  }

  .group:hover .lg\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-grey-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-grey-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-grey-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-grey-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-grey-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-grey-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-grey-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-grey-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-grey-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-grey-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-grey-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-grey-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-grey-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-grey-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray-hover {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .group:hover .lg\:group-hover\:bg-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-yellow-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-yellow-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-yellow-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-yellow-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-green {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-teal-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-teal-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-teal-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-teal-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-teal-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-teal-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-teal-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-teal-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-teal-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-teal-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-teal {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-teal-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-teal-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-teal-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-teal-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-light-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-indigo {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-indigo-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-indigo-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-indigo-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-indigo-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-deep-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-pink {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-pink-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-pink-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-pink-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-pink-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-lime-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-lime-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-lime-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-lime-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-lime-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-lime-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-lime-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-lime-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-lime-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-lime-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-lime {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-lime-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-lime-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-lime-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-lime-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-amber-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-amber-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-amber-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-amber-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-amber-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-amber-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-amber-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-amber-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-amber {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-amber-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-amber-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-amber-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-amber-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-brown-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-brown-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-brown-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-brown-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-brown-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-brown-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-brown-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-brown-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-brown-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-brown-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-brown {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-brown-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-brown-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-brown-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-brown-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-blue-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-transparent:focus-within {
    background-color: transparent
  }

  .lg\:focus-within\:bg-current:focus-within {
    background-color: currentColor
  }

  .lg\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-grey-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-grey-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-grey-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-grey-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-grey-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-grey-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-grey-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-grey-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-grey-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-grey-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-grey-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-grey-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-grey-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-grey-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-gray-hover:focus-within {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .lg\:focus-within\:bg-gray-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-gray-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-gray-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-gray-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-red:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-red-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-red-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-red-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-red-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-orange-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-orange-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-orange-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-orange-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-orange-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-orange-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-orange-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-orange-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-orange-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-orange-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-orange:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-orange-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-orange-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-orange-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-orange-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-orange-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-orange-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-orange-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-orange-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-orange-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-orange-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-orange-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-orange-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-orange-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-orange-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-orange:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-orange-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-orange-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-orange-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-orange-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-yellow:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-yellow-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-yellow-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-yellow-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-yellow-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-green-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-green-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-green-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-green-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-green-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-green-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-green-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-green-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-teal-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-teal-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-teal-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-teal-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-teal-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-teal-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-teal-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-teal-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-teal-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-teal-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-teal:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-teal-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-teal-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-teal-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-teal-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-blue-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-blue-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-blue-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-light-blue-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-indigo:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-indigo-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-indigo-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-indigo-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-indigo-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-purple:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-purple-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-purple-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-purple-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-purple-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-purple:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-purple-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-purple-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-purple-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-deep-purple-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-pink:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-pink-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-pink-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-pink-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-pink-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-lime-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-lime-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-lime-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-lime-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-lime-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-lime-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-lime-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-lime-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-lime-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-lime-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-lime:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-lime-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-lime-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-lime-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-lime-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-amber-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-amber-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-amber-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-amber-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-amber-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-amber-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-amber-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-amber-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-amber-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-amber-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-amber:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-amber-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-amber-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-amber-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-amber-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-brown-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-brown-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-brown-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-brown-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-brown-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-brown-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-brown-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-brown-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-brown-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-brown-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-brown:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-brown-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-brown-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-brown-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-brown-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-gray-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-gray-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-gray-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-blue-gray-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-cyan-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-cyan-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-cyan-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-cyan-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-cyan-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-cyan-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-cyan-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-cyan-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-cyan-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-cyan-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-cyan:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-cyan-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-cyan-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-cyan-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .lg\:focus-within\:bg-cyan-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-transparent:hover {
    background-color: transparent
  }

  .lg\:hover\:bg-current:hover {
    background-color: currentColor
  }

  .lg\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-grey-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-grey-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-grey-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-grey-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-grey-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-grey-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-grey-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-grey-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-grey-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-grey-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-grey-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-grey-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-grey-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-grey-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray-hover:hover {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .lg\:hover\:bg-gray-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-orange-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-orange-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-orange-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-orange-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-orange-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-orange-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-orange-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-orange-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-orange-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-orange-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-orange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-orange-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-orange-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-orange-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-orange-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-orange-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-orange-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-orange-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-orange-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-orange-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-orange-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-orange-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-orange-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-orange-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-orange-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-orange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-orange-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-orange-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-orange-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-orange-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-yellow:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-yellow-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-yellow-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-yellow-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-yellow-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-green-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-green-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-green-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-green-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-teal-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-teal-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-teal-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-teal-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-teal-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-teal-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-teal-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-teal-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-teal-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-teal-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-teal:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-teal-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-teal-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-teal-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-teal-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-blue-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-blue-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-blue-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-light-blue-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-indigo:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-indigo-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-indigo-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-indigo-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-indigo-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-purple:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-purple-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-purple-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-purple-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-purple-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-purple:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-purple-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-purple-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-purple-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-deep-purple-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-pink:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-pink-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-pink-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-pink-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-pink-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-lime-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-lime-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-lime-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-lime-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-lime-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-lime-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-lime-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-lime-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-lime-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-lime-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-lime:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-lime-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-lime-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-lime-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-lime-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-amber-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-amber-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-amber-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-amber-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-amber-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-amber-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-amber-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-amber-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-amber-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-amber-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-amber:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-amber-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-amber-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-amber-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-amber-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-brown-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-brown-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-brown-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-brown-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-brown-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-brown-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-brown-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-brown-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-brown-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-brown-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-brown:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-brown-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-brown-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-brown-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-brown-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-gray-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-gray-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-gray-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-blue-gray-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-transparent:focus {
    background-color: transparent
  }

  .lg\:focus\:bg-current:focus {
    background-color: currentColor
  }

  .lg\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-grey-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-grey-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-grey-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-grey-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-grey-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-grey-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-grey-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-grey-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-grey-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-grey-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-grey-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-grey-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-grey-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-grey-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray-hover:focus {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .lg\:focus\:bg-gray-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-orange-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-orange-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-orange-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-orange-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-orange-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-orange-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-orange-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-orange-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-orange-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-orange-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-orange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-orange-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-orange-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-orange-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-orange-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-orange-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-orange-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-orange-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-orange-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-orange-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-orange-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-orange-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-orange-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-orange-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-orange-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-orange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-orange-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-orange-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-orange-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-orange-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-yellow:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-yellow-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-yellow-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-yellow-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-yellow-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-green-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-green-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-green-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-green-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-teal-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-teal-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-teal-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-teal-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-teal-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-teal-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-teal-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-teal-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-teal-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-teal-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-teal:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-teal-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-teal-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-teal-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-teal-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-blue-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-blue-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-blue-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-light-blue-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-indigo:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-indigo-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-indigo-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-indigo-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-indigo-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-purple:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-purple-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-purple-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-purple-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-purple-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-purple:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-purple-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-purple-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-purple-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-deep-purple-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-pink:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-pink-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-pink-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-pink-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-pink-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-lime-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-lime-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-lime-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-lime-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-lime-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-lime-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-lime-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-lime-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-lime-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-lime-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-lime:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-lime-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-lime-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-lime-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-lime-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-amber-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-amber-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-amber-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-amber-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-amber-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-amber-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-amber-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-amber-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-amber-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-amber-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-amber:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-amber-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-amber-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-amber-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-amber-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-brown-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-brown-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-brown-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-brown-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-brown-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-brown-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-brown-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-brown-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-brown-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-brown-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-brown:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-brown-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-brown-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-brown-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-brown-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-gray-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-gray-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-gray-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-blue-gray-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .lg\:bg-none {
    background-image: none
  }

  .lg\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .lg\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:from-black {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .lg\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:from-grey-50 {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .lg\:from-grey-100 {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .lg\:from-grey-200 {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .lg\:from-grey-300 {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .lg\:from-grey-400 {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .lg\:from-grey-500 {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:from-grey-600 {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .lg\:from-grey-700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:from-grey-800 {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .lg\:from-grey-900 {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .lg\:from-grey {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:from-grey-A100 {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .lg\:from-grey-A200 {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .lg\:from-grey-A400 {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .lg\:from-grey-A700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:from-gray-50 {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .lg\:from-gray-100 {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .lg\:from-gray-200 {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .lg\:from-gray-300 {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .lg\:from-gray-400 {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .lg\:from-gray-500 {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:from-gray-600 {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .lg\:from-gray-700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:from-gray-800 {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .lg\:from-gray-900 {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .lg\:from-gray {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:from-gray-hover {
    --tw-gradient-from: rgba(0, 0, 0, 0.04);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:from-gray-A100 {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .lg\:from-gray-A200 {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .lg\:from-gray-A400 {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .lg\:from-gray-A700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:from-red-50 {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .lg\:from-red-100 {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .lg\:from-red-200 {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .lg\:from-red-300 {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .lg\:from-red-400 {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .lg\:from-red-500 {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .lg\:from-red-600 {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .lg\:from-red-700 {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .lg\:from-red-800 {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .lg\:from-red-900 {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .lg\:from-red {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .lg\:from-red-A100 {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .lg\:from-red-A200 {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .lg\:from-red-A400 {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .lg\:from-red-A700 {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .lg\:from-orange-50 {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .lg\:from-orange-100 {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .lg\:from-orange-200 {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .lg\:from-orange-300 {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .lg\:from-orange-400 {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .lg\:from-orange-500 {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .lg\:from-orange-600 {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .lg\:from-orange-700 {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .lg\:from-orange-800 {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .lg\:from-orange-900 {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .lg\:from-orange {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .lg\:from-orange-A100 {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .lg\:from-orange-A200 {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .lg\:from-orange-A400 {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .lg\:from-orange-A700 {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .lg\:from-deep-orange-50 {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .lg\:from-deep-orange-100 {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .lg\:from-deep-orange-200 {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .lg\:from-deep-orange-300 {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .lg\:from-deep-orange-400 {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .lg\:from-deep-orange-500 {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .lg\:from-deep-orange-600 {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .lg\:from-deep-orange-700 {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .lg\:from-deep-orange-800 {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .lg\:from-deep-orange-900 {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .lg\:from-deep-orange {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .lg\:from-deep-orange-A100 {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .lg\:from-deep-orange-A200 {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .lg\:from-deep-orange-A400 {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .lg\:from-deep-orange-A700 {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .lg\:from-yellow-50 {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .lg\:from-yellow-100 {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .lg\:from-yellow-200 {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .lg\:from-yellow-300 {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .lg\:from-yellow-400 {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .lg\:from-yellow-500 {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .lg\:from-yellow-600 {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .lg\:from-yellow-700 {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .lg\:from-yellow-800 {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .lg\:from-yellow-900 {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .lg\:from-yellow {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .lg\:from-yellow-A100 {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .lg\:from-yellow-A200 {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .lg\:from-yellow-A400 {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .lg\:from-yellow-A700 {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .lg\:from-green-50 {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .lg\:from-green-100 {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .lg\:from-green-200 {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .lg\:from-green-300 {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .lg\:from-green-400 {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .lg\:from-green-500 {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .lg\:from-green-600 {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .lg\:from-green-700 {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .lg\:from-green-800 {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .lg\:from-green-900 {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .lg\:from-green {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .lg\:from-green-A100 {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .lg\:from-green-A200 {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .lg\:from-green-A400 {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .lg\:from-green-A700 {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .lg\:from-light-green-50 {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .lg\:from-light-green-100 {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .lg\:from-light-green-200 {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .lg\:from-light-green-300 {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .lg\:from-light-green-400 {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .lg\:from-light-green-500 {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .lg\:from-light-green-600 {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .lg\:from-light-green-700 {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .lg\:from-light-green-800 {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .lg\:from-light-green-900 {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .lg\:from-light-green {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .lg\:from-light-green-A100 {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .lg\:from-light-green-A200 {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .lg\:from-light-green-A400 {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .lg\:from-light-green-A700 {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .lg\:from-teal-50 {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .lg\:from-teal-100 {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .lg\:from-teal-200 {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .lg\:from-teal-300 {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .lg\:from-teal-400 {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .lg\:from-teal-500 {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .lg\:from-teal-600 {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .lg\:from-teal-700 {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .lg\:from-teal-800 {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .lg\:from-teal-900 {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .lg\:from-teal {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .lg\:from-teal-A100 {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .lg\:from-teal-A200 {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .lg\:from-teal-A400 {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .lg\:from-teal-A700 {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .lg\:from-blue-50 {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .lg\:from-blue-100 {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .lg\:from-blue-200 {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .lg\:from-blue-300 {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .lg\:from-blue-400 {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .lg\:from-blue-500 {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .lg\:from-blue-600 {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .lg\:from-blue-700 {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .lg\:from-blue-800 {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .lg\:from-blue-900 {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .lg\:from-blue {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .lg\:from-blue-A100 {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .lg\:from-blue-A200 {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .lg\:from-blue-A400 {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .lg\:from-blue-A700 {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .lg\:from-light-blue-50 {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .lg\:from-light-blue-100 {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .lg\:from-light-blue-200 {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .lg\:from-light-blue-300 {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .lg\:from-light-blue-400 {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .lg\:from-light-blue-500 {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .lg\:from-light-blue-600 {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .lg\:from-light-blue-700 {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .lg\:from-light-blue-800 {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .lg\:from-light-blue-900 {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .lg\:from-light-blue {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .lg\:from-light-blue-A100 {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .lg\:from-light-blue-A200 {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .lg\:from-light-blue-A400 {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .lg\:from-light-blue-A700 {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .lg\:from-indigo-50 {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .lg\:from-indigo-100 {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .lg\:from-indigo-200 {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .lg\:from-indigo-300 {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .lg\:from-indigo-400 {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .lg\:from-indigo-500 {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .lg\:from-indigo-600 {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .lg\:from-indigo-700 {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .lg\:from-indigo-800 {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .lg\:from-indigo-900 {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .lg\:from-indigo {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .lg\:from-indigo-A100 {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .lg\:from-indigo-A200 {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .lg\:from-indigo-A400 {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .lg\:from-indigo-A700 {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .lg\:from-purple-50 {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .lg\:from-purple-100 {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .lg\:from-purple-200 {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .lg\:from-purple-300 {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .lg\:from-purple-400 {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .lg\:from-purple-500 {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .lg\:from-purple-600 {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .lg\:from-purple-700 {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .lg\:from-purple-800 {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .lg\:from-purple-900 {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .lg\:from-purple {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .lg\:from-purple-A100 {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .lg\:from-purple-A200 {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .lg\:from-purple-A400 {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .lg\:from-purple-A700 {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .lg\:from-deep-purple-50 {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .lg\:from-deep-purple-100 {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .lg\:from-deep-purple-200 {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .lg\:from-deep-purple-300 {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .lg\:from-deep-purple-400 {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .lg\:from-deep-purple-500 {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .lg\:from-deep-purple-600 {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .lg\:from-deep-purple-700 {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .lg\:from-deep-purple-800 {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .lg\:from-deep-purple-900 {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .lg\:from-deep-purple {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .lg\:from-deep-purple-A100 {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .lg\:from-deep-purple-A200 {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .lg\:from-deep-purple-A400 {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .lg\:from-deep-purple-A700 {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .lg\:from-pink-50 {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .lg\:from-pink-100 {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .lg\:from-pink-200 {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .lg\:from-pink-300 {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .lg\:from-pink-400 {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .lg\:from-pink-500 {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .lg\:from-pink-600 {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .lg\:from-pink-700 {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .lg\:from-pink-800 {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .lg\:from-pink-900 {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .lg\:from-pink {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .lg\:from-pink-A100 {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .lg\:from-pink-A200 {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .lg\:from-pink-A400 {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .lg\:from-pink-A700 {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .lg\:from-lime-50 {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .lg\:from-lime-100 {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .lg\:from-lime-200 {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .lg\:from-lime-300 {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .lg\:from-lime-400 {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .lg\:from-lime-500 {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .lg\:from-lime-600 {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .lg\:from-lime-700 {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .lg\:from-lime-800 {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .lg\:from-lime-900 {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .lg\:from-lime {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .lg\:from-lime-A100 {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .lg\:from-lime-A200 {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .lg\:from-lime-A400 {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .lg\:from-lime-A700 {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .lg\:from-amber-50 {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .lg\:from-amber-100 {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .lg\:from-amber-200 {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .lg\:from-amber-300 {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .lg\:from-amber-400 {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .lg\:from-amber-500 {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .lg\:from-amber-600 {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .lg\:from-amber-700 {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .lg\:from-amber-800 {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .lg\:from-amber-900 {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .lg\:from-amber {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .lg\:from-amber-A100 {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .lg\:from-amber-A200 {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .lg\:from-amber-A400 {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .lg\:from-amber-A700 {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .lg\:from-brown-50 {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .lg\:from-brown-100 {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .lg\:from-brown-200 {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .lg\:from-brown-300 {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .lg\:from-brown-400 {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .lg\:from-brown-500 {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .lg\:from-brown-600 {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .lg\:from-brown-700 {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .lg\:from-brown-800 {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .lg\:from-brown-900 {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .lg\:from-brown {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .lg\:from-brown-A100 {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .lg\:from-brown-A200 {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .lg\:from-brown-A400 {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .lg\:from-brown-A700 {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .lg\:from-blue-gray-50 {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .lg\:from-blue-gray-100 {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .lg\:from-blue-gray-200 {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .lg\:from-blue-gray-300 {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .lg\:from-blue-gray-400 {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .lg\:from-blue-gray-500 {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .lg\:from-blue-gray-600 {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .lg\:from-blue-gray-700 {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .lg\:from-blue-gray-800 {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .lg\:from-blue-gray-900 {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .lg\:from-blue-gray {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .lg\:from-blue-gray-A100 {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .lg\:from-blue-gray-A200 {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .lg\:from-blue-gray-A400 {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .lg\:from-blue-gray-A700 {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .lg\:from-cyan-50 {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .lg\:from-cyan-100 {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .lg\:from-cyan-200 {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .lg\:from-cyan-300 {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .lg\:from-cyan-400 {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .lg\:from-cyan-500 {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .lg\:from-cyan-600 {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .lg\:from-cyan-700 {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .lg\:from-cyan-800 {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .lg\:from-cyan-900 {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .lg\:from-cyan {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .lg\:from-cyan-A100 {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .lg\:from-cyan-A200 {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .lg\:from-cyan-A400 {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .lg\:from-cyan-A700 {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .lg\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .lg\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:via-grey-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .lg\:via-grey-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .lg\:via-grey-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .lg\:via-grey-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .lg\:via-grey-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .lg\:via-grey-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:via-grey-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .lg\:via-grey-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:via-grey-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .lg\:via-grey-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .lg\:via-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:via-grey-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .lg\:via-grey-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .lg\:via-grey-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .lg\:via-grey-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .lg\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .lg\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .lg\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .lg\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .lg\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .lg\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .lg\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .lg\:via-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:via-gray-hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:via-gray-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .lg\:via-gray-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .lg\:via-gray-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .lg\:via-gray-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .lg\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .lg\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .lg\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .lg\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .lg\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .lg\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .lg\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .lg\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .lg\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .lg\:via-red {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .lg\:via-red-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .lg\:via-red-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .lg\:via-red-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .lg\:via-red-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .lg\:via-orange-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .lg\:via-orange-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .lg\:via-orange-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .lg\:via-orange-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .lg\:via-orange-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .lg\:via-orange-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .lg\:via-orange-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .lg\:via-orange-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .lg\:via-orange-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .lg\:via-orange-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .lg\:via-orange {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .lg\:via-orange-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .lg\:via-orange-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .lg\:via-orange-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .lg\:via-orange-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .lg\:via-deep-orange-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .lg\:via-deep-orange-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .lg\:via-deep-orange-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .lg\:via-deep-orange-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .lg\:via-deep-orange-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .lg\:via-deep-orange-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .lg\:via-deep-orange-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .lg\:via-deep-orange-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .lg\:via-deep-orange-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .lg\:via-deep-orange-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .lg\:via-deep-orange {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .lg\:via-deep-orange-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .lg\:via-deep-orange-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .lg\:via-deep-orange-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .lg\:via-deep-orange-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .lg\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .lg\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .lg\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .lg\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .lg\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .lg\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .lg\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .lg\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .lg\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .lg\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .lg\:via-yellow {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .lg\:via-yellow-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .lg\:via-yellow-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .lg\:via-yellow-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .lg\:via-yellow-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .lg\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .lg\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .lg\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .lg\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .lg\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .lg\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .lg\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .lg\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .lg\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .lg\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .lg\:via-green {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .lg\:via-green-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .lg\:via-green-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .lg\:via-green-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .lg\:via-green-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .lg\:via-light-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .lg\:via-light-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .lg\:via-light-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .lg\:via-light-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .lg\:via-light-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .lg\:via-light-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .lg\:via-light-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .lg\:via-light-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .lg\:via-light-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .lg\:via-light-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .lg\:via-light-green {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .lg\:via-light-green-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .lg\:via-light-green-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .lg\:via-light-green-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .lg\:via-light-green-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .lg\:via-teal-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .lg\:via-teal-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .lg\:via-teal-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .lg\:via-teal-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .lg\:via-teal-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .lg\:via-teal-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .lg\:via-teal-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .lg\:via-teal-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .lg\:via-teal-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .lg\:via-teal-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .lg\:via-teal {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .lg\:via-teal-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .lg\:via-teal-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .lg\:via-teal-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .lg\:via-teal-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .lg\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .lg\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .lg\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .lg\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .lg\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .lg\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .lg\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .lg\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .lg\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .lg\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .lg\:via-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .lg\:via-blue-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .lg\:via-blue-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .lg\:via-blue-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .lg\:via-blue-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .lg\:via-light-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .lg\:via-light-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .lg\:via-light-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .lg\:via-light-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .lg\:via-light-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .lg\:via-light-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .lg\:via-light-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .lg\:via-light-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .lg\:via-light-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .lg\:via-light-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .lg\:via-light-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .lg\:via-light-blue-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .lg\:via-light-blue-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .lg\:via-light-blue-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .lg\:via-light-blue-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .lg\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .lg\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .lg\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .lg\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .lg\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .lg\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .lg\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .lg\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .lg\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .lg\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .lg\:via-indigo {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .lg\:via-indigo-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .lg\:via-indigo-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .lg\:via-indigo-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .lg\:via-indigo-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .lg\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .lg\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .lg\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .lg\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .lg\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .lg\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .lg\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .lg\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .lg\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .lg\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .lg\:via-purple {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .lg\:via-purple-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .lg\:via-purple-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .lg\:via-purple-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .lg\:via-purple-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .lg\:via-deep-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .lg\:via-deep-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .lg\:via-deep-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .lg\:via-deep-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .lg\:via-deep-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .lg\:via-deep-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .lg\:via-deep-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .lg\:via-deep-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .lg\:via-deep-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .lg\:via-deep-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .lg\:via-deep-purple {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .lg\:via-deep-purple-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .lg\:via-deep-purple-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .lg\:via-deep-purple-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .lg\:via-deep-purple-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .lg\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .lg\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .lg\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .lg\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .lg\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .lg\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .lg\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .lg\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .lg\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .lg\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .lg\:via-pink {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .lg\:via-pink-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .lg\:via-pink-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .lg\:via-pink-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .lg\:via-pink-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .lg\:via-lime-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .lg\:via-lime-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .lg\:via-lime-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .lg\:via-lime-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .lg\:via-lime-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .lg\:via-lime-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .lg\:via-lime-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .lg\:via-lime-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .lg\:via-lime-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .lg\:via-lime-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .lg\:via-lime {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .lg\:via-lime-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .lg\:via-lime-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .lg\:via-lime-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .lg\:via-lime-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .lg\:via-amber-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .lg\:via-amber-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .lg\:via-amber-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .lg\:via-amber-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .lg\:via-amber-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .lg\:via-amber-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .lg\:via-amber-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .lg\:via-amber-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .lg\:via-amber-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .lg\:via-amber-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .lg\:via-amber {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .lg\:via-amber-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .lg\:via-amber-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .lg\:via-amber-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .lg\:via-amber-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .lg\:via-brown-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .lg\:via-brown-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .lg\:via-brown-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .lg\:via-brown-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .lg\:via-brown-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .lg\:via-brown-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .lg\:via-brown-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .lg\:via-brown-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .lg\:via-brown-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .lg\:via-brown-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .lg\:via-brown {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .lg\:via-brown-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .lg\:via-brown-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .lg\:via-brown-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .lg\:via-brown-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .lg\:via-blue-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .lg\:via-blue-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .lg\:via-blue-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .lg\:via-blue-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .lg\:via-blue-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .lg\:via-blue-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .lg\:via-blue-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .lg\:via-blue-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .lg\:via-blue-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .lg\:via-blue-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .lg\:via-blue-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .lg\:via-blue-gray-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .lg\:via-blue-gray-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .lg\:via-blue-gray-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .lg\:via-blue-gray-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .lg\:via-cyan-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .lg\:via-cyan-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .lg\:via-cyan-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .lg\:via-cyan-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .lg\:via-cyan-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .lg\:via-cyan-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .lg\:via-cyan-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .lg\:via-cyan-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .lg\:via-cyan-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .lg\:via-cyan-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .lg\:via-cyan {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .lg\:via-cyan-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .lg\:via-cyan-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .lg\:via-cyan-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .lg\:via-cyan-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .lg\:to-transparent {
    --tw-gradient-to: transparent
  }

  .lg\:to-current {
    --tw-gradient-to: currentColor
  }

  .lg\:to-black {
    --tw-gradient-to: #22292F
  }

  .lg\:to-white {
    --tw-gradient-to: #fff
  }

  .lg\:to-grey-50 {
    --tw-gradient-to: #FAFAFA
  }

  .lg\:to-grey-100 {
    --tw-gradient-to: #F5F5F5
  }

  .lg\:to-grey-200 {
    --tw-gradient-to: #EEEEEE
  }

  .lg\:to-grey-300 {
    --tw-gradient-to: #E0E0E0
  }

  .lg\:to-grey-400 {
    --tw-gradient-to: #BDBDBD
  }

  .lg\:to-grey-500 {
    --tw-gradient-to: #9E9E9E
  }

  .lg\:to-grey-600 {
    --tw-gradient-to: #757575
  }

  .lg\:to-grey-700 {
    --tw-gradient-to: #616161
  }

  .lg\:to-grey-800 {
    --tw-gradient-to: #424242
  }

  .lg\:to-grey-900 {
    --tw-gradient-to: #212121
  }

  .lg\:to-grey {
    --tw-gradient-to: #9E9E9E
  }

  .lg\:to-grey-A100 {
    --tw-gradient-to: #D5D5D5
  }

  .lg\:to-grey-A200 {
    --tw-gradient-to: #AAAAAA
  }

  .lg\:to-grey-A400 {
    --tw-gradient-to: #303030
  }

  .lg\:to-grey-A700 {
    --tw-gradient-to: #616161
  }

  .lg\:to-gray-50 {
    --tw-gradient-to: #FAFAFA
  }

  .lg\:to-gray-100 {
    --tw-gradient-to: #F5F5F5
  }

  .lg\:to-gray-200 {
    --tw-gradient-to: #EEEEEE
  }

  .lg\:to-gray-300 {
    --tw-gradient-to: #E0E0E0
  }

  .lg\:to-gray-400 {
    --tw-gradient-to: #BDBDBD
  }

  .lg\:to-gray-500 {
    --tw-gradient-to: #9E9E9E
  }

  .lg\:to-gray-600 {
    --tw-gradient-to: #757575
  }

  .lg\:to-gray-700 {
    --tw-gradient-to: #616161
  }

  .lg\:to-gray-800 {
    --tw-gradient-to: #424242
  }

  .lg\:to-gray-900 {
    --tw-gradient-to: #212121
  }

  .lg\:to-gray {
    --tw-gradient-to: #9E9E9E
  }

  .lg\:to-gray-hover {
    --tw-gradient-to: rgba(0, 0, 0, 0.04)
  }

  .lg\:to-gray-A100 {
    --tw-gradient-to: #D5D5D5
  }

  .lg\:to-gray-A200 {
    --tw-gradient-to: #AAAAAA
  }

  .lg\:to-gray-A400 {
    --tw-gradient-to: #303030
  }

  .lg\:to-gray-A700 {
    --tw-gradient-to: #616161
  }

  .lg\:to-red-50 {
    --tw-gradient-to: #FFEBEE
  }

  .lg\:to-red-100 {
    --tw-gradient-to: #FFCDD2
  }

  .lg\:to-red-200 {
    --tw-gradient-to: #EF9A9A
  }

  .lg\:to-red-300 {
    --tw-gradient-to: #E57373
  }

  .lg\:to-red-400 {
    --tw-gradient-to: #EF5350
  }

  .lg\:to-red-500 {
    --tw-gradient-to: #F44336
  }

  .lg\:to-red-600 {
    --tw-gradient-to: #E53935
  }

  .lg\:to-red-700 {
    --tw-gradient-to: #D32F2F
  }

  .lg\:to-red-800 {
    --tw-gradient-to: #C62828
  }

  .lg\:to-red-900 {
    --tw-gradient-to: #B71C1C
  }

  .lg\:to-red {
    --tw-gradient-to: #F44336
  }

  .lg\:to-red-A100 {
    --tw-gradient-to: #FF8A80
  }

  .lg\:to-red-A200 {
    --tw-gradient-to: #FF5252
  }

  .lg\:to-red-A400 {
    --tw-gradient-to: #FF1744
  }

  .lg\:to-red-A700 {
    --tw-gradient-to: #D50000
  }

  .lg\:to-orange-50 {
    --tw-gradient-to: #FFF3E0
  }

  .lg\:to-orange-100 {
    --tw-gradient-to: #FFE0B2
  }

  .lg\:to-orange-200 {
    --tw-gradient-to: #FFCC80
  }

  .lg\:to-orange-300 {
    --tw-gradient-to: #FFB74D
  }

  .lg\:to-orange-400 {
    --tw-gradient-to: #FFA726
  }

  .lg\:to-orange-500 {
    --tw-gradient-to: #FF9800
  }

  .lg\:to-orange-600 {
    --tw-gradient-to: #FB8C00
  }

  .lg\:to-orange-700 {
    --tw-gradient-to: #F57C00
  }

  .lg\:to-orange-800 {
    --tw-gradient-to: #EF6C00
  }

  .lg\:to-orange-900 {
    --tw-gradient-to: #E65100
  }

  .lg\:to-orange {
    --tw-gradient-to: #FF9800
  }

  .lg\:to-orange-A100 {
    --tw-gradient-to: #FFD180
  }

  .lg\:to-orange-A200 {
    --tw-gradient-to: #FFAB40
  }

  .lg\:to-orange-A400 {
    --tw-gradient-to: #FF9100
  }

  .lg\:to-orange-A700 {
    --tw-gradient-to: #FF6D00
  }

  .lg\:to-deep-orange-50 {
    --tw-gradient-to: #FBE9E7
  }

  .lg\:to-deep-orange-100 {
    --tw-gradient-to: #FFCCBC
  }

  .lg\:to-deep-orange-200 {
    --tw-gradient-to: #FFAB91
  }

  .lg\:to-deep-orange-300 {
    --tw-gradient-to: #FF8A65
  }

  .lg\:to-deep-orange-400 {
    --tw-gradient-to: #FF7043
  }

  .lg\:to-deep-orange-500 {
    --tw-gradient-to: #FF5722
  }

  .lg\:to-deep-orange-600 {
    --tw-gradient-to: #F4511E
  }

  .lg\:to-deep-orange-700 {
    --tw-gradient-to: #E64A19
  }

  .lg\:to-deep-orange-800 {
    --tw-gradient-to: #D84315
  }

  .lg\:to-deep-orange-900 {
    --tw-gradient-to: #BF360C
  }

  .lg\:to-deep-orange {
    --tw-gradient-to: #FF5722
  }

  .lg\:to-deep-orange-A100 {
    --tw-gradient-to: #FF9E80
  }

  .lg\:to-deep-orange-A200 {
    --tw-gradient-to: #FF6E40
  }

  .lg\:to-deep-orange-A400 {
    --tw-gradient-to: #FF3D00
  }

  .lg\:to-deep-orange-A700 {
    --tw-gradient-to: #DD2C00
  }

  .lg\:to-yellow-50 {
    --tw-gradient-to: #FFFDE7
  }

  .lg\:to-yellow-100 {
    --tw-gradient-to: #FFF9C4
  }

  .lg\:to-yellow-200 {
    --tw-gradient-to: #FFF59D
  }

  .lg\:to-yellow-300 {
    --tw-gradient-to: #FFF176
  }

  .lg\:to-yellow-400 {
    --tw-gradient-to: #FFEE58
  }

  .lg\:to-yellow-500 {
    --tw-gradient-to: #FFEB3B
  }

  .lg\:to-yellow-600 {
    --tw-gradient-to: #FDD835
  }

  .lg\:to-yellow-700 {
    --tw-gradient-to: #FBC02D
  }

  .lg\:to-yellow-800 {
    --tw-gradient-to: #F9A825
  }

  .lg\:to-yellow-900 {
    --tw-gradient-to: #F57F17
  }

  .lg\:to-yellow {
    --tw-gradient-to: #FFEB3B
  }

  .lg\:to-yellow-A100 {
    --tw-gradient-to: #FFFF8D
  }

  .lg\:to-yellow-A200 {
    --tw-gradient-to: #FFFF00
  }

  .lg\:to-yellow-A400 {
    --tw-gradient-to: #FFEA00
  }

  .lg\:to-yellow-A700 {
    --tw-gradient-to: #FFD600
  }

  .lg\:to-green-50 {
    --tw-gradient-to: #E8F5E9
  }

  .lg\:to-green-100 {
    --tw-gradient-to: #C8E6C9
  }

  .lg\:to-green-200 {
    --tw-gradient-to: #A5D6A7
  }

  .lg\:to-green-300 {
    --tw-gradient-to: #81C784
  }

  .lg\:to-green-400 {
    --tw-gradient-to: #66BB6A
  }

  .lg\:to-green-500 {
    --tw-gradient-to: #4CAF50
  }

  .lg\:to-green-600 {
    --tw-gradient-to: #43A047
  }

  .lg\:to-green-700 {
    --tw-gradient-to: #388E3C
  }

  .lg\:to-green-800 {
    --tw-gradient-to: #2E7D32
  }

  .lg\:to-green-900 {
    --tw-gradient-to: #1B5E20
  }

  .lg\:to-green {
    --tw-gradient-to: #4CAF50
  }

  .lg\:to-green-A100 {
    --tw-gradient-to: #B9F6CA
  }

  .lg\:to-green-A200 {
    --tw-gradient-to: #69F0AE
  }

  .lg\:to-green-A400 {
    --tw-gradient-to: #00E676
  }

  .lg\:to-green-A700 {
    --tw-gradient-to: #00C853
  }

  .lg\:to-light-green-50 {
    --tw-gradient-to: #F1F8E9
  }

  .lg\:to-light-green-100 {
    --tw-gradient-to: #DCEDC8
  }

  .lg\:to-light-green-200 {
    --tw-gradient-to: #C5E1A5
  }

  .lg\:to-light-green-300 {
    --tw-gradient-to: #AED581
  }

  .lg\:to-light-green-400 {
    --tw-gradient-to: #9CCC65
  }

  .lg\:to-light-green-500 {
    --tw-gradient-to: #8BC34A
  }

  .lg\:to-light-green-600 {
    --tw-gradient-to: #7CB342
  }

  .lg\:to-light-green-700 {
    --tw-gradient-to: #689F38
  }

  .lg\:to-light-green-800 {
    --tw-gradient-to: #558B2F
  }

  .lg\:to-light-green-900 {
    --tw-gradient-to: #33691E
  }

  .lg\:to-light-green {
    --tw-gradient-to: #8BC34A
  }

  .lg\:to-light-green-A100 {
    --tw-gradient-to: #CCFF90
  }

  .lg\:to-light-green-A200 {
    --tw-gradient-to: #B2FF59
  }

  .lg\:to-light-green-A400 {
    --tw-gradient-to: #76FF03
  }

  .lg\:to-light-green-A700 {
    --tw-gradient-to: #64DD17
  }

  .lg\:to-teal-50 {
    --tw-gradient-to: #E0F2F1
  }

  .lg\:to-teal-100 {
    --tw-gradient-to: #B2DFDB
  }

  .lg\:to-teal-200 {
    --tw-gradient-to: #80CBC4
  }

  .lg\:to-teal-300 {
    --tw-gradient-to: #4DB6AC
  }

  .lg\:to-teal-400 {
    --tw-gradient-to: #26A69A
  }

  .lg\:to-teal-500 {
    --tw-gradient-to: #009688
  }

  .lg\:to-teal-600 {
    --tw-gradient-to: #00897B
  }

  .lg\:to-teal-700 {
    --tw-gradient-to: #00796B
  }

  .lg\:to-teal-800 {
    --tw-gradient-to: #00695C
  }

  .lg\:to-teal-900 {
    --tw-gradient-to: #004D40
  }

  .lg\:to-teal {
    --tw-gradient-to: #009688
  }

  .lg\:to-teal-A100 {
    --tw-gradient-to: #A7FFEB
  }

  .lg\:to-teal-A200 {
    --tw-gradient-to: #64FFDA
  }

  .lg\:to-teal-A400 {
    --tw-gradient-to: #1DE9B6
  }

  .lg\:to-teal-A700 {
    --tw-gradient-to: #00BFA5
  }

  .lg\:to-blue-50 {
    --tw-gradient-to: #E3F2FD
  }

  .lg\:to-blue-100 {
    --tw-gradient-to: #BBDEFB
  }

  .lg\:to-blue-200 {
    --tw-gradient-to: #90CAF9
  }

  .lg\:to-blue-300 {
    --tw-gradient-to: #64B5F6
  }

  .lg\:to-blue-400 {
    --tw-gradient-to: #42A5F5
  }

  .lg\:to-blue-500 {
    --tw-gradient-to: #2196F3
  }

  .lg\:to-blue-600 {
    --tw-gradient-to: #1E88E5
  }

  .lg\:to-blue-700 {
    --tw-gradient-to: #1976D2
  }

  .lg\:to-blue-800 {
    --tw-gradient-to: #1565C0
  }

  .lg\:to-blue-900 {
    --tw-gradient-to: #0D47A1
  }

  .lg\:to-blue {
    --tw-gradient-to: #2196F3
  }

  .lg\:to-blue-A100 {
    --tw-gradient-to: #82B1FF
  }

  .lg\:to-blue-A200 {
    --tw-gradient-to: #448AFF
  }

  .lg\:to-blue-A400 {
    --tw-gradient-to: #2979FF
  }

  .lg\:to-blue-A700 {
    --tw-gradient-to: #2962FF
  }

  .lg\:to-light-blue-50 {
    --tw-gradient-to: #E1F5FE
  }

  .lg\:to-light-blue-100 {
    --tw-gradient-to: #B3E5FC
  }

  .lg\:to-light-blue-200 {
    --tw-gradient-to: #81D4FA
  }

  .lg\:to-light-blue-300 {
    --tw-gradient-to: #4FC3F7
  }

  .lg\:to-light-blue-400 {
    --tw-gradient-to: #29B6F6
  }

  .lg\:to-light-blue-500 {
    --tw-gradient-to: #03A9F4
  }

  .lg\:to-light-blue-600 {
    --tw-gradient-to: #039BE5
  }

  .lg\:to-light-blue-700 {
    --tw-gradient-to: #0288D1
  }

  .lg\:to-light-blue-800 {
    --tw-gradient-to: #0277BD
  }

  .lg\:to-light-blue-900 {
    --tw-gradient-to: #01579B
  }

  .lg\:to-light-blue {
    --tw-gradient-to: #03A9F4
  }

  .lg\:to-light-blue-A100 {
    --tw-gradient-to: #80D8FF
  }

  .lg\:to-light-blue-A200 {
    --tw-gradient-to: #40C4FF
  }

  .lg\:to-light-blue-A400 {
    --tw-gradient-to: #00B0FF
  }

  .lg\:to-light-blue-A700 {
    --tw-gradient-to: #0091EA
  }

  .lg\:to-indigo-50 {
    --tw-gradient-to: #E8EAF6
  }

  .lg\:to-indigo-100 {
    --tw-gradient-to: #C5CAE9
  }

  .lg\:to-indigo-200 {
    --tw-gradient-to: #9FA8DA
  }

  .lg\:to-indigo-300 {
    --tw-gradient-to: #7986CB
  }

  .lg\:to-indigo-400 {
    --tw-gradient-to: #5C6BC0
  }

  .lg\:to-indigo-500 {
    --tw-gradient-to: #3F51B5
  }

  .lg\:to-indigo-600 {
    --tw-gradient-to: #3949AB
  }

  .lg\:to-indigo-700 {
    --tw-gradient-to: #303F9F
  }

  .lg\:to-indigo-800 {
    --tw-gradient-to: #283593
  }

  .lg\:to-indigo-900 {
    --tw-gradient-to: #1A237E
  }

  .lg\:to-indigo {
    --tw-gradient-to: #3F51B5
  }

  .lg\:to-indigo-A100 {
    --tw-gradient-to: #8C9EFF
  }

  .lg\:to-indigo-A200 {
    --tw-gradient-to: #536DFE
  }

  .lg\:to-indigo-A400 {
    --tw-gradient-to: #3D5AFE
  }

  .lg\:to-indigo-A700 {
    --tw-gradient-to: #304FFE
  }

  .lg\:to-purple-50 {
    --tw-gradient-to: #F3E5F5
  }

  .lg\:to-purple-100 {
    --tw-gradient-to: #E1BEE7
  }

  .lg\:to-purple-200 {
    --tw-gradient-to: #CE93D8
  }

  .lg\:to-purple-300 {
    --tw-gradient-to: #BA68C8
  }

  .lg\:to-purple-400 {
    --tw-gradient-to: #AB47BC
  }

  .lg\:to-purple-500 {
    --tw-gradient-to: #9C27B0
  }

  .lg\:to-purple-600 {
    --tw-gradient-to: #8E24AA
  }

  .lg\:to-purple-700 {
    --tw-gradient-to: #7B1FA2
  }

  .lg\:to-purple-800 {
    --tw-gradient-to: #6A1B9A
  }

  .lg\:to-purple-900 {
    --tw-gradient-to: #4A148C
  }

  .lg\:to-purple {
    --tw-gradient-to: #9C27B0
  }

  .lg\:to-purple-A100 {
    --tw-gradient-to: #EA80FC
  }

  .lg\:to-purple-A200 {
    --tw-gradient-to: #E040FB
  }

  .lg\:to-purple-A400 {
    --tw-gradient-to: #D500F9
  }

  .lg\:to-purple-A700 {
    --tw-gradient-to: #AA00FF
  }

  .lg\:to-deep-purple-50 {
    --tw-gradient-to: #EDE7F6
  }

  .lg\:to-deep-purple-100 {
    --tw-gradient-to: #D1C4E9
  }

  .lg\:to-deep-purple-200 {
    --tw-gradient-to: #B39DDB
  }

  .lg\:to-deep-purple-300 {
    --tw-gradient-to: #9575CD
  }

  .lg\:to-deep-purple-400 {
    --tw-gradient-to: #7E57C2
  }

  .lg\:to-deep-purple-500 {
    --tw-gradient-to: #673AB7
  }

  .lg\:to-deep-purple-600 {
    --tw-gradient-to: #5E35B1
  }

  .lg\:to-deep-purple-700 {
    --tw-gradient-to: #512DA8
  }

  .lg\:to-deep-purple-800 {
    --tw-gradient-to: #4527A0
  }

  .lg\:to-deep-purple-900 {
    --tw-gradient-to: #311B92
  }

  .lg\:to-deep-purple {
    --tw-gradient-to: #673AB7
  }

  .lg\:to-deep-purple-A100 {
    --tw-gradient-to: #B388FF
  }

  .lg\:to-deep-purple-A200 {
    --tw-gradient-to: #7C4DFF
  }

  .lg\:to-deep-purple-A400 {
    --tw-gradient-to: #651FFF
  }

  .lg\:to-deep-purple-A700 {
    --tw-gradient-to: #6200EA
  }

  .lg\:to-pink-50 {
    --tw-gradient-to: #FCE4EC
  }

  .lg\:to-pink-100 {
    --tw-gradient-to: #F8BBD0
  }

  .lg\:to-pink-200 {
    --tw-gradient-to: #F48FB1
  }

  .lg\:to-pink-300 {
    --tw-gradient-to: #F06292
  }

  .lg\:to-pink-400 {
    --tw-gradient-to: #EC407A
  }

  .lg\:to-pink-500 {
    --tw-gradient-to: #E91E63
  }

  .lg\:to-pink-600 {
    --tw-gradient-to: #D81B60
  }

  .lg\:to-pink-700 {
    --tw-gradient-to: #C2185B
  }

  .lg\:to-pink-800 {
    --tw-gradient-to: #AD1457
  }

  .lg\:to-pink-900 {
    --tw-gradient-to: #880E4F
  }

  .lg\:to-pink {
    --tw-gradient-to: #E91E63
  }

  .lg\:to-pink-A100 {
    --tw-gradient-to: #FF80AB
  }

  .lg\:to-pink-A200 {
    --tw-gradient-to: #FF4081
  }

  .lg\:to-pink-A400 {
    --tw-gradient-to: #F50057
  }

  .lg\:to-pink-A700 {
    --tw-gradient-to: #C51162
  }

  .lg\:to-lime-50 {
    --tw-gradient-to: #F9FBE7
  }

  .lg\:to-lime-100 {
    --tw-gradient-to: #F0F4C3
  }

  .lg\:to-lime-200 {
    --tw-gradient-to: #E6EE9C
  }

  .lg\:to-lime-300 {
    --tw-gradient-to: #DCE775
  }

  .lg\:to-lime-400 {
    --tw-gradient-to: #D4E157
  }

  .lg\:to-lime-500 {
    --tw-gradient-to: #CDDC39
  }

  .lg\:to-lime-600 {
    --tw-gradient-to: #C0CA33
  }

  .lg\:to-lime-700 {
    --tw-gradient-to: #AFB42B
  }

  .lg\:to-lime-800 {
    --tw-gradient-to: #9E9D24
  }

  .lg\:to-lime-900 {
    --tw-gradient-to: #827717
  }

  .lg\:to-lime {
    --tw-gradient-to: #CDDC39
  }

  .lg\:to-lime-A100 {
    --tw-gradient-to: #F4FF81
  }

  .lg\:to-lime-A200 {
    --tw-gradient-to: #EEFF41
  }

  .lg\:to-lime-A400 {
    --tw-gradient-to: #C6FF00
  }

  .lg\:to-lime-A700 {
    --tw-gradient-to: #AEEA00
  }

  .lg\:to-amber-50 {
    --tw-gradient-to: #FFF8E1
  }

  .lg\:to-amber-100 {
    --tw-gradient-to: #FFECB3
  }

  .lg\:to-amber-200 {
    --tw-gradient-to: #FFE082
  }

  .lg\:to-amber-300 {
    --tw-gradient-to: #FFD54F
  }

  .lg\:to-amber-400 {
    --tw-gradient-to: #FFCA28
  }

  .lg\:to-amber-500 {
    --tw-gradient-to: #FFC107
  }

  .lg\:to-amber-600 {
    --tw-gradient-to: #FFB300
  }

  .lg\:to-amber-700 {
    --tw-gradient-to: #FFA000
  }

  .lg\:to-amber-800 {
    --tw-gradient-to: #FF8F00
  }

  .lg\:to-amber-900 {
    --tw-gradient-to: #FF6F00
  }

  .lg\:to-amber {
    --tw-gradient-to: #FFC107
  }

  .lg\:to-amber-A100 {
    --tw-gradient-to: #FFE57F
  }

  .lg\:to-amber-A200 {
    --tw-gradient-to: #FFD740
  }

  .lg\:to-amber-A400 {
    --tw-gradient-to: #FFC400
  }

  .lg\:to-amber-A700 {
    --tw-gradient-to: #FFAB00
  }

  .lg\:to-brown-50 {
    --tw-gradient-to: #EFEBE9
  }

  .lg\:to-brown-100 {
    --tw-gradient-to: #D7CCC8
  }

  .lg\:to-brown-200 {
    --tw-gradient-to: #BCAAA4
  }

  .lg\:to-brown-300 {
    --tw-gradient-to: #A1887F
  }

  .lg\:to-brown-400 {
    --tw-gradient-to: #8D6E63
  }

  .lg\:to-brown-500 {
    --tw-gradient-to: #795548
  }

  .lg\:to-brown-600 {
    --tw-gradient-to: #6D4C41
  }

  .lg\:to-brown-700 {
    --tw-gradient-to: #5D4037
  }

  .lg\:to-brown-800 {
    --tw-gradient-to: #4E342E
  }

  .lg\:to-brown-900 {
    --tw-gradient-to: #3E2723
  }

  .lg\:to-brown {
    --tw-gradient-to: #795548
  }

  .lg\:to-brown-A100 {
    --tw-gradient-to: #D7CCC8
  }

  .lg\:to-brown-A200 {
    --tw-gradient-to: #BCAAA4
  }

  .lg\:to-brown-A400 {
    --tw-gradient-to: #8D6E63
  }

  .lg\:to-brown-A700 {
    --tw-gradient-to: #5D4037
  }

  .lg\:to-blue-gray-50 {
    --tw-gradient-to: #ECEFF1
  }

  .lg\:to-blue-gray-100 {
    --tw-gradient-to: #CFD8DC
  }

  .lg\:to-blue-gray-200 {
    --tw-gradient-to: #B0BEC5
  }

  .lg\:to-blue-gray-300 {
    --tw-gradient-to: #90A4AE
  }

  .lg\:to-blue-gray-400 {
    --tw-gradient-to: #78909C
  }

  .lg\:to-blue-gray-500 {
    --tw-gradient-to: #607D8B
  }

  .lg\:to-blue-gray-600 {
    --tw-gradient-to: #546E7A
  }

  .lg\:to-blue-gray-700 {
    --tw-gradient-to: #455A64
  }

  .lg\:to-blue-gray-800 {
    --tw-gradient-to: #37474F
  }

  .lg\:to-blue-gray-900 {
    --tw-gradient-to: #263238
  }

  .lg\:to-blue-gray {
    --tw-gradient-to: #607D8B
  }

  .lg\:to-blue-gray-A100 {
    --tw-gradient-to: #CFD8DC
  }

  .lg\:to-blue-gray-A200 {
    --tw-gradient-to: #B0BEC5
  }

  .lg\:to-blue-gray-A400 {
    --tw-gradient-to: #78909C
  }

  .lg\:to-blue-gray-A700 {
    --tw-gradient-to: #455A64
  }

  .lg\:to-cyan-50 {
    --tw-gradient-to: #E0F7FA
  }

  .lg\:to-cyan-100 {
    --tw-gradient-to: #B2EBF2
  }

  .lg\:to-cyan-200 {
    --tw-gradient-to: #80DEEA
  }

  .lg\:to-cyan-300 {
    --tw-gradient-to: #4DD0E1
  }

  .lg\:to-cyan-400 {
    --tw-gradient-to: #26C6DA
  }

  .lg\:to-cyan-500 {
    --tw-gradient-to: #00BCD4
  }

  .lg\:to-cyan-600 {
    --tw-gradient-to: #00ACC1
  }

  .lg\:to-cyan-700 {
    --tw-gradient-to: #0097A7
  }

  .lg\:to-cyan-800 {
    --tw-gradient-to: #00838F
  }

  .lg\:to-cyan-900 {
    --tw-gradient-to: #006064
  }

  .lg\:to-cyan {
    --tw-gradient-to: #00BCD4
  }

  .lg\:to-cyan-A100 {
    --tw-gradient-to: #84FFFF
  }

  .lg\:to-cyan-A200 {
    --tw-gradient-to: #18FFFF
  }

  .lg\:to-cyan-A400 {
    --tw-gradient-to: #00E5FF
  }

  .lg\:to-cyan-A700 {
    --tw-gradient-to: #00B8D4
  }

  .lg\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-black:hover {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .lg\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-grey-50:hover {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .lg\:hover\:from-grey-100:hover {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .lg\:hover\:from-grey-200:hover {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .lg\:hover\:from-grey-300:hover {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .lg\:hover\:from-grey-400:hover {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .lg\:hover\:from-grey-500:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:hover\:from-grey-600:hover {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .lg\:hover\:from-grey-700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:hover\:from-grey-800:hover {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .lg\:hover\:from-grey-900:hover {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .lg\:hover\:from-grey:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:hover\:from-grey-A100:hover {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .lg\:hover\:from-grey-A200:hover {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .lg\:hover\:from-grey-A400:hover {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .lg\:hover\:from-grey-A700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:hover\:from-gray-50:hover {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .lg\:hover\:from-gray-100:hover {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .lg\:hover\:from-gray-200:hover {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .lg\:hover\:from-gray-300:hover {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .lg\:hover\:from-gray-400:hover {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .lg\:hover\:from-gray-500:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:hover\:from-gray-600:hover {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .lg\:hover\:from-gray-700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:hover\:from-gray-800:hover {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .lg\:hover\:from-gray-900:hover {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .lg\:hover\:from-gray:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:hover\:from-gray-hover:hover {
    --tw-gradient-from: rgba(0, 0, 0, 0.04);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:from-gray-A100:hover {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .lg\:hover\:from-gray-A200:hover {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .lg\:hover\:from-gray-A400:hover {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .lg\:hover\:from-gray-A700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:hover\:from-red-50:hover {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .lg\:hover\:from-red-100:hover {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .lg\:hover\:from-red-200:hover {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .lg\:hover\:from-red-300:hover {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .lg\:hover\:from-red-400:hover {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .lg\:hover\:from-red-500:hover {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .lg\:hover\:from-red-600:hover {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .lg\:hover\:from-red-700:hover {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .lg\:hover\:from-red-800:hover {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .lg\:hover\:from-red-900:hover {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .lg\:hover\:from-red:hover {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .lg\:hover\:from-red-A100:hover {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .lg\:hover\:from-red-A200:hover {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .lg\:hover\:from-red-A400:hover {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .lg\:hover\:from-red-A700:hover {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .lg\:hover\:from-orange-50:hover {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .lg\:hover\:from-orange-100:hover {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .lg\:hover\:from-orange-200:hover {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .lg\:hover\:from-orange-300:hover {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .lg\:hover\:from-orange-400:hover {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .lg\:hover\:from-orange-500:hover {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .lg\:hover\:from-orange-600:hover {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .lg\:hover\:from-orange-700:hover {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .lg\:hover\:from-orange-800:hover {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .lg\:hover\:from-orange-900:hover {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .lg\:hover\:from-orange:hover {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .lg\:hover\:from-orange-A100:hover {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .lg\:hover\:from-orange-A200:hover {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .lg\:hover\:from-orange-A400:hover {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .lg\:hover\:from-orange-A700:hover {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .lg\:hover\:from-deep-orange-50:hover {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .lg\:hover\:from-deep-orange-100:hover {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .lg\:hover\:from-deep-orange-200:hover {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .lg\:hover\:from-deep-orange-300:hover {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .lg\:hover\:from-deep-orange-400:hover {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .lg\:hover\:from-deep-orange-500:hover {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .lg\:hover\:from-deep-orange-600:hover {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .lg\:hover\:from-deep-orange-700:hover {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .lg\:hover\:from-deep-orange-800:hover {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .lg\:hover\:from-deep-orange-900:hover {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .lg\:hover\:from-deep-orange:hover {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .lg\:hover\:from-deep-orange-A100:hover {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .lg\:hover\:from-deep-orange-A200:hover {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .lg\:hover\:from-deep-orange-A400:hover {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .lg\:hover\:from-deep-orange-A700:hover {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .lg\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .lg\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .lg\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .lg\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .lg\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .lg\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .lg\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .lg\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .lg\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .lg\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .lg\:hover\:from-yellow:hover {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .lg\:hover\:from-yellow-A100:hover {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .lg\:hover\:from-yellow-A200:hover {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .lg\:hover\:from-yellow-A400:hover {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .lg\:hover\:from-yellow-A700:hover {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .lg\:hover\:from-green-50:hover {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .lg\:hover\:from-green-100:hover {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .lg\:hover\:from-green-200:hover {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .lg\:hover\:from-green-300:hover {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .lg\:hover\:from-green-400:hover {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .lg\:hover\:from-green-500:hover {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .lg\:hover\:from-green-600:hover {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .lg\:hover\:from-green-700:hover {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .lg\:hover\:from-green-800:hover {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .lg\:hover\:from-green-900:hover {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .lg\:hover\:from-green:hover {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .lg\:hover\:from-green-A100:hover {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .lg\:hover\:from-green-A200:hover {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .lg\:hover\:from-green-A400:hover {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .lg\:hover\:from-green-A700:hover {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .lg\:hover\:from-light-green-50:hover {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .lg\:hover\:from-light-green-100:hover {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .lg\:hover\:from-light-green-200:hover {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .lg\:hover\:from-light-green-300:hover {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .lg\:hover\:from-light-green-400:hover {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .lg\:hover\:from-light-green-500:hover {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .lg\:hover\:from-light-green-600:hover {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .lg\:hover\:from-light-green-700:hover {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .lg\:hover\:from-light-green-800:hover {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .lg\:hover\:from-light-green-900:hover {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .lg\:hover\:from-light-green:hover {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .lg\:hover\:from-light-green-A100:hover {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .lg\:hover\:from-light-green-A200:hover {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .lg\:hover\:from-light-green-A400:hover {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .lg\:hover\:from-light-green-A700:hover {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .lg\:hover\:from-teal-50:hover {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .lg\:hover\:from-teal-100:hover {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .lg\:hover\:from-teal-200:hover {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .lg\:hover\:from-teal-300:hover {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .lg\:hover\:from-teal-400:hover {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .lg\:hover\:from-teal-500:hover {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .lg\:hover\:from-teal-600:hover {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .lg\:hover\:from-teal-700:hover {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .lg\:hover\:from-teal-800:hover {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .lg\:hover\:from-teal-900:hover {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .lg\:hover\:from-teal:hover {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .lg\:hover\:from-teal-A100:hover {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .lg\:hover\:from-teal-A200:hover {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .lg\:hover\:from-teal-A400:hover {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .lg\:hover\:from-teal-A700:hover {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .lg\:hover\:from-blue-50:hover {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .lg\:hover\:from-blue-100:hover {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .lg\:hover\:from-blue-200:hover {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .lg\:hover\:from-blue-300:hover {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .lg\:hover\:from-blue-400:hover {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .lg\:hover\:from-blue-500:hover {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .lg\:hover\:from-blue-600:hover {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .lg\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .lg\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .lg\:hover\:from-blue-900:hover {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .lg\:hover\:from-blue:hover {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .lg\:hover\:from-blue-A100:hover {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .lg\:hover\:from-blue-A200:hover {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .lg\:hover\:from-blue-A400:hover {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .lg\:hover\:from-blue-A700:hover {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .lg\:hover\:from-light-blue-50:hover {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .lg\:hover\:from-light-blue-100:hover {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .lg\:hover\:from-light-blue-200:hover {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .lg\:hover\:from-light-blue-300:hover {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .lg\:hover\:from-light-blue-400:hover {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .lg\:hover\:from-light-blue-500:hover {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .lg\:hover\:from-light-blue-600:hover {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .lg\:hover\:from-light-blue-700:hover {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .lg\:hover\:from-light-blue-800:hover {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .lg\:hover\:from-light-blue-900:hover {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .lg\:hover\:from-light-blue:hover {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .lg\:hover\:from-light-blue-A100:hover {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .lg\:hover\:from-light-blue-A200:hover {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .lg\:hover\:from-light-blue-A400:hover {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .lg\:hover\:from-light-blue-A700:hover {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .lg\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .lg\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .lg\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .lg\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .lg\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .lg\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .lg\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .lg\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .lg\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .lg\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .lg\:hover\:from-indigo:hover {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .lg\:hover\:from-indigo-A100:hover {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .lg\:hover\:from-indigo-A200:hover {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .lg\:hover\:from-indigo-A400:hover {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .lg\:hover\:from-indigo-A700:hover {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .lg\:hover\:from-purple-50:hover {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .lg\:hover\:from-purple-100:hover {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .lg\:hover\:from-purple-200:hover {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .lg\:hover\:from-purple-300:hover {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .lg\:hover\:from-purple-400:hover {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .lg\:hover\:from-purple-500:hover {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .lg\:hover\:from-purple-600:hover {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .lg\:hover\:from-purple-700:hover {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .lg\:hover\:from-purple-800:hover {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .lg\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .lg\:hover\:from-purple:hover {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .lg\:hover\:from-purple-A100:hover {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .lg\:hover\:from-purple-A200:hover {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .lg\:hover\:from-purple-A400:hover {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .lg\:hover\:from-purple-A700:hover {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .lg\:hover\:from-deep-purple-50:hover {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .lg\:hover\:from-deep-purple-100:hover {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .lg\:hover\:from-deep-purple-200:hover {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .lg\:hover\:from-deep-purple-300:hover {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .lg\:hover\:from-deep-purple-400:hover {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .lg\:hover\:from-deep-purple-500:hover {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .lg\:hover\:from-deep-purple-600:hover {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .lg\:hover\:from-deep-purple-700:hover {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .lg\:hover\:from-deep-purple-800:hover {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .lg\:hover\:from-deep-purple-900:hover {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .lg\:hover\:from-deep-purple:hover {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .lg\:hover\:from-deep-purple-A100:hover {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .lg\:hover\:from-deep-purple-A200:hover {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .lg\:hover\:from-deep-purple-A400:hover {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .lg\:hover\:from-deep-purple-A700:hover {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .lg\:hover\:from-pink-50:hover {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .lg\:hover\:from-pink-100:hover {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .lg\:hover\:from-pink-200:hover {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .lg\:hover\:from-pink-300:hover {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .lg\:hover\:from-pink-400:hover {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .lg\:hover\:from-pink-500:hover {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .lg\:hover\:from-pink-600:hover {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .lg\:hover\:from-pink-700:hover {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .lg\:hover\:from-pink-800:hover {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .lg\:hover\:from-pink-900:hover {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .lg\:hover\:from-pink:hover {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .lg\:hover\:from-pink-A100:hover {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .lg\:hover\:from-pink-A200:hover {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .lg\:hover\:from-pink-A400:hover {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .lg\:hover\:from-pink-A700:hover {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .lg\:hover\:from-lime-50:hover {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .lg\:hover\:from-lime-100:hover {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .lg\:hover\:from-lime-200:hover {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .lg\:hover\:from-lime-300:hover {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .lg\:hover\:from-lime-400:hover {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .lg\:hover\:from-lime-500:hover {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .lg\:hover\:from-lime-600:hover {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .lg\:hover\:from-lime-700:hover {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .lg\:hover\:from-lime-800:hover {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .lg\:hover\:from-lime-900:hover {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .lg\:hover\:from-lime:hover {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .lg\:hover\:from-lime-A100:hover {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .lg\:hover\:from-lime-A200:hover {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .lg\:hover\:from-lime-A400:hover {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .lg\:hover\:from-lime-A700:hover {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .lg\:hover\:from-amber-50:hover {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .lg\:hover\:from-amber-100:hover {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .lg\:hover\:from-amber-200:hover {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .lg\:hover\:from-amber-300:hover {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .lg\:hover\:from-amber-400:hover {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .lg\:hover\:from-amber-500:hover {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .lg\:hover\:from-amber-600:hover {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .lg\:hover\:from-amber-700:hover {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .lg\:hover\:from-amber-800:hover {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .lg\:hover\:from-amber-900:hover {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .lg\:hover\:from-amber:hover {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .lg\:hover\:from-amber-A100:hover {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .lg\:hover\:from-amber-A200:hover {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .lg\:hover\:from-amber-A400:hover {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .lg\:hover\:from-amber-A700:hover {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .lg\:hover\:from-brown-50:hover {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .lg\:hover\:from-brown-100:hover {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .lg\:hover\:from-brown-200:hover {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .lg\:hover\:from-brown-300:hover {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .lg\:hover\:from-brown-400:hover {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .lg\:hover\:from-brown-500:hover {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .lg\:hover\:from-brown-600:hover {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .lg\:hover\:from-brown-700:hover {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .lg\:hover\:from-brown-800:hover {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .lg\:hover\:from-brown-900:hover {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .lg\:hover\:from-brown:hover {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .lg\:hover\:from-brown-A100:hover {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .lg\:hover\:from-brown-A200:hover {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .lg\:hover\:from-brown-A400:hover {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .lg\:hover\:from-brown-A700:hover {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .lg\:hover\:from-blue-gray-50:hover {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .lg\:hover\:from-blue-gray-100:hover {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .lg\:hover\:from-blue-gray-200:hover {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .lg\:hover\:from-blue-gray-300:hover {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .lg\:hover\:from-blue-gray-400:hover {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .lg\:hover\:from-blue-gray-500:hover {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .lg\:hover\:from-blue-gray-600:hover {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .lg\:hover\:from-blue-gray-700:hover {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .lg\:hover\:from-blue-gray-800:hover {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .lg\:hover\:from-blue-gray-900:hover {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .lg\:hover\:from-blue-gray:hover {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .lg\:hover\:from-blue-gray-A100:hover {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .lg\:hover\:from-blue-gray-A200:hover {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .lg\:hover\:from-blue-gray-A400:hover {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .lg\:hover\:from-blue-gray-A700:hover {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .lg\:hover\:from-cyan-50:hover {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .lg\:hover\:from-cyan-100:hover {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .lg\:hover\:from-cyan-200:hover {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .lg\:hover\:from-cyan-300:hover {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .lg\:hover\:from-cyan-400:hover {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .lg\:hover\:from-cyan-500:hover {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .lg\:hover\:from-cyan-600:hover {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .lg\:hover\:from-cyan-700:hover {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .lg\:hover\:from-cyan-800:hover {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .lg\:hover\:from-cyan-900:hover {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .lg\:hover\:from-cyan:hover {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .lg\:hover\:from-cyan-A100:hover {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .lg\:hover\:from-cyan-A200:hover {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .lg\:hover\:from-cyan-A400:hover {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .lg\:hover\:from-cyan-A700:hover {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .lg\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .lg\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-grey-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .lg\:hover\:via-grey-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .lg\:hover\:via-grey-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .lg\:hover\:via-grey-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .lg\:hover\:via-grey-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .lg\:hover\:via-grey-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:hover\:via-grey-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .lg\:hover\:via-grey-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:hover\:via-grey-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .lg\:hover\:via-grey-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .lg\:hover\:via-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:hover\:via-grey-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .lg\:hover\:via-grey-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .lg\:hover\:via-grey-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .lg\:hover\:via-grey-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .lg\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .lg\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .lg\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .lg\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .lg\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .lg\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .lg\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .lg\:hover\:via-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:hover\:via-gray-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:via-gray-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .lg\:hover\:via-gray-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .lg\:hover\:via-gray-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .lg\:hover\:via-gray-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .lg\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .lg\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .lg\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .lg\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .lg\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .lg\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .lg\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .lg\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .lg\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .lg\:hover\:via-red:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .lg\:hover\:via-red-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .lg\:hover\:via-red-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .lg\:hover\:via-red-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .lg\:hover\:via-red-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .lg\:hover\:via-orange-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .lg\:hover\:via-orange-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .lg\:hover\:via-orange-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .lg\:hover\:via-orange-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .lg\:hover\:via-orange-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .lg\:hover\:via-orange-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .lg\:hover\:via-orange-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .lg\:hover\:via-orange-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .lg\:hover\:via-orange-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .lg\:hover\:via-orange-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .lg\:hover\:via-orange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .lg\:hover\:via-orange-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .lg\:hover\:via-orange-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .lg\:hover\:via-orange-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .lg\:hover\:via-orange-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .lg\:hover\:via-deep-orange-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .lg\:hover\:via-deep-orange-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .lg\:hover\:via-deep-orange-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .lg\:hover\:via-deep-orange-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .lg\:hover\:via-deep-orange-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .lg\:hover\:via-deep-orange-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .lg\:hover\:via-deep-orange-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .lg\:hover\:via-deep-orange-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .lg\:hover\:via-deep-orange-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .lg\:hover\:via-deep-orange-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .lg\:hover\:via-deep-orange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .lg\:hover\:via-deep-orange-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .lg\:hover\:via-deep-orange-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .lg\:hover\:via-deep-orange-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .lg\:hover\:via-deep-orange-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .lg\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .lg\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .lg\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .lg\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .lg\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .lg\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .lg\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .lg\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .lg\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .lg\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .lg\:hover\:via-yellow:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .lg\:hover\:via-yellow-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .lg\:hover\:via-yellow-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .lg\:hover\:via-yellow-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .lg\:hover\:via-yellow-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .lg\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .lg\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .lg\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .lg\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .lg\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .lg\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .lg\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .lg\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .lg\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .lg\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .lg\:hover\:via-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .lg\:hover\:via-green-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .lg\:hover\:via-green-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .lg\:hover\:via-green-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .lg\:hover\:via-green-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .lg\:hover\:via-light-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .lg\:hover\:via-light-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .lg\:hover\:via-light-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .lg\:hover\:via-light-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .lg\:hover\:via-light-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .lg\:hover\:via-light-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .lg\:hover\:via-light-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .lg\:hover\:via-light-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .lg\:hover\:via-light-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .lg\:hover\:via-light-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .lg\:hover\:via-light-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .lg\:hover\:via-light-green-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .lg\:hover\:via-light-green-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .lg\:hover\:via-light-green-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .lg\:hover\:via-light-green-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .lg\:hover\:via-teal-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .lg\:hover\:via-teal-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .lg\:hover\:via-teal-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .lg\:hover\:via-teal-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .lg\:hover\:via-teal-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .lg\:hover\:via-teal-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .lg\:hover\:via-teal-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .lg\:hover\:via-teal-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .lg\:hover\:via-teal-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .lg\:hover\:via-teal-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .lg\:hover\:via-teal:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .lg\:hover\:via-teal-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .lg\:hover\:via-teal-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .lg\:hover\:via-teal-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .lg\:hover\:via-teal-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .lg\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .lg\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .lg\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .lg\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .lg\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .lg\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .lg\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .lg\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .lg\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .lg\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .lg\:hover\:via-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .lg\:hover\:via-blue-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .lg\:hover\:via-blue-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .lg\:hover\:via-blue-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .lg\:hover\:via-blue-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .lg\:hover\:via-light-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .lg\:hover\:via-light-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .lg\:hover\:via-light-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .lg\:hover\:via-light-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .lg\:hover\:via-light-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .lg\:hover\:via-light-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .lg\:hover\:via-light-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .lg\:hover\:via-light-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .lg\:hover\:via-light-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .lg\:hover\:via-light-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .lg\:hover\:via-light-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .lg\:hover\:via-light-blue-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .lg\:hover\:via-light-blue-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .lg\:hover\:via-light-blue-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .lg\:hover\:via-light-blue-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .lg\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .lg\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .lg\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .lg\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .lg\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .lg\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .lg\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .lg\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .lg\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .lg\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .lg\:hover\:via-indigo:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .lg\:hover\:via-indigo-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .lg\:hover\:via-indigo-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .lg\:hover\:via-indigo-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .lg\:hover\:via-indigo-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .lg\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .lg\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .lg\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .lg\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .lg\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .lg\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .lg\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .lg\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .lg\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .lg\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .lg\:hover\:via-purple:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .lg\:hover\:via-purple-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .lg\:hover\:via-purple-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .lg\:hover\:via-purple-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .lg\:hover\:via-purple-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .lg\:hover\:via-deep-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .lg\:hover\:via-deep-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .lg\:hover\:via-deep-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .lg\:hover\:via-deep-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .lg\:hover\:via-deep-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .lg\:hover\:via-deep-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .lg\:hover\:via-deep-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .lg\:hover\:via-deep-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .lg\:hover\:via-deep-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .lg\:hover\:via-deep-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .lg\:hover\:via-deep-purple:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .lg\:hover\:via-deep-purple-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .lg\:hover\:via-deep-purple-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .lg\:hover\:via-deep-purple-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .lg\:hover\:via-deep-purple-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .lg\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .lg\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .lg\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .lg\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .lg\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .lg\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .lg\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .lg\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .lg\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .lg\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .lg\:hover\:via-pink:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .lg\:hover\:via-pink-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .lg\:hover\:via-pink-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .lg\:hover\:via-pink-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .lg\:hover\:via-pink-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .lg\:hover\:via-lime-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .lg\:hover\:via-lime-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .lg\:hover\:via-lime-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .lg\:hover\:via-lime-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .lg\:hover\:via-lime-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .lg\:hover\:via-lime-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .lg\:hover\:via-lime-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .lg\:hover\:via-lime-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .lg\:hover\:via-lime-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .lg\:hover\:via-lime-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .lg\:hover\:via-lime:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .lg\:hover\:via-lime-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .lg\:hover\:via-lime-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .lg\:hover\:via-lime-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .lg\:hover\:via-lime-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .lg\:hover\:via-amber-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .lg\:hover\:via-amber-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .lg\:hover\:via-amber-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .lg\:hover\:via-amber-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .lg\:hover\:via-amber-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .lg\:hover\:via-amber-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .lg\:hover\:via-amber-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .lg\:hover\:via-amber-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .lg\:hover\:via-amber-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .lg\:hover\:via-amber-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .lg\:hover\:via-amber:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .lg\:hover\:via-amber-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .lg\:hover\:via-amber-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .lg\:hover\:via-amber-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .lg\:hover\:via-amber-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .lg\:hover\:via-brown-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .lg\:hover\:via-brown-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .lg\:hover\:via-brown-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .lg\:hover\:via-brown-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .lg\:hover\:via-brown-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .lg\:hover\:via-brown-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .lg\:hover\:via-brown-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .lg\:hover\:via-brown-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .lg\:hover\:via-brown-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .lg\:hover\:via-brown-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .lg\:hover\:via-brown:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .lg\:hover\:via-brown-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .lg\:hover\:via-brown-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .lg\:hover\:via-brown-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .lg\:hover\:via-brown-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .lg\:hover\:via-blue-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .lg\:hover\:via-blue-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .lg\:hover\:via-blue-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .lg\:hover\:via-blue-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .lg\:hover\:via-blue-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .lg\:hover\:via-blue-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .lg\:hover\:via-blue-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .lg\:hover\:via-blue-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .lg\:hover\:via-blue-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .lg\:hover\:via-blue-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .lg\:hover\:via-blue-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .lg\:hover\:via-blue-gray-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .lg\:hover\:via-blue-gray-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .lg\:hover\:via-blue-gray-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .lg\:hover\:via-blue-gray-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .lg\:hover\:via-cyan-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .lg\:hover\:via-cyan-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .lg\:hover\:via-cyan-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .lg\:hover\:via-cyan-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .lg\:hover\:via-cyan-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .lg\:hover\:via-cyan-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .lg\:hover\:via-cyan-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .lg\:hover\:via-cyan-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .lg\:hover\:via-cyan-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .lg\:hover\:via-cyan-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .lg\:hover\:via-cyan:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .lg\:hover\:via-cyan-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .lg\:hover\:via-cyan-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .lg\:hover\:via-cyan-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .lg\:hover\:via-cyan-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .lg\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent
  }

  .lg\:hover\:to-current:hover {
    --tw-gradient-to: currentColor
  }

  .lg\:hover\:to-black:hover {
    --tw-gradient-to: #22292F
  }

  .lg\:hover\:to-white:hover {
    --tw-gradient-to: #fff
  }

  .lg\:hover\:to-grey-50:hover {
    --tw-gradient-to: #FAFAFA
  }

  .lg\:hover\:to-grey-100:hover {
    --tw-gradient-to: #F5F5F5
  }

  .lg\:hover\:to-grey-200:hover {
    --tw-gradient-to: #EEEEEE
  }

  .lg\:hover\:to-grey-300:hover {
    --tw-gradient-to: #E0E0E0
  }

  .lg\:hover\:to-grey-400:hover {
    --tw-gradient-to: #BDBDBD
  }

  .lg\:hover\:to-grey-500:hover {
    --tw-gradient-to: #9E9E9E
  }

  .lg\:hover\:to-grey-600:hover {
    --tw-gradient-to: #757575
  }

  .lg\:hover\:to-grey-700:hover {
    --tw-gradient-to: #616161
  }

  .lg\:hover\:to-grey-800:hover {
    --tw-gradient-to: #424242
  }

  .lg\:hover\:to-grey-900:hover {
    --tw-gradient-to: #212121
  }

  .lg\:hover\:to-grey:hover {
    --tw-gradient-to: #9E9E9E
  }

  .lg\:hover\:to-grey-A100:hover {
    --tw-gradient-to: #D5D5D5
  }

  .lg\:hover\:to-grey-A200:hover {
    --tw-gradient-to: #AAAAAA
  }

  .lg\:hover\:to-grey-A400:hover {
    --tw-gradient-to: #303030
  }

  .lg\:hover\:to-grey-A700:hover {
    --tw-gradient-to: #616161
  }

  .lg\:hover\:to-gray-50:hover {
    --tw-gradient-to: #FAFAFA
  }

  .lg\:hover\:to-gray-100:hover {
    --tw-gradient-to: #F5F5F5
  }

  .lg\:hover\:to-gray-200:hover {
    --tw-gradient-to: #EEEEEE
  }

  .lg\:hover\:to-gray-300:hover {
    --tw-gradient-to: #E0E0E0
  }

  .lg\:hover\:to-gray-400:hover {
    --tw-gradient-to: #BDBDBD
  }

  .lg\:hover\:to-gray-500:hover {
    --tw-gradient-to: #9E9E9E
  }

  .lg\:hover\:to-gray-600:hover {
    --tw-gradient-to: #757575
  }

  .lg\:hover\:to-gray-700:hover {
    --tw-gradient-to: #616161
  }

  .lg\:hover\:to-gray-800:hover {
    --tw-gradient-to: #424242
  }

  .lg\:hover\:to-gray-900:hover {
    --tw-gradient-to: #212121
  }

  .lg\:hover\:to-gray:hover {
    --tw-gradient-to: #9E9E9E
  }

  .lg\:hover\:to-gray-hover:hover {
    --tw-gradient-to: rgba(0, 0, 0, 0.04)
  }

  .lg\:hover\:to-gray-A100:hover {
    --tw-gradient-to: #D5D5D5
  }

  .lg\:hover\:to-gray-A200:hover {
    --tw-gradient-to: #AAAAAA
  }

  .lg\:hover\:to-gray-A400:hover {
    --tw-gradient-to: #303030
  }

  .lg\:hover\:to-gray-A700:hover {
    --tw-gradient-to: #616161
  }

  .lg\:hover\:to-red-50:hover {
    --tw-gradient-to: #FFEBEE
  }

  .lg\:hover\:to-red-100:hover {
    --tw-gradient-to: #FFCDD2
  }

  .lg\:hover\:to-red-200:hover {
    --tw-gradient-to: #EF9A9A
  }

  .lg\:hover\:to-red-300:hover {
    --tw-gradient-to: #E57373
  }

  .lg\:hover\:to-red-400:hover {
    --tw-gradient-to: #EF5350
  }

  .lg\:hover\:to-red-500:hover {
    --tw-gradient-to: #F44336
  }

  .lg\:hover\:to-red-600:hover {
    --tw-gradient-to: #E53935
  }

  .lg\:hover\:to-red-700:hover {
    --tw-gradient-to: #D32F2F
  }

  .lg\:hover\:to-red-800:hover {
    --tw-gradient-to: #C62828
  }

  .lg\:hover\:to-red-900:hover {
    --tw-gradient-to: #B71C1C
  }

  .lg\:hover\:to-red:hover {
    --tw-gradient-to: #F44336
  }

  .lg\:hover\:to-red-A100:hover {
    --tw-gradient-to: #FF8A80
  }

  .lg\:hover\:to-red-A200:hover {
    --tw-gradient-to: #FF5252
  }

  .lg\:hover\:to-red-A400:hover {
    --tw-gradient-to: #FF1744
  }

  .lg\:hover\:to-red-A700:hover {
    --tw-gradient-to: #D50000
  }

  .lg\:hover\:to-orange-50:hover {
    --tw-gradient-to: #FFF3E0
  }

  .lg\:hover\:to-orange-100:hover {
    --tw-gradient-to: #FFE0B2
  }

  .lg\:hover\:to-orange-200:hover {
    --tw-gradient-to: #FFCC80
  }

  .lg\:hover\:to-orange-300:hover {
    --tw-gradient-to: #FFB74D
  }

  .lg\:hover\:to-orange-400:hover {
    --tw-gradient-to: #FFA726
  }

  .lg\:hover\:to-orange-500:hover {
    --tw-gradient-to: #FF9800
  }

  .lg\:hover\:to-orange-600:hover {
    --tw-gradient-to: #FB8C00
  }

  .lg\:hover\:to-orange-700:hover {
    --tw-gradient-to: #F57C00
  }

  .lg\:hover\:to-orange-800:hover {
    --tw-gradient-to: #EF6C00
  }

  .lg\:hover\:to-orange-900:hover {
    --tw-gradient-to: #E65100
  }

  .lg\:hover\:to-orange:hover {
    --tw-gradient-to: #FF9800
  }

  .lg\:hover\:to-orange-A100:hover {
    --tw-gradient-to: #FFD180
  }

  .lg\:hover\:to-orange-A200:hover {
    --tw-gradient-to: #FFAB40
  }

  .lg\:hover\:to-orange-A400:hover {
    --tw-gradient-to: #FF9100
  }

  .lg\:hover\:to-orange-A700:hover {
    --tw-gradient-to: #FF6D00
  }

  .lg\:hover\:to-deep-orange-50:hover {
    --tw-gradient-to: #FBE9E7
  }

  .lg\:hover\:to-deep-orange-100:hover {
    --tw-gradient-to: #FFCCBC
  }

  .lg\:hover\:to-deep-orange-200:hover {
    --tw-gradient-to: #FFAB91
  }

  .lg\:hover\:to-deep-orange-300:hover {
    --tw-gradient-to: #FF8A65
  }

  .lg\:hover\:to-deep-orange-400:hover {
    --tw-gradient-to: #FF7043
  }

  .lg\:hover\:to-deep-orange-500:hover {
    --tw-gradient-to: #FF5722
  }

  .lg\:hover\:to-deep-orange-600:hover {
    --tw-gradient-to: #F4511E
  }

  .lg\:hover\:to-deep-orange-700:hover {
    --tw-gradient-to: #E64A19
  }

  .lg\:hover\:to-deep-orange-800:hover {
    --tw-gradient-to: #D84315
  }

  .lg\:hover\:to-deep-orange-900:hover {
    --tw-gradient-to: #BF360C
  }

  .lg\:hover\:to-deep-orange:hover {
    --tw-gradient-to: #FF5722
  }

  .lg\:hover\:to-deep-orange-A100:hover {
    --tw-gradient-to: #FF9E80
  }

  .lg\:hover\:to-deep-orange-A200:hover {
    --tw-gradient-to: #FF6E40
  }

  .lg\:hover\:to-deep-orange-A400:hover {
    --tw-gradient-to: #FF3D00
  }

  .lg\:hover\:to-deep-orange-A700:hover {
    --tw-gradient-to: #DD2C00
  }

  .lg\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #FFFDE7
  }

  .lg\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #FFF9C4
  }

  .lg\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #FFF59D
  }

  .lg\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #FFF176
  }

  .lg\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #FFEE58
  }

  .lg\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #FFEB3B
  }

  .lg\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #FDD835
  }

  .lg\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #FBC02D
  }

  .lg\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #F9A825
  }

  .lg\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #F57F17
  }

  .lg\:hover\:to-yellow:hover {
    --tw-gradient-to: #FFEB3B
  }

  .lg\:hover\:to-yellow-A100:hover {
    --tw-gradient-to: #FFFF8D
  }

  .lg\:hover\:to-yellow-A200:hover {
    --tw-gradient-to: #FFFF00
  }

  .lg\:hover\:to-yellow-A400:hover {
    --tw-gradient-to: #FFEA00
  }

  .lg\:hover\:to-yellow-A700:hover {
    --tw-gradient-to: #FFD600
  }

  .lg\:hover\:to-green-50:hover {
    --tw-gradient-to: #E8F5E9
  }

  .lg\:hover\:to-green-100:hover {
    --tw-gradient-to: #C8E6C9
  }

  .lg\:hover\:to-green-200:hover {
    --tw-gradient-to: #A5D6A7
  }

  .lg\:hover\:to-green-300:hover {
    --tw-gradient-to: #81C784
  }

  .lg\:hover\:to-green-400:hover {
    --tw-gradient-to: #66BB6A
  }

  .lg\:hover\:to-green-500:hover {
    --tw-gradient-to: #4CAF50
  }

  .lg\:hover\:to-green-600:hover {
    --tw-gradient-to: #43A047
  }

  .lg\:hover\:to-green-700:hover {
    --tw-gradient-to: #388E3C
  }

  .lg\:hover\:to-green-800:hover {
    --tw-gradient-to: #2E7D32
  }

  .lg\:hover\:to-green-900:hover {
    --tw-gradient-to: #1B5E20
  }

  .lg\:hover\:to-green:hover {
    --tw-gradient-to: #4CAF50
  }

  .lg\:hover\:to-green-A100:hover {
    --tw-gradient-to: #B9F6CA
  }

  .lg\:hover\:to-green-A200:hover {
    --tw-gradient-to: #69F0AE
  }

  .lg\:hover\:to-green-A400:hover {
    --tw-gradient-to: #00E676
  }

  .lg\:hover\:to-green-A700:hover {
    --tw-gradient-to: #00C853
  }

  .lg\:hover\:to-light-green-50:hover {
    --tw-gradient-to: #F1F8E9
  }

  .lg\:hover\:to-light-green-100:hover {
    --tw-gradient-to: #DCEDC8
  }

  .lg\:hover\:to-light-green-200:hover {
    --tw-gradient-to: #C5E1A5
  }

  .lg\:hover\:to-light-green-300:hover {
    --tw-gradient-to: #AED581
  }

  .lg\:hover\:to-light-green-400:hover {
    --tw-gradient-to: #9CCC65
  }

  .lg\:hover\:to-light-green-500:hover {
    --tw-gradient-to: #8BC34A
  }

  .lg\:hover\:to-light-green-600:hover {
    --tw-gradient-to: #7CB342
  }

  .lg\:hover\:to-light-green-700:hover {
    --tw-gradient-to: #689F38
  }

  .lg\:hover\:to-light-green-800:hover {
    --tw-gradient-to: #558B2F
  }

  .lg\:hover\:to-light-green-900:hover {
    --tw-gradient-to: #33691E
  }

  .lg\:hover\:to-light-green:hover {
    --tw-gradient-to: #8BC34A
  }

  .lg\:hover\:to-light-green-A100:hover {
    --tw-gradient-to: #CCFF90
  }

  .lg\:hover\:to-light-green-A200:hover {
    --tw-gradient-to: #B2FF59
  }

  .lg\:hover\:to-light-green-A400:hover {
    --tw-gradient-to: #76FF03
  }

  .lg\:hover\:to-light-green-A700:hover {
    --tw-gradient-to: #64DD17
  }

  .lg\:hover\:to-teal-50:hover {
    --tw-gradient-to: #E0F2F1
  }

  .lg\:hover\:to-teal-100:hover {
    --tw-gradient-to: #B2DFDB
  }

  .lg\:hover\:to-teal-200:hover {
    --tw-gradient-to: #80CBC4
  }

  .lg\:hover\:to-teal-300:hover {
    --tw-gradient-to: #4DB6AC
  }

  .lg\:hover\:to-teal-400:hover {
    --tw-gradient-to: #26A69A
  }

  .lg\:hover\:to-teal-500:hover {
    --tw-gradient-to: #009688
  }

  .lg\:hover\:to-teal-600:hover {
    --tw-gradient-to: #00897B
  }

  .lg\:hover\:to-teal-700:hover {
    --tw-gradient-to: #00796B
  }

  .lg\:hover\:to-teal-800:hover {
    --tw-gradient-to: #00695C
  }

  .lg\:hover\:to-teal-900:hover {
    --tw-gradient-to: #004D40
  }

  .lg\:hover\:to-teal:hover {
    --tw-gradient-to: #009688
  }

  .lg\:hover\:to-teal-A100:hover {
    --tw-gradient-to: #A7FFEB
  }

  .lg\:hover\:to-teal-A200:hover {
    --tw-gradient-to: #64FFDA
  }

  .lg\:hover\:to-teal-A400:hover {
    --tw-gradient-to: #1DE9B6
  }

  .lg\:hover\:to-teal-A700:hover {
    --tw-gradient-to: #00BFA5
  }

  .lg\:hover\:to-blue-50:hover {
    --tw-gradient-to: #E3F2FD
  }

  .lg\:hover\:to-blue-100:hover {
    --tw-gradient-to: #BBDEFB
  }

  .lg\:hover\:to-blue-200:hover {
    --tw-gradient-to: #90CAF9
  }

  .lg\:hover\:to-blue-300:hover {
    --tw-gradient-to: #64B5F6
  }

  .lg\:hover\:to-blue-400:hover {
    --tw-gradient-to: #42A5F5
  }

  .lg\:hover\:to-blue-500:hover {
    --tw-gradient-to: #2196F3
  }

  .lg\:hover\:to-blue-600:hover {
    --tw-gradient-to: #1E88E5
  }

  .lg\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1976D2
  }

  .lg\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1565C0
  }

  .lg\:hover\:to-blue-900:hover {
    --tw-gradient-to: #0D47A1
  }

  .lg\:hover\:to-blue:hover {
    --tw-gradient-to: #2196F3
  }

  .lg\:hover\:to-blue-A100:hover {
    --tw-gradient-to: #82B1FF
  }

  .lg\:hover\:to-blue-A200:hover {
    --tw-gradient-to: #448AFF
  }

  .lg\:hover\:to-blue-A400:hover {
    --tw-gradient-to: #2979FF
  }

  .lg\:hover\:to-blue-A700:hover {
    --tw-gradient-to: #2962FF
  }

  .lg\:hover\:to-light-blue-50:hover {
    --tw-gradient-to: #E1F5FE
  }

  .lg\:hover\:to-light-blue-100:hover {
    --tw-gradient-to: #B3E5FC
  }

  .lg\:hover\:to-light-blue-200:hover {
    --tw-gradient-to: #81D4FA
  }

  .lg\:hover\:to-light-blue-300:hover {
    --tw-gradient-to: #4FC3F7
  }

  .lg\:hover\:to-light-blue-400:hover {
    --tw-gradient-to: #29B6F6
  }

  .lg\:hover\:to-light-blue-500:hover {
    --tw-gradient-to: #03A9F4
  }

  .lg\:hover\:to-light-blue-600:hover {
    --tw-gradient-to: #039BE5
  }

  .lg\:hover\:to-light-blue-700:hover {
    --tw-gradient-to: #0288D1
  }

  .lg\:hover\:to-light-blue-800:hover {
    --tw-gradient-to: #0277BD
  }

  .lg\:hover\:to-light-blue-900:hover {
    --tw-gradient-to: #01579B
  }

  .lg\:hover\:to-light-blue:hover {
    --tw-gradient-to: #03A9F4
  }

  .lg\:hover\:to-light-blue-A100:hover {
    --tw-gradient-to: #80D8FF
  }

  .lg\:hover\:to-light-blue-A200:hover {
    --tw-gradient-to: #40C4FF
  }

  .lg\:hover\:to-light-blue-A400:hover {
    --tw-gradient-to: #00B0FF
  }

  .lg\:hover\:to-light-blue-A700:hover {
    --tw-gradient-to: #0091EA
  }

  .lg\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #E8EAF6
  }

  .lg\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #C5CAE9
  }

  .lg\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #9FA8DA
  }

  .lg\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #7986CB
  }

  .lg\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #5C6BC0
  }

  .lg\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #3F51B5
  }

  .lg\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #3949AB
  }

  .lg\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #303F9F
  }

  .lg\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #283593
  }

  .lg\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #1A237E
  }

  .lg\:hover\:to-indigo:hover {
    --tw-gradient-to: #3F51B5
  }

  .lg\:hover\:to-indigo-A100:hover {
    --tw-gradient-to: #8C9EFF
  }

  .lg\:hover\:to-indigo-A200:hover {
    --tw-gradient-to: #536DFE
  }

  .lg\:hover\:to-indigo-A400:hover {
    --tw-gradient-to: #3D5AFE
  }

  .lg\:hover\:to-indigo-A700:hover {
    --tw-gradient-to: #304FFE
  }

  .lg\:hover\:to-purple-50:hover {
    --tw-gradient-to: #F3E5F5
  }

  .lg\:hover\:to-purple-100:hover {
    --tw-gradient-to: #E1BEE7
  }

  .lg\:hover\:to-purple-200:hover {
    --tw-gradient-to: #CE93D8
  }

  .lg\:hover\:to-purple-300:hover {
    --tw-gradient-to: #BA68C8
  }

  .lg\:hover\:to-purple-400:hover {
    --tw-gradient-to: #AB47BC
  }

  .lg\:hover\:to-purple-500:hover {
    --tw-gradient-to: #9C27B0
  }

  .lg\:hover\:to-purple-600:hover {
    --tw-gradient-to: #8E24AA
  }

  .lg\:hover\:to-purple-700:hover {
    --tw-gradient-to: #7B1FA2
  }

  .lg\:hover\:to-purple-800:hover {
    --tw-gradient-to: #6A1B9A
  }

  .lg\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4A148C
  }

  .lg\:hover\:to-purple:hover {
    --tw-gradient-to: #9C27B0
  }

  .lg\:hover\:to-purple-A100:hover {
    --tw-gradient-to: #EA80FC
  }

  .lg\:hover\:to-purple-A200:hover {
    --tw-gradient-to: #E040FB
  }

  .lg\:hover\:to-purple-A400:hover {
    --tw-gradient-to: #D500F9
  }

  .lg\:hover\:to-purple-A700:hover {
    --tw-gradient-to: #AA00FF
  }

  .lg\:hover\:to-deep-purple-50:hover {
    --tw-gradient-to: #EDE7F6
  }

  .lg\:hover\:to-deep-purple-100:hover {
    --tw-gradient-to: #D1C4E9
  }

  .lg\:hover\:to-deep-purple-200:hover {
    --tw-gradient-to: #B39DDB
  }

  .lg\:hover\:to-deep-purple-300:hover {
    --tw-gradient-to: #9575CD
  }

  .lg\:hover\:to-deep-purple-400:hover {
    --tw-gradient-to: #7E57C2
  }

  .lg\:hover\:to-deep-purple-500:hover {
    --tw-gradient-to: #673AB7
  }

  .lg\:hover\:to-deep-purple-600:hover {
    --tw-gradient-to: #5E35B1
  }

  .lg\:hover\:to-deep-purple-700:hover {
    --tw-gradient-to: #512DA8
  }

  .lg\:hover\:to-deep-purple-800:hover {
    --tw-gradient-to: #4527A0
  }

  .lg\:hover\:to-deep-purple-900:hover {
    --tw-gradient-to: #311B92
  }

  .lg\:hover\:to-deep-purple:hover {
    --tw-gradient-to: #673AB7
  }

  .lg\:hover\:to-deep-purple-A100:hover {
    --tw-gradient-to: #B388FF
  }

  .lg\:hover\:to-deep-purple-A200:hover {
    --tw-gradient-to: #7C4DFF
  }

  .lg\:hover\:to-deep-purple-A400:hover {
    --tw-gradient-to: #651FFF
  }

  .lg\:hover\:to-deep-purple-A700:hover {
    --tw-gradient-to: #6200EA
  }

  .lg\:hover\:to-pink-50:hover {
    --tw-gradient-to: #FCE4EC
  }

  .lg\:hover\:to-pink-100:hover {
    --tw-gradient-to: #F8BBD0
  }

  .lg\:hover\:to-pink-200:hover {
    --tw-gradient-to: #F48FB1
  }

  .lg\:hover\:to-pink-300:hover {
    --tw-gradient-to: #F06292
  }

  .lg\:hover\:to-pink-400:hover {
    --tw-gradient-to: #EC407A
  }

  .lg\:hover\:to-pink-500:hover {
    --tw-gradient-to: #E91E63
  }

  .lg\:hover\:to-pink-600:hover {
    --tw-gradient-to: #D81B60
  }

  .lg\:hover\:to-pink-700:hover {
    --tw-gradient-to: #C2185B
  }

  .lg\:hover\:to-pink-800:hover {
    --tw-gradient-to: #AD1457
  }

  .lg\:hover\:to-pink-900:hover {
    --tw-gradient-to: #880E4F
  }

  .lg\:hover\:to-pink:hover {
    --tw-gradient-to: #E91E63
  }

  .lg\:hover\:to-pink-A100:hover {
    --tw-gradient-to: #FF80AB
  }

  .lg\:hover\:to-pink-A200:hover {
    --tw-gradient-to: #FF4081
  }

  .lg\:hover\:to-pink-A400:hover {
    --tw-gradient-to: #F50057
  }

  .lg\:hover\:to-pink-A700:hover {
    --tw-gradient-to: #C51162
  }

  .lg\:hover\:to-lime-50:hover {
    --tw-gradient-to: #F9FBE7
  }

  .lg\:hover\:to-lime-100:hover {
    --tw-gradient-to: #F0F4C3
  }

  .lg\:hover\:to-lime-200:hover {
    --tw-gradient-to: #E6EE9C
  }

  .lg\:hover\:to-lime-300:hover {
    --tw-gradient-to: #DCE775
  }

  .lg\:hover\:to-lime-400:hover {
    --tw-gradient-to: #D4E157
  }

  .lg\:hover\:to-lime-500:hover {
    --tw-gradient-to: #CDDC39
  }

  .lg\:hover\:to-lime-600:hover {
    --tw-gradient-to: #C0CA33
  }

  .lg\:hover\:to-lime-700:hover {
    --tw-gradient-to: #AFB42B
  }

  .lg\:hover\:to-lime-800:hover {
    --tw-gradient-to: #9E9D24
  }

  .lg\:hover\:to-lime-900:hover {
    --tw-gradient-to: #827717
  }

  .lg\:hover\:to-lime:hover {
    --tw-gradient-to: #CDDC39
  }

  .lg\:hover\:to-lime-A100:hover {
    --tw-gradient-to: #F4FF81
  }

  .lg\:hover\:to-lime-A200:hover {
    --tw-gradient-to: #EEFF41
  }

  .lg\:hover\:to-lime-A400:hover {
    --tw-gradient-to: #C6FF00
  }

  .lg\:hover\:to-lime-A700:hover {
    --tw-gradient-to: #AEEA00
  }

  .lg\:hover\:to-amber-50:hover {
    --tw-gradient-to: #FFF8E1
  }

  .lg\:hover\:to-amber-100:hover {
    --tw-gradient-to: #FFECB3
  }

  .lg\:hover\:to-amber-200:hover {
    --tw-gradient-to: #FFE082
  }

  .lg\:hover\:to-amber-300:hover {
    --tw-gradient-to: #FFD54F
  }

  .lg\:hover\:to-amber-400:hover {
    --tw-gradient-to: #FFCA28
  }

  .lg\:hover\:to-amber-500:hover {
    --tw-gradient-to: #FFC107
  }

  .lg\:hover\:to-amber-600:hover {
    --tw-gradient-to: #FFB300
  }

  .lg\:hover\:to-amber-700:hover {
    --tw-gradient-to: #FFA000
  }

  .lg\:hover\:to-amber-800:hover {
    --tw-gradient-to: #FF8F00
  }

  .lg\:hover\:to-amber-900:hover {
    --tw-gradient-to: #FF6F00
  }

  .lg\:hover\:to-amber:hover {
    --tw-gradient-to: #FFC107
  }

  .lg\:hover\:to-amber-A100:hover {
    --tw-gradient-to: #FFE57F
  }

  .lg\:hover\:to-amber-A200:hover {
    --tw-gradient-to: #FFD740
  }

  .lg\:hover\:to-amber-A400:hover {
    --tw-gradient-to: #FFC400
  }

  .lg\:hover\:to-amber-A700:hover {
    --tw-gradient-to: #FFAB00
  }

  .lg\:hover\:to-brown-50:hover {
    --tw-gradient-to: #EFEBE9
  }

  .lg\:hover\:to-brown-100:hover {
    --tw-gradient-to: #D7CCC8
  }

  .lg\:hover\:to-brown-200:hover {
    --tw-gradient-to: #BCAAA4
  }

  .lg\:hover\:to-brown-300:hover {
    --tw-gradient-to: #A1887F
  }

  .lg\:hover\:to-brown-400:hover {
    --tw-gradient-to: #8D6E63
  }

  .lg\:hover\:to-brown-500:hover {
    --tw-gradient-to: #795548
  }

  .lg\:hover\:to-brown-600:hover {
    --tw-gradient-to: #6D4C41
  }

  .lg\:hover\:to-brown-700:hover {
    --tw-gradient-to: #5D4037
  }

  .lg\:hover\:to-brown-800:hover {
    --tw-gradient-to: #4E342E
  }

  .lg\:hover\:to-brown-900:hover {
    --tw-gradient-to: #3E2723
  }

  .lg\:hover\:to-brown:hover {
    --tw-gradient-to: #795548
  }

  .lg\:hover\:to-brown-A100:hover {
    --tw-gradient-to: #D7CCC8
  }

  .lg\:hover\:to-brown-A200:hover {
    --tw-gradient-to: #BCAAA4
  }

  .lg\:hover\:to-brown-A400:hover {
    --tw-gradient-to: #8D6E63
  }

  .lg\:hover\:to-brown-A700:hover {
    --tw-gradient-to: #5D4037
  }

  .lg\:hover\:to-blue-gray-50:hover {
    --tw-gradient-to: #ECEFF1
  }

  .lg\:hover\:to-blue-gray-100:hover {
    --tw-gradient-to: #CFD8DC
  }

  .lg\:hover\:to-blue-gray-200:hover {
    --tw-gradient-to: #B0BEC5
  }

  .lg\:hover\:to-blue-gray-300:hover {
    --tw-gradient-to: #90A4AE
  }

  .lg\:hover\:to-blue-gray-400:hover {
    --tw-gradient-to: #78909C
  }

  .lg\:hover\:to-blue-gray-500:hover {
    --tw-gradient-to: #607D8B
  }

  .lg\:hover\:to-blue-gray-600:hover {
    --tw-gradient-to: #546E7A
  }

  .lg\:hover\:to-blue-gray-700:hover {
    --tw-gradient-to: #455A64
  }

  .lg\:hover\:to-blue-gray-800:hover {
    --tw-gradient-to: #37474F
  }

  .lg\:hover\:to-blue-gray-900:hover {
    --tw-gradient-to: #263238
  }

  .lg\:hover\:to-blue-gray:hover {
    --tw-gradient-to: #607D8B
  }

  .lg\:hover\:to-blue-gray-A100:hover {
    --tw-gradient-to: #CFD8DC
  }

  .lg\:hover\:to-blue-gray-A200:hover {
    --tw-gradient-to: #B0BEC5
  }

  .lg\:hover\:to-blue-gray-A400:hover {
    --tw-gradient-to: #78909C
  }

  .lg\:hover\:to-blue-gray-A700:hover {
    --tw-gradient-to: #455A64
  }

  .lg\:hover\:to-cyan-50:hover {
    --tw-gradient-to: #E0F7FA
  }

  .lg\:hover\:to-cyan-100:hover {
    --tw-gradient-to: #B2EBF2
  }

  .lg\:hover\:to-cyan-200:hover {
    --tw-gradient-to: #80DEEA
  }

  .lg\:hover\:to-cyan-300:hover {
    --tw-gradient-to: #4DD0E1
  }

  .lg\:hover\:to-cyan-400:hover {
    --tw-gradient-to: #26C6DA
  }

  .lg\:hover\:to-cyan-500:hover {
    --tw-gradient-to: #00BCD4
  }

  .lg\:hover\:to-cyan-600:hover {
    --tw-gradient-to: #00ACC1
  }

  .lg\:hover\:to-cyan-700:hover {
    --tw-gradient-to: #0097A7
  }

  .lg\:hover\:to-cyan-800:hover {
    --tw-gradient-to: #00838F
  }

  .lg\:hover\:to-cyan-900:hover {
    --tw-gradient-to: #006064
  }

  .lg\:hover\:to-cyan:hover {
    --tw-gradient-to: #00BCD4
  }

  .lg\:hover\:to-cyan-A100:hover {
    --tw-gradient-to: #84FFFF
  }

  .lg\:hover\:to-cyan-A200:hover {
    --tw-gradient-to: #18FFFF
  }

  .lg\:hover\:to-cyan-A400:hover {
    --tw-gradient-to: #00E5FF
  }

  .lg\:hover\:to-cyan-A700:hover {
    --tw-gradient-to: #00B8D4
  }

  .lg\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-black:focus {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .lg\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-grey-50:focus {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .lg\:focus\:from-grey-100:focus {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .lg\:focus\:from-grey-200:focus {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .lg\:focus\:from-grey-300:focus {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .lg\:focus\:from-grey-400:focus {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .lg\:focus\:from-grey-500:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:focus\:from-grey-600:focus {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .lg\:focus\:from-grey-700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:focus\:from-grey-800:focus {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .lg\:focus\:from-grey-900:focus {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .lg\:focus\:from-grey:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:focus\:from-grey-A100:focus {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .lg\:focus\:from-grey-A200:focus {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .lg\:focus\:from-grey-A400:focus {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .lg\:focus\:from-grey-A700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:focus\:from-gray-50:focus {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .lg\:focus\:from-gray-100:focus {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .lg\:focus\:from-gray-200:focus {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .lg\:focus\:from-gray-300:focus {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .lg\:focus\:from-gray-400:focus {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .lg\:focus\:from-gray-500:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:focus\:from-gray-600:focus {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .lg\:focus\:from-gray-700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:focus\:from-gray-800:focus {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .lg\:focus\:from-gray-900:focus {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .lg\:focus\:from-gray:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:focus\:from-gray-hover:focus {
    --tw-gradient-from: rgba(0, 0, 0, 0.04);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:from-gray-A100:focus {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .lg\:focus\:from-gray-A200:focus {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .lg\:focus\:from-gray-A400:focus {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .lg\:focus\:from-gray-A700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:focus\:from-red-50:focus {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .lg\:focus\:from-red-100:focus {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .lg\:focus\:from-red-200:focus {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .lg\:focus\:from-red-300:focus {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .lg\:focus\:from-red-400:focus {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .lg\:focus\:from-red-500:focus {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .lg\:focus\:from-red-600:focus {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .lg\:focus\:from-red-700:focus {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .lg\:focus\:from-red-800:focus {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .lg\:focus\:from-red-900:focus {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .lg\:focus\:from-red:focus {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .lg\:focus\:from-red-A100:focus {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .lg\:focus\:from-red-A200:focus {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .lg\:focus\:from-red-A400:focus {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .lg\:focus\:from-red-A700:focus {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .lg\:focus\:from-orange-50:focus {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .lg\:focus\:from-orange-100:focus {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .lg\:focus\:from-orange-200:focus {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .lg\:focus\:from-orange-300:focus {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .lg\:focus\:from-orange-400:focus {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .lg\:focus\:from-orange-500:focus {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .lg\:focus\:from-orange-600:focus {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .lg\:focus\:from-orange-700:focus {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .lg\:focus\:from-orange-800:focus {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .lg\:focus\:from-orange-900:focus {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .lg\:focus\:from-orange:focus {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .lg\:focus\:from-orange-A100:focus {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .lg\:focus\:from-orange-A200:focus {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .lg\:focus\:from-orange-A400:focus {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .lg\:focus\:from-orange-A700:focus {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .lg\:focus\:from-deep-orange-50:focus {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .lg\:focus\:from-deep-orange-100:focus {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .lg\:focus\:from-deep-orange-200:focus {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .lg\:focus\:from-deep-orange-300:focus {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .lg\:focus\:from-deep-orange-400:focus {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .lg\:focus\:from-deep-orange-500:focus {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .lg\:focus\:from-deep-orange-600:focus {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .lg\:focus\:from-deep-orange-700:focus {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .lg\:focus\:from-deep-orange-800:focus {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .lg\:focus\:from-deep-orange-900:focus {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .lg\:focus\:from-deep-orange:focus {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .lg\:focus\:from-deep-orange-A100:focus {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .lg\:focus\:from-deep-orange-A200:focus {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .lg\:focus\:from-deep-orange-A400:focus {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .lg\:focus\:from-deep-orange-A700:focus {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .lg\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .lg\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .lg\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .lg\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .lg\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .lg\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .lg\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .lg\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .lg\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .lg\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .lg\:focus\:from-yellow:focus {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .lg\:focus\:from-yellow-A100:focus {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .lg\:focus\:from-yellow-A200:focus {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .lg\:focus\:from-yellow-A400:focus {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .lg\:focus\:from-yellow-A700:focus {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .lg\:focus\:from-green-50:focus {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .lg\:focus\:from-green-100:focus {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .lg\:focus\:from-green-200:focus {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .lg\:focus\:from-green-300:focus {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .lg\:focus\:from-green-400:focus {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .lg\:focus\:from-green-500:focus {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .lg\:focus\:from-green-600:focus {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .lg\:focus\:from-green-700:focus {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .lg\:focus\:from-green-800:focus {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .lg\:focus\:from-green-900:focus {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .lg\:focus\:from-green:focus {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .lg\:focus\:from-green-A100:focus {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .lg\:focus\:from-green-A200:focus {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .lg\:focus\:from-green-A400:focus {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .lg\:focus\:from-green-A700:focus {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .lg\:focus\:from-light-green-50:focus {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .lg\:focus\:from-light-green-100:focus {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .lg\:focus\:from-light-green-200:focus {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .lg\:focus\:from-light-green-300:focus {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .lg\:focus\:from-light-green-400:focus {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .lg\:focus\:from-light-green-500:focus {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .lg\:focus\:from-light-green-600:focus {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .lg\:focus\:from-light-green-700:focus {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .lg\:focus\:from-light-green-800:focus {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .lg\:focus\:from-light-green-900:focus {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .lg\:focus\:from-light-green:focus {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .lg\:focus\:from-light-green-A100:focus {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .lg\:focus\:from-light-green-A200:focus {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .lg\:focus\:from-light-green-A400:focus {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .lg\:focus\:from-light-green-A700:focus {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .lg\:focus\:from-teal-50:focus {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .lg\:focus\:from-teal-100:focus {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .lg\:focus\:from-teal-200:focus {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .lg\:focus\:from-teal-300:focus {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .lg\:focus\:from-teal-400:focus {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .lg\:focus\:from-teal-500:focus {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .lg\:focus\:from-teal-600:focus {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .lg\:focus\:from-teal-700:focus {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .lg\:focus\:from-teal-800:focus {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .lg\:focus\:from-teal-900:focus {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .lg\:focus\:from-teal:focus {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .lg\:focus\:from-teal-A100:focus {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .lg\:focus\:from-teal-A200:focus {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .lg\:focus\:from-teal-A400:focus {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .lg\:focus\:from-teal-A700:focus {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .lg\:focus\:from-blue-50:focus {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .lg\:focus\:from-blue-100:focus {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .lg\:focus\:from-blue-200:focus {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .lg\:focus\:from-blue-300:focus {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .lg\:focus\:from-blue-400:focus {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .lg\:focus\:from-blue-500:focus {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .lg\:focus\:from-blue-600:focus {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .lg\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .lg\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .lg\:focus\:from-blue-900:focus {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .lg\:focus\:from-blue:focus {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .lg\:focus\:from-blue-A100:focus {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .lg\:focus\:from-blue-A200:focus {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .lg\:focus\:from-blue-A400:focus {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .lg\:focus\:from-blue-A700:focus {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .lg\:focus\:from-light-blue-50:focus {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .lg\:focus\:from-light-blue-100:focus {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .lg\:focus\:from-light-blue-200:focus {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .lg\:focus\:from-light-blue-300:focus {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .lg\:focus\:from-light-blue-400:focus {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .lg\:focus\:from-light-blue-500:focus {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .lg\:focus\:from-light-blue-600:focus {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .lg\:focus\:from-light-blue-700:focus {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .lg\:focus\:from-light-blue-800:focus {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .lg\:focus\:from-light-blue-900:focus {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .lg\:focus\:from-light-blue:focus {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .lg\:focus\:from-light-blue-A100:focus {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .lg\:focus\:from-light-blue-A200:focus {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .lg\:focus\:from-light-blue-A400:focus {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .lg\:focus\:from-light-blue-A700:focus {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .lg\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .lg\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .lg\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .lg\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .lg\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .lg\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .lg\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .lg\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .lg\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .lg\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .lg\:focus\:from-indigo:focus {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .lg\:focus\:from-indigo-A100:focus {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .lg\:focus\:from-indigo-A200:focus {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .lg\:focus\:from-indigo-A400:focus {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .lg\:focus\:from-indigo-A700:focus {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .lg\:focus\:from-purple-50:focus {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .lg\:focus\:from-purple-100:focus {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .lg\:focus\:from-purple-200:focus {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .lg\:focus\:from-purple-300:focus {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .lg\:focus\:from-purple-400:focus {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .lg\:focus\:from-purple-500:focus {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .lg\:focus\:from-purple-600:focus {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .lg\:focus\:from-purple-700:focus {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .lg\:focus\:from-purple-800:focus {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .lg\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .lg\:focus\:from-purple:focus {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .lg\:focus\:from-purple-A100:focus {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .lg\:focus\:from-purple-A200:focus {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .lg\:focus\:from-purple-A400:focus {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .lg\:focus\:from-purple-A700:focus {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .lg\:focus\:from-deep-purple-50:focus {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .lg\:focus\:from-deep-purple-100:focus {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .lg\:focus\:from-deep-purple-200:focus {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .lg\:focus\:from-deep-purple-300:focus {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .lg\:focus\:from-deep-purple-400:focus {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .lg\:focus\:from-deep-purple-500:focus {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .lg\:focus\:from-deep-purple-600:focus {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .lg\:focus\:from-deep-purple-700:focus {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .lg\:focus\:from-deep-purple-800:focus {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .lg\:focus\:from-deep-purple-900:focus {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .lg\:focus\:from-deep-purple:focus {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .lg\:focus\:from-deep-purple-A100:focus {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .lg\:focus\:from-deep-purple-A200:focus {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .lg\:focus\:from-deep-purple-A400:focus {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .lg\:focus\:from-deep-purple-A700:focus {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .lg\:focus\:from-pink-50:focus {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .lg\:focus\:from-pink-100:focus {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .lg\:focus\:from-pink-200:focus {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .lg\:focus\:from-pink-300:focus {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .lg\:focus\:from-pink-400:focus {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .lg\:focus\:from-pink-500:focus {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .lg\:focus\:from-pink-600:focus {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .lg\:focus\:from-pink-700:focus {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .lg\:focus\:from-pink-800:focus {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .lg\:focus\:from-pink-900:focus {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .lg\:focus\:from-pink:focus {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .lg\:focus\:from-pink-A100:focus {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .lg\:focus\:from-pink-A200:focus {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .lg\:focus\:from-pink-A400:focus {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .lg\:focus\:from-pink-A700:focus {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .lg\:focus\:from-lime-50:focus {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .lg\:focus\:from-lime-100:focus {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .lg\:focus\:from-lime-200:focus {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .lg\:focus\:from-lime-300:focus {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .lg\:focus\:from-lime-400:focus {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .lg\:focus\:from-lime-500:focus {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .lg\:focus\:from-lime-600:focus {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .lg\:focus\:from-lime-700:focus {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .lg\:focus\:from-lime-800:focus {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .lg\:focus\:from-lime-900:focus {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .lg\:focus\:from-lime:focus {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .lg\:focus\:from-lime-A100:focus {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .lg\:focus\:from-lime-A200:focus {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .lg\:focus\:from-lime-A400:focus {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .lg\:focus\:from-lime-A700:focus {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .lg\:focus\:from-amber-50:focus {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .lg\:focus\:from-amber-100:focus {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .lg\:focus\:from-amber-200:focus {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .lg\:focus\:from-amber-300:focus {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .lg\:focus\:from-amber-400:focus {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .lg\:focus\:from-amber-500:focus {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .lg\:focus\:from-amber-600:focus {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .lg\:focus\:from-amber-700:focus {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .lg\:focus\:from-amber-800:focus {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .lg\:focus\:from-amber-900:focus {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .lg\:focus\:from-amber:focus {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .lg\:focus\:from-amber-A100:focus {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .lg\:focus\:from-amber-A200:focus {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .lg\:focus\:from-amber-A400:focus {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .lg\:focus\:from-amber-A700:focus {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .lg\:focus\:from-brown-50:focus {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .lg\:focus\:from-brown-100:focus {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .lg\:focus\:from-brown-200:focus {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .lg\:focus\:from-brown-300:focus {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .lg\:focus\:from-brown-400:focus {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .lg\:focus\:from-brown-500:focus {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .lg\:focus\:from-brown-600:focus {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .lg\:focus\:from-brown-700:focus {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .lg\:focus\:from-brown-800:focus {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .lg\:focus\:from-brown-900:focus {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .lg\:focus\:from-brown:focus {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .lg\:focus\:from-brown-A100:focus {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .lg\:focus\:from-brown-A200:focus {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .lg\:focus\:from-brown-A400:focus {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .lg\:focus\:from-brown-A700:focus {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .lg\:focus\:from-blue-gray-50:focus {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .lg\:focus\:from-blue-gray-100:focus {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .lg\:focus\:from-blue-gray-200:focus {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .lg\:focus\:from-blue-gray-300:focus {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .lg\:focus\:from-blue-gray-400:focus {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .lg\:focus\:from-blue-gray-500:focus {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .lg\:focus\:from-blue-gray-600:focus {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .lg\:focus\:from-blue-gray-700:focus {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .lg\:focus\:from-blue-gray-800:focus {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .lg\:focus\:from-blue-gray-900:focus {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .lg\:focus\:from-blue-gray:focus {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .lg\:focus\:from-blue-gray-A100:focus {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .lg\:focus\:from-blue-gray-A200:focus {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .lg\:focus\:from-blue-gray-A400:focus {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .lg\:focus\:from-blue-gray-A700:focus {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .lg\:focus\:from-cyan-50:focus {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .lg\:focus\:from-cyan-100:focus {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .lg\:focus\:from-cyan-200:focus {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .lg\:focus\:from-cyan-300:focus {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .lg\:focus\:from-cyan-400:focus {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .lg\:focus\:from-cyan-500:focus {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .lg\:focus\:from-cyan-600:focus {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .lg\:focus\:from-cyan-700:focus {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .lg\:focus\:from-cyan-800:focus {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .lg\:focus\:from-cyan-900:focus {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .lg\:focus\:from-cyan:focus {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .lg\:focus\:from-cyan-A100:focus {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .lg\:focus\:from-cyan-A200:focus {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .lg\:focus\:from-cyan-A400:focus {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .lg\:focus\:from-cyan-A700:focus {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .lg\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .lg\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-grey-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .lg\:focus\:via-grey-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .lg\:focus\:via-grey-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .lg\:focus\:via-grey-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .lg\:focus\:via-grey-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .lg\:focus\:via-grey-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:focus\:via-grey-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .lg\:focus\:via-grey-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:focus\:via-grey-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .lg\:focus\:via-grey-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .lg\:focus\:via-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:focus\:via-grey-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .lg\:focus\:via-grey-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .lg\:focus\:via-grey-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .lg\:focus\:via-grey-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .lg\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .lg\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .lg\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .lg\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .lg\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .lg\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .lg\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .lg\:focus\:via-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .lg\:focus\:via-gray-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:via-gray-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .lg\:focus\:via-gray-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .lg\:focus\:via-gray-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .lg\:focus\:via-gray-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .lg\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .lg\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .lg\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .lg\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .lg\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .lg\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .lg\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .lg\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .lg\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .lg\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .lg\:focus\:via-red:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .lg\:focus\:via-red-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .lg\:focus\:via-red-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .lg\:focus\:via-red-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .lg\:focus\:via-red-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .lg\:focus\:via-orange-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .lg\:focus\:via-orange-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .lg\:focus\:via-orange-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .lg\:focus\:via-orange-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .lg\:focus\:via-orange-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .lg\:focus\:via-orange-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .lg\:focus\:via-orange-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .lg\:focus\:via-orange-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .lg\:focus\:via-orange-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .lg\:focus\:via-orange-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .lg\:focus\:via-orange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .lg\:focus\:via-orange-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .lg\:focus\:via-orange-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .lg\:focus\:via-orange-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .lg\:focus\:via-orange-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .lg\:focus\:via-deep-orange-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .lg\:focus\:via-deep-orange-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .lg\:focus\:via-deep-orange-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .lg\:focus\:via-deep-orange-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .lg\:focus\:via-deep-orange-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .lg\:focus\:via-deep-orange-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .lg\:focus\:via-deep-orange-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .lg\:focus\:via-deep-orange-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .lg\:focus\:via-deep-orange-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .lg\:focus\:via-deep-orange-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .lg\:focus\:via-deep-orange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .lg\:focus\:via-deep-orange-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .lg\:focus\:via-deep-orange-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .lg\:focus\:via-deep-orange-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .lg\:focus\:via-deep-orange-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .lg\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .lg\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .lg\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .lg\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .lg\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .lg\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .lg\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .lg\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .lg\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .lg\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .lg\:focus\:via-yellow:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .lg\:focus\:via-yellow-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .lg\:focus\:via-yellow-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .lg\:focus\:via-yellow-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .lg\:focus\:via-yellow-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .lg\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .lg\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .lg\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .lg\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .lg\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .lg\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .lg\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .lg\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .lg\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .lg\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .lg\:focus\:via-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .lg\:focus\:via-green-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .lg\:focus\:via-green-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .lg\:focus\:via-green-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .lg\:focus\:via-green-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .lg\:focus\:via-light-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .lg\:focus\:via-light-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .lg\:focus\:via-light-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .lg\:focus\:via-light-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .lg\:focus\:via-light-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .lg\:focus\:via-light-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .lg\:focus\:via-light-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .lg\:focus\:via-light-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .lg\:focus\:via-light-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .lg\:focus\:via-light-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .lg\:focus\:via-light-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .lg\:focus\:via-light-green-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .lg\:focus\:via-light-green-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .lg\:focus\:via-light-green-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .lg\:focus\:via-light-green-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .lg\:focus\:via-teal-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .lg\:focus\:via-teal-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .lg\:focus\:via-teal-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .lg\:focus\:via-teal-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .lg\:focus\:via-teal-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .lg\:focus\:via-teal-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .lg\:focus\:via-teal-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .lg\:focus\:via-teal-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .lg\:focus\:via-teal-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .lg\:focus\:via-teal-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .lg\:focus\:via-teal:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .lg\:focus\:via-teal-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .lg\:focus\:via-teal-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .lg\:focus\:via-teal-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .lg\:focus\:via-teal-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .lg\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .lg\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .lg\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .lg\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .lg\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .lg\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .lg\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .lg\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .lg\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .lg\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .lg\:focus\:via-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .lg\:focus\:via-blue-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .lg\:focus\:via-blue-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .lg\:focus\:via-blue-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .lg\:focus\:via-blue-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .lg\:focus\:via-light-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .lg\:focus\:via-light-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .lg\:focus\:via-light-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .lg\:focus\:via-light-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .lg\:focus\:via-light-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .lg\:focus\:via-light-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .lg\:focus\:via-light-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .lg\:focus\:via-light-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .lg\:focus\:via-light-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .lg\:focus\:via-light-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .lg\:focus\:via-light-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .lg\:focus\:via-light-blue-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .lg\:focus\:via-light-blue-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .lg\:focus\:via-light-blue-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .lg\:focus\:via-light-blue-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .lg\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .lg\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .lg\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .lg\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .lg\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .lg\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .lg\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .lg\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .lg\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .lg\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .lg\:focus\:via-indigo:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .lg\:focus\:via-indigo-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .lg\:focus\:via-indigo-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .lg\:focus\:via-indigo-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .lg\:focus\:via-indigo-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .lg\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .lg\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .lg\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .lg\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .lg\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .lg\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .lg\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .lg\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .lg\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .lg\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .lg\:focus\:via-purple:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .lg\:focus\:via-purple-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .lg\:focus\:via-purple-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .lg\:focus\:via-purple-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .lg\:focus\:via-purple-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .lg\:focus\:via-deep-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .lg\:focus\:via-deep-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .lg\:focus\:via-deep-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .lg\:focus\:via-deep-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .lg\:focus\:via-deep-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .lg\:focus\:via-deep-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .lg\:focus\:via-deep-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .lg\:focus\:via-deep-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .lg\:focus\:via-deep-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .lg\:focus\:via-deep-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .lg\:focus\:via-deep-purple:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .lg\:focus\:via-deep-purple-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .lg\:focus\:via-deep-purple-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .lg\:focus\:via-deep-purple-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .lg\:focus\:via-deep-purple-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .lg\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .lg\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .lg\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .lg\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .lg\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .lg\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .lg\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .lg\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .lg\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .lg\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .lg\:focus\:via-pink:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .lg\:focus\:via-pink-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .lg\:focus\:via-pink-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .lg\:focus\:via-pink-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .lg\:focus\:via-pink-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .lg\:focus\:via-lime-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .lg\:focus\:via-lime-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .lg\:focus\:via-lime-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .lg\:focus\:via-lime-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .lg\:focus\:via-lime-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .lg\:focus\:via-lime-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .lg\:focus\:via-lime-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .lg\:focus\:via-lime-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .lg\:focus\:via-lime-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .lg\:focus\:via-lime-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .lg\:focus\:via-lime:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .lg\:focus\:via-lime-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .lg\:focus\:via-lime-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .lg\:focus\:via-lime-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .lg\:focus\:via-lime-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .lg\:focus\:via-amber-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .lg\:focus\:via-amber-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .lg\:focus\:via-amber-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .lg\:focus\:via-amber-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .lg\:focus\:via-amber-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .lg\:focus\:via-amber-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .lg\:focus\:via-amber-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .lg\:focus\:via-amber-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .lg\:focus\:via-amber-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .lg\:focus\:via-amber-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .lg\:focus\:via-amber:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .lg\:focus\:via-amber-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .lg\:focus\:via-amber-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .lg\:focus\:via-amber-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .lg\:focus\:via-amber-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .lg\:focus\:via-brown-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .lg\:focus\:via-brown-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .lg\:focus\:via-brown-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .lg\:focus\:via-brown-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .lg\:focus\:via-brown-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .lg\:focus\:via-brown-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .lg\:focus\:via-brown-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .lg\:focus\:via-brown-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .lg\:focus\:via-brown-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .lg\:focus\:via-brown-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .lg\:focus\:via-brown:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .lg\:focus\:via-brown-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .lg\:focus\:via-brown-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .lg\:focus\:via-brown-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .lg\:focus\:via-brown-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .lg\:focus\:via-blue-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .lg\:focus\:via-blue-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .lg\:focus\:via-blue-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .lg\:focus\:via-blue-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .lg\:focus\:via-blue-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .lg\:focus\:via-blue-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .lg\:focus\:via-blue-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .lg\:focus\:via-blue-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .lg\:focus\:via-blue-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .lg\:focus\:via-blue-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .lg\:focus\:via-blue-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .lg\:focus\:via-blue-gray-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .lg\:focus\:via-blue-gray-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .lg\:focus\:via-blue-gray-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .lg\:focus\:via-blue-gray-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .lg\:focus\:via-cyan-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .lg\:focus\:via-cyan-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .lg\:focus\:via-cyan-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .lg\:focus\:via-cyan-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .lg\:focus\:via-cyan-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .lg\:focus\:via-cyan-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .lg\:focus\:via-cyan-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .lg\:focus\:via-cyan-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .lg\:focus\:via-cyan-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .lg\:focus\:via-cyan-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .lg\:focus\:via-cyan:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .lg\:focus\:via-cyan-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .lg\:focus\:via-cyan-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .lg\:focus\:via-cyan-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .lg\:focus\:via-cyan-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .lg\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent
  }

  .lg\:focus\:to-current:focus {
    --tw-gradient-to: currentColor
  }

  .lg\:focus\:to-black:focus {
    --tw-gradient-to: #22292F
  }

  .lg\:focus\:to-white:focus {
    --tw-gradient-to: #fff
  }

  .lg\:focus\:to-grey-50:focus {
    --tw-gradient-to: #FAFAFA
  }

  .lg\:focus\:to-grey-100:focus {
    --tw-gradient-to: #F5F5F5
  }

  .lg\:focus\:to-grey-200:focus {
    --tw-gradient-to: #EEEEEE
  }

  .lg\:focus\:to-grey-300:focus {
    --tw-gradient-to: #E0E0E0
  }

  .lg\:focus\:to-grey-400:focus {
    --tw-gradient-to: #BDBDBD
  }

  .lg\:focus\:to-grey-500:focus {
    --tw-gradient-to: #9E9E9E
  }

  .lg\:focus\:to-grey-600:focus {
    --tw-gradient-to: #757575
  }

  .lg\:focus\:to-grey-700:focus {
    --tw-gradient-to: #616161
  }

  .lg\:focus\:to-grey-800:focus {
    --tw-gradient-to: #424242
  }

  .lg\:focus\:to-grey-900:focus {
    --tw-gradient-to: #212121
  }

  .lg\:focus\:to-grey:focus {
    --tw-gradient-to: #9E9E9E
  }

  .lg\:focus\:to-grey-A100:focus {
    --tw-gradient-to: #D5D5D5
  }

  .lg\:focus\:to-grey-A200:focus {
    --tw-gradient-to: #AAAAAA
  }

  .lg\:focus\:to-grey-A400:focus {
    --tw-gradient-to: #303030
  }

  .lg\:focus\:to-grey-A700:focus {
    --tw-gradient-to: #616161
  }

  .lg\:focus\:to-gray-50:focus {
    --tw-gradient-to: #FAFAFA
  }

  .lg\:focus\:to-gray-100:focus {
    --tw-gradient-to: #F5F5F5
  }

  .lg\:focus\:to-gray-200:focus {
    --tw-gradient-to: #EEEEEE
  }

  .lg\:focus\:to-gray-300:focus {
    --tw-gradient-to: #E0E0E0
  }

  .lg\:focus\:to-gray-400:focus {
    --tw-gradient-to: #BDBDBD
  }

  .lg\:focus\:to-gray-500:focus {
    --tw-gradient-to: #9E9E9E
  }

  .lg\:focus\:to-gray-600:focus {
    --tw-gradient-to: #757575
  }

  .lg\:focus\:to-gray-700:focus {
    --tw-gradient-to: #616161
  }

  .lg\:focus\:to-gray-800:focus {
    --tw-gradient-to: #424242
  }

  .lg\:focus\:to-gray-900:focus {
    --tw-gradient-to: #212121
  }

  .lg\:focus\:to-gray:focus {
    --tw-gradient-to: #9E9E9E
  }

  .lg\:focus\:to-gray-hover:focus {
    --tw-gradient-to: rgba(0, 0, 0, 0.04)
  }

  .lg\:focus\:to-gray-A100:focus {
    --tw-gradient-to: #D5D5D5
  }

  .lg\:focus\:to-gray-A200:focus {
    --tw-gradient-to: #AAAAAA
  }

  .lg\:focus\:to-gray-A400:focus {
    --tw-gradient-to: #303030
  }

  .lg\:focus\:to-gray-A700:focus {
    --tw-gradient-to: #616161
  }

  .lg\:focus\:to-red-50:focus {
    --tw-gradient-to: #FFEBEE
  }

  .lg\:focus\:to-red-100:focus {
    --tw-gradient-to: #FFCDD2
  }

  .lg\:focus\:to-red-200:focus {
    --tw-gradient-to: #EF9A9A
  }

  .lg\:focus\:to-red-300:focus {
    --tw-gradient-to: #E57373
  }

  .lg\:focus\:to-red-400:focus {
    --tw-gradient-to: #EF5350
  }

  .lg\:focus\:to-red-500:focus {
    --tw-gradient-to: #F44336
  }

  .lg\:focus\:to-red-600:focus {
    --tw-gradient-to: #E53935
  }

  .lg\:focus\:to-red-700:focus {
    --tw-gradient-to: #D32F2F
  }

  .lg\:focus\:to-red-800:focus {
    --tw-gradient-to: #C62828
  }

  .lg\:focus\:to-red-900:focus {
    --tw-gradient-to: #B71C1C
  }

  .lg\:focus\:to-red:focus {
    --tw-gradient-to: #F44336
  }

  .lg\:focus\:to-red-A100:focus {
    --tw-gradient-to: #FF8A80
  }

  .lg\:focus\:to-red-A200:focus {
    --tw-gradient-to: #FF5252
  }

  .lg\:focus\:to-red-A400:focus {
    --tw-gradient-to: #FF1744
  }

  .lg\:focus\:to-red-A700:focus {
    --tw-gradient-to: #D50000
  }

  .lg\:focus\:to-orange-50:focus {
    --tw-gradient-to: #FFF3E0
  }

  .lg\:focus\:to-orange-100:focus {
    --tw-gradient-to: #FFE0B2
  }

  .lg\:focus\:to-orange-200:focus {
    --tw-gradient-to: #FFCC80
  }

  .lg\:focus\:to-orange-300:focus {
    --tw-gradient-to: #FFB74D
  }

  .lg\:focus\:to-orange-400:focus {
    --tw-gradient-to: #FFA726
  }

  .lg\:focus\:to-orange-500:focus {
    --tw-gradient-to: #FF9800
  }

  .lg\:focus\:to-orange-600:focus {
    --tw-gradient-to: #FB8C00
  }

  .lg\:focus\:to-orange-700:focus {
    --tw-gradient-to: #F57C00
  }

  .lg\:focus\:to-orange-800:focus {
    --tw-gradient-to: #EF6C00
  }

  .lg\:focus\:to-orange-900:focus {
    --tw-gradient-to: #E65100
  }

  .lg\:focus\:to-orange:focus {
    --tw-gradient-to: #FF9800
  }

  .lg\:focus\:to-orange-A100:focus {
    --tw-gradient-to: #FFD180
  }

  .lg\:focus\:to-orange-A200:focus {
    --tw-gradient-to: #FFAB40
  }

  .lg\:focus\:to-orange-A400:focus {
    --tw-gradient-to: #FF9100
  }

  .lg\:focus\:to-orange-A700:focus {
    --tw-gradient-to: #FF6D00
  }

  .lg\:focus\:to-deep-orange-50:focus {
    --tw-gradient-to: #FBE9E7
  }

  .lg\:focus\:to-deep-orange-100:focus {
    --tw-gradient-to: #FFCCBC
  }

  .lg\:focus\:to-deep-orange-200:focus {
    --tw-gradient-to: #FFAB91
  }

  .lg\:focus\:to-deep-orange-300:focus {
    --tw-gradient-to: #FF8A65
  }

  .lg\:focus\:to-deep-orange-400:focus {
    --tw-gradient-to: #FF7043
  }

  .lg\:focus\:to-deep-orange-500:focus {
    --tw-gradient-to: #FF5722
  }

  .lg\:focus\:to-deep-orange-600:focus {
    --tw-gradient-to: #F4511E
  }

  .lg\:focus\:to-deep-orange-700:focus {
    --tw-gradient-to: #E64A19
  }

  .lg\:focus\:to-deep-orange-800:focus {
    --tw-gradient-to: #D84315
  }

  .lg\:focus\:to-deep-orange-900:focus {
    --tw-gradient-to: #BF360C
  }

  .lg\:focus\:to-deep-orange:focus {
    --tw-gradient-to: #FF5722
  }

  .lg\:focus\:to-deep-orange-A100:focus {
    --tw-gradient-to: #FF9E80
  }

  .lg\:focus\:to-deep-orange-A200:focus {
    --tw-gradient-to: #FF6E40
  }

  .lg\:focus\:to-deep-orange-A400:focus {
    --tw-gradient-to: #FF3D00
  }

  .lg\:focus\:to-deep-orange-A700:focus {
    --tw-gradient-to: #DD2C00
  }

  .lg\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #FFFDE7
  }

  .lg\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #FFF9C4
  }

  .lg\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #FFF59D
  }

  .lg\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #FFF176
  }

  .lg\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #FFEE58
  }

  .lg\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #FFEB3B
  }

  .lg\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #FDD835
  }

  .lg\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #FBC02D
  }

  .lg\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #F9A825
  }

  .lg\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #F57F17
  }

  .lg\:focus\:to-yellow:focus {
    --tw-gradient-to: #FFEB3B
  }

  .lg\:focus\:to-yellow-A100:focus {
    --tw-gradient-to: #FFFF8D
  }

  .lg\:focus\:to-yellow-A200:focus {
    --tw-gradient-to: #FFFF00
  }

  .lg\:focus\:to-yellow-A400:focus {
    --tw-gradient-to: #FFEA00
  }

  .lg\:focus\:to-yellow-A700:focus {
    --tw-gradient-to: #FFD600
  }

  .lg\:focus\:to-green-50:focus {
    --tw-gradient-to: #E8F5E9
  }

  .lg\:focus\:to-green-100:focus {
    --tw-gradient-to: #C8E6C9
  }

  .lg\:focus\:to-green-200:focus {
    --tw-gradient-to: #A5D6A7
  }

  .lg\:focus\:to-green-300:focus {
    --tw-gradient-to: #81C784
  }

  .lg\:focus\:to-green-400:focus {
    --tw-gradient-to: #66BB6A
  }

  .lg\:focus\:to-green-500:focus {
    --tw-gradient-to: #4CAF50
  }

  .lg\:focus\:to-green-600:focus {
    --tw-gradient-to: #43A047
  }

  .lg\:focus\:to-green-700:focus {
    --tw-gradient-to: #388E3C
  }

  .lg\:focus\:to-green-800:focus {
    --tw-gradient-to: #2E7D32
  }

  .lg\:focus\:to-green-900:focus {
    --tw-gradient-to: #1B5E20
  }

  .lg\:focus\:to-green:focus {
    --tw-gradient-to: #4CAF50
  }

  .lg\:focus\:to-green-A100:focus {
    --tw-gradient-to: #B9F6CA
  }

  .lg\:focus\:to-green-A200:focus {
    --tw-gradient-to: #69F0AE
  }

  .lg\:focus\:to-green-A400:focus {
    --tw-gradient-to: #00E676
  }

  .lg\:focus\:to-green-A700:focus {
    --tw-gradient-to: #00C853
  }

  .lg\:focus\:to-light-green-50:focus {
    --tw-gradient-to: #F1F8E9
  }

  .lg\:focus\:to-light-green-100:focus {
    --tw-gradient-to: #DCEDC8
  }

  .lg\:focus\:to-light-green-200:focus {
    --tw-gradient-to: #C5E1A5
  }

  .lg\:focus\:to-light-green-300:focus {
    --tw-gradient-to: #AED581
  }

  .lg\:focus\:to-light-green-400:focus {
    --tw-gradient-to: #9CCC65
  }

  .lg\:focus\:to-light-green-500:focus {
    --tw-gradient-to: #8BC34A
  }

  .lg\:focus\:to-light-green-600:focus {
    --tw-gradient-to: #7CB342
  }

  .lg\:focus\:to-light-green-700:focus {
    --tw-gradient-to: #689F38
  }

  .lg\:focus\:to-light-green-800:focus {
    --tw-gradient-to: #558B2F
  }

  .lg\:focus\:to-light-green-900:focus {
    --tw-gradient-to: #33691E
  }

  .lg\:focus\:to-light-green:focus {
    --tw-gradient-to: #8BC34A
  }

  .lg\:focus\:to-light-green-A100:focus {
    --tw-gradient-to: #CCFF90
  }

  .lg\:focus\:to-light-green-A200:focus {
    --tw-gradient-to: #B2FF59
  }

  .lg\:focus\:to-light-green-A400:focus {
    --tw-gradient-to: #76FF03
  }

  .lg\:focus\:to-light-green-A700:focus {
    --tw-gradient-to: #64DD17
  }

  .lg\:focus\:to-teal-50:focus {
    --tw-gradient-to: #E0F2F1
  }

  .lg\:focus\:to-teal-100:focus {
    --tw-gradient-to: #B2DFDB
  }

  .lg\:focus\:to-teal-200:focus {
    --tw-gradient-to: #80CBC4
  }

  .lg\:focus\:to-teal-300:focus {
    --tw-gradient-to: #4DB6AC
  }

  .lg\:focus\:to-teal-400:focus {
    --tw-gradient-to: #26A69A
  }

  .lg\:focus\:to-teal-500:focus {
    --tw-gradient-to: #009688
  }

  .lg\:focus\:to-teal-600:focus {
    --tw-gradient-to: #00897B
  }

  .lg\:focus\:to-teal-700:focus {
    --tw-gradient-to: #00796B
  }

  .lg\:focus\:to-teal-800:focus {
    --tw-gradient-to: #00695C
  }

  .lg\:focus\:to-teal-900:focus {
    --tw-gradient-to: #004D40
  }

  .lg\:focus\:to-teal:focus {
    --tw-gradient-to: #009688
  }

  .lg\:focus\:to-teal-A100:focus {
    --tw-gradient-to: #A7FFEB
  }

  .lg\:focus\:to-teal-A200:focus {
    --tw-gradient-to: #64FFDA
  }

  .lg\:focus\:to-teal-A400:focus {
    --tw-gradient-to: #1DE9B6
  }

  .lg\:focus\:to-teal-A700:focus {
    --tw-gradient-to: #00BFA5
  }

  .lg\:focus\:to-blue-50:focus {
    --tw-gradient-to: #E3F2FD
  }

  .lg\:focus\:to-blue-100:focus {
    --tw-gradient-to: #BBDEFB
  }

  .lg\:focus\:to-blue-200:focus {
    --tw-gradient-to: #90CAF9
  }

  .lg\:focus\:to-blue-300:focus {
    --tw-gradient-to: #64B5F6
  }

  .lg\:focus\:to-blue-400:focus {
    --tw-gradient-to: #42A5F5
  }

  .lg\:focus\:to-blue-500:focus {
    --tw-gradient-to: #2196F3
  }

  .lg\:focus\:to-blue-600:focus {
    --tw-gradient-to: #1E88E5
  }

  .lg\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1976D2
  }

  .lg\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1565C0
  }

  .lg\:focus\:to-blue-900:focus {
    --tw-gradient-to: #0D47A1
  }

  .lg\:focus\:to-blue:focus {
    --tw-gradient-to: #2196F3
  }

  .lg\:focus\:to-blue-A100:focus {
    --tw-gradient-to: #82B1FF
  }

  .lg\:focus\:to-blue-A200:focus {
    --tw-gradient-to: #448AFF
  }

  .lg\:focus\:to-blue-A400:focus {
    --tw-gradient-to: #2979FF
  }

  .lg\:focus\:to-blue-A700:focus {
    --tw-gradient-to: #2962FF
  }

  .lg\:focus\:to-light-blue-50:focus {
    --tw-gradient-to: #E1F5FE
  }

  .lg\:focus\:to-light-blue-100:focus {
    --tw-gradient-to: #B3E5FC
  }

  .lg\:focus\:to-light-blue-200:focus {
    --tw-gradient-to: #81D4FA
  }

  .lg\:focus\:to-light-blue-300:focus {
    --tw-gradient-to: #4FC3F7
  }

  .lg\:focus\:to-light-blue-400:focus {
    --tw-gradient-to: #29B6F6
  }

  .lg\:focus\:to-light-blue-500:focus {
    --tw-gradient-to: #03A9F4
  }

  .lg\:focus\:to-light-blue-600:focus {
    --tw-gradient-to: #039BE5
  }

  .lg\:focus\:to-light-blue-700:focus {
    --tw-gradient-to: #0288D1
  }

  .lg\:focus\:to-light-blue-800:focus {
    --tw-gradient-to: #0277BD
  }

  .lg\:focus\:to-light-blue-900:focus {
    --tw-gradient-to: #01579B
  }

  .lg\:focus\:to-light-blue:focus {
    --tw-gradient-to: #03A9F4
  }

  .lg\:focus\:to-light-blue-A100:focus {
    --tw-gradient-to: #80D8FF
  }

  .lg\:focus\:to-light-blue-A200:focus {
    --tw-gradient-to: #40C4FF
  }

  .lg\:focus\:to-light-blue-A400:focus {
    --tw-gradient-to: #00B0FF
  }

  .lg\:focus\:to-light-blue-A700:focus {
    --tw-gradient-to: #0091EA
  }

  .lg\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #E8EAF6
  }

  .lg\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #C5CAE9
  }

  .lg\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #9FA8DA
  }

  .lg\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #7986CB
  }

  .lg\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #5C6BC0
  }

  .lg\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #3F51B5
  }

  .lg\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #3949AB
  }

  .lg\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #303F9F
  }

  .lg\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #283593
  }

  .lg\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #1A237E
  }

  .lg\:focus\:to-indigo:focus {
    --tw-gradient-to: #3F51B5
  }

  .lg\:focus\:to-indigo-A100:focus {
    --tw-gradient-to: #8C9EFF
  }

  .lg\:focus\:to-indigo-A200:focus {
    --tw-gradient-to: #536DFE
  }

  .lg\:focus\:to-indigo-A400:focus {
    --tw-gradient-to: #3D5AFE
  }

  .lg\:focus\:to-indigo-A700:focus {
    --tw-gradient-to: #304FFE
  }

  .lg\:focus\:to-purple-50:focus {
    --tw-gradient-to: #F3E5F5
  }

  .lg\:focus\:to-purple-100:focus {
    --tw-gradient-to: #E1BEE7
  }

  .lg\:focus\:to-purple-200:focus {
    --tw-gradient-to: #CE93D8
  }

  .lg\:focus\:to-purple-300:focus {
    --tw-gradient-to: #BA68C8
  }

  .lg\:focus\:to-purple-400:focus {
    --tw-gradient-to: #AB47BC
  }

  .lg\:focus\:to-purple-500:focus {
    --tw-gradient-to: #9C27B0
  }

  .lg\:focus\:to-purple-600:focus {
    --tw-gradient-to: #8E24AA
  }

  .lg\:focus\:to-purple-700:focus {
    --tw-gradient-to: #7B1FA2
  }

  .lg\:focus\:to-purple-800:focus {
    --tw-gradient-to: #6A1B9A
  }

  .lg\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4A148C
  }

  .lg\:focus\:to-purple:focus {
    --tw-gradient-to: #9C27B0
  }

  .lg\:focus\:to-purple-A100:focus {
    --tw-gradient-to: #EA80FC
  }

  .lg\:focus\:to-purple-A200:focus {
    --tw-gradient-to: #E040FB
  }

  .lg\:focus\:to-purple-A400:focus {
    --tw-gradient-to: #D500F9
  }

  .lg\:focus\:to-purple-A700:focus {
    --tw-gradient-to: #AA00FF
  }

  .lg\:focus\:to-deep-purple-50:focus {
    --tw-gradient-to: #EDE7F6
  }

  .lg\:focus\:to-deep-purple-100:focus {
    --tw-gradient-to: #D1C4E9
  }

  .lg\:focus\:to-deep-purple-200:focus {
    --tw-gradient-to: #B39DDB
  }

  .lg\:focus\:to-deep-purple-300:focus {
    --tw-gradient-to: #9575CD
  }

  .lg\:focus\:to-deep-purple-400:focus {
    --tw-gradient-to: #7E57C2
  }

  .lg\:focus\:to-deep-purple-500:focus {
    --tw-gradient-to: #673AB7
  }

  .lg\:focus\:to-deep-purple-600:focus {
    --tw-gradient-to: #5E35B1
  }

  .lg\:focus\:to-deep-purple-700:focus {
    --tw-gradient-to: #512DA8
  }

  .lg\:focus\:to-deep-purple-800:focus {
    --tw-gradient-to: #4527A0
  }

  .lg\:focus\:to-deep-purple-900:focus {
    --tw-gradient-to: #311B92
  }

  .lg\:focus\:to-deep-purple:focus {
    --tw-gradient-to: #673AB7
  }

  .lg\:focus\:to-deep-purple-A100:focus {
    --tw-gradient-to: #B388FF
  }

  .lg\:focus\:to-deep-purple-A200:focus {
    --tw-gradient-to: #7C4DFF
  }

  .lg\:focus\:to-deep-purple-A400:focus {
    --tw-gradient-to: #651FFF
  }

  .lg\:focus\:to-deep-purple-A700:focus {
    --tw-gradient-to: #6200EA
  }

  .lg\:focus\:to-pink-50:focus {
    --tw-gradient-to: #FCE4EC
  }

  .lg\:focus\:to-pink-100:focus {
    --tw-gradient-to: #F8BBD0
  }

  .lg\:focus\:to-pink-200:focus {
    --tw-gradient-to: #F48FB1
  }

  .lg\:focus\:to-pink-300:focus {
    --tw-gradient-to: #F06292
  }

  .lg\:focus\:to-pink-400:focus {
    --tw-gradient-to: #EC407A
  }

  .lg\:focus\:to-pink-500:focus {
    --tw-gradient-to: #E91E63
  }

  .lg\:focus\:to-pink-600:focus {
    --tw-gradient-to: #D81B60
  }

  .lg\:focus\:to-pink-700:focus {
    --tw-gradient-to: #C2185B
  }

  .lg\:focus\:to-pink-800:focus {
    --tw-gradient-to: #AD1457
  }

  .lg\:focus\:to-pink-900:focus {
    --tw-gradient-to: #880E4F
  }

  .lg\:focus\:to-pink:focus {
    --tw-gradient-to: #E91E63
  }

  .lg\:focus\:to-pink-A100:focus {
    --tw-gradient-to: #FF80AB
  }

  .lg\:focus\:to-pink-A200:focus {
    --tw-gradient-to: #FF4081
  }

  .lg\:focus\:to-pink-A400:focus {
    --tw-gradient-to: #F50057
  }

  .lg\:focus\:to-pink-A700:focus {
    --tw-gradient-to: #C51162
  }

  .lg\:focus\:to-lime-50:focus {
    --tw-gradient-to: #F9FBE7
  }

  .lg\:focus\:to-lime-100:focus {
    --tw-gradient-to: #F0F4C3
  }

  .lg\:focus\:to-lime-200:focus {
    --tw-gradient-to: #E6EE9C
  }

  .lg\:focus\:to-lime-300:focus {
    --tw-gradient-to: #DCE775
  }

  .lg\:focus\:to-lime-400:focus {
    --tw-gradient-to: #D4E157
  }

  .lg\:focus\:to-lime-500:focus {
    --tw-gradient-to: #CDDC39
  }

  .lg\:focus\:to-lime-600:focus {
    --tw-gradient-to: #C0CA33
  }

  .lg\:focus\:to-lime-700:focus {
    --tw-gradient-to: #AFB42B
  }

  .lg\:focus\:to-lime-800:focus {
    --tw-gradient-to: #9E9D24
  }

  .lg\:focus\:to-lime-900:focus {
    --tw-gradient-to: #827717
  }

  .lg\:focus\:to-lime:focus {
    --tw-gradient-to: #CDDC39
  }

  .lg\:focus\:to-lime-A100:focus {
    --tw-gradient-to: #F4FF81
  }

  .lg\:focus\:to-lime-A200:focus {
    --tw-gradient-to: #EEFF41
  }

  .lg\:focus\:to-lime-A400:focus {
    --tw-gradient-to: #C6FF00
  }

  .lg\:focus\:to-lime-A700:focus {
    --tw-gradient-to: #AEEA00
  }

  .lg\:focus\:to-amber-50:focus {
    --tw-gradient-to: #FFF8E1
  }

  .lg\:focus\:to-amber-100:focus {
    --tw-gradient-to: #FFECB3
  }

  .lg\:focus\:to-amber-200:focus {
    --tw-gradient-to: #FFE082
  }

  .lg\:focus\:to-amber-300:focus {
    --tw-gradient-to: #FFD54F
  }

  .lg\:focus\:to-amber-400:focus {
    --tw-gradient-to: #FFCA28
  }

  .lg\:focus\:to-amber-500:focus {
    --tw-gradient-to: #FFC107
  }

  .lg\:focus\:to-amber-600:focus {
    --tw-gradient-to: #FFB300
  }

  .lg\:focus\:to-amber-700:focus {
    --tw-gradient-to: #FFA000
  }

  .lg\:focus\:to-amber-800:focus {
    --tw-gradient-to: #FF8F00
  }

  .lg\:focus\:to-amber-900:focus {
    --tw-gradient-to: #FF6F00
  }

  .lg\:focus\:to-amber:focus {
    --tw-gradient-to: #FFC107
  }

  .lg\:focus\:to-amber-A100:focus {
    --tw-gradient-to: #FFE57F
  }

  .lg\:focus\:to-amber-A200:focus {
    --tw-gradient-to: #FFD740
  }

  .lg\:focus\:to-amber-A400:focus {
    --tw-gradient-to: #FFC400
  }

  .lg\:focus\:to-amber-A700:focus {
    --tw-gradient-to: #FFAB00
  }

  .lg\:focus\:to-brown-50:focus {
    --tw-gradient-to: #EFEBE9
  }

  .lg\:focus\:to-brown-100:focus {
    --tw-gradient-to: #D7CCC8
  }

  .lg\:focus\:to-brown-200:focus {
    --tw-gradient-to: #BCAAA4
  }

  .lg\:focus\:to-brown-300:focus {
    --tw-gradient-to: #A1887F
  }

  .lg\:focus\:to-brown-400:focus {
    --tw-gradient-to: #8D6E63
  }

  .lg\:focus\:to-brown-500:focus {
    --tw-gradient-to: #795548
  }

  .lg\:focus\:to-brown-600:focus {
    --tw-gradient-to: #6D4C41
  }

  .lg\:focus\:to-brown-700:focus {
    --tw-gradient-to: #5D4037
  }

  .lg\:focus\:to-brown-800:focus {
    --tw-gradient-to: #4E342E
  }

  .lg\:focus\:to-brown-900:focus {
    --tw-gradient-to: #3E2723
  }

  .lg\:focus\:to-brown:focus {
    --tw-gradient-to: #795548
  }

  .lg\:focus\:to-brown-A100:focus {
    --tw-gradient-to: #D7CCC8
  }

  .lg\:focus\:to-brown-A200:focus {
    --tw-gradient-to: #BCAAA4
  }

  .lg\:focus\:to-brown-A400:focus {
    --tw-gradient-to: #8D6E63
  }

  .lg\:focus\:to-brown-A700:focus {
    --tw-gradient-to: #5D4037
  }

  .lg\:focus\:to-blue-gray-50:focus {
    --tw-gradient-to: #ECEFF1
  }

  .lg\:focus\:to-blue-gray-100:focus {
    --tw-gradient-to: #CFD8DC
  }

  .lg\:focus\:to-blue-gray-200:focus {
    --tw-gradient-to: #B0BEC5
  }

  .lg\:focus\:to-blue-gray-300:focus {
    --tw-gradient-to: #90A4AE
  }

  .lg\:focus\:to-blue-gray-400:focus {
    --tw-gradient-to: #78909C
  }

  .lg\:focus\:to-blue-gray-500:focus {
    --tw-gradient-to: #607D8B
  }

  .lg\:focus\:to-blue-gray-600:focus {
    --tw-gradient-to: #546E7A
  }

  .lg\:focus\:to-blue-gray-700:focus {
    --tw-gradient-to: #455A64
  }

  .lg\:focus\:to-blue-gray-800:focus {
    --tw-gradient-to: #37474F
  }

  .lg\:focus\:to-blue-gray-900:focus {
    --tw-gradient-to: #263238
  }

  .lg\:focus\:to-blue-gray:focus {
    --tw-gradient-to: #607D8B
  }

  .lg\:focus\:to-blue-gray-A100:focus {
    --tw-gradient-to: #CFD8DC
  }

  .lg\:focus\:to-blue-gray-A200:focus {
    --tw-gradient-to: #B0BEC5
  }

  .lg\:focus\:to-blue-gray-A400:focus {
    --tw-gradient-to: #78909C
  }

  .lg\:focus\:to-blue-gray-A700:focus {
    --tw-gradient-to: #455A64
  }

  .lg\:focus\:to-cyan-50:focus {
    --tw-gradient-to: #E0F7FA
  }

  .lg\:focus\:to-cyan-100:focus {
    --tw-gradient-to: #B2EBF2
  }

  .lg\:focus\:to-cyan-200:focus {
    --tw-gradient-to: #80DEEA
  }

  .lg\:focus\:to-cyan-300:focus {
    --tw-gradient-to: #4DD0E1
  }

  .lg\:focus\:to-cyan-400:focus {
    --tw-gradient-to: #26C6DA
  }

  .lg\:focus\:to-cyan-500:focus {
    --tw-gradient-to: #00BCD4
  }

  .lg\:focus\:to-cyan-600:focus {
    --tw-gradient-to: #00ACC1
  }

  .lg\:focus\:to-cyan-700:focus {
    --tw-gradient-to: #0097A7
  }

  .lg\:focus\:to-cyan-800:focus {
    --tw-gradient-to: #00838F
  }

  .lg\:focus\:to-cyan-900:focus {
    --tw-gradient-to: #006064
  }

  .lg\:focus\:to-cyan:focus {
    --tw-gradient-to: #00BCD4
  }

  .lg\:focus\:to-cyan-A100:focus {
    --tw-gradient-to: #84FFFF
  }

  .lg\:focus\:to-cyan-A200:focus {
    --tw-gradient-to: #18FFFF
  }

  .lg\:focus\:to-cyan-A400:focus {
    --tw-gradient-to: #00E5FF
  }

  .lg\:focus\:to-cyan-A700:focus {
    --tw-gradient-to: #00B8D4
  }

  .lg\:bg-opacity-0 {
    --tw-bg-opacity: 0
  }

  .lg\:bg-opacity-5 {
    --tw-bg-opacity: 0.05
  }

  .lg\:bg-opacity-10 {
    --tw-bg-opacity: 0.1
  }

  .lg\:bg-opacity-20 {
    --tw-bg-opacity: 0.2
  }

  .lg\:bg-opacity-25 {
    --tw-bg-opacity: 0.25
  }

  .lg\:bg-opacity-30 {
    --tw-bg-opacity: 0.3
  }

  .lg\:bg-opacity-40 {
    --tw-bg-opacity: 0.4
  }

  .lg\:bg-opacity-50 {
    --tw-bg-opacity: 0.5
  }

  .lg\:bg-opacity-60 {
    --tw-bg-opacity: 0.6
  }

  .lg\:bg-opacity-70 {
    --tw-bg-opacity: 0.7
  }

  .lg\:bg-opacity-75 {
    --tw-bg-opacity: 0.75
  }

  .lg\:bg-opacity-80 {
    --tw-bg-opacity: 0.8
  }

  .lg\:bg-opacity-90 {
    --tw-bg-opacity: 0.9
  }

  .lg\:bg-opacity-95 {
    --tw-bg-opacity: 0.95
  }

  .lg\:bg-opacity-100 {
    --tw-bg-opacity: 1
  }

  .group:hover .lg\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0
  }

  .group:hover .lg\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05
  }

  .group:hover .lg\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1
  }

  .group:hover .lg\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2
  }

  .group:hover .lg\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25
  }

  .group:hover .lg\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3
  }

  .group:hover .lg\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4
  }

  .group:hover .lg\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5
  }

  .group:hover .lg\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6
  }

  .group:hover .lg\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7
  }

  .group:hover .lg\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75
  }

  .group:hover .lg\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8
  }

  .group:hover .lg\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9
  }

  .group:hover .lg\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95
  }

  .group:hover .lg\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1
  }

  .lg\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0
  }

  .lg\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05
  }

  .lg\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1
  }

  .lg\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2
  }

  .lg\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25
  }

  .lg\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3
  }

  .lg\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4
  }

  .lg\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5
  }

  .lg\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6
  }

  .lg\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7
  }

  .lg\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75
  }

  .lg\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8
  }

  .lg\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9
  }

  .lg\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95
  }

  .lg\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1
  }

  .lg\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0
  }

  .lg\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05
  }

  .lg\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1
  }

  .lg\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2
  }

  .lg\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25
  }

  .lg\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3
  }

  .lg\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4
  }

  .lg\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5
  }

  .lg\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6
  }

  .lg\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7
  }

  .lg\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75
  }

  .lg\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8
  }

  .lg\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9
  }

  .lg\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95
  }

  .lg\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1
  }

  .lg\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0
  }

  .lg\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05
  }

  .lg\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1
  }

  .lg\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2
  }

  .lg\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25
  }

  .lg\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3
  }

  .lg\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4
  }

  .lg\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5
  }

  .lg\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6
  }

  .lg\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7
  }

  .lg\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75
  }

  .lg\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8
  }

  .lg\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9
  }

  .lg\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95
  }

  .lg\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1
  }

  .lg\:bg-bottom {
    background-position: bottom
  }

  .lg\:bg-center {
    background-position: center
  }

  .lg\:bg-left {
    background-position: left
  }

  .lg\:bg-left-bottom {
    background-position: left bottom
  }

  .lg\:bg-left-top {
    background-position: left top
  }

  .lg\:bg-right {
    background-position: right
  }

  .lg\:bg-right-bottom {
    background-position: right bottom
  }

  .lg\:bg-right-top {
    background-position: right top
  }

  .lg\:bg-top {
    background-position: top
  }

  .lg\:bg-repeat {
    background-repeat: repeat
  }

  .lg\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .lg\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .lg\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .lg\:bg-repeat-round {
    background-repeat: round
  }

  .lg\:bg-repeat-space {
    background-repeat: space
  }

  .lg\:bg-auto {
    background-size: auto
  }

  .lg\:bg-cover {
    background-size: cover
  }

  .lg\:bg-contain {
    background-size: contain
  }

  .lg\:border-collapse {
    border-collapse: collapse
  }

  .lg\:border-separate {
    border-collapse: separate
  }

  .lg\:border-transparent {
    border-color: transparent
  }

  .lg\:border-current {
    border-color: currentColor
  }

  .lg\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .lg\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .lg\:border-grey-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .lg\:border-grey-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .lg\:border-grey-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .lg\:border-grey-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .lg\:border-grey-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .lg\:border-grey-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:border-grey-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .lg\:border-grey-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:border-grey-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .lg\:border-grey-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .lg\:border-grey {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:border-grey-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .lg\:border-grey-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .lg\:border-grey-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .lg\:border-grey-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .lg\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .lg\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .lg\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .lg\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .lg\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .lg\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .lg\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .lg\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:border-gray-hover {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .lg\:border-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .lg\:border-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .lg\:border-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .lg\:border-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .lg\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .lg\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .lg\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .lg\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .lg\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .lg\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .lg\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .lg\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .lg\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .lg\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .lg\:border-red-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .lg\:border-red-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .lg\:border-red-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .lg\:border-red-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .lg\:border-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .lg\:border-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .lg\:border-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .lg\:border-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .lg\:border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .lg\:border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .lg\:border-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .lg\:border-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .lg\:border-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .lg\:border-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .lg\:border-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .lg\:border-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .lg\:border-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .lg\:border-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .lg\:border-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .lg\:border-deep-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .lg\:border-deep-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .lg\:border-deep-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .lg\:border-deep-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .lg\:border-deep-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .lg\:border-deep-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .lg\:border-deep-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .lg\:border-deep-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .lg\:border-deep-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .lg\:border-deep-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .lg\:border-deep-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .lg\:border-deep-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .lg\:border-deep-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .lg\:border-deep-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .lg\:border-deep-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .lg\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .lg\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .lg\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .lg\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .lg\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .lg\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .lg\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .lg\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .lg\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .lg\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .lg\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .lg\:border-yellow-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .lg\:border-yellow-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .lg\:border-yellow-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .lg\:border-yellow-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .lg\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .lg\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .lg\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .lg\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .lg\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .lg\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .lg\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .lg\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .lg\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .lg\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .lg\:border-green {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .lg\:border-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .lg\:border-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .lg\:border-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .lg\:border-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .lg\:border-light-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .lg\:border-light-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .lg\:border-light-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .lg\:border-light-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .lg\:border-light-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .lg\:border-light-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .lg\:border-light-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .lg\:border-light-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .lg\:border-light-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .lg\:border-light-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .lg\:border-light-green {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .lg\:border-light-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .lg\:border-light-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .lg\:border-light-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .lg\:border-light-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .lg\:border-teal-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .lg\:border-teal-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .lg\:border-teal-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .lg\:border-teal-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .lg\:border-teal-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .lg\:border-teal-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .lg\:border-teal-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .lg\:border-teal-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .lg\:border-teal-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .lg\:border-teal-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .lg\:border-teal {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .lg\:border-teal-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .lg\:border-teal-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .lg\:border-teal-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .lg\:border-teal-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .lg\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .lg\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .lg\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .lg\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .lg\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .lg\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .lg\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .lg\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .lg\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .lg\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .lg\:border-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .lg\:border-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .lg\:border-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .lg\:border-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .lg\:border-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .lg\:border-light-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .lg\:border-light-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .lg\:border-light-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .lg\:border-light-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .lg\:border-light-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .lg\:border-light-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .lg\:border-light-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .lg\:border-light-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .lg\:border-light-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .lg\:border-light-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .lg\:border-light-blue {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .lg\:border-light-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .lg\:border-light-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .lg\:border-light-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .lg\:border-light-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .lg\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .lg\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .lg\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .lg\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .lg\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .lg\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .lg\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .lg\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .lg\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .lg\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .lg\:border-indigo {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .lg\:border-indigo-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .lg\:border-indigo-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .lg\:border-indigo-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .lg\:border-indigo-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .lg\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .lg\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .lg\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .lg\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .lg\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .lg\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .lg\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .lg\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .lg\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .lg\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .lg\:border-purple {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .lg\:border-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .lg\:border-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .lg\:border-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .lg\:border-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .lg\:border-deep-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .lg\:border-deep-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .lg\:border-deep-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .lg\:border-deep-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .lg\:border-deep-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .lg\:border-deep-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .lg\:border-deep-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .lg\:border-deep-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .lg\:border-deep-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .lg\:border-deep-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .lg\:border-deep-purple {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .lg\:border-deep-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .lg\:border-deep-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .lg\:border-deep-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .lg\:border-deep-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .lg\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .lg\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .lg\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .lg\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .lg\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .lg\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .lg\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .lg\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .lg\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .lg\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .lg\:border-pink {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .lg\:border-pink-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .lg\:border-pink-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .lg\:border-pink-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .lg\:border-pink-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .lg\:border-lime-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .lg\:border-lime-100 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .lg\:border-lime-200 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .lg\:border-lime-300 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .lg\:border-lime-400 {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .lg\:border-lime-500 {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .lg\:border-lime-600 {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .lg\:border-lime-700 {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .lg\:border-lime-800 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .lg\:border-lime-900 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .lg\:border-lime {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .lg\:border-lime-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .lg\:border-lime-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .lg\:border-lime-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .lg\:border-lime-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .lg\:border-amber-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .lg\:border-amber-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .lg\:border-amber-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .lg\:border-amber-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .lg\:border-amber-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .lg\:border-amber-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .lg\:border-amber-600 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .lg\:border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .lg\:border-amber-800 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .lg\:border-amber-900 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .lg\:border-amber {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .lg\:border-amber-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .lg\:border-amber-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .lg\:border-amber-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .lg\:border-amber-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .lg\:border-brown-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .lg\:border-brown-100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .lg\:border-brown-200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .lg\:border-brown-300 {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .lg\:border-brown-400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .lg\:border-brown-500 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .lg\:border-brown-600 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .lg\:border-brown-700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .lg\:border-brown-800 {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .lg\:border-brown-900 {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .lg\:border-brown {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .lg\:border-brown-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .lg\:border-brown-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .lg\:border-brown-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .lg\:border-brown-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .lg\:border-blue-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .lg\:border-blue-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .lg\:border-blue-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .lg\:border-blue-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .lg\:border-blue-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .lg\:border-blue-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .lg\:border-blue-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .lg\:border-blue-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .lg\:border-blue-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .lg\:border-blue-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .lg\:border-blue-gray {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .lg\:border-blue-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .lg\:border-blue-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .lg\:border-blue-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .lg\:border-blue-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .lg\:border-cyan-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .lg\:border-cyan-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .lg\:border-cyan-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .lg\:border-cyan-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .lg\:border-cyan-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .lg\:border-cyan-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .lg\:border-cyan-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .lg\:border-cyan-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .lg\:border-cyan-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .lg\:border-cyan-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .lg\:border-cyan {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .lg\:border-cyan-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .lg\:border-cyan-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .lg\:border-cyan-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .lg\:border-cyan-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-transparent {
    border-color: transparent
  }

  .group:hover .lg\:group-hover\:border-current {
    border-color: currentColor
  }

  .group:hover .lg\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-grey-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-grey-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-grey-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-grey-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-grey-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-grey-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-grey-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-grey-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-grey-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-grey-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-grey {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-grey-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-grey-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-grey-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-grey-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-gray-hover {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .group:hover .lg\:group-hover\:border-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-red-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-red-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-red-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-red-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-yellow-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-yellow-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-yellow-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-yellow-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-green {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-green {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-teal-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-teal-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-teal-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-teal-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-teal-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-teal-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-teal-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-teal-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-teal-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-teal-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-teal {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-teal-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-teal-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-teal-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-teal-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-blue {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-light-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-indigo {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-indigo-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-indigo-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-indigo-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-indigo-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-purple {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-purple {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-deep-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-pink {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-pink-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-pink-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-pink-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-pink-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-lime-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-lime-100 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-lime-200 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-lime-300 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-lime-400 {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-lime-500 {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-lime-600 {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-lime-700 {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-lime-800 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-lime-900 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-lime {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-lime-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-lime-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-lime-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-lime-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-amber-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-amber-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-amber-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-amber-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-amber-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-amber-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-amber-600 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-amber-800 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-amber-900 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-amber {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-amber-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-amber-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-amber-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-amber-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-brown-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-brown-100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-brown-200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-brown-300 {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-brown-400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-brown-500 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-brown-600 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-brown-700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-brown-800 {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-brown-900 {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-brown {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-brown-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-brown-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-brown-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-brown-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-gray {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-blue-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-cyan-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-cyan-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-cyan-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-cyan-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-cyan-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-cyan-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-cyan-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-cyan-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-cyan-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-cyan-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-cyan {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-cyan-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-cyan-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-cyan-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .group:hover .lg\:group-hover\:border-cyan-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-transparent:focus-within {
    border-color: transparent
  }

  .lg\:focus-within\:border-current:focus-within {
    border-color: currentColor
  }

  .lg\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-grey-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-grey-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-grey-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-grey-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-grey-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-grey-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-grey-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-grey-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-grey-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-grey-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-grey-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-grey-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-grey-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-grey-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-gray-hover:focus-within {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .lg\:focus-within\:border-gray-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-gray-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-gray-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-gray-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-red:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-red-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-red-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-red-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-red-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-orange-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-orange-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-orange-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-orange-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-orange-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-orange-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-orange-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-orange-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-orange-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-orange-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-orange:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-orange-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-orange-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-orange-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-orange-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-orange-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-orange-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-orange-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-orange-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-orange-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-orange-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-orange-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-orange-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-orange-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-orange-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-orange:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-orange-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-orange-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-orange-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-orange-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-yellow:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-yellow-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-yellow-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-yellow-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-yellow-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-green-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-green-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-green-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-green-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-green-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-green-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-green-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-green-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-teal-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-teal-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-teal-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-teal-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-teal-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-teal-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-teal-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-teal-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-teal-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-teal-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-teal:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-teal-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-teal-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-teal-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-teal-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-blue-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-blue-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-blue-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-light-blue-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-indigo:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-indigo-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-indigo-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-indigo-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-indigo-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-purple:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-purple-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-purple-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-purple-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-purple-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-purple:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-purple-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-purple-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-purple-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-deep-purple-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-pink:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-pink-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-pink-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-pink-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-pink-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-lime-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-lime-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-lime-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-lime-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-lime-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-lime-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-lime-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-lime-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-lime-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-lime-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-lime:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-lime-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-lime-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-lime-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-lime-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-amber-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-amber-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-amber-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-amber-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-amber-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-amber-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-amber-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-amber-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-amber-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-amber-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-amber:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-amber-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-amber-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-amber-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-amber-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-brown-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-brown-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-brown-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-brown-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-brown-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-brown-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-brown-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-brown-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-brown-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-brown-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-brown:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-brown-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-brown-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-brown-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-brown-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-gray-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-gray-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-gray-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-blue-gray-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-cyan-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-cyan-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-cyan-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-cyan-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-cyan-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-cyan-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-cyan-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-cyan-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-cyan-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-cyan-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-cyan:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-cyan-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-cyan-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-cyan-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .lg\:focus-within\:border-cyan-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .lg\:hover\:border-transparent:hover {
    border-color: transparent
  }

  .lg\:hover\:border-current:hover {
    border-color: currentColor
  }

  .lg\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .lg\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-grey-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .lg\:hover\:border-grey-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .lg\:hover\:border-grey-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .lg\:hover\:border-grey-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .lg\:hover\:border-grey-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .lg\:hover\:border-grey-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:hover\:border-grey-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .lg\:hover\:border-grey-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:hover\:border-grey-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .lg\:hover\:border-grey-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .lg\:hover\:border-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:hover\:border-grey-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .lg\:hover\:border-grey-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .lg\:hover\:border-grey-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .lg\:hover\:border-grey-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray-hover:hover {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .lg\:hover\:border-gray-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-orange-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .lg\:hover\:border-orange-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .lg\:hover\:border-orange-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .lg\:hover\:border-orange-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .lg\:hover\:border-orange-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .lg\:hover\:border-orange-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-orange-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-orange-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-orange-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-orange-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-orange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-orange-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .lg\:hover\:border-orange-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .lg\:hover\:border-orange-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-orange-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-orange-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-orange-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-orange-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-orange-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-orange-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-orange-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-orange-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-orange-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-orange-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-orange-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-orange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-orange-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-orange-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-orange-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-orange-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .lg\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .lg\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .lg\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .lg\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .lg\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .lg\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .lg\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .lg\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .lg\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .lg\:hover\:border-yellow:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .lg\:hover\:border-yellow-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .lg\:hover\:border-yellow-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-yellow-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-yellow-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-green-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-green-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-green-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-green-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .lg\:hover\:border-teal-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .lg\:hover\:border-teal-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .lg\:hover\:border-teal-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .lg\:hover\:border-teal-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .lg\:hover\:border-teal-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .lg\:hover\:border-teal-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .lg\:hover\:border-teal-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .lg\:hover\:border-teal-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .lg\:hover\:border-teal-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .lg\:hover\:border-teal-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .lg\:hover\:border-teal:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .lg\:hover\:border-teal-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .lg\:hover\:border-teal-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .lg\:hover\:border-teal-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .lg\:hover\:border-teal-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-blue-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-blue-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-blue-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-light-blue-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .lg\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .lg\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .lg\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .lg\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .lg\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .lg\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .lg\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .lg\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .lg\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .lg\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .lg\:hover\:border-indigo:hover {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .lg\:hover\:border-indigo-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-indigo-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .lg\:hover\:border-indigo-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .lg\:hover\:border-indigo-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .lg\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .lg\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .lg\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .lg\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .lg\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .lg\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .lg\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .lg\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .lg\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .lg\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .lg\:hover\:border-purple:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .lg\:hover\:border-purple-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .lg\:hover\:border-purple-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .lg\:hover\:border-purple-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .lg\:hover\:border-purple-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-purple:hover {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-purple-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-purple-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-purple-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-deep-purple-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .lg\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .lg\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .lg\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .lg\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .lg\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .lg\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .lg\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .lg\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .lg\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .lg\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .lg\:hover\:border-pink:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .lg\:hover\:border-pink-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .lg\:hover\:border-pink-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .lg\:hover\:border-pink-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .lg\:hover\:border-pink-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .lg\:hover\:border-lime-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .lg\:hover\:border-lime-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .lg\:hover\:border-lime-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .lg\:hover\:border-lime-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .lg\:hover\:border-lime-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .lg\:hover\:border-lime-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .lg\:hover\:border-lime-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .lg\:hover\:border-lime-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .lg\:hover\:border-lime-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .lg\:hover\:border-lime-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .lg\:hover\:border-lime:hover {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .lg\:hover\:border-lime-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .lg\:hover\:border-lime-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .lg\:hover\:border-lime-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-lime-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-amber-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .lg\:hover\:border-amber-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .lg\:hover\:border-amber-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .lg\:hover\:border-amber-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .lg\:hover\:border-amber-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .lg\:hover\:border-amber-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .lg\:hover\:border-amber-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-amber-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-amber-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-amber-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-amber:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .lg\:hover\:border-amber-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .lg\:hover\:border-amber-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .lg\:hover\:border-amber-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-amber-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-brown-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .lg\:hover\:border-brown-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .lg\:hover\:border-brown-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .lg\:hover\:border-brown-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .lg\:hover\:border-brown-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .lg\:hover\:border-brown-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .lg\:hover\:border-brown-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .lg\:hover\:border-brown-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .lg\:hover\:border-brown-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .lg\:hover\:border-brown-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .lg\:hover\:border-brown:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .lg\:hover\:border-brown-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .lg\:hover\:border-brown-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .lg\:hover\:border-brown-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .lg\:hover\:border-brown-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-gray-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-gray-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-gray-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .lg\:hover\:border-blue-gray-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .lg\:focus\:border-transparent:focus {
    border-color: transparent
  }

  .lg\:focus\:border-current:focus {
    border-color: currentColor
  }

  .lg\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .lg\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-grey-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .lg\:focus\:border-grey-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .lg\:focus\:border-grey-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .lg\:focus\:border-grey-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .lg\:focus\:border-grey-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .lg\:focus\:border-grey-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:focus\:border-grey-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .lg\:focus\:border-grey-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:focus\:border-grey-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .lg\:focus\:border-grey-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .lg\:focus\:border-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:focus\:border-grey-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .lg\:focus\:border-grey-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .lg\:focus\:border-grey-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .lg\:focus\:border-grey-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray-hover:focus {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .lg\:focus\:border-gray-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-orange-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .lg\:focus\:border-orange-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .lg\:focus\:border-orange-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .lg\:focus\:border-orange-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .lg\:focus\:border-orange-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .lg\:focus\:border-orange-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-orange-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-orange-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-orange-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-orange-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-orange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-orange-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .lg\:focus\:border-orange-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .lg\:focus\:border-orange-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-orange-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-orange-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-orange-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-orange-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-orange-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-orange-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-orange-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-orange-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-orange-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-orange-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-orange-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-orange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-orange-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-orange-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-orange-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-orange-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .lg\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .lg\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .lg\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .lg\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .lg\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .lg\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .lg\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .lg\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .lg\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .lg\:focus\:border-yellow:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .lg\:focus\:border-yellow-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .lg\:focus\:border-yellow-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-yellow-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-yellow-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-green-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-green-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-green-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-green-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .lg\:focus\:border-teal-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .lg\:focus\:border-teal-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .lg\:focus\:border-teal-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .lg\:focus\:border-teal-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .lg\:focus\:border-teal-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .lg\:focus\:border-teal-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .lg\:focus\:border-teal-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .lg\:focus\:border-teal-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .lg\:focus\:border-teal-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .lg\:focus\:border-teal-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .lg\:focus\:border-teal:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .lg\:focus\:border-teal-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .lg\:focus\:border-teal-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .lg\:focus\:border-teal-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .lg\:focus\:border-teal-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-blue-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-blue-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-blue-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-light-blue-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .lg\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .lg\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .lg\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .lg\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .lg\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .lg\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .lg\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .lg\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .lg\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .lg\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .lg\:focus\:border-indigo:focus {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .lg\:focus\:border-indigo-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-indigo-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .lg\:focus\:border-indigo-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .lg\:focus\:border-indigo-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .lg\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .lg\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .lg\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .lg\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .lg\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .lg\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .lg\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .lg\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .lg\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .lg\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .lg\:focus\:border-purple:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .lg\:focus\:border-purple-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .lg\:focus\:border-purple-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .lg\:focus\:border-purple-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .lg\:focus\:border-purple-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-purple:focus {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-purple-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-purple-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-purple-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-deep-purple-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .lg\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .lg\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .lg\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .lg\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .lg\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .lg\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .lg\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .lg\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .lg\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .lg\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .lg\:focus\:border-pink:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .lg\:focus\:border-pink-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .lg\:focus\:border-pink-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .lg\:focus\:border-pink-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .lg\:focus\:border-pink-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .lg\:focus\:border-lime-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .lg\:focus\:border-lime-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .lg\:focus\:border-lime-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .lg\:focus\:border-lime-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .lg\:focus\:border-lime-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .lg\:focus\:border-lime-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .lg\:focus\:border-lime-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .lg\:focus\:border-lime-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .lg\:focus\:border-lime-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .lg\:focus\:border-lime-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .lg\:focus\:border-lime:focus {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .lg\:focus\:border-lime-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .lg\:focus\:border-lime-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .lg\:focus\:border-lime-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-lime-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-amber-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .lg\:focus\:border-amber-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .lg\:focus\:border-amber-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .lg\:focus\:border-amber-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .lg\:focus\:border-amber-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .lg\:focus\:border-amber-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .lg\:focus\:border-amber-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-amber-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-amber-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-amber-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-amber:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .lg\:focus\:border-amber-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .lg\:focus\:border-amber-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .lg\:focus\:border-amber-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-amber-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-brown-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .lg\:focus\:border-brown-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .lg\:focus\:border-brown-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .lg\:focus\:border-brown-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .lg\:focus\:border-brown-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .lg\:focus\:border-brown-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .lg\:focus\:border-brown-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .lg\:focus\:border-brown-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .lg\:focus\:border-brown-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .lg\:focus\:border-brown-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .lg\:focus\:border-brown:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .lg\:focus\:border-brown-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .lg\:focus\:border-brown-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .lg\:focus\:border-brown-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .lg\:focus\:border-brown-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-gray-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-gray-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-gray-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .lg\:focus\:border-blue-gray-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .lg\:border-opacity-0 {
    --tw-border-opacity: 0
  }

  .lg\:border-opacity-5 {
    --tw-border-opacity: 0.05
  }

  .lg\:border-opacity-10 {
    --tw-border-opacity: 0.1
  }

  .lg\:border-opacity-20 {
    --tw-border-opacity: 0.2
  }

  .lg\:border-opacity-25 {
    --tw-border-opacity: 0.25
  }

  .lg\:border-opacity-30 {
    --tw-border-opacity: 0.3
  }

  .lg\:border-opacity-40 {
    --tw-border-opacity: 0.4
  }

  .lg\:border-opacity-50 {
    --tw-border-opacity: 0.5
  }

  .lg\:border-opacity-60 {
    --tw-border-opacity: 0.6
  }

  .lg\:border-opacity-70 {
    --tw-border-opacity: 0.7
  }

  .lg\:border-opacity-75 {
    --tw-border-opacity: 0.75
  }

  .lg\:border-opacity-80 {
    --tw-border-opacity: 0.8
  }

  .lg\:border-opacity-90 {
    --tw-border-opacity: 0.9
  }

  .lg\:border-opacity-95 {
    --tw-border-opacity: 0.95
  }

  .lg\:border-opacity-100 {
    --tw-border-opacity: 1
  }

  .group:hover .lg\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0
  }

  .group:hover .lg\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05
  }

  .group:hover .lg\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1
  }

  .group:hover .lg\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2
  }

  .group:hover .lg\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25
  }

  .group:hover .lg\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3
  }

  .group:hover .lg\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4
  }

  .group:hover .lg\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5
  }

  .group:hover .lg\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6
  }

  .group:hover .lg\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7
  }

  .group:hover .lg\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75
  }

  .group:hover .lg\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8
  }

  .group:hover .lg\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9
  }

  .group:hover .lg\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95
  }

  .group:hover .lg\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1
  }

  .lg\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0
  }

  .lg\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05
  }

  .lg\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1
  }

  .lg\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2
  }

  .lg\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25
  }

  .lg\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3
  }

  .lg\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4
  }

  .lg\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5
  }

  .lg\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6
  }

  .lg\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7
  }

  .lg\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75
  }

  .lg\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8
  }

  .lg\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9
  }

  .lg\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95
  }

  .lg\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1
  }

  .lg\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0
  }

  .lg\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05
  }

  .lg\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1
  }

  .lg\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2
  }

  .lg\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25
  }

  .lg\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3
  }

  .lg\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4
  }

  .lg\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5
  }

  .lg\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6
  }

  .lg\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7
  }

  .lg\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75
  }

  .lg\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8
  }

  .lg\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9
  }

  .lg\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95
  }

  .lg\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1
  }

  .lg\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0
  }

  .lg\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05
  }

  .lg\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1
  }

  .lg\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2
  }

  .lg\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25
  }

  .lg\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3
  }

  .lg\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4
  }

  .lg\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5
  }

  .lg\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6
  }

  .lg\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7
  }

  .lg\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75
  }

  .lg\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8
  }

  .lg\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9
  }

  .lg\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95
  }

  .lg\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1
  }

  .lg\:rounded-2 {
    border-radius: .2rem
  }

  .lg\:rounded-4 {
    border-radius: .4rem
  }

  .lg\:rounded-6 {
    border-radius: .6rem
  }

  .lg\:rounded-8 {
    border-radius: .8rem
  }

  .lg\:rounded-12 {
    border-radius: 1.2rem
  }

  .lg\:rounded-16 {
    border-radius: 1.6rem
  }

  .lg\:rounded-20 {
    border-radius: 2rem
  }

  .lg\:rounded-24 {
    border-radius: 2.4rem
  }

  .lg\:rounded-28 {
    border-radius: 2.8rem
  }

  .lg\:rounded-32 {
    border-radius: 3.2rem
  }

  .lg\:rounded-none {
    border-radius: 0
  }

  .lg\:rounded-sm {
    border-radius: .2rem
  }

  .lg\:rounded {
    border-radius: .4rem
  }

  .lg\:rounded-md {
    border-radius: .6rem
  }

  .lg\:rounded-lg {
    border-radius: .8rem
  }

  .lg\:rounded-xl {
    border-radius: 1.2rem
  }

  .lg\:rounded-2xl {
    border-radius: 1.6rem
  }

  .lg\:rounded-3xl {
    border-radius: 2.4rem
  }

  .lg\:rounded-full {
    border-radius: 9999px
  }

  .lg\:rounded-t-2 {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem
  }

  .lg\:rounded-r-2 {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
  }

  .lg\:rounded-b-2 {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .lg\:rounded-l-2 {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .lg\:rounded-t-4 {
    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem
  }

  .lg\:rounded-r-4 {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem
  }

  .lg\:rounded-b-4 {
    border-bottom-right-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .lg\:rounded-l-4 {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .lg\:rounded-t-6 {
    border-top-left-radius: .6rem;
    border-top-right-radius: .6rem
  }

  .lg\:rounded-r-6 {
    border-top-right-radius: .6rem;
    border-bottom-right-radius: .6rem
  }

  .lg\:rounded-b-6 {
    border-bottom-right-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .lg\:rounded-l-6 {
    border-top-left-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .lg\:rounded-t-8 {
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem
  }

  .lg\:rounded-r-8 {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem
  }

  .lg\:rounded-b-8 {
    border-bottom-right-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .lg\:rounded-l-8 {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .lg\:rounded-t-12 {
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem
  }

  .lg\:rounded-r-12 {
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem
  }

  .lg\:rounded-b-12 {
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .lg\:rounded-l-12 {
    border-top-left-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .lg\:rounded-t-16 {
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem
  }

  .lg\:rounded-r-16 {
    border-top-right-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem
  }

  .lg\:rounded-b-16 {
    border-bottom-right-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .lg\:rounded-l-16 {
    border-top-left-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .lg\:rounded-t-20 {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem
  }

  .lg\:rounded-r-20 {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem
  }

  .lg\:rounded-b-20 {
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem
  }

  .lg\:rounded-l-20 {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem
  }

  .lg\:rounded-t-24 {
    border-top-left-radius: 2.4rem;
    border-top-right-radius: 2.4rem
  }

  .lg\:rounded-r-24 {
    border-top-right-radius: 2.4rem;
    border-bottom-right-radius: 2.4rem
  }

  .lg\:rounded-b-24 {
    border-bottom-right-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .lg\:rounded-l-24 {
    border-top-left-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .lg\:rounded-t-28 {
    border-top-left-radius: 2.8rem;
    border-top-right-radius: 2.8rem
  }

  .lg\:rounded-r-28 {
    border-top-right-radius: 2.8rem;
    border-bottom-right-radius: 2.8rem
  }

  .lg\:rounded-b-28 {
    border-bottom-right-radius: 2.8rem;
    border-bottom-left-radius: 2.8rem
  }

  .lg\:rounded-l-28 {
    border-top-left-radius: 2.8rem;
    border-bottom-left-radius: 2.8rem
  }

  .lg\:rounded-t-32 {
    border-top-left-radius: 3.2rem;
    border-top-right-radius: 3.2rem
  }

  .lg\:rounded-r-32 {
    border-top-right-radius: 3.2rem;
    border-bottom-right-radius: 3.2rem
  }

  .lg\:rounded-b-32 {
    border-bottom-right-radius: 3.2rem;
    border-bottom-left-radius: 3.2rem
  }

  .lg\:rounded-l-32 {
    border-top-left-radius: 3.2rem;
    border-bottom-left-radius: 3.2rem
  }

  .lg\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .lg\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .lg\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .lg\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .lg\:rounded-t-sm {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem
  }

  .lg\:rounded-r-sm {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
  }

  .lg\:rounded-b-sm {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .lg\:rounded-l-sm {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .lg\:rounded-t {
    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem
  }

  .lg\:rounded-r {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem
  }

  .lg\:rounded-b {
    border-bottom-right-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .lg\:rounded-l {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .lg\:rounded-t-md {
    border-top-left-radius: .6rem;
    border-top-right-radius: .6rem
  }

  .lg\:rounded-r-md {
    border-top-right-radius: .6rem;
    border-bottom-right-radius: .6rem
  }

  .lg\:rounded-b-md {
    border-bottom-right-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .lg\:rounded-l-md {
    border-top-left-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .lg\:rounded-t-lg {
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem
  }

  .lg\:rounded-r-lg {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem
  }

  .lg\:rounded-b-lg {
    border-bottom-right-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .lg\:rounded-l-lg {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .lg\:rounded-t-xl {
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem
  }

  .lg\:rounded-r-xl {
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem
  }

  .lg\:rounded-b-xl {
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .lg\:rounded-l-xl {
    border-top-left-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .lg\:rounded-t-2xl {
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem
  }

  .lg\:rounded-r-2xl {
    border-top-right-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem
  }

  .lg\:rounded-b-2xl {
    border-bottom-right-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .lg\:rounded-l-2xl {
    border-top-left-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .lg\:rounded-t-3xl {
    border-top-left-radius: 2.4rem;
    border-top-right-radius: 2.4rem
  }

  .lg\:rounded-r-3xl {
    border-top-right-radius: 2.4rem;
    border-bottom-right-radius: 2.4rem
  }

  .lg\:rounded-b-3xl {
    border-bottom-right-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .lg\:rounded-l-3xl {
    border-top-left-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .lg\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .lg\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .lg\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .lg\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .lg\:rounded-tl-2 {
    border-top-left-radius: .2rem
  }

  .lg\:rounded-tr-2 {
    border-top-right-radius: .2rem
  }

  .lg\:rounded-br-2 {
    border-bottom-right-radius: .2rem
  }

  .lg\:rounded-bl-2 {
    border-bottom-left-radius: .2rem
  }

  .lg\:rounded-tl-4 {
    border-top-left-radius: .4rem
  }

  .lg\:rounded-tr-4 {
    border-top-right-radius: .4rem
  }

  .lg\:rounded-br-4 {
    border-bottom-right-radius: .4rem
  }

  .lg\:rounded-bl-4 {
    border-bottom-left-radius: .4rem
  }

  .lg\:rounded-tl-6 {
    border-top-left-radius: .6rem
  }

  .lg\:rounded-tr-6 {
    border-top-right-radius: .6rem
  }

  .lg\:rounded-br-6 {
    border-bottom-right-radius: .6rem
  }

  .lg\:rounded-bl-6 {
    border-bottom-left-radius: .6rem
  }

  .lg\:rounded-tl-8 {
    border-top-left-radius: .8rem
  }

  .lg\:rounded-tr-8 {
    border-top-right-radius: .8rem
  }

  .lg\:rounded-br-8 {
    border-bottom-right-radius: .8rem
  }

  .lg\:rounded-bl-8 {
    border-bottom-left-radius: .8rem
  }

  .lg\:rounded-tl-12 {
    border-top-left-radius: 1.2rem
  }

  .lg\:rounded-tr-12 {
    border-top-right-radius: 1.2rem
  }

  .lg\:rounded-br-12 {
    border-bottom-right-radius: 1.2rem
  }

  .lg\:rounded-bl-12 {
    border-bottom-left-radius: 1.2rem
  }

  .lg\:rounded-tl-16 {
    border-top-left-radius: 1.6rem
  }

  .lg\:rounded-tr-16 {
    border-top-right-radius: 1.6rem
  }

  .lg\:rounded-br-16 {
    border-bottom-right-radius: 1.6rem
  }

  .lg\:rounded-bl-16 {
    border-bottom-left-radius: 1.6rem
  }

  .lg\:rounded-tl-20 {
    border-top-left-radius: 2rem
  }

  .lg\:rounded-tr-20 {
    border-top-right-radius: 2rem
  }

  .lg\:rounded-br-20 {
    border-bottom-right-radius: 2rem
  }

  .lg\:rounded-bl-20 {
    border-bottom-left-radius: 2rem
  }

  .lg\:rounded-tl-24 {
    border-top-left-radius: 2.4rem
  }

  .lg\:rounded-tr-24 {
    border-top-right-radius: 2.4rem
  }

  .lg\:rounded-br-24 {
    border-bottom-right-radius: 2.4rem
  }

  .lg\:rounded-bl-24 {
    border-bottom-left-radius: 2.4rem
  }

  .lg\:rounded-tl-28 {
    border-top-left-radius: 2.8rem
  }

  .lg\:rounded-tr-28 {
    border-top-right-radius: 2.8rem
  }

  .lg\:rounded-br-28 {
    border-bottom-right-radius: 2.8rem
  }

  .lg\:rounded-bl-28 {
    border-bottom-left-radius: 2.8rem
  }

  .lg\:rounded-tl-32 {
    border-top-left-radius: 3.2rem
  }

  .lg\:rounded-tr-32 {
    border-top-right-radius: 3.2rem
  }

  .lg\:rounded-br-32 {
    border-bottom-right-radius: 3.2rem
  }

  .lg\:rounded-bl-32 {
    border-bottom-left-radius: 3.2rem
  }

  .lg\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .lg\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .lg\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .lg\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .lg\:rounded-tl-sm {
    border-top-left-radius: .2rem
  }

  .lg\:rounded-tr-sm {
    border-top-right-radius: .2rem
  }

  .lg\:rounded-br-sm {
    border-bottom-right-radius: .2rem
  }

  .lg\:rounded-bl-sm {
    border-bottom-left-radius: .2rem
  }

  .lg\:rounded-tl {
    border-top-left-radius: .4rem
  }

  .lg\:rounded-tr {
    border-top-right-radius: .4rem
  }

  .lg\:rounded-br {
    border-bottom-right-radius: .4rem
  }

  .lg\:rounded-bl {
    border-bottom-left-radius: .4rem
  }

  .lg\:rounded-tl-md {
    border-top-left-radius: .6rem
  }

  .lg\:rounded-tr-md {
    border-top-right-radius: .6rem
  }

  .lg\:rounded-br-md {
    border-bottom-right-radius: .6rem
  }

  .lg\:rounded-bl-md {
    border-bottom-left-radius: .6rem
  }

  .lg\:rounded-tl-lg {
    border-top-left-radius: .8rem
  }

  .lg\:rounded-tr-lg {
    border-top-right-radius: .8rem
  }

  .lg\:rounded-br-lg {
    border-bottom-right-radius: .8rem
  }

  .lg\:rounded-bl-lg {
    border-bottom-left-radius: .8rem
  }

  .lg\:rounded-tl-xl {
    border-top-left-radius: 1.2rem
  }

  .lg\:rounded-tr-xl {
    border-top-right-radius: 1.2rem
  }

  .lg\:rounded-br-xl {
    border-bottom-right-radius: 1.2rem
  }

  .lg\:rounded-bl-xl {
    border-bottom-left-radius: 1.2rem
  }

  .lg\:rounded-tl-2xl {
    border-top-left-radius: 1.6rem
  }

  .lg\:rounded-tr-2xl {
    border-top-right-radius: 1.6rem
  }

  .lg\:rounded-br-2xl {
    border-bottom-right-radius: 1.6rem
  }

  .lg\:rounded-bl-2xl {
    border-bottom-left-radius: 1.6rem
  }

  .lg\:rounded-tl-3xl {
    border-top-left-radius: 2.4rem
  }

  .lg\:rounded-tr-3xl {
    border-top-right-radius: 2.4rem
  }

  .lg\:rounded-br-3xl {
    border-bottom-right-radius: 2.4rem
  }

  .lg\:rounded-bl-3xl {
    border-bottom-left-radius: 2.4rem
  }

  .lg\:rounded-tl-full {
    border-top-left-radius: 9999px
  }

  .lg\:rounded-tr-full {
    border-top-right-radius: 9999px
  }

  .lg\:rounded-br-full {
    border-bottom-right-radius: 9999px
  }

  .lg\:rounded-bl-full {
    border-bottom-left-radius: 9999px
  }

  .lg\:border-solid {
    border-style: solid
  }

  .lg\:border-dashed {
    border-style: dashed
  }

  .lg\:border-dotted {
    border-style: dotted
  }

  .lg\:border-double {
    border-style: double
  }

  .lg\:border-none {
    border-style: none
  }

  .lg\:border-0 {
    border-width: 0px
  }

  .lg\:border-1 {
    border-width: 1px
  }

  .lg\:border-2 {
    border-width: 2px
  }

  .lg\:border-3 {
    border-width: 3px
  }

  .lg\:border-4 {
    border-width: 4px
  }

  .lg\:border-8 {
    border-width: 8px
  }

  .lg\:border {
    border-width: 1px
  }

  .lg\:border-t-0 {
    border-top-width: 0px
  }

  .lg\:border-r-0 {
    border-right-width: 0px
  }

  .lg\:border-b-0 {
    border-bottom-width: 0px
  }

  .lg\:border-l-0 {
    border-left-width: 0px
  }

  .lg\:border-t-1 {
    border-top-width: 1px
  }

  .lg\:border-r-1 {
    border-right-width: 1px
  }

  .lg\:border-b-1 {
    border-bottom-width: 1px
  }

  .lg\:border-l-1 {
    border-left-width: 1px
  }

  .lg\:border-t-2 {
    border-top-width: 2px
  }

  .lg\:border-r-2 {
    border-right-width: 2px
  }

  .lg\:border-b-2 {
    border-bottom-width: 2px
  }

  .lg\:border-l-2 {
    border-left-width: 2px
  }

  .lg\:border-t-3 {
    border-top-width: 3px
  }

  .lg\:border-r-3 {
    border-right-width: 3px
  }

  .lg\:border-b-3 {
    border-bottom-width: 3px
  }

  .lg\:border-l-3 {
    border-left-width: 3px
  }

  .lg\:border-t-4 {
    border-top-width: 4px
  }

  .lg\:border-r-4 {
    border-right-width: 4px
  }

  .lg\:border-b-4 {
    border-bottom-width: 4px
  }

  .lg\:border-l-4 {
    border-left-width: 4px
  }

  .lg\:border-t-8 {
    border-top-width: 8px
  }

  .lg\:border-r-8 {
    border-right-width: 8px
  }

  .lg\:border-b-8 {
    border-bottom-width: 8px
  }

  .lg\:border-l-8 {
    border-left-width: 8px
  }

  .lg\:border-t {
    border-top-width: 1px
  }

  .lg\:border-r {
    border-right-width: 1px
  }

  .lg\:border-b {
    border-bottom-width: 1px
  }

  .lg\:border-l {
    border-left-width: 1px
  }

  .lg\:box-border {
    box-sizing: border-box
  }

  .lg\:box-content {
    box-sizing: content-box
  }

  .lg\:cursor-auto {
    cursor: auto
  }

  .lg\:cursor-default {
    cursor: default
  }

  .lg\:cursor-pointer {
    cursor: pointer
  }

  .lg\:cursor-wait {
    cursor: wait
  }

  .lg\:cursor-text {
    cursor: text
  }

  .lg\:cursor-move {
    cursor: move
  }

  .lg\:cursor-not-allowed {
    cursor: not-allowed
  }

  .lg\:block {
    display: block
  }

  .lg\:inline-block {
    display: inline-block
  }

  .lg\:inline {
    display: inline
  }

  .lg\:flex {
    display: flex
  }

  .lg\:inline-flex {
    display: inline-flex
  }

  .lg\:table {
    display: table
  }

  .lg\:table-caption {
    display: table-caption
  }

  .lg\:table-cell {
    display: table-cell
  }

  .lg\:table-column {
    display: table-column
  }

  .lg\:table-column-group {
    display: table-column-group
  }

  .lg\:table-footer-group {
    display: table-footer-group
  }

  .lg\:table-header-group {
    display: table-header-group
  }

  .lg\:table-row-group {
    display: table-row-group
  }

  .lg\:table-row {
    display: table-row
  }

  .lg\:flow-root {
    display: flow-root
  }

  .lg\:grid {
    display: grid
  }

  .lg\:inline-grid {
    display: inline-grid
  }

  .lg\:contents {
    display: contents
  }

  .lg\:hidden {
    display: none
  }

  .lg\:hover\:block:hover {
    display: block
  }

  .lg\:hover\:inline-block:hover {
    display: inline-block
  }

  .lg\:hover\:inline:hover {
    display: inline
  }

  .lg\:hover\:flex:hover {
    display: flex
  }

  .lg\:hover\:inline-flex:hover {
    display: inline-flex
  }

  .lg\:hover\:table:hover {
    display: table
  }

  .lg\:hover\:table-caption:hover {
    display: table-caption
  }

  .lg\:hover\:table-cell:hover {
    display: table-cell
  }

  .lg\:hover\:table-column:hover {
    display: table-column
  }

  .lg\:hover\:table-column-group:hover {
    display: table-column-group
  }

  .lg\:hover\:table-footer-group:hover {
    display: table-footer-group
  }

  .lg\:hover\:table-header-group:hover {
    display: table-header-group
  }

  .lg\:hover\:table-row-group:hover {
    display: table-row-group
  }

  .lg\:hover\:table-row:hover {
    display: table-row
  }

  .lg\:hover\:flow-root:hover {
    display: flow-root
  }

  .lg\:hover\:grid:hover {
    display: grid
  }

  .lg\:hover\:inline-grid:hover {
    display: inline-grid
  }

  .lg\:hover\:contents:hover {
    display: contents
  }

  .lg\:hover\:hidden:hover {
    display: none
  }

  .lg\:focus\:block:focus {
    display: block
  }

  .lg\:focus\:inline-block:focus {
    display: inline-block
  }

  .lg\:focus\:inline:focus {
    display: inline
  }

  .lg\:focus\:flex:focus {
    display: flex
  }

  .lg\:focus\:inline-flex:focus {
    display: inline-flex
  }

  .lg\:focus\:table:focus {
    display: table
  }

  .lg\:focus\:table-caption:focus {
    display: table-caption
  }

  .lg\:focus\:table-cell:focus {
    display: table-cell
  }

  .lg\:focus\:table-column:focus {
    display: table-column
  }

  .lg\:focus\:table-column-group:focus {
    display: table-column-group
  }

  .lg\:focus\:table-footer-group:focus {
    display: table-footer-group
  }

  .lg\:focus\:table-header-group:focus {
    display: table-header-group
  }

  .lg\:focus\:table-row-group:focus {
    display: table-row-group
  }

  .lg\:focus\:table-row:focus {
    display: table-row
  }

  .lg\:focus\:flow-root:focus {
    display: flow-root
  }

  .lg\:focus\:grid:focus {
    display: grid
  }

  .lg\:focus\:inline-grid:focus {
    display: inline-grid
  }

  .lg\:focus\:contents:focus {
    display: contents
  }

  .lg\:focus\:hidden:focus {
    display: none
  }

  .lg\:flex-row {
    flex-direction: row
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .lg\:flex-col {
    flex-direction: column
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .lg\:flex-wrap {
    flex-wrap: wrap
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap
  }

  .lg\:place-items-auto {
    place-items: auto
  }

  .lg\:place-items-start {
    place-items: start
  }

  .lg\:place-items-end {
    place-items: end
  }

  .lg\:place-items-center {
    place-items: center
  }

  .lg\:place-items-stretch {
    place-items: stretch
  }

  .lg\:place-content-center {
    place-content: center
  }

  .lg\:place-content-start {
    place-content: start
  }

  .lg\:place-content-end {
    place-content: end
  }

  .lg\:place-content-between {
    place-content: space-between
  }

  .lg\:place-content-around {
    place-content: space-around
  }

  .lg\:place-content-evenly {
    place-content: space-evenly
  }

  .lg\:place-content-stretch {
    place-content: stretch
  }

  .lg\:place-self-auto {
    place-self: auto
  }

  .lg\:place-self-start {
    place-self: start
  }

  .lg\:place-self-end {
    place-self: end
  }

  .lg\:place-self-center {
    place-self: center
  }

  .lg\:place-self-stretch {
    place-self: stretch
  }

  .lg\:items-start {
    align-items: flex-start
  }

  .lg\:items-end {
    align-items: flex-end
  }

  .lg\:items-center {
    align-items: center
  }

  .lg\:items-baseline {
    align-items: baseline
  }

  .lg\:items-stretch {
    align-items: stretch
  }

  .lg\:content-center {
    align-content: center
  }

  .lg\:content-start {
    align-content: flex-start
  }

  .lg\:content-end {
    align-content: flex-end
  }

  .lg\:content-between {
    align-content: space-between
  }

  .lg\:content-around {
    align-content: space-around
  }

  .lg\:content-evenly {
    align-content: space-evenly
  }

  .lg\:self-auto {
    align-self: auto
  }

  .lg\:self-start {
    align-self: flex-start
  }

  .lg\:self-end {
    align-self: flex-end
  }

  .lg\:self-center {
    align-self: center
  }

  .lg\:self-stretch {
    align-self: stretch
  }

  .lg\:justify-items-auto {
    justify-items: auto
  }

  .lg\:justify-items-start {
    justify-items: start
  }

  .lg\:justify-items-end {
    justify-items: end
  }

  .lg\:justify-items-center {
    justify-items: center
  }

  .lg\:justify-items-stretch {
    justify-items: stretch
  }

  .lg\:justify-start {
    justify-content: flex-start
  }

  .lg\:justify-end {
    justify-content: flex-end
  }

  .lg\:justify-center {
    justify-content: center
  }

  .lg\:justify-between {
    justify-content: space-between
  }

  .lg\:justify-around {
    justify-content: space-around
  }

  .lg\:justify-evenly {
    justify-content: space-evenly
  }

  .lg\:justify-self-auto {
    justify-self: auto
  }

  .lg\:justify-self-start {
    justify-self: start
  }

  .lg\:justify-self-end {
    justify-self: end
  }

  .lg\:justify-self-center {
    justify-self: center
  }

  .lg\:justify-self-stretch {
    justify-self: stretch
  }

  .lg\:flex-1 {
    flex: 1 1 0%
  }

  .lg\:flex-auto {
    flex: 1 1 auto
  }

  .lg\:flex-initial {
    flex: 0 1 auto
  }

  .lg\:flex-none {
    flex: none
  }

  .lg\:flex-grow-0 {
    flex-grow: 0
  }

  .lg\:flex-grow {
    flex-grow: 1
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0
  }

  .lg\:flex-shrink {
    flex-shrink: 1
  }

  .lg\:order-1 {
    order: 1
  }

  .lg\:order-2 {
    order: 2
  }

  .lg\:order-3 {
    order: 3
  }

  .lg\:order-4 {
    order: 4
  }

  .lg\:order-5 {
    order: 5
  }

  .lg\:order-6 {
    order: 6
  }

  .lg\:order-7 {
    order: 7
  }

  .lg\:order-8 {
    order: 8
  }

  .lg\:order-9 {
    order: 9
  }

  .lg\:order-10 {
    order: 10
  }

  .lg\:order-11 {
    order: 11
  }

  .lg\:order-12 {
    order: 12
  }

  .lg\:order-first {
    order: -9999
  }

  .lg\:order-last {
    order: 9999
  }

  .lg\:order-none {
    order: 0
  }

  .lg\:float-right {
    float: right
  }

  .lg\:float-left {
    float: left
  }

  .lg\:float-none {
    float: none
  }

  [dir='ltr'] .lg\:ltr\:float-right,[dir='ltr'].lg\:ltr\:float-right {
    float: right
  }

  [dir='ltr'] .lg\:ltr\:float-left,[dir='ltr'].lg\:ltr\:float-left {
    float: left
  }

  [dir='ltr'] .lg\:ltr\:float-none,[dir='ltr'].lg\:ltr\:float-none {
    float: none
  }

  [dir='rtl'] .lg\:rtl\:float-right,[dir='rtl'].lg\:rtl\:float-right {
    float: right
  }

  [dir='rtl'] .lg\:rtl\:float-left,[dir='rtl'].lg\:rtl\:float-left {
    float: left
  }

  [dir='rtl'] .lg\:rtl\:float-none,[dir='rtl'].lg\:rtl\:float-none {
    float: none
  }

  .lg\:clear-left {
    clear: left
  }

  .lg\:clear-right {
    clear: right
  }

  .lg\:clear-both {
    clear: both
  }

  .lg\:clear-none {
    clear: none
  }

  .lg\:font-sans {
    font-family: Muli, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .lg\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .lg\:font-mono {
    font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace
  }

  .lg\:font-100 {
    font-weight: 100
  }

  .lg\:font-200 {
    font-weight: 200
  }

  .lg\:font-300 {
    font-weight: 300
  }

  .lg\:font-400 {
    font-weight: 400
  }

  .lg\:font-500 {
    font-weight: 500
  }

  .lg\:font-600 {
    font-weight: 600
  }

  .lg\:font-700 {
    font-weight: 700
  }

  .lg\:font-800 {
    font-weight: 800
  }

  .lg\:font-900 {
    font-weight: 900
  }

  .lg\:font-thin {
    font-weight: 100
  }

  .lg\:font-extralight {
    font-weight: 200
  }

  .lg\:font-light {
    font-weight: 300
  }

  .lg\:font-normal {
    font-weight: 400
  }

  .lg\:font-medium {
    font-weight: 500
  }

  .lg\:font-semibold {
    font-weight: 600
  }

  .lg\:font-bold {
    font-weight: 700
  }

  .lg\:font-extrabold {
    font-weight: 800
  }

  .lg\:font-black {
    font-weight: 900
  }

  .lg\:hover\:font-100:hover {
    font-weight: 100
  }

  .lg\:hover\:font-200:hover {
    font-weight: 200
  }

  .lg\:hover\:font-300:hover {
    font-weight: 300
  }

  .lg\:hover\:font-400:hover {
    font-weight: 400
  }

  .lg\:hover\:font-500:hover {
    font-weight: 500
  }

  .lg\:hover\:font-600:hover {
    font-weight: 600
  }

  .lg\:hover\:font-700:hover {
    font-weight: 700
  }

  .lg\:hover\:font-800:hover {
    font-weight: 800
  }

  .lg\:hover\:font-900:hover {
    font-weight: 900
  }

  .lg\:hover\:font-thin:hover {
    font-weight: 100
  }

  .lg\:hover\:font-extralight:hover {
    font-weight: 200
  }

  .lg\:hover\:font-light:hover {
    font-weight: 300
  }

  .lg\:hover\:font-normal:hover {
    font-weight: 400
  }

  .lg\:hover\:font-medium:hover {
    font-weight: 500
  }

  .lg\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .lg\:hover\:font-bold:hover {
    font-weight: 700
  }

  .lg\:hover\:font-extrabold:hover {
    font-weight: 800
  }

  .lg\:hover\:font-black:hover {
    font-weight: 900
  }

  .lg\:focus\:font-100:focus {
    font-weight: 100
  }

  .lg\:focus\:font-200:focus {
    font-weight: 200
  }

  .lg\:focus\:font-300:focus {
    font-weight: 300
  }

  .lg\:focus\:font-400:focus {
    font-weight: 400
  }

  .lg\:focus\:font-500:focus {
    font-weight: 500
  }

  .lg\:focus\:font-600:focus {
    font-weight: 600
  }

  .lg\:focus\:font-700:focus {
    font-weight: 700
  }

  .lg\:focus\:font-800:focus {
    font-weight: 800
  }

  .lg\:focus\:font-900:focus {
    font-weight: 900
  }

  .lg\:focus\:font-thin:focus {
    font-weight: 100
  }

  .lg\:focus\:font-extralight:focus {
    font-weight: 200
  }

  .lg\:focus\:font-light:focus {
    font-weight: 300
  }

  .lg\:focus\:font-normal:focus {
    font-weight: 400
  }

  .lg\:focus\:font-medium:focus {
    font-weight: 500
  }

  .lg\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .lg\:focus\:font-bold:focus {
    font-weight: 700
  }

  .lg\:focus\:font-extrabold:focus {
    font-weight: 800
  }

  .lg\:focus\:font-black:focus {
    font-weight: 900
  }

  .lg\:h-0 {
    height: 0
  }

  .lg\:h-1 {
    height: 0.1rem
  }

  .lg\:h-2 {
    height: 0.2rem
  }

  .lg\:h-3 {
    height: 0.3rem
  }

  .lg\:h-4 {
    height: 0.4rem
  }

  .lg\:h-5 {
    height: 0.5rem
  }

  .lg\:h-6 {
    height: 0.6rem
  }

  .lg\:h-7 {
    height: 0.7rem
  }

  .lg\:h-8 {
    height: 0.8rem
  }

  .lg\:h-9 {
    height: 0.9rem
  }

  .lg\:h-10 {
    height: 1.0rem
  }

  .lg\:h-12 {
    height: 1.2rem
  }

  .lg\:h-14 {
    height: 1.4rem
  }

  .lg\:h-16 {
    height: 1.6rem
  }

  .lg\:h-20 {
    height: 2rem
  }

  .lg\:h-24 {
    height: 2.4rem
  }

  .lg\:h-28 {
    height: 2.8rem
  }

  .lg\:h-32 {
    height: 3.2rem
  }

  .lg\:h-36 {
    height: 3.6rem
  }

  .lg\:h-40 {
    height: 4rem
  }

  .lg\:h-44 {
    height: 4.4rem
  }

  .lg\:h-48 {
    height: 4.8rem
  }

  .lg\:h-52 {
    height: 5.2rem
  }

  .lg\:h-56 {
    height: 5.6rem
  }

  .lg\:h-60 {
    height: 6rem
  }

  .lg\:h-64 {
    height: 6.4rem
  }

  .lg\:h-68 {
    height: 6.8rem
  }

  .lg\:h-72 {
    height: 7.2rem
  }

  .lg\:h-76 {
    height: 7.6rem
  }

  .lg\:h-80 {
    height: 8rem
  }

  .lg\:h-84 {
    height: 8.4rem
  }

  .lg\:h-88 {
    height: 8.8rem
  }

  .lg\:h-92 {
    height: 9.2rem
  }

  .lg\:h-96 {
    height: 9.6rem
  }

  .lg\:h-128 {
    height: 12.8rem
  }

  .lg\:h-136 {
    height: 13.6rem
  }

  .lg\:h-160 {
    height: 16rem
  }

  .lg\:h-192 {
    height: 19.2rem
  }

  .lg\:h-200 {
    height: 20rem
  }

  .lg\:h-208 {
    height: 20.8rem
  }

  .lg\:h-216 {
    height: 21.6rem
  }

  .lg\:h-224 {
    height: 22.4rem
  }

  .lg\:h-256 {
    height: 25.6rem
  }

  .lg\:h-288 {
    height: 28.8rem
  }

  .lg\:h-320 {
    height: 32rem
  }

  .lg\:h-360 {
    height: 36rem
  }

  .lg\:h-384 {
    height: 38.4rem
  }

  .lg\:h-400 {
    height: 40rem
  }

  .lg\:h-512 {
    height: 51.2rem
  }

  .lg\:h-640 {
    height: 64rem
  }

  .lg\:h-auto {
    height: auto
  }

  .lg\:h-xs {
    height: 32rem
  }

  .lg\:h-sm {
    height: 48rem
  }

  .lg\:h-md {
    height: 64rem
  }

  .lg\:h-lg {
    height: 80rem
  }

  .lg\:h-xl {
    height: 96rem
  }

  .lg\:h-2xl {
    height: 112rem
  }

  .lg\:h-3xl {
    height: 128rem
  }

  .lg\:h-4xl {
    height: 144rem
  }

  .lg\:h-5xl {
    height: 160rem
  }

  .lg\:h-px {
    height: 1px
  }

  .lg\:h-0\.5 {
    height: 0.05rem
  }

  .lg\:h-1\.5 {
    height: 0.15rem
  }

  .lg\:h-2\.5 {
    height: 0.25rem
  }

  .lg\:h-3\.5 {
    height: 0.35rem
  }

  .lg\:h-1\/2 {
    height: 50%
  }

  .lg\:h-1\/3 {
    height: 33.333333%
  }

  .lg\:h-2\/3 {
    height: 66.666667%
  }

  .lg\:h-1\/4 {
    height: 25%
  }

  .lg\:h-2\/4 {
    height: 50%
  }

  .lg\:h-3\/4 {
    height: 75%
  }

  .lg\:h-1\/5 {
    height: 20%
  }

  .lg\:h-2\/5 {
    height: 40%
  }

  .lg\:h-3\/5 {
    height: 60%
  }

  .lg\:h-4\/5 {
    height: 80%
  }

  .lg\:h-1\/6 {
    height: 16.666667%
  }

  .lg\:h-2\/6 {
    height: 33.333333%
  }

  .lg\:h-3\/6 {
    height: 50%
  }

  .lg\:h-4\/6 {
    height: 66.666667%
  }

  .lg\:h-5\/6 {
    height: 83.333333%
  }

  .lg\:h-full {
    height: 100%
  }

  .lg\:h-screen {
    height: 100vh
  }

  .lg\:text-10 {
    font-size: 1rem
  }

  .lg\:text-11 {
    font-size: 1.1rem
  }

  .lg\:text-12 {
    font-size: 1.2rem
  }

  .lg\:text-13 {
    font-size: 1.3rem
  }

  .lg\:text-14 {
    font-size: 1.4rem
  }

  .lg\:text-15 {
    font-size: 1.5rem
  }

  .lg\:text-16 {
    font-size: 1.6rem
  }

  .lg\:text-17 {
    font-size: 1.7rem
  }

  .lg\:text-18 {
    font-size: 1.8rem
  }

  .lg\:text-19 {
    font-size: 1.9rem
  }

  .lg\:text-20 {
    font-size: 2rem
  }

  .lg\:text-24 {
    font-size: 2.4rem
  }

  .lg\:text-28 {
    font-size: 2.8rem
  }

  .lg\:text-32 {
    font-size: 3.2rem
  }

  .lg\:text-36 {
    font-size: 3.6rem
  }

  .lg\:text-40 {
    font-size: 4rem
  }

  .lg\:text-44 {
    font-size: 4.4rem
  }

  .lg\:text-48 {
    font-size: 4.8rem
  }

  .lg\:text-52 {
    font-size: 5.2rem
  }

  .lg\:text-56 {
    font-size: 5.6rem
  }

  .lg\:text-60 {
    font-size: 6rem
  }

  .lg\:text-64 {
    font-size: 6.4rem
  }

  .lg\:text-68 {
    font-size: 6.8rem
  }

  .lg\:text-72 {
    font-size: 7.2rem
  }

  .lg\:text-96 {
    font-size: 9.6rem
  }

  .lg\:text-128 {
    font-size: 12.8rem
  }

  .lg\:text-xs {
    font-size: 1.2rem;
    line-height: 1.6rem
  }

  .lg\:text-sm {
    font-size: 1.4rem;
    line-height: 2rem
  }

  .lg\:text-base {
    font-size: 1.6rem;
    line-height: 2.4rem
  }

  .lg\:text-lg {
    font-size: 1.8rem;
    line-height: 2.8em
  }

  .lg\:text-xl {
    font-size: 2rem;
    line-height: 2.8rem
  }

  .lg\:text-2xl {
    font-size: 2.4rem;
    line-height: 3.2rem
  }

  .lg\:text-3xl {
    font-size: 3rem;
    line-height: 3.6rem
  }

  .lg\:text-4xl {
    font-size: 3.6rem;
    line-height: 4rem
  }

  .lg\:text-5xl {
    font-size: 4.8rem;
    line-height: 1
  }

  .lg\:text-6xl {
    font-size: 6rem;
    line-height: 1
  }

  .lg\:text-7xl {
    font-size: 7.2rem;
    line-height: 1
  }

  .lg\:text-8xl {
    font-size: 9.6rem;
    line-height: 1
  }

  .lg\:text-9xl {
    font-size: 12.8rem;
    line-height: 1
  }

  .lg\:leading-3 {
    line-height: 1.2rem
  }

  .lg\:leading-4 {
    line-height: 1.6rem
  }

  .lg\:leading-5 {
    line-height: 2rem
  }

  .lg\:leading-6 {
    line-height: 2.4rem
  }

  .lg\:leading-7 {
    line-height: 2.8rem
  }

  .lg\:leading-8 {
    line-height: 3.2rem
  }

  .lg\:leading-9 {
    line-height: 3.6rem
  }

  .lg\:leading-10 {
    line-height: 4rem
  }

  .lg\:leading-none {
    line-height: 1
  }

  .lg\:leading-tight {
    line-height: 1.25
  }

  .lg\:leading-snug {
    line-height: 1.375
  }

  .lg\:leading-normal {
    line-height: 1.5
  }

  .lg\:leading-relaxed {
    line-height: 1.625
  }

  .lg\:leading-loose {
    line-height: 2
  }

  .lg\:list-inside {
    list-style-position: inside
  }

  .lg\:list-outside {
    list-style-position: outside
  }

  .lg\:list-none {
    list-style-type: none
  }

  .lg\:list-disc {
    list-style-type: disc
  }

  .lg\:list-decimal {
    list-style-type: decimal
  }

  .lg\:m-0 {
    margin: 0
  }

  .lg\:m-1 {
    margin: 0.1rem
  }

  .lg\:m-2 {
    margin: 0.2rem
  }

  .lg\:m-3 {
    margin: 0.3rem
  }

  .lg\:m-4 {
    margin: 0.4rem
  }

  .lg\:m-5 {
    margin: 0.5rem
  }

  .lg\:m-6 {
    margin: 0.6rem
  }

  .lg\:m-7 {
    margin: 0.7rem
  }

  .lg\:m-8 {
    margin: 0.8rem
  }

  .lg\:m-9 {
    margin: 0.9rem
  }

  .lg\:m-10 {
    margin: 1.0rem
  }

  .lg\:m-12 {
    margin: 1.2rem
  }

  .lg\:m-14 {
    margin: 1.4rem
  }

  .lg\:m-16 {
    margin: 1.6rem
  }

  .lg\:m-20 {
    margin: 2rem
  }

  .lg\:m-24 {
    margin: 2.4rem
  }

  .lg\:m-28 {
    margin: 2.8rem
  }

  .lg\:m-32 {
    margin: 3.2rem
  }

  .lg\:m-36 {
    margin: 3.6rem
  }

  .lg\:m-40 {
    margin: 4rem
  }

  .lg\:m-44 {
    margin: 4.4rem
  }

  .lg\:m-48 {
    margin: 4.8rem
  }

  .lg\:m-52 {
    margin: 5.2rem
  }

  .lg\:m-56 {
    margin: 5.6rem
  }

  .lg\:m-60 {
    margin: 6rem
  }

  .lg\:m-64 {
    margin: 6.4rem
  }

  .lg\:m-68 {
    margin: 6.8rem
  }

  .lg\:m-72 {
    margin: 7.2rem
  }

  .lg\:m-76 {
    margin: 7.6rem
  }

  .lg\:m-80 {
    margin: 8rem
  }

  .lg\:m-84 {
    margin: 8.4rem
  }

  .lg\:m-88 {
    margin: 8.8rem
  }

  .lg\:m-92 {
    margin: 9.2rem
  }

  .lg\:m-96 {
    margin: 9.6rem
  }

  .lg\:m-128 {
    margin: 12.8rem
  }

  .lg\:m-136 {
    margin: 13.6rem
  }

  .lg\:m-160 {
    margin: 16rem
  }

  .lg\:m-192 {
    margin: 19.2rem
  }

  .lg\:m-200 {
    margin: 20rem
  }

  .lg\:m-208 {
    margin: 20.8rem
  }

  .lg\:m-216 {
    margin: 21.6rem
  }

  .lg\:m-224 {
    margin: 22.4rem
  }

  .lg\:m-256 {
    margin: 25.6rem
  }

  .lg\:m-288 {
    margin: 28.8rem
  }

  .lg\:m-320 {
    margin: 32rem
  }

  .lg\:m-360 {
    margin: 36rem
  }

  .lg\:m-384 {
    margin: 38.4rem
  }

  .lg\:m-400 {
    margin: 40rem
  }

  .lg\:m-512 {
    margin: 51.2rem
  }

  .lg\:m-640 {
    margin: 64rem
  }

  .lg\:m-auto {
    margin: auto
  }

  .lg\:m-xs {
    margin: 32rem
  }

  .lg\:m-sm {
    margin: 48rem
  }

  .lg\:m-md {
    margin: 64rem
  }

  .lg\:m-lg {
    margin: 80rem
  }

  .lg\:m-xl {
    margin: 96rem
  }

  .lg\:m-2xl {
    margin: 112rem
  }

  .lg\:m-3xl {
    margin: 128rem
  }

  .lg\:m-4xl {
    margin: 144rem
  }

  .lg\:m-5xl {
    margin: 160rem
  }

  .lg\:m-px {
    margin: 1px
  }

  .lg\:m-0\.5 {
    margin: 0.05rem
  }

  .lg\:m-1\.5 {
    margin: 0.15rem
  }

  .lg\:m-2\.5 {
    margin: 0.25rem
  }

  .lg\:m-3\.5 {
    margin: 0.35rem
  }

  .lg\:-m-1 {
    margin: -0.1rem
  }

  .lg\:-m-2 {
    margin: -0.2rem
  }

  .lg\:-m-3 {
    margin: -0.3rem
  }

  .lg\:-m-4 {
    margin: -0.4rem
  }

  .lg\:-m-5 {
    margin: -0.5rem
  }

  .lg\:-m-6 {
    margin: -0.6rem
  }

  .lg\:-m-7 {
    margin: -0.7rem
  }

  .lg\:-m-8 {
    margin: -0.8rem
  }

  .lg\:-m-9 {
    margin: -0.9rem
  }

  .lg\:-m-10 {
    margin: -1rem
  }

  .lg\:-m-12 {
    margin: -1.2rem
  }

  .lg\:-m-14 {
    margin: -1.4rem
  }

  .lg\:-m-16 {
    margin: -1.6rem
  }

  .lg\:-m-20 {
    margin: -2rem
  }

  .lg\:-m-24 {
    margin: -2.4rem
  }

  .lg\:-m-28 {
    margin: -2.8rem
  }

  .lg\:-m-32 {
    margin: -3.2rem
  }

  .lg\:-m-36 {
    margin: -3.6rem
  }

  .lg\:-m-40 {
    margin: -4rem
  }

  .lg\:-m-44 {
    margin: -4.4rem
  }

  .lg\:-m-48 {
    margin: -4.8rem
  }

  .lg\:-m-52 {
    margin: -5.2rem
  }

  .lg\:-m-56 {
    margin: -5.6rem
  }

  .lg\:-m-60 {
    margin: -6rem
  }

  .lg\:-m-64 {
    margin: -6.4rem
  }

  .lg\:-m-68 {
    margin: -6.8rem
  }

  .lg\:-m-72 {
    margin: -7.2rem
  }

  .lg\:-m-76 {
    margin: -7.6rem
  }

  .lg\:-m-80 {
    margin: -8rem
  }

  .lg\:-m-84 {
    margin: -8.4rem
  }

  .lg\:-m-88 {
    margin: -8.8rem
  }

  .lg\:-m-92 {
    margin: -9.2rem
  }

  .lg\:-m-96 {
    margin: -9.6rem
  }

  .lg\:-m-128 {
    margin: -12.8rem
  }

  .lg\:-m-136 {
    margin: -13.6rem
  }

  .lg\:-m-160 {
    margin: -16rem
  }

  .lg\:-m-192 {
    margin: -19.2rem
  }

  .lg\:-m-200 {
    margin: -20rem
  }

  .lg\:-m-208 {
    margin: -20.8rem
  }

  .lg\:-m-216 {
    margin: -21.6rem
  }

  .lg\:-m-224 {
    margin: -22.4rem
  }

  .lg\:-m-256 {
    margin: -25.6rem
  }

  .lg\:-m-288 {
    margin: -28.8rem
  }

  .lg\:-m-320 {
    margin: -32rem
  }

  .lg\:-m-360 {
    margin: -36rem
  }

  .lg\:-m-384 {
    margin: -38.4rem
  }

  .lg\:-m-400 {
    margin: -40rem
  }

  .lg\:-m-512 {
    margin: -51.2rem
  }

  .lg\:-m-640 {
    margin: -64rem
  }

  .lg\:-m-xs {
    margin: -32rem
  }

  .lg\:-m-sm {
    margin: -48rem
  }

  .lg\:-m-md {
    margin: -64rem
  }

  .lg\:-m-lg {
    margin: -80rem
  }

  .lg\:-m-xl {
    margin: -96rem
  }

  .lg\:-m-2xl {
    margin: -112rem
  }

  .lg\:-m-3xl {
    margin: -128rem
  }

  .lg\:-m-4xl {
    margin: -144rem
  }

  .lg\:-m-5xl {
    margin: -160rem
  }

  .lg\:-m-px {
    margin: -1px
  }

  .lg\:-m-0\.5 {
    margin: -0.05rem
  }

  .lg\:-m-1\.5 {
    margin: -0.15rem
  }

  .lg\:-m-2\.5 {
    margin: -0.25rem
  }

  .lg\:-m-3\.5 {
    margin: -0.35rem
  }

  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .lg\:my-1 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem
  }

  .lg\:mx-1 {
    margin-left: 0.1rem;
    margin-right: 0.1rem
  }

  .lg\:my-2 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem
  }

  .lg\:mx-2 {
    margin-left: 0.2rem;
    margin-right: 0.2rem
  }

  .lg\:my-3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem
  }

  .lg\:mx-3 {
    margin-left: 0.3rem;
    margin-right: 0.3rem
  }

  .lg\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  .lg\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  .lg\:my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .lg\:mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .lg\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  .lg\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  .lg\:my-7 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem
  }

  .lg\:mx-7 {
    margin-left: 0.7rem;
    margin-right: 0.7rem
  }

  .lg\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  .lg\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  .lg\:my-9 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem
  }

  .lg\:mx-9 {
    margin-left: 0.9rem;
    margin-right: 0.9rem
  }

  .lg\:my-10 {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem
  }

  .lg\:mx-10 {
    margin-left: 1.0rem;
    margin-right: 1.0rem
  }

  .lg\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  .lg\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  .lg\:my-14 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem
  }

  .lg\:mx-14 {
    margin-left: 1.4rem;
    margin-right: 1.4rem
  }

  .lg\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  .lg\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  .lg\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .lg\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .lg\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  .lg\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .lg\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  .lg\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  .lg\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  .lg\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  .lg\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  .lg\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  .lg\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .lg\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .lg\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  .lg\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  .lg\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  .lg\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  .lg\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  .lg\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  .lg\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  .lg\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  .lg\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .lg\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .lg\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  .lg\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  .lg\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  .lg\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  .lg\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  .lg\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  .lg\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  .lg\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  .lg\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .lg\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .lg\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  .lg\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  .lg\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  .lg\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  .lg\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  .lg\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  .lg\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  .lg\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  .lg\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  .lg\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  .lg\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  .lg\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  .lg\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .lg\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .lg\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  .lg\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  .lg\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  .lg\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  .lg\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  .lg\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  .lg\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  .lg\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  .lg\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  .lg\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  .lg\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  .lg\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  .lg\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  .lg\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  .lg\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  .lg\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  .lg\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  .lg\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  .lg\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  .lg\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  .lg\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  .lg\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  .lg\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  .lg\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  .lg\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  .lg\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  .lg\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .lg\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  .lg\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  .lg\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  .lg\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  .lg\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  .lg\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  .lg\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  .lg\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  .lg\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  .lg\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  .lg\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  .lg\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  .lg\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  .lg\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  .lg\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  .lg\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  .lg\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  .lg\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  .lg\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .lg\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .lg\:my-0\.5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem
  }

  .lg\:mx-0\.5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem
  }

  .lg\:my-1\.5 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem
  }

  .lg\:mx-1\.5 {
    margin-left: 0.15rem;
    margin-right: 0.15rem
  }

  .lg\:my-2\.5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .lg\:mx-2\.5 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .lg\:my-3\.5 {
    margin-top: 0.35rem;
    margin-bottom: 0.35rem
  }

  .lg\:mx-3\.5 {
    margin-left: 0.35rem;
    margin-right: 0.35rem
  }

  .lg\:-my-1 {
    margin-top: -0.1rem;
    margin-bottom: -0.1rem
  }

  .lg\:-mx-1 {
    margin-left: -0.1rem;
    margin-right: -0.1rem
  }

  .lg\:-my-2 {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem
  }

  .lg\:-mx-2 {
    margin-left: -0.2rem;
    margin-right: -0.2rem
  }

  .lg\:-my-3 {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem
  }

  .lg\:-mx-3 {
    margin-left: -0.3rem;
    margin-right: -0.3rem
  }

  .lg\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  .lg\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  .lg\:-my-5 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .lg\:-mx-5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .lg\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  .lg\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  .lg\:-my-7 {
    margin-top: -0.7rem;
    margin-bottom: -0.7rem
  }

  .lg\:-mx-7 {
    margin-left: -0.7rem;
    margin-right: -0.7rem
  }

  .lg\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  .lg\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  .lg\:-my-9 {
    margin-top: -0.9rem;
    margin-bottom: -0.9rem
  }

  .lg\:-mx-9 {
    margin-left: -0.9rem;
    margin-right: -0.9rem
  }

  .lg\:-my-10 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .lg\:-mx-10 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .lg\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  .lg\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  .lg\:-my-14 {
    margin-top: -1.4rem;
    margin-bottom: -1.4rem
  }

  .lg\:-mx-14 {
    margin-left: -1.4rem;
    margin-right: -1.4rem
  }

  .lg\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  .lg\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  .lg\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .lg\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .lg\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  .lg\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  .lg\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  .lg\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  .lg\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  .lg\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  .lg\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  .lg\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  .lg\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .lg\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .lg\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  .lg\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  .lg\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  .lg\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  .lg\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  .lg\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  .lg\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  .lg\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  .lg\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .lg\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .lg\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  .lg\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  .lg\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  .lg\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  .lg\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  .lg\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  .lg\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  .lg\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  .lg\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .lg\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .lg\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  .lg\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  .lg\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  .lg\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  .lg\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  .lg\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  .lg\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  .lg\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  .lg\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  .lg\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  .lg\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  .lg\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  .lg\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .lg\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .lg\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  .lg\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  .lg\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  .lg\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  .lg\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  .lg\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  .lg\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  .lg\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  .lg\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  .lg\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  .lg\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  .lg\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  .lg\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  .lg\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  .lg\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  .lg\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  .lg\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  .lg\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  .lg\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  .lg\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  .lg\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  .lg\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  .lg\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  .lg\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  .lg\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  .lg\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  .lg\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  .lg\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  .lg\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  .lg\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  .lg\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  .lg\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  .lg\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  .lg\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  .lg\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  .lg\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  .lg\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  .lg\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  .lg\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  .lg\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  .lg\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  .lg\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  .lg\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  .lg\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  .lg\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .lg\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .lg\:-my-0\.5 {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem
  }

  .lg\:-mx-0\.5 {
    margin-left: -0.05rem;
    margin-right: -0.05rem
  }

  .lg\:-my-1\.5 {
    margin-top: -0.15rem;
    margin-bottom: -0.15rem
  }

  .lg\:-mx-1\.5 {
    margin-left: -0.15rem;
    margin-right: -0.15rem
  }

  .lg\:-my-2\.5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .lg\:-mx-2\.5 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .lg\:-my-3\.5 {
    margin-top: -0.35rem;
    margin-bottom: -0.35rem
  }

  .lg\:-mx-3\.5 {
    margin-left: -0.35rem;
    margin-right: -0.35rem
  }

  .lg\:mt-0 {
    margin-top: 0
  }

  .lg\:mr-0 {
    margin-right: 0
  }

  .lg\:mb-0 {
    margin-bottom: 0
  }

  .lg\:ml-0 {
    margin-left: 0
  }

  .lg\:mt-1 {
    margin-top: 0.1rem
  }

  .lg\:mr-1 {
    margin-right: 0.1rem
  }

  .lg\:mb-1 {
    margin-bottom: 0.1rem
  }

  .lg\:ml-1 {
    margin-left: 0.1rem
  }

  .lg\:mt-2 {
    margin-top: 0.2rem
  }

  .lg\:mr-2 {
    margin-right: 0.2rem
  }

  .lg\:mb-2 {
    margin-bottom: 0.2rem
  }

  .lg\:ml-2 {
    margin-left: 0.2rem
  }

  .lg\:mt-3 {
    margin-top: 0.3rem
  }

  .lg\:mr-3 {
    margin-right: 0.3rem
  }

  .lg\:mb-3 {
    margin-bottom: 0.3rem
  }

  .lg\:ml-3 {
    margin-left: 0.3rem
  }

  .lg\:mt-4 {
    margin-top: 0.4rem
  }

  .lg\:mr-4 {
    margin-right: 0.4rem
  }

  .lg\:mb-4 {
    margin-bottom: 0.4rem
  }

  .lg\:ml-4 {
    margin-left: 0.4rem
  }

  .lg\:mt-5 {
    margin-top: 0.5rem
  }

  .lg\:mr-5 {
    margin-right: 0.5rem
  }

  .lg\:mb-5 {
    margin-bottom: 0.5rem
  }

  .lg\:ml-5 {
    margin-left: 0.5rem
  }

  .lg\:mt-6 {
    margin-top: 0.6rem
  }

  .lg\:mr-6 {
    margin-right: 0.6rem
  }

  .lg\:mb-6 {
    margin-bottom: 0.6rem
  }

  .lg\:ml-6 {
    margin-left: 0.6rem
  }

  .lg\:mt-7 {
    margin-top: 0.7rem
  }

  .lg\:mr-7 {
    margin-right: 0.7rem
  }

  .lg\:mb-7 {
    margin-bottom: 0.7rem
  }

  .lg\:ml-7 {
    margin-left: 0.7rem
  }

  .lg\:mt-8 {
    margin-top: 0.8rem
  }

  .lg\:mr-8 {
    margin-right: 0.8rem
  }

  .lg\:mb-8 {
    margin-bottom: 0.8rem
  }

  .lg\:ml-8 {
    margin-left: 0.8rem
  }

  .lg\:mt-9 {
    margin-top: 0.9rem
  }

  .lg\:mr-9 {
    margin-right: 0.9rem
  }

  .lg\:mb-9 {
    margin-bottom: 0.9rem
  }

  .lg\:ml-9 {
    margin-left: 0.9rem
  }

  .lg\:mt-10 {
    margin-top: 1.0rem
  }

  .lg\:mr-10 {
    margin-right: 1.0rem
  }

  .lg\:mb-10 {
    margin-bottom: 1.0rem
  }

  .lg\:ml-10 {
    margin-left: 1.0rem
  }

  .lg\:mt-12 {
    margin-top: 1.2rem
  }

  .lg\:mr-12 {
    margin-right: 1.2rem
  }

  .lg\:mb-12 {
    margin-bottom: 1.2rem
  }

  .lg\:ml-12 {
    margin-left: 1.2rem
  }

  .lg\:mt-14 {
    margin-top: 1.4rem
  }

  .lg\:mr-14 {
    margin-right: 1.4rem
  }

  .lg\:mb-14 {
    margin-bottom: 1.4rem
  }

  .lg\:ml-14 {
    margin-left: 1.4rem
  }

  .lg\:mt-16 {
    margin-top: 1.6rem
  }

  .lg\:mr-16 {
    margin-right: 1.6rem
  }

  .lg\:mb-16 {
    margin-bottom: 1.6rem
  }

  .lg\:ml-16 {
    margin-left: 1.6rem
  }

  .lg\:mt-20 {
    margin-top: 2rem
  }

  .lg\:mr-20 {
    margin-right: 2rem
  }

  .lg\:mb-20 {
    margin-bottom: 2rem
  }

  .lg\:ml-20 {
    margin-left: 2rem
  }

  .lg\:mt-24 {
    margin-top: 2.4rem
  }

  .lg\:mr-24 {
    margin-right: 2.4rem
  }

  .lg\:mb-24 {
    margin-bottom: 2.4rem
  }

  .lg\:ml-24 {
    margin-left: 2.4rem
  }

  .lg\:mt-28 {
    margin-top: 2.8rem
  }

  .lg\:mr-28 {
    margin-right: 2.8rem
  }

  .lg\:mb-28 {
    margin-bottom: 2.8rem
  }

  .lg\:ml-28 {
    margin-left: 2.8rem
  }

  .lg\:mt-32 {
    margin-top: 3.2rem
  }

  .lg\:mr-32 {
    margin-right: 3.2rem
  }

  .lg\:mb-32 {
    margin-bottom: 3.2rem
  }

  .lg\:ml-32 {
    margin-left: 3.2rem
  }

  .lg\:mt-36 {
    margin-top: 3.6rem
  }

  .lg\:mr-36 {
    margin-right: 3.6rem
  }

  .lg\:mb-36 {
    margin-bottom: 3.6rem
  }

  .lg\:ml-36 {
    margin-left: 3.6rem
  }

  .lg\:mt-40 {
    margin-top: 4rem
  }

  .lg\:mr-40 {
    margin-right: 4rem
  }

  .lg\:mb-40 {
    margin-bottom: 4rem
  }

  .lg\:ml-40 {
    margin-left: 4rem
  }

  .lg\:mt-44 {
    margin-top: 4.4rem
  }

  .lg\:mr-44 {
    margin-right: 4.4rem
  }

  .lg\:mb-44 {
    margin-bottom: 4.4rem
  }

  .lg\:ml-44 {
    margin-left: 4.4rem
  }

  .lg\:mt-48 {
    margin-top: 4.8rem
  }

  .lg\:mr-48 {
    margin-right: 4.8rem
  }

  .lg\:mb-48 {
    margin-bottom: 4.8rem
  }

  .lg\:ml-48 {
    margin-left: 4.8rem
  }

  .lg\:mt-52 {
    margin-top: 5.2rem
  }

  .lg\:mr-52 {
    margin-right: 5.2rem
  }

  .lg\:mb-52 {
    margin-bottom: 5.2rem
  }

  .lg\:ml-52 {
    margin-left: 5.2rem
  }

  .lg\:mt-56 {
    margin-top: 5.6rem
  }

  .lg\:mr-56 {
    margin-right: 5.6rem
  }

  .lg\:mb-56 {
    margin-bottom: 5.6rem
  }

  .lg\:ml-56 {
    margin-left: 5.6rem
  }

  .lg\:mt-60 {
    margin-top: 6rem
  }

  .lg\:mr-60 {
    margin-right: 6rem
  }

  .lg\:mb-60 {
    margin-bottom: 6rem
  }

  .lg\:ml-60 {
    margin-left: 6rem
  }

  .lg\:mt-64 {
    margin-top: 6.4rem
  }

  .lg\:mr-64 {
    margin-right: 6.4rem
  }

  .lg\:mb-64 {
    margin-bottom: 6.4rem
  }

  .lg\:ml-64 {
    margin-left: 6.4rem
  }

  .lg\:mt-68 {
    margin-top: 6.8rem
  }

  .lg\:mr-68 {
    margin-right: 6.8rem
  }

  .lg\:mb-68 {
    margin-bottom: 6.8rem
  }

  .lg\:ml-68 {
    margin-left: 6.8rem
  }

  .lg\:mt-72 {
    margin-top: 7.2rem
  }

  .lg\:mr-72 {
    margin-right: 7.2rem
  }

  .lg\:mb-72 {
    margin-bottom: 7.2rem
  }

  .lg\:ml-72 {
    margin-left: 7.2rem
  }

  .lg\:mt-76 {
    margin-top: 7.6rem
  }

  .lg\:mr-76 {
    margin-right: 7.6rem
  }

  .lg\:mb-76 {
    margin-bottom: 7.6rem
  }

  .lg\:ml-76 {
    margin-left: 7.6rem
  }

  .lg\:mt-80 {
    margin-top: 8rem
  }

  .lg\:mr-80 {
    margin-right: 8rem
  }

  .lg\:mb-80 {
    margin-bottom: 8rem
  }

  .lg\:ml-80 {
    margin-left: 8rem
  }

  .lg\:mt-84 {
    margin-top: 8.4rem
  }

  .lg\:mr-84 {
    margin-right: 8.4rem
  }

  .lg\:mb-84 {
    margin-bottom: 8.4rem
  }

  .lg\:ml-84 {
    margin-left: 8.4rem
  }

  .lg\:mt-88 {
    margin-top: 8.8rem
  }

  .lg\:mr-88 {
    margin-right: 8.8rem
  }

  .lg\:mb-88 {
    margin-bottom: 8.8rem
  }

  .lg\:ml-88 {
    margin-left: 8.8rem
  }

  .lg\:mt-92 {
    margin-top: 9.2rem
  }

  .lg\:mr-92 {
    margin-right: 9.2rem
  }

  .lg\:mb-92 {
    margin-bottom: 9.2rem
  }

  .lg\:ml-92 {
    margin-left: 9.2rem
  }

  .lg\:mt-96 {
    margin-top: 9.6rem
  }

  .lg\:mr-96 {
    margin-right: 9.6rem
  }

  .lg\:mb-96 {
    margin-bottom: 9.6rem
  }

  .lg\:ml-96 {
    margin-left: 9.6rem
  }

  .lg\:mt-128 {
    margin-top: 12.8rem
  }

  .lg\:mr-128 {
    margin-right: 12.8rem
  }

  .lg\:mb-128 {
    margin-bottom: 12.8rem
  }

  .lg\:ml-128 {
    margin-left: 12.8rem
  }

  .lg\:mt-136 {
    margin-top: 13.6rem
  }

  .lg\:mr-136 {
    margin-right: 13.6rem
  }

  .lg\:mb-136 {
    margin-bottom: 13.6rem
  }

  .lg\:ml-136 {
    margin-left: 13.6rem
  }

  .lg\:mt-160 {
    margin-top: 16rem
  }

  .lg\:mr-160 {
    margin-right: 16rem
  }

  .lg\:mb-160 {
    margin-bottom: 16rem
  }

  .lg\:ml-160 {
    margin-left: 16rem
  }

  .lg\:mt-192 {
    margin-top: 19.2rem
  }

  .lg\:mr-192 {
    margin-right: 19.2rem
  }

  .lg\:mb-192 {
    margin-bottom: 19.2rem
  }

  .lg\:ml-192 {
    margin-left: 19.2rem
  }

  .lg\:mt-200 {
    margin-top: 20rem
  }

  .lg\:mr-200 {
    margin-right: 20rem
  }

  .lg\:mb-200 {
    margin-bottom: 20rem
  }

  .lg\:ml-200 {
    margin-left: 20rem
  }

  .lg\:mt-208 {
    margin-top: 20.8rem
  }

  .lg\:mr-208 {
    margin-right: 20.8rem
  }

  .lg\:mb-208 {
    margin-bottom: 20.8rem
  }

  .lg\:ml-208 {
    margin-left: 20.8rem
  }

  .lg\:mt-216 {
    margin-top: 21.6rem
  }

  .lg\:mr-216 {
    margin-right: 21.6rem
  }

  .lg\:mb-216 {
    margin-bottom: 21.6rem
  }

  .lg\:ml-216 {
    margin-left: 21.6rem
  }

  .lg\:mt-224 {
    margin-top: 22.4rem
  }

  .lg\:mr-224 {
    margin-right: 22.4rem
  }

  .lg\:mb-224 {
    margin-bottom: 22.4rem
  }

  .lg\:ml-224 {
    margin-left: 22.4rem
  }

  .lg\:mt-256 {
    margin-top: 25.6rem
  }

  .lg\:mr-256 {
    margin-right: 25.6rem
  }

  .lg\:mb-256 {
    margin-bottom: 25.6rem
  }

  .lg\:ml-256 {
    margin-left: 25.6rem
  }

  .lg\:mt-288 {
    margin-top: 28.8rem
  }

  .lg\:mr-288 {
    margin-right: 28.8rem
  }

  .lg\:mb-288 {
    margin-bottom: 28.8rem
  }

  .lg\:ml-288 {
    margin-left: 28.8rem
  }

  .lg\:mt-320 {
    margin-top: 32rem
  }

  .lg\:mr-320 {
    margin-right: 32rem
  }

  .lg\:mb-320 {
    margin-bottom: 32rem
  }

  .lg\:ml-320 {
    margin-left: 32rem
  }

  .lg\:mt-360 {
    margin-top: 36rem
  }

  .lg\:mr-360 {
    margin-right: 36rem
  }

  .lg\:mb-360 {
    margin-bottom: 36rem
  }

  .lg\:ml-360 {
    margin-left: 36rem
  }

  .lg\:mt-384 {
    margin-top: 38.4rem
  }

  .lg\:mr-384 {
    margin-right: 38.4rem
  }

  .lg\:mb-384 {
    margin-bottom: 38.4rem
  }

  .lg\:ml-384 {
    margin-left: 38.4rem
  }

  .lg\:mt-400 {
    margin-top: 40rem
  }

  .lg\:mr-400 {
    margin-right: 40rem
  }

  .lg\:mb-400 {
    margin-bottom: 40rem
  }

  .lg\:ml-400 {
    margin-left: 40rem
  }

  .lg\:mt-512 {
    margin-top: 51.2rem
  }

  .lg\:mr-512 {
    margin-right: 51.2rem
  }

  .lg\:mb-512 {
    margin-bottom: 51.2rem
  }

  .lg\:ml-512 {
    margin-left: 51.2rem
  }

  .lg\:mt-640 {
    margin-top: 64rem
  }

  .lg\:mr-640 {
    margin-right: 64rem
  }

  .lg\:mb-640 {
    margin-bottom: 64rem
  }

  .lg\:ml-640 {
    margin-left: 64rem
  }

  .lg\:mt-auto {
    margin-top: auto
  }

  .lg\:mr-auto {
    margin-right: auto
  }

  .lg\:mb-auto {
    margin-bottom: auto
  }

  .lg\:ml-auto {
    margin-left: auto
  }

  .lg\:mt-xs {
    margin-top: 32rem
  }

  .lg\:mr-xs {
    margin-right: 32rem
  }

  .lg\:mb-xs {
    margin-bottom: 32rem
  }

  .lg\:ml-xs {
    margin-left: 32rem
  }

  .lg\:mt-sm {
    margin-top: 48rem
  }

  .lg\:mr-sm {
    margin-right: 48rem
  }

  .lg\:mb-sm {
    margin-bottom: 48rem
  }

  .lg\:ml-sm {
    margin-left: 48rem
  }

  .lg\:mt-md {
    margin-top: 64rem
  }

  .lg\:mr-md {
    margin-right: 64rem
  }

  .lg\:mb-md {
    margin-bottom: 64rem
  }

  .lg\:ml-md {
    margin-left: 64rem
  }

  .lg\:mt-lg {
    margin-top: 80rem
  }

  .lg\:mr-lg {
    margin-right: 80rem
  }

  .lg\:mb-lg {
    margin-bottom: 80rem
  }

  .lg\:ml-lg {
    margin-left: 80rem
  }

  .lg\:mt-xl {
    margin-top: 96rem
  }

  .lg\:mr-xl {
    margin-right: 96rem
  }

  .lg\:mb-xl {
    margin-bottom: 96rem
  }

  .lg\:ml-xl {
    margin-left: 96rem
  }

  .lg\:mt-2xl {
    margin-top: 112rem
  }

  .lg\:mr-2xl {
    margin-right: 112rem
  }

  .lg\:mb-2xl {
    margin-bottom: 112rem
  }

  .lg\:ml-2xl {
    margin-left: 112rem
  }

  .lg\:mt-3xl {
    margin-top: 128rem
  }

  .lg\:mr-3xl {
    margin-right: 128rem
  }

  .lg\:mb-3xl {
    margin-bottom: 128rem
  }

  .lg\:ml-3xl {
    margin-left: 128rem
  }

  .lg\:mt-4xl {
    margin-top: 144rem
  }

  .lg\:mr-4xl {
    margin-right: 144rem
  }

  .lg\:mb-4xl {
    margin-bottom: 144rem
  }

  .lg\:ml-4xl {
    margin-left: 144rem
  }

  .lg\:mt-5xl {
    margin-top: 160rem
  }

  .lg\:mr-5xl {
    margin-right: 160rem
  }

  .lg\:mb-5xl {
    margin-bottom: 160rem
  }

  .lg\:ml-5xl {
    margin-left: 160rem
  }

  .lg\:mt-px {
    margin-top: 1px
  }

  .lg\:mr-px {
    margin-right: 1px
  }

  .lg\:mb-px {
    margin-bottom: 1px
  }

  .lg\:ml-px {
    margin-left: 1px
  }

  .lg\:mt-0\.5 {
    margin-top: 0.05rem
  }

  .lg\:mr-0\.5 {
    margin-right: 0.05rem
  }

  .lg\:mb-0\.5 {
    margin-bottom: 0.05rem
  }

  .lg\:ml-0\.5 {
    margin-left: 0.05rem
  }

  .lg\:mt-1\.5 {
    margin-top: 0.15rem
  }

  .lg\:mr-1\.5 {
    margin-right: 0.15rem
  }

  .lg\:mb-1\.5 {
    margin-bottom: 0.15rem
  }

  .lg\:ml-1\.5 {
    margin-left: 0.15rem
  }

  .lg\:mt-2\.5 {
    margin-top: 0.25rem
  }

  .lg\:mr-2\.5 {
    margin-right: 0.25rem
  }

  .lg\:mb-2\.5 {
    margin-bottom: 0.25rem
  }

  .lg\:ml-2\.5 {
    margin-left: 0.25rem
  }

  .lg\:mt-3\.5 {
    margin-top: 0.35rem
  }

  .lg\:mr-3\.5 {
    margin-right: 0.35rem
  }

  .lg\:mb-3\.5 {
    margin-bottom: 0.35rem
  }

  .lg\:ml-3\.5 {
    margin-left: 0.35rem
  }

  .lg\:-mt-1 {
    margin-top: -0.1rem
  }

  .lg\:-mr-1 {
    margin-right: -0.1rem
  }

  .lg\:-mb-1 {
    margin-bottom: -0.1rem
  }

  .lg\:-ml-1 {
    margin-left: -0.1rem
  }

  .lg\:-mt-2 {
    margin-top: -0.2rem
  }

  .lg\:-mr-2 {
    margin-right: -0.2rem
  }

  .lg\:-mb-2 {
    margin-bottom: -0.2rem
  }

  .lg\:-ml-2 {
    margin-left: -0.2rem
  }

  .lg\:-mt-3 {
    margin-top: -0.3rem
  }

  .lg\:-mr-3 {
    margin-right: -0.3rem
  }

  .lg\:-mb-3 {
    margin-bottom: -0.3rem
  }

  .lg\:-ml-3 {
    margin-left: -0.3rem
  }

  .lg\:-mt-4 {
    margin-top: -0.4rem
  }

  .lg\:-mr-4 {
    margin-right: -0.4rem
  }

  .lg\:-mb-4 {
    margin-bottom: -0.4rem
  }

  .lg\:-ml-4 {
    margin-left: -0.4rem
  }

  .lg\:-mt-5 {
    margin-top: -0.5rem
  }

  .lg\:-mr-5 {
    margin-right: -0.5rem
  }

  .lg\:-mb-5 {
    margin-bottom: -0.5rem
  }

  .lg\:-ml-5 {
    margin-left: -0.5rem
  }

  .lg\:-mt-6 {
    margin-top: -0.6rem
  }

  .lg\:-mr-6 {
    margin-right: -0.6rem
  }

  .lg\:-mb-6 {
    margin-bottom: -0.6rem
  }

  .lg\:-ml-6 {
    margin-left: -0.6rem
  }

  .lg\:-mt-7 {
    margin-top: -0.7rem
  }

  .lg\:-mr-7 {
    margin-right: -0.7rem
  }

  .lg\:-mb-7 {
    margin-bottom: -0.7rem
  }

  .lg\:-ml-7 {
    margin-left: -0.7rem
  }

  .lg\:-mt-8 {
    margin-top: -0.8rem
  }

  .lg\:-mr-8 {
    margin-right: -0.8rem
  }

  .lg\:-mb-8 {
    margin-bottom: -0.8rem
  }

  .lg\:-ml-8 {
    margin-left: -0.8rem
  }

  .lg\:-mt-9 {
    margin-top: -0.9rem
  }

  .lg\:-mr-9 {
    margin-right: -0.9rem
  }

  .lg\:-mb-9 {
    margin-bottom: -0.9rem
  }

  .lg\:-ml-9 {
    margin-left: -0.9rem
  }

  .lg\:-mt-10 {
    margin-top: -1rem
  }

  .lg\:-mr-10 {
    margin-right: -1rem
  }

  .lg\:-mb-10 {
    margin-bottom: -1rem
  }

  .lg\:-ml-10 {
    margin-left: -1rem
  }

  .lg\:-mt-12 {
    margin-top: -1.2rem
  }

  .lg\:-mr-12 {
    margin-right: -1.2rem
  }

  .lg\:-mb-12 {
    margin-bottom: -1.2rem
  }

  .lg\:-ml-12 {
    margin-left: -1.2rem
  }

  .lg\:-mt-14 {
    margin-top: -1.4rem
  }

  .lg\:-mr-14 {
    margin-right: -1.4rem
  }

  .lg\:-mb-14 {
    margin-bottom: -1.4rem
  }

  .lg\:-ml-14 {
    margin-left: -1.4rem
  }

  .lg\:-mt-16 {
    margin-top: -1.6rem
  }

  .lg\:-mr-16 {
    margin-right: -1.6rem
  }

  .lg\:-mb-16 {
    margin-bottom: -1.6rem
  }

  .lg\:-ml-16 {
    margin-left: -1.6rem
  }

  .lg\:-mt-20 {
    margin-top: -2rem
  }

  .lg\:-mr-20 {
    margin-right: -2rem
  }

  .lg\:-mb-20 {
    margin-bottom: -2rem
  }

  .lg\:-ml-20 {
    margin-left: -2rem
  }

  .lg\:-mt-24 {
    margin-top: -2.4rem
  }

  .lg\:-mr-24 {
    margin-right: -2.4rem
  }

  .lg\:-mb-24 {
    margin-bottom: -2.4rem
  }

  .lg\:-ml-24 {
    margin-left: -2.4rem
  }

  .lg\:-mt-28 {
    margin-top: -2.8rem
  }

  .lg\:-mr-28 {
    margin-right: -2.8rem
  }

  .lg\:-mb-28 {
    margin-bottom: -2.8rem
  }

  .lg\:-ml-28 {
    margin-left: -2.8rem
  }

  .lg\:-mt-32 {
    margin-top: -3.2rem
  }

  .lg\:-mr-32 {
    margin-right: -3.2rem
  }

  .lg\:-mb-32 {
    margin-bottom: -3.2rem
  }

  .lg\:-ml-32 {
    margin-left: -3.2rem
  }

  .lg\:-mt-36 {
    margin-top: -3.6rem
  }

  .lg\:-mr-36 {
    margin-right: -3.6rem
  }

  .lg\:-mb-36 {
    margin-bottom: -3.6rem
  }

  .lg\:-ml-36 {
    margin-left: -3.6rem
  }

  .lg\:-mt-40 {
    margin-top: -4rem
  }

  .lg\:-mr-40 {
    margin-right: -4rem
  }

  .lg\:-mb-40 {
    margin-bottom: -4rem
  }

  .lg\:-ml-40 {
    margin-left: -4rem
  }

  .lg\:-mt-44 {
    margin-top: -4.4rem
  }

  .lg\:-mr-44 {
    margin-right: -4.4rem
  }

  .lg\:-mb-44 {
    margin-bottom: -4.4rem
  }

  .lg\:-ml-44 {
    margin-left: -4.4rem
  }

  .lg\:-mt-48 {
    margin-top: -4.8rem
  }

  .lg\:-mr-48 {
    margin-right: -4.8rem
  }

  .lg\:-mb-48 {
    margin-bottom: -4.8rem
  }

  .lg\:-ml-48 {
    margin-left: -4.8rem
  }

  .lg\:-mt-52 {
    margin-top: -5.2rem
  }

  .lg\:-mr-52 {
    margin-right: -5.2rem
  }

  .lg\:-mb-52 {
    margin-bottom: -5.2rem
  }

  .lg\:-ml-52 {
    margin-left: -5.2rem
  }

  .lg\:-mt-56 {
    margin-top: -5.6rem
  }

  .lg\:-mr-56 {
    margin-right: -5.6rem
  }

  .lg\:-mb-56 {
    margin-bottom: -5.6rem
  }

  .lg\:-ml-56 {
    margin-left: -5.6rem
  }

  .lg\:-mt-60 {
    margin-top: -6rem
  }

  .lg\:-mr-60 {
    margin-right: -6rem
  }

  .lg\:-mb-60 {
    margin-bottom: -6rem
  }

  .lg\:-ml-60 {
    margin-left: -6rem
  }

  .lg\:-mt-64 {
    margin-top: -6.4rem
  }

  .lg\:-mr-64 {
    margin-right: -6.4rem
  }

  .lg\:-mb-64 {
    margin-bottom: -6.4rem
  }

  .lg\:-ml-64 {
    margin-left: -6.4rem
  }

  .lg\:-mt-68 {
    margin-top: -6.8rem
  }

  .lg\:-mr-68 {
    margin-right: -6.8rem
  }

  .lg\:-mb-68 {
    margin-bottom: -6.8rem
  }

  .lg\:-ml-68 {
    margin-left: -6.8rem
  }

  .lg\:-mt-72 {
    margin-top: -7.2rem
  }

  .lg\:-mr-72 {
    margin-right: -7.2rem
  }

  .lg\:-mb-72 {
    margin-bottom: -7.2rem
  }

  .lg\:-ml-72 {
    margin-left: -7.2rem
  }

  .lg\:-mt-76 {
    margin-top: -7.6rem
  }

  .lg\:-mr-76 {
    margin-right: -7.6rem
  }

  .lg\:-mb-76 {
    margin-bottom: -7.6rem
  }

  .lg\:-ml-76 {
    margin-left: -7.6rem
  }

  .lg\:-mt-80 {
    margin-top: -8rem
  }

  .lg\:-mr-80 {
    margin-right: -8rem
  }

  .lg\:-mb-80 {
    margin-bottom: -8rem
  }

  .lg\:-ml-80 {
    margin-left: -8rem
  }

  .lg\:-mt-84 {
    margin-top: -8.4rem
  }

  .lg\:-mr-84 {
    margin-right: -8.4rem
  }

  .lg\:-mb-84 {
    margin-bottom: -8.4rem
  }

  .lg\:-ml-84 {
    margin-left: -8.4rem
  }

  .lg\:-mt-88 {
    margin-top: -8.8rem
  }

  .lg\:-mr-88 {
    margin-right: -8.8rem
  }

  .lg\:-mb-88 {
    margin-bottom: -8.8rem
  }

  .lg\:-ml-88 {
    margin-left: -8.8rem
  }

  .lg\:-mt-92 {
    margin-top: -9.2rem
  }

  .lg\:-mr-92 {
    margin-right: -9.2rem
  }

  .lg\:-mb-92 {
    margin-bottom: -9.2rem
  }

  .lg\:-ml-92 {
    margin-left: -9.2rem
  }

  .lg\:-mt-96 {
    margin-top: -9.6rem
  }

  .lg\:-mr-96 {
    margin-right: -9.6rem
  }

  .lg\:-mb-96 {
    margin-bottom: -9.6rem
  }

  .lg\:-ml-96 {
    margin-left: -9.6rem
  }

  .lg\:-mt-128 {
    margin-top: -12.8rem
  }

  .lg\:-mr-128 {
    margin-right: -12.8rem
  }

  .lg\:-mb-128 {
    margin-bottom: -12.8rem
  }

  .lg\:-ml-128 {
    margin-left: -12.8rem
  }

  .lg\:-mt-136 {
    margin-top: -13.6rem
  }

  .lg\:-mr-136 {
    margin-right: -13.6rem
  }

  .lg\:-mb-136 {
    margin-bottom: -13.6rem
  }

  .lg\:-ml-136 {
    margin-left: -13.6rem
  }

  .lg\:-mt-160 {
    margin-top: -16rem
  }

  .lg\:-mr-160 {
    margin-right: -16rem
  }

  .lg\:-mb-160 {
    margin-bottom: -16rem
  }

  .lg\:-ml-160 {
    margin-left: -16rem
  }

  .lg\:-mt-192 {
    margin-top: -19.2rem
  }

  .lg\:-mr-192 {
    margin-right: -19.2rem
  }

  .lg\:-mb-192 {
    margin-bottom: -19.2rem
  }

  .lg\:-ml-192 {
    margin-left: -19.2rem
  }

  .lg\:-mt-200 {
    margin-top: -20rem
  }

  .lg\:-mr-200 {
    margin-right: -20rem
  }

  .lg\:-mb-200 {
    margin-bottom: -20rem
  }

  .lg\:-ml-200 {
    margin-left: -20rem
  }

  .lg\:-mt-208 {
    margin-top: -20.8rem
  }

  .lg\:-mr-208 {
    margin-right: -20.8rem
  }

  .lg\:-mb-208 {
    margin-bottom: -20.8rem
  }

  .lg\:-ml-208 {
    margin-left: -20.8rem
  }

  .lg\:-mt-216 {
    margin-top: -21.6rem
  }

  .lg\:-mr-216 {
    margin-right: -21.6rem
  }

  .lg\:-mb-216 {
    margin-bottom: -21.6rem
  }

  .lg\:-ml-216 {
    margin-left: -21.6rem
  }

  .lg\:-mt-224 {
    margin-top: -22.4rem
  }

  .lg\:-mr-224 {
    margin-right: -22.4rem
  }

  .lg\:-mb-224 {
    margin-bottom: -22.4rem
  }

  .lg\:-ml-224 {
    margin-left: -22.4rem
  }

  .lg\:-mt-256 {
    margin-top: -25.6rem
  }

  .lg\:-mr-256 {
    margin-right: -25.6rem
  }

  .lg\:-mb-256 {
    margin-bottom: -25.6rem
  }

  .lg\:-ml-256 {
    margin-left: -25.6rem
  }

  .lg\:-mt-288 {
    margin-top: -28.8rem
  }

  .lg\:-mr-288 {
    margin-right: -28.8rem
  }

  .lg\:-mb-288 {
    margin-bottom: -28.8rem
  }

  .lg\:-ml-288 {
    margin-left: -28.8rem
  }

  .lg\:-mt-320 {
    margin-top: -32rem
  }

  .lg\:-mr-320 {
    margin-right: -32rem
  }

  .lg\:-mb-320 {
    margin-bottom: -32rem
  }

  .lg\:-ml-320 {
    margin-left: -32rem
  }

  .lg\:-mt-360 {
    margin-top: -36rem
  }

  .lg\:-mr-360 {
    margin-right: -36rem
  }

  .lg\:-mb-360 {
    margin-bottom: -36rem
  }

  .lg\:-ml-360 {
    margin-left: -36rem
  }

  .lg\:-mt-384 {
    margin-top: -38.4rem
  }

  .lg\:-mr-384 {
    margin-right: -38.4rem
  }

  .lg\:-mb-384 {
    margin-bottom: -38.4rem
  }

  .lg\:-ml-384 {
    margin-left: -38.4rem
  }

  .lg\:-mt-400 {
    margin-top: -40rem
  }

  .lg\:-mr-400 {
    margin-right: -40rem
  }

  .lg\:-mb-400 {
    margin-bottom: -40rem
  }

  .lg\:-ml-400 {
    margin-left: -40rem
  }

  .lg\:-mt-512 {
    margin-top: -51.2rem
  }

  .lg\:-mr-512 {
    margin-right: -51.2rem
  }

  .lg\:-mb-512 {
    margin-bottom: -51.2rem
  }

  .lg\:-ml-512 {
    margin-left: -51.2rem
  }

  .lg\:-mt-640 {
    margin-top: -64rem
  }

  .lg\:-mr-640 {
    margin-right: -64rem
  }

  .lg\:-mb-640 {
    margin-bottom: -64rem
  }

  .lg\:-ml-640 {
    margin-left: -64rem
  }

  .lg\:-mt-xs {
    margin-top: -32rem
  }

  .lg\:-mr-xs {
    margin-right: -32rem
  }

  .lg\:-mb-xs {
    margin-bottom: -32rem
  }

  .lg\:-ml-xs {
    margin-left: -32rem
  }

  .lg\:-mt-sm {
    margin-top: -48rem
  }

  .lg\:-mr-sm {
    margin-right: -48rem
  }

  .lg\:-mb-sm {
    margin-bottom: -48rem
  }

  .lg\:-ml-sm {
    margin-left: -48rem
  }

  .lg\:-mt-md {
    margin-top: -64rem
  }

  .lg\:-mr-md {
    margin-right: -64rem
  }

  .lg\:-mb-md {
    margin-bottom: -64rem
  }

  .lg\:-ml-md {
    margin-left: -64rem
  }

  .lg\:-mt-lg {
    margin-top: -80rem
  }

  .lg\:-mr-lg {
    margin-right: -80rem
  }

  .lg\:-mb-lg {
    margin-bottom: -80rem
  }

  .lg\:-ml-lg {
    margin-left: -80rem
  }

  .lg\:-mt-xl {
    margin-top: -96rem
  }

  .lg\:-mr-xl {
    margin-right: -96rem
  }

  .lg\:-mb-xl {
    margin-bottom: -96rem
  }

  .lg\:-ml-xl {
    margin-left: -96rem
  }

  .lg\:-mt-2xl {
    margin-top: -112rem
  }

  .lg\:-mr-2xl {
    margin-right: -112rem
  }

  .lg\:-mb-2xl {
    margin-bottom: -112rem
  }

  .lg\:-ml-2xl {
    margin-left: -112rem
  }

  .lg\:-mt-3xl {
    margin-top: -128rem
  }

  .lg\:-mr-3xl {
    margin-right: -128rem
  }

  .lg\:-mb-3xl {
    margin-bottom: -128rem
  }

  .lg\:-ml-3xl {
    margin-left: -128rem
  }

  .lg\:-mt-4xl {
    margin-top: -144rem
  }

  .lg\:-mr-4xl {
    margin-right: -144rem
  }

  .lg\:-mb-4xl {
    margin-bottom: -144rem
  }

  .lg\:-ml-4xl {
    margin-left: -144rem
  }

  .lg\:-mt-5xl {
    margin-top: -160rem
  }

  .lg\:-mr-5xl {
    margin-right: -160rem
  }

  .lg\:-mb-5xl {
    margin-bottom: -160rem
  }

  .lg\:-ml-5xl {
    margin-left: -160rem
  }

  .lg\:-mt-px {
    margin-top: -1px
  }

  .lg\:-mr-px {
    margin-right: -1px
  }

  .lg\:-mb-px {
    margin-bottom: -1px
  }

  .lg\:-ml-px {
    margin-left: -1px
  }

  .lg\:-mt-0\.5 {
    margin-top: -0.05rem
  }

  .lg\:-mr-0\.5 {
    margin-right: -0.05rem
  }

  .lg\:-mb-0\.5 {
    margin-bottom: -0.05rem
  }

  .lg\:-ml-0\.5 {
    margin-left: -0.05rem
  }

  .lg\:-mt-1\.5 {
    margin-top: -0.15rem
  }

  .lg\:-mr-1\.5 {
    margin-right: -0.15rem
  }

  .lg\:-mb-1\.5 {
    margin-bottom: -0.15rem
  }

  .lg\:-ml-1\.5 {
    margin-left: -0.15rem
  }

  .lg\:-mt-2\.5 {
    margin-top: -0.25rem
  }

  .lg\:-mr-2\.5 {
    margin-right: -0.25rem
  }

  .lg\:-mb-2\.5 {
    margin-bottom: -0.25rem
  }

  .lg\:-ml-2\.5 {
    margin-left: -0.25rem
  }

  .lg\:-mt-3\.5 {
    margin-top: -0.35rem
  }

  .lg\:-mr-3\.5 {
    margin-right: -0.35rem
  }

  .lg\:-mb-3\.5 {
    margin-bottom: -0.35rem
  }

  .lg\:-ml-3\.5 {
    margin-left: -0.35rem
  }

  [dir='ltr'] .lg\:ltr\:m-0,[dir='ltr'].lg\:ltr\:m-0 {
    margin: 0
  }

  [dir='ltr'] .lg\:ltr\:m-1,[dir='ltr'].lg\:ltr\:m-1 {
    margin: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:m-2,[dir='ltr'].lg\:ltr\:m-2 {
    margin: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:m-3,[dir='ltr'].lg\:ltr\:m-3 {
    margin: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:m-4,[dir='ltr'].lg\:ltr\:m-4 {
    margin: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:m-5,[dir='ltr'].lg\:ltr\:m-5 {
    margin: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:m-6,[dir='ltr'].lg\:ltr\:m-6 {
    margin: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:m-7,[dir='ltr'].lg\:ltr\:m-7 {
    margin: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:m-8,[dir='ltr'].lg\:ltr\:m-8 {
    margin: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:m-9,[dir='ltr'].lg\:ltr\:m-9 {
    margin: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:m-10,[dir='ltr'].lg\:ltr\:m-10 {
    margin: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:m-12,[dir='ltr'].lg\:ltr\:m-12 {
    margin: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:m-14,[dir='ltr'].lg\:ltr\:m-14 {
    margin: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:m-16,[dir='ltr'].lg\:ltr\:m-16 {
    margin: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:m-20,[dir='ltr'].lg\:ltr\:m-20 {
    margin: 2rem
  }

  [dir='ltr'] .lg\:ltr\:m-24,[dir='ltr'].lg\:ltr\:m-24 {
    margin: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:m-28,[dir='ltr'].lg\:ltr\:m-28 {
    margin: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:m-32,[dir='ltr'].lg\:ltr\:m-32 {
    margin: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:m-36,[dir='ltr'].lg\:ltr\:m-36 {
    margin: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:m-40,[dir='ltr'].lg\:ltr\:m-40 {
    margin: 4rem
  }

  [dir='ltr'] .lg\:ltr\:m-44,[dir='ltr'].lg\:ltr\:m-44 {
    margin: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:m-48,[dir='ltr'].lg\:ltr\:m-48 {
    margin: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:m-52,[dir='ltr'].lg\:ltr\:m-52 {
    margin: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:m-56,[dir='ltr'].lg\:ltr\:m-56 {
    margin: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:m-60,[dir='ltr'].lg\:ltr\:m-60 {
    margin: 6rem
  }

  [dir='ltr'] .lg\:ltr\:m-64,[dir='ltr'].lg\:ltr\:m-64 {
    margin: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:m-68,[dir='ltr'].lg\:ltr\:m-68 {
    margin: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:m-72,[dir='ltr'].lg\:ltr\:m-72 {
    margin: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:m-76,[dir='ltr'].lg\:ltr\:m-76 {
    margin: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:m-80,[dir='ltr'].lg\:ltr\:m-80 {
    margin: 8rem
  }

  [dir='ltr'] .lg\:ltr\:m-84,[dir='ltr'].lg\:ltr\:m-84 {
    margin: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:m-88,[dir='ltr'].lg\:ltr\:m-88 {
    margin: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:m-92,[dir='ltr'].lg\:ltr\:m-92 {
    margin: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:m-96,[dir='ltr'].lg\:ltr\:m-96 {
    margin: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:m-128,[dir='ltr'].lg\:ltr\:m-128 {
    margin: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:m-136,[dir='ltr'].lg\:ltr\:m-136 {
    margin: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:m-160,[dir='ltr'].lg\:ltr\:m-160 {
    margin: 16rem
  }

  [dir='ltr'] .lg\:ltr\:m-192,[dir='ltr'].lg\:ltr\:m-192 {
    margin: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:m-200,[dir='ltr'].lg\:ltr\:m-200 {
    margin: 20rem
  }

  [dir='ltr'] .lg\:ltr\:m-208,[dir='ltr'].lg\:ltr\:m-208 {
    margin: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:m-216,[dir='ltr'].lg\:ltr\:m-216 {
    margin: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:m-224,[dir='ltr'].lg\:ltr\:m-224 {
    margin: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:m-256,[dir='ltr'].lg\:ltr\:m-256 {
    margin: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:m-288,[dir='ltr'].lg\:ltr\:m-288 {
    margin: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:m-320,[dir='ltr'].lg\:ltr\:m-320 {
    margin: 32rem
  }

  [dir='ltr'] .lg\:ltr\:m-360,[dir='ltr'].lg\:ltr\:m-360 {
    margin: 36rem
  }

  [dir='ltr'] .lg\:ltr\:m-384,[dir='ltr'].lg\:ltr\:m-384 {
    margin: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:m-400,[dir='ltr'].lg\:ltr\:m-400 {
    margin: 40rem
  }

  [dir='ltr'] .lg\:ltr\:m-512,[dir='ltr'].lg\:ltr\:m-512 {
    margin: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:m-640,[dir='ltr'].lg\:ltr\:m-640 {
    margin: 64rem
  }

  [dir='ltr'] .lg\:ltr\:m-auto,[dir='ltr'].lg\:ltr\:m-auto {
    margin: auto
  }

  [dir='ltr'] .lg\:ltr\:m-xs,[dir='ltr'].lg\:ltr\:m-xs {
    margin: 32rem
  }

  [dir='ltr'] .lg\:ltr\:m-sm,[dir='ltr'].lg\:ltr\:m-sm {
    margin: 48rem
  }

  [dir='ltr'] .lg\:ltr\:m-md,[dir='ltr'].lg\:ltr\:m-md {
    margin: 64rem
  }

  [dir='ltr'] .lg\:ltr\:m-lg,[dir='ltr'].lg\:ltr\:m-lg {
    margin: 80rem
  }

  [dir='ltr'] .lg\:ltr\:m-xl,[dir='ltr'].lg\:ltr\:m-xl {
    margin: 96rem
  }

  [dir='ltr'] .lg\:ltr\:m-2xl,[dir='ltr'].lg\:ltr\:m-2xl {
    margin: 112rem
  }

  [dir='ltr'] .lg\:ltr\:m-3xl,[dir='ltr'].lg\:ltr\:m-3xl {
    margin: 128rem
  }

  [dir='ltr'] .lg\:ltr\:m-4xl,[dir='ltr'].lg\:ltr\:m-4xl {
    margin: 144rem
  }

  [dir='ltr'] .lg\:ltr\:m-5xl,[dir='ltr'].lg\:ltr\:m-5xl {
    margin: 160rem
  }

  [dir='ltr'] .lg\:ltr\:m-px,[dir='ltr'].lg\:ltr\:m-px {
    margin: 1px
  }

  [dir='ltr'] .lg\:ltr\:m-0\.5,[dir='ltr'].lg\:ltr\:m-0\.5 {
    margin: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:m-1\.5,[dir='ltr'].lg\:ltr\:m-1\.5 {
    margin: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:m-2\.5,[dir='ltr'].lg\:ltr\:m-2\.5 {
    margin: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:m-3\.5,[dir='ltr'].lg\:ltr\:m-3\.5 {
    margin: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:-m-1,[dir='ltr'].lg\:ltr\:-m-1 {
    margin: -0.1rem
  }

  [dir='ltr'] .lg\:ltr\:-m-2,[dir='ltr'].lg\:ltr\:-m-2 {
    margin: -0.2rem
  }

  [dir='ltr'] .lg\:ltr\:-m-3,[dir='ltr'].lg\:ltr\:-m-3 {
    margin: -0.3rem
  }

  [dir='ltr'] .lg\:ltr\:-m-4,[dir='ltr'].lg\:ltr\:-m-4 {
    margin: -0.4rem
  }

  [dir='ltr'] .lg\:ltr\:-m-5,[dir='ltr'].lg\:ltr\:-m-5 {
    margin: -0.5rem
  }

  [dir='ltr'] .lg\:ltr\:-m-6,[dir='ltr'].lg\:ltr\:-m-6 {
    margin: -0.6rem
  }

  [dir='ltr'] .lg\:ltr\:-m-7,[dir='ltr'].lg\:ltr\:-m-7 {
    margin: -0.7rem
  }

  [dir='ltr'] .lg\:ltr\:-m-8,[dir='ltr'].lg\:ltr\:-m-8 {
    margin: -0.8rem
  }

  [dir='ltr'] .lg\:ltr\:-m-9,[dir='ltr'].lg\:ltr\:-m-9 {
    margin: -0.9rem
  }

  [dir='ltr'] .lg\:ltr\:-m-10,[dir='ltr'].lg\:ltr\:-m-10 {
    margin: -1rem
  }

  [dir='ltr'] .lg\:ltr\:-m-12,[dir='ltr'].lg\:ltr\:-m-12 {
    margin: -1.2rem
  }

  [dir='ltr'] .lg\:ltr\:-m-14,[dir='ltr'].lg\:ltr\:-m-14 {
    margin: -1.4rem
  }

  [dir='ltr'] .lg\:ltr\:-m-16,[dir='ltr'].lg\:ltr\:-m-16 {
    margin: -1.6rem
  }

  [dir='ltr'] .lg\:ltr\:-m-20,[dir='ltr'].lg\:ltr\:-m-20 {
    margin: -2rem
  }

  [dir='ltr'] .lg\:ltr\:-m-24,[dir='ltr'].lg\:ltr\:-m-24 {
    margin: -2.4rem
  }

  [dir='ltr'] .lg\:ltr\:-m-28,[dir='ltr'].lg\:ltr\:-m-28 {
    margin: -2.8rem
  }

  [dir='ltr'] .lg\:ltr\:-m-32,[dir='ltr'].lg\:ltr\:-m-32 {
    margin: -3.2rem
  }

  [dir='ltr'] .lg\:ltr\:-m-36,[dir='ltr'].lg\:ltr\:-m-36 {
    margin: -3.6rem
  }

  [dir='ltr'] .lg\:ltr\:-m-40,[dir='ltr'].lg\:ltr\:-m-40 {
    margin: -4rem
  }

  [dir='ltr'] .lg\:ltr\:-m-44,[dir='ltr'].lg\:ltr\:-m-44 {
    margin: -4.4rem
  }

  [dir='ltr'] .lg\:ltr\:-m-48,[dir='ltr'].lg\:ltr\:-m-48 {
    margin: -4.8rem
  }

  [dir='ltr'] .lg\:ltr\:-m-52,[dir='ltr'].lg\:ltr\:-m-52 {
    margin: -5.2rem
  }

  [dir='ltr'] .lg\:ltr\:-m-56,[dir='ltr'].lg\:ltr\:-m-56 {
    margin: -5.6rem
  }

  [dir='ltr'] .lg\:ltr\:-m-60,[dir='ltr'].lg\:ltr\:-m-60 {
    margin: -6rem
  }

  [dir='ltr'] .lg\:ltr\:-m-64,[dir='ltr'].lg\:ltr\:-m-64 {
    margin: -6.4rem
  }

  [dir='ltr'] .lg\:ltr\:-m-68,[dir='ltr'].lg\:ltr\:-m-68 {
    margin: -6.8rem
  }

  [dir='ltr'] .lg\:ltr\:-m-72,[dir='ltr'].lg\:ltr\:-m-72 {
    margin: -7.2rem
  }

  [dir='ltr'] .lg\:ltr\:-m-76,[dir='ltr'].lg\:ltr\:-m-76 {
    margin: -7.6rem
  }

  [dir='ltr'] .lg\:ltr\:-m-80,[dir='ltr'].lg\:ltr\:-m-80 {
    margin: -8rem
  }

  [dir='ltr'] .lg\:ltr\:-m-84,[dir='ltr'].lg\:ltr\:-m-84 {
    margin: -8.4rem
  }

  [dir='ltr'] .lg\:ltr\:-m-88,[dir='ltr'].lg\:ltr\:-m-88 {
    margin: -8.8rem
  }

  [dir='ltr'] .lg\:ltr\:-m-92,[dir='ltr'].lg\:ltr\:-m-92 {
    margin: -9.2rem
  }

  [dir='ltr'] .lg\:ltr\:-m-96,[dir='ltr'].lg\:ltr\:-m-96 {
    margin: -9.6rem
  }

  [dir='ltr'] .lg\:ltr\:-m-128,[dir='ltr'].lg\:ltr\:-m-128 {
    margin: -12.8rem
  }

  [dir='ltr'] .lg\:ltr\:-m-136,[dir='ltr'].lg\:ltr\:-m-136 {
    margin: -13.6rem
  }

  [dir='ltr'] .lg\:ltr\:-m-160,[dir='ltr'].lg\:ltr\:-m-160 {
    margin: -16rem
  }

  [dir='ltr'] .lg\:ltr\:-m-192,[dir='ltr'].lg\:ltr\:-m-192 {
    margin: -19.2rem
  }

  [dir='ltr'] .lg\:ltr\:-m-200,[dir='ltr'].lg\:ltr\:-m-200 {
    margin: -20rem
  }

  [dir='ltr'] .lg\:ltr\:-m-208,[dir='ltr'].lg\:ltr\:-m-208 {
    margin: -20.8rem
  }

  [dir='ltr'] .lg\:ltr\:-m-216,[dir='ltr'].lg\:ltr\:-m-216 {
    margin: -21.6rem
  }

  [dir='ltr'] .lg\:ltr\:-m-224,[dir='ltr'].lg\:ltr\:-m-224 {
    margin: -22.4rem
  }

  [dir='ltr'] .lg\:ltr\:-m-256,[dir='ltr'].lg\:ltr\:-m-256 {
    margin: -25.6rem
  }

  [dir='ltr'] .lg\:ltr\:-m-288,[dir='ltr'].lg\:ltr\:-m-288 {
    margin: -28.8rem
  }

  [dir='ltr'] .lg\:ltr\:-m-320,[dir='ltr'].lg\:ltr\:-m-320 {
    margin: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-m-360,[dir='ltr'].lg\:ltr\:-m-360 {
    margin: -36rem
  }

  [dir='ltr'] .lg\:ltr\:-m-384,[dir='ltr'].lg\:ltr\:-m-384 {
    margin: -38.4rem
  }

  [dir='ltr'] .lg\:ltr\:-m-400,[dir='ltr'].lg\:ltr\:-m-400 {
    margin: -40rem
  }

  [dir='ltr'] .lg\:ltr\:-m-512,[dir='ltr'].lg\:ltr\:-m-512 {
    margin: -51.2rem
  }

  [dir='ltr'] .lg\:ltr\:-m-640,[dir='ltr'].lg\:ltr\:-m-640 {
    margin: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-m-xs,[dir='ltr'].lg\:ltr\:-m-xs {
    margin: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-m-sm,[dir='ltr'].lg\:ltr\:-m-sm {
    margin: -48rem
  }

  [dir='ltr'] .lg\:ltr\:-m-md,[dir='ltr'].lg\:ltr\:-m-md {
    margin: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-m-lg,[dir='ltr'].lg\:ltr\:-m-lg {
    margin: -80rem
  }

  [dir='ltr'] .lg\:ltr\:-m-xl,[dir='ltr'].lg\:ltr\:-m-xl {
    margin: -96rem
  }

  [dir='ltr'] .lg\:ltr\:-m-2xl,[dir='ltr'].lg\:ltr\:-m-2xl {
    margin: -112rem
  }

  [dir='ltr'] .lg\:ltr\:-m-3xl,[dir='ltr'].lg\:ltr\:-m-3xl {
    margin: -128rem
  }

  [dir='ltr'] .lg\:ltr\:-m-4xl,[dir='ltr'].lg\:ltr\:-m-4xl {
    margin: -144rem
  }

  [dir='ltr'] .lg\:ltr\:-m-5xl,[dir='ltr'].lg\:ltr\:-m-5xl {
    margin: -160rem
  }

  [dir='ltr'] .lg\:ltr\:-m-px,[dir='ltr'].lg\:ltr\:-m-px {
    margin: -1px
  }

  [dir='ltr'] .lg\:ltr\:-m-0\.5,[dir='ltr'].lg\:ltr\:-m-0\.5 {
    margin: -0.05rem
  }

  [dir='ltr'] .lg\:ltr\:-m-1\.5,[dir='ltr'].lg\:ltr\:-m-1\.5 {
    margin: -0.15rem
  }

  [dir='ltr'] .lg\:ltr\:-m-2\.5,[dir='ltr'].lg\:ltr\:-m-2\.5 {
    margin: -0.25rem
  }

  [dir='ltr'] .lg\:ltr\:-m-3\.5,[dir='ltr'].lg\:ltr\:-m-3\.5 {
    margin: -0.35rem
  }

  [dir='ltr'] .lg\:ltr\:my-0,[dir='ltr'].lg\:ltr\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  [dir='ltr'] .lg\:ltr\:mx-0,[dir='ltr'].lg\:ltr\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  [dir='ltr'] .lg\:ltr\:my-1,[dir='ltr'].lg\:ltr\:my-1 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:mx-1,[dir='ltr'].lg\:ltr\:mx-1 {
    margin-left: 0.1rem;
    margin-right: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:my-2,[dir='ltr'].lg\:ltr\:my-2 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:mx-2,[dir='ltr'].lg\:ltr\:mx-2 {
    margin-left: 0.2rem;
    margin-right: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:my-3,[dir='ltr'].lg\:ltr\:my-3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:mx-3,[dir='ltr'].lg\:ltr\:mx-3 {
    margin-left: 0.3rem;
    margin-right: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:my-4,[dir='ltr'].lg\:ltr\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:mx-4,[dir='ltr'].lg\:ltr\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:my-5,[dir='ltr'].lg\:ltr\:my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:mx-5,[dir='ltr'].lg\:ltr\:mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:my-6,[dir='ltr'].lg\:ltr\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:mx-6,[dir='ltr'].lg\:ltr\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:my-7,[dir='ltr'].lg\:ltr\:my-7 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:mx-7,[dir='ltr'].lg\:ltr\:mx-7 {
    margin-left: 0.7rem;
    margin-right: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:my-8,[dir='ltr'].lg\:ltr\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:mx-8,[dir='ltr'].lg\:ltr\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:my-9,[dir='ltr'].lg\:ltr\:my-9 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:mx-9,[dir='ltr'].lg\:ltr\:mx-9 {
    margin-left: 0.9rem;
    margin-right: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:my-10,[dir='ltr'].lg\:ltr\:my-10 {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:mx-10,[dir='ltr'].lg\:ltr\:mx-10 {
    margin-left: 1.0rem;
    margin-right: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:my-12,[dir='ltr'].lg\:ltr\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:mx-12,[dir='ltr'].lg\:ltr\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:my-14,[dir='ltr'].lg\:ltr\:my-14 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:mx-14,[dir='ltr'].lg\:ltr\:mx-14 {
    margin-left: 1.4rem;
    margin-right: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:my-16,[dir='ltr'].lg\:ltr\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:mx-16,[dir='ltr'].lg\:ltr\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:my-20,[dir='ltr'].lg\:ltr\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  [dir='ltr'] .lg\:ltr\:mx-20,[dir='ltr'].lg\:ltr\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  [dir='ltr'] .lg\:ltr\:my-24,[dir='ltr'].lg\:ltr\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:mx-24,[dir='ltr'].lg\:ltr\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:my-28,[dir='ltr'].lg\:ltr\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:mx-28,[dir='ltr'].lg\:ltr\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:my-32,[dir='ltr'].lg\:ltr\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:mx-32,[dir='ltr'].lg\:ltr\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:my-36,[dir='ltr'].lg\:ltr\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:mx-36,[dir='ltr'].lg\:ltr\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:my-40,[dir='ltr'].lg\:ltr\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  [dir='ltr'] .lg\:ltr\:mx-40,[dir='ltr'].lg\:ltr\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  [dir='ltr'] .lg\:ltr\:my-44,[dir='ltr'].lg\:ltr\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:mx-44,[dir='ltr'].lg\:ltr\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:my-48,[dir='ltr'].lg\:ltr\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:mx-48,[dir='ltr'].lg\:ltr\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:my-52,[dir='ltr'].lg\:ltr\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:mx-52,[dir='ltr'].lg\:ltr\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:my-56,[dir='ltr'].lg\:ltr\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:mx-56,[dir='ltr'].lg\:ltr\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:my-60,[dir='ltr'].lg\:ltr\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  [dir='ltr'] .lg\:ltr\:mx-60,[dir='ltr'].lg\:ltr\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  [dir='ltr'] .lg\:ltr\:my-64,[dir='ltr'].lg\:ltr\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:mx-64,[dir='ltr'].lg\:ltr\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:my-68,[dir='ltr'].lg\:ltr\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:mx-68,[dir='ltr'].lg\:ltr\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:my-72,[dir='ltr'].lg\:ltr\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:mx-72,[dir='ltr'].lg\:ltr\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:my-76,[dir='ltr'].lg\:ltr\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:mx-76,[dir='ltr'].lg\:ltr\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:my-80,[dir='ltr'].lg\:ltr\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  [dir='ltr'] .lg\:ltr\:mx-80,[dir='ltr'].lg\:ltr\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  [dir='ltr'] .lg\:ltr\:my-84,[dir='ltr'].lg\:ltr\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:mx-84,[dir='ltr'].lg\:ltr\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:my-88,[dir='ltr'].lg\:ltr\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:mx-88,[dir='ltr'].lg\:ltr\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:my-92,[dir='ltr'].lg\:ltr\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:mx-92,[dir='ltr'].lg\:ltr\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:my-96,[dir='ltr'].lg\:ltr\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:mx-96,[dir='ltr'].lg\:ltr\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:my-128,[dir='ltr'].lg\:ltr\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:mx-128,[dir='ltr'].lg\:ltr\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:my-136,[dir='ltr'].lg\:ltr\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:mx-136,[dir='ltr'].lg\:ltr\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:my-160,[dir='ltr'].lg\:ltr\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  [dir='ltr'] .lg\:ltr\:mx-160,[dir='ltr'].lg\:ltr\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  [dir='ltr'] .lg\:ltr\:my-192,[dir='ltr'].lg\:ltr\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:mx-192,[dir='ltr'].lg\:ltr\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:my-200,[dir='ltr'].lg\:ltr\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  [dir='ltr'] .lg\:ltr\:mx-200,[dir='ltr'].lg\:ltr\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  [dir='ltr'] .lg\:ltr\:my-208,[dir='ltr'].lg\:ltr\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:mx-208,[dir='ltr'].lg\:ltr\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:my-216,[dir='ltr'].lg\:ltr\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:mx-216,[dir='ltr'].lg\:ltr\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:my-224,[dir='ltr'].lg\:ltr\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:mx-224,[dir='ltr'].lg\:ltr\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:my-256,[dir='ltr'].lg\:ltr\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:mx-256,[dir='ltr'].lg\:ltr\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:my-288,[dir='ltr'].lg\:ltr\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:mx-288,[dir='ltr'].lg\:ltr\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:my-320,[dir='ltr'].lg\:ltr\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='ltr'] .lg\:ltr\:mx-320,[dir='ltr'].lg\:ltr\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='ltr'] .lg\:ltr\:my-360,[dir='ltr'].lg\:ltr\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  [dir='ltr'] .lg\:ltr\:mx-360,[dir='ltr'].lg\:ltr\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  [dir='ltr'] .lg\:ltr\:my-384,[dir='ltr'].lg\:ltr\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:mx-384,[dir='ltr'].lg\:ltr\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:my-400,[dir='ltr'].lg\:ltr\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  [dir='ltr'] .lg\:ltr\:mx-400,[dir='ltr'].lg\:ltr\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  [dir='ltr'] .lg\:ltr\:my-512,[dir='ltr'].lg\:ltr\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:mx-512,[dir='ltr'].lg\:ltr\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:my-640,[dir='ltr'].lg\:ltr\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='ltr'] .lg\:ltr\:mx-640,[dir='ltr'].lg\:ltr\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='ltr'] .lg\:ltr\:my-auto,[dir='ltr'].lg\:ltr\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  [dir='ltr'] .lg\:ltr\:mx-auto,[dir='ltr'].lg\:ltr\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  [dir='ltr'] .lg\:ltr\:my-xs,[dir='ltr'].lg\:ltr\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='ltr'] .lg\:ltr\:mx-xs,[dir='ltr'].lg\:ltr\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='ltr'] .lg\:ltr\:my-sm,[dir='ltr'].lg\:ltr\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  [dir='ltr'] .lg\:ltr\:mx-sm,[dir='ltr'].lg\:ltr\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  [dir='ltr'] .lg\:ltr\:my-md,[dir='ltr'].lg\:ltr\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='ltr'] .lg\:ltr\:mx-md,[dir='ltr'].lg\:ltr\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='ltr'] .lg\:ltr\:my-lg,[dir='ltr'].lg\:ltr\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  [dir='ltr'] .lg\:ltr\:mx-lg,[dir='ltr'].lg\:ltr\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  [dir='ltr'] .lg\:ltr\:my-xl,[dir='ltr'].lg\:ltr\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  [dir='ltr'] .lg\:ltr\:mx-xl,[dir='ltr'].lg\:ltr\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  [dir='ltr'] .lg\:ltr\:my-2xl,[dir='ltr'].lg\:ltr\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  [dir='ltr'] .lg\:ltr\:mx-2xl,[dir='ltr'].lg\:ltr\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  [dir='ltr'] .lg\:ltr\:my-3xl,[dir='ltr'].lg\:ltr\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  [dir='ltr'] .lg\:ltr\:mx-3xl,[dir='ltr'].lg\:ltr\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  [dir='ltr'] .lg\:ltr\:my-4xl,[dir='ltr'].lg\:ltr\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  [dir='ltr'] .lg\:ltr\:mx-4xl,[dir='ltr'].lg\:ltr\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  [dir='ltr'] .lg\:ltr\:my-5xl,[dir='ltr'].lg\:ltr\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  [dir='ltr'] .lg\:ltr\:mx-5xl,[dir='ltr'].lg\:ltr\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  [dir='ltr'] .lg\:ltr\:my-px,[dir='ltr'].lg\:ltr\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  [dir='ltr'] .lg\:ltr\:mx-px,[dir='ltr'].lg\:ltr\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  [dir='ltr'] .lg\:ltr\:my-0\.5,[dir='ltr'].lg\:ltr\:my-0\.5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:mx-0\.5,[dir='ltr'].lg\:ltr\:mx-0\.5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:my-1\.5,[dir='ltr'].lg\:ltr\:my-1\.5 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:mx-1\.5,[dir='ltr'].lg\:ltr\:mx-1\.5 {
    margin-left: 0.15rem;
    margin-right: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:my-2\.5,[dir='ltr'].lg\:ltr\:my-2\.5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:mx-2\.5,[dir='ltr'].lg\:ltr\:mx-2\.5 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:my-3\.5,[dir='ltr'].lg\:ltr\:my-3\.5 {
    margin-top: 0.35rem;
    margin-bottom: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:mx-3\.5,[dir='ltr'].lg\:ltr\:mx-3\.5 {
    margin-left: 0.35rem;
    margin-right: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:-my-1,[dir='ltr'].lg\:ltr\:-my-1 {
    margin-top: -0.1rem;
    margin-bottom: -0.1rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-1,[dir='ltr'].lg\:ltr\:-mx-1 {
    margin-left: -0.1rem;
    margin-right: -0.1rem
  }

  [dir='ltr'] .lg\:ltr\:-my-2,[dir='ltr'].lg\:ltr\:-my-2 {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-2,[dir='ltr'].lg\:ltr\:-mx-2 {
    margin-left: -0.2rem;
    margin-right: -0.2rem
  }

  [dir='ltr'] .lg\:ltr\:-my-3,[dir='ltr'].lg\:ltr\:-my-3 {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-3,[dir='ltr'].lg\:ltr\:-mx-3 {
    margin-left: -0.3rem;
    margin-right: -0.3rem
  }

  [dir='ltr'] .lg\:ltr\:-my-4,[dir='ltr'].lg\:ltr\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-4,[dir='ltr'].lg\:ltr\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  [dir='ltr'] .lg\:ltr\:-my-5,[dir='ltr'].lg\:ltr\:-my-5 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-5,[dir='ltr'].lg\:ltr\:-mx-5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  [dir='ltr'] .lg\:ltr\:-my-6,[dir='ltr'].lg\:ltr\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-6,[dir='ltr'].lg\:ltr\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  [dir='ltr'] .lg\:ltr\:-my-7,[dir='ltr'].lg\:ltr\:-my-7 {
    margin-top: -0.7rem;
    margin-bottom: -0.7rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-7,[dir='ltr'].lg\:ltr\:-mx-7 {
    margin-left: -0.7rem;
    margin-right: -0.7rem
  }

  [dir='ltr'] .lg\:ltr\:-my-8,[dir='ltr'].lg\:ltr\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-8,[dir='ltr'].lg\:ltr\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  [dir='ltr'] .lg\:ltr\:-my-9,[dir='ltr'].lg\:ltr\:-my-9 {
    margin-top: -0.9rem;
    margin-bottom: -0.9rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-9,[dir='ltr'].lg\:ltr\:-mx-9 {
    margin-left: -0.9rem;
    margin-right: -0.9rem
  }

  [dir='ltr'] .lg\:ltr\:-my-10,[dir='ltr'].lg\:ltr\:-my-10 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-10,[dir='ltr'].lg\:ltr\:-mx-10 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  [dir='ltr'] .lg\:ltr\:-my-12,[dir='ltr'].lg\:ltr\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-12,[dir='ltr'].lg\:ltr\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  [dir='ltr'] .lg\:ltr\:-my-14,[dir='ltr'].lg\:ltr\:-my-14 {
    margin-top: -1.4rem;
    margin-bottom: -1.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-14,[dir='ltr'].lg\:ltr\:-mx-14 {
    margin-left: -1.4rem;
    margin-right: -1.4rem
  }

  [dir='ltr'] .lg\:ltr\:-my-16,[dir='ltr'].lg\:ltr\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-16,[dir='ltr'].lg\:ltr\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  [dir='ltr'] .lg\:ltr\:-my-20,[dir='ltr'].lg\:ltr\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-20,[dir='ltr'].lg\:ltr\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  [dir='ltr'] .lg\:ltr\:-my-24,[dir='ltr'].lg\:ltr\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-24,[dir='ltr'].lg\:ltr\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  [dir='ltr'] .lg\:ltr\:-my-28,[dir='ltr'].lg\:ltr\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-28,[dir='ltr'].lg\:ltr\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  [dir='ltr'] .lg\:ltr\:-my-32,[dir='ltr'].lg\:ltr\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-32,[dir='ltr'].lg\:ltr\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  [dir='ltr'] .lg\:ltr\:-my-36,[dir='ltr'].lg\:ltr\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-36,[dir='ltr'].lg\:ltr\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  [dir='ltr'] .lg\:ltr\:-my-40,[dir='ltr'].lg\:ltr\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-40,[dir='ltr'].lg\:ltr\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  [dir='ltr'] .lg\:ltr\:-my-44,[dir='ltr'].lg\:ltr\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-44,[dir='ltr'].lg\:ltr\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  [dir='ltr'] .lg\:ltr\:-my-48,[dir='ltr'].lg\:ltr\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-48,[dir='ltr'].lg\:ltr\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  [dir='ltr'] .lg\:ltr\:-my-52,[dir='ltr'].lg\:ltr\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-52,[dir='ltr'].lg\:ltr\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  [dir='ltr'] .lg\:ltr\:-my-56,[dir='ltr'].lg\:ltr\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-56,[dir='ltr'].lg\:ltr\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  [dir='ltr'] .lg\:ltr\:-my-60,[dir='ltr'].lg\:ltr\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-60,[dir='ltr'].lg\:ltr\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  [dir='ltr'] .lg\:ltr\:-my-64,[dir='ltr'].lg\:ltr\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-64,[dir='ltr'].lg\:ltr\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  [dir='ltr'] .lg\:ltr\:-my-68,[dir='ltr'].lg\:ltr\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-68,[dir='ltr'].lg\:ltr\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  [dir='ltr'] .lg\:ltr\:-my-72,[dir='ltr'].lg\:ltr\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-72,[dir='ltr'].lg\:ltr\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  [dir='ltr'] .lg\:ltr\:-my-76,[dir='ltr'].lg\:ltr\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-76,[dir='ltr'].lg\:ltr\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  [dir='ltr'] .lg\:ltr\:-my-80,[dir='ltr'].lg\:ltr\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-80,[dir='ltr'].lg\:ltr\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  [dir='ltr'] .lg\:ltr\:-my-84,[dir='ltr'].lg\:ltr\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-84,[dir='ltr'].lg\:ltr\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  [dir='ltr'] .lg\:ltr\:-my-88,[dir='ltr'].lg\:ltr\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-88,[dir='ltr'].lg\:ltr\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  [dir='ltr'] .lg\:ltr\:-my-92,[dir='ltr'].lg\:ltr\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-92,[dir='ltr'].lg\:ltr\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  [dir='ltr'] .lg\:ltr\:-my-96,[dir='ltr'].lg\:ltr\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-96,[dir='ltr'].lg\:ltr\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  [dir='ltr'] .lg\:ltr\:-my-128,[dir='ltr'].lg\:ltr\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-128,[dir='ltr'].lg\:ltr\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  [dir='ltr'] .lg\:ltr\:-my-136,[dir='ltr'].lg\:ltr\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-136,[dir='ltr'].lg\:ltr\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  [dir='ltr'] .lg\:ltr\:-my-160,[dir='ltr'].lg\:ltr\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-160,[dir='ltr'].lg\:ltr\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  [dir='ltr'] .lg\:ltr\:-my-192,[dir='ltr'].lg\:ltr\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-192,[dir='ltr'].lg\:ltr\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  [dir='ltr'] .lg\:ltr\:-my-200,[dir='ltr'].lg\:ltr\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-200,[dir='ltr'].lg\:ltr\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  [dir='ltr'] .lg\:ltr\:-my-208,[dir='ltr'].lg\:ltr\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-208,[dir='ltr'].lg\:ltr\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  [dir='ltr'] .lg\:ltr\:-my-216,[dir='ltr'].lg\:ltr\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-216,[dir='ltr'].lg\:ltr\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  [dir='ltr'] .lg\:ltr\:-my-224,[dir='ltr'].lg\:ltr\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-224,[dir='ltr'].lg\:ltr\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  [dir='ltr'] .lg\:ltr\:-my-256,[dir='ltr'].lg\:ltr\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-256,[dir='ltr'].lg\:ltr\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  [dir='ltr'] .lg\:ltr\:-my-288,[dir='ltr'].lg\:ltr\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-288,[dir='ltr'].lg\:ltr\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  [dir='ltr'] .lg\:ltr\:-my-320,[dir='ltr'].lg\:ltr\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-320,[dir='ltr'].lg\:ltr\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-my-360,[dir='ltr'].lg\:ltr\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-360,[dir='ltr'].lg\:ltr\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  [dir='ltr'] .lg\:ltr\:-my-384,[dir='ltr'].lg\:ltr\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-384,[dir='ltr'].lg\:ltr\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  [dir='ltr'] .lg\:ltr\:-my-400,[dir='ltr'].lg\:ltr\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-400,[dir='ltr'].lg\:ltr\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  [dir='ltr'] .lg\:ltr\:-my-512,[dir='ltr'].lg\:ltr\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-512,[dir='ltr'].lg\:ltr\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  [dir='ltr'] .lg\:ltr\:-my-640,[dir='ltr'].lg\:ltr\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-640,[dir='ltr'].lg\:ltr\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-my-xs,[dir='ltr'].lg\:ltr\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-xs,[dir='ltr'].lg\:ltr\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-my-sm,[dir='ltr'].lg\:ltr\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-sm,[dir='ltr'].lg\:ltr\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  [dir='ltr'] .lg\:ltr\:-my-md,[dir='ltr'].lg\:ltr\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-md,[dir='ltr'].lg\:ltr\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-my-lg,[dir='ltr'].lg\:ltr\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-lg,[dir='ltr'].lg\:ltr\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  [dir='ltr'] .lg\:ltr\:-my-xl,[dir='ltr'].lg\:ltr\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-xl,[dir='ltr'].lg\:ltr\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  [dir='ltr'] .lg\:ltr\:-my-2xl,[dir='ltr'].lg\:ltr\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-2xl,[dir='ltr'].lg\:ltr\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  [dir='ltr'] .lg\:ltr\:-my-3xl,[dir='ltr'].lg\:ltr\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-3xl,[dir='ltr'].lg\:ltr\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  [dir='ltr'] .lg\:ltr\:-my-4xl,[dir='ltr'].lg\:ltr\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-4xl,[dir='ltr'].lg\:ltr\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  [dir='ltr'] .lg\:ltr\:-my-5xl,[dir='ltr'].lg\:ltr\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-5xl,[dir='ltr'].lg\:ltr\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  [dir='ltr'] .lg\:ltr\:-my-px,[dir='ltr'].lg\:ltr\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  [dir='ltr'] .lg\:ltr\:-mx-px,[dir='ltr'].lg\:ltr\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  [dir='ltr'] .lg\:ltr\:-my-0\.5,[dir='ltr'].lg\:ltr\:-my-0\.5 {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-0\.5,[dir='ltr'].lg\:ltr\:-mx-0\.5 {
    margin-left: -0.05rem;
    margin-right: -0.05rem
  }

  [dir='ltr'] .lg\:ltr\:-my-1\.5,[dir='ltr'].lg\:ltr\:-my-1\.5 {
    margin-top: -0.15rem;
    margin-bottom: -0.15rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-1\.5,[dir='ltr'].lg\:ltr\:-mx-1\.5 {
    margin-left: -0.15rem;
    margin-right: -0.15rem
  }

  [dir='ltr'] .lg\:ltr\:-my-2\.5,[dir='ltr'].lg\:ltr\:-my-2\.5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-2\.5,[dir='ltr'].lg\:ltr\:-mx-2\.5 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  [dir='ltr'] .lg\:ltr\:-my-3\.5,[dir='ltr'].lg\:ltr\:-my-3\.5 {
    margin-top: -0.35rem;
    margin-bottom: -0.35rem
  }

  [dir='ltr'] .lg\:ltr\:-mx-3\.5,[dir='ltr'].lg\:ltr\:-mx-3\.5 {
    margin-left: -0.35rem;
    margin-right: -0.35rem
  }

  [dir='ltr'] .lg\:ltr\:mt-0,[dir='ltr'].lg\:ltr\:mt-0 {
    margin-top: 0
  }

  [dir='ltr'] .lg\:ltr\:mr-0,[dir='ltr'].lg\:ltr\:mr-0 {
    margin-right: 0
  }

  [dir='ltr'] .lg\:ltr\:mb-0,[dir='ltr'].lg\:ltr\:mb-0 {
    margin-bottom: 0
  }

  [dir='ltr'] .lg\:ltr\:ml-0,[dir='ltr'].lg\:ltr\:ml-0 {
    margin-left: 0
  }

  [dir='ltr'] .lg\:ltr\:mt-1,[dir='ltr'].lg\:ltr\:mt-1 {
    margin-top: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:mr-1,[dir='ltr'].lg\:ltr\:mr-1 {
    margin-right: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:mb-1,[dir='ltr'].lg\:ltr\:mb-1 {
    margin-bottom: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:ml-1,[dir='ltr'].lg\:ltr\:ml-1 {
    margin-left: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:mt-2,[dir='ltr'].lg\:ltr\:mt-2 {
    margin-top: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:mr-2,[dir='ltr'].lg\:ltr\:mr-2 {
    margin-right: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:mb-2,[dir='ltr'].lg\:ltr\:mb-2 {
    margin-bottom: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:ml-2,[dir='ltr'].lg\:ltr\:ml-2 {
    margin-left: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:mt-3,[dir='ltr'].lg\:ltr\:mt-3 {
    margin-top: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:mr-3,[dir='ltr'].lg\:ltr\:mr-3 {
    margin-right: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:mb-3,[dir='ltr'].lg\:ltr\:mb-3 {
    margin-bottom: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:ml-3,[dir='ltr'].lg\:ltr\:ml-3 {
    margin-left: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:mt-4,[dir='ltr'].lg\:ltr\:mt-4 {
    margin-top: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:mr-4,[dir='ltr'].lg\:ltr\:mr-4 {
    margin-right: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:mb-4,[dir='ltr'].lg\:ltr\:mb-4 {
    margin-bottom: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:ml-4,[dir='ltr'].lg\:ltr\:ml-4 {
    margin-left: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:mt-5,[dir='ltr'].lg\:ltr\:mt-5 {
    margin-top: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:mr-5,[dir='ltr'].lg\:ltr\:mr-5 {
    margin-right: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:mb-5,[dir='ltr'].lg\:ltr\:mb-5 {
    margin-bottom: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:ml-5,[dir='ltr'].lg\:ltr\:ml-5 {
    margin-left: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:mt-6,[dir='ltr'].lg\:ltr\:mt-6 {
    margin-top: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:mr-6,[dir='ltr'].lg\:ltr\:mr-6 {
    margin-right: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:mb-6,[dir='ltr'].lg\:ltr\:mb-6 {
    margin-bottom: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:ml-6,[dir='ltr'].lg\:ltr\:ml-6 {
    margin-left: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:mt-7,[dir='ltr'].lg\:ltr\:mt-7 {
    margin-top: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:mr-7,[dir='ltr'].lg\:ltr\:mr-7 {
    margin-right: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:mb-7,[dir='ltr'].lg\:ltr\:mb-7 {
    margin-bottom: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:ml-7,[dir='ltr'].lg\:ltr\:ml-7 {
    margin-left: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:mt-8,[dir='ltr'].lg\:ltr\:mt-8 {
    margin-top: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:mr-8,[dir='ltr'].lg\:ltr\:mr-8 {
    margin-right: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:mb-8,[dir='ltr'].lg\:ltr\:mb-8 {
    margin-bottom: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:ml-8,[dir='ltr'].lg\:ltr\:ml-8 {
    margin-left: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:mt-9,[dir='ltr'].lg\:ltr\:mt-9 {
    margin-top: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:mr-9,[dir='ltr'].lg\:ltr\:mr-9 {
    margin-right: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:mb-9,[dir='ltr'].lg\:ltr\:mb-9 {
    margin-bottom: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:ml-9,[dir='ltr'].lg\:ltr\:ml-9 {
    margin-left: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:mt-10,[dir='ltr'].lg\:ltr\:mt-10 {
    margin-top: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:mr-10,[dir='ltr'].lg\:ltr\:mr-10 {
    margin-right: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:mb-10,[dir='ltr'].lg\:ltr\:mb-10 {
    margin-bottom: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:ml-10,[dir='ltr'].lg\:ltr\:ml-10 {
    margin-left: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:mt-12,[dir='ltr'].lg\:ltr\:mt-12 {
    margin-top: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:mr-12,[dir='ltr'].lg\:ltr\:mr-12 {
    margin-right: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:mb-12,[dir='ltr'].lg\:ltr\:mb-12 {
    margin-bottom: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:ml-12,[dir='ltr'].lg\:ltr\:ml-12 {
    margin-left: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:mt-14,[dir='ltr'].lg\:ltr\:mt-14 {
    margin-top: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:mr-14,[dir='ltr'].lg\:ltr\:mr-14 {
    margin-right: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:mb-14,[dir='ltr'].lg\:ltr\:mb-14 {
    margin-bottom: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:ml-14,[dir='ltr'].lg\:ltr\:ml-14 {
    margin-left: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:mt-16,[dir='ltr'].lg\:ltr\:mt-16 {
    margin-top: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:mr-16,[dir='ltr'].lg\:ltr\:mr-16 {
    margin-right: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:mb-16,[dir='ltr'].lg\:ltr\:mb-16 {
    margin-bottom: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:ml-16,[dir='ltr'].lg\:ltr\:ml-16 {
    margin-left: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:mt-20,[dir='ltr'].lg\:ltr\:mt-20 {
    margin-top: 2rem
  }

  [dir='ltr'] .lg\:ltr\:mr-20,[dir='ltr'].lg\:ltr\:mr-20 {
    margin-right: 2rem
  }

  [dir='ltr'] .lg\:ltr\:mb-20,[dir='ltr'].lg\:ltr\:mb-20 {
    margin-bottom: 2rem
  }

  [dir='ltr'] .lg\:ltr\:ml-20,[dir='ltr'].lg\:ltr\:ml-20 {
    margin-left: 2rem
  }

  [dir='ltr'] .lg\:ltr\:mt-24,[dir='ltr'].lg\:ltr\:mt-24 {
    margin-top: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:mr-24,[dir='ltr'].lg\:ltr\:mr-24 {
    margin-right: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:mb-24,[dir='ltr'].lg\:ltr\:mb-24 {
    margin-bottom: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:ml-24,[dir='ltr'].lg\:ltr\:ml-24 {
    margin-left: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:mt-28,[dir='ltr'].lg\:ltr\:mt-28 {
    margin-top: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:mr-28,[dir='ltr'].lg\:ltr\:mr-28 {
    margin-right: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:mb-28,[dir='ltr'].lg\:ltr\:mb-28 {
    margin-bottom: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:ml-28,[dir='ltr'].lg\:ltr\:ml-28 {
    margin-left: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:mt-32,[dir='ltr'].lg\:ltr\:mt-32 {
    margin-top: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:mr-32,[dir='ltr'].lg\:ltr\:mr-32 {
    margin-right: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:mb-32,[dir='ltr'].lg\:ltr\:mb-32 {
    margin-bottom: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:ml-32,[dir='ltr'].lg\:ltr\:ml-32 {
    margin-left: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:mt-36,[dir='ltr'].lg\:ltr\:mt-36 {
    margin-top: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:mr-36,[dir='ltr'].lg\:ltr\:mr-36 {
    margin-right: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:mb-36,[dir='ltr'].lg\:ltr\:mb-36 {
    margin-bottom: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:ml-36,[dir='ltr'].lg\:ltr\:ml-36 {
    margin-left: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:mt-40,[dir='ltr'].lg\:ltr\:mt-40 {
    margin-top: 4rem
  }

  [dir='ltr'] .lg\:ltr\:mr-40,[dir='ltr'].lg\:ltr\:mr-40 {
    margin-right: 4rem
  }

  [dir='ltr'] .lg\:ltr\:mb-40,[dir='ltr'].lg\:ltr\:mb-40 {
    margin-bottom: 4rem
  }

  [dir='ltr'] .lg\:ltr\:ml-40,[dir='ltr'].lg\:ltr\:ml-40 {
    margin-left: 4rem
  }

  [dir='ltr'] .lg\:ltr\:mt-44,[dir='ltr'].lg\:ltr\:mt-44 {
    margin-top: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:mr-44,[dir='ltr'].lg\:ltr\:mr-44 {
    margin-right: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:mb-44,[dir='ltr'].lg\:ltr\:mb-44 {
    margin-bottom: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:ml-44,[dir='ltr'].lg\:ltr\:ml-44 {
    margin-left: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:mt-48,[dir='ltr'].lg\:ltr\:mt-48 {
    margin-top: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:mr-48,[dir='ltr'].lg\:ltr\:mr-48 {
    margin-right: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:mb-48,[dir='ltr'].lg\:ltr\:mb-48 {
    margin-bottom: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:ml-48,[dir='ltr'].lg\:ltr\:ml-48 {
    margin-left: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:mt-52,[dir='ltr'].lg\:ltr\:mt-52 {
    margin-top: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:mr-52,[dir='ltr'].lg\:ltr\:mr-52 {
    margin-right: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:mb-52,[dir='ltr'].lg\:ltr\:mb-52 {
    margin-bottom: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:ml-52,[dir='ltr'].lg\:ltr\:ml-52 {
    margin-left: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:mt-56,[dir='ltr'].lg\:ltr\:mt-56 {
    margin-top: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:mr-56,[dir='ltr'].lg\:ltr\:mr-56 {
    margin-right: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:mb-56,[dir='ltr'].lg\:ltr\:mb-56 {
    margin-bottom: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:ml-56,[dir='ltr'].lg\:ltr\:ml-56 {
    margin-left: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:mt-60,[dir='ltr'].lg\:ltr\:mt-60 {
    margin-top: 6rem
  }

  [dir='ltr'] .lg\:ltr\:mr-60,[dir='ltr'].lg\:ltr\:mr-60 {
    margin-right: 6rem
  }

  [dir='ltr'] .lg\:ltr\:mb-60,[dir='ltr'].lg\:ltr\:mb-60 {
    margin-bottom: 6rem
  }

  [dir='ltr'] .lg\:ltr\:ml-60,[dir='ltr'].lg\:ltr\:ml-60 {
    margin-left: 6rem
  }

  [dir='ltr'] .lg\:ltr\:mt-64,[dir='ltr'].lg\:ltr\:mt-64 {
    margin-top: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:mr-64,[dir='ltr'].lg\:ltr\:mr-64 {
    margin-right: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:mb-64,[dir='ltr'].lg\:ltr\:mb-64 {
    margin-bottom: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:ml-64,[dir='ltr'].lg\:ltr\:ml-64 {
    margin-left: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:mt-68,[dir='ltr'].lg\:ltr\:mt-68 {
    margin-top: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:mr-68,[dir='ltr'].lg\:ltr\:mr-68 {
    margin-right: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:mb-68,[dir='ltr'].lg\:ltr\:mb-68 {
    margin-bottom: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:ml-68,[dir='ltr'].lg\:ltr\:ml-68 {
    margin-left: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:mt-72,[dir='ltr'].lg\:ltr\:mt-72 {
    margin-top: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:mr-72,[dir='ltr'].lg\:ltr\:mr-72 {
    margin-right: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:mb-72,[dir='ltr'].lg\:ltr\:mb-72 {
    margin-bottom: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:ml-72,[dir='ltr'].lg\:ltr\:ml-72 {
    margin-left: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:mt-76,[dir='ltr'].lg\:ltr\:mt-76 {
    margin-top: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:mr-76,[dir='ltr'].lg\:ltr\:mr-76 {
    margin-right: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:mb-76,[dir='ltr'].lg\:ltr\:mb-76 {
    margin-bottom: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:ml-76,[dir='ltr'].lg\:ltr\:ml-76 {
    margin-left: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:mt-80,[dir='ltr'].lg\:ltr\:mt-80 {
    margin-top: 8rem
  }

  [dir='ltr'] .lg\:ltr\:mr-80,[dir='ltr'].lg\:ltr\:mr-80 {
    margin-right: 8rem
  }

  [dir='ltr'] .lg\:ltr\:mb-80,[dir='ltr'].lg\:ltr\:mb-80 {
    margin-bottom: 8rem
  }

  [dir='ltr'] .lg\:ltr\:ml-80,[dir='ltr'].lg\:ltr\:ml-80 {
    margin-left: 8rem
  }

  [dir='ltr'] .lg\:ltr\:mt-84,[dir='ltr'].lg\:ltr\:mt-84 {
    margin-top: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:mr-84,[dir='ltr'].lg\:ltr\:mr-84 {
    margin-right: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:mb-84,[dir='ltr'].lg\:ltr\:mb-84 {
    margin-bottom: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:ml-84,[dir='ltr'].lg\:ltr\:ml-84 {
    margin-left: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:mt-88,[dir='ltr'].lg\:ltr\:mt-88 {
    margin-top: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:mr-88,[dir='ltr'].lg\:ltr\:mr-88 {
    margin-right: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:mb-88,[dir='ltr'].lg\:ltr\:mb-88 {
    margin-bottom: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:ml-88,[dir='ltr'].lg\:ltr\:ml-88 {
    margin-left: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:mt-92,[dir='ltr'].lg\:ltr\:mt-92 {
    margin-top: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:mr-92,[dir='ltr'].lg\:ltr\:mr-92 {
    margin-right: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:mb-92,[dir='ltr'].lg\:ltr\:mb-92 {
    margin-bottom: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:ml-92,[dir='ltr'].lg\:ltr\:ml-92 {
    margin-left: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:mt-96,[dir='ltr'].lg\:ltr\:mt-96 {
    margin-top: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:mr-96,[dir='ltr'].lg\:ltr\:mr-96 {
    margin-right: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:mb-96,[dir='ltr'].lg\:ltr\:mb-96 {
    margin-bottom: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:ml-96,[dir='ltr'].lg\:ltr\:ml-96 {
    margin-left: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:mt-128,[dir='ltr'].lg\:ltr\:mt-128 {
    margin-top: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:mr-128,[dir='ltr'].lg\:ltr\:mr-128 {
    margin-right: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:mb-128,[dir='ltr'].lg\:ltr\:mb-128 {
    margin-bottom: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:ml-128,[dir='ltr'].lg\:ltr\:ml-128 {
    margin-left: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:mt-136,[dir='ltr'].lg\:ltr\:mt-136 {
    margin-top: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:mr-136,[dir='ltr'].lg\:ltr\:mr-136 {
    margin-right: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:mb-136,[dir='ltr'].lg\:ltr\:mb-136 {
    margin-bottom: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:ml-136,[dir='ltr'].lg\:ltr\:ml-136 {
    margin-left: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:mt-160,[dir='ltr'].lg\:ltr\:mt-160 {
    margin-top: 16rem
  }

  [dir='ltr'] .lg\:ltr\:mr-160,[dir='ltr'].lg\:ltr\:mr-160 {
    margin-right: 16rem
  }

  [dir='ltr'] .lg\:ltr\:mb-160,[dir='ltr'].lg\:ltr\:mb-160 {
    margin-bottom: 16rem
  }

  [dir='ltr'] .lg\:ltr\:ml-160,[dir='ltr'].lg\:ltr\:ml-160 {
    margin-left: 16rem
  }

  [dir='ltr'] .lg\:ltr\:mt-192,[dir='ltr'].lg\:ltr\:mt-192 {
    margin-top: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:mr-192,[dir='ltr'].lg\:ltr\:mr-192 {
    margin-right: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:mb-192,[dir='ltr'].lg\:ltr\:mb-192 {
    margin-bottom: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:ml-192,[dir='ltr'].lg\:ltr\:ml-192 {
    margin-left: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:mt-200,[dir='ltr'].lg\:ltr\:mt-200 {
    margin-top: 20rem
  }

  [dir='ltr'] .lg\:ltr\:mr-200,[dir='ltr'].lg\:ltr\:mr-200 {
    margin-right: 20rem
  }

  [dir='ltr'] .lg\:ltr\:mb-200,[dir='ltr'].lg\:ltr\:mb-200 {
    margin-bottom: 20rem
  }

  [dir='ltr'] .lg\:ltr\:ml-200,[dir='ltr'].lg\:ltr\:ml-200 {
    margin-left: 20rem
  }

  [dir='ltr'] .lg\:ltr\:mt-208,[dir='ltr'].lg\:ltr\:mt-208 {
    margin-top: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:mr-208,[dir='ltr'].lg\:ltr\:mr-208 {
    margin-right: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:mb-208,[dir='ltr'].lg\:ltr\:mb-208 {
    margin-bottom: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:ml-208,[dir='ltr'].lg\:ltr\:ml-208 {
    margin-left: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:mt-216,[dir='ltr'].lg\:ltr\:mt-216 {
    margin-top: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:mr-216,[dir='ltr'].lg\:ltr\:mr-216 {
    margin-right: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:mb-216,[dir='ltr'].lg\:ltr\:mb-216 {
    margin-bottom: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:ml-216,[dir='ltr'].lg\:ltr\:ml-216 {
    margin-left: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:mt-224,[dir='ltr'].lg\:ltr\:mt-224 {
    margin-top: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:mr-224,[dir='ltr'].lg\:ltr\:mr-224 {
    margin-right: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:mb-224,[dir='ltr'].lg\:ltr\:mb-224 {
    margin-bottom: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:ml-224,[dir='ltr'].lg\:ltr\:ml-224 {
    margin-left: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:mt-256,[dir='ltr'].lg\:ltr\:mt-256 {
    margin-top: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:mr-256,[dir='ltr'].lg\:ltr\:mr-256 {
    margin-right: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:mb-256,[dir='ltr'].lg\:ltr\:mb-256 {
    margin-bottom: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:ml-256,[dir='ltr'].lg\:ltr\:ml-256 {
    margin-left: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:mt-288,[dir='ltr'].lg\:ltr\:mt-288 {
    margin-top: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:mr-288,[dir='ltr'].lg\:ltr\:mr-288 {
    margin-right: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:mb-288,[dir='ltr'].lg\:ltr\:mb-288 {
    margin-bottom: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:ml-288,[dir='ltr'].lg\:ltr\:ml-288 {
    margin-left: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:mt-320,[dir='ltr'].lg\:ltr\:mt-320 {
    margin-top: 32rem
  }

  [dir='ltr'] .lg\:ltr\:mr-320,[dir='ltr'].lg\:ltr\:mr-320 {
    margin-right: 32rem
  }

  [dir='ltr'] .lg\:ltr\:mb-320,[dir='ltr'].lg\:ltr\:mb-320 {
    margin-bottom: 32rem
  }

  [dir='ltr'] .lg\:ltr\:ml-320,[dir='ltr'].lg\:ltr\:ml-320 {
    margin-left: 32rem
  }

  [dir='ltr'] .lg\:ltr\:mt-360,[dir='ltr'].lg\:ltr\:mt-360 {
    margin-top: 36rem
  }

  [dir='ltr'] .lg\:ltr\:mr-360,[dir='ltr'].lg\:ltr\:mr-360 {
    margin-right: 36rem
  }

  [dir='ltr'] .lg\:ltr\:mb-360,[dir='ltr'].lg\:ltr\:mb-360 {
    margin-bottom: 36rem
  }

  [dir='ltr'] .lg\:ltr\:ml-360,[dir='ltr'].lg\:ltr\:ml-360 {
    margin-left: 36rem
  }

  [dir='ltr'] .lg\:ltr\:mt-384,[dir='ltr'].lg\:ltr\:mt-384 {
    margin-top: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:mr-384,[dir='ltr'].lg\:ltr\:mr-384 {
    margin-right: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:mb-384,[dir='ltr'].lg\:ltr\:mb-384 {
    margin-bottom: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:ml-384,[dir='ltr'].lg\:ltr\:ml-384 {
    margin-left: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:mt-400,[dir='ltr'].lg\:ltr\:mt-400 {
    margin-top: 40rem
  }

  [dir='ltr'] .lg\:ltr\:mr-400,[dir='ltr'].lg\:ltr\:mr-400 {
    margin-right: 40rem
  }

  [dir='ltr'] .lg\:ltr\:mb-400,[dir='ltr'].lg\:ltr\:mb-400 {
    margin-bottom: 40rem
  }

  [dir='ltr'] .lg\:ltr\:ml-400,[dir='ltr'].lg\:ltr\:ml-400 {
    margin-left: 40rem
  }

  [dir='ltr'] .lg\:ltr\:mt-512,[dir='ltr'].lg\:ltr\:mt-512 {
    margin-top: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:mr-512,[dir='ltr'].lg\:ltr\:mr-512 {
    margin-right: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:mb-512,[dir='ltr'].lg\:ltr\:mb-512 {
    margin-bottom: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:ml-512,[dir='ltr'].lg\:ltr\:ml-512 {
    margin-left: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:mt-640,[dir='ltr'].lg\:ltr\:mt-640 {
    margin-top: 64rem
  }

  [dir='ltr'] .lg\:ltr\:mr-640,[dir='ltr'].lg\:ltr\:mr-640 {
    margin-right: 64rem
  }

  [dir='ltr'] .lg\:ltr\:mb-640,[dir='ltr'].lg\:ltr\:mb-640 {
    margin-bottom: 64rem
  }

  [dir='ltr'] .lg\:ltr\:ml-640,[dir='ltr'].lg\:ltr\:ml-640 {
    margin-left: 64rem
  }

  [dir='ltr'] .lg\:ltr\:mt-auto,[dir='ltr'].lg\:ltr\:mt-auto {
    margin-top: auto
  }

  [dir='ltr'] .lg\:ltr\:mr-auto,[dir='ltr'].lg\:ltr\:mr-auto {
    margin-right: auto
  }

  [dir='ltr'] .lg\:ltr\:mb-auto,[dir='ltr'].lg\:ltr\:mb-auto {
    margin-bottom: auto
  }

  [dir='ltr'] .lg\:ltr\:ml-auto,[dir='ltr'].lg\:ltr\:ml-auto {
    margin-left: auto
  }

  [dir='ltr'] .lg\:ltr\:mt-xs,[dir='ltr'].lg\:ltr\:mt-xs {
    margin-top: 32rem
  }

  [dir='ltr'] .lg\:ltr\:mr-xs,[dir='ltr'].lg\:ltr\:mr-xs {
    margin-right: 32rem
  }

  [dir='ltr'] .lg\:ltr\:mb-xs,[dir='ltr'].lg\:ltr\:mb-xs {
    margin-bottom: 32rem
  }

  [dir='ltr'] .lg\:ltr\:ml-xs,[dir='ltr'].lg\:ltr\:ml-xs {
    margin-left: 32rem
  }

  [dir='ltr'] .lg\:ltr\:mt-sm,[dir='ltr'].lg\:ltr\:mt-sm {
    margin-top: 48rem
  }

  [dir='ltr'] .lg\:ltr\:mr-sm,[dir='ltr'].lg\:ltr\:mr-sm {
    margin-right: 48rem
  }

  [dir='ltr'] .lg\:ltr\:mb-sm,[dir='ltr'].lg\:ltr\:mb-sm {
    margin-bottom: 48rem
  }

  [dir='ltr'] .lg\:ltr\:ml-sm,[dir='ltr'].lg\:ltr\:ml-sm {
    margin-left: 48rem
  }

  [dir='ltr'] .lg\:ltr\:mt-md,[dir='ltr'].lg\:ltr\:mt-md {
    margin-top: 64rem
  }

  [dir='ltr'] .lg\:ltr\:mr-md,[dir='ltr'].lg\:ltr\:mr-md {
    margin-right: 64rem
  }

  [dir='ltr'] .lg\:ltr\:mb-md,[dir='ltr'].lg\:ltr\:mb-md {
    margin-bottom: 64rem
  }

  [dir='ltr'] .lg\:ltr\:ml-md,[dir='ltr'].lg\:ltr\:ml-md {
    margin-left: 64rem
  }

  [dir='ltr'] .lg\:ltr\:mt-lg,[dir='ltr'].lg\:ltr\:mt-lg {
    margin-top: 80rem
  }

  [dir='ltr'] .lg\:ltr\:mr-lg,[dir='ltr'].lg\:ltr\:mr-lg {
    margin-right: 80rem
  }

  [dir='ltr'] .lg\:ltr\:mb-lg,[dir='ltr'].lg\:ltr\:mb-lg {
    margin-bottom: 80rem
  }

  [dir='ltr'] .lg\:ltr\:ml-lg,[dir='ltr'].lg\:ltr\:ml-lg {
    margin-left: 80rem
  }

  [dir='ltr'] .lg\:ltr\:mt-xl,[dir='ltr'].lg\:ltr\:mt-xl {
    margin-top: 96rem
  }

  [dir='ltr'] .lg\:ltr\:mr-xl,[dir='ltr'].lg\:ltr\:mr-xl {
    margin-right: 96rem
  }

  [dir='ltr'] .lg\:ltr\:mb-xl,[dir='ltr'].lg\:ltr\:mb-xl {
    margin-bottom: 96rem
  }

  [dir='ltr'] .lg\:ltr\:ml-xl,[dir='ltr'].lg\:ltr\:ml-xl {
    margin-left: 96rem
  }

  [dir='ltr'] .lg\:ltr\:mt-2xl,[dir='ltr'].lg\:ltr\:mt-2xl {
    margin-top: 112rem
  }

  [dir='ltr'] .lg\:ltr\:mr-2xl,[dir='ltr'].lg\:ltr\:mr-2xl {
    margin-right: 112rem
  }

  [dir='ltr'] .lg\:ltr\:mb-2xl,[dir='ltr'].lg\:ltr\:mb-2xl {
    margin-bottom: 112rem
  }

  [dir='ltr'] .lg\:ltr\:ml-2xl,[dir='ltr'].lg\:ltr\:ml-2xl {
    margin-left: 112rem
  }

  [dir='ltr'] .lg\:ltr\:mt-3xl,[dir='ltr'].lg\:ltr\:mt-3xl {
    margin-top: 128rem
  }

  [dir='ltr'] .lg\:ltr\:mr-3xl,[dir='ltr'].lg\:ltr\:mr-3xl {
    margin-right: 128rem
  }

  [dir='ltr'] .lg\:ltr\:mb-3xl,[dir='ltr'].lg\:ltr\:mb-3xl {
    margin-bottom: 128rem
  }

  [dir='ltr'] .lg\:ltr\:ml-3xl,[dir='ltr'].lg\:ltr\:ml-3xl {
    margin-left: 128rem
  }

  [dir='ltr'] .lg\:ltr\:mt-4xl,[dir='ltr'].lg\:ltr\:mt-4xl {
    margin-top: 144rem
  }

  [dir='ltr'] .lg\:ltr\:mr-4xl,[dir='ltr'].lg\:ltr\:mr-4xl {
    margin-right: 144rem
  }

  [dir='ltr'] .lg\:ltr\:mb-4xl,[dir='ltr'].lg\:ltr\:mb-4xl {
    margin-bottom: 144rem
  }

  [dir='ltr'] .lg\:ltr\:ml-4xl,[dir='ltr'].lg\:ltr\:ml-4xl {
    margin-left: 144rem
  }

  [dir='ltr'] .lg\:ltr\:mt-5xl,[dir='ltr'].lg\:ltr\:mt-5xl {
    margin-top: 160rem
  }

  [dir='ltr'] .lg\:ltr\:mr-5xl,[dir='ltr'].lg\:ltr\:mr-5xl {
    margin-right: 160rem
  }

  [dir='ltr'] .lg\:ltr\:mb-5xl,[dir='ltr'].lg\:ltr\:mb-5xl {
    margin-bottom: 160rem
  }

  [dir='ltr'] .lg\:ltr\:ml-5xl,[dir='ltr'].lg\:ltr\:ml-5xl {
    margin-left: 160rem
  }

  [dir='ltr'] .lg\:ltr\:mt-px,[dir='ltr'].lg\:ltr\:mt-px {
    margin-top: 1px
  }

  [dir='ltr'] .lg\:ltr\:mr-px,[dir='ltr'].lg\:ltr\:mr-px {
    margin-right: 1px
  }

  [dir='ltr'] .lg\:ltr\:mb-px,[dir='ltr'].lg\:ltr\:mb-px {
    margin-bottom: 1px
  }

  [dir='ltr'] .lg\:ltr\:ml-px,[dir='ltr'].lg\:ltr\:ml-px {
    margin-left: 1px
  }

  [dir='ltr'] .lg\:ltr\:mt-0\.5,[dir='ltr'].lg\:ltr\:mt-0\.5 {
    margin-top: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:mr-0\.5,[dir='ltr'].lg\:ltr\:mr-0\.5 {
    margin-right: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:mb-0\.5,[dir='ltr'].lg\:ltr\:mb-0\.5 {
    margin-bottom: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:ml-0\.5,[dir='ltr'].lg\:ltr\:ml-0\.5 {
    margin-left: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:mt-1\.5,[dir='ltr'].lg\:ltr\:mt-1\.5 {
    margin-top: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:mr-1\.5,[dir='ltr'].lg\:ltr\:mr-1\.5 {
    margin-right: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:mb-1\.5,[dir='ltr'].lg\:ltr\:mb-1\.5 {
    margin-bottom: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:ml-1\.5,[dir='ltr'].lg\:ltr\:ml-1\.5 {
    margin-left: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:mt-2\.5,[dir='ltr'].lg\:ltr\:mt-2\.5 {
    margin-top: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:mr-2\.5,[dir='ltr'].lg\:ltr\:mr-2\.5 {
    margin-right: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:mb-2\.5,[dir='ltr'].lg\:ltr\:mb-2\.5 {
    margin-bottom: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:ml-2\.5,[dir='ltr'].lg\:ltr\:ml-2\.5 {
    margin-left: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:mt-3\.5,[dir='ltr'].lg\:ltr\:mt-3\.5 {
    margin-top: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:mr-3\.5,[dir='ltr'].lg\:ltr\:mr-3\.5 {
    margin-right: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:mb-3\.5,[dir='ltr'].lg\:ltr\:mb-3\.5 {
    margin-bottom: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:ml-3\.5,[dir='ltr'].lg\:ltr\:ml-3\.5 {
    margin-left: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-1,[dir='ltr'].lg\:ltr\:-mt-1 {
    margin-top: -0.1rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-1,[dir='ltr'].lg\:ltr\:-mr-1 {
    margin-right: -0.1rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-1,[dir='ltr'].lg\:ltr\:-mb-1 {
    margin-bottom: -0.1rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-1,[dir='ltr'].lg\:ltr\:-ml-1 {
    margin-left: -0.1rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-2,[dir='ltr'].lg\:ltr\:-mt-2 {
    margin-top: -0.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-2,[dir='ltr'].lg\:ltr\:-mr-2 {
    margin-right: -0.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-2,[dir='ltr'].lg\:ltr\:-mb-2 {
    margin-bottom: -0.2rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-2,[dir='ltr'].lg\:ltr\:-ml-2 {
    margin-left: -0.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-3,[dir='ltr'].lg\:ltr\:-mt-3 {
    margin-top: -0.3rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-3,[dir='ltr'].lg\:ltr\:-mr-3 {
    margin-right: -0.3rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-3,[dir='ltr'].lg\:ltr\:-mb-3 {
    margin-bottom: -0.3rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-3,[dir='ltr'].lg\:ltr\:-ml-3 {
    margin-left: -0.3rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-4,[dir='ltr'].lg\:ltr\:-mt-4 {
    margin-top: -0.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-4,[dir='ltr'].lg\:ltr\:-mr-4 {
    margin-right: -0.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-4,[dir='ltr'].lg\:ltr\:-mb-4 {
    margin-bottom: -0.4rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-4,[dir='ltr'].lg\:ltr\:-ml-4 {
    margin-left: -0.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-5,[dir='ltr'].lg\:ltr\:-mt-5 {
    margin-top: -0.5rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-5,[dir='ltr'].lg\:ltr\:-mr-5 {
    margin-right: -0.5rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-5,[dir='ltr'].lg\:ltr\:-mb-5 {
    margin-bottom: -0.5rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-5,[dir='ltr'].lg\:ltr\:-ml-5 {
    margin-left: -0.5rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-6,[dir='ltr'].lg\:ltr\:-mt-6 {
    margin-top: -0.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-6,[dir='ltr'].lg\:ltr\:-mr-6 {
    margin-right: -0.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-6,[dir='ltr'].lg\:ltr\:-mb-6 {
    margin-bottom: -0.6rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-6,[dir='ltr'].lg\:ltr\:-ml-6 {
    margin-left: -0.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-7,[dir='ltr'].lg\:ltr\:-mt-7 {
    margin-top: -0.7rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-7,[dir='ltr'].lg\:ltr\:-mr-7 {
    margin-right: -0.7rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-7,[dir='ltr'].lg\:ltr\:-mb-7 {
    margin-bottom: -0.7rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-7,[dir='ltr'].lg\:ltr\:-ml-7 {
    margin-left: -0.7rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-8,[dir='ltr'].lg\:ltr\:-mt-8 {
    margin-top: -0.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-8,[dir='ltr'].lg\:ltr\:-mr-8 {
    margin-right: -0.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-8,[dir='ltr'].lg\:ltr\:-mb-8 {
    margin-bottom: -0.8rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-8,[dir='ltr'].lg\:ltr\:-ml-8 {
    margin-left: -0.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-9,[dir='ltr'].lg\:ltr\:-mt-9 {
    margin-top: -0.9rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-9,[dir='ltr'].lg\:ltr\:-mr-9 {
    margin-right: -0.9rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-9,[dir='ltr'].lg\:ltr\:-mb-9 {
    margin-bottom: -0.9rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-9,[dir='ltr'].lg\:ltr\:-ml-9 {
    margin-left: -0.9rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-10,[dir='ltr'].lg\:ltr\:-mt-10 {
    margin-top: -1rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-10,[dir='ltr'].lg\:ltr\:-mr-10 {
    margin-right: -1rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-10,[dir='ltr'].lg\:ltr\:-mb-10 {
    margin-bottom: -1rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-10,[dir='ltr'].lg\:ltr\:-ml-10 {
    margin-left: -1rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-12,[dir='ltr'].lg\:ltr\:-mt-12 {
    margin-top: -1.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-12,[dir='ltr'].lg\:ltr\:-mr-12 {
    margin-right: -1.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-12,[dir='ltr'].lg\:ltr\:-mb-12 {
    margin-bottom: -1.2rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-12,[dir='ltr'].lg\:ltr\:-ml-12 {
    margin-left: -1.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-14,[dir='ltr'].lg\:ltr\:-mt-14 {
    margin-top: -1.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-14,[dir='ltr'].lg\:ltr\:-mr-14 {
    margin-right: -1.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-14,[dir='ltr'].lg\:ltr\:-mb-14 {
    margin-bottom: -1.4rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-14,[dir='ltr'].lg\:ltr\:-ml-14 {
    margin-left: -1.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-16,[dir='ltr'].lg\:ltr\:-mt-16 {
    margin-top: -1.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-16,[dir='ltr'].lg\:ltr\:-mr-16 {
    margin-right: -1.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-16,[dir='ltr'].lg\:ltr\:-mb-16 {
    margin-bottom: -1.6rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-16,[dir='ltr'].lg\:ltr\:-ml-16 {
    margin-left: -1.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-20,[dir='ltr'].lg\:ltr\:-mt-20 {
    margin-top: -2rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-20,[dir='ltr'].lg\:ltr\:-mr-20 {
    margin-right: -2rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-20,[dir='ltr'].lg\:ltr\:-mb-20 {
    margin-bottom: -2rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-20,[dir='ltr'].lg\:ltr\:-ml-20 {
    margin-left: -2rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-24,[dir='ltr'].lg\:ltr\:-mt-24 {
    margin-top: -2.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-24,[dir='ltr'].lg\:ltr\:-mr-24 {
    margin-right: -2.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-24,[dir='ltr'].lg\:ltr\:-mb-24 {
    margin-bottom: -2.4rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-24,[dir='ltr'].lg\:ltr\:-ml-24 {
    margin-left: -2.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-28,[dir='ltr'].lg\:ltr\:-mt-28 {
    margin-top: -2.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-28,[dir='ltr'].lg\:ltr\:-mr-28 {
    margin-right: -2.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-28,[dir='ltr'].lg\:ltr\:-mb-28 {
    margin-bottom: -2.8rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-28,[dir='ltr'].lg\:ltr\:-ml-28 {
    margin-left: -2.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-32,[dir='ltr'].lg\:ltr\:-mt-32 {
    margin-top: -3.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-32,[dir='ltr'].lg\:ltr\:-mr-32 {
    margin-right: -3.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-32,[dir='ltr'].lg\:ltr\:-mb-32 {
    margin-bottom: -3.2rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-32,[dir='ltr'].lg\:ltr\:-ml-32 {
    margin-left: -3.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-36,[dir='ltr'].lg\:ltr\:-mt-36 {
    margin-top: -3.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-36,[dir='ltr'].lg\:ltr\:-mr-36 {
    margin-right: -3.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-36,[dir='ltr'].lg\:ltr\:-mb-36 {
    margin-bottom: -3.6rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-36,[dir='ltr'].lg\:ltr\:-ml-36 {
    margin-left: -3.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-40,[dir='ltr'].lg\:ltr\:-mt-40 {
    margin-top: -4rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-40,[dir='ltr'].lg\:ltr\:-mr-40 {
    margin-right: -4rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-40,[dir='ltr'].lg\:ltr\:-mb-40 {
    margin-bottom: -4rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-40,[dir='ltr'].lg\:ltr\:-ml-40 {
    margin-left: -4rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-44,[dir='ltr'].lg\:ltr\:-mt-44 {
    margin-top: -4.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-44,[dir='ltr'].lg\:ltr\:-mr-44 {
    margin-right: -4.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-44,[dir='ltr'].lg\:ltr\:-mb-44 {
    margin-bottom: -4.4rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-44,[dir='ltr'].lg\:ltr\:-ml-44 {
    margin-left: -4.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-48,[dir='ltr'].lg\:ltr\:-mt-48 {
    margin-top: -4.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-48,[dir='ltr'].lg\:ltr\:-mr-48 {
    margin-right: -4.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-48,[dir='ltr'].lg\:ltr\:-mb-48 {
    margin-bottom: -4.8rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-48,[dir='ltr'].lg\:ltr\:-ml-48 {
    margin-left: -4.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-52,[dir='ltr'].lg\:ltr\:-mt-52 {
    margin-top: -5.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-52,[dir='ltr'].lg\:ltr\:-mr-52 {
    margin-right: -5.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-52,[dir='ltr'].lg\:ltr\:-mb-52 {
    margin-bottom: -5.2rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-52,[dir='ltr'].lg\:ltr\:-ml-52 {
    margin-left: -5.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-56,[dir='ltr'].lg\:ltr\:-mt-56 {
    margin-top: -5.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-56,[dir='ltr'].lg\:ltr\:-mr-56 {
    margin-right: -5.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-56,[dir='ltr'].lg\:ltr\:-mb-56 {
    margin-bottom: -5.6rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-56,[dir='ltr'].lg\:ltr\:-ml-56 {
    margin-left: -5.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-60,[dir='ltr'].lg\:ltr\:-mt-60 {
    margin-top: -6rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-60,[dir='ltr'].lg\:ltr\:-mr-60 {
    margin-right: -6rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-60,[dir='ltr'].lg\:ltr\:-mb-60 {
    margin-bottom: -6rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-60,[dir='ltr'].lg\:ltr\:-ml-60 {
    margin-left: -6rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-64,[dir='ltr'].lg\:ltr\:-mt-64 {
    margin-top: -6.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-64,[dir='ltr'].lg\:ltr\:-mr-64 {
    margin-right: -6.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-64,[dir='ltr'].lg\:ltr\:-mb-64 {
    margin-bottom: -6.4rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-64,[dir='ltr'].lg\:ltr\:-ml-64 {
    margin-left: -6.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-68,[dir='ltr'].lg\:ltr\:-mt-68 {
    margin-top: -6.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-68,[dir='ltr'].lg\:ltr\:-mr-68 {
    margin-right: -6.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-68,[dir='ltr'].lg\:ltr\:-mb-68 {
    margin-bottom: -6.8rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-68,[dir='ltr'].lg\:ltr\:-ml-68 {
    margin-left: -6.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-72,[dir='ltr'].lg\:ltr\:-mt-72 {
    margin-top: -7.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-72,[dir='ltr'].lg\:ltr\:-mr-72 {
    margin-right: -7.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-72,[dir='ltr'].lg\:ltr\:-mb-72 {
    margin-bottom: -7.2rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-72,[dir='ltr'].lg\:ltr\:-ml-72 {
    margin-left: -7.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-76,[dir='ltr'].lg\:ltr\:-mt-76 {
    margin-top: -7.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-76,[dir='ltr'].lg\:ltr\:-mr-76 {
    margin-right: -7.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-76,[dir='ltr'].lg\:ltr\:-mb-76 {
    margin-bottom: -7.6rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-76,[dir='ltr'].lg\:ltr\:-ml-76 {
    margin-left: -7.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-80,[dir='ltr'].lg\:ltr\:-mt-80 {
    margin-top: -8rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-80,[dir='ltr'].lg\:ltr\:-mr-80 {
    margin-right: -8rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-80,[dir='ltr'].lg\:ltr\:-mb-80 {
    margin-bottom: -8rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-80,[dir='ltr'].lg\:ltr\:-ml-80 {
    margin-left: -8rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-84,[dir='ltr'].lg\:ltr\:-mt-84 {
    margin-top: -8.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-84,[dir='ltr'].lg\:ltr\:-mr-84 {
    margin-right: -8.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-84,[dir='ltr'].lg\:ltr\:-mb-84 {
    margin-bottom: -8.4rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-84,[dir='ltr'].lg\:ltr\:-ml-84 {
    margin-left: -8.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-88,[dir='ltr'].lg\:ltr\:-mt-88 {
    margin-top: -8.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-88,[dir='ltr'].lg\:ltr\:-mr-88 {
    margin-right: -8.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-88,[dir='ltr'].lg\:ltr\:-mb-88 {
    margin-bottom: -8.8rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-88,[dir='ltr'].lg\:ltr\:-ml-88 {
    margin-left: -8.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-92,[dir='ltr'].lg\:ltr\:-mt-92 {
    margin-top: -9.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-92,[dir='ltr'].lg\:ltr\:-mr-92 {
    margin-right: -9.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-92,[dir='ltr'].lg\:ltr\:-mb-92 {
    margin-bottom: -9.2rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-92,[dir='ltr'].lg\:ltr\:-ml-92 {
    margin-left: -9.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-96,[dir='ltr'].lg\:ltr\:-mt-96 {
    margin-top: -9.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-96,[dir='ltr'].lg\:ltr\:-mr-96 {
    margin-right: -9.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-96,[dir='ltr'].lg\:ltr\:-mb-96 {
    margin-bottom: -9.6rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-96,[dir='ltr'].lg\:ltr\:-ml-96 {
    margin-left: -9.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-128,[dir='ltr'].lg\:ltr\:-mt-128 {
    margin-top: -12.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-128,[dir='ltr'].lg\:ltr\:-mr-128 {
    margin-right: -12.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-128,[dir='ltr'].lg\:ltr\:-mb-128 {
    margin-bottom: -12.8rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-128,[dir='ltr'].lg\:ltr\:-ml-128 {
    margin-left: -12.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-136,[dir='ltr'].lg\:ltr\:-mt-136 {
    margin-top: -13.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-136,[dir='ltr'].lg\:ltr\:-mr-136 {
    margin-right: -13.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-136,[dir='ltr'].lg\:ltr\:-mb-136 {
    margin-bottom: -13.6rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-136,[dir='ltr'].lg\:ltr\:-ml-136 {
    margin-left: -13.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-160,[dir='ltr'].lg\:ltr\:-mt-160 {
    margin-top: -16rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-160,[dir='ltr'].lg\:ltr\:-mr-160 {
    margin-right: -16rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-160,[dir='ltr'].lg\:ltr\:-mb-160 {
    margin-bottom: -16rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-160,[dir='ltr'].lg\:ltr\:-ml-160 {
    margin-left: -16rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-192,[dir='ltr'].lg\:ltr\:-mt-192 {
    margin-top: -19.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-192,[dir='ltr'].lg\:ltr\:-mr-192 {
    margin-right: -19.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-192,[dir='ltr'].lg\:ltr\:-mb-192 {
    margin-bottom: -19.2rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-192,[dir='ltr'].lg\:ltr\:-ml-192 {
    margin-left: -19.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-200,[dir='ltr'].lg\:ltr\:-mt-200 {
    margin-top: -20rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-200,[dir='ltr'].lg\:ltr\:-mr-200 {
    margin-right: -20rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-200,[dir='ltr'].lg\:ltr\:-mb-200 {
    margin-bottom: -20rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-200,[dir='ltr'].lg\:ltr\:-ml-200 {
    margin-left: -20rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-208,[dir='ltr'].lg\:ltr\:-mt-208 {
    margin-top: -20.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-208,[dir='ltr'].lg\:ltr\:-mr-208 {
    margin-right: -20.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-208,[dir='ltr'].lg\:ltr\:-mb-208 {
    margin-bottom: -20.8rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-208,[dir='ltr'].lg\:ltr\:-ml-208 {
    margin-left: -20.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-216,[dir='ltr'].lg\:ltr\:-mt-216 {
    margin-top: -21.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-216,[dir='ltr'].lg\:ltr\:-mr-216 {
    margin-right: -21.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-216,[dir='ltr'].lg\:ltr\:-mb-216 {
    margin-bottom: -21.6rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-216,[dir='ltr'].lg\:ltr\:-ml-216 {
    margin-left: -21.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-224,[dir='ltr'].lg\:ltr\:-mt-224 {
    margin-top: -22.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-224,[dir='ltr'].lg\:ltr\:-mr-224 {
    margin-right: -22.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-224,[dir='ltr'].lg\:ltr\:-mb-224 {
    margin-bottom: -22.4rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-224,[dir='ltr'].lg\:ltr\:-ml-224 {
    margin-left: -22.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-256,[dir='ltr'].lg\:ltr\:-mt-256 {
    margin-top: -25.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-256,[dir='ltr'].lg\:ltr\:-mr-256 {
    margin-right: -25.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-256,[dir='ltr'].lg\:ltr\:-mb-256 {
    margin-bottom: -25.6rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-256,[dir='ltr'].lg\:ltr\:-ml-256 {
    margin-left: -25.6rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-288,[dir='ltr'].lg\:ltr\:-mt-288 {
    margin-top: -28.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-288,[dir='ltr'].lg\:ltr\:-mr-288 {
    margin-right: -28.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-288,[dir='ltr'].lg\:ltr\:-mb-288 {
    margin-bottom: -28.8rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-288,[dir='ltr'].lg\:ltr\:-ml-288 {
    margin-left: -28.8rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-320,[dir='ltr'].lg\:ltr\:-mt-320 {
    margin-top: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-320,[dir='ltr'].lg\:ltr\:-mr-320 {
    margin-right: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-320,[dir='ltr'].lg\:ltr\:-mb-320 {
    margin-bottom: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-320,[dir='ltr'].lg\:ltr\:-ml-320 {
    margin-left: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-360,[dir='ltr'].lg\:ltr\:-mt-360 {
    margin-top: -36rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-360,[dir='ltr'].lg\:ltr\:-mr-360 {
    margin-right: -36rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-360,[dir='ltr'].lg\:ltr\:-mb-360 {
    margin-bottom: -36rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-360,[dir='ltr'].lg\:ltr\:-ml-360 {
    margin-left: -36rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-384,[dir='ltr'].lg\:ltr\:-mt-384 {
    margin-top: -38.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-384,[dir='ltr'].lg\:ltr\:-mr-384 {
    margin-right: -38.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-384,[dir='ltr'].lg\:ltr\:-mb-384 {
    margin-bottom: -38.4rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-384,[dir='ltr'].lg\:ltr\:-ml-384 {
    margin-left: -38.4rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-400,[dir='ltr'].lg\:ltr\:-mt-400 {
    margin-top: -40rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-400,[dir='ltr'].lg\:ltr\:-mr-400 {
    margin-right: -40rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-400,[dir='ltr'].lg\:ltr\:-mb-400 {
    margin-bottom: -40rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-400,[dir='ltr'].lg\:ltr\:-ml-400 {
    margin-left: -40rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-512,[dir='ltr'].lg\:ltr\:-mt-512 {
    margin-top: -51.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-512,[dir='ltr'].lg\:ltr\:-mr-512 {
    margin-right: -51.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-512,[dir='ltr'].lg\:ltr\:-mb-512 {
    margin-bottom: -51.2rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-512,[dir='ltr'].lg\:ltr\:-ml-512 {
    margin-left: -51.2rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-640,[dir='ltr'].lg\:ltr\:-mt-640 {
    margin-top: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-640,[dir='ltr'].lg\:ltr\:-mr-640 {
    margin-right: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-640,[dir='ltr'].lg\:ltr\:-mb-640 {
    margin-bottom: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-640,[dir='ltr'].lg\:ltr\:-ml-640 {
    margin-left: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-xs,[dir='ltr'].lg\:ltr\:-mt-xs {
    margin-top: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-xs,[dir='ltr'].lg\:ltr\:-mr-xs {
    margin-right: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-xs,[dir='ltr'].lg\:ltr\:-mb-xs {
    margin-bottom: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-xs,[dir='ltr'].lg\:ltr\:-ml-xs {
    margin-left: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-sm,[dir='ltr'].lg\:ltr\:-mt-sm {
    margin-top: -48rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-sm,[dir='ltr'].lg\:ltr\:-mr-sm {
    margin-right: -48rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-sm,[dir='ltr'].lg\:ltr\:-mb-sm {
    margin-bottom: -48rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-sm,[dir='ltr'].lg\:ltr\:-ml-sm {
    margin-left: -48rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-md,[dir='ltr'].lg\:ltr\:-mt-md {
    margin-top: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-md,[dir='ltr'].lg\:ltr\:-mr-md {
    margin-right: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-md,[dir='ltr'].lg\:ltr\:-mb-md {
    margin-bottom: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-md,[dir='ltr'].lg\:ltr\:-ml-md {
    margin-left: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-lg,[dir='ltr'].lg\:ltr\:-mt-lg {
    margin-top: -80rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-lg,[dir='ltr'].lg\:ltr\:-mr-lg {
    margin-right: -80rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-lg,[dir='ltr'].lg\:ltr\:-mb-lg {
    margin-bottom: -80rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-lg,[dir='ltr'].lg\:ltr\:-ml-lg {
    margin-left: -80rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-xl,[dir='ltr'].lg\:ltr\:-mt-xl {
    margin-top: -96rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-xl,[dir='ltr'].lg\:ltr\:-mr-xl {
    margin-right: -96rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-xl,[dir='ltr'].lg\:ltr\:-mb-xl {
    margin-bottom: -96rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-xl,[dir='ltr'].lg\:ltr\:-ml-xl {
    margin-left: -96rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-2xl,[dir='ltr'].lg\:ltr\:-mt-2xl {
    margin-top: -112rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-2xl,[dir='ltr'].lg\:ltr\:-mr-2xl {
    margin-right: -112rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-2xl,[dir='ltr'].lg\:ltr\:-mb-2xl {
    margin-bottom: -112rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-2xl,[dir='ltr'].lg\:ltr\:-ml-2xl {
    margin-left: -112rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-3xl,[dir='ltr'].lg\:ltr\:-mt-3xl {
    margin-top: -128rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-3xl,[dir='ltr'].lg\:ltr\:-mr-3xl {
    margin-right: -128rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-3xl,[dir='ltr'].lg\:ltr\:-mb-3xl {
    margin-bottom: -128rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-3xl,[dir='ltr'].lg\:ltr\:-ml-3xl {
    margin-left: -128rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-4xl,[dir='ltr'].lg\:ltr\:-mt-4xl {
    margin-top: -144rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-4xl,[dir='ltr'].lg\:ltr\:-mr-4xl {
    margin-right: -144rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-4xl,[dir='ltr'].lg\:ltr\:-mb-4xl {
    margin-bottom: -144rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-4xl,[dir='ltr'].lg\:ltr\:-ml-4xl {
    margin-left: -144rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-5xl,[dir='ltr'].lg\:ltr\:-mt-5xl {
    margin-top: -160rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-5xl,[dir='ltr'].lg\:ltr\:-mr-5xl {
    margin-right: -160rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-5xl,[dir='ltr'].lg\:ltr\:-mb-5xl {
    margin-bottom: -160rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-5xl,[dir='ltr'].lg\:ltr\:-ml-5xl {
    margin-left: -160rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-px,[dir='ltr'].lg\:ltr\:-mt-px {
    margin-top: -1px
  }

  [dir='ltr'] .lg\:ltr\:-mr-px,[dir='ltr'].lg\:ltr\:-mr-px {
    margin-right: -1px
  }

  [dir='ltr'] .lg\:ltr\:-mb-px,[dir='ltr'].lg\:ltr\:-mb-px {
    margin-bottom: -1px
  }

  [dir='ltr'] .lg\:ltr\:-ml-px,[dir='ltr'].lg\:ltr\:-ml-px {
    margin-left: -1px
  }

  [dir='ltr'] .lg\:ltr\:-mt-0\.5,[dir='ltr'].lg\:ltr\:-mt-0\.5 {
    margin-top: -0.05rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-0\.5,[dir='ltr'].lg\:ltr\:-mr-0\.5 {
    margin-right: -0.05rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-0\.5,[dir='ltr'].lg\:ltr\:-mb-0\.5 {
    margin-bottom: -0.05rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-0\.5,[dir='ltr'].lg\:ltr\:-ml-0\.5 {
    margin-left: -0.05rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-1\.5,[dir='ltr'].lg\:ltr\:-mt-1\.5 {
    margin-top: -0.15rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-1\.5,[dir='ltr'].lg\:ltr\:-mr-1\.5 {
    margin-right: -0.15rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-1\.5,[dir='ltr'].lg\:ltr\:-mb-1\.5 {
    margin-bottom: -0.15rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-1\.5,[dir='ltr'].lg\:ltr\:-ml-1\.5 {
    margin-left: -0.15rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-2\.5,[dir='ltr'].lg\:ltr\:-mt-2\.5 {
    margin-top: -0.25rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-2\.5,[dir='ltr'].lg\:ltr\:-mr-2\.5 {
    margin-right: -0.25rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-2\.5,[dir='ltr'].lg\:ltr\:-mb-2\.5 {
    margin-bottom: -0.25rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-2\.5,[dir='ltr'].lg\:ltr\:-ml-2\.5 {
    margin-left: -0.25rem
  }

  [dir='ltr'] .lg\:ltr\:-mt-3\.5,[dir='ltr'].lg\:ltr\:-mt-3\.5 {
    margin-top: -0.35rem
  }

  [dir='ltr'] .lg\:ltr\:-mr-3\.5,[dir='ltr'].lg\:ltr\:-mr-3\.5 {
    margin-right: -0.35rem
  }

  [dir='ltr'] .lg\:ltr\:-mb-3\.5,[dir='ltr'].lg\:ltr\:-mb-3\.5 {
    margin-bottom: -0.35rem
  }

  [dir='ltr'] .lg\:ltr\:-ml-3\.5,[dir='ltr'].lg\:ltr\:-ml-3\.5 {
    margin-left: -0.35rem
  }

  [dir='rtl'] .lg\:rtl\:m-0,[dir='rtl'].lg\:rtl\:m-0 {
    margin: 0
  }

  [dir='rtl'] .lg\:rtl\:m-1,[dir='rtl'].lg\:rtl\:m-1 {
    margin: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:m-2,[dir='rtl'].lg\:rtl\:m-2 {
    margin: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:m-3,[dir='rtl'].lg\:rtl\:m-3 {
    margin: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:m-4,[dir='rtl'].lg\:rtl\:m-4 {
    margin: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:m-5,[dir='rtl'].lg\:rtl\:m-5 {
    margin: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:m-6,[dir='rtl'].lg\:rtl\:m-6 {
    margin: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:m-7,[dir='rtl'].lg\:rtl\:m-7 {
    margin: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:m-8,[dir='rtl'].lg\:rtl\:m-8 {
    margin: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:m-9,[dir='rtl'].lg\:rtl\:m-9 {
    margin: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:m-10,[dir='rtl'].lg\:rtl\:m-10 {
    margin: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:m-12,[dir='rtl'].lg\:rtl\:m-12 {
    margin: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:m-14,[dir='rtl'].lg\:rtl\:m-14 {
    margin: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:m-16,[dir='rtl'].lg\:rtl\:m-16 {
    margin: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:m-20,[dir='rtl'].lg\:rtl\:m-20 {
    margin: 2rem
  }

  [dir='rtl'] .lg\:rtl\:m-24,[dir='rtl'].lg\:rtl\:m-24 {
    margin: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:m-28,[dir='rtl'].lg\:rtl\:m-28 {
    margin: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:m-32,[dir='rtl'].lg\:rtl\:m-32 {
    margin: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:m-36,[dir='rtl'].lg\:rtl\:m-36 {
    margin: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:m-40,[dir='rtl'].lg\:rtl\:m-40 {
    margin: 4rem
  }

  [dir='rtl'] .lg\:rtl\:m-44,[dir='rtl'].lg\:rtl\:m-44 {
    margin: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:m-48,[dir='rtl'].lg\:rtl\:m-48 {
    margin: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:m-52,[dir='rtl'].lg\:rtl\:m-52 {
    margin: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:m-56,[dir='rtl'].lg\:rtl\:m-56 {
    margin: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:m-60,[dir='rtl'].lg\:rtl\:m-60 {
    margin: 6rem
  }

  [dir='rtl'] .lg\:rtl\:m-64,[dir='rtl'].lg\:rtl\:m-64 {
    margin: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:m-68,[dir='rtl'].lg\:rtl\:m-68 {
    margin: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:m-72,[dir='rtl'].lg\:rtl\:m-72 {
    margin: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:m-76,[dir='rtl'].lg\:rtl\:m-76 {
    margin: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:m-80,[dir='rtl'].lg\:rtl\:m-80 {
    margin: 8rem
  }

  [dir='rtl'] .lg\:rtl\:m-84,[dir='rtl'].lg\:rtl\:m-84 {
    margin: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:m-88,[dir='rtl'].lg\:rtl\:m-88 {
    margin: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:m-92,[dir='rtl'].lg\:rtl\:m-92 {
    margin: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:m-96,[dir='rtl'].lg\:rtl\:m-96 {
    margin: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:m-128,[dir='rtl'].lg\:rtl\:m-128 {
    margin: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:m-136,[dir='rtl'].lg\:rtl\:m-136 {
    margin: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:m-160,[dir='rtl'].lg\:rtl\:m-160 {
    margin: 16rem
  }

  [dir='rtl'] .lg\:rtl\:m-192,[dir='rtl'].lg\:rtl\:m-192 {
    margin: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:m-200,[dir='rtl'].lg\:rtl\:m-200 {
    margin: 20rem
  }

  [dir='rtl'] .lg\:rtl\:m-208,[dir='rtl'].lg\:rtl\:m-208 {
    margin: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:m-216,[dir='rtl'].lg\:rtl\:m-216 {
    margin: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:m-224,[dir='rtl'].lg\:rtl\:m-224 {
    margin: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:m-256,[dir='rtl'].lg\:rtl\:m-256 {
    margin: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:m-288,[dir='rtl'].lg\:rtl\:m-288 {
    margin: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:m-320,[dir='rtl'].lg\:rtl\:m-320 {
    margin: 32rem
  }

  [dir='rtl'] .lg\:rtl\:m-360,[dir='rtl'].lg\:rtl\:m-360 {
    margin: 36rem
  }

  [dir='rtl'] .lg\:rtl\:m-384,[dir='rtl'].lg\:rtl\:m-384 {
    margin: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:m-400,[dir='rtl'].lg\:rtl\:m-400 {
    margin: 40rem
  }

  [dir='rtl'] .lg\:rtl\:m-512,[dir='rtl'].lg\:rtl\:m-512 {
    margin: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:m-640,[dir='rtl'].lg\:rtl\:m-640 {
    margin: 64rem
  }

  [dir='rtl'] .lg\:rtl\:m-auto,[dir='rtl'].lg\:rtl\:m-auto {
    margin: auto
  }

  [dir='rtl'] .lg\:rtl\:m-xs,[dir='rtl'].lg\:rtl\:m-xs {
    margin: 32rem
  }

  [dir='rtl'] .lg\:rtl\:m-sm,[dir='rtl'].lg\:rtl\:m-sm {
    margin: 48rem
  }

  [dir='rtl'] .lg\:rtl\:m-md,[dir='rtl'].lg\:rtl\:m-md {
    margin: 64rem
  }

  [dir='rtl'] .lg\:rtl\:m-lg,[dir='rtl'].lg\:rtl\:m-lg {
    margin: 80rem
  }

  [dir='rtl'] .lg\:rtl\:m-xl,[dir='rtl'].lg\:rtl\:m-xl {
    margin: 96rem
  }

  [dir='rtl'] .lg\:rtl\:m-2xl,[dir='rtl'].lg\:rtl\:m-2xl {
    margin: 112rem
  }

  [dir='rtl'] .lg\:rtl\:m-3xl,[dir='rtl'].lg\:rtl\:m-3xl {
    margin: 128rem
  }

  [dir='rtl'] .lg\:rtl\:m-4xl,[dir='rtl'].lg\:rtl\:m-4xl {
    margin: 144rem
  }

  [dir='rtl'] .lg\:rtl\:m-5xl,[dir='rtl'].lg\:rtl\:m-5xl {
    margin: 160rem
  }

  [dir='rtl'] .lg\:rtl\:m-px,[dir='rtl'].lg\:rtl\:m-px {
    margin: 1px
  }

  [dir='rtl'] .lg\:rtl\:m-0\.5,[dir='rtl'].lg\:rtl\:m-0\.5 {
    margin: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:m-1\.5,[dir='rtl'].lg\:rtl\:m-1\.5 {
    margin: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:m-2\.5,[dir='rtl'].lg\:rtl\:m-2\.5 {
    margin: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:m-3\.5,[dir='rtl'].lg\:rtl\:m-3\.5 {
    margin: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:-m-1,[dir='rtl'].lg\:rtl\:-m-1 {
    margin: -0.1rem
  }

  [dir='rtl'] .lg\:rtl\:-m-2,[dir='rtl'].lg\:rtl\:-m-2 {
    margin: -0.2rem
  }

  [dir='rtl'] .lg\:rtl\:-m-3,[dir='rtl'].lg\:rtl\:-m-3 {
    margin: -0.3rem
  }

  [dir='rtl'] .lg\:rtl\:-m-4,[dir='rtl'].lg\:rtl\:-m-4 {
    margin: -0.4rem
  }

  [dir='rtl'] .lg\:rtl\:-m-5,[dir='rtl'].lg\:rtl\:-m-5 {
    margin: -0.5rem
  }

  [dir='rtl'] .lg\:rtl\:-m-6,[dir='rtl'].lg\:rtl\:-m-6 {
    margin: -0.6rem
  }

  [dir='rtl'] .lg\:rtl\:-m-7,[dir='rtl'].lg\:rtl\:-m-7 {
    margin: -0.7rem
  }

  [dir='rtl'] .lg\:rtl\:-m-8,[dir='rtl'].lg\:rtl\:-m-8 {
    margin: -0.8rem
  }

  [dir='rtl'] .lg\:rtl\:-m-9,[dir='rtl'].lg\:rtl\:-m-9 {
    margin: -0.9rem
  }

  [dir='rtl'] .lg\:rtl\:-m-10,[dir='rtl'].lg\:rtl\:-m-10 {
    margin: -1rem
  }

  [dir='rtl'] .lg\:rtl\:-m-12,[dir='rtl'].lg\:rtl\:-m-12 {
    margin: -1.2rem
  }

  [dir='rtl'] .lg\:rtl\:-m-14,[dir='rtl'].lg\:rtl\:-m-14 {
    margin: -1.4rem
  }

  [dir='rtl'] .lg\:rtl\:-m-16,[dir='rtl'].lg\:rtl\:-m-16 {
    margin: -1.6rem
  }

  [dir='rtl'] .lg\:rtl\:-m-20,[dir='rtl'].lg\:rtl\:-m-20 {
    margin: -2rem
  }

  [dir='rtl'] .lg\:rtl\:-m-24,[dir='rtl'].lg\:rtl\:-m-24 {
    margin: -2.4rem
  }

  [dir='rtl'] .lg\:rtl\:-m-28,[dir='rtl'].lg\:rtl\:-m-28 {
    margin: -2.8rem
  }

  [dir='rtl'] .lg\:rtl\:-m-32,[dir='rtl'].lg\:rtl\:-m-32 {
    margin: -3.2rem
  }

  [dir='rtl'] .lg\:rtl\:-m-36,[dir='rtl'].lg\:rtl\:-m-36 {
    margin: -3.6rem
  }

  [dir='rtl'] .lg\:rtl\:-m-40,[dir='rtl'].lg\:rtl\:-m-40 {
    margin: -4rem
  }

  [dir='rtl'] .lg\:rtl\:-m-44,[dir='rtl'].lg\:rtl\:-m-44 {
    margin: -4.4rem
  }

  [dir='rtl'] .lg\:rtl\:-m-48,[dir='rtl'].lg\:rtl\:-m-48 {
    margin: -4.8rem
  }

  [dir='rtl'] .lg\:rtl\:-m-52,[dir='rtl'].lg\:rtl\:-m-52 {
    margin: -5.2rem
  }

  [dir='rtl'] .lg\:rtl\:-m-56,[dir='rtl'].lg\:rtl\:-m-56 {
    margin: -5.6rem
  }

  [dir='rtl'] .lg\:rtl\:-m-60,[dir='rtl'].lg\:rtl\:-m-60 {
    margin: -6rem
  }

  [dir='rtl'] .lg\:rtl\:-m-64,[dir='rtl'].lg\:rtl\:-m-64 {
    margin: -6.4rem
  }

  [dir='rtl'] .lg\:rtl\:-m-68,[dir='rtl'].lg\:rtl\:-m-68 {
    margin: -6.8rem
  }

  [dir='rtl'] .lg\:rtl\:-m-72,[dir='rtl'].lg\:rtl\:-m-72 {
    margin: -7.2rem
  }

  [dir='rtl'] .lg\:rtl\:-m-76,[dir='rtl'].lg\:rtl\:-m-76 {
    margin: -7.6rem
  }

  [dir='rtl'] .lg\:rtl\:-m-80,[dir='rtl'].lg\:rtl\:-m-80 {
    margin: -8rem
  }

  [dir='rtl'] .lg\:rtl\:-m-84,[dir='rtl'].lg\:rtl\:-m-84 {
    margin: -8.4rem
  }

  [dir='rtl'] .lg\:rtl\:-m-88,[dir='rtl'].lg\:rtl\:-m-88 {
    margin: -8.8rem
  }

  [dir='rtl'] .lg\:rtl\:-m-92,[dir='rtl'].lg\:rtl\:-m-92 {
    margin: -9.2rem
  }

  [dir='rtl'] .lg\:rtl\:-m-96,[dir='rtl'].lg\:rtl\:-m-96 {
    margin: -9.6rem
  }

  [dir='rtl'] .lg\:rtl\:-m-128,[dir='rtl'].lg\:rtl\:-m-128 {
    margin: -12.8rem
  }

  [dir='rtl'] .lg\:rtl\:-m-136,[dir='rtl'].lg\:rtl\:-m-136 {
    margin: -13.6rem
  }

  [dir='rtl'] .lg\:rtl\:-m-160,[dir='rtl'].lg\:rtl\:-m-160 {
    margin: -16rem
  }

  [dir='rtl'] .lg\:rtl\:-m-192,[dir='rtl'].lg\:rtl\:-m-192 {
    margin: -19.2rem
  }

  [dir='rtl'] .lg\:rtl\:-m-200,[dir='rtl'].lg\:rtl\:-m-200 {
    margin: -20rem
  }

  [dir='rtl'] .lg\:rtl\:-m-208,[dir='rtl'].lg\:rtl\:-m-208 {
    margin: -20.8rem
  }

  [dir='rtl'] .lg\:rtl\:-m-216,[dir='rtl'].lg\:rtl\:-m-216 {
    margin: -21.6rem
  }

  [dir='rtl'] .lg\:rtl\:-m-224,[dir='rtl'].lg\:rtl\:-m-224 {
    margin: -22.4rem
  }

  [dir='rtl'] .lg\:rtl\:-m-256,[dir='rtl'].lg\:rtl\:-m-256 {
    margin: -25.6rem
  }

  [dir='rtl'] .lg\:rtl\:-m-288,[dir='rtl'].lg\:rtl\:-m-288 {
    margin: -28.8rem
  }

  [dir='rtl'] .lg\:rtl\:-m-320,[dir='rtl'].lg\:rtl\:-m-320 {
    margin: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-m-360,[dir='rtl'].lg\:rtl\:-m-360 {
    margin: -36rem
  }

  [dir='rtl'] .lg\:rtl\:-m-384,[dir='rtl'].lg\:rtl\:-m-384 {
    margin: -38.4rem
  }

  [dir='rtl'] .lg\:rtl\:-m-400,[dir='rtl'].lg\:rtl\:-m-400 {
    margin: -40rem
  }

  [dir='rtl'] .lg\:rtl\:-m-512,[dir='rtl'].lg\:rtl\:-m-512 {
    margin: -51.2rem
  }

  [dir='rtl'] .lg\:rtl\:-m-640,[dir='rtl'].lg\:rtl\:-m-640 {
    margin: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-m-xs,[dir='rtl'].lg\:rtl\:-m-xs {
    margin: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-m-sm,[dir='rtl'].lg\:rtl\:-m-sm {
    margin: -48rem
  }

  [dir='rtl'] .lg\:rtl\:-m-md,[dir='rtl'].lg\:rtl\:-m-md {
    margin: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-m-lg,[dir='rtl'].lg\:rtl\:-m-lg {
    margin: -80rem
  }

  [dir='rtl'] .lg\:rtl\:-m-xl,[dir='rtl'].lg\:rtl\:-m-xl {
    margin: -96rem
  }

  [dir='rtl'] .lg\:rtl\:-m-2xl,[dir='rtl'].lg\:rtl\:-m-2xl {
    margin: -112rem
  }

  [dir='rtl'] .lg\:rtl\:-m-3xl,[dir='rtl'].lg\:rtl\:-m-3xl {
    margin: -128rem
  }

  [dir='rtl'] .lg\:rtl\:-m-4xl,[dir='rtl'].lg\:rtl\:-m-4xl {
    margin: -144rem
  }

  [dir='rtl'] .lg\:rtl\:-m-5xl,[dir='rtl'].lg\:rtl\:-m-5xl {
    margin: -160rem
  }

  [dir='rtl'] .lg\:rtl\:-m-px,[dir='rtl'].lg\:rtl\:-m-px {
    margin: -1px
  }

  [dir='rtl'] .lg\:rtl\:-m-0\.5,[dir='rtl'].lg\:rtl\:-m-0\.5 {
    margin: -0.05rem
  }

  [dir='rtl'] .lg\:rtl\:-m-1\.5,[dir='rtl'].lg\:rtl\:-m-1\.5 {
    margin: -0.15rem
  }

  [dir='rtl'] .lg\:rtl\:-m-2\.5,[dir='rtl'].lg\:rtl\:-m-2\.5 {
    margin: -0.25rem
  }

  [dir='rtl'] .lg\:rtl\:-m-3\.5,[dir='rtl'].lg\:rtl\:-m-3\.5 {
    margin: -0.35rem
  }

  [dir='rtl'] .lg\:rtl\:my-0,[dir='rtl'].lg\:rtl\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  [dir='rtl'] .lg\:rtl\:mx-0,[dir='rtl'].lg\:rtl\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  [dir='rtl'] .lg\:rtl\:my-1,[dir='rtl'].lg\:rtl\:my-1 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:mx-1,[dir='rtl'].lg\:rtl\:mx-1 {
    margin-left: 0.1rem;
    margin-right: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:my-2,[dir='rtl'].lg\:rtl\:my-2 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:mx-2,[dir='rtl'].lg\:rtl\:mx-2 {
    margin-left: 0.2rem;
    margin-right: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:my-3,[dir='rtl'].lg\:rtl\:my-3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:mx-3,[dir='rtl'].lg\:rtl\:mx-3 {
    margin-left: 0.3rem;
    margin-right: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:my-4,[dir='rtl'].lg\:rtl\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:mx-4,[dir='rtl'].lg\:rtl\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:my-5,[dir='rtl'].lg\:rtl\:my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:mx-5,[dir='rtl'].lg\:rtl\:mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:my-6,[dir='rtl'].lg\:rtl\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:mx-6,[dir='rtl'].lg\:rtl\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:my-7,[dir='rtl'].lg\:rtl\:my-7 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:mx-7,[dir='rtl'].lg\:rtl\:mx-7 {
    margin-left: 0.7rem;
    margin-right: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:my-8,[dir='rtl'].lg\:rtl\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:mx-8,[dir='rtl'].lg\:rtl\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:my-9,[dir='rtl'].lg\:rtl\:my-9 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:mx-9,[dir='rtl'].lg\:rtl\:mx-9 {
    margin-left: 0.9rem;
    margin-right: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:my-10,[dir='rtl'].lg\:rtl\:my-10 {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:mx-10,[dir='rtl'].lg\:rtl\:mx-10 {
    margin-left: 1.0rem;
    margin-right: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:my-12,[dir='rtl'].lg\:rtl\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:mx-12,[dir='rtl'].lg\:rtl\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:my-14,[dir='rtl'].lg\:rtl\:my-14 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:mx-14,[dir='rtl'].lg\:rtl\:mx-14 {
    margin-left: 1.4rem;
    margin-right: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:my-16,[dir='rtl'].lg\:rtl\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:mx-16,[dir='rtl'].lg\:rtl\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:my-20,[dir='rtl'].lg\:rtl\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  [dir='rtl'] .lg\:rtl\:mx-20,[dir='rtl'].lg\:rtl\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  [dir='rtl'] .lg\:rtl\:my-24,[dir='rtl'].lg\:rtl\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:mx-24,[dir='rtl'].lg\:rtl\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:my-28,[dir='rtl'].lg\:rtl\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:mx-28,[dir='rtl'].lg\:rtl\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:my-32,[dir='rtl'].lg\:rtl\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:mx-32,[dir='rtl'].lg\:rtl\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:my-36,[dir='rtl'].lg\:rtl\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:mx-36,[dir='rtl'].lg\:rtl\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:my-40,[dir='rtl'].lg\:rtl\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  [dir='rtl'] .lg\:rtl\:mx-40,[dir='rtl'].lg\:rtl\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  [dir='rtl'] .lg\:rtl\:my-44,[dir='rtl'].lg\:rtl\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:mx-44,[dir='rtl'].lg\:rtl\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:my-48,[dir='rtl'].lg\:rtl\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:mx-48,[dir='rtl'].lg\:rtl\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:my-52,[dir='rtl'].lg\:rtl\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:mx-52,[dir='rtl'].lg\:rtl\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:my-56,[dir='rtl'].lg\:rtl\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:mx-56,[dir='rtl'].lg\:rtl\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:my-60,[dir='rtl'].lg\:rtl\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  [dir='rtl'] .lg\:rtl\:mx-60,[dir='rtl'].lg\:rtl\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  [dir='rtl'] .lg\:rtl\:my-64,[dir='rtl'].lg\:rtl\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:mx-64,[dir='rtl'].lg\:rtl\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:my-68,[dir='rtl'].lg\:rtl\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:mx-68,[dir='rtl'].lg\:rtl\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:my-72,[dir='rtl'].lg\:rtl\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:mx-72,[dir='rtl'].lg\:rtl\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:my-76,[dir='rtl'].lg\:rtl\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:mx-76,[dir='rtl'].lg\:rtl\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:my-80,[dir='rtl'].lg\:rtl\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  [dir='rtl'] .lg\:rtl\:mx-80,[dir='rtl'].lg\:rtl\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  [dir='rtl'] .lg\:rtl\:my-84,[dir='rtl'].lg\:rtl\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:mx-84,[dir='rtl'].lg\:rtl\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:my-88,[dir='rtl'].lg\:rtl\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:mx-88,[dir='rtl'].lg\:rtl\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:my-92,[dir='rtl'].lg\:rtl\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:mx-92,[dir='rtl'].lg\:rtl\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:my-96,[dir='rtl'].lg\:rtl\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:mx-96,[dir='rtl'].lg\:rtl\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:my-128,[dir='rtl'].lg\:rtl\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:mx-128,[dir='rtl'].lg\:rtl\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:my-136,[dir='rtl'].lg\:rtl\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:mx-136,[dir='rtl'].lg\:rtl\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:my-160,[dir='rtl'].lg\:rtl\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  [dir='rtl'] .lg\:rtl\:mx-160,[dir='rtl'].lg\:rtl\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  [dir='rtl'] .lg\:rtl\:my-192,[dir='rtl'].lg\:rtl\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:mx-192,[dir='rtl'].lg\:rtl\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:my-200,[dir='rtl'].lg\:rtl\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  [dir='rtl'] .lg\:rtl\:mx-200,[dir='rtl'].lg\:rtl\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  [dir='rtl'] .lg\:rtl\:my-208,[dir='rtl'].lg\:rtl\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:mx-208,[dir='rtl'].lg\:rtl\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:my-216,[dir='rtl'].lg\:rtl\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:mx-216,[dir='rtl'].lg\:rtl\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:my-224,[dir='rtl'].lg\:rtl\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:mx-224,[dir='rtl'].lg\:rtl\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:my-256,[dir='rtl'].lg\:rtl\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:mx-256,[dir='rtl'].lg\:rtl\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:my-288,[dir='rtl'].lg\:rtl\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:mx-288,[dir='rtl'].lg\:rtl\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:my-320,[dir='rtl'].lg\:rtl\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='rtl'] .lg\:rtl\:mx-320,[dir='rtl'].lg\:rtl\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='rtl'] .lg\:rtl\:my-360,[dir='rtl'].lg\:rtl\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  [dir='rtl'] .lg\:rtl\:mx-360,[dir='rtl'].lg\:rtl\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  [dir='rtl'] .lg\:rtl\:my-384,[dir='rtl'].lg\:rtl\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:mx-384,[dir='rtl'].lg\:rtl\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:my-400,[dir='rtl'].lg\:rtl\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  [dir='rtl'] .lg\:rtl\:mx-400,[dir='rtl'].lg\:rtl\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  [dir='rtl'] .lg\:rtl\:my-512,[dir='rtl'].lg\:rtl\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:mx-512,[dir='rtl'].lg\:rtl\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:my-640,[dir='rtl'].lg\:rtl\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='rtl'] .lg\:rtl\:mx-640,[dir='rtl'].lg\:rtl\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='rtl'] .lg\:rtl\:my-auto,[dir='rtl'].lg\:rtl\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  [dir='rtl'] .lg\:rtl\:mx-auto,[dir='rtl'].lg\:rtl\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  [dir='rtl'] .lg\:rtl\:my-xs,[dir='rtl'].lg\:rtl\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='rtl'] .lg\:rtl\:mx-xs,[dir='rtl'].lg\:rtl\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='rtl'] .lg\:rtl\:my-sm,[dir='rtl'].lg\:rtl\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  [dir='rtl'] .lg\:rtl\:mx-sm,[dir='rtl'].lg\:rtl\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  [dir='rtl'] .lg\:rtl\:my-md,[dir='rtl'].lg\:rtl\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='rtl'] .lg\:rtl\:mx-md,[dir='rtl'].lg\:rtl\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='rtl'] .lg\:rtl\:my-lg,[dir='rtl'].lg\:rtl\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  [dir='rtl'] .lg\:rtl\:mx-lg,[dir='rtl'].lg\:rtl\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  [dir='rtl'] .lg\:rtl\:my-xl,[dir='rtl'].lg\:rtl\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  [dir='rtl'] .lg\:rtl\:mx-xl,[dir='rtl'].lg\:rtl\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  [dir='rtl'] .lg\:rtl\:my-2xl,[dir='rtl'].lg\:rtl\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  [dir='rtl'] .lg\:rtl\:mx-2xl,[dir='rtl'].lg\:rtl\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  [dir='rtl'] .lg\:rtl\:my-3xl,[dir='rtl'].lg\:rtl\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  [dir='rtl'] .lg\:rtl\:mx-3xl,[dir='rtl'].lg\:rtl\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  [dir='rtl'] .lg\:rtl\:my-4xl,[dir='rtl'].lg\:rtl\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  [dir='rtl'] .lg\:rtl\:mx-4xl,[dir='rtl'].lg\:rtl\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  [dir='rtl'] .lg\:rtl\:my-5xl,[dir='rtl'].lg\:rtl\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  [dir='rtl'] .lg\:rtl\:mx-5xl,[dir='rtl'].lg\:rtl\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  [dir='rtl'] .lg\:rtl\:my-px,[dir='rtl'].lg\:rtl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  [dir='rtl'] .lg\:rtl\:mx-px,[dir='rtl'].lg\:rtl\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  [dir='rtl'] .lg\:rtl\:my-0\.5,[dir='rtl'].lg\:rtl\:my-0\.5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:mx-0\.5,[dir='rtl'].lg\:rtl\:mx-0\.5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:my-1\.5,[dir='rtl'].lg\:rtl\:my-1\.5 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:mx-1\.5,[dir='rtl'].lg\:rtl\:mx-1\.5 {
    margin-left: 0.15rem;
    margin-right: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:my-2\.5,[dir='rtl'].lg\:rtl\:my-2\.5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:mx-2\.5,[dir='rtl'].lg\:rtl\:mx-2\.5 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:my-3\.5,[dir='rtl'].lg\:rtl\:my-3\.5 {
    margin-top: 0.35rem;
    margin-bottom: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:mx-3\.5,[dir='rtl'].lg\:rtl\:mx-3\.5 {
    margin-left: 0.35rem;
    margin-right: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:-my-1,[dir='rtl'].lg\:rtl\:-my-1 {
    margin-top: -0.1rem;
    margin-bottom: -0.1rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-1,[dir='rtl'].lg\:rtl\:-mx-1 {
    margin-left: -0.1rem;
    margin-right: -0.1rem
  }

  [dir='rtl'] .lg\:rtl\:-my-2,[dir='rtl'].lg\:rtl\:-my-2 {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-2,[dir='rtl'].lg\:rtl\:-mx-2 {
    margin-left: -0.2rem;
    margin-right: -0.2rem
  }

  [dir='rtl'] .lg\:rtl\:-my-3,[dir='rtl'].lg\:rtl\:-my-3 {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-3,[dir='rtl'].lg\:rtl\:-mx-3 {
    margin-left: -0.3rem;
    margin-right: -0.3rem
  }

  [dir='rtl'] .lg\:rtl\:-my-4,[dir='rtl'].lg\:rtl\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-4,[dir='rtl'].lg\:rtl\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  [dir='rtl'] .lg\:rtl\:-my-5,[dir='rtl'].lg\:rtl\:-my-5 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-5,[dir='rtl'].lg\:rtl\:-mx-5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  [dir='rtl'] .lg\:rtl\:-my-6,[dir='rtl'].lg\:rtl\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-6,[dir='rtl'].lg\:rtl\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  [dir='rtl'] .lg\:rtl\:-my-7,[dir='rtl'].lg\:rtl\:-my-7 {
    margin-top: -0.7rem;
    margin-bottom: -0.7rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-7,[dir='rtl'].lg\:rtl\:-mx-7 {
    margin-left: -0.7rem;
    margin-right: -0.7rem
  }

  [dir='rtl'] .lg\:rtl\:-my-8,[dir='rtl'].lg\:rtl\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-8,[dir='rtl'].lg\:rtl\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  [dir='rtl'] .lg\:rtl\:-my-9,[dir='rtl'].lg\:rtl\:-my-9 {
    margin-top: -0.9rem;
    margin-bottom: -0.9rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-9,[dir='rtl'].lg\:rtl\:-mx-9 {
    margin-left: -0.9rem;
    margin-right: -0.9rem
  }

  [dir='rtl'] .lg\:rtl\:-my-10,[dir='rtl'].lg\:rtl\:-my-10 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-10,[dir='rtl'].lg\:rtl\:-mx-10 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  [dir='rtl'] .lg\:rtl\:-my-12,[dir='rtl'].lg\:rtl\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-12,[dir='rtl'].lg\:rtl\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  [dir='rtl'] .lg\:rtl\:-my-14,[dir='rtl'].lg\:rtl\:-my-14 {
    margin-top: -1.4rem;
    margin-bottom: -1.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-14,[dir='rtl'].lg\:rtl\:-mx-14 {
    margin-left: -1.4rem;
    margin-right: -1.4rem
  }

  [dir='rtl'] .lg\:rtl\:-my-16,[dir='rtl'].lg\:rtl\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-16,[dir='rtl'].lg\:rtl\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  [dir='rtl'] .lg\:rtl\:-my-20,[dir='rtl'].lg\:rtl\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-20,[dir='rtl'].lg\:rtl\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  [dir='rtl'] .lg\:rtl\:-my-24,[dir='rtl'].lg\:rtl\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-24,[dir='rtl'].lg\:rtl\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  [dir='rtl'] .lg\:rtl\:-my-28,[dir='rtl'].lg\:rtl\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-28,[dir='rtl'].lg\:rtl\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  [dir='rtl'] .lg\:rtl\:-my-32,[dir='rtl'].lg\:rtl\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-32,[dir='rtl'].lg\:rtl\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  [dir='rtl'] .lg\:rtl\:-my-36,[dir='rtl'].lg\:rtl\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-36,[dir='rtl'].lg\:rtl\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  [dir='rtl'] .lg\:rtl\:-my-40,[dir='rtl'].lg\:rtl\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-40,[dir='rtl'].lg\:rtl\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  [dir='rtl'] .lg\:rtl\:-my-44,[dir='rtl'].lg\:rtl\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-44,[dir='rtl'].lg\:rtl\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  [dir='rtl'] .lg\:rtl\:-my-48,[dir='rtl'].lg\:rtl\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-48,[dir='rtl'].lg\:rtl\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  [dir='rtl'] .lg\:rtl\:-my-52,[dir='rtl'].lg\:rtl\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-52,[dir='rtl'].lg\:rtl\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  [dir='rtl'] .lg\:rtl\:-my-56,[dir='rtl'].lg\:rtl\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-56,[dir='rtl'].lg\:rtl\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  [dir='rtl'] .lg\:rtl\:-my-60,[dir='rtl'].lg\:rtl\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-60,[dir='rtl'].lg\:rtl\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  [dir='rtl'] .lg\:rtl\:-my-64,[dir='rtl'].lg\:rtl\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-64,[dir='rtl'].lg\:rtl\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  [dir='rtl'] .lg\:rtl\:-my-68,[dir='rtl'].lg\:rtl\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-68,[dir='rtl'].lg\:rtl\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  [dir='rtl'] .lg\:rtl\:-my-72,[dir='rtl'].lg\:rtl\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-72,[dir='rtl'].lg\:rtl\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  [dir='rtl'] .lg\:rtl\:-my-76,[dir='rtl'].lg\:rtl\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-76,[dir='rtl'].lg\:rtl\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  [dir='rtl'] .lg\:rtl\:-my-80,[dir='rtl'].lg\:rtl\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-80,[dir='rtl'].lg\:rtl\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  [dir='rtl'] .lg\:rtl\:-my-84,[dir='rtl'].lg\:rtl\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-84,[dir='rtl'].lg\:rtl\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  [dir='rtl'] .lg\:rtl\:-my-88,[dir='rtl'].lg\:rtl\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-88,[dir='rtl'].lg\:rtl\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  [dir='rtl'] .lg\:rtl\:-my-92,[dir='rtl'].lg\:rtl\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-92,[dir='rtl'].lg\:rtl\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  [dir='rtl'] .lg\:rtl\:-my-96,[dir='rtl'].lg\:rtl\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-96,[dir='rtl'].lg\:rtl\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  [dir='rtl'] .lg\:rtl\:-my-128,[dir='rtl'].lg\:rtl\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-128,[dir='rtl'].lg\:rtl\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  [dir='rtl'] .lg\:rtl\:-my-136,[dir='rtl'].lg\:rtl\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-136,[dir='rtl'].lg\:rtl\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  [dir='rtl'] .lg\:rtl\:-my-160,[dir='rtl'].lg\:rtl\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-160,[dir='rtl'].lg\:rtl\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  [dir='rtl'] .lg\:rtl\:-my-192,[dir='rtl'].lg\:rtl\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-192,[dir='rtl'].lg\:rtl\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  [dir='rtl'] .lg\:rtl\:-my-200,[dir='rtl'].lg\:rtl\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-200,[dir='rtl'].lg\:rtl\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  [dir='rtl'] .lg\:rtl\:-my-208,[dir='rtl'].lg\:rtl\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-208,[dir='rtl'].lg\:rtl\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  [dir='rtl'] .lg\:rtl\:-my-216,[dir='rtl'].lg\:rtl\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-216,[dir='rtl'].lg\:rtl\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  [dir='rtl'] .lg\:rtl\:-my-224,[dir='rtl'].lg\:rtl\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-224,[dir='rtl'].lg\:rtl\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  [dir='rtl'] .lg\:rtl\:-my-256,[dir='rtl'].lg\:rtl\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-256,[dir='rtl'].lg\:rtl\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  [dir='rtl'] .lg\:rtl\:-my-288,[dir='rtl'].lg\:rtl\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-288,[dir='rtl'].lg\:rtl\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  [dir='rtl'] .lg\:rtl\:-my-320,[dir='rtl'].lg\:rtl\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-320,[dir='rtl'].lg\:rtl\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-my-360,[dir='rtl'].lg\:rtl\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-360,[dir='rtl'].lg\:rtl\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  [dir='rtl'] .lg\:rtl\:-my-384,[dir='rtl'].lg\:rtl\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-384,[dir='rtl'].lg\:rtl\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  [dir='rtl'] .lg\:rtl\:-my-400,[dir='rtl'].lg\:rtl\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-400,[dir='rtl'].lg\:rtl\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  [dir='rtl'] .lg\:rtl\:-my-512,[dir='rtl'].lg\:rtl\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-512,[dir='rtl'].lg\:rtl\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  [dir='rtl'] .lg\:rtl\:-my-640,[dir='rtl'].lg\:rtl\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-640,[dir='rtl'].lg\:rtl\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-my-xs,[dir='rtl'].lg\:rtl\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-xs,[dir='rtl'].lg\:rtl\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-my-sm,[dir='rtl'].lg\:rtl\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-sm,[dir='rtl'].lg\:rtl\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  [dir='rtl'] .lg\:rtl\:-my-md,[dir='rtl'].lg\:rtl\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-md,[dir='rtl'].lg\:rtl\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-my-lg,[dir='rtl'].lg\:rtl\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-lg,[dir='rtl'].lg\:rtl\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  [dir='rtl'] .lg\:rtl\:-my-xl,[dir='rtl'].lg\:rtl\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-xl,[dir='rtl'].lg\:rtl\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  [dir='rtl'] .lg\:rtl\:-my-2xl,[dir='rtl'].lg\:rtl\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-2xl,[dir='rtl'].lg\:rtl\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  [dir='rtl'] .lg\:rtl\:-my-3xl,[dir='rtl'].lg\:rtl\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-3xl,[dir='rtl'].lg\:rtl\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  [dir='rtl'] .lg\:rtl\:-my-4xl,[dir='rtl'].lg\:rtl\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-4xl,[dir='rtl'].lg\:rtl\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  [dir='rtl'] .lg\:rtl\:-my-5xl,[dir='rtl'].lg\:rtl\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-5xl,[dir='rtl'].lg\:rtl\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  [dir='rtl'] .lg\:rtl\:-my-px,[dir='rtl'].lg\:rtl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  [dir='rtl'] .lg\:rtl\:-mx-px,[dir='rtl'].lg\:rtl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  [dir='rtl'] .lg\:rtl\:-my-0\.5,[dir='rtl'].lg\:rtl\:-my-0\.5 {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-0\.5,[dir='rtl'].lg\:rtl\:-mx-0\.5 {
    margin-left: -0.05rem;
    margin-right: -0.05rem
  }

  [dir='rtl'] .lg\:rtl\:-my-1\.5,[dir='rtl'].lg\:rtl\:-my-1\.5 {
    margin-top: -0.15rem;
    margin-bottom: -0.15rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-1\.5,[dir='rtl'].lg\:rtl\:-mx-1\.5 {
    margin-left: -0.15rem;
    margin-right: -0.15rem
  }

  [dir='rtl'] .lg\:rtl\:-my-2\.5,[dir='rtl'].lg\:rtl\:-my-2\.5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-2\.5,[dir='rtl'].lg\:rtl\:-mx-2\.5 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  [dir='rtl'] .lg\:rtl\:-my-3\.5,[dir='rtl'].lg\:rtl\:-my-3\.5 {
    margin-top: -0.35rem;
    margin-bottom: -0.35rem
  }

  [dir='rtl'] .lg\:rtl\:-mx-3\.5,[dir='rtl'].lg\:rtl\:-mx-3\.5 {
    margin-left: -0.35rem;
    margin-right: -0.35rem
  }

  [dir='rtl'] .lg\:rtl\:mt-0,[dir='rtl'].lg\:rtl\:mt-0 {
    margin-top: 0
  }

  [dir='rtl'] .lg\:rtl\:mr-0,[dir='rtl'].lg\:rtl\:mr-0 {
    margin-right: 0
  }

  [dir='rtl'] .lg\:rtl\:mb-0,[dir='rtl'].lg\:rtl\:mb-0 {
    margin-bottom: 0
  }

  [dir='rtl'] .lg\:rtl\:ml-0,[dir='rtl'].lg\:rtl\:ml-0 {
    margin-left: 0
  }

  [dir='rtl'] .lg\:rtl\:mt-1,[dir='rtl'].lg\:rtl\:mt-1 {
    margin-top: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:mr-1,[dir='rtl'].lg\:rtl\:mr-1 {
    margin-right: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:mb-1,[dir='rtl'].lg\:rtl\:mb-1 {
    margin-bottom: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:ml-1,[dir='rtl'].lg\:rtl\:ml-1 {
    margin-left: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:mt-2,[dir='rtl'].lg\:rtl\:mt-2 {
    margin-top: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:mr-2,[dir='rtl'].lg\:rtl\:mr-2 {
    margin-right: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:mb-2,[dir='rtl'].lg\:rtl\:mb-2 {
    margin-bottom: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:ml-2,[dir='rtl'].lg\:rtl\:ml-2 {
    margin-left: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:mt-3,[dir='rtl'].lg\:rtl\:mt-3 {
    margin-top: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:mr-3,[dir='rtl'].lg\:rtl\:mr-3 {
    margin-right: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:mb-3,[dir='rtl'].lg\:rtl\:mb-3 {
    margin-bottom: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:ml-3,[dir='rtl'].lg\:rtl\:ml-3 {
    margin-left: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:mt-4,[dir='rtl'].lg\:rtl\:mt-4 {
    margin-top: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:mr-4,[dir='rtl'].lg\:rtl\:mr-4 {
    margin-right: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:mb-4,[dir='rtl'].lg\:rtl\:mb-4 {
    margin-bottom: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:ml-4,[dir='rtl'].lg\:rtl\:ml-4 {
    margin-left: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:mt-5,[dir='rtl'].lg\:rtl\:mt-5 {
    margin-top: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:mr-5,[dir='rtl'].lg\:rtl\:mr-5 {
    margin-right: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:mb-5,[dir='rtl'].lg\:rtl\:mb-5 {
    margin-bottom: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:ml-5,[dir='rtl'].lg\:rtl\:ml-5 {
    margin-left: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:mt-6,[dir='rtl'].lg\:rtl\:mt-6 {
    margin-top: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:mr-6,[dir='rtl'].lg\:rtl\:mr-6 {
    margin-right: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:mb-6,[dir='rtl'].lg\:rtl\:mb-6 {
    margin-bottom: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:ml-6,[dir='rtl'].lg\:rtl\:ml-6 {
    margin-left: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:mt-7,[dir='rtl'].lg\:rtl\:mt-7 {
    margin-top: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:mr-7,[dir='rtl'].lg\:rtl\:mr-7 {
    margin-right: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:mb-7,[dir='rtl'].lg\:rtl\:mb-7 {
    margin-bottom: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:ml-7,[dir='rtl'].lg\:rtl\:ml-7 {
    margin-left: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:mt-8,[dir='rtl'].lg\:rtl\:mt-8 {
    margin-top: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:mr-8,[dir='rtl'].lg\:rtl\:mr-8 {
    margin-right: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:mb-8,[dir='rtl'].lg\:rtl\:mb-8 {
    margin-bottom: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:ml-8,[dir='rtl'].lg\:rtl\:ml-8 {
    margin-left: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:mt-9,[dir='rtl'].lg\:rtl\:mt-9 {
    margin-top: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:mr-9,[dir='rtl'].lg\:rtl\:mr-9 {
    margin-right: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:mb-9,[dir='rtl'].lg\:rtl\:mb-9 {
    margin-bottom: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:ml-9,[dir='rtl'].lg\:rtl\:ml-9 {
    margin-left: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:mt-10,[dir='rtl'].lg\:rtl\:mt-10 {
    margin-top: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:mr-10,[dir='rtl'].lg\:rtl\:mr-10 {
    margin-right: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:mb-10,[dir='rtl'].lg\:rtl\:mb-10 {
    margin-bottom: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:ml-10,[dir='rtl'].lg\:rtl\:ml-10 {
    margin-left: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:mt-12,[dir='rtl'].lg\:rtl\:mt-12 {
    margin-top: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:mr-12,[dir='rtl'].lg\:rtl\:mr-12 {
    margin-right: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:mb-12,[dir='rtl'].lg\:rtl\:mb-12 {
    margin-bottom: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:ml-12,[dir='rtl'].lg\:rtl\:ml-12 {
    margin-left: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:mt-14,[dir='rtl'].lg\:rtl\:mt-14 {
    margin-top: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:mr-14,[dir='rtl'].lg\:rtl\:mr-14 {
    margin-right: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:mb-14,[dir='rtl'].lg\:rtl\:mb-14 {
    margin-bottom: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:ml-14,[dir='rtl'].lg\:rtl\:ml-14 {
    margin-left: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:mt-16,[dir='rtl'].lg\:rtl\:mt-16 {
    margin-top: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:mr-16,[dir='rtl'].lg\:rtl\:mr-16 {
    margin-right: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:mb-16,[dir='rtl'].lg\:rtl\:mb-16 {
    margin-bottom: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:ml-16,[dir='rtl'].lg\:rtl\:ml-16 {
    margin-left: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:mt-20,[dir='rtl'].lg\:rtl\:mt-20 {
    margin-top: 2rem
  }

  [dir='rtl'] .lg\:rtl\:mr-20,[dir='rtl'].lg\:rtl\:mr-20 {
    margin-right: 2rem
  }

  [dir='rtl'] .lg\:rtl\:mb-20,[dir='rtl'].lg\:rtl\:mb-20 {
    margin-bottom: 2rem
  }

  [dir='rtl'] .lg\:rtl\:ml-20,[dir='rtl'].lg\:rtl\:ml-20 {
    margin-left: 2rem
  }

  [dir='rtl'] .lg\:rtl\:mt-24,[dir='rtl'].lg\:rtl\:mt-24 {
    margin-top: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:mr-24,[dir='rtl'].lg\:rtl\:mr-24 {
    margin-right: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:mb-24,[dir='rtl'].lg\:rtl\:mb-24 {
    margin-bottom: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:ml-24,[dir='rtl'].lg\:rtl\:ml-24 {
    margin-left: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:mt-28,[dir='rtl'].lg\:rtl\:mt-28 {
    margin-top: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:mr-28,[dir='rtl'].lg\:rtl\:mr-28 {
    margin-right: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:mb-28,[dir='rtl'].lg\:rtl\:mb-28 {
    margin-bottom: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:ml-28,[dir='rtl'].lg\:rtl\:ml-28 {
    margin-left: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:mt-32,[dir='rtl'].lg\:rtl\:mt-32 {
    margin-top: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:mr-32,[dir='rtl'].lg\:rtl\:mr-32 {
    margin-right: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:mb-32,[dir='rtl'].lg\:rtl\:mb-32 {
    margin-bottom: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:ml-32,[dir='rtl'].lg\:rtl\:ml-32 {
    margin-left: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:mt-36,[dir='rtl'].lg\:rtl\:mt-36 {
    margin-top: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:mr-36,[dir='rtl'].lg\:rtl\:mr-36 {
    margin-right: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:mb-36,[dir='rtl'].lg\:rtl\:mb-36 {
    margin-bottom: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:ml-36,[dir='rtl'].lg\:rtl\:ml-36 {
    margin-left: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:mt-40,[dir='rtl'].lg\:rtl\:mt-40 {
    margin-top: 4rem
  }

  [dir='rtl'] .lg\:rtl\:mr-40,[dir='rtl'].lg\:rtl\:mr-40 {
    margin-right: 4rem
  }

  [dir='rtl'] .lg\:rtl\:mb-40,[dir='rtl'].lg\:rtl\:mb-40 {
    margin-bottom: 4rem
  }

  [dir='rtl'] .lg\:rtl\:ml-40,[dir='rtl'].lg\:rtl\:ml-40 {
    margin-left: 4rem
  }

  [dir='rtl'] .lg\:rtl\:mt-44,[dir='rtl'].lg\:rtl\:mt-44 {
    margin-top: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:mr-44,[dir='rtl'].lg\:rtl\:mr-44 {
    margin-right: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:mb-44,[dir='rtl'].lg\:rtl\:mb-44 {
    margin-bottom: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:ml-44,[dir='rtl'].lg\:rtl\:ml-44 {
    margin-left: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:mt-48,[dir='rtl'].lg\:rtl\:mt-48 {
    margin-top: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:mr-48,[dir='rtl'].lg\:rtl\:mr-48 {
    margin-right: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:mb-48,[dir='rtl'].lg\:rtl\:mb-48 {
    margin-bottom: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:ml-48,[dir='rtl'].lg\:rtl\:ml-48 {
    margin-left: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:mt-52,[dir='rtl'].lg\:rtl\:mt-52 {
    margin-top: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:mr-52,[dir='rtl'].lg\:rtl\:mr-52 {
    margin-right: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:mb-52,[dir='rtl'].lg\:rtl\:mb-52 {
    margin-bottom: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:ml-52,[dir='rtl'].lg\:rtl\:ml-52 {
    margin-left: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:mt-56,[dir='rtl'].lg\:rtl\:mt-56 {
    margin-top: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:mr-56,[dir='rtl'].lg\:rtl\:mr-56 {
    margin-right: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:mb-56,[dir='rtl'].lg\:rtl\:mb-56 {
    margin-bottom: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:ml-56,[dir='rtl'].lg\:rtl\:ml-56 {
    margin-left: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:mt-60,[dir='rtl'].lg\:rtl\:mt-60 {
    margin-top: 6rem
  }

  [dir='rtl'] .lg\:rtl\:mr-60,[dir='rtl'].lg\:rtl\:mr-60 {
    margin-right: 6rem
  }

  [dir='rtl'] .lg\:rtl\:mb-60,[dir='rtl'].lg\:rtl\:mb-60 {
    margin-bottom: 6rem
  }

  [dir='rtl'] .lg\:rtl\:ml-60,[dir='rtl'].lg\:rtl\:ml-60 {
    margin-left: 6rem
  }

  [dir='rtl'] .lg\:rtl\:mt-64,[dir='rtl'].lg\:rtl\:mt-64 {
    margin-top: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:mr-64,[dir='rtl'].lg\:rtl\:mr-64 {
    margin-right: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:mb-64,[dir='rtl'].lg\:rtl\:mb-64 {
    margin-bottom: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:ml-64,[dir='rtl'].lg\:rtl\:ml-64 {
    margin-left: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:mt-68,[dir='rtl'].lg\:rtl\:mt-68 {
    margin-top: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:mr-68,[dir='rtl'].lg\:rtl\:mr-68 {
    margin-right: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:mb-68,[dir='rtl'].lg\:rtl\:mb-68 {
    margin-bottom: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:ml-68,[dir='rtl'].lg\:rtl\:ml-68 {
    margin-left: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:mt-72,[dir='rtl'].lg\:rtl\:mt-72 {
    margin-top: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:mr-72,[dir='rtl'].lg\:rtl\:mr-72 {
    margin-right: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:mb-72,[dir='rtl'].lg\:rtl\:mb-72 {
    margin-bottom: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:ml-72,[dir='rtl'].lg\:rtl\:ml-72 {
    margin-left: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:mt-76,[dir='rtl'].lg\:rtl\:mt-76 {
    margin-top: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:mr-76,[dir='rtl'].lg\:rtl\:mr-76 {
    margin-right: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:mb-76,[dir='rtl'].lg\:rtl\:mb-76 {
    margin-bottom: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:ml-76,[dir='rtl'].lg\:rtl\:ml-76 {
    margin-left: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:mt-80,[dir='rtl'].lg\:rtl\:mt-80 {
    margin-top: 8rem
  }

  [dir='rtl'] .lg\:rtl\:mr-80,[dir='rtl'].lg\:rtl\:mr-80 {
    margin-right: 8rem
  }

  [dir='rtl'] .lg\:rtl\:mb-80,[dir='rtl'].lg\:rtl\:mb-80 {
    margin-bottom: 8rem
  }

  [dir='rtl'] .lg\:rtl\:ml-80,[dir='rtl'].lg\:rtl\:ml-80 {
    margin-left: 8rem
  }

  [dir='rtl'] .lg\:rtl\:mt-84,[dir='rtl'].lg\:rtl\:mt-84 {
    margin-top: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:mr-84,[dir='rtl'].lg\:rtl\:mr-84 {
    margin-right: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:mb-84,[dir='rtl'].lg\:rtl\:mb-84 {
    margin-bottom: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:ml-84,[dir='rtl'].lg\:rtl\:ml-84 {
    margin-left: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:mt-88,[dir='rtl'].lg\:rtl\:mt-88 {
    margin-top: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:mr-88,[dir='rtl'].lg\:rtl\:mr-88 {
    margin-right: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:mb-88,[dir='rtl'].lg\:rtl\:mb-88 {
    margin-bottom: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:ml-88,[dir='rtl'].lg\:rtl\:ml-88 {
    margin-left: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:mt-92,[dir='rtl'].lg\:rtl\:mt-92 {
    margin-top: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:mr-92,[dir='rtl'].lg\:rtl\:mr-92 {
    margin-right: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:mb-92,[dir='rtl'].lg\:rtl\:mb-92 {
    margin-bottom: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:ml-92,[dir='rtl'].lg\:rtl\:ml-92 {
    margin-left: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:mt-96,[dir='rtl'].lg\:rtl\:mt-96 {
    margin-top: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:mr-96,[dir='rtl'].lg\:rtl\:mr-96 {
    margin-right: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:mb-96,[dir='rtl'].lg\:rtl\:mb-96 {
    margin-bottom: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:ml-96,[dir='rtl'].lg\:rtl\:ml-96 {
    margin-left: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:mt-128,[dir='rtl'].lg\:rtl\:mt-128 {
    margin-top: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:mr-128,[dir='rtl'].lg\:rtl\:mr-128 {
    margin-right: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:mb-128,[dir='rtl'].lg\:rtl\:mb-128 {
    margin-bottom: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:ml-128,[dir='rtl'].lg\:rtl\:ml-128 {
    margin-left: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:mt-136,[dir='rtl'].lg\:rtl\:mt-136 {
    margin-top: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:mr-136,[dir='rtl'].lg\:rtl\:mr-136 {
    margin-right: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:mb-136,[dir='rtl'].lg\:rtl\:mb-136 {
    margin-bottom: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:ml-136,[dir='rtl'].lg\:rtl\:ml-136 {
    margin-left: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:mt-160,[dir='rtl'].lg\:rtl\:mt-160 {
    margin-top: 16rem
  }

  [dir='rtl'] .lg\:rtl\:mr-160,[dir='rtl'].lg\:rtl\:mr-160 {
    margin-right: 16rem
  }

  [dir='rtl'] .lg\:rtl\:mb-160,[dir='rtl'].lg\:rtl\:mb-160 {
    margin-bottom: 16rem
  }

  [dir='rtl'] .lg\:rtl\:ml-160,[dir='rtl'].lg\:rtl\:ml-160 {
    margin-left: 16rem
  }

  [dir='rtl'] .lg\:rtl\:mt-192,[dir='rtl'].lg\:rtl\:mt-192 {
    margin-top: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:mr-192,[dir='rtl'].lg\:rtl\:mr-192 {
    margin-right: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:mb-192,[dir='rtl'].lg\:rtl\:mb-192 {
    margin-bottom: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:ml-192,[dir='rtl'].lg\:rtl\:ml-192 {
    margin-left: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:mt-200,[dir='rtl'].lg\:rtl\:mt-200 {
    margin-top: 20rem
  }

  [dir='rtl'] .lg\:rtl\:mr-200,[dir='rtl'].lg\:rtl\:mr-200 {
    margin-right: 20rem
  }

  [dir='rtl'] .lg\:rtl\:mb-200,[dir='rtl'].lg\:rtl\:mb-200 {
    margin-bottom: 20rem
  }

  [dir='rtl'] .lg\:rtl\:ml-200,[dir='rtl'].lg\:rtl\:ml-200 {
    margin-left: 20rem
  }

  [dir='rtl'] .lg\:rtl\:mt-208,[dir='rtl'].lg\:rtl\:mt-208 {
    margin-top: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:mr-208,[dir='rtl'].lg\:rtl\:mr-208 {
    margin-right: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:mb-208,[dir='rtl'].lg\:rtl\:mb-208 {
    margin-bottom: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:ml-208,[dir='rtl'].lg\:rtl\:ml-208 {
    margin-left: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:mt-216,[dir='rtl'].lg\:rtl\:mt-216 {
    margin-top: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:mr-216,[dir='rtl'].lg\:rtl\:mr-216 {
    margin-right: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:mb-216,[dir='rtl'].lg\:rtl\:mb-216 {
    margin-bottom: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:ml-216,[dir='rtl'].lg\:rtl\:ml-216 {
    margin-left: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:mt-224,[dir='rtl'].lg\:rtl\:mt-224 {
    margin-top: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:mr-224,[dir='rtl'].lg\:rtl\:mr-224 {
    margin-right: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:mb-224,[dir='rtl'].lg\:rtl\:mb-224 {
    margin-bottom: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:ml-224,[dir='rtl'].lg\:rtl\:ml-224 {
    margin-left: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:mt-256,[dir='rtl'].lg\:rtl\:mt-256 {
    margin-top: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:mr-256,[dir='rtl'].lg\:rtl\:mr-256 {
    margin-right: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:mb-256,[dir='rtl'].lg\:rtl\:mb-256 {
    margin-bottom: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:ml-256,[dir='rtl'].lg\:rtl\:ml-256 {
    margin-left: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:mt-288,[dir='rtl'].lg\:rtl\:mt-288 {
    margin-top: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:mr-288,[dir='rtl'].lg\:rtl\:mr-288 {
    margin-right: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:mb-288,[dir='rtl'].lg\:rtl\:mb-288 {
    margin-bottom: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:ml-288,[dir='rtl'].lg\:rtl\:ml-288 {
    margin-left: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:mt-320,[dir='rtl'].lg\:rtl\:mt-320 {
    margin-top: 32rem
  }

  [dir='rtl'] .lg\:rtl\:mr-320,[dir='rtl'].lg\:rtl\:mr-320 {
    margin-right: 32rem
  }

  [dir='rtl'] .lg\:rtl\:mb-320,[dir='rtl'].lg\:rtl\:mb-320 {
    margin-bottom: 32rem
  }

  [dir='rtl'] .lg\:rtl\:ml-320,[dir='rtl'].lg\:rtl\:ml-320 {
    margin-left: 32rem
  }

  [dir='rtl'] .lg\:rtl\:mt-360,[dir='rtl'].lg\:rtl\:mt-360 {
    margin-top: 36rem
  }

  [dir='rtl'] .lg\:rtl\:mr-360,[dir='rtl'].lg\:rtl\:mr-360 {
    margin-right: 36rem
  }

  [dir='rtl'] .lg\:rtl\:mb-360,[dir='rtl'].lg\:rtl\:mb-360 {
    margin-bottom: 36rem
  }

  [dir='rtl'] .lg\:rtl\:ml-360,[dir='rtl'].lg\:rtl\:ml-360 {
    margin-left: 36rem
  }

  [dir='rtl'] .lg\:rtl\:mt-384,[dir='rtl'].lg\:rtl\:mt-384 {
    margin-top: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:mr-384,[dir='rtl'].lg\:rtl\:mr-384 {
    margin-right: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:mb-384,[dir='rtl'].lg\:rtl\:mb-384 {
    margin-bottom: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:ml-384,[dir='rtl'].lg\:rtl\:ml-384 {
    margin-left: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:mt-400,[dir='rtl'].lg\:rtl\:mt-400 {
    margin-top: 40rem
  }

  [dir='rtl'] .lg\:rtl\:mr-400,[dir='rtl'].lg\:rtl\:mr-400 {
    margin-right: 40rem
  }

  [dir='rtl'] .lg\:rtl\:mb-400,[dir='rtl'].lg\:rtl\:mb-400 {
    margin-bottom: 40rem
  }

  [dir='rtl'] .lg\:rtl\:ml-400,[dir='rtl'].lg\:rtl\:ml-400 {
    margin-left: 40rem
  }

  [dir='rtl'] .lg\:rtl\:mt-512,[dir='rtl'].lg\:rtl\:mt-512 {
    margin-top: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:mr-512,[dir='rtl'].lg\:rtl\:mr-512 {
    margin-right: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:mb-512,[dir='rtl'].lg\:rtl\:mb-512 {
    margin-bottom: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:ml-512,[dir='rtl'].lg\:rtl\:ml-512 {
    margin-left: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:mt-640,[dir='rtl'].lg\:rtl\:mt-640 {
    margin-top: 64rem
  }

  [dir='rtl'] .lg\:rtl\:mr-640,[dir='rtl'].lg\:rtl\:mr-640 {
    margin-right: 64rem
  }

  [dir='rtl'] .lg\:rtl\:mb-640,[dir='rtl'].lg\:rtl\:mb-640 {
    margin-bottom: 64rem
  }

  [dir='rtl'] .lg\:rtl\:ml-640,[dir='rtl'].lg\:rtl\:ml-640 {
    margin-left: 64rem
  }

  [dir='rtl'] .lg\:rtl\:mt-auto,[dir='rtl'].lg\:rtl\:mt-auto {
    margin-top: auto
  }

  [dir='rtl'] .lg\:rtl\:mr-auto,[dir='rtl'].lg\:rtl\:mr-auto {
    margin-right: auto
  }

  [dir='rtl'] .lg\:rtl\:mb-auto,[dir='rtl'].lg\:rtl\:mb-auto {
    margin-bottom: auto
  }

  [dir='rtl'] .lg\:rtl\:ml-auto,[dir='rtl'].lg\:rtl\:ml-auto {
    margin-left: auto
  }

  [dir='rtl'] .lg\:rtl\:mt-xs,[dir='rtl'].lg\:rtl\:mt-xs {
    margin-top: 32rem
  }

  [dir='rtl'] .lg\:rtl\:mr-xs,[dir='rtl'].lg\:rtl\:mr-xs {
    margin-right: 32rem
  }

  [dir='rtl'] .lg\:rtl\:mb-xs,[dir='rtl'].lg\:rtl\:mb-xs {
    margin-bottom: 32rem
  }

  [dir='rtl'] .lg\:rtl\:ml-xs,[dir='rtl'].lg\:rtl\:ml-xs {
    margin-left: 32rem
  }

  [dir='rtl'] .lg\:rtl\:mt-sm,[dir='rtl'].lg\:rtl\:mt-sm {
    margin-top: 48rem
  }

  [dir='rtl'] .lg\:rtl\:mr-sm,[dir='rtl'].lg\:rtl\:mr-sm {
    margin-right: 48rem
  }

  [dir='rtl'] .lg\:rtl\:mb-sm,[dir='rtl'].lg\:rtl\:mb-sm {
    margin-bottom: 48rem
  }

  [dir='rtl'] .lg\:rtl\:ml-sm,[dir='rtl'].lg\:rtl\:ml-sm {
    margin-left: 48rem
  }

  [dir='rtl'] .lg\:rtl\:mt-md,[dir='rtl'].lg\:rtl\:mt-md {
    margin-top: 64rem
  }

  [dir='rtl'] .lg\:rtl\:mr-md,[dir='rtl'].lg\:rtl\:mr-md {
    margin-right: 64rem
  }

  [dir='rtl'] .lg\:rtl\:mb-md,[dir='rtl'].lg\:rtl\:mb-md {
    margin-bottom: 64rem
  }

  [dir='rtl'] .lg\:rtl\:ml-md,[dir='rtl'].lg\:rtl\:ml-md {
    margin-left: 64rem
  }

  [dir='rtl'] .lg\:rtl\:mt-lg,[dir='rtl'].lg\:rtl\:mt-lg {
    margin-top: 80rem
  }

  [dir='rtl'] .lg\:rtl\:mr-lg,[dir='rtl'].lg\:rtl\:mr-lg {
    margin-right: 80rem
  }

  [dir='rtl'] .lg\:rtl\:mb-lg,[dir='rtl'].lg\:rtl\:mb-lg {
    margin-bottom: 80rem
  }

  [dir='rtl'] .lg\:rtl\:ml-lg,[dir='rtl'].lg\:rtl\:ml-lg {
    margin-left: 80rem
  }

  [dir='rtl'] .lg\:rtl\:mt-xl,[dir='rtl'].lg\:rtl\:mt-xl {
    margin-top: 96rem
  }

  [dir='rtl'] .lg\:rtl\:mr-xl,[dir='rtl'].lg\:rtl\:mr-xl {
    margin-right: 96rem
  }

  [dir='rtl'] .lg\:rtl\:mb-xl,[dir='rtl'].lg\:rtl\:mb-xl {
    margin-bottom: 96rem
  }

  [dir='rtl'] .lg\:rtl\:ml-xl,[dir='rtl'].lg\:rtl\:ml-xl {
    margin-left: 96rem
  }

  [dir='rtl'] .lg\:rtl\:mt-2xl,[dir='rtl'].lg\:rtl\:mt-2xl {
    margin-top: 112rem
  }

  [dir='rtl'] .lg\:rtl\:mr-2xl,[dir='rtl'].lg\:rtl\:mr-2xl {
    margin-right: 112rem
  }

  [dir='rtl'] .lg\:rtl\:mb-2xl,[dir='rtl'].lg\:rtl\:mb-2xl {
    margin-bottom: 112rem
  }

  [dir='rtl'] .lg\:rtl\:ml-2xl,[dir='rtl'].lg\:rtl\:ml-2xl {
    margin-left: 112rem
  }

  [dir='rtl'] .lg\:rtl\:mt-3xl,[dir='rtl'].lg\:rtl\:mt-3xl {
    margin-top: 128rem
  }

  [dir='rtl'] .lg\:rtl\:mr-3xl,[dir='rtl'].lg\:rtl\:mr-3xl {
    margin-right: 128rem
  }

  [dir='rtl'] .lg\:rtl\:mb-3xl,[dir='rtl'].lg\:rtl\:mb-3xl {
    margin-bottom: 128rem
  }

  [dir='rtl'] .lg\:rtl\:ml-3xl,[dir='rtl'].lg\:rtl\:ml-3xl {
    margin-left: 128rem
  }

  [dir='rtl'] .lg\:rtl\:mt-4xl,[dir='rtl'].lg\:rtl\:mt-4xl {
    margin-top: 144rem
  }

  [dir='rtl'] .lg\:rtl\:mr-4xl,[dir='rtl'].lg\:rtl\:mr-4xl {
    margin-right: 144rem
  }

  [dir='rtl'] .lg\:rtl\:mb-4xl,[dir='rtl'].lg\:rtl\:mb-4xl {
    margin-bottom: 144rem
  }

  [dir='rtl'] .lg\:rtl\:ml-4xl,[dir='rtl'].lg\:rtl\:ml-4xl {
    margin-left: 144rem
  }

  [dir='rtl'] .lg\:rtl\:mt-5xl,[dir='rtl'].lg\:rtl\:mt-5xl {
    margin-top: 160rem
  }

  [dir='rtl'] .lg\:rtl\:mr-5xl,[dir='rtl'].lg\:rtl\:mr-5xl {
    margin-right: 160rem
  }

  [dir='rtl'] .lg\:rtl\:mb-5xl,[dir='rtl'].lg\:rtl\:mb-5xl {
    margin-bottom: 160rem
  }

  [dir='rtl'] .lg\:rtl\:ml-5xl,[dir='rtl'].lg\:rtl\:ml-5xl {
    margin-left: 160rem
  }

  [dir='rtl'] .lg\:rtl\:mt-px,[dir='rtl'].lg\:rtl\:mt-px {
    margin-top: 1px
  }

  [dir='rtl'] .lg\:rtl\:mr-px,[dir='rtl'].lg\:rtl\:mr-px {
    margin-right: 1px
  }

  [dir='rtl'] .lg\:rtl\:mb-px,[dir='rtl'].lg\:rtl\:mb-px {
    margin-bottom: 1px
  }

  [dir='rtl'] .lg\:rtl\:ml-px,[dir='rtl'].lg\:rtl\:ml-px {
    margin-left: 1px
  }

  [dir='rtl'] .lg\:rtl\:mt-0\.5,[dir='rtl'].lg\:rtl\:mt-0\.5 {
    margin-top: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:mr-0\.5,[dir='rtl'].lg\:rtl\:mr-0\.5 {
    margin-right: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:mb-0\.5,[dir='rtl'].lg\:rtl\:mb-0\.5 {
    margin-bottom: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:ml-0\.5,[dir='rtl'].lg\:rtl\:ml-0\.5 {
    margin-left: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:mt-1\.5,[dir='rtl'].lg\:rtl\:mt-1\.5 {
    margin-top: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:mr-1\.5,[dir='rtl'].lg\:rtl\:mr-1\.5 {
    margin-right: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:mb-1\.5,[dir='rtl'].lg\:rtl\:mb-1\.5 {
    margin-bottom: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:ml-1\.5,[dir='rtl'].lg\:rtl\:ml-1\.5 {
    margin-left: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:mt-2\.5,[dir='rtl'].lg\:rtl\:mt-2\.5 {
    margin-top: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:mr-2\.5,[dir='rtl'].lg\:rtl\:mr-2\.5 {
    margin-right: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:mb-2\.5,[dir='rtl'].lg\:rtl\:mb-2\.5 {
    margin-bottom: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:ml-2\.5,[dir='rtl'].lg\:rtl\:ml-2\.5 {
    margin-left: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:mt-3\.5,[dir='rtl'].lg\:rtl\:mt-3\.5 {
    margin-top: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:mr-3\.5,[dir='rtl'].lg\:rtl\:mr-3\.5 {
    margin-right: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:mb-3\.5,[dir='rtl'].lg\:rtl\:mb-3\.5 {
    margin-bottom: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:ml-3\.5,[dir='rtl'].lg\:rtl\:ml-3\.5 {
    margin-left: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-1,[dir='rtl'].lg\:rtl\:-mt-1 {
    margin-top: -0.1rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-1,[dir='rtl'].lg\:rtl\:-mr-1 {
    margin-right: -0.1rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-1,[dir='rtl'].lg\:rtl\:-mb-1 {
    margin-bottom: -0.1rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-1,[dir='rtl'].lg\:rtl\:-ml-1 {
    margin-left: -0.1rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-2,[dir='rtl'].lg\:rtl\:-mt-2 {
    margin-top: -0.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-2,[dir='rtl'].lg\:rtl\:-mr-2 {
    margin-right: -0.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-2,[dir='rtl'].lg\:rtl\:-mb-2 {
    margin-bottom: -0.2rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-2,[dir='rtl'].lg\:rtl\:-ml-2 {
    margin-left: -0.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-3,[dir='rtl'].lg\:rtl\:-mt-3 {
    margin-top: -0.3rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-3,[dir='rtl'].lg\:rtl\:-mr-3 {
    margin-right: -0.3rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-3,[dir='rtl'].lg\:rtl\:-mb-3 {
    margin-bottom: -0.3rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-3,[dir='rtl'].lg\:rtl\:-ml-3 {
    margin-left: -0.3rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-4,[dir='rtl'].lg\:rtl\:-mt-4 {
    margin-top: -0.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-4,[dir='rtl'].lg\:rtl\:-mr-4 {
    margin-right: -0.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-4,[dir='rtl'].lg\:rtl\:-mb-4 {
    margin-bottom: -0.4rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-4,[dir='rtl'].lg\:rtl\:-ml-4 {
    margin-left: -0.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-5,[dir='rtl'].lg\:rtl\:-mt-5 {
    margin-top: -0.5rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-5,[dir='rtl'].lg\:rtl\:-mr-5 {
    margin-right: -0.5rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-5,[dir='rtl'].lg\:rtl\:-mb-5 {
    margin-bottom: -0.5rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-5,[dir='rtl'].lg\:rtl\:-ml-5 {
    margin-left: -0.5rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-6,[dir='rtl'].lg\:rtl\:-mt-6 {
    margin-top: -0.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-6,[dir='rtl'].lg\:rtl\:-mr-6 {
    margin-right: -0.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-6,[dir='rtl'].lg\:rtl\:-mb-6 {
    margin-bottom: -0.6rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-6,[dir='rtl'].lg\:rtl\:-ml-6 {
    margin-left: -0.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-7,[dir='rtl'].lg\:rtl\:-mt-7 {
    margin-top: -0.7rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-7,[dir='rtl'].lg\:rtl\:-mr-7 {
    margin-right: -0.7rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-7,[dir='rtl'].lg\:rtl\:-mb-7 {
    margin-bottom: -0.7rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-7,[dir='rtl'].lg\:rtl\:-ml-7 {
    margin-left: -0.7rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-8,[dir='rtl'].lg\:rtl\:-mt-8 {
    margin-top: -0.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-8,[dir='rtl'].lg\:rtl\:-mr-8 {
    margin-right: -0.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-8,[dir='rtl'].lg\:rtl\:-mb-8 {
    margin-bottom: -0.8rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-8,[dir='rtl'].lg\:rtl\:-ml-8 {
    margin-left: -0.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-9,[dir='rtl'].lg\:rtl\:-mt-9 {
    margin-top: -0.9rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-9,[dir='rtl'].lg\:rtl\:-mr-9 {
    margin-right: -0.9rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-9,[dir='rtl'].lg\:rtl\:-mb-9 {
    margin-bottom: -0.9rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-9,[dir='rtl'].lg\:rtl\:-ml-9 {
    margin-left: -0.9rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-10,[dir='rtl'].lg\:rtl\:-mt-10 {
    margin-top: -1rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-10,[dir='rtl'].lg\:rtl\:-mr-10 {
    margin-right: -1rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-10,[dir='rtl'].lg\:rtl\:-mb-10 {
    margin-bottom: -1rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-10,[dir='rtl'].lg\:rtl\:-ml-10 {
    margin-left: -1rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-12,[dir='rtl'].lg\:rtl\:-mt-12 {
    margin-top: -1.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-12,[dir='rtl'].lg\:rtl\:-mr-12 {
    margin-right: -1.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-12,[dir='rtl'].lg\:rtl\:-mb-12 {
    margin-bottom: -1.2rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-12,[dir='rtl'].lg\:rtl\:-ml-12 {
    margin-left: -1.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-14,[dir='rtl'].lg\:rtl\:-mt-14 {
    margin-top: -1.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-14,[dir='rtl'].lg\:rtl\:-mr-14 {
    margin-right: -1.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-14,[dir='rtl'].lg\:rtl\:-mb-14 {
    margin-bottom: -1.4rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-14,[dir='rtl'].lg\:rtl\:-ml-14 {
    margin-left: -1.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-16,[dir='rtl'].lg\:rtl\:-mt-16 {
    margin-top: -1.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-16,[dir='rtl'].lg\:rtl\:-mr-16 {
    margin-right: -1.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-16,[dir='rtl'].lg\:rtl\:-mb-16 {
    margin-bottom: -1.6rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-16,[dir='rtl'].lg\:rtl\:-ml-16 {
    margin-left: -1.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-20,[dir='rtl'].lg\:rtl\:-mt-20 {
    margin-top: -2rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-20,[dir='rtl'].lg\:rtl\:-mr-20 {
    margin-right: -2rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-20,[dir='rtl'].lg\:rtl\:-mb-20 {
    margin-bottom: -2rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-20,[dir='rtl'].lg\:rtl\:-ml-20 {
    margin-left: -2rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-24,[dir='rtl'].lg\:rtl\:-mt-24 {
    margin-top: -2.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-24,[dir='rtl'].lg\:rtl\:-mr-24 {
    margin-right: -2.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-24,[dir='rtl'].lg\:rtl\:-mb-24 {
    margin-bottom: -2.4rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-24,[dir='rtl'].lg\:rtl\:-ml-24 {
    margin-left: -2.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-28,[dir='rtl'].lg\:rtl\:-mt-28 {
    margin-top: -2.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-28,[dir='rtl'].lg\:rtl\:-mr-28 {
    margin-right: -2.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-28,[dir='rtl'].lg\:rtl\:-mb-28 {
    margin-bottom: -2.8rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-28,[dir='rtl'].lg\:rtl\:-ml-28 {
    margin-left: -2.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-32,[dir='rtl'].lg\:rtl\:-mt-32 {
    margin-top: -3.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-32,[dir='rtl'].lg\:rtl\:-mr-32 {
    margin-right: -3.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-32,[dir='rtl'].lg\:rtl\:-mb-32 {
    margin-bottom: -3.2rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-32,[dir='rtl'].lg\:rtl\:-ml-32 {
    margin-left: -3.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-36,[dir='rtl'].lg\:rtl\:-mt-36 {
    margin-top: -3.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-36,[dir='rtl'].lg\:rtl\:-mr-36 {
    margin-right: -3.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-36,[dir='rtl'].lg\:rtl\:-mb-36 {
    margin-bottom: -3.6rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-36,[dir='rtl'].lg\:rtl\:-ml-36 {
    margin-left: -3.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-40,[dir='rtl'].lg\:rtl\:-mt-40 {
    margin-top: -4rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-40,[dir='rtl'].lg\:rtl\:-mr-40 {
    margin-right: -4rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-40,[dir='rtl'].lg\:rtl\:-mb-40 {
    margin-bottom: -4rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-40,[dir='rtl'].lg\:rtl\:-ml-40 {
    margin-left: -4rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-44,[dir='rtl'].lg\:rtl\:-mt-44 {
    margin-top: -4.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-44,[dir='rtl'].lg\:rtl\:-mr-44 {
    margin-right: -4.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-44,[dir='rtl'].lg\:rtl\:-mb-44 {
    margin-bottom: -4.4rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-44,[dir='rtl'].lg\:rtl\:-ml-44 {
    margin-left: -4.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-48,[dir='rtl'].lg\:rtl\:-mt-48 {
    margin-top: -4.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-48,[dir='rtl'].lg\:rtl\:-mr-48 {
    margin-right: -4.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-48,[dir='rtl'].lg\:rtl\:-mb-48 {
    margin-bottom: -4.8rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-48,[dir='rtl'].lg\:rtl\:-ml-48 {
    margin-left: -4.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-52,[dir='rtl'].lg\:rtl\:-mt-52 {
    margin-top: -5.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-52,[dir='rtl'].lg\:rtl\:-mr-52 {
    margin-right: -5.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-52,[dir='rtl'].lg\:rtl\:-mb-52 {
    margin-bottom: -5.2rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-52,[dir='rtl'].lg\:rtl\:-ml-52 {
    margin-left: -5.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-56,[dir='rtl'].lg\:rtl\:-mt-56 {
    margin-top: -5.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-56,[dir='rtl'].lg\:rtl\:-mr-56 {
    margin-right: -5.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-56,[dir='rtl'].lg\:rtl\:-mb-56 {
    margin-bottom: -5.6rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-56,[dir='rtl'].lg\:rtl\:-ml-56 {
    margin-left: -5.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-60,[dir='rtl'].lg\:rtl\:-mt-60 {
    margin-top: -6rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-60,[dir='rtl'].lg\:rtl\:-mr-60 {
    margin-right: -6rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-60,[dir='rtl'].lg\:rtl\:-mb-60 {
    margin-bottom: -6rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-60,[dir='rtl'].lg\:rtl\:-ml-60 {
    margin-left: -6rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-64,[dir='rtl'].lg\:rtl\:-mt-64 {
    margin-top: -6.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-64,[dir='rtl'].lg\:rtl\:-mr-64 {
    margin-right: -6.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-64,[dir='rtl'].lg\:rtl\:-mb-64 {
    margin-bottom: -6.4rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-64,[dir='rtl'].lg\:rtl\:-ml-64 {
    margin-left: -6.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-68,[dir='rtl'].lg\:rtl\:-mt-68 {
    margin-top: -6.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-68,[dir='rtl'].lg\:rtl\:-mr-68 {
    margin-right: -6.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-68,[dir='rtl'].lg\:rtl\:-mb-68 {
    margin-bottom: -6.8rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-68,[dir='rtl'].lg\:rtl\:-ml-68 {
    margin-left: -6.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-72,[dir='rtl'].lg\:rtl\:-mt-72 {
    margin-top: -7.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-72,[dir='rtl'].lg\:rtl\:-mr-72 {
    margin-right: -7.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-72,[dir='rtl'].lg\:rtl\:-mb-72 {
    margin-bottom: -7.2rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-72,[dir='rtl'].lg\:rtl\:-ml-72 {
    margin-left: -7.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-76,[dir='rtl'].lg\:rtl\:-mt-76 {
    margin-top: -7.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-76,[dir='rtl'].lg\:rtl\:-mr-76 {
    margin-right: -7.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-76,[dir='rtl'].lg\:rtl\:-mb-76 {
    margin-bottom: -7.6rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-76,[dir='rtl'].lg\:rtl\:-ml-76 {
    margin-left: -7.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-80,[dir='rtl'].lg\:rtl\:-mt-80 {
    margin-top: -8rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-80,[dir='rtl'].lg\:rtl\:-mr-80 {
    margin-right: -8rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-80,[dir='rtl'].lg\:rtl\:-mb-80 {
    margin-bottom: -8rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-80,[dir='rtl'].lg\:rtl\:-ml-80 {
    margin-left: -8rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-84,[dir='rtl'].lg\:rtl\:-mt-84 {
    margin-top: -8.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-84,[dir='rtl'].lg\:rtl\:-mr-84 {
    margin-right: -8.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-84,[dir='rtl'].lg\:rtl\:-mb-84 {
    margin-bottom: -8.4rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-84,[dir='rtl'].lg\:rtl\:-ml-84 {
    margin-left: -8.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-88,[dir='rtl'].lg\:rtl\:-mt-88 {
    margin-top: -8.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-88,[dir='rtl'].lg\:rtl\:-mr-88 {
    margin-right: -8.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-88,[dir='rtl'].lg\:rtl\:-mb-88 {
    margin-bottom: -8.8rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-88,[dir='rtl'].lg\:rtl\:-ml-88 {
    margin-left: -8.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-92,[dir='rtl'].lg\:rtl\:-mt-92 {
    margin-top: -9.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-92,[dir='rtl'].lg\:rtl\:-mr-92 {
    margin-right: -9.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-92,[dir='rtl'].lg\:rtl\:-mb-92 {
    margin-bottom: -9.2rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-92,[dir='rtl'].lg\:rtl\:-ml-92 {
    margin-left: -9.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-96,[dir='rtl'].lg\:rtl\:-mt-96 {
    margin-top: -9.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-96,[dir='rtl'].lg\:rtl\:-mr-96 {
    margin-right: -9.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-96,[dir='rtl'].lg\:rtl\:-mb-96 {
    margin-bottom: -9.6rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-96,[dir='rtl'].lg\:rtl\:-ml-96 {
    margin-left: -9.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-128,[dir='rtl'].lg\:rtl\:-mt-128 {
    margin-top: -12.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-128,[dir='rtl'].lg\:rtl\:-mr-128 {
    margin-right: -12.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-128,[dir='rtl'].lg\:rtl\:-mb-128 {
    margin-bottom: -12.8rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-128,[dir='rtl'].lg\:rtl\:-ml-128 {
    margin-left: -12.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-136,[dir='rtl'].lg\:rtl\:-mt-136 {
    margin-top: -13.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-136,[dir='rtl'].lg\:rtl\:-mr-136 {
    margin-right: -13.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-136,[dir='rtl'].lg\:rtl\:-mb-136 {
    margin-bottom: -13.6rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-136,[dir='rtl'].lg\:rtl\:-ml-136 {
    margin-left: -13.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-160,[dir='rtl'].lg\:rtl\:-mt-160 {
    margin-top: -16rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-160,[dir='rtl'].lg\:rtl\:-mr-160 {
    margin-right: -16rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-160,[dir='rtl'].lg\:rtl\:-mb-160 {
    margin-bottom: -16rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-160,[dir='rtl'].lg\:rtl\:-ml-160 {
    margin-left: -16rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-192,[dir='rtl'].lg\:rtl\:-mt-192 {
    margin-top: -19.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-192,[dir='rtl'].lg\:rtl\:-mr-192 {
    margin-right: -19.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-192,[dir='rtl'].lg\:rtl\:-mb-192 {
    margin-bottom: -19.2rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-192,[dir='rtl'].lg\:rtl\:-ml-192 {
    margin-left: -19.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-200,[dir='rtl'].lg\:rtl\:-mt-200 {
    margin-top: -20rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-200,[dir='rtl'].lg\:rtl\:-mr-200 {
    margin-right: -20rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-200,[dir='rtl'].lg\:rtl\:-mb-200 {
    margin-bottom: -20rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-200,[dir='rtl'].lg\:rtl\:-ml-200 {
    margin-left: -20rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-208,[dir='rtl'].lg\:rtl\:-mt-208 {
    margin-top: -20.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-208,[dir='rtl'].lg\:rtl\:-mr-208 {
    margin-right: -20.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-208,[dir='rtl'].lg\:rtl\:-mb-208 {
    margin-bottom: -20.8rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-208,[dir='rtl'].lg\:rtl\:-ml-208 {
    margin-left: -20.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-216,[dir='rtl'].lg\:rtl\:-mt-216 {
    margin-top: -21.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-216,[dir='rtl'].lg\:rtl\:-mr-216 {
    margin-right: -21.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-216,[dir='rtl'].lg\:rtl\:-mb-216 {
    margin-bottom: -21.6rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-216,[dir='rtl'].lg\:rtl\:-ml-216 {
    margin-left: -21.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-224,[dir='rtl'].lg\:rtl\:-mt-224 {
    margin-top: -22.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-224,[dir='rtl'].lg\:rtl\:-mr-224 {
    margin-right: -22.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-224,[dir='rtl'].lg\:rtl\:-mb-224 {
    margin-bottom: -22.4rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-224,[dir='rtl'].lg\:rtl\:-ml-224 {
    margin-left: -22.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-256,[dir='rtl'].lg\:rtl\:-mt-256 {
    margin-top: -25.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-256,[dir='rtl'].lg\:rtl\:-mr-256 {
    margin-right: -25.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-256,[dir='rtl'].lg\:rtl\:-mb-256 {
    margin-bottom: -25.6rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-256,[dir='rtl'].lg\:rtl\:-ml-256 {
    margin-left: -25.6rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-288,[dir='rtl'].lg\:rtl\:-mt-288 {
    margin-top: -28.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-288,[dir='rtl'].lg\:rtl\:-mr-288 {
    margin-right: -28.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-288,[dir='rtl'].lg\:rtl\:-mb-288 {
    margin-bottom: -28.8rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-288,[dir='rtl'].lg\:rtl\:-ml-288 {
    margin-left: -28.8rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-320,[dir='rtl'].lg\:rtl\:-mt-320 {
    margin-top: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-320,[dir='rtl'].lg\:rtl\:-mr-320 {
    margin-right: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-320,[dir='rtl'].lg\:rtl\:-mb-320 {
    margin-bottom: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-320,[dir='rtl'].lg\:rtl\:-ml-320 {
    margin-left: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-360,[dir='rtl'].lg\:rtl\:-mt-360 {
    margin-top: -36rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-360,[dir='rtl'].lg\:rtl\:-mr-360 {
    margin-right: -36rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-360,[dir='rtl'].lg\:rtl\:-mb-360 {
    margin-bottom: -36rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-360,[dir='rtl'].lg\:rtl\:-ml-360 {
    margin-left: -36rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-384,[dir='rtl'].lg\:rtl\:-mt-384 {
    margin-top: -38.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-384,[dir='rtl'].lg\:rtl\:-mr-384 {
    margin-right: -38.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-384,[dir='rtl'].lg\:rtl\:-mb-384 {
    margin-bottom: -38.4rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-384,[dir='rtl'].lg\:rtl\:-ml-384 {
    margin-left: -38.4rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-400,[dir='rtl'].lg\:rtl\:-mt-400 {
    margin-top: -40rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-400,[dir='rtl'].lg\:rtl\:-mr-400 {
    margin-right: -40rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-400,[dir='rtl'].lg\:rtl\:-mb-400 {
    margin-bottom: -40rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-400,[dir='rtl'].lg\:rtl\:-ml-400 {
    margin-left: -40rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-512,[dir='rtl'].lg\:rtl\:-mt-512 {
    margin-top: -51.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-512,[dir='rtl'].lg\:rtl\:-mr-512 {
    margin-right: -51.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-512,[dir='rtl'].lg\:rtl\:-mb-512 {
    margin-bottom: -51.2rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-512,[dir='rtl'].lg\:rtl\:-ml-512 {
    margin-left: -51.2rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-640,[dir='rtl'].lg\:rtl\:-mt-640 {
    margin-top: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-640,[dir='rtl'].lg\:rtl\:-mr-640 {
    margin-right: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-640,[dir='rtl'].lg\:rtl\:-mb-640 {
    margin-bottom: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-640,[dir='rtl'].lg\:rtl\:-ml-640 {
    margin-left: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-xs,[dir='rtl'].lg\:rtl\:-mt-xs {
    margin-top: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-xs,[dir='rtl'].lg\:rtl\:-mr-xs {
    margin-right: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-xs,[dir='rtl'].lg\:rtl\:-mb-xs {
    margin-bottom: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-xs,[dir='rtl'].lg\:rtl\:-ml-xs {
    margin-left: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-sm,[dir='rtl'].lg\:rtl\:-mt-sm {
    margin-top: -48rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-sm,[dir='rtl'].lg\:rtl\:-mr-sm {
    margin-right: -48rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-sm,[dir='rtl'].lg\:rtl\:-mb-sm {
    margin-bottom: -48rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-sm,[dir='rtl'].lg\:rtl\:-ml-sm {
    margin-left: -48rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-md,[dir='rtl'].lg\:rtl\:-mt-md {
    margin-top: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-md,[dir='rtl'].lg\:rtl\:-mr-md {
    margin-right: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-md,[dir='rtl'].lg\:rtl\:-mb-md {
    margin-bottom: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-md,[dir='rtl'].lg\:rtl\:-ml-md {
    margin-left: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-lg,[dir='rtl'].lg\:rtl\:-mt-lg {
    margin-top: -80rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-lg,[dir='rtl'].lg\:rtl\:-mr-lg {
    margin-right: -80rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-lg,[dir='rtl'].lg\:rtl\:-mb-lg {
    margin-bottom: -80rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-lg,[dir='rtl'].lg\:rtl\:-ml-lg {
    margin-left: -80rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-xl,[dir='rtl'].lg\:rtl\:-mt-xl {
    margin-top: -96rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-xl,[dir='rtl'].lg\:rtl\:-mr-xl {
    margin-right: -96rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-xl,[dir='rtl'].lg\:rtl\:-mb-xl {
    margin-bottom: -96rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-xl,[dir='rtl'].lg\:rtl\:-ml-xl {
    margin-left: -96rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-2xl,[dir='rtl'].lg\:rtl\:-mt-2xl {
    margin-top: -112rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-2xl,[dir='rtl'].lg\:rtl\:-mr-2xl {
    margin-right: -112rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-2xl,[dir='rtl'].lg\:rtl\:-mb-2xl {
    margin-bottom: -112rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-2xl,[dir='rtl'].lg\:rtl\:-ml-2xl {
    margin-left: -112rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-3xl,[dir='rtl'].lg\:rtl\:-mt-3xl {
    margin-top: -128rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-3xl,[dir='rtl'].lg\:rtl\:-mr-3xl {
    margin-right: -128rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-3xl,[dir='rtl'].lg\:rtl\:-mb-3xl {
    margin-bottom: -128rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-3xl,[dir='rtl'].lg\:rtl\:-ml-3xl {
    margin-left: -128rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-4xl,[dir='rtl'].lg\:rtl\:-mt-4xl {
    margin-top: -144rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-4xl,[dir='rtl'].lg\:rtl\:-mr-4xl {
    margin-right: -144rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-4xl,[dir='rtl'].lg\:rtl\:-mb-4xl {
    margin-bottom: -144rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-4xl,[dir='rtl'].lg\:rtl\:-ml-4xl {
    margin-left: -144rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-5xl,[dir='rtl'].lg\:rtl\:-mt-5xl {
    margin-top: -160rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-5xl,[dir='rtl'].lg\:rtl\:-mr-5xl {
    margin-right: -160rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-5xl,[dir='rtl'].lg\:rtl\:-mb-5xl {
    margin-bottom: -160rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-5xl,[dir='rtl'].lg\:rtl\:-ml-5xl {
    margin-left: -160rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-px,[dir='rtl'].lg\:rtl\:-mt-px {
    margin-top: -1px
  }

  [dir='rtl'] .lg\:rtl\:-mr-px,[dir='rtl'].lg\:rtl\:-mr-px {
    margin-right: -1px
  }

  [dir='rtl'] .lg\:rtl\:-mb-px,[dir='rtl'].lg\:rtl\:-mb-px {
    margin-bottom: -1px
  }

  [dir='rtl'] .lg\:rtl\:-ml-px,[dir='rtl'].lg\:rtl\:-ml-px {
    margin-left: -1px
  }

  [dir='rtl'] .lg\:rtl\:-mt-0\.5,[dir='rtl'].lg\:rtl\:-mt-0\.5 {
    margin-top: -0.05rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-0\.5,[dir='rtl'].lg\:rtl\:-mr-0\.5 {
    margin-right: -0.05rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-0\.5,[dir='rtl'].lg\:rtl\:-mb-0\.5 {
    margin-bottom: -0.05rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-0\.5,[dir='rtl'].lg\:rtl\:-ml-0\.5 {
    margin-left: -0.05rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-1\.5,[dir='rtl'].lg\:rtl\:-mt-1\.5 {
    margin-top: -0.15rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-1\.5,[dir='rtl'].lg\:rtl\:-mr-1\.5 {
    margin-right: -0.15rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-1\.5,[dir='rtl'].lg\:rtl\:-mb-1\.5 {
    margin-bottom: -0.15rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-1\.5,[dir='rtl'].lg\:rtl\:-ml-1\.5 {
    margin-left: -0.15rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-2\.5,[dir='rtl'].lg\:rtl\:-mt-2\.5 {
    margin-top: -0.25rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-2\.5,[dir='rtl'].lg\:rtl\:-mr-2\.5 {
    margin-right: -0.25rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-2\.5,[dir='rtl'].lg\:rtl\:-mb-2\.5 {
    margin-bottom: -0.25rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-2\.5,[dir='rtl'].lg\:rtl\:-ml-2\.5 {
    margin-left: -0.25rem
  }

  [dir='rtl'] .lg\:rtl\:-mt-3\.5,[dir='rtl'].lg\:rtl\:-mt-3\.5 {
    margin-top: -0.35rem
  }

  [dir='rtl'] .lg\:rtl\:-mr-3\.5,[dir='rtl'].lg\:rtl\:-mr-3\.5 {
    margin-right: -0.35rem
  }

  [dir='rtl'] .lg\:rtl\:-mb-3\.5,[dir='rtl'].lg\:rtl\:-mb-3\.5 {
    margin-bottom: -0.35rem
  }

  [dir='rtl'] .lg\:rtl\:-ml-3\.5,[dir='rtl'].lg\:rtl\:-ml-3\.5 {
    margin-left: -0.35rem
  }

  .lg\:max-h-0 {
    max-height: 0
  }

  .lg\:max-h-1 {
    max-height: 0.1rem
  }

  .lg\:max-h-2 {
    max-height: 0.2rem
  }

  .lg\:max-h-3 {
    max-height: 0.3rem
  }

  .lg\:max-h-4 {
    max-height: 0.4rem
  }

  .lg\:max-h-5 {
    max-height: 0.5rem
  }

  .lg\:max-h-6 {
    max-height: 0.6rem
  }

  .lg\:max-h-7 {
    max-height: 0.7rem
  }

  .lg\:max-h-8 {
    max-height: 0.8rem
  }

  .lg\:max-h-9 {
    max-height: 0.9rem
  }

  .lg\:max-h-10 {
    max-height: 1.0rem
  }

  .lg\:max-h-12 {
    max-height: 1.2rem
  }

  .lg\:max-h-14 {
    max-height: 1.4rem
  }

  .lg\:max-h-16 {
    max-height: 1.6rem
  }

  .lg\:max-h-20 {
    max-height: 2rem
  }

  .lg\:max-h-24 {
    max-height: 2.4rem
  }

  .lg\:max-h-28 {
    max-height: 2.8rem
  }

  .lg\:max-h-32 {
    max-height: 3.2rem
  }

  .lg\:max-h-36 {
    max-height: 3.6rem
  }

  .lg\:max-h-40 {
    max-height: 4rem
  }

  .lg\:max-h-44 {
    max-height: 4.4rem
  }

  .lg\:max-h-48 {
    max-height: 4.8rem
  }

  .lg\:max-h-52 {
    max-height: 5.2rem
  }

  .lg\:max-h-56 {
    max-height: 5.6rem
  }

  .lg\:max-h-60 {
    max-height: 6rem
  }

  .lg\:max-h-64 {
    max-height: 6.4rem
  }

  .lg\:max-h-68 {
    max-height: 6.8rem
  }

  .lg\:max-h-72 {
    max-height: 7.2rem
  }

  .lg\:max-h-76 {
    max-height: 7.6rem
  }

  .lg\:max-h-80 {
    max-height: 8rem
  }

  .lg\:max-h-84 {
    max-height: 8.4rem
  }

  .lg\:max-h-88 {
    max-height: 8.8rem
  }

  .lg\:max-h-92 {
    max-height: 9.2rem
  }

  .lg\:max-h-96 {
    max-height: 9.6rem
  }

  .lg\:max-h-128 {
    max-height: 12.8rem
  }

  .lg\:max-h-136 {
    max-height: 13.6rem
  }

  .lg\:max-h-160 {
    max-height: 16rem
  }

  .lg\:max-h-192 {
    max-height: 19.2rem
  }

  .lg\:max-h-200 {
    max-height: 20rem
  }

  .lg\:max-h-208 {
    max-height: 20.8rem
  }

  .lg\:max-h-216 {
    max-height: 21.6rem
  }

  .lg\:max-h-224 {
    max-height: 22.4rem
  }

  .lg\:max-h-256 {
    max-height: 25.6rem
  }

  .lg\:max-h-288 {
    max-height: 28.8rem
  }

  .lg\:max-h-320 {
    max-height: 32rem
  }

  .lg\:max-h-360 {
    max-height: 36rem
  }

  .lg\:max-h-384 {
    max-height: 38.4rem
  }

  .lg\:max-h-400 {
    max-height: 40rem
  }

  .lg\:max-h-512 {
    max-height: 51.2rem
  }

  .lg\:max-h-640 {
    max-height: 64rem
  }

  .lg\:max-h-xs {
    max-height: 32rem
  }

  .lg\:max-h-sm {
    max-height: 48rem
  }

  .lg\:max-h-md {
    max-height: 64rem
  }

  .lg\:max-h-lg {
    max-height: 80rem
  }

  .lg\:max-h-xl {
    max-height: 96rem
  }

  .lg\:max-h-2xl {
    max-height: 112rem
  }

  .lg\:max-h-3xl {
    max-height: 128rem
  }

  .lg\:max-h-4xl {
    max-height: 144rem
  }

  .lg\:max-h-5xl {
    max-height: 160rem
  }

  .lg\:max-h-px {
    max-height: 1px
  }

  .lg\:max-h-0\.5 {
    max-height: 0.05rem
  }

  .lg\:max-h-1\.5 {
    max-height: 0.15rem
  }

  .lg\:max-h-2\.5 {
    max-height: 0.25rem
  }

  .lg\:max-h-3\.5 {
    max-height: 0.35rem
  }

  .lg\:max-h-full {
    max-height: 100%
  }

  .lg\:max-h-screen {
    max-height: 100vh
  }

  .lg\:max-h-auto {
    max-height: auto
  }

  .lg\:max-w-0 {
    max-width: 0
  }

  .lg\:max-w-1 {
    max-width: 0.1rem
  }

  .lg\:max-w-2 {
    max-width: 0.2rem
  }

  .lg\:max-w-3 {
    max-width: 0.3rem
  }

  .lg\:max-w-4 {
    max-width: 0.4rem
  }

  .lg\:max-w-5 {
    max-width: 0.5rem
  }

  .lg\:max-w-6 {
    max-width: 0.6rem
  }

  .lg\:max-w-7 {
    max-width: 0.7rem
  }

  .lg\:max-w-8 {
    max-width: 0.8rem
  }

  .lg\:max-w-9 {
    max-width: 0.9rem
  }

  .lg\:max-w-10 {
    max-width: 1.0rem
  }

  .lg\:max-w-12 {
    max-width: 1.2rem
  }

  .lg\:max-w-14 {
    max-width: 1.4rem
  }

  .lg\:max-w-16 {
    max-width: 1.6rem
  }

  .lg\:max-w-20 {
    max-width: 2rem
  }

  .lg\:max-w-24 {
    max-width: 2.4rem
  }

  .lg\:max-w-28 {
    max-width: 2.8rem
  }

  .lg\:max-w-32 {
    max-width: 3.2rem
  }

  .lg\:max-w-36 {
    max-width: 3.6rem
  }

  .lg\:max-w-40 {
    max-width: 4rem
  }

  .lg\:max-w-44 {
    max-width: 4.4rem
  }

  .lg\:max-w-48 {
    max-width: 4.8rem
  }

  .lg\:max-w-52 {
    max-width: 5.2rem
  }

  .lg\:max-w-56 {
    max-width: 5.6rem
  }

  .lg\:max-w-60 {
    max-width: 6rem
  }

  .lg\:max-w-64 {
    max-width: 6.4rem
  }

  .lg\:max-w-68 {
    max-width: 6.8rem
  }

  .lg\:max-w-72 {
    max-width: 7.2rem
  }

  .lg\:max-w-76 {
    max-width: 7.6rem
  }

  .lg\:max-w-80 {
    max-width: 8rem
  }

  .lg\:max-w-84 {
    max-width: 8.4rem
  }

  .lg\:max-w-88 {
    max-width: 8.8rem
  }

  .lg\:max-w-92 {
    max-width: 9.2rem
  }

  .lg\:max-w-96 {
    max-width: 9.6rem
  }

  .lg\:max-w-128 {
    max-width: 12.8rem
  }

  .lg\:max-w-136 {
    max-width: 13.6rem
  }

  .lg\:max-w-160 {
    max-width: 16rem
  }

  .lg\:max-w-192 {
    max-width: 19.2rem
  }

  .lg\:max-w-200 {
    max-width: 20rem
  }

  .lg\:max-w-208 {
    max-width: 20.8rem
  }

  .lg\:max-w-216 {
    max-width: 21.6rem
  }

  .lg\:max-w-224 {
    max-width: 22.4rem
  }

  .lg\:max-w-256 {
    max-width: 25.6rem
  }

  .lg\:max-w-288 {
    max-width: 28.8rem
  }

  .lg\:max-w-320 {
    max-width: 32rem
  }

  .lg\:max-w-360 {
    max-width: 36rem
  }

  .lg\:max-w-384 {
    max-width: 38.4rem
  }

  .lg\:max-w-400 {
    max-width: 40rem
  }

  .lg\:max-w-512 {
    max-width: 51.2rem
  }

  .lg\:max-w-640 {
    max-width: 64rem
  }

  .lg\:max-w-none {
    max-width: none
  }

  .lg\:max-w-xs {
    max-width: 32rem
  }

  .lg\:max-w-sm {
    max-width: 48rem
  }

  .lg\:max-w-md {
    max-width: 64rem
  }

  .lg\:max-w-lg {
    max-width: 80rem
  }

  .lg\:max-w-xl {
    max-width: 96rem
  }

  .lg\:max-w-2xl {
    max-width: 112rem
  }

  .lg\:max-w-3xl {
    max-width: 128rem
  }

  .lg\:max-w-4xl {
    max-width: 144rem
  }

  .lg\:max-w-5xl {
    max-width: 160rem
  }

  .lg\:max-w-px {
    max-width: 1px
  }

  .lg\:max-w-0\.5 {
    max-width: 0.05rem
  }

  .lg\:max-w-1\.5 {
    max-width: 0.15rem
  }

  .lg\:max-w-2\.5 {
    max-width: 0.25rem
  }

  .lg\:max-w-3\.5 {
    max-width: 0.35rem
  }

  .lg\:max-w-full {
    max-width: 100%
  }

  .lg\:max-w-min {
    max-width: min-content
  }

  .lg\:max-w-max {
    max-width: max-content
  }

  .lg\:max-w-prose {
    max-width: 65ch
  }

  .lg\:max-w-screen-sm {
    max-width: 600px
  }

  .lg\:max-w-screen-md {
    max-width: 960px
  }

  .lg\:max-w-screen-lg {
    max-width: 1280px
  }

  .lg\:max-w-screen-xl {
    max-width: 1920px
  }

  .lg\:min-h-0 {
    min-height: 0
  }

  .lg\:min-h-1 {
    min-height: 0.1rem
  }

  .lg\:min-h-2 {
    min-height: 0.2rem
  }

  .lg\:min-h-3 {
    min-height: 0.3rem
  }

  .lg\:min-h-4 {
    min-height: 0.4rem
  }

  .lg\:min-h-5 {
    min-height: 0.5rem
  }

  .lg\:min-h-6 {
    min-height: 0.6rem
  }

  .lg\:min-h-7 {
    min-height: 0.7rem
  }

  .lg\:min-h-8 {
    min-height: 0.8rem
  }

  .lg\:min-h-9 {
    min-height: 0.9rem
  }

  .lg\:min-h-10 {
    min-height: 1.0rem
  }

  .lg\:min-h-12 {
    min-height: 1.2rem
  }

  .lg\:min-h-14 {
    min-height: 1.4rem
  }

  .lg\:min-h-16 {
    min-height: 1.6rem
  }

  .lg\:min-h-20 {
    min-height: 2rem
  }

  .lg\:min-h-24 {
    min-height: 2.4rem
  }

  .lg\:min-h-28 {
    min-height: 2.8rem
  }

  .lg\:min-h-32 {
    min-height: 3.2rem
  }

  .lg\:min-h-36 {
    min-height: 3.6rem
  }

  .lg\:min-h-40 {
    min-height: 4rem
  }

  .lg\:min-h-44 {
    min-height: 4.4rem
  }

  .lg\:min-h-48 {
    min-height: 4.8rem
  }

  .lg\:min-h-52 {
    min-height: 5.2rem
  }

  .lg\:min-h-56 {
    min-height: 5.6rem
  }

  .lg\:min-h-60 {
    min-height: 6rem
  }

  .lg\:min-h-64 {
    min-height: 6.4rem
  }

  .lg\:min-h-68 {
    min-height: 6.8rem
  }

  .lg\:min-h-72 {
    min-height: 7.2rem
  }

  .lg\:min-h-76 {
    min-height: 7.6rem
  }

  .lg\:min-h-80 {
    min-height: 8rem
  }

  .lg\:min-h-84 {
    min-height: 8.4rem
  }

  .lg\:min-h-88 {
    min-height: 8.8rem
  }

  .lg\:min-h-92 {
    min-height: 9.2rem
  }

  .lg\:min-h-96 {
    min-height: 9.6rem
  }

  .lg\:min-h-128 {
    min-height: 12.8rem
  }

  .lg\:min-h-136 {
    min-height: 13.6rem
  }

  .lg\:min-h-160 {
    min-height: 16rem
  }

  .lg\:min-h-192 {
    min-height: 19.2rem
  }

  .lg\:min-h-200 {
    min-height: 20rem
  }

  .lg\:min-h-208 {
    min-height: 20.8rem
  }

  .lg\:min-h-216 {
    min-height: 21.6rem
  }

  .lg\:min-h-224 {
    min-height: 22.4rem
  }

  .lg\:min-h-256 {
    min-height: 25.6rem
  }

  .lg\:min-h-288 {
    min-height: 28.8rem
  }

  .lg\:min-h-320 {
    min-height: 32rem
  }

  .lg\:min-h-360 {
    min-height: 36rem
  }

  .lg\:min-h-384 {
    min-height: 38.4rem
  }

  .lg\:min-h-400 {
    min-height: 40rem
  }

  .lg\:min-h-512 {
    min-height: 51.2rem
  }

  .lg\:min-h-640 {
    min-height: 64rem
  }

  .lg\:min-h-auto {
    min-height: auto
  }

  .lg\:min-h-xs {
    min-height: 32rem
  }

  .lg\:min-h-sm {
    min-height: 48rem
  }

  .lg\:min-h-md {
    min-height: 64rem
  }

  .lg\:min-h-lg {
    min-height: 80rem
  }

  .lg\:min-h-xl {
    min-height: 96rem
  }

  .lg\:min-h-2xl {
    min-height: 112rem
  }

  .lg\:min-h-3xl {
    min-height: 128rem
  }

  .lg\:min-h-4xl {
    min-height: 144rem
  }

  .lg\:min-h-5xl {
    min-height: 160rem
  }

  .lg\:min-h-px {
    min-height: 1px
  }

  .lg\:min-h-0\.5 {
    min-height: 0.05rem
  }

  .lg\:min-h-1\.5 {
    min-height: 0.15rem
  }

  .lg\:min-h-2\.5 {
    min-height: 0.25rem
  }

  .lg\:min-h-3\.5 {
    min-height: 0.35rem
  }

  .lg\:min-h-full {
    min-height: 100%
  }

  .lg\:min-h-screen {
    min-height: 100vh
  }

  .lg\:min-w-0 {
    min-width: 0
  }

  .lg\:min-w-1 {
    min-width: 0.1rem
  }

  .lg\:min-w-2 {
    min-width: 0.2rem
  }

  .lg\:min-w-3 {
    min-width: 0.3rem
  }

  .lg\:min-w-4 {
    min-width: 0.4rem
  }

  .lg\:min-w-5 {
    min-width: 0.5rem
  }

  .lg\:min-w-6 {
    min-width: 0.6rem
  }

  .lg\:min-w-7 {
    min-width: 0.7rem
  }

  .lg\:min-w-8 {
    min-width: 0.8rem
  }

  .lg\:min-w-9 {
    min-width: 0.9rem
  }

  .lg\:min-w-10 {
    min-width: 1.0rem
  }

  .lg\:min-w-12 {
    min-width: 1.2rem
  }

  .lg\:min-w-14 {
    min-width: 1.4rem
  }

  .lg\:min-w-16 {
    min-width: 1.6rem
  }

  .lg\:min-w-20 {
    min-width: 2rem
  }

  .lg\:min-w-24 {
    min-width: 2.4rem
  }

  .lg\:min-w-28 {
    min-width: 2.8rem
  }

  .lg\:min-w-32 {
    min-width: 3.2rem
  }

  .lg\:min-w-36 {
    min-width: 3.6rem
  }

  .lg\:min-w-40 {
    min-width: 4rem
  }

  .lg\:min-w-44 {
    min-width: 4.4rem
  }

  .lg\:min-w-48 {
    min-width: 4.8rem
  }

  .lg\:min-w-52 {
    min-width: 5.2rem
  }

  .lg\:min-w-56 {
    min-width: 5.6rem
  }

  .lg\:min-w-60 {
    min-width: 6rem
  }

  .lg\:min-w-64 {
    min-width: 6.4rem
  }

  .lg\:min-w-68 {
    min-width: 6.8rem
  }

  .lg\:min-w-72 {
    min-width: 7.2rem
  }

  .lg\:min-w-76 {
    min-width: 7.6rem
  }

  .lg\:min-w-80 {
    min-width: 8rem
  }

  .lg\:min-w-84 {
    min-width: 8.4rem
  }

  .lg\:min-w-88 {
    min-width: 8.8rem
  }

  .lg\:min-w-92 {
    min-width: 9.2rem
  }

  .lg\:min-w-96 {
    min-width: 9.6rem
  }

  .lg\:min-w-128 {
    min-width: 12.8rem
  }

  .lg\:min-w-136 {
    min-width: 13.6rem
  }

  .lg\:min-w-160 {
    min-width: 16rem
  }

  .lg\:min-w-192 {
    min-width: 19.2rem
  }

  .lg\:min-w-200 {
    min-width: 20rem
  }

  .lg\:min-w-208 {
    min-width: 20.8rem
  }

  .lg\:min-w-216 {
    min-width: 21.6rem
  }

  .lg\:min-w-224 {
    min-width: 22.4rem
  }

  .lg\:min-w-256 {
    min-width: 25.6rem
  }

  .lg\:min-w-288 {
    min-width: 28.8rem
  }

  .lg\:min-w-320 {
    min-width: 32rem
  }

  .lg\:min-w-360 {
    min-width: 36rem
  }

  .lg\:min-w-384 {
    min-width: 38.4rem
  }

  .lg\:min-w-400 {
    min-width: 40rem
  }

  .lg\:min-w-512 {
    min-width: 51.2rem
  }

  .lg\:min-w-640 {
    min-width: 64rem
  }

  .lg\:min-w-xs {
    min-width: 32rem
  }

  .lg\:min-w-sm {
    min-width: 48rem
  }

  .lg\:min-w-md {
    min-width: 64rem
  }

  .lg\:min-w-lg {
    min-width: 80rem
  }

  .lg\:min-w-xl {
    min-width: 96rem
  }

  .lg\:min-w-2xl {
    min-width: 112rem
  }

  .lg\:min-w-3xl {
    min-width: 128rem
  }

  .lg\:min-w-4xl {
    min-width: 144rem
  }

  .lg\:min-w-5xl {
    min-width: 160rem
  }

  .lg\:min-w-px {
    min-width: 1px
  }

  .lg\:min-w-0\.5 {
    min-width: 0.05rem
  }

  .lg\:min-w-1\.5 {
    min-width: 0.15rem
  }

  .lg\:min-w-2\.5 {
    min-width: 0.25rem
  }

  .lg\:min-w-3\.5 {
    min-width: 0.35rem
  }

  .lg\:min-w-full {
    min-width: 100%
  }

  .lg\:min-w-min {
    min-width: min-content
  }

  .lg\:min-w-max {
    min-width: max-content
  }

  .lg\:min-w-screen {
    min-width: 100vw
  }

  .lg\:object-contain {
    object-fit: contain
  }

  .lg\:object-cover {
    object-fit: cover
  }

  .lg\:object-fill {
    object-fit: fill
  }

  .lg\:object-none {
    object-fit: none
  }

  .lg\:object-scale-down {
    object-fit: scale-down
  }

  .lg\:object-bottom {
    object-position: bottom
  }

  .lg\:object-center {
    object-position: center
  }

  .lg\:object-left {
    object-position: left
  }

  .lg\:object-left-bottom {
    object-position: left bottom
  }

  .lg\:object-left-top {
    object-position: left top
  }

  .lg\:object-right {
    object-position: right
  }

  .lg\:object-right-bottom {
    object-position: right bottom
  }

  .lg\:object-right-top {
    object-position: right top
  }

  .lg\:object-top {
    object-position: top
  }

  .lg\:opacity-0 {
    opacity: 0
  }

  .lg\:opacity-5 {
    opacity: 0.05
  }

  .lg\:opacity-10 {
    opacity: 0.1
  }

  .lg\:opacity-20 {
    opacity: 0.2
  }

  .lg\:opacity-25 {
    opacity: 0.25
  }

  .lg\:opacity-30 {
    opacity: 0.3
  }

  .lg\:opacity-40 {
    opacity: 0.4
  }

  .lg\:opacity-50 {
    opacity: 0.5
  }

  .lg\:opacity-60 {
    opacity: 0.6
  }

  .lg\:opacity-70 {
    opacity: 0.7
  }

  .lg\:opacity-75 {
    opacity: 0.75
  }

  .lg\:opacity-80 {
    opacity: 0.8
  }

  .lg\:opacity-90 {
    opacity: 0.9
  }

  .lg\:opacity-95 {
    opacity: 0.95
  }

  .lg\:opacity-100 {
    opacity: 1
  }

  .group:hover .lg\:group-hover\:opacity-0 {
    opacity: 0
  }

  .group:hover .lg\:group-hover\:opacity-5 {
    opacity: 0.05
  }

  .group:hover .lg\:group-hover\:opacity-10 {
    opacity: 0.1
  }

  .group:hover .lg\:group-hover\:opacity-20 {
    opacity: 0.2
  }

  .group:hover .lg\:group-hover\:opacity-25 {
    opacity: 0.25
  }

  .group:hover .lg\:group-hover\:opacity-30 {
    opacity: 0.3
  }

  .group:hover .lg\:group-hover\:opacity-40 {
    opacity: 0.4
  }

  .group:hover .lg\:group-hover\:opacity-50 {
    opacity: 0.5
  }

  .group:hover .lg\:group-hover\:opacity-60 {
    opacity: 0.6
  }

  .group:hover .lg\:group-hover\:opacity-70 {
    opacity: 0.7
  }

  .group:hover .lg\:group-hover\:opacity-75 {
    opacity: 0.75
  }

  .group:hover .lg\:group-hover\:opacity-80 {
    opacity: 0.8
  }

  .group:hover .lg\:group-hover\:opacity-90 {
    opacity: 0.9
  }

  .group:hover .lg\:group-hover\:opacity-95 {
    opacity: 0.95
  }

  .group:hover .lg\:group-hover\:opacity-100 {
    opacity: 1
  }

  .lg\:focus-within\:opacity-0:focus-within {
    opacity: 0
  }

  .lg\:focus-within\:opacity-5:focus-within {
    opacity: 0.05
  }

  .lg\:focus-within\:opacity-10:focus-within {
    opacity: 0.1
  }

  .lg\:focus-within\:opacity-20:focus-within {
    opacity: 0.2
  }

  .lg\:focus-within\:opacity-25:focus-within {
    opacity: 0.25
  }

  .lg\:focus-within\:opacity-30:focus-within {
    opacity: 0.3
  }

  .lg\:focus-within\:opacity-40:focus-within {
    opacity: 0.4
  }

  .lg\:focus-within\:opacity-50:focus-within {
    opacity: 0.5
  }

  .lg\:focus-within\:opacity-60:focus-within {
    opacity: 0.6
  }

  .lg\:focus-within\:opacity-70:focus-within {
    opacity: 0.7
  }

  .lg\:focus-within\:opacity-75:focus-within {
    opacity: 0.75
  }

  .lg\:focus-within\:opacity-80:focus-within {
    opacity: 0.8
  }

  .lg\:focus-within\:opacity-90:focus-within {
    opacity: 0.9
  }

  .lg\:focus-within\:opacity-95:focus-within {
    opacity: 0.95
  }

  .lg\:focus-within\:opacity-100:focus-within {
    opacity: 1
  }

  .lg\:hover\:opacity-0:hover {
    opacity: 0
  }

  .lg\:hover\:opacity-5:hover {
    opacity: 0.05
  }

  .lg\:hover\:opacity-10:hover {
    opacity: 0.1
  }

  .lg\:hover\:opacity-20:hover {
    opacity: 0.2
  }

  .lg\:hover\:opacity-25:hover {
    opacity: 0.25
  }

  .lg\:hover\:opacity-30:hover {
    opacity: 0.3
  }

  .lg\:hover\:opacity-40:hover {
    opacity: 0.4
  }

  .lg\:hover\:opacity-50:hover {
    opacity: 0.5
  }

  .lg\:hover\:opacity-60:hover {
    opacity: 0.6
  }

  .lg\:hover\:opacity-70:hover {
    opacity: 0.7
  }

  .lg\:hover\:opacity-75:hover {
    opacity: 0.75
  }

  .lg\:hover\:opacity-80:hover {
    opacity: 0.8
  }

  .lg\:hover\:opacity-90:hover {
    opacity: 0.9
  }

  .lg\:hover\:opacity-95:hover {
    opacity: 0.95
  }

  .lg\:hover\:opacity-100:hover {
    opacity: 1
  }

  .lg\:focus\:opacity-0:focus {
    opacity: 0
  }

  .lg\:focus\:opacity-5:focus {
    opacity: 0.05
  }

  .lg\:focus\:opacity-10:focus {
    opacity: 0.1
  }

  .lg\:focus\:opacity-20:focus {
    opacity: 0.2
  }

  .lg\:focus\:opacity-25:focus {
    opacity: 0.25
  }

  .lg\:focus\:opacity-30:focus {
    opacity: 0.3
  }

  .lg\:focus\:opacity-40:focus {
    opacity: 0.4
  }

  .lg\:focus\:opacity-50:focus {
    opacity: 0.5
  }

  .lg\:focus\:opacity-60:focus {
    opacity: 0.6
  }

  .lg\:focus\:opacity-70:focus {
    opacity: 0.7
  }

  .lg\:focus\:opacity-75:focus {
    opacity: 0.75
  }

  .lg\:focus\:opacity-80:focus {
    opacity: 0.8
  }

  .lg\:focus\:opacity-90:focus {
    opacity: 0.9
  }

  .lg\:focus\:opacity-95:focus {
    opacity: 0.95
  }

  .lg\:focus\:opacity-100:focus {
    opacity: 1
  }

  .lg\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .lg\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .lg\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .lg\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .lg\:focus-within\:outline-white:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .lg\:focus-within\:outline-black:focus-within {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .lg\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .lg\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .lg\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .lg\:overflow-auto {
    overflow: auto
  }

  .lg\:overflow-hidden {
    overflow: hidden
  }

  .lg\:overflow-visible {
    overflow: visible
  }

  .lg\:overflow-scroll {
    overflow: scroll
  }

  .lg\:overflow-x-auto {
    overflow-x: auto
  }

  .lg\:overflow-y-auto {
    overflow-y: auto
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden
  }

  .lg\:overflow-x-visible {
    overflow-x: visible
  }

  .lg\:overflow-y-visible {
    overflow-y: visible
  }

  .lg\:overflow-x-scroll {
    overflow-x: scroll
  }

  .lg\:overflow-y-scroll {
    overflow-y: scroll
  }

  .lg\:overscroll-auto {
    overscroll-behavior: auto
  }

  .lg\:overscroll-contain {
    overscroll-behavior: contain
  }

  .lg\:overscroll-none {
    overscroll-behavior: none
  }

  .lg\:overscroll-y-auto {
    overscroll-behavior-y: auto
  }

  .lg\:overscroll-y-contain {
    overscroll-behavior-y: contain
  }

  .lg\:overscroll-y-none {
    overscroll-behavior-y: none
  }

  .lg\:overscroll-x-auto {
    overscroll-behavior-x: auto
  }

  .lg\:overscroll-x-contain {
    overscroll-behavior-x: contain
  }

  .lg\:overscroll-x-none {
    overscroll-behavior-x: none
  }

  .lg\:p-0 {
    padding: 0
  }

  .lg\:p-1 {
    padding: 0.1rem
  }

  .lg\:p-2 {
    padding: 0.2rem
  }

  .lg\:p-3 {
    padding: 0.3rem
  }

  .lg\:p-4 {
    padding: 0.4rem
  }

  .lg\:p-5 {
    padding: 0.5rem
  }

  .lg\:p-6 {
    padding: 0.6rem
  }

  .lg\:p-7 {
    padding: 0.7rem
  }

  .lg\:p-8 {
    padding: 0.8rem
  }

  .lg\:p-9 {
    padding: 0.9rem
  }

  .lg\:p-10 {
    padding: 1.0rem
  }

  .lg\:p-12 {
    padding: 1.2rem
  }

  .lg\:p-14 {
    padding: 1.4rem
  }

  .lg\:p-16 {
    padding: 1.6rem
  }

  .lg\:p-20 {
    padding: 2rem
  }

  .lg\:p-24 {
    padding: 2.4rem
  }

  .lg\:p-28 {
    padding: 2.8rem
  }

  .lg\:p-32 {
    padding: 3.2rem
  }

  .lg\:p-36 {
    padding: 3.6rem
  }

  .lg\:p-40 {
    padding: 4rem
  }

  .lg\:p-44 {
    padding: 4.4rem
  }

  .lg\:p-48 {
    padding: 4.8rem
  }

  .lg\:p-52 {
    padding: 5.2rem
  }

  .lg\:p-56 {
    padding: 5.6rem
  }

  .lg\:p-60 {
    padding: 6rem
  }

  .lg\:p-64 {
    padding: 6.4rem
  }

  .lg\:p-68 {
    padding: 6.8rem
  }

  .lg\:p-72 {
    padding: 7.2rem
  }

  .lg\:p-76 {
    padding: 7.6rem
  }

  .lg\:p-80 {
    padding: 8rem
  }

  .lg\:p-84 {
    padding: 8.4rem
  }

  .lg\:p-88 {
    padding: 8.8rem
  }

  .lg\:p-92 {
    padding: 9.2rem
  }

  .lg\:p-96 {
    padding: 9.6rem
  }

  .lg\:p-128 {
    padding: 12.8rem
  }

  .lg\:p-136 {
    padding: 13.6rem
  }

  .lg\:p-160 {
    padding: 16rem
  }

  .lg\:p-192 {
    padding: 19.2rem
  }

  .lg\:p-200 {
    padding: 20rem
  }

  .lg\:p-208 {
    padding: 20.8rem
  }

  .lg\:p-216 {
    padding: 21.6rem
  }

  .lg\:p-224 {
    padding: 22.4rem
  }

  .lg\:p-256 {
    padding: 25.6rem
  }

  .lg\:p-288 {
    padding: 28.8rem
  }

  .lg\:p-320 {
    padding: 32rem
  }

  .lg\:p-360 {
    padding: 36rem
  }

  .lg\:p-384 {
    padding: 38.4rem
  }

  .lg\:p-400 {
    padding: 40rem
  }

  .lg\:p-512 {
    padding: 51.2rem
  }

  .lg\:p-640 {
    padding: 64rem
  }

  .lg\:p-xs {
    padding: 32rem
  }

  .lg\:p-sm {
    padding: 48rem
  }

  .lg\:p-md {
    padding: 64rem
  }

  .lg\:p-lg {
    padding: 80rem
  }

  .lg\:p-xl {
    padding: 96rem
  }

  .lg\:p-2xl {
    padding: 112rem
  }

  .lg\:p-3xl {
    padding: 128rem
  }

  .lg\:p-4xl {
    padding: 144rem
  }

  .lg\:p-5xl {
    padding: 160rem
  }

  .lg\:p-px {
    padding: 1px
  }

  .lg\:p-0\.5 {
    padding: 0.05rem
  }

  .lg\:p-1\.5 {
    padding: 0.15rem
  }

  .lg\:p-2\.5 {
    padding: 0.25rem
  }

  .lg\:p-3\.5 {
    padding: 0.35rem
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .lg\:py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem
  }

  .lg\:px-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem
  }

  .lg\:py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem
  }

  .lg\:px-2 {
    padding-left: 0.2rem;
    padding-right: 0.2rem
  }

  .lg\:py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem
  }

  .lg\:px-3 {
    padding-left: 0.3rem;
    padding-right: 0.3rem
  }

  .lg\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  .lg\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  .lg\:py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .lg\:px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .lg\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  .lg\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  .lg\:py-7 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem
  }

  .lg\:px-7 {
    padding-left: 0.7rem;
    padding-right: 0.7rem
  }

  .lg\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  .lg\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  .lg\:py-9 {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem
  }

  .lg\:px-9 {
    padding-left: 0.9rem;
    padding-right: 0.9rem
  }

  .lg\:py-10 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
  }

  .lg\:px-10 {
    padding-left: 1.0rem;
    padding-right: 1.0rem
  }

  .lg\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  .lg\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .lg\:py-14 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem
  }

  .lg\:px-14 {
    padding-left: 1.4rem;
    padding-right: 1.4rem
  }

  .lg\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  .lg\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  .lg\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .lg\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .lg\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  .lg\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .lg\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  .lg\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  .lg\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  .lg\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  .lg\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  .lg\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  .lg\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .lg\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .lg\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  .lg\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  .lg\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  .lg\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  .lg\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  .lg\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  .lg\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  .lg\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  .lg\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .lg\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .lg\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  .lg\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  .lg\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  .lg\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  .lg\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  .lg\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  .lg\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  .lg\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  .lg\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .lg\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .lg\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  .lg\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  .lg\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  .lg\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  .lg\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  .lg\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  .lg\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  .lg\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  .lg\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  .lg\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  .lg\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  .lg\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  .lg\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .lg\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .lg\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  .lg\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  .lg\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  .lg\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  .lg\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  .lg\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  .lg\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  .lg\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  .lg\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  .lg\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  .lg\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  .lg\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  .lg\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  .lg\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  .lg\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  .lg\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  .lg\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  .lg\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  .lg\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  .lg\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  .lg\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  .lg\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  .lg\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  .lg\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  .lg\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  .lg\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  .lg\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  .lg\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  .lg\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  .lg\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  .lg\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  .lg\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  .lg\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  .lg\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  .lg\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  .lg\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  .lg\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  .lg\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  .lg\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  .lg\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  .lg\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  .lg\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  .lg\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  .lg\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  .lg\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .lg\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .lg\:py-0\.5 {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem
  }

  .lg\:px-0\.5 {
    padding-left: 0.05rem;
    padding-right: 0.05rem
  }

  .lg\:py-1\.5 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem
  }

  .lg\:px-1\.5 {
    padding-left: 0.15rem;
    padding-right: 0.15rem
  }

  .lg\:py-2\.5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .lg\:px-2\.5 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .lg\:py-3\.5 {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem
  }

  .lg\:px-3\.5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem
  }

  .lg\:pt-0 {
    padding-top: 0
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  .lg\:pb-0 {
    padding-bottom: 0
  }

  .lg\:pl-0 {
    padding-left: 0
  }

  .lg\:pt-1 {
    padding-top: 0.1rem
  }

  .lg\:pr-1 {
    padding-right: 0.1rem
  }

  .lg\:pb-1 {
    padding-bottom: 0.1rem
  }

  .lg\:pl-1 {
    padding-left: 0.1rem
  }

  .lg\:pt-2 {
    padding-top: 0.2rem
  }

  .lg\:pr-2 {
    padding-right: 0.2rem
  }

  .lg\:pb-2 {
    padding-bottom: 0.2rem
  }

  .lg\:pl-2 {
    padding-left: 0.2rem
  }

  .lg\:pt-3 {
    padding-top: 0.3rem
  }

  .lg\:pr-3 {
    padding-right: 0.3rem
  }

  .lg\:pb-3 {
    padding-bottom: 0.3rem
  }

  .lg\:pl-3 {
    padding-left: 0.3rem
  }

  .lg\:pt-4 {
    padding-top: 0.4rem
  }

  .lg\:pr-4 {
    padding-right: 0.4rem
  }

  .lg\:pb-4 {
    padding-bottom: 0.4rem
  }

  .lg\:pl-4 {
    padding-left: 0.4rem
  }

  .lg\:pt-5 {
    padding-top: 0.5rem
  }

  .lg\:pr-5 {
    padding-right: 0.5rem
  }

  .lg\:pb-5 {
    padding-bottom: 0.5rem
  }

  .lg\:pl-5 {
    padding-left: 0.5rem
  }

  .lg\:pt-6 {
    padding-top: 0.6rem
  }

  .lg\:pr-6 {
    padding-right: 0.6rem
  }

  .lg\:pb-6 {
    padding-bottom: 0.6rem
  }

  .lg\:pl-6 {
    padding-left: 0.6rem
  }

  .lg\:pt-7 {
    padding-top: 0.7rem
  }

  .lg\:pr-7 {
    padding-right: 0.7rem
  }

  .lg\:pb-7 {
    padding-bottom: 0.7rem
  }

  .lg\:pl-7 {
    padding-left: 0.7rem
  }

  .lg\:pt-8 {
    padding-top: 0.8rem
  }

  .lg\:pr-8 {
    padding-right: 0.8rem
  }

  .lg\:pb-8 {
    padding-bottom: 0.8rem
  }

  .lg\:pl-8 {
    padding-left: 0.8rem
  }

  .lg\:pt-9 {
    padding-top: 0.9rem
  }

  .lg\:pr-9 {
    padding-right: 0.9rem
  }

  .lg\:pb-9 {
    padding-bottom: 0.9rem
  }

  .lg\:pl-9 {
    padding-left: 0.9rem
  }

  .lg\:pt-10 {
    padding-top: 1.0rem
  }

  .lg\:pr-10 {
    padding-right: 1.0rem
  }

  .lg\:pb-10 {
    padding-bottom: 1.0rem
  }

  .lg\:pl-10 {
    padding-left: 1.0rem
  }

  .lg\:pt-12 {
    padding-top: 1.2rem
  }

  .lg\:pr-12 {
    padding-right: 1.2rem
  }

  .lg\:pb-12 {
    padding-bottom: 1.2rem
  }

  .lg\:pl-12 {
    padding-left: 1.2rem
  }

  .lg\:pt-14 {
    padding-top: 1.4rem
  }

  .lg\:pr-14 {
    padding-right: 1.4rem
  }

  .lg\:pb-14 {
    padding-bottom: 1.4rem
  }

  .lg\:pl-14 {
    padding-left: 1.4rem
  }

  .lg\:pt-16 {
    padding-top: 1.6rem
  }

  .lg\:pr-16 {
    padding-right: 1.6rem
  }

  .lg\:pb-16 {
    padding-bottom: 1.6rem
  }

  .lg\:pl-16 {
    padding-left: 1.6rem
  }

  .lg\:pt-20 {
    padding-top: 2rem
  }

  .lg\:pr-20 {
    padding-right: 2rem
  }

  .lg\:pb-20 {
    padding-bottom: 2rem
  }

  .lg\:pl-20 {
    padding-left: 2rem
  }

  .lg\:pt-24 {
    padding-top: 2.4rem
  }

  .lg\:pr-24 {
    padding-right: 2.4rem
  }

  .lg\:pb-24 {
    padding-bottom: 2.4rem
  }

  .lg\:pl-24 {
    padding-left: 2.4rem
  }

  .lg\:pt-28 {
    padding-top: 2.8rem
  }

  .lg\:pr-28 {
    padding-right: 2.8rem
  }

  .lg\:pb-28 {
    padding-bottom: 2.8rem
  }

  .lg\:pl-28 {
    padding-left: 2.8rem
  }

  .lg\:pt-32 {
    padding-top: 3.2rem
  }

  .lg\:pr-32 {
    padding-right: 3.2rem
  }

  .lg\:pb-32 {
    padding-bottom: 3.2rem
  }

  .lg\:pl-32 {
    padding-left: 3.2rem
  }

  .lg\:pt-36 {
    padding-top: 3.6rem
  }

  .lg\:pr-36 {
    padding-right: 3.6rem
  }

  .lg\:pb-36 {
    padding-bottom: 3.6rem
  }

  .lg\:pl-36 {
    padding-left: 3.6rem
  }

  .lg\:pt-40 {
    padding-top: 4rem
  }

  .lg\:pr-40 {
    padding-right: 4rem
  }

  .lg\:pb-40 {
    padding-bottom: 4rem
  }

  .lg\:pl-40 {
    padding-left: 4rem
  }

  .lg\:pt-44 {
    padding-top: 4.4rem
  }

  .lg\:pr-44 {
    padding-right: 4.4rem
  }

  .lg\:pb-44 {
    padding-bottom: 4.4rem
  }

  .lg\:pl-44 {
    padding-left: 4.4rem
  }

  .lg\:pt-48 {
    padding-top: 4.8rem
  }

  .lg\:pr-48 {
    padding-right: 4.8rem
  }

  .lg\:pb-48 {
    padding-bottom: 4.8rem
  }

  .lg\:pl-48 {
    padding-left: 4.8rem
  }

  .lg\:pt-52 {
    padding-top: 5.2rem
  }

  .lg\:pr-52 {
    padding-right: 5.2rem
  }

  .lg\:pb-52 {
    padding-bottom: 5.2rem
  }

  .lg\:pl-52 {
    padding-left: 5.2rem
  }

  .lg\:pt-56 {
    padding-top: 5.6rem
  }

  .lg\:pr-56 {
    padding-right: 5.6rem
  }

  .lg\:pb-56 {
    padding-bottom: 5.6rem
  }

  .lg\:pl-56 {
    padding-left: 5.6rem
  }

  .lg\:pt-60 {
    padding-top: 6rem
  }

  .lg\:pr-60 {
    padding-right: 6rem
  }

  .lg\:pb-60 {
    padding-bottom: 6rem
  }

  .lg\:pl-60 {
    padding-left: 6rem
  }

  .lg\:pt-64 {
    padding-top: 6.4rem
  }

  .lg\:pr-64 {
    padding-right: 6.4rem
  }

  .lg\:pb-64 {
    padding-bottom: 6.4rem
  }

  .lg\:pl-64 {
    padding-left: 6.4rem
  }

  .lg\:pt-68 {
    padding-top: 6.8rem
  }

  .lg\:pr-68 {
    padding-right: 6.8rem
  }

  .lg\:pb-68 {
    padding-bottom: 6.8rem
  }

  .lg\:pl-68 {
    padding-left: 6.8rem
  }

  .lg\:pt-72 {
    padding-top: 7.2rem
  }

  .lg\:pr-72 {
    padding-right: 7.2rem
  }

  .lg\:pb-72 {
    padding-bottom: 7.2rem
  }

  .lg\:pl-72 {
    padding-left: 7.2rem
  }

  .lg\:pt-76 {
    padding-top: 7.6rem
  }

  .lg\:pr-76 {
    padding-right: 7.6rem
  }

  .lg\:pb-76 {
    padding-bottom: 7.6rem
  }

  .lg\:pl-76 {
    padding-left: 7.6rem
  }

  .lg\:pt-80 {
    padding-top: 8rem
  }

  .lg\:pr-80 {
    padding-right: 8rem
  }

  .lg\:pb-80 {
    padding-bottom: 8rem
  }

  .lg\:pl-80 {
    padding-left: 8rem
  }

  .lg\:pt-84 {
    padding-top: 8.4rem
  }

  .lg\:pr-84 {
    padding-right: 8.4rem
  }

  .lg\:pb-84 {
    padding-bottom: 8.4rem
  }

  .lg\:pl-84 {
    padding-left: 8.4rem
  }

  .lg\:pt-88 {
    padding-top: 8.8rem
  }

  .lg\:pr-88 {
    padding-right: 8.8rem
  }

  .lg\:pb-88 {
    padding-bottom: 8.8rem
  }

  .lg\:pl-88 {
    padding-left: 8.8rem
  }

  .lg\:pt-92 {
    padding-top: 9.2rem
  }

  .lg\:pr-92 {
    padding-right: 9.2rem
  }

  .lg\:pb-92 {
    padding-bottom: 9.2rem
  }

  .lg\:pl-92 {
    padding-left: 9.2rem
  }

  .lg\:pt-96 {
    padding-top: 9.6rem
  }

  .lg\:pr-96 {
    padding-right: 9.6rem
  }

  .lg\:pb-96 {
    padding-bottom: 9.6rem
  }

  .lg\:pl-96 {
    padding-left: 9.6rem
  }

  .lg\:pt-128 {
    padding-top: 12.8rem
  }

  .lg\:pr-128 {
    padding-right: 12.8rem
  }

  .lg\:pb-128 {
    padding-bottom: 12.8rem
  }

  .lg\:pl-128 {
    padding-left: 12.8rem
  }

  .lg\:pt-136 {
    padding-top: 13.6rem
  }

  .lg\:pr-136 {
    padding-right: 13.6rem
  }

  .lg\:pb-136 {
    padding-bottom: 13.6rem
  }

  .lg\:pl-136 {
    padding-left: 13.6rem
  }

  .lg\:pt-160 {
    padding-top: 16rem
  }

  .lg\:pr-160 {
    padding-right: 16rem
  }

  .lg\:pb-160 {
    padding-bottom: 16rem
  }

  .lg\:pl-160 {
    padding-left: 16rem
  }

  .lg\:pt-192 {
    padding-top: 19.2rem
  }

  .lg\:pr-192 {
    padding-right: 19.2rem
  }

  .lg\:pb-192 {
    padding-bottom: 19.2rem
  }

  .lg\:pl-192 {
    padding-left: 19.2rem
  }

  .lg\:pt-200 {
    padding-top: 20rem
  }

  .lg\:pr-200 {
    padding-right: 20rem
  }

  .lg\:pb-200 {
    padding-bottom: 20rem
  }

  .lg\:pl-200 {
    padding-left: 20rem
  }

  .lg\:pt-208 {
    padding-top: 20.8rem
  }

  .lg\:pr-208 {
    padding-right: 20.8rem
  }

  .lg\:pb-208 {
    padding-bottom: 20.8rem
  }

  .lg\:pl-208 {
    padding-left: 20.8rem
  }

  .lg\:pt-216 {
    padding-top: 21.6rem
  }

  .lg\:pr-216 {
    padding-right: 21.6rem
  }

  .lg\:pb-216 {
    padding-bottom: 21.6rem
  }

  .lg\:pl-216 {
    padding-left: 21.6rem
  }

  .lg\:pt-224 {
    padding-top: 22.4rem
  }

  .lg\:pr-224 {
    padding-right: 22.4rem
  }

  .lg\:pb-224 {
    padding-bottom: 22.4rem
  }

  .lg\:pl-224 {
    padding-left: 22.4rem
  }

  .lg\:pt-256 {
    padding-top: 25.6rem
  }

  .lg\:pr-256 {
    padding-right: 25.6rem
  }

  .lg\:pb-256 {
    padding-bottom: 25.6rem
  }

  .lg\:pl-256 {
    padding-left: 25.6rem
  }

  .lg\:pt-288 {
    padding-top: 28.8rem
  }

  .lg\:pr-288 {
    padding-right: 28.8rem
  }

  .lg\:pb-288 {
    padding-bottom: 28.8rem
  }

  .lg\:pl-288 {
    padding-left: 28.8rem
  }

  .lg\:pt-320 {
    padding-top: 32rem
  }

  .lg\:pr-320 {
    padding-right: 32rem
  }

  .lg\:pb-320 {
    padding-bottom: 32rem
  }

  .lg\:pl-320 {
    padding-left: 32rem
  }

  .lg\:pt-360 {
    padding-top: 36rem
  }

  .lg\:pr-360 {
    padding-right: 36rem
  }

  .lg\:pb-360 {
    padding-bottom: 36rem
  }

  .lg\:pl-360 {
    padding-left: 36rem
  }

  .lg\:pt-384 {
    padding-top: 38.4rem
  }

  .lg\:pr-384 {
    padding-right: 38.4rem
  }

  .lg\:pb-384 {
    padding-bottom: 38.4rem
  }

  .lg\:pl-384 {
    padding-left: 38.4rem
  }

  .lg\:pt-400 {
    padding-top: 40rem
  }

  .lg\:pr-400 {
    padding-right: 40rem
  }

  .lg\:pb-400 {
    padding-bottom: 40rem
  }

  .lg\:pl-400 {
    padding-left: 40rem
  }

  .lg\:pt-512 {
    padding-top: 51.2rem
  }

  .lg\:pr-512 {
    padding-right: 51.2rem
  }

  .lg\:pb-512 {
    padding-bottom: 51.2rem
  }

  .lg\:pl-512 {
    padding-left: 51.2rem
  }

  .lg\:pt-640 {
    padding-top: 64rem
  }

  .lg\:pr-640 {
    padding-right: 64rem
  }

  .lg\:pb-640 {
    padding-bottom: 64rem
  }

  .lg\:pl-640 {
    padding-left: 64rem
  }

  .lg\:pt-xs {
    padding-top: 32rem
  }

  .lg\:pr-xs {
    padding-right: 32rem
  }

  .lg\:pb-xs {
    padding-bottom: 32rem
  }

  .lg\:pl-xs {
    padding-left: 32rem
  }

  .lg\:pt-sm {
    padding-top: 48rem
  }

  .lg\:pr-sm {
    padding-right: 48rem
  }

  .lg\:pb-sm {
    padding-bottom: 48rem
  }

  .lg\:pl-sm {
    padding-left: 48rem
  }

  .lg\:pt-md {
    padding-top: 64rem
  }

  .lg\:pr-md {
    padding-right: 64rem
  }

  .lg\:pb-md {
    padding-bottom: 64rem
  }

  .lg\:pl-md {
    padding-left: 64rem
  }

  .lg\:pt-lg {
    padding-top: 80rem
  }

  .lg\:pr-lg {
    padding-right: 80rem
  }

  .lg\:pb-lg {
    padding-bottom: 80rem
  }

  .lg\:pl-lg {
    padding-left: 80rem
  }

  .lg\:pt-xl {
    padding-top: 96rem
  }

  .lg\:pr-xl {
    padding-right: 96rem
  }

  .lg\:pb-xl {
    padding-bottom: 96rem
  }

  .lg\:pl-xl {
    padding-left: 96rem
  }

  .lg\:pt-2xl {
    padding-top: 112rem
  }

  .lg\:pr-2xl {
    padding-right: 112rem
  }

  .lg\:pb-2xl {
    padding-bottom: 112rem
  }

  .lg\:pl-2xl {
    padding-left: 112rem
  }

  .lg\:pt-3xl {
    padding-top: 128rem
  }

  .lg\:pr-3xl {
    padding-right: 128rem
  }

  .lg\:pb-3xl {
    padding-bottom: 128rem
  }

  .lg\:pl-3xl {
    padding-left: 128rem
  }

  .lg\:pt-4xl {
    padding-top: 144rem
  }

  .lg\:pr-4xl {
    padding-right: 144rem
  }

  .lg\:pb-4xl {
    padding-bottom: 144rem
  }

  .lg\:pl-4xl {
    padding-left: 144rem
  }

  .lg\:pt-5xl {
    padding-top: 160rem
  }

  .lg\:pr-5xl {
    padding-right: 160rem
  }

  .lg\:pb-5xl {
    padding-bottom: 160rem
  }

  .lg\:pl-5xl {
    padding-left: 160rem
  }

  .lg\:pt-px {
    padding-top: 1px
  }

  .lg\:pr-px {
    padding-right: 1px
  }

  .lg\:pb-px {
    padding-bottom: 1px
  }

  .lg\:pl-px {
    padding-left: 1px
  }

  .lg\:pt-0\.5 {
    padding-top: 0.05rem
  }

  .lg\:pr-0\.5 {
    padding-right: 0.05rem
  }

  .lg\:pb-0\.5 {
    padding-bottom: 0.05rem
  }

  .lg\:pl-0\.5 {
    padding-left: 0.05rem
  }

  .lg\:pt-1\.5 {
    padding-top: 0.15rem
  }

  .lg\:pr-1\.5 {
    padding-right: 0.15rem
  }

  .lg\:pb-1\.5 {
    padding-bottom: 0.15rem
  }

  .lg\:pl-1\.5 {
    padding-left: 0.15rem
  }

  .lg\:pt-2\.5 {
    padding-top: 0.25rem
  }

  .lg\:pr-2\.5 {
    padding-right: 0.25rem
  }

  .lg\:pb-2\.5 {
    padding-bottom: 0.25rem
  }

  .lg\:pl-2\.5 {
    padding-left: 0.25rem
  }

  .lg\:pt-3\.5 {
    padding-top: 0.35rem
  }

  .lg\:pr-3\.5 {
    padding-right: 0.35rem
  }

  .lg\:pb-3\.5 {
    padding-bottom: 0.35rem
  }

  .lg\:pl-3\.5 {
    padding-left: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:p-0,[dir='ltr'].lg\:ltr\:p-0 {
    padding: 0
  }

  [dir='ltr'] .lg\:ltr\:p-1,[dir='ltr'].lg\:ltr\:p-1 {
    padding: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:p-2,[dir='ltr'].lg\:ltr\:p-2 {
    padding: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:p-3,[dir='ltr'].lg\:ltr\:p-3 {
    padding: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:p-4,[dir='ltr'].lg\:ltr\:p-4 {
    padding: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:p-5,[dir='ltr'].lg\:ltr\:p-5 {
    padding: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:p-6,[dir='ltr'].lg\:ltr\:p-6 {
    padding: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:p-7,[dir='ltr'].lg\:ltr\:p-7 {
    padding: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:p-8,[dir='ltr'].lg\:ltr\:p-8 {
    padding: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:p-9,[dir='ltr'].lg\:ltr\:p-9 {
    padding: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:p-10,[dir='ltr'].lg\:ltr\:p-10 {
    padding: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:p-12,[dir='ltr'].lg\:ltr\:p-12 {
    padding: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:p-14,[dir='ltr'].lg\:ltr\:p-14 {
    padding: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:p-16,[dir='ltr'].lg\:ltr\:p-16 {
    padding: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:p-20,[dir='ltr'].lg\:ltr\:p-20 {
    padding: 2rem
  }

  [dir='ltr'] .lg\:ltr\:p-24,[dir='ltr'].lg\:ltr\:p-24 {
    padding: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:p-28,[dir='ltr'].lg\:ltr\:p-28 {
    padding: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:p-32,[dir='ltr'].lg\:ltr\:p-32 {
    padding: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:p-36,[dir='ltr'].lg\:ltr\:p-36 {
    padding: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:p-40,[dir='ltr'].lg\:ltr\:p-40 {
    padding: 4rem
  }

  [dir='ltr'] .lg\:ltr\:p-44,[dir='ltr'].lg\:ltr\:p-44 {
    padding: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:p-48,[dir='ltr'].lg\:ltr\:p-48 {
    padding: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:p-52,[dir='ltr'].lg\:ltr\:p-52 {
    padding: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:p-56,[dir='ltr'].lg\:ltr\:p-56 {
    padding: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:p-60,[dir='ltr'].lg\:ltr\:p-60 {
    padding: 6rem
  }

  [dir='ltr'] .lg\:ltr\:p-64,[dir='ltr'].lg\:ltr\:p-64 {
    padding: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:p-68,[dir='ltr'].lg\:ltr\:p-68 {
    padding: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:p-72,[dir='ltr'].lg\:ltr\:p-72 {
    padding: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:p-76,[dir='ltr'].lg\:ltr\:p-76 {
    padding: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:p-80,[dir='ltr'].lg\:ltr\:p-80 {
    padding: 8rem
  }

  [dir='ltr'] .lg\:ltr\:p-84,[dir='ltr'].lg\:ltr\:p-84 {
    padding: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:p-88,[dir='ltr'].lg\:ltr\:p-88 {
    padding: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:p-92,[dir='ltr'].lg\:ltr\:p-92 {
    padding: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:p-96,[dir='ltr'].lg\:ltr\:p-96 {
    padding: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:p-128,[dir='ltr'].lg\:ltr\:p-128 {
    padding: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:p-136,[dir='ltr'].lg\:ltr\:p-136 {
    padding: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:p-160,[dir='ltr'].lg\:ltr\:p-160 {
    padding: 16rem
  }

  [dir='ltr'] .lg\:ltr\:p-192,[dir='ltr'].lg\:ltr\:p-192 {
    padding: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:p-200,[dir='ltr'].lg\:ltr\:p-200 {
    padding: 20rem
  }

  [dir='ltr'] .lg\:ltr\:p-208,[dir='ltr'].lg\:ltr\:p-208 {
    padding: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:p-216,[dir='ltr'].lg\:ltr\:p-216 {
    padding: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:p-224,[dir='ltr'].lg\:ltr\:p-224 {
    padding: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:p-256,[dir='ltr'].lg\:ltr\:p-256 {
    padding: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:p-288,[dir='ltr'].lg\:ltr\:p-288 {
    padding: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:p-320,[dir='ltr'].lg\:ltr\:p-320 {
    padding: 32rem
  }

  [dir='ltr'] .lg\:ltr\:p-360,[dir='ltr'].lg\:ltr\:p-360 {
    padding: 36rem
  }

  [dir='ltr'] .lg\:ltr\:p-384,[dir='ltr'].lg\:ltr\:p-384 {
    padding: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:p-400,[dir='ltr'].lg\:ltr\:p-400 {
    padding: 40rem
  }

  [dir='ltr'] .lg\:ltr\:p-512,[dir='ltr'].lg\:ltr\:p-512 {
    padding: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:p-640,[dir='ltr'].lg\:ltr\:p-640 {
    padding: 64rem
  }

  [dir='ltr'] .lg\:ltr\:p-xs,[dir='ltr'].lg\:ltr\:p-xs {
    padding: 32rem
  }

  [dir='ltr'] .lg\:ltr\:p-sm,[dir='ltr'].lg\:ltr\:p-sm {
    padding: 48rem
  }

  [dir='ltr'] .lg\:ltr\:p-md,[dir='ltr'].lg\:ltr\:p-md {
    padding: 64rem
  }

  [dir='ltr'] .lg\:ltr\:p-lg,[dir='ltr'].lg\:ltr\:p-lg {
    padding: 80rem
  }

  [dir='ltr'] .lg\:ltr\:p-xl,[dir='ltr'].lg\:ltr\:p-xl {
    padding: 96rem
  }

  [dir='ltr'] .lg\:ltr\:p-2xl,[dir='ltr'].lg\:ltr\:p-2xl {
    padding: 112rem
  }

  [dir='ltr'] .lg\:ltr\:p-3xl,[dir='ltr'].lg\:ltr\:p-3xl {
    padding: 128rem
  }

  [dir='ltr'] .lg\:ltr\:p-4xl,[dir='ltr'].lg\:ltr\:p-4xl {
    padding: 144rem
  }

  [dir='ltr'] .lg\:ltr\:p-5xl,[dir='ltr'].lg\:ltr\:p-5xl {
    padding: 160rem
  }

  [dir='ltr'] .lg\:ltr\:p-px,[dir='ltr'].lg\:ltr\:p-px {
    padding: 1px
  }

  [dir='ltr'] .lg\:ltr\:p-0\.5,[dir='ltr'].lg\:ltr\:p-0\.5 {
    padding: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:p-1\.5,[dir='ltr'].lg\:ltr\:p-1\.5 {
    padding: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:p-2\.5,[dir='ltr'].lg\:ltr\:p-2\.5 {
    padding: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:p-3\.5,[dir='ltr'].lg\:ltr\:p-3\.5 {
    padding: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:py-0,[dir='ltr'].lg\:ltr\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  [dir='ltr'] .lg\:ltr\:px-0,[dir='ltr'].lg\:ltr\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  [dir='ltr'] .lg\:ltr\:py-1,[dir='ltr'].lg\:ltr\:py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:px-1,[dir='ltr'].lg\:ltr\:px-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:py-2,[dir='ltr'].lg\:ltr\:py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:px-2,[dir='ltr'].lg\:ltr\:px-2 {
    padding-left: 0.2rem;
    padding-right: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:py-3,[dir='ltr'].lg\:ltr\:py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:px-3,[dir='ltr'].lg\:ltr\:px-3 {
    padding-left: 0.3rem;
    padding-right: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:py-4,[dir='ltr'].lg\:ltr\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:px-4,[dir='ltr'].lg\:ltr\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:py-5,[dir='ltr'].lg\:ltr\:py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:px-5,[dir='ltr'].lg\:ltr\:px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:py-6,[dir='ltr'].lg\:ltr\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:px-6,[dir='ltr'].lg\:ltr\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:py-7,[dir='ltr'].lg\:ltr\:py-7 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:px-7,[dir='ltr'].lg\:ltr\:px-7 {
    padding-left: 0.7rem;
    padding-right: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:py-8,[dir='ltr'].lg\:ltr\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:px-8,[dir='ltr'].lg\:ltr\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:py-9,[dir='ltr'].lg\:ltr\:py-9 {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:px-9,[dir='ltr'].lg\:ltr\:px-9 {
    padding-left: 0.9rem;
    padding-right: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:py-10,[dir='ltr'].lg\:ltr\:py-10 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:px-10,[dir='ltr'].lg\:ltr\:px-10 {
    padding-left: 1.0rem;
    padding-right: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:py-12,[dir='ltr'].lg\:ltr\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:px-12,[dir='ltr'].lg\:ltr\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:py-14,[dir='ltr'].lg\:ltr\:py-14 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:px-14,[dir='ltr'].lg\:ltr\:px-14 {
    padding-left: 1.4rem;
    padding-right: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:py-16,[dir='ltr'].lg\:ltr\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:px-16,[dir='ltr'].lg\:ltr\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:py-20,[dir='ltr'].lg\:ltr\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  [dir='ltr'] .lg\:ltr\:px-20,[dir='ltr'].lg\:ltr\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  [dir='ltr'] .lg\:ltr\:py-24,[dir='ltr'].lg\:ltr\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:px-24,[dir='ltr'].lg\:ltr\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:py-28,[dir='ltr'].lg\:ltr\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:px-28,[dir='ltr'].lg\:ltr\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:py-32,[dir='ltr'].lg\:ltr\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:px-32,[dir='ltr'].lg\:ltr\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:py-36,[dir='ltr'].lg\:ltr\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:px-36,[dir='ltr'].lg\:ltr\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:py-40,[dir='ltr'].lg\:ltr\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  [dir='ltr'] .lg\:ltr\:px-40,[dir='ltr'].lg\:ltr\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  [dir='ltr'] .lg\:ltr\:py-44,[dir='ltr'].lg\:ltr\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:px-44,[dir='ltr'].lg\:ltr\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:py-48,[dir='ltr'].lg\:ltr\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:px-48,[dir='ltr'].lg\:ltr\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:py-52,[dir='ltr'].lg\:ltr\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:px-52,[dir='ltr'].lg\:ltr\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:py-56,[dir='ltr'].lg\:ltr\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:px-56,[dir='ltr'].lg\:ltr\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:py-60,[dir='ltr'].lg\:ltr\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  [dir='ltr'] .lg\:ltr\:px-60,[dir='ltr'].lg\:ltr\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  [dir='ltr'] .lg\:ltr\:py-64,[dir='ltr'].lg\:ltr\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:px-64,[dir='ltr'].lg\:ltr\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:py-68,[dir='ltr'].lg\:ltr\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:px-68,[dir='ltr'].lg\:ltr\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:py-72,[dir='ltr'].lg\:ltr\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:px-72,[dir='ltr'].lg\:ltr\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:py-76,[dir='ltr'].lg\:ltr\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:px-76,[dir='ltr'].lg\:ltr\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:py-80,[dir='ltr'].lg\:ltr\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  [dir='ltr'] .lg\:ltr\:px-80,[dir='ltr'].lg\:ltr\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  [dir='ltr'] .lg\:ltr\:py-84,[dir='ltr'].lg\:ltr\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:px-84,[dir='ltr'].lg\:ltr\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:py-88,[dir='ltr'].lg\:ltr\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:px-88,[dir='ltr'].lg\:ltr\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:py-92,[dir='ltr'].lg\:ltr\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:px-92,[dir='ltr'].lg\:ltr\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:py-96,[dir='ltr'].lg\:ltr\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:px-96,[dir='ltr'].lg\:ltr\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:py-128,[dir='ltr'].lg\:ltr\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:px-128,[dir='ltr'].lg\:ltr\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:py-136,[dir='ltr'].lg\:ltr\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:px-136,[dir='ltr'].lg\:ltr\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:py-160,[dir='ltr'].lg\:ltr\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  [dir='ltr'] .lg\:ltr\:px-160,[dir='ltr'].lg\:ltr\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  [dir='ltr'] .lg\:ltr\:py-192,[dir='ltr'].lg\:ltr\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:px-192,[dir='ltr'].lg\:ltr\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:py-200,[dir='ltr'].lg\:ltr\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  [dir='ltr'] .lg\:ltr\:px-200,[dir='ltr'].lg\:ltr\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  [dir='ltr'] .lg\:ltr\:py-208,[dir='ltr'].lg\:ltr\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:px-208,[dir='ltr'].lg\:ltr\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:py-216,[dir='ltr'].lg\:ltr\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:px-216,[dir='ltr'].lg\:ltr\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:py-224,[dir='ltr'].lg\:ltr\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:px-224,[dir='ltr'].lg\:ltr\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:py-256,[dir='ltr'].lg\:ltr\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:px-256,[dir='ltr'].lg\:ltr\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:py-288,[dir='ltr'].lg\:ltr\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:px-288,[dir='ltr'].lg\:ltr\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:py-320,[dir='ltr'].lg\:ltr\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='ltr'] .lg\:ltr\:px-320,[dir='ltr'].lg\:ltr\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='ltr'] .lg\:ltr\:py-360,[dir='ltr'].lg\:ltr\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  [dir='ltr'] .lg\:ltr\:px-360,[dir='ltr'].lg\:ltr\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  [dir='ltr'] .lg\:ltr\:py-384,[dir='ltr'].lg\:ltr\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:px-384,[dir='ltr'].lg\:ltr\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:py-400,[dir='ltr'].lg\:ltr\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  [dir='ltr'] .lg\:ltr\:px-400,[dir='ltr'].lg\:ltr\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  [dir='ltr'] .lg\:ltr\:py-512,[dir='ltr'].lg\:ltr\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:px-512,[dir='ltr'].lg\:ltr\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:py-640,[dir='ltr'].lg\:ltr\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='ltr'] .lg\:ltr\:px-640,[dir='ltr'].lg\:ltr\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='ltr'] .lg\:ltr\:py-xs,[dir='ltr'].lg\:ltr\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='ltr'] .lg\:ltr\:px-xs,[dir='ltr'].lg\:ltr\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='ltr'] .lg\:ltr\:py-sm,[dir='ltr'].lg\:ltr\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  [dir='ltr'] .lg\:ltr\:px-sm,[dir='ltr'].lg\:ltr\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  [dir='ltr'] .lg\:ltr\:py-md,[dir='ltr'].lg\:ltr\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='ltr'] .lg\:ltr\:px-md,[dir='ltr'].lg\:ltr\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='ltr'] .lg\:ltr\:py-lg,[dir='ltr'].lg\:ltr\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  [dir='ltr'] .lg\:ltr\:px-lg,[dir='ltr'].lg\:ltr\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  [dir='ltr'] .lg\:ltr\:py-xl,[dir='ltr'].lg\:ltr\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  [dir='ltr'] .lg\:ltr\:px-xl,[dir='ltr'].lg\:ltr\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  [dir='ltr'] .lg\:ltr\:py-2xl,[dir='ltr'].lg\:ltr\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  [dir='ltr'] .lg\:ltr\:px-2xl,[dir='ltr'].lg\:ltr\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  [dir='ltr'] .lg\:ltr\:py-3xl,[dir='ltr'].lg\:ltr\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  [dir='ltr'] .lg\:ltr\:px-3xl,[dir='ltr'].lg\:ltr\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  [dir='ltr'] .lg\:ltr\:py-4xl,[dir='ltr'].lg\:ltr\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  [dir='ltr'] .lg\:ltr\:px-4xl,[dir='ltr'].lg\:ltr\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  [dir='ltr'] .lg\:ltr\:py-5xl,[dir='ltr'].lg\:ltr\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  [dir='ltr'] .lg\:ltr\:px-5xl,[dir='ltr'].lg\:ltr\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  [dir='ltr'] .lg\:ltr\:py-px,[dir='ltr'].lg\:ltr\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  [dir='ltr'] .lg\:ltr\:px-px,[dir='ltr'].lg\:ltr\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  [dir='ltr'] .lg\:ltr\:py-0\.5,[dir='ltr'].lg\:ltr\:py-0\.5 {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:px-0\.5,[dir='ltr'].lg\:ltr\:px-0\.5 {
    padding-left: 0.05rem;
    padding-right: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:py-1\.5,[dir='ltr'].lg\:ltr\:py-1\.5 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:px-1\.5,[dir='ltr'].lg\:ltr\:px-1\.5 {
    padding-left: 0.15rem;
    padding-right: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:py-2\.5,[dir='ltr'].lg\:ltr\:py-2\.5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:px-2\.5,[dir='ltr'].lg\:ltr\:px-2\.5 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:py-3\.5,[dir='ltr'].lg\:ltr\:py-3\.5 {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:px-3\.5,[dir='ltr'].lg\:ltr\:px-3\.5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:pt-0,[dir='ltr'].lg\:ltr\:pt-0 {
    padding-top: 0
  }

  [dir='ltr'] .lg\:ltr\:pr-0,[dir='ltr'].lg\:ltr\:pr-0 {
    padding-right: 0
  }

  [dir='ltr'] .lg\:ltr\:pb-0,[dir='ltr'].lg\:ltr\:pb-0 {
    padding-bottom: 0
  }

  [dir='ltr'] .lg\:ltr\:pl-0,[dir='ltr'].lg\:ltr\:pl-0 {
    padding-left: 0
  }

  [dir='ltr'] .lg\:ltr\:pt-1,[dir='ltr'].lg\:ltr\:pt-1 {
    padding-top: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:pr-1,[dir='ltr'].lg\:ltr\:pr-1 {
    padding-right: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:pb-1,[dir='ltr'].lg\:ltr\:pb-1 {
    padding-bottom: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:pl-1,[dir='ltr'].lg\:ltr\:pl-1 {
    padding-left: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:pt-2,[dir='ltr'].lg\:ltr\:pt-2 {
    padding-top: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:pr-2,[dir='ltr'].lg\:ltr\:pr-2 {
    padding-right: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:pb-2,[dir='ltr'].lg\:ltr\:pb-2 {
    padding-bottom: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:pl-2,[dir='ltr'].lg\:ltr\:pl-2 {
    padding-left: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:pt-3,[dir='ltr'].lg\:ltr\:pt-3 {
    padding-top: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:pr-3,[dir='ltr'].lg\:ltr\:pr-3 {
    padding-right: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:pb-3,[dir='ltr'].lg\:ltr\:pb-3 {
    padding-bottom: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:pl-3,[dir='ltr'].lg\:ltr\:pl-3 {
    padding-left: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:pt-4,[dir='ltr'].lg\:ltr\:pt-4 {
    padding-top: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:pr-4,[dir='ltr'].lg\:ltr\:pr-4 {
    padding-right: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:pb-4,[dir='ltr'].lg\:ltr\:pb-4 {
    padding-bottom: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:pl-4,[dir='ltr'].lg\:ltr\:pl-4 {
    padding-left: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:pt-5,[dir='ltr'].lg\:ltr\:pt-5 {
    padding-top: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:pr-5,[dir='ltr'].lg\:ltr\:pr-5 {
    padding-right: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:pb-5,[dir='ltr'].lg\:ltr\:pb-5 {
    padding-bottom: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:pl-5,[dir='ltr'].lg\:ltr\:pl-5 {
    padding-left: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:pt-6,[dir='ltr'].lg\:ltr\:pt-6 {
    padding-top: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:pr-6,[dir='ltr'].lg\:ltr\:pr-6 {
    padding-right: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:pb-6,[dir='ltr'].lg\:ltr\:pb-6 {
    padding-bottom: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:pl-6,[dir='ltr'].lg\:ltr\:pl-6 {
    padding-left: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:pt-7,[dir='ltr'].lg\:ltr\:pt-7 {
    padding-top: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:pr-7,[dir='ltr'].lg\:ltr\:pr-7 {
    padding-right: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:pb-7,[dir='ltr'].lg\:ltr\:pb-7 {
    padding-bottom: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:pl-7,[dir='ltr'].lg\:ltr\:pl-7 {
    padding-left: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:pt-8,[dir='ltr'].lg\:ltr\:pt-8 {
    padding-top: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:pr-8,[dir='ltr'].lg\:ltr\:pr-8 {
    padding-right: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:pb-8,[dir='ltr'].lg\:ltr\:pb-8 {
    padding-bottom: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:pl-8,[dir='ltr'].lg\:ltr\:pl-8 {
    padding-left: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:pt-9,[dir='ltr'].lg\:ltr\:pt-9 {
    padding-top: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:pr-9,[dir='ltr'].lg\:ltr\:pr-9 {
    padding-right: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:pb-9,[dir='ltr'].lg\:ltr\:pb-9 {
    padding-bottom: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:pl-9,[dir='ltr'].lg\:ltr\:pl-9 {
    padding-left: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:pt-10,[dir='ltr'].lg\:ltr\:pt-10 {
    padding-top: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:pr-10,[dir='ltr'].lg\:ltr\:pr-10 {
    padding-right: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:pb-10,[dir='ltr'].lg\:ltr\:pb-10 {
    padding-bottom: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:pl-10,[dir='ltr'].lg\:ltr\:pl-10 {
    padding-left: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:pt-12,[dir='ltr'].lg\:ltr\:pt-12 {
    padding-top: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:pr-12,[dir='ltr'].lg\:ltr\:pr-12 {
    padding-right: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:pb-12,[dir='ltr'].lg\:ltr\:pb-12 {
    padding-bottom: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:pl-12,[dir='ltr'].lg\:ltr\:pl-12 {
    padding-left: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:pt-14,[dir='ltr'].lg\:ltr\:pt-14 {
    padding-top: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:pr-14,[dir='ltr'].lg\:ltr\:pr-14 {
    padding-right: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:pb-14,[dir='ltr'].lg\:ltr\:pb-14 {
    padding-bottom: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:pl-14,[dir='ltr'].lg\:ltr\:pl-14 {
    padding-left: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:pt-16,[dir='ltr'].lg\:ltr\:pt-16 {
    padding-top: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:pr-16,[dir='ltr'].lg\:ltr\:pr-16 {
    padding-right: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:pb-16,[dir='ltr'].lg\:ltr\:pb-16 {
    padding-bottom: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:pl-16,[dir='ltr'].lg\:ltr\:pl-16 {
    padding-left: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:pt-20,[dir='ltr'].lg\:ltr\:pt-20 {
    padding-top: 2rem
  }

  [dir='ltr'] .lg\:ltr\:pr-20,[dir='ltr'].lg\:ltr\:pr-20 {
    padding-right: 2rem
  }

  [dir='ltr'] .lg\:ltr\:pb-20,[dir='ltr'].lg\:ltr\:pb-20 {
    padding-bottom: 2rem
  }

  [dir='ltr'] .lg\:ltr\:pl-20,[dir='ltr'].lg\:ltr\:pl-20 {
    padding-left: 2rem
  }

  [dir='ltr'] .lg\:ltr\:pt-24,[dir='ltr'].lg\:ltr\:pt-24 {
    padding-top: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:pr-24,[dir='ltr'].lg\:ltr\:pr-24 {
    padding-right: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:pb-24,[dir='ltr'].lg\:ltr\:pb-24 {
    padding-bottom: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:pl-24,[dir='ltr'].lg\:ltr\:pl-24 {
    padding-left: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:pt-28,[dir='ltr'].lg\:ltr\:pt-28 {
    padding-top: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:pr-28,[dir='ltr'].lg\:ltr\:pr-28 {
    padding-right: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:pb-28,[dir='ltr'].lg\:ltr\:pb-28 {
    padding-bottom: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:pl-28,[dir='ltr'].lg\:ltr\:pl-28 {
    padding-left: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:pt-32,[dir='ltr'].lg\:ltr\:pt-32 {
    padding-top: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:pr-32,[dir='ltr'].lg\:ltr\:pr-32 {
    padding-right: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:pb-32,[dir='ltr'].lg\:ltr\:pb-32 {
    padding-bottom: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:pl-32,[dir='ltr'].lg\:ltr\:pl-32 {
    padding-left: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:pt-36,[dir='ltr'].lg\:ltr\:pt-36 {
    padding-top: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:pr-36,[dir='ltr'].lg\:ltr\:pr-36 {
    padding-right: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:pb-36,[dir='ltr'].lg\:ltr\:pb-36 {
    padding-bottom: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:pl-36,[dir='ltr'].lg\:ltr\:pl-36 {
    padding-left: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:pt-40,[dir='ltr'].lg\:ltr\:pt-40 {
    padding-top: 4rem
  }

  [dir='ltr'] .lg\:ltr\:pr-40,[dir='ltr'].lg\:ltr\:pr-40 {
    padding-right: 4rem
  }

  [dir='ltr'] .lg\:ltr\:pb-40,[dir='ltr'].lg\:ltr\:pb-40 {
    padding-bottom: 4rem
  }

  [dir='ltr'] .lg\:ltr\:pl-40,[dir='ltr'].lg\:ltr\:pl-40 {
    padding-left: 4rem
  }

  [dir='ltr'] .lg\:ltr\:pt-44,[dir='ltr'].lg\:ltr\:pt-44 {
    padding-top: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:pr-44,[dir='ltr'].lg\:ltr\:pr-44 {
    padding-right: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:pb-44,[dir='ltr'].lg\:ltr\:pb-44 {
    padding-bottom: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:pl-44,[dir='ltr'].lg\:ltr\:pl-44 {
    padding-left: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:pt-48,[dir='ltr'].lg\:ltr\:pt-48 {
    padding-top: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:pr-48,[dir='ltr'].lg\:ltr\:pr-48 {
    padding-right: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:pb-48,[dir='ltr'].lg\:ltr\:pb-48 {
    padding-bottom: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:pl-48,[dir='ltr'].lg\:ltr\:pl-48 {
    padding-left: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:pt-52,[dir='ltr'].lg\:ltr\:pt-52 {
    padding-top: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:pr-52,[dir='ltr'].lg\:ltr\:pr-52 {
    padding-right: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:pb-52,[dir='ltr'].lg\:ltr\:pb-52 {
    padding-bottom: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:pl-52,[dir='ltr'].lg\:ltr\:pl-52 {
    padding-left: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:pt-56,[dir='ltr'].lg\:ltr\:pt-56 {
    padding-top: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:pr-56,[dir='ltr'].lg\:ltr\:pr-56 {
    padding-right: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:pb-56,[dir='ltr'].lg\:ltr\:pb-56 {
    padding-bottom: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:pl-56,[dir='ltr'].lg\:ltr\:pl-56 {
    padding-left: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:pt-60,[dir='ltr'].lg\:ltr\:pt-60 {
    padding-top: 6rem
  }

  [dir='ltr'] .lg\:ltr\:pr-60,[dir='ltr'].lg\:ltr\:pr-60 {
    padding-right: 6rem
  }

  [dir='ltr'] .lg\:ltr\:pb-60,[dir='ltr'].lg\:ltr\:pb-60 {
    padding-bottom: 6rem
  }

  [dir='ltr'] .lg\:ltr\:pl-60,[dir='ltr'].lg\:ltr\:pl-60 {
    padding-left: 6rem
  }

  [dir='ltr'] .lg\:ltr\:pt-64,[dir='ltr'].lg\:ltr\:pt-64 {
    padding-top: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:pr-64,[dir='ltr'].lg\:ltr\:pr-64 {
    padding-right: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:pb-64,[dir='ltr'].lg\:ltr\:pb-64 {
    padding-bottom: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:pl-64,[dir='ltr'].lg\:ltr\:pl-64 {
    padding-left: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:pt-68,[dir='ltr'].lg\:ltr\:pt-68 {
    padding-top: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:pr-68,[dir='ltr'].lg\:ltr\:pr-68 {
    padding-right: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:pb-68,[dir='ltr'].lg\:ltr\:pb-68 {
    padding-bottom: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:pl-68,[dir='ltr'].lg\:ltr\:pl-68 {
    padding-left: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:pt-72,[dir='ltr'].lg\:ltr\:pt-72 {
    padding-top: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:pr-72,[dir='ltr'].lg\:ltr\:pr-72 {
    padding-right: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:pb-72,[dir='ltr'].lg\:ltr\:pb-72 {
    padding-bottom: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:pl-72,[dir='ltr'].lg\:ltr\:pl-72 {
    padding-left: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:pt-76,[dir='ltr'].lg\:ltr\:pt-76 {
    padding-top: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:pr-76,[dir='ltr'].lg\:ltr\:pr-76 {
    padding-right: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:pb-76,[dir='ltr'].lg\:ltr\:pb-76 {
    padding-bottom: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:pl-76,[dir='ltr'].lg\:ltr\:pl-76 {
    padding-left: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:pt-80,[dir='ltr'].lg\:ltr\:pt-80 {
    padding-top: 8rem
  }

  [dir='ltr'] .lg\:ltr\:pr-80,[dir='ltr'].lg\:ltr\:pr-80 {
    padding-right: 8rem
  }

  [dir='ltr'] .lg\:ltr\:pb-80,[dir='ltr'].lg\:ltr\:pb-80 {
    padding-bottom: 8rem
  }

  [dir='ltr'] .lg\:ltr\:pl-80,[dir='ltr'].lg\:ltr\:pl-80 {
    padding-left: 8rem
  }

  [dir='ltr'] .lg\:ltr\:pt-84,[dir='ltr'].lg\:ltr\:pt-84 {
    padding-top: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:pr-84,[dir='ltr'].lg\:ltr\:pr-84 {
    padding-right: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:pb-84,[dir='ltr'].lg\:ltr\:pb-84 {
    padding-bottom: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:pl-84,[dir='ltr'].lg\:ltr\:pl-84 {
    padding-left: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:pt-88,[dir='ltr'].lg\:ltr\:pt-88 {
    padding-top: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:pr-88,[dir='ltr'].lg\:ltr\:pr-88 {
    padding-right: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:pb-88,[dir='ltr'].lg\:ltr\:pb-88 {
    padding-bottom: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:pl-88,[dir='ltr'].lg\:ltr\:pl-88 {
    padding-left: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:pt-92,[dir='ltr'].lg\:ltr\:pt-92 {
    padding-top: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:pr-92,[dir='ltr'].lg\:ltr\:pr-92 {
    padding-right: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:pb-92,[dir='ltr'].lg\:ltr\:pb-92 {
    padding-bottom: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:pl-92,[dir='ltr'].lg\:ltr\:pl-92 {
    padding-left: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:pt-96,[dir='ltr'].lg\:ltr\:pt-96 {
    padding-top: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:pr-96,[dir='ltr'].lg\:ltr\:pr-96 {
    padding-right: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:pb-96,[dir='ltr'].lg\:ltr\:pb-96 {
    padding-bottom: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:pl-96,[dir='ltr'].lg\:ltr\:pl-96 {
    padding-left: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:pt-128,[dir='ltr'].lg\:ltr\:pt-128 {
    padding-top: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:pr-128,[dir='ltr'].lg\:ltr\:pr-128 {
    padding-right: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:pb-128,[dir='ltr'].lg\:ltr\:pb-128 {
    padding-bottom: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:pl-128,[dir='ltr'].lg\:ltr\:pl-128 {
    padding-left: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:pt-136,[dir='ltr'].lg\:ltr\:pt-136 {
    padding-top: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:pr-136,[dir='ltr'].lg\:ltr\:pr-136 {
    padding-right: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:pb-136,[dir='ltr'].lg\:ltr\:pb-136 {
    padding-bottom: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:pl-136,[dir='ltr'].lg\:ltr\:pl-136 {
    padding-left: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:pt-160,[dir='ltr'].lg\:ltr\:pt-160 {
    padding-top: 16rem
  }

  [dir='ltr'] .lg\:ltr\:pr-160,[dir='ltr'].lg\:ltr\:pr-160 {
    padding-right: 16rem
  }

  [dir='ltr'] .lg\:ltr\:pb-160,[dir='ltr'].lg\:ltr\:pb-160 {
    padding-bottom: 16rem
  }

  [dir='ltr'] .lg\:ltr\:pl-160,[dir='ltr'].lg\:ltr\:pl-160 {
    padding-left: 16rem
  }

  [dir='ltr'] .lg\:ltr\:pt-192,[dir='ltr'].lg\:ltr\:pt-192 {
    padding-top: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:pr-192,[dir='ltr'].lg\:ltr\:pr-192 {
    padding-right: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:pb-192,[dir='ltr'].lg\:ltr\:pb-192 {
    padding-bottom: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:pl-192,[dir='ltr'].lg\:ltr\:pl-192 {
    padding-left: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:pt-200,[dir='ltr'].lg\:ltr\:pt-200 {
    padding-top: 20rem
  }

  [dir='ltr'] .lg\:ltr\:pr-200,[dir='ltr'].lg\:ltr\:pr-200 {
    padding-right: 20rem
  }

  [dir='ltr'] .lg\:ltr\:pb-200,[dir='ltr'].lg\:ltr\:pb-200 {
    padding-bottom: 20rem
  }

  [dir='ltr'] .lg\:ltr\:pl-200,[dir='ltr'].lg\:ltr\:pl-200 {
    padding-left: 20rem
  }

  [dir='ltr'] .lg\:ltr\:pt-208,[dir='ltr'].lg\:ltr\:pt-208 {
    padding-top: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:pr-208,[dir='ltr'].lg\:ltr\:pr-208 {
    padding-right: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:pb-208,[dir='ltr'].lg\:ltr\:pb-208 {
    padding-bottom: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:pl-208,[dir='ltr'].lg\:ltr\:pl-208 {
    padding-left: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:pt-216,[dir='ltr'].lg\:ltr\:pt-216 {
    padding-top: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:pr-216,[dir='ltr'].lg\:ltr\:pr-216 {
    padding-right: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:pb-216,[dir='ltr'].lg\:ltr\:pb-216 {
    padding-bottom: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:pl-216,[dir='ltr'].lg\:ltr\:pl-216 {
    padding-left: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:pt-224,[dir='ltr'].lg\:ltr\:pt-224 {
    padding-top: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:pr-224,[dir='ltr'].lg\:ltr\:pr-224 {
    padding-right: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:pb-224,[dir='ltr'].lg\:ltr\:pb-224 {
    padding-bottom: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:pl-224,[dir='ltr'].lg\:ltr\:pl-224 {
    padding-left: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:pt-256,[dir='ltr'].lg\:ltr\:pt-256 {
    padding-top: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:pr-256,[dir='ltr'].lg\:ltr\:pr-256 {
    padding-right: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:pb-256,[dir='ltr'].lg\:ltr\:pb-256 {
    padding-bottom: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:pl-256,[dir='ltr'].lg\:ltr\:pl-256 {
    padding-left: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:pt-288,[dir='ltr'].lg\:ltr\:pt-288 {
    padding-top: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:pr-288,[dir='ltr'].lg\:ltr\:pr-288 {
    padding-right: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:pb-288,[dir='ltr'].lg\:ltr\:pb-288 {
    padding-bottom: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:pl-288,[dir='ltr'].lg\:ltr\:pl-288 {
    padding-left: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:pt-320,[dir='ltr'].lg\:ltr\:pt-320 {
    padding-top: 32rem
  }

  [dir='ltr'] .lg\:ltr\:pr-320,[dir='ltr'].lg\:ltr\:pr-320 {
    padding-right: 32rem
  }

  [dir='ltr'] .lg\:ltr\:pb-320,[dir='ltr'].lg\:ltr\:pb-320 {
    padding-bottom: 32rem
  }

  [dir='ltr'] .lg\:ltr\:pl-320,[dir='ltr'].lg\:ltr\:pl-320 {
    padding-left: 32rem
  }

  [dir='ltr'] .lg\:ltr\:pt-360,[dir='ltr'].lg\:ltr\:pt-360 {
    padding-top: 36rem
  }

  [dir='ltr'] .lg\:ltr\:pr-360,[dir='ltr'].lg\:ltr\:pr-360 {
    padding-right: 36rem
  }

  [dir='ltr'] .lg\:ltr\:pb-360,[dir='ltr'].lg\:ltr\:pb-360 {
    padding-bottom: 36rem
  }

  [dir='ltr'] .lg\:ltr\:pl-360,[dir='ltr'].lg\:ltr\:pl-360 {
    padding-left: 36rem
  }

  [dir='ltr'] .lg\:ltr\:pt-384,[dir='ltr'].lg\:ltr\:pt-384 {
    padding-top: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:pr-384,[dir='ltr'].lg\:ltr\:pr-384 {
    padding-right: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:pb-384,[dir='ltr'].lg\:ltr\:pb-384 {
    padding-bottom: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:pl-384,[dir='ltr'].lg\:ltr\:pl-384 {
    padding-left: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:pt-400,[dir='ltr'].lg\:ltr\:pt-400 {
    padding-top: 40rem
  }

  [dir='ltr'] .lg\:ltr\:pr-400,[dir='ltr'].lg\:ltr\:pr-400 {
    padding-right: 40rem
  }

  [dir='ltr'] .lg\:ltr\:pb-400,[dir='ltr'].lg\:ltr\:pb-400 {
    padding-bottom: 40rem
  }

  [dir='ltr'] .lg\:ltr\:pl-400,[dir='ltr'].lg\:ltr\:pl-400 {
    padding-left: 40rem
  }

  [dir='ltr'] .lg\:ltr\:pt-512,[dir='ltr'].lg\:ltr\:pt-512 {
    padding-top: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:pr-512,[dir='ltr'].lg\:ltr\:pr-512 {
    padding-right: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:pb-512,[dir='ltr'].lg\:ltr\:pb-512 {
    padding-bottom: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:pl-512,[dir='ltr'].lg\:ltr\:pl-512 {
    padding-left: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:pt-640,[dir='ltr'].lg\:ltr\:pt-640 {
    padding-top: 64rem
  }

  [dir='ltr'] .lg\:ltr\:pr-640,[dir='ltr'].lg\:ltr\:pr-640 {
    padding-right: 64rem
  }

  [dir='ltr'] .lg\:ltr\:pb-640,[dir='ltr'].lg\:ltr\:pb-640 {
    padding-bottom: 64rem
  }

  [dir='ltr'] .lg\:ltr\:pl-640,[dir='ltr'].lg\:ltr\:pl-640 {
    padding-left: 64rem
  }

  [dir='ltr'] .lg\:ltr\:pt-xs,[dir='ltr'].lg\:ltr\:pt-xs {
    padding-top: 32rem
  }

  [dir='ltr'] .lg\:ltr\:pr-xs,[dir='ltr'].lg\:ltr\:pr-xs {
    padding-right: 32rem
  }

  [dir='ltr'] .lg\:ltr\:pb-xs,[dir='ltr'].lg\:ltr\:pb-xs {
    padding-bottom: 32rem
  }

  [dir='ltr'] .lg\:ltr\:pl-xs,[dir='ltr'].lg\:ltr\:pl-xs {
    padding-left: 32rem
  }

  [dir='ltr'] .lg\:ltr\:pt-sm,[dir='ltr'].lg\:ltr\:pt-sm {
    padding-top: 48rem
  }

  [dir='ltr'] .lg\:ltr\:pr-sm,[dir='ltr'].lg\:ltr\:pr-sm {
    padding-right: 48rem
  }

  [dir='ltr'] .lg\:ltr\:pb-sm,[dir='ltr'].lg\:ltr\:pb-sm {
    padding-bottom: 48rem
  }

  [dir='ltr'] .lg\:ltr\:pl-sm,[dir='ltr'].lg\:ltr\:pl-sm {
    padding-left: 48rem
  }

  [dir='ltr'] .lg\:ltr\:pt-md,[dir='ltr'].lg\:ltr\:pt-md {
    padding-top: 64rem
  }

  [dir='ltr'] .lg\:ltr\:pr-md,[dir='ltr'].lg\:ltr\:pr-md {
    padding-right: 64rem
  }

  [dir='ltr'] .lg\:ltr\:pb-md,[dir='ltr'].lg\:ltr\:pb-md {
    padding-bottom: 64rem
  }

  [dir='ltr'] .lg\:ltr\:pl-md,[dir='ltr'].lg\:ltr\:pl-md {
    padding-left: 64rem
  }

  [dir='ltr'] .lg\:ltr\:pt-lg,[dir='ltr'].lg\:ltr\:pt-lg {
    padding-top: 80rem
  }

  [dir='ltr'] .lg\:ltr\:pr-lg,[dir='ltr'].lg\:ltr\:pr-lg {
    padding-right: 80rem
  }

  [dir='ltr'] .lg\:ltr\:pb-lg,[dir='ltr'].lg\:ltr\:pb-lg {
    padding-bottom: 80rem
  }

  [dir='ltr'] .lg\:ltr\:pl-lg,[dir='ltr'].lg\:ltr\:pl-lg {
    padding-left: 80rem
  }

  [dir='ltr'] .lg\:ltr\:pt-xl,[dir='ltr'].lg\:ltr\:pt-xl {
    padding-top: 96rem
  }

  [dir='ltr'] .lg\:ltr\:pr-xl,[dir='ltr'].lg\:ltr\:pr-xl {
    padding-right: 96rem
  }

  [dir='ltr'] .lg\:ltr\:pb-xl,[dir='ltr'].lg\:ltr\:pb-xl {
    padding-bottom: 96rem
  }

  [dir='ltr'] .lg\:ltr\:pl-xl,[dir='ltr'].lg\:ltr\:pl-xl {
    padding-left: 96rem
  }

  [dir='ltr'] .lg\:ltr\:pt-2xl,[dir='ltr'].lg\:ltr\:pt-2xl {
    padding-top: 112rem
  }

  [dir='ltr'] .lg\:ltr\:pr-2xl,[dir='ltr'].lg\:ltr\:pr-2xl {
    padding-right: 112rem
  }

  [dir='ltr'] .lg\:ltr\:pb-2xl,[dir='ltr'].lg\:ltr\:pb-2xl {
    padding-bottom: 112rem
  }

  [dir='ltr'] .lg\:ltr\:pl-2xl,[dir='ltr'].lg\:ltr\:pl-2xl {
    padding-left: 112rem
  }

  [dir='ltr'] .lg\:ltr\:pt-3xl,[dir='ltr'].lg\:ltr\:pt-3xl {
    padding-top: 128rem
  }

  [dir='ltr'] .lg\:ltr\:pr-3xl,[dir='ltr'].lg\:ltr\:pr-3xl {
    padding-right: 128rem
  }

  [dir='ltr'] .lg\:ltr\:pb-3xl,[dir='ltr'].lg\:ltr\:pb-3xl {
    padding-bottom: 128rem
  }

  [dir='ltr'] .lg\:ltr\:pl-3xl,[dir='ltr'].lg\:ltr\:pl-3xl {
    padding-left: 128rem
  }

  [dir='ltr'] .lg\:ltr\:pt-4xl,[dir='ltr'].lg\:ltr\:pt-4xl {
    padding-top: 144rem
  }

  [dir='ltr'] .lg\:ltr\:pr-4xl,[dir='ltr'].lg\:ltr\:pr-4xl {
    padding-right: 144rem
  }

  [dir='ltr'] .lg\:ltr\:pb-4xl,[dir='ltr'].lg\:ltr\:pb-4xl {
    padding-bottom: 144rem
  }

  [dir='ltr'] .lg\:ltr\:pl-4xl,[dir='ltr'].lg\:ltr\:pl-4xl {
    padding-left: 144rem
  }

  [dir='ltr'] .lg\:ltr\:pt-5xl,[dir='ltr'].lg\:ltr\:pt-5xl {
    padding-top: 160rem
  }

  [dir='ltr'] .lg\:ltr\:pr-5xl,[dir='ltr'].lg\:ltr\:pr-5xl {
    padding-right: 160rem
  }

  [dir='ltr'] .lg\:ltr\:pb-5xl,[dir='ltr'].lg\:ltr\:pb-5xl {
    padding-bottom: 160rem
  }

  [dir='ltr'] .lg\:ltr\:pl-5xl,[dir='ltr'].lg\:ltr\:pl-5xl {
    padding-left: 160rem
  }

  [dir='ltr'] .lg\:ltr\:pt-px,[dir='ltr'].lg\:ltr\:pt-px {
    padding-top: 1px
  }

  [dir='ltr'] .lg\:ltr\:pr-px,[dir='ltr'].lg\:ltr\:pr-px {
    padding-right: 1px
  }

  [dir='ltr'] .lg\:ltr\:pb-px,[dir='ltr'].lg\:ltr\:pb-px {
    padding-bottom: 1px
  }

  [dir='ltr'] .lg\:ltr\:pl-px,[dir='ltr'].lg\:ltr\:pl-px {
    padding-left: 1px
  }

  [dir='ltr'] .lg\:ltr\:pt-0\.5,[dir='ltr'].lg\:ltr\:pt-0\.5 {
    padding-top: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:pr-0\.5,[dir='ltr'].lg\:ltr\:pr-0\.5 {
    padding-right: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:pb-0\.5,[dir='ltr'].lg\:ltr\:pb-0\.5 {
    padding-bottom: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:pl-0\.5,[dir='ltr'].lg\:ltr\:pl-0\.5 {
    padding-left: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:pt-1\.5,[dir='ltr'].lg\:ltr\:pt-1\.5 {
    padding-top: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:pr-1\.5,[dir='ltr'].lg\:ltr\:pr-1\.5 {
    padding-right: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:pb-1\.5,[dir='ltr'].lg\:ltr\:pb-1\.5 {
    padding-bottom: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:pl-1\.5,[dir='ltr'].lg\:ltr\:pl-1\.5 {
    padding-left: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:pt-2\.5,[dir='ltr'].lg\:ltr\:pt-2\.5 {
    padding-top: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:pr-2\.5,[dir='ltr'].lg\:ltr\:pr-2\.5 {
    padding-right: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:pb-2\.5,[dir='ltr'].lg\:ltr\:pb-2\.5 {
    padding-bottom: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:pl-2\.5,[dir='ltr'].lg\:ltr\:pl-2\.5 {
    padding-left: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:pt-3\.5,[dir='ltr'].lg\:ltr\:pt-3\.5 {
    padding-top: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:pr-3\.5,[dir='ltr'].lg\:ltr\:pr-3\.5 {
    padding-right: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:pb-3\.5,[dir='ltr'].lg\:ltr\:pb-3\.5 {
    padding-bottom: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:pl-3\.5,[dir='ltr'].lg\:ltr\:pl-3\.5 {
    padding-left: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:p-0,[dir='rtl'].lg\:rtl\:p-0 {
    padding: 0
  }

  [dir='rtl'] .lg\:rtl\:p-1,[dir='rtl'].lg\:rtl\:p-1 {
    padding: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:p-2,[dir='rtl'].lg\:rtl\:p-2 {
    padding: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:p-3,[dir='rtl'].lg\:rtl\:p-3 {
    padding: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:p-4,[dir='rtl'].lg\:rtl\:p-4 {
    padding: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:p-5,[dir='rtl'].lg\:rtl\:p-5 {
    padding: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:p-6,[dir='rtl'].lg\:rtl\:p-6 {
    padding: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:p-7,[dir='rtl'].lg\:rtl\:p-7 {
    padding: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:p-8,[dir='rtl'].lg\:rtl\:p-8 {
    padding: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:p-9,[dir='rtl'].lg\:rtl\:p-9 {
    padding: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:p-10,[dir='rtl'].lg\:rtl\:p-10 {
    padding: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:p-12,[dir='rtl'].lg\:rtl\:p-12 {
    padding: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:p-14,[dir='rtl'].lg\:rtl\:p-14 {
    padding: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:p-16,[dir='rtl'].lg\:rtl\:p-16 {
    padding: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:p-20,[dir='rtl'].lg\:rtl\:p-20 {
    padding: 2rem
  }

  [dir='rtl'] .lg\:rtl\:p-24,[dir='rtl'].lg\:rtl\:p-24 {
    padding: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:p-28,[dir='rtl'].lg\:rtl\:p-28 {
    padding: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:p-32,[dir='rtl'].lg\:rtl\:p-32 {
    padding: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:p-36,[dir='rtl'].lg\:rtl\:p-36 {
    padding: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:p-40,[dir='rtl'].lg\:rtl\:p-40 {
    padding: 4rem
  }

  [dir='rtl'] .lg\:rtl\:p-44,[dir='rtl'].lg\:rtl\:p-44 {
    padding: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:p-48,[dir='rtl'].lg\:rtl\:p-48 {
    padding: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:p-52,[dir='rtl'].lg\:rtl\:p-52 {
    padding: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:p-56,[dir='rtl'].lg\:rtl\:p-56 {
    padding: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:p-60,[dir='rtl'].lg\:rtl\:p-60 {
    padding: 6rem
  }

  [dir='rtl'] .lg\:rtl\:p-64,[dir='rtl'].lg\:rtl\:p-64 {
    padding: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:p-68,[dir='rtl'].lg\:rtl\:p-68 {
    padding: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:p-72,[dir='rtl'].lg\:rtl\:p-72 {
    padding: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:p-76,[dir='rtl'].lg\:rtl\:p-76 {
    padding: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:p-80,[dir='rtl'].lg\:rtl\:p-80 {
    padding: 8rem
  }

  [dir='rtl'] .lg\:rtl\:p-84,[dir='rtl'].lg\:rtl\:p-84 {
    padding: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:p-88,[dir='rtl'].lg\:rtl\:p-88 {
    padding: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:p-92,[dir='rtl'].lg\:rtl\:p-92 {
    padding: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:p-96,[dir='rtl'].lg\:rtl\:p-96 {
    padding: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:p-128,[dir='rtl'].lg\:rtl\:p-128 {
    padding: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:p-136,[dir='rtl'].lg\:rtl\:p-136 {
    padding: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:p-160,[dir='rtl'].lg\:rtl\:p-160 {
    padding: 16rem
  }

  [dir='rtl'] .lg\:rtl\:p-192,[dir='rtl'].lg\:rtl\:p-192 {
    padding: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:p-200,[dir='rtl'].lg\:rtl\:p-200 {
    padding: 20rem
  }

  [dir='rtl'] .lg\:rtl\:p-208,[dir='rtl'].lg\:rtl\:p-208 {
    padding: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:p-216,[dir='rtl'].lg\:rtl\:p-216 {
    padding: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:p-224,[dir='rtl'].lg\:rtl\:p-224 {
    padding: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:p-256,[dir='rtl'].lg\:rtl\:p-256 {
    padding: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:p-288,[dir='rtl'].lg\:rtl\:p-288 {
    padding: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:p-320,[dir='rtl'].lg\:rtl\:p-320 {
    padding: 32rem
  }

  [dir='rtl'] .lg\:rtl\:p-360,[dir='rtl'].lg\:rtl\:p-360 {
    padding: 36rem
  }

  [dir='rtl'] .lg\:rtl\:p-384,[dir='rtl'].lg\:rtl\:p-384 {
    padding: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:p-400,[dir='rtl'].lg\:rtl\:p-400 {
    padding: 40rem
  }

  [dir='rtl'] .lg\:rtl\:p-512,[dir='rtl'].lg\:rtl\:p-512 {
    padding: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:p-640,[dir='rtl'].lg\:rtl\:p-640 {
    padding: 64rem
  }

  [dir='rtl'] .lg\:rtl\:p-xs,[dir='rtl'].lg\:rtl\:p-xs {
    padding: 32rem
  }

  [dir='rtl'] .lg\:rtl\:p-sm,[dir='rtl'].lg\:rtl\:p-sm {
    padding: 48rem
  }

  [dir='rtl'] .lg\:rtl\:p-md,[dir='rtl'].lg\:rtl\:p-md {
    padding: 64rem
  }

  [dir='rtl'] .lg\:rtl\:p-lg,[dir='rtl'].lg\:rtl\:p-lg {
    padding: 80rem
  }

  [dir='rtl'] .lg\:rtl\:p-xl,[dir='rtl'].lg\:rtl\:p-xl {
    padding: 96rem
  }

  [dir='rtl'] .lg\:rtl\:p-2xl,[dir='rtl'].lg\:rtl\:p-2xl {
    padding: 112rem
  }

  [dir='rtl'] .lg\:rtl\:p-3xl,[dir='rtl'].lg\:rtl\:p-3xl {
    padding: 128rem
  }

  [dir='rtl'] .lg\:rtl\:p-4xl,[dir='rtl'].lg\:rtl\:p-4xl {
    padding: 144rem
  }

  [dir='rtl'] .lg\:rtl\:p-5xl,[dir='rtl'].lg\:rtl\:p-5xl {
    padding: 160rem
  }

  [dir='rtl'] .lg\:rtl\:p-px,[dir='rtl'].lg\:rtl\:p-px {
    padding: 1px
  }

  [dir='rtl'] .lg\:rtl\:p-0\.5,[dir='rtl'].lg\:rtl\:p-0\.5 {
    padding: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:p-1\.5,[dir='rtl'].lg\:rtl\:p-1\.5 {
    padding: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:p-2\.5,[dir='rtl'].lg\:rtl\:p-2\.5 {
    padding: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:p-3\.5,[dir='rtl'].lg\:rtl\:p-3\.5 {
    padding: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:py-0,[dir='rtl'].lg\:rtl\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  [dir='rtl'] .lg\:rtl\:px-0,[dir='rtl'].lg\:rtl\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  [dir='rtl'] .lg\:rtl\:py-1,[dir='rtl'].lg\:rtl\:py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:px-1,[dir='rtl'].lg\:rtl\:px-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:py-2,[dir='rtl'].lg\:rtl\:py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:px-2,[dir='rtl'].lg\:rtl\:px-2 {
    padding-left: 0.2rem;
    padding-right: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:py-3,[dir='rtl'].lg\:rtl\:py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:px-3,[dir='rtl'].lg\:rtl\:px-3 {
    padding-left: 0.3rem;
    padding-right: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:py-4,[dir='rtl'].lg\:rtl\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:px-4,[dir='rtl'].lg\:rtl\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:py-5,[dir='rtl'].lg\:rtl\:py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:px-5,[dir='rtl'].lg\:rtl\:px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:py-6,[dir='rtl'].lg\:rtl\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:px-6,[dir='rtl'].lg\:rtl\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:py-7,[dir='rtl'].lg\:rtl\:py-7 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:px-7,[dir='rtl'].lg\:rtl\:px-7 {
    padding-left: 0.7rem;
    padding-right: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:py-8,[dir='rtl'].lg\:rtl\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:px-8,[dir='rtl'].lg\:rtl\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:py-9,[dir='rtl'].lg\:rtl\:py-9 {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:px-9,[dir='rtl'].lg\:rtl\:px-9 {
    padding-left: 0.9rem;
    padding-right: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:py-10,[dir='rtl'].lg\:rtl\:py-10 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:px-10,[dir='rtl'].lg\:rtl\:px-10 {
    padding-left: 1.0rem;
    padding-right: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:py-12,[dir='rtl'].lg\:rtl\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:px-12,[dir='rtl'].lg\:rtl\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:py-14,[dir='rtl'].lg\:rtl\:py-14 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:px-14,[dir='rtl'].lg\:rtl\:px-14 {
    padding-left: 1.4rem;
    padding-right: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:py-16,[dir='rtl'].lg\:rtl\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:px-16,[dir='rtl'].lg\:rtl\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:py-20,[dir='rtl'].lg\:rtl\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  [dir='rtl'] .lg\:rtl\:px-20,[dir='rtl'].lg\:rtl\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  [dir='rtl'] .lg\:rtl\:py-24,[dir='rtl'].lg\:rtl\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:px-24,[dir='rtl'].lg\:rtl\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:py-28,[dir='rtl'].lg\:rtl\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:px-28,[dir='rtl'].lg\:rtl\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:py-32,[dir='rtl'].lg\:rtl\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:px-32,[dir='rtl'].lg\:rtl\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:py-36,[dir='rtl'].lg\:rtl\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:px-36,[dir='rtl'].lg\:rtl\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:py-40,[dir='rtl'].lg\:rtl\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  [dir='rtl'] .lg\:rtl\:px-40,[dir='rtl'].lg\:rtl\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  [dir='rtl'] .lg\:rtl\:py-44,[dir='rtl'].lg\:rtl\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:px-44,[dir='rtl'].lg\:rtl\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:py-48,[dir='rtl'].lg\:rtl\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:px-48,[dir='rtl'].lg\:rtl\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:py-52,[dir='rtl'].lg\:rtl\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:px-52,[dir='rtl'].lg\:rtl\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:py-56,[dir='rtl'].lg\:rtl\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:px-56,[dir='rtl'].lg\:rtl\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:py-60,[dir='rtl'].lg\:rtl\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  [dir='rtl'] .lg\:rtl\:px-60,[dir='rtl'].lg\:rtl\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  [dir='rtl'] .lg\:rtl\:py-64,[dir='rtl'].lg\:rtl\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:px-64,[dir='rtl'].lg\:rtl\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:py-68,[dir='rtl'].lg\:rtl\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:px-68,[dir='rtl'].lg\:rtl\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:py-72,[dir='rtl'].lg\:rtl\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:px-72,[dir='rtl'].lg\:rtl\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:py-76,[dir='rtl'].lg\:rtl\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:px-76,[dir='rtl'].lg\:rtl\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:py-80,[dir='rtl'].lg\:rtl\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  [dir='rtl'] .lg\:rtl\:px-80,[dir='rtl'].lg\:rtl\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  [dir='rtl'] .lg\:rtl\:py-84,[dir='rtl'].lg\:rtl\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:px-84,[dir='rtl'].lg\:rtl\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:py-88,[dir='rtl'].lg\:rtl\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:px-88,[dir='rtl'].lg\:rtl\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:py-92,[dir='rtl'].lg\:rtl\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:px-92,[dir='rtl'].lg\:rtl\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:py-96,[dir='rtl'].lg\:rtl\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:px-96,[dir='rtl'].lg\:rtl\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:py-128,[dir='rtl'].lg\:rtl\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:px-128,[dir='rtl'].lg\:rtl\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:py-136,[dir='rtl'].lg\:rtl\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:px-136,[dir='rtl'].lg\:rtl\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:py-160,[dir='rtl'].lg\:rtl\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  [dir='rtl'] .lg\:rtl\:px-160,[dir='rtl'].lg\:rtl\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  [dir='rtl'] .lg\:rtl\:py-192,[dir='rtl'].lg\:rtl\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:px-192,[dir='rtl'].lg\:rtl\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:py-200,[dir='rtl'].lg\:rtl\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  [dir='rtl'] .lg\:rtl\:px-200,[dir='rtl'].lg\:rtl\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  [dir='rtl'] .lg\:rtl\:py-208,[dir='rtl'].lg\:rtl\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:px-208,[dir='rtl'].lg\:rtl\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:py-216,[dir='rtl'].lg\:rtl\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:px-216,[dir='rtl'].lg\:rtl\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:py-224,[dir='rtl'].lg\:rtl\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:px-224,[dir='rtl'].lg\:rtl\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:py-256,[dir='rtl'].lg\:rtl\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:px-256,[dir='rtl'].lg\:rtl\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:py-288,[dir='rtl'].lg\:rtl\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:px-288,[dir='rtl'].lg\:rtl\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:py-320,[dir='rtl'].lg\:rtl\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='rtl'] .lg\:rtl\:px-320,[dir='rtl'].lg\:rtl\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='rtl'] .lg\:rtl\:py-360,[dir='rtl'].lg\:rtl\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  [dir='rtl'] .lg\:rtl\:px-360,[dir='rtl'].lg\:rtl\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  [dir='rtl'] .lg\:rtl\:py-384,[dir='rtl'].lg\:rtl\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:px-384,[dir='rtl'].lg\:rtl\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:py-400,[dir='rtl'].lg\:rtl\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  [dir='rtl'] .lg\:rtl\:px-400,[dir='rtl'].lg\:rtl\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  [dir='rtl'] .lg\:rtl\:py-512,[dir='rtl'].lg\:rtl\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:px-512,[dir='rtl'].lg\:rtl\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:py-640,[dir='rtl'].lg\:rtl\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='rtl'] .lg\:rtl\:px-640,[dir='rtl'].lg\:rtl\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='rtl'] .lg\:rtl\:py-xs,[dir='rtl'].lg\:rtl\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='rtl'] .lg\:rtl\:px-xs,[dir='rtl'].lg\:rtl\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='rtl'] .lg\:rtl\:py-sm,[dir='rtl'].lg\:rtl\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  [dir='rtl'] .lg\:rtl\:px-sm,[dir='rtl'].lg\:rtl\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  [dir='rtl'] .lg\:rtl\:py-md,[dir='rtl'].lg\:rtl\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='rtl'] .lg\:rtl\:px-md,[dir='rtl'].lg\:rtl\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='rtl'] .lg\:rtl\:py-lg,[dir='rtl'].lg\:rtl\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  [dir='rtl'] .lg\:rtl\:px-lg,[dir='rtl'].lg\:rtl\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  [dir='rtl'] .lg\:rtl\:py-xl,[dir='rtl'].lg\:rtl\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  [dir='rtl'] .lg\:rtl\:px-xl,[dir='rtl'].lg\:rtl\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  [dir='rtl'] .lg\:rtl\:py-2xl,[dir='rtl'].lg\:rtl\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  [dir='rtl'] .lg\:rtl\:px-2xl,[dir='rtl'].lg\:rtl\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  [dir='rtl'] .lg\:rtl\:py-3xl,[dir='rtl'].lg\:rtl\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  [dir='rtl'] .lg\:rtl\:px-3xl,[dir='rtl'].lg\:rtl\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  [dir='rtl'] .lg\:rtl\:py-4xl,[dir='rtl'].lg\:rtl\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  [dir='rtl'] .lg\:rtl\:px-4xl,[dir='rtl'].lg\:rtl\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  [dir='rtl'] .lg\:rtl\:py-5xl,[dir='rtl'].lg\:rtl\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  [dir='rtl'] .lg\:rtl\:px-5xl,[dir='rtl'].lg\:rtl\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  [dir='rtl'] .lg\:rtl\:py-px,[dir='rtl'].lg\:rtl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  [dir='rtl'] .lg\:rtl\:px-px,[dir='rtl'].lg\:rtl\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  [dir='rtl'] .lg\:rtl\:py-0\.5,[dir='rtl'].lg\:rtl\:py-0\.5 {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:px-0\.5,[dir='rtl'].lg\:rtl\:px-0\.5 {
    padding-left: 0.05rem;
    padding-right: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:py-1\.5,[dir='rtl'].lg\:rtl\:py-1\.5 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:px-1\.5,[dir='rtl'].lg\:rtl\:px-1\.5 {
    padding-left: 0.15rem;
    padding-right: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:py-2\.5,[dir='rtl'].lg\:rtl\:py-2\.5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:px-2\.5,[dir='rtl'].lg\:rtl\:px-2\.5 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:py-3\.5,[dir='rtl'].lg\:rtl\:py-3\.5 {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:px-3\.5,[dir='rtl'].lg\:rtl\:px-3\.5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:pt-0,[dir='rtl'].lg\:rtl\:pt-0 {
    padding-top: 0
  }

  [dir='rtl'] .lg\:rtl\:pr-0,[dir='rtl'].lg\:rtl\:pr-0 {
    padding-right: 0
  }

  [dir='rtl'] .lg\:rtl\:pb-0,[dir='rtl'].lg\:rtl\:pb-0 {
    padding-bottom: 0
  }

  [dir='rtl'] .lg\:rtl\:pl-0,[dir='rtl'].lg\:rtl\:pl-0 {
    padding-left: 0
  }

  [dir='rtl'] .lg\:rtl\:pt-1,[dir='rtl'].lg\:rtl\:pt-1 {
    padding-top: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:pr-1,[dir='rtl'].lg\:rtl\:pr-1 {
    padding-right: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:pb-1,[dir='rtl'].lg\:rtl\:pb-1 {
    padding-bottom: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:pl-1,[dir='rtl'].lg\:rtl\:pl-1 {
    padding-left: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:pt-2,[dir='rtl'].lg\:rtl\:pt-2 {
    padding-top: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:pr-2,[dir='rtl'].lg\:rtl\:pr-2 {
    padding-right: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:pb-2,[dir='rtl'].lg\:rtl\:pb-2 {
    padding-bottom: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:pl-2,[dir='rtl'].lg\:rtl\:pl-2 {
    padding-left: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:pt-3,[dir='rtl'].lg\:rtl\:pt-3 {
    padding-top: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:pr-3,[dir='rtl'].lg\:rtl\:pr-3 {
    padding-right: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:pb-3,[dir='rtl'].lg\:rtl\:pb-3 {
    padding-bottom: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:pl-3,[dir='rtl'].lg\:rtl\:pl-3 {
    padding-left: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:pt-4,[dir='rtl'].lg\:rtl\:pt-4 {
    padding-top: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:pr-4,[dir='rtl'].lg\:rtl\:pr-4 {
    padding-right: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:pb-4,[dir='rtl'].lg\:rtl\:pb-4 {
    padding-bottom: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:pl-4,[dir='rtl'].lg\:rtl\:pl-4 {
    padding-left: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:pt-5,[dir='rtl'].lg\:rtl\:pt-5 {
    padding-top: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:pr-5,[dir='rtl'].lg\:rtl\:pr-5 {
    padding-right: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:pb-5,[dir='rtl'].lg\:rtl\:pb-5 {
    padding-bottom: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:pl-5,[dir='rtl'].lg\:rtl\:pl-5 {
    padding-left: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:pt-6,[dir='rtl'].lg\:rtl\:pt-6 {
    padding-top: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:pr-6,[dir='rtl'].lg\:rtl\:pr-6 {
    padding-right: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:pb-6,[dir='rtl'].lg\:rtl\:pb-6 {
    padding-bottom: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:pl-6,[dir='rtl'].lg\:rtl\:pl-6 {
    padding-left: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:pt-7,[dir='rtl'].lg\:rtl\:pt-7 {
    padding-top: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:pr-7,[dir='rtl'].lg\:rtl\:pr-7 {
    padding-right: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:pb-7,[dir='rtl'].lg\:rtl\:pb-7 {
    padding-bottom: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:pl-7,[dir='rtl'].lg\:rtl\:pl-7 {
    padding-left: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:pt-8,[dir='rtl'].lg\:rtl\:pt-8 {
    padding-top: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:pr-8,[dir='rtl'].lg\:rtl\:pr-8 {
    padding-right: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:pb-8,[dir='rtl'].lg\:rtl\:pb-8 {
    padding-bottom: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:pl-8,[dir='rtl'].lg\:rtl\:pl-8 {
    padding-left: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:pt-9,[dir='rtl'].lg\:rtl\:pt-9 {
    padding-top: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:pr-9,[dir='rtl'].lg\:rtl\:pr-9 {
    padding-right: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:pb-9,[dir='rtl'].lg\:rtl\:pb-9 {
    padding-bottom: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:pl-9,[dir='rtl'].lg\:rtl\:pl-9 {
    padding-left: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:pt-10,[dir='rtl'].lg\:rtl\:pt-10 {
    padding-top: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:pr-10,[dir='rtl'].lg\:rtl\:pr-10 {
    padding-right: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:pb-10,[dir='rtl'].lg\:rtl\:pb-10 {
    padding-bottom: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:pl-10,[dir='rtl'].lg\:rtl\:pl-10 {
    padding-left: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:pt-12,[dir='rtl'].lg\:rtl\:pt-12 {
    padding-top: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:pr-12,[dir='rtl'].lg\:rtl\:pr-12 {
    padding-right: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:pb-12,[dir='rtl'].lg\:rtl\:pb-12 {
    padding-bottom: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:pl-12,[dir='rtl'].lg\:rtl\:pl-12 {
    padding-left: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:pt-14,[dir='rtl'].lg\:rtl\:pt-14 {
    padding-top: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:pr-14,[dir='rtl'].lg\:rtl\:pr-14 {
    padding-right: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:pb-14,[dir='rtl'].lg\:rtl\:pb-14 {
    padding-bottom: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:pl-14,[dir='rtl'].lg\:rtl\:pl-14 {
    padding-left: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:pt-16,[dir='rtl'].lg\:rtl\:pt-16 {
    padding-top: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:pr-16,[dir='rtl'].lg\:rtl\:pr-16 {
    padding-right: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:pb-16,[dir='rtl'].lg\:rtl\:pb-16 {
    padding-bottom: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:pl-16,[dir='rtl'].lg\:rtl\:pl-16 {
    padding-left: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:pt-20,[dir='rtl'].lg\:rtl\:pt-20 {
    padding-top: 2rem
  }

  [dir='rtl'] .lg\:rtl\:pr-20,[dir='rtl'].lg\:rtl\:pr-20 {
    padding-right: 2rem
  }

  [dir='rtl'] .lg\:rtl\:pb-20,[dir='rtl'].lg\:rtl\:pb-20 {
    padding-bottom: 2rem
  }

  [dir='rtl'] .lg\:rtl\:pl-20,[dir='rtl'].lg\:rtl\:pl-20 {
    padding-left: 2rem
  }

  [dir='rtl'] .lg\:rtl\:pt-24,[dir='rtl'].lg\:rtl\:pt-24 {
    padding-top: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:pr-24,[dir='rtl'].lg\:rtl\:pr-24 {
    padding-right: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:pb-24,[dir='rtl'].lg\:rtl\:pb-24 {
    padding-bottom: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:pl-24,[dir='rtl'].lg\:rtl\:pl-24 {
    padding-left: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:pt-28,[dir='rtl'].lg\:rtl\:pt-28 {
    padding-top: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:pr-28,[dir='rtl'].lg\:rtl\:pr-28 {
    padding-right: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:pb-28,[dir='rtl'].lg\:rtl\:pb-28 {
    padding-bottom: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:pl-28,[dir='rtl'].lg\:rtl\:pl-28 {
    padding-left: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:pt-32,[dir='rtl'].lg\:rtl\:pt-32 {
    padding-top: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:pr-32,[dir='rtl'].lg\:rtl\:pr-32 {
    padding-right: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:pb-32,[dir='rtl'].lg\:rtl\:pb-32 {
    padding-bottom: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:pl-32,[dir='rtl'].lg\:rtl\:pl-32 {
    padding-left: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:pt-36,[dir='rtl'].lg\:rtl\:pt-36 {
    padding-top: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:pr-36,[dir='rtl'].lg\:rtl\:pr-36 {
    padding-right: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:pb-36,[dir='rtl'].lg\:rtl\:pb-36 {
    padding-bottom: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:pl-36,[dir='rtl'].lg\:rtl\:pl-36 {
    padding-left: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:pt-40,[dir='rtl'].lg\:rtl\:pt-40 {
    padding-top: 4rem
  }

  [dir='rtl'] .lg\:rtl\:pr-40,[dir='rtl'].lg\:rtl\:pr-40 {
    padding-right: 4rem
  }

  [dir='rtl'] .lg\:rtl\:pb-40,[dir='rtl'].lg\:rtl\:pb-40 {
    padding-bottom: 4rem
  }

  [dir='rtl'] .lg\:rtl\:pl-40,[dir='rtl'].lg\:rtl\:pl-40 {
    padding-left: 4rem
  }

  [dir='rtl'] .lg\:rtl\:pt-44,[dir='rtl'].lg\:rtl\:pt-44 {
    padding-top: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:pr-44,[dir='rtl'].lg\:rtl\:pr-44 {
    padding-right: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:pb-44,[dir='rtl'].lg\:rtl\:pb-44 {
    padding-bottom: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:pl-44,[dir='rtl'].lg\:rtl\:pl-44 {
    padding-left: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:pt-48,[dir='rtl'].lg\:rtl\:pt-48 {
    padding-top: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:pr-48,[dir='rtl'].lg\:rtl\:pr-48 {
    padding-right: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:pb-48,[dir='rtl'].lg\:rtl\:pb-48 {
    padding-bottom: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:pl-48,[dir='rtl'].lg\:rtl\:pl-48 {
    padding-left: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:pt-52,[dir='rtl'].lg\:rtl\:pt-52 {
    padding-top: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:pr-52,[dir='rtl'].lg\:rtl\:pr-52 {
    padding-right: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:pb-52,[dir='rtl'].lg\:rtl\:pb-52 {
    padding-bottom: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:pl-52,[dir='rtl'].lg\:rtl\:pl-52 {
    padding-left: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:pt-56,[dir='rtl'].lg\:rtl\:pt-56 {
    padding-top: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:pr-56,[dir='rtl'].lg\:rtl\:pr-56 {
    padding-right: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:pb-56,[dir='rtl'].lg\:rtl\:pb-56 {
    padding-bottom: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:pl-56,[dir='rtl'].lg\:rtl\:pl-56 {
    padding-left: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:pt-60,[dir='rtl'].lg\:rtl\:pt-60 {
    padding-top: 6rem
  }

  [dir='rtl'] .lg\:rtl\:pr-60,[dir='rtl'].lg\:rtl\:pr-60 {
    padding-right: 6rem
  }

  [dir='rtl'] .lg\:rtl\:pb-60,[dir='rtl'].lg\:rtl\:pb-60 {
    padding-bottom: 6rem
  }

  [dir='rtl'] .lg\:rtl\:pl-60,[dir='rtl'].lg\:rtl\:pl-60 {
    padding-left: 6rem
  }

  [dir='rtl'] .lg\:rtl\:pt-64,[dir='rtl'].lg\:rtl\:pt-64 {
    padding-top: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:pr-64,[dir='rtl'].lg\:rtl\:pr-64 {
    padding-right: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:pb-64,[dir='rtl'].lg\:rtl\:pb-64 {
    padding-bottom: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:pl-64,[dir='rtl'].lg\:rtl\:pl-64 {
    padding-left: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:pt-68,[dir='rtl'].lg\:rtl\:pt-68 {
    padding-top: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:pr-68,[dir='rtl'].lg\:rtl\:pr-68 {
    padding-right: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:pb-68,[dir='rtl'].lg\:rtl\:pb-68 {
    padding-bottom: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:pl-68,[dir='rtl'].lg\:rtl\:pl-68 {
    padding-left: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:pt-72,[dir='rtl'].lg\:rtl\:pt-72 {
    padding-top: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:pr-72,[dir='rtl'].lg\:rtl\:pr-72 {
    padding-right: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:pb-72,[dir='rtl'].lg\:rtl\:pb-72 {
    padding-bottom: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:pl-72,[dir='rtl'].lg\:rtl\:pl-72 {
    padding-left: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:pt-76,[dir='rtl'].lg\:rtl\:pt-76 {
    padding-top: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:pr-76,[dir='rtl'].lg\:rtl\:pr-76 {
    padding-right: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:pb-76,[dir='rtl'].lg\:rtl\:pb-76 {
    padding-bottom: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:pl-76,[dir='rtl'].lg\:rtl\:pl-76 {
    padding-left: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:pt-80,[dir='rtl'].lg\:rtl\:pt-80 {
    padding-top: 8rem
  }

  [dir='rtl'] .lg\:rtl\:pr-80,[dir='rtl'].lg\:rtl\:pr-80 {
    padding-right: 8rem
  }

  [dir='rtl'] .lg\:rtl\:pb-80,[dir='rtl'].lg\:rtl\:pb-80 {
    padding-bottom: 8rem
  }

  [dir='rtl'] .lg\:rtl\:pl-80,[dir='rtl'].lg\:rtl\:pl-80 {
    padding-left: 8rem
  }

  [dir='rtl'] .lg\:rtl\:pt-84,[dir='rtl'].lg\:rtl\:pt-84 {
    padding-top: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:pr-84,[dir='rtl'].lg\:rtl\:pr-84 {
    padding-right: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:pb-84,[dir='rtl'].lg\:rtl\:pb-84 {
    padding-bottom: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:pl-84,[dir='rtl'].lg\:rtl\:pl-84 {
    padding-left: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:pt-88,[dir='rtl'].lg\:rtl\:pt-88 {
    padding-top: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:pr-88,[dir='rtl'].lg\:rtl\:pr-88 {
    padding-right: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:pb-88,[dir='rtl'].lg\:rtl\:pb-88 {
    padding-bottom: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:pl-88,[dir='rtl'].lg\:rtl\:pl-88 {
    padding-left: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:pt-92,[dir='rtl'].lg\:rtl\:pt-92 {
    padding-top: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:pr-92,[dir='rtl'].lg\:rtl\:pr-92 {
    padding-right: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:pb-92,[dir='rtl'].lg\:rtl\:pb-92 {
    padding-bottom: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:pl-92,[dir='rtl'].lg\:rtl\:pl-92 {
    padding-left: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:pt-96,[dir='rtl'].lg\:rtl\:pt-96 {
    padding-top: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:pr-96,[dir='rtl'].lg\:rtl\:pr-96 {
    padding-right: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:pb-96,[dir='rtl'].lg\:rtl\:pb-96 {
    padding-bottom: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:pl-96,[dir='rtl'].lg\:rtl\:pl-96 {
    padding-left: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:pt-128,[dir='rtl'].lg\:rtl\:pt-128 {
    padding-top: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:pr-128,[dir='rtl'].lg\:rtl\:pr-128 {
    padding-right: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:pb-128,[dir='rtl'].lg\:rtl\:pb-128 {
    padding-bottom: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:pl-128,[dir='rtl'].lg\:rtl\:pl-128 {
    padding-left: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:pt-136,[dir='rtl'].lg\:rtl\:pt-136 {
    padding-top: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:pr-136,[dir='rtl'].lg\:rtl\:pr-136 {
    padding-right: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:pb-136,[dir='rtl'].lg\:rtl\:pb-136 {
    padding-bottom: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:pl-136,[dir='rtl'].lg\:rtl\:pl-136 {
    padding-left: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:pt-160,[dir='rtl'].lg\:rtl\:pt-160 {
    padding-top: 16rem
  }

  [dir='rtl'] .lg\:rtl\:pr-160,[dir='rtl'].lg\:rtl\:pr-160 {
    padding-right: 16rem
  }

  [dir='rtl'] .lg\:rtl\:pb-160,[dir='rtl'].lg\:rtl\:pb-160 {
    padding-bottom: 16rem
  }

  [dir='rtl'] .lg\:rtl\:pl-160,[dir='rtl'].lg\:rtl\:pl-160 {
    padding-left: 16rem
  }

  [dir='rtl'] .lg\:rtl\:pt-192,[dir='rtl'].lg\:rtl\:pt-192 {
    padding-top: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:pr-192,[dir='rtl'].lg\:rtl\:pr-192 {
    padding-right: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:pb-192,[dir='rtl'].lg\:rtl\:pb-192 {
    padding-bottom: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:pl-192,[dir='rtl'].lg\:rtl\:pl-192 {
    padding-left: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:pt-200,[dir='rtl'].lg\:rtl\:pt-200 {
    padding-top: 20rem
  }

  [dir='rtl'] .lg\:rtl\:pr-200,[dir='rtl'].lg\:rtl\:pr-200 {
    padding-right: 20rem
  }

  [dir='rtl'] .lg\:rtl\:pb-200,[dir='rtl'].lg\:rtl\:pb-200 {
    padding-bottom: 20rem
  }

  [dir='rtl'] .lg\:rtl\:pl-200,[dir='rtl'].lg\:rtl\:pl-200 {
    padding-left: 20rem
  }

  [dir='rtl'] .lg\:rtl\:pt-208,[dir='rtl'].lg\:rtl\:pt-208 {
    padding-top: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:pr-208,[dir='rtl'].lg\:rtl\:pr-208 {
    padding-right: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:pb-208,[dir='rtl'].lg\:rtl\:pb-208 {
    padding-bottom: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:pl-208,[dir='rtl'].lg\:rtl\:pl-208 {
    padding-left: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:pt-216,[dir='rtl'].lg\:rtl\:pt-216 {
    padding-top: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:pr-216,[dir='rtl'].lg\:rtl\:pr-216 {
    padding-right: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:pb-216,[dir='rtl'].lg\:rtl\:pb-216 {
    padding-bottom: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:pl-216,[dir='rtl'].lg\:rtl\:pl-216 {
    padding-left: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:pt-224,[dir='rtl'].lg\:rtl\:pt-224 {
    padding-top: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:pr-224,[dir='rtl'].lg\:rtl\:pr-224 {
    padding-right: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:pb-224,[dir='rtl'].lg\:rtl\:pb-224 {
    padding-bottom: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:pl-224,[dir='rtl'].lg\:rtl\:pl-224 {
    padding-left: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:pt-256,[dir='rtl'].lg\:rtl\:pt-256 {
    padding-top: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:pr-256,[dir='rtl'].lg\:rtl\:pr-256 {
    padding-right: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:pb-256,[dir='rtl'].lg\:rtl\:pb-256 {
    padding-bottom: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:pl-256,[dir='rtl'].lg\:rtl\:pl-256 {
    padding-left: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:pt-288,[dir='rtl'].lg\:rtl\:pt-288 {
    padding-top: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:pr-288,[dir='rtl'].lg\:rtl\:pr-288 {
    padding-right: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:pb-288,[dir='rtl'].lg\:rtl\:pb-288 {
    padding-bottom: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:pl-288,[dir='rtl'].lg\:rtl\:pl-288 {
    padding-left: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:pt-320,[dir='rtl'].lg\:rtl\:pt-320 {
    padding-top: 32rem
  }

  [dir='rtl'] .lg\:rtl\:pr-320,[dir='rtl'].lg\:rtl\:pr-320 {
    padding-right: 32rem
  }

  [dir='rtl'] .lg\:rtl\:pb-320,[dir='rtl'].lg\:rtl\:pb-320 {
    padding-bottom: 32rem
  }

  [dir='rtl'] .lg\:rtl\:pl-320,[dir='rtl'].lg\:rtl\:pl-320 {
    padding-left: 32rem
  }

  [dir='rtl'] .lg\:rtl\:pt-360,[dir='rtl'].lg\:rtl\:pt-360 {
    padding-top: 36rem
  }

  [dir='rtl'] .lg\:rtl\:pr-360,[dir='rtl'].lg\:rtl\:pr-360 {
    padding-right: 36rem
  }

  [dir='rtl'] .lg\:rtl\:pb-360,[dir='rtl'].lg\:rtl\:pb-360 {
    padding-bottom: 36rem
  }

  [dir='rtl'] .lg\:rtl\:pl-360,[dir='rtl'].lg\:rtl\:pl-360 {
    padding-left: 36rem
  }

  [dir='rtl'] .lg\:rtl\:pt-384,[dir='rtl'].lg\:rtl\:pt-384 {
    padding-top: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:pr-384,[dir='rtl'].lg\:rtl\:pr-384 {
    padding-right: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:pb-384,[dir='rtl'].lg\:rtl\:pb-384 {
    padding-bottom: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:pl-384,[dir='rtl'].lg\:rtl\:pl-384 {
    padding-left: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:pt-400,[dir='rtl'].lg\:rtl\:pt-400 {
    padding-top: 40rem
  }

  [dir='rtl'] .lg\:rtl\:pr-400,[dir='rtl'].lg\:rtl\:pr-400 {
    padding-right: 40rem
  }

  [dir='rtl'] .lg\:rtl\:pb-400,[dir='rtl'].lg\:rtl\:pb-400 {
    padding-bottom: 40rem
  }

  [dir='rtl'] .lg\:rtl\:pl-400,[dir='rtl'].lg\:rtl\:pl-400 {
    padding-left: 40rem
  }

  [dir='rtl'] .lg\:rtl\:pt-512,[dir='rtl'].lg\:rtl\:pt-512 {
    padding-top: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:pr-512,[dir='rtl'].lg\:rtl\:pr-512 {
    padding-right: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:pb-512,[dir='rtl'].lg\:rtl\:pb-512 {
    padding-bottom: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:pl-512,[dir='rtl'].lg\:rtl\:pl-512 {
    padding-left: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:pt-640,[dir='rtl'].lg\:rtl\:pt-640 {
    padding-top: 64rem
  }

  [dir='rtl'] .lg\:rtl\:pr-640,[dir='rtl'].lg\:rtl\:pr-640 {
    padding-right: 64rem
  }

  [dir='rtl'] .lg\:rtl\:pb-640,[dir='rtl'].lg\:rtl\:pb-640 {
    padding-bottom: 64rem
  }

  [dir='rtl'] .lg\:rtl\:pl-640,[dir='rtl'].lg\:rtl\:pl-640 {
    padding-left: 64rem
  }

  [dir='rtl'] .lg\:rtl\:pt-xs,[dir='rtl'].lg\:rtl\:pt-xs {
    padding-top: 32rem
  }

  [dir='rtl'] .lg\:rtl\:pr-xs,[dir='rtl'].lg\:rtl\:pr-xs {
    padding-right: 32rem
  }

  [dir='rtl'] .lg\:rtl\:pb-xs,[dir='rtl'].lg\:rtl\:pb-xs {
    padding-bottom: 32rem
  }

  [dir='rtl'] .lg\:rtl\:pl-xs,[dir='rtl'].lg\:rtl\:pl-xs {
    padding-left: 32rem
  }

  [dir='rtl'] .lg\:rtl\:pt-sm,[dir='rtl'].lg\:rtl\:pt-sm {
    padding-top: 48rem
  }

  [dir='rtl'] .lg\:rtl\:pr-sm,[dir='rtl'].lg\:rtl\:pr-sm {
    padding-right: 48rem
  }

  [dir='rtl'] .lg\:rtl\:pb-sm,[dir='rtl'].lg\:rtl\:pb-sm {
    padding-bottom: 48rem
  }

  [dir='rtl'] .lg\:rtl\:pl-sm,[dir='rtl'].lg\:rtl\:pl-sm {
    padding-left: 48rem
  }

  [dir='rtl'] .lg\:rtl\:pt-md,[dir='rtl'].lg\:rtl\:pt-md {
    padding-top: 64rem
  }

  [dir='rtl'] .lg\:rtl\:pr-md,[dir='rtl'].lg\:rtl\:pr-md {
    padding-right: 64rem
  }

  [dir='rtl'] .lg\:rtl\:pb-md,[dir='rtl'].lg\:rtl\:pb-md {
    padding-bottom: 64rem
  }

  [dir='rtl'] .lg\:rtl\:pl-md,[dir='rtl'].lg\:rtl\:pl-md {
    padding-left: 64rem
  }

  [dir='rtl'] .lg\:rtl\:pt-lg,[dir='rtl'].lg\:rtl\:pt-lg {
    padding-top: 80rem
  }

  [dir='rtl'] .lg\:rtl\:pr-lg,[dir='rtl'].lg\:rtl\:pr-lg {
    padding-right: 80rem
  }

  [dir='rtl'] .lg\:rtl\:pb-lg,[dir='rtl'].lg\:rtl\:pb-lg {
    padding-bottom: 80rem
  }

  [dir='rtl'] .lg\:rtl\:pl-lg,[dir='rtl'].lg\:rtl\:pl-lg {
    padding-left: 80rem
  }

  [dir='rtl'] .lg\:rtl\:pt-xl,[dir='rtl'].lg\:rtl\:pt-xl {
    padding-top: 96rem
  }

  [dir='rtl'] .lg\:rtl\:pr-xl,[dir='rtl'].lg\:rtl\:pr-xl {
    padding-right: 96rem
  }

  [dir='rtl'] .lg\:rtl\:pb-xl,[dir='rtl'].lg\:rtl\:pb-xl {
    padding-bottom: 96rem
  }

  [dir='rtl'] .lg\:rtl\:pl-xl,[dir='rtl'].lg\:rtl\:pl-xl {
    padding-left: 96rem
  }

  [dir='rtl'] .lg\:rtl\:pt-2xl,[dir='rtl'].lg\:rtl\:pt-2xl {
    padding-top: 112rem
  }

  [dir='rtl'] .lg\:rtl\:pr-2xl,[dir='rtl'].lg\:rtl\:pr-2xl {
    padding-right: 112rem
  }

  [dir='rtl'] .lg\:rtl\:pb-2xl,[dir='rtl'].lg\:rtl\:pb-2xl {
    padding-bottom: 112rem
  }

  [dir='rtl'] .lg\:rtl\:pl-2xl,[dir='rtl'].lg\:rtl\:pl-2xl {
    padding-left: 112rem
  }

  [dir='rtl'] .lg\:rtl\:pt-3xl,[dir='rtl'].lg\:rtl\:pt-3xl {
    padding-top: 128rem
  }

  [dir='rtl'] .lg\:rtl\:pr-3xl,[dir='rtl'].lg\:rtl\:pr-3xl {
    padding-right: 128rem
  }

  [dir='rtl'] .lg\:rtl\:pb-3xl,[dir='rtl'].lg\:rtl\:pb-3xl {
    padding-bottom: 128rem
  }

  [dir='rtl'] .lg\:rtl\:pl-3xl,[dir='rtl'].lg\:rtl\:pl-3xl {
    padding-left: 128rem
  }

  [dir='rtl'] .lg\:rtl\:pt-4xl,[dir='rtl'].lg\:rtl\:pt-4xl {
    padding-top: 144rem
  }

  [dir='rtl'] .lg\:rtl\:pr-4xl,[dir='rtl'].lg\:rtl\:pr-4xl {
    padding-right: 144rem
  }

  [dir='rtl'] .lg\:rtl\:pb-4xl,[dir='rtl'].lg\:rtl\:pb-4xl {
    padding-bottom: 144rem
  }

  [dir='rtl'] .lg\:rtl\:pl-4xl,[dir='rtl'].lg\:rtl\:pl-4xl {
    padding-left: 144rem
  }

  [dir='rtl'] .lg\:rtl\:pt-5xl,[dir='rtl'].lg\:rtl\:pt-5xl {
    padding-top: 160rem
  }

  [dir='rtl'] .lg\:rtl\:pr-5xl,[dir='rtl'].lg\:rtl\:pr-5xl {
    padding-right: 160rem
  }

  [dir='rtl'] .lg\:rtl\:pb-5xl,[dir='rtl'].lg\:rtl\:pb-5xl {
    padding-bottom: 160rem
  }

  [dir='rtl'] .lg\:rtl\:pl-5xl,[dir='rtl'].lg\:rtl\:pl-5xl {
    padding-left: 160rem
  }

  [dir='rtl'] .lg\:rtl\:pt-px,[dir='rtl'].lg\:rtl\:pt-px {
    padding-top: 1px
  }

  [dir='rtl'] .lg\:rtl\:pr-px,[dir='rtl'].lg\:rtl\:pr-px {
    padding-right: 1px
  }

  [dir='rtl'] .lg\:rtl\:pb-px,[dir='rtl'].lg\:rtl\:pb-px {
    padding-bottom: 1px
  }

  [dir='rtl'] .lg\:rtl\:pl-px,[dir='rtl'].lg\:rtl\:pl-px {
    padding-left: 1px
  }

  [dir='rtl'] .lg\:rtl\:pt-0\.5,[dir='rtl'].lg\:rtl\:pt-0\.5 {
    padding-top: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:pr-0\.5,[dir='rtl'].lg\:rtl\:pr-0\.5 {
    padding-right: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:pb-0\.5,[dir='rtl'].lg\:rtl\:pb-0\.5 {
    padding-bottom: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:pl-0\.5,[dir='rtl'].lg\:rtl\:pl-0\.5 {
    padding-left: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:pt-1\.5,[dir='rtl'].lg\:rtl\:pt-1\.5 {
    padding-top: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:pr-1\.5,[dir='rtl'].lg\:rtl\:pr-1\.5 {
    padding-right: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:pb-1\.5,[dir='rtl'].lg\:rtl\:pb-1\.5 {
    padding-bottom: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:pl-1\.5,[dir='rtl'].lg\:rtl\:pl-1\.5 {
    padding-left: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:pt-2\.5,[dir='rtl'].lg\:rtl\:pt-2\.5 {
    padding-top: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:pr-2\.5,[dir='rtl'].lg\:rtl\:pr-2\.5 {
    padding-right: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:pb-2\.5,[dir='rtl'].lg\:rtl\:pb-2\.5 {
    padding-bottom: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:pl-2\.5,[dir='rtl'].lg\:rtl\:pl-2\.5 {
    padding-left: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:pt-3\.5,[dir='rtl'].lg\:rtl\:pt-3\.5 {
    padding-top: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:pr-3\.5,[dir='rtl'].lg\:rtl\:pr-3\.5 {
    padding-right: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:pb-3\.5,[dir='rtl'].lg\:rtl\:pb-3\.5 {
    padding-bottom: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:pl-3\.5,[dir='rtl'].lg\:rtl\:pl-3\.5 {
    padding-left: 0.35rem
  }

  .lg\:placeholder-transparent::placeholder {
    color: transparent
  }

  .lg\:placeholder-current::placeholder {
    color: currentColor
  }

  .lg\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-grey-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-grey-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-grey-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-grey-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-grey-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-grey-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-grey-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-grey-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-grey-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-grey-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-grey-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-grey-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-grey-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-grey-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-gray-hover::placeholder {
    color: rgba(0, 0, 0, 0.04)
  }

  .lg\:placeholder-gray-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-gray-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-gray-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-gray-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-red::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-red-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-red-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-red-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-red-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-orange-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-orange-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-orange-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-orange-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-orange-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-orange-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-orange-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-orange-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-orange-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-orange-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-orange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-orange-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-orange-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-orange-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-orange-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-orange-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-orange-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-orange-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-orange-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-orange-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-orange-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-orange-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-orange-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-orange-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-orange-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-orange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-orange-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-orange-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-orange-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-orange-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-yellow::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-yellow-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-yellow-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-yellow-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-yellow-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-green-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-green-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-green-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-green-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-green-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-green-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-green-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-green-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-teal-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-teal-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-teal-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-teal-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-teal-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-teal-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-teal-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-teal-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-teal-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-teal-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-teal::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-teal-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-teal-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-teal-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-teal-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-blue-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-blue-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-blue-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-light-blue-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-indigo::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-indigo-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-indigo-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-indigo-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-indigo-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-purple::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-purple-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-purple-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-purple-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-purple-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-purple::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-purple-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-purple-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-purple-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-deep-purple-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-pink::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-pink-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-pink-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-pink-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-pink-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-lime-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-lime-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-lime-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-lime-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-lime-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-lime-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-lime-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-lime-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-lime-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-lime-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-lime::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-lime-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-lime-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-lime-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-lime-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-amber-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-amber-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-amber-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-amber-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-amber-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-amber-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-amber-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-amber-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-amber-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-amber-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-amber::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-amber-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-amber-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-amber-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-amber-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-brown-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-brown-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-brown-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-brown-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-brown-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-brown-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-brown-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-brown-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-brown-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-brown-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-brown::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-brown-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-brown-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-brown-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-brown-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-gray-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-gray-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-gray-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-blue-gray-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-cyan-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-cyan-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-cyan-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-cyan-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-cyan-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-cyan-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-cyan-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-cyan-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-cyan-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-cyan-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-cyan::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-cyan-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-cyan-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-cyan-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-cyan-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent
  }

  .lg\:focus\:placeholder-current:focus::placeholder {
    color: currentColor
  }

  .lg\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-grey-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-grey-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-grey-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-grey-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-grey-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-grey-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-grey-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-grey-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-grey-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-grey-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-grey-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-grey-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-grey-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-grey-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-hover:focus::placeholder {
    color: rgba(0, 0, 0, 0.04)
  }

  .lg\:focus\:placeholder-gray-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-red:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-orange-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-orange-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-orange-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-orange-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-orange-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-orange-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-orange-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-orange-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-orange-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-orange-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-orange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-orange-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-orange-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-orange-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-orange-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-green-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-green-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-green-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-green-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-blue-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-blue-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-blue-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-light-blue-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-purple:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-purple-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-purple-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-purple-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-deep-purple-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-lime-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-lime-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-lime-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-lime-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-lime-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-lime-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-lime-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-lime-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-lime-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-lime-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-lime:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-lime-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-lime-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-lime-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-lime-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-amber-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-amber-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-amber-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-amber-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-amber-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-amber-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-amber-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-amber-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-amber-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-amber-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-amber:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-amber-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-amber-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-amber-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-amber-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-brown-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-brown-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-brown-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-brown-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-brown-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-brown-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-brown-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-brown-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-brown-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-brown-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-brown:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-brown-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-brown-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-brown-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-brown-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-gray-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-gray-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-gray-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-gray-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-cyan-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-cyan-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-cyan-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-cyan-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-cyan-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-cyan-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-cyan-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-cyan-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-cyan-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-cyan-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-cyan:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-cyan-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-cyan-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-cyan-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
  }

  .lg\:focus\:placeholder-cyan-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
  }

  .lg\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0
  }

  .lg\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05
  }

  .lg\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1
  }

  .lg\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2
  }

  .lg\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25
  }

  .lg\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3
  }

  .lg\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4
  }

  .lg\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5
  }

  .lg\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6
  }

  .lg\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7
  }

  .lg\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75
  }

  .lg\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8
  }

  .lg\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9
  }

  .lg\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95
  }

  .lg\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1
  }

  .lg\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0
  }

  .lg\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05
  }

  .lg\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1
  }

  .lg\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2
  }

  .lg\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25
  }

  .lg\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3
  }

  .lg\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4
  }

  .lg\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5
  }

  .lg\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6
  }

  .lg\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7
  }

  .lg\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75
  }

  .lg\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8
  }

  .lg\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9
  }

  .lg\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95
  }

  .lg\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1
  }

  .lg\:pointer-events-none {
    pointer-events: none
  }

  .lg\:pointer-events-auto {
    pointer-events: auto
  }

  .lg\:static {
    position: static
  }

  .lg\:fixed {
    position: fixed
  }

  .lg\:absolute {
    position: absolute
  }

  .lg\:relative {
    position: relative
  }

  .lg\:sticky {
    position: sticky
  }

  .lg\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .lg\:inset-1 {
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem
  }

  .lg\:inset-2 {
    top: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem
  }

  .lg\:inset-3 {
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem
  }

  .lg\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  .lg\:inset-5 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  .lg\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  .lg\:inset-7 {
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
    left: 0.7rem
  }

  .lg\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  .lg\:inset-9 {
    top: 0.9rem;
    right: 0.9rem;
    bottom: 0.9rem;
    left: 0.9rem
  }

  .lg\:inset-10 {
    top: 1.0rem;
    right: 1.0rem;
    bottom: 1.0rem;
    left: 1.0rem
  }

  .lg\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  .lg\:inset-14 {
    top: 1.4rem;
    right: 1.4rem;
    bottom: 1.4rem;
    left: 1.4rem
  }

  .lg\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  .lg\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  .lg\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  .lg\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  .lg\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  .lg\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  .lg\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  .lg\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  .lg\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  .lg\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  .lg\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  .lg\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  .lg\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  .lg\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  .lg\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  .lg\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  .lg\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  .lg\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  .lg\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  .lg\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  .lg\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  .lg\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  .lg\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  .lg\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  .lg\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  .lg\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  .lg\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  .lg\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  .lg\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  .lg\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  .lg\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  .lg\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  .lg\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  .lg\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  .lg\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  .lg\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  .lg\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  .lg\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .lg\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  .lg\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  .lg\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  .lg\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  .lg\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  .lg\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  .lg\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  .lg\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  .lg\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  .lg\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  .lg\:inset-0\.5 {
    top: 0.05rem;
    right: 0.05rem;
    bottom: 0.05rem;
    left: 0.05rem
  }

  .lg\:inset-1\.5 {
    top: 0.15rem;
    right: 0.15rem;
    bottom: 0.15rem;
    left: 0.15rem
  }

  .lg\:inset-2\.5 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  .lg\:inset-3\.5 {
    top: 0.35rem;
    right: 0.35rem;
    bottom: 0.35rem;
    left: 0.35rem
  }

  .lg\:-inset-1 {
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem
  }

  .lg\:-inset-2 {
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem
  }

  .lg\:-inset-3 {
    top: -0.3rem;
    right: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem
  }

  .lg\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  .lg\:-inset-5 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  .lg\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  .lg\:-inset-7 {
    top: -0.7rem;
    right: -0.7rem;
    bottom: -0.7rem;
    left: -0.7rem
  }

  .lg\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  .lg\:-inset-9 {
    top: -0.9rem;
    right: -0.9rem;
    bottom: -0.9rem;
    left: -0.9rem
  }

  .lg\:-inset-10 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  .lg\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  .lg\:-inset-14 {
    top: -1.4rem;
    right: -1.4rem;
    bottom: -1.4rem;
    left: -1.4rem
  }

  .lg\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  .lg\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  .lg\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  .lg\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  .lg\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  .lg\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  .lg\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  .lg\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  .lg\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  .lg\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  .lg\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  .lg\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  .lg\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  .lg\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  .lg\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  .lg\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  .lg\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  .lg\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  .lg\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  .lg\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  .lg\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  .lg\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  .lg\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  .lg\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  .lg\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  .lg\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  .lg\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  .lg\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  .lg\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  .lg\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  .lg\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  .lg\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  .lg\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  .lg\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  .lg\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  .lg\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  .lg\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  .lg\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  .lg\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  .lg\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  .lg\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  .lg\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  .lg\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  .lg\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  .lg\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  .lg\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  .lg\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  .lg\:-inset-0\.5 {
    top: -0.05rem;
    right: -0.05rem;
    bottom: -0.05rem;
    left: -0.05rem
  }

  .lg\:-inset-1\.5 {
    top: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    left: -0.15rem
  }

  .lg\:-inset-2\.5 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  .lg\:-inset-3\.5 {
    top: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    left: -0.35rem
  }

  .lg\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  .lg\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  .lg\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  .lg\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  .lg\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  .lg\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  .lg\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  .lg\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  .lg\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  .lg\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  .lg\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  .lg\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  .lg\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  .lg\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  .lg\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .lg\:inset-x-0 {
    right: 0;
    left: 0
  }

  .lg\:inset-y-1 {
    top: 0.1rem;
    bottom: 0.1rem
  }

  .lg\:inset-x-1 {
    right: 0.1rem;
    left: 0.1rem
  }

  .lg\:inset-y-2 {
    top: 0.2rem;
    bottom: 0.2rem
  }

  .lg\:inset-x-2 {
    right: 0.2rem;
    left: 0.2rem
  }

  .lg\:inset-y-3 {
    top: 0.3rem;
    bottom: 0.3rem
  }

  .lg\:inset-x-3 {
    right: 0.3rem;
    left: 0.3rem
  }

  .lg\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  .lg\:inset-x-4 {
    right: 0.4rem;
    left: 0.4rem
  }

  .lg\:inset-y-5 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  .lg\:inset-x-5 {
    right: 0.5rem;
    left: 0.5rem
  }

  .lg\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  .lg\:inset-x-6 {
    right: 0.6rem;
    left: 0.6rem
  }

  .lg\:inset-y-7 {
    top: 0.7rem;
    bottom: 0.7rem
  }

  .lg\:inset-x-7 {
    right: 0.7rem;
    left: 0.7rem
  }

  .lg\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  .lg\:inset-x-8 {
    right: 0.8rem;
    left: 0.8rem
  }

  .lg\:inset-y-9 {
    top: 0.9rem;
    bottom: 0.9rem
  }

  .lg\:inset-x-9 {
    right: 0.9rem;
    left: 0.9rem
  }

  .lg\:inset-y-10 {
    top: 1.0rem;
    bottom: 1.0rem
  }

  .lg\:inset-x-10 {
    right: 1.0rem;
    left: 1.0rem
  }

  .lg\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  .lg\:inset-x-12 {
    right: 1.2rem;
    left: 1.2rem
  }

  .lg\:inset-y-14 {
    top: 1.4rem;
    bottom: 1.4rem
  }

  .lg\:inset-x-14 {
    right: 1.4rem;
    left: 1.4rem
  }

  .lg\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  .lg\:inset-x-16 {
    right: 1.6rem;
    left: 1.6rem
  }

  .lg\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  .lg\:inset-x-20 {
    right: 2rem;
    left: 2rem
  }

  .lg\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  .lg\:inset-x-24 {
    right: 2.4rem;
    left: 2.4rem
  }

  .lg\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  .lg\:inset-x-28 {
    right: 2.8rem;
    left: 2.8rem
  }

  .lg\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  .lg\:inset-x-32 {
    right: 3.2rem;
    left: 3.2rem
  }

  .lg\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  .lg\:inset-x-36 {
    right: 3.6rem;
    left: 3.6rem
  }

  .lg\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  .lg\:inset-x-40 {
    right: 4rem;
    left: 4rem
  }

  .lg\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  .lg\:inset-x-44 {
    right: 4.4rem;
    left: 4.4rem
  }

  .lg\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  .lg\:inset-x-48 {
    right: 4.8rem;
    left: 4.8rem
  }

  .lg\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  .lg\:inset-x-52 {
    right: 5.2rem;
    left: 5.2rem
  }

  .lg\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  .lg\:inset-x-56 {
    right: 5.6rem;
    left: 5.6rem
  }

  .lg\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  .lg\:inset-x-60 {
    right: 6rem;
    left: 6rem
  }

  .lg\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  .lg\:inset-x-64 {
    right: 6.4rem;
    left: 6.4rem
  }

  .lg\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  .lg\:inset-x-68 {
    right: 6.8rem;
    left: 6.8rem
  }

  .lg\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  .lg\:inset-x-72 {
    right: 7.2rem;
    left: 7.2rem
  }

  .lg\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  .lg\:inset-x-76 {
    right: 7.6rem;
    left: 7.6rem
  }

  .lg\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  .lg\:inset-x-80 {
    right: 8rem;
    left: 8rem
  }

  .lg\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  .lg\:inset-x-84 {
    right: 8.4rem;
    left: 8.4rem
  }

  .lg\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  .lg\:inset-x-88 {
    right: 8.8rem;
    left: 8.8rem
  }

  .lg\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  .lg\:inset-x-92 {
    right: 9.2rem;
    left: 9.2rem
  }

  .lg\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  .lg\:inset-x-96 {
    right: 9.6rem;
    left: 9.6rem
  }

  .lg\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  .lg\:inset-x-128 {
    right: 12.8rem;
    left: 12.8rem
  }

  .lg\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  .lg\:inset-x-136 {
    right: 13.6rem;
    left: 13.6rem
  }

  .lg\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  .lg\:inset-x-160 {
    right: 16rem;
    left: 16rem
  }

  .lg\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  .lg\:inset-x-192 {
    right: 19.2rem;
    left: 19.2rem
  }

  .lg\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  .lg\:inset-x-200 {
    right: 20rem;
    left: 20rem
  }

  .lg\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  .lg\:inset-x-208 {
    right: 20.8rem;
    left: 20.8rem
  }

  .lg\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  .lg\:inset-x-216 {
    right: 21.6rem;
    left: 21.6rem
  }

  .lg\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  .lg\:inset-x-224 {
    right: 22.4rem;
    left: 22.4rem
  }

  .lg\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  .lg\:inset-x-256 {
    right: 25.6rem;
    left: 25.6rem
  }

  .lg\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  .lg\:inset-x-288 {
    right: 28.8rem;
    left: 28.8rem
  }

  .lg\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  .lg\:inset-x-320 {
    right: 32rem;
    left: 32rem
  }

  .lg\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  .lg\:inset-x-360 {
    right: 36rem;
    left: 36rem
  }

  .lg\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  .lg\:inset-x-384 {
    right: 38.4rem;
    left: 38.4rem
  }

  .lg\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  .lg\:inset-x-400 {
    right: 40rem;
    left: 40rem
  }

  .lg\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  .lg\:inset-x-512 {
    right: 51.2rem;
    left: 51.2rem
  }

  .lg\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  .lg\:inset-x-640 {
    right: 64rem;
    left: 64rem
  }

  .lg\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .lg\:inset-x-auto {
    right: auto;
    left: auto
  }

  .lg\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  .lg\:inset-x-xs {
    right: 32rem;
    left: 32rem
  }

  .lg\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  .lg\:inset-x-sm {
    right: 48rem;
    left: 48rem
  }

  .lg\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  .lg\:inset-x-md {
    right: 64rem;
    left: 64rem
  }

  .lg\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  .lg\:inset-x-lg {
    right: 80rem;
    left: 80rem
  }

  .lg\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  .lg\:inset-x-xl {
    right: 96rem;
    left: 96rem
  }

  .lg\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  .lg\:inset-x-2xl {
    right: 112rem;
    left: 112rem
  }

  .lg\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  .lg\:inset-x-3xl {
    right: 128rem;
    left: 128rem
  }

  .lg\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  .lg\:inset-x-4xl {
    right: 144rem;
    left: 144rem
  }

  .lg\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  .lg\:inset-x-5xl {
    right: 160rem;
    left: 160rem
  }

  .lg\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  .lg\:inset-x-px {
    right: 1px;
    left: 1px
  }

  .lg\:inset-y-0\.5 {
    top: 0.05rem;
    bottom: 0.05rem
  }

  .lg\:inset-x-0\.5 {
    right: 0.05rem;
    left: 0.05rem
  }

  .lg\:inset-y-1\.5 {
    top: 0.15rem;
    bottom: 0.15rem
  }

  .lg\:inset-x-1\.5 {
    right: 0.15rem;
    left: 0.15rem
  }

  .lg\:inset-y-2\.5 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  .lg\:inset-x-2\.5 {
    right: 0.25rem;
    left: 0.25rem
  }

  .lg\:inset-y-3\.5 {
    top: 0.35rem;
    bottom: 0.35rem
  }

  .lg\:inset-x-3\.5 {
    right: 0.35rem;
    left: 0.35rem
  }

  .lg\:-inset-y-1 {
    top: -0.1rem;
    bottom: -0.1rem
  }

  .lg\:-inset-x-1 {
    right: -0.1rem;
    left: -0.1rem
  }

  .lg\:-inset-y-2 {
    top: -0.2rem;
    bottom: -0.2rem
  }

  .lg\:-inset-x-2 {
    right: -0.2rem;
    left: -0.2rem
  }

  .lg\:-inset-y-3 {
    top: -0.3rem;
    bottom: -0.3rem
  }

  .lg\:-inset-x-3 {
    right: -0.3rem;
    left: -0.3rem
  }

  .lg\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  .lg\:-inset-x-4 {
    right: -0.4rem;
    left: -0.4rem
  }

  .lg\:-inset-y-5 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  .lg\:-inset-x-5 {
    right: -0.5rem;
    left: -0.5rem
  }

  .lg\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  .lg\:-inset-x-6 {
    right: -0.6rem;
    left: -0.6rem
  }

  .lg\:-inset-y-7 {
    top: -0.7rem;
    bottom: -0.7rem
  }

  .lg\:-inset-x-7 {
    right: -0.7rem;
    left: -0.7rem
  }

  .lg\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  .lg\:-inset-x-8 {
    right: -0.8rem;
    left: -0.8rem
  }

  .lg\:-inset-y-9 {
    top: -0.9rem;
    bottom: -0.9rem
  }

  .lg\:-inset-x-9 {
    right: -0.9rem;
    left: -0.9rem
  }

  .lg\:-inset-y-10 {
    top: -1rem;
    bottom: -1rem
  }

  .lg\:-inset-x-10 {
    right: -1rem;
    left: -1rem
  }

  .lg\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  .lg\:-inset-x-12 {
    right: -1.2rem;
    left: -1.2rem
  }

  .lg\:-inset-y-14 {
    top: -1.4rem;
    bottom: -1.4rem
  }

  .lg\:-inset-x-14 {
    right: -1.4rem;
    left: -1.4rem
  }

  .lg\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  .lg\:-inset-x-16 {
    right: -1.6rem;
    left: -1.6rem
  }

  .lg\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  .lg\:-inset-x-20 {
    right: -2rem;
    left: -2rem
  }

  .lg\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  .lg\:-inset-x-24 {
    right: -2.4rem;
    left: -2.4rem
  }

  .lg\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  .lg\:-inset-x-28 {
    right: -2.8rem;
    left: -2.8rem
  }

  .lg\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  .lg\:-inset-x-32 {
    right: -3.2rem;
    left: -3.2rem
  }

  .lg\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  .lg\:-inset-x-36 {
    right: -3.6rem;
    left: -3.6rem
  }

  .lg\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  .lg\:-inset-x-40 {
    right: -4rem;
    left: -4rem
  }

  .lg\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  .lg\:-inset-x-44 {
    right: -4.4rem;
    left: -4.4rem
  }

  .lg\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  .lg\:-inset-x-48 {
    right: -4.8rem;
    left: -4.8rem
  }

  .lg\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  .lg\:-inset-x-52 {
    right: -5.2rem;
    left: -5.2rem
  }

  .lg\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  .lg\:-inset-x-56 {
    right: -5.6rem;
    left: -5.6rem
  }

  .lg\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  .lg\:-inset-x-60 {
    right: -6rem;
    left: -6rem
  }

  .lg\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  .lg\:-inset-x-64 {
    right: -6.4rem;
    left: -6.4rem
  }

  .lg\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  .lg\:-inset-x-68 {
    right: -6.8rem;
    left: -6.8rem
  }

  .lg\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  .lg\:-inset-x-72 {
    right: -7.2rem;
    left: -7.2rem
  }

  .lg\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  .lg\:-inset-x-76 {
    right: -7.6rem;
    left: -7.6rem
  }

  .lg\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  .lg\:-inset-x-80 {
    right: -8rem;
    left: -8rem
  }

  .lg\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  .lg\:-inset-x-84 {
    right: -8.4rem;
    left: -8.4rem
  }

  .lg\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  .lg\:-inset-x-88 {
    right: -8.8rem;
    left: -8.8rem
  }

  .lg\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  .lg\:-inset-x-92 {
    right: -9.2rem;
    left: -9.2rem
  }

  .lg\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  .lg\:-inset-x-96 {
    right: -9.6rem;
    left: -9.6rem
  }

  .lg\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  .lg\:-inset-x-128 {
    right: -12.8rem;
    left: -12.8rem
  }

  .lg\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  .lg\:-inset-x-136 {
    right: -13.6rem;
    left: -13.6rem
  }

  .lg\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  .lg\:-inset-x-160 {
    right: -16rem;
    left: -16rem
  }

  .lg\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  .lg\:-inset-x-192 {
    right: -19.2rem;
    left: -19.2rem
  }

  .lg\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  .lg\:-inset-x-200 {
    right: -20rem;
    left: -20rem
  }

  .lg\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  .lg\:-inset-x-208 {
    right: -20.8rem;
    left: -20.8rem
  }

  .lg\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  .lg\:-inset-x-216 {
    right: -21.6rem;
    left: -21.6rem
  }

  .lg\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  .lg\:-inset-x-224 {
    right: -22.4rem;
    left: -22.4rem
  }

  .lg\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  .lg\:-inset-x-256 {
    right: -25.6rem;
    left: -25.6rem
  }

  .lg\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  .lg\:-inset-x-288 {
    right: -28.8rem;
    left: -28.8rem
  }

  .lg\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  .lg\:-inset-x-320 {
    right: -32rem;
    left: -32rem
  }

  .lg\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  .lg\:-inset-x-360 {
    right: -36rem;
    left: -36rem
  }

  .lg\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  .lg\:-inset-x-384 {
    right: -38.4rem;
    left: -38.4rem
  }

  .lg\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  .lg\:-inset-x-400 {
    right: -40rem;
    left: -40rem
  }

  .lg\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  .lg\:-inset-x-512 {
    right: -51.2rem;
    left: -51.2rem
  }

  .lg\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  .lg\:-inset-x-640 {
    right: -64rem;
    left: -64rem
  }

  .lg\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  .lg\:-inset-x-xs {
    right: -32rem;
    left: -32rem
  }

  .lg\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  .lg\:-inset-x-sm {
    right: -48rem;
    left: -48rem
  }

  .lg\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  .lg\:-inset-x-md {
    right: -64rem;
    left: -64rem
  }

  .lg\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  .lg\:-inset-x-lg {
    right: -80rem;
    left: -80rem
  }

  .lg\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  .lg\:-inset-x-xl {
    right: -96rem;
    left: -96rem
  }

  .lg\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  .lg\:-inset-x-2xl {
    right: -112rem;
    left: -112rem
  }

  .lg\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  .lg\:-inset-x-3xl {
    right: -128rem;
    left: -128rem
  }

  .lg\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  .lg\:-inset-x-4xl {
    right: -144rem;
    left: -144rem
  }

  .lg\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  .lg\:-inset-x-5xl {
    right: -160rem;
    left: -160rem
  }

  .lg\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  .lg\:-inset-x-px {
    right: -1px;
    left: -1px
  }

  .lg\:-inset-y-0\.5 {
    top: -0.05rem;
    bottom: -0.05rem
  }

  .lg\:-inset-x-0\.5 {
    right: -0.05rem;
    left: -0.05rem
  }

  .lg\:-inset-y-1\.5 {
    top: -0.15rem;
    bottom: -0.15rem
  }

  .lg\:-inset-x-1\.5 {
    right: -0.15rem;
    left: -0.15rem
  }

  .lg\:-inset-y-2\.5 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  .lg\:-inset-x-2\.5 {
    right: -0.25rem;
    left: -0.25rem
  }

  .lg\:-inset-y-3\.5 {
    top: -0.35rem;
    bottom: -0.35rem
  }

  .lg\:-inset-x-3\.5 {
    right: -0.35rem;
    left: -0.35rem
  }

  .lg\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  .lg\:inset-x-1\/2 {
    right: 50%;
    left: 50%
  }

  .lg\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  .lg\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%
  }

  .lg\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  .lg\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%
  }

  .lg\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  .lg\:inset-x-1\/4 {
    right: 25%;
    left: 25%
  }

  .lg\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  .lg\:inset-x-2\/4 {
    right: 50%;
    left: 50%
  }

  .lg\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  .lg\:inset-x-3\/4 {
    right: 75%;
    left: 75%
  }

  .lg\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  .lg\:inset-x-full {
    right: 100%;
    left: 100%
  }

  .lg\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  .lg\:-inset-x-1\/2 {
    right: -50%;
    left: -50%
  }

  .lg\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  .lg\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%
  }

  .lg\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  .lg\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%
  }

  .lg\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  .lg\:-inset-x-1\/4 {
    right: -25%;
    left: -25%
  }

  .lg\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  .lg\:-inset-x-2\/4 {
    right: -50%;
    left: -50%
  }

  .lg\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  .lg\:-inset-x-3\/4 {
    right: -75%;
    left: -75%
  }

  .lg\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  .lg\:-inset-x-full {
    right: -100%;
    left: -100%
  }

  .lg\:top-0 {
    top: 0
  }

  .lg\:right-0 {
    right: 0
  }

  .lg\:bottom-0 {
    bottom: 0
  }

  .lg\:left-0 {
    left: 0
  }

  .lg\:top-1 {
    top: 0.1rem
  }

  .lg\:right-1 {
    right: 0.1rem
  }

  .lg\:bottom-1 {
    bottom: 0.1rem
  }

  .lg\:left-1 {
    left: 0.1rem
  }

  .lg\:top-2 {
    top: 0.2rem
  }

  .lg\:right-2 {
    right: 0.2rem
  }

  .lg\:bottom-2 {
    bottom: 0.2rem
  }

  .lg\:left-2 {
    left: 0.2rem
  }

  .lg\:top-3 {
    top: 0.3rem
  }

  .lg\:right-3 {
    right: 0.3rem
  }

  .lg\:bottom-3 {
    bottom: 0.3rem
  }

  .lg\:left-3 {
    left: 0.3rem
  }

  .lg\:top-4 {
    top: 0.4rem
  }

  .lg\:right-4 {
    right: 0.4rem
  }

  .lg\:bottom-4 {
    bottom: 0.4rem
  }

  .lg\:left-4 {
    left: 0.4rem
  }

  .lg\:top-5 {
    top: 0.5rem
  }

  .lg\:right-5 {
    right: 0.5rem
  }

  .lg\:bottom-5 {
    bottom: 0.5rem
  }

  .lg\:left-5 {
    left: 0.5rem
  }

  .lg\:top-6 {
    top: 0.6rem
  }

  .lg\:right-6 {
    right: 0.6rem
  }

  .lg\:bottom-6 {
    bottom: 0.6rem
  }

  .lg\:left-6 {
    left: 0.6rem
  }

  .lg\:top-7 {
    top: 0.7rem
  }

  .lg\:right-7 {
    right: 0.7rem
  }

  .lg\:bottom-7 {
    bottom: 0.7rem
  }

  .lg\:left-7 {
    left: 0.7rem
  }

  .lg\:top-8 {
    top: 0.8rem
  }

  .lg\:right-8 {
    right: 0.8rem
  }

  .lg\:bottom-8 {
    bottom: 0.8rem
  }

  .lg\:left-8 {
    left: 0.8rem
  }

  .lg\:top-9 {
    top: 0.9rem
  }

  .lg\:right-9 {
    right: 0.9rem
  }

  .lg\:bottom-9 {
    bottom: 0.9rem
  }

  .lg\:left-9 {
    left: 0.9rem
  }

  .lg\:top-10 {
    top: 1.0rem
  }

  .lg\:right-10 {
    right: 1.0rem
  }

  .lg\:bottom-10 {
    bottom: 1.0rem
  }

  .lg\:left-10 {
    left: 1.0rem
  }

  .lg\:top-12 {
    top: 1.2rem
  }

  .lg\:right-12 {
    right: 1.2rem
  }

  .lg\:bottom-12 {
    bottom: 1.2rem
  }

  .lg\:left-12 {
    left: 1.2rem
  }

  .lg\:top-14 {
    top: 1.4rem
  }

  .lg\:right-14 {
    right: 1.4rem
  }

  .lg\:bottom-14 {
    bottom: 1.4rem
  }

  .lg\:left-14 {
    left: 1.4rem
  }

  .lg\:top-16 {
    top: 1.6rem
  }

  .lg\:right-16 {
    right: 1.6rem
  }

  .lg\:bottom-16 {
    bottom: 1.6rem
  }

  .lg\:left-16 {
    left: 1.6rem
  }

  .lg\:top-20 {
    top: 2rem
  }

  .lg\:right-20 {
    right: 2rem
  }

  .lg\:bottom-20 {
    bottom: 2rem
  }

  .lg\:left-20 {
    left: 2rem
  }

  .lg\:top-24 {
    top: 2.4rem
  }

  .lg\:right-24 {
    right: 2.4rem
  }

  .lg\:bottom-24 {
    bottom: 2.4rem
  }

  .lg\:left-24 {
    left: 2.4rem
  }

  .lg\:top-28 {
    top: 2.8rem
  }

  .lg\:right-28 {
    right: 2.8rem
  }

  .lg\:bottom-28 {
    bottom: 2.8rem
  }

  .lg\:left-28 {
    left: 2.8rem
  }

  .lg\:top-32 {
    top: 3.2rem
  }

  .lg\:right-32 {
    right: 3.2rem
  }

  .lg\:bottom-32 {
    bottom: 3.2rem
  }

  .lg\:left-32 {
    left: 3.2rem
  }

  .lg\:top-36 {
    top: 3.6rem
  }

  .lg\:right-36 {
    right: 3.6rem
  }

  .lg\:bottom-36 {
    bottom: 3.6rem
  }

  .lg\:left-36 {
    left: 3.6rem
  }

  .lg\:top-40 {
    top: 4rem
  }

  .lg\:right-40 {
    right: 4rem
  }

  .lg\:bottom-40 {
    bottom: 4rem
  }

  .lg\:left-40 {
    left: 4rem
  }

  .lg\:top-44 {
    top: 4.4rem
  }

  .lg\:right-44 {
    right: 4.4rem
  }

  .lg\:bottom-44 {
    bottom: 4.4rem
  }

  .lg\:left-44 {
    left: 4.4rem
  }

  .lg\:top-48 {
    top: 4.8rem
  }

  .lg\:right-48 {
    right: 4.8rem
  }

  .lg\:bottom-48 {
    bottom: 4.8rem
  }

  .lg\:left-48 {
    left: 4.8rem
  }

  .lg\:top-52 {
    top: 5.2rem
  }

  .lg\:right-52 {
    right: 5.2rem
  }

  .lg\:bottom-52 {
    bottom: 5.2rem
  }

  .lg\:left-52 {
    left: 5.2rem
  }

  .lg\:top-56 {
    top: 5.6rem
  }

  .lg\:right-56 {
    right: 5.6rem
  }

  .lg\:bottom-56 {
    bottom: 5.6rem
  }

  .lg\:left-56 {
    left: 5.6rem
  }

  .lg\:top-60 {
    top: 6rem
  }

  .lg\:right-60 {
    right: 6rem
  }

  .lg\:bottom-60 {
    bottom: 6rem
  }

  .lg\:left-60 {
    left: 6rem
  }

  .lg\:top-64 {
    top: 6.4rem
  }

  .lg\:right-64 {
    right: 6.4rem
  }

  .lg\:bottom-64 {
    bottom: 6.4rem
  }

  .lg\:left-64 {
    left: 6.4rem
  }

  .lg\:top-68 {
    top: 6.8rem
  }

  .lg\:right-68 {
    right: 6.8rem
  }

  .lg\:bottom-68 {
    bottom: 6.8rem
  }

  .lg\:left-68 {
    left: 6.8rem
  }

  .lg\:top-72 {
    top: 7.2rem
  }

  .lg\:right-72 {
    right: 7.2rem
  }

  .lg\:bottom-72 {
    bottom: 7.2rem
  }

  .lg\:left-72 {
    left: 7.2rem
  }

  .lg\:top-76 {
    top: 7.6rem
  }

  .lg\:right-76 {
    right: 7.6rem
  }

  .lg\:bottom-76 {
    bottom: 7.6rem
  }

  .lg\:left-76 {
    left: 7.6rem
  }

  .lg\:top-80 {
    top: 8rem
  }

  .lg\:right-80 {
    right: 8rem
  }

  .lg\:bottom-80 {
    bottom: 8rem
  }

  .lg\:left-80 {
    left: 8rem
  }

  .lg\:top-84 {
    top: 8.4rem
  }

  .lg\:right-84 {
    right: 8.4rem
  }

  .lg\:bottom-84 {
    bottom: 8.4rem
  }

  .lg\:left-84 {
    left: 8.4rem
  }

  .lg\:top-88 {
    top: 8.8rem
  }

  .lg\:right-88 {
    right: 8.8rem
  }

  .lg\:bottom-88 {
    bottom: 8.8rem
  }

  .lg\:left-88 {
    left: 8.8rem
  }

  .lg\:top-92 {
    top: 9.2rem
  }

  .lg\:right-92 {
    right: 9.2rem
  }

  .lg\:bottom-92 {
    bottom: 9.2rem
  }

  .lg\:left-92 {
    left: 9.2rem
  }

  .lg\:top-96 {
    top: 9.6rem
  }

  .lg\:right-96 {
    right: 9.6rem
  }

  .lg\:bottom-96 {
    bottom: 9.6rem
  }

  .lg\:left-96 {
    left: 9.6rem
  }

  .lg\:top-128 {
    top: 12.8rem
  }

  .lg\:right-128 {
    right: 12.8rem
  }

  .lg\:bottom-128 {
    bottom: 12.8rem
  }

  .lg\:left-128 {
    left: 12.8rem
  }

  .lg\:top-136 {
    top: 13.6rem
  }

  .lg\:right-136 {
    right: 13.6rem
  }

  .lg\:bottom-136 {
    bottom: 13.6rem
  }

  .lg\:left-136 {
    left: 13.6rem
  }

  .lg\:top-160 {
    top: 16rem
  }

  .lg\:right-160 {
    right: 16rem
  }

  .lg\:bottom-160 {
    bottom: 16rem
  }

  .lg\:left-160 {
    left: 16rem
  }

  .lg\:top-192 {
    top: 19.2rem
  }

  .lg\:right-192 {
    right: 19.2rem
  }

  .lg\:bottom-192 {
    bottom: 19.2rem
  }

  .lg\:left-192 {
    left: 19.2rem
  }

  .lg\:top-200 {
    top: 20rem
  }

  .lg\:right-200 {
    right: 20rem
  }

  .lg\:bottom-200 {
    bottom: 20rem
  }

  .lg\:left-200 {
    left: 20rem
  }

  .lg\:top-208 {
    top: 20.8rem
  }

  .lg\:right-208 {
    right: 20.8rem
  }

  .lg\:bottom-208 {
    bottom: 20.8rem
  }

  .lg\:left-208 {
    left: 20.8rem
  }

  .lg\:top-216 {
    top: 21.6rem
  }

  .lg\:right-216 {
    right: 21.6rem
  }

  .lg\:bottom-216 {
    bottom: 21.6rem
  }

  .lg\:left-216 {
    left: 21.6rem
  }

  .lg\:top-224 {
    top: 22.4rem
  }

  .lg\:right-224 {
    right: 22.4rem
  }

  .lg\:bottom-224 {
    bottom: 22.4rem
  }

  .lg\:left-224 {
    left: 22.4rem
  }

  .lg\:top-256 {
    top: 25.6rem
  }

  .lg\:right-256 {
    right: 25.6rem
  }

  .lg\:bottom-256 {
    bottom: 25.6rem
  }

  .lg\:left-256 {
    left: 25.6rem
  }

  .lg\:top-288 {
    top: 28.8rem
  }

  .lg\:right-288 {
    right: 28.8rem
  }

  .lg\:bottom-288 {
    bottom: 28.8rem
  }

  .lg\:left-288 {
    left: 28.8rem
  }

  .lg\:top-320 {
    top: 32rem
  }

  .lg\:right-320 {
    right: 32rem
  }

  .lg\:bottom-320 {
    bottom: 32rem
  }

  .lg\:left-320 {
    left: 32rem
  }

  .lg\:top-360 {
    top: 36rem
  }

  .lg\:right-360 {
    right: 36rem
  }

  .lg\:bottom-360 {
    bottom: 36rem
  }

  .lg\:left-360 {
    left: 36rem
  }

  .lg\:top-384 {
    top: 38.4rem
  }

  .lg\:right-384 {
    right: 38.4rem
  }

  .lg\:bottom-384 {
    bottom: 38.4rem
  }

  .lg\:left-384 {
    left: 38.4rem
  }

  .lg\:top-400 {
    top: 40rem
  }

  .lg\:right-400 {
    right: 40rem
  }

  .lg\:bottom-400 {
    bottom: 40rem
  }

  .lg\:left-400 {
    left: 40rem
  }

  .lg\:top-512 {
    top: 51.2rem
  }

  .lg\:right-512 {
    right: 51.2rem
  }

  .lg\:bottom-512 {
    bottom: 51.2rem
  }

  .lg\:left-512 {
    left: 51.2rem
  }

  .lg\:top-640 {
    top: 64rem
  }

  .lg\:right-640 {
    right: 64rem
  }

  .lg\:bottom-640 {
    bottom: 64rem
  }

  .lg\:left-640 {
    left: 64rem
  }

  .lg\:top-auto {
    top: auto
  }

  .lg\:right-auto {
    right: auto
  }

  .lg\:bottom-auto {
    bottom: auto
  }

  .lg\:left-auto {
    left: auto
  }

  .lg\:top-xs {
    top: 32rem
  }

  .lg\:right-xs {
    right: 32rem
  }

  .lg\:bottom-xs {
    bottom: 32rem
  }

  .lg\:left-xs {
    left: 32rem
  }

  .lg\:top-sm {
    top: 48rem
  }

  .lg\:right-sm {
    right: 48rem
  }

  .lg\:bottom-sm {
    bottom: 48rem
  }

  .lg\:left-sm {
    left: 48rem
  }

  .lg\:top-md {
    top: 64rem
  }

  .lg\:right-md {
    right: 64rem
  }

  .lg\:bottom-md {
    bottom: 64rem
  }

  .lg\:left-md {
    left: 64rem
  }

  .lg\:top-lg {
    top: 80rem
  }

  .lg\:right-lg {
    right: 80rem
  }

  .lg\:bottom-lg {
    bottom: 80rem
  }

  .lg\:left-lg {
    left: 80rem
  }

  .lg\:top-xl {
    top: 96rem
  }

  .lg\:right-xl {
    right: 96rem
  }

  .lg\:bottom-xl {
    bottom: 96rem
  }

  .lg\:left-xl {
    left: 96rem
  }

  .lg\:top-2xl {
    top: 112rem
  }

  .lg\:right-2xl {
    right: 112rem
  }

  .lg\:bottom-2xl {
    bottom: 112rem
  }

  .lg\:left-2xl {
    left: 112rem
  }

  .lg\:top-3xl {
    top: 128rem
  }

  .lg\:right-3xl {
    right: 128rem
  }

  .lg\:bottom-3xl {
    bottom: 128rem
  }

  .lg\:left-3xl {
    left: 128rem
  }

  .lg\:top-4xl {
    top: 144rem
  }

  .lg\:right-4xl {
    right: 144rem
  }

  .lg\:bottom-4xl {
    bottom: 144rem
  }

  .lg\:left-4xl {
    left: 144rem
  }

  .lg\:top-5xl {
    top: 160rem
  }

  .lg\:right-5xl {
    right: 160rem
  }

  .lg\:bottom-5xl {
    bottom: 160rem
  }

  .lg\:left-5xl {
    left: 160rem
  }

  .lg\:top-px {
    top: 1px
  }

  .lg\:right-px {
    right: 1px
  }

  .lg\:bottom-px {
    bottom: 1px
  }

  .lg\:left-px {
    left: 1px
  }

  .lg\:top-0\.5 {
    top: 0.05rem
  }

  .lg\:right-0\.5 {
    right: 0.05rem
  }

  .lg\:bottom-0\.5 {
    bottom: 0.05rem
  }

  .lg\:left-0\.5 {
    left: 0.05rem
  }

  .lg\:top-1\.5 {
    top: 0.15rem
  }

  .lg\:right-1\.5 {
    right: 0.15rem
  }

  .lg\:bottom-1\.5 {
    bottom: 0.15rem
  }

  .lg\:left-1\.5 {
    left: 0.15rem
  }

  .lg\:top-2\.5 {
    top: 0.25rem
  }

  .lg\:right-2\.5 {
    right: 0.25rem
  }

  .lg\:bottom-2\.5 {
    bottom: 0.25rem
  }

  .lg\:left-2\.5 {
    left: 0.25rem
  }

  .lg\:top-3\.5 {
    top: 0.35rem
  }

  .lg\:right-3\.5 {
    right: 0.35rem
  }

  .lg\:bottom-3\.5 {
    bottom: 0.35rem
  }

  .lg\:left-3\.5 {
    left: 0.35rem
  }

  .lg\:-top-1 {
    top: -0.1rem
  }

  .lg\:-right-1 {
    right: -0.1rem
  }

  .lg\:-bottom-1 {
    bottom: -0.1rem
  }

  .lg\:-left-1 {
    left: -0.1rem
  }

  .lg\:-top-2 {
    top: -0.2rem
  }

  .lg\:-right-2 {
    right: -0.2rem
  }

  .lg\:-bottom-2 {
    bottom: -0.2rem
  }

  .lg\:-left-2 {
    left: -0.2rem
  }

  .lg\:-top-3 {
    top: -0.3rem
  }

  .lg\:-right-3 {
    right: -0.3rem
  }

  .lg\:-bottom-3 {
    bottom: -0.3rem
  }

  .lg\:-left-3 {
    left: -0.3rem
  }

  .lg\:-top-4 {
    top: -0.4rem
  }

  .lg\:-right-4 {
    right: -0.4rem
  }

  .lg\:-bottom-4 {
    bottom: -0.4rem
  }

  .lg\:-left-4 {
    left: -0.4rem
  }

  .lg\:-top-5 {
    top: -0.5rem
  }

  .lg\:-right-5 {
    right: -0.5rem
  }

  .lg\:-bottom-5 {
    bottom: -0.5rem
  }

  .lg\:-left-5 {
    left: -0.5rem
  }

  .lg\:-top-6 {
    top: -0.6rem
  }

  .lg\:-right-6 {
    right: -0.6rem
  }

  .lg\:-bottom-6 {
    bottom: -0.6rem
  }

  .lg\:-left-6 {
    left: -0.6rem
  }

  .lg\:-top-7 {
    top: -0.7rem
  }

  .lg\:-right-7 {
    right: -0.7rem
  }

  .lg\:-bottom-7 {
    bottom: -0.7rem
  }

  .lg\:-left-7 {
    left: -0.7rem
  }

  .lg\:-top-8 {
    top: -0.8rem
  }

  .lg\:-right-8 {
    right: -0.8rem
  }

  .lg\:-bottom-8 {
    bottom: -0.8rem
  }

  .lg\:-left-8 {
    left: -0.8rem
  }

  .lg\:-top-9 {
    top: -0.9rem
  }

  .lg\:-right-9 {
    right: -0.9rem
  }

  .lg\:-bottom-9 {
    bottom: -0.9rem
  }

  .lg\:-left-9 {
    left: -0.9rem
  }

  .lg\:-top-10 {
    top: -1rem
  }

  .lg\:-right-10 {
    right: -1rem
  }

  .lg\:-bottom-10 {
    bottom: -1rem
  }

  .lg\:-left-10 {
    left: -1rem
  }

  .lg\:-top-12 {
    top: -1.2rem
  }

  .lg\:-right-12 {
    right: -1.2rem
  }

  .lg\:-bottom-12 {
    bottom: -1.2rem
  }

  .lg\:-left-12 {
    left: -1.2rem
  }

  .lg\:-top-14 {
    top: -1.4rem
  }

  .lg\:-right-14 {
    right: -1.4rem
  }

  .lg\:-bottom-14 {
    bottom: -1.4rem
  }

  .lg\:-left-14 {
    left: -1.4rem
  }

  .lg\:-top-16 {
    top: -1.6rem
  }

  .lg\:-right-16 {
    right: -1.6rem
  }

  .lg\:-bottom-16 {
    bottom: -1.6rem
  }

  .lg\:-left-16 {
    left: -1.6rem
  }

  .lg\:-top-20 {
    top: -2rem
  }

  .lg\:-right-20 {
    right: -2rem
  }

  .lg\:-bottom-20 {
    bottom: -2rem
  }

  .lg\:-left-20 {
    left: -2rem
  }

  .lg\:-top-24 {
    top: -2.4rem
  }

  .lg\:-right-24 {
    right: -2.4rem
  }

  .lg\:-bottom-24 {
    bottom: -2.4rem
  }

  .lg\:-left-24 {
    left: -2.4rem
  }

  .lg\:-top-28 {
    top: -2.8rem
  }

  .lg\:-right-28 {
    right: -2.8rem
  }

  .lg\:-bottom-28 {
    bottom: -2.8rem
  }

  .lg\:-left-28 {
    left: -2.8rem
  }

  .lg\:-top-32 {
    top: -3.2rem
  }

  .lg\:-right-32 {
    right: -3.2rem
  }

  .lg\:-bottom-32 {
    bottom: -3.2rem
  }

  .lg\:-left-32 {
    left: -3.2rem
  }

  .lg\:-top-36 {
    top: -3.6rem
  }

  .lg\:-right-36 {
    right: -3.6rem
  }

  .lg\:-bottom-36 {
    bottom: -3.6rem
  }

  .lg\:-left-36 {
    left: -3.6rem
  }

  .lg\:-top-40 {
    top: -4rem
  }

  .lg\:-right-40 {
    right: -4rem
  }

  .lg\:-bottom-40 {
    bottom: -4rem
  }

  .lg\:-left-40 {
    left: -4rem
  }

  .lg\:-top-44 {
    top: -4.4rem
  }

  .lg\:-right-44 {
    right: -4.4rem
  }

  .lg\:-bottom-44 {
    bottom: -4.4rem
  }

  .lg\:-left-44 {
    left: -4.4rem
  }

  .lg\:-top-48 {
    top: -4.8rem
  }

  .lg\:-right-48 {
    right: -4.8rem
  }

  .lg\:-bottom-48 {
    bottom: -4.8rem
  }

  .lg\:-left-48 {
    left: -4.8rem
  }

  .lg\:-top-52 {
    top: -5.2rem
  }

  .lg\:-right-52 {
    right: -5.2rem
  }

  .lg\:-bottom-52 {
    bottom: -5.2rem
  }

  .lg\:-left-52 {
    left: -5.2rem
  }

  .lg\:-top-56 {
    top: -5.6rem
  }

  .lg\:-right-56 {
    right: -5.6rem
  }

  .lg\:-bottom-56 {
    bottom: -5.6rem
  }

  .lg\:-left-56 {
    left: -5.6rem
  }

  .lg\:-top-60 {
    top: -6rem
  }

  .lg\:-right-60 {
    right: -6rem
  }

  .lg\:-bottom-60 {
    bottom: -6rem
  }

  .lg\:-left-60 {
    left: -6rem
  }

  .lg\:-top-64 {
    top: -6.4rem
  }

  .lg\:-right-64 {
    right: -6.4rem
  }

  .lg\:-bottom-64 {
    bottom: -6.4rem
  }

  .lg\:-left-64 {
    left: -6.4rem
  }

  .lg\:-top-68 {
    top: -6.8rem
  }

  .lg\:-right-68 {
    right: -6.8rem
  }

  .lg\:-bottom-68 {
    bottom: -6.8rem
  }

  .lg\:-left-68 {
    left: -6.8rem
  }

  .lg\:-top-72 {
    top: -7.2rem
  }

  .lg\:-right-72 {
    right: -7.2rem
  }

  .lg\:-bottom-72 {
    bottom: -7.2rem
  }

  .lg\:-left-72 {
    left: -7.2rem
  }

  .lg\:-top-76 {
    top: -7.6rem
  }

  .lg\:-right-76 {
    right: -7.6rem
  }

  .lg\:-bottom-76 {
    bottom: -7.6rem
  }

  .lg\:-left-76 {
    left: -7.6rem
  }

  .lg\:-top-80 {
    top: -8rem
  }

  .lg\:-right-80 {
    right: -8rem
  }

  .lg\:-bottom-80 {
    bottom: -8rem
  }

  .lg\:-left-80 {
    left: -8rem
  }

  .lg\:-top-84 {
    top: -8.4rem
  }

  .lg\:-right-84 {
    right: -8.4rem
  }

  .lg\:-bottom-84 {
    bottom: -8.4rem
  }

  .lg\:-left-84 {
    left: -8.4rem
  }

  .lg\:-top-88 {
    top: -8.8rem
  }

  .lg\:-right-88 {
    right: -8.8rem
  }

  .lg\:-bottom-88 {
    bottom: -8.8rem
  }

  .lg\:-left-88 {
    left: -8.8rem
  }

  .lg\:-top-92 {
    top: -9.2rem
  }

  .lg\:-right-92 {
    right: -9.2rem
  }

  .lg\:-bottom-92 {
    bottom: -9.2rem
  }

  .lg\:-left-92 {
    left: -9.2rem
  }

  .lg\:-top-96 {
    top: -9.6rem
  }

  .lg\:-right-96 {
    right: -9.6rem
  }

  .lg\:-bottom-96 {
    bottom: -9.6rem
  }

  .lg\:-left-96 {
    left: -9.6rem
  }

  .lg\:-top-128 {
    top: -12.8rem
  }

  .lg\:-right-128 {
    right: -12.8rem
  }

  .lg\:-bottom-128 {
    bottom: -12.8rem
  }

  .lg\:-left-128 {
    left: -12.8rem
  }

  .lg\:-top-136 {
    top: -13.6rem
  }

  .lg\:-right-136 {
    right: -13.6rem
  }

  .lg\:-bottom-136 {
    bottom: -13.6rem
  }

  .lg\:-left-136 {
    left: -13.6rem
  }

  .lg\:-top-160 {
    top: -16rem
  }

  .lg\:-right-160 {
    right: -16rem
  }

  .lg\:-bottom-160 {
    bottom: -16rem
  }

  .lg\:-left-160 {
    left: -16rem
  }

  .lg\:-top-192 {
    top: -19.2rem
  }

  .lg\:-right-192 {
    right: -19.2rem
  }

  .lg\:-bottom-192 {
    bottom: -19.2rem
  }

  .lg\:-left-192 {
    left: -19.2rem
  }

  .lg\:-top-200 {
    top: -20rem
  }

  .lg\:-right-200 {
    right: -20rem
  }

  .lg\:-bottom-200 {
    bottom: -20rem
  }

  .lg\:-left-200 {
    left: -20rem
  }

  .lg\:-top-208 {
    top: -20.8rem
  }

  .lg\:-right-208 {
    right: -20.8rem
  }

  .lg\:-bottom-208 {
    bottom: -20.8rem
  }

  .lg\:-left-208 {
    left: -20.8rem
  }

  .lg\:-top-216 {
    top: -21.6rem
  }

  .lg\:-right-216 {
    right: -21.6rem
  }

  .lg\:-bottom-216 {
    bottom: -21.6rem
  }

  .lg\:-left-216 {
    left: -21.6rem
  }

  .lg\:-top-224 {
    top: -22.4rem
  }

  .lg\:-right-224 {
    right: -22.4rem
  }

  .lg\:-bottom-224 {
    bottom: -22.4rem
  }

  .lg\:-left-224 {
    left: -22.4rem
  }

  .lg\:-top-256 {
    top: -25.6rem
  }

  .lg\:-right-256 {
    right: -25.6rem
  }

  .lg\:-bottom-256 {
    bottom: -25.6rem
  }

  .lg\:-left-256 {
    left: -25.6rem
  }

  .lg\:-top-288 {
    top: -28.8rem
  }

  .lg\:-right-288 {
    right: -28.8rem
  }

  .lg\:-bottom-288 {
    bottom: -28.8rem
  }

  .lg\:-left-288 {
    left: -28.8rem
  }

  .lg\:-top-320 {
    top: -32rem
  }

  .lg\:-right-320 {
    right: -32rem
  }

  .lg\:-bottom-320 {
    bottom: -32rem
  }

  .lg\:-left-320 {
    left: -32rem
  }

  .lg\:-top-360 {
    top: -36rem
  }

  .lg\:-right-360 {
    right: -36rem
  }

  .lg\:-bottom-360 {
    bottom: -36rem
  }

  .lg\:-left-360 {
    left: -36rem
  }

  .lg\:-top-384 {
    top: -38.4rem
  }

  .lg\:-right-384 {
    right: -38.4rem
  }

  .lg\:-bottom-384 {
    bottom: -38.4rem
  }

  .lg\:-left-384 {
    left: -38.4rem
  }

  .lg\:-top-400 {
    top: -40rem
  }

  .lg\:-right-400 {
    right: -40rem
  }

  .lg\:-bottom-400 {
    bottom: -40rem
  }

  .lg\:-left-400 {
    left: -40rem
  }

  .lg\:-top-512 {
    top: -51.2rem
  }

  .lg\:-right-512 {
    right: -51.2rem
  }

  .lg\:-bottom-512 {
    bottom: -51.2rem
  }

  .lg\:-left-512 {
    left: -51.2rem
  }

  .lg\:-top-640 {
    top: -64rem
  }

  .lg\:-right-640 {
    right: -64rem
  }

  .lg\:-bottom-640 {
    bottom: -64rem
  }

  .lg\:-left-640 {
    left: -64rem
  }

  .lg\:-top-xs {
    top: -32rem
  }

  .lg\:-right-xs {
    right: -32rem
  }

  .lg\:-bottom-xs {
    bottom: -32rem
  }

  .lg\:-left-xs {
    left: -32rem
  }

  .lg\:-top-sm {
    top: -48rem
  }

  .lg\:-right-sm {
    right: -48rem
  }

  .lg\:-bottom-sm {
    bottom: -48rem
  }

  .lg\:-left-sm {
    left: -48rem
  }

  .lg\:-top-md {
    top: -64rem
  }

  .lg\:-right-md {
    right: -64rem
  }

  .lg\:-bottom-md {
    bottom: -64rem
  }

  .lg\:-left-md {
    left: -64rem
  }

  .lg\:-top-lg {
    top: -80rem
  }

  .lg\:-right-lg {
    right: -80rem
  }

  .lg\:-bottom-lg {
    bottom: -80rem
  }

  .lg\:-left-lg {
    left: -80rem
  }

  .lg\:-top-xl {
    top: -96rem
  }

  .lg\:-right-xl {
    right: -96rem
  }

  .lg\:-bottom-xl {
    bottom: -96rem
  }

  .lg\:-left-xl {
    left: -96rem
  }

  .lg\:-top-2xl {
    top: -112rem
  }

  .lg\:-right-2xl {
    right: -112rem
  }

  .lg\:-bottom-2xl {
    bottom: -112rem
  }

  .lg\:-left-2xl {
    left: -112rem
  }

  .lg\:-top-3xl {
    top: -128rem
  }

  .lg\:-right-3xl {
    right: -128rem
  }

  .lg\:-bottom-3xl {
    bottom: -128rem
  }

  .lg\:-left-3xl {
    left: -128rem
  }

  .lg\:-top-4xl {
    top: -144rem
  }

  .lg\:-right-4xl {
    right: -144rem
  }

  .lg\:-bottom-4xl {
    bottom: -144rem
  }

  .lg\:-left-4xl {
    left: -144rem
  }

  .lg\:-top-5xl {
    top: -160rem
  }

  .lg\:-right-5xl {
    right: -160rem
  }

  .lg\:-bottom-5xl {
    bottom: -160rem
  }

  .lg\:-left-5xl {
    left: -160rem
  }

  .lg\:-top-px {
    top: -1px
  }

  .lg\:-right-px {
    right: -1px
  }

  .lg\:-bottom-px {
    bottom: -1px
  }

  .lg\:-left-px {
    left: -1px
  }

  .lg\:-top-0\.5 {
    top: -0.05rem
  }

  .lg\:-right-0\.5 {
    right: -0.05rem
  }

  .lg\:-bottom-0\.5 {
    bottom: -0.05rem
  }

  .lg\:-left-0\.5 {
    left: -0.05rem
  }

  .lg\:-top-1\.5 {
    top: -0.15rem
  }

  .lg\:-right-1\.5 {
    right: -0.15rem
  }

  .lg\:-bottom-1\.5 {
    bottom: -0.15rem
  }

  .lg\:-left-1\.5 {
    left: -0.15rem
  }

  .lg\:-top-2\.5 {
    top: -0.25rem
  }

  .lg\:-right-2\.5 {
    right: -0.25rem
  }

  .lg\:-bottom-2\.5 {
    bottom: -0.25rem
  }

  .lg\:-left-2\.5 {
    left: -0.25rem
  }

  .lg\:-top-3\.5 {
    top: -0.35rem
  }

  .lg\:-right-3\.5 {
    right: -0.35rem
  }

  .lg\:-bottom-3\.5 {
    bottom: -0.35rem
  }

  .lg\:-left-3\.5 {
    left: -0.35rem
  }

  .lg\:top-1\/2 {
    top: 50%
  }

  .lg\:right-1\/2 {
    right: 50%
  }

  .lg\:bottom-1\/2 {
    bottom: 50%
  }

  .lg\:left-1\/2 {
    left: 50%
  }

  .lg\:top-1\/3 {
    top: 33.333333%
  }

  .lg\:right-1\/3 {
    right: 33.333333%
  }

  .lg\:bottom-1\/3 {
    bottom: 33.333333%
  }

  .lg\:left-1\/3 {
    left: 33.333333%
  }

  .lg\:top-2\/3 {
    top: 66.666667%
  }

  .lg\:right-2\/3 {
    right: 66.666667%
  }

  .lg\:bottom-2\/3 {
    bottom: 66.666667%
  }

  .lg\:left-2\/3 {
    left: 66.666667%
  }

  .lg\:top-1\/4 {
    top: 25%
  }

  .lg\:right-1\/4 {
    right: 25%
  }

  .lg\:bottom-1\/4 {
    bottom: 25%
  }

  .lg\:left-1\/4 {
    left: 25%
  }

  .lg\:top-2\/4 {
    top: 50%
  }

  .lg\:right-2\/4 {
    right: 50%
  }

  .lg\:bottom-2\/4 {
    bottom: 50%
  }

  .lg\:left-2\/4 {
    left: 50%
  }

  .lg\:top-3\/4 {
    top: 75%
  }

  .lg\:right-3\/4 {
    right: 75%
  }

  .lg\:bottom-3\/4 {
    bottom: 75%
  }

  .lg\:left-3\/4 {
    left: 75%
  }

  .lg\:top-full {
    top: 100%
  }

  .lg\:right-full {
    right: 100%
  }

  .lg\:bottom-full {
    bottom: 100%
  }

  .lg\:left-full {
    left: 100%
  }

  .lg\:-top-1\/2 {
    top: -50%
  }

  .lg\:-right-1\/2 {
    right: -50%
  }

  .lg\:-bottom-1\/2 {
    bottom: -50%
  }

  .lg\:-left-1\/2 {
    left: -50%
  }

  .lg\:-top-1\/3 {
    top: -33.333333%
  }

  .lg\:-right-1\/3 {
    right: -33.333333%
  }

  .lg\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  .lg\:-left-1\/3 {
    left: -33.333333%
  }

  .lg\:-top-2\/3 {
    top: -66.666667%
  }

  .lg\:-right-2\/3 {
    right: -66.666667%
  }

  .lg\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  .lg\:-left-2\/3 {
    left: -66.666667%
  }

  .lg\:-top-1\/4 {
    top: -25%
  }

  .lg\:-right-1\/4 {
    right: -25%
  }

  .lg\:-bottom-1\/4 {
    bottom: -25%
  }

  .lg\:-left-1\/4 {
    left: -25%
  }

  .lg\:-top-2\/4 {
    top: -50%
  }

  .lg\:-right-2\/4 {
    right: -50%
  }

  .lg\:-bottom-2\/4 {
    bottom: -50%
  }

  .lg\:-left-2\/4 {
    left: -50%
  }

  .lg\:-top-3\/4 {
    top: -75%
  }

  .lg\:-right-3\/4 {
    right: -75%
  }

  .lg\:-bottom-3\/4 {
    bottom: -75%
  }

  .lg\:-left-3\/4 {
    left: -75%
  }

  .lg\:-top-full {
    top: -100%
  }

  .lg\:-right-full {
    right: -100%
  }

  .lg\:-bottom-full {
    bottom: -100%
  }

  .lg\:-left-full {
    left: -100%
  }

  [dir='ltr'] .lg\:ltr\:inset-0,[dir='ltr'].lg\:ltr\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  [dir='ltr'] .lg\:ltr\:inset-1,[dir='ltr'].lg\:ltr\:inset-1 {
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:inset-2,[dir='ltr'].lg\:ltr\:inset-2 {
    top: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-3,[dir='ltr'].lg\:ltr\:inset-3 {
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:inset-4,[dir='ltr'].lg\:ltr\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-5,[dir='ltr'].lg\:ltr\:inset-5 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:inset-6,[dir='ltr'].lg\:ltr\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-7,[dir='ltr'].lg\:ltr\:inset-7 {
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
    left: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:inset-8,[dir='ltr'].lg\:ltr\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-9,[dir='ltr'].lg\:ltr\:inset-9 {
    top: 0.9rem;
    right: 0.9rem;
    bottom: 0.9rem;
    left: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:inset-10,[dir='ltr'].lg\:ltr\:inset-10 {
    top: 1.0rem;
    right: 1.0rem;
    bottom: 1.0rem;
    left: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:inset-12,[dir='ltr'].lg\:ltr\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-14,[dir='ltr'].lg\:ltr\:inset-14 {
    top: 1.4rem;
    right: 1.4rem;
    bottom: 1.4rem;
    left: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-16,[dir='ltr'].lg\:ltr\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-20,[dir='ltr'].lg\:ltr\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-24,[dir='ltr'].lg\:ltr\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-28,[dir='ltr'].lg\:ltr\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-32,[dir='ltr'].lg\:ltr\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-36,[dir='ltr'].lg\:ltr\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-40,[dir='ltr'].lg\:ltr\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-44,[dir='ltr'].lg\:ltr\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-48,[dir='ltr'].lg\:ltr\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-52,[dir='ltr'].lg\:ltr\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-56,[dir='ltr'].lg\:ltr\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-60,[dir='ltr'].lg\:ltr\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-64,[dir='ltr'].lg\:ltr\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-68,[dir='ltr'].lg\:ltr\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-72,[dir='ltr'].lg\:ltr\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-76,[dir='ltr'].lg\:ltr\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-80,[dir='ltr'].lg\:ltr\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-84,[dir='ltr'].lg\:ltr\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-88,[dir='ltr'].lg\:ltr\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-92,[dir='ltr'].lg\:ltr\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-96,[dir='ltr'].lg\:ltr\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-128,[dir='ltr'].lg\:ltr\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-136,[dir='ltr'].lg\:ltr\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-160,[dir='ltr'].lg\:ltr\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  [dir='ltr'] .lg\:ltr\:inset-192,[dir='ltr'].lg\:ltr\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-200,[dir='ltr'].lg\:ltr\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  [dir='ltr'] .lg\:ltr\:inset-208,[dir='ltr'].lg\:ltr\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-216,[dir='ltr'].lg\:ltr\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-224,[dir='ltr'].lg\:ltr\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-256,[dir='ltr'].lg\:ltr\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-288,[dir='ltr'].lg\:ltr\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-320,[dir='ltr'].lg\:ltr\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='ltr'] .lg\:ltr\:inset-360,[dir='ltr'].lg\:ltr\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  [dir='ltr'] .lg\:ltr\:inset-384,[dir='ltr'].lg\:ltr\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-400,[dir='ltr'].lg\:ltr\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  [dir='ltr'] .lg\:ltr\:inset-512,[dir='ltr'].lg\:ltr\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-640,[dir='ltr'].lg\:ltr\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='ltr'] .lg\:ltr\:inset-auto,[dir='ltr'].lg\:ltr\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  [dir='ltr'] .lg\:ltr\:inset-xs,[dir='ltr'].lg\:ltr\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='ltr'] .lg\:ltr\:inset-sm,[dir='ltr'].lg\:ltr\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  [dir='ltr'] .lg\:ltr\:inset-md,[dir='ltr'].lg\:ltr\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='ltr'] .lg\:ltr\:inset-lg,[dir='ltr'].lg\:ltr\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  [dir='ltr'] .lg\:ltr\:inset-xl,[dir='ltr'].lg\:ltr\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  [dir='ltr'] .lg\:ltr\:inset-2xl,[dir='ltr'].lg\:ltr\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  [dir='ltr'] .lg\:ltr\:inset-3xl,[dir='ltr'].lg\:ltr\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  [dir='ltr'] .lg\:ltr\:inset-4xl,[dir='ltr'].lg\:ltr\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  [dir='ltr'] .lg\:ltr\:inset-5xl,[dir='ltr'].lg\:ltr\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  [dir='ltr'] .lg\:ltr\:inset-px,[dir='ltr'].lg\:ltr\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  [dir='ltr'] .lg\:ltr\:inset-0\.5,[dir='ltr'].lg\:ltr\:inset-0\.5 {
    top: 0.05rem;
    right: 0.05rem;
    bottom: 0.05rem;
    left: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:inset-1\.5,[dir='ltr'].lg\:ltr\:inset-1\.5 {
    top: 0.15rem;
    right: 0.15rem;
    bottom: 0.15rem;
    left: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:inset-2\.5,[dir='ltr'].lg\:ltr\:inset-2\.5 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:inset-3\.5,[dir='ltr'].lg\:ltr\:inset-3\.5 {
    top: 0.35rem;
    right: 0.35rem;
    bottom: 0.35rem;
    left: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-1,[dir='ltr'].lg\:ltr\:-inset-1 {
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-2,[dir='ltr'].lg\:ltr\:-inset-2 {
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-3,[dir='ltr'].lg\:ltr\:-inset-3 {
    top: -0.3rem;
    right: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-4,[dir='ltr'].lg\:ltr\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-5,[dir='ltr'].lg\:ltr\:-inset-5 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-6,[dir='ltr'].lg\:ltr\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-7,[dir='ltr'].lg\:ltr\:-inset-7 {
    top: -0.7rem;
    right: -0.7rem;
    bottom: -0.7rem;
    left: -0.7rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-8,[dir='ltr'].lg\:ltr\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-9,[dir='ltr'].lg\:ltr\:-inset-9 {
    top: -0.9rem;
    right: -0.9rem;
    bottom: -0.9rem;
    left: -0.9rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-10,[dir='ltr'].lg\:ltr\:-inset-10 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-12,[dir='ltr'].lg\:ltr\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-14,[dir='ltr'].lg\:ltr\:-inset-14 {
    top: -1.4rem;
    right: -1.4rem;
    bottom: -1.4rem;
    left: -1.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-16,[dir='ltr'].lg\:ltr\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-20,[dir='ltr'].lg\:ltr\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-24,[dir='ltr'].lg\:ltr\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-28,[dir='ltr'].lg\:ltr\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-32,[dir='ltr'].lg\:ltr\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-36,[dir='ltr'].lg\:ltr\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-40,[dir='ltr'].lg\:ltr\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-44,[dir='ltr'].lg\:ltr\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-48,[dir='ltr'].lg\:ltr\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-52,[dir='ltr'].lg\:ltr\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-56,[dir='ltr'].lg\:ltr\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-60,[dir='ltr'].lg\:ltr\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-64,[dir='ltr'].lg\:ltr\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-68,[dir='ltr'].lg\:ltr\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-72,[dir='ltr'].lg\:ltr\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-76,[dir='ltr'].lg\:ltr\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-80,[dir='ltr'].lg\:ltr\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-84,[dir='ltr'].lg\:ltr\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-88,[dir='ltr'].lg\:ltr\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-92,[dir='ltr'].lg\:ltr\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-96,[dir='ltr'].lg\:ltr\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-128,[dir='ltr'].lg\:ltr\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-136,[dir='ltr'].lg\:ltr\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-160,[dir='ltr'].lg\:ltr\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-192,[dir='ltr'].lg\:ltr\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-200,[dir='ltr'].lg\:ltr\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-208,[dir='ltr'].lg\:ltr\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-216,[dir='ltr'].lg\:ltr\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-224,[dir='ltr'].lg\:ltr\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-256,[dir='ltr'].lg\:ltr\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-288,[dir='ltr'].lg\:ltr\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-320,[dir='ltr'].lg\:ltr\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-360,[dir='ltr'].lg\:ltr\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-384,[dir='ltr'].lg\:ltr\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-400,[dir='ltr'].lg\:ltr\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-512,[dir='ltr'].lg\:ltr\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-640,[dir='ltr'].lg\:ltr\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-xs,[dir='ltr'].lg\:ltr\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-sm,[dir='ltr'].lg\:ltr\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-md,[dir='ltr'].lg\:ltr\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-lg,[dir='ltr'].lg\:ltr\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-xl,[dir='ltr'].lg\:ltr\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-2xl,[dir='ltr'].lg\:ltr\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-3xl,[dir='ltr'].lg\:ltr\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-4xl,[dir='ltr'].lg\:ltr\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-5xl,[dir='ltr'].lg\:ltr\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-px,[dir='ltr'].lg\:ltr\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  [dir='ltr'] .lg\:ltr\:-inset-0\.5,[dir='ltr'].lg\:ltr\:-inset-0\.5 {
    top: -0.05rem;
    right: -0.05rem;
    bottom: -0.05rem;
    left: -0.05rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-1\.5,[dir='ltr'].lg\:ltr\:-inset-1\.5 {
    top: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    left: -0.15rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-2\.5,[dir='ltr'].lg\:ltr\:-inset-2\.5 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-3\.5,[dir='ltr'].lg\:ltr\:-inset-3\.5 {
    top: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    left: -0.35rem
  }

  [dir='ltr'] .lg\:ltr\:inset-1\/2,[dir='ltr'].lg\:ltr\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='ltr'] .lg\:ltr\:inset-1\/3,[dir='ltr'].lg\:ltr\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  [dir='ltr'] .lg\:ltr\:inset-2\/3,[dir='ltr'].lg\:ltr\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  [dir='ltr'] .lg\:ltr\:inset-1\/4,[dir='ltr'].lg\:ltr\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  [dir='ltr'] .lg\:ltr\:inset-2\/4,[dir='ltr'].lg\:ltr\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='ltr'] .lg\:ltr\:inset-3\/4,[dir='ltr'].lg\:ltr\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  [dir='ltr'] .lg\:ltr\:inset-full,[dir='ltr'].lg\:ltr\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  [dir='ltr'] .lg\:ltr\:-inset-1\/2,[dir='ltr'].lg\:ltr\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='ltr'] .lg\:ltr\:-inset-1\/3,[dir='ltr'].lg\:ltr\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  [dir='ltr'] .lg\:ltr\:-inset-2\/3,[dir='ltr'].lg\:ltr\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  [dir='ltr'] .lg\:ltr\:-inset-1\/4,[dir='ltr'].lg\:ltr\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  [dir='ltr'] .lg\:ltr\:-inset-2\/4,[dir='ltr'].lg\:ltr\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='ltr'] .lg\:ltr\:-inset-3\/4,[dir='ltr'].lg\:ltr\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  [dir='ltr'] .lg\:ltr\:-inset-full,[dir='ltr'].lg\:ltr\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  [dir='ltr'] .lg\:ltr\:inset-y-0,[dir='ltr'].lg\:ltr\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  [dir='ltr'] .lg\:ltr\:inset-x-0,[dir='ltr'].lg\:ltr\:inset-x-0 {
    right: 0;
    left: 0
  }

  [dir='ltr'] .lg\:ltr\:inset-y-1,[dir='ltr'].lg\:ltr\:inset-y-1 {
    top: 0.1rem;
    bottom: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-1,[dir='ltr'].lg\:ltr\:inset-x-1 {
    right: 0.1rem;
    left: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-2,[dir='ltr'].lg\:ltr\:inset-y-2 {
    top: 0.2rem;
    bottom: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-2,[dir='ltr'].lg\:ltr\:inset-x-2 {
    right: 0.2rem;
    left: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-3,[dir='ltr'].lg\:ltr\:inset-y-3 {
    top: 0.3rem;
    bottom: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-3,[dir='ltr'].lg\:ltr\:inset-x-3 {
    right: 0.3rem;
    left: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-4,[dir='ltr'].lg\:ltr\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-4,[dir='ltr'].lg\:ltr\:inset-x-4 {
    right: 0.4rem;
    left: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-5,[dir='ltr'].lg\:ltr\:inset-y-5 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-5,[dir='ltr'].lg\:ltr\:inset-x-5 {
    right: 0.5rem;
    left: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-6,[dir='ltr'].lg\:ltr\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-6,[dir='ltr'].lg\:ltr\:inset-x-6 {
    right: 0.6rem;
    left: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-7,[dir='ltr'].lg\:ltr\:inset-y-7 {
    top: 0.7rem;
    bottom: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-7,[dir='ltr'].lg\:ltr\:inset-x-7 {
    right: 0.7rem;
    left: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-8,[dir='ltr'].lg\:ltr\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-8,[dir='ltr'].lg\:ltr\:inset-x-8 {
    right: 0.8rem;
    left: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-9,[dir='ltr'].lg\:ltr\:inset-y-9 {
    top: 0.9rem;
    bottom: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-9,[dir='ltr'].lg\:ltr\:inset-x-9 {
    right: 0.9rem;
    left: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-10,[dir='ltr'].lg\:ltr\:inset-y-10 {
    top: 1.0rem;
    bottom: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-10,[dir='ltr'].lg\:ltr\:inset-x-10 {
    right: 1.0rem;
    left: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-12,[dir='ltr'].lg\:ltr\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-12,[dir='ltr'].lg\:ltr\:inset-x-12 {
    right: 1.2rem;
    left: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-14,[dir='ltr'].lg\:ltr\:inset-y-14 {
    top: 1.4rem;
    bottom: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-14,[dir='ltr'].lg\:ltr\:inset-x-14 {
    right: 1.4rem;
    left: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-16,[dir='ltr'].lg\:ltr\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-16,[dir='ltr'].lg\:ltr\:inset-x-16 {
    right: 1.6rem;
    left: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-20,[dir='ltr'].lg\:ltr\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-20,[dir='ltr'].lg\:ltr\:inset-x-20 {
    right: 2rem;
    left: 2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-24,[dir='ltr'].lg\:ltr\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-24,[dir='ltr'].lg\:ltr\:inset-x-24 {
    right: 2.4rem;
    left: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-28,[dir='ltr'].lg\:ltr\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-28,[dir='ltr'].lg\:ltr\:inset-x-28 {
    right: 2.8rem;
    left: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-32,[dir='ltr'].lg\:ltr\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-32,[dir='ltr'].lg\:ltr\:inset-x-32 {
    right: 3.2rem;
    left: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-36,[dir='ltr'].lg\:ltr\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-36,[dir='ltr'].lg\:ltr\:inset-x-36 {
    right: 3.6rem;
    left: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-40,[dir='ltr'].lg\:ltr\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-40,[dir='ltr'].lg\:ltr\:inset-x-40 {
    right: 4rem;
    left: 4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-44,[dir='ltr'].lg\:ltr\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-44,[dir='ltr'].lg\:ltr\:inset-x-44 {
    right: 4.4rem;
    left: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-48,[dir='ltr'].lg\:ltr\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-48,[dir='ltr'].lg\:ltr\:inset-x-48 {
    right: 4.8rem;
    left: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-52,[dir='ltr'].lg\:ltr\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-52,[dir='ltr'].lg\:ltr\:inset-x-52 {
    right: 5.2rem;
    left: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-56,[dir='ltr'].lg\:ltr\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-56,[dir='ltr'].lg\:ltr\:inset-x-56 {
    right: 5.6rem;
    left: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-60,[dir='ltr'].lg\:ltr\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-60,[dir='ltr'].lg\:ltr\:inset-x-60 {
    right: 6rem;
    left: 6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-64,[dir='ltr'].lg\:ltr\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-64,[dir='ltr'].lg\:ltr\:inset-x-64 {
    right: 6.4rem;
    left: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-68,[dir='ltr'].lg\:ltr\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-68,[dir='ltr'].lg\:ltr\:inset-x-68 {
    right: 6.8rem;
    left: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-72,[dir='ltr'].lg\:ltr\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-72,[dir='ltr'].lg\:ltr\:inset-x-72 {
    right: 7.2rem;
    left: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-76,[dir='ltr'].lg\:ltr\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-76,[dir='ltr'].lg\:ltr\:inset-x-76 {
    right: 7.6rem;
    left: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-80,[dir='ltr'].lg\:ltr\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-80,[dir='ltr'].lg\:ltr\:inset-x-80 {
    right: 8rem;
    left: 8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-84,[dir='ltr'].lg\:ltr\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-84,[dir='ltr'].lg\:ltr\:inset-x-84 {
    right: 8.4rem;
    left: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-88,[dir='ltr'].lg\:ltr\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-88,[dir='ltr'].lg\:ltr\:inset-x-88 {
    right: 8.8rem;
    left: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-92,[dir='ltr'].lg\:ltr\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-92,[dir='ltr'].lg\:ltr\:inset-x-92 {
    right: 9.2rem;
    left: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-96,[dir='ltr'].lg\:ltr\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-96,[dir='ltr'].lg\:ltr\:inset-x-96 {
    right: 9.6rem;
    left: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-128,[dir='ltr'].lg\:ltr\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-128,[dir='ltr'].lg\:ltr\:inset-x-128 {
    right: 12.8rem;
    left: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-136,[dir='ltr'].lg\:ltr\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-136,[dir='ltr'].lg\:ltr\:inset-x-136 {
    right: 13.6rem;
    left: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-160,[dir='ltr'].lg\:ltr\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-160,[dir='ltr'].lg\:ltr\:inset-x-160 {
    right: 16rem;
    left: 16rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-192,[dir='ltr'].lg\:ltr\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-192,[dir='ltr'].lg\:ltr\:inset-x-192 {
    right: 19.2rem;
    left: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-200,[dir='ltr'].lg\:ltr\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-200,[dir='ltr'].lg\:ltr\:inset-x-200 {
    right: 20rem;
    left: 20rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-208,[dir='ltr'].lg\:ltr\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-208,[dir='ltr'].lg\:ltr\:inset-x-208 {
    right: 20.8rem;
    left: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-216,[dir='ltr'].lg\:ltr\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-216,[dir='ltr'].lg\:ltr\:inset-x-216 {
    right: 21.6rem;
    left: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-224,[dir='ltr'].lg\:ltr\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-224,[dir='ltr'].lg\:ltr\:inset-x-224 {
    right: 22.4rem;
    left: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-256,[dir='ltr'].lg\:ltr\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-256,[dir='ltr'].lg\:ltr\:inset-x-256 {
    right: 25.6rem;
    left: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-288,[dir='ltr'].lg\:ltr\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-288,[dir='ltr'].lg\:ltr\:inset-x-288 {
    right: 28.8rem;
    left: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-320,[dir='ltr'].lg\:ltr\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-320,[dir='ltr'].lg\:ltr\:inset-x-320 {
    right: 32rem;
    left: 32rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-360,[dir='ltr'].lg\:ltr\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-360,[dir='ltr'].lg\:ltr\:inset-x-360 {
    right: 36rem;
    left: 36rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-384,[dir='ltr'].lg\:ltr\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-384,[dir='ltr'].lg\:ltr\:inset-x-384 {
    right: 38.4rem;
    left: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-400,[dir='ltr'].lg\:ltr\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-400,[dir='ltr'].lg\:ltr\:inset-x-400 {
    right: 40rem;
    left: 40rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-512,[dir='ltr'].lg\:ltr\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-512,[dir='ltr'].lg\:ltr\:inset-x-512 {
    right: 51.2rem;
    left: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-640,[dir='ltr'].lg\:ltr\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-640,[dir='ltr'].lg\:ltr\:inset-x-640 {
    right: 64rem;
    left: 64rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-auto,[dir='ltr'].lg\:ltr\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  [dir='ltr'] .lg\:ltr\:inset-x-auto,[dir='ltr'].lg\:ltr\:inset-x-auto {
    right: auto;
    left: auto
  }

  [dir='ltr'] .lg\:ltr\:inset-y-xs,[dir='ltr'].lg\:ltr\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-xs,[dir='ltr'].lg\:ltr\:inset-x-xs {
    right: 32rem;
    left: 32rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-sm,[dir='ltr'].lg\:ltr\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-sm,[dir='ltr'].lg\:ltr\:inset-x-sm {
    right: 48rem;
    left: 48rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-md,[dir='ltr'].lg\:ltr\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-md,[dir='ltr'].lg\:ltr\:inset-x-md {
    right: 64rem;
    left: 64rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-lg,[dir='ltr'].lg\:ltr\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-lg,[dir='ltr'].lg\:ltr\:inset-x-lg {
    right: 80rem;
    left: 80rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-xl,[dir='ltr'].lg\:ltr\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-xl,[dir='ltr'].lg\:ltr\:inset-x-xl {
    right: 96rem;
    left: 96rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-2xl,[dir='ltr'].lg\:ltr\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-2xl,[dir='ltr'].lg\:ltr\:inset-x-2xl {
    right: 112rem;
    left: 112rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-3xl,[dir='ltr'].lg\:ltr\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-3xl,[dir='ltr'].lg\:ltr\:inset-x-3xl {
    right: 128rem;
    left: 128rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-4xl,[dir='ltr'].lg\:ltr\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-4xl,[dir='ltr'].lg\:ltr\:inset-x-4xl {
    right: 144rem;
    left: 144rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-5xl,[dir='ltr'].lg\:ltr\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-5xl,[dir='ltr'].lg\:ltr\:inset-x-5xl {
    right: 160rem;
    left: 160rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-px,[dir='ltr'].lg\:ltr\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  [dir='ltr'] .lg\:ltr\:inset-x-px,[dir='ltr'].lg\:ltr\:inset-x-px {
    right: 1px;
    left: 1px
  }

  [dir='ltr'] .lg\:ltr\:inset-y-0\.5,[dir='ltr'].lg\:ltr\:inset-y-0\.5 {
    top: 0.05rem;
    bottom: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-0\.5,[dir='ltr'].lg\:ltr\:inset-x-0\.5 {
    right: 0.05rem;
    left: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-1\.5,[dir='ltr'].lg\:ltr\:inset-y-1\.5 {
    top: 0.15rem;
    bottom: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-1\.5,[dir='ltr'].lg\:ltr\:inset-x-1\.5 {
    right: 0.15rem;
    left: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-2\.5,[dir='ltr'].lg\:ltr\:inset-y-2\.5 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-2\.5,[dir='ltr'].lg\:ltr\:inset-x-2\.5 {
    right: 0.25rem;
    left: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-3\.5,[dir='ltr'].lg\:ltr\:inset-y-3\.5 {
    top: 0.35rem;
    bottom: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:inset-x-3\.5,[dir='ltr'].lg\:ltr\:inset-x-3\.5 {
    right: 0.35rem;
    left: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-1,[dir='ltr'].lg\:ltr\:-inset-y-1 {
    top: -0.1rem;
    bottom: -0.1rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-1,[dir='ltr'].lg\:ltr\:-inset-x-1 {
    right: -0.1rem;
    left: -0.1rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-2,[dir='ltr'].lg\:ltr\:-inset-y-2 {
    top: -0.2rem;
    bottom: -0.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-2,[dir='ltr'].lg\:ltr\:-inset-x-2 {
    right: -0.2rem;
    left: -0.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-3,[dir='ltr'].lg\:ltr\:-inset-y-3 {
    top: -0.3rem;
    bottom: -0.3rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-3,[dir='ltr'].lg\:ltr\:-inset-x-3 {
    right: -0.3rem;
    left: -0.3rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-4,[dir='ltr'].lg\:ltr\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-4,[dir='ltr'].lg\:ltr\:-inset-x-4 {
    right: -0.4rem;
    left: -0.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-5,[dir='ltr'].lg\:ltr\:-inset-y-5 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-5,[dir='ltr'].lg\:ltr\:-inset-x-5 {
    right: -0.5rem;
    left: -0.5rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-6,[dir='ltr'].lg\:ltr\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-6,[dir='ltr'].lg\:ltr\:-inset-x-6 {
    right: -0.6rem;
    left: -0.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-7,[dir='ltr'].lg\:ltr\:-inset-y-7 {
    top: -0.7rem;
    bottom: -0.7rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-7,[dir='ltr'].lg\:ltr\:-inset-x-7 {
    right: -0.7rem;
    left: -0.7rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-8,[dir='ltr'].lg\:ltr\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-8,[dir='ltr'].lg\:ltr\:-inset-x-8 {
    right: -0.8rem;
    left: -0.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-9,[dir='ltr'].lg\:ltr\:-inset-y-9 {
    top: -0.9rem;
    bottom: -0.9rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-9,[dir='ltr'].lg\:ltr\:-inset-x-9 {
    right: -0.9rem;
    left: -0.9rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-10,[dir='ltr'].lg\:ltr\:-inset-y-10 {
    top: -1rem;
    bottom: -1rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-10,[dir='ltr'].lg\:ltr\:-inset-x-10 {
    right: -1rem;
    left: -1rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-12,[dir='ltr'].lg\:ltr\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-12,[dir='ltr'].lg\:ltr\:-inset-x-12 {
    right: -1.2rem;
    left: -1.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-14,[dir='ltr'].lg\:ltr\:-inset-y-14 {
    top: -1.4rem;
    bottom: -1.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-14,[dir='ltr'].lg\:ltr\:-inset-x-14 {
    right: -1.4rem;
    left: -1.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-16,[dir='ltr'].lg\:ltr\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-16,[dir='ltr'].lg\:ltr\:-inset-x-16 {
    right: -1.6rem;
    left: -1.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-20,[dir='ltr'].lg\:ltr\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-20,[dir='ltr'].lg\:ltr\:-inset-x-20 {
    right: -2rem;
    left: -2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-24,[dir='ltr'].lg\:ltr\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-24,[dir='ltr'].lg\:ltr\:-inset-x-24 {
    right: -2.4rem;
    left: -2.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-28,[dir='ltr'].lg\:ltr\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-28,[dir='ltr'].lg\:ltr\:-inset-x-28 {
    right: -2.8rem;
    left: -2.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-32,[dir='ltr'].lg\:ltr\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-32,[dir='ltr'].lg\:ltr\:-inset-x-32 {
    right: -3.2rem;
    left: -3.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-36,[dir='ltr'].lg\:ltr\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-36,[dir='ltr'].lg\:ltr\:-inset-x-36 {
    right: -3.6rem;
    left: -3.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-40,[dir='ltr'].lg\:ltr\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-40,[dir='ltr'].lg\:ltr\:-inset-x-40 {
    right: -4rem;
    left: -4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-44,[dir='ltr'].lg\:ltr\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-44,[dir='ltr'].lg\:ltr\:-inset-x-44 {
    right: -4.4rem;
    left: -4.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-48,[dir='ltr'].lg\:ltr\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-48,[dir='ltr'].lg\:ltr\:-inset-x-48 {
    right: -4.8rem;
    left: -4.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-52,[dir='ltr'].lg\:ltr\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-52,[dir='ltr'].lg\:ltr\:-inset-x-52 {
    right: -5.2rem;
    left: -5.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-56,[dir='ltr'].lg\:ltr\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-56,[dir='ltr'].lg\:ltr\:-inset-x-56 {
    right: -5.6rem;
    left: -5.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-60,[dir='ltr'].lg\:ltr\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-60,[dir='ltr'].lg\:ltr\:-inset-x-60 {
    right: -6rem;
    left: -6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-64,[dir='ltr'].lg\:ltr\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-64,[dir='ltr'].lg\:ltr\:-inset-x-64 {
    right: -6.4rem;
    left: -6.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-68,[dir='ltr'].lg\:ltr\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-68,[dir='ltr'].lg\:ltr\:-inset-x-68 {
    right: -6.8rem;
    left: -6.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-72,[dir='ltr'].lg\:ltr\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-72,[dir='ltr'].lg\:ltr\:-inset-x-72 {
    right: -7.2rem;
    left: -7.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-76,[dir='ltr'].lg\:ltr\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-76,[dir='ltr'].lg\:ltr\:-inset-x-76 {
    right: -7.6rem;
    left: -7.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-80,[dir='ltr'].lg\:ltr\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-80,[dir='ltr'].lg\:ltr\:-inset-x-80 {
    right: -8rem;
    left: -8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-84,[dir='ltr'].lg\:ltr\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-84,[dir='ltr'].lg\:ltr\:-inset-x-84 {
    right: -8.4rem;
    left: -8.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-88,[dir='ltr'].lg\:ltr\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-88,[dir='ltr'].lg\:ltr\:-inset-x-88 {
    right: -8.8rem;
    left: -8.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-92,[dir='ltr'].lg\:ltr\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-92,[dir='ltr'].lg\:ltr\:-inset-x-92 {
    right: -9.2rem;
    left: -9.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-96,[dir='ltr'].lg\:ltr\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-96,[dir='ltr'].lg\:ltr\:-inset-x-96 {
    right: -9.6rem;
    left: -9.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-128,[dir='ltr'].lg\:ltr\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-128,[dir='ltr'].lg\:ltr\:-inset-x-128 {
    right: -12.8rem;
    left: -12.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-136,[dir='ltr'].lg\:ltr\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-136,[dir='ltr'].lg\:ltr\:-inset-x-136 {
    right: -13.6rem;
    left: -13.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-160,[dir='ltr'].lg\:ltr\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-160,[dir='ltr'].lg\:ltr\:-inset-x-160 {
    right: -16rem;
    left: -16rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-192,[dir='ltr'].lg\:ltr\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-192,[dir='ltr'].lg\:ltr\:-inset-x-192 {
    right: -19.2rem;
    left: -19.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-200,[dir='ltr'].lg\:ltr\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-200,[dir='ltr'].lg\:ltr\:-inset-x-200 {
    right: -20rem;
    left: -20rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-208,[dir='ltr'].lg\:ltr\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-208,[dir='ltr'].lg\:ltr\:-inset-x-208 {
    right: -20.8rem;
    left: -20.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-216,[dir='ltr'].lg\:ltr\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-216,[dir='ltr'].lg\:ltr\:-inset-x-216 {
    right: -21.6rem;
    left: -21.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-224,[dir='ltr'].lg\:ltr\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-224,[dir='ltr'].lg\:ltr\:-inset-x-224 {
    right: -22.4rem;
    left: -22.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-256,[dir='ltr'].lg\:ltr\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-256,[dir='ltr'].lg\:ltr\:-inset-x-256 {
    right: -25.6rem;
    left: -25.6rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-288,[dir='ltr'].lg\:ltr\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-288,[dir='ltr'].lg\:ltr\:-inset-x-288 {
    right: -28.8rem;
    left: -28.8rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-320,[dir='ltr'].lg\:ltr\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-320,[dir='ltr'].lg\:ltr\:-inset-x-320 {
    right: -32rem;
    left: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-360,[dir='ltr'].lg\:ltr\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-360,[dir='ltr'].lg\:ltr\:-inset-x-360 {
    right: -36rem;
    left: -36rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-384,[dir='ltr'].lg\:ltr\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-384,[dir='ltr'].lg\:ltr\:-inset-x-384 {
    right: -38.4rem;
    left: -38.4rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-400,[dir='ltr'].lg\:ltr\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-400,[dir='ltr'].lg\:ltr\:-inset-x-400 {
    right: -40rem;
    left: -40rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-512,[dir='ltr'].lg\:ltr\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-512,[dir='ltr'].lg\:ltr\:-inset-x-512 {
    right: -51.2rem;
    left: -51.2rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-640,[dir='ltr'].lg\:ltr\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-640,[dir='ltr'].lg\:ltr\:-inset-x-640 {
    right: -64rem;
    left: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-xs,[dir='ltr'].lg\:ltr\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-xs,[dir='ltr'].lg\:ltr\:-inset-x-xs {
    right: -32rem;
    left: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-sm,[dir='ltr'].lg\:ltr\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-sm,[dir='ltr'].lg\:ltr\:-inset-x-sm {
    right: -48rem;
    left: -48rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-md,[dir='ltr'].lg\:ltr\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-md,[dir='ltr'].lg\:ltr\:-inset-x-md {
    right: -64rem;
    left: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-lg,[dir='ltr'].lg\:ltr\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-lg,[dir='ltr'].lg\:ltr\:-inset-x-lg {
    right: -80rem;
    left: -80rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-xl,[dir='ltr'].lg\:ltr\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-xl,[dir='ltr'].lg\:ltr\:-inset-x-xl {
    right: -96rem;
    left: -96rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-2xl,[dir='ltr'].lg\:ltr\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-2xl,[dir='ltr'].lg\:ltr\:-inset-x-2xl {
    right: -112rem;
    left: -112rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-3xl,[dir='ltr'].lg\:ltr\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-3xl,[dir='ltr'].lg\:ltr\:-inset-x-3xl {
    right: -128rem;
    left: -128rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-4xl,[dir='ltr'].lg\:ltr\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-4xl,[dir='ltr'].lg\:ltr\:-inset-x-4xl {
    right: -144rem;
    left: -144rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-5xl,[dir='ltr'].lg\:ltr\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-5xl,[dir='ltr'].lg\:ltr\:-inset-x-5xl {
    right: -160rem;
    left: -160rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-px,[dir='ltr'].lg\:ltr\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-px,[dir='ltr'].lg\:ltr\:-inset-x-px {
    right: -1px;
    left: -1px
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-0\.5,[dir='ltr'].lg\:ltr\:-inset-y-0\.5 {
    top: -0.05rem;
    bottom: -0.05rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-0\.5,[dir='ltr'].lg\:ltr\:-inset-x-0\.5 {
    right: -0.05rem;
    left: -0.05rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-1\.5,[dir='ltr'].lg\:ltr\:-inset-y-1\.5 {
    top: -0.15rem;
    bottom: -0.15rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-1\.5,[dir='ltr'].lg\:ltr\:-inset-x-1\.5 {
    right: -0.15rem;
    left: -0.15rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-2\.5,[dir='ltr'].lg\:ltr\:-inset-y-2\.5 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-2\.5,[dir='ltr'].lg\:ltr\:-inset-x-2\.5 {
    right: -0.25rem;
    left: -0.25rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-3\.5,[dir='ltr'].lg\:ltr\:-inset-y-3\.5 {
    top: -0.35rem;
    bottom: -0.35rem
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-3\.5,[dir='ltr'].lg\:ltr\:-inset-x-3\.5 {
    right: -0.35rem;
    left: -0.35rem
  }

  [dir='ltr'] .lg\:ltr\:inset-y-1\/2,[dir='ltr'].lg\:ltr\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  [dir='ltr'] .lg\:ltr\:inset-x-1\/2,[dir='ltr'].lg\:ltr\:inset-x-1\/2 {
    right: 50%;
    left: 50%
  }

  [dir='ltr'] .lg\:ltr\:inset-y-1\/3,[dir='ltr'].lg\:ltr\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  [dir='ltr'] .lg\:ltr\:inset-x-1\/3,[dir='ltr'].lg\:ltr\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%
  }

  [dir='ltr'] .lg\:ltr\:inset-y-2\/3,[dir='ltr'].lg\:ltr\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  [dir='ltr'] .lg\:ltr\:inset-x-2\/3,[dir='ltr'].lg\:ltr\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%
  }

  [dir='ltr'] .lg\:ltr\:inset-y-1\/4,[dir='ltr'].lg\:ltr\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  [dir='ltr'] .lg\:ltr\:inset-x-1\/4,[dir='ltr'].lg\:ltr\:inset-x-1\/4 {
    right: 25%;
    left: 25%
  }

  [dir='ltr'] .lg\:ltr\:inset-y-2\/4,[dir='ltr'].lg\:ltr\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  [dir='ltr'] .lg\:ltr\:inset-x-2\/4,[dir='ltr'].lg\:ltr\:inset-x-2\/4 {
    right: 50%;
    left: 50%
  }

  [dir='ltr'] .lg\:ltr\:inset-y-3\/4,[dir='ltr'].lg\:ltr\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  [dir='ltr'] .lg\:ltr\:inset-x-3\/4,[dir='ltr'].lg\:ltr\:inset-x-3\/4 {
    right: 75%;
    left: 75%
  }

  [dir='ltr'] .lg\:ltr\:inset-y-full,[dir='ltr'].lg\:ltr\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  [dir='ltr'] .lg\:ltr\:inset-x-full,[dir='ltr'].lg\:ltr\:inset-x-full {
    right: 100%;
    left: 100%
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-1\/2,[dir='ltr'].lg\:ltr\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-1\/2,[dir='ltr'].lg\:ltr\:-inset-x-1\/2 {
    right: -50%;
    left: -50%
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-1\/3,[dir='ltr'].lg\:ltr\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-1\/3,[dir='ltr'].lg\:ltr\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-2\/3,[dir='ltr'].lg\:ltr\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-2\/3,[dir='ltr'].lg\:ltr\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-1\/4,[dir='ltr'].lg\:ltr\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-1\/4,[dir='ltr'].lg\:ltr\:-inset-x-1\/4 {
    right: -25%;
    left: -25%
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-2\/4,[dir='ltr'].lg\:ltr\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-2\/4,[dir='ltr'].lg\:ltr\:-inset-x-2\/4 {
    right: -50%;
    left: -50%
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-3\/4,[dir='ltr'].lg\:ltr\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-3\/4,[dir='ltr'].lg\:ltr\:-inset-x-3\/4 {
    right: -75%;
    left: -75%
  }

  [dir='ltr'] .lg\:ltr\:-inset-y-full,[dir='ltr'].lg\:ltr\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  [dir='ltr'] .lg\:ltr\:-inset-x-full,[dir='ltr'].lg\:ltr\:-inset-x-full {
    right: -100%;
    left: -100%
  }

  [dir='ltr'] .lg\:ltr\:top-0,[dir='ltr'].lg\:ltr\:top-0 {
    top: 0
  }

  [dir='ltr'] .lg\:ltr\:right-0,[dir='ltr'].lg\:ltr\:right-0 {
    right: 0
  }

  [dir='ltr'] .lg\:ltr\:bottom-0,[dir='ltr'].lg\:ltr\:bottom-0 {
    bottom: 0
  }

  [dir='ltr'] .lg\:ltr\:left-0,[dir='ltr'].lg\:ltr\:left-0 {
    left: 0
  }

  [dir='ltr'] .lg\:ltr\:top-1,[dir='ltr'].lg\:ltr\:top-1 {
    top: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:right-1,[dir='ltr'].lg\:ltr\:right-1 {
    right: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-1,[dir='ltr'].lg\:ltr\:bottom-1 {
    bottom: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:left-1,[dir='ltr'].lg\:ltr\:left-1 {
    left: 0.1rem
  }

  [dir='ltr'] .lg\:ltr\:top-2,[dir='ltr'].lg\:ltr\:top-2 {
    top: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:right-2,[dir='ltr'].lg\:ltr\:right-2 {
    right: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-2,[dir='ltr'].lg\:ltr\:bottom-2 {
    bottom: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:left-2,[dir='ltr'].lg\:ltr\:left-2 {
    left: 0.2rem
  }

  [dir='ltr'] .lg\:ltr\:top-3,[dir='ltr'].lg\:ltr\:top-3 {
    top: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:right-3,[dir='ltr'].lg\:ltr\:right-3 {
    right: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-3,[dir='ltr'].lg\:ltr\:bottom-3 {
    bottom: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:left-3,[dir='ltr'].lg\:ltr\:left-3 {
    left: 0.3rem
  }

  [dir='ltr'] .lg\:ltr\:top-4,[dir='ltr'].lg\:ltr\:top-4 {
    top: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:right-4,[dir='ltr'].lg\:ltr\:right-4 {
    right: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-4,[dir='ltr'].lg\:ltr\:bottom-4 {
    bottom: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:left-4,[dir='ltr'].lg\:ltr\:left-4 {
    left: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:top-5,[dir='ltr'].lg\:ltr\:top-5 {
    top: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:right-5,[dir='ltr'].lg\:ltr\:right-5 {
    right: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-5,[dir='ltr'].lg\:ltr\:bottom-5 {
    bottom: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:left-5,[dir='ltr'].lg\:ltr\:left-5 {
    left: 0.5rem
  }

  [dir='ltr'] .lg\:ltr\:top-6,[dir='ltr'].lg\:ltr\:top-6 {
    top: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:right-6,[dir='ltr'].lg\:ltr\:right-6 {
    right: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-6,[dir='ltr'].lg\:ltr\:bottom-6 {
    bottom: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:left-6,[dir='ltr'].lg\:ltr\:left-6 {
    left: 0.6rem
  }

  [dir='ltr'] .lg\:ltr\:top-7,[dir='ltr'].lg\:ltr\:top-7 {
    top: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:right-7,[dir='ltr'].lg\:ltr\:right-7 {
    right: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-7,[dir='ltr'].lg\:ltr\:bottom-7 {
    bottom: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:left-7,[dir='ltr'].lg\:ltr\:left-7 {
    left: 0.7rem
  }

  [dir='ltr'] .lg\:ltr\:top-8,[dir='ltr'].lg\:ltr\:top-8 {
    top: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:right-8,[dir='ltr'].lg\:ltr\:right-8 {
    right: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-8,[dir='ltr'].lg\:ltr\:bottom-8 {
    bottom: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:left-8,[dir='ltr'].lg\:ltr\:left-8 {
    left: 0.8rem
  }

  [dir='ltr'] .lg\:ltr\:top-9,[dir='ltr'].lg\:ltr\:top-9 {
    top: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:right-9,[dir='ltr'].lg\:ltr\:right-9 {
    right: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-9,[dir='ltr'].lg\:ltr\:bottom-9 {
    bottom: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:left-9,[dir='ltr'].lg\:ltr\:left-9 {
    left: 0.9rem
  }

  [dir='ltr'] .lg\:ltr\:top-10,[dir='ltr'].lg\:ltr\:top-10 {
    top: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:right-10,[dir='ltr'].lg\:ltr\:right-10 {
    right: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-10,[dir='ltr'].lg\:ltr\:bottom-10 {
    bottom: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:left-10,[dir='ltr'].lg\:ltr\:left-10 {
    left: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:top-12,[dir='ltr'].lg\:ltr\:top-12 {
    top: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:right-12,[dir='ltr'].lg\:ltr\:right-12 {
    right: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-12,[dir='ltr'].lg\:ltr\:bottom-12 {
    bottom: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:left-12,[dir='ltr'].lg\:ltr\:left-12 {
    left: 1.2rem
  }

  [dir='ltr'] .lg\:ltr\:top-14,[dir='ltr'].lg\:ltr\:top-14 {
    top: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:right-14,[dir='ltr'].lg\:ltr\:right-14 {
    right: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-14,[dir='ltr'].lg\:ltr\:bottom-14 {
    bottom: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:left-14,[dir='ltr'].lg\:ltr\:left-14 {
    left: 1.4rem
  }

  [dir='ltr'] .lg\:ltr\:top-16,[dir='ltr'].lg\:ltr\:top-16 {
    top: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:right-16,[dir='ltr'].lg\:ltr\:right-16 {
    right: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-16,[dir='ltr'].lg\:ltr\:bottom-16 {
    bottom: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:left-16,[dir='ltr'].lg\:ltr\:left-16 {
    left: 1.6rem
  }

  [dir='ltr'] .lg\:ltr\:top-20,[dir='ltr'].lg\:ltr\:top-20 {
    top: 2rem
  }

  [dir='ltr'] .lg\:ltr\:right-20,[dir='ltr'].lg\:ltr\:right-20 {
    right: 2rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-20,[dir='ltr'].lg\:ltr\:bottom-20 {
    bottom: 2rem
  }

  [dir='ltr'] .lg\:ltr\:left-20,[dir='ltr'].lg\:ltr\:left-20 {
    left: 2rem
  }

  [dir='ltr'] .lg\:ltr\:top-24,[dir='ltr'].lg\:ltr\:top-24 {
    top: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:right-24,[dir='ltr'].lg\:ltr\:right-24 {
    right: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-24,[dir='ltr'].lg\:ltr\:bottom-24 {
    bottom: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:left-24,[dir='ltr'].lg\:ltr\:left-24 {
    left: 2.4rem
  }

  [dir='ltr'] .lg\:ltr\:top-28,[dir='ltr'].lg\:ltr\:top-28 {
    top: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:right-28,[dir='ltr'].lg\:ltr\:right-28 {
    right: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-28,[dir='ltr'].lg\:ltr\:bottom-28 {
    bottom: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:left-28,[dir='ltr'].lg\:ltr\:left-28 {
    left: 2.8rem
  }

  [dir='ltr'] .lg\:ltr\:top-32,[dir='ltr'].lg\:ltr\:top-32 {
    top: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:right-32,[dir='ltr'].lg\:ltr\:right-32 {
    right: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-32,[dir='ltr'].lg\:ltr\:bottom-32 {
    bottom: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:left-32,[dir='ltr'].lg\:ltr\:left-32 {
    left: 3.2rem
  }

  [dir='ltr'] .lg\:ltr\:top-36,[dir='ltr'].lg\:ltr\:top-36 {
    top: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:right-36,[dir='ltr'].lg\:ltr\:right-36 {
    right: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-36,[dir='ltr'].lg\:ltr\:bottom-36 {
    bottom: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:left-36,[dir='ltr'].lg\:ltr\:left-36 {
    left: 3.6rem
  }

  [dir='ltr'] .lg\:ltr\:top-40,[dir='ltr'].lg\:ltr\:top-40 {
    top: 4rem
  }

  [dir='ltr'] .lg\:ltr\:right-40,[dir='ltr'].lg\:ltr\:right-40 {
    right: 4rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-40,[dir='ltr'].lg\:ltr\:bottom-40 {
    bottom: 4rem
  }

  [dir='ltr'] .lg\:ltr\:left-40,[dir='ltr'].lg\:ltr\:left-40 {
    left: 4rem
  }

  [dir='ltr'] .lg\:ltr\:top-44,[dir='ltr'].lg\:ltr\:top-44 {
    top: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:right-44,[dir='ltr'].lg\:ltr\:right-44 {
    right: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-44,[dir='ltr'].lg\:ltr\:bottom-44 {
    bottom: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:left-44,[dir='ltr'].lg\:ltr\:left-44 {
    left: 4.4rem
  }

  [dir='ltr'] .lg\:ltr\:top-48,[dir='ltr'].lg\:ltr\:top-48 {
    top: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:right-48,[dir='ltr'].lg\:ltr\:right-48 {
    right: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-48,[dir='ltr'].lg\:ltr\:bottom-48 {
    bottom: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:left-48,[dir='ltr'].lg\:ltr\:left-48 {
    left: 4.8rem
  }

  [dir='ltr'] .lg\:ltr\:top-52,[dir='ltr'].lg\:ltr\:top-52 {
    top: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:right-52,[dir='ltr'].lg\:ltr\:right-52 {
    right: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-52,[dir='ltr'].lg\:ltr\:bottom-52 {
    bottom: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:left-52,[dir='ltr'].lg\:ltr\:left-52 {
    left: 5.2rem
  }

  [dir='ltr'] .lg\:ltr\:top-56,[dir='ltr'].lg\:ltr\:top-56 {
    top: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:right-56,[dir='ltr'].lg\:ltr\:right-56 {
    right: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-56,[dir='ltr'].lg\:ltr\:bottom-56 {
    bottom: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:left-56,[dir='ltr'].lg\:ltr\:left-56 {
    left: 5.6rem
  }

  [dir='ltr'] .lg\:ltr\:top-60,[dir='ltr'].lg\:ltr\:top-60 {
    top: 6rem
  }

  [dir='ltr'] .lg\:ltr\:right-60,[dir='ltr'].lg\:ltr\:right-60 {
    right: 6rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-60,[dir='ltr'].lg\:ltr\:bottom-60 {
    bottom: 6rem
  }

  [dir='ltr'] .lg\:ltr\:left-60,[dir='ltr'].lg\:ltr\:left-60 {
    left: 6rem
  }

  [dir='ltr'] .lg\:ltr\:top-64,[dir='ltr'].lg\:ltr\:top-64 {
    top: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:right-64,[dir='ltr'].lg\:ltr\:right-64 {
    right: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-64,[dir='ltr'].lg\:ltr\:bottom-64 {
    bottom: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:left-64,[dir='ltr'].lg\:ltr\:left-64 {
    left: 6.4rem
  }

  [dir='ltr'] .lg\:ltr\:top-68,[dir='ltr'].lg\:ltr\:top-68 {
    top: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:right-68,[dir='ltr'].lg\:ltr\:right-68 {
    right: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-68,[dir='ltr'].lg\:ltr\:bottom-68 {
    bottom: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:left-68,[dir='ltr'].lg\:ltr\:left-68 {
    left: 6.8rem
  }

  [dir='ltr'] .lg\:ltr\:top-72,[dir='ltr'].lg\:ltr\:top-72 {
    top: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:right-72,[dir='ltr'].lg\:ltr\:right-72 {
    right: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-72,[dir='ltr'].lg\:ltr\:bottom-72 {
    bottom: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:left-72,[dir='ltr'].lg\:ltr\:left-72 {
    left: 7.2rem
  }

  [dir='ltr'] .lg\:ltr\:top-76,[dir='ltr'].lg\:ltr\:top-76 {
    top: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:right-76,[dir='ltr'].lg\:ltr\:right-76 {
    right: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-76,[dir='ltr'].lg\:ltr\:bottom-76 {
    bottom: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:left-76,[dir='ltr'].lg\:ltr\:left-76 {
    left: 7.6rem
  }

  [dir='ltr'] .lg\:ltr\:top-80,[dir='ltr'].lg\:ltr\:top-80 {
    top: 8rem
  }

  [dir='ltr'] .lg\:ltr\:right-80,[dir='ltr'].lg\:ltr\:right-80 {
    right: 8rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-80,[dir='ltr'].lg\:ltr\:bottom-80 {
    bottom: 8rem
  }

  [dir='ltr'] .lg\:ltr\:left-80,[dir='ltr'].lg\:ltr\:left-80 {
    left: 8rem
  }

  [dir='ltr'] .lg\:ltr\:top-84,[dir='ltr'].lg\:ltr\:top-84 {
    top: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:right-84,[dir='ltr'].lg\:ltr\:right-84 {
    right: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-84,[dir='ltr'].lg\:ltr\:bottom-84 {
    bottom: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:left-84,[dir='ltr'].lg\:ltr\:left-84 {
    left: 8.4rem
  }

  [dir='ltr'] .lg\:ltr\:top-88,[dir='ltr'].lg\:ltr\:top-88 {
    top: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:right-88,[dir='ltr'].lg\:ltr\:right-88 {
    right: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-88,[dir='ltr'].lg\:ltr\:bottom-88 {
    bottom: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:left-88,[dir='ltr'].lg\:ltr\:left-88 {
    left: 8.8rem
  }

  [dir='ltr'] .lg\:ltr\:top-92,[dir='ltr'].lg\:ltr\:top-92 {
    top: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:right-92,[dir='ltr'].lg\:ltr\:right-92 {
    right: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-92,[dir='ltr'].lg\:ltr\:bottom-92 {
    bottom: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:left-92,[dir='ltr'].lg\:ltr\:left-92 {
    left: 9.2rem
  }

  [dir='ltr'] .lg\:ltr\:top-96,[dir='ltr'].lg\:ltr\:top-96 {
    top: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:right-96,[dir='ltr'].lg\:ltr\:right-96 {
    right: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-96,[dir='ltr'].lg\:ltr\:bottom-96 {
    bottom: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:left-96,[dir='ltr'].lg\:ltr\:left-96 {
    left: 9.6rem
  }

  [dir='ltr'] .lg\:ltr\:top-128,[dir='ltr'].lg\:ltr\:top-128 {
    top: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:right-128,[dir='ltr'].lg\:ltr\:right-128 {
    right: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-128,[dir='ltr'].lg\:ltr\:bottom-128 {
    bottom: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:left-128,[dir='ltr'].lg\:ltr\:left-128 {
    left: 12.8rem
  }

  [dir='ltr'] .lg\:ltr\:top-136,[dir='ltr'].lg\:ltr\:top-136 {
    top: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:right-136,[dir='ltr'].lg\:ltr\:right-136 {
    right: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-136,[dir='ltr'].lg\:ltr\:bottom-136 {
    bottom: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:left-136,[dir='ltr'].lg\:ltr\:left-136 {
    left: 13.6rem
  }

  [dir='ltr'] .lg\:ltr\:top-160,[dir='ltr'].lg\:ltr\:top-160 {
    top: 16rem
  }

  [dir='ltr'] .lg\:ltr\:right-160,[dir='ltr'].lg\:ltr\:right-160 {
    right: 16rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-160,[dir='ltr'].lg\:ltr\:bottom-160 {
    bottom: 16rem
  }

  [dir='ltr'] .lg\:ltr\:left-160,[dir='ltr'].lg\:ltr\:left-160 {
    left: 16rem
  }

  [dir='ltr'] .lg\:ltr\:top-192,[dir='ltr'].lg\:ltr\:top-192 {
    top: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:right-192,[dir='ltr'].lg\:ltr\:right-192 {
    right: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-192,[dir='ltr'].lg\:ltr\:bottom-192 {
    bottom: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:left-192,[dir='ltr'].lg\:ltr\:left-192 {
    left: 19.2rem
  }

  [dir='ltr'] .lg\:ltr\:top-200,[dir='ltr'].lg\:ltr\:top-200 {
    top: 20rem
  }

  [dir='ltr'] .lg\:ltr\:right-200,[dir='ltr'].lg\:ltr\:right-200 {
    right: 20rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-200,[dir='ltr'].lg\:ltr\:bottom-200 {
    bottom: 20rem
  }

  [dir='ltr'] .lg\:ltr\:left-200,[dir='ltr'].lg\:ltr\:left-200 {
    left: 20rem
  }

  [dir='ltr'] .lg\:ltr\:top-208,[dir='ltr'].lg\:ltr\:top-208 {
    top: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:right-208,[dir='ltr'].lg\:ltr\:right-208 {
    right: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-208,[dir='ltr'].lg\:ltr\:bottom-208 {
    bottom: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:left-208,[dir='ltr'].lg\:ltr\:left-208 {
    left: 20.8rem
  }

  [dir='ltr'] .lg\:ltr\:top-216,[dir='ltr'].lg\:ltr\:top-216 {
    top: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:right-216,[dir='ltr'].lg\:ltr\:right-216 {
    right: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-216,[dir='ltr'].lg\:ltr\:bottom-216 {
    bottom: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:left-216,[dir='ltr'].lg\:ltr\:left-216 {
    left: 21.6rem
  }

  [dir='ltr'] .lg\:ltr\:top-224,[dir='ltr'].lg\:ltr\:top-224 {
    top: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:right-224,[dir='ltr'].lg\:ltr\:right-224 {
    right: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-224,[dir='ltr'].lg\:ltr\:bottom-224 {
    bottom: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:left-224,[dir='ltr'].lg\:ltr\:left-224 {
    left: 22.4rem
  }

  [dir='ltr'] .lg\:ltr\:top-256,[dir='ltr'].lg\:ltr\:top-256 {
    top: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:right-256,[dir='ltr'].lg\:ltr\:right-256 {
    right: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-256,[dir='ltr'].lg\:ltr\:bottom-256 {
    bottom: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:left-256,[dir='ltr'].lg\:ltr\:left-256 {
    left: 25.6rem
  }

  [dir='ltr'] .lg\:ltr\:top-288,[dir='ltr'].lg\:ltr\:top-288 {
    top: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:right-288,[dir='ltr'].lg\:ltr\:right-288 {
    right: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-288,[dir='ltr'].lg\:ltr\:bottom-288 {
    bottom: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:left-288,[dir='ltr'].lg\:ltr\:left-288 {
    left: 28.8rem
  }

  [dir='ltr'] .lg\:ltr\:top-320,[dir='ltr'].lg\:ltr\:top-320 {
    top: 32rem
  }

  [dir='ltr'] .lg\:ltr\:right-320,[dir='ltr'].lg\:ltr\:right-320 {
    right: 32rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-320,[dir='ltr'].lg\:ltr\:bottom-320 {
    bottom: 32rem
  }

  [dir='ltr'] .lg\:ltr\:left-320,[dir='ltr'].lg\:ltr\:left-320 {
    left: 32rem
  }

  [dir='ltr'] .lg\:ltr\:top-360,[dir='ltr'].lg\:ltr\:top-360 {
    top: 36rem
  }

  [dir='ltr'] .lg\:ltr\:right-360,[dir='ltr'].lg\:ltr\:right-360 {
    right: 36rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-360,[dir='ltr'].lg\:ltr\:bottom-360 {
    bottom: 36rem
  }

  [dir='ltr'] .lg\:ltr\:left-360,[dir='ltr'].lg\:ltr\:left-360 {
    left: 36rem
  }

  [dir='ltr'] .lg\:ltr\:top-384,[dir='ltr'].lg\:ltr\:top-384 {
    top: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:right-384,[dir='ltr'].lg\:ltr\:right-384 {
    right: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-384,[dir='ltr'].lg\:ltr\:bottom-384 {
    bottom: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:left-384,[dir='ltr'].lg\:ltr\:left-384 {
    left: 38.4rem
  }

  [dir='ltr'] .lg\:ltr\:top-400,[dir='ltr'].lg\:ltr\:top-400 {
    top: 40rem
  }

  [dir='ltr'] .lg\:ltr\:right-400,[dir='ltr'].lg\:ltr\:right-400 {
    right: 40rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-400,[dir='ltr'].lg\:ltr\:bottom-400 {
    bottom: 40rem
  }

  [dir='ltr'] .lg\:ltr\:left-400,[dir='ltr'].lg\:ltr\:left-400 {
    left: 40rem
  }

  [dir='ltr'] .lg\:ltr\:top-512,[dir='ltr'].lg\:ltr\:top-512 {
    top: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:right-512,[dir='ltr'].lg\:ltr\:right-512 {
    right: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-512,[dir='ltr'].lg\:ltr\:bottom-512 {
    bottom: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:left-512,[dir='ltr'].lg\:ltr\:left-512 {
    left: 51.2rem
  }

  [dir='ltr'] .lg\:ltr\:top-640,[dir='ltr'].lg\:ltr\:top-640 {
    top: 64rem
  }

  [dir='ltr'] .lg\:ltr\:right-640,[dir='ltr'].lg\:ltr\:right-640 {
    right: 64rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-640,[dir='ltr'].lg\:ltr\:bottom-640 {
    bottom: 64rem
  }

  [dir='ltr'] .lg\:ltr\:left-640,[dir='ltr'].lg\:ltr\:left-640 {
    left: 64rem
  }

  [dir='ltr'] .lg\:ltr\:top-auto,[dir='ltr'].lg\:ltr\:top-auto {
    top: auto
  }

  [dir='ltr'] .lg\:ltr\:right-auto,[dir='ltr'].lg\:ltr\:right-auto {
    right: auto
  }

  [dir='ltr'] .lg\:ltr\:bottom-auto,[dir='ltr'].lg\:ltr\:bottom-auto {
    bottom: auto
  }

  [dir='ltr'] .lg\:ltr\:left-auto,[dir='ltr'].lg\:ltr\:left-auto {
    left: auto
  }

  [dir='ltr'] .lg\:ltr\:top-xs,[dir='ltr'].lg\:ltr\:top-xs {
    top: 32rem
  }

  [dir='ltr'] .lg\:ltr\:right-xs,[dir='ltr'].lg\:ltr\:right-xs {
    right: 32rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-xs,[dir='ltr'].lg\:ltr\:bottom-xs {
    bottom: 32rem
  }

  [dir='ltr'] .lg\:ltr\:left-xs,[dir='ltr'].lg\:ltr\:left-xs {
    left: 32rem
  }

  [dir='ltr'] .lg\:ltr\:top-sm,[dir='ltr'].lg\:ltr\:top-sm {
    top: 48rem
  }

  [dir='ltr'] .lg\:ltr\:right-sm,[dir='ltr'].lg\:ltr\:right-sm {
    right: 48rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-sm,[dir='ltr'].lg\:ltr\:bottom-sm {
    bottom: 48rem
  }

  [dir='ltr'] .lg\:ltr\:left-sm,[dir='ltr'].lg\:ltr\:left-sm {
    left: 48rem
  }

  [dir='ltr'] .lg\:ltr\:top-md,[dir='ltr'].lg\:ltr\:top-md {
    top: 64rem
  }

  [dir='ltr'] .lg\:ltr\:right-md,[dir='ltr'].lg\:ltr\:right-md {
    right: 64rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-md,[dir='ltr'].lg\:ltr\:bottom-md {
    bottom: 64rem
  }

  [dir='ltr'] .lg\:ltr\:left-md,[dir='ltr'].lg\:ltr\:left-md {
    left: 64rem
  }

  [dir='ltr'] .lg\:ltr\:top-lg,[dir='ltr'].lg\:ltr\:top-lg {
    top: 80rem
  }

  [dir='ltr'] .lg\:ltr\:right-lg,[dir='ltr'].lg\:ltr\:right-lg {
    right: 80rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-lg,[dir='ltr'].lg\:ltr\:bottom-lg {
    bottom: 80rem
  }

  [dir='ltr'] .lg\:ltr\:left-lg,[dir='ltr'].lg\:ltr\:left-lg {
    left: 80rem
  }

  [dir='ltr'] .lg\:ltr\:top-xl,[dir='ltr'].lg\:ltr\:top-xl {
    top: 96rem
  }

  [dir='ltr'] .lg\:ltr\:right-xl,[dir='ltr'].lg\:ltr\:right-xl {
    right: 96rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-xl,[dir='ltr'].lg\:ltr\:bottom-xl {
    bottom: 96rem
  }

  [dir='ltr'] .lg\:ltr\:left-xl,[dir='ltr'].lg\:ltr\:left-xl {
    left: 96rem
  }

  [dir='ltr'] .lg\:ltr\:top-2xl,[dir='ltr'].lg\:ltr\:top-2xl {
    top: 112rem
  }

  [dir='ltr'] .lg\:ltr\:right-2xl,[dir='ltr'].lg\:ltr\:right-2xl {
    right: 112rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-2xl,[dir='ltr'].lg\:ltr\:bottom-2xl {
    bottom: 112rem
  }

  [dir='ltr'] .lg\:ltr\:left-2xl,[dir='ltr'].lg\:ltr\:left-2xl {
    left: 112rem
  }

  [dir='ltr'] .lg\:ltr\:top-3xl,[dir='ltr'].lg\:ltr\:top-3xl {
    top: 128rem
  }

  [dir='ltr'] .lg\:ltr\:right-3xl,[dir='ltr'].lg\:ltr\:right-3xl {
    right: 128rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-3xl,[dir='ltr'].lg\:ltr\:bottom-3xl {
    bottom: 128rem
  }

  [dir='ltr'] .lg\:ltr\:left-3xl,[dir='ltr'].lg\:ltr\:left-3xl {
    left: 128rem
  }

  [dir='ltr'] .lg\:ltr\:top-4xl,[dir='ltr'].lg\:ltr\:top-4xl {
    top: 144rem
  }

  [dir='ltr'] .lg\:ltr\:right-4xl,[dir='ltr'].lg\:ltr\:right-4xl {
    right: 144rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-4xl,[dir='ltr'].lg\:ltr\:bottom-4xl {
    bottom: 144rem
  }

  [dir='ltr'] .lg\:ltr\:left-4xl,[dir='ltr'].lg\:ltr\:left-4xl {
    left: 144rem
  }

  [dir='ltr'] .lg\:ltr\:top-5xl,[dir='ltr'].lg\:ltr\:top-5xl {
    top: 160rem
  }

  [dir='ltr'] .lg\:ltr\:right-5xl,[dir='ltr'].lg\:ltr\:right-5xl {
    right: 160rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-5xl,[dir='ltr'].lg\:ltr\:bottom-5xl {
    bottom: 160rem
  }

  [dir='ltr'] .lg\:ltr\:left-5xl,[dir='ltr'].lg\:ltr\:left-5xl {
    left: 160rem
  }

  [dir='ltr'] .lg\:ltr\:top-px,[dir='ltr'].lg\:ltr\:top-px {
    top: 1px
  }

  [dir='ltr'] .lg\:ltr\:right-px,[dir='ltr'].lg\:ltr\:right-px {
    right: 1px
  }

  [dir='ltr'] .lg\:ltr\:bottom-px,[dir='ltr'].lg\:ltr\:bottom-px {
    bottom: 1px
  }

  [dir='ltr'] .lg\:ltr\:left-px,[dir='ltr'].lg\:ltr\:left-px {
    left: 1px
  }

  [dir='ltr'] .lg\:ltr\:top-0\.5,[dir='ltr'].lg\:ltr\:top-0\.5 {
    top: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:right-0\.5,[dir='ltr'].lg\:ltr\:right-0\.5 {
    right: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-0\.5,[dir='ltr'].lg\:ltr\:bottom-0\.5 {
    bottom: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:left-0\.5,[dir='ltr'].lg\:ltr\:left-0\.5 {
    left: 0.05rem
  }

  [dir='ltr'] .lg\:ltr\:top-1\.5,[dir='ltr'].lg\:ltr\:top-1\.5 {
    top: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:right-1\.5,[dir='ltr'].lg\:ltr\:right-1\.5 {
    right: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-1\.5,[dir='ltr'].lg\:ltr\:bottom-1\.5 {
    bottom: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:left-1\.5,[dir='ltr'].lg\:ltr\:left-1\.5 {
    left: 0.15rem
  }

  [dir='ltr'] .lg\:ltr\:top-2\.5,[dir='ltr'].lg\:ltr\:top-2\.5 {
    top: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:right-2\.5,[dir='ltr'].lg\:ltr\:right-2\.5 {
    right: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-2\.5,[dir='ltr'].lg\:ltr\:bottom-2\.5 {
    bottom: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:left-2\.5,[dir='ltr'].lg\:ltr\:left-2\.5 {
    left: 0.25rem
  }

  [dir='ltr'] .lg\:ltr\:top-3\.5,[dir='ltr'].lg\:ltr\:top-3\.5 {
    top: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:right-3\.5,[dir='ltr'].lg\:ltr\:right-3\.5 {
    right: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:bottom-3\.5,[dir='ltr'].lg\:ltr\:bottom-3\.5 {
    bottom: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:left-3\.5,[dir='ltr'].lg\:ltr\:left-3\.5 {
    left: 0.35rem
  }

  [dir='ltr'] .lg\:ltr\:-top-1,[dir='ltr'].lg\:ltr\:-top-1 {
    top: -0.1rem
  }

  [dir='ltr'] .lg\:ltr\:-right-1,[dir='ltr'].lg\:ltr\:-right-1 {
    right: -0.1rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-1,[dir='ltr'].lg\:ltr\:-bottom-1 {
    bottom: -0.1rem
  }

  [dir='ltr'] .lg\:ltr\:-left-1,[dir='ltr'].lg\:ltr\:-left-1 {
    left: -0.1rem
  }

  [dir='ltr'] .lg\:ltr\:-top-2,[dir='ltr'].lg\:ltr\:-top-2 {
    top: -0.2rem
  }

  [dir='ltr'] .lg\:ltr\:-right-2,[dir='ltr'].lg\:ltr\:-right-2 {
    right: -0.2rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-2,[dir='ltr'].lg\:ltr\:-bottom-2 {
    bottom: -0.2rem
  }

  [dir='ltr'] .lg\:ltr\:-left-2,[dir='ltr'].lg\:ltr\:-left-2 {
    left: -0.2rem
  }

  [dir='ltr'] .lg\:ltr\:-top-3,[dir='ltr'].lg\:ltr\:-top-3 {
    top: -0.3rem
  }

  [dir='ltr'] .lg\:ltr\:-right-3,[dir='ltr'].lg\:ltr\:-right-3 {
    right: -0.3rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-3,[dir='ltr'].lg\:ltr\:-bottom-3 {
    bottom: -0.3rem
  }

  [dir='ltr'] .lg\:ltr\:-left-3,[dir='ltr'].lg\:ltr\:-left-3 {
    left: -0.3rem
  }

  [dir='ltr'] .lg\:ltr\:-top-4,[dir='ltr'].lg\:ltr\:-top-4 {
    top: -0.4rem
  }

  [dir='ltr'] .lg\:ltr\:-right-4,[dir='ltr'].lg\:ltr\:-right-4 {
    right: -0.4rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-4,[dir='ltr'].lg\:ltr\:-bottom-4 {
    bottom: -0.4rem
  }

  [dir='ltr'] .lg\:ltr\:-left-4,[dir='ltr'].lg\:ltr\:-left-4 {
    left: -0.4rem
  }

  [dir='ltr'] .lg\:ltr\:-top-5,[dir='ltr'].lg\:ltr\:-top-5 {
    top: -0.5rem
  }

  [dir='ltr'] .lg\:ltr\:-right-5,[dir='ltr'].lg\:ltr\:-right-5 {
    right: -0.5rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-5,[dir='ltr'].lg\:ltr\:-bottom-5 {
    bottom: -0.5rem
  }

  [dir='ltr'] .lg\:ltr\:-left-5,[dir='ltr'].lg\:ltr\:-left-5 {
    left: -0.5rem
  }

  [dir='ltr'] .lg\:ltr\:-top-6,[dir='ltr'].lg\:ltr\:-top-6 {
    top: -0.6rem
  }

  [dir='ltr'] .lg\:ltr\:-right-6,[dir='ltr'].lg\:ltr\:-right-6 {
    right: -0.6rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-6,[dir='ltr'].lg\:ltr\:-bottom-6 {
    bottom: -0.6rem
  }

  [dir='ltr'] .lg\:ltr\:-left-6,[dir='ltr'].lg\:ltr\:-left-6 {
    left: -0.6rem
  }

  [dir='ltr'] .lg\:ltr\:-top-7,[dir='ltr'].lg\:ltr\:-top-7 {
    top: -0.7rem
  }

  [dir='ltr'] .lg\:ltr\:-right-7,[dir='ltr'].lg\:ltr\:-right-7 {
    right: -0.7rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-7,[dir='ltr'].lg\:ltr\:-bottom-7 {
    bottom: -0.7rem
  }

  [dir='ltr'] .lg\:ltr\:-left-7,[dir='ltr'].lg\:ltr\:-left-7 {
    left: -0.7rem
  }

  [dir='ltr'] .lg\:ltr\:-top-8,[dir='ltr'].lg\:ltr\:-top-8 {
    top: -0.8rem
  }

  [dir='ltr'] .lg\:ltr\:-right-8,[dir='ltr'].lg\:ltr\:-right-8 {
    right: -0.8rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-8,[dir='ltr'].lg\:ltr\:-bottom-8 {
    bottom: -0.8rem
  }

  [dir='ltr'] .lg\:ltr\:-left-8,[dir='ltr'].lg\:ltr\:-left-8 {
    left: -0.8rem
  }

  [dir='ltr'] .lg\:ltr\:-top-9,[dir='ltr'].lg\:ltr\:-top-9 {
    top: -0.9rem
  }

  [dir='ltr'] .lg\:ltr\:-right-9,[dir='ltr'].lg\:ltr\:-right-9 {
    right: -0.9rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-9,[dir='ltr'].lg\:ltr\:-bottom-9 {
    bottom: -0.9rem
  }

  [dir='ltr'] .lg\:ltr\:-left-9,[dir='ltr'].lg\:ltr\:-left-9 {
    left: -0.9rem
  }

  [dir='ltr'] .lg\:ltr\:-top-10,[dir='ltr'].lg\:ltr\:-top-10 {
    top: -1rem
  }

  [dir='ltr'] .lg\:ltr\:-right-10,[dir='ltr'].lg\:ltr\:-right-10 {
    right: -1rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-10,[dir='ltr'].lg\:ltr\:-bottom-10 {
    bottom: -1rem
  }

  [dir='ltr'] .lg\:ltr\:-left-10,[dir='ltr'].lg\:ltr\:-left-10 {
    left: -1rem
  }

  [dir='ltr'] .lg\:ltr\:-top-12,[dir='ltr'].lg\:ltr\:-top-12 {
    top: -1.2rem
  }

  [dir='ltr'] .lg\:ltr\:-right-12,[dir='ltr'].lg\:ltr\:-right-12 {
    right: -1.2rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-12,[dir='ltr'].lg\:ltr\:-bottom-12 {
    bottom: -1.2rem
  }

  [dir='ltr'] .lg\:ltr\:-left-12,[dir='ltr'].lg\:ltr\:-left-12 {
    left: -1.2rem
  }

  [dir='ltr'] .lg\:ltr\:-top-14,[dir='ltr'].lg\:ltr\:-top-14 {
    top: -1.4rem
  }

  [dir='ltr'] .lg\:ltr\:-right-14,[dir='ltr'].lg\:ltr\:-right-14 {
    right: -1.4rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-14,[dir='ltr'].lg\:ltr\:-bottom-14 {
    bottom: -1.4rem
  }

  [dir='ltr'] .lg\:ltr\:-left-14,[dir='ltr'].lg\:ltr\:-left-14 {
    left: -1.4rem
  }

  [dir='ltr'] .lg\:ltr\:-top-16,[dir='ltr'].lg\:ltr\:-top-16 {
    top: -1.6rem
  }

  [dir='ltr'] .lg\:ltr\:-right-16,[dir='ltr'].lg\:ltr\:-right-16 {
    right: -1.6rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-16,[dir='ltr'].lg\:ltr\:-bottom-16 {
    bottom: -1.6rem
  }

  [dir='ltr'] .lg\:ltr\:-left-16,[dir='ltr'].lg\:ltr\:-left-16 {
    left: -1.6rem
  }

  [dir='ltr'] .lg\:ltr\:-top-20,[dir='ltr'].lg\:ltr\:-top-20 {
    top: -2rem
  }

  [dir='ltr'] .lg\:ltr\:-right-20,[dir='ltr'].lg\:ltr\:-right-20 {
    right: -2rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-20,[dir='ltr'].lg\:ltr\:-bottom-20 {
    bottom: -2rem
  }

  [dir='ltr'] .lg\:ltr\:-left-20,[dir='ltr'].lg\:ltr\:-left-20 {
    left: -2rem
  }

  [dir='ltr'] .lg\:ltr\:-top-24,[dir='ltr'].lg\:ltr\:-top-24 {
    top: -2.4rem
  }

  [dir='ltr'] .lg\:ltr\:-right-24,[dir='ltr'].lg\:ltr\:-right-24 {
    right: -2.4rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-24,[dir='ltr'].lg\:ltr\:-bottom-24 {
    bottom: -2.4rem
  }

  [dir='ltr'] .lg\:ltr\:-left-24,[dir='ltr'].lg\:ltr\:-left-24 {
    left: -2.4rem
  }

  [dir='ltr'] .lg\:ltr\:-top-28,[dir='ltr'].lg\:ltr\:-top-28 {
    top: -2.8rem
  }

  [dir='ltr'] .lg\:ltr\:-right-28,[dir='ltr'].lg\:ltr\:-right-28 {
    right: -2.8rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-28,[dir='ltr'].lg\:ltr\:-bottom-28 {
    bottom: -2.8rem
  }

  [dir='ltr'] .lg\:ltr\:-left-28,[dir='ltr'].lg\:ltr\:-left-28 {
    left: -2.8rem
  }

  [dir='ltr'] .lg\:ltr\:-top-32,[dir='ltr'].lg\:ltr\:-top-32 {
    top: -3.2rem
  }

  [dir='ltr'] .lg\:ltr\:-right-32,[dir='ltr'].lg\:ltr\:-right-32 {
    right: -3.2rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-32,[dir='ltr'].lg\:ltr\:-bottom-32 {
    bottom: -3.2rem
  }

  [dir='ltr'] .lg\:ltr\:-left-32,[dir='ltr'].lg\:ltr\:-left-32 {
    left: -3.2rem
  }

  [dir='ltr'] .lg\:ltr\:-top-36,[dir='ltr'].lg\:ltr\:-top-36 {
    top: -3.6rem
  }

  [dir='ltr'] .lg\:ltr\:-right-36,[dir='ltr'].lg\:ltr\:-right-36 {
    right: -3.6rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-36,[dir='ltr'].lg\:ltr\:-bottom-36 {
    bottom: -3.6rem
  }

  [dir='ltr'] .lg\:ltr\:-left-36,[dir='ltr'].lg\:ltr\:-left-36 {
    left: -3.6rem
  }

  [dir='ltr'] .lg\:ltr\:-top-40,[dir='ltr'].lg\:ltr\:-top-40 {
    top: -4rem
  }

  [dir='ltr'] .lg\:ltr\:-right-40,[dir='ltr'].lg\:ltr\:-right-40 {
    right: -4rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-40,[dir='ltr'].lg\:ltr\:-bottom-40 {
    bottom: -4rem
  }

  [dir='ltr'] .lg\:ltr\:-left-40,[dir='ltr'].lg\:ltr\:-left-40 {
    left: -4rem
  }

  [dir='ltr'] .lg\:ltr\:-top-44,[dir='ltr'].lg\:ltr\:-top-44 {
    top: -4.4rem
  }

  [dir='ltr'] .lg\:ltr\:-right-44,[dir='ltr'].lg\:ltr\:-right-44 {
    right: -4.4rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-44,[dir='ltr'].lg\:ltr\:-bottom-44 {
    bottom: -4.4rem
  }

  [dir='ltr'] .lg\:ltr\:-left-44,[dir='ltr'].lg\:ltr\:-left-44 {
    left: -4.4rem
  }

  [dir='ltr'] .lg\:ltr\:-top-48,[dir='ltr'].lg\:ltr\:-top-48 {
    top: -4.8rem
  }

  [dir='ltr'] .lg\:ltr\:-right-48,[dir='ltr'].lg\:ltr\:-right-48 {
    right: -4.8rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-48,[dir='ltr'].lg\:ltr\:-bottom-48 {
    bottom: -4.8rem
  }

  [dir='ltr'] .lg\:ltr\:-left-48,[dir='ltr'].lg\:ltr\:-left-48 {
    left: -4.8rem
  }

  [dir='ltr'] .lg\:ltr\:-top-52,[dir='ltr'].lg\:ltr\:-top-52 {
    top: -5.2rem
  }

  [dir='ltr'] .lg\:ltr\:-right-52,[dir='ltr'].lg\:ltr\:-right-52 {
    right: -5.2rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-52,[dir='ltr'].lg\:ltr\:-bottom-52 {
    bottom: -5.2rem
  }

  [dir='ltr'] .lg\:ltr\:-left-52,[dir='ltr'].lg\:ltr\:-left-52 {
    left: -5.2rem
  }

  [dir='ltr'] .lg\:ltr\:-top-56,[dir='ltr'].lg\:ltr\:-top-56 {
    top: -5.6rem
  }

  [dir='ltr'] .lg\:ltr\:-right-56,[dir='ltr'].lg\:ltr\:-right-56 {
    right: -5.6rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-56,[dir='ltr'].lg\:ltr\:-bottom-56 {
    bottom: -5.6rem
  }

  [dir='ltr'] .lg\:ltr\:-left-56,[dir='ltr'].lg\:ltr\:-left-56 {
    left: -5.6rem
  }

  [dir='ltr'] .lg\:ltr\:-top-60,[dir='ltr'].lg\:ltr\:-top-60 {
    top: -6rem
  }

  [dir='ltr'] .lg\:ltr\:-right-60,[dir='ltr'].lg\:ltr\:-right-60 {
    right: -6rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-60,[dir='ltr'].lg\:ltr\:-bottom-60 {
    bottom: -6rem
  }

  [dir='ltr'] .lg\:ltr\:-left-60,[dir='ltr'].lg\:ltr\:-left-60 {
    left: -6rem
  }

  [dir='ltr'] .lg\:ltr\:-top-64,[dir='ltr'].lg\:ltr\:-top-64 {
    top: -6.4rem
  }

  [dir='ltr'] .lg\:ltr\:-right-64,[dir='ltr'].lg\:ltr\:-right-64 {
    right: -6.4rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-64,[dir='ltr'].lg\:ltr\:-bottom-64 {
    bottom: -6.4rem
  }

  [dir='ltr'] .lg\:ltr\:-left-64,[dir='ltr'].lg\:ltr\:-left-64 {
    left: -6.4rem
  }

  [dir='ltr'] .lg\:ltr\:-top-68,[dir='ltr'].lg\:ltr\:-top-68 {
    top: -6.8rem
  }

  [dir='ltr'] .lg\:ltr\:-right-68,[dir='ltr'].lg\:ltr\:-right-68 {
    right: -6.8rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-68,[dir='ltr'].lg\:ltr\:-bottom-68 {
    bottom: -6.8rem
  }

  [dir='ltr'] .lg\:ltr\:-left-68,[dir='ltr'].lg\:ltr\:-left-68 {
    left: -6.8rem
  }

  [dir='ltr'] .lg\:ltr\:-top-72,[dir='ltr'].lg\:ltr\:-top-72 {
    top: -7.2rem
  }

  [dir='ltr'] .lg\:ltr\:-right-72,[dir='ltr'].lg\:ltr\:-right-72 {
    right: -7.2rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-72,[dir='ltr'].lg\:ltr\:-bottom-72 {
    bottom: -7.2rem
  }

  [dir='ltr'] .lg\:ltr\:-left-72,[dir='ltr'].lg\:ltr\:-left-72 {
    left: -7.2rem
  }

  [dir='ltr'] .lg\:ltr\:-top-76,[dir='ltr'].lg\:ltr\:-top-76 {
    top: -7.6rem
  }

  [dir='ltr'] .lg\:ltr\:-right-76,[dir='ltr'].lg\:ltr\:-right-76 {
    right: -7.6rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-76,[dir='ltr'].lg\:ltr\:-bottom-76 {
    bottom: -7.6rem
  }

  [dir='ltr'] .lg\:ltr\:-left-76,[dir='ltr'].lg\:ltr\:-left-76 {
    left: -7.6rem
  }

  [dir='ltr'] .lg\:ltr\:-top-80,[dir='ltr'].lg\:ltr\:-top-80 {
    top: -8rem
  }

  [dir='ltr'] .lg\:ltr\:-right-80,[dir='ltr'].lg\:ltr\:-right-80 {
    right: -8rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-80,[dir='ltr'].lg\:ltr\:-bottom-80 {
    bottom: -8rem
  }

  [dir='ltr'] .lg\:ltr\:-left-80,[dir='ltr'].lg\:ltr\:-left-80 {
    left: -8rem
  }

  [dir='ltr'] .lg\:ltr\:-top-84,[dir='ltr'].lg\:ltr\:-top-84 {
    top: -8.4rem
  }

  [dir='ltr'] .lg\:ltr\:-right-84,[dir='ltr'].lg\:ltr\:-right-84 {
    right: -8.4rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-84,[dir='ltr'].lg\:ltr\:-bottom-84 {
    bottom: -8.4rem
  }

  [dir='ltr'] .lg\:ltr\:-left-84,[dir='ltr'].lg\:ltr\:-left-84 {
    left: -8.4rem
  }

  [dir='ltr'] .lg\:ltr\:-top-88,[dir='ltr'].lg\:ltr\:-top-88 {
    top: -8.8rem
  }

  [dir='ltr'] .lg\:ltr\:-right-88,[dir='ltr'].lg\:ltr\:-right-88 {
    right: -8.8rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-88,[dir='ltr'].lg\:ltr\:-bottom-88 {
    bottom: -8.8rem
  }

  [dir='ltr'] .lg\:ltr\:-left-88,[dir='ltr'].lg\:ltr\:-left-88 {
    left: -8.8rem
  }

  [dir='ltr'] .lg\:ltr\:-top-92,[dir='ltr'].lg\:ltr\:-top-92 {
    top: -9.2rem
  }

  [dir='ltr'] .lg\:ltr\:-right-92,[dir='ltr'].lg\:ltr\:-right-92 {
    right: -9.2rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-92,[dir='ltr'].lg\:ltr\:-bottom-92 {
    bottom: -9.2rem
  }

  [dir='ltr'] .lg\:ltr\:-left-92,[dir='ltr'].lg\:ltr\:-left-92 {
    left: -9.2rem
  }

  [dir='ltr'] .lg\:ltr\:-top-96,[dir='ltr'].lg\:ltr\:-top-96 {
    top: -9.6rem
  }

  [dir='ltr'] .lg\:ltr\:-right-96,[dir='ltr'].lg\:ltr\:-right-96 {
    right: -9.6rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-96,[dir='ltr'].lg\:ltr\:-bottom-96 {
    bottom: -9.6rem
  }

  [dir='ltr'] .lg\:ltr\:-left-96,[dir='ltr'].lg\:ltr\:-left-96 {
    left: -9.6rem
  }

  [dir='ltr'] .lg\:ltr\:-top-128,[dir='ltr'].lg\:ltr\:-top-128 {
    top: -12.8rem
  }

  [dir='ltr'] .lg\:ltr\:-right-128,[dir='ltr'].lg\:ltr\:-right-128 {
    right: -12.8rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-128,[dir='ltr'].lg\:ltr\:-bottom-128 {
    bottom: -12.8rem
  }

  [dir='ltr'] .lg\:ltr\:-left-128,[dir='ltr'].lg\:ltr\:-left-128 {
    left: -12.8rem
  }

  [dir='ltr'] .lg\:ltr\:-top-136,[dir='ltr'].lg\:ltr\:-top-136 {
    top: -13.6rem
  }

  [dir='ltr'] .lg\:ltr\:-right-136,[dir='ltr'].lg\:ltr\:-right-136 {
    right: -13.6rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-136,[dir='ltr'].lg\:ltr\:-bottom-136 {
    bottom: -13.6rem
  }

  [dir='ltr'] .lg\:ltr\:-left-136,[dir='ltr'].lg\:ltr\:-left-136 {
    left: -13.6rem
  }

  [dir='ltr'] .lg\:ltr\:-top-160,[dir='ltr'].lg\:ltr\:-top-160 {
    top: -16rem
  }

  [dir='ltr'] .lg\:ltr\:-right-160,[dir='ltr'].lg\:ltr\:-right-160 {
    right: -16rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-160,[dir='ltr'].lg\:ltr\:-bottom-160 {
    bottom: -16rem
  }

  [dir='ltr'] .lg\:ltr\:-left-160,[dir='ltr'].lg\:ltr\:-left-160 {
    left: -16rem
  }

  [dir='ltr'] .lg\:ltr\:-top-192,[dir='ltr'].lg\:ltr\:-top-192 {
    top: -19.2rem
  }

  [dir='ltr'] .lg\:ltr\:-right-192,[dir='ltr'].lg\:ltr\:-right-192 {
    right: -19.2rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-192,[dir='ltr'].lg\:ltr\:-bottom-192 {
    bottom: -19.2rem
  }

  [dir='ltr'] .lg\:ltr\:-left-192,[dir='ltr'].lg\:ltr\:-left-192 {
    left: -19.2rem
  }

  [dir='ltr'] .lg\:ltr\:-top-200,[dir='ltr'].lg\:ltr\:-top-200 {
    top: -20rem
  }

  [dir='ltr'] .lg\:ltr\:-right-200,[dir='ltr'].lg\:ltr\:-right-200 {
    right: -20rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-200,[dir='ltr'].lg\:ltr\:-bottom-200 {
    bottom: -20rem
  }

  [dir='ltr'] .lg\:ltr\:-left-200,[dir='ltr'].lg\:ltr\:-left-200 {
    left: -20rem
  }

  [dir='ltr'] .lg\:ltr\:-top-208,[dir='ltr'].lg\:ltr\:-top-208 {
    top: -20.8rem
  }

  [dir='ltr'] .lg\:ltr\:-right-208,[dir='ltr'].lg\:ltr\:-right-208 {
    right: -20.8rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-208,[dir='ltr'].lg\:ltr\:-bottom-208 {
    bottom: -20.8rem
  }

  [dir='ltr'] .lg\:ltr\:-left-208,[dir='ltr'].lg\:ltr\:-left-208 {
    left: -20.8rem
  }

  [dir='ltr'] .lg\:ltr\:-top-216,[dir='ltr'].lg\:ltr\:-top-216 {
    top: -21.6rem
  }

  [dir='ltr'] .lg\:ltr\:-right-216,[dir='ltr'].lg\:ltr\:-right-216 {
    right: -21.6rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-216,[dir='ltr'].lg\:ltr\:-bottom-216 {
    bottom: -21.6rem
  }

  [dir='ltr'] .lg\:ltr\:-left-216,[dir='ltr'].lg\:ltr\:-left-216 {
    left: -21.6rem
  }

  [dir='ltr'] .lg\:ltr\:-top-224,[dir='ltr'].lg\:ltr\:-top-224 {
    top: -22.4rem
  }

  [dir='ltr'] .lg\:ltr\:-right-224,[dir='ltr'].lg\:ltr\:-right-224 {
    right: -22.4rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-224,[dir='ltr'].lg\:ltr\:-bottom-224 {
    bottom: -22.4rem
  }

  [dir='ltr'] .lg\:ltr\:-left-224,[dir='ltr'].lg\:ltr\:-left-224 {
    left: -22.4rem
  }

  [dir='ltr'] .lg\:ltr\:-top-256,[dir='ltr'].lg\:ltr\:-top-256 {
    top: -25.6rem
  }

  [dir='ltr'] .lg\:ltr\:-right-256,[dir='ltr'].lg\:ltr\:-right-256 {
    right: -25.6rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-256,[dir='ltr'].lg\:ltr\:-bottom-256 {
    bottom: -25.6rem
  }

  [dir='ltr'] .lg\:ltr\:-left-256,[dir='ltr'].lg\:ltr\:-left-256 {
    left: -25.6rem
  }

  [dir='ltr'] .lg\:ltr\:-top-288,[dir='ltr'].lg\:ltr\:-top-288 {
    top: -28.8rem
  }

  [dir='ltr'] .lg\:ltr\:-right-288,[dir='ltr'].lg\:ltr\:-right-288 {
    right: -28.8rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-288,[dir='ltr'].lg\:ltr\:-bottom-288 {
    bottom: -28.8rem
  }

  [dir='ltr'] .lg\:ltr\:-left-288,[dir='ltr'].lg\:ltr\:-left-288 {
    left: -28.8rem
  }

  [dir='ltr'] .lg\:ltr\:-top-320,[dir='ltr'].lg\:ltr\:-top-320 {
    top: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-right-320,[dir='ltr'].lg\:ltr\:-right-320 {
    right: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-320,[dir='ltr'].lg\:ltr\:-bottom-320 {
    bottom: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-left-320,[dir='ltr'].lg\:ltr\:-left-320 {
    left: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-top-360,[dir='ltr'].lg\:ltr\:-top-360 {
    top: -36rem
  }

  [dir='ltr'] .lg\:ltr\:-right-360,[dir='ltr'].lg\:ltr\:-right-360 {
    right: -36rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-360,[dir='ltr'].lg\:ltr\:-bottom-360 {
    bottom: -36rem
  }

  [dir='ltr'] .lg\:ltr\:-left-360,[dir='ltr'].lg\:ltr\:-left-360 {
    left: -36rem
  }

  [dir='ltr'] .lg\:ltr\:-top-384,[dir='ltr'].lg\:ltr\:-top-384 {
    top: -38.4rem
  }

  [dir='ltr'] .lg\:ltr\:-right-384,[dir='ltr'].lg\:ltr\:-right-384 {
    right: -38.4rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-384,[dir='ltr'].lg\:ltr\:-bottom-384 {
    bottom: -38.4rem
  }

  [dir='ltr'] .lg\:ltr\:-left-384,[dir='ltr'].lg\:ltr\:-left-384 {
    left: -38.4rem
  }

  [dir='ltr'] .lg\:ltr\:-top-400,[dir='ltr'].lg\:ltr\:-top-400 {
    top: -40rem
  }

  [dir='ltr'] .lg\:ltr\:-right-400,[dir='ltr'].lg\:ltr\:-right-400 {
    right: -40rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-400,[dir='ltr'].lg\:ltr\:-bottom-400 {
    bottom: -40rem
  }

  [dir='ltr'] .lg\:ltr\:-left-400,[dir='ltr'].lg\:ltr\:-left-400 {
    left: -40rem
  }

  [dir='ltr'] .lg\:ltr\:-top-512,[dir='ltr'].lg\:ltr\:-top-512 {
    top: -51.2rem
  }

  [dir='ltr'] .lg\:ltr\:-right-512,[dir='ltr'].lg\:ltr\:-right-512 {
    right: -51.2rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-512,[dir='ltr'].lg\:ltr\:-bottom-512 {
    bottom: -51.2rem
  }

  [dir='ltr'] .lg\:ltr\:-left-512,[dir='ltr'].lg\:ltr\:-left-512 {
    left: -51.2rem
  }

  [dir='ltr'] .lg\:ltr\:-top-640,[dir='ltr'].lg\:ltr\:-top-640 {
    top: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-right-640,[dir='ltr'].lg\:ltr\:-right-640 {
    right: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-640,[dir='ltr'].lg\:ltr\:-bottom-640 {
    bottom: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-left-640,[dir='ltr'].lg\:ltr\:-left-640 {
    left: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-top-xs,[dir='ltr'].lg\:ltr\:-top-xs {
    top: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-right-xs,[dir='ltr'].lg\:ltr\:-right-xs {
    right: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-xs,[dir='ltr'].lg\:ltr\:-bottom-xs {
    bottom: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-left-xs,[dir='ltr'].lg\:ltr\:-left-xs {
    left: -32rem
  }

  [dir='ltr'] .lg\:ltr\:-top-sm,[dir='ltr'].lg\:ltr\:-top-sm {
    top: -48rem
  }

  [dir='ltr'] .lg\:ltr\:-right-sm,[dir='ltr'].lg\:ltr\:-right-sm {
    right: -48rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-sm,[dir='ltr'].lg\:ltr\:-bottom-sm {
    bottom: -48rem
  }

  [dir='ltr'] .lg\:ltr\:-left-sm,[dir='ltr'].lg\:ltr\:-left-sm {
    left: -48rem
  }

  [dir='ltr'] .lg\:ltr\:-top-md,[dir='ltr'].lg\:ltr\:-top-md {
    top: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-right-md,[dir='ltr'].lg\:ltr\:-right-md {
    right: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-md,[dir='ltr'].lg\:ltr\:-bottom-md {
    bottom: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-left-md,[dir='ltr'].lg\:ltr\:-left-md {
    left: -64rem
  }

  [dir='ltr'] .lg\:ltr\:-top-lg,[dir='ltr'].lg\:ltr\:-top-lg {
    top: -80rem
  }

  [dir='ltr'] .lg\:ltr\:-right-lg,[dir='ltr'].lg\:ltr\:-right-lg {
    right: -80rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-lg,[dir='ltr'].lg\:ltr\:-bottom-lg {
    bottom: -80rem
  }

  [dir='ltr'] .lg\:ltr\:-left-lg,[dir='ltr'].lg\:ltr\:-left-lg {
    left: -80rem
  }

  [dir='ltr'] .lg\:ltr\:-top-xl,[dir='ltr'].lg\:ltr\:-top-xl {
    top: -96rem
  }

  [dir='ltr'] .lg\:ltr\:-right-xl,[dir='ltr'].lg\:ltr\:-right-xl {
    right: -96rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-xl,[dir='ltr'].lg\:ltr\:-bottom-xl {
    bottom: -96rem
  }

  [dir='ltr'] .lg\:ltr\:-left-xl,[dir='ltr'].lg\:ltr\:-left-xl {
    left: -96rem
  }

  [dir='ltr'] .lg\:ltr\:-top-2xl,[dir='ltr'].lg\:ltr\:-top-2xl {
    top: -112rem
  }

  [dir='ltr'] .lg\:ltr\:-right-2xl,[dir='ltr'].lg\:ltr\:-right-2xl {
    right: -112rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-2xl,[dir='ltr'].lg\:ltr\:-bottom-2xl {
    bottom: -112rem
  }

  [dir='ltr'] .lg\:ltr\:-left-2xl,[dir='ltr'].lg\:ltr\:-left-2xl {
    left: -112rem
  }

  [dir='ltr'] .lg\:ltr\:-top-3xl,[dir='ltr'].lg\:ltr\:-top-3xl {
    top: -128rem
  }

  [dir='ltr'] .lg\:ltr\:-right-3xl,[dir='ltr'].lg\:ltr\:-right-3xl {
    right: -128rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-3xl,[dir='ltr'].lg\:ltr\:-bottom-3xl {
    bottom: -128rem
  }

  [dir='ltr'] .lg\:ltr\:-left-3xl,[dir='ltr'].lg\:ltr\:-left-3xl {
    left: -128rem
  }

  [dir='ltr'] .lg\:ltr\:-top-4xl,[dir='ltr'].lg\:ltr\:-top-4xl {
    top: -144rem
  }

  [dir='ltr'] .lg\:ltr\:-right-4xl,[dir='ltr'].lg\:ltr\:-right-4xl {
    right: -144rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-4xl,[dir='ltr'].lg\:ltr\:-bottom-4xl {
    bottom: -144rem
  }

  [dir='ltr'] .lg\:ltr\:-left-4xl,[dir='ltr'].lg\:ltr\:-left-4xl {
    left: -144rem
  }

  [dir='ltr'] .lg\:ltr\:-top-5xl,[dir='ltr'].lg\:ltr\:-top-5xl {
    top: -160rem
  }

  [dir='ltr'] .lg\:ltr\:-right-5xl,[dir='ltr'].lg\:ltr\:-right-5xl {
    right: -160rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-5xl,[dir='ltr'].lg\:ltr\:-bottom-5xl {
    bottom: -160rem
  }

  [dir='ltr'] .lg\:ltr\:-left-5xl,[dir='ltr'].lg\:ltr\:-left-5xl {
    left: -160rem
  }

  [dir='ltr'] .lg\:ltr\:-top-px,[dir='ltr'].lg\:ltr\:-top-px {
    top: -1px
  }

  [dir='ltr'] .lg\:ltr\:-right-px,[dir='ltr'].lg\:ltr\:-right-px {
    right: -1px
  }

  [dir='ltr'] .lg\:ltr\:-bottom-px,[dir='ltr'].lg\:ltr\:-bottom-px {
    bottom: -1px
  }

  [dir='ltr'] .lg\:ltr\:-left-px,[dir='ltr'].lg\:ltr\:-left-px {
    left: -1px
  }

  [dir='ltr'] .lg\:ltr\:-top-0\.5,[dir='ltr'].lg\:ltr\:-top-0\.5 {
    top: -0.05rem
  }

  [dir='ltr'] .lg\:ltr\:-right-0\.5,[dir='ltr'].lg\:ltr\:-right-0\.5 {
    right: -0.05rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-0\.5,[dir='ltr'].lg\:ltr\:-bottom-0\.5 {
    bottom: -0.05rem
  }

  [dir='ltr'] .lg\:ltr\:-left-0\.5,[dir='ltr'].lg\:ltr\:-left-0\.5 {
    left: -0.05rem
  }

  [dir='ltr'] .lg\:ltr\:-top-1\.5,[dir='ltr'].lg\:ltr\:-top-1\.5 {
    top: -0.15rem
  }

  [dir='ltr'] .lg\:ltr\:-right-1\.5,[dir='ltr'].lg\:ltr\:-right-1\.5 {
    right: -0.15rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-1\.5,[dir='ltr'].lg\:ltr\:-bottom-1\.5 {
    bottom: -0.15rem
  }

  [dir='ltr'] .lg\:ltr\:-left-1\.5,[dir='ltr'].lg\:ltr\:-left-1\.5 {
    left: -0.15rem
  }

  [dir='ltr'] .lg\:ltr\:-top-2\.5,[dir='ltr'].lg\:ltr\:-top-2\.5 {
    top: -0.25rem
  }

  [dir='ltr'] .lg\:ltr\:-right-2\.5,[dir='ltr'].lg\:ltr\:-right-2\.5 {
    right: -0.25rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-2\.5,[dir='ltr'].lg\:ltr\:-bottom-2\.5 {
    bottom: -0.25rem
  }

  [dir='ltr'] .lg\:ltr\:-left-2\.5,[dir='ltr'].lg\:ltr\:-left-2\.5 {
    left: -0.25rem
  }

  [dir='ltr'] .lg\:ltr\:-top-3\.5,[dir='ltr'].lg\:ltr\:-top-3\.5 {
    top: -0.35rem
  }

  [dir='ltr'] .lg\:ltr\:-right-3\.5,[dir='ltr'].lg\:ltr\:-right-3\.5 {
    right: -0.35rem
  }

  [dir='ltr'] .lg\:ltr\:-bottom-3\.5,[dir='ltr'].lg\:ltr\:-bottom-3\.5 {
    bottom: -0.35rem
  }

  [dir='ltr'] .lg\:ltr\:-left-3\.5,[dir='ltr'].lg\:ltr\:-left-3\.5 {
    left: -0.35rem
  }

  [dir='ltr'] .lg\:ltr\:top-1\/2,[dir='ltr'].lg\:ltr\:top-1\/2 {
    top: 50%
  }

  [dir='ltr'] .lg\:ltr\:right-1\/2,[dir='ltr'].lg\:ltr\:right-1\/2 {
    right: 50%
  }

  [dir='ltr'] .lg\:ltr\:bottom-1\/2,[dir='ltr'].lg\:ltr\:bottom-1\/2 {
    bottom: 50%
  }

  [dir='ltr'] .lg\:ltr\:left-1\/2,[dir='ltr'].lg\:ltr\:left-1\/2 {
    left: 50%
  }

  [dir='ltr'] .lg\:ltr\:top-1\/3,[dir='ltr'].lg\:ltr\:top-1\/3 {
    top: 33.333333%
  }

  [dir='ltr'] .lg\:ltr\:right-1\/3,[dir='ltr'].lg\:ltr\:right-1\/3 {
    right: 33.333333%
  }

  [dir='ltr'] .lg\:ltr\:bottom-1\/3,[dir='ltr'].lg\:ltr\:bottom-1\/3 {
    bottom: 33.333333%
  }

  [dir='ltr'] .lg\:ltr\:left-1\/3,[dir='ltr'].lg\:ltr\:left-1\/3 {
    left: 33.333333%
  }

  [dir='ltr'] .lg\:ltr\:top-2\/3,[dir='ltr'].lg\:ltr\:top-2\/3 {
    top: 66.666667%
  }

  [dir='ltr'] .lg\:ltr\:right-2\/3,[dir='ltr'].lg\:ltr\:right-2\/3 {
    right: 66.666667%
  }

  [dir='ltr'] .lg\:ltr\:bottom-2\/3,[dir='ltr'].lg\:ltr\:bottom-2\/3 {
    bottom: 66.666667%
  }

  [dir='ltr'] .lg\:ltr\:left-2\/3,[dir='ltr'].lg\:ltr\:left-2\/3 {
    left: 66.666667%
  }

  [dir='ltr'] .lg\:ltr\:top-1\/4,[dir='ltr'].lg\:ltr\:top-1\/4 {
    top: 25%
  }

  [dir='ltr'] .lg\:ltr\:right-1\/4,[dir='ltr'].lg\:ltr\:right-1\/4 {
    right: 25%
  }

  [dir='ltr'] .lg\:ltr\:bottom-1\/4,[dir='ltr'].lg\:ltr\:bottom-1\/4 {
    bottom: 25%
  }

  [dir='ltr'] .lg\:ltr\:left-1\/4,[dir='ltr'].lg\:ltr\:left-1\/4 {
    left: 25%
  }

  [dir='ltr'] .lg\:ltr\:top-2\/4,[dir='ltr'].lg\:ltr\:top-2\/4 {
    top: 50%
  }

  [dir='ltr'] .lg\:ltr\:right-2\/4,[dir='ltr'].lg\:ltr\:right-2\/4 {
    right: 50%
  }

  [dir='ltr'] .lg\:ltr\:bottom-2\/4,[dir='ltr'].lg\:ltr\:bottom-2\/4 {
    bottom: 50%
  }

  [dir='ltr'] .lg\:ltr\:left-2\/4,[dir='ltr'].lg\:ltr\:left-2\/4 {
    left: 50%
  }

  [dir='ltr'] .lg\:ltr\:top-3\/4,[dir='ltr'].lg\:ltr\:top-3\/4 {
    top: 75%
  }

  [dir='ltr'] .lg\:ltr\:right-3\/4,[dir='ltr'].lg\:ltr\:right-3\/4 {
    right: 75%
  }

  [dir='ltr'] .lg\:ltr\:bottom-3\/4,[dir='ltr'].lg\:ltr\:bottom-3\/4 {
    bottom: 75%
  }

  [dir='ltr'] .lg\:ltr\:left-3\/4,[dir='ltr'].lg\:ltr\:left-3\/4 {
    left: 75%
  }

  [dir='ltr'] .lg\:ltr\:top-full,[dir='ltr'].lg\:ltr\:top-full {
    top: 100%
  }

  [dir='ltr'] .lg\:ltr\:right-full,[dir='ltr'].lg\:ltr\:right-full {
    right: 100%
  }

  [dir='ltr'] .lg\:ltr\:bottom-full,[dir='ltr'].lg\:ltr\:bottom-full {
    bottom: 100%
  }

  [dir='ltr'] .lg\:ltr\:left-full,[dir='ltr'].lg\:ltr\:left-full {
    left: 100%
  }

  [dir='ltr'] .lg\:ltr\:-top-1\/2,[dir='ltr'].lg\:ltr\:-top-1\/2 {
    top: -50%
  }

  [dir='ltr'] .lg\:ltr\:-right-1\/2,[dir='ltr'].lg\:ltr\:-right-1\/2 {
    right: -50%
  }

  [dir='ltr'] .lg\:ltr\:-bottom-1\/2,[dir='ltr'].lg\:ltr\:-bottom-1\/2 {
    bottom: -50%
  }

  [dir='ltr'] .lg\:ltr\:-left-1\/2,[dir='ltr'].lg\:ltr\:-left-1\/2 {
    left: -50%
  }

  [dir='ltr'] .lg\:ltr\:-top-1\/3,[dir='ltr'].lg\:ltr\:-top-1\/3 {
    top: -33.333333%
  }

  [dir='ltr'] .lg\:ltr\:-right-1\/3,[dir='ltr'].lg\:ltr\:-right-1\/3 {
    right: -33.333333%
  }

  [dir='ltr'] .lg\:ltr\:-bottom-1\/3,[dir='ltr'].lg\:ltr\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  [dir='ltr'] .lg\:ltr\:-left-1\/3,[dir='ltr'].lg\:ltr\:-left-1\/3 {
    left: -33.333333%
  }

  [dir='ltr'] .lg\:ltr\:-top-2\/3,[dir='ltr'].lg\:ltr\:-top-2\/3 {
    top: -66.666667%
  }

  [dir='ltr'] .lg\:ltr\:-right-2\/3,[dir='ltr'].lg\:ltr\:-right-2\/3 {
    right: -66.666667%
  }

  [dir='ltr'] .lg\:ltr\:-bottom-2\/3,[dir='ltr'].lg\:ltr\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  [dir='ltr'] .lg\:ltr\:-left-2\/3,[dir='ltr'].lg\:ltr\:-left-2\/3 {
    left: -66.666667%
  }

  [dir='ltr'] .lg\:ltr\:-top-1\/4,[dir='ltr'].lg\:ltr\:-top-1\/4 {
    top: -25%
  }

  [dir='ltr'] .lg\:ltr\:-right-1\/4,[dir='ltr'].lg\:ltr\:-right-1\/4 {
    right: -25%
  }

  [dir='ltr'] .lg\:ltr\:-bottom-1\/4,[dir='ltr'].lg\:ltr\:-bottom-1\/4 {
    bottom: -25%
  }

  [dir='ltr'] .lg\:ltr\:-left-1\/4,[dir='ltr'].lg\:ltr\:-left-1\/4 {
    left: -25%
  }

  [dir='ltr'] .lg\:ltr\:-top-2\/4,[dir='ltr'].lg\:ltr\:-top-2\/4 {
    top: -50%
  }

  [dir='ltr'] .lg\:ltr\:-right-2\/4,[dir='ltr'].lg\:ltr\:-right-2\/4 {
    right: -50%
  }

  [dir='ltr'] .lg\:ltr\:-bottom-2\/4,[dir='ltr'].lg\:ltr\:-bottom-2\/4 {
    bottom: -50%
  }

  [dir='ltr'] .lg\:ltr\:-left-2\/4,[dir='ltr'].lg\:ltr\:-left-2\/4 {
    left: -50%
  }

  [dir='ltr'] .lg\:ltr\:-top-3\/4,[dir='ltr'].lg\:ltr\:-top-3\/4 {
    top: -75%
  }

  [dir='ltr'] .lg\:ltr\:-right-3\/4,[dir='ltr'].lg\:ltr\:-right-3\/4 {
    right: -75%
  }

  [dir='ltr'] .lg\:ltr\:-bottom-3\/4,[dir='ltr'].lg\:ltr\:-bottom-3\/4 {
    bottom: -75%
  }

  [dir='ltr'] .lg\:ltr\:-left-3\/4,[dir='ltr'].lg\:ltr\:-left-3\/4 {
    left: -75%
  }

  [dir='ltr'] .lg\:ltr\:-top-full,[dir='ltr'].lg\:ltr\:-top-full {
    top: -100%
  }

  [dir='ltr'] .lg\:ltr\:-right-full,[dir='ltr'].lg\:ltr\:-right-full {
    right: -100%
  }

  [dir='ltr'] .lg\:ltr\:-bottom-full,[dir='ltr'].lg\:ltr\:-bottom-full {
    bottom: -100%
  }

  [dir='ltr'] .lg\:ltr\:-left-full,[dir='ltr'].lg\:ltr\:-left-full {
    left: -100%
  }

  [dir='rtl'] .lg\:rtl\:inset-0,[dir='rtl'].lg\:rtl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  [dir='rtl'] .lg\:rtl\:inset-1,[dir='rtl'].lg\:rtl\:inset-1 {
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:inset-2,[dir='rtl'].lg\:rtl\:inset-2 {
    top: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-3,[dir='rtl'].lg\:rtl\:inset-3 {
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:inset-4,[dir='rtl'].lg\:rtl\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-5,[dir='rtl'].lg\:rtl\:inset-5 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:inset-6,[dir='rtl'].lg\:rtl\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-7,[dir='rtl'].lg\:rtl\:inset-7 {
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
    left: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:inset-8,[dir='rtl'].lg\:rtl\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-9,[dir='rtl'].lg\:rtl\:inset-9 {
    top: 0.9rem;
    right: 0.9rem;
    bottom: 0.9rem;
    left: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:inset-10,[dir='rtl'].lg\:rtl\:inset-10 {
    top: 1.0rem;
    right: 1.0rem;
    bottom: 1.0rem;
    left: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:inset-12,[dir='rtl'].lg\:rtl\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-14,[dir='rtl'].lg\:rtl\:inset-14 {
    top: 1.4rem;
    right: 1.4rem;
    bottom: 1.4rem;
    left: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-16,[dir='rtl'].lg\:rtl\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-20,[dir='rtl'].lg\:rtl\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-24,[dir='rtl'].lg\:rtl\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-28,[dir='rtl'].lg\:rtl\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-32,[dir='rtl'].lg\:rtl\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-36,[dir='rtl'].lg\:rtl\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-40,[dir='rtl'].lg\:rtl\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-44,[dir='rtl'].lg\:rtl\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-48,[dir='rtl'].lg\:rtl\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-52,[dir='rtl'].lg\:rtl\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-56,[dir='rtl'].lg\:rtl\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-60,[dir='rtl'].lg\:rtl\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-64,[dir='rtl'].lg\:rtl\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-68,[dir='rtl'].lg\:rtl\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-72,[dir='rtl'].lg\:rtl\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-76,[dir='rtl'].lg\:rtl\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-80,[dir='rtl'].lg\:rtl\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-84,[dir='rtl'].lg\:rtl\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-88,[dir='rtl'].lg\:rtl\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-92,[dir='rtl'].lg\:rtl\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-96,[dir='rtl'].lg\:rtl\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-128,[dir='rtl'].lg\:rtl\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-136,[dir='rtl'].lg\:rtl\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-160,[dir='rtl'].lg\:rtl\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  [dir='rtl'] .lg\:rtl\:inset-192,[dir='rtl'].lg\:rtl\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-200,[dir='rtl'].lg\:rtl\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  [dir='rtl'] .lg\:rtl\:inset-208,[dir='rtl'].lg\:rtl\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-216,[dir='rtl'].lg\:rtl\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-224,[dir='rtl'].lg\:rtl\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-256,[dir='rtl'].lg\:rtl\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-288,[dir='rtl'].lg\:rtl\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-320,[dir='rtl'].lg\:rtl\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='rtl'] .lg\:rtl\:inset-360,[dir='rtl'].lg\:rtl\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  [dir='rtl'] .lg\:rtl\:inset-384,[dir='rtl'].lg\:rtl\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-400,[dir='rtl'].lg\:rtl\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  [dir='rtl'] .lg\:rtl\:inset-512,[dir='rtl'].lg\:rtl\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-640,[dir='rtl'].lg\:rtl\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='rtl'] .lg\:rtl\:inset-auto,[dir='rtl'].lg\:rtl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  [dir='rtl'] .lg\:rtl\:inset-xs,[dir='rtl'].lg\:rtl\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='rtl'] .lg\:rtl\:inset-sm,[dir='rtl'].lg\:rtl\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  [dir='rtl'] .lg\:rtl\:inset-md,[dir='rtl'].lg\:rtl\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='rtl'] .lg\:rtl\:inset-lg,[dir='rtl'].lg\:rtl\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  [dir='rtl'] .lg\:rtl\:inset-xl,[dir='rtl'].lg\:rtl\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  [dir='rtl'] .lg\:rtl\:inset-2xl,[dir='rtl'].lg\:rtl\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  [dir='rtl'] .lg\:rtl\:inset-3xl,[dir='rtl'].lg\:rtl\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  [dir='rtl'] .lg\:rtl\:inset-4xl,[dir='rtl'].lg\:rtl\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  [dir='rtl'] .lg\:rtl\:inset-5xl,[dir='rtl'].lg\:rtl\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  [dir='rtl'] .lg\:rtl\:inset-px,[dir='rtl'].lg\:rtl\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  [dir='rtl'] .lg\:rtl\:inset-0\.5,[dir='rtl'].lg\:rtl\:inset-0\.5 {
    top: 0.05rem;
    right: 0.05rem;
    bottom: 0.05rem;
    left: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:inset-1\.5,[dir='rtl'].lg\:rtl\:inset-1\.5 {
    top: 0.15rem;
    right: 0.15rem;
    bottom: 0.15rem;
    left: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:inset-2\.5,[dir='rtl'].lg\:rtl\:inset-2\.5 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:inset-3\.5,[dir='rtl'].lg\:rtl\:inset-3\.5 {
    top: 0.35rem;
    right: 0.35rem;
    bottom: 0.35rem;
    left: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-1,[dir='rtl'].lg\:rtl\:-inset-1 {
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-2,[dir='rtl'].lg\:rtl\:-inset-2 {
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-3,[dir='rtl'].lg\:rtl\:-inset-3 {
    top: -0.3rem;
    right: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-4,[dir='rtl'].lg\:rtl\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-5,[dir='rtl'].lg\:rtl\:-inset-5 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-6,[dir='rtl'].lg\:rtl\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-7,[dir='rtl'].lg\:rtl\:-inset-7 {
    top: -0.7rem;
    right: -0.7rem;
    bottom: -0.7rem;
    left: -0.7rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-8,[dir='rtl'].lg\:rtl\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-9,[dir='rtl'].lg\:rtl\:-inset-9 {
    top: -0.9rem;
    right: -0.9rem;
    bottom: -0.9rem;
    left: -0.9rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-10,[dir='rtl'].lg\:rtl\:-inset-10 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-12,[dir='rtl'].lg\:rtl\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-14,[dir='rtl'].lg\:rtl\:-inset-14 {
    top: -1.4rem;
    right: -1.4rem;
    bottom: -1.4rem;
    left: -1.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-16,[dir='rtl'].lg\:rtl\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-20,[dir='rtl'].lg\:rtl\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-24,[dir='rtl'].lg\:rtl\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-28,[dir='rtl'].lg\:rtl\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-32,[dir='rtl'].lg\:rtl\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-36,[dir='rtl'].lg\:rtl\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-40,[dir='rtl'].lg\:rtl\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-44,[dir='rtl'].lg\:rtl\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-48,[dir='rtl'].lg\:rtl\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-52,[dir='rtl'].lg\:rtl\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-56,[dir='rtl'].lg\:rtl\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-60,[dir='rtl'].lg\:rtl\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-64,[dir='rtl'].lg\:rtl\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-68,[dir='rtl'].lg\:rtl\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-72,[dir='rtl'].lg\:rtl\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-76,[dir='rtl'].lg\:rtl\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-80,[dir='rtl'].lg\:rtl\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-84,[dir='rtl'].lg\:rtl\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-88,[dir='rtl'].lg\:rtl\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-92,[dir='rtl'].lg\:rtl\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-96,[dir='rtl'].lg\:rtl\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-128,[dir='rtl'].lg\:rtl\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-136,[dir='rtl'].lg\:rtl\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-160,[dir='rtl'].lg\:rtl\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-192,[dir='rtl'].lg\:rtl\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-200,[dir='rtl'].lg\:rtl\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-208,[dir='rtl'].lg\:rtl\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-216,[dir='rtl'].lg\:rtl\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-224,[dir='rtl'].lg\:rtl\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-256,[dir='rtl'].lg\:rtl\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-288,[dir='rtl'].lg\:rtl\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-320,[dir='rtl'].lg\:rtl\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-360,[dir='rtl'].lg\:rtl\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-384,[dir='rtl'].lg\:rtl\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-400,[dir='rtl'].lg\:rtl\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-512,[dir='rtl'].lg\:rtl\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-640,[dir='rtl'].lg\:rtl\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-xs,[dir='rtl'].lg\:rtl\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-sm,[dir='rtl'].lg\:rtl\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-md,[dir='rtl'].lg\:rtl\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-lg,[dir='rtl'].lg\:rtl\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-xl,[dir='rtl'].lg\:rtl\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-2xl,[dir='rtl'].lg\:rtl\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-3xl,[dir='rtl'].lg\:rtl\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-4xl,[dir='rtl'].lg\:rtl\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-5xl,[dir='rtl'].lg\:rtl\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-px,[dir='rtl'].lg\:rtl\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  [dir='rtl'] .lg\:rtl\:-inset-0\.5,[dir='rtl'].lg\:rtl\:-inset-0\.5 {
    top: -0.05rem;
    right: -0.05rem;
    bottom: -0.05rem;
    left: -0.05rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-1\.5,[dir='rtl'].lg\:rtl\:-inset-1\.5 {
    top: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    left: -0.15rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-2\.5,[dir='rtl'].lg\:rtl\:-inset-2\.5 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-3\.5,[dir='rtl'].lg\:rtl\:-inset-3\.5 {
    top: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    left: -0.35rem
  }

  [dir='rtl'] .lg\:rtl\:inset-1\/2,[dir='rtl'].lg\:rtl\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='rtl'] .lg\:rtl\:inset-1\/3,[dir='rtl'].lg\:rtl\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  [dir='rtl'] .lg\:rtl\:inset-2\/3,[dir='rtl'].lg\:rtl\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  [dir='rtl'] .lg\:rtl\:inset-1\/4,[dir='rtl'].lg\:rtl\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  [dir='rtl'] .lg\:rtl\:inset-2\/4,[dir='rtl'].lg\:rtl\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='rtl'] .lg\:rtl\:inset-3\/4,[dir='rtl'].lg\:rtl\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  [dir='rtl'] .lg\:rtl\:inset-full,[dir='rtl'].lg\:rtl\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  [dir='rtl'] .lg\:rtl\:-inset-1\/2,[dir='rtl'].lg\:rtl\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='rtl'] .lg\:rtl\:-inset-1\/3,[dir='rtl'].lg\:rtl\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  [dir='rtl'] .lg\:rtl\:-inset-2\/3,[dir='rtl'].lg\:rtl\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  [dir='rtl'] .lg\:rtl\:-inset-1\/4,[dir='rtl'].lg\:rtl\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  [dir='rtl'] .lg\:rtl\:-inset-2\/4,[dir='rtl'].lg\:rtl\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='rtl'] .lg\:rtl\:-inset-3\/4,[dir='rtl'].lg\:rtl\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  [dir='rtl'] .lg\:rtl\:-inset-full,[dir='rtl'].lg\:rtl\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  [dir='rtl'] .lg\:rtl\:inset-y-0,[dir='rtl'].lg\:rtl\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  [dir='rtl'] .lg\:rtl\:inset-x-0,[dir='rtl'].lg\:rtl\:inset-x-0 {
    right: 0;
    left: 0
  }

  [dir='rtl'] .lg\:rtl\:inset-y-1,[dir='rtl'].lg\:rtl\:inset-y-1 {
    top: 0.1rem;
    bottom: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-1,[dir='rtl'].lg\:rtl\:inset-x-1 {
    right: 0.1rem;
    left: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-2,[dir='rtl'].lg\:rtl\:inset-y-2 {
    top: 0.2rem;
    bottom: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-2,[dir='rtl'].lg\:rtl\:inset-x-2 {
    right: 0.2rem;
    left: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-3,[dir='rtl'].lg\:rtl\:inset-y-3 {
    top: 0.3rem;
    bottom: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-3,[dir='rtl'].lg\:rtl\:inset-x-3 {
    right: 0.3rem;
    left: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-4,[dir='rtl'].lg\:rtl\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-4,[dir='rtl'].lg\:rtl\:inset-x-4 {
    right: 0.4rem;
    left: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-5,[dir='rtl'].lg\:rtl\:inset-y-5 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-5,[dir='rtl'].lg\:rtl\:inset-x-5 {
    right: 0.5rem;
    left: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-6,[dir='rtl'].lg\:rtl\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-6,[dir='rtl'].lg\:rtl\:inset-x-6 {
    right: 0.6rem;
    left: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-7,[dir='rtl'].lg\:rtl\:inset-y-7 {
    top: 0.7rem;
    bottom: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-7,[dir='rtl'].lg\:rtl\:inset-x-7 {
    right: 0.7rem;
    left: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-8,[dir='rtl'].lg\:rtl\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-8,[dir='rtl'].lg\:rtl\:inset-x-8 {
    right: 0.8rem;
    left: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-9,[dir='rtl'].lg\:rtl\:inset-y-9 {
    top: 0.9rem;
    bottom: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-9,[dir='rtl'].lg\:rtl\:inset-x-9 {
    right: 0.9rem;
    left: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-10,[dir='rtl'].lg\:rtl\:inset-y-10 {
    top: 1.0rem;
    bottom: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-10,[dir='rtl'].lg\:rtl\:inset-x-10 {
    right: 1.0rem;
    left: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-12,[dir='rtl'].lg\:rtl\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-12,[dir='rtl'].lg\:rtl\:inset-x-12 {
    right: 1.2rem;
    left: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-14,[dir='rtl'].lg\:rtl\:inset-y-14 {
    top: 1.4rem;
    bottom: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-14,[dir='rtl'].lg\:rtl\:inset-x-14 {
    right: 1.4rem;
    left: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-16,[dir='rtl'].lg\:rtl\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-16,[dir='rtl'].lg\:rtl\:inset-x-16 {
    right: 1.6rem;
    left: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-20,[dir='rtl'].lg\:rtl\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-20,[dir='rtl'].lg\:rtl\:inset-x-20 {
    right: 2rem;
    left: 2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-24,[dir='rtl'].lg\:rtl\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-24,[dir='rtl'].lg\:rtl\:inset-x-24 {
    right: 2.4rem;
    left: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-28,[dir='rtl'].lg\:rtl\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-28,[dir='rtl'].lg\:rtl\:inset-x-28 {
    right: 2.8rem;
    left: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-32,[dir='rtl'].lg\:rtl\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-32,[dir='rtl'].lg\:rtl\:inset-x-32 {
    right: 3.2rem;
    left: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-36,[dir='rtl'].lg\:rtl\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-36,[dir='rtl'].lg\:rtl\:inset-x-36 {
    right: 3.6rem;
    left: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-40,[dir='rtl'].lg\:rtl\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-40,[dir='rtl'].lg\:rtl\:inset-x-40 {
    right: 4rem;
    left: 4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-44,[dir='rtl'].lg\:rtl\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-44,[dir='rtl'].lg\:rtl\:inset-x-44 {
    right: 4.4rem;
    left: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-48,[dir='rtl'].lg\:rtl\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-48,[dir='rtl'].lg\:rtl\:inset-x-48 {
    right: 4.8rem;
    left: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-52,[dir='rtl'].lg\:rtl\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-52,[dir='rtl'].lg\:rtl\:inset-x-52 {
    right: 5.2rem;
    left: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-56,[dir='rtl'].lg\:rtl\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-56,[dir='rtl'].lg\:rtl\:inset-x-56 {
    right: 5.6rem;
    left: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-60,[dir='rtl'].lg\:rtl\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-60,[dir='rtl'].lg\:rtl\:inset-x-60 {
    right: 6rem;
    left: 6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-64,[dir='rtl'].lg\:rtl\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-64,[dir='rtl'].lg\:rtl\:inset-x-64 {
    right: 6.4rem;
    left: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-68,[dir='rtl'].lg\:rtl\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-68,[dir='rtl'].lg\:rtl\:inset-x-68 {
    right: 6.8rem;
    left: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-72,[dir='rtl'].lg\:rtl\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-72,[dir='rtl'].lg\:rtl\:inset-x-72 {
    right: 7.2rem;
    left: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-76,[dir='rtl'].lg\:rtl\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-76,[dir='rtl'].lg\:rtl\:inset-x-76 {
    right: 7.6rem;
    left: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-80,[dir='rtl'].lg\:rtl\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-80,[dir='rtl'].lg\:rtl\:inset-x-80 {
    right: 8rem;
    left: 8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-84,[dir='rtl'].lg\:rtl\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-84,[dir='rtl'].lg\:rtl\:inset-x-84 {
    right: 8.4rem;
    left: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-88,[dir='rtl'].lg\:rtl\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-88,[dir='rtl'].lg\:rtl\:inset-x-88 {
    right: 8.8rem;
    left: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-92,[dir='rtl'].lg\:rtl\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-92,[dir='rtl'].lg\:rtl\:inset-x-92 {
    right: 9.2rem;
    left: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-96,[dir='rtl'].lg\:rtl\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-96,[dir='rtl'].lg\:rtl\:inset-x-96 {
    right: 9.6rem;
    left: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-128,[dir='rtl'].lg\:rtl\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-128,[dir='rtl'].lg\:rtl\:inset-x-128 {
    right: 12.8rem;
    left: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-136,[dir='rtl'].lg\:rtl\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-136,[dir='rtl'].lg\:rtl\:inset-x-136 {
    right: 13.6rem;
    left: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-160,[dir='rtl'].lg\:rtl\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-160,[dir='rtl'].lg\:rtl\:inset-x-160 {
    right: 16rem;
    left: 16rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-192,[dir='rtl'].lg\:rtl\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-192,[dir='rtl'].lg\:rtl\:inset-x-192 {
    right: 19.2rem;
    left: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-200,[dir='rtl'].lg\:rtl\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-200,[dir='rtl'].lg\:rtl\:inset-x-200 {
    right: 20rem;
    left: 20rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-208,[dir='rtl'].lg\:rtl\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-208,[dir='rtl'].lg\:rtl\:inset-x-208 {
    right: 20.8rem;
    left: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-216,[dir='rtl'].lg\:rtl\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-216,[dir='rtl'].lg\:rtl\:inset-x-216 {
    right: 21.6rem;
    left: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-224,[dir='rtl'].lg\:rtl\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-224,[dir='rtl'].lg\:rtl\:inset-x-224 {
    right: 22.4rem;
    left: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-256,[dir='rtl'].lg\:rtl\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-256,[dir='rtl'].lg\:rtl\:inset-x-256 {
    right: 25.6rem;
    left: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-288,[dir='rtl'].lg\:rtl\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-288,[dir='rtl'].lg\:rtl\:inset-x-288 {
    right: 28.8rem;
    left: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-320,[dir='rtl'].lg\:rtl\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-320,[dir='rtl'].lg\:rtl\:inset-x-320 {
    right: 32rem;
    left: 32rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-360,[dir='rtl'].lg\:rtl\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-360,[dir='rtl'].lg\:rtl\:inset-x-360 {
    right: 36rem;
    left: 36rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-384,[dir='rtl'].lg\:rtl\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-384,[dir='rtl'].lg\:rtl\:inset-x-384 {
    right: 38.4rem;
    left: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-400,[dir='rtl'].lg\:rtl\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-400,[dir='rtl'].lg\:rtl\:inset-x-400 {
    right: 40rem;
    left: 40rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-512,[dir='rtl'].lg\:rtl\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-512,[dir='rtl'].lg\:rtl\:inset-x-512 {
    right: 51.2rem;
    left: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-640,[dir='rtl'].lg\:rtl\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-640,[dir='rtl'].lg\:rtl\:inset-x-640 {
    right: 64rem;
    left: 64rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-auto,[dir='rtl'].lg\:rtl\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  [dir='rtl'] .lg\:rtl\:inset-x-auto,[dir='rtl'].lg\:rtl\:inset-x-auto {
    right: auto;
    left: auto
  }

  [dir='rtl'] .lg\:rtl\:inset-y-xs,[dir='rtl'].lg\:rtl\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-xs,[dir='rtl'].lg\:rtl\:inset-x-xs {
    right: 32rem;
    left: 32rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-sm,[dir='rtl'].lg\:rtl\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-sm,[dir='rtl'].lg\:rtl\:inset-x-sm {
    right: 48rem;
    left: 48rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-md,[dir='rtl'].lg\:rtl\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-md,[dir='rtl'].lg\:rtl\:inset-x-md {
    right: 64rem;
    left: 64rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-lg,[dir='rtl'].lg\:rtl\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-lg,[dir='rtl'].lg\:rtl\:inset-x-lg {
    right: 80rem;
    left: 80rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-xl,[dir='rtl'].lg\:rtl\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-xl,[dir='rtl'].lg\:rtl\:inset-x-xl {
    right: 96rem;
    left: 96rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-2xl,[dir='rtl'].lg\:rtl\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-2xl,[dir='rtl'].lg\:rtl\:inset-x-2xl {
    right: 112rem;
    left: 112rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-3xl,[dir='rtl'].lg\:rtl\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-3xl,[dir='rtl'].lg\:rtl\:inset-x-3xl {
    right: 128rem;
    left: 128rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-4xl,[dir='rtl'].lg\:rtl\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-4xl,[dir='rtl'].lg\:rtl\:inset-x-4xl {
    right: 144rem;
    left: 144rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-5xl,[dir='rtl'].lg\:rtl\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-5xl,[dir='rtl'].lg\:rtl\:inset-x-5xl {
    right: 160rem;
    left: 160rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-px,[dir='rtl'].lg\:rtl\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  [dir='rtl'] .lg\:rtl\:inset-x-px,[dir='rtl'].lg\:rtl\:inset-x-px {
    right: 1px;
    left: 1px
  }

  [dir='rtl'] .lg\:rtl\:inset-y-0\.5,[dir='rtl'].lg\:rtl\:inset-y-0\.5 {
    top: 0.05rem;
    bottom: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-0\.5,[dir='rtl'].lg\:rtl\:inset-x-0\.5 {
    right: 0.05rem;
    left: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-1\.5,[dir='rtl'].lg\:rtl\:inset-y-1\.5 {
    top: 0.15rem;
    bottom: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-1\.5,[dir='rtl'].lg\:rtl\:inset-x-1\.5 {
    right: 0.15rem;
    left: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-2\.5,[dir='rtl'].lg\:rtl\:inset-y-2\.5 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-2\.5,[dir='rtl'].lg\:rtl\:inset-x-2\.5 {
    right: 0.25rem;
    left: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-3\.5,[dir='rtl'].lg\:rtl\:inset-y-3\.5 {
    top: 0.35rem;
    bottom: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:inset-x-3\.5,[dir='rtl'].lg\:rtl\:inset-x-3\.5 {
    right: 0.35rem;
    left: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-1,[dir='rtl'].lg\:rtl\:-inset-y-1 {
    top: -0.1rem;
    bottom: -0.1rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-1,[dir='rtl'].lg\:rtl\:-inset-x-1 {
    right: -0.1rem;
    left: -0.1rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-2,[dir='rtl'].lg\:rtl\:-inset-y-2 {
    top: -0.2rem;
    bottom: -0.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-2,[dir='rtl'].lg\:rtl\:-inset-x-2 {
    right: -0.2rem;
    left: -0.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-3,[dir='rtl'].lg\:rtl\:-inset-y-3 {
    top: -0.3rem;
    bottom: -0.3rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-3,[dir='rtl'].lg\:rtl\:-inset-x-3 {
    right: -0.3rem;
    left: -0.3rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-4,[dir='rtl'].lg\:rtl\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-4,[dir='rtl'].lg\:rtl\:-inset-x-4 {
    right: -0.4rem;
    left: -0.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-5,[dir='rtl'].lg\:rtl\:-inset-y-5 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-5,[dir='rtl'].lg\:rtl\:-inset-x-5 {
    right: -0.5rem;
    left: -0.5rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-6,[dir='rtl'].lg\:rtl\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-6,[dir='rtl'].lg\:rtl\:-inset-x-6 {
    right: -0.6rem;
    left: -0.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-7,[dir='rtl'].lg\:rtl\:-inset-y-7 {
    top: -0.7rem;
    bottom: -0.7rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-7,[dir='rtl'].lg\:rtl\:-inset-x-7 {
    right: -0.7rem;
    left: -0.7rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-8,[dir='rtl'].lg\:rtl\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-8,[dir='rtl'].lg\:rtl\:-inset-x-8 {
    right: -0.8rem;
    left: -0.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-9,[dir='rtl'].lg\:rtl\:-inset-y-9 {
    top: -0.9rem;
    bottom: -0.9rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-9,[dir='rtl'].lg\:rtl\:-inset-x-9 {
    right: -0.9rem;
    left: -0.9rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-10,[dir='rtl'].lg\:rtl\:-inset-y-10 {
    top: -1rem;
    bottom: -1rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-10,[dir='rtl'].lg\:rtl\:-inset-x-10 {
    right: -1rem;
    left: -1rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-12,[dir='rtl'].lg\:rtl\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-12,[dir='rtl'].lg\:rtl\:-inset-x-12 {
    right: -1.2rem;
    left: -1.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-14,[dir='rtl'].lg\:rtl\:-inset-y-14 {
    top: -1.4rem;
    bottom: -1.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-14,[dir='rtl'].lg\:rtl\:-inset-x-14 {
    right: -1.4rem;
    left: -1.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-16,[dir='rtl'].lg\:rtl\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-16,[dir='rtl'].lg\:rtl\:-inset-x-16 {
    right: -1.6rem;
    left: -1.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-20,[dir='rtl'].lg\:rtl\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-20,[dir='rtl'].lg\:rtl\:-inset-x-20 {
    right: -2rem;
    left: -2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-24,[dir='rtl'].lg\:rtl\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-24,[dir='rtl'].lg\:rtl\:-inset-x-24 {
    right: -2.4rem;
    left: -2.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-28,[dir='rtl'].lg\:rtl\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-28,[dir='rtl'].lg\:rtl\:-inset-x-28 {
    right: -2.8rem;
    left: -2.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-32,[dir='rtl'].lg\:rtl\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-32,[dir='rtl'].lg\:rtl\:-inset-x-32 {
    right: -3.2rem;
    left: -3.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-36,[dir='rtl'].lg\:rtl\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-36,[dir='rtl'].lg\:rtl\:-inset-x-36 {
    right: -3.6rem;
    left: -3.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-40,[dir='rtl'].lg\:rtl\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-40,[dir='rtl'].lg\:rtl\:-inset-x-40 {
    right: -4rem;
    left: -4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-44,[dir='rtl'].lg\:rtl\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-44,[dir='rtl'].lg\:rtl\:-inset-x-44 {
    right: -4.4rem;
    left: -4.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-48,[dir='rtl'].lg\:rtl\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-48,[dir='rtl'].lg\:rtl\:-inset-x-48 {
    right: -4.8rem;
    left: -4.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-52,[dir='rtl'].lg\:rtl\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-52,[dir='rtl'].lg\:rtl\:-inset-x-52 {
    right: -5.2rem;
    left: -5.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-56,[dir='rtl'].lg\:rtl\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-56,[dir='rtl'].lg\:rtl\:-inset-x-56 {
    right: -5.6rem;
    left: -5.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-60,[dir='rtl'].lg\:rtl\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-60,[dir='rtl'].lg\:rtl\:-inset-x-60 {
    right: -6rem;
    left: -6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-64,[dir='rtl'].lg\:rtl\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-64,[dir='rtl'].lg\:rtl\:-inset-x-64 {
    right: -6.4rem;
    left: -6.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-68,[dir='rtl'].lg\:rtl\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-68,[dir='rtl'].lg\:rtl\:-inset-x-68 {
    right: -6.8rem;
    left: -6.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-72,[dir='rtl'].lg\:rtl\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-72,[dir='rtl'].lg\:rtl\:-inset-x-72 {
    right: -7.2rem;
    left: -7.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-76,[dir='rtl'].lg\:rtl\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-76,[dir='rtl'].lg\:rtl\:-inset-x-76 {
    right: -7.6rem;
    left: -7.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-80,[dir='rtl'].lg\:rtl\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-80,[dir='rtl'].lg\:rtl\:-inset-x-80 {
    right: -8rem;
    left: -8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-84,[dir='rtl'].lg\:rtl\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-84,[dir='rtl'].lg\:rtl\:-inset-x-84 {
    right: -8.4rem;
    left: -8.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-88,[dir='rtl'].lg\:rtl\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-88,[dir='rtl'].lg\:rtl\:-inset-x-88 {
    right: -8.8rem;
    left: -8.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-92,[dir='rtl'].lg\:rtl\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-92,[dir='rtl'].lg\:rtl\:-inset-x-92 {
    right: -9.2rem;
    left: -9.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-96,[dir='rtl'].lg\:rtl\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-96,[dir='rtl'].lg\:rtl\:-inset-x-96 {
    right: -9.6rem;
    left: -9.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-128,[dir='rtl'].lg\:rtl\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-128,[dir='rtl'].lg\:rtl\:-inset-x-128 {
    right: -12.8rem;
    left: -12.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-136,[dir='rtl'].lg\:rtl\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-136,[dir='rtl'].lg\:rtl\:-inset-x-136 {
    right: -13.6rem;
    left: -13.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-160,[dir='rtl'].lg\:rtl\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-160,[dir='rtl'].lg\:rtl\:-inset-x-160 {
    right: -16rem;
    left: -16rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-192,[dir='rtl'].lg\:rtl\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-192,[dir='rtl'].lg\:rtl\:-inset-x-192 {
    right: -19.2rem;
    left: -19.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-200,[dir='rtl'].lg\:rtl\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-200,[dir='rtl'].lg\:rtl\:-inset-x-200 {
    right: -20rem;
    left: -20rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-208,[dir='rtl'].lg\:rtl\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-208,[dir='rtl'].lg\:rtl\:-inset-x-208 {
    right: -20.8rem;
    left: -20.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-216,[dir='rtl'].lg\:rtl\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-216,[dir='rtl'].lg\:rtl\:-inset-x-216 {
    right: -21.6rem;
    left: -21.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-224,[dir='rtl'].lg\:rtl\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-224,[dir='rtl'].lg\:rtl\:-inset-x-224 {
    right: -22.4rem;
    left: -22.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-256,[dir='rtl'].lg\:rtl\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-256,[dir='rtl'].lg\:rtl\:-inset-x-256 {
    right: -25.6rem;
    left: -25.6rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-288,[dir='rtl'].lg\:rtl\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-288,[dir='rtl'].lg\:rtl\:-inset-x-288 {
    right: -28.8rem;
    left: -28.8rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-320,[dir='rtl'].lg\:rtl\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-320,[dir='rtl'].lg\:rtl\:-inset-x-320 {
    right: -32rem;
    left: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-360,[dir='rtl'].lg\:rtl\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-360,[dir='rtl'].lg\:rtl\:-inset-x-360 {
    right: -36rem;
    left: -36rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-384,[dir='rtl'].lg\:rtl\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-384,[dir='rtl'].lg\:rtl\:-inset-x-384 {
    right: -38.4rem;
    left: -38.4rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-400,[dir='rtl'].lg\:rtl\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-400,[dir='rtl'].lg\:rtl\:-inset-x-400 {
    right: -40rem;
    left: -40rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-512,[dir='rtl'].lg\:rtl\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-512,[dir='rtl'].lg\:rtl\:-inset-x-512 {
    right: -51.2rem;
    left: -51.2rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-640,[dir='rtl'].lg\:rtl\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-640,[dir='rtl'].lg\:rtl\:-inset-x-640 {
    right: -64rem;
    left: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-xs,[dir='rtl'].lg\:rtl\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-xs,[dir='rtl'].lg\:rtl\:-inset-x-xs {
    right: -32rem;
    left: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-sm,[dir='rtl'].lg\:rtl\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-sm,[dir='rtl'].lg\:rtl\:-inset-x-sm {
    right: -48rem;
    left: -48rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-md,[dir='rtl'].lg\:rtl\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-md,[dir='rtl'].lg\:rtl\:-inset-x-md {
    right: -64rem;
    left: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-lg,[dir='rtl'].lg\:rtl\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-lg,[dir='rtl'].lg\:rtl\:-inset-x-lg {
    right: -80rem;
    left: -80rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-xl,[dir='rtl'].lg\:rtl\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-xl,[dir='rtl'].lg\:rtl\:-inset-x-xl {
    right: -96rem;
    left: -96rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-2xl,[dir='rtl'].lg\:rtl\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-2xl,[dir='rtl'].lg\:rtl\:-inset-x-2xl {
    right: -112rem;
    left: -112rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-3xl,[dir='rtl'].lg\:rtl\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-3xl,[dir='rtl'].lg\:rtl\:-inset-x-3xl {
    right: -128rem;
    left: -128rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-4xl,[dir='rtl'].lg\:rtl\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-4xl,[dir='rtl'].lg\:rtl\:-inset-x-4xl {
    right: -144rem;
    left: -144rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-5xl,[dir='rtl'].lg\:rtl\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-5xl,[dir='rtl'].lg\:rtl\:-inset-x-5xl {
    right: -160rem;
    left: -160rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-px,[dir='rtl'].lg\:rtl\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-px,[dir='rtl'].lg\:rtl\:-inset-x-px {
    right: -1px;
    left: -1px
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-0\.5,[dir='rtl'].lg\:rtl\:-inset-y-0\.5 {
    top: -0.05rem;
    bottom: -0.05rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-0\.5,[dir='rtl'].lg\:rtl\:-inset-x-0\.5 {
    right: -0.05rem;
    left: -0.05rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-1\.5,[dir='rtl'].lg\:rtl\:-inset-y-1\.5 {
    top: -0.15rem;
    bottom: -0.15rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-1\.5,[dir='rtl'].lg\:rtl\:-inset-x-1\.5 {
    right: -0.15rem;
    left: -0.15rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-2\.5,[dir='rtl'].lg\:rtl\:-inset-y-2\.5 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-2\.5,[dir='rtl'].lg\:rtl\:-inset-x-2\.5 {
    right: -0.25rem;
    left: -0.25rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-3\.5,[dir='rtl'].lg\:rtl\:-inset-y-3\.5 {
    top: -0.35rem;
    bottom: -0.35rem
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-3\.5,[dir='rtl'].lg\:rtl\:-inset-x-3\.5 {
    right: -0.35rem;
    left: -0.35rem
  }

  [dir='rtl'] .lg\:rtl\:inset-y-1\/2,[dir='rtl'].lg\:rtl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  [dir='rtl'] .lg\:rtl\:inset-x-1\/2,[dir='rtl'].lg\:rtl\:inset-x-1\/2 {
    right: 50%;
    left: 50%
  }

  [dir='rtl'] .lg\:rtl\:inset-y-1\/3,[dir='rtl'].lg\:rtl\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  [dir='rtl'] .lg\:rtl\:inset-x-1\/3,[dir='rtl'].lg\:rtl\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%
  }

  [dir='rtl'] .lg\:rtl\:inset-y-2\/3,[dir='rtl'].lg\:rtl\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  [dir='rtl'] .lg\:rtl\:inset-x-2\/3,[dir='rtl'].lg\:rtl\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%
  }

  [dir='rtl'] .lg\:rtl\:inset-y-1\/4,[dir='rtl'].lg\:rtl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  [dir='rtl'] .lg\:rtl\:inset-x-1\/4,[dir='rtl'].lg\:rtl\:inset-x-1\/4 {
    right: 25%;
    left: 25%
  }

  [dir='rtl'] .lg\:rtl\:inset-y-2\/4,[dir='rtl'].lg\:rtl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  [dir='rtl'] .lg\:rtl\:inset-x-2\/4,[dir='rtl'].lg\:rtl\:inset-x-2\/4 {
    right: 50%;
    left: 50%
  }

  [dir='rtl'] .lg\:rtl\:inset-y-3\/4,[dir='rtl'].lg\:rtl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  [dir='rtl'] .lg\:rtl\:inset-x-3\/4,[dir='rtl'].lg\:rtl\:inset-x-3\/4 {
    right: 75%;
    left: 75%
  }

  [dir='rtl'] .lg\:rtl\:inset-y-full,[dir='rtl'].lg\:rtl\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  [dir='rtl'] .lg\:rtl\:inset-x-full,[dir='rtl'].lg\:rtl\:inset-x-full {
    right: 100%;
    left: 100%
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-1\/2,[dir='rtl'].lg\:rtl\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-1\/2,[dir='rtl'].lg\:rtl\:-inset-x-1\/2 {
    right: -50%;
    left: -50%
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-1\/3,[dir='rtl'].lg\:rtl\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-1\/3,[dir='rtl'].lg\:rtl\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-2\/3,[dir='rtl'].lg\:rtl\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-2\/3,[dir='rtl'].lg\:rtl\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-1\/4,[dir='rtl'].lg\:rtl\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-1\/4,[dir='rtl'].lg\:rtl\:-inset-x-1\/4 {
    right: -25%;
    left: -25%
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-2\/4,[dir='rtl'].lg\:rtl\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-2\/4,[dir='rtl'].lg\:rtl\:-inset-x-2\/4 {
    right: -50%;
    left: -50%
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-3\/4,[dir='rtl'].lg\:rtl\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-3\/4,[dir='rtl'].lg\:rtl\:-inset-x-3\/4 {
    right: -75%;
    left: -75%
  }

  [dir='rtl'] .lg\:rtl\:-inset-y-full,[dir='rtl'].lg\:rtl\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  [dir='rtl'] .lg\:rtl\:-inset-x-full,[dir='rtl'].lg\:rtl\:-inset-x-full {
    right: -100%;
    left: -100%
  }

  [dir='rtl'] .lg\:rtl\:top-0,[dir='rtl'].lg\:rtl\:top-0 {
    top: 0
  }

  [dir='rtl'] .lg\:rtl\:right-0,[dir='rtl'].lg\:rtl\:right-0 {
    right: 0
  }

  [dir='rtl'] .lg\:rtl\:bottom-0,[dir='rtl'].lg\:rtl\:bottom-0 {
    bottom: 0
  }

  [dir='rtl'] .lg\:rtl\:left-0,[dir='rtl'].lg\:rtl\:left-0 {
    left: 0
  }

  [dir='rtl'] .lg\:rtl\:top-1,[dir='rtl'].lg\:rtl\:top-1 {
    top: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:right-1,[dir='rtl'].lg\:rtl\:right-1 {
    right: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-1,[dir='rtl'].lg\:rtl\:bottom-1 {
    bottom: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:left-1,[dir='rtl'].lg\:rtl\:left-1 {
    left: 0.1rem
  }

  [dir='rtl'] .lg\:rtl\:top-2,[dir='rtl'].lg\:rtl\:top-2 {
    top: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:right-2,[dir='rtl'].lg\:rtl\:right-2 {
    right: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-2,[dir='rtl'].lg\:rtl\:bottom-2 {
    bottom: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:left-2,[dir='rtl'].lg\:rtl\:left-2 {
    left: 0.2rem
  }

  [dir='rtl'] .lg\:rtl\:top-3,[dir='rtl'].lg\:rtl\:top-3 {
    top: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:right-3,[dir='rtl'].lg\:rtl\:right-3 {
    right: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-3,[dir='rtl'].lg\:rtl\:bottom-3 {
    bottom: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:left-3,[dir='rtl'].lg\:rtl\:left-3 {
    left: 0.3rem
  }

  [dir='rtl'] .lg\:rtl\:top-4,[dir='rtl'].lg\:rtl\:top-4 {
    top: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:right-4,[dir='rtl'].lg\:rtl\:right-4 {
    right: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-4,[dir='rtl'].lg\:rtl\:bottom-4 {
    bottom: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:left-4,[dir='rtl'].lg\:rtl\:left-4 {
    left: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:top-5,[dir='rtl'].lg\:rtl\:top-5 {
    top: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:right-5,[dir='rtl'].lg\:rtl\:right-5 {
    right: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-5,[dir='rtl'].lg\:rtl\:bottom-5 {
    bottom: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:left-5,[dir='rtl'].lg\:rtl\:left-5 {
    left: 0.5rem
  }

  [dir='rtl'] .lg\:rtl\:top-6,[dir='rtl'].lg\:rtl\:top-6 {
    top: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:right-6,[dir='rtl'].lg\:rtl\:right-6 {
    right: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-6,[dir='rtl'].lg\:rtl\:bottom-6 {
    bottom: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:left-6,[dir='rtl'].lg\:rtl\:left-6 {
    left: 0.6rem
  }

  [dir='rtl'] .lg\:rtl\:top-7,[dir='rtl'].lg\:rtl\:top-7 {
    top: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:right-7,[dir='rtl'].lg\:rtl\:right-7 {
    right: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-7,[dir='rtl'].lg\:rtl\:bottom-7 {
    bottom: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:left-7,[dir='rtl'].lg\:rtl\:left-7 {
    left: 0.7rem
  }

  [dir='rtl'] .lg\:rtl\:top-8,[dir='rtl'].lg\:rtl\:top-8 {
    top: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:right-8,[dir='rtl'].lg\:rtl\:right-8 {
    right: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-8,[dir='rtl'].lg\:rtl\:bottom-8 {
    bottom: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:left-8,[dir='rtl'].lg\:rtl\:left-8 {
    left: 0.8rem
  }

  [dir='rtl'] .lg\:rtl\:top-9,[dir='rtl'].lg\:rtl\:top-9 {
    top: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:right-9,[dir='rtl'].lg\:rtl\:right-9 {
    right: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-9,[dir='rtl'].lg\:rtl\:bottom-9 {
    bottom: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:left-9,[dir='rtl'].lg\:rtl\:left-9 {
    left: 0.9rem
  }

  [dir='rtl'] .lg\:rtl\:top-10,[dir='rtl'].lg\:rtl\:top-10 {
    top: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:right-10,[dir='rtl'].lg\:rtl\:right-10 {
    right: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-10,[dir='rtl'].lg\:rtl\:bottom-10 {
    bottom: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:left-10,[dir='rtl'].lg\:rtl\:left-10 {
    left: 1.0rem
  }

  [dir='rtl'] .lg\:rtl\:top-12,[dir='rtl'].lg\:rtl\:top-12 {
    top: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:right-12,[dir='rtl'].lg\:rtl\:right-12 {
    right: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-12,[dir='rtl'].lg\:rtl\:bottom-12 {
    bottom: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:left-12,[dir='rtl'].lg\:rtl\:left-12 {
    left: 1.2rem
  }

  [dir='rtl'] .lg\:rtl\:top-14,[dir='rtl'].lg\:rtl\:top-14 {
    top: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:right-14,[dir='rtl'].lg\:rtl\:right-14 {
    right: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-14,[dir='rtl'].lg\:rtl\:bottom-14 {
    bottom: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:left-14,[dir='rtl'].lg\:rtl\:left-14 {
    left: 1.4rem
  }

  [dir='rtl'] .lg\:rtl\:top-16,[dir='rtl'].lg\:rtl\:top-16 {
    top: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:right-16,[dir='rtl'].lg\:rtl\:right-16 {
    right: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-16,[dir='rtl'].lg\:rtl\:bottom-16 {
    bottom: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:left-16,[dir='rtl'].lg\:rtl\:left-16 {
    left: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:top-20,[dir='rtl'].lg\:rtl\:top-20 {
    top: 2rem
  }

  [dir='rtl'] .lg\:rtl\:right-20,[dir='rtl'].lg\:rtl\:right-20 {
    right: 2rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-20,[dir='rtl'].lg\:rtl\:bottom-20 {
    bottom: 2rem
  }

  [dir='rtl'] .lg\:rtl\:left-20,[dir='rtl'].lg\:rtl\:left-20 {
    left: 2rem
  }

  [dir='rtl'] .lg\:rtl\:top-24,[dir='rtl'].lg\:rtl\:top-24 {
    top: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:right-24,[dir='rtl'].lg\:rtl\:right-24 {
    right: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-24,[dir='rtl'].lg\:rtl\:bottom-24 {
    bottom: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:left-24,[dir='rtl'].lg\:rtl\:left-24 {
    left: 2.4rem
  }

  [dir='rtl'] .lg\:rtl\:top-28,[dir='rtl'].lg\:rtl\:top-28 {
    top: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:right-28,[dir='rtl'].lg\:rtl\:right-28 {
    right: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-28,[dir='rtl'].lg\:rtl\:bottom-28 {
    bottom: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:left-28,[dir='rtl'].lg\:rtl\:left-28 {
    left: 2.8rem
  }

  [dir='rtl'] .lg\:rtl\:top-32,[dir='rtl'].lg\:rtl\:top-32 {
    top: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:right-32,[dir='rtl'].lg\:rtl\:right-32 {
    right: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-32,[dir='rtl'].lg\:rtl\:bottom-32 {
    bottom: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:left-32,[dir='rtl'].lg\:rtl\:left-32 {
    left: 3.2rem
  }

  [dir='rtl'] .lg\:rtl\:top-36,[dir='rtl'].lg\:rtl\:top-36 {
    top: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:right-36,[dir='rtl'].lg\:rtl\:right-36 {
    right: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-36,[dir='rtl'].lg\:rtl\:bottom-36 {
    bottom: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:left-36,[dir='rtl'].lg\:rtl\:left-36 {
    left: 3.6rem
  }

  [dir='rtl'] .lg\:rtl\:top-40,[dir='rtl'].lg\:rtl\:top-40 {
    top: 4rem
  }

  [dir='rtl'] .lg\:rtl\:right-40,[dir='rtl'].lg\:rtl\:right-40 {
    right: 4rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-40,[dir='rtl'].lg\:rtl\:bottom-40 {
    bottom: 4rem
  }

  [dir='rtl'] .lg\:rtl\:left-40,[dir='rtl'].lg\:rtl\:left-40 {
    left: 4rem
  }

  [dir='rtl'] .lg\:rtl\:top-44,[dir='rtl'].lg\:rtl\:top-44 {
    top: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:right-44,[dir='rtl'].lg\:rtl\:right-44 {
    right: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-44,[dir='rtl'].lg\:rtl\:bottom-44 {
    bottom: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:left-44,[dir='rtl'].lg\:rtl\:left-44 {
    left: 4.4rem
  }

  [dir='rtl'] .lg\:rtl\:top-48,[dir='rtl'].lg\:rtl\:top-48 {
    top: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:right-48,[dir='rtl'].lg\:rtl\:right-48 {
    right: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-48,[dir='rtl'].lg\:rtl\:bottom-48 {
    bottom: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:left-48,[dir='rtl'].lg\:rtl\:left-48 {
    left: 4.8rem
  }

  [dir='rtl'] .lg\:rtl\:top-52,[dir='rtl'].lg\:rtl\:top-52 {
    top: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:right-52,[dir='rtl'].lg\:rtl\:right-52 {
    right: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-52,[dir='rtl'].lg\:rtl\:bottom-52 {
    bottom: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:left-52,[dir='rtl'].lg\:rtl\:left-52 {
    left: 5.2rem
  }

  [dir='rtl'] .lg\:rtl\:top-56,[dir='rtl'].lg\:rtl\:top-56 {
    top: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:right-56,[dir='rtl'].lg\:rtl\:right-56 {
    right: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-56,[dir='rtl'].lg\:rtl\:bottom-56 {
    bottom: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:left-56,[dir='rtl'].lg\:rtl\:left-56 {
    left: 5.6rem
  }

  [dir='rtl'] .lg\:rtl\:top-60,[dir='rtl'].lg\:rtl\:top-60 {
    top: 6rem
  }

  [dir='rtl'] .lg\:rtl\:right-60,[dir='rtl'].lg\:rtl\:right-60 {
    right: 6rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-60,[dir='rtl'].lg\:rtl\:bottom-60 {
    bottom: 6rem
  }

  [dir='rtl'] .lg\:rtl\:left-60,[dir='rtl'].lg\:rtl\:left-60 {
    left: 6rem
  }

  [dir='rtl'] .lg\:rtl\:top-64,[dir='rtl'].lg\:rtl\:top-64 {
    top: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:right-64,[dir='rtl'].lg\:rtl\:right-64 {
    right: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-64,[dir='rtl'].lg\:rtl\:bottom-64 {
    bottom: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:left-64,[dir='rtl'].lg\:rtl\:left-64 {
    left: 6.4rem
  }

  [dir='rtl'] .lg\:rtl\:top-68,[dir='rtl'].lg\:rtl\:top-68 {
    top: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:right-68,[dir='rtl'].lg\:rtl\:right-68 {
    right: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-68,[dir='rtl'].lg\:rtl\:bottom-68 {
    bottom: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:left-68,[dir='rtl'].lg\:rtl\:left-68 {
    left: 6.8rem
  }

  [dir='rtl'] .lg\:rtl\:top-72,[dir='rtl'].lg\:rtl\:top-72 {
    top: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:right-72,[dir='rtl'].lg\:rtl\:right-72 {
    right: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-72,[dir='rtl'].lg\:rtl\:bottom-72 {
    bottom: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:left-72,[dir='rtl'].lg\:rtl\:left-72 {
    left: 7.2rem
  }

  [dir='rtl'] .lg\:rtl\:top-76,[dir='rtl'].lg\:rtl\:top-76 {
    top: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:right-76,[dir='rtl'].lg\:rtl\:right-76 {
    right: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-76,[dir='rtl'].lg\:rtl\:bottom-76 {
    bottom: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:left-76,[dir='rtl'].lg\:rtl\:left-76 {
    left: 7.6rem
  }

  [dir='rtl'] .lg\:rtl\:top-80,[dir='rtl'].lg\:rtl\:top-80 {
    top: 8rem
  }

  [dir='rtl'] .lg\:rtl\:right-80,[dir='rtl'].lg\:rtl\:right-80 {
    right: 8rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-80,[dir='rtl'].lg\:rtl\:bottom-80 {
    bottom: 8rem
  }

  [dir='rtl'] .lg\:rtl\:left-80,[dir='rtl'].lg\:rtl\:left-80 {
    left: 8rem
  }

  [dir='rtl'] .lg\:rtl\:top-84,[dir='rtl'].lg\:rtl\:top-84 {
    top: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:right-84,[dir='rtl'].lg\:rtl\:right-84 {
    right: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-84,[dir='rtl'].lg\:rtl\:bottom-84 {
    bottom: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:left-84,[dir='rtl'].lg\:rtl\:left-84 {
    left: 8.4rem
  }

  [dir='rtl'] .lg\:rtl\:top-88,[dir='rtl'].lg\:rtl\:top-88 {
    top: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:right-88,[dir='rtl'].lg\:rtl\:right-88 {
    right: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-88,[dir='rtl'].lg\:rtl\:bottom-88 {
    bottom: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:left-88,[dir='rtl'].lg\:rtl\:left-88 {
    left: 8.8rem
  }

  [dir='rtl'] .lg\:rtl\:top-92,[dir='rtl'].lg\:rtl\:top-92 {
    top: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:right-92,[dir='rtl'].lg\:rtl\:right-92 {
    right: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-92,[dir='rtl'].lg\:rtl\:bottom-92 {
    bottom: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:left-92,[dir='rtl'].lg\:rtl\:left-92 {
    left: 9.2rem
  }

  [dir='rtl'] .lg\:rtl\:top-96,[dir='rtl'].lg\:rtl\:top-96 {
    top: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:right-96,[dir='rtl'].lg\:rtl\:right-96 {
    right: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-96,[dir='rtl'].lg\:rtl\:bottom-96 {
    bottom: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:left-96,[dir='rtl'].lg\:rtl\:left-96 {
    left: 9.6rem
  }

  [dir='rtl'] .lg\:rtl\:top-128,[dir='rtl'].lg\:rtl\:top-128 {
    top: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:right-128,[dir='rtl'].lg\:rtl\:right-128 {
    right: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-128,[dir='rtl'].lg\:rtl\:bottom-128 {
    bottom: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:left-128,[dir='rtl'].lg\:rtl\:left-128 {
    left: 12.8rem
  }

  [dir='rtl'] .lg\:rtl\:top-136,[dir='rtl'].lg\:rtl\:top-136 {
    top: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:right-136,[dir='rtl'].lg\:rtl\:right-136 {
    right: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-136,[dir='rtl'].lg\:rtl\:bottom-136 {
    bottom: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:left-136,[dir='rtl'].lg\:rtl\:left-136 {
    left: 13.6rem
  }

  [dir='rtl'] .lg\:rtl\:top-160,[dir='rtl'].lg\:rtl\:top-160 {
    top: 16rem
  }

  [dir='rtl'] .lg\:rtl\:right-160,[dir='rtl'].lg\:rtl\:right-160 {
    right: 16rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-160,[dir='rtl'].lg\:rtl\:bottom-160 {
    bottom: 16rem
  }

  [dir='rtl'] .lg\:rtl\:left-160,[dir='rtl'].lg\:rtl\:left-160 {
    left: 16rem
  }

  [dir='rtl'] .lg\:rtl\:top-192,[dir='rtl'].lg\:rtl\:top-192 {
    top: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:right-192,[dir='rtl'].lg\:rtl\:right-192 {
    right: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-192,[dir='rtl'].lg\:rtl\:bottom-192 {
    bottom: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:left-192,[dir='rtl'].lg\:rtl\:left-192 {
    left: 19.2rem
  }

  [dir='rtl'] .lg\:rtl\:top-200,[dir='rtl'].lg\:rtl\:top-200 {
    top: 20rem
  }

  [dir='rtl'] .lg\:rtl\:right-200,[dir='rtl'].lg\:rtl\:right-200 {
    right: 20rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-200,[dir='rtl'].lg\:rtl\:bottom-200 {
    bottom: 20rem
  }

  [dir='rtl'] .lg\:rtl\:left-200,[dir='rtl'].lg\:rtl\:left-200 {
    left: 20rem
  }

  [dir='rtl'] .lg\:rtl\:top-208,[dir='rtl'].lg\:rtl\:top-208 {
    top: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:right-208,[dir='rtl'].lg\:rtl\:right-208 {
    right: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-208,[dir='rtl'].lg\:rtl\:bottom-208 {
    bottom: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:left-208,[dir='rtl'].lg\:rtl\:left-208 {
    left: 20.8rem
  }

  [dir='rtl'] .lg\:rtl\:top-216,[dir='rtl'].lg\:rtl\:top-216 {
    top: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:right-216,[dir='rtl'].lg\:rtl\:right-216 {
    right: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-216,[dir='rtl'].lg\:rtl\:bottom-216 {
    bottom: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:left-216,[dir='rtl'].lg\:rtl\:left-216 {
    left: 21.6rem
  }

  [dir='rtl'] .lg\:rtl\:top-224,[dir='rtl'].lg\:rtl\:top-224 {
    top: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:right-224,[dir='rtl'].lg\:rtl\:right-224 {
    right: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-224,[dir='rtl'].lg\:rtl\:bottom-224 {
    bottom: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:left-224,[dir='rtl'].lg\:rtl\:left-224 {
    left: 22.4rem
  }

  [dir='rtl'] .lg\:rtl\:top-256,[dir='rtl'].lg\:rtl\:top-256 {
    top: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:right-256,[dir='rtl'].lg\:rtl\:right-256 {
    right: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-256,[dir='rtl'].lg\:rtl\:bottom-256 {
    bottom: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:left-256,[dir='rtl'].lg\:rtl\:left-256 {
    left: 25.6rem
  }

  [dir='rtl'] .lg\:rtl\:top-288,[dir='rtl'].lg\:rtl\:top-288 {
    top: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:right-288,[dir='rtl'].lg\:rtl\:right-288 {
    right: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-288,[dir='rtl'].lg\:rtl\:bottom-288 {
    bottom: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:left-288,[dir='rtl'].lg\:rtl\:left-288 {
    left: 28.8rem
  }

  [dir='rtl'] .lg\:rtl\:top-320,[dir='rtl'].lg\:rtl\:top-320 {
    top: 32rem
  }

  [dir='rtl'] .lg\:rtl\:right-320,[dir='rtl'].lg\:rtl\:right-320 {
    right: 32rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-320,[dir='rtl'].lg\:rtl\:bottom-320 {
    bottom: 32rem
  }

  [dir='rtl'] .lg\:rtl\:left-320,[dir='rtl'].lg\:rtl\:left-320 {
    left: 32rem
  }

  [dir='rtl'] .lg\:rtl\:top-360,[dir='rtl'].lg\:rtl\:top-360 {
    top: 36rem
  }

  [dir='rtl'] .lg\:rtl\:right-360,[dir='rtl'].lg\:rtl\:right-360 {
    right: 36rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-360,[dir='rtl'].lg\:rtl\:bottom-360 {
    bottom: 36rem
  }

  [dir='rtl'] .lg\:rtl\:left-360,[dir='rtl'].lg\:rtl\:left-360 {
    left: 36rem
  }

  [dir='rtl'] .lg\:rtl\:top-384,[dir='rtl'].lg\:rtl\:top-384 {
    top: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:right-384,[dir='rtl'].lg\:rtl\:right-384 {
    right: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-384,[dir='rtl'].lg\:rtl\:bottom-384 {
    bottom: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:left-384,[dir='rtl'].lg\:rtl\:left-384 {
    left: 38.4rem
  }

  [dir='rtl'] .lg\:rtl\:top-400,[dir='rtl'].lg\:rtl\:top-400 {
    top: 40rem
  }

  [dir='rtl'] .lg\:rtl\:right-400,[dir='rtl'].lg\:rtl\:right-400 {
    right: 40rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-400,[dir='rtl'].lg\:rtl\:bottom-400 {
    bottom: 40rem
  }

  [dir='rtl'] .lg\:rtl\:left-400,[dir='rtl'].lg\:rtl\:left-400 {
    left: 40rem
  }

  [dir='rtl'] .lg\:rtl\:top-512,[dir='rtl'].lg\:rtl\:top-512 {
    top: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:right-512,[dir='rtl'].lg\:rtl\:right-512 {
    right: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-512,[dir='rtl'].lg\:rtl\:bottom-512 {
    bottom: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:left-512,[dir='rtl'].lg\:rtl\:left-512 {
    left: 51.2rem
  }

  [dir='rtl'] .lg\:rtl\:top-640,[dir='rtl'].lg\:rtl\:top-640 {
    top: 64rem
  }

  [dir='rtl'] .lg\:rtl\:right-640,[dir='rtl'].lg\:rtl\:right-640 {
    right: 64rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-640,[dir='rtl'].lg\:rtl\:bottom-640 {
    bottom: 64rem
  }

  [dir='rtl'] .lg\:rtl\:left-640,[dir='rtl'].lg\:rtl\:left-640 {
    left: 64rem
  }

  [dir='rtl'] .lg\:rtl\:top-auto,[dir='rtl'].lg\:rtl\:top-auto {
    top: auto
  }

  [dir='rtl'] .lg\:rtl\:right-auto,[dir='rtl'].lg\:rtl\:right-auto {
    right: auto
  }

  [dir='rtl'] .lg\:rtl\:bottom-auto,[dir='rtl'].lg\:rtl\:bottom-auto {
    bottom: auto
  }

  [dir='rtl'] .lg\:rtl\:left-auto,[dir='rtl'].lg\:rtl\:left-auto {
    left: auto
  }

  [dir='rtl'] .lg\:rtl\:top-xs,[dir='rtl'].lg\:rtl\:top-xs {
    top: 32rem
  }

  [dir='rtl'] .lg\:rtl\:right-xs,[dir='rtl'].lg\:rtl\:right-xs {
    right: 32rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-xs,[dir='rtl'].lg\:rtl\:bottom-xs {
    bottom: 32rem
  }

  [dir='rtl'] .lg\:rtl\:left-xs,[dir='rtl'].lg\:rtl\:left-xs {
    left: 32rem
  }

  [dir='rtl'] .lg\:rtl\:top-sm,[dir='rtl'].lg\:rtl\:top-sm {
    top: 48rem
  }

  [dir='rtl'] .lg\:rtl\:right-sm,[dir='rtl'].lg\:rtl\:right-sm {
    right: 48rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-sm,[dir='rtl'].lg\:rtl\:bottom-sm {
    bottom: 48rem
  }

  [dir='rtl'] .lg\:rtl\:left-sm,[dir='rtl'].lg\:rtl\:left-sm {
    left: 48rem
  }

  [dir='rtl'] .lg\:rtl\:top-md,[dir='rtl'].lg\:rtl\:top-md {
    top: 64rem
  }

  [dir='rtl'] .lg\:rtl\:right-md,[dir='rtl'].lg\:rtl\:right-md {
    right: 64rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-md,[dir='rtl'].lg\:rtl\:bottom-md {
    bottom: 64rem
  }

  [dir='rtl'] .lg\:rtl\:left-md,[dir='rtl'].lg\:rtl\:left-md {
    left: 64rem
  }

  [dir='rtl'] .lg\:rtl\:top-lg,[dir='rtl'].lg\:rtl\:top-lg {
    top: 80rem
  }

  [dir='rtl'] .lg\:rtl\:right-lg,[dir='rtl'].lg\:rtl\:right-lg {
    right: 80rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-lg,[dir='rtl'].lg\:rtl\:bottom-lg {
    bottom: 80rem
  }

  [dir='rtl'] .lg\:rtl\:left-lg,[dir='rtl'].lg\:rtl\:left-lg {
    left: 80rem
  }

  [dir='rtl'] .lg\:rtl\:top-xl,[dir='rtl'].lg\:rtl\:top-xl {
    top: 96rem
  }

  [dir='rtl'] .lg\:rtl\:right-xl,[dir='rtl'].lg\:rtl\:right-xl {
    right: 96rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-xl,[dir='rtl'].lg\:rtl\:bottom-xl {
    bottom: 96rem
  }

  [dir='rtl'] .lg\:rtl\:left-xl,[dir='rtl'].lg\:rtl\:left-xl {
    left: 96rem
  }

  [dir='rtl'] .lg\:rtl\:top-2xl,[dir='rtl'].lg\:rtl\:top-2xl {
    top: 112rem
  }

  [dir='rtl'] .lg\:rtl\:right-2xl,[dir='rtl'].lg\:rtl\:right-2xl {
    right: 112rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-2xl,[dir='rtl'].lg\:rtl\:bottom-2xl {
    bottom: 112rem
  }

  [dir='rtl'] .lg\:rtl\:left-2xl,[dir='rtl'].lg\:rtl\:left-2xl {
    left: 112rem
  }

  [dir='rtl'] .lg\:rtl\:top-3xl,[dir='rtl'].lg\:rtl\:top-3xl {
    top: 128rem
  }

  [dir='rtl'] .lg\:rtl\:right-3xl,[dir='rtl'].lg\:rtl\:right-3xl {
    right: 128rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-3xl,[dir='rtl'].lg\:rtl\:bottom-3xl {
    bottom: 128rem
  }

  [dir='rtl'] .lg\:rtl\:left-3xl,[dir='rtl'].lg\:rtl\:left-3xl {
    left: 128rem
  }

  [dir='rtl'] .lg\:rtl\:top-4xl,[dir='rtl'].lg\:rtl\:top-4xl {
    top: 144rem
  }

  [dir='rtl'] .lg\:rtl\:right-4xl,[dir='rtl'].lg\:rtl\:right-4xl {
    right: 144rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-4xl,[dir='rtl'].lg\:rtl\:bottom-4xl {
    bottom: 144rem
  }

  [dir='rtl'] .lg\:rtl\:left-4xl,[dir='rtl'].lg\:rtl\:left-4xl {
    left: 144rem
  }

  [dir='rtl'] .lg\:rtl\:top-5xl,[dir='rtl'].lg\:rtl\:top-5xl {
    top: 160rem
  }

  [dir='rtl'] .lg\:rtl\:right-5xl,[dir='rtl'].lg\:rtl\:right-5xl {
    right: 160rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-5xl,[dir='rtl'].lg\:rtl\:bottom-5xl {
    bottom: 160rem
  }

  [dir='rtl'] .lg\:rtl\:left-5xl,[dir='rtl'].lg\:rtl\:left-5xl {
    left: 160rem
  }

  [dir='rtl'] .lg\:rtl\:top-px,[dir='rtl'].lg\:rtl\:top-px {
    top: 1px
  }

  [dir='rtl'] .lg\:rtl\:right-px,[dir='rtl'].lg\:rtl\:right-px {
    right: 1px
  }

  [dir='rtl'] .lg\:rtl\:bottom-px,[dir='rtl'].lg\:rtl\:bottom-px {
    bottom: 1px
  }

  [dir='rtl'] .lg\:rtl\:left-px,[dir='rtl'].lg\:rtl\:left-px {
    left: 1px
  }

  [dir='rtl'] .lg\:rtl\:top-0\.5,[dir='rtl'].lg\:rtl\:top-0\.5 {
    top: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:right-0\.5,[dir='rtl'].lg\:rtl\:right-0\.5 {
    right: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-0\.5,[dir='rtl'].lg\:rtl\:bottom-0\.5 {
    bottom: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:left-0\.5,[dir='rtl'].lg\:rtl\:left-0\.5 {
    left: 0.05rem
  }

  [dir='rtl'] .lg\:rtl\:top-1\.5,[dir='rtl'].lg\:rtl\:top-1\.5 {
    top: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:right-1\.5,[dir='rtl'].lg\:rtl\:right-1\.5 {
    right: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-1\.5,[dir='rtl'].lg\:rtl\:bottom-1\.5 {
    bottom: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:left-1\.5,[dir='rtl'].lg\:rtl\:left-1\.5 {
    left: 0.15rem
  }

  [dir='rtl'] .lg\:rtl\:top-2\.5,[dir='rtl'].lg\:rtl\:top-2\.5 {
    top: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:right-2\.5,[dir='rtl'].lg\:rtl\:right-2\.5 {
    right: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-2\.5,[dir='rtl'].lg\:rtl\:bottom-2\.5 {
    bottom: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:left-2\.5,[dir='rtl'].lg\:rtl\:left-2\.5 {
    left: 0.25rem
  }

  [dir='rtl'] .lg\:rtl\:top-3\.5,[dir='rtl'].lg\:rtl\:top-3\.5 {
    top: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:right-3\.5,[dir='rtl'].lg\:rtl\:right-3\.5 {
    right: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:bottom-3\.5,[dir='rtl'].lg\:rtl\:bottom-3\.5 {
    bottom: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:left-3\.5,[dir='rtl'].lg\:rtl\:left-3\.5 {
    left: 0.35rem
  }

  [dir='rtl'] .lg\:rtl\:-top-1,[dir='rtl'].lg\:rtl\:-top-1 {
    top: -0.1rem
  }

  [dir='rtl'] .lg\:rtl\:-right-1,[dir='rtl'].lg\:rtl\:-right-1 {
    right: -0.1rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-1,[dir='rtl'].lg\:rtl\:-bottom-1 {
    bottom: -0.1rem
  }

  [dir='rtl'] .lg\:rtl\:-left-1,[dir='rtl'].lg\:rtl\:-left-1 {
    left: -0.1rem
  }

  [dir='rtl'] .lg\:rtl\:-top-2,[dir='rtl'].lg\:rtl\:-top-2 {
    top: -0.2rem
  }

  [dir='rtl'] .lg\:rtl\:-right-2,[dir='rtl'].lg\:rtl\:-right-2 {
    right: -0.2rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-2,[dir='rtl'].lg\:rtl\:-bottom-2 {
    bottom: -0.2rem
  }

  [dir='rtl'] .lg\:rtl\:-left-2,[dir='rtl'].lg\:rtl\:-left-2 {
    left: -0.2rem
  }

  [dir='rtl'] .lg\:rtl\:-top-3,[dir='rtl'].lg\:rtl\:-top-3 {
    top: -0.3rem
  }

  [dir='rtl'] .lg\:rtl\:-right-3,[dir='rtl'].lg\:rtl\:-right-3 {
    right: -0.3rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-3,[dir='rtl'].lg\:rtl\:-bottom-3 {
    bottom: -0.3rem
  }

  [dir='rtl'] .lg\:rtl\:-left-3,[dir='rtl'].lg\:rtl\:-left-3 {
    left: -0.3rem
  }

  [dir='rtl'] .lg\:rtl\:-top-4,[dir='rtl'].lg\:rtl\:-top-4 {
    top: -0.4rem
  }

  [dir='rtl'] .lg\:rtl\:-right-4,[dir='rtl'].lg\:rtl\:-right-4 {
    right: -0.4rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-4,[dir='rtl'].lg\:rtl\:-bottom-4 {
    bottom: -0.4rem
  }

  [dir='rtl'] .lg\:rtl\:-left-4,[dir='rtl'].lg\:rtl\:-left-4 {
    left: -0.4rem
  }

  [dir='rtl'] .lg\:rtl\:-top-5,[dir='rtl'].lg\:rtl\:-top-5 {
    top: -0.5rem
  }

  [dir='rtl'] .lg\:rtl\:-right-5,[dir='rtl'].lg\:rtl\:-right-5 {
    right: -0.5rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-5,[dir='rtl'].lg\:rtl\:-bottom-5 {
    bottom: -0.5rem
  }

  [dir='rtl'] .lg\:rtl\:-left-5,[dir='rtl'].lg\:rtl\:-left-5 {
    left: -0.5rem
  }

  [dir='rtl'] .lg\:rtl\:-top-6,[dir='rtl'].lg\:rtl\:-top-6 {
    top: -0.6rem
  }

  [dir='rtl'] .lg\:rtl\:-right-6,[dir='rtl'].lg\:rtl\:-right-6 {
    right: -0.6rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-6,[dir='rtl'].lg\:rtl\:-bottom-6 {
    bottom: -0.6rem
  }

  [dir='rtl'] .lg\:rtl\:-left-6,[dir='rtl'].lg\:rtl\:-left-6 {
    left: -0.6rem
  }

  [dir='rtl'] .lg\:rtl\:-top-7,[dir='rtl'].lg\:rtl\:-top-7 {
    top: -0.7rem
  }

  [dir='rtl'] .lg\:rtl\:-right-7,[dir='rtl'].lg\:rtl\:-right-7 {
    right: -0.7rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-7,[dir='rtl'].lg\:rtl\:-bottom-7 {
    bottom: -0.7rem
  }

  [dir='rtl'] .lg\:rtl\:-left-7,[dir='rtl'].lg\:rtl\:-left-7 {
    left: -0.7rem
  }

  [dir='rtl'] .lg\:rtl\:-top-8,[dir='rtl'].lg\:rtl\:-top-8 {
    top: -0.8rem
  }

  [dir='rtl'] .lg\:rtl\:-right-8,[dir='rtl'].lg\:rtl\:-right-8 {
    right: -0.8rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-8,[dir='rtl'].lg\:rtl\:-bottom-8 {
    bottom: -0.8rem
  }

  [dir='rtl'] .lg\:rtl\:-left-8,[dir='rtl'].lg\:rtl\:-left-8 {
    left: -0.8rem
  }

  [dir='rtl'] .lg\:rtl\:-top-9,[dir='rtl'].lg\:rtl\:-top-9 {
    top: -0.9rem
  }

  [dir='rtl'] .lg\:rtl\:-right-9,[dir='rtl'].lg\:rtl\:-right-9 {
    right: -0.9rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-9,[dir='rtl'].lg\:rtl\:-bottom-9 {
    bottom: -0.9rem
  }

  [dir='rtl'] .lg\:rtl\:-left-9,[dir='rtl'].lg\:rtl\:-left-9 {
    left: -0.9rem
  }

  [dir='rtl'] .lg\:rtl\:-top-10,[dir='rtl'].lg\:rtl\:-top-10 {
    top: -1rem
  }

  [dir='rtl'] .lg\:rtl\:-right-10,[dir='rtl'].lg\:rtl\:-right-10 {
    right: -1rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-10,[dir='rtl'].lg\:rtl\:-bottom-10 {
    bottom: -1rem
  }

  [dir='rtl'] .lg\:rtl\:-left-10,[dir='rtl'].lg\:rtl\:-left-10 {
    left: -1rem
  }

  [dir='rtl'] .lg\:rtl\:-top-12,[dir='rtl'].lg\:rtl\:-top-12 {
    top: -1.2rem
  }

  [dir='rtl'] .lg\:rtl\:-right-12,[dir='rtl'].lg\:rtl\:-right-12 {
    right: -1.2rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-12,[dir='rtl'].lg\:rtl\:-bottom-12 {
    bottom: -1.2rem
  }

  [dir='rtl'] .lg\:rtl\:-left-12,[dir='rtl'].lg\:rtl\:-left-12 {
    left: -1.2rem
  }

  [dir='rtl'] .lg\:rtl\:-top-14,[dir='rtl'].lg\:rtl\:-top-14 {
    top: -1.4rem
  }

  [dir='rtl'] .lg\:rtl\:-right-14,[dir='rtl'].lg\:rtl\:-right-14 {
    right: -1.4rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-14,[dir='rtl'].lg\:rtl\:-bottom-14 {
    bottom: -1.4rem
  }

  [dir='rtl'] .lg\:rtl\:-left-14,[dir='rtl'].lg\:rtl\:-left-14 {
    left: -1.4rem
  }

  [dir='rtl'] .lg\:rtl\:-top-16,[dir='rtl'].lg\:rtl\:-top-16 {
    top: -1.6rem
  }

  [dir='rtl'] .lg\:rtl\:-right-16,[dir='rtl'].lg\:rtl\:-right-16 {
    right: -1.6rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-16,[dir='rtl'].lg\:rtl\:-bottom-16 {
    bottom: -1.6rem
  }

  [dir='rtl'] .lg\:rtl\:-left-16,[dir='rtl'].lg\:rtl\:-left-16 {
    left: -1.6rem
  }

  [dir='rtl'] .lg\:rtl\:-top-20,[dir='rtl'].lg\:rtl\:-top-20 {
    top: -2rem
  }

  [dir='rtl'] .lg\:rtl\:-right-20,[dir='rtl'].lg\:rtl\:-right-20 {
    right: -2rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-20,[dir='rtl'].lg\:rtl\:-bottom-20 {
    bottom: -2rem
  }

  [dir='rtl'] .lg\:rtl\:-left-20,[dir='rtl'].lg\:rtl\:-left-20 {
    left: -2rem
  }

  [dir='rtl'] .lg\:rtl\:-top-24,[dir='rtl'].lg\:rtl\:-top-24 {
    top: -2.4rem
  }

  [dir='rtl'] .lg\:rtl\:-right-24,[dir='rtl'].lg\:rtl\:-right-24 {
    right: -2.4rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-24,[dir='rtl'].lg\:rtl\:-bottom-24 {
    bottom: -2.4rem
  }

  [dir='rtl'] .lg\:rtl\:-left-24,[dir='rtl'].lg\:rtl\:-left-24 {
    left: -2.4rem
  }

  [dir='rtl'] .lg\:rtl\:-top-28,[dir='rtl'].lg\:rtl\:-top-28 {
    top: -2.8rem
  }

  [dir='rtl'] .lg\:rtl\:-right-28,[dir='rtl'].lg\:rtl\:-right-28 {
    right: -2.8rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-28,[dir='rtl'].lg\:rtl\:-bottom-28 {
    bottom: -2.8rem
  }

  [dir='rtl'] .lg\:rtl\:-left-28,[dir='rtl'].lg\:rtl\:-left-28 {
    left: -2.8rem
  }

  [dir='rtl'] .lg\:rtl\:-top-32,[dir='rtl'].lg\:rtl\:-top-32 {
    top: -3.2rem
  }

  [dir='rtl'] .lg\:rtl\:-right-32,[dir='rtl'].lg\:rtl\:-right-32 {
    right: -3.2rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-32,[dir='rtl'].lg\:rtl\:-bottom-32 {
    bottom: -3.2rem
  }

  [dir='rtl'] .lg\:rtl\:-left-32,[dir='rtl'].lg\:rtl\:-left-32 {
    left: -3.2rem
  }

  [dir='rtl'] .lg\:rtl\:-top-36,[dir='rtl'].lg\:rtl\:-top-36 {
    top: -3.6rem
  }

  [dir='rtl'] .lg\:rtl\:-right-36,[dir='rtl'].lg\:rtl\:-right-36 {
    right: -3.6rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-36,[dir='rtl'].lg\:rtl\:-bottom-36 {
    bottom: -3.6rem
  }

  [dir='rtl'] .lg\:rtl\:-left-36,[dir='rtl'].lg\:rtl\:-left-36 {
    left: -3.6rem
  }

  [dir='rtl'] .lg\:rtl\:-top-40,[dir='rtl'].lg\:rtl\:-top-40 {
    top: -4rem
  }

  [dir='rtl'] .lg\:rtl\:-right-40,[dir='rtl'].lg\:rtl\:-right-40 {
    right: -4rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-40,[dir='rtl'].lg\:rtl\:-bottom-40 {
    bottom: -4rem
  }

  [dir='rtl'] .lg\:rtl\:-left-40,[dir='rtl'].lg\:rtl\:-left-40 {
    left: -4rem
  }

  [dir='rtl'] .lg\:rtl\:-top-44,[dir='rtl'].lg\:rtl\:-top-44 {
    top: -4.4rem
  }

  [dir='rtl'] .lg\:rtl\:-right-44,[dir='rtl'].lg\:rtl\:-right-44 {
    right: -4.4rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-44,[dir='rtl'].lg\:rtl\:-bottom-44 {
    bottom: -4.4rem
  }

  [dir='rtl'] .lg\:rtl\:-left-44,[dir='rtl'].lg\:rtl\:-left-44 {
    left: -4.4rem
  }

  [dir='rtl'] .lg\:rtl\:-top-48,[dir='rtl'].lg\:rtl\:-top-48 {
    top: -4.8rem
  }

  [dir='rtl'] .lg\:rtl\:-right-48,[dir='rtl'].lg\:rtl\:-right-48 {
    right: -4.8rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-48,[dir='rtl'].lg\:rtl\:-bottom-48 {
    bottom: -4.8rem
  }

  [dir='rtl'] .lg\:rtl\:-left-48,[dir='rtl'].lg\:rtl\:-left-48 {
    left: -4.8rem
  }

  [dir='rtl'] .lg\:rtl\:-top-52,[dir='rtl'].lg\:rtl\:-top-52 {
    top: -5.2rem
  }

  [dir='rtl'] .lg\:rtl\:-right-52,[dir='rtl'].lg\:rtl\:-right-52 {
    right: -5.2rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-52,[dir='rtl'].lg\:rtl\:-bottom-52 {
    bottom: -5.2rem
  }

  [dir='rtl'] .lg\:rtl\:-left-52,[dir='rtl'].lg\:rtl\:-left-52 {
    left: -5.2rem
  }

  [dir='rtl'] .lg\:rtl\:-top-56,[dir='rtl'].lg\:rtl\:-top-56 {
    top: -5.6rem
  }

  [dir='rtl'] .lg\:rtl\:-right-56,[dir='rtl'].lg\:rtl\:-right-56 {
    right: -5.6rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-56,[dir='rtl'].lg\:rtl\:-bottom-56 {
    bottom: -5.6rem
  }

  [dir='rtl'] .lg\:rtl\:-left-56,[dir='rtl'].lg\:rtl\:-left-56 {
    left: -5.6rem
  }

  [dir='rtl'] .lg\:rtl\:-top-60,[dir='rtl'].lg\:rtl\:-top-60 {
    top: -6rem
  }

  [dir='rtl'] .lg\:rtl\:-right-60,[dir='rtl'].lg\:rtl\:-right-60 {
    right: -6rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-60,[dir='rtl'].lg\:rtl\:-bottom-60 {
    bottom: -6rem
  }

  [dir='rtl'] .lg\:rtl\:-left-60,[dir='rtl'].lg\:rtl\:-left-60 {
    left: -6rem
  }

  [dir='rtl'] .lg\:rtl\:-top-64,[dir='rtl'].lg\:rtl\:-top-64 {
    top: -6.4rem
  }

  [dir='rtl'] .lg\:rtl\:-right-64,[dir='rtl'].lg\:rtl\:-right-64 {
    right: -6.4rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-64,[dir='rtl'].lg\:rtl\:-bottom-64 {
    bottom: -6.4rem
  }

  [dir='rtl'] .lg\:rtl\:-left-64,[dir='rtl'].lg\:rtl\:-left-64 {
    left: -6.4rem
  }

  [dir='rtl'] .lg\:rtl\:-top-68,[dir='rtl'].lg\:rtl\:-top-68 {
    top: -6.8rem
  }

  [dir='rtl'] .lg\:rtl\:-right-68,[dir='rtl'].lg\:rtl\:-right-68 {
    right: -6.8rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-68,[dir='rtl'].lg\:rtl\:-bottom-68 {
    bottom: -6.8rem
  }

  [dir='rtl'] .lg\:rtl\:-left-68,[dir='rtl'].lg\:rtl\:-left-68 {
    left: -6.8rem
  }

  [dir='rtl'] .lg\:rtl\:-top-72,[dir='rtl'].lg\:rtl\:-top-72 {
    top: -7.2rem
  }

  [dir='rtl'] .lg\:rtl\:-right-72,[dir='rtl'].lg\:rtl\:-right-72 {
    right: -7.2rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-72,[dir='rtl'].lg\:rtl\:-bottom-72 {
    bottom: -7.2rem
  }

  [dir='rtl'] .lg\:rtl\:-left-72,[dir='rtl'].lg\:rtl\:-left-72 {
    left: -7.2rem
  }

  [dir='rtl'] .lg\:rtl\:-top-76,[dir='rtl'].lg\:rtl\:-top-76 {
    top: -7.6rem
  }

  [dir='rtl'] .lg\:rtl\:-right-76,[dir='rtl'].lg\:rtl\:-right-76 {
    right: -7.6rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-76,[dir='rtl'].lg\:rtl\:-bottom-76 {
    bottom: -7.6rem
  }

  [dir='rtl'] .lg\:rtl\:-left-76,[dir='rtl'].lg\:rtl\:-left-76 {
    left: -7.6rem
  }

  [dir='rtl'] .lg\:rtl\:-top-80,[dir='rtl'].lg\:rtl\:-top-80 {
    top: -8rem
  }

  [dir='rtl'] .lg\:rtl\:-right-80,[dir='rtl'].lg\:rtl\:-right-80 {
    right: -8rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-80,[dir='rtl'].lg\:rtl\:-bottom-80 {
    bottom: -8rem
  }

  [dir='rtl'] .lg\:rtl\:-left-80,[dir='rtl'].lg\:rtl\:-left-80 {
    left: -8rem
  }

  [dir='rtl'] .lg\:rtl\:-top-84,[dir='rtl'].lg\:rtl\:-top-84 {
    top: -8.4rem
  }

  [dir='rtl'] .lg\:rtl\:-right-84,[dir='rtl'].lg\:rtl\:-right-84 {
    right: -8.4rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-84,[dir='rtl'].lg\:rtl\:-bottom-84 {
    bottom: -8.4rem
  }

  [dir='rtl'] .lg\:rtl\:-left-84,[dir='rtl'].lg\:rtl\:-left-84 {
    left: -8.4rem
  }

  [dir='rtl'] .lg\:rtl\:-top-88,[dir='rtl'].lg\:rtl\:-top-88 {
    top: -8.8rem
  }

  [dir='rtl'] .lg\:rtl\:-right-88,[dir='rtl'].lg\:rtl\:-right-88 {
    right: -8.8rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-88,[dir='rtl'].lg\:rtl\:-bottom-88 {
    bottom: -8.8rem
  }

  [dir='rtl'] .lg\:rtl\:-left-88,[dir='rtl'].lg\:rtl\:-left-88 {
    left: -8.8rem
  }

  [dir='rtl'] .lg\:rtl\:-top-92,[dir='rtl'].lg\:rtl\:-top-92 {
    top: -9.2rem
  }

  [dir='rtl'] .lg\:rtl\:-right-92,[dir='rtl'].lg\:rtl\:-right-92 {
    right: -9.2rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-92,[dir='rtl'].lg\:rtl\:-bottom-92 {
    bottom: -9.2rem
  }

  [dir='rtl'] .lg\:rtl\:-left-92,[dir='rtl'].lg\:rtl\:-left-92 {
    left: -9.2rem
  }

  [dir='rtl'] .lg\:rtl\:-top-96,[dir='rtl'].lg\:rtl\:-top-96 {
    top: -9.6rem
  }

  [dir='rtl'] .lg\:rtl\:-right-96,[dir='rtl'].lg\:rtl\:-right-96 {
    right: -9.6rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-96,[dir='rtl'].lg\:rtl\:-bottom-96 {
    bottom: -9.6rem
  }

  [dir='rtl'] .lg\:rtl\:-left-96,[dir='rtl'].lg\:rtl\:-left-96 {
    left: -9.6rem
  }

  [dir='rtl'] .lg\:rtl\:-top-128,[dir='rtl'].lg\:rtl\:-top-128 {
    top: -12.8rem
  }

  [dir='rtl'] .lg\:rtl\:-right-128,[dir='rtl'].lg\:rtl\:-right-128 {
    right: -12.8rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-128,[dir='rtl'].lg\:rtl\:-bottom-128 {
    bottom: -12.8rem
  }

  [dir='rtl'] .lg\:rtl\:-left-128,[dir='rtl'].lg\:rtl\:-left-128 {
    left: -12.8rem
  }

  [dir='rtl'] .lg\:rtl\:-top-136,[dir='rtl'].lg\:rtl\:-top-136 {
    top: -13.6rem
  }

  [dir='rtl'] .lg\:rtl\:-right-136,[dir='rtl'].lg\:rtl\:-right-136 {
    right: -13.6rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-136,[dir='rtl'].lg\:rtl\:-bottom-136 {
    bottom: -13.6rem
  }

  [dir='rtl'] .lg\:rtl\:-left-136,[dir='rtl'].lg\:rtl\:-left-136 {
    left: -13.6rem
  }

  [dir='rtl'] .lg\:rtl\:-top-160,[dir='rtl'].lg\:rtl\:-top-160 {
    top: -16rem
  }

  [dir='rtl'] .lg\:rtl\:-right-160,[dir='rtl'].lg\:rtl\:-right-160 {
    right: -16rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-160,[dir='rtl'].lg\:rtl\:-bottom-160 {
    bottom: -16rem
  }

  [dir='rtl'] .lg\:rtl\:-left-160,[dir='rtl'].lg\:rtl\:-left-160 {
    left: -16rem
  }

  [dir='rtl'] .lg\:rtl\:-top-192,[dir='rtl'].lg\:rtl\:-top-192 {
    top: -19.2rem
  }

  [dir='rtl'] .lg\:rtl\:-right-192,[dir='rtl'].lg\:rtl\:-right-192 {
    right: -19.2rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-192,[dir='rtl'].lg\:rtl\:-bottom-192 {
    bottom: -19.2rem
  }

  [dir='rtl'] .lg\:rtl\:-left-192,[dir='rtl'].lg\:rtl\:-left-192 {
    left: -19.2rem
  }

  [dir='rtl'] .lg\:rtl\:-top-200,[dir='rtl'].lg\:rtl\:-top-200 {
    top: -20rem
  }

  [dir='rtl'] .lg\:rtl\:-right-200,[dir='rtl'].lg\:rtl\:-right-200 {
    right: -20rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-200,[dir='rtl'].lg\:rtl\:-bottom-200 {
    bottom: -20rem
  }

  [dir='rtl'] .lg\:rtl\:-left-200,[dir='rtl'].lg\:rtl\:-left-200 {
    left: -20rem
  }

  [dir='rtl'] .lg\:rtl\:-top-208,[dir='rtl'].lg\:rtl\:-top-208 {
    top: -20.8rem
  }

  [dir='rtl'] .lg\:rtl\:-right-208,[dir='rtl'].lg\:rtl\:-right-208 {
    right: -20.8rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-208,[dir='rtl'].lg\:rtl\:-bottom-208 {
    bottom: -20.8rem
  }

  [dir='rtl'] .lg\:rtl\:-left-208,[dir='rtl'].lg\:rtl\:-left-208 {
    left: -20.8rem
  }

  [dir='rtl'] .lg\:rtl\:-top-216,[dir='rtl'].lg\:rtl\:-top-216 {
    top: -21.6rem
  }

  [dir='rtl'] .lg\:rtl\:-right-216,[dir='rtl'].lg\:rtl\:-right-216 {
    right: -21.6rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-216,[dir='rtl'].lg\:rtl\:-bottom-216 {
    bottom: -21.6rem
  }

  [dir='rtl'] .lg\:rtl\:-left-216,[dir='rtl'].lg\:rtl\:-left-216 {
    left: -21.6rem
  }

  [dir='rtl'] .lg\:rtl\:-top-224,[dir='rtl'].lg\:rtl\:-top-224 {
    top: -22.4rem
  }

  [dir='rtl'] .lg\:rtl\:-right-224,[dir='rtl'].lg\:rtl\:-right-224 {
    right: -22.4rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-224,[dir='rtl'].lg\:rtl\:-bottom-224 {
    bottom: -22.4rem
  }

  [dir='rtl'] .lg\:rtl\:-left-224,[dir='rtl'].lg\:rtl\:-left-224 {
    left: -22.4rem
  }

  [dir='rtl'] .lg\:rtl\:-top-256,[dir='rtl'].lg\:rtl\:-top-256 {
    top: -25.6rem
  }

  [dir='rtl'] .lg\:rtl\:-right-256,[dir='rtl'].lg\:rtl\:-right-256 {
    right: -25.6rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-256,[dir='rtl'].lg\:rtl\:-bottom-256 {
    bottom: -25.6rem
  }

  [dir='rtl'] .lg\:rtl\:-left-256,[dir='rtl'].lg\:rtl\:-left-256 {
    left: -25.6rem
  }

  [dir='rtl'] .lg\:rtl\:-top-288,[dir='rtl'].lg\:rtl\:-top-288 {
    top: -28.8rem
  }

  [dir='rtl'] .lg\:rtl\:-right-288,[dir='rtl'].lg\:rtl\:-right-288 {
    right: -28.8rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-288,[dir='rtl'].lg\:rtl\:-bottom-288 {
    bottom: -28.8rem
  }

  [dir='rtl'] .lg\:rtl\:-left-288,[dir='rtl'].lg\:rtl\:-left-288 {
    left: -28.8rem
  }

  [dir='rtl'] .lg\:rtl\:-top-320,[dir='rtl'].lg\:rtl\:-top-320 {
    top: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-right-320,[dir='rtl'].lg\:rtl\:-right-320 {
    right: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-320,[dir='rtl'].lg\:rtl\:-bottom-320 {
    bottom: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-left-320,[dir='rtl'].lg\:rtl\:-left-320 {
    left: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-top-360,[dir='rtl'].lg\:rtl\:-top-360 {
    top: -36rem
  }

  [dir='rtl'] .lg\:rtl\:-right-360,[dir='rtl'].lg\:rtl\:-right-360 {
    right: -36rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-360,[dir='rtl'].lg\:rtl\:-bottom-360 {
    bottom: -36rem
  }

  [dir='rtl'] .lg\:rtl\:-left-360,[dir='rtl'].lg\:rtl\:-left-360 {
    left: -36rem
  }

  [dir='rtl'] .lg\:rtl\:-top-384,[dir='rtl'].lg\:rtl\:-top-384 {
    top: -38.4rem
  }

  [dir='rtl'] .lg\:rtl\:-right-384,[dir='rtl'].lg\:rtl\:-right-384 {
    right: -38.4rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-384,[dir='rtl'].lg\:rtl\:-bottom-384 {
    bottom: -38.4rem
  }

  [dir='rtl'] .lg\:rtl\:-left-384,[dir='rtl'].lg\:rtl\:-left-384 {
    left: -38.4rem
  }

  [dir='rtl'] .lg\:rtl\:-top-400,[dir='rtl'].lg\:rtl\:-top-400 {
    top: -40rem
  }

  [dir='rtl'] .lg\:rtl\:-right-400,[dir='rtl'].lg\:rtl\:-right-400 {
    right: -40rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-400,[dir='rtl'].lg\:rtl\:-bottom-400 {
    bottom: -40rem
  }

  [dir='rtl'] .lg\:rtl\:-left-400,[dir='rtl'].lg\:rtl\:-left-400 {
    left: -40rem
  }

  [dir='rtl'] .lg\:rtl\:-top-512,[dir='rtl'].lg\:rtl\:-top-512 {
    top: -51.2rem
  }

  [dir='rtl'] .lg\:rtl\:-right-512,[dir='rtl'].lg\:rtl\:-right-512 {
    right: -51.2rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-512,[dir='rtl'].lg\:rtl\:-bottom-512 {
    bottom: -51.2rem
  }

  [dir='rtl'] .lg\:rtl\:-left-512,[dir='rtl'].lg\:rtl\:-left-512 {
    left: -51.2rem
  }

  [dir='rtl'] .lg\:rtl\:-top-640,[dir='rtl'].lg\:rtl\:-top-640 {
    top: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-right-640,[dir='rtl'].lg\:rtl\:-right-640 {
    right: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-640,[dir='rtl'].lg\:rtl\:-bottom-640 {
    bottom: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-left-640,[dir='rtl'].lg\:rtl\:-left-640 {
    left: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-top-xs,[dir='rtl'].lg\:rtl\:-top-xs {
    top: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-right-xs,[dir='rtl'].lg\:rtl\:-right-xs {
    right: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-xs,[dir='rtl'].lg\:rtl\:-bottom-xs {
    bottom: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-left-xs,[dir='rtl'].lg\:rtl\:-left-xs {
    left: -32rem
  }

  [dir='rtl'] .lg\:rtl\:-top-sm,[dir='rtl'].lg\:rtl\:-top-sm {
    top: -48rem
  }

  [dir='rtl'] .lg\:rtl\:-right-sm,[dir='rtl'].lg\:rtl\:-right-sm {
    right: -48rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-sm,[dir='rtl'].lg\:rtl\:-bottom-sm {
    bottom: -48rem
  }

  [dir='rtl'] .lg\:rtl\:-left-sm,[dir='rtl'].lg\:rtl\:-left-sm {
    left: -48rem
  }

  [dir='rtl'] .lg\:rtl\:-top-md,[dir='rtl'].lg\:rtl\:-top-md {
    top: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-right-md,[dir='rtl'].lg\:rtl\:-right-md {
    right: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-md,[dir='rtl'].lg\:rtl\:-bottom-md {
    bottom: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-left-md,[dir='rtl'].lg\:rtl\:-left-md {
    left: -64rem
  }

  [dir='rtl'] .lg\:rtl\:-top-lg,[dir='rtl'].lg\:rtl\:-top-lg {
    top: -80rem
  }

  [dir='rtl'] .lg\:rtl\:-right-lg,[dir='rtl'].lg\:rtl\:-right-lg {
    right: -80rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-lg,[dir='rtl'].lg\:rtl\:-bottom-lg {
    bottom: -80rem
  }

  [dir='rtl'] .lg\:rtl\:-left-lg,[dir='rtl'].lg\:rtl\:-left-lg {
    left: -80rem
  }

  [dir='rtl'] .lg\:rtl\:-top-xl,[dir='rtl'].lg\:rtl\:-top-xl {
    top: -96rem
  }

  [dir='rtl'] .lg\:rtl\:-right-xl,[dir='rtl'].lg\:rtl\:-right-xl {
    right: -96rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-xl,[dir='rtl'].lg\:rtl\:-bottom-xl {
    bottom: -96rem
  }

  [dir='rtl'] .lg\:rtl\:-left-xl,[dir='rtl'].lg\:rtl\:-left-xl {
    left: -96rem
  }

  [dir='rtl'] .lg\:rtl\:-top-2xl,[dir='rtl'].lg\:rtl\:-top-2xl {
    top: -112rem
  }

  [dir='rtl'] .lg\:rtl\:-right-2xl,[dir='rtl'].lg\:rtl\:-right-2xl {
    right: -112rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-2xl,[dir='rtl'].lg\:rtl\:-bottom-2xl {
    bottom: -112rem
  }

  [dir='rtl'] .lg\:rtl\:-left-2xl,[dir='rtl'].lg\:rtl\:-left-2xl {
    left: -112rem
  }

  [dir='rtl'] .lg\:rtl\:-top-3xl,[dir='rtl'].lg\:rtl\:-top-3xl {
    top: -128rem
  }

  [dir='rtl'] .lg\:rtl\:-right-3xl,[dir='rtl'].lg\:rtl\:-right-3xl {
    right: -128rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-3xl,[dir='rtl'].lg\:rtl\:-bottom-3xl {
    bottom: -128rem
  }

  [dir='rtl'] .lg\:rtl\:-left-3xl,[dir='rtl'].lg\:rtl\:-left-3xl {
    left: -128rem
  }

  [dir='rtl'] .lg\:rtl\:-top-4xl,[dir='rtl'].lg\:rtl\:-top-4xl {
    top: -144rem
  }

  [dir='rtl'] .lg\:rtl\:-right-4xl,[dir='rtl'].lg\:rtl\:-right-4xl {
    right: -144rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-4xl,[dir='rtl'].lg\:rtl\:-bottom-4xl {
    bottom: -144rem
  }

  [dir='rtl'] .lg\:rtl\:-left-4xl,[dir='rtl'].lg\:rtl\:-left-4xl {
    left: -144rem
  }

  [dir='rtl'] .lg\:rtl\:-top-5xl,[dir='rtl'].lg\:rtl\:-top-5xl {
    top: -160rem
  }

  [dir='rtl'] .lg\:rtl\:-right-5xl,[dir='rtl'].lg\:rtl\:-right-5xl {
    right: -160rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-5xl,[dir='rtl'].lg\:rtl\:-bottom-5xl {
    bottom: -160rem
  }

  [dir='rtl'] .lg\:rtl\:-left-5xl,[dir='rtl'].lg\:rtl\:-left-5xl {
    left: -160rem
  }

  [dir='rtl'] .lg\:rtl\:-top-px,[dir='rtl'].lg\:rtl\:-top-px {
    top: -1px
  }

  [dir='rtl'] .lg\:rtl\:-right-px,[dir='rtl'].lg\:rtl\:-right-px {
    right: -1px
  }

  [dir='rtl'] .lg\:rtl\:-bottom-px,[dir='rtl'].lg\:rtl\:-bottom-px {
    bottom: -1px
  }

  [dir='rtl'] .lg\:rtl\:-left-px,[dir='rtl'].lg\:rtl\:-left-px {
    left: -1px
  }

  [dir='rtl'] .lg\:rtl\:-top-0\.5,[dir='rtl'].lg\:rtl\:-top-0\.5 {
    top: -0.05rem
  }

  [dir='rtl'] .lg\:rtl\:-right-0\.5,[dir='rtl'].lg\:rtl\:-right-0\.5 {
    right: -0.05rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-0\.5,[dir='rtl'].lg\:rtl\:-bottom-0\.5 {
    bottom: -0.05rem
  }

  [dir='rtl'] .lg\:rtl\:-left-0\.5,[dir='rtl'].lg\:rtl\:-left-0\.5 {
    left: -0.05rem
  }

  [dir='rtl'] .lg\:rtl\:-top-1\.5,[dir='rtl'].lg\:rtl\:-top-1\.5 {
    top: -0.15rem
  }

  [dir='rtl'] .lg\:rtl\:-right-1\.5,[dir='rtl'].lg\:rtl\:-right-1\.5 {
    right: -0.15rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-1\.5,[dir='rtl'].lg\:rtl\:-bottom-1\.5 {
    bottom: -0.15rem
  }

  [dir='rtl'] .lg\:rtl\:-left-1\.5,[dir='rtl'].lg\:rtl\:-left-1\.5 {
    left: -0.15rem
  }

  [dir='rtl'] .lg\:rtl\:-top-2\.5,[dir='rtl'].lg\:rtl\:-top-2\.5 {
    top: -0.25rem
  }

  [dir='rtl'] .lg\:rtl\:-right-2\.5,[dir='rtl'].lg\:rtl\:-right-2\.5 {
    right: -0.25rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-2\.5,[dir='rtl'].lg\:rtl\:-bottom-2\.5 {
    bottom: -0.25rem
  }

  [dir='rtl'] .lg\:rtl\:-left-2\.5,[dir='rtl'].lg\:rtl\:-left-2\.5 {
    left: -0.25rem
  }

  [dir='rtl'] .lg\:rtl\:-top-3\.5,[dir='rtl'].lg\:rtl\:-top-3\.5 {
    top: -0.35rem
  }

  [dir='rtl'] .lg\:rtl\:-right-3\.5,[dir='rtl'].lg\:rtl\:-right-3\.5 {
    right: -0.35rem
  }

  [dir='rtl'] .lg\:rtl\:-bottom-3\.5,[dir='rtl'].lg\:rtl\:-bottom-3\.5 {
    bottom: -0.35rem
  }

  [dir='rtl'] .lg\:rtl\:-left-3\.5,[dir='rtl'].lg\:rtl\:-left-3\.5 {
    left: -0.35rem
  }

  [dir='rtl'] .lg\:rtl\:top-1\/2,[dir='rtl'].lg\:rtl\:top-1\/2 {
    top: 50%
  }

  [dir='rtl'] .lg\:rtl\:right-1\/2,[dir='rtl'].lg\:rtl\:right-1\/2 {
    right: 50%
  }

  [dir='rtl'] .lg\:rtl\:bottom-1\/2,[dir='rtl'].lg\:rtl\:bottom-1\/2 {
    bottom: 50%
  }

  [dir='rtl'] .lg\:rtl\:left-1\/2,[dir='rtl'].lg\:rtl\:left-1\/2 {
    left: 50%
  }

  [dir='rtl'] .lg\:rtl\:top-1\/3,[dir='rtl'].lg\:rtl\:top-1\/3 {
    top: 33.333333%
  }

  [dir='rtl'] .lg\:rtl\:right-1\/3,[dir='rtl'].lg\:rtl\:right-1\/3 {
    right: 33.333333%
  }

  [dir='rtl'] .lg\:rtl\:bottom-1\/3,[dir='rtl'].lg\:rtl\:bottom-1\/3 {
    bottom: 33.333333%
  }

  [dir='rtl'] .lg\:rtl\:left-1\/3,[dir='rtl'].lg\:rtl\:left-1\/3 {
    left: 33.333333%
  }

  [dir='rtl'] .lg\:rtl\:top-2\/3,[dir='rtl'].lg\:rtl\:top-2\/3 {
    top: 66.666667%
  }

  [dir='rtl'] .lg\:rtl\:right-2\/3,[dir='rtl'].lg\:rtl\:right-2\/3 {
    right: 66.666667%
  }

  [dir='rtl'] .lg\:rtl\:bottom-2\/3,[dir='rtl'].lg\:rtl\:bottom-2\/3 {
    bottom: 66.666667%
  }

  [dir='rtl'] .lg\:rtl\:left-2\/3,[dir='rtl'].lg\:rtl\:left-2\/3 {
    left: 66.666667%
  }

  [dir='rtl'] .lg\:rtl\:top-1\/4,[dir='rtl'].lg\:rtl\:top-1\/4 {
    top: 25%
  }

  [dir='rtl'] .lg\:rtl\:right-1\/4,[dir='rtl'].lg\:rtl\:right-1\/4 {
    right: 25%
  }

  [dir='rtl'] .lg\:rtl\:bottom-1\/4,[dir='rtl'].lg\:rtl\:bottom-1\/4 {
    bottom: 25%
  }

  [dir='rtl'] .lg\:rtl\:left-1\/4,[dir='rtl'].lg\:rtl\:left-1\/4 {
    left: 25%
  }

  [dir='rtl'] .lg\:rtl\:top-2\/4,[dir='rtl'].lg\:rtl\:top-2\/4 {
    top: 50%
  }

  [dir='rtl'] .lg\:rtl\:right-2\/4,[dir='rtl'].lg\:rtl\:right-2\/4 {
    right: 50%
  }

  [dir='rtl'] .lg\:rtl\:bottom-2\/4,[dir='rtl'].lg\:rtl\:bottom-2\/4 {
    bottom: 50%
  }

  [dir='rtl'] .lg\:rtl\:left-2\/4,[dir='rtl'].lg\:rtl\:left-2\/4 {
    left: 50%
  }

  [dir='rtl'] .lg\:rtl\:top-3\/4,[dir='rtl'].lg\:rtl\:top-3\/4 {
    top: 75%
  }

  [dir='rtl'] .lg\:rtl\:right-3\/4,[dir='rtl'].lg\:rtl\:right-3\/4 {
    right: 75%
  }

  [dir='rtl'] .lg\:rtl\:bottom-3\/4,[dir='rtl'].lg\:rtl\:bottom-3\/4 {
    bottom: 75%
  }

  [dir='rtl'] .lg\:rtl\:left-3\/4,[dir='rtl'].lg\:rtl\:left-3\/4 {
    left: 75%
  }

  [dir='rtl'] .lg\:rtl\:top-full,[dir='rtl'].lg\:rtl\:top-full {
    top: 100%
  }

  [dir='rtl'] .lg\:rtl\:right-full,[dir='rtl'].lg\:rtl\:right-full {
    right: 100%
  }

  [dir='rtl'] .lg\:rtl\:bottom-full,[dir='rtl'].lg\:rtl\:bottom-full {
    bottom: 100%
  }

  [dir='rtl'] .lg\:rtl\:left-full,[dir='rtl'].lg\:rtl\:left-full {
    left: 100%
  }

  [dir='rtl'] .lg\:rtl\:-top-1\/2,[dir='rtl'].lg\:rtl\:-top-1\/2 {
    top: -50%
  }

  [dir='rtl'] .lg\:rtl\:-right-1\/2,[dir='rtl'].lg\:rtl\:-right-1\/2 {
    right: -50%
  }

  [dir='rtl'] .lg\:rtl\:-bottom-1\/2,[dir='rtl'].lg\:rtl\:-bottom-1\/2 {
    bottom: -50%
  }

  [dir='rtl'] .lg\:rtl\:-left-1\/2,[dir='rtl'].lg\:rtl\:-left-1\/2 {
    left: -50%
  }

  [dir='rtl'] .lg\:rtl\:-top-1\/3,[dir='rtl'].lg\:rtl\:-top-1\/3 {
    top: -33.333333%
  }

  [dir='rtl'] .lg\:rtl\:-right-1\/3,[dir='rtl'].lg\:rtl\:-right-1\/3 {
    right: -33.333333%
  }

  [dir='rtl'] .lg\:rtl\:-bottom-1\/3,[dir='rtl'].lg\:rtl\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  [dir='rtl'] .lg\:rtl\:-left-1\/3,[dir='rtl'].lg\:rtl\:-left-1\/3 {
    left: -33.333333%
  }

  [dir='rtl'] .lg\:rtl\:-top-2\/3,[dir='rtl'].lg\:rtl\:-top-2\/3 {
    top: -66.666667%
  }

  [dir='rtl'] .lg\:rtl\:-right-2\/3,[dir='rtl'].lg\:rtl\:-right-2\/3 {
    right: -66.666667%
  }

  [dir='rtl'] .lg\:rtl\:-bottom-2\/3,[dir='rtl'].lg\:rtl\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  [dir='rtl'] .lg\:rtl\:-left-2\/3,[dir='rtl'].lg\:rtl\:-left-2\/3 {
    left: -66.666667%
  }

  [dir='rtl'] .lg\:rtl\:-top-1\/4,[dir='rtl'].lg\:rtl\:-top-1\/4 {
    top: -25%
  }

  [dir='rtl'] .lg\:rtl\:-right-1\/4,[dir='rtl'].lg\:rtl\:-right-1\/4 {
    right: -25%
  }

  [dir='rtl'] .lg\:rtl\:-bottom-1\/4,[dir='rtl'].lg\:rtl\:-bottom-1\/4 {
    bottom: -25%
  }

  [dir='rtl'] .lg\:rtl\:-left-1\/4,[dir='rtl'].lg\:rtl\:-left-1\/4 {
    left: -25%
  }

  [dir='rtl'] .lg\:rtl\:-top-2\/4,[dir='rtl'].lg\:rtl\:-top-2\/4 {
    top: -50%
  }

  [dir='rtl'] .lg\:rtl\:-right-2\/4,[dir='rtl'].lg\:rtl\:-right-2\/4 {
    right: -50%
  }

  [dir='rtl'] .lg\:rtl\:-bottom-2\/4,[dir='rtl'].lg\:rtl\:-bottom-2\/4 {
    bottom: -50%
  }

  [dir='rtl'] .lg\:rtl\:-left-2\/4,[dir='rtl'].lg\:rtl\:-left-2\/4 {
    left: -50%
  }

  [dir='rtl'] .lg\:rtl\:-top-3\/4,[dir='rtl'].lg\:rtl\:-top-3\/4 {
    top: -75%
  }

  [dir='rtl'] .lg\:rtl\:-right-3\/4,[dir='rtl'].lg\:rtl\:-right-3\/4 {
    right: -75%
  }

  [dir='rtl'] .lg\:rtl\:-bottom-3\/4,[dir='rtl'].lg\:rtl\:-bottom-3\/4 {
    bottom: -75%
  }

  [dir='rtl'] .lg\:rtl\:-left-3\/4,[dir='rtl'].lg\:rtl\:-left-3\/4 {
    left: -75%
  }

  [dir='rtl'] .lg\:rtl\:-top-full,[dir='rtl'].lg\:rtl\:-top-full {
    top: -100%
  }

  [dir='rtl'] .lg\:rtl\:-right-full,[dir='rtl'].lg\:rtl\:-right-full {
    right: -100%
  }

  [dir='rtl'] .lg\:rtl\:-bottom-full,[dir='rtl'].lg\:rtl\:-bottom-full {
    bottom: -100%
  }

  [dir='rtl'] .lg\:rtl\:-left-full,[dir='rtl'].lg\:rtl\:-left-full {
    left: -100%
  }

  .lg\:resize-none {
    resize: none
  }

  .lg\:resize-y {
    resize: vertical
  }

  .lg\:resize-x {
    resize: horizontal
  }

  .lg\:resize {
    resize: both
  }

  .lg\:shadow-0 {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-1 {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-2 {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-3 {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-4 {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-5 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-6 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-7 {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-8 {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-9 {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-10 {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-11 {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-12 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-13 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-14 {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-15 {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-16 {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-17 {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-18 {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-19 {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-20 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-21 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-22 {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-23 {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-24 {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-0 {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-1 {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-2 {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-3 {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-4 {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-5 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-6 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-7 {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-8 {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-9 {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-10 {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-11 {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-12 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-13 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-14 {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-15 {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-16 {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-17 {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-18 {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-19 {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-20 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-21 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-22 {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-23 {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-24 {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-0:focus-within {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-1:focus-within {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-2:focus-within {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-3:focus-within {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-4:focus-within {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-5:focus-within {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-6:focus-within {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-7:focus-within {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-8:focus-within {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-9:focus-within {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-10:focus-within {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-11:focus-within {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-12:focus-within {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-13:focus-within {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-14:focus-within {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-15:focus-within {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-16:focus-within {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-17:focus-within {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-18:focus-within {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-19:focus-within {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-20:focus-within {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-21:focus-within {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-22:focus-within {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-23:focus-within {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-24:focus-within {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-0:hover {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-1:hover {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-2:hover {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-3:hover {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-4:hover {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-5:hover {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-6:hover {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-7:hover {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-8:hover {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-9:hover {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-10:hover {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-11:hover {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-12:hover {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-13:hover {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-14:hover {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-15:hover {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-16:hover {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-17:hover {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-18:hover {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-19:hover {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-20:hover {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-21:hover {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-22:hover {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-23:hover {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-24:hover {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-0:focus {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-1:focus {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-2:focus {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-3:focus {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-4:focus {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-5:focus {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-6:focus {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-7:focus {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-8:focus {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-9:focus {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-10:focus {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-11:focus {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-12:focus {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-13:focus {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-14:focus {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-15:focus {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-16:focus {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-17:focus {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-18:focus {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-19:focus {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-20:focus {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-21:focus {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-22:focus {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-23:focus {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-24:focus {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:ring-inset {
    --tw-ring-inset: inset
  }

  .lg\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset
  }

  .lg\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus\:ring-inset:focus {
    --tw-ring-inset: inset
  }

  .lg\:ring-offset-transparent {
    --tw-ring-offset-color: transparent
  }

  .lg\:ring-offset-current {
    --tw-ring-offset-color: currentColor
  }

  .lg\:ring-offset-black {
    --tw-ring-offset-color: #22292F
  }

  .lg\:ring-offset-white {
    --tw-ring-offset-color: #fff
  }

  .lg\:ring-offset-grey-50 {
    --tw-ring-offset-color: #FAFAFA
  }

  .lg\:ring-offset-grey-100 {
    --tw-ring-offset-color: #F5F5F5
  }

  .lg\:ring-offset-grey-200 {
    --tw-ring-offset-color: #EEEEEE
  }

  .lg\:ring-offset-grey-300 {
    --tw-ring-offset-color: #E0E0E0
  }

  .lg\:ring-offset-grey-400 {
    --tw-ring-offset-color: #BDBDBD
  }

  .lg\:ring-offset-grey-500 {
    --tw-ring-offset-color: #9E9E9E
  }

  .lg\:ring-offset-grey-600 {
    --tw-ring-offset-color: #757575
  }

  .lg\:ring-offset-grey-700 {
    --tw-ring-offset-color: #616161
  }

  .lg\:ring-offset-grey-800 {
    --tw-ring-offset-color: #424242
  }

  .lg\:ring-offset-grey-900 {
    --tw-ring-offset-color: #212121
  }

  .lg\:ring-offset-grey {
    --tw-ring-offset-color: #9E9E9E
  }

  .lg\:ring-offset-grey-A100 {
    --tw-ring-offset-color: #D5D5D5
  }

  .lg\:ring-offset-grey-A200 {
    --tw-ring-offset-color: #AAAAAA
  }

  .lg\:ring-offset-grey-A400 {
    --tw-ring-offset-color: #303030
  }

  .lg\:ring-offset-grey-A700 {
    --tw-ring-offset-color: #616161
  }

  .lg\:ring-offset-gray-50 {
    --tw-ring-offset-color: #FAFAFA
  }

  .lg\:ring-offset-gray-100 {
    --tw-ring-offset-color: #F5F5F5
  }

  .lg\:ring-offset-gray-200 {
    --tw-ring-offset-color: #EEEEEE
  }

  .lg\:ring-offset-gray-300 {
    --tw-ring-offset-color: #E0E0E0
  }

  .lg\:ring-offset-gray-400 {
    --tw-ring-offset-color: #BDBDBD
  }

  .lg\:ring-offset-gray-500 {
    --tw-ring-offset-color: #9E9E9E
  }

  .lg\:ring-offset-gray-600 {
    --tw-ring-offset-color: #757575
  }

  .lg\:ring-offset-gray-700 {
    --tw-ring-offset-color: #616161
  }

  .lg\:ring-offset-gray-800 {
    --tw-ring-offset-color: #424242
  }

  .lg\:ring-offset-gray-900 {
    --tw-ring-offset-color: #212121
  }

  .lg\:ring-offset-gray {
    --tw-ring-offset-color: #9E9E9E
  }

  .lg\:ring-offset-gray-hover {
    --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
  }

  .lg\:ring-offset-gray-A100 {
    --tw-ring-offset-color: #D5D5D5
  }

  .lg\:ring-offset-gray-A200 {
    --tw-ring-offset-color: #AAAAAA
  }

  .lg\:ring-offset-gray-A400 {
    --tw-ring-offset-color: #303030
  }

  .lg\:ring-offset-gray-A700 {
    --tw-ring-offset-color: #616161
  }

  .lg\:ring-offset-red-50 {
    --tw-ring-offset-color: #FFEBEE
  }

  .lg\:ring-offset-red-100 {
    --tw-ring-offset-color: #FFCDD2
  }

  .lg\:ring-offset-red-200 {
    --tw-ring-offset-color: #EF9A9A
  }

  .lg\:ring-offset-red-300 {
    --tw-ring-offset-color: #E57373
  }

  .lg\:ring-offset-red-400 {
    --tw-ring-offset-color: #EF5350
  }

  .lg\:ring-offset-red-500 {
    --tw-ring-offset-color: #F44336
  }

  .lg\:ring-offset-red-600 {
    --tw-ring-offset-color: #E53935
  }

  .lg\:ring-offset-red-700 {
    --tw-ring-offset-color: #D32F2F
  }

  .lg\:ring-offset-red-800 {
    --tw-ring-offset-color: #C62828
  }

  .lg\:ring-offset-red-900 {
    --tw-ring-offset-color: #B71C1C
  }

  .lg\:ring-offset-red {
    --tw-ring-offset-color: #F44336
  }

  .lg\:ring-offset-red-A100 {
    --tw-ring-offset-color: #FF8A80
  }

  .lg\:ring-offset-red-A200 {
    --tw-ring-offset-color: #FF5252
  }

  .lg\:ring-offset-red-A400 {
    --tw-ring-offset-color: #FF1744
  }

  .lg\:ring-offset-red-A700 {
    --tw-ring-offset-color: #D50000
  }

  .lg\:ring-offset-orange-50 {
    --tw-ring-offset-color: #FFF3E0
  }

  .lg\:ring-offset-orange-100 {
    --tw-ring-offset-color: #FFE0B2
  }

  .lg\:ring-offset-orange-200 {
    --tw-ring-offset-color: #FFCC80
  }

  .lg\:ring-offset-orange-300 {
    --tw-ring-offset-color: #FFB74D
  }

  .lg\:ring-offset-orange-400 {
    --tw-ring-offset-color: #FFA726
  }

  .lg\:ring-offset-orange-500 {
    --tw-ring-offset-color: #FF9800
  }

  .lg\:ring-offset-orange-600 {
    --tw-ring-offset-color: #FB8C00
  }

  .lg\:ring-offset-orange-700 {
    --tw-ring-offset-color: #F57C00
  }

  .lg\:ring-offset-orange-800 {
    --tw-ring-offset-color: #EF6C00
  }

  .lg\:ring-offset-orange-900 {
    --tw-ring-offset-color: #E65100
  }

  .lg\:ring-offset-orange {
    --tw-ring-offset-color: #FF9800
  }

  .lg\:ring-offset-orange-A100 {
    --tw-ring-offset-color: #FFD180
  }

  .lg\:ring-offset-orange-A200 {
    --tw-ring-offset-color: #FFAB40
  }

  .lg\:ring-offset-orange-A400 {
    --tw-ring-offset-color: #FF9100
  }

  .lg\:ring-offset-orange-A700 {
    --tw-ring-offset-color: #FF6D00
  }

  .lg\:ring-offset-deep-orange-50 {
    --tw-ring-offset-color: #FBE9E7
  }

  .lg\:ring-offset-deep-orange-100 {
    --tw-ring-offset-color: #FFCCBC
  }

  .lg\:ring-offset-deep-orange-200 {
    --tw-ring-offset-color: #FFAB91
  }

  .lg\:ring-offset-deep-orange-300 {
    --tw-ring-offset-color: #FF8A65
  }

  .lg\:ring-offset-deep-orange-400 {
    --tw-ring-offset-color: #FF7043
  }

  .lg\:ring-offset-deep-orange-500 {
    --tw-ring-offset-color: #FF5722
  }

  .lg\:ring-offset-deep-orange-600 {
    --tw-ring-offset-color: #F4511E
  }

  .lg\:ring-offset-deep-orange-700 {
    --tw-ring-offset-color: #E64A19
  }

  .lg\:ring-offset-deep-orange-800 {
    --tw-ring-offset-color: #D84315
  }

  .lg\:ring-offset-deep-orange-900 {
    --tw-ring-offset-color: #BF360C
  }

  .lg\:ring-offset-deep-orange {
    --tw-ring-offset-color: #FF5722
  }

  .lg\:ring-offset-deep-orange-A100 {
    --tw-ring-offset-color: #FF9E80
  }

  .lg\:ring-offset-deep-orange-A200 {
    --tw-ring-offset-color: #FF6E40
  }

  .lg\:ring-offset-deep-orange-A400 {
    --tw-ring-offset-color: #FF3D00
  }

  .lg\:ring-offset-deep-orange-A700 {
    --tw-ring-offset-color: #DD2C00
  }

  .lg\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #FFFDE7
  }

  .lg\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #FFF9C4
  }

  .lg\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #FFF59D
  }

  .lg\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #FFF176
  }

  .lg\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #FFEE58
  }

  .lg\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #FFEB3B
  }

  .lg\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #FDD835
  }

  .lg\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #FBC02D
  }

  .lg\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #F9A825
  }

  .lg\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #F57F17
  }

  .lg\:ring-offset-yellow {
    --tw-ring-offset-color: #FFEB3B
  }

  .lg\:ring-offset-yellow-A100 {
    --tw-ring-offset-color: #FFFF8D
  }

  .lg\:ring-offset-yellow-A200 {
    --tw-ring-offset-color: #FFFF00
  }

  .lg\:ring-offset-yellow-A400 {
    --tw-ring-offset-color: #FFEA00
  }

  .lg\:ring-offset-yellow-A700 {
    --tw-ring-offset-color: #FFD600
  }

  .lg\:ring-offset-green-50 {
    --tw-ring-offset-color: #E8F5E9
  }

  .lg\:ring-offset-green-100 {
    --tw-ring-offset-color: #C8E6C9
  }

  .lg\:ring-offset-green-200 {
    --tw-ring-offset-color: #A5D6A7
  }

  .lg\:ring-offset-green-300 {
    --tw-ring-offset-color: #81C784
  }

  .lg\:ring-offset-green-400 {
    --tw-ring-offset-color: #66BB6A
  }

  .lg\:ring-offset-green-500 {
    --tw-ring-offset-color: #4CAF50
  }

  .lg\:ring-offset-green-600 {
    --tw-ring-offset-color: #43A047
  }

  .lg\:ring-offset-green-700 {
    --tw-ring-offset-color: #388E3C
  }

  .lg\:ring-offset-green-800 {
    --tw-ring-offset-color: #2E7D32
  }

  .lg\:ring-offset-green-900 {
    --tw-ring-offset-color: #1B5E20
  }

  .lg\:ring-offset-green {
    --tw-ring-offset-color: #4CAF50
  }

  .lg\:ring-offset-green-A100 {
    --tw-ring-offset-color: #B9F6CA
  }

  .lg\:ring-offset-green-A200 {
    --tw-ring-offset-color: #69F0AE
  }

  .lg\:ring-offset-green-A400 {
    --tw-ring-offset-color: #00E676
  }

  .lg\:ring-offset-green-A700 {
    --tw-ring-offset-color: #00C853
  }

  .lg\:ring-offset-light-green-50 {
    --tw-ring-offset-color: #F1F8E9
  }

  .lg\:ring-offset-light-green-100 {
    --tw-ring-offset-color: #DCEDC8
  }

  .lg\:ring-offset-light-green-200 {
    --tw-ring-offset-color: #C5E1A5
  }

  .lg\:ring-offset-light-green-300 {
    --tw-ring-offset-color: #AED581
  }

  .lg\:ring-offset-light-green-400 {
    --tw-ring-offset-color: #9CCC65
  }

  .lg\:ring-offset-light-green-500 {
    --tw-ring-offset-color: #8BC34A
  }

  .lg\:ring-offset-light-green-600 {
    --tw-ring-offset-color: #7CB342
  }

  .lg\:ring-offset-light-green-700 {
    --tw-ring-offset-color: #689F38
  }

  .lg\:ring-offset-light-green-800 {
    --tw-ring-offset-color: #558B2F
  }

  .lg\:ring-offset-light-green-900 {
    --tw-ring-offset-color: #33691E
  }

  .lg\:ring-offset-light-green {
    --tw-ring-offset-color: #8BC34A
  }

  .lg\:ring-offset-light-green-A100 {
    --tw-ring-offset-color: #CCFF90
  }

  .lg\:ring-offset-light-green-A200 {
    --tw-ring-offset-color: #B2FF59
  }

  .lg\:ring-offset-light-green-A400 {
    --tw-ring-offset-color: #76FF03
  }

  .lg\:ring-offset-light-green-A700 {
    --tw-ring-offset-color: #64DD17
  }

  .lg\:ring-offset-teal-50 {
    --tw-ring-offset-color: #E0F2F1
  }

  .lg\:ring-offset-teal-100 {
    --tw-ring-offset-color: #B2DFDB
  }

  .lg\:ring-offset-teal-200 {
    --tw-ring-offset-color: #80CBC4
  }

  .lg\:ring-offset-teal-300 {
    --tw-ring-offset-color: #4DB6AC
  }

  .lg\:ring-offset-teal-400 {
    --tw-ring-offset-color: #26A69A
  }

  .lg\:ring-offset-teal-500 {
    --tw-ring-offset-color: #009688
  }

  .lg\:ring-offset-teal-600 {
    --tw-ring-offset-color: #00897B
  }

  .lg\:ring-offset-teal-700 {
    --tw-ring-offset-color: #00796B
  }

  .lg\:ring-offset-teal-800 {
    --tw-ring-offset-color: #00695C
  }

  .lg\:ring-offset-teal-900 {
    --tw-ring-offset-color: #004D40
  }

  .lg\:ring-offset-teal {
    --tw-ring-offset-color: #009688
  }

  .lg\:ring-offset-teal-A100 {
    --tw-ring-offset-color: #A7FFEB
  }

  .lg\:ring-offset-teal-A200 {
    --tw-ring-offset-color: #64FFDA
  }

  .lg\:ring-offset-teal-A400 {
    --tw-ring-offset-color: #1DE9B6
  }

  .lg\:ring-offset-teal-A700 {
    --tw-ring-offset-color: #00BFA5
  }

  .lg\:ring-offset-blue-50 {
    --tw-ring-offset-color: #E3F2FD
  }

  .lg\:ring-offset-blue-100 {
    --tw-ring-offset-color: #BBDEFB
  }

  .lg\:ring-offset-blue-200 {
    --tw-ring-offset-color: #90CAF9
  }

  .lg\:ring-offset-blue-300 {
    --tw-ring-offset-color: #64B5F6
  }

  .lg\:ring-offset-blue-400 {
    --tw-ring-offset-color: #42A5F5
  }

  .lg\:ring-offset-blue-500 {
    --tw-ring-offset-color: #2196F3
  }

  .lg\:ring-offset-blue-600 {
    --tw-ring-offset-color: #1E88E5
  }

  .lg\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1976D2
  }

  .lg\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1565C0
  }

  .lg\:ring-offset-blue-900 {
    --tw-ring-offset-color: #0D47A1
  }

  .lg\:ring-offset-blue {
    --tw-ring-offset-color: #2196F3
  }

  .lg\:ring-offset-blue-A100 {
    --tw-ring-offset-color: #82B1FF
  }

  .lg\:ring-offset-blue-A200 {
    --tw-ring-offset-color: #448AFF
  }

  .lg\:ring-offset-blue-A400 {
    --tw-ring-offset-color: #2979FF
  }

  .lg\:ring-offset-blue-A700 {
    --tw-ring-offset-color: #2962FF
  }

  .lg\:ring-offset-light-blue-50 {
    --tw-ring-offset-color: #E1F5FE
  }

  .lg\:ring-offset-light-blue-100 {
    --tw-ring-offset-color: #B3E5FC
  }

  .lg\:ring-offset-light-blue-200 {
    --tw-ring-offset-color: #81D4FA
  }

  .lg\:ring-offset-light-blue-300 {
    --tw-ring-offset-color: #4FC3F7
  }

  .lg\:ring-offset-light-blue-400 {
    --tw-ring-offset-color: #29B6F6
  }

  .lg\:ring-offset-light-blue-500 {
    --tw-ring-offset-color: #03A9F4
  }

  .lg\:ring-offset-light-blue-600 {
    --tw-ring-offset-color: #039BE5
  }

  .lg\:ring-offset-light-blue-700 {
    --tw-ring-offset-color: #0288D1
  }

  .lg\:ring-offset-light-blue-800 {
    --tw-ring-offset-color: #0277BD
  }

  .lg\:ring-offset-light-blue-900 {
    --tw-ring-offset-color: #01579B
  }

  .lg\:ring-offset-light-blue {
    --tw-ring-offset-color: #03A9F4
  }

  .lg\:ring-offset-light-blue-A100 {
    --tw-ring-offset-color: #80D8FF
  }

  .lg\:ring-offset-light-blue-A200 {
    --tw-ring-offset-color: #40C4FF
  }

  .lg\:ring-offset-light-blue-A400 {
    --tw-ring-offset-color: #00B0FF
  }

  .lg\:ring-offset-light-blue-A700 {
    --tw-ring-offset-color: #0091EA
  }

  .lg\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #E8EAF6
  }

  .lg\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #C5CAE9
  }

  .lg\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #9FA8DA
  }

  .lg\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #7986CB
  }

  .lg\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #5C6BC0
  }

  .lg\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #3F51B5
  }

  .lg\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #3949AB
  }

  .lg\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #303F9F
  }

  .lg\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #283593
  }

  .lg\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #1A237E
  }

  .lg\:ring-offset-indigo {
    --tw-ring-offset-color: #3F51B5
  }

  .lg\:ring-offset-indigo-A100 {
    --tw-ring-offset-color: #8C9EFF
  }

  .lg\:ring-offset-indigo-A200 {
    --tw-ring-offset-color: #536DFE
  }

  .lg\:ring-offset-indigo-A400 {
    --tw-ring-offset-color: #3D5AFE
  }

  .lg\:ring-offset-indigo-A700 {
    --tw-ring-offset-color: #304FFE
  }

  .lg\:ring-offset-purple-50 {
    --tw-ring-offset-color: #F3E5F5
  }

  .lg\:ring-offset-purple-100 {
    --tw-ring-offset-color: #E1BEE7
  }

  .lg\:ring-offset-purple-200 {
    --tw-ring-offset-color: #CE93D8
  }

  .lg\:ring-offset-purple-300 {
    --tw-ring-offset-color: #BA68C8
  }

  .lg\:ring-offset-purple-400 {
    --tw-ring-offset-color: #AB47BC
  }

  .lg\:ring-offset-purple-500 {
    --tw-ring-offset-color: #9C27B0
  }

  .lg\:ring-offset-purple-600 {
    --tw-ring-offset-color: #8E24AA
  }

  .lg\:ring-offset-purple-700 {
    --tw-ring-offset-color: #7B1FA2
  }

  .lg\:ring-offset-purple-800 {
    --tw-ring-offset-color: #6A1B9A
  }

  .lg\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4A148C
  }

  .lg\:ring-offset-purple {
    --tw-ring-offset-color: #9C27B0
  }

  .lg\:ring-offset-purple-A100 {
    --tw-ring-offset-color: #EA80FC
  }

  .lg\:ring-offset-purple-A200 {
    --tw-ring-offset-color: #E040FB
  }

  .lg\:ring-offset-purple-A400 {
    --tw-ring-offset-color: #D500F9
  }

  .lg\:ring-offset-purple-A700 {
    --tw-ring-offset-color: #AA00FF
  }

  .lg\:ring-offset-deep-purple-50 {
    --tw-ring-offset-color: #EDE7F6
  }

  .lg\:ring-offset-deep-purple-100 {
    --tw-ring-offset-color: #D1C4E9
  }

  .lg\:ring-offset-deep-purple-200 {
    --tw-ring-offset-color: #B39DDB
  }

  .lg\:ring-offset-deep-purple-300 {
    --tw-ring-offset-color: #9575CD
  }

  .lg\:ring-offset-deep-purple-400 {
    --tw-ring-offset-color: #7E57C2
  }

  .lg\:ring-offset-deep-purple-500 {
    --tw-ring-offset-color: #673AB7
  }

  .lg\:ring-offset-deep-purple-600 {
    --tw-ring-offset-color: #5E35B1
  }

  .lg\:ring-offset-deep-purple-700 {
    --tw-ring-offset-color: #512DA8
  }

  .lg\:ring-offset-deep-purple-800 {
    --tw-ring-offset-color: #4527A0
  }

  .lg\:ring-offset-deep-purple-900 {
    --tw-ring-offset-color: #311B92
  }

  .lg\:ring-offset-deep-purple {
    --tw-ring-offset-color: #673AB7
  }

  .lg\:ring-offset-deep-purple-A100 {
    --tw-ring-offset-color: #B388FF
  }

  .lg\:ring-offset-deep-purple-A200 {
    --tw-ring-offset-color: #7C4DFF
  }

  .lg\:ring-offset-deep-purple-A400 {
    --tw-ring-offset-color: #651FFF
  }

  .lg\:ring-offset-deep-purple-A700 {
    --tw-ring-offset-color: #6200EA
  }

  .lg\:ring-offset-pink-50 {
    --tw-ring-offset-color: #FCE4EC
  }

  .lg\:ring-offset-pink-100 {
    --tw-ring-offset-color: #F8BBD0
  }

  .lg\:ring-offset-pink-200 {
    --tw-ring-offset-color: #F48FB1
  }

  .lg\:ring-offset-pink-300 {
    --tw-ring-offset-color: #F06292
  }

  .lg\:ring-offset-pink-400 {
    --tw-ring-offset-color: #EC407A
  }

  .lg\:ring-offset-pink-500 {
    --tw-ring-offset-color: #E91E63
  }

  .lg\:ring-offset-pink-600 {
    --tw-ring-offset-color: #D81B60
  }

  .lg\:ring-offset-pink-700 {
    --tw-ring-offset-color: #C2185B
  }

  .lg\:ring-offset-pink-800 {
    --tw-ring-offset-color: #AD1457
  }

  .lg\:ring-offset-pink-900 {
    --tw-ring-offset-color: #880E4F
  }

  .lg\:ring-offset-pink {
    --tw-ring-offset-color: #E91E63
  }

  .lg\:ring-offset-pink-A100 {
    --tw-ring-offset-color: #FF80AB
  }

  .lg\:ring-offset-pink-A200 {
    --tw-ring-offset-color: #FF4081
  }

  .lg\:ring-offset-pink-A400 {
    --tw-ring-offset-color: #F50057
  }

  .lg\:ring-offset-pink-A700 {
    --tw-ring-offset-color: #C51162
  }

  .lg\:ring-offset-lime-50 {
    --tw-ring-offset-color: #F9FBE7
  }

  .lg\:ring-offset-lime-100 {
    --tw-ring-offset-color: #F0F4C3
  }

  .lg\:ring-offset-lime-200 {
    --tw-ring-offset-color: #E6EE9C
  }

  .lg\:ring-offset-lime-300 {
    --tw-ring-offset-color: #DCE775
  }

  .lg\:ring-offset-lime-400 {
    --tw-ring-offset-color: #D4E157
  }

  .lg\:ring-offset-lime-500 {
    --tw-ring-offset-color: #CDDC39
  }

  .lg\:ring-offset-lime-600 {
    --tw-ring-offset-color: #C0CA33
  }

  .lg\:ring-offset-lime-700 {
    --tw-ring-offset-color: #AFB42B
  }

  .lg\:ring-offset-lime-800 {
    --tw-ring-offset-color: #9E9D24
  }

  .lg\:ring-offset-lime-900 {
    --tw-ring-offset-color: #827717
  }

  .lg\:ring-offset-lime {
    --tw-ring-offset-color: #CDDC39
  }

  .lg\:ring-offset-lime-A100 {
    --tw-ring-offset-color: #F4FF81
  }

  .lg\:ring-offset-lime-A200 {
    --tw-ring-offset-color: #EEFF41
  }

  .lg\:ring-offset-lime-A400 {
    --tw-ring-offset-color: #C6FF00
  }

  .lg\:ring-offset-lime-A700 {
    --tw-ring-offset-color: #AEEA00
  }

  .lg\:ring-offset-amber-50 {
    --tw-ring-offset-color: #FFF8E1
  }

  .lg\:ring-offset-amber-100 {
    --tw-ring-offset-color: #FFECB3
  }

  .lg\:ring-offset-amber-200 {
    --tw-ring-offset-color: #FFE082
  }

  .lg\:ring-offset-amber-300 {
    --tw-ring-offset-color: #FFD54F
  }

  .lg\:ring-offset-amber-400 {
    --tw-ring-offset-color: #FFCA28
  }

  .lg\:ring-offset-amber-500 {
    --tw-ring-offset-color: #FFC107
  }

  .lg\:ring-offset-amber-600 {
    --tw-ring-offset-color: #FFB300
  }

  .lg\:ring-offset-amber-700 {
    --tw-ring-offset-color: #FFA000
  }

  .lg\:ring-offset-amber-800 {
    --tw-ring-offset-color: #FF8F00
  }

  .lg\:ring-offset-amber-900 {
    --tw-ring-offset-color: #FF6F00
  }

  .lg\:ring-offset-amber {
    --tw-ring-offset-color: #FFC107
  }

  .lg\:ring-offset-amber-A100 {
    --tw-ring-offset-color: #FFE57F
  }

  .lg\:ring-offset-amber-A200 {
    --tw-ring-offset-color: #FFD740
  }

  .lg\:ring-offset-amber-A400 {
    --tw-ring-offset-color: #FFC400
  }

  .lg\:ring-offset-amber-A700 {
    --tw-ring-offset-color: #FFAB00
  }

  .lg\:ring-offset-brown-50 {
    --tw-ring-offset-color: #EFEBE9
  }

  .lg\:ring-offset-brown-100 {
    --tw-ring-offset-color: #D7CCC8
  }

  .lg\:ring-offset-brown-200 {
    --tw-ring-offset-color: #BCAAA4
  }

  .lg\:ring-offset-brown-300 {
    --tw-ring-offset-color: #A1887F
  }

  .lg\:ring-offset-brown-400 {
    --tw-ring-offset-color: #8D6E63
  }

  .lg\:ring-offset-brown-500 {
    --tw-ring-offset-color: #795548
  }

  .lg\:ring-offset-brown-600 {
    --tw-ring-offset-color: #6D4C41
  }

  .lg\:ring-offset-brown-700 {
    --tw-ring-offset-color: #5D4037
  }

  .lg\:ring-offset-brown-800 {
    --tw-ring-offset-color: #4E342E
  }

  .lg\:ring-offset-brown-900 {
    --tw-ring-offset-color: #3E2723
  }

  .lg\:ring-offset-brown {
    --tw-ring-offset-color: #795548
  }

  .lg\:ring-offset-brown-A100 {
    --tw-ring-offset-color: #D7CCC8
  }

  .lg\:ring-offset-brown-A200 {
    --tw-ring-offset-color: #BCAAA4
  }

  .lg\:ring-offset-brown-A400 {
    --tw-ring-offset-color: #8D6E63
  }

  .lg\:ring-offset-brown-A700 {
    --tw-ring-offset-color: #5D4037
  }

  .lg\:ring-offset-blue-gray-50 {
    --tw-ring-offset-color: #ECEFF1
  }

  .lg\:ring-offset-blue-gray-100 {
    --tw-ring-offset-color: #CFD8DC
  }

  .lg\:ring-offset-blue-gray-200 {
    --tw-ring-offset-color: #B0BEC5
  }

  .lg\:ring-offset-blue-gray-300 {
    --tw-ring-offset-color: #90A4AE
  }

  .lg\:ring-offset-blue-gray-400 {
    --tw-ring-offset-color: #78909C
  }

  .lg\:ring-offset-blue-gray-500 {
    --tw-ring-offset-color: #607D8B
  }

  .lg\:ring-offset-blue-gray-600 {
    --tw-ring-offset-color: #546E7A
  }

  .lg\:ring-offset-blue-gray-700 {
    --tw-ring-offset-color: #455A64
  }

  .lg\:ring-offset-blue-gray-800 {
    --tw-ring-offset-color: #37474F
  }

  .lg\:ring-offset-blue-gray-900 {
    --tw-ring-offset-color: #263238
  }

  .lg\:ring-offset-blue-gray {
    --tw-ring-offset-color: #607D8B
  }

  .lg\:ring-offset-blue-gray-A100 {
    --tw-ring-offset-color: #CFD8DC
  }

  .lg\:ring-offset-blue-gray-A200 {
    --tw-ring-offset-color: #B0BEC5
  }

  .lg\:ring-offset-blue-gray-A400 {
    --tw-ring-offset-color: #78909C
  }

  .lg\:ring-offset-blue-gray-A700 {
    --tw-ring-offset-color: #455A64
  }

  .lg\:ring-offset-cyan-50 {
    --tw-ring-offset-color: #E0F7FA
  }

  .lg\:ring-offset-cyan-100 {
    --tw-ring-offset-color: #B2EBF2
  }

  .lg\:ring-offset-cyan-200 {
    --tw-ring-offset-color: #80DEEA
  }

  .lg\:ring-offset-cyan-300 {
    --tw-ring-offset-color: #4DD0E1
  }

  .lg\:ring-offset-cyan-400 {
    --tw-ring-offset-color: #26C6DA
  }

  .lg\:ring-offset-cyan-500 {
    --tw-ring-offset-color: #00BCD4
  }

  .lg\:ring-offset-cyan-600 {
    --tw-ring-offset-color: #00ACC1
  }

  .lg\:ring-offset-cyan-700 {
    --tw-ring-offset-color: #0097A7
  }

  .lg\:ring-offset-cyan-800 {
    --tw-ring-offset-color: #00838F
  }

  .lg\:ring-offset-cyan-900 {
    --tw-ring-offset-color: #006064
  }

  .lg\:ring-offset-cyan {
    --tw-ring-offset-color: #00BCD4
  }

  .lg\:ring-offset-cyan-A100 {
    --tw-ring-offset-color: #84FFFF
  }

  .lg\:ring-offset-cyan-A200 {
    --tw-ring-offset-color: #18FFFF
  }

  .lg\:ring-offset-cyan-A400 {
    --tw-ring-offset-color: #00E5FF
  }

  .lg\:ring-offset-cyan-A700 {
    --tw-ring-offset-color: #00B8D4
  }

  .lg\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent
  }

  .lg\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor
  }

  .lg\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #22292F
  }

  .lg\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff
  }

  .lg\:focus-within\:ring-offset-grey-50:focus-within {
    --tw-ring-offset-color: #FAFAFA
  }

  .lg\:focus-within\:ring-offset-grey-100:focus-within {
    --tw-ring-offset-color: #F5F5F5
  }

  .lg\:focus-within\:ring-offset-grey-200:focus-within {
    --tw-ring-offset-color: #EEEEEE
  }

  .lg\:focus-within\:ring-offset-grey-300:focus-within {
    --tw-ring-offset-color: #E0E0E0
  }

  .lg\:focus-within\:ring-offset-grey-400:focus-within {
    --tw-ring-offset-color: #BDBDBD
  }

  .lg\:focus-within\:ring-offset-grey-500:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .lg\:focus-within\:ring-offset-grey-600:focus-within {
    --tw-ring-offset-color: #757575
  }

  .lg\:focus-within\:ring-offset-grey-700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .lg\:focus-within\:ring-offset-grey-800:focus-within {
    --tw-ring-offset-color: #424242
  }

  .lg\:focus-within\:ring-offset-grey-900:focus-within {
    --tw-ring-offset-color: #212121
  }

  .lg\:focus-within\:ring-offset-grey:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .lg\:focus-within\:ring-offset-grey-A100:focus-within {
    --tw-ring-offset-color: #D5D5D5
  }

  .lg\:focus-within\:ring-offset-grey-A200:focus-within {
    --tw-ring-offset-color: #AAAAAA
  }

  .lg\:focus-within\:ring-offset-grey-A400:focus-within {
    --tw-ring-offset-color: #303030
  }

  .lg\:focus-within\:ring-offset-grey-A700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .lg\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #FAFAFA
  }

  .lg\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #F5F5F5
  }

  .lg\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #EEEEEE
  }

  .lg\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #E0E0E0
  }

  .lg\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #BDBDBD
  }

  .lg\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .lg\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #757575
  }

  .lg\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .lg\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #424242
  }

  .lg\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #212121
  }

  .lg\:focus-within\:ring-offset-gray:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .lg\:focus-within\:ring-offset-gray-hover:focus-within {
    --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
  }

  .lg\:focus-within\:ring-offset-gray-A100:focus-within {
    --tw-ring-offset-color: #D5D5D5
  }

  .lg\:focus-within\:ring-offset-gray-A200:focus-within {
    --tw-ring-offset-color: #AAAAAA
  }

  .lg\:focus-within\:ring-offset-gray-A400:focus-within {
    --tw-ring-offset-color: #303030
  }

  .lg\:focus-within\:ring-offset-gray-A700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .lg\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #FFEBEE
  }

  .lg\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #FFCDD2
  }

  .lg\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #EF9A9A
  }

  .lg\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #E57373
  }

  .lg\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #EF5350
  }

  .lg\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #F44336
  }

  .lg\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #E53935
  }

  .lg\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #D32F2F
  }

  .lg\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #C62828
  }

  .lg\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #B71C1C
  }

  .lg\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: #F44336
  }

  .lg\:focus-within\:ring-offset-red-A100:focus-within {
    --tw-ring-offset-color: #FF8A80
  }

  .lg\:focus-within\:ring-offset-red-A200:focus-within {
    --tw-ring-offset-color: #FF5252
  }

  .lg\:focus-within\:ring-offset-red-A400:focus-within {
    --tw-ring-offset-color: #FF1744
  }

  .lg\:focus-within\:ring-offset-red-A700:focus-within {
    --tw-ring-offset-color: #D50000
  }

  .lg\:focus-within\:ring-offset-orange-50:focus-within {
    --tw-ring-offset-color: #FFF3E0
  }

  .lg\:focus-within\:ring-offset-orange-100:focus-within {
    --tw-ring-offset-color: #FFE0B2
  }

  .lg\:focus-within\:ring-offset-orange-200:focus-within {
    --tw-ring-offset-color: #FFCC80
  }

  .lg\:focus-within\:ring-offset-orange-300:focus-within {
    --tw-ring-offset-color: #FFB74D
  }

  .lg\:focus-within\:ring-offset-orange-400:focus-within {
    --tw-ring-offset-color: #FFA726
  }

  .lg\:focus-within\:ring-offset-orange-500:focus-within {
    --tw-ring-offset-color: #FF9800
  }

  .lg\:focus-within\:ring-offset-orange-600:focus-within {
    --tw-ring-offset-color: #FB8C00
  }

  .lg\:focus-within\:ring-offset-orange-700:focus-within {
    --tw-ring-offset-color: #F57C00
  }

  .lg\:focus-within\:ring-offset-orange-800:focus-within {
    --tw-ring-offset-color: #EF6C00
  }

  .lg\:focus-within\:ring-offset-orange-900:focus-within {
    --tw-ring-offset-color: #E65100
  }

  .lg\:focus-within\:ring-offset-orange:focus-within {
    --tw-ring-offset-color: #FF9800
  }

  .lg\:focus-within\:ring-offset-orange-A100:focus-within {
    --tw-ring-offset-color: #FFD180
  }

  .lg\:focus-within\:ring-offset-orange-A200:focus-within {
    --tw-ring-offset-color: #FFAB40
  }

  .lg\:focus-within\:ring-offset-orange-A400:focus-within {
    --tw-ring-offset-color: #FF9100
  }

  .lg\:focus-within\:ring-offset-orange-A700:focus-within {
    --tw-ring-offset-color: #FF6D00
  }

  .lg\:focus-within\:ring-offset-deep-orange-50:focus-within {
    --tw-ring-offset-color: #FBE9E7
  }

  .lg\:focus-within\:ring-offset-deep-orange-100:focus-within {
    --tw-ring-offset-color: #FFCCBC
  }

  .lg\:focus-within\:ring-offset-deep-orange-200:focus-within {
    --tw-ring-offset-color: #FFAB91
  }

  .lg\:focus-within\:ring-offset-deep-orange-300:focus-within {
    --tw-ring-offset-color: #FF8A65
  }

  .lg\:focus-within\:ring-offset-deep-orange-400:focus-within {
    --tw-ring-offset-color: #FF7043
  }

  .lg\:focus-within\:ring-offset-deep-orange-500:focus-within {
    --tw-ring-offset-color: #FF5722
  }

  .lg\:focus-within\:ring-offset-deep-orange-600:focus-within {
    --tw-ring-offset-color: #F4511E
  }

  .lg\:focus-within\:ring-offset-deep-orange-700:focus-within {
    --tw-ring-offset-color: #E64A19
  }

  .lg\:focus-within\:ring-offset-deep-orange-800:focus-within {
    --tw-ring-offset-color: #D84315
  }

  .lg\:focus-within\:ring-offset-deep-orange-900:focus-within {
    --tw-ring-offset-color: #BF360C
  }

  .lg\:focus-within\:ring-offset-deep-orange:focus-within {
    --tw-ring-offset-color: #FF5722
  }

  .lg\:focus-within\:ring-offset-deep-orange-A100:focus-within {
    --tw-ring-offset-color: #FF9E80
  }

  .lg\:focus-within\:ring-offset-deep-orange-A200:focus-within {
    --tw-ring-offset-color: #FF6E40
  }

  .lg\:focus-within\:ring-offset-deep-orange-A400:focus-within {
    --tw-ring-offset-color: #FF3D00
  }

  .lg\:focus-within\:ring-offset-deep-orange-A700:focus-within {
    --tw-ring-offset-color: #DD2C00
  }

  .lg\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #FFFDE7
  }

  .lg\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #FFF9C4
  }

  .lg\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #FFF59D
  }

  .lg\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #FFF176
  }

  .lg\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #FFEE58
  }

  .lg\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #FFEB3B
  }

  .lg\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #FDD835
  }

  .lg\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #FBC02D
  }

  .lg\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #F9A825
  }

  .lg\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #F57F17
  }

  .lg\:focus-within\:ring-offset-yellow:focus-within {
    --tw-ring-offset-color: #FFEB3B
  }

  .lg\:focus-within\:ring-offset-yellow-A100:focus-within {
    --tw-ring-offset-color: #FFFF8D
  }

  .lg\:focus-within\:ring-offset-yellow-A200:focus-within {
    --tw-ring-offset-color: #FFFF00
  }

  .lg\:focus-within\:ring-offset-yellow-A400:focus-within {
    --tw-ring-offset-color: #FFEA00
  }

  .lg\:focus-within\:ring-offset-yellow-A700:focus-within {
    --tw-ring-offset-color: #FFD600
  }

  .lg\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #E8F5E9
  }

  .lg\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #C8E6C9
  }

  .lg\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #A5D6A7
  }

  .lg\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #81C784
  }

  .lg\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #66BB6A
  }

  .lg\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #4CAF50
  }

  .lg\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #43A047
  }

  .lg\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #388E3C
  }

  .lg\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #2E7D32
  }

  .lg\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #1B5E20
  }

  .lg\:focus-within\:ring-offset-green:focus-within {
    --tw-ring-offset-color: #4CAF50
  }

  .lg\:focus-within\:ring-offset-green-A100:focus-within {
    --tw-ring-offset-color: #B9F6CA
  }

  .lg\:focus-within\:ring-offset-green-A200:focus-within {
    --tw-ring-offset-color: #69F0AE
  }

  .lg\:focus-within\:ring-offset-green-A400:focus-within {
    --tw-ring-offset-color: #00E676
  }

  .lg\:focus-within\:ring-offset-green-A700:focus-within {
    --tw-ring-offset-color: #00C853
  }

  .lg\:focus-within\:ring-offset-light-green-50:focus-within {
    --tw-ring-offset-color: #F1F8E9
  }

  .lg\:focus-within\:ring-offset-light-green-100:focus-within {
    --tw-ring-offset-color: #DCEDC8
  }

  .lg\:focus-within\:ring-offset-light-green-200:focus-within {
    --tw-ring-offset-color: #C5E1A5
  }

  .lg\:focus-within\:ring-offset-light-green-300:focus-within {
    --tw-ring-offset-color: #AED581
  }

  .lg\:focus-within\:ring-offset-light-green-400:focus-within {
    --tw-ring-offset-color: #9CCC65
  }

  .lg\:focus-within\:ring-offset-light-green-500:focus-within {
    --tw-ring-offset-color: #8BC34A
  }

  .lg\:focus-within\:ring-offset-light-green-600:focus-within {
    --tw-ring-offset-color: #7CB342
  }

  .lg\:focus-within\:ring-offset-light-green-700:focus-within {
    --tw-ring-offset-color: #689F38
  }

  .lg\:focus-within\:ring-offset-light-green-800:focus-within {
    --tw-ring-offset-color: #558B2F
  }

  .lg\:focus-within\:ring-offset-light-green-900:focus-within {
    --tw-ring-offset-color: #33691E
  }

  .lg\:focus-within\:ring-offset-light-green:focus-within {
    --tw-ring-offset-color: #8BC34A
  }

  .lg\:focus-within\:ring-offset-light-green-A100:focus-within {
    --tw-ring-offset-color: #CCFF90
  }

  .lg\:focus-within\:ring-offset-light-green-A200:focus-within {
    --tw-ring-offset-color: #B2FF59
  }

  .lg\:focus-within\:ring-offset-light-green-A400:focus-within {
    --tw-ring-offset-color: #76FF03
  }

  .lg\:focus-within\:ring-offset-light-green-A700:focus-within {
    --tw-ring-offset-color: #64DD17
  }

  .lg\:focus-within\:ring-offset-teal-50:focus-within {
    --tw-ring-offset-color: #E0F2F1
  }

  .lg\:focus-within\:ring-offset-teal-100:focus-within {
    --tw-ring-offset-color: #B2DFDB
  }

  .lg\:focus-within\:ring-offset-teal-200:focus-within {
    --tw-ring-offset-color: #80CBC4
  }

  .lg\:focus-within\:ring-offset-teal-300:focus-within {
    --tw-ring-offset-color: #4DB6AC
  }

  .lg\:focus-within\:ring-offset-teal-400:focus-within {
    --tw-ring-offset-color: #26A69A
  }

  .lg\:focus-within\:ring-offset-teal-500:focus-within {
    --tw-ring-offset-color: #009688
  }

  .lg\:focus-within\:ring-offset-teal-600:focus-within {
    --tw-ring-offset-color: #00897B
  }

  .lg\:focus-within\:ring-offset-teal-700:focus-within {
    --tw-ring-offset-color: #00796B
  }

  .lg\:focus-within\:ring-offset-teal-800:focus-within {
    --tw-ring-offset-color: #00695C
  }

  .lg\:focus-within\:ring-offset-teal-900:focus-within {
    --tw-ring-offset-color: #004D40
  }

  .lg\:focus-within\:ring-offset-teal:focus-within {
    --tw-ring-offset-color: #009688
  }

  .lg\:focus-within\:ring-offset-teal-A100:focus-within {
    --tw-ring-offset-color: #A7FFEB
  }

  .lg\:focus-within\:ring-offset-teal-A200:focus-within {
    --tw-ring-offset-color: #64FFDA
  }

  .lg\:focus-within\:ring-offset-teal-A400:focus-within {
    --tw-ring-offset-color: #1DE9B6
  }

  .lg\:focus-within\:ring-offset-teal-A700:focus-within {
    --tw-ring-offset-color: #00BFA5
  }

  .lg\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #E3F2FD
  }

  .lg\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #BBDEFB
  }

  .lg\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #90CAF9
  }

  .lg\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #64B5F6
  }

  .lg\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #42A5F5
  }

  .lg\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #2196F3
  }

  .lg\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #1E88E5
  }

  .lg\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1976D2
  }

  .lg\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1565C0
  }

  .lg\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #0D47A1
  }

  .lg\:focus-within\:ring-offset-blue:focus-within {
    --tw-ring-offset-color: #2196F3
  }

  .lg\:focus-within\:ring-offset-blue-A100:focus-within {
    --tw-ring-offset-color: #82B1FF
  }

  .lg\:focus-within\:ring-offset-blue-A200:focus-within {
    --tw-ring-offset-color: #448AFF
  }

  .lg\:focus-within\:ring-offset-blue-A400:focus-within {
    --tw-ring-offset-color: #2979FF
  }

  .lg\:focus-within\:ring-offset-blue-A700:focus-within {
    --tw-ring-offset-color: #2962FF
  }

  .lg\:focus-within\:ring-offset-light-blue-50:focus-within {
    --tw-ring-offset-color: #E1F5FE
  }

  .lg\:focus-within\:ring-offset-light-blue-100:focus-within {
    --tw-ring-offset-color: #B3E5FC
  }

  .lg\:focus-within\:ring-offset-light-blue-200:focus-within {
    --tw-ring-offset-color: #81D4FA
  }

  .lg\:focus-within\:ring-offset-light-blue-300:focus-within {
    --tw-ring-offset-color: #4FC3F7
  }

  .lg\:focus-within\:ring-offset-light-blue-400:focus-within {
    --tw-ring-offset-color: #29B6F6
  }

  .lg\:focus-within\:ring-offset-light-blue-500:focus-within {
    --tw-ring-offset-color: #03A9F4
  }

  .lg\:focus-within\:ring-offset-light-blue-600:focus-within {
    --tw-ring-offset-color: #039BE5
  }

  .lg\:focus-within\:ring-offset-light-blue-700:focus-within {
    --tw-ring-offset-color: #0288D1
  }

  .lg\:focus-within\:ring-offset-light-blue-800:focus-within {
    --tw-ring-offset-color: #0277BD
  }

  .lg\:focus-within\:ring-offset-light-blue-900:focus-within {
    --tw-ring-offset-color: #01579B
  }

  .lg\:focus-within\:ring-offset-light-blue:focus-within {
    --tw-ring-offset-color: #03A9F4
  }

  .lg\:focus-within\:ring-offset-light-blue-A100:focus-within {
    --tw-ring-offset-color: #80D8FF
  }

  .lg\:focus-within\:ring-offset-light-blue-A200:focus-within {
    --tw-ring-offset-color: #40C4FF
  }

  .lg\:focus-within\:ring-offset-light-blue-A400:focus-within {
    --tw-ring-offset-color: #00B0FF
  }

  .lg\:focus-within\:ring-offset-light-blue-A700:focus-within {
    --tw-ring-offset-color: #0091EA
  }

  .lg\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #E8EAF6
  }

  .lg\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #C5CAE9
  }

  .lg\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #9FA8DA
  }

  .lg\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #7986CB
  }

  .lg\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #5C6BC0
  }

  .lg\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #3F51B5
  }

  .lg\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #3949AB
  }

  .lg\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #303F9F
  }

  .lg\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #283593
  }

  .lg\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #1A237E
  }

  .lg\:focus-within\:ring-offset-indigo:focus-within {
    --tw-ring-offset-color: #3F51B5
  }

  .lg\:focus-within\:ring-offset-indigo-A100:focus-within {
    --tw-ring-offset-color: #8C9EFF
  }

  .lg\:focus-within\:ring-offset-indigo-A200:focus-within {
    --tw-ring-offset-color: #536DFE
  }

  .lg\:focus-within\:ring-offset-indigo-A400:focus-within {
    --tw-ring-offset-color: #3D5AFE
  }

  .lg\:focus-within\:ring-offset-indigo-A700:focus-within {
    --tw-ring-offset-color: #304FFE
  }

  .lg\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #F3E5F5
  }

  .lg\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #E1BEE7
  }

  .lg\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #CE93D8
  }

  .lg\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #BA68C8
  }

  .lg\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #AB47BC
  }

  .lg\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #9C27B0
  }

  .lg\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #8E24AA
  }

  .lg\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #7B1FA2
  }

  .lg\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #6A1B9A
  }

  .lg\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4A148C
  }

  .lg\:focus-within\:ring-offset-purple:focus-within {
    --tw-ring-offset-color: #9C27B0
  }

  .lg\:focus-within\:ring-offset-purple-A100:focus-within {
    --tw-ring-offset-color: #EA80FC
  }

  .lg\:focus-within\:ring-offset-purple-A200:focus-within {
    --tw-ring-offset-color: #E040FB
  }

  .lg\:focus-within\:ring-offset-purple-A400:focus-within {
    --tw-ring-offset-color: #D500F9
  }

  .lg\:focus-within\:ring-offset-purple-A700:focus-within {
    --tw-ring-offset-color: #AA00FF
  }

  .lg\:focus-within\:ring-offset-deep-purple-50:focus-within {
    --tw-ring-offset-color: #EDE7F6
  }

  .lg\:focus-within\:ring-offset-deep-purple-100:focus-within {
    --tw-ring-offset-color: #D1C4E9
  }

  .lg\:focus-within\:ring-offset-deep-purple-200:focus-within {
    --tw-ring-offset-color: #B39DDB
  }

  .lg\:focus-within\:ring-offset-deep-purple-300:focus-within {
    --tw-ring-offset-color: #9575CD
  }

  .lg\:focus-within\:ring-offset-deep-purple-400:focus-within {
    --tw-ring-offset-color: #7E57C2
  }

  .lg\:focus-within\:ring-offset-deep-purple-500:focus-within {
    --tw-ring-offset-color: #673AB7
  }

  .lg\:focus-within\:ring-offset-deep-purple-600:focus-within {
    --tw-ring-offset-color: #5E35B1
  }

  .lg\:focus-within\:ring-offset-deep-purple-700:focus-within {
    --tw-ring-offset-color: #512DA8
  }

  .lg\:focus-within\:ring-offset-deep-purple-800:focus-within {
    --tw-ring-offset-color: #4527A0
  }

  .lg\:focus-within\:ring-offset-deep-purple-900:focus-within {
    --tw-ring-offset-color: #311B92
  }

  .lg\:focus-within\:ring-offset-deep-purple:focus-within {
    --tw-ring-offset-color: #673AB7
  }

  .lg\:focus-within\:ring-offset-deep-purple-A100:focus-within {
    --tw-ring-offset-color: #B388FF
  }

  .lg\:focus-within\:ring-offset-deep-purple-A200:focus-within {
    --tw-ring-offset-color: #7C4DFF
  }

  .lg\:focus-within\:ring-offset-deep-purple-A400:focus-within {
    --tw-ring-offset-color: #651FFF
  }

  .lg\:focus-within\:ring-offset-deep-purple-A700:focus-within {
    --tw-ring-offset-color: #6200EA
  }

  .lg\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #FCE4EC
  }

  .lg\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #F8BBD0
  }

  .lg\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #F48FB1
  }

  .lg\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #F06292
  }

  .lg\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #EC407A
  }

  .lg\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #E91E63
  }

  .lg\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #D81B60
  }

  .lg\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #C2185B
  }

  .lg\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #AD1457
  }

  .lg\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #880E4F
  }

  .lg\:focus-within\:ring-offset-pink:focus-within {
    --tw-ring-offset-color: #E91E63
  }

  .lg\:focus-within\:ring-offset-pink-A100:focus-within {
    --tw-ring-offset-color: #FF80AB
  }

  .lg\:focus-within\:ring-offset-pink-A200:focus-within {
    --tw-ring-offset-color: #FF4081
  }

  .lg\:focus-within\:ring-offset-pink-A400:focus-within {
    --tw-ring-offset-color: #F50057
  }

  .lg\:focus-within\:ring-offset-pink-A700:focus-within {
    --tw-ring-offset-color: #C51162
  }

  .lg\:focus-within\:ring-offset-lime-50:focus-within {
    --tw-ring-offset-color: #F9FBE7
  }

  .lg\:focus-within\:ring-offset-lime-100:focus-within {
    --tw-ring-offset-color: #F0F4C3
  }

  .lg\:focus-within\:ring-offset-lime-200:focus-within {
    --tw-ring-offset-color: #E6EE9C
  }

  .lg\:focus-within\:ring-offset-lime-300:focus-within {
    --tw-ring-offset-color: #DCE775
  }

  .lg\:focus-within\:ring-offset-lime-400:focus-within {
    --tw-ring-offset-color: #D4E157
  }

  .lg\:focus-within\:ring-offset-lime-500:focus-within {
    --tw-ring-offset-color: #CDDC39
  }

  .lg\:focus-within\:ring-offset-lime-600:focus-within {
    --tw-ring-offset-color: #C0CA33
  }

  .lg\:focus-within\:ring-offset-lime-700:focus-within {
    --tw-ring-offset-color: #AFB42B
  }

  .lg\:focus-within\:ring-offset-lime-800:focus-within {
    --tw-ring-offset-color: #9E9D24
  }

  .lg\:focus-within\:ring-offset-lime-900:focus-within {
    --tw-ring-offset-color: #827717
  }

  .lg\:focus-within\:ring-offset-lime:focus-within {
    --tw-ring-offset-color: #CDDC39
  }

  .lg\:focus-within\:ring-offset-lime-A100:focus-within {
    --tw-ring-offset-color: #F4FF81
  }

  .lg\:focus-within\:ring-offset-lime-A200:focus-within {
    --tw-ring-offset-color: #EEFF41
  }

  .lg\:focus-within\:ring-offset-lime-A400:focus-within {
    --tw-ring-offset-color: #C6FF00
  }

  .lg\:focus-within\:ring-offset-lime-A700:focus-within {
    --tw-ring-offset-color: #AEEA00
  }

  .lg\:focus-within\:ring-offset-amber-50:focus-within {
    --tw-ring-offset-color: #FFF8E1
  }

  .lg\:focus-within\:ring-offset-amber-100:focus-within {
    --tw-ring-offset-color: #FFECB3
  }

  .lg\:focus-within\:ring-offset-amber-200:focus-within {
    --tw-ring-offset-color: #FFE082
  }

  .lg\:focus-within\:ring-offset-amber-300:focus-within {
    --tw-ring-offset-color: #FFD54F
  }

  .lg\:focus-within\:ring-offset-amber-400:focus-within {
    --tw-ring-offset-color: #FFCA28
  }

  .lg\:focus-within\:ring-offset-amber-500:focus-within {
    --tw-ring-offset-color: #FFC107
  }

  .lg\:focus-within\:ring-offset-amber-600:focus-within {
    --tw-ring-offset-color: #FFB300
  }

  .lg\:focus-within\:ring-offset-amber-700:focus-within {
    --tw-ring-offset-color: #FFA000
  }

  .lg\:focus-within\:ring-offset-amber-800:focus-within {
    --tw-ring-offset-color: #FF8F00
  }

  .lg\:focus-within\:ring-offset-amber-900:focus-within {
    --tw-ring-offset-color: #FF6F00
  }

  .lg\:focus-within\:ring-offset-amber:focus-within {
    --tw-ring-offset-color: #FFC107
  }

  .lg\:focus-within\:ring-offset-amber-A100:focus-within {
    --tw-ring-offset-color: #FFE57F
  }

  .lg\:focus-within\:ring-offset-amber-A200:focus-within {
    --tw-ring-offset-color: #FFD740
  }

  .lg\:focus-within\:ring-offset-amber-A400:focus-within {
    --tw-ring-offset-color: #FFC400
  }

  .lg\:focus-within\:ring-offset-amber-A700:focus-within {
    --tw-ring-offset-color: #FFAB00
  }

  .lg\:focus-within\:ring-offset-brown-50:focus-within {
    --tw-ring-offset-color: #EFEBE9
  }

  .lg\:focus-within\:ring-offset-brown-100:focus-within {
    --tw-ring-offset-color: #D7CCC8
  }

  .lg\:focus-within\:ring-offset-brown-200:focus-within {
    --tw-ring-offset-color: #BCAAA4
  }

  .lg\:focus-within\:ring-offset-brown-300:focus-within {
    --tw-ring-offset-color: #A1887F
  }

  .lg\:focus-within\:ring-offset-brown-400:focus-within {
    --tw-ring-offset-color: #8D6E63
  }

  .lg\:focus-within\:ring-offset-brown-500:focus-within {
    --tw-ring-offset-color: #795548
  }

  .lg\:focus-within\:ring-offset-brown-600:focus-within {
    --tw-ring-offset-color: #6D4C41
  }

  .lg\:focus-within\:ring-offset-brown-700:focus-within {
    --tw-ring-offset-color: #5D4037
  }

  .lg\:focus-within\:ring-offset-brown-800:focus-within {
    --tw-ring-offset-color: #4E342E
  }

  .lg\:focus-within\:ring-offset-brown-900:focus-within {
    --tw-ring-offset-color: #3E2723
  }

  .lg\:focus-within\:ring-offset-brown:focus-within {
    --tw-ring-offset-color: #795548
  }

  .lg\:focus-within\:ring-offset-brown-A100:focus-within {
    --tw-ring-offset-color: #D7CCC8
  }

  .lg\:focus-within\:ring-offset-brown-A200:focus-within {
    --tw-ring-offset-color: #BCAAA4
  }

  .lg\:focus-within\:ring-offset-brown-A400:focus-within {
    --tw-ring-offset-color: #8D6E63
  }

  .lg\:focus-within\:ring-offset-brown-A700:focus-within {
    --tw-ring-offset-color: #5D4037
  }

  .lg\:focus-within\:ring-offset-blue-gray-50:focus-within {
    --tw-ring-offset-color: #ECEFF1
  }

  .lg\:focus-within\:ring-offset-blue-gray-100:focus-within {
    --tw-ring-offset-color: #CFD8DC
  }

  .lg\:focus-within\:ring-offset-blue-gray-200:focus-within {
    --tw-ring-offset-color: #B0BEC5
  }

  .lg\:focus-within\:ring-offset-blue-gray-300:focus-within {
    --tw-ring-offset-color: #90A4AE
  }

  .lg\:focus-within\:ring-offset-blue-gray-400:focus-within {
    --tw-ring-offset-color: #78909C
  }

  .lg\:focus-within\:ring-offset-blue-gray-500:focus-within {
    --tw-ring-offset-color: #607D8B
  }

  .lg\:focus-within\:ring-offset-blue-gray-600:focus-within {
    --tw-ring-offset-color: #546E7A
  }

  .lg\:focus-within\:ring-offset-blue-gray-700:focus-within {
    --tw-ring-offset-color: #455A64
  }

  .lg\:focus-within\:ring-offset-blue-gray-800:focus-within {
    --tw-ring-offset-color: #37474F
  }

  .lg\:focus-within\:ring-offset-blue-gray-900:focus-within {
    --tw-ring-offset-color: #263238
  }

  .lg\:focus-within\:ring-offset-blue-gray:focus-within {
    --tw-ring-offset-color: #607D8B
  }

  .lg\:focus-within\:ring-offset-blue-gray-A100:focus-within {
    --tw-ring-offset-color: #CFD8DC
  }

  .lg\:focus-within\:ring-offset-blue-gray-A200:focus-within {
    --tw-ring-offset-color: #B0BEC5
  }

  .lg\:focus-within\:ring-offset-blue-gray-A400:focus-within {
    --tw-ring-offset-color: #78909C
  }

  .lg\:focus-within\:ring-offset-blue-gray-A700:focus-within {
    --tw-ring-offset-color: #455A64
  }

  .lg\:focus-within\:ring-offset-cyan-50:focus-within {
    --tw-ring-offset-color: #E0F7FA
  }

  .lg\:focus-within\:ring-offset-cyan-100:focus-within {
    --tw-ring-offset-color: #B2EBF2
  }

  .lg\:focus-within\:ring-offset-cyan-200:focus-within {
    --tw-ring-offset-color: #80DEEA
  }

  .lg\:focus-within\:ring-offset-cyan-300:focus-within {
    --tw-ring-offset-color: #4DD0E1
  }

  .lg\:focus-within\:ring-offset-cyan-400:focus-within {
    --tw-ring-offset-color: #26C6DA
  }

  .lg\:focus-within\:ring-offset-cyan-500:focus-within {
    --tw-ring-offset-color: #00BCD4
  }

  .lg\:focus-within\:ring-offset-cyan-600:focus-within {
    --tw-ring-offset-color: #00ACC1
  }

  .lg\:focus-within\:ring-offset-cyan-700:focus-within {
    --tw-ring-offset-color: #0097A7
  }

  .lg\:focus-within\:ring-offset-cyan-800:focus-within {
    --tw-ring-offset-color: #00838F
  }

  .lg\:focus-within\:ring-offset-cyan-900:focus-within {
    --tw-ring-offset-color: #006064
  }

  .lg\:focus-within\:ring-offset-cyan:focus-within {
    --tw-ring-offset-color: #00BCD4
  }

  .lg\:focus-within\:ring-offset-cyan-A100:focus-within {
    --tw-ring-offset-color: #84FFFF
  }

  .lg\:focus-within\:ring-offset-cyan-A200:focus-within {
    --tw-ring-offset-color: #18FFFF
  }

  .lg\:focus-within\:ring-offset-cyan-A400:focus-within {
    --tw-ring-offset-color: #00E5FF
  }

  .lg\:focus-within\:ring-offset-cyan-A700:focus-within {
    --tw-ring-offset-color: #00B8D4
  }

  .lg\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent
  }

  .lg\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor
  }

  .lg\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #22292F
  }

  .lg\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff
  }

  .lg\:focus\:ring-offset-grey-50:focus {
    --tw-ring-offset-color: #FAFAFA
  }

  .lg\:focus\:ring-offset-grey-100:focus {
    --tw-ring-offset-color: #F5F5F5
  }

  .lg\:focus\:ring-offset-grey-200:focus {
    --tw-ring-offset-color: #EEEEEE
  }

  .lg\:focus\:ring-offset-grey-300:focus {
    --tw-ring-offset-color: #E0E0E0
  }

  .lg\:focus\:ring-offset-grey-400:focus {
    --tw-ring-offset-color: #BDBDBD
  }

  .lg\:focus\:ring-offset-grey-500:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .lg\:focus\:ring-offset-grey-600:focus {
    --tw-ring-offset-color: #757575
  }

  .lg\:focus\:ring-offset-grey-700:focus {
    --tw-ring-offset-color: #616161
  }

  .lg\:focus\:ring-offset-grey-800:focus {
    --tw-ring-offset-color: #424242
  }

  .lg\:focus\:ring-offset-grey-900:focus {
    --tw-ring-offset-color: #212121
  }

  .lg\:focus\:ring-offset-grey:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .lg\:focus\:ring-offset-grey-A100:focus {
    --tw-ring-offset-color: #D5D5D5
  }

  .lg\:focus\:ring-offset-grey-A200:focus {
    --tw-ring-offset-color: #AAAAAA
  }

  .lg\:focus\:ring-offset-grey-A400:focus {
    --tw-ring-offset-color: #303030
  }

  .lg\:focus\:ring-offset-grey-A700:focus {
    --tw-ring-offset-color: #616161
  }

  .lg\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #FAFAFA
  }

  .lg\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #F5F5F5
  }

  .lg\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #EEEEEE
  }

  .lg\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #E0E0E0
  }

  .lg\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #BDBDBD
  }

  .lg\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .lg\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #757575
  }

  .lg\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #616161
  }

  .lg\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #424242
  }

  .lg\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #212121
  }

  .lg\:focus\:ring-offset-gray:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .lg\:focus\:ring-offset-gray-hover:focus {
    --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
  }

  .lg\:focus\:ring-offset-gray-A100:focus {
    --tw-ring-offset-color: #D5D5D5
  }

  .lg\:focus\:ring-offset-gray-A200:focus {
    --tw-ring-offset-color: #AAAAAA
  }

  .lg\:focus\:ring-offset-gray-A400:focus {
    --tw-ring-offset-color: #303030
  }

  .lg\:focus\:ring-offset-gray-A700:focus {
    --tw-ring-offset-color: #616161
  }

  .lg\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #FFEBEE
  }

  .lg\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #FFCDD2
  }

  .lg\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #EF9A9A
  }

  .lg\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #E57373
  }

  .lg\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #EF5350
  }

  .lg\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #F44336
  }

  .lg\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #E53935
  }

  .lg\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #D32F2F
  }

  .lg\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #C62828
  }

  .lg\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #B71C1C
  }

  .lg\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: #F44336
  }

  .lg\:focus\:ring-offset-red-A100:focus {
    --tw-ring-offset-color: #FF8A80
  }

  .lg\:focus\:ring-offset-red-A200:focus {
    --tw-ring-offset-color: #FF5252
  }

  .lg\:focus\:ring-offset-red-A400:focus {
    --tw-ring-offset-color: #FF1744
  }

  .lg\:focus\:ring-offset-red-A700:focus {
    --tw-ring-offset-color: #D50000
  }

  .lg\:focus\:ring-offset-orange-50:focus {
    --tw-ring-offset-color: #FFF3E0
  }

  .lg\:focus\:ring-offset-orange-100:focus {
    --tw-ring-offset-color: #FFE0B2
  }

  .lg\:focus\:ring-offset-orange-200:focus {
    --tw-ring-offset-color: #FFCC80
  }

  .lg\:focus\:ring-offset-orange-300:focus {
    --tw-ring-offset-color: #FFB74D
  }

  .lg\:focus\:ring-offset-orange-400:focus {
    --tw-ring-offset-color: #FFA726
  }

  .lg\:focus\:ring-offset-orange-500:focus {
    --tw-ring-offset-color: #FF9800
  }

  .lg\:focus\:ring-offset-orange-600:focus {
    --tw-ring-offset-color: #FB8C00
  }

  .lg\:focus\:ring-offset-orange-700:focus {
    --tw-ring-offset-color: #F57C00
  }

  .lg\:focus\:ring-offset-orange-800:focus {
    --tw-ring-offset-color: #EF6C00
  }

  .lg\:focus\:ring-offset-orange-900:focus {
    --tw-ring-offset-color: #E65100
  }

  .lg\:focus\:ring-offset-orange:focus {
    --tw-ring-offset-color: #FF9800
  }

  .lg\:focus\:ring-offset-orange-A100:focus {
    --tw-ring-offset-color: #FFD180
  }

  .lg\:focus\:ring-offset-orange-A200:focus {
    --tw-ring-offset-color: #FFAB40
  }

  .lg\:focus\:ring-offset-orange-A400:focus {
    --tw-ring-offset-color: #FF9100
  }

  .lg\:focus\:ring-offset-orange-A700:focus {
    --tw-ring-offset-color: #FF6D00
  }

  .lg\:focus\:ring-offset-deep-orange-50:focus {
    --tw-ring-offset-color: #FBE9E7
  }

  .lg\:focus\:ring-offset-deep-orange-100:focus {
    --tw-ring-offset-color: #FFCCBC
  }

  .lg\:focus\:ring-offset-deep-orange-200:focus {
    --tw-ring-offset-color: #FFAB91
  }

  .lg\:focus\:ring-offset-deep-orange-300:focus {
    --tw-ring-offset-color: #FF8A65
  }

  .lg\:focus\:ring-offset-deep-orange-400:focus {
    --tw-ring-offset-color: #FF7043
  }

  .lg\:focus\:ring-offset-deep-orange-500:focus {
    --tw-ring-offset-color: #FF5722
  }

  .lg\:focus\:ring-offset-deep-orange-600:focus {
    --tw-ring-offset-color: #F4511E
  }

  .lg\:focus\:ring-offset-deep-orange-700:focus {
    --tw-ring-offset-color: #E64A19
  }

  .lg\:focus\:ring-offset-deep-orange-800:focus {
    --tw-ring-offset-color: #D84315
  }

  .lg\:focus\:ring-offset-deep-orange-900:focus {
    --tw-ring-offset-color: #BF360C
  }

  .lg\:focus\:ring-offset-deep-orange:focus {
    --tw-ring-offset-color: #FF5722
  }

  .lg\:focus\:ring-offset-deep-orange-A100:focus {
    --tw-ring-offset-color: #FF9E80
  }

  .lg\:focus\:ring-offset-deep-orange-A200:focus {
    --tw-ring-offset-color: #FF6E40
  }

  .lg\:focus\:ring-offset-deep-orange-A400:focus {
    --tw-ring-offset-color: #FF3D00
  }

  .lg\:focus\:ring-offset-deep-orange-A700:focus {
    --tw-ring-offset-color: #DD2C00
  }

  .lg\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #FFFDE7
  }

  .lg\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #FFF9C4
  }

  .lg\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #FFF59D
  }

  .lg\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #FFF176
  }

  .lg\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #FFEE58
  }

  .lg\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #FFEB3B
  }

  .lg\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #FDD835
  }

  .lg\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #FBC02D
  }

  .lg\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #F9A825
  }

  .lg\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #F57F17
  }

  .lg\:focus\:ring-offset-yellow:focus {
    --tw-ring-offset-color: #FFEB3B
  }

  .lg\:focus\:ring-offset-yellow-A100:focus {
    --tw-ring-offset-color: #FFFF8D
  }

  .lg\:focus\:ring-offset-yellow-A200:focus {
    --tw-ring-offset-color: #FFFF00
  }

  .lg\:focus\:ring-offset-yellow-A400:focus {
    --tw-ring-offset-color: #FFEA00
  }

  .lg\:focus\:ring-offset-yellow-A700:focus {
    --tw-ring-offset-color: #FFD600
  }

  .lg\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #E8F5E9
  }

  .lg\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #C8E6C9
  }

  .lg\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #A5D6A7
  }

  .lg\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #81C784
  }

  .lg\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #66BB6A
  }

  .lg\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #4CAF50
  }

  .lg\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #43A047
  }

  .lg\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #388E3C
  }

  .lg\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #2E7D32
  }

  .lg\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #1B5E20
  }

  .lg\:focus\:ring-offset-green:focus {
    --tw-ring-offset-color: #4CAF50
  }

  .lg\:focus\:ring-offset-green-A100:focus {
    --tw-ring-offset-color: #B9F6CA
  }

  .lg\:focus\:ring-offset-green-A200:focus {
    --tw-ring-offset-color: #69F0AE
  }

  .lg\:focus\:ring-offset-green-A400:focus {
    --tw-ring-offset-color: #00E676
  }

  .lg\:focus\:ring-offset-green-A700:focus {
    --tw-ring-offset-color: #00C853
  }

  .lg\:focus\:ring-offset-light-green-50:focus {
    --tw-ring-offset-color: #F1F8E9
  }

  .lg\:focus\:ring-offset-light-green-100:focus {
    --tw-ring-offset-color: #DCEDC8
  }

  .lg\:focus\:ring-offset-light-green-200:focus {
    --tw-ring-offset-color: #C5E1A5
  }

  .lg\:focus\:ring-offset-light-green-300:focus {
    --tw-ring-offset-color: #AED581
  }

  .lg\:focus\:ring-offset-light-green-400:focus {
    --tw-ring-offset-color: #9CCC65
  }

  .lg\:focus\:ring-offset-light-green-500:focus {
    --tw-ring-offset-color: #8BC34A
  }

  .lg\:focus\:ring-offset-light-green-600:focus {
    --tw-ring-offset-color: #7CB342
  }

  .lg\:focus\:ring-offset-light-green-700:focus {
    --tw-ring-offset-color: #689F38
  }

  .lg\:focus\:ring-offset-light-green-800:focus {
    --tw-ring-offset-color: #558B2F
  }

  .lg\:focus\:ring-offset-light-green-900:focus {
    --tw-ring-offset-color: #33691E
  }

  .lg\:focus\:ring-offset-light-green:focus {
    --tw-ring-offset-color: #8BC34A
  }

  .lg\:focus\:ring-offset-light-green-A100:focus {
    --tw-ring-offset-color: #CCFF90
  }

  .lg\:focus\:ring-offset-light-green-A200:focus {
    --tw-ring-offset-color: #B2FF59
  }

  .lg\:focus\:ring-offset-light-green-A400:focus {
    --tw-ring-offset-color: #76FF03
  }

  .lg\:focus\:ring-offset-light-green-A700:focus {
    --tw-ring-offset-color: #64DD17
  }

  .lg\:focus\:ring-offset-teal-50:focus {
    --tw-ring-offset-color: #E0F2F1
  }

  .lg\:focus\:ring-offset-teal-100:focus {
    --tw-ring-offset-color: #B2DFDB
  }

  .lg\:focus\:ring-offset-teal-200:focus {
    --tw-ring-offset-color: #80CBC4
  }

  .lg\:focus\:ring-offset-teal-300:focus {
    --tw-ring-offset-color: #4DB6AC
  }

  .lg\:focus\:ring-offset-teal-400:focus {
    --tw-ring-offset-color: #26A69A
  }

  .lg\:focus\:ring-offset-teal-500:focus {
    --tw-ring-offset-color: #009688
  }

  .lg\:focus\:ring-offset-teal-600:focus {
    --tw-ring-offset-color: #00897B
  }

  .lg\:focus\:ring-offset-teal-700:focus {
    --tw-ring-offset-color: #00796B
  }

  .lg\:focus\:ring-offset-teal-800:focus {
    --tw-ring-offset-color: #00695C
  }

  .lg\:focus\:ring-offset-teal-900:focus {
    --tw-ring-offset-color: #004D40
  }

  .lg\:focus\:ring-offset-teal:focus {
    --tw-ring-offset-color: #009688
  }

  .lg\:focus\:ring-offset-teal-A100:focus {
    --tw-ring-offset-color: #A7FFEB
  }

  .lg\:focus\:ring-offset-teal-A200:focus {
    --tw-ring-offset-color: #64FFDA
  }

  .lg\:focus\:ring-offset-teal-A400:focus {
    --tw-ring-offset-color: #1DE9B6
  }

  .lg\:focus\:ring-offset-teal-A700:focus {
    --tw-ring-offset-color: #00BFA5
  }

  .lg\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #E3F2FD
  }

  .lg\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #BBDEFB
  }

  .lg\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #90CAF9
  }

  .lg\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #64B5F6
  }

  .lg\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #42A5F5
  }

  .lg\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #2196F3
  }

  .lg\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #1E88E5
  }

  .lg\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1976D2
  }

  .lg\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1565C0
  }

  .lg\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #0D47A1
  }

  .lg\:focus\:ring-offset-blue:focus {
    --tw-ring-offset-color: #2196F3
  }

  .lg\:focus\:ring-offset-blue-A100:focus {
    --tw-ring-offset-color: #82B1FF
  }

  .lg\:focus\:ring-offset-blue-A200:focus {
    --tw-ring-offset-color: #448AFF
  }

  .lg\:focus\:ring-offset-blue-A400:focus {
    --tw-ring-offset-color: #2979FF
  }

  .lg\:focus\:ring-offset-blue-A700:focus {
    --tw-ring-offset-color: #2962FF
  }

  .lg\:focus\:ring-offset-light-blue-50:focus {
    --tw-ring-offset-color: #E1F5FE
  }

  .lg\:focus\:ring-offset-light-blue-100:focus {
    --tw-ring-offset-color: #B3E5FC
  }

  .lg\:focus\:ring-offset-light-blue-200:focus {
    --tw-ring-offset-color: #81D4FA
  }

  .lg\:focus\:ring-offset-light-blue-300:focus {
    --tw-ring-offset-color: #4FC3F7
  }

  .lg\:focus\:ring-offset-light-blue-400:focus {
    --tw-ring-offset-color: #29B6F6
  }

  .lg\:focus\:ring-offset-light-blue-500:focus {
    --tw-ring-offset-color: #03A9F4
  }

  .lg\:focus\:ring-offset-light-blue-600:focus {
    --tw-ring-offset-color: #039BE5
  }

  .lg\:focus\:ring-offset-light-blue-700:focus {
    --tw-ring-offset-color: #0288D1
  }

  .lg\:focus\:ring-offset-light-blue-800:focus {
    --tw-ring-offset-color: #0277BD
  }

  .lg\:focus\:ring-offset-light-blue-900:focus {
    --tw-ring-offset-color: #01579B
  }

  .lg\:focus\:ring-offset-light-blue:focus {
    --tw-ring-offset-color: #03A9F4
  }

  .lg\:focus\:ring-offset-light-blue-A100:focus {
    --tw-ring-offset-color: #80D8FF
  }

  .lg\:focus\:ring-offset-light-blue-A200:focus {
    --tw-ring-offset-color: #40C4FF
  }

  .lg\:focus\:ring-offset-light-blue-A400:focus {
    --tw-ring-offset-color: #00B0FF
  }

  .lg\:focus\:ring-offset-light-blue-A700:focus {
    --tw-ring-offset-color: #0091EA
  }

  .lg\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #E8EAF6
  }

  .lg\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #C5CAE9
  }

  .lg\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #9FA8DA
  }

  .lg\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #7986CB
  }

  .lg\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #5C6BC0
  }

  .lg\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #3F51B5
  }

  .lg\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #3949AB
  }

  .lg\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #303F9F
  }

  .lg\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #283593
  }

  .lg\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #1A237E
  }

  .lg\:focus\:ring-offset-indigo:focus {
    --tw-ring-offset-color: #3F51B5
  }

  .lg\:focus\:ring-offset-indigo-A100:focus {
    --tw-ring-offset-color: #8C9EFF
  }

  .lg\:focus\:ring-offset-indigo-A200:focus {
    --tw-ring-offset-color: #536DFE
  }

  .lg\:focus\:ring-offset-indigo-A400:focus {
    --tw-ring-offset-color: #3D5AFE
  }

  .lg\:focus\:ring-offset-indigo-A700:focus {
    --tw-ring-offset-color: #304FFE
  }

  .lg\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #F3E5F5
  }

  .lg\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #E1BEE7
  }

  .lg\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #CE93D8
  }

  .lg\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #BA68C8
  }

  .lg\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #AB47BC
  }

  .lg\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #9C27B0
  }

  .lg\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #8E24AA
  }

  .lg\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #7B1FA2
  }

  .lg\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #6A1B9A
  }

  .lg\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4A148C
  }

  .lg\:focus\:ring-offset-purple:focus {
    --tw-ring-offset-color: #9C27B0
  }

  .lg\:focus\:ring-offset-purple-A100:focus {
    --tw-ring-offset-color: #EA80FC
  }

  .lg\:focus\:ring-offset-purple-A200:focus {
    --tw-ring-offset-color: #E040FB
  }

  .lg\:focus\:ring-offset-purple-A400:focus {
    --tw-ring-offset-color: #D500F9
  }

  .lg\:focus\:ring-offset-purple-A700:focus {
    --tw-ring-offset-color: #AA00FF
  }

  .lg\:focus\:ring-offset-deep-purple-50:focus {
    --tw-ring-offset-color: #EDE7F6
  }

  .lg\:focus\:ring-offset-deep-purple-100:focus {
    --tw-ring-offset-color: #D1C4E9
  }

  .lg\:focus\:ring-offset-deep-purple-200:focus {
    --tw-ring-offset-color: #B39DDB
  }

  .lg\:focus\:ring-offset-deep-purple-300:focus {
    --tw-ring-offset-color: #9575CD
  }

  .lg\:focus\:ring-offset-deep-purple-400:focus {
    --tw-ring-offset-color: #7E57C2
  }

  .lg\:focus\:ring-offset-deep-purple-500:focus {
    --tw-ring-offset-color: #673AB7
  }

  .lg\:focus\:ring-offset-deep-purple-600:focus {
    --tw-ring-offset-color: #5E35B1
  }

  .lg\:focus\:ring-offset-deep-purple-700:focus {
    --tw-ring-offset-color: #512DA8
  }

  .lg\:focus\:ring-offset-deep-purple-800:focus {
    --tw-ring-offset-color: #4527A0
  }

  .lg\:focus\:ring-offset-deep-purple-900:focus {
    --tw-ring-offset-color: #311B92
  }

  .lg\:focus\:ring-offset-deep-purple:focus {
    --tw-ring-offset-color: #673AB7
  }

  .lg\:focus\:ring-offset-deep-purple-A100:focus {
    --tw-ring-offset-color: #B388FF
  }

  .lg\:focus\:ring-offset-deep-purple-A200:focus {
    --tw-ring-offset-color: #7C4DFF
  }

  .lg\:focus\:ring-offset-deep-purple-A400:focus {
    --tw-ring-offset-color: #651FFF
  }

  .lg\:focus\:ring-offset-deep-purple-A700:focus {
    --tw-ring-offset-color: #6200EA
  }

  .lg\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #FCE4EC
  }

  .lg\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #F8BBD0
  }

  .lg\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #F48FB1
  }

  .lg\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #F06292
  }

  .lg\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #EC407A
  }

  .lg\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #E91E63
  }

  .lg\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #D81B60
  }

  .lg\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #C2185B
  }

  .lg\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #AD1457
  }

  .lg\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #880E4F
  }

  .lg\:focus\:ring-offset-pink:focus {
    --tw-ring-offset-color: #E91E63
  }

  .lg\:focus\:ring-offset-pink-A100:focus {
    --tw-ring-offset-color: #FF80AB
  }

  .lg\:focus\:ring-offset-pink-A200:focus {
    --tw-ring-offset-color: #FF4081
  }

  .lg\:focus\:ring-offset-pink-A400:focus {
    --tw-ring-offset-color: #F50057
  }

  .lg\:focus\:ring-offset-pink-A700:focus {
    --tw-ring-offset-color: #C51162
  }

  .lg\:focus\:ring-offset-lime-50:focus {
    --tw-ring-offset-color: #F9FBE7
  }

  .lg\:focus\:ring-offset-lime-100:focus {
    --tw-ring-offset-color: #F0F4C3
  }

  .lg\:focus\:ring-offset-lime-200:focus {
    --tw-ring-offset-color: #E6EE9C
  }

  .lg\:focus\:ring-offset-lime-300:focus {
    --tw-ring-offset-color: #DCE775
  }

  .lg\:focus\:ring-offset-lime-400:focus {
    --tw-ring-offset-color: #D4E157
  }

  .lg\:focus\:ring-offset-lime-500:focus {
    --tw-ring-offset-color: #CDDC39
  }

  .lg\:focus\:ring-offset-lime-600:focus {
    --tw-ring-offset-color: #C0CA33
  }

  .lg\:focus\:ring-offset-lime-700:focus {
    --tw-ring-offset-color: #AFB42B
  }

  .lg\:focus\:ring-offset-lime-800:focus {
    --tw-ring-offset-color: #9E9D24
  }

  .lg\:focus\:ring-offset-lime-900:focus {
    --tw-ring-offset-color: #827717
  }

  .lg\:focus\:ring-offset-lime:focus {
    --tw-ring-offset-color: #CDDC39
  }

  .lg\:focus\:ring-offset-lime-A100:focus {
    --tw-ring-offset-color: #F4FF81
  }

  .lg\:focus\:ring-offset-lime-A200:focus {
    --tw-ring-offset-color: #EEFF41
  }

  .lg\:focus\:ring-offset-lime-A400:focus {
    --tw-ring-offset-color: #C6FF00
  }

  .lg\:focus\:ring-offset-lime-A700:focus {
    --tw-ring-offset-color: #AEEA00
  }

  .lg\:focus\:ring-offset-amber-50:focus {
    --tw-ring-offset-color: #FFF8E1
  }

  .lg\:focus\:ring-offset-amber-100:focus {
    --tw-ring-offset-color: #FFECB3
  }

  .lg\:focus\:ring-offset-amber-200:focus {
    --tw-ring-offset-color: #FFE082
  }

  .lg\:focus\:ring-offset-amber-300:focus {
    --tw-ring-offset-color: #FFD54F
  }

  .lg\:focus\:ring-offset-amber-400:focus {
    --tw-ring-offset-color: #FFCA28
  }

  .lg\:focus\:ring-offset-amber-500:focus {
    --tw-ring-offset-color: #FFC107
  }

  .lg\:focus\:ring-offset-amber-600:focus {
    --tw-ring-offset-color: #FFB300
  }

  .lg\:focus\:ring-offset-amber-700:focus {
    --tw-ring-offset-color: #FFA000
  }

  .lg\:focus\:ring-offset-amber-800:focus {
    --tw-ring-offset-color: #FF8F00
  }

  .lg\:focus\:ring-offset-amber-900:focus {
    --tw-ring-offset-color: #FF6F00
  }

  .lg\:focus\:ring-offset-amber:focus {
    --tw-ring-offset-color: #FFC107
  }

  .lg\:focus\:ring-offset-amber-A100:focus {
    --tw-ring-offset-color: #FFE57F
  }

  .lg\:focus\:ring-offset-amber-A200:focus {
    --tw-ring-offset-color: #FFD740
  }

  .lg\:focus\:ring-offset-amber-A400:focus {
    --tw-ring-offset-color: #FFC400
  }

  .lg\:focus\:ring-offset-amber-A700:focus {
    --tw-ring-offset-color: #FFAB00
  }

  .lg\:focus\:ring-offset-brown-50:focus {
    --tw-ring-offset-color: #EFEBE9
  }

  .lg\:focus\:ring-offset-brown-100:focus {
    --tw-ring-offset-color: #D7CCC8
  }

  .lg\:focus\:ring-offset-brown-200:focus {
    --tw-ring-offset-color: #BCAAA4
  }

  .lg\:focus\:ring-offset-brown-300:focus {
    --tw-ring-offset-color: #A1887F
  }

  .lg\:focus\:ring-offset-brown-400:focus {
    --tw-ring-offset-color: #8D6E63
  }

  .lg\:focus\:ring-offset-brown-500:focus {
    --tw-ring-offset-color: #795548
  }

  .lg\:focus\:ring-offset-brown-600:focus {
    --tw-ring-offset-color: #6D4C41
  }

  .lg\:focus\:ring-offset-brown-700:focus {
    --tw-ring-offset-color: #5D4037
  }

  .lg\:focus\:ring-offset-brown-800:focus {
    --tw-ring-offset-color: #4E342E
  }

  .lg\:focus\:ring-offset-brown-900:focus {
    --tw-ring-offset-color: #3E2723
  }

  .lg\:focus\:ring-offset-brown:focus {
    --tw-ring-offset-color: #795548
  }

  .lg\:focus\:ring-offset-brown-A100:focus {
    --tw-ring-offset-color: #D7CCC8
  }

  .lg\:focus\:ring-offset-brown-A200:focus {
    --tw-ring-offset-color: #BCAAA4
  }

  .lg\:focus\:ring-offset-brown-A400:focus {
    --tw-ring-offset-color: #8D6E63
  }

  .lg\:focus\:ring-offset-brown-A700:focus {
    --tw-ring-offset-color: #5D4037
  }

  .lg\:focus\:ring-offset-blue-gray-50:focus {
    --tw-ring-offset-color: #ECEFF1
  }

  .lg\:focus\:ring-offset-blue-gray-100:focus {
    --tw-ring-offset-color: #CFD8DC
  }

  .lg\:focus\:ring-offset-blue-gray-200:focus {
    --tw-ring-offset-color: #B0BEC5
  }

  .lg\:focus\:ring-offset-blue-gray-300:focus {
    --tw-ring-offset-color: #90A4AE
  }

  .lg\:focus\:ring-offset-blue-gray-400:focus {
    --tw-ring-offset-color: #78909C
  }

  .lg\:focus\:ring-offset-blue-gray-500:focus {
    --tw-ring-offset-color: #607D8B
  }

  .lg\:focus\:ring-offset-blue-gray-600:focus {
    --tw-ring-offset-color: #546E7A
  }

  .lg\:focus\:ring-offset-blue-gray-700:focus {
    --tw-ring-offset-color: #455A64
  }

  .lg\:focus\:ring-offset-blue-gray-800:focus {
    --tw-ring-offset-color: #37474F
  }

  .lg\:focus\:ring-offset-blue-gray-900:focus {
    --tw-ring-offset-color: #263238
  }

  .lg\:focus\:ring-offset-blue-gray:focus {
    --tw-ring-offset-color: #607D8B
  }

  .lg\:focus\:ring-offset-blue-gray-A100:focus {
    --tw-ring-offset-color: #CFD8DC
  }

  .lg\:focus\:ring-offset-blue-gray-A200:focus {
    --tw-ring-offset-color: #B0BEC5
  }

  .lg\:focus\:ring-offset-blue-gray-A400:focus {
    --tw-ring-offset-color: #78909C
  }

  .lg\:focus\:ring-offset-blue-gray-A700:focus {
    --tw-ring-offset-color: #455A64
  }

  .lg\:focus\:ring-offset-cyan-50:focus {
    --tw-ring-offset-color: #E0F7FA
  }

  .lg\:focus\:ring-offset-cyan-100:focus {
    --tw-ring-offset-color: #B2EBF2
  }

  .lg\:focus\:ring-offset-cyan-200:focus {
    --tw-ring-offset-color: #80DEEA
  }

  .lg\:focus\:ring-offset-cyan-300:focus {
    --tw-ring-offset-color: #4DD0E1
  }

  .lg\:focus\:ring-offset-cyan-400:focus {
    --tw-ring-offset-color: #26C6DA
  }

  .lg\:focus\:ring-offset-cyan-500:focus {
    --tw-ring-offset-color: #00BCD4
  }

  .lg\:focus\:ring-offset-cyan-600:focus {
    --tw-ring-offset-color: #00ACC1
  }

  .lg\:focus\:ring-offset-cyan-700:focus {
    --tw-ring-offset-color: #0097A7
  }

  .lg\:focus\:ring-offset-cyan-800:focus {
    --tw-ring-offset-color: #00838F
  }

  .lg\:focus\:ring-offset-cyan-900:focus {
    --tw-ring-offset-color: #006064
  }

  .lg\:focus\:ring-offset-cyan:focus {
    --tw-ring-offset-color: #00BCD4
  }

  .lg\:focus\:ring-offset-cyan-A100:focus {
    --tw-ring-offset-color: #84FFFF
  }

  .lg\:focus\:ring-offset-cyan-A200:focus {
    --tw-ring-offset-color: #18FFFF
  }

  .lg\:focus\:ring-offset-cyan-A400:focus {
    --tw-ring-offset-color: #00E5FF
  }

  .lg\:focus\:ring-offset-cyan-A700:focus {
    --tw-ring-offset-color: #00B8D4
  }

  .lg\:ring-offset-0 {
    --tw-ring-offset-width: 0px
  }

  .lg\:ring-offset-1 {
    --tw-ring-offset-width: 1px
  }

  .lg\:ring-offset-2 {
    --tw-ring-offset-width: 2px
  }

  .lg\:ring-offset-4 {
    --tw-ring-offset-width: 4px
  }

  .lg\:ring-offset-8 {
    --tw-ring-offset-width: 8px
  }

  .lg\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px
  }

  .lg\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px
  }

  .lg\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px
  }

  .lg\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px
  }

  .lg\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px
  }

  .lg\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px
  }

  .lg\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px
  }

  .lg\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px
  }

  .lg\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px
  }

  .lg\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px
  }

  .lg\:ring-transparent {
    --tw-ring-color: transparent
  }

  .lg\:ring-current {
    --tw-ring-color: currentColor
  }

  .lg\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .lg\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-grey-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .lg\:ring-grey-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .lg\:ring-grey-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .lg\:ring-grey-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .lg\:ring-grey-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .lg\:ring-grey-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .lg\:ring-grey-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .lg\:ring-grey-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .lg\:ring-grey-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .lg\:ring-grey-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .lg\:ring-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .lg\:ring-grey-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .lg\:ring-grey-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .lg\:ring-grey-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .lg\:ring-grey-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .lg\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .lg\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .lg\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .lg\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .lg\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .lg\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .lg\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .lg\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .lg\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .lg\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .lg\:ring-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .lg\:ring-gray-hover {
    --tw-ring-color: rgba(0, 0, 0, 0.04)
  }

  .lg\:ring-gray-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .lg\:ring-gray-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .lg\:ring-gray-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .lg\:ring-gray-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .lg\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .lg\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .lg\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .lg\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .lg\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .lg\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .lg\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .lg\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .lg\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .lg\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .lg\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .lg\:ring-red-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .lg\:ring-red-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .lg\:ring-red-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .lg\:ring-red-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-orange-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .lg\:ring-orange-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .lg\:ring-orange-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .lg\:ring-orange-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .lg\:ring-orange-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .lg\:ring-orange-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-orange-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-orange-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-orange-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-orange-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-orange {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-orange-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .lg\:ring-orange-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .lg\:ring-orange-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-orange-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-orange-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-orange-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-orange-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-orange-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-orange-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-orange-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-orange-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-orange-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-orange-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-orange-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-orange {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-orange-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-orange-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-orange-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-orange-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .lg\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .lg\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .lg\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .lg\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .lg\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .lg\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .lg\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .lg\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .lg\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .lg\:ring-yellow {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .lg\:ring-yellow-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .lg\:ring-yellow-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-yellow-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-yellow-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .lg\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .lg\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .lg\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .lg\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .lg\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .lg\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .lg\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .lg\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .lg\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .lg\:ring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .lg\:ring-green-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .lg\:ring-green-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .lg\:ring-green-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .lg\:ring-green-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .lg\:ring-light-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .lg\:ring-light-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .lg\:ring-light-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .lg\:ring-light-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .lg\:ring-light-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .lg\:ring-light-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .lg\:ring-light-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .lg\:ring-light-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .lg\:ring-light-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .lg\:ring-light-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .lg\:ring-light-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .lg\:ring-light-green-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .lg\:ring-light-green-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .lg\:ring-light-green-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .lg\:ring-light-green-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .lg\:ring-teal-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .lg\:ring-teal-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .lg\:ring-teal-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .lg\:ring-teal-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .lg\:ring-teal-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .lg\:ring-teal-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .lg\:ring-teal-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .lg\:ring-teal-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .lg\:ring-teal-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .lg\:ring-teal-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .lg\:ring-teal {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .lg\:ring-teal-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .lg\:ring-teal-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .lg\:ring-teal-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .lg\:ring-teal-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .lg\:ring-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-light-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .lg\:ring-light-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .lg\:ring-light-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .lg\:ring-light-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .lg\:ring-light-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .lg\:ring-light-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .lg\:ring-light-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .lg\:ring-light-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .lg\:ring-light-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .lg\:ring-light-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .lg\:ring-light-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .lg\:ring-light-blue-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-light-blue-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-light-blue-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-light-blue-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .lg\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .lg\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .lg\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .lg\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .lg\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .lg\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .lg\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .lg\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .lg\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .lg\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .lg\:ring-indigo {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .lg\:ring-indigo-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-indigo-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .lg\:ring-indigo-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .lg\:ring-indigo-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .lg\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .lg\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .lg\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .lg\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .lg\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .lg\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .lg\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .lg\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .lg\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .lg\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .lg\:ring-purple {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .lg\:ring-purple-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .lg\:ring-purple-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .lg\:ring-purple-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .lg\:ring-purple-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-purple {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-purple-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-purple-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-purple-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-deep-purple-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .lg\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .lg\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .lg\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .lg\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .lg\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .lg\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .lg\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .lg\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .lg\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .lg\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .lg\:ring-pink {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .lg\:ring-pink-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .lg\:ring-pink-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .lg\:ring-pink-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .lg\:ring-pink-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .lg\:ring-lime-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .lg\:ring-lime-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .lg\:ring-lime-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .lg\:ring-lime-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .lg\:ring-lime-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .lg\:ring-lime-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .lg\:ring-lime-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .lg\:ring-lime-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .lg\:ring-lime-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .lg\:ring-lime-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .lg\:ring-lime {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .lg\:ring-lime-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .lg\:ring-lime-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .lg\:ring-lime-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-lime-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-amber-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .lg\:ring-amber-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .lg\:ring-amber-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .lg\:ring-amber-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .lg\:ring-amber-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .lg\:ring-amber-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .lg\:ring-amber-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-amber-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-amber-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-amber-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-amber {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .lg\:ring-amber-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .lg\:ring-amber-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .lg\:ring-amber-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-amber-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-brown-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .lg\:ring-brown-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .lg\:ring-brown-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .lg\:ring-brown-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .lg\:ring-brown-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .lg\:ring-brown-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .lg\:ring-brown-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .lg\:ring-brown-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .lg\:ring-brown-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .lg\:ring-brown-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .lg\:ring-brown {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .lg\:ring-brown-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .lg\:ring-brown-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .lg\:ring-brown-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .lg\:ring-brown-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-gray-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-gray-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-gray-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .lg\:ring-blue-gray-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent
  }

  .lg\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor
  }

  .lg\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-grey-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-grey-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-grey-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-grey-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-grey-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-grey-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-grey-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-grey-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-grey-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-grey-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-grey-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-grey-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-grey-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-grey-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray-hover:focus-within {
    --tw-ring-color: rgba(0, 0, 0, 0.04)
  }

  .lg\:focus-within\:ring-gray-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-orange-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-orange-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-orange-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-orange-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-orange-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-orange-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-orange-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-orange-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-orange-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-orange-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-orange:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-orange-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-orange-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-orange-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-orange-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-orange-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-orange-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-orange-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-orange-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-orange-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-orange-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-orange-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-orange-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-orange-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-orange-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-orange:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-orange-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-orange-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-orange-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-orange-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-yellow:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-yellow-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-yellow-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-yellow-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-yellow-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-green-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-green-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-green-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-green-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-teal-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-teal-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-teal-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-teal-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-teal-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-teal-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-teal-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-teal-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-teal-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-teal-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-teal:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-teal-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-teal-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-teal-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-teal-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-blue-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-blue-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-blue-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-light-blue-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-indigo:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-indigo-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-indigo-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-indigo-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-indigo-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-purple:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-purple-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-purple-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-purple-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-purple-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-purple:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-purple-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-purple-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-purple-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-deep-purple-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-pink:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-pink-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-pink-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-pink-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-pink-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-lime-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-lime-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-lime-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-lime-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-lime-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-lime-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-lime-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-lime-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-lime-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-lime-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-lime:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-lime-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-lime-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-lime-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-lime-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-amber-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-amber-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-amber-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-amber-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-amber-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-amber-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-amber-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-amber-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-amber-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-amber-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-amber:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-amber-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-amber-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-amber-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-amber-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-brown-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-brown-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-brown-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-brown-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-brown-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-brown-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-brown-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-brown-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-brown-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-brown-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-brown:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-brown-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-brown-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-brown-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-brown-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-gray-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-gray-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-gray-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-blue-gray-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent
  }

  .lg\:focus\:ring-current:focus {
    --tw-ring-color: currentColor
  }

  .lg\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-grey-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-grey-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-grey-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-grey-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-grey-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-grey-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-grey-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-grey-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-grey-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-grey-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-grey-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-grey-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-grey-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-grey-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray-hover:focus {
    --tw-ring-color: rgba(0, 0, 0, 0.04)
  }

  .lg\:focus\:ring-gray-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-orange-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-orange-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-orange-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-orange-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-orange-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-orange-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-orange-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-orange-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-orange-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-orange-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-orange:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-orange-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-orange-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-orange-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-orange-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-orange-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-orange-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-orange-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-orange-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-orange-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-orange-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-orange-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-orange-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-orange-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-orange-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-orange:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-orange-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-orange-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-orange-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-orange-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-yellow:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-yellow-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-yellow-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-yellow-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-yellow-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-green-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-green-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-green-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-green-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-teal-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-teal-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-teal-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-teal-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-teal-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-teal-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-teal-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-teal-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-teal-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-teal-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-teal:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-teal-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-teal-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-teal-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-teal-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-blue-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-blue-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-blue-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-light-blue-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-indigo:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-indigo-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-indigo-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-indigo-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-indigo-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-purple:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-purple-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-purple-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-purple-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-purple-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-purple:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-purple-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-purple-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-purple-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-deep-purple-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-pink:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-pink-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-pink-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-pink-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-pink-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-lime-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-lime-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-lime-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-lime-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-lime-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-lime-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-lime-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-lime-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-lime-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-lime-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-lime:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-lime-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-lime-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-lime-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-lime-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-amber-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-amber-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-amber-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-amber-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-amber-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-amber-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-amber-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-amber-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-amber-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-amber-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-amber:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-amber-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-amber-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-amber-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-amber-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-brown-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-brown-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-brown-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-brown-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-brown-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-brown-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-brown-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-brown-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-brown-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-brown-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-brown:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-brown-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-brown-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-brown-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-brown-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-gray-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-gray-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-gray-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-blue-gray-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .lg\:ring-opacity-0 {
    --tw-ring-opacity: 0
  }

  .lg\:ring-opacity-5 {
    --tw-ring-opacity: 0.05
  }

  .lg\:ring-opacity-10 {
    --tw-ring-opacity: 0.1
  }

  .lg\:ring-opacity-20 {
    --tw-ring-opacity: 0.2
  }

  .lg\:ring-opacity-25 {
    --tw-ring-opacity: 0.25
  }

  .lg\:ring-opacity-30 {
    --tw-ring-opacity: 0.3
  }

  .lg\:ring-opacity-40 {
    --tw-ring-opacity: 0.4
  }

  .lg\:ring-opacity-50 {
    --tw-ring-opacity: 0.5
  }

  .lg\:ring-opacity-60 {
    --tw-ring-opacity: 0.6
  }

  .lg\:ring-opacity-70 {
    --tw-ring-opacity: 0.7
  }

  .lg\:ring-opacity-75 {
    --tw-ring-opacity: 0.75
  }

  .lg\:ring-opacity-80 {
    --tw-ring-opacity: 0.8
  }

  .lg\:ring-opacity-90 {
    --tw-ring-opacity: 0.9
  }

  .lg\:ring-opacity-95 {
    --tw-ring-opacity: 0.95
  }

  .lg\:ring-opacity-100 {
    --tw-ring-opacity: 1
  }

  .lg\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0
  }

  .lg\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05
  }

  .lg\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1
  }

  .lg\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2
  }

  .lg\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25
  }

  .lg\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3
  }

  .lg\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4
  }

  .lg\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5
  }

  .lg\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6
  }

  .lg\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7
  }

  .lg\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75
  }

  .lg\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8
  }

  .lg\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9
  }

  .lg\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95
  }

  .lg\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1
  }

  .lg\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0
  }

  .lg\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05
  }

  .lg\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1
  }

  .lg\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2
  }

  .lg\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25
  }

  .lg\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3
  }

  .lg\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4
  }

  .lg\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5
  }

  .lg\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6
  }

  .lg\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7
  }

  .lg\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75
  }

  .lg\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8
  }

  .lg\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9
  }

  .lg\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95
  }

  .lg\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1
  }

  .lg\:fill-current {
    fill: currentColor
  }

  .lg\:stroke-current {
    stroke: currentColor
  }

  .lg\:stroke-0 {
    stroke-width: 0
  }

  .lg\:stroke-1 {
    stroke-width: 1
  }

  .lg\:stroke-2 {
    stroke-width: 2
  }

  .lg\:table-auto {
    table-layout: auto
  }

  .lg\:table-fixed {
    table-layout: fixed
  }

  .lg\:text-left {
    text-align: left
  }

  .lg\:text-center {
    text-align: center
  }

  .lg\:text-right {
    text-align: right
  }

  .lg\:text-justify {
    text-align: justify
  }

  [dir='ltr'] .lg\:ltr\:text-left,[dir='ltr'].lg\:ltr\:text-left {
    text-align: left
  }

  [dir='ltr'] .lg\:ltr\:text-center,[dir='ltr'].lg\:ltr\:text-center {
    text-align: center
  }

  [dir='ltr'] .lg\:ltr\:text-right,[dir='ltr'].lg\:ltr\:text-right {
    text-align: right
  }

  [dir='ltr'] .lg\:ltr\:text-justify,[dir='ltr'].lg\:ltr\:text-justify {
    text-align: justify
  }

  [dir='rtl'] .lg\:rtl\:text-left,[dir='rtl'].lg\:rtl\:text-left {
    text-align: left
  }

  [dir='rtl'] .lg\:rtl\:text-center,[dir='rtl'].lg\:rtl\:text-center {
    text-align: center
  }

  [dir='rtl'] .lg\:rtl\:text-right,[dir='rtl'].lg\:rtl\:text-right {
    text-align: right
  }

  [dir='rtl'] .lg\:rtl\:text-justify,[dir='rtl'].lg\:rtl\:text-justify {
    text-align: justify
  }

  .lg\:text-transparent {
    color: transparent
  }

  .lg\:text-current {
    color: currentColor
  }

  .lg\:text-black {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .lg\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .lg\:text-grey-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .lg\:text-grey-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .lg\:text-grey-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .lg\:text-grey-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .lg\:text-grey-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .lg\:text-grey-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:text-grey-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .lg\:text-grey-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:text-grey-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .lg\:text-grey-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .lg\:text-grey {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:text-grey-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .lg\:text-grey-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .lg\:text-grey-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .lg\:text-grey-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .lg\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .lg\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .lg\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .lg\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .lg\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .lg\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .lg\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .lg\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:text-gray-hover {
    color: rgba(0, 0, 0, 0.04)
  }

  .lg\:text-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .lg\:text-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .lg\:text-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .lg\:text-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .lg\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .lg\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .lg\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .lg\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .lg\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .lg\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .lg\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .lg\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .lg\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .lg\:text-red {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .lg\:text-red-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .lg\:text-red-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .lg\:text-red-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .lg\:text-red-A700 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .lg\:text-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .lg\:text-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .lg\:text-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .lg\:text-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .lg\:text-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .lg\:text-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .lg\:text-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .lg\:text-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .lg\:text-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .lg\:text-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .lg\:text-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .lg\:text-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .lg\:text-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .lg\:text-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .lg\:text-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .lg\:text-deep-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .lg\:text-deep-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .lg\:text-deep-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .lg\:text-deep-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .lg\:text-deep-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .lg\:text-deep-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .lg\:text-deep-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .lg\:text-deep-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .lg\:text-deep-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .lg\:text-deep-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .lg\:text-deep-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .lg\:text-deep-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .lg\:text-deep-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .lg\:text-deep-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .lg\:text-deep-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .lg\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .lg\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .lg\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .lg\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .lg\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .lg\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .lg\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .lg\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .lg\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .lg\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .lg\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .lg\:text-yellow-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .lg\:text-yellow-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .lg\:text-yellow-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .lg\:text-yellow-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .lg\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .lg\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .lg\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .lg\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .lg\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .lg\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .lg\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .lg\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .lg\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .lg\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .lg\:text-green {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .lg\:text-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .lg\:text-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .lg\:text-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .lg\:text-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .lg\:text-light-green-50 {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .lg\:text-light-green-100 {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .lg\:text-light-green-200 {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .lg\:text-light-green-300 {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .lg\:text-light-green-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .lg\:text-light-green-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .lg\:text-light-green-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .lg\:text-light-green-700 {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .lg\:text-light-green-800 {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .lg\:text-light-green-900 {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .lg\:text-light-green {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .lg\:text-light-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .lg\:text-light-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .lg\:text-light-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .lg\:text-light-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .lg\:text-teal-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .lg\:text-teal-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .lg\:text-teal-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .lg\:text-teal-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .lg\:text-teal-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .lg\:text-teal-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .lg\:text-teal-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .lg\:text-teal-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .lg\:text-teal-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .lg\:text-teal-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .lg\:text-teal {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .lg\:text-teal-A100 {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .lg\:text-teal-A200 {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .lg\:text-teal-A400 {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .lg\:text-teal-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .lg\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .lg\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .lg\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .lg\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .lg\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .lg\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .lg\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .lg\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .lg\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .lg\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .lg\:text-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .lg\:text-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .lg\:text-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .lg\:text-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .lg\:text-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .lg\:text-light-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .lg\:text-light-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .lg\:text-light-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .lg\:text-light-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .lg\:text-light-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .lg\:text-light-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .lg\:text-light-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .lg\:text-light-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .lg\:text-light-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .lg\:text-light-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .lg\:text-light-blue {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .lg\:text-light-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .lg\:text-light-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .lg\:text-light-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .lg\:text-light-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .lg\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .lg\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .lg\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .lg\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .lg\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .lg\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .lg\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .lg\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .lg\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .lg\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .lg\:text-indigo {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .lg\:text-indigo-A100 {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .lg\:text-indigo-A200 {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .lg\:text-indigo-A400 {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .lg\:text-indigo-A700 {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .lg\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .lg\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .lg\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .lg\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .lg\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .lg\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .lg\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .lg\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .lg\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .lg\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .lg\:text-purple {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .lg\:text-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .lg\:text-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .lg\:text-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .lg\:text-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .lg\:text-deep-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .lg\:text-deep-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .lg\:text-deep-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .lg\:text-deep-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .lg\:text-deep-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .lg\:text-deep-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .lg\:text-deep-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .lg\:text-deep-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .lg\:text-deep-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .lg\:text-deep-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .lg\:text-deep-purple {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .lg\:text-deep-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .lg\:text-deep-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .lg\:text-deep-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .lg\:text-deep-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .lg\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .lg\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .lg\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .lg\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .lg\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .lg\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .lg\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .lg\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .lg\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .lg\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .lg\:text-pink {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .lg\:text-pink-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .lg\:text-pink-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .lg\:text-pink-A400 {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .lg\:text-pink-A700 {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .lg\:text-lime-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .lg\:text-lime-100 {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .lg\:text-lime-200 {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .lg\:text-lime-300 {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .lg\:text-lime-400 {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .lg\:text-lime-500 {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .lg\:text-lime-600 {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .lg\:text-lime-700 {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .lg\:text-lime-800 {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .lg\:text-lime-900 {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .lg\:text-lime {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .lg\:text-lime-A100 {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .lg\:text-lime-A200 {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .lg\:text-lime-A400 {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .lg\:text-lime-A700 {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .lg\:text-amber-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .lg\:text-amber-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .lg\:text-amber-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .lg\:text-amber-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .lg\:text-amber-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .lg\:text-amber-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .lg\:text-amber-600 {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .lg\:text-amber-700 {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .lg\:text-amber-800 {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .lg\:text-amber-900 {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .lg\:text-amber {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .lg\:text-amber-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .lg\:text-amber-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .lg\:text-amber-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .lg\:text-amber-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .lg\:text-brown-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .lg\:text-brown-100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .lg\:text-brown-200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .lg\:text-brown-300 {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .lg\:text-brown-400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .lg\:text-brown-500 {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .lg\:text-brown-600 {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .lg\:text-brown-700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .lg\:text-brown-800 {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .lg\:text-brown-900 {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .lg\:text-brown {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .lg\:text-brown-A100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .lg\:text-brown-A200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .lg\:text-brown-A400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .lg\:text-brown-A700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .lg\:text-blue-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .lg\:text-blue-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .lg\:text-blue-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .lg\:text-blue-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .lg\:text-blue-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .lg\:text-blue-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .lg\:text-blue-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .lg\:text-blue-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .lg\:text-blue-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .lg\:text-blue-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .lg\:text-blue-gray {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .lg\:text-blue-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .lg\:text-blue-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .lg\:text-blue-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .lg\:text-blue-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .lg\:text-cyan-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .lg\:text-cyan-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .lg\:text-cyan-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .lg\:text-cyan-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .lg\:text-cyan-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .lg\:text-cyan-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .lg\:text-cyan-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .lg\:text-cyan-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .lg\:text-cyan-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .lg\:text-cyan-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .lg\:text-cyan {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .lg\:text-cyan-A100 {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .lg\:text-cyan-A200 {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .lg\:text-cyan-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .lg\:text-cyan-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-transparent {
    color: transparent
  }

  .group:hover .lg\:group-hover\:text-current {
    color: currentColor
  }

  .group:hover .lg\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-grey-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-grey-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-grey-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-grey-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-grey-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-grey-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-grey-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-grey-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-grey-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-grey-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-grey {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-grey-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-grey-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-grey-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-grey-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-gray-hover {
    color: rgba(0, 0, 0, 0.04)
  }

  .group:hover .lg\:group-hover\:text-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-red {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-red-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-red-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-red-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-red-A700 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-yellow-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-yellow-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-yellow-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-yellow-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-green {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-green-50 {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-green-100 {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-green-200 {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-green-300 {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-green-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-green-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-green-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-green-700 {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-green-800 {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-green-900 {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-green {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-teal-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-teal-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-teal-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-teal-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-teal-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-teal-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-teal-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-teal-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-teal-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-teal-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-teal {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-teal-A100 {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-teal-A200 {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-teal-A400 {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-teal-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-blue {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-light-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-indigo {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-indigo-A100 {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-indigo-A200 {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-indigo-A400 {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-indigo-A700 {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-purple {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-purple {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-deep-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-pink {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-pink-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-pink-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-pink-A400 {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-pink-A700 {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-lime-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-lime-100 {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-lime-200 {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-lime-300 {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-lime-400 {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-lime-500 {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-lime-600 {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-lime-700 {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-lime-800 {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-lime-900 {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-lime {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-lime-A100 {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-lime-A200 {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-lime-A400 {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-lime-A700 {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-amber-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-amber-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-amber-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-amber-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-amber-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-amber-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-amber-600 {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-amber-700 {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-amber-800 {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-amber-900 {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-amber {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-amber-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-amber-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-amber-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-amber-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-brown-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-brown-100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-brown-200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-brown-300 {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-brown-400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-brown-500 {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-brown-600 {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-brown-700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-brown-800 {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-brown-900 {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-brown {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-brown-A100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-brown-A200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-brown-A400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-brown-A700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-gray {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-blue-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-cyan-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-cyan-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-cyan-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-cyan-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-cyan-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-cyan-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-cyan-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-cyan-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-cyan-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-cyan-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-cyan {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-cyan-A100 {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-cyan-A200 {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-cyan-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .group:hover .lg\:group-hover\:text-cyan-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-transparent:focus-within {
    color: transparent
  }

  .lg\:focus-within\:text-current:focus-within {
    color: currentColor
  }

  .lg\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-grey-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-grey-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-grey-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-grey-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-grey-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-grey-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-grey-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-grey-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-grey-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-grey-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-grey-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-grey-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-grey-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-grey-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-gray-hover:focus-within {
    color: rgba(0, 0, 0, 0.04)
  }

  .lg\:focus-within\:text-gray-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-gray-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-gray-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-gray-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-red:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-red-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-red-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-red-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-red-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-orange-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-orange-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-orange-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-orange-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-orange-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-orange-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-orange-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-orange-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-orange-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-orange-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-orange:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-orange-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-orange-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-orange-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-orange-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-orange-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-orange-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-orange-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-orange-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-orange-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-orange-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-orange-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-orange-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-orange-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-orange-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-orange:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-orange-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-orange-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-orange-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-orange-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-yellow:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-yellow-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-yellow-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-yellow-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-yellow-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-green-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-green-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-green-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-green-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-green-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-green-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-green-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-green-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-teal-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-teal-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-teal-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-teal-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-teal-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-teal-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-teal-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-teal-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-teal-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-teal-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-teal:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-teal-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-teal-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-teal-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-teal-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-blue-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-blue-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-blue-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-light-blue-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-indigo:focus-within {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-indigo-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-indigo-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-indigo-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-indigo-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-purple:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-purple-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-purple-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-purple-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-purple-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-purple:focus-within {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-purple-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-purple-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-purple-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-deep-purple-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-pink:focus-within {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-pink-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-pink-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-pink-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-pink-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-lime-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-lime-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-lime-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-lime-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-lime-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-lime-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-lime-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-lime-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-lime-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-lime-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-lime:focus-within {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-lime-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-lime-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-lime-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-lime-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-amber-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-amber-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-amber-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-amber-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-amber-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-amber-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-amber-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-amber-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-amber-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-amber-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-amber:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-amber-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-amber-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-amber-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-amber-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-brown-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-brown-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-brown-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-brown-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-brown-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-brown-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-brown-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-brown-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-brown-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-brown-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-brown:focus-within {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-brown-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-brown-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-brown-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-brown-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-gray-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-gray-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-gray-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-blue-gray-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-cyan-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-cyan-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-cyan-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-cyan-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-cyan-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-cyan-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-cyan-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-cyan-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-cyan-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-cyan-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-cyan:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-cyan-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-cyan-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-cyan-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .lg\:focus-within\:text-cyan-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .lg\:hover\:text-transparent:hover {
    color: transparent
  }

  .lg\:hover\:text-current:hover {
    color: currentColor
  }

  .lg\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .lg\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-grey-50:hover {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .lg\:hover\:text-grey-100:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .lg\:hover\:text-grey-200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .lg\:hover\:text-grey-300:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .lg\:hover\:text-grey-400:hover {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .lg\:hover\:text-grey-500:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:hover\:text-grey-600:hover {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .lg\:hover\:text-grey-700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:hover\:text-grey-800:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .lg\:hover\:text-grey-900:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .lg\:hover\:text-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:hover\:text-grey-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .lg\:hover\:text-grey-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .lg\:hover\:text-grey-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .lg\:hover\:text-grey-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .lg\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .lg\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .lg\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .lg\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .lg\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .lg\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .lg\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .lg\:hover\:text-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:hover\:text-gray-hover:hover {
    color: rgba(0, 0, 0, 0.04)
  }

  .lg\:hover\:text-gray-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .lg\:hover\:text-gray-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .lg\:hover\:text-gray-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .lg\:hover\:text-gray-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .lg\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .lg\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .lg\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .lg\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .lg\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .lg\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .lg\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .lg\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .lg\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .lg\:hover\:text-red:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .lg\:hover\:text-red-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .lg\:hover\:text-red-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .lg\:hover\:text-red-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .lg\:hover\:text-red-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-orange-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .lg\:hover\:text-orange-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .lg\:hover\:text-orange-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .lg\:hover\:text-orange-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .lg\:hover\:text-orange-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .lg\:hover\:text-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-orange-600:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-orange-700:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-orange-800:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-orange-900:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-orange:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-orange-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .lg\:hover\:text-orange-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .lg\:hover\:text-orange-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-orange-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-orange-50:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-orange-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-orange-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-orange-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-orange-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-orange-600:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-orange-700:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-orange-800:hover {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-orange-900:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-orange:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-orange-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-orange-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-orange-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-orange-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .lg\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .lg\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .lg\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .lg\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .lg\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .lg\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .lg\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .lg\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .lg\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .lg\:hover\:text-yellow:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .lg\:hover\:text-yellow-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .lg\:hover\:text-yellow-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-yellow-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-yellow-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .lg\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .lg\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .lg\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .lg\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .lg\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .lg\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .lg\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .lg\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .lg\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .lg\:hover\:text-green:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .lg\:hover\:text-green-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .lg\:hover\:text-green-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .lg\:hover\:text-green-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .lg\:hover\:text-green-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-green:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-green-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-green-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-green-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-green-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .lg\:hover\:text-teal-50:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .lg\:hover\:text-teal-100:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .lg\:hover\:text-teal-200:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .lg\:hover\:text-teal-300:hover {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .lg\:hover\:text-teal-400:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .lg\:hover\:text-teal-500:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .lg\:hover\:text-teal-600:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .lg\:hover\:text-teal-700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .lg\:hover\:text-teal-800:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .lg\:hover\:text-teal-900:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .lg\:hover\:text-teal:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .lg\:hover\:text-teal-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .lg\:hover\:text-teal-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .lg\:hover\:text-teal-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .lg\:hover\:text-teal-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-blue-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-blue-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-blue-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-light-blue-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .lg\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .lg\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .lg\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .lg\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .lg\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .lg\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .lg\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .lg\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .lg\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .lg\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .lg\:hover\:text-indigo:hover {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .lg\:hover\:text-indigo-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-indigo-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .lg\:hover\:text-indigo-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .lg\:hover\:text-indigo-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .lg\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .lg\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .lg\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .lg\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .lg\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .lg\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .lg\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .lg\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .lg\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .lg\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .lg\:hover\:text-purple:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .lg\:hover\:text-purple-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .lg\:hover\:text-purple-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .lg\:hover\:text-purple-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .lg\:hover\:text-purple-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-purple:hover {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-purple-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-purple-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-purple-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-deep-purple-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .lg\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .lg\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .lg\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .lg\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .lg\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .lg\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .lg\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .lg\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .lg\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .lg\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .lg\:hover\:text-pink:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .lg\:hover\:text-pink-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .lg\:hover\:text-pink-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .lg\:hover\:text-pink-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .lg\:hover\:text-pink-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .lg\:hover\:text-lime-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .lg\:hover\:text-lime-100:hover {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .lg\:hover\:text-lime-200:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .lg\:hover\:text-lime-300:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .lg\:hover\:text-lime-400:hover {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .lg\:hover\:text-lime-500:hover {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .lg\:hover\:text-lime-600:hover {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .lg\:hover\:text-lime-700:hover {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .lg\:hover\:text-lime-800:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .lg\:hover\:text-lime-900:hover {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .lg\:hover\:text-lime:hover {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .lg\:hover\:text-lime-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .lg\:hover\:text-lime-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .lg\:hover\:text-lime-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-lime-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-amber-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .lg\:hover\:text-amber-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .lg\:hover\:text-amber-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .lg\:hover\:text-amber-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .lg\:hover\:text-amber-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .lg\:hover\:text-amber-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .lg\:hover\:text-amber-600:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-amber-700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-amber-800:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-amber-900:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-amber:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .lg\:hover\:text-amber-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .lg\:hover\:text-amber-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .lg\:hover\:text-amber-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-amber-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .lg\:hover\:text-brown-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .lg\:hover\:text-brown-100:hover {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .lg\:hover\:text-brown-200:hover {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .lg\:hover\:text-brown-300:hover {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .lg\:hover\:text-brown-400:hover {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .lg\:hover\:text-brown-500:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .lg\:hover\:text-brown-600:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .lg\:hover\:text-brown-700:hover {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .lg\:hover\:text-brown-800:hover {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .lg\:hover\:text-brown-900:hover {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .lg\:hover\:text-brown:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .lg\:hover\:text-brown-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .lg\:hover\:text-brown-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .lg\:hover\:text-brown-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .lg\:hover\:text-brown-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-gray-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-gray-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-gray-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .lg\:hover\:text-blue-gray-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .lg\:hover\:text-cyan-50:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .lg\:hover\:text-cyan-100:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .lg\:hover\:text-cyan-200:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .lg\:hover\:text-cyan-300:hover {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .lg\:hover\:text-cyan-400:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .lg\:hover\:text-cyan-500:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .lg\:hover\:text-cyan-600:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .lg\:hover\:text-cyan-700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .lg\:hover\:text-cyan-800:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .lg\:hover\:text-cyan-900:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .lg\:hover\:text-cyan:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .lg\:hover\:text-cyan-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-cyan-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-cyan-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .lg\:hover\:text-cyan-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .lg\:focus\:text-transparent:focus {
    color: transparent
  }

  .lg\:focus\:text-current:focus {
    color: currentColor
  }

  .lg\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .lg\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-grey-50:focus {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .lg\:focus\:text-grey-100:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .lg\:focus\:text-grey-200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .lg\:focus\:text-grey-300:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .lg\:focus\:text-grey-400:focus {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .lg\:focus\:text-grey-500:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:focus\:text-grey-600:focus {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .lg\:focus\:text-grey-700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:focus\:text-grey-800:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .lg\:focus\:text-grey-900:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .lg\:focus\:text-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:focus\:text-grey-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .lg\:focus\:text-grey-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .lg\:focus\:text-grey-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .lg\:focus\:text-grey-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .lg\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .lg\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .lg\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .lg\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .lg\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .lg\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .lg\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .lg\:focus\:text-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .lg\:focus\:text-gray-hover:focus {
    color: rgba(0, 0, 0, 0.04)
  }

  .lg\:focus\:text-gray-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .lg\:focus\:text-gray-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .lg\:focus\:text-gray-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .lg\:focus\:text-gray-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .lg\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .lg\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .lg\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .lg\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .lg\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .lg\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .lg\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .lg\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .lg\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .lg\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .lg\:focus\:text-red:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .lg\:focus\:text-red-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .lg\:focus\:text-red-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .lg\:focus\:text-red-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .lg\:focus\:text-red-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-orange-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .lg\:focus\:text-orange-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .lg\:focus\:text-orange-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .lg\:focus\:text-orange-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .lg\:focus\:text-orange-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .lg\:focus\:text-orange-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-orange-600:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-orange-700:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-orange-800:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-orange-900:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-orange:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-orange-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .lg\:focus\:text-orange-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .lg\:focus\:text-orange-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-orange-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-orange-50:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-orange-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-orange-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-orange-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-orange-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-orange-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-orange-600:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-orange-700:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-orange-800:focus {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-orange-900:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-orange:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-orange-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-orange-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-orange-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-orange-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .lg\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .lg\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .lg\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .lg\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .lg\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .lg\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .lg\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .lg\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .lg\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .lg\:focus\:text-yellow:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .lg\:focus\:text-yellow-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .lg\:focus\:text-yellow-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-yellow-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-yellow-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .lg\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .lg\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .lg\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .lg\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .lg\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .lg\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .lg\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .lg\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .lg\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .lg\:focus\:text-green:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .lg\:focus\:text-green-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .lg\:focus\:text-green-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .lg\:focus\:text-green-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .lg\:focus\:text-green-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-green:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-green-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-green-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-green-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-green-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .lg\:focus\:text-teal-50:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .lg\:focus\:text-teal-100:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .lg\:focus\:text-teal-200:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .lg\:focus\:text-teal-300:focus {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .lg\:focus\:text-teal-400:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .lg\:focus\:text-teal-500:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .lg\:focus\:text-teal-600:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .lg\:focus\:text-teal-700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .lg\:focus\:text-teal-800:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .lg\:focus\:text-teal-900:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .lg\:focus\:text-teal:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .lg\:focus\:text-teal-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .lg\:focus\:text-teal-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .lg\:focus\:text-teal-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .lg\:focus\:text-teal-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-blue-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-blue-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-blue-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-light-blue-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .lg\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .lg\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .lg\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .lg\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .lg\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .lg\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .lg\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .lg\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .lg\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .lg\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .lg\:focus\:text-indigo:focus {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .lg\:focus\:text-indigo-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-indigo-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .lg\:focus\:text-indigo-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .lg\:focus\:text-indigo-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .lg\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .lg\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .lg\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .lg\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .lg\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .lg\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .lg\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .lg\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .lg\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .lg\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .lg\:focus\:text-purple:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .lg\:focus\:text-purple-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .lg\:focus\:text-purple-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .lg\:focus\:text-purple-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .lg\:focus\:text-purple-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-purple:focus {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-purple-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-purple-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-purple-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-deep-purple-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .lg\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .lg\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .lg\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .lg\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .lg\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .lg\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .lg\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .lg\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .lg\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .lg\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .lg\:focus\:text-pink:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .lg\:focus\:text-pink-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .lg\:focus\:text-pink-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .lg\:focus\:text-pink-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .lg\:focus\:text-pink-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .lg\:focus\:text-lime-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .lg\:focus\:text-lime-100:focus {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .lg\:focus\:text-lime-200:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .lg\:focus\:text-lime-300:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .lg\:focus\:text-lime-400:focus {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .lg\:focus\:text-lime-500:focus {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .lg\:focus\:text-lime-600:focus {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .lg\:focus\:text-lime-700:focus {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .lg\:focus\:text-lime-800:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .lg\:focus\:text-lime-900:focus {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .lg\:focus\:text-lime:focus {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .lg\:focus\:text-lime-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .lg\:focus\:text-lime-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .lg\:focus\:text-lime-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-lime-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-amber-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .lg\:focus\:text-amber-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .lg\:focus\:text-amber-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .lg\:focus\:text-amber-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .lg\:focus\:text-amber-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .lg\:focus\:text-amber-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .lg\:focus\:text-amber-600:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-amber-700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-amber-800:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-amber-900:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-amber:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .lg\:focus\:text-amber-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .lg\:focus\:text-amber-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .lg\:focus\:text-amber-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-amber-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .lg\:focus\:text-brown-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .lg\:focus\:text-brown-100:focus {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .lg\:focus\:text-brown-200:focus {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .lg\:focus\:text-brown-300:focus {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .lg\:focus\:text-brown-400:focus {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .lg\:focus\:text-brown-500:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .lg\:focus\:text-brown-600:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .lg\:focus\:text-brown-700:focus {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .lg\:focus\:text-brown-800:focus {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .lg\:focus\:text-brown-900:focus {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .lg\:focus\:text-brown:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .lg\:focus\:text-brown-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .lg\:focus\:text-brown-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .lg\:focus\:text-brown-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .lg\:focus\:text-brown-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-gray-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-gray-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-gray-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .lg\:focus\:text-blue-gray-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .lg\:focus\:text-cyan-50:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .lg\:focus\:text-cyan-100:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .lg\:focus\:text-cyan-200:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .lg\:focus\:text-cyan-300:focus {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .lg\:focus\:text-cyan-400:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .lg\:focus\:text-cyan-500:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .lg\:focus\:text-cyan-600:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .lg\:focus\:text-cyan-700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .lg\:focus\:text-cyan-800:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .lg\:focus\:text-cyan-900:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .lg\:focus\:text-cyan:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .lg\:focus\:text-cyan-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-cyan-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-cyan-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .lg\:focus\:text-cyan-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .lg\:text-opacity-0 {
    --tw-text-opacity: 0
  }

  .lg\:text-opacity-5 {
    --tw-text-opacity: 0.05
  }

  .lg\:text-opacity-10 {
    --tw-text-opacity: 0.1
  }

  .lg\:text-opacity-20 {
    --tw-text-opacity: 0.2
  }

  .lg\:text-opacity-25 {
    --tw-text-opacity: 0.25
  }

  .lg\:text-opacity-30 {
    --tw-text-opacity: 0.3
  }

  .lg\:text-opacity-40 {
    --tw-text-opacity: 0.4
  }

  .lg\:text-opacity-50 {
    --tw-text-opacity: 0.5
  }

  .lg\:text-opacity-60 {
    --tw-text-opacity: 0.6
  }

  .lg\:text-opacity-70 {
    --tw-text-opacity: 0.7
  }

  .lg\:text-opacity-75 {
    --tw-text-opacity: 0.75
  }

  .lg\:text-opacity-80 {
    --tw-text-opacity: 0.8
  }

  .lg\:text-opacity-90 {
    --tw-text-opacity: 0.9
  }

  .lg\:text-opacity-95 {
    --tw-text-opacity: 0.95
  }

  .lg\:text-opacity-100 {
    --tw-text-opacity: 1
  }

  .group:hover .lg\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0
  }

  .group:hover .lg\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05
  }

  .group:hover .lg\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1
  }

  .group:hover .lg\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2
  }

  .group:hover .lg\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25
  }

  .group:hover .lg\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3
  }

  .group:hover .lg\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4
  }

  .group:hover .lg\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5
  }

  .group:hover .lg\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6
  }

  .group:hover .lg\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7
  }

  .group:hover .lg\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75
  }

  .group:hover .lg\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8
  }

  .group:hover .lg\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9
  }

  .group:hover .lg\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95
  }

  .group:hover .lg\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1
  }

  .lg\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0
  }

  .lg\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05
  }

  .lg\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1
  }

  .lg\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2
  }

  .lg\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25
  }

  .lg\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3
  }

  .lg\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4
  }

  .lg\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5
  }

  .lg\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6
  }

  .lg\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7
  }

  .lg\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75
  }

  .lg\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8
  }

  .lg\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9
  }

  .lg\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95
  }

  .lg\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1
  }

  .lg\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0
  }

  .lg\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05
  }

  .lg\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1
  }

  .lg\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2
  }

  .lg\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25
  }

  .lg\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3
  }

  .lg\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4
  }

  .lg\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5
  }

  .lg\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6
  }

  .lg\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7
  }

  .lg\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75
  }

  .lg\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8
  }

  .lg\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9
  }

  .lg\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95
  }

  .lg\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1
  }

  .lg\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0
  }

  .lg\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05
  }

  .lg\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1
  }

  .lg\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2
  }

  .lg\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25
  }

  .lg\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3
  }

  .lg\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4
  }

  .lg\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5
  }

  .lg\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6
  }

  .lg\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7
  }

  .lg\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75
  }

  .lg\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8
  }

  .lg\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9
  }

  .lg\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95
  }

  .lg\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1
  }

  .lg\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .lg\:overflow-ellipsis {
    text-overflow: ellipsis
  }

  .lg\:overflow-clip {
    text-overflow: clip
  }

  .lg\:italic {
    font-style: italic
  }

  .lg\:not-italic {
    font-style: normal
  }

  .lg\:hover\:italic:hover {
    font-style: italic
  }

  .lg\:hover\:not-italic:hover {
    font-style: normal
  }

  .lg\:focus\:italic:focus {
    font-style: italic
  }

  .lg\:focus\:not-italic:focus {
    font-style: normal
  }

  .lg\:uppercase {
    text-transform: uppercase
  }

  .lg\:lowercase {
    text-transform: lowercase
  }

  .lg\:capitalize {
    text-transform: capitalize
  }

  .lg\:normal-case {
    text-transform: none
  }

  .lg\:underline {
    text-decoration: underline
  }

  .lg\:line-through {
    text-decoration: line-through
  }

  .lg\:no-underline {
    text-decoration: none
  }

  .group:hover .lg\:group-hover\:underline {
    text-decoration: underline
  }

  .group:hover .lg\:group-hover\:line-through {
    text-decoration: line-through
  }

  .group:hover .lg\:group-hover\:no-underline {
    text-decoration: none
  }

  .lg\:focus-within\:underline:focus-within {
    text-decoration: underline
  }

  .lg\:focus-within\:line-through:focus-within {
    text-decoration: line-through
  }

  .lg\:focus-within\:no-underline:focus-within {
    text-decoration: none
  }

  .lg\:hover\:underline:hover {
    text-decoration: underline
  }

  .lg\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .lg\:hover\:no-underline:hover {
    text-decoration: none
  }

  .lg\:focus\:underline:focus {
    text-decoration: underline
  }

  .lg\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .lg\:focus\:no-underline:focus {
    text-decoration: none
  }

  .lg\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .lg\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .lg\:ordinal, .lg\:slashed-zero, .lg\:lining-nums, .lg\:oldstyle-nums, .lg\:proportional-nums, .lg\:tabular-nums, .lg\:diagonal-fractions, .lg\:stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  .lg\:normal-nums {
    font-variant-numeric: normal
  }

  .lg\:ordinal {
    --tw-ordinal: ordinal
  }

  .lg\:slashed-zero {
    --tw-slashed-zero: slashed-zero
  }

  .lg\:lining-nums {
    --tw-numeric-figure: lining-nums
  }

  .lg\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums
  }

  .lg\:proportional-nums {
    --tw-numeric-spacing: proportional-nums
  }

  .lg\:tabular-nums {
    --tw-numeric-spacing: tabular-nums
  }

  .lg\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions
  }

  .lg\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions
  }

  .lg\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .lg\:tracking-tight {
    letter-spacing: -0.025em
  }

  .lg\:tracking-normal {
    letter-spacing: 0em
  }

  .lg\:tracking-wide {
    letter-spacing: 0.025em
  }

  .lg\:tracking-wider {
    letter-spacing: 0.05em
  }

  .lg\:tracking-widest {
    letter-spacing: 0.1em
  }

  .lg\:select-none {
    -webkit-user-select: none;
            user-select: none
  }

  .lg\:select-text {
    -webkit-user-select: text;
            user-select: text
  }

  .lg\:select-all {
    -webkit-user-select: all;
            user-select: all
  }

  .lg\:select-auto {
    -webkit-user-select: auto;
            user-select: auto
  }

  .lg\:align-baseline {
    vertical-align: baseline
  }

  .lg\:align-top {
    vertical-align: top
  }

  .lg\:align-middle {
    vertical-align: middle
  }

  .lg\:align-bottom {
    vertical-align: bottom
  }

  .lg\:align-text-top {
    vertical-align: text-top
  }

  .lg\:align-text-bottom {
    vertical-align: text-bottom
  }

  .lg\:visible {
    visibility: visible
  }

  .lg\:invisible {
    visibility: hidden
  }

  .lg\:whitespace-normal {
    white-space: normal
  }

  .lg\:whitespace-nowrap {
    white-space: nowrap
  }

  .lg\:whitespace-pre {
    white-space: pre
  }

  .lg\:whitespace-pre-line {
    white-space: pre-line
  }

  .lg\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .lg\:break-normal {
    overflow-wrap: normal;
    word-break: normal
  }

  .lg\:break-words {
    overflow-wrap: break-word
  }

  .lg\:break-all {
    word-break: break-all
  }

  .lg\:w-0 {
    width: 0
  }

  .lg\:w-1 {
    width: 0.1rem
  }

  .lg\:w-2 {
    width: 0.2rem
  }

  .lg\:w-3 {
    width: 0.3rem
  }

  .lg\:w-4 {
    width: 0.4rem
  }

  .lg\:w-5 {
    width: 0.5rem
  }

  .lg\:w-6 {
    width: 0.6rem
  }

  .lg\:w-7 {
    width: 0.7rem
  }

  .lg\:w-8 {
    width: 0.8rem
  }

  .lg\:w-9 {
    width: 0.9rem
  }

  .lg\:w-10 {
    width: 1.0rem
  }

  .lg\:w-12 {
    width: 1.2rem
  }

  .lg\:w-14 {
    width: 1.4rem
  }

  .lg\:w-16 {
    width: 1.6rem
  }

  .lg\:w-20 {
    width: 2rem
  }

  .lg\:w-24 {
    width: 2.4rem
  }

  .lg\:w-28 {
    width: 2.8rem
  }

  .lg\:w-32 {
    width: 3.2rem
  }

  .lg\:w-36 {
    width: 3.6rem
  }

  .lg\:w-40 {
    width: 4rem
  }

  .lg\:w-44 {
    width: 4.4rem
  }

  .lg\:w-48 {
    width: 4.8rem
  }

  .lg\:w-52 {
    width: 5.2rem
  }

  .lg\:w-56 {
    width: 5.6rem
  }

  .lg\:w-60 {
    width: 6rem
  }

  .lg\:w-64 {
    width: 6.4rem
  }

  .lg\:w-68 {
    width: 6.8rem
  }

  .lg\:w-72 {
    width: 7.2rem
  }

  .lg\:w-76 {
    width: 7.6rem
  }

  .lg\:w-80 {
    width: 8rem
  }

  .lg\:w-84 {
    width: 8.4rem
  }

  .lg\:w-88 {
    width: 8.8rem
  }

  .lg\:w-92 {
    width: 9.2rem
  }

  .lg\:w-96 {
    width: 9.6rem
  }

  .lg\:w-128 {
    width: 12.8rem
  }

  .lg\:w-136 {
    width: 13.6rem
  }

  .lg\:w-160 {
    width: 16rem
  }

  .lg\:w-192 {
    width: 19.2rem
  }

  .lg\:w-200 {
    width: 20rem
  }

  .lg\:w-208 {
    width: 20.8rem
  }

  .lg\:w-216 {
    width: 21.6rem
  }

  .lg\:w-224 {
    width: 22.4rem
  }

  .lg\:w-256 {
    width: 25.6rem
  }

  .lg\:w-288 {
    width: 28.8rem
  }

  .lg\:w-320 {
    width: 32rem
  }

  .lg\:w-360 {
    width: 36rem
  }

  .lg\:w-384 {
    width: 38.4rem
  }

  .lg\:w-400 {
    width: 40rem
  }

  .lg\:w-512 {
    width: 51.2rem
  }

  .lg\:w-640 {
    width: 64rem
  }

  .lg\:w-auto {
    width: auto
  }

  .lg\:w-xs {
    width: 32rem
  }

  .lg\:w-sm {
    width: 48rem
  }

  .lg\:w-md {
    width: 64rem
  }

  .lg\:w-lg {
    width: 80rem
  }

  .lg\:w-xl {
    width: 96rem
  }

  .lg\:w-2xl {
    width: 112rem
  }

  .lg\:w-3xl {
    width: 128rem
  }

  .lg\:w-4xl {
    width: 144rem
  }

  .lg\:w-5xl {
    width: 160rem
  }

  .lg\:w-px {
    width: 1px
  }

  .lg\:w-0\.5 {
    width: 0.05rem
  }

  .lg\:w-1\.5 {
    width: 0.15rem
  }

  .lg\:w-2\.5 {
    width: 0.25rem
  }

  .lg\:w-3\.5 {
    width: 0.35rem
  }

  .lg\:w-1\/2 {
    width: 50%
  }

  .lg\:w-1\/3 {
    width: 33.333333%
  }

  .lg\:w-2\/3 {
    width: 66.666667%
  }

  .lg\:w-1\/4 {
    width: 25%
  }

  .lg\:w-2\/4 {
    width: 50%
  }

  .lg\:w-3\/4 {
    width: 75%
  }

  .lg\:w-1\/5 {
    width: 20%
  }

  .lg\:w-2\/5 {
    width: 40%
  }

  .lg\:w-3\/5 {
    width: 60%
  }

  .lg\:w-4\/5 {
    width: 80%
  }

  .lg\:w-1\/6 {
    width: 16.666667%
  }

  .lg\:w-2\/6 {
    width: 33.333333%
  }

  .lg\:w-3\/6 {
    width: 50%
  }

  .lg\:w-4\/6 {
    width: 66.666667%
  }

  .lg\:w-5\/6 {
    width: 83.333333%
  }

  .lg\:w-1\/12 {
    width: 8.333333%
  }

  .lg\:w-2\/12 {
    width: 16.666667%
  }

  .lg\:w-3\/12 {
    width: 25%
  }

  .lg\:w-4\/12 {
    width: 33.333333%
  }

  .lg\:w-5\/12 {
    width: 41.666667%
  }

  .lg\:w-6\/12 {
    width: 50%
  }

  .lg\:w-7\/12 {
    width: 58.333333%
  }

  .lg\:w-8\/12 {
    width: 66.666667%
  }

  .lg\:w-9\/12 {
    width: 75%
  }

  .lg\:w-10\/12 {
    width: 83.333333%
  }

  .lg\:w-11\/12 {
    width: 91.666667%
  }

  .lg\:w-full {
    width: 100%
  }

  .lg\:w-screen {
    width: 100vw
  }

  .lg\:w-min {
    width: min-content
  }

  .lg\:w-max {
    width: max-content
  }

  .lg\:z-0 {
    z-index: 0
  }

  .lg\:z-10 {
    z-index: 10
  }

  .lg\:z-20 {
    z-index: 20
  }

  .lg\:z-30 {
    z-index: 30
  }

  .lg\:z-40 {
    z-index: 40
  }

  .lg\:z-50 {
    z-index: 50
  }

  .lg\:z-99 {
    z-index: 99
  }

  .lg\:z-999 {
    z-index: 999
  }

  .lg\:z-9999 {
    z-index: 9999
  }

  .lg\:z-auto {
    z-index: auto
  }

  .lg\:focus-within\:z-0:focus-within {
    z-index: 0
  }

  .lg\:focus-within\:z-10:focus-within {
    z-index: 10
  }

  .lg\:focus-within\:z-20:focus-within {
    z-index: 20
  }

  .lg\:focus-within\:z-30:focus-within {
    z-index: 30
  }

  .lg\:focus-within\:z-40:focus-within {
    z-index: 40
  }

  .lg\:focus-within\:z-50:focus-within {
    z-index: 50
  }

  .lg\:focus-within\:z-99:focus-within {
    z-index: 99
  }

  .lg\:focus-within\:z-999:focus-within {
    z-index: 999
  }

  .lg\:focus-within\:z-9999:focus-within {
    z-index: 9999
  }

  .lg\:focus-within\:z-auto:focus-within {
    z-index: auto
  }

  .lg\:focus\:z-0:focus {
    z-index: 0
  }

  .lg\:focus\:z-10:focus {
    z-index: 10
  }

  .lg\:focus\:z-20:focus {
    z-index: 20
  }

  .lg\:focus\:z-30:focus {
    z-index: 30
  }

  .lg\:focus\:z-40:focus {
    z-index: 40
  }

  .lg\:focus\:z-50:focus {
    z-index: 50
  }

  .lg\:focus\:z-99:focus {
    z-index: 99
  }

  .lg\:focus\:z-999:focus {
    z-index: 999
  }

  .lg\:focus\:z-9999:focus {
    z-index: 9999
  }

  .lg\:focus\:z-auto:focus {
    z-index: auto
  }

  .lg\:gap-0 {
    gap: 0
  }

  .lg\:gap-1 {
    gap: 0.1rem
  }

  .lg\:gap-2 {
    gap: 0.2rem
  }

  .lg\:gap-3 {
    gap: 0.3rem
  }

  .lg\:gap-4 {
    gap: 0.4rem
  }

  .lg\:gap-5 {
    gap: 0.5rem
  }

  .lg\:gap-6 {
    gap: 0.6rem
  }

  .lg\:gap-7 {
    gap: 0.7rem
  }

  .lg\:gap-8 {
    gap: 0.8rem
  }

  .lg\:gap-9 {
    gap: 0.9rem
  }

  .lg\:gap-10 {
    gap: 1.0rem
  }

  .lg\:gap-12 {
    gap: 1.2rem
  }

  .lg\:gap-14 {
    gap: 1.4rem
  }

  .lg\:gap-16 {
    gap: 1.6rem
  }

  .lg\:gap-20 {
    gap: 2rem
  }

  .lg\:gap-24 {
    gap: 2.4rem
  }

  .lg\:gap-28 {
    gap: 2.8rem
  }

  .lg\:gap-32 {
    gap: 3.2rem
  }

  .lg\:gap-36 {
    gap: 3.6rem
  }

  .lg\:gap-40 {
    gap: 4rem
  }

  .lg\:gap-44 {
    gap: 4.4rem
  }

  .lg\:gap-48 {
    gap: 4.8rem
  }

  .lg\:gap-52 {
    gap: 5.2rem
  }

  .lg\:gap-56 {
    gap: 5.6rem
  }

  .lg\:gap-60 {
    gap: 6rem
  }

  .lg\:gap-64 {
    gap: 6.4rem
  }

  .lg\:gap-68 {
    gap: 6.8rem
  }

  .lg\:gap-72 {
    gap: 7.2rem
  }

  .lg\:gap-76 {
    gap: 7.6rem
  }

  .lg\:gap-80 {
    gap: 8rem
  }

  .lg\:gap-84 {
    gap: 8.4rem
  }

  .lg\:gap-88 {
    gap: 8.8rem
  }

  .lg\:gap-92 {
    gap: 9.2rem
  }

  .lg\:gap-96 {
    gap: 9.6rem
  }

  .lg\:gap-128 {
    gap: 12.8rem
  }

  .lg\:gap-136 {
    gap: 13.6rem
  }

  .lg\:gap-160 {
    gap: 16rem
  }

  .lg\:gap-192 {
    gap: 19.2rem
  }

  .lg\:gap-200 {
    gap: 20rem
  }

  .lg\:gap-208 {
    gap: 20.8rem
  }

  .lg\:gap-216 {
    gap: 21.6rem
  }

  .lg\:gap-224 {
    gap: 22.4rem
  }

  .lg\:gap-256 {
    gap: 25.6rem
  }

  .lg\:gap-288 {
    gap: 28.8rem
  }

  .lg\:gap-320 {
    gap: 32rem
  }

  .lg\:gap-360 {
    gap: 36rem
  }

  .lg\:gap-384 {
    gap: 38.4rem
  }

  .lg\:gap-400 {
    gap: 40rem
  }

  .lg\:gap-512 {
    gap: 51.2rem
  }

  .lg\:gap-640 {
    gap: 64rem
  }

  .lg\:gap-xs {
    gap: 32rem
  }

  .lg\:gap-sm {
    gap: 48rem
  }

  .lg\:gap-md {
    gap: 64rem
  }

  .lg\:gap-lg {
    gap: 80rem
  }

  .lg\:gap-xl {
    gap: 96rem
  }

  .lg\:gap-2xl {
    gap: 112rem
  }

  .lg\:gap-3xl {
    gap: 128rem
  }

  .lg\:gap-4xl {
    gap: 144rem
  }

  .lg\:gap-5xl {
    gap: 160rem
  }

  .lg\:gap-px {
    gap: 1px
  }

  .lg\:gap-0\.5 {
    gap: 0.05rem
  }

  .lg\:gap-1\.5 {
    gap: 0.15rem
  }

  .lg\:gap-2\.5 {
    gap: 0.25rem
  }

  .lg\:gap-3\.5 {
    gap: 0.35rem
  }

  .lg\:gap-x-0 {
    column-gap: 0
  }

  .lg\:gap-x-1 {
    column-gap: 0.1rem
  }

  .lg\:gap-x-2 {
    column-gap: 0.2rem
  }

  .lg\:gap-x-3 {
    column-gap: 0.3rem
  }

  .lg\:gap-x-4 {
    column-gap: 0.4rem
  }

  .lg\:gap-x-5 {
    column-gap: 0.5rem
  }

  .lg\:gap-x-6 {
    column-gap: 0.6rem
  }

  .lg\:gap-x-7 {
    column-gap: 0.7rem
  }

  .lg\:gap-x-8 {
    column-gap: 0.8rem
  }

  .lg\:gap-x-9 {
    column-gap: 0.9rem
  }

  .lg\:gap-x-10 {
    column-gap: 1.0rem
  }

  .lg\:gap-x-12 {
    column-gap: 1.2rem
  }

  .lg\:gap-x-14 {
    column-gap: 1.4rem
  }

  .lg\:gap-x-16 {
    column-gap: 1.6rem
  }

  .lg\:gap-x-20 {
    column-gap: 2rem
  }

  .lg\:gap-x-24 {
    column-gap: 2.4rem
  }

  .lg\:gap-x-28 {
    column-gap: 2.8rem
  }

  .lg\:gap-x-32 {
    column-gap: 3.2rem
  }

  .lg\:gap-x-36 {
    column-gap: 3.6rem
  }

  .lg\:gap-x-40 {
    column-gap: 4rem
  }

  .lg\:gap-x-44 {
    column-gap: 4.4rem
  }

  .lg\:gap-x-48 {
    column-gap: 4.8rem
  }

  .lg\:gap-x-52 {
    column-gap: 5.2rem
  }

  .lg\:gap-x-56 {
    column-gap: 5.6rem
  }

  .lg\:gap-x-60 {
    column-gap: 6rem
  }

  .lg\:gap-x-64 {
    column-gap: 6.4rem
  }

  .lg\:gap-x-68 {
    column-gap: 6.8rem
  }

  .lg\:gap-x-72 {
    column-gap: 7.2rem
  }

  .lg\:gap-x-76 {
    column-gap: 7.6rem
  }

  .lg\:gap-x-80 {
    column-gap: 8rem
  }

  .lg\:gap-x-84 {
    column-gap: 8.4rem
  }

  .lg\:gap-x-88 {
    column-gap: 8.8rem
  }

  .lg\:gap-x-92 {
    column-gap: 9.2rem
  }

  .lg\:gap-x-96 {
    column-gap: 9.6rem
  }

  .lg\:gap-x-128 {
    column-gap: 12.8rem
  }

  .lg\:gap-x-136 {
    column-gap: 13.6rem
  }

  .lg\:gap-x-160 {
    column-gap: 16rem
  }

  .lg\:gap-x-192 {
    column-gap: 19.2rem
  }

  .lg\:gap-x-200 {
    column-gap: 20rem
  }

  .lg\:gap-x-208 {
    column-gap: 20.8rem
  }

  .lg\:gap-x-216 {
    column-gap: 21.6rem
  }

  .lg\:gap-x-224 {
    column-gap: 22.4rem
  }

  .lg\:gap-x-256 {
    column-gap: 25.6rem
  }

  .lg\:gap-x-288 {
    column-gap: 28.8rem
  }

  .lg\:gap-x-320 {
    column-gap: 32rem
  }

  .lg\:gap-x-360 {
    column-gap: 36rem
  }

  .lg\:gap-x-384 {
    column-gap: 38.4rem
  }

  .lg\:gap-x-400 {
    column-gap: 40rem
  }

  .lg\:gap-x-512 {
    column-gap: 51.2rem
  }

  .lg\:gap-x-640 {
    column-gap: 64rem
  }

  .lg\:gap-x-xs {
    column-gap: 32rem
  }

  .lg\:gap-x-sm {
    column-gap: 48rem
  }

  .lg\:gap-x-md {
    column-gap: 64rem
  }

  .lg\:gap-x-lg {
    column-gap: 80rem
  }

  .lg\:gap-x-xl {
    column-gap: 96rem
  }

  .lg\:gap-x-2xl {
    column-gap: 112rem
  }

  .lg\:gap-x-3xl {
    column-gap: 128rem
  }

  .lg\:gap-x-4xl {
    column-gap: 144rem
  }

  .lg\:gap-x-5xl {
    column-gap: 160rem
  }

  .lg\:gap-x-px {
    column-gap: 1px
  }

  .lg\:gap-x-0\.5 {
    column-gap: 0.05rem
  }

  .lg\:gap-x-1\.5 {
    column-gap: 0.15rem
  }

  .lg\:gap-x-2\.5 {
    column-gap: 0.25rem
  }

  .lg\:gap-x-3\.5 {
    column-gap: 0.35rem
  }

  .lg\:gap-y-0 {
    row-gap: 0
  }

  .lg\:gap-y-1 {
    row-gap: 0.1rem
  }

  .lg\:gap-y-2 {
    row-gap: 0.2rem
  }

  .lg\:gap-y-3 {
    row-gap: 0.3rem
  }

  .lg\:gap-y-4 {
    row-gap: 0.4rem
  }

  .lg\:gap-y-5 {
    row-gap: 0.5rem
  }

  .lg\:gap-y-6 {
    row-gap: 0.6rem
  }

  .lg\:gap-y-7 {
    row-gap: 0.7rem
  }

  .lg\:gap-y-8 {
    row-gap: 0.8rem
  }

  .lg\:gap-y-9 {
    row-gap: 0.9rem
  }

  .lg\:gap-y-10 {
    row-gap: 1.0rem
  }

  .lg\:gap-y-12 {
    row-gap: 1.2rem
  }

  .lg\:gap-y-14 {
    row-gap: 1.4rem
  }

  .lg\:gap-y-16 {
    row-gap: 1.6rem
  }

  .lg\:gap-y-20 {
    row-gap: 2rem
  }

  .lg\:gap-y-24 {
    row-gap: 2.4rem
  }

  .lg\:gap-y-28 {
    row-gap: 2.8rem
  }

  .lg\:gap-y-32 {
    row-gap: 3.2rem
  }

  .lg\:gap-y-36 {
    row-gap: 3.6rem
  }

  .lg\:gap-y-40 {
    row-gap: 4rem
  }

  .lg\:gap-y-44 {
    row-gap: 4.4rem
  }

  .lg\:gap-y-48 {
    row-gap: 4.8rem
  }

  .lg\:gap-y-52 {
    row-gap: 5.2rem
  }

  .lg\:gap-y-56 {
    row-gap: 5.6rem
  }

  .lg\:gap-y-60 {
    row-gap: 6rem
  }

  .lg\:gap-y-64 {
    row-gap: 6.4rem
  }

  .lg\:gap-y-68 {
    row-gap: 6.8rem
  }

  .lg\:gap-y-72 {
    row-gap: 7.2rem
  }

  .lg\:gap-y-76 {
    row-gap: 7.6rem
  }

  .lg\:gap-y-80 {
    row-gap: 8rem
  }

  .lg\:gap-y-84 {
    row-gap: 8.4rem
  }

  .lg\:gap-y-88 {
    row-gap: 8.8rem
  }

  .lg\:gap-y-92 {
    row-gap: 9.2rem
  }

  .lg\:gap-y-96 {
    row-gap: 9.6rem
  }

  .lg\:gap-y-128 {
    row-gap: 12.8rem
  }

  .lg\:gap-y-136 {
    row-gap: 13.6rem
  }

  .lg\:gap-y-160 {
    row-gap: 16rem
  }

  .lg\:gap-y-192 {
    row-gap: 19.2rem
  }

  .lg\:gap-y-200 {
    row-gap: 20rem
  }

  .lg\:gap-y-208 {
    row-gap: 20.8rem
  }

  .lg\:gap-y-216 {
    row-gap: 21.6rem
  }

  .lg\:gap-y-224 {
    row-gap: 22.4rem
  }

  .lg\:gap-y-256 {
    row-gap: 25.6rem
  }

  .lg\:gap-y-288 {
    row-gap: 28.8rem
  }

  .lg\:gap-y-320 {
    row-gap: 32rem
  }

  .lg\:gap-y-360 {
    row-gap: 36rem
  }

  .lg\:gap-y-384 {
    row-gap: 38.4rem
  }

  .lg\:gap-y-400 {
    row-gap: 40rem
  }

  .lg\:gap-y-512 {
    row-gap: 51.2rem
  }

  .lg\:gap-y-640 {
    row-gap: 64rem
  }

  .lg\:gap-y-xs {
    row-gap: 32rem
  }

  .lg\:gap-y-sm {
    row-gap: 48rem
  }

  .lg\:gap-y-md {
    row-gap: 64rem
  }

  .lg\:gap-y-lg {
    row-gap: 80rem
  }

  .lg\:gap-y-xl {
    row-gap: 96rem
  }

  .lg\:gap-y-2xl {
    row-gap: 112rem
  }

  .lg\:gap-y-3xl {
    row-gap: 128rem
  }

  .lg\:gap-y-4xl {
    row-gap: 144rem
  }

  .lg\:gap-y-5xl {
    row-gap: 160rem
  }

  .lg\:gap-y-px {
    row-gap: 1px
  }

  .lg\:gap-y-0\.5 {
    row-gap: 0.05rem
  }

  .lg\:gap-y-1\.5 {
    row-gap: 0.15rem
  }

  .lg\:gap-y-2\.5 {
    row-gap: 0.25rem
  }

  .lg\:gap-y-3\.5 {
    row-gap: 0.35rem
  }

  .lg\:grid-flow-row {
    grid-auto-flow: row
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column
  }

  .lg\:grid-flow-row-dense {
    grid-auto-flow: row dense
  }

  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .lg\:grid-cols-none {
    grid-template-columns: none
  }

  .lg\:auto-cols-auto {
    grid-auto-columns: auto
  }

  .lg\:auto-cols-min {
    grid-auto-columns: min-content
  }

  .lg\:auto-cols-max {
    grid-auto-columns: max-content
  }

  .lg\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr)
  }

  .lg\:col-auto {
    grid-column: auto
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .lg\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .lg\:col-span-full {
    grid-column: 1 / -1
  }

  .lg\:col-start-1 {
    grid-column-start: 1
  }

  .lg\:col-start-2 {
    grid-column-start: 2
  }

  .lg\:col-start-3 {
    grid-column-start: 3
  }

  .lg\:col-start-4 {
    grid-column-start: 4
  }

  .lg\:col-start-5 {
    grid-column-start: 5
  }

  .lg\:col-start-6 {
    grid-column-start: 6
  }

  .lg\:col-start-7 {
    grid-column-start: 7
  }

  .lg\:col-start-8 {
    grid-column-start: 8
  }

  .lg\:col-start-9 {
    grid-column-start: 9
  }

  .lg\:col-start-10 {
    grid-column-start: 10
  }

  .lg\:col-start-11 {
    grid-column-start: 11
  }

  .lg\:col-start-12 {
    grid-column-start: 12
  }

  .lg\:col-start-13 {
    grid-column-start: 13
  }

  .lg\:col-start-auto {
    grid-column-start: auto
  }

  .lg\:col-end-1 {
    grid-column-end: 1
  }

  .lg\:col-end-2 {
    grid-column-end: 2
  }

  .lg\:col-end-3 {
    grid-column-end: 3
  }

  .lg\:col-end-4 {
    grid-column-end: 4
  }

  .lg\:col-end-5 {
    grid-column-end: 5
  }

  .lg\:col-end-6 {
    grid-column-end: 6
  }

  .lg\:col-end-7 {
    grid-column-end: 7
  }

  .lg\:col-end-8 {
    grid-column-end: 8
  }

  .lg\:col-end-9 {
    grid-column-end: 9
  }

  .lg\:col-end-10 {
    grid-column-end: 10
  }

  .lg\:col-end-11 {
    grid-column-end: 11
  }

  .lg\:col-end-12 {
    grid-column-end: 12
  }

  .lg\:col-end-13 {
    grid-column-end: 13
  }

  .lg\:col-end-auto {
    grid-column-end: auto
  }

  .lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .lg\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .lg\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .lg\:grid-rows-none {
    grid-template-rows: none
  }

  .lg\:auto-rows-auto {
    grid-auto-rows: auto
  }

  .lg\:auto-rows-min {
    grid-auto-rows: min-content
  }

  .lg\:auto-rows-max {
    grid-auto-rows: max-content
  }

  .lg\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr)
  }

  .lg\:row-auto {
    grid-row: auto
  }

  .lg\:row-span-1 {
    grid-row: span 1 / span 1
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2
  }

  .lg\:row-span-3 {
    grid-row: span 3 / span 3
  }

  .lg\:row-span-4 {
    grid-row: span 4 / span 4
  }

  .lg\:row-span-5 {
    grid-row: span 5 / span 5
  }

  .lg\:row-span-6 {
    grid-row: span 6 / span 6
  }

  .lg\:row-span-full {
    grid-row: 1 / -1
  }

  .lg\:row-start-1 {
    grid-row-start: 1
  }

  .lg\:row-start-2 {
    grid-row-start: 2
  }

  .lg\:row-start-3 {
    grid-row-start: 3
  }

  .lg\:row-start-4 {
    grid-row-start: 4
  }

  .lg\:row-start-5 {
    grid-row-start: 5
  }

  .lg\:row-start-6 {
    grid-row-start: 6
  }

  .lg\:row-start-7 {
    grid-row-start: 7
  }

  .lg\:row-start-auto {
    grid-row-start: auto
  }

  .lg\:row-end-1 {
    grid-row-end: 1
  }

  .lg\:row-end-2 {
    grid-row-end: 2
  }

  .lg\:row-end-3 {
    grid-row-end: 3
  }

  .lg\:row-end-4 {
    grid-row-end: 4
  }

  .lg\:row-end-5 {
    grid-row-end: 5
  }

  .lg\:row-end-6 {
    grid-row-end: 6
  }

  .lg\:row-end-7 {
    grid-row-end: 7
  }

  .lg\:row-end-auto {
    grid-row-end: auto
  }

  .lg\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .lg\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .lg\:transform-none {
    transform: none
  }

  .lg\:origin-center {
    transform-origin: center
  }

  .lg\:origin-top {
    transform-origin: top
  }

  .lg\:origin-top-right {
    transform-origin: top right
  }

  .lg\:origin-right {
    transform-origin: right
  }

  .lg\:origin-bottom-right {
    transform-origin: bottom right
  }

  .lg\:origin-bottom {
    transform-origin: bottom
  }

  .lg\:origin-bottom-left {
    transform-origin: bottom left
  }

  .lg\:origin-left {
    transform-origin: left
  }

  .lg\:origin-top-left {
    transform-origin: top left
  }

  .lg\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .lg\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .lg\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .lg\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .lg\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .lg\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .lg\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .lg\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .lg\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .lg\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .lg\:scale-x-0 {
    --tw-scale-x: 0
  }

  .lg\:scale-x-50 {
    --tw-scale-x: .5
  }

  .lg\:scale-x-75 {
    --tw-scale-x: .75
  }

  .lg\:scale-x-90 {
    --tw-scale-x: .9
  }

  .lg\:scale-x-95 {
    --tw-scale-x: .95
  }

  .lg\:scale-x-100 {
    --tw-scale-x: 1
  }

  .lg\:scale-x-105 {
    --tw-scale-x: 1.05
  }

  .lg\:scale-x-110 {
    --tw-scale-x: 1.1
  }

  .lg\:scale-x-125 {
    --tw-scale-x: 1.25
  }

  .lg\:scale-x-150 {
    --tw-scale-x: 1.5
  }

  .lg\:scale-y-0 {
    --tw-scale-y: 0
  }

  .lg\:scale-y-50 {
    --tw-scale-y: .5
  }

  .lg\:scale-y-75 {
    --tw-scale-y: .75
  }

  .lg\:scale-y-90 {
    --tw-scale-y: .9
  }

  .lg\:scale-y-95 {
    --tw-scale-y: .95
  }

  .lg\:scale-y-100 {
    --tw-scale-y: 1
  }

  .lg\:scale-y-105 {
    --tw-scale-y: 1.05
  }

  .lg\:scale-y-110 {
    --tw-scale-y: 1.1
  }

  .lg\:scale-y-125 {
    --tw-scale-y: 1.25
  }

  .lg\:scale-y-150 {
    --tw-scale-y: 1.5
  }

  .lg\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .lg\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .lg\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .lg\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .lg\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .lg\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .lg\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .lg\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .lg\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .lg\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .lg\:hover\:scale-x-0:hover {
    --tw-scale-x: 0
  }

  .lg\:hover\:scale-x-50:hover {
    --tw-scale-x: .5
  }

  .lg\:hover\:scale-x-75:hover {
    --tw-scale-x: .75
  }

  .lg\:hover\:scale-x-90:hover {
    --tw-scale-x: .9
  }

  .lg\:hover\:scale-x-95:hover {
    --tw-scale-x: .95
  }

  .lg\:hover\:scale-x-100:hover {
    --tw-scale-x: 1
  }

  .lg\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05
  }

  .lg\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1
  }

  .lg\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25
  }

  .lg\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5
  }

  .lg\:hover\:scale-y-0:hover {
    --tw-scale-y: 0
  }

  .lg\:hover\:scale-y-50:hover {
    --tw-scale-y: .5
  }

  .lg\:hover\:scale-y-75:hover {
    --tw-scale-y: .75
  }

  .lg\:hover\:scale-y-90:hover {
    --tw-scale-y: .9
  }

  .lg\:hover\:scale-y-95:hover {
    --tw-scale-y: .95
  }

  .lg\:hover\:scale-y-100:hover {
    --tw-scale-y: 1
  }

  .lg\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05
  }

  .lg\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1
  }

  .lg\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25
  }

  .lg\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5
  }

  .lg\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .lg\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .lg\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .lg\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .lg\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .lg\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .lg\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .lg\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .lg\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .lg\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .lg\:focus\:scale-x-0:focus {
    --tw-scale-x: 0
  }

  .lg\:focus\:scale-x-50:focus {
    --tw-scale-x: .5
  }

  .lg\:focus\:scale-x-75:focus {
    --tw-scale-x: .75
  }

  .lg\:focus\:scale-x-90:focus {
    --tw-scale-x: .9
  }

  .lg\:focus\:scale-x-95:focus {
    --tw-scale-x: .95
  }

  .lg\:focus\:scale-x-100:focus {
    --tw-scale-x: 1
  }

  .lg\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05
  }

  .lg\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1
  }

  .lg\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25
  }

  .lg\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5
  }

  .lg\:focus\:scale-y-0:focus {
    --tw-scale-y: 0
  }

  .lg\:focus\:scale-y-50:focus {
    --tw-scale-y: .5
  }

  .lg\:focus\:scale-y-75:focus {
    --tw-scale-y: .75
  }

  .lg\:focus\:scale-y-90:focus {
    --tw-scale-y: .9
  }

  .lg\:focus\:scale-y-95:focus {
    --tw-scale-y: .95
  }

  .lg\:focus\:scale-y-100:focus {
    --tw-scale-y: 1
  }

  .lg\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05
  }

  .lg\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1
  }

  .lg\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25
  }

  .lg\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5
  }

  .lg\:rotate-0 {
    --tw-rotate: 0deg
  }

  .lg\:rotate-1 {
    --tw-rotate: 1deg
  }

  .lg\:rotate-2 {
    --tw-rotate: 2deg
  }

  .lg\:rotate-3 {
    --tw-rotate: 3deg
  }

  .lg\:rotate-6 {
    --tw-rotate: 6deg
  }

  .lg\:rotate-12 {
    --tw-rotate: 12deg
  }

  .lg\:rotate-45 {
    --tw-rotate: 45deg
  }

  .lg\:rotate-90 {
    --tw-rotate: 90deg
  }

  .lg\:rotate-180 {
    --tw-rotate: 180deg
  }

  .lg\:-rotate-180 {
    --tw-rotate: -180deg
  }

  .lg\:-rotate-90 {
    --tw-rotate: -90deg
  }

  .lg\:-rotate-45 {
    --tw-rotate: -45deg
  }

  .lg\:-rotate-12 {
    --tw-rotate: -12deg
  }

  .lg\:-rotate-6 {
    --tw-rotate: -6deg
  }

  .lg\:-rotate-3 {
    --tw-rotate: -3deg
  }

  .lg\:-rotate-2 {
    --tw-rotate: -2deg
  }

  .lg\:-rotate-1 {
    --tw-rotate: -1deg
  }

  .lg\:hover\:rotate-0:hover {
    --tw-rotate: 0deg
  }

  .lg\:hover\:rotate-1:hover {
    --tw-rotate: 1deg
  }

  .lg\:hover\:rotate-2:hover {
    --tw-rotate: 2deg
  }

  .lg\:hover\:rotate-3:hover {
    --tw-rotate: 3deg
  }

  .lg\:hover\:rotate-6:hover {
    --tw-rotate: 6deg
  }

  .lg\:hover\:rotate-12:hover {
    --tw-rotate: 12deg
  }

  .lg\:hover\:rotate-45:hover {
    --tw-rotate: 45deg
  }

  .lg\:hover\:rotate-90:hover {
    --tw-rotate: 90deg
  }

  .lg\:hover\:rotate-180:hover {
    --tw-rotate: 180deg
  }

  .lg\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg
  }

  .lg\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg
  }

  .lg\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg
  }

  .lg\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg
  }

  .lg\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg
  }

  .lg\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg
  }

  .lg\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg
  }

  .lg\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg
  }

  .lg\:focus\:rotate-0:focus {
    --tw-rotate: 0deg
  }

  .lg\:focus\:rotate-1:focus {
    --tw-rotate: 1deg
  }

  .lg\:focus\:rotate-2:focus {
    --tw-rotate: 2deg
  }

  .lg\:focus\:rotate-3:focus {
    --tw-rotate: 3deg
  }

  .lg\:focus\:rotate-6:focus {
    --tw-rotate: 6deg
  }

  .lg\:focus\:rotate-12:focus {
    --tw-rotate: 12deg
  }

  .lg\:focus\:rotate-45:focus {
    --tw-rotate: 45deg
  }

  .lg\:focus\:rotate-90:focus {
    --tw-rotate: 90deg
  }

  .lg\:focus\:rotate-180:focus {
    --tw-rotate: 180deg
  }

  .lg\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg
  }

  .lg\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg
  }

  .lg\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg
  }

  .lg\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg
  }

  .lg\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg
  }

  .lg\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg
  }

  .lg\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg
  }

  .lg\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg
  }

  .lg\:translate-x-0 {
    --tw-translate-x: 0
  }

  .lg\:translate-x-1 {
    --tw-translate-x: 0.1rem
  }

  .lg\:translate-x-2 {
    --tw-translate-x: 0.2rem
  }

  .lg\:translate-x-3 {
    --tw-translate-x: 0.3rem
  }

  .lg\:translate-x-4 {
    --tw-translate-x: 0.4rem
  }

  .lg\:translate-x-5 {
    --tw-translate-x: 0.5rem
  }

  .lg\:translate-x-6 {
    --tw-translate-x: 0.6rem
  }

  .lg\:translate-x-7 {
    --tw-translate-x: 0.7rem
  }

  .lg\:translate-x-8 {
    --tw-translate-x: 0.8rem
  }

  .lg\:translate-x-9 {
    --tw-translate-x: 0.9rem
  }

  .lg\:translate-x-10 {
    --tw-translate-x: 1.0rem
  }

  .lg\:translate-x-12 {
    --tw-translate-x: 1.2rem
  }

  .lg\:translate-x-14 {
    --tw-translate-x: 1.4rem
  }

  .lg\:translate-x-16 {
    --tw-translate-x: 1.6rem
  }

  .lg\:translate-x-20 {
    --tw-translate-x: 2rem
  }

  .lg\:translate-x-24 {
    --tw-translate-x: 2.4rem
  }

  .lg\:translate-x-28 {
    --tw-translate-x: 2.8rem
  }

  .lg\:translate-x-32 {
    --tw-translate-x: 3.2rem
  }

  .lg\:translate-x-36 {
    --tw-translate-x: 3.6rem
  }

  .lg\:translate-x-40 {
    --tw-translate-x: 4rem
  }

  .lg\:translate-x-44 {
    --tw-translate-x: 4.4rem
  }

  .lg\:translate-x-48 {
    --tw-translate-x: 4.8rem
  }

  .lg\:translate-x-52 {
    --tw-translate-x: 5.2rem
  }

  .lg\:translate-x-56 {
    --tw-translate-x: 5.6rem
  }

  .lg\:translate-x-60 {
    --tw-translate-x: 6rem
  }

  .lg\:translate-x-64 {
    --tw-translate-x: 6.4rem
  }

  .lg\:translate-x-68 {
    --tw-translate-x: 6.8rem
  }

  .lg\:translate-x-72 {
    --tw-translate-x: 7.2rem
  }

  .lg\:translate-x-76 {
    --tw-translate-x: 7.6rem
  }

  .lg\:translate-x-80 {
    --tw-translate-x: 8rem
  }

  .lg\:translate-x-84 {
    --tw-translate-x: 8.4rem
  }

  .lg\:translate-x-88 {
    --tw-translate-x: 8.8rem
  }

  .lg\:translate-x-92 {
    --tw-translate-x: 9.2rem
  }

  .lg\:translate-x-96 {
    --tw-translate-x: 9.6rem
  }

  .lg\:translate-x-128 {
    --tw-translate-x: 12.8rem
  }

  .lg\:translate-x-136 {
    --tw-translate-x: 13.6rem
  }

  .lg\:translate-x-160 {
    --tw-translate-x: 16rem
  }

  .lg\:translate-x-192 {
    --tw-translate-x: 19.2rem
  }

  .lg\:translate-x-200 {
    --tw-translate-x: 20rem
  }

  .lg\:translate-x-208 {
    --tw-translate-x: 20.8rem
  }

  .lg\:translate-x-216 {
    --tw-translate-x: 21.6rem
  }

  .lg\:translate-x-224 {
    --tw-translate-x: 22.4rem
  }

  .lg\:translate-x-256 {
    --tw-translate-x: 25.6rem
  }

  .lg\:translate-x-288 {
    --tw-translate-x: 28.8rem
  }

  .lg\:translate-x-320 {
    --tw-translate-x: 32rem
  }

  .lg\:translate-x-360 {
    --tw-translate-x: 36rem
  }

  .lg\:translate-x-384 {
    --tw-translate-x: 38.4rem
  }

  .lg\:translate-x-400 {
    --tw-translate-x: 40rem
  }

  .lg\:translate-x-512 {
    --tw-translate-x: 51.2rem
  }

  .lg\:translate-x-640 {
    --tw-translate-x: 64rem
  }

  .lg\:translate-x-xs {
    --tw-translate-x: 32rem
  }

  .lg\:translate-x-sm {
    --tw-translate-x: 48rem
  }

  .lg\:translate-x-md {
    --tw-translate-x: 64rem
  }

  .lg\:translate-x-lg {
    --tw-translate-x: 80rem
  }

  .lg\:translate-x-xl {
    --tw-translate-x: 96rem
  }

  .lg\:translate-x-2xl {
    --tw-translate-x: 112rem
  }

  .lg\:translate-x-3xl {
    --tw-translate-x: 128rem
  }

  .lg\:translate-x-4xl {
    --tw-translate-x: 144rem
  }

  .lg\:translate-x-5xl {
    --tw-translate-x: 160rem
  }

  .lg\:translate-x-px {
    --tw-translate-x: 1px
  }

  .lg\:translate-x-0\.5 {
    --tw-translate-x: 0.05rem
  }

  .lg\:translate-x-1\.5 {
    --tw-translate-x: 0.15rem
  }

  .lg\:translate-x-2\.5 {
    --tw-translate-x: 0.25rem
  }

  .lg\:translate-x-3\.5 {
    --tw-translate-x: 0.35rem
  }

  .lg\:-translate-x-1 {
    --tw-translate-x: -0.1rem
  }

  .lg\:-translate-x-2 {
    --tw-translate-x: -0.2rem
  }

  .lg\:-translate-x-3 {
    --tw-translate-x: -0.3rem
  }

  .lg\:-translate-x-4 {
    --tw-translate-x: -0.4rem
  }

  .lg\:-translate-x-5 {
    --tw-translate-x: -0.5rem
  }

  .lg\:-translate-x-6 {
    --tw-translate-x: -0.6rem
  }

  .lg\:-translate-x-7 {
    --tw-translate-x: -0.7rem
  }

  .lg\:-translate-x-8 {
    --tw-translate-x: -0.8rem
  }

  .lg\:-translate-x-9 {
    --tw-translate-x: -0.9rem
  }

  .lg\:-translate-x-10 {
    --tw-translate-x: -1rem
  }

  .lg\:-translate-x-12 {
    --tw-translate-x: -1.2rem
  }

  .lg\:-translate-x-14 {
    --tw-translate-x: -1.4rem
  }

  .lg\:-translate-x-16 {
    --tw-translate-x: -1.6rem
  }

  .lg\:-translate-x-20 {
    --tw-translate-x: -2rem
  }

  .lg\:-translate-x-24 {
    --tw-translate-x: -2.4rem
  }

  .lg\:-translate-x-28 {
    --tw-translate-x: -2.8rem
  }

  .lg\:-translate-x-32 {
    --tw-translate-x: -3.2rem
  }

  .lg\:-translate-x-36 {
    --tw-translate-x: -3.6rem
  }

  .lg\:-translate-x-40 {
    --tw-translate-x: -4rem
  }

  .lg\:-translate-x-44 {
    --tw-translate-x: -4.4rem
  }

  .lg\:-translate-x-48 {
    --tw-translate-x: -4.8rem
  }

  .lg\:-translate-x-52 {
    --tw-translate-x: -5.2rem
  }

  .lg\:-translate-x-56 {
    --tw-translate-x: -5.6rem
  }

  .lg\:-translate-x-60 {
    --tw-translate-x: -6rem
  }

  .lg\:-translate-x-64 {
    --tw-translate-x: -6.4rem
  }

  .lg\:-translate-x-68 {
    --tw-translate-x: -6.8rem
  }

  .lg\:-translate-x-72 {
    --tw-translate-x: -7.2rem
  }

  .lg\:-translate-x-76 {
    --tw-translate-x: -7.6rem
  }

  .lg\:-translate-x-80 {
    --tw-translate-x: -8rem
  }

  .lg\:-translate-x-84 {
    --tw-translate-x: -8.4rem
  }

  .lg\:-translate-x-88 {
    --tw-translate-x: -8.8rem
  }

  .lg\:-translate-x-92 {
    --tw-translate-x: -9.2rem
  }

  .lg\:-translate-x-96 {
    --tw-translate-x: -9.6rem
  }

  .lg\:-translate-x-128 {
    --tw-translate-x: -12.8rem
  }

  .lg\:-translate-x-136 {
    --tw-translate-x: -13.6rem
  }

  .lg\:-translate-x-160 {
    --tw-translate-x: -16rem
  }

  .lg\:-translate-x-192 {
    --tw-translate-x: -19.2rem
  }

  .lg\:-translate-x-200 {
    --tw-translate-x: -20rem
  }

  .lg\:-translate-x-208 {
    --tw-translate-x: -20.8rem
  }

  .lg\:-translate-x-216 {
    --tw-translate-x: -21.6rem
  }

  .lg\:-translate-x-224 {
    --tw-translate-x: -22.4rem
  }

  .lg\:-translate-x-256 {
    --tw-translate-x: -25.6rem
  }

  .lg\:-translate-x-288 {
    --tw-translate-x: -28.8rem
  }

  .lg\:-translate-x-320 {
    --tw-translate-x: -32rem
  }

  .lg\:-translate-x-360 {
    --tw-translate-x: -36rem
  }

  .lg\:-translate-x-384 {
    --tw-translate-x: -38.4rem
  }

  .lg\:-translate-x-400 {
    --tw-translate-x: -40rem
  }

  .lg\:-translate-x-512 {
    --tw-translate-x: -51.2rem
  }

  .lg\:-translate-x-640 {
    --tw-translate-x: -64rem
  }

  .lg\:-translate-x-xs {
    --tw-translate-x: -32rem
  }

  .lg\:-translate-x-sm {
    --tw-translate-x: -48rem
  }

  .lg\:-translate-x-md {
    --tw-translate-x: -64rem
  }

  .lg\:-translate-x-lg {
    --tw-translate-x: -80rem
  }

  .lg\:-translate-x-xl {
    --tw-translate-x: -96rem
  }

  .lg\:-translate-x-2xl {
    --tw-translate-x: -112rem
  }

  .lg\:-translate-x-3xl {
    --tw-translate-x: -128rem
  }

  .lg\:-translate-x-4xl {
    --tw-translate-x: -144rem
  }

  .lg\:-translate-x-5xl {
    --tw-translate-x: -160rem
  }

  .lg\:-translate-x-px {
    --tw-translate-x: -1px
  }

  .lg\:-translate-x-0\.5 {
    --tw-translate-x: -0.05rem
  }

  .lg\:-translate-x-1\.5 {
    --tw-translate-x: -0.15rem
  }

  .lg\:-translate-x-2\.5 {
    --tw-translate-x: -0.25rem
  }

  .lg\:-translate-x-3\.5 {
    --tw-translate-x: -0.35rem
  }

  .lg\:translate-x-1\/2 {
    --tw-translate-x: 50%
  }

  .lg\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%
  }

  .lg\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%
  }

  .lg\:translate-x-1\/4 {
    --tw-translate-x: 25%
  }

  .lg\:translate-x-2\/4 {
    --tw-translate-x: 50%
  }

  .lg\:translate-x-3\/4 {
    --tw-translate-x: 75%
  }

  .lg\:translate-x-full {
    --tw-translate-x: 100%
  }

  .lg\:-translate-x-1\/2 {
    --tw-translate-x: -50%
  }

  .lg\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%
  }

  .lg\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%
  }

  .lg\:-translate-x-1\/4 {
    --tw-translate-x: -25%
  }

  .lg\:-translate-x-2\/4 {
    --tw-translate-x: -50%
  }

  .lg\:-translate-x-3\/4 {
    --tw-translate-x: -75%
  }

  .lg\:-translate-x-full {
    --tw-translate-x: -100%
  }

  .lg\:translate-y-0 {
    --tw-translate-y: 0
  }

  .lg\:translate-y-1 {
    --tw-translate-y: 0.1rem
  }

  .lg\:translate-y-2 {
    --tw-translate-y: 0.2rem
  }

  .lg\:translate-y-3 {
    --tw-translate-y: 0.3rem
  }

  .lg\:translate-y-4 {
    --tw-translate-y: 0.4rem
  }

  .lg\:translate-y-5 {
    --tw-translate-y: 0.5rem
  }

  .lg\:translate-y-6 {
    --tw-translate-y: 0.6rem
  }

  .lg\:translate-y-7 {
    --tw-translate-y: 0.7rem
  }

  .lg\:translate-y-8 {
    --tw-translate-y: 0.8rem
  }

  .lg\:translate-y-9 {
    --tw-translate-y: 0.9rem
  }

  .lg\:translate-y-10 {
    --tw-translate-y: 1.0rem
  }

  .lg\:translate-y-12 {
    --tw-translate-y: 1.2rem
  }

  .lg\:translate-y-14 {
    --tw-translate-y: 1.4rem
  }

  .lg\:translate-y-16 {
    --tw-translate-y: 1.6rem
  }

  .lg\:translate-y-20 {
    --tw-translate-y: 2rem
  }

  .lg\:translate-y-24 {
    --tw-translate-y: 2.4rem
  }

  .lg\:translate-y-28 {
    --tw-translate-y: 2.8rem
  }

  .lg\:translate-y-32 {
    --tw-translate-y: 3.2rem
  }

  .lg\:translate-y-36 {
    --tw-translate-y: 3.6rem
  }

  .lg\:translate-y-40 {
    --tw-translate-y: 4rem
  }

  .lg\:translate-y-44 {
    --tw-translate-y: 4.4rem
  }

  .lg\:translate-y-48 {
    --tw-translate-y: 4.8rem
  }

  .lg\:translate-y-52 {
    --tw-translate-y: 5.2rem
  }

  .lg\:translate-y-56 {
    --tw-translate-y: 5.6rem
  }

  .lg\:translate-y-60 {
    --tw-translate-y: 6rem
  }

  .lg\:translate-y-64 {
    --tw-translate-y: 6.4rem
  }

  .lg\:translate-y-68 {
    --tw-translate-y: 6.8rem
  }

  .lg\:translate-y-72 {
    --tw-translate-y: 7.2rem
  }

  .lg\:translate-y-76 {
    --tw-translate-y: 7.6rem
  }

  .lg\:translate-y-80 {
    --tw-translate-y: 8rem
  }

  .lg\:translate-y-84 {
    --tw-translate-y: 8.4rem
  }

  .lg\:translate-y-88 {
    --tw-translate-y: 8.8rem
  }

  .lg\:translate-y-92 {
    --tw-translate-y: 9.2rem
  }

  .lg\:translate-y-96 {
    --tw-translate-y: 9.6rem
  }

  .lg\:translate-y-128 {
    --tw-translate-y: 12.8rem
  }

  .lg\:translate-y-136 {
    --tw-translate-y: 13.6rem
  }

  .lg\:translate-y-160 {
    --tw-translate-y: 16rem
  }

  .lg\:translate-y-192 {
    --tw-translate-y: 19.2rem
  }

  .lg\:translate-y-200 {
    --tw-translate-y: 20rem
  }

  .lg\:translate-y-208 {
    --tw-translate-y: 20.8rem
  }

  .lg\:translate-y-216 {
    --tw-translate-y: 21.6rem
  }

  .lg\:translate-y-224 {
    --tw-translate-y: 22.4rem
  }

  .lg\:translate-y-256 {
    --tw-translate-y: 25.6rem
  }

  .lg\:translate-y-288 {
    --tw-translate-y: 28.8rem
  }

  .lg\:translate-y-320 {
    --tw-translate-y: 32rem
  }

  .lg\:translate-y-360 {
    --tw-translate-y: 36rem
  }

  .lg\:translate-y-384 {
    --tw-translate-y: 38.4rem
  }

  .lg\:translate-y-400 {
    --tw-translate-y: 40rem
  }

  .lg\:translate-y-512 {
    --tw-translate-y: 51.2rem
  }

  .lg\:translate-y-640 {
    --tw-translate-y: 64rem
  }

  .lg\:translate-y-xs {
    --tw-translate-y: 32rem
  }

  .lg\:translate-y-sm {
    --tw-translate-y: 48rem
  }

  .lg\:translate-y-md {
    --tw-translate-y: 64rem
  }

  .lg\:translate-y-lg {
    --tw-translate-y: 80rem
  }

  .lg\:translate-y-xl {
    --tw-translate-y: 96rem
  }

  .lg\:translate-y-2xl {
    --tw-translate-y: 112rem
  }

  .lg\:translate-y-3xl {
    --tw-translate-y: 128rem
  }

  .lg\:translate-y-4xl {
    --tw-translate-y: 144rem
  }

  .lg\:translate-y-5xl {
    --tw-translate-y: 160rem
  }

  .lg\:translate-y-px {
    --tw-translate-y: 1px
  }

  .lg\:translate-y-0\.5 {
    --tw-translate-y: 0.05rem
  }

  .lg\:translate-y-1\.5 {
    --tw-translate-y: 0.15rem
  }

  .lg\:translate-y-2\.5 {
    --tw-translate-y: 0.25rem
  }

  .lg\:translate-y-3\.5 {
    --tw-translate-y: 0.35rem
  }

  .lg\:-translate-y-1 {
    --tw-translate-y: -0.1rem
  }

  .lg\:-translate-y-2 {
    --tw-translate-y: -0.2rem
  }

  .lg\:-translate-y-3 {
    --tw-translate-y: -0.3rem
  }

  .lg\:-translate-y-4 {
    --tw-translate-y: -0.4rem
  }

  .lg\:-translate-y-5 {
    --tw-translate-y: -0.5rem
  }

  .lg\:-translate-y-6 {
    --tw-translate-y: -0.6rem
  }

  .lg\:-translate-y-7 {
    --tw-translate-y: -0.7rem
  }

  .lg\:-translate-y-8 {
    --tw-translate-y: -0.8rem
  }

  .lg\:-translate-y-9 {
    --tw-translate-y: -0.9rem
  }

  .lg\:-translate-y-10 {
    --tw-translate-y: -1rem
  }

  .lg\:-translate-y-12 {
    --tw-translate-y: -1.2rem
  }

  .lg\:-translate-y-14 {
    --tw-translate-y: -1.4rem
  }

  .lg\:-translate-y-16 {
    --tw-translate-y: -1.6rem
  }

  .lg\:-translate-y-20 {
    --tw-translate-y: -2rem
  }

  .lg\:-translate-y-24 {
    --tw-translate-y: -2.4rem
  }

  .lg\:-translate-y-28 {
    --tw-translate-y: -2.8rem
  }

  .lg\:-translate-y-32 {
    --tw-translate-y: -3.2rem
  }

  .lg\:-translate-y-36 {
    --tw-translate-y: -3.6rem
  }

  .lg\:-translate-y-40 {
    --tw-translate-y: -4rem
  }

  .lg\:-translate-y-44 {
    --tw-translate-y: -4.4rem
  }

  .lg\:-translate-y-48 {
    --tw-translate-y: -4.8rem
  }

  .lg\:-translate-y-52 {
    --tw-translate-y: -5.2rem
  }

  .lg\:-translate-y-56 {
    --tw-translate-y: -5.6rem
  }

  .lg\:-translate-y-60 {
    --tw-translate-y: -6rem
  }

  .lg\:-translate-y-64 {
    --tw-translate-y: -6.4rem
  }

  .lg\:-translate-y-68 {
    --tw-translate-y: -6.8rem
  }

  .lg\:-translate-y-72 {
    --tw-translate-y: -7.2rem
  }

  .lg\:-translate-y-76 {
    --tw-translate-y: -7.6rem
  }

  .lg\:-translate-y-80 {
    --tw-translate-y: -8rem
  }

  .lg\:-translate-y-84 {
    --tw-translate-y: -8.4rem
  }

  .lg\:-translate-y-88 {
    --tw-translate-y: -8.8rem
  }

  .lg\:-translate-y-92 {
    --tw-translate-y: -9.2rem
  }

  .lg\:-translate-y-96 {
    --tw-translate-y: -9.6rem
  }

  .lg\:-translate-y-128 {
    --tw-translate-y: -12.8rem
  }

  .lg\:-translate-y-136 {
    --tw-translate-y: -13.6rem
  }

  .lg\:-translate-y-160 {
    --tw-translate-y: -16rem
  }

  .lg\:-translate-y-192 {
    --tw-translate-y: -19.2rem
  }

  .lg\:-translate-y-200 {
    --tw-translate-y: -20rem
  }

  .lg\:-translate-y-208 {
    --tw-translate-y: -20.8rem
  }

  .lg\:-translate-y-216 {
    --tw-translate-y: -21.6rem
  }

  .lg\:-translate-y-224 {
    --tw-translate-y: -22.4rem
  }

  .lg\:-translate-y-256 {
    --tw-translate-y: -25.6rem
  }

  .lg\:-translate-y-288 {
    --tw-translate-y: -28.8rem
  }

  .lg\:-translate-y-320 {
    --tw-translate-y: -32rem
  }

  .lg\:-translate-y-360 {
    --tw-translate-y: -36rem
  }

  .lg\:-translate-y-384 {
    --tw-translate-y: -38.4rem
  }

  .lg\:-translate-y-400 {
    --tw-translate-y: -40rem
  }

  .lg\:-translate-y-512 {
    --tw-translate-y: -51.2rem
  }

  .lg\:-translate-y-640 {
    --tw-translate-y: -64rem
  }

  .lg\:-translate-y-xs {
    --tw-translate-y: -32rem
  }

  .lg\:-translate-y-sm {
    --tw-translate-y: -48rem
  }

  .lg\:-translate-y-md {
    --tw-translate-y: -64rem
  }

  .lg\:-translate-y-lg {
    --tw-translate-y: -80rem
  }

  .lg\:-translate-y-xl {
    --tw-translate-y: -96rem
  }

  .lg\:-translate-y-2xl {
    --tw-translate-y: -112rem
  }

  .lg\:-translate-y-3xl {
    --tw-translate-y: -128rem
  }

  .lg\:-translate-y-4xl {
    --tw-translate-y: -144rem
  }

  .lg\:-translate-y-5xl {
    --tw-translate-y: -160rem
  }

  .lg\:-translate-y-px {
    --tw-translate-y: -1px
  }

  .lg\:-translate-y-0\.5 {
    --tw-translate-y: -0.05rem
  }

  .lg\:-translate-y-1\.5 {
    --tw-translate-y: -0.15rem
  }

  .lg\:-translate-y-2\.5 {
    --tw-translate-y: -0.25rem
  }

  .lg\:-translate-y-3\.5 {
    --tw-translate-y: -0.35rem
  }

  .lg\:translate-y-1\/2 {
    --tw-translate-y: 50%
  }

  .lg\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%
  }

  .lg\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%
  }

  .lg\:translate-y-1\/4 {
    --tw-translate-y: 25%
  }

  .lg\:translate-y-2\/4 {
    --tw-translate-y: 50%
  }

  .lg\:translate-y-3\/4 {
    --tw-translate-y: 75%
  }

  .lg\:translate-y-full {
    --tw-translate-y: 100%
  }

  .lg\:-translate-y-1\/2 {
    --tw-translate-y: -50%
  }

  .lg\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%
  }

  .lg\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%
  }

  .lg\:-translate-y-1\/4 {
    --tw-translate-y: -25%
  }

  .lg\:-translate-y-2\/4 {
    --tw-translate-y: -50%
  }

  .lg\:-translate-y-3\/4 {
    --tw-translate-y: -75%
  }

  .lg\:-translate-y-full {
    --tw-translate-y: -100%
  }

  .lg\:hover\:translate-x-0:hover {
    --tw-translate-x: 0
  }

  .lg\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.1rem
  }

  .lg\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.2rem
  }

  .lg\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.3rem
  }

  .lg\:hover\:translate-x-4:hover {
    --tw-translate-x: 0.4rem
  }

  .lg\:hover\:translate-x-5:hover {
    --tw-translate-x: 0.5rem
  }

  .lg\:hover\:translate-x-6:hover {
    --tw-translate-x: 0.6rem
  }

  .lg\:hover\:translate-x-7:hover {
    --tw-translate-x: 0.7rem
  }

  .lg\:hover\:translate-x-8:hover {
    --tw-translate-x: 0.8rem
  }

  .lg\:hover\:translate-x-9:hover {
    --tw-translate-x: 0.9rem
  }

  .lg\:hover\:translate-x-10:hover {
    --tw-translate-x: 1.0rem
  }

  .lg\:hover\:translate-x-12:hover {
    --tw-translate-x: 1.2rem
  }

  .lg\:hover\:translate-x-14:hover {
    --tw-translate-x: 1.4rem
  }

  .lg\:hover\:translate-x-16:hover {
    --tw-translate-x: 1.6rem
  }

  .lg\:hover\:translate-x-20:hover {
    --tw-translate-x: 2rem
  }

  .lg\:hover\:translate-x-24:hover {
    --tw-translate-x: 2.4rem
  }

  .lg\:hover\:translate-x-28:hover {
    --tw-translate-x: 2.8rem
  }

  .lg\:hover\:translate-x-32:hover {
    --tw-translate-x: 3.2rem
  }

  .lg\:hover\:translate-x-36:hover {
    --tw-translate-x: 3.6rem
  }

  .lg\:hover\:translate-x-40:hover {
    --tw-translate-x: 4rem
  }

  .lg\:hover\:translate-x-44:hover {
    --tw-translate-x: 4.4rem
  }

  .lg\:hover\:translate-x-48:hover {
    --tw-translate-x: 4.8rem
  }

  .lg\:hover\:translate-x-52:hover {
    --tw-translate-x: 5.2rem
  }

  .lg\:hover\:translate-x-56:hover {
    --tw-translate-x: 5.6rem
  }

  .lg\:hover\:translate-x-60:hover {
    --tw-translate-x: 6rem
  }

  .lg\:hover\:translate-x-64:hover {
    --tw-translate-x: 6.4rem
  }

  .lg\:hover\:translate-x-68:hover {
    --tw-translate-x: 6.8rem
  }

  .lg\:hover\:translate-x-72:hover {
    --tw-translate-x: 7.2rem
  }

  .lg\:hover\:translate-x-76:hover {
    --tw-translate-x: 7.6rem
  }

  .lg\:hover\:translate-x-80:hover {
    --tw-translate-x: 8rem
  }

  .lg\:hover\:translate-x-84:hover {
    --tw-translate-x: 8.4rem
  }

  .lg\:hover\:translate-x-88:hover {
    --tw-translate-x: 8.8rem
  }

  .lg\:hover\:translate-x-92:hover {
    --tw-translate-x: 9.2rem
  }

  .lg\:hover\:translate-x-96:hover {
    --tw-translate-x: 9.6rem
  }

  .lg\:hover\:translate-x-128:hover {
    --tw-translate-x: 12.8rem
  }

  .lg\:hover\:translate-x-136:hover {
    --tw-translate-x: 13.6rem
  }

  .lg\:hover\:translate-x-160:hover {
    --tw-translate-x: 16rem
  }

  .lg\:hover\:translate-x-192:hover {
    --tw-translate-x: 19.2rem
  }

  .lg\:hover\:translate-x-200:hover {
    --tw-translate-x: 20rem
  }

  .lg\:hover\:translate-x-208:hover {
    --tw-translate-x: 20.8rem
  }

  .lg\:hover\:translate-x-216:hover {
    --tw-translate-x: 21.6rem
  }

  .lg\:hover\:translate-x-224:hover {
    --tw-translate-x: 22.4rem
  }

  .lg\:hover\:translate-x-256:hover {
    --tw-translate-x: 25.6rem
  }

  .lg\:hover\:translate-x-288:hover {
    --tw-translate-x: 28.8rem
  }

  .lg\:hover\:translate-x-320:hover {
    --tw-translate-x: 32rem
  }

  .lg\:hover\:translate-x-360:hover {
    --tw-translate-x: 36rem
  }

  .lg\:hover\:translate-x-384:hover {
    --tw-translate-x: 38.4rem
  }

  .lg\:hover\:translate-x-400:hover {
    --tw-translate-x: 40rem
  }

  .lg\:hover\:translate-x-512:hover {
    --tw-translate-x: 51.2rem
  }

  .lg\:hover\:translate-x-640:hover {
    --tw-translate-x: 64rem
  }

  .lg\:hover\:translate-x-xs:hover {
    --tw-translate-x: 32rem
  }

  .lg\:hover\:translate-x-sm:hover {
    --tw-translate-x: 48rem
  }

  .lg\:hover\:translate-x-md:hover {
    --tw-translate-x: 64rem
  }

  .lg\:hover\:translate-x-lg:hover {
    --tw-translate-x: 80rem
  }

  .lg\:hover\:translate-x-xl:hover {
    --tw-translate-x: 96rem
  }

  .lg\:hover\:translate-x-2xl:hover {
    --tw-translate-x: 112rem
  }

  .lg\:hover\:translate-x-3xl:hover {
    --tw-translate-x: 128rem
  }

  .lg\:hover\:translate-x-4xl:hover {
    --tw-translate-x: 144rem
  }

  .lg\:hover\:translate-x-5xl:hover {
    --tw-translate-x: 160rem
  }

  .lg\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px
  }

  .lg\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.05rem
  }

  .lg\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.15rem
  }

  .lg\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.25rem
  }

  .lg\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.35rem
  }

  .lg\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.1rem
  }

  .lg\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.2rem
  }

  .lg\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.3rem
  }

  .lg\:hover\:-translate-x-4:hover {
    --tw-translate-x: -0.4rem
  }

  .lg\:hover\:-translate-x-5:hover {
    --tw-translate-x: -0.5rem
  }

  .lg\:hover\:-translate-x-6:hover {
    --tw-translate-x: -0.6rem
  }

  .lg\:hover\:-translate-x-7:hover {
    --tw-translate-x: -0.7rem
  }

  .lg\:hover\:-translate-x-8:hover {
    --tw-translate-x: -0.8rem
  }

  .lg\:hover\:-translate-x-9:hover {
    --tw-translate-x: -0.9rem
  }

  .lg\:hover\:-translate-x-10:hover {
    --tw-translate-x: -1rem
  }

  .lg\:hover\:-translate-x-12:hover {
    --tw-translate-x: -1.2rem
  }

  .lg\:hover\:-translate-x-14:hover {
    --tw-translate-x: -1.4rem
  }

  .lg\:hover\:-translate-x-16:hover {
    --tw-translate-x: -1.6rem
  }

  .lg\:hover\:-translate-x-20:hover {
    --tw-translate-x: -2rem
  }

  .lg\:hover\:-translate-x-24:hover {
    --tw-translate-x: -2.4rem
  }

  .lg\:hover\:-translate-x-28:hover {
    --tw-translate-x: -2.8rem
  }

  .lg\:hover\:-translate-x-32:hover {
    --tw-translate-x: -3.2rem
  }

  .lg\:hover\:-translate-x-36:hover {
    --tw-translate-x: -3.6rem
  }

  .lg\:hover\:-translate-x-40:hover {
    --tw-translate-x: -4rem
  }

  .lg\:hover\:-translate-x-44:hover {
    --tw-translate-x: -4.4rem
  }

  .lg\:hover\:-translate-x-48:hover {
    --tw-translate-x: -4.8rem
  }

  .lg\:hover\:-translate-x-52:hover {
    --tw-translate-x: -5.2rem
  }

  .lg\:hover\:-translate-x-56:hover {
    --tw-translate-x: -5.6rem
  }

  .lg\:hover\:-translate-x-60:hover {
    --tw-translate-x: -6rem
  }

  .lg\:hover\:-translate-x-64:hover {
    --tw-translate-x: -6.4rem
  }

  .lg\:hover\:-translate-x-68:hover {
    --tw-translate-x: -6.8rem
  }

  .lg\:hover\:-translate-x-72:hover {
    --tw-translate-x: -7.2rem
  }

  .lg\:hover\:-translate-x-76:hover {
    --tw-translate-x: -7.6rem
  }

  .lg\:hover\:-translate-x-80:hover {
    --tw-translate-x: -8rem
  }

  .lg\:hover\:-translate-x-84:hover {
    --tw-translate-x: -8.4rem
  }

  .lg\:hover\:-translate-x-88:hover {
    --tw-translate-x: -8.8rem
  }

  .lg\:hover\:-translate-x-92:hover {
    --tw-translate-x: -9.2rem
  }

  .lg\:hover\:-translate-x-96:hover {
    --tw-translate-x: -9.6rem
  }

  .lg\:hover\:-translate-x-128:hover {
    --tw-translate-x: -12.8rem
  }

  .lg\:hover\:-translate-x-136:hover {
    --tw-translate-x: -13.6rem
  }

  .lg\:hover\:-translate-x-160:hover {
    --tw-translate-x: -16rem
  }

  .lg\:hover\:-translate-x-192:hover {
    --tw-translate-x: -19.2rem
  }

  .lg\:hover\:-translate-x-200:hover {
    --tw-translate-x: -20rem
  }

  .lg\:hover\:-translate-x-208:hover {
    --tw-translate-x: -20.8rem
  }

  .lg\:hover\:-translate-x-216:hover {
    --tw-translate-x: -21.6rem
  }

  .lg\:hover\:-translate-x-224:hover {
    --tw-translate-x: -22.4rem
  }

  .lg\:hover\:-translate-x-256:hover {
    --tw-translate-x: -25.6rem
  }

  .lg\:hover\:-translate-x-288:hover {
    --tw-translate-x: -28.8rem
  }

  .lg\:hover\:-translate-x-320:hover {
    --tw-translate-x: -32rem
  }

  .lg\:hover\:-translate-x-360:hover {
    --tw-translate-x: -36rem
  }

  .lg\:hover\:-translate-x-384:hover {
    --tw-translate-x: -38.4rem
  }

  .lg\:hover\:-translate-x-400:hover {
    --tw-translate-x: -40rem
  }

  .lg\:hover\:-translate-x-512:hover {
    --tw-translate-x: -51.2rem
  }

  .lg\:hover\:-translate-x-640:hover {
    --tw-translate-x: -64rem
  }

  .lg\:hover\:-translate-x-xs:hover {
    --tw-translate-x: -32rem
  }

  .lg\:hover\:-translate-x-sm:hover {
    --tw-translate-x: -48rem
  }

  .lg\:hover\:-translate-x-md:hover {
    --tw-translate-x: -64rem
  }

  .lg\:hover\:-translate-x-lg:hover {
    --tw-translate-x: -80rem
  }

  .lg\:hover\:-translate-x-xl:hover {
    --tw-translate-x: -96rem
  }

  .lg\:hover\:-translate-x-2xl:hover {
    --tw-translate-x: -112rem
  }

  .lg\:hover\:-translate-x-3xl:hover {
    --tw-translate-x: -128rem
  }

  .lg\:hover\:-translate-x-4xl:hover {
    --tw-translate-x: -144rem
  }

  .lg\:hover\:-translate-x-5xl:hover {
    --tw-translate-x: -160rem
  }

  .lg\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px
  }

  .lg\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.05rem
  }

  .lg\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.15rem
  }

  .lg\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.25rem
  }

  .lg\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.35rem
  }

  .lg\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%
  }

  .lg\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%
  }

  .lg\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%
  }

  .lg\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%
  }

  .lg\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%
  }

  .lg\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%
  }

  .lg\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%
  }

  .lg\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%
  }

  .lg\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%
  }

  .lg\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%
  }

  .lg\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%
  }

  .lg\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%
  }

  .lg\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%
  }

  .lg\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%
  }

  .lg\:hover\:translate-y-0:hover {
    --tw-translate-y: 0
  }

  .lg\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.1rem
  }

  .lg\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.2rem
  }

  .lg\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.3rem
  }

  .lg\:hover\:translate-y-4:hover {
    --tw-translate-y: 0.4rem
  }

  .lg\:hover\:translate-y-5:hover {
    --tw-translate-y: 0.5rem
  }

  .lg\:hover\:translate-y-6:hover {
    --tw-translate-y: 0.6rem
  }

  .lg\:hover\:translate-y-7:hover {
    --tw-translate-y: 0.7rem
  }

  .lg\:hover\:translate-y-8:hover {
    --tw-translate-y: 0.8rem
  }

  .lg\:hover\:translate-y-9:hover {
    --tw-translate-y: 0.9rem
  }

  .lg\:hover\:translate-y-10:hover {
    --tw-translate-y: 1.0rem
  }

  .lg\:hover\:translate-y-12:hover {
    --tw-translate-y: 1.2rem
  }

  .lg\:hover\:translate-y-14:hover {
    --tw-translate-y: 1.4rem
  }

  .lg\:hover\:translate-y-16:hover {
    --tw-translate-y: 1.6rem
  }

  .lg\:hover\:translate-y-20:hover {
    --tw-translate-y: 2rem
  }

  .lg\:hover\:translate-y-24:hover {
    --tw-translate-y: 2.4rem
  }

  .lg\:hover\:translate-y-28:hover {
    --tw-translate-y: 2.8rem
  }

  .lg\:hover\:translate-y-32:hover {
    --tw-translate-y: 3.2rem
  }

  .lg\:hover\:translate-y-36:hover {
    --tw-translate-y: 3.6rem
  }

  .lg\:hover\:translate-y-40:hover {
    --tw-translate-y: 4rem
  }

  .lg\:hover\:translate-y-44:hover {
    --tw-translate-y: 4.4rem
  }

  .lg\:hover\:translate-y-48:hover {
    --tw-translate-y: 4.8rem
  }

  .lg\:hover\:translate-y-52:hover {
    --tw-translate-y: 5.2rem
  }

  .lg\:hover\:translate-y-56:hover {
    --tw-translate-y: 5.6rem
  }

  .lg\:hover\:translate-y-60:hover {
    --tw-translate-y: 6rem
  }

  .lg\:hover\:translate-y-64:hover {
    --tw-translate-y: 6.4rem
  }

  .lg\:hover\:translate-y-68:hover {
    --tw-translate-y: 6.8rem
  }

  .lg\:hover\:translate-y-72:hover {
    --tw-translate-y: 7.2rem
  }

  .lg\:hover\:translate-y-76:hover {
    --tw-translate-y: 7.6rem
  }

  .lg\:hover\:translate-y-80:hover {
    --tw-translate-y: 8rem
  }

  .lg\:hover\:translate-y-84:hover {
    --tw-translate-y: 8.4rem
  }

  .lg\:hover\:translate-y-88:hover {
    --tw-translate-y: 8.8rem
  }

  .lg\:hover\:translate-y-92:hover {
    --tw-translate-y: 9.2rem
  }

  .lg\:hover\:translate-y-96:hover {
    --tw-translate-y: 9.6rem
  }

  .lg\:hover\:translate-y-128:hover {
    --tw-translate-y: 12.8rem
  }

  .lg\:hover\:translate-y-136:hover {
    --tw-translate-y: 13.6rem
  }

  .lg\:hover\:translate-y-160:hover {
    --tw-translate-y: 16rem
  }

  .lg\:hover\:translate-y-192:hover {
    --tw-translate-y: 19.2rem
  }

  .lg\:hover\:translate-y-200:hover {
    --tw-translate-y: 20rem
  }

  .lg\:hover\:translate-y-208:hover {
    --tw-translate-y: 20.8rem
  }

  .lg\:hover\:translate-y-216:hover {
    --tw-translate-y: 21.6rem
  }

  .lg\:hover\:translate-y-224:hover {
    --tw-translate-y: 22.4rem
  }

  .lg\:hover\:translate-y-256:hover {
    --tw-translate-y: 25.6rem
  }

  .lg\:hover\:translate-y-288:hover {
    --tw-translate-y: 28.8rem
  }

  .lg\:hover\:translate-y-320:hover {
    --tw-translate-y: 32rem
  }

  .lg\:hover\:translate-y-360:hover {
    --tw-translate-y: 36rem
  }

  .lg\:hover\:translate-y-384:hover {
    --tw-translate-y: 38.4rem
  }

  .lg\:hover\:translate-y-400:hover {
    --tw-translate-y: 40rem
  }

  .lg\:hover\:translate-y-512:hover {
    --tw-translate-y: 51.2rem
  }

  .lg\:hover\:translate-y-640:hover {
    --tw-translate-y: 64rem
  }

  .lg\:hover\:translate-y-xs:hover {
    --tw-translate-y: 32rem
  }

  .lg\:hover\:translate-y-sm:hover {
    --tw-translate-y: 48rem
  }

  .lg\:hover\:translate-y-md:hover {
    --tw-translate-y: 64rem
  }

  .lg\:hover\:translate-y-lg:hover {
    --tw-translate-y: 80rem
  }

  .lg\:hover\:translate-y-xl:hover {
    --tw-translate-y: 96rem
  }

  .lg\:hover\:translate-y-2xl:hover {
    --tw-translate-y: 112rem
  }

  .lg\:hover\:translate-y-3xl:hover {
    --tw-translate-y: 128rem
  }

  .lg\:hover\:translate-y-4xl:hover {
    --tw-translate-y: 144rem
  }

  .lg\:hover\:translate-y-5xl:hover {
    --tw-translate-y: 160rem
  }

  .lg\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px
  }

  .lg\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.05rem
  }

  .lg\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.15rem
  }

  .lg\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.25rem
  }

  .lg\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.35rem
  }

  .lg\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.1rem
  }

  .lg\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.2rem
  }

  .lg\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.3rem
  }

  .lg\:hover\:-translate-y-4:hover {
    --tw-translate-y: -0.4rem
  }

  .lg\:hover\:-translate-y-5:hover {
    --tw-translate-y: -0.5rem
  }

  .lg\:hover\:-translate-y-6:hover {
    --tw-translate-y: -0.6rem
  }

  .lg\:hover\:-translate-y-7:hover {
    --tw-translate-y: -0.7rem
  }

  .lg\:hover\:-translate-y-8:hover {
    --tw-translate-y: -0.8rem
  }

  .lg\:hover\:-translate-y-9:hover {
    --tw-translate-y: -0.9rem
  }

  .lg\:hover\:-translate-y-10:hover {
    --tw-translate-y: -1rem
  }

  .lg\:hover\:-translate-y-12:hover {
    --tw-translate-y: -1.2rem
  }

  .lg\:hover\:-translate-y-14:hover {
    --tw-translate-y: -1.4rem
  }

  .lg\:hover\:-translate-y-16:hover {
    --tw-translate-y: -1.6rem
  }

  .lg\:hover\:-translate-y-20:hover {
    --tw-translate-y: -2rem
  }

  .lg\:hover\:-translate-y-24:hover {
    --tw-translate-y: -2.4rem
  }

  .lg\:hover\:-translate-y-28:hover {
    --tw-translate-y: -2.8rem
  }

  .lg\:hover\:-translate-y-32:hover {
    --tw-translate-y: -3.2rem
  }

  .lg\:hover\:-translate-y-36:hover {
    --tw-translate-y: -3.6rem
  }

  .lg\:hover\:-translate-y-40:hover {
    --tw-translate-y: -4rem
  }

  .lg\:hover\:-translate-y-44:hover {
    --tw-translate-y: -4.4rem
  }

  .lg\:hover\:-translate-y-48:hover {
    --tw-translate-y: -4.8rem
  }

  .lg\:hover\:-translate-y-52:hover {
    --tw-translate-y: -5.2rem
  }

  .lg\:hover\:-translate-y-56:hover {
    --tw-translate-y: -5.6rem
  }

  .lg\:hover\:-translate-y-60:hover {
    --tw-translate-y: -6rem
  }

  .lg\:hover\:-translate-y-64:hover {
    --tw-translate-y: -6.4rem
  }

  .lg\:hover\:-translate-y-68:hover {
    --tw-translate-y: -6.8rem
  }

  .lg\:hover\:-translate-y-72:hover {
    --tw-translate-y: -7.2rem
  }

  .lg\:hover\:-translate-y-76:hover {
    --tw-translate-y: -7.6rem
  }

  .lg\:hover\:-translate-y-80:hover {
    --tw-translate-y: -8rem
  }

  .lg\:hover\:-translate-y-84:hover {
    --tw-translate-y: -8.4rem
  }

  .lg\:hover\:-translate-y-88:hover {
    --tw-translate-y: -8.8rem
  }

  .lg\:hover\:-translate-y-92:hover {
    --tw-translate-y: -9.2rem
  }

  .lg\:hover\:-translate-y-96:hover {
    --tw-translate-y: -9.6rem
  }

  .lg\:hover\:-translate-y-128:hover {
    --tw-translate-y: -12.8rem
  }

  .lg\:hover\:-translate-y-136:hover {
    --tw-translate-y: -13.6rem
  }

  .lg\:hover\:-translate-y-160:hover {
    --tw-translate-y: -16rem
  }

  .lg\:hover\:-translate-y-192:hover {
    --tw-translate-y: -19.2rem
  }

  .lg\:hover\:-translate-y-200:hover {
    --tw-translate-y: -20rem
  }

  .lg\:hover\:-translate-y-208:hover {
    --tw-translate-y: -20.8rem
  }

  .lg\:hover\:-translate-y-216:hover {
    --tw-translate-y: -21.6rem
  }

  .lg\:hover\:-translate-y-224:hover {
    --tw-translate-y: -22.4rem
  }

  .lg\:hover\:-translate-y-256:hover {
    --tw-translate-y: -25.6rem
  }

  .lg\:hover\:-translate-y-288:hover {
    --tw-translate-y: -28.8rem
  }

  .lg\:hover\:-translate-y-320:hover {
    --tw-translate-y: -32rem
  }

  .lg\:hover\:-translate-y-360:hover {
    --tw-translate-y: -36rem
  }

  .lg\:hover\:-translate-y-384:hover {
    --tw-translate-y: -38.4rem
  }

  .lg\:hover\:-translate-y-400:hover {
    --tw-translate-y: -40rem
  }

  .lg\:hover\:-translate-y-512:hover {
    --tw-translate-y: -51.2rem
  }

  .lg\:hover\:-translate-y-640:hover {
    --tw-translate-y: -64rem
  }

  .lg\:hover\:-translate-y-xs:hover {
    --tw-translate-y: -32rem
  }

  .lg\:hover\:-translate-y-sm:hover {
    --tw-translate-y: -48rem
  }

  .lg\:hover\:-translate-y-md:hover {
    --tw-translate-y: -64rem
  }

  .lg\:hover\:-translate-y-lg:hover {
    --tw-translate-y: -80rem
  }

  .lg\:hover\:-translate-y-xl:hover {
    --tw-translate-y: -96rem
  }

  .lg\:hover\:-translate-y-2xl:hover {
    --tw-translate-y: -112rem
  }

  .lg\:hover\:-translate-y-3xl:hover {
    --tw-translate-y: -128rem
  }

  .lg\:hover\:-translate-y-4xl:hover {
    --tw-translate-y: -144rem
  }

  .lg\:hover\:-translate-y-5xl:hover {
    --tw-translate-y: -160rem
  }

  .lg\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px
  }

  .lg\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.05rem
  }

  .lg\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.15rem
  }

  .lg\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.25rem
  }

  .lg\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.35rem
  }

  .lg\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%
  }

  .lg\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%
  }

  .lg\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%
  }

  .lg\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%
  }

  .lg\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%
  }

  .lg\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%
  }

  .lg\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%
  }

  .lg\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%
  }

  .lg\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%
  }

  .lg\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%
  }

  .lg\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%
  }

  .lg\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%
  }

  .lg\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%
  }

  .lg\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%
  }

  .lg\:focus\:translate-x-0:focus {
    --tw-translate-x: 0
  }

  .lg\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.1rem
  }

  .lg\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.2rem
  }

  .lg\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.3rem
  }

  .lg\:focus\:translate-x-4:focus {
    --tw-translate-x: 0.4rem
  }

  .lg\:focus\:translate-x-5:focus {
    --tw-translate-x: 0.5rem
  }

  .lg\:focus\:translate-x-6:focus {
    --tw-translate-x: 0.6rem
  }

  .lg\:focus\:translate-x-7:focus {
    --tw-translate-x: 0.7rem
  }

  .lg\:focus\:translate-x-8:focus {
    --tw-translate-x: 0.8rem
  }

  .lg\:focus\:translate-x-9:focus {
    --tw-translate-x: 0.9rem
  }

  .lg\:focus\:translate-x-10:focus {
    --tw-translate-x: 1.0rem
  }

  .lg\:focus\:translate-x-12:focus {
    --tw-translate-x: 1.2rem
  }

  .lg\:focus\:translate-x-14:focus {
    --tw-translate-x: 1.4rem
  }

  .lg\:focus\:translate-x-16:focus {
    --tw-translate-x: 1.6rem
  }

  .lg\:focus\:translate-x-20:focus {
    --tw-translate-x: 2rem
  }

  .lg\:focus\:translate-x-24:focus {
    --tw-translate-x: 2.4rem
  }

  .lg\:focus\:translate-x-28:focus {
    --tw-translate-x: 2.8rem
  }

  .lg\:focus\:translate-x-32:focus {
    --tw-translate-x: 3.2rem
  }

  .lg\:focus\:translate-x-36:focus {
    --tw-translate-x: 3.6rem
  }

  .lg\:focus\:translate-x-40:focus {
    --tw-translate-x: 4rem
  }

  .lg\:focus\:translate-x-44:focus {
    --tw-translate-x: 4.4rem
  }

  .lg\:focus\:translate-x-48:focus {
    --tw-translate-x: 4.8rem
  }

  .lg\:focus\:translate-x-52:focus {
    --tw-translate-x: 5.2rem
  }

  .lg\:focus\:translate-x-56:focus {
    --tw-translate-x: 5.6rem
  }

  .lg\:focus\:translate-x-60:focus {
    --tw-translate-x: 6rem
  }

  .lg\:focus\:translate-x-64:focus {
    --tw-translate-x: 6.4rem
  }

  .lg\:focus\:translate-x-68:focus {
    --tw-translate-x: 6.8rem
  }

  .lg\:focus\:translate-x-72:focus {
    --tw-translate-x: 7.2rem
  }

  .lg\:focus\:translate-x-76:focus {
    --tw-translate-x: 7.6rem
  }

  .lg\:focus\:translate-x-80:focus {
    --tw-translate-x: 8rem
  }

  .lg\:focus\:translate-x-84:focus {
    --tw-translate-x: 8.4rem
  }

  .lg\:focus\:translate-x-88:focus {
    --tw-translate-x: 8.8rem
  }

  .lg\:focus\:translate-x-92:focus {
    --tw-translate-x: 9.2rem
  }

  .lg\:focus\:translate-x-96:focus {
    --tw-translate-x: 9.6rem
  }

  .lg\:focus\:translate-x-128:focus {
    --tw-translate-x: 12.8rem
  }

  .lg\:focus\:translate-x-136:focus {
    --tw-translate-x: 13.6rem
  }

  .lg\:focus\:translate-x-160:focus {
    --tw-translate-x: 16rem
  }

  .lg\:focus\:translate-x-192:focus {
    --tw-translate-x: 19.2rem
  }

  .lg\:focus\:translate-x-200:focus {
    --tw-translate-x: 20rem
  }

  .lg\:focus\:translate-x-208:focus {
    --tw-translate-x: 20.8rem
  }

  .lg\:focus\:translate-x-216:focus {
    --tw-translate-x: 21.6rem
  }

  .lg\:focus\:translate-x-224:focus {
    --tw-translate-x: 22.4rem
  }

  .lg\:focus\:translate-x-256:focus {
    --tw-translate-x: 25.6rem
  }

  .lg\:focus\:translate-x-288:focus {
    --tw-translate-x: 28.8rem
  }

  .lg\:focus\:translate-x-320:focus {
    --tw-translate-x: 32rem
  }

  .lg\:focus\:translate-x-360:focus {
    --tw-translate-x: 36rem
  }

  .lg\:focus\:translate-x-384:focus {
    --tw-translate-x: 38.4rem
  }

  .lg\:focus\:translate-x-400:focus {
    --tw-translate-x: 40rem
  }

  .lg\:focus\:translate-x-512:focus {
    --tw-translate-x: 51.2rem
  }

  .lg\:focus\:translate-x-640:focus {
    --tw-translate-x: 64rem
  }

  .lg\:focus\:translate-x-xs:focus {
    --tw-translate-x: 32rem
  }

  .lg\:focus\:translate-x-sm:focus {
    --tw-translate-x: 48rem
  }

  .lg\:focus\:translate-x-md:focus {
    --tw-translate-x: 64rem
  }

  .lg\:focus\:translate-x-lg:focus {
    --tw-translate-x: 80rem
  }

  .lg\:focus\:translate-x-xl:focus {
    --tw-translate-x: 96rem
  }

  .lg\:focus\:translate-x-2xl:focus {
    --tw-translate-x: 112rem
  }

  .lg\:focus\:translate-x-3xl:focus {
    --tw-translate-x: 128rem
  }

  .lg\:focus\:translate-x-4xl:focus {
    --tw-translate-x: 144rem
  }

  .lg\:focus\:translate-x-5xl:focus {
    --tw-translate-x: 160rem
  }

  .lg\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px
  }

  .lg\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.05rem
  }

  .lg\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.15rem
  }

  .lg\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.25rem
  }

  .lg\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.35rem
  }

  .lg\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.1rem
  }

  .lg\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.2rem
  }

  .lg\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.3rem
  }

  .lg\:focus\:-translate-x-4:focus {
    --tw-translate-x: -0.4rem
  }

  .lg\:focus\:-translate-x-5:focus {
    --tw-translate-x: -0.5rem
  }

  .lg\:focus\:-translate-x-6:focus {
    --tw-translate-x: -0.6rem
  }

  .lg\:focus\:-translate-x-7:focus {
    --tw-translate-x: -0.7rem
  }

  .lg\:focus\:-translate-x-8:focus {
    --tw-translate-x: -0.8rem
  }

  .lg\:focus\:-translate-x-9:focus {
    --tw-translate-x: -0.9rem
  }

  .lg\:focus\:-translate-x-10:focus {
    --tw-translate-x: -1rem
  }

  .lg\:focus\:-translate-x-12:focus {
    --tw-translate-x: -1.2rem
  }

  .lg\:focus\:-translate-x-14:focus {
    --tw-translate-x: -1.4rem
  }

  .lg\:focus\:-translate-x-16:focus {
    --tw-translate-x: -1.6rem
  }

  .lg\:focus\:-translate-x-20:focus {
    --tw-translate-x: -2rem
  }

  .lg\:focus\:-translate-x-24:focus {
    --tw-translate-x: -2.4rem
  }

  .lg\:focus\:-translate-x-28:focus {
    --tw-translate-x: -2.8rem
  }

  .lg\:focus\:-translate-x-32:focus {
    --tw-translate-x: -3.2rem
  }

  .lg\:focus\:-translate-x-36:focus {
    --tw-translate-x: -3.6rem
  }

  .lg\:focus\:-translate-x-40:focus {
    --tw-translate-x: -4rem
  }

  .lg\:focus\:-translate-x-44:focus {
    --tw-translate-x: -4.4rem
  }

  .lg\:focus\:-translate-x-48:focus {
    --tw-translate-x: -4.8rem
  }

  .lg\:focus\:-translate-x-52:focus {
    --tw-translate-x: -5.2rem
  }

  .lg\:focus\:-translate-x-56:focus {
    --tw-translate-x: -5.6rem
  }

  .lg\:focus\:-translate-x-60:focus {
    --tw-translate-x: -6rem
  }

  .lg\:focus\:-translate-x-64:focus {
    --tw-translate-x: -6.4rem
  }

  .lg\:focus\:-translate-x-68:focus {
    --tw-translate-x: -6.8rem
  }

  .lg\:focus\:-translate-x-72:focus {
    --tw-translate-x: -7.2rem
  }

  .lg\:focus\:-translate-x-76:focus {
    --tw-translate-x: -7.6rem
  }

  .lg\:focus\:-translate-x-80:focus {
    --tw-translate-x: -8rem
  }

  .lg\:focus\:-translate-x-84:focus {
    --tw-translate-x: -8.4rem
  }

  .lg\:focus\:-translate-x-88:focus {
    --tw-translate-x: -8.8rem
  }

  .lg\:focus\:-translate-x-92:focus {
    --tw-translate-x: -9.2rem
  }

  .lg\:focus\:-translate-x-96:focus {
    --tw-translate-x: -9.6rem
  }

  .lg\:focus\:-translate-x-128:focus {
    --tw-translate-x: -12.8rem
  }

  .lg\:focus\:-translate-x-136:focus {
    --tw-translate-x: -13.6rem
  }

  .lg\:focus\:-translate-x-160:focus {
    --tw-translate-x: -16rem
  }

  .lg\:focus\:-translate-x-192:focus {
    --tw-translate-x: -19.2rem
  }

  .lg\:focus\:-translate-x-200:focus {
    --tw-translate-x: -20rem
  }

  .lg\:focus\:-translate-x-208:focus {
    --tw-translate-x: -20.8rem
  }

  .lg\:focus\:-translate-x-216:focus {
    --tw-translate-x: -21.6rem
  }

  .lg\:focus\:-translate-x-224:focus {
    --tw-translate-x: -22.4rem
  }

  .lg\:focus\:-translate-x-256:focus {
    --tw-translate-x: -25.6rem
  }

  .lg\:focus\:-translate-x-288:focus {
    --tw-translate-x: -28.8rem
  }

  .lg\:focus\:-translate-x-320:focus {
    --tw-translate-x: -32rem
  }

  .lg\:focus\:-translate-x-360:focus {
    --tw-translate-x: -36rem
  }

  .lg\:focus\:-translate-x-384:focus {
    --tw-translate-x: -38.4rem
  }

  .lg\:focus\:-translate-x-400:focus {
    --tw-translate-x: -40rem
  }

  .lg\:focus\:-translate-x-512:focus {
    --tw-translate-x: -51.2rem
  }

  .lg\:focus\:-translate-x-640:focus {
    --tw-translate-x: -64rem
  }

  .lg\:focus\:-translate-x-xs:focus {
    --tw-translate-x: -32rem
  }

  .lg\:focus\:-translate-x-sm:focus {
    --tw-translate-x: -48rem
  }

  .lg\:focus\:-translate-x-md:focus {
    --tw-translate-x: -64rem
  }

  .lg\:focus\:-translate-x-lg:focus {
    --tw-translate-x: -80rem
  }

  .lg\:focus\:-translate-x-xl:focus {
    --tw-translate-x: -96rem
  }

  .lg\:focus\:-translate-x-2xl:focus {
    --tw-translate-x: -112rem
  }

  .lg\:focus\:-translate-x-3xl:focus {
    --tw-translate-x: -128rem
  }

  .lg\:focus\:-translate-x-4xl:focus {
    --tw-translate-x: -144rem
  }

  .lg\:focus\:-translate-x-5xl:focus {
    --tw-translate-x: -160rem
  }

  .lg\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px
  }

  .lg\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.05rem
  }

  .lg\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.15rem
  }

  .lg\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.25rem
  }

  .lg\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.35rem
  }

  .lg\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%
  }

  .lg\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%
  }

  .lg\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%
  }

  .lg\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%
  }

  .lg\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%
  }

  .lg\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%
  }

  .lg\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%
  }

  .lg\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%
  }

  .lg\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%
  }

  .lg\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%
  }

  .lg\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%
  }

  .lg\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%
  }

  .lg\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%
  }

  .lg\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%
  }

  .lg\:focus\:translate-y-0:focus {
    --tw-translate-y: 0
  }

  .lg\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.1rem
  }

  .lg\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.2rem
  }

  .lg\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.3rem
  }

  .lg\:focus\:translate-y-4:focus {
    --tw-translate-y: 0.4rem
  }

  .lg\:focus\:translate-y-5:focus {
    --tw-translate-y: 0.5rem
  }

  .lg\:focus\:translate-y-6:focus {
    --tw-translate-y: 0.6rem
  }

  .lg\:focus\:translate-y-7:focus {
    --tw-translate-y: 0.7rem
  }

  .lg\:focus\:translate-y-8:focus {
    --tw-translate-y: 0.8rem
  }

  .lg\:focus\:translate-y-9:focus {
    --tw-translate-y: 0.9rem
  }

  .lg\:focus\:translate-y-10:focus {
    --tw-translate-y: 1.0rem
  }

  .lg\:focus\:translate-y-12:focus {
    --tw-translate-y: 1.2rem
  }

  .lg\:focus\:translate-y-14:focus {
    --tw-translate-y: 1.4rem
  }

  .lg\:focus\:translate-y-16:focus {
    --tw-translate-y: 1.6rem
  }

  .lg\:focus\:translate-y-20:focus {
    --tw-translate-y: 2rem
  }

  .lg\:focus\:translate-y-24:focus {
    --tw-translate-y: 2.4rem
  }

  .lg\:focus\:translate-y-28:focus {
    --tw-translate-y: 2.8rem
  }

  .lg\:focus\:translate-y-32:focus {
    --tw-translate-y: 3.2rem
  }

  .lg\:focus\:translate-y-36:focus {
    --tw-translate-y: 3.6rem
  }

  .lg\:focus\:translate-y-40:focus {
    --tw-translate-y: 4rem
  }

  .lg\:focus\:translate-y-44:focus {
    --tw-translate-y: 4.4rem
  }

  .lg\:focus\:translate-y-48:focus {
    --tw-translate-y: 4.8rem
  }

  .lg\:focus\:translate-y-52:focus {
    --tw-translate-y: 5.2rem
  }

  .lg\:focus\:translate-y-56:focus {
    --tw-translate-y: 5.6rem
  }

  .lg\:focus\:translate-y-60:focus {
    --tw-translate-y: 6rem
  }

  .lg\:focus\:translate-y-64:focus {
    --tw-translate-y: 6.4rem
  }

  .lg\:focus\:translate-y-68:focus {
    --tw-translate-y: 6.8rem
  }

  .lg\:focus\:translate-y-72:focus {
    --tw-translate-y: 7.2rem
  }

  .lg\:focus\:translate-y-76:focus {
    --tw-translate-y: 7.6rem
  }

  .lg\:focus\:translate-y-80:focus {
    --tw-translate-y: 8rem
  }

  .lg\:focus\:translate-y-84:focus {
    --tw-translate-y: 8.4rem
  }

  .lg\:focus\:translate-y-88:focus {
    --tw-translate-y: 8.8rem
  }

  .lg\:focus\:translate-y-92:focus {
    --tw-translate-y: 9.2rem
  }

  .lg\:focus\:translate-y-96:focus {
    --tw-translate-y: 9.6rem
  }

  .lg\:focus\:translate-y-128:focus {
    --tw-translate-y: 12.8rem
  }

  .lg\:focus\:translate-y-136:focus {
    --tw-translate-y: 13.6rem
  }

  .lg\:focus\:translate-y-160:focus {
    --tw-translate-y: 16rem
  }

  .lg\:focus\:translate-y-192:focus {
    --tw-translate-y: 19.2rem
  }

  .lg\:focus\:translate-y-200:focus {
    --tw-translate-y: 20rem
  }

  .lg\:focus\:translate-y-208:focus {
    --tw-translate-y: 20.8rem
  }

  .lg\:focus\:translate-y-216:focus {
    --tw-translate-y: 21.6rem
  }

  .lg\:focus\:translate-y-224:focus {
    --tw-translate-y: 22.4rem
  }

  .lg\:focus\:translate-y-256:focus {
    --tw-translate-y: 25.6rem
  }

  .lg\:focus\:translate-y-288:focus {
    --tw-translate-y: 28.8rem
  }

  .lg\:focus\:translate-y-320:focus {
    --tw-translate-y: 32rem
  }

  .lg\:focus\:translate-y-360:focus {
    --tw-translate-y: 36rem
  }

  .lg\:focus\:translate-y-384:focus {
    --tw-translate-y: 38.4rem
  }

  .lg\:focus\:translate-y-400:focus {
    --tw-translate-y: 40rem
  }

  .lg\:focus\:translate-y-512:focus {
    --tw-translate-y: 51.2rem
  }

  .lg\:focus\:translate-y-640:focus {
    --tw-translate-y: 64rem
  }

  .lg\:focus\:translate-y-xs:focus {
    --tw-translate-y: 32rem
  }

  .lg\:focus\:translate-y-sm:focus {
    --tw-translate-y: 48rem
  }

  .lg\:focus\:translate-y-md:focus {
    --tw-translate-y: 64rem
  }

  .lg\:focus\:translate-y-lg:focus {
    --tw-translate-y: 80rem
  }

  .lg\:focus\:translate-y-xl:focus {
    --tw-translate-y: 96rem
  }

  .lg\:focus\:translate-y-2xl:focus {
    --tw-translate-y: 112rem
  }

  .lg\:focus\:translate-y-3xl:focus {
    --tw-translate-y: 128rem
  }

  .lg\:focus\:translate-y-4xl:focus {
    --tw-translate-y: 144rem
  }

  .lg\:focus\:translate-y-5xl:focus {
    --tw-translate-y: 160rem
  }

  .lg\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px
  }

  .lg\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.05rem
  }

  .lg\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.15rem
  }

  .lg\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.25rem
  }

  .lg\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.35rem
  }

  .lg\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.1rem
  }

  .lg\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.2rem
  }

  .lg\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.3rem
  }

  .lg\:focus\:-translate-y-4:focus {
    --tw-translate-y: -0.4rem
  }

  .lg\:focus\:-translate-y-5:focus {
    --tw-translate-y: -0.5rem
  }

  .lg\:focus\:-translate-y-6:focus {
    --tw-translate-y: -0.6rem
  }

  .lg\:focus\:-translate-y-7:focus {
    --tw-translate-y: -0.7rem
  }

  .lg\:focus\:-translate-y-8:focus {
    --tw-translate-y: -0.8rem
  }

  .lg\:focus\:-translate-y-9:focus {
    --tw-translate-y: -0.9rem
  }

  .lg\:focus\:-translate-y-10:focus {
    --tw-translate-y: -1rem
  }

  .lg\:focus\:-translate-y-12:focus {
    --tw-translate-y: -1.2rem
  }

  .lg\:focus\:-translate-y-14:focus {
    --tw-translate-y: -1.4rem
  }

  .lg\:focus\:-translate-y-16:focus {
    --tw-translate-y: -1.6rem
  }

  .lg\:focus\:-translate-y-20:focus {
    --tw-translate-y: -2rem
  }

  .lg\:focus\:-translate-y-24:focus {
    --tw-translate-y: -2.4rem
  }

  .lg\:focus\:-translate-y-28:focus {
    --tw-translate-y: -2.8rem
  }

  .lg\:focus\:-translate-y-32:focus {
    --tw-translate-y: -3.2rem
  }

  .lg\:focus\:-translate-y-36:focus {
    --tw-translate-y: -3.6rem
  }

  .lg\:focus\:-translate-y-40:focus {
    --tw-translate-y: -4rem
  }

  .lg\:focus\:-translate-y-44:focus {
    --tw-translate-y: -4.4rem
  }

  .lg\:focus\:-translate-y-48:focus {
    --tw-translate-y: -4.8rem
  }

  .lg\:focus\:-translate-y-52:focus {
    --tw-translate-y: -5.2rem
  }

  .lg\:focus\:-translate-y-56:focus {
    --tw-translate-y: -5.6rem
  }

  .lg\:focus\:-translate-y-60:focus {
    --tw-translate-y: -6rem
  }

  .lg\:focus\:-translate-y-64:focus {
    --tw-translate-y: -6.4rem
  }

  .lg\:focus\:-translate-y-68:focus {
    --tw-translate-y: -6.8rem
  }

  .lg\:focus\:-translate-y-72:focus {
    --tw-translate-y: -7.2rem
  }

  .lg\:focus\:-translate-y-76:focus {
    --tw-translate-y: -7.6rem
  }

  .lg\:focus\:-translate-y-80:focus {
    --tw-translate-y: -8rem
  }

  .lg\:focus\:-translate-y-84:focus {
    --tw-translate-y: -8.4rem
  }

  .lg\:focus\:-translate-y-88:focus {
    --tw-translate-y: -8.8rem
  }

  .lg\:focus\:-translate-y-92:focus {
    --tw-translate-y: -9.2rem
  }

  .lg\:focus\:-translate-y-96:focus {
    --tw-translate-y: -9.6rem
  }

  .lg\:focus\:-translate-y-128:focus {
    --tw-translate-y: -12.8rem
  }

  .lg\:focus\:-translate-y-136:focus {
    --tw-translate-y: -13.6rem
  }

  .lg\:focus\:-translate-y-160:focus {
    --tw-translate-y: -16rem
  }

  .lg\:focus\:-translate-y-192:focus {
    --tw-translate-y: -19.2rem
  }

  .lg\:focus\:-translate-y-200:focus {
    --tw-translate-y: -20rem
  }

  .lg\:focus\:-translate-y-208:focus {
    --tw-translate-y: -20.8rem
  }

  .lg\:focus\:-translate-y-216:focus {
    --tw-translate-y: -21.6rem
  }

  .lg\:focus\:-translate-y-224:focus {
    --tw-translate-y: -22.4rem
  }

  .lg\:focus\:-translate-y-256:focus {
    --tw-translate-y: -25.6rem
  }

  .lg\:focus\:-translate-y-288:focus {
    --tw-translate-y: -28.8rem
  }

  .lg\:focus\:-translate-y-320:focus {
    --tw-translate-y: -32rem
  }

  .lg\:focus\:-translate-y-360:focus {
    --tw-translate-y: -36rem
  }

  .lg\:focus\:-translate-y-384:focus {
    --tw-translate-y: -38.4rem
  }

  .lg\:focus\:-translate-y-400:focus {
    --tw-translate-y: -40rem
  }

  .lg\:focus\:-translate-y-512:focus {
    --tw-translate-y: -51.2rem
  }

  .lg\:focus\:-translate-y-640:focus {
    --tw-translate-y: -64rem
  }

  .lg\:focus\:-translate-y-xs:focus {
    --tw-translate-y: -32rem
  }

  .lg\:focus\:-translate-y-sm:focus {
    --tw-translate-y: -48rem
  }

  .lg\:focus\:-translate-y-md:focus {
    --tw-translate-y: -64rem
  }

  .lg\:focus\:-translate-y-lg:focus {
    --tw-translate-y: -80rem
  }

  .lg\:focus\:-translate-y-xl:focus {
    --tw-translate-y: -96rem
  }

  .lg\:focus\:-translate-y-2xl:focus {
    --tw-translate-y: -112rem
  }

  .lg\:focus\:-translate-y-3xl:focus {
    --tw-translate-y: -128rem
  }

  .lg\:focus\:-translate-y-4xl:focus {
    --tw-translate-y: -144rem
  }

  .lg\:focus\:-translate-y-5xl:focus {
    --tw-translate-y: -160rem
  }

  .lg\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px
  }

  .lg\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.05rem
  }

  .lg\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.15rem
  }

  .lg\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.25rem
  }

  .lg\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.35rem
  }

  .lg\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%
  }

  .lg\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%
  }

  .lg\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%
  }

  .lg\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%
  }

  .lg\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%
  }

  .lg\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%
  }

  .lg\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%
  }

  .lg\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%
  }

  .lg\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%
  }

  .lg\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%
  }

  .lg\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%
  }

  .lg\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%
  }

  .lg\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%
  }

  .lg\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%
  }

  .lg\:skew-x-0 {
    --tw-skew-x: 0deg
  }

  .lg\:skew-x-1 {
    --tw-skew-x: 1deg
  }

  .lg\:skew-x-2 {
    --tw-skew-x: 2deg
  }

  .lg\:skew-x-3 {
    --tw-skew-x: 3deg
  }

  .lg\:skew-x-6 {
    --tw-skew-x: 6deg
  }

  .lg\:skew-x-12 {
    --tw-skew-x: 12deg
  }

  .lg\:-skew-x-12 {
    --tw-skew-x: -12deg
  }

  .lg\:-skew-x-6 {
    --tw-skew-x: -6deg
  }

  .lg\:-skew-x-3 {
    --tw-skew-x: -3deg
  }

  .lg\:-skew-x-2 {
    --tw-skew-x: -2deg
  }

  .lg\:-skew-x-1 {
    --tw-skew-x: -1deg
  }

  .lg\:skew-y-0 {
    --tw-skew-y: 0deg
  }

  .lg\:skew-y-1 {
    --tw-skew-y: 1deg
  }

  .lg\:skew-y-2 {
    --tw-skew-y: 2deg
  }

  .lg\:skew-y-3 {
    --tw-skew-y: 3deg
  }

  .lg\:skew-y-6 {
    --tw-skew-y: 6deg
  }

  .lg\:skew-y-12 {
    --tw-skew-y: 12deg
  }

  .lg\:-skew-y-12 {
    --tw-skew-y: -12deg
  }

  .lg\:-skew-y-6 {
    --tw-skew-y: -6deg
  }

  .lg\:-skew-y-3 {
    --tw-skew-y: -3deg
  }

  .lg\:-skew-y-2 {
    --tw-skew-y: -2deg
  }

  .lg\:-skew-y-1 {
    --tw-skew-y: -1deg
  }

  .lg\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg
  }

  .lg\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg
  }

  .lg\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg
  }

  .lg\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg
  }

  .lg\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg
  }

  .lg\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg
  }

  .lg\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg
  }

  .lg\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg
  }

  .lg\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg
  }

  .lg\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg
  }

  .lg\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg
  }

  .lg\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg
  }

  .lg\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg
  }

  .lg\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg
  }

  .lg\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg
  }

  .lg\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg
  }

  .lg\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg
  }

  .lg\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg
  }

  .lg\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg
  }

  .lg\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg
  }

  .lg\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg
  }

  .lg\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg
  }

  .lg\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg
  }

  .lg\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg
  }

  .lg\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg
  }

  .lg\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg
  }

  .lg\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg
  }

  .lg\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg
  }

  .lg\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg
  }

  .lg\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg
  }

  .lg\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg
  }

  .lg\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg
  }

  .lg\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg
  }

  .lg\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg
  }

  .lg\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg
  }

  .lg\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg
  }

  .lg\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg
  }

  .lg\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg
  }

  .lg\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg
  }

  .lg\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg
  }

  .lg\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg
  }

  .lg\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg
  }

  .lg\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg
  }

  .lg\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg
  }

  .lg\:transition-none {
    transition-property: none
  }

  .lg\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .lg\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .lg\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .lg\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .lg\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .lg\:transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .lg\:ease-linear {
    transition-timing-function: linear
  }

  .lg\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .lg\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .lg\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .lg\:duration-75 {
    transition-duration: 75ms
  }

  .lg\:duration-100 {
    transition-duration: 100ms
  }

  .lg\:duration-150 {
    transition-duration: 150ms
  }

  .lg\:duration-200 {
    transition-duration: 200ms
  }

  .lg\:duration-300 {
    transition-duration: 300ms
  }

  .lg\:duration-500 {
    transition-duration: 500ms
  }

  .lg\:duration-700 {
    transition-duration: 700ms
  }

  .lg\:duration-1000 {
    transition-duration: 1000ms
  }

  .lg\:delay-75 {
    transition-delay: 75ms
  }

  .lg\:delay-100 {
    transition-delay: 100ms
  }

  .lg\:delay-150 {
    transition-delay: 150ms
  }

  .lg\:delay-200 {
    transition-delay: 200ms
  }

  .lg\:delay-300 {
    transition-delay: 300ms
  }

  .lg\:delay-500 {
    transition-delay: 500ms
  }

  .lg\:delay-700 {
    transition-delay: 700ms
  }

  .lg\:delay-1000 {
    transition-delay: 1000ms
  }

  .lg\:animate-none {
    animation: none
  }

  .lg\:animate-spin {
    animation: spin 1s linear infinite
  }

  .lg\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .lg\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .lg\:animate-bounce {
    animation: bounce 1s infinite
  }
}

@media (min-width: 1920px) {
  .xl\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  .xl\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.1rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.1rem * var(--tw-space-x-reverse));
    margin-left: calc(0.1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.2rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.2rem * var(--tw-space-x-reverse));
    margin-left: calc(0.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.3rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.3rem * var(--tw-space-x-reverse));
    margin-left: calc(0.3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.4rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.4rem * var(--tw-space-x-reverse));
    margin-left: calc(0.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.6rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.6rem * var(--tw-space-x-reverse));
    margin-left: calc(0.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.7rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.7rem * var(--tw-space-x-reverse));
    margin-left: calc(0.7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.8rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.8rem * var(--tw-space-x-reverse));
    margin-left: calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.9rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.9rem * var(--tw-space-x-reverse));
    margin-left: calc(0.9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.0rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.0rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.0rem * var(--tw-space-x-reverse));
    margin-left: calc(1.0rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.2rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.2rem * var(--tw-space-x-reverse));
    margin-left: calc(1.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.4rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.4rem * var(--tw-space-x-reverse));
    margin-left: calc(1.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.6rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.4rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.4rem * var(--tw-space-x-reverse));
    margin-left: calc(2.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.8rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.8rem * var(--tw-space-x-reverse));
    margin-left: calc(2.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.2rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.2rem * var(--tw-space-x-reverse));
    margin-left: calc(3.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.6rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.6rem * var(--tw-space-x-reverse));
    margin-left: calc(3.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4.4rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4.4rem * var(--tw-space-x-reverse));
    margin-left: calc(4.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4.8rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4.8rem * var(--tw-space-x-reverse));
    margin-left: calc(4.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5.2rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5.2rem * var(--tw-space-x-reverse));
    margin-left: calc(5.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5.6rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5.6rem * var(--tw-space-x-reverse));
    margin-left: calc(5.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6.4rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6.4rem * var(--tw-space-x-reverse));
    margin-left: calc(6.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6.8rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6.8rem * var(--tw-space-x-reverse));
    margin-left: calc(6.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7.2rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7.2rem * var(--tw-space-x-reverse));
    margin-left: calc(7.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7.6rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7.6rem * var(--tw-space-x-reverse));
    margin-left: calc(7.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8.4rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8.4rem * var(--tw-space-x-reverse));
    margin-left: calc(8.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8.8rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8.8rem * var(--tw-space-x-reverse));
    margin-left: calc(8.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9.2rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9.2rem * var(--tw-space-x-reverse));
    margin-left: calc(9.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9.6rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9.6rem * var(--tw-space-x-reverse));
    margin-left: calc(9.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12.8rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12.8rem * var(--tw-space-x-reverse));
    margin-left: calc(12.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13.6rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13.6rem * var(--tw-space-x-reverse));
    margin-left: calc(13.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(19.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(19.2rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(19.2rem * var(--tw-space-x-reverse));
    margin-left: calc(19.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20.8rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20.8rem * var(--tw-space-x-reverse));
    margin-left: calc(20.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(21.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(21.6rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(21.6rem * var(--tw-space-x-reverse));
    margin-left: calc(21.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(22.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(22.4rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(22.4rem * var(--tw-space-x-reverse));
    margin-left: calc(22.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(25.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(25.6rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(25.6rem * var(--tw-space-x-reverse));
    margin-left: calc(25.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(28.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(28.8rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(28.8rem * var(--tw-space-x-reverse));
    margin-left: calc(28.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(32rem * var(--tw-space-x-reverse));
    margin-left: calc(32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(36rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(36rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(36rem * var(--tw-space-x-reverse));
    margin-left: calc(36rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(38.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(38.4rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(38.4rem * var(--tw-space-x-reverse));
    margin-left: calc(38.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(40rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(40rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(40rem * var(--tw-space-x-reverse));
    margin-left: calc(40rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(51.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(51.2rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(51.2rem * var(--tw-space-x-reverse));
    margin-left: calc(51.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(64rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(64rem * var(--tw-space-x-reverse));
    margin-left: calc(64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(32rem * var(--tw-space-x-reverse));
    margin-left: calc(32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(48rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(48rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(48rem * var(--tw-space-x-reverse));
    margin-left: calc(48rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(64rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(64rem * var(--tw-space-x-reverse));
    margin-left: calc(64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(80rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(80rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(80rem * var(--tw-space-x-reverse));
    margin-left: calc(80rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(96rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(96rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(96rem * var(--tw-space-x-reverse));
    margin-left: calc(96rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(112rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(112rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(112rem * var(--tw-space-x-reverse));
    margin-left: calc(112rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(128rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(128rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(128rem * var(--tw-space-x-reverse));
    margin-left: calc(128rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(144rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(144rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(144rem * var(--tw-space-x-reverse));
    margin-left: calc(144rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(160rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(160rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(160rem * var(--tw-space-x-reverse));
    margin-left: calc(160rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse))
  }

  .xl\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.05rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.05rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.05rem * var(--tw-space-x-reverse));
    margin-left: calc(0.05rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.15rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.15rem * var(--tw-space-x-reverse));
    margin-left: calc(0.15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.35rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.35rem * var(--tw-space-y-reverse))
  }

  .xl\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.35rem * var(--tw-space-x-reverse));
    margin-left: calc(0.35rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.1rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.1rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.2rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.3rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.3rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.4rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.6rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.7rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.7rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.8rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.9rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.9rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.2rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.4rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.6rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.4rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.8rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.2rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.6rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4.4rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4.8rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-4.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5.2rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-5.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5.6rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-5.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6.4rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-6.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6.8rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-6.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7.2rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-7.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7.6rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-7.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8.4rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-8.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8.8rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9.2rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-9.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9.6rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-9.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12.8rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-12.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13.6rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-13.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-19.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-19.2rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-19.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-19.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20.8rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-20.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-21.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-21.6rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-21.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-21.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-22.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-22.4rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-22.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-22.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-25.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-25.6rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-25.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-25.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-28.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-28.8rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-28.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-28.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-32rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-32rem * var(--tw-space-x-reverse));
    margin-left: calc(-32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-36rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-36rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-36rem * var(--tw-space-x-reverse));
    margin-left: calc(-36rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-38.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-38.4rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-38.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-38.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-40rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-40rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-40rem * var(--tw-space-x-reverse));
    margin-left: calc(-40rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-51.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-51.2rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-51.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-51.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-64rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-64rem * var(--tw-space-x-reverse));
    margin-left: calc(-64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-32rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-32rem * var(--tw-space-x-reverse));
    margin-left: calc(-32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-48rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-48rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-48rem * var(--tw-space-x-reverse));
    margin-left: calc(-48rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-64rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-64rem * var(--tw-space-x-reverse));
    margin-left: calc(-64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-80rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-80rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-80rem * var(--tw-space-x-reverse));
    margin-left: calc(-80rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-96rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-96rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-96rem * var(--tw-space-x-reverse));
    margin-left: calc(-96rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-112rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-112rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-112rem * var(--tw-space-x-reverse));
    margin-left: calc(-112rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-128rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-128rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-128rem * var(--tw-space-x-reverse));
    margin-left: calc(-128rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-144rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-144rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-144rem * var(--tw-space-x-reverse));
    margin-left: calc(-144rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-160rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-160rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-160rem * var(--tw-space-x-reverse));
    margin-left: calc(-160rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.05rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.05rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.05rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.05rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.15rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.15rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.35rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.35rem * var(--tw-space-y-reverse))
  }

  .xl\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.35rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.35rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1
  }

  .xl\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1
  }

  .xl\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse))
  }

  .xl\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .xl\:divide-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  .xl\:divide-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .xl\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse))
  }

  .xl\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .xl\:divide-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(3px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(3px * var(--tw-divide-y-reverse))
  }

  .xl\:divide-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(3px * var(--tw-divide-x-reverse));
    border-left-width: calc(3px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .xl\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse))
  }

  .xl\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .xl\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse))
  }

  .xl\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .xl\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  .xl\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .xl\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1
  }

  .xl\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1
  }

  .xl\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent
  }

  .xl\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor
  }

  .xl\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-divide-opacity))
  }

  .xl\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-grey-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-divide-opacity))
  }

  .xl\:divide-grey-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-divide-opacity))
  }

  .xl\:divide-grey-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-divide-opacity))
  }

  .xl\:divide-grey-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-divide-opacity))
  }

  .xl\:divide-grey-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-divide-opacity))
  }

  .xl\:divide-grey-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .xl\:divide-grey-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-divide-opacity))
  }

  .xl\:divide-grey-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .xl\:divide-grey-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-divide-opacity))
  }

  .xl\:divide-grey-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity))
  }

  .xl\:divide-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .xl\:divide-grey-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-divide-opacity))
  }

  .xl\:divide-grey-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-divide-opacity))
  }

  .xl\:divide-grey-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-divide-opacity))
  }

  .xl\:divide-grey-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .xl\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-divide-opacity))
  }

  .xl\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-divide-opacity))
  }

  .xl\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-divide-opacity))
  }

  .xl\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-divide-opacity))
  }

  .xl\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-divide-opacity))
  }

  .xl\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .xl\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-divide-opacity))
  }

  .xl\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .xl\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-divide-opacity))
  }

  .xl\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity))
  }

  .xl\:divide-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .xl\:divide-gray-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .xl\:divide-gray-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-divide-opacity))
  }

  .xl\:divide-gray-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-divide-opacity))
  }

  .xl\:divide-gray-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-divide-opacity))
  }

  .xl\:divide-gray-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .xl\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-divide-opacity))
  }

  .xl\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-divide-opacity))
  }

  .xl\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-divide-opacity))
  }

  .xl\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-divide-opacity))
  }

  .xl\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-divide-opacity))
  }

  .xl\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-divide-opacity))
  }

  .xl\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-divide-opacity))
  }

  .xl\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-divide-opacity))
  }

  .xl\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-divide-opacity))
  }

  .xl\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-divide-opacity))
  }

  .xl\:divide-red > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-divide-opacity))
  }

  .xl\:divide-red-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-divide-opacity))
  }

  .xl\:divide-red-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-divide-opacity))
  }

  .xl\:divide-red-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-divide-opacity))
  }

  .xl\:divide-red-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-orange-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-divide-opacity))
  }

  .xl\:divide-orange-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-divide-opacity))
  }

  .xl\:divide-orange-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-divide-opacity))
  }

  .xl\:divide-orange-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-divide-opacity))
  }

  .xl\:divide-orange-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-divide-opacity))
  }

  .xl\:divide-orange-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-orange-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-orange-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-orange-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-orange-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-orange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-orange-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-divide-opacity))
  }

  .xl\:divide-orange-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-divide-opacity))
  }

  .xl\:divide-orange-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-orange-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-orange-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-orange-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-orange-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-orange-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-orange-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-orange-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-orange-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-orange-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-orange-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-orange-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-orange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-orange-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-orange-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-orange-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-orange-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-divide-opacity))
  }

  .xl\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-divide-opacity))
  }

  .xl\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-divide-opacity))
  }

  .xl\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-divide-opacity))
  }

  .xl\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-divide-opacity))
  }

  .xl\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-divide-opacity))
  }

  .xl\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-divide-opacity))
  }

  .xl\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-divide-opacity))
  }

  .xl\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-divide-opacity))
  }

  .xl\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-divide-opacity))
  }

  .xl\:divide-yellow > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-divide-opacity))
  }

  .xl\:divide-yellow-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-divide-opacity))
  }

  .xl\:divide-yellow-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-yellow-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-yellow-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-divide-opacity))
  }

  .xl\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-divide-opacity))
  }

  .xl\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-divide-opacity))
  }

  .xl\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-divide-opacity))
  }

  .xl\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-divide-opacity))
  }

  .xl\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-divide-opacity))
  }

  .xl\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-divide-opacity))
  }

  .xl\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-divide-opacity))
  }

  .xl\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-divide-opacity))
  }

  .xl\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-divide-opacity))
  }

  .xl\:divide-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-divide-opacity))
  }

  .xl\:divide-green-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-divide-opacity))
  }

  .xl\:divide-green-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-divide-opacity))
  }

  .xl\:divide-green-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-divide-opacity))
  }

  .xl\:divide-green-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-divide-opacity))
  }

  .xl\:divide-light-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-divide-opacity))
  }

  .xl\:divide-light-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-divide-opacity))
  }

  .xl\:divide-light-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-divide-opacity))
  }

  .xl\:divide-light-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-divide-opacity))
  }

  .xl\:divide-light-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-divide-opacity))
  }

  .xl\:divide-light-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-divide-opacity))
  }

  .xl\:divide-light-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-divide-opacity))
  }

  .xl\:divide-light-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-divide-opacity))
  }

  .xl\:divide-light-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-divide-opacity))
  }

  .xl\:divide-light-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-divide-opacity))
  }

  .xl\:divide-light-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-divide-opacity))
  }

  .xl\:divide-light-green-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-divide-opacity))
  }

  .xl\:divide-light-green-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-divide-opacity))
  }

  .xl\:divide-light-green-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-divide-opacity))
  }

  .xl\:divide-light-green-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-divide-opacity))
  }

  .xl\:divide-teal-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-divide-opacity))
  }

  .xl\:divide-teal-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-divide-opacity))
  }

  .xl\:divide-teal-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-divide-opacity))
  }

  .xl\:divide-teal-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-divide-opacity))
  }

  .xl\:divide-teal-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-divide-opacity))
  }

  .xl\:divide-teal-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-divide-opacity))
  }

  .xl\:divide-teal-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-divide-opacity))
  }

  .xl\:divide-teal-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-divide-opacity))
  }

  .xl\:divide-teal-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-divide-opacity))
  }

  .xl\:divide-teal-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-divide-opacity))
  }

  .xl\:divide-teal > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-divide-opacity))
  }

  .xl\:divide-teal-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-divide-opacity))
  }

  .xl\:divide-teal-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-divide-opacity))
  }

  .xl\:divide-teal-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-divide-opacity))
  }

  .xl\:divide-teal-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-divide-opacity))
  }

  .xl\:divide-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-light-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-divide-opacity))
  }

  .xl\:divide-light-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-divide-opacity))
  }

  .xl\:divide-light-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-divide-opacity))
  }

  .xl\:divide-light-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-divide-opacity))
  }

  .xl\:divide-light-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-divide-opacity))
  }

  .xl\:divide-light-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-divide-opacity))
  }

  .xl\:divide-light-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-divide-opacity))
  }

  .xl\:divide-light-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-divide-opacity))
  }

  .xl\:divide-light-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-divide-opacity))
  }

  .xl\:divide-light-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-divide-opacity))
  }

  .xl\:divide-light-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-divide-opacity))
  }

  .xl\:divide-light-blue-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-light-blue-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-light-blue-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-light-blue-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-divide-opacity))
  }

  .xl\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-divide-opacity))
  }

  .xl\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-divide-opacity))
  }

  .xl\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-divide-opacity))
  }

  .xl\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-divide-opacity))
  }

  .xl\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-divide-opacity))
  }

  .xl\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-divide-opacity))
  }

  .xl\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-divide-opacity))
  }

  .xl\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-divide-opacity))
  }

  .xl\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-divide-opacity))
  }

  .xl\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-divide-opacity))
  }

  .xl\:divide-indigo > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-divide-opacity))
  }

  .xl\:divide-indigo-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-indigo-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-divide-opacity))
  }

  .xl\:divide-indigo-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-divide-opacity))
  }

  .xl\:divide-indigo-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-divide-opacity))
  }

  .xl\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-divide-opacity))
  }

  .xl\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-divide-opacity))
  }

  .xl\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-divide-opacity))
  }

  .xl\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-divide-opacity))
  }

  .xl\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-divide-opacity))
  }

  .xl\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-divide-opacity))
  }

  .xl\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-divide-opacity))
  }

  .xl\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-divide-opacity))
  }

  .xl\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-divide-opacity))
  }

  .xl\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-divide-opacity))
  }

  .xl\:divide-purple > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-divide-opacity))
  }

  .xl\:divide-purple-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-divide-opacity))
  }

  .xl\:divide-purple-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-divide-opacity))
  }

  .xl\:divide-purple-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-divide-opacity))
  }

  .xl\:divide-purple-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-purple > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-purple-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-purple-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-purple-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-deep-purple-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-divide-opacity))
  }

  .xl\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-divide-opacity))
  }

  .xl\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-divide-opacity))
  }

  .xl\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-divide-opacity))
  }

  .xl\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-divide-opacity))
  }

  .xl\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-divide-opacity))
  }

  .xl\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-divide-opacity))
  }

  .xl\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-divide-opacity))
  }

  .xl\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-divide-opacity))
  }

  .xl\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-divide-opacity))
  }

  .xl\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-divide-opacity))
  }

  .xl\:divide-pink > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-divide-opacity))
  }

  .xl\:divide-pink-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-divide-opacity))
  }

  .xl\:divide-pink-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-divide-opacity))
  }

  .xl\:divide-pink-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-divide-opacity))
  }

  .xl\:divide-pink-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-divide-opacity))
  }

  .xl\:divide-lime-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-divide-opacity))
  }

  .xl\:divide-lime-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-divide-opacity))
  }

  .xl\:divide-lime-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-divide-opacity))
  }

  .xl\:divide-lime-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-divide-opacity))
  }

  .xl\:divide-lime-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-divide-opacity))
  }

  .xl\:divide-lime-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-divide-opacity))
  }

  .xl\:divide-lime-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-divide-opacity))
  }

  .xl\:divide-lime-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-divide-opacity))
  }

  .xl\:divide-lime-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-divide-opacity))
  }

  .xl\:divide-lime-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-divide-opacity))
  }

  .xl\:divide-lime > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-divide-opacity))
  }

  .xl\:divide-lime-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-divide-opacity))
  }

  .xl\:divide-lime-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-divide-opacity))
  }

  .xl\:divide-lime-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-lime-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-amber-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-divide-opacity))
  }

  .xl\:divide-amber-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-divide-opacity))
  }

  .xl\:divide-amber-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-divide-opacity))
  }

  .xl\:divide-amber-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-divide-opacity))
  }

  .xl\:divide-amber-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-divide-opacity))
  }

  .xl\:divide-amber-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-divide-opacity))
  }

  .xl\:divide-amber-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-amber-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-amber-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-amber-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-amber > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-divide-opacity))
  }

  .xl\:divide-amber-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-divide-opacity))
  }

  .xl\:divide-amber-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-divide-opacity))
  }

  .xl\:divide-amber-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-amber-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-divide-opacity))
  }

  .xl\:divide-brown-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-divide-opacity))
  }

  .xl\:divide-brown-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-divide-opacity))
  }

  .xl\:divide-brown-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-divide-opacity))
  }

  .xl\:divide-brown-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-divide-opacity))
  }

  .xl\:divide-brown-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-divide-opacity))
  }

  .xl\:divide-brown-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-divide-opacity))
  }

  .xl\:divide-brown-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-divide-opacity))
  }

  .xl\:divide-brown-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-divide-opacity))
  }

  .xl\:divide-brown-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-divide-opacity))
  }

  .xl\:divide-brown-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-divide-opacity))
  }

  .xl\:divide-brown > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-divide-opacity))
  }

  .xl\:divide-brown-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-divide-opacity))
  }

  .xl\:divide-brown-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-divide-opacity))
  }

  .xl\:divide-brown-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-divide-opacity))
  }

  .xl\:divide-brown-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-gray-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-gray-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-gray-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-divide-opacity))
  }

  .xl\:divide-blue-gray-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-divide-opacity))
  }

  .xl\:divide-cyan-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-divide-opacity))
  }

  .xl\:divide-cyan-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-divide-opacity))
  }

  .xl\:divide-cyan-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-divide-opacity))
  }

  .xl\:divide-cyan-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-divide-opacity))
  }

  .xl\:divide-cyan-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-divide-opacity))
  }

  .xl\:divide-cyan-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-divide-opacity))
  }

  .xl\:divide-cyan-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-divide-opacity))
  }

  .xl\:divide-cyan-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-divide-opacity))
  }

  .xl\:divide-cyan-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-divide-opacity))
  }

  .xl\:divide-cyan-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-divide-opacity))
  }

  .xl\:divide-cyan > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-divide-opacity))
  }

  .xl\:divide-cyan-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-cyan-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-cyan-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-divide-opacity))
  }

  .xl\:divide-cyan-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-divide-opacity))
  }

  .xl\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid
  }

  .xl\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed
  }

  .xl\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted
  }

  .xl\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double
  }

  .xl\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none
  }

  .xl\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0
  }

  .xl\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05
  }

  .xl\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1
  }

  .xl\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2
  }

  .xl\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25
  }

  .xl\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3
  }

  .xl\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4
  }

  .xl\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5
  }

  .xl\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6
  }

  .xl\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7
  }

  .xl\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75
  }

  .xl\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8
  }

  .xl\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9
  }

  .xl\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95
  }

  .xl\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1
  }

  .xl\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .xl\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .xl\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .xl\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .xl\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .xl\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .xl\:appearance-none {
    -webkit-appearance: none;
            appearance: none
  }

  .xl\:bg-fixed {
    background-attachment: fixed
  }

  .xl\:bg-local {
    background-attachment: local
  }

  .xl\:bg-scroll {
    background-attachment: scroll
  }

  .xl\:bg-clip-border {
    background-clip: border-box
  }

  .xl\:bg-clip-padding {
    background-clip: padding-box
  }

  .xl\:bg-clip-content {
    background-clip: content-box
  }

  .xl\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text
  }

  .xl\:bg-transparent {
    background-color: transparent
  }

  .xl\:bg-current {
    background-color: currentColor
  }

  .xl\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .xl\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-grey-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .xl\:bg-grey-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .xl\:bg-grey-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .xl\:bg-grey-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .xl\:bg-grey-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .xl\:bg-grey-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:bg-grey-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .xl\:bg-grey-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:bg-grey-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .xl\:bg-grey-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .xl\:bg-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:bg-grey-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .xl\:bg-grey-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .xl\:bg-grey-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .xl\:bg-grey-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .xl\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .xl\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .xl\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .xl\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .xl\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .xl\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .xl\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .xl\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:bg-gray-hover {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .xl\:bg-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .xl\:bg-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .xl\:bg-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .xl\:bg-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .xl\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .xl\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .xl\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .xl\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .xl\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .xl\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .xl\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .xl\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .xl\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .xl\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .xl\:bg-red-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .xl\:bg-red-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .xl\:bg-red-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .xl\:bg-red-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .xl\:bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .xl\:bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .xl\:bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .xl\:bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .xl\:bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .xl\:bg-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .xl\:bg-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .xl\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .xl\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .xl\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .xl\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .xl\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .xl\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .xl\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .xl\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .xl\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .xl\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .xl\:bg-yellow-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .xl\:bg-yellow-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-yellow-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-yellow-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .xl\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .xl\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .xl\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .xl\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .xl\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .xl\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .xl\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .xl\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .xl\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .xl\:bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .xl\:bg-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .xl\:bg-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .xl\:bg-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .xl\:bg-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .xl\:bg-light-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .xl\:bg-light-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .xl\:bg-light-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .xl\:bg-light-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .xl\:bg-light-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .xl\:bg-light-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .xl\:bg-light-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .xl\:bg-light-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .xl\:bg-light-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .xl\:bg-light-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .xl\:bg-light-green {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .xl\:bg-light-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .xl\:bg-light-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .xl\:bg-light-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .xl\:bg-light-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .xl\:bg-teal-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .xl\:bg-teal-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .xl\:bg-teal-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .xl\:bg-teal-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .xl\:bg-teal-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .xl\:bg-teal-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .xl\:bg-teal-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .xl\:bg-teal-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .xl\:bg-teal-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .xl\:bg-teal-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .xl\:bg-teal {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .xl\:bg-teal-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .xl\:bg-teal-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .xl\:bg-teal-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .xl\:bg-teal-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .xl\:bg-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-light-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .xl\:bg-light-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .xl\:bg-light-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .xl\:bg-light-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .xl\:bg-light-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .xl\:bg-light-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .xl\:bg-light-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .xl\:bg-light-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .xl\:bg-light-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .xl\:bg-light-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .xl\:bg-light-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .xl\:bg-light-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-light-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-light-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-light-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .xl\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .xl\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .xl\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .xl\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .xl\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .xl\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .xl\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .xl\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .xl\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .xl\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .xl\:bg-indigo {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .xl\:bg-indigo-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-indigo-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .xl\:bg-indigo-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .xl\:bg-indigo-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .xl\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .xl\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .xl\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .xl\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .xl\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .xl\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .xl\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .xl\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .xl\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .xl\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .xl\:bg-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .xl\:bg-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .xl\:bg-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .xl\:bg-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .xl\:bg-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-deep-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .xl\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .xl\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .xl\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .xl\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .xl\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .xl\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .xl\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .xl\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .xl\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .xl\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .xl\:bg-pink {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .xl\:bg-pink-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .xl\:bg-pink-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .xl\:bg-pink-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .xl\:bg-pink-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .xl\:bg-lime-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .xl\:bg-lime-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .xl\:bg-lime-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .xl\:bg-lime-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .xl\:bg-lime-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .xl\:bg-lime-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .xl\:bg-lime-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .xl\:bg-lime-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .xl\:bg-lime-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .xl\:bg-lime-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .xl\:bg-lime {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .xl\:bg-lime-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .xl\:bg-lime-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .xl\:bg-lime-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-lime-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-amber-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .xl\:bg-amber-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .xl\:bg-amber-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .xl\:bg-amber-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .xl\:bg-amber-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .xl\:bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .xl\:bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-amber-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-amber-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-amber-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-amber {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .xl\:bg-amber-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .xl\:bg-amber-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .xl\:bg-amber-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-amber-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-brown-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .xl\:bg-brown-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .xl\:bg-brown-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .xl\:bg-brown-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .xl\:bg-brown-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .xl\:bg-brown-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .xl\:bg-brown-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .xl\:bg-brown-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .xl\:bg-brown-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .xl\:bg-brown-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .xl\:bg-brown {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .xl\:bg-brown-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .xl\:bg-brown-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .xl\:bg-brown-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .xl\:bg-brown-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .xl\:bg-blue-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-transparent {
    background-color: transparent
  }

  .group:hover .xl\:group-hover\:bg-current {
    background-color: currentColor
  }

  .group:hover .xl\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-grey-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-grey-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-grey-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-grey-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-grey-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-grey-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-grey-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-grey-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-grey-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-grey-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-grey-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-grey-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-grey-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-grey-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray-hover {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .group:hover .xl\:group-hover\:bg-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-yellow-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-yellow-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-yellow-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-yellow-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-green {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-teal-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-teal-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-teal-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-teal-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-teal-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-teal-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-teal-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-teal-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-teal-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-teal-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-teal {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-teal-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-teal-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-teal-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-teal-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-light-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-indigo {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-indigo-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-indigo-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-indigo-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-indigo-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-deep-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-pink {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-pink-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-pink-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-pink-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-pink-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-lime-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-lime-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-lime-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-lime-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-lime-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-lime-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-lime-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-lime-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-lime-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-lime-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-lime {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-lime-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-lime-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-lime-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-lime-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-amber-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-amber-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-amber-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-amber-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-amber-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-amber-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-amber-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-amber-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-amber {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-amber-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-amber-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-amber-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-amber-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-brown-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-brown-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-brown-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-brown-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-brown-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-brown-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-brown-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-brown-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-brown-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-brown-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-brown {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-brown-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-brown-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-brown-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-brown-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-blue-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-transparent:focus-within {
    background-color: transparent
  }

  .xl\:focus-within\:bg-current:focus-within {
    background-color: currentColor
  }

  .xl\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-grey-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-grey-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-grey-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-grey-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-grey-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-grey-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-grey-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-grey-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-grey-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-grey-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-grey-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-grey-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-grey-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-grey-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-gray-hover:focus-within {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .xl\:focus-within\:bg-gray-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-gray-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-gray-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-gray-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-red:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-red-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-red-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-red-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-red-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-orange-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-orange-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-orange-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-orange-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-orange-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-orange-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-orange-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-orange-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-orange-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-orange-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-orange:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-orange-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-orange-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-orange-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-orange-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-orange-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-orange-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-orange-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-orange-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-orange-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-orange-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-orange-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-orange-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-orange-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-orange-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-orange:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-orange-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-orange-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-orange-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-orange-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-yellow:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-yellow-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-yellow-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-yellow-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-yellow-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-green-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-green-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-green-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-green-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-green-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-green-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-green-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-green-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-teal-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-teal-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-teal-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-teal-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-teal-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-teal-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-teal-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-teal-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-teal-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-teal-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-teal:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-teal-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-teal-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-teal-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-teal-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-blue-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-blue-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-blue-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-light-blue-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-indigo:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-indigo-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-indigo-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-indigo-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-indigo-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-purple:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-purple-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-purple-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-purple-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-purple-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-purple:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-purple-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-purple-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-purple-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-deep-purple-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-pink:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-pink-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-pink-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-pink-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-pink-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-lime-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-lime-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-lime-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-lime-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-lime-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-lime-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-lime-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-lime-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-lime-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-lime-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-lime:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-lime-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-lime-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-lime-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-lime-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-amber-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-amber-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-amber-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-amber-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-amber-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-amber-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-amber-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-amber-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-amber-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-amber-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-amber:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-amber-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-amber-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-amber-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-amber-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-brown-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-brown-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-brown-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-brown-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-brown-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-brown-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-brown-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-brown-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-brown-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-brown-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-brown:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-brown-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-brown-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-brown-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-brown-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-gray-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-gray-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-gray-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-blue-gray-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-cyan-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-cyan-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-cyan-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-cyan-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-cyan-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-cyan-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-cyan-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-cyan-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-cyan-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-cyan-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-cyan:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-cyan-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-cyan-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-cyan-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .xl\:focus-within\:bg-cyan-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-transparent:hover {
    background-color: transparent
  }

  .xl\:hover\:bg-current:hover {
    background-color: currentColor
  }

  .xl\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-grey-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-grey-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-grey-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-grey-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-grey-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-grey-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-grey-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-grey-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-grey-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-grey-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-grey-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-grey-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-grey-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-grey-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray-hover:hover {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .xl\:hover\:bg-gray-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-orange-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-orange-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-orange-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-orange-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-orange-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-orange-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-orange-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-orange-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-orange-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-orange-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-orange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-orange-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-orange-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-orange-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-orange-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-orange-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-orange-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-orange-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-orange-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-orange-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-orange-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-orange-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-orange-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-orange-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-orange-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-orange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-orange-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-orange-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-orange-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-orange-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-yellow:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-yellow-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-yellow-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-yellow-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-yellow-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-green-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-green-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-green-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-green-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-teal-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-teal-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-teal-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-teal-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-teal-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-teal-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-teal-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-teal-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-teal-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-teal-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-teal:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-teal-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-teal-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-teal-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-teal-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-blue-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-blue-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-blue-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-light-blue-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-indigo:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-indigo-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-indigo-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-indigo-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-indigo-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-purple:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-purple-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-purple-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-purple-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-purple-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-purple:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-purple-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-purple-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-purple-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-deep-purple-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-pink:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-pink-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-pink-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-pink-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-pink-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-lime-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-lime-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-lime-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-lime-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-lime-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-lime-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-lime-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-lime-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-lime-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-lime-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-lime:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-lime-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-lime-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-lime-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-lime-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-amber-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-amber-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-amber-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-amber-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-amber-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-amber-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-amber-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-amber-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-amber-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-amber-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-amber:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-amber-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-amber-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-amber-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-amber-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-brown-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-brown-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-brown-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-brown-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-brown-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-brown-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-brown-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-brown-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-brown-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-brown-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-brown:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-brown-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-brown-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-brown-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-brown-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-gray-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-gray-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-gray-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-blue-gray-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-transparent:focus {
    background-color: transparent
  }

  .xl\:focus\:bg-current:focus {
    background-color: currentColor
  }

  .xl\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-grey-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-grey-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-grey-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-grey-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-grey-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-grey-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-grey-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-grey-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-grey-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-grey-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-grey-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-grey-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-grey-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-grey-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray-hover:focus {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .xl\:focus\:bg-gray-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-orange-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-orange-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-orange-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-orange-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-orange-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-orange-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-orange-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-orange-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-orange-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-orange-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-orange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-orange-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-orange-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-orange-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-orange-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-orange-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-orange-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-orange-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-orange-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-orange-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-orange-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-orange-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-orange-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-orange-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-orange-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-orange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-orange-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-orange-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-orange-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-orange-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-yellow:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-yellow-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-yellow-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-yellow-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-yellow-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-green-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-green-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-green-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-green-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-teal-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-teal-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-teal-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-teal-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-teal-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-teal-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-teal-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-teal-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-teal-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-teal-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-teal:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-teal-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-teal-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-teal-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-teal-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-blue-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-blue-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-blue-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-light-blue-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-indigo:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-indigo-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-indigo-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-indigo-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-indigo-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-purple:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-purple-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-purple-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-purple-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-purple-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-purple:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-purple-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-purple-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-purple-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-deep-purple-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-pink:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-pink-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-pink-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-pink-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-pink-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-lime-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-lime-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-lime-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-lime-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-lime-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-lime-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-lime-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-lime-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-lime-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-lime-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-lime:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-lime-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-lime-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-lime-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-lime-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-amber-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-amber-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-amber-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-amber-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-amber-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-amber-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-amber-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-amber-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-amber-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-amber-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-amber:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-amber-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-amber-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-amber-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-amber-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-brown-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-brown-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-brown-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-brown-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-brown-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-brown-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-brown-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-brown-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-brown-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-brown-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-brown:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-brown-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-brown-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-brown-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-brown-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-gray-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-gray-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-gray-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-blue-gray-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .xl\:bg-none {
    background-image: none
  }

  .xl\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .xl\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:from-black {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .xl\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:from-grey-50 {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .xl\:from-grey-100 {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .xl\:from-grey-200 {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .xl\:from-grey-300 {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .xl\:from-grey-400 {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .xl\:from-grey-500 {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:from-grey-600 {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .xl\:from-grey-700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:from-grey-800 {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .xl\:from-grey-900 {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .xl\:from-grey {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:from-grey-A100 {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .xl\:from-grey-A200 {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .xl\:from-grey-A400 {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .xl\:from-grey-A700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:from-gray-50 {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .xl\:from-gray-100 {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .xl\:from-gray-200 {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .xl\:from-gray-300 {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .xl\:from-gray-400 {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .xl\:from-gray-500 {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:from-gray-600 {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .xl\:from-gray-700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:from-gray-800 {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .xl\:from-gray-900 {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .xl\:from-gray {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:from-gray-hover {
    --tw-gradient-from: rgba(0, 0, 0, 0.04);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:from-gray-A100 {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .xl\:from-gray-A200 {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .xl\:from-gray-A400 {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .xl\:from-gray-A700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:from-red-50 {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .xl\:from-red-100 {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .xl\:from-red-200 {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .xl\:from-red-300 {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .xl\:from-red-400 {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .xl\:from-red-500 {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .xl\:from-red-600 {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .xl\:from-red-700 {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .xl\:from-red-800 {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .xl\:from-red-900 {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .xl\:from-red {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .xl\:from-red-A100 {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .xl\:from-red-A200 {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .xl\:from-red-A400 {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .xl\:from-red-A700 {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .xl\:from-orange-50 {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .xl\:from-orange-100 {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .xl\:from-orange-200 {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .xl\:from-orange-300 {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .xl\:from-orange-400 {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .xl\:from-orange-500 {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .xl\:from-orange-600 {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .xl\:from-orange-700 {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .xl\:from-orange-800 {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .xl\:from-orange-900 {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .xl\:from-orange {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .xl\:from-orange-A100 {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .xl\:from-orange-A200 {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .xl\:from-orange-A400 {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .xl\:from-orange-A700 {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .xl\:from-deep-orange-50 {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .xl\:from-deep-orange-100 {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .xl\:from-deep-orange-200 {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .xl\:from-deep-orange-300 {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .xl\:from-deep-orange-400 {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .xl\:from-deep-orange-500 {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .xl\:from-deep-orange-600 {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .xl\:from-deep-orange-700 {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .xl\:from-deep-orange-800 {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .xl\:from-deep-orange-900 {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .xl\:from-deep-orange {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .xl\:from-deep-orange-A100 {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .xl\:from-deep-orange-A200 {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .xl\:from-deep-orange-A400 {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .xl\:from-deep-orange-A700 {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .xl\:from-yellow-50 {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .xl\:from-yellow-100 {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .xl\:from-yellow-200 {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .xl\:from-yellow-300 {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .xl\:from-yellow-400 {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .xl\:from-yellow-500 {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .xl\:from-yellow-600 {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .xl\:from-yellow-700 {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .xl\:from-yellow-800 {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .xl\:from-yellow-900 {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .xl\:from-yellow {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .xl\:from-yellow-A100 {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .xl\:from-yellow-A200 {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .xl\:from-yellow-A400 {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .xl\:from-yellow-A700 {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .xl\:from-green-50 {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .xl\:from-green-100 {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .xl\:from-green-200 {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .xl\:from-green-300 {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .xl\:from-green-400 {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .xl\:from-green-500 {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .xl\:from-green-600 {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .xl\:from-green-700 {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .xl\:from-green-800 {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .xl\:from-green-900 {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .xl\:from-green {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .xl\:from-green-A100 {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .xl\:from-green-A200 {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .xl\:from-green-A400 {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .xl\:from-green-A700 {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .xl\:from-light-green-50 {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .xl\:from-light-green-100 {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .xl\:from-light-green-200 {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .xl\:from-light-green-300 {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .xl\:from-light-green-400 {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .xl\:from-light-green-500 {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .xl\:from-light-green-600 {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .xl\:from-light-green-700 {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .xl\:from-light-green-800 {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .xl\:from-light-green-900 {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .xl\:from-light-green {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .xl\:from-light-green-A100 {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .xl\:from-light-green-A200 {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .xl\:from-light-green-A400 {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .xl\:from-light-green-A700 {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .xl\:from-teal-50 {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .xl\:from-teal-100 {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .xl\:from-teal-200 {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .xl\:from-teal-300 {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .xl\:from-teal-400 {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .xl\:from-teal-500 {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .xl\:from-teal-600 {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .xl\:from-teal-700 {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .xl\:from-teal-800 {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .xl\:from-teal-900 {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .xl\:from-teal {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .xl\:from-teal-A100 {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .xl\:from-teal-A200 {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .xl\:from-teal-A400 {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .xl\:from-teal-A700 {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .xl\:from-blue-50 {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .xl\:from-blue-100 {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .xl\:from-blue-200 {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .xl\:from-blue-300 {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .xl\:from-blue-400 {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .xl\:from-blue-500 {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .xl\:from-blue-600 {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .xl\:from-blue-700 {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .xl\:from-blue-800 {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .xl\:from-blue-900 {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .xl\:from-blue {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .xl\:from-blue-A100 {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .xl\:from-blue-A200 {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .xl\:from-blue-A400 {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .xl\:from-blue-A700 {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .xl\:from-light-blue-50 {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .xl\:from-light-blue-100 {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .xl\:from-light-blue-200 {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .xl\:from-light-blue-300 {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .xl\:from-light-blue-400 {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .xl\:from-light-blue-500 {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .xl\:from-light-blue-600 {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .xl\:from-light-blue-700 {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .xl\:from-light-blue-800 {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .xl\:from-light-blue-900 {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .xl\:from-light-blue {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .xl\:from-light-blue-A100 {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .xl\:from-light-blue-A200 {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .xl\:from-light-blue-A400 {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .xl\:from-light-blue-A700 {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .xl\:from-indigo-50 {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .xl\:from-indigo-100 {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .xl\:from-indigo-200 {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .xl\:from-indigo-300 {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .xl\:from-indigo-400 {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .xl\:from-indigo-500 {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .xl\:from-indigo-600 {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .xl\:from-indigo-700 {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .xl\:from-indigo-800 {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .xl\:from-indigo-900 {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .xl\:from-indigo {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .xl\:from-indigo-A100 {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .xl\:from-indigo-A200 {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .xl\:from-indigo-A400 {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .xl\:from-indigo-A700 {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .xl\:from-purple-50 {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .xl\:from-purple-100 {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .xl\:from-purple-200 {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .xl\:from-purple-300 {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .xl\:from-purple-400 {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .xl\:from-purple-500 {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .xl\:from-purple-600 {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .xl\:from-purple-700 {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .xl\:from-purple-800 {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .xl\:from-purple-900 {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .xl\:from-purple {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .xl\:from-purple-A100 {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .xl\:from-purple-A200 {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .xl\:from-purple-A400 {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .xl\:from-purple-A700 {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .xl\:from-deep-purple-50 {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .xl\:from-deep-purple-100 {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .xl\:from-deep-purple-200 {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .xl\:from-deep-purple-300 {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .xl\:from-deep-purple-400 {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .xl\:from-deep-purple-500 {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .xl\:from-deep-purple-600 {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .xl\:from-deep-purple-700 {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .xl\:from-deep-purple-800 {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .xl\:from-deep-purple-900 {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .xl\:from-deep-purple {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .xl\:from-deep-purple-A100 {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .xl\:from-deep-purple-A200 {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .xl\:from-deep-purple-A400 {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .xl\:from-deep-purple-A700 {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .xl\:from-pink-50 {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .xl\:from-pink-100 {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .xl\:from-pink-200 {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .xl\:from-pink-300 {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .xl\:from-pink-400 {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .xl\:from-pink-500 {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .xl\:from-pink-600 {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .xl\:from-pink-700 {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .xl\:from-pink-800 {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .xl\:from-pink-900 {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .xl\:from-pink {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .xl\:from-pink-A100 {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .xl\:from-pink-A200 {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .xl\:from-pink-A400 {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .xl\:from-pink-A700 {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .xl\:from-lime-50 {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .xl\:from-lime-100 {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .xl\:from-lime-200 {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .xl\:from-lime-300 {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .xl\:from-lime-400 {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .xl\:from-lime-500 {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .xl\:from-lime-600 {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .xl\:from-lime-700 {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .xl\:from-lime-800 {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .xl\:from-lime-900 {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .xl\:from-lime {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .xl\:from-lime-A100 {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .xl\:from-lime-A200 {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .xl\:from-lime-A400 {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .xl\:from-lime-A700 {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .xl\:from-amber-50 {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .xl\:from-amber-100 {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .xl\:from-amber-200 {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .xl\:from-amber-300 {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .xl\:from-amber-400 {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .xl\:from-amber-500 {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .xl\:from-amber-600 {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .xl\:from-amber-700 {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .xl\:from-amber-800 {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .xl\:from-amber-900 {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .xl\:from-amber {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .xl\:from-amber-A100 {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .xl\:from-amber-A200 {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .xl\:from-amber-A400 {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .xl\:from-amber-A700 {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .xl\:from-brown-50 {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .xl\:from-brown-100 {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .xl\:from-brown-200 {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .xl\:from-brown-300 {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .xl\:from-brown-400 {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .xl\:from-brown-500 {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .xl\:from-brown-600 {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .xl\:from-brown-700 {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .xl\:from-brown-800 {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .xl\:from-brown-900 {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .xl\:from-brown {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .xl\:from-brown-A100 {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .xl\:from-brown-A200 {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .xl\:from-brown-A400 {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .xl\:from-brown-A700 {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .xl\:from-blue-gray-50 {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .xl\:from-blue-gray-100 {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .xl\:from-blue-gray-200 {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .xl\:from-blue-gray-300 {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .xl\:from-blue-gray-400 {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .xl\:from-blue-gray-500 {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .xl\:from-blue-gray-600 {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .xl\:from-blue-gray-700 {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .xl\:from-blue-gray-800 {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .xl\:from-blue-gray-900 {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .xl\:from-blue-gray {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .xl\:from-blue-gray-A100 {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .xl\:from-blue-gray-A200 {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .xl\:from-blue-gray-A400 {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .xl\:from-blue-gray-A700 {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .xl\:from-cyan-50 {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .xl\:from-cyan-100 {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .xl\:from-cyan-200 {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .xl\:from-cyan-300 {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .xl\:from-cyan-400 {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .xl\:from-cyan-500 {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .xl\:from-cyan-600 {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .xl\:from-cyan-700 {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .xl\:from-cyan-800 {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .xl\:from-cyan-900 {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .xl\:from-cyan {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .xl\:from-cyan-A100 {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .xl\:from-cyan-A200 {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .xl\:from-cyan-A400 {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .xl\:from-cyan-A700 {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .xl\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .xl\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:via-grey-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .xl\:via-grey-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .xl\:via-grey-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .xl\:via-grey-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .xl\:via-grey-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .xl\:via-grey-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:via-grey-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .xl\:via-grey-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:via-grey-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .xl\:via-grey-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .xl\:via-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:via-grey-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .xl\:via-grey-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .xl\:via-grey-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .xl\:via-grey-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .xl\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .xl\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .xl\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .xl\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .xl\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .xl\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .xl\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .xl\:via-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:via-gray-hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:via-gray-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .xl\:via-gray-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .xl\:via-gray-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .xl\:via-gray-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .xl\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .xl\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .xl\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .xl\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .xl\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .xl\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .xl\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .xl\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .xl\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .xl\:via-red {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .xl\:via-red-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .xl\:via-red-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .xl\:via-red-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .xl\:via-red-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .xl\:via-orange-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .xl\:via-orange-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .xl\:via-orange-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .xl\:via-orange-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .xl\:via-orange-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .xl\:via-orange-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .xl\:via-orange-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .xl\:via-orange-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .xl\:via-orange-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .xl\:via-orange-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .xl\:via-orange {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .xl\:via-orange-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .xl\:via-orange-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .xl\:via-orange-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .xl\:via-orange-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .xl\:via-deep-orange-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .xl\:via-deep-orange-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .xl\:via-deep-orange-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .xl\:via-deep-orange-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .xl\:via-deep-orange-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .xl\:via-deep-orange-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .xl\:via-deep-orange-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .xl\:via-deep-orange-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .xl\:via-deep-orange-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .xl\:via-deep-orange-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .xl\:via-deep-orange {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .xl\:via-deep-orange-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .xl\:via-deep-orange-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .xl\:via-deep-orange-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .xl\:via-deep-orange-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .xl\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .xl\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .xl\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .xl\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .xl\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .xl\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .xl\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .xl\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .xl\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .xl\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .xl\:via-yellow {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .xl\:via-yellow-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .xl\:via-yellow-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .xl\:via-yellow-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .xl\:via-yellow-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .xl\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .xl\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .xl\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .xl\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .xl\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .xl\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .xl\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .xl\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .xl\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .xl\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .xl\:via-green {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .xl\:via-green-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .xl\:via-green-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .xl\:via-green-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .xl\:via-green-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .xl\:via-light-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .xl\:via-light-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .xl\:via-light-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .xl\:via-light-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .xl\:via-light-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .xl\:via-light-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .xl\:via-light-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .xl\:via-light-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .xl\:via-light-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .xl\:via-light-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .xl\:via-light-green {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .xl\:via-light-green-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .xl\:via-light-green-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .xl\:via-light-green-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .xl\:via-light-green-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .xl\:via-teal-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .xl\:via-teal-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .xl\:via-teal-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .xl\:via-teal-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .xl\:via-teal-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .xl\:via-teal-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .xl\:via-teal-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .xl\:via-teal-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .xl\:via-teal-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .xl\:via-teal-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .xl\:via-teal {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .xl\:via-teal-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .xl\:via-teal-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .xl\:via-teal-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .xl\:via-teal-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .xl\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .xl\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .xl\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .xl\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .xl\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .xl\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .xl\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .xl\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .xl\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .xl\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .xl\:via-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .xl\:via-blue-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .xl\:via-blue-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .xl\:via-blue-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .xl\:via-blue-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .xl\:via-light-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .xl\:via-light-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .xl\:via-light-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .xl\:via-light-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .xl\:via-light-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .xl\:via-light-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .xl\:via-light-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .xl\:via-light-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .xl\:via-light-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .xl\:via-light-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .xl\:via-light-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .xl\:via-light-blue-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .xl\:via-light-blue-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .xl\:via-light-blue-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .xl\:via-light-blue-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .xl\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .xl\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .xl\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .xl\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .xl\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .xl\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .xl\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .xl\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .xl\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .xl\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .xl\:via-indigo {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .xl\:via-indigo-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .xl\:via-indigo-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .xl\:via-indigo-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .xl\:via-indigo-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .xl\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .xl\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .xl\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .xl\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .xl\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .xl\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .xl\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .xl\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .xl\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .xl\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .xl\:via-purple {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .xl\:via-purple-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .xl\:via-purple-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .xl\:via-purple-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .xl\:via-purple-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .xl\:via-deep-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .xl\:via-deep-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .xl\:via-deep-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .xl\:via-deep-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .xl\:via-deep-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .xl\:via-deep-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .xl\:via-deep-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .xl\:via-deep-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .xl\:via-deep-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .xl\:via-deep-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .xl\:via-deep-purple {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .xl\:via-deep-purple-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .xl\:via-deep-purple-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .xl\:via-deep-purple-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .xl\:via-deep-purple-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .xl\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .xl\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .xl\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .xl\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .xl\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .xl\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .xl\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .xl\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .xl\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .xl\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .xl\:via-pink {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .xl\:via-pink-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .xl\:via-pink-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .xl\:via-pink-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .xl\:via-pink-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .xl\:via-lime-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .xl\:via-lime-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .xl\:via-lime-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .xl\:via-lime-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .xl\:via-lime-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .xl\:via-lime-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .xl\:via-lime-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .xl\:via-lime-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .xl\:via-lime-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .xl\:via-lime-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .xl\:via-lime {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .xl\:via-lime-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .xl\:via-lime-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .xl\:via-lime-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .xl\:via-lime-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .xl\:via-amber-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .xl\:via-amber-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .xl\:via-amber-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .xl\:via-amber-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .xl\:via-amber-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .xl\:via-amber-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .xl\:via-amber-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .xl\:via-amber-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .xl\:via-amber-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .xl\:via-amber-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .xl\:via-amber {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .xl\:via-amber-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .xl\:via-amber-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .xl\:via-amber-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .xl\:via-amber-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .xl\:via-brown-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .xl\:via-brown-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .xl\:via-brown-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .xl\:via-brown-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .xl\:via-brown-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .xl\:via-brown-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .xl\:via-brown-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .xl\:via-brown-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .xl\:via-brown-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .xl\:via-brown-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .xl\:via-brown {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .xl\:via-brown-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .xl\:via-brown-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .xl\:via-brown-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .xl\:via-brown-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .xl\:via-blue-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .xl\:via-blue-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .xl\:via-blue-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .xl\:via-blue-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .xl\:via-blue-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .xl\:via-blue-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .xl\:via-blue-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .xl\:via-blue-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .xl\:via-blue-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .xl\:via-blue-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .xl\:via-blue-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .xl\:via-blue-gray-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .xl\:via-blue-gray-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .xl\:via-blue-gray-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .xl\:via-blue-gray-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .xl\:via-cyan-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .xl\:via-cyan-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .xl\:via-cyan-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .xl\:via-cyan-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .xl\:via-cyan-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .xl\:via-cyan-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .xl\:via-cyan-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .xl\:via-cyan-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .xl\:via-cyan-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .xl\:via-cyan-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .xl\:via-cyan {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .xl\:via-cyan-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .xl\:via-cyan-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .xl\:via-cyan-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .xl\:via-cyan-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .xl\:to-transparent {
    --tw-gradient-to: transparent
  }

  .xl\:to-current {
    --tw-gradient-to: currentColor
  }

  .xl\:to-black {
    --tw-gradient-to: #22292F
  }

  .xl\:to-white {
    --tw-gradient-to: #fff
  }

  .xl\:to-grey-50 {
    --tw-gradient-to: #FAFAFA
  }

  .xl\:to-grey-100 {
    --tw-gradient-to: #F5F5F5
  }

  .xl\:to-grey-200 {
    --tw-gradient-to: #EEEEEE
  }

  .xl\:to-grey-300 {
    --tw-gradient-to: #E0E0E0
  }

  .xl\:to-grey-400 {
    --tw-gradient-to: #BDBDBD
  }

  .xl\:to-grey-500 {
    --tw-gradient-to: #9E9E9E
  }

  .xl\:to-grey-600 {
    --tw-gradient-to: #757575
  }

  .xl\:to-grey-700 {
    --tw-gradient-to: #616161
  }

  .xl\:to-grey-800 {
    --tw-gradient-to: #424242
  }

  .xl\:to-grey-900 {
    --tw-gradient-to: #212121
  }

  .xl\:to-grey {
    --tw-gradient-to: #9E9E9E
  }

  .xl\:to-grey-A100 {
    --tw-gradient-to: #D5D5D5
  }

  .xl\:to-grey-A200 {
    --tw-gradient-to: #AAAAAA
  }

  .xl\:to-grey-A400 {
    --tw-gradient-to: #303030
  }

  .xl\:to-grey-A700 {
    --tw-gradient-to: #616161
  }

  .xl\:to-gray-50 {
    --tw-gradient-to: #FAFAFA
  }

  .xl\:to-gray-100 {
    --tw-gradient-to: #F5F5F5
  }

  .xl\:to-gray-200 {
    --tw-gradient-to: #EEEEEE
  }

  .xl\:to-gray-300 {
    --tw-gradient-to: #E0E0E0
  }

  .xl\:to-gray-400 {
    --tw-gradient-to: #BDBDBD
  }

  .xl\:to-gray-500 {
    --tw-gradient-to: #9E9E9E
  }

  .xl\:to-gray-600 {
    --tw-gradient-to: #757575
  }

  .xl\:to-gray-700 {
    --tw-gradient-to: #616161
  }

  .xl\:to-gray-800 {
    --tw-gradient-to: #424242
  }

  .xl\:to-gray-900 {
    --tw-gradient-to: #212121
  }

  .xl\:to-gray {
    --tw-gradient-to: #9E9E9E
  }

  .xl\:to-gray-hover {
    --tw-gradient-to: rgba(0, 0, 0, 0.04)
  }

  .xl\:to-gray-A100 {
    --tw-gradient-to: #D5D5D5
  }

  .xl\:to-gray-A200 {
    --tw-gradient-to: #AAAAAA
  }

  .xl\:to-gray-A400 {
    --tw-gradient-to: #303030
  }

  .xl\:to-gray-A700 {
    --tw-gradient-to: #616161
  }

  .xl\:to-red-50 {
    --tw-gradient-to: #FFEBEE
  }

  .xl\:to-red-100 {
    --tw-gradient-to: #FFCDD2
  }

  .xl\:to-red-200 {
    --tw-gradient-to: #EF9A9A
  }

  .xl\:to-red-300 {
    --tw-gradient-to: #E57373
  }

  .xl\:to-red-400 {
    --tw-gradient-to: #EF5350
  }

  .xl\:to-red-500 {
    --tw-gradient-to: #F44336
  }

  .xl\:to-red-600 {
    --tw-gradient-to: #E53935
  }

  .xl\:to-red-700 {
    --tw-gradient-to: #D32F2F
  }

  .xl\:to-red-800 {
    --tw-gradient-to: #C62828
  }

  .xl\:to-red-900 {
    --tw-gradient-to: #B71C1C
  }

  .xl\:to-red {
    --tw-gradient-to: #F44336
  }

  .xl\:to-red-A100 {
    --tw-gradient-to: #FF8A80
  }

  .xl\:to-red-A200 {
    --tw-gradient-to: #FF5252
  }

  .xl\:to-red-A400 {
    --tw-gradient-to: #FF1744
  }

  .xl\:to-red-A700 {
    --tw-gradient-to: #D50000
  }

  .xl\:to-orange-50 {
    --tw-gradient-to: #FFF3E0
  }

  .xl\:to-orange-100 {
    --tw-gradient-to: #FFE0B2
  }

  .xl\:to-orange-200 {
    --tw-gradient-to: #FFCC80
  }

  .xl\:to-orange-300 {
    --tw-gradient-to: #FFB74D
  }

  .xl\:to-orange-400 {
    --tw-gradient-to: #FFA726
  }

  .xl\:to-orange-500 {
    --tw-gradient-to: #FF9800
  }

  .xl\:to-orange-600 {
    --tw-gradient-to: #FB8C00
  }

  .xl\:to-orange-700 {
    --tw-gradient-to: #F57C00
  }

  .xl\:to-orange-800 {
    --tw-gradient-to: #EF6C00
  }

  .xl\:to-orange-900 {
    --tw-gradient-to: #E65100
  }

  .xl\:to-orange {
    --tw-gradient-to: #FF9800
  }

  .xl\:to-orange-A100 {
    --tw-gradient-to: #FFD180
  }

  .xl\:to-orange-A200 {
    --tw-gradient-to: #FFAB40
  }

  .xl\:to-orange-A400 {
    --tw-gradient-to: #FF9100
  }

  .xl\:to-orange-A700 {
    --tw-gradient-to: #FF6D00
  }

  .xl\:to-deep-orange-50 {
    --tw-gradient-to: #FBE9E7
  }

  .xl\:to-deep-orange-100 {
    --tw-gradient-to: #FFCCBC
  }

  .xl\:to-deep-orange-200 {
    --tw-gradient-to: #FFAB91
  }

  .xl\:to-deep-orange-300 {
    --tw-gradient-to: #FF8A65
  }

  .xl\:to-deep-orange-400 {
    --tw-gradient-to: #FF7043
  }

  .xl\:to-deep-orange-500 {
    --tw-gradient-to: #FF5722
  }

  .xl\:to-deep-orange-600 {
    --tw-gradient-to: #F4511E
  }

  .xl\:to-deep-orange-700 {
    --tw-gradient-to: #E64A19
  }

  .xl\:to-deep-orange-800 {
    --tw-gradient-to: #D84315
  }

  .xl\:to-deep-orange-900 {
    --tw-gradient-to: #BF360C
  }

  .xl\:to-deep-orange {
    --tw-gradient-to: #FF5722
  }

  .xl\:to-deep-orange-A100 {
    --tw-gradient-to: #FF9E80
  }

  .xl\:to-deep-orange-A200 {
    --tw-gradient-to: #FF6E40
  }

  .xl\:to-deep-orange-A400 {
    --tw-gradient-to: #FF3D00
  }

  .xl\:to-deep-orange-A700 {
    --tw-gradient-to: #DD2C00
  }

  .xl\:to-yellow-50 {
    --tw-gradient-to: #FFFDE7
  }

  .xl\:to-yellow-100 {
    --tw-gradient-to: #FFF9C4
  }

  .xl\:to-yellow-200 {
    --tw-gradient-to: #FFF59D
  }

  .xl\:to-yellow-300 {
    --tw-gradient-to: #FFF176
  }

  .xl\:to-yellow-400 {
    --tw-gradient-to: #FFEE58
  }

  .xl\:to-yellow-500 {
    --tw-gradient-to: #FFEB3B
  }

  .xl\:to-yellow-600 {
    --tw-gradient-to: #FDD835
  }

  .xl\:to-yellow-700 {
    --tw-gradient-to: #FBC02D
  }

  .xl\:to-yellow-800 {
    --tw-gradient-to: #F9A825
  }

  .xl\:to-yellow-900 {
    --tw-gradient-to: #F57F17
  }

  .xl\:to-yellow {
    --tw-gradient-to: #FFEB3B
  }

  .xl\:to-yellow-A100 {
    --tw-gradient-to: #FFFF8D
  }

  .xl\:to-yellow-A200 {
    --tw-gradient-to: #FFFF00
  }

  .xl\:to-yellow-A400 {
    --tw-gradient-to: #FFEA00
  }

  .xl\:to-yellow-A700 {
    --tw-gradient-to: #FFD600
  }

  .xl\:to-green-50 {
    --tw-gradient-to: #E8F5E9
  }

  .xl\:to-green-100 {
    --tw-gradient-to: #C8E6C9
  }

  .xl\:to-green-200 {
    --tw-gradient-to: #A5D6A7
  }

  .xl\:to-green-300 {
    --tw-gradient-to: #81C784
  }

  .xl\:to-green-400 {
    --tw-gradient-to: #66BB6A
  }

  .xl\:to-green-500 {
    --tw-gradient-to: #4CAF50
  }

  .xl\:to-green-600 {
    --tw-gradient-to: #43A047
  }

  .xl\:to-green-700 {
    --tw-gradient-to: #388E3C
  }

  .xl\:to-green-800 {
    --tw-gradient-to: #2E7D32
  }

  .xl\:to-green-900 {
    --tw-gradient-to: #1B5E20
  }

  .xl\:to-green {
    --tw-gradient-to: #4CAF50
  }

  .xl\:to-green-A100 {
    --tw-gradient-to: #B9F6CA
  }

  .xl\:to-green-A200 {
    --tw-gradient-to: #69F0AE
  }

  .xl\:to-green-A400 {
    --tw-gradient-to: #00E676
  }

  .xl\:to-green-A700 {
    --tw-gradient-to: #00C853
  }

  .xl\:to-light-green-50 {
    --tw-gradient-to: #F1F8E9
  }

  .xl\:to-light-green-100 {
    --tw-gradient-to: #DCEDC8
  }

  .xl\:to-light-green-200 {
    --tw-gradient-to: #C5E1A5
  }

  .xl\:to-light-green-300 {
    --tw-gradient-to: #AED581
  }

  .xl\:to-light-green-400 {
    --tw-gradient-to: #9CCC65
  }

  .xl\:to-light-green-500 {
    --tw-gradient-to: #8BC34A
  }

  .xl\:to-light-green-600 {
    --tw-gradient-to: #7CB342
  }

  .xl\:to-light-green-700 {
    --tw-gradient-to: #689F38
  }

  .xl\:to-light-green-800 {
    --tw-gradient-to: #558B2F
  }

  .xl\:to-light-green-900 {
    --tw-gradient-to: #33691E
  }

  .xl\:to-light-green {
    --tw-gradient-to: #8BC34A
  }

  .xl\:to-light-green-A100 {
    --tw-gradient-to: #CCFF90
  }

  .xl\:to-light-green-A200 {
    --tw-gradient-to: #B2FF59
  }

  .xl\:to-light-green-A400 {
    --tw-gradient-to: #76FF03
  }

  .xl\:to-light-green-A700 {
    --tw-gradient-to: #64DD17
  }

  .xl\:to-teal-50 {
    --tw-gradient-to: #E0F2F1
  }

  .xl\:to-teal-100 {
    --tw-gradient-to: #B2DFDB
  }

  .xl\:to-teal-200 {
    --tw-gradient-to: #80CBC4
  }

  .xl\:to-teal-300 {
    --tw-gradient-to: #4DB6AC
  }

  .xl\:to-teal-400 {
    --tw-gradient-to: #26A69A
  }

  .xl\:to-teal-500 {
    --tw-gradient-to: #009688
  }

  .xl\:to-teal-600 {
    --tw-gradient-to: #00897B
  }

  .xl\:to-teal-700 {
    --tw-gradient-to: #00796B
  }

  .xl\:to-teal-800 {
    --tw-gradient-to: #00695C
  }

  .xl\:to-teal-900 {
    --tw-gradient-to: #004D40
  }

  .xl\:to-teal {
    --tw-gradient-to: #009688
  }

  .xl\:to-teal-A100 {
    --tw-gradient-to: #A7FFEB
  }

  .xl\:to-teal-A200 {
    --tw-gradient-to: #64FFDA
  }

  .xl\:to-teal-A400 {
    --tw-gradient-to: #1DE9B6
  }

  .xl\:to-teal-A700 {
    --tw-gradient-to: #00BFA5
  }

  .xl\:to-blue-50 {
    --tw-gradient-to: #E3F2FD
  }

  .xl\:to-blue-100 {
    --tw-gradient-to: #BBDEFB
  }

  .xl\:to-blue-200 {
    --tw-gradient-to: #90CAF9
  }

  .xl\:to-blue-300 {
    --tw-gradient-to: #64B5F6
  }

  .xl\:to-blue-400 {
    --tw-gradient-to: #42A5F5
  }

  .xl\:to-blue-500 {
    --tw-gradient-to: #2196F3
  }

  .xl\:to-blue-600 {
    --tw-gradient-to: #1E88E5
  }

  .xl\:to-blue-700 {
    --tw-gradient-to: #1976D2
  }

  .xl\:to-blue-800 {
    --tw-gradient-to: #1565C0
  }

  .xl\:to-blue-900 {
    --tw-gradient-to: #0D47A1
  }

  .xl\:to-blue {
    --tw-gradient-to: #2196F3
  }

  .xl\:to-blue-A100 {
    --tw-gradient-to: #82B1FF
  }

  .xl\:to-blue-A200 {
    --tw-gradient-to: #448AFF
  }

  .xl\:to-blue-A400 {
    --tw-gradient-to: #2979FF
  }

  .xl\:to-blue-A700 {
    --tw-gradient-to: #2962FF
  }

  .xl\:to-light-blue-50 {
    --tw-gradient-to: #E1F5FE
  }

  .xl\:to-light-blue-100 {
    --tw-gradient-to: #B3E5FC
  }

  .xl\:to-light-blue-200 {
    --tw-gradient-to: #81D4FA
  }

  .xl\:to-light-blue-300 {
    --tw-gradient-to: #4FC3F7
  }

  .xl\:to-light-blue-400 {
    --tw-gradient-to: #29B6F6
  }

  .xl\:to-light-blue-500 {
    --tw-gradient-to: #03A9F4
  }

  .xl\:to-light-blue-600 {
    --tw-gradient-to: #039BE5
  }

  .xl\:to-light-blue-700 {
    --tw-gradient-to: #0288D1
  }

  .xl\:to-light-blue-800 {
    --tw-gradient-to: #0277BD
  }

  .xl\:to-light-blue-900 {
    --tw-gradient-to: #01579B
  }

  .xl\:to-light-blue {
    --tw-gradient-to: #03A9F4
  }

  .xl\:to-light-blue-A100 {
    --tw-gradient-to: #80D8FF
  }

  .xl\:to-light-blue-A200 {
    --tw-gradient-to: #40C4FF
  }

  .xl\:to-light-blue-A400 {
    --tw-gradient-to: #00B0FF
  }

  .xl\:to-light-blue-A700 {
    --tw-gradient-to: #0091EA
  }

  .xl\:to-indigo-50 {
    --tw-gradient-to: #E8EAF6
  }

  .xl\:to-indigo-100 {
    --tw-gradient-to: #C5CAE9
  }

  .xl\:to-indigo-200 {
    --tw-gradient-to: #9FA8DA
  }

  .xl\:to-indigo-300 {
    --tw-gradient-to: #7986CB
  }

  .xl\:to-indigo-400 {
    --tw-gradient-to: #5C6BC0
  }

  .xl\:to-indigo-500 {
    --tw-gradient-to: #3F51B5
  }

  .xl\:to-indigo-600 {
    --tw-gradient-to: #3949AB
  }

  .xl\:to-indigo-700 {
    --tw-gradient-to: #303F9F
  }

  .xl\:to-indigo-800 {
    --tw-gradient-to: #283593
  }

  .xl\:to-indigo-900 {
    --tw-gradient-to: #1A237E
  }

  .xl\:to-indigo {
    --tw-gradient-to: #3F51B5
  }

  .xl\:to-indigo-A100 {
    --tw-gradient-to: #8C9EFF
  }

  .xl\:to-indigo-A200 {
    --tw-gradient-to: #536DFE
  }

  .xl\:to-indigo-A400 {
    --tw-gradient-to: #3D5AFE
  }

  .xl\:to-indigo-A700 {
    --tw-gradient-to: #304FFE
  }

  .xl\:to-purple-50 {
    --tw-gradient-to: #F3E5F5
  }

  .xl\:to-purple-100 {
    --tw-gradient-to: #E1BEE7
  }

  .xl\:to-purple-200 {
    --tw-gradient-to: #CE93D8
  }

  .xl\:to-purple-300 {
    --tw-gradient-to: #BA68C8
  }

  .xl\:to-purple-400 {
    --tw-gradient-to: #AB47BC
  }

  .xl\:to-purple-500 {
    --tw-gradient-to: #9C27B0
  }

  .xl\:to-purple-600 {
    --tw-gradient-to: #8E24AA
  }

  .xl\:to-purple-700 {
    --tw-gradient-to: #7B1FA2
  }

  .xl\:to-purple-800 {
    --tw-gradient-to: #6A1B9A
  }

  .xl\:to-purple-900 {
    --tw-gradient-to: #4A148C
  }

  .xl\:to-purple {
    --tw-gradient-to: #9C27B0
  }

  .xl\:to-purple-A100 {
    --tw-gradient-to: #EA80FC
  }

  .xl\:to-purple-A200 {
    --tw-gradient-to: #E040FB
  }

  .xl\:to-purple-A400 {
    --tw-gradient-to: #D500F9
  }

  .xl\:to-purple-A700 {
    --tw-gradient-to: #AA00FF
  }

  .xl\:to-deep-purple-50 {
    --tw-gradient-to: #EDE7F6
  }

  .xl\:to-deep-purple-100 {
    --tw-gradient-to: #D1C4E9
  }

  .xl\:to-deep-purple-200 {
    --tw-gradient-to: #B39DDB
  }

  .xl\:to-deep-purple-300 {
    --tw-gradient-to: #9575CD
  }

  .xl\:to-deep-purple-400 {
    --tw-gradient-to: #7E57C2
  }

  .xl\:to-deep-purple-500 {
    --tw-gradient-to: #673AB7
  }

  .xl\:to-deep-purple-600 {
    --tw-gradient-to: #5E35B1
  }

  .xl\:to-deep-purple-700 {
    --tw-gradient-to: #512DA8
  }

  .xl\:to-deep-purple-800 {
    --tw-gradient-to: #4527A0
  }

  .xl\:to-deep-purple-900 {
    --tw-gradient-to: #311B92
  }

  .xl\:to-deep-purple {
    --tw-gradient-to: #673AB7
  }

  .xl\:to-deep-purple-A100 {
    --tw-gradient-to: #B388FF
  }

  .xl\:to-deep-purple-A200 {
    --tw-gradient-to: #7C4DFF
  }

  .xl\:to-deep-purple-A400 {
    --tw-gradient-to: #651FFF
  }

  .xl\:to-deep-purple-A700 {
    --tw-gradient-to: #6200EA
  }

  .xl\:to-pink-50 {
    --tw-gradient-to: #FCE4EC
  }

  .xl\:to-pink-100 {
    --tw-gradient-to: #F8BBD0
  }

  .xl\:to-pink-200 {
    --tw-gradient-to: #F48FB1
  }

  .xl\:to-pink-300 {
    --tw-gradient-to: #F06292
  }

  .xl\:to-pink-400 {
    --tw-gradient-to: #EC407A
  }

  .xl\:to-pink-500 {
    --tw-gradient-to: #E91E63
  }

  .xl\:to-pink-600 {
    --tw-gradient-to: #D81B60
  }

  .xl\:to-pink-700 {
    --tw-gradient-to: #C2185B
  }

  .xl\:to-pink-800 {
    --tw-gradient-to: #AD1457
  }

  .xl\:to-pink-900 {
    --tw-gradient-to: #880E4F
  }

  .xl\:to-pink {
    --tw-gradient-to: #E91E63
  }

  .xl\:to-pink-A100 {
    --tw-gradient-to: #FF80AB
  }

  .xl\:to-pink-A200 {
    --tw-gradient-to: #FF4081
  }

  .xl\:to-pink-A400 {
    --tw-gradient-to: #F50057
  }

  .xl\:to-pink-A700 {
    --tw-gradient-to: #C51162
  }

  .xl\:to-lime-50 {
    --tw-gradient-to: #F9FBE7
  }

  .xl\:to-lime-100 {
    --tw-gradient-to: #F0F4C3
  }

  .xl\:to-lime-200 {
    --tw-gradient-to: #E6EE9C
  }

  .xl\:to-lime-300 {
    --tw-gradient-to: #DCE775
  }

  .xl\:to-lime-400 {
    --tw-gradient-to: #D4E157
  }

  .xl\:to-lime-500 {
    --tw-gradient-to: #CDDC39
  }

  .xl\:to-lime-600 {
    --tw-gradient-to: #C0CA33
  }

  .xl\:to-lime-700 {
    --tw-gradient-to: #AFB42B
  }

  .xl\:to-lime-800 {
    --tw-gradient-to: #9E9D24
  }

  .xl\:to-lime-900 {
    --tw-gradient-to: #827717
  }

  .xl\:to-lime {
    --tw-gradient-to: #CDDC39
  }

  .xl\:to-lime-A100 {
    --tw-gradient-to: #F4FF81
  }

  .xl\:to-lime-A200 {
    --tw-gradient-to: #EEFF41
  }

  .xl\:to-lime-A400 {
    --tw-gradient-to: #C6FF00
  }

  .xl\:to-lime-A700 {
    --tw-gradient-to: #AEEA00
  }

  .xl\:to-amber-50 {
    --tw-gradient-to: #FFF8E1
  }

  .xl\:to-amber-100 {
    --tw-gradient-to: #FFECB3
  }

  .xl\:to-amber-200 {
    --tw-gradient-to: #FFE082
  }

  .xl\:to-amber-300 {
    --tw-gradient-to: #FFD54F
  }

  .xl\:to-amber-400 {
    --tw-gradient-to: #FFCA28
  }

  .xl\:to-amber-500 {
    --tw-gradient-to: #FFC107
  }

  .xl\:to-amber-600 {
    --tw-gradient-to: #FFB300
  }

  .xl\:to-amber-700 {
    --tw-gradient-to: #FFA000
  }

  .xl\:to-amber-800 {
    --tw-gradient-to: #FF8F00
  }

  .xl\:to-amber-900 {
    --tw-gradient-to: #FF6F00
  }

  .xl\:to-amber {
    --tw-gradient-to: #FFC107
  }

  .xl\:to-amber-A100 {
    --tw-gradient-to: #FFE57F
  }

  .xl\:to-amber-A200 {
    --tw-gradient-to: #FFD740
  }

  .xl\:to-amber-A400 {
    --tw-gradient-to: #FFC400
  }

  .xl\:to-amber-A700 {
    --tw-gradient-to: #FFAB00
  }

  .xl\:to-brown-50 {
    --tw-gradient-to: #EFEBE9
  }

  .xl\:to-brown-100 {
    --tw-gradient-to: #D7CCC8
  }

  .xl\:to-brown-200 {
    --tw-gradient-to: #BCAAA4
  }

  .xl\:to-brown-300 {
    --tw-gradient-to: #A1887F
  }

  .xl\:to-brown-400 {
    --tw-gradient-to: #8D6E63
  }

  .xl\:to-brown-500 {
    --tw-gradient-to: #795548
  }

  .xl\:to-brown-600 {
    --tw-gradient-to: #6D4C41
  }

  .xl\:to-brown-700 {
    --tw-gradient-to: #5D4037
  }

  .xl\:to-brown-800 {
    --tw-gradient-to: #4E342E
  }

  .xl\:to-brown-900 {
    --tw-gradient-to: #3E2723
  }

  .xl\:to-brown {
    --tw-gradient-to: #795548
  }

  .xl\:to-brown-A100 {
    --tw-gradient-to: #D7CCC8
  }

  .xl\:to-brown-A200 {
    --tw-gradient-to: #BCAAA4
  }

  .xl\:to-brown-A400 {
    --tw-gradient-to: #8D6E63
  }

  .xl\:to-brown-A700 {
    --tw-gradient-to: #5D4037
  }

  .xl\:to-blue-gray-50 {
    --tw-gradient-to: #ECEFF1
  }

  .xl\:to-blue-gray-100 {
    --tw-gradient-to: #CFD8DC
  }

  .xl\:to-blue-gray-200 {
    --tw-gradient-to: #B0BEC5
  }

  .xl\:to-blue-gray-300 {
    --tw-gradient-to: #90A4AE
  }

  .xl\:to-blue-gray-400 {
    --tw-gradient-to: #78909C
  }

  .xl\:to-blue-gray-500 {
    --tw-gradient-to: #607D8B
  }

  .xl\:to-blue-gray-600 {
    --tw-gradient-to: #546E7A
  }

  .xl\:to-blue-gray-700 {
    --tw-gradient-to: #455A64
  }

  .xl\:to-blue-gray-800 {
    --tw-gradient-to: #37474F
  }

  .xl\:to-blue-gray-900 {
    --tw-gradient-to: #263238
  }

  .xl\:to-blue-gray {
    --tw-gradient-to: #607D8B
  }

  .xl\:to-blue-gray-A100 {
    --tw-gradient-to: #CFD8DC
  }

  .xl\:to-blue-gray-A200 {
    --tw-gradient-to: #B0BEC5
  }

  .xl\:to-blue-gray-A400 {
    --tw-gradient-to: #78909C
  }

  .xl\:to-blue-gray-A700 {
    --tw-gradient-to: #455A64
  }

  .xl\:to-cyan-50 {
    --tw-gradient-to: #E0F7FA
  }

  .xl\:to-cyan-100 {
    --tw-gradient-to: #B2EBF2
  }

  .xl\:to-cyan-200 {
    --tw-gradient-to: #80DEEA
  }

  .xl\:to-cyan-300 {
    --tw-gradient-to: #4DD0E1
  }

  .xl\:to-cyan-400 {
    --tw-gradient-to: #26C6DA
  }

  .xl\:to-cyan-500 {
    --tw-gradient-to: #00BCD4
  }

  .xl\:to-cyan-600 {
    --tw-gradient-to: #00ACC1
  }

  .xl\:to-cyan-700 {
    --tw-gradient-to: #0097A7
  }

  .xl\:to-cyan-800 {
    --tw-gradient-to: #00838F
  }

  .xl\:to-cyan-900 {
    --tw-gradient-to: #006064
  }

  .xl\:to-cyan {
    --tw-gradient-to: #00BCD4
  }

  .xl\:to-cyan-A100 {
    --tw-gradient-to: #84FFFF
  }

  .xl\:to-cyan-A200 {
    --tw-gradient-to: #18FFFF
  }

  .xl\:to-cyan-A400 {
    --tw-gradient-to: #00E5FF
  }

  .xl\:to-cyan-A700 {
    --tw-gradient-to: #00B8D4
  }

  .xl\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-black:hover {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .xl\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-grey-50:hover {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .xl\:hover\:from-grey-100:hover {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .xl\:hover\:from-grey-200:hover {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .xl\:hover\:from-grey-300:hover {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .xl\:hover\:from-grey-400:hover {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .xl\:hover\:from-grey-500:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:hover\:from-grey-600:hover {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .xl\:hover\:from-grey-700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:hover\:from-grey-800:hover {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .xl\:hover\:from-grey-900:hover {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .xl\:hover\:from-grey:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:hover\:from-grey-A100:hover {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .xl\:hover\:from-grey-A200:hover {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .xl\:hover\:from-grey-A400:hover {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .xl\:hover\:from-grey-A700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:hover\:from-gray-50:hover {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .xl\:hover\:from-gray-100:hover {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .xl\:hover\:from-gray-200:hover {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .xl\:hover\:from-gray-300:hover {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .xl\:hover\:from-gray-400:hover {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .xl\:hover\:from-gray-500:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:hover\:from-gray-600:hover {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .xl\:hover\:from-gray-700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:hover\:from-gray-800:hover {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .xl\:hover\:from-gray-900:hover {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .xl\:hover\:from-gray:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:hover\:from-gray-hover:hover {
    --tw-gradient-from: rgba(0, 0, 0, 0.04);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:from-gray-A100:hover {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .xl\:hover\:from-gray-A200:hover {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .xl\:hover\:from-gray-A400:hover {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .xl\:hover\:from-gray-A700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:hover\:from-red-50:hover {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .xl\:hover\:from-red-100:hover {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .xl\:hover\:from-red-200:hover {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .xl\:hover\:from-red-300:hover {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .xl\:hover\:from-red-400:hover {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .xl\:hover\:from-red-500:hover {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .xl\:hover\:from-red-600:hover {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .xl\:hover\:from-red-700:hover {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .xl\:hover\:from-red-800:hover {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .xl\:hover\:from-red-900:hover {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .xl\:hover\:from-red:hover {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .xl\:hover\:from-red-A100:hover {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .xl\:hover\:from-red-A200:hover {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .xl\:hover\:from-red-A400:hover {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .xl\:hover\:from-red-A700:hover {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .xl\:hover\:from-orange-50:hover {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .xl\:hover\:from-orange-100:hover {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .xl\:hover\:from-orange-200:hover {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .xl\:hover\:from-orange-300:hover {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .xl\:hover\:from-orange-400:hover {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .xl\:hover\:from-orange-500:hover {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .xl\:hover\:from-orange-600:hover {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .xl\:hover\:from-orange-700:hover {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .xl\:hover\:from-orange-800:hover {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .xl\:hover\:from-orange-900:hover {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .xl\:hover\:from-orange:hover {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .xl\:hover\:from-orange-A100:hover {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .xl\:hover\:from-orange-A200:hover {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .xl\:hover\:from-orange-A400:hover {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .xl\:hover\:from-orange-A700:hover {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .xl\:hover\:from-deep-orange-50:hover {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .xl\:hover\:from-deep-orange-100:hover {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .xl\:hover\:from-deep-orange-200:hover {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .xl\:hover\:from-deep-orange-300:hover {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .xl\:hover\:from-deep-orange-400:hover {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .xl\:hover\:from-deep-orange-500:hover {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .xl\:hover\:from-deep-orange-600:hover {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .xl\:hover\:from-deep-orange-700:hover {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .xl\:hover\:from-deep-orange-800:hover {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .xl\:hover\:from-deep-orange-900:hover {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .xl\:hover\:from-deep-orange:hover {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .xl\:hover\:from-deep-orange-A100:hover {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .xl\:hover\:from-deep-orange-A200:hover {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .xl\:hover\:from-deep-orange-A400:hover {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .xl\:hover\:from-deep-orange-A700:hover {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .xl\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .xl\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .xl\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .xl\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .xl\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .xl\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .xl\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .xl\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .xl\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .xl\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .xl\:hover\:from-yellow:hover {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .xl\:hover\:from-yellow-A100:hover {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .xl\:hover\:from-yellow-A200:hover {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .xl\:hover\:from-yellow-A400:hover {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .xl\:hover\:from-yellow-A700:hover {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .xl\:hover\:from-green-50:hover {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .xl\:hover\:from-green-100:hover {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .xl\:hover\:from-green-200:hover {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .xl\:hover\:from-green-300:hover {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .xl\:hover\:from-green-400:hover {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .xl\:hover\:from-green-500:hover {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .xl\:hover\:from-green-600:hover {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .xl\:hover\:from-green-700:hover {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .xl\:hover\:from-green-800:hover {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .xl\:hover\:from-green-900:hover {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .xl\:hover\:from-green:hover {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .xl\:hover\:from-green-A100:hover {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .xl\:hover\:from-green-A200:hover {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .xl\:hover\:from-green-A400:hover {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .xl\:hover\:from-green-A700:hover {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .xl\:hover\:from-light-green-50:hover {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .xl\:hover\:from-light-green-100:hover {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .xl\:hover\:from-light-green-200:hover {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .xl\:hover\:from-light-green-300:hover {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .xl\:hover\:from-light-green-400:hover {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .xl\:hover\:from-light-green-500:hover {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .xl\:hover\:from-light-green-600:hover {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .xl\:hover\:from-light-green-700:hover {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .xl\:hover\:from-light-green-800:hover {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .xl\:hover\:from-light-green-900:hover {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .xl\:hover\:from-light-green:hover {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .xl\:hover\:from-light-green-A100:hover {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .xl\:hover\:from-light-green-A200:hover {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .xl\:hover\:from-light-green-A400:hover {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .xl\:hover\:from-light-green-A700:hover {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .xl\:hover\:from-teal-50:hover {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .xl\:hover\:from-teal-100:hover {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .xl\:hover\:from-teal-200:hover {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .xl\:hover\:from-teal-300:hover {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .xl\:hover\:from-teal-400:hover {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .xl\:hover\:from-teal-500:hover {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .xl\:hover\:from-teal-600:hover {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .xl\:hover\:from-teal-700:hover {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .xl\:hover\:from-teal-800:hover {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .xl\:hover\:from-teal-900:hover {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .xl\:hover\:from-teal:hover {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .xl\:hover\:from-teal-A100:hover {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .xl\:hover\:from-teal-A200:hover {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .xl\:hover\:from-teal-A400:hover {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .xl\:hover\:from-teal-A700:hover {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .xl\:hover\:from-blue-50:hover {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .xl\:hover\:from-blue-100:hover {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .xl\:hover\:from-blue-200:hover {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .xl\:hover\:from-blue-300:hover {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .xl\:hover\:from-blue-400:hover {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .xl\:hover\:from-blue-500:hover {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .xl\:hover\:from-blue-600:hover {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .xl\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .xl\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .xl\:hover\:from-blue-900:hover {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .xl\:hover\:from-blue:hover {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .xl\:hover\:from-blue-A100:hover {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .xl\:hover\:from-blue-A200:hover {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .xl\:hover\:from-blue-A400:hover {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .xl\:hover\:from-blue-A700:hover {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .xl\:hover\:from-light-blue-50:hover {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .xl\:hover\:from-light-blue-100:hover {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .xl\:hover\:from-light-blue-200:hover {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .xl\:hover\:from-light-blue-300:hover {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .xl\:hover\:from-light-blue-400:hover {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .xl\:hover\:from-light-blue-500:hover {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .xl\:hover\:from-light-blue-600:hover {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .xl\:hover\:from-light-blue-700:hover {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .xl\:hover\:from-light-blue-800:hover {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .xl\:hover\:from-light-blue-900:hover {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .xl\:hover\:from-light-blue:hover {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .xl\:hover\:from-light-blue-A100:hover {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .xl\:hover\:from-light-blue-A200:hover {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .xl\:hover\:from-light-blue-A400:hover {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .xl\:hover\:from-light-blue-A700:hover {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .xl\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .xl\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .xl\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .xl\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .xl\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .xl\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .xl\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .xl\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .xl\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .xl\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .xl\:hover\:from-indigo:hover {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .xl\:hover\:from-indigo-A100:hover {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .xl\:hover\:from-indigo-A200:hover {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .xl\:hover\:from-indigo-A400:hover {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .xl\:hover\:from-indigo-A700:hover {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .xl\:hover\:from-purple-50:hover {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .xl\:hover\:from-purple-100:hover {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .xl\:hover\:from-purple-200:hover {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .xl\:hover\:from-purple-300:hover {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .xl\:hover\:from-purple-400:hover {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .xl\:hover\:from-purple-500:hover {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .xl\:hover\:from-purple-600:hover {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .xl\:hover\:from-purple-700:hover {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .xl\:hover\:from-purple-800:hover {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .xl\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .xl\:hover\:from-purple:hover {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .xl\:hover\:from-purple-A100:hover {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .xl\:hover\:from-purple-A200:hover {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .xl\:hover\:from-purple-A400:hover {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .xl\:hover\:from-purple-A700:hover {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .xl\:hover\:from-deep-purple-50:hover {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .xl\:hover\:from-deep-purple-100:hover {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .xl\:hover\:from-deep-purple-200:hover {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .xl\:hover\:from-deep-purple-300:hover {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .xl\:hover\:from-deep-purple-400:hover {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .xl\:hover\:from-deep-purple-500:hover {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .xl\:hover\:from-deep-purple-600:hover {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .xl\:hover\:from-deep-purple-700:hover {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .xl\:hover\:from-deep-purple-800:hover {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .xl\:hover\:from-deep-purple-900:hover {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .xl\:hover\:from-deep-purple:hover {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .xl\:hover\:from-deep-purple-A100:hover {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .xl\:hover\:from-deep-purple-A200:hover {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .xl\:hover\:from-deep-purple-A400:hover {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .xl\:hover\:from-deep-purple-A700:hover {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .xl\:hover\:from-pink-50:hover {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .xl\:hover\:from-pink-100:hover {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .xl\:hover\:from-pink-200:hover {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .xl\:hover\:from-pink-300:hover {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .xl\:hover\:from-pink-400:hover {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .xl\:hover\:from-pink-500:hover {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .xl\:hover\:from-pink-600:hover {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .xl\:hover\:from-pink-700:hover {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .xl\:hover\:from-pink-800:hover {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .xl\:hover\:from-pink-900:hover {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .xl\:hover\:from-pink:hover {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .xl\:hover\:from-pink-A100:hover {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .xl\:hover\:from-pink-A200:hover {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .xl\:hover\:from-pink-A400:hover {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .xl\:hover\:from-pink-A700:hover {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .xl\:hover\:from-lime-50:hover {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .xl\:hover\:from-lime-100:hover {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .xl\:hover\:from-lime-200:hover {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .xl\:hover\:from-lime-300:hover {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .xl\:hover\:from-lime-400:hover {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .xl\:hover\:from-lime-500:hover {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .xl\:hover\:from-lime-600:hover {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .xl\:hover\:from-lime-700:hover {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .xl\:hover\:from-lime-800:hover {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .xl\:hover\:from-lime-900:hover {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .xl\:hover\:from-lime:hover {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .xl\:hover\:from-lime-A100:hover {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .xl\:hover\:from-lime-A200:hover {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .xl\:hover\:from-lime-A400:hover {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .xl\:hover\:from-lime-A700:hover {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .xl\:hover\:from-amber-50:hover {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .xl\:hover\:from-amber-100:hover {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .xl\:hover\:from-amber-200:hover {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .xl\:hover\:from-amber-300:hover {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .xl\:hover\:from-amber-400:hover {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .xl\:hover\:from-amber-500:hover {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .xl\:hover\:from-amber-600:hover {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .xl\:hover\:from-amber-700:hover {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .xl\:hover\:from-amber-800:hover {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .xl\:hover\:from-amber-900:hover {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .xl\:hover\:from-amber:hover {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .xl\:hover\:from-amber-A100:hover {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .xl\:hover\:from-amber-A200:hover {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .xl\:hover\:from-amber-A400:hover {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .xl\:hover\:from-amber-A700:hover {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .xl\:hover\:from-brown-50:hover {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .xl\:hover\:from-brown-100:hover {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .xl\:hover\:from-brown-200:hover {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .xl\:hover\:from-brown-300:hover {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .xl\:hover\:from-brown-400:hover {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .xl\:hover\:from-brown-500:hover {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .xl\:hover\:from-brown-600:hover {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .xl\:hover\:from-brown-700:hover {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .xl\:hover\:from-brown-800:hover {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .xl\:hover\:from-brown-900:hover {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .xl\:hover\:from-brown:hover {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .xl\:hover\:from-brown-A100:hover {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .xl\:hover\:from-brown-A200:hover {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .xl\:hover\:from-brown-A400:hover {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .xl\:hover\:from-brown-A700:hover {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .xl\:hover\:from-blue-gray-50:hover {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .xl\:hover\:from-blue-gray-100:hover {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .xl\:hover\:from-blue-gray-200:hover {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .xl\:hover\:from-blue-gray-300:hover {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .xl\:hover\:from-blue-gray-400:hover {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .xl\:hover\:from-blue-gray-500:hover {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .xl\:hover\:from-blue-gray-600:hover {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .xl\:hover\:from-blue-gray-700:hover {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .xl\:hover\:from-blue-gray-800:hover {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .xl\:hover\:from-blue-gray-900:hover {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .xl\:hover\:from-blue-gray:hover {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .xl\:hover\:from-blue-gray-A100:hover {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .xl\:hover\:from-blue-gray-A200:hover {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .xl\:hover\:from-blue-gray-A400:hover {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .xl\:hover\:from-blue-gray-A700:hover {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .xl\:hover\:from-cyan-50:hover {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .xl\:hover\:from-cyan-100:hover {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .xl\:hover\:from-cyan-200:hover {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .xl\:hover\:from-cyan-300:hover {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .xl\:hover\:from-cyan-400:hover {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .xl\:hover\:from-cyan-500:hover {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .xl\:hover\:from-cyan-600:hover {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .xl\:hover\:from-cyan-700:hover {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .xl\:hover\:from-cyan-800:hover {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .xl\:hover\:from-cyan-900:hover {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .xl\:hover\:from-cyan:hover {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .xl\:hover\:from-cyan-A100:hover {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .xl\:hover\:from-cyan-A200:hover {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .xl\:hover\:from-cyan-A400:hover {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .xl\:hover\:from-cyan-A700:hover {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .xl\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .xl\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-grey-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .xl\:hover\:via-grey-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .xl\:hover\:via-grey-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .xl\:hover\:via-grey-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .xl\:hover\:via-grey-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .xl\:hover\:via-grey-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:hover\:via-grey-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .xl\:hover\:via-grey-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:hover\:via-grey-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .xl\:hover\:via-grey-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .xl\:hover\:via-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:hover\:via-grey-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .xl\:hover\:via-grey-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .xl\:hover\:via-grey-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .xl\:hover\:via-grey-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .xl\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .xl\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .xl\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .xl\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .xl\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .xl\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .xl\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .xl\:hover\:via-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:hover\:via-gray-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:via-gray-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .xl\:hover\:via-gray-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .xl\:hover\:via-gray-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .xl\:hover\:via-gray-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .xl\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .xl\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .xl\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .xl\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .xl\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .xl\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .xl\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .xl\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .xl\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .xl\:hover\:via-red:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .xl\:hover\:via-red-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .xl\:hover\:via-red-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .xl\:hover\:via-red-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .xl\:hover\:via-red-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .xl\:hover\:via-orange-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .xl\:hover\:via-orange-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .xl\:hover\:via-orange-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .xl\:hover\:via-orange-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .xl\:hover\:via-orange-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .xl\:hover\:via-orange-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .xl\:hover\:via-orange-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .xl\:hover\:via-orange-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .xl\:hover\:via-orange-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .xl\:hover\:via-orange-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .xl\:hover\:via-orange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .xl\:hover\:via-orange-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .xl\:hover\:via-orange-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .xl\:hover\:via-orange-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .xl\:hover\:via-orange-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .xl\:hover\:via-deep-orange-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .xl\:hover\:via-deep-orange-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .xl\:hover\:via-deep-orange-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .xl\:hover\:via-deep-orange-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .xl\:hover\:via-deep-orange-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .xl\:hover\:via-deep-orange-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .xl\:hover\:via-deep-orange-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .xl\:hover\:via-deep-orange-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .xl\:hover\:via-deep-orange-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .xl\:hover\:via-deep-orange-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .xl\:hover\:via-deep-orange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .xl\:hover\:via-deep-orange-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .xl\:hover\:via-deep-orange-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .xl\:hover\:via-deep-orange-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .xl\:hover\:via-deep-orange-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .xl\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .xl\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .xl\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .xl\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .xl\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .xl\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .xl\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .xl\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .xl\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .xl\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .xl\:hover\:via-yellow:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .xl\:hover\:via-yellow-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .xl\:hover\:via-yellow-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .xl\:hover\:via-yellow-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .xl\:hover\:via-yellow-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .xl\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .xl\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .xl\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .xl\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .xl\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .xl\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .xl\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .xl\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .xl\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .xl\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .xl\:hover\:via-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .xl\:hover\:via-green-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .xl\:hover\:via-green-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .xl\:hover\:via-green-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .xl\:hover\:via-green-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .xl\:hover\:via-light-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .xl\:hover\:via-light-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .xl\:hover\:via-light-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .xl\:hover\:via-light-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .xl\:hover\:via-light-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .xl\:hover\:via-light-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .xl\:hover\:via-light-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .xl\:hover\:via-light-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .xl\:hover\:via-light-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .xl\:hover\:via-light-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .xl\:hover\:via-light-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .xl\:hover\:via-light-green-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .xl\:hover\:via-light-green-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .xl\:hover\:via-light-green-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .xl\:hover\:via-light-green-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .xl\:hover\:via-teal-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .xl\:hover\:via-teal-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .xl\:hover\:via-teal-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .xl\:hover\:via-teal-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .xl\:hover\:via-teal-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .xl\:hover\:via-teal-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .xl\:hover\:via-teal-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .xl\:hover\:via-teal-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .xl\:hover\:via-teal-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .xl\:hover\:via-teal-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .xl\:hover\:via-teal:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .xl\:hover\:via-teal-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .xl\:hover\:via-teal-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .xl\:hover\:via-teal-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .xl\:hover\:via-teal-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .xl\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .xl\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .xl\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .xl\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .xl\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .xl\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .xl\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .xl\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .xl\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .xl\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .xl\:hover\:via-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .xl\:hover\:via-blue-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .xl\:hover\:via-blue-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .xl\:hover\:via-blue-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .xl\:hover\:via-blue-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .xl\:hover\:via-light-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .xl\:hover\:via-light-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .xl\:hover\:via-light-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .xl\:hover\:via-light-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .xl\:hover\:via-light-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .xl\:hover\:via-light-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .xl\:hover\:via-light-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .xl\:hover\:via-light-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .xl\:hover\:via-light-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .xl\:hover\:via-light-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .xl\:hover\:via-light-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .xl\:hover\:via-light-blue-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .xl\:hover\:via-light-blue-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .xl\:hover\:via-light-blue-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .xl\:hover\:via-light-blue-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .xl\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .xl\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .xl\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .xl\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .xl\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .xl\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .xl\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .xl\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .xl\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .xl\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .xl\:hover\:via-indigo:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .xl\:hover\:via-indigo-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .xl\:hover\:via-indigo-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .xl\:hover\:via-indigo-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .xl\:hover\:via-indigo-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .xl\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .xl\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .xl\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .xl\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .xl\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .xl\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .xl\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .xl\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .xl\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .xl\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .xl\:hover\:via-purple:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .xl\:hover\:via-purple-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .xl\:hover\:via-purple-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .xl\:hover\:via-purple-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .xl\:hover\:via-purple-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .xl\:hover\:via-deep-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .xl\:hover\:via-deep-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .xl\:hover\:via-deep-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .xl\:hover\:via-deep-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .xl\:hover\:via-deep-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .xl\:hover\:via-deep-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .xl\:hover\:via-deep-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .xl\:hover\:via-deep-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .xl\:hover\:via-deep-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .xl\:hover\:via-deep-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .xl\:hover\:via-deep-purple:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .xl\:hover\:via-deep-purple-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .xl\:hover\:via-deep-purple-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .xl\:hover\:via-deep-purple-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .xl\:hover\:via-deep-purple-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .xl\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .xl\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .xl\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .xl\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .xl\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .xl\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .xl\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .xl\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .xl\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .xl\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .xl\:hover\:via-pink:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .xl\:hover\:via-pink-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .xl\:hover\:via-pink-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .xl\:hover\:via-pink-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .xl\:hover\:via-pink-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .xl\:hover\:via-lime-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .xl\:hover\:via-lime-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .xl\:hover\:via-lime-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .xl\:hover\:via-lime-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .xl\:hover\:via-lime-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .xl\:hover\:via-lime-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .xl\:hover\:via-lime-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .xl\:hover\:via-lime-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .xl\:hover\:via-lime-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .xl\:hover\:via-lime-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .xl\:hover\:via-lime:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .xl\:hover\:via-lime-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .xl\:hover\:via-lime-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .xl\:hover\:via-lime-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .xl\:hover\:via-lime-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .xl\:hover\:via-amber-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .xl\:hover\:via-amber-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .xl\:hover\:via-amber-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .xl\:hover\:via-amber-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .xl\:hover\:via-amber-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .xl\:hover\:via-amber-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .xl\:hover\:via-amber-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .xl\:hover\:via-amber-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .xl\:hover\:via-amber-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .xl\:hover\:via-amber-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .xl\:hover\:via-amber:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .xl\:hover\:via-amber-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .xl\:hover\:via-amber-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .xl\:hover\:via-amber-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .xl\:hover\:via-amber-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .xl\:hover\:via-brown-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .xl\:hover\:via-brown-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .xl\:hover\:via-brown-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .xl\:hover\:via-brown-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .xl\:hover\:via-brown-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .xl\:hover\:via-brown-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .xl\:hover\:via-brown-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .xl\:hover\:via-brown-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .xl\:hover\:via-brown-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .xl\:hover\:via-brown-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .xl\:hover\:via-brown:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .xl\:hover\:via-brown-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .xl\:hover\:via-brown-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .xl\:hover\:via-brown-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .xl\:hover\:via-brown-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .xl\:hover\:via-blue-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .xl\:hover\:via-blue-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .xl\:hover\:via-blue-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .xl\:hover\:via-blue-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .xl\:hover\:via-blue-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .xl\:hover\:via-blue-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .xl\:hover\:via-blue-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .xl\:hover\:via-blue-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .xl\:hover\:via-blue-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .xl\:hover\:via-blue-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .xl\:hover\:via-blue-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .xl\:hover\:via-blue-gray-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .xl\:hover\:via-blue-gray-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .xl\:hover\:via-blue-gray-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .xl\:hover\:via-blue-gray-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .xl\:hover\:via-cyan-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .xl\:hover\:via-cyan-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .xl\:hover\:via-cyan-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .xl\:hover\:via-cyan-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .xl\:hover\:via-cyan-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .xl\:hover\:via-cyan-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .xl\:hover\:via-cyan-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .xl\:hover\:via-cyan-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .xl\:hover\:via-cyan-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .xl\:hover\:via-cyan-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .xl\:hover\:via-cyan:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .xl\:hover\:via-cyan-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .xl\:hover\:via-cyan-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .xl\:hover\:via-cyan-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .xl\:hover\:via-cyan-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .xl\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent
  }

  .xl\:hover\:to-current:hover {
    --tw-gradient-to: currentColor
  }

  .xl\:hover\:to-black:hover {
    --tw-gradient-to: #22292F
  }

  .xl\:hover\:to-white:hover {
    --tw-gradient-to: #fff
  }

  .xl\:hover\:to-grey-50:hover {
    --tw-gradient-to: #FAFAFA
  }

  .xl\:hover\:to-grey-100:hover {
    --tw-gradient-to: #F5F5F5
  }

  .xl\:hover\:to-grey-200:hover {
    --tw-gradient-to: #EEEEEE
  }

  .xl\:hover\:to-grey-300:hover {
    --tw-gradient-to: #E0E0E0
  }

  .xl\:hover\:to-grey-400:hover {
    --tw-gradient-to: #BDBDBD
  }

  .xl\:hover\:to-grey-500:hover {
    --tw-gradient-to: #9E9E9E
  }

  .xl\:hover\:to-grey-600:hover {
    --tw-gradient-to: #757575
  }

  .xl\:hover\:to-grey-700:hover {
    --tw-gradient-to: #616161
  }

  .xl\:hover\:to-grey-800:hover {
    --tw-gradient-to: #424242
  }

  .xl\:hover\:to-grey-900:hover {
    --tw-gradient-to: #212121
  }

  .xl\:hover\:to-grey:hover {
    --tw-gradient-to: #9E9E9E
  }

  .xl\:hover\:to-grey-A100:hover {
    --tw-gradient-to: #D5D5D5
  }

  .xl\:hover\:to-grey-A200:hover {
    --tw-gradient-to: #AAAAAA
  }

  .xl\:hover\:to-grey-A400:hover {
    --tw-gradient-to: #303030
  }

  .xl\:hover\:to-grey-A700:hover {
    --tw-gradient-to: #616161
  }

  .xl\:hover\:to-gray-50:hover {
    --tw-gradient-to: #FAFAFA
  }

  .xl\:hover\:to-gray-100:hover {
    --tw-gradient-to: #F5F5F5
  }

  .xl\:hover\:to-gray-200:hover {
    --tw-gradient-to: #EEEEEE
  }

  .xl\:hover\:to-gray-300:hover {
    --tw-gradient-to: #E0E0E0
  }

  .xl\:hover\:to-gray-400:hover {
    --tw-gradient-to: #BDBDBD
  }

  .xl\:hover\:to-gray-500:hover {
    --tw-gradient-to: #9E9E9E
  }

  .xl\:hover\:to-gray-600:hover {
    --tw-gradient-to: #757575
  }

  .xl\:hover\:to-gray-700:hover {
    --tw-gradient-to: #616161
  }

  .xl\:hover\:to-gray-800:hover {
    --tw-gradient-to: #424242
  }

  .xl\:hover\:to-gray-900:hover {
    --tw-gradient-to: #212121
  }

  .xl\:hover\:to-gray:hover {
    --tw-gradient-to: #9E9E9E
  }

  .xl\:hover\:to-gray-hover:hover {
    --tw-gradient-to: rgba(0, 0, 0, 0.04)
  }

  .xl\:hover\:to-gray-A100:hover {
    --tw-gradient-to: #D5D5D5
  }

  .xl\:hover\:to-gray-A200:hover {
    --tw-gradient-to: #AAAAAA
  }

  .xl\:hover\:to-gray-A400:hover {
    --tw-gradient-to: #303030
  }

  .xl\:hover\:to-gray-A700:hover {
    --tw-gradient-to: #616161
  }

  .xl\:hover\:to-red-50:hover {
    --tw-gradient-to: #FFEBEE
  }

  .xl\:hover\:to-red-100:hover {
    --tw-gradient-to: #FFCDD2
  }

  .xl\:hover\:to-red-200:hover {
    --tw-gradient-to: #EF9A9A
  }

  .xl\:hover\:to-red-300:hover {
    --tw-gradient-to: #E57373
  }

  .xl\:hover\:to-red-400:hover {
    --tw-gradient-to: #EF5350
  }

  .xl\:hover\:to-red-500:hover {
    --tw-gradient-to: #F44336
  }

  .xl\:hover\:to-red-600:hover {
    --tw-gradient-to: #E53935
  }

  .xl\:hover\:to-red-700:hover {
    --tw-gradient-to: #D32F2F
  }

  .xl\:hover\:to-red-800:hover {
    --tw-gradient-to: #C62828
  }

  .xl\:hover\:to-red-900:hover {
    --tw-gradient-to: #B71C1C
  }

  .xl\:hover\:to-red:hover {
    --tw-gradient-to: #F44336
  }

  .xl\:hover\:to-red-A100:hover {
    --tw-gradient-to: #FF8A80
  }

  .xl\:hover\:to-red-A200:hover {
    --tw-gradient-to: #FF5252
  }

  .xl\:hover\:to-red-A400:hover {
    --tw-gradient-to: #FF1744
  }

  .xl\:hover\:to-red-A700:hover {
    --tw-gradient-to: #D50000
  }

  .xl\:hover\:to-orange-50:hover {
    --tw-gradient-to: #FFF3E0
  }

  .xl\:hover\:to-orange-100:hover {
    --tw-gradient-to: #FFE0B2
  }

  .xl\:hover\:to-orange-200:hover {
    --tw-gradient-to: #FFCC80
  }

  .xl\:hover\:to-orange-300:hover {
    --tw-gradient-to: #FFB74D
  }

  .xl\:hover\:to-orange-400:hover {
    --tw-gradient-to: #FFA726
  }

  .xl\:hover\:to-orange-500:hover {
    --tw-gradient-to: #FF9800
  }

  .xl\:hover\:to-orange-600:hover {
    --tw-gradient-to: #FB8C00
  }

  .xl\:hover\:to-orange-700:hover {
    --tw-gradient-to: #F57C00
  }

  .xl\:hover\:to-orange-800:hover {
    --tw-gradient-to: #EF6C00
  }

  .xl\:hover\:to-orange-900:hover {
    --tw-gradient-to: #E65100
  }

  .xl\:hover\:to-orange:hover {
    --tw-gradient-to: #FF9800
  }

  .xl\:hover\:to-orange-A100:hover {
    --tw-gradient-to: #FFD180
  }

  .xl\:hover\:to-orange-A200:hover {
    --tw-gradient-to: #FFAB40
  }

  .xl\:hover\:to-orange-A400:hover {
    --tw-gradient-to: #FF9100
  }

  .xl\:hover\:to-orange-A700:hover {
    --tw-gradient-to: #FF6D00
  }

  .xl\:hover\:to-deep-orange-50:hover {
    --tw-gradient-to: #FBE9E7
  }

  .xl\:hover\:to-deep-orange-100:hover {
    --tw-gradient-to: #FFCCBC
  }

  .xl\:hover\:to-deep-orange-200:hover {
    --tw-gradient-to: #FFAB91
  }

  .xl\:hover\:to-deep-orange-300:hover {
    --tw-gradient-to: #FF8A65
  }

  .xl\:hover\:to-deep-orange-400:hover {
    --tw-gradient-to: #FF7043
  }

  .xl\:hover\:to-deep-orange-500:hover {
    --tw-gradient-to: #FF5722
  }

  .xl\:hover\:to-deep-orange-600:hover {
    --tw-gradient-to: #F4511E
  }

  .xl\:hover\:to-deep-orange-700:hover {
    --tw-gradient-to: #E64A19
  }

  .xl\:hover\:to-deep-orange-800:hover {
    --tw-gradient-to: #D84315
  }

  .xl\:hover\:to-deep-orange-900:hover {
    --tw-gradient-to: #BF360C
  }

  .xl\:hover\:to-deep-orange:hover {
    --tw-gradient-to: #FF5722
  }

  .xl\:hover\:to-deep-orange-A100:hover {
    --tw-gradient-to: #FF9E80
  }

  .xl\:hover\:to-deep-orange-A200:hover {
    --tw-gradient-to: #FF6E40
  }

  .xl\:hover\:to-deep-orange-A400:hover {
    --tw-gradient-to: #FF3D00
  }

  .xl\:hover\:to-deep-orange-A700:hover {
    --tw-gradient-to: #DD2C00
  }

  .xl\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #FFFDE7
  }

  .xl\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #FFF9C4
  }

  .xl\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #FFF59D
  }

  .xl\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #FFF176
  }

  .xl\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #FFEE58
  }

  .xl\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #FFEB3B
  }

  .xl\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #FDD835
  }

  .xl\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #FBC02D
  }

  .xl\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #F9A825
  }

  .xl\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #F57F17
  }

  .xl\:hover\:to-yellow:hover {
    --tw-gradient-to: #FFEB3B
  }

  .xl\:hover\:to-yellow-A100:hover {
    --tw-gradient-to: #FFFF8D
  }

  .xl\:hover\:to-yellow-A200:hover {
    --tw-gradient-to: #FFFF00
  }

  .xl\:hover\:to-yellow-A400:hover {
    --tw-gradient-to: #FFEA00
  }

  .xl\:hover\:to-yellow-A700:hover {
    --tw-gradient-to: #FFD600
  }

  .xl\:hover\:to-green-50:hover {
    --tw-gradient-to: #E8F5E9
  }

  .xl\:hover\:to-green-100:hover {
    --tw-gradient-to: #C8E6C9
  }

  .xl\:hover\:to-green-200:hover {
    --tw-gradient-to: #A5D6A7
  }

  .xl\:hover\:to-green-300:hover {
    --tw-gradient-to: #81C784
  }

  .xl\:hover\:to-green-400:hover {
    --tw-gradient-to: #66BB6A
  }

  .xl\:hover\:to-green-500:hover {
    --tw-gradient-to: #4CAF50
  }

  .xl\:hover\:to-green-600:hover {
    --tw-gradient-to: #43A047
  }

  .xl\:hover\:to-green-700:hover {
    --tw-gradient-to: #388E3C
  }

  .xl\:hover\:to-green-800:hover {
    --tw-gradient-to: #2E7D32
  }

  .xl\:hover\:to-green-900:hover {
    --tw-gradient-to: #1B5E20
  }

  .xl\:hover\:to-green:hover {
    --tw-gradient-to: #4CAF50
  }

  .xl\:hover\:to-green-A100:hover {
    --tw-gradient-to: #B9F6CA
  }

  .xl\:hover\:to-green-A200:hover {
    --tw-gradient-to: #69F0AE
  }

  .xl\:hover\:to-green-A400:hover {
    --tw-gradient-to: #00E676
  }

  .xl\:hover\:to-green-A700:hover {
    --tw-gradient-to: #00C853
  }

  .xl\:hover\:to-light-green-50:hover {
    --tw-gradient-to: #F1F8E9
  }

  .xl\:hover\:to-light-green-100:hover {
    --tw-gradient-to: #DCEDC8
  }

  .xl\:hover\:to-light-green-200:hover {
    --tw-gradient-to: #C5E1A5
  }

  .xl\:hover\:to-light-green-300:hover {
    --tw-gradient-to: #AED581
  }

  .xl\:hover\:to-light-green-400:hover {
    --tw-gradient-to: #9CCC65
  }

  .xl\:hover\:to-light-green-500:hover {
    --tw-gradient-to: #8BC34A
  }

  .xl\:hover\:to-light-green-600:hover {
    --tw-gradient-to: #7CB342
  }

  .xl\:hover\:to-light-green-700:hover {
    --tw-gradient-to: #689F38
  }

  .xl\:hover\:to-light-green-800:hover {
    --tw-gradient-to: #558B2F
  }

  .xl\:hover\:to-light-green-900:hover {
    --tw-gradient-to: #33691E
  }

  .xl\:hover\:to-light-green:hover {
    --tw-gradient-to: #8BC34A
  }

  .xl\:hover\:to-light-green-A100:hover {
    --tw-gradient-to: #CCFF90
  }

  .xl\:hover\:to-light-green-A200:hover {
    --tw-gradient-to: #B2FF59
  }

  .xl\:hover\:to-light-green-A400:hover {
    --tw-gradient-to: #76FF03
  }

  .xl\:hover\:to-light-green-A700:hover {
    --tw-gradient-to: #64DD17
  }

  .xl\:hover\:to-teal-50:hover {
    --tw-gradient-to: #E0F2F1
  }

  .xl\:hover\:to-teal-100:hover {
    --tw-gradient-to: #B2DFDB
  }

  .xl\:hover\:to-teal-200:hover {
    --tw-gradient-to: #80CBC4
  }

  .xl\:hover\:to-teal-300:hover {
    --tw-gradient-to: #4DB6AC
  }

  .xl\:hover\:to-teal-400:hover {
    --tw-gradient-to: #26A69A
  }

  .xl\:hover\:to-teal-500:hover {
    --tw-gradient-to: #009688
  }

  .xl\:hover\:to-teal-600:hover {
    --tw-gradient-to: #00897B
  }

  .xl\:hover\:to-teal-700:hover {
    --tw-gradient-to: #00796B
  }

  .xl\:hover\:to-teal-800:hover {
    --tw-gradient-to: #00695C
  }

  .xl\:hover\:to-teal-900:hover {
    --tw-gradient-to: #004D40
  }

  .xl\:hover\:to-teal:hover {
    --tw-gradient-to: #009688
  }

  .xl\:hover\:to-teal-A100:hover {
    --tw-gradient-to: #A7FFEB
  }

  .xl\:hover\:to-teal-A200:hover {
    --tw-gradient-to: #64FFDA
  }

  .xl\:hover\:to-teal-A400:hover {
    --tw-gradient-to: #1DE9B6
  }

  .xl\:hover\:to-teal-A700:hover {
    --tw-gradient-to: #00BFA5
  }

  .xl\:hover\:to-blue-50:hover {
    --tw-gradient-to: #E3F2FD
  }

  .xl\:hover\:to-blue-100:hover {
    --tw-gradient-to: #BBDEFB
  }

  .xl\:hover\:to-blue-200:hover {
    --tw-gradient-to: #90CAF9
  }

  .xl\:hover\:to-blue-300:hover {
    --tw-gradient-to: #64B5F6
  }

  .xl\:hover\:to-blue-400:hover {
    --tw-gradient-to: #42A5F5
  }

  .xl\:hover\:to-blue-500:hover {
    --tw-gradient-to: #2196F3
  }

  .xl\:hover\:to-blue-600:hover {
    --tw-gradient-to: #1E88E5
  }

  .xl\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1976D2
  }

  .xl\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1565C0
  }

  .xl\:hover\:to-blue-900:hover {
    --tw-gradient-to: #0D47A1
  }

  .xl\:hover\:to-blue:hover {
    --tw-gradient-to: #2196F3
  }

  .xl\:hover\:to-blue-A100:hover {
    --tw-gradient-to: #82B1FF
  }

  .xl\:hover\:to-blue-A200:hover {
    --tw-gradient-to: #448AFF
  }

  .xl\:hover\:to-blue-A400:hover {
    --tw-gradient-to: #2979FF
  }

  .xl\:hover\:to-blue-A700:hover {
    --tw-gradient-to: #2962FF
  }

  .xl\:hover\:to-light-blue-50:hover {
    --tw-gradient-to: #E1F5FE
  }

  .xl\:hover\:to-light-blue-100:hover {
    --tw-gradient-to: #B3E5FC
  }

  .xl\:hover\:to-light-blue-200:hover {
    --tw-gradient-to: #81D4FA
  }

  .xl\:hover\:to-light-blue-300:hover {
    --tw-gradient-to: #4FC3F7
  }

  .xl\:hover\:to-light-blue-400:hover {
    --tw-gradient-to: #29B6F6
  }

  .xl\:hover\:to-light-blue-500:hover {
    --tw-gradient-to: #03A9F4
  }

  .xl\:hover\:to-light-blue-600:hover {
    --tw-gradient-to: #039BE5
  }

  .xl\:hover\:to-light-blue-700:hover {
    --tw-gradient-to: #0288D1
  }

  .xl\:hover\:to-light-blue-800:hover {
    --tw-gradient-to: #0277BD
  }

  .xl\:hover\:to-light-blue-900:hover {
    --tw-gradient-to: #01579B
  }

  .xl\:hover\:to-light-blue:hover {
    --tw-gradient-to: #03A9F4
  }

  .xl\:hover\:to-light-blue-A100:hover {
    --tw-gradient-to: #80D8FF
  }

  .xl\:hover\:to-light-blue-A200:hover {
    --tw-gradient-to: #40C4FF
  }

  .xl\:hover\:to-light-blue-A400:hover {
    --tw-gradient-to: #00B0FF
  }

  .xl\:hover\:to-light-blue-A700:hover {
    --tw-gradient-to: #0091EA
  }

  .xl\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #E8EAF6
  }

  .xl\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #C5CAE9
  }

  .xl\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #9FA8DA
  }

  .xl\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #7986CB
  }

  .xl\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #5C6BC0
  }

  .xl\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #3F51B5
  }

  .xl\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #3949AB
  }

  .xl\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #303F9F
  }

  .xl\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #283593
  }

  .xl\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #1A237E
  }

  .xl\:hover\:to-indigo:hover {
    --tw-gradient-to: #3F51B5
  }

  .xl\:hover\:to-indigo-A100:hover {
    --tw-gradient-to: #8C9EFF
  }

  .xl\:hover\:to-indigo-A200:hover {
    --tw-gradient-to: #536DFE
  }

  .xl\:hover\:to-indigo-A400:hover {
    --tw-gradient-to: #3D5AFE
  }

  .xl\:hover\:to-indigo-A700:hover {
    --tw-gradient-to: #304FFE
  }

  .xl\:hover\:to-purple-50:hover {
    --tw-gradient-to: #F3E5F5
  }

  .xl\:hover\:to-purple-100:hover {
    --tw-gradient-to: #E1BEE7
  }

  .xl\:hover\:to-purple-200:hover {
    --tw-gradient-to: #CE93D8
  }

  .xl\:hover\:to-purple-300:hover {
    --tw-gradient-to: #BA68C8
  }

  .xl\:hover\:to-purple-400:hover {
    --tw-gradient-to: #AB47BC
  }

  .xl\:hover\:to-purple-500:hover {
    --tw-gradient-to: #9C27B0
  }

  .xl\:hover\:to-purple-600:hover {
    --tw-gradient-to: #8E24AA
  }

  .xl\:hover\:to-purple-700:hover {
    --tw-gradient-to: #7B1FA2
  }

  .xl\:hover\:to-purple-800:hover {
    --tw-gradient-to: #6A1B9A
  }

  .xl\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4A148C
  }

  .xl\:hover\:to-purple:hover {
    --tw-gradient-to: #9C27B0
  }

  .xl\:hover\:to-purple-A100:hover {
    --tw-gradient-to: #EA80FC
  }

  .xl\:hover\:to-purple-A200:hover {
    --tw-gradient-to: #E040FB
  }

  .xl\:hover\:to-purple-A400:hover {
    --tw-gradient-to: #D500F9
  }

  .xl\:hover\:to-purple-A700:hover {
    --tw-gradient-to: #AA00FF
  }

  .xl\:hover\:to-deep-purple-50:hover {
    --tw-gradient-to: #EDE7F6
  }

  .xl\:hover\:to-deep-purple-100:hover {
    --tw-gradient-to: #D1C4E9
  }

  .xl\:hover\:to-deep-purple-200:hover {
    --tw-gradient-to: #B39DDB
  }

  .xl\:hover\:to-deep-purple-300:hover {
    --tw-gradient-to: #9575CD
  }

  .xl\:hover\:to-deep-purple-400:hover {
    --tw-gradient-to: #7E57C2
  }

  .xl\:hover\:to-deep-purple-500:hover {
    --tw-gradient-to: #673AB7
  }

  .xl\:hover\:to-deep-purple-600:hover {
    --tw-gradient-to: #5E35B1
  }

  .xl\:hover\:to-deep-purple-700:hover {
    --tw-gradient-to: #512DA8
  }

  .xl\:hover\:to-deep-purple-800:hover {
    --tw-gradient-to: #4527A0
  }

  .xl\:hover\:to-deep-purple-900:hover {
    --tw-gradient-to: #311B92
  }

  .xl\:hover\:to-deep-purple:hover {
    --tw-gradient-to: #673AB7
  }

  .xl\:hover\:to-deep-purple-A100:hover {
    --tw-gradient-to: #B388FF
  }

  .xl\:hover\:to-deep-purple-A200:hover {
    --tw-gradient-to: #7C4DFF
  }

  .xl\:hover\:to-deep-purple-A400:hover {
    --tw-gradient-to: #651FFF
  }

  .xl\:hover\:to-deep-purple-A700:hover {
    --tw-gradient-to: #6200EA
  }

  .xl\:hover\:to-pink-50:hover {
    --tw-gradient-to: #FCE4EC
  }

  .xl\:hover\:to-pink-100:hover {
    --tw-gradient-to: #F8BBD0
  }

  .xl\:hover\:to-pink-200:hover {
    --tw-gradient-to: #F48FB1
  }

  .xl\:hover\:to-pink-300:hover {
    --tw-gradient-to: #F06292
  }

  .xl\:hover\:to-pink-400:hover {
    --tw-gradient-to: #EC407A
  }

  .xl\:hover\:to-pink-500:hover {
    --tw-gradient-to: #E91E63
  }

  .xl\:hover\:to-pink-600:hover {
    --tw-gradient-to: #D81B60
  }

  .xl\:hover\:to-pink-700:hover {
    --tw-gradient-to: #C2185B
  }

  .xl\:hover\:to-pink-800:hover {
    --tw-gradient-to: #AD1457
  }

  .xl\:hover\:to-pink-900:hover {
    --tw-gradient-to: #880E4F
  }

  .xl\:hover\:to-pink:hover {
    --tw-gradient-to: #E91E63
  }

  .xl\:hover\:to-pink-A100:hover {
    --tw-gradient-to: #FF80AB
  }

  .xl\:hover\:to-pink-A200:hover {
    --tw-gradient-to: #FF4081
  }

  .xl\:hover\:to-pink-A400:hover {
    --tw-gradient-to: #F50057
  }

  .xl\:hover\:to-pink-A700:hover {
    --tw-gradient-to: #C51162
  }

  .xl\:hover\:to-lime-50:hover {
    --tw-gradient-to: #F9FBE7
  }

  .xl\:hover\:to-lime-100:hover {
    --tw-gradient-to: #F0F4C3
  }

  .xl\:hover\:to-lime-200:hover {
    --tw-gradient-to: #E6EE9C
  }

  .xl\:hover\:to-lime-300:hover {
    --tw-gradient-to: #DCE775
  }

  .xl\:hover\:to-lime-400:hover {
    --tw-gradient-to: #D4E157
  }

  .xl\:hover\:to-lime-500:hover {
    --tw-gradient-to: #CDDC39
  }

  .xl\:hover\:to-lime-600:hover {
    --tw-gradient-to: #C0CA33
  }

  .xl\:hover\:to-lime-700:hover {
    --tw-gradient-to: #AFB42B
  }

  .xl\:hover\:to-lime-800:hover {
    --tw-gradient-to: #9E9D24
  }

  .xl\:hover\:to-lime-900:hover {
    --tw-gradient-to: #827717
  }

  .xl\:hover\:to-lime:hover {
    --tw-gradient-to: #CDDC39
  }

  .xl\:hover\:to-lime-A100:hover {
    --tw-gradient-to: #F4FF81
  }

  .xl\:hover\:to-lime-A200:hover {
    --tw-gradient-to: #EEFF41
  }

  .xl\:hover\:to-lime-A400:hover {
    --tw-gradient-to: #C6FF00
  }

  .xl\:hover\:to-lime-A700:hover {
    --tw-gradient-to: #AEEA00
  }

  .xl\:hover\:to-amber-50:hover {
    --tw-gradient-to: #FFF8E1
  }

  .xl\:hover\:to-amber-100:hover {
    --tw-gradient-to: #FFECB3
  }

  .xl\:hover\:to-amber-200:hover {
    --tw-gradient-to: #FFE082
  }

  .xl\:hover\:to-amber-300:hover {
    --tw-gradient-to: #FFD54F
  }

  .xl\:hover\:to-amber-400:hover {
    --tw-gradient-to: #FFCA28
  }

  .xl\:hover\:to-amber-500:hover {
    --tw-gradient-to: #FFC107
  }

  .xl\:hover\:to-amber-600:hover {
    --tw-gradient-to: #FFB300
  }

  .xl\:hover\:to-amber-700:hover {
    --tw-gradient-to: #FFA000
  }

  .xl\:hover\:to-amber-800:hover {
    --tw-gradient-to: #FF8F00
  }

  .xl\:hover\:to-amber-900:hover {
    --tw-gradient-to: #FF6F00
  }

  .xl\:hover\:to-amber:hover {
    --tw-gradient-to: #FFC107
  }

  .xl\:hover\:to-amber-A100:hover {
    --tw-gradient-to: #FFE57F
  }

  .xl\:hover\:to-amber-A200:hover {
    --tw-gradient-to: #FFD740
  }

  .xl\:hover\:to-amber-A400:hover {
    --tw-gradient-to: #FFC400
  }

  .xl\:hover\:to-amber-A700:hover {
    --tw-gradient-to: #FFAB00
  }

  .xl\:hover\:to-brown-50:hover {
    --tw-gradient-to: #EFEBE9
  }

  .xl\:hover\:to-brown-100:hover {
    --tw-gradient-to: #D7CCC8
  }

  .xl\:hover\:to-brown-200:hover {
    --tw-gradient-to: #BCAAA4
  }

  .xl\:hover\:to-brown-300:hover {
    --tw-gradient-to: #A1887F
  }

  .xl\:hover\:to-brown-400:hover {
    --tw-gradient-to: #8D6E63
  }

  .xl\:hover\:to-brown-500:hover {
    --tw-gradient-to: #795548
  }

  .xl\:hover\:to-brown-600:hover {
    --tw-gradient-to: #6D4C41
  }

  .xl\:hover\:to-brown-700:hover {
    --tw-gradient-to: #5D4037
  }

  .xl\:hover\:to-brown-800:hover {
    --tw-gradient-to: #4E342E
  }

  .xl\:hover\:to-brown-900:hover {
    --tw-gradient-to: #3E2723
  }

  .xl\:hover\:to-brown:hover {
    --tw-gradient-to: #795548
  }

  .xl\:hover\:to-brown-A100:hover {
    --tw-gradient-to: #D7CCC8
  }

  .xl\:hover\:to-brown-A200:hover {
    --tw-gradient-to: #BCAAA4
  }

  .xl\:hover\:to-brown-A400:hover {
    --tw-gradient-to: #8D6E63
  }

  .xl\:hover\:to-brown-A700:hover {
    --tw-gradient-to: #5D4037
  }

  .xl\:hover\:to-blue-gray-50:hover {
    --tw-gradient-to: #ECEFF1
  }

  .xl\:hover\:to-blue-gray-100:hover {
    --tw-gradient-to: #CFD8DC
  }

  .xl\:hover\:to-blue-gray-200:hover {
    --tw-gradient-to: #B0BEC5
  }

  .xl\:hover\:to-blue-gray-300:hover {
    --tw-gradient-to: #90A4AE
  }

  .xl\:hover\:to-blue-gray-400:hover {
    --tw-gradient-to: #78909C
  }

  .xl\:hover\:to-blue-gray-500:hover {
    --tw-gradient-to: #607D8B
  }

  .xl\:hover\:to-blue-gray-600:hover {
    --tw-gradient-to: #546E7A
  }

  .xl\:hover\:to-blue-gray-700:hover {
    --tw-gradient-to: #455A64
  }

  .xl\:hover\:to-blue-gray-800:hover {
    --tw-gradient-to: #37474F
  }

  .xl\:hover\:to-blue-gray-900:hover {
    --tw-gradient-to: #263238
  }

  .xl\:hover\:to-blue-gray:hover {
    --tw-gradient-to: #607D8B
  }

  .xl\:hover\:to-blue-gray-A100:hover {
    --tw-gradient-to: #CFD8DC
  }

  .xl\:hover\:to-blue-gray-A200:hover {
    --tw-gradient-to: #B0BEC5
  }

  .xl\:hover\:to-blue-gray-A400:hover {
    --tw-gradient-to: #78909C
  }

  .xl\:hover\:to-blue-gray-A700:hover {
    --tw-gradient-to: #455A64
  }

  .xl\:hover\:to-cyan-50:hover {
    --tw-gradient-to: #E0F7FA
  }

  .xl\:hover\:to-cyan-100:hover {
    --tw-gradient-to: #B2EBF2
  }

  .xl\:hover\:to-cyan-200:hover {
    --tw-gradient-to: #80DEEA
  }

  .xl\:hover\:to-cyan-300:hover {
    --tw-gradient-to: #4DD0E1
  }

  .xl\:hover\:to-cyan-400:hover {
    --tw-gradient-to: #26C6DA
  }

  .xl\:hover\:to-cyan-500:hover {
    --tw-gradient-to: #00BCD4
  }

  .xl\:hover\:to-cyan-600:hover {
    --tw-gradient-to: #00ACC1
  }

  .xl\:hover\:to-cyan-700:hover {
    --tw-gradient-to: #0097A7
  }

  .xl\:hover\:to-cyan-800:hover {
    --tw-gradient-to: #00838F
  }

  .xl\:hover\:to-cyan-900:hover {
    --tw-gradient-to: #006064
  }

  .xl\:hover\:to-cyan:hover {
    --tw-gradient-to: #00BCD4
  }

  .xl\:hover\:to-cyan-A100:hover {
    --tw-gradient-to: #84FFFF
  }

  .xl\:hover\:to-cyan-A200:hover {
    --tw-gradient-to: #18FFFF
  }

  .xl\:hover\:to-cyan-A400:hover {
    --tw-gradient-to: #00E5FF
  }

  .xl\:hover\:to-cyan-A700:hover {
    --tw-gradient-to: #00B8D4
  }

  .xl\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-black:focus {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .xl\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-grey-50:focus {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .xl\:focus\:from-grey-100:focus {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .xl\:focus\:from-grey-200:focus {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .xl\:focus\:from-grey-300:focus {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .xl\:focus\:from-grey-400:focus {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .xl\:focus\:from-grey-500:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:focus\:from-grey-600:focus {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .xl\:focus\:from-grey-700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:focus\:from-grey-800:focus {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .xl\:focus\:from-grey-900:focus {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .xl\:focus\:from-grey:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:focus\:from-grey-A100:focus {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .xl\:focus\:from-grey-A200:focus {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .xl\:focus\:from-grey-A400:focus {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .xl\:focus\:from-grey-A700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:focus\:from-gray-50:focus {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .xl\:focus\:from-gray-100:focus {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .xl\:focus\:from-gray-200:focus {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .xl\:focus\:from-gray-300:focus {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .xl\:focus\:from-gray-400:focus {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .xl\:focus\:from-gray-500:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:focus\:from-gray-600:focus {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .xl\:focus\:from-gray-700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:focus\:from-gray-800:focus {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .xl\:focus\:from-gray-900:focus {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .xl\:focus\:from-gray:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:focus\:from-gray-hover:focus {
    --tw-gradient-from: rgba(0, 0, 0, 0.04);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:from-gray-A100:focus {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .xl\:focus\:from-gray-A200:focus {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .xl\:focus\:from-gray-A400:focus {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .xl\:focus\:from-gray-A700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:focus\:from-red-50:focus {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .xl\:focus\:from-red-100:focus {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .xl\:focus\:from-red-200:focus {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .xl\:focus\:from-red-300:focus {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .xl\:focus\:from-red-400:focus {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .xl\:focus\:from-red-500:focus {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .xl\:focus\:from-red-600:focus {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .xl\:focus\:from-red-700:focus {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .xl\:focus\:from-red-800:focus {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .xl\:focus\:from-red-900:focus {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .xl\:focus\:from-red:focus {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .xl\:focus\:from-red-A100:focus {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .xl\:focus\:from-red-A200:focus {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .xl\:focus\:from-red-A400:focus {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .xl\:focus\:from-red-A700:focus {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .xl\:focus\:from-orange-50:focus {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .xl\:focus\:from-orange-100:focus {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .xl\:focus\:from-orange-200:focus {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .xl\:focus\:from-orange-300:focus {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .xl\:focus\:from-orange-400:focus {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .xl\:focus\:from-orange-500:focus {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .xl\:focus\:from-orange-600:focus {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .xl\:focus\:from-orange-700:focus {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .xl\:focus\:from-orange-800:focus {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .xl\:focus\:from-orange-900:focus {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .xl\:focus\:from-orange:focus {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .xl\:focus\:from-orange-A100:focus {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .xl\:focus\:from-orange-A200:focus {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .xl\:focus\:from-orange-A400:focus {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .xl\:focus\:from-orange-A700:focus {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .xl\:focus\:from-deep-orange-50:focus {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .xl\:focus\:from-deep-orange-100:focus {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .xl\:focus\:from-deep-orange-200:focus {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .xl\:focus\:from-deep-orange-300:focus {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .xl\:focus\:from-deep-orange-400:focus {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .xl\:focus\:from-deep-orange-500:focus {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .xl\:focus\:from-deep-orange-600:focus {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .xl\:focus\:from-deep-orange-700:focus {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .xl\:focus\:from-deep-orange-800:focus {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .xl\:focus\:from-deep-orange-900:focus {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .xl\:focus\:from-deep-orange:focus {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .xl\:focus\:from-deep-orange-A100:focus {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .xl\:focus\:from-deep-orange-A200:focus {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .xl\:focus\:from-deep-orange-A400:focus {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .xl\:focus\:from-deep-orange-A700:focus {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .xl\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .xl\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .xl\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .xl\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .xl\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .xl\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .xl\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .xl\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .xl\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .xl\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .xl\:focus\:from-yellow:focus {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .xl\:focus\:from-yellow-A100:focus {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .xl\:focus\:from-yellow-A200:focus {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .xl\:focus\:from-yellow-A400:focus {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .xl\:focus\:from-yellow-A700:focus {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .xl\:focus\:from-green-50:focus {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .xl\:focus\:from-green-100:focus {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .xl\:focus\:from-green-200:focus {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .xl\:focus\:from-green-300:focus {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .xl\:focus\:from-green-400:focus {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .xl\:focus\:from-green-500:focus {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .xl\:focus\:from-green-600:focus {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .xl\:focus\:from-green-700:focus {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .xl\:focus\:from-green-800:focus {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .xl\:focus\:from-green-900:focus {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .xl\:focus\:from-green:focus {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .xl\:focus\:from-green-A100:focus {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .xl\:focus\:from-green-A200:focus {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .xl\:focus\:from-green-A400:focus {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .xl\:focus\:from-green-A700:focus {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .xl\:focus\:from-light-green-50:focus {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .xl\:focus\:from-light-green-100:focus {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .xl\:focus\:from-light-green-200:focus {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .xl\:focus\:from-light-green-300:focus {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .xl\:focus\:from-light-green-400:focus {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .xl\:focus\:from-light-green-500:focus {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .xl\:focus\:from-light-green-600:focus {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .xl\:focus\:from-light-green-700:focus {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .xl\:focus\:from-light-green-800:focus {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .xl\:focus\:from-light-green-900:focus {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .xl\:focus\:from-light-green:focus {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .xl\:focus\:from-light-green-A100:focus {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .xl\:focus\:from-light-green-A200:focus {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .xl\:focus\:from-light-green-A400:focus {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .xl\:focus\:from-light-green-A700:focus {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .xl\:focus\:from-teal-50:focus {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .xl\:focus\:from-teal-100:focus {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .xl\:focus\:from-teal-200:focus {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .xl\:focus\:from-teal-300:focus {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .xl\:focus\:from-teal-400:focus {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .xl\:focus\:from-teal-500:focus {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .xl\:focus\:from-teal-600:focus {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .xl\:focus\:from-teal-700:focus {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .xl\:focus\:from-teal-800:focus {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .xl\:focus\:from-teal-900:focus {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .xl\:focus\:from-teal:focus {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .xl\:focus\:from-teal-A100:focus {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .xl\:focus\:from-teal-A200:focus {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .xl\:focus\:from-teal-A400:focus {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .xl\:focus\:from-teal-A700:focus {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .xl\:focus\:from-blue-50:focus {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .xl\:focus\:from-blue-100:focus {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .xl\:focus\:from-blue-200:focus {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .xl\:focus\:from-blue-300:focus {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .xl\:focus\:from-blue-400:focus {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .xl\:focus\:from-blue-500:focus {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .xl\:focus\:from-blue-600:focus {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .xl\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .xl\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .xl\:focus\:from-blue-900:focus {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .xl\:focus\:from-blue:focus {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .xl\:focus\:from-blue-A100:focus {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .xl\:focus\:from-blue-A200:focus {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .xl\:focus\:from-blue-A400:focus {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .xl\:focus\:from-blue-A700:focus {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .xl\:focus\:from-light-blue-50:focus {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .xl\:focus\:from-light-blue-100:focus {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .xl\:focus\:from-light-blue-200:focus {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .xl\:focus\:from-light-blue-300:focus {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .xl\:focus\:from-light-blue-400:focus {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .xl\:focus\:from-light-blue-500:focus {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .xl\:focus\:from-light-blue-600:focus {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .xl\:focus\:from-light-blue-700:focus {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .xl\:focus\:from-light-blue-800:focus {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .xl\:focus\:from-light-blue-900:focus {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .xl\:focus\:from-light-blue:focus {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .xl\:focus\:from-light-blue-A100:focus {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .xl\:focus\:from-light-blue-A200:focus {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .xl\:focus\:from-light-blue-A400:focus {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .xl\:focus\:from-light-blue-A700:focus {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .xl\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .xl\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .xl\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .xl\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .xl\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .xl\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .xl\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .xl\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .xl\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .xl\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .xl\:focus\:from-indigo:focus {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .xl\:focus\:from-indigo-A100:focus {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .xl\:focus\:from-indigo-A200:focus {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .xl\:focus\:from-indigo-A400:focus {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .xl\:focus\:from-indigo-A700:focus {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .xl\:focus\:from-purple-50:focus {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .xl\:focus\:from-purple-100:focus {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .xl\:focus\:from-purple-200:focus {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .xl\:focus\:from-purple-300:focus {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .xl\:focus\:from-purple-400:focus {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .xl\:focus\:from-purple-500:focus {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .xl\:focus\:from-purple-600:focus {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .xl\:focus\:from-purple-700:focus {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .xl\:focus\:from-purple-800:focus {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .xl\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .xl\:focus\:from-purple:focus {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .xl\:focus\:from-purple-A100:focus {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .xl\:focus\:from-purple-A200:focus {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .xl\:focus\:from-purple-A400:focus {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .xl\:focus\:from-purple-A700:focus {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .xl\:focus\:from-deep-purple-50:focus {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .xl\:focus\:from-deep-purple-100:focus {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .xl\:focus\:from-deep-purple-200:focus {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .xl\:focus\:from-deep-purple-300:focus {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .xl\:focus\:from-deep-purple-400:focus {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .xl\:focus\:from-deep-purple-500:focus {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .xl\:focus\:from-deep-purple-600:focus {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .xl\:focus\:from-deep-purple-700:focus {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .xl\:focus\:from-deep-purple-800:focus {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .xl\:focus\:from-deep-purple-900:focus {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .xl\:focus\:from-deep-purple:focus {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .xl\:focus\:from-deep-purple-A100:focus {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .xl\:focus\:from-deep-purple-A200:focus {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .xl\:focus\:from-deep-purple-A400:focus {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .xl\:focus\:from-deep-purple-A700:focus {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .xl\:focus\:from-pink-50:focus {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .xl\:focus\:from-pink-100:focus {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .xl\:focus\:from-pink-200:focus {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .xl\:focus\:from-pink-300:focus {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .xl\:focus\:from-pink-400:focus {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .xl\:focus\:from-pink-500:focus {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .xl\:focus\:from-pink-600:focus {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .xl\:focus\:from-pink-700:focus {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .xl\:focus\:from-pink-800:focus {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .xl\:focus\:from-pink-900:focus {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .xl\:focus\:from-pink:focus {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .xl\:focus\:from-pink-A100:focus {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .xl\:focus\:from-pink-A200:focus {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .xl\:focus\:from-pink-A400:focus {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .xl\:focus\:from-pink-A700:focus {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .xl\:focus\:from-lime-50:focus {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .xl\:focus\:from-lime-100:focus {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .xl\:focus\:from-lime-200:focus {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .xl\:focus\:from-lime-300:focus {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .xl\:focus\:from-lime-400:focus {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .xl\:focus\:from-lime-500:focus {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .xl\:focus\:from-lime-600:focus {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .xl\:focus\:from-lime-700:focus {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .xl\:focus\:from-lime-800:focus {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .xl\:focus\:from-lime-900:focus {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .xl\:focus\:from-lime:focus {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .xl\:focus\:from-lime-A100:focus {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .xl\:focus\:from-lime-A200:focus {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .xl\:focus\:from-lime-A400:focus {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .xl\:focus\:from-lime-A700:focus {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .xl\:focus\:from-amber-50:focus {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .xl\:focus\:from-amber-100:focus {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .xl\:focus\:from-amber-200:focus {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .xl\:focus\:from-amber-300:focus {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .xl\:focus\:from-amber-400:focus {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .xl\:focus\:from-amber-500:focus {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .xl\:focus\:from-amber-600:focus {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .xl\:focus\:from-amber-700:focus {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .xl\:focus\:from-amber-800:focus {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .xl\:focus\:from-amber-900:focus {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .xl\:focus\:from-amber:focus {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .xl\:focus\:from-amber-A100:focus {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .xl\:focus\:from-amber-A200:focus {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .xl\:focus\:from-amber-A400:focus {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .xl\:focus\:from-amber-A700:focus {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .xl\:focus\:from-brown-50:focus {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .xl\:focus\:from-brown-100:focus {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .xl\:focus\:from-brown-200:focus {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .xl\:focus\:from-brown-300:focus {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .xl\:focus\:from-brown-400:focus {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .xl\:focus\:from-brown-500:focus {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .xl\:focus\:from-brown-600:focus {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .xl\:focus\:from-brown-700:focus {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .xl\:focus\:from-brown-800:focus {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .xl\:focus\:from-brown-900:focus {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .xl\:focus\:from-brown:focus {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .xl\:focus\:from-brown-A100:focus {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .xl\:focus\:from-brown-A200:focus {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .xl\:focus\:from-brown-A400:focus {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .xl\:focus\:from-brown-A700:focus {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .xl\:focus\:from-blue-gray-50:focus {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .xl\:focus\:from-blue-gray-100:focus {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .xl\:focus\:from-blue-gray-200:focus {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .xl\:focus\:from-blue-gray-300:focus {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .xl\:focus\:from-blue-gray-400:focus {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .xl\:focus\:from-blue-gray-500:focus {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .xl\:focus\:from-blue-gray-600:focus {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .xl\:focus\:from-blue-gray-700:focus {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .xl\:focus\:from-blue-gray-800:focus {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .xl\:focus\:from-blue-gray-900:focus {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .xl\:focus\:from-blue-gray:focus {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .xl\:focus\:from-blue-gray-A100:focus {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .xl\:focus\:from-blue-gray-A200:focus {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .xl\:focus\:from-blue-gray-A400:focus {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .xl\:focus\:from-blue-gray-A700:focus {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .xl\:focus\:from-cyan-50:focus {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .xl\:focus\:from-cyan-100:focus {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .xl\:focus\:from-cyan-200:focus {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .xl\:focus\:from-cyan-300:focus {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .xl\:focus\:from-cyan-400:focus {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .xl\:focus\:from-cyan-500:focus {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .xl\:focus\:from-cyan-600:focus {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .xl\:focus\:from-cyan-700:focus {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .xl\:focus\:from-cyan-800:focus {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .xl\:focus\:from-cyan-900:focus {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .xl\:focus\:from-cyan:focus {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .xl\:focus\:from-cyan-A100:focus {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .xl\:focus\:from-cyan-A200:focus {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .xl\:focus\:from-cyan-A400:focus {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .xl\:focus\:from-cyan-A700:focus {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .xl\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .xl\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-grey-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .xl\:focus\:via-grey-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .xl\:focus\:via-grey-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .xl\:focus\:via-grey-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .xl\:focus\:via-grey-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .xl\:focus\:via-grey-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:focus\:via-grey-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .xl\:focus\:via-grey-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:focus\:via-grey-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .xl\:focus\:via-grey-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .xl\:focus\:via-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:focus\:via-grey-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .xl\:focus\:via-grey-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .xl\:focus\:via-grey-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .xl\:focus\:via-grey-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .xl\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .xl\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .xl\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .xl\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .xl\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .xl\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .xl\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .xl\:focus\:via-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .xl\:focus\:via-gray-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:via-gray-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .xl\:focus\:via-gray-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .xl\:focus\:via-gray-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .xl\:focus\:via-gray-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .xl\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .xl\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .xl\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .xl\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .xl\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .xl\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .xl\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .xl\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .xl\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .xl\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .xl\:focus\:via-red:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .xl\:focus\:via-red-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .xl\:focus\:via-red-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .xl\:focus\:via-red-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .xl\:focus\:via-red-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .xl\:focus\:via-orange-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .xl\:focus\:via-orange-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .xl\:focus\:via-orange-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .xl\:focus\:via-orange-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .xl\:focus\:via-orange-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .xl\:focus\:via-orange-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .xl\:focus\:via-orange-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .xl\:focus\:via-orange-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .xl\:focus\:via-orange-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .xl\:focus\:via-orange-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .xl\:focus\:via-orange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .xl\:focus\:via-orange-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .xl\:focus\:via-orange-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .xl\:focus\:via-orange-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .xl\:focus\:via-orange-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .xl\:focus\:via-deep-orange-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .xl\:focus\:via-deep-orange-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .xl\:focus\:via-deep-orange-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .xl\:focus\:via-deep-orange-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .xl\:focus\:via-deep-orange-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .xl\:focus\:via-deep-orange-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .xl\:focus\:via-deep-orange-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .xl\:focus\:via-deep-orange-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .xl\:focus\:via-deep-orange-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .xl\:focus\:via-deep-orange-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .xl\:focus\:via-deep-orange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .xl\:focus\:via-deep-orange-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .xl\:focus\:via-deep-orange-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .xl\:focus\:via-deep-orange-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .xl\:focus\:via-deep-orange-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .xl\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .xl\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .xl\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .xl\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .xl\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .xl\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .xl\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .xl\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .xl\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .xl\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .xl\:focus\:via-yellow:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .xl\:focus\:via-yellow-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .xl\:focus\:via-yellow-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .xl\:focus\:via-yellow-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .xl\:focus\:via-yellow-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .xl\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .xl\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .xl\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .xl\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .xl\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .xl\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .xl\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .xl\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .xl\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .xl\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .xl\:focus\:via-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .xl\:focus\:via-green-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .xl\:focus\:via-green-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .xl\:focus\:via-green-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .xl\:focus\:via-green-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .xl\:focus\:via-light-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .xl\:focus\:via-light-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .xl\:focus\:via-light-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .xl\:focus\:via-light-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .xl\:focus\:via-light-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .xl\:focus\:via-light-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .xl\:focus\:via-light-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .xl\:focus\:via-light-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .xl\:focus\:via-light-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .xl\:focus\:via-light-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .xl\:focus\:via-light-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .xl\:focus\:via-light-green-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .xl\:focus\:via-light-green-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .xl\:focus\:via-light-green-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .xl\:focus\:via-light-green-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .xl\:focus\:via-teal-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .xl\:focus\:via-teal-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .xl\:focus\:via-teal-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .xl\:focus\:via-teal-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .xl\:focus\:via-teal-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .xl\:focus\:via-teal-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .xl\:focus\:via-teal-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .xl\:focus\:via-teal-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .xl\:focus\:via-teal-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .xl\:focus\:via-teal-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .xl\:focus\:via-teal:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .xl\:focus\:via-teal-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .xl\:focus\:via-teal-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .xl\:focus\:via-teal-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .xl\:focus\:via-teal-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .xl\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .xl\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .xl\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .xl\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .xl\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .xl\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .xl\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .xl\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .xl\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .xl\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .xl\:focus\:via-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .xl\:focus\:via-blue-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .xl\:focus\:via-blue-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .xl\:focus\:via-blue-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .xl\:focus\:via-blue-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .xl\:focus\:via-light-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .xl\:focus\:via-light-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .xl\:focus\:via-light-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .xl\:focus\:via-light-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .xl\:focus\:via-light-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .xl\:focus\:via-light-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .xl\:focus\:via-light-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .xl\:focus\:via-light-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .xl\:focus\:via-light-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .xl\:focus\:via-light-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .xl\:focus\:via-light-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .xl\:focus\:via-light-blue-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .xl\:focus\:via-light-blue-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .xl\:focus\:via-light-blue-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .xl\:focus\:via-light-blue-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .xl\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .xl\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .xl\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .xl\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .xl\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .xl\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .xl\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .xl\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .xl\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .xl\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .xl\:focus\:via-indigo:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .xl\:focus\:via-indigo-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .xl\:focus\:via-indigo-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .xl\:focus\:via-indigo-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .xl\:focus\:via-indigo-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .xl\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .xl\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .xl\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .xl\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .xl\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .xl\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .xl\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .xl\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .xl\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .xl\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .xl\:focus\:via-purple:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .xl\:focus\:via-purple-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .xl\:focus\:via-purple-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .xl\:focus\:via-purple-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .xl\:focus\:via-purple-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .xl\:focus\:via-deep-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .xl\:focus\:via-deep-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .xl\:focus\:via-deep-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .xl\:focus\:via-deep-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .xl\:focus\:via-deep-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .xl\:focus\:via-deep-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .xl\:focus\:via-deep-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .xl\:focus\:via-deep-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .xl\:focus\:via-deep-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .xl\:focus\:via-deep-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .xl\:focus\:via-deep-purple:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .xl\:focus\:via-deep-purple-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .xl\:focus\:via-deep-purple-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .xl\:focus\:via-deep-purple-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .xl\:focus\:via-deep-purple-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .xl\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .xl\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .xl\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .xl\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .xl\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .xl\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .xl\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .xl\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .xl\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .xl\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .xl\:focus\:via-pink:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .xl\:focus\:via-pink-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .xl\:focus\:via-pink-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .xl\:focus\:via-pink-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .xl\:focus\:via-pink-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .xl\:focus\:via-lime-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .xl\:focus\:via-lime-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .xl\:focus\:via-lime-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .xl\:focus\:via-lime-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .xl\:focus\:via-lime-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .xl\:focus\:via-lime-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .xl\:focus\:via-lime-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .xl\:focus\:via-lime-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .xl\:focus\:via-lime-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .xl\:focus\:via-lime-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .xl\:focus\:via-lime:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .xl\:focus\:via-lime-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .xl\:focus\:via-lime-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .xl\:focus\:via-lime-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .xl\:focus\:via-lime-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .xl\:focus\:via-amber-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .xl\:focus\:via-amber-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .xl\:focus\:via-amber-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .xl\:focus\:via-amber-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .xl\:focus\:via-amber-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .xl\:focus\:via-amber-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .xl\:focus\:via-amber-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .xl\:focus\:via-amber-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .xl\:focus\:via-amber-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .xl\:focus\:via-amber-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .xl\:focus\:via-amber:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .xl\:focus\:via-amber-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .xl\:focus\:via-amber-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .xl\:focus\:via-amber-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .xl\:focus\:via-amber-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .xl\:focus\:via-brown-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .xl\:focus\:via-brown-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .xl\:focus\:via-brown-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .xl\:focus\:via-brown-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .xl\:focus\:via-brown-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .xl\:focus\:via-brown-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .xl\:focus\:via-brown-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .xl\:focus\:via-brown-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .xl\:focus\:via-brown-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .xl\:focus\:via-brown-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .xl\:focus\:via-brown:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .xl\:focus\:via-brown-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .xl\:focus\:via-brown-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .xl\:focus\:via-brown-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .xl\:focus\:via-brown-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .xl\:focus\:via-blue-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .xl\:focus\:via-blue-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .xl\:focus\:via-blue-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .xl\:focus\:via-blue-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .xl\:focus\:via-blue-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .xl\:focus\:via-blue-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .xl\:focus\:via-blue-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .xl\:focus\:via-blue-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .xl\:focus\:via-blue-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .xl\:focus\:via-blue-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .xl\:focus\:via-blue-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .xl\:focus\:via-blue-gray-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .xl\:focus\:via-blue-gray-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .xl\:focus\:via-blue-gray-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .xl\:focus\:via-blue-gray-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .xl\:focus\:via-cyan-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .xl\:focus\:via-cyan-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .xl\:focus\:via-cyan-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .xl\:focus\:via-cyan-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .xl\:focus\:via-cyan-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .xl\:focus\:via-cyan-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .xl\:focus\:via-cyan-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .xl\:focus\:via-cyan-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .xl\:focus\:via-cyan-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .xl\:focus\:via-cyan-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .xl\:focus\:via-cyan:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .xl\:focus\:via-cyan-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .xl\:focus\:via-cyan-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .xl\:focus\:via-cyan-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .xl\:focus\:via-cyan-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .xl\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent
  }

  .xl\:focus\:to-current:focus {
    --tw-gradient-to: currentColor
  }

  .xl\:focus\:to-black:focus {
    --tw-gradient-to: #22292F
  }

  .xl\:focus\:to-white:focus {
    --tw-gradient-to: #fff
  }

  .xl\:focus\:to-grey-50:focus {
    --tw-gradient-to: #FAFAFA
  }

  .xl\:focus\:to-grey-100:focus {
    --tw-gradient-to: #F5F5F5
  }

  .xl\:focus\:to-grey-200:focus {
    --tw-gradient-to: #EEEEEE
  }

  .xl\:focus\:to-grey-300:focus {
    --tw-gradient-to: #E0E0E0
  }

  .xl\:focus\:to-grey-400:focus {
    --tw-gradient-to: #BDBDBD
  }

  .xl\:focus\:to-grey-500:focus {
    --tw-gradient-to: #9E9E9E
  }

  .xl\:focus\:to-grey-600:focus {
    --tw-gradient-to: #757575
  }

  .xl\:focus\:to-grey-700:focus {
    --tw-gradient-to: #616161
  }

  .xl\:focus\:to-grey-800:focus {
    --tw-gradient-to: #424242
  }

  .xl\:focus\:to-grey-900:focus {
    --tw-gradient-to: #212121
  }

  .xl\:focus\:to-grey:focus {
    --tw-gradient-to: #9E9E9E
  }

  .xl\:focus\:to-grey-A100:focus {
    --tw-gradient-to: #D5D5D5
  }

  .xl\:focus\:to-grey-A200:focus {
    --tw-gradient-to: #AAAAAA
  }

  .xl\:focus\:to-grey-A400:focus {
    --tw-gradient-to: #303030
  }

  .xl\:focus\:to-grey-A700:focus {
    --tw-gradient-to: #616161
  }

  .xl\:focus\:to-gray-50:focus {
    --tw-gradient-to: #FAFAFA
  }

  .xl\:focus\:to-gray-100:focus {
    --tw-gradient-to: #F5F5F5
  }

  .xl\:focus\:to-gray-200:focus {
    --tw-gradient-to: #EEEEEE
  }

  .xl\:focus\:to-gray-300:focus {
    --tw-gradient-to: #E0E0E0
  }

  .xl\:focus\:to-gray-400:focus {
    --tw-gradient-to: #BDBDBD
  }

  .xl\:focus\:to-gray-500:focus {
    --tw-gradient-to: #9E9E9E
  }

  .xl\:focus\:to-gray-600:focus {
    --tw-gradient-to: #757575
  }

  .xl\:focus\:to-gray-700:focus {
    --tw-gradient-to: #616161
  }

  .xl\:focus\:to-gray-800:focus {
    --tw-gradient-to: #424242
  }

  .xl\:focus\:to-gray-900:focus {
    --tw-gradient-to: #212121
  }

  .xl\:focus\:to-gray:focus {
    --tw-gradient-to: #9E9E9E
  }

  .xl\:focus\:to-gray-hover:focus {
    --tw-gradient-to: rgba(0, 0, 0, 0.04)
  }

  .xl\:focus\:to-gray-A100:focus {
    --tw-gradient-to: #D5D5D5
  }

  .xl\:focus\:to-gray-A200:focus {
    --tw-gradient-to: #AAAAAA
  }

  .xl\:focus\:to-gray-A400:focus {
    --tw-gradient-to: #303030
  }

  .xl\:focus\:to-gray-A700:focus {
    --tw-gradient-to: #616161
  }

  .xl\:focus\:to-red-50:focus {
    --tw-gradient-to: #FFEBEE
  }

  .xl\:focus\:to-red-100:focus {
    --tw-gradient-to: #FFCDD2
  }

  .xl\:focus\:to-red-200:focus {
    --tw-gradient-to: #EF9A9A
  }

  .xl\:focus\:to-red-300:focus {
    --tw-gradient-to: #E57373
  }

  .xl\:focus\:to-red-400:focus {
    --tw-gradient-to: #EF5350
  }

  .xl\:focus\:to-red-500:focus {
    --tw-gradient-to: #F44336
  }

  .xl\:focus\:to-red-600:focus {
    --tw-gradient-to: #E53935
  }

  .xl\:focus\:to-red-700:focus {
    --tw-gradient-to: #D32F2F
  }

  .xl\:focus\:to-red-800:focus {
    --tw-gradient-to: #C62828
  }

  .xl\:focus\:to-red-900:focus {
    --tw-gradient-to: #B71C1C
  }

  .xl\:focus\:to-red:focus {
    --tw-gradient-to: #F44336
  }

  .xl\:focus\:to-red-A100:focus {
    --tw-gradient-to: #FF8A80
  }

  .xl\:focus\:to-red-A200:focus {
    --tw-gradient-to: #FF5252
  }

  .xl\:focus\:to-red-A400:focus {
    --tw-gradient-to: #FF1744
  }

  .xl\:focus\:to-red-A700:focus {
    --tw-gradient-to: #D50000
  }

  .xl\:focus\:to-orange-50:focus {
    --tw-gradient-to: #FFF3E0
  }

  .xl\:focus\:to-orange-100:focus {
    --tw-gradient-to: #FFE0B2
  }

  .xl\:focus\:to-orange-200:focus {
    --tw-gradient-to: #FFCC80
  }

  .xl\:focus\:to-orange-300:focus {
    --tw-gradient-to: #FFB74D
  }

  .xl\:focus\:to-orange-400:focus {
    --tw-gradient-to: #FFA726
  }

  .xl\:focus\:to-orange-500:focus {
    --tw-gradient-to: #FF9800
  }

  .xl\:focus\:to-orange-600:focus {
    --tw-gradient-to: #FB8C00
  }

  .xl\:focus\:to-orange-700:focus {
    --tw-gradient-to: #F57C00
  }

  .xl\:focus\:to-orange-800:focus {
    --tw-gradient-to: #EF6C00
  }

  .xl\:focus\:to-orange-900:focus {
    --tw-gradient-to: #E65100
  }

  .xl\:focus\:to-orange:focus {
    --tw-gradient-to: #FF9800
  }

  .xl\:focus\:to-orange-A100:focus {
    --tw-gradient-to: #FFD180
  }

  .xl\:focus\:to-orange-A200:focus {
    --tw-gradient-to: #FFAB40
  }

  .xl\:focus\:to-orange-A400:focus {
    --tw-gradient-to: #FF9100
  }

  .xl\:focus\:to-orange-A700:focus {
    --tw-gradient-to: #FF6D00
  }

  .xl\:focus\:to-deep-orange-50:focus {
    --tw-gradient-to: #FBE9E7
  }

  .xl\:focus\:to-deep-orange-100:focus {
    --tw-gradient-to: #FFCCBC
  }

  .xl\:focus\:to-deep-orange-200:focus {
    --tw-gradient-to: #FFAB91
  }

  .xl\:focus\:to-deep-orange-300:focus {
    --tw-gradient-to: #FF8A65
  }

  .xl\:focus\:to-deep-orange-400:focus {
    --tw-gradient-to: #FF7043
  }

  .xl\:focus\:to-deep-orange-500:focus {
    --tw-gradient-to: #FF5722
  }

  .xl\:focus\:to-deep-orange-600:focus {
    --tw-gradient-to: #F4511E
  }

  .xl\:focus\:to-deep-orange-700:focus {
    --tw-gradient-to: #E64A19
  }

  .xl\:focus\:to-deep-orange-800:focus {
    --tw-gradient-to: #D84315
  }

  .xl\:focus\:to-deep-orange-900:focus {
    --tw-gradient-to: #BF360C
  }

  .xl\:focus\:to-deep-orange:focus {
    --tw-gradient-to: #FF5722
  }

  .xl\:focus\:to-deep-orange-A100:focus {
    --tw-gradient-to: #FF9E80
  }

  .xl\:focus\:to-deep-orange-A200:focus {
    --tw-gradient-to: #FF6E40
  }

  .xl\:focus\:to-deep-orange-A400:focus {
    --tw-gradient-to: #FF3D00
  }

  .xl\:focus\:to-deep-orange-A700:focus {
    --tw-gradient-to: #DD2C00
  }

  .xl\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #FFFDE7
  }

  .xl\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #FFF9C4
  }

  .xl\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #FFF59D
  }

  .xl\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #FFF176
  }

  .xl\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #FFEE58
  }

  .xl\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #FFEB3B
  }

  .xl\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #FDD835
  }

  .xl\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #FBC02D
  }

  .xl\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #F9A825
  }

  .xl\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #F57F17
  }

  .xl\:focus\:to-yellow:focus {
    --tw-gradient-to: #FFEB3B
  }

  .xl\:focus\:to-yellow-A100:focus {
    --tw-gradient-to: #FFFF8D
  }

  .xl\:focus\:to-yellow-A200:focus {
    --tw-gradient-to: #FFFF00
  }

  .xl\:focus\:to-yellow-A400:focus {
    --tw-gradient-to: #FFEA00
  }

  .xl\:focus\:to-yellow-A700:focus {
    --tw-gradient-to: #FFD600
  }

  .xl\:focus\:to-green-50:focus {
    --tw-gradient-to: #E8F5E9
  }

  .xl\:focus\:to-green-100:focus {
    --tw-gradient-to: #C8E6C9
  }

  .xl\:focus\:to-green-200:focus {
    --tw-gradient-to: #A5D6A7
  }

  .xl\:focus\:to-green-300:focus {
    --tw-gradient-to: #81C784
  }

  .xl\:focus\:to-green-400:focus {
    --tw-gradient-to: #66BB6A
  }

  .xl\:focus\:to-green-500:focus {
    --tw-gradient-to: #4CAF50
  }

  .xl\:focus\:to-green-600:focus {
    --tw-gradient-to: #43A047
  }

  .xl\:focus\:to-green-700:focus {
    --tw-gradient-to: #388E3C
  }

  .xl\:focus\:to-green-800:focus {
    --tw-gradient-to: #2E7D32
  }

  .xl\:focus\:to-green-900:focus {
    --tw-gradient-to: #1B5E20
  }

  .xl\:focus\:to-green:focus {
    --tw-gradient-to: #4CAF50
  }

  .xl\:focus\:to-green-A100:focus {
    --tw-gradient-to: #B9F6CA
  }

  .xl\:focus\:to-green-A200:focus {
    --tw-gradient-to: #69F0AE
  }

  .xl\:focus\:to-green-A400:focus {
    --tw-gradient-to: #00E676
  }

  .xl\:focus\:to-green-A700:focus {
    --tw-gradient-to: #00C853
  }

  .xl\:focus\:to-light-green-50:focus {
    --tw-gradient-to: #F1F8E9
  }

  .xl\:focus\:to-light-green-100:focus {
    --tw-gradient-to: #DCEDC8
  }

  .xl\:focus\:to-light-green-200:focus {
    --tw-gradient-to: #C5E1A5
  }

  .xl\:focus\:to-light-green-300:focus {
    --tw-gradient-to: #AED581
  }

  .xl\:focus\:to-light-green-400:focus {
    --tw-gradient-to: #9CCC65
  }

  .xl\:focus\:to-light-green-500:focus {
    --tw-gradient-to: #8BC34A
  }

  .xl\:focus\:to-light-green-600:focus {
    --tw-gradient-to: #7CB342
  }

  .xl\:focus\:to-light-green-700:focus {
    --tw-gradient-to: #689F38
  }

  .xl\:focus\:to-light-green-800:focus {
    --tw-gradient-to: #558B2F
  }

  .xl\:focus\:to-light-green-900:focus {
    --tw-gradient-to: #33691E
  }

  .xl\:focus\:to-light-green:focus {
    --tw-gradient-to: #8BC34A
  }

  .xl\:focus\:to-light-green-A100:focus {
    --tw-gradient-to: #CCFF90
  }

  .xl\:focus\:to-light-green-A200:focus {
    --tw-gradient-to: #B2FF59
  }

  .xl\:focus\:to-light-green-A400:focus {
    --tw-gradient-to: #76FF03
  }

  .xl\:focus\:to-light-green-A700:focus {
    --tw-gradient-to: #64DD17
  }

  .xl\:focus\:to-teal-50:focus {
    --tw-gradient-to: #E0F2F1
  }

  .xl\:focus\:to-teal-100:focus {
    --tw-gradient-to: #B2DFDB
  }

  .xl\:focus\:to-teal-200:focus {
    --tw-gradient-to: #80CBC4
  }

  .xl\:focus\:to-teal-300:focus {
    --tw-gradient-to: #4DB6AC
  }

  .xl\:focus\:to-teal-400:focus {
    --tw-gradient-to: #26A69A
  }

  .xl\:focus\:to-teal-500:focus {
    --tw-gradient-to: #009688
  }

  .xl\:focus\:to-teal-600:focus {
    --tw-gradient-to: #00897B
  }

  .xl\:focus\:to-teal-700:focus {
    --tw-gradient-to: #00796B
  }

  .xl\:focus\:to-teal-800:focus {
    --tw-gradient-to: #00695C
  }

  .xl\:focus\:to-teal-900:focus {
    --tw-gradient-to: #004D40
  }

  .xl\:focus\:to-teal:focus {
    --tw-gradient-to: #009688
  }

  .xl\:focus\:to-teal-A100:focus {
    --tw-gradient-to: #A7FFEB
  }

  .xl\:focus\:to-teal-A200:focus {
    --tw-gradient-to: #64FFDA
  }

  .xl\:focus\:to-teal-A400:focus {
    --tw-gradient-to: #1DE9B6
  }

  .xl\:focus\:to-teal-A700:focus {
    --tw-gradient-to: #00BFA5
  }

  .xl\:focus\:to-blue-50:focus {
    --tw-gradient-to: #E3F2FD
  }

  .xl\:focus\:to-blue-100:focus {
    --tw-gradient-to: #BBDEFB
  }

  .xl\:focus\:to-blue-200:focus {
    --tw-gradient-to: #90CAF9
  }

  .xl\:focus\:to-blue-300:focus {
    --tw-gradient-to: #64B5F6
  }

  .xl\:focus\:to-blue-400:focus {
    --tw-gradient-to: #42A5F5
  }

  .xl\:focus\:to-blue-500:focus {
    --tw-gradient-to: #2196F3
  }

  .xl\:focus\:to-blue-600:focus {
    --tw-gradient-to: #1E88E5
  }

  .xl\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1976D2
  }

  .xl\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1565C0
  }

  .xl\:focus\:to-blue-900:focus {
    --tw-gradient-to: #0D47A1
  }

  .xl\:focus\:to-blue:focus {
    --tw-gradient-to: #2196F3
  }

  .xl\:focus\:to-blue-A100:focus {
    --tw-gradient-to: #82B1FF
  }

  .xl\:focus\:to-blue-A200:focus {
    --tw-gradient-to: #448AFF
  }

  .xl\:focus\:to-blue-A400:focus {
    --tw-gradient-to: #2979FF
  }

  .xl\:focus\:to-blue-A700:focus {
    --tw-gradient-to: #2962FF
  }

  .xl\:focus\:to-light-blue-50:focus {
    --tw-gradient-to: #E1F5FE
  }

  .xl\:focus\:to-light-blue-100:focus {
    --tw-gradient-to: #B3E5FC
  }

  .xl\:focus\:to-light-blue-200:focus {
    --tw-gradient-to: #81D4FA
  }

  .xl\:focus\:to-light-blue-300:focus {
    --tw-gradient-to: #4FC3F7
  }

  .xl\:focus\:to-light-blue-400:focus {
    --tw-gradient-to: #29B6F6
  }

  .xl\:focus\:to-light-blue-500:focus {
    --tw-gradient-to: #03A9F4
  }

  .xl\:focus\:to-light-blue-600:focus {
    --tw-gradient-to: #039BE5
  }

  .xl\:focus\:to-light-blue-700:focus {
    --tw-gradient-to: #0288D1
  }

  .xl\:focus\:to-light-blue-800:focus {
    --tw-gradient-to: #0277BD
  }

  .xl\:focus\:to-light-blue-900:focus {
    --tw-gradient-to: #01579B
  }

  .xl\:focus\:to-light-blue:focus {
    --tw-gradient-to: #03A9F4
  }

  .xl\:focus\:to-light-blue-A100:focus {
    --tw-gradient-to: #80D8FF
  }

  .xl\:focus\:to-light-blue-A200:focus {
    --tw-gradient-to: #40C4FF
  }

  .xl\:focus\:to-light-blue-A400:focus {
    --tw-gradient-to: #00B0FF
  }

  .xl\:focus\:to-light-blue-A700:focus {
    --tw-gradient-to: #0091EA
  }

  .xl\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #E8EAF6
  }

  .xl\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #C5CAE9
  }

  .xl\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #9FA8DA
  }

  .xl\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #7986CB
  }

  .xl\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #5C6BC0
  }

  .xl\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #3F51B5
  }

  .xl\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #3949AB
  }

  .xl\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #303F9F
  }

  .xl\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #283593
  }

  .xl\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #1A237E
  }

  .xl\:focus\:to-indigo:focus {
    --tw-gradient-to: #3F51B5
  }

  .xl\:focus\:to-indigo-A100:focus {
    --tw-gradient-to: #8C9EFF
  }

  .xl\:focus\:to-indigo-A200:focus {
    --tw-gradient-to: #536DFE
  }

  .xl\:focus\:to-indigo-A400:focus {
    --tw-gradient-to: #3D5AFE
  }

  .xl\:focus\:to-indigo-A700:focus {
    --tw-gradient-to: #304FFE
  }

  .xl\:focus\:to-purple-50:focus {
    --tw-gradient-to: #F3E5F5
  }

  .xl\:focus\:to-purple-100:focus {
    --tw-gradient-to: #E1BEE7
  }

  .xl\:focus\:to-purple-200:focus {
    --tw-gradient-to: #CE93D8
  }

  .xl\:focus\:to-purple-300:focus {
    --tw-gradient-to: #BA68C8
  }

  .xl\:focus\:to-purple-400:focus {
    --tw-gradient-to: #AB47BC
  }

  .xl\:focus\:to-purple-500:focus {
    --tw-gradient-to: #9C27B0
  }

  .xl\:focus\:to-purple-600:focus {
    --tw-gradient-to: #8E24AA
  }

  .xl\:focus\:to-purple-700:focus {
    --tw-gradient-to: #7B1FA2
  }

  .xl\:focus\:to-purple-800:focus {
    --tw-gradient-to: #6A1B9A
  }

  .xl\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4A148C
  }

  .xl\:focus\:to-purple:focus {
    --tw-gradient-to: #9C27B0
  }

  .xl\:focus\:to-purple-A100:focus {
    --tw-gradient-to: #EA80FC
  }

  .xl\:focus\:to-purple-A200:focus {
    --tw-gradient-to: #E040FB
  }

  .xl\:focus\:to-purple-A400:focus {
    --tw-gradient-to: #D500F9
  }

  .xl\:focus\:to-purple-A700:focus {
    --tw-gradient-to: #AA00FF
  }

  .xl\:focus\:to-deep-purple-50:focus {
    --tw-gradient-to: #EDE7F6
  }

  .xl\:focus\:to-deep-purple-100:focus {
    --tw-gradient-to: #D1C4E9
  }

  .xl\:focus\:to-deep-purple-200:focus {
    --tw-gradient-to: #B39DDB
  }

  .xl\:focus\:to-deep-purple-300:focus {
    --tw-gradient-to: #9575CD
  }

  .xl\:focus\:to-deep-purple-400:focus {
    --tw-gradient-to: #7E57C2
  }

  .xl\:focus\:to-deep-purple-500:focus {
    --tw-gradient-to: #673AB7
  }

  .xl\:focus\:to-deep-purple-600:focus {
    --tw-gradient-to: #5E35B1
  }

  .xl\:focus\:to-deep-purple-700:focus {
    --tw-gradient-to: #512DA8
  }

  .xl\:focus\:to-deep-purple-800:focus {
    --tw-gradient-to: #4527A0
  }

  .xl\:focus\:to-deep-purple-900:focus {
    --tw-gradient-to: #311B92
  }

  .xl\:focus\:to-deep-purple:focus {
    --tw-gradient-to: #673AB7
  }

  .xl\:focus\:to-deep-purple-A100:focus {
    --tw-gradient-to: #B388FF
  }

  .xl\:focus\:to-deep-purple-A200:focus {
    --tw-gradient-to: #7C4DFF
  }

  .xl\:focus\:to-deep-purple-A400:focus {
    --tw-gradient-to: #651FFF
  }

  .xl\:focus\:to-deep-purple-A700:focus {
    --tw-gradient-to: #6200EA
  }

  .xl\:focus\:to-pink-50:focus {
    --tw-gradient-to: #FCE4EC
  }

  .xl\:focus\:to-pink-100:focus {
    --tw-gradient-to: #F8BBD0
  }

  .xl\:focus\:to-pink-200:focus {
    --tw-gradient-to: #F48FB1
  }

  .xl\:focus\:to-pink-300:focus {
    --tw-gradient-to: #F06292
  }

  .xl\:focus\:to-pink-400:focus {
    --tw-gradient-to: #EC407A
  }

  .xl\:focus\:to-pink-500:focus {
    --tw-gradient-to: #E91E63
  }

  .xl\:focus\:to-pink-600:focus {
    --tw-gradient-to: #D81B60
  }

  .xl\:focus\:to-pink-700:focus {
    --tw-gradient-to: #C2185B
  }

  .xl\:focus\:to-pink-800:focus {
    --tw-gradient-to: #AD1457
  }

  .xl\:focus\:to-pink-900:focus {
    --tw-gradient-to: #880E4F
  }

  .xl\:focus\:to-pink:focus {
    --tw-gradient-to: #E91E63
  }

  .xl\:focus\:to-pink-A100:focus {
    --tw-gradient-to: #FF80AB
  }

  .xl\:focus\:to-pink-A200:focus {
    --tw-gradient-to: #FF4081
  }

  .xl\:focus\:to-pink-A400:focus {
    --tw-gradient-to: #F50057
  }

  .xl\:focus\:to-pink-A700:focus {
    --tw-gradient-to: #C51162
  }

  .xl\:focus\:to-lime-50:focus {
    --tw-gradient-to: #F9FBE7
  }

  .xl\:focus\:to-lime-100:focus {
    --tw-gradient-to: #F0F4C3
  }

  .xl\:focus\:to-lime-200:focus {
    --tw-gradient-to: #E6EE9C
  }

  .xl\:focus\:to-lime-300:focus {
    --tw-gradient-to: #DCE775
  }

  .xl\:focus\:to-lime-400:focus {
    --tw-gradient-to: #D4E157
  }

  .xl\:focus\:to-lime-500:focus {
    --tw-gradient-to: #CDDC39
  }

  .xl\:focus\:to-lime-600:focus {
    --tw-gradient-to: #C0CA33
  }

  .xl\:focus\:to-lime-700:focus {
    --tw-gradient-to: #AFB42B
  }

  .xl\:focus\:to-lime-800:focus {
    --tw-gradient-to: #9E9D24
  }

  .xl\:focus\:to-lime-900:focus {
    --tw-gradient-to: #827717
  }

  .xl\:focus\:to-lime:focus {
    --tw-gradient-to: #CDDC39
  }

  .xl\:focus\:to-lime-A100:focus {
    --tw-gradient-to: #F4FF81
  }

  .xl\:focus\:to-lime-A200:focus {
    --tw-gradient-to: #EEFF41
  }

  .xl\:focus\:to-lime-A400:focus {
    --tw-gradient-to: #C6FF00
  }

  .xl\:focus\:to-lime-A700:focus {
    --tw-gradient-to: #AEEA00
  }

  .xl\:focus\:to-amber-50:focus {
    --tw-gradient-to: #FFF8E1
  }

  .xl\:focus\:to-amber-100:focus {
    --tw-gradient-to: #FFECB3
  }

  .xl\:focus\:to-amber-200:focus {
    --tw-gradient-to: #FFE082
  }

  .xl\:focus\:to-amber-300:focus {
    --tw-gradient-to: #FFD54F
  }

  .xl\:focus\:to-amber-400:focus {
    --tw-gradient-to: #FFCA28
  }

  .xl\:focus\:to-amber-500:focus {
    --tw-gradient-to: #FFC107
  }

  .xl\:focus\:to-amber-600:focus {
    --tw-gradient-to: #FFB300
  }

  .xl\:focus\:to-amber-700:focus {
    --tw-gradient-to: #FFA000
  }

  .xl\:focus\:to-amber-800:focus {
    --tw-gradient-to: #FF8F00
  }

  .xl\:focus\:to-amber-900:focus {
    --tw-gradient-to: #FF6F00
  }

  .xl\:focus\:to-amber:focus {
    --tw-gradient-to: #FFC107
  }

  .xl\:focus\:to-amber-A100:focus {
    --tw-gradient-to: #FFE57F
  }

  .xl\:focus\:to-amber-A200:focus {
    --tw-gradient-to: #FFD740
  }

  .xl\:focus\:to-amber-A400:focus {
    --tw-gradient-to: #FFC400
  }

  .xl\:focus\:to-amber-A700:focus {
    --tw-gradient-to: #FFAB00
  }

  .xl\:focus\:to-brown-50:focus {
    --tw-gradient-to: #EFEBE9
  }

  .xl\:focus\:to-brown-100:focus {
    --tw-gradient-to: #D7CCC8
  }

  .xl\:focus\:to-brown-200:focus {
    --tw-gradient-to: #BCAAA4
  }

  .xl\:focus\:to-brown-300:focus {
    --tw-gradient-to: #A1887F
  }

  .xl\:focus\:to-brown-400:focus {
    --tw-gradient-to: #8D6E63
  }

  .xl\:focus\:to-brown-500:focus {
    --tw-gradient-to: #795548
  }

  .xl\:focus\:to-brown-600:focus {
    --tw-gradient-to: #6D4C41
  }

  .xl\:focus\:to-brown-700:focus {
    --tw-gradient-to: #5D4037
  }

  .xl\:focus\:to-brown-800:focus {
    --tw-gradient-to: #4E342E
  }

  .xl\:focus\:to-brown-900:focus {
    --tw-gradient-to: #3E2723
  }

  .xl\:focus\:to-brown:focus {
    --tw-gradient-to: #795548
  }

  .xl\:focus\:to-brown-A100:focus {
    --tw-gradient-to: #D7CCC8
  }

  .xl\:focus\:to-brown-A200:focus {
    --tw-gradient-to: #BCAAA4
  }

  .xl\:focus\:to-brown-A400:focus {
    --tw-gradient-to: #8D6E63
  }

  .xl\:focus\:to-brown-A700:focus {
    --tw-gradient-to: #5D4037
  }

  .xl\:focus\:to-blue-gray-50:focus {
    --tw-gradient-to: #ECEFF1
  }

  .xl\:focus\:to-blue-gray-100:focus {
    --tw-gradient-to: #CFD8DC
  }

  .xl\:focus\:to-blue-gray-200:focus {
    --tw-gradient-to: #B0BEC5
  }

  .xl\:focus\:to-blue-gray-300:focus {
    --tw-gradient-to: #90A4AE
  }

  .xl\:focus\:to-blue-gray-400:focus {
    --tw-gradient-to: #78909C
  }

  .xl\:focus\:to-blue-gray-500:focus {
    --tw-gradient-to: #607D8B
  }

  .xl\:focus\:to-blue-gray-600:focus {
    --tw-gradient-to: #546E7A
  }

  .xl\:focus\:to-blue-gray-700:focus {
    --tw-gradient-to: #455A64
  }

  .xl\:focus\:to-blue-gray-800:focus {
    --tw-gradient-to: #37474F
  }

  .xl\:focus\:to-blue-gray-900:focus {
    --tw-gradient-to: #263238
  }

  .xl\:focus\:to-blue-gray:focus {
    --tw-gradient-to: #607D8B
  }

  .xl\:focus\:to-blue-gray-A100:focus {
    --tw-gradient-to: #CFD8DC
  }

  .xl\:focus\:to-blue-gray-A200:focus {
    --tw-gradient-to: #B0BEC5
  }

  .xl\:focus\:to-blue-gray-A400:focus {
    --tw-gradient-to: #78909C
  }

  .xl\:focus\:to-blue-gray-A700:focus {
    --tw-gradient-to: #455A64
  }

  .xl\:focus\:to-cyan-50:focus {
    --tw-gradient-to: #E0F7FA
  }

  .xl\:focus\:to-cyan-100:focus {
    --tw-gradient-to: #B2EBF2
  }

  .xl\:focus\:to-cyan-200:focus {
    --tw-gradient-to: #80DEEA
  }

  .xl\:focus\:to-cyan-300:focus {
    --tw-gradient-to: #4DD0E1
  }

  .xl\:focus\:to-cyan-400:focus {
    --tw-gradient-to: #26C6DA
  }

  .xl\:focus\:to-cyan-500:focus {
    --tw-gradient-to: #00BCD4
  }

  .xl\:focus\:to-cyan-600:focus {
    --tw-gradient-to: #00ACC1
  }

  .xl\:focus\:to-cyan-700:focus {
    --tw-gradient-to: #0097A7
  }

  .xl\:focus\:to-cyan-800:focus {
    --tw-gradient-to: #00838F
  }

  .xl\:focus\:to-cyan-900:focus {
    --tw-gradient-to: #006064
  }

  .xl\:focus\:to-cyan:focus {
    --tw-gradient-to: #00BCD4
  }

  .xl\:focus\:to-cyan-A100:focus {
    --tw-gradient-to: #84FFFF
  }

  .xl\:focus\:to-cyan-A200:focus {
    --tw-gradient-to: #18FFFF
  }

  .xl\:focus\:to-cyan-A400:focus {
    --tw-gradient-to: #00E5FF
  }

  .xl\:focus\:to-cyan-A700:focus {
    --tw-gradient-to: #00B8D4
  }

  .xl\:bg-opacity-0 {
    --tw-bg-opacity: 0
  }

  .xl\:bg-opacity-5 {
    --tw-bg-opacity: 0.05
  }

  .xl\:bg-opacity-10 {
    --tw-bg-opacity: 0.1
  }

  .xl\:bg-opacity-20 {
    --tw-bg-opacity: 0.2
  }

  .xl\:bg-opacity-25 {
    --tw-bg-opacity: 0.25
  }

  .xl\:bg-opacity-30 {
    --tw-bg-opacity: 0.3
  }

  .xl\:bg-opacity-40 {
    --tw-bg-opacity: 0.4
  }

  .xl\:bg-opacity-50 {
    --tw-bg-opacity: 0.5
  }

  .xl\:bg-opacity-60 {
    --tw-bg-opacity: 0.6
  }

  .xl\:bg-opacity-70 {
    --tw-bg-opacity: 0.7
  }

  .xl\:bg-opacity-75 {
    --tw-bg-opacity: 0.75
  }

  .xl\:bg-opacity-80 {
    --tw-bg-opacity: 0.8
  }

  .xl\:bg-opacity-90 {
    --tw-bg-opacity: 0.9
  }

  .xl\:bg-opacity-95 {
    --tw-bg-opacity: 0.95
  }

  .xl\:bg-opacity-100 {
    --tw-bg-opacity: 1
  }

  .group:hover .xl\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0
  }

  .group:hover .xl\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05
  }

  .group:hover .xl\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1
  }

  .group:hover .xl\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2
  }

  .group:hover .xl\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25
  }

  .group:hover .xl\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3
  }

  .group:hover .xl\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4
  }

  .group:hover .xl\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5
  }

  .group:hover .xl\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6
  }

  .group:hover .xl\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7
  }

  .group:hover .xl\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75
  }

  .group:hover .xl\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8
  }

  .group:hover .xl\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9
  }

  .group:hover .xl\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95
  }

  .group:hover .xl\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1
  }

  .xl\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0
  }

  .xl\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05
  }

  .xl\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1
  }

  .xl\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2
  }

  .xl\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25
  }

  .xl\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3
  }

  .xl\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4
  }

  .xl\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5
  }

  .xl\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6
  }

  .xl\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7
  }

  .xl\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75
  }

  .xl\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8
  }

  .xl\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9
  }

  .xl\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95
  }

  .xl\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1
  }

  .xl\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0
  }

  .xl\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05
  }

  .xl\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1
  }

  .xl\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2
  }

  .xl\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25
  }

  .xl\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3
  }

  .xl\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4
  }

  .xl\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5
  }

  .xl\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6
  }

  .xl\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7
  }

  .xl\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75
  }

  .xl\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8
  }

  .xl\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9
  }

  .xl\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95
  }

  .xl\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1
  }

  .xl\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0
  }

  .xl\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05
  }

  .xl\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1
  }

  .xl\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2
  }

  .xl\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25
  }

  .xl\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3
  }

  .xl\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4
  }

  .xl\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5
  }

  .xl\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6
  }

  .xl\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7
  }

  .xl\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75
  }

  .xl\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8
  }

  .xl\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9
  }

  .xl\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95
  }

  .xl\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1
  }

  .xl\:bg-bottom {
    background-position: bottom
  }

  .xl\:bg-center {
    background-position: center
  }

  .xl\:bg-left {
    background-position: left
  }

  .xl\:bg-left-bottom {
    background-position: left bottom
  }

  .xl\:bg-left-top {
    background-position: left top
  }

  .xl\:bg-right {
    background-position: right
  }

  .xl\:bg-right-bottom {
    background-position: right bottom
  }

  .xl\:bg-right-top {
    background-position: right top
  }

  .xl\:bg-top {
    background-position: top
  }

  .xl\:bg-repeat {
    background-repeat: repeat
  }

  .xl\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .xl\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .xl\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .xl\:bg-repeat-round {
    background-repeat: round
  }

  .xl\:bg-repeat-space {
    background-repeat: space
  }

  .xl\:bg-auto {
    background-size: auto
  }

  .xl\:bg-cover {
    background-size: cover
  }

  .xl\:bg-contain {
    background-size: contain
  }

  .xl\:border-collapse {
    border-collapse: collapse
  }

  .xl\:border-separate {
    border-collapse: separate
  }

  .xl\:border-transparent {
    border-color: transparent
  }

  .xl\:border-current {
    border-color: currentColor
  }

  .xl\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .xl\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xl\:border-grey-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .xl\:border-grey-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .xl\:border-grey-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .xl\:border-grey-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .xl\:border-grey-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .xl\:border-grey-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:border-grey-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .xl\:border-grey-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:border-grey-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .xl\:border-grey-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .xl\:border-grey {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:border-grey-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .xl\:border-grey-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .xl\:border-grey-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .xl\:border-grey-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .xl\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .xl\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .xl\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .xl\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .xl\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .xl\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .xl\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .xl\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:border-gray-hover {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .xl\:border-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .xl\:border-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .xl\:border-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .xl\:border-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .xl\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .xl\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .xl\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .xl\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .xl\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .xl\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .xl\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .xl\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .xl\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .xl\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .xl\:border-red-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .xl\:border-red-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .xl\:border-red-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .xl\:border-red-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .xl\:border-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .xl\:border-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .xl\:border-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .xl\:border-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .xl\:border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .xl\:border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .xl\:border-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .xl\:border-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .xl\:border-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .xl\:border-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .xl\:border-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .xl\:border-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .xl\:border-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .xl\:border-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .xl\:border-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .xl\:border-deep-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .xl\:border-deep-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .xl\:border-deep-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .xl\:border-deep-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .xl\:border-deep-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .xl\:border-deep-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .xl\:border-deep-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .xl\:border-deep-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .xl\:border-deep-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .xl\:border-deep-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .xl\:border-deep-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .xl\:border-deep-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .xl\:border-deep-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .xl\:border-deep-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .xl\:border-deep-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .xl\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .xl\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .xl\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .xl\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .xl\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .xl\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .xl\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .xl\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .xl\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .xl\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .xl\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .xl\:border-yellow-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .xl\:border-yellow-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .xl\:border-yellow-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .xl\:border-yellow-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .xl\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .xl\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .xl\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .xl\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .xl\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .xl\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .xl\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .xl\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .xl\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .xl\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .xl\:border-green {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .xl\:border-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .xl\:border-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .xl\:border-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .xl\:border-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .xl\:border-light-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .xl\:border-light-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .xl\:border-light-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .xl\:border-light-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .xl\:border-light-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .xl\:border-light-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .xl\:border-light-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .xl\:border-light-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .xl\:border-light-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .xl\:border-light-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .xl\:border-light-green {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .xl\:border-light-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .xl\:border-light-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .xl\:border-light-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .xl\:border-light-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .xl\:border-teal-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .xl\:border-teal-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .xl\:border-teal-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .xl\:border-teal-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .xl\:border-teal-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .xl\:border-teal-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .xl\:border-teal-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .xl\:border-teal-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .xl\:border-teal-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .xl\:border-teal-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .xl\:border-teal {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .xl\:border-teal-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .xl\:border-teal-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .xl\:border-teal-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .xl\:border-teal-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .xl\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .xl\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .xl\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .xl\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .xl\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .xl\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .xl\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .xl\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .xl\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .xl\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .xl\:border-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .xl\:border-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .xl\:border-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .xl\:border-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .xl\:border-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .xl\:border-light-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .xl\:border-light-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .xl\:border-light-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .xl\:border-light-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .xl\:border-light-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .xl\:border-light-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .xl\:border-light-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .xl\:border-light-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .xl\:border-light-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .xl\:border-light-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .xl\:border-light-blue {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .xl\:border-light-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .xl\:border-light-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .xl\:border-light-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .xl\:border-light-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .xl\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .xl\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .xl\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .xl\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .xl\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .xl\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .xl\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .xl\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .xl\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .xl\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .xl\:border-indigo {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .xl\:border-indigo-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .xl\:border-indigo-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .xl\:border-indigo-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .xl\:border-indigo-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .xl\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .xl\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .xl\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .xl\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .xl\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .xl\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .xl\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .xl\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .xl\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .xl\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .xl\:border-purple {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .xl\:border-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .xl\:border-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .xl\:border-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .xl\:border-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .xl\:border-deep-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .xl\:border-deep-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .xl\:border-deep-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .xl\:border-deep-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .xl\:border-deep-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .xl\:border-deep-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .xl\:border-deep-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .xl\:border-deep-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .xl\:border-deep-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .xl\:border-deep-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .xl\:border-deep-purple {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .xl\:border-deep-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .xl\:border-deep-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .xl\:border-deep-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .xl\:border-deep-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .xl\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .xl\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .xl\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .xl\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .xl\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .xl\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .xl\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .xl\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .xl\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .xl\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .xl\:border-pink {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .xl\:border-pink-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .xl\:border-pink-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .xl\:border-pink-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .xl\:border-pink-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .xl\:border-lime-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .xl\:border-lime-100 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .xl\:border-lime-200 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .xl\:border-lime-300 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .xl\:border-lime-400 {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .xl\:border-lime-500 {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .xl\:border-lime-600 {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .xl\:border-lime-700 {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .xl\:border-lime-800 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .xl\:border-lime-900 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .xl\:border-lime {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .xl\:border-lime-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .xl\:border-lime-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .xl\:border-lime-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .xl\:border-lime-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .xl\:border-amber-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .xl\:border-amber-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .xl\:border-amber-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .xl\:border-amber-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .xl\:border-amber-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .xl\:border-amber-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .xl\:border-amber-600 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .xl\:border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .xl\:border-amber-800 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .xl\:border-amber-900 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .xl\:border-amber {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .xl\:border-amber-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .xl\:border-amber-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .xl\:border-amber-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .xl\:border-amber-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .xl\:border-brown-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .xl\:border-brown-100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .xl\:border-brown-200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .xl\:border-brown-300 {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .xl\:border-brown-400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .xl\:border-brown-500 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .xl\:border-brown-600 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .xl\:border-brown-700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .xl\:border-brown-800 {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .xl\:border-brown-900 {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .xl\:border-brown {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .xl\:border-brown-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .xl\:border-brown-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .xl\:border-brown-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .xl\:border-brown-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .xl\:border-blue-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .xl\:border-blue-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .xl\:border-blue-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .xl\:border-blue-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .xl\:border-blue-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .xl\:border-blue-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .xl\:border-blue-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .xl\:border-blue-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .xl\:border-blue-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .xl\:border-blue-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .xl\:border-blue-gray {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .xl\:border-blue-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .xl\:border-blue-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .xl\:border-blue-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .xl\:border-blue-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .xl\:border-cyan-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .xl\:border-cyan-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .xl\:border-cyan-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .xl\:border-cyan-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .xl\:border-cyan-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .xl\:border-cyan-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .xl\:border-cyan-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .xl\:border-cyan-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .xl\:border-cyan-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .xl\:border-cyan-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .xl\:border-cyan {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .xl\:border-cyan-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .xl\:border-cyan-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .xl\:border-cyan-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .xl\:border-cyan-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-transparent {
    border-color: transparent
  }

  .group:hover .xl\:group-hover\:border-current {
    border-color: currentColor
  }

  .group:hover .xl\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-grey-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-grey-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-grey-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-grey-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-grey-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-grey-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-grey-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-grey-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-grey-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-grey-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-grey {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-grey-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-grey-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-grey-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-grey-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-gray-hover {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .group:hover .xl\:group-hover\:border-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-red-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-red-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-red-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-red-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-yellow-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-yellow-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-yellow-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-yellow-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-green {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-green {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-teal-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-teal-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-teal-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-teal-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-teal-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-teal-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-teal-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-teal-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-teal-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-teal-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-teal {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-teal-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-teal-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-teal-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-teal-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-blue {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-light-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-indigo {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-indigo-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-indigo-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-indigo-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-indigo-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-purple {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-purple {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-deep-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-pink {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-pink-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-pink-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-pink-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-pink-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-lime-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-lime-100 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-lime-200 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-lime-300 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-lime-400 {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-lime-500 {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-lime-600 {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-lime-700 {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-lime-800 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-lime-900 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-lime {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-lime-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-lime-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-lime-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-lime-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-amber-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-amber-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-amber-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-amber-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-amber-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-amber-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-amber-600 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-amber-800 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-amber-900 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-amber {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-amber-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-amber-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-amber-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-amber-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-brown-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-brown-100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-brown-200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-brown-300 {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-brown-400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-brown-500 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-brown-600 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-brown-700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-brown-800 {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-brown-900 {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-brown {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-brown-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-brown-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-brown-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-brown-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-gray {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-blue-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-cyan-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-cyan-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-cyan-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-cyan-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-cyan-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-cyan-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-cyan-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-cyan-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-cyan-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-cyan-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-cyan {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-cyan-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-cyan-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-cyan-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .group:hover .xl\:group-hover\:border-cyan-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-transparent:focus-within {
    border-color: transparent
  }

  .xl\:focus-within\:border-current:focus-within {
    border-color: currentColor
  }

  .xl\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-grey-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-grey-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-grey-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-grey-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-grey-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-grey-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-grey-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-grey-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-grey-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-grey-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-grey-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-grey-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-grey-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-grey-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-gray-hover:focus-within {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .xl\:focus-within\:border-gray-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-gray-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-gray-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-gray-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-red:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-red-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-red-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-red-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-red-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-orange-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-orange-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-orange-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-orange-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-orange-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-orange-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-orange-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-orange-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-orange-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-orange-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-orange:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-orange-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-orange-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-orange-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-orange-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-orange-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-orange-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-orange-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-orange-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-orange-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-orange-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-orange-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-orange-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-orange-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-orange-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-orange:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-orange-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-orange-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-orange-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-orange-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-yellow:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-yellow-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-yellow-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-yellow-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-yellow-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-green-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-green-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-green-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-green-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-green-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-green-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-green-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-green-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-teal-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-teal-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-teal-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-teal-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-teal-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-teal-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-teal-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-teal-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-teal-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-teal-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-teal:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-teal-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-teal-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-teal-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-teal-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-blue-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-blue-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-blue-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-light-blue-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-indigo:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-indigo-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-indigo-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-indigo-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-indigo-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-purple:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-purple-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-purple-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-purple-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-purple-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-purple:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-purple-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-purple-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-purple-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-deep-purple-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-pink:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-pink-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-pink-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-pink-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-pink-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-lime-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-lime-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-lime-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-lime-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-lime-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-lime-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-lime-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-lime-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-lime-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-lime-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-lime:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-lime-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-lime-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-lime-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-lime-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-amber-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-amber-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-amber-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-amber-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-amber-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-amber-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-amber-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-amber-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-amber-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-amber-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-amber:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-amber-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-amber-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-amber-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-amber-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-brown-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-brown-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-brown-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-brown-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-brown-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-brown-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-brown-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-brown-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-brown-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-brown-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-brown:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-brown-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-brown-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-brown-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-brown-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-gray-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-gray-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-gray-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-blue-gray-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-cyan-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-cyan-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-cyan-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-cyan-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-cyan-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-cyan-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-cyan-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-cyan-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-cyan-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-cyan-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-cyan:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-cyan-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-cyan-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-cyan-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .xl\:focus-within\:border-cyan-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .xl\:hover\:border-transparent:hover {
    border-color: transparent
  }

  .xl\:hover\:border-current:hover {
    border-color: currentColor
  }

  .xl\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .xl\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-grey-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .xl\:hover\:border-grey-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .xl\:hover\:border-grey-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .xl\:hover\:border-grey-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .xl\:hover\:border-grey-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .xl\:hover\:border-grey-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:hover\:border-grey-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .xl\:hover\:border-grey-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:hover\:border-grey-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .xl\:hover\:border-grey-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .xl\:hover\:border-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:hover\:border-grey-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .xl\:hover\:border-grey-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .xl\:hover\:border-grey-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .xl\:hover\:border-grey-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray-hover:hover {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .xl\:hover\:border-gray-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-orange-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .xl\:hover\:border-orange-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .xl\:hover\:border-orange-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .xl\:hover\:border-orange-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .xl\:hover\:border-orange-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .xl\:hover\:border-orange-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-orange-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-orange-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-orange-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-orange-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-orange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-orange-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .xl\:hover\:border-orange-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .xl\:hover\:border-orange-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-orange-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-orange-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-orange-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-orange-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-orange-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-orange-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-orange-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-orange-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-orange-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-orange-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-orange-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-orange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-orange-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-orange-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-orange-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-orange-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .xl\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .xl\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .xl\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .xl\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .xl\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .xl\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .xl\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .xl\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .xl\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .xl\:hover\:border-yellow:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .xl\:hover\:border-yellow-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .xl\:hover\:border-yellow-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-yellow-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-yellow-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-green-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-green-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-green-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-green-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .xl\:hover\:border-teal-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .xl\:hover\:border-teal-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .xl\:hover\:border-teal-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .xl\:hover\:border-teal-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .xl\:hover\:border-teal-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .xl\:hover\:border-teal-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .xl\:hover\:border-teal-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .xl\:hover\:border-teal-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .xl\:hover\:border-teal-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .xl\:hover\:border-teal-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .xl\:hover\:border-teal:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .xl\:hover\:border-teal-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .xl\:hover\:border-teal-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .xl\:hover\:border-teal-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .xl\:hover\:border-teal-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-blue-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-blue-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-blue-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-light-blue-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .xl\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .xl\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .xl\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .xl\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .xl\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .xl\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .xl\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .xl\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .xl\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .xl\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .xl\:hover\:border-indigo:hover {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .xl\:hover\:border-indigo-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-indigo-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .xl\:hover\:border-indigo-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .xl\:hover\:border-indigo-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .xl\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .xl\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .xl\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .xl\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .xl\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .xl\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .xl\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .xl\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .xl\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .xl\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .xl\:hover\:border-purple:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .xl\:hover\:border-purple-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .xl\:hover\:border-purple-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .xl\:hover\:border-purple-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .xl\:hover\:border-purple-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-purple:hover {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-purple-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-purple-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-purple-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-deep-purple-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .xl\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .xl\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .xl\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .xl\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .xl\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .xl\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .xl\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .xl\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .xl\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .xl\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .xl\:hover\:border-pink:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .xl\:hover\:border-pink-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .xl\:hover\:border-pink-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .xl\:hover\:border-pink-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .xl\:hover\:border-pink-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .xl\:hover\:border-lime-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .xl\:hover\:border-lime-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .xl\:hover\:border-lime-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .xl\:hover\:border-lime-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .xl\:hover\:border-lime-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .xl\:hover\:border-lime-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .xl\:hover\:border-lime-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .xl\:hover\:border-lime-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .xl\:hover\:border-lime-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .xl\:hover\:border-lime-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .xl\:hover\:border-lime:hover {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .xl\:hover\:border-lime-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .xl\:hover\:border-lime-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .xl\:hover\:border-lime-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-lime-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-amber-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .xl\:hover\:border-amber-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .xl\:hover\:border-amber-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .xl\:hover\:border-amber-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .xl\:hover\:border-amber-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .xl\:hover\:border-amber-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .xl\:hover\:border-amber-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-amber-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-amber-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-amber-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-amber:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .xl\:hover\:border-amber-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .xl\:hover\:border-amber-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .xl\:hover\:border-amber-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-amber-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-brown-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .xl\:hover\:border-brown-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .xl\:hover\:border-brown-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .xl\:hover\:border-brown-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .xl\:hover\:border-brown-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .xl\:hover\:border-brown-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .xl\:hover\:border-brown-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .xl\:hover\:border-brown-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .xl\:hover\:border-brown-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .xl\:hover\:border-brown-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .xl\:hover\:border-brown:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .xl\:hover\:border-brown-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .xl\:hover\:border-brown-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .xl\:hover\:border-brown-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .xl\:hover\:border-brown-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-gray-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-gray-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-gray-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .xl\:hover\:border-blue-gray-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .xl\:focus\:border-transparent:focus {
    border-color: transparent
  }

  .xl\:focus\:border-current:focus {
    border-color: currentColor
  }

  .xl\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .xl\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-grey-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .xl\:focus\:border-grey-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .xl\:focus\:border-grey-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .xl\:focus\:border-grey-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .xl\:focus\:border-grey-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .xl\:focus\:border-grey-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:focus\:border-grey-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .xl\:focus\:border-grey-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:focus\:border-grey-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .xl\:focus\:border-grey-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .xl\:focus\:border-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:focus\:border-grey-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .xl\:focus\:border-grey-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .xl\:focus\:border-grey-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .xl\:focus\:border-grey-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray-hover:focus {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .xl\:focus\:border-gray-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-orange-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .xl\:focus\:border-orange-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .xl\:focus\:border-orange-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .xl\:focus\:border-orange-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .xl\:focus\:border-orange-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .xl\:focus\:border-orange-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-orange-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-orange-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-orange-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-orange-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-orange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-orange-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .xl\:focus\:border-orange-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .xl\:focus\:border-orange-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-orange-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-orange-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-orange-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-orange-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-orange-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-orange-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-orange-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-orange-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-orange-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-orange-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-orange-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-orange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-orange-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-orange-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-orange-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-orange-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .xl\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .xl\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .xl\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .xl\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .xl\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .xl\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .xl\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .xl\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .xl\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .xl\:focus\:border-yellow:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .xl\:focus\:border-yellow-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .xl\:focus\:border-yellow-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-yellow-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-yellow-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-green-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-green-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-green-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-green-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .xl\:focus\:border-teal-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .xl\:focus\:border-teal-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .xl\:focus\:border-teal-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .xl\:focus\:border-teal-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .xl\:focus\:border-teal-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .xl\:focus\:border-teal-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .xl\:focus\:border-teal-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .xl\:focus\:border-teal-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .xl\:focus\:border-teal-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .xl\:focus\:border-teal-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .xl\:focus\:border-teal:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .xl\:focus\:border-teal-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .xl\:focus\:border-teal-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .xl\:focus\:border-teal-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .xl\:focus\:border-teal-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-blue-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-blue-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-blue-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-light-blue-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .xl\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .xl\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .xl\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .xl\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .xl\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .xl\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .xl\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .xl\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .xl\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .xl\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .xl\:focus\:border-indigo:focus {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .xl\:focus\:border-indigo-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-indigo-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .xl\:focus\:border-indigo-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .xl\:focus\:border-indigo-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .xl\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .xl\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .xl\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .xl\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .xl\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .xl\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .xl\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .xl\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .xl\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .xl\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .xl\:focus\:border-purple:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .xl\:focus\:border-purple-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .xl\:focus\:border-purple-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .xl\:focus\:border-purple-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .xl\:focus\:border-purple-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-purple:focus {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-purple-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-purple-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-purple-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-deep-purple-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .xl\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .xl\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .xl\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .xl\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .xl\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .xl\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .xl\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .xl\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .xl\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .xl\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .xl\:focus\:border-pink:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .xl\:focus\:border-pink-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .xl\:focus\:border-pink-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .xl\:focus\:border-pink-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .xl\:focus\:border-pink-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .xl\:focus\:border-lime-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .xl\:focus\:border-lime-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .xl\:focus\:border-lime-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .xl\:focus\:border-lime-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .xl\:focus\:border-lime-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .xl\:focus\:border-lime-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .xl\:focus\:border-lime-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .xl\:focus\:border-lime-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .xl\:focus\:border-lime-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .xl\:focus\:border-lime-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .xl\:focus\:border-lime:focus {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .xl\:focus\:border-lime-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .xl\:focus\:border-lime-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .xl\:focus\:border-lime-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-lime-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-amber-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .xl\:focus\:border-amber-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .xl\:focus\:border-amber-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .xl\:focus\:border-amber-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .xl\:focus\:border-amber-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .xl\:focus\:border-amber-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .xl\:focus\:border-amber-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-amber-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-amber-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-amber-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-amber:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .xl\:focus\:border-amber-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .xl\:focus\:border-amber-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .xl\:focus\:border-amber-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-amber-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-brown-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .xl\:focus\:border-brown-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .xl\:focus\:border-brown-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .xl\:focus\:border-brown-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .xl\:focus\:border-brown-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .xl\:focus\:border-brown-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .xl\:focus\:border-brown-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .xl\:focus\:border-brown-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .xl\:focus\:border-brown-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .xl\:focus\:border-brown-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .xl\:focus\:border-brown:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .xl\:focus\:border-brown-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .xl\:focus\:border-brown-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .xl\:focus\:border-brown-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .xl\:focus\:border-brown-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-gray-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-gray-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-gray-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .xl\:focus\:border-blue-gray-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .xl\:border-opacity-0 {
    --tw-border-opacity: 0
  }

  .xl\:border-opacity-5 {
    --tw-border-opacity: 0.05
  }

  .xl\:border-opacity-10 {
    --tw-border-opacity: 0.1
  }

  .xl\:border-opacity-20 {
    --tw-border-opacity: 0.2
  }

  .xl\:border-opacity-25 {
    --tw-border-opacity: 0.25
  }

  .xl\:border-opacity-30 {
    --tw-border-opacity: 0.3
  }

  .xl\:border-opacity-40 {
    --tw-border-opacity: 0.4
  }

  .xl\:border-opacity-50 {
    --tw-border-opacity: 0.5
  }

  .xl\:border-opacity-60 {
    --tw-border-opacity: 0.6
  }

  .xl\:border-opacity-70 {
    --tw-border-opacity: 0.7
  }

  .xl\:border-opacity-75 {
    --tw-border-opacity: 0.75
  }

  .xl\:border-opacity-80 {
    --tw-border-opacity: 0.8
  }

  .xl\:border-opacity-90 {
    --tw-border-opacity: 0.9
  }

  .xl\:border-opacity-95 {
    --tw-border-opacity: 0.95
  }

  .xl\:border-opacity-100 {
    --tw-border-opacity: 1
  }

  .group:hover .xl\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0
  }

  .group:hover .xl\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05
  }

  .group:hover .xl\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1
  }

  .group:hover .xl\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2
  }

  .group:hover .xl\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25
  }

  .group:hover .xl\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3
  }

  .group:hover .xl\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4
  }

  .group:hover .xl\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5
  }

  .group:hover .xl\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6
  }

  .group:hover .xl\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7
  }

  .group:hover .xl\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75
  }

  .group:hover .xl\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8
  }

  .group:hover .xl\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9
  }

  .group:hover .xl\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95
  }

  .group:hover .xl\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1
  }

  .xl\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0
  }

  .xl\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05
  }

  .xl\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1
  }

  .xl\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2
  }

  .xl\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25
  }

  .xl\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3
  }

  .xl\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4
  }

  .xl\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5
  }

  .xl\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6
  }

  .xl\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7
  }

  .xl\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75
  }

  .xl\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8
  }

  .xl\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9
  }

  .xl\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95
  }

  .xl\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1
  }

  .xl\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0
  }

  .xl\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05
  }

  .xl\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1
  }

  .xl\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2
  }

  .xl\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25
  }

  .xl\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3
  }

  .xl\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4
  }

  .xl\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5
  }

  .xl\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6
  }

  .xl\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7
  }

  .xl\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75
  }

  .xl\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8
  }

  .xl\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9
  }

  .xl\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95
  }

  .xl\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1
  }

  .xl\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0
  }

  .xl\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05
  }

  .xl\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1
  }

  .xl\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2
  }

  .xl\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25
  }

  .xl\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3
  }

  .xl\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4
  }

  .xl\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5
  }

  .xl\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6
  }

  .xl\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7
  }

  .xl\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75
  }

  .xl\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8
  }

  .xl\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9
  }

  .xl\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95
  }

  .xl\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1
  }

  .xl\:rounded-2 {
    border-radius: .2rem
  }

  .xl\:rounded-4 {
    border-radius: .4rem
  }

  .xl\:rounded-6 {
    border-radius: .6rem
  }

  .xl\:rounded-8 {
    border-radius: .8rem
  }

  .xl\:rounded-12 {
    border-radius: 1.2rem
  }

  .xl\:rounded-16 {
    border-radius: 1.6rem
  }

  .xl\:rounded-20 {
    border-radius: 2rem
  }

  .xl\:rounded-24 {
    border-radius: 2.4rem
  }

  .xl\:rounded-28 {
    border-radius: 2.8rem
  }

  .xl\:rounded-32 {
    border-radius: 3.2rem
  }

  .xl\:rounded-none {
    border-radius: 0
  }

  .xl\:rounded-sm {
    border-radius: .2rem
  }

  .xl\:rounded {
    border-radius: .4rem
  }

  .xl\:rounded-md {
    border-radius: .6rem
  }

  .xl\:rounded-lg {
    border-radius: .8rem
  }

  .xl\:rounded-xl {
    border-radius: 1.2rem
  }

  .xl\:rounded-2xl {
    border-radius: 1.6rem
  }

  .xl\:rounded-3xl {
    border-radius: 2.4rem
  }

  .xl\:rounded-full {
    border-radius: 9999px
  }

  .xl\:rounded-t-2 {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem
  }

  .xl\:rounded-r-2 {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
  }

  .xl\:rounded-b-2 {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .xl\:rounded-l-2 {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .xl\:rounded-t-4 {
    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem
  }

  .xl\:rounded-r-4 {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem
  }

  .xl\:rounded-b-4 {
    border-bottom-right-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .xl\:rounded-l-4 {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .xl\:rounded-t-6 {
    border-top-left-radius: .6rem;
    border-top-right-radius: .6rem
  }

  .xl\:rounded-r-6 {
    border-top-right-radius: .6rem;
    border-bottom-right-radius: .6rem
  }

  .xl\:rounded-b-6 {
    border-bottom-right-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .xl\:rounded-l-6 {
    border-top-left-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .xl\:rounded-t-8 {
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem
  }

  .xl\:rounded-r-8 {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem
  }

  .xl\:rounded-b-8 {
    border-bottom-right-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .xl\:rounded-l-8 {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .xl\:rounded-t-12 {
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem
  }

  .xl\:rounded-r-12 {
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem
  }

  .xl\:rounded-b-12 {
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .xl\:rounded-l-12 {
    border-top-left-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .xl\:rounded-t-16 {
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem
  }

  .xl\:rounded-r-16 {
    border-top-right-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem
  }

  .xl\:rounded-b-16 {
    border-bottom-right-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .xl\:rounded-l-16 {
    border-top-left-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .xl\:rounded-t-20 {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem
  }

  .xl\:rounded-r-20 {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem
  }

  .xl\:rounded-b-20 {
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem
  }

  .xl\:rounded-l-20 {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem
  }

  .xl\:rounded-t-24 {
    border-top-left-radius: 2.4rem;
    border-top-right-radius: 2.4rem
  }

  .xl\:rounded-r-24 {
    border-top-right-radius: 2.4rem;
    border-bottom-right-radius: 2.4rem
  }

  .xl\:rounded-b-24 {
    border-bottom-right-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .xl\:rounded-l-24 {
    border-top-left-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .xl\:rounded-t-28 {
    border-top-left-radius: 2.8rem;
    border-top-right-radius: 2.8rem
  }

  .xl\:rounded-r-28 {
    border-top-right-radius: 2.8rem;
    border-bottom-right-radius: 2.8rem
  }

  .xl\:rounded-b-28 {
    border-bottom-right-radius: 2.8rem;
    border-bottom-left-radius: 2.8rem
  }

  .xl\:rounded-l-28 {
    border-top-left-radius: 2.8rem;
    border-bottom-left-radius: 2.8rem
  }

  .xl\:rounded-t-32 {
    border-top-left-radius: 3.2rem;
    border-top-right-radius: 3.2rem
  }

  .xl\:rounded-r-32 {
    border-top-right-radius: 3.2rem;
    border-bottom-right-radius: 3.2rem
  }

  .xl\:rounded-b-32 {
    border-bottom-right-radius: 3.2rem;
    border-bottom-left-radius: 3.2rem
  }

  .xl\:rounded-l-32 {
    border-top-left-radius: 3.2rem;
    border-bottom-left-radius: 3.2rem
  }

  .xl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .xl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .xl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .xl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .xl\:rounded-t-sm {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem
  }

  .xl\:rounded-r-sm {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
  }

  .xl\:rounded-b-sm {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .xl\:rounded-l-sm {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .xl\:rounded-t {
    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem
  }

  .xl\:rounded-r {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem
  }

  .xl\:rounded-b {
    border-bottom-right-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .xl\:rounded-l {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .xl\:rounded-t-md {
    border-top-left-radius: .6rem;
    border-top-right-radius: .6rem
  }

  .xl\:rounded-r-md {
    border-top-right-radius: .6rem;
    border-bottom-right-radius: .6rem
  }

  .xl\:rounded-b-md {
    border-bottom-right-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .xl\:rounded-l-md {
    border-top-left-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .xl\:rounded-t-lg {
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem
  }

  .xl\:rounded-r-lg {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem
  }

  .xl\:rounded-b-lg {
    border-bottom-right-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .xl\:rounded-l-lg {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .xl\:rounded-t-xl {
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem
  }

  .xl\:rounded-r-xl {
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem
  }

  .xl\:rounded-b-xl {
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .xl\:rounded-l-xl {
    border-top-left-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .xl\:rounded-t-2xl {
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem
  }

  .xl\:rounded-r-2xl {
    border-top-right-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem
  }

  .xl\:rounded-b-2xl {
    border-bottom-right-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .xl\:rounded-l-2xl {
    border-top-left-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .xl\:rounded-t-3xl {
    border-top-left-radius: 2.4rem;
    border-top-right-radius: 2.4rem
  }

  .xl\:rounded-r-3xl {
    border-top-right-radius: 2.4rem;
    border-bottom-right-radius: 2.4rem
  }

  .xl\:rounded-b-3xl {
    border-bottom-right-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .xl\:rounded-l-3xl {
    border-top-left-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .xl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .xl\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .xl\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .xl\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .xl\:rounded-tl-2 {
    border-top-left-radius: .2rem
  }

  .xl\:rounded-tr-2 {
    border-top-right-radius: .2rem
  }

  .xl\:rounded-br-2 {
    border-bottom-right-radius: .2rem
  }

  .xl\:rounded-bl-2 {
    border-bottom-left-radius: .2rem
  }

  .xl\:rounded-tl-4 {
    border-top-left-radius: .4rem
  }

  .xl\:rounded-tr-4 {
    border-top-right-radius: .4rem
  }

  .xl\:rounded-br-4 {
    border-bottom-right-radius: .4rem
  }

  .xl\:rounded-bl-4 {
    border-bottom-left-radius: .4rem
  }

  .xl\:rounded-tl-6 {
    border-top-left-radius: .6rem
  }

  .xl\:rounded-tr-6 {
    border-top-right-radius: .6rem
  }

  .xl\:rounded-br-6 {
    border-bottom-right-radius: .6rem
  }

  .xl\:rounded-bl-6 {
    border-bottom-left-radius: .6rem
  }

  .xl\:rounded-tl-8 {
    border-top-left-radius: .8rem
  }

  .xl\:rounded-tr-8 {
    border-top-right-radius: .8rem
  }

  .xl\:rounded-br-8 {
    border-bottom-right-radius: .8rem
  }

  .xl\:rounded-bl-8 {
    border-bottom-left-radius: .8rem
  }

  .xl\:rounded-tl-12 {
    border-top-left-radius: 1.2rem
  }

  .xl\:rounded-tr-12 {
    border-top-right-radius: 1.2rem
  }

  .xl\:rounded-br-12 {
    border-bottom-right-radius: 1.2rem
  }

  .xl\:rounded-bl-12 {
    border-bottom-left-radius: 1.2rem
  }

  .xl\:rounded-tl-16 {
    border-top-left-radius: 1.6rem
  }

  .xl\:rounded-tr-16 {
    border-top-right-radius: 1.6rem
  }

  .xl\:rounded-br-16 {
    border-bottom-right-radius: 1.6rem
  }

  .xl\:rounded-bl-16 {
    border-bottom-left-radius: 1.6rem
  }

  .xl\:rounded-tl-20 {
    border-top-left-radius: 2rem
  }

  .xl\:rounded-tr-20 {
    border-top-right-radius: 2rem
  }

  .xl\:rounded-br-20 {
    border-bottom-right-radius: 2rem
  }

  .xl\:rounded-bl-20 {
    border-bottom-left-radius: 2rem
  }

  .xl\:rounded-tl-24 {
    border-top-left-radius: 2.4rem
  }

  .xl\:rounded-tr-24 {
    border-top-right-radius: 2.4rem
  }

  .xl\:rounded-br-24 {
    border-bottom-right-radius: 2.4rem
  }

  .xl\:rounded-bl-24 {
    border-bottom-left-radius: 2.4rem
  }

  .xl\:rounded-tl-28 {
    border-top-left-radius: 2.8rem
  }

  .xl\:rounded-tr-28 {
    border-top-right-radius: 2.8rem
  }

  .xl\:rounded-br-28 {
    border-bottom-right-radius: 2.8rem
  }

  .xl\:rounded-bl-28 {
    border-bottom-left-radius: 2.8rem
  }

  .xl\:rounded-tl-32 {
    border-top-left-radius: 3.2rem
  }

  .xl\:rounded-tr-32 {
    border-top-right-radius: 3.2rem
  }

  .xl\:rounded-br-32 {
    border-bottom-right-radius: 3.2rem
  }

  .xl\:rounded-bl-32 {
    border-bottom-left-radius: 3.2rem
  }

  .xl\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .xl\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .xl\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .xl\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .xl\:rounded-tl-sm {
    border-top-left-radius: .2rem
  }

  .xl\:rounded-tr-sm {
    border-top-right-radius: .2rem
  }

  .xl\:rounded-br-sm {
    border-bottom-right-radius: .2rem
  }

  .xl\:rounded-bl-sm {
    border-bottom-left-radius: .2rem
  }

  .xl\:rounded-tl {
    border-top-left-radius: .4rem
  }

  .xl\:rounded-tr {
    border-top-right-radius: .4rem
  }

  .xl\:rounded-br {
    border-bottom-right-radius: .4rem
  }

  .xl\:rounded-bl {
    border-bottom-left-radius: .4rem
  }

  .xl\:rounded-tl-md {
    border-top-left-radius: .6rem
  }

  .xl\:rounded-tr-md {
    border-top-right-radius: .6rem
  }

  .xl\:rounded-br-md {
    border-bottom-right-radius: .6rem
  }

  .xl\:rounded-bl-md {
    border-bottom-left-radius: .6rem
  }

  .xl\:rounded-tl-lg {
    border-top-left-radius: .8rem
  }

  .xl\:rounded-tr-lg {
    border-top-right-radius: .8rem
  }

  .xl\:rounded-br-lg {
    border-bottom-right-radius: .8rem
  }

  .xl\:rounded-bl-lg {
    border-bottom-left-radius: .8rem
  }

  .xl\:rounded-tl-xl {
    border-top-left-radius: 1.2rem
  }

  .xl\:rounded-tr-xl {
    border-top-right-radius: 1.2rem
  }

  .xl\:rounded-br-xl {
    border-bottom-right-radius: 1.2rem
  }

  .xl\:rounded-bl-xl {
    border-bottom-left-radius: 1.2rem
  }

  .xl\:rounded-tl-2xl {
    border-top-left-radius: 1.6rem
  }

  .xl\:rounded-tr-2xl {
    border-top-right-radius: 1.6rem
  }

  .xl\:rounded-br-2xl {
    border-bottom-right-radius: 1.6rem
  }

  .xl\:rounded-bl-2xl {
    border-bottom-left-radius: 1.6rem
  }

  .xl\:rounded-tl-3xl {
    border-top-left-radius: 2.4rem
  }

  .xl\:rounded-tr-3xl {
    border-top-right-radius: 2.4rem
  }

  .xl\:rounded-br-3xl {
    border-bottom-right-radius: 2.4rem
  }

  .xl\:rounded-bl-3xl {
    border-bottom-left-radius: 2.4rem
  }

  .xl\:rounded-tl-full {
    border-top-left-radius: 9999px
  }

  .xl\:rounded-tr-full {
    border-top-right-radius: 9999px
  }

  .xl\:rounded-br-full {
    border-bottom-right-radius: 9999px
  }

  .xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px
  }

  .xl\:border-solid {
    border-style: solid
  }

  .xl\:border-dashed {
    border-style: dashed
  }

  .xl\:border-dotted {
    border-style: dotted
  }

  .xl\:border-double {
    border-style: double
  }

  .xl\:border-none {
    border-style: none
  }

  .xl\:border-0 {
    border-width: 0px
  }

  .xl\:border-1 {
    border-width: 1px
  }

  .xl\:border-2 {
    border-width: 2px
  }

  .xl\:border-3 {
    border-width: 3px
  }

  .xl\:border-4 {
    border-width: 4px
  }

  .xl\:border-8 {
    border-width: 8px
  }

  .xl\:border {
    border-width: 1px
  }

  .xl\:border-t-0 {
    border-top-width: 0px
  }

  .xl\:border-r-0 {
    border-right-width: 0px
  }

  .xl\:border-b-0 {
    border-bottom-width: 0px
  }

  .xl\:border-l-0 {
    border-left-width: 0px
  }

  .xl\:border-t-1 {
    border-top-width: 1px
  }

  .xl\:border-r-1 {
    border-right-width: 1px
  }

  .xl\:border-b-1 {
    border-bottom-width: 1px
  }

  .xl\:border-l-1 {
    border-left-width: 1px
  }

  .xl\:border-t-2 {
    border-top-width: 2px
  }

  .xl\:border-r-2 {
    border-right-width: 2px
  }

  .xl\:border-b-2 {
    border-bottom-width: 2px
  }

  .xl\:border-l-2 {
    border-left-width: 2px
  }

  .xl\:border-t-3 {
    border-top-width: 3px
  }

  .xl\:border-r-3 {
    border-right-width: 3px
  }

  .xl\:border-b-3 {
    border-bottom-width: 3px
  }

  .xl\:border-l-3 {
    border-left-width: 3px
  }

  .xl\:border-t-4 {
    border-top-width: 4px
  }

  .xl\:border-r-4 {
    border-right-width: 4px
  }

  .xl\:border-b-4 {
    border-bottom-width: 4px
  }

  .xl\:border-l-4 {
    border-left-width: 4px
  }

  .xl\:border-t-8 {
    border-top-width: 8px
  }

  .xl\:border-r-8 {
    border-right-width: 8px
  }

  .xl\:border-b-8 {
    border-bottom-width: 8px
  }

  .xl\:border-l-8 {
    border-left-width: 8px
  }

  .xl\:border-t {
    border-top-width: 1px
  }

  .xl\:border-r {
    border-right-width: 1px
  }

  .xl\:border-b {
    border-bottom-width: 1px
  }

  .xl\:border-l {
    border-left-width: 1px
  }

  .xl\:box-border {
    box-sizing: border-box
  }

  .xl\:box-content {
    box-sizing: content-box
  }

  .xl\:cursor-auto {
    cursor: auto
  }

  .xl\:cursor-default {
    cursor: default
  }

  .xl\:cursor-pointer {
    cursor: pointer
  }

  .xl\:cursor-wait {
    cursor: wait
  }

  .xl\:cursor-text {
    cursor: text
  }

  .xl\:cursor-move {
    cursor: move
  }

  .xl\:cursor-not-allowed {
    cursor: not-allowed
  }

  .xl\:block {
    display: block
  }

  .xl\:inline-block {
    display: inline-block
  }

  .xl\:inline {
    display: inline
  }

  .xl\:flex {
    display: flex
  }

  .xl\:inline-flex {
    display: inline-flex
  }

  .xl\:table {
    display: table
  }

  .xl\:table-caption {
    display: table-caption
  }

  .xl\:table-cell {
    display: table-cell
  }

  .xl\:table-column {
    display: table-column
  }

  .xl\:table-column-group {
    display: table-column-group
  }

  .xl\:table-footer-group {
    display: table-footer-group
  }

  .xl\:table-header-group {
    display: table-header-group
  }

  .xl\:table-row-group {
    display: table-row-group
  }

  .xl\:table-row {
    display: table-row
  }

  .xl\:flow-root {
    display: flow-root
  }

  .xl\:grid {
    display: grid
  }

  .xl\:inline-grid {
    display: inline-grid
  }

  .xl\:contents {
    display: contents
  }

  .xl\:hidden {
    display: none
  }

  .xl\:hover\:block:hover {
    display: block
  }

  .xl\:hover\:inline-block:hover {
    display: inline-block
  }

  .xl\:hover\:inline:hover {
    display: inline
  }

  .xl\:hover\:flex:hover {
    display: flex
  }

  .xl\:hover\:inline-flex:hover {
    display: inline-flex
  }

  .xl\:hover\:table:hover {
    display: table
  }

  .xl\:hover\:table-caption:hover {
    display: table-caption
  }

  .xl\:hover\:table-cell:hover {
    display: table-cell
  }

  .xl\:hover\:table-column:hover {
    display: table-column
  }

  .xl\:hover\:table-column-group:hover {
    display: table-column-group
  }

  .xl\:hover\:table-footer-group:hover {
    display: table-footer-group
  }

  .xl\:hover\:table-header-group:hover {
    display: table-header-group
  }

  .xl\:hover\:table-row-group:hover {
    display: table-row-group
  }

  .xl\:hover\:table-row:hover {
    display: table-row
  }

  .xl\:hover\:flow-root:hover {
    display: flow-root
  }

  .xl\:hover\:grid:hover {
    display: grid
  }

  .xl\:hover\:inline-grid:hover {
    display: inline-grid
  }

  .xl\:hover\:contents:hover {
    display: contents
  }

  .xl\:hover\:hidden:hover {
    display: none
  }

  .xl\:focus\:block:focus {
    display: block
  }

  .xl\:focus\:inline-block:focus {
    display: inline-block
  }

  .xl\:focus\:inline:focus {
    display: inline
  }

  .xl\:focus\:flex:focus {
    display: flex
  }

  .xl\:focus\:inline-flex:focus {
    display: inline-flex
  }

  .xl\:focus\:table:focus {
    display: table
  }

  .xl\:focus\:table-caption:focus {
    display: table-caption
  }

  .xl\:focus\:table-cell:focus {
    display: table-cell
  }

  .xl\:focus\:table-column:focus {
    display: table-column
  }

  .xl\:focus\:table-column-group:focus {
    display: table-column-group
  }

  .xl\:focus\:table-footer-group:focus {
    display: table-footer-group
  }

  .xl\:focus\:table-header-group:focus {
    display: table-header-group
  }

  .xl\:focus\:table-row-group:focus {
    display: table-row-group
  }

  .xl\:focus\:table-row:focus {
    display: table-row
  }

  .xl\:focus\:flow-root:focus {
    display: flow-root
  }

  .xl\:focus\:grid:focus {
    display: grid
  }

  .xl\:focus\:inline-grid:focus {
    display: inline-grid
  }

  .xl\:focus\:contents:focus {
    display: contents
  }

  .xl\:focus\:hidden:focus {
    display: none
  }

  .xl\:flex-row {
    flex-direction: row
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .xl\:flex-col {
    flex-direction: column
  }

  .xl\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .xl\:flex-wrap {
    flex-wrap: wrap
  }

  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .xl\:flex-nowrap {
    flex-wrap: nowrap
  }

  .xl\:place-items-auto {
    place-items: auto
  }

  .xl\:place-items-start {
    place-items: start
  }

  .xl\:place-items-end {
    place-items: end
  }

  .xl\:place-items-center {
    place-items: center
  }

  .xl\:place-items-stretch {
    place-items: stretch
  }

  .xl\:place-content-center {
    place-content: center
  }

  .xl\:place-content-start {
    place-content: start
  }

  .xl\:place-content-end {
    place-content: end
  }

  .xl\:place-content-between {
    place-content: space-between
  }

  .xl\:place-content-around {
    place-content: space-around
  }

  .xl\:place-content-evenly {
    place-content: space-evenly
  }

  .xl\:place-content-stretch {
    place-content: stretch
  }

  .xl\:place-self-auto {
    place-self: auto
  }

  .xl\:place-self-start {
    place-self: start
  }

  .xl\:place-self-end {
    place-self: end
  }

  .xl\:place-self-center {
    place-self: center
  }

  .xl\:place-self-stretch {
    place-self: stretch
  }

  .xl\:items-start {
    align-items: flex-start
  }

  .xl\:items-end {
    align-items: flex-end
  }

  .xl\:items-center {
    align-items: center
  }

  .xl\:items-baseline {
    align-items: baseline
  }

  .xl\:items-stretch {
    align-items: stretch
  }

  .xl\:content-center {
    align-content: center
  }

  .xl\:content-start {
    align-content: flex-start
  }

  .xl\:content-end {
    align-content: flex-end
  }

  .xl\:content-between {
    align-content: space-between
  }

  .xl\:content-around {
    align-content: space-around
  }

  .xl\:content-evenly {
    align-content: space-evenly
  }

  .xl\:self-auto {
    align-self: auto
  }

  .xl\:self-start {
    align-self: flex-start
  }

  .xl\:self-end {
    align-self: flex-end
  }

  .xl\:self-center {
    align-self: center
  }

  .xl\:self-stretch {
    align-self: stretch
  }

  .xl\:justify-items-auto {
    justify-items: auto
  }

  .xl\:justify-items-start {
    justify-items: start
  }

  .xl\:justify-items-end {
    justify-items: end
  }

  .xl\:justify-items-center {
    justify-items: center
  }

  .xl\:justify-items-stretch {
    justify-items: stretch
  }

  .xl\:justify-start {
    justify-content: flex-start
  }

  .xl\:justify-end {
    justify-content: flex-end
  }

  .xl\:justify-center {
    justify-content: center
  }

  .xl\:justify-between {
    justify-content: space-between
  }

  .xl\:justify-around {
    justify-content: space-around
  }

  .xl\:justify-evenly {
    justify-content: space-evenly
  }

  .xl\:justify-self-auto {
    justify-self: auto
  }

  .xl\:justify-self-start {
    justify-self: start
  }

  .xl\:justify-self-end {
    justify-self: end
  }

  .xl\:justify-self-center {
    justify-self: center
  }

  .xl\:justify-self-stretch {
    justify-self: stretch
  }

  .xl\:flex-1 {
    flex: 1 1 0%
  }

  .xl\:flex-auto {
    flex: 1 1 auto
  }

  .xl\:flex-initial {
    flex: 0 1 auto
  }

  .xl\:flex-none {
    flex: none
  }

  .xl\:flex-grow-0 {
    flex-grow: 0
  }

  .xl\:flex-grow {
    flex-grow: 1
  }

  .xl\:flex-shrink-0 {
    flex-shrink: 0
  }

  .xl\:flex-shrink {
    flex-shrink: 1
  }

  .xl\:order-1 {
    order: 1
  }

  .xl\:order-2 {
    order: 2
  }

  .xl\:order-3 {
    order: 3
  }

  .xl\:order-4 {
    order: 4
  }

  .xl\:order-5 {
    order: 5
  }

  .xl\:order-6 {
    order: 6
  }

  .xl\:order-7 {
    order: 7
  }

  .xl\:order-8 {
    order: 8
  }

  .xl\:order-9 {
    order: 9
  }

  .xl\:order-10 {
    order: 10
  }

  .xl\:order-11 {
    order: 11
  }

  .xl\:order-12 {
    order: 12
  }

  .xl\:order-first {
    order: -9999
  }

  .xl\:order-last {
    order: 9999
  }

  .xl\:order-none {
    order: 0
  }

  .xl\:float-right {
    float: right
  }

  .xl\:float-left {
    float: left
  }

  .xl\:float-none {
    float: none
  }

  [dir='ltr'] .xl\:ltr\:float-right,[dir='ltr'].xl\:ltr\:float-right {
    float: right
  }

  [dir='ltr'] .xl\:ltr\:float-left,[dir='ltr'].xl\:ltr\:float-left {
    float: left
  }

  [dir='ltr'] .xl\:ltr\:float-none,[dir='ltr'].xl\:ltr\:float-none {
    float: none
  }

  [dir='rtl'] .xl\:rtl\:float-right,[dir='rtl'].xl\:rtl\:float-right {
    float: right
  }

  [dir='rtl'] .xl\:rtl\:float-left,[dir='rtl'].xl\:rtl\:float-left {
    float: left
  }

  [dir='rtl'] .xl\:rtl\:float-none,[dir='rtl'].xl\:rtl\:float-none {
    float: none
  }

  .xl\:clear-left {
    clear: left
  }

  .xl\:clear-right {
    clear: right
  }

  .xl\:clear-both {
    clear: both
  }

  .xl\:clear-none {
    clear: none
  }

  .xl\:font-sans {
    font-family: Muli, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .xl\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .xl\:font-mono {
    font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace
  }

  .xl\:font-100 {
    font-weight: 100
  }

  .xl\:font-200 {
    font-weight: 200
  }

  .xl\:font-300 {
    font-weight: 300
  }

  .xl\:font-400 {
    font-weight: 400
  }

  .xl\:font-500 {
    font-weight: 500
  }

  .xl\:font-600 {
    font-weight: 600
  }

  .xl\:font-700 {
    font-weight: 700
  }

  .xl\:font-800 {
    font-weight: 800
  }

  .xl\:font-900 {
    font-weight: 900
  }

  .xl\:font-thin {
    font-weight: 100
  }

  .xl\:font-extralight {
    font-weight: 200
  }

  .xl\:font-light {
    font-weight: 300
  }

  .xl\:font-normal {
    font-weight: 400
  }

  .xl\:font-medium {
    font-weight: 500
  }

  .xl\:font-semibold {
    font-weight: 600
  }

  .xl\:font-bold {
    font-weight: 700
  }

  .xl\:font-extrabold {
    font-weight: 800
  }

  .xl\:font-black {
    font-weight: 900
  }

  .xl\:hover\:font-100:hover {
    font-weight: 100
  }

  .xl\:hover\:font-200:hover {
    font-weight: 200
  }

  .xl\:hover\:font-300:hover {
    font-weight: 300
  }

  .xl\:hover\:font-400:hover {
    font-weight: 400
  }

  .xl\:hover\:font-500:hover {
    font-weight: 500
  }

  .xl\:hover\:font-600:hover {
    font-weight: 600
  }

  .xl\:hover\:font-700:hover {
    font-weight: 700
  }

  .xl\:hover\:font-800:hover {
    font-weight: 800
  }

  .xl\:hover\:font-900:hover {
    font-weight: 900
  }

  .xl\:hover\:font-thin:hover {
    font-weight: 100
  }

  .xl\:hover\:font-extralight:hover {
    font-weight: 200
  }

  .xl\:hover\:font-light:hover {
    font-weight: 300
  }

  .xl\:hover\:font-normal:hover {
    font-weight: 400
  }

  .xl\:hover\:font-medium:hover {
    font-weight: 500
  }

  .xl\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .xl\:hover\:font-bold:hover {
    font-weight: 700
  }

  .xl\:hover\:font-extrabold:hover {
    font-weight: 800
  }

  .xl\:hover\:font-black:hover {
    font-weight: 900
  }

  .xl\:focus\:font-100:focus {
    font-weight: 100
  }

  .xl\:focus\:font-200:focus {
    font-weight: 200
  }

  .xl\:focus\:font-300:focus {
    font-weight: 300
  }

  .xl\:focus\:font-400:focus {
    font-weight: 400
  }

  .xl\:focus\:font-500:focus {
    font-weight: 500
  }

  .xl\:focus\:font-600:focus {
    font-weight: 600
  }

  .xl\:focus\:font-700:focus {
    font-weight: 700
  }

  .xl\:focus\:font-800:focus {
    font-weight: 800
  }

  .xl\:focus\:font-900:focus {
    font-weight: 900
  }

  .xl\:focus\:font-thin:focus {
    font-weight: 100
  }

  .xl\:focus\:font-extralight:focus {
    font-weight: 200
  }

  .xl\:focus\:font-light:focus {
    font-weight: 300
  }

  .xl\:focus\:font-normal:focus {
    font-weight: 400
  }

  .xl\:focus\:font-medium:focus {
    font-weight: 500
  }

  .xl\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .xl\:focus\:font-bold:focus {
    font-weight: 700
  }

  .xl\:focus\:font-extrabold:focus {
    font-weight: 800
  }

  .xl\:focus\:font-black:focus {
    font-weight: 900
  }

  .xl\:h-0 {
    height: 0
  }

  .xl\:h-1 {
    height: 0.1rem
  }

  .xl\:h-2 {
    height: 0.2rem
  }

  .xl\:h-3 {
    height: 0.3rem
  }

  .xl\:h-4 {
    height: 0.4rem
  }

  .xl\:h-5 {
    height: 0.5rem
  }

  .xl\:h-6 {
    height: 0.6rem
  }

  .xl\:h-7 {
    height: 0.7rem
  }

  .xl\:h-8 {
    height: 0.8rem
  }

  .xl\:h-9 {
    height: 0.9rem
  }

  .xl\:h-10 {
    height: 1.0rem
  }

  .xl\:h-12 {
    height: 1.2rem
  }

  .xl\:h-14 {
    height: 1.4rem
  }

  .xl\:h-16 {
    height: 1.6rem
  }

  .xl\:h-20 {
    height: 2rem
  }

  .xl\:h-24 {
    height: 2.4rem
  }

  .xl\:h-28 {
    height: 2.8rem
  }

  .xl\:h-32 {
    height: 3.2rem
  }

  .xl\:h-36 {
    height: 3.6rem
  }

  .xl\:h-40 {
    height: 4rem
  }

  .xl\:h-44 {
    height: 4.4rem
  }

  .xl\:h-48 {
    height: 4.8rem
  }

  .xl\:h-52 {
    height: 5.2rem
  }

  .xl\:h-56 {
    height: 5.6rem
  }

  .xl\:h-60 {
    height: 6rem
  }

  .xl\:h-64 {
    height: 6.4rem
  }

  .xl\:h-68 {
    height: 6.8rem
  }

  .xl\:h-72 {
    height: 7.2rem
  }

  .xl\:h-76 {
    height: 7.6rem
  }

  .xl\:h-80 {
    height: 8rem
  }

  .xl\:h-84 {
    height: 8.4rem
  }

  .xl\:h-88 {
    height: 8.8rem
  }

  .xl\:h-92 {
    height: 9.2rem
  }

  .xl\:h-96 {
    height: 9.6rem
  }

  .xl\:h-128 {
    height: 12.8rem
  }

  .xl\:h-136 {
    height: 13.6rem
  }

  .xl\:h-160 {
    height: 16rem
  }

  .xl\:h-192 {
    height: 19.2rem
  }

  .xl\:h-200 {
    height: 20rem
  }

  .xl\:h-208 {
    height: 20.8rem
  }

  .xl\:h-216 {
    height: 21.6rem
  }

  .xl\:h-224 {
    height: 22.4rem
  }

  .xl\:h-256 {
    height: 25.6rem
  }

  .xl\:h-288 {
    height: 28.8rem
  }

  .xl\:h-320 {
    height: 32rem
  }

  .xl\:h-360 {
    height: 36rem
  }

  .xl\:h-384 {
    height: 38.4rem
  }

  .xl\:h-400 {
    height: 40rem
  }

  .xl\:h-512 {
    height: 51.2rem
  }

  .xl\:h-640 {
    height: 64rem
  }

  .xl\:h-auto {
    height: auto
  }

  .xl\:h-xs {
    height: 32rem
  }

  .xl\:h-sm {
    height: 48rem
  }

  .xl\:h-md {
    height: 64rem
  }

  .xl\:h-lg {
    height: 80rem
  }

  .xl\:h-xl {
    height: 96rem
  }

  .xl\:h-2xl {
    height: 112rem
  }

  .xl\:h-3xl {
    height: 128rem
  }

  .xl\:h-4xl {
    height: 144rem
  }

  .xl\:h-5xl {
    height: 160rem
  }

  .xl\:h-px {
    height: 1px
  }

  .xl\:h-0\.5 {
    height: 0.05rem
  }

  .xl\:h-1\.5 {
    height: 0.15rem
  }

  .xl\:h-2\.5 {
    height: 0.25rem
  }

  .xl\:h-3\.5 {
    height: 0.35rem
  }

  .xl\:h-1\/2 {
    height: 50%
  }

  .xl\:h-1\/3 {
    height: 33.333333%
  }

  .xl\:h-2\/3 {
    height: 66.666667%
  }

  .xl\:h-1\/4 {
    height: 25%
  }

  .xl\:h-2\/4 {
    height: 50%
  }

  .xl\:h-3\/4 {
    height: 75%
  }

  .xl\:h-1\/5 {
    height: 20%
  }

  .xl\:h-2\/5 {
    height: 40%
  }

  .xl\:h-3\/5 {
    height: 60%
  }

  .xl\:h-4\/5 {
    height: 80%
  }

  .xl\:h-1\/6 {
    height: 16.666667%
  }

  .xl\:h-2\/6 {
    height: 33.333333%
  }

  .xl\:h-3\/6 {
    height: 50%
  }

  .xl\:h-4\/6 {
    height: 66.666667%
  }

  .xl\:h-5\/6 {
    height: 83.333333%
  }

  .xl\:h-full {
    height: 100%
  }

  .xl\:h-screen {
    height: 100vh
  }

  .xl\:text-10 {
    font-size: 1rem
  }

  .xl\:text-11 {
    font-size: 1.1rem
  }

  .xl\:text-12 {
    font-size: 1.2rem
  }

  .xl\:text-13 {
    font-size: 1.3rem
  }

  .xl\:text-14 {
    font-size: 1.4rem
  }

  .xl\:text-15 {
    font-size: 1.5rem
  }

  .xl\:text-16 {
    font-size: 1.6rem
  }

  .xl\:text-17 {
    font-size: 1.7rem
  }

  .xl\:text-18 {
    font-size: 1.8rem
  }

  .xl\:text-19 {
    font-size: 1.9rem
  }

  .xl\:text-20 {
    font-size: 2rem
  }

  .xl\:text-24 {
    font-size: 2.4rem
  }

  .xl\:text-28 {
    font-size: 2.8rem
  }

  .xl\:text-32 {
    font-size: 3.2rem
  }

  .xl\:text-36 {
    font-size: 3.6rem
  }

  .xl\:text-40 {
    font-size: 4rem
  }

  .xl\:text-44 {
    font-size: 4.4rem
  }

  .xl\:text-48 {
    font-size: 4.8rem
  }

  .xl\:text-52 {
    font-size: 5.2rem
  }

  .xl\:text-56 {
    font-size: 5.6rem
  }

  .xl\:text-60 {
    font-size: 6rem
  }

  .xl\:text-64 {
    font-size: 6.4rem
  }

  .xl\:text-68 {
    font-size: 6.8rem
  }

  .xl\:text-72 {
    font-size: 7.2rem
  }

  .xl\:text-96 {
    font-size: 9.6rem
  }

  .xl\:text-128 {
    font-size: 12.8rem
  }

  .xl\:text-xs {
    font-size: 1.2rem;
    line-height: 1.6rem
  }

  .xl\:text-sm {
    font-size: 1.4rem;
    line-height: 2rem
  }

  .xl\:text-base {
    font-size: 1.6rem;
    line-height: 2.4rem
  }

  .xl\:text-lg {
    font-size: 1.8rem;
    line-height: 2.8em
  }

  .xl\:text-xl {
    font-size: 2rem;
    line-height: 2.8rem
  }

  .xl\:text-2xl {
    font-size: 2.4rem;
    line-height: 3.2rem
  }

  .xl\:text-3xl {
    font-size: 3rem;
    line-height: 3.6rem
  }

  .xl\:text-4xl {
    font-size: 3.6rem;
    line-height: 4rem
  }

  .xl\:text-5xl {
    font-size: 4.8rem;
    line-height: 1
  }

  .xl\:text-6xl {
    font-size: 6rem;
    line-height: 1
  }

  .xl\:text-7xl {
    font-size: 7.2rem;
    line-height: 1
  }

  .xl\:text-8xl {
    font-size: 9.6rem;
    line-height: 1
  }

  .xl\:text-9xl {
    font-size: 12.8rem;
    line-height: 1
  }

  .xl\:leading-3 {
    line-height: 1.2rem
  }

  .xl\:leading-4 {
    line-height: 1.6rem
  }

  .xl\:leading-5 {
    line-height: 2rem
  }

  .xl\:leading-6 {
    line-height: 2.4rem
  }

  .xl\:leading-7 {
    line-height: 2.8rem
  }

  .xl\:leading-8 {
    line-height: 3.2rem
  }

  .xl\:leading-9 {
    line-height: 3.6rem
  }

  .xl\:leading-10 {
    line-height: 4rem
  }

  .xl\:leading-none {
    line-height: 1
  }

  .xl\:leading-tight {
    line-height: 1.25
  }

  .xl\:leading-snug {
    line-height: 1.375
  }

  .xl\:leading-normal {
    line-height: 1.5
  }

  .xl\:leading-relaxed {
    line-height: 1.625
  }

  .xl\:leading-loose {
    line-height: 2
  }

  .xl\:list-inside {
    list-style-position: inside
  }

  .xl\:list-outside {
    list-style-position: outside
  }

  .xl\:list-none {
    list-style-type: none
  }

  .xl\:list-disc {
    list-style-type: disc
  }

  .xl\:list-decimal {
    list-style-type: decimal
  }

  .xl\:m-0 {
    margin: 0
  }

  .xl\:m-1 {
    margin: 0.1rem
  }

  .xl\:m-2 {
    margin: 0.2rem
  }

  .xl\:m-3 {
    margin: 0.3rem
  }

  .xl\:m-4 {
    margin: 0.4rem
  }

  .xl\:m-5 {
    margin: 0.5rem
  }

  .xl\:m-6 {
    margin: 0.6rem
  }

  .xl\:m-7 {
    margin: 0.7rem
  }

  .xl\:m-8 {
    margin: 0.8rem
  }

  .xl\:m-9 {
    margin: 0.9rem
  }

  .xl\:m-10 {
    margin: 1.0rem
  }

  .xl\:m-12 {
    margin: 1.2rem
  }

  .xl\:m-14 {
    margin: 1.4rem
  }

  .xl\:m-16 {
    margin: 1.6rem
  }

  .xl\:m-20 {
    margin: 2rem
  }

  .xl\:m-24 {
    margin: 2.4rem
  }

  .xl\:m-28 {
    margin: 2.8rem
  }

  .xl\:m-32 {
    margin: 3.2rem
  }

  .xl\:m-36 {
    margin: 3.6rem
  }

  .xl\:m-40 {
    margin: 4rem
  }

  .xl\:m-44 {
    margin: 4.4rem
  }

  .xl\:m-48 {
    margin: 4.8rem
  }

  .xl\:m-52 {
    margin: 5.2rem
  }

  .xl\:m-56 {
    margin: 5.6rem
  }

  .xl\:m-60 {
    margin: 6rem
  }

  .xl\:m-64 {
    margin: 6.4rem
  }

  .xl\:m-68 {
    margin: 6.8rem
  }

  .xl\:m-72 {
    margin: 7.2rem
  }

  .xl\:m-76 {
    margin: 7.6rem
  }

  .xl\:m-80 {
    margin: 8rem
  }

  .xl\:m-84 {
    margin: 8.4rem
  }

  .xl\:m-88 {
    margin: 8.8rem
  }

  .xl\:m-92 {
    margin: 9.2rem
  }

  .xl\:m-96 {
    margin: 9.6rem
  }

  .xl\:m-128 {
    margin: 12.8rem
  }

  .xl\:m-136 {
    margin: 13.6rem
  }

  .xl\:m-160 {
    margin: 16rem
  }

  .xl\:m-192 {
    margin: 19.2rem
  }

  .xl\:m-200 {
    margin: 20rem
  }

  .xl\:m-208 {
    margin: 20.8rem
  }

  .xl\:m-216 {
    margin: 21.6rem
  }

  .xl\:m-224 {
    margin: 22.4rem
  }

  .xl\:m-256 {
    margin: 25.6rem
  }

  .xl\:m-288 {
    margin: 28.8rem
  }

  .xl\:m-320 {
    margin: 32rem
  }

  .xl\:m-360 {
    margin: 36rem
  }

  .xl\:m-384 {
    margin: 38.4rem
  }

  .xl\:m-400 {
    margin: 40rem
  }

  .xl\:m-512 {
    margin: 51.2rem
  }

  .xl\:m-640 {
    margin: 64rem
  }

  .xl\:m-auto {
    margin: auto
  }

  .xl\:m-xs {
    margin: 32rem
  }

  .xl\:m-sm {
    margin: 48rem
  }

  .xl\:m-md {
    margin: 64rem
  }

  .xl\:m-lg {
    margin: 80rem
  }

  .xl\:m-xl {
    margin: 96rem
  }

  .xl\:m-2xl {
    margin: 112rem
  }

  .xl\:m-3xl {
    margin: 128rem
  }

  .xl\:m-4xl {
    margin: 144rem
  }

  .xl\:m-5xl {
    margin: 160rem
  }

  .xl\:m-px {
    margin: 1px
  }

  .xl\:m-0\.5 {
    margin: 0.05rem
  }

  .xl\:m-1\.5 {
    margin: 0.15rem
  }

  .xl\:m-2\.5 {
    margin: 0.25rem
  }

  .xl\:m-3\.5 {
    margin: 0.35rem
  }

  .xl\:-m-1 {
    margin: -0.1rem
  }

  .xl\:-m-2 {
    margin: -0.2rem
  }

  .xl\:-m-3 {
    margin: -0.3rem
  }

  .xl\:-m-4 {
    margin: -0.4rem
  }

  .xl\:-m-5 {
    margin: -0.5rem
  }

  .xl\:-m-6 {
    margin: -0.6rem
  }

  .xl\:-m-7 {
    margin: -0.7rem
  }

  .xl\:-m-8 {
    margin: -0.8rem
  }

  .xl\:-m-9 {
    margin: -0.9rem
  }

  .xl\:-m-10 {
    margin: -1rem
  }

  .xl\:-m-12 {
    margin: -1.2rem
  }

  .xl\:-m-14 {
    margin: -1.4rem
  }

  .xl\:-m-16 {
    margin: -1.6rem
  }

  .xl\:-m-20 {
    margin: -2rem
  }

  .xl\:-m-24 {
    margin: -2.4rem
  }

  .xl\:-m-28 {
    margin: -2.8rem
  }

  .xl\:-m-32 {
    margin: -3.2rem
  }

  .xl\:-m-36 {
    margin: -3.6rem
  }

  .xl\:-m-40 {
    margin: -4rem
  }

  .xl\:-m-44 {
    margin: -4.4rem
  }

  .xl\:-m-48 {
    margin: -4.8rem
  }

  .xl\:-m-52 {
    margin: -5.2rem
  }

  .xl\:-m-56 {
    margin: -5.6rem
  }

  .xl\:-m-60 {
    margin: -6rem
  }

  .xl\:-m-64 {
    margin: -6.4rem
  }

  .xl\:-m-68 {
    margin: -6.8rem
  }

  .xl\:-m-72 {
    margin: -7.2rem
  }

  .xl\:-m-76 {
    margin: -7.6rem
  }

  .xl\:-m-80 {
    margin: -8rem
  }

  .xl\:-m-84 {
    margin: -8.4rem
  }

  .xl\:-m-88 {
    margin: -8.8rem
  }

  .xl\:-m-92 {
    margin: -9.2rem
  }

  .xl\:-m-96 {
    margin: -9.6rem
  }

  .xl\:-m-128 {
    margin: -12.8rem
  }

  .xl\:-m-136 {
    margin: -13.6rem
  }

  .xl\:-m-160 {
    margin: -16rem
  }

  .xl\:-m-192 {
    margin: -19.2rem
  }

  .xl\:-m-200 {
    margin: -20rem
  }

  .xl\:-m-208 {
    margin: -20.8rem
  }

  .xl\:-m-216 {
    margin: -21.6rem
  }

  .xl\:-m-224 {
    margin: -22.4rem
  }

  .xl\:-m-256 {
    margin: -25.6rem
  }

  .xl\:-m-288 {
    margin: -28.8rem
  }

  .xl\:-m-320 {
    margin: -32rem
  }

  .xl\:-m-360 {
    margin: -36rem
  }

  .xl\:-m-384 {
    margin: -38.4rem
  }

  .xl\:-m-400 {
    margin: -40rem
  }

  .xl\:-m-512 {
    margin: -51.2rem
  }

  .xl\:-m-640 {
    margin: -64rem
  }

  .xl\:-m-xs {
    margin: -32rem
  }

  .xl\:-m-sm {
    margin: -48rem
  }

  .xl\:-m-md {
    margin: -64rem
  }

  .xl\:-m-lg {
    margin: -80rem
  }

  .xl\:-m-xl {
    margin: -96rem
  }

  .xl\:-m-2xl {
    margin: -112rem
  }

  .xl\:-m-3xl {
    margin: -128rem
  }

  .xl\:-m-4xl {
    margin: -144rem
  }

  .xl\:-m-5xl {
    margin: -160rem
  }

  .xl\:-m-px {
    margin: -1px
  }

  .xl\:-m-0\.5 {
    margin: -0.05rem
  }

  .xl\:-m-1\.5 {
    margin: -0.15rem
  }

  .xl\:-m-2\.5 {
    margin: -0.25rem
  }

  .xl\:-m-3\.5 {
    margin: -0.35rem
  }

  .xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .xl\:my-1 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem
  }

  .xl\:mx-1 {
    margin-left: 0.1rem;
    margin-right: 0.1rem
  }

  .xl\:my-2 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem
  }

  .xl\:mx-2 {
    margin-left: 0.2rem;
    margin-right: 0.2rem
  }

  .xl\:my-3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem
  }

  .xl\:mx-3 {
    margin-left: 0.3rem;
    margin-right: 0.3rem
  }

  .xl\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  .xl\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  .xl\:my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .xl\:mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .xl\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  .xl\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  .xl\:my-7 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem
  }

  .xl\:mx-7 {
    margin-left: 0.7rem;
    margin-right: 0.7rem
  }

  .xl\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  .xl\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  .xl\:my-9 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem
  }

  .xl\:mx-9 {
    margin-left: 0.9rem;
    margin-right: 0.9rem
  }

  .xl\:my-10 {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem
  }

  .xl\:mx-10 {
    margin-left: 1.0rem;
    margin-right: 1.0rem
  }

  .xl\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  .xl\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  .xl\:my-14 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem
  }

  .xl\:mx-14 {
    margin-left: 1.4rem;
    margin-right: 1.4rem
  }

  .xl\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  .xl\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  .xl\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .xl\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .xl\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  .xl\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .xl\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  .xl\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  .xl\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  .xl\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  .xl\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  .xl\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  .xl\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .xl\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .xl\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  .xl\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  .xl\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  .xl\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  .xl\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  .xl\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  .xl\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  .xl\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  .xl\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .xl\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .xl\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  .xl\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  .xl\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  .xl\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  .xl\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  .xl\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  .xl\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  .xl\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  .xl\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .xl\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .xl\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  .xl\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  .xl\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  .xl\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  .xl\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  .xl\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  .xl\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  .xl\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  .xl\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  .xl\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  .xl\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  .xl\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  .xl\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .xl\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .xl\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  .xl\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  .xl\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  .xl\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  .xl\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  .xl\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  .xl\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  .xl\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  .xl\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  .xl\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  .xl\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  .xl\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  .xl\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  .xl\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  .xl\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  .xl\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  .xl\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  .xl\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  .xl\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  .xl\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  .xl\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  .xl\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  .xl\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  .xl\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  .xl\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  .xl\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  .xl\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .xl\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  .xl\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  .xl\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  .xl\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  .xl\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  .xl\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  .xl\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  .xl\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  .xl\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  .xl\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  .xl\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  .xl\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  .xl\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  .xl\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  .xl\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  .xl\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  .xl\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  .xl\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  .xl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .xl\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .xl\:my-0\.5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem
  }

  .xl\:mx-0\.5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem
  }

  .xl\:my-1\.5 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem
  }

  .xl\:mx-1\.5 {
    margin-left: 0.15rem;
    margin-right: 0.15rem
  }

  .xl\:my-2\.5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .xl\:mx-2\.5 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .xl\:my-3\.5 {
    margin-top: 0.35rem;
    margin-bottom: 0.35rem
  }

  .xl\:mx-3\.5 {
    margin-left: 0.35rem;
    margin-right: 0.35rem
  }

  .xl\:-my-1 {
    margin-top: -0.1rem;
    margin-bottom: -0.1rem
  }

  .xl\:-mx-1 {
    margin-left: -0.1rem;
    margin-right: -0.1rem
  }

  .xl\:-my-2 {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem
  }

  .xl\:-mx-2 {
    margin-left: -0.2rem;
    margin-right: -0.2rem
  }

  .xl\:-my-3 {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem
  }

  .xl\:-mx-3 {
    margin-left: -0.3rem;
    margin-right: -0.3rem
  }

  .xl\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  .xl\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  .xl\:-my-5 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .xl\:-mx-5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .xl\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  .xl\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  .xl\:-my-7 {
    margin-top: -0.7rem;
    margin-bottom: -0.7rem
  }

  .xl\:-mx-7 {
    margin-left: -0.7rem;
    margin-right: -0.7rem
  }

  .xl\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  .xl\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  .xl\:-my-9 {
    margin-top: -0.9rem;
    margin-bottom: -0.9rem
  }

  .xl\:-mx-9 {
    margin-left: -0.9rem;
    margin-right: -0.9rem
  }

  .xl\:-my-10 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .xl\:-mx-10 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .xl\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  .xl\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  .xl\:-my-14 {
    margin-top: -1.4rem;
    margin-bottom: -1.4rem
  }

  .xl\:-mx-14 {
    margin-left: -1.4rem;
    margin-right: -1.4rem
  }

  .xl\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  .xl\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  .xl\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .xl\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .xl\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  .xl\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  .xl\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  .xl\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  .xl\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  .xl\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  .xl\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  .xl\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  .xl\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .xl\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .xl\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  .xl\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  .xl\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  .xl\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  .xl\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  .xl\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  .xl\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  .xl\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  .xl\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .xl\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .xl\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  .xl\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  .xl\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  .xl\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  .xl\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  .xl\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  .xl\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  .xl\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  .xl\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .xl\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .xl\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  .xl\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  .xl\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  .xl\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  .xl\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  .xl\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  .xl\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  .xl\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  .xl\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  .xl\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  .xl\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  .xl\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  .xl\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .xl\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .xl\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  .xl\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  .xl\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  .xl\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  .xl\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  .xl\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  .xl\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  .xl\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  .xl\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  .xl\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  .xl\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  .xl\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  .xl\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  .xl\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  .xl\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  .xl\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  .xl\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  .xl\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  .xl\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  .xl\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  .xl\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  .xl\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  .xl\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  .xl\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  .xl\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  .xl\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  .xl\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  .xl\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  .xl\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  .xl\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  .xl\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  .xl\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  .xl\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  .xl\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  .xl\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  .xl\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  .xl\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  .xl\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  .xl\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  .xl\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  .xl\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  .xl\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  .xl\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  .xl\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  .xl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .xl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .xl\:-my-0\.5 {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem
  }

  .xl\:-mx-0\.5 {
    margin-left: -0.05rem;
    margin-right: -0.05rem
  }

  .xl\:-my-1\.5 {
    margin-top: -0.15rem;
    margin-bottom: -0.15rem
  }

  .xl\:-mx-1\.5 {
    margin-left: -0.15rem;
    margin-right: -0.15rem
  }

  .xl\:-my-2\.5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .xl\:-mx-2\.5 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .xl\:-my-3\.5 {
    margin-top: -0.35rem;
    margin-bottom: -0.35rem
  }

  .xl\:-mx-3\.5 {
    margin-left: -0.35rem;
    margin-right: -0.35rem
  }

  .xl\:mt-0 {
    margin-top: 0
  }

  .xl\:mr-0 {
    margin-right: 0
  }

  .xl\:mb-0 {
    margin-bottom: 0
  }

  .xl\:ml-0 {
    margin-left: 0
  }

  .xl\:mt-1 {
    margin-top: 0.1rem
  }

  .xl\:mr-1 {
    margin-right: 0.1rem
  }

  .xl\:mb-1 {
    margin-bottom: 0.1rem
  }

  .xl\:ml-1 {
    margin-left: 0.1rem
  }

  .xl\:mt-2 {
    margin-top: 0.2rem
  }

  .xl\:mr-2 {
    margin-right: 0.2rem
  }

  .xl\:mb-2 {
    margin-bottom: 0.2rem
  }

  .xl\:ml-2 {
    margin-left: 0.2rem
  }

  .xl\:mt-3 {
    margin-top: 0.3rem
  }

  .xl\:mr-3 {
    margin-right: 0.3rem
  }

  .xl\:mb-3 {
    margin-bottom: 0.3rem
  }

  .xl\:ml-3 {
    margin-left: 0.3rem
  }

  .xl\:mt-4 {
    margin-top: 0.4rem
  }

  .xl\:mr-4 {
    margin-right: 0.4rem
  }

  .xl\:mb-4 {
    margin-bottom: 0.4rem
  }

  .xl\:ml-4 {
    margin-left: 0.4rem
  }

  .xl\:mt-5 {
    margin-top: 0.5rem
  }

  .xl\:mr-5 {
    margin-right: 0.5rem
  }

  .xl\:mb-5 {
    margin-bottom: 0.5rem
  }

  .xl\:ml-5 {
    margin-left: 0.5rem
  }

  .xl\:mt-6 {
    margin-top: 0.6rem
  }

  .xl\:mr-6 {
    margin-right: 0.6rem
  }

  .xl\:mb-6 {
    margin-bottom: 0.6rem
  }

  .xl\:ml-6 {
    margin-left: 0.6rem
  }

  .xl\:mt-7 {
    margin-top: 0.7rem
  }

  .xl\:mr-7 {
    margin-right: 0.7rem
  }

  .xl\:mb-7 {
    margin-bottom: 0.7rem
  }

  .xl\:ml-7 {
    margin-left: 0.7rem
  }

  .xl\:mt-8 {
    margin-top: 0.8rem
  }

  .xl\:mr-8 {
    margin-right: 0.8rem
  }

  .xl\:mb-8 {
    margin-bottom: 0.8rem
  }

  .xl\:ml-8 {
    margin-left: 0.8rem
  }

  .xl\:mt-9 {
    margin-top: 0.9rem
  }

  .xl\:mr-9 {
    margin-right: 0.9rem
  }

  .xl\:mb-9 {
    margin-bottom: 0.9rem
  }

  .xl\:ml-9 {
    margin-left: 0.9rem
  }

  .xl\:mt-10 {
    margin-top: 1.0rem
  }

  .xl\:mr-10 {
    margin-right: 1.0rem
  }

  .xl\:mb-10 {
    margin-bottom: 1.0rem
  }

  .xl\:ml-10 {
    margin-left: 1.0rem
  }

  .xl\:mt-12 {
    margin-top: 1.2rem
  }

  .xl\:mr-12 {
    margin-right: 1.2rem
  }

  .xl\:mb-12 {
    margin-bottom: 1.2rem
  }

  .xl\:ml-12 {
    margin-left: 1.2rem
  }

  .xl\:mt-14 {
    margin-top: 1.4rem
  }

  .xl\:mr-14 {
    margin-right: 1.4rem
  }

  .xl\:mb-14 {
    margin-bottom: 1.4rem
  }

  .xl\:ml-14 {
    margin-left: 1.4rem
  }

  .xl\:mt-16 {
    margin-top: 1.6rem
  }

  .xl\:mr-16 {
    margin-right: 1.6rem
  }

  .xl\:mb-16 {
    margin-bottom: 1.6rem
  }

  .xl\:ml-16 {
    margin-left: 1.6rem
  }

  .xl\:mt-20 {
    margin-top: 2rem
  }

  .xl\:mr-20 {
    margin-right: 2rem
  }

  .xl\:mb-20 {
    margin-bottom: 2rem
  }

  .xl\:ml-20 {
    margin-left: 2rem
  }

  .xl\:mt-24 {
    margin-top: 2.4rem
  }

  .xl\:mr-24 {
    margin-right: 2.4rem
  }

  .xl\:mb-24 {
    margin-bottom: 2.4rem
  }

  .xl\:ml-24 {
    margin-left: 2.4rem
  }

  .xl\:mt-28 {
    margin-top: 2.8rem
  }

  .xl\:mr-28 {
    margin-right: 2.8rem
  }

  .xl\:mb-28 {
    margin-bottom: 2.8rem
  }

  .xl\:ml-28 {
    margin-left: 2.8rem
  }

  .xl\:mt-32 {
    margin-top: 3.2rem
  }

  .xl\:mr-32 {
    margin-right: 3.2rem
  }

  .xl\:mb-32 {
    margin-bottom: 3.2rem
  }

  .xl\:ml-32 {
    margin-left: 3.2rem
  }

  .xl\:mt-36 {
    margin-top: 3.6rem
  }

  .xl\:mr-36 {
    margin-right: 3.6rem
  }

  .xl\:mb-36 {
    margin-bottom: 3.6rem
  }

  .xl\:ml-36 {
    margin-left: 3.6rem
  }

  .xl\:mt-40 {
    margin-top: 4rem
  }

  .xl\:mr-40 {
    margin-right: 4rem
  }

  .xl\:mb-40 {
    margin-bottom: 4rem
  }

  .xl\:ml-40 {
    margin-left: 4rem
  }

  .xl\:mt-44 {
    margin-top: 4.4rem
  }

  .xl\:mr-44 {
    margin-right: 4.4rem
  }

  .xl\:mb-44 {
    margin-bottom: 4.4rem
  }

  .xl\:ml-44 {
    margin-left: 4.4rem
  }

  .xl\:mt-48 {
    margin-top: 4.8rem
  }

  .xl\:mr-48 {
    margin-right: 4.8rem
  }

  .xl\:mb-48 {
    margin-bottom: 4.8rem
  }

  .xl\:ml-48 {
    margin-left: 4.8rem
  }

  .xl\:mt-52 {
    margin-top: 5.2rem
  }

  .xl\:mr-52 {
    margin-right: 5.2rem
  }

  .xl\:mb-52 {
    margin-bottom: 5.2rem
  }

  .xl\:ml-52 {
    margin-left: 5.2rem
  }

  .xl\:mt-56 {
    margin-top: 5.6rem
  }

  .xl\:mr-56 {
    margin-right: 5.6rem
  }

  .xl\:mb-56 {
    margin-bottom: 5.6rem
  }

  .xl\:ml-56 {
    margin-left: 5.6rem
  }

  .xl\:mt-60 {
    margin-top: 6rem
  }

  .xl\:mr-60 {
    margin-right: 6rem
  }

  .xl\:mb-60 {
    margin-bottom: 6rem
  }

  .xl\:ml-60 {
    margin-left: 6rem
  }

  .xl\:mt-64 {
    margin-top: 6.4rem
  }

  .xl\:mr-64 {
    margin-right: 6.4rem
  }

  .xl\:mb-64 {
    margin-bottom: 6.4rem
  }

  .xl\:ml-64 {
    margin-left: 6.4rem
  }

  .xl\:mt-68 {
    margin-top: 6.8rem
  }

  .xl\:mr-68 {
    margin-right: 6.8rem
  }

  .xl\:mb-68 {
    margin-bottom: 6.8rem
  }

  .xl\:ml-68 {
    margin-left: 6.8rem
  }

  .xl\:mt-72 {
    margin-top: 7.2rem
  }

  .xl\:mr-72 {
    margin-right: 7.2rem
  }

  .xl\:mb-72 {
    margin-bottom: 7.2rem
  }

  .xl\:ml-72 {
    margin-left: 7.2rem
  }

  .xl\:mt-76 {
    margin-top: 7.6rem
  }

  .xl\:mr-76 {
    margin-right: 7.6rem
  }

  .xl\:mb-76 {
    margin-bottom: 7.6rem
  }

  .xl\:ml-76 {
    margin-left: 7.6rem
  }

  .xl\:mt-80 {
    margin-top: 8rem
  }

  .xl\:mr-80 {
    margin-right: 8rem
  }

  .xl\:mb-80 {
    margin-bottom: 8rem
  }

  .xl\:ml-80 {
    margin-left: 8rem
  }

  .xl\:mt-84 {
    margin-top: 8.4rem
  }

  .xl\:mr-84 {
    margin-right: 8.4rem
  }

  .xl\:mb-84 {
    margin-bottom: 8.4rem
  }

  .xl\:ml-84 {
    margin-left: 8.4rem
  }

  .xl\:mt-88 {
    margin-top: 8.8rem
  }

  .xl\:mr-88 {
    margin-right: 8.8rem
  }

  .xl\:mb-88 {
    margin-bottom: 8.8rem
  }

  .xl\:ml-88 {
    margin-left: 8.8rem
  }

  .xl\:mt-92 {
    margin-top: 9.2rem
  }

  .xl\:mr-92 {
    margin-right: 9.2rem
  }

  .xl\:mb-92 {
    margin-bottom: 9.2rem
  }

  .xl\:ml-92 {
    margin-left: 9.2rem
  }

  .xl\:mt-96 {
    margin-top: 9.6rem
  }

  .xl\:mr-96 {
    margin-right: 9.6rem
  }

  .xl\:mb-96 {
    margin-bottom: 9.6rem
  }

  .xl\:ml-96 {
    margin-left: 9.6rem
  }

  .xl\:mt-128 {
    margin-top: 12.8rem
  }

  .xl\:mr-128 {
    margin-right: 12.8rem
  }

  .xl\:mb-128 {
    margin-bottom: 12.8rem
  }

  .xl\:ml-128 {
    margin-left: 12.8rem
  }

  .xl\:mt-136 {
    margin-top: 13.6rem
  }

  .xl\:mr-136 {
    margin-right: 13.6rem
  }

  .xl\:mb-136 {
    margin-bottom: 13.6rem
  }

  .xl\:ml-136 {
    margin-left: 13.6rem
  }

  .xl\:mt-160 {
    margin-top: 16rem
  }

  .xl\:mr-160 {
    margin-right: 16rem
  }

  .xl\:mb-160 {
    margin-bottom: 16rem
  }

  .xl\:ml-160 {
    margin-left: 16rem
  }

  .xl\:mt-192 {
    margin-top: 19.2rem
  }

  .xl\:mr-192 {
    margin-right: 19.2rem
  }

  .xl\:mb-192 {
    margin-bottom: 19.2rem
  }

  .xl\:ml-192 {
    margin-left: 19.2rem
  }

  .xl\:mt-200 {
    margin-top: 20rem
  }

  .xl\:mr-200 {
    margin-right: 20rem
  }

  .xl\:mb-200 {
    margin-bottom: 20rem
  }

  .xl\:ml-200 {
    margin-left: 20rem
  }

  .xl\:mt-208 {
    margin-top: 20.8rem
  }

  .xl\:mr-208 {
    margin-right: 20.8rem
  }

  .xl\:mb-208 {
    margin-bottom: 20.8rem
  }

  .xl\:ml-208 {
    margin-left: 20.8rem
  }

  .xl\:mt-216 {
    margin-top: 21.6rem
  }

  .xl\:mr-216 {
    margin-right: 21.6rem
  }

  .xl\:mb-216 {
    margin-bottom: 21.6rem
  }

  .xl\:ml-216 {
    margin-left: 21.6rem
  }

  .xl\:mt-224 {
    margin-top: 22.4rem
  }

  .xl\:mr-224 {
    margin-right: 22.4rem
  }

  .xl\:mb-224 {
    margin-bottom: 22.4rem
  }

  .xl\:ml-224 {
    margin-left: 22.4rem
  }

  .xl\:mt-256 {
    margin-top: 25.6rem
  }

  .xl\:mr-256 {
    margin-right: 25.6rem
  }

  .xl\:mb-256 {
    margin-bottom: 25.6rem
  }

  .xl\:ml-256 {
    margin-left: 25.6rem
  }

  .xl\:mt-288 {
    margin-top: 28.8rem
  }

  .xl\:mr-288 {
    margin-right: 28.8rem
  }

  .xl\:mb-288 {
    margin-bottom: 28.8rem
  }

  .xl\:ml-288 {
    margin-left: 28.8rem
  }

  .xl\:mt-320 {
    margin-top: 32rem
  }

  .xl\:mr-320 {
    margin-right: 32rem
  }

  .xl\:mb-320 {
    margin-bottom: 32rem
  }

  .xl\:ml-320 {
    margin-left: 32rem
  }

  .xl\:mt-360 {
    margin-top: 36rem
  }

  .xl\:mr-360 {
    margin-right: 36rem
  }

  .xl\:mb-360 {
    margin-bottom: 36rem
  }

  .xl\:ml-360 {
    margin-left: 36rem
  }

  .xl\:mt-384 {
    margin-top: 38.4rem
  }

  .xl\:mr-384 {
    margin-right: 38.4rem
  }

  .xl\:mb-384 {
    margin-bottom: 38.4rem
  }

  .xl\:ml-384 {
    margin-left: 38.4rem
  }

  .xl\:mt-400 {
    margin-top: 40rem
  }

  .xl\:mr-400 {
    margin-right: 40rem
  }

  .xl\:mb-400 {
    margin-bottom: 40rem
  }

  .xl\:ml-400 {
    margin-left: 40rem
  }

  .xl\:mt-512 {
    margin-top: 51.2rem
  }

  .xl\:mr-512 {
    margin-right: 51.2rem
  }

  .xl\:mb-512 {
    margin-bottom: 51.2rem
  }

  .xl\:ml-512 {
    margin-left: 51.2rem
  }

  .xl\:mt-640 {
    margin-top: 64rem
  }

  .xl\:mr-640 {
    margin-right: 64rem
  }

  .xl\:mb-640 {
    margin-bottom: 64rem
  }

  .xl\:ml-640 {
    margin-left: 64rem
  }

  .xl\:mt-auto {
    margin-top: auto
  }

  .xl\:mr-auto {
    margin-right: auto
  }

  .xl\:mb-auto {
    margin-bottom: auto
  }

  .xl\:ml-auto {
    margin-left: auto
  }

  .xl\:mt-xs {
    margin-top: 32rem
  }

  .xl\:mr-xs {
    margin-right: 32rem
  }

  .xl\:mb-xs {
    margin-bottom: 32rem
  }

  .xl\:ml-xs {
    margin-left: 32rem
  }

  .xl\:mt-sm {
    margin-top: 48rem
  }

  .xl\:mr-sm {
    margin-right: 48rem
  }

  .xl\:mb-sm {
    margin-bottom: 48rem
  }

  .xl\:ml-sm {
    margin-left: 48rem
  }

  .xl\:mt-md {
    margin-top: 64rem
  }

  .xl\:mr-md {
    margin-right: 64rem
  }

  .xl\:mb-md {
    margin-bottom: 64rem
  }

  .xl\:ml-md {
    margin-left: 64rem
  }

  .xl\:mt-lg {
    margin-top: 80rem
  }

  .xl\:mr-lg {
    margin-right: 80rem
  }

  .xl\:mb-lg {
    margin-bottom: 80rem
  }

  .xl\:ml-lg {
    margin-left: 80rem
  }

  .xl\:mt-xl {
    margin-top: 96rem
  }

  .xl\:mr-xl {
    margin-right: 96rem
  }

  .xl\:mb-xl {
    margin-bottom: 96rem
  }

  .xl\:ml-xl {
    margin-left: 96rem
  }

  .xl\:mt-2xl {
    margin-top: 112rem
  }

  .xl\:mr-2xl {
    margin-right: 112rem
  }

  .xl\:mb-2xl {
    margin-bottom: 112rem
  }

  .xl\:ml-2xl {
    margin-left: 112rem
  }

  .xl\:mt-3xl {
    margin-top: 128rem
  }

  .xl\:mr-3xl {
    margin-right: 128rem
  }

  .xl\:mb-3xl {
    margin-bottom: 128rem
  }

  .xl\:ml-3xl {
    margin-left: 128rem
  }

  .xl\:mt-4xl {
    margin-top: 144rem
  }

  .xl\:mr-4xl {
    margin-right: 144rem
  }

  .xl\:mb-4xl {
    margin-bottom: 144rem
  }

  .xl\:ml-4xl {
    margin-left: 144rem
  }

  .xl\:mt-5xl {
    margin-top: 160rem
  }

  .xl\:mr-5xl {
    margin-right: 160rem
  }

  .xl\:mb-5xl {
    margin-bottom: 160rem
  }

  .xl\:ml-5xl {
    margin-left: 160rem
  }

  .xl\:mt-px {
    margin-top: 1px
  }

  .xl\:mr-px {
    margin-right: 1px
  }

  .xl\:mb-px {
    margin-bottom: 1px
  }

  .xl\:ml-px {
    margin-left: 1px
  }

  .xl\:mt-0\.5 {
    margin-top: 0.05rem
  }

  .xl\:mr-0\.5 {
    margin-right: 0.05rem
  }

  .xl\:mb-0\.5 {
    margin-bottom: 0.05rem
  }

  .xl\:ml-0\.5 {
    margin-left: 0.05rem
  }

  .xl\:mt-1\.5 {
    margin-top: 0.15rem
  }

  .xl\:mr-1\.5 {
    margin-right: 0.15rem
  }

  .xl\:mb-1\.5 {
    margin-bottom: 0.15rem
  }

  .xl\:ml-1\.5 {
    margin-left: 0.15rem
  }

  .xl\:mt-2\.5 {
    margin-top: 0.25rem
  }

  .xl\:mr-2\.5 {
    margin-right: 0.25rem
  }

  .xl\:mb-2\.5 {
    margin-bottom: 0.25rem
  }

  .xl\:ml-2\.5 {
    margin-left: 0.25rem
  }

  .xl\:mt-3\.5 {
    margin-top: 0.35rem
  }

  .xl\:mr-3\.5 {
    margin-right: 0.35rem
  }

  .xl\:mb-3\.5 {
    margin-bottom: 0.35rem
  }

  .xl\:ml-3\.5 {
    margin-left: 0.35rem
  }

  .xl\:-mt-1 {
    margin-top: -0.1rem
  }

  .xl\:-mr-1 {
    margin-right: -0.1rem
  }

  .xl\:-mb-1 {
    margin-bottom: -0.1rem
  }

  .xl\:-ml-1 {
    margin-left: -0.1rem
  }

  .xl\:-mt-2 {
    margin-top: -0.2rem
  }

  .xl\:-mr-2 {
    margin-right: -0.2rem
  }

  .xl\:-mb-2 {
    margin-bottom: -0.2rem
  }

  .xl\:-ml-2 {
    margin-left: -0.2rem
  }

  .xl\:-mt-3 {
    margin-top: -0.3rem
  }

  .xl\:-mr-3 {
    margin-right: -0.3rem
  }

  .xl\:-mb-3 {
    margin-bottom: -0.3rem
  }

  .xl\:-ml-3 {
    margin-left: -0.3rem
  }

  .xl\:-mt-4 {
    margin-top: -0.4rem
  }

  .xl\:-mr-4 {
    margin-right: -0.4rem
  }

  .xl\:-mb-4 {
    margin-bottom: -0.4rem
  }

  .xl\:-ml-4 {
    margin-left: -0.4rem
  }

  .xl\:-mt-5 {
    margin-top: -0.5rem
  }

  .xl\:-mr-5 {
    margin-right: -0.5rem
  }

  .xl\:-mb-5 {
    margin-bottom: -0.5rem
  }

  .xl\:-ml-5 {
    margin-left: -0.5rem
  }

  .xl\:-mt-6 {
    margin-top: -0.6rem
  }

  .xl\:-mr-6 {
    margin-right: -0.6rem
  }

  .xl\:-mb-6 {
    margin-bottom: -0.6rem
  }

  .xl\:-ml-6 {
    margin-left: -0.6rem
  }

  .xl\:-mt-7 {
    margin-top: -0.7rem
  }

  .xl\:-mr-7 {
    margin-right: -0.7rem
  }

  .xl\:-mb-7 {
    margin-bottom: -0.7rem
  }

  .xl\:-ml-7 {
    margin-left: -0.7rem
  }

  .xl\:-mt-8 {
    margin-top: -0.8rem
  }

  .xl\:-mr-8 {
    margin-right: -0.8rem
  }

  .xl\:-mb-8 {
    margin-bottom: -0.8rem
  }

  .xl\:-ml-8 {
    margin-left: -0.8rem
  }

  .xl\:-mt-9 {
    margin-top: -0.9rem
  }

  .xl\:-mr-9 {
    margin-right: -0.9rem
  }

  .xl\:-mb-9 {
    margin-bottom: -0.9rem
  }

  .xl\:-ml-9 {
    margin-left: -0.9rem
  }

  .xl\:-mt-10 {
    margin-top: -1rem
  }

  .xl\:-mr-10 {
    margin-right: -1rem
  }

  .xl\:-mb-10 {
    margin-bottom: -1rem
  }

  .xl\:-ml-10 {
    margin-left: -1rem
  }

  .xl\:-mt-12 {
    margin-top: -1.2rem
  }

  .xl\:-mr-12 {
    margin-right: -1.2rem
  }

  .xl\:-mb-12 {
    margin-bottom: -1.2rem
  }

  .xl\:-ml-12 {
    margin-left: -1.2rem
  }

  .xl\:-mt-14 {
    margin-top: -1.4rem
  }

  .xl\:-mr-14 {
    margin-right: -1.4rem
  }

  .xl\:-mb-14 {
    margin-bottom: -1.4rem
  }

  .xl\:-ml-14 {
    margin-left: -1.4rem
  }

  .xl\:-mt-16 {
    margin-top: -1.6rem
  }

  .xl\:-mr-16 {
    margin-right: -1.6rem
  }

  .xl\:-mb-16 {
    margin-bottom: -1.6rem
  }

  .xl\:-ml-16 {
    margin-left: -1.6rem
  }

  .xl\:-mt-20 {
    margin-top: -2rem
  }

  .xl\:-mr-20 {
    margin-right: -2rem
  }

  .xl\:-mb-20 {
    margin-bottom: -2rem
  }

  .xl\:-ml-20 {
    margin-left: -2rem
  }

  .xl\:-mt-24 {
    margin-top: -2.4rem
  }

  .xl\:-mr-24 {
    margin-right: -2.4rem
  }

  .xl\:-mb-24 {
    margin-bottom: -2.4rem
  }

  .xl\:-ml-24 {
    margin-left: -2.4rem
  }

  .xl\:-mt-28 {
    margin-top: -2.8rem
  }

  .xl\:-mr-28 {
    margin-right: -2.8rem
  }

  .xl\:-mb-28 {
    margin-bottom: -2.8rem
  }

  .xl\:-ml-28 {
    margin-left: -2.8rem
  }

  .xl\:-mt-32 {
    margin-top: -3.2rem
  }

  .xl\:-mr-32 {
    margin-right: -3.2rem
  }

  .xl\:-mb-32 {
    margin-bottom: -3.2rem
  }

  .xl\:-ml-32 {
    margin-left: -3.2rem
  }

  .xl\:-mt-36 {
    margin-top: -3.6rem
  }

  .xl\:-mr-36 {
    margin-right: -3.6rem
  }

  .xl\:-mb-36 {
    margin-bottom: -3.6rem
  }

  .xl\:-ml-36 {
    margin-left: -3.6rem
  }

  .xl\:-mt-40 {
    margin-top: -4rem
  }

  .xl\:-mr-40 {
    margin-right: -4rem
  }

  .xl\:-mb-40 {
    margin-bottom: -4rem
  }

  .xl\:-ml-40 {
    margin-left: -4rem
  }

  .xl\:-mt-44 {
    margin-top: -4.4rem
  }

  .xl\:-mr-44 {
    margin-right: -4.4rem
  }

  .xl\:-mb-44 {
    margin-bottom: -4.4rem
  }

  .xl\:-ml-44 {
    margin-left: -4.4rem
  }

  .xl\:-mt-48 {
    margin-top: -4.8rem
  }

  .xl\:-mr-48 {
    margin-right: -4.8rem
  }

  .xl\:-mb-48 {
    margin-bottom: -4.8rem
  }

  .xl\:-ml-48 {
    margin-left: -4.8rem
  }

  .xl\:-mt-52 {
    margin-top: -5.2rem
  }

  .xl\:-mr-52 {
    margin-right: -5.2rem
  }

  .xl\:-mb-52 {
    margin-bottom: -5.2rem
  }

  .xl\:-ml-52 {
    margin-left: -5.2rem
  }

  .xl\:-mt-56 {
    margin-top: -5.6rem
  }

  .xl\:-mr-56 {
    margin-right: -5.6rem
  }

  .xl\:-mb-56 {
    margin-bottom: -5.6rem
  }

  .xl\:-ml-56 {
    margin-left: -5.6rem
  }

  .xl\:-mt-60 {
    margin-top: -6rem
  }

  .xl\:-mr-60 {
    margin-right: -6rem
  }

  .xl\:-mb-60 {
    margin-bottom: -6rem
  }

  .xl\:-ml-60 {
    margin-left: -6rem
  }

  .xl\:-mt-64 {
    margin-top: -6.4rem
  }

  .xl\:-mr-64 {
    margin-right: -6.4rem
  }

  .xl\:-mb-64 {
    margin-bottom: -6.4rem
  }

  .xl\:-ml-64 {
    margin-left: -6.4rem
  }

  .xl\:-mt-68 {
    margin-top: -6.8rem
  }

  .xl\:-mr-68 {
    margin-right: -6.8rem
  }

  .xl\:-mb-68 {
    margin-bottom: -6.8rem
  }

  .xl\:-ml-68 {
    margin-left: -6.8rem
  }

  .xl\:-mt-72 {
    margin-top: -7.2rem
  }

  .xl\:-mr-72 {
    margin-right: -7.2rem
  }

  .xl\:-mb-72 {
    margin-bottom: -7.2rem
  }

  .xl\:-ml-72 {
    margin-left: -7.2rem
  }

  .xl\:-mt-76 {
    margin-top: -7.6rem
  }

  .xl\:-mr-76 {
    margin-right: -7.6rem
  }

  .xl\:-mb-76 {
    margin-bottom: -7.6rem
  }

  .xl\:-ml-76 {
    margin-left: -7.6rem
  }

  .xl\:-mt-80 {
    margin-top: -8rem
  }

  .xl\:-mr-80 {
    margin-right: -8rem
  }

  .xl\:-mb-80 {
    margin-bottom: -8rem
  }

  .xl\:-ml-80 {
    margin-left: -8rem
  }

  .xl\:-mt-84 {
    margin-top: -8.4rem
  }

  .xl\:-mr-84 {
    margin-right: -8.4rem
  }

  .xl\:-mb-84 {
    margin-bottom: -8.4rem
  }

  .xl\:-ml-84 {
    margin-left: -8.4rem
  }

  .xl\:-mt-88 {
    margin-top: -8.8rem
  }

  .xl\:-mr-88 {
    margin-right: -8.8rem
  }

  .xl\:-mb-88 {
    margin-bottom: -8.8rem
  }

  .xl\:-ml-88 {
    margin-left: -8.8rem
  }

  .xl\:-mt-92 {
    margin-top: -9.2rem
  }

  .xl\:-mr-92 {
    margin-right: -9.2rem
  }

  .xl\:-mb-92 {
    margin-bottom: -9.2rem
  }

  .xl\:-ml-92 {
    margin-left: -9.2rem
  }

  .xl\:-mt-96 {
    margin-top: -9.6rem
  }

  .xl\:-mr-96 {
    margin-right: -9.6rem
  }

  .xl\:-mb-96 {
    margin-bottom: -9.6rem
  }

  .xl\:-ml-96 {
    margin-left: -9.6rem
  }

  .xl\:-mt-128 {
    margin-top: -12.8rem
  }

  .xl\:-mr-128 {
    margin-right: -12.8rem
  }

  .xl\:-mb-128 {
    margin-bottom: -12.8rem
  }

  .xl\:-ml-128 {
    margin-left: -12.8rem
  }

  .xl\:-mt-136 {
    margin-top: -13.6rem
  }

  .xl\:-mr-136 {
    margin-right: -13.6rem
  }

  .xl\:-mb-136 {
    margin-bottom: -13.6rem
  }

  .xl\:-ml-136 {
    margin-left: -13.6rem
  }

  .xl\:-mt-160 {
    margin-top: -16rem
  }

  .xl\:-mr-160 {
    margin-right: -16rem
  }

  .xl\:-mb-160 {
    margin-bottom: -16rem
  }

  .xl\:-ml-160 {
    margin-left: -16rem
  }

  .xl\:-mt-192 {
    margin-top: -19.2rem
  }

  .xl\:-mr-192 {
    margin-right: -19.2rem
  }

  .xl\:-mb-192 {
    margin-bottom: -19.2rem
  }

  .xl\:-ml-192 {
    margin-left: -19.2rem
  }

  .xl\:-mt-200 {
    margin-top: -20rem
  }

  .xl\:-mr-200 {
    margin-right: -20rem
  }

  .xl\:-mb-200 {
    margin-bottom: -20rem
  }

  .xl\:-ml-200 {
    margin-left: -20rem
  }

  .xl\:-mt-208 {
    margin-top: -20.8rem
  }

  .xl\:-mr-208 {
    margin-right: -20.8rem
  }

  .xl\:-mb-208 {
    margin-bottom: -20.8rem
  }

  .xl\:-ml-208 {
    margin-left: -20.8rem
  }

  .xl\:-mt-216 {
    margin-top: -21.6rem
  }

  .xl\:-mr-216 {
    margin-right: -21.6rem
  }

  .xl\:-mb-216 {
    margin-bottom: -21.6rem
  }

  .xl\:-ml-216 {
    margin-left: -21.6rem
  }

  .xl\:-mt-224 {
    margin-top: -22.4rem
  }

  .xl\:-mr-224 {
    margin-right: -22.4rem
  }

  .xl\:-mb-224 {
    margin-bottom: -22.4rem
  }

  .xl\:-ml-224 {
    margin-left: -22.4rem
  }

  .xl\:-mt-256 {
    margin-top: -25.6rem
  }

  .xl\:-mr-256 {
    margin-right: -25.6rem
  }

  .xl\:-mb-256 {
    margin-bottom: -25.6rem
  }

  .xl\:-ml-256 {
    margin-left: -25.6rem
  }

  .xl\:-mt-288 {
    margin-top: -28.8rem
  }

  .xl\:-mr-288 {
    margin-right: -28.8rem
  }

  .xl\:-mb-288 {
    margin-bottom: -28.8rem
  }

  .xl\:-ml-288 {
    margin-left: -28.8rem
  }

  .xl\:-mt-320 {
    margin-top: -32rem
  }

  .xl\:-mr-320 {
    margin-right: -32rem
  }

  .xl\:-mb-320 {
    margin-bottom: -32rem
  }

  .xl\:-ml-320 {
    margin-left: -32rem
  }

  .xl\:-mt-360 {
    margin-top: -36rem
  }

  .xl\:-mr-360 {
    margin-right: -36rem
  }

  .xl\:-mb-360 {
    margin-bottom: -36rem
  }

  .xl\:-ml-360 {
    margin-left: -36rem
  }

  .xl\:-mt-384 {
    margin-top: -38.4rem
  }

  .xl\:-mr-384 {
    margin-right: -38.4rem
  }

  .xl\:-mb-384 {
    margin-bottom: -38.4rem
  }

  .xl\:-ml-384 {
    margin-left: -38.4rem
  }

  .xl\:-mt-400 {
    margin-top: -40rem
  }

  .xl\:-mr-400 {
    margin-right: -40rem
  }

  .xl\:-mb-400 {
    margin-bottom: -40rem
  }

  .xl\:-ml-400 {
    margin-left: -40rem
  }

  .xl\:-mt-512 {
    margin-top: -51.2rem
  }

  .xl\:-mr-512 {
    margin-right: -51.2rem
  }

  .xl\:-mb-512 {
    margin-bottom: -51.2rem
  }

  .xl\:-ml-512 {
    margin-left: -51.2rem
  }

  .xl\:-mt-640 {
    margin-top: -64rem
  }

  .xl\:-mr-640 {
    margin-right: -64rem
  }

  .xl\:-mb-640 {
    margin-bottom: -64rem
  }

  .xl\:-ml-640 {
    margin-left: -64rem
  }

  .xl\:-mt-xs {
    margin-top: -32rem
  }

  .xl\:-mr-xs {
    margin-right: -32rem
  }

  .xl\:-mb-xs {
    margin-bottom: -32rem
  }

  .xl\:-ml-xs {
    margin-left: -32rem
  }

  .xl\:-mt-sm {
    margin-top: -48rem
  }

  .xl\:-mr-sm {
    margin-right: -48rem
  }

  .xl\:-mb-sm {
    margin-bottom: -48rem
  }

  .xl\:-ml-sm {
    margin-left: -48rem
  }

  .xl\:-mt-md {
    margin-top: -64rem
  }

  .xl\:-mr-md {
    margin-right: -64rem
  }

  .xl\:-mb-md {
    margin-bottom: -64rem
  }

  .xl\:-ml-md {
    margin-left: -64rem
  }

  .xl\:-mt-lg {
    margin-top: -80rem
  }

  .xl\:-mr-lg {
    margin-right: -80rem
  }

  .xl\:-mb-lg {
    margin-bottom: -80rem
  }

  .xl\:-ml-lg {
    margin-left: -80rem
  }

  .xl\:-mt-xl {
    margin-top: -96rem
  }

  .xl\:-mr-xl {
    margin-right: -96rem
  }

  .xl\:-mb-xl {
    margin-bottom: -96rem
  }

  .xl\:-ml-xl {
    margin-left: -96rem
  }

  .xl\:-mt-2xl {
    margin-top: -112rem
  }

  .xl\:-mr-2xl {
    margin-right: -112rem
  }

  .xl\:-mb-2xl {
    margin-bottom: -112rem
  }

  .xl\:-ml-2xl {
    margin-left: -112rem
  }

  .xl\:-mt-3xl {
    margin-top: -128rem
  }

  .xl\:-mr-3xl {
    margin-right: -128rem
  }

  .xl\:-mb-3xl {
    margin-bottom: -128rem
  }

  .xl\:-ml-3xl {
    margin-left: -128rem
  }

  .xl\:-mt-4xl {
    margin-top: -144rem
  }

  .xl\:-mr-4xl {
    margin-right: -144rem
  }

  .xl\:-mb-4xl {
    margin-bottom: -144rem
  }

  .xl\:-ml-4xl {
    margin-left: -144rem
  }

  .xl\:-mt-5xl {
    margin-top: -160rem
  }

  .xl\:-mr-5xl {
    margin-right: -160rem
  }

  .xl\:-mb-5xl {
    margin-bottom: -160rem
  }

  .xl\:-ml-5xl {
    margin-left: -160rem
  }

  .xl\:-mt-px {
    margin-top: -1px
  }

  .xl\:-mr-px {
    margin-right: -1px
  }

  .xl\:-mb-px {
    margin-bottom: -1px
  }

  .xl\:-ml-px {
    margin-left: -1px
  }

  .xl\:-mt-0\.5 {
    margin-top: -0.05rem
  }

  .xl\:-mr-0\.5 {
    margin-right: -0.05rem
  }

  .xl\:-mb-0\.5 {
    margin-bottom: -0.05rem
  }

  .xl\:-ml-0\.5 {
    margin-left: -0.05rem
  }

  .xl\:-mt-1\.5 {
    margin-top: -0.15rem
  }

  .xl\:-mr-1\.5 {
    margin-right: -0.15rem
  }

  .xl\:-mb-1\.5 {
    margin-bottom: -0.15rem
  }

  .xl\:-ml-1\.5 {
    margin-left: -0.15rem
  }

  .xl\:-mt-2\.5 {
    margin-top: -0.25rem
  }

  .xl\:-mr-2\.5 {
    margin-right: -0.25rem
  }

  .xl\:-mb-2\.5 {
    margin-bottom: -0.25rem
  }

  .xl\:-ml-2\.5 {
    margin-left: -0.25rem
  }

  .xl\:-mt-3\.5 {
    margin-top: -0.35rem
  }

  .xl\:-mr-3\.5 {
    margin-right: -0.35rem
  }

  .xl\:-mb-3\.5 {
    margin-bottom: -0.35rem
  }

  .xl\:-ml-3\.5 {
    margin-left: -0.35rem
  }

  [dir='ltr'] .xl\:ltr\:m-0,[dir='ltr'].xl\:ltr\:m-0 {
    margin: 0
  }

  [dir='ltr'] .xl\:ltr\:m-1,[dir='ltr'].xl\:ltr\:m-1 {
    margin: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:m-2,[dir='ltr'].xl\:ltr\:m-2 {
    margin: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:m-3,[dir='ltr'].xl\:ltr\:m-3 {
    margin: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:m-4,[dir='ltr'].xl\:ltr\:m-4 {
    margin: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:m-5,[dir='ltr'].xl\:ltr\:m-5 {
    margin: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:m-6,[dir='ltr'].xl\:ltr\:m-6 {
    margin: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:m-7,[dir='ltr'].xl\:ltr\:m-7 {
    margin: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:m-8,[dir='ltr'].xl\:ltr\:m-8 {
    margin: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:m-9,[dir='ltr'].xl\:ltr\:m-9 {
    margin: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:m-10,[dir='ltr'].xl\:ltr\:m-10 {
    margin: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:m-12,[dir='ltr'].xl\:ltr\:m-12 {
    margin: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:m-14,[dir='ltr'].xl\:ltr\:m-14 {
    margin: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:m-16,[dir='ltr'].xl\:ltr\:m-16 {
    margin: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:m-20,[dir='ltr'].xl\:ltr\:m-20 {
    margin: 2rem
  }

  [dir='ltr'] .xl\:ltr\:m-24,[dir='ltr'].xl\:ltr\:m-24 {
    margin: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:m-28,[dir='ltr'].xl\:ltr\:m-28 {
    margin: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:m-32,[dir='ltr'].xl\:ltr\:m-32 {
    margin: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:m-36,[dir='ltr'].xl\:ltr\:m-36 {
    margin: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:m-40,[dir='ltr'].xl\:ltr\:m-40 {
    margin: 4rem
  }

  [dir='ltr'] .xl\:ltr\:m-44,[dir='ltr'].xl\:ltr\:m-44 {
    margin: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:m-48,[dir='ltr'].xl\:ltr\:m-48 {
    margin: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:m-52,[dir='ltr'].xl\:ltr\:m-52 {
    margin: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:m-56,[dir='ltr'].xl\:ltr\:m-56 {
    margin: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:m-60,[dir='ltr'].xl\:ltr\:m-60 {
    margin: 6rem
  }

  [dir='ltr'] .xl\:ltr\:m-64,[dir='ltr'].xl\:ltr\:m-64 {
    margin: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:m-68,[dir='ltr'].xl\:ltr\:m-68 {
    margin: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:m-72,[dir='ltr'].xl\:ltr\:m-72 {
    margin: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:m-76,[dir='ltr'].xl\:ltr\:m-76 {
    margin: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:m-80,[dir='ltr'].xl\:ltr\:m-80 {
    margin: 8rem
  }

  [dir='ltr'] .xl\:ltr\:m-84,[dir='ltr'].xl\:ltr\:m-84 {
    margin: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:m-88,[dir='ltr'].xl\:ltr\:m-88 {
    margin: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:m-92,[dir='ltr'].xl\:ltr\:m-92 {
    margin: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:m-96,[dir='ltr'].xl\:ltr\:m-96 {
    margin: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:m-128,[dir='ltr'].xl\:ltr\:m-128 {
    margin: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:m-136,[dir='ltr'].xl\:ltr\:m-136 {
    margin: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:m-160,[dir='ltr'].xl\:ltr\:m-160 {
    margin: 16rem
  }

  [dir='ltr'] .xl\:ltr\:m-192,[dir='ltr'].xl\:ltr\:m-192 {
    margin: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:m-200,[dir='ltr'].xl\:ltr\:m-200 {
    margin: 20rem
  }

  [dir='ltr'] .xl\:ltr\:m-208,[dir='ltr'].xl\:ltr\:m-208 {
    margin: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:m-216,[dir='ltr'].xl\:ltr\:m-216 {
    margin: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:m-224,[dir='ltr'].xl\:ltr\:m-224 {
    margin: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:m-256,[dir='ltr'].xl\:ltr\:m-256 {
    margin: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:m-288,[dir='ltr'].xl\:ltr\:m-288 {
    margin: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:m-320,[dir='ltr'].xl\:ltr\:m-320 {
    margin: 32rem
  }

  [dir='ltr'] .xl\:ltr\:m-360,[dir='ltr'].xl\:ltr\:m-360 {
    margin: 36rem
  }

  [dir='ltr'] .xl\:ltr\:m-384,[dir='ltr'].xl\:ltr\:m-384 {
    margin: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:m-400,[dir='ltr'].xl\:ltr\:m-400 {
    margin: 40rem
  }

  [dir='ltr'] .xl\:ltr\:m-512,[dir='ltr'].xl\:ltr\:m-512 {
    margin: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:m-640,[dir='ltr'].xl\:ltr\:m-640 {
    margin: 64rem
  }

  [dir='ltr'] .xl\:ltr\:m-auto,[dir='ltr'].xl\:ltr\:m-auto {
    margin: auto
  }

  [dir='ltr'] .xl\:ltr\:m-xs,[dir='ltr'].xl\:ltr\:m-xs {
    margin: 32rem
  }

  [dir='ltr'] .xl\:ltr\:m-sm,[dir='ltr'].xl\:ltr\:m-sm {
    margin: 48rem
  }

  [dir='ltr'] .xl\:ltr\:m-md,[dir='ltr'].xl\:ltr\:m-md {
    margin: 64rem
  }

  [dir='ltr'] .xl\:ltr\:m-lg,[dir='ltr'].xl\:ltr\:m-lg {
    margin: 80rem
  }

  [dir='ltr'] .xl\:ltr\:m-xl,[dir='ltr'].xl\:ltr\:m-xl {
    margin: 96rem
  }

  [dir='ltr'] .xl\:ltr\:m-2xl,[dir='ltr'].xl\:ltr\:m-2xl {
    margin: 112rem
  }

  [dir='ltr'] .xl\:ltr\:m-3xl,[dir='ltr'].xl\:ltr\:m-3xl {
    margin: 128rem
  }

  [dir='ltr'] .xl\:ltr\:m-4xl,[dir='ltr'].xl\:ltr\:m-4xl {
    margin: 144rem
  }

  [dir='ltr'] .xl\:ltr\:m-5xl,[dir='ltr'].xl\:ltr\:m-5xl {
    margin: 160rem
  }

  [dir='ltr'] .xl\:ltr\:m-px,[dir='ltr'].xl\:ltr\:m-px {
    margin: 1px
  }

  [dir='ltr'] .xl\:ltr\:m-0\.5,[dir='ltr'].xl\:ltr\:m-0\.5 {
    margin: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:m-1\.5,[dir='ltr'].xl\:ltr\:m-1\.5 {
    margin: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:m-2\.5,[dir='ltr'].xl\:ltr\:m-2\.5 {
    margin: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:m-3\.5,[dir='ltr'].xl\:ltr\:m-3\.5 {
    margin: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:-m-1,[dir='ltr'].xl\:ltr\:-m-1 {
    margin: -0.1rem
  }

  [dir='ltr'] .xl\:ltr\:-m-2,[dir='ltr'].xl\:ltr\:-m-2 {
    margin: -0.2rem
  }

  [dir='ltr'] .xl\:ltr\:-m-3,[dir='ltr'].xl\:ltr\:-m-3 {
    margin: -0.3rem
  }

  [dir='ltr'] .xl\:ltr\:-m-4,[dir='ltr'].xl\:ltr\:-m-4 {
    margin: -0.4rem
  }

  [dir='ltr'] .xl\:ltr\:-m-5,[dir='ltr'].xl\:ltr\:-m-5 {
    margin: -0.5rem
  }

  [dir='ltr'] .xl\:ltr\:-m-6,[dir='ltr'].xl\:ltr\:-m-6 {
    margin: -0.6rem
  }

  [dir='ltr'] .xl\:ltr\:-m-7,[dir='ltr'].xl\:ltr\:-m-7 {
    margin: -0.7rem
  }

  [dir='ltr'] .xl\:ltr\:-m-8,[dir='ltr'].xl\:ltr\:-m-8 {
    margin: -0.8rem
  }

  [dir='ltr'] .xl\:ltr\:-m-9,[dir='ltr'].xl\:ltr\:-m-9 {
    margin: -0.9rem
  }

  [dir='ltr'] .xl\:ltr\:-m-10,[dir='ltr'].xl\:ltr\:-m-10 {
    margin: -1rem
  }

  [dir='ltr'] .xl\:ltr\:-m-12,[dir='ltr'].xl\:ltr\:-m-12 {
    margin: -1.2rem
  }

  [dir='ltr'] .xl\:ltr\:-m-14,[dir='ltr'].xl\:ltr\:-m-14 {
    margin: -1.4rem
  }

  [dir='ltr'] .xl\:ltr\:-m-16,[dir='ltr'].xl\:ltr\:-m-16 {
    margin: -1.6rem
  }

  [dir='ltr'] .xl\:ltr\:-m-20,[dir='ltr'].xl\:ltr\:-m-20 {
    margin: -2rem
  }

  [dir='ltr'] .xl\:ltr\:-m-24,[dir='ltr'].xl\:ltr\:-m-24 {
    margin: -2.4rem
  }

  [dir='ltr'] .xl\:ltr\:-m-28,[dir='ltr'].xl\:ltr\:-m-28 {
    margin: -2.8rem
  }

  [dir='ltr'] .xl\:ltr\:-m-32,[dir='ltr'].xl\:ltr\:-m-32 {
    margin: -3.2rem
  }

  [dir='ltr'] .xl\:ltr\:-m-36,[dir='ltr'].xl\:ltr\:-m-36 {
    margin: -3.6rem
  }

  [dir='ltr'] .xl\:ltr\:-m-40,[dir='ltr'].xl\:ltr\:-m-40 {
    margin: -4rem
  }

  [dir='ltr'] .xl\:ltr\:-m-44,[dir='ltr'].xl\:ltr\:-m-44 {
    margin: -4.4rem
  }

  [dir='ltr'] .xl\:ltr\:-m-48,[dir='ltr'].xl\:ltr\:-m-48 {
    margin: -4.8rem
  }

  [dir='ltr'] .xl\:ltr\:-m-52,[dir='ltr'].xl\:ltr\:-m-52 {
    margin: -5.2rem
  }

  [dir='ltr'] .xl\:ltr\:-m-56,[dir='ltr'].xl\:ltr\:-m-56 {
    margin: -5.6rem
  }

  [dir='ltr'] .xl\:ltr\:-m-60,[dir='ltr'].xl\:ltr\:-m-60 {
    margin: -6rem
  }

  [dir='ltr'] .xl\:ltr\:-m-64,[dir='ltr'].xl\:ltr\:-m-64 {
    margin: -6.4rem
  }

  [dir='ltr'] .xl\:ltr\:-m-68,[dir='ltr'].xl\:ltr\:-m-68 {
    margin: -6.8rem
  }

  [dir='ltr'] .xl\:ltr\:-m-72,[dir='ltr'].xl\:ltr\:-m-72 {
    margin: -7.2rem
  }

  [dir='ltr'] .xl\:ltr\:-m-76,[dir='ltr'].xl\:ltr\:-m-76 {
    margin: -7.6rem
  }

  [dir='ltr'] .xl\:ltr\:-m-80,[dir='ltr'].xl\:ltr\:-m-80 {
    margin: -8rem
  }

  [dir='ltr'] .xl\:ltr\:-m-84,[dir='ltr'].xl\:ltr\:-m-84 {
    margin: -8.4rem
  }

  [dir='ltr'] .xl\:ltr\:-m-88,[dir='ltr'].xl\:ltr\:-m-88 {
    margin: -8.8rem
  }

  [dir='ltr'] .xl\:ltr\:-m-92,[dir='ltr'].xl\:ltr\:-m-92 {
    margin: -9.2rem
  }

  [dir='ltr'] .xl\:ltr\:-m-96,[dir='ltr'].xl\:ltr\:-m-96 {
    margin: -9.6rem
  }

  [dir='ltr'] .xl\:ltr\:-m-128,[dir='ltr'].xl\:ltr\:-m-128 {
    margin: -12.8rem
  }

  [dir='ltr'] .xl\:ltr\:-m-136,[dir='ltr'].xl\:ltr\:-m-136 {
    margin: -13.6rem
  }

  [dir='ltr'] .xl\:ltr\:-m-160,[dir='ltr'].xl\:ltr\:-m-160 {
    margin: -16rem
  }

  [dir='ltr'] .xl\:ltr\:-m-192,[dir='ltr'].xl\:ltr\:-m-192 {
    margin: -19.2rem
  }

  [dir='ltr'] .xl\:ltr\:-m-200,[dir='ltr'].xl\:ltr\:-m-200 {
    margin: -20rem
  }

  [dir='ltr'] .xl\:ltr\:-m-208,[dir='ltr'].xl\:ltr\:-m-208 {
    margin: -20.8rem
  }

  [dir='ltr'] .xl\:ltr\:-m-216,[dir='ltr'].xl\:ltr\:-m-216 {
    margin: -21.6rem
  }

  [dir='ltr'] .xl\:ltr\:-m-224,[dir='ltr'].xl\:ltr\:-m-224 {
    margin: -22.4rem
  }

  [dir='ltr'] .xl\:ltr\:-m-256,[dir='ltr'].xl\:ltr\:-m-256 {
    margin: -25.6rem
  }

  [dir='ltr'] .xl\:ltr\:-m-288,[dir='ltr'].xl\:ltr\:-m-288 {
    margin: -28.8rem
  }

  [dir='ltr'] .xl\:ltr\:-m-320,[dir='ltr'].xl\:ltr\:-m-320 {
    margin: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-m-360,[dir='ltr'].xl\:ltr\:-m-360 {
    margin: -36rem
  }

  [dir='ltr'] .xl\:ltr\:-m-384,[dir='ltr'].xl\:ltr\:-m-384 {
    margin: -38.4rem
  }

  [dir='ltr'] .xl\:ltr\:-m-400,[dir='ltr'].xl\:ltr\:-m-400 {
    margin: -40rem
  }

  [dir='ltr'] .xl\:ltr\:-m-512,[dir='ltr'].xl\:ltr\:-m-512 {
    margin: -51.2rem
  }

  [dir='ltr'] .xl\:ltr\:-m-640,[dir='ltr'].xl\:ltr\:-m-640 {
    margin: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-m-xs,[dir='ltr'].xl\:ltr\:-m-xs {
    margin: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-m-sm,[dir='ltr'].xl\:ltr\:-m-sm {
    margin: -48rem
  }

  [dir='ltr'] .xl\:ltr\:-m-md,[dir='ltr'].xl\:ltr\:-m-md {
    margin: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-m-lg,[dir='ltr'].xl\:ltr\:-m-lg {
    margin: -80rem
  }

  [dir='ltr'] .xl\:ltr\:-m-xl,[dir='ltr'].xl\:ltr\:-m-xl {
    margin: -96rem
  }

  [dir='ltr'] .xl\:ltr\:-m-2xl,[dir='ltr'].xl\:ltr\:-m-2xl {
    margin: -112rem
  }

  [dir='ltr'] .xl\:ltr\:-m-3xl,[dir='ltr'].xl\:ltr\:-m-3xl {
    margin: -128rem
  }

  [dir='ltr'] .xl\:ltr\:-m-4xl,[dir='ltr'].xl\:ltr\:-m-4xl {
    margin: -144rem
  }

  [dir='ltr'] .xl\:ltr\:-m-5xl,[dir='ltr'].xl\:ltr\:-m-5xl {
    margin: -160rem
  }

  [dir='ltr'] .xl\:ltr\:-m-px,[dir='ltr'].xl\:ltr\:-m-px {
    margin: -1px
  }

  [dir='ltr'] .xl\:ltr\:-m-0\.5,[dir='ltr'].xl\:ltr\:-m-0\.5 {
    margin: -0.05rem
  }

  [dir='ltr'] .xl\:ltr\:-m-1\.5,[dir='ltr'].xl\:ltr\:-m-1\.5 {
    margin: -0.15rem
  }

  [dir='ltr'] .xl\:ltr\:-m-2\.5,[dir='ltr'].xl\:ltr\:-m-2\.5 {
    margin: -0.25rem
  }

  [dir='ltr'] .xl\:ltr\:-m-3\.5,[dir='ltr'].xl\:ltr\:-m-3\.5 {
    margin: -0.35rem
  }

  [dir='ltr'] .xl\:ltr\:my-0,[dir='ltr'].xl\:ltr\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  [dir='ltr'] .xl\:ltr\:mx-0,[dir='ltr'].xl\:ltr\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  [dir='ltr'] .xl\:ltr\:my-1,[dir='ltr'].xl\:ltr\:my-1 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:mx-1,[dir='ltr'].xl\:ltr\:mx-1 {
    margin-left: 0.1rem;
    margin-right: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:my-2,[dir='ltr'].xl\:ltr\:my-2 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:mx-2,[dir='ltr'].xl\:ltr\:mx-2 {
    margin-left: 0.2rem;
    margin-right: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:my-3,[dir='ltr'].xl\:ltr\:my-3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:mx-3,[dir='ltr'].xl\:ltr\:mx-3 {
    margin-left: 0.3rem;
    margin-right: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:my-4,[dir='ltr'].xl\:ltr\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:mx-4,[dir='ltr'].xl\:ltr\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:my-5,[dir='ltr'].xl\:ltr\:my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:mx-5,[dir='ltr'].xl\:ltr\:mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:my-6,[dir='ltr'].xl\:ltr\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:mx-6,[dir='ltr'].xl\:ltr\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:my-7,[dir='ltr'].xl\:ltr\:my-7 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:mx-7,[dir='ltr'].xl\:ltr\:mx-7 {
    margin-left: 0.7rem;
    margin-right: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:my-8,[dir='ltr'].xl\:ltr\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:mx-8,[dir='ltr'].xl\:ltr\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:my-9,[dir='ltr'].xl\:ltr\:my-9 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:mx-9,[dir='ltr'].xl\:ltr\:mx-9 {
    margin-left: 0.9rem;
    margin-right: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:my-10,[dir='ltr'].xl\:ltr\:my-10 {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:mx-10,[dir='ltr'].xl\:ltr\:mx-10 {
    margin-left: 1.0rem;
    margin-right: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:my-12,[dir='ltr'].xl\:ltr\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:mx-12,[dir='ltr'].xl\:ltr\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:my-14,[dir='ltr'].xl\:ltr\:my-14 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:mx-14,[dir='ltr'].xl\:ltr\:mx-14 {
    margin-left: 1.4rem;
    margin-right: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:my-16,[dir='ltr'].xl\:ltr\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:mx-16,[dir='ltr'].xl\:ltr\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:my-20,[dir='ltr'].xl\:ltr\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  [dir='ltr'] .xl\:ltr\:mx-20,[dir='ltr'].xl\:ltr\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  [dir='ltr'] .xl\:ltr\:my-24,[dir='ltr'].xl\:ltr\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:mx-24,[dir='ltr'].xl\:ltr\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:my-28,[dir='ltr'].xl\:ltr\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:mx-28,[dir='ltr'].xl\:ltr\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:my-32,[dir='ltr'].xl\:ltr\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:mx-32,[dir='ltr'].xl\:ltr\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:my-36,[dir='ltr'].xl\:ltr\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:mx-36,[dir='ltr'].xl\:ltr\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:my-40,[dir='ltr'].xl\:ltr\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  [dir='ltr'] .xl\:ltr\:mx-40,[dir='ltr'].xl\:ltr\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  [dir='ltr'] .xl\:ltr\:my-44,[dir='ltr'].xl\:ltr\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:mx-44,[dir='ltr'].xl\:ltr\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:my-48,[dir='ltr'].xl\:ltr\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:mx-48,[dir='ltr'].xl\:ltr\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:my-52,[dir='ltr'].xl\:ltr\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:mx-52,[dir='ltr'].xl\:ltr\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:my-56,[dir='ltr'].xl\:ltr\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:mx-56,[dir='ltr'].xl\:ltr\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:my-60,[dir='ltr'].xl\:ltr\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  [dir='ltr'] .xl\:ltr\:mx-60,[dir='ltr'].xl\:ltr\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  [dir='ltr'] .xl\:ltr\:my-64,[dir='ltr'].xl\:ltr\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:mx-64,[dir='ltr'].xl\:ltr\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:my-68,[dir='ltr'].xl\:ltr\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:mx-68,[dir='ltr'].xl\:ltr\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:my-72,[dir='ltr'].xl\:ltr\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:mx-72,[dir='ltr'].xl\:ltr\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:my-76,[dir='ltr'].xl\:ltr\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:mx-76,[dir='ltr'].xl\:ltr\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:my-80,[dir='ltr'].xl\:ltr\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  [dir='ltr'] .xl\:ltr\:mx-80,[dir='ltr'].xl\:ltr\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  [dir='ltr'] .xl\:ltr\:my-84,[dir='ltr'].xl\:ltr\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:mx-84,[dir='ltr'].xl\:ltr\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:my-88,[dir='ltr'].xl\:ltr\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:mx-88,[dir='ltr'].xl\:ltr\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:my-92,[dir='ltr'].xl\:ltr\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:mx-92,[dir='ltr'].xl\:ltr\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:my-96,[dir='ltr'].xl\:ltr\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:mx-96,[dir='ltr'].xl\:ltr\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:my-128,[dir='ltr'].xl\:ltr\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:mx-128,[dir='ltr'].xl\:ltr\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:my-136,[dir='ltr'].xl\:ltr\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:mx-136,[dir='ltr'].xl\:ltr\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:my-160,[dir='ltr'].xl\:ltr\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  [dir='ltr'] .xl\:ltr\:mx-160,[dir='ltr'].xl\:ltr\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  [dir='ltr'] .xl\:ltr\:my-192,[dir='ltr'].xl\:ltr\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:mx-192,[dir='ltr'].xl\:ltr\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:my-200,[dir='ltr'].xl\:ltr\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  [dir='ltr'] .xl\:ltr\:mx-200,[dir='ltr'].xl\:ltr\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  [dir='ltr'] .xl\:ltr\:my-208,[dir='ltr'].xl\:ltr\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:mx-208,[dir='ltr'].xl\:ltr\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:my-216,[dir='ltr'].xl\:ltr\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:mx-216,[dir='ltr'].xl\:ltr\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:my-224,[dir='ltr'].xl\:ltr\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:mx-224,[dir='ltr'].xl\:ltr\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:my-256,[dir='ltr'].xl\:ltr\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:mx-256,[dir='ltr'].xl\:ltr\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:my-288,[dir='ltr'].xl\:ltr\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:mx-288,[dir='ltr'].xl\:ltr\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:my-320,[dir='ltr'].xl\:ltr\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='ltr'] .xl\:ltr\:mx-320,[dir='ltr'].xl\:ltr\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='ltr'] .xl\:ltr\:my-360,[dir='ltr'].xl\:ltr\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  [dir='ltr'] .xl\:ltr\:mx-360,[dir='ltr'].xl\:ltr\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  [dir='ltr'] .xl\:ltr\:my-384,[dir='ltr'].xl\:ltr\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:mx-384,[dir='ltr'].xl\:ltr\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:my-400,[dir='ltr'].xl\:ltr\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  [dir='ltr'] .xl\:ltr\:mx-400,[dir='ltr'].xl\:ltr\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  [dir='ltr'] .xl\:ltr\:my-512,[dir='ltr'].xl\:ltr\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:mx-512,[dir='ltr'].xl\:ltr\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:my-640,[dir='ltr'].xl\:ltr\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='ltr'] .xl\:ltr\:mx-640,[dir='ltr'].xl\:ltr\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='ltr'] .xl\:ltr\:my-auto,[dir='ltr'].xl\:ltr\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  [dir='ltr'] .xl\:ltr\:mx-auto,[dir='ltr'].xl\:ltr\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  [dir='ltr'] .xl\:ltr\:my-xs,[dir='ltr'].xl\:ltr\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='ltr'] .xl\:ltr\:mx-xs,[dir='ltr'].xl\:ltr\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='ltr'] .xl\:ltr\:my-sm,[dir='ltr'].xl\:ltr\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  [dir='ltr'] .xl\:ltr\:mx-sm,[dir='ltr'].xl\:ltr\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  [dir='ltr'] .xl\:ltr\:my-md,[dir='ltr'].xl\:ltr\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='ltr'] .xl\:ltr\:mx-md,[dir='ltr'].xl\:ltr\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='ltr'] .xl\:ltr\:my-lg,[dir='ltr'].xl\:ltr\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  [dir='ltr'] .xl\:ltr\:mx-lg,[dir='ltr'].xl\:ltr\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  [dir='ltr'] .xl\:ltr\:my-xl,[dir='ltr'].xl\:ltr\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  [dir='ltr'] .xl\:ltr\:mx-xl,[dir='ltr'].xl\:ltr\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  [dir='ltr'] .xl\:ltr\:my-2xl,[dir='ltr'].xl\:ltr\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  [dir='ltr'] .xl\:ltr\:mx-2xl,[dir='ltr'].xl\:ltr\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  [dir='ltr'] .xl\:ltr\:my-3xl,[dir='ltr'].xl\:ltr\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  [dir='ltr'] .xl\:ltr\:mx-3xl,[dir='ltr'].xl\:ltr\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  [dir='ltr'] .xl\:ltr\:my-4xl,[dir='ltr'].xl\:ltr\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  [dir='ltr'] .xl\:ltr\:mx-4xl,[dir='ltr'].xl\:ltr\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  [dir='ltr'] .xl\:ltr\:my-5xl,[dir='ltr'].xl\:ltr\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  [dir='ltr'] .xl\:ltr\:mx-5xl,[dir='ltr'].xl\:ltr\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  [dir='ltr'] .xl\:ltr\:my-px,[dir='ltr'].xl\:ltr\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  [dir='ltr'] .xl\:ltr\:mx-px,[dir='ltr'].xl\:ltr\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  [dir='ltr'] .xl\:ltr\:my-0\.5,[dir='ltr'].xl\:ltr\:my-0\.5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:mx-0\.5,[dir='ltr'].xl\:ltr\:mx-0\.5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:my-1\.5,[dir='ltr'].xl\:ltr\:my-1\.5 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:mx-1\.5,[dir='ltr'].xl\:ltr\:mx-1\.5 {
    margin-left: 0.15rem;
    margin-right: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:my-2\.5,[dir='ltr'].xl\:ltr\:my-2\.5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:mx-2\.5,[dir='ltr'].xl\:ltr\:mx-2\.5 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:my-3\.5,[dir='ltr'].xl\:ltr\:my-3\.5 {
    margin-top: 0.35rem;
    margin-bottom: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:mx-3\.5,[dir='ltr'].xl\:ltr\:mx-3\.5 {
    margin-left: 0.35rem;
    margin-right: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:-my-1,[dir='ltr'].xl\:ltr\:-my-1 {
    margin-top: -0.1rem;
    margin-bottom: -0.1rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-1,[dir='ltr'].xl\:ltr\:-mx-1 {
    margin-left: -0.1rem;
    margin-right: -0.1rem
  }

  [dir='ltr'] .xl\:ltr\:-my-2,[dir='ltr'].xl\:ltr\:-my-2 {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-2,[dir='ltr'].xl\:ltr\:-mx-2 {
    margin-left: -0.2rem;
    margin-right: -0.2rem
  }

  [dir='ltr'] .xl\:ltr\:-my-3,[dir='ltr'].xl\:ltr\:-my-3 {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-3,[dir='ltr'].xl\:ltr\:-mx-3 {
    margin-left: -0.3rem;
    margin-right: -0.3rem
  }

  [dir='ltr'] .xl\:ltr\:-my-4,[dir='ltr'].xl\:ltr\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-4,[dir='ltr'].xl\:ltr\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  [dir='ltr'] .xl\:ltr\:-my-5,[dir='ltr'].xl\:ltr\:-my-5 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-5,[dir='ltr'].xl\:ltr\:-mx-5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  [dir='ltr'] .xl\:ltr\:-my-6,[dir='ltr'].xl\:ltr\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-6,[dir='ltr'].xl\:ltr\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  [dir='ltr'] .xl\:ltr\:-my-7,[dir='ltr'].xl\:ltr\:-my-7 {
    margin-top: -0.7rem;
    margin-bottom: -0.7rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-7,[dir='ltr'].xl\:ltr\:-mx-7 {
    margin-left: -0.7rem;
    margin-right: -0.7rem
  }

  [dir='ltr'] .xl\:ltr\:-my-8,[dir='ltr'].xl\:ltr\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-8,[dir='ltr'].xl\:ltr\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  [dir='ltr'] .xl\:ltr\:-my-9,[dir='ltr'].xl\:ltr\:-my-9 {
    margin-top: -0.9rem;
    margin-bottom: -0.9rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-9,[dir='ltr'].xl\:ltr\:-mx-9 {
    margin-left: -0.9rem;
    margin-right: -0.9rem
  }

  [dir='ltr'] .xl\:ltr\:-my-10,[dir='ltr'].xl\:ltr\:-my-10 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-10,[dir='ltr'].xl\:ltr\:-mx-10 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  [dir='ltr'] .xl\:ltr\:-my-12,[dir='ltr'].xl\:ltr\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-12,[dir='ltr'].xl\:ltr\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  [dir='ltr'] .xl\:ltr\:-my-14,[dir='ltr'].xl\:ltr\:-my-14 {
    margin-top: -1.4rem;
    margin-bottom: -1.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-14,[dir='ltr'].xl\:ltr\:-mx-14 {
    margin-left: -1.4rem;
    margin-right: -1.4rem
  }

  [dir='ltr'] .xl\:ltr\:-my-16,[dir='ltr'].xl\:ltr\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-16,[dir='ltr'].xl\:ltr\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  [dir='ltr'] .xl\:ltr\:-my-20,[dir='ltr'].xl\:ltr\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-20,[dir='ltr'].xl\:ltr\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  [dir='ltr'] .xl\:ltr\:-my-24,[dir='ltr'].xl\:ltr\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-24,[dir='ltr'].xl\:ltr\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  [dir='ltr'] .xl\:ltr\:-my-28,[dir='ltr'].xl\:ltr\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-28,[dir='ltr'].xl\:ltr\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  [dir='ltr'] .xl\:ltr\:-my-32,[dir='ltr'].xl\:ltr\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-32,[dir='ltr'].xl\:ltr\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  [dir='ltr'] .xl\:ltr\:-my-36,[dir='ltr'].xl\:ltr\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-36,[dir='ltr'].xl\:ltr\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  [dir='ltr'] .xl\:ltr\:-my-40,[dir='ltr'].xl\:ltr\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-40,[dir='ltr'].xl\:ltr\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  [dir='ltr'] .xl\:ltr\:-my-44,[dir='ltr'].xl\:ltr\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-44,[dir='ltr'].xl\:ltr\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  [dir='ltr'] .xl\:ltr\:-my-48,[dir='ltr'].xl\:ltr\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-48,[dir='ltr'].xl\:ltr\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  [dir='ltr'] .xl\:ltr\:-my-52,[dir='ltr'].xl\:ltr\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-52,[dir='ltr'].xl\:ltr\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  [dir='ltr'] .xl\:ltr\:-my-56,[dir='ltr'].xl\:ltr\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-56,[dir='ltr'].xl\:ltr\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  [dir='ltr'] .xl\:ltr\:-my-60,[dir='ltr'].xl\:ltr\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-60,[dir='ltr'].xl\:ltr\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  [dir='ltr'] .xl\:ltr\:-my-64,[dir='ltr'].xl\:ltr\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-64,[dir='ltr'].xl\:ltr\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  [dir='ltr'] .xl\:ltr\:-my-68,[dir='ltr'].xl\:ltr\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-68,[dir='ltr'].xl\:ltr\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  [dir='ltr'] .xl\:ltr\:-my-72,[dir='ltr'].xl\:ltr\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-72,[dir='ltr'].xl\:ltr\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  [dir='ltr'] .xl\:ltr\:-my-76,[dir='ltr'].xl\:ltr\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-76,[dir='ltr'].xl\:ltr\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  [dir='ltr'] .xl\:ltr\:-my-80,[dir='ltr'].xl\:ltr\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-80,[dir='ltr'].xl\:ltr\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  [dir='ltr'] .xl\:ltr\:-my-84,[dir='ltr'].xl\:ltr\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-84,[dir='ltr'].xl\:ltr\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  [dir='ltr'] .xl\:ltr\:-my-88,[dir='ltr'].xl\:ltr\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-88,[dir='ltr'].xl\:ltr\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  [dir='ltr'] .xl\:ltr\:-my-92,[dir='ltr'].xl\:ltr\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-92,[dir='ltr'].xl\:ltr\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  [dir='ltr'] .xl\:ltr\:-my-96,[dir='ltr'].xl\:ltr\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-96,[dir='ltr'].xl\:ltr\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  [dir='ltr'] .xl\:ltr\:-my-128,[dir='ltr'].xl\:ltr\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-128,[dir='ltr'].xl\:ltr\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  [dir='ltr'] .xl\:ltr\:-my-136,[dir='ltr'].xl\:ltr\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-136,[dir='ltr'].xl\:ltr\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  [dir='ltr'] .xl\:ltr\:-my-160,[dir='ltr'].xl\:ltr\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-160,[dir='ltr'].xl\:ltr\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  [dir='ltr'] .xl\:ltr\:-my-192,[dir='ltr'].xl\:ltr\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-192,[dir='ltr'].xl\:ltr\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  [dir='ltr'] .xl\:ltr\:-my-200,[dir='ltr'].xl\:ltr\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-200,[dir='ltr'].xl\:ltr\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  [dir='ltr'] .xl\:ltr\:-my-208,[dir='ltr'].xl\:ltr\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-208,[dir='ltr'].xl\:ltr\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  [dir='ltr'] .xl\:ltr\:-my-216,[dir='ltr'].xl\:ltr\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-216,[dir='ltr'].xl\:ltr\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  [dir='ltr'] .xl\:ltr\:-my-224,[dir='ltr'].xl\:ltr\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-224,[dir='ltr'].xl\:ltr\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  [dir='ltr'] .xl\:ltr\:-my-256,[dir='ltr'].xl\:ltr\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-256,[dir='ltr'].xl\:ltr\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  [dir='ltr'] .xl\:ltr\:-my-288,[dir='ltr'].xl\:ltr\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-288,[dir='ltr'].xl\:ltr\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  [dir='ltr'] .xl\:ltr\:-my-320,[dir='ltr'].xl\:ltr\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-320,[dir='ltr'].xl\:ltr\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-my-360,[dir='ltr'].xl\:ltr\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-360,[dir='ltr'].xl\:ltr\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  [dir='ltr'] .xl\:ltr\:-my-384,[dir='ltr'].xl\:ltr\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-384,[dir='ltr'].xl\:ltr\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  [dir='ltr'] .xl\:ltr\:-my-400,[dir='ltr'].xl\:ltr\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-400,[dir='ltr'].xl\:ltr\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  [dir='ltr'] .xl\:ltr\:-my-512,[dir='ltr'].xl\:ltr\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-512,[dir='ltr'].xl\:ltr\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  [dir='ltr'] .xl\:ltr\:-my-640,[dir='ltr'].xl\:ltr\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-640,[dir='ltr'].xl\:ltr\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-my-xs,[dir='ltr'].xl\:ltr\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-xs,[dir='ltr'].xl\:ltr\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-my-sm,[dir='ltr'].xl\:ltr\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-sm,[dir='ltr'].xl\:ltr\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  [dir='ltr'] .xl\:ltr\:-my-md,[dir='ltr'].xl\:ltr\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-md,[dir='ltr'].xl\:ltr\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-my-lg,[dir='ltr'].xl\:ltr\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-lg,[dir='ltr'].xl\:ltr\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  [dir='ltr'] .xl\:ltr\:-my-xl,[dir='ltr'].xl\:ltr\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-xl,[dir='ltr'].xl\:ltr\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  [dir='ltr'] .xl\:ltr\:-my-2xl,[dir='ltr'].xl\:ltr\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-2xl,[dir='ltr'].xl\:ltr\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  [dir='ltr'] .xl\:ltr\:-my-3xl,[dir='ltr'].xl\:ltr\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-3xl,[dir='ltr'].xl\:ltr\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  [dir='ltr'] .xl\:ltr\:-my-4xl,[dir='ltr'].xl\:ltr\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-4xl,[dir='ltr'].xl\:ltr\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  [dir='ltr'] .xl\:ltr\:-my-5xl,[dir='ltr'].xl\:ltr\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-5xl,[dir='ltr'].xl\:ltr\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  [dir='ltr'] .xl\:ltr\:-my-px,[dir='ltr'].xl\:ltr\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  [dir='ltr'] .xl\:ltr\:-mx-px,[dir='ltr'].xl\:ltr\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  [dir='ltr'] .xl\:ltr\:-my-0\.5,[dir='ltr'].xl\:ltr\:-my-0\.5 {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-0\.5,[dir='ltr'].xl\:ltr\:-mx-0\.5 {
    margin-left: -0.05rem;
    margin-right: -0.05rem
  }

  [dir='ltr'] .xl\:ltr\:-my-1\.5,[dir='ltr'].xl\:ltr\:-my-1\.5 {
    margin-top: -0.15rem;
    margin-bottom: -0.15rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-1\.5,[dir='ltr'].xl\:ltr\:-mx-1\.5 {
    margin-left: -0.15rem;
    margin-right: -0.15rem
  }

  [dir='ltr'] .xl\:ltr\:-my-2\.5,[dir='ltr'].xl\:ltr\:-my-2\.5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-2\.5,[dir='ltr'].xl\:ltr\:-mx-2\.5 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  [dir='ltr'] .xl\:ltr\:-my-3\.5,[dir='ltr'].xl\:ltr\:-my-3\.5 {
    margin-top: -0.35rem;
    margin-bottom: -0.35rem
  }

  [dir='ltr'] .xl\:ltr\:-mx-3\.5,[dir='ltr'].xl\:ltr\:-mx-3\.5 {
    margin-left: -0.35rem;
    margin-right: -0.35rem
  }

  [dir='ltr'] .xl\:ltr\:mt-0,[dir='ltr'].xl\:ltr\:mt-0 {
    margin-top: 0
  }

  [dir='ltr'] .xl\:ltr\:mr-0,[dir='ltr'].xl\:ltr\:mr-0 {
    margin-right: 0
  }

  [dir='ltr'] .xl\:ltr\:mb-0,[dir='ltr'].xl\:ltr\:mb-0 {
    margin-bottom: 0
  }

  [dir='ltr'] .xl\:ltr\:ml-0,[dir='ltr'].xl\:ltr\:ml-0 {
    margin-left: 0
  }

  [dir='ltr'] .xl\:ltr\:mt-1,[dir='ltr'].xl\:ltr\:mt-1 {
    margin-top: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:mr-1,[dir='ltr'].xl\:ltr\:mr-1 {
    margin-right: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:mb-1,[dir='ltr'].xl\:ltr\:mb-1 {
    margin-bottom: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:ml-1,[dir='ltr'].xl\:ltr\:ml-1 {
    margin-left: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:mt-2,[dir='ltr'].xl\:ltr\:mt-2 {
    margin-top: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:mr-2,[dir='ltr'].xl\:ltr\:mr-2 {
    margin-right: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:mb-2,[dir='ltr'].xl\:ltr\:mb-2 {
    margin-bottom: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:ml-2,[dir='ltr'].xl\:ltr\:ml-2 {
    margin-left: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:mt-3,[dir='ltr'].xl\:ltr\:mt-3 {
    margin-top: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:mr-3,[dir='ltr'].xl\:ltr\:mr-3 {
    margin-right: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:mb-3,[dir='ltr'].xl\:ltr\:mb-3 {
    margin-bottom: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:ml-3,[dir='ltr'].xl\:ltr\:ml-3 {
    margin-left: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:mt-4,[dir='ltr'].xl\:ltr\:mt-4 {
    margin-top: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:mr-4,[dir='ltr'].xl\:ltr\:mr-4 {
    margin-right: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:mb-4,[dir='ltr'].xl\:ltr\:mb-4 {
    margin-bottom: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:ml-4,[dir='ltr'].xl\:ltr\:ml-4 {
    margin-left: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:mt-5,[dir='ltr'].xl\:ltr\:mt-5 {
    margin-top: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:mr-5,[dir='ltr'].xl\:ltr\:mr-5 {
    margin-right: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:mb-5,[dir='ltr'].xl\:ltr\:mb-5 {
    margin-bottom: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:ml-5,[dir='ltr'].xl\:ltr\:ml-5 {
    margin-left: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:mt-6,[dir='ltr'].xl\:ltr\:mt-6 {
    margin-top: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:mr-6,[dir='ltr'].xl\:ltr\:mr-6 {
    margin-right: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:mb-6,[dir='ltr'].xl\:ltr\:mb-6 {
    margin-bottom: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:ml-6,[dir='ltr'].xl\:ltr\:ml-6 {
    margin-left: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:mt-7,[dir='ltr'].xl\:ltr\:mt-7 {
    margin-top: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:mr-7,[dir='ltr'].xl\:ltr\:mr-7 {
    margin-right: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:mb-7,[dir='ltr'].xl\:ltr\:mb-7 {
    margin-bottom: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:ml-7,[dir='ltr'].xl\:ltr\:ml-7 {
    margin-left: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:mt-8,[dir='ltr'].xl\:ltr\:mt-8 {
    margin-top: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:mr-8,[dir='ltr'].xl\:ltr\:mr-8 {
    margin-right: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:mb-8,[dir='ltr'].xl\:ltr\:mb-8 {
    margin-bottom: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:ml-8,[dir='ltr'].xl\:ltr\:ml-8 {
    margin-left: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:mt-9,[dir='ltr'].xl\:ltr\:mt-9 {
    margin-top: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:mr-9,[dir='ltr'].xl\:ltr\:mr-9 {
    margin-right: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:mb-9,[dir='ltr'].xl\:ltr\:mb-9 {
    margin-bottom: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:ml-9,[dir='ltr'].xl\:ltr\:ml-9 {
    margin-left: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:mt-10,[dir='ltr'].xl\:ltr\:mt-10 {
    margin-top: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:mr-10,[dir='ltr'].xl\:ltr\:mr-10 {
    margin-right: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:mb-10,[dir='ltr'].xl\:ltr\:mb-10 {
    margin-bottom: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:ml-10,[dir='ltr'].xl\:ltr\:ml-10 {
    margin-left: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:mt-12,[dir='ltr'].xl\:ltr\:mt-12 {
    margin-top: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:mr-12,[dir='ltr'].xl\:ltr\:mr-12 {
    margin-right: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:mb-12,[dir='ltr'].xl\:ltr\:mb-12 {
    margin-bottom: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:ml-12,[dir='ltr'].xl\:ltr\:ml-12 {
    margin-left: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:mt-14,[dir='ltr'].xl\:ltr\:mt-14 {
    margin-top: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:mr-14,[dir='ltr'].xl\:ltr\:mr-14 {
    margin-right: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:mb-14,[dir='ltr'].xl\:ltr\:mb-14 {
    margin-bottom: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:ml-14,[dir='ltr'].xl\:ltr\:ml-14 {
    margin-left: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:mt-16,[dir='ltr'].xl\:ltr\:mt-16 {
    margin-top: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:mr-16,[dir='ltr'].xl\:ltr\:mr-16 {
    margin-right: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:mb-16,[dir='ltr'].xl\:ltr\:mb-16 {
    margin-bottom: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:ml-16,[dir='ltr'].xl\:ltr\:ml-16 {
    margin-left: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:mt-20,[dir='ltr'].xl\:ltr\:mt-20 {
    margin-top: 2rem
  }

  [dir='ltr'] .xl\:ltr\:mr-20,[dir='ltr'].xl\:ltr\:mr-20 {
    margin-right: 2rem
  }

  [dir='ltr'] .xl\:ltr\:mb-20,[dir='ltr'].xl\:ltr\:mb-20 {
    margin-bottom: 2rem
  }

  [dir='ltr'] .xl\:ltr\:ml-20,[dir='ltr'].xl\:ltr\:ml-20 {
    margin-left: 2rem
  }

  [dir='ltr'] .xl\:ltr\:mt-24,[dir='ltr'].xl\:ltr\:mt-24 {
    margin-top: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:mr-24,[dir='ltr'].xl\:ltr\:mr-24 {
    margin-right: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:mb-24,[dir='ltr'].xl\:ltr\:mb-24 {
    margin-bottom: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:ml-24,[dir='ltr'].xl\:ltr\:ml-24 {
    margin-left: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:mt-28,[dir='ltr'].xl\:ltr\:mt-28 {
    margin-top: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:mr-28,[dir='ltr'].xl\:ltr\:mr-28 {
    margin-right: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:mb-28,[dir='ltr'].xl\:ltr\:mb-28 {
    margin-bottom: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:ml-28,[dir='ltr'].xl\:ltr\:ml-28 {
    margin-left: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:mt-32,[dir='ltr'].xl\:ltr\:mt-32 {
    margin-top: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:mr-32,[dir='ltr'].xl\:ltr\:mr-32 {
    margin-right: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:mb-32,[dir='ltr'].xl\:ltr\:mb-32 {
    margin-bottom: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:ml-32,[dir='ltr'].xl\:ltr\:ml-32 {
    margin-left: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:mt-36,[dir='ltr'].xl\:ltr\:mt-36 {
    margin-top: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:mr-36,[dir='ltr'].xl\:ltr\:mr-36 {
    margin-right: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:mb-36,[dir='ltr'].xl\:ltr\:mb-36 {
    margin-bottom: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:ml-36,[dir='ltr'].xl\:ltr\:ml-36 {
    margin-left: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:mt-40,[dir='ltr'].xl\:ltr\:mt-40 {
    margin-top: 4rem
  }

  [dir='ltr'] .xl\:ltr\:mr-40,[dir='ltr'].xl\:ltr\:mr-40 {
    margin-right: 4rem
  }

  [dir='ltr'] .xl\:ltr\:mb-40,[dir='ltr'].xl\:ltr\:mb-40 {
    margin-bottom: 4rem
  }

  [dir='ltr'] .xl\:ltr\:ml-40,[dir='ltr'].xl\:ltr\:ml-40 {
    margin-left: 4rem
  }

  [dir='ltr'] .xl\:ltr\:mt-44,[dir='ltr'].xl\:ltr\:mt-44 {
    margin-top: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:mr-44,[dir='ltr'].xl\:ltr\:mr-44 {
    margin-right: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:mb-44,[dir='ltr'].xl\:ltr\:mb-44 {
    margin-bottom: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:ml-44,[dir='ltr'].xl\:ltr\:ml-44 {
    margin-left: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:mt-48,[dir='ltr'].xl\:ltr\:mt-48 {
    margin-top: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:mr-48,[dir='ltr'].xl\:ltr\:mr-48 {
    margin-right: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:mb-48,[dir='ltr'].xl\:ltr\:mb-48 {
    margin-bottom: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:ml-48,[dir='ltr'].xl\:ltr\:ml-48 {
    margin-left: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:mt-52,[dir='ltr'].xl\:ltr\:mt-52 {
    margin-top: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:mr-52,[dir='ltr'].xl\:ltr\:mr-52 {
    margin-right: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:mb-52,[dir='ltr'].xl\:ltr\:mb-52 {
    margin-bottom: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:ml-52,[dir='ltr'].xl\:ltr\:ml-52 {
    margin-left: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:mt-56,[dir='ltr'].xl\:ltr\:mt-56 {
    margin-top: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:mr-56,[dir='ltr'].xl\:ltr\:mr-56 {
    margin-right: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:mb-56,[dir='ltr'].xl\:ltr\:mb-56 {
    margin-bottom: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:ml-56,[dir='ltr'].xl\:ltr\:ml-56 {
    margin-left: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:mt-60,[dir='ltr'].xl\:ltr\:mt-60 {
    margin-top: 6rem
  }

  [dir='ltr'] .xl\:ltr\:mr-60,[dir='ltr'].xl\:ltr\:mr-60 {
    margin-right: 6rem
  }

  [dir='ltr'] .xl\:ltr\:mb-60,[dir='ltr'].xl\:ltr\:mb-60 {
    margin-bottom: 6rem
  }

  [dir='ltr'] .xl\:ltr\:ml-60,[dir='ltr'].xl\:ltr\:ml-60 {
    margin-left: 6rem
  }

  [dir='ltr'] .xl\:ltr\:mt-64,[dir='ltr'].xl\:ltr\:mt-64 {
    margin-top: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:mr-64,[dir='ltr'].xl\:ltr\:mr-64 {
    margin-right: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:mb-64,[dir='ltr'].xl\:ltr\:mb-64 {
    margin-bottom: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:ml-64,[dir='ltr'].xl\:ltr\:ml-64 {
    margin-left: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:mt-68,[dir='ltr'].xl\:ltr\:mt-68 {
    margin-top: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:mr-68,[dir='ltr'].xl\:ltr\:mr-68 {
    margin-right: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:mb-68,[dir='ltr'].xl\:ltr\:mb-68 {
    margin-bottom: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:ml-68,[dir='ltr'].xl\:ltr\:ml-68 {
    margin-left: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:mt-72,[dir='ltr'].xl\:ltr\:mt-72 {
    margin-top: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:mr-72,[dir='ltr'].xl\:ltr\:mr-72 {
    margin-right: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:mb-72,[dir='ltr'].xl\:ltr\:mb-72 {
    margin-bottom: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:ml-72,[dir='ltr'].xl\:ltr\:ml-72 {
    margin-left: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:mt-76,[dir='ltr'].xl\:ltr\:mt-76 {
    margin-top: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:mr-76,[dir='ltr'].xl\:ltr\:mr-76 {
    margin-right: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:mb-76,[dir='ltr'].xl\:ltr\:mb-76 {
    margin-bottom: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:ml-76,[dir='ltr'].xl\:ltr\:ml-76 {
    margin-left: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:mt-80,[dir='ltr'].xl\:ltr\:mt-80 {
    margin-top: 8rem
  }

  [dir='ltr'] .xl\:ltr\:mr-80,[dir='ltr'].xl\:ltr\:mr-80 {
    margin-right: 8rem
  }

  [dir='ltr'] .xl\:ltr\:mb-80,[dir='ltr'].xl\:ltr\:mb-80 {
    margin-bottom: 8rem
  }

  [dir='ltr'] .xl\:ltr\:ml-80,[dir='ltr'].xl\:ltr\:ml-80 {
    margin-left: 8rem
  }

  [dir='ltr'] .xl\:ltr\:mt-84,[dir='ltr'].xl\:ltr\:mt-84 {
    margin-top: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:mr-84,[dir='ltr'].xl\:ltr\:mr-84 {
    margin-right: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:mb-84,[dir='ltr'].xl\:ltr\:mb-84 {
    margin-bottom: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:ml-84,[dir='ltr'].xl\:ltr\:ml-84 {
    margin-left: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:mt-88,[dir='ltr'].xl\:ltr\:mt-88 {
    margin-top: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:mr-88,[dir='ltr'].xl\:ltr\:mr-88 {
    margin-right: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:mb-88,[dir='ltr'].xl\:ltr\:mb-88 {
    margin-bottom: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:ml-88,[dir='ltr'].xl\:ltr\:ml-88 {
    margin-left: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:mt-92,[dir='ltr'].xl\:ltr\:mt-92 {
    margin-top: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:mr-92,[dir='ltr'].xl\:ltr\:mr-92 {
    margin-right: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:mb-92,[dir='ltr'].xl\:ltr\:mb-92 {
    margin-bottom: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:ml-92,[dir='ltr'].xl\:ltr\:ml-92 {
    margin-left: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:mt-96,[dir='ltr'].xl\:ltr\:mt-96 {
    margin-top: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:mr-96,[dir='ltr'].xl\:ltr\:mr-96 {
    margin-right: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:mb-96,[dir='ltr'].xl\:ltr\:mb-96 {
    margin-bottom: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:ml-96,[dir='ltr'].xl\:ltr\:ml-96 {
    margin-left: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:mt-128,[dir='ltr'].xl\:ltr\:mt-128 {
    margin-top: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:mr-128,[dir='ltr'].xl\:ltr\:mr-128 {
    margin-right: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:mb-128,[dir='ltr'].xl\:ltr\:mb-128 {
    margin-bottom: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:ml-128,[dir='ltr'].xl\:ltr\:ml-128 {
    margin-left: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:mt-136,[dir='ltr'].xl\:ltr\:mt-136 {
    margin-top: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:mr-136,[dir='ltr'].xl\:ltr\:mr-136 {
    margin-right: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:mb-136,[dir='ltr'].xl\:ltr\:mb-136 {
    margin-bottom: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:ml-136,[dir='ltr'].xl\:ltr\:ml-136 {
    margin-left: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:mt-160,[dir='ltr'].xl\:ltr\:mt-160 {
    margin-top: 16rem
  }

  [dir='ltr'] .xl\:ltr\:mr-160,[dir='ltr'].xl\:ltr\:mr-160 {
    margin-right: 16rem
  }

  [dir='ltr'] .xl\:ltr\:mb-160,[dir='ltr'].xl\:ltr\:mb-160 {
    margin-bottom: 16rem
  }

  [dir='ltr'] .xl\:ltr\:ml-160,[dir='ltr'].xl\:ltr\:ml-160 {
    margin-left: 16rem
  }

  [dir='ltr'] .xl\:ltr\:mt-192,[dir='ltr'].xl\:ltr\:mt-192 {
    margin-top: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:mr-192,[dir='ltr'].xl\:ltr\:mr-192 {
    margin-right: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:mb-192,[dir='ltr'].xl\:ltr\:mb-192 {
    margin-bottom: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:ml-192,[dir='ltr'].xl\:ltr\:ml-192 {
    margin-left: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:mt-200,[dir='ltr'].xl\:ltr\:mt-200 {
    margin-top: 20rem
  }

  [dir='ltr'] .xl\:ltr\:mr-200,[dir='ltr'].xl\:ltr\:mr-200 {
    margin-right: 20rem
  }

  [dir='ltr'] .xl\:ltr\:mb-200,[dir='ltr'].xl\:ltr\:mb-200 {
    margin-bottom: 20rem
  }

  [dir='ltr'] .xl\:ltr\:ml-200,[dir='ltr'].xl\:ltr\:ml-200 {
    margin-left: 20rem
  }

  [dir='ltr'] .xl\:ltr\:mt-208,[dir='ltr'].xl\:ltr\:mt-208 {
    margin-top: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:mr-208,[dir='ltr'].xl\:ltr\:mr-208 {
    margin-right: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:mb-208,[dir='ltr'].xl\:ltr\:mb-208 {
    margin-bottom: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:ml-208,[dir='ltr'].xl\:ltr\:ml-208 {
    margin-left: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:mt-216,[dir='ltr'].xl\:ltr\:mt-216 {
    margin-top: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:mr-216,[dir='ltr'].xl\:ltr\:mr-216 {
    margin-right: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:mb-216,[dir='ltr'].xl\:ltr\:mb-216 {
    margin-bottom: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:ml-216,[dir='ltr'].xl\:ltr\:ml-216 {
    margin-left: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:mt-224,[dir='ltr'].xl\:ltr\:mt-224 {
    margin-top: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:mr-224,[dir='ltr'].xl\:ltr\:mr-224 {
    margin-right: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:mb-224,[dir='ltr'].xl\:ltr\:mb-224 {
    margin-bottom: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:ml-224,[dir='ltr'].xl\:ltr\:ml-224 {
    margin-left: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:mt-256,[dir='ltr'].xl\:ltr\:mt-256 {
    margin-top: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:mr-256,[dir='ltr'].xl\:ltr\:mr-256 {
    margin-right: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:mb-256,[dir='ltr'].xl\:ltr\:mb-256 {
    margin-bottom: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:ml-256,[dir='ltr'].xl\:ltr\:ml-256 {
    margin-left: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:mt-288,[dir='ltr'].xl\:ltr\:mt-288 {
    margin-top: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:mr-288,[dir='ltr'].xl\:ltr\:mr-288 {
    margin-right: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:mb-288,[dir='ltr'].xl\:ltr\:mb-288 {
    margin-bottom: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:ml-288,[dir='ltr'].xl\:ltr\:ml-288 {
    margin-left: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:mt-320,[dir='ltr'].xl\:ltr\:mt-320 {
    margin-top: 32rem
  }

  [dir='ltr'] .xl\:ltr\:mr-320,[dir='ltr'].xl\:ltr\:mr-320 {
    margin-right: 32rem
  }

  [dir='ltr'] .xl\:ltr\:mb-320,[dir='ltr'].xl\:ltr\:mb-320 {
    margin-bottom: 32rem
  }

  [dir='ltr'] .xl\:ltr\:ml-320,[dir='ltr'].xl\:ltr\:ml-320 {
    margin-left: 32rem
  }

  [dir='ltr'] .xl\:ltr\:mt-360,[dir='ltr'].xl\:ltr\:mt-360 {
    margin-top: 36rem
  }

  [dir='ltr'] .xl\:ltr\:mr-360,[dir='ltr'].xl\:ltr\:mr-360 {
    margin-right: 36rem
  }

  [dir='ltr'] .xl\:ltr\:mb-360,[dir='ltr'].xl\:ltr\:mb-360 {
    margin-bottom: 36rem
  }

  [dir='ltr'] .xl\:ltr\:ml-360,[dir='ltr'].xl\:ltr\:ml-360 {
    margin-left: 36rem
  }

  [dir='ltr'] .xl\:ltr\:mt-384,[dir='ltr'].xl\:ltr\:mt-384 {
    margin-top: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:mr-384,[dir='ltr'].xl\:ltr\:mr-384 {
    margin-right: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:mb-384,[dir='ltr'].xl\:ltr\:mb-384 {
    margin-bottom: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:ml-384,[dir='ltr'].xl\:ltr\:ml-384 {
    margin-left: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:mt-400,[dir='ltr'].xl\:ltr\:mt-400 {
    margin-top: 40rem
  }

  [dir='ltr'] .xl\:ltr\:mr-400,[dir='ltr'].xl\:ltr\:mr-400 {
    margin-right: 40rem
  }

  [dir='ltr'] .xl\:ltr\:mb-400,[dir='ltr'].xl\:ltr\:mb-400 {
    margin-bottom: 40rem
  }

  [dir='ltr'] .xl\:ltr\:ml-400,[dir='ltr'].xl\:ltr\:ml-400 {
    margin-left: 40rem
  }

  [dir='ltr'] .xl\:ltr\:mt-512,[dir='ltr'].xl\:ltr\:mt-512 {
    margin-top: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:mr-512,[dir='ltr'].xl\:ltr\:mr-512 {
    margin-right: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:mb-512,[dir='ltr'].xl\:ltr\:mb-512 {
    margin-bottom: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:ml-512,[dir='ltr'].xl\:ltr\:ml-512 {
    margin-left: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:mt-640,[dir='ltr'].xl\:ltr\:mt-640 {
    margin-top: 64rem
  }

  [dir='ltr'] .xl\:ltr\:mr-640,[dir='ltr'].xl\:ltr\:mr-640 {
    margin-right: 64rem
  }

  [dir='ltr'] .xl\:ltr\:mb-640,[dir='ltr'].xl\:ltr\:mb-640 {
    margin-bottom: 64rem
  }

  [dir='ltr'] .xl\:ltr\:ml-640,[dir='ltr'].xl\:ltr\:ml-640 {
    margin-left: 64rem
  }

  [dir='ltr'] .xl\:ltr\:mt-auto,[dir='ltr'].xl\:ltr\:mt-auto {
    margin-top: auto
  }

  [dir='ltr'] .xl\:ltr\:mr-auto,[dir='ltr'].xl\:ltr\:mr-auto {
    margin-right: auto
  }

  [dir='ltr'] .xl\:ltr\:mb-auto,[dir='ltr'].xl\:ltr\:mb-auto {
    margin-bottom: auto
  }

  [dir='ltr'] .xl\:ltr\:ml-auto,[dir='ltr'].xl\:ltr\:ml-auto {
    margin-left: auto
  }

  [dir='ltr'] .xl\:ltr\:mt-xs,[dir='ltr'].xl\:ltr\:mt-xs {
    margin-top: 32rem
  }

  [dir='ltr'] .xl\:ltr\:mr-xs,[dir='ltr'].xl\:ltr\:mr-xs {
    margin-right: 32rem
  }

  [dir='ltr'] .xl\:ltr\:mb-xs,[dir='ltr'].xl\:ltr\:mb-xs {
    margin-bottom: 32rem
  }

  [dir='ltr'] .xl\:ltr\:ml-xs,[dir='ltr'].xl\:ltr\:ml-xs {
    margin-left: 32rem
  }

  [dir='ltr'] .xl\:ltr\:mt-sm,[dir='ltr'].xl\:ltr\:mt-sm {
    margin-top: 48rem
  }

  [dir='ltr'] .xl\:ltr\:mr-sm,[dir='ltr'].xl\:ltr\:mr-sm {
    margin-right: 48rem
  }

  [dir='ltr'] .xl\:ltr\:mb-sm,[dir='ltr'].xl\:ltr\:mb-sm {
    margin-bottom: 48rem
  }

  [dir='ltr'] .xl\:ltr\:ml-sm,[dir='ltr'].xl\:ltr\:ml-sm {
    margin-left: 48rem
  }

  [dir='ltr'] .xl\:ltr\:mt-md,[dir='ltr'].xl\:ltr\:mt-md {
    margin-top: 64rem
  }

  [dir='ltr'] .xl\:ltr\:mr-md,[dir='ltr'].xl\:ltr\:mr-md {
    margin-right: 64rem
  }

  [dir='ltr'] .xl\:ltr\:mb-md,[dir='ltr'].xl\:ltr\:mb-md {
    margin-bottom: 64rem
  }

  [dir='ltr'] .xl\:ltr\:ml-md,[dir='ltr'].xl\:ltr\:ml-md {
    margin-left: 64rem
  }

  [dir='ltr'] .xl\:ltr\:mt-lg,[dir='ltr'].xl\:ltr\:mt-lg {
    margin-top: 80rem
  }

  [dir='ltr'] .xl\:ltr\:mr-lg,[dir='ltr'].xl\:ltr\:mr-lg {
    margin-right: 80rem
  }

  [dir='ltr'] .xl\:ltr\:mb-lg,[dir='ltr'].xl\:ltr\:mb-lg {
    margin-bottom: 80rem
  }

  [dir='ltr'] .xl\:ltr\:ml-lg,[dir='ltr'].xl\:ltr\:ml-lg {
    margin-left: 80rem
  }

  [dir='ltr'] .xl\:ltr\:mt-xl,[dir='ltr'].xl\:ltr\:mt-xl {
    margin-top: 96rem
  }

  [dir='ltr'] .xl\:ltr\:mr-xl,[dir='ltr'].xl\:ltr\:mr-xl {
    margin-right: 96rem
  }

  [dir='ltr'] .xl\:ltr\:mb-xl,[dir='ltr'].xl\:ltr\:mb-xl {
    margin-bottom: 96rem
  }

  [dir='ltr'] .xl\:ltr\:ml-xl,[dir='ltr'].xl\:ltr\:ml-xl {
    margin-left: 96rem
  }

  [dir='ltr'] .xl\:ltr\:mt-2xl,[dir='ltr'].xl\:ltr\:mt-2xl {
    margin-top: 112rem
  }

  [dir='ltr'] .xl\:ltr\:mr-2xl,[dir='ltr'].xl\:ltr\:mr-2xl {
    margin-right: 112rem
  }

  [dir='ltr'] .xl\:ltr\:mb-2xl,[dir='ltr'].xl\:ltr\:mb-2xl {
    margin-bottom: 112rem
  }

  [dir='ltr'] .xl\:ltr\:ml-2xl,[dir='ltr'].xl\:ltr\:ml-2xl {
    margin-left: 112rem
  }

  [dir='ltr'] .xl\:ltr\:mt-3xl,[dir='ltr'].xl\:ltr\:mt-3xl {
    margin-top: 128rem
  }

  [dir='ltr'] .xl\:ltr\:mr-3xl,[dir='ltr'].xl\:ltr\:mr-3xl {
    margin-right: 128rem
  }

  [dir='ltr'] .xl\:ltr\:mb-3xl,[dir='ltr'].xl\:ltr\:mb-3xl {
    margin-bottom: 128rem
  }

  [dir='ltr'] .xl\:ltr\:ml-3xl,[dir='ltr'].xl\:ltr\:ml-3xl {
    margin-left: 128rem
  }

  [dir='ltr'] .xl\:ltr\:mt-4xl,[dir='ltr'].xl\:ltr\:mt-4xl {
    margin-top: 144rem
  }

  [dir='ltr'] .xl\:ltr\:mr-4xl,[dir='ltr'].xl\:ltr\:mr-4xl {
    margin-right: 144rem
  }

  [dir='ltr'] .xl\:ltr\:mb-4xl,[dir='ltr'].xl\:ltr\:mb-4xl {
    margin-bottom: 144rem
  }

  [dir='ltr'] .xl\:ltr\:ml-4xl,[dir='ltr'].xl\:ltr\:ml-4xl {
    margin-left: 144rem
  }

  [dir='ltr'] .xl\:ltr\:mt-5xl,[dir='ltr'].xl\:ltr\:mt-5xl {
    margin-top: 160rem
  }

  [dir='ltr'] .xl\:ltr\:mr-5xl,[dir='ltr'].xl\:ltr\:mr-5xl {
    margin-right: 160rem
  }

  [dir='ltr'] .xl\:ltr\:mb-5xl,[dir='ltr'].xl\:ltr\:mb-5xl {
    margin-bottom: 160rem
  }

  [dir='ltr'] .xl\:ltr\:ml-5xl,[dir='ltr'].xl\:ltr\:ml-5xl {
    margin-left: 160rem
  }

  [dir='ltr'] .xl\:ltr\:mt-px,[dir='ltr'].xl\:ltr\:mt-px {
    margin-top: 1px
  }

  [dir='ltr'] .xl\:ltr\:mr-px,[dir='ltr'].xl\:ltr\:mr-px {
    margin-right: 1px
  }

  [dir='ltr'] .xl\:ltr\:mb-px,[dir='ltr'].xl\:ltr\:mb-px {
    margin-bottom: 1px
  }

  [dir='ltr'] .xl\:ltr\:ml-px,[dir='ltr'].xl\:ltr\:ml-px {
    margin-left: 1px
  }

  [dir='ltr'] .xl\:ltr\:mt-0\.5,[dir='ltr'].xl\:ltr\:mt-0\.5 {
    margin-top: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:mr-0\.5,[dir='ltr'].xl\:ltr\:mr-0\.5 {
    margin-right: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:mb-0\.5,[dir='ltr'].xl\:ltr\:mb-0\.5 {
    margin-bottom: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:ml-0\.5,[dir='ltr'].xl\:ltr\:ml-0\.5 {
    margin-left: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:mt-1\.5,[dir='ltr'].xl\:ltr\:mt-1\.5 {
    margin-top: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:mr-1\.5,[dir='ltr'].xl\:ltr\:mr-1\.5 {
    margin-right: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:mb-1\.5,[dir='ltr'].xl\:ltr\:mb-1\.5 {
    margin-bottom: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:ml-1\.5,[dir='ltr'].xl\:ltr\:ml-1\.5 {
    margin-left: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:mt-2\.5,[dir='ltr'].xl\:ltr\:mt-2\.5 {
    margin-top: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:mr-2\.5,[dir='ltr'].xl\:ltr\:mr-2\.5 {
    margin-right: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:mb-2\.5,[dir='ltr'].xl\:ltr\:mb-2\.5 {
    margin-bottom: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:ml-2\.5,[dir='ltr'].xl\:ltr\:ml-2\.5 {
    margin-left: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:mt-3\.5,[dir='ltr'].xl\:ltr\:mt-3\.5 {
    margin-top: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:mr-3\.5,[dir='ltr'].xl\:ltr\:mr-3\.5 {
    margin-right: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:mb-3\.5,[dir='ltr'].xl\:ltr\:mb-3\.5 {
    margin-bottom: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:ml-3\.5,[dir='ltr'].xl\:ltr\:ml-3\.5 {
    margin-left: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-1,[dir='ltr'].xl\:ltr\:-mt-1 {
    margin-top: -0.1rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-1,[dir='ltr'].xl\:ltr\:-mr-1 {
    margin-right: -0.1rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-1,[dir='ltr'].xl\:ltr\:-mb-1 {
    margin-bottom: -0.1rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-1,[dir='ltr'].xl\:ltr\:-ml-1 {
    margin-left: -0.1rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-2,[dir='ltr'].xl\:ltr\:-mt-2 {
    margin-top: -0.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-2,[dir='ltr'].xl\:ltr\:-mr-2 {
    margin-right: -0.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-2,[dir='ltr'].xl\:ltr\:-mb-2 {
    margin-bottom: -0.2rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-2,[dir='ltr'].xl\:ltr\:-ml-2 {
    margin-left: -0.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-3,[dir='ltr'].xl\:ltr\:-mt-3 {
    margin-top: -0.3rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-3,[dir='ltr'].xl\:ltr\:-mr-3 {
    margin-right: -0.3rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-3,[dir='ltr'].xl\:ltr\:-mb-3 {
    margin-bottom: -0.3rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-3,[dir='ltr'].xl\:ltr\:-ml-3 {
    margin-left: -0.3rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-4,[dir='ltr'].xl\:ltr\:-mt-4 {
    margin-top: -0.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-4,[dir='ltr'].xl\:ltr\:-mr-4 {
    margin-right: -0.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-4,[dir='ltr'].xl\:ltr\:-mb-4 {
    margin-bottom: -0.4rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-4,[dir='ltr'].xl\:ltr\:-ml-4 {
    margin-left: -0.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-5,[dir='ltr'].xl\:ltr\:-mt-5 {
    margin-top: -0.5rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-5,[dir='ltr'].xl\:ltr\:-mr-5 {
    margin-right: -0.5rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-5,[dir='ltr'].xl\:ltr\:-mb-5 {
    margin-bottom: -0.5rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-5,[dir='ltr'].xl\:ltr\:-ml-5 {
    margin-left: -0.5rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-6,[dir='ltr'].xl\:ltr\:-mt-6 {
    margin-top: -0.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-6,[dir='ltr'].xl\:ltr\:-mr-6 {
    margin-right: -0.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-6,[dir='ltr'].xl\:ltr\:-mb-6 {
    margin-bottom: -0.6rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-6,[dir='ltr'].xl\:ltr\:-ml-6 {
    margin-left: -0.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-7,[dir='ltr'].xl\:ltr\:-mt-7 {
    margin-top: -0.7rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-7,[dir='ltr'].xl\:ltr\:-mr-7 {
    margin-right: -0.7rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-7,[dir='ltr'].xl\:ltr\:-mb-7 {
    margin-bottom: -0.7rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-7,[dir='ltr'].xl\:ltr\:-ml-7 {
    margin-left: -0.7rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-8,[dir='ltr'].xl\:ltr\:-mt-8 {
    margin-top: -0.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-8,[dir='ltr'].xl\:ltr\:-mr-8 {
    margin-right: -0.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-8,[dir='ltr'].xl\:ltr\:-mb-8 {
    margin-bottom: -0.8rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-8,[dir='ltr'].xl\:ltr\:-ml-8 {
    margin-left: -0.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-9,[dir='ltr'].xl\:ltr\:-mt-9 {
    margin-top: -0.9rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-9,[dir='ltr'].xl\:ltr\:-mr-9 {
    margin-right: -0.9rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-9,[dir='ltr'].xl\:ltr\:-mb-9 {
    margin-bottom: -0.9rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-9,[dir='ltr'].xl\:ltr\:-ml-9 {
    margin-left: -0.9rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-10,[dir='ltr'].xl\:ltr\:-mt-10 {
    margin-top: -1rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-10,[dir='ltr'].xl\:ltr\:-mr-10 {
    margin-right: -1rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-10,[dir='ltr'].xl\:ltr\:-mb-10 {
    margin-bottom: -1rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-10,[dir='ltr'].xl\:ltr\:-ml-10 {
    margin-left: -1rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-12,[dir='ltr'].xl\:ltr\:-mt-12 {
    margin-top: -1.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-12,[dir='ltr'].xl\:ltr\:-mr-12 {
    margin-right: -1.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-12,[dir='ltr'].xl\:ltr\:-mb-12 {
    margin-bottom: -1.2rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-12,[dir='ltr'].xl\:ltr\:-ml-12 {
    margin-left: -1.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-14,[dir='ltr'].xl\:ltr\:-mt-14 {
    margin-top: -1.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-14,[dir='ltr'].xl\:ltr\:-mr-14 {
    margin-right: -1.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-14,[dir='ltr'].xl\:ltr\:-mb-14 {
    margin-bottom: -1.4rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-14,[dir='ltr'].xl\:ltr\:-ml-14 {
    margin-left: -1.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-16,[dir='ltr'].xl\:ltr\:-mt-16 {
    margin-top: -1.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-16,[dir='ltr'].xl\:ltr\:-mr-16 {
    margin-right: -1.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-16,[dir='ltr'].xl\:ltr\:-mb-16 {
    margin-bottom: -1.6rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-16,[dir='ltr'].xl\:ltr\:-ml-16 {
    margin-left: -1.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-20,[dir='ltr'].xl\:ltr\:-mt-20 {
    margin-top: -2rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-20,[dir='ltr'].xl\:ltr\:-mr-20 {
    margin-right: -2rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-20,[dir='ltr'].xl\:ltr\:-mb-20 {
    margin-bottom: -2rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-20,[dir='ltr'].xl\:ltr\:-ml-20 {
    margin-left: -2rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-24,[dir='ltr'].xl\:ltr\:-mt-24 {
    margin-top: -2.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-24,[dir='ltr'].xl\:ltr\:-mr-24 {
    margin-right: -2.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-24,[dir='ltr'].xl\:ltr\:-mb-24 {
    margin-bottom: -2.4rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-24,[dir='ltr'].xl\:ltr\:-ml-24 {
    margin-left: -2.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-28,[dir='ltr'].xl\:ltr\:-mt-28 {
    margin-top: -2.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-28,[dir='ltr'].xl\:ltr\:-mr-28 {
    margin-right: -2.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-28,[dir='ltr'].xl\:ltr\:-mb-28 {
    margin-bottom: -2.8rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-28,[dir='ltr'].xl\:ltr\:-ml-28 {
    margin-left: -2.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-32,[dir='ltr'].xl\:ltr\:-mt-32 {
    margin-top: -3.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-32,[dir='ltr'].xl\:ltr\:-mr-32 {
    margin-right: -3.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-32,[dir='ltr'].xl\:ltr\:-mb-32 {
    margin-bottom: -3.2rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-32,[dir='ltr'].xl\:ltr\:-ml-32 {
    margin-left: -3.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-36,[dir='ltr'].xl\:ltr\:-mt-36 {
    margin-top: -3.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-36,[dir='ltr'].xl\:ltr\:-mr-36 {
    margin-right: -3.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-36,[dir='ltr'].xl\:ltr\:-mb-36 {
    margin-bottom: -3.6rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-36,[dir='ltr'].xl\:ltr\:-ml-36 {
    margin-left: -3.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-40,[dir='ltr'].xl\:ltr\:-mt-40 {
    margin-top: -4rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-40,[dir='ltr'].xl\:ltr\:-mr-40 {
    margin-right: -4rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-40,[dir='ltr'].xl\:ltr\:-mb-40 {
    margin-bottom: -4rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-40,[dir='ltr'].xl\:ltr\:-ml-40 {
    margin-left: -4rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-44,[dir='ltr'].xl\:ltr\:-mt-44 {
    margin-top: -4.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-44,[dir='ltr'].xl\:ltr\:-mr-44 {
    margin-right: -4.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-44,[dir='ltr'].xl\:ltr\:-mb-44 {
    margin-bottom: -4.4rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-44,[dir='ltr'].xl\:ltr\:-ml-44 {
    margin-left: -4.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-48,[dir='ltr'].xl\:ltr\:-mt-48 {
    margin-top: -4.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-48,[dir='ltr'].xl\:ltr\:-mr-48 {
    margin-right: -4.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-48,[dir='ltr'].xl\:ltr\:-mb-48 {
    margin-bottom: -4.8rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-48,[dir='ltr'].xl\:ltr\:-ml-48 {
    margin-left: -4.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-52,[dir='ltr'].xl\:ltr\:-mt-52 {
    margin-top: -5.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-52,[dir='ltr'].xl\:ltr\:-mr-52 {
    margin-right: -5.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-52,[dir='ltr'].xl\:ltr\:-mb-52 {
    margin-bottom: -5.2rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-52,[dir='ltr'].xl\:ltr\:-ml-52 {
    margin-left: -5.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-56,[dir='ltr'].xl\:ltr\:-mt-56 {
    margin-top: -5.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-56,[dir='ltr'].xl\:ltr\:-mr-56 {
    margin-right: -5.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-56,[dir='ltr'].xl\:ltr\:-mb-56 {
    margin-bottom: -5.6rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-56,[dir='ltr'].xl\:ltr\:-ml-56 {
    margin-left: -5.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-60,[dir='ltr'].xl\:ltr\:-mt-60 {
    margin-top: -6rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-60,[dir='ltr'].xl\:ltr\:-mr-60 {
    margin-right: -6rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-60,[dir='ltr'].xl\:ltr\:-mb-60 {
    margin-bottom: -6rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-60,[dir='ltr'].xl\:ltr\:-ml-60 {
    margin-left: -6rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-64,[dir='ltr'].xl\:ltr\:-mt-64 {
    margin-top: -6.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-64,[dir='ltr'].xl\:ltr\:-mr-64 {
    margin-right: -6.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-64,[dir='ltr'].xl\:ltr\:-mb-64 {
    margin-bottom: -6.4rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-64,[dir='ltr'].xl\:ltr\:-ml-64 {
    margin-left: -6.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-68,[dir='ltr'].xl\:ltr\:-mt-68 {
    margin-top: -6.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-68,[dir='ltr'].xl\:ltr\:-mr-68 {
    margin-right: -6.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-68,[dir='ltr'].xl\:ltr\:-mb-68 {
    margin-bottom: -6.8rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-68,[dir='ltr'].xl\:ltr\:-ml-68 {
    margin-left: -6.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-72,[dir='ltr'].xl\:ltr\:-mt-72 {
    margin-top: -7.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-72,[dir='ltr'].xl\:ltr\:-mr-72 {
    margin-right: -7.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-72,[dir='ltr'].xl\:ltr\:-mb-72 {
    margin-bottom: -7.2rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-72,[dir='ltr'].xl\:ltr\:-ml-72 {
    margin-left: -7.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-76,[dir='ltr'].xl\:ltr\:-mt-76 {
    margin-top: -7.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-76,[dir='ltr'].xl\:ltr\:-mr-76 {
    margin-right: -7.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-76,[dir='ltr'].xl\:ltr\:-mb-76 {
    margin-bottom: -7.6rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-76,[dir='ltr'].xl\:ltr\:-ml-76 {
    margin-left: -7.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-80,[dir='ltr'].xl\:ltr\:-mt-80 {
    margin-top: -8rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-80,[dir='ltr'].xl\:ltr\:-mr-80 {
    margin-right: -8rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-80,[dir='ltr'].xl\:ltr\:-mb-80 {
    margin-bottom: -8rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-80,[dir='ltr'].xl\:ltr\:-ml-80 {
    margin-left: -8rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-84,[dir='ltr'].xl\:ltr\:-mt-84 {
    margin-top: -8.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-84,[dir='ltr'].xl\:ltr\:-mr-84 {
    margin-right: -8.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-84,[dir='ltr'].xl\:ltr\:-mb-84 {
    margin-bottom: -8.4rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-84,[dir='ltr'].xl\:ltr\:-ml-84 {
    margin-left: -8.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-88,[dir='ltr'].xl\:ltr\:-mt-88 {
    margin-top: -8.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-88,[dir='ltr'].xl\:ltr\:-mr-88 {
    margin-right: -8.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-88,[dir='ltr'].xl\:ltr\:-mb-88 {
    margin-bottom: -8.8rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-88,[dir='ltr'].xl\:ltr\:-ml-88 {
    margin-left: -8.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-92,[dir='ltr'].xl\:ltr\:-mt-92 {
    margin-top: -9.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-92,[dir='ltr'].xl\:ltr\:-mr-92 {
    margin-right: -9.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-92,[dir='ltr'].xl\:ltr\:-mb-92 {
    margin-bottom: -9.2rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-92,[dir='ltr'].xl\:ltr\:-ml-92 {
    margin-left: -9.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-96,[dir='ltr'].xl\:ltr\:-mt-96 {
    margin-top: -9.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-96,[dir='ltr'].xl\:ltr\:-mr-96 {
    margin-right: -9.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-96,[dir='ltr'].xl\:ltr\:-mb-96 {
    margin-bottom: -9.6rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-96,[dir='ltr'].xl\:ltr\:-ml-96 {
    margin-left: -9.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-128,[dir='ltr'].xl\:ltr\:-mt-128 {
    margin-top: -12.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-128,[dir='ltr'].xl\:ltr\:-mr-128 {
    margin-right: -12.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-128,[dir='ltr'].xl\:ltr\:-mb-128 {
    margin-bottom: -12.8rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-128,[dir='ltr'].xl\:ltr\:-ml-128 {
    margin-left: -12.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-136,[dir='ltr'].xl\:ltr\:-mt-136 {
    margin-top: -13.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-136,[dir='ltr'].xl\:ltr\:-mr-136 {
    margin-right: -13.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-136,[dir='ltr'].xl\:ltr\:-mb-136 {
    margin-bottom: -13.6rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-136,[dir='ltr'].xl\:ltr\:-ml-136 {
    margin-left: -13.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-160,[dir='ltr'].xl\:ltr\:-mt-160 {
    margin-top: -16rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-160,[dir='ltr'].xl\:ltr\:-mr-160 {
    margin-right: -16rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-160,[dir='ltr'].xl\:ltr\:-mb-160 {
    margin-bottom: -16rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-160,[dir='ltr'].xl\:ltr\:-ml-160 {
    margin-left: -16rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-192,[dir='ltr'].xl\:ltr\:-mt-192 {
    margin-top: -19.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-192,[dir='ltr'].xl\:ltr\:-mr-192 {
    margin-right: -19.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-192,[dir='ltr'].xl\:ltr\:-mb-192 {
    margin-bottom: -19.2rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-192,[dir='ltr'].xl\:ltr\:-ml-192 {
    margin-left: -19.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-200,[dir='ltr'].xl\:ltr\:-mt-200 {
    margin-top: -20rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-200,[dir='ltr'].xl\:ltr\:-mr-200 {
    margin-right: -20rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-200,[dir='ltr'].xl\:ltr\:-mb-200 {
    margin-bottom: -20rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-200,[dir='ltr'].xl\:ltr\:-ml-200 {
    margin-left: -20rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-208,[dir='ltr'].xl\:ltr\:-mt-208 {
    margin-top: -20.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-208,[dir='ltr'].xl\:ltr\:-mr-208 {
    margin-right: -20.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-208,[dir='ltr'].xl\:ltr\:-mb-208 {
    margin-bottom: -20.8rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-208,[dir='ltr'].xl\:ltr\:-ml-208 {
    margin-left: -20.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-216,[dir='ltr'].xl\:ltr\:-mt-216 {
    margin-top: -21.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-216,[dir='ltr'].xl\:ltr\:-mr-216 {
    margin-right: -21.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-216,[dir='ltr'].xl\:ltr\:-mb-216 {
    margin-bottom: -21.6rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-216,[dir='ltr'].xl\:ltr\:-ml-216 {
    margin-left: -21.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-224,[dir='ltr'].xl\:ltr\:-mt-224 {
    margin-top: -22.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-224,[dir='ltr'].xl\:ltr\:-mr-224 {
    margin-right: -22.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-224,[dir='ltr'].xl\:ltr\:-mb-224 {
    margin-bottom: -22.4rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-224,[dir='ltr'].xl\:ltr\:-ml-224 {
    margin-left: -22.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-256,[dir='ltr'].xl\:ltr\:-mt-256 {
    margin-top: -25.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-256,[dir='ltr'].xl\:ltr\:-mr-256 {
    margin-right: -25.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-256,[dir='ltr'].xl\:ltr\:-mb-256 {
    margin-bottom: -25.6rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-256,[dir='ltr'].xl\:ltr\:-ml-256 {
    margin-left: -25.6rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-288,[dir='ltr'].xl\:ltr\:-mt-288 {
    margin-top: -28.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-288,[dir='ltr'].xl\:ltr\:-mr-288 {
    margin-right: -28.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-288,[dir='ltr'].xl\:ltr\:-mb-288 {
    margin-bottom: -28.8rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-288,[dir='ltr'].xl\:ltr\:-ml-288 {
    margin-left: -28.8rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-320,[dir='ltr'].xl\:ltr\:-mt-320 {
    margin-top: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-320,[dir='ltr'].xl\:ltr\:-mr-320 {
    margin-right: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-320,[dir='ltr'].xl\:ltr\:-mb-320 {
    margin-bottom: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-320,[dir='ltr'].xl\:ltr\:-ml-320 {
    margin-left: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-360,[dir='ltr'].xl\:ltr\:-mt-360 {
    margin-top: -36rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-360,[dir='ltr'].xl\:ltr\:-mr-360 {
    margin-right: -36rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-360,[dir='ltr'].xl\:ltr\:-mb-360 {
    margin-bottom: -36rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-360,[dir='ltr'].xl\:ltr\:-ml-360 {
    margin-left: -36rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-384,[dir='ltr'].xl\:ltr\:-mt-384 {
    margin-top: -38.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-384,[dir='ltr'].xl\:ltr\:-mr-384 {
    margin-right: -38.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-384,[dir='ltr'].xl\:ltr\:-mb-384 {
    margin-bottom: -38.4rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-384,[dir='ltr'].xl\:ltr\:-ml-384 {
    margin-left: -38.4rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-400,[dir='ltr'].xl\:ltr\:-mt-400 {
    margin-top: -40rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-400,[dir='ltr'].xl\:ltr\:-mr-400 {
    margin-right: -40rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-400,[dir='ltr'].xl\:ltr\:-mb-400 {
    margin-bottom: -40rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-400,[dir='ltr'].xl\:ltr\:-ml-400 {
    margin-left: -40rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-512,[dir='ltr'].xl\:ltr\:-mt-512 {
    margin-top: -51.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-512,[dir='ltr'].xl\:ltr\:-mr-512 {
    margin-right: -51.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-512,[dir='ltr'].xl\:ltr\:-mb-512 {
    margin-bottom: -51.2rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-512,[dir='ltr'].xl\:ltr\:-ml-512 {
    margin-left: -51.2rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-640,[dir='ltr'].xl\:ltr\:-mt-640 {
    margin-top: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-640,[dir='ltr'].xl\:ltr\:-mr-640 {
    margin-right: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-640,[dir='ltr'].xl\:ltr\:-mb-640 {
    margin-bottom: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-640,[dir='ltr'].xl\:ltr\:-ml-640 {
    margin-left: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-xs,[dir='ltr'].xl\:ltr\:-mt-xs {
    margin-top: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-xs,[dir='ltr'].xl\:ltr\:-mr-xs {
    margin-right: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-xs,[dir='ltr'].xl\:ltr\:-mb-xs {
    margin-bottom: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-xs,[dir='ltr'].xl\:ltr\:-ml-xs {
    margin-left: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-sm,[dir='ltr'].xl\:ltr\:-mt-sm {
    margin-top: -48rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-sm,[dir='ltr'].xl\:ltr\:-mr-sm {
    margin-right: -48rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-sm,[dir='ltr'].xl\:ltr\:-mb-sm {
    margin-bottom: -48rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-sm,[dir='ltr'].xl\:ltr\:-ml-sm {
    margin-left: -48rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-md,[dir='ltr'].xl\:ltr\:-mt-md {
    margin-top: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-md,[dir='ltr'].xl\:ltr\:-mr-md {
    margin-right: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-md,[dir='ltr'].xl\:ltr\:-mb-md {
    margin-bottom: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-md,[dir='ltr'].xl\:ltr\:-ml-md {
    margin-left: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-lg,[dir='ltr'].xl\:ltr\:-mt-lg {
    margin-top: -80rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-lg,[dir='ltr'].xl\:ltr\:-mr-lg {
    margin-right: -80rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-lg,[dir='ltr'].xl\:ltr\:-mb-lg {
    margin-bottom: -80rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-lg,[dir='ltr'].xl\:ltr\:-ml-lg {
    margin-left: -80rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-xl,[dir='ltr'].xl\:ltr\:-mt-xl {
    margin-top: -96rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-xl,[dir='ltr'].xl\:ltr\:-mr-xl {
    margin-right: -96rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-xl,[dir='ltr'].xl\:ltr\:-mb-xl {
    margin-bottom: -96rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-xl,[dir='ltr'].xl\:ltr\:-ml-xl {
    margin-left: -96rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-2xl,[dir='ltr'].xl\:ltr\:-mt-2xl {
    margin-top: -112rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-2xl,[dir='ltr'].xl\:ltr\:-mr-2xl {
    margin-right: -112rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-2xl,[dir='ltr'].xl\:ltr\:-mb-2xl {
    margin-bottom: -112rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-2xl,[dir='ltr'].xl\:ltr\:-ml-2xl {
    margin-left: -112rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-3xl,[dir='ltr'].xl\:ltr\:-mt-3xl {
    margin-top: -128rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-3xl,[dir='ltr'].xl\:ltr\:-mr-3xl {
    margin-right: -128rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-3xl,[dir='ltr'].xl\:ltr\:-mb-3xl {
    margin-bottom: -128rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-3xl,[dir='ltr'].xl\:ltr\:-ml-3xl {
    margin-left: -128rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-4xl,[dir='ltr'].xl\:ltr\:-mt-4xl {
    margin-top: -144rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-4xl,[dir='ltr'].xl\:ltr\:-mr-4xl {
    margin-right: -144rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-4xl,[dir='ltr'].xl\:ltr\:-mb-4xl {
    margin-bottom: -144rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-4xl,[dir='ltr'].xl\:ltr\:-ml-4xl {
    margin-left: -144rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-5xl,[dir='ltr'].xl\:ltr\:-mt-5xl {
    margin-top: -160rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-5xl,[dir='ltr'].xl\:ltr\:-mr-5xl {
    margin-right: -160rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-5xl,[dir='ltr'].xl\:ltr\:-mb-5xl {
    margin-bottom: -160rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-5xl,[dir='ltr'].xl\:ltr\:-ml-5xl {
    margin-left: -160rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-px,[dir='ltr'].xl\:ltr\:-mt-px {
    margin-top: -1px
  }

  [dir='ltr'] .xl\:ltr\:-mr-px,[dir='ltr'].xl\:ltr\:-mr-px {
    margin-right: -1px
  }

  [dir='ltr'] .xl\:ltr\:-mb-px,[dir='ltr'].xl\:ltr\:-mb-px {
    margin-bottom: -1px
  }

  [dir='ltr'] .xl\:ltr\:-ml-px,[dir='ltr'].xl\:ltr\:-ml-px {
    margin-left: -1px
  }

  [dir='ltr'] .xl\:ltr\:-mt-0\.5,[dir='ltr'].xl\:ltr\:-mt-0\.5 {
    margin-top: -0.05rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-0\.5,[dir='ltr'].xl\:ltr\:-mr-0\.5 {
    margin-right: -0.05rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-0\.5,[dir='ltr'].xl\:ltr\:-mb-0\.5 {
    margin-bottom: -0.05rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-0\.5,[dir='ltr'].xl\:ltr\:-ml-0\.5 {
    margin-left: -0.05rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-1\.5,[dir='ltr'].xl\:ltr\:-mt-1\.5 {
    margin-top: -0.15rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-1\.5,[dir='ltr'].xl\:ltr\:-mr-1\.5 {
    margin-right: -0.15rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-1\.5,[dir='ltr'].xl\:ltr\:-mb-1\.5 {
    margin-bottom: -0.15rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-1\.5,[dir='ltr'].xl\:ltr\:-ml-1\.5 {
    margin-left: -0.15rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-2\.5,[dir='ltr'].xl\:ltr\:-mt-2\.5 {
    margin-top: -0.25rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-2\.5,[dir='ltr'].xl\:ltr\:-mr-2\.5 {
    margin-right: -0.25rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-2\.5,[dir='ltr'].xl\:ltr\:-mb-2\.5 {
    margin-bottom: -0.25rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-2\.5,[dir='ltr'].xl\:ltr\:-ml-2\.5 {
    margin-left: -0.25rem
  }

  [dir='ltr'] .xl\:ltr\:-mt-3\.5,[dir='ltr'].xl\:ltr\:-mt-3\.5 {
    margin-top: -0.35rem
  }

  [dir='ltr'] .xl\:ltr\:-mr-3\.5,[dir='ltr'].xl\:ltr\:-mr-3\.5 {
    margin-right: -0.35rem
  }

  [dir='ltr'] .xl\:ltr\:-mb-3\.5,[dir='ltr'].xl\:ltr\:-mb-3\.5 {
    margin-bottom: -0.35rem
  }

  [dir='ltr'] .xl\:ltr\:-ml-3\.5,[dir='ltr'].xl\:ltr\:-ml-3\.5 {
    margin-left: -0.35rem
  }

  [dir='rtl'] .xl\:rtl\:m-0,[dir='rtl'].xl\:rtl\:m-0 {
    margin: 0
  }

  [dir='rtl'] .xl\:rtl\:m-1,[dir='rtl'].xl\:rtl\:m-1 {
    margin: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:m-2,[dir='rtl'].xl\:rtl\:m-2 {
    margin: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:m-3,[dir='rtl'].xl\:rtl\:m-3 {
    margin: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:m-4,[dir='rtl'].xl\:rtl\:m-4 {
    margin: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:m-5,[dir='rtl'].xl\:rtl\:m-5 {
    margin: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:m-6,[dir='rtl'].xl\:rtl\:m-6 {
    margin: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:m-7,[dir='rtl'].xl\:rtl\:m-7 {
    margin: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:m-8,[dir='rtl'].xl\:rtl\:m-8 {
    margin: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:m-9,[dir='rtl'].xl\:rtl\:m-9 {
    margin: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:m-10,[dir='rtl'].xl\:rtl\:m-10 {
    margin: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:m-12,[dir='rtl'].xl\:rtl\:m-12 {
    margin: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:m-14,[dir='rtl'].xl\:rtl\:m-14 {
    margin: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:m-16,[dir='rtl'].xl\:rtl\:m-16 {
    margin: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:m-20,[dir='rtl'].xl\:rtl\:m-20 {
    margin: 2rem
  }

  [dir='rtl'] .xl\:rtl\:m-24,[dir='rtl'].xl\:rtl\:m-24 {
    margin: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:m-28,[dir='rtl'].xl\:rtl\:m-28 {
    margin: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:m-32,[dir='rtl'].xl\:rtl\:m-32 {
    margin: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:m-36,[dir='rtl'].xl\:rtl\:m-36 {
    margin: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:m-40,[dir='rtl'].xl\:rtl\:m-40 {
    margin: 4rem
  }

  [dir='rtl'] .xl\:rtl\:m-44,[dir='rtl'].xl\:rtl\:m-44 {
    margin: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:m-48,[dir='rtl'].xl\:rtl\:m-48 {
    margin: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:m-52,[dir='rtl'].xl\:rtl\:m-52 {
    margin: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:m-56,[dir='rtl'].xl\:rtl\:m-56 {
    margin: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:m-60,[dir='rtl'].xl\:rtl\:m-60 {
    margin: 6rem
  }

  [dir='rtl'] .xl\:rtl\:m-64,[dir='rtl'].xl\:rtl\:m-64 {
    margin: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:m-68,[dir='rtl'].xl\:rtl\:m-68 {
    margin: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:m-72,[dir='rtl'].xl\:rtl\:m-72 {
    margin: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:m-76,[dir='rtl'].xl\:rtl\:m-76 {
    margin: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:m-80,[dir='rtl'].xl\:rtl\:m-80 {
    margin: 8rem
  }

  [dir='rtl'] .xl\:rtl\:m-84,[dir='rtl'].xl\:rtl\:m-84 {
    margin: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:m-88,[dir='rtl'].xl\:rtl\:m-88 {
    margin: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:m-92,[dir='rtl'].xl\:rtl\:m-92 {
    margin: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:m-96,[dir='rtl'].xl\:rtl\:m-96 {
    margin: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:m-128,[dir='rtl'].xl\:rtl\:m-128 {
    margin: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:m-136,[dir='rtl'].xl\:rtl\:m-136 {
    margin: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:m-160,[dir='rtl'].xl\:rtl\:m-160 {
    margin: 16rem
  }

  [dir='rtl'] .xl\:rtl\:m-192,[dir='rtl'].xl\:rtl\:m-192 {
    margin: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:m-200,[dir='rtl'].xl\:rtl\:m-200 {
    margin: 20rem
  }

  [dir='rtl'] .xl\:rtl\:m-208,[dir='rtl'].xl\:rtl\:m-208 {
    margin: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:m-216,[dir='rtl'].xl\:rtl\:m-216 {
    margin: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:m-224,[dir='rtl'].xl\:rtl\:m-224 {
    margin: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:m-256,[dir='rtl'].xl\:rtl\:m-256 {
    margin: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:m-288,[dir='rtl'].xl\:rtl\:m-288 {
    margin: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:m-320,[dir='rtl'].xl\:rtl\:m-320 {
    margin: 32rem
  }

  [dir='rtl'] .xl\:rtl\:m-360,[dir='rtl'].xl\:rtl\:m-360 {
    margin: 36rem
  }

  [dir='rtl'] .xl\:rtl\:m-384,[dir='rtl'].xl\:rtl\:m-384 {
    margin: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:m-400,[dir='rtl'].xl\:rtl\:m-400 {
    margin: 40rem
  }

  [dir='rtl'] .xl\:rtl\:m-512,[dir='rtl'].xl\:rtl\:m-512 {
    margin: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:m-640,[dir='rtl'].xl\:rtl\:m-640 {
    margin: 64rem
  }

  [dir='rtl'] .xl\:rtl\:m-auto,[dir='rtl'].xl\:rtl\:m-auto {
    margin: auto
  }

  [dir='rtl'] .xl\:rtl\:m-xs,[dir='rtl'].xl\:rtl\:m-xs {
    margin: 32rem
  }

  [dir='rtl'] .xl\:rtl\:m-sm,[dir='rtl'].xl\:rtl\:m-sm {
    margin: 48rem
  }

  [dir='rtl'] .xl\:rtl\:m-md,[dir='rtl'].xl\:rtl\:m-md {
    margin: 64rem
  }

  [dir='rtl'] .xl\:rtl\:m-lg,[dir='rtl'].xl\:rtl\:m-lg {
    margin: 80rem
  }

  [dir='rtl'] .xl\:rtl\:m-xl,[dir='rtl'].xl\:rtl\:m-xl {
    margin: 96rem
  }

  [dir='rtl'] .xl\:rtl\:m-2xl,[dir='rtl'].xl\:rtl\:m-2xl {
    margin: 112rem
  }

  [dir='rtl'] .xl\:rtl\:m-3xl,[dir='rtl'].xl\:rtl\:m-3xl {
    margin: 128rem
  }

  [dir='rtl'] .xl\:rtl\:m-4xl,[dir='rtl'].xl\:rtl\:m-4xl {
    margin: 144rem
  }

  [dir='rtl'] .xl\:rtl\:m-5xl,[dir='rtl'].xl\:rtl\:m-5xl {
    margin: 160rem
  }

  [dir='rtl'] .xl\:rtl\:m-px,[dir='rtl'].xl\:rtl\:m-px {
    margin: 1px
  }

  [dir='rtl'] .xl\:rtl\:m-0\.5,[dir='rtl'].xl\:rtl\:m-0\.5 {
    margin: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:m-1\.5,[dir='rtl'].xl\:rtl\:m-1\.5 {
    margin: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:m-2\.5,[dir='rtl'].xl\:rtl\:m-2\.5 {
    margin: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:m-3\.5,[dir='rtl'].xl\:rtl\:m-3\.5 {
    margin: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:-m-1,[dir='rtl'].xl\:rtl\:-m-1 {
    margin: -0.1rem
  }

  [dir='rtl'] .xl\:rtl\:-m-2,[dir='rtl'].xl\:rtl\:-m-2 {
    margin: -0.2rem
  }

  [dir='rtl'] .xl\:rtl\:-m-3,[dir='rtl'].xl\:rtl\:-m-3 {
    margin: -0.3rem
  }

  [dir='rtl'] .xl\:rtl\:-m-4,[dir='rtl'].xl\:rtl\:-m-4 {
    margin: -0.4rem
  }

  [dir='rtl'] .xl\:rtl\:-m-5,[dir='rtl'].xl\:rtl\:-m-5 {
    margin: -0.5rem
  }

  [dir='rtl'] .xl\:rtl\:-m-6,[dir='rtl'].xl\:rtl\:-m-6 {
    margin: -0.6rem
  }

  [dir='rtl'] .xl\:rtl\:-m-7,[dir='rtl'].xl\:rtl\:-m-7 {
    margin: -0.7rem
  }

  [dir='rtl'] .xl\:rtl\:-m-8,[dir='rtl'].xl\:rtl\:-m-8 {
    margin: -0.8rem
  }

  [dir='rtl'] .xl\:rtl\:-m-9,[dir='rtl'].xl\:rtl\:-m-9 {
    margin: -0.9rem
  }

  [dir='rtl'] .xl\:rtl\:-m-10,[dir='rtl'].xl\:rtl\:-m-10 {
    margin: -1rem
  }

  [dir='rtl'] .xl\:rtl\:-m-12,[dir='rtl'].xl\:rtl\:-m-12 {
    margin: -1.2rem
  }

  [dir='rtl'] .xl\:rtl\:-m-14,[dir='rtl'].xl\:rtl\:-m-14 {
    margin: -1.4rem
  }

  [dir='rtl'] .xl\:rtl\:-m-16,[dir='rtl'].xl\:rtl\:-m-16 {
    margin: -1.6rem
  }

  [dir='rtl'] .xl\:rtl\:-m-20,[dir='rtl'].xl\:rtl\:-m-20 {
    margin: -2rem
  }

  [dir='rtl'] .xl\:rtl\:-m-24,[dir='rtl'].xl\:rtl\:-m-24 {
    margin: -2.4rem
  }

  [dir='rtl'] .xl\:rtl\:-m-28,[dir='rtl'].xl\:rtl\:-m-28 {
    margin: -2.8rem
  }

  [dir='rtl'] .xl\:rtl\:-m-32,[dir='rtl'].xl\:rtl\:-m-32 {
    margin: -3.2rem
  }

  [dir='rtl'] .xl\:rtl\:-m-36,[dir='rtl'].xl\:rtl\:-m-36 {
    margin: -3.6rem
  }

  [dir='rtl'] .xl\:rtl\:-m-40,[dir='rtl'].xl\:rtl\:-m-40 {
    margin: -4rem
  }

  [dir='rtl'] .xl\:rtl\:-m-44,[dir='rtl'].xl\:rtl\:-m-44 {
    margin: -4.4rem
  }

  [dir='rtl'] .xl\:rtl\:-m-48,[dir='rtl'].xl\:rtl\:-m-48 {
    margin: -4.8rem
  }

  [dir='rtl'] .xl\:rtl\:-m-52,[dir='rtl'].xl\:rtl\:-m-52 {
    margin: -5.2rem
  }

  [dir='rtl'] .xl\:rtl\:-m-56,[dir='rtl'].xl\:rtl\:-m-56 {
    margin: -5.6rem
  }

  [dir='rtl'] .xl\:rtl\:-m-60,[dir='rtl'].xl\:rtl\:-m-60 {
    margin: -6rem
  }

  [dir='rtl'] .xl\:rtl\:-m-64,[dir='rtl'].xl\:rtl\:-m-64 {
    margin: -6.4rem
  }

  [dir='rtl'] .xl\:rtl\:-m-68,[dir='rtl'].xl\:rtl\:-m-68 {
    margin: -6.8rem
  }

  [dir='rtl'] .xl\:rtl\:-m-72,[dir='rtl'].xl\:rtl\:-m-72 {
    margin: -7.2rem
  }

  [dir='rtl'] .xl\:rtl\:-m-76,[dir='rtl'].xl\:rtl\:-m-76 {
    margin: -7.6rem
  }

  [dir='rtl'] .xl\:rtl\:-m-80,[dir='rtl'].xl\:rtl\:-m-80 {
    margin: -8rem
  }

  [dir='rtl'] .xl\:rtl\:-m-84,[dir='rtl'].xl\:rtl\:-m-84 {
    margin: -8.4rem
  }

  [dir='rtl'] .xl\:rtl\:-m-88,[dir='rtl'].xl\:rtl\:-m-88 {
    margin: -8.8rem
  }

  [dir='rtl'] .xl\:rtl\:-m-92,[dir='rtl'].xl\:rtl\:-m-92 {
    margin: -9.2rem
  }

  [dir='rtl'] .xl\:rtl\:-m-96,[dir='rtl'].xl\:rtl\:-m-96 {
    margin: -9.6rem
  }

  [dir='rtl'] .xl\:rtl\:-m-128,[dir='rtl'].xl\:rtl\:-m-128 {
    margin: -12.8rem
  }

  [dir='rtl'] .xl\:rtl\:-m-136,[dir='rtl'].xl\:rtl\:-m-136 {
    margin: -13.6rem
  }

  [dir='rtl'] .xl\:rtl\:-m-160,[dir='rtl'].xl\:rtl\:-m-160 {
    margin: -16rem
  }

  [dir='rtl'] .xl\:rtl\:-m-192,[dir='rtl'].xl\:rtl\:-m-192 {
    margin: -19.2rem
  }

  [dir='rtl'] .xl\:rtl\:-m-200,[dir='rtl'].xl\:rtl\:-m-200 {
    margin: -20rem
  }

  [dir='rtl'] .xl\:rtl\:-m-208,[dir='rtl'].xl\:rtl\:-m-208 {
    margin: -20.8rem
  }

  [dir='rtl'] .xl\:rtl\:-m-216,[dir='rtl'].xl\:rtl\:-m-216 {
    margin: -21.6rem
  }

  [dir='rtl'] .xl\:rtl\:-m-224,[dir='rtl'].xl\:rtl\:-m-224 {
    margin: -22.4rem
  }

  [dir='rtl'] .xl\:rtl\:-m-256,[dir='rtl'].xl\:rtl\:-m-256 {
    margin: -25.6rem
  }

  [dir='rtl'] .xl\:rtl\:-m-288,[dir='rtl'].xl\:rtl\:-m-288 {
    margin: -28.8rem
  }

  [dir='rtl'] .xl\:rtl\:-m-320,[dir='rtl'].xl\:rtl\:-m-320 {
    margin: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-m-360,[dir='rtl'].xl\:rtl\:-m-360 {
    margin: -36rem
  }

  [dir='rtl'] .xl\:rtl\:-m-384,[dir='rtl'].xl\:rtl\:-m-384 {
    margin: -38.4rem
  }

  [dir='rtl'] .xl\:rtl\:-m-400,[dir='rtl'].xl\:rtl\:-m-400 {
    margin: -40rem
  }

  [dir='rtl'] .xl\:rtl\:-m-512,[dir='rtl'].xl\:rtl\:-m-512 {
    margin: -51.2rem
  }

  [dir='rtl'] .xl\:rtl\:-m-640,[dir='rtl'].xl\:rtl\:-m-640 {
    margin: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-m-xs,[dir='rtl'].xl\:rtl\:-m-xs {
    margin: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-m-sm,[dir='rtl'].xl\:rtl\:-m-sm {
    margin: -48rem
  }

  [dir='rtl'] .xl\:rtl\:-m-md,[dir='rtl'].xl\:rtl\:-m-md {
    margin: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-m-lg,[dir='rtl'].xl\:rtl\:-m-lg {
    margin: -80rem
  }

  [dir='rtl'] .xl\:rtl\:-m-xl,[dir='rtl'].xl\:rtl\:-m-xl {
    margin: -96rem
  }

  [dir='rtl'] .xl\:rtl\:-m-2xl,[dir='rtl'].xl\:rtl\:-m-2xl {
    margin: -112rem
  }

  [dir='rtl'] .xl\:rtl\:-m-3xl,[dir='rtl'].xl\:rtl\:-m-3xl {
    margin: -128rem
  }

  [dir='rtl'] .xl\:rtl\:-m-4xl,[dir='rtl'].xl\:rtl\:-m-4xl {
    margin: -144rem
  }

  [dir='rtl'] .xl\:rtl\:-m-5xl,[dir='rtl'].xl\:rtl\:-m-5xl {
    margin: -160rem
  }

  [dir='rtl'] .xl\:rtl\:-m-px,[dir='rtl'].xl\:rtl\:-m-px {
    margin: -1px
  }

  [dir='rtl'] .xl\:rtl\:-m-0\.5,[dir='rtl'].xl\:rtl\:-m-0\.5 {
    margin: -0.05rem
  }

  [dir='rtl'] .xl\:rtl\:-m-1\.5,[dir='rtl'].xl\:rtl\:-m-1\.5 {
    margin: -0.15rem
  }

  [dir='rtl'] .xl\:rtl\:-m-2\.5,[dir='rtl'].xl\:rtl\:-m-2\.5 {
    margin: -0.25rem
  }

  [dir='rtl'] .xl\:rtl\:-m-3\.5,[dir='rtl'].xl\:rtl\:-m-3\.5 {
    margin: -0.35rem
  }

  [dir='rtl'] .xl\:rtl\:my-0,[dir='rtl'].xl\:rtl\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  [dir='rtl'] .xl\:rtl\:mx-0,[dir='rtl'].xl\:rtl\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  [dir='rtl'] .xl\:rtl\:my-1,[dir='rtl'].xl\:rtl\:my-1 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:mx-1,[dir='rtl'].xl\:rtl\:mx-1 {
    margin-left: 0.1rem;
    margin-right: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:my-2,[dir='rtl'].xl\:rtl\:my-2 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:mx-2,[dir='rtl'].xl\:rtl\:mx-2 {
    margin-left: 0.2rem;
    margin-right: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:my-3,[dir='rtl'].xl\:rtl\:my-3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:mx-3,[dir='rtl'].xl\:rtl\:mx-3 {
    margin-left: 0.3rem;
    margin-right: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:my-4,[dir='rtl'].xl\:rtl\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:mx-4,[dir='rtl'].xl\:rtl\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:my-5,[dir='rtl'].xl\:rtl\:my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:mx-5,[dir='rtl'].xl\:rtl\:mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:my-6,[dir='rtl'].xl\:rtl\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:mx-6,[dir='rtl'].xl\:rtl\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:my-7,[dir='rtl'].xl\:rtl\:my-7 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:mx-7,[dir='rtl'].xl\:rtl\:mx-7 {
    margin-left: 0.7rem;
    margin-right: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:my-8,[dir='rtl'].xl\:rtl\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:mx-8,[dir='rtl'].xl\:rtl\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:my-9,[dir='rtl'].xl\:rtl\:my-9 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:mx-9,[dir='rtl'].xl\:rtl\:mx-9 {
    margin-left: 0.9rem;
    margin-right: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:my-10,[dir='rtl'].xl\:rtl\:my-10 {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:mx-10,[dir='rtl'].xl\:rtl\:mx-10 {
    margin-left: 1.0rem;
    margin-right: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:my-12,[dir='rtl'].xl\:rtl\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:mx-12,[dir='rtl'].xl\:rtl\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:my-14,[dir='rtl'].xl\:rtl\:my-14 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:mx-14,[dir='rtl'].xl\:rtl\:mx-14 {
    margin-left: 1.4rem;
    margin-right: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:my-16,[dir='rtl'].xl\:rtl\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:mx-16,[dir='rtl'].xl\:rtl\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:my-20,[dir='rtl'].xl\:rtl\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  [dir='rtl'] .xl\:rtl\:mx-20,[dir='rtl'].xl\:rtl\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  [dir='rtl'] .xl\:rtl\:my-24,[dir='rtl'].xl\:rtl\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:mx-24,[dir='rtl'].xl\:rtl\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:my-28,[dir='rtl'].xl\:rtl\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:mx-28,[dir='rtl'].xl\:rtl\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:my-32,[dir='rtl'].xl\:rtl\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:mx-32,[dir='rtl'].xl\:rtl\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:my-36,[dir='rtl'].xl\:rtl\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:mx-36,[dir='rtl'].xl\:rtl\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:my-40,[dir='rtl'].xl\:rtl\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  [dir='rtl'] .xl\:rtl\:mx-40,[dir='rtl'].xl\:rtl\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  [dir='rtl'] .xl\:rtl\:my-44,[dir='rtl'].xl\:rtl\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:mx-44,[dir='rtl'].xl\:rtl\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:my-48,[dir='rtl'].xl\:rtl\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:mx-48,[dir='rtl'].xl\:rtl\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:my-52,[dir='rtl'].xl\:rtl\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:mx-52,[dir='rtl'].xl\:rtl\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:my-56,[dir='rtl'].xl\:rtl\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:mx-56,[dir='rtl'].xl\:rtl\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:my-60,[dir='rtl'].xl\:rtl\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  [dir='rtl'] .xl\:rtl\:mx-60,[dir='rtl'].xl\:rtl\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  [dir='rtl'] .xl\:rtl\:my-64,[dir='rtl'].xl\:rtl\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:mx-64,[dir='rtl'].xl\:rtl\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:my-68,[dir='rtl'].xl\:rtl\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:mx-68,[dir='rtl'].xl\:rtl\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:my-72,[dir='rtl'].xl\:rtl\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:mx-72,[dir='rtl'].xl\:rtl\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:my-76,[dir='rtl'].xl\:rtl\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:mx-76,[dir='rtl'].xl\:rtl\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:my-80,[dir='rtl'].xl\:rtl\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  [dir='rtl'] .xl\:rtl\:mx-80,[dir='rtl'].xl\:rtl\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  [dir='rtl'] .xl\:rtl\:my-84,[dir='rtl'].xl\:rtl\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:mx-84,[dir='rtl'].xl\:rtl\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:my-88,[dir='rtl'].xl\:rtl\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:mx-88,[dir='rtl'].xl\:rtl\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:my-92,[dir='rtl'].xl\:rtl\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:mx-92,[dir='rtl'].xl\:rtl\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:my-96,[dir='rtl'].xl\:rtl\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:mx-96,[dir='rtl'].xl\:rtl\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:my-128,[dir='rtl'].xl\:rtl\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:mx-128,[dir='rtl'].xl\:rtl\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:my-136,[dir='rtl'].xl\:rtl\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:mx-136,[dir='rtl'].xl\:rtl\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:my-160,[dir='rtl'].xl\:rtl\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  [dir='rtl'] .xl\:rtl\:mx-160,[dir='rtl'].xl\:rtl\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  [dir='rtl'] .xl\:rtl\:my-192,[dir='rtl'].xl\:rtl\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:mx-192,[dir='rtl'].xl\:rtl\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:my-200,[dir='rtl'].xl\:rtl\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  [dir='rtl'] .xl\:rtl\:mx-200,[dir='rtl'].xl\:rtl\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  [dir='rtl'] .xl\:rtl\:my-208,[dir='rtl'].xl\:rtl\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:mx-208,[dir='rtl'].xl\:rtl\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:my-216,[dir='rtl'].xl\:rtl\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:mx-216,[dir='rtl'].xl\:rtl\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:my-224,[dir='rtl'].xl\:rtl\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:mx-224,[dir='rtl'].xl\:rtl\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:my-256,[dir='rtl'].xl\:rtl\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:mx-256,[dir='rtl'].xl\:rtl\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:my-288,[dir='rtl'].xl\:rtl\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:mx-288,[dir='rtl'].xl\:rtl\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:my-320,[dir='rtl'].xl\:rtl\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='rtl'] .xl\:rtl\:mx-320,[dir='rtl'].xl\:rtl\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='rtl'] .xl\:rtl\:my-360,[dir='rtl'].xl\:rtl\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  [dir='rtl'] .xl\:rtl\:mx-360,[dir='rtl'].xl\:rtl\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  [dir='rtl'] .xl\:rtl\:my-384,[dir='rtl'].xl\:rtl\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:mx-384,[dir='rtl'].xl\:rtl\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:my-400,[dir='rtl'].xl\:rtl\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  [dir='rtl'] .xl\:rtl\:mx-400,[dir='rtl'].xl\:rtl\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  [dir='rtl'] .xl\:rtl\:my-512,[dir='rtl'].xl\:rtl\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:mx-512,[dir='rtl'].xl\:rtl\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:my-640,[dir='rtl'].xl\:rtl\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='rtl'] .xl\:rtl\:mx-640,[dir='rtl'].xl\:rtl\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='rtl'] .xl\:rtl\:my-auto,[dir='rtl'].xl\:rtl\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  [dir='rtl'] .xl\:rtl\:mx-auto,[dir='rtl'].xl\:rtl\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  [dir='rtl'] .xl\:rtl\:my-xs,[dir='rtl'].xl\:rtl\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='rtl'] .xl\:rtl\:mx-xs,[dir='rtl'].xl\:rtl\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='rtl'] .xl\:rtl\:my-sm,[dir='rtl'].xl\:rtl\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  [dir='rtl'] .xl\:rtl\:mx-sm,[dir='rtl'].xl\:rtl\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  [dir='rtl'] .xl\:rtl\:my-md,[dir='rtl'].xl\:rtl\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='rtl'] .xl\:rtl\:mx-md,[dir='rtl'].xl\:rtl\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='rtl'] .xl\:rtl\:my-lg,[dir='rtl'].xl\:rtl\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  [dir='rtl'] .xl\:rtl\:mx-lg,[dir='rtl'].xl\:rtl\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  [dir='rtl'] .xl\:rtl\:my-xl,[dir='rtl'].xl\:rtl\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  [dir='rtl'] .xl\:rtl\:mx-xl,[dir='rtl'].xl\:rtl\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  [dir='rtl'] .xl\:rtl\:my-2xl,[dir='rtl'].xl\:rtl\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  [dir='rtl'] .xl\:rtl\:mx-2xl,[dir='rtl'].xl\:rtl\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  [dir='rtl'] .xl\:rtl\:my-3xl,[dir='rtl'].xl\:rtl\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  [dir='rtl'] .xl\:rtl\:mx-3xl,[dir='rtl'].xl\:rtl\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  [dir='rtl'] .xl\:rtl\:my-4xl,[dir='rtl'].xl\:rtl\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  [dir='rtl'] .xl\:rtl\:mx-4xl,[dir='rtl'].xl\:rtl\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  [dir='rtl'] .xl\:rtl\:my-5xl,[dir='rtl'].xl\:rtl\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  [dir='rtl'] .xl\:rtl\:mx-5xl,[dir='rtl'].xl\:rtl\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  [dir='rtl'] .xl\:rtl\:my-px,[dir='rtl'].xl\:rtl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  [dir='rtl'] .xl\:rtl\:mx-px,[dir='rtl'].xl\:rtl\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  [dir='rtl'] .xl\:rtl\:my-0\.5,[dir='rtl'].xl\:rtl\:my-0\.5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:mx-0\.5,[dir='rtl'].xl\:rtl\:mx-0\.5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:my-1\.5,[dir='rtl'].xl\:rtl\:my-1\.5 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:mx-1\.5,[dir='rtl'].xl\:rtl\:mx-1\.5 {
    margin-left: 0.15rem;
    margin-right: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:my-2\.5,[dir='rtl'].xl\:rtl\:my-2\.5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:mx-2\.5,[dir='rtl'].xl\:rtl\:mx-2\.5 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:my-3\.5,[dir='rtl'].xl\:rtl\:my-3\.5 {
    margin-top: 0.35rem;
    margin-bottom: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:mx-3\.5,[dir='rtl'].xl\:rtl\:mx-3\.5 {
    margin-left: 0.35rem;
    margin-right: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:-my-1,[dir='rtl'].xl\:rtl\:-my-1 {
    margin-top: -0.1rem;
    margin-bottom: -0.1rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-1,[dir='rtl'].xl\:rtl\:-mx-1 {
    margin-left: -0.1rem;
    margin-right: -0.1rem
  }

  [dir='rtl'] .xl\:rtl\:-my-2,[dir='rtl'].xl\:rtl\:-my-2 {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-2,[dir='rtl'].xl\:rtl\:-mx-2 {
    margin-left: -0.2rem;
    margin-right: -0.2rem
  }

  [dir='rtl'] .xl\:rtl\:-my-3,[dir='rtl'].xl\:rtl\:-my-3 {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-3,[dir='rtl'].xl\:rtl\:-mx-3 {
    margin-left: -0.3rem;
    margin-right: -0.3rem
  }

  [dir='rtl'] .xl\:rtl\:-my-4,[dir='rtl'].xl\:rtl\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-4,[dir='rtl'].xl\:rtl\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  [dir='rtl'] .xl\:rtl\:-my-5,[dir='rtl'].xl\:rtl\:-my-5 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-5,[dir='rtl'].xl\:rtl\:-mx-5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  [dir='rtl'] .xl\:rtl\:-my-6,[dir='rtl'].xl\:rtl\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-6,[dir='rtl'].xl\:rtl\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  [dir='rtl'] .xl\:rtl\:-my-7,[dir='rtl'].xl\:rtl\:-my-7 {
    margin-top: -0.7rem;
    margin-bottom: -0.7rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-7,[dir='rtl'].xl\:rtl\:-mx-7 {
    margin-left: -0.7rem;
    margin-right: -0.7rem
  }

  [dir='rtl'] .xl\:rtl\:-my-8,[dir='rtl'].xl\:rtl\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-8,[dir='rtl'].xl\:rtl\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  [dir='rtl'] .xl\:rtl\:-my-9,[dir='rtl'].xl\:rtl\:-my-9 {
    margin-top: -0.9rem;
    margin-bottom: -0.9rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-9,[dir='rtl'].xl\:rtl\:-mx-9 {
    margin-left: -0.9rem;
    margin-right: -0.9rem
  }

  [dir='rtl'] .xl\:rtl\:-my-10,[dir='rtl'].xl\:rtl\:-my-10 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-10,[dir='rtl'].xl\:rtl\:-mx-10 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  [dir='rtl'] .xl\:rtl\:-my-12,[dir='rtl'].xl\:rtl\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-12,[dir='rtl'].xl\:rtl\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  [dir='rtl'] .xl\:rtl\:-my-14,[dir='rtl'].xl\:rtl\:-my-14 {
    margin-top: -1.4rem;
    margin-bottom: -1.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-14,[dir='rtl'].xl\:rtl\:-mx-14 {
    margin-left: -1.4rem;
    margin-right: -1.4rem
  }

  [dir='rtl'] .xl\:rtl\:-my-16,[dir='rtl'].xl\:rtl\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-16,[dir='rtl'].xl\:rtl\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  [dir='rtl'] .xl\:rtl\:-my-20,[dir='rtl'].xl\:rtl\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-20,[dir='rtl'].xl\:rtl\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  [dir='rtl'] .xl\:rtl\:-my-24,[dir='rtl'].xl\:rtl\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-24,[dir='rtl'].xl\:rtl\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  [dir='rtl'] .xl\:rtl\:-my-28,[dir='rtl'].xl\:rtl\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-28,[dir='rtl'].xl\:rtl\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  [dir='rtl'] .xl\:rtl\:-my-32,[dir='rtl'].xl\:rtl\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-32,[dir='rtl'].xl\:rtl\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  [dir='rtl'] .xl\:rtl\:-my-36,[dir='rtl'].xl\:rtl\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-36,[dir='rtl'].xl\:rtl\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  [dir='rtl'] .xl\:rtl\:-my-40,[dir='rtl'].xl\:rtl\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-40,[dir='rtl'].xl\:rtl\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  [dir='rtl'] .xl\:rtl\:-my-44,[dir='rtl'].xl\:rtl\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-44,[dir='rtl'].xl\:rtl\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  [dir='rtl'] .xl\:rtl\:-my-48,[dir='rtl'].xl\:rtl\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-48,[dir='rtl'].xl\:rtl\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  [dir='rtl'] .xl\:rtl\:-my-52,[dir='rtl'].xl\:rtl\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-52,[dir='rtl'].xl\:rtl\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  [dir='rtl'] .xl\:rtl\:-my-56,[dir='rtl'].xl\:rtl\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-56,[dir='rtl'].xl\:rtl\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  [dir='rtl'] .xl\:rtl\:-my-60,[dir='rtl'].xl\:rtl\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-60,[dir='rtl'].xl\:rtl\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  [dir='rtl'] .xl\:rtl\:-my-64,[dir='rtl'].xl\:rtl\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-64,[dir='rtl'].xl\:rtl\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  [dir='rtl'] .xl\:rtl\:-my-68,[dir='rtl'].xl\:rtl\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-68,[dir='rtl'].xl\:rtl\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  [dir='rtl'] .xl\:rtl\:-my-72,[dir='rtl'].xl\:rtl\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-72,[dir='rtl'].xl\:rtl\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  [dir='rtl'] .xl\:rtl\:-my-76,[dir='rtl'].xl\:rtl\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-76,[dir='rtl'].xl\:rtl\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  [dir='rtl'] .xl\:rtl\:-my-80,[dir='rtl'].xl\:rtl\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-80,[dir='rtl'].xl\:rtl\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  [dir='rtl'] .xl\:rtl\:-my-84,[dir='rtl'].xl\:rtl\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-84,[dir='rtl'].xl\:rtl\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  [dir='rtl'] .xl\:rtl\:-my-88,[dir='rtl'].xl\:rtl\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-88,[dir='rtl'].xl\:rtl\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  [dir='rtl'] .xl\:rtl\:-my-92,[dir='rtl'].xl\:rtl\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-92,[dir='rtl'].xl\:rtl\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  [dir='rtl'] .xl\:rtl\:-my-96,[dir='rtl'].xl\:rtl\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-96,[dir='rtl'].xl\:rtl\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  [dir='rtl'] .xl\:rtl\:-my-128,[dir='rtl'].xl\:rtl\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-128,[dir='rtl'].xl\:rtl\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  [dir='rtl'] .xl\:rtl\:-my-136,[dir='rtl'].xl\:rtl\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-136,[dir='rtl'].xl\:rtl\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  [dir='rtl'] .xl\:rtl\:-my-160,[dir='rtl'].xl\:rtl\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-160,[dir='rtl'].xl\:rtl\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  [dir='rtl'] .xl\:rtl\:-my-192,[dir='rtl'].xl\:rtl\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-192,[dir='rtl'].xl\:rtl\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  [dir='rtl'] .xl\:rtl\:-my-200,[dir='rtl'].xl\:rtl\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-200,[dir='rtl'].xl\:rtl\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  [dir='rtl'] .xl\:rtl\:-my-208,[dir='rtl'].xl\:rtl\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-208,[dir='rtl'].xl\:rtl\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  [dir='rtl'] .xl\:rtl\:-my-216,[dir='rtl'].xl\:rtl\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-216,[dir='rtl'].xl\:rtl\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  [dir='rtl'] .xl\:rtl\:-my-224,[dir='rtl'].xl\:rtl\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-224,[dir='rtl'].xl\:rtl\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  [dir='rtl'] .xl\:rtl\:-my-256,[dir='rtl'].xl\:rtl\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-256,[dir='rtl'].xl\:rtl\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  [dir='rtl'] .xl\:rtl\:-my-288,[dir='rtl'].xl\:rtl\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-288,[dir='rtl'].xl\:rtl\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  [dir='rtl'] .xl\:rtl\:-my-320,[dir='rtl'].xl\:rtl\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-320,[dir='rtl'].xl\:rtl\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-my-360,[dir='rtl'].xl\:rtl\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-360,[dir='rtl'].xl\:rtl\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  [dir='rtl'] .xl\:rtl\:-my-384,[dir='rtl'].xl\:rtl\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-384,[dir='rtl'].xl\:rtl\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  [dir='rtl'] .xl\:rtl\:-my-400,[dir='rtl'].xl\:rtl\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-400,[dir='rtl'].xl\:rtl\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  [dir='rtl'] .xl\:rtl\:-my-512,[dir='rtl'].xl\:rtl\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-512,[dir='rtl'].xl\:rtl\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  [dir='rtl'] .xl\:rtl\:-my-640,[dir='rtl'].xl\:rtl\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-640,[dir='rtl'].xl\:rtl\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-my-xs,[dir='rtl'].xl\:rtl\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-xs,[dir='rtl'].xl\:rtl\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-my-sm,[dir='rtl'].xl\:rtl\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-sm,[dir='rtl'].xl\:rtl\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  [dir='rtl'] .xl\:rtl\:-my-md,[dir='rtl'].xl\:rtl\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-md,[dir='rtl'].xl\:rtl\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-my-lg,[dir='rtl'].xl\:rtl\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-lg,[dir='rtl'].xl\:rtl\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  [dir='rtl'] .xl\:rtl\:-my-xl,[dir='rtl'].xl\:rtl\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-xl,[dir='rtl'].xl\:rtl\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  [dir='rtl'] .xl\:rtl\:-my-2xl,[dir='rtl'].xl\:rtl\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-2xl,[dir='rtl'].xl\:rtl\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  [dir='rtl'] .xl\:rtl\:-my-3xl,[dir='rtl'].xl\:rtl\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-3xl,[dir='rtl'].xl\:rtl\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  [dir='rtl'] .xl\:rtl\:-my-4xl,[dir='rtl'].xl\:rtl\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-4xl,[dir='rtl'].xl\:rtl\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  [dir='rtl'] .xl\:rtl\:-my-5xl,[dir='rtl'].xl\:rtl\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-5xl,[dir='rtl'].xl\:rtl\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  [dir='rtl'] .xl\:rtl\:-my-px,[dir='rtl'].xl\:rtl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  [dir='rtl'] .xl\:rtl\:-mx-px,[dir='rtl'].xl\:rtl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  [dir='rtl'] .xl\:rtl\:-my-0\.5,[dir='rtl'].xl\:rtl\:-my-0\.5 {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-0\.5,[dir='rtl'].xl\:rtl\:-mx-0\.5 {
    margin-left: -0.05rem;
    margin-right: -0.05rem
  }

  [dir='rtl'] .xl\:rtl\:-my-1\.5,[dir='rtl'].xl\:rtl\:-my-1\.5 {
    margin-top: -0.15rem;
    margin-bottom: -0.15rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-1\.5,[dir='rtl'].xl\:rtl\:-mx-1\.5 {
    margin-left: -0.15rem;
    margin-right: -0.15rem
  }

  [dir='rtl'] .xl\:rtl\:-my-2\.5,[dir='rtl'].xl\:rtl\:-my-2\.5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-2\.5,[dir='rtl'].xl\:rtl\:-mx-2\.5 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  [dir='rtl'] .xl\:rtl\:-my-3\.5,[dir='rtl'].xl\:rtl\:-my-3\.5 {
    margin-top: -0.35rem;
    margin-bottom: -0.35rem
  }

  [dir='rtl'] .xl\:rtl\:-mx-3\.5,[dir='rtl'].xl\:rtl\:-mx-3\.5 {
    margin-left: -0.35rem;
    margin-right: -0.35rem
  }

  [dir='rtl'] .xl\:rtl\:mt-0,[dir='rtl'].xl\:rtl\:mt-0 {
    margin-top: 0
  }

  [dir='rtl'] .xl\:rtl\:mr-0,[dir='rtl'].xl\:rtl\:mr-0 {
    margin-right: 0
  }

  [dir='rtl'] .xl\:rtl\:mb-0,[dir='rtl'].xl\:rtl\:mb-0 {
    margin-bottom: 0
  }

  [dir='rtl'] .xl\:rtl\:ml-0,[dir='rtl'].xl\:rtl\:ml-0 {
    margin-left: 0
  }

  [dir='rtl'] .xl\:rtl\:mt-1,[dir='rtl'].xl\:rtl\:mt-1 {
    margin-top: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:mr-1,[dir='rtl'].xl\:rtl\:mr-1 {
    margin-right: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:mb-1,[dir='rtl'].xl\:rtl\:mb-1 {
    margin-bottom: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:ml-1,[dir='rtl'].xl\:rtl\:ml-1 {
    margin-left: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:mt-2,[dir='rtl'].xl\:rtl\:mt-2 {
    margin-top: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:mr-2,[dir='rtl'].xl\:rtl\:mr-2 {
    margin-right: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:mb-2,[dir='rtl'].xl\:rtl\:mb-2 {
    margin-bottom: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:ml-2,[dir='rtl'].xl\:rtl\:ml-2 {
    margin-left: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:mt-3,[dir='rtl'].xl\:rtl\:mt-3 {
    margin-top: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:mr-3,[dir='rtl'].xl\:rtl\:mr-3 {
    margin-right: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:mb-3,[dir='rtl'].xl\:rtl\:mb-3 {
    margin-bottom: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:ml-3,[dir='rtl'].xl\:rtl\:ml-3 {
    margin-left: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:mt-4,[dir='rtl'].xl\:rtl\:mt-4 {
    margin-top: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:mr-4,[dir='rtl'].xl\:rtl\:mr-4 {
    margin-right: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:mb-4,[dir='rtl'].xl\:rtl\:mb-4 {
    margin-bottom: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:ml-4,[dir='rtl'].xl\:rtl\:ml-4 {
    margin-left: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:mt-5,[dir='rtl'].xl\:rtl\:mt-5 {
    margin-top: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:mr-5,[dir='rtl'].xl\:rtl\:mr-5 {
    margin-right: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:mb-5,[dir='rtl'].xl\:rtl\:mb-5 {
    margin-bottom: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:ml-5,[dir='rtl'].xl\:rtl\:ml-5 {
    margin-left: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:mt-6,[dir='rtl'].xl\:rtl\:mt-6 {
    margin-top: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:mr-6,[dir='rtl'].xl\:rtl\:mr-6 {
    margin-right: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:mb-6,[dir='rtl'].xl\:rtl\:mb-6 {
    margin-bottom: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:ml-6,[dir='rtl'].xl\:rtl\:ml-6 {
    margin-left: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:mt-7,[dir='rtl'].xl\:rtl\:mt-7 {
    margin-top: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:mr-7,[dir='rtl'].xl\:rtl\:mr-7 {
    margin-right: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:mb-7,[dir='rtl'].xl\:rtl\:mb-7 {
    margin-bottom: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:ml-7,[dir='rtl'].xl\:rtl\:ml-7 {
    margin-left: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:mt-8,[dir='rtl'].xl\:rtl\:mt-8 {
    margin-top: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:mr-8,[dir='rtl'].xl\:rtl\:mr-8 {
    margin-right: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:mb-8,[dir='rtl'].xl\:rtl\:mb-8 {
    margin-bottom: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:ml-8,[dir='rtl'].xl\:rtl\:ml-8 {
    margin-left: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:mt-9,[dir='rtl'].xl\:rtl\:mt-9 {
    margin-top: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:mr-9,[dir='rtl'].xl\:rtl\:mr-9 {
    margin-right: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:mb-9,[dir='rtl'].xl\:rtl\:mb-9 {
    margin-bottom: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:ml-9,[dir='rtl'].xl\:rtl\:ml-9 {
    margin-left: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:mt-10,[dir='rtl'].xl\:rtl\:mt-10 {
    margin-top: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:mr-10,[dir='rtl'].xl\:rtl\:mr-10 {
    margin-right: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:mb-10,[dir='rtl'].xl\:rtl\:mb-10 {
    margin-bottom: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:ml-10,[dir='rtl'].xl\:rtl\:ml-10 {
    margin-left: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:mt-12,[dir='rtl'].xl\:rtl\:mt-12 {
    margin-top: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:mr-12,[dir='rtl'].xl\:rtl\:mr-12 {
    margin-right: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:mb-12,[dir='rtl'].xl\:rtl\:mb-12 {
    margin-bottom: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:ml-12,[dir='rtl'].xl\:rtl\:ml-12 {
    margin-left: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:mt-14,[dir='rtl'].xl\:rtl\:mt-14 {
    margin-top: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:mr-14,[dir='rtl'].xl\:rtl\:mr-14 {
    margin-right: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:mb-14,[dir='rtl'].xl\:rtl\:mb-14 {
    margin-bottom: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:ml-14,[dir='rtl'].xl\:rtl\:ml-14 {
    margin-left: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:mt-16,[dir='rtl'].xl\:rtl\:mt-16 {
    margin-top: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:mr-16,[dir='rtl'].xl\:rtl\:mr-16 {
    margin-right: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:mb-16,[dir='rtl'].xl\:rtl\:mb-16 {
    margin-bottom: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:ml-16,[dir='rtl'].xl\:rtl\:ml-16 {
    margin-left: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:mt-20,[dir='rtl'].xl\:rtl\:mt-20 {
    margin-top: 2rem
  }

  [dir='rtl'] .xl\:rtl\:mr-20,[dir='rtl'].xl\:rtl\:mr-20 {
    margin-right: 2rem
  }

  [dir='rtl'] .xl\:rtl\:mb-20,[dir='rtl'].xl\:rtl\:mb-20 {
    margin-bottom: 2rem
  }

  [dir='rtl'] .xl\:rtl\:ml-20,[dir='rtl'].xl\:rtl\:ml-20 {
    margin-left: 2rem
  }

  [dir='rtl'] .xl\:rtl\:mt-24,[dir='rtl'].xl\:rtl\:mt-24 {
    margin-top: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:mr-24,[dir='rtl'].xl\:rtl\:mr-24 {
    margin-right: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:mb-24,[dir='rtl'].xl\:rtl\:mb-24 {
    margin-bottom: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:ml-24,[dir='rtl'].xl\:rtl\:ml-24 {
    margin-left: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:mt-28,[dir='rtl'].xl\:rtl\:mt-28 {
    margin-top: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:mr-28,[dir='rtl'].xl\:rtl\:mr-28 {
    margin-right: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:mb-28,[dir='rtl'].xl\:rtl\:mb-28 {
    margin-bottom: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:ml-28,[dir='rtl'].xl\:rtl\:ml-28 {
    margin-left: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:mt-32,[dir='rtl'].xl\:rtl\:mt-32 {
    margin-top: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:mr-32,[dir='rtl'].xl\:rtl\:mr-32 {
    margin-right: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:mb-32,[dir='rtl'].xl\:rtl\:mb-32 {
    margin-bottom: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:ml-32,[dir='rtl'].xl\:rtl\:ml-32 {
    margin-left: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:mt-36,[dir='rtl'].xl\:rtl\:mt-36 {
    margin-top: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:mr-36,[dir='rtl'].xl\:rtl\:mr-36 {
    margin-right: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:mb-36,[dir='rtl'].xl\:rtl\:mb-36 {
    margin-bottom: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:ml-36,[dir='rtl'].xl\:rtl\:ml-36 {
    margin-left: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:mt-40,[dir='rtl'].xl\:rtl\:mt-40 {
    margin-top: 4rem
  }

  [dir='rtl'] .xl\:rtl\:mr-40,[dir='rtl'].xl\:rtl\:mr-40 {
    margin-right: 4rem
  }

  [dir='rtl'] .xl\:rtl\:mb-40,[dir='rtl'].xl\:rtl\:mb-40 {
    margin-bottom: 4rem
  }

  [dir='rtl'] .xl\:rtl\:ml-40,[dir='rtl'].xl\:rtl\:ml-40 {
    margin-left: 4rem
  }

  [dir='rtl'] .xl\:rtl\:mt-44,[dir='rtl'].xl\:rtl\:mt-44 {
    margin-top: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:mr-44,[dir='rtl'].xl\:rtl\:mr-44 {
    margin-right: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:mb-44,[dir='rtl'].xl\:rtl\:mb-44 {
    margin-bottom: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:ml-44,[dir='rtl'].xl\:rtl\:ml-44 {
    margin-left: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:mt-48,[dir='rtl'].xl\:rtl\:mt-48 {
    margin-top: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:mr-48,[dir='rtl'].xl\:rtl\:mr-48 {
    margin-right: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:mb-48,[dir='rtl'].xl\:rtl\:mb-48 {
    margin-bottom: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:ml-48,[dir='rtl'].xl\:rtl\:ml-48 {
    margin-left: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:mt-52,[dir='rtl'].xl\:rtl\:mt-52 {
    margin-top: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:mr-52,[dir='rtl'].xl\:rtl\:mr-52 {
    margin-right: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:mb-52,[dir='rtl'].xl\:rtl\:mb-52 {
    margin-bottom: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:ml-52,[dir='rtl'].xl\:rtl\:ml-52 {
    margin-left: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:mt-56,[dir='rtl'].xl\:rtl\:mt-56 {
    margin-top: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:mr-56,[dir='rtl'].xl\:rtl\:mr-56 {
    margin-right: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:mb-56,[dir='rtl'].xl\:rtl\:mb-56 {
    margin-bottom: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:ml-56,[dir='rtl'].xl\:rtl\:ml-56 {
    margin-left: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:mt-60,[dir='rtl'].xl\:rtl\:mt-60 {
    margin-top: 6rem
  }

  [dir='rtl'] .xl\:rtl\:mr-60,[dir='rtl'].xl\:rtl\:mr-60 {
    margin-right: 6rem
  }

  [dir='rtl'] .xl\:rtl\:mb-60,[dir='rtl'].xl\:rtl\:mb-60 {
    margin-bottom: 6rem
  }

  [dir='rtl'] .xl\:rtl\:ml-60,[dir='rtl'].xl\:rtl\:ml-60 {
    margin-left: 6rem
  }

  [dir='rtl'] .xl\:rtl\:mt-64,[dir='rtl'].xl\:rtl\:mt-64 {
    margin-top: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:mr-64,[dir='rtl'].xl\:rtl\:mr-64 {
    margin-right: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:mb-64,[dir='rtl'].xl\:rtl\:mb-64 {
    margin-bottom: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:ml-64,[dir='rtl'].xl\:rtl\:ml-64 {
    margin-left: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:mt-68,[dir='rtl'].xl\:rtl\:mt-68 {
    margin-top: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:mr-68,[dir='rtl'].xl\:rtl\:mr-68 {
    margin-right: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:mb-68,[dir='rtl'].xl\:rtl\:mb-68 {
    margin-bottom: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:ml-68,[dir='rtl'].xl\:rtl\:ml-68 {
    margin-left: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:mt-72,[dir='rtl'].xl\:rtl\:mt-72 {
    margin-top: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:mr-72,[dir='rtl'].xl\:rtl\:mr-72 {
    margin-right: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:mb-72,[dir='rtl'].xl\:rtl\:mb-72 {
    margin-bottom: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:ml-72,[dir='rtl'].xl\:rtl\:ml-72 {
    margin-left: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:mt-76,[dir='rtl'].xl\:rtl\:mt-76 {
    margin-top: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:mr-76,[dir='rtl'].xl\:rtl\:mr-76 {
    margin-right: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:mb-76,[dir='rtl'].xl\:rtl\:mb-76 {
    margin-bottom: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:ml-76,[dir='rtl'].xl\:rtl\:ml-76 {
    margin-left: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:mt-80,[dir='rtl'].xl\:rtl\:mt-80 {
    margin-top: 8rem
  }

  [dir='rtl'] .xl\:rtl\:mr-80,[dir='rtl'].xl\:rtl\:mr-80 {
    margin-right: 8rem
  }

  [dir='rtl'] .xl\:rtl\:mb-80,[dir='rtl'].xl\:rtl\:mb-80 {
    margin-bottom: 8rem
  }

  [dir='rtl'] .xl\:rtl\:ml-80,[dir='rtl'].xl\:rtl\:ml-80 {
    margin-left: 8rem
  }

  [dir='rtl'] .xl\:rtl\:mt-84,[dir='rtl'].xl\:rtl\:mt-84 {
    margin-top: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:mr-84,[dir='rtl'].xl\:rtl\:mr-84 {
    margin-right: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:mb-84,[dir='rtl'].xl\:rtl\:mb-84 {
    margin-bottom: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:ml-84,[dir='rtl'].xl\:rtl\:ml-84 {
    margin-left: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:mt-88,[dir='rtl'].xl\:rtl\:mt-88 {
    margin-top: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:mr-88,[dir='rtl'].xl\:rtl\:mr-88 {
    margin-right: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:mb-88,[dir='rtl'].xl\:rtl\:mb-88 {
    margin-bottom: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:ml-88,[dir='rtl'].xl\:rtl\:ml-88 {
    margin-left: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:mt-92,[dir='rtl'].xl\:rtl\:mt-92 {
    margin-top: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:mr-92,[dir='rtl'].xl\:rtl\:mr-92 {
    margin-right: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:mb-92,[dir='rtl'].xl\:rtl\:mb-92 {
    margin-bottom: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:ml-92,[dir='rtl'].xl\:rtl\:ml-92 {
    margin-left: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:mt-96,[dir='rtl'].xl\:rtl\:mt-96 {
    margin-top: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:mr-96,[dir='rtl'].xl\:rtl\:mr-96 {
    margin-right: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:mb-96,[dir='rtl'].xl\:rtl\:mb-96 {
    margin-bottom: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:ml-96,[dir='rtl'].xl\:rtl\:ml-96 {
    margin-left: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:mt-128,[dir='rtl'].xl\:rtl\:mt-128 {
    margin-top: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:mr-128,[dir='rtl'].xl\:rtl\:mr-128 {
    margin-right: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:mb-128,[dir='rtl'].xl\:rtl\:mb-128 {
    margin-bottom: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:ml-128,[dir='rtl'].xl\:rtl\:ml-128 {
    margin-left: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:mt-136,[dir='rtl'].xl\:rtl\:mt-136 {
    margin-top: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:mr-136,[dir='rtl'].xl\:rtl\:mr-136 {
    margin-right: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:mb-136,[dir='rtl'].xl\:rtl\:mb-136 {
    margin-bottom: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:ml-136,[dir='rtl'].xl\:rtl\:ml-136 {
    margin-left: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:mt-160,[dir='rtl'].xl\:rtl\:mt-160 {
    margin-top: 16rem
  }

  [dir='rtl'] .xl\:rtl\:mr-160,[dir='rtl'].xl\:rtl\:mr-160 {
    margin-right: 16rem
  }

  [dir='rtl'] .xl\:rtl\:mb-160,[dir='rtl'].xl\:rtl\:mb-160 {
    margin-bottom: 16rem
  }

  [dir='rtl'] .xl\:rtl\:ml-160,[dir='rtl'].xl\:rtl\:ml-160 {
    margin-left: 16rem
  }

  [dir='rtl'] .xl\:rtl\:mt-192,[dir='rtl'].xl\:rtl\:mt-192 {
    margin-top: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:mr-192,[dir='rtl'].xl\:rtl\:mr-192 {
    margin-right: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:mb-192,[dir='rtl'].xl\:rtl\:mb-192 {
    margin-bottom: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:ml-192,[dir='rtl'].xl\:rtl\:ml-192 {
    margin-left: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:mt-200,[dir='rtl'].xl\:rtl\:mt-200 {
    margin-top: 20rem
  }

  [dir='rtl'] .xl\:rtl\:mr-200,[dir='rtl'].xl\:rtl\:mr-200 {
    margin-right: 20rem
  }

  [dir='rtl'] .xl\:rtl\:mb-200,[dir='rtl'].xl\:rtl\:mb-200 {
    margin-bottom: 20rem
  }

  [dir='rtl'] .xl\:rtl\:ml-200,[dir='rtl'].xl\:rtl\:ml-200 {
    margin-left: 20rem
  }

  [dir='rtl'] .xl\:rtl\:mt-208,[dir='rtl'].xl\:rtl\:mt-208 {
    margin-top: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:mr-208,[dir='rtl'].xl\:rtl\:mr-208 {
    margin-right: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:mb-208,[dir='rtl'].xl\:rtl\:mb-208 {
    margin-bottom: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:ml-208,[dir='rtl'].xl\:rtl\:ml-208 {
    margin-left: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:mt-216,[dir='rtl'].xl\:rtl\:mt-216 {
    margin-top: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:mr-216,[dir='rtl'].xl\:rtl\:mr-216 {
    margin-right: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:mb-216,[dir='rtl'].xl\:rtl\:mb-216 {
    margin-bottom: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:ml-216,[dir='rtl'].xl\:rtl\:ml-216 {
    margin-left: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:mt-224,[dir='rtl'].xl\:rtl\:mt-224 {
    margin-top: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:mr-224,[dir='rtl'].xl\:rtl\:mr-224 {
    margin-right: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:mb-224,[dir='rtl'].xl\:rtl\:mb-224 {
    margin-bottom: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:ml-224,[dir='rtl'].xl\:rtl\:ml-224 {
    margin-left: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:mt-256,[dir='rtl'].xl\:rtl\:mt-256 {
    margin-top: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:mr-256,[dir='rtl'].xl\:rtl\:mr-256 {
    margin-right: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:mb-256,[dir='rtl'].xl\:rtl\:mb-256 {
    margin-bottom: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:ml-256,[dir='rtl'].xl\:rtl\:ml-256 {
    margin-left: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:mt-288,[dir='rtl'].xl\:rtl\:mt-288 {
    margin-top: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:mr-288,[dir='rtl'].xl\:rtl\:mr-288 {
    margin-right: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:mb-288,[dir='rtl'].xl\:rtl\:mb-288 {
    margin-bottom: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:ml-288,[dir='rtl'].xl\:rtl\:ml-288 {
    margin-left: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:mt-320,[dir='rtl'].xl\:rtl\:mt-320 {
    margin-top: 32rem
  }

  [dir='rtl'] .xl\:rtl\:mr-320,[dir='rtl'].xl\:rtl\:mr-320 {
    margin-right: 32rem
  }

  [dir='rtl'] .xl\:rtl\:mb-320,[dir='rtl'].xl\:rtl\:mb-320 {
    margin-bottom: 32rem
  }

  [dir='rtl'] .xl\:rtl\:ml-320,[dir='rtl'].xl\:rtl\:ml-320 {
    margin-left: 32rem
  }

  [dir='rtl'] .xl\:rtl\:mt-360,[dir='rtl'].xl\:rtl\:mt-360 {
    margin-top: 36rem
  }

  [dir='rtl'] .xl\:rtl\:mr-360,[dir='rtl'].xl\:rtl\:mr-360 {
    margin-right: 36rem
  }

  [dir='rtl'] .xl\:rtl\:mb-360,[dir='rtl'].xl\:rtl\:mb-360 {
    margin-bottom: 36rem
  }

  [dir='rtl'] .xl\:rtl\:ml-360,[dir='rtl'].xl\:rtl\:ml-360 {
    margin-left: 36rem
  }

  [dir='rtl'] .xl\:rtl\:mt-384,[dir='rtl'].xl\:rtl\:mt-384 {
    margin-top: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:mr-384,[dir='rtl'].xl\:rtl\:mr-384 {
    margin-right: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:mb-384,[dir='rtl'].xl\:rtl\:mb-384 {
    margin-bottom: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:ml-384,[dir='rtl'].xl\:rtl\:ml-384 {
    margin-left: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:mt-400,[dir='rtl'].xl\:rtl\:mt-400 {
    margin-top: 40rem
  }

  [dir='rtl'] .xl\:rtl\:mr-400,[dir='rtl'].xl\:rtl\:mr-400 {
    margin-right: 40rem
  }

  [dir='rtl'] .xl\:rtl\:mb-400,[dir='rtl'].xl\:rtl\:mb-400 {
    margin-bottom: 40rem
  }

  [dir='rtl'] .xl\:rtl\:ml-400,[dir='rtl'].xl\:rtl\:ml-400 {
    margin-left: 40rem
  }

  [dir='rtl'] .xl\:rtl\:mt-512,[dir='rtl'].xl\:rtl\:mt-512 {
    margin-top: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:mr-512,[dir='rtl'].xl\:rtl\:mr-512 {
    margin-right: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:mb-512,[dir='rtl'].xl\:rtl\:mb-512 {
    margin-bottom: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:ml-512,[dir='rtl'].xl\:rtl\:ml-512 {
    margin-left: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:mt-640,[dir='rtl'].xl\:rtl\:mt-640 {
    margin-top: 64rem
  }

  [dir='rtl'] .xl\:rtl\:mr-640,[dir='rtl'].xl\:rtl\:mr-640 {
    margin-right: 64rem
  }

  [dir='rtl'] .xl\:rtl\:mb-640,[dir='rtl'].xl\:rtl\:mb-640 {
    margin-bottom: 64rem
  }

  [dir='rtl'] .xl\:rtl\:ml-640,[dir='rtl'].xl\:rtl\:ml-640 {
    margin-left: 64rem
  }

  [dir='rtl'] .xl\:rtl\:mt-auto,[dir='rtl'].xl\:rtl\:mt-auto {
    margin-top: auto
  }

  [dir='rtl'] .xl\:rtl\:mr-auto,[dir='rtl'].xl\:rtl\:mr-auto {
    margin-right: auto
  }

  [dir='rtl'] .xl\:rtl\:mb-auto,[dir='rtl'].xl\:rtl\:mb-auto {
    margin-bottom: auto
  }

  [dir='rtl'] .xl\:rtl\:ml-auto,[dir='rtl'].xl\:rtl\:ml-auto {
    margin-left: auto
  }

  [dir='rtl'] .xl\:rtl\:mt-xs,[dir='rtl'].xl\:rtl\:mt-xs {
    margin-top: 32rem
  }

  [dir='rtl'] .xl\:rtl\:mr-xs,[dir='rtl'].xl\:rtl\:mr-xs {
    margin-right: 32rem
  }

  [dir='rtl'] .xl\:rtl\:mb-xs,[dir='rtl'].xl\:rtl\:mb-xs {
    margin-bottom: 32rem
  }

  [dir='rtl'] .xl\:rtl\:ml-xs,[dir='rtl'].xl\:rtl\:ml-xs {
    margin-left: 32rem
  }

  [dir='rtl'] .xl\:rtl\:mt-sm,[dir='rtl'].xl\:rtl\:mt-sm {
    margin-top: 48rem
  }

  [dir='rtl'] .xl\:rtl\:mr-sm,[dir='rtl'].xl\:rtl\:mr-sm {
    margin-right: 48rem
  }

  [dir='rtl'] .xl\:rtl\:mb-sm,[dir='rtl'].xl\:rtl\:mb-sm {
    margin-bottom: 48rem
  }

  [dir='rtl'] .xl\:rtl\:ml-sm,[dir='rtl'].xl\:rtl\:ml-sm {
    margin-left: 48rem
  }

  [dir='rtl'] .xl\:rtl\:mt-md,[dir='rtl'].xl\:rtl\:mt-md {
    margin-top: 64rem
  }

  [dir='rtl'] .xl\:rtl\:mr-md,[dir='rtl'].xl\:rtl\:mr-md {
    margin-right: 64rem
  }

  [dir='rtl'] .xl\:rtl\:mb-md,[dir='rtl'].xl\:rtl\:mb-md {
    margin-bottom: 64rem
  }

  [dir='rtl'] .xl\:rtl\:ml-md,[dir='rtl'].xl\:rtl\:ml-md {
    margin-left: 64rem
  }

  [dir='rtl'] .xl\:rtl\:mt-lg,[dir='rtl'].xl\:rtl\:mt-lg {
    margin-top: 80rem
  }

  [dir='rtl'] .xl\:rtl\:mr-lg,[dir='rtl'].xl\:rtl\:mr-lg {
    margin-right: 80rem
  }

  [dir='rtl'] .xl\:rtl\:mb-lg,[dir='rtl'].xl\:rtl\:mb-lg {
    margin-bottom: 80rem
  }

  [dir='rtl'] .xl\:rtl\:ml-lg,[dir='rtl'].xl\:rtl\:ml-lg {
    margin-left: 80rem
  }

  [dir='rtl'] .xl\:rtl\:mt-xl,[dir='rtl'].xl\:rtl\:mt-xl {
    margin-top: 96rem
  }

  [dir='rtl'] .xl\:rtl\:mr-xl,[dir='rtl'].xl\:rtl\:mr-xl {
    margin-right: 96rem
  }

  [dir='rtl'] .xl\:rtl\:mb-xl,[dir='rtl'].xl\:rtl\:mb-xl {
    margin-bottom: 96rem
  }

  [dir='rtl'] .xl\:rtl\:ml-xl,[dir='rtl'].xl\:rtl\:ml-xl {
    margin-left: 96rem
  }

  [dir='rtl'] .xl\:rtl\:mt-2xl,[dir='rtl'].xl\:rtl\:mt-2xl {
    margin-top: 112rem
  }

  [dir='rtl'] .xl\:rtl\:mr-2xl,[dir='rtl'].xl\:rtl\:mr-2xl {
    margin-right: 112rem
  }

  [dir='rtl'] .xl\:rtl\:mb-2xl,[dir='rtl'].xl\:rtl\:mb-2xl {
    margin-bottom: 112rem
  }

  [dir='rtl'] .xl\:rtl\:ml-2xl,[dir='rtl'].xl\:rtl\:ml-2xl {
    margin-left: 112rem
  }

  [dir='rtl'] .xl\:rtl\:mt-3xl,[dir='rtl'].xl\:rtl\:mt-3xl {
    margin-top: 128rem
  }

  [dir='rtl'] .xl\:rtl\:mr-3xl,[dir='rtl'].xl\:rtl\:mr-3xl {
    margin-right: 128rem
  }

  [dir='rtl'] .xl\:rtl\:mb-3xl,[dir='rtl'].xl\:rtl\:mb-3xl {
    margin-bottom: 128rem
  }

  [dir='rtl'] .xl\:rtl\:ml-3xl,[dir='rtl'].xl\:rtl\:ml-3xl {
    margin-left: 128rem
  }

  [dir='rtl'] .xl\:rtl\:mt-4xl,[dir='rtl'].xl\:rtl\:mt-4xl {
    margin-top: 144rem
  }

  [dir='rtl'] .xl\:rtl\:mr-4xl,[dir='rtl'].xl\:rtl\:mr-4xl {
    margin-right: 144rem
  }

  [dir='rtl'] .xl\:rtl\:mb-4xl,[dir='rtl'].xl\:rtl\:mb-4xl {
    margin-bottom: 144rem
  }

  [dir='rtl'] .xl\:rtl\:ml-4xl,[dir='rtl'].xl\:rtl\:ml-4xl {
    margin-left: 144rem
  }

  [dir='rtl'] .xl\:rtl\:mt-5xl,[dir='rtl'].xl\:rtl\:mt-5xl {
    margin-top: 160rem
  }

  [dir='rtl'] .xl\:rtl\:mr-5xl,[dir='rtl'].xl\:rtl\:mr-5xl {
    margin-right: 160rem
  }

  [dir='rtl'] .xl\:rtl\:mb-5xl,[dir='rtl'].xl\:rtl\:mb-5xl {
    margin-bottom: 160rem
  }

  [dir='rtl'] .xl\:rtl\:ml-5xl,[dir='rtl'].xl\:rtl\:ml-5xl {
    margin-left: 160rem
  }

  [dir='rtl'] .xl\:rtl\:mt-px,[dir='rtl'].xl\:rtl\:mt-px {
    margin-top: 1px
  }

  [dir='rtl'] .xl\:rtl\:mr-px,[dir='rtl'].xl\:rtl\:mr-px {
    margin-right: 1px
  }

  [dir='rtl'] .xl\:rtl\:mb-px,[dir='rtl'].xl\:rtl\:mb-px {
    margin-bottom: 1px
  }

  [dir='rtl'] .xl\:rtl\:ml-px,[dir='rtl'].xl\:rtl\:ml-px {
    margin-left: 1px
  }

  [dir='rtl'] .xl\:rtl\:mt-0\.5,[dir='rtl'].xl\:rtl\:mt-0\.5 {
    margin-top: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:mr-0\.5,[dir='rtl'].xl\:rtl\:mr-0\.5 {
    margin-right: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:mb-0\.5,[dir='rtl'].xl\:rtl\:mb-0\.5 {
    margin-bottom: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:ml-0\.5,[dir='rtl'].xl\:rtl\:ml-0\.5 {
    margin-left: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:mt-1\.5,[dir='rtl'].xl\:rtl\:mt-1\.5 {
    margin-top: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:mr-1\.5,[dir='rtl'].xl\:rtl\:mr-1\.5 {
    margin-right: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:mb-1\.5,[dir='rtl'].xl\:rtl\:mb-1\.5 {
    margin-bottom: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:ml-1\.5,[dir='rtl'].xl\:rtl\:ml-1\.5 {
    margin-left: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:mt-2\.5,[dir='rtl'].xl\:rtl\:mt-2\.5 {
    margin-top: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:mr-2\.5,[dir='rtl'].xl\:rtl\:mr-2\.5 {
    margin-right: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:mb-2\.5,[dir='rtl'].xl\:rtl\:mb-2\.5 {
    margin-bottom: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:ml-2\.5,[dir='rtl'].xl\:rtl\:ml-2\.5 {
    margin-left: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:mt-3\.5,[dir='rtl'].xl\:rtl\:mt-3\.5 {
    margin-top: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:mr-3\.5,[dir='rtl'].xl\:rtl\:mr-3\.5 {
    margin-right: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:mb-3\.5,[dir='rtl'].xl\:rtl\:mb-3\.5 {
    margin-bottom: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:ml-3\.5,[dir='rtl'].xl\:rtl\:ml-3\.5 {
    margin-left: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-1,[dir='rtl'].xl\:rtl\:-mt-1 {
    margin-top: -0.1rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-1,[dir='rtl'].xl\:rtl\:-mr-1 {
    margin-right: -0.1rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-1,[dir='rtl'].xl\:rtl\:-mb-1 {
    margin-bottom: -0.1rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-1,[dir='rtl'].xl\:rtl\:-ml-1 {
    margin-left: -0.1rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-2,[dir='rtl'].xl\:rtl\:-mt-2 {
    margin-top: -0.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-2,[dir='rtl'].xl\:rtl\:-mr-2 {
    margin-right: -0.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-2,[dir='rtl'].xl\:rtl\:-mb-2 {
    margin-bottom: -0.2rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-2,[dir='rtl'].xl\:rtl\:-ml-2 {
    margin-left: -0.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-3,[dir='rtl'].xl\:rtl\:-mt-3 {
    margin-top: -0.3rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-3,[dir='rtl'].xl\:rtl\:-mr-3 {
    margin-right: -0.3rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-3,[dir='rtl'].xl\:rtl\:-mb-3 {
    margin-bottom: -0.3rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-3,[dir='rtl'].xl\:rtl\:-ml-3 {
    margin-left: -0.3rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-4,[dir='rtl'].xl\:rtl\:-mt-4 {
    margin-top: -0.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-4,[dir='rtl'].xl\:rtl\:-mr-4 {
    margin-right: -0.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-4,[dir='rtl'].xl\:rtl\:-mb-4 {
    margin-bottom: -0.4rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-4,[dir='rtl'].xl\:rtl\:-ml-4 {
    margin-left: -0.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-5,[dir='rtl'].xl\:rtl\:-mt-5 {
    margin-top: -0.5rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-5,[dir='rtl'].xl\:rtl\:-mr-5 {
    margin-right: -0.5rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-5,[dir='rtl'].xl\:rtl\:-mb-5 {
    margin-bottom: -0.5rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-5,[dir='rtl'].xl\:rtl\:-ml-5 {
    margin-left: -0.5rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-6,[dir='rtl'].xl\:rtl\:-mt-6 {
    margin-top: -0.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-6,[dir='rtl'].xl\:rtl\:-mr-6 {
    margin-right: -0.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-6,[dir='rtl'].xl\:rtl\:-mb-6 {
    margin-bottom: -0.6rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-6,[dir='rtl'].xl\:rtl\:-ml-6 {
    margin-left: -0.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-7,[dir='rtl'].xl\:rtl\:-mt-7 {
    margin-top: -0.7rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-7,[dir='rtl'].xl\:rtl\:-mr-7 {
    margin-right: -0.7rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-7,[dir='rtl'].xl\:rtl\:-mb-7 {
    margin-bottom: -0.7rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-7,[dir='rtl'].xl\:rtl\:-ml-7 {
    margin-left: -0.7rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-8,[dir='rtl'].xl\:rtl\:-mt-8 {
    margin-top: -0.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-8,[dir='rtl'].xl\:rtl\:-mr-8 {
    margin-right: -0.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-8,[dir='rtl'].xl\:rtl\:-mb-8 {
    margin-bottom: -0.8rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-8,[dir='rtl'].xl\:rtl\:-ml-8 {
    margin-left: -0.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-9,[dir='rtl'].xl\:rtl\:-mt-9 {
    margin-top: -0.9rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-9,[dir='rtl'].xl\:rtl\:-mr-9 {
    margin-right: -0.9rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-9,[dir='rtl'].xl\:rtl\:-mb-9 {
    margin-bottom: -0.9rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-9,[dir='rtl'].xl\:rtl\:-ml-9 {
    margin-left: -0.9rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-10,[dir='rtl'].xl\:rtl\:-mt-10 {
    margin-top: -1rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-10,[dir='rtl'].xl\:rtl\:-mr-10 {
    margin-right: -1rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-10,[dir='rtl'].xl\:rtl\:-mb-10 {
    margin-bottom: -1rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-10,[dir='rtl'].xl\:rtl\:-ml-10 {
    margin-left: -1rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-12,[dir='rtl'].xl\:rtl\:-mt-12 {
    margin-top: -1.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-12,[dir='rtl'].xl\:rtl\:-mr-12 {
    margin-right: -1.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-12,[dir='rtl'].xl\:rtl\:-mb-12 {
    margin-bottom: -1.2rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-12,[dir='rtl'].xl\:rtl\:-ml-12 {
    margin-left: -1.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-14,[dir='rtl'].xl\:rtl\:-mt-14 {
    margin-top: -1.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-14,[dir='rtl'].xl\:rtl\:-mr-14 {
    margin-right: -1.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-14,[dir='rtl'].xl\:rtl\:-mb-14 {
    margin-bottom: -1.4rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-14,[dir='rtl'].xl\:rtl\:-ml-14 {
    margin-left: -1.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-16,[dir='rtl'].xl\:rtl\:-mt-16 {
    margin-top: -1.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-16,[dir='rtl'].xl\:rtl\:-mr-16 {
    margin-right: -1.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-16,[dir='rtl'].xl\:rtl\:-mb-16 {
    margin-bottom: -1.6rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-16,[dir='rtl'].xl\:rtl\:-ml-16 {
    margin-left: -1.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-20,[dir='rtl'].xl\:rtl\:-mt-20 {
    margin-top: -2rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-20,[dir='rtl'].xl\:rtl\:-mr-20 {
    margin-right: -2rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-20,[dir='rtl'].xl\:rtl\:-mb-20 {
    margin-bottom: -2rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-20,[dir='rtl'].xl\:rtl\:-ml-20 {
    margin-left: -2rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-24,[dir='rtl'].xl\:rtl\:-mt-24 {
    margin-top: -2.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-24,[dir='rtl'].xl\:rtl\:-mr-24 {
    margin-right: -2.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-24,[dir='rtl'].xl\:rtl\:-mb-24 {
    margin-bottom: -2.4rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-24,[dir='rtl'].xl\:rtl\:-ml-24 {
    margin-left: -2.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-28,[dir='rtl'].xl\:rtl\:-mt-28 {
    margin-top: -2.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-28,[dir='rtl'].xl\:rtl\:-mr-28 {
    margin-right: -2.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-28,[dir='rtl'].xl\:rtl\:-mb-28 {
    margin-bottom: -2.8rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-28,[dir='rtl'].xl\:rtl\:-ml-28 {
    margin-left: -2.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-32,[dir='rtl'].xl\:rtl\:-mt-32 {
    margin-top: -3.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-32,[dir='rtl'].xl\:rtl\:-mr-32 {
    margin-right: -3.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-32,[dir='rtl'].xl\:rtl\:-mb-32 {
    margin-bottom: -3.2rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-32,[dir='rtl'].xl\:rtl\:-ml-32 {
    margin-left: -3.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-36,[dir='rtl'].xl\:rtl\:-mt-36 {
    margin-top: -3.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-36,[dir='rtl'].xl\:rtl\:-mr-36 {
    margin-right: -3.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-36,[dir='rtl'].xl\:rtl\:-mb-36 {
    margin-bottom: -3.6rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-36,[dir='rtl'].xl\:rtl\:-ml-36 {
    margin-left: -3.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-40,[dir='rtl'].xl\:rtl\:-mt-40 {
    margin-top: -4rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-40,[dir='rtl'].xl\:rtl\:-mr-40 {
    margin-right: -4rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-40,[dir='rtl'].xl\:rtl\:-mb-40 {
    margin-bottom: -4rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-40,[dir='rtl'].xl\:rtl\:-ml-40 {
    margin-left: -4rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-44,[dir='rtl'].xl\:rtl\:-mt-44 {
    margin-top: -4.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-44,[dir='rtl'].xl\:rtl\:-mr-44 {
    margin-right: -4.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-44,[dir='rtl'].xl\:rtl\:-mb-44 {
    margin-bottom: -4.4rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-44,[dir='rtl'].xl\:rtl\:-ml-44 {
    margin-left: -4.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-48,[dir='rtl'].xl\:rtl\:-mt-48 {
    margin-top: -4.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-48,[dir='rtl'].xl\:rtl\:-mr-48 {
    margin-right: -4.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-48,[dir='rtl'].xl\:rtl\:-mb-48 {
    margin-bottom: -4.8rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-48,[dir='rtl'].xl\:rtl\:-ml-48 {
    margin-left: -4.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-52,[dir='rtl'].xl\:rtl\:-mt-52 {
    margin-top: -5.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-52,[dir='rtl'].xl\:rtl\:-mr-52 {
    margin-right: -5.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-52,[dir='rtl'].xl\:rtl\:-mb-52 {
    margin-bottom: -5.2rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-52,[dir='rtl'].xl\:rtl\:-ml-52 {
    margin-left: -5.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-56,[dir='rtl'].xl\:rtl\:-mt-56 {
    margin-top: -5.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-56,[dir='rtl'].xl\:rtl\:-mr-56 {
    margin-right: -5.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-56,[dir='rtl'].xl\:rtl\:-mb-56 {
    margin-bottom: -5.6rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-56,[dir='rtl'].xl\:rtl\:-ml-56 {
    margin-left: -5.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-60,[dir='rtl'].xl\:rtl\:-mt-60 {
    margin-top: -6rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-60,[dir='rtl'].xl\:rtl\:-mr-60 {
    margin-right: -6rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-60,[dir='rtl'].xl\:rtl\:-mb-60 {
    margin-bottom: -6rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-60,[dir='rtl'].xl\:rtl\:-ml-60 {
    margin-left: -6rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-64,[dir='rtl'].xl\:rtl\:-mt-64 {
    margin-top: -6.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-64,[dir='rtl'].xl\:rtl\:-mr-64 {
    margin-right: -6.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-64,[dir='rtl'].xl\:rtl\:-mb-64 {
    margin-bottom: -6.4rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-64,[dir='rtl'].xl\:rtl\:-ml-64 {
    margin-left: -6.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-68,[dir='rtl'].xl\:rtl\:-mt-68 {
    margin-top: -6.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-68,[dir='rtl'].xl\:rtl\:-mr-68 {
    margin-right: -6.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-68,[dir='rtl'].xl\:rtl\:-mb-68 {
    margin-bottom: -6.8rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-68,[dir='rtl'].xl\:rtl\:-ml-68 {
    margin-left: -6.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-72,[dir='rtl'].xl\:rtl\:-mt-72 {
    margin-top: -7.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-72,[dir='rtl'].xl\:rtl\:-mr-72 {
    margin-right: -7.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-72,[dir='rtl'].xl\:rtl\:-mb-72 {
    margin-bottom: -7.2rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-72,[dir='rtl'].xl\:rtl\:-ml-72 {
    margin-left: -7.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-76,[dir='rtl'].xl\:rtl\:-mt-76 {
    margin-top: -7.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-76,[dir='rtl'].xl\:rtl\:-mr-76 {
    margin-right: -7.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-76,[dir='rtl'].xl\:rtl\:-mb-76 {
    margin-bottom: -7.6rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-76,[dir='rtl'].xl\:rtl\:-ml-76 {
    margin-left: -7.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-80,[dir='rtl'].xl\:rtl\:-mt-80 {
    margin-top: -8rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-80,[dir='rtl'].xl\:rtl\:-mr-80 {
    margin-right: -8rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-80,[dir='rtl'].xl\:rtl\:-mb-80 {
    margin-bottom: -8rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-80,[dir='rtl'].xl\:rtl\:-ml-80 {
    margin-left: -8rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-84,[dir='rtl'].xl\:rtl\:-mt-84 {
    margin-top: -8.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-84,[dir='rtl'].xl\:rtl\:-mr-84 {
    margin-right: -8.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-84,[dir='rtl'].xl\:rtl\:-mb-84 {
    margin-bottom: -8.4rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-84,[dir='rtl'].xl\:rtl\:-ml-84 {
    margin-left: -8.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-88,[dir='rtl'].xl\:rtl\:-mt-88 {
    margin-top: -8.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-88,[dir='rtl'].xl\:rtl\:-mr-88 {
    margin-right: -8.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-88,[dir='rtl'].xl\:rtl\:-mb-88 {
    margin-bottom: -8.8rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-88,[dir='rtl'].xl\:rtl\:-ml-88 {
    margin-left: -8.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-92,[dir='rtl'].xl\:rtl\:-mt-92 {
    margin-top: -9.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-92,[dir='rtl'].xl\:rtl\:-mr-92 {
    margin-right: -9.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-92,[dir='rtl'].xl\:rtl\:-mb-92 {
    margin-bottom: -9.2rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-92,[dir='rtl'].xl\:rtl\:-ml-92 {
    margin-left: -9.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-96,[dir='rtl'].xl\:rtl\:-mt-96 {
    margin-top: -9.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-96,[dir='rtl'].xl\:rtl\:-mr-96 {
    margin-right: -9.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-96,[dir='rtl'].xl\:rtl\:-mb-96 {
    margin-bottom: -9.6rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-96,[dir='rtl'].xl\:rtl\:-ml-96 {
    margin-left: -9.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-128,[dir='rtl'].xl\:rtl\:-mt-128 {
    margin-top: -12.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-128,[dir='rtl'].xl\:rtl\:-mr-128 {
    margin-right: -12.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-128,[dir='rtl'].xl\:rtl\:-mb-128 {
    margin-bottom: -12.8rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-128,[dir='rtl'].xl\:rtl\:-ml-128 {
    margin-left: -12.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-136,[dir='rtl'].xl\:rtl\:-mt-136 {
    margin-top: -13.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-136,[dir='rtl'].xl\:rtl\:-mr-136 {
    margin-right: -13.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-136,[dir='rtl'].xl\:rtl\:-mb-136 {
    margin-bottom: -13.6rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-136,[dir='rtl'].xl\:rtl\:-ml-136 {
    margin-left: -13.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-160,[dir='rtl'].xl\:rtl\:-mt-160 {
    margin-top: -16rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-160,[dir='rtl'].xl\:rtl\:-mr-160 {
    margin-right: -16rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-160,[dir='rtl'].xl\:rtl\:-mb-160 {
    margin-bottom: -16rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-160,[dir='rtl'].xl\:rtl\:-ml-160 {
    margin-left: -16rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-192,[dir='rtl'].xl\:rtl\:-mt-192 {
    margin-top: -19.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-192,[dir='rtl'].xl\:rtl\:-mr-192 {
    margin-right: -19.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-192,[dir='rtl'].xl\:rtl\:-mb-192 {
    margin-bottom: -19.2rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-192,[dir='rtl'].xl\:rtl\:-ml-192 {
    margin-left: -19.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-200,[dir='rtl'].xl\:rtl\:-mt-200 {
    margin-top: -20rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-200,[dir='rtl'].xl\:rtl\:-mr-200 {
    margin-right: -20rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-200,[dir='rtl'].xl\:rtl\:-mb-200 {
    margin-bottom: -20rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-200,[dir='rtl'].xl\:rtl\:-ml-200 {
    margin-left: -20rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-208,[dir='rtl'].xl\:rtl\:-mt-208 {
    margin-top: -20.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-208,[dir='rtl'].xl\:rtl\:-mr-208 {
    margin-right: -20.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-208,[dir='rtl'].xl\:rtl\:-mb-208 {
    margin-bottom: -20.8rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-208,[dir='rtl'].xl\:rtl\:-ml-208 {
    margin-left: -20.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-216,[dir='rtl'].xl\:rtl\:-mt-216 {
    margin-top: -21.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-216,[dir='rtl'].xl\:rtl\:-mr-216 {
    margin-right: -21.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-216,[dir='rtl'].xl\:rtl\:-mb-216 {
    margin-bottom: -21.6rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-216,[dir='rtl'].xl\:rtl\:-ml-216 {
    margin-left: -21.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-224,[dir='rtl'].xl\:rtl\:-mt-224 {
    margin-top: -22.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-224,[dir='rtl'].xl\:rtl\:-mr-224 {
    margin-right: -22.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-224,[dir='rtl'].xl\:rtl\:-mb-224 {
    margin-bottom: -22.4rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-224,[dir='rtl'].xl\:rtl\:-ml-224 {
    margin-left: -22.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-256,[dir='rtl'].xl\:rtl\:-mt-256 {
    margin-top: -25.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-256,[dir='rtl'].xl\:rtl\:-mr-256 {
    margin-right: -25.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-256,[dir='rtl'].xl\:rtl\:-mb-256 {
    margin-bottom: -25.6rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-256,[dir='rtl'].xl\:rtl\:-ml-256 {
    margin-left: -25.6rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-288,[dir='rtl'].xl\:rtl\:-mt-288 {
    margin-top: -28.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-288,[dir='rtl'].xl\:rtl\:-mr-288 {
    margin-right: -28.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-288,[dir='rtl'].xl\:rtl\:-mb-288 {
    margin-bottom: -28.8rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-288,[dir='rtl'].xl\:rtl\:-ml-288 {
    margin-left: -28.8rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-320,[dir='rtl'].xl\:rtl\:-mt-320 {
    margin-top: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-320,[dir='rtl'].xl\:rtl\:-mr-320 {
    margin-right: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-320,[dir='rtl'].xl\:rtl\:-mb-320 {
    margin-bottom: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-320,[dir='rtl'].xl\:rtl\:-ml-320 {
    margin-left: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-360,[dir='rtl'].xl\:rtl\:-mt-360 {
    margin-top: -36rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-360,[dir='rtl'].xl\:rtl\:-mr-360 {
    margin-right: -36rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-360,[dir='rtl'].xl\:rtl\:-mb-360 {
    margin-bottom: -36rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-360,[dir='rtl'].xl\:rtl\:-ml-360 {
    margin-left: -36rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-384,[dir='rtl'].xl\:rtl\:-mt-384 {
    margin-top: -38.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-384,[dir='rtl'].xl\:rtl\:-mr-384 {
    margin-right: -38.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-384,[dir='rtl'].xl\:rtl\:-mb-384 {
    margin-bottom: -38.4rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-384,[dir='rtl'].xl\:rtl\:-ml-384 {
    margin-left: -38.4rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-400,[dir='rtl'].xl\:rtl\:-mt-400 {
    margin-top: -40rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-400,[dir='rtl'].xl\:rtl\:-mr-400 {
    margin-right: -40rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-400,[dir='rtl'].xl\:rtl\:-mb-400 {
    margin-bottom: -40rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-400,[dir='rtl'].xl\:rtl\:-ml-400 {
    margin-left: -40rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-512,[dir='rtl'].xl\:rtl\:-mt-512 {
    margin-top: -51.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-512,[dir='rtl'].xl\:rtl\:-mr-512 {
    margin-right: -51.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-512,[dir='rtl'].xl\:rtl\:-mb-512 {
    margin-bottom: -51.2rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-512,[dir='rtl'].xl\:rtl\:-ml-512 {
    margin-left: -51.2rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-640,[dir='rtl'].xl\:rtl\:-mt-640 {
    margin-top: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-640,[dir='rtl'].xl\:rtl\:-mr-640 {
    margin-right: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-640,[dir='rtl'].xl\:rtl\:-mb-640 {
    margin-bottom: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-640,[dir='rtl'].xl\:rtl\:-ml-640 {
    margin-left: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-xs,[dir='rtl'].xl\:rtl\:-mt-xs {
    margin-top: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-xs,[dir='rtl'].xl\:rtl\:-mr-xs {
    margin-right: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-xs,[dir='rtl'].xl\:rtl\:-mb-xs {
    margin-bottom: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-xs,[dir='rtl'].xl\:rtl\:-ml-xs {
    margin-left: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-sm,[dir='rtl'].xl\:rtl\:-mt-sm {
    margin-top: -48rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-sm,[dir='rtl'].xl\:rtl\:-mr-sm {
    margin-right: -48rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-sm,[dir='rtl'].xl\:rtl\:-mb-sm {
    margin-bottom: -48rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-sm,[dir='rtl'].xl\:rtl\:-ml-sm {
    margin-left: -48rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-md,[dir='rtl'].xl\:rtl\:-mt-md {
    margin-top: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-md,[dir='rtl'].xl\:rtl\:-mr-md {
    margin-right: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-md,[dir='rtl'].xl\:rtl\:-mb-md {
    margin-bottom: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-md,[dir='rtl'].xl\:rtl\:-ml-md {
    margin-left: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-lg,[dir='rtl'].xl\:rtl\:-mt-lg {
    margin-top: -80rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-lg,[dir='rtl'].xl\:rtl\:-mr-lg {
    margin-right: -80rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-lg,[dir='rtl'].xl\:rtl\:-mb-lg {
    margin-bottom: -80rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-lg,[dir='rtl'].xl\:rtl\:-ml-lg {
    margin-left: -80rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-xl,[dir='rtl'].xl\:rtl\:-mt-xl {
    margin-top: -96rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-xl,[dir='rtl'].xl\:rtl\:-mr-xl {
    margin-right: -96rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-xl,[dir='rtl'].xl\:rtl\:-mb-xl {
    margin-bottom: -96rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-xl,[dir='rtl'].xl\:rtl\:-ml-xl {
    margin-left: -96rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-2xl,[dir='rtl'].xl\:rtl\:-mt-2xl {
    margin-top: -112rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-2xl,[dir='rtl'].xl\:rtl\:-mr-2xl {
    margin-right: -112rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-2xl,[dir='rtl'].xl\:rtl\:-mb-2xl {
    margin-bottom: -112rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-2xl,[dir='rtl'].xl\:rtl\:-ml-2xl {
    margin-left: -112rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-3xl,[dir='rtl'].xl\:rtl\:-mt-3xl {
    margin-top: -128rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-3xl,[dir='rtl'].xl\:rtl\:-mr-3xl {
    margin-right: -128rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-3xl,[dir='rtl'].xl\:rtl\:-mb-3xl {
    margin-bottom: -128rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-3xl,[dir='rtl'].xl\:rtl\:-ml-3xl {
    margin-left: -128rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-4xl,[dir='rtl'].xl\:rtl\:-mt-4xl {
    margin-top: -144rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-4xl,[dir='rtl'].xl\:rtl\:-mr-4xl {
    margin-right: -144rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-4xl,[dir='rtl'].xl\:rtl\:-mb-4xl {
    margin-bottom: -144rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-4xl,[dir='rtl'].xl\:rtl\:-ml-4xl {
    margin-left: -144rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-5xl,[dir='rtl'].xl\:rtl\:-mt-5xl {
    margin-top: -160rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-5xl,[dir='rtl'].xl\:rtl\:-mr-5xl {
    margin-right: -160rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-5xl,[dir='rtl'].xl\:rtl\:-mb-5xl {
    margin-bottom: -160rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-5xl,[dir='rtl'].xl\:rtl\:-ml-5xl {
    margin-left: -160rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-px,[dir='rtl'].xl\:rtl\:-mt-px {
    margin-top: -1px
  }

  [dir='rtl'] .xl\:rtl\:-mr-px,[dir='rtl'].xl\:rtl\:-mr-px {
    margin-right: -1px
  }

  [dir='rtl'] .xl\:rtl\:-mb-px,[dir='rtl'].xl\:rtl\:-mb-px {
    margin-bottom: -1px
  }

  [dir='rtl'] .xl\:rtl\:-ml-px,[dir='rtl'].xl\:rtl\:-ml-px {
    margin-left: -1px
  }

  [dir='rtl'] .xl\:rtl\:-mt-0\.5,[dir='rtl'].xl\:rtl\:-mt-0\.5 {
    margin-top: -0.05rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-0\.5,[dir='rtl'].xl\:rtl\:-mr-0\.5 {
    margin-right: -0.05rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-0\.5,[dir='rtl'].xl\:rtl\:-mb-0\.5 {
    margin-bottom: -0.05rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-0\.5,[dir='rtl'].xl\:rtl\:-ml-0\.5 {
    margin-left: -0.05rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-1\.5,[dir='rtl'].xl\:rtl\:-mt-1\.5 {
    margin-top: -0.15rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-1\.5,[dir='rtl'].xl\:rtl\:-mr-1\.5 {
    margin-right: -0.15rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-1\.5,[dir='rtl'].xl\:rtl\:-mb-1\.5 {
    margin-bottom: -0.15rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-1\.5,[dir='rtl'].xl\:rtl\:-ml-1\.5 {
    margin-left: -0.15rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-2\.5,[dir='rtl'].xl\:rtl\:-mt-2\.5 {
    margin-top: -0.25rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-2\.5,[dir='rtl'].xl\:rtl\:-mr-2\.5 {
    margin-right: -0.25rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-2\.5,[dir='rtl'].xl\:rtl\:-mb-2\.5 {
    margin-bottom: -0.25rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-2\.5,[dir='rtl'].xl\:rtl\:-ml-2\.5 {
    margin-left: -0.25rem
  }

  [dir='rtl'] .xl\:rtl\:-mt-3\.5,[dir='rtl'].xl\:rtl\:-mt-3\.5 {
    margin-top: -0.35rem
  }

  [dir='rtl'] .xl\:rtl\:-mr-3\.5,[dir='rtl'].xl\:rtl\:-mr-3\.5 {
    margin-right: -0.35rem
  }

  [dir='rtl'] .xl\:rtl\:-mb-3\.5,[dir='rtl'].xl\:rtl\:-mb-3\.5 {
    margin-bottom: -0.35rem
  }

  [dir='rtl'] .xl\:rtl\:-ml-3\.5,[dir='rtl'].xl\:rtl\:-ml-3\.5 {
    margin-left: -0.35rem
  }

  .xl\:max-h-0 {
    max-height: 0
  }

  .xl\:max-h-1 {
    max-height: 0.1rem
  }

  .xl\:max-h-2 {
    max-height: 0.2rem
  }

  .xl\:max-h-3 {
    max-height: 0.3rem
  }

  .xl\:max-h-4 {
    max-height: 0.4rem
  }

  .xl\:max-h-5 {
    max-height: 0.5rem
  }

  .xl\:max-h-6 {
    max-height: 0.6rem
  }

  .xl\:max-h-7 {
    max-height: 0.7rem
  }

  .xl\:max-h-8 {
    max-height: 0.8rem
  }

  .xl\:max-h-9 {
    max-height: 0.9rem
  }

  .xl\:max-h-10 {
    max-height: 1.0rem
  }

  .xl\:max-h-12 {
    max-height: 1.2rem
  }

  .xl\:max-h-14 {
    max-height: 1.4rem
  }

  .xl\:max-h-16 {
    max-height: 1.6rem
  }

  .xl\:max-h-20 {
    max-height: 2rem
  }

  .xl\:max-h-24 {
    max-height: 2.4rem
  }

  .xl\:max-h-28 {
    max-height: 2.8rem
  }

  .xl\:max-h-32 {
    max-height: 3.2rem
  }

  .xl\:max-h-36 {
    max-height: 3.6rem
  }

  .xl\:max-h-40 {
    max-height: 4rem
  }

  .xl\:max-h-44 {
    max-height: 4.4rem
  }

  .xl\:max-h-48 {
    max-height: 4.8rem
  }

  .xl\:max-h-52 {
    max-height: 5.2rem
  }

  .xl\:max-h-56 {
    max-height: 5.6rem
  }

  .xl\:max-h-60 {
    max-height: 6rem
  }

  .xl\:max-h-64 {
    max-height: 6.4rem
  }

  .xl\:max-h-68 {
    max-height: 6.8rem
  }

  .xl\:max-h-72 {
    max-height: 7.2rem
  }

  .xl\:max-h-76 {
    max-height: 7.6rem
  }

  .xl\:max-h-80 {
    max-height: 8rem
  }

  .xl\:max-h-84 {
    max-height: 8.4rem
  }

  .xl\:max-h-88 {
    max-height: 8.8rem
  }

  .xl\:max-h-92 {
    max-height: 9.2rem
  }

  .xl\:max-h-96 {
    max-height: 9.6rem
  }

  .xl\:max-h-128 {
    max-height: 12.8rem
  }

  .xl\:max-h-136 {
    max-height: 13.6rem
  }

  .xl\:max-h-160 {
    max-height: 16rem
  }

  .xl\:max-h-192 {
    max-height: 19.2rem
  }

  .xl\:max-h-200 {
    max-height: 20rem
  }

  .xl\:max-h-208 {
    max-height: 20.8rem
  }

  .xl\:max-h-216 {
    max-height: 21.6rem
  }

  .xl\:max-h-224 {
    max-height: 22.4rem
  }

  .xl\:max-h-256 {
    max-height: 25.6rem
  }

  .xl\:max-h-288 {
    max-height: 28.8rem
  }

  .xl\:max-h-320 {
    max-height: 32rem
  }

  .xl\:max-h-360 {
    max-height: 36rem
  }

  .xl\:max-h-384 {
    max-height: 38.4rem
  }

  .xl\:max-h-400 {
    max-height: 40rem
  }

  .xl\:max-h-512 {
    max-height: 51.2rem
  }

  .xl\:max-h-640 {
    max-height: 64rem
  }

  .xl\:max-h-xs {
    max-height: 32rem
  }

  .xl\:max-h-sm {
    max-height: 48rem
  }

  .xl\:max-h-md {
    max-height: 64rem
  }

  .xl\:max-h-lg {
    max-height: 80rem
  }

  .xl\:max-h-xl {
    max-height: 96rem
  }

  .xl\:max-h-2xl {
    max-height: 112rem
  }

  .xl\:max-h-3xl {
    max-height: 128rem
  }

  .xl\:max-h-4xl {
    max-height: 144rem
  }

  .xl\:max-h-5xl {
    max-height: 160rem
  }

  .xl\:max-h-px {
    max-height: 1px
  }

  .xl\:max-h-0\.5 {
    max-height: 0.05rem
  }

  .xl\:max-h-1\.5 {
    max-height: 0.15rem
  }

  .xl\:max-h-2\.5 {
    max-height: 0.25rem
  }

  .xl\:max-h-3\.5 {
    max-height: 0.35rem
  }

  .xl\:max-h-full {
    max-height: 100%
  }

  .xl\:max-h-screen {
    max-height: 100vh
  }

  .xl\:max-h-auto {
    max-height: auto
  }

  .xl\:max-w-0 {
    max-width: 0
  }

  .xl\:max-w-1 {
    max-width: 0.1rem
  }

  .xl\:max-w-2 {
    max-width: 0.2rem
  }

  .xl\:max-w-3 {
    max-width: 0.3rem
  }

  .xl\:max-w-4 {
    max-width: 0.4rem
  }

  .xl\:max-w-5 {
    max-width: 0.5rem
  }

  .xl\:max-w-6 {
    max-width: 0.6rem
  }

  .xl\:max-w-7 {
    max-width: 0.7rem
  }

  .xl\:max-w-8 {
    max-width: 0.8rem
  }

  .xl\:max-w-9 {
    max-width: 0.9rem
  }

  .xl\:max-w-10 {
    max-width: 1.0rem
  }

  .xl\:max-w-12 {
    max-width: 1.2rem
  }

  .xl\:max-w-14 {
    max-width: 1.4rem
  }

  .xl\:max-w-16 {
    max-width: 1.6rem
  }

  .xl\:max-w-20 {
    max-width: 2rem
  }

  .xl\:max-w-24 {
    max-width: 2.4rem
  }

  .xl\:max-w-28 {
    max-width: 2.8rem
  }

  .xl\:max-w-32 {
    max-width: 3.2rem
  }

  .xl\:max-w-36 {
    max-width: 3.6rem
  }

  .xl\:max-w-40 {
    max-width: 4rem
  }

  .xl\:max-w-44 {
    max-width: 4.4rem
  }

  .xl\:max-w-48 {
    max-width: 4.8rem
  }

  .xl\:max-w-52 {
    max-width: 5.2rem
  }

  .xl\:max-w-56 {
    max-width: 5.6rem
  }

  .xl\:max-w-60 {
    max-width: 6rem
  }

  .xl\:max-w-64 {
    max-width: 6.4rem
  }

  .xl\:max-w-68 {
    max-width: 6.8rem
  }

  .xl\:max-w-72 {
    max-width: 7.2rem
  }

  .xl\:max-w-76 {
    max-width: 7.6rem
  }

  .xl\:max-w-80 {
    max-width: 8rem
  }

  .xl\:max-w-84 {
    max-width: 8.4rem
  }

  .xl\:max-w-88 {
    max-width: 8.8rem
  }

  .xl\:max-w-92 {
    max-width: 9.2rem
  }

  .xl\:max-w-96 {
    max-width: 9.6rem
  }

  .xl\:max-w-128 {
    max-width: 12.8rem
  }

  .xl\:max-w-136 {
    max-width: 13.6rem
  }

  .xl\:max-w-160 {
    max-width: 16rem
  }

  .xl\:max-w-192 {
    max-width: 19.2rem
  }

  .xl\:max-w-200 {
    max-width: 20rem
  }

  .xl\:max-w-208 {
    max-width: 20.8rem
  }

  .xl\:max-w-216 {
    max-width: 21.6rem
  }

  .xl\:max-w-224 {
    max-width: 22.4rem
  }

  .xl\:max-w-256 {
    max-width: 25.6rem
  }

  .xl\:max-w-288 {
    max-width: 28.8rem
  }

  .xl\:max-w-320 {
    max-width: 32rem
  }

  .xl\:max-w-360 {
    max-width: 36rem
  }

  .xl\:max-w-384 {
    max-width: 38.4rem
  }

  .xl\:max-w-400 {
    max-width: 40rem
  }

  .xl\:max-w-512 {
    max-width: 51.2rem
  }

  .xl\:max-w-640 {
    max-width: 64rem
  }

  .xl\:max-w-none {
    max-width: none
  }

  .xl\:max-w-xs {
    max-width: 32rem
  }

  .xl\:max-w-sm {
    max-width: 48rem
  }

  .xl\:max-w-md {
    max-width: 64rem
  }

  .xl\:max-w-lg {
    max-width: 80rem
  }

  .xl\:max-w-xl {
    max-width: 96rem
  }

  .xl\:max-w-2xl {
    max-width: 112rem
  }

  .xl\:max-w-3xl {
    max-width: 128rem
  }

  .xl\:max-w-4xl {
    max-width: 144rem
  }

  .xl\:max-w-5xl {
    max-width: 160rem
  }

  .xl\:max-w-px {
    max-width: 1px
  }

  .xl\:max-w-0\.5 {
    max-width: 0.05rem
  }

  .xl\:max-w-1\.5 {
    max-width: 0.15rem
  }

  .xl\:max-w-2\.5 {
    max-width: 0.25rem
  }

  .xl\:max-w-3\.5 {
    max-width: 0.35rem
  }

  .xl\:max-w-full {
    max-width: 100%
  }

  .xl\:max-w-min {
    max-width: min-content
  }

  .xl\:max-w-max {
    max-width: max-content
  }

  .xl\:max-w-prose {
    max-width: 65ch
  }

  .xl\:max-w-screen-sm {
    max-width: 600px
  }

  .xl\:max-w-screen-md {
    max-width: 960px
  }

  .xl\:max-w-screen-lg {
    max-width: 1280px
  }

  .xl\:max-w-screen-xl {
    max-width: 1920px
  }

  .xl\:min-h-0 {
    min-height: 0
  }

  .xl\:min-h-1 {
    min-height: 0.1rem
  }

  .xl\:min-h-2 {
    min-height: 0.2rem
  }

  .xl\:min-h-3 {
    min-height: 0.3rem
  }

  .xl\:min-h-4 {
    min-height: 0.4rem
  }

  .xl\:min-h-5 {
    min-height: 0.5rem
  }

  .xl\:min-h-6 {
    min-height: 0.6rem
  }

  .xl\:min-h-7 {
    min-height: 0.7rem
  }

  .xl\:min-h-8 {
    min-height: 0.8rem
  }

  .xl\:min-h-9 {
    min-height: 0.9rem
  }

  .xl\:min-h-10 {
    min-height: 1.0rem
  }

  .xl\:min-h-12 {
    min-height: 1.2rem
  }

  .xl\:min-h-14 {
    min-height: 1.4rem
  }

  .xl\:min-h-16 {
    min-height: 1.6rem
  }

  .xl\:min-h-20 {
    min-height: 2rem
  }

  .xl\:min-h-24 {
    min-height: 2.4rem
  }

  .xl\:min-h-28 {
    min-height: 2.8rem
  }

  .xl\:min-h-32 {
    min-height: 3.2rem
  }

  .xl\:min-h-36 {
    min-height: 3.6rem
  }

  .xl\:min-h-40 {
    min-height: 4rem
  }

  .xl\:min-h-44 {
    min-height: 4.4rem
  }

  .xl\:min-h-48 {
    min-height: 4.8rem
  }

  .xl\:min-h-52 {
    min-height: 5.2rem
  }

  .xl\:min-h-56 {
    min-height: 5.6rem
  }

  .xl\:min-h-60 {
    min-height: 6rem
  }

  .xl\:min-h-64 {
    min-height: 6.4rem
  }

  .xl\:min-h-68 {
    min-height: 6.8rem
  }

  .xl\:min-h-72 {
    min-height: 7.2rem
  }

  .xl\:min-h-76 {
    min-height: 7.6rem
  }

  .xl\:min-h-80 {
    min-height: 8rem
  }

  .xl\:min-h-84 {
    min-height: 8.4rem
  }

  .xl\:min-h-88 {
    min-height: 8.8rem
  }

  .xl\:min-h-92 {
    min-height: 9.2rem
  }

  .xl\:min-h-96 {
    min-height: 9.6rem
  }

  .xl\:min-h-128 {
    min-height: 12.8rem
  }

  .xl\:min-h-136 {
    min-height: 13.6rem
  }

  .xl\:min-h-160 {
    min-height: 16rem
  }

  .xl\:min-h-192 {
    min-height: 19.2rem
  }

  .xl\:min-h-200 {
    min-height: 20rem
  }

  .xl\:min-h-208 {
    min-height: 20.8rem
  }

  .xl\:min-h-216 {
    min-height: 21.6rem
  }

  .xl\:min-h-224 {
    min-height: 22.4rem
  }

  .xl\:min-h-256 {
    min-height: 25.6rem
  }

  .xl\:min-h-288 {
    min-height: 28.8rem
  }

  .xl\:min-h-320 {
    min-height: 32rem
  }

  .xl\:min-h-360 {
    min-height: 36rem
  }

  .xl\:min-h-384 {
    min-height: 38.4rem
  }

  .xl\:min-h-400 {
    min-height: 40rem
  }

  .xl\:min-h-512 {
    min-height: 51.2rem
  }

  .xl\:min-h-640 {
    min-height: 64rem
  }

  .xl\:min-h-auto {
    min-height: auto
  }

  .xl\:min-h-xs {
    min-height: 32rem
  }

  .xl\:min-h-sm {
    min-height: 48rem
  }

  .xl\:min-h-md {
    min-height: 64rem
  }

  .xl\:min-h-lg {
    min-height: 80rem
  }

  .xl\:min-h-xl {
    min-height: 96rem
  }

  .xl\:min-h-2xl {
    min-height: 112rem
  }

  .xl\:min-h-3xl {
    min-height: 128rem
  }

  .xl\:min-h-4xl {
    min-height: 144rem
  }

  .xl\:min-h-5xl {
    min-height: 160rem
  }

  .xl\:min-h-px {
    min-height: 1px
  }

  .xl\:min-h-0\.5 {
    min-height: 0.05rem
  }

  .xl\:min-h-1\.5 {
    min-height: 0.15rem
  }

  .xl\:min-h-2\.5 {
    min-height: 0.25rem
  }

  .xl\:min-h-3\.5 {
    min-height: 0.35rem
  }

  .xl\:min-h-full {
    min-height: 100%
  }

  .xl\:min-h-screen {
    min-height: 100vh
  }

  .xl\:min-w-0 {
    min-width: 0
  }

  .xl\:min-w-1 {
    min-width: 0.1rem
  }

  .xl\:min-w-2 {
    min-width: 0.2rem
  }

  .xl\:min-w-3 {
    min-width: 0.3rem
  }

  .xl\:min-w-4 {
    min-width: 0.4rem
  }

  .xl\:min-w-5 {
    min-width: 0.5rem
  }

  .xl\:min-w-6 {
    min-width: 0.6rem
  }

  .xl\:min-w-7 {
    min-width: 0.7rem
  }

  .xl\:min-w-8 {
    min-width: 0.8rem
  }

  .xl\:min-w-9 {
    min-width: 0.9rem
  }

  .xl\:min-w-10 {
    min-width: 1.0rem
  }

  .xl\:min-w-12 {
    min-width: 1.2rem
  }

  .xl\:min-w-14 {
    min-width: 1.4rem
  }

  .xl\:min-w-16 {
    min-width: 1.6rem
  }

  .xl\:min-w-20 {
    min-width: 2rem
  }

  .xl\:min-w-24 {
    min-width: 2.4rem
  }

  .xl\:min-w-28 {
    min-width: 2.8rem
  }

  .xl\:min-w-32 {
    min-width: 3.2rem
  }

  .xl\:min-w-36 {
    min-width: 3.6rem
  }

  .xl\:min-w-40 {
    min-width: 4rem
  }

  .xl\:min-w-44 {
    min-width: 4.4rem
  }

  .xl\:min-w-48 {
    min-width: 4.8rem
  }

  .xl\:min-w-52 {
    min-width: 5.2rem
  }

  .xl\:min-w-56 {
    min-width: 5.6rem
  }

  .xl\:min-w-60 {
    min-width: 6rem
  }

  .xl\:min-w-64 {
    min-width: 6.4rem
  }

  .xl\:min-w-68 {
    min-width: 6.8rem
  }

  .xl\:min-w-72 {
    min-width: 7.2rem
  }

  .xl\:min-w-76 {
    min-width: 7.6rem
  }

  .xl\:min-w-80 {
    min-width: 8rem
  }

  .xl\:min-w-84 {
    min-width: 8.4rem
  }

  .xl\:min-w-88 {
    min-width: 8.8rem
  }

  .xl\:min-w-92 {
    min-width: 9.2rem
  }

  .xl\:min-w-96 {
    min-width: 9.6rem
  }

  .xl\:min-w-128 {
    min-width: 12.8rem
  }

  .xl\:min-w-136 {
    min-width: 13.6rem
  }

  .xl\:min-w-160 {
    min-width: 16rem
  }

  .xl\:min-w-192 {
    min-width: 19.2rem
  }

  .xl\:min-w-200 {
    min-width: 20rem
  }

  .xl\:min-w-208 {
    min-width: 20.8rem
  }

  .xl\:min-w-216 {
    min-width: 21.6rem
  }

  .xl\:min-w-224 {
    min-width: 22.4rem
  }

  .xl\:min-w-256 {
    min-width: 25.6rem
  }

  .xl\:min-w-288 {
    min-width: 28.8rem
  }

  .xl\:min-w-320 {
    min-width: 32rem
  }

  .xl\:min-w-360 {
    min-width: 36rem
  }

  .xl\:min-w-384 {
    min-width: 38.4rem
  }

  .xl\:min-w-400 {
    min-width: 40rem
  }

  .xl\:min-w-512 {
    min-width: 51.2rem
  }

  .xl\:min-w-640 {
    min-width: 64rem
  }

  .xl\:min-w-xs {
    min-width: 32rem
  }

  .xl\:min-w-sm {
    min-width: 48rem
  }

  .xl\:min-w-md {
    min-width: 64rem
  }

  .xl\:min-w-lg {
    min-width: 80rem
  }

  .xl\:min-w-xl {
    min-width: 96rem
  }

  .xl\:min-w-2xl {
    min-width: 112rem
  }

  .xl\:min-w-3xl {
    min-width: 128rem
  }

  .xl\:min-w-4xl {
    min-width: 144rem
  }

  .xl\:min-w-5xl {
    min-width: 160rem
  }

  .xl\:min-w-px {
    min-width: 1px
  }

  .xl\:min-w-0\.5 {
    min-width: 0.05rem
  }

  .xl\:min-w-1\.5 {
    min-width: 0.15rem
  }

  .xl\:min-w-2\.5 {
    min-width: 0.25rem
  }

  .xl\:min-w-3\.5 {
    min-width: 0.35rem
  }

  .xl\:min-w-full {
    min-width: 100%
  }

  .xl\:min-w-min {
    min-width: min-content
  }

  .xl\:min-w-max {
    min-width: max-content
  }

  .xl\:min-w-screen {
    min-width: 100vw
  }

  .xl\:object-contain {
    object-fit: contain
  }

  .xl\:object-cover {
    object-fit: cover
  }

  .xl\:object-fill {
    object-fit: fill
  }

  .xl\:object-none {
    object-fit: none
  }

  .xl\:object-scale-down {
    object-fit: scale-down
  }

  .xl\:object-bottom {
    object-position: bottom
  }

  .xl\:object-center {
    object-position: center
  }

  .xl\:object-left {
    object-position: left
  }

  .xl\:object-left-bottom {
    object-position: left bottom
  }

  .xl\:object-left-top {
    object-position: left top
  }

  .xl\:object-right {
    object-position: right
  }

  .xl\:object-right-bottom {
    object-position: right bottom
  }

  .xl\:object-right-top {
    object-position: right top
  }

  .xl\:object-top {
    object-position: top
  }

  .xl\:opacity-0 {
    opacity: 0
  }

  .xl\:opacity-5 {
    opacity: 0.05
  }

  .xl\:opacity-10 {
    opacity: 0.1
  }

  .xl\:opacity-20 {
    opacity: 0.2
  }

  .xl\:opacity-25 {
    opacity: 0.25
  }

  .xl\:opacity-30 {
    opacity: 0.3
  }

  .xl\:opacity-40 {
    opacity: 0.4
  }

  .xl\:opacity-50 {
    opacity: 0.5
  }

  .xl\:opacity-60 {
    opacity: 0.6
  }

  .xl\:opacity-70 {
    opacity: 0.7
  }

  .xl\:opacity-75 {
    opacity: 0.75
  }

  .xl\:opacity-80 {
    opacity: 0.8
  }

  .xl\:opacity-90 {
    opacity: 0.9
  }

  .xl\:opacity-95 {
    opacity: 0.95
  }

  .xl\:opacity-100 {
    opacity: 1
  }

  .group:hover .xl\:group-hover\:opacity-0 {
    opacity: 0
  }

  .group:hover .xl\:group-hover\:opacity-5 {
    opacity: 0.05
  }

  .group:hover .xl\:group-hover\:opacity-10 {
    opacity: 0.1
  }

  .group:hover .xl\:group-hover\:opacity-20 {
    opacity: 0.2
  }

  .group:hover .xl\:group-hover\:opacity-25 {
    opacity: 0.25
  }

  .group:hover .xl\:group-hover\:opacity-30 {
    opacity: 0.3
  }

  .group:hover .xl\:group-hover\:opacity-40 {
    opacity: 0.4
  }

  .group:hover .xl\:group-hover\:opacity-50 {
    opacity: 0.5
  }

  .group:hover .xl\:group-hover\:opacity-60 {
    opacity: 0.6
  }

  .group:hover .xl\:group-hover\:opacity-70 {
    opacity: 0.7
  }

  .group:hover .xl\:group-hover\:opacity-75 {
    opacity: 0.75
  }

  .group:hover .xl\:group-hover\:opacity-80 {
    opacity: 0.8
  }

  .group:hover .xl\:group-hover\:opacity-90 {
    opacity: 0.9
  }

  .group:hover .xl\:group-hover\:opacity-95 {
    opacity: 0.95
  }

  .group:hover .xl\:group-hover\:opacity-100 {
    opacity: 1
  }

  .xl\:focus-within\:opacity-0:focus-within {
    opacity: 0
  }

  .xl\:focus-within\:opacity-5:focus-within {
    opacity: 0.05
  }

  .xl\:focus-within\:opacity-10:focus-within {
    opacity: 0.1
  }

  .xl\:focus-within\:opacity-20:focus-within {
    opacity: 0.2
  }

  .xl\:focus-within\:opacity-25:focus-within {
    opacity: 0.25
  }

  .xl\:focus-within\:opacity-30:focus-within {
    opacity: 0.3
  }

  .xl\:focus-within\:opacity-40:focus-within {
    opacity: 0.4
  }

  .xl\:focus-within\:opacity-50:focus-within {
    opacity: 0.5
  }

  .xl\:focus-within\:opacity-60:focus-within {
    opacity: 0.6
  }

  .xl\:focus-within\:opacity-70:focus-within {
    opacity: 0.7
  }

  .xl\:focus-within\:opacity-75:focus-within {
    opacity: 0.75
  }

  .xl\:focus-within\:opacity-80:focus-within {
    opacity: 0.8
  }

  .xl\:focus-within\:opacity-90:focus-within {
    opacity: 0.9
  }

  .xl\:focus-within\:opacity-95:focus-within {
    opacity: 0.95
  }

  .xl\:focus-within\:opacity-100:focus-within {
    opacity: 1
  }

  .xl\:hover\:opacity-0:hover {
    opacity: 0
  }

  .xl\:hover\:opacity-5:hover {
    opacity: 0.05
  }

  .xl\:hover\:opacity-10:hover {
    opacity: 0.1
  }

  .xl\:hover\:opacity-20:hover {
    opacity: 0.2
  }

  .xl\:hover\:opacity-25:hover {
    opacity: 0.25
  }

  .xl\:hover\:opacity-30:hover {
    opacity: 0.3
  }

  .xl\:hover\:opacity-40:hover {
    opacity: 0.4
  }

  .xl\:hover\:opacity-50:hover {
    opacity: 0.5
  }

  .xl\:hover\:opacity-60:hover {
    opacity: 0.6
  }

  .xl\:hover\:opacity-70:hover {
    opacity: 0.7
  }

  .xl\:hover\:opacity-75:hover {
    opacity: 0.75
  }

  .xl\:hover\:opacity-80:hover {
    opacity: 0.8
  }

  .xl\:hover\:opacity-90:hover {
    opacity: 0.9
  }

  .xl\:hover\:opacity-95:hover {
    opacity: 0.95
  }

  .xl\:hover\:opacity-100:hover {
    opacity: 1
  }

  .xl\:focus\:opacity-0:focus {
    opacity: 0
  }

  .xl\:focus\:opacity-5:focus {
    opacity: 0.05
  }

  .xl\:focus\:opacity-10:focus {
    opacity: 0.1
  }

  .xl\:focus\:opacity-20:focus {
    opacity: 0.2
  }

  .xl\:focus\:opacity-25:focus {
    opacity: 0.25
  }

  .xl\:focus\:opacity-30:focus {
    opacity: 0.3
  }

  .xl\:focus\:opacity-40:focus {
    opacity: 0.4
  }

  .xl\:focus\:opacity-50:focus {
    opacity: 0.5
  }

  .xl\:focus\:opacity-60:focus {
    opacity: 0.6
  }

  .xl\:focus\:opacity-70:focus {
    opacity: 0.7
  }

  .xl\:focus\:opacity-75:focus {
    opacity: 0.75
  }

  .xl\:focus\:opacity-80:focus {
    opacity: 0.8
  }

  .xl\:focus\:opacity-90:focus {
    opacity: 0.9
  }

  .xl\:focus\:opacity-95:focus {
    opacity: 0.95
  }

  .xl\:focus\:opacity-100:focus {
    opacity: 1
  }

  .xl\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .xl\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .xl\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .xl\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .xl\:focus-within\:outline-white:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .xl\:focus-within\:outline-black:focus-within {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .xl\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .xl\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .xl\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .xl\:overflow-auto {
    overflow: auto
  }

  .xl\:overflow-hidden {
    overflow: hidden
  }

  .xl\:overflow-visible {
    overflow: visible
  }

  .xl\:overflow-scroll {
    overflow: scroll
  }

  .xl\:overflow-x-auto {
    overflow-x: auto
  }

  .xl\:overflow-y-auto {
    overflow-y: auto
  }

  .xl\:overflow-x-hidden {
    overflow-x: hidden
  }

  .xl\:overflow-y-hidden {
    overflow-y: hidden
  }

  .xl\:overflow-x-visible {
    overflow-x: visible
  }

  .xl\:overflow-y-visible {
    overflow-y: visible
  }

  .xl\:overflow-x-scroll {
    overflow-x: scroll
  }

  .xl\:overflow-y-scroll {
    overflow-y: scroll
  }

  .xl\:overscroll-auto {
    overscroll-behavior: auto
  }

  .xl\:overscroll-contain {
    overscroll-behavior: contain
  }

  .xl\:overscroll-none {
    overscroll-behavior: none
  }

  .xl\:overscroll-y-auto {
    overscroll-behavior-y: auto
  }

  .xl\:overscroll-y-contain {
    overscroll-behavior-y: contain
  }

  .xl\:overscroll-y-none {
    overscroll-behavior-y: none
  }

  .xl\:overscroll-x-auto {
    overscroll-behavior-x: auto
  }

  .xl\:overscroll-x-contain {
    overscroll-behavior-x: contain
  }

  .xl\:overscroll-x-none {
    overscroll-behavior-x: none
  }

  .xl\:p-0 {
    padding: 0
  }

  .xl\:p-1 {
    padding: 0.1rem
  }

  .xl\:p-2 {
    padding: 0.2rem
  }

  .xl\:p-3 {
    padding: 0.3rem
  }

  .xl\:p-4 {
    padding: 0.4rem
  }

  .xl\:p-5 {
    padding: 0.5rem
  }

  .xl\:p-6 {
    padding: 0.6rem
  }

  .xl\:p-7 {
    padding: 0.7rem
  }

  .xl\:p-8 {
    padding: 0.8rem
  }

  .xl\:p-9 {
    padding: 0.9rem
  }

  .xl\:p-10 {
    padding: 1.0rem
  }

  .xl\:p-12 {
    padding: 1.2rem
  }

  .xl\:p-14 {
    padding: 1.4rem
  }

  .xl\:p-16 {
    padding: 1.6rem
  }

  .xl\:p-20 {
    padding: 2rem
  }

  .xl\:p-24 {
    padding: 2.4rem
  }

  .xl\:p-28 {
    padding: 2.8rem
  }

  .xl\:p-32 {
    padding: 3.2rem
  }

  .xl\:p-36 {
    padding: 3.6rem
  }

  .xl\:p-40 {
    padding: 4rem
  }

  .xl\:p-44 {
    padding: 4.4rem
  }

  .xl\:p-48 {
    padding: 4.8rem
  }

  .xl\:p-52 {
    padding: 5.2rem
  }

  .xl\:p-56 {
    padding: 5.6rem
  }

  .xl\:p-60 {
    padding: 6rem
  }

  .xl\:p-64 {
    padding: 6.4rem
  }

  .xl\:p-68 {
    padding: 6.8rem
  }

  .xl\:p-72 {
    padding: 7.2rem
  }

  .xl\:p-76 {
    padding: 7.6rem
  }

  .xl\:p-80 {
    padding: 8rem
  }

  .xl\:p-84 {
    padding: 8.4rem
  }

  .xl\:p-88 {
    padding: 8.8rem
  }

  .xl\:p-92 {
    padding: 9.2rem
  }

  .xl\:p-96 {
    padding: 9.6rem
  }

  .xl\:p-128 {
    padding: 12.8rem
  }

  .xl\:p-136 {
    padding: 13.6rem
  }

  .xl\:p-160 {
    padding: 16rem
  }

  .xl\:p-192 {
    padding: 19.2rem
  }

  .xl\:p-200 {
    padding: 20rem
  }

  .xl\:p-208 {
    padding: 20.8rem
  }

  .xl\:p-216 {
    padding: 21.6rem
  }

  .xl\:p-224 {
    padding: 22.4rem
  }

  .xl\:p-256 {
    padding: 25.6rem
  }

  .xl\:p-288 {
    padding: 28.8rem
  }

  .xl\:p-320 {
    padding: 32rem
  }

  .xl\:p-360 {
    padding: 36rem
  }

  .xl\:p-384 {
    padding: 38.4rem
  }

  .xl\:p-400 {
    padding: 40rem
  }

  .xl\:p-512 {
    padding: 51.2rem
  }

  .xl\:p-640 {
    padding: 64rem
  }

  .xl\:p-xs {
    padding: 32rem
  }

  .xl\:p-sm {
    padding: 48rem
  }

  .xl\:p-md {
    padding: 64rem
  }

  .xl\:p-lg {
    padding: 80rem
  }

  .xl\:p-xl {
    padding: 96rem
  }

  .xl\:p-2xl {
    padding: 112rem
  }

  .xl\:p-3xl {
    padding: 128rem
  }

  .xl\:p-4xl {
    padding: 144rem
  }

  .xl\:p-5xl {
    padding: 160rem
  }

  .xl\:p-px {
    padding: 1px
  }

  .xl\:p-0\.5 {
    padding: 0.05rem
  }

  .xl\:p-1\.5 {
    padding: 0.15rem
  }

  .xl\:p-2\.5 {
    padding: 0.25rem
  }

  .xl\:p-3\.5 {
    padding: 0.35rem
  }

  .xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .xl\:py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem
  }

  .xl\:px-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem
  }

  .xl\:py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem
  }

  .xl\:px-2 {
    padding-left: 0.2rem;
    padding-right: 0.2rem
  }

  .xl\:py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem
  }

  .xl\:px-3 {
    padding-left: 0.3rem;
    padding-right: 0.3rem
  }

  .xl\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  .xl\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  .xl\:py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .xl\:px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .xl\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  .xl\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  .xl\:py-7 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem
  }

  .xl\:px-7 {
    padding-left: 0.7rem;
    padding-right: 0.7rem
  }

  .xl\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  .xl\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  .xl\:py-9 {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem
  }

  .xl\:px-9 {
    padding-left: 0.9rem;
    padding-right: 0.9rem
  }

  .xl\:py-10 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
  }

  .xl\:px-10 {
    padding-left: 1.0rem;
    padding-right: 1.0rem
  }

  .xl\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  .xl\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .xl\:py-14 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem
  }

  .xl\:px-14 {
    padding-left: 1.4rem;
    padding-right: 1.4rem
  }

  .xl\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  .xl\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  .xl\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .xl\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .xl\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  .xl\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .xl\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  .xl\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  .xl\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  .xl\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  .xl\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  .xl\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  .xl\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .xl\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .xl\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  .xl\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  .xl\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  .xl\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  .xl\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  .xl\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  .xl\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  .xl\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  .xl\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .xl\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .xl\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  .xl\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  .xl\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  .xl\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  .xl\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  .xl\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  .xl\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  .xl\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  .xl\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .xl\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .xl\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  .xl\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  .xl\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  .xl\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  .xl\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  .xl\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  .xl\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  .xl\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  .xl\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  .xl\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  .xl\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  .xl\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  .xl\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .xl\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .xl\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  .xl\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  .xl\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  .xl\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  .xl\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  .xl\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  .xl\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  .xl\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  .xl\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  .xl\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  .xl\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  .xl\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  .xl\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  .xl\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  .xl\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  .xl\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  .xl\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  .xl\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  .xl\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  .xl\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  .xl\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  .xl\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  .xl\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  .xl\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  .xl\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  .xl\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  .xl\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  .xl\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  .xl\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  .xl\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  .xl\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  .xl\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  .xl\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  .xl\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  .xl\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  .xl\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  .xl\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  .xl\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  .xl\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  .xl\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  .xl\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  .xl\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  .xl\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  .xl\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  .xl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .xl\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .xl\:py-0\.5 {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem
  }

  .xl\:px-0\.5 {
    padding-left: 0.05rem;
    padding-right: 0.05rem
  }

  .xl\:py-1\.5 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem
  }

  .xl\:px-1\.5 {
    padding-left: 0.15rem;
    padding-right: 0.15rem
  }

  .xl\:py-2\.5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .xl\:px-2\.5 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .xl\:py-3\.5 {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem
  }

  .xl\:px-3\.5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem
  }

  .xl\:pt-0 {
    padding-top: 0
  }

  .xl\:pr-0 {
    padding-right: 0
  }

  .xl\:pb-0 {
    padding-bottom: 0
  }

  .xl\:pl-0 {
    padding-left: 0
  }

  .xl\:pt-1 {
    padding-top: 0.1rem
  }

  .xl\:pr-1 {
    padding-right: 0.1rem
  }

  .xl\:pb-1 {
    padding-bottom: 0.1rem
  }

  .xl\:pl-1 {
    padding-left: 0.1rem
  }

  .xl\:pt-2 {
    padding-top: 0.2rem
  }

  .xl\:pr-2 {
    padding-right: 0.2rem
  }

  .xl\:pb-2 {
    padding-bottom: 0.2rem
  }

  .xl\:pl-2 {
    padding-left: 0.2rem
  }

  .xl\:pt-3 {
    padding-top: 0.3rem
  }

  .xl\:pr-3 {
    padding-right: 0.3rem
  }

  .xl\:pb-3 {
    padding-bottom: 0.3rem
  }

  .xl\:pl-3 {
    padding-left: 0.3rem
  }

  .xl\:pt-4 {
    padding-top: 0.4rem
  }

  .xl\:pr-4 {
    padding-right: 0.4rem
  }

  .xl\:pb-4 {
    padding-bottom: 0.4rem
  }

  .xl\:pl-4 {
    padding-left: 0.4rem
  }

  .xl\:pt-5 {
    padding-top: 0.5rem
  }

  .xl\:pr-5 {
    padding-right: 0.5rem
  }

  .xl\:pb-5 {
    padding-bottom: 0.5rem
  }

  .xl\:pl-5 {
    padding-left: 0.5rem
  }

  .xl\:pt-6 {
    padding-top: 0.6rem
  }

  .xl\:pr-6 {
    padding-right: 0.6rem
  }

  .xl\:pb-6 {
    padding-bottom: 0.6rem
  }

  .xl\:pl-6 {
    padding-left: 0.6rem
  }

  .xl\:pt-7 {
    padding-top: 0.7rem
  }

  .xl\:pr-7 {
    padding-right: 0.7rem
  }

  .xl\:pb-7 {
    padding-bottom: 0.7rem
  }

  .xl\:pl-7 {
    padding-left: 0.7rem
  }

  .xl\:pt-8 {
    padding-top: 0.8rem
  }

  .xl\:pr-8 {
    padding-right: 0.8rem
  }

  .xl\:pb-8 {
    padding-bottom: 0.8rem
  }

  .xl\:pl-8 {
    padding-left: 0.8rem
  }

  .xl\:pt-9 {
    padding-top: 0.9rem
  }

  .xl\:pr-9 {
    padding-right: 0.9rem
  }

  .xl\:pb-9 {
    padding-bottom: 0.9rem
  }

  .xl\:pl-9 {
    padding-left: 0.9rem
  }

  .xl\:pt-10 {
    padding-top: 1.0rem
  }

  .xl\:pr-10 {
    padding-right: 1.0rem
  }

  .xl\:pb-10 {
    padding-bottom: 1.0rem
  }

  .xl\:pl-10 {
    padding-left: 1.0rem
  }

  .xl\:pt-12 {
    padding-top: 1.2rem
  }

  .xl\:pr-12 {
    padding-right: 1.2rem
  }

  .xl\:pb-12 {
    padding-bottom: 1.2rem
  }

  .xl\:pl-12 {
    padding-left: 1.2rem
  }

  .xl\:pt-14 {
    padding-top: 1.4rem
  }

  .xl\:pr-14 {
    padding-right: 1.4rem
  }

  .xl\:pb-14 {
    padding-bottom: 1.4rem
  }

  .xl\:pl-14 {
    padding-left: 1.4rem
  }

  .xl\:pt-16 {
    padding-top: 1.6rem
  }

  .xl\:pr-16 {
    padding-right: 1.6rem
  }

  .xl\:pb-16 {
    padding-bottom: 1.6rem
  }

  .xl\:pl-16 {
    padding-left: 1.6rem
  }

  .xl\:pt-20 {
    padding-top: 2rem
  }

  .xl\:pr-20 {
    padding-right: 2rem
  }

  .xl\:pb-20 {
    padding-bottom: 2rem
  }

  .xl\:pl-20 {
    padding-left: 2rem
  }

  .xl\:pt-24 {
    padding-top: 2.4rem
  }

  .xl\:pr-24 {
    padding-right: 2.4rem
  }

  .xl\:pb-24 {
    padding-bottom: 2.4rem
  }

  .xl\:pl-24 {
    padding-left: 2.4rem
  }

  .xl\:pt-28 {
    padding-top: 2.8rem
  }

  .xl\:pr-28 {
    padding-right: 2.8rem
  }

  .xl\:pb-28 {
    padding-bottom: 2.8rem
  }

  .xl\:pl-28 {
    padding-left: 2.8rem
  }

  .xl\:pt-32 {
    padding-top: 3.2rem
  }

  .xl\:pr-32 {
    padding-right: 3.2rem
  }

  .xl\:pb-32 {
    padding-bottom: 3.2rem
  }

  .xl\:pl-32 {
    padding-left: 3.2rem
  }

  .xl\:pt-36 {
    padding-top: 3.6rem
  }

  .xl\:pr-36 {
    padding-right: 3.6rem
  }

  .xl\:pb-36 {
    padding-bottom: 3.6rem
  }

  .xl\:pl-36 {
    padding-left: 3.6rem
  }

  .xl\:pt-40 {
    padding-top: 4rem
  }

  .xl\:pr-40 {
    padding-right: 4rem
  }

  .xl\:pb-40 {
    padding-bottom: 4rem
  }

  .xl\:pl-40 {
    padding-left: 4rem
  }

  .xl\:pt-44 {
    padding-top: 4.4rem
  }

  .xl\:pr-44 {
    padding-right: 4.4rem
  }

  .xl\:pb-44 {
    padding-bottom: 4.4rem
  }

  .xl\:pl-44 {
    padding-left: 4.4rem
  }

  .xl\:pt-48 {
    padding-top: 4.8rem
  }

  .xl\:pr-48 {
    padding-right: 4.8rem
  }

  .xl\:pb-48 {
    padding-bottom: 4.8rem
  }

  .xl\:pl-48 {
    padding-left: 4.8rem
  }

  .xl\:pt-52 {
    padding-top: 5.2rem
  }

  .xl\:pr-52 {
    padding-right: 5.2rem
  }

  .xl\:pb-52 {
    padding-bottom: 5.2rem
  }

  .xl\:pl-52 {
    padding-left: 5.2rem
  }

  .xl\:pt-56 {
    padding-top: 5.6rem
  }

  .xl\:pr-56 {
    padding-right: 5.6rem
  }

  .xl\:pb-56 {
    padding-bottom: 5.6rem
  }

  .xl\:pl-56 {
    padding-left: 5.6rem
  }

  .xl\:pt-60 {
    padding-top: 6rem
  }

  .xl\:pr-60 {
    padding-right: 6rem
  }

  .xl\:pb-60 {
    padding-bottom: 6rem
  }

  .xl\:pl-60 {
    padding-left: 6rem
  }

  .xl\:pt-64 {
    padding-top: 6.4rem
  }

  .xl\:pr-64 {
    padding-right: 6.4rem
  }

  .xl\:pb-64 {
    padding-bottom: 6.4rem
  }

  .xl\:pl-64 {
    padding-left: 6.4rem
  }

  .xl\:pt-68 {
    padding-top: 6.8rem
  }

  .xl\:pr-68 {
    padding-right: 6.8rem
  }

  .xl\:pb-68 {
    padding-bottom: 6.8rem
  }

  .xl\:pl-68 {
    padding-left: 6.8rem
  }

  .xl\:pt-72 {
    padding-top: 7.2rem
  }

  .xl\:pr-72 {
    padding-right: 7.2rem
  }

  .xl\:pb-72 {
    padding-bottom: 7.2rem
  }

  .xl\:pl-72 {
    padding-left: 7.2rem
  }

  .xl\:pt-76 {
    padding-top: 7.6rem
  }

  .xl\:pr-76 {
    padding-right: 7.6rem
  }

  .xl\:pb-76 {
    padding-bottom: 7.6rem
  }

  .xl\:pl-76 {
    padding-left: 7.6rem
  }

  .xl\:pt-80 {
    padding-top: 8rem
  }

  .xl\:pr-80 {
    padding-right: 8rem
  }

  .xl\:pb-80 {
    padding-bottom: 8rem
  }

  .xl\:pl-80 {
    padding-left: 8rem
  }

  .xl\:pt-84 {
    padding-top: 8.4rem
  }

  .xl\:pr-84 {
    padding-right: 8.4rem
  }

  .xl\:pb-84 {
    padding-bottom: 8.4rem
  }

  .xl\:pl-84 {
    padding-left: 8.4rem
  }

  .xl\:pt-88 {
    padding-top: 8.8rem
  }

  .xl\:pr-88 {
    padding-right: 8.8rem
  }

  .xl\:pb-88 {
    padding-bottom: 8.8rem
  }

  .xl\:pl-88 {
    padding-left: 8.8rem
  }

  .xl\:pt-92 {
    padding-top: 9.2rem
  }

  .xl\:pr-92 {
    padding-right: 9.2rem
  }

  .xl\:pb-92 {
    padding-bottom: 9.2rem
  }

  .xl\:pl-92 {
    padding-left: 9.2rem
  }

  .xl\:pt-96 {
    padding-top: 9.6rem
  }

  .xl\:pr-96 {
    padding-right: 9.6rem
  }

  .xl\:pb-96 {
    padding-bottom: 9.6rem
  }

  .xl\:pl-96 {
    padding-left: 9.6rem
  }

  .xl\:pt-128 {
    padding-top: 12.8rem
  }

  .xl\:pr-128 {
    padding-right: 12.8rem
  }

  .xl\:pb-128 {
    padding-bottom: 12.8rem
  }

  .xl\:pl-128 {
    padding-left: 12.8rem
  }

  .xl\:pt-136 {
    padding-top: 13.6rem
  }

  .xl\:pr-136 {
    padding-right: 13.6rem
  }

  .xl\:pb-136 {
    padding-bottom: 13.6rem
  }

  .xl\:pl-136 {
    padding-left: 13.6rem
  }

  .xl\:pt-160 {
    padding-top: 16rem
  }

  .xl\:pr-160 {
    padding-right: 16rem
  }

  .xl\:pb-160 {
    padding-bottom: 16rem
  }

  .xl\:pl-160 {
    padding-left: 16rem
  }

  .xl\:pt-192 {
    padding-top: 19.2rem
  }

  .xl\:pr-192 {
    padding-right: 19.2rem
  }

  .xl\:pb-192 {
    padding-bottom: 19.2rem
  }

  .xl\:pl-192 {
    padding-left: 19.2rem
  }

  .xl\:pt-200 {
    padding-top: 20rem
  }

  .xl\:pr-200 {
    padding-right: 20rem
  }

  .xl\:pb-200 {
    padding-bottom: 20rem
  }

  .xl\:pl-200 {
    padding-left: 20rem
  }

  .xl\:pt-208 {
    padding-top: 20.8rem
  }

  .xl\:pr-208 {
    padding-right: 20.8rem
  }

  .xl\:pb-208 {
    padding-bottom: 20.8rem
  }

  .xl\:pl-208 {
    padding-left: 20.8rem
  }

  .xl\:pt-216 {
    padding-top: 21.6rem
  }

  .xl\:pr-216 {
    padding-right: 21.6rem
  }

  .xl\:pb-216 {
    padding-bottom: 21.6rem
  }

  .xl\:pl-216 {
    padding-left: 21.6rem
  }

  .xl\:pt-224 {
    padding-top: 22.4rem
  }

  .xl\:pr-224 {
    padding-right: 22.4rem
  }

  .xl\:pb-224 {
    padding-bottom: 22.4rem
  }

  .xl\:pl-224 {
    padding-left: 22.4rem
  }

  .xl\:pt-256 {
    padding-top: 25.6rem
  }

  .xl\:pr-256 {
    padding-right: 25.6rem
  }

  .xl\:pb-256 {
    padding-bottom: 25.6rem
  }

  .xl\:pl-256 {
    padding-left: 25.6rem
  }

  .xl\:pt-288 {
    padding-top: 28.8rem
  }

  .xl\:pr-288 {
    padding-right: 28.8rem
  }

  .xl\:pb-288 {
    padding-bottom: 28.8rem
  }

  .xl\:pl-288 {
    padding-left: 28.8rem
  }

  .xl\:pt-320 {
    padding-top: 32rem
  }

  .xl\:pr-320 {
    padding-right: 32rem
  }

  .xl\:pb-320 {
    padding-bottom: 32rem
  }

  .xl\:pl-320 {
    padding-left: 32rem
  }

  .xl\:pt-360 {
    padding-top: 36rem
  }

  .xl\:pr-360 {
    padding-right: 36rem
  }

  .xl\:pb-360 {
    padding-bottom: 36rem
  }

  .xl\:pl-360 {
    padding-left: 36rem
  }

  .xl\:pt-384 {
    padding-top: 38.4rem
  }

  .xl\:pr-384 {
    padding-right: 38.4rem
  }

  .xl\:pb-384 {
    padding-bottom: 38.4rem
  }

  .xl\:pl-384 {
    padding-left: 38.4rem
  }

  .xl\:pt-400 {
    padding-top: 40rem
  }

  .xl\:pr-400 {
    padding-right: 40rem
  }

  .xl\:pb-400 {
    padding-bottom: 40rem
  }

  .xl\:pl-400 {
    padding-left: 40rem
  }

  .xl\:pt-512 {
    padding-top: 51.2rem
  }

  .xl\:pr-512 {
    padding-right: 51.2rem
  }

  .xl\:pb-512 {
    padding-bottom: 51.2rem
  }

  .xl\:pl-512 {
    padding-left: 51.2rem
  }

  .xl\:pt-640 {
    padding-top: 64rem
  }

  .xl\:pr-640 {
    padding-right: 64rem
  }

  .xl\:pb-640 {
    padding-bottom: 64rem
  }

  .xl\:pl-640 {
    padding-left: 64rem
  }

  .xl\:pt-xs {
    padding-top: 32rem
  }

  .xl\:pr-xs {
    padding-right: 32rem
  }

  .xl\:pb-xs {
    padding-bottom: 32rem
  }

  .xl\:pl-xs {
    padding-left: 32rem
  }

  .xl\:pt-sm {
    padding-top: 48rem
  }

  .xl\:pr-sm {
    padding-right: 48rem
  }

  .xl\:pb-sm {
    padding-bottom: 48rem
  }

  .xl\:pl-sm {
    padding-left: 48rem
  }

  .xl\:pt-md {
    padding-top: 64rem
  }

  .xl\:pr-md {
    padding-right: 64rem
  }

  .xl\:pb-md {
    padding-bottom: 64rem
  }

  .xl\:pl-md {
    padding-left: 64rem
  }

  .xl\:pt-lg {
    padding-top: 80rem
  }

  .xl\:pr-lg {
    padding-right: 80rem
  }

  .xl\:pb-lg {
    padding-bottom: 80rem
  }

  .xl\:pl-lg {
    padding-left: 80rem
  }

  .xl\:pt-xl {
    padding-top: 96rem
  }

  .xl\:pr-xl {
    padding-right: 96rem
  }

  .xl\:pb-xl {
    padding-bottom: 96rem
  }

  .xl\:pl-xl {
    padding-left: 96rem
  }

  .xl\:pt-2xl {
    padding-top: 112rem
  }

  .xl\:pr-2xl {
    padding-right: 112rem
  }

  .xl\:pb-2xl {
    padding-bottom: 112rem
  }

  .xl\:pl-2xl {
    padding-left: 112rem
  }

  .xl\:pt-3xl {
    padding-top: 128rem
  }

  .xl\:pr-3xl {
    padding-right: 128rem
  }

  .xl\:pb-3xl {
    padding-bottom: 128rem
  }

  .xl\:pl-3xl {
    padding-left: 128rem
  }

  .xl\:pt-4xl {
    padding-top: 144rem
  }

  .xl\:pr-4xl {
    padding-right: 144rem
  }

  .xl\:pb-4xl {
    padding-bottom: 144rem
  }

  .xl\:pl-4xl {
    padding-left: 144rem
  }

  .xl\:pt-5xl {
    padding-top: 160rem
  }

  .xl\:pr-5xl {
    padding-right: 160rem
  }

  .xl\:pb-5xl {
    padding-bottom: 160rem
  }

  .xl\:pl-5xl {
    padding-left: 160rem
  }

  .xl\:pt-px {
    padding-top: 1px
  }

  .xl\:pr-px {
    padding-right: 1px
  }

  .xl\:pb-px {
    padding-bottom: 1px
  }

  .xl\:pl-px {
    padding-left: 1px
  }

  .xl\:pt-0\.5 {
    padding-top: 0.05rem
  }

  .xl\:pr-0\.5 {
    padding-right: 0.05rem
  }

  .xl\:pb-0\.5 {
    padding-bottom: 0.05rem
  }

  .xl\:pl-0\.5 {
    padding-left: 0.05rem
  }

  .xl\:pt-1\.5 {
    padding-top: 0.15rem
  }

  .xl\:pr-1\.5 {
    padding-right: 0.15rem
  }

  .xl\:pb-1\.5 {
    padding-bottom: 0.15rem
  }

  .xl\:pl-1\.5 {
    padding-left: 0.15rem
  }

  .xl\:pt-2\.5 {
    padding-top: 0.25rem
  }

  .xl\:pr-2\.5 {
    padding-right: 0.25rem
  }

  .xl\:pb-2\.5 {
    padding-bottom: 0.25rem
  }

  .xl\:pl-2\.5 {
    padding-left: 0.25rem
  }

  .xl\:pt-3\.5 {
    padding-top: 0.35rem
  }

  .xl\:pr-3\.5 {
    padding-right: 0.35rem
  }

  .xl\:pb-3\.5 {
    padding-bottom: 0.35rem
  }

  .xl\:pl-3\.5 {
    padding-left: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:p-0,[dir='ltr'].xl\:ltr\:p-0 {
    padding: 0
  }

  [dir='ltr'] .xl\:ltr\:p-1,[dir='ltr'].xl\:ltr\:p-1 {
    padding: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:p-2,[dir='ltr'].xl\:ltr\:p-2 {
    padding: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:p-3,[dir='ltr'].xl\:ltr\:p-3 {
    padding: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:p-4,[dir='ltr'].xl\:ltr\:p-4 {
    padding: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:p-5,[dir='ltr'].xl\:ltr\:p-5 {
    padding: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:p-6,[dir='ltr'].xl\:ltr\:p-6 {
    padding: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:p-7,[dir='ltr'].xl\:ltr\:p-7 {
    padding: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:p-8,[dir='ltr'].xl\:ltr\:p-8 {
    padding: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:p-9,[dir='ltr'].xl\:ltr\:p-9 {
    padding: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:p-10,[dir='ltr'].xl\:ltr\:p-10 {
    padding: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:p-12,[dir='ltr'].xl\:ltr\:p-12 {
    padding: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:p-14,[dir='ltr'].xl\:ltr\:p-14 {
    padding: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:p-16,[dir='ltr'].xl\:ltr\:p-16 {
    padding: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:p-20,[dir='ltr'].xl\:ltr\:p-20 {
    padding: 2rem
  }

  [dir='ltr'] .xl\:ltr\:p-24,[dir='ltr'].xl\:ltr\:p-24 {
    padding: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:p-28,[dir='ltr'].xl\:ltr\:p-28 {
    padding: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:p-32,[dir='ltr'].xl\:ltr\:p-32 {
    padding: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:p-36,[dir='ltr'].xl\:ltr\:p-36 {
    padding: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:p-40,[dir='ltr'].xl\:ltr\:p-40 {
    padding: 4rem
  }

  [dir='ltr'] .xl\:ltr\:p-44,[dir='ltr'].xl\:ltr\:p-44 {
    padding: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:p-48,[dir='ltr'].xl\:ltr\:p-48 {
    padding: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:p-52,[dir='ltr'].xl\:ltr\:p-52 {
    padding: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:p-56,[dir='ltr'].xl\:ltr\:p-56 {
    padding: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:p-60,[dir='ltr'].xl\:ltr\:p-60 {
    padding: 6rem
  }

  [dir='ltr'] .xl\:ltr\:p-64,[dir='ltr'].xl\:ltr\:p-64 {
    padding: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:p-68,[dir='ltr'].xl\:ltr\:p-68 {
    padding: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:p-72,[dir='ltr'].xl\:ltr\:p-72 {
    padding: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:p-76,[dir='ltr'].xl\:ltr\:p-76 {
    padding: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:p-80,[dir='ltr'].xl\:ltr\:p-80 {
    padding: 8rem
  }

  [dir='ltr'] .xl\:ltr\:p-84,[dir='ltr'].xl\:ltr\:p-84 {
    padding: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:p-88,[dir='ltr'].xl\:ltr\:p-88 {
    padding: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:p-92,[dir='ltr'].xl\:ltr\:p-92 {
    padding: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:p-96,[dir='ltr'].xl\:ltr\:p-96 {
    padding: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:p-128,[dir='ltr'].xl\:ltr\:p-128 {
    padding: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:p-136,[dir='ltr'].xl\:ltr\:p-136 {
    padding: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:p-160,[dir='ltr'].xl\:ltr\:p-160 {
    padding: 16rem
  }

  [dir='ltr'] .xl\:ltr\:p-192,[dir='ltr'].xl\:ltr\:p-192 {
    padding: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:p-200,[dir='ltr'].xl\:ltr\:p-200 {
    padding: 20rem
  }

  [dir='ltr'] .xl\:ltr\:p-208,[dir='ltr'].xl\:ltr\:p-208 {
    padding: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:p-216,[dir='ltr'].xl\:ltr\:p-216 {
    padding: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:p-224,[dir='ltr'].xl\:ltr\:p-224 {
    padding: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:p-256,[dir='ltr'].xl\:ltr\:p-256 {
    padding: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:p-288,[dir='ltr'].xl\:ltr\:p-288 {
    padding: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:p-320,[dir='ltr'].xl\:ltr\:p-320 {
    padding: 32rem
  }

  [dir='ltr'] .xl\:ltr\:p-360,[dir='ltr'].xl\:ltr\:p-360 {
    padding: 36rem
  }

  [dir='ltr'] .xl\:ltr\:p-384,[dir='ltr'].xl\:ltr\:p-384 {
    padding: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:p-400,[dir='ltr'].xl\:ltr\:p-400 {
    padding: 40rem
  }

  [dir='ltr'] .xl\:ltr\:p-512,[dir='ltr'].xl\:ltr\:p-512 {
    padding: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:p-640,[dir='ltr'].xl\:ltr\:p-640 {
    padding: 64rem
  }

  [dir='ltr'] .xl\:ltr\:p-xs,[dir='ltr'].xl\:ltr\:p-xs {
    padding: 32rem
  }

  [dir='ltr'] .xl\:ltr\:p-sm,[dir='ltr'].xl\:ltr\:p-sm {
    padding: 48rem
  }

  [dir='ltr'] .xl\:ltr\:p-md,[dir='ltr'].xl\:ltr\:p-md {
    padding: 64rem
  }

  [dir='ltr'] .xl\:ltr\:p-lg,[dir='ltr'].xl\:ltr\:p-lg {
    padding: 80rem
  }

  [dir='ltr'] .xl\:ltr\:p-xl,[dir='ltr'].xl\:ltr\:p-xl {
    padding: 96rem
  }

  [dir='ltr'] .xl\:ltr\:p-2xl,[dir='ltr'].xl\:ltr\:p-2xl {
    padding: 112rem
  }

  [dir='ltr'] .xl\:ltr\:p-3xl,[dir='ltr'].xl\:ltr\:p-3xl {
    padding: 128rem
  }

  [dir='ltr'] .xl\:ltr\:p-4xl,[dir='ltr'].xl\:ltr\:p-4xl {
    padding: 144rem
  }

  [dir='ltr'] .xl\:ltr\:p-5xl,[dir='ltr'].xl\:ltr\:p-5xl {
    padding: 160rem
  }

  [dir='ltr'] .xl\:ltr\:p-px,[dir='ltr'].xl\:ltr\:p-px {
    padding: 1px
  }

  [dir='ltr'] .xl\:ltr\:p-0\.5,[dir='ltr'].xl\:ltr\:p-0\.5 {
    padding: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:p-1\.5,[dir='ltr'].xl\:ltr\:p-1\.5 {
    padding: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:p-2\.5,[dir='ltr'].xl\:ltr\:p-2\.5 {
    padding: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:p-3\.5,[dir='ltr'].xl\:ltr\:p-3\.5 {
    padding: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:py-0,[dir='ltr'].xl\:ltr\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  [dir='ltr'] .xl\:ltr\:px-0,[dir='ltr'].xl\:ltr\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  [dir='ltr'] .xl\:ltr\:py-1,[dir='ltr'].xl\:ltr\:py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:px-1,[dir='ltr'].xl\:ltr\:px-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:py-2,[dir='ltr'].xl\:ltr\:py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:px-2,[dir='ltr'].xl\:ltr\:px-2 {
    padding-left: 0.2rem;
    padding-right: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:py-3,[dir='ltr'].xl\:ltr\:py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:px-3,[dir='ltr'].xl\:ltr\:px-3 {
    padding-left: 0.3rem;
    padding-right: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:py-4,[dir='ltr'].xl\:ltr\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:px-4,[dir='ltr'].xl\:ltr\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:py-5,[dir='ltr'].xl\:ltr\:py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:px-5,[dir='ltr'].xl\:ltr\:px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:py-6,[dir='ltr'].xl\:ltr\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:px-6,[dir='ltr'].xl\:ltr\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:py-7,[dir='ltr'].xl\:ltr\:py-7 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:px-7,[dir='ltr'].xl\:ltr\:px-7 {
    padding-left: 0.7rem;
    padding-right: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:py-8,[dir='ltr'].xl\:ltr\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:px-8,[dir='ltr'].xl\:ltr\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:py-9,[dir='ltr'].xl\:ltr\:py-9 {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:px-9,[dir='ltr'].xl\:ltr\:px-9 {
    padding-left: 0.9rem;
    padding-right: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:py-10,[dir='ltr'].xl\:ltr\:py-10 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:px-10,[dir='ltr'].xl\:ltr\:px-10 {
    padding-left: 1.0rem;
    padding-right: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:py-12,[dir='ltr'].xl\:ltr\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:px-12,[dir='ltr'].xl\:ltr\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:py-14,[dir='ltr'].xl\:ltr\:py-14 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:px-14,[dir='ltr'].xl\:ltr\:px-14 {
    padding-left: 1.4rem;
    padding-right: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:py-16,[dir='ltr'].xl\:ltr\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:px-16,[dir='ltr'].xl\:ltr\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:py-20,[dir='ltr'].xl\:ltr\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  [dir='ltr'] .xl\:ltr\:px-20,[dir='ltr'].xl\:ltr\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  [dir='ltr'] .xl\:ltr\:py-24,[dir='ltr'].xl\:ltr\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:px-24,[dir='ltr'].xl\:ltr\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:py-28,[dir='ltr'].xl\:ltr\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:px-28,[dir='ltr'].xl\:ltr\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:py-32,[dir='ltr'].xl\:ltr\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:px-32,[dir='ltr'].xl\:ltr\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:py-36,[dir='ltr'].xl\:ltr\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:px-36,[dir='ltr'].xl\:ltr\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:py-40,[dir='ltr'].xl\:ltr\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  [dir='ltr'] .xl\:ltr\:px-40,[dir='ltr'].xl\:ltr\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  [dir='ltr'] .xl\:ltr\:py-44,[dir='ltr'].xl\:ltr\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:px-44,[dir='ltr'].xl\:ltr\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:py-48,[dir='ltr'].xl\:ltr\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:px-48,[dir='ltr'].xl\:ltr\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:py-52,[dir='ltr'].xl\:ltr\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:px-52,[dir='ltr'].xl\:ltr\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:py-56,[dir='ltr'].xl\:ltr\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:px-56,[dir='ltr'].xl\:ltr\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:py-60,[dir='ltr'].xl\:ltr\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  [dir='ltr'] .xl\:ltr\:px-60,[dir='ltr'].xl\:ltr\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  [dir='ltr'] .xl\:ltr\:py-64,[dir='ltr'].xl\:ltr\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:px-64,[dir='ltr'].xl\:ltr\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:py-68,[dir='ltr'].xl\:ltr\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:px-68,[dir='ltr'].xl\:ltr\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:py-72,[dir='ltr'].xl\:ltr\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:px-72,[dir='ltr'].xl\:ltr\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:py-76,[dir='ltr'].xl\:ltr\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:px-76,[dir='ltr'].xl\:ltr\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:py-80,[dir='ltr'].xl\:ltr\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  [dir='ltr'] .xl\:ltr\:px-80,[dir='ltr'].xl\:ltr\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  [dir='ltr'] .xl\:ltr\:py-84,[dir='ltr'].xl\:ltr\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:px-84,[dir='ltr'].xl\:ltr\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:py-88,[dir='ltr'].xl\:ltr\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:px-88,[dir='ltr'].xl\:ltr\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:py-92,[dir='ltr'].xl\:ltr\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:px-92,[dir='ltr'].xl\:ltr\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:py-96,[dir='ltr'].xl\:ltr\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:px-96,[dir='ltr'].xl\:ltr\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:py-128,[dir='ltr'].xl\:ltr\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:px-128,[dir='ltr'].xl\:ltr\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:py-136,[dir='ltr'].xl\:ltr\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:px-136,[dir='ltr'].xl\:ltr\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:py-160,[dir='ltr'].xl\:ltr\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  [dir='ltr'] .xl\:ltr\:px-160,[dir='ltr'].xl\:ltr\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  [dir='ltr'] .xl\:ltr\:py-192,[dir='ltr'].xl\:ltr\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:px-192,[dir='ltr'].xl\:ltr\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:py-200,[dir='ltr'].xl\:ltr\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  [dir='ltr'] .xl\:ltr\:px-200,[dir='ltr'].xl\:ltr\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  [dir='ltr'] .xl\:ltr\:py-208,[dir='ltr'].xl\:ltr\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:px-208,[dir='ltr'].xl\:ltr\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:py-216,[dir='ltr'].xl\:ltr\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:px-216,[dir='ltr'].xl\:ltr\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:py-224,[dir='ltr'].xl\:ltr\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:px-224,[dir='ltr'].xl\:ltr\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:py-256,[dir='ltr'].xl\:ltr\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:px-256,[dir='ltr'].xl\:ltr\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:py-288,[dir='ltr'].xl\:ltr\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:px-288,[dir='ltr'].xl\:ltr\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:py-320,[dir='ltr'].xl\:ltr\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='ltr'] .xl\:ltr\:px-320,[dir='ltr'].xl\:ltr\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='ltr'] .xl\:ltr\:py-360,[dir='ltr'].xl\:ltr\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  [dir='ltr'] .xl\:ltr\:px-360,[dir='ltr'].xl\:ltr\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  [dir='ltr'] .xl\:ltr\:py-384,[dir='ltr'].xl\:ltr\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:px-384,[dir='ltr'].xl\:ltr\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:py-400,[dir='ltr'].xl\:ltr\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  [dir='ltr'] .xl\:ltr\:px-400,[dir='ltr'].xl\:ltr\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  [dir='ltr'] .xl\:ltr\:py-512,[dir='ltr'].xl\:ltr\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:px-512,[dir='ltr'].xl\:ltr\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:py-640,[dir='ltr'].xl\:ltr\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='ltr'] .xl\:ltr\:px-640,[dir='ltr'].xl\:ltr\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='ltr'] .xl\:ltr\:py-xs,[dir='ltr'].xl\:ltr\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='ltr'] .xl\:ltr\:px-xs,[dir='ltr'].xl\:ltr\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='ltr'] .xl\:ltr\:py-sm,[dir='ltr'].xl\:ltr\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  [dir='ltr'] .xl\:ltr\:px-sm,[dir='ltr'].xl\:ltr\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  [dir='ltr'] .xl\:ltr\:py-md,[dir='ltr'].xl\:ltr\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='ltr'] .xl\:ltr\:px-md,[dir='ltr'].xl\:ltr\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='ltr'] .xl\:ltr\:py-lg,[dir='ltr'].xl\:ltr\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  [dir='ltr'] .xl\:ltr\:px-lg,[dir='ltr'].xl\:ltr\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  [dir='ltr'] .xl\:ltr\:py-xl,[dir='ltr'].xl\:ltr\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  [dir='ltr'] .xl\:ltr\:px-xl,[dir='ltr'].xl\:ltr\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  [dir='ltr'] .xl\:ltr\:py-2xl,[dir='ltr'].xl\:ltr\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  [dir='ltr'] .xl\:ltr\:px-2xl,[dir='ltr'].xl\:ltr\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  [dir='ltr'] .xl\:ltr\:py-3xl,[dir='ltr'].xl\:ltr\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  [dir='ltr'] .xl\:ltr\:px-3xl,[dir='ltr'].xl\:ltr\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  [dir='ltr'] .xl\:ltr\:py-4xl,[dir='ltr'].xl\:ltr\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  [dir='ltr'] .xl\:ltr\:px-4xl,[dir='ltr'].xl\:ltr\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  [dir='ltr'] .xl\:ltr\:py-5xl,[dir='ltr'].xl\:ltr\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  [dir='ltr'] .xl\:ltr\:px-5xl,[dir='ltr'].xl\:ltr\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  [dir='ltr'] .xl\:ltr\:py-px,[dir='ltr'].xl\:ltr\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  [dir='ltr'] .xl\:ltr\:px-px,[dir='ltr'].xl\:ltr\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  [dir='ltr'] .xl\:ltr\:py-0\.5,[dir='ltr'].xl\:ltr\:py-0\.5 {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:px-0\.5,[dir='ltr'].xl\:ltr\:px-0\.5 {
    padding-left: 0.05rem;
    padding-right: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:py-1\.5,[dir='ltr'].xl\:ltr\:py-1\.5 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:px-1\.5,[dir='ltr'].xl\:ltr\:px-1\.5 {
    padding-left: 0.15rem;
    padding-right: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:py-2\.5,[dir='ltr'].xl\:ltr\:py-2\.5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:px-2\.5,[dir='ltr'].xl\:ltr\:px-2\.5 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:py-3\.5,[dir='ltr'].xl\:ltr\:py-3\.5 {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:px-3\.5,[dir='ltr'].xl\:ltr\:px-3\.5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:pt-0,[dir='ltr'].xl\:ltr\:pt-0 {
    padding-top: 0
  }

  [dir='ltr'] .xl\:ltr\:pr-0,[dir='ltr'].xl\:ltr\:pr-0 {
    padding-right: 0
  }

  [dir='ltr'] .xl\:ltr\:pb-0,[dir='ltr'].xl\:ltr\:pb-0 {
    padding-bottom: 0
  }

  [dir='ltr'] .xl\:ltr\:pl-0,[dir='ltr'].xl\:ltr\:pl-0 {
    padding-left: 0
  }

  [dir='ltr'] .xl\:ltr\:pt-1,[dir='ltr'].xl\:ltr\:pt-1 {
    padding-top: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:pr-1,[dir='ltr'].xl\:ltr\:pr-1 {
    padding-right: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:pb-1,[dir='ltr'].xl\:ltr\:pb-1 {
    padding-bottom: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:pl-1,[dir='ltr'].xl\:ltr\:pl-1 {
    padding-left: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:pt-2,[dir='ltr'].xl\:ltr\:pt-2 {
    padding-top: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:pr-2,[dir='ltr'].xl\:ltr\:pr-2 {
    padding-right: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:pb-2,[dir='ltr'].xl\:ltr\:pb-2 {
    padding-bottom: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:pl-2,[dir='ltr'].xl\:ltr\:pl-2 {
    padding-left: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:pt-3,[dir='ltr'].xl\:ltr\:pt-3 {
    padding-top: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:pr-3,[dir='ltr'].xl\:ltr\:pr-3 {
    padding-right: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:pb-3,[dir='ltr'].xl\:ltr\:pb-3 {
    padding-bottom: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:pl-3,[dir='ltr'].xl\:ltr\:pl-3 {
    padding-left: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:pt-4,[dir='ltr'].xl\:ltr\:pt-4 {
    padding-top: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:pr-4,[dir='ltr'].xl\:ltr\:pr-4 {
    padding-right: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:pb-4,[dir='ltr'].xl\:ltr\:pb-4 {
    padding-bottom: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:pl-4,[dir='ltr'].xl\:ltr\:pl-4 {
    padding-left: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:pt-5,[dir='ltr'].xl\:ltr\:pt-5 {
    padding-top: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:pr-5,[dir='ltr'].xl\:ltr\:pr-5 {
    padding-right: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:pb-5,[dir='ltr'].xl\:ltr\:pb-5 {
    padding-bottom: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:pl-5,[dir='ltr'].xl\:ltr\:pl-5 {
    padding-left: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:pt-6,[dir='ltr'].xl\:ltr\:pt-6 {
    padding-top: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:pr-6,[dir='ltr'].xl\:ltr\:pr-6 {
    padding-right: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:pb-6,[dir='ltr'].xl\:ltr\:pb-6 {
    padding-bottom: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:pl-6,[dir='ltr'].xl\:ltr\:pl-6 {
    padding-left: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:pt-7,[dir='ltr'].xl\:ltr\:pt-7 {
    padding-top: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:pr-7,[dir='ltr'].xl\:ltr\:pr-7 {
    padding-right: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:pb-7,[dir='ltr'].xl\:ltr\:pb-7 {
    padding-bottom: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:pl-7,[dir='ltr'].xl\:ltr\:pl-7 {
    padding-left: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:pt-8,[dir='ltr'].xl\:ltr\:pt-8 {
    padding-top: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:pr-8,[dir='ltr'].xl\:ltr\:pr-8 {
    padding-right: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:pb-8,[dir='ltr'].xl\:ltr\:pb-8 {
    padding-bottom: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:pl-8,[dir='ltr'].xl\:ltr\:pl-8 {
    padding-left: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:pt-9,[dir='ltr'].xl\:ltr\:pt-9 {
    padding-top: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:pr-9,[dir='ltr'].xl\:ltr\:pr-9 {
    padding-right: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:pb-9,[dir='ltr'].xl\:ltr\:pb-9 {
    padding-bottom: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:pl-9,[dir='ltr'].xl\:ltr\:pl-9 {
    padding-left: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:pt-10,[dir='ltr'].xl\:ltr\:pt-10 {
    padding-top: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:pr-10,[dir='ltr'].xl\:ltr\:pr-10 {
    padding-right: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:pb-10,[dir='ltr'].xl\:ltr\:pb-10 {
    padding-bottom: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:pl-10,[dir='ltr'].xl\:ltr\:pl-10 {
    padding-left: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:pt-12,[dir='ltr'].xl\:ltr\:pt-12 {
    padding-top: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:pr-12,[dir='ltr'].xl\:ltr\:pr-12 {
    padding-right: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:pb-12,[dir='ltr'].xl\:ltr\:pb-12 {
    padding-bottom: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:pl-12,[dir='ltr'].xl\:ltr\:pl-12 {
    padding-left: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:pt-14,[dir='ltr'].xl\:ltr\:pt-14 {
    padding-top: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:pr-14,[dir='ltr'].xl\:ltr\:pr-14 {
    padding-right: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:pb-14,[dir='ltr'].xl\:ltr\:pb-14 {
    padding-bottom: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:pl-14,[dir='ltr'].xl\:ltr\:pl-14 {
    padding-left: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:pt-16,[dir='ltr'].xl\:ltr\:pt-16 {
    padding-top: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:pr-16,[dir='ltr'].xl\:ltr\:pr-16 {
    padding-right: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:pb-16,[dir='ltr'].xl\:ltr\:pb-16 {
    padding-bottom: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:pl-16,[dir='ltr'].xl\:ltr\:pl-16 {
    padding-left: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:pt-20,[dir='ltr'].xl\:ltr\:pt-20 {
    padding-top: 2rem
  }

  [dir='ltr'] .xl\:ltr\:pr-20,[dir='ltr'].xl\:ltr\:pr-20 {
    padding-right: 2rem
  }

  [dir='ltr'] .xl\:ltr\:pb-20,[dir='ltr'].xl\:ltr\:pb-20 {
    padding-bottom: 2rem
  }

  [dir='ltr'] .xl\:ltr\:pl-20,[dir='ltr'].xl\:ltr\:pl-20 {
    padding-left: 2rem
  }

  [dir='ltr'] .xl\:ltr\:pt-24,[dir='ltr'].xl\:ltr\:pt-24 {
    padding-top: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:pr-24,[dir='ltr'].xl\:ltr\:pr-24 {
    padding-right: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:pb-24,[dir='ltr'].xl\:ltr\:pb-24 {
    padding-bottom: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:pl-24,[dir='ltr'].xl\:ltr\:pl-24 {
    padding-left: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:pt-28,[dir='ltr'].xl\:ltr\:pt-28 {
    padding-top: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:pr-28,[dir='ltr'].xl\:ltr\:pr-28 {
    padding-right: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:pb-28,[dir='ltr'].xl\:ltr\:pb-28 {
    padding-bottom: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:pl-28,[dir='ltr'].xl\:ltr\:pl-28 {
    padding-left: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:pt-32,[dir='ltr'].xl\:ltr\:pt-32 {
    padding-top: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:pr-32,[dir='ltr'].xl\:ltr\:pr-32 {
    padding-right: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:pb-32,[dir='ltr'].xl\:ltr\:pb-32 {
    padding-bottom: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:pl-32,[dir='ltr'].xl\:ltr\:pl-32 {
    padding-left: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:pt-36,[dir='ltr'].xl\:ltr\:pt-36 {
    padding-top: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:pr-36,[dir='ltr'].xl\:ltr\:pr-36 {
    padding-right: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:pb-36,[dir='ltr'].xl\:ltr\:pb-36 {
    padding-bottom: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:pl-36,[dir='ltr'].xl\:ltr\:pl-36 {
    padding-left: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:pt-40,[dir='ltr'].xl\:ltr\:pt-40 {
    padding-top: 4rem
  }

  [dir='ltr'] .xl\:ltr\:pr-40,[dir='ltr'].xl\:ltr\:pr-40 {
    padding-right: 4rem
  }

  [dir='ltr'] .xl\:ltr\:pb-40,[dir='ltr'].xl\:ltr\:pb-40 {
    padding-bottom: 4rem
  }

  [dir='ltr'] .xl\:ltr\:pl-40,[dir='ltr'].xl\:ltr\:pl-40 {
    padding-left: 4rem
  }

  [dir='ltr'] .xl\:ltr\:pt-44,[dir='ltr'].xl\:ltr\:pt-44 {
    padding-top: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:pr-44,[dir='ltr'].xl\:ltr\:pr-44 {
    padding-right: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:pb-44,[dir='ltr'].xl\:ltr\:pb-44 {
    padding-bottom: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:pl-44,[dir='ltr'].xl\:ltr\:pl-44 {
    padding-left: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:pt-48,[dir='ltr'].xl\:ltr\:pt-48 {
    padding-top: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:pr-48,[dir='ltr'].xl\:ltr\:pr-48 {
    padding-right: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:pb-48,[dir='ltr'].xl\:ltr\:pb-48 {
    padding-bottom: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:pl-48,[dir='ltr'].xl\:ltr\:pl-48 {
    padding-left: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:pt-52,[dir='ltr'].xl\:ltr\:pt-52 {
    padding-top: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:pr-52,[dir='ltr'].xl\:ltr\:pr-52 {
    padding-right: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:pb-52,[dir='ltr'].xl\:ltr\:pb-52 {
    padding-bottom: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:pl-52,[dir='ltr'].xl\:ltr\:pl-52 {
    padding-left: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:pt-56,[dir='ltr'].xl\:ltr\:pt-56 {
    padding-top: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:pr-56,[dir='ltr'].xl\:ltr\:pr-56 {
    padding-right: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:pb-56,[dir='ltr'].xl\:ltr\:pb-56 {
    padding-bottom: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:pl-56,[dir='ltr'].xl\:ltr\:pl-56 {
    padding-left: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:pt-60,[dir='ltr'].xl\:ltr\:pt-60 {
    padding-top: 6rem
  }

  [dir='ltr'] .xl\:ltr\:pr-60,[dir='ltr'].xl\:ltr\:pr-60 {
    padding-right: 6rem
  }

  [dir='ltr'] .xl\:ltr\:pb-60,[dir='ltr'].xl\:ltr\:pb-60 {
    padding-bottom: 6rem
  }

  [dir='ltr'] .xl\:ltr\:pl-60,[dir='ltr'].xl\:ltr\:pl-60 {
    padding-left: 6rem
  }

  [dir='ltr'] .xl\:ltr\:pt-64,[dir='ltr'].xl\:ltr\:pt-64 {
    padding-top: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:pr-64,[dir='ltr'].xl\:ltr\:pr-64 {
    padding-right: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:pb-64,[dir='ltr'].xl\:ltr\:pb-64 {
    padding-bottom: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:pl-64,[dir='ltr'].xl\:ltr\:pl-64 {
    padding-left: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:pt-68,[dir='ltr'].xl\:ltr\:pt-68 {
    padding-top: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:pr-68,[dir='ltr'].xl\:ltr\:pr-68 {
    padding-right: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:pb-68,[dir='ltr'].xl\:ltr\:pb-68 {
    padding-bottom: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:pl-68,[dir='ltr'].xl\:ltr\:pl-68 {
    padding-left: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:pt-72,[dir='ltr'].xl\:ltr\:pt-72 {
    padding-top: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:pr-72,[dir='ltr'].xl\:ltr\:pr-72 {
    padding-right: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:pb-72,[dir='ltr'].xl\:ltr\:pb-72 {
    padding-bottom: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:pl-72,[dir='ltr'].xl\:ltr\:pl-72 {
    padding-left: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:pt-76,[dir='ltr'].xl\:ltr\:pt-76 {
    padding-top: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:pr-76,[dir='ltr'].xl\:ltr\:pr-76 {
    padding-right: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:pb-76,[dir='ltr'].xl\:ltr\:pb-76 {
    padding-bottom: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:pl-76,[dir='ltr'].xl\:ltr\:pl-76 {
    padding-left: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:pt-80,[dir='ltr'].xl\:ltr\:pt-80 {
    padding-top: 8rem
  }

  [dir='ltr'] .xl\:ltr\:pr-80,[dir='ltr'].xl\:ltr\:pr-80 {
    padding-right: 8rem
  }

  [dir='ltr'] .xl\:ltr\:pb-80,[dir='ltr'].xl\:ltr\:pb-80 {
    padding-bottom: 8rem
  }

  [dir='ltr'] .xl\:ltr\:pl-80,[dir='ltr'].xl\:ltr\:pl-80 {
    padding-left: 8rem
  }

  [dir='ltr'] .xl\:ltr\:pt-84,[dir='ltr'].xl\:ltr\:pt-84 {
    padding-top: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:pr-84,[dir='ltr'].xl\:ltr\:pr-84 {
    padding-right: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:pb-84,[dir='ltr'].xl\:ltr\:pb-84 {
    padding-bottom: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:pl-84,[dir='ltr'].xl\:ltr\:pl-84 {
    padding-left: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:pt-88,[dir='ltr'].xl\:ltr\:pt-88 {
    padding-top: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:pr-88,[dir='ltr'].xl\:ltr\:pr-88 {
    padding-right: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:pb-88,[dir='ltr'].xl\:ltr\:pb-88 {
    padding-bottom: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:pl-88,[dir='ltr'].xl\:ltr\:pl-88 {
    padding-left: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:pt-92,[dir='ltr'].xl\:ltr\:pt-92 {
    padding-top: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:pr-92,[dir='ltr'].xl\:ltr\:pr-92 {
    padding-right: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:pb-92,[dir='ltr'].xl\:ltr\:pb-92 {
    padding-bottom: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:pl-92,[dir='ltr'].xl\:ltr\:pl-92 {
    padding-left: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:pt-96,[dir='ltr'].xl\:ltr\:pt-96 {
    padding-top: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:pr-96,[dir='ltr'].xl\:ltr\:pr-96 {
    padding-right: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:pb-96,[dir='ltr'].xl\:ltr\:pb-96 {
    padding-bottom: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:pl-96,[dir='ltr'].xl\:ltr\:pl-96 {
    padding-left: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:pt-128,[dir='ltr'].xl\:ltr\:pt-128 {
    padding-top: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:pr-128,[dir='ltr'].xl\:ltr\:pr-128 {
    padding-right: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:pb-128,[dir='ltr'].xl\:ltr\:pb-128 {
    padding-bottom: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:pl-128,[dir='ltr'].xl\:ltr\:pl-128 {
    padding-left: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:pt-136,[dir='ltr'].xl\:ltr\:pt-136 {
    padding-top: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:pr-136,[dir='ltr'].xl\:ltr\:pr-136 {
    padding-right: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:pb-136,[dir='ltr'].xl\:ltr\:pb-136 {
    padding-bottom: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:pl-136,[dir='ltr'].xl\:ltr\:pl-136 {
    padding-left: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:pt-160,[dir='ltr'].xl\:ltr\:pt-160 {
    padding-top: 16rem
  }

  [dir='ltr'] .xl\:ltr\:pr-160,[dir='ltr'].xl\:ltr\:pr-160 {
    padding-right: 16rem
  }

  [dir='ltr'] .xl\:ltr\:pb-160,[dir='ltr'].xl\:ltr\:pb-160 {
    padding-bottom: 16rem
  }

  [dir='ltr'] .xl\:ltr\:pl-160,[dir='ltr'].xl\:ltr\:pl-160 {
    padding-left: 16rem
  }

  [dir='ltr'] .xl\:ltr\:pt-192,[dir='ltr'].xl\:ltr\:pt-192 {
    padding-top: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:pr-192,[dir='ltr'].xl\:ltr\:pr-192 {
    padding-right: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:pb-192,[dir='ltr'].xl\:ltr\:pb-192 {
    padding-bottom: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:pl-192,[dir='ltr'].xl\:ltr\:pl-192 {
    padding-left: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:pt-200,[dir='ltr'].xl\:ltr\:pt-200 {
    padding-top: 20rem
  }

  [dir='ltr'] .xl\:ltr\:pr-200,[dir='ltr'].xl\:ltr\:pr-200 {
    padding-right: 20rem
  }

  [dir='ltr'] .xl\:ltr\:pb-200,[dir='ltr'].xl\:ltr\:pb-200 {
    padding-bottom: 20rem
  }

  [dir='ltr'] .xl\:ltr\:pl-200,[dir='ltr'].xl\:ltr\:pl-200 {
    padding-left: 20rem
  }

  [dir='ltr'] .xl\:ltr\:pt-208,[dir='ltr'].xl\:ltr\:pt-208 {
    padding-top: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:pr-208,[dir='ltr'].xl\:ltr\:pr-208 {
    padding-right: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:pb-208,[dir='ltr'].xl\:ltr\:pb-208 {
    padding-bottom: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:pl-208,[dir='ltr'].xl\:ltr\:pl-208 {
    padding-left: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:pt-216,[dir='ltr'].xl\:ltr\:pt-216 {
    padding-top: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:pr-216,[dir='ltr'].xl\:ltr\:pr-216 {
    padding-right: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:pb-216,[dir='ltr'].xl\:ltr\:pb-216 {
    padding-bottom: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:pl-216,[dir='ltr'].xl\:ltr\:pl-216 {
    padding-left: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:pt-224,[dir='ltr'].xl\:ltr\:pt-224 {
    padding-top: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:pr-224,[dir='ltr'].xl\:ltr\:pr-224 {
    padding-right: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:pb-224,[dir='ltr'].xl\:ltr\:pb-224 {
    padding-bottom: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:pl-224,[dir='ltr'].xl\:ltr\:pl-224 {
    padding-left: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:pt-256,[dir='ltr'].xl\:ltr\:pt-256 {
    padding-top: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:pr-256,[dir='ltr'].xl\:ltr\:pr-256 {
    padding-right: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:pb-256,[dir='ltr'].xl\:ltr\:pb-256 {
    padding-bottom: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:pl-256,[dir='ltr'].xl\:ltr\:pl-256 {
    padding-left: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:pt-288,[dir='ltr'].xl\:ltr\:pt-288 {
    padding-top: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:pr-288,[dir='ltr'].xl\:ltr\:pr-288 {
    padding-right: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:pb-288,[dir='ltr'].xl\:ltr\:pb-288 {
    padding-bottom: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:pl-288,[dir='ltr'].xl\:ltr\:pl-288 {
    padding-left: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:pt-320,[dir='ltr'].xl\:ltr\:pt-320 {
    padding-top: 32rem
  }

  [dir='ltr'] .xl\:ltr\:pr-320,[dir='ltr'].xl\:ltr\:pr-320 {
    padding-right: 32rem
  }

  [dir='ltr'] .xl\:ltr\:pb-320,[dir='ltr'].xl\:ltr\:pb-320 {
    padding-bottom: 32rem
  }

  [dir='ltr'] .xl\:ltr\:pl-320,[dir='ltr'].xl\:ltr\:pl-320 {
    padding-left: 32rem
  }

  [dir='ltr'] .xl\:ltr\:pt-360,[dir='ltr'].xl\:ltr\:pt-360 {
    padding-top: 36rem
  }

  [dir='ltr'] .xl\:ltr\:pr-360,[dir='ltr'].xl\:ltr\:pr-360 {
    padding-right: 36rem
  }

  [dir='ltr'] .xl\:ltr\:pb-360,[dir='ltr'].xl\:ltr\:pb-360 {
    padding-bottom: 36rem
  }

  [dir='ltr'] .xl\:ltr\:pl-360,[dir='ltr'].xl\:ltr\:pl-360 {
    padding-left: 36rem
  }

  [dir='ltr'] .xl\:ltr\:pt-384,[dir='ltr'].xl\:ltr\:pt-384 {
    padding-top: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:pr-384,[dir='ltr'].xl\:ltr\:pr-384 {
    padding-right: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:pb-384,[dir='ltr'].xl\:ltr\:pb-384 {
    padding-bottom: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:pl-384,[dir='ltr'].xl\:ltr\:pl-384 {
    padding-left: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:pt-400,[dir='ltr'].xl\:ltr\:pt-400 {
    padding-top: 40rem
  }

  [dir='ltr'] .xl\:ltr\:pr-400,[dir='ltr'].xl\:ltr\:pr-400 {
    padding-right: 40rem
  }

  [dir='ltr'] .xl\:ltr\:pb-400,[dir='ltr'].xl\:ltr\:pb-400 {
    padding-bottom: 40rem
  }

  [dir='ltr'] .xl\:ltr\:pl-400,[dir='ltr'].xl\:ltr\:pl-400 {
    padding-left: 40rem
  }

  [dir='ltr'] .xl\:ltr\:pt-512,[dir='ltr'].xl\:ltr\:pt-512 {
    padding-top: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:pr-512,[dir='ltr'].xl\:ltr\:pr-512 {
    padding-right: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:pb-512,[dir='ltr'].xl\:ltr\:pb-512 {
    padding-bottom: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:pl-512,[dir='ltr'].xl\:ltr\:pl-512 {
    padding-left: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:pt-640,[dir='ltr'].xl\:ltr\:pt-640 {
    padding-top: 64rem
  }

  [dir='ltr'] .xl\:ltr\:pr-640,[dir='ltr'].xl\:ltr\:pr-640 {
    padding-right: 64rem
  }

  [dir='ltr'] .xl\:ltr\:pb-640,[dir='ltr'].xl\:ltr\:pb-640 {
    padding-bottom: 64rem
  }

  [dir='ltr'] .xl\:ltr\:pl-640,[dir='ltr'].xl\:ltr\:pl-640 {
    padding-left: 64rem
  }

  [dir='ltr'] .xl\:ltr\:pt-xs,[dir='ltr'].xl\:ltr\:pt-xs {
    padding-top: 32rem
  }

  [dir='ltr'] .xl\:ltr\:pr-xs,[dir='ltr'].xl\:ltr\:pr-xs {
    padding-right: 32rem
  }

  [dir='ltr'] .xl\:ltr\:pb-xs,[dir='ltr'].xl\:ltr\:pb-xs {
    padding-bottom: 32rem
  }

  [dir='ltr'] .xl\:ltr\:pl-xs,[dir='ltr'].xl\:ltr\:pl-xs {
    padding-left: 32rem
  }

  [dir='ltr'] .xl\:ltr\:pt-sm,[dir='ltr'].xl\:ltr\:pt-sm {
    padding-top: 48rem
  }

  [dir='ltr'] .xl\:ltr\:pr-sm,[dir='ltr'].xl\:ltr\:pr-sm {
    padding-right: 48rem
  }

  [dir='ltr'] .xl\:ltr\:pb-sm,[dir='ltr'].xl\:ltr\:pb-sm {
    padding-bottom: 48rem
  }

  [dir='ltr'] .xl\:ltr\:pl-sm,[dir='ltr'].xl\:ltr\:pl-sm {
    padding-left: 48rem
  }

  [dir='ltr'] .xl\:ltr\:pt-md,[dir='ltr'].xl\:ltr\:pt-md {
    padding-top: 64rem
  }

  [dir='ltr'] .xl\:ltr\:pr-md,[dir='ltr'].xl\:ltr\:pr-md {
    padding-right: 64rem
  }

  [dir='ltr'] .xl\:ltr\:pb-md,[dir='ltr'].xl\:ltr\:pb-md {
    padding-bottom: 64rem
  }

  [dir='ltr'] .xl\:ltr\:pl-md,[dir='ltr'].xl\:ltr\:pl-md {
    padding-left: 64rem
  }

  [dir='ltr'] .xl\:ltr\:pt-lg,[dir='ltr'].xl\:ltr\:pt-lg {
    padding-top: 80rem
  }

  [dir='ltr'] .xl\:ltr\:pr-lg,[dir='ltr'].xl\:ltr\:pr-lg {
    padding-right: 80rem
  }

  [dir='ltr'] .xl\:ltr\:pb-lg,[dir='ltr'].xl\:ltr\:pb-lg {
    padding-bottom: 80rem
  }

  [dir='ltr'] .xl\:ltr\:pl-lg,[dir='ltr'].xl\:ltr\:pl-lg {
    padding-left: 80rem
  }

  [dir='ltr'] .xl\:ltr\:pt-xl,[dir='ltr'].xl\:ltr\:pt-xl {
    padding-top: 96rem
  }

  [dir='ltr'] .xl\:ltr\:pr-xl,[dir='ltr'].xl\:ltr\:pr-xl {
    padding-right: 96rem
  }

  [dir='ltr'] .xl\:ltr\:pb-xl,[dir='ltr'].xl\:ltr\:pb-xl {
    padding-bottom: 96rem
  }

  [dir='ltr'] .xl\:ltr\:pl-xl,[dir='ltr'].xl\:ltr\:pl-xl {
    padding-left: 96rem
  }

  [dir='ltr'] .xl\:ltr\:pt-2xl,[dir='ltr'].xl\:ltr\:pt-2xl {
    padding-top: 112rem
  }

  [dir='ltr'] .xl\:ltr\:pr-2xl,[dir='ltr'].xl\:ltr\:pr-2xl {
    padding-right: 112rem
  }

  [dir='ltr'] .xl\:ltr\:pb-2xl,[dir='ltr'].xl\:ltr\:pb-2xl {
    padding-bottom: 112rem
  }

  [dir='ltr'] .xl\:ltr\:pl-2xl,[dir='ltr'].xl\:ltr\:pl-2xl {
    padding-left: 112rem
  }

  [dir='ltr'] .xl\:ltr\:pt-3xl,[dir='ltr'].xl\:ltr\:pt-3xl {
    padding-top: 128rem
  }

  [dir='ltr'] .xl\:ltr\:pr-3xl,[dir='ltr'].xl\:ltr\:pr-3xl {
    padding-right: 128rem
  }

  [dir='ltr'] .xl\:ltr\:pb-3xl,[dir='ltr'].xl\:ltr\:pb-3xl {
    padding-bottom: 128rem
  }

  [dir='ltr'] .xl\:ltr\:pl-3xl,[dir='ltr'].xl\:ltr\:pl-3xl {
    padding-left: 128rem
  }

  [dir='ltr'] .xl\:ltr\:pt-4xl,[dir='ltr'].xl\:ltr\:pt-4xl {
    padding-top: 144rem
  }

  [dir='ltr'] .xl\:ltr\:pr-4xl,[dir='ltr'].xl\:ltr\:pr-4xl {
    padding-right: 144rem
  }

  [dir='ltr'] .xl\:ltr\:pb-4xl,[dir='ltr'].xl\:ltr\:pb-4xl {
    padding-bottom: 144rem
  }

  [dir='ltr'] .xl\:ltr\:pl-4xl,[dir='ltr'].xl\:ltr\:pl-4xl {
    padding-left: 144rem
  }

  [dir='ltr'] .xl\:ltr\:pt-5xl,[dir='ltr'].xl\:ltr\:pt-5xl {
    padding-top: 160rem
  }

  [dir='ltr'] .xl\:ltr\:pr-5xl,[dir='ltr'].xl\:ltr\:pr-5xl {
    padding-right: 160rem
  }

  [dir='ltr'] .xl\:ltr\:pb-5xl,[dir='ltr'].xl\:ltr\:pb-5xl {
    padding-bottom: 160rem
  }

  [dir='ltr'] .xl\:ltr\:pl-5xl,[dir='ltr'].xl\:ltr\:pl-5xl {
    padding-left: 160rem
  }

  [dir='ltr'] .xl\:ltr\:pt-px,[dir='ltr'].xl\:ltr\:pt-px {
    padding-top: 1px
  }

  [dir='ltr'] .xl\:ltr\:pr-px,[dir='ltr'].xl\:ltr\:pr-px {
    padding-right: 1px
  }

  [dir='ltr'] .xl\:ltr\:pb-px,[dir='ltr'].xl\:ltr\:pb-px {
    padding-bottom: 1px
  }

  [dir='ltr'] .xl\:ltr\:pl-px,[dir='ltr'].xl\:ltr\:pl-px {
    padding-left: 1px
  }

  [dir='ltr'] .xl\:ltr\:pt-0\.5,[dir='ltr'].xl\:ltr\:pt-0\.5 {
    padding-top: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:pr-0\.5,[dir='ltr'].xl\:ltr\:pr-0\.5 {
    padding-right: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:pb-0\.5,[dir='ltr'].xl\:ltr\:pb-0\.5 {
    padding-bottom: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:pl-0\.5,[dir='ltr'].xl\:ltr\:pl-0\.5 {
    padding-left: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:pt-1\.5,[dir='ltr'].xl\:ltr\:pt-1\.5 {
    padding-top: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:pr-1\.5,[dir='ltr'].xl\:ltr\:pr-1\.5 {
    padding-right: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:pb-1\.5,[dir='ltr'].xl\:ltr\:pb-1\.5 {
    padding-bottom: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:pl-1\.5,[dir='ltr'].xl\:ltr\:pl-1\.5 {
    padding-left: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:pt-2\.5,[dir='ltr'].xl\:ltr\:pt-2\.5 {
    padding-top: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:pr-2\.5,[dir='ltr'].xl\:ltr\:pr-2\.5 {
    padding-right: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:pb-2\.5,[dir='ltr'].xl\:ltr\:pb-2\.5 {
    padding-bottom: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:pl-2\.5,[dir='ltr'].xl\:ltr\:pl-2\.5 {
    padding-left: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:pt-3\.5,[dir='ltr'].xl\:ltr\:pt-3\.5 {
    padding-top: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:pr-3\.5,[dir='ltr'].xl\:ltr\:pr-3\.5 {
    padding-right: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:pb-3\.5,[dir='ltr'].xl\:ltr\:pb-3\.5 {
    padding-bottom: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:pl-3\.5,[dir='ltr'].xl\:ltr\:pl-3\.5 {
    padding-left: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:p-0,[dir='rtl'].xl\:rtl\:p-0 {
    padding: 0
  }

  [dir='rtl'] .xl\:rtl\:p-1,[dir='rtl'].xl\:rtl\:p-1 {
    padding: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:p-2,[dir='rtl'].xl\:rtl\:p-2 {
    padding: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:p-3,[dir='rtl'].xl\:rtl\:p-3 {
    padding: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:p-4,[dir='rtl'].xl\:rtl\:p-4 {
    padding: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:p-5,[dir='rtl'].xl\:rtl\:p-5 {
    padding: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:p-6,[dir='rtl'].xl\:rtl\:p-6 {
    padding: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:p-7,[dir='rtl'].xl\:rtl\:p-7 {
    padding: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:p-8,[dir='rtl'].xl\:rtl\:p-8 {
    padding: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:p-9,[dir='rtl'].xl\:rtl\:p-9 {
    padding: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:p-10,[dir='rtl'].xl\:rtl\:p-10 {
    padding: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:p-12,[dir='rtl'].xl\:rtl\:p-12 {
    padding: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:p-14,[dir='rtl'].xl\:rtl\:p-14 {
    padding: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:p-16,[dir='rtl'].xl\:rtl\:p-16 {
    padding: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:p-20,[dir='rtl'].xl\:rtl\:p-20 {
    padding: 2rem
  }

  [dir='rtl'] .xl\:rtl\:p-24,[dir='rtl'].xl\:rtl\:p-24 {
    padding: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:p-28,[dir='rtl'].xl\:rtl\:p-28 {
    padding: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:p-32,[dir='rtl'].xl\:rtl\:p-32 {
    padding: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:p-36,[dir='rtl'].xl\:rtl\:p-36 {
    padding: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:p-40,[dir='rtl'].xl\:rtl\:p-40 {
    padding: 4rem
  }

  [dir='rtl'] .xl\:rtl\:p-44,[dir='rtl'].xl\:rtl\:p-44 {
    padding: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:p-48,[dir='rtl'].xl\:rtl\:p-48 {
    padding: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:p-52,[dir='rtl'].xl\:rtl\:p-52 {
    padding: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:p-56,[dir='rtl'].xl\:rtl\:p-56 {
    padding: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:p-60,[dir='rtl'].xl\:rtl\:p-60 {
    padding: 6rem
  }

  [dir='rtl'] .xl\:rtl\:p-64,[dir='rtl'].xl\:rtl\:p-64 {
    padding: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:p-68,[dir='rtl'].xl\:rtl\:p-68 {
    padding: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:p-72,[dir='rtl'].xl\:rtl\:p-72 {
    padding: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:p-76,[dir='rtl'].xl\:rtl\:p-76 {
    padding: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:p-80,[dir='rtl'].xl\:rtl\:p-80 {
    padding: 8rem
  }

  [dir='rtl'] .xl\:rtl\:p-84,[dir='rtl'].xl\:rtl\:p-84 {
    padding: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:p-88,[dir='rtl'].xl\:rtl\:p-88 {
    padding: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:p-92,[dir='rtl'].xl\:rtl\:p-92 {
    padding: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:p-96,[dir='rtl'].xl\:rtl\:p-96 {
    padding: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:p-128,[dir='rtl'].xl\:rtl\:p-128 {
    padding: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:p-136,[dir='rtl'].xl\:rtl\:p-136 {
    padding: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:p-160,[dir='rtl'].xl\:rtl\:p-160 {
    padding: 16rem
  }

  [dir='rtl'] .xl\:rtl\:p-192,[dir='rtl'].xl\:rtl\:p-192 {
    padding: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:p-200,[dir='rtl'].xl\:rtl\:p-200 {
    padding: 20rem
  }

  [dir='rtl'] .xl\:rtl\:p-208,[dir='rtl'].xl\:rtl\:p-208 {
    padding: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:p-216,[dir='rtl'].xl\:rtl\:p-216 {
    padding: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:p-224,[dir='rtl'].xl\:rtl\:p-224 {
    padding: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:p-256,[dir='rtl'].xl\:rtl\:p-256 {
    padding: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:p-288,[dir='rtl'].xl\:rtl\:p-288 {
    padding: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:p-320,[dir='rtl'].xl\:rtl\:p-320 {
    padding: 32rem
  }

  [dir='rtl'] .xl\:rtl\:p-360,[dir='rtl'].xl\:rtl\:p-360 {
    padding: 36rem
  }

  [dir='rtl'] .xl\:rtl\:p-384,[dir='rtl'].xl\:rtl\:p-384 {
    padding: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:p-400,[dir='rtl'].xl\:rtl\:p-400 {
    padding: 40rem
  }

  [dir='rtl'] .xl\:rtl\:p-512,[dir='rtl'].xl\:rtl\:p-512 {
    padding: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:p-640,[dir='rtl'].xl\:rtl\:p-640 {
    padding: 64rem
  }

  [dir='rtl'] .xl\:rtl\:p-xs,[dir='rtl'].xl\:rtl\:p-xs {
    padding: 32rem
  }

  [dir='rtl'] .xl\:rtl\:p-sm,[dir='rtl'].xl\:rtl\:p-sm {
    padding: 48rem
  }

  [dir='rtl'] .xl\:rtl\:p-md,[dir='rtl'].xl\:rtl\:p-md {
    padding: 64rem
  }

  [dir='rtl'] .xl\:rtl\:p-lg,[dir='rtl'].xl\:rtl\:p-lg {
    padding: 80rem
  }

  [dir='rtl'] .xl\:rtl\:p-xl,[dir='rtl'].xl\:rtl\:p-xl {
    padding: 96rem
  }

  [dir='rtl'] .xl\:rtl\:p-2xl,[dir='rtl'].xl\:rtl\:p-2xl {
    padding: 112rem
  }

  [dir='rtl'] .xl\:rtl\:p-3xl,[dir='rtl'].xl\:rtl\:p-3xl {
    padding: 128rem
  }

  [dir='rtl'] .xl\:rtl\:p-4xl,[dir='rtl'].xl\:rtl\:p-4xl {
    padding: 144rem
  }

  [dir='rtl'] .xl\:rtl\:p-5xl,[dir='rtl'].xl\:rtl\:p-5xl {
    padding: 160rem
  }

  [dir='rtl'] .xl\:rtl\:p-px,[dir='rtl'].xl\:rtl\:p-px {
    padding: 1px
  }

  [dir='rtl'] .xl\:rtl\:p-0\.5,[dir='rtl'].xl\:rtl\:p-0\.5 {
    padding: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:p-1\.5,[dir='rtl'].xl\:rtl\:p-1\.5 {
    padding: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:p-2\.5,[dir='rtl'].xl\:rtl\:p-2\.5 {
    padding: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:p-3\.5,[dir='rtl'].xl\:rtl\:p-3\.5 {
    padding: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:py-0,[dir='rtl'].xl\:rtl\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  [dir='rtl'] .xl\:rtl\:px-0,[dir='rtl'].xl\:rtl\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  [dir='rtl'] .xl\:rtl\:py-1,[dir='rtl'].xl\:rtl\:py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:px-1,[dir='rtl'].xl\:rtl\:px-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:py-2,[dir='rtl'].xl\:rtl\:py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:px-2,[dir='rtl'].xl\:rtl\:px-2 {
    padding-left: 0.2rem;
    padding-right: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:py-3,[dir='rtl'].xl\:rtl\:py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:px-3,[dir='rtl'].xl\:rtl\:px-3 {
    padding-left: 0.3rem;
    padding-right: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:py-4,[dir='rtl'].xl\:rtl\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:px-4,[dir='rtl'].xl\:rtl\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:py-5,[dir='rtl'].xl\:rtl\:py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:px-5,[dir='rtl'].xl\:rtl\:px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:py-6,[dir='rtl'].xl\:rtl\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:px-6,[dir='rtl'].xl\:rtl\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:py-7,[dir='rtl'].xl\:rtl\:py-7 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:px-7,[dir='rtl'].xl\:rtl\:px-7 {
    padding-left: 0.7rem;
    padding-right: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:py-8,[dir='rtl'].xl\:rtl\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:px-8,[dir='rtl'].xl\:rtl\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:py-9,[dir='rtl'].xl\:rtl\:py-9 {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:px-9,[dir='rtl'].xl\:rtl\:px-9 {
    padding-left: 0.9rem;
    padding-right: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:py-10,[dir='rtl'].xl\:rtl\:py-10 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:px-10,[dir='rtl'].xl\:rtl\:px-10 {
    padding-left: 1.0rem;
    padding-right: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:py-12,[dir='rtl'].xl\:rtl\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:px-12,[dir='rtl'].xl\:rtl\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:py-14,[dir='rtl'].xl\:rtl\:py-14 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:px-14,[dir='rtl'].xl\:rtl\:px-14 {
    padding-left: 1.4rem;
    padding-right: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:py-16,[dir='rtl'].xl\:rtl\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:px-16,[dir='rtl'].xl\:rtl\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:py-20,[dir='rtl'].xl\:rtl\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  [dir='rtl'] .xl\:rtl\:px-20,[dir='rtl'].xl\:rtl\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  [dir='rtl'] .xl\:rtl\:py-24,[dir='rtl'].xl\:rtl\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:px-24,[dir='rtl'].xl\:rtl\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:py-28,[dir='rtl'].xl\:rtl\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:px-28,[dir='rtl'].xl\:rtl\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:py-32,[dir='rtl'].xl\:rtl\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:px-32,[dir='rtl'].xl\:rtl\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:py-36,[dir='rtl'].xl\:rtl\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:px-36,[dir='rtl'].xl\:rtl\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:py-40,[dir='rtl'].xl\:rtl\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  [dir='rtl'] .xl\:rtl\:px-40,[dir='rtl'].xl\:rtl\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  [dir='rtl'] .xl\:rtl\:py-44,[dir='rtl'].xl\:rtl\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:px-44,[dir='rtl'].xl\:rtl\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:py-48,[dir='rtl'].xl\:rtl\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:px-48,[dir='rtl'].xl\:rtl\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:py-52,[dir='rtl'].xl\:rtl\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:px-52,[dir='rtl'].xl\:rtl\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:py-56,[dir='rtl'].xl\:rtl\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:px-56,[dir='rtl'].xl\:rtl\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:py-60,[dir='rtl'].xl\:rtl\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  [dir='rtl'] .xl\:rtl\:px-60,[dir='rtl'].xl\:rtl\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  [dir='rtl'] .xl\:rtl\:py-64,[dir='rtl'].xl\:rtl\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:px-64,[dir='rtl'].xl\:rtl\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:py-68,[dir='rtl'].xl\:rtl\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:px-68,[dir='rtl'].xl\:rtl\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:py-72,[dir='rtl'].xl\:rtl\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:px-72,[dir='rtl'].xl\:rtl\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:py-76,[dir='rtl'].xl\:rtl\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:px-76,[dir='rtl'].xl\:rtl\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:py-80,[dir='rtl'].xl\:rtl\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  [dir='rtl'] .xl\:rtl\:px-80,[dir='rtl'].xl\:rtl\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  [dir='rtl'] .xl\:rtl\:py-84,[dir='rtl'].xl\:rtl\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:px-84,[dir='rtl'].xl\:rtl\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:py-88,[dir='rtl'].xl\:rtl\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:px-88,[dir='rtl'].xl\:rtl\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:py-92,[dir='rtl'].xl\:rtl\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:px-92,[dir='rtl'].xl\:rtl\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:py-96,[dir='rtl'].xl\:rtl\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:px-96,[dir='rtl'].xl\:rtl\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:py-128,[dir='rtl'].xl\:rtl\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:px-128,[dir='rtl'].xl\:rtl\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:py-136,[dir='rtl'].xl\:rtl\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:px-136,[dir='rtl'].xl\:rtl\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:py-160,[dir='rtl'].xl\:rtl\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  [dir='rtl'] .xl\:rtl\:px-160,[dir='rtl'].xl\:rtl\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  [dir='rtl'] .xl\:rtl\:py-192,[dir='rtl'].xl\:rtl\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:px-192,[dir='rtl'].xl\:rtl\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:py-200,[dir='rtl'].xl\:rtl\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  [dir='rtl'] .xl\:rtl\:px-200,[dir='rtl'].xl\:rtl\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  [dir='rtl'] .xl\:rtl\:py-208,[dir='rtl'].xl\:rtl\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:px-208,[dir='rtl'].xl\:rtl\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:py-216,[dir='rtl'].xl\:rtl\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:px-216,[dir='rtl'].xl\:rtl\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:py-224,[dir='rtl'].xl\:rtl\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:px-224,[dir='rtl'].xl\:rtl\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:py-256,[dir='rtl'].xl\:rtl\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:px-256,[dir='rtl'].xl\:rtl\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:py-288,[dir='rtl'].xl\:rtl\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:px-288,[dir='rtl'].xl\:rtl\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:py-320,[dir='rtl'].xl\:rtl\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='rtl'] .xl\:rtl\:px-320,[dir='rtl'].xl\:rtl\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='rtl'] .xl\:rtl\:py-360,[dir='rtl'].xl\:rtl\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  [dir='rtl'] .xl\:rtl\:px-360,[dir='rtl'].xl\:rtl\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  [dir='rtl'] .xl\:rtl\:py-384,[dir='rtl'].xl\:rtl\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:px-384,[dir='rtl'].xl\:rtl\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:py-400,[dir='rtl'].xl\:rtl\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  [dir='rtl'] .xl\:rtl\:px-400,[dir='rtl'].xl\:rtl\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  [dir='rtl'] .xl\:rtl\:py-512,[dir='rtl'].xl\:rtl\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:px-512,[dir='rtl'].xl\:rtl\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:py-640,[dir='rtl'].xl\:rtl\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='rtl'] .xl\:rtl\:px-640,[dir='rtl'].xl\:rtl\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='rtl'] .xl\:rtl\:py-xs,[dir='rtl'].xl\:rtl\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='rtl'] .xl\:rtl\:px-xs,[dir='rtl'].xl\:rtl\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='rtl'] .xl\:rtl\:py-sm,[dir='rtl'].xl\:rtl\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  [dir='rtl'] .xl\:rtl\:px-sm,[dir='rtl'].xl\:rtl\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  [dir='rtl'] .xl\:rtl\:py-md,[dir='rtl'].xl\:rtl\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='rtl'] .xl\:rtl\:px-md,[dir='rtl'].xl\:rtl\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='rtl'] .xl\:rtl\:py-lg,[dir='rtl'].xl\:rtl\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  [dir='rtl'] .xl\:rtl\:px-lg,[dir='rtl'].xl\:rtl\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  [dir='rtl'] .xl\:rtl\:py-xl,[dir='rtl'].xl\:rtl\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  [dir='rtl'] .xl\:rtl\:px-xl,[dir='rtl'].xl\:rtl\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  [dir='rtl'] .xl\:rtl\:py-2xl,[dir='rtl'].xl\:rtl\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  [dir='rtl'] .xl\:rtl\:px-2xl,[dir='rtl'].xl\:rtl\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  [dir='rtl'] .xl\:rtl\:py-3xl,[dir='rtl'].xl\:rtl\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  [dir='rtl'] .xl\:rtl\:px-3xl,[dir='rtl'].xl\:rtl\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  [dir='rtl'] .xl\:rtl\:py-4xl,[dir='rtl'].xl\:rtl\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  [dir='rtl'] .xl\:rtl\:px-4xl,[dir='rtl'].xl\:rtl\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  [dir='rtl'] .xl\:rtl\:py-5xl,[dir='rtl'].xl\:rtl\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  [dir='rtl'] .xl\:rtl\:px-5xl,[dir='rtl'].xl\:rtl\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  [dir='rtl'] .xl\:rtl\:py-px,[dir='rtl'].xl\:rtl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  [dir='rtl'] .xl\:rtl\:px-px,[dir='rtl'].xl\:rtl\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  [dir='rtl'] .xl\:rtl\:py-0\.5,[dir='rtl'].xl\:rtl\:py-0\.5 {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:px-0\.5,[dir='rtl'].xl\:rtl\:px-0\.5 {
    padding-left: 0.05rem;
    padding-right: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:py-1\.5,[dir='rtl'].xl\:rtl\:py-1\.5 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:px-1\.5,[dir='rtl'].xl\:rtl\:px-1\.5 {
    padding-left: 0.15rem;
    padding-right: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:py-2\.5,[dir='rtl'].xl\:rtl\:py-2\.5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:px-2\.5,[dir='rtl'].xl\:rtl\:px-2\.5 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:py-3\.5,[dir='rtl'].xl\:rtl\:py-3\.5 {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:px-3\.5,[dir='rtl'].xl\:rtl\:px-3\.5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:pt-0,[dir='rtl'].xl\:rtl\:pt-0 {
    padding-top: 0
  }

  [dir='rtl'] .xl\:rtl\:pr-0,[dir='rtl'].xl\:rtl\:pr-0 {
    padding-right: 0
  }

  [dir='rtl'] .xl\:rtl\:pb-0,[dir='rtl'].xl\:rtl\:pb-0 {
    padding-bottom: 0
  }

  [dir='rtl'] .xl\:rtl\:pl-0,[dir='rtl'].xl\:rtl\:pl-0 {
    padding-left: 0
  }

  [dir='rtl'] .xl\:rtl\:pt-1,[dir='rtl'].xl\:rtl\:pt-1 {
    padding-top: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:pr-1,[dir='rtl'].xl\:rtl\:pr-1 {
    padding-right: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:pb-1,[dir='rtl'].xl\:rtl\:pb-1 {
    padding-bottom: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:pl-1,[dir='rtl'].xl\:rtl\:pl-1 {
    padding-left: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:pt-2,[dir='rtl'].xl\:rtl\:pt-2 {
    padding-top: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:pr-2,[dir='rtl'].xl\:rtl\:pr-2 {
    padding-right: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:pb-2,[dir='rtl'].xl\:rtl\:pb-2 {
    padding-bottom: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:pl-2,[dir='rtl'].xl\:rtl\:pl-2 {
    padding-left: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:pt-3,[dir='rtl'].xl\:rtl\:pt-3 {
    padding-top: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:pr-3,[dir='rtl'].xl\:rtl\:pr-3 {
    padding-right: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:pb-3,[dir='rtl'].xl\:rtl\:pb-3 {
    padding-bottom: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:pl-3,[dir='rtl'].xl\:rtl\:pl-3 {
    padding-left: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:pt-4,[dir='rtl'].xl\:rtl\:pt-4 {
    padding-top: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:pr-4,[dir='rtl'].xl\:rtl\:pr-4 {
    padding-right: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:pb-4,[dir='rtl'].xl\:rtl\:pb-4 {
    padding-bottom: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:pl-4,[dir='rtl'].xl\:rtl\:pl-4 {
    padding-left: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:pt-5,[dir='rtl'].xl\:rtl\:pt-5 {
    padding-top: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:pr-5,[dir='rtl'].xl\:rtl\:pr-5 {
    padding-right: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:pb-5,[dir='rtl'].xl\:rtl\:pb-5 {
    padding-bottom: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:pl-5,[dir='rtl'].xl\:rtl\:pl-5 {
    padding-left: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:pt-6,[dir='rtl'].xl\:rtl\:pt-6 {
    padding-top: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:pr-6,[dir='rtl'].xl\:rtl\:pr-6 {
    padding-right: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:pb-6,[dir='rtl'].xl\:rtl\:pb-6 {
    padding-bottom: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:pl-6,[dir='rtl'].xl\:rtl\:pl-6 {
    padding-left: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:pt-7,[dir='rtl'].xl\:rtl\:pt-7 {
    padding-top: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:pr-7,[dir='rtl'].xl\:rtl\:pr-7 {
    padding-right: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:pb-7,[dir='rtl'].xl\:rtl\:pb-7 {
    padding-bottom: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:pl-7,[dir='rtl'].xl\:rtl\:pl-7 {
    padding-left: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:pt-8,[dir='rtl'].xl\:rtl\:pt-8 {
    padding-top: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:pr-8,[dir='rtl'].xl\:rtl\:pr-8 {
    padding-right: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:pb-8,[dir='rtl'].xl\:rtl\:pb-8 {
    padding-bottom: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:pl-8,[dir='rtl'].xl\:rtl\:pl-8 {
    padding-left: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:pt-9,[dir='rtl'].xl\:rtl\:pt-9 {
    padding-top: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:pr-9,[dir='rtl'].xl\:rtl\:pr-9 {
    padding-right: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:pb-9,[dir='rtl'].xl\:rtl\:pb-9 {
    padding-bottom: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:pl-9,[dir='rtl'].xl\:rtl\:pl-9 {
    padding-left: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:pt-10,[dir='rtl'].xl\:rtl\:pt-10 {
    padding-top: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:pr-10,[dir='rtl'].xl\:rtl\:pr-10 {
    padding-right: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:pb-10,[dir='rtl'].xl\:rtl\:pb-10 {
    padding-bottom: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:pl-10,[dir='rtl'].xl\:rtl\:pl-10 {
    padding-left: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:pt-12,[dir='rtl'].xl\:rtl\:pt-12 {
    padding-top: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:pr-12,[dir='rtl'].xl\:rtl\:pr-12 {
    padding-right: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:pb-12,[dir='rtl'].xl\:rtl\:pb-12 {
    padding-bottom: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:pl-12,[dir='rtl'].xl\:rtl\:pl-12 {
    padding-left: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:pt-14,[dir='rtl'].xl\:rtl\:pt-14 {
    padding-top: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:pr-14,[dir='rtl'].xl\:rtl\:pr-14 {
    padding-right: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:pb-14,[dir='rtl'].xl\:rtl\:pb-14 {
    padding-bottom: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:pl-14,[dir='rtl'].xl\:rtl\:pl-14 {
    padding-left: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:pt-16,[dir='rtl'].xl\:rtl\:pt-16 {
    padding-top: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:pr-16,[dir='rtl'].xl\:rtl\:pr-16 {
    padding-right: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:pb-16,[dir='rtl'].xl\:rtl\:pb-16 {
    padding-bottom: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:pl-16,[dir='rtl'].xl\:rtl\:pl-16 {
    padding-left: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:pt-20,[dir='rtl'].xl\:rtl\:pt-20 {
    padding-top: 2rem
  }

  [dir='rtl'] .xl\:rtl\:pr-20,[dir='rtl'].xl\:rtl\:pr-20 {
    padding-right: 2rem
  }

  [dir='rtl'] .xl\:rtl\:pb-20,[dir='rtl'].xl\:rtl\:pb-20 {
    padding-bottom: 2rem
  }

  [dir='rtl'] .xl\:rtl\:pl-20,[dir='rtl'].xl\:rtl\:pl-20 {
    padding-left: 2rem
  }

  [dir='rtl'] .xl\:rtl\:pt-24,[dir='rtl'].xl\:rtl\:pt-24 {
    padding-top: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:pr-24,[dir='rtl'].xl\:rtl\:pr-24 {
    padding-right: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:pb-24,[dir='rtl'].xl\:rtl\:pb-24 {
    padding-bottom: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:pl-24,[dir='rtl'].xl\:rtl\:pl-24 {
    padding-left: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:pt-28,[dir='rtl'].xl\:rtl\:pt-28 {
    padding-top: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:pr-28,[dir='rtl'].xl\:rtl\:pr-28 {
    padding-right: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:pb-28,[dir='rtl'].xl\:rtl\:pb-28 {
    padding-bottom: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:pl-28,[dir='rtl'].xl\:rtl\:pl-28 {
    padding-left: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:pt-32,[dir='rtl'].xl\:rtl\:pt-32 {
    padding-top: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:pr-32,[dir='rtl'].xl\:rtl\:pr-32 {
    padding-right: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:pb-32,[dir='rtl'].xl\:rtl\:pb-32 {
    padding-bottom: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:pl-32,[dir='rtl'].xl\:rtl\:pl-32 {
    padding-left: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:pt-36,[dir='rtl'].xl\:rtl\:pt-36 {
    padding-top: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:pr-36,[dir='rtl'].xl\:rtl\:pr-36 {
    padding-right: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:pb-36,[dir='rtl'].xl\:rtl\:pb-36 {
    padding-bottom: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:pl-36,[dir='rtl'].xl\:rtl\:pl-36 {
    padding-left: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:pt-40,[dir='rtl'].xl\:rtl\:pt-40 {
    padding-top: 4rem
  }

  [dir='rtl'] .xl\:rtl\:pr-40,[dir='rtl'].xl\:rtl\:pr-40 {
    padding-right: 4rem
  }

  [dir='rtl'] .xl\:rtl\:pb-40,[dir='rtl'].xl\:rtl\:pb-40 {
    padding-bottom: 4rem
  }

  [dir='rtl'] .xl\:rtl\:pl-40,[dir='rtl'].xl\:rtl\:pl-40 {
    padding-left: 4rem
  }

  [dir='rtl'] .xl\:rtl\:pt-44,[dir='rtl'].xl\:rtl\:pt-44 {
    padding-top: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:pr-44,[dir='rtl'].xl\:rtl\:pr-44 {
    padding-right: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:pb-44,[dir='rtl'].xl\:rtl\:pb-44 {
    padding-bottom: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:pl-44,[dir='rtl'].xl\:rtl\:pl-44 {
    padding-left: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:pt-48,[dir='rtl'].xl\:rtl\:pt-48 {
    padding-top: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:pr-48,[dir='rtl'].xl\:rtl\:pr-48 {
    padding-right: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:pb-48,[dir='rtl'].xl\:rtl\:pb-48 {
    padding-bottom: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:pl-48,[dir='rtl'].xl\:rtl\:pl-48 {
    padding-left: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:pt-52,[dir='rtl'].xl\:rtl\:pt-52 {
    padding-top: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:pr-52,[dir='rtl'].xl\:rtl\:pr-52 {
    padding-right: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:pb-52,[dir='rtl'].xl\:rtl\:pb-52 {
    padding-bottom: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:pl-52,[dir='rtl'].xl\:rtl\:pl-52 {
    padding-left: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:pt-56,[dir='rtl'].xl\:rtl\:pt-56 {
    padding-top: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:pr-56,[dir='rtl'].xl\:rtl\:pr-56 {
    padding-right: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:pb-56,[dir='rtl'].xl\:rtl\:pb-56 {
    padding-bottom: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:pl-56,[dir='rtl'].xl\:rtl\:pl-56 {
    padding-left: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:pt-60,[dir='rtl'].xl\:rtl\:pt-60 {
    padding-top: 6rem
  }

  [dir='rtl'] .xl\:rtl\:pr-60,[dir='rtl'].xl\:rtl\:pr-60 {
    padding-right: 6rem
  }

  [dir='rtl'] .xl\:rtl\:pb-60,[dir='rtl'].xl\:rtl\:pb-60 {
    padding-bottom: 6rem
  }

  [dir='rtl'] .xl\:rtl\:pl-60,[dir='rtl'].xl\:rtl\:pl-60 {
    padding-left: 6rem
  }

  [dir='rtl'] .xl\:rtl\:pt-64,[dir='rtl'].xl\:rtl\:pt-64 {
    padding-top: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:pr-64,[dir='rtl'].xl\:rtl\:pr-64 {
    padding-right: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:pb-64,[dir='rtl'].xl\:rtl\:pb-64 {
    padding-bottom: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:pl-64,[dir='rtl'].xl\:rtl\:pl-64 {
    padding-left: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:pt-68,[dir='rtl'].xl\:rtl\:pt-68 {
    padding-top: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:pr-68,[dir='rtl'].xl\:rtl\:pr-68 {
    padding-right: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:pb-68,[dir='rtl'].xl\:rtl\:pb-68 {
    padding-bottom: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:pl-68,[dir='rtl'].xl\:rtl\:pl-68 {
    padding-left: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:pt-72,[dir='rtl'].xl\:rtl\:pt-72 {
    padding-top: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:pr-72,[dir='rtl'].xl\:rtl\:pr-72 {
    padding-right: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:pb-72,[dir='rtl'].xl\:rtl\:pb-72 {
    padding-bottom: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:pl-72,[dir='rtl'].xl\:rtl\:pl-72 {
    padding-left: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:pt-76,[dir='rtl'].xl\:rtl\:pt-76 {
    padding-top: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:pr-76,[dir='rtl'].xl\:rtl\:pr-76 {
    padding-right: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:pb-76,[dir='rtl'].xl\:rtl\:pb-76 {
    padding-bottom: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:pl-76,[dir='rtl'].xl\:rtl\:pl-76 {
    padding-left: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:pt-80,[dir='rtl'].xl\:rtl\:pt-80 {
    padding-top: 8rem
  }

  [dir='rtl'] .xl\:rtl\:pr-80,[dir='rtl'].xl\:rtl\:pr-80 {
    padding-right: 8rem
  }

  [dir='rtl'] .xl\:rtl\:pb-80,[dir='rtl'].xl\:rtl\:pb-80 {
    padding-bottom: 8rem
  }

  [dir='rtl'] .xl\:rtl\:pl-80,[dir='rtl'].xl\:rtl\:pl-80 {
    padding-left: 8rem
  }

  [dir='rtl'] .xl\:rtl\:pt-84,[dir='rtl'].xl\:rtl\:pt-84 {
    padding-top: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:pr-84,[dir='rtl'].xl\:rtl\:pr-84 {
    padding-right: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:pb-84,[dir='rtl'].xl\:rtl\:pb-84 {
    padding-bottom: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:pl-84,[dir='rtl'].xl\:rtl\:pl-84 {
    padding-left: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:pt-88,[dir='rtl'].xl\:rtl\:pt-88 {
    padding-top: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:pr-88,[dir='rtl'].xl\:rtl\:pr-88 {
    padding-right: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:pb-88,[dir='rtl'].xl\:rtl\:pb-88 {
    padding-bottom: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:pl-88,[dir='rtl'].xl\:rtl\:pl-88 {
    padding-left: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:pt-92,[dir='rtl'].xl\:rtl\:pt-92 {
    padding-top: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:pr-92,[dir='rtl'].xl\:rtl\:pr-92 {
    padding-right: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:pb-92,[dir='rtl'].xl\:rtl\:pb-92 {
    padding-bottom: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:pl-92,[dir='rtl'].xl\:rtl\:pl-92 {
    padding-left: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:pt-96,[dir='rtl'].xl\:rtl\:pt-96 {
    padding-top: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:pr-96,[dir='rtl'].xl\:rtl\:pr-96 {
    padding-right: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:pb-96,[dir='rtl'].xl\:rtl\:pb-96 {
    padding-bottom: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:pl-96,[dir='rtl'].xl\:rtl\:pl-96 {
    padding-left: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:pt-128,[dir='rtl'].xl\:rtl\:pt-128 {
    padding-top: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:pr-128,[dir='rtl'].xl\:rtl\:pr-128 {
    padding-right: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:pb-128,[dir='rtl'].xl\:rtl\:pb-128 {
    padding-bottom: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:pl-128,[dir='rtl'].xl\:rtl\:pl-128 {
    padding-left: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:pt-136,[dir='rtl'].xl\:rtl\:pt-136 {
    padding-top: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:pr-136,[dir='rtl'].xl\:rtl\:pr-136 {
    padding-right: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:pb-136,[dir='rtl'].xl\:rtl\:pb-136 {
    padding-bottom: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:pl-136,[dir='rtl'].xl\:rtl\:pl-136 {
    padding-left: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:pt-160,[dir='rtl'].xl\:rtl\:pt-160 {
    padding-top: 16rem
  }

  [dir='rtl'] .xl\:rtl\:pr-160,[dir='rtl'].xl\:rtl\:pr-160 {
    padding-right: 16rem
  }

  [dir='rtl'] .xl\:rtl\:pb-160,[dir='rtl'].xl\:rtl\:pb-160 {
    padding-bottom: 16rem
  }

  [dir='rtl'] .xl\:rtl\:pl-160,[dir='rtl'].xl\:rtl\:pl-160 {
    padding-left: 16rem
  }

  [dir='rtl'] .xl\:rtl\:pt-192,[dir='rtl'].xl\:rtl\:pt-192 {
    padding-top: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:pr-192,[dir='rtl'].xl\:rtl\:pr-192 {
    padding-right: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:pb-192,[dir='rtl'].xl\:rtl\:pb-192 {
    padding-bottom: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:pl-192,[dir='rtl'].xl\:rtl\:pl-192 {
    padding-left: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:pt-200,[dir='rtl'].xl\:rtl\:pt-200 {
    padding-top: 20rem
  }

  [dir='rtl'] .xl\:rtl\:pr-200,[dir='rtl'].xl\:rtl\:pr-200 {
    padding-right: 20rem
  }

  [dir='rtl'] .xl\:rtl\:pb-200,[dir='rtl'].xl\:rtl\:pb-200 {
    padding-bottom: 20rem
  }

  [dir='rtl'] .xl\:rtl\:pl-200,[dir='rtl'].xl\:rtl\:pl-200 {
    padding-left: 20rem
  }

  [dir='rtl'] .xl\:rtl\:pt-208,[dir='rtl'].xl\:rtl\:pt-208 {
    padding-top: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:pr-208,[dir='rtl'].xl\:rtl\:pr-208 {
    padding-right: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:pb-208,[dir='rtl'].xl\:rtl\:pb-208 {
    padding-bottom: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:pl-208,[dir='rtl'].xl\:rtl\:pl-208 {
    padding-left: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:pt-216,[dir='rtl'].xl\:rtl\:pt-216 {
    padding-top: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:pr-216,[dir='rtl'].xl\:rtl\:pr-216 {
    padding-right: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:pb-216,[dir='rtl'].xl\:rtl\:pb-216 {
    padding-bottom: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:pl-216,[dir='rtl'].xl\:rtl\:pl-216 {
    padding-left: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:pt-224,[dir='rtl'].xl\:rtl\:pt-224 {
    padding-top: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:pr-224,[dir='rtl'].xl\:rtl\:pr-224 {
    padding-right: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:pb-224,[dir='rtl'].xl\:rtl\:pb-224 {
    padding-bottom: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:pl-224,[dir='rtl'].xl\:rtl\:pl-224 {
    padding-left: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:pt-256,[dir='rtl'].xl\:rtl\:pt-256 {
    padding-top: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:pr-256,[dir='rtl'].xl\:rtl\:pr-256 {
    padding-right: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:pb-256,[dir='rtl'].xl\:rtl\:pb-256 {
    padding-bottom: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:pl-256,[dir='rtl'].xl\:rtl\:pl-256 {
    padding-left: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:pt-288,[dir='rtl'].xl\:rtl\:pt-288 {
    padding-top: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:pr-288,[dir='rtl'].xl\:rtl\:pr-288 {
    padding-right: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:pb-288,[dir='rtl'].xl\:rtl\:pb-288 {
    padding-bottom: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:pl-288,[dir='rtl'].xl\:rtl\:pl-288 {
    padding-left: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:pt-320,[dir='rtl'].xl\:rtl\:pt-320 {
    padding-top: 32rem
  }

  [dir='rtl'] .xl\:rtl\:pr-320,[dir='rtl'].xl\:rtl\:pr-320 {
    padding-right: 32rem
  }

  [dir='rtl'] .xl\:rtl\:pb-320,[dir='rtl'].xl\:rtl\:pb-320 {
    padding-bottom: 32rem
  }

  [dir='rtl'] .xl\:rtl\:pl-320,[dir='rtl'].xl\:rtl\:pl-320 {
    padding-left: 32rem
  }

  [dir='rtl'] .xl\:rtl\:pt-360,[dir='rtl'].xl\:rtl\:pt-360 {
    padding-top: 36rem
  }

  [dir='rtl'] .xl\:rtl\:pr-360,[dir='rtl'].xl\:rtl\:pr-360 {
    padding-right: 36rem
  }

  [dir='rtl'] .xl\:rtl\:pb-360,[dir='rtl'].xl\:rtl\:pb-360 {
    padding-bottom: 36rem
  }

  [dir='rtl'] .xl\:rtl\:pl-360,[dir='rtl'].xl\:rtl\:pl-360 {
    padding-left: 36rem
  }

  [dir='rtl'] .xl\:rtl\:pt-384,[dir='rtl'].xl\:rtl\:pt-384 {
    padding-top: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:pr-384,[dir='rtl'].xl\:rtl\:pr-384 {
    padding-right: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:pb-384,[dir='rtl'].xl\:rtl\:pb-384 {
    padding-bottom: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:pl-384,[dir='rtl'].xl\:rtl\:pl-384 {
    padding-left: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:pt-400,[dir='rtl'].xl\:rtl\:pt-400 {
    padding-top: 40rem
  }

  [dir='rtl'] .xl\:rtl\:pr-400,[dir='rtl'].xl\:rtl\:pr-400 {
    padding-right: 40rem
  }

  [dir='rtl'] .xl\:rtl\:pb-400,[dir='rtl'].xl\:rtl\:pb-400 {
    padding-bottom: 40rem
  }

  [dir='rtl'] .xl\:rtl\:pl-400,[dir='rtl'].xl\:rtl\:pl-400 {
    padding-left: 40rem
  }

  [dir='rtl'] .xl\:rtl\:pt-512,[dir='rtl'].xl\:rtl\:pt-512 {
    padding-top: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:pr-512,[dir='rtl'].xl\:rtl\:pr-512 {
    padding-right: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:pb-512,[dir='rtl'].xl\:rtl\:pb-512 {
    padding-bottom: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:pl-512,[dir='rtl'].xl\:rtl\:pl-512 {
    padding-left: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:pt-640,[dir='rtl'].xl\:rtl\:pt-640 {
    padding-top: 64rem
  }

  [dir='rtl'] .xl\:rtl\:pr-640,[dir='rtl'].xl\:rtl\:pr-640 {
    padding-right: 64rem
  }

  [dir='rtl'] .xl\:rtl\:pb-640,[dir='rtl'].xl\:rtl\:pb-640 {
    padding-bottom: 64rem
  }

  [dir='rtl'] .xl\:rtl\:pl-640,[dir='rtl'].xl\:rtl\:pl-640 {
    padding-left: 64rem
  }

  [dir='rtl'] .xl\:rtl\:pt-xs,[dir='rtl'].xl\:rtl\:pt-xs {
    padding-top: 32rem
  }

  [dir='rtl'] .xl\:rtl\:pr-xs,[dir='rtl'].xl\:rtl\:pr-xs {
    padding-right: 32rem
  }

  [dir='rtl'] .xl\:rtl\:pb-xs,[dir='rtl'].xl\:rtl\:pb-xs {
    padding-bottom: 32rem
  }

  [dir='rtl'] .xl\:rtl\:pl-xs,[dir='rtl'].xl\:rtl\:pl-xs {
    padding-left: 32rem
  }

  [dir='rtl'] .xl\:rtl\:pt-sm,[dir='rtl'].xl\:rtl\:pt-sm {
    padding-top: 48rem
  }

  [dir='rtl'] .xl\:rtl\:pr-sm,[dir='rtl'].xl\:rtl\:pr-sm {
    padding-right: 48rem
  }

  [dir='rtl'] .xl\:rtl\:pb-sm,[dir='rtl'].xl\:rtl\:pb-sm {
    padding-bottom: 48rem
  }

  [dir='rtl'] .xl\:rtl\:pl-sm,[dir='rtl'].xl\:rtl\:pl-sm {
    padding-left: 48rem
  }

  [dir='rtl'] .xl\:rtl\:pt-md,[dir='rtl'].xl\:rtl\:pt-md {
    padding-top: 64rem
  }

  [dir='rtl'] .xl\:rtl\:pr-md,[dir='rtl'].xl\:rtl\:pr-md {
    padding-right: 64rem
  }

  [dir='rtl'] .xl\:rtl\:pb-md,[dir='rtl'].xl\:rtl\:pb-md {
    padding-bottom: 64rem
  }

  [dir='rtl'] .xl\:rtl\:pl-md,[dir='rtl'].xl\:rtl\:pl-md {
    padding-left: 64rem
  }

  [dir='rtl'] .xl\:rtl\:pt-lg,[dir='rtl'].xl\:rtl\:pt-lg {
    padding-top: 80rem
  }

  [dir='rtl'] .xl\:rtl\:pr-lg,[dir='rtl'].xl\:rtl\:pr-lg {
    padding-right: 80rem
  }

  [dir='rtl'] .xl\:rtl\:pb-lg,[dir='rtl'].xl\:rtl\:pb-lg {
    padding-bottom: 80rem
  }

  [dir='rtl'] .xl\:rtl\:pl-lg,[dir='rtl'].xl\:rtl\:pl-lg {
    padding-left: 80rem
  }

  [dir='rtl'] .xl\:rtl\:pt-xl,[dir='rtl'].xl\:rtl\:pt-xl {
    padding-top: 96rem
  }

  [dir='rtl'] .xl\:rtl\:pr-xl,[dir='rtl'].xl\:rtl\:pr-xl {
    padding-right: 96rem
  }

  [dir='rtl'] .xl\:rtl\:pb-xl,[dir='rtl'].xl\:rtl\:pb-xl {
    padding-bottom: 96rem
  }

  [dir='rtl'] .xl\:rtl\:pl-xl,[dir='rtl'].xl\:rtl\:pl-xl {
    padding-left: 96rem
  }

  [dir='rtl'] .xl\:rtl\:pt-2xl,[dir='rtl'].xl\:rtl\:pt-2xl {
    padding-top: 112rem
  }

  [dir='rtl'] .xl\:rtl\:pr-2xl,[dir='rtl'].xl\:rtl\:pr-2xl {
    padding-right: 112rem
  }

  [dir='rtl'] .xl\:rtl\:pb-2xl,[dir='rtl'].xl\:rtl\:pb-2xl {
    padding-bottom: 112rem
  }

  [dir='rtl'] .xl\:rtl\:pl-2xl,[dir='rtl'].xl\:rtl\:pl-2xl {
    padding-left: 112rem
  }

  [dir='rtl'] .xl\:rtl\:pt-3xl,[dir='rtl'].xl\:rtl\:pt-3xl {
    padding-top: 128rem
  }

  [dir='rtl'] .xl\:rtl\:pr-3xl,[dir='rtl'].xl\:rtl\:pr-3xl {
    padding-right: 128rem
  }

  [dir='rtl'] .xl\:rtl\:pb-3xl,[dir='rtl'].xl\:rtl\:pb-3xl {
    padding-bottom: 128rem
  }

  [dir='rtl'] .xl\:rtl\:pl-3xl,[dir='rtl'].xl\:rtl\:pl-3xl {
    padding-left: 128rem
  }

  [dir='rtl'] .xl\:rtl\:pt-4xl,[dir='rtl'].xl\:rtl\:pt-4xl {
    padding-top: 144rem
  }

  [dir='rtl'] .xl\:rtl\:pr-4xl,[dir='rtl'].xl\:rtl\:pr-4xl {
    padding-right: 144rem
  }

  [dir='rtl'] .xl\:rtl\:pb-4xl,[dir='rtl'].xl\:rtl\:pb-4xl {
    padding-bottom: 144rem
  }

  [dir='rtl'] .xl\:rtl\:pl-4xl,[dir='rtl'].xl\:rtl\:pl-4xl {
    padding-left: 144rem
  }

  [dir='rtl'] .xl\:rtl\:pt-5xl,[dir='rtl'].xl\:rtl\:pt-5xl {
    padding-top: 160rem
  }

  [dir='rtl'] .xl\:rtl\:pr-5xl,[dir='rtl'].xl\:rtl\:pr-5xl {
    padding-right: 160rem
  }

  [dir='rtl'] .xl\:rtl\:pb-5xl,[dir='rtl'].xl\:rtl\:pb-5xl {
    padding-bottom: 160rem
  }

  [dir='rtl'] .xl\:rtl\:pl-5xl,[dir='rtl'].xl\:rtl\:pl-5xl {
    padding-left: 160rem
  }

  [dir='rtl'] .xl\:rtl\:pt-px,[dir='rtl'].xl\:rtl\:pt-px {
    padding-top: 1px
  }

  [dir='rtl'] .xl\:rtl\:pr-px,[dir='rtl'].xl\:rtl\:pr-px {
    padding-right: 1px
  }

  [dir='rtl'] .xl\:rtl\:pb-px,[dir='rtl'].xl\:rtl\:pb-px {
    padding-bottom: 1px
  }

  [dir='rtl'] .xl\:rtl\:pl-px,[dir='rtl'].xl\:rtl\:pl-px {
    padding-left: 1px
  }

  [dir='rtl'] .xl\:rtl\:pt-0\.5,[dir='rtl'].xl\:rtl\:pt-0\.5 {
    padding-top: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:pr-0\.5,[dir='rtl'].xl\:rtl\:pr-0\.5 {
    padding-right: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:pb-0\.5,[dir='rtl'].xl\:rtl\:pb-0\.5 {
    padding-bottom: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:pl-0\.5,[dir='rtl'].xl\:rtl\:pl-0\.5 {
    padding-left: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:pt-1\.5,[dir='rtl'].xl\:rtl\:pt-1\.5 {
    padding-top: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:pr-1\.5,[dir='rtl'].xl\:rtl\:pr-1\.5 {
    padding-right: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:pb-1\.5,[dir='rtl'].xl\:rtl\:pb-1\.5 {
    padding-bottom: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:pl-1\.5,[dir='rtl'].xl\:rtl\:pl-1\.5 {
    padding-left: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:pt-2\.5,[dir='rtl'].xl\:rtl\:pt-2\.5 {
    padding-top: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:pr-2\.5,[dir='rtl'].xl\:rtl\:pr-2\.5 {
    padding-right: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:pb-2\.5,[dir='rtl'].xl\:rtl\:pb-2\.5 {
    padding-bottom: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:pl-2\.5,[dir='rtl'].xl\:rtl\:pl-2\.5 {
    padding-left: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:pt-3\.5,[dir='rtl'].xl\:rtl\:pt-3\.5 {
    padding-top: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:pr-3\.5,[dir='rtl'].xl\:rtl\:pr-3\.5 {
    padding-right: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:pb-3\.5,[dir='rtl'].xl\:rtl\:pb-3\.5 {
    padding-bottom: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:pl-3\.5,[dir='rtl'].xl\:rtl\:pl-3\.5 {
    padding-left: 0.35rem
  }

  .xl\:placeholder-transparent::placeholder {
    color: transparent
  }

  .xl\:placeholder-current::placeholder {
    color: currentColor
  }

  .xl\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-grey-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-grey-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-grey-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-grey-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-grey-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-grey-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-grey-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-grey-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-grey-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-grey-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-grey-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-grey-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-grey-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-grey-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-gray-hover::placeholder {
    color: rgba(0, 0, 0, 0.04)
  }

  .xl\:placeholder-gray-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-gray-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-gray-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-gray-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-red::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-red-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-red-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-red-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-red-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-orange-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-orange-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-orange-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-orange-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-orange-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-orange-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-orange-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-orange-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-orange-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-orange-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-orange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-orange-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-orange-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-orange-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-orange-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-orange-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-orange-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-orange-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-orange-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-orange-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-orange-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-orange-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-orange-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-orange-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-orange-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-orange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-orange-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-orange-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-orange-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-orange-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-yellow::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-yellow-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-yellow-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-yellow-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-yellow-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-green-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-green-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-green-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-green-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-green-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-green-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-green-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-green-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-teal-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-teal-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-teal-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-teal-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-teal-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-teal-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-teal-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-teal-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-teal-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-teal-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-teal::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-teal-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-teal-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-teal-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-teal-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-blue-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-blue-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-blue-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-light-blue-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-indigo::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-indigo-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-indigo-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-indigo-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-indigo-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-purple::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-purple-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-purple-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-purple-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-purple-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-purple::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-purple-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-purple-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-purple-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-deep-purple-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-pink::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-pink-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-pink-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-pink-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-pink-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-lime-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-lime-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-lime-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-lime-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-lime-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-lime-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-lime-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-lime-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-lime-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-lime-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-lime::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-lime-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-lime-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-lime-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-lime-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-amber-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-amber-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-amber-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-amber-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-amber-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-amber-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-amber-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-amber-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-amber-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-amber-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-amber::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-amber-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-amber-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-amber-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-amber-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-brown-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-brown-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-brown-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-brown-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-brown-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-brown-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-brown-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-brown-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-brown-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-brown-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-brown::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-brown-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-brown-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-brown-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-brown-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-gray-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-gray-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-gray-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-blue-gray-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-cyan-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-cyan-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-cyan-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-cyan-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-cyan-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-cyan-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-cyan-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-cyan-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-cyan-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-cyan-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-cyan::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-cyan-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-cyan-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-cyan-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-cyan-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent
  }

  .xl\:focus\:placeholder-current:focus::placeholder {
    color: currentColor
  }

  .xl\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-grey-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-grey-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-grey-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-grey-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-grey-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-grey-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-grey-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-grey-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-grey-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-grey-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-grey-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-grey-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-grey-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-grey-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-hover:focus::placeholder {
    color: rgba(0, 0, 0, 0.04)
  }

  .xl\:focus\:placeholder-gray-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-red:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-orange-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-orange-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-orange-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-orange-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-orange-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-orange-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-orange-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-orange-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-orange-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-orange-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-orange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-orange-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-orange-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-orange-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-orange-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-green-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-green-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-green-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-green-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-blue-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-blue-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-blue-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-light-blue-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-purple:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-purple-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-purple-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-purple-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-deep-purple-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-lime-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-lime-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-lime-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-lime-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-lime-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-lime-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-lime-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-lime-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-lime-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-lime-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-lime:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-lime-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-lime-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-lime-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-lime-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-amber-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-amber-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-amber-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-amber-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-amber-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-amber-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-amber-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-amber-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-amber-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-amber-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-amber:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-amber-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-amber-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-amber-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-amber-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-brown-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-brown-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-brown-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-brown-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-brown-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-brown-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-brown-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-brown-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-brown-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-brown-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-brown:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-brown-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-brown-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-brown-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-brown-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-gray-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-gray-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-gray-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-gray-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-cyan-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-cyan-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-cyan-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-cyan-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-cyan-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-cyan-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-cyan-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-cyan-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-cyan-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-cyan-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-cyan:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-cyan-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-cyan-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-cyan-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
  }

  .xl\:focus\:placeholder-cyan-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
  }

  .xl\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0
  }

  .xl\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05
  }

  .xl\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1
  }

  .xl\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2
  }

  .xl\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25
  }

  .xl\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3
  }

  .xl\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4
  }

  .xl\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5
  }

  .xl\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6
  }

  .xl\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7
  }

  .xl\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75
  }

  .xl\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8
  }

  .xl\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9
  }

  .xl\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95
  }

  .xl\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1
  }

  .xl\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0
  }

  .xl\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05
  }

  .xl\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1
  }

  .xl\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2
  }

  .xl\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25
  }

  .xl\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3
  }

  .xl\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4
  }

  .xl\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5
  }

  .xl\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6
  }

  .xl\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7
  }

  .xl\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75
  }

  .xl\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8
  }

  .xl\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9
  }

  .xl\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95
  }

  .xl\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1
  }

  .xl\:pointer-events-none {
    pointer-events: none
  }

  .xl\:pointer-events-auto {
    pointer-events: auto
  }

  .xl\:static {
    position: static
  }

  .xl\:fixed {
    position: fixed
  }

  .xl\:absolute {
    position: absolute
  }

  .xl\:relative {
    position: relative
  }

  .xl\:sticky {
    position: sticky
  }

  .xl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .xl\:inset-1 {
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem
  }

  .xl\:inset-2 {
    top: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem
  }

  .xl\:inset-3 {
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem
  }

  .xl\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  .xl\:inset-5 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  .xl\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  .xl\:inset-7 {
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
    left: 0.7rem
  }

  .xl\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  .xl\:inset-9 {
    top: 0.9rem;
    right: 0.9rem;
    bottom: 0.9rem;
    left: 0.9rem
  }

  .xl\:inset-10 {
    top: 1.0rem;
    right: 1.0rem;
    bottom: 1.0rem;
    left: 1.0rem
  }

  .xl\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  .xl\:inset-14 {
    top: 1.4rem;
    right: 1.4rem;
    bottom: 1.4rem;
    left: 1.4rem
  }

  .xl\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  .xl\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  .xl\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  .xl\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  .xl\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  .xl\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  .xl\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  .xl\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  .xl\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  .xl\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  .xl\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  .xl\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  .xl\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  .xl\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  .xl\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  .xl\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  .xl\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  .xl\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  .xl\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  .xl\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  .xl\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  .xl\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  .xl\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  .xl\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  .xl\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  .xl\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  .xl\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  .xl\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  .xl\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  .xl\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  .xl\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  .xl\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  .xl\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  .xl\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  .xl\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  .xl\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  .xl\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  .xl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .xl\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  .xl\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  .xl\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  .xl\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  .xl\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  .xl\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  .xl\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  .xl\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  .xl\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  .xl\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  .xl\:inset-0\.5 {
    top: 0.05rem;
    right: 0.05rem;
    bottom: 0.05rem;
    left: 0.05rem
  }

  .xl\:inset-1\.5 {
    top: 0.15rem;
    right: 0.15rem;
    bottom: 0.15rem;
    left: 0.15rem
  }

  .xl\:inset-2\.5 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  .xl\:inset-3\.5 {
    top: 0.35rem;
    right: 0.35rem;
    bottom: 0.35rem;
    left: 0.35rem
  }

  .xl\:-inset-1 {
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem
  }

  .xl\:-inset-2 {
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem
  }

  .xl\:-inset-3 {
    top: -0.3rem;
    right: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem
  }

  .xl\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  .xl\:-inset-5 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  .xl\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  .xl\:-inset-7 {
    top: -0.7rem;
    right: -0.7rem;
    bottom: -0.7rem;
    left: -0.7rem
  }

  .xl\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  .xl\:-inset-9 {
    top: -0.9rem;
    right: -0.9rem;
    bottom: -0.9rem;
    left: -0.9rem
  }

  .xl\:-inset-10 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  .xl\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  .xl\:-inset-14 {
    top: -1.4rem;
    right: -1.4rem;
    bottom: -1.4rem;
    left: -1.4rem
  }

  .xl\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  .xl\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  .xl\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  .xl\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  .xl\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  .xl\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  .xl\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  .xl\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  .xl\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  .xl\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  .xl\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  .xl\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  .xl\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  .xl\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  .xl\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  .xl\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  .xl\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  .xl\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  .xl\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  .xl\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  .xl\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  .xl\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  .xl\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  .xl\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  .xl\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  .xl\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  .xl\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  .xl\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  .xl\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  .xl\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  .xl\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  .xl\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  .xl\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  .xl\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  .xl\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  .xl\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  .xl\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  .xl\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  .xl\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  .xl\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  .xl\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  .xl\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  .xl\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  .xl\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  .xl\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  .xl\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  .xl\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  .xl\:-inset-0\.5 {
    top: -0.05rem;
    right: -0.05rem;
    bottom: -0.05rem;
    left: -0.05rem
  }

  .xl\:-inset-1\.5 {
    top: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    left: -0.15rem
  }

  .xl\:-inset-2\.5 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  .xl\:-inset-3\.5 {
    top: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    left: -0.35rem
  }

  .xl\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  .xl\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  .xl\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  .xl\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  .xl\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  .xl\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  .xl\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  .xl\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  .xl\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  .xl\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  .xl\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  .xl\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  .xl\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  .xl\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  .xl\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .xl\:inset-x-0 {
    right: 0;
    left: 0
  }

  .xl\:inset-y-1 {
    top: 0.1rem;
    bottom: 0.1rem
  }

  .xl\:inset-x-1 {
    right: 0.1rem;
    left: 0.1rem
  }

  .xl\:inset-y-2 {
    top: 0.2rem;
    bottom: 0.2rem
  }

  .xl\:inset-x-2 {
    right: 0.2rem;
    left: 0.2rem
  }

  .xl\:inset-y-3 {
    top: 0.3rem;
    bottom: 0.3rem
  }

  .xl\:inset-x-3 {
    right: 0.3rem;
    left: 0.3rem
  }

  .xl\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  .xl\:inset-x-4 {
    right: 0.4rem;
    left: 0.4rem
  }

  .xl\:inset-y-5 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  .xl\:inset-x-5 {
    right: 0.5rem;
    left: 0.5rem
  }

  .xl\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  .xl\:inset-x-6 {
    right: 0.6rem;
    left: 0.6rem
  }

  .xl\:inset-y-7 {
    top: 0.7rem;
    bottom: 0.7rem
  }

  .xl\:inset-x-7 {
    right: 0.7rem;
    left: 0.7rem
  }

  .xl\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  .xl\:inset-x-8 {
    right: 0.8rem;
    left: 0.8rem
  }

  .xl\:inset-y-9 {
    top: 0.9rem;
    bottom: 0.9rem
  }

  .xl\:inset-x-9 {
    right: 0.9rem;
    left: 0.9rem
  }

  .xl\:inset-y-10 {
    top: 1.0rem;
    bottom: 1.0rem
  }

  .xl\:inset-x-10 {
    right: 1.0rem;
    left: 1.0rem
  }

  .xl\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  .xl\:inset-x-12 {
    right: 1.2rem;
    left: 1.2rem
  }

  .xl\:inset-y-14 {
    top: 1.4rem;
    bottom: 1.4rem
  }

  .xl\:inset-x-14 {
    right: 1.4rem;
    left: 1.4rem
  }

  .xl\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  .xl\:inset-x-16 {
    right: 1.6rem;
    left: 1.6rem
  }

  .xl\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  .xl\:inset-x-20 {
    right: 2rem;
    left: 2rem
  }

  .xl\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  .xl\:inset-x-24 {
    right: 2.4rem;
    left: 2.4rem
  }

  .xl\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  .xl\:inset-x-28 {
    right: 2.8rem;
    left: 2.8rem
  }

  .xl\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  .xl\:inset-x-32 {
    right: 3.2rem;
    left: 3.2rem
  }

  .xl\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  .xl\:inset-x-36 {
    right: 3.6rem;
    left: 3.6rem
  }

  .xl\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  .xl\:inset-x-40 {
    right: 4rem;
    left: 4rem
  }

  .xl\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  .xl\:inset-x-44 {
    right: 4.4rem;
    left: 4.4rem
  }

  .xl\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  .xl\:inset-x-48 {
    right: 4.8rem;
    left: 4.8rem
  }

  .xl\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  .xl\:inset-x-52 {
    right: 5.2rem;
    left: 5.2rem
  }

  .xl\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  .xl\:inset-x-56 {
    right: 5.6rem;
    left: 5.6rem
  }

  .xl\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  .xl\:inset-x-60 {
    right: 6rem;
    left: 6rem
  }

  .xl\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  .xl\:inset-x-64 {
    right: 6.4rem;
    left: 6.4rem
  }

  .xl\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  .xl\:inset-x-68 {
    right: 6.8rem;
    left: 6.8rem
  }

  .xl\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  .xl\:inset-x-72 {
    right: 7.2rem;
    left: 7.2rem
  }

  .xl\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  .xl\:inset-x-76 {
    right: 7.6rem;
    left: 7.6rem
  }

  .xl\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  .xl\:inset-x-80 {
    right: 8rem;
    left: 8rem
  }

  .xl\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  .xl\:inset-x-84 {
    right: 8.4rem;
    left: 8.4rem
  }

  .xl\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  .xl\:inset-x-88 {
    right: 8.8rem;
    left: 8.8rem
  }

  .xl\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  .xl\:inset-x-92 {
    right: 9.2rem;
    left: 9.2rem
  }

  .xl\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  .xl\:inset-x-96 {
    right: 9.6rem;
    left: 9.6rem
  }

  .xl\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  .xl\:inset-x-128 {
    right: 12.8rem;
    left: 12.8rem
  }

  .xl\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  .xl\:inset-x-136 {
    right: 13.6rem;
    left: 13.6rem
  }

  .xl\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  .xl\:inset-x-160 {
    right: 16rem;
    left: 16rem
  }

  .xl\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  .xl\:inset-x-192 {
    right: 19.2rem;
    left: 19.2rem
  }

  .xl\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  .xl\:inset-x-200 {
    right: 20rem;
    left: 20rem
  }

  .xl\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  .xl\:inset-x-208 {
    right: 20.8rem;
    left: 20.8rem
  }

  .xl\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  .xl\:inset-x-216 {
    right: 21.6rem;
    left: 21.6rem
  }

  .xl\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  .xl\:inset-x-224 {
    right: 22.4rem;
    left: 22.4rem
  }

  .xl\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  .xl\:inset-x-256 {
    right: 25.6rem;
    left: 25.6rem
  }

  .xl\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  .xl\:inset-x-288 {
    right: 28.8rem;
    left: 28.8rem
  }

  .xl\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  .xl\:inset-x-320 {
    right: 32rem;
    left: 32rem
  }

  .xl\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  .xl\:inset-x-360 {
    right: 36rem;
    left: 36rem
  }

  .xl\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  .xl\:inset-x-384 {
    right: 38.4rem;
    left: 38.4rem
  }

  .xl\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  .xl\:inset-x-400 {
    right: 40rem;
    left: 40rem
  }

  .xl\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  .xl\:inset-x-512 {
    right: 51.2rem;
    left: 51.2rem
  }

  .xl\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  .xl\:inset-x-640 {
    right: 64rem;
    left: 64rem
  }

  .xl\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .xl\:inset-x-auto {
    right: auto;
    left: auto
  }

  .xl\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  .xl\:inset-x-xs {
    right: 32rem;
    left: 32rem
  }

  .xl\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  .xl\:inset-x-sm {
    right: 48rem;
    left: 48rem
  }

  .xl\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  .xl\:inset-x-md {
    right: 64rem;
    left: 64rem
  }

  .xl\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  .xl\:inset-x-lg {
    right: 80rem;
    left: 80rem
  }

  .xl\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  .xl\:inset-x-xl {
    right: 96rem;
    left: 96rem
  }

  .xl\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  .xl\:inset-x-2xl {
    right: 112rem;
    left: 112rem
  }

  .xl\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  .xl\:inset-x-3xl {
    right: 128rem;
    left: 128rem
  }

  .xl\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  .xl\:inset-x-4xl {
    right: 144rem;
    left: 144rem
  }

  .xl\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  .xl\:inset-x-5xl {
    right: 160rem;
    left: 160rem
  }

  .xl\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  .xl\:inset-x-px {
    right: 1px;
    left: 1px
  }

  .xl\:inset-y-0\.5 {
    top: 0.05rem;
    bottom: 0.05rem
  }

  .xl\:inset-x-0\.5 {
    right: 0.05rem;
    left: 0.05rem
  }

  .xl\:inset-y-1\.5 {
    top: 0.15rem;
    bottom: 0.15rem
  }

  .xl\:inset-x-1\.5 {
    right: 0.15rem;
    left: 0.15rem
  }

  .xl\:inset-y-2\.5 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  .xl\:inset-x-2\.5 {
    right: 0.25rem;
    left: 0.25rem
  }

  .xl\:inset-y-3\.5 {
    top: 0.35rem;
    bottom: 0.35rem
  }

  .xl\:inset-x-3\.5 {
    right: 0.35rem;
    left: 0.35rem
  }

  .xl\:-inset-y-1 {
    top: -0.1rem;
    bottom: -0.1rem
  }

  .xl\:-inset-x-1 {
    right: -0.1rem;
    left: -0.1rem
  }

  .xl\:-inset-y-2 {
    top: -0.2rem;
    bottom: -0.2rem
  }

  .xl\:-inset-x-2 {
    right: -0.2rem;
    left: -0.2rem
  }

  .xl\:-inset-y-3 {
    top: -0.3rem;
    bottom: -0.3rem
  }

  .xl\:-inset-x-3 {
    right: -0.3rem;
    left: -0.3rem
  }

  .xl\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  .xl\:-inset-x-4 {
    right: -0.4rem;
    left: -0.4rem
  }

  .xl\:-inset-y-5 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  .xl\:-inset-x-5 {
    right: -0.5rem;
    left: -0.5rem
  }

  .xl\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  .xl\:-inset-x-6 {
    right: -0.6rem;
    left: -0.6rem
  }

  .xl\:-inset-y-7 {
    top: -0.7rem;
    bottom: -0.7rem
  }

  .xl\:-inset-x-7 {
    right: -0.7rem;
    left: -0.7rem
  }

  .xl\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  .xl\:-inset-x-8 {
    right: -0.8rem;
    left: -0.8rem
  }

  .xl\:-inset-y-9 {
    top: -0.9rem;
    bottom: -0.9rem
  }

  .xl\:-inset-x-9 {
    right: -0.9rem;
    left: -0.9rem
  }

  .xl\:-inset-y-10 {
    top: -1rem;
    bottom: -1rem
  }

  .xl\:-inset-x-10 {
    right: -1rem;
    left: -1rem
  }

  .xl\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  .xl\:-inset-x-12 {
    right: -1.2rem;
    left: -1.2rem
  }

  .xl\:-inset-y-14 {
    top: -1.4rem;
    bottom: -1.4rem
  }

  .xl\:-inset-x-14 {
    right: -1.4rem;
    left: -1.4rem
  }

  .xl\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  .xl\:-inset-x-16 {
    right: -1.6rem;
    left: -1.6rem
  }

  .xl\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  .xl\:-inset-x-20 {
    right: -2rem;
    left: -2rem
  }

  .xl\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  .xl\:-inset-x-24 {
    right: -2.4rem;
    left: -2.4rem
  }

  .xl\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  .xl\:-inset-x-28 {
    right: -2.8rem;
    left: -2.8rem
  }

  .xl\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  .xl\:-inset-x-32 {
    right: -3.2rem;
    left: -3.2rem
  }

  .xl\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  .xl\:-inset-x-36 {
    right: -3.6rem;
    left: -3.6rem
  }

  .xl\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  .xl\:-inset-x-40 {
    right: -4rem;
    left: -4rem
  }

  .xl\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  .xl\:-inset-x-44 {
    right: -4.4rem;
    left: -4.4rem
  }

  .xl\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  .xl\:-inset-x-48 {
    right: -4.8rem;
    left: -4.8rem
  }

  .xl\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  .xl\:-inset-x-52 {
    right: -5.2rem;
    left: -5.2rem
  }

  .xl\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  .xl\:-inset-x-56 {
    right: -5.6rem;
    left: -5.6rem
  }

  .xl\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  .xl\:-inset-x-60 {
    right: -6rem;
    left: -6rem
  }

  .xl\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  .xl\:-inset-x-64 {
    right: -6.4rem;
    left: -6.4rem
  }

  .xl\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  .xl\:-inset-x-68 {
    right: -6.8rem;
    left: -6.8rem
  }

  .xl\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  .xl\:-inset-x-72 {
    right: -7.2rem;
    left: -7.2rem
  }

  .xl\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  .xl\:-inset-x-76 {
    right: -7.6rem;
    left: -7.6rem
  }

  .xl\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  .xl\:-inset-x-80 {
    right: -8rem;
    left: -8rem
  }

  .xl\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  .xl\:-inset-x-84 {
    right: -8.4rem;
    left: -8.4rem
  }

  .xl\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  .xl\:-inset-x-88 {
    right: -8.8rem;
    left: -8.8rem
  }

  .xl\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  .xl\:-inset-x-92 {
    right: -9.2rem;
    left: -9.2rem
  }

  .xl\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  .xl\:-inset-x-96 {
    right: -9.6rem;
    left: -9.6rem
  }

  .xl\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  .xl\:-inset-x-128 {
    right: -12.8rem;
    left: -12.8rem
  }

  .xl\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  .xl\:-inset-x-136 {
    right: -13.6rem;
    left: -13.6rem
  }

  .xl\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  .xl\:-inset-x-160 {
    right: -16rem;
    left: -16rem
  }

  .xl\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  .xl\:-inset-x-192 {
    right: -19.2rem;
    left: -19.2rem
  }

  .xl\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  .xl\:-inset-x-200 {
    right: -20rem;
    left: -20rem
  }

  .xl\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  .xl\:-inset-x-208 {
    right: -20.8rem;
    left: -20.8rem
  }

  .xl\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  .xl\:-inset-x-216 {
    right: -21.6rem;
    left: -21.6rem
  }

  .xl\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  .xl\:-inset-x-224 {
    right: -22.4rem;
    left: -22.4rem
  }

  .xl\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  .xl\:-inset-x-256 {
    right: -25.6rem;
    left: -25.6rem
  }

  .xl\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  .xl\:-inset-x-288 {
    right: -28.8rem;
    left: -28.8rem
  }

  .xl\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  .xl\:-inset-x-320 {
    right: -32rem;
    left: -32rem
  }

  .xl\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  .xl\:-inset-x-360 {
    right: -36rem;
    left: -36rem
  }

  .xl\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  .xl\:-inset-x-384 {
    right: -38.4rem;
    left: -38.4rem
  }

  .xl\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  .xl\:-inset-x-400 {
    right: -40rem;
    left: -40rem
  }

  .xl\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  .xl\:-inset-x-512 {
    right: -51.2rem;
    left: -51.2rem
  }

  .xl\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  .xl\:-inset-x-640 {
    right: -64rem;
    left: -64rem
  }

  .xl\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  .xl\:-inset-x-xs {
    right: -32rem;
    left: -32rem
  }

  .xl\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  .xl\:-inset-x-sm {
    right: -48rem;
    left: -48rem
  }

  .xl\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  .xl\:-inset-x-md {
    right: -64rem;
    left: -64rem
  }

  .xl\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  .xl\:-inset-x-lg {
    right: -80rem;
    left: -80rem
  }

  .xl\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  .xl\:-inset-x-xl {
    right: -96rem;
    left: -96rem
  }

  .xl\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  .xl\:-inset-x-2xl {
    right: -112rem;
    left: -112rem
  }

  .xl\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  .xl\:-inset-x-3xl {
    right: -128rem;
    left: -128rem
  }

  .xl\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  .xl\:-inset-x-4xl {
    right: -144rem;
    left: -144rem
  }

  .xl\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  .xl\:-inset-x-5xl {
    right: -160rem;
    left: -160rem
  }

  .xl\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  .xl\:-inset-x-px {
    right: -1px;
    left: -1px
  }

  .xl\:-inset-y-0\.5 {
    top: -0.05rem;
    bottom: -0.05rem
  }

  .xl\:-inset-x-0\.5 {
    right: -0.05rem;
    left: -0.05rem
  }

  .xl\:-inset-y-1\.5 {
    top: -0.15rem;
    bottom: -0.15rem
  }

  .xl\:-inset-x-1\.5 {
    right: -0.15rem;
    left: -0.15rem
  }

  .xl\:-inset-y-2\.5 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  .xl\:-inset-x-2\.5 {
    right: -0.25rem;
    left: -0.25rem
  }

  .xl\:-inset-y-3\.5 {
    top: -0.35rem;
    bottom: -0.35rem
  }

  .xl\:-inset-x-3\.5 {
    right: -0.35rem;
    left: -0.35rem
  }

  .xl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  .xl\:inset-x-1\/2 {
    right: 50%;
    left: 50%
  }

  .xl\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  .xl\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%
  }

  .xl\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  .xl\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%
  }

  .xl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  .xl\:inset-x-1\/4 {
    right: 25%;
    left: 25%
  }

  .xl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  .xl\:inset-x-2\/4 {
    right: 50%;
    left: 50%
  }

  .xl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  .xl\:inset-x-3\/4 {
    right: 75%;
    left: 75%
  }

  .xl\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  .xl\:inset-x-full {
    right: 100%;
    left: 100%
  }

  .xl\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  .xl\:-inset-x-1\/2 {
    right: -50%;
    left: -50%
  }

  .xl\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  .xl\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%
  }

  .xl\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  .xl\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%
  }

  .xl\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  .xl\:-inset-x-1\/4 {
    right: -25%;
    left: -25%
  }

  .xl\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  .xl\:-inset-x-2\/4 {
    right: -50%;
    left: -50%
  }

  .xl\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  .xl\:-inset-x-3\/4 {
    right: -75%;
    left: -75%
  }

  .xl\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  .xl\:-inset-x-full {
    right: -100%;
    left: -100%
  }

  .xl\:top-0 {
    top: 0
  }

  .xl\:right-0 {
    right: 0
  }

  .xl\:bottom-0 {
    bottom: 0
  }

  .xl\:left-0 {
    left: 0
  }

  .xl\:top-1 {
    top: 0.1rem
  }

  .xl\:right-1 {
    right: 0.1rem
  }

  .xl\:bottom-1 {
    bottom: 0.1rem
  }

  .xl\:left-1 {
    left: 0.1rem
  }

  .xl\:top-2 {
    top: 0.2rem
  }

  .xl\:right-2 {
    right: 0.2rem
  }

  .xl\:bottom-2 {
    bottom: 0.2rem
  }

  .xl\:left-2 {
    left: 0.2rem
  }

  .xl\:top-3 {
    top: 0.3rem
  }

  .xl\:right-3 {
    right: 0.3rem
  }

  .xl\:bottom-3 {
    bottom: 0.3rem
  }

  .xl\:left-3 {
    left: 0.3rem
  }

  .xl\:top-4 {
    top: 0.4rem
  }

  .xl\:right-4 {
    right: 0.4rem
  }

  .xl\:bottom-4 {
    bottom: 0.4rem
  }

  .xl\:left-4 {
    left: 0.4rem
  }

  .xl\:top-5 {
    top: 0.5rem
  }

  .xl\:right-5 {
    right: 0.5rem
  }

  .xl\:bottom-5 {
    bottom: 0.5rem
  }

  .xl\:left-5 {
    left: 0.5rem
  }

  .xl\:top-6 {
    top: 0.6rem
  }

  .xl\:right-6 {
    right: 0.6rem
  }

  .xl\:bottom-6 {
    bottom: 0.6rem
  }

  .xl\:left-6 {
    left: 0.6rem
  }

  .xl\:top-7 {
    top: 0.7rem
  }

  .xl\:right-7 {
    right: 0.7rem
  }

  .xl\:bottom-7 {
    bottom: 0.7rem
  }

  .xl\:left-7 {
    left: 0.7rem
  }

  .xl\:top-8 {
    top: 0.8rem
  }

  .xl\:right-8 {
    right: 0.8rem
  }

  .xl\:bottom-8 {
    bottom: 0.8rem
  }

  .xl\:left-8 {
    left: 0.8rem
  }

  .xl\:top-9 {
    top: 0.9rem
  }

  .xl\:right-9 {
    right: 0.9rem
  }

  .xl\:bottom-9 {
    bottom: 0.9rem
  }

  .xl\:left-9 {
    left: 0.9rem
  }

  .xl\:top-10 {
    top: 1.0rem
  }

  .xl\:right-10 {
    right: 1.0rem
  }

  .xl\:bottom-10 {
    bottom: 1.0rem
  }

  .xl\:left-10 {
    left: 1.0rem
  }

  .xl\:top-12 {
    top: 1.2rem
  }

  .xl\:right-12 {
    right: 1.2rem
  }

  .xl\:bottom-12 {
    bottom: 1.2rem
  }

  .xl\:left-12 {
    left: 1.2rem
  }

  .xl\:top-14 {
    top: 1.4rem
  }

  .xl\:right-14 {
    right: 1.4rem
  }

  .xl\:bottom-14 {
    bottom: 1.4rem
  }

  .xl\:left-14 {
    left: 1.4rem
  }

  .xl\:top-16 {
    top: 1.6rem
  }

  .xl\:right-16 {
    right: 1.6rem
  }

  .xl\:bottom-16 {
    bottom: 1.6rem
  }

  .xl\:left-16 {
    left: 1.6rem
  }

  .xl\:top-20 {
    top: 2rem
  }

  .xl\:right-20 {
    right: 2rem
  }

  .xl\:bottom-20 {
    bottom: 2rem
  }

  .xl\:left-20 {
    left: 2rem
  }

  .xl\:top-24 {
    top: 2.4rem
  }

  .xl\:right-24 {
    right: 2.4rem
  }

  .xl\:bottom-24 {
    bottom: 2.4rem
  }

  .xl\:left-24 {
    left: 2.4rem
  }

  .xl\:top-28 {
    top: 2.8rem
  }

  .xl\:right-28 {
    right: 2.8rem
  }

  .xl\:bottom-28 {
    bottom: 2.8rem
  }

  .xl\:left-28 {
    left: 2.8rem
  }

  .xl\:top-32 {
    top: 3.2rem
  }

  .xl\:right-32 {
    right: 3.2rem
  }

  .xl\:bottom-32 {
    bottom: 3.2rem
  }

  .xl\:left-32 {
    left: 3.2rem
  }

  .xl\:top-36 {
    top: 3.6rem
  }

  .xl\:right-36 {
    right: 3.6rem
  }

  .xl\:bottom-36 {
    bottom: 3.6rem
  }

  .xl\:left-36 {
    left: 3.6rem
  }

  .xl\:top-40 {
    top: 4rem
  }

  .xl\:right-40 {
    right: 4rem
  }

  .xl\:bottom-40 {
    bottom: 4rem
  }

  .xl\:left-40 {
    left: 4rem
  }

  .xl\:top-44 {
    top: 4.4rem
  }

  .xl\:right-44 {
    right: 4.4rem
  }

  .xl\:bottom-44 {
    bottom: 4.4rem
  }

  .xl\:left-44 {
    left: 4.4rem
  }

  .xl\:top-48 {
    top: 4.8rem
  }

  .xl\:right-48 {
    right: 4.8rem
  }

  .xl\:bottom-48 {
    bottom: 4.8rem
  }

  .xl\:left-48 {
    left: 4.8rem
  }

  .xl\:top-52 {
    top: 5.2rem
  }

  .xl\:right-52 {
    right: 5.2rem
  }

  .xl\:bottom-52 {
    bottom: 5.2rem
  }

  .xl\:left-52 {
    left: 5.2rem
  }

  .xl\:top-56 {
    top: 5.6rem
  }

  .xl\:right-56 {
    right: 5.6rem
  }

  .xl\:bottom-56 {
    bottom: 5.6rem
  }

  .xl\:left-56 {
    left: 5.6rem
  }

  .xl\:top-60 {
    top: 6rem
  }

  .xl\:right-60 {
    right: 6rem
  }

  .xl\:bottom-60 {
    bottom: 6rem
  }

  .xl\:left-60 {
    left: 6rem
  }

  .xl\:top-64 {
    top: 6.4rem
  }

  .xl\:right-64 {
    right: 6.4rem
  }

  .xl\:bottom-64 {
    bottom: 6.4rem
  }

  .xl\:left-64 {
    left: 6.4rem
  }

  .xl\:top-68 {
    top: 6.8rem
  }

  .xl\:right-68 {
    right: 6.8rem
  }

  .xl\:bottom-68 {
    bottom: 6.8rem
  }

  .xl\:left-68 {
    left: 6.8rem
  }

  .xl\:top-72 {
    top: 7.2rem
  }

  .xl\:right-72 {
    right: 7.2rem
  }

  .xl\:bottom-72 {
    bottom: 7.2rem
  }

  .xl\:left-72 {
    left: 7.2rem
  }

  .xl\:top-76 {
    top: 7.6rem
  }

  .xl\:right-76 {
    right: 7.6rem
  }

  .xl\:bottom-76 {
    bottom: 7.6rem
  }

  .xl\:left-76 {
    left: 7.6rem
  }

  .xl\:top-80 {
    top: 8rem
  }

  .xl\:right-80 {
    right: 8rem
  }

  .xl\:bottom-80 {
    bottom: 8rem
  }

  .xl\:left-80 {
    left: 8rem
  }

  .xl\:top-84 {
    top: 8.4rem
  }

  .xl\:right-84 {
    right: 8.4rem
  }

  .xl\:bottom-84 {
    bottom: 8.4rem
  }

  .xl\:left-84 {
    left: 8.4rem
  }

  .xl\:top-88 {
    top: 8.8rem
  }

  .xl\:right-88 {
    right: 8.8rem
  }

  .xl\:bottom-88 {
    bottom: 8.8rem
  }

  .xl\:left-88 {
    left: 8.8rem
  }

  .xl\:top-92 {
    top: 9.2rem
  }

  .xl\:right-92 {
    right: 9.2rem
  }

  .xl\:bottom-92 {
    bottom: 9.2rem
  }

  .xl\:left-92 {
    left: 9.2rem
  }

  .xl\:top-96 {
    top: 9.6rem
  }

  .xl\:right-96 {
    right: 9.6rem
  }

  .xl\:bottom-96 {
    bottom: 9.6rem
  }

  .xl\:left-96 {
    left: 9.6rem
  }

  .xl\:top-128 {
    top: 12.8rem
  }

  .xl\:right-128 {
    right: 12.8rem
  }

  .xl\:bottom-128 {
    bottom: 12.8rem
  }

  .xl\:left-128 {
    left: 12.8rem
  }

  .xl\:top-136 {
    top: 13.6rem
  }

  .xl\:right-136 {
    right: 13.6rem
  }

  .xl\:bottom-136 {
    bottom: 13.6rem
  }

  .xl\:left-136 {
    left: 13.6rem
  }

  .xl\:top-160 {
    top: 16rem
  }

  .xl\:right-160 {
    right: 16rem
  }

  .xl\:bottom-160 {
    bottom: 16rem
  }

  .xl\:left-160 {
    left: 16rem
  }

  .xl\:top-192 {
    top: 19.2rem
  }

  .xl\:right-192 {
    right: 19.2rem
  }

  .xl\:bottom-192 {
    bottom: 19.2rem
  }

  .xl\:left-192 {
    left: 19.2rem
  }

  .xl\:top-200 {
    top: 20rem
  }

  .xl\:right-200 {
    right: 20rem
  }

  .xl\:bottom-200 {
    bottom: 20rem
  }

  .xl\:left-200 {
    left: 20rem
  }

  .xl\:top-208 {
    top: 20.8rem
  }

  .xl\:right-208 {
    right: 20.8rem
  }

  .xl\:bottom-208 {
    bottom: 20.8rem
  }

  .xl\:left-208 {
    left: 20.8rem
  }

  .xl\:top-216 {
    top: 21.6rem
  }

  .xl\:right-216 {
    right: 21.6rem
  }

  .xl\:bottom-216 {
    bottom: 21.6rem
  }

  .xl\:left-216 {
    left: 21.6rem
  }

  .xl\:top-224 {
    top: 22.4rem
  }

  .xl\:right-224 {
    right: 22.4rem
  }

  .xl\:bottom-224 {
    bottom: 22.4rem
  }

  .xl\:left-224 {
    left: 22.4rem
  }

  .xl\:top-256 {
    top: 25.6rem
  }

  .xl\:right-256 {
    right: 25.6rem
  }

  .xl\:bottom-256 {
    bottom: 25.6rem
  }

  .xl\:left-256 {
    left: 25.6rem
  }

  .xl\:top-288 {
    top: 28.8rem
  }

  .xl\:right-288 {
    right: 28.8rem
  }

  .xl\:bottom-288 {
    bottom: 28.8rem
  }

  .xl\:left-288 {
    left: 28.8rem
  }

  .xl\:top-320 {
    top: 32rem
  }

  .xl\:right-320 {
    right: 32rem
  }

  .xl\:bottom-320 {
    bottom: 32rem
  }

  .xl\:left-320 {
    left: 32rem
  }

  .xl\:top-360 {
    top: 36rem
  }

  .xl\:right-360 {
    right: 36rem
  }

  .xl\:bottom-360 {
    bottom: 36rem
  }

  .xl\:left-360 {
    left: 36rem
  }

  .xl\:top-384 {
    top: 38.4rem
  }

  .xl\:right-384 {
    right: 38.4rem
  }

  .xl\:bottom-384 {
    bottom: 38.4rem
  }

  .xl\:left-384 {
    left: 38.4rem
  }

  .xl\:top-400 {
    top: 40rem
  }

  .xl\:right-400 {
    right: 40rem
  }

  .xl\:bottom-400 {
    bottom: 40rem
  }

  .xl\:left-400 {
    left: 40rem
  }

  .xl\:top-512 {
    top: 51.2rem
  }

  .xl\:right-512 {
    right: 51.2rem
  }

  .xl\:bottom-512 {
    bottom: 51.2rem
  }

  .xl\:left-512 {
    left: 51.2rem
  }

  .xl\:top-640 {
    top: 64rem
  }

  .xl\:right-640 {
    right: 64rem
  }

  .xl\:bottom-640 {
    bottom: 64rem
  }

  .xl\:left-640 {
    left: 64rem
  }

  .xl\:top-auto {
    top: auto
  }

  .xl\:right-auto {
    right: auto
  }

  .xl\:bottom-auto {
    bottom: auto
  }

  .xl\:left-auto {
    left: auto
  }

  .xl\:top-xs {
    top: 32rem
  }

  .xl\:right-xs {
    right: 32rem
  }

  .xl\:bottom-xs {
    bottom: 32rem
  }

  .xl\:left-xs {
    left: 32rem
  }

  .xl\:top-sm {
    top: 48rem
  }

  .xl\:right-sm {
    right: 48rem
  }

  .xl\:bottom-sm {
    bottom: 48rem
  }

  .xl\:left-sm {
    left: 48rem
  }

  .xl\:top-md {
    top: 64rem
  }

  .xl\:right-md {
    right: 64rem
  }

  .xl\:bottom-md {
    bottom: 64rem
  }

  .xl\:left-md {
    left: 64rem
  }

  .xl\:top-lg {
    top: 80rem
  }

  .xl\:right-lg {
    right: 80rem
  }

  .xl\:bottom-lg {
    bottom: 80rem
  }

  .xl\:left-lg {
    left: 80rem
  }

  .xl\:top-xl {
    top: 96rem
  }

  .xl\:right-xl {
    right: 96rem
  }

  .xl\:bottom-xl {
    bottom: 96rem
  }

  .xl\:left-xl {
    left: 96rem
  }

  .xl\:top-2xl {
    top: 112rem
  }

  .xl\:right-2xl {
    right: 112rem
  }

  .xl\:bottom-2xl {
    bottom: 112rem
  }

  .xl\:left-2xl {
    left: 112rem
  }

  .xl\:top-3xl {
    top: 128rem
  }

  .xl\:right-3xl {
    right: 128rem
  }

  .xl\:bottom-3xl {
    bottom: 128rem
  }

  .xl\:left-3xl {
    left: 128rem
  }

  .xl\:top-4xl {
    top: 144rem
  }

  .xl\:right-4xl {
    right: 144rem
  }

  .xl\:bottom-4xl {
    bottom: 144rem
  }

  .xl\:left-4xl {
    left: 144rem
  }

  .xl\:top-5xl {
    top: 160rem
  }

  .xl\:right-5xl {
    right: 160rem
  }

  .xl\:bottom-5xl {
    bottom: 160rem
  }

  .xl\:left-5xl {
    left: 160rem
  }

  .xl\:top-px {
    top: 1px
  }

  .xl\:right-px {
    right: 1px
  }

  .xl\:bottom-px {
    bottom: 1px
  }

  .xl\:left-px {
    left: 1px
  }

  .xl\:top-0\.5 {
    top: 0.05rem
  }

  .xl\:right-0\.5 {
    right: 0.05rem
  }

  .xl\:bottom-0\.5 {
    bottom: 0.05rem
  }

  .xl\:left-0\.5 {
    left: 0.05rem
  }

  .xl\:top-1\.5 {
    top: 0.15rem
  }

  .xl\:right-1\.5 {
    right: 0.15rem
  }

  .xl\:bottom-1\.5 {
    bottom: 0.15rem
  }

  .xl\:left-1\.5 {
    left: 0.15rem
  }

  .xl\:top-2\.5 {
    top: 0.25rem
  }

  .xl\:right-2\.5 {
    right: 0.25rem
  }

  .xl\:bottom-2\.5 {
    bottom: 0.25rem
  }

  .xl\:left-2\.5 {
    left: 0.25rem
  }

  .xl\:top-3\.5 {
    top: 0.35rem
  }

  .xl\:right-3\.5 {
    right: 0.35rem
  }

  .xl\:bottom-3\.5 {
    bottom: 0.35rem
  }

  .xl\:left-3\.5 {
    left: 0.35rem
  }

  .xl\:-top-1 {
    top: -0.1rem
  }

  .xl\:-right-1 {
    right: -0.1rem
  }

  .xl\:-bottom-1 {
    bottom: -0.1rem
  }

  .xl\:-left-1 {
    left: -0.1rem
  }

  .xl\:-top-2 {
    top: -0.2rem
  }

  .xl\:-right-2 {
    right: -0.2rem
  }

  .xl\:-bottom-2 {
    bottom: -0.2rem
  }

  .xl\:-left-2 {
    left: -0.2rem
  }

  .xl\:-top-3 {
    top: -0.3rem
  }

  .xl\:-right-3 {
    right: -0.3rem
  }

  .xl\:-bottom-3 {
    bottom: -0.3rem
  }

  .xl\:-left-3 {
    left: -0.3rem
  }

  .xl\:-top-4 {
    top: -0.4rem
  }

  .xl\:-right-4 {
    right: -0.4rem
  }

  .xl\:-bottom-4 {
    bottom: -0.4rem
  }

  .xl\:-left-4 {
    left: -0.4rem
  }

  .xl\:-top-5 {
    top: -0.5rem
  }

  .xl\:-right-5 {
    right: -0.5rem
  }

  .xl\:-bottom-5 {
    bottom: -0.5rem
  }

  .xl\:-left-5 {
    left: -0.5rem
  }

  .xl\:-top-6 {
    top: -0.6rem
  }

  .xl\:-right-6 {
    right: -0.6rem
  }

  .xl\:-bottom-6 {
    bottom: -0.6rem
  }

  .xl\:-left-6 {
    left: -0.6rem
  }

  .xl\:-top-7 {
    top: -0.7rem
  }

  .xl\:-right-7 {
    right: -0.7rem
  }

  .xl\:-bottom-7 {
    bottom: -0.7rem
  }

  .xl\:-left-7 {
    left: -0.7rem
  }

  .xl\:-top-8 {
    top: -0.8rem
  }

  .xl\:-right-8 {
    right: -0.8rem
  }

  .xl\:-bottom-8 {
    bottom: -0.8rem
  }

  .xl\:-left-8 {
    left: -0.8rem
  }

  .xl\:-top-9 {
    top: -0.9rem
  }

  .xl\:-right-9 {
    right: -0.9rem
  }

  .xl\:-bottom-9 {
    bottom: -0.9rem
  }

  .xl\:-left-9 {
    left: -0.9rem
  }

  .xl\:-top-10 {
    top: -1rem
  }

  .xl\:-right-10 {
    right: -1rem
  }

  .xl\:-bottom-10 {
    bottom: -1rem
  }

  .xl\:-left-10 {
    left: -1rem
  }

  .xl\:-top-12 {
    top: -1.2rem
  }

  .xl\:-right-12 {
    right: -1.2rem
  }

  .xl\:-bottom-12 {
    bottom: -1.2rem
  }

  .xl\:-left-12 {
    left: -1.2rem
  }

  .xl\:-top-14 {
    top: -1.4rem
  }

  .xl\:-right-14 {
    right: -1.4rem
  }

  .xl\:-bottom-14 {
    bottom: -1.4rem
  }

  .xl\:-left-14 {
    left: -1.4rem
  }

  .xl\:-top-16 {
    top: -1.6rem
  }

  .xl\:-right-16 {
    right: -1.6rem
  }

  .xl\:-bottom-16 {
    bottom: -1.6rem
  }

  .xl\:-left-16 {
    left: -1.6rem
  }

  .xl\:-top-20 {
    top: -2rem
  }

  .xl\:-right-20 {
    right: -2rem
  }

  .xl\:-bottom-20 {
    bottom: -2rem
  }

  .xl\:-left-20 {
    left: -2rem
  }

  .xl\:-top-24 {
    top: -2.4rem
  }

  .xl\:-right-24 {
    right: -2.4rem
  }

  .xl\:-bottom-24 {
    bottom: -2.4rem
  }

  .xl\:-left-24 {
    left: -2.4rem
  }

  .xl\:-top-28 {
    top: -2.8rem
  }

  .xl\:-right-28 {
    right: -2.8rem
  }

  .xl\:-bottom-28 {
    bottom: -2.8rem
  }

  .xl\:-left-28 {
    left: -2.8rem
  }

  .xl\:-top-32 {
    top: -3.2rem
  }

  .xl\:-right-32 {
    right: -3.2rem
  }

  .xl\:-bottom-32 {
    bottom: -3.2rem
  }

  .xl\:-left-32 {
    left: -3.2rem
  }

  .xl\:-top-36 {
    top: -3.6rem
  }

  .xl\:-right-36 {
    right: -3.6rem
  }

  .xl\:-bottom-36 {
    bottom: -3.6rem
  }

  .xl\:-left-36 {
    left: -3.6rem
  }

  .xl\:-top-40 {
    top: -4rem
  }

  .xl\:-right-40 {
    right: -4rem
  }

  .xl\:-bottom-40 {
    bottom: -4rem
  }

  .xl\:-left-40 {
    left: -4rem
  }

  .xl\:-top-44 {
    top: -4.4rem
  }

  .xl\:-right-44 {
    right: -4.4rem
  }

  .xl\:-bottom-44 {
    bottom: -4.4rem
  }

  .xl\:-left-44 {
    left: -4.4rem
  }

  .xl\:-top-48 {
    top: -4.8rem
  }

  .xl\:-right-48 {
    right: -4.8rem
  }

  .xl\:-bottom-48 {
    bottom: -4.8rem
  }

  .xl\:-left-48 {
    left: -4.8rem
  }

  .xl\:-top-52 {
    top: -5.2rem
  }

  .xl\:-right-52 {
    right: -5.2rem
  }

  .xl\:-bottom-52 {
    bottom: -5.2rem
  }

  .xl\:-left-52 {
    left: -5.2rem
  }

  .xl\:-top-56 {
    top: -5.6rem
  }

  .xl\:-right-56 {
    right: -5.6rem
  }

  .xl\:-bottom-56 {
    bottom: -5.6rem
  }

  .xl\:-left-56 {
    left: -5.6rem
  }

  .xl\:-top-60 {
    top: -6rem
  }

  .xl\:-right-60 {
    right: -6rem
  }

  .xl\:-bottom-60 {
    bottom: -6rem
  }

  .xl\:-left-60 {
    left: -6rem
  }

  .xl\:-top-64 {
    top: -6.4rem
  }

  .xl\:-right-64 {
    right: -6.4rem
  }

  .xl\:-bottom-64 {
    bottom: -6.4rem
  }

  .xl\:-left-64 {
    left: -6.4rem
  }

  .xl\:-top-68 {
    top: -6.8rem
  }

  .xl\:-right-68 {
    right: -6.8rem
  }

  .xl\:-bottom-68 {
    bottom: -6.8rem
  }

  .xl\:-left-68 {
    left: -6.8rem
  }

  .xl\:-top-72 {
    top: -7.2rem
  }

  .xl\:-right-72 {
    right: -7.2rem
  }

  .xl\:-bottom-72 {
    bottom: -7.2rem
  }

  .xl\:-left-72 {
    left: -7.2rem
  }

  .xl\:-top-76 {
    top: -7.6rem
  }

  .xl\:-right-76 {
    right: -7.6rem
  }

  .xl\:-bottom-76 {
    bottom: -7.6rem
  }

  .xl\:-left-76 {
    left: -7.6rem
  }

  .xl\:-top-80 {
    top: -8rem
  }

  .xl\:-right-80 {
    right: -8rem
  }

  .xl\:-bottom-80 {
    bottom: -8rem
  }

  .xl\:-left-80 {
    left: -8rem
  }

  .xl\:-top-84 {
    top: -8.4rem
  }

  .xl\:-right-84 {
    right: -8.4rem
  }

  .xl\:-bottom-84 {
    bottom: -8.4rem
  }

  .xl\:-left-84 {
    left: -8.4rem
  }

  .xl\:-top-88 {
    top: -8.8rem
  }

  .xl\:-right-88 {
    right: -8.8rem
  }

  .xl\:-bottom-88 {
    bottom: -8.8rem
  }

  .xl\:-left-88 {
    left: -8.8rem
  }

  .xl\:-top-92 {
    top: -9.2rem
  }

  .xl\:-right-92 {
    right: -9.2rem
  }

  .xl\:-bottom-92 {
    bottom: -9.2rem
  }

  .xl\:-left-92 {
    left: -9.2rem
  }

  .xl\:-top-96 {
    top: -9.6rem
  }

  .xl\:-right-96 {
    right: -9.6rem
  }

  .xl\:-bottom-96 {
    bottom: -9.6rem
  }

  .xl\:-left-96 {
    left: -9.6rem
  }

  .xl\:-top-128 {
    top: -12.8rem
  }

  .xl\:-right-128 {
    right: -12.8rem
  }

  .xl\:-bottom-128 {
    bottom: -12.8rem
  }

  .xl\:-left-128 {
    left: -12.8rem
  }

  .xl\:-top-136 {
    top: -13.6rem
  }

  .xl\:-right-136 {
    right: -13.6rem
  }

  .xl\:-bottom-136 {
    bottom: -13.6rem
  }

  .xl\:-left-136 {
    left: -13.6rem
  }

  .xl\:-top-160 {
    top: -16rem
  }

  .xl\:-right-160 {
    right: -16rem
  }

  .xl\:-bottom-160 {
    bottom: -16rem
  }

  .xl\:-left-160 {
    left: -16rem
  }

  .xl\:-top-192 {
    top: -19.2rem
  }

  .xl\:-right-192 {
    right: -19.2rem
  }

  .xl\:-bottom-192 {
    bottom: -19.2rem
  }

  .xl\:-left-192 {
    left: -19.2rem
  }

  .xl\:-top-200 {
    top: -20rem
  }

  .xl\:-right-200 {
    right: -20rem
  }

  .xl\:-bottom-200 {
    bottom: -20rem
  }

  .xl\:-left-200 {
    left: -20rem
  }

  .xl\:-top-208 {
    top: -20.8rem
  }

  .xl\:-right-208 {
    right: -20.8rem
  }

  .xl\:-bottom-208 {
    bottom: -20.8rem
  }

  .xl\:-left-208 {
    left: -20.8rem
  }

  .xl\:-top-216 {
    top: -21.6rem
  }

  .xl\:-right-216 {
    right: -21.6rem
  }

  .xl\:-bottom-216 {
    bottom: -21.6rem
  }

  .xl\:-left-216 {
    left: -21.6rem
  }

  .xl\:-top-224 {
    top: -22.4rem
  }

  .xl\:-right-224 {
    right: -22.4rem
  }

  .xl\:-bottom-224 {
    bottom: -22.4rem
  }

  .xl\:-left-224 {
    left: -22.4rem
  }

  .xl\:-top-256 {
    top: -25.6rem
  }

  .xl\:-right-256 {
    right: -25.6rem
  }

  .xl\:-bottom-256 {
    bottom: -25.6rem
  }

  .xl\:-left-256 {
    left: -25.6rem
  }

  .xl\:-top-288 {
    top: -28.8rem
  }

  .xl\:-right-288 {
    right: -28.8rem
  }

  .xl\:-bottom-288 {
    bottom: -28.8rem
  }

  .xl\:-left-288 {
    left: -28.8rem
  }

  .xl\:-top-320 {
    top: -32rem
  }

  .xl\:-right-320 {
    right: -32rem
  }

  .xl\:-bottom-320 {
    bottom: -32rem
  }

  .xl\:-left-320 {
    left: -32rem
  }

  .xl\:-top-360 {
    top: -36rem
  }

  .xl\:-right-360 {
    right: -36rem
  }

  .xl\:-bottom-360 {
    bottom: -36rem
  }

  .xl\:-left-360 {
    left: -36rem
  }

  .xl\:-top-384 {
    top: -38.4rem
  }

  .xl\:-right-384 {
    right: -38.4rem
  }

  .xl\:-bottom-384 {
    bottom: -38.4rem
  }

  .xl\:-left-384 {
    left: -38.4rem
  }

  .xl\:-top-400 {
    top: -40rem
  }

  .xl\:-right-400 {
    right: -40rem
  }

  .xl\:-bottom-400 {
    bottom: -40rem
  }

  .xl\:-left-400 {
    left: -40rem
  }

  .xl\:-top-512 {
    top: -51.2rem
  }

  .xl\:-right-512 {
    right: -51.2rem
  }

  .xl\:-bottom-512 {
    bottom: -51.2rem
  }

  .xl\:-left-512 {
    left: -51.2rem
  }

  .xl\:-top-640 {
    top: -64rem
  }

  .xl\:-right-640 {
    right: -64rem
  }

  .xl\:-bottom-640 {
    bottom: -64rem
  }

  .xl\:-left-640 {
    left: -64rem
  }

  .xl\:-top-xs {
    top: -32rem
  }

  .xl\:-right-xs {
    right: -32rem
  }

  .xl\:-bottom-xs {
    bottom: -32rem
  }

  .xl\:-left-xs {
    left: -32rem
  }

  .xl\:-top-sm {
    top: -48rem
  }

  .xl\:-right-sm {
    right: -48rem
  }

  .xl\:-bottom-sm {
    bottom: -48rem
  }

  .xl\:-left-sm {
    left: -48rem
  }

  .xl\:-top-md {
    top: -64rem
  }

  .xl\:-right-md {
    right: -64rem
  }

  .xl\:-bottom-md {
    bottom: -64rem
  }

  .xl\:-left-md {
    left: -64rem
  }

  .xl\:-top-lg {
    top: -80rem
  }

  .xl\:-right-lg {
    right: -80rem
  }

  .xl\:-bottom-lg {
    bottom: -80rem
  }

  .xl\:-left-lg {
    left: -80rem
  }

  .xl\:-top-xl {
    top: -96rem
  }

  .xl\:-right-xl {
    right: -96rem
  }

  .xl\:-bottom-xl {
    bottom: -96rem
  }

  .xl\:-left-xl {
    left: -96rem
  }

  .xl\:-top-2xl {
    top: -112rem
  }

  .xl\:-right-2xl {
    right: -112rem
  }

  .xl\:-bottom-2xl {
    bottom: -112rem
  }

  .xl\:-left-2xl {
    left: -112rem
  }

  .xl\:-top-3xl {
    top: -128rem
  }

  .xl\:-right-3xl {
    right: -128rem
  }

  .xl\:-bottom-3xl {
    bottom: -128rem
  }

  .xl\:-left-3xl {
    left: -128rem
  }

  .xl\:-top-4xl {
    top: -144rem
  }

  .xl\:-right-4xl {
    right: -144rem
  }

  .xl\:-bottom-4xl {
    bottom: -144rem
  }

  .xl\:-left-4xl {
    left: -144rem
  }

  .xl\:-top-5xl {
    top: -160rem
  }

  .xl\:-right-5xl {
    right: -160rem
  }

  .xl\:-bottom-5xl {
    bottom: -160rem
  }

  .xl\:-left-5xl {
    left: -160rem
  }

  .xl\:-top-px {
    top: -1px
  }

  .xl\:-right-px {
    right: -1px
  }

  .xl\:-bottom-px {
    bottom: -1px
  }

  .xl\:-left-px {
    left: -1px
  }

  .xl\:-top-0\.5 {
    top: -0.05rem
  }

  .xl\:-right-0\.5 {
    right: -0.05rem
  }

  .xl\:-bottom-0\.5 {
    bottom: -0.05rem
  }

  .xl\:-left-0\.5 {
    left: -0.05rem
  }

  .xl\:-top-1\.5 {
    top: -0.15rem
  }

  .xl\:-right-1\.5 {
    right: -0.15rem
  }

  .xl\:-bottom-1\.5 {
    bottom: -0.15rem
  }

  .xl\:-left-1\.5 {
    left: -0.15rem
  }

  .xl\:-top-2\.5 {
    top: -0.25rem
  }

  .xl\:-right-2\.5 {
    right: -0.25rem
  }

  .xl\:-bottom-2\.5 {
    bottom: -0.25rem
  }

  .xl\:-left-2\.5 {
    left: -0.25rem
  }

  .xl\:-top-3\.5 {
    top: -0.35rem
  }

  .xl\:-right-3\.5 {
    right: -0.35rem
  }

  .xl\:-bottom-3\.5 {
    bottom: -0.35rem
  }

  .xl\:-left-3\.5 {
    left: -0.35rem
  }

  .xl\:top-1\/2 {
    top: 50%
  }

  .xl\:right-1\/2 {
    right: 50%
  }

  .xl\:bottom-1\/2 {
    bottom: 50%
  }

  .xl\:left-1\/2 {
    left: 50%
  }

  .xl\:top-1\/3 {
    top: 33.333333%
  }

  .xl\:right-1\/3 {
    right: 33.333333%
  }

  .xl\:bottom-1\/3 {
    bottom: 33.333333%
  }

  .xl\:left-1\/3 {
    left: 33.333333%
  }

  .xl\:top-2\/3 {
    top: 66.666667%
  }

  .xl\:right-2\/3 {
    right: 66.666667%
  }

  .xl\:bottom-2\/3 {
    bottom: 66.666667%
  }

  .xl\:left-2\/3 {
    left: 66.666667%
  }

  .xl\:top-1\/4 {
    top: 25%
  }

  .xl\:right-1\/4 {
    right: 25%
  }

  .xl\:bottom-1\/4 {
    bottom: 25%
  }

  .xl\:left-1\/4 {
    left: 25%
  }

  .xl\:top-2\/4 {
    top: 50%
  }

  .xl\:right-2\/4 {
    right: 50%
  }

  .xl\:bottom-2\/4 {
    bottom: 50%
  }

  .xl\:left-2\/4 {
    left: 50%
  }

  .xl\:top-3\/4 {
    top: 75%
  }

  .xl\:right-3\/4 {
    right: 75%
  }

  .xl\:bottom-3\/4 {
    bottom: 75%
  }

  .xl\:left-3\/4 {
    left: 75%
  }

  .xl\:top-full {
    top: 100%
  }

  .xl\:right-full {
    right: 100%
  }

  .xl\:bottom-full {
    bottom: 100%
  }

  .xl\:left-full {
    left: 100%
  }

  .xl\:-top-1\/2 {
    top: -50%
  }

  .xl\:-right-1\/2 {
    right: -50%
  }

  .xl\:-bottom-1\/2 {
    bottom: -50%
  }

  .xl\:-left-1\/2 {
    left: -50%
  }

  .xl\:-top-1\/3 {
    top: -33.333333%
  }

  .xl\:-right-1\/3 {
    right: -33.333333%
  }

  .xl\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  .xl\:-left-1\/3 {
    left: -33.333333%
  }

  .xl\:-top-2\/3 {
    top: -66.666667%
  }

  .xl\:-right-2\/3 {
    right: -66.666667%
  }

  .xl\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  .xl\:-left-2\/3 {
    left: -66.666667%
  }

  .xl\:-top-1\/4 {
    top: -25%
  }

  .xl\:-right-1\/4 {
    right: -25%
  }

  .xl\:-bottom-1\/4 {
    bottom: -25%
  }

  .xl\:-left-1\/4 {
    left: -25%
  }

  .xl\:-top-2\/4 {
    top: -50%
  }

  .xl\:-right-2\/4 {
    right: -50%
  }

  .xl\:-bottom-2\/4 {
    bottom: -50%
  }

  .xl\:-left-2\/4 {
    left: -50%
  }

  .xl\:-top-3\/4 {
    top: -75%
  }

  .xl\:-right-3\/4 {
    right: -75%
  }

  .xl\:-bottom-3\/4 {
    bottom: -75%
  }

  .xl\:-left-3\/4 {
    left: -75%
  }

  .xl\:-top-full {
    top: -100%
  }

  .xl\:-right-full {
    right: -100%
  }

  .xl\:-bottom-full {
    bottom: -100%
  }

  .xl\:-left-full {
    left: -100%
  }

  [dir='ltr'] .xl\:ltr\:inset-0,[dir='ltr'].xl\:ltr\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  [dir='ltr'] .xl\:ltr\:inset-1,[dir='ltr'].xl\:ltr\:inset-1 {
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:inset-2,[dir='ltr'].xl\:ltr\:inset-2 {
    top: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-3,[dir='ltr'].xl\:ltr\:inset-3 {
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:inset-4,[dir='ltr'].xl\:ltr\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-5,[dir='ltr'].xl\:ltr\:inset-5 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:inset-6,[dir='ltr'].xl\:ltr\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-7,[dir='ltr'].xl\:ltr\:inset-7 {
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
    left: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:inset-8,[dir='ltr'].xl\:ltr\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-9,[dir='ltr'].xl\:ltr\:inset-9 {
    top: 0.9rem;
    right: 0.9rem;
    bottom: 0.9rem;
    left: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:inset-10,[dir='ltr'].xl\:ltr\:inset-10 {
    top: 1.0rem;
    right: 1.0rem;
    bottom: 1.0rem;
    left: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:inset-12,[dir='ltr'].xl\:ltr\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-14,[dir='ltr'].xl\:ltr\:inset-14 {
    top: 1.4rem;
    right: 1.4rem;
    bottom: 1.4rem;
    left: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-16,[dir='ltr'].xl\:ltr\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-20,[dir='ltr'].xl\:ltr\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-24,[dir='ltr'].xl\:ltr\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-28,[dir='ltr'].xl\:ltr\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-32,[dir='ltr'].xl\:ltr\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-36,[dir='ltr'].xl\:ltr\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-40,[dir='ltr'].xl\:ltr\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-44,[dir='ltr'].xl\:ltr\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-48,[dir='ltr'].xl\:ltr\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-52,[dir='ltr'].xl\:ltr\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-56,[dir='ltr'].xl\:ltr\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-60,[dir='ltr'].xl\:ltr\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-64,[dir='ltr'].xl\:ltr\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-68,[dir='ltr'].xl\:ltr\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-72,[dir='ltr'].xl\:ltr\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-76,[dir='ltr'].xl\:ltr\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-80,[dir='ltr'].xl\:ltr\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-84,[dir='ltr'].xl\:ltr\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-88,[dir='ltr'].xl\:ltr\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-92,[dir='ltr'].xl\:ltr\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-96,[dir='ltr'].xl\:ltr\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-128,[dir='ltr'].xl\:ltr\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-136,[dir='ltr'].xl\:ltr\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-160,[dir='ltr'].xl\:ltr\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  [dir='ltr'] .xl\:ltr\:inset-192,[dir='ltr'].xl\:ltr\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-200,[dir='ltr'].xl\:ltr\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  [dir='ltr'] .xl\:ltr\:inset-208,[dir='ltr'].xl\:ltr\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-216,[dir='ltr'].xl\:ltr\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-224,[dir='ltr'].xl\:ltr\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-256,[dir='ltr'].xl\:ltr\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-288,[dir='ltr'].xl\:ltr\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-320,[dir='ltr'].xl\:ltr\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='ltr'] .xl\:ltr\:inset-360,[dir='ltr'].xl\:ltr\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  [dir='ltr'] .xl\:ltr\:inset-384,[dir='ltr'].xl\:ltr\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-400,[dir='ltr'].xl\:ltr\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  [dir='ltr'] .xl\:ltr\:inset-512,[dir='ltr'].xl\:ltr\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-640,[dir='ltr'].xl\:ltr\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='ltr'] .xl\:ltr\:inset-auto,[dir='ltr'].xl\:ltr\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  [dir='ltr'] .xl\:ltr\:inset-xs,[dir='ltr'].xl\:ltr\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='ltr'] .xl\:ltr\:inset-sm,[dir='ltr'].xl\:ltr\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  [dir='ltr'] .xl\:ltr\:inset-md,[dir='ltr'].xl\:ltr\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='ltr'] .xl\:ltr\:inset-lg,[dir='ltr'].xl\:ltr\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  [dir='ltr'] .xl\:ltr\:inset-xl,[dir='ltr'].xl\:ltr\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  [dir='ltr'] .xl\:ltr\:inset-2xl,[dir='ltr'].xl\:ltr\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  [dir='ltr'] .xl\:ltr\:inset-3xl,[dir='ltr'].xl\:ltr\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  [dir='ltr'] .xl\:ltr\:inset-4xl,[dir='ltr'].xl\:ltr\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  [dir='ltr'] .xl\:ltr\:inset-5xl,[dir='ltr'].xl\:ltr\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  [dir='ltr'] .xl\:ltr\:inset-px,[dir='ltr'].xl\:ltr\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  [dir='ltr'] .xl\:ltr\:inset-0\.5,[dir='ltr'].xl\:ltr\:inset-0\.5 {
    top: 0.05rem;
    right: 0.05rem;
    bottom: 0.05rem;
    left: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:inset-1\.5,[dir='ltr'].xl\:ltr\:inset-1\.5 {
    top: 0.15rem;
    right: 0.15rem;
    bottom: 0.15rem;
    left: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:inset-2\.5,[dir='ltr'].xl\:ltr\:inset-2\.5 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:inset-3\.5,[dir='ltr'].xl\:ltr\:inset-3\.5 {
    top: 0.35rem;
    right: 0.35rem;
    bottom: 0.35rem;
    left: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-1,[dir='ltr'].xl\:ltr\:-inset-1 {
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-2,[dir='ltr'].xl\:ltr\:-inset-2 {
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-3,[dir='ltr'].xl\:ltr\:-inset-3 {
    top: -0.3rem;
    right: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-4,[dir='ltr'].xl\:ltr\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-5,[dir='ltr'].xl\:ltr\:-inset-5 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-6,[dir='ltr'].xl\:ltr\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-7,[dir='ltr'].xl\:ltr\:-inset-7 {
    top: -0.7rem;
    right: -0.7rem;
    bottom: -0.7rem;
    left: -0.7rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-8,[dir='ltr'].xl\:ltr\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-9,[dir='ltr'].xl\:ltr\:-inset-9 {
    top: -0.9rem;
    right: -0.9rem;
    bottom: -0.9rem;
    left: -0.9rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-10,[dir='ltr'].xl\:ltr\:-inset-10 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-12,[dir='ltr'].xl\:ltr\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-14,[dir='ltr'].xl\:ltr\:-inset-14 {
    top: -1.4rem;
    right: -1.4rem;
    bottom: -1.4rem;
    left: -1.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-16,[dir='ltr'].xl\:ltr\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-20,[dir='ltr'].xl\:ltr\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-24,[dir='ltr'].xl\:ltr\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-28,[dir='ltr'].xl\:ltr\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-32,[dir='ltr'].xl\:ltr\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-36,[dir='ltr'].xl\:ltr\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-40,[dir='ltr'].xl\:ltr\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-44,[dir='ltr'].xl\:ltr\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-48,[dir='ltr'].xl\:ltr\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-52,[dir='ltr'].xl\:ltr\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-56,[dir='ltr'].xl\:ltr\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-60,[dir='ltr'].xl\:ltr\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-64,[dir='ltr'].xl\:ltr\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-68,[dir='ltr'].xl\:ltr\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-72,[dir='ltr'].xl\:ltr\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-76,[dir='ltr'].xl\:ltr\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-80,[dir='ltr'].xl\:ltr\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-84,[dir='ltr'].xl\:ltr\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-88,[dir='ltr'].xl\:ltr\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-92,[dir='ltr'].xl\:ltr\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-96,[dir='ltr'].xl\:ltr\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-128,[dir='ltr'].xl\:ltr\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-136,[dir='ltr'].xl\:ltr\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-160,[dir='ltr'].xl\:ltr\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-192,[dir='ltr'].xl\:ltr\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-200,[dir='ltr'].xl\:ltr\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-208,[dir='ltr'].xl\:ltr\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-216,[dir='ltr'].xl\:ltr\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-224,[dir='ltr'].xl\:ltr\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-256,[dir='ltr'].xl\:ltr\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-288,[dir='ltr'].xl\:ltr\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-320,[dir='ltr'].xl\:ltr\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-360,[dir='ltr'].xl\:ltr\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-384,[dir='ltr'].xl\:ltr\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-400,[dir='ltr'].xl\:ltr\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-512,[dir='ltr'].xl\:ltr\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-640,[dir='ltr'].xl\:ltr\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-xs,[dir='ltr'].xl\:ltr\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-sm,[dir='ltr'].xl\:ltr\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-md,[dir='ltr'].xl\:ltr\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-lg,[dir='ltr'].xl\:ltr\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-xl,[dir='ltr'].xl\:ltr\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-2xl,[dir='ltr'].xl\:ltr\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-3xl,[dir='ltr'].xl\:ltr\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-4xl,[dir='ltr'].xl\:ltr\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-5xl,[dir='ltr'].xl\:ltr\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-px,[dir='ltr'].xl\:ltr\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  [dir='ltr'] .xl\:ltr\:-inset-0\.5,[dir='ltr'].xl\:ltr\:-inset-0\.5 {
    top: -0.05rem;
    right: -0.05rem;
    bottom: -0.05rem;
    left: -0.05rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-1\.5,[dir='ltr'].xl\:ltr\:-inset-1\.5 {
    top: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    left: -0.15rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-2\.5,[dir='ltr'].xl\:ltr\:-inset-2\.5 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-3\.5,[dir='ltr'].xl\:ltr\:-inset-3\.5 {
    top: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    left: -0.35rem
  }

  [dir='ltr'] .xl\:ltr\:inset-1\/2,[dir='ltr'].xl\:ltr\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='ltr'] .xl\:ltr\:inset-1\/3,[dir='ltr'].xl\:ltr\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  [dir='ltr'] .xl\:ltr\:inset-2\/3,[dir='ltr'].xl\:ltr\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  [dir='ltr'] .xl\:ltr\:inset-1\/4,[dir='ltr'].xl\:ltr\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  [dir='ltr'] .xl\:ltr\:inset-2\/4,[dir='ltr'].xl\:ltr\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='ltr'] .xl\:ltr\:inset-3\/4,[dir='ltr'].xl\:ltr\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  [dir='ltr'] .xl\:ltr\:inset-full,[dir='ltr'].xl\:ltr\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  [dir='ltr'] .xl\:ltr\:-inset-1\/2,[dir='ltr'].xl\:ltr\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='ltr'] .xl\:ltr\:-inset-1\/3,[dir='ltr'].xl\:ltr\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  [dir='ltr'] .xl\:ltr\:-inset-2\/3,[dir='ltr'].xl\:ltr\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  [dir='ltr'] .xl\:ltr\:-inset-1\/4,[dir='ltr'].xl\:ltr\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  [dir='ltr'] .xl\:ltr\:-inset-2\/4,[dir='ltr'].xl\:ltr\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='ltr'] .xl\:ltr\:-inset-3\/4,[dir='ltr'].xl\:ltr\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  [dir='ltr'] .xl\:ltr\:-inset-full,[dir='ltr'].xl\:ltr\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  [dir='ltr'] .xl\:ltr\:inset-y-0,[dir='ltr'].xl\:ltr\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  [dir='ltr'] .xl\:ltr\:inset-x-0,[dir='ltr'].xl\:ltr\:inset-x-0 {
    right: 0;
    left: 0
  }

  [dir='ltr'] .xl\:ltr\:inset-y-1,[dir='ltr'].xl\:ltr\:inset-y-1 {
    top: 0.1rem;
    bottom: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-1,[dir='ltr'].xl\:ltr\:inset-x-1 {
    right: 0.1rem;
    left: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-2,[dir='ltr'].xl\:ltr\:inset-y-2 {
    top: 0.2rem;
    bottom: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-2,[dir='ltr'].xl\:ltr\:inset-x-2 {
    right: 0.2rem;
    left: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-3,[dir='ltr'].xl\:ltr\:inset-y-3 {
    top: 0.3rem;
    bottom: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-3,[dir='ltr'].xl\:ltr\:inset-x-3 {
    right: 0.3rem;
    left: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-4,[dir='ltr'].xl\:ltr\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-4,[dir='ltr'].xl\:ltr\:inset-x-4 {
    right: 0.4rem;
    left: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-5,[dir='ltr'].xl\:ltr\:inset-y-5 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-5,[dir='ltr'].xl\:ltr\:inset-x-5 {
    right: 0.5rem;
    left: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-6,[dir='ltr'].xl\:ltr\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-6,[dir='ltr'].xl\:ltr\:inset-x-6 {
    right: 0.6rem;
    left: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-7,[dir='ltr'].xl\:ltr\:inset-y-7 {
    top: 0.7rem;
    bottom: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-7,[dir='ltr'].xl\:ltr\:inset-x-7 {
    right: 0.7rem;
    left: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-8,[dir='ltr'].xl\:ltr\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-8,[dir='ltr'].xl\:ltr\:inset-x-8 {
    right: 0.8rem;
    left: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-9,[dir='ltr'].xl\:ltr\:inset-y-9 {
    top: 0.9rem;
    bottom: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-9,[dir='ltr'].xl\:ltr\:inset-x-9 {
    right: 0.9rem;
    left: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-10,[dir='ltr'].xl\:ltr\:inset-y-10 {
    top: 1.0rem;
    bottom: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-10,[dir='ltr'].xl\:ltr\:inset-x-10 {
    right: 1.0rem;
    left: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-12,[dir='ltr'].xl\:ltr\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-12,[dir='ltr'].xl\:ltr\:inset-x-12 {
    right: 1.2rem;
    left: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-14,[dir='ltr'].xl\:ltr\:inset-y-14 {
    top: 1.4rem;
    bottom: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-14,[dir='ltr'].xl\:ltr\:inset-x-14 {
    right: 1.4rem;
    left: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-16,[dir='ltr'].xl\:ltr\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-16,[dir='ltr'].xl\:ltr\:inset-x-16 {
    right: 1.6rem;
    left: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-20,[dir='ltr'].xl\:ltr\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-20,[dir='ltr'].xl\:ltr\:inset-x-20 {
    right: 2rem;
    left: 2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-24,[dir='ltr'].xl\:ltr\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-24,[dir='ltr'].xl\:ltr\:inset-x-24 {
    right: 2.4rem;
    left: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-28,[dir='ltr'].xl\:ltr\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-28,[dir='ltr'].xl\:ltr\:inset-x-28 {
    right: 2.8rem;
    left: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-32,[dir='ltr'].xl\:ltr\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-32,[dir='ltr'].xl\:ltr\:inset-x-32 {
    right: 3.2rem;
    left: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-36,[dir='ltr'].xl\:ltr\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-36,[dir='ltr'].xl\:ltr\:inset-x-36 {
    right: 3.6rem;
    left: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-40,[dir='ltr'].xl\:ltr\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-40,[dir='ltr'].xl\:ltr\:inset-x-40 {
    right: 4rem;
    left: 4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-44,[dir='ltr'].xl\:ltr\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-44,[dir='ltr'].xl\:ltr\:inset-x-44 {
    right: 4.4rem;
    left: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-48,[dir='ltr'].xl\:ltr\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-48,[dir='ltr'].xl\:ltr\:inset-x-48 {
    right: 4.8rem;
    left: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-52,[dir='ltr'].xl\:ltr\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-52,[dir='ltr'].xl\:ltr\:inset-x-52 {
    right: 5.2rem;
    left: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-56,[dir='ltr'].xl\:ltr\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-56,[dir='ltr'].xl\:ltr\:inset-x-56 {
    right: 5.6rem;
    left: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-60,[dir='ltr'].xl\:ltr\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-60,[dir='ltr'].xl\:ltr\:inset-x-60 {
    right: 6rem;
    left: 6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-64,[dir='ltr'].xl\:ltr\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-64,[dir='ltr'].xl\:ltr\:inset-x-64 {
    right: 6.4rem;
    left: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-68,[dir='ltr'].xl\:ltr\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-68,[dir='ltr'].xl\:ltr\:inset-x-68 {
    right: 6.8rem;
    left: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-72,[dir='ltr'].xl\:ltr\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-72,[dir='ltr'].xl\:ltr\:inset-x-72 {
    right: 7.2rem;
    left: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-76,[dir='ltr'].xl\:ltr\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-76,[dir='ltr'].xl\:ltr\:inset-x-76 {
    right: 7.6rem;
    left: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-80,[dir='ltr'].xl\:ltr\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-80,[dir='ltr'].xl\:ltr\:inset-x-80 {
    right: 8rem;
    left: 8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-84,[dir='ltr'].xl\:ltr\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-84,[dir='ltr'].xl\:ltr\:inset-x-84 {
    right: 8.4rem;
    left: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-88,[dir='ltr'].xl\:ltr\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-88,[dir='ltr'].xl\:ltr\:inset-x-88 {
    right: 8.8rem;
    left: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-92,[dir='ltr'].xl\:ltr\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-92,[dir='ltr'].xl\:ltr\:inset-x-92 {
    right: 9.2rem;
    left: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-96,[dir='ltr'].xl\:ltr\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-96,[dir='ltr'].xl\:ltr\:inset-x-96 {
    right: 9.6rem;
    left: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-128,[dir='ltr'].xl\:ltr\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-128,[dir='ltr'].xl\:ltr\:inset-x-128 {
    right: 12.8rem;
    left: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-136,[dir='ltr'].xl\:ltr\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-136,[dir='ltr'].xl\:ltr\:inset-x-136 {
    right: 13.6rem;
    left: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-160,[dir='ltr'].xl\:ltr\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-160,[dir='ltr'].xl\:ltr\:inset-x-160 {
    right: 16rem;
    left: 16rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-192,[dir='ltr'].xl\:ltr\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-192,[dir='ltr'].xl\:ltr\:inset-x-192 {
    right: 19.2rem;
    left: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-200,[dir='ltr'].xl\:ltr\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-200,[dir='ltr'].xl\:ltr\:inset-x-200 {
    right: 20rem;
    left: 20rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-208,[dir='ltr'].xl\:ltr\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-208,[dir='ltr'].xl\:ltr\:inset-x-208 {
    right: 20.8rem;
    left: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-216,[dir='ltr'].xl\:ltr\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-216,[dir='ltr'].xl\:ltr\:inset-x-216 {
    right: 21.6rem;
    left: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-224,[dir='ltr'].xl\:ltr\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-224,[dir='ltr'].xl\:ltr\:inset-x-224 {
    right: 22.4rem;
    left: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-256,[dir='ltr'].xl\:ltr\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-256,[dir='ltr'].xl\:ltr\:inset-x-256 {
    right: 25.6rem;
    left: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-288,[dir='ltr'].xl\:ltr\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-288,[dir='ltr'].xl\:ltr\:inset-x-288 {
    right: 28.8rem;
    left: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-320,[dir='ltr'].xl\:ltr\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-320,[dir='ltr'].xl\:ltr\:inset-x-320 {
    right: 32rem;
    left: 32rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-360,[dir='ltr'].xl\:ltr\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-360,[dir='ltr'].xl\:ltr\:inset-x-360 {
    right: 36rem;
    left: 36rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-384,[dir='ltr'].xl\:ltr\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-384,[dir='ltr'].xl\:ltr\:inset-x-384 {
    right: 38.4rem;
    left: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-400,[dir='ltr'].xl\:ltr\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-400,[dir='ltr'].xl\:ltr\:inset-x-400 {
    right: 40rem;
    left: 40rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-512,[dir='ltr'].xl\:ltr\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-512,[dir='ltr'].xl\:ltr\:inset-x-512 {
    right: 51.2rem;
    left: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-640,[dir='ltr'].xl\:ltr\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-640,[dir='ltr'].xl\:ltr\:inset-x-640 {
    right: 64rem;
    left: 64rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-auto,[dir='ltr'].xl\:ltr\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  [dir='ltr'] .xl\:ltr\:inset-x-auto,[dir='ltr'].xl\:ltr\:inset-x-auto {
    right: auto;
    left: auto
  }

  [dir='ltr'] .xl\:ltr\:inset-y-xs,[dir='ltr'].xl\:ltr\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-xs,[dir='ltr'].xl\:ltr\:inset-x-xs {
    right: 32rem;
    left: 32rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-sm,[dir='ltr'].xl\:ltr\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-sm,[dir='ltr'].xl\:ltr\:inset-x-sm {
    right: 48rem;
    left: 48rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-md,[dir='ltr'].xl\:ltr\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-md,[dir='ltr'].xl\:ltr\:inset-x-md {
    right: 64rem;
    left: 64rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-lg,[dir='ltr'].xl\:ltr\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-lg,[dir='ltr'].xl\:ltr\:inset-x-lg {
    right: 80rem;
    left: 80rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-xl,[dir='ltr'].xl\:ltr\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-xl,[dir='ltr'].xl\:ltr\:inset-x-xl {
    right: 96rem;
    left: 96rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-2xl,[dir='ltr'].xl\:ltr\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-2xl,[dir='ltr'].xl\:ltr\:inset-x-2xl {
    right: 112rem;
    left: 112rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-3xl,[dir='ltr'].xl\:ltr\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-3xl,[dir='ltr'].xl\:ltr\:inset-x-3xl {
    right: 128rem;
    left: 128rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-4xl,[dir='ltr'].xl\:ltr\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-4xl,[dir='ltr'].xl\:ltr\:inset-x-4xl {
    right: 144rem;
    left: 144rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-5xl,[dir='ltr'].xl\:ltr\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-5xl,[dir='ltr'].xl\:ltr\:inset-x-5xl {
    right: 160rem;
    left: 160rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-px,[dir='ltr'].xl\:ltr\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  [dir='ltr'] .xl\:ltr\:inset-x-px,[dir='ltr'].xl\:ltr\:inset-x-px {
    right: 1px;
    left: 1px
  }

  [dir='ltr'] .xl\:ltr\:inset-y-0\.5,[dir='ltr'].xl\:ltr\:inset-y-0\.5 {
    top: 0.05rem;
    bottom: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-0\.5,[dir='ltr'].xl\:ltr\:inset-x-0\.5 {
    right: 0.05rem;
    left: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-1\.5,[dir='ltr'].xl\:ltr\:inset-y-1\.5 {
    top: 0.15rem;
    bottom: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-1\.5,[dir='ltr'].xl\:ltr\:inset-x-1\.5 {
    right: 0.15rem;
    left: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-2\.5,[dir='ltr'].xl\:ltr\:inset-y-2\.5 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-2\.5,[dir='ltr'].xl\:ltr\:inset-x-2\.5 {
    right: 0.25rem;
    left: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-3\.5,[dir='ltr'].xl\:ltr\:inset-y-3\.5 {
    top: 0.35rem;
    bottom: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:inset-x-3\.5,[dir='ltr'].xl\:ltr\:inset-x-3\.5 {
    right: 0.35rem;
    left: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-1,[dir='ltr'].xl\:ltr\:-inset-y-1 {
    top: -0.1rem;
    bottom: -0.1rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-1,[dir='ltr'].xl\:ltr\:-inset-x-1 {
    right: -0.1rem;
    left: -0.1rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-2,[dir='ltr'].xl\:ltr\:-inset-y-2 {
    top: -0.2rem;
    bottom: -0.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-2,[dir='ltr'].xl\:ltr\:-inset-x-2 {
    right: -0.2rem;
    left: -0.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-3,[dir='ltr'].xl\:ltr\:-inset-y-3 {
    top: -0.3rem;
    bottom: -0.3rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-3,[dir='ltr'].xl\:ltr\:-inset-x-3 {
    right: -0.3rem;
    left: -0.3rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-4,[dir='ltr'].xl\:ltr\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-4,[dir='ltr'].xl\:ltr\:-inset-x-4 {
    right: -0.4rem;
    left: -0.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-5,[dir='ltr'].xl\:ltr\:-inset-y-5 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-5,[dir='ltr'].xl\:ltr\:-inset-x-5 {
    right: -0.5rem;
    left: -0.5rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-6,[dir='ltr'].xl\:ltr\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-6,[dir='ltr'].xl\:ltr\:-inset-x-6 {
    right: -0.6rem;
    left: -0.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-7,[dir='ltr'].xl\:ltr\:-inset-y-7 {
    top: -0.7rem;
    bottom: -0.7rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-7,[dir='ltr'].xl\:ltr\:-inset-x-7 {
    right: -0.7rem;
    left: -0.7rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-8,[dir='ltr'].xl\:ltr\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-8,[dir='ltr'].xl\:ltr\:-inset-x-8 {
    right: -0.8rem;
    left: -0.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-9,[dir='ltr'].xl\:ltr\:-inset-y-9 {
    top: -0.9rem;
    bottom: -0.9rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-9,[dir='ltr'].xl\:ltr\:-inset-x-9 {
    right: -0.9rem;
    left: -0.9rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-10,[dir='ltr'].xl\:ltr\:-inset-y-10 {
    top: -1rem;
    bottom: -1rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-10,[dir='ltr'].xl\:ltr\:-inset-x-10 {
    right: -1rem;
    left: -1rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-12,[dir='ltr'].xl\:ltr\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-12,[dir='ltr'].xl\:ltr\:-inset-x-12 {
    right: -1.2rem;
    left: -1.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-14,[dir='ltr'].xl\:ltr\:-inset-y-14 {
    top: -1.4rem;
    bottom: -1.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-14,[dir='ltr'].xl\:ltr\:-inset-x-14 {
    right: -1.4rem;
    left: -1.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-16,[dir='ltr'].xl\:ltr\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-16,[dir='ltr'].xl\:ltr\:-inset-x-16 {
    right: -1.6rem;
    left: -1.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-20,[dir='ltr'].xl\:ltr\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-20,[dir='ltr'].xl\:ltr\:-inset-x-20 {
    right: -2rem;
    left: -2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-24,[dir='ltr'].xl\:ltr\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-24,[dir='ltr'].xl\:ltr\:-inset-x-24 {
    right: -2.4rem;
    left: -2.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-28,[dir='ltr'].xl\:ltr\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-28,[dir='ltr'].xl\:ltr\:-inset-x-28 {
    right: -2.8rem;
    left: -2.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-32,[dir='ltr'].xl\:ltr\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-32,[dir='ltr'].xl\:ltr\:-inset-x-32 {
    right: -3.2rem;
    left: -3.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-36,[dir='ltr'].xl\:ltr\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-36,[dir='ltr'].xl\:ltr\:-inset-x-36 {
    right: -3.6rem;
    left: -3.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-40,[dir='ltr'].xl\:ltr\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-40,[dir='ltr'].xl\:ltr\:-inset-x-40 {
    right: -4rem;
    left: -4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-44,[dir='ltr'].xl\:ltr\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-44,[dir='ltr'].xl\:ltr\:-inset-x-44 {
    right: -4.4rem;
    left: -4.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-48,[dir='ltr'].xl\:ltr\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-48,[dir='ltr'].xl\:ltr\:-inset-x-48 {
    right: -4.8rem;
    left: -4.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-52,[dir='ltr'].xl\:ltr\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-52,[dir='ltr'].xl\:ltr\:-inset-x-52 {
    right: -5.2rem;
    left: -5.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-56,[dir='ltr'].xl\:ltr\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-56,[dir='ltr'].xl\:ltr\:-inset-x-56 {
    right: -5.6rem;
    left: -5.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-60,[dir='ltr'].xl\:ltr\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-60,[dir='ltr'].xl\:ltr\:-inset-x-60 {
    right: -6rem;
    left: -6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-64,[dir='ltr'].xl\:ltr\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-64,[dir='ltr'].xl\:ltr\:-inset-x-64 {
    right: -6.4rem;
    left: -6.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-68,[dir='ltr'].xl\:ltr\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-68,[dir='ltr'].xl\:ltr\:-inset-x-68 {
    right: -6.8rem;
    left: -6.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-72,[dir='ltr'].xl\:ltr\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-72,[dir='ltr'].xl\:ltr\:-inset-x-72 {
    right: -7.2rem;
    left: -7.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-76,[dir='ltr'].xl\:ltr\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-76,[dir='ltr'].xl\:ltr\:-inset-x-76 {
    right: -7.6rem;
    left: -7.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-80,[dir='ltr'].xl\:ltr\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-80,[dir='ltr'].xl\:ltr\:-inset-x-80 {
    right: -8rem;
    left: -8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-84,[dir='ltr'].xl\:ltr\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-84,[dir='ltr'].xl\:ltr\:-inset-x-84 {
    right: -8.4rem;
    left: -8.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-88,[dir='ltr'].xl\:ltr\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-88,[dir='ltr'].xl\:ltr\:-inset-x-88 {
    right: -8.8rem;
    left: -8.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-92,[dir='ltr'].xl\:ltr\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-92,[dir='ltr'].xl\:ltr\:-inset-x-92 {
    right: -9.2rem;
    left: -9.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-96,[dir='ltr'].xl\:ltr\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-96,[dir='ltr'].xl\:ltr\:-inset-x-96 {
    right: -9.6rem;
    left: -9.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-128,[dir='ltr'].xl\:ltr\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-128,[dir='ltr'].xl\:ltr\:-inset-x-128 {
    right: -12.8rem;
    left: -12.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-136,[dir='ltr'].xl\:ltr\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-136,[dir='ltr'].xl\:ltr\:-inset-x-136 {
    right: -13.6rem;
    left: -13.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-160,[dir='ltr'].xl\:ltr\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-160,[dir='ltr'].xl\:ltr\:-inset-x-160 {
    right: -16rem;
    left: -16rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-192,[dir='ltr'].xl\:ltr\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-192,[dir='ltr'].xl\:ltr\:-inset-x-192 {
    right: -19.2rem;
    left: -19.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-200,[dir='ltr'].xl\:ltr\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-200,[dir='ltr'].xl\:ltr\:-inset-x-200 {
    right: -20rem;
    left: -20rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-208,[dir='ltr'].xl\:ltr\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-208,[dir='ltr'].xl\:ltr\:-inset-x-208 {
    right: -20.8rem;
    left: -20.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-216,[dir='ltr'].xl\:ltr\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-216,[dir='ltr'].xl\:ltr\:-inset-x-216 {
    right: -21.6rem;
    left: -21.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-224,[dir='ltr'].xl\:ltr\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-224,[dir='ltr'].xl\:ltr\:-inset-x-224 {
    right: -22.4rem;
    left: -22.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-256,[dir='ltr'].xl\:ltr\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-256,[dir='ltr'].xl\:ltr\:-inset-x-256 {
    right: -25.6rem;
    left: -25.6rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-288,[dir='ltr'].xl\:ltr\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-288,[dir='ltr'].xl\:ltr\:-inset-x-288 {
    right: -28.8rem;
    left: -28.8rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-320,[dir='ltr'].xl\:ltr\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-320,[dir='ltr'].xl\:ltr\:-inset-x-320 {
    right: -32rem;
    left: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-360,[dir='ltr'].xl\:ltr\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-360,[dir='ltr'].xl\:ltr\:-inset-x-360 {
    right: -36rem;
    left: -36rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-384,[dir='ltr'].xl\:ltr\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-384,[dir='ltr'].xl\:ltr\:-inset-x-384 {
    right: -38.4rem;
    left: -38.4rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-400,[dir='ltr'].xl\:ltr\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-400,[dir='ltr'].xl\:ltr\:-inset-x-400 {
    right: -40rem;
    left: -40rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-512,[dir='ltr'].xl\:ltr\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-512,[dir='ltr'].xl\:ltr\:-inset-x-512 {
    right: -51.2rem;
    left: -51.2rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-640,[dir='ltr'].xl\:ltr\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-640,[dir='ltr'].xl\:ltr\:-inset-x-640 {
    right: -64rem;
    left: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-xs,[dir='ltr'].xl\:ltr\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-xs,[dir='ltr'].xl\:ltr\:-inset-x-xs {
    right: -32rem;
    left: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-sm,[dir='ltr'].xl\:ltr\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-sm,[dir='ltr'].xl\:ltr\:-inset-x-sm {
    right: -48rem;
    left: -48rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-md,[dir='ltr'].xl\:ltr\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-md,[dir='ltr'].xl\:ltr\:-inset-x-md {
    right: -64rem;
    left: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-lg,[dir='ltr'].xl\:ltr\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-lg,[dir='ltr'].xl\:ltr\:-inset-x-lg {
    right: -80rem;
    left: -80rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-xl,[dir='ltr'].xl\:ltr\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-xl,[dir='ltr'].xl\:ltr\:-inset-x-xl {
    right: -96rem;
    left: -96rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-2xl,[dir='ltr'].xl\:ltr\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-2xl,[dir='ltr'].xl\:ltr\:-inset-x-2xl {
    right: -112rem;
    left: -112rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-3xl,[dir='ltr'].xl\:ltr\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-3xl,[dir='ltr'].xl\:ltr\:-inset-x-3xl {
    right: -128rem;
    left: -128rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-4xl,[dir='ltr'].xl\:ltr\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-4xl,[dir='ltr'].xl\:ltr\:-inset-x-4xl {
    right: -144rem;
    left: -144rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-5xl,[dir='ltr'].xl\:ltr\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-5xl,[dir='ltr'].xl\:ltr\:-inset-x-5xl {
    right: -160rem;
    left: -160rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-px,[dir='ltr'].xl\:ltr\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-px,[dir='ltr'].xl\:ltr\:-inset-x-px {
    right: -1px;
    left: -1px
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-0\.5,[dir='ltr'].xl\:ltr\:-inset-y-0\.5 {
    top: -0.05rem;
    bottom: -0.05rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-0\.5,[dir='ltr'].xl\:ltr\:-inset-x-0\.5 {
    right: -0.05rem;
    left: -0.05rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-1\.5,[dir='ltr'].xl\:ltr\:-inset-y-1\.5 {
    top: -0.15rem;
    bottom: -0.15rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-1\.5,[dir='ltr'].xl\:ltr\:-inset-x-1\.5 {
    right: -0.15rem;
    left: -0.15rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-2\.5,[dir='ltr'].xl\:ltr\:-inset-y-2\.5 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-2\.5,[dir='ltr'].xl\:ltr\:-inset-x-2\.5 {
    right: -0.25rem;
    left: -0.25rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-3\.5,[dir='ltr'].xl\:ltr\:-inset-y-3\.5 {
    top: -0.35rem;
    bottom: -0.35rem
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-3\.5,[dir='ltr'].xl\:ltr\:-inset-x-3\.5 {
    right: -0.35rem;
    left: -0.35rem
  }

  [dir='ltr'] .xl\:ltr\:inset-y-1\/2,[dir='ltr'].xl\:ltr\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  [dir='ltr'] .xl\:ltr\:inset-x-1\/2,[dir='ltr'].xl\:ltr\:inset-x-1\/2 {
    right: 50%;
    left: 50%
  }

  [dir='ltr'] .xl\:ltr\:inset-y-1\/3,[dir='ltr'].xl\:ltr\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  [dir='ltr'] .xl\:ltr\:inset-x-1\/3,[dir='ltr'].xl\:ltr\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%
  }

  [dir='ltr'] .xl\:ltr\:inset-y-2\/3,[dir='ltr'].xl\:ltr\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  [dir='ltr'] .xl\:ltr\:inset-x-2\/3,[dir='ltr'].xl\:ltr\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%
  }

  [dir='ltr'] .xl\:ltr\:inset-y-1\/4,[dir='ltr'].xl\:ltr\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  [dir='ltr'] .xl\:ltr\:inset-x-1\/4,[dir='ltr'].xl\:ltr\:inset-x-1\/4 {
    right: 25%;
    left: 25%
  }

  [dir='ltr'] .xl\:ltr\:inset-y-2\/4,[dir='ltr'].xl\:ltr\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  [dir='ltr'] .xl\:ltr\:inset-x-2\/4,[dir='ltr'].xl\:ltr\:inset-x-2\/4 {
    right: 50%;
    left: 50%
  }

  [dir='ltr'] .xl\:ltr\:inset-y-3\/4,[dir='ltr'].xl\:ltr\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  [dir='ltr'] .xl\:ltr\:inset-x-3\/4,[dir='ltr'].xl\:ltr\:inset-x-3\/4 {
    right: 75%;
    left: 75%
  }

  [dir='ltr'] .xl\:ltr\:inset-y-full,[dir='ltr'].xl\:ltr\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  [dir='ltr'] .xl\:ltr\:inset-x-full,[dir='ltr'].xl\:ltr\:inset-x-full {
    right: 100%;
    left: 100%
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-1\/2,[dir='ltr'].xl\:ltr\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-1\/2,[dir='ltr'].xl\:ltr\:-inset-x-1\/2 {
    right: -50%;
    left: -50%
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-1\/3,[dir='ltr'].xl\:ltr\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-1\/3,[dir='ltr'].xl\:ltr\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-2\/3,[dir='ltr'].xl\:ltr\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-2\/3,[dir='ltr'].xl\:ltr\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-1\/4,[dir='ltr'].xl\:ltr\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-1\/4,[dir='ltr'].xl\:ltr\:-inset-x-1\/4 {
    right: -25%;
    left: -25%
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-2\/4,[dir='ltr'].xl\:ltr\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-2\/4,[dir='ltr'].xl\:ltr\:-inset-x-2\/4 {
    right: -50%;
    left: -50%
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-3\/4,[dir='ltr'].xl\:ltr\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-3\/4,[dir='ltr'].xl\:ltr\:-inset-x-3\/4 {
    right: -75%;
    left: -75%
  }

  [dir='ltr'] .xl\:ltr\:-inset-y-full,[dir='ltr'].xl\:ltr\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  [dir='ltr'] .xl\:ltr\:-inset-x-full,[dir='ltr'].xl\:ltr\:-inset-x-full {
    right: -100%;
    left: -100%
  }

  [dir='ltr'] .xl\:ltr\:top-0,[dir='ltr'].xl\:ltr\:top-0 {
    top: 0
  }

  [dir='ltr'] .xl\:ltr\:right-0,[dir='ltr'].xl\:ltr\:right-0 {
    right: 0
  }

  [dir='ltr'] .xl\:ltr\:bottom-0,[dir='ltr'].xl\:ltr\:bottom-0 {
    bottom: 0
  }

  [dir='ltr'] .xl\:ltr\:left-0,[dir='ltr'].xl\:ltr\:left-0 {
    left: 0
  }

  [dir='ltr'] .xl\:ltr\:top-1,[dir='ltr'].xl\:ltr\:top-1 {
    top: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:right-1,[dir='ltr'].xl\:ltr\:right-1 {
    right: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-1,[dir='ltr'].xl\:ltr\:bottom-1 {
    bottom: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:left-1,[dir='ltr'].xl\:ltr\:left-1 {
    left: 0.1rem
  }

  [dir='ltr'] .xl\:ltr\:top-2,[dir='ltr'].xl\:ltr\:top-2 {
    top: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:right-2,[dir='ltr'].xl\:ltr\:right-2 {
    right: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-2,[dir='ltr'].xl\:ltr\:bottom-2 {
    bottom: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:left-2,[dir='ltr'].xl\:ltr\:left-2 {
    left: 0.2rem
  }

  [dir='ltr'] .xl\:ltr\:top-3,[dir='ltr'].xl\:ltr\:top-3 {
    top: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:right-3,[dir='ltr'].xl\:ltr\:right-3 {
    right: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-3,[dir='ltr'].xl\:ltr\:bottom-3 {
    bottom: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:left-3,[dir='ltr'].xl\:ltr\:left-3 {
    left: 0.3rem
  }

  [dir='ltr'] .xl\:ltr\:top-4,[dir='ltr'].xl\:ltr\:top-4 {
    top: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:right-4,[dir='ltr'].xl\:ltr\:right-4 {
    right: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-4,[dir='ltr'].xl\:ltr\:bottom-4 {
    bottom: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:left-4,[dir='ltr'].xl\:ltr\:left-4 {
    left: 0.4rem
  }

  [dir='ltr'] .xl\:ltr\:top-5,[dir='ltr'].xl\:ltr\:top-5 {
    top: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:right-5,[dir='ltr'].xl\:ltr\:right-5 {
    right: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-5,[dir='ltr'].xl\:ltr\:bottom-5 {
    bottom: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:left-5,[dir='ltr'].xl\:ltr\:left-5 {
    left: 0.5rem
  }

  [dir='ltr'] .xl\:ltr\:top-6,[dir='ltr'].xl\:ltr\:top-6 {
    top: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:right-6,[dir='ltr'].xl\:ltr\:right-6 {
    right: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-6,[dir='ltr'].xl\:ltr\:bottom-6 {
    bottom: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:left-6,[dir='ltr'].xl\:ltr\:left-6 {
    left: 0.6rem
  }

  [dir='ltr'] .xl\:ltr\:top-7,[dir='ltr'].xl\:ltr\:top-7 {
    top: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:right-7,[dir='ltr'].xl\:ltr\:right-7 {
    right: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-7,[dir='ltr'].xl\:ltr\:bottom-7 {
    bottom: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:left-7,[dir='ltr'].xl\:ltr\:left-7 {
    left: 0.7rem
  }

  [dir='ltr'] .xl\:ltr\:top-8,[dir='ltr'].xl\:ltr\:top-8 {
    top: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:right-8,[dir='ltr'].xl\:ltr\:right-8 {
    right: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-8,[dir='ltr'].xl\:ltr\:bottom-8 {
    bottom: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:left-8,[dir='ltr'].xl\:ltr\:left-8 {
    left: 0.8rem
  }

  [dir='ltr'] .xl\:ltr\:top-9,[dir='ltr'].xl\:ltr\:top-9 {
    top: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:right-9,[dir='ltr'].xl\:ltr\:right-9 {
    right: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-9,[dir='ltr'].xl\:ltr\:bottom-9 {
    bottom: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:left-9,[dir='ltr'].xl\:ltr\:left-9 {
    left: 0.9rem
  }

  [dir='ltr'] .xl\:ltr\:top-10,[dir='ltr'].xl\:ltr\:top-10 {
    top: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:right-10,[dir='ltr'].xl\:ltr\:right-10 {
    right: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-10,[dir='ltr'].xl\:ltr\:bottom-10 {
    bottom: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:left-10,[dir='ltr'].xl\:ltr\:left-10 {
    left: 1.0rem
  }

  [dir='ltr'] .xl\:ltr\:top-12,[dir='ltr'].xl\:ltr\:top-12 {
    top: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:right-12,[dir='ltr'].xl\:ltr\:right-12 {
    right: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-12,[dir='ltr'].xl\:ltr\:bottom-12 {
    bottom: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:left-12,[dir='ltr'].xl\:ltr\:left-12 {
    left: 1.2rem
  }

  [dir='ltr'] .xl\:ltr\:top-14,[dir='ltr'].xl\:ltr\:top-14 {
    top: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:right-14,[dir='ltr'].xl\:ltr\:right-14 {
    right: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-14,[dir='ltr'].xl\:ltr\:bottom-14 {
    bottom: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:left-14,[dir='ltr'].xl\:ltr\:left-14 {
    left: 1.4rem
  }

  [dir='ltr'] .xl\:ltr\:top-16,[dir='ltr'].xl\:ltr\:top-16 {
    top: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:right-16,[dir='ltr'].xl\:ltr\:right-16 {
    right: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-16,[dir='ltr'].xl\:ltr\:bottom-16 {
    bottom: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:left-16,[dir='ltr'].xl\:ltr\:left-16 {
    left: 1.6rem
  }

  [dir='ltr'] .xl\:ltr\:top-20,[dir='ltr'].xl\:ltr\:top-20 {
    top: 2rem
  }

  [dir='ltr'] .xl\:ltr\:right-20,[dir='ltr'].xl\:ltr\:right-20 {
    right: 2rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-20,[dir='ltr'].xl\:ltr\:bottom-20 {
    bottom: 2rem
  }

  [dir='ltr'] .xl\:ltr\:left-20,[dir='ltr'].xl\:ltr\:left-20 {
    left: 2rem
  }

  [dir='ltr'] .xl\:ltr\:top-24,[dir='ltr'].xl\:ltr\:top-24 {
    top: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:right-24,[dir='ltr'].xl\:ltr\:right-24 {
    right: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-24,[dir='ltr'].xl\:ltr\:bottom-24 {
    bottom: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:left-24,[dir='ltr'].xl\:ltr\:left-24 {
    left: 2.4rem
  }

  [dir='ltr'] .xl\:ltr\:top-28,[dir='ltr'].xl\:ltr\:top-28 {
    top: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:right-28,[dir='ltr'].xl\:ltr\:right-28 {
    right: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-28,[dir='ltr'].xl\:ltr\:bottom-28 {
    bottom: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:left-28,[dir='ltr'].xl\:ltr\:left-28 {
    left: 2.8rem
  }

  [dir='ltr'] .xl\:ltr\:top-32,[dir='ltr'].xl\:ltr\:top-32 {
    top: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:right-32,[dir='ltr'].xl\:ltr\:right-32 {
    right: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-32,[dir='ltr'].xl\:ltr\:bottom-32 {
    bottom: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:left-32,[dir='ltr'].xl\:ltr\:left-32 {
    left: 3.2rem
  }

  [dir='ltr'] .xl\:ltr\:top-36,[dir='ltr'].xl\:ltr\:top-36 {
    top: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:right-36,[dir='ltr'].xl\:ltr\:right-36 {
    right: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-36,[dir='ltr'].xl\:ltr\:bottom-36 {
    bottom: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:left-36,[dir='ltr'].xl\:ltr\:left-36 {
    left: 3.6rem
  }

  [dir='ltr'] .xl\:ltr\:top-40,[dir='ltr'].xl\:ltr\:top-40 {
    top: 4rem
  }

  [dir='ltr'] .xl\:ltr\:right-40,[dir='ltr'].xl\:ltr\:right-40 {
    right: 4rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-40,[dir='ltr'].xl\:ltr\:bottom-40 {
    bottom: 4rem
  }

  [dir='ltr'] .xl\:ltr\:left-40,[dir='ltr'].xl\:ltr\:left-40 {
    left: 4rem
  }

  [dir='ltr'] .xl\:ltr\:top-44,[dir='ltr'].xl\:ltr\:top-44 {
    top: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:right-44,[dir='ltr'].xl\:ltr\:right-44 {
    right: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-44,[dir='ltr'].xl\:ltr\:bottom-44 {
    bottom: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:left-44,[dir='ltr'].xl\:ltr\:left-44 {
    left: 4.4rem
  }

  [dir='ltr'] .xl\:ltr\:top-48,[dir='ltr'].xl\:ltr\:top-48 {
    top: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:right-48,[dir='ltr'].xl\:ltr\:right-48 {
    right: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-48,[dir='ltr'].xl\:ltr\:bottom-48 {
    bottom: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:left-48,[dir='ltr'].xl\:ltr\:left-48 {
    left: 4.8rem
  }

  [dir='ltr'] .xl\:ltr\:top-52,[dir='ltr'].xl\:ltr\:top-52 {
    top: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:right-52,[dir='ltr'].xl\:ltr\:right-52 {
    right: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-52,[dir='ltr'].xl\:ltr\:bottom-52 {
    bottom: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:left-52,[dir='ltr'].xl\:ltr\:left-52 {
    left: 5.2rem
  }

  [dir='ltr'] .xl\:ltr\:top-56,[dir='ltr'].xl\:ltr\:top-56 {
    top: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:right-56,[dir='ltr'].xl\:ltr\:right-56 {
    right: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-56,[dir='ltr'].xl\:ltr\:bottom-56 {
    bottom: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:left-56,[dir='ltr'].xl\:ltr\:left-56 {
    left: 5.6rem
  }

  [dir='ltr'] .xl\:ltr\:top-60,[dir='ltr'].xl\:ltr\:top-60 {
    top: 6rem
  }

  [dir='ltr'] .xl\:ltr\:right-60,[dir='ltr'].xl\:ltr\:right-60 {
    right: 6rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-60,[dir='ltr'].xl\:ltr\:bottom-60 {
    bottom: 6rem
  }

  [dir='ltr'] .xl\:ltr\:left-60,[dir='ltr'].xl\:ltr\:left-60 {
    left: 6rem
  }

  [dir='ltr'] .xl\:ltr\:top-64,[dir='ltr'].xl\:ltr\:top-64 {
    top: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:right-64,[dir='ltr'].xl\:ltr\:right-64 {
    right: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-64,[dir='ltr'].xl\:ltr\:bottom-64 {
    bottom: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:left-64,[dir='ltr'].xl\:ltr\:left-64 {
    left: 6.4rem
  }

  [dir='ltr'] .xl\:ltr\:top-68,[dir='ltr'].xl\:ltr\:top-68 {
    top: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:right-68,[dir='ltr'].xl\:ltr\:right-68 {
    right: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-68,[dir='ltr'].xl\:ltr\:bottom-68 {
    bottom: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:left-68,[dir='ltr'].xl\:ltr\:left-68 {
    left: 6.8rem
  }

  [dir='ltr'] .xl\:ltr\:top-72,[dir='ltr'].xl\:ltr\:top-72 {
    top: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:right-72,[dir='ltr'].xl\:ltr\:right-72 {
    right: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-72,[dir='ltr'].xl\:ltr\:bottom-72 {
    bottom: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:left-72,[dir='ltr'].xl\:ltr\:left-72 {
    left: 7.2rem
  }

  [dir='ltr'] .xl\:ltr\:top-76,[dir='ltr'].xl\:ltr\:top-76 {
    top: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:right-76,[dir='ltr'].xl\:ltr\:right-76 {
    right: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-76,[dir='ltr'].xl\:ltr\:bottom-76 {
    bottom: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:left-76,[dir='ltr'].xl\:ltr\:left-76 {
    left: 7.6rem
  }

  [dir='ltr'] .xl\:ltr\:top-80,[dir='ltr'].xl\:ltr\:top-80 {
    top: 8rem
  }

  [dir='ltr'] .xl\:ltr\:right-80,[dir='ltr'].xl\:ltr\:right-80 {
    right: 8rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-80,[dir='ltr'].xl\:ltr\:bottom-80 {
    bottom: 8rem
  }

  [dir='ltr'] .xl\:ltr\:left-80,[dir='ltr'].xl\:ltr\:left-80 {
    left: 8rem
  }

  [dir='ltr'] .xl\:ltr\:top-84,[dir='ltr'].xl\:ltr\:top-84 {
    top: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:right-84,[dir='ltr'].xl\:ltr\:right-84 {
    right: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-84,[dir='ltr'].xl\:ltr\:bottom-84 {
    bottom: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:left-84,[dir='ltr'].xl\:ltr\:left-84 {
    left: 8.4rem
  }

  [dir='ltr'] .xl\:ltr\:top-88,[dir='ltr'].xl\:ltr\:top-88 {
    top: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:right-88,[dir='ltr'].xl\:ltr\:right-88 {
    right: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-88,[dir='ltr'].xl\:ltr\:bottom-88 {
    bottom: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:left-88,[dir='ltr'].xl\:ltr\:left-88 {
    left: 8.8rem
  }

  [dir='ltr'] .xl\:ltr\:top-92,[dir='ltr'].xl\:ltr\:top-92 {
    top: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:right-92,[dir='ltr'].xl\:ltr\:right-92 {
    right: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-92,[dir='ltr'].xl\:ltr\:bottom-92 {
    bottom: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:left-92,[dir='ltr'].xl\:ltr\:left-92 {
    left: 9.2rem
  }

  [dir='ltr'] .xl\:ltr\:top-96,[dir='ltr'].xl\:ltr\:top-96 {
    top: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:right-96,[dir='ltr'].xl\:ltr\:right-96 {
    right: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-96,[dir='ltr'].xl\:ltr\:bottom-96 {
    bottom: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:left-96,[dir='ltr'].xl\:ltr\:left-96 {
    left: 9.6rem
  }

  [dir='ltr'] .xl\:ltr\:top-128,[dir='ltr'].xl\:ltr\:top-128 {
    top: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:right-128,[dir='ltr'].xl\:ltr\:right-128 {
    right: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-128,[dir='ltr'].xl\:ltr\:bottom-128 {
    bottom: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:left-128,[dir='ltr'].xl\:ltr\:left-128 {
    left: 12.8rem
  }

  [dir='ltr'] .xl\:ltr\:top-136,[dir='ltr'].xl\:ltr\:top-136 {
    top: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:right-136,[dir='ltr'].xl\:ltr\:right-136 {
    right: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-136,[dir='ltr'].xl\:ltr\:bottom-136 {
    bottom: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:left-136,[dir='ltr'].xl\:ltr\:left-136 {
    left: 13.6rem
  }

  [dir='ltr'] .xl\:ltr\:top-160,[dir='ltr'].xl\:ltr\:top-160 {
    top: 16rem
  }

  [dir='ltr'] .xl\:ltr\:right-160,[dir='ltr'].xl\:ltr\:right-160 {
    right: 16rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-160,[dir='ltr'].xl\:ltr\:bottom-160 {
    bottom: 16rem
  }

  [dir='ltr'] .xl\:ltr\:left-160,[dir='ltr'].xl\:ltr\:left-160 {
    left: 16rem
  }

  [dir='ltr'] .xl\:ltr\:top-192,[dir='ltr'].xl\:ltr\:top-192 {
    top: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:right-192,[dir='ltr'].xl\:ltr\:right-192 {
    right: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-192,[dir='ltr'].xl\:ltr\:bottom-192 {
    bottom: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:left-192,[dir='ltr'].xl\:ltr\:left-192 {
    left: 19.2rem
  }

  [dir='ltr'] .xl\:ltr\:top-200,[dir='ltr'].xl\:ltr\:top-200 {
    top: 20rem
  }

  [dir='ltr'] .xl\:ltr\:right-200,[dir='ltr'].xl\:ltr\:right-200 {
    right: 20rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-200,[dir='ltr'].xl\:ltr\:bottom-200 {
    bottom: 20rem
  }

  [dir='ltr'] .xl\:ltr\:left-200,[dir='ltr'].xl\:ltr\:left-200 {
    left: 20rem
  }

  [dir='ltr'] .xl\:ltr\:top-208,[dir='ltr'].xl\:ltr\:top-208 {
    top: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:right-208,[dir='ltr'].xl\:ltr\:right-208 {
    right: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-208,[dir='ltr'].xl\:ltr\:bottom-208 {
    bottom: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:left-208,[dir='ltr'].xl\:ltr\:left-208 {
    left: 20.8rem
  }

  [dir='ltr'] .xl\:ltr\:top-216,[dir='ltr'].xl\:ltr\:top-216 {
    top: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:right-216,[dir='ltr'].xl\:ltr\:right-216 {
    right: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-216,[dir='ltr'].xl\:ltr\:bottom-216 {
    bottom: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:left-216,[dir='ltr'].xl\:ltr\:left-216 {
    left: 21.6rem
  }

  [dir='ltr'] .xl\:ltr\:top-224,[dir='ltr'].xl\:ltr\:top-224 {
    top: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:right-224,[dir='ltr'].xl\:ltr\:right-224 {
    right: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-224,[dir='ltr'].xl\:ltr\:bottom-224 {
    bottom: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:left-224,[dir='ltr'].xl\:ltr\:left-224 {
    left: 22.4rem
  }

  [dir='ltr'] .xl\:ltr\:top-256,[dir='ltr'].xl\:ltr\:top-256 {
    top: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:right-256,[dir='ltr'].xl\:ltr\:right-256 {
    right: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-256,[dir='ltr'].xl\:ltr\:bottom-256 {
    bottom: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:left-256,[dir='ltr'].xl\:ltr\:left-256 {
    left: 25.6rem
  }

  [dir='ltr'] .xl\:ltr\:top-288,[dir='ltr'].xl\:ltr\:top-288 {
    top: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:right-288,[dir='ltr'].xl\:ltr\:right-288 {
    right: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-288,[dir='ltr'].xl\:ltr\:bottom-288 {
    bottom: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:left-288,[dir='ltr'].xl\:ltr\:left-288 {
    left: 28.8rem
  }

  [dir='ltr'] .xl\:ltr\:top-320,[dir='ltr'].xl\:ltr\:top-320 {
    top: 32rem
  }

  [dir='ltr'] .xl\:ltr\:right-320,[dir='ltr'].xl\:ltr\:right-320 {
    right: 32rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-320,[dir='ltr'].xl\:ltr\:bottom-320 {
    bottom: 32rem
  }

  [dir='ltr'] .xl\:ltr\:left-320,[dir='ltr'].xl\:ltr\:left-320 {
    left: 32rem
  }

  [dir='ltr'] .xl\:ltr\:top-360,[dir='ltr'].xl\:ltr\:top-360 {
    top: 36rem
  }

  [dir='ltr'] .xl\:ltr\:right-360,[dir='ltr'].xl\:ltr\:right-360 {
    right: 36rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-360,[dir='ltr'].xl\:ltr\:bottom-360 {
    bottom: 36rem
  }

  [dir='ltr'] .xl\:ltr\:left-360,[dir='ltr'].xl\:ltr\:left-360 {
    left: 36rem
  }

  [dir='ltr'] .xl\:ltr\:top-384,[dir='ltr'].xl\:ltr\:top-384 {
    top: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:right-384,[dir='ltr'].xl\:ltr\:right-384 {
    right: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-384,[dir='ltr'].xl\:ltr\:bottom-384 {
    bottom: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:left-384,[dir='ltr'].xl\:ltr\:left-384 {
    left: 38.4rem
  }

  [dir='ltr'] .xl\:ltr\:top-400,[dir='ltr'].xl\:ltr\:top-400 {
    top: 40rem
  }

  [dir='ltr'] .xl\:ltr\:right-400,[dir='ltr'].xl\:ltr\:right-400 {
    right: 40rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-400,[dir='ltr'].xl\:ltr\:bottom-400 {
    bottom: 40rem
  }

  [dir='ltr'] .xl\:ltr\:left-400,[dir='ltr'].xl\:ltr\:left-400 {
    left: 40rem
  }

  [dir='ltr'] .xl\:ltr\:top-512,[dir='ltr'].xl\:ltr\:top-512 {
    top: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:right-512,[dir='ltr'].xl\:ltr\:right-512 {
    right: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-512,[dir='ltr'].xl\:ltr\:bottom-512 {
    bottom: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:left-512,[dir='ltr'].xl\:ltr\:left-512 {
    left: 51.2rem
  }

  [dir='ltr'] .xl\:ltr\:top-640,[dir='ltr'].xl\:ltr\:top-640 {
    top: 64rem
  }

  [dir='ltr'] .xl\:ltr\:right-640,[dir='ltr'].xl\:ltr\:right-640 {
    right: 64rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-640,[dir='ltr'].xl\:ltr\:bottom-640 {
    bottom: 64rem
  }

  [dir='ltr'] .xl\:ltr\:left-640,[dir='ltr'].xl\:ltr\:left-640 {
    left: 64rem
  }

  [dir='ltr'] .xl\:ltr\:top-auto,[dir='ltr'].xl\:ltr\:top-auto {
    top: auto
  }

  [dir='ltr'] .xl\:ltr\:right-auto,[dir='ltr'].xl\:ltr\:right-auto {
    right: auto
  }

  [dir='ltr'] .xl\:ltr\:bottom-auto,[dir='ltr'].xl\:ltr\:bottom-auto {
    bottom: auto
  }

  [dir='ltr'] .xl\:ltr\:left-auto,[dir='ltr'].xl\:ltr\:left-auto {
    left: auto
  }

  [dir='ltr'] .xl\:ltr\:top-xs,[dir='ltr'].xl\:ltr\:top-xs {
    top: 32rem
  }

  [dir='ltr'] .xl\:ltr\:right-xs,[dir='ltr'].xl\:ltr\:right-xs {
    right: 32rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-xs,[dir='ltr'].xl\:ltr\:bottom-xs {
    bottom: 32rem
  }

  [dir='ltr'] .xl\:ltr\:left-xs,[dir='ltr'].xl\:ltr\:left-xs {
    left: 32rem
  }

  [dir='ltr'] .xl\:ltr\:top-sm,[dir='ltr'].xl\:ltr\:top-sm {
    top: 48rem
  }

  [dir='ltr'] .xl\:ltr\:right-sm,[dir='ltr'].xl\:ltr\:right-sm {
    right: 48rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-sm,[dir='ltr'].xl\:ltr\:bottom-sm {
    bottom: 48rem
  }

  [dir='ltr'] .xl\:ltr\:left-sm,[dir='ltr'].xl\:ltr\:left-sm {
    left: 48rem
  }

  [dir='ltr'] .xl\:ltr\:top-md,[dir='ltr'].xl\:ltr\:top-md {
    top: 64rem
  }

  [dir='ltr'] .xl\:ltr\:right-md,[dir='ltr'].xl\:ltr\:right-md {
    right: 64rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-md,[dir='ltr'].xl\:ltr\:bottom-md {
    bottom: 64rem
  }

  [dir='ltr'] .xl\:ltr\:left-md,[dir='ltr'].xl\:ltr\:left-md {
    left: 64rem
  }

  [dir='ltr'] .xl\:ltr\:top-lg,[dir='ltr'].xl\:ltr\:top-lg {
    top: 80rem
  }

  [dir='ltr'] .xl\:ltr\:right-lg,[dir='ltr'].xl\:ltr\:right-lg {
    right: 80rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-lg,[dir='ltr'].xl\:ltr\:bottom-lg {
    bottom: 80rem
  }

  [dir='ltr'] .xl\:ltr\:left-lg,[dir='ltr'].xl\:ltr\:left-lg {
    left: 80rem
  }

  [dir='ltr'] .xl\:ltr\:top-xl,[dir='ltr'].xl\:ltr\:top-xl {
    top: 96rem
  }

  [dir='ltr'] .xl\:ltr\:right-xl,[dir='ltr'].xl\:ltr\:right-xl {
    right: 96rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-xl,[dir='ltr'].xl\:ltr\:bottom-xl {
    bottom: 96rem
  }

  [dir='ltr'] .xl\:ltr\:left-xl,[dir='ltr'].xl\:ltr\:left-xl {
    left: 96rem
  }

  [dir='ltr'] .xl\:ltr\:top-2xl,[dir='ltr'].xl\:ltr\:top-2xl {
    top: 112rem
  }

  [dir='ltr'] .xl\:ltr\:right-2xl,[dir='ltr'].xl\:ltr\:right-2xl {
    right: 112rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-2xl,[dir='ltr'].xl\:ltr\:bottom-2xl {
    bottom: 112rem
  }

  [dir='ltr'] .xl\:ltr\:left-2xl,[dir='ltr'].xl\:ltr\:left-2xl {
    left: 112rem
  }

  [dir='ltr'] .xl\:ltr\:top-3xl,[dir='ltr'].xl\:ltr\:top-3xl {
    top: 128rem
  }

  [dir='ltr'] .xl\:ltr\:right-3xl,[dir='ltr'].xl\:ltr\:right-3xl {
    right: 128rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-3xl,[dir='ltr'].xl\:ltr\:bottom-3xl {
    bottom: 128rem
  }

  [dir='ltr'] .xl\:ltr\:left-3xl,[dir='ltr'].xl\:ltr\:left-3xl {
    left: 128rem
  }

  [dir='ltr'] .xl\:ltr\:top-4xl,[dir='ltr'].xl\:ltr\:top-4xl {
    top: 144rem
  }

  [dir='ltr'] .xl\:ltr\:right-4xl,[dir='ltr'].xl\:ltr\:right-4xl {
    right: 144rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-4xl,[dir='ltr'].xl\:ltr\:bottom-4xl {
    bottom: 144rem
  }

  [dir='ltr'] .xl\:ltr\:left-4xl,[dir='ltr'].xl\:ltr\:left-4xl {
    left: 144rem
  }

  [dir='ltr'] .xl\:ltr\:top-5xl,[dir='ltr'].xl\:ltr\:top-5xl {
    top: 160rem
  }

  [dir='ltr'] .xl\:ltr\:right-5xl,[dir='ltr'].xl\:ltr\:right-5xl {
    right: 160rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-5xl,[dir='ltr'].xl\:ltr\:bottom-5xl {
    bottom: 160rem
  }

  [dir='ltr'] .xl\:ltr\:left-5xl,[dir='ltr'].xl\:ltr\:left-5xl {
    left: 160rem
  }

  [dir='ltr'] .xl\:ltr\:top-px,[dir='ltr'].xl\:ltr\:top-px {
    top: 1px
  }

  [dir='ltr'] .xl\:ltr\:right-px,[dir='ltr'].xl\:ltr\:right-px {
    right: 1px
  }

  [dir='ltr'] .xl\:ltr\:bottom-px,[dir='ltr'].xl\:ltr\:bottom-px {
    bottom: 1px
  }

  [dir='ltr'] .xl\:ltr\:left-px,[dir='ltr'].xl\:ltr\:left-px {
    left: 1px
  }

  [dir='ltr'] .xl\:ltr\:top-0\.5,[dir='ltr'].xl\:ltr\:top-0\.5 {
    top: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:right-0\.5,[dir='ltr'].xl\:ltr\:right-0\.5 {
    right: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-0\.5,[dir='ltr'].xl\:ltr\:bottom-0\.5 {
    bottom: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:left-0\.5,[dir='ltr'].xl\:ltr\:left-0\.5 {
    left: 0.05rem
  }

  [dir='ltr'] .xl\:ltr\:top-1\.5,[dir='ltr'].xl\:ltr\:top-1\.5 {
    top: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:right-1\.5,[dir='ltr'].xl\:ltr\:right-1\.5 {
    right: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-1\.5,[dir='ltr'].xl\:ltr\:bottom-1\.5 {
    bottom: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:left-1\.5,[dir='ltr'].xl\:ltr\:left-1\.5 {
    left: 0.15rem
  }

  [dir='ltr'] .xl\:ltr\:top-2\.5,[dir='ltr'].xl\:ltr\:top-2\.5 {
    top: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:right-2\.5,[dir='ltr'].xl\:ltr\:right-2\.5 {
    right: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-2\.5,[dir='ltr'].xl\:ltr\:bottom-2\.5 {
    bottom: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:left-2\.5,[dir='ltr'].xl\:ltr\:left-2\.5 {
    left: 0.25rem
  }

  [dir='ltr'] .xl\:ltr\:top-3\.5,[dir='ltr'].xl\:ltr\:top-3\.5 {
    top: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:right-3\.5,[dir='ltr'].xl\:ltr\:right-3\.5 {
    right: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:bottom-3\.5,[dir='ltr'].xl\:ltr\:bottom-3\.5 {
    bottom: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:left-3\.5,[dir='ltr'].xl\:ltr\:left-3\.5 {
    left: 0.35rem
  }

  [dir='ltr'] .xl\:ltr\:-top-1,[dir='ltr'].xl\:ltr\:-top-1 {
    top: -0.1rem
  }

  [dir='ltr'] .xl\:ltr\:-right-1,[dir='ltr'].xl\:ltr\:-right-1 {
    right: -0.1rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-1,[dir='ltr'].xl\:ltr\:-bottom-1 {
    bottom: -0.1rem
  }

  [dir='ltr'] .xl\:ltr\:-left-1,[dir='ltr'].xl\:ltr\:-left-1 {
    left: -0.1rem
  }

  [dir='ltr'] .xl\:ltr\:-top-2,[dir='ltr'].xl\:ltr\:-top-2 {
    top: -0.2rem
  }

  [dir='ltr'] .xl\:ltr\:-right-2,[dir='ltr'].xl\:ltr\:-right-2 {
    right: -0.2rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-2,[dir='ltr'].xl\:ltr\:-bottom-2 {
    bottom: -0.2rem
  }

  [dir='ltr'] .xl\:ltr\:-left-2,[dir='ltr'].xl\:ltr\:-left-2 {
    left: -0.2rem
  }

  [dir='ltr'] .xl\:ltr\:-top-3,[dir='ltr'].xl\:ltr\:-top-3 {
    top: -0.3rem
  }

  [dir='ltr'] .xl\:ltr\:-right-3,[dir='ltr'].xl\:ltr\:-right-3 {
    right: -0.3rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-3,[dir='ltr'].xl\:ltr\:-bottom-3 {
    bottom: -0.3rem
  }

  [dir='ltr'] .xl\:ltr\:-left-3,[dir='ltr'].xl\:ltr\:-left-3 {
    left: -0.3rem
  }

  [dir='ltr'] .xl\:ltr\:-top-4,[dir='ltr'].xl\:ltr\:-top-4 {
    top: -0.4rem
  }

  [dir='ltr'] .xl\:ltr\:-right-4,[dir='ltr'].xl\:ltr\:-right-4 {
    right: -0.4rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-4,[dir='ltr'].xl\:ltr\:-bottom-4 {
    bottom: -0.4rem
  }

  [dir='ltr'] .xl\:ltr\:-left-4,[dir='ltr'].xl\:ltr\:-left-4 {
    left: -0.4rem
  }

  [dir='ltr'] .xl\:ltr\:-top-5,[dir='ltr'].xl\:ltr\:-top-5 {
    top: -0.5rem
  }

  [dir='ltr'] .xl\:ltr\:-right-5,[dir='ltr'].xl\:ltr\:-right-5 {
    right: -0.5rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-5,[dir='ltr'].xl\:ltr\:-bottom-5 {
    bottom: -0.5rem
  }

  [dir='ltr'] .xl\:ltr\:-left-5,[dir='ltr'].xl\:ltr\:-left-5 {
    left: -0.5rem
  }

  [dir='ltr'] .xl\:ltr\:-top-6,[dir='ltr'].xl\:ltr\:-top-6 {
    top: -0.6rem
  }

  [dir='ltr'] .xl\:ltr\:-right-6,[dir='ltr'].xl\:ltr\:-right-6 {
    right: -0.6rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-6,[dir='ltr'].xl\:ltr\:-bottom-6 {
    bottom: -0.6rem
  }

  [dir='ltr'] .xl\:ltr\:-left-6,[dir='ltr'].xl\:ltr\:-left-6 {
    left: -0.6rem
  }

  [dir='ltr'] .xl\:ltr\:-top-7,[dir='ltr'].xl\:ltr\:-top-7 {
    top: -0.7rem
  }

  [dir='ltr'] .xl\:ltr\:-right-7,[dir='ltr'].xl\:ltr\:-right-7 {
    right: -0.7rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-7,[dir='ltr'].xl\:ltr\:-bottom-7 {
    bottom: -0.7rem
  }

  [dir='ltr'] .xl\:ltr\:-left-7,[dir='ltr'].xl\:ltr\:-left-7 {
    left: -0.7rem
  }

  [dir='ltr'] .xl\:ltr\:-top-8,[dir='ltr'].xl\:ltr\:-top-8 {
    top: -0.8rem
  }

  [dir='ltr'] .xl\:ltr\:-right-8,[dir='ltr'].xl\:ltr\:-right-8 {
    right: -0.8rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-8,[dir='ltr'].xl\:ltr\:-bottom-8 {
    bottom: -0.8rem
  }

  [dir='ltr'] .xl\:ltr\:-left-8,[dir='ltr'].xl\:ltr\:-left-8 {
    left: -0.8rem
  }

  [dir='ltr'] .xl\:ltr\:-top-9,[dir='ltr'].xl\:ltr\:-top-9 {
    top: -0.9rem
  }

  [dir='ltr'] .xl\:ltr\:-right-9,[dir='ltr'].xl\:ltr\:-right-9 {
    right: -0.9rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-9,[dir='ltr'].xl\:ltr\:-bottom-9 {
    bottom: -0.9rem
  }

  [dir='ltr'] .xl\:ltr\:-left-9,[dir='ltr'].xl\:ltr\:-left-9 {
    left: -0.9rem
  }

  [dir='ltr'] .xl\:ltr\:-top-10,[dir='ltr'].xl\:ltr\:-top-10 {
    top: -1rem
  }

  [dir='ltr'] .xl\:ltr\:-right-10,[dir='ltr'].xl\:ltr\:-right-10 {
    right: -1rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-10,[dir='ltr'].xl\:ltr\:-bottom-10 {
    bottom: -1rem
  }

  [dir='ltr'] .xl\:ltr\:-left-10,[dir='ltr'].xl\:ltr\:-left-10 {
    left: -1rem
  }

  [dir='ltr'] .xl\:ltr\:-top-12,[dir='ltr'].xl\:ltr\:-top-12 {
    top: -1.2rem
  }

  [dir='ltr'] .xl\:ltr\:-right-12,[dir='ltr'].xl\:ltr\:-right-12 {
    right: -1.2rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-12,[dir='ltr'].xl\:ltr\:-bottom-12 {
    bottom: -1.2rem
  }

  [dir='ltr'] .xl\:ltr\:-left-12,[dir='ltr'].xl\:ltr\:-left-12 {
    left: -1.2rem
  }

  [dir='ltr'] .xl\:ltr\:-top-14,[dir='ltr'].xl\:ltr\:-top-14 {
    top: -1.4rem
  }

  [dir='ltr'] .xl\:ltr\:-right-14,[dir='ltr'].xl\:ltr\:-right-14 {
    right: -1.4rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-14,[dir='ltr'].xl\:ltr\:-bottom-14 {
    bottom: -1.4rem
  }

  [dir='ltr'] .xl\:ltr\:-left-14,[dir='ltr'].xl\:ltr\:-left-14 {
    left: -1.4rem
  }

  [dir='ltr'] .xl\:ltr\:-top-16,[dir='ltr'].xl\:ltr\:-top-16 {
    top: -1.6rem
  }

  [dir='ltr'] .xl\:ltr\:-right-16,[dir='ltr'].xl\:ltr\:-right-16 {
    right: -1.6rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-16,[dir='ltr'].xl\:ltr\:-bottom-16 {
    bottom: -1.6rem
  }

  [dir='ltr'] .xl\:ltr\:-left-16,[dir='ltr'].xl\:ltr\:-left-16 {
    left: -1.6rem
  }

  [dir='ltr'] .xl\:ltr\:-top-20,[dir='ltr'].xl\:ltr\:-top-20 {
    top: -2rem
  }

  [dir='ltr'] .xl\:ltr\:-right-20,[dir='ltr'].xl\:ltr\:-right-20 {
    right: -2rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-20,[dir='ltr'].xl\:ltr\:-bottom-20 {
    bottom: -2rem
  }

  [dir='ltr'] .xl\:ltr\:-left-20,[dir='ltr'].xl\:ltr\:-left-20 {
    left: -2rem
  }

  [dir='ltr'] .xl\:ltr\:-top-24,[dir='ltr'].xl\:ltr\:-top-24 {
    top: -2.4rem
  }

  [dir='ltr'] .xl\:ltr\:-right-24,[dir='ltr'].xl\:ltr\:-right-24 {
    right: -2.4rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-24,[dir='ltr'].xl\:ltr\:-bottom-24 {
    bottom: -2.4rem
  }

  [dir='ltr'] .xl\:ltr\:-left-24,[dir='ltr'].xl\:ltr\:-left-24 {
    left: -2.4rem
  }

  [dir='ltr'] .xl\:ltr\:-top-28,[dir='ltr'].xl\:ltr\:-top-28 {
    top: -2.8rem
  }

  [dir='ltr'] .xl\:ltr\:-right-28,[dir='ltr'].xl\:ltr\:-right-28 {
    right: -2.8rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-28,[dir='ltr'].xl\:ltr\:-bottom-28 {
    bottom: -2.8rem
  }

  [dir='ltr'] .xl\:ltr\:-left-28,[dir='ltr'].xl\:ltr\:-left-28 {
    left: -2.8rem
  }

  [dir='ltr'] .xl\:ltr\:-top-32,[dir='ltr'].xl\:ltr\:-top-32 {
    top: -3.2rem
  }

  [dir='ltr'] .xl\:ltr\:-right-32,[dir='ltr'].xl\:ltr\:-right-32 {
    right: -3.2rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-32,[dir='ltr'].xl\:ltr\:-bottom-32 {
    bottom: -3.2rem
  }

  [dir='ltr'] .xl\:ltr\:-left-32,[dir='ltr'].xl\:ltr\:-left-32 {
    left: -3.2rem
  }

  [dir='ltr'] .xl\:ltr\:-top-36,[dir='ltr'].xl\:ltr\:-top-36 {
    top: -3.6rem
  }

  [dir='ltr'] .xl\:ltr\:-right-36,[dir='ltr'].xl\:ltr\:-right-36 {
    right: -3.6rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-36,[dir='ltr'].xl\:ltr\:-bottom-36 {
    bottom: -3.6rem
  }

  [dir='ltr'] .xl\:ltr\:-left-36,[dir='ltr'].xl\:ltr\:-left-36 {
    left: -3.6rem
  }

  [dir='ltr'] .xl\:ltr\:-top-40,[dir='ltr'].xl\:ltr\:-top-40 {
    top: -4rem
  }

  [dir='ltr'] .xl\:ltr\:-right-40,[dir='ltr'].xl\:ltr\:-right-40 {
    right: -4rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-40,[dir='ltr'].xl\:ltr\:-bottom-40 {
    bottom: -4rem
  }

  [dir='ltr'] .xl\:ltr\:-left-40,[dir='ltr'].xl\:ltr\:-left-40 {
    left: -4rem
  }

  [dir='ltr'] .xl\:ltr\:-top-44,[dir='ltr'].xl\:ltr\:-top-44 {
    top: -4.4rem
  }

  [dir='ltr'] .xl\:ltr\:-right-44,[dir='ltr'].xl\:ltr\:-right-44 {
    right: -4.4rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-44,[dir='ltr'].xl\:ltr\:-bottom-44 {
    bottom: -4.4rem
  }

  [dir='ltr'] .xl\:ltr\:-left-44,[dir='ltr'].xl\:ltr\:-left-44 {
    left: -4.4rem
  }

  [dir='ltr'] .xl\:ltr\:-top-48,[dir='ltr'].xl\:ltr\:-top-48 {
    top: -4.8rem
  }

  [dir='ltr'] .xl\:ltr\:-right-48,[dir='ltr'].xl\:ltr\:-right-48 {
    right: -4.8rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-48,[dir='ltr'].xl\:ltr\:-bottom-48 {
    bottom: -4.8rem
  }

  [dir='ltr'] .xl\:ltr\:-left-48,[dir='ltr'].xl\:ltr\:-left-48 {
    left: -4.8rem
  }

  [dir='ltr'] .xl\:ltr\:-top-52,[dir='ltr'].xl\:ltr\:-top-52 {
    top: -5.2rem
  }

  [dir='ltr'] .xl\:ltr\:-right-52,[dir='ltr'].xl\:ltr\:-right-52 {
    right: -5.2rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-52,[dir='ltr'].xl\:ltr\:-bottom-52 {
    bottom: -5.2rem
  }

  [dir='ltr'] .xl\:ltr\:-left-52,[dir='ltr'].xl\:ltr\:-left-52 {
    left: -5.2rem
  }

  [dir='ltr'] .xl\:ltr\:-top-56,[dir='ltr'].xl\:ltr\:-top-56 {
    top: -5.6rem
  }

  [dir='ltr'] .xl\:ltr\:-right-56,[dir='ltr'].xl\:ltr\:-right-56 {
    right: -5.6rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-56,[dir='ltr'].xl\:ltr\:-bottom-56 {
    bottom: -5.6rem
  }

  [dir='ltr'] .xl\:ltr\:-left-56,[dir='ltr'].xl\:ltr\:-left-56 {
    left: -5.6rem
  }

  [dir='ltr'] .xl\:ltr\:-top-60,[dir='ltr'].xl\:ltr\:-top-60 {
    top: -6rem
  }

  [dir='ltr'] .xl\:ltr\:-right-60,[dir='ltr'].xl\:ltr\:-right-60 {
    right: -6rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-60,[dir='ltr'].xl\:ltr\:-bottom-60 {
    bottom: -6rem
  }

  [dir='ltr'] .xl\:ltr\:-left-60,[dir='ltr'].xl\:ltr\:-left-60 {
    left: -6rem
  }

  [dir='ltr'] .xl\:ltr\:-top-64,[dir='ltr'].xl\:ltr\:-top-64 {
    top: -6.4rem
  }

  [dir='ltr'] .xl\:ltr\:-right-64,[dir='ltr'].xl\:ltr\:-right-64 {
    right: -6.4rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-64,[dir='ltr'].xl\:ltr\:-bottom-64 {
    bottom: -6.4rem
  }

  [dir='ltr'] .xl\:ltr\:-left-64,[dir='ltr'].xl\:ltr\:-left-64 {
    left: -6.4rem
  }

  [dir='ltr'] .xl\:ltr\:-top-68,[dir='ltr'].xl\:ltr\:-top-68 {
    top: -6.8rem
  }

  [dir='ltr'] .xl\:ltr\:-right-68,[dir='ltr'].xl\:ltr\:-right-68 {
    right: -6.8rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-68,[dir='ltr'].xl\:ltr\:-bottom-68 {
    bottom: -6.8rem
  }

  [dir='ltr'] .xl\:ltr\:-left-68,[dir='ltr'].xl\:ltr\:-left-68 {
    left: -6.8rem
  }

  [dir='ltr'] .xl\:ltr\:-top-72,[dir='ltr'].xl\:ltr\:-top-72 {
    top: -7.2rem
  }

  [dir='ltr'] .xl\:ltr\:-right-72,[dir='ltr'].xl\:ltr\:-right-72 {
    right: -7.2rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-72,[dir='ltr'].xl\:ltr\:-bottom-72 {
    bottom: -7.2rem
  }

  [dir='ltr'] .xl\:ltr\:-left-72,[dir='ltr'].xl\:ltr\:-left-72 {
    left: -7.2rem
  }

  [dir='ltr'] .xl\:ltr\:-top-76,[dir='ltr'].xl\:ltr\:-top-76 {
    top: -7.6rem
  }

  [dir='ltr'] .xl\:ltr\:-right-76,[dir='ltr'].xl\:ltr\:-right-76 {
    right: -7.6rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-76,[dir='ltr'].xl\:ltr\:-bottom-76 {
    bottom: -7.6rem
  }

  [dir='ltr'] .xl\:ltr\:-left-76,[dir='ltr'].xl\:ltr\:-left-76 {
    left: -7.6rem
  }

  [dir='ltr'] .xl\:ltr\:-top-80,[dir='ltr'].xl\:ltr\:-top-80 {
    top: -8rem
  }

  [dir='ltr'] .xl\:ltr\:-right-80,[dir='ltr'].xl\:ltr\:-right-80 {
    right: -8rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-80,[dir='ltr'].xl\:ltr\:-bottom-80 {
    bottom: -8rem
  }

  [dir='ltr'] .xl\:ltr\:-left-80,[dir='ltr'].xl\:ltr\:-left-80 {
    left: -8rem
  }

  [dir='ltr'] .xl\:ltr\:-top-84,[dir='ltr'].xl\:ltr\:-top-84 {
    top: -8.4rem
  }

  [dir='ltr'] .xl\:ltr\:-right-84,[dir='ltr'].xl\:ltr\:-right-84 {
    right: -8.4rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-84,[dir='ltr'].xl\:ltr\:-bottom-84 {
    bottom: -8.4rem
  }

  [dir='ltr'] .xl\:ltr\:-left-84,[dir='ltr'].xl\:ltr\:-left-84 {
    left: -8.4rem
  }

  [dir='ltr'] .xl\:ltr\:-top-88,[dir='ltr'].xl\:ltr\:-top-88 {
    top: -8.8rem
  }

  [dir='ltr'] .xl\:ltr\:-right-88,[dir='ltr'].xl\:ltr\:-right-88 {
    right: -8.8rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-88,[dir='ltr'].xl\:ltr\:-bottom-88 {
    bottom: -8.8rem
  }

  [dir='ltr'] .xl\:ltr\:-left-88,[dir='ltr'].xl\:ltr\:-left-88 {
    left: -8.8rem
  }

  [dir='ltr'] .xl\:ltr\:-top-92,[dir='ltr'].xl\:ltr\:-top-92 {
    top: -9.2rem
  }

  [dir='ltr'] .xl\:ltr\:-right-92,[dir='ltr'].xl\:ltr\:-right-92 {
    right: -9.2rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-92,[dir='ltr'].xl\:ltr\:-bottom-92 {
    bottom: -9.2rem
  }

  [dir='ltr'] .xl\:ltr\:-left-92,[dir='ltr'].xl\:ltr\:-left-92 {
    left: -9.2rem
  }

  [dir='ltr'] .xl\:ltr\:-top-96,[dir='ltr'].xl\:ltr\:-top-96 {
    top: -9.6rem
  }

  [dir='ltr'] .xl\:ltr\:-right-96,[dir='ltr'].xl\:ltr\:-right-96 {
    right: -9.6rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-96,[dir='ltr'].xl\:ltr\:-bottom-96 {
    bottom: -9.6rem
  }

  [dir='ltr'] .xl\:ltr\:-left-96,[dir='ltr'].xl\:ltr\:-left-96 {
    left: -9.6rem
  }

  [dir='ltr'] .xl\:ltr\:-top-128,[dir='ltr'].xl\:ltr\:-top-128 {
    top: -12.8rem
  }

  [dir='ltr'] .xl\:ltr\:-right-128,[dir='ltr'].xl\:ltr\:-right-128 {
    right: -12.8rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-128,[dir='ltr'].xl\:ltr\:-bottom-128 {
    bottom: -12.8rem
  }

  [dir='ltr'] .xl\:ltr\:-left-128,[dir='ltr'].xl\:ltr\:-left-128 {
    left: -12.8rem
  }

  [dir='ltr'] .xl\:ltr\:-top-136,[dir='ltr'].xl\:ltr\:-top-136 {
    top: -13.6rem
  }

  [dir='ltr'] .xl\:ltr\:-right-136,[dir='ltr'].xl\:ltr\:-right-136 {
    right: -13.6rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-136,[dir='ltr'].xl\:ltr\:-bottom-136 {
    bottom: -13.6rem
  }

  [dir='ltr'] .xl\:ltr\:-left-136,[dir='ltr'].xl\:ltr\:-left-136 {
    left: -13.6rem
  }

  [dir='ltr'] .xl\:ltr\:-top-160,[dir='ltr'].xl\:ltr\:-top-160 {
    top: -16rem
  }

  [dir='ltr'] .xl\:ltr\:-right-160,[dir='ltr'].xl\:ltr\:-right-160 {
    right: -16rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-160,[dir='ltr'].xl\:ltr\:-bottom-160 {
    bottom: -16rem
  }

  [dir='ltr'] .xl\:ltr\:-left-160,[dir='ltr'].xl\:ltr\:-left-160 {
    left: -16rem
  }

  [dir='ltr'] .xl\:ltr\:-top-192,[dir='ltr'].xl\:ltr\:-top-192 {
    top: -19.2rem
  }

  [dir='ltr'] .xl\:ltr\:-right-192,[dir='ltr'].xl\:ltr\:-right-192 {
    right: -19.2rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-192,[dir='ltr'].xl\:ltr\:-bottom-192 {
    bottom: -19.2rem
  }

  [dir='ltr'] .xl\:ltr\:-left-192,[dir='ltr'].xl\:ltr\:-left-192 {
    left: -19.2rem
  }

  [dir='ltr'] .xl\:ltr\:-top-200,[dir='ltr'].xl\:ltr\:-top-200 {
    top: -20rem
  }

  [dir='ltr'] .xl\:ltr\:-right-200,[dir='ltr'].xl\:ltr\:-right-200 {
    right: -20rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-200,[dir='ltr'].xl\:ltr\:-bottom-200 {
    bottom: -20rem
  }

  [dir='ltr'] .xl\:ltr\:-left-200,[dir='ltr'].xl\:ltr\:-left-200 {
    left: -20rem
  }

  [dir='ltr'] .xl\:ltr\:-top-208,[dir='ltr'].xl\:ltr\:-top-208 {
    top: -20.8rem
  }

  [dir='ltr'] .xl\:ltr\:-right-208,[dir='ltr'].xl\:ltr\:-right-208 {
    right: -20.8rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-208,[dir='ltr'].xl\:ltr\:-bottom-208 {
    bottom: -20.8rem
  }

  [dir='ltr'] .xl\:ltr\:-left-208,[dir='ltr'].xl\:ltr\:-left-208 {
    left: -20.8rem
  }

  [dir='ltr'] .xl\:ltr\:-top-216,[dir='ltr'].xl\:ltr\:-top-216 {
    top: -21.6rem
  }

  [dir='ltr'] .xl\:ltr\:-right-216,[dir='ltr'].xl\:ltr\:-right-216 {
    right: -21.6rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-216,[dir='ltr'].xl\:ltr\:-bottom-216 {
    bottom: -21.6rem
  }

  [dir='ltr'] .xl\:ltr\:-left-216,[dir='ltr'].xl\:ltr\:-left-216 {
    left: -21.6rem
  }

  [dir='ltr'] .xl\:ltr\:-top-224,[dir='ltr'].xl\:ltr\:-top-224 {
    top: -22.4rem
  }

  [dir='ltr'] .xl\:ltr\:-right-224,[dir='ltr'].xl\:ltr\:-right-224 {
    right: -22.4rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-224,[dir='ltr'].xl\:ltr\:-bottom-224 {
    bottom: -22.4rem
  }

  [dir='ltr'] .xl\:ltr\:-left-224,[dir='ltr'].xl\:ltr\:-left-224 {
    left: -22.4rem
  }

  [dir='ltr'] .xl\:ltr\:-top-256,[dir='ltr'].xl\:ltr\:-top-256 {
    top: -25.6rem
  }

  [dir='ltr'] .xl\:ltr\:-right-256,[dir='ltr'].xl\:ltr\:-right-256 {
    right: -25.6rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-256,[dir='ltr'].xl\:ltr\:-bottom-256 {
    bottom: -25.6rem
  }

  [dir='ltr'] .xl\:ltr\:-left-256,[dir='ltr'].xl\:ltr\:-left-256 {
    left: -25.6rem
  }

  [dir='ltr'] .xl\:ltr\:-top-288,[dir='ltr'].xl\:ltr\:-top-288 {
    top: -28.8rem
  }

  [dir='ltr'] .xl\:ltr\:-right-288,[dir='ltr'].xl\:ltr\:-right-288 {
    right: -28.8rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-288,[dir='ltr'].xl\:ltr\:-bottom-288 {
    bottom: -28.8rem
  }

  [dir='ltr'] .xl\:ltr\:-left-288,[dir='ltr'].xl\:ltr\:-left-288 {
    left: -28.8rem
  }

  [dir='ltr'] .xl\:ltr\:-top-320,[dir='ltr'].xl\:ltr\:-top-320 {
    top: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-right-320,[dir='ltr'].xl\:ltr\:-right-320 {
    right: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-320,[dir='ltr'].xl\:ltr\:-bottom-320 {
    bottom: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-left-320,[dir='ltr'].xl\:ltr\:-left-320 {
    left: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-top-360,[dir='ltr'].xl\:ltr\:-top-360 {
    top: -36rem
  }

  [dir='ltr'] .xl\:ltr\:-right-360,[dir='ltr'].xl\:ltr\:-right-360 {
    right: -36rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-360,[dir='ltr'].xl\:ltr\:-bottom-360 {
    bottom: -36rem
  }

  [dir='ltr'] .xl\:ltr\:-left-360,[dir='ltr'].xl\:ltr\:-left-360 {
    left: -36rem
  }

  [dir='ltr'] .xl\:ltr\:-top-384,[dir='ltr'].xl\:ltr\:-top-384 {
    top: -38.4rem
  }

  [dir='ltr'] .xl\:ltr\:-right-384,[dir='ltr'].xl\:ltr\:-right-384 {
    right: -38.4rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-384,[dir='ltr'].xl\:ltr\:-bottom-384 {
    bottom: -38.4rem
  }

  [dir='ltr'] .xl\:ltr\:-left-384,[dir='ltr'].xl\:ltr\:-left-384 {
    left: -38.4rem
  }

  [dir='ltr'] .xl\:ltr\:-top-400,[dir='ltr'].xl\:ltr\:-top-400 {
    top: -40rem
  }

  [dir='ltr'] .xl\:ltr\:-right-400,[dir='ltr'].xl\:ltr\:-right-400 {
    right: -40rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-400,[dir='ltr'].xl\:ltr\:-bottom-400 {
    bottom: -40rem
  }

  [dir='ltr'] .xl\:ltr\:-left-400,[dir='ltr'].xl\:ltr\:-left-400 {
    left: -40rem
  }

  [dir='ltr'] .xl\:ltr\:-top-512,[dir='ltr'].xl\:ltr\:-top-512 {
    top: -51.2rem
  }

  [dir='ltr'] .xl\:ltr\:-right-512,[dir='ltr'].xl\:ltr\:-right-512 {
    right: -51.2rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-512,[dir='ltr'].xl\:ltr\:-bottom-512 {
    bottom: -51.2rem
  }

  [dir='ltr'] .xl\:ltr\:-left-512,[dir='ltr'].xl\:ltr\:-left-512 {
    left: -51.2rem
  }

  [dir='ltr'] .xl\:ltr\:-top-640,[dir='ltr'].xl\:ltr\:-top-640 {
    top: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-right-640,[dir='ltr'].xl\:ltr\:-right-640 {
    right: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-640,[dir='ltr'].xl\:ltr\:-bottom-640 {
    bottom: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-left-640,[dir='ltr'].xl\:ltr\:-left-640 {
    left: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-top-xs,[dir='ltr'].xl\:ltr\:-top-xs {
    top: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-right-xs,[dir='ltr'].xl\:ltr\:-right-xs {
    right: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-xs,[dir='ltr'].xl\:ltr\:-bottom-xs {
    bottom: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-left-xs,[dir='ltr'].xl\:ltr\:-left-xs {
    left: -32rem
  }

  [dir='ltr'] .xl\:ltr\:-top-sm,[dir='ltr'].xl\:ltr\:-top-sm {
    top: -48rem
  }

  [dir='ltr'] .xl\:ltr\:-right-sm,[dir='ltr'].xl\:ltr\:-right-sm {
    right: -48rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-sm,[dir='ltr'].xl\:ltr\:-bottom-sm {
    bottom: -48rem
  }

  [dir='ltr'] .xl\:ltr\:-left-sm,[dir='ltr'].xl\:ltr\:-left-sm {
    left: -48rem
  }

  [dir='ltr'] .xl\:ltr\:-top-md,[dir='ltr'].xl\:ltr\:-top-md {
    top: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-right-md,[dir='ltr'].xl\:ltr\:-right-md {
    right: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-md,[dir='ltr'].xl\:ltr\:-bottom-md {
    bottom: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-left-md,[dir='ltr'].xl\:ltr\:-left-md {
    left: -64rem
  }

  [dir='ltr'] .xl\:ltr\:-top-lg,[dir='ltr'].xl\:ltr\:-top-lg {
    top: -80rem
  }

  [dir='ltr'] .xl\:ltr\:-right-lg,[dir='ltr'].xl\:ltr\:-right-lg {
    right: -80rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-lg,[dir='ltr'].xl\:ltr\:-bottom-lg {
    bottom: -80rem
  }

  [dir='ltr'] .xl\:ltr\:-left-lg,[dir='ltr'].xl\:ltr\:-left-lg {
    left: -80rem
  }

  [dir='ltr'] .xl\:ltr\:-top-xl,[dir='ltr'].xl\:ltr\:-top-xl {
    top: -96rem
  }

  [dir='ltr'] .xl\:ltr\:-right-xl,[dir='ltr'].xl\:ltr\:-right-xl {
    right: -96rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-xl,[dir='ltr'].xl\:ltr\:-bottom-xl {
    bottom: -96rem
  }

  [dir='ltr'] .xl\:ltr\:-left-xl,[dir='ltr'].xl\:ltr\:-left-xl {
    left: -96rem
  }

  [dir='ltr'] .xl\:ltr\:-top-2xl,[dir='ltr'].xl\:ltr\:-top-2xl {
    top: -112rem
  }

  [dir='ltr'] .xl\:ltr\:-right-2xl,[dir='ltr'].xl\:ltr\:-right-2xl {
    right: -112rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-2xl,[dir='ltr'].xl\:ltr\:-bottom-2xl {
    bottom: -112rem
  }

  [dir='ltr'] .xl\:ltr\:-left-2xl,[dir='ltr'].xl\:ltr\:-left-2xl {
    left: -112rem
  }

  [dir='ltr'] .xl\:ltr\:-top-3xl,[dir='ltr'].xl\:ltr\:-top-3xl {
    top: -128rem
  }

  [dir='ltr'] .xl\:ltr\:-right-3xl,[dir='ltr'].xl\:ltr\:-right-3xl {
    right: -128rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-3xl,[dir='ltr'].xl\:ltr\:-bottom-3xl {
    bottom: -128rem
  }

  [dir='ltr'] .xl\:ltr\:-left-3xl,[dir='ltr'].xl\:ltr\:-left-3xl {
    left: -128rem
  }

  [dir='ltr'] .xl\:ltr\:-top-4xl,[dir='ltr'].xl\:ltr\:-top-4xl {
    top: -144rem
  }

  [dir='ltr'] .xl\:ltr\:-right-4xl,[dir='ltr'].xl\:ltr\:-right-4xl {
    right: -144rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-4xl,[dir='ltr'].xl\:ltr\:-bottom-4xl {
    bottom: -144rem
  }

  [dir='ltr'] .xl\:ltr\:-left-4xl,[dir='ltr'].xl\:ltr\:-left-4xl {
    left: -144rem
  }

  [dir='ltr'] .xl\:ltr\:-top-5xl,[dir='ltr'].xl\:ltr\:-top-5xl {
    top: -160rem
  }

  [dir='ltr'] .xl\:ltr\:-right-5xl,[dir='ltr'].xl\:ltr\:-right-5xl {
    right: -160rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-5xl,[dir='ltr'].xl\:ltr\:-bottom-5xl {
    bottom: -160rem
  }

  [dir='ltr'] .xl\:ltr\:-left-5xl,[dir='ltr'].xl\:ltr\:-left-5xl {
    left: -160rem
  }

  [dir='ltr'] .xl\:ltr\:-top-px,[dir='ltr'].xl\:ltr\:-top-px {
    top: -1px
  }

  [dir='ltr'] .xl\:ltr\:-right-px,[dir='ltr'].xl\:ltr\:-right-px {
    right: -1px
  }

  [dir='ltr'] .xl\:ltr\:-bottom-px,[dir='ltr'].xl\:ltr\:-bottom-px {
    bottom: -1px
  }

  [dir='ltr'] .xl\:ltr\:-left-px,[dir='ltr'].xl\:ltr\:-left-px {
    left: -1px
  }

  [dir='ltr'] .xl\:ltr\:-top-0\.5,[dir='ltr'].xl\:ltr\:-top-0\.5 {
    top: -0.05rem
  }

  [dir='ltr'] .xl\:ltr\:-right-0\.5,[dir='ltr'].xl\:ltr\:-right-0\.5 {
    right: -0.05rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-0\.5,[dir='ltr'].xl\:ltr\:-bottom-0\.5 {
    bottom: -0.05rem
  }

  [dir='ltr'] .xl\:ltr\:-left-0\.5,[dir='ltr'].xl\:ltr\:-left-0\.5 {
    left: -0.05rem
  }

  [dir='ltr'] .xl\:ltr\:-top-1\.5,[dir='ltr'].xl\:ltr\:-top-1\.5 {
    top: -0.15rem
  }

  [dir='ltr'] .xl\:ltr\:-right-1\.5,[dir='ltr'].xl\:ltr\:-right-1\.5 {
    right: -0.15rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-1\.5,[dir='ltr'].xl\:ltr\:-bottom-1\.5 {
    bottom: -0.15rem
  }

  [dir='ltr'] .xl\:ltr\:-left-1\.5,[dir='ltr'].xl\:ltr\:-left-1\.5 {
    left: -0.15rem
  }

  [dir='ltr'] .xl\:ltr\:-top-2\.5,[dir='ltr'].xl\:ltr\:-top-2\.5 {
    top: -0.25rem
  }

  [dir='ltr'] .xl\:ltr\:-right-2\.5,[dir='ltr'].xl\:ltr\:-right-2\.5 {
    right: -0.25rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-2\.5,[dir='ltr'].xl\:ltr\:-bottom-2\.5 {
    bottom: -0.25rem
  }

  [dir='ltr'] .xl\:ltr\:-left-2\.5,[dir='ltr'].xl\:ltr\:-left-2\.5 {
    left: -0.25rem
  }

  [dir='ltr'] .xl\:ltr\:-top-3\.5,[dir='ltr'].xl\:ltr\:-top-3\.5 {
    top: -0.35rem
  }

  [dir='ltr'] .xl\:ltr\:-right-3\.5,[dir='ltr'].xl\:ltr\:-right-3\.5 {
    right: -0.35rem
  }

  [dir='ltr'] .xl\:ltr\:-bottom-3\.5,[dir='ltr'].xl\:ltr\:-bottom-3\.5 {
    bottom: -0.35rem
  }

  [dir='ltr'] .xl\:ltr\:-left-3\.5,[dir='ltr'].xl\:ltr\:-left-3\.5 {
    left: -0.35rem
  }

  [dir='ltr'] .xl\:ltr\:top-1\/2,[dir='ltr'].xl\:ltr\:top-1\/2 {
    top: 50%
  }

  [dir='ltr'] .xl\:ltr\:right-1\/2,[dir='ltr'].xl\:ltr\:right-1\/2 {
    right: 50%
  }

  [dir='ltr'] .xl\:ltr\:bottom-1\/2,[dir='ltr'].xl\:ltr\:bottom-1\/2 {
    bottom: 50%
  }

  [dir='ltr'] .xl\:ltr\:left-1\/2,[dir='ltr'].xl\:ltr\:left-1\/2 {
    left: 50%
  }

  [dir='ltr'] .xl\:ltr\:top-1\/3,[dir='ltr'].xl\:ltr\:top-1\/3 {
    top: 33.333333%
  }

  [dir='ltr'] .xl\:ltr\:right-1\/3,[dir='ltr'].xl\:ltr\:right-1\/3 {
    right: 33.333333%
  }

  [dir='ltr'] .xl\:ltr\:bottom-1\/3,[dir='ltr'].xl\:ltr\:bottom-1\/3 {
    bottom: 33.333333%
  }

  [dir='ltr'] .xl\:ltr\:left-1\/3,[dir='ltr'].xl\:ltr\:left-1\/3 {
    left: 33.333333%
  }

  [dir='ltr'] .xl\:ltr\:top-2\/3,[dir='ltr'].xl\:ltr\:top-2\/3 {
    top: 66.666667%
  }

  [dir='ltr'] .xl\:ltr\:right-2\/3,[dir='ltr'].xl\:ltr\:right-2\/3 {
    right: 66.666667%
  }

  [dir='ltr'] .xl\:ltr\:bottom-2\/3,[dir='ltr'].xl\:ltr\:bottom-2\/3 {
    bottom: 66.666667%
  }

  [dir='ltr'] .xl\:ltr\:left-2\/3,[dir='ltr'].xl\:ltr\:left-2\/3 {
    left: 66.666667%
  }

  [dir='ltr'] .xl\:ltr\:top-1\/4,[dir='ltr'].xl\:ltr\:top-1\/4 {
    top: 25%
  }

  [dir='ltr'] .xl\:ltr\:right-1\/4,[dir='ltr'].xl\:ltr\:right-1\/4 {
    right: 25%
  }

  [dir='ltr'] .xl\:ltr\:bottom-1\/4,[dir='ltr'].xl\:ltr\:bottom-1\/4 {
    bottom: 25%
  }

  [dir='ltr'] .xl\:ltr\:left-1\/4,[dir='ltr'].xl\:ltr\:left-1\/4 {
    left: 25%
  }

  [dir='ltr'] .xl\:ltr\:top-2\/4,[dir='ltr'].xl\:ltr\:top-2\/4 {
    top: 50%
  }

  [dir='ltr'] .xl\:ltr\:right-2\/4,[dir='ltr'].xl\:ltr\:right-2\/4 {
    right: 50%
  }

  [dir='ltr'] .xl\:ltr\:bottom-2\/4,[dir='ltr'].xl\:ltr\:bottom-2\/4 {
    bottom: 50%
  }

  [dir='ltr'] .xl\:ltr\:left-2\/4,[dir='ltr'].xl\:ltr\:left-2\/4 {
    left: 50%
  }

  [dir='ltr'] .xl\:ltr\:top-3\/4,[dir='ltr'].xl\:ltr\:top-3\/4 {
    top: 75%
  }

  [dir='ltr'] .xl\:ltr\:right-3\/4,[dir='ltr'].xl\:ltr\:right-3\/4 {
    right: 75%
  }

  [dir='ltr'] .xl\:ltr\:bottom-3\/4,[dir='ltr'].xl\:ltr\:bottom-3\/4 {
    bottom: 75%
  }

  [dir='ltr'] .xl\:ltr\:left-3\/4,[dir='ltr'].xl\:ltr\:left-3\/4 {
    left: 75%
  }

  [dir='ltr'] .xl\:ltr\:top-full,[dir='ltr'].xl\:ltr\:top-full {
    top: 100%
  }

  [dir='ltr'] .xl\:ltr\:right-full,[dir='ltr'].xl\:ltr\:right-full {
    right: 100%
  }

  [dir='ltr'] .xl\:ltr\:bottom-full,[dir='ltr'].xl\:ltr\:bottom-full {
    bottom: 100%
  }

  [dir='ltr'] .xl\:ltr\:left-full,[dir='ltr'].xl\:ltr\:left-full {
    left: 100%
  }

  [dir='ltr'] .xl\:ltr\:-top-1\/2,[dir='ltr'].xl\:ltr\:-top-1\/2 {
    top: -50%
  }

  [dir='ltr'] .xl\:ltr\:-right-1\/2,[dir='ltr'].xl\:ltr\:-right-1\/2 {
    right: -50%
  }

  [dir='ltr'] .xl\:ltr\:-bottom-1\/2,[dir='ltr'].xl\:ltr\:-bottom-1\/2 {
    bottom: -50%
  }

  [dir='ltr'] .xl\:ltr\:-left-1\/2,[dir='ltr'].xl\:ltr\:-left-1\/2 {
    left: -50%
  }

  [dir='ltr'] .xl\:ltr\:-top-1\/3,[dir='ltr'].xl\:ltr\:-top-1\/3 {
    top: -33.333333%
  }

  [dir='ltr'] .xl\:ltr\:-right-1\/3,[dir='ltr'].xl\:ltr\:-right-1\/3 {
    right: -33.333333%
  }

  [dir='ltr'] .xl\:ltr\:-bottom-1\/3,[dir='ltr'].xl\:ltr\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  [dir='ltr'] .xl\:ltr\:-left-1\/3,[dir='ltr'].xl\:ltr\:-left-1\/3 {
    left: -33.333333%
  }

  [dir='ltr'] .xl\:ltr\:-top-2\/3,[dir='ltr'].xl\:ltr\:-top-2\/3 {
    top: -66.666667%
  }

  [dir='ltr'] .xl\:ltr\:-right-2\/3,[dir='ltr'].xl\:ltr\:-right-2\/3 {
    right: -66.666667%
  }

  [dir='ltr'] .xl\:ltr\:-bottom-2\/3,[dir='ltr'].xl\:ltr\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  [dir='ltr'] .xl\:ltr\:-left-2\/3,[dir='ltr'].xl\:ltr\:-left-2\/3 {
    left: -66.666667%
  }

  [dir='ltr'] .xl\:ltr\:-top-1\/4,[dir='ltr'].xl\:ltr\:-top-1\/4 {
    top: -25%
  }

  [dir='ltr'] .xl\:ltr\:-right-1\/4,[dir='ltr'].xl\:ltr\:-right-1\/4 {
    right: -25%
  }

  [dir='ltr'] .xl\:ltr\:-bottom-1\/4,[dir='ltr'].xl\:ltr\:-bottom-1\/4 {
    bottom: -25%
  }

  [dir='ltr'] .xl\:ltr\:-left-1\/4,[dir='ltr'].xl\:ltr\:-left-1\/4 {
    left: -25%
  }

  [dir='ltr'] .xl\:ltr\:-top-2\/4,[dir='ltr'].xl\:ltr\:-top-2\/4 {
    top: -50%
  }

  [dir='ltr'] .xl\:ltr\:-right-2\/4,[dir='ltr'].xl\:ltr\:-right-2\/4 {
    right: -50%
  }

  [dir='ltr'] .xl\:ltr\:-bottom-2\/4,[dir='ltr'].xl\:ltr\:-bottom-2\/4 {
    bottom: -50%
  }

  [dir='ltr'] .xl\:ltr\:-left-2\/4,[dir='ltr'].xl\:ltr\:-left-2\/4 {
    left: -50%
  }

  [dir='ltr'] .xl\:ltr\:-top-3\/4,[dir='ltr'].xl\:ltr\:-top-3\/4 {
    top: -75%
  }

  [dir='ltr'] .xl\:ltr\:-right-3\/4,[dir='ltr'].xl\:ltr\:-right-3\/4 {
    right: -75%
  }

  [dir='ltr'] .xl\:ltr\:-bottom-3\/4,[dir='ltr'].xl\:ltr\:-bottom-3\/4 {
    bottom: -75%
  }

  [dir='ltr'] .xl\:ltr\:-left-3\/4,[dir='ltr'].xl\:ltr\:-left-3\/4 {
    left: -75%
  }

  [dir='ltr'] .xl\:ltr\:-top-full,[dir='ltr'].xl\:ltr\:-top-full {
    top: -100%
  }

  [dir='ltr'] .xl\:ltr\:-right-full,[dir='ltr'].xl\:ltr\:-right-full {
    right: -100%
  }

  [dir='ltr'] .xl\:ltr\:-bottom-full,[dir='ltr'].xl\:ltr\:-bottom-full {
    bottom: -100%
  }

  [dir='ltr'] .xl\:ltr\:-left-full,[dir='ltr'].xl\:ltr\:-left-full {
    left: -100%
  }

  [dir='rtl'] .xl\:rtl\:inset-0,[dir='rtl'].xl\:rtl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  [dir='rtl'] .xl\:rtl\:inset-1,[dir='rtl'].xl\:rtl\:inset-1 {
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:inset-2,[dir='rtl'].xl\:rtl\:inset-2 {
    top: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-3,[dir='rtl'].xl\:rtl\:inset-3 {
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:inset-4,[dir='rtl'].xl\:rtl\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-5,[dir='rtl'].xl\:rtl\:inset-5 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:inset-6,[dir='rtl'].xl\:rtl\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-7,[dir='rtl'].xl\:rtl\:inset-7 {
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
    left: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:inset-8,[dir='rtl'].xl\:rtl\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-9,[dir='rtl'].xl\:rtl\:inset-9 {
    top: 0.9rem;
    right: 0.9rem;
    bottom: 0.9rem;
    left: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:inset-10,[dir='rtl'].xl\:rtl\:inset-10 {
    top: 1.0rem;
    right: 1.0rem;
    bottom: 1.0rem;
    left: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:inset-12,[dir='rtl'].xl\:rtl\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-14,[dir='rtl'].xl\:rtl\:inset-14 {
    top: 1.4rem;
    right: 1.4rem;
    bottom: 1.4rem;
    left: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-16,[dir='rtl'].xl\:rtl\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-20,[dir='rtl'].xl\:rtl\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-24,[dir='rtl'].xl\:rtl\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-28,[dir='rtl'].xl\:rtl\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-32,[dir='rtl'].xl\:rtl\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-36,[dir='rtl'].xl\:rtl\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-40,[dir='rtl'].xl\:rtl\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-44,[dir='rtl'].xl\:rtl\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-48,[dir='rtl'].xl\:rtl\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-52,[dir='rtl'].xl\:rtl\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-56,[dir='rtl'].xl\:rtl\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-60,[dir='rtl'].xl\:rtl\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-64,[dir='rtl'].xl\:rtl\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-68,[dir='rtl'].xl\:rtl\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-72,[dir='rtl'].xl\:rtl\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-76,[dir='rtl'].xl\:rtl\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-80,[dir='rtl'].xl\:rtl\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-84,[dir='rtl'].xl\:rtl\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-88,[dir='rtl'].xl\:rtl\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-92,[dir='rtl'].xl\:rtl\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-96,[dir='rtl'].xl\:rtl\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-128,[dir='rtl'].xl\:rtl\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-136,[dir='rtl'].xl\:rtl\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-160,[dir='rtl'].xl\:rtl\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  [dir='rtl'] .xl\:rtl\:inset-192,[dir='rtl'].xl\:rtl\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-200,[dir='rtl'].xl\:rtl\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  [dir='rtl'] .xl\:rtl\:inset-208,[dir='rtl'].xl\:rtl\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-216,[dir='rtl'].xl\:rtl\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-224,[dir='rtl'].xl\:rtl\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-256,[dir='rtl'].xl\:rtl\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-288,[dir='rtl'].xl\:rtl\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-320,[dir='rtl'].xl\:rtl\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='rtl'] .xl\:rtl\:inset-360,[dir='rtl'].xl\:rtl\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  [dir='rtl'] .xl\:rtl\:inset-384,[dir='rtl'].xl\:rtl\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-400,[dir='rtl'].xl\:rtl\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  [dir='rtl'] .xl\:rtl\:inset-512,[dir='rtl'].xl\:rtl\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-640,[dir='rtl'].xl\:rtl\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='rtl'] .xl\:rtl\:inset-auto,[dir='rtl'].xl\:rtl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  [dir='rtl'] .xl\:rtl\:inset-xs,[dir='rtl'].xl\:rtl\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='rtl'] .xl\:rtl\:inset-sm,[dir='rtl'].xl\:rtl\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  [dir='rtl'] .xl\:rtl\:inset-md,[dir='rtl'].xl\:rtl\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='rtl'] .xl\:rtl\:inset-lg,[dir='rtl'].xl\:rtl\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  [dir='rtl'] .xl\:rtl\:inset-xl,[dir='rtl'].xl\:rtl\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  [dir='rtl'] .xl\:rtl\:inset-2xl,[dir='rtl'].xl\:rtl\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  [dir='rtl'] .xl\:rtl\:inset-3xl,[dir='rtl'].xl\:rtl\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  [dir='rtl'] .xl\:rtl\:inset-4xl,[dir='rtl'].xl\:rtl\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  [dir='rtl'] .xl\:rtl\:inset-5xl,[dir='rtl'].xl\:rtl\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  [dir='rtl'] .xl\:rtl\:inset-px,[dir='rtl'].xl\:rtl\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  [dir='rtl'] .xl\:rtl\:inset-0\.5,[dir='rtl'].xl\:rtl\:inset-0\.5 {
    top: 0.05rem;
    right: 0.05rem;
    bottom: 0.05rem;
    left: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:inset-1\.5,[dir='rtl'].xl\:rtl\:inset-1\.5 {
    top: 0.15rem;
    right: 0.15rem;
    bottom: 0.15rem;
    left: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:inset-2\.5,[dir='rtl'].xl\:rtl\:inset-2\.5 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:inset-3\.5,[dir='rtl'].xl\:rtl\:inset-3\.5 {
    top: 0.35rem;
    right: 0.35rem;
    bottom: 0.35rem;
    left: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-1,[dir='rtl'].xl\:rtl\:-inset-1 {
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-2,[dir='rtl'].xl\:rtl\:-inset-2 {
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-3,[dir='rtl'].xl\:rtl\:-inset-3 {
    top: -0.3rem;
    right: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-4,[dir='rtl'].xl\:rtl\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-5,[dir='rtl'].xl\:rtl\:-inset-5 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-6,[dir='rtl'].xl\:rtl\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-7,[dir='rtl'].xl\:rtl\:-inset-7 {
    top: -0.7rem;
    right: -0.7rem;
    bottom: -0.7rem;
    left: -0.7rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-8,[dir='rtl'].xl\:rtl\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-9,[dir='rtl'].xl\:rtl\:-inset-9 {
    top: -0.9rem;
    right: -0.9rem;
    bottom: -0.9rem;
    left: -0.9rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-10,[dir='rtl'].xl\:rtl\:-inset-10 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-12,[dir='rtl'].xl\:rtl\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-14,[dir='rtl'].xl\:rtl\:-inset-14 {
    top: -1.4rem;
    right: -1.4rem;
    bottom: -1.4rem;
    left: -1.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-16,[dir='rtl'].xl\:rtl\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-20,[dir='rtl'].xl\:rtl\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-24,[dir='rtl'].xl\:rtl\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-28,[dir='rtl'].xl\:rtl\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-32,[dir='rtl'].xl\:rtl\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-36,[dir='rtl'].xl\:rtl\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-40,[dir='rtl'].xl\:rtl\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-44,[dir='rtl'].xl\:rtl\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-48,[dir='rtl'].xl\:rtl\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-52,[dir='rtl'].xl\:rtl\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-56,[dir='rtl'].xl\:rtl\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-60,[dir='rtl'].xl\:rtl\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-64,[dir='rtl'].xl\:rtl\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-68,[dir='rtl'].xl\:rtl\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-72,[dir='rtl'].xl\:rtl\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-76,[dir='rtl'].xl\:rtl\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-80,[dir='rtl'].xl\:rtl\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-84,[dir='rtl'].xl\:rtl\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-88,[dir='rtl'].xl\:rtl\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-92,[dir='rtl'].xl\:rtl\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-96,[dir='rtl'].xl\:rtl\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-128,[dir='rtl'].xl\:rtl\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-136,[dir='rtl'].xl\:rtl\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-160,[dir='rtl'].xl\:rtl\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-192,[dir='rtl'].xl\:rtl\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-200,[dir='rtl'].xl\:rtl\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-208,[dir='rtl'].xl\:rtl\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-216,[dir='rtl'].xl\:rtl\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-224,[dir='rtl'].xl\:rtl\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-256,[dir='rtl'].xl\:rtl\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-288,[dir='rtl'].xl\:rtl\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-320,[dir='rtl'].xl\:rtl\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-360,[dir='rtl'].xl\:rtl\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-384,[dir='rtl'].xl\:rtl\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-400,[dir='rtl'].xl\:rtl\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-512,[dir='rtl'].xl\:rtl\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-640,[dir='rtl'].xl\:rtl\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-xs,[dir='rtl'].xl\:rtl\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-sm,[dir='rtl'].xl\:rtl\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-md,[dir='rtl'].xl\:rtl\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-lg,[dir='rtl'].xl\:rtl\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-xl,[dir='rtl'].xl\:rtl\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-2xl,[dir='rtl'].xl\:rtl\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-3xl,[dir='rtl'].xl\:rtl\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-4xl,[dir='rtl'].xl\:rtl\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-5xl,[dir='rtl'].xl\:rtl\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-px,[dir='rtl'].xl\:rtl\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  [dir='rtl'] .xl\:rtl\:-inset-0\.5,[dir='rtl'].xl\:rtl\:-inset-0\.5 {
    top: -0.05rem;
    right: -0.05rem;
    bottom: -0.05rem;
    left: -0.05rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-1\.5,[dir='rtl'].xl\:rtl\:-inset-1\.5 {
    top: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    left: -0.15rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-2\.5,[dir='rtl'].xl\:rtl\:-inset-2\.5 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-3\.5,[dir='rtl'].xl\:rtl\:-inset-3\.5 {
    top: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    left: -0.35rem
  }

  [dir='rtl'] .xl\:rtl\:inset-1\/2,[dir='rtl'].xl\:rtl\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='rtl'] .xl\:rtl\:inset-1\/3,[dir='rtl'].xl\:rtl\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  [dir='rtl'] .xl\:rtl\:inset-2\/3,[dir='rtl'].xl\:rtl\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  [dir='rtl'] .xl\:rtl\:inset-1\/4,[dir='rtl'].xl\:rtl\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  [dir='rtl'] .xl\:rtl\:inset-2\/4,[dir='rtl'].xl\:rtl\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='rtl'] .xl\:rtl\:inset-3\/4,[dir='rtl'].xl\:rtl\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  [dir='rtl'] .xl\:rtl\:inset-full,[dir='rtl'].xl\:rtl\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  [dir='rtl'] .xl\:rtl\:-inset-1\/2,[dir='rtl'].xl\:rtl\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='rtl'] .xl\:rtl\:-inset-1\/3,[dir='rtl'].xl\:rtl\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  [dir='rtl'] .xl\:rtl\:-inset-2\/3,[dir='rtl'].xl\:rtl\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  [dir='rtl'] .xl\:rtl\:-inset-1\/4,[dir='rtl'].xl\:rtl\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  [dir='rtl'] .xl\:rtl\:-inset-2\/4,[dir='rtl'].xl\:rtl\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='rtl'] .xl\:rtl\:-inset-3\/4,[dir='rtl'].xl\:rtl\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  [dir='rtl'] .xl\:rtl\:-inset-full,[dir='rtl'].xl\:rtl\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  [dir='rtl'] .xl\:rtl\:inset-y-0,[dir='rtl'].xl\:rtl\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  [dir='rtl'] .xl\:rtl\:inset-x-0,[dir='rtl'].xl\:rtl\:inset-x-0 {
    right: 0;
    left: 0
  }

  [dir='rtl'] .xl\:rtl\:inset-y-1,[dir='rtl'].xl\:rtl\:inset-y-1 {
    top: 0.1rem;
    bottom: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-1,[dir='rtl'].xl\:rtl\:inset-x-1 {
    right: 0.1rem;
    left: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-2,[dir='rtl'].xl\:rtl\:inset-y-2 {
    top: 0.2rem;
    bottom: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-2,[dir='rtl'].xl\:rtl\:inset-x-2 {
    right: 0.2rem;
    left: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-3,[dir='rtl'].xl\:rtl\:inset-y-3 {
    top: 0.3rem;
    bottom: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-3,[dir='rtl'].xl\:rtl\:inset-x-3 {
    right: 0.3rem;
    left: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-4,[dir='rtl'].xl\:rtl\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-4,[dir='rtl'].xl\:rtl\:inset-x-4 {
    right: 0.4rem;
    left: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-5,[dir='rtl'].xl\:rtl\:inset-y-5 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-5,[dir='rtl'].xl\:rtl\:inset-x-5 {
    right: 0.5rem;
    left: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-6,[dir='rtl'].xl\:rtl\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-6,[dir='rtl'].xl\:rtl\:inset-x-6 {
    right: 0.6rem;
    left: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-7,[dir='rtl'].xl\:rtl\:inset-y-7 {
    top: 0.7rem;
    bottom: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-7,[dir='rtl'].xl\:rtl\:inset-x-7 {
    right: 0.7rem;
    left: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-8,[dir='rtl'].xl\:rtl\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-8,[dir='rtl'].xl\:rtl\:inset-x-8 {
    right: 0.8rem;
    left: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-9,[dir='rtl'].xl\:rtl\:inset-y-9 {
    top: 0.9rem;
    bottom: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-9,[dir='rtl'].xl\:rtl\:inset-x-9 {
    right: 0.9rem;
    left: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-10,[dir='rtl'].xl\:rtl\:inset-y-10 {
    top: 1.0rem;
    bottom: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-10,[dir='rtl'].xl\:rtl\:inset-x-10 {
    right: 1.0rem;
    left: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-12,[dir='rtl'].xl\:rtl\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-12,[dir='rtl'].xl\:rtl\:inset-x-12 {
    right: 1.2rem;
    left: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-14,[dir='rtl'].xl\:rtl\:inset-y-14 {
    top: 1.4rem;
    bottom: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-14,[dir='rtl'].xl\:rtl\:inset-x-14 {
    right: 1.4rem;
    left: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-16,[dir='rtl'].xl\:rtl\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-16,[dir='rtl'].xl\:rtl\:inset-x-16 {
    right: 1.6rem;
    left: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-20,[dir='rtl'].xl\:rtl\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-20,[dir='rtl'].xl\:rtl\:inset-x-20 {
    right: 2rem;
    left: 2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-24,[dir='rtl'].xl\:rtl\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-24,[dir='rtl'].xl\:rtl\:inset-x-24 {
    right: 2.4rem;
    left: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-28,[dir='rtl'].xl\:rtl\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-28,[dir='rtl'].xl\:rtl\:inset-x-28 {
    right: 2.8rem;
    left: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-32,[dir='rtl'].xl\:rtl\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-32,[dir='rtl'].xl\:rtl\:inset-x-32 {
    right: 3.2rem;
    left: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-36,[dir='rtl'].xl\:rtl\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-36,[dir='rtl'].xl\:rtl\:inset-x-36 {
    right: 3.6rem;
    left: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-40,[dir='rtl'].xl\:rtl\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-40,[dir='rtl'].xl\:rtl\:inset-x-40 {
    right: 4rem;
    left: 4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-44,[dir='rtl'].xl\:rtl\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-44,[dir='rtl'].xl\:rtl\:inset-x-44 {
    right: 4.4rem;
    left: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-48,[dir='rtl'].xl\:rtl\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-48,[dir='rtl'].xl\:rtl\:inset-x-48 {
    right: 4.8rem;
    left: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-52,[dir='rtl'].xl\:rtl\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-52,[dir='rtl'].xl\:rtl\:inset-x-52 {
    right: 5.2rem;
    left: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-56,[dir='rtl'].xl\:rtl\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-56,[dir='rtl'].xl\:rtl\:inset-x-56 {
    right: 5.6rem;
    left: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-60,[dir='rtl'].xl\:rtl\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-60,[dir='rtl'].xl\:rtl\:inset-x-60 {
    right: 6rem;
    left: 6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-64,[dir='rtl'].xl\:rtl\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-64,[dir='rtl'].xl\:rtl\:inset-x-64 {
    right: 6.4rem;
    left: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-68,[dir='rtl'].xl\:rtl\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-68,[dir='rtl'].xl\:rtl\:inset-x-68 {
    right: 6.8rem;
    left: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-72,[dir='rtl'].xl\:rtl\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-72,[dir='rtl'].xl\:rtl\:inset-x-72 {
    right: 7.2rem;
    left: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-76,[dir='rtl'].xl\:rtl\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-76,[dir='rtl'].xl\:rtl\:inset-x-76 {
    right: 7.6rem;
    left: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-80,[dir='rtl'].xl\:rtl\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-80,[dir='rtl'].xl\:rtl\:inset-x-80 {
    right: 8rem;
    left: 8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-84,[dir='rtl'].xl\:rtl\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-84,[dir='rtl'].xl\:rtl\:inset-x-84 {
    right: 8.4rem;
    left: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-88,[dir='rtl'].xl\:rtl\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-88,[dir='rtl'].xl\:rtl\:inset-x-88 {
    right: 8.8rem;
    left: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-92,[dir='rtl'].xl\:rtl\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-92,[dir='rtl'].xl\:rtl\:inset-x-92 {
    right: 9.2rem;
    left: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-96,[dir='rtl'].xl\:rtl\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-96,[dir='rtl'].xl\:rtl\:inset-x-96 {
    right: 9.6rem;
    left: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-128,[dir='rtl'].xl\:rtl\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-128,[dir='rtl'].xl\:rtl\:inset-x-128 {
    right: 12.8rem;
    left: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-136,[dir='rtl'].xl\:rtl\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-136,[dir='rtl'].xl\:rtl\:inset-x-136 {
    right: 13.6rem;
    left: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-160,[dir='rtl'].xl\:rtl\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-160,[dir='rtl'].xl\:rtl\:inset-x-160 {
    right: 16rem;
    left: 16rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-192,[dir='rtl'].xl\:rtl\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-192,[dir='rtl'].xl\:rtl\:inset-x-192 {
    right: 19.2rem;
    left: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-200,[dir='rtl'].xl\:rtl\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-200,[dir='rtl'].xl\:rtl\:inset-x-200 {
    right: 20rem;
    left: 20rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-208,[dir='rtl'].xl\:rtl\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-208,[dir='rtl'].xl\:rtl\:inset-x-208 {
    right: 20.8rem;
    left: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-216,[dir='rtl'].xl\:rtl\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-216,[dir='rtl'].xl\:rtl\:inset-x-216 {
    right: 21.6rem;
    left: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-224,[dir='rtl'].xl\:rtl\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-224,[dir='rtl'].xl\:rtl\:inset-x-224 {
    right: 22.4rem;
    left: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-256,[dir='rtl'].xl\:rtl\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-256,[dir='rtl'].xl\:rtl\:inset-x-256 {
    right: 25.6rem;
    left: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-288,[dir='rtl'].xl\:rtl\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-288,[dir='rtl'].xl\:rtl\:inset-x-288 {
    right: 28.8rem;
    left: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-320,[dir='rtl'].xl\:rtl\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-320,[dir='rtl'].xl\:rtl\:inset-x-320 {
    right: 32rem;
    left: 32rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-360,[dir='rtl'].xl\:rtl\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-360,[dir='rtl'].xl\:rtl\:inset-x-360 {
    right: 36rem;
    left: 36rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-384,[dir='rtl'].xl\:rtl\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-384,[dir='rtl'].xl\:rtl\:inset-x-384 {
    right: 38.4rem;
    left: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-400,[dir='rtl'].xl\:rtl\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-400,[dir='rtl'].xl\:rtl\:inset-x-400 {
    right: 40rem;
    left: 40rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-512,[dir='rtl'].xl\:rtl\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-512,[dir='rtl'].xl\:rtl\:inset-x-512 {
    right: 51.2rem;
    left: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-640,[dir='rtl'].xl\:rtl\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-640,[dir='rtl'].xl\:rtl\:inset-x-640 {
    right: 64rem;
    left: 64rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-auto,[dir='rtl'].xl\:rtl\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  [dir='rtl'] .xl\:rtl\:inset-x-auto,[dir='rtl'].xl\:rtl\:inset-x-auto {
    right: auto;
    left: auto
  }

  [dir='rtl'] .xl\:rtl\:inset-y-xs,[dir='rtl'].xl\:rtl\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-xs,[dir='rtl'].xl\:rtl\:inset-x-xs {
    right: 32rem;
    left: 32rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-sm,[dir='rtl'].xl\:rtl\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-sm,[dir='rtl'].xl\:rtl\:inset-x-sm {
    right: 48rem;
    left: 48rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-md,[dir='rtl'].xl\:rtl\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-md,[dir='rtl'].xl\:rtl\:inset-x-md {
    right: 64rem;
    left: 64rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-lg,[dir='rtl'].xl\:rtl\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-lg,[dir='rtl'].xl\:rtl\:inset-x-lg {
    right: 80rem;
    left: 80rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-xl,[dir='rtl'].xl\:rtl\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-xl,[dir='rtl'].xl\:rtl\:inset-x-xl {
    right: 96rem;
    left: 96rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-2xl,[dir='rtl'].xl\:rtl\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-2xl,[dir='rtl'].xl\:rtl\:inset-x-2xl {
    right: 112rem;
    left: 112rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-3xl,[dir='rtl'].xl\:rtl\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-3xl,[dir='rtl'].xl\:rtl\:inset-x-3xl {
    right: 128rem;
    left: 128rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-4xl,[dir='rtl'].xl\:rtl\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-4xl,[dir='rtl'].xl\:rtl\:inset-x-4xl {
    right: 144rem;
    left: 144rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-5xl,[dir='rtl'].xl\:rtl\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-5xl,[dir='rtl'].xl\:rtl\:inset-x-5xl {
    right: 160rem;
    left: 160rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-px,[dir='rtl'].xl\:rtl\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  [dir='rtl'] .xl\:rtl\:inset-x-px,[dir='rtl'].xl\:rtl\:inset-x-px {
    right: 1px;
    left: 1px
  }

  [dir='rtl'] .xl\:rtl\:inset-y-0\.5,[dir='rtl'].xl\:rtl\:inset-y-0\.5 {
    top: 0.05rem;
    bottom: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-0\.5,[dir='rtl'].xl\:rtl\:inset-x-0\.5 {
    right: 0.05rem;
    left: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-1\.5,[dir='rtl'].xl\:rtl\:inset-y-1\.5 {
    top: 0.15rem;
    bottom: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-1\.5,[dir='rtl'].xl\:rtl\:inset-x-1\.5 {
    right: 0.15rem;
    left: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-2\.5,[dir='rtl'].xl\:rtl\:inset-y-2\.5 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-2\.5,[dir='rtl'].xl\:rtl\:inset-x-2\.5 {
    right: 0.25rem;
    left: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-3\.5,[dir='rtl'].xl\:rtl\:inset-y-3\.5 {
    top: 0.35rem;
    bottom: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:inset-x-3\.5,[dir='rtl'].xl\:rtl\:inset-x-3\.5 {
    right: 0.35rem;
    left: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-1,[dir='rtl'].xl\:rtl\:-inset-y-1 {
    top: -0.1rem;
    bottom: -0.1rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-1,[dir='rtl'].xl\:rtl\:-inset-x-1 {
    right: -0.1rem;
    left: -0.1rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-2,[dir='rtl'].xl\:rtl\:-inset-y-2 {
    top: -0.2rem;
    bottom: -0.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-2,[dir='rtl'].xl\:rtl\:-inset-x-2 {
    right: -0.2rem;
    left: -0.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-3,[dir='rtl'].xl\:rtl\:-inset-y-3 {
    top: -0.3rem;
    bottom: -0.3rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-3,[dir='rtl'].xl\:rtl\:-inset-x-3 {
    right: -0.3rem;
    left: -0.3rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-4,[dir='rtl'].xl\:rtl\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-4,[dir='rtl'].xl\:rtl\:-inset-x-4 {
    right: -0.4rem;
    left: -0.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-5,[dir='rtl'].xl\:rtl\:-inset-y-5 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-5,[dir='rtl'].xl\:rtl\:-inset-x-5 {
    right: -0.5rem;
    left: -0.5rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-6,[dir='rtl'].xl\:rtl\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-6,[dir='rtl'].xl\:rtl\:-inset-x-6 {
    right: -0.6rem;
    left: -0.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-7,[dir='rtl'].xl\:rtl\:-inset-y-7 {
    top: -0.7rem;
    bottom: -0.7rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-7,[dir='rtl'].xl\:rtl\:-inset-x-7 {
    right: -0.7rem;
    left: -0.7rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-8,[dir='rtl'].xl\:rtl\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-8,[dir='rtl'].xl\:rtl\:-inset-x-8 {
    right: -0.8rem;
    left: -0.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-9,[dir='rtl'].xl\:rtl\:-inset-y-9 {
    top: -0.9rem;
    bottom: -0.9rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-9,[dir='rtl'].xl\:rtl\:-inset-x-9 {
    right: -0.9rem;
    left: -0.9rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-10,[dir='rtl'].xl\:rtl\:-inset-y-10 {
    top: -1rem;
    bottom: -1rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-10,[dir='rtl'].xl\:rtl\:-inset-x-10 {
    right: -1rem;
    left: -1rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-12,[dir='rtl'].xl\:rtl\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-12,[dir='rtl'].xl\:rtl\:-inset-x-12 {
    right: -1.2rem;
    left: -1.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-14,[dir='rtl'].xl\:rtl\:-inset-y-14 {
    top: -1.4rem;
    bottom: -1.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-14,[dir='rtl'].xl\:rtl\:-inset-x-14 {
    right: -1.4rem;
    left: -1.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-16,[dir='rtl'].xl\:rtl\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-16,[dir='rtl'].xl\:rtl\:-inset-x-16 {
    right: -1.6rem;
    left: -1.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-20,[dir='rtl'].xl\:rtl\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-20,[dir='rtl'].xl\:rtl\:-inset-x-20 {
    right: -2rem;
    left: -2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-24,[dir='rtl'].xl\:rtl\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-24,[dir='rtl'].xl\:rtl\:-inset-x-24 {
    right: -2.4rem;
    left: -2.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-28,[dir='rtl'].xl\:rtl\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-28,[dir='rtl'].xl\:rtl\:-inset-x-28 {
    right: -2.8rem;
    left: -2.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-32,[dir='rtl'].xl\:rtl\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-32,[dir='rtl'].xl\:rtl\:-inset-x-32 {
    right: -3.2rem;
    left: -3.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-36,[dir='rtl'].xl\:rtl\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-36,[dir='rtl'].xl\:rtl\:-inset-x-36 {
    right: -3.6rem;
    left: -3.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-40,[dir='rtl'].xl\:rtl\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-40,[dir='rtl'].xl\:rtl\:-inset-x-40 {
    right: -4rem;
    left: -4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-44,[dir='rtl'].xl\:rtl\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-44,[dir='rtl'].xl\:rtl\:-inset-x-44 {
    right: -4.4rem;
    left: -4.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-48,[dir='rtl'].xl\:rtl\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-48,[dir='rtl'].xl\:rtl\:-inset-x-48 {
    right: -4.8rem;
    left: -4.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-52,[dir='rtl'].xl\:rtl\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-52,[dir='rtl'].xl\:rtl\:-inset-x-52 {
    right: -5.2rem;
    left: -5.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-56,[dir='rtl'].xl\:rtl\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-56,[dir='rtl'].xl\:rtl\:-inset-x-56 {
    right: -5.6rem;
    left: -5.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-60,[dir='rtl'].xl\:rtl\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-60,[dir='rtl'].xl\:rtl\:-inset-x-60 {
    right: -6rem;
    left: -6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-64,[dir='rtl'].xl\:rtl\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-64,[dir='rtl'].xl\:rtl\:-inset-x-64 {
    right: -6.4rem;
    left: -6.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-68,[dir='rtl'].xl\:rtl\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-68,[dir='rtl'].xl\:rtl\:-inset-x-68 {
    right: -6.8rem;
    left: -6.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-72,[dir='rtl'].xl\:rtl\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-72,[dir='rtl'].xl\:rtl\:-inset-x-72 {
    right: -7.2rem;
    left: -7.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-76,[dir='rtl'].xl\:rtl\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-76,[dir='rtl'].xl\:rtl\:-inset-x-76 {
    right: -7.6rem;
    left: -7.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-80,[dir='rtl'].xl\:rtl\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-80,[dir='rtl'].xl\:rtl\:-inset-x-80 {
    right: -8rem;
    left: -8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-84,[dir='rtl'].xl\:rtl\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-84,[dir='rtl'].xl\:rtl\:-inset-x-84 {
    right: -8.4rem;
    left: -8.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-88,[dir='rtl'].xl\:rtl\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-88,[dir='rtl'].xl\:rtl\:-inset-x-88 {
    right: -8.8rem;
    left: -8.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-92,[dir='rtl'].xl\:rtl\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-92,[dir='rtl'].xl\:rtl\:-inset-x-92 {
    right: -9.2rem;
    left: -9.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-96,[dir='rtl'].xl\:rtl\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-96,[dir='rtl'].xl\:rtl\:-inset-x-96 {
    right: -9.6rem;
    left: -9.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-128,[dir='rtl'].xl\:rtl\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-128,[dir='rtl'].xl\:rtl\:-inset-x-128 {
    right: -12.8rem;
    left: -12.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-136,[dir='rtl'].xl\:rtl\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-136,[dir='rtl'].xl\:rtl\:-inset-x-136 {
    right: -13.6rem;
    left: -13.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-160,[dir='rtl'].xl\:rtl\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-160,[dir='rtl'].xl\:rtl\:-inset-x-160 {
    right: -16rem;
    left: -16rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-192,[dir='rtl'].xl\:rtl\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-192,[dir='rtl'].xl\:rtl\:-inset-x-192 {
    right: -19.2rem;
    left: -19.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-200,[dir='rtl'].xl\:rtl\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-200,[dir='rtl'].xl\:rtl\:-inset-x-200 {
    right: -20rem;
    left: -20rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-208,[dir='rtl'].xl\:rtl\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-208,[dir='rtl'].xl\:rtl\:-inset-x-208 {
    right: -20.8rem;
    left: -20.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-216,[dir='rtl'].xl\:rtl\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-216,[dir='rtl'].xl\:rtl\:-inset-x-216 {
    right: -21.6rem;
    left: -21.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-224,[dir='rtl'].xl\:rtl\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-224,[dir='rtl'].xl\:rtl\:-inset-x-224 {
    right: -22.4rem;
    left: -22.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-256,[dir='rtl'].xl\:rtl\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-256,[dir='rtl'].xl\:rtl\:-inset-x-256 {
    right: -25.6rem;
    left: -25.6rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-288,[dir='rtl'].xl\:rtl\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-288,[dir='rtl'].xl\:rtl\:-inset-x-288 {
    right: -28.8rem;
    left: -28.8rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-320,[dir='rtl'].xl\:rtl\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-320,[dir='rtl'].xl\:rtl\:-inset-x-320 {
    right: -32rem;
    left: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-360,[dir='rtl'].xl\:rtl\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-360,[dir='rtl'].xl\:rtl\:-inset-x-360 {
    right: -36rem;
    left: -36rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-384,[dir='rtl'].xl\:rtl\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-384,[dir='rtl'].xl\:rtl\:-inset-x-384 {
    right: -38.4rem;
    left: -38.4rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-400,[dir='rtl'].xl\:rtl\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-400,[dir='rtl'].xl\:rtl\:-inset-x-400 {
    right: -40rem;
    left: -40rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-512,[dir='rtl'].xl\:rtl\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-512,[dir='rtl'].xl\:rtl\:-inset-x-512 {
    right: -51.2rem;
    left: -51.2rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-640,[dir='rtl'].xl\:rtl\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-640,[dir='rtl'].xl\:rtl\:-inset-x-640 {
    right: -64rem;
    left: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-xs,[dir='rtl'].xl\:rtl\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-xs,[dir='rtl'].xl\:rtl\:-inset-x-xs {
    right: -32rem;
    left: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-sm,[dir='rtl'].xl\:rtl\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-sm,[dir='rtl'].xl\:rtl\:-inset-x-sm {
    right: -48rem;
    left: -48rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-md,[dir='rtl'].xl\:rtl\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-md,[dir='rtl'].xl\:rtl\:-inset-x-md {
    right: -64rem;
    left: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-lg,[dir='rtl'].xl\:rtl\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-lg,[dir='rtl'].xl\:rtl\:-inset-x-lg {
    right: -80rem;
    left: -80rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-xl,[dir='rtl'].xl\:rtl\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-xl,[dir='rtl'].xl\:rtl\:-inset-x-xl {
    right: -96rem;
    left: -96rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-2xl,[dir='rtl'].xl\:rtl\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-2xl,[dir='rtl'].xl\:rtl\:-inset-x-2xl {
    right: -112rem;
    left: -112rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-3xl,[dir='rtl'].xl\:rtl\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-3xl,[dir='rtl'].xl\:rtl\:-inset-x-3xl {
    right: -128rem;
    left: -128rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-4xl,[dir='rtl'].xl\:rtl\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-4xl,[dir='rtl'].xl\:rtl\:-inset-x-4xl {
    right: -144rem;
    left: -144rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-5xl,[dir='rtl'].xl\:rtl\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-5xl,[dir='rtl'].xl\:rtl\:-inset-x-5xl {
    right: -160rem;
    left: -160rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-px,[dir='rtl'].xl\:rtl\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-px,[dir='rtl'].xl\:rtl\:-inset-x-px {
    right: -1px;
    left: -1px
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-0\.5,[dir='rtl'].xl\:rtl\:-inset-y-0\.5 {
    top: -0.05rem;
    bottom: -0.05rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-0\.5,[dir='rtl'].xl\:rtl\:-inset-x-0\.5 {
    right: -0.05rem;
    left: -0.05rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-1\.5,[dir='rtl'].xl\:rtl\:-inset-y-1\.5 {
    top: -0.15rem;
    bottom: -0.15rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-1\.5,[dir='rtl'].xl\:rtl\:-inset-x-1\.5 {
    right: -0.15rem;
    left: -0.15rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-2\.5,[dir='rtl'].xl\:rtl\:-inset-y-2\.5 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-2\.5,[dir='rtl'].xl\:rtl\:-inset-x-2\.5 {
    right: -0.25rem;
    left: -0.25rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-3\.5,[dir='rtl'].xl\:rtl\:-inset-y-3\.5 {
    top: -0.35rem;
    bottom: -0.35rem
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-3\.5,[dir='rtl'].xl\:rtl\:-inset-x-3\.5 {
    right: -0.35rem;
    left: -0.35rem
  }

  [dir='rtl'] .xl\:rtl\:inset-y-1\/2,[dir='rtl'].xl\:rtl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  [dir='rtl'] .xl\:rtl\:inset-x-1\/2,[dir='rtl'].xl\:rtl\:inset-x-1\/2 {
    right: 50%;
    left: 50%
  }

  [dir='rtl'] .xl\:rtl\:inset-y-1\/3,[dir='rtl'].xl\:rtl\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  [dir='rtl'] .xl\:rtl\:inset-x-1\/3,[dir='rtl'].xl\:rtl\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%
  }

  [dir='rtl'] .xl\:rtl\:inset-y-2\/3,[dir='rtl'].xl\:rtl\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  [dir='rtl'] .xl\:rtl\:inset-x-2\/3,[dir='rtl'].xl\:rtl\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%
  }

  [dir='rtl'] .xl\:rtl\:inset-y-1\/4,[dir='rtl'].xl\:rtl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  [dir='rtl'] .xl\:rtl\:inset-x-1\/4,[dir='rtl'].xl\:rtl\:inset-x-1\/4 {
    right: 25%;
    left: 25%
  }

  [dir='rtl'] .xl\:rtl\:inset-y-2\/4,[dir='rtl'].xl\:rtl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  [dir='rtl'] .xl\:rtl\:inset-x-2\/4,[dir='rtl'].xl\:rtl\:inset-x-2\/4 {
    right: 50%;
    left: 50%
  }

  [dir='rtl'] .xl\:rtl\:inset-y-3\/4,[dir='rtl'].xl\:rtl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  [dir='rtl'] .xl\:rtl\:inset-x-3\/4,[dir='rtl'].xl\:rtl\:inset-x-3\/4 {
    right: 75%;
    left: 75%
  }

  [dir='rtl'] .xl\:rtl\:inset-y-full,[dir='rtl'].xl\:rtl\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  [dir='rtl'] .xl\:rtl\:inset-x-full,[dir='rtl'].xl\:rtl\:inset-x-full {
    right: 100%;
    left: 100%
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-1\/2,[dir='rtl'].xl\:rtl\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-1\/2,[dir='rtl'].xl\:rtl\:-inset-x-1\/2 {
    right: -50%;
    left: -50%
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-1\/3,[dir='rtl'].xl\:rtl\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-1\/3,[dir='rtl'].xl\:rtl\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-2\/3,[dir='rtl'].xl\:rtl\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-2\/3,[dir='rtl'].xl\:rtl\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-1\/4,[dir='rtl'].xl\:rtl\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-1\/4,[dir='rtl'].xl\:rtl\:-inset-x-1\/4 {
    right: -25%;
    left: -25%
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-2\/4,[dir='rtl'].xl\:rtl\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-2\/4,[dir='rtl'].xl\:rtl\:-inset-x-2\/4 {
    right: -50%;
    left: -50%
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-3\/4,[dir='rtl'].xl\:rtl\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-3\/4,[dir='rtl'].xl\:rtl\:-inset-x-3\/4 {
    right: -75%;
    left: -75%
  }

  [dir='rtl'] .xl\:rtl\:-inset-y-full,[dir='rtl'].xl\:rtl\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  [dir='rtl'] .xl\:rtl\:-inset-x-full,[dir='rtl'].xl\:rtl\:-inset-x-full {
    right: -100%;
    left: -100%
  }

  [dir='rtl'] .xl\:rtl\:top-0,[dir='rtl'].xl\:rtl\:top-0 {
    top: 0
  }

  [dir='rtl'] .xl\:rtl\:right-0,[dir='rtl'].xl\:rtl\:right-0 {
    right: 0
  }

  [dir='rtl'] .xl\:rtl\:bottom-0,[dir='rtl'].xl\:rtl\:bottom-0 {
    bottom: 0
  }

  [dir='rtl'] .xl\:rtl\:left-0,[dir='rtl'].xl\:rtl\:left-0 {
    left: 0
  }

  [dir='rtl'] .xl\:rtl\:top-1,[dir='rtl'].xl\:rtl\:top-1 {
    top: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:right-1,[dir='rtl'].xl\:rtl\:right-1 {
    right: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-1,[dir='rtl'].xl\:rtl\:bottom-1 {
    bottom: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:left-1,[dir='rtl'].xl\:rtl\:left-1 {
    left: 0.1rem
  }

  [dir='rtl'] .xl\:rtl\:top-2,[dir='rtl'].xl\:rtl\:top-2 {
    top: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:right-2,[dir='rtl'].xl\:rtl\:right-2 {
    right: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-2,[dir='rtl'].xl\:rtl\:bottom-2 {
    bottom: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:left-2,[dir='rtl'].xl\:rtl\:left-2 {
    left: 0.2rem
  }

  [dir='rtl'] .xl\:rtl\:top-3,[dir='rtl'].xl\:rtl\:top-3 {
    top: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:right-3,[dir='rtl'].xl\:rtl\:right-3 {
    right: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-3,[dir='rtl'].xl\:rtl\:bottom-3 {
    bottom: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:left-3,[dir='rtl'].xl\:rtl\:left-3 {
    left: 0.3rem
  }

  [dir='rtl'] .xl\:rtl\:top-4,[dir='rtl'].xl\:rtl\:top-4 {
    top: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:right-4,[dir='rtl'].xl\:rtl\:right-4 {
    right: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-4,[dir='rtl'].xl\:rtl\:bottom-4 {
    bottom: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:left-4,[dir='rtl'].xl\:rtl\:left-4 {
    left: 0.4rem
  }

  [dir='rtl'] .xl\:rtl\:top-5,[dir='rtl'].xl\:rtl\:top-5 {
    top: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:right-5,[dir='rtl'].xl\:rtl\:right-5 {
    right: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-5,[dir='rtl'].xl\:rtl\:bottom-5 {
    bottom: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:left-5,[dir='rtl'].xl\:rtl\:left-5 {
    left: 0.5rem
  }

  [dir='rtl'] .xl\:rtl\:top-6,[dir='rtl'].xl\:rtl\:top-6 {
    top: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:right-6,[dir='rtl'].xl\:rtl\:right-6 {
    right: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-6,[dir='rtl'].xl\:rtl\:bottom-6 {
    bottom: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:left-6,[dir='rtl'].xl\:rtl\:left-6 {
    left: 0.6rem
  }

  [dir='rtl'] .xl\:rtl\:top-7,[dir='rtl'].xl\:rtl\:top-7 {
    top: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:right-7,[dir='rtl'].xl\:rtl\:right-7 {
    right: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-7,[dir='rtl'].xl\:rtl\:bottom-7 {
    bottom: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:left-7,[dir='rtl'].xl\:rtl\:left-7 {
    left: 0.7rem
  }

  [dir='rtl'] .xl\:rtl\:top-8,[dir='rtl'].xl\:rtl\:top-8 {
    top: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:right-8,[dir='rtl'].xl\:rtl\:right-8 {
    right: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-8,[dir='rtl'].xl\:rtl\:bottom-8 {
    bottom: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:left-8,[dir='rtl'].xl\:rtl\:left-8 {
    left: 0.8rem
  }

  [dir='rtl'] .xl\:rtl\:top-9,[dir='rtl'].xl\:rtl\:top-9 {
    top: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:right-9,[dir='rtl'].xl\:rtl\:right-9 {
    right: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-9,[dir='rtl'].xl\:rtl\:bottom-9 {
    bottom: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:left-9,[dir='rtl'].xl\:rtl\:left-9 {
    left: 0.9rem
  }

  [dir='rtl'] .xl\:rtl\:top-10,[dir='rtl'].xl\:rtl\:top-10 {
    top: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:right-10,[dir='rtl'].xl\:rtl\:right-10 {
    right: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-10,[dir='rtl'].xl\:rtl\:bottom-10 {
    bottom: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:left-10,[dir='rtl'].xl\:rtl\:left-10 {
    left: 1.0rem
  }

  [dir='rtl'] .xl\:rtl\:top-12,[dir='rtl'].xl\:rtl\:top-12 {
    top: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:right-12,[dir='rtl'].xl\:rtl\:right-12 {
    right: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-12,[dir='rtl'].xl\:rtl\:bottom-12 {
    bottom: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:left-12,[dir='rtl'].xl\:rtl\:left-12 {
    left: 1.2rem
  }

  [dir='rtl'] .xl\:rtl\:top-14,[dir='rtl'].xl\:rtl\:top-14 {
    top: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:right-14,[dir='rtl'].xl\:rtl\:right-14 {
    right: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-14,[dir='rtl'].xl\:rtl\:bottom-14 {
    bottom: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:left-14,[dir='rtl'].xl\:rtl\:left-14 {
    left: 1.4rem
  }

  [dir='rtl'] .xl\:rtl\:top-16,[dir='rtl'].xl\:rtl\:top-16 {
    top: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:right-16,[dir='rtl'].xl\:rtl\:right-16 {
    right: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-16,[dir='rtl'].xl\:rtl\:bottom-16 {
    bottom: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:left-16,[dir='rtl'].xl\:rtl\:left-16 {
    left: 1.6rem
  }

  [dir='rtl'] .xl\:rtl\:top-20,[dir='rtl'].xl\:rtl\:top-20 {
    top: 2rem
  }

  [dir='rtl'] .xl\:rtl\:right-20,[dir='rtl'].xl\:rtl\:right-20 {
    right: 2rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-20,[dir='rtl'].xl\:rtl\:bottom-20 {
    bottom: 2rem
  }

  [dir='rtl'] .xl\:rtl\:left-20,[dir='rtl'].xl\:rtl\:left-20 {
    left: 2rem
  }

  [dir='rtl'] .xl\:rtl\:top-24,[dir='rtl'].xl\:rtl\:top-24 {
    top: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:right-24,[dir='rtl'].xl\:rtl\:right-24 {
    right: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-24,[dir='rtl'].xl\:rtl\:bottom-24 {
    bottom: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:left-24,[dir='rtl'].xl\:rtl\:left-24 {
    left: 2.4rem
  }

  [dir='rtl'] .xl\:rtl\:top-28,[dir='rtl'].xl\:rtl\:top-28 {
    top: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:right-28,[dir='rtl'].xl\:rtl\:right-28 {
    right: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-28,[dir='rtl'].xl\:rtl\:bottom-28 {
    bottom: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:left-28,[dir='rtl'].xl\:rtl\:left-28 {
    left: 2.8rem
  }

  [dir='rtl'] .xl\:rtl\:top-32,[dir='rtl'].xl\:rtl\:top-32 {
    top: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:right-32,[dir='rtl'].xl\:rtl\:right-32 {
    right: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-32,[dir='rtl'].xl\:rtl\:bottom-32 {
    bottom: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:left-32,[dir='rtl'].xl\:rtl\:left-32 {
    left: 3.2rem
  }

  [dir='rtl'] .xl\:rtl\:top-36,[dir='rtl'].xl\:rtl\:top-36 {
    top: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:right-36,[dir='rtl'].xl\:rtl\:right-36 {
    right: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-36,[dir='rtl'].xl\:rtl\:bottom-36 {
    bottom: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:left-36,[dir='rtl'].xl\:rtl\:left-36 {
    left: 3.6rem
  }

  [dir='rtl'] .xl\:rtl\:top-40,[dir='rtl'].xl\:rtl\:top-40 {
    top: 4rem
  }

  [dir='rtl'] .xl\:rtl\:right-40,[dir='rtl'].xl\:rtl\:right-40 {
    right: 4rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-40,[dir='rtl'].xl\:rtl\:bottom-40 {
    bottom: 4rem
  }

  [dir='rtl'] .xl\:rtl\:left-40,[dir='rtl'].xl\:rtl\:left-40 {
    left: 4rem
  }

  [dir='rtl'] .xl\:rtl\:top-44,[dir='rtl'].xl\:rtl\:top-44 {
    top: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:right-44,[dir='rtl'].xl\:rtl\:right-44 {
    right: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-44,[dir='rtl'].xl\:rtl\:bottom-44 {
    bottom: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:left-44,[dir='rtl'].xl\:rtl\:left-44 {
    left: 4.4rem
  }

  [dir='rtl'] .xl\:rtl\:top-48,[dir='rtl'].xl\:rtl\:top-48 {
    top: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:right-48,[dir='rtl'].xl\:rtl\:right-48 {
    right: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-48,[dir='rtl'].xl\:rtl\:bottom-48 {
    bottom: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:left-48,[dir='rtl'].xl\:rtl\:left-48 {
    left: 4.8rem
  }

  [dir='rtl'] .xl\:rtl\:top-52,[dir='rtl'].xl\:rtl\:top-52 {
    top: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:right-52,[dir='rtl'].xl\:rtl\:right-52 {
    right: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-52,[dir='rtl'].xl\:rtl\:bottom-52 {
    bottom: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:left-52,[dir='rtl'].xl\:rtl\:left-52 {
    left: 5.2rem
  }

  [dir='rtl'] .xl\:rtl\:top-56,[dir='rtl'].xl\:rtl\:top-56 {
    top: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:right-56,[dir='rtl'].xl\:rtl\:right-56 {
    right: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-56,[dir='rtl'].xl\:rtl\:bottom-56 {
    bottom: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:left-56,[dir='rtl'].xl\:rtl\:left-56 {
    left: 5.6rem
  }

  [dir='rtl'] .xl\:rtl\:top-60,[dir='rtl'].xl\:rtl\:top-60 {
    top: 6rem
  }

  [dir='rtl'] .xl\:rtl\:right-60,[dir='rtl'].xl\:rtl\:right-60 {
    right: 6rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-60,[dir='rtl'].xl\:rtl\:bottom-60 {
    bottom: 6rem
  }

  [dir='rtl'] .xl\:rtl\:left-60,[dir='rtl'].xl\:rtl\:left-60 {
    left: 6rem
  }

  [dir='rtl'] .xl\:rtl\:top-64,[dir='rtl'].xl\:rtl\:top-64 {
    top: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:right-64,[dir='rtl'].xl\:rtl\:right-64 {
    right: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-64,[dir='rtl'].xl\:rtl\:bottom-64 {
    bottom: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:left-64,[dir='rtl'].xl\:rtl\:left-64 {
    left: 6.4rem
  }

  [dir='rtl'] .xl\:rtl\:top-68,[dir='rtl'].xl\:rtl\:top-68 {
    top: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:right-68,[dir='rtl'].xl\:rtl\:right-68 {
    right: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-68,[dir='rtl'].xl\:rtl\:bottom-68 {
    bottom: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:left-68,[dir='rtl'].xl\:rtl\:left-68 {
    left: 6.8rem
  }

  [dir='rtl'] .xl\:rtl\:top-72,[dir='rtl'].xl\:rtl\:top-72 {
    top: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:right-72,[dir='rtl'].xl\:rtl\:right-72 {
    right: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-72,[dir='rtl'].xl\:rtl\:bottom-72 {
    bottom: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:left-72,[dir='rtl'].xl\:rtl\:left-72 {
    left: 7.2rem
  }

  [dir='rtl'] .xl\:rtl\:top-76,[dir='rtl'].xl\:rtl\:top-76 {
    top: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:right-76,[dir='rtl'].xl\:rtl\:right-76 {
    right: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-76,[dir='rtl'].xl\:rtl\:bottom-76 {
    bottom: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:left-76,[dir='rtl'].xl\:rtl\:left-76 {
    left: 7.6rem
  }

  [dir='rtl'] .xl\:rtl\:top-80,[dir='rtl'].xl\:rtl\:top-80 {
    top: 8rem
  }

  [dir='rtl'] .xl\:rtl\:right-80,[dir='rtl'].xl\:rtl\:right-80 {
    right: 8rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-80,[dir='rtl'].xl\:rtl\:bottom-80 {
    bottom: 8rem
  }

  [dir='rtl'] .xl\:rtl\:left-80,[dir='rtl'].xl\:rtl\:left-80 {
    left: 8rem
  }

  [dir='rtl'] .xl\:rtl\:top-84,[dir='rtl'].xl\:rtl\:top-84 {
    top: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:right-84,[dir='rtl'].xl\:rtl\:right-84 {
    right: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-84,[dir='rtl'].xl\:rtl\:bottom-84 {
    bottom: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:left-84,[dir='rtl'].xl\:rtl\:left-84 {
    left: 8.4rem
  }

  [dir='rtl'] .xl\:rtl\:top-88,[dir='rtl'].xl\:rtl\:top-88 {
    top: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:right-88,[dir='rtl'].xl\:rtl\:right-88 {
    right: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-88,[dir='rtl'].xl\:rtl\:bottom-88 {
    bottom: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:left-88,[dir='rtl'].xl\:rtl\:left-88 {
    left: 8.8rem
  }

  [dir='rtl'] .xl\:rtl\:top-92,[dir='rtl'].xl\:rtl\:top-92 {
    top: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:right-92,[dir='rtl'].xl\:rtl\:right-92 {
    right: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-92,[dir='rtl'].xl\:rtl\:bottom-92 {
    bottom: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:left-92,[dir='rtl'].xl\:rtl\:left-92 {
    left: 9.2rem
  }

  [dir='rtl'] .xl\:rtl\:top-96,[dir='rtl'].xl\:rtl\:top-96 {
    top: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:right-96,[dir='rtl'].xl\:rtl\:right-96 {
    right: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-96,[dir='rtl'].xl\:rtl\:bottom-96 {
    bottom: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:left-96,[dir='rtl'].xl\:rtl\:left-96 {
    left: 9.6rem
  }

  [dir='rtl'] .xl\:rtl\:top-128,[dir='rtl'].xl\:rtl\:top-128 {
    top: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:right-128,[dir='rtl'].xl\:rtl\:right-128 {
    right: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-128,[dir='rtl'].xl\:rtl\:bottom-128 {
    bottom: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:left-128,[dir='rtl'].xl\:rtl\:left-128 {
    left: 12.8rem
  }

  [dir='rtl'] .xl\:rtl\:top-136,[dir='rtl'].xl\:rtl\:top-136 {
    top: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:right-136,[dir='rtl'].xl\:rtl\:right-136 {
    right: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-136,[dir='rtl'].xl\:rtl\:bottom-136 {
    bottom: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:left-136,[dir='rtl'].xl\:rtl\:left-136 {
    left: 13.6rem
  }

  [dir='rtl'] .xl\:rtl\:top-160,[dir='rtl'].xl\:rtl\:top-160 {
    top: 16rem
  }

  [dir='rtl'] .xl\:rtl\:right-160,[dir='rtl'].xl\:rtl\:right-160 {
    right: 16rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-160,[dir='rtl'].xl\:rtl\:bottom-160 {
    bottom: 16rem
  }

  [dir='rtl'] .xl\:rtl\:left-160,[dir='rtl'].xl\:rtl\:left-160 {
    left: 16rem
  }

  [dir='rtl'] .xl\:rtl\:top-192,[dir='rtl'].xl\:rtl\:top-192 {
    top: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:right-192,[dir='rtl'].xl\:rtl\:right-192 {
    right: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-192,[dir='rtl'].xl\:rtl\:bottom-192 {
    bottom: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:left-192,[dir='rtl'].xl\:rtl\:left-192 {
    left: 19.2rem
  }

  [dir='rtl'] .xl\:rtl\:top-200,[dir='rtl'].xl\:rtl\:top-200 {
    top: 20rem
  }

  [dir='rtl'] .xl\:rtl\:right-200,[dir='rtl'].xl\:rtl\:right-200 {
    right: 20rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-200,[dir='rtl'].xl\:rtl\:bottom-200 {
    bottom: 20rem
  }

  [dir='rtl'] .xl\:rtl\:left-200,[dir='rtl'].xl\:rtl\:left-200 {
    left: 20rem
  }

  [dir='rtl'] .xl\:rtl\:top-208,[dir='rtl'].xl\:rtl\:top-208 {
    top: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:right-208,[dir='rtl'].xl\:rtl\:right-208 {
    right: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-208,[dir='rtl'].xl\:rtl\:bottom-208 {
    bottom: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:left-208,[dir='rtl'].xl\:rtl\:left-208 {
    left: 20.8rem
  }

  [dir='rtl'] .xl\:rtl\:top-216,[dir='rtl'].xl\:rtl\:top-216 {
    top: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:right-216,[dir='rtl'].xl\:rtl\:right-216 {
    right: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-216,[dir='rtl'].xl\:rtl\:bottom-216 {
    bottom: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:left-216,[dir='rtl'].xl\:rtl\:left-216 {
    left: 21.6rem
  }

  [dir='rtl'] .xl\:rtl\:top-224,[dir='rtl'].xl\:rtl\:top-224 {
    top: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:right-224,[dir='rtl'].xl\:rtl\:right-224 {
    right: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-224,[dir='rtl'].xl\:rtl\:bottom-224 {
    bottom: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:left-224,[dir='rtl'].xl\:rtl\:left-224 {
    left: 22.4rem
  }

  [dir='rtl'] .xl\:rtl\:top-256,[dir='rtl'].xl\:rtl\:top-256 {
    top: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:right-256,[dir='rtl'].xl\:rtl\:right-256 {
    right: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-256,[dir='rtl'].xl\:rtl\:bottom-256 {
    bottom: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:left-256,[dir='rtl'].xl\:rtl\:left-256 {
    left: 25.6rem
  }

  [dir='rtl'] .xl\:rtl\:top-288,[dir='rtl'].xl\:rtl\:top-288 {
    top: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:right-288,[dir='rtl'].xl\:rtl\:right-288 {
    right: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-288,[dir='rtl'].xl\:rtl\:bottom-288 {
    bottom: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:left-288,[dir='rtl'].xl\:rtl\:left-288 {
    left: 28.8rem
  }

  [dir='rtl'] .xl\:rtl\:top-320,[dir='rtl'].xl\:rtl\:top-320 {
    top: 32rem
  }

  [dir='rtl'] .xl\:rtl\:right-320,[dir='rtl'].xl\:rtl\:right-320 {
    right: 32rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-320,[dir='rtl'].xl\:rtl\:bottom-320 {
    bottom: 32rem
  }

  [dir='rtl'] .xl\:rtl\:left-320,[dir='rtl'].xl\:rtl\:left-320 {
    left: 32rem
  }

  [dir='rtl'] .xl\:rtl\:top-360,[dir='rtl'].xl\:rtl\:top-360 {
    top: 36rem
  }

  [dir='rtl'] .xl\:rtl\:right-360,[dir='rtl'].xl\:rtl\:right-360 {
    right: 36rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-360,[dir='rtl'].xl\:rtl\:bottom-360 {
    bottom: 36rem
  }

  [dir='rtl'] .xl\:rtl\:left-360,[dir='rtl'].xl\:rtl\:left-360 {
    left: 36rem
  }

  [dir='rtl'] .xl\:rtl\:top-384,[dir='rtl'].xl\:rtl\:top-384 {
    top: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:right-384,[dir='rtl'].xl\:rtl\:right-384 {
    right: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-384,[dir='rtl'].xl\:rtl\:bottom-384 {
    bottom: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:left-384,[dir='rtl'].xl\:rtl\:left-384 {
    left: 38.4rem
  }

  [dir='rtl'] .xl\:rtl\:top-400,[dir='rtl'].xl\:rtl\:top-400 {
    top: 40rem
  }

  [dir='rtl'] .xl\:rtl\:right-400,[dir='rtl'].xl\:rtl\:right-400 {
    right: 40rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-400,[dir='rtl'].xl\:rtl\:bottom-400 {
    bottom: 40rem
  }

  [dir='rtl'] .xl\:rtl\:left-400,[dir='rtl'].xl\:rtl\:left-400 {
    left: 40rem
  }

  [dir='rtl'] .xl\:rtl\:top-512,[dir='rtl'].xl\:rtl\:top-512 {
    top: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:right-512,[dir='rtl'].xl\:rtl\:right-512 {
    right: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-512,[dir='rtl'].xl\:rtl\:bottom-512 {
    bottom: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:left-512,[dir='rtl'].xl\:rtl\:left-512 {
    left: 51.2rem
  }

  [dir='rtl'] .xl\:rtl\:top-640,[dir='rtl'].xl\:rtl\:top-640 {
    top: 64rem
  }

  [dir='rtl'] .xl\:rtl\:right-640,[dir='rtl'].xl\:rtl\:right-640 {
    right: 64rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-640,[dir='rtl'].xl\:rtl\:bottom-640 {
    bottom: 64rem
  }

  [dir='rtl'] .xl\:rtl\:left-640,[dir='rtl'].xl\:rtl\:left-640 {
    left: 64rem
  }

  [dir='rtl'] .xl\:rtl\:top-auto,[dir='rtl'].xl\:rtl\:top-auto {
    top: auto
  }

  [dir='rtl'] .xl\:rtl\:right-auto,[dir='rtl'].xl\:rtl\:right-auto {
    right: auto
  }

  [dir='rtl'] .xl\:rtl\:bottom-auto,[dir='rtl'].xl\:rtl\:bottom-auto {
    bottom: auto
  }

  [dir='rtl'] .xl\:rtl\:left-auto,[dir='rtl'].xl\:rtl\:left-auto {
    left: auto
  }

  [dir='rtl'] .xl\:rtl\:top-xs,[dir='rtl'].xl\:rtl\:top-xs {
    top: 32rem
  }

  [dir='rtl'] .xl\:rtl\:right-xs,[dir='rtl'].xl\:rtl\:right-xs {
    right: 32rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-xs,[dir='rtl'].xl\:rtl\:bottom-xs {
    bottom: 32rem
  }

  [dir='rtl'] .xl\:rtl\:left-xs,[dir='rtl'].xl\:rtl\:left-xs {
    left: 32rem
  }

  [dir='rtl'] .xl\:rtl\:top-sm,[dir='rtl'].xl\:rtl\:top-sm {
    top: 48rem
  }

  [dir='rtl'] .xl\:rtl\:right-sm,[dir='rtl'].xl\:rtl\:right-sm {
    right: 48rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-sm,[dir='rtl'].xl\:rtl\:bottom-sm {
    bottom: 48rem
  }

  [dir='rtl'] .xl\:rtl\:left-sm,[dir='rtl'].xl\:rtl\:left-sm {
    left: 48rem
  }

  [dir='rtl'] .xl\:rtl\:top-md,[dir='rtl'].xl\:rtl\:top-md {
    top: 64rem
  }

  [dir='rtl'] .xl\:rtl\:right-md,[dir='rtl'].xl\:rtl\:right-md {
    right: 64rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-md,[dir='rtl'].xl\:rtl\:bottom-md {
    bottom: 64rem
  }

  [dir='rtl'] .xl\:rtl\:left-md,[dir='rtl'].xl\:rtl\:left-md {
    left: 64rem
  }

  [dir='rtl'] .xl\:rtl\:top-lg,[dir='rtl'].xl\:rtl\:top-lg {
    top: 80rem
  }

  [dir='rtl'] .xl\:rtl\:right-lg,[dir='rtl'].xl\:rtl\:right-lg {
    right: 80rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-lg,[dir='rtl'].xl\:rtl\:bottom-lg {
    bottom: 80rem
  }

  [dir='rtl'] .xl\:rtl\:left-lg,[dir='rtl'].xl\:rtl\:left-lg {
    left: 80rem
  }

  [dir='rtl'] .xl\:rtl\:top-xl,[dir='rtl'].xl\:rtl\:top-xl {
    top: 96rem
  }

  [dir='rtl'] .xl\:rtl\:right-xl,[dir='rtl'].xl\:rtl\:right-xl {
    right: 96rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-xl,[dir='rtl'].xl\:rtl\:bottom-xl {
    bottom: 96rem
  }

  [dir='rtl'] .xl\:rtl\:left-xl,[dir='rtl'].xl\:rtl\:left-xl {
    left: 96rem
  }

  [dir='rtl'] .xl\:rtl\:top-2xl,[dir='rtl'].xl\:rtl\:top-2xl {
    top: 112rem
  }

  [dir='rtl'] .xl\:rtl\:right-2xl,[dir='rtl'].xl\:rtl\:right-2xl {
    right: 112rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-2xl,[dir='rtl'].xl\:rtl\:bottom-2xl {
    bottom: 112rem
  }

  [dir='rtl'] .xl\:rtl\:left-2xl,[dir='rtl'].xl\:rtl\:left-2xl {
    left: 112rem
  }

  [dir='rtl'] .xl\:rtl\:top-3xl,[dir='rtl'].xl\:rtl\:top-3xl {
    top: 128rem
  }

  [dir='rtl'] .xl\:rtl\:right-3xl,[dir='rtl'].xl\:rtl\:right-3xl {
    right: 128rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-3xl,[dir='rtl'].xl\:rtl\:bottom-3xl {
    bottom: 128rem
  }

  [dir='rtl'] .xl\:rtl\:left-3xl,[dir='rtl'].xl\:rtl\:left-3xl {
    left: 128rem
  }

  [dir='rtl'] .xl\:rtl\:top-4xl,[dir='rtl'].xl\:rtl\:top-4xl {
    top: 144rem
  }

  [dir='rtl'] .xl\:rtl\:right-4xl,[dir='rtl'].xl\:rtl\:right-4xl {
    right: 144rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-4xl,[dir='rtl'].xl\:rtl\:bottom-4xl {
    bottom: 144rem
  }

  [dir='rtl'] .xl\:rtl\:left-4xl,[dir='rtl'].xl\:rtl\:left-4xl {
    left: 144rem
  }

  [dir='rtl'] .xl\:rtl\:top-5xl,[dir='rtl'].xl\:rtl\:top-5xl {
    top: 160rem
  }

  [dir='rtl'] .xl\:rtl\:right-5xl,[dir='rtl'].xl\:rtl\:right-5xl {
    right: 160rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-5xl,[dir='rtl'].xl\:rtl\:bottom-5xl {
    bottom: 160rem
  }

  [dir='rtl'] .xl\:rtl\:left-5xl,[dir='rtl'].xl\:rtl\:left-5xl {
    left: 160rem
  }

  [dir='rtl'] .xl\:rtl\:top-px,[dir='rtl'].xl\:rtl\:top-px {
    top: 1px
  }

  [dir='rtl'] .xl\:rtl\:right-px,[dir='rtl'].xl\:rtl\:right-px {
    right: 1px
  }

  [dir='rtl'] .xl\:rtl\:bottom-px,[dir='rtl'].xl\:rtl\:bottom-px {
    bottom: 1px
  }

  [dir='rtl'] .xl\:rtl\:left-px,[dir='rtl'].xl\:rtl\:left-px {
    left: 1px
  }

  [dir='rtl'] .xl\:rtl\:top-0\.5,[dir='rtl'].xl\:rtl\:top-0\.5 {
    top: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:right-0\.5,[dir='rtl'].xl\:rtl\:right-0\.5 {
    right: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-0\.5,[dir='rtl'].xl\:rtl\:bottom-0\.5 {
    bottom: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:left-0\.5,[dir='rtl'].xl\:rtl\:left-0\.5 {
    left: 0.05rem
  }

  [dir='rtl'] .xl\:rtl\:top-1\.5,[dir='rtl'].xl\:rtl\:top-1\.5 {
    top: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:right-1\.5,[dir='rtl'].xl\:rtl\:right-1\.5 {
    right: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-1\.5,[dir='rtl'].xl\:rtl\:bottom-1\.5 {
    bottom: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:left-1\.5,[dir='rtl'].xl\:rtl\:left-1\.5 {
    left: 0.15rem
  }

  [dir='rtl'] .xl\:rtl\:top-2\.5,[dir='rtl'].xl\:rtl\:top-2\.5 {
    top: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:right-2\.5,[dir='rtl'].xl\:rtl\:right-2\.5 {
    right: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-2\.5,[dir='rtl'].xl\:rtl\:bottom-2\.5 {
    bottom: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:left-2\.5,[dir='rtl'].xl\:rtl\:left-2\.5 {
    left: 0.25rem
  }

  [dir='rtl'] .xl\:rtl\:top-3\.5,[dir='rtl'].xl\:rtl\:top-3\.5 {
    top: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:right-3\.5,[dir='rtl'].xl\:rtl\:right-3\.5 {
    right: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:bottom-3\.5,[dir='rtl'].xl\:rtl\:bottom-3\.5 {
    bottom: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:left-3\.5,[dir='rtl'].xl\:rtl\:left-3\.5 {
    left: 0.35rem
  }

  [dir='rtl'] .xl\:rtl\:-top-1,[dir='rtl'].xl\:rtl\:-top-1 {
    top: -0.1rem
  }

  [dir='rtl'] .xl\:rtl\:-right-1,[dir='rtl'].xl\:rtl\:-right-1 {
    right: -0.1rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-1,[dir='rtl'].xl\:rtl\:-bottom-1 {
    bottom: -0.1rem
  }

  [dir='rtl'] .xl\:rtl\:-left-1,[dir='rtl'].xl\:rtl\:-left-1 {
    left: -0.1rem
  }

  [dir='rtl'] .xl\:rtl\:-top-2,[dir='rtl'].xl\:rtl\:-top-2 {
    top: -0.2rem
  }

  [dir='rtl'] .xl\:rtl\:-right-2,[dir='rtl'].xl\:rtl\:-right-2 {
    right: -0.2rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-2,[dir='rtl'].xl\:rtl\:-bottom-2 {
    bottom: -0.2rem
  }

  [dir='rtl'] .xl\:rtl\:-left-2,[dir='rtl'].xl\:rtl\:-left-2 {
    left: -0.2rem
  }

  [dir='rtl'] .xl\:rtl\:-top-3,[dir='rtl'].xl\:rtl\:-top-3 {
    top: -0.3rem
  }

  [dir='rtl'] .xl\:rtl\:-right-3,[dir='rtl'].xl\:rtl\:-right-3 {
    right: -0.3rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-3,[dir='rtl'].xl\:rtl\:-bottom-3 {
    bottom: -0.3rem
  }

  [dir='rtl'] .xl\:rtl\:-left-3,[dir='rtl'].xl\:rtl\:-left-3 {
    left: -0.3rem
  }

  [dir='rtl'] .xl\:rtl\:-top-4,[dir='rtl'].xl\:rtl\:-top-4 {
    top: -0.4rem
  }

  [dir='rtl'] .xl\:rtl\:-right-4,[dir='rtl'].xl\:rtl\:-right-4 {
    right: -0.4rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-4,[dir='rtl'].xl\:rtl\:-bottom-4 {
    bottom: -0.4rem
  }

  [dir='rtl'] .xl\:rtl\:-left-4,[dir='rtl'].xl\:rtl\:-left-4 {
    left: -0.4rem
  }

  [dir='rtl'] .xl\:rtl\:-top-5,[dir='rtl'].xl\:rtl\:-top-5 {
    top: -0.5rem
  }

  [dir='rtl'] .xl\:rtl\:-right-5,[dir='rtl'].xl\:rtl\:-right-5 {
    right: -0.5rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-5,[dir='rtl'].xl\:rtl\:-bottom-5 {
    bottom: -0.5rem
  }

  [dir='rtl'] .xl\:rtl\:-left-5,[dir='rtl'].xl\:rtl\:-left-5 {
    left: -0.5rem
  }

  [dir='rtl'] .xl\:rtl\:-top-6,[dir='rtl'].xl\:rtl\:-top-6 {
    top: -0.6rem
  }

  [dir='rtl'] .xl\:rtl\:-right-6,[dir='rtl'].xl\:rtl\:-right-6 {
    right: -0.6rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-6,[dir='rtl'].xl\:rtl\:-bottom-6 {
    bottom: -0.6rem
  }

  [dir='rtl'] .xl\:rtl\:-left-6,[dir='rtl'].xl\:rtl\:-left-6 {
    left: -0.6rem
  }

  [dir='rtl'] .xl\:rtl\:-top-7,[dir='rtl'].xl\:rtl\:-top-7 {
    top: -0.7rem
  }

  [dir='rtl'] .xl\:rtl\:-right-7,[dir='rtl'].xl\:rtl\:-right-7 {
    right: -0.7rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-7,[dir='rtl'].xl\:rtl\:-bottom-7 {
    bottom: -0.7rem
  }

  [dir='rtl'] .xl\:rtl\:-left-7,[dir='rtl'].xl\:rtl\:-left-7 {
    left: -0.7rem
  }

  [dir='rtl'] .xl\:rtl\:-top-8,[dir='rtl'].xl\:rtl\:-top-8 {
    top: -0.8rem
  }

  [dir='rtl'] .xl\:rtl\:-right-8,[dir='rtl'].xl\:rtl\:-right-8 {
    right: -0.8rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-8,[dir='rtl'].xl\:rtl\:-bottom-8 {
    bottom: -0.8rem
  }

  [dir='rtl'] .xl\:rtl\:-left-8,[dir='rtl'].xl\:rtl\:-left-8 {
    left: -0.8rem
  }

  [dir='rtl'] .xl\:rtl\:-top-9,[dir='rtl'].xl\:rtl\:-top-9 {
    top: -0.9rem
  }

  [dir='rtl'] .xl\:rtl\:-right-9,[dir='rtl'].xl\:rtl\:-right-9 {
    right: -0.9rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-9,[dir='rtl'].xl\:rtl\:-bottom-9 {
    bottom: -0.9rem
  }

  [dir='rtl'] .xl\:rtl\:-left-9,[dir='rtl'].xl\:rtl\:-left-9 {
    left: -0.9rem
  }

  [dir='rtl'] .xl\:rtl\:-top-10,[dir='rtl'].xl\:rtl\:-top-10 {
    top: -1rem
  }

  [dir='rtl'] .xl\:rtl\:-right-10,[dir='rtl'].xl\:rtl\:-right-10 {
    right: -1rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-10,[dir='rtl'].xl\:rtl\:-bottom-10 {
    bottom: -1rem
  }

  [dir='rtl'] .xl\:rtl\:-left-10,[dir='rtl'].xl\:rtl\:-left-10 {
    left: -1rem
  }

  [dir='rtl'] .xl\:rtl\:-top-12,[dir='rtl'].xl\:rtl\:-top-12 {
    top: -1.2rem
  }

  [dir='rtl'] .xl\:rtl\:-right-12,[dir='rtl'].xl\:rtl\:-right-12 {
    right: -1.2rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-12,[dir='rtl'].xl\:rtl\:-bottom-12 {
    bottom: -1.2rem
  }

  [dir='rtl'] .xl\:rtl\:-left-12,[dir='rtl'].xl\:rtl\:-left-12 {
    left: -1.2rem
  }

  [dir='rtl'] .xl\:rtl\:-top-14,[dir='rtl'].xl\:rtl\:-top-14 {
    top: -1.4rem
  }

  [dir='rtl'] .xl\:rtl\:-right-14,[dir='rtl'].xl\:rtl\:-right-14 {
    right: -1.4rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-14,[dir='rtl'].xl\:rtl\:-bottom-14 {
    bottom: -1.4rem
  }

  [dir='rtl'] .xl\:rtl\:-left-14,[dir='rtl'].xl\:rtl\:-left-14 {
    left: -1.4rem
  }

  [dir='rtl'] .xl\:rtl\:-top-16,[dir='rtl'].xl\:rtl\:-top-16 {
    top: -1.6rem
  }

  [dir='rtl'] .xl\:rtl\:-right-16,[dir='rtl'].xl\:rtl\:-right-16 {
    right: -1.6rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-16,[dir='rtl'].xl\:rtl\:-bottom-16 {
    bottom: -1.6rem
  }

  [dir='rtl'] .xl\:rtl\:-left-16,[dir='rtl'].xl\:rtl\:-left-16 {
    left: -1.6rem
  }

  [dir='rtl'] .xl\:rtl\:-top-20,[dir='rtl'].xl\:rtl\:-top-20 {
    top: -2rem
  }

  [dir='rtl'] .xl\:rtl\:-right-20,[dir='rtl'].xl\:rtl\:-right-20 {
    right: -2rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-20,[dir='rtl'].xl\:rtl\:-bottom-20 {
    bottom: -2rem
  }

  [dir='rtl'] .xl\:rtl\:-left-20,[dir='rtl'].xl\:rtl\:-left-20 {
    left: -2rem
  }

  [dir='rtl'] .xl\:rtl\:-top-24,[dir='rtl'].xl\:rtl\:-top-24 {
    top: -2.4rem
  }

  [dir='rtl'] .xl\:rtl\:-right-24,[dir='rtl'].xl\:rtl\:-right-24 {
    right: -2.4rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-24,[dir='rtl'].xl\:rtl\:-bottom-24 {
    bottom: -2.4rem
  }

  [dir='rtl'] .xl\:rtl\:-left-24,[dir='rtl'].xl\:rtl\:-left-24 {
    left: -2.4rem
  }

  [dir='rtl'] .xl\:rtl\:-top-28,[dir='rtl'].xl\:rtl\:-top-28 {
    top: -2.8rem
  }

  [dir='rtl'] .xl\:rtl\:-right-28,[dir='rtl'].xl\:rtl\:-right-28 {
    right: -2.8rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-28,[dir='rtl'].xl\:rtl\:-bottom-28 {
    bottom: -2.8rem
  }

  [dir='rtl'] .xl\:rtl\:-left-28,[dir='rtl'].xl\:rtl\:-left-28 {
    left: -2.8rem
  }

  [dir='rtl'] .xl\:rtl\:-top-32,[dir='rtl'].xl\:rtl\:-top-32 {
    top: -3.2rem
  }

  [dir='rtl'] .xl\:rtl\:-right-32,[dir='rtl'].xl\:rtl\:-right-32 {
    right: -3.2rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-32,[dir='rtl'].xl\:rtl\:-bottom-32 {
    bottom: -3.2rem
  }

  [dir='rtl'] .xl\:rtl\:-left-32,[dir='rtl'].xl\:rtl\:-left-32 {
    left: -3.2rem
  }

  [dir='rtl'] .xl\:rtl\:-top-36,[dir='rtl'].xl\:rtl\:-top-36 {
    top: -3.6rem
  }

  [dir='rtl'] .xl\:rtl\:-right-36,[dir='rtl'].xl\:rtl\:-right-36 {
    right: -3.6rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-36,[dir='rtl'].xl\:rtl\:-bottom-36 {
    bottom: -3.6rem
  }

  [dir='rtl'] .xl\:rtl\:-left-36,[dir='rtl'].xl\:rtl\:-left-36 {
    left: -3.6rem
  }

  [dir='rtl'] .xl\:rtl\:-top-40,[dir='rtl'].xl\:rtl\:-top-40 {
    top: -4rem
  }

  [dir='rtl'] .xl\:rtl\:-right-40,[dir='rtl'].xl\:rtl\:-right-40 {
    right: -4rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-40,[dir='rtl'].xl\:rtl\:-bottom-40 {
    bottom: -4rem
  }

  [dir='rtl'] .xl\:rtl\:-left-40,[dir='rtl'].xl\:rtl\:-left-40 {
    left: -4rem
  }

  [dir='rtl'] .xl\:rtl\:-top-44,[dir='rtl'].xl\:rtl\:-top-44 {
    top: -4.4rem
  }

  [dir='rtl'] .xl\:rtl\:-right-44,[dir='rtl'].xl\:rtl\:-right-44 {
    right: -4.4rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-44,[dir='rtl'].xl\:rtl\:-bottom-44 {
    bottom: -4.4rem
  }

  [dir='rtl'] .xl\:rtl\:-left-44,[dir='rtl'].xl\:rtl\:-left-44 {
    left: -4.4rem
  }

  [dir='rtl'] .xl\:rtl\:-top-48,[dir='rtl'].xl\:rtl\:-top-48 {
    top: -4.8rem
  }

  [dir='rtl'] .xl\:rtl\:-right-48,[dir='rtl'].xl\:rtl\:-right-48 {
    right: -4.8rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-48,[dir='rtl'].xl\:rtl\:-bottom-48 {
    bottom: -4.8rem
  }

  [dir='rtl'] .xl\:rtl\:-left-48,[dir='rtl'].xl\:rtl\:-left-48 {
    left: -4.8rem
  }

  [dir='rtl'] .xl\:rtl\:-top-52,[dir='rtl'].xl\:rtl\:-top-52 {
    top: -5.2rem
  }

  [dir='rtl'] .xl\:rtl\:-right-52,[dir='rtl'].xl\:rtl\:-right-52 {
    right: -5.2rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-52,[dir='rtl'].xl\:rtl\:-bottom-52 {
    bottom: -5.2rem
  }

  [dir='rtl'] .xl\:rtl\:-left-52,[dir='rtl'].xl\:rtl\:-left-52 {
    left: -5.2rem
  }

  [dir='rtl'] .xl\:rtl\:-top-56,[dir='rtl'].xl\:rtl\:-top-56 {
    top: -5.6rem
  }

  [dir='rtl'] .xl\:rtl\:-right-56,[dir='rtl'].xl\:rtl\:-right-56 {
    right: -5.6rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-56,[dir='rtl'].xl\:rtl\:-bottom-56 {
    bottom: -5.6rem
  }

  [dir='rtl'] .xl\:rtl\:-left-56,[dir='rtl'].xl\:rtl\:-left-56 {
    left: -5.6rem
  }

  [dir='rtl'] .xl\:rtl\:-top-60,[dir='rtl'].xl\:rtl\:-top-60 {
    top: -6rem
  }

  [dir='rtl'] .xl\:rtl\:-right-60,[dir='rtl'].xl\:rtl\:-right-60 {
    right: -6rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-60,[dir='rtl'].xl\:rtl\:-bottom-60 {
    bottom: -6rem
  }

  [dir='rtl'] .xl\:rtl\:-left-60,[dir='rtl'].xl\:rtl\:-left-60 {
    left: -6rem
  }

  [dir='rtl'] .xl\:rtl\:-top-64,[dir='rtl'].xl\:rtl\:-top-64 {
    top: -6.4rem
  }

  [dir='rtl'] .xl\:rtl\:-right-64,[dir='rtl'].xl\:rtl\:-right-64 {
    right: -6.4rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-64,[dir='rtl'].xl\:rtl\:-bottom-64 {
    bottom: -6.4rem
  }

  [dir='rtl'] .xl\:rtl\:-left-64,[dir='rtl'].xl\:rtl\:-left-64 {
    left: -6.4rem
  }

  [dir='rtl'] .xl\:rtl\:-top-68,[dir='rtl'].xl\:rtl\:-top-68 {
    top: -6.8rem
  }

  [dir='rtl'] .xl\:rtl\:-right-68,[dir='rtl'].xl\:rtl\:-right-68 {
    right: -6.8rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-68,[dir='rtl'].xl\:rtl\:-bottom-68 {
    bottom: -6.8rem
  }

  [dir='rtl'] .xl\:rtl\:-left-68,[dir='rtl'].xl\:rtl\:-left-68 {
    left: -6.8rem
  }

  [dir='rtl'] .xl\:rtl\:-top-72,[dir='rtl'].xl\:rtl\:-top-72 {
    top: -7.2rem
  }

  [dir='rtl'] .xl\:rtl\:-right-72,[dir='rtl'].xl\:rtl\:-right-72 {
    right: -7.2rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-72,[dir='rtl'].xl\:rtl\:-bottom-72 {
    bottom: -7.2rem
  }

  [dir='rtl'] .xl\:rtl\:-left-72,[dir='rtl'].xl\:rtl\:-left-72 {
    left: -7.2rem
  }

  [dir='rtl'] .xl\:rtl\:-top-76,[dir='rtl'].xl\:rtl\:-top-76 {
    top: -7.6rem
  }

  [dir='rtl'] .xl\:rtl\:-right-76,[dir='rtl'].xl\:rtl\:-right-76 {
    right: -7.6rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-76,[dir='rtl'].xl\:rtl\:-bottom-76 {
    bottom: -7.6rem
  }

  [dir='rtl'] .xl\:rtl\:-left-76,[dir='rtl'].xl\:rtl\:-left-76 {
    left: -7.6rem
  }

  [dir='rtl'] .xl\:rtl\:-top-80,[dir='rtl'].xl\:rtl\:-top-80 {
    top: -8rem
  }

  [dir='rtl'] .xl\:rtl\:-right-80,[dir='rtl'].xl\:rtl\:-right-80 {
    right: -8rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-80,[dir='rtl'].xl\:rtl\:-bottom-80 {
    bottom: -8rem
  }

  [dir='rtl'] .xl\:rtl\:-left-80,[dir='rtl'].xl\:rtl\:-left-80 {
    left: -8rem
  }

  [dir='rtl'] .xl\:rtl\:-top-84,[dir='rtl'].xl\:rtl\:-top-84 {
    top: -8.4rem
  }

  [dir='rtl'] .xl\:rtl\:-right-84,[dir='rtl'].xl\:rtl\:-right-84 {
    right: -8.4rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-84,[dir='rtl'].xl\:rtl\:-bottom-84 {
    bottom: -8.4rem
  }

  [dir='rtl'] .xl\:rtl\:-left-84,[dir='rtl'].xl\:rtl\:-left-84 {
    left: -8.4rem
  }

  [dir='rtl'] .xl\:rtl\:-top-88,[dir='rtl'].xl\:rtl\:-top-88 {
    top: -8.8rem
  }

  [dir='rtl'] .xl\:rtl\:-right-88,[dir='rtl'].xl\:rtl\:-right-88 {
    right: -8.8rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-88,[dir='rtl'].xl\:rtl\:-bottom-88 {
    bottom: -8.8rem
  }

  [dir='rtl'] .xl\:rtl\:-left-88,[dir='rtl'].xl\:rtl\:-left-88 {
    left: -8.8rem
  }

  [dir='rtl'] .xl\:rtl\:-top-92,[dir='rtl'].xl\:rtl\:-top-92 {
    top: -9.2rem
  }

  [dir='rtl'] .xl\:rtl\:-right-92,[dir='rtl'].xl\:rtl\:-right-92 {
    right: -9.2rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-92,[dir='rtl'].xl\:rtl\:-bottom-92 {
    bottom: -9.2rem
  }

  [dir='rtl'] .xl\:rtl\:-left-92,[dir='rtl'].xl\:rtl\:-left-92 {
    left: -9.2rem
  }

  [dir='rtl'] .xl\:rtl\:-top-96,[dir='rtl'].xl\:rtl\:-top-96 {
    top: -9.6rem
  }

  [dir='rtl'] .xl\:rtl\:-right-96,[dir='rtl'].xl\:rtl\:-right-96 {
    right: -9.6rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-96,[dir='rtl'].xl\:rtl\:-bottom-96 {
    bottom: -9.6rem
  }

  [dir='rtl'] .xl\:rtl\:-left-96,[dir='rtl'].xl\:rtl\:-left-96 {
    left: -9.6rem
  }

  [dir='rtl'] .xl\:rtl\:-top-128,[dir='rtl'].xl\:rtl\:-top-128 {
    top: -12.8rem
  }

  [dir='rtl'] .xl\:rtl\:-right-128,[dir='rtl'].xl\:rtl\:-right-128 {
    right: -12.8rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-128,[dir='rtl'].xl\:rtl\:-bottom-128 {
    bottom: -12.8rem
  }

  [dir='rtl'] .xl\:rtl\:-left-128,[dir='rtl'].xl\:rtl\:-left-128 {
    left: -12.8rem
  }

  [dir='rtl'] .xl\:rtl\:-top-136,[dir='rtl'].xl\:rtl\:-top-136 {
    top: -13.6rem
  }

  [dir='rtl'] .xl\:rtl\:-right-136,[dir='rtl'].xl\:rtl\:-right-136 {
    right: -13.6rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-136,[dir='rtl'].xl\:rtl\:-bottom-136 {
    bottom: -13.6rem
  }

  [dir='rtl'] .xl\:rtl\:-left-136,[dir='rtl'].xl\:rtl\:-left-136 {
    left: -13.6rem
  }

  [dir='rtl'] .xl\:rtl\:-top-160,[dir='rtl'].xl\:rtl\:-top-160 {
    top: -16rem
  }

  [dir='rtl'] .xl\:rtl\:-right-160,[dir='rtl'].xl\:rtl\:-right-160 {
    right: -16rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-160,[dir='rtl'].xl\:rtl\:-bottom-160 {
    bottom: -16rem
  }

  [dir='rtl'] .xl\:rtl\:-left-160,[dir='rtl'].xl\:rtl\:-left-160 {
    left: -16rem
  }

  [dir='rtl'] .xl\:rtl\:-top-192,[dir='rtl'].xl\:rtl\:-top-192 {
    top: -19.2rem
  }

  [dir='rtl'] .xl\:rtl\:-right-192,[dir='rtl'].xl\:rtl\:-right-192 {
    right: -19.2rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-192,[dir='rtl'].xl\:rtl\:-bottom-192 {
    bottom: -19.2rem
  }

  [dir='rtl'] .xl\:rtl\:-left-192,[dir='rtl'].xl\:rtl\:-left-192 {
    left: -19.2rem
  }

  [dir='rtl'] .xl\:rtl\:-top-200,[dir='rtl'].xl\:rtl\:-top-200 {
    top: -20rem
  }

  [dir='rtl'] .xl\:rtl\:-right-200,[dir='rtl'].xl\:rtl\:-right-200 {
    right: -20rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-200,[dir='rtl'].xl\:rtl\:-bottom-200 {
    bottom: -20rem
  }

  [dir='rtl'] .xl\:rtl\:-left-200,[dir='rtl'].xl\:rtl\:-left-200 {
    left: -20rem
  }

  [dir='rtl'] .xl\:rtl\:-top-208,[dir='rtl'].xl\:rtl\:-top-208 {
    top: -20.8rem
  }

  [dir='rtl'] .xl\:rtl\:-right-208,[dir='rtl'].xl\:rtl\:-right-208 {
    right: -20.8rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-208,[dir='rtl'].xl\:rtl\:-bottom-208 {
    bottom: -20.8rem
  }

  [dir='rtl'] .xl\:rtl\:-left-208,[dir='rtl'].xl\:rtl\:-left-208 {
    left: -20.8rem
  }

  [dir='rtl'] .xl\:rtl\:-top-216,[dir='rtl'].xl\:rtl\:-top-216 {
    top: -21.6rem
  }

  [dir='rtl'] .xl\:rtl\:-right-216,[dir='rtl'].xl\:rtl\:-right-216 {
    right: -21.6rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-216,[dir='rtl'].xl\:rtl\:-bottom-216 {
    bottom: -21.6rem
  }

  [dir='rtl'] .xl\:rtl\:-left-216,[dir='rtl'].xl\:rtl\:-left-216 {
    left: -21.6rem
  }

  [dir='rtl'] .xl\:rtl\:-top-224,[dir='rtl'].xl\:rtl\:-top-224 {
    top: -22.4rem
  }

  [dir='rtl'] .xl\:rtl\:-right-224,[dir='rtl'].xl\:rtl\:-right-224 {
    right: -22.4rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-224,[dir='rtl'].xl\:rtl\:-bottom-224 {
    bottom: -22.4rem
  }

  [dir='rtl'] .xl\:rtl\:-left-224,[dir='rtl'].xl\:rtl\:-left-224 {
    left: -22.4rem
  }

  [dir='rtl'] .xl\:rtl\:-top-256,[dir='rtl'].xl\:rtl\:-top-256 {
    top: -25.6rem
  }

  [dir='rtl'] .xl\:rtl\:-right-256,[dir='rtl'].xl\:rtl\:-right-256 {
    right: -25.6rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-256,[dir='rtl'].xl\:rtl\:-bottom-256 {
    bottom: -25.6rem
  }

  [dir='rtl'] .xl\:rtl\:-left-256,[dir='rtl'].xl\:rtl\:-left-256 {
    left: -25.6rem
  }

  [dir='rtl'] .xl\:rtl\:-top-288,[dir='rtl'].xl\:rtl\:-top-288 {
    top: -28.8rem
  }

  [dir='rtl'] .xl\:rtl\:-right-288,[dir='rtl'].xl\:rtl\:-right-288 {
    right: -28.8rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-288,[dir='rtl'].xl\:rtl\:-bottom-288 {
    bottom: -28.8rem
  }

  [dir='rtl'] .xl\:rtl\:-left-288,[dir='rtl'].xl\:rtl\:-left-288 {
    left: -28.8rem
  }

  [dir='rtl'] .xl\:rtl\:-top-320,[dir='rtl'].xl\:rtl\:-top-320 {
    top: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-right-320,[dir='rtl'].xl\:rtl\:-right-320 {
    right: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-320,[dir='rtl'].xl\:rtl\:-bottom-320 {
    bottom: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-left-320,[dir='rtl'].xl\:rtl\:-left-320 {
    left: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-top-360,[dir='rtl'].xl\:rtl\:-top-360 {
    top: -36rem
  }

  [dir='rtl'] .xl\:rtl\:-right-360,[dir='rtl'].xl\:rtl\:-right-360 {
    right: -36rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-360,[dir='rtl'].xl\:rtl\:-bottom-360 {
    bottom: -36rem
  }

  [dir='rtl'] .xl\:rtl\:-left-360,[dir='rtl'].xl\:rtl\:-left-360 {
    left: -36rem
  }

  [dir='rtl'] .xl\:rtl\:-top-384,[dir='rtl'].xl\:rtl\:-top-384 {
    top: -38.4rem
  }

  [dir='rtl'] .xl\:rtl\:-right-384,[dir='rtl'].xl\:rtl\:-right-384 {
    right: -38.4rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-384,[dir='rtl'].xl\:rtl\:-bottom-384 {
    bottom: -38.4rem
  }

  [dir='rtl'] .xl\:rtl\:-left-384,[dir='rtl'].xl\:rtl\:-left-384 {
    left: -38.4rem
  }

  [dir='rtl'] .xl\:rtl\:-top-400,[dir='rtl'].xl\:rtl\:-top-400 {
    top: -40rem
  }

  [dir='rtl'] .xl\:rtl\:-right-400,[dir='rtl'].xl\:rtl\:-right-400 {
    right: -40rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-400,[dir='rtl'].xl\:rtl\:-bottom-400 {
    bottom: -40rem
  }

  [dir='rtl'] .xl\:rtl\:-left-400,[dir='rtl'].xl\:rtl\:-left-400 {
    left: -40rem
  }

  [dir='rtl'] .xl\:rtl\:-top-512,[dir='rtl'].xl\:rtl\:-top-512 {
    top: -51.2rem
  }

  [dir='rtl'] .xl\:rtl\:-right-512,[dir='rtl'].xl\:rtl\:-right-512 {
    right: -51.2rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-512,[dir='rtl'].xl\:rtl\:-bottom-512 {
    bottom: -51.2rem
  }

  [dir='rtl'] .xl\:rtl\:-left-512,[dir='rtl'].xl\:rtl\:-left-512 {
    left: -51.2rem
  }

  [dir='rtl'] .xl\:rtl\:-top-640,[dir='rtl'].xl\:rtl\:-top-640 {
    top: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-right-640,[dir='rtl'].xl\:rtl\:-right-640 {
    right: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-640,[dir='rtl'].xl\:rtl\:-bottom-640 {
    bottom: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-left-640,[dir='rtl'].xl\:rtl\:-left-640 {
    left: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-top-xs,[dir='rtl'].xl\:rtl\:-top-xs {
    top: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-right-xs,[dir='rtl'].xl\:rtl\:-right-xs {
    right: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-xs,[dir='rtl'].xl\:rtl\:-bottom-xs {
    bottom: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-left-xs,[dir='rtl'].xl\:rtl\:-left-xs {
    left: -32rem
  }

  [dir='rtl'] .xl\:rtl\:-top-sm,[dir='rtl'].xl\:rtl\:-top-sm {
    top: -48rem
  }

  [dir='rtl'] .xl\:rtl\:-right-sm,[dir='rtl'].xl\:rtl\:-right-sm {
    right: -48rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-sm,[dir='rtl'].xl\:rtl\:-bottom-sm {
    bottom: -48rem
  }

  [dir='rtl'] .xl\:rtl\:-left-sm,[dir='rtl'].xl\:rtl\:-left-sm {
    left: -48rem
  }

  [dir='rtl'] .xl\:rtl\:-top-md,[dir='rtl'].xl\:rtl\:-top-md {
    top: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-right-md,[dir='rtl'].xl\:rtl\:-right-md {
    right: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-md,[dir='rtl'].xl\:rtl\:-bottom-md {
    bottom: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-left-md,[dir='rtl'].xl\:rtl\:-left-md {
    left: -64rem
  }

  [dir='rtl'] .xl\:rtl\:-top-lg,[dir='rtl'].xl\:rtl\:-top-lg {
    top: -80rem
  }

  [dir='rtl'] .xl\:rtl\:-right-lg,[dir='rtl'].xl\:rtl\:-right-lg {
    right: -80rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-lg,[dir='rtl'].xl\:rtl\:-bottom-lg {
    bottom: -80rem
  }

  [dir='rtl'] .xl\:rtl\:-left-lg,[dir='rtl'].xl\:rtl\:-left-lg {
    left: -80rem
  }

  [dir='rtl'] .xl\:rtl\:-top-xl,[dir='rtl'].xl\:rtl\:-top-xl {
    top: -96rem
  }

  [dir='rtl'] .xl\:rtl\:-right-xl,[dir='rtl'].xl\:rtl\:-right-xl {
    right: -96rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-xl,[dir='rtl'].xl\:rtl\:-bottom-xl {
    bottom: -96rem
  }

  [dir='rtl'] .xl\:rtl\:-left-xl,[dir='rtl'].xl\:rtl\:-left-xl {
    left: -96rem
  }

  [dir='rtl'] .xl\:rtl\:-top-2xl,[dir='rtl'].xl\:rtl\:-top-2xl {
    top: -112rem
  }

  [dir='rtl'] .xl\:rtl\:-right-2xl,[dir='rtl'].xl\:rtl\:-right-2xl {
    right: -112rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-2xl,[dir='rtl'].xl\:rtl\:-bottom-2xl {
    bottom: -112rem
  }

  [dir='rtl'] .xl\:rtl\:-left-2xl,[dir='rtl'].xl\:rtl\:-left-2xl {
    left: -112rem
  }

  [dir='rtl'] .xl\:rtl\:-top-3xl,[dir='rtl'].xl\:rtl\:-top-3xl {
    top: -128rem
  }

  [dir='rtl'] .xl\:rtl\:-right-3xl,[dir='rtl'].xl\:rtl\:-right-3xl {
    right: -128rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-3xl,[dir='rtl'].xl\:rtl\:-bottom-3xl {
    bottom: -128rem
  }

  [dir='rtl'] .xl\:rtl\:-left-3xl,[dir='rtl'].xl\:rtl\:-left-3xl {
    left: -128rem
  }

  [dir='rtl'] .xl\:rtl\:-top-4xl,[dir='rtl'].xl\:rtl\:-top-4xl {
    top: -144rem
  }

  [dir='rtl'] .xl\:rtl\:-right-4xl,[dir='rtl'].xl\:rtl\:-right-4xl {
    right: -144rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-4xl,[dir='rtl'].xl\:rtl\:-bottom-4xl {
    bottom: -144rem
  }

  [dir='rtl'] .xl\:rtl\:-left-4xl,[dir='rtl'].xl\:rtl\:-left-4xl {
    left: -144rem
  }

  [dir='rtl'] .xl\:rtl\:-top-5xl,[dir='rtl'].xl\:rtl\:-top-5xl {
    top: -160rem
  }

  [dir='rtl'] .xl\:rtl\:-right-5xl,[dir='rtl'].xl\:rtl\:-right-5xl {
    right: -160rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-5xl,[dir='rtl'].xl\:rtl\:-bottom-5xl {
    bottom: -160rem
  }

  [dir='rtl'] .xl\:rtl\:-left-5xl,[dir='rtl'].xl\:rtl\:-left-5xl {
    left: -160rem
  }

  [dir='rtl'] .xl\:rtl\:-top-px,[dir='rtl'].xl\:rtl\:-top-px {
    top: -1px
  }

  [dir='rtl'] .xl\:rtl\:-right-px,[dir='rtl'].xl\:rtl\:-right-px {
    right: -1px
  }

  [dir='rtl'] .xl\:rtl\:-bottom-px,[dir='rtl'].xl\:rtl\:-bottom-px {
    bottom: -1px
  }

  [dir='rtl'] .xl\:rtl\:-left-px,[dir='rtl'].xl\:rtl\:-left-px {
    left: -1px
  }

  [dir='rtl'] .xl\:rtl\:-top-0\.5,[dir='rtl'].xl\:rtl\:-top-0\.5 {
    top: -0.05rem
  }

  [dir='rtl'] .xl\:rtl\:-right-0\.5,[dir='rtl'].xl\:rtl\:-right-0\.5 {
    right: -0.05rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-0\.5,[dir='rtl'].xl\:rtl\:-bottom-0\.5 {
    bottom: -0.05rem
  }

  [dir='rtl'] .xl\:rtl\:-left-0\.5,[dir='rtl'].xl\:rtl\:-left-0\.5 {
    left: -0.05rem
  }

  [dir='rtl'] .xl\:rtl\:-top-1\.5,[dir='rtl'].xl\:rtl\:-top-1\.5 {
    top: -0.15rem
  }

  [dir='rtl'] .xl\:rtl\:-right-1\.5,[dir='rtl'].xl\:rtl\:-right-1\.5 {
    right: -0.15rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-1\.5,[dir='rtl'].xl\:rtl\:-bottom-1\.5 {
    bottom: -0.15rem
  }

  [dir='rtl'] .xl\:rtl\:-left-1\.5,[dir='rtl'].xl\:rtl\:-left-1\.5 {
    left: -0.15rem
  }

  [dir='rtl'] .xl\:rtl\:-top-2\.5,[dir='rtl'].xl\:rtl\:-top-2\.5 {
    top: -0.25rem
  }

  [dir='rtl'] .xl\:rtl\:-right-2\.5,[dir='rtl'].xl\:rtl\:-right-2\.5 {
    right: -0.25rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-2\.5,[dir='rtl'].xl\:rtl\:-bottom-2\.5 {
    bottom: -0.25rem
  }

  [dir='rtl'] .xl\:rtl\:-left-2\.5,[dir='rtl'].xl\:rtl\:-left-2\.5 {
    left: -0.25rem
  }

  [dir='rtl'] .xl\:rtl\:-top-3\.5,[dir='rtl'].xl\:rtl\:-top-3\.5 {
    top: -0.35rem
  }

  [dir='rtl'] .xl\:rtl\:-right-3\.5,[dir='rtl'].xl\:rtl\:-right-3\.5 {
    right: -0.35rem
  }

  [dir='rtl'] .xl\:rtl\:-bottom-3\.5,[dir='rtl'].xl\:rtl\:-bottom-3\.5 {
    bottom: -0.35rem
  }

  [dir='rtl'] .xl\:rtl\:-left-3\.5,[dir='rtl'].xl\:rtl\:-left-3\.5 {
    left: -0.35rem
  }

  [dir='rtl'] .xl\:rtl\:top-1\/2,[dir='rtl'].xl\:rtl\:top-1\/2 {
    top: 50%
  }

  [dir='rtl'] .xl\:rtl\:right-1\/2,[dir='rtl'].xl\:rtl\:right-1\/2 {
    right: 50%
  }

  [dir='rtl'] .xl\:rtl\:bottom-1\/2,[dir='rtl'].xl\:rtl\:bottom-1\/2 {
    bottom: 50%
  }

  [dir='rtl'] .xl\:rtl\:left-1\/2,[dir='rtl'].xl\:rtl\:left-1\/2 {
    left: 50%
  }

  [dir='rtl'] .xl\:rtl\:top-1\/3,[dir='rtl'].xl\:rtl\:top-1\/3 {
    top: 33.333333%
  }

  [dir='rtl'] .xl\:rtl\:right-1\/3,[dir='rtl'].xl\:rtl\:right-1\/3 {
    right: 33.333333%
  }

  [dir='rtl'] .xl\:rtl\:bottom-1\/3,[dir='rtl'].xl\:rtl\:bottom-1\/3 {
    bottom: 33.333333%
  }

  [dir='rtl'] .xl\:rtl\:left-1\/3,[dir='rtl'].xl\:rtl\:left-1\/3 {
    left: 33.333333%
  }

  [dir='rtl'] .xl\:rtl\:top-2\/3,[dir='rtl'].xl\:rtl\:top-2\/3 {
    top: 66.666667%
  }

  [dir='rtl'] .xl\:rtl\:right-2\/3,[dir='rtl'].xl\:rtl\:right-2\/3 {
    right: 66.666667%
  }

  [dir='rtl'] .xl\:rtl\:bottom-2\/3,[dir='rtl'].xl\:rtl\:bottom-2\/3 {
    bottom: 66.666667%
  }

  [dir='rtl'] .xl\:rtl\:left-2\/3,[dir='rtl'].xl\:rtl\:left-2\/3 {
    left: 66.666667%
  }

  [dir='rtl'] .xl\:rtl\:top-1\/4,[dir='rtl'].xl\:rtl\:top-1\/4 {
    top: 25%
  }

  [dir='rtl'] .xl\:rtl\:right-1\/4,[dir='rtl'].xl\:rtl\:right-1\/4 {
    right: 25%
  }

  [dir='rtl'] .xl\:rtl\:bottom-1\/4,[dir='rtl'].xl\:rtl\:bottom-1\/4 {
    bottom: 25%
  }

  [dir='rtl'] .xl\:rtl\:left-1\/4,[dir='rtl'].xl\:rtl\:left-1\/4 {
    left: 25%
  }

  [dir='rtl'] .xl\:rtl\:top-2\/4,[dir='rtl'].xl\:rtl\:top-2\/4 {
    top: 50%
  }

  [dir='rtl'] .xl\:rtl\:right-2\/4,[dir='rtl'].xl\:rtl\:right-2\/4 {
    right: 50%
  }

  [dir='rtl'] .xl\:rtl\:bottom-2\/4,[dir='rtl'].xl\:rtl\:bottom-2\/4 {
    bottom: 50%
  }

  [dir='rtl'] .xl\:rtl\:left-2\/4,[dir='rtl'].xl\:rtl\:left-2\/4 {
    left: 50%
  }

  [dir='rtl'] .xl\:rtl\:top-3\/4,[dir='rtl'].xl\:rtl\:top-3\/4 {
    top: 75%
  }

  [dir='rtl'] .xl\:rtl\:right-3\/4,[dir='rtl'].xl\:rtl\:right-3\/4 {
    right: 75%
  }

  [dir='rtl'] .xl\:rtl\:bottom-3\/4,[dir='rtl'].xl\:rtl\:bottom-3\/4 {
    bottom: 75%
  }

  [dir='rtl'] .xl\:rtl\:left-3\/4,[dir='rtl'].xl\:rtl\:left-3\/4 {
    left: 75%
  }

  [dir='rtl'] .xl\:rtl\:top-full,[dir='rtl'].xl\:rtl\:top-full {
    top: 100%
  }

  [dir='rtl'] .xl\:rtl\:right-full,[dir='rtl'].xl\:rtl\:right-full {
    right: 100%
  }

  [dir='rtl'] .xl\:rtl\:bottom-full,[dir='rtl'].xl\:rtl\:bottom-full {
    bottom: 100%
  }

  [dir='rtl'] .xl\:rtl\:left-full,[dir='rtl'].xl\:rtl\:left-full {
    left: 100%
  }

  [dir='rtl'] .xl\:rtl\:-top-1\/2,[dir='rtl'].xl\:rtl\:-top-1\/2 {
    top: -50%
  }

  [dir='rtl'] .xl\:rtl\:-right-1\/2,[dir='rtl'].xl\:rtl\:-right-1\/2 {
    right: -50%
  }

  [dir='rtl'] .xl\:rtl\:-bottom-1\/2,[dir='rtl'].xl\:rtl\:-bottom-1\/2 {
    bottom: -50%
  }

  [dir='rtl'] .xl\:rtl\:-left-1\/2,[dir='rtl'].xl\:rtl\:-left-1\/2 {
    left: -50%
  }

  [dir='rtl'] .xl\:rtl\:-top-1\/3,[dir='rtl'].xl\:rtl\:-top-1\/3 {
    top: -33.333333%
  }

  [dir='rtl'] .xl\:rtl\:-right-1\/3,[dir='rtl'].xl\:rtl\:-right-1\/3 {
    right: -33.333333%
  }

  [dir='rtl'] .xl\:rtl\:-bottom-1\/3,[dir='rtl'].xl\:rtl\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  [dir='rtl'] .xl\:rtl\:-left-1\/3,[dir='rtl'].xl\:rtl\:-left-1\/3 {
    left: -33.333333%
  }

  [dir='rtl'] .xl\:rtl\:-top-2\/3,[dir='rtl'].xl\:rtl\:-top-2\/3 {
    top: -66.666667%
  }

  [dir='rtl'] .xl\:rtl\:-right-2\/3,[dir='rtl'].xl\:rtl\:-right-2\/3 {
    right: -66.666667%
  }

  [dir='rtl'] .xl\:rtl\:-bottom-2\/3,[dir='rtl'].xl\:rtl\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  [dir='rtl'] .xl\:rtl\:-left-2\/3,[dir='rtl'].xl\:rtl\:-left-2\/3 {
    left: -66.666667%
  }

  [dir='rtl'] .xl\:rtl\:-top-1\/4,[dir='rtl'].xl\:rtl\:-top-1\/4 {
    top: -25%
  }

  [dir='rtl'] .xl\:rtl\:-right-1\/4,[dir='rtl'].xl\:rtl\:-right-1\/4 {
    right: -25%
  }

  [dir='rtl'] .xl\:rtl\:-bottom-1\/4,[dir='rtl'].xl\:rtl\:-bottom-1\/4 {
    bottom: -25%
  }

  [dir='rtl'] .xl\:rtl\:-left-1\/4,[dir='rtl'].xl\:rtl\:-left-1\/4 {
    left: -25%
  }

  [dir='rtl'] .xl\:rtl\:-top-2\/4,[dir='rtl'].xl\:rtl\:-top-2\/4 {
    top: -50%
  }

  [dir='rtl'] .xl\:rtl\:-right-2\/4,[dir='rtl'].xl\:rtl\:-right-2\/4 {
    right: -50%
  }

  [dir='rtl'] .xl\:rtl\:-bottom-2\/4,[dir='rtl'].xl\:rtl\:-bottom-2\/4 {
    bottom: -50%
  }

  [dir='rtl'] .xl\:rtl\:-left-2\/4,[dir='rtl'].xl\:rtl\:-left-2\/4 {
    left: -50%
  }

  [dir='rtl'] .xl\:rtl\:-top-3\/4,[dir='rtl'].xl\:rtl\:-top-3\/4 {
    top: -75%
  }

  [dir='rtl'] .xl\:rtl\:-right-3\/4,[dir='rtl'].xl\:rtl\:-right-3\/4 {
    right: -75%
  }

  [dir='rtl'] .xl\:rtl\:-bottom-3\/4,[dir='rtl'].xl\:rtl\:-bottom-3\/4 {
    bottom: -75%
  }

  [dir='rtl'] .xl\:rtl\:-left-3\/4,[dir='rtl'].xl\:rtl\:-left-3\/4 {
    left: -75%
  }

  [dir='rtl'] .xl\:rtl\:-top-full,[dir='rtl'].xl\:rtl\:-top-full {
    top: -100%
  }

  [dir='rtl'] .xl\:rtl\:-right-full,[dir='rtl'].xl\:rtl\:-right-full {
    right: -100%
  }

  [dir='rtl'] .xl\:rtl\:-bottom-full,[dir='rtl'].xl\:rtl\:-bottom-full {
    bottom: -100%
  }

  [dir='rtl'] .xl\:rtl\:-left-full,[dir='rtl'].xl\:rtl\:-left-full {
    left: -100%
  }

  .xl\:resize-none {
    resize: none
  }

  .xl\:resize-y {
    resize: vertical
  }

  .xl\:resize-x {
    resize: horizontal
  }

  .xl\:resize {
    resize: both
  }

  .xl\:shadow-0 {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-1 {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-2 {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-3 {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-4 {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-5 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-6 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-7 {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-8 {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-9 {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-10 {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-11 {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-12 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-13 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-14 {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-15 {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-16 {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-17 {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-18 {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-19 {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-20 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-21 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-22 {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-23 {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-24 {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-0 {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-1 {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-2 {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-3 {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-4 {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-5 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-6 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-7 {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-8 {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-9 {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-10 {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-11 {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-12 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-13 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-14 {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-15 {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-16 {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-17 {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-18 {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-19 {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-20 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-21 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-22 {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-23 {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-24 {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-0:focus-within {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-1:focus-within {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-2:focus-within {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-3:focus-within {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-4:focus-within {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-5:focus-within {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-6:focus-within {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-7:focus-within {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-8:focus-within {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-9:focus-within {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-10:focus-within {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-11:focus-within {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-12:focus-within {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-13:focus-within {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-14:focus-within {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-15:focus-within {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-16:focus-within {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-17:focus-within {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-18:focus-within {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-19:focus-within {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-20:focus-within {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-21:focus-within {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-22:focus-within {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-23:focus-within {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-24:focus-within {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-0:hover {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-1:hover {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-2:hover {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-3:hover {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-4:hover {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-5:hover {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-6:hover {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-7:hover {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-8:hover {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-9:hover {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-10:hover {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-11:hover {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-12:hover {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-13:hover {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-14:hover {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-15:hover {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-16:hover {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-17:hover {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-18:hover {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-19:hover {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-20:hover {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-21:hover {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-22:hover {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-23:hover {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-24:hover {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-0:focus {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-1:focus {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-2:focus {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-3:focus {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-4:focus {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-5:focus {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-6:focus {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-7:focus {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-8:focus {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-9:focus {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-10:focus {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-11:focus {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-12:focus {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-13:focus {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-14:focus {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-15:focus {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-16:focus {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-17:focus {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-18:focus {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-19:focus {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-20:focus {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-21:focus {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-22:focus {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-23:focus {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-24:focus {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:ring-inset {
    --tw-ring-inset: inset
  }

  .xl\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset
  }

  .xl\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus\:ring-inset:focus {
    --tw-ring-inset: inset
  }

  .xl\:ring-offset-transparent {
    --tw-ring-offset-color: transparent
  }

  .xl\:ring-offset-current {
    --tw-ring-offset-color: currentColor
  }

  .xl\:ring-offset-black {
    --tw-ring-offset-color: #22292F
  }

  .xl\:ring-offset-white {
    --tw-ring-offset-color: #fff
  }

  .xl\:ring-offset-grey-50 {
    --tw-ring-offset-color: #FAFAFA
  }

  .xl\:ring-offset-grey-100 {
    --tw-ring-offset-color: #F5F5F5
  }

  .xl\:ring-offset-grey-200 {
    --tw-ring-offset-color: #EEEEEE
  }

  .xl\:ring-offset-grey-300 {
    --tw-ring-offset-color: #E0E0E0
  }

  .xl\:ring-offset-grey-400 {
    --tw-ring-offset-color: #BDBDBD
  }

  .xl\:ring-offset-grey-500 {
    --tw-ring-offset-color: #9E9E9E
  }

  .xl\:ring-offset-grey-600 {
    --tw-ring-offset-color: #757575
  }

  .xl\:ring-offset-grey-700 {
    --tw-ring-offset-color: #616161
  }

  .xl\:ring-offset-grey-800 {
    --tw-ring-offset-color: #424242
  }

  .xl\:ring-offset-grey-900 {
    --tw-ring-offset-color: #212121
  }

  .xl\:ring-offset-grey {
    --tw-ring-offset-color: #9E9E9E
  }

  .xl\:ring-offset-grey-A100 {
    --tw-ring-offset-color: #D5D5D5
  }

  .xl\:ring-offset-grey-A200 {
    --tw-ring-offset-color: #AAAAAA
  }

  .xl\:ring-offset-grey-A400 {
    --tw-ring-offset-color: #303030
  }

  .xl\:ring-offset-grey-A700 {
    --tw-ring-offset-color: #616161
  }

  .xl\:ring-offset-gray-50 {
    --tw-ring-offset-color: #FAFAFA
  }

  .xl\:ring-offset-gray-100 {
    --tw-ring-offset-color: #F5F5F5
  }

  .xl\:ring-offset-gray-200 {
    --tw-ring-offset-color: #EEEEEE
  }

  .xl\:ring-offset-gray-300 {
    --tw-ring-offset-color: #E0E0E0
  }

  .xl\:ring-offset-gray-400 {
    --tw-ring-offset-color: #BDBDBD
  }

  .xl\:ring-offset-gray-500 {
    --tw-ring-offset-color: #9E9E9E
  }

  .xl\:ring-offset-gray-600 {
    --tw-ring-offset-color: #757575
  }

  .xl\:ring-offset-gray-700 {
    --tw-ring-offset-color: #616161
  }

  .xl\:ring-offset-gray-800 {
    --tw-ring-offset-color: #424242
  }

  .xl\:ring-offset-gray-900 {
    --tw-ring-offset-color: #212121
  }

  .xl\:ring-offset-gray {
    --tw-ring-offset-color: #9E9E9E
  }

  .xl\:ring-offset-gray-hover {
    --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
  }

  .xl\:ring-offset-gray-A100 {
    --tw-ring-offset-color: #D5D5D5
  }

  .xl\:ring-offset-gray-A200 {
    --tw-ring-offset-color: #AAAAAA
  }

  .xl\:ring-offset-gray-A400 {
    --tw-ring-offset-color: #303030
  }

  .xl\:ring-offset-gray-A700 {
    --tw-ring-offset-color: #616161
  }

  .xl\:ring-offset-red-50 {
    --tw-ring-offset-color: #FFEBEE
  }

  .xl\:ring-offset-red-100 {
    --tw-ring-offset-color: #FFCDD2
  }

  .xl\:ring-offset-red-200 {
    --tw-ring-offset-color: #EF9A9A
  }

  .xl\:ring-offset-red-300 {
    --tw-ring-offset-color: #E57373
  }

  .xl\:ring-offset-red-400 {
    --tw-ring-offset-color: #EF5350
  }

  .xl\:ring-offset-red-500 {
    --tw-ring-offset-color: #F44336
  }

  .xl\:ring-offset-red-600 {
    --tw-ring-offset-color: #E53935
  }

  .xl\:ring-offset-red-700 {
    --tw-ring-offset-color: #D32F2F
  }

  .xl\:ring-offset-red-800 {
    --tw-ring-offset-color: #C62828
  }

  .xl\:ring-offset-red-900 {
    --tw-ring-offset-color: #B71C1C
  }

  .xl\:ring-offset-red {
    --tw-ring-offset-color: #F44336
  }

  .xl\:ring-offset-red-A100 {
    --tw-ring-offset-color: #FF8A80
  }

  .xl\:ring-offset-red-A200 {
    --tw-ring-offset-color: #FF5252
  }

  .xl\:ring-offset-red-A400 {
    --tw-ring-offset-color: #FF1744
  }

  .xl\:ring-offset-red-A700 {
    --tw-ring-offset-color: #D50000
  }

  .xl\:ring-offset-orange-50 {
    --tw-ring-offset-color: #FFF3E0
  }

  .xl\:ring-offset-orange-100 {
    --tw-ring-offset-color: #FFE0B2
  }

  .xl\:ring-offset-orange-200 {
    --tw-ring-offset-color: #FFCC80
  }

  .xl\:ring-offset-orange-300 {
    --tw-ring-offset-color: #FFB74D
  }

  .xl\:ring-offset-orange-400 {
    --tw-ring-offset-color: #FFA726
  }

  .xl\:ring-offset-orange-500 {
    --tw-ring-offset-color: #FF9800
  }

  .xl\:ring-offset-orange-600 {
    --tw-ring-offset-color: #FB8C00
  }

  .xl\:ring-offset-orange-700 {
    --tw-ring-offset-color: #F57C00
  }

  .xl\:ring-offset-orange-800 {
    --tw-ring-offset-color: #EF6C00
  }

  .xl\:ring-offset-orange-900 {
    --tw-ring-offset-color: #E65100
  }

  .xl\:ring-offset-orange {
    --tw-ring-offset-color: #FF9800
  }

  .xl\:ring-offset-orange-A100 {
    --tw-ring-offset-color: #FFD180
  }

  .xl\:ring-offset-orange-A200 {
    --tw-ring-offset-color: #FFAB40
  }

  .xl\:ring-offset-orange-A400 {
    --tw-ring-offset-color: #FF9100
  }

  .xl\:ring-offset-orange-A700 {
    --tw-ring-offset-color: #FF6D00
  }

  .xl\:ring-offset-deep-orange-50 {
    --tw-ring-offset-color: #FBE9E7
  }

  .xl\:ring-offset-deep-orange-100 {
    --tw-ring-offset-color: #FFCCBC
  }

  .xl\:ring-offset-deep-orange-200 {
    --tw-ring-offset-color: #FFAB91
  }

  .xl\:ring-offset-deep-orange-300 {
    --tw-ring-offset-color: #FF8A65
  }

  .xl\:ring-offset-deep-orange-400 {
    --tw-ring-offset-color: #FF7043
  }

  .xl\:ring-offset-deep-orange-500 {
    --tw-ring-offset-color: #FF5722
  }

  .xl\:ring-offset-deep-orange-600 {
    --tw-ring-offset-color: #F4511E
  }

  .xl\:ring-offset-deep-orange-700 {
    --tw-ring-offset-color: #E64A19
  }

  .xl\:ring-offset-deep-orange-800 {
    --tw-ring-offset-color: #D84315
  }

  .xl\:ring-offset-deep-orange-900 {
    --tw-ring-offset-color: #BF360C
  }

  .xl\:ring-offset-deep-orange {
    --tw-ring-offset-color: #FF5722
  }

  .xl\:ring-offset-deep-orange-A100 {
    --tw-ring-offset-color: #FF9E80
  }

  .xl\:ring-offset-deep-orange-A200 {
    --tw-ring-offset-color: #FF6E40
  }

  .xl\:ring-offset-deep-orange-A400 {
    --tw-ring-offset-color: #FF3D00
  }

  .xl\:ring-offset-deep-orange-A700 {
    --tw-ring-offset-color: #DD2C00
  }

  .xl\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #FFFDE7
  }

  .xl\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #FFF9C4
  }

  .xl\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #FFF59D
  }

  .xl\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #FFF176
  }

  .xl\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #FFEE58
  }

  .xl\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #FFEB3B
  }

  .xl\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #FDD835
  }

  .xl\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #FBC02D
  }

  .xl\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #F9A825
  }

  .xl\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #F57F17
  }

  .xl\:ring-offset-yellow {
    --tw-ring-offset-color: #FFEB3B
  }

  .xl\:ring-offset-yellow-A100 {
    --tw-ring-offset-color: #FFFF8D
  }

  .xl\:ring-offset-yellow-A200 {
    --tw-ring-offset-color: #FFFF00
  }

  .xl\:ring-offset-yellow-A400 {
    --tw-ring-offset-color: #FFEA00
  }

  .xl\:ring-offset-yellow-A700 {
    --tw-ring-offset-color: #FFD600
  }

  .xl\:ring-offset-green-50 {
    --tw-ring-offset-color: #E8F5E9
  }

  .xl\:ring-offset-green-100 {
    --tw-ring-offset-color: #C8E6C9
  }

  .xl\:ring-offset-green-200 {
    --tw-ring-offset-color: #A5D6A7
  }

  .xl\:ring-offset-green-300 {
    --tw-ring-offset-color: #81C784
  }

  .xl\:ring-offset-green-400 {
    --tw-ring-offset-color: #66BB6A
  }

  .xl\:ring-offset-green-500 {
    --tw-ring-offset-color: #4CAF50
  }

  .xl\:ring-offset-green-600 {
    --tw-ring-offset-color: #43A047
  }

  .xl\:ring-offset-green-700 {
    --tw-ring-offset-color: #388E3C
  }

  .xl\:ring-offset-green-800 {
    --tw-ring-offset-color: #2E7D32
  }

  .xl\:ring-offset-green-900 {
    --tw-ring-offset-color: #1B5E20
  }

  .xl\:ring-offset-green {
    --tw-ring-offset-color: #4CAF50
  }

  .xl\:ring-offset-green-A100 {
    --tw-ring-offset-color: #B9F6CA
  }

  .xl\:ring-offset-green-A200 {
    --tw-ring-offset-color: #69F0AE
  }

  .xl\:ring-offset-green-A400 {
    --tw-ring-offset-color: #00E676
  }

  .xl\:ring-offset-green-A700 {
    --tw-ring-offset-color: #00C853
  }

  .xl\:ring-offset-light-green-50 {
    --tw-ring-offset-color: #F1F8E9
  }

  .xl\:ring-offset-light-green-100 {
    --tw-ring-offset-color: #DCEDC8
  }

  .xl\:ring-offset-light-green-200 {
    --tw-ring-offset-color: #C5E1A5
  }

  .xl\:ring-offset-light-green-300 {
    --tw-ring-offset-color: #AED581
  }

  .xl\:ring-offset-light-green-400 {
    --tw-ring-offset-color: #9CCC65
  }

  .xl\:ring-offset-light-green-500 {
    --tw-ring-offset-color: #8BC34A
  }

  .xl\:ring-offset-light-green-600 {
    --tw-ring-offset-color: #7CB342
  }

  .xl\:ring-offset-light-green-700 {
    --tw-ring-offset-color: #689F38
  }

  .xl\:ring-offset-light-green-800 {
    --tw-ring-offset-color: #558B2F
  }

  .xl\:ring-offset-light-green-900 {
    --tw-ring-offset-color: #33691E
  }

  .xl\:ring-offset-light-green {
    --tw-ring-offset-color: #8BC34A
  }

  .xl\:ring-offset-light-green-A100 {
    --tw-ring-offset-color: #CCFF90
  }

  .xl\:ring-offset-light-green-A200 {
    --tw-ring-offset-color: #B2FF59
  }

  .xl\:ring-offset-light-green-A400 {
    --tw-ring-offset-color: #76FF03
  }

  .xl\:ring-offset-light-green-A700 {
    --tw-ring-offset-color: #64DD17
  }

  .xl\:ring-offset-teal-50 {
    --tw-ring-offset-color: #E0F2F1
  }

  .xl\:ring-offset-teal-100 {
    --tw-ring-offset-color: #B2DFDB
  }

  .xl\:ring-offset-teal-200 {
    --tw-ring-offset-color: #80CBC4
  }

  .xl\:ring-offset-teal-300 {
    --tw-ring-offset-color: #4DB6AC
  }

  .xl\:ring-offset-teal-400 {
    --tw-ring-offset-color: #26A69A
  }

  .xl\:ring-offset-teal-500 {
    --tw-ring-offset-color: #009688
  }

  .xl\:ring-offset-teal-600 {
    --tw-ring-offset-color: #00897B
  }

  .xl\:ring-offset-teal-700 {
    --tw-ring-offset-color: #00796B
  }

  .xl\:ring-offset-teal-800 {
    --tw-ring-offset-color: #00695C
  }

  .xl\:ring-offset-teal-900 {
    --tw-ring-offset-color: #004D40
  }

  .xl\:ring-offset-teal {
    --tw-ring-offset-color: #009688
  }

  .xl\:ring-offset-teal-A100 {
    --tw-ring-offset-color: #A7FFEB
  }

  .xl\:ring-offset-teal-A200 {
    --tw-ring-offset-color: #64FFDA
  }

  .xl\:ring-offset-teal-A400 {
    --tw-ring-offset-color: #1DE9B6
  }

  .xl\:ring-offset-teal-A700 {
    --tw-ring-offset-color: #00BFA5
  }

  .xl\:ring-offset-blue-50 {
    --tw-ring-offset-color: #E3F2FD
  }

  .xl\:ring-offset-blue-100 {
    --tw-ring-offset-color: #BBDEFB
  }

  .xl\:ring-offset-blue-200 {
    --tw-ring-offset-color: #90CAF9
  }

  .xl\:ring-offset-blue-300 {
    --tw-ring-offset-color: #64B5F6
  }

  .xl\:ring-offset-blue-400 {
    --tw-ring-offset-color: #42A5F5
  }

  .xl\:ring-offset-blue-500 {
    --tw-ring-offset-color: #2196F3
  }

  .xl\:ring-offset-blue-600 {
    --tw-ring-offset-color: #1E88E5
  }

  .xl\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1976D2
  }

  .xl\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1565C0
  }

  .xl\:ring-offset-blue-900 {
    --tw-ring-offset-color: #0D47A1
  }

  .xl\:ring-offset-blue {
    --tw-ring-offset-color: #2196F3
  }

  .xl\:ring-offset-blue-A100 {
    --tw-ring-offset-color: #82B1FF
  }

  .xl\:ring-offset-blue-A200 {
    --tw-ring-offset-color: #448AFF
  }

  .xl\:ring-offset-blue-A400 {
    --tw-ring-offset-color: #2979FF
  }

  .xl\:ring-offset-blue-A700 {
    --tw-ring-offset-color: #2962FF
  }

  .xl\:ring-offset-light-blue-50 {
    --tw-ring-offset-color: #E1F5FE
  }

  .xl\:ring-offset-light-blue-100 {
    --tw-ring-offset-color: #B3E5FC
  }

  .xl\:ring-offset-light-blue-200 {
    --tw-ring-offset-color: #81D4FA
  }

  .xl\:ring-offset-light-blue-300 {
    --tw-ring-offset-color: #4FC3F7
  }

  .xl\:ring-offset-light-blue-400 {
    --tw-ring-offset-color: #29B6F6
  }

  .xl\:ring-offset-light-blue-500 {
    --tw-ring-offset-color: #03A9F4
  }

  .xl\:ring-offset-light-blue-600 {
    --tw-ring-offset-color: #039BE5
  }

  .xl\:ring-offset-light-blue-700 {
    --tw-ring-offset-color: #0288D1
  }

  .xl\:ring-offset-light-blue-800 {
    --tw-ring-offset-color: #0277BD
  }

  .xl\:ring-offset-light-blue-900 {
    --tw-ring-offset-color: #01579B
  }

  .xl\:ring-offset-light-blue {
    --tw-ring-offset-color: #03A9F4
  }

  .xl\:ring-offset-light-blue-A100 {
    --tw-ring-offset-color: #80D8FF
  }

  .xl\:ring-offset-light-blue-A200 {
    --tw-ring-offset-color: #40C4FF
  }

  .xl\:ring-offset-light-blue-A400 {
    --tw-ring-offset-color: #00B0FF
  }

  .xl\:ring-offset-light-blue-A700 {
    --tw-ring-offset-color: #0091EA
  }

  .xl\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #E8EAF6
  }

  .xl\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #C5CAE9
  }

  .xl\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #9FA8DA
  }

  .xl\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #7986CB
  }

  .xl\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #5C6BC0
  }

  .xl\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #3F51B5
  }

  .xl\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #3949AB
  }

  .xl\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #303F9F
  }

  .xl\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #283593
  }

  .xl\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #1A237E
  }

  .xl\:ring-offset-indigo {
    --tw-ring-offset-color: #3F51B5
  }

  .xl\:ring-offset-indigo-A100 {
    --tw-ring-offset-color: #8C9EFF
  }

  .xl\:ring-offset-indigo-A200 {
    --tw-ring-offset-color: #536DFE
  }

  .xl\:ring-offset-indigo-A400 {
    --tw-ring-offset-color: #3D5AFE
  }

  .xl\:ring-offset-indigo-A700 {
    --tw-ring-offset-color: #304FFE
  }

  .xl\:ring-offset-purple-50 {
    --tw-ring-offset-color: #F3E5F5
  }

  .xl\:ring-offset-purple-100 {
    --tw-ring-offset-color: #E1BEE7
  }

  .xl\:ring-offset-purple-200 {
    --tw-ring-offset-color: #CE93D8
  }

  .xl\:ring-offset-purple-300 {
    --tw-ring-offset-color: #BA68C8
  }

  .xl\:ring-offset-purple-400 {
    --tw-ring-offset-color: #AB47BC
  }

  .xl\:ring-offset-purple-500 {
    --tw-ring-offset-color: #9C27B0
  }

  .xl\:ring-offset-purple-600 {
    --tw-ring-offset-color: #8E24AA
  }

  .xl\:ring-offset-purple-700 {
    --tw-ring-offset-color: #7B1FA2
  }

  .xl\:ring-offset-purple-800 {
    --tw-ring-offset-color: #6A1B9A
  }

  .xl\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4A148C
  }

  .xl\:ring-offset-purple {
    --tw-ring-offset-color: #9C27B0
  }

  .xl\:ring-offset-purple-A100 {
    --tw-ring-offset-color: #EA80FC
  }

  .xl\:ring-offset-purple-A200 {
    --tw-ring-offset-color: #E040FB
  }

  .xl\:ring-offset-purple-A400 {
    --tw-ring-offset-color: #D500F9
  }

  .xl\:ring-offset-purple-A700 {
    --tw-ring-offset-color: #AA00FF
  }

  .xl\:ring-offset-deep-purple-50 {
    --tw-ring-offset-color: #EDE7F6
  }

  .xl\:ring-offset-deep-purple-100 {
    --tw-ring-offset-color: #D1C4E9
  }

  .xl\:ring-offset-deep-purple-200 {
    --tw-ring-offset-color: #B39DDB
  }

  .xl\:ring-offset-deep-purple-300 {
    --tw-ring-offset-color: #9575CD
  }

  .xl\:ring-offset-deep-purple-400 {
    --tw-ring-offset-color: #7E57C2
  }

  .xl\:ring-offset-deep-purple-500 {
    --tw-ring-offset-color: #673AB7
  }

  .xl\:ring-offset-deep-purple-600 {
    --tw-ring-offset-color: #5E35B1
  }

  .xl\:ring-offset-deep-purple-700 {
    --tw-ring-offset-color: #512DA8
  }

  .xl\:ring-offset-deep-purple-800 {
    --tw-ring-offset-color: #4527A0
  }

  .xl\:ring-offset-deep-purple-900 {
    --tw-ring-offset-color: #311B92
  }

  .xl\:ring-offset-deep-purple {
    --tw-ring-offset-color: #673AB7
  }

  .xl\:ring-offset-deep-purple-A100 {
    --tw-ring-offset-color: #B388FF
  }

  .xl\:ring-offset-deep-purple-A200 {
    --tw-ring-offset-color: #7C4DFF
  }

  .xl\:ring-offset-deep-purple-A400 {
    --tw-ring-offset-color: #651FFF
  }

  .xl\:ring-offset-deep-purple-A700 {
    --tw-ring-offset-color: #6200EA
  }

  .xl\:ring-offset-pink-50 {
    --tw-ring-offset-color: #FCE4EC
  }

  .xl\:ring-offset-pink-100 {
    --tw-ring-offset-color: #F8BBD0
  }

  .xl\:ring-offset-pink-200 {
    --tw-ring-offset-color: #F48FB1
  }

  .xl\:ring-offset-pink-300 {
    --tw-ring-offset-color: #F06292
  }

  .xl\:ring-offset-pink-400 {
    --tw-ring-offset-color: #EC407A
  }

  .xl\:ring-offset-pink-500 {
    --tw-ring-offset-color: #E91E63
  }

  .xl\:ring-offset-pink-600 {
    --tw-ring-offset-color: #D81B60
  }

  .xl\:ring-offset-pink-700 {
    --tw-ring-offset-color: #C2185B
  }

  .xl\:ring-offset-pink-800 {
    --tw-ring-offset-color: #AD1457
  }

  .xl\:ring-offset-pink-900 {
    --tw-ring-offset-color: #880E4F
  }

  .xl\:ring-offset-pink {
    --tw-ring-offset-color: #E91E63
  }

  .xl\:ring-offset-pink-A100 {
    --tw-ring-offset-color: #FF80AB
  }

  .xl\:ring-offset-pink-A200 {
    --tw-ring-offset-color: #FF4081
  }

  .xl\:ring-offset-pink-A400 {
    --tw-ring-offset-color: #F50057
  }

  .xl\:ring-offset-pink-A700 {
    --tw-ring-offset-color: #C51162
  }

  .xl\:ring-offset-lime-50 {
    --tw-ring-offset-color: #F9FBE7
  }

  .xl\:ring-offset-lime-100 {
    --tw-ring-offset-color: #F0F4C3
  }

  .xl\:ring-offset-lime-200 {
    --tw-ring-offset-color: #E6EE9C
  }

  .xl\:ring-offset-lime-300 {
    --tw-ring-offset-color: #DCE775
  }

  .xl\:ring-offset-lime-400 {
    --tw-ring-offset-color: #D4E157
  }

  .xl\:ring-offset-lime-500 {
    --tw-ring-offset-color: #CDDC39
  }

  .xl\:ring-offset-lime-600 {
    --tw-ring-offset-color: #C0CA33
  }

  .xl\:ring-offset-lime-700 {
    --tw-ring-offset-color: #AFB42B
  }

  .xl\:ring-offset-lime-800 {
    --tw-ring-offset-color: #9E9D24
  }

  .xl\:ring-offset-lime-900 {
    --tw-ring-offset-color: #827717
  }

  .xl\:ring-offset-lime {
    --tw-ring-offset-color: #CDDC39
  }

  .xl\:ring-offset-lime-A100 {
    --tw-ring-offset-color: #F4FF81
  }

  .xl\:ring-offset-lime-A200 {
    --tw-ring-offset-color: #EEFF41
  }

  .xl\:ring-offset-lime-A400 {
    --tw-ring-offset-color: #C6FF00
  }

  .xl\:ring-offset-lime-A700 {
    --tw-ring-offset-color: #AEEA00
  }

  .xl\:ring-offset-amber-50 {
    --tw-ring-offset-color: #FFF8E1
  }

  .xl\:ring-offset-amber-100 {
    --tw-ring-offset-color: #FFECB3
  }

  .xl\:ring-offset-amber-200 {
    --tw-ring-offset-color: #FFE082
  }

  .xl\:ring-offset-amber-300 {
    --tw-ring-offset-color: #FFD54F
  }

  .xl\:ring-offset-amber-400 {
    --tw-ring-offset-color: #FFCA28
  }

  .xl\:ring-offset-amber-500 {
    --tw-ring-offset-color: #FFC107
  }

  .xl\:ring-offset-amber-600 {
    --tw-ring-offset-color: #FFB300
  }

  .xl\:ring-offset-amber-700 {
    --tw-ring-offset-color: #FFA000
  }

  .xl\:ring-offset-amber-800 {
    --tw-ring-offset-color: #FF8F00
  }

  .xl\:ring-offset-amber-900 {
    --tw-ring-offset-color: #FF6F00
  }

  .xl\:ring-offset-amber {
    --tw-ring-offset-color: #FFC107
  }

  .xl\:ring-offset-amber-A100 {
    --tw-ring-offset-color: #FFE57F
  }

  .xl\:ring-offset-amber-A200 {
    --tw-ring-offset-color: #FFD740
  }

  .xl\:ring-offset-amber-A400 {
    --tw-ring-offset-color: #FFC400
  }

  .xl\:ring-offset-amber-A700 {
    --tw-ring-offset-color: #FFAB00
  }

  .xl\:ring-offset-brown-50 {
    --tw-ring-offset-color: #EFEBE9
  }

  .xl\:ring-offset-brown-100 {
    --tw-ring-offset-color: #D7CCC8
  }

  .xl\:ring-offset-brown-200 {
    --tw-ring-offset-color: #BCAAA4
  }

  .xl\:ring-offset-brown-300 {
    --tw-ring-offset-color: #A1887F
  }

  .xl\:ring-offset-brown-400 {
    --tw-ring-offset-color: #8D6E63
  }

  .xl\:ring-offset-brown-500 {
    --tw-ring-offset-color: #795548
  }

  .xl\:ring-offset-brown-600 {
    --tw-ring-offset-color: #6D4C41
  }

  .xl\:ring-offset-brown-700 {
    --tw-ring-offset-color: #5D4037
  }

  .xl\:ring-offset-brown-800 {
    --tw-ring-offset-color: #4E342E
  }

  .xl\:ring-offset-brown-900 {
    --tw-ring-offset-color: #3E2723
  }

  .xl\:ring-offset-brown {
    --tw-ring-offset-color: #795548
  }

  .xl\:ring-offset-brown-A100 {
    --tw-ring-offset-color: #D7CCC8
  }

  .xl\:ring-offset-brown-A200 {
    --tw-ring-offset-color: #BCAAA4
  }

  .xl\:ring-offset-brown-A400 {
    --tw-ring-offset-color: #8D6E63
  }

  .xl\:ring-offset-brown-A700 {
    --tw-ring-offset-color: #5D4037
  }

  .xl\:ring-offset-blue-gray-50 {
    --tw-ring-offset-color: #ECEFF1
  }

  .xl\:ring-offset-blue-gray-100 {
    --tw-ring-offset-color: #CFD8DC
  }

  .xl\:ring-offset-blue-gray-200 {
    --tw-ring-offset-color: #B0BEC5
  }

  .xl\:ring-offset-blue-gray-300 {
    --tw-ring-offset-color: #90A4AE
  }

  .xl\:ring-offset-blue-gray-400 {
    --tw-ring-offset-color: #78909C
  }

  .xl\:ring-offset-blue-gray-500 {
    --tw-ring-offset-color: #607D8B
  }

  .xl\:ring-offset-blue-gray-600 {
    --tw-ring-offset-color: #546E7A
  }

  .xl\:ring-offset-blue-gray-700 {
    --tw-ring-offset-color: #455A64
  }

  .xl\:ring-offset-blue-gray-800 {
    --tw-ring-offset-color: #37474F
  }

  .xl\:ring-offset-blue-gray-900 {
    --tw-ring-offset-color: #263238
  }

  .xl\:ring-offset-blue-gray {
    --tw-ring-offset-color: #607D8B
  }

  .xl\:ring-offset-blue-gray-A100 {
    --tw-ring-offset-color: #CFD8DC
  }

  .xl\:ring-offset-blue-gray-A200 {
    --tw-ring-offset-color: #B0BEC5
  }

  .xl\:ring-offset-blue-gray-A400 {
    --tw-ring-offset-color: #78909C
  }

  .xl\:ring-offset-blue-gray-A700 {
    --tw-ring-offset-color: #455A64
  }

  .xl\:ring-offset-cyan-50 {
    --tw-ring-offset-color: #E0F7FA
  }

  .xl\:ring-offset-cyan-100 {
    --tw-ring-offset-color: #B2EBF2
  }

  .xl\:ring-offset-cyan-200 {
    --tw-ring-offset-color: #80DEEA
  }

  .xl\:ring-offset-cyan-300 {
    --tw-ring-offset-color: #4DD0E1
  }

  .xl\:ring-offset-cyan-400 {
    --tw-ring-offset-color: #26C6DA
  }

  .xl\:ring-offset-cyan-500 {
    --tw-ring-offset-color: #00BCD4
  }

  .xl\:ring-offset-cyan-600 {
    --tw-ring-offset-color: #00ACC1
  }

  .xl\:ring-offset-cyan-700 {
    --tw-ring-offset-color: #0097A7
  }

  .xl\:ring-offset-cyan-800 {
    --tw-ring-offset-color: #00838F
  }

  .xl\:ring-offset-cyan-900 {
    --tw-ring-offset-color: #006064
  }

  .xl\:ring-offset-cyan {
    --tw-ring-offset-color: #00BCD4
  }

  .xl\:ring-offset-cyan-A100 {
    --tw-ring-offset-color: #84FFFF
  }

  .xl\:ring-offset-cyan-A200 {
    --tw-ring-offset-color: #18FFFF
  }

  .xl\:ring-offset-cyan-A400 {
    --tw-ring-offset-color: #00E5FF
  }

  .xl\:ring-offset-cyan-A700 {
    --tw-ring-offset-color: #00B8D4
  }

  .xl\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent
  }

  .xl\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor
  }

  .xl\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #22292F
  }

  .xl\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff
  }

  .xl\:focus-within\:ring-offset-grey-50:focus-within {
    --tw-ring-offset-color: #FAFAFA
  }

  .xl\:focus-within\:ring-offset-grey-100:focus-within {
    --tw-ring-offset-color: #F5F5F5
  }

  .xl\:focus-within\:ring-offset-grey-200:focus-within {
    --tw-ring-offset-color: #EEEEEE
  }

  .xl\:focus-within\:ring-offset-grey-300:focus-within {
    --tw-ring-offset-color: #E0E0E0
  }

  .xl\:focus-within\:ring-offset-grey-400:focus-within {
    --tw-ring-offset-color: #BDBDBD
  }

  .xl\:focus-within\:ring-offset-grey-500:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .xl\:focus-within\:ring-offset-grey-600:focus-within {
    --tw-ring-offset-color: #757575
  }

  .xl\:focus-within\:ring-offset-grey-700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .xl\:focus-within\:ring-offset-grey-800:focus-within {
    --tw-ring-offset-color: #424242
  }

  .xl\:focus-within\:ring-offset-grey-900:focus-within {
    --tw-ring-offset-color: #212121
  }

  .xl\:focus-within\:ring-offset-grey:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .xl\:focus-within\:ring-offset-grey-A100:focus-within {
    --tw-ring-offset-color: #D5D5D5
  }

  .xl\:focus-within\:ring-offset-grey-A200:focus-within {
    --tw-ring-offset-color: #AAAAAA
  }

  .xl\:focus-within\:ring-offset-grey-A400:focus-within {
    --tw-ring-offset-color: #303030
  }

  .xl\:focus-within\:ring-offset-grey-A700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .xl\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #FAFAFA
  }

  .xl\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #F5F5F5
  }

  .xl\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #EEEEEE
  }

  .xl\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #E0E0E0
  }

  .xl\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #BDBDBD
  }

  .xl\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .xl\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #757575
  }

  .xl\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .xl\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #424242
  }

  .xl\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #212121
  }

  .xl\:focus-within\:ring-offset-gray:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .xl\:focus-within\:ring-offset-gray-hover:focus-within {
    --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
  }

  .xl\:focus-within\:ring-offset-gray-A100:focus-within {
    --tw-ring-offset-color: #D5D5D5
  }

  .xl\:focus-within\:ring-offset-gray-A200:focus-within {
    --tw-ring-offset-color: #AAAAAA
  }

  .xl\:focus-within\:ring-offset-gray-A400:focus-within {
    --tw-ring-offset-color: #303030
  }

  .xl\:focus-within\:ring-offset-gray-A700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .xl\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #FFEBEE
  }

  .xl\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #FFCDD2
  }

  .xl\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #EF9A9A
  }

  .xl\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #E57373
  }

  .xl\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #EF5350
  }

  .xl\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #F44336
  }

  .xl\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #E53935
  }

  .xl\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #D32F2F
  }

  .xl\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #C62828
  }

  .xl\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #B71C1C
  }

  .xl\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: #F44336
  }

  .xl\:focus-within\:ring-offset-red-A100:focus-within {
    --tw-ring-offset-color: #FF8A80
  }

  .xl\:focus-within\:ring-offset-red-A200:focus-within {
    --tw-ring-offset-color: #FF5252
  }

  .xl\:focus-within\:ring-offset-red-A400:focus-within {
    --tw-ring-offset-color: #FF1744
  }

  .xl\:focus-within\:ring-offset-red-A700:focus-within {
    --tw-ring-offset-color: #D50000
  }

  .xl\:focus-within\:ring-offset-orange-50:focus-within {
    --tw-ring-offset-color: #FFF3E0
  }

  .xl\:focus-within\:ring-offset-orange-100:focus-within {
    --tw-ring-offset-color: #FFE0B2
  }

  .xl\:focus-within\:ring-offset-orange-200:focus-within {
    --tw-ring-offset-color: #FFCC80
  }

  .xl\:focus-within\:ring-offset-orange-300:focus-within {
    --tw-ring-offset-color: #FFB74D
  }

  .xl\:focus-within\:ring-offset-orange-400:focus-within {
    --tw-ring-offset-color: #FFA726
  }

  .xl\:focus-within\:ring-offset-orange-500:focus-within {
    --tw-ring-offset-color: #FF9800
  }

  .xl\:focus-within\:ring-offset-orange-600:focus-within {
    --tw-ring-offset-color: #FB8C00
  }

  .xl\:focus-within\:ring-offset-orange-700:focus-within {
    --tw-ring-offset-color: #F57C00
  }

  .xl\:focus-within\:ring-offset-orange-800:focus-within {
    --tw-ring-offset-color: #EF6C00
  }

  .xl\:focus-within\:ring-offset-orange-900:focus-within {
    --tw-ring-offset-color: #E65100
  }

  .xl\:focus-within\:ring-offset-orange:focus-within {
    --tw-ring-offset-color: #FF9800
  }

  .xl\:focus-within\:ring-offset-orange-A100:focus-within {
    --tw-ring-offset-color: #FFD180
  }

  .xl\:focus-within\:ring-offset-orange-A200:focus-within {
    --tw-ring-offset-color: #FFAB40
  }

  .xl\:focus-within\:ring-offset-orange-A400:focus-within {
    --tw-ring-offset-color: #FF9100
  }

  .xl\:focus-within\:ring-offset-orange-A700:focus-within {
    --tw-ring-offset-color: #FF6D00
  }

  .xl\:focus-within\:ring-offset-deep-orange-50:focus-within {
    --tw-ring-offset-color: #FBE9E7
  }

  .xl\:focus-within\:ring-offset-deep-orange-100:focus-within {
    --tw-ring-offset-color: #FFCCBC
  }

  .xl\:focus-within\:ring-offset-deep-orange-200:focus-within {
    --tw-ring-offset-color: #FFAB91
  }

  .xl\:focus-within\:ring-offset-deep-orange-300:focus-within {
    --tw-ring-offset-color: #FF8A65
  }

  .xl\:focus-within\:ring-offset-deep-orange-400:focus-within {
    --tw-ring-offset-color: #FF7043
  }

  .xl\:focus-within\:ring-offset-deep-orange-500:focus-within {
    --tw-ring-offset-color: #FF5722
  }

  .xl\:focus-within\:ring-offset-deep-orange-600:focus-within {
    --tw-ring-offset-color: #F4511E
  }

  .xl\:focus-within\:ring-offset-deep-orange-700:focus-within {
    --tw-ring-offset-color: #E64A19
  }

  .xl\:focus-within\:ring-offset-deep-orange-800:focus-within {
    --tw-ring-offset-color: #D84315
  }

  .xl\:focus-within\:ring-offset-deep-orange-900:focus-within {
    --tw-ring-offset-color: #BF360C
  }

  .xl\:focus-within\:ring-offset-deep-orange:focus-within {
    --tw-ring-offset-color: #FF5722
  }

  .xl\:focus-within\:ring-offset-deep-orange-A100:focus-within {
    --tw-ring-offset-color: #FF9E80
  }

  .xl\:focus-within\:ring-offset-deep-orange-A200:focus-within {
    --tw-ring-offset-color: #FF6E40
  }

  .xl\:focus-within\:ring-offset-deep-orange-A400:focus-within {
    --tw-ring-offset-color: #FF3D00
  }

  .xl\:focus-within\:ring-offset-deep-orange-A700:focus-within {
    --tw-ring-offset-color: #DD2C00
  }

  .xl\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #FFFDE7
  }

  .xl\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #FFF9C4
  }

  .xl\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #FFF59D
  }

  .xl\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #FFF176
  }

  .xl\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #FFEE58
  }

  .xl\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #FFEB3B
  }

  .xl\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #FDD835
  }

  .xl\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #FBC02D
  }

  .xl\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #F9A825
  }

  .xl\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #F57F17
  }

  .xl\:focus-within\:ring-offset-yellow:focus-within {
    --tw-ring-offset-color: #FFEB3B
  }

  .xl\:focus-within\:ring-offset-yellow-A100:focus-within {
    --tw-ring-offset-color: #FFFF8D
  }

  .xl\:focus-within\:ring-offset-yellow-A200:focus-within {
    --tw-ring-offset-color: #FFFF00
  }

  .xl\:focus-within\:ring-offset-yellow-A400:focus-within {
    --tw-ring-offset-color: #FFEA00
  }

  .xl\:focus-within\:ring-offset-yellow-A700:focus-within {
    --tw-ring-offset-color: #FFD600
  }

  .xl\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #E8F5E9
  }

  .xl\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #C8E6C9
  }

  .xl\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #A5D6A7
  }

  .xl\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #81C784
  }

  .xl\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #66BB6A
  }

  .xl\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #4CAF50
  }

  .xl\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #43A047
  }

  .xl\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #388E3C
  }

  .xl\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #2E7D32
  }

  .xl\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #1B5E20
  }

  .xl\:focus-within\:ring-offset-green:focus-within {
    --tw-ring-offset-color: #4CAF50
  }

  .xl\:focus-within\:ring-offset-green-A100:focus-within {
    --tw-ring-offset-color: #B9F6CA
  }

  .xl\:focus-within\:ring-offset-green-A200:focus-within {
    --tw-ring-offset-color: #69F0AE
  }

  .xl\:focus-within\:ring-offset-green-A400:focus-within {
    --tw-ring-offset-color: #00E676
  }

  .xl\:focus-within\:ring-offset-green-A700:focus-within {
    --tw-ring-offset-color: #00C853
  }

  .xl\:focus-within\:ring-offset-light-green-50:focus-within {
    --tw-ring-offset-color: #F1F8E9
  }

  .xl\:focus-within\:ring-offset-light-green-100:focus-within {
    --tw-ring-offset-color: #DCEDC8
  }

  .xl\:focus-within\:ring-offset-light-green-200:focus-within {
    --tw-ring-offset-color: #C5E1A5
  }

  .xl\:focus-within\:ring-offset-light-green-300:focus-within {
    --tw-ring-offset-color: #AED581
  }

  .xl\:focus-within\:ring-offset-light-green-400:focus-within {
    --tw-ring-offset-color: #9CCC65
  }

  .xl\:focus-within\:ring-offset-light-green-500:focus-within {
    --tw-ring-offset-color: #8BC34A
  }

  .xl\:focus-within\:ring-offset-light-green-600:focus-within {
    --tw-ring-offset-color: #7CB342
  }

  .xl\:focus-within\:ring-offset-light-green-700:focus-within {
    --tw-ring-offset-color: #689F38
  }

  .xl\:focus-within\:ring-offset-light-green-800:focus-within {
    --tw-ring-offset-color: #558B2F
  }

  .xl\:focus-within\:ring-offset-light-green-900:focus-within {
    --tw-ring-offset-color: #33691E
  }

  .xl\:focus-within\:ring-offset-light-green:focus-within {
    --tw-ring-offset-color: #8BC34A
  }

  .xl\:focus-within\:ring-offset-light-green-A100:focus-within {
    --tw-ring-offset-color: #CCFF90
  }

  .xl\:focus-within\:ring-offset-light-green-A200:focus-within {
    --tw-ring-offset-color: #B2FF59
  }

  .xl\:focus-within\:ring-offset-light-green-A400:focus-within {
    --tw-ring-offset-color: #76FF03
  }

  .xl\:focus-within\:ring-offset-light-green-A700:focus-within {
    --tw-ring-offset-color: #64DD17
  }

  .xl\:focus-within\:ring-offset-teal-50:focus-within {
    --tw-ring-offset-color: #E0F2F1
  }

  .xl\:focus-within\:ring-offset-teal-100:focus-within {
    --tw-ring-offset-color: #B2DFDB
  }

  .xl\:focus-within\:ring-offset-teal-200:focus-within {
    --tw-ring-offset-color: #80CBC4
  }

  .xl\:focus-within\:ring-offset-teal-300:focus-within {
    --tw-ring-offset-color: #4DB6AC
  }

  .xl\:focus-within\:ring-offset-teal-400:focus-within {
    --tw-ring-offset-color: #26A69A
  }

  .xl\:focus-within\:ring-offset-teal-500:focus-within {
    --tw-ring-offset-color: #009688
  }

  .xl\:focus-within\:ring-offset-teal-600:focus-within {
    --tw-ring-offset-color: #00897B
  }

  .xl\:focus-within\:ring-offset-teal-700:focus-within {
    --tw-ring-offset-color: #00796B
  }

  .xl\:focus-within\:ring-offset-teal-800:focus-within {
    --tw-ring-offset-color: #00695C
  }

  .xl\:focus-within\:ring-offset-teal-900:focus-within {
    --tw-ring-offset-color: #004D40
  }

  .xl\:focus-within\:ring-offset-teal:focus-within {
    --tw-ring-offset-color: #009688
  }

  .xl\:focus-within\:ring-offset-teal-A100:focus-within {
    --tw-ring-offset-color: #A7FFEB
  }

  .xl\:focus-within\:ring-offset-teal-A200:focus-within {
    --tw-ring-offset-color: #64FFDA
  }

  .xl\:focus-within\:ring-offset-teal-A400:focus-within {
    --tw-ring-offset-color: #1DE9B6
  }

  .xl\:focus-within\:ring-offset-teal-A700:focus-within {
    --tw-ring-offset-color: #00BFA5
  }

  .xl\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #E3F2FD
  }

  .xl\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #BBDEFB
  }

  .xl\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #90CAF9
  }

  .xl\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #64B5F6
  }

  .xl\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #42A5F5
  }

  .xl\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #2196F3
  }

  .xl\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #1E88E5
  }

  .xl\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1976D2
  }

  .xl\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1565C0
  }

  .xl\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #0D47A1
  }

  .xl\:focus-within\:ring-offset-blue:focus-within {
    --tw-ring-offset-color: #2196F3
  }

  .xl\:focus-within\:ring-offset-blue-A100:focus-within {
    --tw-ring-offset-color: #82B1FF
  }

  .xl\:focus-within\:ring-offset-blue-A200:focus-within {
    --tw-ring-offset-color: #448AFF
  }

  .xl\:focus-within\:ring-offset-blue-A400:focus-within {
    --tw-ring-offset-color: #2979FF
  }

  .xl\:focus-within\:ring-offset-blue-A700:focus-within {
    --tw-ring-offset-color: #2962FF
  }

  .xl\:focus-within\:ring-offset-light-blue-50:focus-within {
    --tw-ring-offset-color: #E1F5FE
  }

  .xl\:focus-within\:ring-offset-light-blue-100:focus-within {
    --tw-ring-offset-color: #B3E5FC
  }

  .xl\:focus-within\:ring-offset-light-blue-200:focus-within {
    --tw-ring-offset-color: #81D4FA
  }

  .xl\:focus-within\:ring-offset-light-blue-300:focus-within {
    --tw-ring-offset-color: #4FC3F7
  }

  .xl\:focus-within\:ring-offset-light-blue-400:focus-within {
    --tw-ring-offset-color: #29B6F6
  }

  .xl\:focus-within\:ring-offset-light-blue-500:focus-within {
    --tw-ring-offset-color: #03A9F4
  }

  .xl\:focus-within\:ring-offset-light-blue-600:focus-within {
    --tw-ring-offset-color: #039BE5
  }

  .xl\:focus-within\:ring-offset-light-blue-700:focus-within {
    --tw-ring-offset-color: #0288D1
  }

  .xl\:focus-within\:ring-offset-light-blue-800:focus-within {
    --tw-ring-offset-color: #0277BD
  }

  .xl\:focus-within\:ring-offset-light-blue-900:focus-within {
    --tw-ring-offset-color: #01579B
  }

  .xl\:focus-within\:ring-offset-light-blue:focus-within {
    --tw-ring-offset-color: #03A9F4
  }

  .xl\:focus-within\:ring-offset-light-blue-A100:focus-within {
    --tw-ring-offset-color: #80D8FF
  }

  .xl\:focus-within\:ring-offset-light-blue-A200:focus-within {
    --tw-ring-offset-color: #40C4FF
  }

  .xl\:focus-within\:ring-offset-light-blue-A400:focus-within {
    --tw-ring-offset-color: #00B0FF
  }

  .xl\:focus-within\:ring-offset-light-blue-A700:focus-within {
    --tw-ring-offset-color: #0091EA
  }

  .xl\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #E8EAF6
  }

  .xl\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #C5CAE9
  }

  .xl\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #9FA8DA
  }

  .xl\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #7986CB
  }

  .xl\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #5C6BC0
  }

  .xl\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #3F51B5
  }

  .xl\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #3949AB
  }

  .xl\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #303F9F
  }

  .xl\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #283593
  }

  .xl\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #1A237E
  }

  .xl\:focus-within\:ring-offset-indigo:focus-within {
    --tw-ring-offset-color: #3F51B5
  }

  .xl\:focus-within\:ring-offset-indigo-A100:focus-within {
    --tw-ring-offset-color: #8C9EFF
  }

  .xl\:focus-within\:ring-offset-indigo-A200:focus-within {
    --tw-ring-offset-color: #536DFE
  }

  .xl\:focus-within\:ring-offset-indigo-A400:focus-within {
    --tw-ring-offset-color: #3D5AFE
  }

  .xl\:focus-within\:ring-offset-indigo-A700:focus-within {
    --tw-ring-offset-color: #304FFE
  }

  .xl\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #F3E5F5
  }

  .xl\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #E1BEE7
  }

  .xl\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #CE93D8
  }

  .xl\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #BA68C8
  }

  .xl\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #AB47BC
  }

  .xl\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #9C27B0
  }

  .xl\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #8E24AA
  }

  .xl\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #7B1FA2
  }

  .xl\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #6A1B9A
  }

  .xl\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4A148C
  }

  .xl\:focus-within\:ring-offset-purple:focus-within {
    --tw-ring-offset-color: #9C27B0
  }

  .xl\:focus-within\:ring-offset-purple-A100:focus-within {
    --tw-ring-offset-color: #EA80FC
  }

  .xl\:focus-within\:ring-offset-purple-A200:focus-within {
    --tw-ring-offset-color: #E040FB
  }

  .xl\:focus-within\:ring-offset-purple-A400:focus-within {
    --tw-ring-offset-color: #D500F9
  }

  .xl\:focus-within\:ring-offset-purple-A700:focus-within {
    --tw-ring-offset-color: #AA00FF
  }

  .xl\:focus-within\:ring-offset-deep-purple-50:focus-within {
    --tw-ring-offset-color: #EDE7F6
  }

  .xl\:focus-within\:ring-offset-deep-purple-100:focus-within {
    --tw-ring-offset-color: #D1C4E9
  }

  .xl\:focus-within\:ring-offset-deep-purple-200:focus-within {
    --tw-ring-offset-color: #B39DDB
  }

  .xl\:focus-within\:ring-offset-deep-purple-300:focus-within {
    --tw-ring-offset-color: #9575CD
  }

  .xl\:focus-within\:ring-offset-deep-purple-400:focus-within {
    --tw-ring-offset-color: #7E57C2
  }

  .xl\:focus-within\:ring-offset-deep-purple-500:focus-within {
    --tw-ring-offset-color: #673AB7
  }

  .xl\:focus-within\:ring-offset-deep-purple-600:focus-within {
    --tw-ring-offset-color: #5E35B1
  }

  .xl\:focus-within\:ring-offset-deep-purple-700:focus-within {
    --tw-ring-offset-color: #512DA8
  }

  .xl\:focus-within\:ring-offset-deep-purple-800:focus-within {
    --tw-ring-offset-color: #4527A0
  }

  .xl\:focus-within\:ring-offset-deep-purple-900:focus-within {
    --tw-ring-offset-color: #311B92
  }

  .xl\:focus-within\:ring-offset-deep-purple:focus-within {
    --tw-ring-offset-color: #673AB7
  }

  .xl\:focus-within\:ring-offset-deep-purple-A100:focus-within {
    --tw-ring-offset-color: #B388FF
  }

  .xl\:focus-within\:ring-offset-deep-purple-A200:focus-within {
    --tw-ring-offset-color: #7C4DFF
  }

  .xl\:focus-within\:ring-offset-deep-purple-A400:focus-within {
    --tw-ring-offset-color: #651FFF
  }

  .xl\:focus-within\:ring-offset-deep-purple-A700:focus-within {
    --tw-ring-offset-color: #6200EA
  }

  .xl\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #FCE4EC
  }

  .xl\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #F8BBD0
  }

  .xl\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #F48FB1
  }

  .xl\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #F06292
  }

  .xl\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #EC407A
  }

  .xl\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #E91E63
  }

  .xl\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #D81B60
  }

  .xl\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #C2185B
  }

  .xl\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #AD1457
  }

  .xl\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #880E4F
  }

  .xl\:focus-within\:ring-offset-pink:focus-within {
    --tw-ring-offset-color: #E91E63
  }

  .xl\:focus-within\:ring-offset-pink-A100:focus-within {
    --tw-ring-offset-color: #FF80AB
  }

  .xl\:focus-within\:ring-offset-pink-A200:focus-within {
    --tw-ring-offset-color: #FF4081
  }

  .xl\:focus-within\:ring-offset-pink-A400:focus-within {
    --tw-ring-offset-color: #F50057
  }

  .xl\:focus-within\:ring-offset-pink-A700:focus-within {
    --tw-ring-offset-color: #C51162
  }

  .xl\:focus-within\:ring-offset-lime-50:focus-within {
    --tw-ring-offset-color: #F9FBE7
  }

  .xl\:focus-within\:ring-offset-lime-100:focus-within {
    --tw-ring-offset-color: #F0F4C3
  }

  .xl\:focus-within\:ring-offset-lime-200:focus-within {
    --tw-ring-offset-color: #E6EE9C
  }

  .xl\:focus-within\:ring-offset-lime-300:focus-within {
    --tw-ring-offset-color: #DCE775
  }

  .xl\:focus-within\:ring-offset-lime-400:focus-within {
    --tw-ring-offset-color: #D4E157
  }

  .xl\:focus-within\:ring-offset-lime-500:focus-within {
    --tw-ring-offset-color: #CDDC39
  }

  .xl\:focus-within\:ring-offset-lime-600:focus-within {
    --tw-ring-offset-color: #C0CA33
  }

  .xl\:focus-within\:ring-offset-lime-700:focus-within {
    --tw-ring-offset-color: #AFB42B
  }

  .xl\:focus-within\:ring-offset-lime-800:focus-within {
    --tw-ring-offset-color: #9E9D24
  }

  .xl\:focus-within\:ring-offset-lime-900:focus-within {
    --tw-ring-offset-color: #827717
  }

  .xl\:focus-within\:ring-offset-lime:focus-within {
    --tw-ring-offset-color: #CDDC39
  }

  .xl\:focus-within\:ring-offset-lime-A100:focus-within {
    --tw-ring-offset-color: #F4FF81
  }

  .xl\:focus-within\:ring-offset-lime-A200:focus-within {
    --tw-ring-offset-color: #EEFF41
  }

  .xl\:focus-within\:ring-offset-lime-A400:focus-within {
    --tw-ring-offset-color: #C6FF00
  }

  .xl\:focus-within\:ring-offset-lime-A700:focus-within {
    --tw-ring-offset-color: #AEEA00
  }

  .xl\:focus-within\:ring-offset-amber-50:focus-within {
    --tw-ring-offset-color: #FFF8E1
  }

  .xl\:focus-within\:ring-offset-amber-100:focus-within {
    --tw-ring-offset-color: #FFECB3
  }

  .xl\:focus-within\:ring-offset-amber-200:focus-within {
    --tw-ring-offset-color: #FFE082
  }

  .xl\:focus-within\:ring-offset-amber-300:focus-within {
    --tw-ring-offset-color: #FFD54F
  }

  .xl\:focus-within\:ring-offset-amber-400:focus-within {
    --tw-ring-offset-color: #FFCA28
  }

  .xl\:focus-within\:ring-offset-amber-500:focus-within {
    --tw-ring-offset-color: #FFC107
  }

  .xl\:focus-within\:ring-offset-amber-600:focus-within {
    --tw-ring-offset-color: #FFB300
  }

  .xl\:focus-within\:ring-offset-amber-700:focus-within {
    --tw-ring-offset-color: #FFA000
  }

  .xl\:focus-within\:ring-offset-amber-800:focus-within {
    --tw-ring-offset-color: #FF8F00
  }

  .xl\:focus-within\:ring-offset-amber-900:focus-within {
    --tw-ring-offset-color: #FF6F00
  }

  .xl\:focus-within\:ring-offset-amber:focus-within {
    --tw-ring-offset-color: #FFC107
  }

  .xl\:focus-within\:ring-offset-amber-A100:focus-within {
    --tw-ring-offset-color: #FFE57F
  }

  .xl\:focus-within\:ring-offset-amber-A200:focus-within {
    --tw-ring-offset-color: #FFD740
  }

  .xl\:focus-within\:ring-offset-amber-A400:focus-within {
    --tw-ring-offset-color: #FFC400
  }

  .xl\:focus-within\:ring-offset-amber-A700:focus-within {
    --tw-ring-offset-color: #FFAB00
  }

  .xl\:focus-within\:ring-offset-brown-50:focus-within {
    --tw-ring-offset-color: #EFEBE9
  }

  .xl\:focus-within\:ring-offset-brown-100:focus-within {
    --tw-ring-offset-color: #D7CCC8
  }

  .xl\:focus-within\:ring-offset-brown-200:focus-within {
    --tw-ring-offset-color: #BCAAA4
  }

  .xl\:focus-within\:ring-offset-brown-300:focus-within {
    --tw-ring-offset-color: #A1887F
  }

  .xl\:focus-within\:ring-offset-brown-400:focus-within {
    --tw-ring-offset-color: #8D6E63
  }

  .xl\:focus-within\:ring-offset-brown-500:focus-within {
    --tw-ring-offset-color: #795548
  }

  .xl\:focus-within\:ring-offset-brown-600:focus-within {
    --tw-ring-offset-color: #6D4C41
  }

  .xl\:focus-within\:ring-offset-brown-700:focus-within {
    --tw-ring-offset-color: #5D4037
  }

  .xl\:focus-within\:ring-offset-brown-800:focus-within {
    --tw-ring-offset-color: #4E342E
  }

  .xl\:focus-within\:ring-offset-brown-900:focus-within {
    --tw-ring-offset-color: #3E2723
  }

  .xl\:focus-within\:ring-offset-brown:focus-within {
    --tw-ring-offset-color: #795548
  }

  .xl\:focus-within\:ring-offset-brown-A100:focus-within {
    --tw-ring-offset-color: #D7CCC8
  }

  .xl\:focus-within\:ring-offset-brown-A200:focus-within {
    --tw-ring-offset-color: #BCAAA4
  }

  .xl\:focus-within\:ring-offset-brown-A400:focus-within {
    --tw-ring-offset-color: #8D6E63
  }

  .xl\:focus-within\:ring-offset-brown-A700:focus-within {
    --tw-ring-offset-color: #5D4037
  }

  .xl\:focus-within\:ring-offset-blue-gray-50:focus-within {
    --tw-ring-offset-color: #ECEFF1
  }

  .xl\:focus-within\:ring-offset-blue-gray-100:focus-within {
    --tw-ring-offset-color: #CFD8DC
  }

  .xl\:focus-within\:ring-offset-blue-gray-200:focus-within {
    --tw-ring-offset-color: #B0BEC5
  }

  .xl\:focus-within\:ring-offset-blue-gray-300:focus-within {
    --tw-ring-offset-color: #90A4AE
  }

  .xl\:focus-within\:ring-offset-blue-gray-400:focus-within {
    --tw-ring-offset-color: #78909C
  }

  .xl\:focus-within\:ring-offset-blue-gray-500:focus-within {
    --tw-ring-offset-color: #607D8B
  }

  .xl\:focus-within\:ring-offset-blue-gray-600:focus-within {
    --tw-ring-offset-color: #546E7A
  }

  .xl\:focus-within\:ring-offset-blue-gray-700:focus-within {
    --tw-ring-offset-color: #455A64
  }

  .xl\:focus-within\:ring-offset-blue-gray-800:focus-within {
    --tw-ring-offset-color: #37474F
  }

  .xl\:focus-within\:ring-offset-blue-gray-900:focus-within {
    --tw-ring-offset-color: #263238
  }

  .xl\:focus-within\:ring-offset-blue-gray:focus-within {
    --tw-ring-offset-color: #607D8B
  }

  .xl\:focus-within\:ring-offset-blue-gray-A100:focus-within {
    --tw-ring-offset-color: #CFD8DC
  }

  .xl\:focus-within\:ring-offset-blue-gray-A200:focus-within {
    --tw-ring-offset-color: #B0BEC5
  }

  .xl\:focus-within\:ring-offset-blue-gray-A400:focus-within {
    --tw-ring-offset-color: #78909C
  }

  .xl\:focus-within\:ring-offset-blue-gray-A700:focus-within {
    --tw-ring-offset-color: #455A64
  }

  .xl\:focus-within\:ring-offset-cyan-50:focus-within {
    --tw-ring-offset-color: #E0F7FA
  }

  .xl\:focus-within\:ring-offset-cyan-100:focus-within {
    --tw-ring-offset-color: #B2EBF2
  }

  .xl\:focus-within\:ring-offset-cyan-200:focus-within {
    --tw-ring-offset-color: #80DEEA
  }

  .xl\:focus-within\:ring-offset-cyan-300:focus-within {
    --tw-ring-offset-color: #4DD0E1
  }

  .xl\:focus-within\:ring-offset-cyan-400:focus-within {
    --tw-ring-offset-color: #26C6DA
  }

  .xl\:focus-within\:ring-offset-cyan-500:focus-within {
    --tw-ring-offset-color: #00BCD4
  }

  .xl\:focus-within\:ring-offset-cyan-600:focus-within {
    --tw-ring-offset-color: #00ACC1
  }

  .xl\:focus-within\:ring-offset-cyan-700:focus-within {
    --tw-ring-offset-color: #0097A7
  }

  .xl\:focus-within\:ring-offset-cyan-800:focus-within {
    --tw-ring-offset-color: #00838F
  }

  .xl\:focus-within\:ring-offset-cyan-900:focus-within {
    --tw-ring-offset-color: #006064
  }

  .xl\:focus-within\:ring-offset-cyan:focus-within {
    --tw-ring-offset-color: #00BCD4
  }

  .xl\:focus-within\:ring-offset-cyan-A100:focus-within {
    --tw-ring-offset-color: #84FFFF
  }

  .xl\:focus-within\:ring-offset-cyan-A200:focus-within {
    --tw-ring-offset-color: #18FFFF
  }

  .xl\:focus-within\:ring-offset-cyan-A400:focus-within {
    --tw-ring-offset-color: #00E5FF
  }

  .xl\:focus-within\:ring-offset-cyan-A700:focus-within {
    --tw-ring-offset-color: #00B8D4
  }

  .xl\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent
  }

  .xl\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor
  }

  .xl\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #22292F
  }

  .xl\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff
  }

  .xl\:focus\:ring-offset-grey-50:focus {
    --tw-ring-offset-color: #FAFAFA
  }

  .xl\:focus\:ring-offset-grey-100:focus {
    --tw-ring-offset-color: #F5F5F5
  }

  .xl\:focus\:ring-offset-grey-200:focus {
    --tw-ring-offset-color: #EEEEEE
  }

  .xl\:focus\:ring-offset-grey-300:focus {
    --tw-ring-offset-color: #E0E0E0
  }

  .xl\:focus\:ring-offset-grey-400:focus {
    --tw-ring-offset-color: #BDBDBD
  }

  .xl\:focus\:ring-offset-grey-500:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .xl\:focus\:ring-offset-grey-600:focus {
    --tw-ring-offset-color: #757575
  }

  .xl\:focus\:ring-offset-grey-700:focus {
    --tw-ring-offset-color: #616161
  }

  .xl\:focus\:ring-offset-grey-800:focus {
    --tw-ring-offset-color: #424242
  }

  .xl\:focus\:ring-offset-grey-900:focus {
    --tw-ring-offset-color: #212121
  }

  .xl\:focus\:ring-offset-grey:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .xl\:focus\:ring-offset-grey-A100:focus {
    --tw-ring-offset-color: #D5D5D5
  }

  .xl\:focus\:ring-offset-grey-A200:focus {
    --tw-ring-offset-color: #AAAAAA
  }

  .xl\:focus\:ring-offset-grey-A400:focus {
    --tw-ring-offset-color: #303030
  }

  .xl\:focus\:ring-offset-grey-A700:focus {
    --tw-ring-offset-color: #616161
  }

  .xl\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #FAFAFA
  }

  .xl\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #F5F5F5
  }

  .xl\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #EEEEEE
  }

  .xl\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #E0E0E0
  }

  .xl\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #BDBDBD
  }

  .xl\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .xl\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #757575
  }

  .xl\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #616161
  }

  .xl\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #424242
  }

  .xl\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #212121
  }

  .xl\:focus\:ring-offset-gray:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .xl\:focus\:ring-offset-gray-hover:focus {
    --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
  }

  .xl\:focus\:ring-offset-gray-A100:focus {
    --tw-ring-offset-color: #D5D5D5
  }

  .xl\:focus\:ring-offset-gray-A200:focus {
    --tw-ring-offset-color: #AAAAAA
  }

  .xl\:focus\:ring-offset-gray-A400:focus {
    --tw-ring-offset-color: #303030
  }

  .xl\:focus\:ring-offset-gray-A700:focus {
    --tw-ring-offset-color: #616161
  }

  .xl\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #FFEBEE
  }

  .xl\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #FFCDD2
  }

  .xl\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #EF9A9A
  }

  .xl\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #E57373
  }

  .xl\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #EF5350
  }

  .xl\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #F44336
  }

  .xl\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #E53935
  }

  .xl\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #D32F2F
  }

  .xl\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #C62828
  }

  .xl\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #B71C1C
  }

  .xl\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: #F44336
  }

  .xl\:focus\:ring-offset-red-A100:focus {
    --tw-ring-offset-color: #FF8A80
  }

  .xl\:focus\:ring-offset-red-A200:focus {
    --tw-ring-offset-color: #FF5252
  }

  .xl\:focus\:ring-offset-red-A400:focus {
    --tw-ring-offset-color: #FF1744
  }

  .xl\:focus\:ring-offset-red-A700:focus {
    --tw-ring-offset-color: #D50000
  }

  .xl\:focus\:ring-offset-orange-50:focus {
    --tw-ring-offset-color: #FFF3E0
  }

  .xl\:focus\:ring-offset-orange-100:focus {
    --tw-ring-offset-color: #FFE0B2
  }

  .xl\:focus\:ring-offset-orange-200:focus {
    --tw-ring-offset-color: #FFCC80
  }

  .xl\:focus\:ring-offset-orange-300:focus {
    --tw-ring-offset-color: #FFB74D
  }

  .xl\:focus\:ring-offset-orange-400:focus {
    --tw-ring-offset-color: #FFA726
  }

  .xl\:focus\:ring-offset-orange-500:focus {
    --tw-ring-offset-color: #FF9800
  }

  .xl\:focus\:ring-offset-orange-600:focus {
    --tw-ring-offset-color: #FB8C00
  }

  .xl\:focus\:ring-offset-orange-700:focus {
    --tw-ring-offset-color: #F57C00
  }

  .xl\:focus\:ring-offset-orange-800:focus {
    --tw-ring-offset-color: #EF6C00
  }

  .xl\:focus\:ring-offset-orange-900:focus {
    --tw-ring-offset-color: #E65100
  }

  .xl\:focus\:ring-offset-orange:focus {
    --tw-ring-offset-color: #FF9800
  }

  .xl\:focus\:ring-offset-orange-A100:focus {
    --tw-ring-offset-color: #FFD180
  }

  .xl\:focus\:ring-offset-orange-A200:focus {
    --tw-ring-offset-color: #FFAB40
  }

  .xl\:focus\:ring-offset-orange-A400:focus {
    --tw-ring-offset-color: #FF9100
  }

  .xl\:focus\:ring-offset-orange-A700:focus {
    --tw-ring-offset-color: #FF6D00
  }

  .xl\:focus\:ring-offset-deep-orange-50:focus {
    --tw-ring-offset-color: #FBE9E7
  }

  .xl\:focus\:ring-offset-deep-orange-100:focus {
    --tw-ring-offset-color: #FFCCBC
  }

  .xl\:focus\:ring-offset-deep-orange-200:focus {
    --tw-ring-offset-color: #FFAB91
  }

  .xl\:focus\:ring-offset-deep-orange-300:focus {
    --tw-ring-offset-color: #FF8A65
  }

  .xl\:focus\:ring-offset-deep-orange-400:focus {
    --tw-ring-offset-color: #FF7043
  }

  .xl\:focus\:ring-offset-deep-orange-500:focus {
    --tw-ring-offset-color: #FF5722
  }

  .xl\:focus\:ring-offset-deep-orange-600:focus {
    --tw-ring-offset-color: #F4511E
  }

  .xl\:focus\:ring-offset-deep-orange-700:focus {
    --tw-ring-offset-color: #E64A19
  }

  .xl\:focus\:ring-offset-deep-orange-800:focus {
    --tw-ring-offset-color: #D84315
  }

  .xl\:focus\:ring-offset-deep-orange-900:focus {
    --tw-ring-offset-color: #BF360C
  }

  .xl\:focus\:ring-offset-deep-orange:focus {
    --tw-ring-offset-color: #FF5722
  }

  .xl\:focus\:ring-offset-deep-orange-A100:focus {
    --tw-ring-offset-color: #FF9E80
  }

  .xl\:focus\:ring-offset-deep-orange-A200:focus {
    --tw-ring-offset-color: #FF6E40
  }

  .xl\:focus\:ring-offset-deep-orange-A400:focus {
    --tw-ring-offset-color: #FF3D00
  }

  .xl\:focus\:ring-offset-deep-orange-A700:focus {
    --tw-ring-offset-color: #DD2C00
  }

  .xl\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #FFFDE7
  }

  .xl\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #FFF9C4
  }

  .xl\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #FFF59D
  }

  .xl\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #FFF176
  }

  .xl\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #FFEE58
  }

  .xl\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #FFEB3B
  }

  .xl\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #FDD835
  }

  .xl\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #FBC02D
  }

  .xl\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #F9A825
  }

  .xl\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #F57F17
  }

  .xl\:focus\:ring-offset-yellow:focus {
    --tw-ring-offset-color: #FFEB3B
  }

  .xl\:focus\:ring-offset-yellow-A100:focus {
    --tw-ring-offset-color: #FFFF8D
  }

  .xl\:focus\:ring-offset-yellow-A200:focus {
    --tw-ring-offset-color: #FFFF00
  }

  .xl\:focus\:ring-offset-yellow-A400:focus {
    --tw-ring-offset-color: #FFEA00
  }

  .xl\:focus\:ring-offset-yellow-A700:focus {
    --tw-ring-offset-color: #FFD600
  }

  .xl\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #E8F5E9
  }

  .xl\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #C8E6C9
  }

  .xl\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #A5D6A7
  }

  .xl\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #81C784
  }

  .xl\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #66BB6A
  }

  .xl\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #4CAF50
  }

  .xl\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #43A047
  }

  .xl\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #388E3C
  }

  .xl\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #2E7D32
  }

  .xl\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #1B5E20
  }

  .xl\:focus\:ring-offset-green:focus {
    --tw-ring-offset-color: #4CAF50
  }

  .xl\:focus\:ring-offset-green-A100:focus {
    --tw-ring-offset-color: #B9F6CA
  }

  .xl\:focus\:ring-offset-green-A200:focus {
    --tw-ring-offset-color: #69F0AE
  }

  .xl\:focus\:ring-offset-green-A400:focus {
    --tw-ring-offset-color: #00E676
  }

  .xl\:focus\:ring-offset-green-A700:focus {
    --tw-ring-offset-color: #00C853
  }

  .xl\:focus\:ring-offset-light-green-50:focus {
    --tw-ring-offset-color: #F1F8E9
  }

  .xl\:focus\:ring-offset-light-green-100:focus {
    --tw-ring-offset-color: #DCEDC8
  }

  .xl\:focus\:ring-offset-light-green-200:focus {
    --tw-ring-offset-color: #C5E1A5
  }

  .xl\:focus\:ring-offset-light-green-300:focus {
    --tw-ring-offset-color: #AED581
  }

  .xl\:focus\:ring-offset-light-green-400:focus {
    --tw-ring-offset-color: #9CCC65
  }

  .xl\:focus\:ring-offset-light-green-500:focus {
    --tw-ring-offset-color: #8BC34A
  }

  .xl\:focus\:ring-offset-light-green-600:focus {
    --tw-ring-offset-color: #7CB342
  }

  .xl\:focus\:ring-offset-light-green-700:focus {
    --tw-ring-offset-color: #689F38
  }

  .xl\:focus\:ring-offset-light-green-800:focus {
    --tw-ring-offset-color: #558B2F
  }

  .xl\:focus\:ring-offset-light-green-900:focus {
    --tw-ring-offset-color: #33691E
  }

  .xl\:focus\:ring-offset-light-green:focus {
    --tw-ring-offset-color: #8BC34A
  }

  .xl\:focus\:ring-offset-light-green-A100:focus {
    --tw-ring-offset-color: #CCFF90
  }

  .xl\:focus\:ring-offset-light-green-A200:focus {
    --tw-ring-offset-color: #B2FF59
  }

  .xl\:focus\:ring-offset-light-green-A400:focus {
    --tw-ring-offset-color: #76FF03
  }

  .xl\:focus\:ring-offset-light-green-A700:focus {
    --tw-ring-offset-color: #64DD17
  }

  .xl\:focus\:ring-offset-teal-50:focus {
    --tw-ring-offset-color: #E0F2F1
  }

  .xl\:focus\:ring-offset-teal-100:focus {
    --tw-ring-offset-color: #B2DFDB
  }

  .xl\:focus\:ring-offset-teal-200:focus {
    --tw-ring-offset-color: #80CBC4
  }

  .xl\:focus\:ring-offset-teal-300:focus {
    --tw-ring-offset-color: #4DB6AC
  }

  .xl\:focus\:ring-offset-teal-400:focus {
    --tw-ring-offset-color: #26A69A
  }

  .xl\:focus\:ring-offset-teal-500:focus {
    --tw-ring-offset-color: #009688
  }

  .xl\:focus\:ring-offset-teal-600:focus {
    --tw-ring-offset-color: #00897B
  }

  .xl\:focus\:ring-offset-teal-700:focus {
    --tw-ring-offset-color: #00796B
  }

  .xl\:focus\:ring-offset-teal-800:focus {
    --tw-ring-offset-color: #00695C
  }

  .xl\:focus\:ring-offset-teal-900:focus {
    --tw-ring-offset-color: #004D40
  }

  .xl\:focus\:ring-offset-teal:focus {
    --tw-ring-offset-color: #009688
  }

  .xl\:focus\:ring-offset-teal-A100:focus {
    --tw-ring-offset-color: #A7FFEB
  }

  .xl\:focus\:ring-offset-teal-A200:focus {
    --tw-ring-offset-color: #64FFDA
  }

  .xl\:focus\:ring-offset-teal-A400:focus {
    --tw-ring-offset-color: #1DE9B6
  }

  .xl\:focus\:ring-offset-teal-A700:focus {
    --tw-ring-offset-color: #00BFA5
  }

  .xl\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #E3F2FD
  }

  .xl\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #BBDEFB
  }

  .xl\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #90CAF9
  }

  .xl\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #64B5F6
  }

  .xl\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #42A5F5
  }

  .xl\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #2196F3
  }

  .xl\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #1E88E5
  }

  .xl\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1976D2
  }

  .xl\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1565C0
  }

  .xl\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #0D47A1
  }

  .xl\:focus\:ring-offset-blue:focus {
    --tw-ring-offset-color: #2196F3
  }

  .xl\:focus\:ring-offset-blue-A100:focus {
    --tw-ring-offset-color: #82B1FF
  }

  .xl\:focus\:ring-offset-blue-A200:focus {
    --tw-ring-offset-color: #448AFF
  }

  .xl\:focus\:ring-offset-blue-A400:focus {
    --tw-ring-offset-color: #2979FF
  }

  .xl\:focus\:ring-offset-blue-A700:focus {
    --tw-ring-offset-color: #2962FF
  }

  .xl\:focus\:ring-offset-light-blue-50:focus {
    --tw-ring-offset-color: #E1F5FE
  }

  .xl\:focus\:ring-offset-light-blue-100:focus {
    --tw-ring-offset-color: #B3E5FC
  }

  .xl\:focus\:ring-offset-light-blue-200:focus {
    --tw-ring-offset-color: #81D4FA
  }

  .xl\:focus\:ring-offset-light-blue-300:focus {
    --tw-ring-offset-color: #4FC3F7
  }

  .xl\:focus\:ring-offset-light-blue-400:focus {
    --tw-ring-offset-color: #29B6F6
  }

  .xl\:focus\:ring-offset-light-blue-500:focus {
    --tw-ring-offset-color: #03A9F4
  }

  .xl\:focus\:ring-offset-light-blue-600:focus {
    --tw-ring-offset-color: #039BE5
  }

  .xl\:focus\:ring-offset-light-blue-700:focus {
    --tw-ring-offset-color: #0288D1
  }

  .xl\:focus\:ring-offset-light-blue-800:focus {
    --tw-ring-offset-color: #0277BD
  }

  .xl\:focus\:ring-offset-light-blue-900:focus {
    --tw-ring-offset-color: #01579B
  }

  .xl\:focus\:ring-offset-light-blue:focus {
    --tw-ring-offset-color: #03A9F4
  }

  .xl\:focus\:ring-offset-light-blue-A100:focus {
    --tw-ring-offset-color: #80D8FF
  }

  .xl\:focus\:ring-offset-light-blue-A200:focus {
    --tw-ring-offset-color: #40C4FF
  }

  .xl\:focus\:ring-offset-light-blue-A400:focus {
    --tw-ring-offset-color: #00B0FF
  }

  .xl\:focus\:ring-offset-light-blue-A700:focus {
    --tw-ring-offset-color: #0091EA
  }

  .xl\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #E8EAF6
  }

  .xl\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #C5CAE9
  }

  .xl\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #9FA8DA
  }

  .xl\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #7986CB
  }

  .xl\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #5C6BC0
  }

  .xl\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #3F51B5
  }

  .xl\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #3949AB
  }

  .xl\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #303F9F
  }

  .xl\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #283593
  }

  .xl\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #1A237E
  }

  .xl\:focus\:ring-offset-indigo:focus {
    --tw-ring-offset-color: #3F51B5
  }

  .xl\:focus\:ring-offset-indigo-A100:focus {
    --tw-ring-offset-color: #8C9EFF
  }

  .xl\:focus\:ring-offset-indigo-A200:focus {
    --tw-ring-offset-color: #536DFE
  }

  .xl\:focus\:ring-offset-indigo-A400:focus {
    --tw-ring-offset-color: #3D5AFE
  }

  .xl\:focus\:ring-offset-indigo-A700:focus {
    --tw-ring-offset-color: #304FFE
  }

  .xl\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #F3E5F5
  }

  .xl\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #E1BEE7
  }

  .xl\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #CE93D8
  }

  .xl\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #BA68C8
  }

  .xl\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #AB47BC
  }

  .xl\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #9C27B0
  }

  .xl\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #8E24AA
  }

  .xl\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #7B1FA2
  }

  .xl\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #6A1B9A
  }

  .xl\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4A148C
  }

  .xl\:focus\:ring-offset-purple:focus {
    --tw-ring-offset-color: #9C27B0
  }

  .xl\:focus\:ring-offset-purple-A100:focus {
    --tw-ring-offset-color: #EA80FC
  }

  .xl\:focus\:ring-offset-purple-A200:focus {
    --tw-ring-offset-color: #E040FB
  }

  .xl\:focus\:ring-offset-purple-A400:focus {
    --tw-ring-offset-color: #D500F9
  }

  .xl\:focus\:ring-offset-purple-A700:focus {
    --tw-ring-offset-color: #AA00FF
  }

  .xl\:focus\:ring-offset-deep-purple-50:focus {
    --tw-ring-offset-color: #EDE7F6
  }

  .xl\:focus\:ring-offset-deep-purple-100:focus {
    --tw-ring-offset-color: #D1C4E9
  }

  .xl\:focus\:ring-offset-deep-purple-200:focus {
    --tw-ring-offset-color: #B39DDB
  }

  .xl\:focus\:ring-offset-deep-purple-300:focus {
    --tw-ring-offset-color: #9575CD
  }

  .xl\:focus\:ring-offset-deep-purple-400:focus {
    --tw-ring-offset-color: #7E57C2
  }

  .xl\:focus\:ring-offset-deep-purple-500:focus {
    --tw-ring-offset-color: #673AB7
  }

  .xl\:focus\:ring-offset-deep-purple-600:focus {
    --tw-ring-offset-color: #5E35B1
  }

  .xl\:focus\:ring-offset-deep-purple-700:focus {
    --tw-ring-offset-color: #512DA8
  }

  .xl\:focus\:ring-offset-deep-purple-800:focus {
    --tw-ring-offset-color: #4527A0
  }

  .xl\:focus\:ring-offset-deep-purple-900:focus {
    --tw-ring-offset-color: #311B92
  }

  .xl\:focus\:ring-offset-deep-purple:focus {
    --tw-ring-offset-color: #673AB7
  }

  .xl\:focus\:ring-offset-deep-purple-A100:focus {
    --tw-ring-offset-color: #B388FF
  }

  .xl\:focus\:ring-offset-deep-purple-A200:focus {
    --tw-ring-offset-color: #7C4DFF
  }

  .xl\:focus\:ring-offset-deep-purple-A400:focus {
    --tw-ring-offset-color: #651FFF
  }

  .xl\:focus\:ring-offset-deep-purple-A700:focus {
    --tw-ring-offset-color: #6200EA
  }

  .xl\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #FCE4EC
  }

  .xl\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #F8BBD0
  }

  .xl\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #F48FB1
  }

  .xl\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #F06292
  }

  .xl\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #EC407A
  }

  .xl\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #E91E63
  }

  .xl\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #D81B60
  }

  .xl\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #C2185B
  }

  .xl\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #AD1457
  }

  .xl\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #880E4F
  }

  .xl\:focus\:ring-offset-pink:focus {
    --tw-ring-offset-color: #E91E63
  }

  .xl\:focus\:ring-offset-pink-A100:focus {
    --tw-ring-offset-color: #FF80AB
  }

  .xl\:focus\:ring-offset-pink-A200:focus {
    --tw-ring-offset-color: #FF4081
  }

  .xl\:focus\:ring-offset-pink-A400:focus {
    --tw-ring-offset-color: #F50057
  }

  .xl\:focus\:ring-offset-pink-A700:focus {
    --tw-ring-offset-color: #C51162
  }

  .xl\:focus\:ring-offset-lime-50:focus {
    --tw-ring-offset-color: #F9FBE7
  }

  .xl\:focus\:ring-offset-lime-100:focus {
    --tw-ring-offset-color: #F0F4C3
  }

  .xl\:focus\:ring-offset-lime-200:focus {
    --tw-ring-offset-color: #E6EE9C
  }

  .xl\:focus\:ring-offset-lime-300:focus {
    --tw-ring-offset-color: #DCE775
  }

  .xl\:focus\:ring-offset-lime-400:focus {
    --tw-ring-offset-color: #D4E157
  }

  .xl\:focus\:ring-offset-lime-500:focus {
    --tw-ring-offset-color: #CDDC39
  }

  .xl\:focus\:ring-offset-lime-600:focus {
    --tw-ring-offset-color: #C0CA33
  }

  .xl\:focus\:ring-offset-lime-700:focus {
    --tw-ring-offset-color: #AFB42B
  }

  .xl\:focus\:ring-offset-lime-800:focus {
    --tw-ring-offset-color: #9E9D24
  }

  .xl\:focus\:ring-offset-lime-900:focus {
    --tw-ring-offset-color: #827717
  }

  .xl\:focus\:ring-offset-lime:focus {
    --tw-ring-offset-color: #CDDC39
  }

  .xl\:focus\:ring-offset-lime-A100:focus {
    --tw-ring-offset-color: #F4FF81
  }

  .xl\:focus\:ring-offset-lime-A200:focus {
    --tw-ring-offset-color: #EEFF41
  }

  .xl\:focus\:ring-offset-lime-A400:focus {
    --tw-ring-offset-color: #C6FF00
  }

  .xl\:focus\:ring-offset-lime-A700:focus {
    --tw-ring-offset-color: #AEEA00
  }

  .xl\:focus\:ring-offset-amber-50:focus {
    --tw-ring-offset-color: #FFF8E1
  }

  .xl\:focus\:ring-offset-amber-100:focus {
    --tw-ring-offset-color: #FFECB3
  }

  .xl\:focus\:ring-offset-amber-200:focus {
    --tw-ring-offset-color: #FFE082
  }

  .xl\:focus\:ring-offset-amber-300:focus {
    --tw-ring-offset-color: #FFD54F
  }

  .xl\:focus\:ring-offset-amber-400:focus {
    --tw-ring-offset-color: #FFCA28
  }

  .xl\:focus\:ring-offset-amber-500:focus {
    --tw-ring-offset-color: #FFC107
  }

  .xl\:focus\:ring-offset-amber-600:focus {
    --tw-ring-offset-color: #FFB300
  }

  .xl\:focus\:ring-offset-amber-700:focus {
    --tw-ring-offset-color: #FFA000
  }

  .xl\:focus\:ring-offset-amber-800:focus {
    --tw-ring-offset-color: #FF8F00
  }

  .xl\:focus\:ring-offset-amber-900:focus {
    --tw-ring-offset-color: #FF6F00
  }

  .xl\:focus\:ring-offset-amber:focus {
    --tw-ring-offset-color: #FFC107
  }

  .xl\:focus\:ring-offset-amber-A100:focus {
    --tw-ring-offset-color: #FFE57F
  }

  .xl\:focus\:ring-offset-amber-A200:focus {
    --tw-ring-offset-color: #FFD740
  }

  .xl\:focus\:ring-offset-amber-A400:focus {
    --tw-ring-offset-color: #FFC400
  }

  .xl\:focus\:ring-offset-amber-A700:focus {
    --tw-ring-offset-color: #FFAB00
  }

  .xl\:focus\:ring-offset-brown-50:focus {
    --tw-ring-offset-color: #EFEBE9
  }

  .xl\:focus\:ring-offset-brown-100:focus {
    --tw-ring-offset-color: #D7CCC8
  }

  .xl\:focus\:ring-offset-brown-200:focus {
    --tw-ring-offset-color: #BCAAA4
  }

  .xl\:focus\:ring-offset-brown-300:focus {
    --tw-ring-offset-color: #A1887F
  }

  .xl\:focus\:ring-offset-brown-400:focus {
    --tw-ring-offset-color: #8D6E63
  }

  .xl\:focus\:ring-offset-brown-500:focus {
    --tw-ring-offset-color: #795548
  }

  .xl\:focus\:ring-offset-brown-600:focus {
    --tw-ring-offset-color: #6D4C41
  }

  .xl\:focus\:ring-offset-brown-700:focus {
    --tw-ring-offset-color: #5D4037
  }

  .xl\:focus\:ring-offset-brown-800:focus {
    --tw-ring-offset-color: #4E342E
  }

  .xl\:focus\:ring-offset-brown-900:focus {
    --tw-ring-offset-color: #3E2723
  }

  .xl\:focus\:ring-offset-brown:focus {
    --tw-ring-offset-color: #795548
  }

  .xl\:focus\:ring-offset-brown-A100:focus {
    --tw-ring-offset-color: #D7CCC8
  }

  .xl\:focus\:ring-offset-brown-A200:focus {
    --tw-ring-offset-color: #BCAAA4
  }

  .xl\:focus\:ring-offset-brown-A400:focus {
    --tw-ring-offset-color: #8D6E63
  }

  .xl\:focus\:ring-offset-brown-A700:focus {
    --tw-ring-offset-color: #5D4037
  }

  .xl\:focus\:ring-offset-blue-gray-50:focus {
    --tw-ring-offset-color: #ECEFF1
  }

  .xl\:focus\:ring-offset-blue-gray-100:focus {
    --tw-ring-offset-color: #CFD8DC
  }

  .xl\:focus\:ring-offset-blue-gray-200:focus {
    --tw-ring-offset-color: #B0BEC5
  }

  .xl\:focus\:ring-offset-blue-gray-300:focus {
    --tw-ring-offset-color: #90A4AE
  }

  .xl\:focus\:ring-offset-blue-gray-400:focus {
    --tw-ring-offset-color: #78909C
  }

  .xl\:focus\:ring-offset-blue-gray-500:focus {
    --tw-ring-offset-color: #607D8B
  }

  .xl\:focus\:ring-offset-blue-gray-600:focus {
    --tw-ring-offset-color: #546E7A
  }

  .xl\:focus\:ring-offset-blue-gray-700:focus {
    --tw-ring-offset-color: #455A64
  }

  .xl\:focus\:ring-offset-blue-gray-800:focus {
    --tw-ring-offset-color: #37474F
  }

  .xl\:focus\:ring-offset-blue-gray-900:focus {
    --tw-ring-offset-color: #263238
  }

  .xl\:focus\:ring-offset-blue-gray:focus {
    --tw-ring-offset-color: #607D8B
  }

  .xl\:focus\:ring-offset-blue-gray-A100:focus {
    --tw-ring-offset-color: #CFD8DC
  }

  .xl\:focus\:ring-offset-blue-gray-A200:focus {
    --tw-ring-offset-color: #B0BEC5
  }

  .xl\:focus\:ring-offset-blue-gray-A400:focus {
    --tw-ring-offset-color: #78909C
  }

  .xl\:focus\:ring-offset-blue-gray-A700:focus {
    --tw-ring-offset-color: #455A64
  }

  .xl\:focus\:ring-offset-cyan-50:focus {
    --tw-ring-offset-color: #E0F7FA
  }

  .xl\:focus\:ring-offset-cyan-100:focus {
    --tw-ring-offset-color: #B2EBF2
  }

  .xl\:focus\:ring-offset-cyan-200:focus {
    --tw-ring-offset-color: #80DEEA
  }

  .xl\:focus\:ring-offset-cyan-300:focus {
    --tw-ring-offset-color: #4DD0E1
  }

  .xl\:focus\:ring-offset-cyan-400:focus {
    --tw-ring-offset-color: #26C6DA
  }

  .xl\:focus\:ring-offset-cyan-500:focus {
    --tw-ring-offset-color: #00BCD4
  }

  .xl\:focus\:ring-offset-cyan-600:focus {
    --tw-ring-offset-color: #00ACC1
  }

  .xl\:focus\:ring-offset-cyan-700:focus {
    --tw-ring-offset-color: #0097A7
  }

  .xl\:focus\:ring-offset-cyan-800:focus {
    --tw-ring-offset-color: #00838F
  }

  .xl\:focus\:ring-offset-cyan-900:focus {
    --tw-ring-offset-color: #006064
  }

  .xl\:focus\:ring-offset-cyan:focus {
    --tw-ring-offset-color: #00BCD4
  }

  .xl\:focus\:ring-offset-cyan-A100:focus {
    --tw-ring-offset-color: #84FFFF
  }

  .xl\:focus\:ring-offset-cyan-A200:focus {
    --tw-ring-offset-color: #18FFFF
  }

  .xl\:focus\:ring-offset-cyan-A400:focus {
    --tw-ring-offset-color: #00E5FF
  }

  .xl\:focus\:ring-offset-cyan-A700:focus {
    --tw-ring-offset-color: #00B8D4
  }

  .xl\:ring-offset-0 {
    --tw-ring-offset-width: 0px
  }

  .xl\:ring-offset-1 {
    --tw-ring-offset-width: 1px
  }

  .xl\:ring-offset-2 {
    --tw-ring-offset-width: 2px
  }

  .xl\:ring-offset-4 {
    --tw-ring-offset-width: 4px
  }

  .xl\:ring-offset-8 {
    --tw-ring-offset-width: 8px
  }

  .xl\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px
  }

  .xl\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px
  }

  .xl\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px
  }

  .xl\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px
  }

  .xl\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px
  }

  .xl\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px
  }

  .xl\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px
  }

  .xl\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px
  }

  .xl\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px
  }

  .xl\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px
  }

  .xl\:ring-transparent {
    --tw-ring-color: transparent
  }

  .xl\:ring-current {
    --tw-ring-color: currentColor
  }

  .xl\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .xl\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-grey-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .xl\:ring-grey-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .xl\:ring-grey-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .xl\:ring-grey-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .xl\:ring-grey-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .xl\:ring-grey-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .xl\:ring-grey-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .xl\:ring-grey-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .xl\:ring-grey-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .xl\:ring-grey-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .xl\:ring-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .xl\:ring-grey-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .xl\:ring-grey-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .xl\:ring-grey-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .xl\:ring-grey-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .xl\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .xl\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .xl\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .xl\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .xl\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .xl\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .xl\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .xl\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .xl\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .xl\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .xl\:ring-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .xl\:ring-gray-hover {
    --tw-ring-color: rgba(0, 0, 0, 0.04)
  }

  .xl\:ring-gray-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .xl\:ring-gray-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .xl\:ring-gray-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .xl\:ring-gray-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .xl\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .xl\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .xl\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .xl\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .xl\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .xl\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .xl\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .xl\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .xl\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .xl\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .xl\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .xl\:ring-red-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .xl\:ring-red-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .xl\:ring-red-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .xl\:ring-red-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-orange-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .xl\:ring-orange-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .xl\:ring-orange-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .xl\:ring-orange-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .xl\:ring-orange-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .xl\:ring-orange-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-orange-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-orange-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-orange-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-orange-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-orange {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-orange-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .xl\:ring-orange-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .xl\:ring-orange-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-orange-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-orange-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-orange-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-orange-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-orange-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-orange-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-orange-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-orange-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-orange-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-orange-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-orange-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-orange {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-orange-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-orange-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-orange-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-orange-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .xl\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .xl\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .xl\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .xl\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .xl\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .xl\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .xl\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .xl\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .xl\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .xl\:ring-yellow {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .xl\:ring-yellow-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .xl\:ring-yellow-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-yellow-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-yellow-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .xl\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .xl\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .xl\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .xl\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .xl\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .xl\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .xl\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .xl\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .xl\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .xl\:ring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .xl\:ring-green-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .xl\:ring-green-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .xl\:ring-green-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .xl\:ring-green-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .xl\:ring-light-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .xl\:ring-light-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .xl\:ring-light-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .xl\:ring-light-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .xl\:ring-light-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .xl\:ring-light-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .xl\:ring-light-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .xl\:ring-light-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .xl\:ring-light-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .xl\:ring-light-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .xl\:ring-light-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .xl\:ring-light-green-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .xl\:ring-light-green-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .xl\:ring-light-green-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .xl\:ring-light-green-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .xl\:ring-teal-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .xl\:ring-teal-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .xl\:ring-teal-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .xl\:ring-teal-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .xl\:ring-teal-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .xl\:ring-teal-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .xl\:ring-teal-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .xl\:ring-teal-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .xl\:ring-teal-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .xl\:ring-teal-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .xl\:ring-teal {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .xl\:ring-teal-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .xl\:ring-teal-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .xl\:ring-teal-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .xl\:ring-teal-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .xl\:ring-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-light-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .xl\:ring-light-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .xl\:ring-light-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .xl\:ring-light-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .xl\:ring-light-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .xl\:ring-light-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .xl\:ring-light-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .xl\:ring-light-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .xl\:ring-light-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .xl\:ring-light-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .xl\:ring-light-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .xl\:ring-light-blue-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-light-blue-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-light-blue-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-light-blue-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .xl\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .xl\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .xl\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .xl\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .xl\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .xl\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .xl\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .xl\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .xl\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .xl\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .xl\:ring-indigo {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .xl\:ring-indigo-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-indigo-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .xl\:ring-indigo-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .xl\:ring-indigo-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .xl\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .xl\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .xl\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .xl\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .xl\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .xl\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .xl\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .xl\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .xl\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .xl\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .xl\:ring-purple {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .xl\:ring-purple-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .xl\:ring-purple-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .xl\:ring-purple-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .xl\:ring-purple-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-purple {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-purple-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-purple-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-purple-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-deep-purple-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .xl\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .xl\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .xl\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .xl\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .xl\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .xl\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .xl\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .xl\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .xl\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .xl\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .xl\:ring-pink {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .xl\:ring-pink-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .xl\:ring-pink-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .xl\:ring-pink-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .xl\:ring-pink-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .xl\:ring-lime-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .xl\:ring-lime-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .xl\:ring-lime-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .xl\:ring-lime-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .xl\:ring-lime-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .xl\:ring-lime-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .xl\:ring-lime-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .xl\:ring-lime-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .xl\:ring-lime-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .xl\:ring-lime-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .xl\:ring-lime {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .xl\:ring-lime-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .xl\:ring-lime-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .xl\:ring-lime-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-lime-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-amber-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .xl\:ring-amber-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .xl\:ring-amber-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .xl\:ring-amber-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .xl\:ring-amber-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .xl\:ring-amber-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .xl\:ring-amber-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-amber-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-amber-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-amber-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-amber {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .xl\:ring-amber-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .xl\:ring-amber-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .xl\:ring-amber-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-amber-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-brown-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .xl\:ring-brown-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .xl\:ring-brown-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .xl\:ring-brown-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .xl\:ring-brown-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .xl\:ring-brown-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .xl\:ring-brown-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .xl\:ring-brown-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .xl\:ring-brown-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .xl\:ring-brown-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .xl\:ring-brown {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .xl\:ring-brown-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .xl\:ring-brown-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .xl\:ring-brown-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .xl\:ring-brown-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-gray-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-gray-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-gray-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .xl\:ring-blue-gray-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent
  }

  .xl\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor
  }

  .xl\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-grey-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-grey-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-grey-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-grey-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-grey-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-grey-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-grey-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-grey-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-grey-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-grey-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-grey-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-grey-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-grey-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-grey-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray-hover:focus-within {
    --tw-ring-color: rgba(0, 0, 0, 0.04)
  }

  .xl\:focus-within\:ring-gray-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-orange-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-orange-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-orange-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-orange-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-orange-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-orange-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-orange-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-orange-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-orange-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-orange-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-orange:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-orange-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-orange-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-orange-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-orange-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-orange-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-orange-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-orange-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-orange-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-orange-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-orange-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-orange-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-orange-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-orange-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-orange-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-orange:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-orange-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-orange-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-orange-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-orange-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-yellow:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-yellow-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-yellow-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-yellow-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-yellow-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-green-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-green-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-green-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-green-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-teal-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-teal-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-teal-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-teal-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-teal-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-teal-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-teal-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-teal-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-teal-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-teal-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-teal:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-teal-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-teal-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-teal-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-teal-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-blue-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-blue-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-blue-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-light-blue-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-indigo:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-indigo-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-indigo-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-indigo-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-indigo-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-purple:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-purple-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-purple-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-purple-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-purple-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-purple:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-purple-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-purple-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-purple-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-deep-purple-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-pink:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-pink-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-pink-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-pink-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-pink-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-lime-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-lime-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-lime-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-lime-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-lime-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-lime-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-lime-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-lime-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-lime-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-lime-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-lime:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-lime-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-lime-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-lime-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-lime-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-amber-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-amber-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-amber-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-amber-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-amber-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-amber-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-amber-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-amber-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-amber-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-amber-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-amber:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-amber-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-amber-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-amber-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-amber-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-brown-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-brown-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-brown-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-brown-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-brown-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-brown-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-brown-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-brown-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-brown-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-brown-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-brown:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-brown-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-brown-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-brown-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-brown-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-gray-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-gray-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-gray-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-blue-gray-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent
  }

  .xl\:focus\:ring-current:focus {
    --tw-ring-color: currentColor
  }

  .xl\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-grey-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-grey-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-grey-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-grey-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-grey-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-grey-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-grey-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-grey-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-grey-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-grey-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-grey-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-grey-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-grey-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-grey-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray-hover:focus {
    --tw-ring-color: rgba(0, 0, 0, 0.04)
  }

  .xl\:focus\:ring-gray-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-orange-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-orange-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-orange-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-orange-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-orange-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-orange-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-orange-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-orange-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-orange-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-orange-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-orange:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-orange-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-orange-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-orange-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-orange-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-orange-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-orange-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-orange-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-orange-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-orange-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-orange-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-orange-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-orange-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-orange-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-orange-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-orange:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-orange-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-orange-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-orange-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-orange-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-yellow:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-yellow-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-yellow-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-yellow-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-yellow-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-green-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-green-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-green-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-green-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-teal-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-teal-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-teal-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-teal-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-teal-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-teal-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-teal-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-teal-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-teal-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-teal-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-teal:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-teal-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-teal-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-teal-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-teal-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-blue-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-blue-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-blue-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-light-blue-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-indigo:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-indigo-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-indigo-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-indigo-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-indigo-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-purple:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-purple-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-purple-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-purple-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-purple-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-purple:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-purple-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-purple-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-purple-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-deep-purple-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-pink:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-pink-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-pink-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-pink-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-pink-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-lime-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-lime-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-lime-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-lime-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-lime-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-lime-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-lime-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-lime-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-lime-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-lime-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-lime:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-lime-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-lime-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-lime-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-lime-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-amber-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-amber-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-amber-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-amber-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-amber-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-amber-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-amber-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-amber-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-amber-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-amber-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-amber:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-amber-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-amber-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-amber-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-amber-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-brown-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-brown-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-brown-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-brown-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-brown-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-brown-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-brown-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-brown-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-brown-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-brown-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-brown:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-brown-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-brown-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-brown-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-brown-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-gray-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-gray-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-gray-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-blue-gray-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .xl\:ring-opacity-0 {
    --tw-ring-opacity: 0
  }

  .xl\:ring-opacity-5 {
    --tw-ring-opacity: 0.05
  }

  .xl\:ring-opacity-10 {
    --tw-ring-opacity: 0.1
  }

  .xl\:ring-opacity-20 {
    --tw-ring-opacity: 0.2
  }

  .xl\:ring-opacity-25 {
    --tw-ring-opacity: 0.25
  }

  .xl\:ring-opacity-30 {
    --tw-ring-opacity: 0.3
  }

  .xl\:ring-opacity-40 {
    --tw-ring-opacity: 0.4
  }

  .xl\:ring-opacity-50 {
    --tw-ring-opacity: 0.5
  }

  .xl\:ring-opacity-60 {
    --tw-ring-opacity: 0.6
  }

  .xl\:ring-opacity-70 {
    --tw-ring-opacity: 0.7
  }

  .xl\:ring-opacity-75 {
    --tw-ring-opacity: 0.75
  }

  .xl\:ring-opacity-80 {
    --tw-ring-opacity: 0.8
  }

  .xl\:ring-opacity-90 {
    --tw-ring-opacity: 0.9
  }

  .xl\:ring-opacity-95 {
    --tw-ring-opacity: 0.95
  }

  .xl\:ring-opacity-100 {
    --tw-ring-opacity: 1
  }

  .xl\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0
  }

  .xl\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05
  }

  .xl\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1
  }

  .xl\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2
  }

  .xl\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25
  }

  .xl\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3
  }

  .xl\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4
  }

  .xl\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5
  }

  .xl\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6
  }

  .xl\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7
  }

  .xl\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75
  }

  .xl\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8
  }

  .xl\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9
  }

  .xl\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95
  }

  .xl\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1
  }

  .xl\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0
  }

  .xl\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05
  }

  .xl\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1
  }

  .xl\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2
  }

  .xl\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25
  }

  .xl\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3
  }

  .xl\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4
  }

  .xl\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5
  }

  .xl\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6
  }

  .xl\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7
  }

  .xl\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75
  }

  .xl\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8
  }

  .xl\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9
  }

  .xl\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95
  }

  .xl\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1
  }

  .xl\:fill-current {
    fill: currentColor
  }

  .xl\:stroke-current {
    stroke: currentColor
  }

  .xl\:stroke-0 {
    stroke-width: 0
  }

  .xl\:stroke-1 {
    stroke-width: 1
  }

  .xl\:stroke-2 {
    stroke-width: 2
  }

  .xl\:table-auto {
    table-layout: auto
  }

  .xl\:table-fixed {
    table-layout: fixed
  }

  .xl\:text-left {
    text-align: left
  }

  .xl\:text-center {
    text-align: center
  }

  .xl\:text-right {
    text-align: right
  }

  .xl\:text-justify {
    text-align: justify
  }

  [dir='ltr'] .xl\:ltr\:text-left,[dir='ltr'].xl\:ltr\:text-left {
    text-align: left
  }

  [dir='ltr'] .xl\:ltr\:text-center,[dir='ltr'].xl\:ltr\:text-center {
    text-align: center
  }

  [dir='ltr'] .xl\:ltr\:text-right,[dir='ltr'].xl\:ltr\:text-right {
    text-align: right
  }

  [dir='ltr'] .xl\:ltr\:text-justify,[dir='ltr'].xl\:ltr\:text-justify {
    text-align: justify
  }

  [dir='rtl'] .xl\:rtl\:text-left,[dir='rtl'].xl\:rtl\:text-left {
    text-align: left
  }

  [dir='rtl'] .xl\:rtl\:text-center,[dir='rtl'].xl\:rtl\:text-center {
    text-align: center
  }

  [dir='rtl'] .xl\:rtl\:text-right,[dir='rtl'].xl\:rtl\:text-right {
    text-align: right
  }

  [dir='rtl'] .xl\:rtl\:text-justify,[dir='rtl'].xl\:rtl\:text-justify {
    text-align: justify
  }

  .xl\:text-transparent {
    color: transparent
  }

  .xl\:text-current {
    color: currentColor
  }

  .xl\:text-black {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .xl\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .xl\:text-grey-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .xl\:text-grey-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .xl\:text-grey-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .xl\:text-grey-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .xl\:text-grey-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .xl\:text-grey-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:text-grey-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .xl\:text-grey-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:text-grey-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .xl\:text-grey-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .xl\:text-grey {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:text-grey-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .xl\:text-grey-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .xl\:text-grey-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .xl\:text-grey-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .xl\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .xl\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .xl\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .xl\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .xl\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .xl\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .xl\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .xl\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:text-gray-hover {
    color: rgba(0, 0, 0, 0.04)
  }

  .xl\:text-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .xl\:text-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .xl\:text-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .xl\:text-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .xl\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .xl\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .xl\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .xl\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .xl\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .xl\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .xl\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .xl\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .xl\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .xl\:text-red {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .xl\:text-red-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .xl\:text-red-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .xl\:text-red-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .xl\:text-red-A700 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .xl\:text-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .xl\:text-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .xl\:text-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .xl\:text-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .xl\:text-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .xl\:text-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .xl\:text-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .xl\:text-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .xl\:text-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .xl\:text-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .xl\:text-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .xl\:text-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .xl\:text-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .xl\:text-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .xl\:text-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .xl\:text-deep-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .xl\:text-deep-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .xl\:text-deep-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .xl\:text-deep-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .xl\:text-deep-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .xl\:text-deep-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .xl\:text-deep-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .xl\:text-deep-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .xl\:text-deep-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .xl\:text-deep-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .xl\:text-deep-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .xl\:text-deep-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .xl\:text-deep-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .xl\:text-deep-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .xl\:text-deep-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .xl\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .xl\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .xl\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .xl\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .xl\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .xl\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .xl\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .xl\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .xl\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .xl\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .xl\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .xl\:text-yellow-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .xl\:text-yellow-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .xl\:text-yellow-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .xl\:text-yellow-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .xl\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .xl\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .xl\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .xl\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .xl\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .xl\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .xl\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .xl\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .xl\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .xl\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .xl\:text-green {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .xl\:text-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .xl\:text-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .xl\:text-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .xl\:text-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .xl\:text-light-green-50 {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .xl\:text-light-green-100 {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .xl\:text-light-green-200 {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .xl\:text-light-green-300 {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .xl\:text-light-green-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .xl\:text-light-green-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .xl\:text-light-green-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .xl\:text-light-green-700 {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .xl\:text-light-green-800 {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .xl\:text-light-green-900 {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .xl\:text-light-green {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .xl\:text-light-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .xl\:text-light-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .xl\:text-light-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .xl\:text-light-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .xl\:text-teal-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .xl\:text-teal-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .xl\:text-teal-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .xl\:text-teal-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .xl\:text-teal-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .xl\:text-teal-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .xl\:text-teal-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .xl\:text-teal-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .xl\:text-teal-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .xl\:text-teal-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .xl\:text-teal {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .xl\:text-teal-A100 {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .xl\:text-teal-A200 {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .xl\:text-teal-A400 {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .xl\:text-teal-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .xl\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .xl\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .xl\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .xl\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .xl\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .xl\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .xl\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .xl\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .xl\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .xl\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .xl\:text-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .xl\:text-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .xl\:text-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .xl\:text-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .xl\:text-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .xl\:text-light-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .xl\:text-light-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .xl\:text-light-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .xl\:text-light-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .xl\:text-light-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .xl\:text-light-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .xl\:text-light-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .xl\:text-light-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .xl\:text-light-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .xl\:text-light-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .xl\:text-light-blue {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .xl\:text-light-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .xl\:text-light-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .xl\:text-light-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .xl\:text-light-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .xl\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .xl\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .xl\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .xl\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .xl\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .xl\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .xl\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .xl\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .xl\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .xl\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .xl\:text-indigo {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .xl\:text-indigo-A100 {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .xl\:text-indigo-A200 {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .xl\:text-indigo-A400 {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .xl\:text-indigo-A700 {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .xl\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .xl\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .xl\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .xl\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .xl\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .xl\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .xl\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .xl\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .xl\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .xl\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .xl\:text-purple {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .xl\:text-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .xl\:text-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .xl\:text-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .xl\:text-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .xl\:text-deep-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .xl\:text-deep-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .xl\:text-deep-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .xl\:text-deep-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .xl\:text-deep-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .xl\:text-deep-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .xl\:text-deep-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .xl\:text-deep-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .xl\:text-deep-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .xl\:text-deep-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .xl\:text-deep-purple {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .xl\:text-deep-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .xl\:text-deep-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .xl\:text-deep-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .xl\:text-deep-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .xl\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .xl\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .xl\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .xl\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .xl\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .xl\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .xl\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .xl\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .xl\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .xl\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .xl\:text-pink {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .xl\:text-pink-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .xl\:text-pink-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .xl\:text-pink-A400 {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .xl\:text-pink-A700 {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .xl\:text-lime-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .xl\:text-lime-100 {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .xl\:text-lime-200 {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .xl\:text-lime-300 {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .xl\:text-lime-400 {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .xl\:text-lime-500 {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .xl\:text-lime-600 {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .xl\:text-lime-700 {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .xl\:text-lime-800 {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .xl\:text-lime-900 {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .xl\:text-lime {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .xl\:text-lime-A100 {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .xl\:text-lime-A200 {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .xl\:text-lime-A400 {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .xl\:text-lime-A700 {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .xl\:text-amber-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .xl\:text-amber-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .xl\:text-amber-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .xl\:text-amber-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .xl\:text-amber-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .xl\:text-amber-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .xl\:text-amber-600 {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .xl\:text-amber-700 {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .xl\:text-amber-800 {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .xl\:text-amber-900 {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .xl\:text-amber {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .xl\:text-amber-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .xl\:text-amber-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .xl\:text-amber-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .xl\:text-amber-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .xl\:text-brown-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .xl\:text-brown-100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .xl\:text-brown-200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .xl\:text-brown-300 {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .xl\:text-brown-400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .xl\:text-brown-500 {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .xl\:text-brown-600 {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .xl\:text-brown-700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .xl\:text-brown-800 {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .xl\:text-brown-900 {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .xl\:text-brown {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .xl\:text-brown-A100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .xl\:text-brown-A200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .xl\:text-brown-A400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .xl\:text-brown-A700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .xl\:text-blue-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .xl\:text-blue-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .xl\:text-blue-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .xl\:text-blue-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .xl\:text-blue-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .xl\:text-blue-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .xl\:text-blue-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .xl\:text-blue-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .xl\:text-blue-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .xl\:text-blue-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .xl\:text-blue-gray {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .xl\:text-blue-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .xl\:text-blue-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .xl\:text-blue-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .xl\:text-blue-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .xl\:text-cyan-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .xl\:text-cyan-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .xl\:text-cyan-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .xl\:text-cyan-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .xl\:text-cyan-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .xl\:text-cyan-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .xl\:text-cyan-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .xl\:text-cyan-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .xl\:text-cyan-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .xl\:text-cyan-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .xl\:text-cyan {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .xl\:text-cyan-A100 {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .xl\:text-cyan-A200 {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .xl\:text-cyan-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .xl\:text-cyan-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-transparent {
    color: transparent
  }

  .group:hover .xl\:group-hover\:text-current {
    color: currentColor
  }

  .group:hover .xl\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-grey-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-grey-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-grey-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-grey-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-grey-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-grey-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-grey-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-grey-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-grey-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-grey-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-grey {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-grey-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-grey-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-grey-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-grey-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-gray-hover {
    color: rgba(0, 0, 0, 0.04)
  }

  .group:hover .xl\:group-hover\:text-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-red {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-red-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-red-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-red-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-red-A700 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-yellow-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-yellow-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-yellow-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-yellow-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-green {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-green-50 {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-green-100 {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-green-200 {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-green-300 {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-green-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-green-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-green-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-green-700 {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-green-800 {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-green-900 {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-green {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-teal-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-teal-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-teal-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-teal-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-teal-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-teal-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-teal-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-teal-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-teal-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-teal-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-teal {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-teal-A100 {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-teal-A200 {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-teal-A400 {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-teal-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-blue {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-light-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-indigo {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-indigo-A100 {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-indigo-A200 {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-indigo-A400 {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-indigo-A700 {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-purple {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-purple {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-deep-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-pink {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-pink-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-pink-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-pink-A400 {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-pink-A700 {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-lime-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-lime-100 {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-lime-200 {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-lime-300 {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-lime-400 {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-lime-500 {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-lime-600 {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-lime-700 {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-lime-800 {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-lime-900 {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-lime {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-lime-A100 {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-lime-A200 {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-lime-A400 {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-lime-A700 {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-amber-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-amber-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-amber-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-amber-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-amber-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-amber-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-amber-600 {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-amber-700 {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-amber-800 {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-amber-900 {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-amber {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-amber-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-amber-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-amber-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-amber-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-brown-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-brown-100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-brown-200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-brown-300 {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-brown-400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-brown-500 {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-brown-600 {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-brown-700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-brown-800 {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-brown-900 {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-brown {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-brown-A100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-brown-A200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-brown-A400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-brown-A700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-gray {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-blue-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-cyan-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-cyan-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-cyan-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-cyan-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-cyan-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-cyan-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-cyan-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-cyan-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-cyan-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-cyan-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-cyan {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-cyan-A100 {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-cyan-A200 {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-cyan-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .group:hover .xl\:group-hover\:text-cyan-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-transparent:focus-within {
    color: transparent
  }

  .xl\:focus-within\:text-current:focus-within {
    color: currentColor
  }

  .xl\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-grey-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-grey-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-grey-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-grey-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-grey-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-grey-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-grey-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-grey-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-grey-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-grey-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-grey-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-grey-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-grey-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-grey-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-gray-hover:focus-within {
    color: rgba(0, 0, 0, 0.04)
  }

  .xl\:focus-within\:text-gray-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-gray-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-gray-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-gray-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-red:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-red-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-red-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-red-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-red-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-orange-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-orange-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-orange-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-orange-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-orange-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-orange-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-orange-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-orange-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-orange-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-orange-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-orange:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-orange-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-orange-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-orange-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-orange-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-orange-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-orange-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-orange-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-orange-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-orange-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-orange-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-orange-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-orange-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-orange-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-orange-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-orange:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-orange-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-orange-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-orange-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-orange-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-yellow:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-yellow-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-yellow-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-yellow-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-yellow-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-green-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-green-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-green-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-green-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-green-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-green-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-green-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-green-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-teal-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-teal-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-teal-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-teal-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-teal-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-teal-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-teal-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-teal-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-teal-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-teal-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-teal:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-teal-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-teal-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-teal-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-teal-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-blue-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-blue-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-blue-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-light-blue-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-indigo:focus-within {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-indigo-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-indigo-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-indigo-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-indigo-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-purple:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-purple-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-purple-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-purple-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-purple-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-purple:focus-within {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-purple-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-purple-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-purple-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-deep-purple-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-pink:focus-within {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-pink-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-pink-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-pink-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-pink-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-lime-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-lime-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-lime-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-lime-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-lime-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-lime-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-lime-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-lime-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-lime-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-lime-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-lime:focus-within {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-lime-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-lime-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-lime-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-lime-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-amber-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-amber-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-amber-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-amber-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-amber-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-amber-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-amber-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-amber-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-amber-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-amber-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-amber:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-amber-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-amber-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-amber-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-amber-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-brown-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-brown-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-brown-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-brown-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-brown-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-brown-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-brown-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-brown-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-brown-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-brown-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-brown:focus-within {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-brown-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-brown-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-brown-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-brown-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-gray-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-gray-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-gray-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-blue-gray-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-cyan-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-cyan-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-cyan-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-cyan-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-cyan-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-cyan-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-cyan-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-cyan-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-cyan-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-cyan-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-cyan:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-cyan-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-cyan-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-cyan-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .xl\:focus-within\:text-cyan-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .xl\:hover\:text-transparent:hover {
    color: transparent
  }

  .xl\:hover\:text-current:hover {
    color: currentColor
  }

  .xl\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .xl\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-grey-50:hover {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .xl\:hover\:text-grey-100:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .xl\:hover\:text-grey-200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .xl\:hover\:text-grey-300:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .xl\:hover\:text-grey-400:hover {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .xl\:hover\:text-grey-500:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:hover\:text-grey-600:hover {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .xl\:hover\:text-grey-700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:hover\:text-grey-800:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .xl\:hover\:text-grey-900:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .xl\:hover\:text-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:hover\:text-grey-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .xl\:hover\:text-grey-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .xl\:hover\:text-grey-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .xl\:hover\:text-grey-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .xl\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .xl\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .xl\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .xl\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .xl\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .xl\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .xl\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .xl\:hover\:text-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:hover\:text-gray-hover:hover {
    color: rgba(0, 0, 0, 0.04)
  }

  .xl\:hover\:text-gray-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .xl\:hover\:text-gray-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .xl\:hover\:text-gray-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .xl\:hover\:text-gray-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .xl\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .xl\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .xl\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .xl\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .xl\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .xl\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .xl\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .xl\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .xl\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .xl\:hover\:text-red:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .xl\:hover\:text-red-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .xl\:hover\:text-red-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .xl\:hover\:text-red-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .xl\:hover\:text-red-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-orange-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .xl\:hover\:text-orange-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .xl\:hover\:text-orange-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .xl\:hover\:text-orange-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .xl\:hover\:text-orange-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .xl\:hover\:text-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-orange-600:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-orange-700:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-orange-800:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-orange-900:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-orange:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-orange-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .xl\:hover\:text-orange-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .xl\:hover\:text-orange-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-orange-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-orange-50:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-orange-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-orange-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-orange-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-orange-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-orange-600:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-orange-700:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-orange-800:hover {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-orange-900:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-orange:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-orange-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-orange-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-orange-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-orange-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .xl\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .xl\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .xl\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .xl\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .xl\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .xl\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .xl\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .xl\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .xl\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .xl\:hover\:text-yellow:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .xl\:hover\:text-yellow-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .xl\:hover\:text-yellow-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-yellow-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-yellow-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .xl\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .xl\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .xl\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .xl\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .xl\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .xl\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .xl\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .xl\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .xl\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .xl\:hover\:text-green:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .xl\:hover\:text-green-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .xl\:hover\:text-green-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .xl\:hover\:text-green-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .xl\:hover\:text-green-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-green:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-green-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-green-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-green-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-green-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .xl\:hover\:text-teal-50:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .xl\:hover\:text-teal-100:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .xl\:hover\:text-teal-200:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .xl\:hover\:text-teal-300:hover {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .xl\:hover\:text-teal-400:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .xl\:hover\:text-teal-500:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .xl\:hover\:text-teal-600:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .xl\:hover\:text-teal-700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .xl\:hover\:text-teal-800:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .xl\:hover\:text-teal-900:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .xl\:hover\:text-teal:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .xl\:hover\:text-teal-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .xl\:hover\:text-teal-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .xl\:hover\:text-teal-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .xl\:hover\:text-teal-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-blue-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-blue-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-blue-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-light-blue-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .xl\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .xl\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .xl\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .xl\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .xl\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .xl\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .xl\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .xl\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .xl\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .xl\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .xl\:hover\:text-indigo:hover {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .xl\:hover\:text-indigo-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-indigo-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .xl\:hover\:text-indigo-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .xl\:hover\:text-indigo-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .xl\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .xl\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .xl\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .xl\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .xl\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .xl\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .xl\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .xl\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .xl\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .xl\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .xl\:hover\:text-purple:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .xl\:hover\:text-purple-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .xl\:hover\:text-purple-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .xl\:hover\:text-purple-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .xl\:hover\:text-purple-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-purple:hover {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-purple-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-purple-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-purple-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-deep-purple-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .xl\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .xl\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .xl\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .xl\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .xl\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .xl\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .xl\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .xl\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .xl\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .xl\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .xl\:hover\:text-pink:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .xl\:hover\:text-pink-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .xl\:hover\:text-pink-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .xl\:hover\:text-pink-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .xl\:hover\:text-pink-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .xl\:hover\:text-lime-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .xl\:hover\:text-lime-100:hover {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .xl\:hover\:text-lime-200:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .xl\:hover\:text-lime-300:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .xl\:hover\:text-lime-400:hover {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .xl\:hover\:text-lime-500:hover {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .xl\:hover\:text-lime-600:hover {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .xl\:hover\:text-lime-700:hover {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .xl\:hover\:text-lime-800:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .xl\:hover\:text-lime-900:hover {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .xl\:hover\:text-lime:hover {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .xl\:hover\:text-lime-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .xl\:hover\:text-lime-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .xl\:hover\:text-lime-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-lime-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-amber-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .xl\:hover\:text-amber-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .xl\:hover\:text-amber-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .xl\:hover\:text-amber-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .xl\:hover\:text-amber-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .xl\:hover\:text-amber-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .xl\:hover\:text-amber-600:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-amber-700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-amber-800:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-amber-900:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-amber:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .xl\:hover\:text-amber-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .xl\:hover\:text-amber-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .xl\:hover\:text-amber-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-amber-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .xl\:hover\:text-brown-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .xl\:hover\:text-brown-100:hover {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .xl\:hover\:text-brown-200:hover {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .xl\:hover\:text-brown-300:hover {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .xl\:hover\:text-brown-400:hover {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .xl\:hover\:text-brown-500:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .xl\:hover\:text-brown-600:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .xl\:hover\:text-brown-700:hover {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .xl\:hover\:text-brown-800:hover {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .xl\:hover\:text-brown-900:hover {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .xl\:hover\:text-brown:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .xl\:hover\:text-brown-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .xl\:hover\:text-brown-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .xl\:hover\:text-brown-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .xl\:hover\:text-brown-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-gray-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-gray-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-gray-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .xl\:hover\:text-blue-gray-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .xl\:hover\:text-cyan-50:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .xl\:hover\:text-cyan-100:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .xl\:hover\:text-cyan-200:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .xl\:hover\:text-cyan-300:hover {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .xl\:hover\:text-cyan-400:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .xl\:hover\:text-cyan-500:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .xl\:hover\:text-cyan-600:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .xl\:hover\:text-cyan-700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .xl\:hover\:text-cyan-800:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .xl\:hover\:text-cyan-900:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .xl\:hover\:text-cyan:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .xl\:hover\:text-cyan-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-cyan-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-cyan-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .xl\:hover\:text-cyan-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .xl\:focus\:text-transparent:focus {
    color: transparent
  }

  .xl\:focus\:text-current:focus {
    color: currentColor
  }

  .xl\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .xl\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-grey-50:focus {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .xl\:focus\:text-grey-100:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .xl\:focus\:text-grey-200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .xl\:focus\:text-grey-300:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .xl\:focus\:text-grey-400:focus {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .xl\:focus\:text-grey-500:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:focus\:text-grey-600:focus {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .xl\:focus\:text-grey-700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:focus\:text-grey-800:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .xl\:focus\:text-grey-900:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .xl\:focus\:text-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:focus\:text-grey-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .xl\:focus\:text-grey-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .xl\:focus\:text-grey-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .xl\:focus\:text-grey-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .xl\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .xl\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .xl\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .xl\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .xl\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .xl\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .xl\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .xl\:focus\:text-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .xl\:focus\:text-gray-hover:focus {
    color: rgba(0, 0, 0, 0.04)
  }

  .xl\:focus\:text-gray-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .xl\:focus\:text-gray-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .xl\:focus\:text-gray-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .xl\:focus\:text-gray-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .xl\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .xl\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .xl\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .xl\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .xl\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .xl\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .xl\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .xl\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .xl\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .xl\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .xl\:focus\:text-red:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .xl\:focus\:text-red-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .xl\:focus\:text-red-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .xl\:focus\:text-red-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .xl\:focus\:text-red-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-orange-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .xl\:focus\:text-orange-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .xl\:focus\:text-orange-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .xl\:focus\:text-orange-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .xl\:focus\:text-orange-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .xl\:focus\:text-orange-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-orange-600:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-orange-700:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-orange-800:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-orange-900:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-orange:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-orange-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .xl\:focus\:text-orange-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .xl\:focus\:text-orange-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-orange-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-orange-50:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-orange-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-orange-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-orange-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-orange-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-orange-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-orange-600:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-orange-700:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-orange-800:focus {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-orange-900:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-orange:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-orange-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-orange-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-orange-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-orange-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .xl\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .xl\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .xl\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .xl\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .xl\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .xl\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .xl\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .xl\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .xl\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .xl\:focus\:text-yellow:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .xl\:focus\:text-yellow-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .xl\:focus\:text-yellow-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-yellow-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-yellow-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .xl\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .xl\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .xl\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .xl\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .xl\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .xl\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .xl\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .xl\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .xl\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .xl\:focus\:text-green:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .xl\:focus\:text-green-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .xl\:focus\:text-green-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .xl\:focus\:text-green-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .xl\:focus\:text-green-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-green:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-green-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-green-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-green-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-green-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .xl\:focus\:text-teal-50:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .xl\:focus\:text-teal-100:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .xl\:focus\:text-teal-200:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .xl\:focus\:text-teal-300:focus {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .xl\:focus\:text-teal-400:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .xl\:focus\:text-teal-500:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .xl\:focus\:text-teal-600:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .xl\:focus\:text-teal-700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .xl\:focus\:text-teal-800:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .xl\:focus\:text-teal-900:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .xl\:focus\:text-teal:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .xl\:focus\:text-teal-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .xl\:focus\:text-teal-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .xl\:focus\:text-teal-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .xl\:focus\:text-teal-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-blue-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-blue-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-blue-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-light-blue-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .xl\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .xl\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .xl\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .xl\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .xl\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .xl\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .xl\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .xl\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .xl\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .xl\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .xl\:focus\:text-indigo:focus {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .xl\:focus\:text-indigo-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-indigo-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .xl\:focus\:text-indigo-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .xl\:focus\:text-indigo-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .xl\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .xl\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .xl\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .xl\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .xl\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .xl\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .xl\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .xl\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .xl\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .xl\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .xl\:focus\:text-purple:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .xl\:focus\:text-purple-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .xl\:focus\:text-purple-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .xl\:focus\:text-purple-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .xl\:focus\:text-purple-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-purple:focus {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-purple-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-purple-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-purple-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-deep-purple-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .xl\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .xl\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .xl\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .xl\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .xl\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .xl\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .xl\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .xl\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .xl\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .xl\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .xl\:focus\:text-pink:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .xl\:focus\:text-pink-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .xl\:focus\:text-pink-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .xl\:focus\:text-pink-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .xl\:focus\:text-pink-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .xl\:focus\:text-lime-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .xl\:focus\:text-lime-100:focus {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .xl\:focus\:text-lime-200:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .xl\:focus\:text-lime-300:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .xl\:focus\:text-lime-400:focus {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .xl\:focus\:text-lime-500:focus {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .xl\:focus\:text-lime-600:focus {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .xl\:focus\:text-lime-700:focus {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .xl\:focus\:text-lime-800:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .xl\:focus\:text-lime-900:focus {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .xl\:focus\:text-lime:focus {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .xl\:focus\:text-lime-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .xl\:focus\:text-lime-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .xl\:focus\:text-lime-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-lime-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-amber-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .xl\:focus\:text-amber-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .xl\:focus\:text-amber-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .xl\:focus\:text-amber-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .xl\:focus\:text-amber-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .xl\:focus\:text-amber-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .xl\:focus\:text-amber-600:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-amber-700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-amber-800:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-amber-900:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-amber:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .xl\:focus\:text-amber-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .xl\:focus\:text-amber-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .xl\:focus\:text-amber-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-amber-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .xl\:focus\:text-brown-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .xl\:focus\:text-brown-100:focus {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .xl\:focus\:text-brown-200:focus {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .xl\:focus\:text-brown-300:focus {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .xl\:focus\:text-brown-400:focus {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .xl\:focus\:text-brown-500:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .xl\:focus\:text-brown-600:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .xl\:focus\:text-brown-700:focus {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .xl\:focus\:text-brown-800:focus {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .xl\:focus\:text-brown-900:focus {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .xl\:focus\:text-brown:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .xl\:focus\:text-brown-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .xl\:focus\:text-brown-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .xl\:focus\:text-brown-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .xl\:focus\:text-brown-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-gray-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-gray-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-gray-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .xl\:focus\:text-blue-gray-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .xl\:focus\:text-cyan-50:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .xl\:focus\:text-cyan-100:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .xl\:focus\:text-cyan-200:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .xl\:focus\:text-cyan-300:focus {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .xl\:focus\:text-cyan-400:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .xl\:focus\:text-cyan-500:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .xl\:focus\:text-cyan-600:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .xl\:focus\:text-cyan-700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .xl\:focus\:text-cyan-800:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .xl\:focus\:text-cyan-900:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .xl\:focus\:text-cyan:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .xl\:focus\:text-cyan-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-cyan-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-cyan-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .xl\:focus\:text-cyan-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .xl\:text-opacity-0 {
    --tw-text-opacity: 0
  }

  .xl\:text-opacity-5 {
    --tw-text-opacity: 0.05
  }

  .xl\:text-opacity-10 {
    --tw-text-opacity: 0.1
  }

  .xl\:text-opacity-20 {
    --tw-text-opacity: 0.2
  }

  .xl\:text-opacity-25 {
    --tw-text-opacity: 0.25
  }

  .xl\:text-opacity-30 {
    --tw-text-opacity: 0.3
  }

  .xl\:text-opacity-40 {
    --tw-text-opacity: 0.4
  }

  .xl\:text-opacity-50 {
    --tw-text-opacity: 0.5
  }

  .xl\:text-opacity-60 {
    --tw-text-opacity: 0.6
  }

  .xl\:text-opacity-70 {
    --tw-text-opacity: 0.7
  }

  .xl\:text-opacity-75 {
    --tw-text-opacity: 0.75
  }

  .xl\:text-opacity-80 {
    --tw-text-opacity: 0.8
  }

  .xl\:text-opacity-90 {
    --tw-text-opacity: 0.9
  }

  .xl\:text-opacity-95 {
    --tw-text-opacity: 0.95
  }

  .xl\:text-opacity-100 {
    --tw-text-opacity: 1
  }

  .group:hover .xl\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0
  }

  .group:hover .xl\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05
  }

  .group:hover .xl\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1
  }

  .group:hover .xl\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2
  }

  .group:hover .xl\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25
  }

  .group:hover .xl\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3
  }

  .group:hover .xl\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4
  }

  .group:hover .xl\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5
  }

  .group:hover .xl\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6
  }

  .group:hover .xl\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7
  }

  .group:hover .xl\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75
  }

  .group:hover .xl\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8
  }

  .group:hover .xl\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9
  }

  .group:hover .xl\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95
  }

  .group:hover .xl\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1
  }

  .xl\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0
  }

  .xl\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05
  }

  .xl\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1
  }

  .xl\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2
  }

  .xl\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25
  }

  .xl\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3
  }

  .xl\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4
  }

  .xl\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5
  }

  .xl\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6
  }

  .xl\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7
  }

  .xl\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75
  }

  .xl\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8
  }

  .xl\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9
  }

  .xl\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95
  }

  .xl\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1
  }

  .xl\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0
  }

  .xl\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05
  }

  .xl\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1
  }

  .xl\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2
  }

  .xl\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25
  }

  .xl\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3
  }

  .xl\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4
  }

  .xl\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5
  }

  .xl\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6
  }

  .xl\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7
  }

  .xl\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75
  }

  .xl\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8
  }

  .xl\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9
  }

  .xl\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95
  }

  .xl\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1
  }

  .xl\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0
  }

  .xl\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05
  }

  .xl\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1
  }

  .xl\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2
  }

  .xl\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25
  }

  .xl\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3
  }

  .xl\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4
  }

  .xl\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5
  }

  .xl\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6
  }

  .xl\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7
  }

  .xl\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75
  }

  .xl\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8
  }

  .xl\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9
  }

  .xl\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95
  }

  .xl\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1
  }

  .xl\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .xl\:overflow-ellipsis {
    text-overflow: ellipsis
  }

  .xl\:overflow-clip {
    text-overflow: clip
  }

  .xl\:italic {
    font-style: italic
  }

  .xl\:not-italic {
    font-style: normal
  }

  .xl\:hover\:italic:hover {
    font-style: italic
  }

  .xl\:hover\:not-italic:hover {
    font-style: normal
  }

  .xl\:focus\:italic:focus {
    font-style: italic
  }

  .xl\:focus\:not-italic:focus {
    font-style: normal
  }

  .xl\:uppercase {
    text-transform: uppercase
  }

  .xl\:lowercase {
    text-transform: lowercase
  }

  .xl\:capitalize {
    text-transform: capitalize
  }

  .xl\:normal-case {
    text-transform: none
  }

  .xl\:underline {
    text-decoration: underline
  }

  .xl\:line-through {
    text-decoration: line-through
  }

  .xl\:no-underline {
    text-decoration: none
  }

  .group:hover .xl\:group-hover\:underline {
    text-decoration: underline
  }

  .group:hover .xl\:group-hover\:line-through {
    text-decoration: line-through
  }

  .group:hover .xl\:group-hover\:no-underline {
    text-decoration: none
  }

  .xl\:focus-within\:underline:focus-within {
    text-decoration: underline
  }

  .xl\:focus-within\:line-through:focus-within {
    text-decoration: line-through
  }

  .xl\:focus-within\:no-underline:focus-within {
    text-decoration: none
  }

  .xl\:hover\:underline:hover {
    text-decoration: underline
  }

  .xl\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .xl\:hover\:no-underline:hover {
    text-decoration: none
  }

  .xl\:focus\:underline:focus {
    text-decoration: underline
  }

  .xl\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .xl\:focus\:no-underline:focus {
    text-decoration: none
  }

  .xl\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .xl\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .xl\:ordinal, .xl\:slashed-zero, .xl\:lining-nums, .xl\:oldstyle-nums, .xl\:proportional-nums, .xl\:tabular-nums, .xl\:diagonal-fractions, .xl\:stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  .xl\:normal-nums {
    font-variant-numeric: normal
  }

  .xl\:ordinal {
    --tw-ordinal: ordinal
  }

  .xl\:slashed-zero {
    --tw-slashed-zero: slashed-zero
  }

  .xl\:lining-nums {
    --tw-numeric-figure: lining-nums
  }

  .xl\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums
  }

  .xl\:proportional-nums {
    --tw-numeric-spacing: proportional-nums
  }

  .xl\:tabular-nums {
    --tw-numeric-spacing: tabular-nums
  }

  .xl\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions
  }

  .xl\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions
  }

  .xl\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .xl\:tracking-tight {
    letter-spacing: -0.025em
  }

  .xl\:tracking-normal {
    letter-spacing: 0em
  }

  .xl\:tracking-wide {
    letter-spacing: 0.025em
  }

  .xl\:tracking-wider {
    letter-spacing: 0.05em
  }

  .xl\:tracking-widest {
    letter-spacing: 0.1em
  }

  .xl\:select-none {
    -webkit-user-select: none;
            user-select: none
  }

  .xl\:select-text {
    -webkit-user-select: text;
            user-select: text
  }

  .xl\:select-all {
    -webkit-user-select: all;
            user-select: all
  }

  .xl\:select-auto {
    -webkit-user-select: auto;
            user-select: auto
  }

  .xl\:align-baseline {
    vertical-align: baseline
  }

  .xl\:align-top {
    vertical-align: top
  }

  .xl\:align-middle {
    vertical-align: middle
  }

  .xl\:align-bottom {
    vertical-align: bottom
  }

  .xl\:align-text-top {
    vertical-align: text-top
  }

  .xl\:align-text-bottom {
    vertical-align: text-bottom
  }

  .xl\:visible {
    visibility: visible
  }

  .xl\:invisible {
    visibility: hidden
  }

  .xl\:whitespace-normal {
    white-space: normal
  }

  .xl\:whitespace-nowrap {
    white-space: nowrap
  }

  .xl\:whitespace-pre {
    white-space: pre
  }

  .xl\:whitespace-pre-line {
    white-space: pre-line
  }

  .xl\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .xl\:break-normal {
    overflow-wrap: normal;
    word-break: normal
  }

  .xl\:break-words {
    overflow-wrap: break-word
  }

  .xl\:break-all {
    word-break: break-all
  }

  .xl\:w-0 {
    width: 0
  }

  .xl\:w-1 {
    width: 0.1rem
  }

  .xl\:w-2 {
    width: 0.2rem
  }

  .xl\:w-3 {
    width: 0.3rem
  }

  .xl\:w-4 {
    width: 0.4rem
  }

  .xl\:w-5 {
    width: 0.5rem
  }

  .xl\:w-6 {
    width: 0.6rem
  }

  .xl\:w-7 {
    width: 0.7rem
  }

  .xl\:w-8 {
    width: 0.8rem
  }

  .xl\:w-9 {
    width: 0.9rem
  }

  .xl\:w-10 {
    width: 1.0rem
  }

  .xl\:w-12 {
    width: 1.2rem
  }

  .xl\:w-14 {
    width: 1.4rem
  }

  .xl\:w-16 {
    width: 1.6rem
  }

  .xl\:w-20 {
    width: 2rem
  }

  .xl\:w-24 {
    width: 2.4rem
  }

  .xl\:w-28 {
    width: 2.8rem
  }

  .xl\:w-32 {
    width: 3.2rem
  }

  .xl\:w-36 {
    width: 3.6rem
  }

  .xl\:w-40 {
    width: 4rem
  }

  .xl\:w-44 {
    width: 4.4rem
  }

  .xl\:w-48 {
    width: 4.8rem
  }

  .xl\:w-52 {
    width: 5.2rem
  }

  .xl\:w-56 {
    width: 5.6rem
  }

  .xl\:w-60 {
    width: 6rem
  }

  .xl\:w-64 {
    width: 6.4rem
  }

  .xl\:w-68 {
    width: 6.8rem
  }

  .xl\:w-72 {
    width: 7.2rem
  }

  .xl\:w-76 {
    width: 7.6rem
  }

  .xl\:w-80 {
    width: 8rem
  }

  .xl\:w-84 {
    width: 8.4rem
  }

  .xl\:w-88 {
    width: 8.8rem
  }

  .xl\:w-92 {
    width: 9.2rem
  }

  .xl\:w-96 {
    width: 9.6rem
  }

  .xl\:w-128 {
    width: 12.8rem
  }

  .xl\:w-136 {
    width: 13.6rem
  }

  .xl\:w-160 {
    width: 16rem
  }

  .xl\:w-192 {
    width: 19.2rem
  }

  .xl\:w-200 {
    width: 20rem
  }

  .xl\:w-208 {
    width: 20.8rem
  }

  .xl\:w-216 {
    width: 21.6rem
  }

  .xl\:w-224 {
    width: 22.4rem
  }

  .xl\:w-256 {
    width: 25.6rem
  }

  .xl\:w-288 {
    width: 28.8rem
  }

  .xl\:w-320 {
    width: 32rem
  }

  .xl\:w-360 {
    width: 36rem
  }

  .xl\:w-384 {
    width: 38.4rem
  }

  .xl\:w-400 {
    width: 40rem
  }

  .xl\:w-512 {
    width: 51.2rem
  }

  .xl\:w-640 {
    width: 64rem
  }

  .xl\:w-auto {
    width: auto
  }

  .xl\:w-xs {
    width: 32rem
  }

  .xl\:w-sm {
    width: 48rem
  }

  .xl\:w-md {
    width: 64rem
  }

  .xl\:w-lg {
    width: 80rem
  }

  .xl\:w-xl {
    width: 96rem
  }

  .xl\:w-2xl {
    width: 112rem
  }

  .xl\:w-3xl {
    width: 128rem
  }

  .xl\:w-4xl {
    width: 144rem
  }

  .xl\:w-5xl {
    width: 160rem
  }

  .xl\:w-px {
    width: 1px
  }

  .xl\:w-0\.5 {
    width: 0.05rem
  }

  .xl\:w-1\.5 {
    width: 0.15rem
  }

  .xl\:w-2\.5 {
    width: 0.25rem
  }

  .xl\:w-3\.5 {
    width: 0.35rem
  }

  .xl\:w-1\/2 {
    width: 50%
  }

  .xl\:w-1\/3 {
    width: 33.333333%
  }

  .xl\:w-2\/3 {
    width: 66.666667%
  }

  .xl\:w-1\/4 {
    width: 25%
  }

  .xl\:w-2\/4 {
    width: 50%
  }

  .xl\:w-3\/4 {
    width: 75%
  }

  .xl\:w-1\/5 {
    width: 20%
  }

  .xl\:w-2\/5 {
    width: 40%
  }

  .xl\:w-3\/5 {
    width: 60%
  }

  .xl\:w-4\/5 {
    width: 80%
  }

  .xl\:w-1\/6 {
    width: 16.666667%
  }

  .xl\:w-2\/6 {
    width: 33.333333%
  }

  .xl\:w-3\/6 {
    width: 50%
  }

  .xl\:w-4\/6 {
    width: 66.666667%
  }

  .xl\:w-5\/6 {
    width: 83.333333%
  }

  .xl\:w-1\/12 {
    width: 8.333333%
  }

  .xl\:w-2\/12 {
    width: 16.666667%
  }

  .xl\:w-3\/12 {
    width: 25%
  }

  .xl\:w-4\/12 {
    width: 33.333333%
  }

  .xl\:w-5\/12 {
    width: 41.666667%
  }

  .xl\:w-6\/12 {
    width: 50%
  }

  .xl\:w-7\/12 {
    width: 58.333333%
  }

  .xl\:w-8\/12 {
    width: 66.666667%
  }

  .xl\:w-9\/12 {
    width: 75%
  }

  .xl\:w-10\/12 {
    width: 83.333333%
  }

  .xl\:w-11\/12 {
    width: 91.666667%
  }

  .xl\:w-full {
    width: 100%
  }

  .xl\:w-screen {
    width: 100vw
  }

  .xl\:w-min {
    width: min-content
  }

  .xl\:w-max {
    width: max-content
  }

  .xl\:z-0 {
    z-index: 0
  }

  .xl\:z-10 {
    z-index: 10
  }

  .xl\:z-20 {
    z-index: 20
  }

  .xl\:z-30 {
    z-index: 30
  }

  .xl\:z-40 {
    z-index: 40
  }

  .xl\:z-50 {
    z-index: 50
  }

  .xl\:z-99 {
    z-index: 99
  }

  .xl\:z-999 {
    z-index: 999
  }

  .xl\:z-9999 {
    z-index: 9999
  }

  .xl\:z-auto {
    z-index: auto
  }

  .xl\:focus-within\:z-0:focus-within {
    z-index: 0
  }

  .xl\:focus-within\:z-10:focus-within {
    z-index: 10
  }

  .xl\:focus-within\:z-20:focus-within {
    z-index: 20
  }

  .xl\:focus-within\:z-30:focus-within {
    z-index: 30
  }

  .xl\:focus-within\:z-40:focus-within {
    z-index: 40
  }

  .xl\:focus-within\:z-50:focus-within {
    z-index: 50
  }

  .xl\:focus-within\:z-99:focus-within {
    z-index: 99
  }

  .xl\:focus-within\:z-999:focus-within {
    z-index: 999
  }

  .xl\:focus-within\:z-9999:focus-within {
    z-index: 9999
  }

  .xl\:focus-within\:z-auto:focus-within {
    z-index: auto
  }

  .xl\:focus\:z-0:focus {
    z-index: 0
  }

  .xl\:focus\:z-10:focus {
    z-index: 10
  }

  .xl\:focus\:z-20:focus {
    z-index: 20
  }

  .xl\:focus\:z-30:focus {
    z-index: 30
  }

  .xl\:focus\:z-40:focus {
    z-index: 40
  }

  .xl\:focus\:z-50:focus {
    z-index: 50
  }

  .xl\:focus\:z-99:focus {
    z-index: 99
  }

  .xl\:focus\:z-999:focus {
    z-index: 999
  }

  .xl\:focus\:z-9999:focus {
    z-index: 9999
  }

  .xl\:focus\:z-auto:focus {
    z-index: auto
  }

  .xl\:gap-0 {
    gap: 0
  }

  .xl\:gap-1 {
    gap: 0.1rem
  }

  .xl\:gap-2 {
    gap: 0.2rem
  }

  .xl\:gap-3 {
    gap: 0.3rem
  }

  .xl\:gap-4 {
    gap: 0.4rem
  }

  .xl\:gap-5 {
    gap: 0.5rem
  }

  .xl\:gap-6 {
    gap: 0.6rem
  }

  .xl\:gap-7 {
    gap: 0.7rem
  }

  .xl\:gap-8 {
    gap: 0.8rem
  }

  .xl\:gap-9 {
    gap: 0.9rem
  }

  .xl\:gap-10 {
    gap: 1.0rem
  }

  .xl\:gap-12 {
    gap: 1.2rem
  }

  .xl\:gap-14 {
    gap: 1.4rem
  }

  .xl\:gap-16 {
    gap: 1.6rem
  }

  .xl\:gap-20 {
    gap: 2rem
  }

  .xl\:gap-24 {
    gap: 2.4rem
  }

  .xl\:gap-28 {
    gap: 2.8rem
  }

  .xl\:gap-32 {
    gap: 3.2rem
  }

  .xl\:gap-36 {
    gap: 3.6rem
  }

  .xl\:gap-40 {
    gap: 4rem
  }

  .xl\:gap-44 {
    gap: 4.4rem
  }

  .xl\:gap-48 {
    gap: 4.8rem
  }

  .xl\:gap-52 {
    gap: 5.2rem
  }

  .xl\:gap-56 {
    gap: 5.6rem
  }

  .xl\:gap-60 {
    gap: 6rem
  }

  .xl\:gap-64 {
    gap: 6.4rem
  }

  .xl\:gap-68 {
    gap: 6.8rem
  }

  .xl\:gap-72 {
    gap: 7.2rem
  }

  .xl\:gap-76 {
    gap: 7.6rem
  }

  .xl\:gap-80 {
    gap: 8rem
  }

  .xl\:gap-84 {
    gap: 8.4rem
  }

  .xl\:gap-88 {
    gap: 8.8rem
  }

  .xl\:gap-92 {
    gap: 9.2rem
  }

  .xl\:gap-96 {
    gap: 9.6rem
  }

  .xl\:gap-128 {
    gap: 12.8rem
  }

  .xl\:gap-136 {
    gap: 13.6rem
  }

  .xl\:gap-160 {
    gap: 16rem
  }

  .xl\:gap-192 {
    gap: 19.2rem
  }

  .xl\:gap-200 {
    gap: 20rem
  }

  .xl\:gap-208 {
    gap: 20.8rem
  }

  .xl\:gap-216 {
    gap: 21.6rem
  }

  .xl\:gap-224 {
    gap: 22.4rem
  }

  .xl\:gap-256 {
    gap: 25.6rem
  }

  .xl\:gap-288 {
    gap: 28.8rem
  }

  .xl\:gap-320 {
    gap: 32rem
  }

  .xl\:gap-360 {
    gap: 36rem
  }

  .xl\:gap-384 {
    gap: 38.4rem
  }

  .xl\:gap-400 {
    gap: 40rem
  }

  .xl\:gap-512 {
    gap: 51.2rem
  }

  .xl\:gap-640 {
    gap: 64rem
  }

  .xl\:gap-xs {
    gap: 32rem
  }

  .xl\:gap-sm {
    gap: 48rem
  }

  .xl\:gap-md {
    gap: 64rem
  }

  .xl\:gap-lg {
    gap: 80rem
  }

  .xl\:gap-xl {
    gap: 96rem
  }

  .xl\:gap-2xl {
    gap: 112rem
  }

  .xl\:gap-3xl {
    gap: 128rem
  }

  .xl\:gap-4xl {
    gap: 144rem
  }

  .xl\:gap-5xl {
    gap: 160rem
  }

  .xl\:gap-px {
    gap: 1px
  }

  .xl\:gap-0\.5 {
    gap: 0.05rem
  }

  .xl\:gap-1\.5 {
    gap: 0.15rem
  }

  .xl\:gap-2\.5 {
    gap: 0.25rem
  }

  .xl\:gap-3\.5 {
    gap: 0.35rem
  }

  .xl\:gap-x-0 {
    column-gap: 0
  }

  .xl\:gap-x-1 {
    column-gap: 0.1rem
  }

  .xl\:gap-x-2 {
    column-gap: 0.2rem
  }

  .xl\:gap-x-3 {
    column-gap: 0.3rem
  }

  .xl\:gap-x-4 {
    column-gap: 0.4rem
  }

  .xl\:gap-x-5 {
    column-gap: 0.5rem
  }

  .xl\:gap-x-6 {
    column-gap: 0.6rem
  }

  .xl\:gap-x-7 {
    column-gap: 0.7rem
  }

  .xl\:gap-x-8 {
    column-gap: 0.8rem
  }

  .xl\:gap-x-9 {
    column-gap: 0.9rem
  }

  .xl\:gap-x-10 {
    column-gap: 1.0rem
  }

  .xl\:gap-x-12 {
    column-gap: 1.2rem
  }

  .xl\:gap-x-14 {
    column-gap: 1.4rem
  }

  .xl\:gap-x-16 {
    column-gap: 1.6rem
  }

  .xl\:gap-x-20 {
    column-gap: 2rem
  }

  .xl\:gap-x-24 {
    column-gap: 2.4rem
  }

  .xl\:gap-x-28 {
    column-gap: 2.8rem
  }

  .xl\:gap-x-32 {
    column-gap: 3.2rem
  }

  .xl\:gap-x-36 {
    column-gap: 3.6rem
  }

  .xl\:gap-x-40 {
    column-gap: 4rem
  }

  .xl\:gap-x-44 {
    column-gap: 4.4rem
  }

  .xl\:gap-x-48 {
    column-gap: 4.8rem
  }

  .xl\:gap-x-52 {
    column-gap: 5.2rem
  }

  .xl\:gap-x-56 {
    column-gap: 5.6rem
  }

  .xl\:gap-x-60 {
    column-gap: 6rem
  }

  .xl\:gap-x-64 {
    column-gap: 6.4rem
  }

  .xl\:gap-x-68 {
    column-gap: 6.8rem
  }

  .xl\:gap-x-72 {
    column-gap: 7.2rem
  }

  .xl\:gap-x-76 {
    column-gap: 7.6rem
  }

  .xl\:gap-x-80 {
    column-gap: 8rem
  }

  .xl\:gap-x-84 {
    column-gap: 8.4rem
  }

  .xl\:gap-x-88 {
    column-gap: 8.8rem
  }

  .xl\:gap-x-92 {
    column-gap: 9.2rem
  }

  .xl\:gap-x-96 {
    column-gap: 9.6rem
  }

  .xl\:gap-x-128 {
    column-gap: 12.8rem
  }

  .xl\:gap-x-136 {
    column-gap: 13.6rem
  }

  .xl\:gap-x-160 {
    column-gap: 16rem
  }

  .xl\:gap-x-192 {
    column-gap: 19.2rem
  }

  .xl\:gap-x-200 {
    column-gap: 20rem
  }

  .xl\:gap-x-208 {
    column-gap: 20.8rem
  }

  .xl\:gap-x-216 {
    column-gap: 21.6rem
  }

  .xl\:gap-x-224 {
    column-gap: 22.4rem
  }

  .xl\:gap-x-256 {
    column-gap: 25.6rem
  }

  .xl\:gap-x-288 {
    column-gap: 28.8rem
  }

  .xl\:gap-x-320 {
    column-gap: 32rem
  }

  .xl\:gap-x-360 {
    column-gap: 36rem
  }

  .xl\:gap-x-384 {
    column-gap: 38.4rem
  }

  .xl\:gap-x-400 {
    column-gap: 40rem
  }

  .xl\:gap-x-512 {
    column-gap: 51.2rem
  }

  .xl\:gap-x-640 {
    column-gap: 64rem
  }

  .xl\:gap-x-xs {
    column-gap: 32rem
  }

  .xl\:gap-x-sm {
    column-gap: 48rem
  }

  .xl\:gap-x-md {
    column-gap: 64rem
  }

  .xl\:gap-x-lg {
    column-gap: 80rem
  }

  .xl\:gap-x-xl {
    column-gap: 96rem
  }

  .xl\:gap-x-2xl {
    column-gap: 112rem
  }

  .xl\:gap-x-3xl {
    column-gap: 128rem
  }

  .xl\:gap-x-4xl {
    column-gap: 144rem
  }

  .xl\:gap-x-5xl {
    column-gap: 160rem
  }

  .xl\:gap-x-px {
    column-gap: 1px
  }

  .xl\:gap-x-0\.5 {
    column-gap: 0.05rem
  }

  .xl\:gap-x-1\.5 {
    column-gap: 0.15rem
  }

  .xl\:gap-x-2\.5 {
    column-gap: 0.25rem
  }

  .xl\:gap-x-3\.5 {
    column-gap: 0.35rem
  }

  .xl\:gap-y-0 {
    row-gap: 0
  }

  .xl\:gap-y-1 {
    row-gap: 0.1rem
  }

  .xl\:gap-y-2 {
    row-gap: 0.2rem
  }

  .xl\:gap-y-3 {
    row-gap: 0.3rem
  }

  .xl\:gap-y-4 {
    row-gap: 0.4rem
  }

  .xl\:gap-y-5 {
    row-gap: 0.5rem
  }

  .xl\:gap-y-6 {
    row-gap: 0.6rem
  }

  .xl\:gap-y-7 {
    row-gap: 0.7rem
  }

  .xl\:gap-y-8 {
    row-gap: 0.8rem
  }

  .xl\:gap-y-9 {
    row-gap: 0.9rem
  }

  .xl\:gap-y-10 {
    row-gap: 1.0rem
  }

  .xl\:gap-y-12 {
    row-gap: 1.2rem
  }

  .xl\:gap-y-14 {
    row-gap: 1.4rem
  }

  .xl\:gap-y-16 {
    row-gap: 1.6rem
  }

  .xl\:gap-y-20 {
    row-gap: 2rem
  }

  .xl\:gap-y-24 {
    row-gap: 2.4rem
  }

  .xl\:gap-y-28 {
    row-gap: 2.8rem
  }

  .xl\:gap-y-32 {
    row-gap: 3.2rem
  }

  .xl\:gap-y-36 {
    row-gap: 3.6rem
  }

  .xl\:gap-y-40 {
    row-gap: 4rem
  }

  .xl\:gap-y-44 {
    row-gap: 4.4rem
  }

  .xl\:gap-y-48 {
    row-gap: 4.8rem
  }

  .xl\:gap-y-52 {
    row-gap: 5.2rem
  }

  .xl\:gap-y-56 {
    row-gap: 5.6rem
  }

  .xl\:gap-y-60 {
    row-gap: 6rem
  }

  .xl\:gap-y-64 {
    row-gap: 6.4rem
  }

  .xl\:gap-y-68 {
    row-gap: 6.8rem
  }

  .xl\:gap-y-72 {
    row-gap: 7.2rem
  }

  .xl\:gap-y-76 {
    row-gap: 7.6rem
  }

  .xl\:gap-y-80 {
    row-gap: 8rem
  }

  .xl\:gap-y-84 {
    row-gap: 8.4rem
  }

  .xl\:gap-y-88 {
    row-gap: 8.8rem
  }

  .xl\:gap-y-92 {
    row-gap: 9.2rem
  }

  .xl\:gap-y-96 {
    row-gap: 9.6rem
  }

  .xl\:gap-y-128 {
    row-gap: 12.8rem
  }

  .xl\:gap-y-136 {
    row-gap: 13.6rem
  }

  .xl\:gap-y-160 {
    row-gap: 16rem
  }

  .xl\:gap-y-192 {
    row-gap: 19.2rem
  }

  .xl\:gap-y-200 {
    row-gap: 20rem
  }

  .xl\:gap-y-208 {
    row-gap: 20.8rem
  }

  .xl\:gap-y-216 {
    row-gap: 21.6rem
  }

  .xl\:gap-y-224 {
    row-gap: 22.4rem
  }

  .xl\:gap-y-256 {
    row-gap: 25.6rem
  }

  .xl\:gap-y-288 {
    row-gap: 28.8rem
  }

  .xl\:gap-y-320 {
    row-gap: 32rem
  }

  .xl\:gap-y-360 {
    row-gap: 36rem
  }

  .xl\:gap-y-384 {
    row-gap: 38.4rem
  }

  .xl\:gap-y-400 {
    row-gap: 40rem
  }

  .xl\:gap-y-512 {
    row-gap: 51.2rem
  }

  .xl\:gap-y-640 {
    row-gap: 64rem
  }

  .xl\:gap-y-xs {
    row-gap: 32rem
  }

  .xl\:gap-y-sm {
    row-gap: 48rem
  }

  .xl\:gap-y-md {
    row-gap: 64rem
  }

  .xl\:gap-y-lg {
    row-gap: 80rem
  }

  .xl\:gap-y-xl {
    row-gap: 96rem
  }

  .xl\:gap-y-2xl {
    row-gap: 112rem
  }

  .xl\:gap-y-3xl {
    row-gap: 128rem
  }

  .xl\:gap-y-4xl {
    row-gap: 144rem
  }

  .xl\:gap-y-5xl {
    row-gap: 160rem
  }

  .xl\:gap-y-px {
    row-gap: 1px
  }

  .xl\:gap-y-0\.5 {
    row-gap: 0.05rem
  }

  .xl\:gap-y-1\.5 {
    row-gap: 0.15rem
  }

  .xl\:gap-y-2\.5 {
    row-gap: 0.25rem
  }

  .xl\:gap-y-3\.5 {
    row-gap: 0.35rem
  }

  .xl\:grid-flow-row {
    grid-auto-flow: row
  }

  .xl\:grid-flow-col {
    grid-auto-flow: column
  }

  .xl\:grid-flow-row-dense {
    grid-auto-flow: row dense
  }

  .xl\:grid-flow-col-dense {
    grid-auto-flow: column dense
  }

  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .xl\:grid-cols-none {
    grid-template-columns: none
  }

  .xl\:auto-cols-auto {
    grid-auto-columns: auto
  }

  .xl\:auto-cols-min {
    grid-auto-columns: min-content
  }

  .xl\:auto-cols-max {
    grid-auto-columns: max-content
  }

  .xl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr)
  }

  .xl\:col-auto {
    grid-column: auto
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .xl\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .xl\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .xl\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .xl\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .xl\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .xl\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .xl\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .xl\:col-span-full {
    grid-column: 1 / -1
  }

  .xl\:col-start-1 {
    grid-column-start: 1
  }

  .xl\:col-start-2 {
    grid-column-start: 2
  }

  .xl\:col-start-3 {
    grid-column-start: 3
  }

  .xl\:col-start-4 {
    grid-column-start: 4
  }

  .xl\:col-start-5 {
    grid-column-start: 5
  }

  .xl\:col-start-6 {
    grid-column-start: 6
  }

  .xl\:col-start-7 {
    grid-column-start: 7
  }

  .xl\:col-start-8 {
    grid-column-start: 8
  }

  .xl\:col-start-9 {
    grid-column-start: 9
  }

  .xl\:col-start-10 {
    grid-column-start: 10
  }

  .xl\:col-start-11 {
    grid-column-start: 11
  }

  .xl\:col-start-12 {
    grid-column-start: 12
  }

  .xl\:col-start-13 {
    grid-column-start: 13
  }

  .xl\:col-start-auto {
    grid-column-start: auto
  }

  .xl\:col-end-1 {
    grid-column-end: 1
  }

  .xl\:col-end-2 {
    grid-column-end: 2
  }

  .xl\:col-end-3 {
    grid-column-end: 3
  }

  .xl\:col-end-4 {
    grid-column-end: 4
  }

  .xl\:col-end-5 {
    grid-column-end: 5
  }

  .xl\:col-end-6 {
    grid-column-end: 6
  }

  .xl\:col-end-7 {
    grid-column-end: 7
  }

  .xl\:col-end-8 {
    grid-column-end: 8
  }

  .xl\:col-end-9 {
    grid-column-end: 9
  }

  .xl\:col-end-10 {
    grid-column-end: 10
  }

  .xl\:col-end-11 {
    grid-column-end: 11
  }

  .xl\:col-end-12 {
    grid-column-end: 12
  }

  .xl\:col-end-13 {
    grid-column-end: 13
  }

  .xl\:col-end-auto {
    grid-column-end: auto
  }

  .xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .xl\:grid-rows-none {
    grid-template-rows: none
  }

  .xl\:auto-rows-auto {
    grid-auto-rows: auto
  }

  .xl\:auto-rows-min {
    grid-auto-rows: min-content
  }

  .xl\:auto-rows-max {
    grid-auto-rows: max-content
  }

  .xl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr)
  }

  .xl\:row-auto {
    grid-row: auto
  }

  .xl\:row-span-1 {
    grid-row: span 1 / span 1
  }

  .xl\:row-span-2 {
    grid-row: span 2 / span 2
  }

  .xl\:row-span-3 {
    grid-row: span 3 / span 3
  }

  .xl\:row-span-4 {
    grid-row: span 4 / span 4
  }

  .xl\:row-span-5 {
    grid-row: span 5 / span 5
  }

  .xl\:row-span-6 {
    grid-row: span 6 / span 6
  }

  .xl\:row-span-full {
    grid-row: 1 / -1
  }

  .xl\:row-start-1 {
    grid-row-start: 1
  }

  .xl\:row-start-2 {
    grid-row-start: 2
  }

  .xl\:row-start-3 {
    grid-row-start: 3
  }

  .xl\:row-start-4 {
    grid-row-start: 4
  }

  .xl\:row-start-5 {
    grid-row-start: 5
  }

  .xl\:row-start-6 {
    grid-row-start: 6
  }

  .xl\:row-start-7 {
    grid-row-start: 7
  }

  .xl\:row-start-auto {
    grid-row-start: auto
  }

  .xl\:row-end-1 {
    grid-row-end: 1
  }

  .xl\:row-end-2 {
    grid-row-end: 2
  }

  .xl\:row-end-3 {
    grid-row-end: 3
  }

  .xl\:row-end-4 {
    grid-row-end: 4
  }

  .xl\:row-end-5 {
    grid-row-end: 5
  }

  .xl\:row-end-6 {
    grid-row-end: 6
  }

  .xl\:row-end-7 {
    grid-row-end: 7
  }

  .xl\:row-end-auto {
    grid-row-end: auto
  }

  .xl\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .xl\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .xl\:transform-none {
    transform: none
  }

  .xl\:origin-center {
    transform-origin: center
  }

  .xl\:origin-top {
    transform-origin: top
  }

  .xl\:origin-top-right {
    transform-origin: top right
  }

  .xl\:origin-right {
    transform-origin: right
  }

  .xl\:origin-bottom-right {
    transform-origin: bottom right
  }

  .xl\:origin-bottom {
    transform-origin: bottom
  }

  .xl\:origin-bottom-left {
    transform-origin: bottom left
  }

  .xl\:origin-left {
    transform-origin: left
  }

  .xl\:origin-top-left {
    transform-origin: top left
  }

  .xl\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .xl\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .xl\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .xl\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .xl\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .xl\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .xl\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .xl\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .xl\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .xl\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .xl\:scale-x-0 {
    --tw-scale-x: 0
  }

  .xl\:scale-x-50 {
    --tw-scale-x: .5
  }

  .xl\:scale-x-75 {
    --tw-scale-x: .75
  }

  .xl\:scale-x-90 {
    --tw-scale-x: .9
  }

  .xl\:scale-x-95 {
    --tw-scale-x: .95
  }

  .xl\:scale-x-100 {
    --tw-scale-x: 1
  }

  .xl\:scale-x-105 {
    --tw-scale-x: 1.05
  }

  .xl\:scale-x-110 {
    --tw-scale-x: 1.1
  }

  .xl\:scale-x-125 {
    --tw-scale-x: 1.25
  }

  .xl\:scale-x-150 {
    --tw-scale-x: 1.5
  }

  .xl\:scale-y-0 {
    --tw-scale-y: 0
  }

  .xl\:scale-y-50 {
    --tw-scale-y: .5
  }

  .xl\:scale-y-75 {
    --tw-scale-y: .75
  }

  .xl\:scale-y-90 {
    --tw-scale-y: .9
  }

  .xl\:scale-y-95 {
    --tw-scale-y: .95
  }

  .xl\:scale-y-100 {
    --tw-scale-y: 1
  }

  .xl\:scale-y-105 {
    --tw-scale-y: 1.05
  }

  .xl\:scale-y-110 {
    --tw-scale-y: 1.1
  }

  .xl\:scale-y-125 {
    --tw-scale-y: 1.25
  }

  .xl\:scale-y-150 {
    --tw-scale-y: 1.5
  }

  .xl\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .xl\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .xl\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .xl\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .xl\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .xl\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .xl\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .xl\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .xl\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .xl\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .xl\:hover\:scale-x-0:hover {
    --tw-scale-x: 0
  }

  .xl\:hover\:scale-x-50:hover {
    --tw-scale-x: .5
  }

  .xl\:hover\:scale-x-75:hover {
    --tw-scale-x: .75
  }

  .xl\:hover\:scale-x-90:hover {
    --tw-scale-x: .9
  }

  .xl\:hover\:scale-x-95:hover {
    --tw-scale-x: .95
  }

  .xl\:hover\:scale-x-100:hover {
    --tw-scale-x: 1
  }

  .xl\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05
  }

  .xl\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1
  }

  .xl\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25
  }

  .xl\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5
  }

  .xl\:hover\:scale-y-0:hover {
    --tw-scale-y: 0
  }

  .xl\:hover\:scale-y-50:hover {
    --tw-scale-y: .5
  }

  .xl\:hover\:scale-y-75:hover {
    --tw-scale-y: .75
  }

  .xl\:hover\:scale-y-90:hover {
    --tw-scale-y: .9
  }

  .xl\:hover\:scale-y-95:hover {
    --tw-scale-y: .95
  }

  .xl\:hover\:scale-y-100:hover {
    --tw-scale-y: 1
  }

  .xl\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05
  }

  .xl\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1
  }

  .xl\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25
  }

  .xl\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5
  }

  .xl\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .xl\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .xl\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .xl\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .xl\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .xl\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .xl\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .xl\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .xl\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .xl\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .xl\:focus\:scale-x-0:focus {
    --tw-scale-x: 0
  }

  .xl\:focus\:scale-x-50:focus {
    --tw-scale-x: .5
  }

  .xl\:focus\:scale-x-75:focus {
    --tw-scale-x: .75
  }

  .xl\:focus\:scale-x-90:focus {
    --tw-scale-x: .9
  }

  .xl\:focus\:scale-x-95:focus {
    --tw-scale-x: .95
  }

  .xl\:focus\:scale-x-100:focus {
    --tw-scale-x: 1
  }

  .xl\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05
  }

  .xl\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1
  }

  .xl\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25
  }

  .xl\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5
  }

  .xl\:focus\:scale-y-0:focus {
    --tw-scale-y: 0
  }

  .xl\:focus\:scale-y-50:focus {
    --tw-scale-y: .5
  }

  .xl\:focus\:scale-y-75:focus {
    --tw-scale-y: .75
  }

  .xl\:focus\:scale-y-90:focus {
    --tw-scale-y: .9
  }

  .xl\:focus\:scale-y-95:focus {
    --tw-scale-y: .95
  }

  .xl\:focus\:scale-y-100:focus {
    --tw-scale-y: 1
  }

  .xl\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05
  }

  .xl\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1
  }

  .xl\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25
  }

  .xl\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5
  }

  .xl\:rotate-0 {
    --tw-rotate: 0deg
  }

  .xl\:rotate-1 {
    --tw-rotate: 1deg
  }

  .xl\:rotate-2 {
    --tw-rotate: 2deg
  }

  .xl\:rotate-3 {
    --tw-rotate: 3deg
  }

  .xl\:rotate-6 {
    --tw-rotate: 6deg
  }

  .xl\:rotate-12 {
    --tw-rotate: 12deg
  }

  .xl\:rotate-45 {
    --tw-rotate: 45deg
  }

  .xl\:rotate-90 {
    --tw-rotate: 90deg
  }

  .xl\:rotate-180 {
    --tw-rotate: 180deg
  }

  .xl\:-rotate-180 {
    --tw-rotate: -180deg
  }

  .xl\:-rotate-90 {
    --tw-rotate: -90deg
  }

  .xl\:-rotate-45 {
    --tw-rotate: -45deg
  }

  .xl\:-rotate-12 {
    --tw-rotate: -12deg
  }

  .xl\:-rotate-6 {
    --tw-rotate: -6deg
  }

  .xl\:-rotate-3 {
    --tw-rotate: -3deg
  }

  .xl\:-rotate-2 {
    --tw-rotate: -2deg
  }

  .xl\:-rotate-1 {
    --tw-rotate: -1deg
  }

  .xl\:hover\:rotate-0:hover {
    --tw-rotate: 0deg
  }

  .xl\:hover\:rotate-1:hover {
    --tw-rotate: 1deg
  }

  .xl\:hover\:rotate-2:hover {
    --tw-rotate: 2deg
  }

  .xl\:hover\:rotate-3:hover {
    --tw-rotate: 3deg
  }

  .xl\:hover\:rotate-6:hover {
    --tw-rotate: 6deg
  }

  .xl\:hover\:rotate-12:hover {
    --tw-rotate: 12deg
  }

  .xl\:hover\:rotate-45:hover {
    --tw-rotate: 45deg
  }

  .xl\:hover\:rotate-90:hover {
    --tw-rotate: 90deg
  }

  .xl\:hover\:rotate-180:hover {
    --tw-rotate: 180deg
  }

  .xl\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg
  }

  .xl\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg
  }

  .xl\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg
  }

  .xl\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg
  }

  .xl\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg
  }

  .xl\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg
  }

  .xl\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg
  }

  .xl\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg
  }

  .xl\:focus\:rotate-0:focus {
    --tw-rotate: 0deg
  }

  .xl\:focus\:rotate-1:focus {
    --tw-rotate: 1deg
  }

  .xl\:focus\:rotate-2:focus {
    --tw-rotate: 2deg
  }

  .xl\:focus\:rotate-3:focus {
    --tw-rotate: 3deg
  }

  .xl\:focus\:rotate-6:focus {
    --tw-rotate: 6deg
  }

  .xl\:focus\:rotate-12:focus {
    --tw-rotate: 12deg
  }

  .xl\:focus\:rotate-45:focus {
    --tw-rotate: 45deg
  }

  .xl\:focus\:rotate-90:focus {
    --tw-rotate: 90deg
  }

  .xl\:focus\:rotate-180:focus {
    --tw-rotate: 180deg
  }

  .xl\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg
  }

  .xl\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg
  }

  .xl\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg
  }

  .xl\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg
  }

  .xl\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg
  }

  .xl\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg
  }

  .xl\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg
  }

  .xl\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg
  }

  .xl\:translate-x-0 {
    --tw-translate-x: 0
  }

  .xl\:translate-x-1 {
    --tw-translate-x: 0.1rem
  }

  .xl\:translate-x-2 {
    --tw-translate-x: 0.2rem
  }

  .xl\:translate-x-3 {
    --tw-translate-x: 0.3rem
  }

  .xl\:translate-x-4 {
    --tw-translate-x: 0.4rem
  }

  .xl\:translate-x-5 {
    --tw-translate-x: 0.5rem
  }

  .xl\:translate-x-6 {
    --tw-translate-x: 0.6rem
  }

  .xl\:translate-x-7 {
    --tw-translate-x: 0.7rem
  }

  .xl\:translate-x-8 {
    --tw-translate-x: 0.8rem
  }

  .xl\:translate-x-9 {
    --tw-translate-x: 0.9rem
  }

  .xl\:translate-x-10 {
    --tw-translate-x: 1.0rem
  }

  .xl\:translate-x-12 {
    --tw-translate-x: 1.2rem
  }

  .xl\:translate-x-14 {
    --tw-translate-x: 1.4rem
  }

  .xl\:translate-x-16 {
    --tw-translate-x: 1.6rem
  }

  .xl\:translate-x-20 {
    --tw-translate-x: 2rem
  }

  .xl\:translate-x-24 {
    --tw-translate-x: 2.4rem
  }

  .xl\:translate-x-28 {
    --tw-translate-x: 2.8rem
  }

  .xl\:translate-x-32 {
    --tw-translate-x: 3.2rem
  }

  .xl\:translate-x-36 {
    --tw-translate-x: 3.6rem
  }

  .xl\:translate-x-40 {
    --tw-translate-x: 4rem
  }

  .xl\:translate-x-44 {
    --tw-translate-x: 4.4rem
  }

  .xl\:translate-x-48 {
    --tw-translate-x: 4.8rem
  }

  .xl\:translate-x-52 {
    --tw-translate-x: 5.2rem
  }

  .xl\:translate-x-56 {
    --tw-translate-x: 5.6rem
  }

  .xl\:translate-x-60 {
    --tw-translate-x: 6rem
  }

  .xl\:translate-x-64 {
    --tw-translate-x: 6.4rem
  }

  .xl\:translate-x-68 {
    --tw-translate-x: 6.8rem
  }

  .xl\:translate-x-72 {
    --tw-translate-x: 7.2rem
  }

  .xl\:translate-x-76 {
    --tw-translate-x: 7.6rem
  }

  .xl\:translate-x-80 {
    --tw-translate-x: 8rem
  }

  .xl\:translate-x-84 {
    --tw-translate-x: 8.4rem
  }

  .xl\:translate-x-88 {
    --tw-translate-x: 8.8rem
  }

  .xl\:translate-x-92 {
    --tw-translate-x: 9.2rem
  }

  .xl\:translate-x-96 {
    --tw-translate-x: 9.6rem
  }

  .xl\:translate-x-128 {
    --tw-translate-x: 12.8rem
  }

  .xl\:translate-x-136 {
    --tw-translate-x: 13.6rem
  }

  .xl\:translate-x-160 {
    --tw-translate-x: 16rem
  }

  .xl\:translate-x-192 {
    --tw-translate-x: 19.2rem
  }

  .xl\:translate-x-200 {
    --tw-translate-x: 20rem
  }

  .xl\:translate-x-208 {
    --tw-translate-x: 20.8rem
  }

  .xl\:translate-x-216 {
    --tw-translate-x: 21.6rem
  }

  .xl\:translate-x-224 {
    --tw-translate-x: 22.4rem
  }

  .xl\:translate-x-256 {
    --tw-translate-x: 25.6rem
  }

  .xl\:translate-x-288 {
    --tw-translate-x: 28.8rem
  }

  .xl\:translate-x-320 {
    --tw-translate-x: 32rem
  }

  .xl\:translate-x-360 {
    --tw-translate-x: 36rem
  }

  .xl\:translate-x-384 {
    --tw-translate-x: 38.4rem
  }

  .xl\:translate-x-400 {
    --tw-translate-x: 40rem
  }

  .xl\:translate-x-512 {
    --tw-translate-x: 51.2rem
  }

  .xl\:translate-x-640 {
    --tw-translate-x: 64rem
  }

  .xl\:translate-x-xs {
    --tw-translate-x: 32rem
  }

  .xl\:translate-x-sm {
    --tw-translate-x: 48rem
  }

  .xl\:translate-x-md {
    --tw-translate-x: 64rem
  }

  .xl\:translate-x-lg {
    --tw-translate-x: 80rem
  }

  .xl\:translate-x-xl {
    --tw-translate-x: 96rem
  }

  .xl\:translate-x-2xl {
    --tw-translate-x: 112rem
  }

  .xl\:translate-x-3xl {
    --tw-translate-x: 128rem
  }

  .xl\:translate-x-4xl {
    --tw-translate-x: 144rem
  }

  .xl\:translate-x-5xl {
    --tw-translate-x: 160rem
  }

  .xl\:translate-x-px {
    --tw-translate-x: 1px
  }

  .xl\:translate-x-0\.5 {
    --tw-translate-x: 0.05rem
  }

  .xl\:translate-x-1\.5 {
    --tw-translate-x: 0.15rem
  }

  .xl\:translate-x-2\.5 {
    --tw-translate-x: 0.25rem
  }

  .xl\:translate-x-3\.5 {
    --tw-translate-x: 0.35rem
  }

  .xl\:-translate-x-1 {
    --tw-translate-x: -0.1rem
  }

  .xl\:-translate-x-2 {
    --tw-translate-x: -0.2rem
  }

  .xl\:-translate-x-3 {
    --tw-translate-x: -0.3rem
  }

  .xl\:-translate-x-4 {
    --tw-translate-x: -0.4rem
  }

  .xl\:-translate-x-5 {
    --tw-translate-x: -0.5rem
  }

  .xl\:-translate-x-6 {
    --tw-translate-x: -0.6rem
  }

  .xl\:-translate-x-7 {
    --tw-translate-x: -0.7rem
  }

  .xl\:-translate-x-8 {
    --tw-translate-x: -0.8rem
  }

  .xl\:-translate-x-9 {
    --tw-translate-x: -0.9rem
  }

  .xl\:-translate-x-10 {
    --tw-translate-x: -1rem
  }

  .xl\:-translate-x-12 {
    --tw-translate-x: -1.2rem
  }

  .xl\:-translate-x-14 {
    --tw-translate-x: -1.4rem
  }

  .xl\:-translate-x-16 {
    --tw-translate-x: -1.6rem
  }

  .xl\:-translate-x-20 {
    --tw-translate-x: -2rem
  }

  .xl\:-translate-x-24 {
    --tw-translate-x: -2.4rem
  }

  .xl\:-translate-x-28 {
    --tw-translate-x: -2.8rem
  }

  .xl\:-translate-x-32 {
    --tw-translate-x: -3.2rem
  }

  .xl\:-translate-x-36 {
    --tw-translate-x: -3.6rem
  }

  .xl\:-translate-x-40 {
    --tw-translate-x: -4rem
  }

  .xl\:-translate-x-44 {
    --tw-translate-x: -4.4rem
  }

  .xl\:-translate-x-48 {
    --tw-translate-x: -4.8rem
  }

  .xl\:-translate-x-52 {
    --tw-translate-x: -5.2rem
  }

  .xl\:-translate-x-56 {
    --tw-translate-x: -5.6rem
  }

  .xl\:-translate-x-60 {
    --tw-translate-x: -6rem
  }

  .xl\:-translate-x-64 {
    --tw-translate-x: -6.4rem
  }

  .xl\:-translate-x-68 {
    --tw-translate-x: -6.8rem
  }

  .xl\:-translate-x-72 {
    --tw-translate-x: -7.2rem
  }

  .xl\:-translate-x-76 {
    --tw-translate-x: -7.6rem
  }

  .xl\:-translate-x-80 {
    --tw-translate-x: -8rem
  }

  .xl\:-translate-x-84 {
    --tw-translate-x: -8.4rem
  }

  .xl\:-translate-x-88 {
    --tw-translate-x: -8.8rem
  }

  .xl\:-translate-x-92 {
    --tw-translate-x: -9.2rem
  }

  .xl\:-translate-x-96 {
    --tw-translate-x: -9.6rem
  }

  .xl\:-translate-x-128 {
    --tw-translate-x: -12.8rem
  }

  .xl\:-translate-x-136 {
    --tw-translate-x: -13.6rem
  }

  .xl\:-translate-x-160 {
    --tw-translate-x: -16rem
  }

  .xl\:-translate-x-192 {
    --tw-translate-x: -19.2rem
  }

  .xl\:-translate-x-200 {
    --tw-translate-x: -20rem
  }

  .xl\:-translate-x-208 {
    --tw-translate-x: -20.8rem
  }

  .xl\:-translate-x-216 {
    --tw-translate-x: -21.6rem
  }

  .xl\:-translate-x-224 {
    --tw-translate-x: -22.4rem
  }

  .xl\:-translate-x-256 {
    --tw-translate-x: -25.6rem
  }

  .xl\:-translate-x-288 {
    --tw-translate-x: -28.8rem
  }

  .xl\:-translate-x-320 {
    --tw-translate-x: -32rem
  }

  .xl\:-translate-x-360 {
    --tw-translate-x: -36rem
  }

  .xl\:-translate-x-384 {
    --tw-translate-x: -38.4rem
  }

  .xl\:-translate-x-400 {
    --tw-translate-x: -40rem
  }

  .xl\:-translate-x-512 {
    --tw-translate-x: -51.2rem
  }

  .xl\:-translate-x-640 {
    --tw-translate-x: -64rem
  }

  .xl\:-translate-x-xs {
    --tw-translate-x: -32rem
  }

  .xl\:-translate-x-sm {
    --tw-translate-x: -48rem
  }

  .xl\:-translate-x-md {
    --tw-translate-x: -64rem
  }

  .xl\:-translate-x-lg {
    --tw-translate-x: -80rem
  }

  .xl\:-translate-x-xl {
    --tw-translate-x: -96rem
  }

  .xl\:-translate-x-2xl {
    --tw-translate-x: -112rem
  }

  .xl\:-translate-x-3xl {
    --tw-translate-x: -128rem
  }

  .xl\:-translate-x-4xl {
    --tw-translate-x: -144rem
  }

  .xl\:-translate-x-5xl {
    --tw-translate-x: -160rem
  }

  .xl\:-translate-x-px {
    --tw-translate-x: -1px
  }

  .xl\:-translate-x-0\.5 {
    --tw-translate-x: -0.05rem
  }

  .xl\:-translate-x-1\.5 {
    --tw-translate-x: -0.15rem
  }

  .xl\:-translate-x-2\.5 {
    --tw-translate-x: -0.25rem
  }

  .xl\:-translate-x-3\.5 {
    --tw-translate-x: -0.35rem
  }

  .xl\:translate-x-1\/2 {
    --tw-translate-x: 50%
  }

  .xl\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%
  }

  .xl\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%
  }

  .xl\:translate-x-1\/4 {
    --tw-translate-x: 25%
  }

  .xl\:translate-x-2\/4 {
    --tw-translate-x: 50%
  }

  .xl\:translate-x-3\/4 {
    --tw-translate-x: 75%
  }

  .xl\:translate-x-full {
    --tw-translate-x: 100%
  }

  .xl\:-translate-x-1\/2 {
    --tw-translate-x: -50%
  }

  .xl\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%
  }

  .xl\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%
  }

  .xl\:-translate-x-1\/4 {
    --tw-translate-x: -25%
  }

  .xl\:-translate-x-2\/4 {
    --tw-translate-x: -50%
  }

  .xl\:-translate-x-3\/4 {
    --tw-translate-x: -75%
  }

  .xl\:-translate-x-full {
    --tw-translate-x: -100%
  }

  .xl\:translate-y-0 {
    --tw-translate-y: 0
  }

  .xl\:translate-y-1 {
    --tw-translate-y: 0.1rem
  }

  .xl\:translate-y-2 {
    --tw-translate-y: 0.2rem
  }

  .xl\:translate-y-3 {
    --tw-translate-y: 0.3rem
  }

  .xl\:translate-y-4 {
    --tw-translate-y: 0.4rem
  }

  .xl\:translate-y-5 {
    --tw-translate-y: 0.5rem
  }

  .xl\:translate-y-6 {
    --tw-translate-y: 0.6rem
  }

  .xl\:translate-y-7 {
    --tw-translate-y: 0.7rem
  }

  .xl\:translate-y-8 {
    --tw-translate-y: 0.8rem
  }

  .xl\:translate-y-9 {
    --tw-translate-y: 0.9rem
  }

  .xl\:translate-y-10 {
    --tw-translate-y: 1.0rem
  }

  .xl\:translate-y-12 {
    --tw-translate-y: 1.2rem
  }

  .xl\:translate-y-14 {
    --tw-translate-y: 1.4rem
  }

  .xl\:translate-y-16 {
    --tw-translate-y: 1.6rem
  }

  .xl\:translate-y-20 {
    --tw-translate-y: 2rem
  }

  .xl\:translate-y-24 {
    --tw-translate-y: 2.4rem
  }

  .xl\:translate-y-28 {
    --tw-translate-y: 2.8rem
  }

  .xl\:translate-y-32 {
    --tw-translate-y: 3.2rem
  }

  .xl\:translate-y-36 {
    --tw-translate-y: 3.6rem
  }

  .xl\:translate-y-40 {
    --tw-translate-y: 4rem
  }

  .xl\:translate-y-44 {
    --tw-translate-y: 4.4rem
  }

  .xl\:translate-y-48 {
    --tw-translate-y: 4.8rem
  }

  .xl\:translate-y-52 {
    --tw-translate-y: 5.2rem
  }

  .xl\:translate-y-56 {
    --tw-translate-y: 5.6rem
  }

  .xl\:translate-y-60 {
    --tw-translate-y: 6rem
  }

  .xl\:translate-y-64 {
    --tw-translate-y: 6.4rem
  }

  .xl\:translate-y-68 {
    --tw-translate-y: 6.8rem
  }

  .xl\:translate-y-72 {
    --tw-translate-y: 7.2rem
  }

  .xl\:translate-y-76 {
    --tw-translate-y: 7.6rem
  }

  .xl\:translate-y-80 {
    --tw-translate-y: 8rem
  }

  .xl\:translate-y-84 {
    --tw-translate-y: 8.4rem
  }

  .xl\:translate-y-88 {
    --tw-translate-y: 8.8rem
  }

  .xl\:translate-y-92 {
    --tw-translate-y: 9.2rem
  }

  .xl\:translate-y-96 {
    --tw-translate-y: 9.6rem
  }

  .xl\:translate-y-128 {
    --tw-translate-y: 12.8rem
  }

  .xl\:translate-y-136 {
    --tw-translate-y: 13.6rem
  }

  .xl\:translate-y-160 {
    --tw-translate-y: 16rem
  }

  .xl\:translate-y-192 {
    --tw-translate-y: 19.2rem
  }

  .xl\:translate-y-200 {
    --tw-translate-y: 20rem
  }

  .xl\:translate-y-208 {
    --tw-translate-y: 20.8rem
  }

  .xl\:translate-y-216 {
    --tw-translate-y: 21.6rem
  }

  .xl\:translate-y-224 {
    --tw-translate-y: 22.4rem
  }

  .xl\:translate-y-256 {
    --tw-translate-y: 25.6rem
  }

  .xl\:translate-y-288 {
    --tw-translate-y: 28.8rem
  }

  .xl\:translate-y-320 {
    --tw-translate-y: 32rem
  }

  .xl\:translate-y-360 {
    --tw-translate-y: 36rem
  }

  .xl\:translate-y-384 {
    --tw-translate-y: 38.4rem
  }

  .xl\:translate-y-400 {
    --tw-translate-y: 40rem
  }

  .xl\:translate-y-512 {
    --tw-translate-y: 51.2rem
  }

  .xl\:translate-y-640 {
    --tw-translate-y: 64rem
  }

  .xl\:translate-y-xs {
    --tw-translate-y: 32rem
  }

  .xl\:translate-y-sm {
    --tw-translate-y: 48rem
  }

  .xl\:translate-y-md {
    --tw-translate-y: 64rem
  }

  .xl\:translate-y-lg {
    --tw-translate-y: 80rem
  }

  .xl\:translate-y-xl {
    --tw-translate-y: 96rem
  }

  .xl\:translate-y-2xl {
    --tw-translate-y: 112rem
  }

  .xl\:translate-y-3xl {
    --tw-translate-y: 128rem
  }

  .xl\:translate-y-4xl {
    --tw-translate-y: 144rem
  }

  .xl\:translate-y-5xl {
    --tw-translate-y: 160rem
  }

  .xl\:translate-y-px {
    --tw-translate-y: 1px
  }

  .xl\:translate-y-0\.5 {
    --tw-translate-y: 0.05rem
  }

  .xl\:translate-y-1\.5 {
    --tw-translate-y: 0.15rem
  }

  .xl\:translate-y-2\.5 {
    --tw-translate-y: 0.25rem
  }

  .xl\:translate-y-3\.5 {
    --tw-translate-y: 0.35rem
  }

  .xl\:-translate-y-1 {
    --tw-translate-y: -0.1rem
  }

  .xl\:-translate-y-2 {
    --tw-translate-y: -0.2rem
  }

  .xl\:-translate-y-3 {
    --tw-translate-y: -0.3rem
  }

  .xl\:-translate-y-4 {
    --tw-translate-y: -0.4rem
  }

  .xl\:-translate-y-5 {
    --tw-translate-y: -0.5rem
  }

  .xl\:-translate-y-6 {
    --tw-translate-y: -0.6rem
  }

  .xl\:-translate-y-7 {
    --tw-translate-y: -0.7rem
  }

  .xl\:-translate-y-8 {
    --tw-translate-y: -0.8rem
  }

  .xl\:-translate-y-9 {
    --tw-translate-y: -0.9rem
  }

  .xl\:-translate-y-10 {
    --tw-translate-y: -1rem
  }

  .xl\:-translate-y-12 {
    --tw-translate-y: -1.2rem
  }

  .xl\:-translate-y-14 {
    --tw-translate-y: -1.4rem
  }

  .xl\:-translate-y-16 {
    --tw-translate-y: -1.6rem
  }

  .xl\:-translate-y-20 {
    --tw-translate-y: -2rem
  }

  .xl\:-translate-y-24 {
    --tw-translate-y: -2.4rem
  }

  .xl\:-translate-y-28 {
    --tw-translate-y: -2.8rem
  }

  .xl\:-translate-y-32 {
    --tw-translate-y: -3.2rem
  }

  .xl\:-translate-y-36 {
    --tw-translate-y: -3.6rem
  }

  .xl\:-translate-y-40 {
    --tw-translate-y: -4rem
  }

  .xl\:-translate-y-44 {
    --tw-translate-y: -4.4rem
  }

  .xl\:-translate-y-48 {
    --tw-translate-y: -4.8rem
  }

  .xl\:-translate-y-52 {
    --tw-translate-y: -5.2rem
  }

  .xl\:-translate-y-56 {
    --tw-translate-y: -5.6rem
  }

  .xl\:-translate-y-60 {
    --tw-translate-y: -6rem
  }

  .xl\:-translate-y-64 {
    --tw-translate-y: -6.4rem
  }

  .xl\:-translate-y-68 {
    --tw-translate-y: -6.8rem
  }

  .xl\:-translate-y-72 {
    --tw-translate-y: -7.2rem
  }

  .xl\:-translate-y-76 {
    --tw-translate-y: -7.6rem
  }

  .xl\:-translate-y-80 {
    --tw-translate-y: -8rem
  }

  .xl\:-translate-y-84 {
    --tw-translate-y: -8.4rem
  }

  .xl\:-translate-y-88 {
    --tw-translate-y: -8.8rem
  }

  .xl\:-translate-y-92 {
    --tw-translate-y: -9.2rem
  }

  .xl\:-translate-y-96 {
    --tw-translate-y: -9.6rem
  }

  .xl\:-translate-y-128 {
    --tw-translate-y: -12.8rem
  }

  .xl\:-translate-y-136 {
    --tw-translate-y: -13.6rem
  }

  .xl\:-translate-y-160 {
    --tw-translate-y: -16rem
  }

  .xl\:-translate-y-192 {
    --tw-translate-y: -19.2rem
  }

  .xl\:-translate-y-200 {
    --tw-translate-y: -20rem
  }

  .xl\:-translate-y-208 {
    --tw-translate-y: -20.8rem
  }

  .xl\:-translate-y-216 {
    --tw-translate-y: -21.6rem
  }

  .xl\:-translate-y-224 {
    --tw-translate-y: -22.4rem
  }

  .xl\:-translate-y-256 {
    --tw-translate-y: -25.6rem
  }

  .xl\:-translate-y-288 {
    --tw-translate-y: -28.8rem
  }

  .xl\:-translate-y-320 {
    --tw-translate-y: -32rem
  }

  .xl\:-translate-y-360 {
    --tw-translate-y: -36rem
  }

  .xl\:-translate-y-384 {
    --tw-translate-y: -38.4rem
  }

  .xl\:-translate-y-400 {
    --tw-translate-y: -40rem
  }

  .xl\:-translate-y-512 {
    --tw-translate-y: -51.2rem
  }

  .xl\:-translate-y-640 {
    --tw-translate-y: -64rem
  }

  .xl\:-translate-y-xs {
    --tw-translate-y: -32rem
  }

  .xl\:-translate-y-sm {
    --tw-translate-y: -48rem
  }

  .xl\:-translate-y-md {
    --tw-translate-y: -64rem
  }

  .xl\:-translate-y-lg {
    --tw-translate-y: -80rem
  }

  .xl\:-translate-y-xl {
    --tw-translate-y: -96rem
  }

  .xl\:-translate-y-2xl {
    --tw-translate-y: -112rem
  }

  .xl\:-translate-y-3xl {
    --tw-translate-y: -128rem
  }

  .xl\:-translate-y-4xl {
    --tw-translate-y: -144rem
  }

  .xl\:-translate-y-5xl {
    --tw-translate-y: -160rem
  }

  .xl\:-translate-y-px {
    --tw-translate-y: -1px
  }

  .xl\:-translate-y-0\.5 {
    --tw-translate-y: -0.05rem
  }

  .xl\:-translate-y-1\.5 {
    --tw-translate-y: -0.15rem
  }

  .xl\:-translate-y-2\.5 {
    --tw-translate-y: -0.25rem
  }

  .xl\:-translate-y-3\.5 {
    --tw-translate-y: -0.35rem
  }

  .xl\:translate-y-1\/2 {
    --tw-translate-y: 50%
  }

  .xl\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%
  }

  .xl\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%
  }

  .xl\:translate-y-1\/4 {
    --tw-translate-y: 25%
  }

  .xl\:translate-y-2\/4 {
    --tw-translate-y: 50%
  }

  .xl\:translate-y-3\/4 {
    --tw-translate-y: 75%
  }

  .xl\:translate-y-full {
    --tw-translate-y: 100%
  }

  .xl\:-translate-y-1\/2 {
    --tw-translate-y: -50%
  }

  .xl\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%
  }

  .xl\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%
  }

  .xl\:-translate-y-1\/4 {
    --tw-translate-y: -25%
  }

  .xl\:-translate-y-2\/4 {
    --tw-translate-y: -50%
  }

  .xl\:-translate-y-3\/4 {
    --tw-translate-y: -75%
  }

  .xl\:-translate-y-full {
    --tw-translate-y: -100%
  }

  .xl\:hover\:translate-x-0:hover {
    --tw-translate-x: 0
  }

  .xl\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.1rem
  }

  .xl\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.2rem
  }

  .xl\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.3rem
  }

  .xl\:hover\:translate-x-4:hover {
    --tw-translate-x: 0.4rem
  }

  .xl\:hover\:translate-x-5:hover {
    --tw-translate-x: 0.5rem
  }

  .xl\:hover\:translate-x-6:hover {
    --tw-translate-x: 0.6rem
  }

  .xl\:hover\:translate-x-7:hover {
    --tw-translate-x: 0.7rem
  }

  .xl\:hover\:translate-x-8:hover {
    --tw-translate-x: 0.8rem
  }

  .xl\:hover\:translate-x-9:hover {
    --tw-translate-x: 0.9rem
  }

  .xl\:hover\:translate-x-10:hover {
    --tw-translate-x: 1.0rem
  }

  .xl\:hover\:translate-x-12:hover {
    --tw-translate-x: 1.2rem
  }

  .xl\:hover\:translate-x-14:hover {
    --tw-translate-x: 1.4rem
  }

  .xl\:hover\:translate-x-16:hover {
    --tw-translate-x: 1.6rem
  }

  .xl\:hover\:translate-x-20:hover {
    --tw-translate-x: 2rem
  }

  .xl\:hover\:translate-x-24:hover {
    --tw-translate-x: 2.4rem
  }

  .xl\:hover\:translate-x-28:hover {
    --tw-translate-x: 2.8rem
  }

  .xl\:hover\:translate-x-32:hover {
    --tw-translate-x: 3.2rem
  }

  .xl\:hover\:translate-x-36:hover {
    --tw-translate-x: 3.6rem
  }

  .xl\:hover\:translate-x-40:hover {
    --tw-translate-x: 4rem
  }

  .xl\:hover\:translate-x-44:hover {
    --tw-translate-x: 4.4rem
  }

  .xl\:hover\:translate-x-48:hover {
    --tw-translate-x: 4.8rem
  }

  .xl\:hover\:translate-x-52:hover {
    --tw-translate-x: 5.2rem
  }

  .xl\:hover\:translate-x-56:hover {
    --tw-translate-x: 5.6rem
  }

  .xl\:hover\:translate-x-60:hover {
    --tw-translate-x: 6rem
  }

  .xl\:hover\:translate-x-64:hover {
    --tw-translate-x: 6.4rem
  }

  .xl\:hover\:translate-x-68:hover {
    --tw-translate-x: 6.8rem
  }

  .xl\:hover\:translate-x-72:hover {
    --tw-translate-x: 7.2rem
  }

  .xl\:hover\:translate-x-76:hover {
    --tw-translate-x: 7.6rem
  }

  .xl\:hover\:translate-x-80:hover {
    --tw-translate-x: 8rem
  }

  .xl\:hover\:translate-x-84:hover {
    --tw-translate-x: 8.4rem
  }

  .xl\:hover\:translate-x-88:hover {
    --tw-translate-x: 8.8rem
  }

  .xl\:hover\:translate-x-92:hover {
    --tw-translate-x: 9.2rem
  }

  .xl\:hover\:translate-x-96:hover {
    --tw-translate-x: 9.6rem
  }

  .xl\:hover\:translate-x-128:hover {
    --tw-translate-x: 12.8rem
  }

  .xl\:hover\:translate-x-136:hover {
    --tw-translate-x: 13.6rem
  }

  .xl\:hover\:translate-x-160:hover {
    --tw-translate-x: 16rem
  }

  .xl\:hover\:translate-x-192:hover {
    --tw-translate-x: 19.2rem
  }

  .xl\:hover\:translate-x-200:hover {
    --tw-translate-x: 20rem
  }

  .xl\:hover\:translate-x-208:hover {
    --tw-translate-x: 20.8rem
  }

  .xl\:hover\:translate-x-216:hover {
    --tw-translate-x: 21.6rem
  }

  .xl\:hover\:translate-x-224:hover {
    --tw-translate-x: 22.4rem
  }

  .xl\:hover\:translate-x-256:hover {
    --tw-translate-x: 25.6rem
  }

  .xl\:hover\:translate-x-288:hover {
    --tw-translate-x: 28.8rem
  }

  .xl\:hover\:translate-x-320:hover {
    --tw-translate-x: 32rem
  }

  .xl\:hover\:translate-x-360:hover {
    --tw-translate-x: 36rem
  }

  .xl\:hover\:translate-x-384:hover {
    --tw-translate-x: 38.4rem
  }

  .xl\:hover\:translate-x-400:hover {
    --tw-translate-x: 40rem
  }

  .xl\:hover\:translate-x-512:hover {
    --tw-translate-x: 51.2rem
  }

  .xl\:hover\:translate-x-640:hover {
    --tw-translate-x: 64rem
  }

  .xl\:hover\:translate-x-xs:hover {
    --tw-translate-x: 32rem
  }

  .xl\:hover\:translate-x-sm:hover {
    --tw-translate-x: 48rem
  }

  .xl\:hover\:translate-x-md:hover {
    --tw-translate-x: 64rem
  }

  .xl\:hover\:translate-x-lg:hover {
    --tw-translate-x: 80rem
  }

  .xl\:hover\:translate-x-xl:hover {
    --tw-translate-x: 96rem
  }

  .xl\:hover\:translate-x-2xl:hover {
    --tw-translate-x: 112rem
  }

  .xl\:hover\:translate-x-3xl:hover {
    --tw-translate-x: 128rem
  }

  .xl\:hover\:translate-x-4xl:hover {
    --tw-translate-x: 144rem
  }

  .xl\:hover\:translate-x-5xl:hover {
    --tw-translate-x: 160rem
  }

  .xl\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px
  }

  .xl\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.05rem
  }

  .xl\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.15rem
  }

  .xl\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.25rem
  }

  .xl\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.35rem
  }

  .xl\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.1rem
  }

  .xl\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.2rem
  }

  .xl\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.3rem
  }

  .xl\:hover\:-translate-x-4:hover {
    --tw-translate-x: -0.4rem
  }

  .xl\:hover\:-translate-x-5:hover {
    --tw-translate-x: -0.5rem
  }

  .xl\:hover\:-translate-x-6:hover {
    --tw-translate-x: -0.6rem
  }

  .xl\:hover\:-translate-x-7:hover {
    --tw-translate-x: -0.7rem
  }

  .xl\:hover\:-translate-x-8:hover {
    --tw-translate-x: -0.8rem
  }

  .xl\:hover\:-translate-x-9:hover {
    --tw-translate-x: -0.9rem
  }

  .xl\:hover\:-translate-x-10:hover {
    --tw-translate-x: -1rem
  }

  .xl\:hover\:-translate-x-12:hover {
    --tw-translate-x: -1.2rem
  }

  .xl\:hover\:-translate-x-14:hover {
    --tw-translate-x: -1.4rem
  }

  .xl\:hover\:-translate-x-16:hover {
    --tw-translate-x: -1.6rem
  }

  .xl\:hover\:-translate-x-20:hover {
    --tw-translate-x: -2rem
  }

  .xl\:hover\:-translate-x-24:hover {
    --tw-translate-x: -2.4rem
  }

  .xl\:hover\:-translate-x-28:hover {
    --tw-translate-x: -2.8rem
  }

  .xl\:hover\:-translate-x-32:hover {
    --tw-translate-x: -3.2rem
  }

  .xl\:hover\:-translate-x-36:hover {
    --tw-translate-x: -3.6rem
  }

  .xl\:hover\:-translate-x-40:hover {
    --tw-translate-x: -4rem
  }

  .xl\:hover\:-translate-x-44:hover {
    --tw-translate-x: -4.4rem
  }

  .xl\:hover\:-translate-x-48:hover {
    --tw-translate-x: -4.8rem
  }

  .xl\:hover\:-translate-x-52:hover {
    --tw-translate-x: -5.2rem
  }

  .xl\:hover\:-translate-x-56:hover {
    --tw-translate-x: -5.6rem
  }

  .xl\:hover\:-translate-x-60:hover {
    --tw-translate-x: -6rem
  }

  .xl\:hover\:-translate-x-64:hover {
    --tw-translate-x: -6.4rem
  }

  .xl\:hover\:-translate-x-68:hover {
    --tw-translate-x: -6.8rem
  }

  .xl\:hover\:-translate-x-72:hover {
    --tw-translate-x: -7.2rem
  }

  .xl\:hover\:-translate-x-76:hover {
    --tw-translate-x: -7.6rem
  }

  .xl\:hover\:-translate-x-80:hover {
    --tw-translate-x: -8rem
  }

  .xl\:hover\:-translate-x-84:hover {
    --tw-translate-x: -8.4rem
  }

  .xl\:hover\:-translate-x-88:hover {
    --tw-translate-x: -8.8rem
  }

  .xl\:hover\:-translate-x-92:hover {
    --tw-translate-x: -9.2rem
  }

  .xl\:hover\:-translate-x-96:hover {
    --tw-translate-x: -9.6rem
  }

  .xl\:hover\:-translate-x-128:hover {
    --tw-translate-x: -12.8rem
  }

  .xl\:hover\:-translate-x-136:hover {
    --tw-translate-x: -13.6rem
  }

  .xl\:hover\:-translate-x-160:hover {
    --tw-translate-x: -16rem
  }

  .xl\:hover\:-translate-x-192:hover {
    --tw-translate-x: -19.2rem
  }

  .xl\:hover\:-translate-x-200:hover {
    --tw-translate-x: -20rem
  }

  .xl\:hover\:-translate-x-208:hover {
    --tw-translate-x: -20.8rem
  }

  .xl\:hover\:-translate-x-216:hover {
    --tw-translate-x: -21.6rem
  }

  .xl\:hover\:-translate-x-224:hover {
    --tw-translate-x: -22.4rem
  }

  .xl\:hover\:-translate-x-256:hover {
    --tw-translate-x: -25.6rem
  }

  .xl\:hover\:-translate-x-288:hover {
    --tw-translate-x: -28.8rem
  }

  .xl\:hover\:-translate-x-320:hover {
    --tw-translate-x: -32rem
  }

  .xl\:hover\:-translate-x-360:hover {
    --tw-translate-x: -36rem
  }

  .xl\:hover\:-translate-x-384:hover {
    --tw-translate-x: -38.4rem
  }

  .xl\:hover\:-translate-x-400:hover {
    --tw-translate-x: -40rem
  }

  .xl\:hover\:-translate-x-512:hover {
    --tw-translate-x: -51.2rem
  }

  .xl\:hover\:-translate-x-640:hover {
    --tw-translate-x: -64rem
  }

  .xl\:hover\:-translate-x-xs:hover {
    --tw-translate-x: -32rem
  }

  .xl\:hover\:-translate-x-sm:hover {
    --tw-translate-x: -48rem
  }

  .xl\:hover\:-translate-x-md:hover {
    --tw-translate-x: -64rem
  }

  .xl\:hover\:-translate-x-lg:hover {
    --tw-translate-x: -80rem
  }

  .xl\:hover\:-translate-x-xl:hover {
    --tw-translate-x: -96rem
  }

  .xl\:hover\:-translate-x-2xl:hover {
    --tw-translate-x: -112rem
  }

  .xl\:hover\:-translate-x-3xl:hover {
    --tw-translate-x: -128rem
  }

  .xl\:hover\:-translate-x-4xl:hover {
    --tw-translate-x: -144rem
  }

  .xl\:hover\:-translate-x-5xl:hover {
    --tw-translate-x: -160rem
  }

  .xl\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px
  }

  .xl\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.05rem
  }

  .xl\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.15rem
  }

  .xl\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.25rem
  }

  .xl\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.35rem
  }

  .xl\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%
  }

  .xl\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%
  }

  .xl\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%
  }

  .xl\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%
  }

  .xl\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%
  }

  .xl\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%
  }

  .xl\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%
  }

  .xl\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%
  }

  .xl\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%
  }

  .xl\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%
  }

  .xl\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%
  }

  .xl\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%
  }

  .xl\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%
  }

  .xl\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%
  }

  .xl\:hover\:translate-y-0:hover {
    --tw-translate-y: 0
  }

  .xl\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.1rem
  }

  .xl\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.2rem
  }

  .xl\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.3rem
  }

  .xl\:hover\:translate-y-4:hover {
    --tw-translate-y: 0.4rem
  }

  .xl\:hover\:translate-y-5:hover {
    --tw-translate-y: 0.5rem
  }

  .xl\:hover\:translate-y-6:hover {
    --tw-translate-y: 0.6rem
  }

  .xl\:hover\:translate-y-7:hover {
    --tw-translate-y: 0.7rem
  }

  .xl\:hover\:translate-y-8:hover {
    --tw-translate-y: 0.8rem
  }

  .xl\:hover\:translate-y-9:hover {
    --tw-translate-y: 0.9rem
  }

  .xl\:hover\:translate-y-10:hover {
    --tw-translate-y: 1.0rem
  }

  .xl\:hover\:translate-y-12:hover {
    --tw-translate-y: 1.2rem
  }

  .xl\:hover\:translate-y-14:hover {
    --tw-translate-y: 1.4rem
  }

  .xl\:hover\:translate-y-16:hover {
    --tw-translate-y: 1.6rem
  }

  .xl\:hover\:translate-y-20:hover {
    --tw-translate-y: 2rem
  }

  .xl\:hover\:translate-y-24:hover {
    --tw-translate-y: 2.4rem
  }

  .xl\:hover\:translate-y-28:hover {
    --tw-translate-y: 2.8rem
  }

  .xl\:hover\:translate-y-32:hover {
    --tw-translate-y: 3.2rem
  }

  .xl\:hover\:translate-y-36:hover {
    --tw-translate-y: 3.6rem
  }

  .xl\:hover\:translate-y-40:hover {
    --tw-translate-y: 4rem
  }

  .xl\:hover\:translate-y-44:hover {
    --tw-translate-y: 4.4rem
  }

  .xl\:hover\:translate-y-48:hover {
    --tw-translate-y: 4.8rem
  }

  .xl\:hover\:translate-y-52:hover {
    --tw-translate-y: 5.2rem
  }

  .xl\:hover\:translate-y-56:hover {
    --tw-translate-y: 5.6rem
  }

  .xl\:hover\:translate-y-60:hover {
    --tw-translate-y: 6rem
  }

  .xl\:hover\:translate-y-64:hover {
    --tw-translate-y: 6.4rem
  }

  .xl\:hover\:translate-y-68:hover {
    --tw-translate-y: 6.8rem
  }

  .xl\:hover\:translate-y-72:hover {
    --tw-translate-y: 7.2rem
  }

  .xl\:hover\:translate-y-76:hover {
    --tw-translate-y: 7.6rem
  }

  .xl\:hover\:translate-y-80:hover {
    --tw-translate-y: 8rem
  }

  .xl\:hover\:translate-y-84:hover {
    --tw-translate-y: 8.4rem
  }

  .xl\:hover\:translate-y-88:hover {
    --tw-translate-y: 8.8rem
  }

  .xl\:hover\:translate-y-92:hover {
    --tw-translate-y: 9.2rem
  }

  .xl\:hover\:translate-y-96:hover {
    --tw-translate-y: 9.6rem
  }

  .xl\:hover\:translate-y-128:hover {
    --tw-translate-y: 12.8rem
  }

  .xl\:hover\:translate-y-136:hover {
    --tw-translate-y: 13.6rem
  }

  .xl\:hover\:translate-y-160:hover {
    --tw-translate-y: 16rem
  }

  .xl\:hover\:translate-y-192:hover {
    --tw-translate-y: 19.2rem
  }

  .xl\:hover\:translate-y-200:hover {
    --tw-translate-y: 20rem
  }

  .xl\:hover\:translate-y-208:hover {
    --tw-translate-y: 20.8rem
  }

  .xl\:hover\:translate-y-216:hover {
    --tw-translate-y: 21.6rem
  }

  .xl\:hover\:translate-y-224:hover {
    --tw-translate-y: 22.4rem
  }

  .xl\:hover\:translate-y-256:hover {
    --tw-translate-y: 25.6rem
  }

  .xl\:hover\:translate-y-288:hover {
    --tw-translate-y: 28.8rem
  }

  .xl\:hover\:translate-y-320:hover {
    --tw-translate-y: 32rem
  }

  .xl\:hover\:translate-y-360:hover {
    --tw-translate-y: 36rem
  }

  .xl\:hover\:translate-y-384:hover {
    --tw-translate-y: 38.4rem
  }

  .xl\:hover\:translate-y-400:hover {
    --tw-translate-y: 40rem
  }

  .xl\:hover\:translate-y-512:hover {
    --tw-translate-y: 51.2rem
  }

  .xl\:hover\:translate-y-640:hover {
    --tw-translate-y: 64rem
  }

  .xl\:hover\:translate-y-xs:hover {
    --tw-translate-y: 32rem
  }

  .xl\:hover\:translate-y-sm:hover {
    --tw-translate-y: 48rem
  }

  .xl\:hover\:translate-y-md:hover {
    --tw-translate-y: 64rem
  }

  .xl\:hover\:translate-y-lg:hover {
    --tw-translate-y: 80rem
  }

  .xl\:hover\:translate-y-xl:hover {
    --tw-translate-y: 96rem
  }

  .xl\:hover\:translate-y-2xl:hover {
    --tw-translate-y: 112rem
  }

  .xl\:hover\:translate-y-3xl:hover {
    --tw-translate-y: 128rem
  }

  .xl\:hover\:translate-y-4xl:hover {
    --tw-translate-y: 144rem
  }

  .xl\:hover\:translate-y-5xl:hover {
    --tw-translate-y: 160rem
  }

  .xl\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px
  }

  .xl\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.05rem
  }

  .xl\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.15rem
  }

  .xl\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.25rem
  }

  .xl\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.35rem
  }

  .xl\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.1rem
  }

  .xl\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.2rem
  }

  .xl\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.3rem
  }

  .xl\:hover\:-translate-y-4:hover {
    --tw-translate-y: -0.4rem
  }

  .xl\:hover\:-translate-y-5:hover {
    --tw-translate-y: -0.5rem
  }

  .xl\:hover\:-translate-y-6:hover {
    --tw-translate-y: -0.6rem
  }

  .xl\:hover\:-translate-y-7:hover {
    --tw-translate-y: -0.7rem
  }

  .xl\:hover\:-translate-y-8:hover {
    --tw-translate-y: -0.8rem
  }

  .xl\:hover\:-translate-y-9:hover {
    --tw-translate-y: -0.9rem
  }

  .xl\:hover\:-translate-y-10:hover {
    --tw-translate-y: -1rem
  }

  .xl\:hover\:-translate-y-12:hover {
    --tw-translate-y: -1.2rem
  }

  .xl\:hover\:-translate-y-14:hover {
    --tw-translate-y: -1.4rem
  }

  .xl\:hover\:-translate-y-16:hover {
    --tw-translate-y: -1.6rem
  }

  .xl\:hover\:-translate-y-20:hover {
    --tw-translate-y: -2rem
  }

  .xl\:hover\:-translate-y-24:hover {
    --tw-translate-y: -2.4rem
  }

  .xl\:hover\:-translate-y-28:hover {
    --tw-translate-y: -2.8rem
  }

  .xl\:hover\:-translate-y-32:hover {
    --tw-translate-y: -3.2rem
  }

  .xl\:hover\:-translate-y-36:hover {
    --tw-translate-y: -3.6rem
  }

  .xl\:hover\:-translate-y-40:hover {
    --tw-translate-y: -4rem
  }

  .xl\:hover\:-translate-y-44:hover {
    --tw-translate-y: -4.4rem
  }

  .xl\:hover\:-translate-y-48:hover {
    --tw-translate-y: -4.8rem
  }

  .xl\:hover\:-translate-y-52:hover {
    --tw-translate-y: -5.2rem
  }

  .xl\:hover\:-translate-y-56:hover {
    --tw-translate-y: -5.6rem
  }

  .xl\:hover\:-translate-y-60:hover {
    --tw-translate-y: -6rem
  }

  .xl\:hover\:-translate-y-64:hover {
    --tw-translate-y: -6.4rem
  }

  .xl\:hover\:-translate-y-68:hover {
    --tw-translate-y: -6.8rem
  }

  .xl\:hover\:-translate-y-72:hover {
    --tw-translate-y: -7.2rem
  }

  .xl\:hover\:-translate-y-76:hover {
    --tw-translate-y: -7.6rem
  }

  .xl\:hover\:-translate-y-80:hover {
    --tw-translate-y: -8rem
  }

  .xl\:hover\:-translate-y-84:hover {
    --tw-translate-y: -8.4rem
  }

  .xl\:hover\:-translate-y-88:hover {
    --tw-translate-y: -8.8rem
  }

  .xl\:hover\:-translate-y-92:hover {
    --tw-translate-y: -9.2rem
  }

  .xl\:hover\:-translate-y-96:hover {
    --tw-translate-y: -9.6rem
  }

  .xl\:hover\:-translate-y-128:hover {
    --tw-translate-y: -12.8rem
  }

  .xl\:hover\:-translate-y-136:hover {
    --tw-translate-y: -13.6rem
  }

  .xl\:hover\:-translate-y-160:hover {
    --tw-translate-y: -16rem
  }

  .xl\:hover\:-translate-y-192:hover {
    --tw-translate-y: -19.2rem
  }

  .xl\:hover\:-translate-y-200:hover {
    --tw-translate-y: -20rem
  }

  .xl\:hover\:-translate-y-208:hover {
    --tw-translate-y: -20.8rem
  }

  .xl\:hover\:-translate-y-216:hover {
    --tw-translate-y: -21.6rem
  }

  .xl\:hover\:-translate-y-224:hover {
    --tw-translate-y: -22.4rem
  }

  .xl\:hover\:-translate-y-256:hover {
    --tw-translate-y: -25.6rem
  }

  .xl\:hover\:-translate-y-288:hover {
    --tw-translate-y: -28.8rem
  }

  .xl\:hover\:-translate-y-320:hover {
    --tw-translate-y: -32rem
  }

  .xl\:hover\:-translate-y-360:hover {
    --tw-translate-y: -36rem
  }

  .xl\:hover\:-translate-y-384:hover {
    --tw-translate-y: -38.4rem
  }

  .xl\:hover\:-translate-y-400:hover {
    --tw-translate-y: -40rem
  }

  .xl\:hover\:-translate-y-512:hover {
    --tw-translate-y: -51.2rem
  }

  .xl\:hover\:-translate-y-640:hover {
    --tw-translate-y: -64rem
  }

  .xl\:hover\:-translate-y-xs:hover {
    --tw-translate-y: -32rem
  }

  .xl\:hover\:-translate-y-sm:hover {
    --tw-translate-y: -48rem
  }

  .xl\:hover\:-translate-y-md:hover {
    --tw-translate-y: -64rem
  }

  .xl\:hover\:-translate-y-lg:hover {
    --tw-translate-y: -80rem
  }

  .xl\:hover\:-translate-y-xl:hover {
    --tw-translate-y: -96rem
  }

  .xl\:hover\:-translate-y-2xl:hover {
    --tw-translate-y: -112rem
  }

  .xl\:hover\:-translate-y-3xl:hover {
    --tw-translate-y: -128rem
  }

  .xl\:hover\:-translate-y-4xl:hover {
    --tw-translate-y: -144rem
  }

  .xl\:hover\:-translate-y-5xl:hover {
    --tw-translate-y: -160rem
  }

  .xl\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px
  }

  .xl\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.05rem
  }

  .xl\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.15rem
  }

  .xl\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.25rem
  }

  .xl\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.35rem
  }

  .xl\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%
  }

  .xl\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%
  }

  .xl\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%
  }

  .xl\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%
  }

  .xl\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%
  }

  .xl\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%
  }

  .xl\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%
  }

  .xl\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%
  }

  .xl\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%
  }

  .xl\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%
  }

  .xl\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%
  }

  .xl\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%
  }

  .xl\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%
  }

  .xl\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%
  }

  .xl\:focus\:translate-x-0:focus {
    --tw-translate-x: 0
  }

  .xl\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.1rem
  }

  .xl\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.2rem
  }

  .xl\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.3rem
  }

  .xl\:focus\:translate-x-4:focus {
    --tw-translate-x: 0.4rem
  }

  .xl\:focus\:translate-x-5:focus {
    --tw-translate-x: 0.5rem
  }

  .xl\:focus\:translate-x-6:focus {
    --tw-translate-x: 0.6rem
  }

  .xl\:focus\:translate-x-7:focus {
    --tw-translate-x: 0.7rem
  }

  .xl\:focus\:translate-x-8:focus {
    --tw-translate-x: 0.8rem
  }

  .xl\:focus\:translate-x-9:focus {
    --tw-translate-x: 0.9rem
  }

  .xl\:focus\:translate-x-10:focus {
    --tw-translate-x: 1.0rem
  }

  .xl\:focus\:translate-x-12:focus {
    --tw-translate-x: 1.2rem
  }

  .xl\:focus\:translate-x-14:focus {
    --tw-translate-x: 1.4rem
  }

  .xl\:focus\:translate-x-16:focus {
    --tw-translate-x: 1.6rem
  }

  .xl\:focus\:translate-x-20:focus {
    --tw-translate-x: 2rem
  }

  .xl\:focus\:translate-x-24:focus {
    --tw-translate-x: 2.4rem
  }

  .xl\:focus\:translate-x-28:focus {
    --tw-translate-x: 2.8rem
  }

  .xl\:focus\:translate-x-32:focus {
    --tw-translate-x: 3.2rem
  }

  .xl\:focus\:translate-x-36:focus {
    --tw-translate-x: 3.6rem
  }

  .xl\:focus\:translate-x-40:focus {
    --tw-translate-x: 4rem
  }

  .xl\:focus\:translate-x-44:focus {
    --tw-translate-x: 4.4rem
  }

  .xl\:focus\:translate-x-48:focus {
    --tw-translate-x: 4.8rem
  }

  .xl\:focus\:translate-x-52:focus {
    --tw-translate-x: 5.2rem
  }

  .xl\:focus\:translate-x-56:focus {
    --tw-translate-x: 5.6rem
  }

  .xl\:focus\:translate-x-60:focus {
    --tw-translate-x: 6rem
  }

  .xl\:focus\:translate-x-64:focus {
    --tw-translate-x: 6.4rem
  }

  .xl\:focus\:translate-x-68:focus {
    --tw-translate-x: 6.8rem
  }

  .xl\:focus\:translate-x-72:focus {
    --tw-translate-x: 7.2rem
  }

  .xl\:focus\:translate-x-76:focus {
    --tw-translate-x: 7.6rem
  }

  .xl\:focus\:translate-x-80:focus {
    --tw-translate-x: 8rem
  }

  .xl\:focus\:translate-x-84:focus {
    --tw-translate-x: 8.4rem
  }

  .xl\:focus\:translate-x-88:focus {
    --tw-translate-x: 8.8rem
  }

  .xl\:focus\:translate-x-92:focus {
    --tw-translate-x: 9.2rem
  }

  .xl\:focus\:translate-x-96:focus {
    --tw-translate-x: 9.6rem
  }

  .xl\:focus\:translate-x-128:focus {
    --tw-translate-x: 12.8rem
  }

  .xl\:focus\:translate-x-136:focus {
    --tw-translate-x: 13.6rem
  }

  .xl\:focus\:translate-x-160:focus {
    --tw-translate-x: 16rem
  }

  .xl\:focus\:translate-x-192:focus {
    --tw-translate-x: 19.2rem
  }

  .xl\:focus\:translate-x-200:focus {
    --tw-translate-x: 20rem
  }

  .xl\:focus\:translate-x-208:focus {
    --tw-translate-x: 20.8rem
  }

  .xl\:focus\:translate-x-216:focus {
    --tw-translate-x: 21.6rem
  }

  .xl\:focus\:translate-x-224:focus {
    --tw-translate-x: 22.4rem
  }

  .xl\:focus\:translate-x-256:focus {
    --tw-translate-x: 25.6rem
  }

  .xl\:focus\:translate-x-288:focus {
    --tw-translate-x: 28.8rem
  }

  .xl\:focus\:translate-x-320:focus {
    --tw-translate-x: 32rem
  }

  .xl\:focus\:translate-x-360:focus {
    --tw-translate-x: 36rem
  }

  .xl\:focus\:translate-x-384:focus {
    --tw-translate-x: 38.4rem
  }

  .xl\:focus\:translate-x-400:focus {
    --tw-translate-x: 40rem
  }

  .xl\:focus\:translate-x-512:focus {
    --tw-translate-x: 51.2rem
  }

  .xl\:focus\:translate-x-640:focus {
    --tw-translate-x: 64rem
  }

  .xl\:focus\:translate-x-xs:focus {
    --tw-translate-x: 32rem
  }

  .xl\:focus\:translate-x-sm:focus {
    --tw-translate-x: 48rem
  }

  .xl\:focus\:translate-x-md:focus {
    --tw-translate-x: 64rem
  }

  .xl\:focus\:translate-x-lg:focus {
    --tw-translate-x: 80rem
  }

  .xl\:focus\:translate-x-xl:focus {
    --tw-translate-x: 96rem
  }

  .xl\:focus\:translate-x-2xl:focus {
    --tw-translate-x: 112rem
  }

  .xl\:focus\:translate-x-3xl:focus {
    --tw-translate-x: 128rem
  }

  .xl\:focus\:translate-x-4xl:focus {
    --tw-translate-x: 144rem
  }

  .xl\:focus\:translate-x-5xl:focus {
    --tw-translate-x: 160rem
  }

  .xl\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px
  }

  .xl\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.05rem
  }

  .xl\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.15rem
  }

  .xl\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.25rem
  }

  .xl\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.35rem
  }

  .xl\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.1rem
  }

  .xl\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.2rem
  }

  .xl\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.3rem
  }

  .xl\:focus\:-translate-x-4:focus {
    --tw-translate-x: -0.4rem
  }

  .xl\:focus\:-translate-x-5:focus {
    --tw-translate-x: -0.5rem
  }

  .xl\:focus\:-translate-x-6:focus {
    --tw-translate-x: -0.6rem
  }

  .xl\:focus\:-translate-x-7:focus {
    --tw-translate-x: -0.7rem
  }

  .xl\:focus\:-translate-x-8:focus {
    --tw-translate-x: -0.8rem
  }

  .xl\:focus\:-translate-x-9:focus {
    --tw-translate-x: -0.9rem
  }

  .xl\:focus\:-translate-x-10:focus {
    --tw-translate-x: -1rem
  }

  .xl\:focus\:-translate-x-12:focus {
    --tw-translate-x: -1.2rem
  }

  .xl\:focus\:-translate-x-14:focus {
    --tw-translate-x: -1.4rem
  }

  .xl\:focus\:-translate-x-16:focus {
    --tw-translate-x: -1.6rem
  }

  .xl\:focus\:-translate-x-20:focus {
    --tw-translate-x: -2rem
  }

  .xl\:focus\:-translate-x-24:focus {
    --tw-translate-x: -2.4rem
  }

  .xl\:focus\:-translate-x-28:focus {
    --tw-translate-x: -2.8rem
  }

  .xl\:focus\:-translate-x-32:focus {
    --tw-translate-x: -3.2rem
  }

  .xl\:focus\:-translate-x-36:focus {
    --tw-translate-x: -3.6rem
  }

  .xl\:focus\:-translate-x-40:focus {
    --tw-translate-x: -4rem
  }

  .xl\:focus\:-translate-x-44:focus {
    --tw-translate-x: -4.4rem
  }

  .xl\:focus\:-translate-x-48:focus {
    --tw-translate-x: -4.8rem
  }

  .xl\:focus\:-translate-x-52:focus {
    --tw-translate-x: -5.2rem
  }

  .xl\:focus\:-translate-x-56:focus {
    --tw-translate-x: -5.6rem
  }

  .xl\:focus\:-translate-x-60:focus {
    --tw-translate-x: -6rem
  }

  .xl\:focus\:-translate-x-64:focus {
    --tw-translate-x: -6.4rem
  }

  .xl\:focus\:-translate-x-68:focus {
    --tw-translate-x: -6.8rem
  }

  .xl\:focus\:-translate-x-72:focus {
    --tw-translate-x: -7.2rem
  }

  .xl\:focus\:-translate-x-76:focus {
    --tw-translate-x: -7.6rem
  }

  .xl\:focus\:-translate-x-80:focus {
    --tw-translate-x: -8rem
  }

  .xl\:focus\:-translate-x-84:focus {
    --tw-translate-x: -8.4rem
  }

  .xl\:focus\:-translate-x-88:focus {
    --tw-translate-x: -8.8rem
  }

  .xl\:focus\:-translate-x-92:focus {
    --tw-translate-x: -9.2rem
  }

  .xl\:focus\:-translate-x-96:focus {
    --tw-translate-x: -9.6rem
  }

  .xl\:focus\:-translate-x-128:focus {
    --tw-translate-x: -12.8rem
  }

  .xl\:focus\:-translate-x-136:focus {
    --tw-translate-x: -13.6rem
  }

  .xl\:focus\:-translate-x-160:focus {
    --tw-translate-x: -16rem
  }

  .xl\:focus\:-translate-x-192:focus {
    --tw-translate-x: -19.2rem
  }

  .xl\:focus\:-translate-x-200:focus {
    --tw-translate-x: -20rem
  }

  .xl\:focus\:-translate-x-208:focus {
    --tw-translate-x: -20.8rem
  }

  .xl\:focus\:-translate-x-216:focus {
    --tw-translate-x: -21.6rem
  }

  .xl\:focus\:-translate-x-224:focus {
    --tw-translate-x: -22.4rem
  }

  .xl\:focus\:-translate-x-256:focus {
    --tw-translate-x: -25.6rem
  }

  .xl\:focus\:-translate-x-288:focus {
    --tw-translate-x: -28.8rem
  }

  .xl\:focus\:-translate-x-320:focus {
    --tw-translate-x: -32rem
  }

  .xl\:focus\:-translate-x-360:focus {
    --tw-translate-x: -36rem
  }

  .xl\:focus\:-translate-x-384:focus {
    --tw-translate-x: -38.4rem
  }

  .xl\:focus\:-translate-x-400:focus {
    --tw-translate-x: -40rem
  }

  .xl\:focus\:-translate-x-512:focus {
    --tw-translate-x: -51.2rem
  }

  .xl\:focus\:-translate-x-640:focus {
    --tw-translate-x: -64rem
  }

  .xl\:focus\:-translate-x-xs:focus {
    --tw-translate-x: -32rem
  }

  .xl\:focus\:-translate-x-sm:focus {
    --tw-translate-x: -48rem
  }

  .xl\:focus\:-translate-x-md:focus {
    --tw-translate-x: -64rem
  }

  .xl\:focus\:-translate-x-lg:focus {
    --tw-translate-x: -80rem
  }

  .xl\:focus\:-translate-x-xl:focus {
    --tw-translate-x: -96rem
  }

  .xl\:focus\:-translate-x-2xl:focus {
    --tw-translate-x: -112rem
  }

  .xl\:focus\:-translate-x-3xl:focus {
    --tw-translate-x: -128rem
  }

  .xl\:focus\:-translate-x-4xl:focus {
    --tw-translate-x: -144rem
  }

  .xl\:focus\:-translate-x-5xl:focus {
    --tw-translate-x: -160rem
  }

  .xl\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px
  }

  .xl\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.05rem
  }

  .xl\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.15rem
  }

  .xl\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.25rem
  }

  .xl\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.35rem
  }

  .xl\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%
  }

  .xl\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%
  }

  .xl\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%
  }

  .xl\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%
  }

  .xl\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%
  }

  .xl\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%
  }

  .xl\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%
  }

  .xl\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%
  }

  .xl\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%
  }

  .xl\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%
  }

  .xl\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%
  }

  .xl\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%
  }

  .xl\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%
  }

  .xl\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%
  }

  .xl\:focus\:translate-y-0:focus {
    --tw-translate-y: 0
  }

  .xl\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.1rem
  }

  .xl\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.2rem
  }

  .xl\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.3rem
  }

  .xl\:focus\:translate-y-4:focus {
    --tw-translate-y: 0.4rem
  }

  .xl\:focus\:translate-y-5:focus {
    --tw-translate-y: 0.5rem
  }

  .xl\:focus\:translate-y-6:focus {
    --tw-translate-y: 0.6rem
  }

  .xl\:focus\:translate-y-7:focus {
    --tw-translate-y: 0.7rem
  }

  .xl\:focus\:translate-y-8:focus {
    --tw-translate-y: 0.8rem
  }

  .xl\:focus\:translate-y-9:focus {
    --tw-translate-y: 0.9rem
  }

  .xl\:focus\:translate-y-10:focus {
    --tw-translate-y: 1.0rem
  }

  .xl\:focus\:translate-y-12:focus {
    --tw-translate-y: 1.2rem
  }

  .xl\:focus\:translate-y-14:focus {
    --tw-translate-y: 1.4rem
  }

  .xl\:focus\:translate-y-16:focus {
    --tw-translate-y: 1.6rem
  }

  .xl\:focus\:translate-y-20:focus {
    --tw-translate-y: 2rem
  }

  .xl\:focus\:translate-y-24:focus {
    --tw-translate-y: 2.4rem
  }

  .xl\:focus\:translate-y-28:focus {
    --tw-translate-y: 2.8rem
  }

  .xl\:focus\:translate-y-32:focus {
    --tw-translate-y: 3.2rem
  }

  .xl\:focus\:translate-y-36:focus {
    --tw-translate-y: 3.6rem
  }

  .xl\:focus\:translate-y-40:focus {
    --tw-translate-y: 4rem
  }

  .xl\:focus\:translate-y-44:focus {
    --tw-translate-y: 4.4rem
  }

  .xl\:focus\:translate-y-48:focus {
    --tw-translate-y: 4.8rem
  }

  .xl\:focus\:translate-y-52:focus {
    --tw-translate-y: 5.2rem
  }

  .xl\:focus\:translate-y-56:focus {
    --tw-translate-y: 5.6rem
  }

  .xl\:focus\:translate-y-60:focus {
    --tw-translate-y: 6rem
  }

  .xl\:focus\:translate-y-64:focus {
    --tw-translate-y: 6.4rem
  }

  .xl\:focus\:translate-y-68:focus {
    --tw-translate-y: 6.8rem
  }

  .xl\:focus\:translate-y-72:focus {
    --tw-translate-y: 7.2rem
  }

  .xl\:focus\:translate-y-76:focus {
    --tw-translate-y: 7.6rem
  }

  .xl\:focus\:translate-y-80:focus {
    --tw-translate-y: 8rem
  }

  .xl\:focus\:translate-y-84:focus {
    --tw-translate-y: 8.4rem
  }

  .xl\:focus\:translate-y-88:focus {
    --tw-translate-y: 8.8rem
  }

  .xl\:focus\:translate-y-92:focus {
    --tw-translate-y: 9.2rem
  }

  .xl\:focus\:translate-y-96:focus {
    --tw-translate-y: 9.6rem
  }

  .xl\:focus\:translate-y-128:focus {
    --tw-translate-y: 12.8rem
  }

  .xl\:focus\:translate-y-136:focus {
    --tw-translate-y: 13.6rem
  }

  .xl\:focus\:translate-y-160:focus {
    --tw-translate-y: 16rem
  }

  .xl\:focus\:translate-y-192:focus {
    --tw-translate-y: 19.2rem
  }

  .xl\:focus\:translate-y-200:focus {
    --tw-translate-y: 20rem
  }

  .xl\:focus\:translate-y-208:focus {
    --tw-translate-y: 20.8rem
  }

  .xl\:focus\:translate-y-216:focus {
    --tw-translate-y: 21.6rem
  }

  .xl\:focus\:translate-y-224:focus {
    --tw-translate-y: 22.4rem
  }

  .xl\:focus\:translate-y-256:focus {
    --tw-translate-y: 25.6rem
  }

  .xl\:focus\:translate-y-288:focus {
    --tw-translate-y: 28.8rem
  }

  .xl\:focus\:translate-y-320:focus {
    --tw-translate-y: 32rem
  }

  .xl\:focus\:translate-y-360:focus {
    --tw-translate-y: 36rem
  }

  .xl\:focus\:translate-y-384:focus {
    --tw-translate-y: 38.4rem
  }

  .xl\:focus\:translate-y-400:focus {
    --tw-translate-y: 40rem
  }

  .xl\:focus\:translate-y-512:focus {
    --tw-translate-y: 51.2rem
  }

  .xl\:focus\:translate-y-640:focus {
    --tw-translate-y: 64rem
  }

  .xl\:focus\:translate-y-xs:focus {
    --tw-translate-y: 32rem
  }

  .xl\:focus\:translate-y-sm:focus {
    --tw-translate-y: 48rem
  }

  .xl\:focus\:translate-y-md:focus {
    --tw-translate-y: 64rem
  }

  .xl\:focus\:translate-y-lg:focus {
    --tw-translate-y: 80rem
  }

  .xl\:focus\:translate-y-xl:focus {
    --tw-translate-y: 96rem
  }

  .xl\:focus\:translate-y-2xl:focus {
    --tw-translate-y: 112rem
  }

  .xl\:focus\:translate-y-3xl:focus {
    --tw-translate-y: 128rem
  }

  .xl\:focus\:translate-y-4xl:focus {
    --tw-translate-y: 144rem
  }

  .xl\:focus\:translate-y-5xl:focus {
    --tw-translate-y: 160rem
  }

  .xl\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px
  }

  .xl\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.05rem
  }

  .xl\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.15rem
  }

  .xl\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.25rem
  }

  .xl\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.35rem
  }

  .xl\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.1rem
  }

  .xl\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.2rem
  }

  .xl\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.3rem
  }

  .xl\:focus\:-translate-y-4:focus {
    --tw-translate-y: -0.4rem
  }

  .xl\:focus\:-translate-y-5:focus {
    --tw-translate-y: -0.5rem
  }

  .xl\:focus\:-translate-y-6:focus {
    --tw-translate-y: -0.6rem
  }

  .xl\:focus\:-translate-y-7:focus {
    --tw-translate-y: -0.7rem
  }

  .xl\:focus\:-translate-y-8:focus {
    --tw-translate-y: -0.8rem
  }

  .xl\:focus\:-translate-y-9:focus {
    --tw-translate-y: -0.9rem
  }

  .xl\:focus\:-translate-y-10:focus {
    --tw-translate-y: -1rem
  }

  .xl\:focus\:-translate-y-12:focus {
    --tw-translate-y: -1.2rem
  }

  .xl\:focus\:-translate-y-14:focus {
    --tw-translate-y: -1.4rem
  }

  .xl\:focus\:-translate-y-16:focus {
    --tw-translate-y: -1.6rem
  }

  .xl\:focus\:-translate-y-20:focus {
    --tw-translate-y: -2rem
  }

  .xl\:focus\:-translate-y-24:focus {
    --tw-translate-y: -2.4rem
  }

  .xl\:focus\:-translate-y-28:focus {
    --tw-translate-y: -2.8rem
  }

  .xl\:focus\:-translate-y-32:focus {
    --tw-translate-y: -3.2rem
  }

  .xl\:focus\:-translate-y-36:focus {
    --tw-translate-y: -3.6rem
  }

  .xl\:focus\:-translate-y-40:focus {
    --tw-translate-y: -4rem
  }

  .xl\:focus\:-translate-y-44:focus {
    --tw-translate-y: -4.4rem
  }

  .xl\:focus\:-translate-y-48:focus {
    --tw-translate-y: -4.8rem
  }

  .xl\:focus\:-translate-y-52:focus {
    --tw-translate-y: -5.2rem
  }

  .xl\:focus\:-translate-y-56:focus {
    --tw-translate-y: -5.6rem
  }

  .xl\:focus\:-translate-y-60:focus {
    --tw-translate-y: -6rem
  }

  .xl\:focus\:-translate-y-64:focus {
    --tw-translate-y: -6.4rem
  }

  .xl\:focus\:-translate-y-68:focus {
    --tw-translate-y: -6.8rem
  }

  .xl\:focus\:-translate-y-72:focus {
    --tw-translate-y: -7.2rem
  }

  .xl\:focus\:-translate-y-76:focus {
    --tw-translate-y: -7.6rem
  }

  .xl\:focus\:-translate-y-80:focus {
    --tw-translate-y: -8rem
  }

  .xl\:focus\:-translate-y-84:focus {
    --tw-translate-y: -8.4rem
  }

  .xl\:focus\:-translate-y-88:focus {
    --tw-translate-y: -8.8rem
  }

  .xl\:focus\:-translate-y-92:focus {
    --tw-translate-y: -9.2rem
  }

  .xl\:focus\:-translate-y-96:focus {
    --tw-translate-y: -9.6rem
  }

  .xl\:focus\:-translate-y-128:focus {
    --tw-translate-y: -12.8rem
  }

  .xl\:focus\:-translate-y-136:focus {
    --tw-translate-y: -13.6rem
  }

  .xl\:focus\:-translate-y-160:focus {
    --tw-translate-y: -16rem
  }

  .xl\:focus\:-translate-y-192:focus {
    --tw-translate-y: -19.2rem
  }

  .xl\:focus\:-translate-y-200:focus {
    --tw-translate-y: -20rem
  }

  .xl\:focus\:-translate-y-208:focus {
    --tw-translate-y: -20.8rem
  }

  .xl\:focus\:-translate-y-216:focus {
    --tw-translate-y: -21.6rem
  }

  .xl\:focus\:-translate-y-224:focus {
    --tw-translate-y: -22.4rem
  }

  .xl\:focus\:-translate-y-256:focus {
    --tw-translate-y: -25.6rem
  }

  .xl\:focus\:-translate-y-288:focus {
    --tw-translate-y: -28.8rem
  }

  .xl\:focus\:-translate-y-320:focus {
    --tw-translate-y: -32rem
  }

  .xl\:focus\:-translate-y-360:focus {
    --tw-translate-y: -36rem
  }

  .xl\:focus\:-translate-y-384:focus {
    --tw-translate-y: -38.4rem
  }

  .xl\:focus\:-translate-y-400:focus {
    --tw-translate-y: -40rem
  }

  .xl\:focus\:-translate-y-512:focus {
    --tw-translate-y: -51.2rem
  }

  .xl\:focus\:-translate-y-640:focus {
    --tw-translate-y: -64rem
  }

  .xl\:focus\:-translate-y-xs:focus {
    --tw-translate-y: -32rem
  }

  .xl\:focus\:-translate-y-sm:focus {
    --tw-translate-y: -48rem
  }

  .xl\:focus\:-translate-y-md:focus {
    --tw-translate-y: -64rem
  }

  .xl\:focus\:-translate-y-lg:focus {
    --tw-translate-y: -80rem
  }

  .xl\:focus\:-translate-y-xl:focus {
    --tw-translate-y: -96rem
  }

  .xl\:focus\:-translate-y-2xl:focus {
    --tw-translate-y: -112rem
  }

  .xl\:focus\:-translate-y-3xl:focus {
    --tw-translate-y: -128rem
  }

  .xl\:focus\:-translate-y-4xl:focus {
    --tw-translate-y: -144rem
  }

  .xl\:focus\:-translate-y-5xl:focus {
    --tw-translate-y: -160rem
  }

  .xl\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px
  }

  .xl\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.05rem
  }

  .xl\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.15rem
  }

  .xl\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.25rem
  }

  .xl\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.35rem
  }

  .xl\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%
  }

  .xl\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%
  }

  .xl\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%
  }

  .xl\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%
  }

  .xl\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%
  }

  .xl\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%
  }

  .xl\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%
  }

  .xl\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%
  }

  .xl\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%
  }

  .xl\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%
  }

  .xl\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%
  }

  .xl\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%
  }

  .xl\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%
  }

  .xl\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%
  }

  .xl\:skew-x-0 {
    --tw-skew-x: 0deg
  }

  .xl\:skew-x-1 {
    --tw-skew-x: 1deg
  }

  .xl\:skew-x-2 {
    --tw-skew-x: 2deg
  }

  .xl\:skew-x-3 {
    --tw-skew-x: 3deg
  }

  .xl\:skew-x-6 {
    --tw-skew-x: 6deg
  }

  .xl\:skew-x-12 {
    --tw-skew-x: 12deg
  }

  .xl\:-skew-x-12 {
    --tw-skew-x: -12deg
  }

  .xl\:-skew-x-6 {
    --tw-skew-x: -6deg
  }

  .xl\:-skew-x-3 {
    --tw-skew-x: -3deg
  }

  .xl\:-skew-x-2 {
    --tw-skew-x: -2deg
  }

  .xl\:-skew-x-1 {
    --tw-skew-x: -1deg
  }

  .xl\:skew-y-0 {
    --tw-skew-y: 0deg
  }

  .xl\:skew-y-1 {
    --tw-skew-y: 1deg
  }

  .xl\:skew-y-2 {
    --tw-skew-y: 2deg
  }

  .xl\:skew-y-3 {
    --tw-skew-y: 3deg
  }

  .xl\:skew-y-6 {
    --tw-skew-y: 6deg
  }

  .xl\:skew-y-12 {
    --tw-skew-y: 12deg
  }

  .xl\:-skew-y-12 {
    --tw-skew-y: -12deg
  }

  .xl\:-skew-y-6 {
    --tw-skew-y: -6deg
  }

  .xl\:-skew-y-3 {
    --tw-skew-y: -3deg
  }

  .xl\:-skew-y-2 {
    --tw-skew-y: -2deg
  }

  .xl\:-skew-y-1 {
    --tw-skew-y: -1deg
  }

  .xl\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg
  }

  .xl\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg
  }

  .xl\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg
  }

  .xl\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg
  }

  .xl\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg
  }

  .xl\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg
  }

  .xl\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg
  }

  .xl\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg
  }

  .xl\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg
  }

  .xl\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg
  }

  .xl\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg
  }

  .xl\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg
  }

  .xl\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg
  }

  .xl\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg
  }

  .xl\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg
  }

  .xl\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg
  }

  .xl\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg
  }

  .xl\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg
  }

  .xl\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg
  }

  .xl\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg
  }

  .xl\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg
  }

  .xl\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg
  }

  .xl\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg
  }

  .xl\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg
  }

  .xl\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg
  }

  .xl\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg
  }

  .xl\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg
  }

  .xl\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg
  }

  .xl\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg
  }

  .xl\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg
  }

  .xl\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg
  }

  .xl\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg
  }

  .xl\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg
  }

  .xl\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg
  }

  .xl\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg
  }

  .xl\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg
  }

  .xl\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg
  }

  .xl\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg
  }

  .xl\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg
  }

  .xl\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg
  }

  .xl\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg
  }

  .xl\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg
  }

  .xl\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg
  }

  .xl\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg
  }

  .xl\:transition-none {
    transition-property: none
  }

  .xl\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .xl\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .xl\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .xl\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .xl\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .xl\:transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .xl\:ease-linear {
    transition-timing-function: linear
  }

  .xl\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .xl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .xl\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .xl\:duration-75 {
    transition-duration: 75ms
  }

  .xl\:duration-100 {
    transition-duration: 100ms
  }

  .xl\:duration-150 {
    transition-duration: 150ms
  }

  .xl\:duration-200 {
    transition-duration: 200ms
  }

  .xl\:duration-300 {
    transition-duration: 300ms
  }

  .xl\:duration-500 {
    transition-duration: 500ms
  }

  .xl\:duration-700 {
    transition-duration: 700ms
  }

  .xl\:duration-1000 {
    transition-duration: 1000ms
  }

  .xl\:delay-75 {
    transition-delay: 75ms
  }

  .xl\:delay-100 {
    transition-delay: 100ms
  }

  .xl\:delay-150 {
    transition-delay: 150ms
  }

  .xl\:delay-200 {
    transition-delay: 200ms
  }

  .xl\:delay-300 {
    transition-delay: 300ms
  }

  .xl\:delay-500 {
    transition-delay: 500ms
  }

  .xl\:delay-700 {
    transition-delay: 700ms
  }

  .xl\:delay-1000 {
    transition-delay: 1000ms
  }

  .xl\:animate-none {
    animation: none
  }

  .xl\:animate-spin {
    animation: spin 1s linear infinite
  }

  .xl\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .xl\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .xl\:animate-bounce {
    animation: bounce 1s infinite
  }
}

@media print {
  .print\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  .print\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.1rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.1rem * var(--tw-space-x-reverse));
    margin-left: calc(0.1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.2rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.2rem * var(--tw-space-x-reverse));
    margin-left: calc(0.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.3rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.3rem * var(--tw-space-x-reverse));
    margin-left: calc(0.3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.4rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.4rem * var(--tw-space-x-reverse));
    margin-left: calc(0.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.6rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.6rem * var(--tw-space-x-reverse));
    margin-left: calc(0.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.7rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.7rem * var(--tw-space-x-reverse));
    margin-left: calc(0.7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.8rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.8rem * var(--tw-space-x-reverse));
    margin-left: calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.9rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.9rem * var(--tw-space-x-reverse));
    margin-left: calc(0.9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.0rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.0rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.0rem * var(--tw-space-x-reverse));
    margin-left: calc(1.0rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.2rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.2rem * var(--tw-space-x-reverse));
    margin-left: calc(1.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.4rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.4rem * var(--tw-space-x-reverse));
    margin-left: calc(1.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.6rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.4rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.4rem * var(--tw-space-x-reverse));
    margin-left: calc(2.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.8rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.8rem * var(--tw-space-x-reverse));
    margin-left: calc(2.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.2rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.2rem * var(--tw-space-x-reverse));
    margin-left: calc(3.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.6rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.6rem * var(--tw-space-x-reverse));
    margin-left: calc(3.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4.4rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4.4rem * var(--tw-space-x-reverse));
    margin-left: calc(4.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4.8rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4.8rem * var(--tw-space-x-reverse));
    margin-left: calc(4.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5.2rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5.2rem * var(--tw-space-x-reverse));
    margin-left: calc(5.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5.6rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5.6rem * var(--tw-space-x-reverse));
    margin-left: calc(5.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6.4rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6.4rem * var(--tw-space-x-reverse));
    margin-left: calc(6.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6.8rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6.8rem * var(--tw-space-x-reverse));
    margin-left: calc(6.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7.2rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7.2rem * var(--tw-space-x-reverse));
    margin-left: calc(7.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7.6rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7.6rem * var(--tw-space-x-reverse));
    margin-left: calc(7.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8.4rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8.4rem * var(--tw-space-x-reverse));
    margin-left: calc(8.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8.8rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8.8rem * var(--tw-space-x-reverse));
    margin-left: calc(8.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9.2rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9.2rem * var(--tw-space-x-reverse));
    margin-left: calc(9.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9.6rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9.6rem * var(--tw-space-x-reverse));
    margin-left: calc(9.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12.8rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12.8rem * var(--tw-space-x-reverse));
    margin-left: calc(12.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13.6rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13.6rem * var(--tw-space-x-reverse));
    margin-left: calc(13.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(19.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(19.2rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(19.2rem * var(--tw-space-x-reverse));
    margin-left: calc(19.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20.8rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20.8rem * var(--tw-space-x-reverse));
    margin-left: calc(20.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(21.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(21.6rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(21.6rem * var(--tw-space-x-reverse));
    margin-left: calc(21.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(22.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(22.4rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(22.4rem * var(--tw-space-x-reverse));
    margin-left: calc(22.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(25.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(25.6rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(25.6rem * var(--tw-space-x-reverse));
    margin-left: calc(25.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(28.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(28.8rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(28.8rem * var(--tw-space-x-reverse));
    margin-left: calc(28.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(32rem * var(--tw-space-x-reverse));
    margin-left: calc(32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(36rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(36rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(36rem * var(--tw-space-x-reverse));
    margin-left: calc(36rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(38.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(38.4rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(38.4rem * var(--tw-space-x-reverse));
    margin-left: calc(38.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(40rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(40rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(40rem * var(--tw-space-x-reverse));
    margin-left: calc(40rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(51.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(51.2rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(51.2rem * var(--tw-space-x-reverse));
    margin-left: calc(51.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(64rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(64rem * var(--tw-space-x-reverse));
    margin-left: calc(64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(32rem * var(--tw-space-x-reverse));
    margin-left: calc(32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(48rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(48rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(48rem * var(--tw-space-x-reverse));
    margin-left: calc(48rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(64rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(64rem * var(--tw-space-x-reverse));
    margin-left: calc(64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(80rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(80rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(80rem * var(--tw-space-x-reverse));
    margin-left: calc(80rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(96rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(96rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(96rem * var(--tw-space-x-reverse));
    margin-left: calc(96rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(112rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(112rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(112rem * var(--tw-space-x-reverse));
    margin-left: calc(112rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(128rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(128rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(128rem * var(--tw-space-x-reverse));
    margin-left: calc(128rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(144rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(144rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(144rem * var(--tw-space-x-reverse));
    margin-left: calc(144rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(160rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(160rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(160rem * var(--tw-space-x-reverse));
    margin-left: calc(160rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse))
  }

  .print\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.05rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.05rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.05rem * var(--tw-space-x-reverse));
    margin-left: calc(0.05rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.15rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.15rem * var(--tw-space-x-reverse));
    margin-left: calc(0.15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.35rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.35rem * var(--tw-space-y-reverse))
  }

  .print\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.35rem * var(--tw-space-x-reverse));
    margin-left: calc(0.35rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.1rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.1rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.2rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.3rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.3rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.4rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.6rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.7rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.7rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.8rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.9rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.9rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.2rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.4rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.6rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.4rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.8rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.2rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.6rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4.4rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4.8rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-4.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5.2rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-5.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5.6rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-5.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6.4rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-6.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6.8rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-6.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7.2rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-7.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7.6rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-7.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8.4rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-8.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8.8rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9.2rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-9.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9.6rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-9.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12.8rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-12.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13.6rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-13.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-19.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-19.2rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-19.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-19.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20.8rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-20.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-21.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-21.6rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-21.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-21.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-22.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-22.4rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-22.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-22.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-25.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-25.6rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-25.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-25.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-28.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-28.8rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-28.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-28.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-32rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-32rem * var(--tw-space-x-reverse));
    margin-left: calc(-32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-36rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-36rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-36rem * var(--tw-space-x-reverse));
    margin-left: calc(-36rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-38.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-38.4rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-38.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-38.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-40rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-40rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-40rem * var(--tw-space-x-reverse));
    margin-left: calc(-40rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-51.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-51.2rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-51.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-51.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-64rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-64rem * var(--tw-space-x-reverse));
    margin-left: calc(-64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-32rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-32rem * var(--tw-space-x-reverse));
    margin-left: calc(-32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-48rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-48rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-48rem * var(--tw-space-x-reverse));
    margin-left: calc(-48rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-64rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-64rem * var(--tw-space-x-reverse));
    margin-left: calc(-64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-80rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-80rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-80rem * var(--tw-space-x-reverse));
    margin-left: calc(-80rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-96rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-96rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-96rem * var(--tw-space-x-reverse));
    margin-left: calc(-96rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-112rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-112rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-112rem * var(--tw-space-x-reverse));
    margin-left: calc(-112rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-128rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-128rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-128rem * var(--tw-space-x-reverse));
    margin-left: calc(-128rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-144rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-144rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-144rem * var(--tw-space-x-reverse));
    margin-left: calc(-144rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-160rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-160rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-160rem * var(--tw-space-x-reverse));
    margin-left: calc(-160rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse))
  }

  .print\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.05rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.05rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.05rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.05rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.15rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.15rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.35rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.35rem * var(--tw-space-y-reverse))
  }

  .print\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.35rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.35rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .print\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1
  }

  .print\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1
  }

  .print\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse))
  }

  .print\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .print\:divide-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  .print\:divide-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .print\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse))
  }

  .print\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .print\:divide-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(3px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(3px * var(--tw-divide-y-reverse))
  }

  .print\:divide-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(3px * var(--tw-divide-x-reverse));
    border-left-width: calc(3px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .print\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse))
  }

  .print\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .print\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse))
  }

  .print\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .print\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  .print\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .print\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1
  }

  .print\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1
  }

  .print\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent
  }

  .print\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor
  }

  .print\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-divide-opacity))
  }

  .print\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity))
  }

  .print\:divide-grey-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-divide-opacity))
  }

  .print\:divide-grey-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-divide-opacity))
  }

  .print\:divide-grey-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-divide-opacity))
  }

  .print\:divide-grey-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-divide-opacity))
  }

  .print\:divide-grey-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-divide-opacity))
  }

  .print\:divide-grey-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .print\:divide-grey-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-divide-opacity))
  }

  .print\:divide-grey-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .print\:divide-grey-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-divide-opacity))
  }

  .print\:divide-grey-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity))
  }

  .print\:divide-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .print\:divide-grey-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-divide-opacity))
  }

  .print\:divide-grey-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-divide-opacity))
  }

  .print\:divide-grey-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-divide-opacity))
  }

  .print\:divide-grey-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .print\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-divide-opacity))
  }

  .print\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-divide-opacity))
  }

  .print\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-divide-opacity))
  }

  .print\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-divide-opacity))
  }

  .print\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-divide-opacity))
  }

  .print\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .print\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-divide-opacity))
  }

  .print\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .print\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-divide-opacity))
  }

  .print\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity))
  }

  .print\:divide-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .print\:divide-gray-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .print\:divide-gray-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-divide-opacity))
  }

  .print\:divide-gray-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-divide-opacity))
  }

  .print\:divide-gray-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-divide-opacity))
  }

  .print\:divide-gray-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .print\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-divide-opacity))
  }

  .print\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-divide-opacity))
  }

  .print\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-divide-opacity))
  }

  .print\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-divide-opacity))
  }

  .print\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-divide-opacity))
  }

  .print\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-divide-opacity))
  }

  .print\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-divide-opacity))
  }

  .print\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-divide-opacity))
  }

  .print\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-divide-opacity))
  }

  .print\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-divide-opacity))
  }

  .print\:divide-red > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-divide-opacity))
  }

  .print\:divide-red-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-divide-opacity))
  }

  .print\:divide-red-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-divide-opacity))
  }

  .print\:divide-red-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-divide-opacity))
  }

  .print\:divide-red-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-divide-opacity))
  }

  .print\:divide-orange-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-divide-opacity))
  }

  .print\:divide-orange-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-divide-opacity))
  }

  .print\:divide-orange-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-divide-opacity))
  }

  .print\:divide-orange-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-divide-opacity))
  }

  .print\:divide-orange-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-divide-opacity))
  }

  .print\:divide-orange-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-divide-opacity))
  }

  .print\:divide-orange-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-divide-opacity))
  }

  .print\:divide-orange-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-divide-opacity))
  }

  .print\:divide-orange-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-divide-opacity))
  }

  .print\:divide-orange-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-divide-opacity))
  }

  .print\:divide-orange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-divide-opacity))
  }

  .print\:divide-orange-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-divide-opacity))
  }

  .print\:divide-orange-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-divide-opacity))
  }

  .print\:divide-orange-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-divide-opacity))
  }

  .print\:divide-orange-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-divide-opacity))
  }

  .print\:divide-deep-orange-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-divide-opacity))
  }

  .print\:divide-deep-orange-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-divide-opacity))
  }

  .print\:divide-deep-orange-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-divide-opacity))
  }

  .print\:divide-deep-orange-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-divide-opacity))
  }

  .print\:divide-deep-orange-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-divide-opacity))
  }

  .print\:divide-deep-orange-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-divide-opacity))
  }

  .print\:divide-deep-orange-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-divide-opacity))
  }

  .print\:divide-deep-orange-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-divide-opacity))
  }

  .print\:divide-deep-orange-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-divide-opacity))
  }

  .print\:divide-deep-orange-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-divide-opacity))
  }

  .print\:divide-deep-orange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-divide-opacity))
  }

  .print\:divide-deep-orange-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-divide-opacity))
  }

  .print\:divide-deep-orange-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-divide-opacity))
  }

  .print\:divide-deep-orange-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-divide-opacity))
  }

  .print\:divide-deep-orange-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-divide-opacity))
  }

  .print\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-divide-opacity))
  }

  .print\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-divide-opacity))
  }

  .print\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-divide-opacity))
  }

  .print\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-divide-opacity))
  }

  .print\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-divide-opacity))
  }

  .print\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-divide-opacity))
  }

  .print\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-divide-opacity))
  }

  .print\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-divide-opacity))
  }

  .print\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-divide-opacity))
  }

  .print\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-divide-opacity))
  }

  .print\:divide-yellow > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-divide-opacity))
  }

  .print\:divide-yellow-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-divide-opacity))
  }

  .print\:divide-yellow-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-divide-opacity))
  }

  .print\:divide-yellow-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-divide-opacity))
  }

  .print\:divide-yellow-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-divide-opacity))
  }

  .print\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-divide-opacity))
  }

  .print\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-divide-opacity))
  }

  .print\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-divide-opacity))
  }

  .print\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-divide-opacity))
  }

  .print\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-divide-opacity))
  }

  .print\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-divide-opacity))
  }

  .print\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-divide-opacity))
  }

  .print\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-divide-opacity))
  }

  .print\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-divide-opacity))
  }

  .print\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-divide-opacity))
  }

  .print\:divide-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-divide-opacity))
  }

  .print\:divide-green-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-divide-opacity))
  }

  .print\:divide-green-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-divide-opacity))
  }

  .print\:divide-green-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-divide-opacity))
  }

  .print\:divide-green-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-divide-opacity))
  }

  .print\:divide-light-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-divide-opacity))
  }

  .print\:divide-light-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-divide-opacity))
  }

  .print\:divide-light-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-divide-opacity))
  }

  .print\:divide-light-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-divide-opacity))
  }

  .print\:divide-light-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-divide-opacity))
  }

  .print\:divide-light-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-divide-opacity))
  }

  .print\:divide-light-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-divide-opacity))
  }

  .print\:divide-light-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-divide-opacity))
  }

  .print\:divide-light-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-divide-opacity))
  }

  .print\:divide-light-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-divide-opacity))
  }

  .print\:divide-light-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-divide-opacity))
  }

  .print\:divide-light-green-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-divide-opacity))
  }

  .print\:divide-light-green-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-divide-opacity))
  }

  .print\:divide-light-green-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-divide-opacity))
  }

  .print\:divide-light-green-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-divide-opacity))
  }

  .print\:divide-teal-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-divide-opacity))
  }

  .print\:divide-teal-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-divide-opacity))
  }

  .print\:divide-teal-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-divide-opacity))
  }

  .print\:divide-teal-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-divide-opacity))
  }

  .print\:divide-teal-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-divide-opacity))
  }

  .print\:divide-teal-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-divide-opacity))
  }

  .print\:divide-teal-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-divide-opacity))
  }

  .print\:divide-teal-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-divide-opacity))
  }

  .print\:divide-teal-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-divide-opacity))
  }

  .print\:divide-teal-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-divide-opacity))
  }

  .print\:divide-teal > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-divide-opacity))
  }

  .print\:divide-teal-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-divide-opacity))
  }

  .print\:divide-teal-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-divide-opacity))
  }

  .print\:divide-teal-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-divide-opacity))
  }

  .print\:divide-teal-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-divide-opacity))
  }

  .print\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-divide-opacity))
  }

  .print\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-divide-opacity))
  }

  .print\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-divide-opacity))
  }

  .print\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-divide-opacity))
  }

  .print\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-divide-opacity))
  }

  .print\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-divide-opacity))
  }

  .print\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-divide-opacity))
  }

  .print\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-divide-opacity))
  }

  .print\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-divide-opacity))
  }

  .print\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-divide-opacity))
  }

  .print\:divide-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-divide-opacity))
  }

  .print\:divide-blue-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-divide-opacity))
  }

  .print\:divide-blue-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-divide-opacity))
  }

  .print\:divide-blue-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-divide-opacity))
  }

  .print\:divide-blue-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-divide-opacity))
  }

  .print\:divide-light-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-divide-opacity))
  }

  .print\:divide-light-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-divide-opacity))
  }

  .print\:divide-light-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-divide-opacity))
  }

  .print\:divide-light-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-divide-opacity))
  }

  .print\:divide-light-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-divide-opacity))
  }

  .print\:divide-light-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-divide-opacity))
  }

  .print\:divide-light-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-divide-opacity))
  }

  .print\:divide-light-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-divide-opacity))
  }

  .print\:divide-light-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-divide-opacity))
  }

  .print\:divide-light-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-divide-opacity))
  }

  .print\:divide-light-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-divide-opacity))
  }

  .print\:divide-light-blue-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-divide-opacity))
  }

  .print\:divide-light-blue-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-divide-opacity))
  }

  .print\:divide-light-blue-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-divide-opacity))
  }

  .print\:divide-light-blue-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-divide-opacity))
  }

  .print\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-divide-opacity))
  }

  .print\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-divide-opacity))
  }

  .print\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-divide-opacity))
  }

  .print\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-divide-opacity))
  }

  .print\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-divide-opacity))
  }

  .print\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-divide-opacity))
  }

  .print\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-divide-opacity))
  }

  .print\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-divide-opacity))
  }

  .print\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-divide-opacity))
  }

  .print\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-divide-opacity))
  }

  .print\:divide-indigo > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-divide-opacity))
  }

  .print\:divide-indigo-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-divide-opacity))
  }

  .print\:divide-indigo-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-divide-opacity))
  }

  .print\:divide-indigo-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-divide-opacity))
  }

  .print\:divide-indigo-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-divide-opacity))
  }

  .print\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-divide-opacity))
  }

  .print\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-divide-opacity))
  }

  .print\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-divide-opacity))
  }

  .print\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-divide-opacity))
  }

  .print\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-divide-opacity))
  }

  .print\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-divide-opacity))
  }

  .print\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-divide-opacity))
  }

  .print\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-divide-opacity))
  }

  .print\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-divide-opacity))
  }

  .print\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-divide-opacity))
  }

  .print\:divide-purple > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-divide-opacity))
  }

  .print\:divide-purple-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-divide-opacity))
  }

  .print\:divide-purple-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-divide-opacity))
  }

  .print\:divide-purple-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-divide-opacity))
  }

  .print\:divide-purple-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-divide-opacity))
  }

  .print\:divide-deep-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-divide-opacity))
  }

  .print\:divide-deep-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-divide-opacity))
  }

  .print\:divide-deep-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-divide-opacity))
  }

  .print\:divide-deep-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-divide-opacity))
  }

  .print\:divide-deep-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-divide-opacity))
  }

  .print\:divide-deep-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-divide-opacity))
  }

  .print\:divide-deep-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-divide-opacity))
  }

  .print\:divide-deep-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-divide-opacity))
  }

  .print\:divide-deep-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-divide-opacity))
  }

  .print\:divide-deep-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-divide-opacity))
  }

  .print\:divide-deep-purple > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-divide-opacity))
  }

  .print\:divide-deep-purple-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-divide-opacity))
  }

  .print\:divide-deep-purple-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-divide-opacity))
  }

  .print\:divide-deep-purple-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-divide-opacity))
  }

  .print\:divide-deep-purple-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-divide-opacity))
  }

  .print\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-divide-opacity))
  }

  .print\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-divide-opacity))
  }

  .print\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-divide-opacity))
  }

  .print\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-divide-opacity))
  }

  .print\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-divide-opacity))
  }

  .print\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-divide-opacity))
  }

  .print\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-divide-opacity))
  }

  .print\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-divide-opacity))
  }

  .print\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-divide-opacity))
  }

  .print\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-divide-opacity))
  }

  .print\:divide-pink > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-divide-opacity))
  }

  .print\:divide-pink-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-divide-opacity))
  }

  .print\:divide-pink-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-divide-opacity))
  }

  .print\:divide-pink-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-divide-opacity))
  }

  .print\:divide-pink-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-divide-opacity))
  }

  .print\:divide-lime-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-divide-opacity))
  }

  .print\:divide-lime-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-divide-opacity))
  }

  .print\:divide-lime-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-divide-opacity))
  }

  .print\:divide-lime-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-divide-opacity))
  }

  .print\:divide-lime-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-divide-opacity))
  }

  .print\:divide-lime-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-divide-opacity))
  }

  .print\:divide-lime-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-divide-opacity))
  }

  .print\:divide-lime-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-divide-opacity))
  }

  .print\:divide-lime-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-divide-opacity))
  }

  .print\:divide-lime-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-divide-opacity))
  }

  .print\:divide-lime > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-divide-opacity))
  }

  .print\:divide-lime-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-divide-opacity))
  }

  .print\:divide-lime-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-divide-opacity))
  }

  .print\:divide-lime-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-divide-opacity))
  }

  .print\:divide-lime-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-divide-opacity))
  }

  .print\:divide-amber-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-divide-opacity))
  }

  .print\:divide-amber-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-divide-opacity))
  }

  .print\:divide-amber-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-divide-opacity))
  }

  .print\:divide-amber-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-divide-opacity))
  }

  .print\:divide-amber-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-divide-opacity))
  }

  .print\:divide-amber-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-divide-opacity))
  }

  .print\:divide-amber-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-divide-opacity))
  }

  .print\:divide-amber-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-divide-opacity))
  }

  .print\:divide-amber-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-divide-opacity))
  }

  .print\:divide-amber-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-divide-opacity))
  }

  .print\:divide-amber > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-divide-opacity))
  }

  .print\:divide-amber-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-divide-opacity))
  }

  .print\:divide-amber-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-divide-opacity))
  }

  .print\:divide-amber-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-divide-opacity))
  }

  .print\:divide-amber-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-divide-opacity))
  }

  .print\:divide-brown-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-divide-opacity))
  }

  .print\:divide-brown-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-divide-opacity))
  }

  .print\:divide-brown-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-divide-opacity))
  }

  .print\:divide-brown-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-divide-opacity))
  }

  .print\:divide-brown-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-divide-opacity))
  }

  .print\:divide-brown-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-divide-opacity))
  }

  .print\:divide-brown-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-divide-opacity))
  }

  .print\:divide-brown-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-divide-opacity))
  }

  .print\:divide-brown-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-divide-opacity))
  }

  .print\:divide-brown-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-divide-opacity))
  }

  .print\:divide-brown > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-divide-opacity))
  }

  .print\:divide-brown-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-divide-opacity))
  }

  .print\:divide-brown-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-divide-opacity))
  }

  .print\:divide-brown-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-divide-opacity))
  }

  .print\:divide-brown-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-divide-opacity))
  }

  .print\:divide-blue-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-divide-opacity))
  }

  .print\:divide-blue-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-divide-opacity))
  }

  .print\:divide-blue-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-divide-opacity))
  }

  .print\:divide-blue-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-divide-opacity))
  }

  .print\:divide-blue-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-divide-opacity))
  }

  .print\:divide-blue-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-divide-opacity))
  }

  .print\:divide-blue-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-divide-opacity))
  }

  .print\:divide-blue-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-divide-opacity))
  }

  .print\:divide-blue-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-divide-opacity))
  }

  .print\:divide-blue-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-divide-opacity))
  }

  .print\:divide-blue-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-divide-opacity))
  }

  .print\:divide-blue-gray-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-divide-opacity))
  }

  .print\:divide-blue-gray-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-divide-opacity))
  }

  .print\:divide-blue-gray-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-divide-opacity))
  }

  .print\:divide-blue-gray-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-divide-opacity))
  }

  .print\:divide-cyan-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-divide-opacity))
  }

  .print\:divide-cyan-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-divide-opacity))
  }

  .print\:divide-cyan-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-divide-opacity))
  }

  .print\:divide-cyan-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-divide-opacity))
  }

  .print\:divide-cyan-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-divide-opacity))
  }

  .print\:divide-cyan-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-divide-opacity))
  }

  .print\:divide-cyan-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-divide-opacity))
  }

  .print\:divide-cyan-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-divide-opacity))
  }

  .print\:divide-cyan-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-divide-opacity))
  }

  .print\:divide-cyan-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-divide-opacity))
  }

  .print\:divide-cyan > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-divide-opacity))
  }

  .print\:divide-cyan-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-divide-opacity))
  }

  .print\:divide-cyan-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-divide-opacity))
  }

  .print\:divide-cyan-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-divide-opacity))
  }

  .print\:divide-cyan-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-divide-opacity))
  }

  .print\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid
  }

  .print\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed
  }

  .print\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted
  }

  .print\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double
  }

  .print\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none
  }

  .print\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0
  }

  .print\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05
  }

  .print\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1
  }

  .print\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2
  }

  .print\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25
  }

  .print\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3
  }

  .print\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4
  }

  .print\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5
  }

  .print\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6
  }

  .print\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7
  }

  .print\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75
  }

  .print\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8
  }

  .print\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9
  }

  .print\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95
  }

  .print\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1
  }

  .print\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .print\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .print\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .print\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .print\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .print\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .print\:appearance-none {
    -webkit-appearance: none;
            appearance: none
  }

  .print\:bg-fixed {
    background-attachment: fixed
  }

  .print\:bg-local {
    background-attachment: local
  }

  .print\:bg-scroll {
    background-attachment: scroll
  }

  .print\:bg-clip-border {
    background-clip: border-box
  }

  .print\:bg-clip-padding {
    background-clip: padding-box
  }

  .print\:bg-clip-content {
    background-clip: content-box
  }

  .print\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text
  }

  .print\:bg-transparent {
    background-color: transparent
  }

  .print\:bg-current {
    background-color: currentColor
  }

  .print\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .print\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .print\:bg-grey-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .print\:bg-grey-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .print\:bg-grey-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .print\:bg-grey-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .print\:bg-grey-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .print\:bg-grey-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:bg-grey-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .print\:bg-grey-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:bg-grey-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .print\:bg-grey-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .print\:bg-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:bg-grey-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .print\:bg-grey-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .print\:bg-grey-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .print\:bg-grey-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .print\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .print\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .print\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .print\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .print\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .print\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .print\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .print\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:bg-gray-hover {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .print\:bg-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .print\:bg-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .print\:bg-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .print\:bg-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .print\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .print\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .print\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .print\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .print\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .print\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .print\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .print\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .print\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .print\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .print\:bg-red-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .print\:bg-red-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .print\:bg-red-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .print\:bg-red-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .print\:bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .print\:bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .print\:bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .print\:bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .print\:bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .print\:bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .print\:bg-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .print\:bg-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .print\:bg-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .print\:bg-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .print\:bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .print\:bg-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .print\:bg-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .print\:bg-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .print\:bg-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .print\:bg-deep-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .print\:bg-deep-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .print\:bg-deep-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .print\:bg-deep-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .print\:bg-deep-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .print\:bg-deep-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .print\:bg-deep-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .print\:bg-deep-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .print\:bg-deep-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .print\:bg-deep-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .print\:bg-deep-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .print\:bg-deep-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .print\:bg-deep-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .print\:bg-deep-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .print\:bg-deep-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .print\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .print\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .print\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .print\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .print\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .print\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .print\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .print\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .print\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .print\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .print\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .print\:bg-yellow-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .print\:bg-yellow-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .print\:bg-yellow-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .print\:bg-yellow-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .print\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .print\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .print\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .print\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .print\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .print\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .print\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .print\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .print\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .print\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .print\:bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .print\:bg-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .print\:bg-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .print\:bg-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .print\:bg-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .print\:bg-light-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .print\:bg-light-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .print\:bg-light-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .print\:bg-light-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .print\:bg-light-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .print\:bg-light-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .print\:bg-light-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .print\:bg-light-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .print\:bg-light-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .print\:bg-light-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .print\:bg-light-green {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .print\:bg-light-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .print\:bg-light-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .print\:bg-light-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .print\:bg-light-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .print\:bg-teal-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .print\:bg-teal-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .print\:bg-teal-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .print\:bg-teal-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .print\:bg-teal-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .print\:bg-teal-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .print\:bg-teal-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .print\:bg-teal-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .print\:bg-teal-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .print\:bg-teal-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .print\:bg-teal {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .print\:bg-teal-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .print\:bg-teal-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .print\:bg-teal-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .print\:bg-teal-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .print\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .print\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .print\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .print\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .print\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .print\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .print\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .print\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .print\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .print\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .print\:bg-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .print\:bg-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .print\:bg-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .print\:bg-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .print\:bg-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .print\:bg-light-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .print\:bg-light-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .print\:bg-light-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .print\:bg-light-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .print\:bg-light-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .print\:bg-light-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .print\:bg-light-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .print\:bg-light-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .print\:bg-light-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .print\:bg-light-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .print\:bg-light-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .print\:bg-light-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .print\:bg-light-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .print\:bg-light-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .print\:bg-light-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .print\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .print\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .print\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .print\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .print\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .print\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .print\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .print\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .print\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .print\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .print\:bg-indigo {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .print\:bg-indigo-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .print\:bg-indigo-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .print\:bg-indigo-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .print\:bg-indigo-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .print\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .print\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .print\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .print\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .print\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .print\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .print\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .print\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .print\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .print\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .print\:bg-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .print\:bg-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .print\:bg-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .print\:bg-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .print\:bg-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .print\:bg-deep-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .print\:bg-deep-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .print\:bg-deep-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .print\:bg-deep-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .print\:bg-deep-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .print\:bg-deep-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .print\:bg-deep-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .print\:bg-deep-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .print\:bg-deep-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .print\:bg-deep-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .print\:bg-deep-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .print\:bg-deep-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .print\:bg-deep-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .print\:bg-deep-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .print\:bg-deep-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .print\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .print\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .print\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .print\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .print\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .print\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .print\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .print\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .print\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .print\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .print\:bg-pink {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .print\:bg-pink-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .print\:bg-pink-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .print\:bg-pink-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .print\:bg-pink-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .print\:bg-lime-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .print\:bg-lime-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .print\:bg-lime-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .print\:bg-lime-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .print\:bg-lime-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .print\:bg-lime-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .print\:bg-lime-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .print\:bg-lime-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .print\:bg-lime-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .print\:bg-lime-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .print\:bg-lime {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .print\:bg-lime-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .print\:bg-lime-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .print\:bg-lime-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .print\:bg-lime-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .print\:bg-amber-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .print\:bg-amber-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .print\:bg-amber-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .print\:bg-amber-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .print\:bg-amber-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .print\:bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .print\:bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .print\:bg-amber-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .print\:bg-amber-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .print\:bg-amber-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .print\:bg-amber {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .print\:bg-amber-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .print\:bg-amber-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .print\:bg-amber-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .print\:bg-amber-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .print\:bg-brown-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .print\:bg-brown-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .print\:bg-brown-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .print\:bg-brown-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .print\:bg-brown-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .print\:bg-brown-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .print\:bg-brown-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .print\:bg-brown-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .print\:bg-brown-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .print\:bg-brown-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .print\:bg-brown {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .print\:bg-brown-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .print\:bg-brown-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .print\:bg-brown-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .print\:bg-brown-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .print\:bg-blue-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .print\:bg-blue-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .print\:bg-blue-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .print\:bg-blue-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .print\:bg-blue-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .print\:bg-blue-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .print\:bg-blue-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .print\:bg-blue-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .print\:bg-blue-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .print\:bg-blue-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .print\:bg-blue-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .print\:bg-blue-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .print\:bg-blue-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .print\:bg-blue-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .print\:bg-blue-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .print\:bg-cyan-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .print\:bg-cyan-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .print\:bg-cyan-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .print\:bg-cyan-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .print\:bg-cyan-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .print\:bg-cyan-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .print\:bg-cyan-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .print\:bg-cyan-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .print\:bg-cyan-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .print\:bg-cyan-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .print\:bg-cyan {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .print\:bg-cyan-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .print\:bg-cyan-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .print\:bg-cyan-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .print\:bg-cyan-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-transparent {
    background-color: transparent
  }

  .group:hover .print\:group-hover\:bg-current {
    background-color: currentColor
  }

  .group:hover .print\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-grey-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-grey-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-grey-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-grey-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-grey-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-grey-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-grey-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-grey-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-grey-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-grey-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-grey-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-grey-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-grey-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-grey-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-gray-hover {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .group:hover .print\:group-hover\:bg-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-red-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-red-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-red-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-red-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-yellow-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-yellow-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-yellow-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-yellow-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-green {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-teal-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-teal-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-teal-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-teal-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-teal-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-teal-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-teal-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-teal-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-teal-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-teal-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-teal {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-teal-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-teal-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-teal-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-teal-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-light-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-indigo {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-indigo-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-indigo-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-indigo-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-indigo-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-purple {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-deep-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-pink {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-pink-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-pink-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-pink-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-pink-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-lime-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-lime-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-lime-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-lime-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-lime-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-lime-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-lime-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-lime-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-lime-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-lime-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-lime {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-lime-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-lime-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-lime-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-lime-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-amber-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-amber-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-amber-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-amber-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-amber-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-amber-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-amber-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-amber-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-amber {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-amber-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-amber-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-amber-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-amber-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-brown-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-brown-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-brown-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-brown-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-brown-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-brown-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-brown-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-brown-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-brown-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-brown-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-brown {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-brown-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-brown-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-brown-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-brown-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-blue-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-cyan-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-cyan-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-cyan-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-cyan-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-cyan-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-cyan-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-cyan-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-cyan-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-cyan-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-cyan-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-cyan {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-cyan-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-cyan-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-cyan-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .group:hover .print\:group-hover\:bg-cyan-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-transparent:focus-within {
    background-color: transparent
  }

  .print\:focus-within\:bg-current:focus-within {
    background-color: currentColor
  }

  .print\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-grey-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-grey-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-grey-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-grey-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-grey-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-grey-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-grey-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-grey-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-grey-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-grey-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-grey-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-grey-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-grey-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-grey-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-gray-hover:focus-within {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .print\:focus-within\:bg-gray-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-gray-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-gray-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-gray-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-red:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-red-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-red-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-red-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-red-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-orange-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-orange-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-orange-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-orange-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-orange-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-orange-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-orange-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-orange-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-orange-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-orange-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-orange:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-orange-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-orange-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-orange-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-orange-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-orange-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-orange-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-orange-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-orange-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-orange-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-orange-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-orange-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-orange-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-orange-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-orange-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-orange:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-orange-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-orange-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-orange-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-orange-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-yellow:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-yellow-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-yellow-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-yellow-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-yellow-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-green-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-green-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-green-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-green-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-green-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-green-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-green-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-green-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-teal-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-teal-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-teal-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-teal-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-teal-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-teal-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-teal-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-teal-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-teal-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-teal-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-teal:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-teal-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-teal-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-teal-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-teal-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-blue-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-blue-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-blue-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-light-blue-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-indigo:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-indigo-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-indigo-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-indigo-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-indigo-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-purple:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-purple-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-purple-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-purple-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-purple-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-purple:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-purple-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-purple-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-purple-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-deep-purple-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-pink:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-pink-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-pink-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-pink-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-pink-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-lime-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-lime-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-lime-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-lime-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-lime-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-lime-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-lime-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-lime-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-lime-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-lime-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-lime:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-lime-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-lime-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-lime-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-lime-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-amber-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-amber-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-amber-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-amber-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-amber-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-amber-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-amber-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-amber-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-amber-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-amber-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-amber:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-amber-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-amber-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-amber-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-amber-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-brown-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-brown-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-brown-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-brown-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-brown-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-brown-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-brown-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-brown-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-brown-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-brown-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-brown:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-brown-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-brown-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-brown-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-brown-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-gray-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-gray-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-gray-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-blue-gray-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-cyan-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-cyan-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-cyan-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-cyan-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-cyan-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-cyan-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-cyan-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-cyan-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-cyan-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-cyan-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-cyan:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-cyan-A100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-cyan-A200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-cyan-A400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .print\:focus-within\:bg-cyan-A700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-transparent:hover {
    background-color: transparent
  }

  .print\:hover\:bg-current:hover {
    background-color: currentColor
  }

  .print\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-grey-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-grey-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-grey-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-grey-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-grey-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-grey-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-grey-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-grey-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-grey-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-grey-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-grey-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-grey-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-grey-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-grey-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-gray-hover:hover {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .print\:hover\:bg-gray-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-gray-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-gray-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-gray-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-red:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-red-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-red-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-red-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-red-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-orange-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-orange-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-orange-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-orange-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-orange-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-orange-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-orange-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-orange-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-orange-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-orange-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-orange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-orange-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-orange-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-orange-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-orange-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-orange-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-orange-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-orange-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-orange-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-orange-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-orange-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-orange-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-orange-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-orange-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-orange-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-orange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-orange-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-orange-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-orange-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-orange-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-yellow:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-yellow-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-yellow-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-yellow-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-yellow-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-green-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-green-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-green-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-green-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-green-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-green-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-green-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-green-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-teal-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-teal-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-teal-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-teal-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-teal-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-teal-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-teal-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-teal-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-teal-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-teal-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-teal:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-teal-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-teal-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-teal-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-teal-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-blue-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-blue-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-blue-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-light-blue-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-indigo:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-indigo-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-indigo-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-indigo-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-indigo-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-purple:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-purple-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-purple-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-purple-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-purple-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-purple:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-purple-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-purple-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-purple-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-deep-purple-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-pink:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-pink-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-pink-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-pink-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-pink-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-lime-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-lime-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-lime-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-lime-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-lime-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-lime-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-lime-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-lime-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-lime-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-lime-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-lime:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-lime-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-lime-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-lime-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-lime-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-amber-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-amber-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-amber-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-amber-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-amber-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-amber-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-amber-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-amber-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-amber-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-amber-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-amber:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-amber-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-amber-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-amber-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-amber-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-brown-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-brown-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-brown-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-brown-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-brown-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-brown-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-brown-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-brown-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-brown-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-brown-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-brown:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-brown-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-brown-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-brown-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-brown-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-gray-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-gray-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-gray-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-blue-gray-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-cyan-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-cyan-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-cyan-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-cyan-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-cyan-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-cyan-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-cyan-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-cyan-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-cyan-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-cyan-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-cyan:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-cyan-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-cyan-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-cyan-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .print\:hover\:bg-cyan-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-transparent:focus {
    background-color: transparent
  }

  .print\:focus\:bg-current:focus {
    background-color: currentColor
  }

  .print\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-grey-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-grey-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-grey-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-grey-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-grey-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-grey-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-grey-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-grey-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-grey-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-grey-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-grey-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-grey-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-grey-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-grey-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-gray-hover:focus {
    background-color: rgba(0, 0, 0, 0.04)
  }

  .print\:focus\:bg-gray-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-gray-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-gray-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-gray-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-red:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-red-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-red-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-red-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-red-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-orange-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-orange-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-orange-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-orange-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-orange-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-orange-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-orange-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-orange-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-orange-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-orange-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-orange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-orange-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-orange-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-orange-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-orange-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-orange-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-orange-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-orange-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-orange-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-orange-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-orange-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-orange-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-orange-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-orange-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-orange-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-orange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-orange-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-orange-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-orange-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-orange-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-yellow:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-yellow-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-yellow-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-yellow-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-yellow-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-green-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-green-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-green-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-green-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-green-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-green-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-green-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-green-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-teal-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-teal-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-teal-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-teal-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-teal-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-teal-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-teal-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-teal-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-teal-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-teal-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-teal:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-teal-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-teal-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-teal-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-teal-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-blue-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-blue-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-blue-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-light-blue-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-indigo:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-indigo-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-indigo-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-indigo-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-indigo-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-purple:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-purple-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-purple-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-purple-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-purple-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-purple:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-purple-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-purple-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-purple-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-deep-purple-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-pink:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-pink-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-pink-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-pink-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-pink-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-lime-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-lime-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-lime-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-lime-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-lime-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-lime-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-lime-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-lime-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-lime-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-lime-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-lime:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-lime-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-lime-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-lime-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-lime-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-amber-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-amber-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-amber-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-amber-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-amber-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-amber-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-amber-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-amber-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-amber-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-amber-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-amber:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-amber-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-amber-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-amber-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-amber-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-brown-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-brown-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-brown-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-brown-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-brown-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-brown-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-brown-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-brown-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-brown-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-brown-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-brown:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-brown-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-brown-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-brown-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-brown-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-gray-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-gray-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-gray-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-blue-gray-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-cyan-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-cyan-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-cyan-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-cyan-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-cyan-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-cyan-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-cyan-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-cyan-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-cyan-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-cyan-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-cyan:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-cyan-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-cyan-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-cyan-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .print\:focus\:bg-cyan-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .print\:bg-none {
    background-image: none
  }

  .print\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .print\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .print\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .print\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .print\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .print\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .print\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .print\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .print\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .print\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .print\:from-black {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .print\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .print\:from-grey-50 {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .print\:from-grey-100 {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .print\:from-grey-200 {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .print\:from-grey-300 {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .print\:from-grey-400 {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .print\:from-grey-500 {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:from-grey-600 {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .print\:from-grey-700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:from-grey-800 {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .print\:from-grey-900 {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .print\:from-grey {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:from-grey-A100 {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .print\:from-grey-A200 {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .print\:from-grey-A400 {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .print\:from-grey-A700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:from-gray-50 {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .print\:from-gray-100 {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .print\:from-gray-200 {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .print\:from-gray-300 {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .print\:from-gray-400 {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .print\:from-gray-500 {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:from-gray-600 {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .print\:from-gray-700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:from-gray-800 {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .print\:from-gray-900 {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .print\:from-gray {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:from-gray-hover {
    --tw-gradient-from: rgba(0, 0, 0, 0.04);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .print\:from-gray-A100 {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .print\:from-gray-A200 {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .print\:from-gray-A400 {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .print\:from-gray-A700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:from-red-50 {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .print\:from-red-100 {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .print\:from-red-200 {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .print\:from-red-300 {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .print\:from-red-400 {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .print\:from-red-500 {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .print\:from-red-600 {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .print\:from-red-700 {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .print\:from-red-800 {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .print\:from-red-900 {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .print\:from-red {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .print\:from-red-A100 {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .print\:from-red-A200 {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .print\:from-red-A400 {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .print\:from-red-A700 {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .print\:from-orange-50 {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .print\:from-orange-100 {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .print\:from-orange-200 {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .print\:from-orange-300 {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .print\:from-orange-400 {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .print\:from-orange-500 {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .print\:from-orange-600 {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .print\:from-orange-700 {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .print\:from-orange-800 {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .print\:from-orange-900 {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .print\:from-orange {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .print\:from-orange-A100 {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .print\:from-orange-A200 {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .print\:from-orange-A400 {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .print\:from-orange-A700 {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .print\:from-deep-orange-50 {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .print\:from-deep-orange-100 {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .print\:from-deep-orange-200 {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .print\:from-deep-orange-300 {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .print\:from-deep-orange-400 {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .print\:from-deep-orange-500 {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .print\:from-deep-orange-600 {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .print\:from-deep-orange-700 {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .print\:from-deep-orange-800 {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .print\:from-deep-orange-900 {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .print\:from-deep-orange {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .print\:from-deep-orange-A100 {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .print\:from-deep-orange-A200 {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .print\:from-deep-orange-A400 {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .print\:from-deep-orange-A700 {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .print\:from-yellow-50 {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .print\:from-yellow-100 {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .print\:from-yellow-200 {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .print\:from-yellow-300 {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .print\:from-yellow-400 {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .print\:from-yellow-500 {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .print\:from-yellow-600 {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .print\:from-yellow-700 {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .print\:from-yellow-800 {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .print\:from-yellow-900 {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .print\:from-yellow {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .print\:from-yellow-A100 {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .print\:from-yellow-A200 {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .print\:from-yellow-A400 {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .print\:from-yellow-A700 {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .print\:from-green-50 {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .print\:from-green-100 {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .print\:from-green-200 {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .print\:from-green-300 {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .print\:from-green-400 {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .print\:from-green-500 {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .print\:from-green-600 {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .print\:from-green-700 {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .print\:from-green-800 {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .print\:from-green-900 {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .print\:from-green {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .print\:from-green-A100 {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .print\:from-green-A200 {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .print\:from-green-A400 {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .print\:from-green-A700 {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .print\:from-light-green-50 {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .print\:from-light-green-100 {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .print\:from-light-green-200 {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .print\:from-light-green-300 {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .print\:from-light-green-400 {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .print\:from-light-green-500 {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .print\:from-light-green-600 {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .print\:from-light-green-700 {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .print\:from-light-green-800 {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .print\:from-light-green-900 {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .print\:from-light-green {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .print\:from-light-green-A100 {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .print\:from-light-green-A200 {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .print\:from-light-green-A400 {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .print\:from-light-green-A700 {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .print\:from-teal-50 {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .print\:from-teal-100 {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .print\:from-teal-200 {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .print\:from-teal-300 {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .print\:from-teal-400 {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .print\:from-teal-500 {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .print\:from-teal-600 {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .print\:from-teal-700 {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .print\:from-teal-800 {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .print\:from-teal-900 {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .print\:from-teal {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .print\:from-teal-A100 {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .print\:from-teal-A200 {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .print\:from-teal-A400 {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .print\:from-teal-A700 {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .print\:from-blue-50 {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .print\:from-blue-100 {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .print\:from-blue-200 {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .print\:from-blue-300 {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .print\:from-blue-400 {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .print\:from-blue-500 {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .print\:from-blue-600 {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .print\:from-blue-700 {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .print\:from-blue-800 {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .print\:from-blue-900 {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .print\:from-blue {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .print\:from-blue-A100 {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .print\:from-blue-A200 {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .print\:from-blue-A400 {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .print\:from-blue-A700 {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .print\:from-light-blue-50 {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .print\:from-light-blue-100 {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .print\:from-light-blue-200 {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .print\:from-light-blue-300 {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .print\:from-light-blue-400 {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .print\:from-light-blue-500 {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .print\:from-light-blue-600 {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .print\:from-light-blue-700 {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .print\:from-light-blue-800 {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .print\:from-light-blue-900 {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .print\:from-light-blue {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .print\:from-light-blue-A100 {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .print\:from-light-blue-A200 {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .print\:from-light-blue-A400 {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .print\:from-light-blue-A700 {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .print\:from-indigo-50 {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .print\:from-indigo-100 {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .print\:from-indigo-200 {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .print\:from-indigo-300 {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .print\:from-indigo-400 {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .print\:from-indigo-500 {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .print\:from-indigo-600 {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .print\:from-indigo-700 {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .print\:from-indigo-800 {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .print\:from-indigo-900 {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .print\:from-indigo {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .print\:from-indigo-A100 {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .print\:from-indigo-A200 {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .print\:from-indigo-A400 {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .print\:from-indigo-A700 {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .print\:from-purple-50 {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .print\:from-purple-100 {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .print\:from-purple-200 {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .print\:from-purple-300 {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .print\:from-purple-400 {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .print\:from-purple-500 {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .print\:from-purple-600 {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .print\:from-purple-700 {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .print\:from-purple-800 {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .print\:from-purple-900 {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .print\:from-purple {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .print\:from-purple-A100 {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .print\:from-purple-A200 {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .print\:from-purple-A400 {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .print\:from-purple-A700 {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .print\:from-deep-purple-50 {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .print\:from-deep-purple-100 {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .print\:from-deep-purple-200 {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .print\:from-deep-purple-300 {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .print\:from-deep-purple-400 {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .print\:from-deep-purple-500 {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .print\:from-deep-purple-600 {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .print\:from-deep-purple-700 {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .print\:from-deep-purple-800 {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .print\:from-deep-purple-900 {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .print\:from-deep-purple {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .print\:from-deep-purple-A100 {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .print\:from-deep-purple-A200 {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .print\:from-deep-purple-A400 {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .print\:from-deep-purple-A700 {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .print\:from-pink-50 {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .print\:from-pink-100 {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .print\:from-pink-200 {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .print\:from-pink-300 {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .print\:from-pink-400 {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .print\:from-pink-500 {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .print\:from-pink-600 {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .print\:from-pink-700 {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .print\:from-pink-800 {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .print\:from-pink-900 {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .print\:from-pink {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .print\:from-pink-A100 {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .print\:from-pink-A200 {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .print\:from-pink-A400 {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .print\:from-pink-A700 {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .print\:from-lime-50 {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .print\:from-lime-100 {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .print\:from-lime-200 {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .print\:from-lime-300 {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .print\:from-lime-400 {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .print\:from-lime-500 {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .print\:from-lime-600 {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .print\:from-lime-700 {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .print\:from-lime-800 {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .print\:from-lime-900 {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .print\:from-lime {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .print\:from-lime-A100 {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .print\:from-lime-A200 {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .print\:from-lime-A400 {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .print\:from-lime-A700 {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .print\:from-amber-50 {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .print\:from-amber-100 {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .print\:from-amber-200 {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .print\:from-amber-300 {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .print\:from-amber-400 {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .print\:from-amber-500 {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .print\:from-amber-600 {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .print\:from-amber-700 {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .print\:from-amber-800 {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .print\:from-amber-900 {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .print\:from-amber {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .print\:from-amber-A100 {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .print\:from-amber-A200 {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .print\:from-amber-A400 {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .print\:from-amber-A700 {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .print\:from-brown-50 {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .print\:from-brown-100 {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .print\:from-brown-200 {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .print\:from-brown-300 {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .print\:from-brown-400 {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .print\:from-brown-500 {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .print\:from-brown-600 {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .print\:from-brown-700 {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .print\:from-brown-800 {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .print\:from-brown-900 {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .print\:from-brown {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .print\:from-brown-A100 {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .print\:from-brown-A200 {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .print\:from-brown-A400 {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .print\:from-brown-A700 {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .print\:from-blue-gray-50 {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .print\:from-blue-gray-100 {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .print\:from-blue-gray-200 {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .print\:from-blue-gray-300 {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .print\:from-blue-gray-400 {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .print\:from-blue-gray-500 {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .print\:from-blue-gray-600 {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .print\:from-blue-gray-700 {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .print\:from-blue-gray-800 {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .print\:from-blue-gray-900 {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .print\:from-blue-gray {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .print\:from-blue-gray-A100 {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .print\:from-blue-gray-A200 {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .print\:from-blue-gray-A400 {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .print\:from-blue-gray-A700 {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .print\:from-cyan-50 {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .print\:from-cyan-100 {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .print\:from-cyan-200 {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .print\:from-cyan-300 {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .print\:from-cyan-400 {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .print\:from-cyan-500 {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .print\:from-cyan-600 {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .print\:from-cyan-700 {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .print\:from-cyan-800 {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .print\:from-cyan-900 {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .print\:from-cyan {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .print\:from-cyan-A100 {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .print\:from-cyan-A200 {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .print\:from-cyan-A400 {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .print\:from-cyan-A700 {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .print\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .print\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .print\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .print\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .print\:via-grey-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .print\:via-grey-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .print\:via-grey-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .print\:via-grey-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .print\:via-grey-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .print\:via-grey-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:via-grey-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .print\:via-grey-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:via-grey-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .print\:via-grey-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .print\:via-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:via-grey-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .print\:via-grey-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .print\:via-grey-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .print\:via-grey-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .print\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .print\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .print\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .print\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .print\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .print\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .print\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .print\:via-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:via-gray-hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .print\:via-gray-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .print\:via-gray-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .print\:via-gray-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .print\:via-gray-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .print\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .print\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .print\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .print\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .print\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .print\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .print\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .print\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .print\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .print\:via-red {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .print\:via-red-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .print\:via-red-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .print\:via-red-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .print\:via-red-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .print\:via-orange-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .print\:via-orange-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .print\:via-orange-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .print\:via-orange-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .print\:via-orange-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .print\:via-orange-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .print\:via-orange-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .print\:via-orange-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .print\:via-orange-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .print\:via-orange-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .print\:via-orange {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .print\:via-orange-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .print\:via-orange-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .print\:via-orange-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .print\:via-orange-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .print\:via-deep-orange-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .print\:via-deep-orange-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .print\:via-deep-orange-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .print\:via-deep-orange-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .print\:via-deep-orange-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .print\:via-deep-orange-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .print\:via-deep-orange-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .print\:via-deep-orange-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .print\:via-deep-orange-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .print\:via-deep-orange-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .print\:via-deep-orange {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .print\:via-deep-orange-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .print\:via-deep-orange-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .print\:via-deep-orange-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .print\:via-deep-orange-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .print\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .print\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .print\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .print\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .print\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .print\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .print\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .print\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .print\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .print\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .print\:via-yellow {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .print\:via-yellow-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .print\:via-yellow-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .print\:via-yellow-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .print\:via-yellow-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .print\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .print\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .print\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .print\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .print\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .print\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .print\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .print\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .print\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .print\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .print\:via-green {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .print\:via-green-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .print\:via-green-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .print\:via-green-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .print\:via-green-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .print\:via-light-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .print\:via-light-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .print\:via-light-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .print\:via-light-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .print\:via-light-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .print\:via-light-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .print\:via-light-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .print\:via-light-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .print\:via-light-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .print\:via-light-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .print\:via-light-green {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .print\:via-light-green-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .print\:via-light-green-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .print\:via-light-green-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .print\:via-light-green-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .print\:via-teal-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .print\:via-teal-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .print\:via-teal-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .print\:via-teal-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .print\:via-teal-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .print\:via-teal-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .print\:via-teal-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .print\:via-teal-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .print\:via-teal-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .print\:via-teal-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .print\:via-teal {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .print\:via-teal-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .print\:via-teal-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .print\:via-teal-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .print\:via-teal-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .print\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .print\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .print\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .print\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .print\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .print\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .print\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .print\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .print\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .print\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .print\:via-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .print\:via-blue-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .print\:via-blue-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .print\:via-blue-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .print\:via-blue-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .print\:via-light-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .print\:via-light-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .print\:via-light-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .print\:via-light-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .print\:via-light-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .print\:via-light-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .print\:via-light-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .print\:via-light-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .print\:via-light-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .print\:via-light-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .print\:via-light-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .print\:via-light-blue-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .print\:via-light-blue-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .print\:via-light-blue-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .print\:via-light-blue-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .print\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .print\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .print\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .print\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .print\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .print\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .print\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .print\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .print\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .print\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .print\:via-indigo {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .print\:via-indigo-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .print\:via-indigo-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .print\:via-indigo-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .print\:via-indigo-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .print\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .print\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .print\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .print\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .print\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .print\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .print\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .print\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .print\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .print\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .print\:via-purple {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .print\:via-purple-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .print\:via-purple-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .print\:via-purple-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .print\:via-purple-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .print\:via-deep-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .print\:via-deep-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .print\:via-deep-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .print\:via-deep-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .print\:via-deep-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .print\:via-deep-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .print\:via-deep-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .print\:via-deep-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .print\:via-deep-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .print\:via-deep-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .print\:via-deep-purple {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .print\:via-deep-purple-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .print\:via-deep-purple-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .print\:via-deep-purple-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .print\:via-deep-purple-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .print\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .print\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .print\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .print\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .print\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .print\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .print\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .print\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .print\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .print\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .print\:via-pink {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .print\:via-pink-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .print\:via-pink-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .print\:via-pink-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .print\:via-pink-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .print\:via-lime-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .print\:via-lime-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .print\:via-lime-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .print\:via-lime-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .print\:via-lime-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .print\:via-lime-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .print\:via-lime-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .print\:via-lime-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .print\:via-lime-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .print\:via-lime-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .print\:via-lime {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .print\:via-lime-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .print\:via-lime-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .print\:via-lime-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .print\:via-lime-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .print\:via-amber-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .print\:via-amber-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .print\:via-amber-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .print\:via-amber-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .print\:via-amber-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .print\:via-amber-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .print\:via-amber-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .print\:via-amber-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .print\:via-amber-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .print\:via-amber-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .print\:via-amber {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .print\:via-amber-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .print\:via-amber-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .print\:via-amber-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .print\:via-amber-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .print\:via-brown-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .print\:via-brown-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .print\:via-brown-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .print\:via-brown-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .print\:via-brown-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .print\:via-brown-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .print\:via-brown-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .print\:via-brown-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .print\:via-brown-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .print\:via-brown-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .print\:via-brown {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .print\:via-brown-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .print\:via-brown-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .print\:via-brown-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .print\:via-brown-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .print\:via-blue-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .print\:via-blue-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .print\:via-blue-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .print\:via-blue-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .print\:via-blue-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .print\:via-blue-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .print\:via-blue-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .print\:via-blue-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .print\:via-blue-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .print\:via-blue-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .print\:via-blue-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .print\:via-blue-gray-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .print\:via-blue-gray-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .print\:via-blue-gray-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .print\:via-blue-gray-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .print\:via-cyan-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .print\:via-cyan-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .print\:via-cyan-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .print\:via-cyan-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .print\:via-cyan-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .print\:via-cyan-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .print\:via-cyan-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .print\:via-cyan-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .print\:via-cyan-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .print\:via-cyan-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .print\:via-cyan {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .print\:via-cyan-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .print\:via-cyan-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .print\:via-cyan-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .print\:via-cyan-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .print\:to-transparent {
    --tw-gradient-to: transparent
  }

  .print\:to-current {
    --tw-gradient-to: currentColor
  }

  .print\:to-black {
    --tw-gradient-to: #22292F
  }

  .print\:to-white {
    --tw-gradient-to: #fff
  }

  .print\:to-grey-50 {
    --tw-gradient-to: #FAFAFA
  }

  .print\:to-grey-100 {
    --tw-gradient-to: #F5F5F5
  }

  .print\:to-grey-200 {
    --tw-gradient-to: #EEEEEE
  }

  .print\:to-grey-300 {
    --tw-gradient-to: #E0E0E0
  }

  .print\:to-grey-400 {
    --tw-gradient-to: #BDBDBD
  }

  .print\:to-grey-500 {
    --tw-gradient-to: #9E9E9E
  }

  .print\:to-grey-600 {
    --tw-gradient-to: #757575
  }

  .print\:to-grey-700 {
    --tw-gradient-to: #616161
  }

  .print\:to-grey-800 {
    --tw-gradient-to: #424242
  }

  .print\:to-grey-900 {
    --tw-gradient-to: #212121
  }

  .print\:to-grey {
    --tw-gradient-to: #9E9E9E
  }

  .print\:to-grey-A100 {
    --tw-gradient-to: #D5D5D5
  }

  .print\:to-grey-A200 {
    --tw-gradient-to: #AAAAAA
  }

  .print\:to-grey-A400 {
    --tw-gradient-to: #303030
  }

  .print\:to-grey-A700 {
    --tw-gradient-to: #616161
  }

  .print\:to-gray-50 {
    --tw-gradient-to: #FAFAFA
  }

  .print\:to-gray-100 {
    --tw-gradient-to: #F5F5F5
  }

  .print\:to-gray-200 {
    --tw-gradient-to: #EEEEEE
  }

  .print\:to-gray-300 {
    --tw-gradient-to: #E0E0E0
  }

  .print\:to-gray-400 {
    --tw-gradient-to: #BDBDBD
  }

  .print\:to-gray-500 {
    --tw-gradient-to: #9E9E9E
  }

  .print\:to-gray-600 {
    --tw-gradient-to: #757575
  }

  .print\:to-gray-700 {
    --tw-gradient-to: #616161
  }

  .print\:to-gray-800 {
    --tw-gradient-to: #424242
  }

  .print\:to-gray-900 {
    --tw-gradient-to: #212121
  }

  .print\:to-gray {
    --tw-gradient-to: #9E9E9E
  }

  .print\:to-gray-hover {
    --tw-gradient-to: rgba(0, 0, 0, 0.04)
  }

  .print\:to-gray-A100 {
    --tw-gradient-to: #D5D5D5
  }

  .print\:to-gray-A200 {
    --tw-gradient-to: #AAAAAA
  }

  .print\:to-gray-A400 {
    --tw-gradient-to: #303030
  }

  .print\:to-gray-A700 {
    --tw-gradient-to: #616161
  }

  .print\:to-red-50 {
    --tw-gradient-to: #FFEBEE
  }

  .print\:to-red-100 {
    --tw-gradient-to: #FFCDD2
  }

  .print\:to-red-200 {
    --tw-gradient-to: #EF9A9A
  }

  .print\:to-red-300 {
    --tw-gradient-to: #E57373
  }

  .print\:to-red-400 {
    --tw-gradient-to: #EF5350
  }

  .print\:to-red-500 {
    --tw-gradient-to: #F44336
  }

  .print\:to-red-600 {
    --tw-gradient-to: #E53935
  }

  .print\:to-red-700 {
    --tw-gradient-to: #D32F2F
  }

  .print\:to-red-800 {
    --tw-gradient-to: #C62828
  }

  .print\:to-red-900 {
    --tw-gradient-to: #B71C1C
  }

  .print\:to-red {
    --tw-gradient-to: #F44336
  }

  .print\:to-red-A100 {
    --tw-gradient-to: #FF8A80
  }

  .print\:to-red-A200 {
    --tw-gradient-to: #FF5252
  }

  .print\:to-red-A400 {
    --tw-gradient-to: #FF1744
  }

  .print\:to-red-A700 {
    --tw-gradient-to: #D50000
  }

  .print\:to-orange-50 {
    --tw-gradient-to: #FFF3E0
  }

  .print\:to-orange-100 {
    --tw-gradient-to: #FFE0B2
  }

  .print\:to-orange-200 {
    --tw-gradient-to: #FFCC80
  }

  .print\:to-orange-300 {
    --tw-gradient-to: #FFB74D
  }

  .print\:to-orange-400 {
    --tw-gradient-to: #FFA726
  }

  .print\:to-orange-500 {
    --tw-gradient-to: #FF9800
  }

  .print\:to-orange-600 {
    --tw-gradient-to: #FB8C00
  }

  .print\:to-orange-700 {
    --tw-gradient-to: #F57C00
  }

  .print\:to-orange-800 {
    --tw-gradient-to: #EF6C00
  }

  .print\:to-orange-900 {
    --tw-gradient-to: #E65100
  }

  .print\:to-orange {
    --tw-gradient-to: #FF9800
  }

  .print\:to-orange-A100 {
    --tw-gradient-to: #FFD180
  }

  .print\:to-orange-A200 {
    --tw-gradient-to: #FFAB40
  }

  .print\:to-orange-A400 {
    --tw-gradient-to: #FF9100
  }

  .print\:to-orange-A700 {
    --tw-gradient-to: #FF6D00
  }

  .print\:to-deep-orange-50 {
    --tw-gradient-to: #FBE9E7
  }

  .print\:to-deep-orange-100 {
    --tw-gradient-to: #FFCCBC
  }

  .print\:to-deep-orange-200 {
    --tw-gradient-to: #FFAB91
  }

  .print\:to-deep-orange-300 {
    --tw-gradient-to: #FF8A65
  }

  .print\:to-deep-orange-400 {
    --tw-gradient-to: #FF7043
  }

  .print\:to-deep-orange-500 {
    --tw-gradient-to: #FF5722
  }

  .print\:to-deep-orange-600 {
    --tw-gradient-to: #F4511E
  }

  .print\:to-deep-orange-700 {
    --tw-gradient-to: #E64A19
  }

  .print\:to-deep-orange-800 {
    --tw-gradient-to: #D84315
  }

  .print\:to-deep-orange-900 {
    --tw-gradient-to: #BF360C
  }

  .print\:to-deep-orange {
    --tw-gradient-to: #FF5722
  }

  .print\:to-deep-orange-A100 {
    --tw-gradient-to: #FF9E80
  }

  .print\:to-deep-orange-A200 {
    --tw-gradient-to: #FF6E40
  }

  .print\:to-deep-orange-A400 {
    --tw-gradient-to: #FF3D00
  }

  .print\:to-deep-orange-A700 {
    --tw-gradient-to: #DD2C00
  }

  .print\:to-yellow-50 {
    --tw-gradient-to: #FFFDE7
  }

  .print\:to-yellow-100 {
    --tw-gradient-to: #FFF9C4
  }

  .print\:to-yellow-200 {
    --tw-gradient-to: #FFF59D
  }

  .print\:to-yellow-300 {
    --tw-gradient-to: #FFF176
  }

  .print\:to-yellow-400 {
    --tw-gradient-to: #FFEE58
  }

  .print\:to-yellow-500 {
    --tw-gradient-to: #FFEB3B
  }

  .print\:to-yellow-600 {
    --tw-gradient-to: #FDD835
  }

  .print\:to-yellow-700 {
    --tw-gradient-to: #FBC02D
  }

  .print\:to-yellow-800 {
    --tw-gradient-to: #F9A825
  }

  .print\:to-yellow-900 {
    --tw-gradient-to: #F57F17
  }

  .print\:to-yellow {
    --tw-gradient-to: #FFEB3B
  }

  .print\:to-yellow-A100 {
    --tw-gradient-to: #FFFF8D
  }

  .print\:to-yellow-A200 {
    --tw-gradient-to: #FFFF00
  }

  .print\:to-yellow-A400 {
    --tw-gradient-to: #FFEA00
  }

  .print\:to-yellow-A700 {
    --tw-gradient-to: #FFD600
  }

  .print\:to-green-50 {
    --tw-gradient-to: #E8F5E9
  }

  .print\:to-green-100 {
    --tw-gradient-to: #C8E6C9
  }

  .print\:to-green-200 {
    --tw-gradient-to: #A5D6A7
  }

  .print\:to-green-300 {
    --tw-gradient-to: #81C784
  }

  .print\:to-green-400 {
    --tw-gradient-to: #66BB6A
  }

  .print\:to-green-500 {
    --tw-gradient-to: #4CAF50
  }

  .print\:to-green-600 {
    --tw-gradient-to: #43A047
  }

  .print\:to-green-700 {
    --tw-gradient-to: #388E3C
  }

  .print\:to-green-800 {
    --tw-gradient-to: #2E7D32
  }

  .print\:to-green-900 {
    --tw-gradient-to: #1B5E20
  }

  .print\:to-green {
    --tw-gradient-to: #4CAF50
  }

  .print\:to-green-A100 {
    --tw-gradient-to: #B9F6CA
  }

  .print\:to-green-A200 {
    --tw-gradient-to: #69F0AE
  }

  .print\:to-green-A400 {
    --tw-gradient-to: #00E676
  }

  .print\:to-green-A700 {
    --tw-gradient-to: #00C853
  }

  .print\:to-light-green-50 {
    --tw-gradient-to: #F1F8E9
  }

  .print\:to-light-green-100 {
    --tw-gradient-to: #DCEDC8
  }

  .print\:to-light-green-200 {
    --tw-gradient-to: #C5E1A5
  }

  .print\:to-light-green-300 {
    --tw-gradient-to: #AED581
  }

  .print\:to-light-green-400 {
    --tw-gradient-to: #9CCC65
  }

  .print\:to-light-green-500 {
    --tw-gradient-to: #8BC34A
  }

  .print\:to-light-green-600 {
    --tw-gradient-to: #7CB342
  }

  .print\:to-light-green-700 {
    --tw-gradient-to: #689F38
  }

  .print\:to-light-green-800 {
    --tw-gradient-to: #558B2F
  }

  .print\:to-light-green-900 {
    --tw-gradient-to: #33691E
  }

  .print\:to-light-green {
    --tw-gradient-to: #8BC34A
  }

  .print\:to-light-green-A100 {
    --tw-gradient-to: #CCFF90
  }

  .print\:to-light-green-A200 {
    --tw-gradient-to: #B2FF59
  }

  .print\:to-light-green-A400 {
    --tw-gradient-to: #76FF03
  }

  .print\:to-light-green-A700 {
    --tw-gradient-to: #64DD17
  }

  .print\:to-teal-50 {
    --tw-gradient-to: #E0F2F1
  }

  .print\:to-teal-100 {
    --tw-gradient-to: #B2DFDB
  }

  .print\:to-teal-200 {
    --tw-gradient-to: #80CBC4
  }

  .print\:to-teal-300 {
    --tw-gradient-to: #4DB6AC
  }

  .print\:to-teal-400 {
    --tw-gradient-to: #26A69A
  }

  .print\:to-teal-500 {
    --tw-gradient-to: #009688
  }

  .print\:to-teal-600 {
    --tw-gradient-to: #00897B
  }

  .print\:to-teal-700 {
    --tw-gradient-to: #00796B
  }

  .print\:to-teal-800 {
    --tw-gradient-to: #00695C
  }

  .print\:to-teal-900 {
    --tw-gradient-to: #004D40
  }

  .print\:to-teal {
    --tw-gradient-to: #009688
  }

  .print\:to-teal-A100 {
    --tw-gradient-to: #A7FFEB
  }

  .print\:to-teal-A200 {
    --tw-gradient-to: #64FFDA
  }

  .print\:to-teal-A400 {
    --tw-gradient-to: #1DE9B6
  }

  .print\:to-teal-A700 {
    --tw-gradient-to: #00BFA5
  }

  .print\:to-blue-50 {
    --tw-gradient-to: #E3F2FD
  }

  .print\:to-blue-100 {
    --tw-gradient-to: #BBDEFB
  }

  .print\:to-blue-200 {
    --tw-gradient-to: #90CAF9
  }

  .print\:to-blue-300 {
    --tw-gradient-to: #64B5F6
  }

  .print\:to-blue-400 {
    --tw-gradient-to: #42A5F5
  }

  .print\:to-blue-500 {
    --tw-gradient-to: #2196F3
  }

  .print\:to-blue-600 {
    --tw-gradient-to: #1E88E5
  }

  .print\:to-blue-700 {
    --tw-gradient-to: #1976D2
  }

  .print\:to-blue-800 {
    --tw-gradient-to: #1565C0
  }

  .print\:to-blue-900 {
    --tw-gradient-to: #0D47A1
  }

  .print\:to-blue {
    --tw-gradient-to: #2196F3
  }

  .print\:to-blue-A100 {
    --tw-gradient-to: #82B1FF
  }

  .print\:to-blue-A200 {
    --tw-gradient-to: #448AFF
  }

  .print\:to-blue-A400 {
    --tw-gradient-to: #2979FF
  }

  .print\:to-blue-A700 {
    --tw-gradient-to: #2962FF
  }

  .print\:to-light-blue-50 {
    --tw-gradient-to: #E1F5FE
  }

  .print\:to-light-blue-100 {
    --tw-gradient-to: #B3E5FC
  }

  .print\:to-light-blue-200 {
    --tw-gradient-to: #81D4FA
  }

  .print\:to-light-blue-300 {
    --tw-gradient-to: #4FC3F7
  }

  .print\:to-light-blue-400 {
    --tw-gradient-to: #29B6F6
  }

  .print\:to-light-blue-500 {
    --tw-gradient-to: #03A9F4
  }

  .print\:to-light-blue-600 {
    --tw-gradient-to: #039BE5
  }

  .print\:to-light-blue-700 {
    --tw-gradient-to: #0288D1
  }

  .print\:to-light-blue-800 {
    --tw-gradient-to: #0277BD
  }

  .print\:to-light-blue-900 {
    --tw-gradient-to: #01579B
  }

  .print\:to-light-blue {
    --tw-gradient-to: #03A9F4
  }

  .print\:to-light-blue-A100 {
    --tw-gradient-to: #80D8FF
  }

  .print\:to-light-blue-A200 {
    --tw-gradient-to: #40C4FF
  }

  .print\:to-light-blue-A400 {
    --tw-gradient-to: #00B0FF
  }

  .print\:to-light-blue-A700 {
    --tw-gradient-to: #0091EA
  }

  .print\:to-indigo-50 {
    --tw-gradient-to: #E8EAF6
  }

  .print\:to-indigo-100 {
    --tw-gradient-to: #C5CAE9
  }

  .print\:to-indigo-200 {
    --tw-gradient-to: #9FA8DA
  }

  .print\:to-indigo-300 {
    --tw-gradient-to: #7986CB
  }

  .print\:to-indigo-400 {
    --tw-gradient-to: #5C6BC0
  }

  .print\:to-indigo-500 {
    --tw-gradient-to: #3F51B5
  }

  .print\:to-indigo-600 {
    --tw-gradient-to: #3949AB
  }

  .print\:to-indigo-700 {
    --tw-gradient-to: #303F9F
  }

  .print\:to-indigo-800 {
    --tw-gradient-to: #283593
  }

  .print\:to-indigo-900 {
    --tw-gradient-to: #1A237E
  }

  .print\:to-indigo {
    --tw-gradient-to: #3F51B5
  }

  .print\:to-indigo-A100 {
    --tw-gradient-to: #8C9EFF
  }

  .print\:to-indigo-A200 {
    --tw-gradient-to: #536DFE
  }

  .print\:to-indigo-A400 {
    --tw-gradient-to: #3D5AFE
  }

  .print\:to-indigo-A700 {
    --tw-gradient-to: #304FFE
  }

  .print\:to-purple-50 {
    --tw-gradient-to: #F3E5F5
  }

  .print\:to-purple-100 {
    --tw-gradient-to: #E1BEE7
  }

  .print\:to-purple-200 {
    --tw-gradient-to: #CE93D8
  }

  .print\:to-purple-300 {
    --tw-gradient-to: #BA68C8
  }

  .print\:to-purple-400 {
    --tw-gradient-to: #AB47BC
  }

  .print\:to-purple-500 {
    --tw-gradient-to: #9C27B0
  }

  .print\:to-purple-600 {
    --tw-gradient-to: #8E24AA
  }

  .print\:to-purple-700 {
    --tw-gradient-to: #7B1FA2
  }

  .print\:to-purple-800 {
    --tw-gradient-to: #6A1B9A
  }

  .print\:to-purple-900 {
    --tw-gradient-to: #4A148C
  }

  .print\:to-purple {
    --tw-gradient-to: #9C27B0
  }

  .print\:to-purple-A100 {
    --tw-gradient-to: #EA80FC
  }

  .print\:to-purple-A200 {
    --tw-gradient-to: #E040FB
  }

  .print\:to-purple-A400 {
    --tw-gradient-to: #D500F9
  }

  .print\:to-purple-A700 {
    --tw-gradient-to: #AA00FF
  }

  .print\:to-deep-purple-50 {
    --tw-gradient-to: #EDE7F6
  }

  .print\:to-deep-purple-100 {
    --tw-gradient-to: #D1C4E9
  }

  .print\:to-deep-purple-200 {
    --tw-gradient-to: #B39DDB
  }

  .print\:to-deep-purple-300 {
    --tw-gradient-to: #9575CD
  }

  .print\:to-deep-purple-400 {
    --tw-gradient-to: #7E57C2
  }

  .print\:to-deep-purple-500 {
    --tw-gradient-to: #673AB7
  }

  .print\:to-deep-purple-600 {
    --tw-gradient-to: #5E35B1
  }

  .print\:to-deep-purple-700 {
    --tw-gradient-to: #512DA8
  }

  .print\:to-deep-purple-800 {
    --tw-gradient-to: #4527A0
  }

  .print\:to-deep-purple-900 {
    --tw-gradient-to: #311B92
  }

  .print\:to-deep-purple {
    --tw-gradient-to: #673AB7
  }

  .print\:to-deep-purple-A100 {
    --tw-gradient-to: #B388FF
  }

  .print\:to-deep-purple-A200 {
    --tw-gradient-to: #7C4DFF
  }

  .print\:to-deep-purple-A400 {
    --tw-gradient-to: #651FFF
  }

  .print\:to-deep-purple-A700 {
    --tw-gradient-to: #6200EA
  }

  .print\:to-pink-50 {
    --tw-gradient-to: #FCE4EC
  }

  .print\:to-pink-100 {
    --tw-gradient-to: #F8BBD0
  }

  .print\:to-pink-200 {
    --tw-gradient-to: #F48FB1
  }

  .print\:to-pink-300 {
    --tw-gradient-to: #F06292
  }

  .print\:to-pink-400 {
    --tw-gradient-to: #EC407A
  }

  .print\:to-pink-500 {
    --tw-gradient-to: #E91E63
  }

  .print\:to-pink-600 {
    --tw-gradient-to: #D81B60
  }

  .print\:to-pink-700 {
    --tw-gradient-to: #C2185B
  }

  .print\:to-pink-800 {
    --tw-gradient-to: #AD1457
  }

  .print\:to-pink-900 {
    --tw-gradient-to: #880E4F
  }

  .print\:to-pink {
    --tw-gradient-to: #E91E63
  }

  .print\:to-pink-A100 {
    --tw-gradient-to: #FF80AB
  }

  .print\:to-pink-A200 {
    --tw-gradient-to: #FF4081
  }

  .print\:to-pink-A400 {
    --tw-gradient-to: #F50057
  }

  .print\:to-pink-A700 {
    --tw-gradient-to: #C51162
  }

  .print\:to-lime-50 {
    --tw-gradient-to: #F9FBE7
  }

  .print\:to-lime-100 {
    --tw-gradient-to: #F0F4C3
  }

  .print\:to-lime-200 {
    --tw-gradient-to: #E6EE9C
  }

  .print\:to-lime-300 {
    --tw-gradient-to: #DCE775
  }

  .print\:to-lime-400 {
    --tw-gradient-to: #D4E157
  }

  .print\:to-lime-500 {
    --tw-gradient-to: #CDDC39
  }

  .print\:to-lime-600 {
    --tw-gradient-to: #C0CA33
  }

  .print\:to-lime-700 {
    --tw-gradient-to: #AFB42B
  }

  .print\:to-lime-800 {
    --tw-gradient-to: #9E9D24
  }

  .print\:to-lime-900 {
    --tw-gradient-to: #827717
  }

  .print\:to-lime {
    --tw-gradient-to: #CDDC39
  }

  .print\:to-lime-A100 {
    --tw-gradient-to: #F4FF81
  }

  .print\:to-lime-A200 {
    --tw-gradient-to: #EEFF41
  }

  .print\:to-lime-A400 {
    --tw-gradient-to: #C6FF00
  }

  .print\:to-lime-A700 {
    --tw-gradient-to: #AEEA00
  }

  .print\:to-amber-50 {
    --tw-gradient-to: #FFF8E1
  }

  .print\:to-amber-100 {
    --tw-gradient-to: #FFECB3
  }

  .print\:to-amber-200 {
    --tw-gradient-to: #FFE082
  }

  .print\:to-amber-300 {
    --tw-gradient-to: #FFD54F
  }

  .print\:to-amber-400 {
    --tw-gradient-to: #FFCA28
  }

  .print\:to-amber-500 {
    --tw-gradient-to: #FFC107
  }

  .print\:to-amber-600 {
    --tw-gradient-to: #FFB300
  }

  .print\:to-amber-700 {
    --tw-gradient-to: #FFA000
  }

  .print\:to-amber-800 {
    --tw-gradient-to: #FF8F00
  }

  .print\:to-amber-900 {
    --tw-gradient-to: #FF6F00
  }

  .print\:to-amber {
    --tw-gradient-to: #FFC107
  }

  .print\:to-amber-A100 {
    --tw-gradient-to: #FFE57F
  }

  .print\:to-amber-A200 {
    --tw-gradient-to: #FFD740
  }

  .print\:to-amber-A400 {
    --tw-gradient-to: #FFC400
  }

  .print\:to-amber-A700 {
    --tw-gradient-to: #FFAB00
  }

  .print\:to-brown-50 {
    --tw-gradient-to: #EFEBE9
  }

  .print\:to-brown-100 {
    --tw-gradient-to: #D7CCC8
  }

  .print\:to-brown-200 {
    --tw-gradient-to: #BCAAA4
  }

  .print\:to-brown-300 {
    --tw-gradient-to: #A1887F
  }

  .print\:to-brown-400 {
    --tw-gradient-to: #8D6E63
  }

  .print\:to-brown-500 {
    --tw-gradient-to: #795548
  }

  .print\:to-brown-600 {
    --tw-gradient-to: #6D4C41
  }

  .print\:to-brown-700 {
    --tw-gradient-to: #5D4037
  }

  .print\:to-brown-800 {
    --tw-gradient-to: #4E342E
  }

  .print\:to-brown-900 {
    --tw-gradient-to: #3E2723
  }

  .print\:to-brown {
    --tw-gradient-to: #795548
  }

  .print\:to-brown-A100 {
    --tw-gradient-to: #D7CCC8
  }

  .print\:to-brown-A200 {
    --tw-gradient-to: #BCAAA4
  }

  .print\:to-brown-A400 {
    --tw-gradient-to: #8D6E63
  }

  .print\:to-brown-A700 {
    --tw-gradient-to: #5D4037
  }

  .print\:to-blue-gray-50 {
    --tw-gradient-to: #ECEFF1
  }

  .print\:to-blue-gray-100 {
    --tw-gradient-to: #CFD8DC
  }

  .print\:to-blue-gray-200 {
    --tw-gradient-to: #B0BEC5
  }

  .print\:to-blue-gray-300 {
    --tw-gradient-to: #90A4AE
  }

  .print\:to-blue-gray-400 {
    --tw-gradient-to: #78909C
  }

  .print\:to-blue-gray-500 {
    --tw-gradient-to: #607D8B
  }

  .print\:to-blue-gray-600 {
    --tw-gradient-to: #546E7A
  }

  .print\:to-blue-gray-700 {
    --tw-gradient-to: #455A64
  }

  .print\:to-blue-gray-800 {
    --tw-gradient-to: #37474F
  }

  .print\:to-blue-gray-900 {
    --tw-gradient-to: #263238
  }

  .print\:to-blue-gray {
    --tw-gradient-to: #607D8B
  }

  .print\:to-blue-gray-A100 {
    --tw-gradient-to: #CFD8DC
  }

  .print\:to-blue-gray-A200 {
    --tw-gradient-to: #B0BEC5
  }

  .print\:to-blue-gray-A400 {
    --tw-gradient-to: #78909C
  }

  .print\:to-blue-gray-A700 {
    --tw-gradient-to: #455A64
  }

  .print\:to-cyan-50 {
    --tw-gradient-to: #E0F7FA
  }

  .print\:to-cyan-100 {
    --tw-gradient-to: #B2EBF2
  }

  .print\:to-cyan-200 {
    --tw-gradient-to: #80DEEA
  }

  .print\:to-cyan-300 {
    --tw-gradient-to: #4DD0E1
  }

  .print\:to-cyan-400 {
    --tw-gradient-to: #26C6DA
  }

  .print\:to-cyan-500 {
    --tw-gradient-to: #00BCD4
  }

  .print\:to-cyan-600 {
    --tw-gradient-to: #00ACC1
  }

  .print\:to-cyan-700 {
    --tw-gradient-to: #0097A7
  }

  .print\:to-cyan-800 {
    --tw-gradient-to: #00838F
  }

  .print\:to-cyan-900 {
    --tw-gradient-to: #006064
  }

  .print\:to-cyan {
    --tw-gradient-to: #00BCD4
  }

  .print\:to-cyan-A100 {
    --tw-gradient-to: #84FFFF
  }

  .print\:to-cyan-A200 {
    --tw-gradient-to: #18FFFF
  }

  .print\:to-cyan-A400 {
    --tw-gradient-to: #00E5FF
  }

  .print\:to-cyan-A700 {
    --tw-gradient-to: #00B8D4
  }

  .print\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .print\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .print\:hover\:from-black:hover {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .print\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .print\:hover\:from-grey-50:hover {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .print\:hover\:from-grey-100:hover {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .print\:hover\:from-grey-200:hover {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .print\:hover\:from-grey-300:hover {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .print\:hover\:from-grey-400:hover {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .print\:hover\:from-grey-500:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:hover\:from-grey-600:hover {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .print\:hover\:from-grey-700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:hover\:from-grey-800:hover {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .print\:hover\:from-grey-900:hover {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .print\:hover\:from-grey:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:hover\:from-grey-A100:hover {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .print\:hover\:from-grey-A200:hover {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .print\:hover\:from-grey-A400:hover {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .print\:hover\:from-grey-A700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:hover\:from-gray-50:hover {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .print\:hover\:from-gray-100:hover {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .print\:hover\:from-gray-200:hover {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .print\:hover\:from-gray-300:hover {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .print\:hover\:from-gray-400:hover {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .print\:hover\:from-gray-500:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:hover\:from-gray-600:hover {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .print\:hover\:from-gray-700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:hover\:from-gray-800:hover {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .print\:hover\:from-gray-900:hover {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .print\:hover\:from-gray:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:hover\:from-gray-hover:hover {
    --tw-gradient-from: rgba(0, 0, 0, 0.04);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .print\:hover\:from-gray-A100:hover {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .print\:hover\:from-gray-A200:hover {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .print\:hover\:from-gray-A400:hover {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .print\:hover\:from-gray-A700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:hover\:from-red-50:hover {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .print\:hover\:from-red-100:hover {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .print\:hover\:from-red-200:hover {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .print\:hover\:from-red-300:hover {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .print\:hover\:from-red-400:hover {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .print\:hover\:from-red-500:hover {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .print\:hover\:from-red-600:hover {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .print\:hover\:from-red-700:hover {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .print\:hover\:from-red-800:hover {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .print\:hover\:from-red-900:hover {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .print\:hover\:from-red:hover {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .print\:hover\:from-red-A100:hover {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .print\:hover\:from-red-A200:hover {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .print\:hover\:from-red-A400:hover {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .print\:hover\:from-red-A700:hover {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .print\:hover\:from-orange-50:hover {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .print\:hover\:from-orange-100:hover {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .print\:hover\:from-orange-200:hover {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .print\:hover\:from-orange-300:hover {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .print\:hover\:from-orange-400:hover {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .print\:hover\:from-orange-500:hover {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .print\:hover\:from-orange-600:hover {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .print\:hover\:from-orange-700:hover {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .print\:hover\:from-orange-800:hover {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .print\:hover\:from-orange-900:hover {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .print\:hover\:from-orange:hover {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .print\:hover\:from-orange-A100:hover {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .print\:hover\:from-orange-A200:hover {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .print\:hover\:from-orange-A400:hover {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .print\:hover\:from-orange-A700:hover {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .print\:hover\:from-deep-orange-50:hover {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .print\:hover\:from-deep-orange-100:hover {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .print\:hover\:from-deep-orange-200:hover {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .print\:hover\:from-deep-orange-300:hover {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .print\:hover\:from-deep-orange-400:hover {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .print\:hover\:from-deep-orange-500:hover {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .print\:hover\:from-deep-orange-600:hover {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .print\:hover\:from-deep-orange-700:hover {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .print\:hover\:from-deep-orange-800:hover {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .print\:hover\:from-deep-orange-900:hover {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .print\:hover\:from-deep-orange:hover {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .print\:hover\:from-deep-orange-A100:hover {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .print\:hover\:from-deep-orange-A200:hover {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .print\:hover\:from-deep-orange-A400:hover {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .print\:hover\:from-deep-orange-A700:hover {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .print\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .print\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .print\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .print\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .print\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .print\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .print\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .print\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .print\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .print\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .print\:hover\:from-yellow:hover {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .print\:hover\:from-yellow-A100:hover {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .print\:hover\:from-yellow-A200:hover {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .print\:hover\:from-yellow-A400:hover {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .print\:hover\:from-yellow-A700:hover {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .print\:hover\:from-green-50:hover {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .print\:hover\:from-green-100:hover {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .print\:hover\:from-green-200:hover {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .print\:hover\:from-green-300:hover {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .print\:hover\:from-green-400:hover {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .print\:hover\:from-green-500:hover {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .print\:hover\:from-green-600:hover {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .print\:hover\:from-green-700:hover {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .print\:hover\:from-green-800:hover {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .print\:hover\:from-green-900:hover {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .print\:hover\:from-green:hover {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .print\:hover\:from-green-A100:hover {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .print\:hover\:from-green-A200:hover {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .print\:hover\:from-green-A400:hover {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .print\:hover\:from-green-A700:hover {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .print\:hover\:from-light-green-50:hover {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .print\:hover\:from-light-green-100:hover {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .print\:hover\:from-light-green-200:hover {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .print\:hover\:from-light-green-300:hover {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .print\:hover\:from-light-green-400:hover {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .print\:hover\:from-light-green-500:hover {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .print\:hover\:from-light-green-600:hover {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .print\:hover\:from-light-green-700:hover {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .print\:hover\:from-light-green-800:hover {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .print\:hover\:from-light-green-900:hover {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .print\:hover\:from-light-green:hover {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .print\:hover\:from-light-green-A100:hover {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .print\:hover\:from-light-green-A200:hover {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .print\:hover\:from-light-green-A400:hover {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .print\:hover\:from-light-green-A700:hover {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .print\:hover\:from-teal-50:hover {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .print\:hover\:from-teal-100:hover {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .print\:hover\:from-teal-200:hover {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .print\:hover\:from-teal-300:hover {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .print\:hover\:from-teal-400:hover {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .print\:hover\:from-teal-500:hover {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .print\:hover\:from-teal-600:hover {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .print\:hover\:from-teal-700:hover {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .print\:hover\:from-teal-800:hover {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .print\:hover\:from-teal-900:hover {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .print\:hover\:from-teal:hover {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .print\:hover\:from-teal-A100:hover {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .print\:hover\:from-teal-A200:hover {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .print\:hover\:from-teal-A400:hover {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .print\:hover\:from-teal-A700:hover {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .print\:hover\:from-blue-50:hover {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .print\:hover\:from-blue-100:hover {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .print\:hover\:from-blue-200:hover {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .print\:hover\:from-blue-300:hover {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .print\:hover\:from-blue-400:hover {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .print\:hover\:from-blue-500:hover {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .print\:hover\:from-blue-600:hover {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .print\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .print\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .print\:hover\:from-blue-900:hover {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .print\:hover\:from-blue:hover {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .print\:hover\:from-blue-A100:hover {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .print\:hover\:from-blue-A200:hover {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .print\:hover\:from-blue-A400:hover {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .print\:hover\:from-blue-A700:hover {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .print\:hover\:from-light-blue-50:hover {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .print\:hover\:from-light-blue-100:hover {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .print\:hover\:from-light-blue-200:hover {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .print\:hover\:from-light-blue-300:hover {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .print\:hover\:from-light-blue-400:hover {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .print\:hover\:from-light-blue-500:hover {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .print\:hover\:from-light-blue-600:hover {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .print\:hover\:from-light-blue-700:hover {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .print\:hover\:from-light-blue-800:hover {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .print\:hover\:from-light-blue-900:hover {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .print\:hover\:from-light-blue:hover {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .print\:hover\:from-light-blue-A100:hover {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .print\:hover\:from-light-blue-A200:hover {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .print\:hover\:from-light-blue-A400:hover {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .print\:hover\:from-light-blue-A700:hover {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .print\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .print\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .print\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .print\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .print\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .print\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .print\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .print\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .print\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .print\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .print\:hover\:from-indigo:hover {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .print\:hover\:from-indigo-A100:hover {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .print\:hover\:from-indigo-A200:hover {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .print\:hover\:from-indigo-A400:hover {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .print\:hover\:from-indigo-A700:hover {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .print\:hover\:from-purple-50:hover {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .print\:hover\:from-purple-100:hover {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .print\:hover\:from-purple-200:hover {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .print\:hover\:from-purple-300:hover {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .print\:hover\:from-purple-400:hover {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .print\:hover\:from-purple-500:hover {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .print\:hover\:from-purple-600:hover {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .print\:hover\:from-purple-700:hover {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .print\:hover\:from-purple-800:hover {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .print\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .print\:hover\:from-purple:hover {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .print\:hover\:from-purple-A100:hover {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .print\:hover\:from-purple-A200:hover {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .print\:hover\:from-purple-A400:hover {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .print\:hover\:from-purple-A700:hover {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .print\:hover\:from-deep-purple-50:hover {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .print\:hover\:from-deep-purple-100:hover {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .print\:hover\:from-deep-purple-200:hover {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .print\:hover\:from-deep-purple-300:hover {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .print\:hover\:from-deep-purple-400:hover {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .print\:hover\:from-deep-purple-500:hover {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .print\:hover\:from-deep-purple-600:hover {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .print\:hover\:from-deep-purple-700:hover {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .print\:hover\:from-deep-purple-800:hover {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .print\:hover\:from-deep-purple-900:hover {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .print\:hover\:from-deep-purple:hover {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .print\:hover\:from-deep-purple-A100:hover {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .print\:hover\:from-deep-purple-A200:hover {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .print\:hover\:from-deep-purple-A400:hover {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .print\:hover\:from-deep-purple-A700:hover {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .print\:hover\:from-pink-50:hover {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .print\:hover\:from-pink-100:hover {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .print\:hover\:from-pink-200:hover {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .print\:hover\:from-pink-300:hover {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .print\:hover\:from-pink-400:hover {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .print\:hover\:from-pink-500:hover {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .print\:hover\:from-pink-600:hover {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .print\:hover\:from-pink-700:hover {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .print\:hover\:from-pink-800:hover {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .print\:hover\:from-pink-900:hover {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .print\:hover\:from-pink:hover {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .print\:hover\:from-pink-A100:hover {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .print\:hover\:from-pink-A200:hover {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .print\:hover\:from-pink-A400:hover {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .print\:hover\:from-pink-A700:hover {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .print\:hover\:from-lime-50:hover {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .print\:hover\:from-lime-100:hover {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .print\:hover\:from-lime-200:hover {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .print\:hover\:from-lime-300:hover {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .print\:hover\:from-lime-400:hover {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .print\:hover\:from-lime-500:hover {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .print\:hover\:from-lime-600:hover {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .print\:hover\:from-lime-700:hover {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .print\:hover\:from-lime-800:hover {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .print\:hover\:from-lime-900:hover {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .print\:hover\:from-lime:hover {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .print\:hover\:from-lime-A100:hover {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .print\:hover\:from-lime-A200:hover {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .print\:hover\:from-lime-A400:hover {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .print\:hover\:from-lime-A700:hover {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .print\:hover\:from-amber-50:hover {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .print\:hover\:from-amber-100:hover {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .print\:hover\:from-amber-200:hover {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .print\:hover\:from-amber-300:hover {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .print\:hover\:from-amber-400:hover {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .print\:hover\:from-amber-500:hover {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .print\:hover\:from-amber-600:hover {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .print\:hover\:from-amber-700:hover {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .print\:hover\:from-amber-800:hover {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .print\:hover\:from-amber-900:hover {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .print\:hover\:from-amber:hover {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .print\:hover\:from-amber-A100:hover {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .print\:hover\:from-amber-A200:hover {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .print\:hover\:from-amber-A400:hover {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .print\:hover\:from-amber-A700:hover {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .print\:hover\:from-brown-50:hover {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .print\:hover\:from-brown-100:hover {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .print\:hover\:from-brown-200:hover {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .print\:hover\:from-brown-300:hover {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .print\:hover\:from-brown-400:hover {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .print\:hover\:from-brown-500:hover {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .print\:hover\:from-brown-600:hover {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .print\:hover\:from-brown-700:hover {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .print\:hover\:from-brown-800:hover {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .print\:hover\:from-brown-900:hover {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .print\:hover\:from-brown:hover {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .print\:hover\:from-brown-A100:hover {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .print\:hover\:from-brown-A200:hover {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .print\:hover\:from-brown-A400:hover {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .print\:hover\:from-brown-A700:hover {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .print\:hover\:from-blue-gray-50:hover {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .print\:hover\:from-blue-gray-100:hover {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .print\:hover\:from-blue-gray-200:hover {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .print\:hover\:from-blue-gray-300:hover {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .print\:hover\:from-blue-gray-400:hover {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .print\:hover\:from-blue-gray-500:hover {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .print\:hover\:from-blue-gray-600:hover {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .print\:hover\:from-blue-gray-700:hover {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .print\:hover\:from-blue-gray-800:hover {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .print\:hover\:from-blue-gray-900:hover {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .print\:hover\:from-blue-gray:hover {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .print\:hover\:from-blue-gray-A100:hover {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .print\:hover\:from-blue-gray-A200:hover {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .print\:hover\:from-blue-gray-A400:hover {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .print\:hover\:from-blue-gray-A700:hover {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .print\:hover\:from-cyan-50:hover {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .print\:hover\:from-cyan-100:hover {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .print\:hover\:from-cyan-200:hover {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .print\:hover\:from-cyan-300:hover {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .print\:hover\:from-cyan-400:hover {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .print\:hover\:from-cyan-500:hover {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .print\:hover\:from-cyan-600:hover {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .print\:hover\:from-cyan-700:hover {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .print\:hover\:from-cyan-800:hover {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .print\:hover\:from-cyan-900:hover {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .print\:hover\:from-cyan:hover {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .print\:hover\:from-cyan-A100:hover {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .print\:hover\:from-cyan-A200:hover {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .print\:hover\:from-cyan-A400:hover {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .print\:hover\:from-cyan-A700:hover {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .print\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .print\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .print\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .print\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .print\:hover\:via-grey-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .print\:hover\:via-grey-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .print\:hover\:via-grey-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .print\:hover\:via-grey-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .print\:hover\:via-grey-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .print\:hover\:via-grey-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:hover\:via-grey-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .print\:hover\:via-grey-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:hover\:via-grey-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .print\:hover\:via-grey-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .print\:hover\:via-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:hover\:via-grey-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .print\:hover\:via-grey-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .print\:hover\:via-grey-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .print\:hover\:via-grey-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .print\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .print\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .print\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .print\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .print\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .print\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .print\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .print\:hover\:via-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:hover\:via-gray-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .print\:hover\:via-gray-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .print\:hover\:via-gray-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .print\:hover\:via-gray-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .print\:hover\:via-gray-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .print\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .print\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .print\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .print\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .print\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .print\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .print\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .print\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .print\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .print\:hover\:via-red:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .print\:hover\:via-red-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .print\:hover\:via-red-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .print\:hover\:via-red-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .print\:hover\:via-red-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .print\:hover\:via-orange-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .print\:hover\:via-orange-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .print\:hover\:via-orange-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .print\:hover\:via-orange-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .print\:hover\:via-orange-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .print\:hover\:via-orange-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .print\:hover\:via-orange-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .print\:hover\:via-orange-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .print\:hover\:via-orange-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .print\:hover\:via-orange-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .print\:hover\:via-orange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .print\:hover\:via-orange-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .print\:hover\:via-orange-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .print\:hover\:via-orange-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .print\:hover\:via-orange-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .print\:hover\:via-deep-orange-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .print\:hover\:via-deep-orange-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .print\:hover\:via-deep-orange-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .print\:hover\:via-deep-orange-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .print\:hover\:via-deep-orange-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .print\:hover\:via-deep-orange-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .print\:hover\:via-deep-orange-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .print\:hover\:via-deep-orange-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .print\:hover\:via-deep-orange-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .print\:hover\:via-deep-orange-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .print\:hover\:via-deep-orange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .print\:hover\:via-deep-orange-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .print\:hover\:via-deep-orange-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .print\:hover\:via-deep-orange-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .print\:hover\:via-deep-orange-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .print\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .print\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .print\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .print\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .print\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .print\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .print\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .print\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .print\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .print\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .print\:hover\:via-yellow:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .print\:hover\:via-yellow-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .print\:hover\:via-yellow-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .print\:hover\:via-yellow-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .print\:hover\:via-yellow-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .print\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .print\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .print\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .print\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .print\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .print\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .print\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .print\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .print\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .print\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .print\:hover\:via-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .print\:hover\:via-green-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .print\:hover\:via-green-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .print\:hover\:via-green-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .print\:hover\:via-green-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .print\:hover\:via-light-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .print\:hover\:via-light-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .print\:hover\:via-light-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .print\:hover\:via-light-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .print\:hover\:via-light-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .print\:hover\:via-light-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .print\:hover\:via-light-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .print\:hover\:via-light-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .print\:hover\:via-light-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .print\:hover\:via-light-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .print\:hover\:via-light-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .print\:hover\:via-light-green-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .print\:hover\:via-light-green-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .print\:hover\:via-light-green-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .print\:hover\:via-light-green-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .print\:hover\:via-teal-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .print\:hover\:via-teal-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .print\:hover\:via-teal-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .print\:hover\:via-teal-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .print\:hover\:via-teal-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .print\:hover\:via-teal-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .print\:hover\:via-teal-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .print\:hover\:via-teal-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .print\:hover\:via-teal-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .print\:hover\:via-teal-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .print\:hover\:via-teal:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .print\:hover\:via-teal-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .print\:hover\:via-teal-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .print\:hover\:via-teal-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .print\:hover\:via-teal-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .print\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .print\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .print\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .print\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .print\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .print\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .print\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .print\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .print\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .print\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .print\:hover\:via-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .print\:hover\:via-blue-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .print\:hover\:via-blue-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .print\:hover\:via-blue-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .print\:hover\:via-blue-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .print\:hover\:via-light-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .print\:hover\:via-light-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .print\:hover\:via-light-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .print\:hover\:via-light-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .print\:hover\:via-light-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .print\:hover\:via-light-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .print\:hover\:via-light-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .print\:hover\:via-light-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .print\:hover\:via-light-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .print\:hover\:via-light-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .print\:hover\:via-light-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .print\:hover\:via-light-blue-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .print\:hover\:via-light-blue-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .print\:hover\:via-light-blue-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .print\:hover\:via-light-blue-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .print\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .print\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .print\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .print\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .print\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .print\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .print\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .print\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .print\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .print\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .print\:hover\:via-indigo:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .print\:hover\:via-indigo-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .print\:hover\:via-indigo-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .print\:hover\:via-indigo-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .print\:hover\:via-indigo-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .print\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .print\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .print\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .print\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .print\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .print\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .print\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .print\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .print\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .print\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .print\:hover\:via-purple:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .print\:hover\:via-purple-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .print\:hover\:via-purple-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .print\:hover\:via-purple-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .print\:hover\:via-purple-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .print\:hover\:via-deep-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .print\:hover\:via-deep-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .print\:hover\:via-deep-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .print\:hover\:via-deep-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .print\:hover\:via-deep-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .print\:hover\:via-deep-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .print\:hover\:via-deep-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .print\:hover\:via-deep-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .print\:hover\:via-deep-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .print\:hover\:via-deep-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .print\:hover\:via-deep-purple:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .print\:hover\:via-deep-purple-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .print\:hover\:via-deep-purple-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .print\:hover\:via-deep-purple-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .print\:hover\:via-deep-purple-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .print\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .print\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .print\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .print\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .print\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .print\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .print\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .print\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .print\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .print\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .print\:hover\:via-pink:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .print\:hover\:via-pink-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .print\:hover\:via-pink-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .print\:hover\:via-pink-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .print\:hover\:via-pink-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .print\:hover\:via-lime-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .print\:hover\:via-lime-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .print\:hover\:via-lime-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .print\:hover\:via-lime-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .print\:hover\:via-lime-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .print\:hover\:via-lime-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .print\:hover\:via-lime-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .print\:hover\:via-lime-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .print\:hover\:via-lime-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .print\:hover\:via-lime-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .print\:hover\:via-lime:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .print\:hover\:via-lime-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .print\:hover\:via-lime-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .print\:hover\:via-lime-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .print\:hover\:via-lime-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .print\:hover\:via-amber-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .print\:hover\:via-amber-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .print\:hover\:via-amber-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .print\:hover\:via-amber-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .print\:hover\:via-amber-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .print\:hover\:via-amber-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .print\:hover\:via-amber-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .print\:hover\:via-amber-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .print\:hover\:via-amber-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .print\:hover\:via-amber-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .print\:hover\:via-amber:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .print\:hover\:via-amber-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .print\:hover\:via-amber-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .print\:hover\:via-amber-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .print\:hover\:via-amber-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .print\:hover\:via-brown-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .print\:hover\:via-brown-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .print\:hover\:via-brown-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .print\:hover\:via-brown-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .print\:hover\:via-brown-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .print\:hover\:via-brown-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .print\:hover\:via-brown-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .print\:hover\:via-brown-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .print\:hover\:via-brown-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .print\:hover\:via-brown-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .print\:hover\:via-brown:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .print\:hover\:via-brown-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .print\:hover\:via-brown-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .print\:hover\:via-brown-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .print\:hover\:via-brown-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .print\:hover\:via-blue-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .print\:hover\:via-blue-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .print\:hover\:via-blue-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .print\:hover\:via-blue-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .print\:hover\:via-blue-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .print\:hover\:via-blue-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .print\:hover\:via-blue-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .print\:hover\:via-blue-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .print\:hover\:via-blue-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .print\:hover\:via-blue-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .print\:hover\:via-blue-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .print\:hover\:via-blue-gray-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .print\:hover\:via-blue-gray-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .print\:hover\:via-blue-gray-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .print\:hover\:via-blue-gray-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .print\:hover\:via-cyan-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .print\:hover\:via-cyan-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .print\:hover\:via-cyan-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .print\:hover\:via-cyan-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .print\:hover\:via-cyan-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .print\:hover\:via-cyan-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .print\:hover\:via-cyan-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .print\:hover\:via-cyan-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .print\:hover\:via-cyan-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .print\:hover\:via-cyan-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .print\:hover\:via-cyan:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .print\:hover\:via-cyan-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .print\:hover\:via-cyan-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .print\:hover\:via-cyan-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .print\:hover\:via-cyan-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .print\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent
  }

  .print\:hover\:to-current:hover {
    --tw-gradient-to: currentColor
  }

  .print\:hover\:to-black:hover {
    --tw-gradient-to: #22292F
  }

  .print\:hover\:to-white:hover {
    --tw-gradient-to: #fff
  }

  .print\:hover\:to-grey-50:hover {
    --tw-gradient-to: #FAFAFA
  }

  .print\:hover\:to-grey-100:hover {
    --tw-gradient-to: #F5F5F5
  }

  .print\:hover\:to-grey-200:hover {
    --tw-gradient-to: #EEEEEE
  }

  .print\:hover\:to-grey-300:hover {
    --tw-gradient-to: #E0E0E0
  }

  .print\:hover\:to-grey-400:hover {
    --tw-gradient-to: #BDBDBD
  }

  .print\:hover\:to-grey-500:hover {
    --tw-gradient-to: #9E9E9E
  }

  .print\:hover\:to-grey-600:hover {
    --tw-gradient-to: #757575
  }

  .print\:hover\:to-grey-700:hover {
    --tw-gradient-to: #616161
  }

  .print\:hover\:to-grey-800:hover {
    --tw-gradient-to: #424242
  }

  .print\:hover\:to-grey-900:hover {
    --tw-gradient-to: #212121
  }

  .print\:hover\:to-grey:hover {
    --tw-gradient-to: #9E9E9E
  }

  .print\:hover\:to-grey-A100:hover {
    --tw-gradient-to: #D5D5D5
  }

  .print\:hover\:to-grey-A200:hover {
    --tw-gradient-to: #AAAAAA
  }

  .print\:hover\:to-grey-A400:hover {
    --tw-gradient-to: #303030
  }

  .print\:hover\:to-grey-A700:hover {
    --tw-gradient-to: #616161
  }

  .print\:hover\:to-gray-50:hover {
    --tw-gradient-to: #FAFAFA
  }

  .print\:hover\:to-gray-100:hover {
    --tw-gradient-to: #F5F5F5
  }

  .print\:hover\:to-gray-200:hover {
    --tw-gradient-to: #EEEEEE
  }

  .print\:hover\:to-gray-300:hover {
    --tw-gradient-to: #E0E0E0
  }

  .print\:hover\:to-gray-400:hover {
    --tw-gradient-to: #BDBDBD
  }

  .print\:hover\:to-gray-500:hover {
    --tw-gradient-to: #9E9E9E
  }

  .print\:hover\:to-gray-600:hover {
    --tw-gradient-to: #757575
  }

  .print\:hover\:to-gray-700:hover {
    --tw-gradient-to: #616161
  }

  .print\:hover\:to-gray-800:hover {
    --tw-gradient-to: #424242
  }

  .print\:hover\:to-gray-900:hover {
    --tw-gradient-to: #212121
  }

  .print\:hover\:to-gray:hover {
    --tw-gradient-to: #9E9E9E
  }

  .print\:hover\:to-gray-hover:hover {
    --tw-gradient-to: rgba(0, 0, 0, 0.04)
  }

  .print\:hover\:to-gray-A100:hover {
    --tw-gradient-to: #D5D5D5
  }

  .print\:hover\:to-gray-A200:hover {
    --tw-gradient-to: #AAAAAA
  }

  .print\:hover\:to-gray-A400:hover {
    --tw-gradient-to: #303030
  }

  .print\:hover\:to-gray-A700:hover {
    --tw-gradient-to: #616161
  }

  .print\:hover\:to-red-50:hover {
    --tw-gradient-to: #FFEBEE
  }

  .print\:hover\:to-red-100:hover {
    --tw-gradient-to: #FFCDD2
  }

  .print\:hover\:to-red-200:hover {
    --tw-gradient-to: #EF9A9A
  }

  .print\:hover\:to-red-300:hover {
    --tw-gradient-to: #E57373
  }

  .print\:hover\:to-red-400:hover {
    --tw-gradient-to: #EF5350
  }

  .print\:hover\:to-red-500:hover {
    --tw-gradient-to: #F44336
  }

  .print\:hover\:to-red-600:hover {
    --tw-gradient-to: #E53935
  }

  .print\:hover\:to-red-700:hover {
    --tw-gradient-to: #D32F2F
  }

  .print\:hover\:to-red-800:hover {
    --tw-gradient-to: #C62828
  }

  .print\:hover\:to-red-900:hover {
    --tw-gradient-to: #B71C1C
  }

  .print\:hover\:to-red:hover {
    --tw-gradient-to: #F44336
  }

  .print\:hover\:to-red-A100:hover {
    --tw-gradient-to: #FF8A80
  }

  .print\:hover\:to-red-A200:hover {
    --tw-gradient-to: #FF5252
  }

  .print\:hover\:to-red-A400:hover {
    --tw-gradient-to: #FF1744
  }

  .print\:hover\:to-red-A700:hover {
    --tw-gradient-to: #D50000
  }

  .print\:hover\:to-orange-50:hover {
    --tw-gradient-to: #FFF3E0
  }

  .print\:hover\:to-orange-100:hover {
    --tw-gradient-to: #FFE0B2
  }

  .print\:hover\:to-orange-200:hover {
    --tw-gradient-to: #FFCC80
  }

  .print\:hover\:to-orange-300:hover {
    --tw-gradient-to: #FFB74D
  }

  .print\:hover\:to-orange-400:hover {
    --tw-gradient-to: #FFA726
  }

  .print\:hover\:to-orange-500:hover {
    --tw-gradient-to: #FF9800
  }

  .print\:hover\:to-orange-600:hover {
    --tw-gradient-to: #FB8C00
  }

  .print\:hover\:to-orange-700:hover {
    --tw-gradient-to: #F57C00
  }

  .print\:hover\:to-orange-800:hover {
    --tw-gradient-to: #EF6C00
  }

  .print\:hover\:to-orange-900:hover {
    --tw-gradient-to: #E65100
  }

  .print\:hover\:to-orange:hover {
    --tw-gradient-to: #FF9800
  }

  .print\:hover\:to-orange-A100:hover {
    --tw-gradient-to: #FFD180
  }

  .print\:hover\:to-orange-A200:hover {
    --tw-gradient-to: #FFAB40
  }

  .print\:hover\:to-orange-A400:hover {
    --tw-gradient-to: #FF9100
  }

  .print\:hover\:to-orange-A700:hover {
    --tw-gradient-to: #FF6D00
  }

  .print\:hover\:to-deep-orange-50:hover {
    --tw-gradient-to: #FBE9E7
  }

  .print\:hover\:to-deep-orange-100:hover {
    --tw-gradient-to: #FFCCBC
  }

  .print\:hover\:to-deep-orange-200:hover {
    --tw-gradient-to: #FFAB91
  }

  .print\:hover\:to-deep-orange-300:hover {
    --tw-gradient-to: #FF8A65
  }

  .print\:hover\:to-deep-orange-400:hover {
    --tw-gradient-to: #FF7043
  }

  .print\:hover\:to-deep-orange-500:hover {
    --tw-gradient-to: #FF5722
  }

  .print\:hover\:to-deep-orange-600:hover {
    --tw-gradient-to: #F4511E
  }

  .print\:hover\:to-deep-orange-700:hover {
    --tw-gradient-to: #E64A19
  }

  .print\:hover\:to-deep-orange-800:hover {
    --tw-gradient-to: #D84315
  }

  .print\:hover\:to-deep-orange-900:hover {
    --tw-gradient-to: #BF360C
  }

  .print\:hover\:to-deep-orange:hover {
    --tw-gradient-to: #FF5722
  }

  .print\:hover\:to-deep-orange-A100:hover {
    --tw-gradient-to: #FF9E80
  }

  .print\:hover\:to-deep-orange-A200:hover {
    --tw-gradient-to: #FF6E40
  }

  .print\:hover\:to-deep-orange-A400:hover {
    --tw-gradient-to: #FF3D00
  }

  .print\:hover\:to-deep-orange-A700:hover {
    --tw-gradient-to: #DD2C00
  }

  .print\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #FFFDE7
  }

  .print\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #FFF9C4
  }

  .print\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #FFF59D
  }

  .print\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #FFF176
  }

  .print\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #FFEE58
  }

  .print\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #FFEB3B
  }

  .print\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #FDD835
  }

  .print\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #FBC02D
  }

  .print\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #F9A825
  }

  .print\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #F57F17
  }

  .print\:hover\:to-yellow:hover {
    --tw-gradient-to: #FFEB3B
  }

  .print\:hover\:to-yellow-A100:hover {
    --tw-gradient-to: #FFFF8D
  }

  .print\:hover\:to-yellow-A200:hover {
    --tw-gradient-to: #FFFF00
  }

  .print\:hover\:to-yellow-A400:hover {
    --tw-gradient-to: #FFEA00
  }

  .print\:hover\:to-yellow-A700:hover {
    --tw-gradient-to: #FFD600
  }

  .print\:hover\:to-green-50:hover {
    --tw-gradient-to: #E8F5E9
  }

  .print\:hover\:to-green-100:hover {
    --tw-gradient-to: #C8E6C9
  }

  .print\:hover\:to-green-200:hover {
    --tw-gradient-to: #A5D6A7
  }

  .print\:hover\:to-green-300:hover {
    --tw-gradient-to: #81C784
  }

  .print\:hover\:to-green-400:hover {
    --tw-gradient-to: #66BB6A
  }

  .print\:hover\:to-green-500:hover {
    --tw-gradient-to: #4CAF50
  }

  .print\:hover\:to-green-600:hover {
    --tw-gradient-to: #43A047
  }

  .print\:hover\:to-green-700:hover {
    --tw-gradient-to: #388E3C
  }

  .print\:hover\:to-green-800:hover {
    --tw-gradient-to: #2E7D32
  }

  .print\:hover\:to-green-900:hover {
    --tw-gradient-to: #1B5E20
  }

  .print\:hover\:to-green:hover {
    --tw-gradient-to: #4CAF50
  }

  .print\:hover\:to-green-A100:hover {
    --tw-gradient-to: #B9F6CA
  }

  .print\:hover\:to-green-A200:hover {
    --tw-gradient-to: #69F0AE
  }

  .print\:hover\:to-green-A400:hover {
    --tw-gradient-to: #00E676
  }

  .print\:hover\:to-green-A700:hover {
    --tw-gradient-to: #00C853
  }

  .print\:hover\:to-light-green-50:hover {
    --tw-gradient-to: #F1F8E9
  }

  .print\:hover\:to-light-green-100:hover {
    --tw-gradient-to: #DCEDC8
  }

  .print\:hover\:to-light-green-200:hover {
    --tw-gradient-to: #C5E1A5
  }

  .print\:hover\:to-light-green-300:hover {
    --tw-gradient-to: #AED581
  }

  .print\:hover\:to-light-green-400:hover {
    --tw-gradient-to: #9CCC65
  }

  .print\:hover\:to-light-green-500:hover {
    --tw-gradient-to: #8BC34A
  }

  .print\:hover\:to-light-green-600:hover {
    --tw-gradient-to: #7CB342
  }

  .print\:hover\:to-light-green-700:hover {
    --tw-gradient-to: #689F38
  }

  .print\:hover\:to-light-green-800:hover {
    --tw-gradient-to: #558B2F
  }

  .print\:hover\:to-light-green-900:hover {
    --tw-gradient-to: #33691E
  }

  .print\:hover\:to-light-green:hover {
    --tw-gradient-to: #8BC34A
  }

  .print\:hover\:to-light-green-A100:hover {
    --tw-gradient-to: #CCFF90
  }

  .print\:hover\:to-light-green-A200:hover {
    --tw-gradient-to: #B2FF59
  }

  .print\:hover\:to-light-green-A400:hover {
    --tw-gradient-to: #76FF03
  }

  .print\:hover\:to-light-green-A700:hover {
    --tw-gradient-to: #64DD17
  }

  .print\:hover\:to-teal-50:hover {
    --tw-gradient-to: #E0F2F1
  }

  .print\:hover\:to-teal-100:hover {
    --tw-gradient-to: #B2DFDB
  }

  .print\:hover\:to-teal-200:hover {
    --tw-gradient-to: #80CBC4
  }

  .print\:hover\:to-teal-300:hover {
    --tw-gradient-to: #4DB6AC
  }

  .print\:hover\:to-teal-400:hover {
    --tw-gradient-to: #26A69A
  }

  .print\:hover\:to-teal-500:hover {
    --tw-gradient-to: #009688
  }

  .print\:hover\:to-teal-600:hover {
    --tw-gradient-to: #00897B
  }

  .print\:hover\:to-teal-700:hover {
    --tw-gradient-to: #00796B
  }

  .print\:hover\:to-teal-800:hover {
    --tw-gradient-to: #00695C
  }

  .print\:hover\:to-teal-900:hover {
    --tw-gradient-to: #004D40
  }

  .print\:hover\:to-teal:hover {
    --tw-gradient-to: #009688
  }

  .print\:hover\:to-teal-A100:hover {
    --tw-gradient-to: #A7FFEB
  }

  .print\:hover\:to-teal-A200:hover {
    --tw-gradient-to: #64FFDA
  }

  .print\:hover\:to-teal-A400:hover {
    --tw-gradient-to: #1DE9B6
  }

  .print\:hover\:to-teal-A700:hover {
    --tw-gradient-to: #00BFA5
  }

  .print\:hover\:to-blue-50:hover {
    --tw-gradient-to: #E3F2FD
  }

  .print\:hover\:to-blue-100:hover {
    --tw-gradient-to: #BBDEFB
  }

  .print\:hover\:to-blue-200:hover {
    --tw-gradient-to: #90CAF9
  }

  .print\:hover\:to-blue-300:hover {
    --tw-gradient-to: #64B5F6
  }

  .print\:hover\:to-blue-400:hover {
    --tw-gradient-to: #42A5F5
  }

  .print\:hover\:to-blue-500:hover {
    --tw-gradient-to: #2196F3
  }

  .print\:hover\:to-blue-600:hover {
    --tw-gradient-to: #1E88E5
  }

  .print\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1976D2
  }

  .print\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1565C0
  }

  .print\:hover\:to-blue-900:hover {
    --tw-gradient-to: #0D47A1
  }

  .print\:hover\:to-blue:hover {
    --tw-gradient-to: #2196F3
  }

  .print\:hover\:to-blue-A100:hover {
    --tw-gradient-to: #82B1FF
  }

  .print\:hover\:to-blue-A200:hover {
    --tw-gradient-to: #448AFF
  }

  .print\:hover\:to-blue-A400:hover {
    --tw-gradient-to: #2979FF
  }

  .print\:hover\:to-blue-A700:hover {
    --tw-gradient-to: #2962FF
  }

  .print\:hover\:to-light-blue-50:hover {
    --tw-gradient-to: #E1F5FE
  }

  .print\:hover\:to-light-blue-100:hover {
    --tw-gradient-to: #B3E5FC
  }

  .print\:hover\:to-light-blue-200:hover {
    --tw-gradient-to: #81D4FA
  }

  .print\:hover\:to-light-blue-300:hover {
    --tw-gradient-to: #4FC3F7
  }

  .print\:hover\:to-light-blue-400:hover {
    --tw-gradient-to: #29B6F6
  }

  .print\:hover\:to-light-blue-500:hover {
    --tw-gradient-to: #03A9F4
  }

  .print\:hover\:to-light-blue-600:hover {
    --tw-gradient-to: #039BE5
  }

  .print\:hover\:to-light-blue-700:hover {
    --tw-gradient-to: #0288D1
  }

  .print\:hover\:to-light-blue-800:hover {
    --tw-gradient-to: #0277BD
  }

  .print\:hover\:to-light-blue-900:hover {
    --tw-gradient-to: #01579B
  }

  .print\:hover\:to-light-blue:hover {
    --tw-gradient-to: #03A9F4
  }

  .print\:hover\:to-light-blue-A100:hover {
    --tw-gradient-to: #80D8FF
  }

  .print\:hover\:to-light-blue-A200:hover {
    --tw-gradient-to: #40C4FF
  }

  .print\:hover\:to-light-blue-A400:hover {
    --tw-gradient-to: #00B0FF
  }

  .print\:hover\:to-light-blue-A700:hover {
    --tw-gradient-to: #0091EA
  }

  .print\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #E8EAF6
  }

  .print\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #C5CAE9
  }

  .print\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #9FA8DA
  }

  .print\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #7986CB
  }

  .print\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #5C6BC0
  }

  .print\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #3F51B5
  }

  .print\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #3949AB
  }

  .print\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #303F9F
  }

  .print\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #283593
  }

  .print\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #1A237E
  }

  .print\:hover\:to-indigo:hover {
    --tw-gradient-to: #3F51B5
  }

  .print\:hover\:to-indigo-A100:hover {
    --tw-gradient-to: #8C9EFF
  }

  .print\:hover\:to-indigo-A200:hover {
    --tw-gradient-to: #536DFE
  }

  .print\:hover\:to-indigo-A400:hover {
    --tw-gradient-to: #3D5AFE
  }

  .print\:hover\:to-indigo-A700:hover {
    --tw-gradient-to: #304FFE
  }

  .print\:hover\:to-purple-50:hover {
    --tw-gradient-to: #F3E5F5
  }

  .print\:hover\:to-purple-100:hover {
    --tw-gradient-to: #E1BEE7
  }

  .print\:hover\:to-purple-200:hover {
    --tw-gradient-to: #CE93D8
  }

  .print\:hover\:to-purple-300:hover {
    --tw-gradient-to: #BA68C8
  }

  .print\:hover\:to-purple-400:hover {
    --tw-gradient-to: #AB47BC
  }

  .print\:hover\:to-purple-500:hover {
    --tw-gradient-to: #9C27B0
  }

  .print\:hover\:to-purple-600:hover {
    --tw-gradient-to: #8E24AA
  }

  .print\:hover\:to-purple-700:hover {
    --tw-gradient-to: #7B1FA2
  }

  .print\:hover\:to-purple-800:hover {
    --tw-gradient-to: #6A1B9A
  }

  .print\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4A148C
  }

  .print\:hover\:to-purple:hover {
    --tw-gradient-to: #9C27B0
  }

  .print\:hover\:to-purple-A100:hover {
    --tw-gradient-to: #EA80FC
  }

  .print\:hover\:to-purple-A200:hover {
    --tw-gradient-to: #E040FB
  }

  .print\:hover\:to-purple-A400:hover {
    --tw-gradient-to: #D500F9
  }

  .print\:hover\:to-purple-A700:hover {
    --tw-gradient-to: #AA00FF
  }

  .print\:hover\:to-deep-purple-50:hover {
    --tw-gradient-to: #EDE7F6
  }

  .print\:hover\:to-deep-purple-100:hover {
    --tw-gradient-to: #D1C4E9
  }

  .print\:hover\:to-deep-purple-200:hover {
    --tw-gradient-to: #B39DDB
  }

  .print\:hover\:to-deep-purple-300:hover {
    --tw-gradient-to: #9575CD
  }

  .print\:hover\:to-deep-purple-400:hover {
    --tw-gradient-to: #7E57C2
  }

  .print\:hover\:to-deep-purple-500:hover {
    --tw-gradient-to: #673AB7
  }

  .print\:hover\:to-deep-purple-600:hover {
    --tw-gradient-to: #5E35B1
  }

  .print\:hover\:to-deep-purple-700:hover {
    --tw-gradient-to: #512DA8
  }

  .print\:hover\:to-deep-purple-800:hover {
    --tw-gradient-to: #4527A0
  }

  .print\:hover\:to-deep-purple-900:hover {
    --tw-gradient-to: #311B92
  }

  .print\:hover\:to-deep-purple:hover {
    --tw-gradient-to: #673AB7
  }

  .print\:hover\:to-deep-purple-A100:hover {
    --tw-gradient-to: #B388FF
  }

  .print\:hover\:to-deep-purple-A200:hover {
    --tw-gradient-to: #7C4DFF
  }

  .print\:hover\:to-deep-purple-A400:hover {
    --tw-gradient-to: #651FFF
  }

  .print\:hover\:to-deep-purple-A700:hover {
    --tw-gradient-to: #6200EA
  }

  .print\:hover\:to-pink-50:hover {
    --tw-gradient-to: #FCE4EC
  }

  .print\:hover\:to-pink-100:hover {
    --tw-gradient-to: #F8BBD0
  }

  .print\:hover\:to-pink-200:hover {
    --tw-gradient-to: #F48FB1
  }

  .print\:hover\:to-pink-300:hover {
    --tw-gradient-to: #F06292
  }

  .print\:hover\:to-pink-400:hover {
    --tw-gradient-to: #EC407A
  }

  .print\:hover\:to-pink-500:hover {
    --tw-gradient-to: #E91E63
  }

  .print\:hover\:to-pink-600:hover {
    --tw-gradient-to: #D81B60
  }

  .print\:hover\:to-pink-700:hover {
    --tw-gradient-to: #C2185B
  }

  .print\:hover\:to-pink-800:hover {
    --tw-gradient-to: #AD1457
  }

  .print\:hover\:to-pink-900:hover {
    --tw-gradient-to: #880E4F
  }

  .print\:hover\:to-pink:hover {
    --tw-gradient-to: #E91E63
  }

  .print\:hover\:to-pink-A100:hover {
    --tw-gradient-to: #FF80AB
  }

  .print\:hover\:to-pink-A200:hover {
    --tw-gradient-to: #FF4081
  }

  .print\:hover\:to-pink-A400:hover {
    --tw-gradient-to: #F50057
  }

  .print\:hover\:to-pink-A700:hover {
    --tw-gradient-to: #C51162
  }

  .print\:hover\:to-lime-50:hover {
    --tw-gradient-to: #F9FBE7
  }

  .print\:hover\:to-lime-100:hover {
    --tw-gradient-to: #F0F4C3
  }

  .print\:hover\:to-lime-200:hover {
    --tw-gradient-to: #E6EE9C
  }

  .print\:hover\:to-lime-300:hover {
    --tw-gradient-to: #DCE775
  }

  .print\:hover\:to-lime-400:hover {
    --tw-gradient-to: #D4E157
  }

  .print\:hover\:to-lime-500:hover {
    --tw-gradient-to: #CDDC39
  }

  .print\:hover\:to-lime-600:hover {
    --tw-gradient-to: #C0CA33
  }

  .print\:hover\:to-lime-700:hover {
    --tw-gradient-to: #AFB42B
  }

  .print\:hover\:to-lime-800:hover {
    --tw-gradient-to: #9E9D24
  }

  .print\:hover\:to-lime-900:hover {
    --tw-gradient-to: #827717
  }

  .print\:hover\:to-lime:hover {
    --tw-gradient-to: #CDDC39
  }

  .print\:hover\:to-lime-A100:hover {
    --tw-gradient-to: #F4FF81
  }

  .print\:hover\:to-lime-A200:hover {
    --tw-gradient-to: #EEFF41
  }

  .print\:hover\:to-lime-A400:hover {
    --tw-gradient-to: #C6FF00
  }

  .print\:hover\:to-lime-A700:hover {
    --tw-gradient-to: #AEEA00
  }

  .print\:hover\:to-amber-50:hover {
    --tw-gradient-to: #FFF8E1
  }

  .print\:hover\:to-amber-100:hover {
    --tw-gradient-to: #FFECB3
  }

  .print\:hover\:to-amber-200:hover {
    --tw-gradient-to: #FFE082
  }

  .print\:hover\:to-amber-300:hover {
    --tw-gradient-to: #FFD54F
  }

  .print\:hover\:to-amber-400:hover {
    --tw-gradient-to: #FFCA28
  }

  .print\:hover\:to-amber-500:hover {
    --tw-gradient-to: #FFC107
  }

  .print\:hover\:to-amber-600:hover {
    --tw-gradient-to: #FFB300
  }

  .print\:hover\:to-amber-700:hover {
    --tw-gradient-to: #FFA000
  }

  .print\:hover\:to-amber-800:hover {
    --tw-gradient-to: #FF8F00
  }

  .print\:hover\:to-amber-900:hover {
    --tw-gradient-to: #FF6F00
  }

  .print\:hover\:to-amber:hover {
    --tw-gradient-to: #FFC107
  }

  .print\:hover\:to-amber-A100:hover {
    --tw-gradient-to: #FFE57F
  }

  .print\:hover\:to-amber-A200:hover {
    --tw-gradient-to: #FFD740
  }

  .print\:hover\:to-amber-A400:hover {
    --tw-gradient-to: #FFC400
  }

  .print\:hover\:to-amber-A700:hover {
    --tw-gradient-to: #FFAB00
  }

  .print\:hover\:to-brown-50:hover {
    --tw-gradient-to: #EFEBE9
  }

  .print\:hover\:to-brown-100:hover {
    --tw-gradient-to: #D7CCC8
  }

  .print\:hover\:to-brown-200:hover {
    --tw-gradient-to: #BCAAA4
  }

  .print\:hover\:to-brown-300:hover {
    --tw-gradient-to: #A1887F
  }

  .print\:hover\:to-brown-400:hover {
    --tw-gradient-to: #8D6E63
  }

  .print\:hover\:to-brown-500:hover {
    --tw-gradient-to: #795548
  }

  .print\:hover\:to-brown-600:hover {
    --tw-gradient-to: #6D4C41
  }

  .print\:hover\:to-brown-700:hover {
    --tw-gradient-to: #5D4037
  }

  .print\:hover\:to-brown-800:hover {
    --tw-gradient-to: #4E342E
  }

  .print\:hover\:to-brown-900:hover {
    --tw-gradient-to: #3E2723
  }

  .print\:hover\:to-brown:hover {
    --tw-gradient-to: #795548
  }

  .print\:hover\:to-brown-A100:hover {
    --tw-gradient-to: #D7CCC8
  }

  .print\:hover\:to-brown-A200:hover {
    --tw-gradient-to: #BCAAA4
  }

  .print\:hover\:to-brown-A400:hover {
    --tw-gradient-to: #8D6E63
  }

  .print\:hover\:to-brown-A700:hover {
    --tw-gradient-to: #5D4037
  }

  .print\:hover\:to-blue-gray-50:hover {
    --tw-gradient-to: #ECEFF1
  }

  .print\:hover\:to-blue-gray-100:hover {
    --tw-gradient-to: #CFD8DC
  }

  .print\:hover\:to-blue-gray-200:hover {
    --tw-gradient-to: #B0BEC5
  }

  .print\:hover\:to-blue-gray-300:hover {
    --tw-gradient-to: #90A4AE
  }

  .print\:hover\:to-blue-gray-400:hover {
    --tw-gradient-to: #78909C
  }

  .print\:hover\:to-blue-gray-500:hover {
    --tw-gradient-to: #607D8B
  }

  .print\:hover\:to-blue-gray-600:hover {
    --tw-gradient-to: #546E7A
  }

  .print\:hover\:to-blue-gray-700:hover {
    --tw-gradient-to: #455A64
  }

  .print\:hover\:to-blue-gray-800:hover {
    --tw-gradient-to: #37474F
  }

  .print\:hover\:to-blue-gray-900:hover {
    --tw-gradient-to: #263238
  }

  .print\:hover\:to-blue-gray:hover {
    --tw-gradient-to: #607D8B
  }

  .print\:hover\:to-blue-gray-A100:hover {
    --tw-gradient-to: #CFD8DC
  }

  .print\:hover\:to-blue-gray-A200:hover {
    --tw-gradient-to: #B0BEC5
  }

  .print\:hover\:to-blue-gray-A400:hover {
    --tw-gradient-to: #78909C
  }

  .print\:hover\:to-blue-gray-A700:hover {
    --tw-gradient-to: #455A64
  }

  .print\:hover\:to-cyan-50:hover {
    --tw-gradient-to: #E0F7FA
  }

  .print\:hover\:to-cyan-100:hover {
    --tw-gradient-to: #B2EBF2
  }

  .print\:hover\:to-cyan-200:hover {
    --tw-gradient-to: #80DEEA
  }

  .print\:hover\:to-cyan-300:hover {
    --tw-gradient-to: #4DD0E1
  }

  .print\:hover\:to-cyan-400:hover {
    --tw-gradient-to: #26C6DA
  }

  .print\:hover\:to-cyan-500:hover {
    --tw-gradient-to: #00BCD4
  }

  .print\:hover\:to-cyan-600:hover {
    --tw-gradient-to: #00ACC1
  }

  .print\:hover\:to-cyan-700:hover {
    --tw-gradient-to: #0097A7
  }

  .print\:hover\:to-cyan-800:hover {
    --tw-gradient-to: #00838F
  }

  .print\:hover\:to-cyan-900:hover {
    --tw-gradient-to: #006064
  }

  .print\:hover\:to-cyan:hover {
    --tw-gradient-to: #00BCD4
  }

  .print\:hover\:to-cyan-A100:hover {
    --tw-gradient-to: #84FFFF
  }

  .print\:hover\:to-cyan-A200:hover {
    --tw-gradient-to: #18FFFF
  }

  .print\:hover\:to-cyan-A400:hover {
    --tw-gradient-to: #00E5FF
  }

  .print\:hover\:to-cyan-A700:hover {
    --tw-gradient-to: #00B8D4
  }

  .print\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .print\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .print\:focus\:from-black:focus {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .print\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .print\:focus\:from-grey-50:focus {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .print\:focus\:from-grey-100:focus {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .print\:focus\:from-grey-200:focus {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .print\:focus\:from-grey-300:focus {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .print\:focus\:from-grey-400:focus {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .print\:focus\:from-grey-500:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:focus\:from-grey-600:focus {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .print\:focus\:from-grey-700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:focus\:from-grey-800:focus {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .print\:focus\:from-grey-900:focus {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .print\:focus\:from-grey:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:focus\:from-grey-A100:focus {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .print\:focus\:from-grey-A200:focus {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .print\:focus\:from-grey-A400:focus {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .print\:focus\:from-grey-A700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:focus\:from-gray-50:focus {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .print\:focus\:from-gray-100:focus {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .print\:focus\:from-gray-200:focus {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .print\:focus\:from-gray-300:focus {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .print\:focus\:from-gray-400:focus {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .print\:focus\:from-gray-500:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:focus\:from-gray-600:focus {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .print\:focus\:from-gray-700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:focus\:from-gray-800:focus {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .print\:focus\:from-gray-900:focus {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .print\:focus\:from-gray:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:focus\:from-gray-hover:focus {
    --tw-gradient-from: rgba(0, 0, 0, 0.04);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .print\:focus\:from-gray-A100:focus {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .print\:focus\:from-gray-A200:focus {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .print\:focus\:from-gray-A400:focus {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .print\:focus\:from-gray-A700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:focus\:from-red-50:focus {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .print\:focus\:from-red-100:focus {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .print\:focus\:from-red-200:focus {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .print\:focus\:from-red-300:focus {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .print\:focus\:from-red-400:focus {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .print\:focus\:from-red-500:focus {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .print\:focus\:from-red-600:focus {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .print\:focus\:from-red-700:focus {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .print\:focus\:from-red-800:focus {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .print\:focus\:from-red-900:focus {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .print\:focus\:from-red:focus {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .print\:focus\:from-red-A100:focus {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .print\:focus\:from-red-A200:focus {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .print\:focus\:from-red-A400:focus {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .print\:focus\:from-red-A700:focus {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .print\:focus\:from-orange-50:focus {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .print\:focus\:from-orange-100:focus {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .print\:focus\:from-orange-200:focus {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .print\:focus\:from-orange-300:focus {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .print\:focus\:from-orange-400:focus {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .print\:focus\:from-orange-500:focus {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .print\:focus\:from-orange-600:focus {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .print\:focus\:from-orange-700:focus {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .print\:focus\:from-orange-800:focus {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .print\:focus\:from-orange-900:focus {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .print\:focus\:from-orange:focus {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .print\:focus\:from-orange-A100:focus {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .print\:focus\:from-orange-A200:focus {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .print\:focus\:from-orange-A400:focus {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .print\:focus\:from-orange-A700:focus {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .print\:focus\:from-deep-orange-50:focus {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .print\:focus\:from-deep-orange-100:focus {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .print\:focus\:from-deep-orange-200:focus {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .print\:focus\:from-deep-orange-300:focus {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .print\:focus\:from-deep-orange-400:focus {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .print\:focus\:from-deep-orange-500:focus {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .print\:focus\:from-deep-orange-600:focus {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .print\:focus\:from-deep-orange-700:focus {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .print\:focus\:from-deep-orange-800:focus {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .print\:focus\:from-deep-orange-900:focus {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .print\:focus\:from-deep-orange:focus {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .print\:focus\:from-deep-orange-A100:focus {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .print\:focus\:from-deep-orange-A200:focus {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .print\:focus\:from-deep-orange-A400:focus {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .print\:focus\:from-deep-orange-A700:focus {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .print\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .print\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .print\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .print\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .print\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .print\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .print\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .print\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .print\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .print\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .print\:focus\:from-yellow:focus {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .print\:focus\:from-yellow-A100:focus {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .print\:focus\:from-yellow-A200:focus {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .print\:focus\:from-yellow-A400:focus {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .print\:focus\:from-yellow-A700:focus {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .print\:focus\:from-green-50:focus {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .print\:focus\:from-green-100:focus {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .print\:focus\:from-green-200:focus {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .print\:focus\:from-green-300:focus {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .print\:focus\:from-green-400:focus {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .print\:focus\:from-green-500:focus {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .print\:focus\:from-green-600:focus {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .print\:focus\:from-green-700:focus {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .print\:focus\:from-green-800:focus {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .print\:focus\:from-green-900:focus {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .print\:focus\:from-green:focus {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .print\:focus\:from-green-A100:focus {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .print\:focus\:from-green-A200:focus {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .print\:focus\:from-green-A400:focus {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .print\:focus\:from-green-A700:focus {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .print\:focus\:from-light-green-50:focus {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .print\:focus\:from-light-green-100:focus {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .print\:focus\:from-light-green-200:focus {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .print\:focus\:from-light-green-300:focus {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .print\:focus\:from-light-green-400:focus {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .print\:focus\:from-light-green-500:focus {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .print\:focus\:from-light-green-600:focus {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .print\:focus\:from-light-green-700:focus {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .print\:focus\:from-light-green-800:focus {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .print\:focus\:from-light-green-900:focus {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .print\:focus\:from-light-green:focus {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .print\:focus\:from-light-green-A100:focus {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .print\:focus\:from-light-green-A200:focus {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .print\:focus\:from-light-green-A400:focus {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .print\:focus\:from-light-green-A700:focus {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .print\:focus\:from-teal-50:focus {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .print\:focus\:from-teal-100:focus {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .print\:focus\:from-teal-200:focus {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .print\:focus\:from-teal-300:focus {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .print\:focus\:from-teal-400:focus {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .print\:focus\:from-teal-500:focus {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .print\:focus\:from-teal-600:focus {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .print\:focus\:from-teal-700:focus {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .print\:focus\:from-teal-800:focus {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .print\:focus\:from-teal-900:focus {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .print\:focus\:from-teal:focus {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .print\:focus\:from-teal-A100:focus {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .print\:focus\:from-teal-A200:focus {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .print\:focus\:from-teal-A400:focus {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .print\:focus\:from-teal-A700:focus {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .print\:focus\:from-blue-50:focus {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .print\:focus\:from-blue-100:focus {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .print\:focus\:from-blue-200:focus {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .print\:focus\:from-blue-300:focus {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .print\:focus\:from-blue-400:focus {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .print\:focus\:from-blue-500:focus {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .print\:focus\:from-blue-600:focus {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .print\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .print\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .print\:focus\:from-blue-900:focus {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .print\:focus\:from-blue:focus {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .print\:focus\:from-blue-A100:focus {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .print\:focus\:from-blue-A200:focus {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .print\:focus\:from-blue-A400:focus {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .print\:focus\:from-blue-A700:focus {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .print\:focus\:from-light-blue-50:focus {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .print\:focus\:from-light-blue-100:focus {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .print\:focus\:from-light-blue-200:focus {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .print\:focus\:from-light-blue-300:focus {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .print\:focus\:from-light-blue-400:focus {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .print\:focus\:from-light-blue-500:focus {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .print\:focus\:from-light-blue-600:focus {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .print\:focus\:from-light-blue-700:focus {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .print\:focus\:from-light-blue-800:focus {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .print\:focus\:from-light-blue-900:focus {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .print\:focus\:from-light-blue:focus {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .print\:focus\:from-light-blue-A100:focus {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .print\:focus\:from-light-blue-A200:focus {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .print\:focus\:from-light-blue-A400:focus {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .print\:focus\:from-light-blue-A700:focus {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .print\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .print\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .print\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .print\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .print\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .print\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .print\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .print\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .print\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .print\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .print\:focus\:from-indigo:focus {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .print\:focus\:from-indigo-A100:focus {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .print\:focus\:from-indigo-A200:focus {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .print\:focus\:from-indigo-A400:focus {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .print\:focus\:from-indigo-A700:focus {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .print\:focus\:from-purple-50:focus {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .print\:focus\:from-purple-100:focus {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .print\:focus\:from-purple-200:focus {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .print\:focus\:from-purple-300:focus {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .print\:focus\:from-purple-400:focus {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .print\:focus\:from-purple-500:focus {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .print\:focus\:from-purple-600:focus {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .print\:focus\:from-purple-700:focus {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .print\:focus\:from-purple-800:focus {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .print\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .print\:focus\:from-purple:focus {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .print\:focus\:from-purple-A100:focus {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .print\:focus\:from-purple-A200:focus {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .print\:focus\:from-purple-A400:focus {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .print\:focus\:from-purple-A700:focus {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .print\:focus\:from-deep-purple-50:focus {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .print\:focus\:from-deep-purple-100:focus {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .print\:focus\:from-deep-purple-200:focus {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .print\:focus\:from-deep-purple-300:focus {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .print\:focus\:from-deep-purple-400:focus {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .print\:focus\:from-deep-purple-500:focus {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .print\:focus\:from-deep-purple-600:focus {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .print\:focus\:from-deep-purple-700:focus {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .print\:focus\:from-deep-purple-800:focus {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .print\:focus\:from-deep-purple-900:focus {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .print\:focus\:from-deep-purple:focus {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .print\:focus\:from-deep-purple-A100:focus {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .print\:focus\:from-deep-purple-A200:focus {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .print\:focus\:from-deep-purple-A400:focus {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .print\:focus\:from-deep-purple-A700:focus {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .print\:focus\:from-pink-50:focus {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .print\:focus\:from-pink-100:focus {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .print\:focus\:from-pink-200:focus {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .print\:focus\:from-pink-300:focus {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .print\:focus\:from-pink-400:focus {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .print\:focus\:from-pink-500:focus {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .print\:focus\:from-pink-600:focus {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .print\:focus\:from-pink-700:focus {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .print\:focus\:from-pink-800:focus {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .print\:focus\:from-pink-900:focus {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .print\:focus\:from-pink:focus {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .print\:focus\:from-pink-A100:focus {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .print\:focus\:from-pink-A200:focus {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .print\:focus\:from-pink-A400:focus {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .print\:focus\:from-pink-A700:focus {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .print\:focus\:from-lime-50:focus {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .print\:focus\:from-lime-100:focus {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .print\:focus\:from-lime-200:focus {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .print\:focus\:from-lime-300:focus {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .print\:focus\:from-lime-400:focus {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .print\:focus\:from-lime-500:focus {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .print\:focus\:from-lime-600:focus {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .print\:focus\:from-lime-700:focus {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .print\:focus\:from-lime-800:focus {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .print\:focus\:from-lime-900:focus {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .print\:focus\:from-lime:focus {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .print\:focus\:from-lime-A100:focus {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .print\:focus\:from-lime-A200:focus {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .print\:focus\:from-lime-A400:focus {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .print\:focus\:from-lime-A700:focus {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .print\:focus\:from-amber-50:focus {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .print\:focus\:from-amber-100:focus {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .print\:focus\:from-amber-200:focus {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .print\:focus\:from-amber-300:focus {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .print\:focus\:from-amber-400:focus {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .print\:focus\:from-amber-500:focus {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .print\:focus\:from-amber-600:focus {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .print\:focus\:from-amber-700:focus {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .print\:focus\:from-amber-800:focus {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .print\:focus\:from-amber-900:focus {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .print\:focus\:from-amber:focus {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .print\:focus\:from-amber-A100:focus {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .print\:focus\:from-amber-A200:focus {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .print\:focus\:from-amber-A400:focus {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .print\:focus\:from-amber-A700:focus {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .print\:focus\:from-brown-50:focus {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .print\:focus\:from-brown-100:focus {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .print\:focus\:from-brown-200:focus {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .print\:focus\:from-brown-300:focus {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .print\:focus\:from-brown-400:focus {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .print\:focus\:from-brown-500:focus {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .print\:focus\:from-brown-600:focus {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .print\:focus\:from-brown-700:focus {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .print\:focus\:from-brown-800:focus {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .print\:focus\:from-brown-900:focus {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .print\:focus\:from-brown:focus {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .print\:focus\:from-brown-A100:focus {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .print\:focus\:from-brown-A200:focus {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .print\:focus\:from-brown-A400:focus {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .print\:focus\:from-brown-A700:focus {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .print\:focus\:from-blue-gray-50:focus {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .print\:focus\:from-blue-gray-100:focus {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .print\:focus\:from-blue-gray-200:focus {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .print\:focus\:from-blue-gray-300:focus {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .print\:focus\:from-blue-gray-400:focus {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .print\:focus\:from-blue-gray-500:focus {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .print\:focus\:from-blue-gray-600:focus {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .print\:focus\:from-blue-gray-700:focus {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .print\:focus\:from-blue-gray-800:focus {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .print\:focus\:from-blue-gray-900:focus {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .print\:focus\:from-blue-gray:focus {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .print\:focus\:from-blue-gray-A100:focus {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .print\:focus\:from-blue-gray-A200:focus {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .print\:focus\:from-blue-gray-A400:focus {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .print\:focus\:from-blue-gray-A700:focus {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .print\:focus\:from-cyan-50:focus {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .print\:focus\:from-cyan-100:focus {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .print\:focus\:from-cyan-200:focus {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .print\:focus\:from-cyan-300:focus {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .print\:focus\:from-cyan-400:focus {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .print\:focus\:from-cyan-500:focus {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .print\:focus\:from-cyan-600:focus {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .print\:focus\:from-cyan-700:focus {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .print\:focus\:from-cyan-800:focus {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .print\:focus\:from-cyan-900:focus {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .print\:focus\:from-cyan:focus {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .print\:focus\:from-cyan-A100:focus {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .print\:focus\:from-cyan-A200:focus {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .print\:focus\:from-cyan-A400:focus {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .print\:focus\:from-cyan-A700:focus {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .print\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .print\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .print\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .print\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .print\:focus\:via-grey-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .print\:focus\:via-grey-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .print\:focus\:via-grey-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .print\:focus\:via-grey-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .print\:focus\:via-grey-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .print\:focus\:via-grey-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:focus\:via-grey-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .print\:focus\:via-grey-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:focus\:via-grey-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .print\:focus\:via-grey-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .print\:focus\:via-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:focus\:via-grey-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .print\:focus\:via-grey-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .print\:focus\:via-grey-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .print\:focus\:via-grey-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .print\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .print\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .print\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .print\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .print\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .print\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .print\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .print\:focus\:via-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .print\:focus\:via-gray-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(0, 0, 0, 0.04), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .print\:focus\:via-gray-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .print\:focus\:via-gray-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .print\:focus\:via-gray-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .print\:focus\:via-gray-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .print\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .print\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .print\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .print\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .print\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .print\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .print\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .print\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .print\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .print\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .print\:focus\:via-red:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .print\:focus\:via-red-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .print\:focus\:via-red-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .print\:focus\:via-red-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .print\:focus\:via-red-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .print\:focus\:via-orange-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .print\:focus\:via-orange-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .print\:focus\:via-orange-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .print\:focus\:via-orange-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .print\:focus\:via-orange-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .print\:focus\:via-orange-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .print\:focus\:via-orange-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .print\:focus\:via-orange-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .print\:focus\:via-orange-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .print\:focus\:via-orange-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .print\:focus\:via-orange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .print\:focus\:via-orange-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .print\:focus\:via-orange-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .print\:focus\:via-orange-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .print\:focus\:via-orange-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .print\:focus\:via-deep-orange-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .print\:focus\:via-deep-orange-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .print\:focus\:via-deep-orange-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .print\:focus\:via-deep-orange-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .print\:focus\:via-deep-orange-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .print\:focus\:via-deep-orange-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .print\:focus\:via-deep-orange-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .print\:focus\:via-deep-orange-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .print\:focus\:via-deep-orange-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .print\:focus\:via-deep-orange-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .print\:focus\:via-deep-orange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .print\:focus\:via-deep-orange-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .print\:focus\:via-deep-orange-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .print\:focus\:via-deep-orange-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .print\:focus\:via-deep-orange-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .print\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .print\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .print\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .print\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .print\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .print\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .print\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .print\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .print\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .print\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .print\:focus\:via-yellow:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .print\:focus\:via-yellow-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .print\:focus\:via-yellow-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .print\:focus\:via-yellow-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .print\:focus\:via-yellow-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .print\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .print\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .print\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .print\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .print\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .print\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .print\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .print\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .print\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .print\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .print\:focus\:via-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .print\:focus\:via-green-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .print\:focus\:via-green-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .print\:focus\:via-green-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .print\:focus\:via-green-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .print\:focus\:via-light-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .print\:focus\:via-light-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .print\:focus\:via-light-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .print\:focus\:via-light-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .print\:focus\:via-light-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .print\:focus\:via-light-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .print\:focus\:via-light-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .print\:focus\:via-light-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .print\:focus\:via-light-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .print\:focus\:via-light-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .print\:focus\:via-light-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .print\:focus\:via-light-green-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .print\:focus\:via-light-green-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .print\:focus\:via-light-green-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .print\:focus\:via-light-green-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .print\:focus\:via-teal-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .print\:focus\:via-teal-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .print\:focus\:via-teal-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .print\:focus\:via-teal-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .print\:focus\:via-teal-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .print\:focus\:via-teal-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .print\:focus\:via-teal-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .print\:focus\:via-teal-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .print\:focus\:via-teal-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .print\:focus\:via-teal-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .print\:focus\:via-teal:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .print\:focus\:via-teal-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .print\:focus\:via-teal-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .print\:focus\:via-teal-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .print\:focus\:via-teal-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .print\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .print\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .print\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .print\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .print\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .print\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .print\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .print\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .print\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .print\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .print\:focus\:via-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .print\:focus\:via-blue-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .print\:focus\:via-blue-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .print\:focus\:via-blue-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .print\:focus\:via-blue-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .print\:focus\:via-light-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .print\:focus\:via-light-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .print\:focus\:via-light-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .print\:focus\:via-light-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .print\:focus\:via-light-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .print\:focus\:via-light-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .print\:focus\:via-light-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .print\:focus\:via-light-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .print\:focus\:via-light-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .print\:focus\:via-light-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .print\:focus\:via-light-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .print\:focus\:via-light-blue-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .print\:focus\:via-light-blue-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .print\:focus\:via-light-blue-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .print\:focus\:via-light-blue-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .print\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .print\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .print\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .print\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .print\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .print\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .print\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .print\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .print\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .print\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .print\:focus\:via-indigo:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .print\:focus\:via-indigo-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .print\:focus\:via-indigo-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .print\:focus\:via-indigo-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .print\:focus\:via-indigo-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .print\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .print\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .print\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .print\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .print\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .print\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .print\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .print\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .print\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .print\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .print\:focus\:via-purple:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .print\:focus\:via-purple-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .print\:focus\:via-purple-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .print\:focus\:via-purple-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .print\:focus\:via-purple-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .print\:focus\:via-deep-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .print\:focus\:via-deep-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .print\:focus\:via-deep-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .print\:focus\:via-deep-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .print\:focus\:via-deep-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .print\:focus\:via-deep-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .print\:focus\:via-deep-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .print\:focus\:via-deep-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .print\:focus\:via-deep-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .print\:focus\:via-deep-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .print\:focus\:via-deep-purple:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .print\:focus\:via-deep-purple-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .print\:focus\:via-deep-purple-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .print\:focus\:via-deep-purple-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .print\:focus\:via-deep-purple-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .print\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .print\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .print\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .print\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .print\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .print\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .print\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .print\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .print\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .print\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .print\:focus\:via-pink:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .print\:focus\:via-pink-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .print\:focus\:via-pink-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .print\:focus\:via-pink-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .print\:focus\:via-pink-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .print\:focus\:via-lime-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .print\:focus\:via-lime-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .print\:focus\:via-lime-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .print\:focus\:via-lime-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .print\:focus\:via-lime-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .print\:focus\:via-lime-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .print\:focus\:via-lime-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .print\:focus\:via-lime-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .print\:focus\:via-lime-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .print\:focus\:via-lime-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .print\:focus\:via-lime:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .print\:focus\:via-lime-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .print\:focus\:via-lime-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .print\:focus\:via-lime-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .print\:focus\:via-lime-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .print\:focus\:via-amber-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .print\:focus\:via-amber-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .print\:focus\:via-amber-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .print\:focus\:via-amber-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .print\:focus\:via-amber-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .print\:focus\:via-amber-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .print\:focus\:via-amber-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .print\:focus\:via-amber-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .print\:focus\:via-amber-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .print\:focus\:via-amber-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .print\:focus\:via-amber:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .print\:focus\:via-amber-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .print\:focus\:via-amber-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .print\:focus\:via-amber-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .print\:focus\:via-amber-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .print\:focus\:via-brown-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .print\:focus\:via-brown-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .print\:focus\:via-brown-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .print\:focus\:via-brown-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .print\:focus\:via-brown-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .print\:focus\:via-brown-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .print\:focus\:via-brown-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .print\:focus\:via-brown-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .print\:focus\:via-brown-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .print\:focus\:via-brown-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .print\:focus\:via-brown:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .print\:focus\:via-brown-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .print\:focus\:via-brown-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .print\:focus\:via-brown-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .print\:focus\:via-brown-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .print\:focus\:via-blue-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .print\:focus\:via-blue-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .print\:focus\:via-blue-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .print\:focus\:via-blue-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .print\:focus\:via-blue-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .print\:focus\:via-blue-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .print\:focus\:via-blue-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .print\:focus\:via-blue-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .print\:focus\:via-blue-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .print\:focus\:via-blue-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .print\:focus\:via-blue-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .print\:focus\:via-blue-gray-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .print\:focus\:via-blue-gray-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .print\:focus\:via-blue-gray-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .print\:focus\:via-blue-gray-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .print\:focus\:via-cyan-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .print\:focus\:via-cyan-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .print\:focus\:via-cyan-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .print\:focus\:via-cyan-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .print\:focus\:via-cyan-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .print\:focus\:via-cyan-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .print\:focus\:via-cyan-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .print\:focus\:via-cyan-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .print\:focus\:via-cyan-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .print\:focus\:via-cyan-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .print\:focus\:via-cyan:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .print\:focus\:via-cyan-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .print\:focus\:via-cyan-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .print\:focus\:via-cyan-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .print\:focus\:via-cyan-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .print\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent
  }

  .print\:focus\:to-current:focus {
    --tw-gradient-to: currentColor
  }

  .print\:focus\:to-black:focus {
    --tw-gradient-to: #22292F
  }

  .print\:focus\:to-white:focus {
    --tw-gradient-to: #fff
  }

  .print\:focus\:to-grey-50:focus {
    --tw-gradient-to: #FAFAFA
  }

  .print\:focus\:to-grey-100:focus {
    --tw-gradient-to: #F5F5F5
  }

  .print\:focus\:to-grey-200:focus {
    --tw-gradient-to: #EEEEEE
  }

  .print\:focus\:to-grey-300:focus {
    --tw-gradient-to: #E0E0E0
  }

  .print\:focus\:to-grey-400:focus {
    --tw-gradient-to: #BDBDBD
  }

  .print\:focus\:to-grey-500:focus {
    --tw-gradient-to: #9E9E9E
  }

  .print\:focus\:to-grey-600:focus {
    --tw-gradient-to: #757575
  }

  .print\:focus\:to-grey-700:focus {
    --tw-gradient-to: #616161
  }

  .print\:focus\:to-grey-800:focus {
    --tw-gradient-to: #424242
  }

  .print\:focus\:to-grey-900:focus {
    --tw-gradient-to: #212121
  }

  .print\:focus\:to-grey:focus {
    --tw-gradient-to: #9E9E9E
  }

  .print\:focus\:to-grey-A100:focus {
    --tw-gradient-to: #D5D5D5
  }

  .print\:focus\:to-grey-A200:focus {
    --tw-gradient-to: #AAAAAA
  }

  .print\:focus\:to-grey-A400:focus {
    --tw-gradient-to: #303030
  }

  .print\:focus\:to-grey-A700:focus {
    --tw-gradient-to: #616161
  }

  .print\:focus\:to-gray-50:focus {
    --tw-gradient-to: #FAFAFA
  }

  .print\:focus\:to-gray-100:focus {
    --tw-gradient-to: #F5F5F5
  }

  .print\:focus\:to-gray-200:focus {
    --tw-gradient-to: #EEEEEE
  }

  .print\:focus\:to-gray-300:focus {
    --tw-gradient-to: #E0E0E0
  }

  .print\:focus\:to-gray-400:focus {
    --tw-gradient-to: #BDBDBD
  }

  .print\:focus\:to-gray-500:focus {
    --tw-gradient-to: #9E9E9E
  }

  .print\:focus\:to-gray-600:focus {
    --tw-gradient-to: #757575
  }

  .print\:focus\:to-gray-700:focus {
    --tw-gradient-to: #616161
  }

  .print\:focus\:to-gray-800:focus {
    --tw-gradient-to: #424242
  }

  .print\:focus\:to-gray-900:focus {
    --tw-gradient-to: #212121
  }

  .print\:focus\:to-gray:focus {
    --tw-gradient-to: #9E9E9E
  }

  .print\:focus\:to-gray-hover:focus {
    --tw-gradient-to: rgba(0, 0, 0, 0.04)
  }

  .print\:focus\:to-gray-A100:focus {
    --tw-gradient-to: #D5D5D5
  }

  .print\:focus\:to-gray-A200:focus {
    --tw-gradient-to: #AAAAAA
  }

  .print\:focus\:to-gray-A400:focus {
    --tw-gradient-to: #303030
  }

  .print\:focus\:to-gray-A700:focus {
    --tw-gradient-to: #616161
  }

  .print\:focus\:to-red-50:focus {
    --tw-gradient-to: #FFEBEE
  }

  .print\:focus\:to-red-100:focus {
    --tw-gradient-to: #FFCDD2
  }

  .print\:focus\:to-red-200:focus {
    --tw-gradient-to: #EF9A9A
  }

  .print\:focus\:to-red-300:focus {
    --tw-gradient-to: #E57373
  }

  .print\:focus\:to-red-400:focus {
    --tw-gradient-to: #EF5350
  }

  .print\:focus\:to-red-500:focus {
    --tw-gradient-to: #F44336
  }

  .print\:focus\:to-red-600:focus {
    --tw-gradient-to: #E53935
  }

  .print\:focus\:to-red-700:focus {
    --tw-gradient-to: #D32F2F
  }

  .print\:focus\:to-red-800:focus {
    --tw-gradient-to: #C62828
  }

  .print\:focus\:to-red-900:focus {
    --tw-gradient-to: #B71C1C
  }

  .print\:focus\:to-red:focus {
    --tw-gradient-to: #F44336
  }

  .print\:focus\:to-red-A100:focus {
    --tw-gradient-to: #FF8A80
  }

  .print\:focus\:to-red-A200:focus {
    --tw-gradient-to: #FF5252
  }

  .print\:focus\:to-red-A400:focus {
    --tw-gradient-to: #FF1744
  }

  .print\:focus\:to-red-A700:focus {
    --tw-gradient-to: #D50000
  }

  .print\:focus\:to-orange-50:focus {
    --tw-gradient-to: #FFF3E0
  }

  .print\:focus\:to-orange-100:focus {
    --tw-gradient-to: #FFE0B2
  }

  .print\:focus\:to-orange-200:focus {
    --tw-gradient-to: #FFCC80
  }

  .print\:focus\:to-orange-300:focus {
    --tw-gradient-to: #FFB74D
  }

  .print\:focus\:to-orange-400:focus {
    --tw-gradient-to: #FFA726
  }

  .print\:focus\:to-orange-500:focus {
    --tw-gradient-to: #FF9800
  }

  .print\:focus\:to-orange-600:focus {
    --tw-gradient-to: #FB8C00
  }

  .print\:focus\:to-orange-700:focus {
    --tw-gradient-to: #F57C00
  }

  .print\:focus\:to-orange-800:focus {
    --tw-gradient-to: #EF6C00
  }

  .print\:focus\:to-orange-900:focus {
    --tw-gradient-to: #E65100
  }

  .print\:focus\:to-orange:focus {
    --tw-gradient-to: #FF9800
  }

  .print\:focus\:to-orange-A100:focus {
    --tw-gradient-to: #FFD180
  }

  .print\:focus\:to-orange-A200:focus {
    --tw-gradient-to: #FFAB40
  }

  .print\:focus\:to-orange-A400:focus {
    --tw-gradient-to: #FF9100
  }

  .print\:focus\:to-orange-A700:focus {
    --tw-gradient-to: #FF6D00
  }

  .print\:focus\:to-deep-orange-50:focus {
    --tw-gradient-to: #FBE9E7
  }

  .print\:focus\:to-deep-orange-100:focus {
    --tw-gradient-to: #FFCCBC
  }

  .print\:focus\:to-deep-orange-200:focus {
    --tw-gradient-to: #FFAB91
  }

  .print\:focus\:to-deep-orange-300:focus {
    --tw-gradient-to: #FF8A65
  }

  .print\:focus\:to-deep-orange-400:focus {
    --tw-gradient-to: #FF7043
  }

  .print\:focus\:to-deep-orange-500:focus {
    --tw-gradient-to: #FF5722
  }

  .print\:focus\:to-deep-orange-600:focus {
    --tw-gradient-to: #F4511E
  }

  .print\:focus\:to-deep-orange-700:focus {
    --tw-gradient-to: #E64A19
  }

  .print\:focus\:to-deep-orange-800:focus {
    --tw-gradient-to: #D84315
  }

  .print\:focus\:to-deep-orange-900:focus {
    --tw-gradient-to: #BF360C
  }

  .print\:focus\:to-deep-orange:focus {
    --tw-gradient-to: #FF5722
  }

  .print\:focus\:to-deep-orange-A100:focus {
    --tw-gradient-to: #FF9E80
  }

  .print\:focus\:to-deep-orange-A200:focus {
    --tw-gradient-to: #FF6E40
  }

  .print\:focus\:to-deep-orange-A400:focus {
    --tw-gradient-to: #FF3D00
  }

  .print\:focus\:to-deep-orange-A700:focus {
    --tw-gradient-to: #DD2C00
  }

  .print\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #FFFDE7
  }

  .print\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #FFF9C4
  }

  .print\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #FFF59D
  }

  .print\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #FFF176
  }

  .print\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #FFEE58
  }

  .print\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #FFEB3B
  }

  .print\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #FDD835
  }

  .print\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #FBC02D
  }

  .print\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #F9A825
  }

  .print\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #F57F17
  }

  .print\:focus\:to-yellow:focus {
    --tw-gradient-to: #FFEB3B
  }

  .print\:focus\:to-yellow-A100:focus {
    --tw-gradient-to: #FFFF8D
  }

  .print\:focus\:to-yellow-A200:focus {
    --tw-gradient-to: #FFFF00
  }

  .print\:focus\:to-yellow-A400:focus {
    --tw-gradient-to: #FFEA00
  }

  .print\:focus\:to-yellow-A700:focus {
    --tw-gradient-to: #FFD600
  }

  .print\:focus\:to-green-50:focus {
    --tw-gradient-to: #E8F5E9
  }

  .print\:focus\:to-green-100:focus {
    --tw-gradient-to: #C8E6C9
  }

  .print\:focus\:to-green-200:focus {
    --tw-gradient-to: #A5D6A7
  }

  .print\:focus\:to-green-300:focus {
    --tw-gradient-to: #81C784
  }

  .print\:focus\:to-green-400:focus {
    --tw-gradient-to: #66BB6A
  }

  .print\:focus\:to-green-500:focus {
    --tw-gradient-to: #4CAF50
  }

  .print\:focus\:to-green-600:focus {
    --tw-gradient-to: #43A047
  }

  .print\:focus\:to-green-700:focus {
    --tw-gradient-to: #388E3C
  }

  .print\:focus\:to-green-800:focus {
    --tw-gradient-to: #2E7D32
  }

  .print\:focus\:to-green-900:focus {
    --tw-gradient-to: #1B5E20
  }

  .print\:focus\:to-green:focus {
    --tw-gradient-to: #4CAF50
  }

  .print\:focus\:to-green-A100:focus {
    --tw-gradient-to: #B9F6CA
  }

  .print\:focus\:to-green-A200:focus {
    --tw-gradient-to: #69F0AE
  }

  .print\:focus\:to-green-A400:focus {
    --tw-gradient-to: #00E676
  }

  .print\:focus\:to-green-A700:focus {
    --tw-gradient-to: #00C853
  }

  .print\:focus\:to-light-green-50:focus {
    --tw-gradient-to: #F1F8E9
  }

  .print\:focus\:to-light-green-100:focus {
    --tw-gradient-to: #DCEDC8
  }

  .print\:focus\:to-light-green-200:focus {
    --tw-gradient-to: #C5E1A5
  }

  .print\:focus\:to-light-green-300:focus {
    --tw-gradient-to: #AED581
  }

  .print\:focus\:to-light-green-400:focus {
    --tw-gradient-to: #9CCC65
  }

  .print\:focus\:to-light-green-500:focus {
    --tw-gradient-to: #8BC34A
  }

  .print\:focus\:to-light-green-600:focus {
    --tw-gradient-to: #7CB342
  }

  .print\:focus\:to-light-green-700:focus {
    --tw-gradient-to: #689F38
  }

  .print\:focus\:to-light-green-800:focus {
    --tw-gradient-to: #558B2F
  }

  .print\:focus\:to-light-green-900:focus {
    --tw-gradient-to: #33691E
  }

  .print\:focus\:to-light-green:focus {
    --tw-gradient-to: #8BC34A
  }

  .print\:focus\:to-light-green-A100:focus {
    --tw-gradient-to: #CCFF90
  }

  .print\:focus\:to-light-green-A200:focus {
    --tw-gradient-to: #B2FF59
  }

  .print\:focus\:to-light-green-A400:focus {
    --tw-gradient-to: #76FF03
  }

  .print\:focus\:to-light-green-A700:focus {
    --tw-gradient-to: #64DD17
  }

  .print\:focus\:to-teal-50:focus {
    --tw-gradient-to: #E0F2F1
  }

  .print\:focus\:to-teal-100:focus {
    --tw-gradient-to: #B2DFDB
  }

  .print\:focus\:to-teal-200:focus {
    --tw-gradient-to: #80CBC4
  }

  .print\:focus\:to-teal-300:focus {
    --tw-gradient-to: #4DB6AC
  }

  .print\:focus\:to-teal-400:focus {
    --tw-gradient-to: #26A69A
  }

  .print\:focus\:to-teal-500:focus {
    --tw-gradient-to: #009688
  }

  .print\:focus\:to-teal-600:focus {
    --tw-gradient-to: #00897B
  }

  .print\:focus\:to-teal-700:focus {
    --tw-gradient-to: #00796B
  }

  .print\:focus\:to-teal-800:focus {
    --tw-gradient-to: #00695C
  }

  .print\:focus\:to-teal-900:focus {
    --tw-gradient-to: #004D40
  }

  .print\:focus\:to-teal:focus {
    --tw-gradient-to: #009688
  }

  .print\:focus\:to-teal-A100:focus {
    --tw-gradient-to: #A7FFEB
  }

  .print\:focus\:to-teal-A200:focus {
    --tw-gradient-to: #64FFDA
  }

  .print\:focus\:to-teal-A400:focus {
    --tw-gradient-to: #1DE9B6
  }

  .print\:focus\:to-teal-A700:focus {
    --tw-gradient-to: #00BFA5
  }

  .print\:focus\:to-blue-50:focus {
    --tw-gradient-to: #E3F2FD
  }

  .print\:focus\:to-blue-100:focus {
    --tw-gradient-to: #BBDEFB
  }

  .print\:focus\:to-blue-200:focus {
    --tw-gradient-to: #90CAF9
  }

  .print\:focus\:to-blue-300:focus {
    --tw-gradient-to: #64B5F6
  }

  .print\:focus\:to-blue-400:focus {
    --tw-gradient-to: #42A5F5
  }

  .print\:focus\:to-blue-500:focus {
    --tw-gradient-to: #2196F3
  }

  .print\:focus\:to-blue-600:focus {
    --tw-gradient-to: #1E88E5
  }

  .print\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1976D2
  }

  .print\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1565C0
  }

  .print\:focus\:to-blue-900:focus {
    --tw-gradient-to: #0D47A1
  }

  .print\:focus\:to-blue:focus {
    --tw-gradient-to: #2196F3
  }

  .print\:focus\:to-blue-A100:focus {
    --tw-gradient-to: #82B1FF
  }

  .print\:focus\:to-blue-A200:focus {
    --tw-gradient-to: #448AFF
  }

  .print\:focus\:to-blue-A400:focus {
    --tw-gradient-to: #2979FF
  }

  .print\:focus\:to-blue-A700:focus {
    --tw-gradient-to: #2962FF
  }

  .print\:focus\:to-light-blue-50:focus {
    --tw-gradient-to: #E1F5FE
  }

  .print\:focus\:to-light-blue-100:focus {
    --tw-gradient-to: #B3E5FC
  }

  .print\:focus\:to-light-blue-200:focus {
    --tw-gradient-to: #81D4FA
  }

  .print\:focus\:to-light-blue-300:focus {
    --tw-gradient-to: #4FC3F7
  }

  .print\:focus\:to-light-blue-400:focus {
    --tw-gradient-to: #29B6F6
  }

  .print\:focus\:to-light-blue-500:focus {
    --tw-gradient-to: #03A9F4
  }

  .print\:focus\:to-light-blue-600:focus {
    --tw-gradient-to: #039BE5
  }

  .print\:focus\:to-light-blue-700:focus {
    --tw-gradient-to: #0288D1
  }

  .print\:focus\:to-light-blue-800:focus {
    --tw-gradient-to: #0277BD
  }

  .print\:focus\:to-light-blue-900:focus {
    --tw-gradient-to: #01579B
  }

  .print\:focus\:to-light-blue:focus {
    --tw-gradient-to: #03A9F4
  }

  .print\:focus\:to-light-blue-A100:focus {
    --tw-gradient-to: #80D8FF
  }

  .print\:focus\:to-light-blue-A200:focus {
    --tw-gradient-to: #40C4FF
  }

  .print\:focus\:to-light-blue-A400:focus {
    --tw-gradient-to: #00B0FF
  }

  .print\:focus\:to-light-blue-A700:focus {
    --tw-gradient-to: #0091EA
  }

  .print\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #E8EAF6
  }

  .print\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #C5CAE9
  }

  .print\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #9FA8DA
  }

  .print\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #7986CB
  }

  .print\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #5C6BC0
  }

  .print\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #3F51B5
  }

  .print\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #3949AB
  }

  .print\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #303F9F
  }

  .print\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #283593
  }

  .print\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #1A237E
  }

  .print\:focus\:to-indigo:focus {
    --tw-gradient-to: #3F51B5
  }

  .print\:focus\:to-indigo-A100:focus {
    --tw-gradient-to: #8C9EFF
  }

  .print\:focus\:to-indigo-A200:focus {
    --tw-gradient-to: #536DFE
  }

  .print\:focus\:to-indigo-A400:focus {
    --tw-gradient-to: #3D5AFE
  }

  .print\:focus\:to-indigo-A700:focus {
    --tw-gradient-to: #304FFE
  }

  .print\:focus\:to-purple-50:focus {
    --tw-gradient-to: #F3E5F5
  }

  .print\:focus\:to-purple-100:focus {
    --tw-gradient-to: #E1BEE7
  }

  .print\:focus\:to-purple-200:focus {
    --tw-gradient-to: #CE93D8
  }

  .print\:focus\:to-purple-300:focus {
    --tw-gradient-to: #BA68C8
  }

  .print\:focus\:to-purple-400:focus {
    --tw-gradient-to: #AB47BC
  }

  .print\:focus\:to-purple-500:focus {
    --tw-gradient-to: #9C27B0
  }

  .print\:focus\:to-purple-600:focus {
    --tw-gradient-to: #8E24AA
  }

  .print\:focus\:to-purple-700:focus {
    --tw-gradient-to: #7B1FA2
  }

  .print\:focus\:to-purple-800:focus {
    --tw-gradient-to: #6A1B9A
  }

  .print\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4A148C
  }

  .print\:focus\:to-purple:focus {
    --tw-gradient-to: #9C27B0
  }

  .print\:focus\:to-purple-A100:focus {
    --tw-gradient-to: #EA80FC
  }

  .print\:focus\:to-purple-A200:focus {
    --tw-gradient-to: #E040FB
  }

  .print\:focus\:to-purple-A400:focus {
    --tw-gradient-to: #D500F9
  }

  .print\:focus\:to-purple-A700:focus {
    --tw-gradient-to: #AA00FF
  }

  .print\:focus\:to-deep-purple-50:focus {
    --tw-gradient-to: #EDE7F6
  }

  .print\:focus\:to-deep-purple-100:focus {
    --tw-gradient-to: #D1C4E9
  }

  .print\:focus\:to-deep-purple-200:focus {
    --tw-gradient-to: #B39DDB
  }

  .print\:focus\:to-deep-purple-300:focus {
    --tw-gradient-to: #9575CD
  }

  .print\:focus\:to-deep-purple-400:focus {
    --tw-gradient-to: #7E57C2
  }

  .print\:focus\:to-deep-purple-500:focus {
    --tw-gradient-to: #673AB7
  }

  .print\:focus\:to-deep-purple-600:focus {
    --tw-gradient-to: #5E35B1
  }

  .print\:focus\:to-deep-purple-700:focus {
    --tw-gradient-to: #512DA8
  }

  .print\:focus\:to-deep-purple-800:focus {
    --tw-gradient-to: #4527A0
  }

  .print\:focus\:to-deep-purple-900:focus {
    --tw-gradient-to: #311B92
  }

  .print\:focus\:to-deep-purple:focus {
    --tw-gradient-to: #673AB7
  }

  .print\:focus\:to-deep-purple-A100:focus {
    --tw-gradient-to: #B388FF
  }

  .print\:focus\:to-deep-purple-A200:focus {
    --tw-gradient-to: #7C4DFF
  }

  .print\:focus\:to-deep-purple-A400:focus {
    --tw-gradient-to: #651FFF
  }

  .print\:focus\:to-deep-purple-A700:focus {
    --tw-gradient-to: #6200EA
  }

  .print\:focus\:to-pink-50:focus {
    --tw-gradient-to: #FCE4EC
  }

  .print\:focus\:to-pink-100:focus {
    --tw-gradient-to: #F8BBD0
  }

  .print\:focus\:to-pink-200:focus {
    --tw-gradient-to: #F48FB1
  }

  .print\:focus\:to-pink-300:focus {
    --tw-gradient-to: #F06292
  }

  .print\:focus\:to-pink-400:focus {
    --tw-gradient-to: #EC407A
  }

  .print\:focus\:to-pink-500:focus {
    --tw-gradient-to: #E91E63
  }

  .print\:focus\:to-pink-600:focus {
    --tw-gradient-to: #D81B60
  }

  .print\:focus\:to-pink-700:focus {
    --tw-gradient-to: #C2185B
  }

  .print\:focus\:to-pink-800:focus {
    --tw-gradient-to: #AD1457
  }

  .print\:focus\:to-pink-900:focus {
    --tw-gradient-to: #880E4F
  }

  .print\:focus\:to-pink:focus {
    --tw-gradient-to: #E91E63
  }

  .print\:focus\:to-pink-A100:focus {
    --tw-gradient-to: #FF80AB
  }

  .print\:focus\:to-pink-A200:focus {
    --tw-gradient-to: #FF4081
  }

  .print\:focus\:to-pink-A400:focus {
    --tw-gradient-to: #F50057
  }

  .print\:focus\:to-pink-A700:focus {
    --tw-gradient-to: #C51162
  }

  .print\:focus\:to-lime-50:focus {
    --tw-gradient-to: #F9FBE7
  }

  .print\:focus\:to-lime-100:focus {
    --tw-gradient-to: #F0F4C3
  }

  .print\:focus\:to-lime-200:focus {
    --tw-gradient-to: #E6EE9C
  }

  .print\:focus\:to-lime-300:focus {
    --tw-gradient-to: #DCE775
  }

  .print\:focus\:to-lime-400:focus {
    --tw-gradient-to: #D4E157
  }

  .print\:focus\:to-lime-500:focus {
    --tw-gradient-to: #CDDC39
  }

  .print\:focus\:to-lime-600:focus {
    --tw-gradient-to: #C0CA33
  }

  .print\:focus\:to-lime-700:focus {
    --tw-gradient-to: #AFB42B
  }

  .print\:focus\:to-lime-800:focus {
    --tw-gradient-to: #9E9D24
  }

  .print\:focus\:to-lime-900:focus {
    --tw-gradient-to: #827717
  }

  .print\:focus\:to-lime:focus {
    --tw-gradient-to: #CDDC39
  }

  .print\:focus\:to-lime-A100:focus {
    --tw-gradient-to: #F4FF81
  }

  .print\:focus\:to-lime-A200:focus {
    --tw-gradient-to: #EEFF41
  }

  .print\:focus\:to-lime-A400:focus {
    --tw-gradient-to: #C6FF00
  }

  .print\:focus\:to-lime-A700:focus {
    --tw-gradient-to: #AEEA00
  }

  .print\:focus\:to-amber-50:focus {
    --tw-gradient-to: #FFF8E1
  }

  .print\:focus\:to-amber-100:focus {
    --tw-gradient-to: #FFECB3
  }

  .print\:focus\:to-amber-200:focus {
    --tw-gradient-to: #FFE082
  }

  .print\:focus\:to-amber-300:focus {
    --tw-gradient-to: #FFD54F
  }

  .print\:focus\:to-amber-400:focus {
    --tw-gradient-to: #FFCA28
  }

  .print\:focus\:to-amber-500:focus {
    --tw-gradient-to: #FFC107
  }

  .print\:focus\:to-amber-600:focus {
    --tw-gradient-to: #FFB300
  }

  .print\:focus\:to-amber-700:focus {
    --tw-gradient-to: #FFA000
  }

  .print\:focus\:to-amber-800:focus {
    --tw-gradient-to: #FF8F00
  }

  .print\:focus\:to-amber-900:focus {
    --tw-gradient-to: #FF6F00
  }

  .print\:focus\:to-amber:focus {
    --tw-gradient-to: #FFC107
  }

  .print\:focus\:to-amber-A100:focus {
    --tw-gradient-to: #FFE57F
  }

  .print\:focus\:to-amber-A200:focus {
    --tw-gradient-to: #FFD740
  }

  .print\:focus\:to-amber-A400:focus {
    --tw-gradient-to: #FFC400
  }

  .print\:focus\:to-amber-A700:focus {
    --tw-gradient-to: #FFAB00
  }

  .print\:focus\:to-brown-50:focus {
    --tw-gradient-to: #EFEBE9
  }

  .print\:focus\:to-brown-100:focus {
    --tw-gradient-to: #D7CCC8
  }

  .print\:focus\:to-brown-200:focus {
    --tw-gradient-to: #BCAAA4
  }

  .print\:focus\:to-brown-300:focus {
    --tw-gradient-to: #A1887F
  }

  .print\:focus\:to-brown-400:focus {
    --tw-gradient-to: #8D6E63
  }

  .print\:focus\:to-brown-500:focus {
    --tw-gradient-to: #795548
  }

  .print\:focus\:to-brown-600:focus {
    --tw-gradient-to: #6D4C41
  }

  .print\:focus\:to-brown-700:focus {
    --tw-gradient-to: #5D4037
  }

  .print\:focus\:to-brown-800:focus {
    --tw-gradient-to: #4E342E
  }

  .print\:focus\:to-brown-900:focus {
    --tw-gradient-to: #3E2723
  }

  .print\:focus\:to-brown:focus {
    --tw-gradient-to: #795548
  }

  .print\:focus\:to-brown-A100:focus {
    --tw-gradient-to: #D7CCC8
  }

  .print\:focus\:to-brown-A200:focus {
    --tw-gradient-to: #BCAAA4
  }

  .print\:focus\:to-brown-A400:focus {
    --tw-gradient-to: #8D6E63
  }

  .print\:focus\:to-brown-A700:focus {
    --tw-gradient-to: #5D4037
  }

  .print\:focus\:to-blue-gray-50:focus {
    --tw-gradient-to: #ECEFF1
  }

  .print\:focus\:to-blue-gray-100:focus {
    --tw-gradient-to: #CFD8DC
  }

  .print\:focus\:to-blue-gray-200:focus {
    --tw-gradient-to: #B0BEC5
  }

  .print\:focus\:to-blue-gray-300:focus {
    --tw-gradient-to: #90A4AE
  }

  .print\:focus\:to-blue-gray-400:focus {
    --tw-gradient-to: #78909C
  }

  .print\:focus\:to-blue-gray-500:focus {
    --tw-gradient-to: #607D8B
  }

  .print\:focus\:to-blue-gray-600:focus {
    --tw-gradient-to: #546E7A
  }

  .print\:focus\:to-blue-gray-700:focus {
    --tw-gradient-to: #455A64
  }

  .print\:focus\:to-blue-gray-800:focus {
    --tw-gradient-to: #37474F
  }

  .print\:focus\:to-blue-gray-900:focus {
    --tw-gradient-to: #263238
  }

  .print\:focus\:to-blue-gray:focus {
    --tw-gradient-to: #607D8B
  }

  .print\:focus\:to-blue-gray-A100:focus {
    --tw-gradient-to: #CFD8DC
  }

  .print\:focus\:to-blue-gray-A200:focus {
    --tw-gradient-to: #B0BEC5
  }

  .print\:focus\:to-blue-gray-A400:focus {
    --tw-gradient-to: #78909C
  }

  .print\:focus\:to-blue-gray-A700:focus {
    --tw-gradient-to: #455A64
  }

  .print\:focus\:to-cyan-50:focus {
    --tw-gradient-to: #E0F7FA
  }

  .print\:focus\:to-cyan-100:focus {
    --tw-gradient-to: #B2EBF2
  }

  .print\:focus\:to-cyan-200:focus {
    --tw-gradient-to: #80DEEA
  }

  .print\:focus\:to-cyan-300:focus {
    --tw-gradient-to: #4DD0E1
  }

  .print\:focus\:to-cyan-400:focus {
    --tw-gradient-to: #26C6DA
  }

  .print\:focus\:to-cyan-500:focus {
    --tw-gradient-to: #00BCD4
  }

  .print\:focus\:to-cyan-600:focus {
    --tw-gradient-to: #00ACC1
  }

  .print\:focus\:to-cyan-700:focus {
    --tw-gradient-to: #0097A7
  }

  .print\:focus\:to-cyan-800:focus {
    --tw-gradient-to: #00838F
  }

  .print\:focus\:to-cyan-900:focus {
    --tw-gradient-to: #006064
  }

  .print\:focus\:to-cyan:focus {
    --tw-gradient-to: #00BCD4
  }

  .print\:focus\:to-cyan-A100:focus {
    --tw-gradient-to: #84FFFF
  }

  .print\:focus\:to-cyan-A200:focus {
    --tw-gradient-to: #18FFFF
  }

  .print\:focus\:to-cyan-A400:focus {
    --tw-gradient-to: #00E5FF
  }

  .print\:focus\:to-cyan-A700:focus {
    --tw-gradient-to: #00B8D4
  }

  .print\:bg-opacity-0 {
    --tw-bg-opacity: 0
  }

  .print\:bg-opacity-5 {
    --tw-bg-opacity: 0.05
  }

  .print\:bg-opacity-10 {
    --tw-bg-opacity: 0.1
  }

  .print\:bg-opacity-20 {
    --tw-bg-opacity: 0.2
  }

  .print\:bg-opacity-25 {
    --tw-bg-opacity: 0.25
  }

  .print\:bg-opacity-30 {
    --tw-bg-opacity: 0.3
  }

  .print\:bg-opacity-40 {
    --tw-bg-opacity: 0.4
  }

  .print\:bg-opacity-50 {
    --tw-bg-opacity: 0.5
  }

  .print\:bg-opacity-60 {
    --tw-bg-opacity: 0.6
  }

  .print\:bg-opacity-70 {
    --tw-bg-opacity: 0.7
  }

  .print\:bg-opacity-75 {
    --tw-bg-opacity: 0.75
  }

  .print\:bg-opacity-80 {
    --tw-bg-opacity: 0.8
  }

  .print\:bg-opacity-90 {
    --tw-bg-opacity: 0.9
  }

  .print\:bg-opacity-95 {
    --tw-bg-opacity: 0.95
  }

  .print\:bg-opacity-100 {
    --tw-bg-opacity: 1
  }

  .group:hover .print\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0
  }

  .group:hover .print\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05
  }

  .group:hover .print\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1
  }

  .group:hover .print\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2
  }

  .group:hover .print\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25
  }

  .group:hover .print\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3
  }

  .group:hover .print\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4
  }

  .group:hover .print\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5
  }

  .group:hover .print\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6
  }

  .group:hover .print\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7
  }

  .group:hover .print\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75
  }

  .group:hover .print\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8
  }

  .group:hover .print\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9
  }

  .group:hover .print\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95
  }

  .group:hover .print\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1
  }

  .print\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0
  }

  .print\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05
  }

  .print\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1
  }

  .print\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2
  }

  .print\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25
  }

  .print\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3
  }

  .print\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4
  }

  .print\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5
  }

  .print\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6
  }

  .print\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7
  }

  .print\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75
  }

  .print\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8
  }

  .print\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9
  }

  .print\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95
  }

  .print\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1
  }

  .print\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0
  }

  .print\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05
  }

  .print\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1
  }

  .print\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2
  }

  .print\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25
  }

  .print\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3
  }

  .print\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4
  }

  .print\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5
  }

  .print\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6
  }

  .print\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7
  }

  .print\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75
  }

  .print\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8
  }

  .print\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9
  }

  .print\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95
  }

  .print\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1
  }

  .print\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0
  }

  .print\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05
  }

  .print\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1
  }

  .print\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2
  }

  .print\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25
  }

  .print\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3
  }

  .print\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4
  }

  .print\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5
  }

  .print\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6
  }

  .print\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7
  }

  .print\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75
  }

  .print\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8
  }

  .print\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9
  }

  .print\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95
  }

  .print\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1
  }

  .print\:bg-bottom {
    background-position: bottom
  }

  .print\:bg-center {
    background-position: center
  }

  .print\:bg-left {
    background-position: left
  }

  .print\:bg-left-bottom {
    background-position: left bottom
  }

  .print\:bg-left-top {
    background-position: left top
  }

  .print\:bg-right {
    background-position: right
  }

  .print\:bg-right-bottom {
    background-position: right bottom
  }

  .print\:bg-right-top {
    background-position: right top
  }

  .print\:bg-top {
    background-position: top
  }

  .print\:bg-repeat {
    background-repeat: repeat
  }

  .print\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .print\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .print\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .print\:bg-repeat-round {
    background-repeat: round
  }

  .print\:bg-repeat-space {
    background-repeat: space
  }

  .print\:bg-auto {
    background-size: auto
  }

  .print\:bg-cover {
    background-size: cover
  }

  .print\:bg-contain {
    background-size: contain
  }

  .print\:border-collapse {
    border-collapse: collapse
  }

  .print\:border-separate {
    border-collapse: separate
  }

  .print\:border-transparent {
    border-color: transparent
  }

  .print\:border-current {
    border-color: currentColor
  }

  .print\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .print\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .print\:border-grey-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .print\:border-grey-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .print\:border-grey-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .print\:border-grey-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .print\:border-grey-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .print\:border-grey-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:border-grey-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .print\:border-grey-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:border-grey-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .print\:border-grey-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .print\:border-grey {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:border-grey-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .print\:border-grey-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .print\:border-grey-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .print\:border-grey-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .print\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .print\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .print\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .print\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .print\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .print\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .print\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .print\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:border-gray-hover {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .print\:border-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .print\:border-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .print\:border-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .print\:border-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .print\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .print\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .print\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .print\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .print\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .print\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .print\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .print\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .print\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .print\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .print\:border-red-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .print\:border-red-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .print\:border-red-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .print\:border-red-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .print\:border-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .print\:border-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .print\:border-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .print\:border-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .print\:border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .print\:border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .print\:border-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .print\:border-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .print\:border-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .print\:border-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .print\:border-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .print\:border-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .print\:border-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .print\:border-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .print\:border-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .print\:border-deep-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .print\:border-deep-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .print\:border-deep-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .print\:border-deep-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .print\:border-deep-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .print\:border-deep-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .print\:border-deep-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .print\:border-deep-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .print\:border-deep-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .print\:border-deep-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .print\:border-deep-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .print\:border-deep-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .print\:border-deep-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .print\:border-deep-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .print\:border-deep-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .print\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .print\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .print\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .print\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .print\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .print\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .print\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .print\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .print\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .print\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .print\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .print\:border-yellow-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .print\:border-yellow-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .print\:border-yellow-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .print\:border-yellow-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .print\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .print\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .print\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .print\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .print\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .print\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .print\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .print\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .print\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .print\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .print\:border-green {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .print\:border-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .print\:border-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .print\:border-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .print\:border-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .print\:border-light-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .print\:border-light-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .print\:border-light-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .print\:border-light-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .print\:border-light-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .print\:border-light-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .print\:border-light-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .print\:border-light-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .print\:border-light-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .print\:border-light-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .print\:border-light-green {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .print\:border-light-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .print\:border-light-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .print\:border-light-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .print\:border-light-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .print\:border-teal-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .print\:border-teal-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .print\:border-teal-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .print\:border-teal-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .print\:border-teal-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .print\:border-teal-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .print\:border-teal-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .print\:border-teal-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .print\:border-teal-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .print\:border-teal-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .print\:border-teal {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .print\:border-teal-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .print\:border-teal-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .print\:border-teal-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .print\:border-teal-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .print\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .print\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .print\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .print\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .print\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .print\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .print\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .print\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .print\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .print\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .print\:border-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .print\:border-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .print\:border-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .print\:border-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .print\:border-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .print\:border-light-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .print\:border-light-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .print\:border-light-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .print\:border-light-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .print\:border-light-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .print\:border-light-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .print\:border-light-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .print\:border-light-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .print\:border-light-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .print\:border-light-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .print\:border-light-blue {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .print\:border-light-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .print\:border-light-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .print\:border-light-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .print\:border-light-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .print\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .print\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .print\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .print\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .print\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .print\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .print\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .print\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .print\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .print\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .print\:border-indigo {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .print\:border-indigo-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .print\:border-indigo-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .print\:border-indigo-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .print\:border-indigo-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .print\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .print\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .print\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .print\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .print\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .print\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .print\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .print\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .print\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .print\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .print\:border-purple {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .print\:border-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .print\:border-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .print\:border-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .print\:border-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .print\:border-deep-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .print\:border-deep-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .print\:border-deep-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .print\:border-deep-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .print\:border-deep-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .print\:border-deep-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .print\:border-deep-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .print\:border-deep-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .print\:border-deep-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .print\:border-deep-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .print\:border-deep-purple {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .print\:border-deep-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .print\:border-deep-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .print\:border-deep-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .print\:border-deep-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .print\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .print\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .print\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .print\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .print\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .print\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .print\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .print\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .print\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .print\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .print\:border-pink {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .print\:border-pink-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .print\:border-pink-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .print\:border-pink-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .print\:border-pink-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .print\:border-lime-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .print\:border-lime-100 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .print\:border-lime-200 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .print\:border-lime-300 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .print\:border-lime-400 {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .print\:border-lime-500 {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .print\:border-lime-600 {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .print\:border-lime-700 {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .print\:border-lime-800 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .print\:border-lime-900 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .print\:border-lime {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .print\:border-lime-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .print\:border-lime-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .print\:border-lime-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .print\:border-lime-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .print\:border-amber-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .print\:border-amber-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .print\:border-amber-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .print\:border-amber-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .print\:border-amber-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .print\:border-amber-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .print\:border-amber-600 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .print\:border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .print\:border-amber-800 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .print\:border-amber-900 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .print\:border-amber {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .print\:border-amber-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .print\:border-amber-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .print\:border-amber-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .print\:border-amber-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .print\:border-brown-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .print\:border-brown-100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .print\:border-brown-200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .print\:border-brown-300 {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .print\:border-brown-400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .print\:border-brown-500 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .print\:border-brown-600 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .print\:border-brown-700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .print\:border-brown-800 {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .print\:border-brown-900 {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .print\:border-brown {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .print\:border-brown-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .print\:border-brown-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .print\:border-brown-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .print\:border-brown-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .print\:border-blue-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .print\:border-blue-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .print\:border-blue-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .print\:border-blue-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .print\:border-blue-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .print\:border-blue-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .print\:border-blue-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .print\:border-blue-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .print\:border-blue-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .print\:border-blue-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .print\:border-blue-gray {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .print\:border-blue-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .print\:border-blue-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .print\:border-blue-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .print\:border-blue-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .print\:border-cyan-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .print\:border-cyan-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .print\:border-cyan-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .print\:border-cyan-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .print\:border-cyan-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .print\:border-cyan-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .print\:border-cyan-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .print\:border-cyan-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .print\:border-cyan-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .print\:border-cyan-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .print\:border-cyan {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .print\:border-cyan-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .print\:border-cyan-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .print\:border-cyan-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .print\:border-cyan-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-transparent {
    border-color: transparent
  }

  .group:hover .print\:group-hover\:border-current {
    border-color: currentColor
  }

  .group:hover .print\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-grey-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-grey-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-grey-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-grey-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-grey-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-grey-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-grey-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-grey-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-grey-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-grey-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-grey {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-grey-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-grey-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-grey-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-grey-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-gray-hover {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .group:hover .print\:group-hover\:border-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-red-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-red-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-red-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-red-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-orange {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-yellow-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-yellow-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-yellow-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-yellow-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-green {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-green {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-teal-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-teal-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-teal-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-teal-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-teal-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-teal-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-teal-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-teal-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-teal-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-teal-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-teal {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-teal-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-teal-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-teal-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-teal-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-blue {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-light-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-indigo {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-indigo-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-indigo-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-indigo-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-indigo-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-purple {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-purple {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-deep-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-pink {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-pink-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-pink-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-pink-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-pink-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-lime-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-lime-100 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-lime-200 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-lime-300 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-lime-400 {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-lime-500 {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-lime-600 {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-lime-700 {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-lime-800 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-lime-900 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-lime {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-lime-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-lime-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-lime-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-lime-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-amber-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-amber-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-amber-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-amber-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-amber-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-amber-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-amber-600 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-amber-800 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-amber-900 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-amber {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-amber-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-amber-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-amber-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-amber-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-brown-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-brown-100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-brown-200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-brown-300 {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-brown-400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-brown-500 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-brown-600 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-brown-700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-brown-800 {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-brown-900 {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-brown {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-brown-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-brown-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-brown-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-brown-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-gray {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-blue-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-cyan-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-cyan-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-cyan-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-cyan-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-cyan-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-cyan-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-cyan-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-cyan-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-cyan-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-cyan-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-cyan {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-cyan-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-cyan-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-cyan-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .group:hover .print\:group-hover\:border-cyan-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-transparent:focus-within {
    border-color: transparent
  }

  .print\:focus-within\:border-current:focus-within {
    border-color: currentColor
  }

  .print\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-grey-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-grey-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-grey-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-grey-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-grey-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-grey-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-grey-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-grey-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-grey-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-grey-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-grey-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-grey-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-grey-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-grey-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-gray-hover:focus-within {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .print\:focus-within\:border-gray-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-gray-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-gray-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-gray-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-red:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-red-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-red-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-red-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-red-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-orange-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-orange-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-orange-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-orange-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-orange-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-orange-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-orange-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-orange-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-orange-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-orange-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-orange:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-orange-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-orange-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-orange-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-orange-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-orange-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-orange-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-orange-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-orange-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-orange-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-orange-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-orange-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-orange-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-orange-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-orange-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-orange:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-orange-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-orange-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-orange-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-orange-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-yellow:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-yellow-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-yellow-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-yellow-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-yellow-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-green-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-green-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-green-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-green-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-green-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-green-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-green-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-green-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-teal-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-teal-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-teal-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-teal-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-teal-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-teal-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-teal-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-teal-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-teal-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-teal-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-teal:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-teal-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-teal-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-teal-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-teal-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-blue-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-blue-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-blue-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-light-blue-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-indigo:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-indigo-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-indigo-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-indigo-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-indigo-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-purple:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-purple-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-purple-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-purple-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-purple-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-purple:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-purple-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-purple-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-purple-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-deep-purple-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-pink:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-pink-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-pink-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-pink-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-pink-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-lime-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-lime-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-lime-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-lime-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-lime-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-lime-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-lime-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-lime-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-lime-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-lime-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-lime:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-lime-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-lime-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-lime-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-lime-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-amber-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-amber-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-amber-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-amber-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-amber-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-amber-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-amber-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-amber-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-amber-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-amber-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-amber:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-amber-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-amber-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-amber-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-amber-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-brown-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-brown-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-brown-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-brown-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-brown-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-brown-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-brown-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-brown-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-brown-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-brown-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-brown:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-brown-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-brown-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-brown-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-brown-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-gray-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-gray-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-gray-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-blue-gray-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-cyan-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-cyan-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-cyan-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-cyan-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-cyan-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-cyan-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-cyan-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-cyan-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-cyan-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-cyan-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-cyan:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-cyan-A100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-cyan-A200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-cyan-A400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .print\:focus-within\:border-cyan-A700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .print\:hover\:border-transparent:hover {
    border-color: transparent
  }

  .print\:hover\:border-current:hover {
    border-color: currentColor
  }

  .print\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .print\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-grey-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .print\:hover\:border-grey-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .print\:hover\:border-grey-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .print\:hover\:border-grey-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .print\:hover\:border-grey-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .print\:hover\:border-grey-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:hover\:border-grey-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .print\:hover\:border-grey-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:hover\:border-grey-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .print\:hover\:border-grey-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .print\:hover\:border-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:hover\:border-grey-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .print\:hover\:border-grey-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .print\:hover\:border-grey-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .print\:hover\:border-grey-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .print\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .print\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .print\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .print\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .print\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .print\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .print\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .print\:hover\:border-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:hover\:border-gray-hover:hover {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .print\:hover\:border-gray-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .print\:hover\:border-gray-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .print\:hover\:border-gray-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .print\:hover\:border-gray-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .print\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .print\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .print\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .print\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .print\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .print\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .print\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .print\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .print\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .print\:hover\:border-red:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .print\:hover\:border-red-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .print\:hover\:border-red-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .print\:hover\:border-red-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .print\:hover\:border-red-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-orange-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .print\:hover\:border-orange-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .print\:hover\:border-orange-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .print\:hover\:border-orange-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .print\:hover\:border-orange-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .print\:hover\:border-orange-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-orange-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-orange-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-orange-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-orange-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-orange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-orange-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .print\:hover\:border-orange-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .print\:hover\:border-orange-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-orange-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-orange-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-orange-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-orange-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-orange-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-orange-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-orange-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-orange-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-orange-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-orange-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-orange-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-orange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-orange-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-orange-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-orange-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-orange-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .print\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .print\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .print\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .print\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .print\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .print\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .print\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .print\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .print\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .print\:hover\:border-yellow:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .print\:hover\:border-yellow-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .print\:hover\:border-yellow-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-yellow-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-yellow-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .print\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .print\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .print\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .print\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .print\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .print\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .print\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .print\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .print\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .print\:hover\:border-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .print\:hover\:border-green-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .print\:hover\:border-green-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .print\:hover\:border-green-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .print\:hover\:border-green-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-green-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-green-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-green-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-green-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .print\:hover\:border-teal-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .print\:hover\:border-teal-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .print\:hover\:border-teal-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .print\:hover\:border-teal-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .print\:hover\:border-teal-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .print\:hover\:border-teal-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .print\:hover\:border-teal-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .print\:hover\:border-teal-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .print\:hover\:border-teal-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .print\:hover\:border-teal-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .print\:hover\:border-teal:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .print\:hover\:border-teal-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .print\:hover\:border-teal-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .print\:hover\:border-teal-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .print\:hover\:border-teal-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-blue-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-blue-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-blue-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-light-blue-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .print\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .print\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .print\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .print\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .print\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .print\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .print\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .print\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .print\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .print\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .print\:hover\:border-indigo:hover {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .print\:hover\:border-indigo-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-indigo-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .print\:hover\:border-indigo-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .print\:hover\:border-indigo-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .print\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .print\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .print\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .print\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .print\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .print\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .print\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .print\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .print\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .print\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .print\:hover\:border-purple:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .print\:hover\:border-purple-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .print\:hover\:border-purple-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .print\:hover\:border-purple-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .print\:hover\:border-purple-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-purple:hover {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-purple-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-purple-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-purple-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-deep-purple-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .print\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .print\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .print\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .print\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .print\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .print\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .print\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .print\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .print\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .print\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .print\:hover\:border-pink:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .print\:hover\:border-pink-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .print\:hover\:border-pink-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .print\:hover\:border-pink-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .print\:hover\:border-pink-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .print\:hover\:border-lime-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .print\:hover\:border-lime-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .print\:hover\:border-lime-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .print\:hover\:border-lime-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .print\:hover\:border-lime-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .print\:hover\:border-lime-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .print\:hover\:border-lime-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .print\:hover\:border-lime-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .print\:hover\:border-lime-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .print\:hover\:border-lime-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .print\:hover\:border-lime:hover {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .print\:hover\:border-lime-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .print\:hover\:border-lime-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .print\:hover\:border-lime-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-lime-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-amber-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .print\:hover\:border-amber-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .print\:hover\:border-amber-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .print\:hover\:border-amber-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .print\:hover\:border-amber-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .print\:hover\:border-amber-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .print\:hover\:border-amber-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-amber-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-amber-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-amber-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-amber:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .print\:hover\:border-amber-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .print\:hover\:border-amber-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .print\:hover\:border-amber-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-amber-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .print\:hover\:border-brown-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .print\:hover\:border-brown-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .print\:hover\:border-brown-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .print\:hover\:border-brown-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .print\:hover\:border-brown-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .print\:hover\:border-brown-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .print\:hover\:border-brown-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .print\:hover\:border-brown-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .print\:hover\:border-brown-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .print\:hover\:border-brown-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .print\:hover\:border-brown:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .print\:hover\:border-brown-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .print\:hover\:border-brown-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .print\:hover\:border-brown-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .print\:hover\:border-brown-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-gray-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-gray-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-gray-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .print\:hover\:border-blue-gray-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .print\:hover\:border-cyan-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .print\:hover\:border-cyan-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .print\:hover\:border-cyan-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .print\:hover\:border-cyan-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .print\:hover\:border-cyan-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .print\:hover\:border-cyan-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .print\:hover\:border-cyan-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .print\:hover\:border-cyan-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .print\:hover\:border-cyan-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .print\:hover\:border-cyan-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .print\:hover\:border-cyan:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .print\:hover\:border-cyan-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-cyan-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-cyan-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .print\:hover\:border-cyan-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .print\:focus\:border-transparent:focus {
    border-color: transparent
  }

  .print\:focus\:border-current:focus {
    border-color: currentColor
  }

  .print\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .print\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-grey-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .print\:focus\:border-grey-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .print\:focus\:border-grey-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .print\:focus\:border-grey-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .print\:focus\:border-grey-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .print\:focus\:border-grey-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:focus\:border-grey-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .print\:focus\:border-grey-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:focus\:border-grey-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .print\:focus\:border-grey-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .print\:focus\:border-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:focus\:border-grey-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .print\:focus\:border-grey-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .print\:focus\:border-grey-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .print\:focus\:border-grey-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .print\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .print\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .print\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .print\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .print\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .print\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .print\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .print\:focus\:border-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .print\:focus\:border-gray-hover:focus {
    border-color: rgba(0, 0, 0, 0.04)
  }

  .print\:focus\:border-gray-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .print\:focus\:border-gray-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .print\:focus\:border-gray-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .print\:focus\:border-gray-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .print\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .print\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .print\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .print\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .print\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .print\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .print\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .print\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .print\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .print\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .print\:focus\:border-red:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .print\:focus\:border-red-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .print\:focus\:border-red-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .print\:focus\:border-red-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .print\:focus\:border-red-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-orange-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .print\:focus\:border-orange-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .print\:focus\:border-orange-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .print\:focus\:border-orange-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .print\:focus\:border-orange-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .print\:focus\:border-orange-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-orange-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-orange-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-orange-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-orange-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-orange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-orange-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .print\:focus\:border-orange-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .print\:focus\:border-orange-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-orange-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-orange-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-orange-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-orange-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-orange-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-orange-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-orange-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-orange-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-orange-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-orange-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-orange-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-orange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-orange-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-orange-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-orange-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-orange-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .print\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .print\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .print\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .print\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .print\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .print\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .print\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .print\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .print\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .print\:focus\:border-yellow:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .print\:focus\:border-yellow-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .print\:focus\:border-yellow-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-yellow-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-yellow-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .print\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .print\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .print\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .print\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .print\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .print\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .print\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .print\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .print\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .print\:focus\:border-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .print\:focus\:border-green-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .print\:focus\:border-green-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .print\:focus\:border-green-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .print\:focus\:border-green-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-green-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-green-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-green-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-green-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .print\:focus\:border-teal-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .print\:focus\:border-teal-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .print\:focus\:border-teal-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .print\:focus\:border-teal-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .print\:focus\:border-teal-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .print\:focus\:border-teal-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .print\:focus\:border-teal-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .print\:focus\:border-teal-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .print\:focus\:border-teal-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .print\:focus\:border-teal-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .print\:focus\:border-teal:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .print\:focus\:border-teal-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .print\:focus\:border-teal-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .print\:focus\:border-teal-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .print\:focus\:border-teal-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-blue-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-blue-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-blue-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-light-blue-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .print\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .print\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .print\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .print\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .print\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .print\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .print\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .print\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .print\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .print\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .print\:focus\:border-indigo:focus {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .print\:focus\:border-indigo-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-indigo-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .print\:focus\:border-indigo-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .print\:focus\:border-indigo-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .print\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .print\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .print\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .print\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .print\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .print\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .print\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .print\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .print\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .print\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .print\:focus\:border-purple:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .print\:focus\:border-purple-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .print\:focus\:border-purple-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .print\:focus\:border-purple-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .print\:focus\:border-purple-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-purple:focus {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-purple-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-purple-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-purple-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-deep-purple-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .print\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .print\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .print\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .print\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .print\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .print\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .print\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .print\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .print\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .print\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .print\:focus\:border-pink:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .print\:focus\:border-pink-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .print\:focus\:border-pink-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .print\:focus\:border-pink-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .print\:focus\:border-pink-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .print\:focus\:border-lime-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .print\:focus\:border-lime-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .print\:focus\:border-lime-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .print\:focus\:border-lime-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .print\:focus\:border-lime-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .print\:focus\:border-lime-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .print\:focus\:border-lime-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .print\:focus\:border-lime-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .print\:focus\:border-lime-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .print\:focus\:border-lime-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .print\:focus\:border-lime:focus {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .print\:focus\:border-lime-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .print\:focus\:border-lime-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .print\:focus\:border-lime-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-lime-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-amber-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .print\:focus\:border-amber-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .print\:focus\:border-amber-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .print\:focus\:border-amber-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .print\:focus\:border-amber-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .print\:focus\:border-amber-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .print\:focus\:border-amber-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-amber-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-amber-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-amber-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-amber:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .print\:focus\:border-amber-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .print\:focus\:border-amber-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .print\:focus\:border-amber-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-amber-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .print\:focus\:border-brown-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .print\:focus\:border-brown-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .print\:focus\:border-brown-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .print\:focus\:border-brown-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .print\:focus\:border-brown-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .print\:focus\:border-brown-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .print\:focus\:border-brown-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .print\:focus\:border-brown-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .print\:focus\:border-brown-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .print\:focus\:border-brown-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .print\:focus\:border-brown:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .print\:focus\:border-brown-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .print\:focus\:border-brown-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .print\:focus\:border-brown-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .print\:focus\:border-brown-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-gray-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-gray-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-gray-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .print\:focus\:border-blue-gray-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .print\:focus\:border-cyan-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .print\:focus\:border-cyan-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .print\:focus\:border-cyan-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .print\:focus\:border-cyan-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .print\:focus\:border-cyan-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .print\:focus\:border-cyan-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .print\:focus\:border-cyan-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .print\:focus\:border-cyan-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .print\:focus\:border-cyan-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .print\:focus\:border-cyan-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .print\:focus\:border-cyan:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .print\:focus\:border-cyan-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-cyan-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-cyan-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .print\:focus\:border-cyan-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .print\:border-opacity-0 {
    --tw-border-opacity: 0
  }

  .print\:border-opacity-5 {
    --tw-border-opacity: 0.05
  }

  .print\:border-opacity-10 {
    --tw-border-opacity: 0.1
  }

  .print\:border-opacity-20 {
    --tw-border-opacity: 0.2
  }

  .print\:border-opacity-25 {
    --tw-border-opacity: 0.25
  }

  .print\:border-opacity-30 {
    --tw-border-opacity: 0.3
  }

  .print\:border-opacity-40 {
    --tw-border-opacity: 0.4
  }

  .print\:border-opacity-50 {
    --tw-border-opacity: 0.5
  }

  .print\:border-opacity-60 {
    --tw-border-opacity: 0.6
  }

  .print\:border-opacity-70 {
    --tw-border-opacity: 0.7
  }

  .print\:border-opacity-75 {
    --tw-border-opacity: 0.75
  }

  .print\:border-opacity-80 {
    --tw-border-opacity: 0.8
  }

  .print\:border-opacity-90 {
    --tw-border-opacity: 0.9
  }

  .print\:border-opacity-95 {
    --tw-border-opacity: 0.95
  }

  .print\:border-opacity-100 {
    --tw-border-opacity: 1
  }

  .group:hover .print\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0
  }

  .group:hover .print\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05
  }

  .group:hover .print\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1
  }

  .group:hover .print\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2
  }

  .group:hover .print\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25
  }

  .group:hover .print\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3
  }

  .group:hover .print\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4
  }

  .group:hover .print\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5
  }

  .group:hover .print\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6
  }

  .group:hover .print\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7
  }

  .group:hover .print\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75
  }

  .group:hover .print\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8
  }

  .group:hover .print\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9
  }

  .group:hover .print\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95
  }

  .group:hover .print\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1
  }

  .print\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0
  }

  .print\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05
  }

  .print\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1
  }

  .print\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2
  }

  .print\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25
  }

  .print\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3
  }

  .print\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4
  }

  .print\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5
  }

  .print\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6
  }

  .print\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7
  }

  .print\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75
  }

  .print\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8
  }

  .print\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9
  }

  .print\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95
  }

  .print\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1
  }

  .print\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0
  }

  .print\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05
  }

  .print\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1
  }

  .print\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2
  }

  .print\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25
  }

  .print\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3
  }

  .print\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4
  }

  .print\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5
  }

  .print\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6
  }

  .print\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7
  }

  .print\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75
  }

  .print\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8
  }

  .print\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9
  }

  .print\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95
  }

  .print\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1
  }

  .print\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0
  }

  .print\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05
  }

  .print\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1
  }

  .print\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2
  }

  .print\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25
  }

  .print\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3
  }

  .print\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4
  }

  .print\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5
  }

  .print\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6
  }

  .print\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7
  }

  .print\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75
  }

  .print\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8
  }

  .print\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9
  }

  .print\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95
  }

  .print\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1
  }

  .print\:rounded-2 {
    border-radius: .2rem
  }

  .print\:rounded-4 {
    border-radius: .4rem
  }

  .print\:rounded-6 {
    border-radius: .6rem
  }

  .print\:rounded-8 {
    border-radius: .8rem
  }

  .print\:rounded-12 {
    border-radius: 1.2rem
  }

  .print\:rounded-16 {
    border-radius: 1.6rem
  }

  .print\:rounded-20 {
    border-radius: 2rem
  }

  .print\:rounded-24 {
    border-radius: 2.4rem
  }

  .print\:rounded-28 {
    border-radius: 2.8rem
  }

  .print\:rounded-32 {
    border-radius: 3.2rem
  }

  .print\:rounded-none {
    border-radius: 0
  }

  .print\:rounded-sm {
    border-radius: .2rem
  }

  .print\:rounded {
    border-radius: .4rem
  }

  .print\:rounded-md {
    border-radius: .6rem
  }

  .print\:rounded-lg {
    border-radius: .8rem
  }

  .print\:rounded-xl {
    border-radius: 1.2rem
  }

  .print\:rounded-2xl {
    border-radius: 1.6rem
  }

  .print\:rounded-3xl {
    border-radius: 2.4rem
  }

  .print\:rounded-full {
    border-radius: 9999px
  }

  .print\:rounded-t-2 {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem
  }

  .print\:rounded-r-2 {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
  }

  .print\:rounded-b-2 {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .print\:rounded-l-2 {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .print\:rounded-t-4 {
    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem
  }

  .print\:rounded-r-4 {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem
  }

  .print\:rounded-b-4 {
    border-bottom-right-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .print\:rounded-l-4 {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .print\:rounded-t-6 {
    border-top-left-radius: .6rem;
    border-top-right-radius: .6rem
  }

  .print\:rounded-r-6 {
    border-top-right-radius: .6rem;
    border-bottom-right-radius: .6rem
  }

  .print\:rounded-b-6 {
    border-bottom-right-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .print\:rounded-l-6 {
    border-top-left-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .print\:rounded-t-8 {
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem
  }

  .print\:rounded-r-8 {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem
  }

  .print\:rounded-b-8 {
    border-bottom-right-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .print\:rounded-l-8 {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .print\:rounded-t-12 {
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem
  }

  .print\:rounded-r-12 {
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem
  }

  .print\:rounded-b-12 {
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .print\:rounded-l-12 {
    border-top-left-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .print\:rounded-t-16 {
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem
  }

  .print\:rounded-r-16 {
    border-top-right-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem
  }

  .print\:rounded-b-16 {
    border-bottom-right-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .print\:rounded-l-16 {
    border-top-left-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .print\:rounded-t-20 {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem
  }

  .print\:rounded-r-20 {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem
  }

  .print\:rounded-b-20 {
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem
  }

  .print\:rounded-l-20 {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem
  }

  .print\:rounded-t-24 {
    border-top-left-radius: 2.4rem;
    border-top-right-radius: 2.4rem
  }

  .print\:rounded-r-24 {
    border-top-right-radius: 2.4rem;
    border-bottom-right-radius: 2.4rem
  }

  .print\:rounded-b-24 {
    border-bottom-right-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .print\:rounded-l-24 {
    border-top-left-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .print\:rounded-t-28 {
    border-top-left-radius: 2.8rem;
    border-top-right-radius: 2.8rem
  }

  .print\:rounded-r-28 {
    border-top-right-radius: 2.8rem;
    border-bottom-right-radius: 2.8rem
  }

  .print\:rounded-b-28 {
    border-bottom-right-radius: 2.8rem;
    border-bottom-left-radius: 2.8rem
  }

  .print\:rounded-l-28 {
    border-top-left-radius: 2.8rem;
    border-bottom-left-radius: 2.8rem
  }

  .print\:rounded-t-32 {
    border-top-left-radius: 3.2rem;
    border-top-right-radius: 3.2rem
  }

  .print\:rounded-r-32 {
    border-top-right-radius: 3.2rem;
    border-bottom-right-radius: 3.2rem
  }

  .print\:rounded-b-32 {
    border-bottom-right-radius: 3.2rem;
    border-bottom-left-radius: 3.2rem
  }

  .print\:rounded-l-32 {
    border-top-left-radius: 3.2rem;
    border-bottom-left-radius: 3.2rem
  }

  .print\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .print\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .print\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .print\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .print\:rounded-t-sm {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem
  }

  .print\:rounded-r-sm {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
  }

  .print\:rounded-b-sm {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .print\:rounded-l-sm {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .print\:rounded-t {
    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem
  }

  .print\:rounded-r {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem
  }

  .print\:rounded-b {
    border-bottom-right-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .print\:rounded-l {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .print\:rounded-t-md {
    border-top-left-radius: .6rem;
    border-top-right-radius: .6rem
  }

  .print\:rounded-r-md {
    border-top-right-radius: .6rem;
    border-bottom-right-radius: .6rem
  }

  .print\:rounded-b-md {
    border-bottom-right-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .print\:rounded-l-md {
    border-top-left-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .print\:rounded-t-lg {
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem
  }

  .print\:rounded-r-lg {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem
  }

  .print\:rounded-b-lg {
    border-bottom-right-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .print\:rounded-l-lg {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .print\:rounded-t-xl {
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem
  }

  .print\:rounded-r-xl {
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem
  }

  .print\:rounded-b-xl {
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .print\:rounded-l-xl {
    border-top-left-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .print\:rounded-t-2xl {
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem
  }

  .print\:rounded-r-2xl {
    border-top-right-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem
  }

  .print\:rounded-b-2xl {
    border-bottom-right-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .print\:rounded-l-2xl {
    border-top-left-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .print\:rounded-t-3xl {
    border-top-left-radius: 2.4rem;
    border-top-right-radius: 2.4rem
  }

  .print\:rounded-r-3xl {
    border-top-right-radius: 2.4rem;
    border-bottom-right-radius: 2.4rem
  }

  .print\:rounded-b-3xl {
    border-bottom-right-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .print\:rounded-l-3xl {
    border-top-left-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .print\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .print\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .print\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .print\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .print\:rounded-tl-2 {
    border-top-left-radius: .2rem
  }

  .print\:rounded-tr-2 {
    border-top-right-radius: .2rem
  }

  .print\:rounded-br-2 {
    border-bottom-right-radius: .2rem
  }

  .print\:rounded-bl-2 {
    border-bottom-left-radius: .2rem
  }

  .print\:rounded-tl-4 {
    border-top-left-radius: .4rem
  }

  .print\:rounded-tr-4 {
    border-top-right-radius: .4rem
  }

  .print\:rounded-br-4 {
    border-bottom-right-radius: .4rem
  }

  .print\:rounded-bl-4 {
    border-bottom-left-radius: .4rem
  }

  .print\:rounded-tl-6 {
    border-top-left-radius: .6rem
  }

  .print\:rounded-tr-6 {
    border-top-right-radius: .6rem
  }

  .print\:rounded-br-6 {
    border-bottom-right-radius: .6rem
  }

  .print\:rounded-bl-6 {
    border-bottom-left-radius: .6rem
  }

  .print\:rounded-tl-8 {
    border-top-left-radius: .8rem
  }

  .print\:rounded-tr-8 {
    border-top-right-radius: .8rem
  }

  .print\:rounded-br-8 {
    border-bottom-right-radius: .8rem
  }

  .print\:rounded-bl-8 {
    border-bottom-left-radius: .8rem
  }

  .print\:rounded-tl-12 {
    border-top-left-radius: 1.2rem
  }

  .print\:rounded-tr-12 {
    border-top-right-radius: 1.2rem
  }

  .print\:rounded-br-12 {
    border-bottom-right-radius: 1.2rem
  }

  .print\:rounded-bl-12 {
    border-bottom-left-radius: 1.2rem
  }

  .print\:rounded-tl-16 {
    border-top-left-radius: 1.6rem
  }

  .print\:rounded-tr-16 {
    border-top-right-radius: 1.6rem
  }

  .print\:rounded-br-16 {
    border-bottom-right-radius: 1.6rem
  }

  .print\:rounded-bl-16 {
    border-bottom-left-radius: 1.6rem
  }

  .print\:rounded-tl-20 {
    border-top-left-radius: 2rem
  }

  .print\:rounded-tr-20 {
    border-top-right-radius: 2rem
  }

  .print\:rounded-br-20 {
    border-bottom-right-radius: 2rem
  }

  .print\:rounded-bl-20 {
    border-bottom-left-radius: 2rem
  }

  .print\:rounded-tl-24 {
    border-top-left-radius: 2.4rem
  }

  .print\:rounded-tr-24 {
    border-top-right-radius: 2.4rem
  }

  .print\:rounded-br-24 {
    border-bottom-right-radius: 2.4rem
  }

  .print\:rounded-bl-24 {
    border-bottom-left-radius: 2.4rem
  }

  .print\:rounded-tl-28 {
    border-top-left-radius: 2.8rem
  }

  .print\:rounded-tr-28 {
    border-top-right-radius: 2.8rem
  }

  .print\:rounded-br-28 {
    border-bottom-right-radius: 2.8rem
  }

  .print\:rounded-bl-28 {
    border-bottom-left-radius: 2.8rem
  }

  .print\:rounded-tl-32 {
    border-top-left-radius: 3.2rem
  }

  .print\:rounded-tr-32 {
    border-top-right-radius: 3.2rem
  }

  .print\:rounded-br-32 {
    border-bottom-right-radius: 3.2rem
  }

  .print\:rounded-bl-32 {
    border-bottom-left-radius: 3.2rem
  }

  .print\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .print\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .print\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .print\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .print\:rounded-tl-sm {
    border-top-left-radius: .2rem
  }

  .print\:rounded-tr-sm {
    border-top-right-radius: .2rem
  }

  .print\:rounded-br-sm {
    border-bottom-right-radius: .2rem
  }

  .print\:rounded-bl-sm {
    border-bottom-left-radius: .2rem
  }

  .print\:rounded-tl {
    border-top-left-radius: .4rem
  }

  .print\:rounded-tr {
    border-top-right-radius: .4rem
  }

  .print\:rounded-br {
    border-bottom-right-radius: .4rem
  }

  .print\:rounded-bl {
    border-bottom-left-radius: .4rem
  }

  .print\:rounded-tl-md {
    border-top-left-radius: .6rem
  }

  .print\:rounded-tr-md {
    border-top-right-radius: .6rem
  }

  .print\:rounded-br-md {
    border-bottom-right-radius: .6rem
  }

  .print\:rounded-bl-md {
    border-bottom-left-radius: .6rem
  }

  .print\:rounded-tl-lg {
    border-top-left-radius: .8rem
  }

  .print\:rounded-tr-lg {
    border-top-right-radius: .8rem
  }

  .print\:rounded-br-lg {
    border-bottom-right-radius: .8rem
  }

  .print\:rounded-bl-lg {
    border-bottom-left-radius: .8rem
  }

  .print\:rounded-tl-xl {
    border-top-left-radius: 1.2rem
  }

  .print\:rounded-tr-xl {
    border-top-right-radius: 1.2rem
  }

  .print\:rounded-br-xl {
    border-bottom-right-radius: 1.2rem
  }

  .print\:rounded-bl-xl {
    border-bottom-left-radius: 1.2rem
  }

  .print\:rounded-tl-2xl {
    border-top-left-radius: 1.6rem
  }

  .print\:rounded-tr-2xl {
    border-top-right-radius: 1.6rem
  }

  .print\:rounded-br-2xl {
    border-bottom-right-radius: 1.6rem
  }

  .print\:rounded-bl-2xl {
    border-bottom-left-radius: 1.6rem
  }

  .print\:rounded-tl-3xl {
    border-top-left-radius: 2.4rem
  }

  .print\:rounded-tr-3xl {
    border-top-right-radius: 2.4rem
  }

  .print\:rounded-br-3xl {
    border-bottom-right-radius: 2.4rem
  }

  .print\:rounded-bl-3xl {
    border-bottom-left-radius: 2.4rem
  }

  .print\:rounded-tl-full {
    border-top-left-radius: 9999px
  }

  .print\:rounded-tr-full {
    border-top-right-radius: 9999px
  }

  .print\:rounded-br-full {
    border-bottom-right-radius: 9999px
  }

  .print\:rounded-bl-full {
    border-bottom-left-radius: 9999px
  }

  .print\:border-solid {
    border-style: solid
  }

  .print\:border-dashed {
    border-style: dashed
  }

  .print\:border-dotted {
    border-style: dotted
  }

  .print\:border-double {
    border-style: double
  }

  .print\:border-none {
    border-style: none
  }

  .print\:border-0 {
    border-width: 0px
  }

  .print\:border-1 {
    border-width: 1px
  }

  .print\:border-2 {
    border-width: 2px
  }

  .print\:border-3 {
    border-width: 3px
  }

  .print\:border-4 {
    border-width: 4px
  }

  .print\:border-8 {
    border-width: 8px
  }

  .print\:border {
    border-width: 1px
  }

  .print\:border-t-0 {
    border-top-width: 0px
  }

  .print\:border-r-0 {
    border-right-width: 0px
  }

  .print\:border-b-0 {
    border-bottom-width: 0px
  }

  .print\:border-l-0 {
    border-left-width: 0px
  }

  .print\:border-t-1 {
    border-top-width: 1px
  }

  .print\:border-r-1 {
    border-right-width: 1px
  }

  .print\:border-b-1 {
    border-bottom-width: 1px
  }

  .print\:border-l-1 {
    border-left-width: 1px
  }

  .print\:border-t-2 {
    border-top-width: 2px
  }

  .print\:border-r-2 {
    border-right-width: 2px
  }

  .print\:border-b-2 {
    border-bottom-width: 2px
  }

  .print\:border-l-2 {
    border-left-width: 2px
  }

  .print\:border-t-3 {
    border-top-width: 3px
  }

  .print\:border-r-3 {
    border-right-width: 3px
  }

  .print\:border-b-3 {
    border-bottom-width: 3px
  }

  .print\:border-l-3 {
    border-left-width: 3px
  }

  .print\:border-t-4 {
    border-top-width: 4px
  }

  .print\:border-r-4 {
    border-right-width: 4px
  }

  .print\:border-b-4 {
    border-bottom-width: 4px
  }

  .print\:border-l-4 {
    border-left-width: 4px
  }

  .print\:border-t-8 {
    border-top-width: 8px
  }

  .print\:border-r-8 {
    border-right-width: 8px
  }

  .print\:border-b-8 {
    border-bottom-width: 8px
  }

  .print\:border-l-8 {
    border-left-width: 8px
  }

  .print\:border-t {
    border-top-width: 1px
  }

  .print\:border-r {
    border-right-width: 1px
  }

  .print\:border-b {
    border-bottom-width: 1px
  }

  .print\:border-l {
    border-left-width: 1px
  }

  .print\:box-border {
    box-sizing: border-box
  }

  .print\:box-content {
    box-sizing: content-box
  }

  .print\:cursor-auto {
    cursor: auto
  }

  .print\:cursor-default {
    cursor: default
  }

  .print\:cursor-pointer {
    cursor: pointer
  }

  .print\:cursor-wait {
    cursor: wait
  }

  .print\:cursor-text {
    cursor: text
  }

  .print\:cursor-move {
    cursor: move
  }

  .print\:cursor-not-allowed {
    cursor: not-allowed
  }

  .print\:block {
    display: block
  }

  .print\:inline-block {
    display: inline-block
  }

  .print\:inline {
    display: inline
  }

  .print\:flex {
    display: flex
  }

  .print\:inline-flex {
    display: inline-flex
  }

  .print\:table {
    display: table
  }

  .print\:table-caption {
    display: table-caption
  }

  .print\:table-cell {
    display: table-cell
  }

  .print\:table-column {
    display: table-column
  }

  .print\:table-column-group {
    display: table-column-group
  }

  .print\:table-footer-group {
    display: table-footer-group
  }

  .print\:table-header-group {
    display: table-header-group
  }

  .print\:table-row-group {
    display: table-row-group
  }

  .print\:table-row {
    display: table-row
  }

  .print\:flow-root {
    display: flow-root
  }

  .print\:grid {
    display: grid
  }

  .print\:inline-grid {
    display: inline-grid
  }

  .print\:contents {
    display: contents
  }

  .print\:hidden {
    display: none
  }

  .print\:hover\:block:hover {
    display: block
  }

  .print\:hover\:inline-block:hover {
    display: inline-block
  }

  .print\:hover\:inline:hover {
    display: inline
  }

  .print\:hover\:flex:hover {
    display: flex
  }

  .print\:hover\:inline-flex:hover {
    display: inline-flex
  }

  .print\:hover\:table:hover {
    display: table
  }

  .print\:hover\:table-caption:hover {
    display: table-caption
  }

  .print\:hover\:table-cell:hover {
    display: table-cell
  }

  .print\:hover\:table-column:hover {
    display: table-column
  }

  .print\:hover\:table-column-group:hover {
    display: table-column-group
  }

  .print\:hover\:table-footer-group:hover {
    display: table-footer-group
  }

  .print\:hover\:table-header-group:hover {
    display: table-header-group
  }

  .print\:hover\:table-row-group:hover {
    display: table-row-group
  }

  .print\:hover\:table-row:hover {
    display: table-row
  }

  .print\:hover\:flow-root:hover {
    display: flow-root
  }

  .print\:hover\:grid:hover {
    display: grid
  }

  .print\:hover\:inline-grid:hover {
    display: inline-grid
  }

  .print\:hover\:contents:hover {
    display: contents
  }

  .print\:hover\:hidden:hover {
    display: none
  }

  .print\:focus\:block:focus {
    display: block
  }

  .print\:focus\:inline-block:focus {
    display: inline-block
  }

  .print\:focus\:inline:focus {
    display: inline
  }

  .print\:focus\:flex:focus {
    display: flex
  }

  .print\:focus\:inline-flex:focus {
    display: inline-flex
  }

  .print\:focus\:table:focus {
    display: table
  }

  .print\:focus\:table-caption:focus {
    display: table-caption
  }

  .print\:focus\:table-cell:focus {
    display: table-cell
  }

  .print\:focus\:table-column:focus {
    display: table-column
  }

  .print\:focus\:table-column-group:focus {
    display: table-column-group
  }

  .print\:focus\:table-footer-group:focus {
    display: table-footer-group
  }

  .print\:focus\:table-header-group:focus {
    display: table-header-group
  }

  .print\:focus\:table-row-group:focus {
    display: table-row-group
  }

  .print\:focus\:table-row:focus {
    display: table-row
  }

  .print\:focus\:flow-root:focus {
    display: flow-root
  }

  .print\:focus\:grid:focus {
    display: grid
  }

  .print\:focus\:inline-grid:focus {
    display: inline-grid
  }

  .print\:focus\:contents:focus {
    display: contents
  }

  .print\:focus\:hidden:focus {
    display: none
  }

  .print\:flex-row {
    flex-direction: row
  }

  .print\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .print\:flex-col {
    flex-direction: column
  }

  .print\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .print\:flex-wrap {
    flex-wrap: wrap
  }

  .print\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .print\:flex-nowrap {
    flex-wrap: nowrap
  }

  .print\:place-items-auto {
    place-items: auto
  }

  .print\:place-items-start {
    place-items: start
  }

  .print\:place-items-end {
    place-items: end
  }

  .print\:place-items-center {
    place-items: center
  }

  .print\:place-items-stretch {
    place-items: stretch
  }

  .print\:place-content-center {
    place-content: center
  }

  .print\:place-content-start {
    place-content: start
  }

  .print\:place-content-end {
    place-content: end
  }

  .print\:place-content-between {
    place-content: space-between
  }

  .print\:place-content-around {
    place-content: space-around
  }

  .print\:place-content-evenly {
    place-content: space-evenly
  }

  .print\:place-content-stretch {
    place-content: stretch
  }

  .print\:place-self-auto {
    place-self: auto
  }

  .print\:place-self-start {
    place-self: start
  }

  .print\:place-self-end {
    place-self: end
  }

  .print\:place-self-center {
    place-self: center
  }

  .print\:place-self-stretch {
    place-self: stretch
  }

  .print\:items-start {
    align-items: flex-start
  }

  .print\:items-end {
    align-items: flex-end
  }

  .print\:items-center {
    align-items: center
  }

  .print\:items-baseline {
    align-items: baseline
  }

  .print\:items-stretch {
    align-items: stretch
  }

  .print\:content-center {
    align-content: center
  }

  .print\:content-start {
    align-content: flex-start
  }

  .print\:content-end {
    align-content: flex-end
  }

  .print\:content-between {
    align-content: space-between
  }

  .print\:content-around {
    align-content: space-around
  }

  .print\:content-evenly {
    align-content: space-evenly
  }

  .print\:self-auto {
    align-self: auto
  }

  .print\:self-start {
    align-self: flex-start
  }

  .print\:self-end {
    align-self: flex-end
  }

  .print\:self-center {
    align-self: center
  }

  .print\:self-stretch {
    align-self: stretch
  }

  .print\:justify-items-auto {
    justify-items: auto
  }

  .print\:justify-items-start {
    justify-items: start
  }

  .print\:justify-items-end {
    justify-items: end
  }

  .print\:justify-items-center {
    justify-items: center
  }

  .print\:justify-items-stretch {
    justify-items: stretch
  }

  .print\:justify-start {
    justify-content: flex-start
  }

  .print\:justify-end {
    justify-content: flex-end
  }

  .print\:justify-center {
    justify-content: center
  }

  .print\:justify-between {
    justify-content: space-between
  }

  .print\:justify-around {
    justify-content: space-around
  }

  .print\:justify-evenly {
    justify-content: space-evenly
  }

  .print\:justify-self-auto {
    justify-self: auto
  }

  .print\:justify-self-start {
    justify-self: start
  }

  .print\:justify-self-end {
    justify-self: end
  }

  .print\:justify-self-center {
    justify-self: center
  }

  .print\:justify-self-stretch {
    justify-self: stretch
  }

  .print\:flex-1 {
    flex: 1 1 0%
  }

  .print\:flex-auto {
    flex: 1 1 auto
  }

  .print\:flex-initial {
    flex: 0 1 auto
  }

  .print\:flex-none {
    flex: none
  }

  .print\:flex-grow-0 {
    flex-grow: 0
  }

  .print\:flex-grow {
    flex-grow: 1
  }

  .print\:flex-shrink-0 {
    flex-shrink: 0
  }

  .print\:flex-shrink {
    flex-shrink: 1
  }

  .print\:order-1 {
    order: 1
  }

  .print\:order-2 {
    order: 2
  }

  .print\:order-3 {
    order: 3
  }

  .print\:order-4 {
    order: 4
  }

  .print\:order-5 {
    order: 5
  }

  .print\:order-6 {
    order: 6
  }

  .print\:order-7 {
    order: 7
  }

  .print\:order-8 {
    order: 8
  }

  .print\:order-9 {
    order: 9
  }

  .print\:order-10 {
    order: 10
  }

  .print\:order-11 {
    order: 11
  }

  .print\:order-12 {
    order: 12
  }

  .print\:order-first {
    order: -9999
  }

  .print\:order-last {
    order: 9999
  }

  .print\:order-none {
    order: 0
  }

  .print\:float-right {
    float: right
  }

  .print\:float-left {
    float: left
  }

  .print\:float-none {
    float: none
  }

  [dir='ltr'] .print\:ltr\:float-right,[dir='ltr'].print\:ltr\:float-right {
    float: right
  }

  [dir='ltr'] .print\:ltr\:float-left,[dir='ltr'].print\:ltr\:float-left {
    float: left
  }

  [dir='ltr'] .print\:ltr\:float-none,[dir='ltr'].print\:ltr\:float-none {
    float: none
  }

  [dir='rtl'] .print\:rtl\:float-right,[dir='rtl'].print\:rtl\:float-right {
    float: right
  }

  [dir='rtl'] .print\:rtl\:float-left,[dir='rtl'].print\:rtl\:float-left {
    float: left
  }

  [dir='rtl'] .print\:rtl\:float-none,[dir='rtl'].print\:rtl\:float-none {
    float: none
  }

  .print\:clear-left {
    clear: left
  }

  .print\:clear-right {
    clear: right
  }

  .print\:clear-both {
    clear: both
  }

  .print\:clear-none {
    clear: none
  }

  .print\:font-sans {
    font-family: Muli, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .print\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .print\:font-mono {
    font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace
  }

  .print\:font-100 {
    font-weight: 100
  }

  .print\:font-200 {
    font-weight: 200
  }

  .print\:font-300 {
    font-weight: 300
  }

  .print\:font-400 {
    font-weight: 400
  }

  .print\:font-500 {
    font-weight: 500
  }

  .print\:font-600 {
    font-weight: 600
  }

  .print\:font-700 {
    font-weight: 700
  }

  .print\:font-800 {
    font-weight: 800
  }

  .print\:font-900 {
    font-weight: 900
  }

  .print\:font-thin {
    font-weight: 100
  }

  .print\:font-extralight {
    font-weight: 200
  }

  .print\:font-light {
    font-weight: 300
  }

  .print\:font-normal {
    font-weight: 400
  }

  .print\:font-medium {
    font-weight: 500
  }

  .print\:font-semibold {
    font-weight: 600
  }

  .print\:font-bold {
    font-weight: 700
  }

  .print\:font-extrabold {
    font-weight: 800
  }

  .print\:font-black {
    font-weight: 900
  }

  .print\:hover\:font-100:hover {
    font-weight: 100
  }

  .print\:hover\:font-200:hover {
    font-weight: 200
  }

  .print\:hover\:font-300:hover {
    font-weight: 300
  }

  .print\:hover\:font-400:hover {
    font-weight: 400
  }

  .print\:hover\:font-500:hover {
    font-weight: 500
  }

  .print\:hover\:font-600:hover {
    font-weight: 600
  }

  .print\:hover\:font-700:hover {
    font-weight: 700
  }

  .print\:hover\:font-800:hover {
    font-weight: 800
  }

  .print\:hover\:font-900:hover {
    font-weight: 900
  }

  .print\:hover\:font-thin:hover {
    font-weight: 100
  }

  .print\:hover\:font-extralight:hover {
    font-weight: 200
  }

  .print\:hover\:font-light:hover {
    font-weight: 300
  }

  .print\:hover\:font-normal:hover {
    font-weight: 400
  }

  .print\:hover\:font-medium:hover {
    font-weight: 500
  }

  .print\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .print\:hover\:font-bold:hover {
    font-weight: 700
  }

  .print\:hover\:font-extrabold:hover {
    font-weight: 800
  }

  .print\:hover\:font-black:hover {
    font-weight: 900
  }

  .print\:focus\:font-100:focus {
    font-weight: 100
  }

  .print\:focus\:font-200:focus {
    font-weight: 200
  }

  .print\:focus\:font-300:focus {
    font-weight: 300
  }

  .print\:focus\:font-400:focus {
    font-weight: 400
  }

  .print\:focus\:font-500:focus {
    font-weight: 500
  }

  .print\:focus\:font-600:focus {
    font-weight: 600
  }

  .print\:focus\:font-700:focus {
    font-weight: 700
  }

  .print\:focus\:font-800:focus {
    font-weight: 800
  }

  .print\:focus\:font-900:focus {
    font-weight: 900
  }

  .print\:focus\:font-thin:focus {
    font-weight: 100
  }

  .print\:focus\:font-extralight:focus {
    font-weight: 200
  }

  .print\:focus\:font-light:focus {
    font-weight: 300
  }

  .print\:focus\:font-normal:focus {
    font-weight: 400
  }

  .print\:focus\:font-medium:focus {
    font-weight: 500
  }

  .print\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .print\:focus\:font-bold:focus {
    font-weight: 700
  }

  .print\:focus\:font-extrabold:focus {
    font-weight: 800
  }

  .print\:focus\:font-black:focus {
    font-weight: 900
  }

  .print\:h-0 {
    height: 0
  }

  .print\:h-1 {
    height: 0.1rem
  }

  .print\:h-2 {
    height: 0.2rem
  }

  .print\:h-3 {
    height: 0.3rem
  }

  .print\:h-4 {
    height: 0.4rem
  }

  .print\:h-5 {
    height: 0.5rem
  }

  .print\:h-6 {
    height: 0.6rem
  }

  .print\:h-7 {
    height: 0.7rem
  }

  .print\:h-8 {
    height: 0.8rem
  }

  .print\:h-9 {
    height: 0.9rem
  }

  .print\:h-10 {
    height: 1.0rem
  }

  .print\:h-12 {
    height: 1.2rem
  }

  .print\:h-14 {
    height: 1.4rem
  }

  .print\:h-16 {
    height: 1.6rem
  }

  .print\:h-20 {
    height: 2rem
  }

  .print\:h-24 {
    height: 2.4rem
  }

  .print\:h-28 {
    height: 2.8rem
  }

  .print\:h-32 {
    height: 3.2rem
  }

  .print\:h-36 {
    height: 3.6rem
  }

  .print\:h-40 {
    height: 4rem
  }

  .print\:h-44 {
    height: 4.4rem
  }

  .print\:h-48 {
    height: 4.8rem
  }

  .print\:h-52 {
    height: 5.2rem
  }

  .print\:h-56 {
    height: 5.6rem
  }

  .print\:h-60 {
    height: 6rem
  }

  .print\:h-64 {
    height: 6.4rem
  }

  .print\:h-68 {
    height: 6.8rem
  }

  .print\:h-72 {
    height: 7.2rem
  }

  .print\:h-76 {
    height: 7.6rem
  }

  .print\:h-80 {
    height: 8rem
  }

  .print\:h-84 {
    height: 8.4rem
  }

  .print\:h-88 {
    height: 8.8rem
  }

  .print\:h-92 {
    height: 9.2rem
  }

  .print\:h-96 {
    height: 9.6rem
  }

  .print\:h-128 {
    height: 12.8rem
  }

  .print\:h-136 {
    height: 13.6rem
  }

  .print\:h-160 {
    height: 16rem
  }

  .print\:h-192 {
    height: 19.2rem
  }

  .print\:h-200 {
    height: 20rem
  }

  .print\:h-208 {
    height: 20.8rem
  }

  .print\:h-216 {
    height: 21.6rem
  }

  .print\:h-224 {
    height: 22.4rem
  }

  .print\:h-256 {
    height: 25.6rem
  }

  .print\:h-288 {
    height: 28.8rem
  }

  .print\:h-320 {
    height: 32rem
  }

  .print\:h-360 {
    height: 36rem
  }

  .print\:h-384 {
    height: 38.4rem
  }

  .print\:h-400 {
    height: 40rem
  }

  .print\:h-512 {
    height: 51.2rem
  }

  .print\:h-640 {
    height: 64rem
  }

  .print\:h-auto {
    height: auto
  }

  .print\:h-xs {
    height: 32rem
  }

  .print\:h-sm {
    height: 48rem
  }

  .print\:h-md {
    height: 64rem
  }

  .print\:h-lg {
    height: 80rem
  }

  .print\:h-xl {
    height: 96rem
  }

  .print\:h-2xl {
    height: 112rem
  }

  .print\:h-3xl {
    height: 128rem
  }

  .print\:h-4xl {
    height: 144rem
  }

  .print\:h-5xl {
    height: 160rem
  }

  .print\:h-px {
    height: 1px
  }

  .print\:h-0\.5 {
    height: 0.05rem
  }

  .print\:h-1\.5 {
    height: 0.15rem
  }

  .print\:h-2\.5 {
    height: 0.25rem
  }

  .print\:h-3\.5 {
    height: 0.35rem
  }

  .print\:h-1\/2 {
    height: 50%
  }

  .print\:h-1\/3 {
    height: 33.333333%
  }

  .print\:h-2\/3 {
    height: 66.666667%
  }

  .print\:h-1\/4 {
    height: 25%
  }

  .print\:h-2\/4 {
    height: 50%
  }

  .print\:h-3\/4 {
    height: 75%
  }

  .print\:h-1\/5 {
    height: 20%
  }

  .print\:h-2\/5 {
    height: 40%
  }

  .print\:h-3\/5 {
    height: 60%
  }

  .print\:h-4\/5 {
    height: 80%
  }

  .print\:h-1\/6 {
    height: 16.666667%
  }

  .print\:h-2\/6 {
    height: 33.333333%
  }

  .print\:h-3\/6 {
    height: 50%
  }

  .print\:h-4\/6 {
    height: 66.666667%
  }

  .print\:h-5\/6 {
    height: 83.333333%
  }

  .print\:h-full {
    height: 100%
  }

  .print\:h-screen {
    height: 100vh
  }

  .print\:text-10 {
    font-size: 1rem
  }

  .print\:text-11 {
    font-size: 1.1rem
  }

  .print\:text-12 {
    font-size: 1.2rem
  }

  .print\:text-13 {
    font-size: 1.3rem
  }

  .print\:text-14 {
    font-size: 1.4rem
  }

  .print\:text-15 {
    font-size: 1.5rem
  }

  .print\:text-16 {
    font-size: 1.6rem
  }

  .print\:text-17 {
    font-size: 1.7rem
  }

  .print\:text-18 {
    font-size: 1.8rem
  }

  .print\:text-19 {
    font-size: 1.9rem
  }

  .print\:text-20 {
    font-size: 2rem
  }

  .print\:text-24 {
    font-size: 2.4rem
  }

  .print\:text-28 {
    font-size: 2.8rem
  }

  .print\:text-32 {
    font-size: 3.2rem
  }

  .print\:text-36 {
    font-size: 3.6rem
  }

  .print\:text-40 {
    font-size: 4rem
  }

  .print\:text-44 {
    font-size: 4.4rem
  }

  .print\:text-48 {
    font-size: 4.8rem
  }

  .print\:text-52 {
    font-size: 5.2rem
  }

  .print\:text-56 {
    font-size: 5.6rem
  }

  .print\:text-60 {
    font-size: 6rem
  }

  .print\:text-64 {
    font-size: 6.4rem
  }

  .print\:text-68 {
    font-size: 6.8rem
  }

  .print\:text-72 {
    font-size: 7.2rem
  }

  .print\:text-96 {
    font-size: 9.6rem
  }

  .print\:text-128 {
    font-size: 12.8rem
  }

  .print\:text-xs {
    font-size: 1.2rem;
    line-height: 1.6rem
  }

  .print\:text-sm {
    font-size: 1.4rem;
    line-height: 2rem
  }

  .print\:text-base {
    font-size: 1.6rem;
    line-height: 2.4rem
  }

  .print\:text-lg {
    font-size: 1.8rem;
    line-height: 2.8em
  }

  .print\:text-xl {
    font-size: 2rem;
    line-height: 2.8rem
  }

  .print\:text-2xl {
    font-size: 2.4rem;
    line-height: 3.2rem
  }

  .print\:text-3xl {
    font-size: 3rem;
    line-height: 3.6rem
  }

  .print\:text-4xl {
    font-size: 3.6rem;
    line-height: 4rem
  }

  .print\:text-5xl {
    font-size: 4.8rem;
    line-height: 1
  }

  .print\:text-6xl {
    font-size: 6rem;
    line-height: 1
  }

  .print\:text-7xl {
    font-size: 7.2rem;
    line-height: 1
  }

  .print\:text-8xl {
    font-size: 9.6rem;
    line-height: 1
  }

  .print\:text-9xl {
    font-size: 12.8rem;
    line-height: 1
  }

  .print\:leading-3 {
    line-height: 1.2rem
  }

  .print\:leading-4 {
    line-height: 1.6rem
  }

  .print\:leading-5 {
    line-height: 2rem
  }

  .print\:leading-6 {
    line-height: 2.4rem
  }

  .print\:leading-7 {
    line-height: 2.8rem
  }

  .print\:leading-8 {
    line-height: 3.2rem
  }

  .print\:leading-9 {
    line-height: 3.6rem
  }

  .print\:leading-10 {
    line-height: 4rem
  }

  .print\:leading-none {
    line-height: 1
  }

  .print\:leading-tight {
    line-height: 1.25
  }

  .print\:leading-snug {
    line-height: 1.375
  }

  .print\:leading-normal {
    line-height: 1.5
  }

  .print\:leading-relaxed {
    line-height: 1.625
  }

  .print\:leading-loose {
    line-height: 2
  }

  .print\:list-inside {
    list-style-position: inside
  }

  .print\:list-outside {
    list-style-position: outside
  }

  .print\:list-none {
    list-style-type: none
  }

  .print\:list-disc {
    list-style-type: disc
  }

  .print\:list-decimal {
    list-style-type: decimal
  }

  .print\:m-0 {
    margin: 0
  }

  .print\:m-1 {
    margin: 0.1rem
  }

  .print\:m-2 {
    margin: 0.2rem
  }

  .print\:m-3 {
    margin: 0.3rem
  }

  .print\:m-4 {
    margin: 0.4rem
  }

  .print\:m-5 {
    margin: 0.5rem
  }

  .print\:m-6 {
    margin: 0.6rem
  }

  .print\:m-7 {
    margin: 0.7rem
  }

  .print\:m-8 {
    margin: 0.8rem
  }

  .print\:m-9 {
    margin: 0.9rem
  }

  .print\:m-10 {
    margin: 1.0rem
  }

  .print\:m-12 {
    margin: 1.2rem
  }

  .print\:m-14 {
    margin: 1.4rem
  }

  .print\:m-16 {
    margin: 1.6rem
  }

  .print\:m-20 {
    margin: 2rem
  }

  .print\:m-24 {
    margin: 2.4rem
  }

  .print\:m-28 {
    margin: 2.8rem
  }

  .print\:m-32 {
    margin: 3.2rem
  }

  .print\:m-36 {
    margin: 3.6rem
  }

  .print\:m-40 {
    margin: 4rem
  }

  .print\:m-44 {
    margin: 4.4rem
  }

  .print\:m-48 {
    margin: 4.8rem
  }

  .print\:m-52 {
    margin: 5.2rem
  }

  .print\:m-56 {
    margin: 5.6rem
  }

  .print\:m-60 {
    margin: 6rem
  }

  .print\:m-64 {
    margin: 6.4rem
  }

  .print\:m-68 {
    margin: 6.8rem
  }

  .print\:m-72 {
    margin: 7.2rem
  }

  .print\:m-76 {
    margin: 7.6rem
  }

  .print\:m-80 {
    margin: 8rem
  }

  .print\:m-84 {
    margin: 8.4rem
  }

  .print\:m-88 {
    margin: 8.8rem
  }

  .print\:m-92 {
    margin: 9.2rem
  }

  .print\:m-96 {
    margin: 9.6rem
  }

  .print\:m-128 {
    margin: 12.8rem
  }

  .print\:m-136 {
    margin: 13.6rem
  }

  .print\:m-160 {
    margin: 16rem
  }

  .print\:m-192 {
    margin: 19.2rem
  }

  .print\:m-200 {
    margin: 20rem
  }

  .print\:m-208 {
    margin: 20.8rem
  }

  .print\:m-216 {
    margin: 21.6rem
  }

  .print\:m-224 {
    margin: 22.4rem
  }

  .print\:m-256 {
    margin: 25.6rem
  }

  .print\:m-288 {
    margin: 28.8rem
  }

  .print\:m-320 {
    margin: 32rem
  }

  .print\:m-360 {
    margin: 36rem
  }

  .print\:m-384 {
    margin: 38.4rem
  }

  .print\:m-400 {
    margin: 40rem
  }

  .print\:m-512 {
    margin: 51.2rem
  }

  .print\:m-640 {
    margin: 64rem
  }

  .print\:m-auto {
    margin: auto
  }

  .print\:m-xs {
    margin: 32rem
  }

  .print\:m-sm {
    margin: 48rem
  }

  .print\:m-md {
    margin: 64rem
  }

  .print\:m-lg {
    margin: 80rem
  }

  .print\:m-xl {
    margin: 96rem
  }

  .print\:m-2xl {
    margin: 112rem
  }

  .print\:m-3xl {
    margin: 128rem
  }

  .print\:m-4xl {
    margin: 144rem
  }

  .print\:m-5xl {
    margin: 160rem
  }

  .print\:m-px {
    margin: 1px
  }

  .print\:m-0\.5 {
    margin: 0.05rem
  }

  .print\:m-1\.5 {
    margin: 0.15rem
  }

  .print\:m-2\.5 {
    margin: 0.25rem
  }

  .print\:m-3\.5 {
    margin: 0.35rem
  }

  .print\:-m-1 {
    margin: -0.1rem
  }

  .print\:-m-2 {
    margin: -0.2rem
  }

  .print\:-m-3 {
    margin: -0.3rem
  }

  .print\:-m-4 {
    margin: -0.4rem
  }

  .print\:-m-5 {
    margin: -0.5rem
  }

  .print\:-m-6 {
    margin: -0.6rem
  }

  .print\:-m-7 {
    margin: -0.7rem
  }

  .print\:-m-8 {
    margin: -0.8rem
  }

  .print\:-m-9 {
    margin: -0.9rem
  }

  .print\:-m-10 {
    margin: -1rem
  }

  .print\:-m-12 {
    margin: -1.2rem
  }

  .print\:-m-14 {
    margin: -1.4rem
  }

  .print\:-m-16 {
    margin: -1.6rem
  }

  .print\:-m-20 {
    margin: -2rem
  }

  .print\:-m-24 {
    margin: -2.4rem
  }

  .print\:-m-28 {
    margin: -2.8rem
  }

  .print\:-m-32 {
    margin: -3.2rem
  }

  .print\:-m-36 {
    margin: -3.6rem
  }

  .print\:-m-40 {
    margin: -4rem
  }

  .print\:-m-44 {
    margin: -4.4rem
  }

  .print\:-m-48 {
    margin: -4.8rem
  }

  .print\:-m-52 {
    margin: -5.2rem
  }

  .print\:-m-56 {
    margin: -5.6rem
  }

  .print\:-m-60 {
    margin: -6rem
  }

  .print\:-m-64 {
    margin: -6.4rem
  }

  .print\:-m-68 {
    margin: -6.8rem
  }

  .print\:-m-72 {
    margin: -7.2rem
  }

  .print\:-m-76 {
    margin: -7.6rem
  }

  .print\:-m-80 {
    margin: -8rem
  }

  .print\:-m-84 {
    margin: -8.4rem
  }

  .print\:-m-88 {
    margin: -8.8rem
  }

  .print\:-m-92 {
    margin: -9.2rem
  }

  .print\:-m-96 {
    margin: -9.6rem
  }

  .print\:-m-128 {
    margin: -12.8rem
  }

  .print\:-m-136 {
    margin: -13.6rem
  }

  .print\:-m-160 {
    margin: -16rem
  }

  .print\:-m-192 {
    margin: -19.2rem
  }

  .print\:-m-200 {
    margin: -20rem
  }

  .print\:-m-208 {
    margin: -20.8rem
  }

  .print\:-m-216 {
    margin: -21.6rem
  }

  .print\:-m-224 {
    margin: -22.4rem
  }

  .print\:-m-256 {
    margin: -25.6rem
  }

  .print\:-m-288 {
    margin: -28.8rem
  }

  .print\:-m-320 {
    margin: -32rem
  }

  .print\:-m-360 {
    margin: -36rem
  }

  .print\:-m-384 {
    margin: -38.4rem
  }

  .print\:-m-400 {
    margin: -40rem
  }

  .print\:-m-512 {
    margin: -51.2rem
  }

  .print\:-m-640 {
    margin: -64rem
  }

  .print\:-m-xs {
    margin: -32rem
  }

  .print\:-m-sm {
    margin: -48rem
  }

  .print\:-m-md {
    margin: -64rem
  }

  .print\:-m-lg {
    margin: -80rem
  }

  .print\:-m-xl {
    margin: -96rem
  }

  .print\:-m-2xl {
    margin: -112rem
  }

  .print\:-m-3xl {
    margin: -128rem
  }

  .print\:-m-4xl {
    margin: -144rem
  }

  .print\:-m-5xl {
    margin: -160rem
  }

  .print\:-m-px {
    margin: -1px
  }

  .print\:-m-0\.5 {
    margin: -0.05rem
  }

  .print\:-m-1\.5 {
    margin: -0.15rem
  }

  .print\:-m-2\.5 {
    margin: -0.25rem
  }

  .print\:-m-3\.5 {
    margin: -0.35rem
  }

  .print\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .print\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .print\:my-1 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem
  }

  .print\:mx-1 {
    margin-left: 0.1rem;
    margin-right: 0.1rem
  }

  .print\:my-2 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem
  }

  .print\:mx-2 {
    margin-left: 0.2rem;
    margin-right: 0.2rem
  }

  .print\:my-3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem
  }

  .print\:mx-3 {
    margin-left: 0.3rem;
    margin-right: 0.3rem
  }

  .print\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  .print\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  .print\:my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .print\:mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .print\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  .print\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  .print\:my-7 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem
  }

  .print\:mx-7 {
    margin-left: 0.7rem;
    margin-right: 0.7rem
  }

  .print\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  .print\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  .print\:my-9 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem
  }

  .print\:mx-9 {
    margin-left: 0.9rem;
    margin-right: 0.9rem
  }

  .print\:my-10 {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem
  }

  .print\:mx-10 {
    margin-left: 1.0rem;
    margin-right: 1.0rem
  }

  .print\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  .print\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  .print\:my-14 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem
  }

  .print\:mx-14 {
    margin-left: 1.4rem;
    margin-right: 1.4rem
  }

  .print\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  .print\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  .print\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .print\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .print\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  .print\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .print\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  .print\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  .print\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  .print\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  .print\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  .print\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  .print\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .print\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .print\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  .print\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  .print\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  .print\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  .print\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  .print\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  .print\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  .print\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  .print\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .print\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .print\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  .print\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  .print\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  .print\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  .print\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  .print\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  .print\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  .print\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  .print\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .print\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .print\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  .print\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  .print\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  .print\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  .print\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  .print\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  .print\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  .print\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  .print\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  .print\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  .print\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  .print\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  .print\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .print\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .print\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  .print\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  .print\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  .print\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  .print\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  .print\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  .print\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  .print\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  .print\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  .print\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  .print\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  .print\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  .print\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  .print\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  .print\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  .print\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  .print\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  .print\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  .print\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  .print\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  .print\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  .print\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  .print\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  .print\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  .print\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  .print\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  .print\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .print\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .print\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  .print\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  .print\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  .print\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  .print\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  .print\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  .print\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  .print\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  .print\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  .print\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  .print\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  .print\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  .print\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  .print\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  .print\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  .print\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  .print\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  .print\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  .print\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .print\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .print\:my-0\.5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem
  }

  .print\:mx-0\.5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem
  }

  .print\:my-1\.5 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem
  }

  .print\:mx-1\.5 {
    margin-left: 0.15rem;
    margin-right: 0.15rem
  }

  .print\:my-2\.5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .print\:mx-2\.5 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .print\:my-3\.5 {
    margin-top: 0.35rem;
    margin-bottom: 0.35rem
  }

  .print\:mx-3\.5 {
    margin-left: 0.35rem;
    margin-right: 0.35rem
  }

  .print\:-my-1 {
    margin-top: -0.1rem;
    margin-bottom: -0.1rem
  }

  .print\:-mx-1 {
    margin-left: -0.1rem;
    margin-right: -0.1rem
  }

  .print\:-my-2 {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem
  }

  .print\:-mx-2 {
    margin-left: -0.2rem;
    margin-right: -0.2rem
  }

  .print\:-my-3 {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem
  }

  .print\:-mx-3 {
    margin-left: -0.3rem;
    margin-right: -0.3rem
  }

  .print\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  .print\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  .print\:-my-5 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .print\:-mx-5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .print\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  .print\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  .print\:-my-7 {
    margin-top: -0.7rem;
    margin-bottom: -0.7rem
  }

  .print\:-mx-7 {
    margin-left: -0.7rem;
    margin-right: -0.7rem
  }

  .print\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  .print\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  .print\:-my-9 {
    margin-top: -0.9rem;
    margin-bottom: -0.9rem
  }

  .print\:-mx-9 {
    margin-left: -0.9rem;
    margin-right: -0.9rem
  }

  .print\:-my-10 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .print\:-mx-10 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .print\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  .print\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  .print\:-my-14 {
    margin-top: -1.4rem;
    margin-bottom: -1.4rem
  }

  .print\:-mx-14 {
    margin-left: -1.4rem;
    margin-right: -1.4rem
  }

  .print\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  .print\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  .print\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .print\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .print\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  .print\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  .print\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  .print\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  .print\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  .print\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  .print\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  .print\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  .print\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .print\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .print\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  .print\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  .print\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  .print\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  .print\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  .print\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  .print\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  .print\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  .print\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .print\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .print\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  .print\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  .print\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  .print\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  .print\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  .print\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  .print\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  .print\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  .print\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .print\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .print\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  .print\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  .print\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  .print\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  .print\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  .print\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  .print\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  .print\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  .print\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  .print\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  .print\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  .print\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  .print\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .print\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .print\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  .print\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  .print\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  .print\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  .print\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  .print\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  .print\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  .print\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  .print\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  .print\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  .print\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  .print\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  .print\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  .print\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  .print\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  .print\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  .print\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  .print\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  .print\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  .print\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  .print\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  .print\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  .print\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  .print\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  .print\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  .print\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  .print\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  .print\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  .print\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  .print\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  .print\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  .print\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  .print\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  .print\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  .print\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  .print\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  .print\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  .print\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  .print\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  .print\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  .print\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  .print\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  .print\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  .print\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  .print\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .print\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .print\:-my-0\.5 {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem
  }

  .print\:-mx-0\.5 {
    margin-left: -0.05rem;
    margin-right: -0.05rem
  }

  .print\:-my-1\.5 {
    margin-top: -0.15rem;
    margin-bottom: -0.15rem
  }

  .print\:-mx-1\.5 {
    margin-left: -0.15rem;
    margin-right: -0.15rem
  }

  .print\:-my-2\.5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .print\:-mx-2\.5 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .print\:-my-3\.5 {
    margin-top: -0.35rem;
    margin-bottom: -0.35rem
  }

  .print\:-mx-3\.5 {
    margin-left: -0.35rem;
    margin-right: -0.35rem
  }

  .print\:mt-0 {
    margin-top: 0
  }

  .print\:mr-0 {
    margin-right: 0
  }

  .print\:mb-0 {
    margin-bottom: 0
  }

  .print\:ml-0 {
    margin-left: 0
  }

  .print\:mt-1 {
    margin-top: 0.1rem
  }

  .print\:mr-1 {
    margin-right: 0.1rem
  }

  .print\:mb-1 {
    margin-bottom: 0.1rem
  }

  .print\:ml-1 {
    margin-left: 0.1rem
  }

  .print\:mt-2 {
    margin-top: 0.2rem
  }

  .print\:mr-2 {
    margin-right: 0.2rem
  }

  .print\:mb-2 {
    margin-bottom: 0.2rem
  }

  .print\:ml-2 {
    margin-left: 0.2rem
  }

  .print\:mt-3 {
    margin-top: 0.3rem
  }

  .print\:mr-3 {
    margin-right: 0.3rem
  }

  .print\:mb-3 {
    margin-bottom: 0.3rem
  }

  .print\:ml-3 {
    margin-left: 0.3rem
  }

  .print\:mt-4 {
    margin-top: 0.4rem
  }

  .print\:mr-4 {
    margin-right: 0.4rem
  }

  .print\:mb-4 {
    margin-bottom: 0.4rem
  }

  .print\:ml-4 {
    margin-left: 0.4rem
  }

  .print\:mt-5 {
    margin-top: 0.5rem
  }

  .print\:mr-5 {
    margin-right: 0.5rem
  }

  .print\:mb-5 {
    margin-bottom: 0.5rem
  }

  .print\:ml-5 {
    margin-left: 0.5rem
  }

  .print\:mt-6 {
    margin-top: 0.6rem
  }

  .print\:mr-6 {
    margin-right: 0.6rem
  }

  .print\:mb-6 {
    margin-bottom: 0.6rem
  }

  .print\:ml-6 {
    margin-left: 0.6rem
  }

  .print\:mt-7 {
    margin-top: 0.7rem
  }

  .print\:mr-7 {
    margin-right: 0.7rem
  }

  .print\:mb-7 {
    margin-bottom: 0.7rem
  }

  .print\:ml-7 {
    margin-left: 0.7rem
  }

  .print\:mt-8 {
    margin-top: 0.8rem
  }

  .print\:mr-8 {
    margin-right: 0.8rem
  }

  .print\:mb-8 {
    margin-bottom: 0.8rem
  }

  .print\:ml-8 {
    margin-left: 0.8rem
  }

  .print\:mt-9 {
    margin-top: 0.9rem
  }

  .print\:mr-9 {
    margin-right: 0.9rem
  }

  .print\:mb-9 {
    margin-bottom: 0.9rem
  }

  .print\:ml-9 {
    margin-left: 0.9rem
  }

  .print\:mt-10 {
    margin-top: 1.0rem
  }

  .print\:mr-10 {
    margin-right: 1.0rem
  }

  .print\:mb-10 {
    margin-bottom: 1.0rem
  }

  .print\:ml-10 {
    margin-left: 1.0rem
  }

  .print\:mt-12 {
    margin-top: 1.2rem
  }

  .print\:mr-12 {
    margin-right: 1.2rem
  }

  .print\:mb-12 {
    margin-bottom: 1.2rem
  }

  .print\:ml-12 {
    margin-left: 1.2rem
  }

  .print\:mt-14 {
    margin-top: 1.4rem
  }

  .print\:mr-14 {
    margin-right: 1.4rem
  }

  .print\:mb-14 {
    margin-bottom: 1.4rem
  }

  .print\:ml-14 {
    margin-left: 1.4rem
  }

  .print\:mt-16 {
    margin-top: 1.6rem
  }

  .print\:mr-16 {
    margin-right: 1.6rem
  }

  .print\:mb-16 {
    margin-bottom: 1.6rem
  }

  .print\:ml-16 {
    margin-left: 1.6rem
  }

  .print\:mt-20 {
    margin-top: 2rem
  }

  .print\:mr-20 {
    margin-right: 2rem
  }

  .print\:mb-20 {
    margin-bottom: 2rem
  }

  .print\:ml-20 {
    margin-left: 2rem
  }

  .print\:mt-24 {
    margin-top: 2.4rem
  }

  .print\:mr-24 {
    margin-right: 2.4rem
  }

  .print\:mb-24 {
    margin-bottom: 2.4rem
  }

  .print\:ml-24 {
    margin-left: 2.4rem
  }

  .print\:mt-28 {
    margin-top: 2.8rem
  }

  .print\:mr-28 {
    margin-right: 2.8rem
  }

  .print\:mb-28 {
    margin-bottom: 2.8rem
  }

  .print\:ml-28 {
    margin-left: 2.8rem
  }

  .print\:mt-32 {
    margin-top: 3.2rem
  }

  .print\:mr-32 {
    margin-right: 3.2rem
  }

  .print\:mb-32 {
    margin-bottom: 3.2rem
  }

  .print\:ml-32 {
    margin-left: 3.2rem
  }

  .print\:mt-36 {
    margin-top: 3.6rem
  }

  .print\:mr-36 {
    margin-right: 3.6rem
  }

  .print\:mb-36 {
    margin-bottom: 3.6rem
  }

  .print\:ml-36 {
    margin-left: 3.6rem
  }

  .print\:mt-40 {
    margin-top: 4rem
  }

  .print\:mr-40 {
    margin-right: 4rem
  }

  .print\:mb-40 {
    margin-bottom: 4rem
  }

  .print\:ml-40 {
    margin-left: 4rem
  }

  .print\:mt-44 {
    margin-top: 4.4rem
  }

  .print\:mr-44 {
    margin-right: 4.4rem
  }

  .print\:mb-44 {
    margin-bottom: 4.4rem
  }

  .print\:ml-44 {
    margin-left: 4.4rem
  }

  .print\:mt-48 {
    margin-top: 4.8rem
  }

  .print\:mr-48 {
    margin-right: 4.8rem
  }

  .print\:mb-48 {
    margin-bottom: 4.8rem
  }

  .print\:ml-48 {
    margin-left: 4.8rem
  }

  .print\:mt-52 {
    margin-top: 5.2rem
  }

  .print\:mr-52 {
    margin-right: 5.2rem
  }

  .print\:mb-52 {
    margin-bottom: 5.2rem
  }

  .print\:ml-52 {
    margin-left: 5.2rem
  }

  .print\:mt-56 {
    margin-top: 5.6rem
  }

  .print\:mr-56 {
    margin-right: 5.6rem
  }

  .print\:mb-56 {
    margin-bottom: 5.6rem
  }

  .print\:ml-56 {
    margin-left: 5.6rem
  }

  .print\:mt-60 {
    margin-top: 6rem
  }

  .print\:mr-60 {
    margin-right: 6rem
  }

  .print\:mb-60 {
    margin-bottom: 6rem
  }

  .print\:ml-60 {
    margin-left: 6rem
  }

  .print\:mt-64 {
    margin-top: 6.4rem
  }

  .print\:mr-64 {
    margin-right: 6.4rem
  }

  .print\:mb-64 {
    margin-bottom: 6.4rem
  }

  .print\:ml-64 {
    margin-left: 6.4rem
  }

  .print\:mt-68 {
    margin-top: 6.8rem
  }

  .print\:mr-68 {
    margin-right: 6.8rem
  }

  .print\:mb-68 {
    margin-bottom: 6.8rem
  }

  .print\:ml-68 {
    margin-left: 6.8rem
  }

  .print\:mt-72 {
    margin-top: 7.2rem
  }

  .print\:mr-72 {
    margin-right: 7.2rem
  }

  .print\:mb-72 {
    margin-bottom: 7.2rem
  }

  .print\:ml-72 {
    margin-left: 7.2rem
  }

  .print\:mt-76 {
    margin-top: 7.6rem
  }

  .print\:mr-76 {
    margin-right: 7.6rem
  }

  .print\:mb-76 {
    margin-bottom: 7.6rem
  }

  .print\:ml-76 {
    margin-left: 7.6rem
  }

  .print\:mt-80 {
    margin-top: 8rem
  }

  .print\:mr-80 {
    margin-right: 8rem
  }

  .print\:mb-80 {
    margin-bottom: 8rem
  }

  .print\:ml-80 {
    margin-left: 8rem
  }

  .print\:mt-84 {
    margin-top: 8.4rem
  }

  .print\:mr-84 {
    margin-right: 8.4rem
  }

  .print\:mb-84 {
    margin-bottom: 8.4rem
  }

  .print\:ml-84 {
    margin-left: 8.4rem
  }

  .print\:mt-88 {
    margin-top: 8.8rem
  }

  .print\:mr-88 {
    margin-right: 8.8rem
  }

  .print\:mb-88 {
    margin-bottom: 8.8rem
  }

  .print\:ml-88 {
    margin-left: 8.8rem
  }

  .print\:mt-92 {
    margin-top: 9.2rem
  }

  .print\:mr-92 {
    margin-right: 9.2rem
  }

  .print\:mb-92 {
    margin-bottom: 9.2rem
  }

  .print\:ml-92 {
    margin-left: 9.2rem
  }

  .print\:mt-96 {
    margin-top: 9.6rem
  }

  .print\:mr-96 {
    margin-right: 9.6rem
  }

  .print\:mb-96 {
    margin-bottom: 9.6rem
  }

  .print\:ml-96 {
    margin-left: 9.6rem
  }

  .print\:mt-128 {
    margin-top: 12.8rem
  }

  .print\:mr-128 {
    margin-right: 12.8rem
  }

  .print\:mb-128 {
    margin-bottom: 12.8rem
  }

  .print\:ml-128 {
    margin-left: 12.8rem
  }

  .print\:mt-136 {
    margin-top: 13.6rem
  }

  .print\:mr-136 {
    margin-right: 13.6rem
  }

  .print\:mb-136 {
    margin-bottom: 13.6rem
  }

  .print\:ml-136 {
    margin-left: 13.6rem
  }

  .print\:mt-160 {
    margin-top: 16rem
  }

  .print\:mr-160 {
    margin-right: 16rem
  }

  .print\:mb-160 {
    margin-bottom: 16rem
  }

  .print\:ml-160 {
    margin-left: 16rem
  }

  .print\:mt-192 {
    margin-top: 19.2rem
  }

  .print\:mr-192 {
    margin-right: 19.2rem
  }

  .print\:mb-192 {
    margin-bottom: 19.2rem
  }

  .print\:ml-192 {
    margin-left: 19.2rem
  }

  .print\:mt-200 {
    margin-top: 20rem
  }

  .print\:mr-200 {
    margin-right: 20rem
  }

  .print\:mb-200 {
    margin-bottom: 20rem
  }

  .print\:ml-200 {
    margin-left: 20rem
  }

  .print\:mt-208 {
    margin-top: 20.8rem
  }

  .print\:mr-208 {
    margin-right: 20.8rem
  }

  .print\:mb-208 {
    margin-bottom: 20.8rem
  }

  .print\:ml-208 {
    margin-left: 20.8rem
  }

  .print\:mt-216 {
    margin-top: 21.6rem
  }

  .print\:mr-216 {
    margin-right: 21.6rem
  }

  .print\:mb-216 {
    margin-bottom: 21.6rem
  }

  .print\:ml-216 {
    margin-left: 21.6rem
  }

  .print\:mt-224 {
    margin-top: 22.4rem
  }

  .print\:mr-224 {
    margin-right: 22.4rem
  }

  .print\:mb-224 {
    margin-bottom: 22.4rem
  }

  .print\:ml-224 {
    margin-left: 22.4rem
  }

  .print\:mt-256 {
    margin-top: 25.6rem
  }

  .print\:mr-256 {
    margin-right: 25.6rem
  }

  .print\:mb-256 {
    margin-bottom: 25.6rem
  }

  .print\:ml-256 {
    margin-left: 25.6rem
  }

  .print\:mt-288 {
    margin-top: 28.8rem
  }

  .print\:mr-288 {
    margin-right: 28.8rem
  }

  .print\:mb-288 {
    margin-bottom: 28.8rem
  }

  .print\:ml-288 {
    margin-left: 28.8rem
  }

  .print\:mt-320 {
    margin-top: 32rem
  }

  .print\:mr-320 {
    margin-right: 32rem
  }

  .print\:mb-320 {
    margin-bottom: 32rem
  }

  .print\:ml-320 {
    margin-left: 32rem
  }

  .print\:mt-360 {
    margin-top: 36rem
  }

  .print\:mr-360 {
    margin-right: 36rem
  }

  .print\:mb-360 {
    margin-bottom: 36rem
  }

  .print\:ml-360 {
    margin-left: 36rem
  }

  .print\:mt-384 {
    margin-top: 38.4rem
  }

  .print\:mr-384 {
    margin-right: 38.4rem
  }

  .print\:mb-384 {
    margin-bottom: 38.4rem
  }

  .print\:ml-384 {
    margin-left: 38.4rem
  }

  .print\:mt-400 {
    margin-top: 40rem
  }

  .print\:mr-400 {
    margin-right: 40rem
  }

  .print\:mb-400 {
    margin-bottom: 40rem
  }

  .print\:ml-400 {
    margin-left: 40rem
  }

  .print\:mt-512 {
    margin-top: 51.2rem
  }

  .print\:mr-512 {
    margin-right: 51.2rem
  }

  .print\:mb-512 {
    margin-bottom: 51.2rem
  }

  .print\:ml-512 {
    margin-left: 51.2rem
  }

  .print\:mt-640 {
    margin-top: 64rem
  }

  .print\:mr-640 {
    margin-right: 64rem
  }

  .print\:mb-640 {
    margin-bottom: 64rem
  }

  .print\:ml-640 {
    margin-left: 64rem
  }

  .print\:mt-auto {
    margin-top: auto
  }

  .print\:mr-auto {
    margin-right: auto
  }

  .print\:mb-auto {
    margin-bottom: auto
  }

  .print\:ml-auto {
    margin-left: auto
  }

  .print\:mt-xs {
    margin-top: 32rem
  }

  .print\:mr-xs {
    margin-right: 32rem
  }

  .print\:mb-xs {
    margin-bottom: 32rem
  }

  .print\:ml-xs {
    margin-left: 32rem
  }

  .print\:mt-sm {
    margin-top: 48rem
  }

  .print\:mr-sm {
    margin-right: 48rem
  }

  .print\:mb-sm {
    margin-bottom: 48rem
  }

  .print\:ml-sm {
    margin-left: 48rem
  }

  .print\:mt-md {
    margin-top: 64rem
  }

  .print\:mr-md {
    margin-right: 64rem
  }

  .print\:mb-md {
    margin-bottom: 64rem
  }

  .print\:ml-md {
    margin-left: 64rem
  }

  .print\:mt-lg {
    margin-top: 80rem
  }

  .print\:mr-lg {
    margin-right: 80rem
  }

  .print\:mb-lg {
    margin-bottom: 80rem
  }

  .print\:ml-lg {
    margin-left: 80rem
  }

  .print\:mt-xl {
    margin-top: 96rem
  }

  .print\:mr-xl {
    margin-right: 96rem
  }

  .print\:mb-xl {
    margin-bottom: 96rem
  }

  .print\:ml-xl {
    margin-left: 96rem
  }

  .print\:mt-2xl {
    margin-top: 112rem
  }

  .print\:mr-2xl {
    margin-right: 112rem
  }

  .print\:mb-2xl {
    margin-bottom: 112rem
  }

  .print\:ml-2xl {
    margin-left: 112rem
  }

  .print\:mt-3xl {
    margin-top: 128rem
  }

  .print\:mr-3xl {
    margin-right: 128rem
  }

  .print\:mb-3xl {
    margin-bottom: 128rem
  }

  .print\:ml-3xl {
    margin-left: 128rem
  }

  .print\:mt-4xl {
    margin-top: 144rem
  }

  .print\:mr-4xl {
    margin-right: 144rem
  }

  .print\:mb-4xl {
    margin-bottom: 144rem
  }

  .print\:ml-4xl {
    margin-left: 144rem
  }

  .print\:mt-5xl {
    margin-top: 160rem
  }

  .print\:mr-5xl {
    margin-right: 160rem
  }

  .print\:mb-5xl {
    margin-bottom: 160rem
  }

  .print\:ml-5xl {
    margin-left: 160rem
  }

  .print\:mt-px {
    margin-top: 1px
  }

  .print\:mr-px {
    margin-right: 1px
  }

  .print\:mb-px {
    margin-bottom: 1px
  }

  .print\:ml-px {
    margin-left: 1px
  }

  .print\:mt-0\.5 {
    margin-top: 0.05rem
  }

  .print\:mr-0\.5 {
    margin-right: 0.05rem
  }

  .print\:mb-0\.5 {
    margin-bottom: 0.05rem
  }

  .print\:ml-0\.5 {
    margin-left: 0.05rem
  }

  .print\:mt-1\.5 {
    margin-top: 0.15rem
  }

  .print\:mr-1\.5 {
    margin-right: 0.15rem
  }

  .print\:mb-1\.5 {
    margin-bottom: 0.15rem
  }

  .print\:ml-1\.5 {
    margin-left: 0.15rem
  }

  .print\:mt-2\.5 {
    margin-top: 0.25rem
  }

  .print\:mr-2\.5 {
    margin-right: 0.25rem
  }

  .print\:mb-2\.5 {
    margin-bottom: 0.25rem
  }

  .print\:ml-2\.5 {
    margin-left: 0.25rem
  }

  .print\:mt-3\.5 {
    margin-top: 0.35rem
  }

  .print\:mr-3\.5 {
    margin-right: 0.35rem
  }

  .print\:mb-3\.5 {
    margin-bottom: 0.35rem
  }

  .print\:ml-3\.5 {
    margin-left: 0.35rem
  }

  .print\:-mt-1 {
    margin-top: -0.1rem
  }

  .print\:-mr-1 {
    margin-right: -0.1rem
  }

  .print\:-mb-1 {
    margin-bottom: -0.1rem
  }

  .print\:-ml-1 {
    margin-left: -0.1rem
  }

  .print\:-mt-2 {
    margin-top: -0.2rem
  }

  .print\:-mr-2 {
    margin-right: -0.2rem
  }

  .print\:-mb-2 {
    margin-bottom: -0.2rem
  }

  .print\:-ml-2 {
    margin-left: -0.2rem
  }

  .print\:-mt-3 {
    margin-top: -0.3rem
  }

  .print\:-mr-3 {
    margin-right: -0.3rem
  }

  .print\:-mb-3 {
    margin-bottom: -0.3rem
  }

  .print\:-ml-3 {
    margin-left: -0.3rem
  }

  .print\:-mt-4 {
    margin-top: -0.4rem
  }

  .print\:-mr-4 {
    margin-right: -0.4rem
  }

  .print\:-mb-4 {
    margin-bottom: -0.4rem
  }

  .print\:-ml-4 {
    margin-left: -0.4rem
  }

  .print\:-mt-5 {
    margin-top: -0.5rem
  }

  .print\:-mr-5 {
    margin-right: -0.5rem
  }

  .print\:-mb-5 {
    margin-bottom: -0.5rem
  }

  .print\:-ml-5 {
    margin-left: -0.5rem
  }

  .print\:-mt-6 {
    margin-top: -0.6rem
  }

  .print\:-mr-6 {
    margin-right: -0.6rem
  }

  .print\:-mb-6 {
    margin-bottom: -0.6rem
  }

  .print\:-ml-6 {
    margin-left: -0.6rem
  }

  .print\:-mt-7 {
    margin-top: -0.7rem
  }

  .print\:-mr-7 {
    margin-right: -0.7rem
  }

  .print\:-mb-7 {
    margin-bottom: -0.7rem
  }

  .print\:-ml-7 {
    margin-left: -0.7rem
  }

  .print\:-mt-8 {
    margin-top: -0.8rem
  }

  .print\:-mr-8 {
    margin-right: -0.8rem
  }

  .print\:-mb-8 {
    margin-bottom: -0.8rem
  }

  .print\:-ml-8 {
    margin-left: -0.8rem
  }

  .print\:-mt-9 {
    margin-top: -0.9rem
  }

  .print\:-mr-9 {
    margin-right: -0.9rem
  }

  .print\:-mb-9 {
    margin-bottom: -0.9rem
  }

  .print\:-ml-9 {
    margin-left: -0.9rem
  }

  .print\:-mt-10 {
    margin-top: -1rem
  }

  .print\:-mr-10 {
    margin-right: -1rem
  }

  .print\:-mb-10 {
    margin-bottom: -1rem
  }

  .print\:-ml-10 {
    margin-left: -1rem
  }

  .print\:-mt-12 {
    margin-top: -1.2rem
  }

  .print\:-mr-12 {
    margin-right: -1.2rem
  }

  .print\:-mb-12 {
    margin-bottom: -1.2rem
  }

  .print\:-ml-12 {
    margin-left: -1.2rem
  }

  .print\:-mt-14 {
    margin-top: -1.4rem
  }

  .print\:-mr-14 {
    margin-right: -1.4rem
  }

  .print\:-mb-14 {
    margin-bottom: -1.4rem
  }

  .print\:-ml-14 {
    margin-left: -1.4rem
  }

  .print\:-mt-16 {
    margin-top: -1.6rem
  }

  .print\:-mr-16 {
    margin-right: -1.6rem
  }

  .print\:-mb-16 {
    margin-bottom: -1.6rem
  }

  .print\:-ml-16 {
    margin-left: -1.6rem
  }

  .print\:-mt-20 {
    margin-top: -2rem
  }

  .print\:-mr-20 {
    margin-right: -2rem
  }

  .print\:-mb-20 {
    margin-bottom: -2rem
  }

  .print\:-ml-20 {
    margin-left: -2rem
  }

  .print\:-mt-24 {
    margin-top: -2.4rem
  }

  .print\:-mr-24 {
    margin-right: -2.4rem
  }

  .print\:-mb-24 {
    margin-bottom: -2.4rem
  }

  .print\:-ml-24 {
    margin-left: -2.4rem
  }

  .print\:-mt-28 {
    margin-top: -2.8rem
  }

  .print\:-mr-28 {
    margin-right: -2.8rem
  }

  .print\:-mb-28 {
    margin-bottom: -2.8rem
  }

  .print\:-ml-28 {
    margin-left: -2.8rem
  }

  .print\:-mt-32 {
    margin-top: -3.2rem
  }

  .print\:-mr-32 {
    margin-right: -3.2rem
  }

  .print\:-mb-32 {
    margin-bottom: -3.2rem
  }

  .print\:-ml-32 {
    margin-left: -3.2rem
  }

  .print\:-mt-36 {
    margin-top: -3.6rem
  }

  .print\:-mr-36 {
    margin-right: -3.6rem
  }

  .print\:-mb-36 {
    margin-bottom: -3.6rem
  }

  .print\:-ml-36 {
    margin-left: -3.6rem
  }

  .print\:-mt-40 {
    margin-top: -4rem
  }

  .print\:-mr-40 {
    margin-right: -4rem
  }

  .print\:-mb-40 {
    margin-bottom: -4rem
  }

  .print\:-ml-40 {
    margin-left: -4rem
  }

  .print\:-mt-44 {
    margin-top: -4.4rem
  }

  .print\:-mr-44 {
    margin-right: -4.4rem
  }

  .print\:-mb-44 {
    margin-bottom: -4.4rem
  }

  .print\:-ml-44 {
    margin-left: -4.4rem
  }

  .print\:-mt-48 {
    margin-top: -4.8rem
  }

  .print\:-mr-48 {
    margin-right: -4.8rem
  }

  .print\:-mb-48 {
    margin-bottom: -4.8rem
  }

  .print\:-ml-48 {
    margin-left: -4.8rem
  }

  .print\:-mt-52 {
    margin-top: -5.2rem
  }

  .print\:-mr-52 {
    margin-right: -5.2rem
  }

  .print\:-mb-52 {
    margin-bottom: -5.2rem
  }

  .print\:-ml-52 {
    margin-left: -5.2rem
  }

  .print\:-mt-56 {
    margin-top: -5.6rem
  }

  .print\:-mr-56 {
    margin-right: -5.6rem
  }

  .print\:-mb-56 {
    margin-bottom: -5.6rem
  }

  .print\:-ml-56 {
    margin-left: -5.6rem
  }

  .print\:-mt-60 {
    margin-top: -6rem
  }

  .print\:-mr-60 {
    margin-right: -6rem
  }

  .print\:-mb-60 {
    margin-bottom: -6rem
  }

  .print\:-ml-60 {
    margin-left: -6rem
  }

  .print\:-mt-64 {
    margin-top: -6.4rem
  }

  .print\:-mr-64 {
    margin-right: -6.4rem
  }

  .print\:-mb-64 {
    margin-bottom: -6.4rem
  }

  .print\:-ml-64 {
    margin-left: -6.4rem
  }

  .print\:-mt-68 {
    margin-top: -6.8rem
  }

  .print\:-mr-68 {
    margin-right: -6.8rem
  }

  .print\:-mb-68 {
    margin-bottom: -6.8rem
  }

  .print\:-ml-68 {
    margin-left: -6.8rem
  }

  .print\:-mt-72 {
    margin-top: -7.2rem
  }

  .print\:-mr-72 {
    margin-right: -7.2rem
  }

  .print\:-mb-72 {
    margin-bottom: -7.2rem
  }

  .print\:-ml-72 {
    margin-left: -7.2rem
  }

  .print\:-mt-76 {
    margin-top: -7.6rem
  }

  .print\:-mr-76 {
    margin-right: -7.6rem
  }

  .print\:-mb-76 {
    margin-bottom: -7.6rem
  }

  .print\:-ml-76 {
    margin-left: -7.6rem
  }

  .print\:-mt-80 {
    margin-top: -8rem
  }

  .print\:-mr-80 {
    margin-right: -8rem
  }

  .print\:-mb-80 {
    margin-bottom: -8rem
  }

  .print\:-ml-80 {
    margin-left: -8rem
  }

  .print\:-mt-84 {
    margin-top: -8.4rem
  }

  .print\:-mr-84 {
    margin-right: -8.4rem
  }

  .print\:-mb-84 {
    margin-bottom: -8.4rem
  }

  .print\:-ml-84 {
    margin-left: -8.4rem
  }

  .print\:-mt-88 {
    margin-top: -8.8rem
  }

  .print\:-mr-88 {
    margin-right: -8.8rem
  }

  .print\:-mb-88 {
    margin-bottom: -8.8rem
  }

  .print\:-ml-88 {
    margin-left: -8.8rem
  }

  .print\:-mt-92 {
    margin-top: -9.2rem
  }

  .print\:-mr-92 {
    margin-right: -9.2rem
  }

  .print\:-mb-92 {
    margin-bottom: -9.2rem
  }

  .print\:-ml-92 {
    margin-left: -9.2rem
  }

  .print\:-mt-96 {
    margin-top: -9.6rem
  }

  .print\:-mr-96 {
    margin-right: -9.6rem
  }

  .print\:-mb-96 {
    margin-bottom: -9.6rem
  }

  .print\:-ml-96 {
    margin-left: -9.6rem
  }

  .print\:-mt-128 {
    margin-top: -12.8rem
  }

  .print\:-mr-128 {
    margin-right: -12.8rem
  }

  .print\:-mb-128 {
    margin-bottom: -12.8rem
  }

  .print\:-ml-128 {
    margin-left: -12.8rem
  }

  .print\:-mt-136 {
    margin-top: -13.6rem
  }

  .print\:-mr-136 {
    margin-right: -13.6rem
  }

  .print\:-mb-136 {
    margin-bottom: -13.6rem
  }

  .print\:-ml-136 {
    margin-left: -13.6rem
  }

  .print\:-mt-160 {
    margin-top: -16rem
  }

  .print\:-mr-160 {
    margin-right: -16rem
  }

  .print\:-mb-160 {
    margin-bottom: -16rem
  }

  .print\:-ml-160 {
    margin-left: -16rem
  }

  .print\:-mt-192 {
    margin-top: -19.2rem
  }

  .print\:-mr-192 {
    margin-right: -19.2rem
  }

  .print\:-mb-192 {
    margin-bottom: -19.2rem
  }

  .print\:-ml-192 {
    margin-left: -19.2rem
  }

  .print\:-mt-200 {
    margin-top: -20rem
  }

  .print\:-mr-200 {
    margin-right: -20rem
  }

  .print\:-mb-200 {
    margin-bottom: -20rem
  }

  .print\:-ml-200 {
    margin-left: -20rem
  }

  .print\:-mt-208 {
    margin-top: -20.8rem
  }

  .print\:-mr-208 {
    margin-right: -20.8rem
  }

  .print\:-mb-208 {
    margin-bottom: -20.8rem
  }

  .print\:-ml-208 {
    margin-left: -20.8rem
  }

  .print\:-mt-216 {
    margin-top: -21.6rem
  }

  .print\:-mr-216 {
    margin-right: -21.6rem
  }

  .print\:-mb-216 {
    margin-bottom: -21.6rem
  }

  .print\:-ml-216 {
    margin-left: -21.6rem
  }

  .print\:-mt-224 {
    margin-top: -22.4rem
  }

  .print\:-mr-224 {
    margin-right: -22.4rem
  }

  .print\:-mb-224 {
    margin-bottom: -22.4rem
  }

  .print\:-ml-224 {
    margin-left: -22.4rem
  }

  .print\:-mt-256 {
    margin-top: -25.6rem
  }

  .print\:-mr-256 {
    margin-right: -25.6rem
  }

  .print\:-mb-256 {
    margin-bottom: -25.6rem
  }

  .print\:-ml-256 {
    margin-left: -25.6rem
  }

  .print\:-mt-288 {
    margin-top: -28.8rem
  }

  .print\:-mr-288 {
    margin-right: -28.8rem
  }

  .print\:-mb-288 {
    margin-bottom: -28.8rem
  }

  .print\:-ml-288 {
    margin-left: -28.8rem
  }

  .print\:-mt-320 {
    margin-top: -32rem
  }

  .print\:-mr-320 {
    margin-right: -32rem
  }

  .print\:-mb-320 {
    margin-bottom: -32rem
  }

  .print\:-ml-320 {
    margin-left: -32rem
  }

  .print\:-mt-360 {
    margin-top: -36rem
  }

  .print\:-mr-360 {
    margin-right: -36rem
  }

  .print\:-mb-360 {
    margin-bottom: -36rem
  }

  .print\:-ml-360 {
    margin-left: -36rem
  }

  .print\:-mt-384 {
    margin-top: -38.4rem
  }

  .print\:-mr-384 {
    margin-right: -38.4rem
  }

  .print\:-mb-384 {
    margin-bottom: -38.4rem
  }

  .print\:-ml-384 {
    margin-left: -38.4rem
  }

  .print\:-mt-400 {
    margin-top: -40rem
  }

  .print\:-mr-400 {
    margin-right: -40rem
  }

  .print\:-mb-400 {
    margin-bottom: -40rem
  }

  .print\:-ml-400 {
    margin-left: -40rem
  }

  .print\:-mt-512 {
    margin-top: -51.2rem
  }

  .print\:-mr-512 {
    margin-right: -51.2rem
  }

  .print\:-mb-512 {
    margin-bottom: -51.2rem
  }

  .print\:-ml-512 {
    margin-left: -51.2rem
  }

  .print\:-mt-640 {
    margin-top: -64rem
  }

  .print\:-mr-640 {
    margin-right: -64rem
  }

  .print\:-mb-640 {
    margin-bottom: -64rem
  }

  .print\:-ml-640 {
    margin-left: -64rem
  }

  .print\:-mt-xs {
    margin-top: -32rem
  }

  .print\:-mr-xs {
    margin-right: -32rem
  }

  .print\:-mb-xs {
    margin-bottom: -32rem
  }

  .print\:-ml-xs {
    margin-left: -32rem
  }

  .print\:-mt-sm {
    margin-top: -48rem
  }

  .print\:-mr-sm {
    margin-right: -48rem
  }

  .print\:-mb-sm {
    margin-bottom: -48rem
  }

  .print\:-ml-sm {
    margin-left: -48rem
  }

  .print\:-mt-md {
    margin-top: -64rem
  }

  .print\:-mr-md {
    margin-right: -64rem
  }

  .print\:-mb-md {
    margin-bottom: -64rem
  }

  .print\:-ml-md {
    margin-left: -64rem
  }

  .print\:-mt-lg {
    margin-top: -80rem
  }

  .print\:-mr-lg {
    margin-right: -80rem
  }

  .print\:-mb-lg {
    margin-bottom: -80rem
  }

  .print\:-ml-lg {
    margin-left: -80rem
  }

  .print\:-mt-xl {
    margin-top: -96rem
  }

  .print\:-mr-xl {
    margin-right: -96rem
  }

  .print\:-mb-xl {
    margin-bottom: -96rem
  }

  .print\:-ml-xl {
    margin-left: -96rem
  }

  .print\:-mt-2xl {
    margin-top: -112rem
  }

  .print\:-mr-2xl {
    margin-right: -112rem
  }

  .print\:-mb-2xl {
    margin-bottom: -112rem
  }

  .print\:-ml-2xl {
    margin-left: -112rem
  }

  .print\:-mt-3xl {
    margin-top: -128rem
  }

  .print\:-mr-3xl {
    margin-right: -128rem
  }

  .print\:-mb-3xl {
    margin-bottom: -128rem
  }

  .print\:-ml-3xl {
    margin-left: -128rem
  }

  .print\:-mt-4xl {
    margin-top: -144rem
  }

  .print\:-mr-4xl {
    margin-right: -144rem
  }

  .print\:-mb-4xl {
    margin-bottom: -144rem
  }

  .print\:-ml-4xl {
    margin-left: -144rem
  }

  .print\:-mt-5xl {
    margin-top: -160rem
  }

  .print\:-mr-5xl {
    margin-right: -160rem
  }

  .print\:-mb-5xl {
    margin-bottom: -160rem
  }

  .print\:-ml-5xl {
    margin-left: -160rem
  }

  .print\:-mt-px {
    margin-top: -1px
  }

  .print\:-mr-px {
    margin-right: -1px
  }

  .print\:-mb-px {
    margin-bottom: -1px
  }

  .print\:-ml-px {
    margin-left: -1px
  }

  .print\:-mt-0\.5 {
    margin-top: -0.05rem
  }

  .print\:-mr-0\.5 {
    margin-right: -0.05rem
  }

  .print\:-mb-0\.5 {
    margin-bottom: -0.05rem
  }

  .print\:-ml-0\.5 {
    margin-left: -0.05rem
  }

  .print\:-mt-1\.5 {
    margin-top: -0.15rem
  }

  .print\:-mr-1\.5 {
    margin-right: -0.15rem
  }

  .print\:-mb-1\.5 {
    margin-bottom: -0.15rem
  }

  .print\:-ml-1\.5 {
    margin-left: -0.15rem
  }

  .print\:-mt-2\.5 {
    margin-top: -0.25rem
  }

  .print\:-mr-2\.5 {
    margin-right: -0.25rem
  }

  .print\:-mb-2\.5 {
    margin-bottom: -0.25rem
  }

  .print\:-ml-2\.5 {
    margin-left: -0.25rem
  }

  .print\:-mt-3\.5 {
    margin-top: -0.35rem
  }

  .print\:-mr-3\.5 {
    margin-right: -0.35rem
  }

  .print\:-mb-3\.5 {
    margin-bottom: -0.35rem
  }

  .print\:-ml-3\.5 {
    margin-left: -0.35rem
  }

  [dir='ltr'] .print\:ltr\:m-0,[dir='ltr'].print\:ltr\:m-0 {
    margin: 0
  }

  [dir='ltr'] .print\:ltr\:m-1,[dir='ltr'].print\:ltr\:m-1 {
    margin: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:m-2,[dir='ltr'].print\:ltr\:m-2 {
    margin: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:m-3,[dir='ltr'].print\:ltr\:m-3 {
    margin: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:m-4,[dir='ltr'].print\:ltr\:m-4 {
    margin: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:m-5,[dir='ltr'].print\:ltr\:m-5 {
    margin: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:m-6,[dir='ltr'].print\:ltr\:m-6 {
    margin: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:m-7,[dir='ltr'].print\:ltr\:m-7 {
    margin: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:m-8,[dir='ltr'].print\:ltr\:m-8 {
    margin: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:m-9,[dir='ltr'].print\:ltr\:m-9 {
    margin: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:m-10,[dir='ltr'].print\:ltr\:m-10 {
    margin: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:m-12,[dir='ltr'].print\:ltr\:m-12 {
    margin: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:m-14,[dir='ltr'].print\:ltr\:m-14 {
    margin: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:m-16,[dir='ltr'].print\:ltr\:m-16 {
    margin: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:m-20,[dir='ltr'].print\:ltr\:m-20 {
    margin: 2rem
  }

  [dir='ltr'] .print\:ltr\:m-24,[dir='ltr'].print\:ltr\:m-24 {
    margin: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:m-28,[dir='ltr'].print\:ltr\:m-28 {
    margin: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:m-32,[dir='ltr'].print\:ltr\:m-32 {
    margin: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:m-36,[dir='ltr'].print\:ltr\:m-36 {
    margin: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:m-40,[dir='ltr'].print\:ltr\:m-40 {
    margin: 4rem
  }

  [dir='ltr'] .print\:ltr\:m-44,[dir='ltr'].print\:ltr\:m-44 {
    margin: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:m-48,[dir='ltr'].print\:ltr\:m-48 {
    margin: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:m-52,[dir='ltr'].print\:ltr\:m-52 {
    margin: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:m-56,[dir='ltr'].print\:ltr\:m-56 {
    margin: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:m-60,[dir='ltr'].print\:ltr\:m-60 {
    margin: 6rem
  }

  [dir='ltr'] .print\:ltr\:m-64,[dir='ltr'].print\:ltr\:m-64 {
    margin: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:m-68,[dir='ltr'].print\:ltr\:m-68 {
    margin: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:m-72,[dir='ltr'].print\:ltr\:m-72 {
    margin: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:m-76,[dir='ltr'].print\:ltr\:m-76 {
    margin: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:m-80,[dir='ltr'].print\:ltr\:m-80 {
    margin: 8rem
  }

  [dir='ltr'] .print\:ltr\:m-84,[dir='ltr'].print\:ltr\:m-84 {
    margin: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:m-88,[dir='ltr'].print\:ltr\:m-88 {
    margin: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:m-92,[dir='ltr'].print\:ltr\:m-92 {
    margin: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:m-96,[dir='ltr'].print\:ltr\:m-96 {
    margin: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:m-128,[dir='ltr'].print\:ltr\:m-128 {
    margin: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:m-136,[dir='ltr'].print\:ltr\:m-136 {
    margin: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:m-160,[dir='ltr'].print\:ltr\:m-160 {
    margin: 16rem
  }

  [dir='ltr'] .print\:ltr\:m-192,[dir='ltr'].print\:ltr\:m-192 {
    margin: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:m-200,[dir='ltr'].print\:ltr\:m-200 {
    margin: 20rem
  }

  [dir='ltr'] .print\:ltr\:m-208,[dir='ltr'].print\:ltr\:m-208 {
    margin: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:m-216,[dir='ltr'].print\:ltr\:m-216 {
    margin: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:m-224,[dir='ltr'].print\:ltr\:m-224 {
    margin: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:m-256,[dir='ltr'].print\:ltr\:m-256 {
    margin: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:m-288,[dir='ltr'].print\:ltr\:m-288 {
    margin: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:m-320,[dir='ltr'].print\:ltr\:m-320 {
    margin: 32rem
  }

  [dir='ltr'] .print\:ltr\:m-360,[dir='ltr'].print\:ltr\:m-360 {
    margin: 36rem
  }

  [dir='ltr'] .print\:ltr\:m-384,[dir='ltr'].print\:ltr\:m-384 {
    margin: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:m-400,[dir='ltr'].print\:ltr\:m-400 {
    margin: 40rem
  }

  [dir='ltr'] .print\:ltr\:m-512,[dir='ltr'].print\:ltr\:m-512 {
    margin: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:m-640,[dir='ltr'].print\:ltr\:m-640 {
    margin: 64rem
  }

  [dir='ltr'] .print\:ltr\:m-auto,[dir='ltr'].print\:ltr\:m-auto {
    margin: auto
  }

  [dir='ltr'] .print\:ltr\:m-xs,[dir='ltr'].print\:ltr\:m-xs {
    margin: 32rem
  }

  [dir='ltr'] .print\:ltr\:m-sm,[dir='ltr'].print\:ltr\:m-sm {
    margin: 48rem
  }

  [dir='ltr'] .print\:ltr\:m-md,[dir='ltr'].print\:ltr\:m-md {
    margin: 64rem
  }

  [dir='ltr'] .print\:ltr\:m-lg,[dir='ltr'].print\:ltr\:m-lg {
    margin: 80rem
  }

  [dir='ltr'] .print\:ltr\:m-xl,[dir='ltr'].print\:ltr\:m-xl {
    margin: 96rem
  }

  [dir='ltr'] .print\:ltr\:m-2xl,[dir='ltr'].print\:ltr\:m-2xl {
    margin: 112rem
  }

  [dir='ltr'] .print\:ltr\:m-3xl,[dir='ltr'].print\:ltr\:m-3xl {
    margin: 128rem
  }

  [dir='ltr'] .print\:ltr\:m-4xl,[dir='ltr'].print\:ltr\:m-4xl {
    margin: 144rem
  }

  [dir='ltr'] .print\:ltr\:m-5xl,[dir='ltr'].print\:ltr\:m-5xl {
    margin: 160rem
  }

  [dir='ltr'] .print\:ltr\:m-px,[dir='ltr'].print\:ltr\:m-px {
    margin: 1px
  }

  [dir='ltr'] .print\:ltr\:m-0\.5,[dir='ltr'].print\:ltr\:m-0\.5 {
    margin: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:m-1\.5,[dir='ltr'].print\:ltr\:m-1\.5 {
    margin: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:m-2\.5,[dir='ltr'].print\:ltr\:m-2\.5 {
    margin: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:m-3\.5,[dir='ltr'].print\:ltr\:m-3\.5 {
    margin: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:-m-1,[dir='ltr'].print\:ltr\:-m-1 {
    margin: -0.1rem
  }

  [dir='ltr'] .print\:ltr\:-m-2,[dir='ltr'].print\:ltr\:-m-2 {
    margin: -0.2rem
  }

  [dir='ltr'] .print\:ltr\:-m-3,[dir='ltr'].print\:ltr\:-m-3 {
    margin: -0.3rem
  }

  [dir='ltr'] .print\:ltr\:-m-4,[dir='ltr'].print\:ltr\:-m-4 {
    margin: -0.4rem
  }

  [dir='ltr'] .print\:ltr\:-m-5,[dir='ltr'].print\:ltr\:-m-5 {
    margin: -0.5rem
  }

  [dir='ltr'] .print\:ltr\:-m-6,[dir='ltr'].print\:ltr\:-m-6 {
    margin: -0.6rem
  }

  [dir='ltr'] .print\:ltr\:-m-7,[dir='ltr'].print\:ltr\:-m-7 {
    margin: -0.7rem
  }

  [dir='ltr'] .print\:ltr\:-m-8,[dir='ltr'].print\:ltr\:-m-8 {
    margin: -0.8rem
  }

  [dir='ltr'] .print\:ltr\:-m-9,[dir='ltr'].print\:ltr\:-m-9 {
    margin: -0.9rem
  }

  [dir='ltr'] .print\:ltr\:-m-10,[dir='ltr'].print\:ltr\:-m-10 {
    margin: -1rem
  }

  [dir='ltr'] .print\:ltr\:-m-12,[dir='ltr'].print\:ltr\:-m-12 {
    margin: -1.2rem
  }

  [dir='ltr'] .print\:ltr\:-m-14,[dir='ltr'].print\:ltr\:-m-14 {
    margin: -1.4rem
  }

  [dir='ltr'] .print\:ltr\:-m-16,[dir='ltr'].print\:ltr\:-m-16 {
    margin: -1.6rem
  }

  [dir='ltr'] .print\:ltr\:-m-20,[dir='ltr'].print\:ltr\:-m-20 {
    margin: -2rem
  }

  [dir='ltr'] .print\:ltr\:-m-24,[dir='ltr'].print\:ltr\:-m-24 {
    margin: -2.4rem
  }

  [dir='ltr'] .print\:ltr\:-m-28,[dir='ltr'].print\:ltr\:-m-28 {
    margin: -2.8rem
  }

  [dir='ltr'] .print\:ltr\:-m-32,[dir='ltr'].print\:ltr\:-m-32 {
    margin: -3.2rem
  }

  [dir='ltr'] .print\:ltr\:-m-36,[dir='ltr'].print\:ltr\:-m-36 {
    margin: -3.6rem
  }

  [dir='ltr'] .print\:ltr\:-m-40,[dir='ltr'].print\:ltr\:-m-40 {
    margin: -4rem
  }

  [dir='ltr'] .print\:ltr\:-m-44,[dir='ltr'].print\:ltr\:-m-44 {
    margin: -4.4rem
  }

  [dir='ltr'] .print\:ltr\:-m-48,[dir='ltr'].print\:ltr\:-m-48 {
    margin: -4.8rem
  }

  [dir='ltr'] .print\:ltr\:-m-52,[dir='ltr'].print\:ltr\:-m-52 {
    margin: -5.2rem
  }

  [dir='ltr'] .print\:ltr\:-m-56,[dir='ltr'].print\:ltr\:-m-56 {
    margin: -5.6rem
  }

  [dir='ltr'] .print\:ltr\:-m-60,[dir='ltr'].print\:ltr\:-m-60 {
    margin: -6rem
  }

  [dir='ltr'] .print\:ltr\:-m-64,[dir='ltr'].print\:ltr\:-m-64 {
    margin: -6.4rem
  }

  [dir='ltr'] .print\:ltr\:-m-68,[dir='ltr'].print\:ltr\:-m-68 {
    margin: -6.8rem
  }

  [dir='ltr'] .print\:ltr\:-m-72,[dir='ltr'].print\:ltr\:-m-72 {
    margin: -7.2rem
  }

  [dir='ltr'] .print\:ltr\:-m-76,[dir='ltr'].print\:ltr\:-m-76 {
    margin: -7.6rem
  }

  [dir='ltr'] .print\:ltr\:-m-80,[dir='ltr'].print\:ltr\:-m-80 {
    margin: -8rem
  }

  [dir='ltr'] .print\:ltr\:-m-84,[dir='ltr'].print\:ltr\:-m-84 {
    margin: -8.4rem
  }

  [dir='ltr'] .print\:ltr\:-m-88,[dir='ltr'].print\:ltr\:-m-88 {
    margin: -8.8rem
  }

  [dir='ltr'] .print\:ltr\:-m-92,[dir='ltr'].print\:ltr\:-m-92 {
    margin: -9.2rem
  }

  [dir='ltr'] .print\:ltr\:-m-96,[dir='ltr'].print\:ltr\:-m-96 {
    margin: -9.6rem
  }

  [dir='ltr'] .print\:ltr\:-m-128,[dir='ltr'].print\:ltr\:-m-128 {
    margin: -12.8rem
  }

  [dir='ltr'] .print\:ltr\:-m-136,[dir='ltr'].print\:ltr\:-m-136 {
    margin: -13.6rem
  }

  [dir='ltr'] .print\:ltr\:-m-160,[dir='ltr'].print\:ltr\:-m-160 {
    margin: -16rem
  }

  [dir='ltr'] .print\:ltr\:-m-192,[dir='ltr'].print\:ltr\:-m-192 {
    margin: -19.2rem
  }

  [dir='ltr'] .print\:ltr\:-m-200,[dir='ltr'].print\:ltr\:-m-200 {
    margin: -20rem
  }

  [dir='ltr'] .print\:ltr\:-m-208,[dir='ltr'].print\:ltr\:-m-208 {
    margin: -20.8rem
  }

  [dir='ltr'] .print\:ltr\:-m-216,[dir='ltr'].print\:ltr\:-m-216 {
    margin: -21.6rem
  }

  [dir='ltr'] .print\:ltr\:-m-224,[dir='ltr'].print\:ltr\:-m-224 {
    margin: -22.4rem
  }

  [dir='ltr'] .print\:ltr\:-m-256,[dir='ltr'].print\:ltr\:-m-256 {
    margin: -25.6rem
  }

  [dir='ltr'] .print\:ltr\:-m-288,[dir='ltr'].print\:ltr\:-m-288 {
    margin: -28.8rem
  }

  [dir='ltr'] .print\:ltr\:-m-320,[dir='ltr'].print\:ltr\:-m-320 {
    margin: -32rem
  }

  [dir='ltr'] .print\:ltr\:-m-360,[dir='ltr'].print\:ltr\:-m-360 {
    margin: -36rem
  }

  [dir='ltr'] .print\:ltr\:-m-384,[dir='ltr'].print\:ltr\:-m-384 {
    margin: -38.4rem
  }

  [dir='ltr'] .print\:ltr\:-m-400,[dir='ltr'].print\:ltr\:-m-400 {
    margin: -40rem
  }

  [dir='ltr'] .print\:ltr\:-m-512,[dir='ltr'].print\:ltr\:-m-512 {
    margin: -51.2rem
  }

  [dir='ltr'] .print\:ltr\:-m-640,[dir='ltr'].print\:ltr\:-m-640 {
    margin: -64rem
  }

  [dir='ltr'] .print\:ltr\:-m-xs,[dir='ltr'].print\:ltr\:-m-xs {
    margin: -32rem
  }

  [dir='ltr'] .print\:ltr\:-m-sm,[dir='ltr'].print\:ltr\:-m-sm {
    margin: -48rem
  }

  [dir='ltr'] .print\:ltr\:-m-md,[dir='ltr'].print\:ltr\:-m-md {
    margin: -64rem
  }

  [dir='ltr'] .print\:ltr\:-m-lg,[dir='ltr'].print\:ltr\:-m-lg {
    margin: -80rem
  }

  [dir='ltr'] .print\:ltr\:-m-xl,[dir='ltr'].print\:ltr\:-m-xl {
    margin: -96rem
  }

  [dir='ltr'] .print\:ltr\:-m-2xl,[dir='ltr'].print\:ltr\:-m-2xl {
    margin: -112rem
  }

  [dir='ltr'] .print\:ltr\:-m-3xl,[dir='ltr'].print\:ltr\:-m-3xl {
    margin: -128rem
  }

  [dir='ltr'] .print\:ltr\:-m-4xl,[dir='ltr'].print\:ltr\:-m-4xl {
    margin: -144rem
  }

  [dir='ltr'] .print\:ltr\:-m-5xl,[dir='ltr'].print\:ltr\:-m-5xl {
    margin: -160rem
  }

  [dir='ltr'] .print\:ltr\:-m-px,[dir='ltr'].print\:ltr\:-m-px {
    margin: -1px
  }

  [dir='ltr'] .print\:ltr\:-m-0\.5,[dir='ltr'].print\:ltr\:-m-0\.5 {
    margin: -0.05rem
  }

  [dir='ltr'] .print\:ltr\:-m-1\.5,[dir='ltr'].print\:ltr\:-m-1\.5 {
    margin: -0.15rem
  }

  [dir='ltr'] .print\:ltr\:-m-2\.5,[dir='ltr'].print\:ltr\:-m-2\.5 {
    margin: -0.25rem
  }

  [dir='ltr'] .print\:ltr\:-m-3\.5,[dir='ltr'].print\:ltr\:-m-3\.5 {
    margin: -0.35rem
  }

  [dir='ltr'] .print\:ltr\:my-0,[dir='ltr'].print\:ltr\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  [dir='ltr'] .print\:ltr\:mx-0,[dir='ltr'].print\:ltr\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  [dir='ltr'] .print\:ltr\:my-1,[dir='ltr'].print\:ltr\:my-1 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:mx-1,[dir='ltr'].print\:ltr\:mx-1 {
    margin-left: 0.1rem;
    margin-right: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:my-2,[dir='ltr'].print\:ltr\:my-2 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:mx-2,[dir='ltr'].print\:ltr\:mx-2 {
    margin-left: 0.2rem;
    margin-right: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:my-3,[dir='ltr'].print\:ltr\:my-3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:mx-3,[dir='ltr'].print\:ltr\:mx-3 {
    margin-left: 0.3rem;
    margin-right: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:my-4,[dir='ltr'].print\:ltr\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:mx-4,[dir='ltr'].print\:ltr\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:my-5,[dir='ltr'].print\:ltr\:my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:mx-5,[dir='ltr'].print\:ltr\:mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:my-6,[dir='ltr'].print\:ltr\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:mx-6,[dir='ltr'].print\:ltr\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:my-7,[dir='ltr'].print\:ltr\:my-7 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:mx-7,[dir='ltr'].print\:ltr\:mx-7 {
    margin-left: 0.7rem;
    margin-right: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:my-8,[dir='ltr'].print\:ltr\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:mx-8,[dir='ltr'].print\:ltr\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:my-9,[dir='ltr'].print\:ltr\:my-9 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:mx-9,[dir='ltr'].print\:ltr\:mx-9 {
    margin-left: 0.9rem;
    margin-right: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:my-10,[dir='ltr'].print\:ltr\:my-10 {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:mx-10,[dir='ltr'].print\:ltr\:mx-10 {
    margin-left: 1.0rem;
    margin-right: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:my-12,[dir='ltr'].print\:ltr\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:mx-12,[dir='ltr'].print\:ltr\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:my-14,[dir='ltr'].print\:ltr\:my-14 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:mx-14,[dir='ltr'].print\:ltr\:mx-14 {
    margin-left: 1.4rem;
    margin-right: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:my-16,[dir='ltr'].print\:ltr\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:mx-16,[dir='ltr'].print\:ltr\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:my-20,[dir='ltr'].print\:ltr\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  [dir='ltr'] .print\:ltr\:mx-20,[dir='ltr'].print\:ltr\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  [dir='ltr'] .print\:ltr\:my-24,[dir='ltr'].print\:ltr\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:mx-24,[dir='ltr'].print\:ltr\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:my-28,[dir='ltr'].print\:ltr\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:mx-28,[dir='ltr'].print\:ltr\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:my-32,[dir='ltr'].print\:ltr\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:mx-32,[dir='ltr'].print\:ltr\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:my-36,[dir='ltr'].print\:ltr\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:mx-36,[dir='ltr'].print\:ltr\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:my-40,[dir='ltr'].print\:ltr\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  [dir='ltr'] .print\:ltr\:mx-40,[dir='ltr'].print\:ltr\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  [dir='ltr'] .print\:ltr\:my-44,[dir='ltr'].print\:ltr\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:mx-44,[dir='ltr'].print\:ltr\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:my-48,[dir='ltr'].print\:ltr\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:mx-48,[dir='ltr'].print\:ltr\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:my-52,[dir='ltr'].print\:ltr\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:mx-52,[dir='ltr'].print\:ltr\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:my-56,[dir='ltr'].print\:ltr\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:mx-56,[dir='ltr'].print\:ltr\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:my-60,[dir='ltr'].print\:ltr\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  [dir='ltr'] .print\:ltr\:mx-60,[dir='ltr'].print\:ltr\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  [dir='ltr'] .print\:ltr\:my-64,[dir='ltr'].print\:ltr\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:mx-64,[dir='ltr'].print\:ltr\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:my-68,[dir='ltr'].print\:ltr\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:mx-68,[dir='ltr'].print\:ltr\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:my-72,[dir='ltr'].print\:ltr\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:mx-72,[dir='ltr'].print\:ltr\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:my-76,[dir='ltr'].print\:ltr\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:mx-76,[dir='ltr'].print\:ltr\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:my-80,[dir='ltr'].print\:ltr\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  [dir='ltr'] .print\:ltr\:mx-80,[dir='ltr'].print\:ltr\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  [dir='ltr'] .print\:ltr\:my-84,[dir='ltr'].print\:ltr\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:mx-84,[dir='ltr'].print\:ltr\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:my-88,[dir='ltr'].print\:ltr\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:mx-88,[dir='ltr'].print\:ltr\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:my-92,[dir='ltr'].print\:ltr\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:mx-92,[dir='ltr'].print\:ltr\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:my-96,[dir='ltr'].print\:ltr\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:mx-96,[dir='ltr'].print\:ltr\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:my-128,[dir='ltr'].print\:ltr\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:mx-128,[dir='ltr'].print\:ltr\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:my-136,[dir='ltr'].print\:ltr\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:mx-136,[dir='ltr'].print\:ltr\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:my-160,[dir='ltr'].print\:ltr\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  [dir='ltr'] .print\:ltr\:mx-160,[dir='ltr'].print\:ltr\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  [dir='ltr'] .print\:ltr\:my-192,[dir='ltr'].print\:ltr\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:mx-192,[dir='ltr'].print\:ltr\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:my-200,[dir='ltr'].print\:ltr\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  [dir='ltr'] .print\:ltr\:mx-200,[dir='ltr'].print\:ltr\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  [dir='ltr'] .print\:ltr\:my-208,[dir='ltr'].print\:ltr\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:mx-208,[dir='ltr'].print\:ltr\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:my-216,[dir='ltr'].print\:ltr\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:mx-216,[dir='ltr'].print\:ltr\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:my-224,[dir='ltr'].print\:ltr\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:mx-224,[dir='ltr'].print\:ltr\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:my-256,[dir='ltr'].print\:ltr\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:mx-256,[dir='ltr'].print\:ltr\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:my-288,[dir='ltr'].print\:ltr\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:mx-288,[dir='ltr'].print\:ltr\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:my-320,[dir='ltr'].print\:ltr\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='ltr'] .print\:ltr\:mx-320,[dir='ltr'].print\:ltr\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='ltr'] .print\:ltr\:my-360,[dir='ltr'].print\:ltr\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  [dir='ltr'] .print\:ltr\:mx-360,[dir='ltr'].print\:ltr\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  [dir='ltr'] .print\:ltr\:my-384,[dir='ltr'].print\:ltr\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:mx-384,[dir='ltr'].print\:ltr\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:my-400,[dir='ltr'].print\:ltr\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  [dir='ltr'] .print\:ltr\:mx-400,[dir='ltr'].print\:ltr\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  [dir='ltr'] .print\:ltr\:my-512,[dir='ltr'].print\:ltr\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:mx-512,[dir='ltr'].print\:ltr\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:my-640,[dir='ltr'].print\:ltr\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='ltr'] .print\:ltr\:mx-640,[dir='ltr'].print\:ltr\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='ltr'] .print\:ltr\:my-auto,[dir='ltr'].print\:ltr\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  [dir='ltr'] .print\:ltr\:mx-auto,[dir='ltr'].print\:ltr\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  [dir='ltr'] .print\:ltr\:my-xs,[dir='ltr'].print\:ltr\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='ltr'] .print\:ltr\:mx-xs,[dir='ltr'].print\:ltr\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='ltr'] .print\:ltr\:my-sm,[dir='ltr'].print\:ltr\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  [dir='ltr'] .print\:ltr\:mx-sm,[dir='ltr'].print\:ltr\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  [dir='ltr'] .print\:ltr\:my-md,[dir='ltr'].print\:ltr\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='ltr'] .print\:ltr\:mx-md,[dir='ltr'].print\:ltr\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='ltr'] .print\:ltr\:my-lg,[dir='ltr'].print\:ltr\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  [dir='ltr'] .print\:ltr\:mx-lg,[dir='ltr'].print\:ltr\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  [dir='ltr'] .print\:ltr\:my-xl,[dir='ltr'].print\:ltr\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  [dir='ltr'] .print\:ltr\:mx-xl,[dir='ltr'].print\:ltr\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  [dir='ltr'] .print\:ltr\:my-2xl,[dir='ltr'].print\:ltr\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  [dir='ltr'] .print\:ltr\:mx-2xl,[dir='ltr'].print\:ltr\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  [dir='ltr'] .print\:ltr\:my-3xl,[dir='ltr'].print\:ltr\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  [dir='ltr'] .print\:ltr\:mx-3xl,[dir='ltr'].print\:ltr\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  [dir='ltr'] .print\:ltr\:my-4xl,[dir='ltr'].print\:ltr\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  [dir='ltr'] .print\:ltr\:mx-4xl,[dir='ltr'].print\:ltr\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  [dir='ltr'] .print\:ltr\:my-5xl,[dir='ltr'].print\:ltr\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  [dir='ltr'] .print\:ltr\:mx-5xl,[dir='ltr'].print\:ltr\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  [dir='ltr'] .print\:ltr\:my-px,[dir='ltr'].print\:ltr\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  [dir='ltr'] .print\:ltr\:mx-px,[dir='ltr'].print\:ltr\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  [dir='ltr'] .print\:ltr\:my-0\.5,[dir='ltr'].print\:ltr\:my-0\.5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:mx-0\.5,[dir='ltr'].print\:ltr\:mx-0\.5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:my-1\.5,[dir='ltr'].print\:ltr\:my-1\.5 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:mx-1\.5,[dir='ltr'].print\:ltr\:mx-1\.5 {
    margin-left: 0.15rem;
    margin-right: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:my-2\.5,[dir='ltr'].print\:ltr\:my-2\.5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:mx-2\.5,[dir='ltr'].print\:ltr\:mx-2\.5 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:my-3\.5,[dir='ltr'].print\:ltr\:my-3\.5 {
    margin-top: 0.35rem;
    margin-bottom: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:mx-3\.5,[dir='ltr'].print\:ltr\:mx-3\.5 {
    margin-left: 0.35rem;
    margin-right: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:-my-1,[dir='ltr'].print\:ltr\:-my-1 {
    margin-top: -0.1rem;
    margin-bottom: -0.1rem
  }

  [dir='ltr'] .print\:ltr\:-mx-1,[dir='ltr'].print\:ltr\:-mx-1 {
    margin-left: -0.1rem;
    margin-right: -0.1rem
  }

  [dir='ltr'] .print\:ltr\:-my-2,[dir='ltr'].print\:ltr\:-my-2 {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem
  }

  [dir='ltr'] .print\:ltr\:-mx-2,[dir='ltr'].print\:ltr\:-mx-2 {
    margin-left: -0.2rem;
    margin-right: -0.2rem
  }

  [dir='ltr'] .print\:ltr\:-my-3,[dir='ltr'].print\:ltr\:-my-3 {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem
  }

  [dir='ltr'] .print\:ltr\:-mx-3,[dir='ltr'].print\:ltr\:-mx-3 {
    margin-left: -0.3rem;
    margin-right: -0.3rem
  }

  [dir='ltr'] .print\:ltr\:-my-4,[dir='ltr'].print\:ltr\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  [dir='ltr'] .print\:ltr\:-mx-4,[dir='ltr'].print\:ltr\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  [dir='ltr'] .print\:ltr\:-my-5,[dir='ltr'].print\:ltr\:-my-5 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  [dir='ltr'] .print\:ltr\:-mx-5,[dir='ltr'].print\:ltr\:-mx-5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  [dir='ltr'] .print\:ltr\:-my-6,[dir='ltr'].print\:ltr\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  [dir='ltr'] .print\:ltr\:-mx-6,[dir='ltr'].print\:ltr\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  [dir='ltr'] .print\:ltr\:-my-7,[dir='ltr'].print\:ltr\:-my-7 {
    margin-top: -0.7rem;
    margin-bottom: -0.7rem
  }

  [dir='ltr'] .print\:ltr\:-mx-7,[dir='ltr'].print\:ltr\:-mx-7 {
    margin-left: -0.7rem;
    margin-right: -0.7rem
  }

  [dir='ltr'] .print\:ltr\:-my-8,[dir='ltr'].print\:ltr\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  [dir='ltr'] .print\:ltr\:-mx-8,[dir='ltr'].print\:ltr\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  [dir='ltr'] .print\:ltr\:-my-9,[dir='ltr'].print\:ltr\:-my-9 {
    margin-top: -0.9rem;
    margin-bottom: -0.9rem
  }

  [dir='ltr'] .print\:ltr\:-mx-9,[dir='ltr'].print\:ltr\:-mx-9 {
    margin-left: -0.9rem;
    margin-right: -0.9rem
  }

  [dir='ltr'] .print\:ltr\:-my-10,[dir='ltr'].print\:ltr\:-my-10 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  [dir='ltr'] .print\:ltr\:-mx-10,[dir='ltr'].print\:ltr\:-mx-10 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  [dir='ltr'] .print\:ltr\:-my-12,[dir='ltr'].print\:ltr\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  [dir='ltr'] .print\:ltr\:-mx-12,[dir='ltr'].print\:ltr\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  [dir='ltr'] .print\:ltr\:-my-14,[dir='ltr'].print\:ltr\:-my-14 {
    margin-top: -1.4rem;
    margin-bottom: -1.4rem
  }

  [dir='ltr'] .print\:ltr\:-mx-14,[dir='ltr'].print\:ltr\:-mx-14 {
    margin-left: -1.4rem;
    margin-right: -1.4rem
  }

  [dir='ltr'] .print\:ltr\:-my-16,[dir='ltr'].print\:ltr\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  [dir='ltr'] .print\:ltr\:-mx-16,[dir='ltr'].print\:ltr\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  [dir='ltr'] .print\:ltr\:-my-20,[dir='ltr'].print\:ltr\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  [dir='ltr'] .print\:ltr\:-mx-20,[dir='ltr'].print\:ltr\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  [dir='ltr'] .print\:ltr\:-my-24,[dir='ltr'].print\:ltr\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  [dir='ltr'] .print\:ltr\:-mx-24,[dir='ltr'].print\:ltr\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  [dir='ltr'] .print\:ltr\:-my-28,[dir='ltr'].print\:ltr\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  [dir='ltr'] .print\:ltr\:-mx-28,[dir='ltr'].print\:ltr\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  [dir='ltr'] .print\:ltr\:-my-32,[dir='ltr'].print\:ltr\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  [dir='ltr'] .print\:ltr\:-mx-32,[dir='ltr'].print\:ltr\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  [dir='ltr'] .print\:ltr\:-my-36,[dir='ltr'].print\:ltr\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  [dir='ltr'] .print\:ltr\:-mx-36,[dir='ltr'].print\:ltr\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  [dir='ltr'] .print\:ltr\:-my-40,[dir='ltr'].print\:ltr\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  [dir='ltr'] .print\:ltr\:-mx-40,[dir='ltr'].print\:ltr\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  [dir='ltr'] .print\:ltr\:-my-44,[dir='ltr'].print\:ltr\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  [dir='ltr'] .print\:ltr\:-mx-44,[dir='ltr'].print\:ltr\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  [dir='ltr'] .print\:ltr\:-my-48,[dir='ltr'].print\:ltr\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  [dir='ltr'] .print\:ltr\:-mx-48,[dir='ltr'].print\:ltr\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  [dir='ltr'] .print\:ltr\:-my-52,[dir='ltr'].print\:ltr\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  [dir='ltr'] .print\:ltr\:-mx-52,[dir='ltr'].print\:ltr\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  [dir='ltr'] .print\:ltr\:-my-56,[dir='ltr'].print\:ltr\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  [dir='ltr'] .print\:ltr\:-mx-56,[dir='ltr'].print\:ltr\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  [dir='ltr'] .print\:ltr\:-my-60,[dir='ltr'].print\:ltr\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  [dir='ltr'] .print\:ltr\:-mx-60,[dir='ltr'].print\:ltr\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  [dir='ltr'] .print\:ltr\:-my-64,[dir='ltr'].print\:ltr\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  [dir='ltr'] .print\:ltr\:-mx-64,[dir='ltr'].print\:ltr\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  [dir='ltr'] .print\:ltr\:-my-68,[dir='ltr'].print\:ltr\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  [dir='ltr'] .print\:ltr\:-mx-68,[dir='ltr'].print\:ltr\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  [dir='ltr'] .print\:ltr\:-my-72,[dir='ltr'].print\:ltr\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  [dir='ltr'] .print\:ltr\:-mx-72,[dir='ltr'].print\:ltr\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  [dir='ltr'] .print\:ltr\:-my-76,[dir='ltr'].print\:ltr\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  [dir='ltr'] .print\:ltr\:-mx-76,[dir='ltr'].print\:ltr\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  [dir='ltr'] .print\:ltr\:-my-80,[dir='ltr'].print\:ltr\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  [dir='ltr'] .print\:ltr\:-mx-80,[dir='ltr'].print\:ltr\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  [dir='ltr'] .print\:ltr\:-my-84,[dir='ltr'].print\:ltr\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  [dir='ltr'] .print\:ltr\:-mx-84,[dir='ltr'].print\:ltr\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  [dir='ltr'] .print\:ltr\:-my-88,[dir='ltr'].print\:ltr\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  [dir='ltr'] .print\:ltr\:-mx-88,[dir='ltr'].print\:ltr\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  [dir='ltr'] .print\:ltr\:-my-92,[dir='ltr'].print\:ltr\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  [dir='ltr'] .print\:ltr\:-mx-92,[dir='ltr'].print\:ltr\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  [dir='ltr'] .print\:ltr\:-my-96,[dir='ltr'].print\:ltr\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  [dir='ltr'] .print\:ltr\:-mx-96,[dir='ltr'].print\:ltr\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  [dir='ltr'] .print\:ltr\:-my-128,[dir='ltr'].print\:ltr\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  [dir='ltr'] .print\:ltr\:-mx-128,[dir='ltr'].print\:ltr\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  [dir='ltr'] .print\:ltr\:-my-136,[dir='ltr'].print\:ltr\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  [dir='ltr'] .print\:ltr\:-mx-136,[dir='ltr'].print\:ltr\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  [dir='ltr'] .print\:ltr\:-my-160,[dir='ltr'].print\:ltr\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  [dir='ltr'] .print\:ltr\:-mx-160,[dir='ltr'].print\:ltr\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  [dir='ltr'] .print\:ltr\:-my-192,[dir='ltr'].print\:ltr\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  [dir='ltr'] .print\:ltr\:-mx-192,[dir='ltr'].print\:ltr\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  [dir='ltr'] .print\:ltr\:-my-200,[dir='ltr'].print\:ltr\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  [dir='ltr'] .print\:ltr\:-mx-200,[dir='ltr'].print\:ltr\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  [dir='ltr'] .print\:ltr\:-my-208,[dir='ltr'].print\:ltr\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  [dir='ltr'] .print\:ltr\:-mx-208,[dir='ltr'].print\:ltr\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  [dir='ltr'] .print\:ltr\:-my-216,[dir='ltr'].print\:ltr\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  [dir='ltr'] .print\:ltr\:-mx-216,[dir='ltr'].print\:ltr\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  [dir='ltr'] .print\:ltr\:-my-224,[dir='ltr'].print\:ltr\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  [dir='ltr'] .print\:ltr\:-mx-224,[dir='ltr'].print\:ltr\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  [dir='ltr'] .print\:ltr\:-my-256,[dir='ltr'].print\:ltr\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  [dir='ltr'] .print\:ltr\:-mx-256,[dir='ltr'].print\:ltr\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  [dir='ltr'] .print\:ltr\:-my-288,[dir='ltr'].print\:ltr\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  [dir='ltr'] .print\:ltr\:-mx-288,[dir='ltr'].print\:ltr\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  [dir='ltr'] .print\:ltr\:-my-320,[dir='ltr'].print\:ltr\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='ltr'] .print\:ltr\:-mx-320,[dir='ltr'].print\:ltr\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='ltr'] .print\:ltr\:-my-360,[dir='ltr'].print\:ltr\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  [dir='ltr'] .print\:ltr\:-mx-360,[dir='ltr'].print\:ltr\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  [dir='ltr'] .print\:ltr\:-my-384,[dir='ltr'].print\:ltr\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  [dir='ltr'] .print\:ltr\:-mx-384,[dir='ltr'].print\:ltr\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  [dir='ltr'] .print\:ltr\:-my-400,[dir='ltr'].print\:ltr\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  [dir='ltr'] .print\:ltr\:-mx-400,[dir='ltr'].print\:ltr\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  [dir='ltr'] .print\:ltr\:-my-512,[dir='ltr'].print\:ltr\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  [dir='ltr'] .print\:ltr\:-mx-512,[dir='ltr'].print\:ltr\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  [dir='ltr'] .print\:ltr\:-my-640,[dir='ltr'].print\:ltr\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='ltr'] .print\:ltr\:-mx-640,[dir='ltr'].print\:ltr\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='ltr'] .print\:ltr\:-my-xs,[dir='ltr'].print\:ltr\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='ltr'] .print\:ltr\:-mx-xs,[dir='ltr'].print\:ltr\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='ltr'] .print\:ltr\:-my-sm,[dir='ltr'].print\:ltr\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  [dir='ltr'] .print\:ltr\:-mx-sm,[dir='ltr'].print\:ltr\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  [dir='ltr'] .print\:ltr\:-my-md,[dir='ltr'].print\:ltr\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='ltr'] .print\:ltr\:-mx-md,[dir='ltr'].print\:ltr\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='ltr'] .print\:ltr\:-my-lg,[dir='ltr'].print\:ltr\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  [dir='ltr'] .print\:ltr\:-mx-lg,[dir='ltr'].print\:ltr\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  [dir='ltr'] .print\:ltr\:-my-xl,[dir='ltr'].print\:ltr\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  [dir='ltr'] .print\:ltr\:-mx-xl,[dir='ltr'].print\:ltr\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  [dir='ltr'] .print\:ltr\:-my-2xl,[dir='ltr'].print\:ltr\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  [dir='ltr'] .print\:ltr\:-mx-2xl,[dir='ltr'].print\:ltr\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  [dir='ltr'] .print\:ltr\:-my-3xl,[dir='ltr'].print\:ltr\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  [dir='ltr'] .print\:ltr\:-mx-3xl,[dir='ltr'].print\:ltr\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  [dir='ltr'] .print\:ltr\:-my-4xl,[dir='ltr'].print\:ltr\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  [dir='ltr'] .print\:ltr\:-mx-4xl,[dir='ltr'].print\:ltr\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  [dir='ltr'] .print\:ltr\:-my-5xl,[dir='ltr'].print\:ltr\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  [dir='ltr'] .print\:ltr\:-mx-5xl,[dir='ltr'].print\:ltr\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  [dir='ltr'] .print\:ltr\:-my-px,[dir='ltr'].print\:ltr\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  [dir='ltr'] .print\:ltr\:-mx-px,[dir='ltr'].print\:ltr\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  [dir='ltr'] .print\:ltr\:-my-0\.5,[dir='ltr'].print\:ltr\:-my-0\.5 {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem
  }

  [dir='ltr'] .print\:ltr\:-mx-0\.5,[dir='ltr'].print\:ltr\:-mx-0\.5 {
    margin-left: -0.05rem;
    margin-right: -0.05rem
  }

  [dir='ltr'] .print\:ltr\:-my-1\.5,[dir='ltr'].print\:ltr\:-my-1\.5 {
    margin-top: -0.15rem;
    margin-bottom: -0.15rem
  }

  [dir='ltr'] .print\:ltr\:-mx-1\.5,[dir='ltr'].print\:ltr\:-mx-1\.5 {
    margin-left: -0.15rem;
    margin-right: -0.15rem
  }

  [dir='ltr'] .print\:ltr\:-my-2\.5,[dir='ltr'].print\:ltr\:-my-2\.5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  [dir='ltr'] .print\:ltr\:-mx-2\.5,[dir='ltr'].print\:ltr\:-mx-2\.5 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  [dir='ltr'] .print\:ltr\:-my-3\.5,[dir='ltr'].print\:ltr\:-my-3\.5 {
    margin-top: -0.35rem;
    margin-bottom: -0.35rem
  }

  [dir='ltr'] .print\:ltr\:-mx-3\.5,[dir='ltr'].print\:ltr\:-mx-3\.5 {
    margin-left: -0.35rem;
    margin-right: -0.35rem
  }

  [dir='ltr'] .print\:ltr\:mt-0,[dir='ltr'].print\:ltr\:mt-0 {
    margin-top: 0
  }

  [dir='ltr'] .print\:ltr\:mr-0,[dir='ltr'].print\:ltr\:mr-0 {
    margin-right: 0
  }

  [dir='ltr'] .print\:ltr\:mb-0,[dir='ltr'].print\:ltr\:mb-0 {
    margin-bottom: 0
  }

  [dir='ltr'] .print\:ltr\:ml-0,[dir='ltr'].print\:ltr\:ml-0 {
    margin-left: 0
  }

  [dir='ltr'] .print\:ltr\:mt-1,[dir='ltr'].print\:ltr\:mt-1 {
    margin-top: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:mr-1,[dir='ltr'].print\:ltr\:mr-1 {
    margin-right: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:mb-1,[dir='ltr'].print\:ltr\:mb-1 {
    margin-bottom: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:ml-1,[dir='ltr'].print\:ltr\:ml-1 {
    margin-left: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:mt-2,[dir='ltr'].print\:ltr\:mt-2 {
    margin-top: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:mr-2,[dir='ltr'].print\:ltr\:mr-2 {
    margin-right: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:mb-2,[dir='ltr'].print\:ltr\:mb-2 {
    margin-bottom: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:ml-2,[dir='ltr'].print\:ltr\:ml-2 {
    margin-left: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:mt-3,[dir='ltr'].print\:ltr\:mt-3 {
    margin-top: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:mr-3,[dir='ltr'].print\:ltr\:mr-3 {
    margin-right: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:mb-3,[dir='ltr'].print\:ltr\:mb-3 {
    margin-bottom: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:ml-3,[dir='ltr'].print\:ltr\:ml-3 {
    margin-left: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:mt-4,[dir='ltr'].print\:ltr\:mt-4 {
    margin-top: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:mr-4,[dir='ltr'].print\:ltr\:mr-4 {
    margin-right: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:mb-4,[dir='ltr'].print\:ltr\:mb-4 {
    margin-bottom: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:ml-4,[dir='ltr'].print\:ltr\:ml-4 {
    margin-left: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:mt-5,[dir='ltr'].print\:ltr\:mt-5 {
    margin-top: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:mr-5,[dir='ltr'].print\:ltr\:mr-5 {
    margin-right: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:mb-5,[dir='ltr'].print\:ltr\:mb-5 {
    margin-bottom: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:ml-5,[dir='ltr'].print\:ltr\:ml-5 {
    margin-left: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:mt-6,[dir='ltr'].print\:ltr\:mt-6 {
    margin-top: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:mr-6,[dir='ltr'].print\:ltr\:mr-6 {
    margin-right: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:mb-6,[dir='ltr'].print\:ltr\:mb-6 {
    margin-bottom: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:ml-6,[dir='ltr'].print\:ltr\:ml-6 {
    margin-left: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:mt-7,[dir='ltr'].print\:ltr\:mt-7 {
    margin-top: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:mr-7,[dir='ltr'].print\:ltr\:mr-7 {
    margin-right: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:mb-7,[dir='ltr'].print\:ltr\:mb-7 {
    margin-bottom: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:ml-7,[dir='ltr'].print\:ltr\:ml-7 {
    margin-left: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:mt-8,[dir='ltr'].print\:ltr\:mt-8 {
    margin-top: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:mr-8,[dir='ltr'].print\:ltr\:mr-8 {
    margin-right: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:mb-8,[dir='ltr'].print\:ltr\:mb-8 {
    margin-bottom: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:ml-8,[dir='ltr'].print\:ltr\:ml-8 {
    margin-left: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:mt-9,[dir='ltr'].print\:ltr\:mt-9 {
    margin-top: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:mr-9,[dir='ltr'].print\:ltr\:mr-9 {
    margin-right: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:mb-9,[dir='ltr'].print\:ltr\:mb-9 {
    margin-bottom: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:ml-9,[dir='ltr'].print\:ltr\:ml-9 {
    margin-left: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:mt-10,[dir='ltr'].print\:ltr\:mt-10 {
    margin-top: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:mr-10,[dir='ltr'].print\:ltr\:mr-10 {
    margin-right: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:mb-10,[dir='ltr'].print\:ltr\:mb-10 {
    margin-bottom: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:ml-10,[dir='ltr'].print\:ltr\:ml-10 {
    margin-left: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:mt-12,[dir='ltr'].print\:ltr\:mt-12 {
    margin-top: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:mr-12,[dir='ltr'].print\:ltr\:mr-12 {
    margin-right: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:mb-12,[dir='ltr'].print\:ltr\:mb-12 {
    margin-bottom: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:ml-12,[dir='ltr'].print\:ltr\:ml-12 {
    margin-left: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:mt-14,[dir='ltr'].print\:ltr\:mt-14 {
    margin-top: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:mr-14,[dir='ltr'].print\:ltr\:mr-14 {
    margin-right: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:mb-14,[dir='ltr'].print\:ltr\:mb-14 {
    margin-bottom: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:ml-14,[dir='ltr'].print\:ltr\:ml-14 {
    margin-left: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:mt-16,[dir='ltr'].print\:ltr\:mt-16 {
    margin-top: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:mr-16,[dir='ltr'].print\:ltr\:mr-16 {
    margin-right: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:mb-16,[dir='ltr'].print\:ltr\:mb-16 {
    margin-bottom: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:ml-16,[dir='ltr'].print\:ltr\:ml-16 {
    margin-left: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:mt-20,[dir='ltr'].print\:ltr\:mt-20 {
    margin-top: 2rem
  }

  [dir='ltr'] .print\:ltr\:mr-20,[dir='ltr'].print\:ltr\:mr-20 {
    margin-right: 2rem
  }

  [dir='ltr'] .print\:ltr\:mb-20,[dir='ltr'].print\:ltr\:mb-20 {
    margin-bottom: 2rem
  }

  [dir='ltr'] .print\:ltr\:ml-20,[dir='ltr'].print\:ltr\:ml-20 {
    margin-left: 2rem
  }

  [dir='ltr'] .print\:ltr\:mt-24,[dir='ltr'].print\:ltr\:mt-24 {
    margin-top: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:mr-24,[dir='ltr'].print\:ltr\:mr-24 {
    margin-right: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:mb-24,[dir='ltr'].print\:ltr\:mb-24 {
    margin-bottom: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:ml-24,[dir='ltr'].print\:ltr\:ml-24 {
    margin-left: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:mt-28,[dir='ltr'].print\:ltr\:mt-28 {
    margin-top: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:mr-28,[dir='ltr'].print\:ltr\:mr-28 {
    margin-right: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:mb-28,[dir='ltr'].print\:ltr\:mb-28 {
    margin-bottom: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:ml-28,[dir='ltr'].print\:ltr\:ml-28 {
    margin-left: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:mt-32,[dir='ltr'].print\:ltr\:mt-32 {
    margin-top: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:mr-32,[dir='ltr'].print\:ltr\:mr-32 {
    margin-right: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:mb-32,[dir='ltr'].print\:ltr\:mb-32 {
    margin-bottom: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:ml-32,[dir='ltr'].print\:ltr\:ml-32 {
    margin-left: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:mt-36,[dir='ltr'].print\:ltr\:mt-36 {
    margin-top: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:mr-36,[dir='ltr'].print\:ltr\:mr-36 {
    margin-right: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:mb-36,[dir='ltr'].print\:ltr\:mb-36 {
    margin-bottom: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:ml-36,[dir='ltr'].print\:ltr\:ml-36 {
    margin-left: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:mt-40,[dir='ltr'].print\:ltr\:mt-40 {
    margin-top: 4rem
  }

  [dir='ltr'] .print\:ltr\:mr-40,[dir='ltr'].print\:ltr\:mr-40 {
    margin-right: 4rem
  }

  [dir='ltr'] .print\:ltr\:mb-40,[dir='ltr'].print\:ltr\:mb-40 {
    margin-bottom: 4rem
  }

  [dir='ltr'] .print\:ltr\:ml-40,[dir='ltr'].print\:ltr\:ml-40 {
    margin-left: 4rem
  }

  [dir='ltr'] .print\:ltr\:mt-44,[dir='ltr'].print\:ltr\:mt-44 {
    margin-top: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:mr-44,[dir='ltr'].print\:ltr\:mr-44 {
    margin-right: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:mb-44,[dir='ltr'].print\:ltr\:mb-44 {
    margin-bottom: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:ml-44,[dir='ltr'].print\:ltr\:ml-44 {
    margin-left: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:mt-48,[dir='ltr'].print\:ltr\:mt-48 {
    margin-top: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:mr-48,[dir='ltr'].print\:ltr\:mr-48 {
    margin-right: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:mb-48,[dir='ltr'].print\:ltr\:mb-48 {
    margin-bottom: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:ml-48,[dir='ltr'].print\:ltr\:ml-48 {
    margin-left: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:mt-52,[dir='ltr'].print\:ltr\:mt-52 {
    margin-top: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:mr-52,[dir='ltr'].print\:ltr\:mr-52 {
    margin-right: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:mb-52,[dir='ltr'].print\:ltr\:mb-52 {
    margin-bottom: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:ml-52,[dir='ltr'].print\:ltr\:ml-52 {
    margin-left: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:mt-56,[dir='ltr'].print\:ltr\:mt-56 {
    margin-top: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:mr-56,[dir='ltr'].print\:ltr\:mr-56 {
    margin-right: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:mb-56,[dir='ltr'].print\:ltr\:mb-56 {
    margin-bottom: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:ml-56,[dir='ltr'].print\:ltr\:ml-56 {
    margin-left: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:mt-60,[dir='ltr'].print\:ltr\:mt-60 {
    margin-top: 6rem
  }

  [dir='ltr'] .print\:ltr\:mr-60,[dir='ltr'].print\:ltr\:mr-60 {
    margin-right: 6rem
  }

  [dir='ltr'] .print\:ltr\:mb-60,[dir='ltr'].print\:ltr\:mb-60 {
    margin-bottom: 6rem
  }

  [dir='ltr'] .print\:ltr\:ml-60,[dir='ltr'].print\:ltr\:ml-60 {
    margin-left: 6rem
  }

  [dir='ltr'] .print\:ltr\:mt-64,[dir='ltr'].print\:ltr\:mt-64 {
    margin-top: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:mr-64,[dir='ltr'].print\:ltr\:mr-64 {
    margin-right: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:mb-64,[dir='ltr'].print\:ltr\:mb-64 {
    margin-bottom: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:ml-64,[dir='ltr'].print\:ltr\:ml-64 {
    margin-left: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:mt-68,[dir='ltr'].print\:ltr\:mt-68 {
    margin-top: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:mr-68,[dir='ltr'].print\:ltr\:mr-68 {
    margin-right: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:mb-68,[dir='ltr'].print\:ltr\:mb-68 {
    margin-bottom: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:ml-68,[dir='ltr'].print\:ltr\:ml-68 {
    margin-left: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:mt-72,[dir='ltr'].print\:ltr\:mt-72 {
    margin-top: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:mr-72,[dir='ltr'].print\:ltr\:mr-72 {
    margin-right: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:mb-72,[dir='ltr'].print\:ltr\:mb-72 {
    margin-bottom: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:ml-72,[dir='ltr'].print\:ltr\:ml-72 {
    margin-left: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:mt-76,[dir='ltr'].print\:ltr\:mt-76 {
    margin-top: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:mr-76,[dir='ltr'].print\:ltr\:mr-76 {
    margin-right: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:mb-76,[dir='ltr'].print\:ltr\:mb-76 {
    margin-bottom: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:ml-76,[dir='ltr'].print\:ltr\:ml-76 {
    margin-left: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:mt-80,[dir='ltr'].print\:ltr\:mt-80 {
    margin-top: 8rem
  }

  [dir='ltr'] .print\:ltr\:mr-80,[dir='ltr'].print\:ltr\:mr-80 {
    margin-right: 8rem
  }

  [dir='ltr'] .print\:ltr\:mb-80,[dir='ltr'].print\:ltr\:mb-80 {
    margin-bottom: 8rem
  }

  [dir='ltr'] .print\:ltr\:ml-80,[dir='ltr'].print\:ltr\:ml-80 {
    margin-left: 8rem
  }

  [dir='ltr'] .print\:ltr\:mt-84,[dir='ltr'].print\:ltr\:mt-84 {
    margin-top: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:mr-84,[dir='ltr'].print\:ltr\:mr-84 {
    margin-right: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:mb-84,[dir='ltr'].print\:ltr\:mb-84 {
    margin-bottom: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:ml-84,[dir='ltr'].print\:ltr\:ml-84 {
    margin-left: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:mt-88,[dir='ltr'].print\:ltr\:mt-88 {
    margin-top: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:mr-88,[dir='ltr'].print\:ltr\:mr-88 {
    margin-right: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:mb-88,[dir='ltr'].print\:ltr\:mb-88 {
    margin-bottom: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:ml-88,[dir='ltr'].print\:ltr\:ml-88 {
    margin-left: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:mt-92,[dir='ltr'].print\:ltr\:mt-92 {
    margin-top: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:mr-92,[dir='ltr'].print\:ltr\:mr-92 {
    margin-right: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:mb-92,[dir='ltr'].print\:ltr\:mb-92 {
    margin-bottom: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:ml-92,[dir='ltr'].print\:ltr\:ml-92 {
    margin-left: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:mt-96,[dir='ltr'].print\:ltr\:mt-96 {
    margin-top: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:mr-96,[dir='ltr'].print\:ltr\:mr-96 {
    margin-right: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:mb-96,[dir='ltr'].print\:ltr\:mb-96 {
    margin-bottom: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:ml-96,[dir='ltr'].print\:ltr\:ml-96 {
    margin-left: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:mt-128,[dir='ltr'].print\:ltr\:mt-128 {
    margin-top: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:mr-128,[dir='ltr'].print\:ltr\:mr-128 {
    margin-right: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:mb-128,[dir='ltr'].print\:ltr\:mb-128 {
    margin-bottom: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:ml-128,[dir='ltr'].print\:ltr\:ml-128 {
    margin-left: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:mt-136,[dir='ltr'].print\:ltr\:mt-136 {
    margin-top: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:mr-136,[dir='ltr'].print\:ltr\:mr-136 {
    margin-right: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:mb-136,[dir='ltr'].print\:ltr\:mb-136 {
    margin-bottom: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:ml-136,[dir='ltr'].print\:ltr\:ml-136 {
    margin-left: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:mt-160,[dir='ltr'].print\:ltr\:mt-160 {
    margin-top: 16rem
  }

  [dir='ltr'] .print\:ltr\:mr-160,[dir='ltr'].print\:ltr\:mr-160 {
    margin-right: 16rem
  }

  [dir='ltr'] .print\:ltr\:mb-160,[dir='ltr'].print\:ltr\:mb-160 {
    margin-bottom: 16rem
  }

  [dir='ltr'] .print\:ltr\:ml-160,[dir='ltr'].print\:ltr\:ml-160 {
    margin-left: 16rem
  }

  [dir='ltr'] .print\:ltr\:mt-192,[dir='ltr'].print\:ltr\:mt-192 {
    margin-top: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:mr-192,[dir='ltr'].print\:ltr\:mr-192 {
    margin-right: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:mb-192,[dir='ltr'].print\:ltr\:mb-192 {
    margin-bottom: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:ml-192,[dir='ltr'].print\:ltr\:ml-192 {
    margin-left: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:mt-200,[dir='ltr'].print\:ltr\:mt-200 {
    margin-top: 20rem
  }

  [dir='ltr'] .print\:ltr\:mr-200,[dir='ltr'].print\:ltr\:mr-200 {
    margin-right: 20rem
  }

  [dir='ltr'] .print\:ltr\:mb-200,[dir='ltr'].print\:ltr\:mb-200 {
    margin-bottom: 20rem
  }

  [dir='ltr'] .print\:ltr\:ml-200,[dir='ltr'].print\:ltr\:ml-200 {
    margin-left: 20rem
  }

  [dir='ltr'] .print\:ltr\:mt-208,[dir='ltr'].print\:ltr\:mt-208 {
    margin-top: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:mr-208,[dir='ltr'].print\:ltr\:mr-208 {
    margin-right: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:mb-208,[dir='ltr'].print\:ltr\:mb-208 {
    margin-bottom: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:ml-208,[dir='ltr'].print\:ltr\:ml-208 {
    margin-left: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:mt-216,[dir='ltr'].print\:ltr\:mt-216 {
    margin-top: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:mr-216,[dir='ltr'].print\:ltr\:mr-216 {
    margin-right: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:mb-216,[dir='ltr'].print\:ltr\:mb-216 {
    margin-bottom: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:ml-216,[dir='ltr'].print\:ltr\:ml-216 {
    margin-left: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:mt-224,[dir='ltr'].print\:ltr\:mt-224 {
    margin-top: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:mr-224,[dir='ltr'].print\:ltr\:mr-224 {
    margin-right: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:mb-224,[dir='ltr'].print\:ltr\:mb-224 {
    margin-bottom: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:ml-224,[dir='ltr'].print\:ltr\:ml-224 {
    margin-left: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:mt-256,[dir='ltr'].print\:ltr\:mt-256 {
    margin-top: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:mr-256,[dir='ltr'].print\:ltr\:mr-256 {
    margin-right: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:mb-256,[dir='ltr'].print\:ltr\:mb-256 {
    margin-bottom: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:ml-256,[dir='ltr'].print\:ltr\:ml-256 {
    margin-left: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:mt-288,[dir='ltr'].print\:ltr\:mt-288 {
    margin-top: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:mr-288,[dir='ltr'].print\:ltr\:mr-288 {
    margin-right: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:mb-288,[dir='ltr'].print\:ltr\:mb-288 {
    margin-bottom: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:ml-288,[dir='ltr'].print\:ltr\:ml-288 {
    margin-left: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:mt-320,[dir='ltr'].print\:ltr\:mt-320 {
    margin-top: 32rem
  }

  [dir='ltr'] .print\:ltr\:mr-320,[dir='ltr'].print\:ltr\:mr-320 {
    margin-right: 32rem
  }

  [dir='ltr'] .print\:ltr\:mb-320,[dir='ltr'].print\:ltr\:mb-320 {
    margin-bottom: 32rem
  }

  [dir='ltr'] .print\:ltr\:ml-320,[dir='ltr'].print\:ltr\:ml-320 {
    margin-left: 32rem
  }

  [dir='ltr'] .print\:ltr\:mt-360,[dir='ltr'].print\:ltr\:mt-360 {
    margin-top: 36rem
  }

  [dir='ltr'] .print\:ltr\:mr-360,[dir='ltr'].print\:ltr\:mr-360 {
    margin-right: 36rem
  }

  [dir='ltr'] .print\:ltr\:mb-360,[dir='ltr'].print\:ltr\:mb-360 {
    margin-bottom: 36rem
  }

  [dir='ltr'] .print\:ltr\:ml-360,[dir='ltr'].print\:ltr\:ml-360 {
    margin-left: 36rem
  }

  [dir='ltr'] .print\:ltr\:mt-384,[dir='ltr'].print\:ltr\:mt-384 {
    margin-top: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:mr-384,[dir='ltr'].print\:ltr\:mr-384 {
    margin-right: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:mb-384,[dir='ltr'].print\:ltr\:mb-384 {
    margin-bottom: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:ml-384,[dir='ltr'].print\:ltr\:ml-384 {
    margin-left: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:mt-400,[dir='ltr'].print\:ltr\:mt-400 {
    margin-top: 40rem
  }

  [dir='ltr'] .print\:ltr\:mr-400,[dir='ltr'].print\:ltr\:mr-400 {
    margin-right: 40rem
  }

  [dir='ltr'] .print\:ltr\:mb-400,[dir='ltr'].print\:ltr\:mb-400 {
    margin-bottom: 40rem
  }

  [dir='ltr'] .print\:ltr\:ml-400,[dir='ltr'].print\:ltr\:ml-400 {
    margin-left: 40rem
  }

  [dir='ltr'] .print\:ltr\:mt-512,[dir='ltr'].print\:ltr\:mt-512 {
    margin-top: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:mr-512,[dir='ltr'].print\:ltr\:mr-512 {
    margin-right: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:mb-512,[dir='ltr'].print\:ltr\:mb-512 {
    margin-bottom: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:ml-512,[dir='ltr'].print\:ltr\:ml-512 {
    margin-left: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:mt-640,[dir='ltr'].print\:ltr\:mt-640 {
    margin-top: 64rem
  }

  [dir='ltr'] .print\:ltr\:mr-640,[dir='ltr'].print\:ltr\:mr-640 {
    margin-right: 64rem
  }

  [dir='ltr'] .print\:ltr\:mb-640,[dir='ltr'].print\:ltr\:mb-640 {
    margin-bottom: 64rem
  }

  [dir='ltr'] .print\:ltr\:ml-640,[dir='ltr'].print\:ltr\:ml-640 {
    margin-left: 64rem
  }

  [dir='ltr'] .print\:ltr\:mt-auto,[dir='ltr'].print\:ltr\:mt-auto {
    margin-top: auto
  }

  [dir='ltr'] .print\:ltr\:mr-auto,[dir='ltr'].print\:ltr\:mr-auto {
    margin-right: auto
  }

  [dir='ltr'] .print\:ltr\:mb-auto,[dir='ltr'].print\:ltr\:mb-auto {
    margin-bottom: auto
  }

  [dir='ltr'] .print\:ltr\:ml-auto,[dir='ltr'].print\:ltr\:ml-auto {
    margin-left: auto
  }

  [dir='ltr'] .print\:ltr\:mt-xs,[dir='ltr'].print\:ltr\:mt-xs {
    margin-top: 32rem
  }

  [dir='ltr'] .print\:ltr\:mr-xs,[dir='ltr'].print\:ltr\:mr-xs {
    margin-right: 32rem
  }

  [dir='ltr'] .print\:ltr\:mb-xs,[dir='ltr'].print\:ltr\:mb-xs {
    margin-bottom: 32rem
  }

  [dir='ltr'] .print\:ltr\:ml-xs,[dir='ltr'].print\:ltr\:ml-xs {
    margin-left: 32rem
  }

  [dir='ltr'] .print\:ltr\:mt-sm,[dir='ltr'].print\:ltr\:mt-sm {
    margin-top: 48rem
  }

  [dir='ltr'] .print\:ltr\:mr-sm,[dir='ltr'].print\:ltr\:mr-sm {
    margin-right: 48rem
  }

  [dir='ltr'] .print\:ltr\:mb-sm,[dir='ltr'].print\:ltr\:mb-sm {
    margin-bottom: 48rem
  }

  [dir='ltr'] .print\:ltr\:ml-sm,[dir='ltr'].print\:ltr\:ml-sm {
    margin-left: 48rem
  }

  [dir='ltr'] .print\:ltr\:mt-md,[dir='ltr'].print\:ltr\:mt-md {
    margin-top: 64rem
  }

  [dir='ltr'] .print\:ltr\:mr-md,[dir='ltr'].print\:ltr\:mr-md {
    margin-right: 64rem
  }

  [dir='ltr'] .print\:ltr\:mb-md,[dir='ltr'].print\:ltr\:mb-md {
    margin-bottom: 64rem
  }

  [dir='ltr'] .print\:ltr\:ml-md,[dir='ltr'].print\:ltr\:ml-md {
    margin-left: 64rem
  }

  [dir='ltr'] .print\:ltr\:mt-lg,[dir='ltr'].print\:ltr\:mt-lg {
    margin-top: 80rem
  }

  [dir='ltr'] .print\:ltr\:mr-lg,[dir='ltr'].print\:ltr\:mr-lg {
    margin-right: 80rem
  }

  [dir='ltr'] .print\:ltr\:mb-lg,[dir='ltr'].print\:ltr\:mb-lg {
    margin-bottom: 80rem
  }

  [dir='ltr'] .print\:ltr\:ml-lg,[dir='ltr'].print\:ltr\:ml-lg {
    margin-left: 80rem
  }

  [dir='ltr'] .print\:ltr\:mt-xl,[dir='ltr'].print\:ltr\:mt-xl {
    margin-top: 96rem
  }

  [dir='ltr'] .print\:ltr\:mr-xl,[dir='ltr'].print\:ltr\:mr-xl {
    margin-right: 96rem
  }

  [dir='ltr'] .print\:ltr\:mb-xl,[dir='ltr'].print\:ltr\:mb-xl {
    margin-bottom: 96rem
  }

  [dir='ltr'] .print\:ltr\:ml-xl,[dir='ltr'].print\:ltr\:ml-xl {
    margin-left: 96rem
  }

  [dir='ltr'] .print\:ltr\:mt-2xl,[dir='ltr'].print\:ltr\:mt-2xl {
    margin-top: 112rem
  }

  [dir='ltr'] .print\:ltr\:mr-2xl,[dir='ltr'].print\:ltr\:mr-2xl {
    margin-right: 112rem
  }

  [dir='ltr'] .print\:ltr\:mb-2xl,[dir='ltr'].print\:ltr\:mb-2xl {
    margin-bottom: 112rem
  }

  [dir='ltr'] .print\:ltr\:ml-2xl,[dir='ltr'].print\:ltr\:ml-2xl {
    margin-left: 112rem
  }

  [dir='ltr'] .print\:ltr\:mt-3xl,[dir='ltr'].print\:ltr\:mt-3xl {
    margin-top: 128rem
  }

  [dir='ltr'] .print\:ltr\:mr-3xl,[dir='ltr'].print\:ltr\:mr-3xl {
    margin-right: 128rem
  }

  [dir='ltr'] .print\:ltr\:mb-3xl,[dir='ltr'].print\:ltr\:mb-3xl {
    margin-bottom: 128rem
  }

  [dir='ltr'] .print\:ltr\:ml-3xl,[dir='ltr'].print\:ltr\:ml-3xl {
    margin-left: 128rem
  }

  [dir='ltr'] .print\:ltr\:mt-4xl,[dir='ltr'].print\:ltr\:mt-4xl {
    margin-top: 144rem
  }

  [dir='ltr'] .print\:ltr\:mr-4xl,[dir='ltr'].print\:ltr\:mr-4xl {
    margin-right: 144rem
  }

  [dir='ltr'] .print\:ltr\:mb-4xl,[dir='ltr'].print\:ltr\:mb-4xl {
    margin-bottom: 144rem
  }

  [dir='ltr'] .print\:ltr\:ml-4xl,[dir='ltr'].print\:ltr\:ml-4xl {
    margin-left: 144rem
  }

  [dir='ltr'] .print\:ltr\:mt-5xl,[dir='ltr'].print\:ltr\:mt-5xl {
    margin-top: 160rem
  }

  [dir='ltr'] .print\:ltr\:mr-5xl,[dir='ltr'].print\:ltr\:mr-5xl {
    margin-right: 160rem
  }

  [dir='ltr'] .print\:ltr\:mb-5xl,[dir='ltr'].print\:ltr\:mb-5xl {
    margin-bottom: 160rem
  }

  [dir='ltr'] .print\:ltr\:ml-5xl,[dir='ltr'].print\:ltr\:ml-5xl {
    margin-left: 160rem
  }

  [dir='ltr'] .print\:ltr\:mt-px,[dir='ltr'].print\:ltr\:mt-px {
    margin-top: 1px
  }

  [dir='ltr'] .print\:ltr\:mr-px,[dir='ltr'].print\:ltr\:mr-px {
    margin-right: 1px
  }

  [dir='ltr'] .print\:ltr\:mb-px,[dir='ltr'].print\:ltr\:mb-px {
    margin-bottom: 1px
  }

  [dir='ltr'] .print\:ltr\:ml-px,[dir='ltr'].print\:ltr\:ml-px {
    margin-left: 1px
  }

  [dir='ltr'] .print\:ltr\:mt-0\.5,[dir='ltr'].print\:ltr\:mt-0\.5 {
    margin-top: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:mr-0\.5,[dir='ltr'].print\:ltr\:mr-0\.5 {
    margin-right: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:mb-0\.5,[dir='ltr'].print\:ltr\:mb-0\.5 {
    margin-bottom: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:ml-0\.5,[dir='ltr'].print\:ltr\:ml-0\.5 {
    margin-left: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:mt-1\.5,[dir='ltr'].print\:ltr\:mt-1\.5 {
    margin-top: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:mr-1\.5,[dir='ltr'].print\:ltr\:mr-1\.5 {
    margin-right: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:mb-1\.5,[dir='ltr'].print\:ltr\:mb-1\.5 {
    margin-bottom: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:ml-1\.5,[dir='ltr'].print\:ltr\:ml-1\.5 {
    margin-left: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:mt-2\.5,[dir='ltr'].print\:ltr\:mt-2\.5 {
    margin-top: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:mr-2\.5,[dir='ltr'].print\:ltr\:mr-2\.5 {
    margin-right: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:mb-2\.5,[dir='ltr'].print\:ltr\:mb-2\.5 {
    margin-bottom: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:ml-2\.5,[dir='ltr'].print\:ltr\:ml-2\.5 {
    margin-left: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:mt-3\.5,[dir='ltr'].print\:ltr\:mt-3\.5 {
    margin-top: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:mr-3\.5,[dir='ltr'].print\:ltr\:mr-3\.5 {
    margin-right: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:mb-3\.5,[dir='ltr'].print\:ltr\:mb-3\.5 {
    margin-bottom: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:ml-3\.5,[dir='ltr'].print\:ltr\:ml-3\.5 {
    margin-left: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:-mt-1,[dir='ltr'].print\:ltr\:-mt-1 {
    margin-top: -0.1rem
  }

  [dir='ltr'] .print\:ltr\:-mr-1,[dir='ltr'].print\:ltr\:-mr-1 {
    margin-right: -0.1rem
  }

  [dir='ltr'] .print\:ltr\:-mb-1,[dir='ltr'].print\:ltr\:-mb-1 {
    margin-bottom: -0.1rem
  }

  [dir='ltr'] .print\:ltr\:-ml-1,[dir='ltr'].print\:ltr\:-ml-1 {
    margin-left: -0.1rem
  }

  [dir='ltr'] .print\:ltr\:-mt-2,[dir='ltr'].print\:ltr\:-mt-2 {
    margin-top: -0.2rem
  }

  [dir='ltr'] .print\:ltr\:-mr-2,[dir='ltr'].print\:ltr\:-mr-2 {
    margin-right: -0.2rem
  }

  [dir='ltr'] .print\:ltr\:-mb-2,[dir='ltr'].print\:ltr\:-mb-2 {
    margin-bottom: -0.2rem
  }

  [dir='ltr'] .print\:ltr\:-ml-2,[dir='ltr'].print\:ltr\:-ml-2 {
    margin-left: -0.2rem
  }

  [dir='ltr'] .print\:ltr\:-mt-3,[dir='ltr'].print\:ltr\:-mt-3 {
    margin-top: -0.3rem
  }

  [dir='ltr'] .print\:ltr\:-mr-3,[dir='ltr'].print\:ltr\:-mr-3 {
    margin-right: -0.3rem
  }

  [dir='ltr'] .print\:ltr\:-mb-3,[dir='ltr'].print\:ltr\:-mb-3 {
    margin-bottom: -0.3rem
  }

  [dir='ltr'] .print\:ltr\:-ml-3,[dir='ltr'].print\:ltr\:-ml-3 {
    margin-left: -0.3rem
  }

  [dir='ltr'] .print\:ltr\:-mt-4,[dir='ltr'].print\:ltr\:-mt-4 {
    margin-top: -0.4rem
  }

  [dir='ltr'] .print\:ltr\:-mr-4,[dir='ltr'].print\:ltr\:-mr-4 {
    margin-right: -0.4rem
  }

  [dir='ltr'] .print\:ltr\:-mb-4,[dir='ltr'].print\:ltr\:-mb-4 {
    margin-bottom: -0.4rem
  }

  [dir='ltr'] .print\:ltr\:-ml-4,[dir='ltr'].print\:ltr\:-ml-4 {
    margin-left: -0.4rem
  }

  [dir='ltr'] .print\:ltr\:-mt-5,[dir='ltr'].print\:ltr\:-mt-5 {
    margin-top: -0.5rem
  }

  [dir='ltr'] .print\:ltr\:-mr-5,[dir='ltr'].print\:ltr\:-mr-5 {
    margin-right: -0.5rem
  }

  [dir='ltr'] .print\:ltr\:-mb-5,[dir='ltr'].print\:ltr\:-mb-5 {
    margin-bottom: -0.5rem
  }

  [dir='ltr'] .print\:ltr\:-ml-5,[dir='ltr'].print\:ltr\:-ml-5 {
    margin-left: -0.5rem
  }

  [dir='ltr'] .print\:ltr\:-mt-6,[dir='ltr'].print\:ltr\:-mt-6 {
    margin-top: -0.6rem
  }

  [dir='ltr'] .print\:ltr\:-mr-6,[dir='ltr'].print\:ltr\:-mr-6 {
    margin-right: -0.6rem
  }

  [dir='ltr'] .print\:ltr\:-mb-6,[dir='ltr'].print\:ltr\:-mb-6 {
    margin-bottom: -0.6rem
  }

  [dir='ltr'] .print\:ltr\:-ml-6,[dir='ltr'].print\:ltr\:-ml-6 {
    margin-left: -0.6rem
  }

  [dir='ltr'] .print\:ltr\:-mt-7,[dir='ltr'].print\:ltr\:-mt-7 {
    margin-top: -0.7rem
  }

  [dir='ltr'] .print\:ltr\:-mr-7,[dir='ltr'].print\:ltr\:-mr-7 {
    margin-right: -0.7rem
  }

  [dir='ltr'] .print\:ltr\:-mb-7,[dir='ltr'].print\:ltr\:-mb-7 {
    margin-bottom: -0.7rem
  }

  [dir='ltr'] .print\:ltr\:-ml-7,[dir='ltr'].print\:ltr\:-ml-7 {
    margin-left: -0.7rem
  }

  [dir='ltr'] .print\:ltr\:-mt-8,[dir='ltr'].print\:ltr\:-mt-8 {
    margin-top: -0.8rem
  }

  [dir='ltr'] .print\:ltr\:-mr-8,[dir='ltr'].print\:ltr\:-mr-8 {
    margin-right: -0.8rem
  }

  [dir='ltr'] .print\:ltr\:-mb-8,[dir='ltr'].print\:ltr\:-mb-8 {
    margin-bottom: -0.8rem
  }

  [dir='ltr'] .print\:ltr\:-ml-8,[dir='ltr'].print\:ltr\:-ml-8 {
    margin-left: -0.8rem
  }

  [dir='ltr'] .print\:ltr\:-mt-9,[dir='ltr'].print\:ltr\:-mt-9 {
    margin-top: -0.9rem
  }

  [dir='ltr'] .print\:ltr\:-mr-9,[dir='ltr'].print\:ltr\:-mr-9 {
    margin-right: -0.9rem
  }

  [dir='ltr'] .print\:ltr\:-mb-9,[dir='ltr'].print\:ltr\:-mb-9 {
    margin-bottom: -0.9rem
  }

  [dir='ltr'] .print\:ltr\:-ml-9,[dir='ltr'].print\:ltr\:-ml-9 {
    margin-left: -0.9rem
  }

  [dir='ltr'] .print\:ltr\:-mt-10,[dir='ltr'].print\:ltr\:-mt-10 {
    margin-top: -1rem
  }

  [dir='ltr'] .print\:ltr\:-mr-10,[dir='ltr'].print\:ltr\:-mr-10 {
    margin-right: -1rem
  }

  [dir='ltr'] .print\:ltr\:-mb-10,[dir='ltr'].print\:ltr\:-mb-10 {
    margin-bottom: -1rem
  }

  [dir='ltr'] .print\:ltr\:-ml-10,[dir='ltr'].print\:ltr\:-ml-10 {
    margin-left: -1rem
  }

  [dir='ltr'] .print\:ltr\:-mt-12,[dir='ltr'].print\:ltr\:-mt-12 {
    margin-top: -1.2rem
  }

  [dir='ltr'] .print\:ltr\:-mr-12,[dir='ltr'].print\:ltr\:-mr-12 {
    margin-right: -1.2rem
  }

  [dir='ltr'] .print\:ltr\:-mb-12,[dir='ltr'].print\:ltr\:-mb-12 {
    margin-bottom: -1.2rem
  }

  [dir='ltr'] .print\:ltr\:-ml-12,[dir='ltr'].print\:ltr\:-ml-12 {
    margin-left: -1.2rem
  }

  [dir='ltr'] .print\:ltr\:-mt-14,[dir='ltr'].print\:ltr\:-mt-14 {
    margin-top: -1.4rem
  }

  [dir='ltr'] .print\:ltr\:-mr-14,[dir='ltr'].print\:ltr\:-mr-14 {
    margin-right: -1.4rem
  }

  [dir='ltr'] .print\:ltr\:-mb-14,[dir='ltr'].print\:ltr\:-mb-14 {
    margin-bottom: -1.4rem
  }

  [dir='ltr'] .print\:ltr\:-ml-14,[dir='ltr'].print\:ltr\:-ml-14 {
    margin-left: -1.4rem
  }

  [dir='ltr'] .print\:ltr\:-mt-16,[dir='ltr'].print\:ltr\:-mt-16 {
    margin-top: -1.6rem
  }

  [dir='ltr'] .print\:ltr\:-mr-16,[dir='ltr'].print\:ltr\:-mr-16 {
    margin-right: -1.6rem
  }

  [dir='ltr'] .print\:ltr\:-mb-16,[dir='ltr'].print\:ltr\:-mb-16 {
    margin-bottom: -1.6rem
  }

  [dir='ltr'] .print\:ltr\:-ml-16,[dir='ltr'].print\:ltr\:-ml-16 {
    margin-left: -1.6rem
  }

  [dir='ltr'] .print\:ltr\:-mt-20,[dir='ltr'].print\:ltr\:-mt-20 {
    margin-top: -2rem
  }

  [dir='ltr'] .print\:ltr\:-mr-20,[dir='ltr'].print\:ltr\:-mr-20 {
    margin-right: -2rem
  }

  [dir='ltr'] .print\:ltr\:-mb-20,[dir='ltr'].print\:ltr\:-mb-20 {
    margin-bottom: -2rem
  }

  [dir='ltr'] .print\:ltr\:-ml-20,[dir='ltr'].print\:ltr\:-ml-20 {
    margin-left: -2rem
  }

  [dir='ltr'] .print\:ltr\:-mt-24,[dir='ltr'].print\:ltr\:-mt-24 {
    margin-top: -2.4rem
  }

  [dir='ltr'] .print\:ltr\:-mr-24,[dir='ltr'].print\:ltr\:-mr-24 {
    margin-right: -2.4rem
  }

  [dir='ltr'] .print\:ltr\:-mb-24,[dir='ltr'].print\:ltr\:-mb-24 {
    margin-bottom: -2.4rem
  }

  [dir='ltr'] .print\:ltr\:-ml-24,[dir='ltr'].print\:ltr\:-ml-24 {
    margin-left: -2.4rem
  }

  [dir='ltr'] .print\:ltr\:-mt-28,[dir='ltr'].print\:ltr\:-mt-28 {
    margin-top: -2.8rem
  }

  [dir='ltr'] .print\:ltr\:-mr-28,[dir='ltr'].print\:ltr\:-mr-28 {
    margin-right: -2.8rem
  }

  [dir='ltr'] .print\:ltr\:-mb-28,[dir='ltr'].print\:ltr\:-mb-28 {
    margin-bottom: -2.8rem
  }

  [dir='ltr'] .print\:ltr\:-ml-28,[dir='ltr'].print\:ltr\:-ml-28 {
    margin-left: -2.8rem
  }

  [dir='ltr'] .print\:ltr\:-mt-32,[dir='ltr'].print\:ltr\:-mt-32 {
    margin-top: -3.2rem
  }

  [dir='ltr'] .print\:ltr\:-mr-32,[dir='ltr'].print\:ltr\:-mr-32 {
    margin-right: -3.2rem
  }

  [dir='ltr'] .print\:ltr\:-mb-32,[dir='ltr'].print\:ltr\:-mb-32 {
    margin-bottom: -3.2rem
  }

  [dir='ltr'] .print\:ltr\:-ml-32,[dir='ltr'].print\:ltr\:-ml-32 {
    margin-left: -3.2rem
  }

  [dir='ltr'] .print\:ltr\:-mt-36,[dir='ltr'].print\:ltr\:-mt-36 {
    margin-top: -3.6rem
  }

  [dir='ltr'] .print\:ltr\:-mr-36,[dir='ltr'].print\:ltr\:-mr-36 {
    margin-right: -3.6rem
  }

  [dir='ltr'] .print\:ltr\:-mb-36,[dir='ltr'].print\:ltr\:-mb-36 {
    margin-bottom: -3.6rem
  }

  [dir='ltr'] .print\:ltr\:-ml-36,[dir='ltr'].print\:ltr\:-ml-36 {
    margin-left: -3.6rem
  }

  [dir='ltr'] .print\:ltr\:-mt-40,[dir='ltr'].print\:ltr\:-mt-40 {
    margin-top: -4rem
  }

  [dir='ltr'] .print\:ltr\:-mr-40,[dir='ltr'].print\:ltr\:-mr-40 {
    margin-right: -4rem
  }

  [dir='ltr'] .print\:ltr\:-mb-40,[dir='ltr'].print\:ltr\:-mb-40 {
    margin-bottom: -4rem
  }

  [dir='ltr'] .print\:ltr\:-ml-40,[dir='ltr'].print\:ltr\:-ml-40 {
    margin-left: -4rem
  }

  [dir='ltr'] .print\:ltr\:-mt-44,[dir='ltr'].print\:ltr\:-mt-44 {
    margin-top: -4.4rem
  }

  [dir='ltr'] .print\:ltr\:-mr-44,[dir='ltr'].print\:ltr\:-mr-44 {
    margin-right: -4.4rem
  }

  [dir='ltr'] .print\:ltr\:-mb-44,[dir='ltr'].print\:ltr\:-mb-44 {
    margin-bottom: -4.4rem
  }

  [dir='ltr'] .print\:ltr\:-ml-44,[dir='ltr'].print\:ltr\:-ml-44 {
    margin-left: -4.4rem
  }

  [dir='ltr'] .print\:ltr\:-mt-48,[dir='ltr'].print\:ltr\:-mt-48 {
    margin-top: -4.8rem
  }

  [dir='ltr'] .print\:ltr\:-mr-48,[dir='ltr'].print\:ltr\:-mr-48 {
    margin-right: -4.8rem
  }

  [dir='ltr'] .print\:ltr\:-mb-48,[dir='ltr'].print\:ltr\:-mb-48 {
    margin-bottom: -4.8rem
  }

  [dir='ltr'] .print\:ltr\:-ml-48,[dir='ltr'].print\:ltr\:-ml-48 {
    margin-left: -4.8rem
  }

  [dir='ltr'] .print\:ltr\:-mt-52,[dir='ltr'].print\:ltr\:-mt-52 {
    margin-top: -5.2rem
  }

  [dir='ltr'] .print\:ltr\:-mr-52,[dir='ltr'].print\:ltr\:-mr-52 {
    margin-right: -5.2rem
  }

  [dir='ltr'] .print\:ltr\:-mb-52,[dir='ltr'].print\:ltr\:-mb-52 {
    margin-bottom: -5.2rem
  }

  [dir='ltr'] .print\:ltr\:-ml-52,[dir='ltr'].print\:ltr\:-ml-52 {
    margin-left: -5.2rem
  }

  [dir='ltr'] .print\:ltr\:-mt-56,[dir='ltr'].print\:ltr\:-mt-56 {
    margin-top: -5.6rem
  }

  [dir='ltr'] .print\:ltr\:-mr-56,[dir='ltr'].print\:ltr\:-mr-56 {
    margin-right: -5.6rem
  }

  [dir='ltr'] .print\:ltr\:-mb-56,[dir='ltr'].print\:ltr\:-mb-56 {
    margin-bottom: -5.6rem
  }

  [dir='ltr'] .print\:ltr\:-ml-56,[dir='ltr'].print\:ltr\:-ml-56 {
    margin-left: -5.6rem
  }

  [dir='ltr'] .print\:ltr\:-mt-60,[dir='ltr'].print\:ltr\:-mt-60 {
    margin-top: -6rem
  }

  [dir='ltr'] .print\:ltr\:-mr-60,[dir='ltr'].print\:ltr\:-mr-60 {
    margin-right: -6rem
  }

  [dir='ltr'] .print\:ltr\:-mb-60,[dir='ltr'].print\:ltr\:-mb-60 {
    margin-bottom: -6rem
  }

  [dir='ltr'] .print\:ltr\:-ml-60,[dir='ltr'].print\:ltr\:-ml-60 {
    margin-left: -6rem
  }

  [dir='ltr'] .print\:ltr\:-mt-64,[dir='ltr'].print\:ltr\:-mt-64 {
    margin-top: -6.4rem
  }

  [dir='ltr'] .print\:ltr\:-mr-64,[dir='ltr'].print\:ltr\:-mr-64 {
    margin-right: -6.4rem
  }

  [dir='ltr'] .print\:ltr\:-mb-64,[dir='ltr'].print\:ltr\:-mb-64 {
    margin-bottom: -6.4rem
  }

  [dir='ltr'] .print\:ltr\:-ml-64,[dir='ltr'].print\:ltr\:-ml-64 {
    margin-left: -6.4rem
  }

  [dir='ltr'] .print\:ltr\:-mt-68,[dir='ltr'].print\:ltr\:-mt-68 {
    margin-top: -6.8rem
  }

  [dir='ltr'] .print\:ltr\:-mr-68,[dir='ltr'].print\:ltr\:-mr-68 {
    margin-right: -6.8rem
  }

  [dir='ltr'] .print\:ltr\:-mb-68,[dir='ltr'].print\:ltr\:-mb-68 {
    margin-bottom: -6.8rem
  }

  [dir='ltr'] .print\:ltr\:-ml-68,[dir='ltr'].print\:ltr\:-ml-68 {
    margin-left: -6.8rem
  }

  [dir='ltr'] .print\:ltr\:-mt-72,[dir='ltr'].print\:ltr\:-mt-72 {
    margin-top: -7.2rem
  }

  [dir='ltr'] .print\:ltr\:-mr-72,[dir='ltr'].print\:ltr\:-mr-72 {
    margin-right: -7.2rem
  }

  [dir='ltr'] .print\:ltr\:-mb-72,[dir='ltr'].print\:ltr\:-mb-72 {
    margin-bottom: -7.2rem
  }

  [dir='ltr'] .print\:ltr\:-ml-72,[dir='ltr'].print\:ltr\:-ml-72 {
    margin-left: -7.2rem
  }

  [dir='ltr'] .print\:ltr\:-mt-76,[dir='ltr'].print\:ltr\:-mt-76 {
    margin-top: -7.6rem
  }

  [dir='ltr'] .print\:ltr\:-mr-76,[dir='ltr'].print\:ltr\:-mr-76 {
    margin-right: -7.6rem
  }

  [dir='ltr'] .print\:ltr\:-mb-76,[dir='ltr'].print\:ltr\:-mb-76 {
    margin-bottom: -7.6rem
  }

  [dir='ltr'] .print\:ltr\:-ml-76,[dir='ltr'].print\:ltr\:-ml-76 {
    margin-left: -7.6rem
  }

  [dir='ltr'] .print\:ltr\:-mt-80,[dir='ltr'].print\:ltr\:-mt-80 {
    margin-top: -8rem
  }

  [dir='ltr'] .print\:ltr\:-mr-80,[dir='ltr'].print\:ltr\:-mr-80 {
    margin-right: -8rem
  }

  [dir='ltr'] .print\:ltr\:-mb-80,[dir='ltr'].print\:ltr\:-mb-80 {
    margin-bottom: -8rem
  }

  [dir='ltr'] .print\:ltr\:-ml-80,[dir='ltr'].print\:ltr\:-ml-80 {
    margin-left: -8rem
  }

  [dir='ltr'] .print\:ltr\:-mt-84,[dir='ltr'].print\:ltr\:-mt-84 {
    margin-top: -8.4rem
  }

  [dir='ltr'] .print\:ltr\:-mr-84,[dir='ltr'].print\:ltr\:-mr-84 {
    margin-right: -8.4rem
  }

  [dir='ltr'] .print\:ltr\:-mb-84,[dir='ltr'].print\:ltr\:-mb-84 {
    margin-bottom: -8.4rem
  }

  [dir='ltr'] .print\:ltr\:-ml-84,[dir='ltr'].print\:ltr\:-ml-84 {
    margin-left: -8.4rem
  }

  [dir='ltr'] .print\:ltr\:-mt-88,[dir='ltr'].print\:ltr\:-mt-88 {
    margin-top: -8.8rem
  }

  [dir='ltr'] .print\:ltr\:-mr-88,[dir='ltr'].print\:ltr\:-mr-88 {
    margin-right: -8.8rem
  }

  [dir='ltr'] .print\:ltr\:-mb-88,[dir='ltr'].print\:ltr\:-mb-88 {
    margin-bottom: -8.8rem
  }

  [dir='ltr'] .print\:ltr\:-ml-88,[dir='ltr'].print\:ltr\:-ml-88 {
    margin-left: -8.8rem
  }

  [dir='ltr'] .print\:ltr\:-mt-92,[dir='ltr'].print\:ltr\:-mt-92 {
    margin-top: -9.2rem
  }

  [dir='ltr'] .print\:ltr\:-mr-92,[dir='ltr'].print\:ltr\:-mr-92 {
    margin-right: -9.2rem
  }

  [dir='ltr'] .print\:ltr\:-mb-92,[dir='ltr'].print\:ltr\:-mb-92 {
    margin-bottom: -9.2rem
  }

  [dir='ltr'] .print\:ltr\:-ml-92,[dir='ltr'].print\:ltr\:-ml-92 {
    margin-left: -9.2rem
  }

  [dir='ltr'] .print\:ltr\:-mt-96,[dir='ltr'].print\:ltr\:-mt-96 {
    margin-top: -9.6rem
  }

  [dir='ltr'] .print\:ltr\:-mr-96,[dir='ltr'].print\:ltr\:-mr-96 {
    margin-right: -9.6rem
  }

  [dir='ltr'] .print\:ltr\:-mb-96,[dir='ltr'].print\:ltr\:-mb-96 {
    margin-bottom: -9.6rem
  }

  [dir='ltr'] .print\:ltr\:-ml-96,[dir='ltr'].print\:ltr\:-ml-96 {
    margin-left: -9.6rem
  }

  [dir='ltr'] .print\:ltr\:-mt-128,[dir='ltr'].print\:ltr\:-mt-128 {
    margin-top: -12.8rem
  }

  [dir='ltr'] .print\:ltr\:-mr-128,[dir='ltr'].print\:ltr\:-mr-128 {
    margin-right: -12.8rem
  }

  [dir='ltr'] .print\:ltr\:-mb-128,[dir='ltr'].print\:ltr\:-mb-128 {
    margin-bottom: -12.8rem
  }

  [dir='ltr'] .print\:ltr\:-ml-128,[dir='ltr'].print\:ltr\:-ml-128 {
    margin-left: -12.8rem
  }

  [dir='ltr'] .print\:ltr\:-mt-136,[dir='ltr'].print\:ltr\:-mt-136 {
    margin-top: -13.6rem
  }

  [dir='ltr'] .print\:ltr\:-mr-136,[dir='ltr'].print\:ltr\:-mr-136 {
    margin-right: -13.6rem
  }

  [dir='ltr'] .print\:ltr\:-mb-136,[dir='ltr'].print\:ltr\:-mb-136 {
    margin-bottom: -13.6rem
  }

  [dir='ltr'] .print\:ltr\:-ml-136,[dir='ltr'].print\:ltr\:-ml-136 {
    margin-left: -13.6rem
  }

  [dir='ltr'] .print\:ltr\:-mt-160,[dir='ltr'].print\:ltr\:-mt-160 {
    margin-top: -16rem
  }

  [dir='ltr'] .print\:ltr\:-mr-160,[dir='ltr'].print\:ltr\:-mr-160 {
    margin-right: -16rem
  }

  [dir='ltr'] .print\:ltr\:-mb-160,[dir='ltr'].print\:ltr\:-mb-160 {
    margin-bottom: -16rem
  }

  [dir='ltr'] .print\:ltr\:-ml-160,[dir='ltr'].print\:ltr\:-ml-160 {
    margin-left: -16rem
  }

  [dir='ltr'] .print\:ltr\:-mt-192,[dir='ltr'].print\:ltr\:-mt-192 {
    margin-top: -19.2rem
  }

  [dir='ltr'] .print\:ltr\:-mr-192,[dir='ltr'].print\:ltr\:-mr-192 {
    margin-right: -19.2rem
  }

  [dir='ltr'] .print\:ltr\:-mb-192,[dir='ltr'].print\:ltr\:-mb-192 {
    margin-bottom: -19.2rem
  }

  [dir='ltr'] .print\:ltr\:-ml-192,[dir='ltr'].print\:ltr\:-ml-192 {
    margin-left: -19.2rem
  }

  [dir='ltr'] .print\:ltr\:-mt-200,[dir='ltr'].print\:ltr\:-mt-200 {
    margin-top: -20rem
  }

  [dir='ltr'] .print\:ltr\:-mr-200,[dir='ltr'].print\:ltr\:-mr-200 {
    margin-right: -20rem
  }

  [dir='ltr'] .print\:ltr\:-mb-200,[dir='ltr'].print\:ltr\:-mb-200 {
    margin-bottom: -20rem
  }

  [dir='ltr'] .print\:ltr\:-ml-200,[dir='ltr'].print\:ltr\:-ml-200 {
    margin-left: -20rem
  }

  [dir='ltr'] .print\:ltr\:-mt-208,[dir='ltr'].print\:ltr\:-mt-208 {
    margin-top: -20.8rem
  }

  [dir='ltr'] .print\:ltr\:-mr-208,[dir='ltr'].print\:ltr\:-mr-208 {
    margin-right: -20.8rem
  }

  [dir='ltr'] .print\:ltr\:-mb-208,[dir='ltr'].print\:ltr\:-mb-208 {
    margin-bottom: -20.8rem
  }

  [dir='ltr'] .print\:ltr\:-ml-208,[dir='ltr'].print\:ltr\:-ml-208 {
    margin-left: -20.8rem
  }

  [dir='ltr'] .print\:ltr\:-mt-216,[dir='ltr'].print\:ltr\:-mt-216 {
    margin-top: -21.6rem
  }

  [dir='ltr'] .print\:ltr\:-mr-216,[dir='ltr'].print\:ltr\:-mr-216 {
    margin-right: -21.6rem
  }

  [dir='ltr'] .print\:ltr\:-mb-216,[dir='ltr'].print\:ltr\:-mb-216 {
    margin-bottom: -21.6rem
  }

  [dir='ltr'] .print\:ltr\:-ml-216,[dir='ltr'].print\:ltr\:-ml-216 {
    margin-left: -21.6rem
  }

  [dir='ltr'] .print\:ltr\:-mt-224,[dir='ltr'].print\:ltr\:-mt-224 {
    margin-top: -22.4rem
  }

  [dir='ltr'] .print\:ltr\:-mr-224,[dir='ltr'].print\:ltr\:-mr-224 {
    margin-right: -22.4rem
  }

  [dir='ltr'] .print\:ltr\:-mb-224,[dir='ltr'].print\:ltr\:-mb-224 {
    margin-bottom: -22.4rem
  }

  [dir='ltr'] .print\:ltr\:-ml-224,[dir='ltr'].print\:ltr\:-ml-224 {
    margin-left: -22.4rem
  }

  [dir='ltr'] .print\:ltr\:-mt-256,[dir='ltr'].print\:ltr\:-mt-256 {
    margin-top: -25.6rem
  }

  [dir='ltr'] .print\:ltr\:-mr-256,[dir='ltr'].print\:ltr\:-mr-256 {
    margin-right: -25.6rem
  }

  [dir='ltr'] .print\:ltr\:-mb-256,[dir='ltr'].print\:ltr\:-mb-256 {
    margin-bottom: -25.6rem
  }

  [dir='ltr'] .print\:ltr\:-ml-256,[dir='ltr'].print\:ltr\:-ml-256 {
    margin-left: -25.6rem
  }

  [dir='ltr'] .print\:ltr\:-mt-288,[dir='ltr'].print\:ltr\:-mt-288 {
    margin-top: -28.8rem
  }

  [dir='ltr'] .print\:ltr\:-mr-288,[dir='ltr'].print\:ltr\:-mr-288 {
    margin-right: -28.8rem
  }

  [dir='ltr'] .print\:ltr\:-mb-288,[dir='ltr'].print\:ltr\:-mb-288 {
    margin-bottom: -28.8rem
  }

  [dir='ltr'] .print\:ltr\:-ml-288,[dir='ltr'].print\:ltr\:-ml-288 {
    margin-left: -28.8rem
  }

  [dir='ltr'] .print\:ltr\:-mt-320,[dir='ltr'].print\:ltr\:-mt-320 {
    margin-top: -32rem
  }

  [dir='ltr'] .print\:ltr\:-mr-320,[dir='ltr'].print\:ltr\:-mr-320 {
    margin-right: -32rem
  }

  [dir='ltr'] .print\:ltr\:-mb-320,[dir='ltr'].print\:ltr\:-mb-320 {
    margin-bottom: -32rem
  }

  [dir='ltr'] .print\:ltr\:-ml-320,[dir='ltr'].print\:ltr\:-ml-320 {
    margin-left: -32rem
  }

  [dir='ltr'] .print\:ltr\:-mt-360,[dir='ltr'].print\:ltr\:-mt-360 {
    margin-top: -36rem
  }

  [dir='ltr'] .print\:ltr\:-mr-360,[dir='ltr'].print\:ltr\:-mr-360 {
    margin-right: -36rem
  }

  [dir='ltr'] .print\:ltr\:-mb-360,[dir='ltr'].print\:ltr\:-mb-360 {
    margin-bottom: -36rem
  }

  [dir='ltr'] .print\:ltr\:-ml-360,[dir='ltr'].print\:ltr\:-ml-360 {
    margin-left: -36rem
  }

  [dir='ltr'] .print\:ltr\:-mt-384,[dir='ltr'].print\:ltr\:-mt-384 {
    margin-top: -38.4rem
  }

  [dir='ltr'] .print\:ltr\:-mr-384,[dir='ltr'].print\:ltr\:-mr-384 {
    margin-right: -38.4rem
  }

  [dir='ltr'] .print\:ltr\:-mb-384,[dir='ltr'].print\:ltr\:-mb-384 {
    margin-bottom: -38.4rem
  }

  [dir='ltr'] .print\:ltr\:-ml-384,[dir='ltr'].print\:ltr\:-ml-384 {
    margin-left: -38.4rem
  }

  [dir='ltr'] .print\:ltr\:-mt-400,[dir='ltr'].print\:ltr\:-mt-400 {
    margin-top: -40rem
  }

  [dir='ltr'] .print\:ltr\:-mr-400,[dir='ltr'].print\:ltr\:-mr-400 {
    margin-right: -40rem
  }

  [dir='ltr'] .print\:ltr\:-mb-400,[dir='ltr'].print\:ltr\:-mb-400 {
    margin-bottom: -40rem
  }

  [dir='ltr'] .print\:ltr\:-ml-400,[dir='ltr'].print\:ltr\:-ml-400 {
    margin-left: -40rem
  }

  [dir='ltr'] .print\:ltr\:-mt-512,[dir='ltr'].print\:ltr\:-mt-512 {
    margin-top: -51.2rem
  }

  [dir='ltr'] .print\:ltr\:-mr-512,[dir='ltr'].print\:ltr\:-mr-512 {
    margin-right: -51.2rem
  }

  [dir='ltr'] .print\:ltr\:-mb-512,[dir='ltr'].print\:ltr\:-mb-512 {
    margin-bottom: -51.2rem
  }

  [dir='ltr'] .print\:ltr\:-ml-512,[dir='ltr'].print\:ltr\:-ml-512 {
    margin-left: -51.2rem
  }

  [dir='ltr'] .print\:ltr\:-mt-640,[dir='ltr'].print\:ltr\:-mt-640 {
    margin-top: -64rem
  }

  [dir='ltr'] .print\:ltr\:-mr-640,[dir='ltr'].print\:ltr\:-mr-640 {
    margin-right: -64rem
  }

  [dir='ltr'] .print\:ltr\:-mb-640,[dir='ltr'].print\:ltr\:-mb-640 {
    margin-bottom: -64rem
  }

  [dir='ltr'] .print\:ltr\:-ml-640,[dir='ltr'].print\:ltr\:-ml-640 {
    margin-left: -64rem
  }

  [dir='ltr'] .print\:ltr\:-mt-xs,[dir='ltr'].print\:ltr\:-mt-xs {
    margin-top: -32rem
  }

  [dir='ltr'] .print\:ltr\:-mr-xs,[dir='ltr'].print\:ltr\:-mr-xs {
    margin-right: -32rem
  }

  [dir='ltr'] .print\:ltr\:-mb-xs,[dir='ltr'].print\:ltr\:-mb-xs {
    margin-bottom: -32rem
  }

  [dir='ltr'] .print\:ltr\:-ml-xs,[dir='ltr'].print\:ltr\:-ml-xs {
    margin-left: -32rem
  }

  [dir='ltr'] .print\:ltr\:-mt-sm,[dir='ltr'].print\:ltr\:-mt-sm {
    margin-top: -48rem
  }

  [dir='ltr'] .print\:ltr\:-mr-sm,[dir='ltr'].print\:ltr\:-mr-sm {
    margin-right: -48rem
  }

  [dir='ltr'] .print\:ltr\:-mb-sm,[dir='ltr'].print\:ltr\:-mb-sm {
    margin-bottom: -48rem
  }

  [dir='ltr'] .print\:ltr\:-ml-sm,[dir='ltr'].print\:ltr\:-ml-sm {
    margin-left: -48rem
  }

  [dir='ltr'] .print\:ltr\:-mt-md,[dir='ltr'].print\:ltr\:-mt-md {
    margin-top: -64rem
  }

  [dir='ltr'] .print\:ltr\:-mr-md,[dir='ltr'].print\:ltr\:-mr-md {
    margin-right: -64rem
  }

  [dir='ltr'] .print\:ltr\:-mb-md,[dir='ltr'].print\:ltr\:-mb-md {
    margin-bottom: -64rem
  }

  [dir='ltr'] .print\:ltr\:-ml-md,[dir='ltr'].print\:ltr\:-ml-md {
    margin-left: -64rem
  }

  [dir='ltr'] .print\:ltr\:-mt-lg,[dir='ltr'].print\:ltr\:-mt-lg {
    margin-top: -80rem
  }

  [dir='ltr'] .print\:ltr\:-mr-lg,[dir='ltr'].print\:ltr\:-mr-lg {
    margin-right: -80rem
  }

  [dir='ltr'] .print\:ltr\:-mb-lg,[dir='ltr'].print\:ltr\:-mb-lg {
    margin-bottom: -80rem
  }

  [dir='ltr'] .print\:ltr\:-ml-lg,[dir='ltr'].print\:ltr\:-ml-lg {
    margin-left: -80rem
  }

  [dir='ltr'] .print\:ltr\:-mt-xl,[dir='ltr'].print\:ltr\:-mt-xl {
    margin-top: -96rem
  }

  [dir='ltr'] .print\:ltr\:-mr-xl,[dir='ltr'].print\:ltr\:-mr-xl {
    margin-right: -96rem
  }

  [dir='ltr'] .print\:ltr\:-mb-xl,[dir='ltr'].print\:ltr\:-mb-xl {
    margin-bottom: -96rem
  }

  [dir='ltr'] .print\:ltr\:-ml-xl,[dir='ltr'].print\:ltr\:-ml-xl {
    margin-left: -96rem
  }

  [dir='ltr'] .print\:ltr\:-mt-2xl,[dir='ltr'].print\:ltr\:-mt-2xl {
    margin-top: -112rem
  }

  [dir='ltr'] .print\:ltr\:-mr-2xl,[dir='ltr'].print\:ltr\:-mr-2xl {
    margin-right: -112rem
  }

  [dir='ltr'] .print\:ltr\:-mb-2xl,[dir='ltr'].print\:ltr\:-mb-2xl {
    margin-bottom: -112rem
  }

  [dir='ltr'] .print\:ltr\:-ml-2xl,[dir='ltr'].print\:ltr\:-ml-2xl {
    margin-left: -112rem
  }

  [dir='ltr'] .print\:ltr\:-mt-3xl,[dir='ltr'].print\:ltr\:-mt-3xl {
    margin-top: -128rem
  }

  [dir='ltr'] .print\:ltr\:-mr-3xl,[dir='ltr'].print\:ltr\:-mr-3xl {
    margin-right: -128rem
  }

  [dir='ltr'] .print\:ltr\:-mb-3xl,[dir='ltr'].print\:ltr\:-mb-3xl {
    margin-bottom: -128rem
  }

  [dir='ltr'] .print\:ltr\:-ml-3xl,[dir='ltr'].print\:ltr\:-ml-3xl {
    margin-left: -128rem
  }

  [dir='ltr'] .print\:ltr\:-mt-4xl,[dir='ltr'].print\:ltr\:-mt-4xl {
    margin-top: -144rem
  }

  [dir='ltr'] .print\:ltr\:-mr-4xl,[dir='ltr'].print\:ltr\:-mr-4xl {
    margin-right: -144rem
  }

  [dir='ltr'] .print\:ltr\:-mb-4xl,[dir='ltr'].print\:ltr\:-mb-4xl {
    margin-bottom: -144rem
  }

  [dir='ltr'] .print\:ltr\:-ml-4xl,[dir='ltr'].print\:ltr\:-ml-4xl {
    margin-left: -144rem
  }

  [dir='ltr'] .print\:ltr\:-mt-5xl,[dir='ltr'].print\:ltr\:-mt-5xl {
    margin-top: -160rem
  }

  [dir='ltr'] .print\:ltr\:-mr-5xl,[dir='ltr'].print\:ltr\:-mr-5xl {
    margin-right: -160rem
  }

  [dir='ltr'] .print\:ltr\:-mb-5xl,[dir='ltr'].print\:ltr\:-mb-5xl {
    margin-bottom: -160rem
  }

  [dir='ltr'] .print\:ltr\:-ml-5xl,[dir='ltr'].print\:ltr\:-ml-5xl {
    margin-left: -160rem
  }

  [dir='ltr'] .print\:ltr\:-mt-px,[dir='ltr'].print\:ltr\:-mt-px {
    margin-top: -1px
  }

  [dir='ltr'] .print\:ltr\:-mr-px,[dir='ltr'].print\:ltr\:-mr-px {
    margin-right: -1px
  }

  [dir='ltr'] .print\:ltr\:-mb-px,[dir='ltr'].print\:ltr\:-mb-px {
    margin-bottom: -1px
  }

  [dir='ltr'] .print\:ltr\:-ml-px,[dir='ltr'].print\:ltr\:-ml-px {
    margin-left: -1px
  }

  [dir='ltr'] .print\:ltr\:-mt-0\.5,[dir='ltr'].print\:ltr\:-mt-0\.5 {
    margin-top: -0.05rem
  }

  [dir='ltr'] .print\:ltr\:-mr-0\.5,[dir='ltr'].print\:ltr\:-mr-0\.5 {
    margin-right: -0.05rem
  }

  [dir='ltr'] .print\:ltr\:-mb-0\.5,[dir='ltr'].print\:ltr\:-mb-0\.5 {
    margin-bottom: -0.05rem
  }

  [dir='ltr'] .print\:ltr\:-ml-0\.5,[dir='ltr'].print\:ltr\:-ml-0\.5 {
    margin-left: -0.05rem
  }

  [dir='ltr'] .print\:ltr\:-mt-1\.5,[dir='ltr'].print\:ltr\:-mt-1\.5 {
    margin-top: -0.15rem
  }

  [dir='ltr'] .print\:ltr\:-mr-1\.5,[dir='ltr'].print\:ltr\:-mr-1\.5 {
    margin-right: -0.15rem
  }

  [dir='ltr'] .print\:ltr\:-mb-1\.5,[dir='ltr'].print\:ltr\:-mb-1\.5 {
    margin-bottom: -0.15rem
  }

  [dir='ltr'] .print\:ltr\:-ml-1\.5,[dir='ltr'].print\:ltr\:-ml-1\.5 {
    margin-left: -0.15rem
  }

  [dir='ltr'] .print\:ltr\:-mt-2\.5,[dir='ltr'].print\:ltr\:-mt-2\.5 {
    margin-top: -0.25rem
  }

  [dir='ltr'] .print\:ltr\:-mr-2\.5,[dir='ltr'].print\:ltr\:-mr-2\.5 {
    margin-right: -0.25rem
  }

  [dir='ltr'] .print\:ltr\:-mb-2\.5,[dir='ltr'].print\:ltr\:-mb-2\.5 {
    margin-bottom: -0.25rem
  }

  [dir='ltr'] .print\:ltr\:-ml-2\.5,[dir='ltr'].print\:ltr\:-ml-2\.5 {
    margin-left: -0.25rem
  }

  [dir='ltr'] .print\:ltr\:-mt-3\.5,[dir='ltr'].print\:ltr\:-mt-3\.5 {
    margin-top: -0.35rem
  }

  [dir='ltr'] .print\:ltr\:-mr-3\.5,[dir='ltr'].print\:ltr\:-mr-3\.5 {
    margin-right: -0.35rem
  }

  [dir='ltr'] .print\:ltr\:-mb-3\.5,[dir='ltr'].print\:ltr\:-mb-3\.5 {
    margin-bottom: -0.35rem
  }

  [dir='ltr'] .print\:ltr\:-ml-3\.5,[dir='ltr'].print\:ltr\:-ml-3\.5 {
    margin-left: -0.35rem
  }

  [dir='rtl'] .print\:rtl\:m-0,[dir='rtl'].print\:rtl\:m-0 {
    margin: 0
  }

  [dir='rtl'] .print\:rtl\:m-1,[dir='rtl'].print\:rtl\:m-1 {
    margin: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:m-2,[dir='rtl'].print\:rtl\:m-2 {
    margin: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:m-3,[dir='rtl'].print\:rtl\:m-3 {
    margin: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:m-4,[dir='rtl'].print\:rtl\:m-4 {
    margin: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:m-5,[dir='rtl'].print\:rtl\:m-5 {
    margin: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:m-6,[dir='rtl'].print\:rtl\:m-6 {
    margin: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:m-7,[dir='rtl'].print\:rtl\:m-7 {
    margin: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:m-8,[dir='rtl'].print\:rtl\:m-8 {
    margin: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:m-9,[dir='rtl'].print\:rtl\:m-9 {
    margin: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:m-10,[dir='rtl'].print\:rtl\:m-10 {
    margin: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:m-12,[dir='rtl'].print\:rtl\:m-12 {
    margin: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:m-14,[dir='rtl'].print\:rtl\:m-14 {
    margin: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:m-16,[dir='rtl'].print\:rtl\:m-16 {
    margin: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:m-20,[dir='rtl'].print\:rtl\:m-20 {
    margin: 2rem
  }

  [dir='rtl'] .print\:rtl\:m-24,[dir='rtl'].print\:rtl\:m-24 {
    margin: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:m-28,[dir='rtl'].print\:rtl\:m-28 {
    margin: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:m-32,[dir='rtl'].print\:rtl\:m-32 {
    margin: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:m-36,[dir='rtl'].print\:rtl\:m-36 {
    margin: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:m-40,[dir='rtl'].print\:rtl\:m-40 {
    margin: 4rem
  }

  [dir='rtl'] .print\:rtl\:m-44,[dir='rtl'].print\:rtl\:m-44 {
    margin: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:m-48,[dir='rtl'].print\:rtl\:m-48 {
    margin: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:m-52,[dir='rtl'].print\:rtl\:m-52 {
    margin: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:m-56,[dir='rtl'].print\:rtl\:m-56 {
    margin: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:m-60,[dir='rtl'].print\:rtl\:m-60 {
    margin: 6rem
  }

  [dir='rtl'] .print\:rtl\:m-64,[dir='rtl'].print\:rtl\:m-64 {
    margin: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:m-68,[dir='rtl'].print\:rtl\:m-68 {
    margin: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:m-72,[dir='rtl'].print\:rtl\:m-72 {
    margin: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:m-76,[dir='rtl'].print\:rtl\:m-76 {
    margin: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:m-80,[dir='rtl'].print\:rtl\:m-80 {
    margin: 8rem
  }

  [dir='rtl'] .print\:rtl\:m-84,[dir='rtl'].print\:rtl\:m-84 {
    margin: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:m-88,[dir='rtl'].print\:rtl\:m-88 {
    margin: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:m-92,[dir='rtl'].print\:rtl\:m-92 {
    margin: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:m-96,[dir='rtl'].print\:rtl\:m-96 {
    margin: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:m-128,[dir='rtl'].print\:rtl\:m-128 {
    margin: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:m-136,[dir='rtl'].print\:rtl\:m-136 {
    margin: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:m-160,[dir='rtl'].print\:rtl\:m-160 {
    margin: 16rem
  }

  [dir='rtl'] .print\:rtl\:m-192,[dir='rtl'].print\:rtl\:m-192 {
    margin: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:m-200,[dir='rtl'].print\:rtl\:m-200 {
    margin: 20rem
  }

  [dir='rtl'] .print\:rtl\:m-208,[dir='rtl'].print\:rtl\:m-208 {
    margin: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:m-216,[dir='rtl'].print\:rtl\:m-216 {
    margin: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:m-224,[dir='rtl'].print\:rtl\:m-224 {
    margin: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:m-256,[dir='rtl'].print\:rtl\:m-256 {
    margin: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:m-288,[dir='rtl'].print\:rtl\:m-288 {
    margin: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:m-320,[dir='rtl'].print\:rtl\:m-320 {
    margin: 32rem
  }

  [dir='rtl'] .print\:rtl\:m-360,[dir='rtl'].print\:rtl\:m-360 {
    margin: 36rem
  }

  [dir='rtl'] .print\:rtl\:m-384,[dir='rtl'].print\:rtl\:m-384 {
    margin: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:m-400,[dir='rtl'].print\:rtl\:m-400 {
    margin: 40rem
  }

  [dir='rtl'] .print\:rtl\:m-512,[dir='rtl'].print\:rtl\:m-512 {
    margin: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:m-640,[dir='rtl'].print\:rtl\:m-640 {
    margin: 64rem
  }

  [dir='rtl'] .print\:rtl\:m-auto,[dir='rtl'].print\:rtl\:m-auto {
    margin: auto
  }

  [dir='rtl'] .print\:rtl\:m-xs,[dir='rtl'].print\:rtl\:m-xs {
    margin: 32rem
  }

  [dir='rtl'] .print\:rtl\:m-sm,[dir='rtl'].print\:rtl\:m-sm {
    margin: 48rem
  }

  [dir='rtl'] .print\:rtl\:m-md,[dir='rtl'].print\:rtl\:m-md {
    margin: 64rem
  }

  [dir='rtl'] .print\:rtl\:m-lg,[dir='rtl'].print\:rtl\:m-lg {
    margin: 80rem
  }

  [dir='rtl'] .print\:rtl\:m-xl,[dir='rtl'].print\:rtl\:m-xl {
    margin: 96rem
  }

  [dir='rtl'] .print\:rtl\:m-2xl,[dir='rtl'].print\:rtl\:m-2xl {
    margin: 112rem
  }

  [dir='rtl'] .print\:rtl\:m-3xl,[dir='rtl'].print\:rtl\:m-3xl {
    margin: 128rem
  }

  [dir='rtl'] .print\:rtl\:m-4xl,[dir='rtl'].print\:rtl\:m-4xl {
    margin: 144rem
  }

  [dir='rtl'] .print\:rtl\:m-5xl,[dir='rtl'].print\:rtl\:m-5xl {
    margin: 160rem
  }

  [dir='rtl'] .print\:rtl\:m-px,[dir='rtl'].print\:rtl\:m-px {
    margin: 1px
  }

  [dir='rtl'] .print\:rtl\:m-0\.5,[dir='rtl'].print\:rtl\:m-0\.5 {
    margin: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:m-1\.5,[dir='rtl'].print\:rtl\:m-1\.5 {
    margin: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:m-2\.5,[dir='rtl'].print\:rtl\:m-2\.5 {
    margin: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:m-3\.5,[dir='rtl'].print\:rtl\:m-3\.5 {
    margin: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:-m-1,[dir='rtl'].print\:rtl\:-m-1 {
    margin: -0.1rem
  }

  [dir='rtl'] .print\:rtl\:-m-2,[dir='rtl'].print\:rtl\:-m-2 {
    margin: -0.2rem
  }

  [dir='rtl'] .print\:rtl\:-m-3,[dir='rtl'].print\:rtl\:-m-3 {
    margin: -0.3rem
  }

  [dir='rtl'] .print\:rtl\:-m-4,[dir='rtl'].print\:rtl\:-m-4 {
    margin: -0.4rem
  }

  [dir='rtl'] .print\:rtl\:-m-5,[dir='rtl'].print\:rtl\:-m-5 {
    margin: -0.5rem
  }

  [dir='rtl'] .print\:rtl\:-m-6,[dir='rtl'].print\:rtl\:-m-6 {
    margin: -0.6rem
  }

  [dir='rtl'] .print\:rtl\:-m-7,[dir='rtl'].print\:rtl\:-m-7 {
    margin: -0.7rem
  }

  [dir='rtl'] .print\:rtl\:-m-8,[dir='rtl'].print\:rtl\:-m-8 {
    margin: -0.8rem
  }

  [dir='rtl'] .print\:rtl\:-m-9,[dir='rtl'].print\:rtl\:-m-9 {
    margin: -0.9rem
  }

  [dir='rtl'] .print\:rtl\:-m-10,[dir='rtl'].print\:rtl\:-m-10 {
    margin: -1rem
  }

  [dir='rtl'] .print\:rtl\:-m-12,[dir='rtl'].print\:rtl\:-m-12 {
    margin: -1.2rem
  }

  [dir='rtl'] .print\:rtl\:-m-14,[dir='rtl'].print\:rtl\:-m-14 {
    margin: -1.4rem
  }

  [dir='rtl'] .print\:rtl\:-m-16,[dir='rtl'].print\:rtl\:-m-16 {
    margin: -1.6rem
  }

  [dir='rtl'] .print\:rtl\:-m-20,[dir='rtl'].print\:rtl\:-m-20 {
    margin: -2rem
  }

  [dir='rtl'] .print\:rtl\:-m-24,[dir='rtl'].print\:rtl\:-m-24 {
    margin: -2.4rem
  }

  [dir='rtl'] .print\:rtl\:-m-28,[dir='rtl'].print\:rtl\:-m-28 {
    margin: -2.8rem
  }

  [dir='rtl'] .print\:rtl\:-m-32,[dir='rtl'].print\:rtl\:-m-32 {
    margin: -3.2rem
  }

  [dir='rtl'] .print\:rtl\:-m-36,[dir='rtl'].print\:rtl\:-m-36 {
    margin: -3.6rem
  }

  [dir='rtl'] .print\:rtl\:-m-40,[dir='rtl'].print\:rtl\:-m-40 {
    margin: -4rem
  }

  [dir='rtl'] .print\:rtl\:-m-44,[dir='rtl'].print\:rtl\:-m-44 {
    margin: -4.4rem
  }

  [dir='rtl'] .print\:rtl\:-m-48,[dir='rtl'].print\:rtl\:-m-48 {
    margin: -4.8rem
  }

  [dir='rtl'] .print\:rtl\:-m-52,[dir='rtl'].print\:rtl\:-m-52 {
    margin: -5.2rem
  }

  [dir='rtl'] .print\:rtl\:-m-56,[dir='rtl'].print\:rtl\:-m-56 {
    margin: -5.6rem
  }

  [dir='rtl'] .print\:rtl\:-m-60,[dir='rtl'].print\:rtl\:-m-60 {
    margin: -6rem
  }

  [dir='rtl'] .print\:rtl\:-m-64,[dir='rtl'].print\:rtl\:-m-64 {
    margin: -6.4rem
  }

  [dir='rtl'] .print\:rtl\:-m-68,[dir='rtl'].print\:rtl\:-m-68 {
    margin: -6.8rem
  }

  [dir='rtl'] .print\:rtl\:-m-72,[dir='rtl'].print\:rtl\:-m-72 {
    margin: -7.2rem
  }

  [dir='rtl'] .print\:rtl\:-m-76,[dir='rtl'].print\:rtl\:-m-76 {
    margin: -7.6rem
  }

  [dir='rtl'] .print\:rtl\:-m-80,[dir='rtl'].print\:rtl\:-m-80 {
    margin: -8rem
  }

  [dir='rtl'] .print\:rtl\:-m-84,[dir='rtl'].print\:rtl\:-m-84 {
    margin: -8.4rem
  }

  [dir='rtl'] .print\:rtl\:-m-88,[dir='rtl'].print\:rtl\:-m-88 {
    margin: -8.8rem
  }

  [dir='rtl'] .print\:rtl\:-m-92,[dir='rtl'].print\:rtl\:-m-92 {
    margin: -9.2rem
  }

  [dir='rtl'] .print\:rtl\:-m-96,[dir='rtl'].print\:rtl\:-m-96 {
    margin: -9.6rem
  }

  [dir='rtl'] .print\:rtl\:-m-128,[dir='rtl'].print\:rtl\:-m-128 {
    margin: -12.8rem
  }

  [dir='rtl'] .print\:rtl\:-m-136,[dir='rtl'].print\:rtl\:-m-136 {
    margin: -13.6rem
  }

  [dir='rtl'] .print\:rtl\:-m-160,[dir='rtl'].print\:rtl\:-m-160 {
    margin: -16rem
  }

  [dir='rtl'] .print\:rtl\:-m-192,[dir='rtl'].print\:rtl\:-m-192 {
    margin: -19.2rem
  }

  [dir='rtl'] .print\:rtl\:-m-200,[dir='rtl'].print\:rtl\:-m-200 {
    margin: -20rem
  }

  [dir='rtl'] .print\:rtl\:-m-208,[dir='rtl'].print\:rtl\:-m-208 {
    margin: -20.8rem
  }

  [dir='rtl'] .print\:rtl\:-m-216,[dir='rtl'].print\:rtl\:-m-216 {
    margin: -21.6rem
  }

  [dir='rtl'] .print\:rtl\:-m-224,[dir='rtl'].print\:rtl\:-m-224 {
    margin: -22.4rem
  }

  [dir='rtl'] .print\:rtl\:-m-256,[dir='rtl'].print\:rtl\:-m-256 {
    margin: -25.6rem
  }

  [dir='rtl'] .print\:rtl\:-m-288,[dir='rtl'].print\:rtl\:-m-288 {
    margin: -28.8rem
  }

  [dir='rtl'] .print\:rtl\:-m-320,[dir='rtl'].print\:rtl\:-m-320 {
    margin: -32rem
  }

  [dir='rtl'] .print\:rtl\:-m-360,[dir='rtl'].print\:rtl\:-m-360 {
    margin: -36rem
  }

  [dir='rtl'] .print\:rtl\:-m-384,[dir='rtl'].print\:rtl\:-m-384 {
    margin: -38.4rem
  }

  [dir='rtl'] .print\:rtl\:-m-400,[dir='rtl'].print\:rtl\:-m-400 {
    margin: -40rem
  }

  [dir='rtl'] .print\:rtl\:-m-512,[dir='rtl'].print\:rtl\:-m-512 {
    margin: -51.2rem
  }

  [dir='rtl'] .print\:rtl\:-m-640,[dir='rtl'].print\:rtl\:-m-640 {
    margin: -64rem
  }

  [dir='rtl'] .print\:rtl\:-m-xs,[dir='rtl'].print\:rtl\:-m-xs {
    margin: -32rem
  }

  [dir='rtl'] .print\:rtl\:-m-sm,[dir='rtl'].print\:rtl\:-m-sm {
    margin: -48rem
  }

  [dir='rtl'] .print\:rtl\:-m-md,[dir='rtl'].print\:rtl\:-m-md {
    margin: -64rem
  }

  [dir='rtl'] .print\:rtl\:-m-lg,[dir='rtl'].print\:rtl\:-m-lg {
    margin: -80rem
  }

  [dir='rtl'] .print\:rtl\:-m-xl,[dir='rtl'].print\:rtl\:-m-xl {
    margin: -96rem
  }

  [dir='rtl'] .print\:rtl\:-m-2xl,[dir='rtl'].print\:rtl\:-m-2xl {
    margin: -112rem
  }

  [dir='rtl'] .print\:rtl\:-m-3xl,[dir='rtl'].print\:rtl\:-m-3xl {
    margin: -128rem
  }

  [dir='rtl'] .print\:rtl\:-m-4xl,[dir='rtl'].print\:rtl\:-m-4xl {
    margin: -144rem
  }

  [dir='rtl'] .print\:rtl\:-m-5xl,[dir='rtl'].print\:rtl\:-m-5xl {
    margin: -160rem
  }

  [dir='rtl'] .print\:rtl\:-m-px,[dir='rtl'].print\:rtl\:-m-px {
    margin: -1px
  }

  [dir='rtl'] .print\:rtl\:-m-0\.5,[dir='rtl'].print\:rtl\:-m-0\.5 {
    margin: -0.05rem
  }

  [dir='rtl'] .print\:rtl\:-m-1\.5,[dir='rtl'].print\:rtl\:-m-1\.5 {
    margin: -0.15rem
  }

  [dir='rtl'] .print\:rtl\:-m-2\.5,[dir='rtl'].print\:rtl\:-m-2\.5 {
    margin: -0.25rem
  }

  [dir='rtl'] .print\:rtl\:-m-3\.5,[dir='rtl'].print\:rtl\:-m-3\.5 {
    margin: -0.35rem
  }

  [dir='rtl'] .print\:rtl\:my-0,[dir='rtl'].print\:rtl\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  [dir='rtl'] .print\:rtl\:mx-0,[dir='rtl'].print\:rtl\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  [dir='rtl'] .print\:rtl\:my-1,[dir='rtl'].print\:rtl\:my-1 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:mx-1,[dir='rtl'].print\:rtl\:mx-1 {
    margin-left: 0.1rem;
    margin-right: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:my-2,[dir='rtl'].print\:rtl\:my-2 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:mx-2,[dir='rtl'].print\:rtl\:mx-2 {
    margin-left: 0.2rem;
    margin-right: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:my-3,[dir='rtl'].print\:rtl\:my-3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:mx-3,[dir='rtl'].print\:rtl\:mx-3 {
    margin-left: 0.3rem;
    margin-right: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:my-4,[dir='rtl'].print\:rtl\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:mx-4,[dir='rtl'].print\:rtl\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:my-5,[dir='rtl'].print\:rtl\:my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:mx-5,[dir='rtl'].print\:rtl\:mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:my-6,[dir='rtl'].print\:rtl\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:mx-6,[dir='rtl'].print\:rtl\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:my-7,[dir='rtl'].print\:rtl\:my-7 {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:mx-7,[dir='rtl'].print\:rtl\:mx-7 {
    margin-left: 0.7rem;
    margin-right: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:my-8,[dir='rtl'].print\:rtl\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:mx-8,[dir='rtl'].print\:rtl\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:my-9,[dir='rtl'].print\:rtl\:my-9 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:mx-9,[dir='rtl'].print\:rtl\:mx-9 {
    margin-left: 0.9rem;
    margin-right: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:my-10,[dir='rtl'].print\:rtl\:my-10 {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:mx-10,[dir='rtl'].print\:rtl\:mx-10 {
    margin-left: 1.0rem;
    margin-right: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:my-12,[dir='rtl'].print\:rtl\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:mx-12,[dir='rtl'].print\:rtl\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:my-14,[dir='rtl'].print\:rtl\:my-14 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:mx-14,[dir='rtl'].print\:rtl\:mx-14 {
    margin-left: 1.4rem;
    margin-right: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:my-16,[dir='rtl'].print\:rtl\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:mx-16,[dir='rtl'].print\:rtl\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:my-20,[dir='rtl'].print\:rtl\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  [dir='rtl'] .print\:rtl\:mx-20,[dir='rtl'].print\:rtl\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  [dir='rtl'] .print\:rtl\:my-24,[dir='rtl'].print\:rtl\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:mx-24,[dir='rtl'].print\:rtl\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:my-28,[dir='rtl'].print\:rtl\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:mx-28,[dir='rtl'].print\:rtl\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:my-32,[dir='rtl'].print\:rtl\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:mx-32,[dir='rtl'].print\:rtl\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:my-36,[dir='rtl'].print\:rtl\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:mx-36,[dir='rtl'].print\:rtl\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:my-40,[dir='rtl'].print\:rtl\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  [dir='rtl'] .print\:rtl\:mx-40,[dir='rtl'].print\:rtl\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  [dir='rtl'] .print\:rtl\:my-44,[dir='rtl'].print\:rtl\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:mx-44,[dir='rtl'].print\:rtl\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:my-48,[dir='rtl'].print\:rtl\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:mx-48,[dir='rtl'].print\:rtl\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:my-52,[dir='rtl'].print\:rtl\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:mx-52,[dir='rtl'].print\:rtl\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:my-56,[dir='rtl'].print\:rtl\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:mx-56,[dir='rtl'].print\:rtl\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:my-60,[dir='rtl'].print\:rtl\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  [dir='rtl'] .print\:rtl\:mx-60,[dir='rtl'].print\:rtl\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  [dir='rtl'] .print\:rtl\:my-64,[dir='rtl'].print\:rtl\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:mx-64,[dir='rtl'].print\:rtl\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:my-68,[dir='rtl'].print\:rtl\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:mx-68,[dir='rtl'].print\:rtl\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:my-72,[dir='rtl'].print\:rtl\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:mx-72,[dir='rtl'].print\:rtl\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:my-76,[dir='rtl'].print\:rtl\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:mx-76,[dir='rtl'].print\:rtl\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:my-80,[dir='rtl'].print\:rtl\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  [dir='rtl'] .print\:rtl\:mx-80,[dir='rtl'].print\:rtl\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  [dir='rtl'] .print\:rtl\:my-84,[dir='rtl'].print\:rtl\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:mx-84,[dir='rtl'].print\:rtl\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:my-88,[dir='rtl'].print\:rtl\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:mx-88,[dir='rtl'].print\:rtl\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:my-92,[dir='rtl'].print\:rtl\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:mx-92,[dir='rtl'].print\:rtl\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:my-96,[dir='rtl'].print\:rtl\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:mx-96,[dir='rtl'].print\:rtl\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:my-128,[dir='rtl'].print\:rtl\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:mx-128,[dir='rtl'].print\:rtl\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:my-136,[dir='rtl'].print\:rtl\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:mx-136,[dir='rtl'].print\:rtl\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:my-160,[dir='rtl'].print\:rtl\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  [dir='rtl'] .print\:rtl\:mx-160,[dir='rtl'].print\:rtl\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  [dir='rtl'] .print\:rtl\:my-192,[dir='rtl'].print\:rtl\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:mx-192,[dir='rtl'].print\:rtl\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:my-200,[dir='rtl'].print\:rtl\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  [dir='rtl'] .print\:rtl\:mx-200,[dir='rtl'].print\:rtl\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  [dir='rtl'] .print\:rtl\:my-208,[dir='rtl'].print\:rtl\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:mx-208,[dir='rtl'].print\:rtl\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:my-216,[dir='rtl'].print\:rtl\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:mx-216,[dir='rtl'].print\:rtl\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:my-224,[dir='rtl'].print\:rtl\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:mx-224,[dir='rtl'].print\:rtl\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:my-256,[dir='rtl'].print\:rtl\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:mx-256,[dir='rtl'].print\:rtl\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:my-288,[dir='rtl'].print\:rtl\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:mx-288,[dir='rtl'].print\:rtl\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:my-320,[dir='rtl'].print\:rtl\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='rtl'] .print\:rtl\:mx-320,[dir='rtl'].print\:rtl\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='rtl'] .print\:rtl\:my-360,[dir='rtl'].print\:rtl\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  [dir='rtl'] .print\:rtl\:mx-360,[dir='rtl'].print\:rtl\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  [dir='rtl'] .print\:rtl\:my-384,[dir='rtl'].print\:rtl\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:mx-384,[dir='rtl'].print\:rtl\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:my-400,[dir='rtl'].print\:rtl\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  [dir='rtl'] .print\:rtl\:mx-400,[dir='rtl'].print\:rtl\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  [dir='rtl'] .print\:rtl\:my-512,[dir='rtl'].print\:rtl\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:mx-512,[dir='rtl'].print\:rtl\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:my-640,[dir='rtl'].print\:rtl\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='rtl'] .print\:rtl\:mx-640,[dir='rtl'].print\:rtl\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='rtl'] .print\:rtl\:my-auto,[dir='rtl'].print\:rtl\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  [dir='rtl'] .print\:rtl\:mx-auto,[dir='rtl'].print\:rtl\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  [dir='rtl'] .print\:rtl\:my-xs,[dir='rtl'].print\:rtl\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  [dir='rtl'] .print\:rtl\:mx-xs,[dir='rtl'].print\:rtl\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  [dir='rtl'] .print\:rtl\:my-sm,[dir='rtl'].print\:rtl\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  [dir='rtl'] .print\:rtl\:mx-sm,[dir='rtl'].print\:rtl\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  [dir='rtl'] .print\:rtl\:my-md,[dir='rtl'].print\:rtl\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  [dir='rtl'] .print\:rtl\:mx-md,[dir='rtl'].print\:rtl\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  [dir='rtl'] .print\:rtl\:my-lg,[dir='rtl'].print\:rtl\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  [dir='rtl'] .print\:rtl\:mx-lg,[dir='rtl'].print\:rtl\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  [dir='rtl'] .print\:rtl\:my-xl,[dir='rtl'].print\:rtl\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  [dir='rtl'] .print\:rtl\:mx-xl,[dir='rtl'].print\:rtl\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  [dir='rtl'] .print\:rtl\:my-2xl,[dir='rtl'].print\:rtl\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  [dir='rtl'] .print\:rtl\:mx-2xl,[dir='rtl'].print\:rtl\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  [dir='rtl'] .print\:rtl\:my-3xl,[dir='rtl'].print\:rtl\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  [dir='rtl'] .print\:rtl\:mx-3xl,[dir='rtl'].print\:rtl\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  [dir='rtl'] .print\:rtl\:my-4xl,[dir='rtl'].print\:rtl\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  [dir='rtl'] .print\:rtl\:mx-4xl,[dir='rtl'].print\:rtl\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  [dir='rtl'] .print\:rtl\:my-5xl,[dir='rtl'].print\:rtl\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  [dir='rtl'] .print\:rtl\:mx-5xl,[dir='rtl'].print\:rtl\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  [dir='rtl'] .print\:rtl\:my-px,[dir='rtl'].print\:rtl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  [dir='rtl'] .print\:rtl\:mx-px,[dir='rtl'].print\:rtl\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  [dir='rtl'] .print\:rtl\:my-0\.5,[dir='rtl'].print\:rtl\:my-0\.5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:mx-0\.5,[dir='rtl'].print\:rtl\:mx-0\.5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:my-1\.5,[dir='rtl'].print\:rtl\:my-1\.5 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:mx-1\.5,[dir='rtl'].print\:rtl\:mx-1\.5 {
    margin-left: 0.15rem;
    margin-right: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:my-2\.5,[dir='rtl'].print\:rtl\:my-2\.5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:mx-2\.5,[dir='rtl'].print\:rtl\:mx-2\.5 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:my-3\.5,[dir='rtl'].print\:rtl\:my-3\.5 {
    margin-top: 0.35rem;
    margin-bottom: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:mx-3\.5,[dir='rtl'].print\:rtl\:mx-3\.5 {
    margin-left: 0.35rem;
    margin-right: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:-my-1,[dir='rtl'].print\:rtl\:-my-1 {
    margin-top: -0.1rem;
    margin-bottom: -0.1rem
  }

  [dir='rtl'] .print\:rtl\:-mx-1,[dir='rtl'].print\:rtl\:-mx-1 {
    margin-left: -0.1rem;
    margin-right: -0.1rem
  }

  [dir='rtl'] .print\:rtl\:-my-2,[dir='rtl'].print\:rtl\:-my-2 {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem
  }

  [dir='rtl'] .print\:rtl\:-mx-2,[dir='rtl'].print\:rtl\:-mx-2 {
    margin-left: -0.2rem;
    margin-right: -0.2rem
  }

  [dir='rtl'] .print\:rtl\:-my-3,[dir='rtl'].print\:rtl\:-my-3 {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem
  }

  [dir='rtl'] .print\:rtl\:-mx-3,[dir='rtl'].print\:rtl\:-mx-3 {
    margin-left: -0.3rem;
    margin-right: -0.3rem
  }

  [dir='rtl'] .print\:rtl\:-my-4,[dir='rtl'].print\:rtl\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  [dir='rtl'] .print\:rtl\:-mx-4,[dir='rtl'].print\:rtl\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  [dir='rtl'] .print\:rtl\:-my-5,[dir='rtl'].print\:rtl\:-my-5 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  [dir='rtl'] .print\:rtl\:-mx-5,[dir='rtl'].print\:rtl\:-mx-5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  [dir='rtl'] .print\:rtl\:-my-6,[dir='rtl'].print\:rtl\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  [dir='rtl'] .print\:rtl\:-mx-6,[dir='rtl'].print\:rtl\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  [dir='rtl'] .print\:rtl\:-my-7,[dir='rtl'].print\:rtl\:-my-7 {
    margin-top: -0.7rem;
    margin-bottom: -0.7rem
  }

  [dir='rtl'] .print\:rtl\:-mx-7,[dir='rtl'].print\:rtl\:-mx-7 {
    margin-left: -0.7rem;
    margin-right: -0.7rem
  }

  [dir='rtl'] .print\:rtl\:-my-8,[dir='rtl'].print\:rtl\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  [dir='rtl'] .print\:rtl\:-mx-8,[dir='rtl'].print\:rtl\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  [dir='rtl'] .print\:rtl\:-my-9,[dir='rtl'].print\:rtl\:-my-9 {
    margin-top: -0.9rem;
    margin-bottom: -0.9rem
  }

  [dir='rtl'] .print\:rtl\:-mx-9,[dir='rtl'].print\:rtl\:-mx-9 {
    margin-left: -0.9rem;
    margin-right: -0.9rem
  }

  [dir='rtl'] .print\:rtl\:-my-10,[dir='rtl'].print\:rtl\:-my-10 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  [dir='rtl'] .print\:rtl\:-mx-10,[dir='rtl'].print\:rtl\:-mx-10 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  [dir='rtl'] .print\:rtl\:-my-12,[dir='rtl'].print\:rtl\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  [dir='rtl'] .print\:rtl\:-mx-12,[dir='rtl'].print\:rtl\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  [dir='rtl'] .print\:rtl\:-my-14,[dir='rtl'].print\:rtl\:-my-14 {
    margin-top: -1.4rem;
    margin-bottom: -1.4rem
  }

  [dir='rtl'] .print\:rtl\:-mx-14,[dir='rtl'].print\:rtl\:-mx-14 {
    margin-left: -1.4rem;
    margin-right: -1.4rem
  }

  [dir='rtl'] .print\:rtl\:-my-16,[dir='rtl'].print\:rtl\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  [dir='rtl'] .print\:rtl\:-mx-16,[dir='rtl'].print\:rtl\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  [dir='rtl'] .print\:rtl\:-my-20,[dir='rtl'].print\:rtl\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  [dir='rtl'] .print\:rtl\:-mx-20,[dir='rtl'].print\:rtl\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  [dir='rtl'] .print\:rtl\:-my-24,[dir='rtl'].print\:rtl\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  [dir='rtl'] .print\:rtl\:-mx-24,[dir='rtl'].print\:rtl\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  [dir='rtl'] .print\:rtl\:-my-28,[dir='rtl'].print\:rtl\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  [dir='rtl'] .print\:rtl\:-mx-28,[dir='rtl'].print\:rtl\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  [dir='rtl'] .print\:rtl\:-my-32,[dir='rtl'].print\:rtl\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  [dir='rtl'] .print\:rtl\:-mx-32,[dir='rtl'].print\:rtl\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  [dir='rtl'] .print\:rtl\:-my-36,[dir='rtl'].print\:rtl\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  [dir='rtl'] .print\:rtl\:-mx-36,[dir='rtl'].print\:rtl\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  [dir='rtl'] .print\:rtl\:-my-40,[dir='rtl'].print\:rtl\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  [dir='rtl'] .print\:rtl\:-mx-40,[dir='rtl'].print\:rtl\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  [dir='rtl'] .print\:rtl\:-my-44,[dir='rtl'].print\:rtl\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  [dir='rtl'] .print\:rtl\:-mx-44,[dir='rtl'].print\:rtl\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  [dir='rtl'] .print\:rtl\:-my-48,[dir='rtl'].print\:rtl\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  [dir='rtl'] .print\:rtl\:-mx-48,[dir='rtl'].print\:rtl\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  [dir='rtl'] .print\:rtl\:-my-52,[dir='rtl'].print\:rtl\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  [dir='rtl'] .print\:rtl\:-mx-52,[dir='rtl'].print\:rtl\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  [dir='rtl'] .print\:rtl\:-my-56,[dir='rtl'].print\:rtl\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  [dir='rtl'] .print\:rtl\:-mx-56,[dir='rtl'].print\:rtl\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  [dir='rtl'] .print\:rtl\:-my-60,[dir='rtl'].print\:rtl\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  [dir='rtl'] .print\:rtl\:-mx-60,[dir='rtl'].print\:rtl\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  [dir='rtl'] .print\:rtl\:-my-64,[dir='rtl'].print\:rtl\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  [dir='rtl'] .print\:rtl\:-mx-64,[dir='rtl'].print\:rtl\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  [dir='rtl'] .print\:rtl\:-my-68,[dir='rtl'].print\:rtl\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  [dir='rtl'] .print\:rtl\:-mx-68,[dir='rtl'].print\:rtl\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  [dir='rtl'] .print\:rtl\:-my-72,[dir='rtl'].print\:rtl\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  [dir='rtl'] .print\:rtl\:-mx-72,[dir='rtl'].print\:rtl\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  [dir='rtl'] .print\:rtl\:-my-76,[dir='rtl'].print\:rtl\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  [dir='rtl'] .print\:rtl\:-mx-76,[dir='rtl'].print\:rtl\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  [dir='rtl'] .print\:rtl\:-my-80,[dir='rtl'].print\:rtl\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  [dir='rtl'] .print\:rtl\:-mx-80,[dir='rtl'].print\:rtl\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  [dir='rtl'] .print\:rtl\:-my-84,[dir='rtl'].print\:rtl\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  [dir='rtl'] .print\:rtl\:-mx-84,[dir='rtl'].print\:rtl\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  [dir='rtl'] .print\:rtl\:-my-88,[dir='rtl'].print\:rtl\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  [dir='rtl'] .print\:rtl\:-mx-88,[dir='rtl'].print\:rtl\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  [dir='rtl'] .print\:rtl\:-my-92,[dir='rtl'].print\:rtl\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  [dir='rtl'] .print\:rtl\:-mx-92,[dir='rtl'].print\:rtl\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  [dir='rtl'] .print\:rtl\:-my-96,[dir='rtl'].print\:rtl\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  [dir='rtl'] .print\:rtl\:-mx-96,[dir='rtl'].print\:rtl\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  [dir='rtl'] .print\:rtl\:-my-128,[dir='rtl'].print\:rtl\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  [dir='rtl'] .print\:rtl\:-mx-128,[dir='rtl'].print\:rtl\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  [dir='rtl'] .print\:rtl\:-my-136,[dir='rtl'].print\:rtl\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  [dir='rtl'] .print\:rtl\:-mx-136,[dir='rtl'].print\:rtl\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  [dir='rtl'] .print\:rtl\:-my-160,[dir='rtl'].print\:rtl\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  [dir='rtl'] .print\:rtl\:-mx-160,[dir='rtl'].print\:rtl\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  [dir='rtl'] .print\:rtl\:-my-192,[dir='rtl'].print\:rtl\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  [dir='rtl'] .print\:rtl\:-mx-192,[dir='rtl'].print\:rtl\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  [dir='rtl'] .print\:rtl\:-my-200,[dir='rtl'].print\:rtl\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  [dir='rtl'] .print\:rtl\:-mx-200,[dir='rtl'].print\:rtl\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  [dir='rtl'] .print\:rtl\:-my-208,[dir='rtl'].print\:rtl\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  [dir='rtl'] .print\:rtl\:-mx-208,[dir='rtl'].print\:rtl\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  [dir='rtl'] .print\:rtl\:-my-216,[dir='rtl'].print\:rtl\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  [dir='rtl'] .print\:rtl\:-mx-216,[dir='rtl'].print\:rtl\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  [dir='rtl'] .print\:rtl\:-my-224,[dir='rtl'].print\:rtl\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  [dir='rtl'] .print\:rtl\:-mx-224,[dir='rtl'].print\:rtl\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  [dir='rtl'] .print\:rtl\:-my-256,[dir='rtl'].print\:rtl\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  [dir='rtl'] .print\:rtl\:-mx-256,[dir='rtl'].print\:rtl\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  [dir='rtl'] .print\:rtl\:-my-288,[dir='rtl'].print\:rtl\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  [dir='rtl'] .print\:rtl\:-mx-288,[dir='rtl'].print\:rtl\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  [dir='rtl'] .print\:rtl\:-my-320,[dir='rtl'].print\:rtl\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='rtl'] .print\:rtl\:-mx-320,[dir='rtl'].print\:rtl\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='rtl'] .print\:rtl\:-my-360,[dir='rtl'].print\:rtl\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  [dir='rtl'] .print\:rtl\:-mx-360,[dir='rtl'].print\:rtl\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  [dir='rtl'] .print\:rtl\:-my-384,[dir='rtl'].print\:rtl\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  [dir='rtl'] .print\:rtl\:-mx-384,[dir='rtl'].print\:rtl\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  [dir='rtl'] .print\:rtl\:-my-400,[dir='rtl'].print\:rtl\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  [dir='rtl'] .print\:rtl\:-mx-400,[dir='rtl'].print\:rtl\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  [dir='rtl'] .print\:rtl\:-my-512,[dir='rtl'].print\:rtl\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  [dir='rtl'] .print\:rtl\:-mx-512,[dir='rtl'].print\:rtl\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  [dir='rtl'] .print\:rtl\:-my-640,[dir='rtl'].print\:rtl\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='rtl'] .print\:rtl\:-mx-640,[dir='rtl'].print\:rtl\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='rtl'] .print\:rtl\:-my-xs,[dir='rtl'].print\:rtl\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  [dir='rtl'] .print\:rtl\:-mx-xs,[dir='rtl'].print\:rtl\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  [dir='rtl'] .print\:rtl\:-my-sm,[dir='rtl'].print\:rtl\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  [dir='rtl'] .print\:rtl\:-mx-sm,[dir='rtl'].print\:rtl\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  [dir='rtl'] .print\:rtl\:-my-md,[dir='rtl'].print\:rtl\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  [dir='rtl'] .print\:rtl\:-mx-md,[dir='rtl'].print\:rtl\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  [dir='rtl'] .print\:rtl\:-my-lg,[dir='rtl'].print\:rtl\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  [dir='rtl'] .print\:rtl\:-mx-lg,[dir='rtl'].print\:rtl\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  [dir='rtl'] .print\:rtl\:-my-xl,[dir='rtl'].print\:rtl\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  [dir='rtl'] .print\:rtl\:-mx-xl,[dir='rtl'].print\:rtl\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  [dir='rtl'] .print\:rtl\:-my-2xl,[dir='rtl'].print\:rtl\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  [dir='rtl'] .print\:rtl\:-mx-2xl,[dir='rtl'].print\:rtl\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  [dir='rtl'] .print\:rtl\:-my-3xl,[dir='rtl'].print\:rtl\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  [dir='rtl'] .print\:rtl\:-mx-3xl,[dir='rtl'].print\:rtl\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  [dir='rtl'] .print\:rtl\:-my-4xl,[dir='rtl'].print\:rtl\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  [dir='rtl'] .print\:rtl\:-mx-4xl,[dir='rtl'].print\:rtl\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  [dir='rtl'] .print\:rtl\:-my-5xl,[dir='rtl'].print\:rtl\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  [dir='rtl'] .print\:rtl\:-mx-5xl,[dir='rtl'].print\:rtl\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  [dir='rtl'] .print\:rtl\:-my-px,[dir='rtl'].print\:rtl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  [dir='rtl'] .print\:rtl\:-mx-px,[dir='rtl'].print\:rtl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  [dir='rtl'] .print\:rtl\:-my-0\.5,[dir='rtl'].print\:rtl\:-my-0\.5 {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem
  }

  [dir='rtl'] .print\:rtl\:-mx-0\.5,[dir='rtl'].print\:rtl\:-mx-0\.5 {
    margin-left: -0.05rem;
    margin-right: -0.05rem
  }

  [dir='rtl'] .print\:rtl\:-my-1\.5,[dir='rtl'].print\:rtl\:-my-1\.5 {
    margin-top: -0.15rem;
    margin-bottom: -0.15rem
  }

  [dir='rtl'] .print\:rtl\:-mx-1\.5,[dir='rtl'].print\:rtl\:-mx-1\.5 {
    margin-left: -0.15rem;
    margin-right: -0.15rem
  }

  [dir='rtl'] .print\:rtl\:-my-2\.5,[dir='rtl'].print\:rtl\:-my-2\.5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  [dir='rtl'] .print\:rtl\:-mx-2\.5,[dir='rtl'].print\:rtl\:-mx-2\.5 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  [dir='rtl'] .print\:rtl\:-my-3\.5,[dir='rtl'].print\:rtl\:-my-3\.5 {
    margin-top: -0.35rem;
    margin-bottom: -0.35rem
  }

  [dir='rtl'] .print\:rtl\:-mx-3\.5,[dir='rtl'].print\:rtl\:-mx-3\.5 {
    margin-left: -0.35rem;
    margin-right: -0.35rem
  }

  [dir='rtl'] .print\:rtl\:mt-0,[dir='rtl'].print\:rtl\:mt-0 {
    margin-top: 0
  }

  [dir='rtl'] .print\:rtl\:mr-0,[dir='rtl'].print\:rtl\:mr-0 {
    margin-right: 0
  }

  [dir='rtl'] .print\:rtl\:mb-0,[dir='rtl'].print\:rtl\:mb-0 {
    margin-bottom: 0
  }

  [dir='rtl'] .print\:rtl\:ml-0,[dir='rtl'].print\:rtl\:ml-0 {
    margin-left: 0
  }

  [dir='rtl'] .print\:rtl\:mt-1,[dir='rtl'].print\:rtl\:mt-1 {
    margin-top: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:mr-1,[dir='rtl'].print\:rtl\:mr-1 {
    margin-right: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:mb-1,[dir='rtl'].print\:rtl\:mb-1 {
    margin-bottom: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:ml-1,[dir='rtl'].print\:rtl\:ml-1 {
    margin-left: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:mt-2,[dir='rtl'].print\:rtl\:mt-2 {
    margin-top: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:mr-2,[dir='rtl'].print\:rtl\:mr-2 {
    margin-right: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:mb-2,[dir='rtl'].print\:rtl\:mb-2 {
    margin-bottom: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:ml-2,[dir='rtl'].print\:rtl\:ml-2 {
    margin-left: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:mt-3,[dir='rtl'].print\:rtl\:mt-3 {
    margin-top: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:mr-3,[dir='rtl'].print\:rtl\:mr-3 {
    margin-right: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:mb-3,[dir='rtl'].print\:rtl\:mb-3 {
    margin-bottom: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:ml-3,[dir='rtl'].print\:rtl\:ml-3 {
    margin-left: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:mt-4,[dir='rtl'].print\:rtl\:mt-4 {
    margin-top: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:mr-4,[dir='rtl'].print\:rtl\:mr-4 {
    margin-right: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:mb-4,[dir='rtl'].print\:rtl\:mb-4 {
    margin-bottom: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:ml-4,[dir='rtl'].print\:rtl\:ml-4 {
    margin-left: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:mt-5,[dir='rtl'].print\:rtl\:mt-5 {
    margin-top: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:mr-5,[dir='rtl'].print\:rtl\:mr-5 {
    margin-right: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:mb-5,[dir='rtl'].print\:rtl\:mb-5 {
    margin-bottom: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:ml-5,[dir='rtl'].print\:rtl\:ml-5 {
    margin-left: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:mt-6,[dir='rtl'].print\:rtl\:mt-6 {
    margin-top: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:mr-6,[dir='rtl'].print\:rtl\:mr-6 {
    margin-right: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:mb-6,[dir='rtl'].print\:rtl\:mb-6 {
    margin-bottom: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:ml-6,[dir='rtl'].print\:rtl\:ml-6 {
    margin-left: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:mt-7,[dir='rtl'].print\:rtl\:mt-7 {
    margin-top: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:mr-7,[dir='rtl'].print\:rtl\:mr-7 {
    margin-right: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:mb-7,[dir='rtl'].print\:rtl\:mb-7 {
    margin-bottom: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:ml-7,[dir='rtl'].print\:rtl\:ml-7 {
    margin-left: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:mt-8,[dir='rtl'].print\:rtl\:mt-8 {
    margin-top: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:mr-8,[dir='rtl'].print\:rtl\:mr-8 {
    margin-right: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:mb-8,[dir='rtl'].print\:rtl\:mb-8 {
    margin-bottom: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:ml-8,[dir='rtl'].print\:rtl\:ml-8 {
    margin-left: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:mt-9,[dir='rtl'].print\:rtl\:mt-9 {
    margin-top: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:mr-9,[dir='rtl'].print\:rtl\:mr-9 {
    margin-right: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:mb-9,[dir='rtl'].print\:rtl\:mb-9 {
    margin-bottom: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:ml-9,[dir='rtl'].print\:rtl\:ml-9 {
    margin-left: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:mt-10,[dir='rtl'].print\:rtl\:mt-10 {
    margin-top: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:mr-10,[dir='rtl'].print\:rtl\:mr-10 {
    margin-right: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:mb-10,[dir='rtl'].print\:rtl\:mb-10 {
    margin-bottom: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:ml-10,[dir='rtl'].print\:rtl\:ml-10 {
    margin-left: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:mt-12,[dir='rtl'].print\:rtl\:mt-12 {
    margin-top: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:mr-12,[dir='rtl'].print\:rtl\:mr-12 {
    margin-right: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:mb-12,[dir='rtl'].print\:rtl\:mb-12 {
    margin-bottom: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:ml-12,[dir='rtl'].print\:rtl\:ml-12 {
    margin-left: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:mt-14,[dir='rtl'].print\:rtl\:mt-14 {
    margin-top: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:mr-14,[dir='rtl'].print\:rtl\:mr-14 {
    margin-right: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:mb-14,[dir='rtl'].print\:rtl\:mb-14 {
    margin-bottom: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:ml-14,[dir='rtl'].print\:rtl\:ml-14 {
    margin-left: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:mt-16,[dir='rtl'].print\:rtl\:mt-16 {
    margin-top: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:mr-16,[dir='rtl'].print\:rtl\:mr-16 {
    margin-right: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:mb-16,[dir='rtl'].print\:rtl\:mb-16 {
    margin-bottom: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:ml-16,[dir='rtl'].print\:rtl\:ml-16 {
    margin-left: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:mt-20,[dir='rtl'].print\:rtl\:mt-20 {
    margin-top: 2rem
  }

  [dir='rtl'] .print\:rtl\:mr-20,[dir='rtl'].print\:rtl\:mr-20 {
    margin-right: 2rem
  }

  [dir='rtl'] .print\:rtl\:mb-20,[dir='rtl'].print\:rtl\:mb-20 {
    margin-bottom: 2rem
  }

  [dir='rtl'] .print\:rtl\:ml-20,[dir='rtl'].print\:rtl\:ml-20 {
    margin-left: 2rem
  }

  [dir='rtl'] .print\:rtl\:mt-24,[dir='rtl'].print\:rtl\:mt-24 {
    margin-top: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:mr-24,[dir='rtl'].print\:rtl\:mr-24 {
    margin-right: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:mb-24,[dir='rtl'].print\:rtl\:mb-24 {
    margin-bottom: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:ml-24,[dir='rtl'].print\:rtl\:ml-24 {
    margin-left: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:mt-28,[dir='rtl'].print\:rtl\:mt-28 {
    margin-top: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:mr-28,[dir='rtl'].print\:rtl\:mr-28 {
    margin-right: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:mb-28,[dir='rtl'].print\:rtl\:mb-28 {
    margin-bottom: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:ml-28,[dir='rtl'].print\:rtl\:ml-28 {
    margin-left: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:mt-32,[dir='rtl'].print\:rtl\:mt-32 {
    margin-top: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:mr-32,[dir='rtl'].print\:rtl\:mr-32 {
    margin-right: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:mb-32,[dir='rtl'].print\:rtl\:mb-32 {
    margin-bottom: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:ml-32,[dir='rtl'].print\:rtl\:ml-32 {
    margin-left: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:mt-36,[dir='rtl'].print\:rtl\:mt-36 {
    margin-top: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:mr-36,[dir='rtl'].print\:rtl\:mr-36 {
    margin-right: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:mb-36,[dir='rtl'].print\:rtl\:mb-36 {
    margin-bottom: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:ml-36,[dir='rtl'].print\:rtl\:ml-36 {
    margin-left: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:mt-40,[dir='rtl'].print\:rtl\:mt-40 {
    margin-top: 4rem
  }

  [dir='rtl'] .print\:rtl\:mr-40,[dir='rtl'].print\:rtl\:mr-40 {
    margin-right: 4rem
  }

  [dir='rtl'] .print\:rtl\:mb-40,[dir='rtl'].print\:rtl\:mb-40 {
    margin-bottom: 4rem
  }

  [dir='rtl'] .print\:rtl\:ml-40,[dir='rtl'].print\:rtl\:ml-40 {
    margin-left: 4rem
  }

  [dir='rtl'] .print\:rtl\:mt-44,[dir='rtl'].print\:rtl\:mt-44 {
    margin-top: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:mr-44,[dir='rtl'].print\:rtl\:mr-44 {
    margin-right: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:mb-44,[dir='rtl'].print\:rtl\:mb-44 {
    margin-bottom: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:ml-44,[dir='rtl'].print\:rtl\:ml-44 {
    margin-left: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:mt-48,[dir='rtl'].print\:rtl\:mt-48 {
    margin-top: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:mr-48,[dir='rtl'].print\:rtl\:mr-48 {
    margin-right: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:mb-48,[dir='rtl'].print\:rtl\:mb-48 {
    margin-bottom: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:ml-48,[dir='rtl'].print\:rtl\:ml-48 {
    margin-left: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:mt-52,[dir='rtl'].print\:rtl\:mt-52 {
    margin-top: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:mr-52,[dir='rtl'].print\:rtl\:mr-52 {
    margin-right: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:mb-52,[dir='rtl'].print\:rtl\:mb-52 {
    margin-bottom: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:ml-52,[dir='rtl'].print\:rtl\:ml-52 {
    margin-left: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:mt-56,[dir='rtl'].print\:rtl\:mt-56 {
    margin-top: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:mr-56,[dir='rtl'].print\:rtl\:mr-56 {
    margin-right: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:mb-56,[dir='rtl'].print\:rtl\:mb-56 {
    margin-bottom: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:ml-56,[dir='rtl'].print\:rtl\:ml-56 {
    margin-left: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:mt-60,[dir='rtl'].print\:rtl\:mt-60 {
    margin-top: 6rem
  }

  [dir='rtl'] .print\:rtl\:mr-60,[dir='rtl'].print\:rtl\:mr-60 {
    margin-right: 6rem
  }

  [dir='rtl'] .print\:rtl\:mb-60,[dir='rtl'].print\:rtl\:mb-60 {
    margin-bottom: 6rem
  }

  [dir='rtl'] .print\:rtl\:ml-60,[dir='rtl'].print\:rtl\:ml-60 {
    margin-left: 6rem
  }

  [dir='rtl'] .print\:rtl\:mt-64,[dir='rtl'].print\:rtl\:mt-64 {
    margin-top: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:mr-64,[dir='rtl'].print\:rtl\:mr-64 {
    margin-right: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:mb-64,[dir='rtl'].print\:rtl\:mb-64 {
    margin-bottom: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:ml-64,[dir='rtl'].print\:rtl\:ml-64 {
    margin-left: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:mt-68,[dir='rtl'].print\:rtl\:mt-68 {
    margin-top: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:mr-68,[dir='rtl'].print\:rtl\:mr-68 {
    margin-right: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:mb-68,[dir='rtl'].print\:rtl\:mb-68 {
    margin-bottom: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:ml-68,[dir='rtl'].print\:rtl\:ml-68 {
    margin-left: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:mt-72,[dir='rtl'].print\:rtl\:mt-72 {
    margin-top: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:mr-72,[dir='rtl'].print\:rtl\:mr-72 {
    margin-right: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:mb-72,[dir='rtl'].print\:rtl\:mb-72 {
    margin-bottom: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:ml-72,[dir='rtl'].print\:rtl\:ml-72 {
    margin-left: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:mt-76,[dir='rtl'].print\:rtl\:mt-76 {
    margin-top: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:mr-76,[dir='rtl'].print\:rtl\:mr-76 {
    margin-right: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:mb-76,[dir='rtl'].print\:rtl\:mb-76 {
    margin-bottom: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:ml-76,[dir='rtl'].print\:rtl\:ml-76 {
    margin-left: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:mt-80,[dir='rtl'].print\:rtl\:mt-80 {
    margin-top: 8rem
  }

  [dir='rtl'] .print\:rtl\:mr-80,[dir='rtl'].print\:rtl\:mr-80 {
    margin-right: 8rem
  }

  [dir='rtl'] .print\:rtl\:mb-80,[dir='rtl'].print\:rtl\:mb-80 {
    margin-bottom: 8rem
  }

  [dir='rtl'] .print\:rtl\:ml-80,[dir='rtl'].print\:rtl\:ml-80 {
    margin-left: 8rem
  }

  [dir='rtl'] .print\:rtl\:mt-84,[dir='rtl'].print\:rtl\:mt-84 {
    margin-top: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:mr-84,[dir='rtl'].print\:rtl\:mr-84 {
    margin-right: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:mb-84,[dir='rtl'].print\:rtl\:mb-84 {
    margin-bottom: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:ml-84,[dir='rtl'].print\:rtl\:ml-84 {
    margin-left: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:mt-88,[dir='rtl'].print\:rtl\:mt-88 {
    margin-top: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:mr-88,[dir='rtl'].print\:rtl\:mr-88 {
    margin-right: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:mb-88,[dir='rtl'].print\:rtl\:mb-88 {
    margin-bottom: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:ml-88,[dir='rtl'].print\:rtl\:ml-88 {
    margin-left: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:mt-92,[dir='rtl'].print\:rtl\:mt-92 {
    margin-top: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:mr-92,[dir='rtl'].print\:rtl\:mr-92 {
    margin-right: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:mb-92,[dir='rtl'].print\:rtl\:mb-92 {
    margin-bottom: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:ml-92,[dir='rtl'].print\:rtl\:ml-92 {
    margin-left: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:mt-96,[dir='rtl'].print\:rtl\:mt-96 {
    margin-top: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:mr-96,[dir='rtl'].print\:rtl\:mr-96 {
    margin-right: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:mb-96,[dir='rtl'].print\:rtl\:mb-96 {
    margin-bottom: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:ml-96,[dir='rtl'].print\:rtl\:ml-96 {
    margin-left: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:mt-128,[dir='rtl'].print\:rtl\:mt-128 {
    margin-top: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:mr-128,[dir='rtl'].print\:rtl\:mr-128 {
    margin-right: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:mb-128,[dir='rtl'].print\:rtl\:mb-128 {
    margin-bottom: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:ml-128,[dir='rtl'].print\:rtl\:ml-128 {
    margin-left: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:mt-136,[dir='rtl'].print\:rtl\:mt-136 {
    margin-top: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:mr-136,[dir='rtl'].print\:rtl\:mr-136 {
    margin-right: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:mb-136,[dir='rtl'].print\:rtl\:mb-136 {
    margin-bottom: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:ml-136,[dir='rtl'].print\:rtl\:ml-136 {
    margin-left: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:mt-160,[dir='rtl'].print\:rtl\:mt-160 {
    margin-top: 16rem
  }

  [dir='rtl'] .print\:rtl\:mr-160,[dir='rtl'].print\:rtl\:mr-160 {
    margin-right: 16rem
  }

  [dir='rtl'] .print\:rtl\:mb-160,[dir='rtl'].print\:rtl\:mb-160 {
    margin-bottom: 16rem
  }

  [dir='rtl'] .print\:rtl\:ml-160,[dir='rtl'].print\:rtl\:ml-160 {
    margin-left: 16rem
  }

  [dir='rtl'] .print\:rtl\:mt-192,[dir='rtl'].print\:rtl\:mt-192 {
    margin-top: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:mr-192,[dir='rtl'].print\:rtl\:mr-192 {
    margin-right: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:mb-192,[dir='rtl'].print\:rtl\:mb-192 {
    margin-bottom: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:ml-192,[dir='rtl'].print\:rtl\:ml-192 {
    margin-left: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:mt-200,[dir='rtl'].print\:rtl\:mt-200 {
    margin-top: 20rem
  }

  [dir='rtl'] .print\:rtl\:mr-200,[dir='rtl'].print\:rtl\:mr-200 {
    margin-right: 20rem
  }

  [dir='rtl'] .print\:rtl\:mb-200,[dir='rtl'].print\:rtl\:mb-200 {
    margin-bottom: 20rem
  }

  [dir='rtl'] .print\:rtl\:ml-200,[dir='rtl'].print\:rtl\:ml-200 {
    margin-left: 20rem
  }

  [dir='rtl'] .print\:rtl\:mt-208,[dir='rtl'].print\:rtl\:mt-208 {
    margin-top: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:mr-208,[dir='rtl'].print\:rtl\:mr-208 {
    margin-right: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:mb-208,[dir='rtl'].print\:rtl\:mb-208 {
    margin-bottom: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:ml-208,[dir='rtl'].print\:rtl\:ml-208 {
    margin-left: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:mt-216,[dir='rtl'].print\:rtl\:mt-216 {
    margin-top: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:mr-216,[dir='rtl'].print\:rtl\:mr-216 {
    margin-right: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:mb-216,[dir='rtl'].print\:rtl\:mb-216 {
    margin-bottom: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:ml-216,[dir='rtl'].print\:rtl\:ml-216 {
    margin-left: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:mt-224,[dir='rtl'].print\:rtl\:mt-224 {
    margin-top: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:mr-224,[dir='rtl'].print\:rtl\:mr-224 {
    margin-right: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:mb-224,[dir='rtl'].print\:rtl\:mb-224 {
    margin-bottom: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:ml-224,[dir='rtl'].print\:rtl\:ml-224 {
    margin-left: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:mt-256,[dir='rtl'].print\:rtl\:mt-256 {
    margin-top: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:mr-256,[dir='rtl'].print\:rtl\:mr-256 {
    margin-right: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:mb-256,[dir='rtl'].print\:rtl\:mb-256 {
    margin-bottom: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:ml-256,[dir='rtl'].print\:rtl\:ml-256 {
    margin-left: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:mt-288,[dir='rtl'].print\:rtl\:mt-288 {
    margin-top: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:mr-288,[dir='rtl'].print\:rtl\:mr-288 {
    margin-right: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:mb-288,[dir='rtl'].print\:rtl\:mb-288 {
    margin-bottom: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:ml-288,[dir='rtl'].print\:rtl\:ml-288 {
    margin-left: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:mt-320,[dir='rtl'].print\:rtl\:mt-320 {
    margin-top: 32rem
  }

  [dir='rtl'] .print\:rtl\:mr-320,[dir='rtl'].print\:rtl\:mr-320 {
    margin-right: 32rem
  }

  [dir='rtl'] .print\:rtl\:mb-320,[dir='rtl'].print\:rtl\:mb-320 {
    margin-bottom: 32rem
  }

  [dir='rtl'] .print\:rtl\:ml-320,[dir='rtl'].print\:rtl\:ml-320 {
    margin-left: 32rem
  }

  [dir='rtl'] .print\:rtl\:mt-360,[dir='rtl'].print\:rtl\:mt-360 {
    margin-top: 36rem
  }

  [dir='rtl'] .print\:rtl\:mr-360,[dir='rtl'].print\:rtl\:mr-360 {
    margin-right: 36rem
  }

  [dir='rtl'] .print\:rtl\:mb-360,[dir='rtl'].print\:rtl\:mb-360 {
    margin-bottom: 36rem
  }

  [dir='rtl'] .print\:rtl\:ml-360,[dir='rtl'].print\:rtl\:ml-360 {
    margin-left: 36rem
  }

  [dir='rtl'] .print\:rtl\:mt-384,[dir='rtl'].print\:rtl\:mt-384 {
    margin-top: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:mr-384,[dir='rtl'].print\:rtl\:mr-384 {
    margin-right: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:mb-384,[dir='rtl'].print\:rtl\:mb-384 {
    margin-bottom: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:ml-384,[dir='rtl'].print\:rtl\:ml-384 {
    margin-left: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:mt-400,[dir='rtl'].print\:rtl\:mt-400 {
    margin-top: 40rem
  }

  [dir='rtl'] .print\:rtl\:mr-400,[dir='rtl'].print\:rtl\:mr-400 {
    margin-right: 40rem
  }

  [dir='rtl'] .print\:rtl\:mb-400,[dir='rtl'].print\:rtl\:mb-400 {
    margin-bottom: 40rem
  }

  [dir='rtl'] .print\:rtl\:ml-400,[dir='rtl'].print\:rtl\:ml-400 {
    margin-left: 40rem
  }

  [dir='rtl'] .print\:rtl\:mt-512,[dir='rtl'].print\:rtl\:mt-512 {
    margin-top: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:mr-512,[dir='rtl'].print\:rtl\:mr-512 {
    margin-right: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:mb-512,[dir='rtl'].print\:rtl\:mb-512 {
    margin-bottom: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:ml-512,[dir='rtl'].print\:rtl\:ml-512 {
    margin-left: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:mt-640,[dir='rtl'].print\:rtl\:mt-640 {
    margin-top: 64rem
  }

  [dir='rtl'] .print\:rtl\:mr-640,[dir='rtl'].print\:rtl\:mr-640 {
    margin-right: 64rem
  }

  [dir='rtl'] .print\:rtl\:mb-640,[dir='rtl'].print\:rtl\:mb-640 {
    margin-bottom: 64rem
  }

  [dir='rtl'] .print\:rtl\:ml-640,[dir='rtl'].print\:rtl\:ml-640 {
    margin-left: 64rem
  }

  [dir='rtl'] .print\:rtl\:mt-auto,[dir='rtl'].print\:rtl\:mt-auto {
    margin-top: auto
  }

  [dir='rtl'] .print\:rtl\:mr-auto,[dir='rtl'].print\:rtl\:mr-auto {
    margin-right: auto
  }

  [dir='rtl'] .print\:rtl\:mb-auto,[dir='rtl'].print\:rtl\:mb-auto {
    margin-bottom: auto
  }

  [dir='rtl'] .print\:rtl\:ml-auto,[dir='rtl'].print\:rtl\:ml-auto {
    margin-left: auto
  }

  [dir='rtl'] .print\:rtl\:mt-xs,[dir='rtl'].print\:rtl\:mt-xs {
    margin-top: 32rem
  }

  [dir='rtl'] .print\:rtl\:mr-xs,[dir='rtl'].print\:rtl\:mr-xs {
    margin-right: 32rem
  }

  [dir='rtl'] .print\:rtl\:mb-xs,[dir='rtl'].print\:rtl\:mb-xs {
    margin-bottom: 32rem
  }

  [dir='rtl'] .print\:rtl\:ml-xs,[dir='rtl'].print\:rtl\:ml-xs {
    margin-left: 32rem
  }

  [dir='rtl'] .print\:rtl\:mt-sm,[dir='rtl'].print\:rtl\:mt-sm {
    margin-top: 48rem
  }

  [dir='rtl'] .print\:rtl\:mr-sm,[dir='rtl'].print\:rtl\:mr-sm {
    margin-right: 48rem
  }

  [dir='rtl'] .print\:rtl\:mb-sm,[dir='rtl'].print\:rtl\:mb-sm {
    margin-bottom: 48rem
  }

  [dir='rtl'] .print\:rtl\:ml-sm,[dir='rtl'].print\:rtl\:ml-sm {
    margin-left: 48rem
  }

  [dir='rtl'] .print\:rtl\:mt-md,[dir='rtl'].print\:rtl\:mt-md {
    margin-top: 64rem
  }

  [dir='rtl'] .print\:rtl\:mr-md,[dir='rtl'].print\:rtl\:mr-md {
    margin-right: 64rem
  }

  [dir='rtl'] .print\:rtl\:mb-md,[dir='rtl'].print\:rtl\:mb-md {
    margin-bottom: 64rem
  }

  [dir='rtl'] .print\:rtl\:ml-md,[dir='rtl'].print\:rtl\:ml-md {
    margin-left: 64rem
  }

  [dir='rtl'] .print\:rtl\:mt-lg,[dir='rtl'].print\:rtl\:mt-lg {
    margin-top: 80rem
  }

  [dir='rtl'] .print\:rtl\:mr-lg,[dir='rtl'].print\:rtl\:mr-lg {
    margin-right: 80rem
  }

  [dir='rtl'] .print\:rtl\:mb-lg,[dir='rtl'].print\:rtl\:mb-lg {
    margin-bottom: 80rem
  }

  [dir='rtl'] .print\:rtl\:ml-lg,[dir='rtl'].print\:rtl\:ml-lg {
    margin-left: 80rem
  }

  [dir='rtl'] .print\:rtl\:mt-xl,[dir='rtl'].print\:rtl\:mt-xl {
    margin-top: 96rem
  }

  [dir='rtl'] .print\:rtl\:mr-xl,[dir='rtl'].print\:rtl\:mr-xl {
    margin-right: 96rem
  }

  [dir='rtl'] .print\:rtl\:mb-xl,[dir='rtl'].print\:rtl\:mb-xl {
    margin-bottom: 96rem
  }

  [dir='rtl'] .print\:rtl\:ml-xl,[dir='rtl'].print\:rtl\:ml-xl {
    margin-left: 96rem
  }

  [dir='rtl'] .print\:rtl\:mt-2xl,[dir='rtl'].print\:rtl\:mt-2xl {
    margin-top: 112rem
  }

  [dir='rtl'] .print\:rtl\:mr-2xl,[dir='rtl'].print\:rtl\:mr-2xl {
    margin-right: 112rem
  }

  [dir='rtl'] .print\:rtl\:mb-2xl,[dir='rtl'].print\:rtl\:mb-2xl {
    margin-bottom: 112rem
  }

  [dir='rtl'] .print\:rtl\:ml-2xl,[dir='rtl'].print\:rtl\:ml-2xl {
    margin-left: 112rem
  }

  [dir='rtl'] .print\:rtl\:mt-3xl,[dir='rtl'].print\:rtl\:mt-3xl {
    margin-top: 128rem
  }

  [dir='rtl'] .print\:rtl\:mr-3xl,[dir='rtl'].print\:rtl\:mr-3xl {
    margin-right: 128rem
  }

  [dir='rtl'] .print\:rtl\:mb-3xl,[dir='rtl'].print\:rtl\:mb-3xl {
    margin-bottom: 128rem
  }

  [dir='rtl'] .print\:rtl\:ml-3xl,[dir='rtl'].print\:rtl\:ml-3xl {
    margin-left: 128rem
  }

  [dir='rtl'] .print\:rtl\:mt-4xl,[dir='rtl'].print\:rtl\:mt-4xl {
    margin-top: 144rem
  }

  [dir='rtl'] .print\:rtl\:mr-4xl,[dir='rtl'].print\:rtl\:mr-4xl {
    margin-right: 144rem
  }

  [dir='rtl'] .print\:rtl\:mb-4xl,[dir='rtl'].print\:rtl\:mb-4xl {
    margin-bottom: 144rem
  }

  [dir='rtl'] .print\:rtl\:ml-4xl,[dir='rtl'].print\:rtl\:ml-4xl {
    margin-left: 144rem
  }

  [dir='rtl'] .print\:rtl\:mt-5xl,[dir='rtl'].print\:rtl\:mt-5xl {
    margin-top: 160rem
  }

  [dir='rtl'] .print\:rtl\:mr-5xl,[dir='rtl'].print\:rtl\:mr-5xl {
    margin-right: 160rem
  }

  [dir='rtl'] .print\:rtl\:mb-5xl,[dir='rtl'].print\:rtl\:mb-5xl {
    margin-bottom: 160rem
  }

  [dir='rtl'] .print\:rtl\:ml-5xl,[dir='rtl'].print\:rtl\:ml-5xl {
    margin-left: 160rem
  }

  [dir='rtl'] .print\:rtl\:mt-px,[dir='rtl'].print\:rtl\:mt-px {
    margin-top: 1px
  }

  [dir='rtl'] .print\:rtl\:mr-px,[dir='rtl'].print\:rtl\:mr-px {
    margin-right: 1px
  }

  [dir='rtl'] .print\:rtl\:mb-px,[dir='rtl'].print\:rtl\:mb-px {
    margin-bottom: 1px
  }

  [dir='rtl'] .print\:rtl\:ml-px,[dir='rtl'].print\:rtl\:ml-px {
    margin-left: 1px
  }

  [dir='rtl'] .print\:rtl\:mt-0\.5,[dir='rtl'].print\:rtl\:mt-0\.5 {
    margin-top: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:mr-0\.5,[dir='rtl'].print\:rtl\:mr-0\.5 {
    margin-right: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:mb-0\.5,[dir='rtl'].print\:rtl\:mb-0\.5 {
    margin-bottom: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:ml-0\.5,[dir='rtl'].print\:rtl\:ml-0\.5 {
    margin-left: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:mt-1\.5,[dir='rtl'].print\:rtl\:mt-1\.5 {
    margin-top: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:mr-1\.5,[dir='rtl'].print\:rtl\:mr-1\.5 {
    margin-right: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:mb-1\.5,[dir='rtl'].print\:rtl\:mb-1\.5 {
    margin-bottom: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:ml-1\.5,[dir='rtl'].print\:rtl\:ml-1\.5 {
    margin-left: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:mt-2\.5,[dir='rtl'].print\:rtl\:mt-2\.5 {
    margin-top: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:mr-2\.5,[dir='rtl'].print\:rtl\:mr-2\.5 {
    margin-right: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:mb-2\.5,[dir='rtl'].print\:rtl\:mb-2\.5 {
    margin-bottom: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:ml-2\.5,[dir='rtl'].print\:rtl\:ml-2\.5 {
    margin-left: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:mt-3\.5,[dir='rtl'].print\:rtl\:mt-3\.5 {
    margin-top: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:mr-3\.5,[dir='rtl'].print\:rtl\:mr-3\.5 {
    margin-right: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:mb-3\.5,[dir='rtl'].print\:rtl\:mb-3\.5 {
    margin-bottom: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:ml-3\.5,[dir='rtl'].print\:rtl\:ml-3\.5 {
    margin-left: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:-mt-1,[dir='rtl'].print\:rtl\:-mt-1 {
    margin-top: -0.1rem
  }

  [dir='rtl'] .print\:rtl\:-mr-1,[dir='rtl'].print\:rtl\:-mr-1 {
    margin-right: -0.1rem
  }

  [dir='rtl'] .print\:rtl\:-mb-1,[dir='rtl'].print\:rtl\:-mb-1 {
    margin-bottom: -0.1rem
  }

  [dir='rtl'] .print\:rtl\:-ml-1,[dir='rtl'].print\:rtl\:-ml-1 {
    margin-left: -0.1rem
  }

  [dir='rtl'] .print\:rtl\:-mt-2,[dir='rtl'].print\:rtl\:-mt-2 {
    margin-top: -0.2rem
  }

  [dir='rtl'] .print\:rtl\:-mr-2,[dir='rtl'].print\:rtl\:-mr-2 {
    margin-right: -0.2rem
  }

  [dir='rtl'] .print\:rtl\:-mb-2,[dir='rtl'].print\:rtl\:-mb-2 {
    margin-bottom: -0.2rem
  }

  [dir='rtl'] .print\:rtl\:-ml-2,[dir='rtl'].print\:rtl\:-ml-2 {
    margin-left: -0.2rem
  }

  [dir='rtl'] .print\:rtl\:-mt-3,[dir='rtl'].print\:rtl\:-mt-3 {
    margin-top: -0.3rem
  }

  [dir='rtl'] .print\:rtl\:-mr-3,[dir='rtl'].print\:rtl\:-mr-3 {
    margin-right: -0.3rem
  }

  [dir='rtl'] .print\:rtl\:-mb-3,[dir='rtl'].print\:rtl\:-mb-3 {
    margin-bottom: -0.3rem
  }

  [dir='rtl'] .print\:rtl\:-ml-3,[dir='rtl'].print\:rtl\:-ml-3 {
    margin-left: -0.3rem
  }

  [dir='rtl'] .print\:rtl\:-mt-4,[dir='rtl'].print\:rtl\:-mt-4 {
    margin-top: -0.4rem
  }

  [dir='rtl'] .print\:rtl\:-mr-4,[dir='rtl'].print\:rtl\:-mr-4 {
    margin-right: -0.4rem
  }

  [dir='rtl'] .print\:rtl\:-mb-4,[dir='rtl'].print\:rtl\:-mb-4 {
    margin-bottom: -0.4rem
  }

  [dir='rtl'] .print\:rtl\:-ml-4,[dir='rtl'].print\:rtl\:-ml-4 {
    margin-left: -0.4rem
  }

  [dir='rtl'] .print\:rtl\:-mt-5,[dir='rtl'].print\:rtl\:-mt-5 {
    margin-top: -0.5rem
  }

  [dir='rtl'] .print\:rtl\:-mr-5,[dir='rtl'].print\:rtl\:-mr-5 {
    margin-right: -0.5rem
  }

  [dir='rtl'] .print\:rtl\:-mb-5,[dir='rtl'].print\:rtl\:-mb-5 {
    margin-bottom: -0.5rem
  }

  [dir='rtl'] .print\:rtl\:-ml-5,[dir='rtl'].print\:rtl\:-ml-5 {
    margin-left: -0.5rem
  }

  [dir='rtl'] .print\:rtl\:-mt-6,[dir='rtl'].print\:rtl\:-mt-6 {
    margin-top: -0.6rem
  }

  [dir='rtl'] .print\:rtl\:-mr-6,[dir='rtl'].print\:rtl\:-mr-6 {
    margin-right: -0.6rem
  }

  [dir='rtl'] .print\:rtl\:-mb-6,[dir='rtl'].print\:rtl\:-mb-6 {
    margin-bottom: -0.6rem
  }

  [dir='rtl'] .print\:rtl\:-ml-6,[dir='rtl'].print\:rtl\:-ml-6 {
    margin-left: -0.6rem
  }

  [dir='rtl'] .print\:rtl\:-mt-7,[dir='rtl'].print\:rtl\:-mt-7 {
    margin-top: -0.7rem
  }

  [dir='rtl'] .print\:rtl\:-mr-7,[dir='rtl'].print\:rtl\:-mr-7 {
    margin-right: -0.7rem
  }

  [dir='rtl'] .print\:rtl\:-mb-7,[dir='rtl'].print\:rtl\:-mb-7 {
    margin-bottom: -0.7rem
  }

  [dir='rtl'] .print\:rtl\:-ml-7,[dir='rtl'].print\:rtl\:-ml-7 {
    margin-left: -0.7rem
  }

  [dir='rtl'] .print\:rtl\:-mt-8,[dir='rtl'].print\:rtl\:-mt-8 {
    margin-top: -0.8rem
  }

  [dir='rtl'] .print\:rtl\:-mr-8,[dir='rtl'].print\:rtl\:-mr-8 {
    margin-right: -0.8rem
  }

  [dir='rtl'] .print\:rtl\:-mb-8,[dir='rtl'].print\:rtl\:-mb-8 {
    margin-bottom: -0.8rem
  }

  [dir='rtl'] .print\:rtl\:-ml-8,[dir='rtl'].print\:rtl\:-ml-8 {
    margin-left: -0.8rem
  }

  [dir='rtl'] .print\:rtl\:-mt-9,[dir='rtl'].print\:rtl\:-mt-9 {
    margin-top: -0.9rem
  }

  [dir='rtl'] .print\:rtl\:-mr-9,[dir='rtl'].print\:rtl\:-mr-9 {
    margin-right: -0.9rem
  }

  [dir='rtl'] .print\:rtl\:-mb-9,[dir='rtl'].print\:rtl\:-mb-9 {
    margin-bottom: -0.9rem
  }

  [dir='rtl'] .print\:rtl\:-ml-9,[dir='rtl'].print\:rtl\:-ml-9 {
    margin-left: -0.9rem
  }

  [dir='rtl'] .print\:rtl\:-mt-10,[dir='rtl'].print\:rtl\:-mt-10 {
    margin-top: -1rem
  }

  [dir='rtl'] .print\:rtl\:-mr-10,[dir='rtl'].print\:rtl\:-mr-10 {
    margin-right: -1rem
  }

  [dir='rtl'] .print\:rtl\:-mb-10,[dir='rtl'].print\:rtl\:-mb-10 {
    margin-bottom: -1rem
  }

  [dir='rtl'] .print\:rtl\:-ml-10,[dir='rtl'].print\:rtl\:-ml-10 {
    margin-left: -1rem
  }

  [dir='rtl'] .print\:rtl\:-mt-12,[dir='rtl'].print\:rtl\:-mt-12 {
    margin-top: -1.2rem
  }

  [dir='rtl'] .print\:rtl\:-mr-12,[dir='rtl'].print\:rtl\:-mr-12 {
    margin-right: -1.2rem
  }

  [dir='rtl'] .print\:rtl\:-mb-12,[dir='rtl'].print\:rtl\:-mb-12 {
    margin-bottom: -1.2rem
  }

  [dir='rtl'] .print\:rtl\:-ml-12,[dir='rtl'].print\:rtl\:-ml-12 {
    margin-left: -1.2rem
  }

  [dir='rtl'] .print\:rtl\:-mt-14,[dir='rtl'].print\:rtl\:-mt-14 {
    margin-top: -1.4rem
  }

  [dir='rtl'] .print\:rtl\:-mr-14,[dir='rtl'].print\:rtl\:-mr-14 {
    margin-right: -1.4rem
  }

  [dir='rtl'] .print\:rtl\:-mb-14,[dir='rtl'].print\:rtl\:-mb-14 {
    margin-bottom: -1.4rem
  }

  [dir='rtl'] .print\:rtl\:-ml-14,[dir='rtl'].print\:rtl\:-ml-14 {
    margin-left: -1.4rem
  }

  [dir='rtl'] .print\:rtl\:-mt-16,[dir='rtl'].print\:rtl\:-mt-16 {
    margin-top: -1.6rem
  }

  [dir='rtl'] .print\:rtl\:-mr-16,[dir='rtl'].print\:rtl\:-mr-16 {
    margin-right: -1.6rem
  }

  [dir='rtl'] .print\:rtl\:-mb-16,[dir='rtl'].print\:rtl\:-mb-16 {
    margin-bottom: -1.6rem
  }

  [dir='rtl'] .print\:rtl\:-ml-16,[dir='rtl'].print\:rtl\:-ml-16 {
    margin-left: -1.6rem
  }

  [dir='rtl'] .print\:rtl\:-mt-20,[dir='rtl'].print\:rtl\:-mt-20 {
    margin-top: -2rem
  }

  [dir='rtl'] .print\:rtl\:-mr-20,[dir='rtl'].print\:rtl\:-mr-20 {
    margin-right: -2rem
  }

  [dir='rtl'] .print\:rtl\:-mb-20,[dir='rtl'].print\:rtl\:-mb-20 {
    margin-bottom: -2rem
  }

  [dir='rtl'] .print\:rtl\:-ml-20,[dir='rtl'].print\:rtl\:-ml-20 {
    margin-left: -2rem
  }

  [dir='rtl'] .print\:rtl\:-mt-24,[dir='rtl'].print\:rtl\:-mt-24 {
    margin-top: -2.4rem
  }

  [dir='rtl'] .print\:rtl\:-mr-24,[dir='rtl'].print\:rtl\:-mr-24 {
    margin-right: -2.4rem
  }

  [dir='rtl'] .print\:rtl\:-mb-24,[dir='rtl'].print\:rtl\:-mb-24 {
    margin-bottom: -2.4rem
  }

  [dir='rtl'] .print\:rtl\:-ml-24,[dir='rtl'].print\:rtl\:-ml-24 {
    margin-left: -2.4rem
  }

  [dir='rtl'] .print\:rtl\:-mt-28,[dir='rtl'].print\:rtl\:-mt-28 {
    margin-top: -2.8rem
  }

  [dir='rtl'] .print\:rtl\:-mr-28,[dir='rtl'].print\:rtl\:-mr-28 {
    margin-right: -2.8rem
  }

  [dir='rtl'] .print\:rtl\:-mb-28,[dir='rtl'].print\:rtl\:-mb-28 {
    margin-bottom: -2.8rem
  }

  [dir='rtl'] .print\:rtl\:-ml-28,[dir='rtl'].print\:rtl\:-ml-28 {
    margin-left: -2.8rem
  }

  [dir='rtl'] .print\:rtl\:-mt-32,[dir='rtl'].print\:rtl\:-mt-32 {
    margin-top: -3.2rem
  }

  [dir='rtl'] .print\:rtl\:-mr-32,[dir='rtl'].print\:rtl\:-mr-32 {
    margin-right: -3.2rem
  }

  [dir='rtl'] .print\:rtl\:-mb-32,[dir='rtl'].print\:rtl\:-mb-32 {
    margin-bottom: -3.2rem
  }

  [dir='rtl'] .print\:rtl\:-ml-32,[dir='rtl'].print\:rtl\:-ml-32 {
    margin-left: -3.2rem
  }

  [dir='rtl'] .print\:rtl\:-mt-36,[dir='rtl'].print\:rtl\:-mt-36 {
    margin-top: -3.6rem
  }

  [dir='rtl'] .print\:rtl\:-mr-36,[dir='rtl'].print\:rtl\:-mr-36 {
    margin-right: -3.6rem
  }

  [dir='rtl'] .print\:rtl\:-mb-36,[dir='rtl'].print\:rtl\:-mb-36 {
    margin-bottom: -3.6rem
  }

  [dir='rtl'] .print\:rtl\:-ml-36,[dir='rtl'].print\:rtl\:-ml-36 {
    margin-left: -3.6rem
  }

  [dir='rtl'] .print\:rtl\:-mt-40,[dir='rtl'].print\:rtl\:-mt-40 {
    margin-top: -4rem
  }

  [dir='rtl'] .print\:rtl\:-mr-40,[dir='rtl'].print\:rtl\:-mr-40 {
    margin-right: -4rem
  }

  [dir='rtl'] .print\:rtl\:-mb-40,[dir='rtl'].print\:rtl\:-mb-40 {
    margin-bottom: -4rem
  }

  [dir='rtl'] .print\:rtl\:-ml-40,[dir='rtl'].print\:rtl\:-ml-40 {
    margin-left: -4rem
  }

  [dir='rtl'] .print\:rtl\:-mt-44,[dir='rtl'].print\:rtl\:-mt-44 {
    margin-top: -4.4rem
  }

  [dir='rtl'] .print\:rtl\:-mr-44,[dir='rtl'].print\:rtl\:-mr-44 {
    margin-right: -4.4rem
  }

  [dir='rtl'] .print\:rtl\:-mb-44,[dir='rtl'].print\:rtl\:-mb-44 {
    margin-bottom: -4.4rem
  }

  [dir='rtl'] .print\:rtl\:-ml-44,[dir='rtl'].print\:rtl\:-ml-44 {
    margin-left: -4.4rem
  }

  [dir='rtl'] .print\:rtl\:-mt-48,[dir='rtl'].print\:rtl\:-mt-48 {
    margin-top: -4.8rem
  }

  [dir='rtl'] .print\:rtl\:-mr-48,[dir='rtl'].print\:rtl\:-mr-48 {
    margin-right: -4.8rem
  }

  [dir='rtl'] .print\:rtl\:-mb-48,[dir='rtl'].print\:rtl\:-mb-48 {
    margin-bottom: -4.8rem
  }

  [dir='rtl'] .print\:rtl\:-ml-48,[dir='rtl'].print\:rtl\:-ml-48 {
    margin-left: -4.8rem
  }

  [dir='rtl'] .print\:rtl\:-mt-52,[dir='rtl'].print\:rtl\:-mt-52 {
    margin-top: -5.2rem
  }

  [dir='rtl'] .print\:rtl\:-mr-52,[dir='rtl'].print\:rtl\:-mr-52 {
    margin-right: -5.2rem
  }

  [dir='rtl'] .print\:rtl\:-mb-52,[dir='rtl'].print\:rtl\:-mb-52 {
    margin-bottom: -5.2rem
  }

  [dir='rtl'] .print\:rtl\:-ml-52,[dir='rtl'].print\:rtl\:-ml-52 {
    margin-left: -5.2rem
  }

  [dir='rtl'] .print\:rtl\:-mt-56,[dir='rtl'].print\:rtl\:-mt-56 {
    margin-top: -5.6rem
  }

  [dir='rtl'] .print\:rtl\:-mr-56,[dir='rtl'].print\:rtl\:-mr-56 {
    margin-right: -5.6rem
  }

  [dir='rtl'] .print\:rtl\:-mb-56,[dir='rtl'].print\:rtl\:-mb-56 {
    margin-bottom: -5.6rem
  }

  [dir='rtl'] .print\:rtl\:-ml-56,[dir='rtl'].print\:rtl\:-ml-56 {
    margin-left: -5.6rem
  }

  [dir='rtl'] .print\:rtl\:-mt-60,[dir='rtl'].print\:rtl\:-mt-60 {
    margin-top: -6rem
  }

  [dir='rtl'] .print\:rtl\:-mr-60,[dir='rtl'].print\:rtl\:-mr-60 {
    margin-right: -6rem
  }

  [dir='rtl'] .print\:rtl\:-mb-60,[dir='rtl'].print\:rtl\:-mb-60 {
    margin-bottom: -6rem
  }

  [dir='rtl'] .print\:rtl\:-ml-60,[dir='rtl'].print\:rtl\:-ml-60 {
    margin-left: -6rem
  }

  [dir='rtl'] .print\:rtl\:-mt-64,[dir='rtl'].print\:rtl\:-mt-64 {
    margin-top: -6.4rem
  }

  [dir='rtl'] .print\:rtl\:-mr-64,[dir='rtl'].print\:rtl\:-mr-64 {
    margin-right: -6.4rem
  }

  [dir='rtl'] .print\:rtl\:-mb-64,[dir='rtl'].print\:rtl\:-mb-64 {
    margin-bottom: -6.4rem
  }

  [dir='rtl'] .print\:rtl\:-ml-64,[dir='rtl'].print\:rtl\:-ml-64 {
    margin-left: -6.4rem
  }

  [dir='rtl'] .print\:rtl\:-mt-68,[dir='rtl'].print\:rtl\:-mt-68 {
    margin-top: -6.8rem
  }

  [dir='rtl'] .print\:rtl\:-mr-68,[dir='rtl'].print\:rtl\:-mr-68 {
    margin-right: -6.8rem
  }

  [dir='rtl'] .print\:rtl\:-mb-68,[dir='rtl'].print\:rtl\:-mb-68 {
    margin-bottom: -6.8rem
  }

  [dir='rtl'] .print\:rtl\:-ml-68,[dir='rtl'].print\:rtl\:-ml-68 {
    margin-left: -6.8rem
  }

  [dir='rtl'] .print\:rtl\:-mt-72,[dir='rtl'].print\:rtl\:-mt-72 {
    margin-top: -7.2rem
  }

  [dir='rtl'] .print\:rtl\:-mr-72,[dir='rtl'].print\:rtl\:-mr-72 {
    margin-right: -7.2rem
  }

  [dir='rtl'] .print\:rtl\:-mb-72,[dir='rtl'].print\:rtl\:-mb-72 {
    margin-bottom: -7.2rem
  }

  [dir='rtl'] .print\:rtl\:-ml-72,[dir='rtl'].print\:rtl\:-ml-72 {
    margin-left: -7.2rem
  }

  [dir='rtl'] .print\:rtl\:-mt-76,[dir='rtl'].print\:rtl\:-mt-76 {
    margin-top: -7.6rem
  }

  [dir='rtl'] .print\:rtl\:-mr-76,[dir='rtl'].print\:rtl\:-mr-76 {
    margin-right: -7.6rem
  }

  [dir='rtl'] .print\:rtl\:-mb-76,[dir='rtl'].print\:rtl\:-mb-76 {
    margin-bottom: -7.6rem
  }

  [dir='rtl'] .print\:rtl\:-ml-76,[dir='rtl'].print\:rtl\:-ml-76 {
    margin-left: -7.6rem
  }

  [dir='rtl'] .print\:rtl\:-mt-80,[dir='rtl'].print\:rtl\:-mt-80 {
    margin-top: -8rem
  }

  [dir='rtl'] .print\:rtl\:-mr-80,[dir='rtl'].print\:rtl\:-mr-80 {
    margin-right: -8rem
  }

  [dir='rtl'] .print\:rtl\:-mb-80,[dir='rtl'].print\:rtl\:-mb-80 {
    margin-bottom: -8rem
  }

  [dir='rtl'] .print\:rtl\:-ml-80,[dir='rtl'].print\:rtl\:-ml-80 {
    margin-left: -8rem
  }

  [dir='rtl'] .print\:rtl\:-mt-84,[dir='rtl'].print\:rtl\:-mt-84 {
    margin-top: -8.4rem
  }

  [dir='rtl'] .print\:rtl\:-mr-84,[dir='rtl'].print\:rtl\:-mr-84 {
    margin-right: -8.4rem
  }

  [dir='rtl'] .print\:rtl\:-mb-84,[dir='rtl'].print\:rtl\:-mb-84 {
    margin-bottom: -8.4rem
  }

  [dir='rtl'] .print\:rtl\:-ml-84,[dir='rtl'].print\:rtl\:-ml-84 {
    margin-left: -8.4rem
  }

  [dir='rtl'] .print\:rtl\:-mt-88,[dir='rtl'].print\:rtl\:-mt-88 {
    margin-top: -8.8rem
  }

  [dir='rtl'] .print\:rtl\:-mr-88,[dir='rtl'].print\:rtl\:-mr-88 {
    margin-right: -8.8rem
  }

  [dir='rtl'] .print\:rtl\:-mb-88,[dir='rtl'].print\:rtl\:-mb-88 {
    margin-bottom: -8.8rem
  }

  [dir='rtl'] .print\:rtl\:-ml-88,[dir='rtl'].print\:rtl\:-ml-88 {
    margin-left: -8.8rem
  }

  [dir='rtl'] .print\:rtl\:-mt-92,[dir='rtl'].print\:rtl\:-mt-92 {
    margin-top: -9.2rem
  }

  [dir='rtl'] .print\:rtl\:-mr-92,[dir='rtl'].print\:rtl\:-mr-92 {
    margin-right: -9.2rem
  }

  [dir='rtl'] .print\:rtl\:-mb-92,[dir='rtl'].print\:rtl\:-mb-92 {
    margin-bottom: -9.2rem
  }

  [dir='rtl'] .print\:rtl\:-ml-92,[dir='rtl'].print\:rtl\:-ml-92 {
    margin-left: -9.2rem
  }

  [dir='rtl'] .print\:rtl\:-mt-96,[dir='rtl'].print\:rtl\:-mt-96 {
    margin-top: -9.6rem
  }

  [dir='rtl'] .print\:rtl\:-mr-96,[dir='rtl'].print\:rtl\:-mr-96 {
    margin-right: -9.6rem
  }

  [dir='rtl'] .print\:rtl\:-mb-96,[dir='rtl'].print\:rtl\:-mb-96 {
    margin-bottom: -9.6rem
  }

  [dir='rtl'] .print\:rtl\:-ml-96,[dir='rtl'].print\:rtl\:-ml-96 {
    margin-left: -9.6rem
  }

  [dir='rtl'] .print\:rtl\:-mt-128,[dir='rtl'].print\:rtl\:-mt-128 {
    margin-top: -12.8rem
  }

  [dir='rtl'] .print\:rtl\:-mr-128,[dir='rtl'].print\:rtl\:-mr-128 {
    margin-right: -12.8rem
  }

  [dir='rtl'] .print\:rtl\:-mb-128,[dir='rtl'].print\:rtl\:-mb-128 {
    margin-bottom: -12.8rem
  }

  [dir='rtl'] .print\:rtl\:-ml-128,[dir='rtl'].print\:rtl\:-ml-128 {
    margin-left: -12.8rem
  }

  [dir='rtl'] .print\:rtl\:-mt-136,[dir='rtl'].print\:rtl\:-mt-136 {
    margin-top: -13.6rem
  }

  [dir='rtl'] .print\:rtl\:-mr-136,[dir='rtl'].print\:rtl\:-mr-136 {
    margin-right: -13.6rem
  }

  [dir='rtl'] .print\:rtl\:-mb-136,[dir='rtl'].print\:rtl\:-mb-136 {
    margin-bottom: -13.6rem
  }

  [dir='rtl'] .print\:rtl\:-ml-136,[dir='rtl'].print\:rtl\:-ml-136 {
    margin-left: -13.6rem
  }

  [dir='rtl'] .print\:rtl\:-mt-160,[dir='rtl'].print\:rtl\:-mt-160 {
    margin-top: -16rem
  }

  [dir='rtl'] .print\:rtl\:-mr-160,[dir='rtl'].print\:rtl\:-mr-160 {
    margin-right: -16rem
  }

  [dir='rtl'] .print\:rtl\:-mb-160,[dir='rtl'].print\:rtl\:-mb-160 {
    margin-bottom: -16rem
  }

  [dir='rtl'] .print\:rtl\:-ml-160,[dir='rtl'].print\:rtl\:-ml-160 {
    margin-left: -16rem
  }

  [dir='rtl'] .print\:rtl\:-mt-192,[dir='rtl'].print\:rtl\:-mt-192 {
    margin-top: -19.2rem
  }

  [dir='rtl'] .print\:rtl\:-mr-192,[dir='rtl'].print\:rtl\:-mr-192 {
    margin-right: -19.2rem
  }

  [dir='rtl'] .print\:rtl\:-mb-192,[dir='rtl'].print\:rtl\:-mb-192 {
    margin-bottom: -19.2rem
  }

  [dir='rtl'] .print\:rtl\:-ml-192,[dir='rtl'].print\:rtl\:-ml-192 {
    margin-left: -19.2rem
  }

  [dir='rtl'] .print\:rtl\:-mt-200,[dir='rtl'].print\:rtl\:-mt-200 {
    margin-top: -20rem
  }

  [dir='rtl'] .print\:rtl\:-mr-200,[dir='rtl'].print\:rtl\:-mr-200 {
    margin-right: -20rem
  }

  [dir='rtl'] .print\:rtl\:-mb-200,[dir='rtl'].print\:rtl\:-mb-200 {
    margin-bottom: -20rem
  }

  [dir='rtl'] .print\:rtl\:-ml-200,[dir='rtl'].print\:rtl\:-ml-200 {
    margin-left: -20rem
  }

  [dir='rtl'] .print\:rtl\:-mt-208,[dir='rtl'].print\:rtl\:-mt-208 {
    margin-top: -20.8rem
  }

  [dir='rtl'] .print\:rtl\:-mr-208,[dir='rtl'].print\:rtl\:-mr-208 {
    margin-right: -20.8rem
  }

  [dir='rtl'] .print\:rtl\:-mb-208,[dir='rtl'].print\:rtl\:-mb-208 {
    margin-bottom: -20.8rem
  }

  [dir='rtl'] .print\:rtl\:-ml-208,[dir='rtl'].print\:rtl\:-ml-208 {
    margin-left: -20.8rem
  }

  [dir='rtl'] .print\:rtl\:-mt-216,[dir='rtl'].print\:rtl\:-mt-216 {
    margin-top: -21.6rem
  }

  [dir='rtl'] .print\:rtl\:-mr-216,[dir='rtl'].print\:rtl\:-mr-216 {
    margin-right: -21.6rem
  }

  [dir='rtl'] .print\:rtl\:-mb-216,[dir='rtl'].print\:rtl\:-mb-216 {
    margin-bottom: -21.6rem
  }

  [dir='rtl'] .print\:rtl\:-ml-216,[dir='rtl'].print\:rtl\:-ml-216 {
    margin-left: -21.6rem
  }

  [dir='rtl'] .print\:rtl\:-mt-224,[dir='rtl'].print\:rtl\:-mt-224 {
    margin-top: -22.4rem
  }

  [dir='rtl'] .print\:rtl\:-mr-224,[dir='rtl'].print\:rtl\:-mr-224 {
    margin-right: -22.4rem
  }

  [dir='rtl'] .print\:rtl\:-mb-224,[dir='rtl'].print\:rtl\:-mb-224 {
    margin-bottom: -22.4rem
  }

  [dir='rtl'] .print\:rtl\:-ml-224,[dir='rtl'].print\:rtl\:-ml-224 {
    margin-left: -22.4rem
  }

  [dir='rtl'] .print\:rtl\:-mt-256,[dir='rtl'].print\:rtl\:-mt-256 {
    margin-top: -25.6rem
  }

  [dir='rtl'] .print\:rtl\:-mr-256,[dir='rtl'].print\:rtl\:-mr-256 {
    margin-right: -25.6rem
  }

  [dir='rtl'] .print\:rtl\:-mb-256,[dir='rtl'].print\:rtl\:-mb-256 {
    margin-bottom: -25.6rem
  }

  [dir='rtl'] .print\:rtl\:-ml-256,[dir='rtl'].print\:rtl\:-ml-256 {
    margin-left: -25.6rem
  }

  [dir='rtl'] .print\:rtl\:-mt-288,[dir='rtl'].print\:rtl\:-mt-288 {
    margin-top: -28.8rem
  }

  [dir='rtl'] .print\:rtl\:-mr-288,[dir='rtl'].print\:rtl\:-mr-288 {
    margin-right: -28.8rem
  }

  [dir='rtl'] .print\:rtl\:-mb-288,[dir='rtl'].print\:rtl\:-mb-288 {
    margin-bottom: -28.8rem
  }

  [dir='rtl'] .print\:rtl\:-ml-288,[dir='rtl'].print\:rtl\:-ml-288 {
    margin-left: -28.8rem
  }

  [dir='rtl'] .print\:rtl\:-mt-320,[dir='rtl'].print\:rtl\:-mt-320 {
    margin-top: -32rem
  }

  [dir='rtl'] .print\:rtl\:-mr-320,[dir='rtl'].print\:rtl\:-mr-320 {
    margin-right: -32rem
  }

  [dir='rtl'] .print\:rtl\:-mb-320,[dir='rtl'].print\:rtl\:-mb-320 {
    margin-bottom: -32rem
  }

  [dir='rtl'] .print\:rtl\:-ml-320,[dir='rtl'].print\:rtl\:-ml-320 {
    margin-left: -32rem
  }

  [dir='rtl'] .print\:rtl\:-mt-360,[dir='rtl'].print\:rtl\:-mt-360 {
    margin-top: -36rem
  }

  [dir='rtl'] .print\:rtl\:-mr-360,[dir='rtl'].print\:rtl\:-mr-360 {
    margin-right: -36rem
  }

  [dir='rtl'] .print\:rtl\:-mb-360,[dir='rtl'].print\:rtl\:-mb-360 {
    margin-bottom: -36rem
  }

  [dir='rtl'] .print\:rtl\:-ml-360,[dir='rtl'].print\:rtl\:-ml-360 {
    margin-left: -36rem
  }

  [dir='rtl'] .print\:rtl\:-mt-384,[dir='rtl'].print\:rtl\:-mt-384 {
    margin-top: -38.4rem
  }

  [dir='rtl'] .print\:rtl\:-mr-384,[dir='rtl'].print\:rtl\:-mr-384 {
    margin-right: -38.4rem
  }

  [dir='rtl'] .print\:rtl\:-mb-384,[dir='rtl'].print\:rtl\:-mb-384 {
    margin-bottom: -38.4rem
  }

  [dir='rtl'] .print\:rtl\:-ml-384,[dir='rtl'].print\:rtl\:-ml-384 {
    margin-left: -38.4rem
  }

  [dir='rtl'] .print\:rtl\:-mt-400,[dir='rtl'].print\:rtl\:-mt-400 {
    margin-top: -40rem
  }

  [dir='rtl'] .print\:rtl\:-mr-400,[dir='rtl'].print\:rtl\:-mr-400 {
    margin-right: -40rem
  }

  [dir='rtl'] .print\:rtl\:-mb-400,[dir='rtl'].print\:rtl\:-mb-400 {
    margin-bottom: -40rem
  }

  [dir='rtl'] .print\:rtl\:-ml-400,[dir='rtl'].print\:rtl\:-ml-400 {
    margin-left: -40rem
  }

  [dir='rtl'] .print\:rtl\:-mt-512,[dir='rtl'].print\:rtl\:-mt-512 {
    margin-top: -51.2rem
  }

  [dir='rtl'] .print\:rtl\:-mr-512,[dir='rtl'].print\:rtl\:-mr-512 {
    margin-right: -51.2rem
  }

  [dir='rtl'] .print\:rtl\:-mb-512,[dir='rtl'].print\:rtl\:-mb-512 {
    margin-bottom: -51.2rem
  }

  [dir='rtl'] .print\:rtl\:-ml-512,[dir='rtl'].print\:rtl\:-ml-512 {
    margin-left: -51.2rem
  }

  [dir='rtl'] .print\:rtl\:-mt-640,[dir='rtl'].print\:rtl\:-mt-640 {
    margin-top: -64rem
  }

  [dir='rtl'] .print\:rtl\:-mr-640,[dir='rtl'].print\:rtl\:-mr-640 {
    margin-right: -64rem
  }

  [dir='rtl'] .print\:rtl\:-mb-640,[dir='rtl'].print\:rtl\:-mb-640 {
    margin-bottom: -64rem
  }

  [dir='rtl'] .print\:rtl\:-ml-640,[dir='rtl'].print\:rtl\:-ml-640 {
    margin-left: -64rem
  }

  [dir='rtl'] .print\:rtl\:-mt-xs,[dir='rtl'].print\:rtl\:-mt-xs {
    margin-top: -32rem
  }

  [dir='rtl'] .print\:rtl\:-mr-xs,[dir='rtl'].print\:rtl\:-mr-xs {
    margin-right: -32rem
  }

  [dir='rtl'] .print\:rtl\:-mb-xs,[dir='rtl'].print\:rtl\:-mb-xs {
    margin-bottom: -32rem
  }

  [dir='rtl'] .print\:rtl\:-ml-xs,[dir='rtl'].print\:rtl\:-ml-xs {
    margin-left: -32rem
  }

  [dir='rtl'] .print\:rtl\:-mt-sm,[dir='rtl'].print\:rtl\:-mt-sm {
    margin-top: -48rem
  }

  [dir='rtl'] .print\:rtl\:-mr-sm,[dir='rtl'].print\:rtl\:-mr-sm {
    margin-right: -48rem
  }

  [dir='rtl'] .print\:rtl\:-mb-sm,[dir='rtl'].print\:rtl\:-mb-sm {
    margin-bottom: -48rem
  }

  [dir='rtl'] .print\:rtl\:-ml-sm,[dir='rtl'].print\:rtl\:-ml-sm {
    margin-left: -48rem
  }

  [dir='rtl'] .print\:rtl\:-mt-md,[dir='rtl'].print\:rtl\:-mt-md {
    margin-top: -64rem
  }

  [dir='rtl'] .print\:rtl\:-mr-md,[dir='rtl'].print\:rtl\:-mr-md {
    margin-right: -64rem
  }

  [dir='rtl'] .print\:rtl\:-mb-md,[dir='rtl'].print\:rtl\:-mb-md {
    margin-bottom: -64rem
  }

  [dir='rtl'] .print\:rtl\:-ml-md,[dir='rtl'].print\:rtl\:-ml-md {
    margin-left: -64rem
  }

  [dir='rtl'] .print\:rtl\:-mt-lg,[dir='rtl'].print\:rtl\:-mt-lg {
    margin-top: -80rem
  }

  [dir='rtl'] .print\:rtl\:-mr-lg,[dir='rtl'].print\:rtl\:-mr-lg {
    margin-right: -80rem
  }

  [dir='rtl'] .print\:rtl\:-mb-lg,[dir='rtl'].print\:rtl\:-mb-lg {
    margin-bottom: -80rem
  }

  [dir='rtl'] .print\:rtl\:-ml-lg,[dir='rtl'].print\:rtl\:-ml-lg {
    margin-left: -80rem
  }

  [dir='rtl'] .print\:rtl\:-mt-xl,[dir='rtl'].print\:rtl\:-mt-xl {
    margin-top: -96rem
  }

  [dir='rtl'] .print\:rtl\:-mr-xl,[dir='rtl'].print\:rtl\:-mr-xl {
    margin-right: -96rem
  }

  [dir='rtl'] .print\:rtl\:-mb-xl,[dir='rtl'].print\:rtl\:-mb-xl {
    margin-bottom: -96rem
  }

  [dir='rtl'] .print\:rtl\:-ml-xl,[dir='rtl'].print\:rtl\:-ml-xl {
    margin-left: -96rem
  }

  [dir='rtl'] .print\:rtl\:-mt-2xl,[dir='rtl'].print\:rtl\:-mt-2xl {
    margin-top: -112rem
  }

  [dir='rtl'] .print\:rtl\:-mr-2xl,[dir='rtl'].print\:rtl\:-mr-2xl {
    margin-right: -112rem
  }

  [dir='rtl'] .print\:rtl\:-mb-2xl,[dir='rtl'].print\:rtl\:-mb-2xl {
    margin-bottom: -112rem
  }

  [dir='rtl'] .print\:rtl\:-ml-2xl,[dir='rtl'].print\:rtl\:-ml-2xl {
    margin-left: -112rem
  }

  [dir='rtl'] .print\:rtl\:-mt-3xl,[dir='rtl'].print\:rtl\:-mt-3xl {
    margin-top: -128rem
  }

  [dir='rtl'] .print\:rtl\:-mr-3xl,[dir='rtl'].print\:rtl\:-mr-3xl {
    margin-right: -128rem
  }

  [dir='rtl'] .print\:rtl\:-mb-3xl,[dir='rtl'].print\:rtl\:-mb-3xl {
    margin-bottom: -128rem
  }

  [dir='rtl'] .print\:rtl\:-ml-3xl,[dir='rtl'].print\:rtl\:-ml-3xl {
    margin-left: -128rem
  }

  [dir='rtl'] .print\:rtl\:-mt-4xl,[dir='rtl'].print\:rtl\:-mt-4xl {
    margin-top: -144rem
  }

  [dir='rtl'] .print\:rtl\:-mr-4xl,[dir='rtl'].print\:rtl\:-mr-4xl {
    margin-right: -144rem
  }

  [dir='rtl'] .print\:rtl\:-mb-4xl,[dir='rtl'].print\:rtl\:-mb-4xl {
    margin-bottom: -144rem
  }

  [dir='rtl'] .print\:rtl\:-ml-4xl,[dir='rtl'].print\:rtl\:-ml-4xl {
    margin-left: -144rem
  }

  [dir='rtl'] .print\:rtl\:-mt-5xl,[dir='rtl'].print\:rtl\:-mt-5xl {
    margin-top: -160rem
  }

  [dir='rtl'] .print\:rtl\:-mr-5xl,[dir='rtl'].print\:rtl\:-mr-5xl {
    margin-right: -160rem
  }

  [dir='rtl'] .print\:rtl\:-mb-5xl,[dir='rtl'].print\:rtl\:-mb-5xl {
    margin-bottom: -160rem
  }

  [dir='rtl'] .print\:rtl\:-ml-5xl,[dir='rtl'].print\:rtl\:-ml-5xl {
    margin-left: -160rem
  }

  [dir='rtl'] .print\:rtl\:-mt-px,[dir='rtl'].print\:rtl\:-mt-px {
    margin-top: -1px
  }

  [dir='rtl'] .print\:rtl\:-mr-px,[dir='rtl'].print\:rtl\:-mr-px {
    margin-right: -1px
  }

  [dir='rtl'] .print\:rtl\:-mb-px,[dir='rtl'].print\:rtl\:-mb-px {
    margin-bottom: -1px
  }

  [dir='rtl'] .print\:rtl\:-ml-px,[dir='rtl'].print\:rtl\:-ml-px {
    margin-left: -1px
  }

  [dir='rtl'] .print\:rtl\:-mt-0\.5,[dir='rtl'].print\:rtl\:-mt-0\.5 {
    margin-top: -0.05rem
  }

  [dir='rtl'] .print\:rtl\:-mr-0\.5,[dir='rtl'].print\:rtl\:-mr-0\.5 {
    margin-right: -0.05rem
  }

  [dir='rtl'] .print\:rtl\:-mb-0\.5,[dir='rtl'].print\:rtl\:-mb-0\.5 {
    margin-bottom: -0.05rem
  }

  [dir='rtl'] .print\:rtl\:-ml-0\.5,[dir='rtl'].print\:rtl\:-ml-0\.5 {
    margin-left: -0.05rem
  }

  [dir='rtl'] .print\:rtl\:-mt-1\.5,[dir='rtl'].print\:rtl\:-mt-1\.5 {
    margin-top: -0.15rem
  }

  [dir='rtl'] .print\:rtl\:-mr-1\.5,[dir='rtl'].print\:rtl\:-mr-1\.5 {
    margin-right: -0.15rem
  }

  [dir='rtl'] .print\:rtl\:-mb-1\.5,[dir='rtl'].print\:rtl\:-mb-1\.5 {
    margin-bottom: -0.15rem
  }

  [dir='rtl'] .print\:rtl\:-ml-1\.5,[dir='rtl'].print\:rtl\:-ml-1\.5 {
    margin-left: -0.15rem
  }

  [dir='rtl'] .print\:rtl\:-mt-2\.5,[dir='rtl'].print\:rtl\:-mt-2\.5 {
    margin-top: -0.25rem
  }

  [dir='rtl'] .print\:rtl\:-mr-2\.5,[dir='rtl'].print\:rtl\:-mr-2\.5 {
    margin-right: -0.25rem
  }

  [dir='rtl'] .print\:rtl\:-mb-2\.5,[dir='rtl'].print\:rtl\:-mb-2\.5 {
    margin-bottom: -0.25rem
  }

  [dir='rtl'] .print\:rtl\:-ml-2\.5,[dir='rtl'].print\:rtl\:-ml-2\.5 {
    margin-left: -0.25rem
  }

  [dir='rtl'] .print\:rtl\:-mt-3\.5,[dir='rtl'].print\:rtl\:-mt-3\.5 {
    margin-top: -0.35rem
  }

  [dir='rtl'] .print\:rtl\:-mr-3\.5,[dir='rtl'].print\:rtl\:-mr-3\.5 {
    margin-right: -0.35rem
  }

  [dir='rtl'] .print\:rtl\:-mb-3\.5,[dir='rtl'].print\:rtl\:-mb-3\.5 {
    margin-bottom: -0.35rem
  }

  [dir='rtl'] .print\:rtl\:-ml-3\.5,[dir='rtl'].print\:rtl\:-ml-3\.5 {
    margin-left: -0.35rem
  }

  .print\:max-h-0 {
    max-height: 0
  }

  .print\:max-h-1 {
    max-height: 0.1rem
  }

  .print\:max-h-2 {
    max-height: 0.2rem
  }

  .print\:max-h-3 {
    max-height: 0.3rem
  }

  .print\:max-h-4 {
    max-height: 0.4rem
  }

  .print\:max-h-5 {
    max-height: 0.5rem
  }

  .print\:max-h-6 {
    max-height: 0.6rem
  }

  .print\:max-h-7 {
    max-height: 0.7rem
  }

  .print\:max-h-8 {
    max-height: 0.8rem
  }

  .print\:max-h-9 {
    max-height: 0.9rem
  }

  .print\:max-h-10 {
    max-height: 1.0rem
  }

  .print\:max-h-12 {
    max-height: 1.2rem
  }

  .print\:max-h-14 {
    max-height: 1.4rem
  }

  .print\:max-h-16 {
    max-height: 1.6rem
  }

  .print\:max-h-20 {
    max-height: 2rem
  }

  .print\:max-h-24 {
    max-height: 2.4rem
  }

  .print\:max-h-28 {
    max-height: 2.8rem
  }

  .print\:max-h-32 {
    max-height: 3.2rem
  }

  .print\:max-h-36 {
    max-height: 3.6rem
  }

  .print\:max-h-40 {
    max-height: 4rem
  }

  .print\:max-h-44 {
    max-height: 4.4rem
  }

  .print\:max-h-48 {
    max-height: 4.8rem
  }

  .print\:max-h-52 {
    max-height: 5.2rem
  }

  .print\:max-h-56 {
    max-height: 5.6rem
  }

  .print\:max-h-60 {
    max-height: 6rem
  }

  .print\:max-h-64 {
    max-height: 6.4rem
  }

  .print\:max-h-68 {
    max-height: 6.8rem
  }

  .print\:max-h-72 {
    max-height: 7.2rem
  }

  .print\:max-h-76 {
    max-height: 7.6rem
  }

  .print\:max-h-80 {
    max-height: 8rem
  }

  .print\:max-h-84 {
    max-height: 8.4rem
  }

  .print\:max-h-88 {
    max-height: 8.8rem
  }

  .print\:max-h-92 {
    max-height: 9.2rem
  }

  .print\:max-h-96 {
    max-height: 9.6rem
  }

  .print\:max-h-128 {
    max-height: 12.8rem
  }

  .print\:max-h-136 {
    max-height: 13.6rem
  }

  .print\:max-h-160 {
    max-height: 16rem
  }

  .print\:max-h-192 {
    max-height: 19.2rem
  }

  .print\:max-h-200 {
    max-height: 20rem
  }

  .print\:max-h-208 {
    max-height: 20.8rem
  }

  .print\:max-h-216 {
    max-height: 21.6rem
  }

  .print\:max-h-224 {
    max-height: 22.4rem
  }

  .print\:max-h-256 {
    max-height: 25.6rem
  }

  .print\:max-h-288 {
    max-height: 28.8rem
  }

  .print\:max-h-320 {
    max-height: 32rem
  }

  .print\:max-h-360 {
    max-height: 36rem
  }

  .print\:max-h-384 {
    max-height: 38.4rem
  }

  .print\:max-h-400 {
    max-height: 40rem
  }

  .print\:max-h-512 {
    max-height: 51.2rem
  }

  .print\:max-h-640 {
    max-height: 64rem
  }

  .print\:max-h-xs {
    max-height: 32rem
  }

  .print\:max-h-sm {
    max-height: 48rem
  }

  .print\:max-h-md {
    max-height: 64rem
  }

  .print\:max-h-lg {
    max-height: 80rem
  }

  .print\:max-h-xl {
    max-height: 96rem
  }

  .print\:max-h-2xl {
    max-height: 112rem
  }

  .print\:max-h-3xl {
    max-height: 128rem
  }

  .print\:max-h-4xl {
    max-height: 144rem
  }

  .print\:max-h-5xl {
    max-height: 160rem
  }

  .print\:max-h-px {
    max-height: 1px
  }

  .print\:max-h-0\.5 {
    max-height: 0.05rem
  }

  .print\:max-h-1\.5 {
    max-height: 0.15rem
  }

  .print\:max-h-2\.5 {
    max-height: 0.25rem
  }

  .print\:max-h-3\.5 {
    max-height: 0.35rem
  }

  .print\:max-h-full {
    max-height: 100%
  }

  .print\:max-h-screen {
    max-height: 100vh
  }

  .print\:max-h-auto {
    max-height: auto
  }

  .print\:max-w-0 {
    max-width: 0
  }

  .print\:max-w-1 {
    max-width: 0.1rem
  }

  .print\:max-w-2 {
    max-width: 0.2rem
  }

  .print\:max-w-3 {
    max-width: 0.3rem
  }

  .print\:max-w-4 {
    max-width: 0.4rem
  }

  .print\:max-w-5 {
    max-width: 0.5rem
  }

  .print\:max-w-6 {
    max-width: 0.6rem
  }

  .print\:max-w-7 {
    max-width: 0.7rem
  }

  .print\:max-w-8 {
    max-width: 0.8rem
  }

  .print\:max-w-9 {
    max-width: 0.9rem
  }

  .print\:max-w-10 {
    max-width: 1.0rem
  }

  .print\:max-w-12 {
    max-width: 1.2rem
  }

  .print\:max-w-14 {
    max-width: 1.4rem
  }

  .print\:max-w-16 {
    max-width: 1.6rem
  }

  .print\:max-w-20 {
    max-width: 2rem
  }

  .print\:max-w-24 {
    max-width: 2.4rem
  }

  .print\:max-w-28 {
    max-width: 2.8rem
  }

  .print\:max-w-32 {
    max-width: 3.2rem
  }

  .print\:max-w-36 {
    max-width: 3.6rem
  }

  .print\:max-w-40 {
    max-width: 4rem
  }

  .print\:max-w-44 {
    max-width: 4.4rem
  }

  .print\:max-w-48 {
    max-width: 4.8rem
  }

  .print\:max-w-52 {
    max-width: 5.2rem
  }

  .print\:max-w-56 {
    max-width: 5.6rem
  }

  .print\:max-w-60 {
    max-width: 6rem
  }

  .print\:max-w-64 {
    max-width: 6.4rem
  }

  .print\:max-w-68 {
    max-width: 6.8rem
  }

  .print\:max-w-72 {
    max-width: 7.2rem
  }

  .print\:max-w-76 {
    max-width: 7.6rem
  }

  .print\:max-w-80 {
    max-width: 8rem
  }

  .print\:max-w-84 {
    max-width: 8.4rem
  }

  .print\:max-w-88 {
    max-width: 8.8rem
  }

  .print\:max-w-92 {
    max-width: 9.2rem
  }

  .print\:max-w-96 {
    max-width: 9.6rem
  }

  .print\:max-w-128 {
    max-width: 12.8rem
  }

  .print\:max-w-136 {
    max-width: 13.6rem
  }

  .print\:max-w-160 {
    max-width: 16rem
  }

  .print\:max-w-192 {
    max-width: 19.2rem
  }

  .print\:max-w-200 {
    max-width: 20rem
  }

  .print\:max-w-208 {
    max-width: 20.8rem
  }

  .print\:max-w-216 {
    max-width: 21.6rem
  }

  .print\:max-w-224 {
    max-width: 22.4rem
  }

  .print\:max-w-256 {
    max-width: 25.6rem
  }

  .print\:max-w-288 {
    max-width: 28.8rem
  }

  .print\:max-w-320 {
    max-width: 32rem
  }

  .print\:max-w-360 {
    max-width: 36rem
  }

  .print\:max-w-384 {
    max-width: 38.4rem
  }

  .print\:max-w-400 {
    max-width: 40rem
  }

  .print\:max-w-512 {
    max-width: 51.2rem
  }

  .print\:max-w-640 {
    max-width: 64rem
  }

  .print\:max-w-none {
    max-width: none
  }

  .print\:max-w-xs {
    max-width: 32rem
  }

  .print\:max-w-sm {
    max-width: 48rem
  }

  .print\:max-w-md {
    max-width: 64rem
  }

  .print\:max-w-lg {
    max-width: 80rem
  }

  .print\:max-w-xl {
    max-width: 96rem
  }

  .print\:max-w-2xl {
    max-width: 112rem
  }

  .print\:max-w-3xl {
    max-width: 128rem
  }

  .print\:max-w-4xl {
    max-width: 144rem
  }

  .print\:max-w-5xl {
    max-width: 160rem
  }

  .print\:max-w-px {
    max-width: 1px
  }

  .print\:max-w-0\.5 {
    max-width: 0.05rem
  }

  .print\:max-w-1\.5 {
    max-width: 0.15rem
  }

  .print\:max-w-2\.5 {
    max-width: 0.25rem
  }

  .print\:max-w-3\.5 {
    max-width: 0.35rem
  }

  .print\:max-w-full {
    max-width: 100%
  }

  .print\:max-w-min {
    max-width: min-content
  }

  .print\:max-w-max {
    max-width: max-content
  }

  .print\:max-w-prose {
    max-width: 65ch
  }

  .print\:max-w-screen-sm {
    max-width: 600px
  }

  .print\:max-w-screen-md {
    max-width: 960px
  }

  .print\:max-w-screen-lg {
    max-width: 1280px
  }

  .print\:max-w-screen-xl {
    max-width: 1920px
  }

  .print\:min-h-0 {
    min-height: 0
  }

  .print\:min-h-1 {
    min-height: 0.1rem
  }

  .print\:min-h-2 {
    min-height: 0.2rem
  }

  .print\:min-h-3 {
    min-height: 0.3rem
  }

  .print\:min-h-4 {
    min-height: 0.4rem
  }

  .print\:min-h-5 {
    min-height: 0.5rem
  }

  .print\:min-h-6 {
    min-height: 0.6rem
  }

  .print\:min-h-7 {
    min-height: 0.7rem
  }

  .print\:min-h-8 {
    min-height: 0.8rem
  }

  .print\:min-h-9 {
    min-height: 0.9rem
  }

  .print\:min-h-10 {
    min-height: 1.0rem
  }

  .print\:min-h-12 {
    min-height: 1.2rem
  }

  .print\:min-h-14 {
    min-height: 1.4rem
  }

  .print\:min-h-16 {
    min-height: 1.6rem
  }

  .print\:min-h-20 {
    min-height: 2rem
  }

  .print\:min-h-24 {
    min-height: 2.4rem
  }

  .print\:min-h-28 {
    min-height: 2.8rem
  }

  .print\:min-h-32 {
    min-height: 3.2rem
  }

  .print\:min-h-36 {
    min-height: 3.6rem
  }

  .print\:min-h-40 {
    min-height: 4rem
  }

  .print\:min-h-44 {
    min-height: 4.4rem
  }

  .print\:min-h-48 {
    min-height: 4.8rem
  }

  .print\:min-h-52 {
    min-height: 5.2rem
  }

  .print\:min-h-56 {
    min-height: 5.6rem
  }

  .print\:min-h-60 {
    min-height: 6rem
  }

  .print\:min-h-64 {
    min-height: 6.4rem
  }

  .print\:min-h-68 {
    min-height: 6.8rem
  }

  .print\:min-h-72 {
    min-height: 7.2rem
  }

  .print\:min-h-76 {
    min-height: 7.6rem
  }

  .print\:min-h-80 {
    min-height: 8rem
  }

  .print\:min-h-84 {
    min-height: 8.4rem
  }

  .print\:min-h-88 {
    min-height: 8.8rem
  }

  .print\:min-h-92 {
    min-height: 9.2rem
  }

  .print\:min-h-96 {
    min-height: 9.6rem
  }

  .print\:min-h-128 {
    min-height: 12.8rem
  }

  .print\:min-h-136 {
    min-height: 13.6rem
  }

  .print\:min-h-160 {
    min-height: 16rem
  }

  .print\:min-h-192 {
    min-height: 19.2rem
  }

  .print\:min-h-200 {
    min-height: 20rem
  }

  .print\:min-h-208 {
    min-height: 20.8rem
  }

  .print\:min-h-216 {
    min-height: 21.6rem
  }

  .print\:min-h-224 {
    min-height: 22.4rem
  }

  .print\:min-h-256 {
    min-height: 25.6rem
  }

  .print\:min-h-288 {
    min-height: 28.8rem
  }

  .print\:min-h-320 {
    min-height: 32rem
  }

  .print\:min-h-360 {
    min-height: 36rem
  }

  .print\:min-h-384 {
    min-height: 38.4rem
  }

  .print\:min-h-400 {
    min-height: 40rem
  }

  .print\:min-h-512 {
    min-height: 51.2rem
  }

  .print\:min-h-640 {
    min-height: 64rem
  }

  .print\:min-h-auto {
    min-height: auto
  }

  .print\:min-h-xs {
    min-height: 32rem
  }

  .print\:min-h-sm {
    min-height: 48rem
  }

  .print\:min-h-md {
    min-height: 64rem
  }

  .print\:min-h-lg {
    min-height: 80rem
  }

  .print\:min-h-xl {
    min-height: 96rem
  }

  .print\:min-h-2xl {
    min-height: 112rem
  }

  .print\:min-h-3xl {
    min-height: 128rem
  }

  .print\:min-h-4xl {
    min-height: 144rem
  }

  .print\:min-h-5xl {
    min-height: 160rem
  }

  .print\:min-h-px {
    min-height: 1px
  }

  .print\:min-h-0\.5 {
    min-height: 0.05rem
  }

  .print\:min-h-1\.5 {
    min-height: 0.15rem
  }

  .print\:min-h-2\.5 {
    min-height: 0.25rem
  }

  .print\:min-h-3\.5 {
    min-height: 0.35rem
  }

  .print\:min-h-full {
    min-height: 100%
  }

  .print\:min-h-screen {
    min-height: 100vh
  }

  .print\:min-w-0 {
    min-width: 0
  }

  .print\:min-w-1 {
    min-width: 0.1rem
  }

  .print\:min-w-2 {
    min-width: 0.2rem
  }

  .print\:min-w-3 {
    min-width: 0.3rem
  }

  .print\:min-w-4 {
    min-width: 0.4rem
  }

  .print\:min-w-5 {
    min-width: 0.5rem
  }

  .print\:min-w-6 {
    min-width: 0.6rem
  }

  .print\:min-w-7 {
    min-width: 0.7rem
  }

  .print\:min-w-8 {
    min-width: 0.8rem
  }

  .print\:min-w-9 {
    min-width: 0.9rem
  }

  .print\:min-w-10 {
    min-width: 1.0rem
  }

  .print\:min-w-12 {
    min-width: 1.2rem
  }

  .print\:min-w-14 {
    min-width: 1.4rem
  }

  .print\:min-w-16 {
    min-width: 1.6rem
  }

  .print\:min-w-20 {
    min-width: 2rem
  }

  .print\:min-w-24 {
    min-width: 2.4rem
  }

  .print\:min-w-28 {
    min-width: 2.8rem
  }

  .print\:min-w-32 {
    min-width: 3.2rem
  }

  .print\:min-w-36 {
    min-width: 3.6rem
  }

  .print\:min-w-40 {
    min-width: 4rem
  }

  .print\:min-w-44 {
    min-width: 4.4rem
  }

  .print\:min-w-48 {
    min-width: 4.8rem
  }

  .print\:min-w-52 {
    min-width: 5.2rem
  }

  .print\:min-w-56 {
    min-width: 5.6rem
  }

  .print\:min-w-60 {
    min-width: 6rem
  }

  .print\:min-w-64 {
    min-width: 6.4rem
  }

  .print\:min-w-68 {
    min-width: 6.8rem
  }

  .print\:min-w-72 {
    min-width: 7.2rem
  }

  .print\:min-w-76 {
    min-width: 7.6rem
  }

  .print\:min-w-80 {
    min-width: 8rem
  }

  .print\:min-w-84 {
    min-width: 8.4rem
  }

  .print\:min-w-88 {
    min-width: 8.8rem
  }

  .print\:min-w-92 {
    min-width: 9.2rem
  }

  .print\:min-w-96 {
    min-width: 9.6rem
  }

  .print\:min-w-128 {
    min-width: 12.8rem
  }

  .print\:min-w-136 {
    min-width: 13.6rem
  }

  .print\:min-w-160 {
    min-width: 16rem
  }

  .print\:min-w-192 {
    min-width: 19.2rem
  }

  .print\:min-w-200 {
    min-width: 20rem
  }

  .print\:min-w-208 {
    min-width: 20.8rem
  }

  .print\:min-w-216 {
    min-width: 21.6rem
  }

  .print\:min-w-224 {
    min-width: 22.4rem
  }

  .print\:min-w-256 {
    min-width: 25.6rem
  }

  .print\:min-w-288 {
    min-width: 28.8rem
  }

  .print\:min-w-320 {
    min-width: 32rem
  }

  .print\:min-w-360 {
    min-width: 36rem
  }

  .print\:min-w-384 {
    min-width: 38.4rem
  }

  .print\:min-w-400 {
    min-width: 40rem
  }

  .print\:min-w-512 {
    min-width: 51.2rem
  }

  .print\:min-w-640 {
    min-width: 64rem
  }

  .print\:min-w-xs {
    min-width: 32rem
  }

  .print\:min-w-sm {
    min-width: 48rem
  }

  .print\:min-w-md {
    min-width: 64rem
  }

  .print\:min-w-lg {
    min-width: 80rem
  }

  .print\:min-w-xl {
    min-width: 96rem
  }

  .print\:min-w-2xl {
    min-width: 112rem
  }

  .print\:min-w-3xl {
    min-width: 128rem
  }

  .print\:min-w-4xl {
    min-width: 144rem
  }

  .print\:min-w-5xl {
    min-width: 160rem
  }

  .print\:min-w-px {
    min-width: 1px
  }

  .print\:min-w-0\.5 {
    min-width: 0.05rem
  }

  .print\:min-w-1\.5 {
    min-width: 0.15rem
  }

  .print\:min-w-2\.5 {
    min-width: 0.25rem
  }

  .print\:min-w-3\.5 {
    min-width: 0.35rem
  }

  .print\:min-w-full {
    min-width: 100%
  }

  .print\:min-w-min {
    min-width: min-content
  }

  .print\:min-w-max {
    min-width: max-content
  }

  .print\:min-w-screen {
    min-width: 100vw
  }

  .print\:object-contain {
    object-fit: contain
  }

  .print\:object-cover {
    object-fit: cover
  }

  .print\:object-fill {
    object-fit: fill
  }

  .print\:object-none {
    object-fit: none
  }

  .print\:object-scale-down {
    object-fit: scale-down
  }

  .print\:object-bottom {
    object-position: bottom
  }

  .print\:object-center {
    object-position: center
  }

  .print\:object-left {
    object-position: left
  }

  .print\:object-left-bottom {
    object-position: left bottom
  }

  .print\:object-left-top {
    object-position: left top
  }

  .print\:object-right {
    object-position: right
  }

  .print\:object-right-bottom {
    object-position: right bottom
  }

  .print\:object-right-top {
    object-position: right top
  }

  .print\:object-top {
    object-position: top
  }

  .print\:opacity-0 {
    opacity: 0
  }

  .print\:opacity-5 {
    opacity: 0.05
  }

  .print\:opacity-10 {
    opacity: 0.1
  }

  .print\:opacity-20 {
    opacity: 0.2
  }

  .print\:opacity-25 {
    opacity: 0.25
  }

  .print\:opacity-30 {
    opacity: 0.3
  }

  .print\:opacity-40 {
    opacity: 0.4
  }

  .print\:opacity-50 {
    opacity: 0.5
  }

  .print\:opacity-60 {
    opacity: 0.6
  }

  .print\:opacity-70 {
    opacity: 0.7
  }

  .print\:opacity-75 {
    opacity: 0.75
  }

  .print\:opacity-80 {
    opacity: 0.8
  }

  .print\:opacity-90 {
    opacity: 0.9
  }

  .print\:opacity-95 {
    opacity: 0.95
  }

  .print\:opacity-100 {
    opacity: 1
  }

  .group:hover .print\:group-hover\:opacity-0 {
    opacity: 0
  }

  .group:hover .print\:group-hover\:opacity-5 {
    opacity: 0.05
  }

  .group:hover .print\:group-hover\:opacity-10 {
    opacity: 0.1
  }

  .group:hover .print\:group-hover\:opacity-20 {
    opacity: 0.2
  }

  .group:hover .print\:group-hover\:opacity-25 {
    opacity: 0.25
  }

  .group:hover .print\:group-hover\:opacity-30 {
    opacity: 0.3
  }

  .group:hover .print\:group-hover\:opacity-40 {
    opacity: 0.4
  }

  .group:hover .print\:group-hover\:opacity-50 {
    opacity: 0.5
  }

  .group:hover .print\:group-hover\:opacity-60 {
    opacity: 0.6
  }

  .group:hover .print\:group-hover\:opacity-70 {
    opacity: 0.7
  }

  .group:hover .print\:group-hover\:opacity-75 {
    opacity: 0.75
  }

  .group:hover .print\:group-hover\:opacity-80 {
    opacity: 0.8
  }

  .group:hover .print\:group-hover\:opacity-90 {
    opacity: 0.9
  }

  .group:hover .print\:group-hover\:opacity-95 {
    opacity: 0.95
  }

  .group:hover .print\:group-hover\:opacity-100 {
    opacity: 1
  }

  .print\:focus-within\:opacity-0:focus-within {
    opacity: 0
  }

  .print\:focus-within\:opacity-5:focus-within {
    opacity: 0.05
  }

  .print\:focus-within\:opacity-10:focus-within {
    opacity: 0.1
  }

  .print\:focus-within\:opacity-20:focus-within {
    opacity: 0.2
  }

  .print\:focus-within\:opacity-25:focus-within {
    opacity: 0.25
  }

  .print\:focus-within\:opacity-30:focus-within {
    opacity: 0.3
  }

  .print\:focus-within\:opacity-40:focus-within {
    opacity: 0.4
  }

  .print\:focus-within\:opacity-50:focus-within {
    opacity: 0.5
  }

  .print\:focus-within\:opacity-60:focus-within {
    opacity: 0.6
  }

  .print\:focus-within\:opacity-70:focus-within {
    opacity: 0.7
  }

  .print\:focus-within\:opacity-75:focus-within {
    opacity: 0.75
  }

  .print\:focus-within\:opacity-80:focus-within {
    opacity: 0.8
  }

  .print\:focus-within\:opacity-90:focus-within {
    opacity: 0.9
  }

  .print\:focus-within\:opacity-95:focus-within {
    opacity: 0.95
  }

  .print\:focus-within\:opacity-100:focus-within {
    opacity: 1
  }

  .print\:hover\:opacity-0:hover {
    opacity: 0
  }

  .print\:hover\:opacity-5:hover {
    opacity: 0.05
  }

  .print\:hover\:opacity-10:hover {
    opacity: 0.1
  }

  .print\:hover\:opacity-20:hover {
    opacity: 0.2
  }

  .print\:hover\:opacity-25:hover {
    opacity: 0.25
  }

  .print\:hover\:opacity-30:hover {
    opacity: 0.3
  }

  .print\:hover\:opacity-40:hover {
    opacity: 0.4
  }

  .print\:hover\:opacity-50:hover {
    opacity: 0.5
  }

  .print\:hover\:opacity-60:hover {
    opacity: 0.6
  }

  .print\:hover\:opacity-70:hover {
    opacity: 0.7
  }

  .print\:hover\:opacity-75:hover {
    opacity: 0.75
  }

  .print\:hover\:opacity-80:hover {
    opacity: 0.8
  }

  .print\:hover\:opacity-90:hover {
    opacity: 0.9
  }

  .print\:hover\:opacity-95:hover {
    opacity: 0.95
  }

  .print\:hover\:opacity-100:hover {
    opacity: 1
  }

  .print\:focus\:opacity-0:focus {
    opacity: 0
  }

  .print\:focus\:opacity-5:focus {
    opacity: 0.05
  }

  .print\:focus\:opacity-10:focus {
    opacity: 0.1
  }

  .print\:focus\:opacity-20:focus {
    opacity: 0.2
  }

  .print\:focus\:opacity-25:focus {
    opacity: 0.25
  }

  .print\:focus\:opacity-30:focus {
    opacity: 0.3
  }

  .print\:focus\:opacity-40:focus {
    opacity: 0.4
  }

  .print\:focus\:opacity-50:focus {
    opacity: 0.5
  }

  .print\:focus\:opacity-60:focus {
    opacity: 0.6
  }

  .print\:focus\:opacity-70:focus {
    opacity: 0.7
  }

  .print\:focus\:opacity-75:focus {
    opacity: 0.75
  }

  .print\:focus\:opacity-80:focus {
    opacity: 0.8
  }

  .print\:focus\:opacity-90:focus {
    opacity: 0.9
  }

  .print\:focus\:opacity-95:focus {
    opacity: 0.95
  }

  .print\:focus\:opacity-100:focus {
    opacity: 1
  }

  .print\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .print\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .print\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .print\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .print\:focus-within\:outline-white:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .print\:focus-within\:outline-black:focus-within {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .print\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .print\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .print\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .print\:overflow-auto {
    overflow: auto
  }

  .print\:overflow-hidden {
    overflow: hidden
  }

  .print\:overflow-visible {
    overflow: visible
  }

  .print\:overflow-scroll {
    overflow: scroll
  }

  .print\:overflow-x-auto {
    overflow-x: auto
  }

  .print\:overflow-y-auto {
    overflow-y: auto
  }

  .print\:overflow-x-hidden {
    overflow-x: hidden
  }

  .print\:overflow-y-hidden {
    overflow-y: hidden
  }

  .print\:overflow-x-visible {
    overflow-x: visible
  }

  .print\:overflow-y-visible {
    overflow-y: visible
  }

  .print\:overflow-x-scroll {
    overflow-x: scroll
  }

  .print\:overflow-y-scroll {
    overflow-y: scroll
  }

  .print\:overscroll-auto {
    overscroll-behavior: auto
  }

  .print\:overscroll-contain {
    overscroll-behavior: contain
  }

  .print\:overscroll-none {
    overscroll-behavior: none
  }

  .print\:overscroll-y-auto {
    overscroll-behavior-y: auto
  }

  .print\:overscroll-y-contain {
    overscroll-behavior-y: contain
  }

  .print\:overscroll-y-none {
    overscroll-behavior-y: none
  }

  .print\:overscroll-x-auto {
    overscroll-behavior-x: auto
  }

  .print\:overscroll-x-contain {
    overscroll-behavior-x: contain
  }

  .print\:overscroll-x-none {
    overscroll-behavior-x: none
  }

  .print\:p-0 {
    padding: 0
  }

  .print\:p-1 {
    padding: 0.1rem
  }

  .print\:p-2 {
    padding: 0.2rem
  }

  .print\:p-3 {
    padding: 0.3rem
  }

  .print\:p-4 {
    padding: 0.4rem
  }

  .print\:p-5 {
    padding: 0.5rem
  }

  .print\:p-6 {
    padding: 0.6rem
  }

  .print\:p-7 {
    padding: 0.7rem
  }

  .print\:p-8 {
    padding: 0.8rem
  }

  .print\:p-9 {
    padding: 0.9rem
  }

  .print\:p-10 {
    padding: 1.0rem
  }

  .print\:p-12 {
    padding: 1.2rem
  }

  .print\:p-14 {
    padding: 1.4rem
  }

  .print\:p-16 {
    padding: 1.6rem
  }

  .print\:p-20 {
    padding: 2rem
  }

  .print\:p-24 {
    padding: 2.4rem
  }

  .print\:p-28 {
    padding: 2.8rem
  }

  .print\:p-32 {
    padding: 3.2rem
  }

  .print\:p-36 {
    padding: 3.6rem
  }

  .print\:p-40 {
    padding: 4rem
  }

  .print\:p-44 {
    padding: 4.4rem
  }

  .print\:p-48 {
    padding: 4.8rem
  }

  .print\:p-52 {
    padding: 5.2rem
  }

  .print\:p-56 {
    padding: 5.6rem
  }

  .print\:p-60 {
    padding: 6rem
  }

  .print\:p-64 {
    padding: 6.4rem
  }

  .print\:p-68 {
    padding: 6.8rem
  }

  .print\:p-72 {
    padding: 7.2rem
  }

  .print\:p-76 {
    padding: 7.6rem
  }

  .print\:p-80 {
    padding: 8rem
  }

  .print\:p-84 {
    padding: 8.4rem
  }

  .print\:p-88 {
    padding: 8.8rem
  }

  .print\:p-92 {
    padding: 9.2rem
  }

  .print\:p-96 {
    padding: 9.6rem
  }

  .print\:p-128 {
    padding: 12.8rem
  }

  .print\:p-136 {
    padding: 13.6rem
  }

  .print\:p-160 {
    padding: 16rem
  }

  .print\:p-192 {
    padding: 19.2rem
  }

  .print\:p-200 {
    padding: 20rem
  }

  .print\:p-208 {
    padding: 20.8rem
  }

  .print\:p-216 {
    padding: 21.6rem
  }

  .print\:p-224 {
    padding: 22.4rem
  }

  .print\:p-256 {
    padding: 25.6rem
  }

  .print\:p-288 {
    padding: 28.8rem
  }

  .print\:p-320 {
    padding: 32rem
  }

  .print\:p-360 {
    padding: 36rem
  }

  .print\:p-384 {
    padding: 38.4rem
  }

  .print\:p-400 {
    padding: 40rem
  }

  .print\:p-512 {
    padding: 51.2rem
  }

  .print\:p-640 {
    padding: 64rem
  }

  .print\:p-xs {
    padding: 32rem
  }

  .print\:p-sm {
    padding: 48rem
  }

  .print\:p-md {
    padding: 64rem
  }

  .print\:p-lg {
    padding: 80rem
  }

  .print\:p-xl {
    padding: 96rem
  }

  .print\:p-2xl {
    padding: 112rem
  }

  .print\:p-3xl {
    padding: 128rem
  }

  .print\:p-4xl {
    padding: 144rem
  }

  .print\:p-5xl {
    padding: 160rem
  }

  .print\:p-px {
    padding: 1px
  }

  .print\:p-0\.5 {
    padding: 0.05rem
  }

  .print\:p-1\.5 {
    padding: 0.15rem
  }

  .print\:p-2\.5 {
    padding: 0.25rem
  }

  .print\:p-3\.5 {
    padding: 0.35rem
  }

  .print\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .print\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .print\:py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem
  }

  .print\:px-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem
  }

  .print\:py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem
  }

  .print\:px-2 {
    padding-left: 0.2rem;
    padding-right: 0.2rem
  }

  .print\:py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem
  }

  .print\:px-3 {
    padding-left: 0.3rem;
    padding-right: 0.3rem
  }

  .print\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  .print\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  .print\:py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .print\:px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .print\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  .print\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  .print\:py-7 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem
  }

  .print\:px-7 {
    padding-left: 0.7rem;
    padding-right: 0.7rem
  }

  .print\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  .print\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  .print\:py-9 {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem
  }

  .print\:px-9 {
    padding-left: 0.9rem;
    padding-right: 0.9rem
  }

  .print\:py-10 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
  }

  .print\:px-10 {
    padding-left: 1.0rem;
    padding-right: 1.0rem
  }

  .print\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  .print\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .print\:py-14 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem
  }

  .print\:px-14 {
    padding-left: 1.4rem;
    padding-right: 1.4rem
  }

  .print\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  .print\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  .print\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .print\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .print\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  .print\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .print\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  .print\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  .print\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  .print\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  .print\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  .print\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  .print\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .print\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .print\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  .print\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  .print\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  .print\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  .print\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  .print\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  .print\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  .print\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  .print\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .print\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .print\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  .print\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  .print\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  .print\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  .print\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  .print\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  .print\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  .print\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  .print\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .print\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .print\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  .print\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  .print\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  .print\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  .print\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  .print\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  .print\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  .print\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  .print\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  .print\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  .print\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  .print\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  .print\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .print\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .print\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  .print\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  .print\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  .print\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  .print\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  .print\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  .print\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  .print\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  .print\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  .print\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  .print\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  .print\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  .print\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  .print\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  .print\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  .print\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  .print\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  .print\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  .print\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  .print\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  .print\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  .print\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  .print\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  .print\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  .print\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  .print\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  .print\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  .print\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  .print\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  .print\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  .print\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  .print\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  .print\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  .print\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  .print\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  .print\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  .print\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  .print\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  .print\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  .print\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  .print\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  .print\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  .print\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  .print\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  .print\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .print\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .print\:py-0\.5 {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem
  }

  .print\:px-0\.5 {
    padding-left: 0.05rem;
    padding-right: 0.05rem
  }

  .print\:py-1\.5 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem
  }

  .print\:px-1\.5 {
    padding-left: 0.15rem;
    padding-right: 0.15rem
  }

  .print\:py-2\.5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .print\:px-2\.5 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .print\:py-3\.5 {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem
  }

  .print\:px-3\.5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem
  }

  .print\:pt-0 {
    padding-top: 0
  }

  .print\:pr-0 {
    padding-right: 0
  }

  .print\:pb-0 {
    padding-bottom: 0
  }

  .print\:pl-0 {
    padding-left: 0
  }

  .print\:pt-1 {
    padding-top: 0.1rem
  }

  .print\:pr-1 {
    padding-right: 0.1rem
  }

  .print\:pb-1 {
    padding-bottom: 0.1rem
  }

  .print\:pl-1 {
    padding-left: 0.1rem
  }

  .print\:pt-2 {
    padding-top: 0.2rem
  }

  .print\:pr-2 {
    padding-right: 0.2rem
  }

  .print\:pb-2 {
    padding-bottom: 0.2rem
  }

  .print\:pl-2 {
    padding-left: 0.2rem
  }

  .print\:pt-3 {
    padding-top: 0.3rem
  }

  .print\:pr-3 {
    padding-right: 0.3rem
  }

  .print\:pb-3 {
    padding-bottom: 0.3rem
  }

  .print\:pl-3 {
    padding-left: 0.3rem
  }

  .print\:pt-4 {
    padding-top: 0.4rem
  }

  .print\:pr-4 {
    padding-right: 0.4rem
  }

  .print\:pb-4 {
    padding-bottom: 0.4rem
  }

  .print\:pl-4 {
    padding-left: 0.4rem
  }

  .print\:pt-5 {
    padding-top: 0.5rem
  }

  .print\:pr-5 {
    padding-right: 0.5rem
  }

  .print\:pb-5 {
    padding-bottom: 0.5rem
  }

  .print\:pl-5 {
    padding-left: 0.5rem
  }

  .print\:pt-6 {
    padding-top: 0.6rem
  }

  .print\:pr-6 {
    padding-right: 0.6rem
  }

  .print\:pb-6 {
    padding-bottom: 0.6rem
  }

  .print\:pl-6 {
    padding-left: 0.6rem
  }

  .print\:pt-7 {
    padding-top: 0.7rem
  }

  .print\:pr-7 {
    padding-right: 0.7rem
  }

  .print\:pb-7 {
    padding-bottom: 0.7rem
  }

  .print\:pl-7 {
    padding-left: 0.7rem
  }

  .print\:pt-8 {
    padding-top: 0.8rem
  }

  .print\:pr-8 {
    padding-right: 0.8rem
  }

  .print\:pb-8 {
    padding-bottom: 0.8rem
  }

  .print\:pl-8 {
    padding-left: 0.8rem
  }

  .print\:pt-9 {
    padding-top: 0.9rem
  }

  .print\:pr-9 {
    padding-right: 0.9rem
  }

  .print\:pb-9 {
    padding-bottom: 0.9rem
  }

  .print\:pl-9 {
    padding-left: 0.9rem
  }

  .print\:pt-10 {
    padding-top: 1.0rem
  }

  .print\:pr-10 {
    padding-right: 1.0rem
  }

  .print\:pb-10 {
    padding-bottom: 1.0rem
  }

  .print\:pl-10 {
    padding-left: 1.0rem
  }

  .print\:pt-12 {
    padding-top: 1.2rem
  }

  .print\:pr-12 {
    padding-right: 1.2rem
  }

  .print\:pb-12 {
    padding-bottom: 1.2rem
  }

  .print\:pl-12 {
    padding-left: 1.2rem
  }

  .print\:pt-14 {
    padding-top: 1.4rem
  }

  .print\:pr-14 {
    padding-right: 1.4rem
  }

  .print\:pb-14 {
    padding-bottom: 1.4rem
  }

  .print\:pl-14 {
    padding-left: 1.4rem
  }

  .print\:pt-16 {
    padding-top: 1.6rem
  }

  .print\:pr-16 {
    padding-right: 1.6rem
  }

  .print\:pb-16 {
    padding-bottom: 1.6rem
  }

  .print\:pl-16 {
    padding-left: 1.6rem
  }

  .print\:pt-20 {
    padding-top: 2rem
  }

  .print\:pr-20 {
    padding-right: 2rem
  }

  .print\:pb-20 {
    padding-bottom: 2rem
  }

  .print\:pl-20 {
    padding-left: 2rem
  }

  .print\:pt-24 {
    padding-top: 2.4rem
  }

  .print\:pr-24 {
    padding-right: 2.4rem
  }

  .print\:pb-24 {
    padding-bottom: 2.4rem
  }

  .print\:pl-24 {
    padding-left: 2.4rem
  }

  .print\:pt-28 {
    padding-top: 2.8rem
  }

  .print\:pr-28 {
    padding-right: 2.8rem
  }

  .print\:pb-28 {
    padding-bottom: 2.8rem
  }

  .print\:pl-28 {
    padding-left: 2.8rem
  }

  .print\:pt-32 {
    padding-top: 3.2rem
  }

  .print\:pr-32 {
    padding-right: 3.2rem
  }

  .print\:pb-32 {
    padding-bottom: 3.2rem
  }

  .print\:pl-32 {
    padding-left: 3.2rem
  }

  .print\:pt-36 {
    padding-top: 3.6rem
  }

  .print\:pr-36 {
    padding-right: 3.6rem
  }

  .print\:pb-36 {
    padding-bottom: 3.6rem
  }

  .print\:pl-36 {
    padding-left: 3.6rem
  }

  .print\:pt-40 {
    padding-top: 4rem
  }

  .print\:pr-40 {
    padding-right: 4rem
  }

  .print\:pb-40 {
    padding-bottom: 4rem
  }

  .print\:pl-40 {
    padding-left: 4rem
  }

  .print\:pt-44 {
    padding-top: 4.4rem
  }

  .print\:pr-44 {
    padding-right: 4.4rem
  }

  .print\:pb-44 {
    padding-bottom: 4.4rem
  }

  .print\:pl-44 {
    padding-left: 4.4rem
  }

  .print\:pt-48 {
    padding-top: 4.8rem
  }

  .print\:pr-48 {
    padding-right: 4.8rem
  }

  .print\:pb-48 {
    padding-bottom: 4.8rem
  }

  .print\:pl-48 {
    padding-left: 4.8rem
  }

  .print\:pt-52 {
    padding-top: 5.2rem
  }

  .print\:pr-52 {
    padding-right: 5.2rem
  }

  .print\:pb-52 {
    padding-bottom: 5.2rem
  }

  .print\:pl-52 {
    padding-left: 5.2rem
  }

  .print\:pt-56 {
    padding-top: 5.6rem
  }

  .print\:pr-56 {
    padding-right: 5.6rem
  }

  .print\:pb-56 {
    padding-bottom: 5.6rem
  }

  .print\:pl-56 {
    padding-left: 5.6rem
  }

  .print\:pt-60 {
    padding-top: 6rem
  }

  .print\:pr-60 {
    padding-right: 6rem
  }

  .print\:pb-60 {
    padding-bottom: 6rem
  }

  .print\:pl-60 {
    padding-left: 6rem
  }

  .print\:pt-64 {
    padding-top: 6.4rem
  }

  .print\:pr-64 {
    padding-right: 6.4rem
  }

  .print\:pb-64 {
    padding-bottom: 6.4rem
  }

  .print\:pl-64 {
    padding-left: 6.4rem
  }

  .print\:pt-68 {
    padding-top: 6.8rem
  }

  .print\:pr-68 {
    padding-right: 6.8rem
  }

  .print\:pb-68 {
    padding-bottom: 6.8rem
  }

  .print\:pl-68 {
    padding-left: 6.8rem
  }

  .print\:pt-72 {
    padding-top: 7.2rem
  }

  .print\:pr-72 {
    padding-right: 7.2rem
  }

  .print\:pb-72 {
    padding-bottom: 7.2rem
  }

  .print\:pl-72 {
    padding-left: 7.2rem
  }

  .print\:pt-76 {
    padding-top: 7.6rem
  }

  .print\:pr-76 {
    padding-right: 7.6rem
  }

  .print\:pb-76 {
    padding-bottom: 7.6rem
  }

  .print\:pl-76 {
    padding-left: 7.6rem
  }

  .print\:pt-80 {
    padding-top: 8rem
  }

  .print\:pr-80 {
    padding-right: 8rem
  }

  .print\:pb-80 {
    padding-bottom: 8rem
  }

  .print\:pl-80 {
    padding-left: 8rem
  }

  .print\:pt-84 {
    padding-top: 8.4rem
  }

  .print\:pr-84 {
    padding-right: 8.4rem
  }

  .print\:pb-84 {
    padding-bottom: 8.4rem
  }

  .print\:pl-84 {
    padding-left: 8.4rem
  }

  .print\:pt-88 {
    padding-top: 8.8rem
  }

  .print\:pr-88 {
    padding-right: 8.8rem
  }

  .print\:pb-88 {
    padding-bottom: 8.8rem
  }

  .print\:pl-88 {
    padding-left: 8.8rem
  }

  .print\:pt-92 {
    padding-top: 9.2rem
  }

  .print\:pr-92 {
    padding-right: 9.2rem
  }

  .print\:pb-92 {
    padding-bottom: 9.2rem
  }

  .print\:pl-92 {
    padding-left: 9.2rem
  }

  .print\:pt-96 {
    padding-top: 9.6rem
  }

  .print\:pr-96 {
    padding-right: 9.6rem
  }

  .print\:pb-96 {
    padding-bottom: 9.6rem
  }

  .print\:pl-96 {
    padding-left: 9.6rem
  }

  .print\:pt-128 {
    padding-top: 12.8rem
  }

  .print\:pr-128 {
    padding-right: 12.8rem
  }

  .print\:pb-128 {
    padding-bottom: 12.8rem
  }

  .print\:pl-128 {
    padding-left: 12.8rem
  }

  .print\:pt-136 {
    padding-top: 13.6rem
  }

  .print\:pr-136 {
    padding-right: 13.6rem
  }

  .print\:pb-136 {
    padding-bottom: 13.6rem
  }

  .print\:pl-136 {
    padding-left: 13.6rem
  }

  .print\:pt-160 {
    padding-top: 16rem
  }

  .print\:pr-160 {
    padding-right: 16rem
  }

  .print\:pb-160 {
    padding-bottom: 16rem
  }

  .print\:pl-160 {
    padding-left: 16rem
  }

  .print\:pt-192 {
    padding-top: 19.2rem
  }

  .print\:pr-192 {
    padding-right: 19.2rem
  }

  .print\:pb-192 {
    padding-bottom: 19.2rem
  }

  .print\:pl-192 {
    padding-left: 19.2rem
  }

  .print\:pt-200 {
    padding-top: 20rem
  }

  .print\:pr-200 {
    padding-right: 20rem
  }

  .print\:pb-200 {
    padding-bottom: 20rem
  }

  .print\:pl-200 {
    padding-left: 20rem
  }

  .print\:pt-208 {
    padding-top: 20.8rem
  }

  .print\:pr-208 {
    padding-right: 20.8rem
  }

  .print\:pb-208 {
    padding-bottom: 20.8rem
  }

  .print\:pl-208 {
    padding-left: 20.8rem
  }

  .print\:pt-216 {
    padding-top: 21.6rem
  }

  .print\:pr-216 {
    padding-right: 21.6rem
  }

  .print\:pb-216 {
    padding-bottom: 21.6rem
  }

  .print\:pl-216 {
    padding-left: 21.6rem
  }

  .print\:pt-224 {
    padding-top: 22.4rem
  }

  .print\:pr-224 {
    padding-right: 22.4rem
  }

  .print\:pb-224 {
    padding-bottom: 22.4rem
  }

  .print\:pl-224 {
    padding-left: 22.4rem
  }

  .print\:pt-256 {
    padding-top: 25.6rem
  }

  .print\:pr-256 {
    padding-right: 25.6rem
  }

  .print\:pb-256 {
    padding-bottom: 25.6rem
  }

  .print\:pl-256 {
    padding-left: 25.6rem
  }

  .print\:pt-288 {
    padding-top: 28.8rem
  }

  .print\:pr-288 {
    padding-right: 28.8rem
  }

  .print\:pb-288 {
    padding-bottom: 28.8rem
  }

  .print\:pl-288 {
    padding-left: 28.8rem
  }

  .print\:pt-320 {
    padding-top: 32rem
  }

  .print\:pr-320 {
    padding-right: 32rem
  }

  .print\:pb-320 {
    padding-bottom: 32rem
  }

  .print\:pl-320 {
    padding-left: 32rem
  }

  .print\:pt-360 {
    padding-top: 36rem
  }

  .print\:pr-360 {
    padding-right: 36rem
  }

  .print\:pb-360 {
    padding-bottom: 36rem
  }

  .print\:pl-360 {
    padding-left: 36rem
  }

  .print\:pt-384 {
    padding-top: 38.4rem
  }

  .print\:pr-384 {
    padding-right: 38.4rem
  }

  .print\:pb-384 {
    padding-bottom: 38.4rem
  }

  .print\:pl-384 {
    padding-left: 38.4rem
  }

  .print\:pt-400 {
    padding-top: 40rem
  }

  .print\:pr-400 {
    padding-right: 40rem
  }

  .print\:pb-400 {
    padding-bottom: 40rem
  }

  .print\:pl-400 {
    padding-left: 40rem
  }

  .print\:pt-512 {
    padding-top: 51.2rem
  }

  .print\:pr-512 {
    padding-right: 51.2rem
  }

  .print\:pb-512 {
    padding-bottom: 51.2rem
  }

  .print\:pl-512 {
    padding-left: 51.2rem
  }

  .print\:pt-640 {
    padding-top: 64rem
  }

  .print\:pr-640 {
    padding-right: 64rem
  }

  .print\:pb-640 {
    padding-bottom: 64rem
  }

  .print\:pl-640 {
    padding-left: 64rem
  }

  .print\:pt-xs {
    padding-top: 32rem
  }

  .print\:pr-xs {
    padding-right: 32rem
  }

  .print\:pb-xs {
    padding-bottom: 32rem
  }

  .print\:pl-xs {
    padding-left: 32rem
  }

  .print\:pt-sm {
    padding-top: 48rem
  }

  .print\:pr-sm {
    padding-right: 48rem
  }

  .print\:pb-sm {
    padding-bottom: 48rem
  }

  .print\:pl-sm {
    padding-left: 48rem
  }

  .print\:pt-md {
    padding-top: 64rem
  }

  .print\:pr-md {
    padding-right: 64rem
  }

  .print\:pb-md {
    padding-bottom: 64rem
  }

  .print\:pl-md {
    padding-left: 64rem
  }

  .print\:pt-lg {
    padding-top: 80rem
  }

  .print\:pr-lg {
    padding-right: 80rem
  }

  .print\:pb-lg {
    padding-bottom: 80rem
  }

  .print\:pl-lg {
    padding-left: 80rem
  }

  .print\:pt-xl {
    padding-top: 96rem
  }

  .print\:pr-xl {
    padding-right: 96rem
  }

  .print\:pb-xl {
    padding-bottom: 96rem
  }

  .print\:pl-xl {
    padding-left: 96rem
  }

  .print\:pt-2xl {
    padding-top: 112rem
  }

  .print\:pr-2xl {
    padding-right: 112rem
  }

  .print\:pb-2xl {
    padding-bottom: 112rem
  }

  .print\:pl-2xl {
    padding-left: 112rem
  }

  .print\:pt-3xl {
    padding-top: 128rem
  }

  .print\:pr-3xl {
    padding-right: 128rem
  }

  .print\:pb-3xl {
    padding-bottom: 128rem
  }

  .print\:pl-3xl {
    padding-left: 128rem
  }

  .print\:pt-4xl {
    padding-top: 144rem
  }

  .print\:pr-4xl {
    padding-right: 144rem
  }

  .print\:pb-4xl {
    padding-bottom: 144rem
  }

  .print\:pl-4xl {
    padding-left: 144rem
  }

  .print\:pt-5xl {
    padding-top: 160rem
  }

  .print\:pr-5xl {
    padding-right: 160rem
  }

  .print\:pb-5xl {
    padding-bottom: 160rem
  }

  .print\:pl-5xl {
    padding-left: 160rem
  }

  .print\:pt-px {
    padding-top: 1px
  }

  .print\:pr-px {
    padding-right: 1px
  }

  .print\:pb-px {
    padding-bottom: 1px
  }

  .print\:pl-px {
    padding-left: 1px
  }

  .print\:pt-0\.5 {
    padding-top: 0.05rem
  }

  .print\:pr-0\.5 {
    padding-right: 0.05rem
  }

  .print\:pb-0\.5 {
    padding-bottom: 0.05rem
  }

  .print\:pl-0\.5 {
    padding-left: 0.05rem
  }

  .print\:pt-1\.5 {
    padding-top: 0.15rem
  }

  .print\:pr-1\.5 {
    padding-right: 0.15rem
  }

  .print\:pb-1\.5 {
    padding-bottom: 0.15rem
  }

  .print\:pl-1\.5 {
    padding-left: 0.15rem
  }

  .print\:pt-2\.5 {
    padding-top: 0.25rem
  }

  .print\:pr-2\.5 {
    padding-right: 0.25rem
  }

  .print\:pb-2\.5 {
    padding-bottom: 0.25rem
  }

  .print\:pl-2\.5 {
    padding-left: 0.25rem
  }

  .print\:pt-3\.5 {
    padding-top: 0.35rem
  }

  .print\:pr-3\.5 {
    padding-right: 0.35rem
  }

  .print\:pb-3\.5 {
    padding-bottom: 0.35rem
  }

  .print\:pl-3\.5 {
    padding-left: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:p-0,[dir='ltr'].print\:ltr\:p-0 {
    padding: 0
  }

  [dir='ltr'] .print\:ltr\:p-1,[dir='ltr'].print\:ltr\:p-1 {
    padding: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:p-2,[dir='ltr'].print\:ltr\:p-2 {
    padding: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:p-3,[dir='ltr'].print\:ltr\:p-3 {
    padding: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:p-4,[dir='ltr'].print\:ltr\:p-4 {
    padding: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:p-5,[dir='ltr'].print\:ltr\:p-5 {
    padding: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:p-6,[dir='ltr'].print\:ltr\:p-6 {
    padding: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:p-7,[dir='ltr'].print\:ltr\:p-7 {
    padding: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:p-8,[dir='ltr'].print\:ltr\:p-8 {
    padding: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:p-9,[dir='ltr'].print\:ltr\:p-9 {
    padding: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:p-10,[dir='ltr'].print\:ltr\:p-10 {
    padding: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:p-12,[dir='ltr'].print\:ltr\:p-12 {
    padding: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:p-14,[dir='ltr'].print\:ltr\:p-14 {
    padding: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:p-16,[dir='ltr'].print\:ltr\:p-16 {
    padding: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:p-20,[dir='ltr'].print\:ltr\:p-20 {
    padding: 2rem
  }

  [dir='ltr'] .print\:ltr\:p-24,[dir='ltr'].print\:ltr\:p-24 {
    padding: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:p-28,[dir='ltr'].print\:ltr\:p-28 {
    padding: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:p-32,[dir='ltr'].print\:ltr\:p-32 {
    padding: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:p-36,[dir='ltr'].print\:ltr\:p-36 {
    padding: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:p-40,[dir='ltr'].print\:ltr\:p-40 {
    padding: 4rem
  }

  [dir='ltr'] .print\:ltr\:p-44,[dir='ltr'].print\:ltr\:p-44 {
    padding: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:p-48,[dir='ltr'].print\:ltr\:p-48 {
    padding: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:p-52,[dir='ltr'].print\:ltr\:p-52 {
    padding: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:p-56,[dir='ltr'].print\:ltr\:p-56 {
    padding: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:p-60,[dir='ltr'].print\:ltr\:p-60 {
    padding: 6rem
  }

  [dir='ltr'] .print\:ltr\:p-64,[dir='ltr'].print\:ltr\:p-64 {
    padding: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:p-68,[dir='ltr'].print\:ltr\:p-68 {
    padding: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:p-72,[dir='ltr'].print\:ltr\:p-72 {
    padding: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:p-76,[dir='ltr'].print\:ltr\:p-76 {
    padding: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:p-80,[dir='ltr'].print\:ltr\:p-80 {
    padding: 8rem
  }

  [dir='ltr'] .print\:ltr\:p-84,[dir='ltr'].print\:ltr\:p-84 {
    padding: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:p-88,[dir='ltr'].print\:ltr\:p-88 {
    padding: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:p-92,[dir='ltr'].print\:ltr\:p-92 {
    padding: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:p-96,[dir='ltr'].print\:ltr\:p-96 {
    padding: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:p-128,[dir='ltr'].print\:ltr\:p-128 {
    padding: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:p-136,[dir='ltr'].print\:ltr\:p-136 {
    padding: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:p-160,[dir='ltr'].print\:ltr\:p-160 {
    padding: 16rem
  }

  [dir='ltr'] .print\:ltr\:p-192,[dir='ltr'].print\:ltr\:p-192 {
    padding: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:p-200,[dir='ltr'].print\:ltr\:p-200 {
    padding: 20rem
  }

  [dir='ltr'] .print\:ltr\:p-208,[dir='ltr'].print\:ltr\:p-208 {
    padding: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:p-216,[dir='ltr'].print\:ltr\:p-216 {
    padding: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:p-224,[dir='ltr'].print\:ltr\:p-224 {
    padding: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:p-256,[dir='ltr'].print\:ltr\:p-256 {
    padding: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:p-288,[dir='ltr'].print\:ltr\:p-288 {
    padding: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:p-320,[dir='ltr'].print\:ltr\:p-320 {
    padding: 32rem
  }

  [dir='ltr'] .print\:ltr\:p-360,[dir='ltr'].print\:ltr\:p-360 {
    padding: 36rem
  }

  [dir='ltr'] .print\:ltr\:p-384,[dir='ltr'].print\:ltr\:p-384 {
    padding: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:p-400,[dir='ltr'].print\:ltr\:p-400 {
    padding: 40rem
  }

  [dir='ltr'] .print\:ltr\:p-512,[dir='ltr'].print\:ltr\:p-512 {
    padding: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:p-640,[dir='ltr'].print\:ltr\:p-640 {
    padding: 64rem
  }

  [dir='ltr'] .print\:ltr\:p-xs,[dir='ltr'].print\:ltr\:p-xs {
    padding: 32rem
  }

  [dir='ltr'] .print\:ltr\:p-sm,[dir='ltr'].print\:ltr\:p-sm {
    padding: 48rem
  }

  [dir='ltr'] .print\:ltr\:p-md,[dir='ltr'].print\:ltr\:p-md {
    padding: 64rem
  }

  [dir='ltr'] .print\:ltr\:p-lg,[dir='ltr'].print\:ltr\:p-lg {
    padding: 80rem
  }

  [dir='ltr'] .print\:ltr\:p-xl,[dir='ltr'].print\:ltr\:p-xl {
    padding: 96rem
  }

  [dir='ltr'] .print\:ltr\:p-2xl,[dir='ltr'].print\:ltr\:p-2xl {
    padding: 112rem
  }

  [dir='ltr'] .print\:ltr\:p-3xl,[dir='ltr'].print\:ltr\:p-3xl {
    padding: 128rem
  }

  [dir='ltr'] .print\:ltr\:p-4xl,[dir='ltr'].print\:ltr\:p-4xl {
    padding: 144rem
  }

  [dir='ltr'] .print\:ltr\:p-5xl,[dir='ltr'].print\:ltr\:p-5xl {
    padding: 160rem
  }

  [dir='ltr'] .print\:ltr\:p-px,[dir='ltr'].print\:ltr\:p-px {
    padding: 1px
  }

  [dir='ltr'] .print\:ltr\:p-0\.5,[dir='ltr'].print\:ltr\:p-0\.5 {
    padding: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:p-1\.5,[dir='ltr'].print\:ltr\:p-1\.5 {
    padding: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:p-2\.5,[dir='ltr'].print\:ltr\:p-2\.5 {
    padding: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:p-3\.5,[dir='ltr'].print\:ltr\:p-3\.5 {
    padding: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:py-0,[dir='ltr'].print\:ltr\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  [dir='ltr'] .print\:ltr\:px-0,[dir='ltr'].print\:ltr\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  [dir='ltr'] .print\:ltr\:py-1,[dir='ltr'].print\:ltr\:py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:px-1,[dir='ltr'].print\:ltr\:px-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:py-2,[dir='ltr'].print\:ltr\:py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:px-2,[dir='ltr'].print\:ltr\:px-2 {
    padding-left: 0.2rem;
    padding-right: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:py-3,[dir='ltr'].print\:ltr\:py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:px-3,[dir='ltr'].print\:ltr\:px-3 {
    padding-left: 0.3rem;
    padding-right: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:py-4,[dir='ltr'].print\:ltr\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:px-4,[dir='ltr'].print\:ltr\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:py-5,[dir='ltr'].print\:ltr\:py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:px-5,[dir='ltr'].print\:ltr\:px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:py-6,[dir='ltr'].print\:ltr\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:px-6,[dir='ltr'].print\:ltr\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:py-7,[dir='ltr'].print\:ltr\:py-7 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:px-7,[dir='ltr'].print\:ltr\:px-7 {
    padding-left: 0.7rem;
    padding-right: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:py-8,[dir='ltr'].print\:ltr\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:px-8,[dir='ltr'].print\:ltr\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:py-9,[dir='ltr'].print\:ltr\:py-9 {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:px-9,[dir='ltr'].print\:ltr\:px-9 {
    padding-left: 0.9rem;
    padding-right: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:py-10,[dir='ltr'].print\:ltr\:py-10 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:px-10,[dir='ltr'].print\:ltr\:px-10 {
    padding-left: 1.0rem;
    padding-right: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:py-12,[dir='ltr'].print\:ltr\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:px-12,[dir='ltr'].print\:ltr\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:py-14,[dir='ltr'].print\:ltr\:py-14 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:px-14,[dir='ltr'].print\:ltr\:px-14 {
    padding-left: 1.4rem;
    padding-right: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:py-16,[dir='ltr'].print\:ltr\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:px-16,[dir='ltr'].print\:ltr\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:py-20,[dir='ltr'].print\:ltr\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  [dir='ltr'] .print\:ltr\:px-20,[dir='ltr'].print\:ltr\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  [dir='ltr'] .print\:ltr\:py-24,[dir='ltr'].print\:ltr\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:px-24,[dir='ltr'].print\:ltr\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:py-28,[dir='ltr'].print\:ltr\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:px-28,[dir='ltr'].print\:ltr\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:py-32,[dir='ltr'].print\:ltr\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:px-32,[dir='ltr'].print\:ltr\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:py-36,[dir='ltr'].print\:ltr\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:px-36,[dir='ltr'].print\:ltr\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:py-40,[dir='ltr'].print\:ltr\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  [dir='ltr'] .print\:ltr\:px-40,[dir='ltr'].print\:ltr\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  [dir='ltr'] .print\:ltr\:py-44,[dir='ltr'].print\:ltr\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:px-44,[dir='ltr'].print\:ltr\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:py-48,[dir='ltr'].print\:ltr\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:px-48,[dir='ltr'].print\:ltr\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:py-52,[dir='ltr'].print\:ltr\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:px-52,[dir='ltr'].print\:ltr\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:py-56,[dir='ltr'].print\:ltr\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:px-56,[dir='ltr'].print\:ltr\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:py-60,[dir='ltr'].print\:ltr\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  [dir='ltr'] .print\:ltr\:px-60,[dir='ltr'].print\:ltr\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  [dir='ltr'] .print\:ltr\:py-64,[dir='ltr'].print\:ltr\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:px-64,[dir='ltr'].print\:ltr\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:py-68,[dir='ltr'].print\:ltr\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:px-68,[dir='ltr'].print\:ltr\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:py-72,[dir='ltr'].print\:ltr\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:px-72,[dir='ltr'].print\:ltr\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:py-76,[dir='ltr'].print\:ltr\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:px-76,[dir='ltr'].print\:ltr\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:py-80,[dir='ltr'].print\:ltr\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  [dir='ltr'] .print\:ltr\:px-80,[dir='ltr'].print\:ltr\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  [dir='ltr'] .print\:ltr\:py-84,[dir='ltr'].print\:ltr\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:px-84,[dir='ltr'].print\:ltr\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:py-88,[dir='ltr'].print\:ltr\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:px-88,[dir='ltr'].print\:ltr\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:py-92,[dir='ltr'].print\:ltr\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:px-92,[dir='ltr'].print\:ltr\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:py-96,[dir='ltr'].print\:ltr\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:px-96,[dir='ltr'].print\:ltr\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:py-128,[dir='ltr'].print\:ltr\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:px-128,[dir='ltr'].print\:ltr\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:py-136,[dir='ltr'].print\:ltr\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:px-136,[dir='ltr'].print\:ltr\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:py-160,[dir='ltr'].print\:ltr\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  [dir='ltr'] .print\:ltr\:px-160,[dir='ltr'].print\:ltr\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  [dir='ltr'] .print\:ltr\:py-192,[dir='ltr'].print\:ltr\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:px-192,[dir='ltr'].print\:ltr\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:py-200,[dir='ltr'].print\:ltr\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  [dir='ltr'] .print\:ltr\:px-200,[dir='ltr'].print\:ltr\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  [dir='ltr'] .print\:ltr\:py-208,[dir='ltr'].print\:ltr\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:px-208,[dir='ltr'].print\:ltr\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:py-216,[dir='ltr'].print\:ltr\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:px-216,[dir='ltr'].print\:ltr\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:py-224,[dir='ltr'].print\:ltr\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:px-224,[dir='ltr'].print\:ltr\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:py-256,[dir='ltr'].print\:ltr\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:px-256,[dir='ltr'].print\:ltr\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:py-288,[dir='ltr'].print\:ltr\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:px-288,[dir='ltr'].print\:ltr\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:py-320,[dir='ltr'].print\:ltr\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='ltr'] .print\:ltr\:px-320,[dir='ltr'].print\:ltr\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='ltr'] .print\:ltr\:py-360,[dir='ltr'].print\:ltr\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  [dir='ltr'] .print\:ltr\:px-360,[dir='ltr'].print\:ltr\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  [dir='ltr'] .print\:ltr\:py-384,[dir='ltr'].print\:ltr\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:px-384,[dir='ltr'].print\:ltr\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:py-400,[dir='ltr'].print\:ltr\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  [dir='ltr'] .print\:ltr\:px-400,[dir='ltr'].print\:ltr\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  [dir='ltr'] .print\:ltr\:py-512,[dir='ltr'].print\:ltr\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:px-512,[dir='ltr'].print\:ltr\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:py-640,[dir='ltr'].print\:ltr\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='ltr'] .print\:ltr\:px-640,[dir='ltr'].print\:ltr\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='ltr'] .print\:ltr\:py-xs,[dir='ltr'].print\:ltr\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='ltr'] .print\:ltr\:px-xs,[dir='ltr'].print\:ltr\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='ltr'] .print\:ltr\:py-sm,[dir='ltr'].print\:ltr\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  [dir='ltr'] .print\:ltr\:px-sm,[dir='ltr'].print\:ltr\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  [dir='ltr'] .print\:ltr\:py-md,[dir='ltr'].print\:ltr\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='ltr'] .print\:ltr\:px-md,[dir='ltr'].print\:ltr\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='ltr'] .print\:ltr\:py-lg,[dir='ltr'].print\:ltr\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  [dir='ltr'] .print\:ltr\:px-lg,[dir='ltr'].print\:ltr\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  [dir='ltr'] .print\:ltr\:py-xl,[dir='ltr'].print\:ltr\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  [dir='ltr'] .print\:ltr\:px-xl,[dir='ltr'].print\:ltr\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  [dir='ltr'] .print\:ltr\:py-2xl,[dir='ltr'].print\:ltr\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  [dir='ltr'] .print\:ltr\:px-2xl,[dir='ltr'].print\:ltr\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  [dir='ltr'] .print\:ltr\:py-3xl,[dir='ltr'].print\:ltr\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  [dir='ltr'] .print\:ltr\:px-3xl,[dir='ltr'].print\:ltr\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  [dir='ltr'] .print\:ltr\:py-4xl,[dir='ltr'].print\:ltr\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  [dir='ltr'] .print\:ltr\:px-4xl,[dir='ltr'].print\:ltr\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  [dir='ltr'] .print\:ltr\:py-5xl,[dir='ltr'].print\:ltr\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  [dir='ltr'] .print\:ltr\:px-5xl,[dir='ltr'].print\:ltr\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  [dir='ltr'] .print\:ltr\:py-px,[dir='ltr'].print\:ltr\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  [dir='ltr'] .print\:ltr\:px-px,[dir='ltr'].print\:ltr\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  [dir='ltr'] .print\:ltr\:py-0\.5,[dir='ltr'].print\:ltr\:py-0\.5 {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:px-0\.5,[dir='ltr'].print\:ltr\:px-0\.5 {
    padding-left: 0.05rem;
    padding-right: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:py-1\.5,[dir='ltr'].print\:ltr\:py-1\.5 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:px-1\.5,[dir='ltr'].print\:ltr\:px-1\.5 {
    padding-left: 0.15rem;
    padding-right: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:py-2\.5,[dir='ltr'].print\:ltr\:py-2\.5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:px-2\.5,[dir='ltr'].print\:ltr\:px-2\.5 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:py-3\.5,[dir='ltr'].print\:ltr\:py-3\.5 {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:px-3\.5,[dir='ltr'].print\:ltr\:px-3\.5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:pt-0,[dir='ltr'].print\:ltr\:pt-0 {
    padding-top: 0
  }

  [dir='ltr'] .print\:ltr\:pr-0,[dir='ltr'].print\:ltr\:pr-0 {
    padding-right: 0
  }

  [dir='ltr'] .print\:ltr\:pb-0,[dir='ltr'].print\:ltr\:pb-0 {
    padding-bottom: 0
  }

  [dir='ltr'] .print\:ltr\:pl-0,[dir='ltr'].print\:ltr\:pl-0 {
    padding-left: 0
  }

  [dir='ltr'] .print\:ltr\:pt-1,[dir='ltr'].print\:ltr\:pt-1 {
    padding-top: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:pr-1,[dir='ltr'].print\:ltr\:pr-1 {
    padding-right: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:pb-1,[dir='ltr'].print\:ltr\:pb-1 {
    padding-bottom: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:pl-1,[dir='ltr'].print\:ltr\:pl-1 {
    padding-left: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:pt-2,[dir='ltr'].print\:ltr\:pt-2 {
    padding-top: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:pr-2,[dir='ltr'].print\:ltr\:pr-2 {
    padding-right: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:pb-2,[dir='ltr'].print\:ltr\:pb-2 {
    padding-bottom: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:pl-2,[dir='ltr'].print\:ltr\:pl-2 {
    padding-left: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:pt-3,[dir='ltr'].print\:ltr\:pt-3 {
    padding-top: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:pr-3,[dir='ltr'].print\:ltr\:pr-3 {
    padding-right: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:pb-3,[dir='ltr'].print\:ltr\:pb-3 {
    padding-bottom: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:pl-3,[dir='ltr'].print\:ltr\:pl-3 {
    padding-left: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:pt-4,[dir='ltr'].print\:ltr\:pt-4 {
    padding-top: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:pr-4,[dir='ltr'].print\:ltr\:pr-4 {
    padding-right: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:pb-4,[dir='ltr'].print\:ltr\:pb-4 {
    padding-bottom: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:pl-4,[dir='ltr'].print\:ltr\:pl-4 {
    padding-left: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:pt-5,[dir='ltr'].print\:ltr\:pt-5 {
    padding-top: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:pr-5,[dir='ltr'].print\:ltr\:pr-5 {
    padding-right: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:pb-5,[dir='ltr'].print\:ltr\:pb-5 {
    padding-bottom: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:pl-5,[dir='ltr'].print\:ltr\:pl-5 {
    padding-left: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:pt-6,[dir='ltr'].print\:ltr\:pt-6 {
    padding-top: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:pr-6,[dir='ltr'].print\:ltr\:pr-6 {
    padding-right: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:pb-6,[dir='ltr'].print\:ltr\:pb-6 {
    padding-bottom: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:pl-6,[dir='ltr'].print\:ltr\:pl-6 {
    padding-left: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:pt-7,[dir='ltr'].print\:ltr\:pt-7 {
    padding-top: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:pr-7,[dir='ltr'].print\:ltr\:pr-7 {
    padding-right: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:pb-7,[dir='ltr'].print\:ltr\:pb-7 {
    padding-bottom: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:pl-7,[dir='ltr'].print\:ltr\:pl-7 {
    padding-left: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:pt-8,[dir='ltr'].print\:ltr\:pt-8 {
    padding-top: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:pr-8,[dir='ltr'].print\:ltr\:pr-8 {
    padding-right: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:pb-8,[dir='ltr'].print\:ltr\:pb-8 {
    padding-bottom: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:pl-8,[dir='ltr'].print\:ltr\:pl-8 {
    padding-left: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:pt-9,[dir='ltr'].print\:ltr\:pt-9 {
    padding-top: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:pr-9,[dir='ltr'].print\:ltr\:pr-9 {
    padding-right: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:pb-9,[dir='ltr'].print\:ltr\:pb-9 {
    padding-bottom: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:pl-9,[dir='ltr'].print\:ltr\:pl-9 {
    padding-left: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:pt-10,[dir='ltr'].print\:ltr\:pt-10 {
    padding-top: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:pr-10,[dir='ltr'].print\:ltr\:pr-10 {
    padding-right: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:pb-10,[dir='ltr'].print\:ltr\:pb-10 {
    padding-bottom: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:pl-10,[dir='ltr'].print\:ltr\:pl-10 {
    padding-left: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:pt-12,[dir='ltr'].print\:ltr\:pt-12 {
    padding-top: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:pr-12,[dir='ltr'].print\:ltr\:pr-12 {
    padding-right: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:pb-12,[dir='ltr'].print\:ltr\:pb-12 {
    padding-bottom: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:pl-12,[dir='ltr'].print\:ltr\:pl-12 {
    padding-left: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:pt-14,[dir='ltr'].print\:ltr\:pt-14 {
    padding-top: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:pr-14,[dir='ltr'].print\:ltr\:pr-14 {
    padding-right: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:pb-14,[dir='ltr'].print\:ltr\:pb-14 {
    padding-bottom: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:pl-14,[dir='ltr'].print\:ltr\:pl-14 {
    padding-left: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:pt-16,[dir='ltr'].print\:ltr\:pt-16 {
    padding-top: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:pr-16,[dir='ltr'].print\:ltr\:pr-16 {
    padding-right: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:pb-16,[dir='ltr'].print\:ltr\:pb-16 {
    padding-bottom: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:pl-16,[dir='ltr'].print\:ltr\:pl-16 {
    padding-left: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:pt-20,[dir='ltr'].print\:ltr\:pt-20 {
    padding-top: 2rem
  }

  [dir='ltr'] .print\:ltr\:pr-20,[dir='ltr'].print\:ltr\:pr-20 {
    padding-right: 2rem
  }

  [dir='ltr'] .print\:ltr\:pb-20,[dir='ltr'].print\:ltr\:pb-20 {
    padding-bottom: 2rem
  }

  [dir='ltr'] .print\:ltr\:pl-20,[dir='ltr'].print\:ltr\:pl-20 {
    padding-left: 2rem
  }

  [dir='ltr'] .print\:ltr\:pt-24,[dir='ltr'].print\:ltr\:pt-24 {
    padding-top: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:pr-24,[dir='ltr'].print\:ltr\:pr-24 {
    padding-right: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:pb-24,[dir='ltr'].print\:ltr\:pb-24 {
    padding-bottom: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:pl-24,[dir='ltr'].print\:ltr\:pl-24 {
    padding-left: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:pt-28,[dir='ltr'].print\:ltr\:pt-28 {
    padding-top: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:pr-28,[dir='ltr'].print\:ltr\:pr-28 {
    padding-right: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:pb-28,[dir='ltr'].print\:ltr\:pb-28 {
    padding-bottom: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:pl-28,[dir='ltr'].print\:ltr\:pl-28 {
    padding-left: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:pt-32,[dir='ltr'].print\:ltr\:pt-32 {
    padding-top: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:pr-32,[dir='ltr'].print\:ltr\:pr-32 {
    padding-right: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:pb-32,[dir='ltr'].print\:ltr\:pb-32 {
    padding-bottom: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:pl-32,[dir='ltr'].print\:ltr\:pl-32 {
    padding-left: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:pt-36,[dir='ltr'].print\:ltr\:pt-36 {
    padding-top: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:pr-36,[dir='ltr'].print\:ltr\:pr-36 {
    padding-right: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:pb-36,[dir='ltr'].print\:ltr\:pb-36 {
    padding-bottom: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:pl-36,[dir='ltr'].print\:ltr\:pl-36 {
    padding-left: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:pt-40,[dir='ltr'].print\:ltr\:pt-40 {
    padding-top: 4rem
  }

  [dir='ltr'] .print\:ltr\:pr-40,[dir='ltr'].print\:ltr\:pr-40 {
    padding-right: 4rem
  }

  [dir='ltr'] .print\:ltr\:pb-40,[dir='ltr'].print\:ltr\:pb-40 {
    padding-bottom: 4rem
  }

  [dir='ltr'] .print\:ltr\:pl-40,[dir='ltr'].print\:ltr\:pl-40 {
    padding-left: 4rem
  }

  [dir='ltr'] .print\:ltr\:pt-44,[dir='ltr'].print\:ltr\:pt-44 {
    padding-top: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:pr-44,[dir='ltr'].print\:ltr\:pr-44 {
    padding-right: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:pb-44,[dir='ltr'].print\:ltr\:pb-44 {
    padding-bottom: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:pl-44,[dir='ltr'].print\:ltr\:pl-44 {
    padding-left: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:pt-48,[dir='ltr'].print\:ltr\:pt-48 {
    padding-top: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:pr-48,[dir='ltr'].print\:ltr\:pr-48 {
    padding-right: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:pb-48,[dir='ltr'].print\:ltr\:pb-48 {
    padding-bottom: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:pl-48,[dir='ltr'].print\:ltr\:pl-48 {
    padding-left: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:pt-52,[dir='ltr'].print\:ltr\:pt-52 {
    padding-top: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:pr-52,[dir='ltr'].print\:ltr\:pr-52 {
    padding-right: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:pb-52,[dir='ltr'].print\:ltr\:pb-52 {
    padding-bottom: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:pl-52,[dir='ltr'].print\:ltr\:pl-52 {
    padding-left: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:pt-56,[dir='ltr'].print\:ltr\:pt-56 {
    padding-top: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:pr-56,[dir='ltr'].print\:ltr\:pr-56 {
    padding-right: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:pb-56,[dir='ltr'].print\:ltr\:pb-56 {
    padding-bottom: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:pl-56,[dir='ltr'].print\:ltr\:pl-56 {
    padding-left: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:pt-60,[dir='ltr'].print\:ltr\:pt-60 {
    padding-top: 6rem
  }

  [dir='ltr'] .print\:ltr\:pr-60,[dir='ltr'].print\:ltr\:pr-60 {
    padding-right: 6rem
  }

  [dir='ltr'] .print\:ltr\:pb-60,[dir='ltr'].print\:ltr\:pb-60 {
    padding-bottom: 6rem
  }

  [dir='ltr'] .print\:ltr\:pl-60,[dir='ltr'].print\:ltr\:pl-60 {
    padding-left: 6rem
  }

  [dir='ltr'] .print\:ltr\:pt-64,[dir='ltr'].print\:ltr\:pt-64 {
    padding-top: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:pr-64,[dir='ltr'].print\:ltr\:pr-64 {
    padding-right: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:pb-64,[dir='ltr'].print\:ltr\:pb-64 {
    padding-bottom: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:pl-64,[dir='ltr'].print\:ltr\:pl-64 {
    padding-left: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:pt-68,[dir='ltr'].print\:ltr\:pt-68 {
    padding-top: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:pr-68,[dir='ltr'].print\:ltr\:pr-68 {
    padding-right: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:pb-68,[dir='ltr'].print\:ltr\:pb-68 {
    padding-bottom: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:pl-68,[dir='ltr'].print\:ltr\:pl-68 {
    padding-left: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:pt-72,[dir='ltr'].print\:ltr\:pt-72 {
    padding-top: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:pr-72,[dir='ltr'].print\:ltr\:pr-72 {
    padding-right: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:pb-72,[dir='ltr'].print\:ltr\:pb-72 {
    padding-bottom: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:pl-72,[dir='ltr'].print\:ltr\:pl-72 {
    padding-left: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:pt-76,[dir='ltr'].print\:ltr\:pt-76 {
    padding-top: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:pr-76,[dir='ltr'].print\:ltr\:pr-76 {
    padding-right: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:pb-76,[dir='ltr'].print\:ltr\:pb-76 {
    padding-bottom: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:pl-76,[dir='ltr'].print\:ltr\:pl-76 {
    padding-left: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:pt-80,[dir='ltr'].print\:ltr\:pt-80 {
    padding-top: 8rem
  }

  [dir='ltr'] .print\:ltr\:pr-80,[dir='ltr'].print\:ltr\:pr-80 {
    padding-right: 8rem
  }

  [dir='ltr'] .print\:ltr\:pb-80,[dir='ltr'].print\:ltr\:pb-80 {
    padding-bottom: 8rem
  }

  [dir='ltr'] .print\:ltr\:pl-80,[dir='ltr'].print\:ltr\:pl-80 {
    padding-left: 8rem
  }

  [dir='ltr'] .print\:ltr\:pt-84,[dir='ltr'].print\:ltr\:pt-84 {
    padding-top: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:pr-84,[dir='ltr'].print\:ltr\:pr-84 {
    padding-right: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:pb-84,[dir='ltr'].print\:ltr\:pb-84 {
    padding-bottom: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:pl-84,[dir='ltr'].print\:ltr\:pl-84 {
    padding-left: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:pt-88,[dir='ltr'].print\:ltr\:pt-88 {
    padding-top: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:pr-88,[dir='ltr'].print\:ltr\:pr-88 {
    padding-right: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:pb-88,[dir='ltr'].print\:ltr\:pb-88 {
    padding-bottom: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:pl-88,[dir='ltr'].print\:ltr\:pl-88 {
    padding-left: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:pt-92,[dir='ltr'].print\:ltr\:pt-92 {
    padding-top: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:pr-92,[dir='ltr'].print\:ltr\:pr-92 {
    padding-right: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:pb-92,[dir='ltr'].print\:ltr\:pb-92 {
    padding-bottom: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:pl-92,[dir='ltr'].print\:ltr\:pl-92 {
    padding-left: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:pt-96,[dir='ltr'].print\:ltr\:pt-96 {
    padding-top: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:pr-96,[dir='ltr'].print\:ltr\:pr-96 {
    padding-right: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:pb-96,[dir='ltr'].print\:ltr\:pb-96 {
    padding-bottom: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:pl-96,[dir='ltr'].print\:ltr\:pl-96 {
    padding-left: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:pt-128,[dir='ltr'].print\:ltr\:pt-128 {
    padding-top: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:pr-128,[dir='ltr'].print\:ltr\:pr-128 {
    padding-right: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:pb-128,[dir='ltr'].print\:ltr\:pb-128 {
    padding-bottom: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:pl-128,[dir='ltr'].print\:ltr\:pl-128 {
    padding-left: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:pt-136,[dir='ltr'].print\:ltr\:pt-136 {
    padding-top: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:pr-136,[dir='ltr'].print\:ltr\:pr-136 {
    padding-right: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:pb-136,[dir='ltr'].print\:ltr\:pb-136 {
    padding-bottom: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:pl-136,[dir='ltr'].print\:ltr\:pl-136 {
    padding-left: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:pt-160,[dir='ltr'].print\:ltr\:pt-160 {
    padding-top: 16rem
  }

  [dir='ltr'] .print\:ltr\:pr-160,[dir='ltr'].print\:ltr\:pr-160 {
    padding-right: 16rem
  }

  [dir='ltr'] .print\:ltr\:pb-160,[dir='ltr'].print\:ltr\:pb-160 {
    padding-bottom: 16rem
  }

  [dir='ltr'] .print\:ltr\:pl-160,[dir='ltr'].print\:ltr\:pl-160 {
    padding-left: 16rem
  }

  [dir='ltr'] .print\:ltr\:pt-192,[dir='ltr'].print\:ltr\:pt-192 {
    padding-top: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:pr-192,[dir='ltr'].print\:ltr\:pr-192 {
    padding-right: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:pb-192,[dir='ltr'].print\:ltr\:pb-192 {
    padding-bottom: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:pl-192,[dir='ltr'].print\:ltr\:pl-192 {
    padding-left: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:pt-200,[dir='ltr'].print\:ltr\:pt-200 {
    padding-top: 20rem
  }

  [dir='ltr'] .print\:ltr\:pr-200,[dir='ltr'].print\:ltr\:pr-200 {
    padding-right: 20rem
  }

  [dir='ltr'] .print\:ltr\:pb-200,[dir='ltr'].print\:ltr\:pb-200 {
    padding-bottom: 20rem
  }

  [dir='ltr'] .print\:ltr\:pl-200,[dir='ltr'].print\:ltr\:pl-200 {
    padding-left: 20rem
  }

  [dir='ltr'] .print\:ltr\:pt-208,[dir='ltr'].print\:ltr\:pt-208 {
    padding-top: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:pr-208,[dir='ltr'].print\:ltr\:pr-208 {
    padding-right: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:pb-208,[dir='ltr'].print\:ltr\:pb-208 {
    padding-bottom: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:pl-208,[dir='ltr'].print\:ltr\:pl-208 {
    padding-left: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:pt-216,[dir='ltr'].print\:ltr\:pt-216 {
    padding-top: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:pr-216,[dir='ltr'].print\:ltr\:pr-216 {
    padding-right: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:pb-216,[dir='ltr'].print\:ltr\:pb-216 {
    padding-bottom: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:pl-216,[dir='ltr'].print\:ltr\:pl-216 {
    padding-left: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:pt-224,[dir='ltr'].print\:ltr\:pt-224 {
    padding-top: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:pr-224,[dir='ltr'].print\:ltr\:pr-224 {
    padding-right: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:pb-224,[dir='ltr'].print\:ltr\:pb-224 {
    padding-bottom: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:pl-224,[dir='ltr'].print\:ltr\:pl-224 {
    padding-left: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:pt-256,[dir='ltr'].print\:ltr\:pt-256 {
    padding-top: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:pr-256,[dir='ltr'].print\:ltr\:pr-256 {
    padding-right: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:pb-256,[dir='ltr'].print\:ltr\:pb-256 {
    padding-bottom: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:pl-256,[dir='ltr'].print\:ltr\:pl-256 {
    padding-left: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:pt-288,[dir='ltr'].print\:ltr\:pt-288 {
    padding-top: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:pr-288,[dir='ltr'].print\:ltr\:pr-288 {
    padding-right: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:pb-288,[dir='ltr'].print\:ltr\:pb-288 {
    padding-bottom: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:pl-288,[dir='ltr'].print\:ltr\:pl-288 {
    padding-left: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:pt-320,[dir='ltr'].print\:ltr\:pt-320 {
    padding-top: 32rem
  }

  [dir='ltr'] .print\:ltr\:pr-320,[dir='ltr'].print\:ltr\:pr-320 {
    padding-right: 32rem
  }

  [dir='ltr'] .print\:ltr\:pb-320,[dir='ltr'].print\:ltr\:pb-320 {
    padding-bottom: 32rem
  }

  [dir='ltr'] .print\:ltr\:pl-320,[dir='ltr'].print\:ltr\:pl-320 {
    padding-left: 32rem
  }

  [dir='ltr'] .print\:ltr\:pt-360,[dir='ltr'].print\:ltr\:pt-360 {
    padding-top: 36rem
  }

  [dir='ltr'] .print\:ltr\:pr-360,[dir='ltr'].print\:ltr\:pr-360 {
    padding-right: 36rem
  }

  [dir='ltr'] .print\:ltr\:pb-360,[dir='ltr'].print\:ltr\:pb-360 {
    padding-bottom: 36rem
  }

  [dir='ltr'] .print\:ltr\:pl-360,[dir='ltr'].print\:ltr\:pl-360 {
    padding-left: 36rem
  }

  [dir='ltr'] .print\:ltr\:pt-384,[dir='ltr'].print\:ltr\:pt-384 {
    padding-top: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:pr-384,[dir='ltr'].print\:ltr\:pr-384 {
    padding-right: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:pb-384,[dir='ltr'].print\:ltr\:pb-384 {
    padding-bottom: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:pl-384,[dir='ltr'].print\:ltr\:pl-384 {
    padding-left: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:pt-400,[dir='ltr'].print\:ltr\:pt-400 {
    padding-top: 40rem
  }

  [dir='ltr'] .print\:ltr\:pr-400,[dir='ltr'].print\:ltr\:pr-400 {
    padding-right: 40rem
  }

  [dir='ltr'] .print\:ltr\:pb-400,[dir='ltr'].print\:ltr\:pb-400 {
    padding-bottom: 40rem
  }

  [dir='ltr'] .print\:ltr\:pl-400,[dir='ltr'].print\:ltr\:pl-400 {
    padding-left: 40rem
  }

  [dir='ltr'] .print\:ltr\:pt-512,[dir='ltr'].print\:ltr\:pt-512 {
    padding-top: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:pr-512,[dir='ltr'].print\:ltr\:pr-512 {
    padding-right: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:pb-512,[dir='ltr'].print\:ltr\:pb-512 {
    padding-bottom: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:pl-512,[dir='ltr'].print\:ltr\:pl-512 {
    padding-left: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:pt-640,[dir='ltr'].print\:ltr\:pt-640 {
    padding-top: 64rem
  }

  [dir='ltr'] .print\:ltr\:pr-640,[dir='ltr'].print\:ltr\:pr-640 {
    padding-right: 64rem
  }

  [dir='ltr'] .print\:ltr\:pb-640,[dir='ltr'].print\:ltr\:pb-640 {
    padding-bottom: 64rem
  }

  [dir='ltr'] .print\:ltr\:pl-640,[dir='ltr'].print\:ltr\:pl-640 {
    padding-left: 64rem
  }

  [dir='ltr'] .print\:ltr\:pt-xs,[dir='ltr'].print\:ltr\:pt-xs {
    padding-top: 32rem
  }

  [dir='ltr'] .print\:ltr\:pr-xs,[dir='ltr'].print\:ltr\:pr-xs {
    padding-right: 32rem
  }

  [dir='ltr'] .print\:ltr\:pb-xs,[dir='ltr'].print\:ltr\:pb-xs {
    padding-bottom: 32rem
  }

  [dir='ltr'] .print\:ltr\:pl-xs,[dir='ltr'].print\:ltr\:pl-xs {
    padding-left: 32rem
  }

  [dir='ltr'] .print\:ltr\:pt-sm,[dir='ltr'].print\:ltr\:pt-sm {
    padding-top: 48rem
  }

  [dir='ltr'] .print\:ltr\:pr-sm,[dir='ltr'].print\:ltr\:pr-sm {
    padding-right: 48rem
  }

  [dir='ltr'] .print\:ltr\:pb-sm,[dir='ltr'].print\:ltr\:pb-sm {
    padding-bottom: 48rem
  }

  [dir='ltr'] .print\:ltr\:pl-sm,[dir='ltr'].print\:ltr\:pl-sm {
    padding-left: 48rem
  }

  [dir='ltr'] .print\:ltr\:pt-md,[dir='ltr'].print\:ltr\:pt-md {
    padding-top: 64rem
  }

  [dir='ltr'] .print\:ltr\:pr-md,[dir='ltr'].print\:ltr\:pr-md {
    padding-right: 64rem
  }

  [dir='ltr'] .print\:ltr\:pb-md,[dir='ltr'].print\:ltr\:pb-md {
    padding-bottom: 64rem
  }

  [dir='ltr'] .print\:ltr\:pl-md,[dir='ltr'].print\:ltr\:pl-md {
    padding-left: 64rem
  }

  [dir='ltr'] .print\:ltr\:pt-lg,[dir='ltr'].print\:ltr\:pt-lg {
    padding-top: 80rem
  }

  [dir='ltr'] .print\:ltr\:pr-lg,[dir='ltr'].print\:ltr\:pr-lg {
    padding-right: 80rem
  }

  [dir='ltr'] .print\:ltr\:pb-lg,[dir='ltr'].print\:ltr\:pb-lg {
    padding-bottom: 80rem
  }

  [dir='ltr'] .print\:ltr\:pl-lg,[dir='ltr'].print\:ltr\:pl-lg {
    padding-left: 80rem
  }

  [dir='ltr'] .print\:ltr\:pt-xl,[dir='ltr'].print\:ltr\:pt-xl {
    padding-top: 96rem
  }

  [dir='ltr'] .print\:ltr\:pr-xl,[dir='ltr'].print\:ltr\:pr-xl {
    padding-right: 96rem
  }

  [dir='ltr'] .print\:ltr\:pb-xl,[dir='ltr'].print\:ltr\:pb-xl {
    padding-bottom: 96rem
  }

  [dir='ltr'] .print\:ltr\:pl-xl,[dir='ltr'].print\:ltr\:pl-xl {
    padding-left: 96rem
  }

  [dir='ltr'] .print\:ltr\:pt-2xl,[dir='ltr'].print\:ltr\:pt-2xl {
    padding-top: 112rem
  }

  [dir='ltr'] .print\:ltr\:pr-2xl,[dir='ltr'].print\:ltr\:pr-2xl {
    padding-right: 112rem
  }

  [dir='ltr'] .print\:ltr\:pb-2xl,[dir='ltr'].print\:ltr\:pb-2xl {
    padding-bottom: 112rem
  }

  [dir='ltr'] .print\:ltr\:pl-2xl,[dir='ltr'].print\:ltr\:pl-2xl {
    padding-left: 112rem
  }

  [dir='ltr'] .print\:ltr\:pt-3xl,[dir='ltr'].print\:ltr\:pt-3xl {
    padding-top: 128rem
  }

  [dir='ltr'] .print\:ltr\:pr-3xl,[dir='ltr'].print\:ltr\:pr-3xl {
    padding-right: 128rem
  }

  [dir='ltr'] .print\:ltr\:pb-3xl,[dir='ltr'].print\:ltr\:pb-3xl {
    padding-bottom: 128rem
  }

  [dir='ltr'] .print\:ltr\:pl-3xl,[dir='ltr'].print\:ltr\:pl-3xl {
    padding-left: 128rem
  }

  [dir='ltr'] .print\:ltr\:pt-4xl,[dir='ltr'].print\:ltr\:pt-4xl {
    padding-top: 144rem
  }

  [dir='ltr'] .print\:ltr\:pr-4xl,[dir='ltr'].print\:ltr\:pr-4xl {
    padding-right: 144rem
  }

  [dir='ltr'] .print\:ltr\:pb-4xl,[dir='ltr'].print\:ltr\:pb-4xl {
    padding-bottom: 144rem
  }

  [dir='ltr'] .print\:ltr\:pl-4xl,[dir='ltr'].print\:ltr\:pl-4xl {
    padding-left: 144rem
  }

  [dir='ltr'] .print\:ltr\:pt-5xl,[dir='ltr'].print\:ltr\:pt-5xl {
    padding-top: 160rem
  }

  [dir='ltr'] .print\:ltr\:pr-5xl,[dir='ltr'].print\:ltr\:pr-5xl {
    padding-right: 160rem
  }

  [dir='ltr'] .print\:ltr\:pb-5xl,[dir='ltr'].print\:ltr\:pb-5xl {
    padding-bottom: 160rem
  }

  [dir='ltr'] .print\:ltr\:pl-5xl,[dir='ltr'].print\:ltr\:pl-5xl {
    padding-left: 160rem
  }

  [dir='ltr'] .print\:ltr\:pt-px,[dir='ltr'].print\:ltr\:pt-px {
    padding-top: 1px
  }

  [dir='ltr'] .print\:ltr\:pr-px,[dir='ltr'].print\:ltr\:pr-px {
    padding-right: 1px
  }

  [dir='ltr'] .print\:ltr\:pb-px,[dir='ltr'].print\:ltr\:pb-px {
    padding-bottom: 1px
  }

  [dir='ltr'] .print\:ltr\:pl-px,[dir='ltr'].print\:ltr\:pl-px {
    padding-left: 1px
  }

  [dir='ltr'] .print\:ltr\:pt-0\.5,[dir='ltr'].print\:ltr\:pt-0\.5 {
    padding-top: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:pr-0\.5,[dir='ltr'].print\:ltr\:pr-0\.5 {
    padding-right: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:pb-0\.5,[dir='ltr'].print\:ltr\:pb-0\.5 {
    padding-bottom: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:pl-0\.5,[dir='ltr'].print\:ltr\:pl-0\.5 {
    padding-left: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:pt-1\.5,[dir='ltr'].print\:ltr\:pt-1\.5 {
    padding-top: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:pr-1\.5,[dir='ltr'].print\:ltr\:pr-1\.5 {
    padding-right: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:pb-1\.5,[dir='ltr'].print\:ltr\:pb-1\.5 {
    padding-bottom: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:pl-1\.5,[dir='ltr'].print\:ltr\:pl-1\.5 {
    padding-left: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:pt-2\.5,[dir='ltr'].print\:ltr\:pt-2\.5 {
    padding-top: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:pr-2\.5,[dir='ltr'].print\:ltr\:pr-2\.5 {
    padding-right: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:pb-2\.5,[dir='ltr'].print\:ltr\:pb-2\.5 {
    padding-bottom: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:pl-2\.5,[dir='ltr'].print\:ltr\:pl-2\.5 {
    padding-left: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:pt-3\.5,[dir='ltr'].print\:ltr\:pt-3\.5 {
    padding-top: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:pr-3\.5,[dir='ltr'].print\:ltr\:pr-3\.5 {
    padding-right: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:pb-3\.5,[dir='ltr'].print\:ltr\:pb-3\.5 {
    padding-bottom: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:pl-3\.5,[dir='ltr'].print\:ltr\:pl-3\.5 {
    padding-left: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:p-0,[dir='rtl'].print\:rtl\:p-0 {
    padding: 0
  }

  [dir='rtl'] .print\:rtl\:p-1,[dir='rtl'].print\:rtl\:p-1 {
    padding: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:p-2,[dir='rtl'].print\:rtl\:p-2 {
    padding: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:p-3,[dir='rtl'].print\:rtl\:p-3 {
    padding: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:p-4,[dir='rtl'].print\:rtl\:p-4 {
    padding: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:p-5,[dir='rtl'].print\:rtl\:p-5 {
    padding: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:p-6,[dir='rtl'].print\:rtl\:p-6 {
    padding: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:p-7,[dir='rtl'].print\:rtl\:p-7 {
    padding: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:p-8,[dir='rtl'].print\:rtl\:p-8 {
    padding: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:p-9,[dir='rtl'].print\:rtl\:p-9 {
    padding: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:p-10,[dir='rtl'].print\:rtl\:p-10 {
    padding: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:p-12,[dir='rtl'].print\:rtl\:p-12 {
    padding: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:p-14,[dir='rtl'].print\:rtl\:p-14 {
    padding: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:p-16,[dir='rtl'].print\:rtl\:p-16 {
    padding: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:p-20,[dir='rtl'].print\:rtl\:p-20 {
    padding: 2rem
  }

  [dir='rtl'] .print\:rtl\:p-24,[dir='rtl'].print\:rtl\:p-24 {
    padding: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:p-28,[dir='rtl'].print\:rtl\:p-28 {
    padding: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:p-32,[dir='rtl'].print\:rtl\:p-32 {
    padding: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:p-36,[dir='rtl'].print\:rtl\:p-36 {
    padding: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:p-40,[dir='rtl'].print\:rtl\:p-40 {
    padding: 4rem
  }

  [dir='rtl'] .print\:rtl\:p-44,[dir='rtl'].print\:rtl\:p-44 {
    padding: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:p-48,[dir='rtl'].print\:rtl\:p-48 {
    padding: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:p-52,[dir='rtl'].print\:rtl\:p-52 {
    padding: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:p-56,[dir='rtl'].print\:rtl\:p-56 {
    padding: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:p-60,[dir='rtl'].print\:rtl\:p-60 {
    padding: 6rem
  }

  [dir='rtl'] .print\:rtl\:p-64,[dir='rtl'].print\:rtl\:p-64 {
    padding: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:p-68,[dir='rtl'].print\:rtl\:p-68 {
    padding: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:p-72,[dir='rtl'].print\:rtl\:p-72 {
    padding: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:p-76,[dir='rtl'].print\:rtl\:p-76 {
    padding: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:p-80,[dir='rtl'].print\:rtl\:p-80 {
    padding: 8rem
  }

  [dir='rtl'] .print\:rtl\:p-84,[dir='rtl'].print\:rtl\:p-84 {
    padding: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:p-88,[dir='rtl'].print\:rtl\:p-88 {
    padding: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:p-92,[dir='rtl'].print\:rtl\:p-92 {
    padding: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:p-96,[dir='rtl'].print\:rtl\:p-96 {
    padding: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:p-128,[dir='rtl'].print\:rtl\:p-128 {
    padding: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:p-136,[dir='rtl'].print\:rtl\:p-136 {
    padding: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:p-160,[dir='rtl'].print\:rtl\:p-160 {
    padding: 16rem
  }

  [dir='rtl'] .print\:rtl\:p-192,[dir='rtl'].print\:rtl\:p-192 {
    padding: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:p-200,[dir='rtl'].print\:rtl\:p-200 {
    padding: 20rem
  }

  [dir='rtl'] .print\:rtl\:p-208,[dir='rtl'].print\:rtl\:p-208 {
    padding: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:p-216,[dir='rtl'].print\:rtl\:p-216 {
    padding: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:p-224,[dir='rtl'].print\:rtl\:p-224 {
    padding: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:p-256,[dir='rtl'].print\:rtl\:p-256 {
    padding: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:p-288,[dir='rtl'].print\:rtl\:p-288 {
    padding: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:p-320,[dir='rtl'].print\:rtl\:p-320 {
    padding: 32rem
  }

  [dir='rtl'] .print\:rtl\:p-360,[dir='rtl'].print\:rtl\:p-360 {
    padding: 36rem
  }

  [dir='rtl'] .print\:rtl\:p-384,[dir='rtl'].print\:rtl\:p-384 {
    padding: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:p-400,[dir='rtl'].print\:rtl\:p-400 {
    padding: 40rem
  }

  [dir='rtl'] .print\:rtl\:p-512,[dir='rtl'].print\:rtl\:p-512 {
    padding: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:p-640,[dir='rtl'].print\:rtl\:p-640 {
    padding: 64rem
  }

  [dir='rtl'] .print\:rtl\:p-xs,[dir='rtl'].print\:rtl\:p-xs {
    padding: 32rem
  }

  [dir='rtl'] .print\:rtl\:p-sm,[dir='rtl'].print\:rtl\:p-sm {
    padding: 48rem
  }

  [dir='rtl'] .print\:rtl\:p-md,[dir='rtl'].print\:rtl\:p-md {
    padding: 64rem
  }

  [dir='rtl'] .print\:rtl\:p-lg,[dir='rtl'].print\:rtl\:p-lg {
    padding: 80rem
  }

  [dir='rtl'] .print\:rtl\:p-xl,[dir='rtl'].print\:rtl\:p-xl {
    padding: 96rem
  }

  [dir='rtl'] .print\:rtl\:p-2xl,[dir='rtl'].print\:rtl\:p-2xl {
    padding: 112rem
  }

  [dir='rtl'] .print\:rtl\:p-3xl,[dir='rtl'].print\:rtl\:p-3xl {
    padding: 128rem
  }

  [dir='rtl'] .print\:rtl\:p-4xl,[dir='rtl'].print\:rtl\:p-4xl {
    padding: 144rem
  }

  [dir='rtl'] .print\:rtl\:p-5xl,[dir='rtl'].print\:rtl\:p-5xl {
    padding: 160rem
  }

  [dir='rtl'] .print\:rtl\:p-px,[dir='rtl'].print\:rtl\:p-px {
    padding: 1px
  }

  [dir='rtl'] .print\:rtl\:p-0\.5,[dir='rtl'].print\:rtl\:p-0\.5 {
    padding: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:p-1\.5,[dir='rtl'].print\:rtl\:p-1\.5 {
    padding: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:p-2\.5,[dir='rtl'].print\:rtl\:p-2\.5 {
    padding: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:p-3\.5,[dir='rtl'].print\:rtl\:p-3\.5 {
    padding: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:py-0,[dir='rtl'].print\:rtl\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  [dir='rtl'] .print\:rtl\:px-0,[dir='rtl'].print\:rtl\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  [dir='rtl'] .print\:rtl\:py-1,[dir='rtl'].print\:rtl\:py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:px-1,[dir='rtl'].print\:rtl\:px-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:py-2,[dir='rtl'].print\:rtl\:py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:px-2,[dir='rtl'].print\:rtl\:px-2 {
    padding-left: 0.2rem;
    padding-right: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:py-3,[dir='rtl'].print\:rtl\:py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:px-3,[dir='rtl'].print\:rtl\:px-3 {
    padding-left: 0.3rem;
    padding-right: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:py-4,[dir='rtl'].print\:rtl\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:px-4,[dir='rtl'].print\:rtl\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:py-5,[dir='rtl'].print\:rtl\:py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:px-5,[dir='rtl'].print\:rtl\:px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:py-6,[dir='rtl'].print\:rtl\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:px-6,[dir='rtl'].print\:rtl\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:py-7,[dir='rtl'].print\:rtl\:py-7 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:px-7,[dir='rtl'].print\:rtl\:px-7 {
    padding-left: 0.7rem;
    padding-right: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:py-8,[dir='rtl'].print\:rtl\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:px-8,[dir='rtl'].print\:rtl\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:py-9,[dir='rtl'].print\:rtl\:py-9 {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:px-9,[dir='rtl'].print\:rtl\:px-9 {
    padding-left: 0.9rem;
    padding-right: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:py-10,[dir='rtl'].print\:rtl\:py-10 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:px-10,[dir='rtl'].print\:rtl\:px-10 {
    padding-left: 1.0rem;
    padding-right: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:py-12,[dir='rtl'].print\:rtl\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:px-12,[dir='rtl'].print\:rtl\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:py-14,[dir='rtl'].print\:rtl\:py-14 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:px-14,[dir='rtl'].print\:rtl\:px-14 {
    padding-left: 1.4rem;
    padding-right: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:py-16,[dir='rtl'].print\:rtl\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:px-16,[dir='rtl'].print\:rtl\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:py-20,[dir='rtl'].print\:rtl\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  [dir='rtl'] .print\:rtl\:px-20,[dir='rtl'].print\:rtl\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  [dir='rtl'] .print\:rtl\:py-24,[dir='rtl'].print\:rtl\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:px-24,[dir='rtl'].print\:rtl\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:py-28,[dir='rtl'].print\:rtl\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:px-28,[dir='rtl'].print\:rtl\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:py-32,[dir='rtl'].print\:rtl\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:px-32,[dir='rtl'].print\:rtl\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:py-36,[dir='rtl'].print\:rtl\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:px-36,[dir='rtl'].print\:rtl\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:py-40,[dir='rtl'].print\:rtl\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  [dir='rtl'] .print\:rtl\:px-40,[dir='rtl'].print\:rtl\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  [dir='rtl'] .print\:rtl\:py-44,[dir='rtl'].print\:rtl\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:px-44,[dir='rtl'].print\:rtl\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:py-48,[dir='rtl'].print\:rtl\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:px-48,[dir='rtl'].print\:rtl\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:py-52,[dir='rtl'].print\:rtl\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:px-52,[dir='rtl'].print\:rtl\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:py-56,[dir='rtl'].print\:rtl\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:px-56,[dir='rtl'].print\:rtl\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:py-60,[dir='rtl'].print\:rtl\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  [dir='rtl'] .print\:rtl\:px-60,[dir='rtl'].print\:rtl\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  [dir='rtl'] .print\:rtl\:py-64,[dir='rtl'].print\:rtl\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:px-64,[dir='rtl'].print\:rtl\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:py-68,[dir='rtl'].print\:rtl\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:px-68,[dir='rtl'].print\:rtl\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:py-72,[dir='rtl'].print\:rtl\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:px-72,[dir='rtl'].print\:rtl\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:py-76,[dir='rtl'].print\:rtl\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:px-76,[dir='rtl'].print\:rtl\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:py-80,[dir='rtl'].print\:rtl\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  [dir='rtl'] .print\:rtl\:px-80,[dir='rtl'].print\:rtl\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  [dir='rtl'] .print\:rtl\:py-84,[dir='rtl'].print\:rtl\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:px-84,[dir='rtl'].print\:rtl\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:py-88,[dir='rtl'].print\:rtl\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:px-88,[dir='rtl'].print\:rtl\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:py-92,[dir='rtl'].print\:rtl\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:px-92,[dir='rtl'].print\:rtl\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:py-96,[dir='rtl'].print\:rtl\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:px-96,[dir='rtl'].print\:rtl\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:py-128,[dir='rtl'].print\:rtl\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:px-128,[dir='rtl'].print\:rtl\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:py-136,[dir='rtl'].print\:rtl\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:px-136,[dir='rtl'].print\:rtl\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:py-160,[dir='rtl'].print\:rtl\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  [dir='rtl'] .print\:rtl\:px-160,[dir='rtl'].print\:rtl\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  [dir='rtl'] .print\:rtl\:py-192,[dir='rtl'].print\:rtl\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:px-192,[dir='rtl'].print\:rtl\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:py-200,[dir='rtl'].print\:rtl\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  [dir='rtl'] .print\:rtl\:px-200,[dir='rtl'].print\:rtl\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  [dir='rtl'] .print\:rtl\:py-208,[dir='rtl'].print\:rtl\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:px-208,[dir='rtl'].print\:rtl\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:py-216,[dir='rtl'].print\:rtl\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:px-216,[dir='rtl'].print\:rtl\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:py-224,[dir='rtl'].print\:rtl\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:px-224,[dir='rtl'].print\:rtl\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:py-256,[dir='rtl'].print\:rtl\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:px-256,[dir='rtl'].print\:rtl\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:py-288,[dir='rtl'].print\:rtl\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:px-288,[dir='rtl'].print\:rtl\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:py-320,[dir='rtl'].print\:rtl\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='rtl'] .print\:rtl\:px-320,[dir='rtl'].print\:rtl\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='rtl'] .print\:rtl\:py-360,[dir='rtl'].print\:rtl\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  [dir='rtl'] .print\:rtl\:px-360,[dir='rtl'].print\:rtl\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  [dir='rtl'] .print\:rtl\:py-384,[dir='rtl'].print\:rtl\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:px-384,[dir='rtl'].print\:rtl\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:py-400,[dir='rtl'].print\:rtl\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  [dir='rtl'] .print\:rtl\:px-400,[dir='rtl'].print\:rtl\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  [dir='rtl'] .print\:rtl\:py-512,[dir='rtl'].print\:rtl\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:px-512,[dir='rtl'].print\:rtl\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:py-640,[dir='rtl'].print\:rtl\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='rtl'] .print\:rtl\:px-640,[dir='rtl'].print\:rtl\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='rtl'] .print\:rtl\:py-xs,[dir='rtl'].print\:rtl\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  [dir='rtl'] .print\:rtl\:px-xs,[dir='rtl'].print\:rtl\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  [dir='rtl'] .print\:rtl\:py-sm,[dir='rtl'].print\:rtl\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  [dir='rtl'] .print\:rtl\:px-sm,[dir='rtl'].print\:rtl\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  [dir='rtl'] .print\:rtl\:py-md,[dir='rtl'].print\:rtl\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  [dir='rtl'] .print\:rtl\:px-md,[dir='rtl'].print\:rtl\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  [dir='rtl'] .print\:rtl\:py-lg,[dir='rtl'].print\:rtl\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  [dir='rtl'] .print\:rtl\:px-lg,[dir='rtl'].print\:rtl\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  [dir='rtl'] .print\:rtl\:py-xl,[dir='rtl'].print\:rtl\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  [dir='rtl'] .print\:rtl\:px-xl,[dir='rtl'].print\:rtl\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  [dir='rtl'] .print\:rtl\:py-2xl,[dir='rtl'].print\:rtl\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  [dir='rtl'] .print\:rtl\:px-2xl,[dir='rtl'].print\:rtl\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  [dir='rtl'] .print\:rtl\:py-3xl,[dir='rtl'].print\:rtl\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  [dir='rtl'] .print\:rtl\:px-3xl,[dir='rtl'].print\:rtl\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  [dir='rtl'] .print\:rtl\:py-4xl,[dir='rtl'].print\:rtl\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  [dir='rtl'] .print\:rtl\:px-4xl,[dir='rtl'].print\:rtl\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  [dir='rtl'] .print\:rtl\:py-5xl,[dir='rtl'].print\:rtl\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  [dir='rtl'] .print\:rtl\:px-5xl,[dir='rtl'].print\:rtl\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  [dir='rtl'] .print\:rtl\:py-px,[dir='rtl'].print\:rtl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  [dir='rtl'] .print\:rtl\:px-px,[dir='rtl'].print\:rtl\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  [dir='rtl'] .print\:rtl\:py-0\.5,[dir='rtl'].print\:rtl\:py-0\.5 {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:px-0\.5,[dir='rtl'].print\:rtl\:px-0\.5 {
    padding-left: 0.05rem;
    padding-right: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:py-1\.5,[dir='rtl'].print\:rtl\:py-1\.5 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:px-1\.5,[dir='rtl'].print\:rtl\:px-1\.5 {
    padding-left: 0.15rem;
    padding-right: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:py-2\.5,[dir='rtl'].print\:rtl\:py-2\.5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:px-2\.5,[dir='rtl'].print\:rtl\:px-2\.5 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:py-3\.5,[dir='rtl'].print\:rtl\:py-3\.5 {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:px-3\.5,[dir='rtl'].print\:rtl\:px-3\.5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:pt-0,[dir='rtl'].print\:rtl\:pt-0 {
    padding-top: 0
  }

  [dir='rtl'] .print\:rtl\:pr-0,[dir='rtl'].print\:rtl\:pr-0 {
    padding-right: 0
  }

  [dir='rtl'] .print\:rtl\:pb-0,[dir='rtl'].print\:rtl\:pb-0 {
    padding-bottom: 0
  }

  [dir='rtl'] .print\:rtl\:pl-0,[dir='rtl'].print\:rtl\:pl-0 {
    padding-left: 0
  }

  [dir='rtl'] .print\:rtl\:pt-1,[dir='rtl'].print\:rtl\:pt-1 {
    padding-top: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:pr-1,[dir='rtl'].print\:rtl\:pr-1 {
    padding-right: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:pb-1,[dir='rtl'].print\:rtl\:pb-1 {
    padding-bottom: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:pl-1,[dir='rtl'].print\:rtl\:pl-1 {
    padding-left: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:pt-2,[dir='rtl'].print\:rtl\:pt-2 {
    padding-top: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:pr-2,[dir='rtl'].print\:rtl\:pr-2 {
    padding-right: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:pb-2,[dir='rtl'].print\:rtl\:pb-2 {
    padding-bottom: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:pl-2,[dir='rtl'].print\:rtl\:pl-2 {
    padding-left: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:pt-3,[dir='rtl'].print\:rtl\:pt-3 {
    padding-top: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:pr-3,[dir='rtl'].print\:rtl\:pr-3 {
    padding-right: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:pb-3,[dir='rtl'].print\:rtl\:pb-3 {
    padding-bottom: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:pl-3,[dir='rtl'].print\:rtl\:pl-3 {
    padding-left: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:pt-4,[dir='rtl'].print\:rtl\:pt-4 {
    padding-top: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:pr-4,[dir='rtl'].print\:rtl\:pr-4 {
    padding-right: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:pb-4,[dir='rtl'].print\:rtl\:pb-4 {
    padding-bottom: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:pl-4,[dir='rtl'].print\:rtl\:pl-4 {
    padding-left: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:pt-5,[dir='rtl'].print\:rtl\:pt-5 {
    padding-top: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:pr-5,[dir='rtl'].print\:rtl\:pr-5 {
    padding-right: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:pb-5,[dir='rtl'].print\:rtl\:pb-5 {
    padding-bottom: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:pl-5,[dir='rtl'].print\:rtl\:pl-5 {
    padding-left: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:pt-6,[dir='rtl'].print\:rtl\:pt-6 {
    padding-top: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:pr-6,[dir='rtl'].print\:rtl\:pr-6 {
    padding-right: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:pb-6,[dir='rtl'].print\:rtl\:pb-6 {
    padding-bottom: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:pl-6,[dir='rtl'].print\:rtl\:pl-6 {
    padding-left: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:pt-7,[dir='rtl'].print\:rtl\:pt-7 {
    padding-top: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:pr-7,[dir='rtl'].print\:rtl\:pr-7 {
    padding-right: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:pb-7,[dir='rtl'].print\:rtl\:pb-7 {
    padding-bottom: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:pl-7,[dir='rtl'].print\:rtl\:pl-7 {
    padding-left: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:pt-8,[dir='rtl'].print\:rtl\:pt-8 {
    padding-top: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:pr-8,[dir='rtl'].print\:rtl\:pr-8 {
    padding-right: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:pb-8,[dir='rtl'].print\:rtl\:pb-8 {
    padding-bottom: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:pl-8,[dir='rtl'].print\:rtl\:pl-8 {
    padding-left: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:pt-9,[dir='rtl'].print\:rtl\:pt-9 {
    padding-top: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:pr-9,[dir='rtl'].print\:rtl\:pr-9 {
    padding-right: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:pb-9,[dir='rtl'].print\:rtl\:pb-9 {
    padding-bottom: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:pl-9,[dir='rtl'].print\:rtl\:pl-9 {
    padding-left: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:pt-10,[dir='rtl'].print\:rtl\:pt-10 {
    padding-top: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:pr-10,[dir='rtl'].print\:rtl\:pr-10 {
    padding-right: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:pb-10,[dir='rtl'].print\:rtl\:pb-10 {
    padding-bottom: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:pl-10,[dir='rtl'].print\:rtl\:pl-10 {
    padding-left: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:pt-12,[dir='rtl'].print\:rtl\:pt-12 {
    padding-top: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:pr-12,[dir='rtl'].print\:rtl\:pr-12 {
    padding-right: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:pb-12,[dir='rtl'].print\:rtl\:pb-12 {
    padding-bottom: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:pl-12,[dir='rtl'].print\:rtl\:pl-12 {
    padding-left: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:pt-14,[dir='rtl'].print\:rtl\:pt-14 {
    padding-top: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:pr-14,[dir='rtl'].print\:rtl\:pr-14 {
    padding-right: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:pb-14,[dir='rtl'].print\:rtl\:pb-14 {
    padding-bottom: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:pl-14,[dir='rtl'].print\:rtl\:pl-14 {
    padding-left: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:pt-16,[dir='rtl'].print\:rtl\:pt-16 {
    padding-top: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:pr-16,[dir='rtl'].print\:rtl\:pr-16 {
    padding-right: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:pb-16,[dir='rtl'].print\:rtl\:pb-16 {
    padding-bottom: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:pl-16,[dir='rtl'].print\:rtl\:pl-16 {
    padding-left: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:pt-20,[dir='rtl'].print\:rtl\:pt-20 {
    padding-top: 2rem
  }

  [dir='rtl'] .print\:rtl\:pr-20,[dir='rtl'].print\:rtl\:pr-20 {
    padding-right: 2rem
  }

  [dir='rtl'] .print\:rtl\:pb-20,[dir='rtl'].print\:rtl\:pb-20 {
    padding-bottom: 2rem
  }

  [dir='rtl'] .print\:rtl\:pl-20,[dir='rtl'].print\:rtl\:pl-20 {
    padding-left: 2rem
  }

  [dir='rtl'] .print\:rtl\:pt-24,[dir='rtl'].print\:rtl\:pt-24 {
    padding-top: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:pr-24,[dir='rtl'].print\:rtl\:pr-24 {
    padding-right: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:pb-24,[dir='rtl'].print\:rtl\:pb-24 {
    padding-bottom: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:pl-24,[dir='rtl'].print\:rtl\:pl-24 {
    padding-left: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:pt-28,[dir='rtl'].print\:rtl\:pt-28 {
    padding-top: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:pr-28,[dir='rtl'].print\:rtl\:pr-28 {
    padding-right: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:pb-28,[dir='rtl'].print\:rtl\:pb-28 {
    padding-bottom: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:pl-28,[dir='rtl'].print\:rtl\:pl-28 {
    padding-left: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:pt-32,[dir='rtl'].print\:rtl\:pt-32 {
    padding-top: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:pr-32,[dir='rtl'].print\:rtl\:pr-32 {
    padding-right: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:pb-32,[dir='rtl'].print\:rtl\:pb-32 {
    padding-bottom: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:pl-32,[dir='rtl'].print\:rtl\:pl-32 {
    padding-left: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:pt-36,[dir='rtl'].print\:rtl\:pt-36 {
    padding-top: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:pr-36,[dir='rtl'].print\:rtl\:pr-36 {
    padding-right: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:pb-36,[dir='rtl'].print\:rtl\:pb-36 {
    padding-bottom: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:pl-36,[dir='rtl'].print\:rtl\:pl-36 {
    padding-left: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:pt-40,[dir='rtl'].print\:rtl\:pt-40 {
    padding-top: 4rem
  }

  [dir='rtl'] .print\:rtl\:pr-40,[dir='rtl'].print\:rtl\:pr-40 {
    padding-right: 4rem
  }

  [dir='rtl'] .print\:rtl\:pb-40,[dir='rtl'].print\:rtl\:pb-40 {
    padding-bottom: 4rem
  }

  [dir='rtl'] .print\:rtl\:pl-40,[dir='rtl'].print\:rtl\:pl-40 {
    padding-left: 4rem
  }

  [dir='rtl'] .print\:rtl\:pt-44,[dir='rtl'].print\:rtl\:pt-44 {
    padding-top: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:pr-44,[dir='rtl'].print\:rtl\:pr-44 {
    padding-right: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:pb-44,[dir='rtl'].print\:rtl\:pb-44 {
    padding-bottom: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:pl-44,[dir='rtl'].print\:rtl\:pl-44 {
    padding-left: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:pt-48,[dir='rtl'].print\:rtl\:pt-48 {
    padding-top: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:pr-48,[dir='rtl'].print\:rtl\:pr-48 {
    padding-right: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:pb-48,[dir='rtl'].print\:rtl\:pb-48 {
    padding-bottom: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:pl-48,[dir='rtl'].print\:rtl\:pl-48 {
    padding-left: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:pt-52,[dir='rtl'].print\:rtl\:pt-52 {
    padding-top: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:pr-52,[dir='rtl'].print\:rtl\:pr-52 {
    padding-right: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:pb-52,[dir='rtl'].print\:rtl\:pb-52 {
    padding-bottom: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:pl-52,[dir='rtl'].print\:rtl\:pl-52 {
    padding-left: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:pt-56,[dir='rtl'].print\:rtl\:pt-56 {
    padding-top: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:pr-56,[dir='rtl'].print\:rtl\:pr-56 {
    padding-right: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:pb-56,[dir='rtl'].print\:rtl\:pb-56 {
    padding-bottom: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:pl-56,[dir='rtl'].print\:rtl\:pl-56 {
    padding-left: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:pt-60,[dir='rtl'].print\:rtl\:pt-60 {
    padding-top: 6rem
  }

  [dir='rtl'] .print\:rtl\:pr-60,[dir='rtl'].print\:rtl\:pr-60 {
    padding-right: 6rem
  }

  [dir='rtl'] .print\:rtl\:pb-60,[dir='rtl'].print\:rtl\:pb-60 {
    padding-bottom: 6rem
  }

  [dir='rtl'] .print\:rtl\:pl-60,[dir='rtl'].print\:rtl\:pl-60 {
    padding-left: 6rem
  }

  [dir='rtl'] .print\:rtl\:pt-64,[dir='rtl'].print\:rtl\:pt-64 {
    padding-top: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:pr-64,[dir='rtl'].print\:rtl\:pr-64 {
    padding-right: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:pb-64,[dir='rtl'].print\:rtl\:pb-64 {
    padding-bottom: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:pl-64,[dir='rtl'].print\:rtl\:pl-64 {
    padding-left: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:pt-68,[dir='rtl'].print\:rtl\:pt-68 {
    padding-top: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:pr-68,[dir='rtl'].print\:rtl\:pr-68 {
    padding-right: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:pb-68,[dir='rtl'].print\:rtl\:pb-68 {
    padding-bottom: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:pl-68,[dir='rtl'].print\:rtl\:pl-68 {
    padding-left: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:pt-72,[dir='rtl'].print\:rtl\:pt-72 {
    padding-top: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:pr-72,[dir='rtl'].print\:rtl\:pr-72 {
    padding-right: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:pb-72,[dir='rtl'].print\:rtl\:pb-72 {
    padding-bottom: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:pl-72,[dir='rtl'].print\:rtl\:pl-72 {
    padding-left: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:pt-76,[dir='rtl'].print\:rtl\:pt-76 {
    padding-top: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:pr-76,[dir='rtl'].print\:rtl\:pr-76 {
    padding-right: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:pb-76,[dir='rtl'].print\:rtl\:pb-76 {
    padding-bottom: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:pl-76,[dir='rtl'].print\:rtl\:pl-76 {
    padding-left: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:pt-80,[dir='rtl'].print\:rtl\:pt-80 {
    padding-top: 8rem
  }

  [dir='rtl'] .print\:rtl\:pr-80,[dir='rtl'].print\:rtl\:pr-80 {
    padding-right: 8rem
  }

  [dir='rtl'] .print\:rtl\:pb-80,[dir='rtl'].print\:rtl\:pb-80 {
    padding-bottom: 8rem
  }

  [dir='rtl'] .print\:rtl\:pl-80,[dir='rtl'].print\:rtl\:pl-80 {
    padding-left: 8rem
  }

  [dir='rtl'] .print\:rtl\:pt-84,[dir='rtl'].print\:rtl\:pt-84 {
    padding-top: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:pr-84,[dir='rtl'].print\:rtl\:pr-84 {
    padding-right: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:pb-84,[dir='rtl'].print\:rtl\:pb-84 {
    padding-bottom: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:pl-84,[dir='rtl'].print\:rtl\:pl-84 {
    padding-left: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:pt-88,[dir='rtl'].print\:rtl\:pt-88 {
    padding-top: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:pr-88,[dir='rtl'].print\:rtl\:pr-88 {
    padding-right: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:pb-88,[dir='rtl'].print\:rtl\:pb-88 {
    padding-bottom: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:pl-88,[dir='rtl'].print\:rtl\:pl-88 {
    padding-left: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:pt-92,[dir='rtl'].print\:rtl\:pt-92 {
    padding-top: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:pr-92,[dir='rtl'].print\:rtl\:pr-92 {
    padding-right: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:pb-92,[dir='rtl'].print\:rtl\:pb-92 {
    padding-bottom: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:pl-92,[dir='rtl'].print\:rtl\:pl-92 {
    padding-left: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:pt-96,[dir='rtl'].print\:rtl\:pt-96 {
    padding-top: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:pr-96,[dir='rtl'].print\:rtl\:pr-96 {
    padding-right: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:pb-96,[dir='rtl'].print\:rtl\:pb-96 {
    padding-bottom: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:pl-96,[dir='rtl'].print\:rtl\:pl-96 {
    padding-left: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:pt-128,[dir='rtl'].print\:rtl\:pt-128 {
    padding-top: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:pr-128,[dir='rtl'].print\:rtl\:pr-128 {
    padding-right: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:pb-128,[dir='rtl'].print\:rtl\:pb-128 {
    padding-bottom: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:pl-128,[dir='rtl'].print\:rtl\:pl-128 {
    padding-left: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:pt-136,[dir='rtl'].print\:rtl\:pt-136 {
    padding-top: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:pr-136,[dir='rtl'].print\:rtl\:pr-136 {
    padding-right: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:pb-136,[dir='rtl'].print\:rtl\:pb-136 {
    padding-bottom: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:pl-136,[dir='rtl'].print\:rtl\:pl-136 {
    padding-left: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:pt-160,[dir='rtl'].print\:rtl\:pt-160 {
    padding-top: 16rem
  }

  [dir='rtl'] .print\:rtl\:pr-160,[dir='rtl'].print\:rtl\:pr-160 {
    padding-right: 16rem
  }

  [dir='rtl'] .print\:rtl\:pb-160,[dir='rtl'].print\:rtl\:pb-160 {
    padding-bottom: 16rem
  }

  [dir='rtl'] .print\:rtl\:pl-160,[dir='rtl'].print\:rtl\:pl-160 {
    padding-left: 16rem
  }

  [dir='rtl'] .print\:rtl\:pt-192,[dir='rtl'].print\:rtl\:pt-192 {
    padding-top: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:pr-192,[dir='rtl'].print\:rtl\:pr-192 {
    padding-right: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:pb-192,[dir='rtl'].print\:rtl\:pb-192 {
    padding-bottom: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:pl-192,[dir='rtl'].print\:rtl\:pl-192 {
    padding-left: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:pt-200,[dir='rtl'].print\:rtl\:pt-200 {
    padding-top: 20rem
  }

  [dir='rtl'] .print\:rtl\:pr-200,[dir='rtl'].print\:rtl\:pr-200 {
    padding-right: 20rem
  }

  [dir='rtl'] .print\:rtl\:pb-200,[dir='rtl'].print\:rtl\:pb-200 {
    padding-bottom: 20rem
  }

  [dir='rtl'] .print\:rtl\:pl-200,[dir='rtl'].print\:rtl\:pl-200 {
    padding-left: 20rem
  }

  [dir='rtl'] .print\:rtl\:pt-208,[dir='rtl'].print\:rtl\:pt-208 {
    padding-top: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:pr-208,[dir='rtl'].print\:rtl\:pr-208 {
    padding-right: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:pb-208,[dir='rtl'].print\:rtl\:pb-208 {
    padding-bottom: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:pl-208,[dir='rtl'].print\:rtl\:pl-208 {
    padding-left: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:pt-216,[dir='rtl'].print\:rtl\:pt-216 {
    padding-top: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:pr-216,[dir='rtl'].print\:rtl\:pr-216 {
    padding-right: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:pb-216,[dir='rtl'].print\:rtl\:pb-216 {
    padding-bottom: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:pl-216,[dir='rtl'].print\:rtl\:pl-216 {
    padding-left: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:pt-224,[dir='rtl'].print\:rtl\:pt-224 {
    padding-top: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:pr-224,[dir='rtl'].print\:rtl\:pr-224 {
    padding-right: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:pb-224,[dir='rtl'].print\:rtl\:pb-224 {
    padding-bottom: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:pl-224,[dir='rtl'].print\:rtl\:pl-224 {
    padding-left: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:pt-256,[dir='rtl'].print\:rtl\:pt-256 {
    padding-top: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:pr-256,[dir='rtl'].print\:rtl\:pr-256 {
    padding-right: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:pb-256,[dir='rtl'].print\:rtl\:pb-256 {
    padding-bottom: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:pl-256,[dir='rtl'].print\:rtl\:pl-256 {
    padding-left: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:pt-288,[dir='rtl'].print\:rtl\:pt-288 {
    padding-top: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:pr-288,[dir='rtl'].print\:rtl\:pr-288 {
    padding-right: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:pb-288,[dir='rtl'].print\:rtl\:pb-288 {
    padding-bottom: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:pl-288,[dir='rtl'].print\:rtl\:pl-288 {
    padding-left: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:pt-320,[dir='rtl'].print\:rtl\:pt-320 {
    padding-top: 32rem
  }

  [dir='rtl'] .print\:rtl\:pr-320,[dir='rtl'].print\:rtl\:pr-320 {
    padding-right: 32rem
  }

  [dir='rtl'] .print\:rtl\:pb-320,[dir='rtl'].print\:rtl\:pb-320 {
    padding-bottom: 32rem
  }

  [dir='rtl'] .print\:rtl\:pl-320,[dir='rtl'].print\:rtl\:pl-320 {
    padding-left: 32rem
  }

  [dir='rtl'] .print\:rtl\:pt-360,[dir='rtl'].print\:rtl\:pt-360 {
    padding-top: 36rem
  }

  [dir='rtl'] .print\:rtl\:pr-360,[dir='rtl'].print\:rtl\:pr-360 {
    padding-right: 36rem
  }

  [dir='rtl'] .print\:rtl\:pb-360,[dir='rtl'].print\:rtl\:pb-360 {
    padding-bottom: 36rem
  }

  [dir='rtl'] .print\:rtl\:pl-360,[dir='rtl'].print\:rtl\:pl-360 {
    padding-left: 36rem
  }

  [dir='rtl'] .print\:rtl\:pt-384,[dir='rtl'].print\:rtl\:pt-384 {
    padding-top: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:pr-384,[dir='rtl'].print\:rtl\:pr-384 {
    padding-right: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:pb-384,[dir='rtl'].print\:rtl\:pb-384 {
    padding-bottom: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:pl-384,[dir='rtl'].print\:rtl\:pl-384 {
    padding-left: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:pt-400,[dir='rtl'].print\:rtl\:pt-400 {
    padding-top: 40rem
  }

  [dir='rtl'] .print\:rtl\:pr-400,[dir='rtl'].print\:rtl\:pr-400 {
    padding-right: 40rem
  }

  [dir='rtl'] .print\:rtl\:pb-400,[dir='rtl'].print\:rtl\:pb-400 {
    padding-bottom: 40rem
  }

  [dir='rtl'] .print\:rtl\:pl-400,[dir='rtl'].print\:rtl\:pl-400 {
    padding-left: 40rem
  }

  [dir='rtl'] .print\:rtl\:pt-512,[dir='rtl'].print\:rtl\:pt-512 {
    padding-top: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:pr-512,[dir='rtl'].print\:rtl\:pr-512 {
    padding-right: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:pb-512,[dir='rtl'].print\:rtl\:pb-512 {
    padding-bottom: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:pl-512,[dir='rtl'].print\:rtl\:pl-512 {
    padding-left: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:pt-640,[dir='rtl'].print\:rtl\:pt-640 {
    padding-top: 64rem
  }

  [dir='rtl'] .print\:rtl\:pr-640,[dir='rtl'].print\:rtl\:pr-640 {
    padding-right: 64rem
  }

  [dir='rtl'] .print\:rtl\:pb-640,[dir='rtl'].print\:rtl\:pb-640 {
    padding-bottom: 64rem
  }

  [dir='rtl'] .print\:rtl\:pl-640,[dir='rtl'].print\:rtl\:pl-640 {
    padding-left: 64rem
  }

  [dir='rtl'] .print\:rtl\:pt-xs,[dir='rtl'].print\:rtl\:pt-xs {
    padding-top: 32rem
  }

  [dir='rtl'] .print\:rtl\:pr-xs,[dir='rtl'].print\:rtl\:pr-xs {
    padding-right: 32rem
  }

  [dir='rtl'] .print\:rtl\:pb-xs,[dir='rtl'].print\:rtl\:pb-xs {
    padding-bottom: 32rem
  }

  [dir='rtl'] .print\:rtl\:pl-xs,[dir='rtl'].print\:rtl\:pl-xs {
    padding-left: 32rem
  }

  [dir='rtl'] .print\:rtl\:pt-sm,[dir='rtl'].print\:rtl\:pt-sm {
    padding-top: 48rem
  }

  [dir='rtl'] .print\:rtl\:pr-sm,[dir='rtl'].print\:rtl\:pr-sm {
    padding-right: 48rem
  }

  [dir='rtl'] .print\:rtl\:pb-sm,[dir='rtl'].print\:rtl\:pb-sm {
    padding-bottom: 48rem
  }

  [dir='rtl'] .print\:rtl\:pl-sm,[dir='rtl'].print\:rtl\:pl-sm {
    padding-left: 48rem
  }

  [dir='rtl'] .print\:rtl\:pt-md,[dir='rtl'].print\:rtl\:pt-md {
    padding-top: 64rem
  }

  [dir='rtl'] .print\:rtl\:pr-md,[dir='rtl'].print\:rtl\:pr-md {
    padding-right: 64rem
  }

  [dir='rtl'] .print\:rtl\:pb-md,[dir='rtl'].print\:rtl\:pb-md {
    padding-bottom: 64rem
  }

  [dir='rtl'] .print\:rtl\:pl-md,[dir='rtl'].print\:rtl\:pl-md {
    padding-left: 64rem
  }

  [dir='rtl'] .print\:rtl\:pt-lg,[dir='rtl'].print\:rtl\:pt-lg {
    padding-top: 80rem
  }

  [dir='rtl'] .print\:rtl\:pr-lg,[dir='rtl'].print\:rtl\:pr-lg {
    padding-right: 80rem
  }

  [dir='rtl'] .print\:rtl\:pb-lg,[dir='rtl'].print\:rtl\:pb-lg {
    padding-bottom: 80rem
  }

  [dir='rtl'] .print\:rtl\:pl-lg,[dir='rtl'].print\:rtl\:pl-lg {
    padding-left: 80rem
  }

  [dir='rtl'] .print\:rtl\:pt-xl,[dir='rtl'].print\:rtl\:pt-xl {
    padding-top: 96rem
  }

  [dir='rtl'] .print\:rtl\:pr-xl,[dir='rtl'].print\:rtl\:pr-xl {
    padding-right: 96rem
  }

  [dir='rtl'] .print\:rtl\:pb-xl,[dir='rtl'].print\:rtl\:pb-xl {
    padding-bottom: 96rem
  }

  [dir='rtl'] .print\:rtl\:pl-xl,[dir='rtl'].print\:rtl\:pl-xl {
    padding-left: 96rem
  }

  [dir='rtl'] .print\:rtl\:pt-2xl,[dir='rtl'].print\:rtl\:pt-2xl {
    padding-top: 112rem
  }

  [dir='rtl'] .print\:rtl\:pr-2xl,[dir='rtl'].print\:rtl\:pr-2xl {
    padding-right: 112rem
  }

  [dir='rtl'] .print\:rtl\:pb-2xl,[dir='rtl'].print\:rtl\:pb-2xl {
    padding-bottom: 112rem
  }

  [dir='rtl'] .print\:rtl\:pl-2xl,[dir='rtl'].print\:rtl\:pl-2xl {
    padding-left: 112rem
  }

  [dir='rtl'] .print\:rtl\:pt-3xl,[dir='rtl'].print\:rtl\:pt-3xl {
    padding-top: 128rem
  }

  [dir='rtl'] .print\:rtl\:pr-3xl,[dir='rtl'].print\:rtl\:pr-3xl {
    padding-right: 128rem
  }

  [dir='rtl'] .print\:rtl\:pb-3xl,[dir='rtl'].print\:rtl\:pb-3xl {
    padding-bottom: 128rem
  }

  [dir='rtl'] .print\:rtl\:pl-3xl,[dir='rtl'].print\:rtl\:pl-3xl {
    padding-left: 128rem
  }

  [dir='rtl'] .print\:rtl\:pt-4xl,[dir='rtl'].print\:rtl\:pt-4xl {
    padding-top: 144rem
  }

  [dir='rtl'] .print\:rtl\:pr-4xl,[dir='rtl'].print\:rtl\:pr-4xl {
    padding-right: 144rem
  }

  [dir='rtl'] .print\:rtl\:pb-4xl,[dir='rtl'].print\:rtl\:pb-4xl {
    padding-bottom: 144rem
  }

  [dir='rtl'] .print\:rtl\:pl-4xl,[dir='rtl'].print\:rtl\:pl-4xl {
    padding-left: 144rem
  }

  [dir='rtl'] .print\:rtl\:pt-5xl,[dir='rtl'].print\:rtl\:pt-5xl {
    padding-top: 160rem
  }

  [dir='rtl'] .print\:rtl\:pr-5xl,[dir='rtl'].print\:rtl\:pr-5xl {
    padding-right: 160rem
  }

  [dir='rtl'] .print\:rtl\:pb-5xl,[dir='rtl'].print\:rtl\:pb-5xl {
    padding-bottom: 160rem
  }

  [dir='rtl'] .print\:rtl\:pl-5xl,[dir='rtl'].print\:rtl\:pl-5xl {
    padding-left: 160rem
  }

  [dir='rtl'] .print\:rtl\:pt-px,[dir='rtl'].print\:rtl\:pt-px {
    padding-top: 1px
  }

  [dir='rtl'] .print\:rtl\:pr-px,[dir='rtl'].print\:rtl\:pr-px {
    padding-right: 1px
  }

  [dir='rtl'] .print\:rtl\:pb-px,[dir='rtl'].print\:rtl\:pb-px {
    padding-bottom: 1px
  }

  [dir='rtl'] .print\:rtl\:pl-px,[dir='rtl'].print\:rtl\:pl-px {
    padding-left: 1px
  }

  [dir='rtl'] .print\:rtl\:pt-0\.5,[dir='rtl'].print\:rtl\:pt-0\.5 {
    padding-top: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:pr-0\.5,[dir='rtl'].print\:rtl\:pr-0\.5 {
    padding-right: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:pb-0\.5,[dir='rtl'].print\:rtl\:pb-0\.5 {
    padding-bottom: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:pl-0\.5,[dir='rtl'].print\:rtl\:pl-0\.5 {
    padding-left: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:pt-1\.5,[dir='rtl'].print\:rtl\:pt-1\.5 {
    padding-top: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:pr-1\.5,[dir='rtl'].print\:rtl\:pr-1\.5 {
    padding-right: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:pb-1\.5,[dir='rtl'].print\:rtl\:pb-1\.5 {
    padding-bottom: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:pl-1\.5,[dir='rtl'].print\:rtl\:pl-1\.5 {
    padding-left: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:pt-2\.5,[dir='rtl'].print\:rtl\:pt-2\.5 {
    padding-top: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:pr-2\.5,[dir='rtl'].print\:rtl\:pr-2\.5 {
    padding-right: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:pb-2\.5,[dir='rtl'].print\:rtl\:pb-2\.5 {
    padding-bottom: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:pl-2\.5,[dir='rtl'].print\:rtl\:pl-2\.5 {
    padding-left: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:pt-3\.5,[dir='rtl'].print\:rtl\:pt-3\.5 {
    padding-top: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:pr-3\.5,[dir='rtl'].print\:rtl\:pr-3\.5 {
    padding-right: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:pb-3\.5,[dir='rtl'].print\:rtl\:pb-3\.5 {
    padding-bottom: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:pl-3\.5,[dir='rtl'].print\:rtl\:pl-3\.5 {
    padding-left: 0.35rem
  }

  .print\:placeholder-transparent::placeholder {
    color: transparent
  }

  .print\:placeholder-current::placeholder {
    color: currentColor
  }

  .print\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-grey-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-grey-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-grey-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-grey-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-grey-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-grey-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-grey-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-grey-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-grey-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-grey-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-grey-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-grey-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-grey-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-grey-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-gray-hover::placeholder {
    color: rgba(0, 0, 0, 0.04)
  }

  .print\:placeholder-gray-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-gray-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-gray-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-gray-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-red::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-red-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-red-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-red-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-red-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-orange-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-orange-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-orange-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-orange-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-orange-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-orange-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-orange-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-orange-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-orange-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-orange-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-orange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-orange-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-orange-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-orange-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-orange-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-orange-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-orange-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-orange-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-orange-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-orange-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-orange-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-orange-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-orange-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-orange-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-orange-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-orange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-orange-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-orange-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-orange-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-orange-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-yellow::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-yellow-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-yellow-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-yellow-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-yellow-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-green-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-green-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-green-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-green-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-green-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-green-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-green-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-green-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-teal-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-teal-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-teal-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-teal-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-teal-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-teal-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-teal-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-teal-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-teal-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-teal-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-teal::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-teal-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-teal-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-teal-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-teal-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-blue-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-blue-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-blue-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-light-blue-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-indigo::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-indigo-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-indigo-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-indigo-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-indigo-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-purple::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-purple-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-purple-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-purple-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-purple-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-purple::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-purple-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-purple-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-purple-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-deep-purple-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-pink::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-pink-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-pink-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-pink-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-pink-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-lime-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-lime-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-lime-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-lime-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-lime-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-lime-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-lime-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-lime-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-lime-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-lime-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-lime::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-lime-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-lime-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-lime-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-lime-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-amber-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-amber-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-amber-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-amber-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-amber-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-amber-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-amber-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-amber-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-amber-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-amber-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-amber::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-amber-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-amber-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-amber-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-amber-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-brown-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-brown-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-brown-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-brown-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-brown-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-brown-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-brown-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-brown-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-brown-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-brown-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-brown::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-brown-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-brown-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-brown-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-brown-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-gray-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-gray-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-gray-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-blue-gray-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-cyan-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-cyan-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-cyan-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-cyan-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-cyan-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-cyan-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-cyan-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-cyan-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-cyan-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-cyan-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-cyan::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-cyan-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-cyan-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-cyan-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-cyan-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent
  }

  .print\:focus\:placeholder-current:focus::placeholder {
    color: currentColor
  }

  .print\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-grey-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-grey-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-grey-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-grey-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-grey-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-grey-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-grey-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-grey-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-grey-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-grey-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-grey-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-grey-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-grey-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-grey-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-gray-hover:focus::placeholder {
    color: rgba(0, 0, 0, 0.04)
  }

  .print\:focus\:placeholder-gray-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-gray-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-gray-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-gray-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-red:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-red-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-red-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-red-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-red-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-orange-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-orange-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-orange-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-orange-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-orange-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-orange-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-orange-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-orange-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-orange-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-orange-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-orange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-orange-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-orange-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-orange-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-orange-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-orange-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-orange-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-orange-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-orange-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-orange-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-orange-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-orange-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-orange-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-orange-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-orange-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-orange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-orange-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-orange-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-orange-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-orange-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-yellow:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-yellow-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-yellow-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-yellow-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-yellow-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-green-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-green-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-green-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-green-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-green-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-green-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-green-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-green-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-teal-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-teal-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-teal-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-teal-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-teal-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-teal-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-teal-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-teal-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-teal-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-teal-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-teal:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-teal-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-teal-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-teal-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-teal-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-blue-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-blue-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-blue-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-light-blue-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-indigo:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-indigo-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-indigo-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-indigo-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-indigo-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-purple:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-purple-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-purple-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-purple-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-purple-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-purple:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-purple-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-purple-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-purple-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-deep-purple-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-pink:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-pink-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-pink-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-pink-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-pink-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-lime-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-lime-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-lime-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-lime-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-lime-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-lime-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-lime-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-lime-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-lime-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-lime-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-lime:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-lime-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-lime-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-lime-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-lime-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-amber-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-amber-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-amber-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-amber-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-amber-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-amber-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-amber-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-amber-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-amber-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-amber-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-amber:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-amber-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-amber-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-amber-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-amber-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-brown-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-brown-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-brown-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-brown-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-brown-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-brown-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-brown-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-brown-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-brown-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-brown-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-brown:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-brown-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-brown-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-brown-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-brown-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-gray-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-gray-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-gray-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-blue-gray-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-cyan-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-cyan-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-cyan-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-cyan-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-cyan-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-cyan-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-cyan-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-cyan-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-cyan-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-cyan-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-cyan:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-cyan-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-cyan-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-cyan-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
  }

  .print\:focus\:placeholder-cyan-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
  }

  .print\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0
  }

  .print\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05
  }

  .print\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1
  }

  .print\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2
  }

  .print\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25
  }

  .print\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3
  }

  .print\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4
  }

  .print\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5
  }

  .print\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6
  }

  .print\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7
  }

  .print\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75
  }

  .print\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8
  }

  .print\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9
  }

  .print\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95
  }

  .print\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1
  }

  .print\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0
  }

  .print\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05
  }

  .print\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1
  }

  .print\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2
  }

  .print\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25
  }

  .print\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3
  }

  .print\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4
  }

  .print\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5
  }

  .print\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6
  }

  .print\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7
  }

  .print\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75
  }

  .print\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8
  }

  .print\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9
  }

  .print\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95
  }

  .print\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1
  }

  .print\:pointer-events-none {
    pointer-events: none
  }

  .print\:pointer-events-auto {
    pointer-events: auto
  }

  .print\:static {
    position: static
  }

  .print\:fixed {
    position: fixed
  }

  .print\:absolute {
    position: absolute
  }

  .print\:relative {
    position: relative
  }

  .print\:sticky {
    position: sticky
  }

  .print\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .print\:inset-1 {
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem
  }

  .print\:inset-2 {
    top: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem
  }

  .print\:inset-3 {
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem
  }

  .print\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  .print\:inset-5 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  .print\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  .print\:inset-7 {
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
    left: 0.7rem
  }

  .print\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  .print\:inset-9 {
    top: 0.9rem;
    right: 0.9rem;
    bottom: 0.9rem;
    left: 0.9rem
  }

  .print\:inset-10 {
    top: 1.0rem;
    right: 1.0rem;
    bottom: 1.0rem;
    left: 1.0rem
  }

  .print\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  .print\:inset-14 {
    top: 1.4rem;
    right: 1.4rem;
    bottom: 1.4rem;
    left: 1.4rem
  }

  .print\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  .print\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  .print\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  .print\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  .print\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  .print\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  .print\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  .print\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  .print\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  .print\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  .print\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  .print\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  .print\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  .print\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  .print\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  .print\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  .print\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  .print\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  .print\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  .print\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  .print\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  .print\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  .print\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  .print\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  .print\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  .print\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  .print\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  .print\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  .print\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  .print\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  .print\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  .print\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  .print\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  .print\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  .print\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  .print\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  .print\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  .print\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .print\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  .print\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  .print\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  .print\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  .print\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  .print\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  .print\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  .print\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  .print\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  .print\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  .print\:inset-0\.5 {
    top: 0.05rem;
    right: 0.05rem;
    bottom: 0.05rem;
    left: 0.05rem
  }

  .print\:inset-1\.5 {
    top: 0.15rem;
    right: 0.15rem;
    bottom: 0.15rem;
    left: 0.15rem
  }

  .print\:inset-2\.5 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  .print\:inset-3\.5 {
    top: 0.35rem;
    right: 0.35rem;
    bottom: 0.35rem;
    left: 0.35rem
  }

  .print\:-inset-1 {
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem
  }

  .print\:-inset-2 {
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem
  }

  .print\:-inset-3 {
    top: -0.3rem;
    right: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem
  }

  .print\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  .print\:-inset-5 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  .print\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  .print\:-inset-7 {
    top: -0.7rem;
    right: -0.7rem;
    bottom: -0.7rem;
    left: -0.7rem
  }

  .print\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  .print\:-inset-9 {
    top: -0.9rem;
    right: -0.9rem;
    bottom: -0.9rem;
    left: -0.9rem
  }

  .print\:-inset-10 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  .print\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  .print\:-inset-14 {
    top: -1.4rem;
    right: -1.4rem;
    bottom: -1.4rem;
    left: -1.4rem
  }

  .print\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  .print\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  .print\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  .print\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  .print\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  .print\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  .print\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  .print\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  .print\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  .print\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  .print\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  .print\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  .print\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  .print\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  .print\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  .print\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  .print\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  .print\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  .print\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  .print\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  .print\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  .print\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  .print\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  .print\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  .print\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  .print\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  .print\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  .print\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  .print\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  .print\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  .print\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  .print\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  .print\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  .print\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  .print\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  .print\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  .print\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  .print\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  .print\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  .print\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  .print\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  .print\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  .print\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  .print\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  .print\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  .print\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  .print\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  .print\:-inset-0\.5 {
    top: -0.05rem;
    right: -0.05rem;
    bottom: -0.05rem;
    left: -0.05rem
  }

  .print\:-inset-1\.5 {
    top: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    left: -0.15rem
  }

  .print\:-inset-2\.5 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  .print\:-inset-3\.5 {
    top: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    left: -0.35rem
  }

  .print\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  .print\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  .print\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  .print\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  .print\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  .print\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  .print\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  .print\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  .print\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  .print\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  .print\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  .print\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  .print\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  .print\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  .print\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .print\:inset-x-0 {
    right: 0;
    left: 0
  }

  .print\:inset-y-1 {
    top: 0.1rem;
    bottom: 0.1rem
  }

  .print\:inset-x-1 {
    right: 0.1rem;
    left: 0.1rem
  }

  .print\:inset-y-2 {
    top: 0.2rem;
    bottom: 0.2rem
  }

  .print\:inset-x-2 {
    right: 0.2rem;
    left: 0.2rem
  }

  .print\:inset-y-3 {
    top: 0.3rem;
    bottom: 0.3rem
  }

  .print\:inset-x-3 {
    right: 0.3rem;
    left: 0.3rem
  }

  .print\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  .print\:inset-x-4 {
    right: 0.4rem;
    left: 0.4rem
  }

  .print\:inset-y-5 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  .print\:inset-x-5 {
    right: 0.5rem;
    left: 0.5rem
  }

  .print\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  .print\:inset-x-6 {
    right: 0.6rem;
    left: 0.6rem
  }

  .print\:inset-y-7 {
    top: 0.7rem;
    bottom: 0.7rem
  }

  .print\:inset-x-7 {
    right: 0.7rem;
    left: 0.7rem
  }

  .print\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  .print\:inset-x-8 {
    right: 0.8rem;
    left: 0.8rem
  }

  .print\:inset-y-9 {
    top: 0.9rem;
    bottom: 0.9rem
  }

  .print\:inset-x-9 {
    right: 0.9rem;
    left: 0.9rem
  }

  .print\:inset-y-10 {
    top: 1.0rem;
    bottom: 1.0rem
  }

  .print\:inset-x-10 {
    right: 1.0rem;
    left: 1.0rem
  }

  .print\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  .print\:inset-x-12 {
    right: 1.2rem;
    left: 1.2rem
  }

  .print\:inset-y-14 {
    top: 1.4rem;
    bottom: 1.4rem
  }

  .print\:inset-x-14 {
    right: 1.4rem;
    left: 1.4rem
  }

  .print\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  .print\:inset-x-16 {
    right: 1.6rem;
    left: 1.6rem
  }

  .print\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  .print\:inset-x-20 {
    right: 2rem;
    left: 2rem
  }

  .print\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  .print\:inset-x-24 {
    right: 2.4rem;
    left: 2.4rem
  }

  .print\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  .print\:inset-x-28 {
    right: 2.8rem;
    left: 2.8rem
  }

  .print\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  .print\:inset-x-32 {
    right: 3.2rem;
    left: 3.2rem
  }

  .print\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  .print\:inset-x-36 {
    right: 3.6rem;
    left: 3.6rem
  }

  .print\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  .print\:inset-x-40 {
    right: 4rem;
    left: 4rem
  }

  .print\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  .print\:inset-x-44 {
    right: 4.4rem;
    left: 4.4rem
  }

  .print\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  .print\:inset-x-48 {
    right: 4.8rem;
    left: 4.8rem
  }

  .print\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  .print\:inset-x-52 {
    right: 5.2rem;
    left: 5.2rem
  }

  .print\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  .print\:inset-x-56 {
    right: 5.6rem;
    left: 5.6rem
  }

  .print\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  .print\:inset-x-60 {
    right: 6rem;
    left: 6rem
  }

  .print\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  .print\:inset-x-64 {
    right: 6.4rem;
    left: 6.4rem
  }

  .print\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  .print\:inset-x-68 {
    right: 6.8rem;
    left: 6.8rem
  }

  .print\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  .print\:inset-x-72 {
    right: 7.2rem;
    left: 7.2rem
  }

  .print\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  .print\:inset-x-76 {
    right: 7.6rem;
    left: 7.6rem
  }

  .print\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  .print\:inset-x-80 {
    right: 8rem;
    left: 8rem
  }

  .print\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  .print\:inset-x-84 {
    right: 8.4rem;
    left: 8.4rem
  }

  .print\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  .print\:inset-x-88 {
    right: 8.8rem;
    left: 8.8rem
  }

  .print\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  .print\:inset-x-92 {
    right: 9.2rem;
    left: 9.2rem
  }

  .print\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  .print\:inset-x-96 {
    right: 9.6rem;
    left: 9.6rem
  }

  .print\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  .print\:inset-x-128 {
    right: 12.8rem;
    left: 12.8rem
  }

  .print\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  .print\:inset-x-136 {
    right: 13.6rem;
    left: 13.6rem
  }

  .print\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  .print\:inset-x-160 {
    right: 16rem;
    left: 16rem
  }

  .print\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  .print\:inset-x-192 {
    right: 19.2rem;
    left: 19.2rem
  }

  .print\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  .print\:inset-x-200 {
    right: 20rem;
    left: 20rem
  }

  .print\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  .print\:inset-x-208 {
    right: 20.8rem;
    left: 20.8rem
  }

  .print\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  .print\:inset-x-216 {
    right: 21.6rem;
    left: 21.6rem
  }

  .print\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  .print\:inset-x-224 {
    right: 22.4rem;
    left: 22.4rem
  }

  .print\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  .print\:inset-x-256 {
    right: 25.6rem;
    left: 25.6rem
  }

  .print\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  .print\:inset-x-288 {
    right: 28.8rem;
    left: 28.8rem
  }

  .print\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  .print\:inset-x-320 {
    right: 32rem;
    left: 32rem
  }

  .print\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  .print\:inset-x-360 {
    right: 36rem;
    left: 36rem
  }

  .print\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  .print\:inset-x-384 {
    right: 38.4rem;
    left: 38.4rem
  }

  .print\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  .print\:inset-x-400 {
    right: 40rem;
    left: 40rem
  }

  .print\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  .print\:inset-x-512 {
    right: 51.2rem;
    left: 51.2rem
  }

  .print\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  .print\:inset-x-640 {
    right: 64rem;
    left: 64rem
  }

  .print\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .print\:inset-x-auto {
    right: auto;
    left: auto
  }

  .print\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  .print\:inset-x-xs {
    right: 32rem;
    left: 32rem
  }

  .print\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  .print\:inset-x-sm {
    right: 48rem;
    left: 48rem
  }

  .print\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  .print\:inset-x-md {
    right: 64rem;
    left: 64rem
  }

  .print\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  .print\:inset-x-lg {
    right: 80rem;
    left: 80rem
  }

  .print\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  .print\:inset-x-xl {
    right: 96rem;
    left: 96rem
  }

  .print\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  .print\:inset-x-2xl {
    right: 112rem;
    left: 112rem
  }

  .print\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  .print\:inset-x-3xl {
    right: 128rem;
    left: 128rem
  }

  .print\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  .print\:inset-x-4xl {
    right: 144rem;
    left: 144rem
  }

  .print\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  .print\:inset-x-5xl {
    right: 160rem;
    left: 160rem
  }

  .print\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  .print\:inset-x-px {
    right: 1px;
    left: 1px
  }

  .print\:inset-y-0\.5 {
    top: 0.05rem;
    bottom: 0.05rem
  }

  .print\:inset-x-0\.5 {
    right: 0.05rem;
    left: 0.05rem
  }

  .print\:inset-y-1\.5 {
    top: 0.15rem;
    bottom: 0.15rem
  }

  .print\:inset-x-1\.5 {
    right: 0.15rem;
    left: 0.15rem
  }

  .print\:inset-y-2\.5 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  .print\:inset-x-2\.5 {
    right: 0.25rem;
    left: 0.25rem
  }

  .print\:inset-y-3\.5 {
    top: 0.35rem;
    bottom: 0.35rem
  }

  .print\:inset-x-3\.5 {
    right: 0.35rem;
    left: 0.35rem
  }

  .print\:-inset-y-1 {
    top: -0.1rem;
    bottom: -0.1rem
  }

  .print\:-inset-x-1 {
    right: -0.1rem;
    left: -0.1rem
  }

  .print\:-inset-y-2 {
    top: -0.2rem;
    bottom: -0.2rem
  }

  .print\:-inset-x-2 {
    right: -0.2rem;
    left: -0.2rem
  }

  .print\:-inset-y-3 {
    top: -0.3rem;
    bottom: -0.3rem
  }

  .print\:-inset-x-3 {
    right: -0.3rem;
    left: -0.3rem
  }

  .print\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  .print\:-inset-x-4 {
    right: -0.4rem;
    left: -0.4rem
  }

  .print\:-inset-y-5 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  .print\:-inset-x-5 {
    right: -0.5rem;
    left: -0.5rem
  }

  .print\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  .print\:-inset-x-6 {
    right: -0.6rem;
    left: -0.6rem
  }

  .print\:-inset-y-7 {
    top: -0.7rem;
    bottom: -0.7rem
  }

  .print\:-inset-x-7 {
    right: -0.7rem;
    left: -0.7rem
  }

  .print\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  .print\:-inset-x-8 {
    right: -0.8rem;
    left: -0.8rem
  }

  .print\:-inset-y-9 {
    top: -0.9rem;
    bottom: -0.9rem
  }

  .print\:-inset-x-9 {
    right: -0.9rem;
    left: -0.9rem
  }

  .print\:-inset-y-10 {
    top: -1rem;
    bottom: -1rem
  }

  .print\:-inset-x-10 {
    right: -1rem;
    left: -1rem
  }

  .print\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  .print\:-inset-x-12 {
    right: -1.2rem;
    left: -1.2rem
  }

  .print\:-inset-y-14 {
    top: -1.4rem;
    bottom: -1.4rem
  }

  .print\:-inset-x-14 {
    right: -1.4rem;
    left: -1.4rem
  }

  .print\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  .print\:-inset-x-16 {
    right: -1.6rem;
    left: -1.6rem
  }

  .print\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  .print\:-inset-x-20 {
    right: -2rem;
    left: -2rem
  }

  .print\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  .print\:-inset-x-24 {
    right: -2.4rem;
    left: -2.4rem
  }

  .print\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  .print\:-inset-x-28 {
    right: -2.8rem;
    left: -2.8rem
  }

  .print\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  .print\:-inset-x-32 {
    right: -3.2rem;
    left: -3.2rem
  }

  .print\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  .print\:-inset-x-36 {
    right: -3.6rem;
    left: -3.6rem
  }

  .print\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  .print\:-inset-x-40 {
    right: -4rem;
    left: -4rem
  }

  .print\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  .print\:-inset-x-44 {
    right: -4.4rem;
    left: -4.4rem
  }

  .print\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  .print\:-inset-x-48 {
    right: -4.8rem;
    left: -4.8rem
  }

  .print\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  .print\:-inset-x-52 {
    right: -5.2rem;
    left: -5.2rem
  }

  .print\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  .print\:-inset-x-56 {
    right: -5.6rem;
    left: -5.6rem
  }

  .print\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  .print\:-inset-x-60 {
    right: -6rem;
    left: -6rem
  }

  .print\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  .print\:-inset-x-64 {
    right: -6.4rem;
    left: -6.4rem
  }

  .print\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  .print\:-inset-x-68 {
    right: -6.8rem;
    left: -6.8rem
  }

  .print\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  .print\:-inset-x-72 {
    right: -7.2rem;
    left: -7.2rem
  }

  .print\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  .print\:-inset-x-76 {
    right: -7.6rem;
    left: -7.6rem
  }

  .print\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  .print\:-inset-x-80 {
    right: -8rem;
    left: -8rem
  }

  .print\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  .print\:-inset-x-84 {
    right: -8.4rem;
    left: -8.4rem
  }

  .print\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  .print\:-inset-x-88 {
    right: -8.8rem;
    left: -8.8rem
  }

  .print\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  .print\:-inset-x-92 {
    right: -9.2rem;
    left: -9.2rem
  }

  .print\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  .print\:-inset-x-96 {
    right: -9.6rem;
    left: -9.6rem
  }

  .print\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  .print\:-inset-x-128 {
    right: -12.8rem;
    left: -12.8rem
  }

  .print\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  .print\:-inset-x-136 {
    right: -13.6rem;
    left: -13.6rem
  }

  .print\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  .print\:-inset-x-160 {
    right: -16rem;
    left: -16rem
  }

  .print\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  .print\:-inset-x-192 {
    right: -19.2rem;
    left: -19.2rem
  }

  .print\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  .print\:-inset-x-200 {
    right: -20rem;
    left: -20rem
  }

  .print\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  .print\:-inset-x-208 {
    right: -20.8rem;
    left: -20.8rem
  }

  .print\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  .print\:-inset-x-216 {
    right: -21.6rem;
    left: -21.6rem
  }

  .print\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  .print\:-inset-x-224 {
    right: -22.4rem;
    left: -22.4rem
  }

  .print\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  .print\:-inset-x-256 {
    right: -25.6rem;
    left: -25.6rem
  }

  .print\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  .print\:-inset-x-288 {
    right: -28.8rem;
    left: -28.8rem
  }

  .print\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  .print\:-inset-x-320 {
    right: -32rem;
    left: -32rem
  }

  .print\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  .print\:-inset-x-360 {
    right: -36rem;
    left: -36rem
  }

  .print\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  .print\:-inset-x-384 {
    right: -38.4rem;
    left: -38.4rem
  }

  .print\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  .print\:-inset-x-400 {
    right: -40rem;
    left: -40rem
  }

  .print\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  .print\:-inset-x-512 {
    right: -51.2rem;
    left: -51.2rem
  }

  .print\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  .print\:-inset-x-640 {
    right: -64rem;
    left: -64rem
  }

  .print\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  .print\:-inset-x-xs {
    right: -32rem;
    left: -32rem
  }

  .print\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  .print\:-inset-x-sm {
    right: -48rem;
    left: -48rem
  }

  .print\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  .print\:-inset-x-md {
    right: -64rem;
    left: -64rem
  }

  .print\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  .print\:-inset-x-lg {
    right: -80rem;
    left: -80rem
  }

  .print\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  .print\:-inset-x-xl {
    right: -96rem;
    left: -96rem
  }

  .print\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  .print\:-inset-x-2xl {
    right: -112rem;
    left: -112rem
  }

  .print\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  .print\:-inset-x-3xl {
    right: -128rem;
    left: -128rem
  }

  .print\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  .print\:-inset-x-4xl {
    right: -144rem;
    left: -144rem
  }

  .print\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  .print\:-inset-x-5xl {
    right: -160rem;
    left: -160rem
  }

  .print\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  .print\:-inset-x-px {
    right: -1px;
    left: -1px
  }

  .print\:-inset-y-0\.5 {
    top: -0.05rem;
    bottom: -0.05rem
  }

  .print\:-inset-x-0\.5 {
    right: -0.05rem;
    left: -0.05rem
  }

  .print\:-inset-y-1\.5 {
    top: -0.15rem;
    bottom: -0.15rem
  }

  .print\:-inset-x-1\.5 {
    right: -0.15rem;
    left: -0.15rem
  }

  .print\:-inset-y-2\.5 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  .print\:-inset-x-2\.5 {
    right: -0.25rem;
    left: -0.25rem
  }

  .print\:-inset-y-3\.5 {
    top: -0.35rem;
    bottom: -0.35rem
  }

  .print\:-inset-x-3\.5 {
    right: -0.35rem;
    left: -0.35rem
  }

  .print\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  .print\:inset-x-1\/2 {
    right: 50%;
    left: 50%
  }

  .print\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  .print\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%
  }

  .print\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  .print\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%
  }

  .print\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  .print\:inset-x-1\/4 {
    right: 25%;
    left: 25%
  }

  .print\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  .print\:inset-x-2\/4 {
    right: 50%;
    left: 50%
  }

  .print\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  .print\:inset-x-3\/4 {
    right: 75%;
    left: 75%
  }

  .print\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  .print\:inset-x-full {
    right: 100%;
    left: 100%
  }

  .print\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  .print\:-inset-x-1\/2 {
    right: -50%;
    left: -50%
  }

  .print\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  .print\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%
  }

  .print\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  .print\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%
  }

  .print\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  .print\:-inset-x-1\/4 {
    right: -25%;
    left: -25%
  }

  .print\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  .print\:-inset-x-2\/4 {
    right: -50%;
    left: -50%
  }

  .print\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  .print\:-inset-x-3\/4 {
    right: -75%;
    left: -75%
  }

  .print\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  .print\:-inset-x-full {
    right: -100%;
    left: -100%
  }

  .print\:top-0 {
    top: 0
  }

  .print\:right-0 {
    right: 0
  }

  .print\:bottom-0 {
    bottom: 0
  }

  .print\:left-0 {
    left: 0
  }

  .print\:top-1 {
    top: 0.1rem
  }

  .print\:right-1 {
    right: 0.1rem
  }

  .print\:bottom-1 {
    bottom: 0.1rem
  }

  .print\:left-1 {
    left: 0.1rem
  }

  .print\:top-2 {
    top: 0.2rem
  }

  .print\:right-2 {
    right: 0.2rem
  }

  .print\:bottom-2 {
    bottom: 0.2rem
  }

  .print\:left-2 {
    left: 0.2rem
  }

  .print\:top-3 {
    top: 0.3rem
  }

  .print\:right-3 {
    right: 0.3rem
  }

  .print\:bottom-3 {
    bottom: 0.3rem
  }

  .print\:left-3 {
    left: 0.3rem
  }

  .print\:top-4 {
    top: 0.4rem
  }

  .print\:right-4 {
    right: 0.4rem
  }

  .print\:bottom-4 {
    bottom: 0.4rem
  }

  .print\:left-4 {
    left: 0.4rem
  }

  .print\:top-5 {
    top: 0.5rem
  }

  .print\:right-5 {
    right: 0.5rem
  }

  .print\:bottom-5 {
    bottom: 0.5rem
  }

  .print\:left-5 {
    left: 0.5rem
  }

  .print\:top-6 {
    top: 0.6rem
  }

  .print\:right-6 {
    right: 0.6rem
  }

  .print\:bottom-6 {
    bottom: 0.6rem
  }

  .print\:left-6 {
    left: 0.6rem
  }

  .print\:top-7 {
    top: 0.7rem
  }

  .print\:right-7 {
    right: 0.7rem
  }

  .print\:bottom-7 {
    bottom: 0.7rem
  }

  .print\:left-7 {
    left: 0.7rem
  }

  .print\:top-8 {
    top: 0.8rem
  }

  .print\:right-8 {
    right: 0.8rem
  }

  .print\:bottom-8 {
    bottom: 0.8rem
  }

  .print\:left-8 {
    left: 0.8rem
  }

  .print\:top-9 {
    top: 0.9rem
  }

  .print\:right-9 {
    right: 0.9rem
  }

  .print\:bottom-9 {
    bottom: 0.9rem
  }

  .print\:left-9 {
    left: 0.9rem
  }

  .print\:top-10 {
    top: 1.0rem
  }

  .print\:right-10 {
    right: 1.0rem
  }

  .print\:bottom-10 {
    bottom: 1.0rem
  }

  .print\:left-10 {
    left: 1.0rem
  }

  .print\:top-12 {
    top: 1.2rem
  }

  .print\:right-12 {
    right: 1.2rem
  }

  .print\:bottom-12 {
    bottom: 1.2rem
  }

  .print\:left-12 {
    left: 1.2rem
  }

  .print\:top-14 {
    top: 1.4rem
  }

  .print\:right-14 {
    right: 1.4rem
  }

  .print\:bottom-14 {
    bottom: 1.4rem
  }

  .print\:left-14 {
    left: 1.4rem
  }

  .print\:top-16 {
    top: 1.6rem
  }

  .print\:right-16 {
    right: 1.6rem
  }

  .print\:bottom-16 {
    bottom: 1.6rem
  }

  .print\:left-16 {
    left: 1.6rem
  }

  .print\:top-20 {
    top: 2rem
  }

  .print\:right-20 {
    right: 2rem
  }

  .print\:bottom-20 {
    bottom: 2rem
  }

  .print\:left-20 {
    left: 2rem
  }

  .print\:top-24 {
    top: 2.4rem
  }

  .print\:right-24 {
    right: 2.4rem
  }

  .print\:bottom-24 {
    bottom: 2.4rem
  }

  .print\:left-24 {
    left: 2.4rem
  }

  .print\:top-28 {
    top: 2.8rem
  }

  .print\:right-28 {
    right: 2.8rem
  }

  .print\:bottom-28 {
    bottom: 2.8rem
  }

  .print\:left-28 {
    left: 2.8rem
  }

  .print\:top-32 {
    top: 3.2rem
  }

  .print\:right-32 {
    right: 3.2rem
  }

  .print\:bottom-32 {
    bottom: 3.2rem
  }

  .print\:left-32 {
    left: 3.2rem
  }

  .print\:top-36 {
    top: 3.6rem
  }

  .print\:right-36 {
    right: 3.6rem
  }

  .print\:bottom-36 {
    bottom: 3.6rem
  }

  .print\:left-36 {
    left: 3.6rem
  }

  .print\:top-40 {
    top: 4rem
  }

  .print\:right-40 {
    right: 4rem
  }

  .print\:bottom-40 {
    bottom: 4rem
  }

  .print\:left-40 {
    left: 4rem
  }

  .print\:top-44 {
    top: 4.4rem
  }

  .print\:right-44 {
    right: 4.4rem
  }

  .print\:bottom-44 {
    bottom: 4.4rem
  }

  .print\:left-44 {
    left: 4.4rem
  }

  .print\:top-48 {
    top: 4.8rem
  }

  .print\:right-48 {
    right: 4.8rem
  }

  .print\:bottom-48 {
    bottom: 4.8rem
  }

  .print\:left-48 {
    left: 4.8rem
  }

  .print\:top-52 {
    top: 5.2rem
  }

  .print\:right-52 {
    right: 5.2rem
  }

  .print\:bottom-52 {
    bottom: 5.2rem
  }

  .print\:left-52 {
    left: 5.2rem
  }

  .print\:top-56 {
    top: 5.6rem
  }

  .print\:right-56 {
    right: 5.6rem
  }

  .print\:bottom-56 {
    bottom: 5.6rem
  }

  .print\:left-56 {
    left: 5.6rem
  }

  .print\:top-60 {
    top: 6rem
  }

  .print\:right-60 {
    right: 6rem
  }

  .print\:bottom-60 {
    bottom: 6rem
  }

  .print\:left-60 {
    left: 6rem
  }

  .print\:top-64 {
    top: 6.4rem
  }

  .print\:right-64 {
    right: 6.4rem
  }

  .print\:bottom-64 {
    bottom: 6.4rem
  }

  .print\:left-64 {
    left: 6.4rem
  }

  .print\:top-68 {
    top: 6.8rem
  }

  .print\:right-68 {
    right: 6.8rem
  }

  .print\:bottom-68 {
    bottom: 6.8rem
  }

  .print\:left-68 {
    left: 6.8rem
  }

  .print\:top-72 {
    top: 7.2rem
  }

  .print\:right-72 {
    right: 7.2rem
  }

  .print\:bottom-72 {
    bottom: 7.2rem
  }

  .print\:left-72 {
    left: 7.2rem
  }

  .print\:top-76 {
    top: 7.6rem
  }

  .print\:right-76 {
    right: 7.6rem
  }

  .print\:bottom-76 {
    bottom: 7.6rem
  }

  .print\:left-76 {
    left: 7.6rem
  }

  .print\:top-80 {
    top: 8rem
  }

  .print\:right-80 {
    right: 8rem
  }

  .print\:bottom-80 {
    bottom: 8rem
  }

  .print\:left-80 {
    left: 8rem
  }

  .print\:top-84 {
    top: 8.4rem
  }

  .print\:right-84 {
    right: 8.4rem
  }

  .print\:bottom-84 {
    bottom: 8.4rem
  }

  .print\:left-84 {
    left: 8.4rem
  }

  .print\:top-88 {
    top: 8.8rem
  }

  .print\:right-88 {
    right: 8.8rem
  }

  .print\:bottom-88 {
    bottom: 8.8rem
  }

  .print\:left-88 {
    left: 8.8rem
  }

  .print\:top-92 {
    top: 9.2rem
  }

  .print\:right-92 {
    right: 9.2rem
  }

  .print\:bottom-92 {
    bottom: 9.2rem
  }

  .print\:left-92 {
    left: 9.2rem
  }

  .print\:top-96 {
    top: 9.6rem
  }

  .print\:right-96 {
    right: 9.6rem
  }

  .print\:bottom-96 {
    bottom: 9.6rem
  }

  .print\:left-96 {
    left: 9.6rem
  }

  .print\:top-128 {
    top: 12.8rem
  }

  .print\:right-128 {
    right: 12.8rem
  }

  .print\:bottom-128 {
    bottom: 12.8rem
  }

  .print\:left-128 {
    left: 12.8rem
  }

  .print\:top-136 {
    top: 13.6rem
  }

  .print\:right-136 {
    right: 13.6rem
  }

  .print\:bottom-136 {
    bottom: 13.6rem
  }

  .print\:left-136 {
    left: 13.6rem
  }

  .print\:top-160 {
    top: 16rem
  }

  .print\:right-160 {
    right: 16rem
  }

  .print\:bottom-160 {
    bottom: 16rem
  }

  .print\:left-160 {
    left: 16rem
  }

  .print\:top-192 {
    top: 19.2rem
  }

  .print\:right-192 {
    right: 19.2rem
  }

  .print\:bottom-192 {
    bottom: 19.2rem
  }

  .print\:left-192 {
    left: 19.2rem
  }

  .print\:top-200 {
    top: 20rem
  }

  .print\:right-200 {
    right: 20rem
  }

  .print\:bottom-200 {
    bottom: 20rem
  }

  .print\:left-200 {
    left: 20rem
  }

  .print\:top-208 {
    top: 20.8rem
  }

  .print\:right-208 {
    right: 20.8rem
  }

  .print\:bottom-208 {
    bottom: 20.8rem
  }

  .print\:left-208 {
    left: 20.8rem
  }

  .print\:top-216 {
    top: 21.6rem
  }

  .print\:right-216 {
    right: 21.6rem
  }

  .print\:bottom-216 {
    bottom: 21.6rem
  }

  .print\:left-216 {
    left: 21.6rem
  }

  .print\:top-224 {
    top: 22.4rem
  }

  .print\:right-224 {
    right: 22.4rem
  }

  .print\:bottom-224 {
    bottom: 22.4rem
  }

  .print\:left-224 {
    left: 22.4rem
  }

  .print\:top-256 {
    top: 25.6rem
  }

  .print\:right-256 {
    right: 25.6rem
  }

  .print\:bottom-256 {
    bottom: 25.6rem
  }

  .print\:left-256 {
    left: 25.6rem
  }

  .print\:top-288 {
    top: 28.8rem
  }

  .print\:right-288 {
    right: 28.8rem
  }

  .print\:bottom-288 {
    bottom: 28.8rem
  }

  .print\:left-288 {
    left: 28.8rem
  }

  .print\:top-320 {
    top: 32rem
  }

  .print\:right-320 {
    right: 32rem
  }

  .print\:bottom-320 {
    bottom: 32rem
  }

  .print\:left-320 {
    left: 32rem
  }

  .print\:top-360 {
    top: 36rem
  }

  .print\:right-360 {
    right: 36rem
  }

  .print\:bottom-360 {
    bottom: 36rem
  }

  .print\:left-360 {
    left: 36rem
  }

  .print\:top-384 {
    top: 38.4rem
  }

  .print\:right-384 {
    right: 38.4rem
  }

  .print\:bottom-384 {
    bottom: 38.4rem
  }

  .print\:left-384 {
    left: 38.4rem
  }

  .print\:top-400 {
    top: 40rem
  }

  .print\:right-400 {
    right: 40rem
  }

  .print\:bottom-400 {
    bottom: 40rem
  }

  .print\:left-400 {
    left: 40rem
  }

  .print\:top-512 {
    top: 51.2rem
  }

  .print\:right-512 {
    right: 51.2rem
  }

  .print\:bottom-512 {
    bottom: 51.2rem
  }

  .print\:left-512 {
    left: 51.2rem
  }

  .print\:top-640 {
    top: 64rem
  }

  .print\:right-640 {
    right: 64rem
  }

  .print\:bottom-640 {
    bottom: 64rem
  }

  .print\:left-640 {
    left: 64rem
  }

  .print\:top-auto {
    top: auto
  }

  .print\:right-auto {
    right: auto
  }

  .print\:bottom-auto {
    bottom: auto
  }

  .print\:left-auto {
    left: auto
  }

  .print\:top-xs {
    top: 32rem
  }

  .print\:right-xs {
    right: 32rem
  }

  .print\:bottom-xs {
    bottom: 32rem
  }

  .print\:left-xs {
    left: 32rem
  }

  .print\:top-sm {
    top: 48rem
  }

  .print\:right-sm {
    right: 48rem
  }

  .print\:bottom-sm {
    bottom: 48rem
  }

  .print\:left-sm {
    left: 48rem
  }

  .print\:top-md {
    top: 64rem
  }

  .print\:right-md {
    right: 64rem
  }

  .print\:bottom-md {
    bottom: 64rem
  }

  .print\:left-md {
    left: 64rem
  }

  .print\:top-lg {
    top: 80rem
  }

  .print\:right-lg {
    right: 80rem
  }

  .print\:bottom-lg {
    bottom: 80rem
  }

  .print\:left-lg {
    left: 80rem
  }

  .print\:top-xl {
    top: 96rem
  }

  .print\:right-xl {
    right: 96rem
  }

  .print\:bottom-xl {
    bottom: 96rem
  }

  .print\:left-xl {
    left: 96rem
  }

  .print\:top-2xl {
    top: 112rem
  }

  .print\:right-2xl {
    right: 112rem
  }

  .print\:bottom-2xl {
    bottom: 112rem
  }

  .print\:left-2xl {
    left: 112rem
  }

  .print\:top-3xl {
    top: 128rem
  }

  .print\:right-3xl {
    right: 128rem
  }

  .print\:bottom-3xl {
    bottom: 128rem
  }

  .print\:left-3xl {
    left: 128rem
  }

  .print\:top-4xl {
    top: 144rem
  }

  .print\:right-4xl {
    right: 144rem
  }

  .print\:bottom-4xl {
    bottom: 144rem
  }

  .print\:left-4xl {
    left: 144rem
  }

  .print\:top-5xl {
    top: 160rem
  }

  .print\:right-5xl {
    right: 160rem
  }

  .print\:bottom-5xl {
    bottom: 160rem
  }

  .print\:left-5xl {
    left: 160rem
  }

  .print\:top-px {
    top: 1px
  }

  .print\:right-px {
    right: 1px
  }

  .print\:bottom-px {
    bottom: 1px
  }

  .print\:left-px {
    left: 1px
  }

  .print\:top-0\.5 {
    top: 0.05rem
  }

  .print\:right-0\.5 {
    right: 0.05rem
  }

  .print\:bottom-0\.5 {
    bottom: 0.05rem
  }

  .print\:left-0\.5 {
    left: 0.05rem
  }

  .print\:top-1\.5 {
    top: 0.15rem
  }

  .print\:right-1\.5 {
    right: 0.15rem
  }

  .print\:bottom-1\.5 {
    bottom: 0.15rem
  }

  .print\:left-1\.5 {
    left: 0.15rem
  }

  .print\:top-2\.5 {
    top: 0.25rem
  }

  .print\:right-2\.5 {
    right: 0.25rem
  }

  .print\:bottom-2\.5 {
    bottom: 0.25rem
  }

  .print\:left-2\.5 {
    left: 0.25rem
  }

  .print\:top-3\.5 {
    top: 0.35rem
  }

  .print\:right-3\.5 {
    right: 0.35rem
  }

  .print\:bottom-3\.5 {
    bottom: 0.35rem
  }

  .print\:left-3\.5 {
    left: 0.35rem
  }

  .print\:-top-1 {
    top: -0.1rem
  }

  .print\:-right-1 {
    right: -0.1rem
  }

  .print\:-bottom-1 {
    bottom: -0.1rem
  }

  .print\:-left-1 {
    left: -0.1rem
  }

  .print\:-top-2 {
    top: -0.2rem
  }

  .print\:-right-2 {
    right: -0.2rem
  }

  .print\:-bottom-2 {
    bottom: -0.2rem
  }

  .print\:-left-2 {
    left: -0.2rem
  }

  .print\:-top-3 {
    top: -0.3rem
  }

  .print\:-right-3 {
    right: -0.3rem
  }

  .print\:-bottom-3 {
    bottom: -0.3rem
  }

  .print\:-left-3 {
    left: -0.3rem
  }

  .print\:-top-4 {
    top: -0.4rem
  }

  .print\:-right-4 {
    right: -0.4rem
  }

  .print\:-bottom-4 {
    bottom: -0.4rem
  }

  .print\:-left-4 {
    left: -0.4rem
  }

  .print\:-top-5 {
    top: -0.5rem
  }

  .print\:-right-5 {
    right: -0.5rem
  }

  .print\:-bottom-5 {
    bottom: -0.5rem
  }

  .print\:-left-5 {
    left: -0.5rem
  }

  .print\:-top-6 {
    top: -0.6rem
  }

  .print\:-right-6 {
    right: -0.6rem
  }

  .print\:-bottom-6 {
    bottom: -0.6rem
  }

  .print\:-left-6 {
    left: -0.6rem
  }

  .print\:-top-7 {
    top: -0.7rem
  }

  .print\:-right-7 {
    right: -0.7rem
  }

  .print\:-bottom-7 {
    bottom: -0.7rem
  }

  .print\:-left-7 {
    left: -0.7rem
  }

  .print\:-top-8 {
    top: -0.8rem
  }

  .print\:-right-8 {
    right: -0.8rem
  }

  .print\:-bottom-8 {
    bottom: -0.8rem
  }

  .print\:-left-8 {
    left: -0.8rem
  }

  .print\:-top-9 {
    top: -0.9rem
  }

  .print\:-right-9 {
    right: -0.9rem
  }

  .print\:-bottom-9 {
    bottom: -0.9rem
  }

  .print\:-left-9 {
    left: -0.9rem
  }

  .print\:-top-10 {
    top: -1rem
  }

  .print\:-right-10 {
    right: -1rem
  }

  .print\:-bottom-10 {
    bottom: -1rem
  }

  .print\:-left-10 {
    left: -1rem
  }

  .print\:-top-12 {
    top: -1.2rem
  }

  .print\:-right-12 {
    right: -1.2rem
  }

  .print\:-bottom-12 {
    bottom: -1.2rem
  }

  .print\:-left-12 {
    left: -1.2rem
  }

  .print\:-top-14 {
    top: -1.4rem
  }

  .print\:-right-14 {
    right: -1.4rem
  }

  .print\:-bottom-14 {
    bottom: -1.4rem
  }

  .print\:-left-14 {
    left: -1.4rem
  }

  .print\:-top-16 {
    top: -1.6rem
  }

  .print\:-right-16 {
    right: -1.6rem
  }

  .print\:-bottom-16 {
    bottom: -1.6rem
  }

  .print\:-left-16 {
    left: -1.6rem
  }

  .print\:-top-20 {
    top: -2rem
  }

  .print\:-right-20 {
    right: -2rem
  }

  .print\:-bottom-20 {
    bottom: -2rem
  }

  .print\:-left-20 {
    left: -2rem
  }

  .print\:-top-24 {
    top: -2.4rem
  }

  .print\:-right-24 {
    right: -2.4rem
  }

  .print\:-bottom-24 {
    bottom: -2.4rem
  }

  .print\:-left-24 {
    left: -2.4rem
  }

  .print\:-top-28 {
    top: -2.8rem
  }

  .print\:-right-28 {
    right: -2.8rem
  }

  .print\:-bottom-28 {
    bottom: -2.8rem
  }

  .print\:-left-28 {
    left: -2.8rem
  }

  .print\:-top-32 {
    top: -3.2rem
  }

  .print\:-right-32 {
    right: -3.2rem
  }

  .print\:-bottom-32 {
    bottom: -3.2rem
  }

  .print\:-left-32 {
    left: -3.2rem
  }

  .print\:-top-36 {
    top: -3.6rem
  }

  .print\:-right-36 {
    right: -3.6rem
  }

  .print\:-bottom-36 {
    bottom: -3.6rem
  }

  .print\:-left-36 {
    left: -3.6rem
  }

  .print\:-top-40 {
    top: -4rem
  }

  .print\:-right-40 {
    right: -4rem
  }

  .print\:-bottom-40 {
    bottom: -4rem
  }

  .print\:-left-40 {
    left: -4rem
  }

  .print\:-top-44 {
    top: -4.4rem
  }

  .print\:-right-44 {
    right: -4.4rem
  }

  .print\:-bottom-44 {
    bottom: -4.4rem
  }

  .print\:-left-44 {
    left: -4.4rem
  }

  .print\:-top-48 {
    top: -4.8rem
  }

  .print\:-right-48 {
    right: -4.8rem
  }

  .print\:-bottom-48 {
    bottom: -4.8rem
  }

  .print\:-left-48 {
    left: -4.8rem
  }

  .print\:-top-52 {
    top: -5.2rem
  }

  .print\:-right-52 {
    right: -5.2rem
  }

  .print\:-bottom-52 {
    bottom: -5.2rem
  }

  .print\:-left-52 {
    left: -5.2rem
  }

  .print\:-top-56 {
    top: -5.6rem
  }

  .print\:-right-56 {
    right: -5.6rem
  }

  .print\:-bottom-56 {
    bottom: -5.6rem
  }

  .print\:-left-56 {
    left: -5.6rem
  }

  .print\:-top-60 {
    top: -6rem
  }

  .print\:-right-60 {
    right: -6rem
  }

  .print\:-bottom-60 {
    bottom: -6rem
  }

  .print\:-left-60 {
    left: -6rem
  }

  .print\:-top-64 {
    top: -6.4rem
  }

  .print\:-right-64 {
    right: -6.4rem
  }

  .print\:-bottom-64 {
    bottom: -6.4rem
  }

  .print\:-left-64 {
    left: -6.4rem
  }

  .print\:-top-68 {
    top: -6.8rem
  }

  .print\:-right-68 {
    right: -6.8rem
  }

  .print\:-bottom-68 {
    bottom: -6.8rem
  }

  .print\:-left-68 {
    left: -6.8rem
  }

  .print\:-top-72 {
    top: -7.2rem
  }

  .print\:-right-72 {
    right: -7.2rem
  }

  .print\:-bottom-72 {
    bottom: -7.2rem
  }

  .print\:-left-72 {
    left: -7.2rem
  }

  .print\:-top-76 {
    top: -7.6rem
  }

  .print\:-right-76 {
    right: -7.6rem
  }

  .print\:-bottom-76 {
    bottom: -7.6rem
  }

  .print\:-left-76 {
    left: -7.6rem
  }

  .print\:-top-80 {
    top: -8rem
  }

  .print\:-right-80 {
    right: -8rem
  }

  .print\:-bottom-80 {
    bottom: -8rem
  }

  .print\:-left-80 {
    left: -8rem
  }

  .print\:-top-84 {
    top: -8.4rem
  }

  .print\:-right-84 {
    right: -8.4rem
  }

  .print\:-bottom-84 {
    bottom: -8.4rem
  }

  .print\:-left-84 {
    left: -8.4rem
  }

  .print\:-top-88 {
    top: -8.8rem
  }

  .print\:-right-88 {
    right: -8.8rem
  }

  .print\:-bottom-88 {
    bottom: -8.8rem
  }

  .print\:-left-88 {
    left: -8.8rem
  }

  .print\:-top-92 {
    top: -9.2rem
  }

  .print\:-right-92 {
    right: -9.2rem
  }

  .print\:-bottom-92 {
    bottom: -9.2rem
  }

  .print\:-left-92 {
    left: -9.2rem
  }

  .print\:-top-96 {
    top: -9.6rem
  }

  .print\:-right-96 {
    right: -9.6rem
  }

  .print\:-bottom-96 {
    bottom: -9.6rem
  }

  .print\:-left-96 {
    left: -9.6rem
  }

  .print\:-top-128 {
    top: -12.8rem
  }

  .print\:-right-128 {
    right: -12.8rem
  }

  .print\:-bottom-128 {
    bottom: -12.8rem
  }

  .print\:-left-128 {
    left: -12.8rem
  }

  .print\:-top-136 {
    top: -13.6rem
  }

  .print\:-right-136 {
    right: -13.6rem
  }

  .print\:-bottom-136 {
    bottom: -13.6rem
  }

  .print\:-left-136 {
    left: -13.6rem
  }

  .print\:-top-160 {
    top: -16rem
  }

  .print\:-right-160 {
    right: -16rem
  }

  .print\:-bottom-160 {
    bottom: -16rem
  }

  .print\:-left-160 {
    left: -16rem
  }

  .print\:-top-192 {
    top: -19.2rem
  }

  .print\:-right-192 {
    right: -19.2rem
  }

  .print\:-bottom-192 {
    bottom: -19.2rem
  }

  .print\:-left-192 {
    left: -19.2rem
  }

  .print\:-top-200 {
    top: -20rem
  }

  .print\:-right-200 {
    right: -20rem
  }

  .print\:-bottom-200 {
    bottom: -20rem
  }

  .print\:-left-200 {
    left: -20rem
  }

  .print\:-top-208 {
    top: -20.8rem
  }

  .print\:-right-208 {
    right: -20.8rem
  }

  .print\:-bottom-208 {
    bottom: -20.8rem
  }

  .print\:-left-208 {
    left: -20.8rem
  }

  .print\:-top-216 {
    top: -21.6rem
  }

  .print\:-right-216 {
    right: -21.6rem
  }

  .print\:-bottom-216 {
    bottom: -21.6rem
  }

  .print\:-left-216 {
    left: -21.6rem
  }

  .print\:-top-224 {
    top: -22.4rem
  }

  .print\:-right-224 {
    right: -22.4rem
  }

  .print\:-bottom-224 {
    bottom: -22.4rem
  }

  .print\:-left-224 {
    left: -22.4rem
  }

  .print\:-top-256 {
    top: -25.6rem
  }

  .print\:-right-256 {
    right: -25.6rem
  }

  .print\:-bottom-256 {
    bottom: -25.6rem
  }

  .print\:-left-256 {
    left: -25.6rem
  }

  .print\:-top-288 {
    top: -28.8rem
  }

  .print\:-right-288 {
    right: -28.8rem
  }

  .print\:-bottom-288 {
    bottom: -28.8rem
  }

  .print\:-left-288 {
    left: -28.8rem
  }

  .print\:-top-320 {
    top: -32rem
  }

  .print\:-right-320 {
    right: -32rem
  }

  .print\:-bottom-320 {
    bottom: -32rem
  }

  .print\:-left-320 {
    left: -32rem
  }

  .print\:-top-360 {
    top: -36rem
  }

  .print\:-right-360 {
    right: -36rem
  }

  .print\:-bottom-360 {
    bottom: -36rem
  }

  .print\:-left-360 {
    left: -36rem
  }

  .print\:-top-384 {
    top: -38.4rem
  }

  .print\:-right-384 {
    right: -38.4rem
  }

  .print\:-bottom-384 {
    bottom: -38.4rem
  }

  .print\:-left-384 {
    left: -38.4rem
  }

  .print\:-top-400 {
    top: -40rem
  }

  .print\:-right-400 {
    right: -40rem
  }

  .print\:-bottom-400 {
    bottom: -40rem
  }

  .print\:-left-400 {
    left: -40rem
  }

  .print\:-top-512 {
    top: -51.2rem
  }

  .print\:-right-512 {
    right: -51.2rem
  }

  .print\:-bottom-512 {
    bottom: -51.2rem
  }

  .print\:-left-512 {
    left: -51.2rem
  }

  .print\:-top-640 {
    top: -64rem
  }

  .print\:-right-640 {
    right: -64rem
  }

  .print\:-bottom-640 {
    bottom: -64rem
  }

  .print\:-left-640 {
    left: -64rem
  }

  .print\:-top-xs {
    top: -32rem
  }

  .print\:-right-xs {
    right: -32rem
  }

  .print\:-bottom-xs {
    bottom: -32rem
  }

  .print\:-left-xs {
    left: -32rem
  }

  .print\:-top-sm {
    top: -48rem
  }

  .print\:-right-sm {
    right: -48rem
  }

  .print\:-bottom-sm {
    bottom: -48rem
  }

  .print\:-left-sm {
    left: -48rem
  }

  .print\:-top-md {
    top: -64rem
  }

  .print\:-right-md {
    right: -64rem
  }

  .print\:-bottom-md {
    bottom: -64rem
  }

  .print\:-left-md {
    left: -64rem
  }

  .print\:-top-lg {
    top: -80rem
  }

  .print\:-right-lg {
    right: -80rem
  }

  .print\:-bottom-lg {
    bottom: -80rem
  }

  .print\:-left-lg {
    left: -80rem
  }

  .print\:-top-xl {
    top: -96rem
  }

  .print\:-right-xl {
    right: -96rem
  }

  .print\:-bottom-xl {
    bottom: -96rem
  }

  .print\:-left-xl {
    left: -96rem
  }

  .print\:-top-2xl {
    top: -112rem
  }

  .print\:-right-2xl {
    right: -112rem
  }

  .print\:-bottom-2xl {
    bottom: -112rem
  }

  .print\:-left-2xl {
    left: -112rem
  }

  .print\:-top-3xl {
    top: -128rem
  }

  .print\:-right-3xl {
    right: -128rem
  }

  .print\:-bottom-3xl {
    bottom: -128rem
  }

  .print\:-left-3xl {
    left: -128rem
  }

  .print\:-top-4xl {
    top: -144rem
  }

  .print\:-right-4xl {
    right: -144rem
  }

  .print\:-bottom-4xl {
    bottom: -144rem
  }

  .print\:-left-4xl {
    left: -144rem
  }

  .print\:-top-5xl {
    top: -160rem
  }

  .print\:-right-5xl {
    right: -160rem
  }

  .print\:-bottom-5xl {
    bottom: -160rem
  }

  .print\:-left-5xl {
    left: -160rem
  }

  .print\:-top-px {
    top: -1px
  }

  .print\:-right-px {
    right: -1px
  }

  .print\:-bottom-px {
    bottom: -1px
  }

  .print\:-left-px {
    left: -1px
  }

  .print\:-top-0\.5 {
    top: -0.05rem
  }

  .print\:-right-0\.5 {
    right: -0.05rem
  }

  .print\:-bottom-0\.5 {
    bottom: -0.05rem
  }

  .print\:-left-0\.5 {
    left: -0.05rem
  }

  .print\:-top-1\.5 {
    top: -0.15rem
  }

  .print\:-right-1\.5 {
    right: -0.15rem
  }

  .print\:-bottom-1\.5 {
    bottom: -0.15rem
  }

  .print\:-left-1\.5 {
    left: -0.15rem
  }

  .print\:-top-2\.5 {
    top: -0.25rem
  }

  .print\:-right-2\.5 {
    right: -0.25rem
  }

  .print\:-bottom-2\.5 {
    bottom: -0.25rem
  }

  .print\:-left-2\.5 {
    left: -0.25rem
  }

  .print\:-top-3\.5 {
    top: -0.35rem
  }

  .print\:-right-3\.5 {
    right: -0.35rem
  }

  .print\:-bottom-3\.5 {
    bottom: -0.35rem
  }

  .print\:-left-3\.5 {
    left: -0.35rem
  }

  .print\:top-1\/2 {
    top: 50%
  }

  .print\:right-1\/2 {
    right: 50%
  }

  .print\:bottom-1\/2 {
    bottom: 50%
  }

  .print\:left-1\/2 {
    left: 50%
  }

  .print\:top-1\/3 {
    top: 33.333333%
  }

  .print\:right-1\/3 {
    right: 33.333333%
  }

  .print\:bottom-1\/3 {
    bottom: 33.333333%
  }

  .print\:left-1\/3 {
    left: 33.333333%
  }

  .print\:top-2\/3 {
    top: 66.666667%
  }

  .print\:right-2\/3 {
    right: 66.666667%
  }

  .print\:bottom-2\/3 {
    bottom: 66.666667%
  }

  .print\:left-2\/3 {
    left: 66.666667%
  }

  .print\:top-1\/4 {
    top: 25%
  }

  .print\:right-1\/4 {
    right: 25%
  }

  .print\:bottom-1\/4 {
    bottom: 25%
  }

  .print\:left-1\/4 {
    left: 25%
  }

  .print\:top-2\/4 {
    top: 50%
  }

  .print\:right-2\/4 {
    right: 50%
  }

  .print\:bottom-2\/4 {
    bottom: 50%
  }

  .print\:left-2\/4 {
    left: 50%
  }

  .print\:top-3\/4 {
    top: 75%
  }

  .print\:right-3\/4 {
    right: 75%
  }

  .print\:bottom-3\/4 {
    bottom: 75%
  }

  .print\:left-3\/4 {
    left: 75%
  }

  .print\:top-full {
    top: 100%
  }

  .print\:right-full {
    right: 100%
  }

  .print\:bottom-full {
    bottom: 100%
  }

  .print\:left-full {
    left: 100%
  }

  .print\:-top-1\/2 {
    top: -50%
  }

  .print\:-right-1\/2 {
    right: -50%
  }

  .print\:-bottom-1\/2 {
    bottom: -50%
  }

  .print\:-left-1\/2 {
    left: -50%
  }

  .print\:-top-1\/3 {
    top: -33.333333%
  }

  .print\:-right-1\/3 {
    right: -33.333333%
  }

  .print\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  .print\:-left-1\/3 {
    left: -33.333333%
  }

  .print\:-top-2\/3 {
    top: -66.666667%
  }

  .print\:-right-2\/3 {
    right: -66.666667%
  }

  .print\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  .print\:-left-2\/3 {
    left: -66.666667%
  }

  .print\:-top-1\/4 {
    top: -25%
  }

  .print\:-right-1\/4 {
    right: -25%
  }

  .print\:-bottom-1\/4 {
    bottom: -25%
  }

  .print\:-left-1\/4 {
    left: -25%
  }

  .print\:-top-2\/4 {
    top: -50%
  }

  .print\:-right-2\/4 {
    right: -50%
  }

  .print\:-bottom-2\/4 {
    bottom: -50%
  }

  .print\:-left-2\/4 {
    left: -50%
  }

  .print\:-top-3\/4 {
    top: -75%
  }

  .print\:-right-3\/4 {
    right: -75%
  }

  .print\:-bottom-3\/4 {
    bottom: -75%
  }

  .print\:-left-3\/4 {
    left: -75%
  }

  .print\:-top-full {
    top: -100%
  }

  .print\:-right-full {
    right: -100%
  }

  .print\:-bottom-full {
    bottom: -100%
  }

  .print\:-left-full {
    left: -100%
  }

  [dir='ltr'] .print\:ltr\:inset-0,[dir='ltr'].print\:ltr\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  [dir='ltr'] .print\:ltr\:inset-1,[dir='ltr'].print\:ltr\:inset-1 {
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:inset-2,[dir='ltr'].print\:ltr\:inset-2 {
    top: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-3,[dir='ltr'].print\:ltr\:inset-3 {
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:inset-4,[dir='ltr'].print\:ltr\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-5,[dir='ltr'].print\:ltr\:inset-5 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:inset-6,[dir='ltr'].print\:ltr\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-7,[dir='ltr'].print\:ltr\:inset-7 {
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
    left: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:inset-8,[dir='ltr'].print\:ltr\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-9,[dir='ltr'].print\:ltr\:inset-9 {
    top: 0.9rem;
    right: 0.9rem;
    bottom: 0.9rem;
    left: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:inset-10,[dir='ltr'].print\:ltr\:inset-10 {
    top: 1.0rem;
    right: 1.0rem;
    bottom: 1.0rem;
    left: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:inset-12,[dir='ltr'].print\:ltr\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-14,[dir='ltr'].print\:ltr\:inset-14 {
    top: 1.4rem;
    right: 1.4rem;
    bottom: 1.4rem;
    left: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-16,[dir='ltr'].print\:ltr\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-20,[dir='ltr'].print\:ltr\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  [dir='ltr'] .print\:ltr\:inset-24,[dir='ltr'].print\:ltr\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-28,[dir='ltr'].print\:ltr\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-32,[dir='ltr'].print\:ltr\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-36,[dir='ltr'].print\:ltr\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-40,[dir='ltr'].print\:ltr\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  [dir='ltr'] .print\:ltr\:inset-44,[dir='ltr'].print\:ltr\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-48,[dir='ltr'].print\:ltr\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-52,[dir='ltr'].print\:ltr\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-56,[dir='ltr'].print\:ltr\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-60,[dir='ltr'].print\:ltr\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  [dir='ltr'] .print\:ltr\:inset-64,[dir='ltr'].print\:ltr\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-68,[dir='ltr'].print\:ltr\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-72,[dir='ltr'].print\:ltr\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-76,[dir='ltr'].print\:ltr\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-80,[dir='ltr'].print\:ltr\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  [dir='ltr'] .print\:ltr\:inset-84,[dir='ltr'].print\:ltr\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-88,[dir='ltr'].print\:ltr\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-92,[dir='ltr'].print\:ltr\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-96,[dir='ltr'].print\:ltr\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-128,[dir='ltr'].print\:ltr\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-136,[dir='ltr'].print\:ltr\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-160,[dir='ltr'].print\:ltr\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  [dir='ltr'] .print\:ltr\:inset-192,[dir='ltr'].print\:ltr\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-200,[dir='ltr'].print\:ltr\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  [dir='ltr'] .print\:ltr\:inset-208,[dir='ltr'].print\:ltr\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-216,[dir='ltr'].print\:ltr\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-224,[dir='ltr'].print\:ltr\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-256,[dir='ltr'].print\:ltr\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-288,[dir='ltr'].print\:ltr\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-320,[dir='ltr'].print\:ltr\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='ltr'] .print\:ltr\:inset-360,[dir='ltr'].print\:ltr\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  [dir='ltr'] .print\:ltr\:inset-384,[dir='ltr'].print\:ltr\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-400,[dir='ltr'].print\:ltr\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  [dir='ltr'] .print\:ltr\:inset-512,[dir='ltr'].print\:ltr\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-640,[dir='ltr'].print\:ltr\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='ltr'] .print\:ltr\:inset-auto,[dir='ltr'].print\:ltr\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  [dir='ltr'] .print\:ltr\:inset-xs,[dir='ltr'].print\:ltr\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='ltr'] .print\:ltr\:inset-sm,[dir='ltr'].print\:ltr\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  [dir='ltr'] .print\:ltr\:inset-md,[dir='ltr'].print\:ltr\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='ltr'] .print\:ltr\:inset-lg,[dir='ltr'].print\:ltr\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  [dir='ltr'] .print\:ltr\:inset-xl,[dir='ltr'].print\:ltr\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  [dir='ltr'] .print\:ltr\:inset-2xl,[dir='ltr'].print\:ltr\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  [dir='ltr'] .print\:ltr\:inset-3xl,[dir='ltr'].print\:ltr\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  [dir='ltr'] .print\:ltr\:inset-4xl,[dir='ltr'].print\:ltr\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  [dir='ltr'] .print\:ltr\:inset-5xl,[dir='ltr'].print\:ltr\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  [dir='ltr'] .print\:ltr\:inset-px,[dir='ltr'].print\:ltr\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  [dir='ltr'] .print\:ltr\:inset-0\.5,[dir='ltr'].print\:ltr\:inset-0\.5 {
    top: 0.05rem;
    right: 0.05rem;
    bottom: 0.05rem;
    left: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:inset-1\.5,[dir='ltr'].print\:ltr\:inset-1\.5 {
    top: 0.15rem;
    right: 0.15rem;
    bottom: 0.15rem;
    left: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:inset-2\.5,[dir='ltr'].print\:ltr\:inset-2\.5 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:inset-3\.5,[dir='ltr'].print\:ltr\:inset-3\.5 {
    top: 0.35rem;
    right: 0.35rem;
    bottom: 0.35rem;
    left: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:-inset-1,[dir='ltr'].print\:ltr\:-inset-1 {
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem
  }

  [dir='ltr'] .print\:ltr\:-inset-2,[dir='ltr'].print\:ltr\:-inset-2 {
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-3,[dir='ltr'].print\:ltr\:-inset-3 {
    top: -0.3rem;
    right: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem
  }

  [dir='ltr'] .print\:ltr\:-inset-4,[dir='ltr'].print\:ltr\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-5,[dir='ltr'].print\:ltr\:-inset-5 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  [dir='ltr'] .print\:ltr\:-inset-6,[dir='ltr'].print\:ltr\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-7,[dir='ltr'].print\:ltr\:-inset-7 {
    top: -0.7rem;
    right: -0.7rem;
    bottom: -0.7rem;
    left: -0.7rem
  }

  [dir='ltr'] .print\:ltr\:-inset-8,[dir='ltr'].print\:ltr\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-9,[dir='ltr'].print\:ltr\:-inset-9 {
    top: -0.9rem;
    right: -0.9rem;
    bottom: -0.9rem;
    left: -0.9rem
  }

  [dir='ltr'] .print\:ltr\:-inset-10,[dir='ltr'].print\:ltr\:-inset-10 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  [dir='ltr'] .print\:ltr\:-inset-12,[dir='ltr'].print\:ltr\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-14,[dir='ltr'].print\:ltr\:-inset-14 {
    top: -1.4rem;
    right: -1.4rem;
    bottom: -1.4rem;
    left: -1.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-16,[dir='ltr'].print\:ltr\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-20,[dir='ltr'].print\:ltr\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-24,[dir='ltr'].print\:ltr\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-28,[dir='ltr'].print\:ltr\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-32,[dir='ltr'].print\:ltr\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-36,[dir='ltr'].print\:ltr\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-40,[dir='ltr'].print\:ltr\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-44,[dir='ltr'].print\:ltr\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-48,[dir='ltr'].print\:ltr\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-52,[dir='ltr'].print\:ltr\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-56,[dir='ltr'].print\:ltr\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-60,[dir='ltr'].print\:ltr\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-64,[dir='ltr'].print\:ltr\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-68,[dir='ltr'].print\:ltr\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-72,[dir='ltr'].print\:ltr\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-76,[dir='ltr'].print\:ltr\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-80,[dir='ltr'].print\:ltr\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-84,[dir='ltr'].print\:ltr\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-88,[dir='ltr'].print\:ltr\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-92,[dir='ltr'].print\:ltr\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-96,[dir='ltr'].print\:ltr\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-128,[dir='ltr'].print\:ltr\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-136,[dir='ltr'].print\:ltr\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-160,[dir='ltr'].print\:ltr\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  [dir='ltr'] .print\:ltr\:-inset-192,[dir='ltr'].print\:ltr\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-200,[dir='ltr'].print\:ltr\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  [dir='ltr'] .print\:ltr\:-inset-208,[dir='ltr'].print\:ltr\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-216,[dir='ltr'].print\:ltr\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-224,[dir='ltr'].print\:ltr\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-256,[dir='ltr'].print\:ltr\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-288,[dir='ltr'].print\:ltr\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-320,[dir='ltr'].print\:ltr\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='ltr'] .print\:ltr\:-inset-360,[dir='ltr'].print\:ltr\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  [dir='ltr'] .print\:ltr\:-inset-384,[dir='ltr'].print\:ltr\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-400,[dir='ltr'].print\:ltr\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  [dir='ltr'] .print\:ltr\:-inset-512,[dir='ltr'].print\:ltr\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-640,[dir='ltr'].print\:ltr\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='ltr'] .print\:ltr\:-inset-xs,[dir='ltr'].print\:ltr\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='ltr'] .print\:ltr\:-inset-sm,[dir='ltr'].print\:ltr\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  [dir='ltr'] .print\:ltr\:-inset-md,[dir='ltr'].print\:ltr\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='ltr'] .print\:ltr\:-inset-lg,[dir='ltr'].print\:ltr\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  [dir='ltr'] .print\:ltr\:-inset-xl,[dir='ltr'].print\:ltr\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  [dir='ltr'] .print\:ltr\:-inset-2xl,[dir='ltr'].print\:ltr\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  [dir='ltr'] .print\:ltr\:-inset-3xl,[dir='ltr'].print\:ltr\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  [dir='ltr'] .print\:ltr\:-inset-4xl,[dir='ltr'].print\:ltr\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  [dir='ltr'] .print\:ltr\:-inset-5xl,[dir='ltr'].print\:ltr\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  [dir='ltr'] .print\:ltr\:-inset-px,[dir='ltr'].print\:ltr\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  [dir='ltr'] .print\:ltr\:-inset-0\.5,[dir='ltr'].print\:ltr\:-inset-0\.5 {
    top: -0.05rem;
    right: -0.05rem;
    bottom: -0.05rem;
    left: -0.05rem
  }

  [dir='ltr'] .print\:ltr\:-inset-1\.5,[dir='ltr'].print\:ltr\:-inset-1\.5 {
    top: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    left: -0.15rem
  }

  [dir='ltr'] .print\:ltr\:-inset-2\.5,[dir='ltr'].print\:ltr\:-inset-2\.5 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  [dir='ltr'] .print\:ltr\:-inset-3\.5,[dir='ltr'].print\:ltr\:-inset-3\.5 {
    top: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    left: -0.35rem
  }

  [dir='ltr'] .print\:ltr\:inset-1\/2,[dir='ltr'].print\:ltr\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='ltr'] .print\:ltr\:inset-1\/3,[dir='ltr'].print\:ltr\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  [dir='ltr'] .print\:ltr\:inset-2\/3,[dir='ltr'].print\:ltr\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  [dir='ltr'] .print\:ltr\:inset-1\/4,[dir='ltr'].print\:ltr\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  [dir='ltr'] .print\:ltr\:inset-2\/4,[dir='ltr'].print\:ltr\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='ltr'] .print\:ltr\:inset-3\/4,[dir='ltr'].print\:ltr\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  [dir='ltr'] .print\:ltr\:inset-full,[dir='ltr'].print\:ltr\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  [dir='ltr'] .print\:ltr\:-inset-1\/2,[dir='ltr'].print\:ltr\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='ltr'] .print\:ltr\:-inset-1\/3,[dir='ltr'].print\:ltr\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  [dir='ltr'] .print\:ltr\:-inset-2\/3,[dir='ltr'].print\:ltr\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  [dir='ltr'] .print\:ltr\:-inset-1\/4,[dir='ltr'].print\:ltr\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  [dir='ltr'] .print\:ltr\:-inset-2\/4,[dir='ltr'].print\:ltr\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='ltr'] .print\:ltr\:-inset-3\/4,[dir='ltr'].print\:ltr\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  [dir='ltr'] .print\:ltr\:-inset-full,[dir='ltr'].print\:ltr\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  [dir='ltr'] .print\:ltr\:inset-y-0,[dir='ltr'].print\:ltr\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  [dir='ltr'] .print\:ltr\:inset-x-0,[dir='ltr'].print\:ltr\:inset-x-0 {
    right: 0;
    left: 0
  }

  [dir='ltr'] .print\:ltr\:inset-y-1,[dir='ltr'].print\:ltr\:inset-y-1 {
    top: 0.1rem;
    bottom: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-1,[dir='ltr'].print\:ltr\:inset-x-1 {
    right: 0.1rem;
    left: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-2,[dir='ltr'].print\:ltr\:inset-y-2 {
    top: 0.2rem;
    bottom: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-2,[dir='ltr'].print\:ltr\:inset-x-2 {
    right: 0.2rem;
    left: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-3,[dir='ltr'].print\:ltr\:inset-y-3 {
    top: 0.3rem;
    bottom: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-3,[dir='ltr'].print\:ltr\:inset-x-3 {
    right: 0.3rem;
    left: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-4,[dir='ltr'].print\:ltr\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-4,[dir='ltr'].print\:ltr\:inset-x-4 {
    right: 0.4rem;
    left: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-5,[dir='ltr'].print\:ltr\:inset-y-5 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-5,[dir='ltr'].print\:ltr\:inset-x-5 {
    right: 0.5rem;
    left: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-6,[dir='ltr'].print\:ltr\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-6,[dir='ltr'].print\:ltr\:inset-x-6 {
    right: 0.6rem;
    left: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-7,[dir='ltr'].print\:ltr\:inset-y-7 {
    top: 0.7rem;
    bottom: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-7,[dir='ltr'].print\:ltr\:inset-x-7 {
    right: 0.7rem;
    left: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-8,[dir='ltr'].print\:ltr\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-8,[dir='ltr'].print\:ltr\:inset-x-8 {
    right: 0.8rem;
    left: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-9,[dir='ltr'].print\:ltr\:inset-y-9 {
    top: 0.9rem;
    bottom: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-9,[dir='ltr'].print\:ltr\:inset-x-9 {
    right: 0.9rem;
    left: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-10,[dir='ltr'].print\:ltr\:inset-y-10 {
    top: 1.0rem;
    bottom: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-10,[dir='ltr'].print\:ltr\:inset-x-10 {
    right: 1.0rem;
    left: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-12,[dir='ltr'].print\:ltr\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-12,[dir='ltr'].print\:ltr\:inset-x-12 {
    right: 1.2rem;
    left: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-14,[dir='ltr'].print\:ltr\:inset-y-14 {
    top: 1.4rem;
    bottom: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-14,[dir='ltr'].print\:ltr\:inset-x-14 {
    right: 1.4rem;
    left: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-16,[dir='ltr'].print\:ltr\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-16,[dir='ltr'].print\:ltr\:inset-x-16 {
    right: 1.6rem;
    left: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-20,[dir='ltr'].print\:ltr\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-20,[dir='ltr'].print\:ltr\:inset-x-20 {
    right: 2rem;
    left: 2rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-24,[dir='ltr'].print\:ltr\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-24,[dir='ltr'].print\:ltr\:inset-x-24 {
    right: 2.4rem;
    left: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-28,[dir='ltr'].print\:ltr\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-28,[dir='ltr'].print\:ltr\:inset-x-28 {
    right: 2.8rem;
    left: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-32,[dir='ltr'].print\:ltr\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-32,[dir='ltr'].print\:ltr\:inset-x-32 {
    right: 3.2rem;
    left: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-36,[dir='ltr'].print\:ltr\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-36,[dir='ltr'].print\:ltr\:inset-x-36 {
    right: 3.6rem;
    left: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-40,[dir='ltr'].print\:ltr\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-40,[dir='ltr'].print\:ltr\:inset-x-40 {
    right: 4rem;
    left: 4rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-44,[dir='ltr'].print\:ltr\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-44,[dir='ltr'].print\:ltr\:inset-x-44 {
    right: 4.4rem;
    left: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-48,[dir='ltr'].print\:ltr\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-48,[dir='ltr'].print\:ltr\:inset-x-48 {
    right: 4.8rem;
    left: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-52,[dir='ltr'].print\:ltr\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-52,[dir='ltr'].print\:ltr\:inset-x-52 {
    right: 5.2rem;
    left: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-56,[dir='ltr'].print\:ltr\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-56,[dir='ltr'].print\:ltr\:inset-x-56 {
    right: 5.6rem;
    left: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-60,[dir='ltr'].print\:ltr\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-60,[dir='ltr'].print\:ltr\:inset-x-60 {
    right: 6rem;
    left: 6rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-64,[dir='ltr'].print\:ltr\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-64,[dir='ltr'].print\:ltr\:inset-x-64 {
    right: 6.4rem;
    left: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-68,[dir='ltr'].print\:ltr\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-68,[dir='ltr'].print\:ltr\:inset-x-68 {
    right: 6.8rem;
    left: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-72,[dir='ltr'].print\:ltr\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-72,[dir='ltr'].print\:ltr\:inset-x-72 {
    right: 7.2rem;
    left: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-76,[dir='ltr'].print\:ltr\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-76,[dir='ltr'].print\:ltr\:inset-x-76 {
    right: 7.6rem;
    left: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-80,[dir='ltr'].print\:ltr\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-80,[dir='ltr'].print\:ltr\:inset-x-80 {
    right: 8rem;
    left: 8rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-84,[dir='ltr'].print\:ltr\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-84,[dir='ltr'].print\:ltr\:inset-x-84 {
    right: 8.4rem;
    left: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-88,[dir='ltr'].print\:ltr\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-88,[dir='ltr'].print\:ltr\:inset-x-88 {
    right: 8.8rem;
    left: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-92,[dir='ltr'].print\:ltr\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-92,[dir='ltr'].print\:ltr\:inset-x-92 {
    right: 9.2rem;
    left: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-96,[dir='ltr'].print\:ltr\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-96,[dir='ltr'].print\:ltr\:inset-x-96 {
    right: 9.6rem;
    left: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-128,[dir='ltr'].print\:ltr\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-128,[dir='ltr'].print\:ltr\:inset-x-128 {
    right: 12.8rem;
    left: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-136,[dir='ltr'].print\:ltr\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-136,[dir='ltr'].print\:ltr\:inset-x-136 {
    right: 13.6rem;
    left: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-160,[dir='ltr'].print\:ltr\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-160,[dir='ltr'].print\:ltr\:inset-x-160 {
    right: 16rem;
    left: 16rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-192,[dir='ltr'].print\:ltr\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-192,[dir='ltr'].print\:ltr\:inset-x-192 {
    right: 19.2rem;
    left: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-200,[dir='ltr'].print\:ltr\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-200,[dir='ltr'].print\:ltr\:inset-x-200 {
    right: 20rem;
    left: 20rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-208,[dir='ltr'].print\:ltr\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-208,[dir='ltr'].print\:ltr\:inset-x-208 {
    right: 20.8rem;
    left: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-216,[dir='ltr'].print\:ltr\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-216,[dir='ltr'].print\:ltr\:inset-x-216 {
    right: 21.6rem;
    left: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-224,[dir='ltr'].print\:ltr\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-224,[dir='ltr'].print\:ltr\:inset-x-224 {
    right: 22.4rem;
    left: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-256,[dir='ltr'].print\:ltr\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-256,[dir='ltr'].print\:ltr\:inset-x-256 {
    right: 25.6rem;
    left: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-288,[dir='ltr'].print\:ltr\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-288,[dir='ltr'].print\:ltr\:inset-x-288 {
    right: 28.8rem;
    left: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-320,[dir='ltr'].print\:ltr\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-320,[dir='ltr'].print\:ltr\:inset-x-320 {
    right: 32rem;
    left: 32rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-360,[dir='ltr'].print\:ltr\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-360,[dir='ltr'].print\:ltr\:inset-x-360 {
    right: 36rem;
    left: 36rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-384,[dir='ltr'].print\:ltr\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-384,[dir='ltr'].print\:ltr\:inset-x-384 {
    right: 38.4rem;
    left: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-400,[dir='ltr'].print\:ltr\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-400,[dir='ltr'].print\:ltr\:inset-x-400 {
    right: 40rem;
    left: 40rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-512,[dir='ltr'].print\:ltr\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-512,[dir='ltr'].print\:ltr\:inset-x-512 {
    right: 51.2rem;
    left: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-640,[dir='ltr'].print\:ltr\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-640,[dir='ltr'].print\:ltr\:inset-x-640 {
    right: 64rem;
    left: 64rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-auto,[dir='ltr'].print\:ltr\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  [dir='ltr'] .print\:ltr\:inset-x-auto,[dir='ltr'].print\:ltr\:inset-x-auto {
    right: auto;
    left: auto
  }

  [dir='ltr'] .print\:ltr\:inset-y-xs,[dir='ltr'].print\:ltr\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-xs,[dir='ltr'].print\:ltr\:inset-x-xs {
    right: 32rem;
    left: 32rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-sm,[dir='ltr'].print\:ltr\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-sm,[dir='ltr'].print\:ltr\:inset-x-sm {
    right: 48rem;
    left: 48rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-md,[dir='ltr'].print\:ltr\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-md,[dir='ltr'].print\:ltr\:inset-x-md {
    right: 64rem;
    left: 64rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-lg,[dir='ltr'].print\:ltr\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-lg,[dir='ltr'].print\:ltr\:inset-x-lg {
    right: 80rem;
    left: 80rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-xl,[dir='ltr'].print\:ltr\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-xl,[dir='ltr'].print\:ltr\:inset-x-xl {
    right: 96rem;
    left: 96rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-2xl,[dir='ltr'].print\:ltr\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-2xl,[dir='ltr'].print\:ltr\:inset-x-2xl {
    right: 112rem;
    left: 112rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-3xl,[dir='ltr'].print\:ltr\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-3xl,[dir='ltr'].print\:ltr\:inset-x-3xl {
    right: 128rem;
    left: 128rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-4xl,[dir='ltr'].print\:ltr\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-4xl,[dir='ltr'].print\:ltr\:inset-x-4xl {
    right: 144rem;
    left: 144rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-5xl,[dir='ltr'].print\:ltr\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-5xl,[dir='ltr'].print\:ltr\:inset-x-5xl {
    right: 160rem;
    left: 160rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-px,[dir='ltr'].print\:ltr\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  [dir='ltr'] .print\:ltr\:inset-x-px,[dir='ltr'].print\:ltr\:inset-x-px {
    right: 1px;
    left: 1px
  }

  [dir='ltr'] .print\:ltr\:inset-y-0\.5,[dir='ltr'].print\:ltr\:inset-y-0\.5 {
    top: 0.05rem;
    bottom: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-0\.5,[dir='ltr'].print\:ltr\:inset-x-0\.5 {
    right: 0.05rem;
    left: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-1\.5,[dir='ltr'].print\:ltr\:inset-y-1\.5 {
    top: 0.15rem;
    bottom: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-1\.5,[dir='ltr'].print\:ltr\:inset-x-1\.5 {
    right: 0.15rem;
    left: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-2\.5,[dir='ltr'].print\:ltr\:inset-y-2\.5 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-2\.5,[dir='ltr'].print\:ltr\:inset-x-2\.5 {
    right: 0.25rem;
    left: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-3\.5,[dir='ltr'].print\:ltr\:inset-y-3\.5 {
    top: 0.35rem;
    bottom: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:inset-x-3\.5,[dir='ltr'].print\:ltr\:inset-x-3\.5 {
    right: 0.35rem;
    left: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-1,[dir='ltr'].print\:ltr\:-inset-y-1 {
    top: -0.1rem;
    bottom: -0.1rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-1,[dir='ltr'].print\:ltr\:-inset-x-1 {
    right: -0.1rem;
    left: -0.1rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-2,[dir='ltr'].print\:ltr\:-inset-y-2 {
    top: -0.2rem;
    bottom: -0.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-2,[dir='ltr'].print\:ltr\:-inset-x-2 {
    right: -0.2rem;
    left: -0.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-3,[dir='ltr'].print\:ltr\:-inset-y-3 {
    top: -0.3rem;
    bottom: -0.3rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-3,[dir='ltr'].print\:ltr\:-inset-x-3 {
    right: -0.3rem;
    left: -0.3rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-4,[dir='ltr'].print\:ltr\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-4,[dir='ltr'].print\:ltr\:-inset-x-4 {
    right: -0.4rem;
    left: -0.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-5,[dir='ltr'].print\:ltr\:-inset-y-5 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-5,[dir='ltr'].print\:ltr\:-inset-x-5 {
    right: -0.5rem;
    left: -0.5rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-6,[dir='ltr'].print\:ltr\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-6,[dir='ltr'].print\:ltr\:-inset-x-6 {
    right: -0.6rem;
    left: -0.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-7,[dir='ltr'].print\:ltr\:-inset-y-7 {
    top: -0.7rem;
    bottom: -0.7rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-7,[dir='ltr'].print\:ltr\:-inset-x-7 {
    right: -0.7rem;
    left: -0.7rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-8,[dir='ltr'].print\:ltr\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-8,[dir='ltr'].print\:ltr\:-inset-x-8 {
    right: -0.8rem;
    left: -0.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-9,[dir='ltr'].print\:ltr\:-inset-y-9 {
    top: -0.9rem;
    bottom: -0.9rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-9,[dir='ltr'].print\:ltr\:-inset-x-9 {
    right: -0.9rem;
    left: -0.9rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-10,[dir='ltr'].print\:ltr\:-inset-y-10 {
    top: -1rem;
    bottom: -1rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-10,[dir='ltr'].print\:ltr\:-inset-x-10 {
    right: -1rem;
    left: -1rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-12,[dir='ltr'].print\:ltr\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-12,[dir='ltr'].print\:ltr\:-inset-x-12 {
    right: -1.2rem;
    left: -1.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-14,[dir='ltr'].print\:ltr\:-inset-y-14 {
    top: -1.4rem;
    bottom: -1.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-14,[dir='ltr'].print\:ltr\:-inset-x-14 {
    right: -1.4rem;
    left: -1.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-16,[dir='ltr'].print\:ltr\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-16,[dir='ltr'].print\:ltr\:-inset-x-16 {
    right: -1.6rem;
    left: -1.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-20,[dir='ltr'].print\:ltr\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-20,[dir='ltr'].print\:ltr\:-inset-x-20 {
    right: -2rem;
    left: -2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-24,[dir='ltr'].print\:ltr\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-24,[dir='ltr'].print\:ltr\:-inset-x-24 {
    right: -2.4rem;
    left: -2.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-28,[dir='ltr'].print\:ltr\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-28,[dir='ltr'].print\:ltr\:-inset-x-28 {
    right: -2.8rem;
    left: -2.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-32,[dir='ltr'].print\:ltr\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-32,[dir='ltr'].print\:ltr\:-inset-x-32 {
    right: -3.2rem;
    left: -3.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-36,[dir='ltr'].print\:ltr\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-36,[dir='ltr'].print\:ltr\:-inset-x-36 {
    right: -3.6rem;
    left: -3.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-40,[dir='ltr'].print\:ltr\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-40,[dir='ltr'].print\:ltr\:-inset-x-40 {
    right: -4rem;
    left: -4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-44,[dir='ltr'].print\:ltr\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-44,[dir='ltr'].print\:ltr\:-inset-x-44 {
    right: -4.4rem;
    left: -4.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-48,[dir='ltr'].print\:ltr\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-48,[dir='ltr'].print\:ltr\:-inset-x-48 {
    right: -4.8rem;
    left: -4.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-52,[dir='ltr'].print\:ltr\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-52,[dir='ltr'].print\:ltr\:-inset-x-52 {
    right: -5.2rem;
    left: -5.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-56,[dir='ltr'].print\:ltr\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-56,[dir='ltr'].print\:ltr\:-inset-x-56 {
    right: -5.6rem;
    left: -5.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-60,[dir='ltr'].print\:ltr\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-60,[dir='ltr'].print\:ltr\:-inset-x-60 {
    right: -6rem;
    left: -6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-64,[dir='ltr'].print\:ltr\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-64,[dir='ltr'].print\:ltr\:-inset-x-64 {
    right: -6.4rem;
    left: -6.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-68,[dir='ltr'].print\:ltr\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-68,[dir='ltr'].print\:ltr\:-inset-x-68 {
    right: -6.8rem;
    left: -6.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-72,[dir='ltr'].print\:ltr\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-72,[dir='ltr'].print\:ltr\:-inset-x-72 {
    right: -7.2rem;
    left: -7.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-76,[dir='ltr'].print\:ltr\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-76,[dir='ltr'].print\:ltr\:-inset-x-76 {
    right: -7.6rem;
    left: -7.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-80,[dir='ltr'].print\:ltr\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-80,[dir='ltr'].print\:ltr\:-inset-x-80 {
    right: -8rem;
    left: -8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-84,[dir='ltr'].print\:ltr\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-84,[dir='ltr'].print\:ltr\:-inset-x-84 {
    right: -8.4rem;
    left: -8.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-88,[dir='ltr'].print\:ltr\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-88,[dir='ltr'].print\:ltr\:-inset-x-88 {
    right: -8.8rem;
    left: -8.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-92,[dir='ltr'].print\:ltr\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-92,[dir='ltr'].print\:ltr\:-inset-x-92 {
    right: -9.2rem;
    left: -9.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-96,[dir='ltr'].print\:ltr\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-96,[dir='ltr'].print\:ltr\:-inset-x-96 {
    right: -9.6rem;
    left: -9.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-128,[dir='ltr'].print\:ltr\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-128,[dir='ltr'].print\:ltr\:-inset-x-128 {
    right: -12.8rem;
    left: -12.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-136,[dir='ltr'].print\:ltr\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-136,[dir='ltr'].print\:ltr\:-inset-x-136 {
    right: -13.6rem;
    left: -13.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-160,[dir='ltr'].print\:ltr\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-160,[dir='ltr'].print\:ltr\:-inset-x-160 {
    right: -16rem;
    left: -16rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-192,[dir='ltr'].print\:ltr\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-192,[dir='ltr'].print\:ltr\:-inset-x-192 {
    right: -19.2rem;
    left: -19.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-200,[dir='ltr'].print\:ltr\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-200,[dir='ltr'].print\:ltr\:-inset-x-200 {
    right: -20rem;
    left: -20rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-208,[dir='ltr'].print\:ltr\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-208,[dir='ltr'].print\:ltr\:-inset-x-208 {
    right: -20.8rem;
    left: -20.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-216,[dir='ltr'].print\:ltr\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-216,[dir='ltr'].print\:ltr\:-inset-x-216 {
    right: -21.6rem;
    left: -21.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-224,[dir='ltr'].print\:ltr\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-224,[dir='ltr'].print\:ltr\:-inset-x-224 {
    right: -22.4rem;
    left: -22.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-256,[dir='ltr'].print\:ltr\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-256,[dir='ltr'].print\:ltr\:-inset-x-256 {
    right: -25.6rem;
    left: -25.6rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-288,[dir='ltr'].print\:ltr\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-288,[dir='ltr'].print\:ltr\:-inset-x-288 {
    right: -28.8rem;
    left: -28.8rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-320,[dir='ltr'].print\:ltr\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-320,[dir='ltr'].print\:ltr\:-inset-x-320 {
    right: -32rem;
    left: -32rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-360,[dir='ltr'].print\:ltr\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-360,[dir='ltr'].print\:ltr\:-inset-x-360 {
    right: -36rem;
    left: -36rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-384,[dir='ltr'].print\:ltr\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-384,[dir='ltr'].print\:ltr\:-inset-x-384 {
    right: -38.4rem;
    left: -38.4rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-400,[dir='ltr'].print\:ltr\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-400,[dir='ltr'].print\:ltr\:-inset-x-400 {
    right: -40rem;
    left: -40rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-512,[dir='ltr'].print\:ltr\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-512,[dir='ltr'].print\:ltr\:-inset-x-512 {
    right: -51.2rem;
    left: -51.2rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-640,[dir='ltr'].print\:ltr\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-640,[dir='ltr'].print\:ltr\:-inset-x-640 {
    right: -64rem;
    left: -64rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-xs,[dir='ltr'].print\:ltr\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-xs,[dir='ltr'].print\:ltr\:-inset-x-xs {
    right: -32rem;
    left: -32rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-sm,[dir='ltr'].print\:ltr\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-sm,[dir='ltr'].print\:ltr\:-inset-x-sm {
    right: -48rem;
    left: -48rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-md,[dir='ltr'].print\:ltr\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-md,[dir='ltr'].print\:ltr\:-inset-x-md {
    right: -64rem;
    left: -64rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-lg,[dir='ltr'].print\:ltr\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-lg,[dir='ltr'].print\:ltr\:-inset-x-lg {
    right: -80rem;
    left: -80rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-xl,[dir='ltr'].print\:ltr\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-xl,[dir='ltr'].print\:ltr\:-inset-x-xl {
    right: -96rem;
    left: -96rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-2xl,[dir='ltr'].print\:ltr\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-2xl,[dir='ltr'].print\:ltr\:-inset-x-2xl {
    right: -112rem;
    left: -112rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-3xl,[dir='ltr'].print\:ltr\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-3xl,[dir='ltr'].print\:ltr\:-inset-x-3xl {
    right: -128rem;
    left: -128rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-4xl,[dir='ltr'].print\:ltr\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-4xl,[dir='ltr'].print\:ltr\:-inset-x-4xl {
    right: -144rem;
    left: -144rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-5xl,[dir='ltr'].print\:ltr\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-5xl,[dir='ltr'].print\:ltr\:-inset-x-5xl {
    right: -160rem;
    left: -160rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-px,[dir='ltr'].print\:ltr\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  [dir='ltr'] .print\:ltr\:-inset-x-px,[dir='ltr'].print\:ltr\:-inset-x-px {
    right: -1px;
    left: -1px
  }

  [dir='ltr'] .print\:ltr\:-inset-y-0\.5,[dir='ltr'].print\:ltr\:-inset-y-0\.5 {
    top: -0.05rem;
    bottom: -0.05rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-0\.5,[dir='ltr'].print\:ltr\:-inset-x-0\.5 {
    right: -0.05rem;
    left: -0.05rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-1\.5,[dir='ltr'].print\:ltr\:-inset-y-1\.5 {
    top: -0.15rem;
    bottom: -0.15rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-1\.5,[dir='ltr'].print\:ltr\:-inset-x-1\.5 {
    right: -0.15rem;
    left: -0.15rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-2\.5,[dir='ltr'].print\:ltr\:-inset-y-2\.5 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-2\.5,[dir='ltr'].print\:ltr\:-inset-x-2\.5 {
    right: -0.25rem;
    left: -0.25rem
  }

  [dir='ltr'] .print\:ltr\:-inset-y-3\.5,[dir='ltr'].print\:ltr\:-inset-y-3\.5 {
    top: -0.35rem;
    bottom: -0.35rem
  }

  [dir='ltr'] .print\:ltr\:-inset-x-3\.5,[dir='ltr'].print\:ltr\:-inset-x-3\.5 {
    right: -0.35rem;
    left: -0.35rem
  }

  [dir='ltr'] .print\:ltr\:inset-y-1\/2,[dir='ltr'].print\:ltr\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  [dir='ltr'] .print\:ltr\:inset-x-1\/2,[dir='ltr'].print\:ltr\:inset-x-1\/2 {
    right: 50%;
    left: 50%
  }

  [dir='ltr'] .print\:ltr\:inset-y-1\/3,[dir='ltr'].print\:ltr\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  [dir='ltr'] .print\:ltr\:inset-x-1\/3,[dir='ltr'].print\:ltr\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%
  }

  [dir='ltr'] .print\:ltr\:inset-y-2\/3,[dir='ltr'].print\:ltr\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  [dir='ltr'] .print\:ltr\:inset-x-2\/3,[dir='ltr'].print\:ltr\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%
  }

  [dir='ltr'] .print\:ltr\:inset-y-1\/4,[dir='ltr'].print\:ltr\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  [dir='ltr'] .print\:ltr\:inset-x-1\/4,[dir='ltr'].print\:ltr\:inset-x-1\/4 {
    right: 25%;
    left: 25%
  }

  [dir='ltr'] .print\:ltr\:inset-y-2\/4,[dir='ltr'].print\:ltr\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  [dir='ltr'] .print\:ltr\:inset-x-2\/4,[dir='ltr'].print\:ltr\:inset-x-2\/4 {
    right: 50%;
    left: 50%
  }

  [dir='ltr'] .print\:ltr\:inset-y-3\/4,[dir='ltr'].print\:ltr\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  [dir='ltr'] .print\:ltr\:inset-x-3\/4,[dir='ltr'].print\:ltr\:inset-x-3\/4 {
    right: 75%;
    left: 75%
  }

  [dir='ltr'] .print\:ltr\:inset-y-full,[dir='ltr'].print\:ltr\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  [dir='ltr'] .print\:ltr\:inset-x-full,[dir='ltr'].print\:ltr\:inset-x-full {
    right: 100%;
    left: 100%
  }

  [dir='ltr'] .print\:ltr\:-inset-y-1\/2,[dir='ltr'].print\:ltr\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  [dir='ltr'] .print\:ltr\:-inset-x-1\/2,[dir='ltr'].print\:ltr\:-inset-x-1\/2 {
    right: -50%;
    left: -50%
  }

  [dir='ltr'] .print\:ltr\:-inset-y-1\/3,[dir='ltr'].print\:ltr\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  [dir='ltr'] .print\:ltr\:-inset-x-1\/3,[dir='ltr'].print\:ltr\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%
  }

  [dir='ltr'] .print\:ltr\:-inset-y-2\/3,[dir='ltr'].print\:ltr\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  [dir='ltr'] .print\:ltr\:-inset-x-2\/3,[dir='ltr'].print\:ltr\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%
  }

  [dir='ltr'] .print\:ltr\:-inset-y-1\/4,[dir='ltr'].print\:ltr\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  [dir='ltr'] .print\:ltr\:-inset-x-1\/4,[dir='ltr'].print\:ltr\:-inset-x-1\/4 {
    right: -25%;
    left: -25%
  }

  [dir='ltr'] .print\:ltr\:-inset-y-2\/4,[dir='ltr'].print\:ltr\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  [dir='ltr'] .print\:ltr\:-inset-x-2\/4,[dir='ltr'].print\:ltr\:-inset-x-2\/4 {
    right: -50%;
    left: -50%
  }

  [dir='ltr'] .print\:ltr\:-inset-y-3\/4,[dir='ltr'].print\:ltr\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  [dir='ltr'] .print\:ltr\:-inset-x-3\/4,[dir='ltr'].print\:ltr\:-inset-x-3\/4 {
    right: -75%;
    left: -75%
  }

  [dir='ltr'] .print\:ltr\:-inset-y-full,[dir='ltr'].print\:ltr\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  [dir='ltr'] .print\:ltr\:-inset-x-full,[dir='ltr'].print\:ltr\:-inset-x-full {
    right: -100%;
    left: -100%
  }

  [dir='ltr'] .print\:ltr\:top-0,[dir='ltr'].print\:ltr\:top-0 {
    top: 0
  }

  [dir='ltr'] .print\:ltr\:right-0,[dir='ltr'].print\:ltr\:right-0 {
    right: 0
  }

  [dir='ltr'] .print\:ltr\:bottom-0,[dir='ltr'].print\:ltr\:bottom-0 {
    bottom: 0
  }

  [dir='ltr'] .print\:ltr\:left-0,[dir='ltr'].print\:ltr\:left-0 {
    left: 0
  }

  [dir='ltr'] .print\:ltr\:top-1,[dir='ltr'].print\:ltr\:top-1 {
    top: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:right-1,[dir='ltr'].print\:ltr\:right-1 {
    right: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:bottom-1,[dir='ltr'].print\:ltr\:bottom-1 {
    bottom: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:left-1,[dir='ltr'].print\:ltr\:left-1 {
    left: 0.1rem
  }

  [dir='ltr'] .print\:ltr\:top-2,[dir='ltr'].print\:ltr\:top-2 {
    top: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:right-2,[dir='ltr'].print\:ltr\:right-2 {
    right: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:bottom-2,[dir='ltr'].print\:ltr\:bottom-2 {
    bottom: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:left-2,[dir='ltr'].print\:ltr\:left-2 {
    left: 0.2rem
  }

  [dir='ltr'] .print\:ltr\:top-3,[dir='ltr'].print\:ltr\:top-3 {
    top: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:right-3,[dir='ltr'].print\:ltr\:right-3 {
    right: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:bottom-3,[dir='ltr'].print\:ltr\:bottom-3 {
    bottom: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:left-3,[dir='ltr'].print\:ltr\:left-3 {
    left: 0.3rem
  }

  [dir='ltr'] .print\:ltr\:top-4,[dir='ltr'].print\:ltr\:top-4 {
    top: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:right-4,[dir='ltr'].print\:ltr\:right-4 {
    right: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:bottom-4,[dir='ltr'].print\:ltr\:bottom-4 {
    bottom: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:left-4,[dir='ltr'].print\:ltr\:left-4 {
    left: 0.4rem
  }

  [dir='ltr'] .print\:ltr\:top-5,[dir='ltr'].print\:ltr\:top-5 {
    top: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:right-5,[dir='ltr'].print\:ltr\:right-5 {
    right: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:bottom-5,[dir='ltr'].print\:ltr\:bottom-5 {
    bottom: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:left-5,[dir='ltr'].print\:ltr\:left-5 {
    left: 0.5rem
  }

  [dir='ltr'] .print\:ltr\:top-6,[dir='ltr'].print\:ltr\:top-6 {
    top: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:right-6,[dir='ltr'].print\:ltr\:right-6 {
    right: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:bottom-6,[dir='ltr'].print\:ltr\:bottom-6 {
    bottom: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:left-6,[dir='ltr'].print\:ltr\:left-6 {
    left: 0.6rem
  }

  [dir='ltr'] .print\:ltr\:top-7,[dir='ltr'].print\:ltr\:top-7 {
    top: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:right-7,[dir='ltr'].print\:ltr\:right-7 {
    right: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:bottom-7,[dir='ltr'].print\:ltr\:bottom-7 {
    bottom: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:left-7,[dir='ltr'].print\:ltr\:left-7 {
    left: 0.7rem
  }

  [dir='ltr'] .print\:ltr\:top-8,[dir='ltr'].print\:ltr\:top-8 {
    top: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:right-8,[dir='ltr'].print\:ltr\:right-8 {
    right: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:bottom-8,[dir='ltr'].print\:ltr\:bottom-8 {
    bottom: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:left-8,[dir='ltr'].print\:ltr\:left-8 {
    left: 0.8rem
  }

  [dir='ltr'] .print\:ltr\:top-9,[dir='ltr'].print\:ltr\:top-9 {
    top: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:right-9,[dir='ltr'].print\:ltr\:right-9 {
    right: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:bottom-9,[dir='ltr'].print\:ltr\:bottom-9 {
    bottom: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:left-9,[dir='ltr'].print\:ltr\:left-9 {
    left: 0.9rem
  }

  [dir='ltr'] .print\:ltr\:top-10,[dir='ltr'].print\:ltr\:top-10 {
    top: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:right-10,[dir='ltr'].print\:ltr\:right-10 {
    right: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:bottom-10,[dir='ltr'].print\:ltr\:bottom-10 {
    bottom: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:left-10,[dir='ltr'].print\:ltr\:left-10 {
    left: 1.0rem
  }

  [dir='ltr'] .print\:ltr\:top-12,[dir='ltr'].print\:ltr\:top-12 {
    top: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:right-12,[dir='ltr'].print\:ltr\:right-12 {
    right: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:bottom-12,[dir='ltr'].print\:ltr\:bottom-12 {
    bottom: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:left-12,[dir='ltr'].print\:ltr\:left-12 {
    left: 1.2rem
  }

  [dir='ltr'] .print\:ltr\:top-14,[dir='ltr'].print\:ltr\:top-14 {
    top: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:right-14,[dir='ltr'].print\:ltr\:right-14 {
    right: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:bottom-14,[dir='ltr'].print\:ltr\:bottom-14 {
    bottom: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:left-14,[dir='ltr'].print\:ltr\:left-14 {
    left: 1.4rem
  }

  [dir='ltr'] .print\:ltr\:top-16,[dir='ltr'].print\:ltr\:top-16 {
    top: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:right-16,[dir='ltr'].print\:ltr\:right-16 {
    right: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:bottom-16,[dir='ltr'].print\:ltr\:bottom-16 {
    bottom: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:left-16,[dir='ltr'].print\:ltr\:left-16 {
    left: 1.6rem
  }

  [dir='ltr'] .print\:ltr\:top-20,[dir='ltr'].print\:ltr\:top-20 {
    top: 2rem
  }

  [dir='ltr'] .print\:ltr\:right-20,[dir='ltr'].print\:ltr\:right-20 {
    right: 2rem
  }

  [dir='ltr'] .print\:ltr\:bottom-20,[dir='ltr'].print\:ltr\:bottom-20 {
    bottom: 2rem
  }

  [dir='ltr'] .print\:ltr\:left-20,[dir='ltr'].print\:ltr\:left-20 {
    left: 2rem
  }

  [dir='ltr'] .print\:ltr\:top-24,[dir='ltr'].print\:ltr\:top-24 {
    top: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:right-24,[dir='ltr'].print\:ltr\:right-24 {
    right: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:bottom-24,[dir='ltr'].print\:ltr\:bottom-24 {
    bottom: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:left-24,[dir='ltr'].print\:ltr\:left-24 {
    left: 2.4rem
  }

  [dir='ltr'] .print\:ltr\:top-28,[dir='ltr'].print\:ltr\:top-28 {
    top: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:right-28,[dir='ltr'].print\:ltr\:right-28 {
    right: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:bottom-28,[dir='ltr'].print\:ltr\:bottom-28 {
    bottom: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:left-28,[dir='ltr'].print\:ltr\:left-28 {
    left: 2.8rem
  }

  [dir='ltr'] .print\:ltr\:top-32,[dir='ltr'].print\:ltr\:top-32 {
    top: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:right-32,[dir='ltr'].print\:ltr\:right-32 {
    right: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:bottom-32,[dir='ltr'].print\:ltr\:bottom-32 {
    bottom: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:left-32,[dir='ltr'].print\:ltr\:left-32 {
    left: 3.2rem
  }

  [dir='ltr'] .print\:ltr\:top-36,[dir='ltr'].print\:ltr\:top-36 {
    top: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:right-36,[dir='ltr'].print\:ltr\:right-36 {
    right: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:bottom-36,[dir='ltr'].print\:ltr\:bottom-36 {
    bottom: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:left-36,[dir='ltr'].print\:ltr\:left-36 {
    left: 3.6rem
  }

  [dir='ltr'] .print\:ltr\:top-40,[dir='ltr'].print\:ltr\:top-40 {
    top: 4rem
  }

  [dir='ltr'] .print\:ltr\:right-40,[dir='ltr'].print\:ltr\:right-40 {
    right: 4rem
  }

  [dir='ltr'] .print\:ltr\:bottom-40,[dir='ltr'].print\:ltr\:bottom-40 {
    bottom: 4rem
  }

  [dir='ltr'] .print\:ltr\:left-40,[dir='ltr'].print\:ltr\:left-40 {
    left: 4rem
  }

  [dir='ltr'] .print\:ltr\:top-44,[dir='ltr'].print\:ltr\:top-44 {
    top: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:right-44,[dir='ltr'].print\:ltr\:right-44 {
    right: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:bottom-44,[dir='ltr'].print\:ltr\:bottom-44 {
    bottom: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:left-44,[dir='ltr'].print\:ltr\:left-44 {
    left: 4.4rem
  }

  [dir='ltr'] .print\:ltr\:top-48,[dir='ltr'].print\:ltr\:top-48 {
    top: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:right-48,[dir='ltr'].print\:ltr\:right-48 {
    right: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:bottom-48,[dir='ltr'].print\:ltr\:bottom-48 {
    bottom: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:left-48,[dir='ltr'].print\:ltr\:left-48 {
    left: 4.8rem
  }

  [dir='ltr'] .print\:ltr\:top-52,[dir='ltr'].print\:ltr\:top-52 {
    top: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:right-52,[dir='ltr'].print\:ltr\:right-52 {
    right: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:bottom-52,[dir='ltr'].print\:ltr\:bottom-52 {
    bottom: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:left-52,[dir='ltr'].print\:ltr\:left-52 {
    left: 5.2rem
  }

  [dir='ltr'] .print\:ltr\:top-56,[dir='ltr'].print\:ltr\:top-56 {
    top: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:right-56,[dir='ltr'].print\:ltr\:right-56 {
    right: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:bottom-56,[dir='ltr'].print\:ltr\:bottom-56 {
    bottom: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:left-56,[dir='ltr'].print\:ltr\:left-56 {
    left: 5.6rem
  }

  [dir='ltr'] .print\:ltr\:top-60,[dir='ltr'].print\:ltr\:top-60 {
    top: 6rem
  }

  [dir='ltr'] .print\:ltr\:right-60,[dir='ltr'].print\:ltr\:right-60 {
    right: 6rem
  }

  [dir='ltr'] .print\:ltr\:bottom-60,[dir='ltr'].print\:ltr\:bottom-60 {
    bottom: 6rem
  }

  [dir='ltr'] .print\:ltr\:left-60,[dir='ltr'].print\:ltr\:left-60 {
    left: 6rem
  }

  [dir='ltr'] .print\:ltr\:top-64,[dir='ltr'].print\:ltr\:top-64 {
    top: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:right-64,[dir='ltr'].print\:ltr\:right-64 {
    right: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:bottom-64,[dir='ltr'].print\:ltr\:bottom-64 {
    bottom: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:left-64,[dir='ltr'].print\:ltr\:left-64 {
    left: 6.4rem
  }

  [dir='ltr'] .print\:ltr\:top-68,[dir='ltr'].print\:ltr\:top-68 {
    top: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:right-68,[dir='ltr'].print\:ltr\:right-68 {
    right: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:bottom-68,[dir='ltr'].print\:ltr\:bottom-68 {
    bottom: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:left-68,[dir='ltr'].print\:ltr\:left-68 {
    left: 6.8rem
  }

  [dir='ltr'] .print\:ltr\:top-72,[dir='ltr'].print\:ltr\:top-72 {
    top: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:right-72,[dir='ltr'].print\:ltr\:right-72 {
    right: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:bottom-72,[dir='ltr'].print\:ltr\:bottom-72 {
    bottom: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:left-72,[dir='ltr'].print\:ltr\:left-72 {
    left: 7.2rem
  }

  [dir='ltr'] .print\:ltr\:top-76,[dir='ltr'].print\:ltr\:top-76 {
    top: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:right-76,[dir='ltr'].print\:ltr\:right-76 {
    right: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:bottom-76,[dir='ltr'].print\:ltr\:bottom-76 {
    bottom: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:left-76,[dir='ltr'].print\:ltr\:left-76 {
    left: 7.6rem
  }

  [dir='ltr'] .print\:ltr\:top-80,[dir='ltr'].print\:ltr\:top-80 {
    top: 8rem
  }

  [dir='ltr'] .print\:ltr\:right-80,[dir='ltr'].print\:ltr\:right-80 {
    right: 8rem
  }

  [dir='ltr'] .print\:ltr\:bottom-80,[dir='ltr'].print\:ltr\:bottom-80 {
    bottom: 8rem
  }

  [dir='ltr'] .print\:ltr\:left-80,[dir='ltr'].print\:ltr\:left-80 {
    left: 8rem
  }

  [dir='ltr'] .print\:ltr\:top-84,[dir='ltr'].print\:ltr\:top-84 {
    top: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:right-84,[dir='ltr'].print\:ltr\:right-84 {
    right: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:bottom-84,[dir='ltr'].print\:ltr\:bottom-84 {
    bottom: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:left-84,[dir='ltr'].print\:ltr\:left-84 {
    left: 8.4rem
  }

  [dir='ltr'] .print\:ltr\:top-88,[dir='ltr'].print\:ltr\:top-88 {
    top: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:right-88,[dir='ltr'].print\:ltr\:right-88 {
    right: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:bottom-88,[dir='ltr'].print\:ltr\:bottom-88 {
    bottom: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:left-88,[dir='ltr'].print\:ltr\:left-88 {
    left: 8.8rem
  }

  [dir='ltr'] .print\:ltr\:top-92,[dir='ltr'].print\:ltr\:top-92 {
    top: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:right-92,[dir='ltr'].print\:ltr\:right-92 {
    right: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:bottom-92,[dir='ltr'].print\:ltr\:bottom-92 {
    bottom: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:left-92,[dir='ltr'].print\:ltr\:left-92 {
    left: 9.2rem
  }

  [dir='ltr'] .print\:ltr\:top-96,[dir='ltr'].print\:ltr\:top-96 {
    top: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:right-96,[dir='ltr'].print\:ltr\:right-96 {
    right: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:bottom-96,[dir='ltr'].print\:ltr\:bottom-96 {
    bottom: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:left-96,[dir='ltr'].print\:ltr\:left-96 {
    left: 9.6rem
  }

  [dir='ltr'] .print\:ltr\:top-128,[dir='ltr'].print\:ltr\:top-128 {
    top: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:right-128,[dir='ltr'].print\:ltr\:right-128 {
    right: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:bottom-128,[dir='ltr'].print\:ltr\:bottom-128 {
    bottom: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:left-128,[dir='ltr'].print\:ltr\:left-128 {
    left: 12.8rem
  }

  [dir='ltr'] .print\:ltr\:top-136,[dir='ltr'].print\:ltr\:top-136 {
    top: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:right-136,[dir='ltr'].print\:ltr\:right-136 {
    right: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:bottom-136,[dir='ltr'].print\:ltr\:bottom-136 {
    bottom: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:left-136,[dir='ltr'].print\:ltr\:left-136 {
    left: 13.6rem
  }

  [dir='ltr'] .print\:ltr\:top-160,[dir='ltr'].print\:ltr\:top-160 {
    top: 16rem
  }

  [dir='ltr'] .print\:ltr\:right-160,[dir='ltr'].print\:ltr\:right-160 {
    right: 16rem
  }

  [dir='ltr'] .print\:ltr\:bottom-160,[dir='ltr'].print\:ltr\:bottom-160 {
    bottom: 16rem
  }

  [dir='ltr'] .print\:ltr\:left-160,[dir='ltr'].print\:ltr\:left-160 {
    left: 16rem
  }

  [dir='ltr'] .print\:ltr\:top-192,[dir='ltr'].print\:ltr\:top-192 {
    top: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:right-192,[dir='ltr'].print\:ltr\:right-192 {
    right: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:bottom-192,[dir='ltr'].print\:ltr\:bottom-192 {
    bottom: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:left-192,[dir='ltr'].print\:ltr\:left-192 {
    left: 19.2rem
  }

  [dir='ltr'] .print\:ltr\:top-200,[dir='ltr'].print\:ltr\:top-200 {
    top: 20rem
  }

  [dir='ltr'] .print\:ltr\:right-200,[dir='ltr'].print\:ltr\:right-200 {
    right: 20rem
  }

  [dir='ltr'] .print\:ltr\:bottom-200,[dir='ltr'].print\:ltr\:bottom-200 {
    bottom: 20rem
  }

  [dir='ltr'] .print\:ltr\:left-200,[dir='ltr'].print\:ltr\:left-200 {
    left: 20rem
  }

  [dir='ltr'] .print\:ltr\:top-208,[dir='ltr'].print\:ltr\:top-208 {
    top: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:right-208,[dir='ltr'].print\:ltr\:right-208 {
    right: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:bottom-208,[dir='ltr'].print\:ltr\:bottom-208 {
    bottom: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:left-208,[dir='ltr'].print\:ltr\:left-208 {
    left: 20.8rem
  }

  [dir='ltr'] .print\:ltr\:top-216,[dir='ltr'].print\:ltr\:top-216 {
    top: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:right-216,[dir='ltr'].print\:ltr\:right-216 {
    right: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:bottom-216,[dir='ltr'].print\:ltr\:bottom-216 {
    bottom: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:left-216,[dir='ltr'].print\:ltr\:left-216 {
    left: 21.6rem
  }

  [dir='ltr'] .print\:ltr\:top-224,[dir='ltr'].print\:ltr\:top-224 {
    top: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:right-224,[dir='ltr'].print\:ltr\:right-224 {
    right: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:bottom-224,[dir='ltr'].print\:ltr\:bottom-224 {
    bottom: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:left-224,[dir='ltr'].print\:ltr\:left-224 {
    left: 22.4rem
  }

  [dir='ltr'] .print\:ltr\:top-256,[dir='ltr'].print\:ltr\:top-256 {
    top: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:right-256,[dir='ltr'].print\:ltr\:right-256 {
    right: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:bottom-256,[dir='ltr'].print\:ltr\:bottom-256 {
    bottom: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:left-256,[dir='ltr'].print\:ltr\:left-256 {
    left: 25.6rem
  }

  [dir='ltr'] .print\:ltr\:top-288,[dir='ltr'].print\:ltr\:top-288 {
    top: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:right-288,[dir='ltr'].print\:ltr\:right-288 {
    right: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:bottom-288,[dir='ltr'].print\:ltr\:bottom-288 {
    bottom: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:left-288,[dir='ltr'].print\:ltr\:left-288 {
    left: 28.8rem
  }

  [dir='ltr'] .print\:ltr\:top-320,[dir='ltr'].print\:ltr\:top-320 {
    top: 32rem
  }

  [dir='ltr'] .print\:ltr\:right-320,[dir='ltr'].print\:ltr\:right-320 {
    right: 32rem
  }

  [dir='ltr'] .print\:ltr\:bottom-320,[dir='ltr'].print\:ltr\:bottom-320 {
    bottom: 32rem
  }

  [dir='ltr'] .print\:ltr\:left-320,[dir='ltr'].print\:ltr\:left-320 {
    left: 32rem
  }

  [dir='ltr'] .print\:ltr\:top-360,[dir='ltr'].print\:ltr\:top-360 {
    top: 36rem
  }

  [dir='ltr'] .print\:ltr\:right-360,[dir='ltr'].print\:ltr\:right-360 {
    right: 36rem
  }

  [dir='ltr'] .print\:ltr\:bottom-360,[dir='ltr'].print\:ltr\:bottom-360 {
    bottom: 36rem
  }

  [dir='ltr'] .print\:ltr\:left-360,[dir='ltr'].print\:ltr\:left-360 {
    left: 36rem
  }

  [dir='ltr'] .print\:ltr\:top-384,[dir='ltr'].print\:ltr\:top-384 {
    top: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:right-384,[dir='ltr'].print\:ltr\:right-384 {
    right: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:bottom-384,[dir='ltr'].print\:ltr\:bottom-384 {
    bottom: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:left-384,[dir='ltr'].print\:ltr\:left-384 {
    left: 38.4rem
  }

  [dir='ltr'] .print\:ltr\:top-400,[dir='ltr'].print\:ltr\:top-400 {
    top: 40rem
  }

  [dir='ltr'] .print\:ltr\:right-400,[dir='ltr'].print\:ltr\:right-400 {
    right: 40rem
  }

  [dir='ltr'] .print\:ltr\:bottom-400,[dir='ltr'].print\:ltr\:bottom-400 {
    bottom: 40rem
  }

  [dir='ltr'] .print\:ltr\:left-400,[dir='ltr'].print\:ltr\:left-400 {
    left: 40rem
  }

  [dir='ltr'] .print\:ltr\:top-512,[dir='ltr'].print\:ltr\:top-512 {
    top: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:right-512,[dir='ltr'].print\:ltr\:right-512 {
    right: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:bottom-512,[dir='ltr'].print\:ltr\:bottom-512 {
    bottom: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:left-512,[dir='ltr'].print\:ltr\:left-512 {
    left: 51.2rem
  }

  [dir='ltr'] .print\:ltr\:top-640,[dir='ltr'].print\:ltr\:top-640 {
    top: 64rem
  }

  [dir='ltr'] .print\:ltr\:right-640,[dir='ltr'].print\:ltr\:right-640 {
    right: 64rem
  }

  [dir='ltr'] .print\:ltr\:bottom-640,[dir='ltr'].print\:ltr\:bottom-640 {
    bottom: 64rem
  }

  [dir='ltr'] .print\:ltr\:left-640,[dir='ltr'].print\:ltr\:left-640 {
    left: 64rem
  }

  [dir='ltr'] .print\:ltr\:top-auto,[dir='ltr'].print\:ltr\:top-auto {
    top: auto
  }

  [dir='ltr'] .print\:ltr\:right-auto,[dir='ltr'].print\:ltr\:right-auto {
    right: auto
  }

  [dir='ltr'] .print\:ltr\:bottom-auto,[dir='ltr'].print\:ltr\:bottom-auto {
    bottom: auto
  }

  [dir='ltr'] .print\:ltr\:left-auto,[dir='ltr'].print\:ltr\:left-auto {
    left: auto
  }

  [dir='ltr'] .print\:ltr\:top-xs,[dir='ltr'].print\:ltr\:top-xs {
    top: 32rem
  }

  [dir='ltr'] .print\:ltr\:right-xs,[dir='ltr'].print\:ltr\:right-xs {
    right: 32rem
  }

  [dir='ltr'] .print\:ltr\:bottom-xs,[dir='ltr'].print\:ltr\:bottom-xs {
    bottom: 32rem
  }

  [dir='ltr'] .print\:ltr\:left-xs,[dir='ltr'].print\:ltr\:left-xs {
    left: 32rem
  }

  [dir='ltr'] .print\:ltr\:top-sm,[dir='ltr'].print\:ltr\:top-sm {
    top: 48rem
  }

  [dir='ltr'] .print\:ltr\:right-sm,[dir='ltr'].print\:ltr\:right-sm {
    right: 48rem
  }

  [dir='ltr'] .print\:ltr\:bottom-sm,[dir='ltr'].print\:ltr\:bottom-sm {
    bottom: 48rem
  }

  [dir='ltr'] .print\:ltr\:left-sm,[dir='ltr'].print\:ltr\:left-sm {
    left: 48rem
  }

  [dir='ltr'] .print\:ltr\:top-md,[dir='ltr'].print\:ltr\:top-md {
    top: 64rem
  }

  [dir='ltr'] .print\:ltr\:right-md,[dir='ltr'].print\:ltr\:right-md {
    right: 64rem
  }

  [dir='ltr'] .print\:ltr\:bottom-md,[dir='ltr'].print\:ltr\:bottom-md {
    bottom: 64rem
  }

  [dir='ltr'] .print\:ltr\:left-md,[dir='ltr'].print\:ltr\:left-md {
    left: 64rem
  }

  [dir='ltr'] .print\:ltr\:top-lg,[dir='ltr'].print\:ltr\:top-lg {
    top: 80rem
  }

  [dir='ltr'] .print\:ltr\:right-lg,[dir='ltr'].print\:ltr\:right-lg {
    right: 80rem
  }

  [dir='ltr'] .print\:ltr\:bottom-lg,[dir='ltr'].print\:ltr\:bottom-lg {
    bottom: 80rem
  }

  [dir='ltr'] .print\:ltr\:left-lg,[dir='ltr'].print\:ltr\:left-lg {
    left: 80rem
  }

  [dir='ltr'] .print\:ltr\:top-xl,[dir='ltr'].print\:ltr\:top-xl {
    top: 96rem
  }

  [dir='ltr'] .print\:ltr\:right-xl,[dir='ltr'].print\:ltr\:right-xl {
    right: 96rem
  }

  [dir='ltr'] .print\:ltr\:bottom-xl,[dir='ltr'].print\:ltr\:bottom-xl {
    bottom: 96rem
  }

  [dir='ltr'] .print\:ltr\:left-xl,[dir='ltr'].print\:ltr\:left-xl {
    left: 96rem
  }

  [dir='ltr'] .print\:ltr\:top-2xl,[dir='ltr'].print\:ltr\:top-2xl {
    top: 112rem
  }

  [dir='ltr'] .print\:ltr\:right-2xl,[dir='ltr'].print\:ltr\:right-2xl {
    right: 112rem
  }

  [dir='ltr'] .print\:ltr\:bottom-2xl,[dir='ltr'].print\:ltr\:bottom-2xl {
    bottom: 112rem
  }

  [dir='ltr'] .print\:ltr\:left-2xl,[dir='ltr'].print\:ltr\:left-2xl {
    left: 112rem
  }

  [dir='ltr'] .print\:ltr\:top-3xl,[dir='ltr'].print\:ltr\:top-3xl {
    top: 128rem
  }

  [dir='ltr'] .print\:ltr\:right-3xl,[dir='ltr'].print\:ltr\:right-3xl {
    right: 128rem
  }

  [dir='ltr'] .print\:ltr\:bottom-3xl,[dir='ltr'].print\:ltr\:bottom-3xl {
    bottom: 128rem
  }

  [dir='ltr'] .print\:ltr\:left-3xl,[dir='ltr'].print\:ltr\:left-3xl {
    left: 128rem
  }

  [dir='ltr'] .print\:ltr\:top-4xl,[dir='ltr'].print\:ltr\:top-4xl {
    top: 144rem
  }

  [dir='ltr'] .print\:ltr\:right-4xl,[dir='ltr'].print\:ltr\:right-4xl {
    right: 144rem
  }

  [dir='ltr'] .print\:ltr\:bottom-4xl,[dir='ltr'].print\:ltr\:bottom-4xl {
    bottom: 144rem
  }

  [dir='ltr'] .print\:ltr\:left-4xl,[dir='ltr'].print\:ltr\:left-4xl {
    left: 144rem
  }

  [dir='ltr'] .print\:ltr\:top-5xl,[dir='ltr'].print\:ltr\:top-5xl {
    top: 160rem
  }

  [dir='ltr'] .print\:ltr\:right-5xl,[dir='ltr'].print\:ltr\:right-5xl {
    right: 160rem
  }

  [dir='ltr'] .print\:ltr\:bottom-5xl,[dir='ltr'].print\:ltr\:bottom-5xl {
    bottom: 160rem
  }

  [dir='ltr'] .print\:ltr\:left-5xl,[dir='ltr'].print\:ltr\:left-5xl {
    left: 160rem
  }

  [dir='ltr'] .print\:ltr\:top-px,[dir='ltr'].print\:ltr\:top-px {
    top: 1px
  }

  [dir='ltr'] .print\:ltr\:right-px,[dir='ltr'].print\:ltr\:right-px {
    right: 1px
  }

  [dir='ltr'] .print\:ltr\:bottom-px,[dir='ltr'].print\:ltr\:bottom-px {
    bottom: 1px
  }

  [dir='ltr'] .print\:ltr\:left-px,[dir='ltr'].print\:ltr\:left-px {
    left: 1px
  }

  [dir='ltr'] .print\:ltr\:top-0\.5,[dir='ltr'].print\:ltr\:top-0\.5 {
    top: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:right-0\.5,[dir='ltr'].print\:ltr\:right-0\.5 {
    right: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:bottom-0\.5,[dir='ltr'].print\:ltr\:bottom-0\.5 {
    bottom: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:left-0\.5,[dir='ltr'].print\:ltr\:left-0\.5 {
    left: 0.05rem
  }

  [dir='ltr'] .print\:ltr\:top-1\.5,[dir='ltr'].print\:ltr\:top-1\.5 {
    top: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:right-1\.5,[dir='ltr'].print\:ltr\:right-1\.5 {
    right: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:bottom-1\.5,[dir='ltr'].print\:ltr\:bottom-1\.5 {
    bottom: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:left-1\.5,[dir='ltr'].print\:ltr\:left-1\.5 {
    left: 0.15rem
  }

  [dir='ltr'] .print\:ltr\:top-2\.5,[dir='ltr'].print\:ltr\:top-2\.5 {
    top: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:right-2\.5,[dir='ltr'].print\:ltr\:right-2\.5 {
    right: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:bottom-2\.5,[dir='ltr'].print\:ltr\:bottom-2\.5 {
    bottom: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:left-2\.5,[dir='ltr'].print\:ltr\:left-2\.5 {
    left: 0.25rem
  }

  [dir='ltr'] .print\:ltr\:top-3\.5,[dir='ltr'].print\:ltr\:top-3\.5 {
    top: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:right-3\.5,[dir='ltr'].print\:ltr\:right-3\.5 {
    right: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:bottom-3\.5,[dir='ltr'].print\:ltr\:bottom-3\.5 {
    bottom: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:left-3\.5,[dir='ltr'].print\:ltr\:left-3\.5 {
    left: 0.35rem
  }

  [dir='ltr'] .print\:ltr\:-top-1,[dir='ltr'].print\:ltr\:-top-1 {
    top: -0.1rem
  }

  [dir='ltr'] .print\:ltr\:-right-1,[dir='ltr'].print\:ltr\:-right-1 {
    right: -0.1rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-1,[dir='ltr'].print\:ltr\:-bottom-1 {
    bottom: -0.1rem
  }

  [dir='ltr'] .print\:ltr\:-left-1,[dir='ltr'].print\:ltr\:-left-1 {
    left: -0.1rem
  }

  [dir='ltr'] .print\:ltr\:-top-2,[dir='ltr'].print\:ltr\:-top-2 {
    top: -0.2rem
  }

  [dir='ltr'] .print\:ltr\:-right-2,[dir='ltr'].print\:ltr\:-right-2 {
    right: -0.2rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-2,[dir='ltr'].print\:ltr\:-bottom-2 {
    bottom: -0.2rem
  }

  [dir='ltr'] .print\:ltr\:-left-2,[dir='ltr'].print\:ltr\:-left-2 {
    left: -0.2rem
  }

  [dir='ltr'] .print\:ltr\:-top-3,[dir='ltr'].print\:ltr\:-top-3 {
    top: -0.3rem
  }

  [dir='ltr'] .print\:ltr\:-right-3,[dir='ltr'].print\:ltr\:-right-3 {
    right: -0.3rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-3,[dir='ltr'].print\:ltr\:-bottom-3 {
    bottom: -0.3rem
  }

  [dir='ltr'] .print\:ltr\:-left-3,[dir='ltr'].print\:ltr\:-left-3 {
    left: -0.3rem
  }

  [dir='ltr'] .print\:ltr\:-top-4,[dir='ltr'].print\:ltr\:-top-4 {
    top: -0.4rem
  }

  [dir='ltr'] .print\:ltr\:-right-4,[dir='ltr'].print\:ltr\:-right-4 {
    right: -0.4rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-4,[dir='ltr'].print\:ltr\:-bottom-4 {
    bottom: -0.4rem
  }

  [dir='ltr'] .print\:ltr\:-left-4,[dir='ltr'].print\:ltr\:-left-4 {
    left: -0.4rem
  }

  [dir='ltr'] .print\:ltr\:-top-5,[dir='ltr'].print\:ltr\:-top-5 {
    top: -0.5rem
  }

  [dir='ltr'] .print\:ltr\:-right-5,[dir='ltr'].print\:ltr\:-right-5 {
    right: -0.5rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-5,[dir='ltr'].print\:ltr\:-bottom-5 {
    bottom: -0.5rem
  }

  [dir='ltr'] .print\:ltr\:-left-5,[dir='ltr'].print\:ltr\:-left-5 {
    left: -0.5rem
  }

  [dir='ltr'] .print\:ltr\:-top-6,[dir='ltr'].print\:ltr\:-top-6 {
    top: -0.6rem
  }

  [dir='ltr'] .print\:ltr\:-right-6,[dir='ltr'].print\:ltr\:-right-6 {
    right: -0.6rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-6,[dir='ltr'].print\:ltr\:-bottom-6 {
    bottom: -0.6rem
  }

  [dir='ltr'] .print\:ltr\:-left-6,[dir='ltr'].print\:ltr\:-left-6 {
    left: -0.6rem
  }

  [dir='ltr'] .print\:ltr\:-top-7,[dir='ltr'].print\:ltr\:-top-7 {
    top: -0.7rem
  }

  [dir='ltr'] .print\:ltr\:-right-7,[dir='ltr'].print\:ltr\:-right-7 {
    right: -0.7rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-7,[dir='ltr'].print\:ltr\:-bottom-7 {
    bottom: -0.7rem
  }

  [dir='ltr'] .print\:ltr\:-left-7,[dir='ltr'].print\:ltr\:-left-7 {
    left: -0.7rem
  }

  [dir='ltr'] .print\:ltr\:-top-8,[dir='ltr'].print\:ltr\:-top-8 {
    top: -0.8rem
  }

  [dir='ltr'] .print\:ltr\:-right-8,[dir='ltr'].print\:ltr\:-right-8 {
    right: -0.8rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-8,[dir='ltr'].print\:ltr\:-bottom-8 {
    bottom: -0.8rem
  }

  [dir='ltr'] .print\:ltr\:-left-8,[dir='ltr'].print\:ltr\:-left-8 {
    left: -0.8rem
  }

  [dir='ltr'] .print\:ltr\:-top-9,[dir='ltr'].print\:ltr\:-top-9 {
    top: -0.9rem
  }

  [dir='ltr'] .print\:ltr\:-right-9,[dir='ltr'].print\:ltr\:-right-9 {
    right: -0.9rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-9,[dir='ltr'].print\:ltr\:-bottom-9 {
    bottom: -0.9rem
  }

  [dir='ltr'] .print\:ltr\:-left-9,[dir='ltr'].print\:ltr\:-left-9 {
    left: -0.9rem
  }

  [dir='ltr'] .print\:ltr\:-top-10,[dir='ltr'].print\:ltr\:-top-10 {
    top: -1rem
  }

  [dir='ltr'] .print\:ltr\:-right-10,[dir='ltr'].print\:ltr\:-right-10 {
    right: -1rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-10,[dir='ltr'].print\:ltr\:-bottom-10 {
    bottom: -1rem
  }

  [dir='ltr'] .print\:ltr\:-left-10,[dir='ltr'].print\:ltr\:-left-10 {
    left: -1rem
  }

  [dir='ltr'] .print\:ltr\:-top-12,[dir='ltr'].print\:ltr\:-top-12 {
    top: -1.2rem
  }

  [dir='ltr'] .print\:ltr\:-right-12,[dir='ltr'].print\:ltr\:-right-12 {
    right: -1.2rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-12,[dir='ltr'].print\:ltr\:-bottom-12 {
    bottom: -1.2rem
  }

  [dir='ltr'] .print\:ltr\:-left-12,[dir='ltr'].print\:ltr\:-left-12 {
    left: -1.2rem
  }

  [dir='ltr'] .print\:ltr\:-top-14,[dir='ltr'].print\:ltr\:-top-14 {
    top: -1.4rem
  }

  [dir='ltr'] .print\:ltr\:-right-14,[dir='ltr'].print\:ltr\:-right-14 {
    right: -1.4rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-14,[dir='ltr'].print\:ltr\:-bottom-14 {
    bottom: -1.4rem
  }

  [dir='ltr'] .print\:ltr\:-left-14,[dir='ltr'].print\:ltr\:-left-14 {
    left: -1.4rem
  }

  [dir='ltr'] .print\:ltr\:-top-16,[dir='ltr'].print\:ltr\:-top-16 {
    top: -1.6rem
  }

  [dir='ltr'] .print\:ltr\:-right-16,[dir='ltr'].print\:ltr\:-right-16 {
    right: -1.6rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-16,[dir='ltr'].print\:ltr\:-bottom-16 {
    bottom: -1.6rem
  }

  [dir='ltr'] .print\:ltr\:-left-16,[dir='ltr'].print\:ltr\:-left-16 {
    left: -1.6rem
  }

  [dir='ltr'] .print\:ltr\:-top-20,[dir='ltr'].print\:ltr\:-top-20 {
    top: -2rem
  }

  [dir='ltr'] .print\:ltr\:-right-20,[dir='ltr'].print\:ltr\:-right-20 {
    right: -2rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-20,[dir='ltr'].print\:ltr\:-bottom-20 {
    bottom: -2rem
  }

  [dir='ltr'] .print\:ltr\:-left-20,[dir='ltr'].print\:ltr\:-left-20 {
    left: -2rem
  }

  [dir='ltr'] .print\:ltr\:-top-24,[dir='ltr'].print\:ltr\:-top-24 {
    top: -2.4rem
  }

  [dir='ltr'] .print\:ltr\:-right-24,[dir='ltr'].print\:ltr\:-right-24 {
    right: -2.4rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-24,[dir='ltr'].print\:ltr\:-bottom-24 {
    bottom: -2.4rem
  }

  [dir='ltr'] .print\:ltr\:-left-24,[dir='ltr'].print\:ltr\:-left-24 {
    left: -2.4rem
  }

  [dir='ltr'] .print\:ltr\:-top-28,[dir='ltr'].print\:ltr\:-top-28 {
    top: -2.8rem
  }

  [dir='ltr'] .print\:ltr\:-right-28,[dir='ltr'].print\:ltr\:-right-28 {
    right: -2.8rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-28,[dir='ltr'].print\:ltr\:-bottom-28 {
    bottom: -2.8rem
  }

  [dir='ltr'] .print\:ltr\:-left-28,[dir='ltr'].print\:ltr\:-left-28 {
    left: -2.8rem
  }

  [dir='ltr'] .print\:ltr\:-top-32,[dir='ltr'].print\:ltr\:-top-32 {
    top: -3.2rem
  }

  [dir='ltr'] .print\:ltr\:-right-32,[dir='ltr'].print\:ltr\:-right-32 {
    right: -3.2rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-32,[dir='ltr'].print\:ltr\:-bottom-32 {
    bottom: -3.2rem
  }

  [dir='ltr'] .print\:ltr\:-left-32,[dir='ltr'].print\:ltr\:-left-32 {
    left: -3.2rem
  }

  [dir='ltr'] .print\:ltr\:-top-36,[dir='ltr'].print\:ltr\:-top-36 {
    top: -3.6rem
  }

  [dir='ltr'] .print\:ltr\:-right-36,[dir='ltr'].print\:ltr\:-right-36 {
    right: -3.6rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-36,[dir='ltr'].print\:ltr\:-bottom-36 {
    bottom: -3.6rem
  }

  [dir='ltr'] .print\:ltr\:-left-36,[dir='ltr'].print\:ltr\:-left-36 {
    left: -3.6rem
  }

  [dir='ltr'] .print\:ltr\:-top-40,[dir='ltr'].print\:ltr\:-top-40 {
    top: -4rem
  }

  [dir='ltr'] .print\:ltr\:-right-40,[dir='ltr'].print\:ltr\:-right-40 {
    right: -4rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-40,[dir='ltr'].print\:ltr\:-bottom-40 {
    bottom: -4rem
  }

  [dir='ltr'] .print\:ltr\:-left-40,[dir='ltr'].print\:ltr\:-left-40 {
    left: -4rem
  }

  [dir='ltr'] .print\:ltr\:-top-44,[dir='ltr'].print\:ltr\:-top-44 {
    top: -4.4rem
  }

  [dir='ltr'] .print\:ltr\:-right-44,[dir='ltr'].print\:ltr\:-right-44 {
    right: -4.4rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-44,[dir='ltr'].print\:ltr\:-bottom-44 {
    bottom: -4.4rem
  }

  [dir='ltr'] .print\:ltr\:-left-44,[dir='ltr'].print\:ltr\:-left-44 {
    left: -4.4rem
  }

  [dir='ltr'] .print\:ltr\:-top-48,[dir='ltr'].print\:ltr\:-top-48 {
    top: -4.8rem
  }

  [dir='ltr'] .print\:ltr\:-right-48,[dir='ltr'].print\:ltr\:-right-48 {
    right: -4.8rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-48,[dir='ltr'].print\:ltr\:-bottom-48 {
    bottom: -4.8rem
  }

  [dir='ltr'] .print\:ltr\:-left-48,[dir='ltr'].print\:ltr\:-left-48 {
    left: -4.8rem
  }

  [dir='ltr'] .print\:ltr\:-top-52,[dir='ltr'].print\:ltr\:-top-52 {
    top: -5.2rem
  }

  [dir='ltr'] .print\:ltr\:-right-52,[dir='ltr'].print\:ltr\:-right-52 {
    right: -5.2rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-52,[dir='ltr'].print\:ltr\:-bottom-52 {
    bottom: -5.2rem
  }

  [dir='ltr'] .print\:ltr\:-left-52,[dir='ltr'].print\:ltr\:-left-52 {
    left: -5.2rem
  }

  [dir='ltr'] .print\:ltr\:-top-56,[dir='ltr'].print\:ltr\:-top-56 {
    top: -5.6rem
  }

  [dir='ltr'] .print\:ltr\:-right-56,[dir='ltr'].print\:ltr\:-right-56 {
    right: -5.6rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-56,[dir='ltr'].print\:ltr\:-bottom-56 {
    bottom: -5.6rem
  }

  [dir='ltr'] .print\:ltr\:-left-56,[dir='ltr'].print\:ltr\:-left-56 {
    left: -5.6rem
  }

  [dir='ltr'] .print\:ltr\:-top-60,[dir='ltr'].print\:ltr\:-top-60 {
    top: -6rem
  }

  [dir='ltr'] .print\:ltr\:-right-60,[dir='ltr'].print\:ltr\:-right-60 {
    right: -6rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-60,[dir='ltr'].print\:ltr\:-bottom-60 {
    bottom: -6rem
  }

  [dir='ltr'] .print\:ltr\:-left-60,[dir='ltr'].print\:ltr\:-left-60 {
    left: -6rem
  }

  [dir='ltr'] .print\:ltr\:-top-64,[dir='ltr'].print\:ltr\:-top-64 {
    top: -6.4rem
  }

  [dir='ltr'] .print\:ltr\:-right-64,[dir='ltr'].print\:ltr\:-right-64 {
    right: -6.4rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-64,[dir='ltr'].print\:ltr\:-bottom-64 {
    bottom: -6.4rem
  }

  [dir='ltr'] .print\:ltr\:-left-64,[dir='ltr'].print\:ltr\:-left-64 {
    left: -6.4rem
  }

  [dir='ltr'] .print\:ltr\:-top-68,[dir='ltr'].print\:ltr\:-top-68 {
    top: -6.8rem
  }

  [dir='ltr'] .print\:ltr\:-right-68,[dir='ltr'].print\:ltr\:-right-68 {
    right: -6.8rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-68,[dir='ltr'].print\:ltr\:-bottom-68 {
    bottom: -6.8rem
  }

  [dir='ltr'] .print\:ltr\:-left-68,[dir='ltr'].print\:ltr\:-left-68 {
    left: -6.8rem
  }

  [dir='ltr'] .print\:ltr\:-top-72,[dir='ltr'].print\:ltr\:-top-72 {
    top: -7.2rem
  }

  [dir='ltr'] .print\:ltr\:-right-72,[dir='ltr'].print\:ltr\:-right-72 {
    right: -7.2rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-72,[dir='ltr'].print\:ltr\:-bottom-72 {
    bottom: -7.2rem
  }

  [dir='ltr'] .print\:ltr\:-left-72,[dir='ltr'].print\:ltr\:-left-72 {
    left: -7.2rem
  }

  [dir='ltr'] .print\:ltr\:-top-76,[dir='ltr'].print\:ltr\:-top-76 {
    top: -7.6rem
  }

  [dir='ltr'] .print\:ltr\:-right-76,[dir='ltr'].print\:ltr\:-right-76 {
    right: -7.6rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-76,[dir='ltr'].print\:ltr\:-bottom-76 {
    bottom: -7.6rem
  }

  [dir='ltr'] .print\:ltr\:-left-76,[dir='ltr'].print\:ltr\:-left-76 {
    left: -7.6rem
  }

  [dir='ltr'] .print\:ltr\:-top-80,[dir='ltr'].print\:ltr\:-top-80 {
    top: -8rem
  }

  [dir='ltr'] .print\:ltr\:-right-80,[dir='ltr'].print\:ltr\:-right-80 {
    right: -8rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-80,[dir='ltr'].print\:ltr\:-bottom-80 {
    bottom: -8rem
  }

  [dir='ltr'] .print\:ltr\:-left-80,[dir='ltr'].print\:ltr\:-left-80 {
    left: -8rem
  }

  [dir='ltr'] .print\:ltr\:-top-84,[dir='ltr'].print\:ltr\:-top-84 {
    top: -8.4rem
  }

  [dir='ltr'] .print\:ltr\:-right-84,[dir='ltr'].print\:ltr\:-right-84 {
    right: -8.4rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-84,[dir='ltr'].print\:ltr\:-bottom-84 {
    bottom: -8.4rem
  }

  [dir='ltr'] .print\:ltr\:-left-84,[dir='ltr'].print\:ltr\:-left-84 {
    left: -8.4rem
  }

  [dir='ltr'] .print\:ltr\:-top-88,[dir='ltr'].print\:ltr\:-top-88 {
    top: -8.8rem
  }

  [dir='ltr'] .print\:ltr\:-right-88,[dir='ltr'].print\:ltr\:-right-88 {
    right: -8.8rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-88,[dir='ltr'].print\:ltr\:-bottom-88 {
    bottom: -8.8rem
  }

  [dir='ltr'] .print\:ltr\:-left-88,[dir='ltr'].print\:ltr\:-left-88 {
    left: -8.8rem
  }

  [dir='ltr'] .print\:ltr\:-top-92,[dir='ltr'].print\:ltr\:-top-92 {
    top: -9.2rem
  }

  [dir='ltr'] .print\:ltr\:-right-92,[dir='ltr'].print\:ltr\:-right-92 {
    right: -9.2rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-92,[dir='ltr'].print\:ltr\:-bottom-92 {
    bottom: -9.2rem
  }

  [dir='ltr'] .print\:ltr\:-left-92,[dir='ltr'].print\:ltr\:-left-92 {
    left: -9.2rem
  }

  [dir='ltr'] .print\:ltr\:-top-96,[dir='ltr'].print\:ltr\:-top-96 {
    top: -9.6rem
  }

  [dir='ltr'] .print\:ltr\:-right-96,[dir='ltr'].print\:ltr\:-right-96 {
    right: -9.6rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-96,[dir='ltr'].print\:ltr\:-bottom-96 {
    bottom: -9.6rem
  }

  [dir='ltr'] .print\:ltr\:-left-96,[dir='ltr'].print\:ltr\:-left-96 {
    left: -9.6rem
  }

  [dir='ltr'] .print\:ltr\:-top-128,[dir='ltr'].print\:ltr\:-top-128 {
    top: -12.8rem
  }

  [dir='ltr'] .print\:ltr\:-right-128,[dir='ltr'].print\:ltr\:-right-128 {
    right: -12.8rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-128,[dir='ltr'].print\:ltr\:-bottom-128 {
    bottom: -12.8rem
  }

  [dir='ltr'] .print\:ltr\:-left-128,[dir='ltr'].print\:ltr\:-left-128 {
    left: -12.8rem
  }

  [dir='ltr'] .print\:ltr\:-top-136,[dir='ltr'].print\:ltr\:-top-136 {
    top: -13.6rem
  }

  [dir='ltr'] .print\:ltr\:-right-136,[dir='ltr'].print\:ltr\:-right-136 {
    right: -13.6rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-136,[dir='ltr'].print\:ltr\:-bottom-136 {
    bottom: -13.6rem
  }

  [dir='ltr'] .print\:ltr\:-left-136,[dir='ltr'].print\:ltr\:-left-136 {
    left: -13.6rem
  }

  [dir='ltr'] .print\:ltr\:-top-160,[dir='ltr'].print\:ltr\:-top-160 {
    top: -16rem
  }

  [dir='ltr'] .print\:ltr\:-right-160,[dir='ltr'].print\:ltr\:-right-160 {
    right: -16rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-160,[dir='ltr'].print\:ltr\:-bottom-160 {
    bottom: -16rem
  }

  [dir='ltr'] .print\:ltr\:-left-160,[dir='ltr'].print\:ltr\:-left-160 {
    left: -16rem
  }

  [dir='ltr'] .print\:ltr\:-top-192,[dir='ltr'].print\:ltr\:-top-192 {
    top: -19.2rem
  }

  [dir='ltr'] .print\:ltr\:-right-192,[dir='ltr'].print\:ltr\:-right-192 {
    right: -19.2rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-192,[dir='ltr'].print\:ltr\:-bottom-192 {
    bottom: -19.2rem
  }

  [dir='ltr'] .print\:ltr\:-left-192,[dir='ltr'].print\:ltr\:-left-192 {
    left: -19.2rem
  }

  [dir='ltr'] .print\:ltr\:-top-200,[dir='ltr'].print\:ltr\:-top-200 {
    top: -20rem
  }

  [dir='ltr'] .print\:ltr\:-right-200,[dir='ltr'].print\:ltr\:-right-200 {
    right: -20rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-200,[dir='ltr'].print\:ltr\:-bottom-200 {
    bottom: -20rem
  }

  [dir='ltr'] .print\:ltr\:-left-200,[dir='ltr'].print\:ltr\:-left-200 {
    left: -20rem
  }

  [dir='ltr'] .print\:ltr\:-top-208,[dir='ltr'].print\:ltr\:-top-208 {
    top: -20.8rem
  }

  [dir='ltr'] .print\:ltr\:-right-208,[dir='ltr'].print\:ltr\:-right-208 {
    right: -20.8rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-208,[dir='ltr'].print\:ltr\:-bottom-208 {
    bottom: -20.8rem
  }

  [dir='ltr'] .print\:ltr\:-left-208,[dir='ltr'].print\:ltr\:-left-208 {
    left: -20.8rem
  }

  [dir='ltr'] .print\:ltr\:-top-216,[dir='ltr'].print\:ltr\:-top-216 {
    top: -21.6rem
  }

  [dir='ltr'] .print\:ltr\:-right-216,[dir='ltr'].print\:ltr\:-right-216 {
    right: -21.6rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-216,[dir='ltr'].print\:ltr\:-bottom-216 {
    bottom: -21.6rem
  }

  [dir='ltr'] .print\:ltr\:-left-216,[dir='ltr'].print\:ltr\:-left-216 {
    left: -21.6rem
  }

  [dir='ltr'] .print\:ltr\:-top-224,[dir='ltr'].print\:ltr\:-top-224 {
    top: -22.4rem
  }

  [dir='ltr'] .print\:ltr\:-right-224,[dir='ltr'].print\:ltr\:-right-224 {
    right: -22.4rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-224,[dir='ltr'].print\:ltr\:-bottom-224 {
    bottom: -22.4rem
  }

  [dir='ltr'] .print\:ltr\:-left-224,[dir='ltr'].print\:ltr\:-left-224 {
    left: -22.4rem
  }

  [dir='ltr'] .print\:ltr\:-top-256,[dir='ltr'].print\:ltr\:-top-256 {
    top: -25.6rem
  }

  [dir='ltr'] .print\:ltr\:-right-256,[dir='ltr'].print\:ltr\:-right-256 {
    right: -25.6rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-256,[dir='ltr'].print\:ltr\:-bottom-256 {
    bottom: -25.6rem
  }

  [dir='ltr'] .print\:ltr\:-left-256,[dir='ltr'].print\:ltr\:-left-256 {
    left: -25.6rem
  }

  [dir='ltr'] .print\:ltr\:-top-288,[dir='ltr'].print\:ltr\:-top-288 {
    top: -28.8rem
  }

  [dir='ltr'] .print\:ltr\:-right-288,[dir='ltr'].print\:ltr\:-right-288 {
    right: -28.8rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-288,[dir='ltr'].print\:ltr\:-bottom-288 {
    bottom: -28.8rem
  }

  [dir='ltr'] .print\:ltr\:-left-288,[dir='ltr'].print\:ltr\:-left-288 {
    left: -28.8rem
  }

  [dir='ltr'] .print\:ltr\:-top-320,[dir='ltr'].print\:ltr\:-top-320 {
    top: -32rem
  }

  [dir='ltr'] .print\:ltr\:-right-320,[dir='ltr'].print\:ltr\:-right-320 {
    right: -32rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-320,[dir='ltr'].print\:ltr\:-bottom-320 {
    bottom: -32rem
  }

  [dir='ltr'] .print\:ltr\:-left-320,[dir='ltr'].print\:ltr\:-left-320 {
    left: -32rem
  }

  [dir='ltr'] .print\:ltr\:-top-360,[dir='ltr'].print\:ltr\:-top-360 {
    top: -36rem
  }

  [dir='ltr'] .print\:ltr\:-right-360,[dir='ltr'].print\:ltr\:-right-360 {
    right: -36rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-360,[dir='ltr'].print\:ltr\:-bottom-360 {
    bottom: -36rem
  }

  [dir='ltr'] .print\:ltr\:-left-360,[dir='ltr'].print\:ltr\:-left-360 {
    left: -36rem
  }

  [dir='ltr'] .print\:ltr\:-top-384,[dir='ltr'].print\:ltr\:-top-384 {
    top: -38.4rem
  }

  [dir='ltr'] .print\:ltr\:-right-384,[dir='ltr'].print\:ltr\:-right-384 {
    right: -38.4rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-384,[dir='ltr'].print\:ltr\:-bottom-384 {
    bottom: -38.4rem
  }

  [dir='ltr'] .print\:ltr\:-left-384,[dir='ltr'].print\:ltr\:-left-384 {
    left: -38.4rem
  }

  [dir='ltr'] .print\:ltr\:-top-400,[dir='ltr'].print\:ltr\:-top-400 {
    top: -40rem
  }

  [dir='ltr'] .print\:ltr\:-right-400,[dir='ltr'].print\:ltr\:-right-400 {
    right: -40rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-400,[dir='ltr'].print\:ltr\:-bottom-400 {
    bottom: -40rem
  }

  [dir='ltr'] .print\:ltr\:-left-400,[dir='ltr'].print\:ltr\:-left-400 {
    left: -40rem
  }

  [dir='ltr'] .print\:ltr\:-top-512,[dir='ltr'].print\:ltr\:-top-512 {
    top: -51.2rem
  }

  [dir='ltr'] .print\:ltr\:-right-512,[dir='ltr'].print\:ltr\:-right-512 {
    right: -51.2rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-512,[dir='ltr'].print\:ltr\:-bottom-512 {
    bottom: -51.2rem
  }

  [dir='ltr'] .print\:ltr\:-left-512,[dir='ltr'].print\:ltr\:-left-512 {
    left: -51.2rem
  }

  [dir='ltr'] .print\:ltr\:-top-640,[dir='ltr'].print\:ltr\:-top-640 {
    top: -64rem
  }

  [dir='ltr'] .print\:ltr\:-right-640,[dir='ltr'].print\:ltr\:-right-640 {
    right: -64rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-640,[dir='ltr'].print\:ltr\:-bottom-640 {
    bottom: -64rem
  }

  [dir='ltr'] .print\:ltr\:-left-640,[dir='ltr'].print\:ltr\:-left-640 {
    left: -64rem
  }

  [dir='ltr'] .print\:ltr\:-top-xs,[dir='ltr'].print\:ltr\:-top-xs {
    top: -32rem
  }

  [dir='ltr'] .print\:ltr\:-right-xs,[dir='ltr'].print\:ltr\:-right-xs {
    right: -32rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-xs,[dir='ltr'].print\:ltr\:-bottom-xs {
    bottom: -32rem
  }

  [dir='ltr'] .print\:ltr\:-left-xs,[dir='ltr'].print\:ltr\:-left-xs {
    left: -32rem
  }

  [dir='ltr'] .print\:ltr\:-top-sm,[dir='ltr'].print\:ltr\:-top-sm {
    top: -48rem
  }

  [dir='ltr'] .print\:ltr\:-right-sm,[dir='ltr'].print\:ltr\:-right-sm {
    right: -48rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-sm,[dir='ltr'].print\:ltr\:-bottom-sm {
    bottom: -48rem
  }

  [dir='ltr'] .print\:ltr\:-left-sm,[dir='ltr'].print\:ltr\:-left-sm {
    left: -48rem
  }

  [dir='ltr'] .print\:ltr\:-top-md,[dir='ltr'].print\:ltr\:-top-md {
    top: -64rem
  }

  [dir='ltr'] .print\:ltr\:-right-md,[dir='ltr'].print\:ltr\:-right-md {
    right: -64rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-md,[dir='ltr'].print\:ltr\:-bottom-md {
    bottom: -64rem
  }

  [dir='ltr'] .print\:ltr\:-left-md,[dir='ltr'].print\:ltr\:-left-md {
    left: -64rem
  }

  [dir='ltr'] .print\:ltr\:-top-lg,[dir='ltr'].print\:ltr\:-top-lg {
    top: -80rem
  }

  [dir='ltr'] .print\:ltr\:-right-lg,[dir='ltr'].print\:ltr\:-right-lg {
    right: -80rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-lg,[dir='ltr'].print\:ltr\:-bottom-lg {
    bottom: -80rem
  }

  [dir='ltr'] .print\:ltr\:-left-lg,[dir='ltr'].print\:ltr\:-left-lg {
    left: -80rem
  }

  [dir='ltr'] .print\:ltr\:-top-xl,[dir='ltr'].print\:ltr\:-top-xl {
    top: -96rem
  }

  [dir='ltr'] .print\:ltr\:-right-xl,[dir='ltr'].print\:ltr\:-right-xl {
    right: -96rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-xl,[dir='ltr'].print\:ltr\:-bottom-xl {
    bottom: -96rem
  }

  [dir='ltr'] .print\:ltr\:-left-xl,[dir='ltr'].print\:ltr\:-left-xl {
    left: -96rem
  }

  [dir='ltr'] .print\:ltr\:-top-2xl,[dir='ltr'].print\:ltr\:-top-2xl {
    top: -112rem
  }

  [dir='ltr'] .print\:ltr\:-right-2xl,[dir='ltr'].print\:ltr\:-right-2xl {
    right: -112rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-2xl,[dir='ltr'].print\:ltr\:-bottom-2xl {
    bottom: -112rem
  }

  [dir='ltr'] .print\:ltr\:-left-2xl,[dir='ltr'].print\:ltr\:-left-2xl {
    left: -112rem
  }

  [dir='ltr'] .print\:ltr\:-top-3xl,[dir='ltr'].print\:ltr\:-top-3xl {
    top: -128rem
  }

  [dir='ltr'] .print\:ltr\:-right-3xl,[dir='ltr'].print\:ltr\:-right-3xl {
    right: -128rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-3xl,[dir='ltr'].print\:ltr\:-bottom-3xl {
    bottom: -128rem
  }

  [dir='ltr'] .print\:ltr\:-left-3xl,[dir='ltr'].print\:ltr\:-left-3xl {
    left: -128rem
  }

  [dir='ltr'] .print\:ltr\:-top-4xl,[dir='ltr'].print\:ltr\:-top-4xl {
    top: -144rem
  }

  [dir='ltr'] .print\:ltr\:-right-4xl,[dir='ltr'].print\:ltr\:-right-4xl {
    right: -144rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-4xl,[dir='ltr'].print\:ltr\:-bottom-4xl {
    bottom: -144rem
  }

  [dir='ltr'] .print\:ltr\:-left-4xl,[dir='ltr'].print\:ltr\:-left-4xl {
    left: -144rem
  }

  [dir='ltr'] .print\:ltr\:-top-5xl,[dir='ltr'].print\:ltr\:-top-5xl {
    top: -160rem
  }

  [dir='ltr'] .print\:ltr\:-right-5xl,[dir='ltr'].print\:ltr\:-right-5xl {
    right: -160rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-5xl,[dir='ltr'].print\:ltr\:-bottom-5xl {
    bottom: -160rem
  }

  [dir='ltr'] .print\:ltr\:-left-5xl,[dir='ltr'].print\:ltr\:-left-5xl {
    left: -160rem
  }

  [dir='ltr'] .print\:ltr\:-top-px,[dir='ltr'].print\:ltr\:-top-px {
    top: -1px
  }

  [dir='ltr'] .print\:ltr\:-right-px,[dir='ltr'].print\:ltr\:-right-px {
    right: -1px
  }

  [dir='ltr'] .print\:ltr\:-bottom-px,[dir='ltr'].print\:ltr\:-bottom-px {
    bottom: -1px
  }

  [dir='ltr'] .print\:ltr\:-left-px,[dir='ltr'].print\:ltr\:-left-px {
    left: -1px
  }

  [dir='ltr'] .print\:ltr\:-top-0\.5,[dir='ltr'].print\:ltr\:-top-0\.5 {
    top: -0.05rem
  }

  [dir='ltr'] .print\:ltr\:-right-0\.5,[dir='ltr'].print\:ltr\:-right-0\.5 {
    right: -0.05rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-0\.5,[dir='ltr'].print\:ltr\:-bottom-0\.5 {
    bottom: -0.05rem
  }

  [dir='ltr'] .print\:ltr\:-left-0\.5,[dir='ltr'].print\:ltr\:-left-0\.5 {
    left: -0.05rem
  }

  [dir='ltr'] .print\:ltr\:-top-1\.5,[dir='ltr'].print\:ltr\:-top-1\.5 {
    top: -0.15rem
  }

  [dir='ltr'] .print\:ltr\:-right-1\.5,[dir='ltr'].print\:ltr\:-right-1\.5 {
    right: -0.15rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-1\.5,[dir='ltr'].print\:ltr\:-bottom-1\.5 {
    bottom: -0.15rem
  }

  [dir='ltr'] .print\:ltr\:-left-1\.5,[dir='ltr'].print\:ltr\:-left-1\.5 {
    left: -0.15rem
  }

  [dir='ltr'] .print\:ltr\:-top-2\.5,[dir='ltr'].print\:ltr\:-top-2\.5 {
    top: -0.25rem
  }

  [dir='ltr'] .print\:ltr\:-right-2\.5,[dir='ltr'].print\:ltr\:-right-2\.5 {
    right: -0.25rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-2\.5,[dir='ltr'].print\:ltr\:-bottom-2\.5 {
    bottom: -0.25rem
  }

  [dir='ltr'] .print\:ltr\:-left-2\.5,[dir='ltr'].print\:ltr\:-left-2\.5 {
    left: -0.25rem
  }

  [dir='ltr'] .print\:ltr\:-top-3\.5,[dir='ltr'].print\:ltr\:-top-3\.5 {
    top: -0.35rem
  }

  [dir='ltr'] .print\:ltr\:-right-3\.5,[dir='ltr'].print\:ltr\:-right-3\.5 {
    right: -0.35rem
  }

  [dir='ltr'] .print\:ltr\:-bottom-3\.5,[dir='ltr'].print\:ltr\:-bottom-3\.5 {
    bottom: -0.35rem
  }

  [dir='ltr'] .print\:ltr\:-left-3\.5,[dir='ltr'].print\:ltr\:-left-3\.5 {
    left: -0.35rem
  }

  [dir='ltr'] .print\:ltr\:top-1\/2,[dir='ltr'].print\:ltr\:top-1\/2 {
    top: 50%
  }

  [dir='ltr'] .print\:ltr\:right-1\/2,[dir='ltr'].print\:ltr\:right-1\/2 {
    right: 50%
  }

  [dir='ltr'] .print\:ltr\:bottom-1\/2,[dir='ltr'].print\:ltr\:bottom-1\/2 {
    bottom: 50%
  }

  [dir='ltr'] .print\:ltr\:left-1\/2,[dir='ltr'].print\:ltr\:left-1\/2 {
    left: 50%
  }

  [dir='ltr'] .print\:ltr\:top-1\/3,[dir='ltr'].print\:ltr\:top-1\/3 {
    top: 33.333333%
  }

  [dir='ltr'] .print\:ltr\:right-1\/3,[dir='ltr'].print\:ltr\:right-1\/3 {
    right: 33.333333%
  }

  [dir='ltr'] .print\:ltr\:bottom-1\/3,[dir='ltr'].print\:ltr\:bottom-1\/3 {
    bottom: 33.333333%
  }

  [dir='ltr'] .print\:ltr\:left-1\/3,[dir='ltr'].print\:ltr\:left-1\/3 {
    left: 33.333333%
  }

  [dir='ltr'] .print\:ltr\:top-2\/3,[dir='ltr'].print\:ltr\:top-2\/3 {
    top: 66.666667%
  }

  [dir='ltr'] .print\:ltr\:right-2\/3,[dir='ltr'].print\:ltr\:right-2\/3 {
    right: 66.666667%
  }

  [dir='ltr'] .print\:ltr\:bottom-2\/3,[dir='ltr'].print\:ltr\:bottom-2\/3 {
    bottom: 66.666667%
  }

  [dir='ltr'] .print\:ltr\:left-2\/3,[dir='ltr'].print\:ltr\:left-2\/3 {
    left: 66.666667%
  }

  [dir='ltr'] .print\:ltr\:top-1\/4,[dir='ltr'].print\:ltr\:top-1\/4 {
    top: 25%
  }

  [dir='ltr'] .print\:ltr\:right-1\/4,[dir='ltr'].print\:ltr\:right-1\/4 {
    right: 25%
  }

  [dir='ltr'] .print\:ltr\:bottom-1\/4,[dir='ltr'].print\:ltr\:bottom-1\/4 {
    bottom: 25%
  }

  [dir='ltr'] .print\:ltr\:left-1\/4,[dir='ltr'].print\:ltr\:left-1\/4 {
    left: 25%
  }

  [dir='ltr'] .print\:ltr\:top-2\/4,[dir='ltr'].print\:ltr\:top-2\/4 {
    top: 50%
  }

  [dir='ltr'] .print\:ltr\:right-2\/4,[dir='ltr'].print\:ltr\:right-2\/4 {
    right: 50%
  }

  [dir='ltr'] .print\:ltr\:bottom-2\/4,[dir='ltr'].print\:ltr\:bottom-2\/4 {
    bottom: 50%
  }

  [dir='ltr'] .print\:ltr\:left-2\/4,[dir='ltr'].print\:ltr\:left-2\/4 {
    left: 50%
  }

  [dir='ltr'] .print\:ltr\:top-3\/4,[dir='ltr'].print\:ltr\:top-3\/4 {
    top: 75%
  }

  [dir='ltr'] .print\:ltr\:right-3\/4,[dir='ltr'].print\:ltr\:right-3\/4 {
    right: 75%
  }

  [dir='ltr'] .print\:ltr\:bottom-3\/4,[dir='ltr'].print\:ltr\:bottom-3\/4 {
    bottom: 75%
  }

  [dir='ltr'] .print\:ltr\:left-3\/4,[dir='ltr'].print\:ltr\:left-3\/4 {
    left: 75%
  }

  [dir='ltr'] .print\:ltr\:top-full,[dir='ltr'].print\:ltr\:top-full {
    top: 100%
  }

  [dir='ltr'] .print\:ltr\:right-full,[dir='ltr'].print\:ltr\:right-full {
    right: 100%
  }

  [dir='ltr'] .print\:ltr\:bottom-full,[dir='ltr'].print\:ltr\:bottom-full {
    bottom: 100%
  }

  [dir='ltr'] .print\:ltr\:left-full,[dir='ltr'].print\:ltr\:left-full {
    left: 100%
  }

  [dir='ltr'] .print\:ltr\:-top-1\/2,[dir='ltr'].print\:ltr\:-top-1\/2 {
    top: -50%
  }

  [dir='ltr'] .print\:ltr\:-right-1\/2,[dir='ltr'].print\:ltr\:-right-1\/2 {
    right: -50%
  }

  [dir='ltr'] .print\:ltr\:-bottom-1\/2,[dir='ltr'].print\:ltr\:-bottom-1\/2 {
    bottom: -50%
  }

  [dir='ltr'] .print\:ltr\:-left-1\/2,[dir='ltr'].print\:ltr\:-left-1\/2 {
    left: -50%
  }

  [dir='ltr'] .print\:ltr\:-top-1\/3,[dir='ltr'].print\:ltr\:-top-1\/3 {
    top: -33.333333%
  }

  [dir='ltr'] .print\:ltr\:-right-1\/3,[dir='ltr'].print\:ltr\:-right-1\/3 {
    right: -33.333333%
  }

  [dir='ltr'] .print\:ltr\:-bottom-1\/3,[dir='ltr'].print\:ltr\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  [dir='ltr'] .print\:ltr\:-left-1\/3,[dir='ltr'].print\:ltr\:-left-1\/3 {
    left: -33.333333%
  }

  [dir='ltr'] .print\:ltr\:-top-2\/3,[dir='ltr'].print\:ltr\:-top-2\/3 {
    top: -66.666667%
  }

  [dir='ltr'] .print\:ltr\:-right-2\/3,[dir='ltr'].print\:ltr\:-right-2\/3 {
    right: -66.666667%
  }

  [dir='ltr'] .print\:ltr\:-bottom-2\/3,[dir='ltr'].print\:ltr\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  [dir='ltr'] .print\:ltr\:-left-2\/3,[dir='ltr'].print\:ltr\:-left-2\/3 {
    left: -66.666667%
  }

  [dir='ltr'] .print\:ltr\:-top-1\/4,[dir='ltr'].print\:ltr\:-top-1\/4 {
    top: -25%
  }

  [dir='ltr'] .print\:ltr\:-right-1\/4,[dir='ltr'].print\:ltr\:-right-1\/4 {
    right: -25%
  }

  [dir='ltr'] .print\:ltr\:-bottom-1\/4,[dir='ltr'].print\:ltr\:-bottom-1\/4 {
    bottom: -25%
  }

  [dir='ltr'] .print\:ltr\:-left-1\/4,[dir='ltr'].print\:ltr\:-left-1\/4 {
    left: -25%
  }

  [dir='ltr'] .print\:ltr\:-top-2\/4,[dir='ltr'].print\:ltr\:-top-2\/4 {
    top: -50%
  }

  [dir='ltr'] .print\:ltr\:-right-2\/4,[dir='ltr'].print\:ltr\:-right-2\/4 {
    right: -50%
  }

  [dir='ltr'] .print\:ltr\:-bottom-2\/4,[dir='ltr'].print\:ltr\:-bottom-2\/4 {
    bottom: -50%
  }

  [dir='ltr'] .print\:ltr\:-left-2\/4,[dir='ltr'].print\:ltr\:-left-2\/4 {
    left: -50%
  }

  [dir='ltr'] .print\:ltr\:-top-3\/4,[dir='ltr'].print\:ltr\:-top-3\/4 {
    top: -75%
  }

  [dir='ltr'] .print\:ltr\:-right-3\/4,[dir='ltr'].print\:ltr\:-right-3\/4 {
    right: -75%
  }

  [dir='ltr'] .print\:ltr\:-bottom-3\/4,[dir='ltr'].print\:ltr\:-bottom-3\/4 {
    bottom: -75%
  }

  [dir='ltr'] .print\:ltr\:-left-3\/4,[dir='ltr'].print\:ltr\:-left-3\/4 {
    left: -75%
  }

  [dir='ltr'] .print\:ltr\:-top-full,[dir='ltr'].print\:ltr\:-top-full {
    top: -100%
  }

  [dir='ltr'] .print\:ltr\:-right-full,[dir='ltr'].print\:ltr\:-right-full {
    right: -100%
  }

  [dir='ltr'] .print\:ltr\:-bottom-full,[dir='ltr'].print\:ltr\:-bottom-full {
    bottom: -100%
  }

  [dir='ltr'] .print\:ltr\:-left-full,[dir='ltr'].print\:ltr\:-left-full {
    left: -100%
  }

  [dir='rtl'] .print\:rtl\:inset-0,[dir='rtl'].print\:rtl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  [dir='rtl'] .print\:rtl\:inset-1,[dir='rtl'].print\:rtl\:inset-1 {
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:inset-2,[dir='rtl'].print\:rtl\:inset-2 {
    top: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-3,[dir='rtl'].print\:rtl\:inset-3 {
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:inset-4,[dir='rtl'].print\:rtl\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-5,[dir='rtl'].print\:rtl\:inset-5 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:inset-6,[dir='rtl'].print\:rtl\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-7,[dir='rtl'].print\:rtl\:inset-7 {
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
    left: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:inset-8,[dir='rtl'].print\:rtl\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-9,[dir='rtl'].print\:rtl\:inset-9 {
    top: 0.9rem;
    right: 0.9rem;
    bottom: 0.9rem;
    left: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:inset-10,[dir='rtl'].print\:rtl\:inset-10 {
    top: 1.0rem;
    right: 1.0rem;
    bottom: 1.0rem;
    left: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:inset-12,[dir='rtl'].print\:rtl\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-14,[dir='rtl'].print\:rtl\:inset-14 {
    top: 1.4rem;
    right: 1.4rem;
    bottom: 1.4rem;
    left: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-16,[dir='rtl'].print\:rtl\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-20,[dir='rtl'].print\:rtl\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  [dir='rtl'] .print\:rtl\:inset-24,[dir='rtl'].print\:rtl\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-28,[dir='rtl'].print\:rtl\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-32,[dir='rtl'].print\:rtl\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-36,[dir='rtl'].print\:rtl\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-40,[dir='rtl'].print\:rtl\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  [dir='rtl'] .print\:rtl\:inset-44,[dir='rtl'].print\:rtl\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-48,[dir='rtl'].print\:rtl\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-52,[dir='rtl'].print\:rtl\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-56,[dir='rtl'].print\:rtl\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-60,[dir='rtl'].print\:rtl\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  [dir='rtl'] .print\:rtl\:inset-64,[dir='rtl'].print\:rtl\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-68,[dir='rtl'].print\:rtl\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-72,[dir='rtl'].print\:rtl\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-76,[dir='rtl'].print\:rtl\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-80,[dir='rtl'].print\:rtl\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  [dir='rtl'] .print\:rtl\:inset-84,[dir='rtl'].print\:rtl\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-88,[dir='rtl'].print\:rtl\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-92,[dir='rtl'].print\:rtl\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-96,[dir='rtl'].print\:rtl\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-128,[dir='rtl'].print\:rtl\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-136,[dir='rtl'].print\:rtl\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-160,[dir='rtl'].print\:rtl\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  [dir='rtl'] .print\:rtl\:inset-192,[dir='rtl'].print\:rtl\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-200,[dir='rtl'].print\:rtl\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  [dir='rtl'] .print\:rtl\:inset-208,[dir='rtl'].print\:rtl\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-216,[dir='rtl'].print\:rtl\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-224,[dir='rtl'].print\:rtl\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-256,[dir='rtl'].print\:rtl\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-288,[dir='rtl'].print\:rtl\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-320,[dir='rtl'].print\:rtl\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='rtl'] .print\:rtl\:inset-360,[dir='rtl'].print\:rtl\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  [dir='rtl'] .print\:rtl\:inset-384,[dir='rtl'].print\:rtl\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-400,[dir='rtl'].print\:rtl\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  [dir='rtl'] .print\:rtl\:inset-512,[dir='rtl'].print\:rtl\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-640,[dir='rtl'].print\:rtl\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='rtl'] .print\:rtl\:inset-auto,[dir='rtl'].print\:rtl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  [dir='rtl'] .print\:rtl\:inset-xs,[dir='rtl'].print\:rtl\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  [dir='rtl'] .print\:rtl\:inset-sm,[dir='rtl'].print\:rtl\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  [dir='rtl'] .print\:rtl\:inset-md,[dir='rtl'].print\:rtl\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  [dir='rtl'] .print\:rtl\:inset-lg,[dir='rtl'].print\:rtl\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  [dir='rtl'] .print\:rtl\:inset-xl,[dir='rtl'].print\:rtl\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  [dir='rtl'] .print\:rtl\:inset-2xl,[dir='rtl'].print\:rtl\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  [dir='rtl'] .print\:rtl\:inset-3xl,[dir='rtl'].print\:rtl\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  [dir='rtl'] .print\:rtl\:inset-4xl,[dir='rtl'].print\:rtl\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  [dir='rtl'] .print\:rtl\:inset-5xl,[dir='rtl'].print\:rtl\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  [dir='rtl'] .print\:rtl\:inset-px,[dir='rtl'].print\:rtl\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  [dir='rtl'] .print\:rtl\:inset-0\.5,[dir='rtl'].print\:rtl\:inset-0\.5 {
    top: 0.05rem;
    right: 0.05rem;
    bottom: 0.05rem;
    left: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:inset-1\.5,[dir='rtl'].print\:rtl\:inset-1\.5 {
    top: 0.15rem;
    right: 0.15rem;
    bottom: 0.15rem;
    left: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:inset-2\.5,[dir='rtl'].print\:rtl\:inset-2\.5 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:inset-3\.5,[dir='rtl'].print\:rtl\:inset-3\.5 {
    top: 0.35rem;
    right: 0.35rem;
    bottom: 0.35rem;
    left: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:-inset-1,[dir='rtl'].print\:rtl\:-inset-1 {
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem
  }

  [dir='rtl'] .print\:rtl\:-inset-2,[dir='rtl'].print\:rtl\:-inset-2 {
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-3,[dir='rtl'].print\:rtl\:-inset-3 {
    top: -0.3rem;
    right: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem
  }

  [dir='rtl'] .print\:rtl\:-inset-4,[dir='rtl'].print\:rtl\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-5,[dir='rtl'].print\:rtl\:-inset-5 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  [dir='rtl'] .print\:rtl\:-inset-6,[dir='rtl'].print\:rtl\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-7,[dir='rtl'].print\:rtl\:-inset-7 {
    top: -0.7rem;
    right: -0.7rem;
    bottom: -0.7rem;
    left: -0.7rem
  }

  [dir='rtl'] .print\:rtl\:-inset-8,[dir='rtl'].print\:rtl\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-9,[dir='rtl'].print\:rtl\:-inset-9 {
    top: -0.9rem;
    right: -0.9rem;
    bottom: -0.9rem;
    left: -0.9rem
  }

  [dir='rtl'] .print\:rtl\:-inset-10,[dir='rtl'].print\:rtl\:-inset-10 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  [dir='rtl'] .print\:rtl\:-inset-12,[dir='rtl'].print\:rtl\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-14,[dir='rtl'].print\:rtl\:-inset-14 {
    top: -1.4rem;
    right: -1.4rem;
    bottom: -1.4rem;
    left: -1.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-16,[dir='rtl'].print\:rtl\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-20,[dir='rtl'].print\:rtl\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-24,[dir='rtl'].print\:rtl\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-28,[dir='rtl'].print\:rtl\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-32,[dir='rtl'].print\:rtl\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-36,[dir='rtl'].print\:rtl\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-40,[dir='rtl'].print\:rtl\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-44,[dir='rtl'].print\:rtl\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-48,[dir='rtl'].print\:rtl\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-52,[dir='rtl'].print\:rtl\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-56,[dir='rtl'].print\:rtl\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-60,[dir='rtl'].print\:rtl\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-64,[dir='rtl'].print\:rtl\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-68,[dir='rtl'].print\:rtl\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-72,[dir='rtl'].print\:rtl\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-76,[dir='rtl'].print\:rtl\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-80,[dir='rtl'].print\:rtl\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-84,[dir='rtl'].print\:rtl\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-88,[dir='rtl'].print\:rtl\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-92,[dir='rtl'].print\:rtl\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-96,[dir='rtl'].print\:rtl\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-128,[dir='rtl'].print\:rtl\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-136,[dir='rtl'].print\:rtl\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-160,[dir='rtl'].print\:rtl\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  [dir='rtl'] .print\:rtl\:-inset-192,[dir='rtl'].print\:rtl\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-200,[dir='rtl'].print\:rtl\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  [dir='rtl'] .print\:rtl\:-inset-208,[dir='rtl'].print\:rtl\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-216,[dir='rtl'].print\:rtl\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-224,[dir='rtl'].print\:rtl\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-256,[dir='rtl'].print\:rtl\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-288,[dir='rtl'].print\:rtl\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-320,[dir='rtl'].print\:rtl\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='rtl'] .print\:rtl\:-inset-360,[dir='rtl'].print\:rtl\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  [dir='rtl'] .print\:rtl\:-inset-384,[dir='rtl'].print\:rtl\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-400,[dir='rtl'].print\:rtl\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  [dir='rtl'] .print\:rtl\:-inset-512,[dir='rtl'].print\:rtl\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-640,[dir='rtl'].print\:rtl\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='rtl'] .print\:rtl\:-inset-xs,[dir='rtl'].print\:rtl\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  [dir='rtl'] .print\:rtl\:-inset-sm,[dir='rtl'].print\:rtl\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  [dir='rtl'] .print\:rtl\:-inset-md,[dir='rtl'].print\:rtl\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  [dir='rtl'] .print\:rtl\:-inset-lg,[dir='rtl'].print\:rtl\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  [dir='rtl'] .print\:rtl\:-inset-xl,[dir='rtl'].print\:rtl\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  [dir='rtl'] .print\:rtl\:-inset-2xl,[dir='rtl'].print\:rtl\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  [dir='rtl'] .print\:rtl\:-inset-3xl,[dir='rtl'].print\:rtl\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  [dir='rtl'] .print\:rtl\:-inset-4xl,[dir='rtl'].print\:rtl\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  [dir='rtl'] .print\:rtl\:-inset-5xl,[dir='rtl'].print\:rtl\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  [dir='rtl'] .print\:rtl\:-inset-px,[dir='rtl'].print\:rtl\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  [dir='rtl'] .print\:rtl\:-inset-0\.5,[dir='rtl'].print\:rtl\:-inset-0\.5 {
    top: -0.05rem;
    right: -0.05rem;
    bottom: -0.05rem;
    left: -0.05rem
  }

  [dir='rtl'] .print\:rtl\:-inset-1\.5,[dir='rtl'].print\:rtl\:-inset-1\.5 {
    top: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    left: -0.15rem
  }

  [dir='rtl'] .print\:rtl\:-inset-2\.5,[dir='rtl'].print\:rtl\:-inset-2\.5 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  [dir='rtl'] .print\:rtl\:-inset-3\.5,[dir='rtl'].print\:rtl\:-inset-3\.5 {
    top: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    left: -0.35rem
  }

  [dir='rtl'] .print\:rtl\:inset-1\/2,[dir='rtl'].print\:rtl\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='rtl'] .print\:rtl\:inset-1\/3,[dir='rtl'].print\:rtl\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  [dir='rtl'] .print\:rtl\:inset-2\/3,[dir='rtl'].print\:rtl\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  [dir='rtl'] .print\:rtl\:inset-1\/4,[dir='rtl'].print\:rtl\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  [dir='rtl'] .print\:rtl\:inset-2\/4,[dir='rtl'].print\:rtl\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  [dir='rtl'] .print\:rtl\:inset-3\/4,[dir='rtl'].print\:rtl\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  [dir='rtl'] .print\:rtl\:inset-full,[dir='rtl'].print\:rtl\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  [dir='rtl'] .print\:rtl\:-inset-1\/2,[dir='rtl'].print\:rtl\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='rtl'] .print\:rtl\:-inset-1\/3,[dir='rtl'].print\:rtl\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  [dir='rtl'] .print\:rtl\:-inset-2\/3,[dir='rtl'].print\:rtl\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  [dir='rtl'] .print\:rtl\:-inset-1\/4,[dir='rtl'].print\:rtl\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  [dir='rtl'] .print\:rtl\:-inset-2\/4,[dir='rtl'].print\:rtl\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  [dir='rtl'] .print\:rtl\:-inset-3\/4,[dir='rtl'].print\:rtl\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  [dir='rtl'] .print\:rtl\:-inset-full,[dir='rtl'].print\:rtl\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  [dir='rtl'] .print\:rtl\:inset-y-0,[dir='rtl'].print\:rtl\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  [dir='rtl'] .print\:rtl\:inset-x-0,[dir='rtl'].print\:rtl\:inset-x-0 {
    right: 0;
    left: 0
  }

  [dir='rtl'] .print\:rtl\:inset-y-1,[dir='rtl'].print\:rtl\:inset-y-1 {
    top: 0.1rem;
    bottom: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-1,[dir='rtl'].print\:rtl\:inset-x-1 {
    right: 0.1rem;
    left: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-2,[dir='rtl'].print\:rtl\:inset-y-2 {
    top: 0.2rem;
    bottom: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-2,[dir='rtl'].print\:rtl\:inset-x-2 {
    right: 0.2rem;
    left: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-3,[dir='rtl'].print\:rtl\:inset-y-3 {
    top: 0.3rem;
    bottom: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-3,[dir='rtl'].print\:rtl\:inset-x-3 {
    right: 0.3rem;
    left: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-4,[dir='rtl'].print\:rtl\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-4,[dir='rtl'].print\:rtl\:inset-x-4 {
    right: 0.4rem;
    left: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-5,[dir='rtl'].print\:rtl\:inset-y-5 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-5,[dir='rtl'].print\:rtl\:inset-x-5 {
    right: 0.5rem;
    left: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-6,[dir='rtl'].print\:rtl\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-6,[dir='rtl'].print\:rtl\:inset-x-6 {
    right: 0.6rem;
    left: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-7,[dir='rtl'].print\:rtl\:inset-y-7 {
    top: 0.7rem;
    bottom: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-7,[dir='rtl'].print\:rtl\:inset-x-7 {
    right: 0.7rem;
    left: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-8,[dir='rtl'].print\:rtl\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-8,[dir='rtl'].print\:rtl\:inset-x-8 {
    right: 0.8rem;
    left: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-9,[dir='rtl'].print\:rtl\:inset-y-9 {
    top: 0.9rem;
    bottom: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-9,[dir='rtl'].print\:rtl\:inset-x-9 {
    right: 0.9rem;
    left: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-10,[dir='rtl'].print\:rtl\:inset-y-10 {
    top: 1.0rem;
    bottom: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-10,[dir='rtl'].print\:rtl\:inset-x-10 {
    right: 1.0rem;
    left: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-12,[dir='rtl'].print\:rtl\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-12,[dir='rtl'].print\:rtl\:inset-x-12 {
    right: 1.2rem;
    left: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-14,[dir='rtl'].print\:rtl\:inset-y-14 {
    top: 1.4rem;
    bottom: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-14,[dir='rtl'].print\:rtl\:inset-x-14 {
    right: 1.4rem;
    left: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-16,[dir='rtl'].print\:rtl\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-16,[dir='rtl'].print\:rtl\:inset-x-16 {
    right: 1.6rem;
    left: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-20,[dir='rtl'].print\:rtl\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-20,[dir='rtl'].print\:rtl\:inset-x-20 {
    right: 2rem;
    left: 2rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-24,[dir='rtl'].print\:rtl\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-24,[dir='rtl'].print\:rtl\:inset-x-24 {
    right: 2.4rem;
    left: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-28,[dir='rtl'].print\:rtl\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-28,[dir='rtl'].print\:rtl\:inset-x-28 {
    right: 2.8rem;
    left: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-32,[dir='rtl'].print\:rtl\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-32,[dir='rtl'].print\:rtl\:inset-x-32 {
    right: 3.2rem;
    left: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-36,[dir='rtl'].print\:rtl\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-36,[dir='rtl'].print\:rtl\:inset-x-36 {
    right: 3.6rem;
    left: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-40,[dir='rtl'].print\:rtl\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-40,[dir='rtl'].print\:rtl\:inset-x-40 {
    right: 4rem;
    left: 4rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-44,[dir='rtl'].print\:rtl\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-44,[dir='rtl'].print\:rtl\:inset-x-44 {
    right: 4.4rem;
    left: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-48,[dir='rtl'].print\:rtl\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-48,[dir='rtl'].print\:rtl\:inset-x-48 {
    right: 4.8rem;
    left: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-52,[dir='rtl'].print\:rtl\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-52,[dir='rtl'].print\:rtl\:inset-x-52 {
    right: 5.2rem;
    left: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-56,[dir='rtl'].print\:rtl\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-56,[dir='rtl'].print\:rtl\:inset-x-56 {
    right: 5.6rem;
    left: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-60,[dir='rtl'].print\:rtl\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-60,[dir='rtl'].print\:rtl\:inset-x-60 {
    right: 6rem;
    left: 6rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-64,[dir='rtl'].print\:rtl\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-64,[dir='rtl'].print\:rtl\:inset-x-64 {
    right: 6.4rem;
    left: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-68,[dir='rtl'].print\:rtl\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-68,[dir='rtl'].print\:rtl\:inset-x-68 {
    right: 6.8rem;
    left: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-72,[dir='rtl'].print\:rtl\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-72,[dir='rtl'].print\:rtl\:inset-x-72 {
    right: 7.2rem;
    left: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-76,[dir='rtl'].print\:rtl\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-76,[dir='rtl'].print\:rtl\:inset-x-76 {
    right: 7.6rem;
    left: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-80,[dir='rtl'].print\:rtl\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-80,[dir='rtl'].print\:rtl\:inset-x-80 {
    right: 8rem;
    left: 8rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-84,[dir='rtl'].print\:rtl\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-84,[dir='rtl'].print\:rtl\:inset-x-84 {
    right: 8.4rem;
    left: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-88,[dir='rtl'].print\:rtl\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-88,[dir='rtl'].print\:rtl\:inset-x-88 {
    right: 8.8rem;
    left: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-92,[dir='rtl'].print\:rtl\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-92,[dir='rtl'].print\:rtl\:inset-x-92 {
    right: 9.2rem;
    left: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-96,[dir='rtl'].print\:rtl\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-96,[dir='rtl'].print\:rtl\:inset-x-96 {
    right: 9.6rem;
    left: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-128,[dir='rtl'].print\:rtl\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-128,[dir='rtl'].print\:rtl\:inset-x-128 {
    right: 12.8rem;
    left: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-136,[dir='rtl'].print\:rtl\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-136,[dir='rtl'].print\:rtl\:inset-x-136 {
    right: 13.6rem;
    left: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-160,[dir='rtl'].print\:rtl\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-160,[dir='rtl'].print\:rtl\:inset-x-160 {
    right: 16rem;
    left: 16rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-192,[dir='rtl'].print\:rtl\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-192,[dir='rtl'].print\:rtl\:inset-x-192 {
    right: 19.2rem;
    left: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-200,[dir='rtl'].print\:rtl\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-200,[dir='rtl'].print\:rtl\:inset-x-200 {
    right: 20rem;
    left: 20rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-208,[dir='rtl'].print\:rtl\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-208,[dir='rtl'].print\:rtl\:inset-x-208 {
    right: 20.8rem;
    left: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-216,[dir='rtl'].print\:rtl\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-216,[dir='rtl'].print\:rtl\:inset-x-216 {
    right: 21.6rem;
    left: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-224,[dir='rtl'].print\:rtl\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-224,[dir='rtl'].print\:rtl\:inset-x-224 {
    right: 22.4rem;
    left: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-256,[dir='rtl'].print\:rtl\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-256,[dir='rtl'].print\:rtl\:inset-x-256 {
    right: 25.6rem;
    left: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-288,[dir='rtl'].print\:rtl\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-288,[dir='rtl'].print\:rtl\:inset-x-288 {
    right: 28.8rem;
    left: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-320,[dir='rtl'].print\:rtl\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-320,[dir='rtl'].print\:rtl\:inset-x-320 {
    right: 32rem;
    left: 32rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-360,[dir='rtl'].print\:rtl\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-360,[dir='rtl'].print\:rtl\:inset-x-360 {
    right: 36rem;
    left: 36rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-384,[dir='rtl'].print\:rtl\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-384,[dir='rtl'].print\:rtl\:inset-x-384 {
    right: 38.4rem;
    left: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-400,[dir='rtl'].print\:rtl\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-400,[dir='rtl'].print\:rtl\:inset-x-400 {
    right: 40rem;
    left: 40rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-512,[dir='rtl'].print\:rtl\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-512,[dir='rtl'].print\:rtl\:inset-x-512 {
    right: 51.2rem;
    left: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-640,[dir='rtl'].print\:rtl\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-640,[dir='rtl'].print\:rtl\:inset-x-640 {
    right: 64rem;
    left: 64rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-auto,[dir='rtl'].print\:rtl\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  [dir='rtl'] .print\:rtl\:inset-x-auto,[dir='rtl'].print\:rtl\:inset-x-auto {
    right: auto;
    left: auto
  }

  [dir='rtl'] .print\:rtl\:inset-y-xs,[dir='rtl'].print\:rtl\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-xs,[dir='rtl'].print\:rtl\:inset-x-xs {
    right: 32rem;
    left: 32rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-sm,[dir='rtl'].print\:rtl\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-sm,[dir='rtl'].print\:rtl\:inset-x-sm {
    right: 48rem;
    left: 48rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-md,[dir='rtl'].print\:rtl\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-md,[dir='rtl'].print\:rtl\:inset-x-md {
    right: 64rem;
    left: 64rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-lg,[dir='rtl'].print\:rtl\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-lg,[dir='rtl'].print\:rtl\:inset-x-lg {
    right: 80rem;
    left: 80rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-xl,[dir='rtl'].print\:rtl\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-xl,[dir='rtl'].print\:rtl\:inset-x-xl {
    right: 96rem;
    left: 96rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-2xl,[dir='rtl'].print\:rtl\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-2xl,[dir='rtl'].print\:rtl\:inset-x-2xl {
    right: 112rem;
    left: 112rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-3xl,[dir='rtl'].print\:rtl\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-3xl,[dir='rtl'].print\:rtl\:inset-x-3xl {
    right: 128rem;
    left: 128rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-4xl,[dir='rtl'].print\:rtl\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-4xl,[dir='rtl'].print\:rtl\:inset-x-4xl {
    right: 144rem;
    left: 144rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-5xl,[dir='rtl'].print\:rtl\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-5xl,[dir='rtl'].print\:rtl\:inset-x-5xl {
    right: 160rem;
    left: 160rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-px,[dir='rtl'].print\:rtl\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  [dir='rtl'] .print\:rtl\:inset-x-px,[dir='rtl'].print\:rtl\:inset-x-px {
    right: 1px;
    left: 1px
  }

  [dir='rtl'] .print\:rtl\:inset-y-0\.5,[dir='rtl'].print\:rtl\:inset-y-0\.5 {
    top: 0.05rem;
    bottom: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-0\.5,[dir='rtl'].print\:rtl\:inset-x-0\.5 {
    right: 0.05rem;
    left: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-1\.5,[dir='rtl'].print\:rtl\:inset-y-1\.5 {
    top: 0.15rem;
    bottom: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-1\.5,[dir='rtl'].print\:rtl\:inset-x-1\.5 {
    right: 0.15rem;
    left: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-2\.5,[dir='rtl'].print\:rtl\:inset-y-2\.5 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-2\.5,[dir='rtl'].print\:rtl\:inset-x-2\.5 {
    right: 0.25rem;
    left: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-3\.5,[dir='rtl'].print\:rtl\:inset-y-3\.5 {
    top: 0.35rem;
    bottom: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:inset-x-3\.5,[dir='rtl'].print\:rtl\:inset-x-3\.5 {
    right: 0.35rem;
    left: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-1,[dir='rtl'].print\:rtl\:-inset-y-1 {
    top: -0.1rem;
    bottom: -0.1rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-1,[dir='rtl'].print\:rtl\:-inset-x-1 {
    right: -0.1rem;
    left: -0.1rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-2,[dir='rtl'].print\:rtl\:-inset-y-2 {
    top: -0.2rem;
    bottom: -0.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-2,[dir='rtl'].print\:rtl\:-inset-x-2 {
    right: -0.2rem;
    left: -0.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-3,[dir='rtl'].print\:rtl\:-inset-y-3 {
    top: -0.3rem;
    bottom: -0.3rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-3,[dir='rtl'].print\:rtl\:-inset-x-3 {
    right: -0.3rem;
    left: -0.3rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-4,[dir='rtl'].print\:rtl\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-4,[dir='rtl'].print\:rtl\:-inset-x-4 {
    right: -0.4rem;
    left: -0.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-5,[dir='rtl'].print\:rtl\:-inset-y-5 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-5,[dir='rtl'].print\:rtl\:-inset-x-5 {
    right: -0.5rem;
    left: -0.5rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-6,[dir='rtl'].print\:rtl\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-6,[dir='rtl'].print\:rtl\:-inset-x-6 {
    right: -0.6rem;
    left: -0.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-7,[dir='rtl'].print\:rtl\:-inset-y-7 {
    top: -0.7rem;
    bottom: -0.7rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-7,[dir='rtl'].print\:rtl\:-inset-x-7 {
    right: -0.7rem;
    left: -0.7rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-8,[dir='rtl'].print\:rtl\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-8,[dir='rtl'].print\:rtl\:-inset-x-8 {
    right: -0.8rem;
    left: -0.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-9,[dir='rtl'].print\:rtl\:-inset-y-9 {
    top: -0.9rem;
    bottom: -0.9rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-9,[dir='rtl'].print\:rtl\:-inset-x-9 {
    right: -0.9rem;
    left: -0.9rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-10,[dir='rtl'].print\:rtl\:-inset-y-10 {
    top: -1rem;
    bottom: -1rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-10,[dir='rtl'].print\:rtl\:-inset-x-10 {
    right: -1rem;
    left: -1rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-12,[dir='rtl'].print\:rtl\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-12,[dir='rtl'].print\:rtl\:-inset-x-12 {
    right: -1.2rem;
    left: -1.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-14,[dir='rtl'].print\:rtl\:-inset-y-14 {
    top: -1.4rem;
    bottom: -1.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-14,[dir='rtl'].print\:rtl\:-inset-x-14 {
    right: -1.4rem;
    left: -1.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-16,[dir='rtl'].print\:rtl\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-16,[dir='rtl'].print\:rtl\:-inset-x-16 {
    right: -1.6rem;
    left: -1.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-20,[dir='rtl'].print\:rtl\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-20,[dir='rtl'].print\:rtl\:-inset-x-20 {
    right: -2rem;
    left: -2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-24,[dir='rtl'].print\:rtl\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-24,[dir='rtl'].print\:rtl\:-inset-x-24 {
    right: -2.4rem;
    left: -2.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-28,[dir='rtl'].print\:rtl\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-28,[dir='rtl'].print\:rtl\:-inset-x-28 {
    right: -2.8rem;
    left: -2.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-32,[dir='rtl'].print\:rtl\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-32,[dir='rtl'].print\:rtl\:-inset-x-32 {
    right: -3.2rem;
    left: -3.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-36,[dir='rtl'].print\:rtl\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-36,[dir='rtl'].print\:rtl\:-inset-x-36 {
    right: -3.6rem;
    left: -3.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-40,[dir='rtl'].print\:rtl\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-40,[dir='rtl'].print\:rtl\:-inset-x-40 {
    right: -4rem;
    left: -4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-44,[dir='rtl'].print\:rtl\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-44,[dir='rtl'].print\:rtl\:-inset-x-44 {
    right: -4.4rem;
    left: -4.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-48,[dir='rtl'].print\:rtl\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-48,[dir='rtl'].print\:rtl\:-inset-x-48 {
    right: -4.8rem;
    left: -4.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-52,[dir='rtl'].print\:rtl\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-52,[dir='rtl'].print\:rtl\:-inset-x-52 {
    right: -5.2rem;
    left: -5.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-56,[dir='rtl'].print\:rtl\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-56,[dir='rtl'].print\:rtl\:-inset-x-56 {
    right: -5.6rem;
    left: -5.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-60,[dir='rtl'].print\:rtl\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-60,[dir='rtl'].print\:rtl\:-inset-x-60 {
    right: -6rem;
    left: -6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-64,[dir='rtl'].print\:rtl\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-64,[dir='rtl'].print\:rtl\:-inset-x-64 {
    right: -6.4rem;
    left: -6.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-68,[dir='rtl'].print\:rtl\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-68,[dir='rtl'].print\:rtl\:-inset-x-68 {
    right: -6.8rem;
    left: -6.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-72,[dir='rtl'].print\:rtl\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-72,[dir='rtl'].print\:rtl\:-inset-x-72 {
    right: -7.2rem;
    left: -7.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-76,[dir='rtl'].print\:rtl\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-76,[dir='rtl'].print\:rtl\:-inset-x-76 {
    right: -7.6rem;
    left: -7.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-80,[dir='rtl'].print\:rtl\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-80,[dir='rtl'].print\:rtl\:-inset-x-80 {
    right: -8rem;
    left: -8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-84,[dir='rtl'].print\:rtl\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-84,[dir='rtl'].print\:rtl\:-inset-x-84 {
    right: -8.4rem;
    left: -8.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-88,[dir='rtl'].print\:rtl\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-88,[dir='rtl'].print\:rtl\:-inset-x-88 {
    right: -8.8rem;
    left: -8.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-92,[dir='rtl'].print\:rtl\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-92,[dir='rtl'].print\:rtl\:-inset-x-92 {
    right: -9.2rem;
    left: -9.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-96,[dir='rtl'].print\:rtl\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-96,[dir='rtl'].print\:rtl\:-inset-x-96 {
    right: -9.6rem;
    left: -9.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-128,[dir='rtl'].print\:rtl\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-128,[dir='rtl'].print\:rtl\:-inset-x-128 {
    right: -12.8rem;
    left: -12.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-136,[dir='rtl'].print\:rtl\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-136,[dir='rtl'].print\:rtl\:-inset-x-136 {
    right: -13.6rem;
    left: -13.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-160,[dir='rtl'].print\:rtl\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-160,[dir='rtl'].print\:rtl\:-inset-x-160 {
    right: -16rem;
    left: -16rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-192,[dir='rtl'].print\:rtl\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-192,[dir='rtl'].print\:rtl\:-inset-x-192 {
    right: -19.2rem;
    left: -19.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-200,[dir='rtl'].print\:rtl\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-200,[dir='rtl'].print\:rtl\:-inset-x-200 {
    right: -20rem;
    left: -20rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-208,[dir='rtl'].print\:rtl\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-208,[dir='rtl'].print\:rtl\:-inset-x-208 {
    right: -20.8rem;
    left: -20.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-216,[dir='rtl'].print\:rtl\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-216,[dir='rtl'].print\:rtl\:-inset-x-216 {
    right: -21.6rem;
    left: -21.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-224,[dir='rtl'].print\:rtl\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-224,[dir='rtl'].print\:rtl\:-inset-x-224 {
    right: -22.4rem;
    left: -22.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-256,[dir='rtl'].print\:rtl\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-256,[dir='rtl'].print\:rtl\:-inset-x-256 {
    right: -25.6rem;
    left: -25.6rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-288,[dir='rtl'].print\:rtl\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-288,[dir='rtl'].print\:rtl\:-inset-x-288 {
    right: -28.8rem;
    left: -28.8rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-320,[dir='rtl'].print\:rtl\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-320,[dir='rtl'].print\:rtl\:-inset-x-320 {
    right: -32rem;
    left: -32rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-360,[dir='rtl'].print\:rtl\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-360,[dir='rtl'].print\:rtl\:-inset-x-360 {
    right: -36rem;
    left: -36rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-384,[dir='rtl'].print\:rtl\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-384,[dir='rtl'].print\:rtl\:-inset-x-384 {
    right: -38.4rem;
    left: -38.4rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-400,[dir='rtl'].print\:rtl\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-400,[dir='rtl'].print\:rtl\:-inset-x-400 {
    right: -40rem;
    left: -40rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-512,[dir='rtl'].print\:rtl\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-512,[dir='rtl'].print\:rtl\:-inset-x-512 {
    right: -51.2rem;
    left: -51.2rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-640,[dir='rtl'].print\:rtl\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-640,[dir='rtl'].print\:rtl\:-inset-x-640 {
    right: -64rem;
    left: -64rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-xs,[dir='rtl'].print\:rtl\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-xs,[dir='rtl'].print\:rtl\:-inset-x-xs {
    right: -32rem;
    left: -32rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-sm,[dir='rtl'].print\:rtl\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-sm,[dir='rtl'].print\:rtl\:-inset-x-sm {
    right: -48rem;
    left: -48rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-md,[dir='rtl'].print\:rtl\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-md,[dir='rtl'].print\:rtl\:-inset-x-md {
    right: -64rem;
    left: -64rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-lg,[dir='rtl'].print\:rtl\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-lg,[dir='rtl'].print\:rtl\:-inset-x-lg {
    right: -80rem;
    left: -80rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-xl,[dir='rtl'].print\:rtl\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-xl,[dir='rtl'].print\:rtl\:-inset-x-xl {
    right: -96rem;
    left: -96rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-2xl,[dir='rtl'].print\:rtl\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-2xl,[dir='rtl'].print\:rtl\:-inset-x-2xl {
    right: -112rem;
    left: -112rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-3xl,[dir='rtl'].print\:rtl\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-3xl,[dir='rtl'].print\:rtl\:-inset-x-3xl {
    right: -128rem;
    left: -128rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-4xl,[dir='rtl'].print\:rtl\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-4xl,[dir='rtl'].print\:rtl\:-inset-x-4xl {
    right: -144rem;
    left: -144rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-5xl,[dir='rtl'].print\:rtl\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-5xl,[dir='rtl'].print\:rtl\:-inset-x-5xl {
    right: -160rem;
    left: -160rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-px,[dir='rtl'].print\:rtl\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  [dir='rtl'] .print\:rtl\:-inset-x-px,[dir='rtl'].print\:rtl\:-inset-x-px {
    right: -1px;
    left: -1px
  }

  [dir='rtl'] .print\:rtl\:-inset-y-0\.5,[dir='rtl'].print\:rtl\:-inset-y-0\.5 {
    top: -0.05rem;
    bottom: -0.05rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-0\.5,[dir='rtl'].print\:rtl\:-inset-x-0\.5 {
    right: -0.05rem;
    left: -0.05rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-1\.5,[dir='rtl'].print\:rtl\:-inset-y-1\.5 {
    top: -0.15rem;
    bottom: -0.15rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-1\.5,[dir='rtl'].print\:rtl\:-inset-x-1\.5 {
    right: -0.15rem;
    left: -0.15rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-2\.5,[dir='rtl'].print\:rtl\:-inset-y-2\.5 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-2\.5,[dir='rtl'].print\:rtl\:-inset-x-2\.5 {
    right: -0.25rem;
    left: -0.25rem
  }

  [dir='rtl'] .print\:rtl\:-inset-y-3\.5,[dir='rtl'].print\:rtl\:-inset-y-3\.5 {
    top: -0.35rem;
    bottom: -0.35rem
  }

  [dir='rtl'] .print\:rtl\:-inset-x-3\.5,[dir='rtl'].print\:rtl\:-inset-x-3\.5 {
    right: -0.35rem;
    left: -0.35rem
  }

  [dir='rtl'] .print\:rtl\:inset-y-1\/2,[dir='rtl'].print\:rtl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  [dir='rtl'] .print\:rtl\:inset-x-1\/2,[dir='rtl'].print\:rtl\:inset-x-1\/2 {
    right: 50%;
    left: 50%
  }

  [dir='rtl'] .print\:rtl\:inset-y-1\/3,[dir='rtl'].print\:rtl\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  [dir='rtl'] .print\:rtl\:inset-x-1\/3,[dir='rtl'].print\:rtl\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%
  }

  [dir='rtl'] .print\:rtl\:inset-y-2\/3,[dir='rtl'].print\:rtl\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  [dir='rtl'] .print\:rtl\:inset-x-2\/3,[dir='rtl'].print\:rtl\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%
  }

  [dir='rtl'] .print\:rtl\:inset-y-1\/4,[dir='rtl'].print\:rtl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  [dir='rtl'] .print\:rtl\:inset-x-1\/4,[dir='rtl'].print\:rtl\:inset-x-1\/4 {
    right: 25%;
    left: 25%
  }

  [dir='rtl'] .print\:rtl\:inset-y-2\/4,[dir='rtl'].print\:rtl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  [dir='rtl'] .print\:rtl\:inset-x-2\/4,[dir='rtl'].print\:rtl\:inset-x-2\/4 {
    right: 50%;
    left: 50%
  }

  [dir='rtl'] .print\:rtl\:inset-y-3\/4,[dir='rtl'].print\:rtl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  [dir='rtl'] .print\:rtl\:inset-x-3\/4,[dir='rtl'].print\:rtl\:inset-x-3\/4 {
    right: 75%;
    left: 75%
  }

  [dir='rtl'] .print\:rtl\:inset-y-full,[dir='rtl'].print\:rtl\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  [dir='rtl'] .print\:rtl\:inset-x-full,[dir='rtl'].print\:rtl\:inset-x-full {
    right: 100%;
    left: 100%
  }

  [dir='rtl'] .print\:rtl\:-inset-y-1\/2,[dir='rtl'].print\:rtl\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  [dir='rtl'] .print\:rtl\:-inset-x-1\/2,[dir='rtl'].print\:rtl\:-inset-x-1\/2 {
    right: -50%;
    left: -50%
  }

  [dir='rtl'] .print\:rtl\:-inset-y-1\/3,[dir='rtl'].print\:rtl\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  [dir='rtl'] .print\:rtl\:-inset-x-1\/3,[dir='rtl'].print\:rtl\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%
  }

  [dir='rtl'] .print\:rtl\:-inset-y-2\/3,[dir='rtl'].print\:rtl\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  [dir='rtl'] .print\:rtl\:-inset-x-2\/3,[dir='rtl'].print\:rtl\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%
  }

  [dir='rtl'] .print\:rtl\:-inset-y-1\/4,[dir='rtl'].print\:rtl\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  [dir='rtl'] .print\:rtl\:-inset-x-1\/4,[dir='rtl'].print\:rtl\:-inset-x-1\/4 {
    right: -25%;
    left: -25%
  }

  [dir='rtl'] .print\:rtl\:-inset-y-2\/4,[dir='rtl'].print\:rtl\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  [dir='rtl'] .print\:rtl\:-inset-x-2\/4,[dir='rtl'].print\:rtl\:-inset-x-2\/4 {
    right: -50%;
    left: -50%
  }

  [dir='rtl'] .print\:rtl\:-inset-y-3\/4,[dir='rtl'].print\:rtl\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  [dir='rtl'] .print\:rtl\:-inset-x-3\/4,[dir='rtl'].print\:rtl\:-inset-x-3\/4 {
    right: -75%;
    left: -75%
  }

  [dir='rtl'] .print\:rtl\:-inset-y-full,[dir='rtl'].print\:rtl\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  [dir='rtl'] .print\:rtl\:-inset-x-full,[dir='rtl'].print\:rtl\:-inset-x-full {
    right: -100%;
    left: -100%
  }

  [dir='rtl'] .print\:rtl\:top-0,[dir='rtl'].print\:rtl\:top-0 {
    top: 0
  }

  [dir='rtl'] .print\:rtl\:right-0,[dir='rtl'].print\:rtl\:right-0 {
    right: 0
  }

  [dir='rtl'] .print\:rtl\:bottom-0,[dir='rtl'].print\:rtl\:bottom-0 {
    bottom: 0
  }

  [dir='rtl'] .print\:rtl\:left-0,[dir='rtl'].print\:rtl\:left-0 {
    left: 0
  }

  [dir='rtl'] .print\:rtl\:top-1,[dir='rtl'].print\:rtl\:top-1 {
    top: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:right-1,[dir='rtl'].print\:rtl\:right-1 {
    right: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:bottom-1,[dir='rtl'].print\:rtl\:bottom-1 {
    bottom: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:left-1,[dir='rtl'].print\:rtl\:left-1 {
    left: 0.1rem
  }

  [dir='rtl'] .print\:rtl\:top-2,[dir='rtl'].print\:rtl\:top-2 {
    top: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:right-2,[dir='rtl'].print\:rtl\:right-2 {
    right: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:bottom-2,[dir='rtl'].print\:rtl\:bottom-2 {
    bottom: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:left-2,[dir='rtl'].print\:rtl\:left-2 {
    left: 0.2rem
  }

  [dir='rtl'] .print\:rtl\:top-3,[dir='rtl'].print\:rtl\:top-3 {
    top: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:right-3,[dir='rtl'].print\:rtl\:right-3 {
    right: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:bottom-3,[dir='rtl'].print\:rtl\:bottom-3 {
    bottom: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:left-3,[dir='rtl'].print\:rtl\:left-3 {
    left: 0.3rem
  }

  [dir='rtl'] .print\:rtl\:top-4,[dir='rtl'].print\:rtl\:top-4 {
    top: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:right-4,[dir='rtl'].print\:rtl\:right-4 {
    right: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:bottom-4,[dir='rtl'].print\:rtl\:bottom-4 {
    bottom: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:left-4,[dir='rtl'].print\:rtl\:left-4 {
    left: 0.4rem
  }

  [dir='rtl'] .print\:rtl\:top-5,[dir='rtl'].print\:rtl\:top-5 {
    top: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:right-5,[dir='rtl'].print\:rtl\:right-5 {
    right: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:bottom-5,[dir='rtl'].print\:rtl\:bottom-5 {
    bottom: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:left-5,[dir='rtl'].print\:rtl\:left-5 {
    left: 0.5rem
  }

  [dir='rtl'] .print\:rtl\:top-6,[dir='rtl'].print\:rtl\:top-6 {
    top: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:right-6,[dir='rtl'].print\:rtl\:right-6 {
    right: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:bottom-6,[dir='rtl'].print\:rtl\:bottom-6 {
    bottom: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:left-6,[dir='rtl'].print\:rtl\:left-6 {
    left: 0.6rem
  }

  [dir='rtl'] .print\:rtl\:top-7,[dir='rtl'].print\:rtl\:top-7 {
    top: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:right-7,[dir='rtl'].print\:rtl\:right-7 {
    right: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:bottom-7,[dir='rtl'].print\:rtl\:bottom-7 {
    bottom: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:left-7,[dir='rtl'].print\:rtl\:left-7 {
    left: 0.7rem
  }

  [dir='rtl'] .print\:rtl\:top-8,[dir='rtl'].print\:rtl\:top-8 {
    top: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:right-8,[dir='rtl'].print\:rtl\:right-8 {
    right: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:bottom-8,[dir='rtl'].print\:rtl\:bottom-8 {
    bottom: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:left-8,[dir='rtl'].print\:rtl\:left-8 {
    left: 0.8rem
  }

  [dir='rtl'] .print\:rtl\:top-9,[dir='rtl'].print\:rtl\:top-9 {
    top: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:right-9,[dir='rtl'].print\:rtl\:right-9 {
    right: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:bottom-9,[dir='rtl'].print\:rtl\:bottom-9 {
    bottom: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:left-9,[dir='rtl'].print\:rtl\:left-9 {
    left: 0.9rem
  }

  [dir='rtl'] .print\:rtl\:top-10,[dir='rtl'].print\:rtl\:top-10 {
    top: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:right-10,[dir='rtl'].print\:rtl\:right-10 {
    right: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:bottom-10,[dir='rtl'].print\:rtl\:bottom-10 {
    bottom: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:left-10,[dir='rtl'].print\:rtl\:left-10 {
    left: 1.0rem
  }

  [dir='rtl'] .print\:rtl\:top-12,[dir='rtl'].print\:rtl\:top-12 {
    top: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:right-12,[dir='rtl'].print\:rtl\:right-12 {
    right: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:bottom-12,[dir='rtl'].print\:rtl\:bottom-12 {
    bottom: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:left-12,[dir='rtl'].print\:rtl\:left-12 {
    left: 1.2rem
  }

  [dir='rtl'] .print\:rtl\:top-14,[dir='rtl'].print\:rtl\:top-14 {
    top: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:right-14,[dir='rtl'].print\:rtl\:right-14 {
    right: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:bottom-14,[dir='rtl'].print\:rtl\:bottom-14 {
    bottom: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:left-14,[dir='rtl'].print\:rtl\:left-14 {
    left: 1.4rem
  }

  [dir='rtl'] .print\:rtl\:top-16,[dir='rtl'].print\:rtl\:top-16 {
    top: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:right-16,[dir='rtl'].print\:rtl\:right-16 {
    right: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:bottom-16,[dir='rtl'].print\:rtl\:bottom-16 {
    bottom: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:left-16,[dir='rtl'].print\:rtl\:left-16 {
    left: 1.6rem
  }

  [dir='rtl'] .print\:rtl\:top-20,[dir='rtl'].print\:rtl\:top-20 {
    top: 2rem
  }

  [dir='rtl'] .print\:rtl\:right-20,[dir='rtl'].print\:rtl\:right-20 {
    right: 2rem
  }

  [dir='rtl'] .print\:rtl\:bottom-20,[dir='rtl'].print\:rtl\:bottom-20 {
    bottom: 2rem
  }

  [dir='rtl'] .print\:rtl\:left-20,[dir='rtl'].print\:rtl\:left-20 {
    left: 2rem
  }

  [dir='rtl'] .print\:rtl\:top-24,[dir='rtl'].print\:rtl\:top-24 {
    top: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:right-24,[dir='rtl'].print\:rtl\:right-24 {
    right: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:bottom-24,[dir='rtl'].print\:rtl\:bottom-24 {
    bottom: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:left-24,[dir='rtl'].print\:rtl\:left-24 {
    left: 2.4rem
  }

  [dir='rtl'] .print\:rtl\:top-28,[dir='rtl'].print\:rtl\:top-28 {
    top: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:right-28,[dir='rtl'].print\:rtl\:right-28 {
    right: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:bottom-28,[dir='rtl'].print\:rtl\:bottom-28 {
    bottom: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:left-28,[dir='rtl'].print\:rtl\:left-28 {
    left: 2.8rem
  }

  [dir='rtl'] .print\:rtl\:top-32,[dir='rtl'].print\:rtl\:top-32 {
    top: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:right-32,[dir='rtl'].print\:rtl\:right-32 {
    right: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:bottom-32,[dir='rtl'].print\:rtl\:bottom-32 {
    bottom: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:left-32,[dir='rtl'].print\:rtl\:left-32 {
    left: 3.2rem
  }

  [dir='rtl'] .print\:rtl\:top-36,[dir='rtl'].print\:rtl\:top-36 {
    top: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:right-36,[dir='rtl'].print\:rtl\:right-36 {
    right: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:bottom-36,[dir='rtl'].print\:rtl\:bottom-36 {
    bottom: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:left-36,[dir='rtl'].print\:rtl\:left-36 {
    left: 3.6rem
  }

  [dir='rtl'] .print\:rtl\:top-40,[dir='rtl'].print\:rtl\:top-40 {
    top: 4rem
  }

  [dir='rtl'] .print\:rtl\:right-40,[dir='rtl'].print\:rtl\:right-40 {
    right: 4rem
  }

  [dir='rtl'] .print\:rtl\:bottom-40,[dir='rtl'].print\:rtl\:bottom-40 {
    bottom: 4rem
  }

  [dir='rtl'] .print\:rtl\:left-40,[dir='rtl'].print\:rtl\:left-40 {
    left: 4rem
  }

  [dir='rtl'] .print\:rtl\:top-44,[dir='rtl'].print\:rtl\:top-44 {
    top: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:right-44,[dir='rtl'].print\:rtl\:right-44 {
    right: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:bottom-44,[dir='rtl'].print\:rtl\:bottom-44 {
    bottom: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:left-44,[dir='rtl'].print\:rtl\:left-44 {
    left: 4.4rem
  }

  [dir='rtl'] .print\:rtl\:top-48,[dir='rtl'].print\:rtl\:top-48 {
    top: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:right-48,[dir='rtl'].print\:rtl\:right-48 {
    right: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:bottom-48,[dir='rtl'].print\:rtl\:bottom-48 {
    bottom: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:left-48,[dir='rtl'].print\:rtl\:left-48 {
    left: 4.8rem
  }

  [dir='rtl'] .print\:rtl\:top-52,[dir='rtl'].print\:rtl\:top-52 {
    top: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:right-52,[dir='rtl'].print\:rtl\:right-52 {
    right: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:bottom-52,[dir='rtl'].print\:rtl\:bottom-52 {
    bottom: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:left-52,[dir='rtl'].print\:rtl\:left-52 {
    left: 5.2rem
  }

  [dir='rtl'] .print\:rtl\:top-56,[dir='rtl'].print\:rtl\:top-56 {
    top: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:right-56,[dir='rtl'].print\:rtl\:right-56 {
    right: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:bottom-56,[dir='rtl'].print\:rtl\:bottom-56 {
    bottom: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:left-56,[dir='rtl'].print\:rtl\:left-56 {
    left: 5.6rem
  }

  [dir='rtl'] .print\:rtl\:top-60,[dir='rtl'].print\:rtl\:top-60 {
    top: 6rem
  }

  [dir='rtl'] .print\:rtl\:right-60,[dir='rtl'].print\:rtl\:right-60 {
    right: 6rem
  }

  [dir='rtl'] .print\:rtl\:bottom-60,[dir='rtl'].print\:rtl\:bottom-60 {
    bottom: 6rem
  }

  [dir='rtl'] .print\:rtl\:left-60,[dir='rtl'].print\:rtl\:left-60 {
    left: 6rem
  }

  [dir='rtl'] .print\:rtl\:top-64,[dir='rtl'].print\:rtl\:top-64 {
    top: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:right-64,[dir='rtl'].print\:rtl\:right-64 {
    right: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:bottom-64,[dir='rtl'].print\:rtl\:bottom-64 {
    bottom: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:left-64,[dir='rtl'].print\:rtl\:left-64 {
    left: 6.4rem
  }

  [dir='rtl'] .print\:rtl\:top-68,[dir='rtl'].print\:rtl\:top-68 {
    top: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:right-68,[dir='rtl'].print\:rtl\:right-68 {
    right: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:bottom-68,[dir='rtl'].print\:rtl\:bottom-68 {
    bottom: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:left-68,[dir='rtl'].print\:rtl\:left-68 {
    left: 6.8rem
  }

  [dir='rtl'] .print\:rtl\:top-72,[dir='rtl'].print\:rtl\:top-72 {
    top: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:right-72,[dir='rtl'].print\:rtl\:right-72 {
    right: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:bottom-72,[dir='rtl'].print\:rtl\:bottom-72 {
    bottom: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:left-72,[dir='rtl'].print\:rtl\:left-72 {
    left: 7.2rem
  }

  [dir='rtl'] .print\:rtl\:top-76,[dir='rtl'].print\:rtl\:top-76 {
    top: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:right-76,[dir='rtl'].print\:rtl\:right-76 {
    right: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:bottom-76,[dir='rtl'].print\:rtl\:bottom-76 {
    bottom: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:left-76,[dir='rtl'].print\:rtl\:left-76 {
    left: 7.6rem
  }

  [dir='rtl'] .print\:rtl\:top-80,[dir='rtl'].print\:rtl\:top-80 {
    top: 8rem
  }

  [dir='rtl'] .print\:rtl\:right-80,[dir='rtl'].print\:rtl\:right-80 {
    right: 8rem
  }

  [dir='rtl'] .print\:rtl\:bottom-80,[dir='rtl'].print\:rtl\:bottom-80 {
    bottom: 8rem
  }

  [dir='rtl'] .print\:rtl\:left-80,[dir='rtl'].print\:rtl\:left-80 {
    left: 8rem
  }

  [dir='rtl'] .print\:rtl\:top-84,[dir='rtl'].print\:rtl\:top-84 {
    top: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:right-84,[dir='rtl'].print\:rtl\:right-84 {
    right: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:bottom-84,[dir='rtl'].print\:rtl\:bottom-84 {
    bottom: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:left-84,[dir='rtl'].print\:rtl\:left-84 {
    left: 8.4rem
  }

  [dir='rtl'] .print\:rtl\:top-88,[dir='rtl'].print\:rtl\:top-88 {
    top: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:right-88,[dir='rtl'].print\:rtl\:right-88 {
    right: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:bottom-88,[dir='rtl'].print\:rtl\:bottom-88 {
    bottom: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:left-88,[dir='rtl'].print\:rtl\:left-88 {
    left: 8.8rem
  }

  [dir='rtl'] .print\:rtl\:top-92,[dir='rtl'].print\:rtl\:top-92 {
    top: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:right-92,[dir='rtl'].print\:rtl\:right-92 {
    right: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:bottom-92,[dir='rtl'].print\:rtl\:bottom-92 {
    bottom: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:left-92,[dir='rtl'].print\:rtl\:left-92 {
    left: 9.2rem
  }

  [dir='rtl'] .print\:rtl\:top-96,[dir='rtl'].print\:rtl\:top-96 {
    top: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:right-96,[dir='rtl'].print\:rtl\:right-96 {
    right: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:bottom-96,[dir='rtl'].print\:rtl\:bottom-96 {
    bottom: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:left-96,[dir='rtl'].print\:rtl\:left-96 {
    left: 9.6rem
  }

  [dir='rtl'] .print\:rtl\:top-128,[dir='rtl'].print\:rtl\:top-128 {
    top: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:right-128,[dir='rtl'].print\:rtl\:right-128 {
    right: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:bottom-128,[dir='rtl'].print\:rtl\:bottom-128 {
    bottom: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:left-128,[dir='rtl'].print\:rtl\:left-128 {
    left: 12.8rem
  }

  [dir='rtl'] .print\:rtl\:top-136,[dir='rtl'].print\:rtl\:top-136 {
    top: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:right-136,[dir='rtl'].print\:rtl\:right-136 {
    right: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:bottom-136,[dir='rtl'].print\:rtl\:bottom-136 {
    bottom: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:left-136,[dir='rtl'].print\:rtl\:left-136 {
    left: 13.6rem
  }

  [dir='rtl'] .print\:rtl\:top-160,[dir='rtl'].print\:rtl\:top-160 {
    top: 16rem
  }

  [dir='rtl'] .print\:rtl\:right-160,[dir='rtl'].print\:rtl\:right-160 {
    right: 16rem
  }

  [dir='rtl'] .print\:rtl\:bottom-160,[dir='rtl'].print\:rtl\:bottom-160 {
    bottom: 16rem
  }

  [dir='rtl'] .print\:rtl\:left-160,[dir='rtl'].print\:rtl\:left-160 {
    left: 16rem
  }

  [dir='rtl'] .print\:rtl\:top-192,[dir='rtl'].print\:rtl\:top-192 {
    top: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:right-192,[dir='rtl'].print\:rtl\:right-192 {
    right: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:bottom-192,[dir='rtl'].print\:rtl\:bottom-192 {
    bottom: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:left-192,[dir='rtl'].print\:rtl\:left-192 {
    left: 19.2rem
  }

  [dir='rtl'] .print\:rtl\:top-200,[dir='rtl'].print\:rtl\:top-200 {
    top: 20rem
  }

  [dir='rtl'] .print\:rtl\:right-200,[dir='rtl'].print\:rtl\:right-200 {
    right: 20rem
  }

  [dir='rtl'] .print\:rtl\:bottom-200,[dir='rtl'].print\:rtl\:bottom-200 {
    bottom: 20rem
  }

  [dir='rtl'] .print\:rtl\:left-200,[dir='rtl'].print\:rtl\:left-200 {
    left: 20rem
  }

  [dir='rtl'] .print\:rtl\:top-208,[dir='rtl'].print\:rtl\:top-208 {
    top: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:right-208,[dir='rtl'].print\:rtl\:right-208 {
    right: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:bottom-208,[dir='rtl'].print\:rtl\:bottom-208 {
    bottom: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:left-208,[dir='rtl'].print\:rtl\:left-208 {
    left: 20.8rem
  }

  [dir='rtl'] .print\:rtl\:top-216,[dir='rtl'].print\:rtl\:top-216 {
    top: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:right-216,[dir='rtl'].print\:rtl\:right-216 {
    right: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:bottom-216,[dir='rtl'].print\:rtl\:bottom-216 {
    bottom: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:left-216,[dir='rtl'].print\:rtl\:left-216 {
    left: 21.6rem
  }

  [dir='rtl'] .print\:rtl\:top-224,[dir='rtl'].print\:rtl\:top-224 {
    top: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:right-224,[dir='rtl'].print\:rtl\:right-224 {
    right: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:bottom-224,[dir='rtl'].print\:rtl\:bottom-224 {
    bottom: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:left-224,[dir='rtl'].print\:rtl\:left-224 {
    left: 22.4rem
  }

  [dir='rtl'] .print\:rtl\:top-256,[dir='rtl'].print\:rtl\:top-256 {
    top: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:right-256,[dir='rtl'].print\:rtl\:right-256 {
    right: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:bottom-256,[dir='rtl'].print\:rtl\:bottom-256 {
    bottom: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:left-256,[dir='rtl'].print\:rtl\:left-256 {
    left: 25.6rem
  }

  [dir='rtl'] .print\:rtl\:top-288,[dir='rtl'].print\:rtl\:top-288 {
    top: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:right-288,[dir='rtl'].print\:rtl\:right-288 {
    right: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:bottom-288,[dir='rtl'].print\:rtl\:bottom-288 {
    bottom: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:left-288,[dir='rtl'].print\:rtl\:left-288 {
    left: 28.8rem
  }

  [dir='rtl'] .print\:rtl\:top-320,[dir='rtl'].print\:rtl\:top-320 {
    top: 32rem
  }

  [dir='rtl'] .print\:rtl\:right-320,[dir='rtl'].print\:rtl\:right-320 {
    right: 32rem
  }

  [dir='rtl'] .print\:rtl\:bottom-320,[dir='rtl'].print\:rtl\:bottom-320 {
    bottom: 32rem
  }

  [dir='rtl'] .print\:rtl\:left-320,[dir='rtl'].print\:rtl\:left-320 {
    left: 32rem
  }

  [dir='rtl'] .print\:rtl\:top-360,[dir='rtl'].print\:rtl\:top-360 {
    top: 36rem
  }

  [dir='rtl'] .print\:rtl\:right-360,[dir='rtl'].print\:rtl\:right-360 {
    right: 36rem
  }

  [dir='rtl'] .print\:rtl\:bottom-360,[dir='rtl'].print\:rtl\:bottom-360 {
    bottom: 36rem
  }

  [dir='rtl'] .print\:rtl\:left-360,[dir='rtl'].print\:rtl\:left-360 {
    left: 36rem
  }

  [dir='rtl'] .print\:rtl\:top-384,[dir='rtl'].print\:rtl\:top-384 {
    top: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:right-384,[dir='rtl'].print\:rtl\:right-384 {
    right: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:bottom-384,[dir='rtl'].print\:rtl\:bottom-384 {
    bottom: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:left-384,[dir='rtl'].print\:rtl\:left-384 {
    left: 38.4rem
  }

  [dir='rtl'] .print\:rtl\:top-400,[dir='rtl'].print\:rtl\:top-400 {
    top: 40rem
  }

  [dir='rtl'] .print\:rtl\:right-400,[dir='rtl'].print\:rtl\:right-400 {
    right: 40rem
  }

  [dir='rtl'] .print\:rtl\:bottom-400,[dir='rtl'].print\:rtl\:bottom-400 {
    bottom: 40rem
  }

  [dir='rtl'] .print\:rtl\:left-400,[dir='rtl'].print\:rtl\:left-400 {
    left: 40rem
  }

  [dir='rtl'] .print\:rtl\:top-512,[dir='rtl'].print\:rtl\:top-512 {
    top: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:right-512,[dir='rtl'].print\:rtl\:right-512 {
    right: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:bottom-512,[dir='rtl'].print\:rtl\:bottom-512 {
    bottom: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:left-512,[dir='rtl'].print\:rtl\:left-512 {
    left: 51.2rem
  }

  [dir='rtl'] .print\:rtl\:top-640,[dir='rtl'].print\:rtl\:top-640 {
    top: 64rem
  }

  [dir='rtl'] .print\:rtl\:right-640,[dir='rtl'].print\:rtl\:right-640 {
    right: 64rem
  }

  [dir='rtl'] .print\:rtl\:bottom-640,[dir='rtl'].print\:rtl\:bottom-640 {
    bottom: 64rem
  }

  [dir='rtl'] .print\:rtl\:left-640,[dir='rtl'].print\:rtl\:left-640 {
    left: 64rem
  }

  [dir='rtl'] .print\:rtl\:top-auto,[dir='rtl'].print\:rtl\:top-auto {
    top: auto
  }

  [dir='rtl'] .print\:rtl\:right-auto,[dir='rtl'].print\:rtl\:right-auto {
    right: auto
  }

  [dir='rtl'] .print\:rtl\:bottom-auto,[dir='rtl'].print\:rtl\:bottom-auto {
    bottom: auto
  }

  [dir='rtl'] .print\:rtl\:left-auto,[dir='rtl'].print\:rtl\:left-auto {
    left: auto
  }

  [dir='rtl'] .print\:rtl\:top-xs,[dir='rtl'].print\:rtl\:top-xs {
    top: 32rem
  }

  [dir='rtl'] .print\:rtl\:right-xs,[dir='rtl'].print\:rtl\:right-xs {
    right: 32rem
  }

  [dir='rtl'] .print\:rtl\:bottom-xs,[dir='rtl'].print\:rtl\:bottom-xs {
    bottom: 32rem
  }

  [dir='rtl'] .print\:rtl\:left-xs,[dir='rtl'].print\:rtl\:left-xs {
    left: 32rem
  }

  [dir='rtl'] .print\:rtl\:top-sm,[dir='rtl'].print\:rtl\:top-sm {
    top: 48rem
  }

  [dir='rtl'] .print\:rtl\:right-sm,[dir='rtl'].print\:rtl\:right-sm {
    right: 48rem
  }

  [dir='rtl'] .print\:rtl\:bottom-sm,[dir='rtl'].print\:rtl\:bottom-sm {
    bottom: 48rem
  }

  [dir='rtl'] .print\:rtl\:left-sm,[dir='rtl'].print\:rtl\:left-sm {
    left: 48rem
  }

  [dir='rtl'] .print\:rtl\:top-md,[dir='rtl'].print\:rtl\:top-md {
    top: 64rem
  }

  [dir='rtl'] .print\:rtl\:right-md,[dir='rtl'].print\:rtl\:right-md {
    right: 64rem
  }

  [dir='rtl'] .print\:rtl\:bottom-md,[dir='rtl'].print\:rtl\:bottom-md {
    bottom: 64rem
  }

  [dir='rtl'] .print\:rtl\:left-md,[dir='rtl'].print\:rtl\:left-md {
    left: 64rem
  }

  [dir='rtl'] .print\:rtl\:top-lg,[dir='rtl'].print\:rtl\:top-lg {
    top: 80rem
  }

  [dir='rtl'] .print\:rtl\:right-lg,[dir='rtl'].print\:rtl\:right-lg {
    right: 80rem
  }

  [dir='rtl'] .print\:rtl\:bottom-lg,[dir='rtl'].print\:rtl\:bottom-lg {
    bottom: 80rem
  }

  [dir='rtl'] .print\:rtl\:left-lg,[dir='rtl'].print\:rtl\:left-lg {
    left: 80rem
  }

  [dir='rtl'] .print\:rtl\:top-xl,[dir='rtl'].print\:rtl\:top-xl {
    top: 96rem
  }

  [dir='rtl'] .print\:rtl\:right-xl,[dir='rtl'].print\:rtl\:right-xl {
    right: 96rem
  }

  [dir='rtl'] .print\:rtl\:bottom-xl,[dir='rtl'].print\:rtl\:bottom-xl {
    bottom: 96rem
  }

  [dir='rtl'] .print\:rtl\:left-xl,[dir='rtl'].print\:rtl\:left-xl {
    left: 96rem
  }

  [dir='rtl'] .print\:rtl\:top-2xl,[dir='rtl'].print\:rtl\:top-2xl {
    top: 112rem
  }

  [dir='rtl'] .print\:rtl\:right-2xl,[dir='rtl'].print\:rtl\:right-2xl {
    right: 112rem
  }

  [dir='rtl'] .print\:rtl\:bottom-2xl,[dir='rtl'].print\:rtl\:bottom-2xl {
    bottom: 112rem
  }

  [dir='rtl'] .print\:rtl\:left-2xl,[dir='rtl'].print\:rtl\:left-2xl {
    left: 112rem
  }

  [dir='rtl'] .print\:rtl\:top-3xl,[dir='rtl'].print\:rtl\:top-3xl {
    top: 128rem
  }

  [dir='rtl'] .print\:rtl\:right-3xl,[dir='rtl'].print\:rtl\:right-3xl {
    right: 128rem
  }

  [dir='rtl'] .print\:rtl\:bottom-3xl,[dir='rtl'].print\:rtl\:bottom-3xl {
    bottom: 128rem
  }

  [dir='rtl'] .print\:rtl\:left-3xl,[dir='rtl'].print\:rtl\:left-3xl {
    left: 128rem
  }

  [dir='rtl'] .print\:rtl\:top-4xl,[dir='rtl'].print\:rtl\:top-4xl {
    top: 144rem
  }

  [dir='rtl'] .print\:rtl\:right-4xl,[dir='rtl'].print\:rtl\:right-4xl {
    right: 144rem
  }

  [dir='rtl'] .print\:rtl\:bottom-4xl,[dir='rtl'].print\:rtl\:bottom-4xl {
    bottom: 144rem
  }

  [dir='rtl'] .print\:rtl\:left-4xl,[dir='rtl'].print\:rtl\:left-4xl {
    left: 144rem
  }

  [dir='rtl'] .print\:rtl\:top-5xl,[dir='rtl'].print\:rtl\:top-5xl {
    top: 160rem
  }

  [dir='rtl'] .print\:rtl\:right-5xl,[dir='rtl'].print\:rtl\:right-5xl {
    right: 160rem
  }

  [dir='rtl'] .print\:rtl\:bottom-5xl,[dir='rtl'].print\:rtl\:bottom-5xl {
    bottom: 160rem
  }

  [dir='rtl'] .print\:rtl\:left-5xl,[dir='rtl'].print\:rtl\:left-5xl {
    left: 160rem
  }

  [dir='rtl'] .print\:rtl\:top-px,[dir='rtl'].print\:rtl\:top-px {
    top: 1px
  }

  [dir='rtl'] .print\:rtl\:right-px,[dir='rtl'].print\:rtl\:right-px {
    right: 1px
  }

  [dir='rtl'] .print\:rtl\:bottom-px,[dir='rtl'].print\:rtl\:bottom-px {
    bottom: 1px
  }

  [dir='rtl'] .print\:rtl\:left-px,[dir='rtl'].print\:rtl\:left-px {
    left: 1px
  }

  [dir='rtl'] .print\:rtl\:top-0\.5,[dir='rtl'].print\:rtl\:top-0\.5 {
    top: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:right-0\.5,[dir='rtl'].print\:rtl\:right-0\.5 {
    right: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:bottom-0\.5,[dir='rtl'].print\:rtl\:bottom-0\.5 {
    bottom: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:left-0\.5,[dir='rtl'].print\:rtl\:left-0\.5 {
    left: 0.05rem
  }

  [dir='rtl'] .print\:rtl\:top-1\.5,[dir='rtl'].print\:rtl\:top-1\.5 {
    top: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:right-1\.5,[dir='rtl'].print\:rtl\:right-1\.5 {
    right: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:bottom-1\.5,[dir='rtl'].print\:rtl\:bottom-1\.5 {
    bottom: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:left-1\.5,[dir='rtl'].print\:rtl\:left-1\.5 {
    left: 0.15rem
  }

  [dir='rtl'] .print\:rtl\:top-2\.5,[dir='rtl'].print\:rtl\:top-2\.5 {
    top: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:right-2\.5,[dir='rtl'].print\:rtl\:right-2\.5 {
    right: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:bottom-2\.5,[dir='rtl'].print\:rtl\:bottom-2\.5 {
    bottom: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:left-2\.5,[dir='rtl'].print\:rtl\:left-2\.5 {
    left: 0.25rem
  }

  [dir='rtl'] .print\:rtl\:top-3\.5,[dir='rtl'].print\:rtl\:top-3\.5 {
    top: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:right-3\.5,[dir='rtl'].print\:rtl\:right-3\.5 {
    right: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:bottom-3\.5,[dir='rtl'].print\:rtl\:bottom-3\.5 {
    bottom: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:left-3\.5,[dir='rtl'].print\:rtl\:left-3\.5 {
    left: 0.35rem
  }

  [dir='rtl'] .print\:rtl\:-top-1,[dir='rtl'].print\:rtl\:-top-1 {
    top: -0.1rem
  }

  [dir='rtl'] .print\:rtl\:-right-1,[dir='rtl'].print\:rtl\:-right-1 {
    right: -0.1rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-1,[dir='rtl'].print\:rtl\:-bottom-1 {
    bottom: -0.1rem
  }

  [dir='rtl'] .print\:rtl\:-left-1,[dir='rtl'].print\:rtl\:-left-1 {
    left: -0.1rem
  }

  [dir='rtl'] .print\:rtl\:-top-2,[dir='rtl'].print\:rtl\:-top-2 {
    top: -0.2rem
  }

  [dir='rtl'] .print\:rtl\:-right-2,[dir='rtl'].print\:rtl\:-right-2 {
    right: -0.2rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-2,[dir='rtl'].print\:rtl\:-bottom-2 {
    bottom: -0.2rem
  }

  [dir='rtl'] .print\:rtl\:-left-2,[dir='rtl'].print\:rtl\:-left-2 {
    left: -0.2rem
  }

  [dir='rtl'] .print\:rtl\:-top-3,[dir='rtl'].print\:rtl\:-top-3 {
    top: -0.3rem
  }

  [dir='rtl'] .print\:rtl\:-right-3,[dir='rtl'].print\:rtl\:-right-3 {
    right: -0.3rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-3,[dir='rtl'].print\:rtl\:-bottom-3 {
    bottom: -0.3rem
  }

  [dir='rtl'] .print\:rtl\:-left-3,[dir='rtl'].print\:rtl\:-left-3 {
    left: -0.3rem
  }

  [dir='rtl'] .print\:rtl\:-top-4,[dir='rtl'].print\:rtl\:-top-4 {
    top: -0.4rem
  }

  [dir='rtl'] .print\:rtl\:-right-4,[dir='rtl'].print\:rtl\:-right-4 {
    right: -0.4rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-4,[dir='rtl'].print\:rtl\:-bottom-4 {
    bottom: -0.4rem
  }

  [dir='rtl'] .print\:rtl\:-left-4,[dir='rtl'].print\:rtl\:-left-4 {
    left: -0.4rem
  }

  [dir='rtl'] .print\:rtl\:-top-5,[dir='rtl'].print\:rtl\:-top-5 {
    top: -0.5rem
  }

  [dir='rtl'] .print\:rtl\:-right-5,[dir='rtl'].print\:rtl\:-right-5 {
    right: -0.5rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-5,[dir='rtl'].print\:rtl\:-bottom-5 {
    bottom: -0.5rem
  }

  [dir='rtl'] .print\:rtl\:-left-5,[dir='rtl'].print\:rtl\:-left-5 {
    left: -0.5rem
  }

  [dir='rtl'] .print\:rtl\:-top-6,[dir='rtl'].print\:rtl\:-top-6 {
    top: -0.6rem
  }

  [dir='rtl'] .print\:rtl\:-right-6,[dir='rtl'].print\:rtl\:-right-6 {
    right: -0.6rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-6,[dir='rtl'].print\:rtl\:-bottom-6 {
    bottom: -0.6rem
  }

  [dir='rtl'] .print\:rtl\:-left-6,[dir='rtl'].print\:rtl\:-left-6 {
    left: -0.6rem
  }

  [dir='rtl'] .print\:rtl\:-top-7,[dir='rtl'].print\:rtl\:-top-7 {
    top: -0.7rem
  }

  [dir='rtl'] .print\:rtl\:-right-7,[dir='rtl'].print\:rtl\:-right-7 {
    right: -0.7rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-7,[dir='rtl'].print\:rtl\:-bottom-7 {
    bottom: -0.7rem
  }

  [dir='rtl'] .print\:rtl\:-left-7,[dir='rtl'].print\:rtl\:-left-7 {
    left: -0.7rem
  }

  [dir='rtl'] .print\:rtl\:-top-8,[dir='rtl'].print\:rtl\:-top-8 {
    top: -0.8rem
  }

  [dir='rtl'] .print\:rtl\:-right-8,[dir='rtl'].print\:rtl\:-right-8 {
    right: -0.8rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-8,[dir='rtl'].print\:rtl\:-bottom-8 {
    bottom: -0.8rem
  }

  [dir='rtl'] .print\:rtl\:-left-8,[dir='rtl'].print\:rtl\:-left-8 {
    left: -0.8rem
  }

  [dir='rtl'] .print\:rtl\:-top-9,[dir='rtl'].print\:rtl\:-top-9 {
    top: -0.9rem
  }

  [dir='rtl'] .print\:rtl\:-right-9,[dir='rtl'].print\:rtl\:-right-9 {
    right: -0.9rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-9,[dir='rtl'].print\:rtl\:-bottom-9 {
    bottom: -0.9rem
  }

  [dir='rtl'] .print\:rtl\:-left-9,[dir='rtl'].print\:rtl\:-left-9 {
    left: -0.9rem
  }

  [dir='rtl'] .print\:rtl\:-top-10,[dir='rtl'].print\:rtl\:-top-10 {
    top: -1rem
  }

  [dir='rtl'] .print\:rtl\:-right-10,[dir='rtl'].print\:rtl\:-right-10 {
    right: -1rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-10,[dir='rtl'].print\:rtl\:-bottom-10 {
    bottom: -1rem
  }

  [dir='rtl'] .print\:rtl\:-left-10,[dir='rtl'].print\:rtl\:-left-10 {
    left: -1rem
  }

  [dir='rtl'] .print\:rtl\:-top-12,[dir='rtl'].print\:rtl\:-top-12 {
    top: -1.2rem
  }

  [dir='rtl'] .print\:rtl\:-right-12,[dir='rtl'].print\:rtl\:-right-12 {
    right: -1.2rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-12,[dir='rtl'].print\:rtl\:-bottom-12 {
    bottom: -1.2rem
  }

  [dir='rtl'] .print\:rtl\:-left-12,[dir='rtl'].print\:rtl\:-left-12 {
    left: -1.2rem
  }

  [dir='rtl'] .print\:rtl\:-top-14,[dir='rtl'].print\:rtl\:-top-14 {
    top: -1.4rem
  }

  [dir='rtl'] .print\:rtl\:-right-14,[dir='rtl'].print\:rtl\:-right-14 {
    right: -1.4rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-14,[dir='rtl'].print\:rtl\:-bottom-14 {
    bottom: -1.4rem
  }

  [dir='rtl'] .print\:rtl\:-left-14,[dir='rtl'].print\:rtl\:-left-14 {
    left: -1.4rem
  }

  [dir='rtl'] .print\:rtl\:-top-16,[dir='rtl'].print\:rtl\:-top-16 {
    top: -1.6rem
  }

  [dir='rtl'] .print\:rtl\:-right-16,[dir='rtl'].print\:rtl\:-right-16 {
    right: -1.6rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-16,[dir='rtl'].print\:rtl\:-bottom-16 {
    bottom: -1.6rem
  }

  [dir='rtl'] .print\:rtl\:-left-16,[dir='rtl'].print\:rtl\:-left-16 {
    left: -1.6rem
  }

  [dir='rtl'] .print\:rtl\:-top-20,[dir='rtl'].print\:rtl\:-top-20 {
    top: -2rem
  }

  [dir='rtl'] .print\:rtl\:-right-20,[dir='rtl'].print\:rtl\:-right-20 {
    right: -2rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-20,[dir='rtl'].print\:rtl\:-bottom-20 {
    bottom: -2rem
  }

  [dir='rtl'] .print\:rtl\:-left-20,[dir='rtl'].print\:rtl\:-left-20 {
    left: -2rem
  }

  [dir='rtl'] .print\:rtl\:-top-24,[dir='rtl'].print\:rtl\:-top-24 {
    top: -2.4rem
  }

  [dir='rtl'] .print\:rtl\:-right-24,[dir='rtl'].print\:rtl\:-right-24 {
    right: -2.4rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-24,[dir='rtl'].print\:rtl\:-bottom-24 {
    bottom: -2.4rem
  }

  [dir='rtl'] .print\:rtl\:-left-24,[dir='rtl'].print\:rtl\:-left-24 {
    left: -2.4rem
  }

  [dir='rtl'] .print\:rtl\:-top-28,[dir='rtl'].print\:rtl\:-top-28 {
    top: -2.8rem
  }

  [dir='rtl'] .print\:rtl\:-right-28,[dir='rtl'].print\:rtl\:-right-28 {
    right: -2.8rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-28,[dir='rtl'].print\:rtl\:-bottom-28 {
    bottom: -2.8rem
  }

  [dir='rtl'] .print\:rtl\:-left-28,[dir='rtl'].print\:rtl\:-left-28 {
    left: -2.8rem
  }

  [dir='rtl'] .print\:rtl\:-top-32,[dir='rtl'].print\:rtl\:-top-32 {
    top: -3.2rem
  }

  [dir='rtl'] .print\:rtl\:-right-32,[dir='rtl'].print\:rtl\:-right-32 {
    right: -3.2rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-32,[dir='rtl'].print\:rtl\:-bottom-32 {
    bottom: -3.2rem
  }

  [dir='rtl'] .print\:rtl\:-left-32,[dir='rtl'].print\:rtl\:-left-32 {
    left: -3.2rem
  }

  [dir='rtl'] .print\:rtl\:-top-36,[dir='rtl'].print\:rtl\:-top-36 {
    top: -3.6rem
  }

  [dir='rtl'] .print\:rtl\:-right-36,[dir='rtl'].print\:rtl\:-right-36 {
    right: -3.6rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-36,[dir='rtl'].print\:rtl\:-bottom-36 {
    bottom: -3.6rem
  }

  [dir='rtl'] .print\:rtl\:-left-36,[dir='rtl'].print\:rtl\:-left-36 {
    left: -3.6rem
  }

  [dir='rtl'] .print\:rtl\:-top-40,[dir='rtl'].print\:rtl\:-top-40 {
    top: -4rem
  }

  [dir='rtl'] .print\:rtl\:-right-40,[dir='rtl'].print\:rtl\:-right-40 {
    right: -4rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-40,[dir='rtl'].print\:rtl\:-bottom-40 {
    bottom: -4rem
  }

  [dir='rtl'] .print\:rtl\:-left-40,[dir='rtl'].print\:rtl\:-left-40 {
    left: -4rem
  }

  [dir='rtl'] .print\:rtl\:-top-44,[dir='rtl'].print\:rtl\:-top-44 {
    top: -4.4rem
  }

  [dir='rtl'] .print\:rtl\:-right-44,[dir='rtl'].print\:rtl\:-right-44 {
    right: -4.4rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-44,[dir='rtl'].print\:rtl\:-bottom-44 {
    bottom: -4.4rem
  }

  [dir='rtl'] .print\:rtl\:-left-44,[dir='rtl'].print\:rtl\:-left-44 {
    left: -4.4rem
  }

  [dir='rtl'] .print\:rtl\:-top-48,[dir='rtl'].print\:rtl\:-top-48 {
    top: -4.8rem
  }

  [dir='rtl'] .print\:rtl\:-right-48,[dir='rtl'].print\:rtl\:-right-48 {
    right: -4.8rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-48,[dir='rtl'].print\:rtl\:-bottom-48 {
    bottom: -4.8rem
  }

  [dir='rtl'] .print\:rtl\:-left-48,[dir='rtl'].print\:rtl\:-left-48 {
    left: -4.8rem
  }

  [dir='rtl'] .print\:rtl\:-top-52,[dir='rtl'].print\:rtl\:-top-52 {
    top: -5.2rem
  }

  [dir='rtl'] .print\:rtl\:-right-52,[dir='rtl'].print\:rtl\:-right-52 {
    right: -5.2rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-52,[dir='rtl'].print\:rtl\:-bottom-52 {
    bottom: -5.2rem
  }

  [dir='rtl'] .print\:rtl\:-left-52,[dir='rtl'].print\:rtl\:-left-52 {
    left: -5.2rem
  }

  [dir='rtl'] .print\:rtl\:-top-56,[dir='rtl'].print\:rtl\:-top-56 {
    top: -5.6rem
  }

  [dir='rtl'] .print\:rtl\:-right-56,[dir='rtl'].print\:rtl\:-right-56 {
    right: -5.6rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-56,[dir='rtl'].print\:rtl\:-bottom-56 {
    bottom: -5.6rem
  }

  [dir='rtl'] .print\:rtl\:-left-56,[dir='rtl'].print\:rtl\:-left-56 {
    left: -5.6rem
  }

  [dir='rtl'] .print\:rtl\:-top-60,[dir='rtl'].print\:rtl\:-top-60 {
    top: -6rem
  }

  [dir='rtl'] .print\:rtl\:-right-60,[dir='rtl'].print\:rtl\:-right-60 {
    right: -6rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-60,[dir='rtl'].print\:rtl\:-bottom-60 {
    bottom: -6rem
  }

  [dir='rtl'] .print\:rtl\:-left-60,[dir='rtl'].print\:rtl\:-left-60 {
    left: -6rem
  }

  [dir='rtl'] .print\:rtl\:-top-64,[dir='rtl'].print\:rtl\:-top-64 {
    top: -6.4rem
  }

  [dir='rtl'] .print\:rtl\:-right-64,[dir='rtl'].print\:rtl\:-right-64 {
    right: -6.4rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-64,[dir='rtl'].print\:rtl\:-bottom-64 {
    bottom: -6.4rem
  }

  [dir='rtl'] .print\:rtl\:-left-64,[dir='rtl'].print\:rtl\:-left-64 {
    left: -6.4rem
  }

  [dir='rtl'] .print\:rtl\:-top-68,[dir='rtl'].print\:rtl\:-top-68 {
    top: -6.8rem
  }

  [dir='rtl'] .print\:rtl\:-right-68,[dir='rtl'].print\:rtl\:-right-68 {
    right: -6.8rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-68,[dir='rtl'].print\:rtl\:-bottom-68 {
    bottom: -6.8rem
  }

  [dir='rtl'] .print\:rtl\:-left-68,[dir='rtl'].print\:rtl\:-left-68 {
    left: -6.8rem
  }

  [dir='rtl'] .print\:rtl\:-top-72,[dir='rtl'].print\:rtl\:-top-72 {
    top: -7.2rem
  }

  [dir='rtl'] .print\:rtl\:-right-72,[dir='rtl'].print\:rtl\:-right-72 {
    right: -7.2rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-72,[dir='rtl'].print\:rtl\:-bottom-72 {
    bottom: -7.2rem
  }

  [dir='rtl'] .print\:rtl\:-left-72,[dir='rtl'].print\:rtl\:-left-72 {
    left: -7.2rem
  }

  [dir='rtl'] .print\:rtl\:-top-76,[dir='rtl'].print\:rtl\:-top-76 {
    top: -7.6rem
  }

  [dir='rtl'] .print\:rtl\:-right-76,[dir='rtl'].print\:rtl\:-right-76 {
    right: -7.6rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-76,[dir='rtl'].print\:rtl\:-bottom-76 {
    bottom: -7.6rem
  }

  [dir='rtl'] .print\:rtl\:-left-76,[dir='rtl'].print\:rtl\:-left-76 {
    left: -7.6rem
  }

  [dir='rtl'] .print\:rtl\:-top-80,[dir='rtl'].print\:rtl\:-top-80 {
    top: -8rem
  }

  [dir='rtl'] .print\:rtl\:-right-80,[dir='rtl'].print\:rtl\:-right-80 {
    right: -8rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-80,[dir='rtl'].print\:rtl\:-bottom-80 {
    bottom: -8rem
  }

  [dir='rtl'] .print\:rtl\:-left-80,[dir='rtl'].print\:rtl\:-left-80 {
    left: -8rem
  }

  [dir='rtl'] .print\:rtl\:-top-84,[dir='rtl'].print\:rtl\:-top-84 {
    top: -8.4rem
  }

  [dir='rtl'] .print\:rtl\:-right-84,[dir='rtl'].print\:rtl\:-right-84 {
    right: -8.4rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-84,[dir='rtl'].print\:rtl\:-bottom-84 {
    bottom: -8.4rem
  }

  [dir='rtl'] .print\:rtl\:-left-84,[dir='rtl'].print\:rtl\:-left-84 {
    left: -8.4rem
  }

  [dir='rtl'] .print\:rtl\:-top-88,[dir='rtl'].print\:rtl\:-top-88 {
    top: -8.8rem
  }

  [dir='rtl'] .print\:rtl\:-right-88,[dir='rtl'].print\:rtl\:-right-88 {
    right: -8.8rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-88,[dir='rtl'].print\:rtl\:-bottom-88 {
    bottom: -8.8rem
  }

  [dir='rtl'] .print\:rtl\:-left-88,[dir='rtl'].print\:rtl\:-left-88 {
    left: -8.8rem
  }

  [dir='rtl'] .print\:rtl\:-top-92,[dir='rtl'].print\:rtl\:-top-92 {
    top: -9.2rem
  }

  [dir='rtl'] .print\:rtl\:-right-92,[dir='rtl'].print\:rtl\:-right-92 {
    right: -9.2rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-92,[dir='rtl'].print\:rtl\:-bottom-92 {
    bottom: -9.2rem
  }

  [dir='rtl'] .print\:rtl\:-left-92,[dir='rtl'].print\:rtl\:-left-92 {
    left: -9.2rem
  }

  [dir='rtl'] .print\:rtl\:-top-96,[dir='rtl'].print\:rtl\:-top-96 {
    top: -9.6rem
  }

  [dir='rtl'] .print\:rtl\:-right-96,[dir='rtl'].print\:rtl\:-right-96 {
    right: -9.6rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-96,[dir='rtl'].print\:rtl\:-bottom-96 {
    bottom: -9.6rem
  }

  [dir='rtl'] .print\:rtl\:-left-96,[dir='rtl'].print\:rtl\:-left-96 {
    left: -9.6rem
  }

  [dir='rtl'] .print\:rtl\:-top-128,[dir='rtl'].print\:rtl\:-top-128 {
    top: -12.8rem
  }

  [dir='rtl'] .print\:rtl\:-right-128,[dir='rtl'].print\:rtl\:-right-128 {
    right: -12.8rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-128,[dir='rtl'].print\:rtl\:-bottom-128 {
    bottom: -12.8rem
  }

  [dir='rtl'] .print\:rtl\:-left-128,[dir='rtl'].print\:rtl\:-left-128 {
    left: -12.8rem
  }

  [dir='rtl'] .print\:rtl\:-top-136,[dir='rtl'].print\:rtl\:-top-136 {
    top: -13.6rem
  }

  [dir='rtl'] .print\:rtl\:-right-136,[dir='rtl'].print\:rtl\:-right-136 {
    right: -13.6rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-136,[dir='rtl'].print\:rtl\:-bottom-136 {
    bottom: -13.6rem
  }

  [dir='rtl'] .print\:rtl\:-left-136,[dir='rtl'].print\:rtl\:-left-136 {
    left: -13.6rem
  }

  [dir='rtl'] .print\:rtl\:-top-160,[dir='rtl'].print\:rtl\:-top-160 {
    top: -16rem
  }

  [dir='rtl'] .print\:rtl\:-right-160,[dir='rtl'].print\:rtl\:-right-160 {
    right: -16rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-160,[dir='rtl'].print\:rtl\:-bottom-160 {
    bottom: -16rem
  }

  [dir='rtl'] .print\:rtl\:-left-160,[dir='rtl'].print\:rtl\:-left-160 {
    left: -16rem
  }

  [dir='rtl'] .print\:rtl\:-top-192,[dir='rtl'].print\:rtl\:-top-192 {
    top: -19.2rem
  }

  [dir='rtl'] .print\:rtl\:-right-192,[dir='rtl'].print\:rtl\:-right-192 {
    right: -19.2rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-192,[dir='rtl'].print\:rtl\:-bottom-192 {
    bottom: -19.2rem
  }

  [dir='rtl'] .print\:rtl\:-left-192,[dir='rtl'].print\:rtl\:-left-192 {
    left: -19.2rem
  }

  [dir='rtl'] .print\:rtl\:-top-200,[dir='rtl'].print\:rtl\:-top-200 {
    top: -20rem
  }

  [dir='rtl'] .print\:rtl\:-right-200,[dir='rtl'].print\:rtl\:-right-200 {
    right: -20rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-200,[dir='rtl'].print\:rtl\:-bottom-200 {
    bottom: -20rem
  }

  [dir='rtl'] .print\:rtl\:-left-200,[dir='rtl'].print\:rtl\:-left-200 {
    left: -20rem
  }

  [dir='rtl'] .print\:rtl\:-top-208,[dir='rtl'].print\:rtl\:-top-208 {
    top: -20.8rem
  }

  [dir='rtl'] .print\:rtl\:-right-208,[dir='rtl'].print\:rtl\:-right-208 {
    right: -20.8rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-208,[dir='rtl'].print\:rtl\:-bottom-208 {
    bottom: -20.8rem
  }

  [dir='rtl'] .print\:rtl\:-left-208,[dir='rtl'].print\:rtl\:-left-208 {
    left: -20.8rem
  }

  [dir='rtl'] .print\:rtl\:-top-216,[dir='rtl'].print\:rtl\:-top-216 {
    top: -21.6rem
  }

  [dir='rtl'] .print\:rtl\:-right-216,[dir='rtl'].print\:rtl\:-right-216 {
    right: -21.6rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-216,[dir='rtl'].print\:rtl\:-bottom-216 {
    bottom: -21.6rem
  }

  [dir='rtl'] .print\:rtl\:-left-216,[dir='rtl'].print\:rtl\:-left-216 {
    left: -21.6rem
  }

  [dir='rtl'] .print\:rtl\:-top-224,[dir='rtl'].print\:rtl\:-top-224 {
    top: -22.4rem
  }

  [dir='rtl'] .print\:rtl\:-right-224,[dir='rtl'].print\:rtl\:-right-224 {
    right: -22.4rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-224,[dir='rtl'].print\:rtl\:-bottom-224 {
    bottom: -22.4rem
  }

  [dir='rtl'] .print\:rtl\:-left-224,[dir='rtl'].print\:rtl\:-left-224 {
    left: -22.4rem
  }

  [dir='rtl'] .print\:rtl\:-top-256,[dir='rtl'].print\:rtl\:-top-256 {
    top: -25.6rem
  }

  [dir='rtl'] .print\:rtl\:-right-256,[dir='rtl'].print\:rtl\:-right-256 {
    right: -25.6rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-256,[dir='rtl'].print\:rtl\:-bottom-256 {
    bottom: -25.6rem
  }

  [dir='rtl'] .print\:rtl\:-left-256,[dir='rtl'].print\:rtl\:-left-256 {
    left: -25.6rem
  }

  [dir='rtl'] .print\:rtl\:-top-288,[dir='rtl'].print\:rtl\:-top-288 {
    top: -28.8rem
  }

  [dir='rtl'] .print\:rtl\:-right-288,[dir='rtl'].print\:rtl\:-right-288 {
    right: -28.8rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-288,[dir='rtl'].print\:rtl\:-bottom-288 {
    bottom: -28.8rem
  }

  [dir='rtl'] .print\:rtl\:-left-288,[dir='rtl'].print\:rtl\:-left-288 {
    left: -28.8rem
  }

  [dir='rtl'] .print\:rtl\:-top-320,[dir='rtl'].print\:rtl\:-top-320 {
    top: -32rem
  }

  [dir='rtl'] .print\:rtl\:-right-320,[dir='rtl'].print\:rtl\:-right-320 {
    right: -32rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-320,[dir='rtl'].print\:rtl\:-bottom-320 {
    bottom: -32rem
  }

  [dir='rtl'] .print\:rtl\:-left-320,[dir='rtl'].print\:rtl\:-left-320 {
    left: -32rem
  }

  [dir='rtl'] .print\:rtl\:-top-360,[dir='rtl'].print\:rtl\:-top-360 {
    top: -36rem
  }

  [dir='rtl'] .print\:rtl\:-right-360,[dir='rtl'].print\:rtl\:-right-360 {
    right: -36rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-360,[dir='rtl'].print\:rtl\:-bottom-360 {
    bottom: -36rem
  }

  [dir='rtl'] .print\:rtl\:-left-360,[dir='rtl'].print\:rtl\:-left-360 {
    left: -36rem
  }

  [dir='rtl'] .print\:rtl\:-top-384,[dir='rtl'].print\:rtl\:-top-384 {
    top: -38.4rem
  }

  [dir='rtl'] .print\:rtl\:-right-384,[dir='rtl'].print\:rtl\:-right-384 {
    right: -38.4rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-384,[dir='rtl'].print\:rtl\:-bottom-384 {
    bottom: -38.4rem
  }

  [dir='rtl'] .print\:rtl\:-left-384,[dir='rtl'].print\:rtl\:-left-384 {
    left: -38.4rem
  }

  [dir='rtl'] .print\:rtl\:-top-400,[dir='rtl'].print\:rtl\:-top-400 {
    top: -40rem
  }

  [dir='rtl'] .print\:rtl\:-right-400,[dir='rtl'].print\:rtl\:-right-400 {
    right: -40rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-400,[dir='rtl'].print\:rtl\:-bottom-400 {
    bottom: -40rem
  }

  [dir='rtl'] .print\:rtl\:-left-400,[dir='rtl'].print\:rtl\:-left-400 {
    left: -40rem
  }

  [dir='rtl'] .print\:rtl\:-top-512,[dir='rtl'].print\:rtl\:-top-512 {
    top: -51.2rem
  }

  [dir='rtl'] .print\:rtl\:-right-512,[dir='rtl'].print\:rtl\:-right-512 {
    right: -51.2rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-512,[dir='rtl'].print\:rtl\:-bottom-512 {
    bottom: -51.2rem
  }

  [dir='rtl'] .print\:rtl\:-left-512,[dir='rtl'].print\:rtl\:-left-512 {
    left: -51.2rem
  }

  [dir='rtl'] .print\:rtl\:-top-640,[dir='rtl'].print\:rtl\:-top-640 {
    top: -64rem
  }

  [dir='rtl'] .print\:rtl\:-right-640,[dir='rtl'].print\:rtl\:-right-640 {
    right: -64rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-640,[dir='rtl'].print\:rtl\:-bottom-640 {
    bottom: -64rem
  }

  [dir='rtl'] .print\:rtl\:-left-640,[dir='rtl'].print\:rtl\:-left-640 {
    left: -64rem
  }

  [dir='rtl'] .print\:rtl\:-top-xs,[dir='rtl'].print\:rtl\:-top-xs {
    top: -32rem
  }

  [dir='rtl'] .print\:rtl\:-right-xs,[dir='rtl'].print\:rtl\:-right-xs {
    right: -32rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-xs,[dir='rtl'].print\:rtl\:-bottom-xs {
    bottom: -32rem
  }

  [dir='rtl'] .print\:rtl\:-left-xs,[dir='rtl'].print\:rtl\:-left-xs {
    left: -32rem
  }

  [dir='rtl'] .print\:rtl\:-top-sm,[dir='rtl'].print\:rtl\:-top-sm {
    top: -48rem
  }

  [dir='rtl'] .print\:rtl\:-right-sm,[dir='rtl'].print\:rtl\:-right-sm {
    right: -48rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-sm,[dir='rtl'].print\:rtl\:-bottom-sm {
    bottom: -48rem
  }

  [dir='rtl'] .print\:rtl\:-left-sm,[dir='rtl'].print\:rtl\:-left-sm {
    left: -48rem
  }

  [dir='rtl'] .print\:rtl\:-top-md,[dir='rtl'].print\:rtl\:-top-md {
    top: -64rem
  }

  [dir='rtl'] .print\:rtl\:-right-md,[dir='rtl'].print\:rtl\:-right-md {
    right: -64rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-md,[dir='rtl'].print\:rtl\:-bottom-md {
    bottom: -64rem
  }

  [dir='rtl'] .print\:rtl\:-left-md,[dir='rtl'].print\:rtl\:-left-md {
    left: -64rem
  }

  [dir='rtl'] .print\:rtl\:-top-lg,[dir='rtl'].print\:rtl\:-top-lg {
    top: -80rem
  }

  [dir='rtl'] .print\:rtl\:-right-lg,[dir='rtl'].print\:rtl\:-right-lg {
    right: -80rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-lg,[dir='rtl'].print\:rtl\:-bottom-lg {
    bottom: -80rem
  }

  [dir='rtl'] .print\:rtl\:-left-lg,[dir='rtl'].print\:rtl\:-left-lg {
    left: -80rem
  }

  [dir='rtl'] .print\:rtl\:-top-xl,[dir='rtl'].print\:rtl\:-top-xl {
    top: -96rem
  }

  [dir='rtl'] .print\:rtl\:-right-xl,[dir='rtl'].print\:rtl\:-right-xl {
    right: -96rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-xl,[dir='rtl'].print\:rtl\:-bottom-xl {
    bottom: -96rem
  }

  [dir='rtl'] .print\:rtl\:-left-xl,[dir='rtl'].print\:rtl\:-left-xl {
    left: -96rem
  }

  [dir='rtl'] .print\:rtl\:-top-2xl,[dir='rtl'].print\:rtl\:-top-2xl {
    top: -112rem
  }

  [dir='rtl'] .print\:rtl\:-right-2xl,[dir='rtl'].print\:rtl\:-right-2xl {
    right: -112rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-2xl,[dir='rtl'].print\:rtl\:-bottom-2xl {
    bottom: -112rem
  }

  [dir='rtl'] .print\:rtl\:-left-2xl,[dir='rtl'].print\:rtl\:-left-2xl {
    left: -112rem
  }

  [dir='rtl'] .print\:rtl\:-top-3xl,[dir='rtl'].print\:rtl\:-top-3xl {
    top: -128rem
  }

  [dir='rtl'] .print\:rtl\:-right-3xl,[dir='rtl'].print\:rtl\:-right-3xl {
    right: -128rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-3xl,[dir='rtl'].print\:rtl\:-bottom-3xl {
    bottom: -128rem
  }

  [dir='rtl'] .print\:rtl\:-left-3xl,[dir='rtl'].print\:rtl\:-left-3xl {
    left: -128rem
  }

  [dir='rtl'] .print\:rtl\:-top-4xl,[dir='rtl'].print\:rtl\:-top-4xl {
    top: -144rem
  }

  [dir='rtl'] .print\:rtl\:-right-4xl,[dir='rtl'].print\:rtl\:-right-4xl {
    right: -144rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-4xl,[dir='rtl'].print\:rtl\:-bottom-4xl {
    bottom: -144rem
  }

  [dir='rtl'] .print\:rtl\:-left-4xl,[dir='rtl'].print\:rtl\:-left-4xl {
    left: -144rem
  }

  [dir='rtl'] .print\:rtl\:-top-5xl,[dir='rtl'].print\:rtl\:-top-5xl {
    top: -160rem
  }

  [dir='rtl'] .print\:rtl\:-right-5xl,[dir='rtl'].print\:rtl\:-right-5xl {
    right: -160rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-5xl,[dir='rtl'].print\:rtl\:-bottom-5xl {
    bottom: -160rem
  }

  [dir='rtl'] .print\:rtl\:-left-5xl,[dir='rtl'].print\:rtl\:-left-5xl {
    left: -160rem
  }

  [dir='rtl'] .print\:rtl\:-top-px,[dir='rtl'].print\:rtl\:-top-px {
    top: -1px
  }

  [dir='rtl'] .print\:rtl\:-right-px,[dir='rtl'].print\:rtl\:-right-px {
    right: -1px
  }

  [dir='rtl'] .print\:rtl\:-bottom-px,[dir='rtl'].print\:rtl\:-bottom-px {
    bottom: -1px
  }

  [dir='rtl'] .print\:rtl\:-left-px,[dir='rtl'].print\:rtl\:-left-px {
    left: -1px
  }

  [dir='rtl'] .print\:rtl\:-top-0\.5,[dir='rtl'].print\:rtl\:-top-0\.5 {
    top: -0.05rem
  }

  [dir='rtl'] .print\:rtl\:-right-0\.5,[dir='rtl'].print\:rtl\:-right-0\.5 {
    right: -0.05rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-0\.5,[dir='rtl'].print\:rtl\:-bottom-0\.5 {
    bottom: -0.05rem
  }

  [dir='rtl'] .print\:rtl\:-left-0\.5,[dir='rtl'].print\:rtl\:-left-0\.5 {
    left: -0.05rem
  }

  [dir='rtl'] .print\:rtl\:-top-1\.5,[dir='rtl'].print\:rtl\:-top-1\.5 {
    top: -0.15rem
  }

  [dir='rtl'] .print\:rtl\:-right-1\.5,[dir='rtl'].print\:rtl\:-right-1\.5 {
    right: -0.15rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-1\.5,[dir='rtl'].print\:rtl\:-bottom-1\.5 {
    bottom: -0.15rem
  }

  [dir='rtl'] .print\:rtl\:-left-1\.5,[dir='rtl'].print\:rtl\:-left-1\.5 {
    left: -0.15rem
  }

  [dir='rtl'] .print\:rtl\:-top-2\.5,[dir='rtl'].print\:rtl\:-top-2\.5 {
    top: -0.25rem
  }

  [dir='rtl'] .print\:rtl\:-right-2\.5,[dir='rtl'].print\:rtl\:-right-2\.5 {
    right: -0.25rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-2\.5,[dir='rtl'].print\:rtl\:-bottom-2\.5 {
    bottom: -0.25rem
  }

  [dir='rtl'] .print\:rtl\:-left-2\.5,[dir='rtl'].print\:rtl\:-left-2\.5 {
    left: -0.25rem
  }

  [dir='rtl'] .print\:rtl\:-top-3\.5,[dir='rtl'].print\:rtl\:-top-3\.5 {
    top: -0.35rem
  }

  [dir='rtl'] .print\:rtl\:-right-3\.5,[dir='rtl'].print\:rtl\:-right-3\.5 {
    right: -0.35rem
  }

  [dir='rtl'] .print\:rtl\:-bottom-3\.5,[dir='rtl'].print\:rtl\:-bottom-3\.5 {
    bottom: -0.35rem
  }

  [dir='rtl'] .print\:rtl\:-left-3\.5,[dir='rtl'].print\:rtl\:-left-3\.5 {
    left: -0.35rem
  }

  [dir='rtl'] .print\:rtl\:top-1\/2,[dir='rtl'].print\:rtl\:top-1\/2 {
    top: 50%
  }

  [dir='rtl'] .print\:rtl\:right-1\/2,[dir='rtl'].print\:rtl\:right-1\/2 {
    right: 50%
  }

  [dir='rtl'] .print\:rtl\:bottom-1\/2,[dir='rtl'].print\:rtl\:bottom-1\/2 {
    bottom: 50%
  }

  [dir='rtl'] .print\:rtl\:left-1\/2,[dir='rtl'].print\:rtl\:left-1\/2 {
    left: 50%
  }

  [dir='rtl'] .print\:rtl\:top-1\/3,[dir='rtl'].print\:rtl\:top-1\/3 {
    top: 33.333333%
  }

  [dir='rtl'] .print\:rtl\:right-1\/3,[dir='rtl'].print\:rtl\:right-1\/3 {
    right: 33.333333%
  }

  [dir='rtl'] .print\:rtl\:bottom-1\/3,[dir='rtl'].print\:rtl\:bottom-1\/3 {
    bottom: 33.333333%
  }

  [dir='rtl'] .print\:rtl\:left-1\/3,[dir='rtl'].print\:rtl\:left-1\/3 {
    left: 33.333333%
  }

  [dir='rtl'] .print\:rtl\:top-2\/3,[dir='rtl'].print\:rtl\:top-2\/3 {
    top: 66.666667%
  }

  [dir='rtl'] .print\:rtl\:right-2\/3,[dir='rtl'].print\:rtl\:right-2\/3 {
    right: 66.666667%
  }

  [dir='rtl'] .print\:rtl\:bottom-2\/3,[dir='rtl'].print\:rtl\:bottom-2\/3 {
    bottom: 66.666667%
  }

  [dir='rtl'] .print\:rtl\:left-2\/3,[dir='rtl'].print\:rtl\:left-2\/3 {
    left: 66.666667%
  }

  [dir='rtl'] .print\:rtl\:top-1\/4,[dir='rtl'].print\:rtl\:top-1\/4 {
    top: 25%
  }

  [dir='rtl'] .print\:rtl\:right-1\/4,[dir='rtl'].print\:rtl\:right-1\/4 {
    right: 25%
  }

  [dir='rtl'] .print\:rtl\:bottom-1\/4,[dir='rtl'].print\:rtl\:bottom-1\/4 {
    bottom: 25%
  }

  [dir='rtl'] .print\:rtl\:left-1\/4,[dir='rtl'].print\:rtl\:left-1\/4 {
    left: 25%
  }

  [dir='rtl'] .print\:rtl\:top-2\/4,[dir='rtl'].print\:rtl\:top-2\/4 {
    top: 50%
  }

  [dir='rtl'] .print\:rtl\:right-2\/4,[dir='rtl'].print\:rtl\:right-2\/4 {
    right: 50%
  }

  [dir='rtl'] .print\:rtl\:bottom-2\/4,[dir='rtl'].print\:rtl\:bottom-2\/4 {
    bottom: 50%
  }

  [dir='rtl'] .print\:rtl\:left-2\/4,[dir='rtl'].print\:rtl\:left-2\/4 {
    left: 50%
  }

  [dir='rtl'] .print\:rtl\:top-3\/4,[dir='rtl'].print\:rtl\:top-3\/4 {
    top: 75%
  }

  [dir='rtl'] .print\:rtl\:right-3\/4,[dir='rtl'].print\:rtl\:right-3\/4 {
    right: 75%
  }

  [dir='rtl'] .print\:rtl\:bottom-3\/4,[dir='rtl'].print\:rtl\:bottom-3\/4 {
    bottom: 75%
  }

  [dir='rtl'] .print\:rtl\:left-3\/4,[dir='rtl'].print\:rtl\:left-3\/4 {
    left: 75%
  }

  [dir='rtl'] .print\:rtl\:top-full,[dir='rtl'].print\:rtl\:top-full {
    top: 100%
  }

  [dir='rtl'] .print\:rtl\:right-full,[dir='rtl'].print\:rtl\:right-full {
    right: 100%
  }

  [dir='rtl'] .print\:rtl\:bottom-full,[dir='rtl'].print\:rtl\:bottom-full {
    bottom: 100%
  }

  [dir='rtl'] .print\:rtl\:left-full,[dir='rtl'].print\:rtl\:left-full {
    left: 100%
  }

  [dir='rtl'] .print\:rtl\:-top-1\/2,[dir='rtl'].print\:rtl\:-top-1\/2 {
    top: -50%
  }

  [dir='rtl'] .print\:rtl\:-right-1\/2,[dir='rtl'].print\:rtl\:-right-1\/2 {
    right: -50%
  }

  [dir='rtl'] .print\:rtl\:-bottom-1\/2,[dir='rtl'].print\:rtl\:-bottom-1\/2 {
    bottom: -50%
  }

  [dir='rtl'] .print\:rtl\:-left-1\/2,[dir='rtl'].print\:rtl\:-left-1\/2 {
    left: -50%
  }

  [dir='rtl'] .print\:rtl\:-top-1\/3,[dir='rtl'].print\:rtl\:-top-1\/3 {
    top: -33.333333%
  }

  [dir='rtl'] .print\:rtl\:-right-1\/3,[dir='rtl'].print\:rtl\:-right-1\/3 {
    right: -33.333333%
  }

  [dir='rtl'] .print\:rtl\:-bottom-1\/3,[dir='rtl'].print\:rtl\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  [dir='rtl'] .print\:rtl\:-left-1\/3,[dir='rtl'].print\:rtl\:-left-1\/3 {
    left: -33.333333%
  }

  [dir='rtl'] .print\:rtl\:-top-2\/3,[dir='rtl'].print\:rtl\:-top-2\/3 {
    top: -66.666667%
  }

  [dir='rtl'] .print\:rtl\:-right-2\/3,[dir='rtl'].print\:rtl\:-right-2\/3 {
    right: -66.666667%
  }

  [dir='rtl'] .print\:rtl\:-bottom-2\/3,[dir='rtl'].print\:rtl\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  [dir='rtl'] .print\:rtl\:-left-2\/3,[dir='rtl'].print\:rtl\:-left-2\/3 {
    left: -66.666667%
  }

  [dir='rtl'] .print\:rtl\:-top-1\/4,[dir='rtl'].print\:rtl\:-top-1\/4 {
    top: -25%
  }

  [dir='rtl'] .print\:rtl\:-right-1\/4,[dir='rtl'].print\:rtl\:-right-1\/4 {
    right: -25%
  }

  [dir='rtl'] .print\:rtl\:-bottom-1\/4,[dir='rtl'].print\:rtl\:-bottom-1\/4 {
    bottom: -25%
  }

  [dir='rtl'] .print\:rtl\:-left-1\/4,[dir='rtl'].print\:rtl\:-left-1\/4 {
    left: -25%
  }

  [dir='rtl'] .print\:rtl\:-top-2\/4,[dir='rtl'].print\:rtl\:-top-2\/4 {
    top: -50%
  }

  [dir='rtl'] .print\:rtl\:-right-2\/4,[dir='rtl'].print\:rtl\:-right-2\/4 {
    right: -50%
  }

  [dir='rtl'] .print\:rtl\:-bottom-2\/4,[dir='rtl'].print\:rtl\:-bottom-2\/4 {
    bottom: -50%
  }

  [dir='rtl'] .print\:rtl\:-left-2\/4,[dir='rtl'].print\:rtl\:-left-2\/4 {
    left: -50%
  }

  [dir='rtl'] .print\:rtl\:-top-3\/4,[dir='rtl'].print\:rtl\:-top-3\/4 {
    top: -75%
  }

  [dir='rtl'] .print\:rtl\:-right-3\/4,[dir='rtl'].print\:rtl\:-right-3\/4 {
    right: -75%
  }

  [dir='rtl'] .print\:rtl\:-bottom-3\/4,[dir='rtl'].print\:rtl\:-bottom-3\/4 {
    bottom: -75%
  }

  [dir='rtl'] .print\:rtl\:-left-3\/4,[dir='rtl'].print\:rtl\:-left-3\/4 {
    left: -75%
  }

  [dir='rtl'] .print\:rtl\:-top-full,[dir='rtl'].print\:rtl\:-top-full {
    top: -100%
  }

  [dir='rtl'] .print\:rtl\:-right-full,[dir='rtl'].print\:rtl\:-right-full {
    right: -100%
  }

  [dir='rtl'] .print\:rtl\:-bottom-full,[dir='rtl'].print\:rtl\:-bottom-full {
    bottom: -100%
  }

  [dir='rtl'] .print\:rtl\:-left-full,[dir='rtl'].print\:rtl\:-left-full {
    left: -100%
  }

  .print\:resize-none {
    resize: none
  }

  .print\:resize-y {
    resize: vertical
  }

  .print\:resize-x {
    resize: horizontal
  }

  .print\:resize {
    resize: both
  }

  .print\:shadow-0 {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-1 {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-2 {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-3 {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-4 {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-5 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-6 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-7 {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-8 {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-9 {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-10 {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-11 {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-12 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-13 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-14 {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-15 {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-16 {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-17 {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-18 {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-19 {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-20 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-21 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-22 {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-23 {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-24 {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-0 {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-1 {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-2 {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-3 {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-4 {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-5 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-6 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-7 {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-8 {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-9 {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-10 {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-11 {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-12 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-13 {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-14 {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-15 {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-16 {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-17 {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-18 {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-19 {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-20 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-21 {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-22 {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-23 {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-24 {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .print\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-0:focus-within {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-1:focus-within {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-2:focus-within {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-3:focus-within {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-4:focus-within {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-5:focus-within {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-6:focus-within {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-7:focus-within {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-8:focus-within {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-9:focus-within {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-10:focus-within {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-11:focus-within {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-12:focus-within {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-13:focus-within {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-14:focus-within {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-15:focus-within {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-16:focus-within {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-17:focus-within {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-18:focus-within {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-19:focus-within {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-20:focus-within {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-21:focus-within {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-22:focus-within {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-23:focus-within {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-24:focus-within {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-0:hover {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-1:hover {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-2:hover {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-3:hover {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-4:hover {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-5:hover {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-6:hover {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-7:hover {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-8:hover {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-9:hover {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-10:hover {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-11:hover {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-12:hover {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-13:hover {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-14:hover {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-15:hover {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-16:hover {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-17:hover {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-18:hover {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-19:hover {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-20:hover {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-21:hover {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-22:hover {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-23:hover {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-24:hover {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-0:focus {
    --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-1:focus {
    --tw-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-2:focus {
    --tw-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-3:focus {
    --tw-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-4:focus {
    --tw-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-5:focus {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-6:focus {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-7:focus {
    --tw-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-8:focus {
    --tw-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-9:focus {
    --tw-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-10:focus {
    --tw-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-11:focus {
    --tw-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-12:focus {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-13:focus {
    --tw-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-14:focus {
    --tw-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-15:focus {
    --tw-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-16:focus {
    --tw-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-17:focus {
    --tw-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-18:focus {
    --tw-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-19:focus {
    --tw-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-20:focus {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-21:focus {
    --tw-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-22:focus {
    --tw-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-23:focus {
    --tw-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-24:focus {
    --tw-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .print\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:ring-inset {
    --tw-ring-inset: inset
  }

  .print\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset
  }

  .print\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .print\:focus\:ring-inset:focus {
    --tw-ring-inset: inset
  }

  .print\:ring-offset-transparent {
    --tw-ring-offset-color: transparent
  }

  .print\:ring-offset-current {
    --tw-ring-offset-color: currentColor
  }

  .print\:ring-offset-black {
    --tw-ring-offset-color: #22292F
  }

  .print\:ring-offset-white {
    --tw-ring-offset-color: #fff
  }

  .print\:ring-offset-grey-50 {
    --tw-ring-offset-color: #FAFAFA
  }

  .print\:ring-offset-grey-100 {
    --tw-ring-offset-color: #F5F5F5
  }

  .print\:ring-offset-grey-200 {
    --tw-ring-offset-color: #EEEEEE
  }

  .print\:ring-offset-grey-300 {
    --tw-ring-offset-color: #E0E0E0
  }

  .print\:ring-offset-grey-400 {
    --tw-ring-offset-color: #BDBDBD
  }

  .print\:ring-offset-grey-500 {
    --tw-ring-offset-color: #9E9E9E
  }

  .print\:ring-offset-grey-600 {
    --tw-ring-offset-color: #757575
  }

  .print\:ring-offset-grey-700 {
    --tw-ring-offset-color: #616161
  }

  .print\:ring-offset-grey-800 {
    --tw-ring-offset-color: #424242
  }

  .print\:ring-offset-grey-900 {
    --tw-ring-offset-color: #212121
  }

  .print\:ring-offset-grey {
    --tw-ring-offset-color: #9E9E9E
  }

  .print\:ring-offset-grey-A100 {
    --tw-ring-offset-color: #D5D5D5
  }

  .print\:ring-offset-grey-A200 {
    --tw-ring-offset-color: #AAAAAA
  }

  .print\:ring-offset-grey-A400 {
    --tw-ring-offset-color: #303030
  }

  .print\:ring-offset-grey-A700 {
    --tw-ring-offset-color: #616161
  }

  .print\:ring-offset-gray-50 {
    --tw-ring-offset-color: #FAFAFA
  }

  .print\:ring-offset-gray-100 {
    --tw-ring-offset-color: #F5F5F5
  }

  .print\:ring-offset-gray-200 {
    --tw-ring-offset-color: #EEEEEE
  }

  .print\:ring-offset-gray-300 {
    --tw-ring-offset-color: #E0E0E0
  }

  .print\:ring-offset-gray-400 {
    --tw-ring-offset-color: #BDBDBD
  }

  .print\:ring-offset-gray-500 {
    --tw-ring-offset-color: #9E9E9E
  }

  .print\:ring-offset-gray-600 {
    --tw-ring-offset-color: #757575
  }

  .print\:ring-offset-gray-700 {
    --tw-ring-offset-color: #616161
  }

  .print\:ring-offset-gray-800 {
    --tw-ring-offset-color: #424242
  }

  .print\:ring-offset-gray-900 {
    --tw-ring-offset-color: #212121
  }

  .print\:ring-offset-gray {
    --tw-ring-offset-color: #9E9E9E
  }

  .print\:ring-offset-gray-hover {
    --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
  }

  .print\:ring-offset-gray-A100 {
    --tw-ring-offset-color: #D5D5D5
  }

  .print\:ring-offset-gray-A200 {
    --tw-ring-offset-color: #AAAAAA
  }

  .print\:ring-offset-gray-A400 {
    --tw-ring-offset-color: #303030
  }

  .print\:ring-offset-gray-A700 {
    --tw-ring-offset-color: #616161
  }

  .print\:ring-offset-red-50 {
    --tw-ring-offset-color: #FFEBEE
  }

  .print\:ring-offset-red-100 {
    --tw-ring-offset-color: #FFCDD2
  }

  .print\:ring-offset-red-200 {
    --tw-ring-offset-color: #EF9A9A
  }

  .print\:ring-offset-red-300 {
    --tw-ring-offset-color: #E57373
  }

  .print\:ring-offset-red-400 {
    --tw-ring-offset-color: #EF5350
  }

  .print\:ring-offset-red-500 {
    --tw-ring-offset-color: #F44336
  }

  .print\:ring-offset-red-600 {
    --tw-ring-offset-color: #E53935
  }

  .print\:ring-offset-red-700 {
    --tw-ring-offset-color: #D32F2F
  }

  .print\:ring-offset-red-800 {
    --tw-ring-offset-color: #C62828
  }

  .print\:ring-offset-red-900 {
    --tw-ring-offset-color: #B71C1C
  }

  .print\:ring-offset-red {
    --tw-ring-offset-color: #F44336
  }

  .print\:ring-offset-red-A100 {
    --tw-ring-offset-color: #FF8A80
  }

  .print\:ring-offset-red-A200 {
    --tw-ring-offset-color: #FF5252
  }

  .print\:ring-offset-red-A400 {
    --tw-ring-offset-color: #FF1744
  }

  .print\:ring-offset-red-A700 {
    --tw-ring-offset-color: #D50000
  }

  .print\:ring-offset-orange-50 {
    --tw-ring-offset-color: #FFF3E0
  }

  .print\:ring-offset-orange-100 {
    --tw-ring-offset-color: #FFE0B2
  }

  .print\:ring-offset-orange-200 {
    --tw-ring-offset-color: #FFCC80
  }

  .print\:ring-offset-orange-300 {
    --tw-ring-offset-color: #FFB74D
  }

  .print\:ring-offset-orange-400 {
    --tw-ring-offset-color: #FFA726
  }

  .print\:ring-offset-orange-500 {
    --tw-ring-offset-color: #FF9800
  }

  .print\:ring-offset-orange-600 {
    --tw-ring-offset-color: #FB8C00
  }

  .print\:ring-offset-orange-700 {
    --tw-ring-offset-color: #F57C00
  }

  .print\:ring-offset-orange-800 {
    --tw-ring-offset-color: #EF6C00
  }

  .print\:ring-offset-orange-900 {
    --tw-ring-offset-color: #E65100
  }

  .print\:ring-offset-orange {
    --tw-ring-offset-color: #FF9800
  }

  .print\:ring-offset-orange-A100 {
    --tw-ring-offset-color: #FFD180
  }

  .print\:ring-offset-orange-A200 {
    --tw-ring-offset-color: #FFAB40
  }

  .print\:ring-offset-orange-A400 {
    --tw-ring-offset-color: #FF9100
  }

  .print\:ring-offset-orange-A700 {
    --tw-ring-offset-color: #FF6D00
  }

  .print\:ring-offset-deep-orange-50 {
    --tw-ring-offset-color: #FBE9E7
  }

  .print\:ring-offset-deep-orange-100 {
    --tw-ring-offset-color: #FFCCBC
  }

  .print\:ring-offset-deep-orange-200 {
    --tw-ring-offset-color: #FFAB91
  }

  .print\:ring-offset-deep-orange-300 {
    --tw-ring-offset-color: #FF8A65
  }

  .print\:ring-offset-deep-orange-400 {
    --tw-ring-offset-color: #FF7043
  }

  .print\:ring-offset-deep-orange-500 {
    --tw-ring-offset-color: #FF5722
  }

  .print\:ring-offset-deep-orange-600 {
    --tw-ring-offset-color: #F4511E
  }

  .print\:ring-offset-deep-orange-700 {
    --tw-ring-offset-color: #E64A19
  }

  .print\:ring-offset-deep-orange-800 {
    --tw-ring-offset-color: #D84315
  }

  .print\:ring-offset-deep-orange-900 {
    --tw-ring-offset-color: #BF360C
  }

  .print\:ring-offset-deep-orange {
    --tw-ring-offset-color: #FF5722
  }

  .print\:ring-offset-deep-orange-A100 {
    --tw-ring-offset-color: #FF9E80
  }

  .print\:ring-offset-deep-orange-A200 {
    --tw-ring-offset-color: #FF6E40
  }

  .print\:ring-offset-deep-orange-A400 {
    --tw-ring-offset-color: #FF3D00
  }

  .print\:ring-offset-deep-orange-A700 {
    --tw-ring-offset-color: #DD2C00
  }

  .print\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #FFFDE7
  }

  .print\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #FFF9C4
  }

  .print\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #FFF59D
  }

  .print\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #FFF176
  }

  .print\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #FFEE58
  }

  .print\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #FFEB3B
  }

  .print\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #FDD835
  }

  .print\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #FBC02D
  }

  .print\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #F9A825
  }

  .print\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #F57F17
  }

  .print\:ring-offset-yellow {
    --tw-ring-offset-color: #FFEB3B
  }

  .print\:ring-offset-yellow-A100 {
    --tw-ring-offset-color: #FFFF8D
  }

  .print\:ring-offset-yellow-A200 {
    --tw-ring-offset-color: #FFFF00
  }

  .print\:ring-offset-yellow-A400 {
    --tw-ring-offset-color: #FFEA00
  }

  .print\:ring-offset-yellow-A700 {
    --tw-ring-offset-color: #FFD600
  }

  .print\:ring-offset-green-50 {
    --tw-ring-offset-color: #E8F5E9
  }

  .print\:ring-offset-green-100 {
    --tw-ring-offset-color: #C8E6C9
  }

  .print\:ring-offset-green-200 {
    --tw-ring-offset-color: #A5D6A7
  }

  .print\:ring-offset-green-300 {
    --tw-ring-offset-color: #81C784
  }

  .print\:ring-offset-green-400 {
    --tw-ring-offset-color: #66BB6A
  }

  .print\:ring-offset-green-500 {
    --tw-ring-offset-color: #4CAF50
  }

  .print\:ring-offset-green-600 {
    --tw-ring-offset-color: #43A047
  }

  .print\:ring-offset-green-700 {
    --tw-ring-offset-color: #388E3C
  }

  .print\:ring-offset-green-800 {
    --tw-ring-offset-color: #2E7D32
  }

  .print\:ring-offset-green-900 {
    --tw-ring-offset-color: #1B5E20
  }

  .print\:ring-offset-green {
    --tw-ring-offset-color: #4CAF50
  }

  .print\:ring-offset-green-A100 {
    --tw-ring-offset-color: #B9F6CA
  }

  .print\:ring-offset-green-A200 {
    --tw-ring-offset-color: #69F0AE
  }

  .print\:ring-offset-green-A400 {
    --tw-ring-offset-color: #00E676
  }

  .print\:ring-offset-green-A700 {
    --tw-ring-offset-color: #00C853
  }

  .print\:ring-offset-light-green-50 {
    --tw-ring-offset-color: #F1F8E9
  }

  .print\:ring-offset-light-green-100 {
    --tw-ring-offset-color: #DCEDC8
  }

  .print\:ring-offset-light-green-200 {
    --tw-ring-offset-color: #C5E1A5
  }

  .print\:ring-offset-light-green-300 {
    --tw-ring-offset-color: #AED581
  }

  .print\:ring-offset-light-green-400 {
    --tw-ring-offset-color: #9CCC65
  }

  .print\:ring-offset-light-green-500 {
    --tw-ring-offset-color: #8BC34A
  }

  .print\:ring-offset-light-green-600 {
    --tw-ring-offset-color: #7CB342
  }

  .print\:ring-offset-light-green-700 {
    --tw-ring-offset-color: #689F38
  }

  .print\:ring-offset-light-green-800 {
    --tw-ring-offset-color: #558B2F
  }

  .print\:ring-offset-light-green-900 {
    --tw-ring-offset-color: #33691E
  }

  .print\:ring-offset-light-green {
    --tw-ring-offset-color: #8BC34A
  }

  .print\:ring-offset-light-green-A100 {
    --tw-ring-offset-color: #CCFF90
  }

  .print\:ring-offset-light-green-A200 {
    --tw-ring-offset-color: #B2FF59
  }

  .print\:ring-offset-light-green-A400 {
    --tw-ring-offset-color: #76FF03
  }

  .print\:ring-offset-light-green-A700 {
    --tw-ring-offset-color: #64DD17
  }

  .print\:ring-offset-teal-50 {
    --tw-ring-offset-color: #E0F2F1
  }

  .print\:ring-offset-teal-100 {
    --tw-ring-offset-color: #B2DFDB
  }

  .print\:ring-offset-teal-200 {
    --tw-ring-offset-color: #80CBC4
  }

  .print\:ring-offset-teal-300 {
    --tw-ring-offset-color: #4DB6AC
  }

  .print\:ring-offset-teal-400 {
    --tw-ring-offset-color: #26A69A
  }

  .print\:ring-offset-teal-500 {
    --tw-ring-offset-color: #009688
  }

  .print\:ring-offset-teal-600 {
    --tw-ring-offset-color: #00897B
  }

  .print\:ring-offset-teal-700 {
    --tw-ring-offset-color: #00796B
  }

  .print\:ring-offset-teal-800 {
    --tw-ring-offset-color: #00695C
  }

  .print\:ring-offset-teal-900 {
    --tw-ring-offset-color: #004D40
  }

  .print\:ring-offset-teal {
    --tw-ring-offset-color: #009688
  }

  .print\:ring-offset-teal-A100 {
    --tw-ring-offset-color: #A7FFEB
  }

  .print\:ring-offset-teal-A200 {
    --tw-ring-offset-color: #64FFDA
  }

  .print\:ring-offset-teal-A400 {
    --tw-ring-offset-color: #1DE9B6
  }

  .print\:ring-offset-teal-A700 {
    --tw-ring-offset-color: #00BFA5
  }

  .print\:ring-offset-blue-50 {
    --tw-ring-offset-color: #E3F2FD
  }

  .print\:ring-offset-blue-100 {
    --tw-ring-offset-color: #BBDEFB
  }

  .print\:ring-offset-blue-200 {
    --tw-ring-offset-color: #90CAF9
  }

  .print\:ring-offset-blue-300 {
    --tw-ring-offset-color: #64B5F6
  }

  .print\:ring-offset-blue-400 {
    --tw-ring-offset-color: #42A5F5
  }

  .print\:ring-offset-blue-500 {
    --tw-ring-offset-color: #2196F3
  }

  .print\:ring-offset-blue-600 {
    --tw-ring-offset-color: #1E88E5
  }

  .print\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1976D2
  }

  .print\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1565C0
  }

  .print\:ring-offset-blue-900 {
    --tw-ring-offset-color: #0D47A1
  }

  .print\:ring-offset-blue {
    --tw-ring-offset-color: #2196F3
  }

  .print\:ring-offset-blue-A100 {
    --tw-ring-offset-color: #82B1FF
  }

  .print\:ring-offset-blue-A200 {
    --tw-ring-offset-color: #448AFF
  }

  .print\:ring-offset-blue-A400 {
    --tw-ring-offset-color: #2979FF
  }

  .print\:ring-offset-blue-A700 {
    --tw-ring-offset-color: #2962FF
  }

  .print\:ring-offset-light-blue-50 {
    --tw-ring-offset-color: #E1F5FE
  }

  .print\:ring-offset-light-blue-100 {
    --tw-ring-offset-color: #B3E5FC
  }

  .print\:ring-offset-light-blue-200 {
    --tw-ring-offset-color: #81D4FA
  }

  .print\:ring-offset-light-blue-300 {
    --tw-ring-offset-color: #4FC3F7
  }

  .print\:ring-offset-light-blue-400 {
    --tw-ring-offset-color: #29B6F6
  }

  .print\:ring-offset-light-blue-500 {
    --tw-ring-offset-color: #03A9F4
  }

  .print\:ring-offset-light-blue-600 {
    --tw-ring-offset-color: #039BE5
  }

  .print\:ring-offset-light-blue-700 {
    --tw-ring-offset-color: #0288D1
  }

  .print\:ring-offset-light-blue-800 {
    --tw-ring-offset-color: #0277BD
  }

  .print\:ring-offset-light-blue-900 {
    --tw-ring-offset-color: #01579B
  }

  .print\:ring-offset-light-blue {
    --tw-ring-offset-color: #03A9F4
  }

  .print\:ring-offset-light-blue-A100 {
    --tw-ring-offset-color: #80D8FF
  }

  .print\:ring-offset-light-blue-A200 {
    --tw-ring-offset-color: #40C4FF
  }

  .print\:ring-offset-light-blue-A400 {
    --tw-ring-offset-color: #00B0FF
  }

  .print\:ring-offset-light-blue-A700 {
    --tw-ring-offset-color: #0091EA
  }

  .print\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #E8EAF6
  }

  .print\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #C5CAE9
  }

  .print\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #9FA8DA
  }

  .print\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #7986CB
  }

  .print\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #5C6BC0
  }

  .print\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #3F51B5
  }

  .print\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #3949AB
  }

  .print\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #303F9F
  }

  .print\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #283593
  }

  .print\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #1A237E
  }

  .print\:ring-offset-indigo {
    --tw-ring-offset-color: #3F51B5
  }

  .print\:ring-offset-indigo-A100 {
    --tw-ring-offset-color: #8C9EFF
  }

  .print\:ring-offset-indigo-A200 {
    --tw-ring-offset-color: #536DFE
  }

  .print\:ring-offset-indigo-A400 {
    --tw-ring-offset-color: #3D5AFE
  }

  .print\:ring-offset-indigo-A700 {
    --tw-ring-offset-color: #304FFE
  }

  .print\:ring-offset-purple-50 {
    --tw-ring-offset-color: #F3E5F5
  }

  .print\:ring-offset-purple-100 {
    --tw-ring-offset-color: #E1BEE7
  }

  .print\:ring-offset-purple-200 {
    --tw-ring-offset-color: #CE93D8
  }

  .print\:ring-offset-purple-300 {
    --tw-ring-offset-color: #BA68C8
  }

  .print\:ring-offset-purple-400 {
    --tw-ring-offset-color: #AB47BC
  }

  .print\:ring-offset-purple-500 {
    --tw-ring-offset-color: #9C27B0
  }

  .print\:ring-offset-purple-600 {
    --tw-ring-offset-color: #8E24AA
  }

  .print\:ring-offset-purple-700 {
    --tw-ring-offset-color: #7B1FA2
  }

  .print\:ring-offset-purple-800 {
    --tw-ring-offset-color: #6A1B9A
  }

  .print\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4A148C
  }

  .print\:ring-offset-purple {
    --tw-ring-offset-color: #9C27B0
  }

  .print\:ring-offset-purple-A100 {
    --tw-ring-offset-color: #EA80FC
  }

  .print\:ring-offset-purple-A200 {
    --tw-ring-offset-color: #E040FB
  }

  .print\:ring-offset-purple-A400 {
    --tw-ring-offset-color: #D500F9
  }

  .print\:ring-offset-purple-A700 {
    --tw-ring-offset-color: #AA00FF
  }

  .print\:ring-offset-deep-purple-50 {
    --tw-ring-offset-color: #EDE7F6
  }

  .print\:ring-offset-deep-purple-100 {
    --tw-ring-offset-color: #D1C4E9
  }

  .print\:ring-offset-deep-purple-200 {
    --tw-ring-offset-color: #B39DDB
  }

  .print\:ring-offset-deep-purple-300 {
    --tw-ring-offset-color: #9575CD
  }

  .print\:ring-offset-deep-purple-400 {
    --tw-ring-offset-color: #7E57C2
  }

  .print\:ring-offset-deep-purple-500 {
    --tw-ring-offset-color: #673AB7
  }

  .print\:ring-offset-deep-purple-600 {
    --tw-ring-offset-color: #5E35B1
  }

  .print\:ring-offset-deep-purple-700 {
    --tw-ring-offset-color: #512DA8
  }

  .print\:ring-offset-deep-purple-800 {
    --tw-ring-offset-color: #4527A0
  }

  .print\:ring-offset-deep-purple-900 {
    --tw-ring-offset-color: #311B92
  }

  .print\:ring-offset-deep-purple {
    --tw-ring-offset-color: #673AB7
  }

  .print\:ring-offset-deep-purple-A100 {
    --tw-ring-offset-color: #B388FF
  }

  .print\:ring-offset-deep-purple-A200 {
    --tw-ring-offset-color: #7C4DFF
  }

  .print\:ring-offset-deep-purple-A400 {
    --tw-ring-offset-color: #651FFF
  }

  .print\:ring-offset-deep-purple-A700 {
    --tw-ring-offset-color: #6200EA
  }

  .print\:ring-offset-pink-50 {
    --tw-ring-offset-color: #FCE4EC
  }

  .print\:ring-offset-pink-100 {
    --tw-ring-offset-color: #F8BBD0
  }

  .print\:ring-offset-pink-200 {
    --tw-ring-offset-color: #F48FB1
  }

  .print\:ring-offset-pink-300 {
    --tw-ring-offset-color: #F06292
  }

  .print\:ring-offset-pink-400 {
    --tw-ring-offset-color: #EC407A
  }

  .print\:ring-offset-pink-500 {
    --tw-ring-offset-color: #E91E63
  }

  .print\:ring-offset-pink-600 {
    --tw-ring-offset-color: #D81B60
  }

  .print\:ring-offset-pink-700 {
    --tw-ring-offset-color: #C2185B
  }

  .print\:ring-offset-pink-800 {
    --tw-ring-offset-color: #AD1457
  }

  .print\:ring-offset-pink-900 {
    --tw-ring-offset-color: #880E4F
  }

  .print\:ring-offset-pink {
    --tw-ring-offset-color: #E91E63
  }

  .print\:ring-offset-pink-A100 {
    --tw-ring-offset-color: #FF80AB
  }

  .print\:ring-offset-pink-A200 {
    --tw-ring-offset-color: #FF4081
  }

  .print\:ring-offset-pink-A400 {
    --tw-ring-offset-color: #F50057
  }

  .print\:ring-offset-pink-A700 {
    --tw-ring-offset-color: #C51162
  }

  .print\:ring-offset-lime-50 {
    --tw-ring-offset-color: #F9FBE7
  }

  .print\:ring-offset-lime-100 {
    --tw-ring-offset-color: #F0F4C3
  }

  .print\:ring-offset-lime-200 {
    --tw-ring-offset-color: #E6EE9C
  }

  .print\:ring-offset-lime-300 {
    --tw-ring-offset-color: #DCE775
  }

  .print\:ring-offset-lime-400 {
    --tw-ring-offset-color: #D4E157
  }

  .print\:ring-offset-lime-500 {
    --tw-ring-offset-color: #CDDC39
  }

  .print\:ring-offset-lime-600 {
    --tw-ring-offset-color: #C0CA33
  }

  .print\:ring-offset-lime-700 {
    --tw-ring-offset-color: #AFB42B
  }

  .print\:ring-offset-lime-800 {
    --tw-ring-offset-color: #9E9D24
  }

  .print\:ring-offset-lime-900 {
    --tw-ring-offset-color: #827717
  }

  .print\:ring-offset-lime {
    --tw-ring-offset-color: #CDDC39
  }

  .print\:ring-offset-lime-A100 {
    --tw-ring-offset-color: #F4FF81
  }

  .print\:ring-offset-lime-A200 {
    --tw-ring-offset-color: #EEFF41
  }

  .print\:ring-offset-lime-A400 {
    --tw-ring-offset-color: #C6FF00
  }

  .print\:ring-offset-lime-A700 {
    --tw-ring-offset-color: #AEEA00
  }

  .print\:ring-offset-amber-50 {
    --tw-ring-offset-color: #FFF8E1
  }

  .print\:ring-offset-amber-100 {
    --tw-ring-offset-color: #FFECB3
  }

  .print\:ring-offset-amber-200 {
    --tw-ring-offset-color: #FFE082
  }

  .print\:ring-offset-amber-300 {
    --tw-ring-offset-color: #FFD54F
  }

  .print\:ring-offset-amber-400 {
    --tw-ring-offset-color: #FFCA28
  }

  .print\:ring-offset-amber-500 {
    --tw-ring-offset-color: #FFC107
  }

  .print\:ring-offset-amber-600 {
    --tw-ring-offset-color: #FFB300
  }

  .print\:ring-offset-amber-700 {
    --tw-ring-offset-color: #FFA000
  }

  .print\:ring-offset-amber-800 {
    --tw-ring-offset-color: #FF8F00
  }

  .print\:ring-offset-amber-900 {
    --tw-ring-offset-color: #FF6F00
  }

  .print\:ring-offset-amber {
    --tw-ring-offset-color: #FFC107
  }

  .print\:ring-offset-amber-A100 {
    --tw-ring-offset-color: #FFE57F
  }

  .print\:ring-offset-amber-A200 {
    --tw-ring-offset-color: #FFD740
  }

  .print\:ring-offset-amber-A400 {
    --tw-ring-offset-color: #FFC400
  }

  .print\:ring-offset-amber-A700 {
    --tw-ring-offset-color: #FFAB00
  }

  .print\:ring-offset-brown-50 {
    --tw-ring-offset-color: #EFEBE9
  }

  .print\:ring-offset-brown-100 {
    --tw-ring-offset-color: #D7CCC8
  }

  .print\:ring-offset-brown-200 {
    --tw-ring-offset-color: #BCAAA4
  }

  .print\:ring-offset-brown-300 {
    --tw-ring-offset-color: #A1887F
  }

  .print\:ring-offset-brown-400 {
    --tw-ring-offset-color: #8D6E63
  }

  .print\:ring-offset-brown-500 {
    --tw-ring-offset-color: #795548
  }

  .print\:ring-offset-brown-600 {
    --tw-ring-offset-color: #6D4C41
  }

  .print\:ring-offset-brown-700 {
    --tw-ring-offset-color: #5D4037
  }

  .print\:ring-offset-brown-800 {
    --tw-ring-offset-color: #4E342E
  }

  .print\:ring-offset-brown-900 {
    --tw-ring-offset-color: #3E2723
  }

  .print\:ring-offset-brown {
    --tw-ring-offset-color: #795548
  }

  .print\:ring-offset-brown-A100 {
    --tw-ring-offset-color: #D7CCC8
  }

  .print\:ring-offset-brown-A200 {
    --tw-ring-offset-color: #BCAAA4
  }

  .print\:ring-offset-brown-A400 {
    --tw-ring-offset-color: #8D6E63
  }

  .print\:ring-offset-brown-A700 {
    --tw-ring-offset-color: #5D4037
  }

  .print\:ring-offset-blue-gray-50 {
    --tw-ring-offset-color: #ECEFF1
  }

  .print\:ring-offset-blue-gray-100 {
    --tw-ring-offset-color: #CFD8DC
  }

  .print\:ring-offset-blue-gray-200 {
    --tw-ring-offset-color: #B0BEC5
  }

  .print\:ring-offset-blue-gray-300 {
    --tw-ring-offset-color: #90A4AE
  }

  .print\:ring-offset-blue-gray-400 {
    --tw-ring-offset-color: #78909C
  }

  .print\:ring-offset-blue-gray-500 {
    --tw-ring-offset-color: #607D8B
  }

  .print\:ring-offset-blue-gray-600 {
    --tw-ring-offset-color: #546E7A
  }

  .print\:ring-offset-blue-gray-700 {
    --tw-ring-offset-color: #455A64
  }

  .print\:ring-offset-blue-gray-800 {
    --tw-ring-offset-color: #37474F
  }

  .print\:ring-offset-blue-gray-900 {
    --tw-ring-offset-color: #263238
  }

  .print\:ring-offset-blue-gray {
    --tw-ring-offset-color: #607D8B
  }

  .print\:ring-offset-blue-gray-A100 {
    --tw-ring-offset-color: #CFD8DC
  }

  .print\:ring-offset-blue-gray-A200 {
    --tw-ring-offset-color: #B0BEC5
  }

  .print\:ring-offset-blue-gray-A400 {
    --tw-ring-offset-color: #78909C
  }

  .print\:ring-offset-blue-gray-A700 {
    --tw-ring-offset-color: #455A64
  }

  .print\:ring-offset-cyan-50 {
    --tw-ring-offset-color: #E0F7FA
  }

  .print\:ring-offset-cyan-100 {
    --tw-ring-offset-color: #B2EBF2
  }

  .print\:ring-offset-cyan-200 {
    --tw-ring-offset-color: #80DEEA
  }

  .print\:ring-offset-cyan-300 {
    --tw-ring-offset-color: #4DD0E1
  }

  .print\:ring-offset-cyan-400 {
    --tw-ring-offset-color: #26C6DA
  }

  .print\:ring-offset-cyan-500 {
    --tw-ring-offset-color: #00BCD4
  }

  .print\:ring-offset-cyan-600 {
    --tw-ring-offset-color: #00ACC1
  }

  .print\:ring-offset-cyan-700 {
    --tw-ring-offset-color: #0097A7
  }

  .print\:ring-offset-cyan-800 {
    --tw-ring-offset-color: #00838F
  }

  .print\:ring-offset-cyan-900 {
    --tw-ring-offset-color: #006064
  }

  .print\:ring-offset-cyan {
    --tw-ring-offset-color: #00BCD4
  }

  .print\:ring-offset-cyan-A100 {
    --tw-ring-offset-color: #84FFFF
  }

  .print\:ring-offset-cyan-A200 {
    --tw-ring-offset-color: #18FFFF
  }

  .print\:ring-offset-cyan-A400 {
    --tw-ring-offset-color: #00E5FF
  }

  .print\:ring-offset-cyan-A700 {
    --tw-ring-offset-color: #00B8D4
  }

  .print\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent
  }

  .print\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor
  }

  .print\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #22292F
  }

  .print\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff
  }

  .print\:focus-within\:ring-offset-grey-50:focus-within {
    --tw-ring-offset-color: #FAFAFA
  }

  .print\:focus-within\:ring-offset-grey-100:focus-within {
    --tw-ring-offset-color: #F5F5F5
  }

  .print\:focus-within\:ring-offset-grey-200:focus-within {
    --tw-ring-offset-color: #EEEEEE
  }

  .print\:focus-within\:ring-offset-grey-300:focus-within {
    --tw-ring-offset-color: #E0E0E0
  }

  .print\:focus-within\:ring-offset-grey-400:focus-within {
    --tw-ring-offset-color: #BDBDBD
  }

  .print\:focus-within\:ring-offset-grey-500:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .print\:focus-within\:ring-offset-grey-600:focus-within {
    --tw-ring-offset-color: #757575
  }

  .print\:focus-within\:ring-offset-grey-700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .print\:focus-within\:ring-offset-grey-800:focus-within {
    --tw-ring-offset-color: #424242
  }

  .print\:focus-within\:ring-offset-grey-900:focus-within {
    --tw-ring-offset-color: #212121
  }

  .print\:focus-within\:ring-offset-grey:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .print\:focus-within\:ring-offset-grey-A100:focus-within {
    --tw-ring-offset-color: #D5D5D5
  }

  .print\:focus-within\:ring-offset-grey-A200:focus-within {
    --tw-ring-offset-color: #AAAAAA
  }

  .print\:focus-within\:ring-offset-grey-A400:focus-within {
    --tw-ring-offset-color: #303030
  }

  .print\:focus-within\:ring-offset-grey-A700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .print\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #FAFAFA
  }

  .print\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #F5F5F5
  }

  .print\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #EEEEEE
  }

  .print\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #E0E0E0
  }

  .print\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #BDBDBD
  }

  .print\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .print\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #757575
  }

  .print\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .print\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #424242
  }

  .print\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #212121
  }

  .print\:focus-within\:ring-offset-gray:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .print\:focus-within\:ring-offset-gray-hover:focus-within {
    --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
  }

  .print\:focus-within\:ring-offset-gray-A100:focus-within {
    --tw-ring-offset-color: #D5D5D5
  }

  .print\:focus-within\:ring-offset-gray-A200:focus-within {
    --tw-ring-offset-color: #AAAAAA
  }

  .print\:focus-within\:ring-offset-gray-A400:focus-within {
    --tw-ring-offset-color: #303030
  }

  .print\:focus-within\:ring-offset-gray-A700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .print\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #FFEBEE
  }

  .print\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #FFCDD2
  }

  .print\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #EF9A9A
  }

  .print\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #E57373
  }

  .print\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #EF5350
  }

  .print\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #F44336
  }

  .print\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #E53935
  }

  .print\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #D32F2F
  }

  .print\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #C62828
  }

  .print\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #B71C1C
  }

  .print\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: #F44336
  }

  .print\:focus-within\:ring-offset-red-A100:focus-within {
    --tw-ring-offset-color: #FF8A80
  }

  .print\:focus-within\:ring-offset-red-A200:focus-within {
    --tw-ring-offset-color: #FF5252
  }

  .print\:focus-within\:ring-offset-red-A400:focus-within {
    --tw-ring-offset-color: #FF1744
  }

  .print\:focus-within\:ring-offset-red-A700:focus-within {
    --tw-ring-offset-color: #D50000
  }

  .print\:focus-within\:ring-offset-orange-50:focus-within {
    --tw-ring-offset-color: #FFF3E0
  }

  .print\:focus-within\:ring-offset-orange-100:focus-within {
    --tw-ring-offset-color: #FFE0B2
  }

  .print\:focus-within\:ring-offset-orange-200:focus-within {
    --tw-ring-offset-color: #FFCC80
  }

  .print\:focus-within\:ring-offset-orange-300:focus-within {
    --tw-ring-offset-color: #FFB74D
  }

  .print\:focus-within\:ring-offset-orange-400:focus-within {
    --tw-ring-offset-color: #FFA726
  }

  .print\:focus-within\:ring-offset-orange-500:focus-within {
    --tw-ring-offset-color: #FF9800
  }

  .print\:focus-within\:ring-offset-orange-600:focus-within {
    --tw-ring-offset-color: #FB8C00
  }

  .print\:focus-within\:ring-offset-orange-700:focus-within {
    --tw-ring-offset-color: #F57C00
  }

  .print\:focus-within\:ring-offset-orange-800:focus-within {
    --tw-ring-offset-color: #EF6C00
  }

  .print\:focus-within\:ring-offset-orange-900:focus-within {
    --tw-ring-offset-color: #E65100
  }

  .print\:focus-within\:ring-offset-orange:focus-within {
    --tw-ring-offset-color: #FF9800
  }

  .print\:focus-within\:ring-offset-orange-A100:focus-within {
    --tw-ring-offset-color: #FFD180
  }

  .print\:focus-within\:ring-offset-orange-A200:focus-within {
    --tw-ring-offset-color: #FFAB40
  }

  .print\:focus-within\:ring-offset-orange-A400:focus-within {
    --tw-ring-offset-color: #FF9100
  }

  .print\:focus-within\:ring-offset-orange-A700:focus-within {
    --tw-ring-offset-color: #FF6D00
  }

  .print\:focus-within\:ring-offset-deep-orange-50:focus-within {
    --tw-ring-offset-color: #FBE9E7
  }

  .print\:focus-within\:ring-offset-deep-orange-100:focus-within {
    --tw-ring-offset-color: #FFCCBC
  }

  .print\:focus-within\:ring-offset-deep-orange-200:focus-within {
    --tw-ring-offset-color: #FFAB91
  }

  .print\:focus-within\:ring-offset-deep-orange-300:focus-within {
    --tw-ring-offset-color: #FF8A65
  }

  .print\:focus-within\:ring-offset-deep-orange-400:focus-within {
    --tw-ring-offset-color: #FF7043
  }

  .print\:focus-within\:ring-offset-deep-orange-500:focus-within {
    --tw-ring-offset-color: #FF5722
  }

  .print\:focus-within\:ring-offset-deep-orange-600:focus-within {
    --tw-ring-offset-color: #F4511E
  }

  .print\:focus-within\:ring-offset-deep-orange-700:focus-within {
    --tw-ring-offset-color: #E64A19
  }

  .print\:focus-within\:ring-offset-deep-orange-800:focus-within {
    --tw-ring-offset-color: #D84315
  }

  .print\:focus-within\:ring-offset-deep-orange-900:focus-within {
    --tw-ring-offset-color: #BF360C
  }

  .print\:focus-within\:ring-offset-deep-orange:focus-within {
    --tw-ring-offset-color: #FF5722
  }

  .print\:focus-within\:ring-offset-deep-orange-A100:focus-within {
    --tw-ring-offset-color: #FF9E80
  }

  .print\:focus-within\:ring-offset-deep-orange-A200:focus-within {
    --tw-ring-offset-color: #FF6E40
  }

  .print\:focus-within\:ring-offset-deep-orange-A400:focus-within {
    --tw-ring-offset-color: #FF3D00
  }

  .print\:focus-within\:ring-offset-deep-orange-A700:focus-within {
    --tw-ring-offset-color: #DD2C00
  }

  .print\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #FFFDE7
  }

  .print\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #FFF9C4
  }

  .print\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #FFF59D
  }

  .print\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #FFF176
  }

  .print\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #FFEE58
  }

  .print\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #FFEB3B
  }

  .print\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #FDD835
  }

  .print\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #FBC02D
  }

  .print\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #F9A825
  }

  .print\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #F57F17
  }

  .print\:focus-within\:ring-offset-yellow:focus-within {
    --tw-ring-offset-color: #FFEB3B
  }

  .print\:focus-within\:ring-offset-yellow-A100:focus-within {
    --tw-ring-offset-color: #FFFF8D
  }

  .print\:focus-within\:ring-offset-yellow-A200:focus-within {
    --tw-ring-offset-color: #FFFF00
  }

  .print\:focus-within\:ring-offset-yellow-A400:focus-within {
    --tw-ring-offset-color: #FFEA00
  }

  .print\:focus-within\:ring-offset-yellow-A700:focus-within {
    --tw-ring-offset-color: #FFD600
  }

  .print\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #E8F5E9
  }

  .print\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #C8E6C9
  }

  .print\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #A5D6A7
  }

  .print\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #81C784
  }

  .print\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #66BB6A
  }

  .print\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #4CAF50
  }

  .print\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #43A047
  }

  .print\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #388E3C
  }

  .print\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #2E7D32
  }

  .print\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #1B5E20
  }

  .print\:focus-within\:ring-offset-green:focus-within {
    --tw-ring-offset-color: #4CAF50
  }

  .print\:focus-within\:ring-offset-green-A100:focus-within {
    --tw-ring-offset-color: #B9F6CA
  }

  .print\:focus-within\:ring-offset-green-A200:focus-within {
    --tw-ring-offset-color: #69F0AE
  }

  .print\:focus-within\:ring-offset-green-A400:focus-within {
    --tw-ring-offset-color: #00E676
  }

  .print\:focus-within\:ring-offset-green-A700:focus-within {
    --tw-ring-offset-color: #00C853
  }

  .print\:focus-within\:ring-offset-light-green-50:focus-within {
    --tw-ring-offset-color: #F1F8E9
  }

  .print\:focus-within\:ring-offset-light-green-100:focus-within {
    --tw-ring-offset-color: #DCEDC8
  }

  .print\:focus-within\:ring-offset-light-green-200:focus-within {
    --tw-ring-offset-color: #C5E1A5
  }

  .print\:focus-within\:ring-offset-light-green-300:focus-within {
    --tw-ring-offset-color: #AED581
  }

  .print\:focus-within\:ring-offset-light-green-400:focus-within {
    --tw-ring-offset-color: #9CCC65
  }

  .print\:focus-within\:ring-offset-light-green-500:focus-within {
    --tw-ring-offset-color: #8BC34A
  }

  .print\:focus-within\:ring-offset-light-green-600:focus-within {
    --tw-ring-offset-color: #7CB342
  }

  .print\:focus-within\:ring-offset-light-green-700:focus-within {
    --tw-ring-offset-color: #689F38
  }

  .print\:focus-within\:ring-offset-light-green-800:focus-within {
    --tw-ring-offset-color: #558B2F
  }

  .print\:focus-within\:ring-offset-light-green-900:focus-within {
    --tw-ring-offset-color: #33691E
  }

  .print\:focus-within\:ring-offset-light-green:focus-within {
    --tw-ring-offset-color: #8BC34A
  }

  .print\:focus-within\:ring-offset-light-green-A100:focus-within {
    --tw-ring-offset-color: #CCFF90
  }

  .print\:focus-within\:ring-offset-light-green-A200:focus-within {
    --tw-ring-offset-color: #B2FF59
  }

  .print\:focus-within\:ring-offset-light-green-A400:focus-within {
    --tw-ring-offset-color: #76FF03
  }

  .print\:focus-within\:ring-offset-light-green-A700:focus-within {
    --tw-ring-offset-color: #64DD17
  }

  .print\:focus-within\:ring-offset-teal-50:focus-within {
    --tw-ring-offset-color: #E0F2F1
  }

  .print\:focus-within\:ring-offset-teal-100:focus-within {
    --tw-ring-offset-color: #B2DFDB
  }

  .print\:focus-within\:ring-offset-teal-200:focus-within {
    --tw-ring-offset-color: #80CBC4
  }

  .print\:focus-within\:ring-offset-teal-300:focus-within {
    --tw-ring-offset-color: #4DB6AC
  }

  .print\:focus-within\:ring-offset-teal-400:focus-within {
    --tw-ring-offset-color: #26A69A
  }

  .print\:focus-within\:ring-offset-teal-500:focus-within {
    --tw-ring-offset-color: #009688
  }

  .print\:focus-within\:ring-offset-teal-600:focus-within {
    --tw-ring-offset-color: #00897B
  }

  .print\:focus-within\:ring-offset-teal-700:focus-within {
    --tw-ring-offset-color: #00796B
  }

  .print\:focus-within\:ring-offset-teal-800:focus-within {
    --tw-ring-offset-color: #00695C
  }

  .print\:focus-within\:ring-offset-teal-900:focus-within {
    --tw-ring-offset-color: #004D40
  }

  .print\:focus-within\:ring-offset-teal:focus-within {
    --tw-ring-offset-color: #009688
  }

  .print\:focus-within\:ring-offset-teal-A100:focus-within {
    --tw-ring-offset-color: #A7FFEB
  }

  .print\:focus-within\:ring-offset-teal-A200:focus-within {
    --tw-ring-offset-color: #64FFDA
  }

  .print\:focus-within\:ring-offset-teal-A400:focus-within {
    --tw-ring-offset-color: #1DE9B6
  }

  .print\:focus-within\:ring-offset-teal-A700:focus-within {
    --tw-ring-offset-color: #00BFA5
  }

  .print\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #E3F2FD
  }

  .print\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #BBDEFB
  }

  .print\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #90CAF9
  }

  .print\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #64B5F6
  }

  .print\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #42A5F5
  }

  .print\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #2196F3
  }

  .print\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #1E88E5
  }

  .print\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1976D2
  }

  .print\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1565C0
  }

  .print\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #0D47A1
  }

  .print\:focus-within\:ring-offset-blue:focus-within {
    --tw-ring-offset-color: #2196F3
  }

  .print\:focus-within\:ring-offset-blue-A100:focus-within {
    --tw-ring-offset-color: #82B1FF
  }

  .print\:focus-within\:ring-offset-blue-A200:focus-within {
    --tw-ring-offset-color: #448AFF
  }

  .print\:focus-within\:ring-offset-blue-A400:focus-within {
    --tw-ring-offset-color: #2979FF
  }

  .print\:focus-within\:ring-offset-blue-A700:focus-within {
    --tw-ring-offset-color: #2962FF
  }

  .print\:focus-within\:ring-offset-light-blue-50:focus-within {
    --tw-ring-offset-color: #E1F5FE
  }

  .print\:focus-within\:ring-offset-light-blue-100:focus-within {
    --tw-ring-offset-color: #B3E5FC
  }

  .print\:focus-within\:ring-offset-light-blue-200:focus-within {
    --tw-ring-offset-color: #81D4FA
  }

  .print\:focus-within\:ring-offset-light-blue-300:focus-within {
    --tw-ring-offset-color: #4FC3F7
  }

  .print\:focus-within\:ring-offset-light-blue-400:focus-within {
    --tw-ring-offset-color: #29B6F6
  }

  .print\:focus-within\:ring-offset-light-blue-500:focus-within {
    --tw-ring-offset-color: #03A9F4
  }

  .print\:focus-within\:ring-offset-light-blue-600:focus-within {
    --tw-ring-offset-color: #039BE5
  }

  .print\:focus-within\:ring-offset-light-blue-700:focus-within {
    --tw-ring-offset-color: #0288D1
  }

  .print\:focus-within\:ring-offset-light-blue-800:focus-within {
    --tw-ring-offset-color: #0277BD
  }

  .print\:focus-within\:ring-offset-light-blue-900:focus-within {
    --tw-ring-offset-color: #01579B
  }

  .print\:focus-within\:ring-offset-light-blue:focus-within {
    --tw-ring-offset-color: #03A9F4
  }

  .print\:focus-within\:ring-offset-light-blue-A100:focus-within {
    --tw-ring-offset-color: #80D8FF
  }

  .print\:focus-within\:ring-offset-light-blue-A200:focus-within {
    --tw-ring-offset-color: #40C4FF
  }

  .print\:focus-within\:ring-offset-light-blue-A400:focus-within {
    --tw-ring-offset-color: #00B0FF
  }

  .print\:focus-within\:ring-offset-light-blue-A700:focus-within {
    --tw-ring-offset-color: #0091EA
  }

  .print\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #E8EAF6
  }

  .print\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #C5CAE9
  }

  .print\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #9FA8DA
  }

  .print\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #7986CB
  }

  .print\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #5C6BC0
  }

  .print\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #3F51B5
  }

  .print\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #3949AB
  }

  .print\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #303F9F
  }

  .print\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #283593
  }

  .print\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #1A237E
  }

  .print\:focus-within\:ring-offset-indigo:focus-within {
    --tw-ring-offset-color: #3F51B5
  }

  .print\:focus-within\:ring-offset-indigo-A100:focus-within {
    --tw-ring-offset-color: #8C9EFF
  }

  .print\:focus-within\:ring-offset-indigo-A200:focus-within {
    --tw-ring-offset-color: #536DFE
  }

  .print\:focus-within\:ring-offset-indigo-A400:focus-within {
    --tw-ring-offset-color: #3D5AFE
  }

  .print\:focus-within\:ring-offset-indigo-A700:focus-within {
    --tw-ring-offset-color: #304FFE
  }

  .print\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #F3E5F5
  }

  .print\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #E1BEE7
  }

  .print\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #CE93D8
  }

  .print\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #BA68C8
  }

  .print\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #AB47BC
  }

  .print\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #9C27B0
  }

  .print\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #8E24AA
  }

  .print\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #7B1FA2
  }

  .print\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #6A1B9A
  }

  .print\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4A148C
  }

  .print\:focus-within\:ring-offset-purple:focus-within {
    --tw-ring-offset-color: #9C27B0
  }

  .print\:focus-within\:ring-offset-purple-A100:focus-within {
    --tw-ring-offset-color: #EA80FC
  }

  .print\:focus-within\:ring-offset-purple-A200:focus-within {
    --tw-ring-offset-color: #E040FB
  }

  .print\:focus-within\:ring-offset-purple-A400:focus-within {
    --tw-ring-offset-color: #D500F9
  }

  .print\:focus-within\:ring-offset-purple-A700:focus-within {
    --tw-ring-offset-color: #AA00FF
  }

  .print\:focus-within\:ring-offset-deep-purple-50:focus-within {
    --tw-ring-offset-color: #EDE7F6
  }

  .print\:focus-within\:ring-offset-deep-purple-100:focus-within {
    --tw-ring-offset-color: #D1C4E9
  }

  .print\:focus-within\:ring-offset-deep-purple-200:focus-within {
    --tw-ring-offset-color: #B39DDB
  }

  .print\:focus-within\:ring-offset-deep-purple-300:focus-within {
    --tw-ring-offset-color: #9575CD
  }

  .print\:focus-within\:ring-offset-deep-purple-400:focus-within {
    --tw-ring-offset-color: #7E57C2
  }

  .print\:focus-within\:ring-offset-deep-purple-500:focus-within {
    --tw-ring-offset-color: #673AB7
  }

  .print\:focus-within\:ring-offset-deep-purple-600:focus-within {
    --tw-ring-offset-color: #5E35B1
  }

  .print\:focus-within\:ring-offset-deep-purple-700:focus-within {
    --tw-ring-offset-color: #512DA8
  }

  .print\:focus-within\:ring-offset-deep-purple-800:focus-within {
    --tw-ring-offset-color: #4527A0
  }

  .print\:focus-within\:ring-offset-deep-purple-900:focus-within {
    --tw-ring-offset-color: #311B92
  }

  .print\:focus-within\:ring-offset-deep-purple:focus-within {
    --tw-ring-offset-color: #673AB7
  }

  .print\:focus-within\:ring-offset-deep-purple-A100:focus-within {
    --tw-ring-offset-color: #B388FF
  }

  .print\:focus-within\:ring-offset-deep-purple-A200:focus-within {
    --tw-ring-offset-color: #7C4DFF
  }

  .print\:focus-within\:ring-offset-deep-purple-A400:focus-within {
    --tw-ring-offset-color: #651FFF
  }

  .print\:focus-within\:ring-offset-deep-purple-A700:focus-within {
    --tw-ring-offset-color: #6200EA
  }

  .print\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #FCE4EC
  }

  .print\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #F8BBD0
  }

  .print\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #F48FB1
  }

  .print\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #F06292
  }

  .print\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #EC407A
  }

  .print\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #E91E63
  }

  .print\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #D81B60
  }

  .print\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #C2185B
  }

  .print\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #AD1457
  }

  .print\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #880E4F
  }

  .print\:focus-within\:ring-offset-pink:focus-within {
    --tw-ring-offset-color: #E91E63
  }

  .print\:focus-within\:ring-offset-pink-A100:focus-within {
    --tw-ring-offset-color: #FF80AB
  }

  .print\:focus-within\:ring-offset-pink-A200:focus-within {
    --tw-ring-offset-color: #FF4081
  }

  .print\:focus-within\:ring-offset-pink-A400:focus-within {
    --tw-ring-offset-color: #F50057
  }

  .print\:focus-within\:ring-offset-pink-A700:focus-within {
    --tw-ring-offset-color: #C51162
  }

  .print\:focus-within\:ring-offset-lime-50:focus-within {
    --tw-ring-offset-color: #F9FBE7
  }

  .print\:focus-within\:ring-offset-lime-100:focus-within {
    --tw-ring-offset-color: #F0F4C3
  }

  .print\:focus-within\:ring-offset-lime-200:focus-within {
    --tw-ring-offset-color: #E6EE9C
  }

  .print\:focus-within\:ring-offset-lime-300:focus-within {
    --tw-ring-offset-color: #DCE775
  }

  .print\:focus-within\:ring-offset-lime-400:focus-within {
    --tw-ring-offset-color: #D4E157
  }

  .print\:focus-within\:ring-offset-lime-500:focus-within {
    --tw-ring-offset-color: #CDDC39
  }

  .print\:focus-within\:ring-offset-lime-600:focus-within {
    --tw-ring-offset-color: #C0CA33
  }

  .print\:focus-within\:ring-offset-lime-700:focus-within {
    --tw-ring-offset-color: #AFB42B
  }

  .print\:focus-within\:ring-offset-lime-800:focus-within {
    --tw-ring-offset-color: #9E9D24
  }

  .print\:focus-within\:ring-offset-lime-900:focus-within {
    --tw-ring-offset-color: #827717
  }

  .print\:focus-within\:ring-offset-lime:focus-within {
    --tw-ring-offset-color: #CDDC39
  }

  .print\:focus-within\:ring-offset-lime-A100:focus-within {
    --tw-ring-offset-color: #F4FF81
  }

  .print\:focus-within\:ring-offset-lime-A200:focus-within {
    --tw-ring-offset-color: #EEFF41
  }

  .print\:focus-within\:ring-offset-lime-A400:focus-within {
    --tw-ring-offset-color: #C6FF00
  }

  .print\:focus-within\:ring-offset-lime-A700:focus-within {
    --tw-ring-offset-color: #AEEA00
  }

  .print\:focus-within\:ring-offset-amber-50:focus-within {
    --tw-ring-offset-color: #FFF8E1
  }

  .print\:focus-within\:ring-offset-amber-100:focus-within {
    --tw-ring-offset-color: #FFECB3
  }

  .print\:focus-within\:ring-offset-amber-200:focus-within {
    --tw-ring-offset-color: #FFE082
  }

  .print\:focus-within\:ring-offset-amber-300:focus-within {
    --tw-ring-offset-color: #FFD54F
  }

  .print\:focus-within\:ring-offset-amber-400:focus-within {
    --tw-ring-offset-color: #FFCA28
  }

  .print\:focus-within\:ring-offset-amber-500:focus-within {
    --tw-ring-offset-color: #FFC107
  }

  .print\:focus-within\:ring-offset-amber-600:focus-within {
    --tw-ring-offset-color: #FFB300
  }

  .print\:focus-within\:ring-offset-amber-700:focus-within {
    --tw-ring-offset-color: #FFA000
  }

  .print\:focus-within\:ring-offset-amber-800:focus-within {
    --tw-ring-offset-color: #FF8F00
  }

  .print\:focus-within\:ring-offset-amber-900:focus-within {
    --tw-ring-offset-color: #FF6F00
  }

  .print\:focus-within\:ring-offset-amber:focus-within {
    --tw-ring-offset-color: #FFC107
  }

  .print\:focus-within\:ring-offset-amber-A100:focus-within {
    --tw-ring-offset-color: #FFE57F
  }

  .print\:focus-within\:ring-offset-amber-A200:focus-within {
    --tw-ring-offset-color: #FFD740
  }

  .print\:focus-within\:ring-offset-amber-A400:focus-within {
    --tw-ring-offset-color: #FFC400
  }

  .print\:focus-within\:ring-offset-amber-A700:focus-within {
    --tw-ring-offset-color: #FFAB00
  }

  .print\:focus-within\:ring-offset-brown-50:focus-within {
    --tw-ring-offset-color: #EFEBE9
  }

  .print\:focus-within\:ring-offset-brown-100:focus-within {
    --tw-ring-offset-color: #D7CCC8
  }

  .print\:focus-within\:ring-offset-brown-200:focus-within {
    --tw-ring-offset-color: #BCAAA4
  }

  .print\:focus-within\:ring-offset-brown-300:focus-within {
    --tw-ring-offset-color: #A1887F
  }

  .print\:focus-within\:ring-offset-brown-400:focus-within {
    --tw-ring-offset-color: #8D6E63
  }

  .print\:focus-within\:ring-offset-brown-500:focus-within {
    --tw-ring-offset-color: #795548
  }

  .print\:focus-within\:ring-offset-brown-600:focus-within {
    --tw-ring-offset-color: #6D4C41
  }

  .print\:focus-within\:ring-offset-brown-700:focus-within {
    --tw-ring-offset-color: #5D4037
  }

  .print\:focus-within\:ring-offset-brown-800:focus-within {
    --tw-ring-offset-color: #4E342E
  }

  .print\:focus-within\:ring-offset-brown-900:focus-within {
    --tw-ring-offset-color: #3E2723
  }

  .print\:focus-within\:ring-offset-brown:focus-within {
    --tw-ring-offset-color: #795548
  }

  .print\:focus-within\:ring-offset-brown-A100:focus-within {
    --tw-ring-offset-color: #D7CCC8
  }

  .print\:focus-within\:ring-offset-brown-A200:focus-within {
    --tw-ring-offset-color: #BCAAA4
  }

  .print\:focus-within\:ring-offset-brown-A400:focus-within {
    --tw-ring-offset-color: #8D6E63
  }

  .print\:focus-within\:ring-offset-brown-A700:focus-within {
    --tw-ring-offset-color: #5D4037
  }

  .print\:focus-within\:ring-offset-blue-gray-50:focus-within {
    --tw-ring-offset-color: #ECEFF1
  }

  .print\:focus-within\:ring-offset-blue-gray-100:focus-within {
    --tw-ring-offset-color: #CFD8DC
  }

  .print\:focus-within\:ring-offset-blue-gray-200:focus-within {
    --tw-ring-offset-color: #B0BEC5
  }

  .print\:focus-within\:ring-offset-blue-gray-300:focus-within {
    --tw-ring-offset-color: #90A4AE
  }

  .print\:focus-within\:ring-offset-blue-gray-400:focus-within {
    --tw-ring-offset-color: #78909C
  }

  .print\:focus-within\:ring-offset-blue-gray-500:focus-within {
    --tw-ring-offset-color: #607D8B
  }

  .print\:focus-within\:ring-offset-blue-gray-600:focus-within {
    --tw-ring-offset-color: #546E7A
  }

  .print\:focus-within\:ring-offset-blue-gray-700:focus-within {
    --tw-ring-offset-color: #455A64
  }

  .print\:focus-within\:ring-offset-blue-gray-800:focus-within {
    --tw-ring-offset-color: #37474F
  }

  .print\:focus-within\:ring-offset-blue-gray-900:focus-within {
    --tw-ring-offset-color: #263238
  }

  .print\:focus-within\:ring-offset-blue-gray:focus-within {
    --tw-ring-offset-color: #607D8B
  }

  .print\:focus-within\:ring-offset-blue-gray-A100:focus-within {
    --tw-ring-offset-color: #CFD8DC
  }

  .print\:focus-within\:ring-offset-blue-gray-A200:focus-within {
    --tw-ring-offset-color: #B0BEC5
  }

  .print\:focus-within\:ring-offset-blue-gray-A400:focus-within {
    --tw-ring-offset-color: #78909C
  }

  .print\:focus-within\:ring-offset-blue-gray-A700:focus-within {
    --tw-ring-offset-color: #455A64
  }

  .print\:focus-within\:ring-offset-cyan-50:focus-within {
    --tw-ring-offset-color: #E0F7FA
  }

  .print\:focus-within\:ring-offset-cyan-100:focus-within {
    --tw-ring-offset-color: #B2EBF2
  }

  .print\:focus-within\:ring-offset-cyan-200:focus-within {
    --tw-ring-offset-color: #80DEEA
  }

  .print\:focus-within\:ring-offset-cyan-300:focus-within {
    --tw-ring-offset-color: #4DD0E1
  }

  .print\:focus-within\:ring-offset-cyan-400:focus-within {
    --tw-ring-offset-color: #26C6DA
  }

  .print\:focus-within\:ring-offset-cyan-500:focus-within {
    --tw-ring-offset-color: #00BCD4
  }

  .print\:focus-within\:ring-offset-cyan-600:focus-within {
    --tw-ring-offset-color: #00ACC1
  }

  .print\:focus-within\:ring-offset-cyan-700:focus-within {
    --tw-ring-offset-color: #0097A7
  }

  .print\:focus-within\:ring-offset-cyan-800:focus-within {
    --tw-ring-offset-color: #00838F
  }

  .print\:focus-within\:ring-offset-cyan-900:focus-within {
    --tw-ring-offset-color: #006064
  }

  .print\:focus-within\:ring-offset-cyan:focus-within {
    --tw-ring-offset-color: #00BCD4
  }

  .print\:focus-within\:ring-offset-cyan-A100:focus-within {
    --tw-ring-offset-color: #84FFFF
  }

  .print\:focus-within\:ring-offset-cyan-A200:focus-within {
    --tw-ring-offset-color: #18FFFF
  }

  .print\:focus-within\:ring-offset-cyan-A400:focus-within {
    --tw-ring-offset-color: #00E5FF
  }

  .print\:focus-within\:ring-offset-cyan-A700:focus-within {
    --tw-ring-offset-color: #00B8D4
  }

  .print\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent
  }

  .print\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor
  }

  .print\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #22292F
  }

  .print\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff
  }

  .print\:focus\:ring-offset-grey-50:focus {
    --tw-ring-offset-color: #FAFAFA
  }

  .print\:focus\:ring-offset-grey-100:focus {
    --tw-ring-offset-color: #F5F5F5
  }

  .print\:focus\:ring-offset-grey-200:focus {
    --tw-ring-offset-color: #EEEEEE
  }

  .print\:focus\:ring-offset-grey-300:focus {
    --tw-ring-offset-color: #E0E0E0
  }

  .print\:focus\:ring-offset-grey-400:focus {
    --tw-ring-offset-color: #BDBDBD
  }

  .print\:focus\:ring-offset-grey-500:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .print\:focus\:ring-offset-grey-600:focus {
    --tw-ring-offset-color: #757575
  }

  .print\:focus\:ring-offset-grey-700:focus {
    --tw-ring-offset-color: #616161
  }

  .print\:focus\:ring-offset-grey-800:focus {
    --tw-ring-offset-color: #424242
  }

  .print\:focus\:ring-offset-grey-900:focus {
    --tw-ring-offset-color: #212121
  }

  .print\:focus\:ring-offset-grey:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .print\:focus\:ring-offset-grey-A100:focus {
    --tw-ring-offset-color: #D5D5D5
  }

  .print\:focus\:ring-offset-grey-A200:focus {
    --tw-ring-offset-color: #AAAAAA
  }

  .print\:focus\:ring-offset-grey-A400:focus {
    --tw-ring-offset-color: #303030
  }

  .print\:focus\:ring-offset-grey-A700:focus {
    --tw-ring-offset-color: #616161
  }

  .print\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #FAFAFA
  }

  .print\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #F5F5F5
  }

  .print\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #EEEEEE
  }

  .print\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #E0E0E0
  }

  .print\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #BDBDBD
  }

  .print\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .print\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #757575
  }

  .print\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #616161
  }

  .print\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #424242
  }

  .print\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #212121
  }

  .print\:focus\:ring-offset-gray:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .print\:focus\:ring-offset-gray-hover:focus {
    --tw-ring-offset-color: rgba(0, 0, 0, 0.04)
  }

  .print\:focus\:ring-offset-gray-A100:focus {
    --tw-ring-offset-color: #D5D5D5
  }

  .print\:focus\:ring-offset-gray-A200:focus {
    --tw-ring-offset-color: #AAAAAA
  }

  .print\:focus\:ring-offset-gray-A400:focus {
    --tw-ring-offset-color: #303030
  }

  .print\:focus\:ring-offset-gray-A700:focus {
    --tw-ring-offset-color: #616161
  }

  .print\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #FFEBEE
  }

  .print\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #FFCDD2
  }

  .print\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #EF9A9A
  }

  .print\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #E57373
  }

  .print\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #EF5350
  }

  .print\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #F44336
  }

  .print\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #E53935
  }

  .print\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #D32F2F
  }

  .print\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #C62828
  }

  .print\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #B71C1C
  }

  .print\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: #F44336
  }

  .print\:focus\:ring-offset-red-A100:focus {
    --tw-ring-offset-color: #FF8A80
  }

  .print\:focus\:ring-offset-red-A200:focus {
    --tw-ring-offset-color: #FF5252
  }

  .print\:focus\:ring-offset-red-A400:focus {
    --tw-ring-offset-color: #FF1744
  }

  .print\:focus\:ring-offset-red-A700:focus {
    --tw-ring-offset-color: #D50000
  }

  .print\:focus\:ring-offset-orange-50:focus {
    --tw-ring-offset-color: #FFF3E0
  }

  .print\:focus\:ring-offset-orange-100:focus {
    --tw-ring-offset-color: #FFE0B2
  }

  .print\:focus\:ring-offset-orange-200:focus {
    --tw-ring-offset-color: #FFCC80
  }

  .print\:focus\:ring-offset-orange-300:focus {
    --tw-ring-offset-color: #FFB74D
  }

  .print\:focus\:ring-offset-orange-400:focus {
    --tw-ring-offset-color: #FFA726
  }

  .print\:focus\:ring-offset-orange-500:focus {
    --tw-ring-offset-color: #FF9800
  }

  .print\:focus\:ring-offset-orange-600:focus {
    --tw-ring-offset-color: #FB8C00
  }

  .print\:focus\:ring-offset-orange-700:focus {
    --tw-ring-offset-color: #F57C00
  }

  .print\:focus\:ring-offset-orange-800:focus {
    --tw-ring-offset-color: #EF6C00
  }

  .print\:focus\:ring-offset-orange-900:focus {
    --tw-ring-offset-color: #E65100
  }

  .print\:focus\:ring-offset-orange:focus {
    --tw-ring-offset-color: #FF9800
  }

  .print\:focus\:ring-offset-orange-A100:focus {
    --tw-ring-offset-color: #FFD180
  }

  .print\:focus\:ring-offset-orange-A200:focus {
    --tw-ring-offset-color: #FFAB40
  }

  .print\:focus\:ring-offset-orange-A400:focus {
    --tw-ring-offset-color: #FF9100
  }

  .print\:focus\:ring-offset-orange-A700:focus {
    --tw-ring-offset-color: #FF6D00
  }

  .print\:focus\:ring-offset-deep-orange-50:focus {
    --tw-ring-offset-color: #FBE9E7
  }

  .print\:focus\:ring-offset-deep-orange-100:focus {
    --tw-ring-offset-color: #FFCCBC
  }

  .print\:focus\:ring-offset-deep-orange-200:focus {
    --tw-ring-offset-color: #FFAB91
  }

  .print\:focus\:ring-offset-deep-orange-300:focus {
    --tw-ring-offset-color: #FF8A65
  }

  .print\:focus\:ring-offset-deep-orange-400:focus {
    --tw-ring-offset-color: #FF7043
  }

  .print\:focus\:ring-offset-deep-orange-500:focus {
    --tw-ring-offset-color: #FF5722
  }

  .print\:focus\:ring-offset-deep-orange-600:focus {
    --tw-ring-offset-color: #F4511E
  }

  .print\:focus\:ring-offset-deep-orange-700:focus {
    --tw-ring-offset-color: #E64A19
  }

  .print\:focus\:ring-offset-deep-orange-800:focus {
    --tw-ring-offset-color: #D84315
  }

  .print\:focus\:ring-offset-deep-orange-900:focus {
    --tw-ring-offset-color: #BF360C
  }

  .print\:focus\:ring-offset-deep-orange:focus {
    --tw-ring-offset-color: #FF5722
  }

  .print\:focus\:ring-offset-deep-orange-A100:focus {
    --tw-ring-offset-color: #FF9E80
  }

  .print\:focus\:ring-offset-deep-orange-A200:focus {
    --tw-ring-offset-color: #FF6E40
  }

  .print\:focus\:ring-offset-deep-orange-A400:focus {
    --tw-ring-offset-color: #FF3D00
  }

  .print\:focus\:ring-offset-deep-orange-A700:focus {
    --tw-ring-offset-color: #DD2C00
  }

  .print\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #FFFDE7
  }

  .print\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #FFF9C4
  }

  .print\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #FFF59D
  }

  .print\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #FFF176
  }

  .print\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #FFEE58
  }

  .print\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #FFEB3B
  }

  .print\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #FDD835
  }

  .print\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #FBC02D
  }

  .print\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #F9A825
  }

  .print\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #F57F17
  }

  .print\:focus\:ring-offset-yellow:focus {
    --tw-ring-offset-color: #FFEB3B
  }

  .print\:focus\:ring-offset-yellow-A100:focus {
    --tw-ring-offset-color: #FFFF8D
  }

  .print\:focus\:ring-offset-yellow-A200:focus {
    --tw-ring-offset-color: #FFFF00
  }

  .print\:focus\:ring-offset-yellow-A400:focus {
    --tw-ring-offset-color: #FFEA00
  }

  .print\:focus\:ring-offset-yellow-A700:focus {
    --tw-ring-offset-color: #FFD600
  }

  .print\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #E8F5E9
  }

  .print\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #C8E6C9
  }

  .print\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #A5D6A7
  }

  .print\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #81C784
  }

  .print\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #66BB6A
  }

  .print\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #4CAF50
  }

  .print\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #43A047
  }

  .print\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #388E3C
  }

  .print\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #2E7D32
  }

  .print\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #1B5E20
  }

  .print\:focus\:ring-offset-green:focus {
    --tw-ring-offset-color: #4CAF50
  }

  .print\:focus\:ring-offset-green-A100:focus {
    --tw-ring-offset-color: #B9F6CA
  }

  .print\:focus\:ring-offset-green-A200:focus {
    --tw-ring-offset-color: #69F0AE
  }

  .print\:focus\:ring-offset-green-A400:focus {
    --tw-ring-offset-color: #00E676
  }

  .print\:focus\:ring-offset-green-A700:focus {
    --tw-ring-offset-color: #00C853
  }

  .print\:focus\:ring-offset-light-green-50:focus {
    --tw-ring-offset-color: #F1F8E9
  }

  .print\:focus\:ring-offset-light-green-100:focus {
    --tw-ring-offset-color: #DCEDC8
  }

  .print\:focus\:ring-offset-light-green-200:focus {
    --tw-ring-offset-color: #C5E1A5
  }

  .print\:focus\:ring-offset-light-green-300:focus {
    --tw-ring-offset-color: #AED581
  }

  .print\:focus\:ring-offset-light-green-400:focus {
    --tw-ring-offset-color: #9CCC65
  }

  .print\:focus\:ring-offset-light-green-500:focus {
    --tw-ring-offset-color: #8BC34A
  }

  .print\:focus\:ring-offset-light-green-600:focus {
    --tw-ring-offset-color: #7CB342
  }

  .print\:focus\:ring-offset-light-green-700:focus {
    --tw-ring-offset-color: #689F38
  }

  .print\:focus\:ring-offset-light-green-800:focus {
    --tw-ring-offset-color: #558B2F
  }

  .print\:focus\:ring-offset-light-green-900:focus {
    --tw-ring-offset-color: #33691E
  }

  .print\:focus\:ring-offset-light-green:focus {
    --tw-ring-offset-color: #8BC34A
  }

  .print\:focus\:ring-offset-light-green-A100:focus {
    --tw-ring-offset-color: #CCFF90
  }

  .print\:focus\:ring-offset-light-green-A200:focus {
    --tw-ring-offset-color: #B2FF59
  }

  .print\:focus\:ring-offset-light-green-A400:focus {
    --tw-ring-offset-color: #76FF03
  }

  .print\:focus\:ring-offset-light-green-A700:focus {
    --tw-ring-offset-color: #64DD17
  }

  .print\:focus\:ring-offset-teal-50:focus {
    --tw-ring-offset-color: #E0F2F1
  }

  .print\:focus\:ring-offset-teal-100:focus {
    --tw-ring-offset-color: #B2DFDB
  }

  .print\:focus\:ring-offset-teal-200:focus {
    --tw-ring-offset-color: #80CBC4
  }

  .print\:focus\:ring-offset-teal-300:focus {
    --tw-ring-offset-color: #4DB6AC
  }

  .print\:focus\:ring-offset-teal-400:focus {
    --tw-ring-offset-color: #26A69A
  }

  .print\:focus\:ring-offset-teal-500:focus {
    --tw-ring-offset-color: #009688
  }

  .print\:focus\:ring-offset-teal-600:focus {
    --tw-ring-offset-color: #00897B
  }

  .print\:focus\:ring-offset-teal-700:focus {
    --tw-ring-offset-color: #00796B
  }

  .print\:focus\:ring-offset-teal-800:focus {
    --tw-ring-offset-color: #00695C
  }

  .print\:focus\:ring-offset-teal-900:focus {
    --tw-ring-offset-color: #004D40
  }

  .print\:focus\:ring-offset-teal:focus {
    --tw-ring-offset-color: #009688
  }

  .print\:focus\:ring-offset-teal-A100:focus {
    --tw-ring-offset-color: #A7FFEB
  }

  .print\:focus\:ring-offset-teal-A200:focus {
    --tw-ring-offset-color: #64FFDA
  }

  .print\:focus\:ring-offset-teal-A400:focus {
    --tw-ring-offset-color: #1DE9B6
  }

  .print\:focus\:ring-offset-teal-A700:focus {
    --tw-ring-offset-color: #00BFA5
  }

  .print\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #E3F2FD
  }

  .print\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #BBDEFB
  }

  .print\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #90CAF9
  }

  .print\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #64B5F6
  }

  .print\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #42A5F5
  }

  .print\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #2196F3
  }

  .print\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #1E88E5
  }

  .print\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1976D2
  }

  .print\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1565C0
  }

  .print\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #0D47A1
  }

  .print\:focus\:ring-offset-blue:focus {
    --tw-ring-offset-color: #2196F3
  }

  .print\:focus\:ring-offset-blue-A100:focus {
    --tw-ring-offset-color: #82B1FF
  }

  .print\:focus\:ring-offset-blue-A200:focus {
    --tw-ring-offset-color: #448AFF
  }

  .print\:focus\:ring-offset-blue-A400:focus {
    --tw-ring-offset-color: #2979FF
  }

  .print\:focus\:ring-offset-blue-A700:focus {
    --tw-ring-offset-color: #2962FF
  }

  .print\:focus\:ring-offset-light-blue-50:focus {
    --tw-ring-offset-color: #E1F5FE
  }

  .print\:focus\:ring-offset-light-blue-100:focus {
    --tw-ring-offset-color: #B3E5FC
  }

  .print\:focus\:ring-offset-light-blue-200:focus {
    --tw-ring-offset-color: #81D4FA
  }

  .print\:focus\:ring-offset-light-blue-300:focus {
    --tw-ring-offset-color: #4FC3F7
  }

  .print\:focus\:ring-offset-light-blue-400:focus {
    --tw-ring-offset-color: #29B6F6
  }

  .print\:focus\:ring-offset-light-blue-500:focus {
    --tw-ring-offset-color: #03A9F4
  }

  .print\:focus\:ring-offset-light-blue-600:focus {
    --tw-ring-offset-color: #039BE5
  }

  .print\:focus\:ring-offset-light-blue-700:focus {
    --tw-ring-offset-color: #0288D1
  }

  .print\:focus\:ring-offset-light-blue-800:focus {
    --tw-ring-offset-color: #0277BD
  }

  .print\:focus\:ring-offset-light-blue-900:focus {
    --tw-ring-offset-color: #01579B
  }

  .print\:focus\:ring-offset-light-blue:focus {
    --tw-ring-offset-color: #03A9F4
  }

  .print\:focus\:ring-offset-light-blue-A100:focus {
    --tw-ring-offset-color: #80D8FF
  }

  .print\:focus\:ring-offset-light-blue-A200:focus {
    --tw-ring-offset-color: #40C4FF
  }

  .print\:focus\:ring-offset-light-blue-A400:focus {
    --tw-ring-offset-color: #00B0FF
  }

  .print\:focus\:ring-offset-light-blue-A700:focus {
    --tw-ring-offset-color: #0091EA
  }

  .print\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #E8EAF6
  }

  .print\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #C5CAE9
  }

  .print\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #9FA8DA
  }

  .print\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #7986CB
  }

  .print\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #5C6BC0
  }

  .print\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #3F51B5
  }

  .print\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #3949AB
  }

  .print\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #303F9F
  }

  .print\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #283593
  }

  .print\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #1A237E
  }

  .print\:focus\:ring-offset-indigo:focus {
    --tw-ring-offset-color: #3F51B5
  }

  .print\:focus\:ring-offset-indigo-A100:focus {
    --tw-ring-offset-color: #8C9EFF
  }

  .print\:focus\:ring-offset-indigo-A200:focus {
    --tw-ring-offset-color: #536DFE
  }

  .print\:focus\:ring-offset-indigo-A400:focus {
    --tw-ring-offset-color: #3D5AFE
  }

  .print\:focus\:ring-offset-indigo-A700:focus {
    --tw-ring-offset-color: #304FFE
  }

  .print\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #F3E5F5
  }

  .print\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #E1BEE7
  }

  .print\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #CE93D8
  }

  .print\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #BA68C8
  }

  .print\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #AB47BC
  }

  .print\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #9C27B0
  }

  .print\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #8E24AA
  }

  .print\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #7B1FA2
  }

  .print\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #6A1B9A
  }

  .print\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4A148C
  }

  .print\:focus\:ring-offset-purple:focus {
    --tw-ring-offset-color: #9C27B0
  }

  .print\:focus\:ring-offset-purple-A100:focus {
    --tw-ring-offset-color: #EA80FC
  }

  .print\:focus\:ring-offset-purple-A200:focus {
    --tw-ring-offset-color: #E040FB
  }

  .print\:focus\:ring-offset-purple-A400:focus {
    --tw-ring-offset-color: #D500F9
  }

  .print\:focus\:ring-offset-purple-A700:focus {
    --tw-ring-offset-color: #AA00FF
  }

  .print\:focus\:ring-offset-deep-purple-50:focus {
    --tw-ring-offset-color: #EDE7F6
  }

  .print\:focus\:ring-offset-deep-purple-100:focus {
    --tw-ring-offset-color: #D1C4E9
  }

  .print\:focus\:ring-offset-deep-purple-200:focus {
    --tw-ring-offset-color: #B39DDB
  }

  .print\:focus\:ring-offset-deep-purple-300:focus {
    --tw-ring-offset-color: #9575CD
  }

  .print\:focus\:ring-offset-deep-purple-400:focus {
    --tw-ring-offset-color: #7E57C2
  }

  .print\:focus\:ring-offset-deep-purple-500:focus {
    --tw-ring-offset-color: #673AB7
  }

  .print\:focus\:ring-offset-deep-purple-600:focus {
    --tw-ring-offset-color: #5E35B1
  }

  .print\:focus\:ring-offset-deep-purple-700:focus {
    --tw-ring-offset-color: #512DA8
  }

  .print\:focus\:ring-offset-deep-purple-800:focus {
    --tw-ring-offset-color: #4527A0
  }

  .print\:focus\:ring-offset-deep-purple-900:focus {
    --tw-ring-offset-color: #311B92
  }

  .print\:focus\:ring-offset-deep-purple:focus {
    --tw-ring-offset-color: #673AB7
  }

  .print\:focus\:ring-offset-deep-purple-A100:focus {
    --tw-ring-offset-color: #B388FF
  }

  .print\:focus\:ring-offset-deep-purple-A200:focus {
    --tw-ring-offset-color: #7C4DFF
  }

  .print\:focus\:ring-offset-deep-purple-A400:focus {
    --tw-ring-offset-color: #651FFF
  }

  .print\:focus\:ring-offset-deep-purple-A700:focus {
    --tw-ring-offset-color: #6200EA
  }

  .print\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #FCE4EC
  }

  .print\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #F8BBD0
  }

  .print\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #F48FB1
  }

  .print\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #F06292
  }

  .print\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #EC407A
  }

  .print\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #E91E63
  }

  .print\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #D81B60
  }

  .print\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #C2185B
  }

  .print\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #AD1457
  }

  .print\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #880E4F
  }

  .print\:focus\:ring-offset-pink:focus {
    --tw-ring-offset-color: #E91E63
  }

  .print\:focus\:ring-offset-pink-A100:focus {
    --tw-ring-offset-color: #FF80AB
  }

  .print\:focus\:ring-offset-pink-A200:focus {
    --tw-ring-offset-color: #FF4081
  }

  .print\:focus\:ring-offset-pink-A400:focus {
    --tw-ring-offset-color: #F50057
  }

  .print\:focus\:ring-offset-pink-A700:focus {
    --tw-ring-offset-color: #C51162
  }

  .print\:focus\:ring-offset-lime-50:focus {
    --tw-ring-offset-color: #F9FBE7
  }

  .print\:focus\:ring-offset-lime-100:focus {
    --tw-ring-offset-color: #F0F4C3
  }

  .print\:focus\:ring-offset-lime-200:focus {
    --tw-ring-offset-color: #E6EE9C
  }

  .print\:focus\:ring-offset-lime-300:focus {
    --tw-ring-offset-color: #DCE775
  }

  .print\:focus\:ring-offset-lime-400:focus {
    --tw-ring-offset-color: #D4E157
  }

  .print\:focus\:ring-offset-lime-500:focus {
    --tw-ring-offset-color: #CDDC39
  }

  .print\:focus\:ring-offset-lime-600:focus {
    --tw-ring-offset-color: #C0CA33
  }

  .print\:focus\:ring-offset-lime-700:focus {
    --tw-ring-offset-color: #AFB42B
  }

  .print\:focus\:ring-offset-lime-800:focus {
    --tw-ring-offset-color: #9E9D24
  }

  .print\:focus\:ring-offset-lime-900:focus {
    --tw-ring-offset-color: #827717
  }

  .print\:focus\:ring-offset-lime:focus {
    --tw-ring-offset-color: #CDDC39
  }

  .print\:focus\:ring-offset-lime-A100:focus {
    --tw-ring-offset-color: #F4FF81
  }

  .print\:focus\:ring-offset-lime-A200:focus {
    --tw-ring-offset-color: #EEFF41
  }

  .print\:focus\:ring-offset-lime-A400:focus {
    --tw-ring-offset-color: #C6FF00
  }

  .print\:focus\:ring-offset-lime-A700:focus {
    --tw-ring-offset-color: #AEEA00
  }

  .print\:focus\:ring-offset-amber-50:focus {
    --tw-ring-offset-color: #FFF8E1
  }

  .print\:focus\:ring-offset-amber-100:focus {
    --tw-ring-offset-color: #FFECB3
  }

  .print\:focus\:ring-offset-amber-200:focus {
    --tw-ring-offset-color: #FFE082
  }

  .print\:focus\:ring-offset-amber-300:focus {
    --tw-ring-offset-color: #FFD54F
  }

  .print\:focus\:ring-offset-amber-400:focus {
    --tw-ring-offset-color: #FFCA28
  }

  .print\:focus\:ring-offset-amber-500:focus {
    --tw-ring-offset-color: #FFC107
  }

  .print\:focus\:ring-offset-amber-600:focus {
    --tw-ring-offset-color: #FFB300
  }

  .print\:focus\:ring-offset-amber-700:focus {
    --tw-ring-offset-color: #FFA000
  }

  .print\:focus\:ring-offset-amber-800:focus {
    --tw-ring-offset-color: #FF8F00
  }

  .print\:focus\:ring-offset-amber-900:focus {
    --tw-ring-offset-color: #FF6F00
  }

  .print\:focus\:ring-offset-amber:focus {
    --tw-ring-offset-color: #FFC107
  }

  .print\:focus\:ring-offset-amber-A100:focus {
    --tw-ring-offset-color: #FFE57F
  }

  .print\:focus\:ring-offset-amber-A200:focus {
    --tw-ring-offset-color: #FFD740
  }

  .print\:focus\:ring-offset-amber-A400:focus {
    --tw-ring-offset-color: #FFC400
  }

  .print\:focus\:ring-offset-amber-A700:focus {
    --tw-ring-offset-color: #FFAB00
  }

  .print\:focus\:ring-offset-brown-50:focus {
    --tw-ring-offset-color: #EFEBE9
  }

  .print\:focus\:ring-offset-brown-100:focus {
    --tw-ring-offset-color: #D7CCC8
  }

  .print\:focus\:ring-offset-brown-200:focus {
    --tw-ring-offset-color: #BCAAA4
  }

  .print\:focus\:ring-offset-brown-300:focus {
    --tw-ring-offset-color: #A1887F
  }

  .print\:focus\:ring-offset-brown-400:focus {
    --tw-ring-offset-color: #8D6E63
  }

  .print\:focus\:ring-offset-brown-500:focus {
    --tw-ring-offset-color: #795548
  }

  .print\:focus\:ring-offset-brown-600:focus {
    --tw-ring-offset-color: #6D4C41
  }

  .print\:focus\:ring-offset-brown-700:focus {
    --tw-ring-offset-color: #5D4037
  }

  .print\:focus\:ring-offset-brown-800:focus {
    --tw-ring-offset-color: #4E342E
  }

  .print\:focus\:ring-offset-brown-900:focus {
    --tw-ring-offset-color: #3E2723
  }

  .print\:focus\:ring-offset-brown:focus {
    --tw-ring-offset-color: #795548
  }

  .print\:focus\:ring-offset-brown-A100:focus {
    --tw-ring-offset-color: #D7CCC8
  }

  .print\:focus\:ring-offset-brown-A200:focus {
    --tw-ring-offset-color: #BCAAA4
  }

  .print\:focus\:ring-offset-brown-A400:focus {
    --tw-ring-offset-color: #8D6E63
  }

  .print\:focus\:ring-offset-brown-A700:focus {
    --tw-ring-offset-color: #5D4037
  }

  .print\:focus\:ring-offset-blue-gray-50:focus {
    --tw-ring-offset-color: #ECEFF1
  }

  .print\:focus\:ring-offset-blue-gray-100:focus {
    --tw-ring-offset-color: #CFD8DC
  }

  .print\:focus\:ring-offset-blue-gray-200:focus {
    --tw-ring-offset-color: #B0BEC5
  }

  .print\:focus\:ring-offset-blue-gray-300:focus {
    --tw-ring-offset-color: #90A4AE
  }

  .print\:focus\:ring-offset-blue-gray-400:focus {
    --tw-ring-offset-color: #78909C
  }

  .print\:focus\:ring-offset-blue-gray-500:focus {
    --tw-ring-offset-color: #607D8B
  }

  .print\:focus\:ring-offset-blue-gray-600:focus {
    --tw-ring-offset-color: #546E7A
  }

  .print\:focus\:ring-offset-blue-gray-700:focus {
    --tw-ring-offset-color: #455A64
  }

  .print\:focus\:ring-offset-blue-gray-800:focus {
    --tw-ring-offset-color: #37474F
  }

  .print\:focus\:ring-offset-blue-gray-900:focus {
    --tw-ring-offset-color: #263238
  }

  .print\:focus\:ring-offset-blue-gray:focus {
    --tw-ring-offset-color: #607D8B
  }

  .print\:focus\:ring-offset-blue-gray-A100:focus {
    --tw-ring-offset-color: #CFD8DC
  }

  .print\:focus\:ring-offset-blue-gray-A200:focus {
    --tw-ring-offset-color: #B0BEC5
  }

  .print\:focus\:ring-offset-blue-gray-A400:focus {
    --tw-ring-offset-color: #78909C
  }

  .print\:focus\:ring-offset-blue-gray-A700:focus {
    --tw-ring-offset-color: #455A64
  }

  .print\:focus\:ring-offset-cyan-50:focus {
    --tw-ring-offset-color: #E0F7FA
  }

  .print\:focus\:ring-offset-cyan-100:focus {
    --tw-ring-offset-color: #B2EBF2
  }

  .print\:focus\:ring-offset-cyan-200:focus {
    --tw-ring-offset-color: #80DEEA
  }

  .print\:focus\:ring-offset-cyan-300:focus {
    --tw-ring-offset-color: #4DD0E1
  }

  .print\:focus\:ring-offset-cyan-400:focus {
    --tw-ring-offset-color: #26C6DA
  }

  .print\:focus\:ring-offset-cyan-500:focus {
    --tw-ring-offset-color: #00BCD4
  }

  .print\:focus\:ring-offset-cyan-600:focus {
    --tw-ring-offset-color: #00ACC1
  }

  .print\:focus\:ring-offset-cyan-700:focus {
    --tw-ring-offset-color: #0097A7
  }

  .print\:focus\:ring-offset-cyan-800:focus {
    --tw-ring-offset-color: #00838F
  }

  .print\:focus\:ring-offset-cyan-900:focus {
    --tw-ring-offset-color: #006064
  }

  .print\:focus\:ring-offset-cyan:focus {
    --tw-ring-offset-color: #00BCD4
  }

  .print\:focus\:ring-offset-cyan-A100:focus {
    --tw-ring-offset-color: #84FFFF
  }

  .print\:focus\:ring-offset-cyan-A200:focus {
    --tw-ring-offset-color: #18FFFF
  }

  .print\:focus\:ring-offset-cyan-A400:focus {
    --tw-ring-offset-color: #00E5FF
  }

  .print\:focus\:ring-offset-cyan-A700:focus {
    --tw-ring-offset-color: #00B8D4
  }

  .print\:ring-offset-0 {
    --tw-ring-offset-width: 0px
  }

  .print\:ring-offset-1 {
    --tw-ring-offset-width: 1px
  }

  .print\:ring-offset-2 {
    --tw-ring-offset-width: 2px
  }

  .print\:ring-offset-4 {
    --tw-ring-offset-width: 4px
  }

  .print\:ring-offset-8 {
    --tw-ring-offset-width: 8px
  }

  .print\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px
  }

  .print\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px
  }

  .print\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px
  }

  .print\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px
  }

  .print\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px
  }

  .print\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px
  }

  .print\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px
  }

  .print\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px
  }

  .print\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px
  }

  .print\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px
  }

  .print\:ring-transparent {
    --tw-ring-color: transparent
  }

  .print\:ring-current {
    --tw-ring-color: currentColor
  }

  .print\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .print\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .print\:ring-grey-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .print\:ring-grey-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .print\:ring-grey-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .print\:ring-grey-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .print\:ring-grey-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .print\:ring-grey-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .print\:ring-grey-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .print\:ring-grey-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .print\:ring-grey-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .print\:ring-grey-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .print\:ring-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .print\:ring-grey-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .print\:ring-grey-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .print\:ring-grey-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .print\:ring-grey-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .print\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .print\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .print\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .print\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .print\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .print\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .print\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .print\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .print\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .print\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .print\:ring-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .print\:ring-gray-hover {
    --tw-ring-color: rgba(0, 0, 0, 0.04)
  }

  .print\:ring-gray-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .print\:ring-gray-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .print\:ring-gray-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .print\:ring-gray-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .print\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .print\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .print\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .print\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .print\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .print\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .print\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .print\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .print\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .print\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .print\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .print\:ring-red-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .print\:ring-red-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .print\:ring-red-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .print\:ring-red-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .print\:ring-orange-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .print\:ring-orange-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .print\:ring-orange-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .print\:ring-orange-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .print\:ring-orange-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .print\:ring-orange-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .print\:ring-orange-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .print\:ring-orange-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .print\:ring-orange-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .print\:ring-orange-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .print\:ring-orange {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .print\:ring-orange-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .print\:ring-orange-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .print\:ring-orange-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .print\:ring-orange-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .print\:ring-deep-orange-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .print\:ring-deep-orange-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .print\:ring-deep-orange-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .print\:ring-deep-orange-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .print\:ring-deep-orange-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .print\:ring-deep-orange-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .print\:ring-deep-orange-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .print\:ring-deep-orange-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .print\:ring-deep-orange-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .print\:ring-deep-orange-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .print\:ring-deep-orange {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .print\:ring-deep-orange-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .print\:ring-deep-orange-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .print\:ring-deep-orange-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .print\:ring-deep-orange-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .print\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .print\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .print\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .print\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .print\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .print\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .print\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .print\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .print\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .print\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .print\:ring-yellow {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .print\:ring-yellow-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .print\:ring-yellow-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .print\:ring-yellow-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .print\:ring-yellow-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .print\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .print\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .print\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .print\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .print\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .print\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .print\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .print\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .print\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .print\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .print\:ring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .print\:ring-green-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .print\:ring-green-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .print\:ring-green-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .print\:ring-green-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .print\:ring-light-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .print\:ring-light-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .print\:ring-light-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .print\:ring-light-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .print\:ring-light-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .print\:ring-light-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .print\:ring-light-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .print\:ring-light-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .print\:ring-light-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .print\:ring-light-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .print\:ring-light-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .print\:ring-light-green-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .print\:ring-light-green-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .print\:ring-light-green-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .print\:ring-light-green-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .print\:ring-teal-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .print\:ring-teal-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .print\:ring-teal-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .print\:ring-teal-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .print\:ring-teal-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .print\:ring-teal-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .print\:ring-teal-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .print\:ring-teal-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .print\:ring-teal-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .print\:ring-teal-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .print\:ring-teal {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .print\:ring-teal-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .print\:ring-teal-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .print\:ring-teal-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .print\:ring-teal-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .print\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .print\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .print\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .print\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .print\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .print\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .print\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .print\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .print\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .print\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .print\:ring-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .print\:ring-blue-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .print\:ring-blue-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .print\:ring-blue-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .print\:ring-blue-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .print\:ring-light-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .print\:ring-light-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .print\:ring-light-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .print\:ring-light-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .print\:ring-light-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .print\:ring-light-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .print\:ring-light-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .print\:ring-light-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .print\:ring-light-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .print\:ring-light-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .print\:ring-light-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .print\:ring-light-blue-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .print\:ring-light-blue-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .print\:ring-light-blue-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .print\:ring-light-blue-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .print\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .print\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .print\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .print\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .print\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .print\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .print\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .print\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .print\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .print\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .print\:ring-indigo {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .print\:ring-indigo-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .print\:ring-indigo-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .print\:ring-indigo-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .print\:ring-indigo-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .print\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .print\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .print\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .print\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .print\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .print\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .print\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .print\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .print\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .print\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .print\:ring-purple {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .print\:ring-purple-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .print\:ring-purple-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .print\:ring-purple-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .print\:ring-purple-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .print\:ring-deep-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .print\:ring-deep-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .print\:ring-deep-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .print\:ring-deep-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .print\:ring-deep-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .print\:ring-deep-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .print\:ring-deep-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .print\:ring-deep-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .print\:ring-deep-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .print\:ring-deep-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .print\:ring-deep-purple {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .print\:ring-deep-purple-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .print\:ring-deep-purple-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .print\:ring-deep-purple-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .print\:ring-deep-purple-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .print\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .print\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .print\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .print\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .print\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .print\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .print\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .print\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .print\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .print\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .print\:ring-pink {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .print\:ring-pink-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .print\:ring-pink-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .print\:ring-pink-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .print\:ring-pink-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .print\:ring-lime-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .print\:ring-lime-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .print\:ring-lime-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .print\:ring-lime-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .print\:ring-lime-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .print\:ring-lime-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .print\:ring-lime-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .print\:ring-lime-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .print\:ring-lime-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .print\:ring-lime-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .print\:ring-lime {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .print\:ring-lime-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .print\:ring-lime-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .print\:ring-lime-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .print\:ring-lime-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .print\:ring-amber-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .print\:ring-amber-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .print\:ring-amber-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .print\:ring-amber-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .print\:ring-amber-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .print\:ring-amber-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .print\:ring-amber-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .print\:ring-amber-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .print\:ring-amber-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .print\:ring-amber-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .print\:ring-amber {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .print\:ring-amber-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .print\:ring-amber-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .print\:ring-amber-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .print\:ring-amber-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .print\:ring-brown-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .print\:ring-brown-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .print\:ring-brown-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .print\:ring-brown-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .print\:ring-brown-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .print\:ring-brown-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .print\:ring-brown-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .print\:ring-brown-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .print\:ring-brown-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .print\:ring-brown-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .print\:ring-brown {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .print\:ring-brown-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .print\:ring-brown-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .print\:ring-brown-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .print\:ring-brown-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .print\:ring-blue-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .print\:ring-blue-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .print\:ring-blue-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .print\:ring-blue-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .print\:ring-blue-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .print\:ring-blue-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .print\:ring-blue-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .print\:ring-blue-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .print\:ring-blue-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .print\:ring-blue-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .print\:ring-blue-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .print\:ring-blue-gray-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .print\:ring-blue-gray-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .print\:ring-blue-gray-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .print\:ring-blue-gray-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .print\:ring-cyan-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .print\:ring-cyan-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .print\:ring-cyan-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .print\:ring-cyan-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .print\:ring-cyan-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .print\:ring-cyan-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .print\:ring-cyan-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .print\:ring-cyan-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .print\:ring-cyan-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .print\:ring-cyan-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .print\:ring-cyan {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .print\:ring-cyan-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .print\:ring-cyan-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .print\:ring-cyan-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .print\:ring-cyan-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent
  }

  .print\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor
  }

  .print\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-grey-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-grey-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-grey-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-grey-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-grey-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-grey-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-grey-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-grey-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-grey-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-grey-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-grey-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-grey-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-grey-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-grey-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-gray-hover:focus-within {
    --tw-ring-color: rgba(0, 0, 0, 0.04)
  }

  .print\:focus-within\:ring-gray-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-gray-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-gray-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-gray-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-red-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-red-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-red-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-red-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-orange-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-orange-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-orange-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-orange-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-orange-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-orange-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-orange-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-orange-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-orange-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-orange-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-orange:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-orange-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-orange-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-orange-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-orange-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-orange-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-orange-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-orange-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-orange-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-orange-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-orange-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-orange-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-orange-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-orange-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-orange-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-orange:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-orange-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-orange-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-orange-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-orange-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-yellow:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-yellow-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-yellow-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-yellow-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-yellow-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-green-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-green-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-green-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-green-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-green-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-green-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-green-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-green-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-teal-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-teal-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-teal-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-teal-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-teal-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-teal-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-teal-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-teal-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-teal-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-teal-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-teal:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-teal-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-teal-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-teal-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-teal-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-blue-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-blue-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-blue-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-light-blue-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-indigo:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-indigo-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-indigo-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-indigo-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-indigo-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-purple:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-purple-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-purple-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-purple-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-purple-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-purple:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-purple-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-purple-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-purple-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-deep-purple-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-pink:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-pink-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-pink-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-pink-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-pink-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-lime-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-lime-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-lime-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-lime-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-lime-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-lime-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-lime-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-lime-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-lime-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-lime-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-lime:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-lime-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-lime-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-lime-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-lime-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-amber-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-amber-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-amber-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-amber-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-amber-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-amber-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-amber-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-amber-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-amber-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-amber-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-amber:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-amber-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-amber-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-amber-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-amber-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-brown-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-brown-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-brown-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-brown-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-brown-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-brown-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-brown-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-brown-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-brown-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-brown-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-brown:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-brown-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-brown-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-brown-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-brown-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-gray-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-gray-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-gray-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-blue-gray-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-cyan-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-cyan-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-cyan-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-cyan-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-cyan-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-cyan-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-cyan-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-cyan-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-cyan-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-cyan-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-cyan:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-cyan-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-cyan-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-cyan-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .print\:focus-within\:ring-cyan-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent
  }

  .print\:focus\:ring-current:focus {
    --tw-ring-color: currentColor
  }

  .print\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-grey-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-grey-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-grey-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-grey-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-grey-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-grey-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-grey-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-grey-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-grey-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-grey-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-grey-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-grey-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-grey-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-grey-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-gray-hover:focus {
    --tw-ring-color: rgba(0, 0, 0, 0.04)
  }

  .print\:focus\:ring-gray-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-gray-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-gray-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-gray-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-red-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-red-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-red-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-red-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-orange-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-orange-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-orange-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-orange-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-orange-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-orange-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-orange-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-orange-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-orange-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-orange-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-orange:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-orange-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-orange-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-orange-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-orange-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-orange-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-orange-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-orange-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-orange-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-orange-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-orange-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-orange-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-orange-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-orange-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-orange-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-orange:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-orange-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-orange-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-orange-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-orange-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-yellow:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-yellow-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-yellow-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-yellow-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-yellow-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-green-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-green-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-green-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-green-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-green-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-green-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-green-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-green-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-teal-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-teal-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-teal-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-teal-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-teal-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-teal-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-teal-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-teal-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-teal-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-teal-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-teal:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-teal-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-teal-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-teal-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-teal-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-blue-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-blue-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-blue-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-light-blue-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-indigo:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-indigo-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-indigo-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-indigo-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-indigo-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-purple:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-purple-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-purple-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-purple-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-purple-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-purple:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-purple-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-purple-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-purple-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-deep-purple-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-pink:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-pink-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-pink-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-pink-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-pink-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-lime-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-lime-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-lime-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-lime-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-lime-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-lime-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-lime-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-lime-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-lime-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-lime-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-lime:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-lime-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-lime-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-lime-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-lime-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-amber-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-amber-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-amber-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-amber-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-amber-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-amber-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-amber-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-amber-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-amber-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-amber-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-amber:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-amber-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-amber-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-amber-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-amber-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-brown-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-brown-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-brown-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-brown-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-brown-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-brown-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-brown-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-brown-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-brown-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-brown-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-brown:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-brown-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-brown-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-brown-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-brown-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-gray-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-gray-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-gray-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-blue-gray-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-cyan-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-cyan-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-cyan-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-cyan-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-cyan-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-cyan-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-cyan-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-cyan-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-cyan-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-cyan-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-cyan:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-cyan-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-cyan-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-cyan-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .print\:focus\:ring-cyan-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .print\:ring-opacity-0 {
    --tw-ring-opacity: 0
  }

  .print\:ring-opacity-5 {
    --tw-ring-opacity: 0.05
  }

  .print\:ring-opacity-10 {
    --tw-ring-opacity: 0.1
  }

  .print\:ring-opacity-20 {
    --tw-ring-opacity: 0.2
  }

  .print\:ring-opacity-25 {
    --tw-ring-opacity: 0.25
  }

  .print\:ring-opacity-30 {
    --tw-ring-opacity: 0.3
  }

  .print\:ring-opacity-40 {
    --tw-ring-opacity: 0.4
  }

  .print\:ring-opacity-50 {
    --tw-ring-opacity: 0.5
  }

  .print\:ring-opacity-60 {
    --tw-ring-opacity: 0.6
  }

  .print\:ring-opacity-70 {
    --tw-ring-opacity: 0.7
  }

  .print\:ring-opacity-75 {
    --tw-ring-opacity: 0.75
  }

  .print\:ring-opacity-80 {
    --tw-ring-opacity: 0.8
  }

  .print\:ring-opacity-90 {
    --tw-ring-opacity: 0.9
  }

  .print\:ring-opacity-95 {
    --tw-ring-opacity: 0.95
  }

  .print\:ring-opacity-100 {
    --tw-ring-opacity: 1
  }

  .print\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0
  }

  .print\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05
  }

  .print\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1
  }

  .print\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2
  }

  .print\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25
  }

  .print\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3
  }

  .print\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4
  }

  .print\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5
  }

  .print\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6
  }

  .print\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7
  }

  .print\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75
  }

  .print\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8
  }

  .print\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9
  }

  .print\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95
  }

  .print\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1
  }

  .print\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0
  }

  .print\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05
  }

  .print\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1
  }

  .print\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2
  }

  .print\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25
  }

  .print\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3
  }

  .print\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4
  }

  .print\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5
  }

  .print\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6
  }

  .print\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7
  }

  .print\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75
  }

  .print\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8
  }

  .print\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9
  }

  .print\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95
  }

  .print\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1
  }

  .print\:fill-current {
    fill: currentColor
  }

  .print\:stroke-current {
    stroke: currentColor
  }

  .print\:stroke-0 {
    stroke-width: 0
  }

  .print\:stroke-1 {
    stroke-width: 1
  }

  .print\:stroke-2 {
    stroke-width: 2
  }

  .print\:table-auto {
    table-layout: auto
  }

  .print\:table-fixed {
    table-layout: fixed
  }

  .print\:text-left {
    text-align: left
  }

  .print\:text-center {
    text-align: center
  }

  .print\:text-right {
    text-align: right
  }

  .print\:text-justify {
    text-align: justify
  }

  [dir='ltr'] .print\:ltr\:text-left,[dir='ltr'].print\:ltr\:text-left {
    text-align: left
  }

  [dir='ltr'] .print\:ltr\:text-center,[dir='ltr'].print\:ltr\:text-center {
    text-align: center
  }

  [dir='ltr'] .print\:ltr\:text-right,[dir='ltr'].print\:ltr\:text-right {
    text-align: right
  }

  [dir='ltr'] .print\:ltr\:text-justify,[dir='ltr'].print\:ltr\:text-justify {
    text-align: justify
  }

  [dir='rtl'] .print\:rtl\:text-left,[dir='rtl'].print\:rtl\:text-left {
    text-align: left
  }

  [dir='rtl'] .print\:rtl\:text-center,[dir='rtl'].print\:rtl\:text-center {
    text-align: center
  }

  [dir='rtl'] .print\:rtl\:text-right,[dir='rtl'].print\:rtl\:text-right {
    text-align: right
  }

  [dir='rtl'] .print\:rtl\:text-justify,[dir='rtl'].print\:rtl\:text-justify {
    text-align: justify
  }

  .print\:text-transparent {
    color: transparent
  }

  .print\:text-current {
    color: currentColor
  }

  .print\:text-black {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .print\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .print\:text-grey-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .print\:text-grey-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .print\:text-grey-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .print\:text-grey-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .print\:text-grey-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .print\:text-grey-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:text-grey-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .print\:text-grey-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:text-grey-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .print\:text-grey-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .print\:text-grey {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:text-grey-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .print\:text-grey-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .print\:text-grey-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .print\:text-grey-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .print\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .print\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .print\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .print\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .print\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .print\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .print\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .print\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:text-gray-hover {
    color: rgba(0, 0, 0, 0.04)
  }

  .print\:text-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .print\:text-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .print\:text-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .print\:text-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .print\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .print\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .print\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .print\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .print\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .print\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .print\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .print\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .print\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .print\:text-red {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .print\:text-red-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .print\:text-red-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .print\:text-red-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .print\:text-red-A700 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .print\:text-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .print\:text-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .print\:text-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .print\:text-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .print\:text-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .print\:text-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .print\:text-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .print\:text-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .print\:text-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .print\:text-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .print\:text-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .print\:text-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .print\:text-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .print\:text-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .print\:text-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .print\:text-deep-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .print\:text-deep-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .print\:text-deep-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .print\:text-deep-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .print\:text-deep-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .print\:text-deep-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .print\:text-deep-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .print\:text-deep-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .print\:text-deep-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .print\:text-deep-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .print\:text-deep-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .print\:text-deep-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .print\:text-deep-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .print\:text-deep-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .print\:text-deep-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .print\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .print\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .print\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .print\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .print\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .print\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .print\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .print\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .print\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .print\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .print\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .print\:text-yellow-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .print\:text-yellow-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .print\:text-yellow-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .print\:text-yellow-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .print\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .print\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .print\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .print\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .print\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .print\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .print\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .print\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .print\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .print\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .print\:text-green {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .print\:text-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .print\:text-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .print\:text-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .print\:text-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .print\:text-light-green-50 {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .print\:text-light-green-100 {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .print\:text-light-green-200 {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .print\:text-light-green-300 {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .print\:text-light-green-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .print\:text-light-green-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .print\:text-light-green-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .print\:text-light-green-700 {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .print\:text-light-green-800 {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .print\:text-light-green-900 {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .print\:text-light-green {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .print\:text-light-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .print\:text-light-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .print\:text-light-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .print\:text-light-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .print\:text-teal-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .print\:text-teal-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .print\:text-teal-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .print\:text-teal-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .print\:text-teal-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .print\:text-teal-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .print\:text-teal-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .print\:text-teal-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .print\:text-teal-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .print\:text-teal-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .print\:text-teal {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .print\:text-teal-A100 {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .print\:text-teal-A200 {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .print\:text-teal-A400 {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .print\:text-teal-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .print\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .print\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .print\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .print\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .print\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .print\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .print\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .print\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .print\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .print\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .print\:text-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .print\:text-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .print\:text-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .print\:text-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .print\:text-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .print\:text-light-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .print\:text-light-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .print\:text-light-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .print\:text-light-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .print\:text-light-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .print\:text-light-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .print\:text-light-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .print\:text-light-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .print\:text-light-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .print\:text-light-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .print\:text-light-blue {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .print\:text-light-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .print\:text-light-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .print\:text-light-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .print\:text-light-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .print\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .print\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .print\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .print\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .print\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .print\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .print\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .print\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .print\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .print\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .print\:text-indigo {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .print\:text-indigo-A100 {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .print\:text-indigo-A200 {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .print\:text-indigo-A400 {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .print\:text-indigo-A700 {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .print\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .print\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .print\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .print\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .print\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .print\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .print\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .print\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .print\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .print\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .print\:text-purple {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .print\:text-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .print\:text-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .print\:text-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .print\:text-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .print\:text-deep-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .print\:text-deep-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .print\:text-deep-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .print\:text-deep-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .print\:text-deep-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .print\:text-deep-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .print\:text-deep-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .print\:text-deep-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .print\:text-deep-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .print\:text-deep-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .print\:text-deep-purple {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .print\:text-deep-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .print\:text-deep-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .print\:text-deep-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .print\:text-deep-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .print\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .print\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .print\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .print\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .print\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .print\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .print\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .print\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .print\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .print\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .print\:text-pink {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .print\:text-pink-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .print\:text-pink-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .print\:text-pink-A400 {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .print\:text-pink-A700 {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .print\:text-lime-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .print\:text-lime-100 {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .print\:text-lime-200 {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .print\:text-lime-300 {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .print\:text-lime-400 {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .print\:text-lime-500 {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .print\:text-lime-600 {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .print\:text-lime-700 {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .print\:text-lime-800 {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .print\:text-lime-900 {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .print\:text-lime {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .print\:text-lime-A100 {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .print\:text-lime-A200 {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .print\:text-lime-A400 {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .print\:text-lime-A700 {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .print\:text-amber-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .print\:text-amber-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .print\:text-amber-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .print\:text-amber-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .print\:text-amber-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .print\:text-amber-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .print\:text-amber-600 {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .print\:text-amber-700 {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .print\:text-amber-800 {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .print\:text-amber-900 {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .print\:text-amber {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .print\:text-amber-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .print\:text-amber-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .print\:text-amber-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .print\:text-amber-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .print\:text-brown-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .print\:text-brown-100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .print\:text-brown-200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .print\:text-brown-300 {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .print\:text-brown-400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .print\:text-brown-500 {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .print\:text-brown-600 {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .print\:text-brown-700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .print\:text-brown-800 {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .print\:text-brown-900 {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .print\:text-brown {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .print\:text-brown-A100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .print\:text-brown-A200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .print\:text-brown-A400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .print\:text-brown-A700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .print\:text-blue-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .print\:text-blue-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .print\:text-blue-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .print\:text-blue-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .print\:text-blue-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .print\:text-blue-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .print\:text-blue-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .print\:text-blue-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .print\:text-blue-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .print\:text-blue-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .print\:text-blue-gray {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .print\:text-blue-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .print\:text-blue-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .print\:text-blue-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .print\:text-blue-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .print\:text-cyan-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .print\:text-cyan-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .print\:text-cyan-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .print\:text-cyan-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .print\:text-cyan-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .print\:text-cyan-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .print\:text-cyan-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .print\:text-cyan-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .print\:text-cyan-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .print\:text-cyan-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .print\:text-cyan {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .print\:text-cyan-A100 {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .print\:text-cyan-A200 {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .print\:text-cyan-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .print\:text-cyan-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-transparent {
    color: transparent
  }

  .group:hover .print\:group-hover\:text-current {
    color: currentColor
  }

  .group:hover .print\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-grey-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-grey-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-grey-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-grey-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-grey-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-grey-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-grey-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-grey-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-grey-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-grey-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-grey {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-grey-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-grey-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-grey-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-grey-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-gray-hover {
    color: rgba(0, 0, 0, 0.04)
  }

  .group:hover .print\:group-hover\:text-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-red {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-red-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-red-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-red-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-red-A700 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-orange {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-yellow-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-yellow-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-yellow-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-yellow-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-green {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-green-50 {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-green-100 {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-green-200 {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-green-300 {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-green-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-green-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-green-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-green-700 {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-green-800 {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-green-900 {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-green {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-teal-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-teal-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-teal-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-teal-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-teal-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-teal-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-teal-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-teal-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-teal-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-teal-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-teal {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-teal-A100 {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-teal-A200 {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-teal-A400 {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-teal-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-blue {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-light-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-indigo {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-indigo-A100 {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-indigo-A200 {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-indigo-A400 {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-indigo-A700 {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-purple {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-purple {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-deep-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-pink {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-pink-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-pink-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-pink-A400 {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-pink-A700 {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-lime-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-lime-100 {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-lime-200 {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-lime-300 {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-lime-400 {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-lime-500 {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-lime-600 {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-lime-700 {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-lime-800 {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-lime-900 {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-lime {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-lime-A100 {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-lime-A200 {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-lime-A400 {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-lime-A700 {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-amber-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-amber-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-amber-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-amber-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-amber-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-amber-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-amber-600 {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-amber-700 {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-amber-800 {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-amber-900 {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-amber {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-amber-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-amber-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-amber-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-amber-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-brown-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-brown-100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-brown-200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-brown-300 {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-brown-400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-brown-500 {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-brown-600 {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-brown-700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-brown-800 {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-brown-900 {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-brown {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-brown-A100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-brown-A200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-brown-A400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-brown-A700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-gray {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-blue-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-cyan-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-cyan-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-cyan-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-cyan-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-cyan-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-cyan-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-cyan-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-cyan-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-cyan-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-cyan-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-cyan {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-cyan-A100 {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-cyan-A200 {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-cyan-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .group:hover .print\:group-hover\:text-cyan-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-transparent:focus-within {
    color: transparent
  }

  .print\:focus-within\:text-current:focus-within {
    color: currentColor
  }

  .print\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-grey-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-grey-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-grey-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-grey-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-grey-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-grey-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-grey-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-grey-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-grey-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-grey-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-grey-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-grey-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-grey-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-grey-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-gray-hover:focus-within {
    color: rgba(0, 0, 0, 0.04)
  }

  .print\:focus-within\:text-gray-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-gray-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-gray-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-gray-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-red:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-red-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-red-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-red-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-red-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-orange-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-orange-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-orange-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-orange-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-orange-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-orange-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-orange-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-orange-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-orange-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-orange-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-orange:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-orange-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-orange-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-orange-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-orange-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-orange-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-orange-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-orange-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-orange-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-orange-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-orange-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-orange-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-orange-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-orange-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-orange-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-orange:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-orange-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-orange-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-orange-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-orange-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-yellow:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-yellow-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-yellow-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-yellow-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-yellow-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-green-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-green-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-green-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-green-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-green-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-green-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-green-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-green-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-teal-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-teal-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-teal-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-teal-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-teal-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-teal-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-teal-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-teal-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-teal-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-teal-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-teal:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-teal-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-teal-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-teal-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-teal-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-blue-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-blue-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-blue-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-light-blue-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-indigo:focus-within {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-indigo-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-indigo-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-indigo-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-indigo-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-purple:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-purple-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-purple-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-purple-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-purple-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-purple:focus-within {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-purple-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-purple-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-purple-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-deep-purple-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-pink:focus-within {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-pink-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-pink-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-pink-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-pink-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-lime-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-lime-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-lime-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-lime-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-lime-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-lime-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-lime-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-lime-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-lime-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-lime-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-lime:focus-within {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-lime-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-lime-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-lime-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-lime-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-amber-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-amber-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-amber-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-amber-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-amber-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-amber-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-amber-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-amber-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-amber-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-amber-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-amber:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-amber-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-amber-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-amber-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-amber-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-brown-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-brown-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-brown-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-brown-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-brown-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-brown-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-brown-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-brown-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-brown-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-brown-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-brown:focus-within {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-brown-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-brown-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-brown-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-brown-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-gray-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-gray-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-gray-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-blue-gray-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-cyan-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-cyan-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-cyan-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-cyan-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-cyan-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-cyan-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-cyan-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-cyan-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-cyan-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-cyan-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-cyan:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-cyan-A100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-cyan-A200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-cyan-A400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .print\:focus-within\:text-cyan-A700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .print\:hover\:text-transparent:hover {
    color: transparent
  }

  .print\:hover\:text-current:hover {
    color: currentColor
  }

  .print\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .print\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-grey-50:hover {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .print\:hover\:text-grey-100:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .print\:hover\:text-grey-200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .print\:hover\:text-grey-300:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .print\:hover\:text-grey-400:hover {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .print\:hover\:text-grey-500:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:hover\:text-grey-600:hover {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .print\:hover\:text-grey-700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:hover\:text-grey-800:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .print\:hover\:text-grey-900:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .print\:hover\:text-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:hover\:text-grey-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .print\:hover\:text-grey-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .print\:hover\:text-grey-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .print\:hover\:text-grey-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .print\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .print\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .print\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .print\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .print\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .print\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .print\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .print\:hover\:text-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:hover\:text-gray-hover:hover {
    color: rgba(0, 0, 0, 0.04)
  }

  .print\:hover\:text-gray-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .print\:hover\:text-gray-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .print\:hover\:text-gray-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .print\:hover\:text-gray-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .print\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .print\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .print\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .print\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .print\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .print\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .print\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .print\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .print\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .print\:hover\:text-red:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .print\:hover\:text-red-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .print\:hover\:text-red-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .print\:hover\:text-red-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .print\:hover\:text-red-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-orange-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .print\:hover\:text-orange-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .print\:hover\:text-orange-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .print\:hover\:text-orange-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .print\:hover\:text-orange-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .print\:hover\:text-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-orange-600:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-orange-700:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-orange-800:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-orange-900:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-orange:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-orange-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .print\:hover\:text-orange-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .print\:hover\:text-orange-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-orange-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-orange-50:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-orange-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-orange-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-orange-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-orange-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-orange-600:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-orange-700:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-orange-800:hover {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-orange-900:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-orange:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-orange-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-orange-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-orange-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-orange-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .print\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .print\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .print\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .print\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .print\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .print\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .print\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .print\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .print\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .print\:hover\:text-yellow:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .print\:hover\:text-yellow-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .print\:hover\:text-yellow-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-yellow-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-yellow-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .print\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .print\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .print\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .print\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .print\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .print\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .print\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .print\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .print\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .print\:hover\:text-green:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .print\:hover\:text-green-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .print\:hover\:text-green-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .print\:hover\:text-green-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .print\:hover\:text-green-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-green:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-green-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-green-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-green-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-green-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .print\:hover\:text-teal-50:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .print\:hover\:text-teal-100:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .print\:hover\:text-teal-200:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .print\:hover\:text-teal-300:hover {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .print\:hover\:text-teal-400:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .print\:hover\:text-teal-500:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .print\:hover\:text-teal-600:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .print\:hover\:text-teal-700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .print\:hover\:text-teal-800:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .print\:hover\:text-teal-900:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .print\:hover\:text-teal:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .print\:hover\:text-teal-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .print\:hover\:text-teal-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .print\:hover\:text-teal-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .print\:hover\:text-teal-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-blue-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-blue-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-blue-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-light-blue-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .print\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .print\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .print\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .print\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .print\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .print\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .print\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .print\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .print\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .print\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .print\:hover\:text-indigo:hover {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .print\:hover\:text-indigo-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-indigo-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .print\:hover\:text-indigo-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .print\:hover\:text-indigo-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .print\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .print\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .print\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .print\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .print\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .print\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .print\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .print\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .print\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .print\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .print\:hover\:text-purple:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .print\:hover\:text-purple-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .print\:hover\:text-purple-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .print\:hover\:text-purple-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .print\:hover\:text-purple-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-purple:hover {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-purple-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-purple-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-purple-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-deep-purple-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .print\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .print\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .print\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .print\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .print\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .print\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .print\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .print\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .print\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .print\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .print\:hover\:text-pink:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .print\:hover\:text-pink-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .print\:hover\:text-pink-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .print\:hover\:text-pink-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .print\:hover\:text-pink-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .print\:hover\:text-lime-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .print\:hover\:text-lime-100:hover {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .print\:hover\:text-lime-200:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .print\:hover\:text-lime-300:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .print\:hover\:text-lime-400:hover {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .print\:hover\:text-lime-500:hover {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .print\:hover\:text-lime-600:hover {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .print\:hover\:text-lime-700:hover {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .print\:hover\:text-lime-800:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .print\:hover\:text-lime-900:hover {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .print\:hover\:text-lime:hover {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .print\:hover\:text-lime-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .print\:hover\:text-lime-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .print\:hover\:text-lime-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-lime-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-amber-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .print\:hover\:text-amber-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .print\:hover\:text-amber-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .print\:hover\:text-amber-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .print\:hover\:text-amber-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .print\:hover\:text-amber-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .print\:hover\:text-amber-600:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-amber-700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-amber-800:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-amber-900:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-amber:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .print\:hover\:text-amber-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .print\:hover\:text-amber-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .print\:hover\:text-amber-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-amber-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .print\:hover\:text-brown-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .print\:hover\:text-brown-100:hover {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .print\:hover\:text-brown-200:hover {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .print\:hover\:text-brown-300:hover {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .print\:hover\:text-brown-400:hover {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .print\:hover\:text-brown-500:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .print\:hover\:text-brown-600:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .print\:hover\:text-brown-700:hover {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .print\:hover\:text-brown-800:hover {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .print\:hover\:text-brown-900:hover {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .print\:hover\:text-brown:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .print\:hover\:text-brown-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .print\:hover\:text-brown-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .print\:hover\:text-brown-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .print\:hover\:text-brown-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-gray-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-gray-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-gray-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .print\:hover\:text-blue-gray-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .print\:hover\:text-cyan-50:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .print\:hover\:text-cyan-100:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .print\:hover\:text-cyan-200:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .print\:hover\:text-cyan-300:hover {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .print\:hover\:text-cyan-400:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .print\:hover\:text-cyan-500:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .print\:hover\:text-cyan-600:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .print\:hover\:text-cyan-700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .print\:hover\:text-cyan-800:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .print\:hover\:text-cyan-900:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .print\:hover\:text-cyan:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .print\:hover\:text-cyan-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-cyan-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-cyan-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .print\:hover\:text-cyan-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .print\:focus\:text-transparent:focus {
    color: transparent
  }

  .print\:focus\:text-current:focus {
    color: currentColor
  }

  .print\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .print\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-grey-50:focus {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .print\:focus\:text-grey-100:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .print\:focus\:text-grey-200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .print\:focus\:text-grey-300:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .print\:focus\:text-grey-400:focus {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .print\:focus\:text-grey-500:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:focus\:text-grey-600:focus {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .print\:focus\:text-grey-700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:focus\:text-grey-800:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .print\:focus\:text-grey-900:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .print\:focus\:text-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:focus\:text-grey-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .print\:focus\:text-grey-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .print\:focus\:text-grey-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .print\:focus\:text-grey-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .print\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .print\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .print\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .print\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .print\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .print\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .print\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .print\:focus\:text-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .print\:focus\:text-gray-hover:focus {
    color: rgba(0, 0, 0, 0.04)
  }

  .print\:focus\:text-gray-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .print\:focus\:text-gray-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .print\:focus\:text-gray-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .print\:focus\:text-gray-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .print\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .print\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .print\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .print\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .print\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .print\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .print\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .print\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .print\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .print\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .print\:focus\:text-red:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .print\:focus\:text-red-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .print\:focus\:text-red-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .print\:focus\:text-red-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .print\:focus\:text-red-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-orange-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .print\:focus\:text-orange-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .print\:focus\:text-orange-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .print\:focus\:text-orange-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .print\:focus\:text-orange-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .print\:focus\:text-orange-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-orange-600:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-orange-700:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-orange-800:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-orange-900:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-orange:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-orange-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .print\:focus\:text-orange-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .print\:focus\:text-orange-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-orange-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-orange-50:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-orange-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-orange-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-orange-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-orange-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-orange-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-orange-600:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-orange-700:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-orange-800:focus {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-orange-900:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-orange:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-orange-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-orange-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-orange-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-orange-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .print\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .print\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .print\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .print\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .print\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .print\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .print\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .print\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .print\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .print\:focus\:text-yellow:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .print\:focus\:text-yellow-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .print\:focus\:text-yellow-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-yellow-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-yellow-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .print\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .print\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .print\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .print\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .print\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .print\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .print\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .print\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .print\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .print\:focus\:text-green:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .print\:focus\:text-green-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .print\:focus\:text-green-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .print\:focus\:text-green-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .print\:focus\:text-green-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-green:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-green-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-green-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-green-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-green-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .print\:focus\:text-teal-50:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .print\:focus\:text-teal-100:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .print\:focus\:text-teal-200:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .print\:focus\:text-teal-300:focus {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .print\:focus\:text-teal-400:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .print\:focus\:text-teal-500:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .print\:focus\:text-teal-600:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .print\:focus\:text-teal-700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .print\:focus\:text-teal-800:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .print\:focus\:text-teal-900:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .print\:focus\:text-teal:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .print\:focus\:text-teal-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .print\:focus\:text-teal-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .print\:focus\:text-teal-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .print\:focus\:text-teal-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-blue-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-blue-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-blue-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-light-blue-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .print\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .print\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .print\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .print\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .print\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .print\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .print\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .print\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .print\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .print\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .print\:focus\:text-indigo:focus {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .print\:focus\:text-indigo-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-indigo-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .print\:focus\:text-indigo-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .print\:focus\:text-indigo-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .print\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .print\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .print\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .print\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .print\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .print\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .print\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .print\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .print\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .print\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .print\:focus\:text-purple:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .print\:focus\:text-purple-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .print\:focus\:text-purple-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .print\:focus\:text-purple-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .print\:focus\:text-purple-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-purple:focus {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-purple-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-purple-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-purple-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-deep-purple-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .print\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .print\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .print\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .print\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .print\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .print\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .print\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .print\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .print\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .print\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .print\:focus\:text-pink:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .print\:focus\:text-pink-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .print\:focus\:text-pink-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .print\:focus\:text-pink-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .print\:focus\:text-pink-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .print\:focus\:text-lime-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .print\:focus\:text-lime-100:focus {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .print\:focus\:text-lime-200:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .print\:focus\:text-lime-300:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .print\:focus\:text-lime-400:focus {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .print\:focus\:text-lime-500:focus {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .print\:focus\:text-lime-600:focus {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .print\:focus\:text-lime-700:focus {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .print\:focus\:text-lime-800:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .print\:focus\:text-lime-900:focus {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .print\:focus\:text-lime:focus {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .print\:focus\:text-lime-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .print\:focus\:text-lime-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .print\:focus\:text-lime-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-lime-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-amber-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .print\:focus\:text-amber-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .print\:focus\:text-amber-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .print\:focus\:text-amber-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .print\:focus\:text-amber-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .print\:focus\:text-amber-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .print\:focus\:text-amber-600:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-amber-700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-amber-800:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-amber-900:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-amber:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .print\:focus\:text-amber-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .print\:focus\:text-amber-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .print\:focus\:text-amber-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-amber-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .print\:focus\:text-brown-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .print\:focus\:text-brown-100:focus {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .print\:focus\:text-brown-200:focus {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .print\:focus\:text-brown-300:focus {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .print\:focus\:text-brown-400:focus {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .print\:focus\:text-brown-500:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .print\:focus\:text-brown-600:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .print\:focus\:text-brown-700:focus {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .print\:focus\:text-brown-800:focus {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .print\:focus\:text-brown-900:focus {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .print\:focus\:text-brown:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .print\:focus\:text-brown-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .print\:focus\:text-brown-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .print\:focus\:text-brown-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .print\:focus\:text-brown-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-gray-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-gray-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-gray-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .print\:focus\:text-blue-gray-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .print\:focus\:text-cyan-50:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .print\:focus\:text-cyan-100:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .print\:focus\:text-cyan-200:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .print\:focus\:text-cyan-300:focus {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .print\:focus\:text-cyan-400:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .print\:focus\:text-cyan-500:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .print\:focus\:text-cyan-600:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .print\:focus\:text-cyan-700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .print\:focus\:text-cyan-800:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .print\:focus\:text-cyan-900:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .print\:focus\:text-cyan:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .print\:focus\:text-cyan-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-cyan-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-cyan-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .print\:focus\:text-cyan-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .print\:text-opacity-0 {
    --tw-text-opacity: 0
  }

  .print\:text-opacity-5 {
    --tw-text-opacity: 0.05
  }

  .print\:text-opacity-10 {
    --tw-text-opacity: 0.1
  }

  .print\:text-opacity-20 {
    --tw-text-opacity: 0.2
  }

  .print\:text-opacity-25 {
    --tw-text-opacity: 0.25
  }

  .print\:text-opacity-30 {
    --tw-text-opacity: 0.3
  }

  .print\:text-opacity-40 {
    --tw-text-opacity: 0.4
  }

  .print\:text-opacity-50 {
    --tw-text-opacity: 0.5
  }

  .print\:text-opacity-60 {
    --tw-text-opacity: 0.6
  }

  .print\:text-opacity-70 {
    --tw-text-opacity: 0.7
  }

  .print\:text-opacity-75 {
    --tw-text-opacity: 0.75
  }

  .print\:text-opacity-80 {
    --tw-text-opacity: 0.8
  }

  .print\:text-opacity-90 {
    --tw-text-opacity: 0.9
  }

  .print\:text-opacity-95 {
    --tw-text-opacity: 0.95
  }

  .print\:text-opacity-100 {
    --tw-text-opacity: 1
  }

  .group:hover .print\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0
  }

  .group:hover .print\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05
  }

  .group:hover .print\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1
  }

  .group:hover .print\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2
  }

  .group:hover .print\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25
  }

  .group:hover .print\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3
  }

  .group:hover .print\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4
  }

  .group:hover .print\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5
  }

  .group:hover .print\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6
  }

  .group:hover .print\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7
  }

  .group:hover .print\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75
  }

  .group:hover .print\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8
  }

  .group:hover .print\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9
  }

  .group:hover .print\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95
  }

  .group:hover .print\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1
  }

  .print\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0
  }

  .print\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05
  }

  .print\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1
  }

  .print\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2
  }

  .print\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25
  }

  .print\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3
  }

  .print\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4
  }

  .print\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5
  }

  .print\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6
  }

  .print\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7
  }

  .print\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75
  }

  .print\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8
  }

  .print\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9
  }

  .print\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95
  }

  .print\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1
  }

  .print\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0
  }

  .print\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05
  }

  .print\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1
  }

  .print\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2
  }

  .print\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25
  }

  .print\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3
  }

  .print\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4
  }

  .print\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5
  }

  .print\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6
  }

  .print\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7
  }

  .print\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75
  }

  .print\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8
  }

  .print\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9
  }

  .print\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95
  }

  .print\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1
  }

  .print\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0
  }

  .print\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05
  }

  .print\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1
  }

  .print\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2
  }

  .print\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25
  }

  .print\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3
  }

  .print\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4
  }

  .print\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5
  }

  .print\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6
  }

  .print\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7
  }

  .print\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75
  }

  .print\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8
  }

  .print\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9
  }

  .print\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95
  }

  .print\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1
  }

  .print\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .print\:overflow-ellipsis {
    text-overflow: ellipsis
  }

  .print\:overflow-clip {
    text-overflow: clip
  }

  .print\:italic {
    font-style: italic
  }

  .print\:not-italic {
    font-style: normal
  }

  .print\:hover\:italic:hover {
    font-style: italic
  }

  .print\:hover\:not-italic:hover {
    font-style: normal
  }

  .print\:focus\:italic:focus {
    font-style: italic
  }

  .print\:focus\:not-italic:focus {
    font-style: normal
  }

  .print\:uppercase {
    text-transform: uppercase
  }

  .print\:lowercase {
    text-transform: lowercase
  }

  .print\:capitalize {
    text-transform: capitalize
  }

  .print\:normal-case {
    text-transform: none
  }

  .print\:underline {
    text-decoration: underline
  }

  .print\:line-through {
    text-decoration: line-through
  }

  .print\:no-underline {
    text-decoration: none
  }

  .group:hover .print\:group-hover\:underline {
    text-decoration: underline
  }

  .group:hover .print\:group-hover\:line-through {
    text-decoration: line-through
  }

  .group:hover .print\:group-hover\:no-underline {
    text-decoration: none
  }

  .print\:focus-within\:underline:focus-within {
    text-decoration: underline
  }

  .print\:focus-within\:line-through:focus-within {
    text-decoration: line-through
  }

  .print\:focus-within\:no-underline:focus-within {
    text-decoration: none
  }

  .print\:hover\:underline:hover {
    text-decoration: underline
  }

  .print\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .print\:hover\:no-underline:hover {
    text-decoration: none
  }

  .print\:focus\:underline:focus {
    text-decoration: underline
  }

  .print\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .print\:focus\:no-underline:focus {
    text-decoration: none
  }

  .print\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .print\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .print\:ordinal, .print\:slashed-zero, .print\:lining-nums, .print\:oldstyle-nums, .print\:proportional-nums, .print\:tabular-nums, .print\:diagonal-fractions, .print\:stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  .print\:normal-nums {
    font-variant-numeric: normal
  }

  .print\:ordinal {
    --tw-ordinal: ordinal
  }

  .print\:slashed-zero {
    --tw-slashed-zero: slashed-zero
  }

  .print\:lining-nums {
    --tw-numeric-figure: lining-nums
  }

  .print\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums
  }

  .print\:proportional-nums {
    --tw-numeric-spacing: proportional-nums
  }

  .print\:tabular-nums {
    --tw-numeric-spacing: tabular-nums
  }

  .print\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions
  }

  .print\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions
  }

  .print\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .print\:tracking-tight {
    letter-spacing: -0.025em
  }

  .print\:tracking-normal {
    letter-spacing: 0em
  }

  .print\:tracking-wide {
    letter-spacing: 0.025em
  }

  .print\:tracking-wider {
    letter-spacing: 0.05em
  }

  .print\:tracking-widest {
    letter-spacing: 0.1em
  }

  .print\:select-none {
    -webkit-user-select: none;
            user-select: none
  }

  .print\:select-text {
    -webkit-user-select: text;
            user-select: text
  }

  .print\:select-all {
    -webkit-user-select: all;
            user-select: all
  }

  .print\:select-auto {
    -webkit-user-select: auto;
            user-select: auto
  }

  .print\:align-baseline {
    vertical-align: baseline
  }

  .print\:align-top {
    vertical-align: top
  }

  .print\:align-middle {
    vertical-align: middle
  }

  .print\:align-bottom {
    vertical-align: bottom
  }

  .print\:align-text-top {
    vertical-align: text-top
  }

  .print\:align-text-bottom {
    vertical-align: text-bottom
  }

  .print\:visible {
    visibility: visible
  }

  .print\:invisible {
    visibility: hidden
  }

  .print\:whitespace-normal {
    white-space: normal
  }

  .print\:whitespace-nowrap {
    white-space: nowrap
  }

  .print\:whitespace-pre {
    white-space: pre
  }

  .print\:whitespace-pre-line {
    white-space: pre-line
  }

  .print\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .print\:break-normal {
    overflow-wrap: normal;
    word-break: normal
  }

  .print\:break-words {
    overflow-wrap: break-word
  }

  .print\:break-all {
    word-break: break-all
  }

  .print\:w-0 {
    width: 0
  }

  .print\:w-1 {
    width: 0.1rem
  }

  .print\:w-2 {
    width: 0.2rem
  }

  .print\:w-3 {
    width: 0.3rem
  }

  .print\:w-4 {
    width: 0.4rem
  }

  .print\:w-5 {
    width: 0.5rem
  }

  .print\:w-6 {
    width: 0.6rem
  }

  .print\:w-7 {
    width: 0.7rem
  }

  .print\:w-8 {
    width: 0.8rem
  }

  .print\:w-9 {
    width: 0.9rem
  }

  .print\:w-10 {
    width: 1.0rem
  }

  .print\:w-12 {
    width: 1.2rem
  }

  .print\:w-14 {
    width: 1.4rem
  }

  .print\:w-16 {
    width: 1.6rem
  }

  .print\:w-20 {
    width: 2rem
  }

  .print\:w-24 {
    width: 2.4rem
  }

  .print\:w-28 {
    width: 2.8rem
  }

  .print\:w-32 {
    width: 3.2rem
  }

  .print\:w-36 {
    width: 3.6rem
  }

  .print\:w-40 {
    width: 4rem
  }

  .print\:w-44 {
    width: 4.4rem
  }

  .print\:w-48 {
    width: 4.8rem
  }

  .print\:w-52 {
    width: 5.2rem
  }

  .print\:w-56 {
    width: 5.6rem
  }

  .print\:w-60 {
    width: 6rem
  }

  .print\:w-64 {
    width: 6.4rem
  }

  .print\:w-68 {
    width: 6.8rem
  }

  .print\:w-72 {
    width: 7.2rem
  }

  .print\:w-76 {
    width: 7.6rem
  }

  .print\:w-80 {
    width: 8rem
  }

  .print\:w-84 {
    width: 8.4rem
  }

  .print\:w-88 {
    width: 8.8rem
  }

  .print\:w-92 {
    width: 9.2rem
  }

  .print\:w-96 {
    width: 9.6rem
  }

  .print\:w-128 {
    width: 12.8rem
  }

  .print\:w-136 {
    width: 13.6rem
  }

  .print\:w-160 {
    width: 16rem
  }

  .print\:w-192 {
    width: 19.2rem
  }

  .print\:w-200 {
    width: 20rem
  }

  .print\:w-208 {
    width: 20.8rem
  }

  .print\:w-216 {
    width: 21.6rem
  }

  .print\:w-224 {
    width: 22.4rem
  }

  .print\:w-256 {
    width: 25.6rem
  }

  .print\:w-288 {
    width: 28.8rem
  }

  .print\:w-320 {
    width: 32rem
  }

  .print\:w-360 {
    width: 36rem
  }

  .print\:w-384 {
    width: 38.4rem
  }

  .print\:w-400 {
    width: 40rem
  }

  .print\:w-512 {
    width: 51.2rem
  }

  .print\:w-640 {
    width: 64rem
  }

  .print\:w-auto {
    width: auto
  }

  .print\:w-xs {
    width: 32rem
  }

  .print\:w-sm {
    width: 48rem
  }

  .print\:w-md {
    width: 64rem
  }

  .print\:w-lg {
    width: 80rem
  }

  .print\:w-xl {
    width: 96rem
  }

  .print\:w-2xl {
    width: 112rem
  }

  .print\:w-3xl {
    width: 128rem
  }

  .print\:w-4xl {
    width: 144rem
  }

  .print\:w-5xl {
    width: 160rem
  }

  .print\:w-px {
    width: 1px
  }

  .print\:w-0\.5 {
    width: 0.05rem
  }

  .print\:w-1\.5 {
    width: 0.15rem
  }

  .print\:w-2\.5 {
    width: 0.25rem
  }

  .print\:w-3\.5 {
    width: 0.35rem
  }

  .print\:w-1\/2 {
    width: 50%
  }

  .print\:w-1\/3 {
    width: 33.333333%
  }

  .print\:w-2\/3 {
    width: 66.666667%
  }

  .print\:w-1\/4 {
    width: 25%
  }

  .print\:w-2\/4 {
    width: 50%
  }

  .print\:w-3\/4 {
    width: 75%
  }

  .print\:w-1\/5 {
    width: 20%
  }

  .print\:w-2\/5 {
    width: 40%
  }

  .print\:w-3\/5 {
    width: 60%
  }

  .print\:w-4\/5 {
    width: 80%
  }

  .print\:w-1\/6 {
    width: 16.666667%
  }

  .print\:w-2\/6 {
    width: 33.333333%
  }

  .print\:w-3\/6 {
    width: 50%
  }

  .print\:w-4\/6 {
    width: 66.666667%
  }

  .print\:w-5\/6 {
    width: 83.333333%
  }

  .print\:w-1\/12 {
    width: 8.333333%
  }

  .print\:w-2\/12 {
    width: 16.666667%
  }

  .print\:w-3\/12 {
    width: 25%
  }

  .print\:w-4\/12 {
    width: 33.333333%
  }

  .print\:w-5\/12 {
    width: 41.666667%
  }

  .print\:w-6\/12 {
    width: 50%
  }

  .print\:w-7\/12 {
    width: 58.333333%
  }

  .print\:w-8\/12 {
    width: 66.666667%
  }

  .print\:w-9\/12 {
    width: 75%
  }

  .print\:w-10\/12 {
    width: 83.333333%
  }

  .print\:w-11\/12 {
    width: 91.666667%
  }

  .print\:w-full {
    width: 100%
  }

  .print\:w-screen {
    width: 100vw
  }

  .print\:w-min {
    width: min-content
  }

  .print\:w-max {
    width: max-content
  }

  .print\:z-0 {
    z-index: 0
  }

  .print\:z-10 {
    z-index: 10
  }

  .print\:z-20 {
    z-index: 20
  }

  .print\:z-30 {
    z-index: 30
  }

  .print\:z-40 {
    z-index: 40
  }

  .print\:z-50 {
    z-index: 50
  }

  .print\:z-99 {
    z-index: 99
  }

  .print\:z-999 {
    z-index: 999
  }

  .print\:z-9999 {
    z-index: 9999
  }

  .print\:z-auto {
    z-index: auto
  }

  .print\:focus-within\:z-0:focus-within {
    z-index: 0
  }

  .print\:focus-within\:z-10:focus-within {
    z-index: 10
  }

  .print\:focus-within\:z-20:focus-within {
    z-index: 20
  }

  .print\:focus-within\:z-30:focus-within {
    z-index: 30
  }

  .print\:focus-within\:z-40:focus-within {
    z-index: 40
  }

  .print\:focus-within\:z-50:focus-within {
    z-index: 50
  }

  .print\:focus-within\:z-99:focus-within {
    z-index: 99
  }

  .print\:focus-within\:z-999:focus-within {
    z-index: 999
  }

  .print\:focus-within\:z-9999:focus-within {
    z-index: 9999
  }

  .print\:focus-within\:z-auto:focus-within {
    z-index: auto
  }

  .print\:focus\:z-0:focus {
    z-index: 0
  }

  .print\:focus\:z-10:focus {
    z-index: 10
  }

  .print\:focus\:z-20:focus {
    z-index: 20
  }

  .print\:focus\:z-30:focus {
    z-index: 30
  }

  .print\:focus\:z-40:focus {
    z-index: 40
  }

  .print\:focus\:z-50:focus {
    z-index: 50
  }

  .print\:focus\:z-99:focus {
    z-index: 99
  }

  .print\:focus\:z-999:focus {
    z-index: 999
  }

  .print\:focus\:z-9999:focus {
    z-index: 9999
  }

  .print\:focus\:z-auto:focus {
    z-index: auto
  }

  .print\:gap-0 {
    gap: 0
  }

  .print\:gap-1 {
    gap: 0.1rem
  }

  .print\:gap-2 {
    gap: 0.2rem
  }

  .print\:gap-3 {
    gap: 0.3rem
  }

  .print\:gap-4 {
    gap: 0.4rem
  }

  .print\:gap-5 {
    gap: 0.5rem
  }

  .print\:gap-6 {
    gap: 0.6rem
  }

  .print\:gap-7 {
    gap: 0.7rem
  }

  .print\:gap-8 {
    gap: 0.8rem
  }

  .print\:gap-9 {
    gap: 0.9rem
  }

  .print\:gap-10 {
    gap: 1.0rem
  }

  .print\:gap-12 {
    gap: 1.2rem
  }

  .print\:gap-14 {
    gap: 1.4rem
  }

  .print\:gap-16 {
    gap: 1.6rem
  }

  .print\:gap-20 {
    gap: 2rem
  }

  .print\:gap-24 {
    gap: 2.4rem
  }

  .print\:gap-28 {
    gap: 2.8rem
  }

  .print\:gap-32 {
    gap: 3.2rem
  }

  .print\:gap-36 {
    gap: 3.6rem
  }

  .print\:gap-40 {
    gap: 4rem
  }

  .print\:gap-44 {
    gap: 4.4rem
  }

  .print\:gap-48 {
    gap: 4.8rem
  }

  .print\:gap-52 {
    gap: 5.2rem
  }

  .print\:gap-56 {
    gap: 5.6rem
  }

  .print\:gap-60 {
    gap: 6rem
  }

  .print\:gap-64 {
    gap: 6.4rem
  }

  .print\:gap-68 {
    gap: 6.8rem
  }

  .print\:gap-72 {
    gap: 7.2rem
  }

  .print\:gap-76 {
    gap: 7.6rem
  }

  .print\:gap-80 {
    gap: 8rem
  }

  .print\:gap-84 {
    gap: 8.4rem
  }

  .print\:gap-88 {
    gap: 8.8rem
  }

  .print\:gap-92 {
    gap: 9.2rem
  }

  .print\:gap-96 {
    gap: 9.6rem
  }

  .print\:gap-128 {
    gap: 12.8rem
  }

  .print\:gap-136 {
    gap: 13.6rem
  }

  .print\:gap-160 {
    gap: 16rem
  }

  .print\:gap-192 {
    gap: 19.2rem
  }

  .print\:gap-200 {
    gap: 20rem
  }

  .print\:gap-208 {
    gap: 20.8rem
  }

  .print\:gap-216 {
    gap: 21.6rem
  }

  .print\:gap-224 {
    gap: 22.4rem
  }

  .print\:gap-256 {
    gap: 25.6rem
  }

  .print\:gap-288 {
    gap: 28.8rem
  }

  .print\:gap-320 {
    gap: 32rem
  }

  .print\:gap-360 {
    gap: 36rem
  }

  .print\:gap-384 {
    gap: 38.4rem
  }

  .print\:gap-400 {
    gap: 40rem
  }

  .print\:gap-512 {
    gap: 51.2rem
  }

  .print\:gap-640 {
    gap: 64rem
  }

  .print\:gap-xs {
    gap: 32rem
  }

  .print\:gap-sm {
    gap: 48rem
  }

  .print\:gap-md {
    gap: 64rem
  }

  .print\:gap-lg {
    gap: 80rem
  }

  .print\:gap-xl {
    gap: 96rem
  }

  .print\:gap-2xl {
    gap: 112rem
  }

  .print\:gap-3xl {
    gap: 128rem
  }

  .print\:gap-4xl {
    gap: 144rem
  }

  .print\:gap-5xl {
    gap: 160rem
  }

  .print\:gap-px {
    gap: 1px
  }

  .print\:gap-0\.5 {
    gap: 0.05rem
  }

  .print\:gap-1\.5 {
    gap: 0.15rem
  }

  .print\:gap-2\.5 {
    gap: 0.25rem
  }

  .print\:gap-3\.5 {
    gap: 0.35rem
  }

  .print\:gap-x-0 {
    column-gap: 0
  }

  .print\:gap-x-1 {
    column-gap: 0.1rem
  }

  .print\:gap-x-2 {
    column-gap: 0.2rem
  }

  .print\:gap-x-3 {
    column-gap: 0.3rem
  }

  .print\:gap-x-4 {
    column-gap: 0.4rem
  }

  .print\:gap-x-5 {
    column-gap: 0.5rem
  }

  .print\:gap-x-6 {
    column-gap: 0.6rem
  }

  .print\:gap-x-7 {
    column-gap: 0.7rem
  }

  .print\:gap-x-8 {
    column-gap: 0.8rem
  }

  .print\:gap-x-9 {
    column-gap: 0.9rem
  }

  .print\:gap-x-10 {
    column-gap: 1.0rem
  }

  .print\:gap-x-12 {
    column-gap: 1.2rem
  }

  .print\:gap-x-14 {
    column-gap: 1.4rem
  }

  .print\:gap-x-16 {
    column-gap: 1.6rem
  }

  .print\:gap-x-20 {
    column-gap: 2rem
  }

  .print\:gap-x-24 {
    column-gap: 2.4rem
  }

  .print\:gap-x-28 {
    column-gap: 2.8rem
  }

  .print\:gap-x-32 {
    column-gap: 3.2rem
  }

  .print\:gap-x-36 {
    column-gap: 3.6rem
  }

  .print\:gap-x-40 {
    column-gap: 4rem
  }

  .print\:gap-x-44 {
    column-gap: 4.4rem
  }

  .print\:gap-x-48 {
    column-gap: 4.8rem
  }

  .print\:gap-x-52 {
    column-gap: 5.2rem
  }

  .print\:gap-x-56 {
    column-gap: 5.6rem
  }

  .print\:gap-x-60 {
    column-gap: 6rem
  }

  .print\:gap-x-64 {
    column-gap: 6.4rem
  }

  .print\:gap-x-68 {
    column-gap: 6.8rem
  }

  .print\:gap-x-72 {
    column-gap: 7.2rem
  }

  .print\:gap-x-76 {
    column-gap: 7.6rem
  }

  .print\:gap-x-80 {
    column-gap: 8rem
  }

  .print\:gap-x-84 {
    column-gap: 8.4rem
  }

  .print\:gap-x-88 {
    column-gap: 8.8rem
  }

  .print\:gap-x-92 {
    column-gap: 9.2rem
  }

  .print\:gap-x-96 {
    column-gap: 9.6rem
  }

  .print\:gap-x-128 {
    column-gap: 12.8rem
  }

  .print\:gap-x-136 {
    column-gap: 13.6rem
  }

  .print\:gap-x-160 {
    column-gap: 16rem
  }

  .print\:gap-x-192 {
    column-gap: 19.2rem
  }

  .print\:gap-x-200 {
    column-gap: 20rem
  }

  .print\:gap-x-208 {
    column-gap: 20.8rem
  }

  .print\:gap-x-216 {
    column-gap: 21.6rem
  }

  .print\:gap-x-224 {
    column-gap: 22.4rem
  }

  .print\:gap-x-256 {
    column-gap: 25.6rem
  }

  .print\:gap-x-288 {
    column-gap: 28.8rem
  }

  .print\:gap-x-320 {
    column-gap: 32rem
  }

  .print\:gap-x-360 {
    column-gap: 36rem
  }

  .print\:gap-x-384 {
    column-gap: 38.4rem
  }

  .print\:gap-x-400 {
    column-gap: 40rem
  }

  .print\:gap-x-512 {
    column-gap: 51.2rem
  }

  .print\:gap-x-640 {
    column-gap: 64rem
  }

  .print\:gap-x-xs {
    column-gap: 32rem
  }

  .print\:gap-x-sm {
    column-gap: 48rem
  }

  .print\:gap-x-md {
    column-gap: 64rem
  }

  .print\:gap-x-lg {
    column-gap: 80rem
  }

  .print\:gap-x-xl {
    column-gap: 96rem
  }

  .print\:gap-x-2xl {
    column-gap: 112rem
  }

  .print\:gap-x-3xl {
    column-gap: 128rem
  }

  .print\:gap-x-4xl {
    column-gap: 144rem
  }

  .print\:gap-x-5xl {
    column-gap: 160rem
  }

  .print\:gap-x-px {
    column-gap: 1px
  }

  .print\:gap-x-0\.5 {
    column-gap: 0.05rem
  }

  .print\:gap-x-1\.5 {
    column-gap: 0.15rem
  }

  .print\:gap-x-2\.5 {
    column-gap: 0.25rem
  }

  .print\:gap-x-3\.5 {
    column-gap: 0.35rem
  }

  .print\:gap-y-0 {
    row-gap: 0
  }

  .print\:gap-y-1 {
    row-gap: 0.1rem
  }

  .print\:gap-y-2 {
    row-gap: 0.2rem
  }

  .print\:gap-y-3 {
    row-gap: 0.3rem
  }

  .print\:gap-y-4 {
    row-gap: 0.4rem
  }

  .print\:gap-y-5 {
    row-gap: 0.5rem
  }

  .print\:gap-y-6 {
    row-gap: 0.6rem
  }

  .print\:gap-y-7 {
    row-gap: 0.7rem
  }

  .print\:gap-y-8 {
    row-gap: 0.8rem
  }

  .print\:gap-y-9 {
    row-gap: 0.9rem
  }

  .print\:gap-y-10 {
    row-gap: 1.0rem
  }

  .print\:gap-y-12 {
    row-gap: 1.2rem
  }

  .print\:gap-y-14 {
    row-gap: 1.4rem
  }

  .print\:gap-y-16 {
    row-gap: 1.6rem
  }

  .print\:gap-y-20 {
    row-gap: 2rem
  }

  .print\:gap-y-24 {
    row-gap: 2.4rem
  }

  .print\:gap-y-28 {
    row-gap: 2.8rem
  }

  .print\:gap-y-32 {
    row-gap: 3.2rem
  }

  .print\:gap-y-36 {
    row-gap: 3.6rem
  }

  .print\:gap-y-40 {
    row-gap: 4rem
  }

  .print\:gap-y-44 {
    row-gap: 4.4rem
  }

  .print\:gap-y-48 {
    row-gap: 4.8rem
  }

  .print\:gap-y-52 {
    row-gap: 5.2rem
  }

  .print\:gap-y-56 {
    row-gap: 5.6rem
  }

  .print\:gap-y-60 {
    row-gap: 6rem
  }

  .print\:gap-y-64 {
    row-gap: 6.4rem
  }

  .print\:gap-y-68 {
    row-gap: 6.8rem
  }

  .print\:gap-y-72 {
    row-gap: 7.2rem
  }

  .print\:gap-y-76 {
    row-gap: 7.6rem
  }

  .print\:gap-y-80 {
    row-gap: 8rem
  }

  .print\:gap-y-84 {
    row-gap: 8.4rem
  }

  .print\:gap-y-88 {
    row-gap: 8.8rem
  }

  .print\:gap-y-92 {
    row-gap: 9.2rem
  }

  .print\:gap-y-96 {
    row-gap: 9.6rem
  }

  .print\:gap-y-128 {
    row-gap: 12.8rem
  }

  .print\:gap-y-136 {
    row-gap: 13.6rem
  }

  .print\:gap-y-160 {
    row-gap: 16rem
  }

  .print\:gap-y-192 {
    row-gap: 19.2rem
  }

  .print\:gap-y-200 {
    row-gap: 20rem
  }

  .print\:gap-y-208 {
    row-gap: 20.8rem
  }

  .print\:gap-y-216 {
    row-gap: 21.6rem
  }

  .print\:gap-y-224 {
    row-gap: 22.4rem
  }

  .print\:gap-y-256 {
    row-gap: 25.6rem
  }

  .print\:gap-y-288 {
    row-gap: 28.8rem
  }

  .print\:gap-y-320 {
    row-gap: 32rem
  }

  .print\:gap-y-360 {
    row-gap: 36rem
  }

  .print\:gap-y-384 {
    row-gap: 38.4rem
  }

  .print\:gap-y-400 {
    row-gap: 40rem
  }

  .print\:gap-y-512 {
    row-gap: 51.2rem
  }

  .print\:gap-y-640 {
    row-gap: 64rem
  }

  .print\:gap-y-xs {
    row-gap: 32rem
  }

  .print\:gap-y-sm {
    row-gap: 48rem
  }

  .print\:gap-y-md {
    row-gap: 64rem
  }

  .print\:gap-y-lg {
    row-gap: 80rem
  }

  .print\:gap-y-xl {
    row-gap: 96rem
  }

  .print\:gap-y-2xl {
    row-gap: 112rem
  }

  .print\:gap-y-3xl {
    row-gap: 128rem
  }

  .print\:gap-y-4xl {
    row-gap: 144rem
  }

  .print\:gap-y-5xl {
    row-gap: 160rem
  }

  .print\:gap-y-px {
    row-gap: 1px
  }

  .print\:gap-y-0\.5 {
    row-gap: 0.05rem
  }

  .print\:gap-y-1\.5 {
    row-gap: 0.15rem
  }

  .print\:gap-y-2\.5 {
    row-gap: 0.25rem
  }

  .print\:gap-y-3\.5 {
    row-gap: 0.35rem
  }

  .print\:grid-flow-row {
    grid-auto-flow: row
  }

  .print\:grid-flow-col {
    grid-auto-flow: column
  }

  .print\:grid-flow-row-dense {
    grid-auto-flow: row dense
  }

  .print\:grid-flow-col-dense {
    grid-auto-flow: column dense
  }

  .print\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .print\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .print\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .print\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .print\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .print\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .print\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .print\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .print\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .print\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .print\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .print\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .print\:grid-cols-none {
    grid-template-columns: none
  }

  .print\:auto-cols-auto {
    grid-auto-columns: auto
  }

  .print\:auto-cols-min {
    grid-auto-columns: min-content
  }

  .print\:auto-cols-max {
    grid-auto-columns: max-content
  }

  .print\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr)
  }

  .print\:col-auto {
    grid-column: auto
  }

  .print\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .print\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .print\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .print\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .print\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .print\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .print\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .print\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .print\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .print\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .print\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .print\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .print\:col-span-full {
    grid-column: 1 / -1
  }

  .print\:col-start-1 {
    grid-column-start: 1
  }

  .print\:col-start-2 {
    grid-column-start: 2
  }

  .print\:col-start-3 {
    grid-column-start: 3
  }

  .print\:col-start-4 {
    grid-column-start: 4
  }

  .print\:col-start-5 {
    grid-column-start: 5
  }

  .print\:col-start-6 {
    grid-column-start: 6
  }

  .print\:col-start-7 {
    grid-column-start: 7
  }

  .print\:col-start-8 {
    grid-column-start: 8
  }

  .print\:col-start-9 {
    grid-column-start: 9
  }

  .print\:col-start-10 {
    grid-column-start: 10
  }

  .print\:col-start-11 {
    grid-column-start: 11
  }

  .print\:col-start-12 {
    grid-column-start: 12
  }

  .print\:col-start-13 {
    grid-column-start: 13
  }

  .print\:col-start-auto {
    grid-column-start: auto
  }

  .print\:col-end-1 {
    grid-column-end: 1
  }

  .print\:col-end-2 {
    grid-column-end: 2
  }

  .print\:col-end-3 {
    grid-column-end: 3
  }

  .print\:col-end-4 {
    grid-column-end: 4
  }

  .print\:col-end-5 {
    grid-column-end: 5
  }

  .print\:col-end-6 {
    grid-column-end: 6
  }

  .print\:col-end-7 {
    grid-column-end: 7
  }

  .print\:col-end-8 {
    grid-column-end: 8
  }

  .print\:col-end-9 {
    grid-column-end: 9
  }

  .print\:col-end-10 {
    grid-column-end: 10
  }

  .print\:col-end-11 {
    grid-column-end: 11
  }

  .print\:col-end-12 {
    grid-column-end: 12
  }

  .print\:col-end-13 {
    grid-column-end: 13
  }

  .print\:col-end-auto {
    grid-column-end: auto
  }

  .print\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .print\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .print\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .print\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .print\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .print\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .print\:grid-rows-none {
    grid-template-rows: none
  }

  .print\:auto-rows-auto {
    grid-auto-rows: auto
  }

  .print\:auto-rows-min {
    grid-auto-rows: min-content
  }

  .print\:auto-rows-max {
    grid-auto-rows: max-content
  }

  .print\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr)
  }

  .print\:row-auto {
    grid-row: auto
  }

  .print\:row-span-1 {
    grid-row: span 1 / span 1
  }

  .print\:row-span-2 {
    grid-row: span 2 / span 2
  }

  .print\:row-span-3 {
    grid-row: span 3 / span 3
  }

  .print\:row-span-4 {
    grid-row: span 4 / span 4
  }

  .print\:row-span-5 {
    grid-row: span 5 / span 5
  }

  .print\:row-span-6 {
    grid-row: span 6 / span 6
  }

  .print\:row-span-full {
    grid-row: 1 / -1
  }

  .print\:row-start-1 {
    grid-row-start: 1
  }

  .print\:row-start-2 {
    grid-row-start: 2
  }

  .print\:row-start-3 {
    grid-row-start: 3
  }

  .print\:row-start-4 {
    grid-row-start: 4
  }

  .print\:row-start-5 {
    grid-row-start: 5
  }

  .print\:row-start-6 {
    grid-row-start: 6
  }

  .print\:row-start-7 {
    grid-row-start: 7
  }

  .print\:row-start-auto {
    grid-row-start: auto
  }

  .print\:row-end-1 {
    grid-row-end: 1
  }

  .print\:row-end-2 {
    grid-row-end: 2
  }

  .print\:row-end-3 {
    grid-row-end: 3
  }

  .print\:row-end-4 {
    grid-row-end: 4
  }

  .print\:row-end-5 {
    grid-row-end: 5
  }

  .print\:row-end-6 {
    grid-row-end: 6
  }

  .print\:row-end-7 {
    grid-row-end: 7
  }

  .print\:row-end-auto {
    grid-row-end: auto
  }

  .print\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .print\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .print\:transform-none {
    transform: none
  }

  .print\:origin-center {
    transform-origin: center
  }

  .print\:origin-top {
    transform-origin: top
  }

  .print\:origin-top-right {
    transform-origin: top right
  }

  .print\:origin-right {
    transform-origin: right
  }

  .print\:origin-bottom-right {
    transform-origin: bottom right
  }

  .print\:origin-bottom {
    transform-origin: bottom
  }

  .print\:origin-bottom-left {
    transform-origin: bottom left
  }

  .print\:origin-left {
    transform-origin: left
  }

  .print\:origin-top-left {
    transform-origin: top left
  }

  .print\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .print\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .print\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .print\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .print\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .print\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .print\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .print\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .print\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .print\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .print\:scale-x-0 {
    --tw-scale-x: 0
  }

  .print\:scale-x-50 {
    --tw-scale-x: .5
  }

  .print\:scale-x-75 {
    --tw-scale-x: .75
  }

  .print\:scale-x-90 {
    --tw-scale-x: .9
  }

  .print\:scale-x-95 {
    --tw-scale-x: .95
  }

  .print\:scale-x-100 {
    --tw-scale-x: 1
  }

  .print\:scale-x-105 {
    --tw-scale-x: 1.05
  }

  .print\:scale-x-110 {
    --tw-scale-x: 1.1
  }

  .print\:scale-x-125 {
    --tw-scale-x: 1.25
  }

  .print\:scale-x-150 {
    --tw-scale-x: 1.5
  }

  .print\:scale-y-0 {
    --tw-scale-y: 0
  }

  .print\:scale-y-50 {
    --tw-scale-y: .5
  }

  .print\:scale-y-75 {
    --tw-scale-y: .75
  }

  .print\:scale-y-90 {
    --tw-scale-y: .9
  }

  .print\:scale-y-95 {
    --tw-scale-y: .95
  }

  .print\:scale-y-100 {
    --tw-scale-y: 1
  }

  .print\:scale-y-105 {
    --tw-scale-y: 1.05
  }

  .print\:scale-y-110 {
    --tw-scale-y: 1.1
  }

  .print\:scale-y-125 {
    --tw-scale-y: 1.25
  }

  .print\:scale-y-150 {
    --tw-scale-y: 1.5
  }

  .print\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .print\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .print\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .print\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .print\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .print\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .print\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .print\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .print\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .print\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .print\:hover\:scale-x-0:hover {
    --tw-scale-x: 0
  }

  .print\:hover\:scale-x-50:hover {
    --tw-scale-x: .5
  }

  .print\:hover\:scale-x-75:hover {
    --tw-scale-x: .75
  }

  .print\:hover\:scale-x-90:hover {
    --tw-scale-x: .9
  }

  .print\:hover\:scale-x-95:hover {
    --tw-scale-x: .95
  }

  .print\:hover\:scale-x-100:hover {
    --tw-scale-x: 1
  }

  .print\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05
  }

  .print\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1
  }

  .print\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25
  }

  .print\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5
  }

  .print\:hover\:scale-y-0:hover {
    --tw-scale-y: 0
  }

  .print\:hover\:scale-y-50:hover {
    --tw-scale-y: .5
  }

  .print\:hover\:scale-y-75:hover {
    --tw-scale-y: .75
  }

  .print\:hover\:scale-y-90:hover {
    --tw-scale-y: .9
  }

  .print\:hover\:scale-y-95:hover {
    --tw-scale-y: .95
  }

  .print\:hover\:scale-y-100:hover {
    --tw-scale-y: 1
  }

  .print\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05
  }

  .print\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1
  }

  .print\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25
  }

  .print\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5
  }

  .print\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .print\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .print\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .print\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .print\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .print\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .print\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .print\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .print\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .print\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .print\:focus\:scale-x-0:focus {
    --tw-scale-x: 0
  }

  .print\:focus\:scale-x-50:focus {
    --tw-scale-x: .5
  }

  .print\:focus\:scale-x-75:focus {
    --tw-scale-x: .75
  }

  .print\:focus\:scale-x-90:focus {
    --tw-scale-x: .9
  }

  .print\:focus\:scale-x-95:focus {
    --tw-scale-x: .95
  }

  .print\:focus\:scale-x-100:focus {
    --tw-scale-x: 1
  }

  .print\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05
  }

  .print\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1
  }

  .print\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25
  }

  .print\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5
  }

  .print\:focus\:scale-y-0:focus {
    --tw-scale-y: 0
  }

  .print\:focus\:scale-y-50:focus {
    --tw-scale-y: .5
  }

  .print\:focus\:scale-y-75:focus {
    --tw-scale-y: .75
  }

  .print\:focus\:scale-y-90:focus {
    --tw-scale-y: .9
  }

  .print\:focus\:scale-y-95:focus {
    --tw-scale-y: .95
  }

  .print\:focus\:scale-y-100:focus {
    --tw-scale-y: 1
  }

  .print\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05
  }

  .print\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1
  }

  .print\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25
  }

  .print\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5
  }

  .print\:rotate-0 {
    --tw-rotate: 0deg
  }

  .print\:rotate-1 {
    --tw-rotate: 1deg
  }

  .print\:rotate-2 {
    --tw-rotate: 2deg
  }

  .print\:rotate-3 {
    --tw-rotate: 3deg
  }

  .print\:rotate-6 {
    --tw-rotate: 6deg
  }

  .print\:rotate-12 {
    --tw-rotate: 12deg
  }

  .print\:rotate-45 {
    --tw-rotate: 45deg
  }

  .print\:rotate-90 {
    --tw-rotate: 90deg
  }

  .print\:rotate-180 {
    --tw-rotate: 180deg
  }

  .print\:-rotate-180 {
    --tw-rotate: -180deg
  }

  .print\:-rotate-90 {
    --tw-rotate: -90deg
  }

  .print\:-rotate-45 {
    --tw-rotate: -45deg
  }

  .print\:-rotate-12 {
    --tw-rotate: -12deg
  }

  .print\:-rotate-6 {
    --tw-rotate: -6deg
  }

  .print\:-rotate-3 {
    --tw-rotate: -3deg
  }

  .print\:-rotate-2 {
    --tw-rotate: -2deg
  }

  .print\:-rotate-1 {
    --tw-rotate: -1deg
  }

  .print\:hover\:rotate-0:hover {
    --tw-rotate: 0deg
  }

  .print\:hover\:rotate-1:hover {
    --tw-rotate: 1deg
  }

  .print\:hover\:rotate-2:hover {
    --tw-rotate: 2deg
  }

  .print\:hover\:rotate-3:hover {
    --tw-rotate: 3deg
  }

  .print\:hover\:rotate-6:hover {
    --tw-rotate: 6deg
  }

  .print\:hover\:rotate-12:hover {
    --tw-rotate: 12deg
  }

  .print\:hover\:rotate-45:hover {
    --tw-rotate: 45deg
  }

  .print\:hover\:rotate-90:hover {
    --tw-rotate: 90deg
  }

  .print\:hover\:rotate-180:hover {
    --tw-rotate: 180deg
  }

  .print\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg
  }

  .print\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg
  }

  .print\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg
  }

  .print\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg
  }

  .print\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg
  }

  .print\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg
  }

  .print\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg
  }

  .print\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg
  }

  .print\:focus\:rotate-0:focus {
    --tw-rotate: 0deg
  }

  .print\:focus\:rotate-1:focus {
    --tw-rotate: 1deg
  }

  .print\:focus\:rotate-2:focus {
    --tw-rotate: 2deg
  }

  .print\:focus\:rotate-3:focus {
    --tw-rotate: 3deg
  }

  .print\:focus\:rotate-6:focus {
    --tw-rotate: 6deg
  }

  .print\:focus\:rotate-12:focus {
    --tw-rotate: 12deg
  }

  .print\:focus\:rotate-45:focus {
    --tw-rotate: 45deg
  }

  .print\:focus\:rotate-90:focus {
    --tw-rotate: 90deg
  }

  .print\:focus\:rotate-180:focus {
    --tw-rotate: 180deg
  }

  .print\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg
  }

  .print\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg
  }

  .print\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg
  }

  .print\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg
  }

  .print\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg
  }

  .print\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg
  }

  .print\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg
  }

  .print\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg
  }

  .print\:translate-x-0 {
    --tw-translate-x: 0
  }

  .print\:translate-x-1 {
    --tw-translate-x: 0.1rem
  }

  .print\:translate-x-2 {
    --tw-translate-x: 0.2rem
  }

  .print\:translate-x-3 {
    --tw-translate-x: 0.3rem
  }

  .print\:translate-x-4 {
    --tw-translate-x: 0.4rem
  }

  .print\:translate-x-5 {
    --tw-translate-x: 0.5rem
  }

  .print\:translate-x-6 {
    --tw-translate-x: 0.6rem
  }

  .print\:translate-x-7 {
    --tw-translate-x: 0.7rem
  }

  .print\:translate-x-8 {
    --tw-translate-x: 0.8rem
  }

  .print\:translate-x-9 {
    --tw-translate-x: 0.9rem
  }

  .print\:translate-x-10 {
    --tw-translate-x: 1.0rem
  }

  .print\:translate-x-12 {
    --tw-translate-x: 1.2rem
  }

  .print\:translate-x-14 {
    --tw-translate-x: 1.4rem
  }

  .print\:translate-x-16 {
    --tw-translate-x: 1.6rem
  }

  .print\:translate-x-20 {
    --tw-translate-x: 2rem
  }

  .print\:translate-x-24 {
    --tw-translate-x: 2.4rem
  }

  .print\:translate-x-28 {
    --tw-translate-x: 2.8rem
  }

  .print\:translate-x-32 {
    --tw-translate-x: 3.2rem
  }

  .print\:translate-x-36 {
    --tw-translate-x: 3.6rem
  }

  .print\:translate-x-40 {
    --tw-translate-x: 4rem
  }

  .print\:translate-x-44 {
    --tw-translate-x: 4.4rem
  }

  .print\:translate-x-48 {
    --tw-translate-x: 4.8rem
  }

  .print\:translate-x-52 {
    --tw-translate-x: 5.2rem
  }

  .print\:translate-x-56 {
    --tw-translate-x: 5.6rem
  }

  .print\:translate-x-60 {
    --tw-translate-x: 6rem
  }

  .print\:translate-x-64 {
    --tw-translate-x: 6.4rem
  }

  .print\:translate-x-68 {
    --tw-translate-x: 6.8rem
  }

  .print\:translate-x-72 {
    --tw-translate-x: 7.2rem
  }

  .print\:translate-x-76 {
    --tw-translate-x: 7.6rem
  }

  .print\:translate-x-80 {
    --tw-translate-x: 8rem
  }

  .print\:translate-x-84 {
    --tw-translate-x: 8.4rem
  }

  .print\:translate-x-88 {
    --tw-translate-x: 8.8rem
  }

  .print\:translate-x-92 {
    --tw-translate-x: 9.2rem
  }

  .print\:translate-x-96 {
    --tw-translate-x: 9.6rem
  }

  .print\:translate-x-128 {
    --tw-translate-x: 12.8rem
  }

  .print\:translate-x-136 {
    --tw-translate-x: 13.6rem
  }

  .print\:translate-x-160 {
    --tw-translate-x: 16rem
  }

  .print\:translate-x-192 {
    --tw-translate-x: 19.2rem
  }

  .print\:translate-x-200 {
    --tw-translate-x: 20rem
  }

  .print\:translate-x-208 {
    --tw-translate-x: 20.8rem
  }

  .print\:translate-x-216 {
    --tw-translate-x: 21.6rem
  }

  .print\:translate-x-224 {
    --tw-translate-x: 22.4rem
  }

  .print\:translate-x-256 {
    --tw-translate-x: 25.6rem
  }

  .print\:translate-x-288 {
    --tw-translate-x: 28.8rem
  }

  .print\:translate-x-320 {
    --tw-translate-x: 32rem
  }

  .print\:translate-x-360 {
    --tw-translate-x: 36rem
  }

  .print\:translate-x-384 {
    --tw-translate-x: 38.4rem
  }

  .print\:translate-x-400 {
    --tw-translate-x: 40rem
  }

  .print\:translate-x-512 {
    --tw-translate-x: 51.2rem
  }

  .print\:translate-x-640 {
    --tw-translate-x: 64rem
  }

  .print\:translate-x-xs {
    --tw-translate-x: 32rem
  }

  .print\:translate-x-sm {
    --tw-translate-x: 48rem
  }

  .print\:translate-x-md {
    --tw-translate-x: 64rem
  }

  .print\:translate-x-lg {
    --tw-translate-x: 80rem
  }

  .print\:translate-x-xl {
    --tw-translate-x: 96rem
  }

  .print\:translate-x-2xl {
    --tw-translate-x: 112rem
  }

  .print\:translate-x-3xl {
    --tw-translate-x: 128rem
  }

  .print\:translate-x-4xl {
    --tw-translate-x: 144rem
  }

  .print\:translate-x-5xl {
    --tw-translate-x: 160rem
  }

  .print\:translate-x-px {
    --tw-translate-x: 1px
  }

  .print\:translate-x-0\.5 {
    --tw-translate-x: 0.05rem
  }

  .print\:translate-x-1\.5 {
    --tw-translate-x: 0.15rem
  }

  .print\:translate-x-2\.5 {
    --tw-translate-x: 0.25rem
  }

  .print\:translate-x-3\.5 {
    --tw-translate-x: 0.35rem
  }

  .print\:-translate-x-1 {
    --tw-translate-x: -0.1rem
  }

  .print\:-translate-x-2 {
    --tw-translate-x: -0.2rem
  }

  .print\:-translate-x-3 {
    --tw-translate-x: -0.3rem
  }

  .print\:-translate-x-4 {
    --tw-translate-x: -0.4rem
  }

  .print\:-translate-x-5 {
    --tw-translate-x: -0.5rem
  }

  .print\:-translate-x-6 {
    --tw-translate-x: -0.6rem
  }

  .print\:-translate-x-7 {
    --tw-translate-x: -0.7rem
  }

  .print\:-translate-x-8 {
    --tw-translate-x: -0.8rem
  }

  .print\:-translate-x-9 {
    --tw-translate-x: -0.9rem
  }

  .print\:-translate-x-10 {
    --tw-translate-x: -1rem
  }

  .print\:-translate-x-12 {
    --tw-translate-x: -1.2rem
  }

  .print\:-translate-x-14 {
    --tw-translate-x: -1.4rem
  }

  .print\:-translate-x-16 {
    --tw-translate-x: -1.6rem
  }

  .print\:-translate-x-20 {
    --tw-translate-x: -2rem
  }

  .print\:-translate-x-24 {
    --tw-translate-x: -2.4rem
  }

  .print\:-translate-x-28 {
    --tw-translate-x: -2.8rem
  }

  .print\:-translate-x-32 {
    --tw-translate-x: -3.2rem
  }

  .print\:-translate-x-36 {
    --tw-translate-x: -3.6rem
  }

  .print\:-translate-x-40 {
    --tw-translate-x: -4rem
  }

  .print\:-translate-x-44 {
    --tw-translate-x: -4.4rem
  }

  .print\:-translate-x-48 {
    --tw-translate-x: -4.8rem
  }

  .print\:-translate-x-52 {
    --tw-translate-x: -5.2rem
  }

  .print\:-translate-x-56 {
    --tw-translate-x: -5.6rem
  }

  .print\:-translate-x-60 {
    --tw-translate-x: -6rem
  }

  .print\:-translate-x-64 {
    --tw-translate-x: -6.4rem
  }

  .print\:-translate-x-68 {
    --tw-translate-x: -6.8rem
  }

  .print\:-translate-x-72 {
    --tw-translate-x: -7.2rem
  }

  .print\:-translate-x-76 {
    --tw-translate-x: -7.6rem
  }

  .print\:-translate-x-80 {
    --tw-translate-x: -8rem
  }

  .print\:-translate-x-84 {
    --tw-translate-x: -8.4rem
  }

  .print\:-translate-x-88 {
    --tw-translate-x: -8.8rem
  }

  .print\:-translate-x-92 {
    --tw-translate-x: -9.2rem
  }

  .print\:-translate-x-96 {
    --tw-translate-x: -9.6rem
  }

  .print\:-translate-x-128 {
    --tw-translate-x: -12.8rem
  }

  .print\:-translate-x-136 {
    --tw-translate-x: -13.6rem
  }

  .print\:-translate-x-160 {
    --tw-translate-x: -16rem
  }

  .print\:-translate-x-192 {
    --tw-translate-x: -19.2rem
  }

  .print\:-translate-x-200 {
    --tw-translate-x: -20rem
  }

  .print\:-translate-x-208 {
    --tw-translate-x: -20.8rem
  }

  .print\:-translate-x-216 {
    --tw-translate-x: -21.6rem
  }

  .print\:-translate-x-224 {
    --tw-translate-x: -22.4rem
  }

  .print\:-translate-x-256 {
    --tw-translate-x: -25.6rem
  }

  .print\:-translate-x-288 {
    --tw-translate-x: -28.8rem
  }

  .print\:-translate-x-320 {
    --tw-translate-x: -32rem
  }

  .print\:-translate-x-360 {
    --tw-translate-x: -36rem
  }

  .print\:-translate-x-384 {
    --tw-translate-x: -38.4rem
  }

  .print\:-translate-x-400 {
    --tw-translate-x: -40rem
  }

  .print\:-translate-x-512 {
    --tw-translate-x: -51.2rem
  }

  .print\:-translate-x-640 {
    --tw-translate-x: -64rem
  }

  .print\:-translate-x-xs {
    --tw-translate-x: -32rem
  }

  .print\:-translate-x-sm {
    --tw-translate-x: -48rem
  }

  .print\:-translate-x-md {
    --tw-translate-x: -64rem
  }

  .print\:-translate-x-lg {
    --tw-translate-x: -80rem
  }

  .print\:-translate-x-xl {
    --tw-translate-x: -96rem
  }

  .print\:-translate-x-2xl {
    --tw-translate-x: -112rem
  }

  .print\:-translate-x-3xl {
    --tw-translate-x: -128rem
  }

  .print\:-translate-x-4xl {
    --tw-translate-x: -144rem
  }

  .print\:-translate-x-5xl {
    --tw-translate-x: -160rem
  }

  .print\:-translate-x-px {
    --tw-translate-x: -1px
  }

  .print\:-translate-x-0\.5 {
    --tw-translate-x: -0.05rem
  }

  .print\:-translate-x-1\.5 {
    --tw-translate-x: -0.15rem
  }

  .print\:-translate-x-2\.5 {
    --tw-translate-x: -0.25rem
  }

  .print\:-translate-x-3\.5 {
    --tw-translate-x: -0.35rem
  }

  .print\:translate-x-1\/2 {
    --tw-translate-x: 50%
  }

  .print\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%
  }

  .print\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%
  }

  .print\:translate-x-1\/4 {
    --tw-translate-x: 25%
  }

  .print\:translate-x-2\/4 {
    --tw-translate-x: 50%
  }

  .print\:translate-x-3\/4 {
    --tw-translate-x: 75%
  }

  .print\:translate-x-full {
    --tw-translate-x: 100%
  }

  .print\:-translate-x-1\/2 {
    --tw-translate-x: -50%
  }

  .print\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%
  }

  .print\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%
  }

  .print\:-translate-x-1\/4 {
    --tw-translate-x: -25%
  }

  .print\:-translate-x-2\/4 {
    --tw-translate-x: -50%
  }

  .print\:-translate-x-3\/4 {
    --tw-translate-x: -75%
  }

  .print\:-translate-x-full {
    --tw-translate-x: -100%
  }

  .print\:translate-y-0 {
    --tw-translate-y: 0
  }

  .print\:translate-y-1 {
    --tw-translate-y: 0.1rem
  }

  .print\:translate-y-2 {
    --tw-translate-y: 0.2rem
  }

  .print\:translate-y-3 {
    --tw-translate-y: 0.3rem
  }

  .print\:translate-y-4 {
    --tw-translate-y: 0.4rem
  }

  .print\:translate-y-5 {
    --tw-translate-y: 0.5rem
  }

  .print\:translate-y-6 {
    --tw-translate-y: 0.6rem
  }

  .print\:translate-y-7 {
    --tw-translate-y: 0.7rem
  }

  .print\:translate-y-8 {
    --tw-translate-y: 0.8rem
  }

  .print\:translate-y-9 {
    --tw-translate-y: 0.9rem
  }

  .print\:translate-y-10 {
    --tw-translate-y: 1.0rem
  }

  .print\:translate-y-12 {
    --tw-translate-y: 1.2rem
  }

  .print\:translate-y-14 {
    --tw-translate-y: 1.4rem
  }

  .print\:translate-y-16 {
    --tw-translate-y: 1.6rem
  }

  .print\:translate-y-20 {
    --tw-translate-y: 2rem
  }

  .print\:translate-y-24 {
    --tw-translate-y: 2.4rem
  }

  .print\:translate-y-28 {
    --tw-translate-y: 2.8rem
  }

  .print\:translate-y-32 {
    --tw-translate-y: 3.2rem
  }

  .print\:translate-y-36 {
    --tw-translate-y: 3.6rem
  }

  .print\:translate-y-40 {
    --tw-translate-y: 4rem
  }

  .print\:translate-y-44 {
    --tw-translate-y: 4.4rem
  }

  .print\:translate-y-48 {
    --tw-translate-y: 4.8rem
  }

  .print\:translate-y-52 {
    --tw-translate-y: 5.2rem
  }

  .print\:translate-y-56 {
    --tw-translate-y: 5.6rem
  }

  .print\:translate-y-60 {
    --tw-translate-y: 6rem
  }

  .print\:translate-y-64 {
    --tw-translate-y: 6.4rem
  }

  .print\:translate-y-68 {
    --tw-translate-y: 6.8rem
  }

  .print\:translate-y-72 {
    --tw-translate-y: 7.2rem
  }

  .print\:translate-y-76 {
    --tw-translate-y: 7.6rem
  }

  .print\:translate-y-80 {
    --tw-translate-y: 8rem
  }

  .print\:translate-y-84 {
    --tw-translate-y: 8.4rem
  }

  .print\:translate-y-88 {
    --tw-translate-y: 8.8rem
  }

  .print\:translate-y-92 {
    --tw-translate-y: 9.2rem
  }

  .print\:translate-y-96 {
    --tw-translate-y: 9.6rem
  }

  .print\:translate-y-128 {
    --tw-translate-y: 12.8rem
  }

  .print\:translate-y-136 {
    --tw-translate-y: 13.6rem
  }

  .print\:translate-y-160 {
    --tw-translate-y: 16rem
  }

  .print\:translate-y-192 {
    --tw-translate-y: 19.2rem
  }

  .print\:translate-y-200 {
    --tw-translate-y: 20rem
  }

  .print\:translate-y-208 {
    --tw-translate-y: 20.8rem
  }

  .print\:translate-y-216 {
    --tw-translate-y: 21.6rem
  }

  .print\:translate-y-224 {
    --tw-translate-y: 22.4rem
  }

  .print\:translate-y-256 {
    --tw-translate-y: 25.6rem
  }

  .print\:translate-y-288 {
    --tw-translate-y: 28.8rem
  }

  .print\:translate-y-320 {
    --tw-translate-y: 32rem
  }

  .print\:translate-y-360 {
    --tw-translate-y: 36rem
  }

  .print\:translate-y-384 {
    --tw-translate-y: 38.4rem
  }

  .print\:translate-y-400 {
    --tw-translate-y: 40rem
  }

  .print\:translate-y-512 {
    --tw-translate-y: 51.2rem
  }

  .print\:translate-y-640 {
    --tw-translate-y: 64rem
  }

  .print\:translate-y-xs {
    --tw-translate-y: 32rem
  }

  .print\:translate-y-sm {
    --tw-translate-y: 48rem
  }

  .print\:translate-y-md {
    --tw-translate-y: 64rem
  }

  .print\:translate-y-lg {
    --tw-translate-y: 80rem
  }

  .print\:translate-y-xl {
    --tw-translate-y: 96rem
  }

  .print\:translate-y-2xl {
    --tw-translate-y: 112rem
  }

  .print\:translate-y-3xl {
    --tw-translate-y: 128rem
  }

  .print\:translate-y-4xl {
    --tw-translate-y: 144rem
  }

  .print\:translate-y-5xl {
    --tw-translate-y: 160rem
  }

  .print\:translate-y-px {
    --tw-translate-y: 1px
  }

  .print\:translate-y-0\.5 {
    --tw-translate-y: 0.05rem
  }

  .print\:translate-y-1\.5 {
    --tw-translate-y: 0.15rem
  }

  .print\:translate-y-2\.5 {
    --tw-translate-y: 0.25rem
  }

  .print\:translate-y-3\.5 {
    --tw-translate-y: 0.35rem
  }

  .print\:-translate-y-1 {
    --tw-translate-y: -0.1rem
  }

  .print\:-translate-y-2 {
    --tw-translate-y: -0.2rem
  }

  .print\:-translate-y-3 {
    --tw-translate-y: -0.3rem
  }

  .print\:-translate-y-4 {
    --tw-translate-y: -0.4rem
  }

  .print\:-translate-y-5 {
    --tw-translate-y: -0.5rem
  }

  .print\:-translate-y-6 {
    --tw-translate-y: -0.6rem
  }

  .print\:-translate-y-7 {
    --tw-translate-y: -0.7rem
  }

  .print\:-translate-y-8 {
    --tw-translate-y: -0.8rem
  }

  .print\:-translate-y-9 {
    --tw-translate-y: -0.9rem
  }

  .print\:-translate-y-10 {
    --tw-translate-y: -1rem
  }

  .print\:-translate-y-12 {
    --tw-translate-y: -1.2rem
  }

  .print\:-translate-y-14 {
    --tw-translate-y: -1.4rem
  }

  .print\:-translate-y-16 {
    --tw-translate-y: -1.6rem
  }

  .print\:-translate-y-20 {
    --tw-translate-y: -2rem
  }

  .print\:-translate-y-24 {
    --tw-translate-y: -2.4rem
  }

  .print\:-translate-y-28 {
    --tw-translate-y: -2.8rem
  }

  .print\:-translate-y-32 {
    --tw-translate-y: -3.2rem
  }

  .print\:-translate-y-36 {
    --tw-translate-y: -3.6rem
  }

  .print\:-translate-y-40 {
    --tw-translate-y: -4rem
  }

  .print\:-translate-y-44 {
    --tw-translate-y: -4.4rem
  }

  .print\:-translate-y-48 {
    --tw-translate-y: -4.8rem
  }

  .print\:-translate-y-52 {
    --tw-translate-y: -5.2rem
  }

  .print\:-translate-y-56 {
    --tw-translate-y: -5.6rem
  }

  .print\:-translate-y-60 {
    --tw-translate-y: -6rem
  }

  .print\:-translate-y-64 {
    --tw-translate-y: -6.4rem
  }

  .print\:-translate-y-68 {
    --tw-translate-y: -6.8rem
  }

  .print\:-translate-y-72 {
    --tw-translate-y: -7.2rem
  }

  .print\:-translate-y-76 {
    --tw-translate-y: -7.6rem
  }

  .print\:-translate-y-80 {
    --tw-translate-y: -8rem
  }

  .print\:-translate-y-84 {
    --tw-translate-y: -8.4rem
  }

  .print\:-translate-y-88 {
    --tw-translate-y: -8.8rem
  }

  .print\:-translate-y-92 {
    --tw-translate-y: -9.2rem
  }

  .print\:-translate-y-96 {
    --tw-translate-y: -9.6rem
  }

  .print\:-translate-y-128 {
    --tw-translate-y: -12.8rem
  }

  .print\:-translate-y-136 {
    --tw-translate-y: -13.6rem
  }

  .print\:-translate-y-160 {
    --tw-translate-y: -16rem
  }

  .print\:-translate-y-192 {
    --tw-translate-y: -19.2rem
  }

  .print\:-translate-y-200 {
    --tw-translate-y: -20rem
  }

  .print\:-translate-y-208 {
    --tw-translate-y: -20.8rem
  }

  .print\:-translate-y-216 {
    --tw-translate-y: -21.6rem
  }

  .print\:-translate-y-224 {
    --tw-translate-y: -22.4rem
  }

  .print\:-translate-y-256 {
    --tw-translate-y: -25.6rem
  }

  .print\:-translate-y-288 {
    --tw-translate-y: -28.8rem
  }

  .print\:-translate-y-320 {
    --tw-translate-y: -32rem
  }

  .print\:-translate-y-360 {
    --tw-translate-y: -36rem
  }

  .print\:-translate-y-384 {
    --tw-translate-y: -38.4rem
  }

  .print\:-translate-y-400 {
    --tw-translate-y: -40rem
  }

  .print\:-translate-y-512 {
    --tw-translate-y: -51.2rem
  }

  .print\:-translate-y-640 {
    --tw-translate-y: -64rem
  }

  .print\:-translate-y-xs {
    --tw-translate-y: -32rem
  }

  .print\:-translate-y-sm {
    --tw-translate-y: -48rem
  }

  .print\:-translate-y-md {
    --tw-translate-y: -64rem
  }

  .print\:-translate-y-lg {
    --tw-translate-y: -80rem
  }

  .print\:-translate-y-xl {
    --tw-translate-y: -96rem
  }

  .print\:-translate-y-2xl {
    --tw-translate-y: -112rem
  }

  .print\:-translate-y-3xl {
    --tw-translate-y: -128rem
  }

  .print\:-translate-y-4xl {
    --tw-translate-y: -144rem
  }

  .print\:-translate-y-5xl {
    --tw-translate-y: -160rem
  }

  .print\:-translate-y-px {
    --tw-translate-y: -1px
  }

  .print\:-translate-y-0\.5 {
    --tw-translate-y: -0.05rem
  }

  .print\:-translate-y-1\.5 {
    --tw-translate-y: -0.15rem
  }

  .print\:-translate-y-2\.5 {
    --tw-translate-y: -0.25rem
  }

  .print\:-translate-y-3\.5 {
    --tw-translate-y: -0.35rem
  }

  .print\:translate-y-1\/2 {
    --tw-translate-y: 50%
  }

  .print\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%
  }

  .print\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%
  }

  .print\:translate-y-1\/4 {
    --tw-translate-y: 25%
  }

  .print\:translate-y-2\/4 {
    --tw-translate-y: 50%
  }

  .print\:translate-y-3\/4 {
    --tw-translate-y: 75%
  }

  .print\:translate-y-full {
    --tw-translate-y: 100%
  }

  .print\:-translate-y-1\/2 {
    --tw-translate-y: -50%
  }

  .print\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%
  }

  .print\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%
  }

  .print\:-translate-y-1\/4 {
    --tw-translate-y: -25%
  }

  .print\:-translate-y-2\/4 {
    --tw-translate-y: -50%
  }

  .print\:-translate-y-3\/4 {
    --tw-translate-y: -75%
  }

  .print\:-translate-y-full {
    --tw-translate-y: -100%
  }

  .print\:hover\:translate-x-0:hover {
    --tw-translate-x: 0
  }

  .print\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.1rem
  }

  .print\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.2rem
  }

  .print\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.3rem
  }

  .print\:hover\:translate-x-4:hover {
    --tw-translate-x: 0.4rem
  }

  .print\:hover\:translate-x-5:hover {
    --tw-translate-x: 0.5rem
  }

  .print\:hover\:translate-x-6:hover {
    --tw-translate-x: 0.6rem
  }

  .print\:hover\:translate-x-7:hover {
    --tw-translate-x: 0.7rem
  }

  .print\:hover\:translate-x-8:hover {
    --tw-translate-x: 0.8rem
  }

  .print\:hover\:translate-x-9:hover {
    --tw-translate-x: 0.9rem
  }

  .print\:hover\:translate-x-10:hover {
    --tw-translate-x: 1.0rem
  }

  .print\:hover\:translate-x-12:hover {
    --tw-translate-x: 1.2rem
  }

  .print\:hover\:translate-x-14:hover {
    --tw-translate-x: 1.4rem
  }

  .print\:hover\:translate-x-16:hover {
    --tw-translate-x: 1.6rem
  }

  .print\:hover\:translate-x-20:hover {
    --tw-translate-x: 2rem
  }

  .print\:hover\:translate-x-24:hover {
    --tw-translate-x: 2.4rem
  }

  .print\:hover\:translate-x-28:hover {
    --tw-translate-x: 2.8rem
  }

  .print\:hover\:translate-x-32:hover {
    --tw-translate-x: 3.2rem
  }

  .print\:hover\:translate-x-36:hover {
    --tw-translate-x: 3.6rem
  }

  .print\:hover\:translate-x-40:hover {
    --tw-translate-x: 4rem
  }

  .print\:hover\:translate-x-44:hover {
    --tw-translate-x: 4.4rem
  }

  .print\:hover\:translate-x-48:hover {
    --tw-translate-x: 4.8rem
  }

  .print\:hover\:translate-x-52:hover {
    --tw-translate-x: 5.2rem
  }

  .print\:hover\:translate-x-56:hover {
    --tw-translate-x: 5.6rem
  }

  .print\:hover\:translate-x-60:hover {
    --tw-translate-x: 6rem
  }

  .print\:hover\:translate-x-64:hover {
    --tw-translate-x: 6.4rem
  }

  .print\:hover\:translate-x-68:hover {
    --tw-translate-x: 6.8rem
  }

  .print\:hover\:translate-x-72:hover {
    --tw-translate-x: 7.2rem
  }

  .print\:hover\:translate-x-76:hover {
    --tw-translate-x: 7.6rem
  }

  .print\:hover\:translate-x-80:hover {
    --tw-translate-x: 8rem
  }

  .print\:hover\:translate-x-84:hover {
    --tw-translate-x: 8.4rem
  }

  .print\:hover\:translate-x-88:hover {
    --tw-translate-x: 8.8rem
  }

  .print\:hover\:translate-x-92:hover {
    --tw-translate-x: 9.2rem
  }

  .print\:hover\:translate-x-96:hover {
    --tw-translate-x: 9.6rem
  }

  .print\:hover\:translate-x-128:hover {
    --tw-translate-x: 12.8rem
  }

  .print\:hover\:translate-x-136:hover {
    --tw-translate-x: 13.6rem
  }

  .print\:hover\:translate-x-160:hover {
    --tw-translate-x: 16rem
  }

  .print\:hover\:translate-x-192:hover {
    --tw-translate-x: 19.2rem
  }

  .print\:hover\:translate-x-200:hover {
    --tw-translate-x: 20rem
  }

  .print\:hover\:translate-x-208:hover {
    --tw-translate-x: 20.8rem
  }

  .print\:hover\:translate-x-216:hover {
    --tw-translate-x: 21.6rem
  }

  .print\:hover\:translate-x-224:hover {
    --tw-translate-x: 22.4rem
  }

  .print\:hover\:translate-x-256:hover {
    --tw-translate-x: 25.6rem
  }

  .print\:hover\:translate-x-288:hover {
    --tw-translate-x: 28.8rem
  }

  .print\:hover\:translate-x-320:hover {
    --tw-translate-x: 32rem
  }

  .print\:hover\:translate-x-360:hover {
    --tw-translate-x: 36rem
  }

  .print\:hover\:translate-x-384:hover {
    --tw-translate-x: 38.4rem
  }

  .print\:hover\:translate-x-400:hover {
    --tw-translate-x: 40rem
  }

  .print\:hover\:translate-x-512:hover {
    --tw-translate-x: 51.2rem
  }

  .print\:hover\:translate-x-640:hover {
    --tw-translate-x: 64rem
  }

  .print\:hover\:translate-x-xs:hover {
    --tw-translate-x: 32rem
  }

  .print\:hover\:translate-x-sm:hover {
    --tw-translate-x: 48rem
  }

  .print\:hover\:translate-x-md:hover {
    --tw-translate-x: 64rem
  }

  .print\:hover\:translate-x-lg:hover {
    --tw-translate-x: 80rem
  }

  .print\:hover\:translate-x-xl:hover {
    --tw-translate-x: 96rem
  }

  .print\:hover\:translate-x-2xl:hover {
    --tw-translate-x: 112rem
  }

  .print\:hover\:translate-x-3xl:hover {
    --tw-translate-x: 128rem
  }

  .print\:hover\:translate-x-4xl:hover {
    --tw-translate-x: 144rem
  }

  .print\:hover\:translate-x-5xl:hover {
    --tw-translate-x: 160rem
  }

  .print\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px
  }

  .print\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.05rem
  }

  .print\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.15rem
  }

  .print\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.25rem
  }

  .print\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.35rem
  }

  .print\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.1rem
  }

  .print\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.2rem
  }

  .print\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.3rem
  }

  .print\:hover\:-translate-x-4:hover {
    --tw-translate-x: -0.4rem
  }

  .print\:hover\:-translate-x-5:hover {
    --tw-translate-x: -0.5rem
  }

  .print\:hover\:-translate-x-6:hover {
    --tw-translate-x: -0.6rem
  }

  .print\:hover\:-translate-x-7:hover {
    --tw-translate-x: -0.7rem
  }

  .print\:hover\:-translate-x-8:hover {
    --tw-translate-x: -0.8rem
  }

  .print\:hover\:-translate-x-9:hover {
    --tw-translate-x: -0.9rem
  }

  .print\:hover\:-translate-x-10:hover {
    --tw-translate-x: -1rem
  }

  .print\:hover\:-translate-x-12:hover {
    --tw-translate-x: -1.2rem
  }

  .print\:hover\:-translate-x-14:hover {
    --tw-translate-x: -1.4rem
  }

  .print\:hover\:-translate-x-16:hover {
    --tw-translate-x: -1.6rem
  }

  .print\:hover\:-translate-x-20:hover {
    --tw-translate-x: -2rem
  }

  .print\:hover\:-translate-x-24:hover {
    --tw-translate-x: -2.4rem
  }

  .print\:hover\:-translate-x-28:hover {
    --tw-translate-x: -2.8rem
  }

  .print\:hover\:-translate-x-32:hover {
    --tw-translate-x: -3.2rem
  }

  .print\:hover\:-translate-x-36:hover {
    --tw-translate-x: -3.6rem
  }

  .print\:hover\:-translate-x-40:hover {
    --tw-translate-x: -4rem
  }

  .print\:hover\:-translate-x-44:hover {
    --tw-translate-x: -4.4rem
  }

  .print\:hover\:-translate-x-48:hover {
    --tw-translate-x: -4.8rem
  }

  .print\:hover\:-translate-x-52:hover {
    --tw-translate-x: -5.2rem
  }

  .print\:hover\:-translate-x-56:hover {
    --tw-translate-x: -5.6rem
  }

  .print\:hover\:-translate-x-60:hover {
    --tw-translate-x: -6rem
  }

  .print\:hover\:-translate-x-64:hover {
    --tw-translate-x: -6.4rem
  }

  .print\:hover\:-translate-x-68:hover {
    --tw-translate-x: -6.8rem
  }

  .print\:hover\:-translate-x-72:hover {
    --tw-translate-x: -7.2rem
  }

  .print\:hover\:-translate-x-76:hover {
    --tw-translate-x: -7.6rem
  }

  .print\:hover\:-translate-x-80:hover {
    --tw-translate-x: -8rem
  }

  .print\:hover\:-translate-x-84:hover {
    --tw-translate-x: -8.4rem
  }

  .print\:hover\:-translate-x-88:hover {
    --tw-translate-x: -8.8rem
  }

  .print\:hover\:-translate-x-92:hover {
    --tw-translate-x: -9.2rem
  }

  .print\:hover\:-translate-x-96:hover {
    --tw-translate-x: -9.6rem
  }

  .print\:hover\:-translate-x-128:hover {
    --tw-translate-x: -12.8rem
  }

  .print\:hover\:-translate-x-136:hover {
    --tw-translate-x: -13.6rem
  }

  .print\:hover\:-translate-x-160:hover {
    --tw-translate-x: -16rem
  }

  .print\:hover\:-translate-x-192:hover {
    --tw-translate-x: -19.2rem
  }

  .print\:hover\:-translate-x-200:hover {
    --tw-translate-x: -20rem
  }

  .print\:hover\:-translate-x-208:hover {
    --tw-translate-x: -20.8rem
  }

  .print\:hover\:-translate-x-216:hover {
    --tw-translate-x: -21.6rem
  }

  .print\:hover\:-translate-x-224:hover {
    --tw-translate-x: -22.4rem
  }

  .print\:hover\:-translate-x-256:hover {
    --tw-translate-x: -25.6rem
  }

  .print\:hover\:-translate-x-288:hover {
    --tw-translate-x: -28.8rem
  }

  .print\:hover\:-translate-x-320:hover {
    --tw-translate-x: -32rem
  }

  .print\:hover\:-translate-x-360:hover {
    --tw-translate-x: -36rem
  }

  .print\:hover\:-translate-x-384:hover {
    --tw-translate-x: -38.4rem
  }

  .print\:hover\:-translate-x-400:hover {
    --tw-translate-x: -40rem
  }

  .print\:hover\:-translate-x-512:hover {
    --tw-translate-x: -51.2rem
  }

  .print\:hover\:-translate-x-640:hover {
    --tw-translate-x: -64rem
  }

  .print\:hover\:-translate-x-xs:hover {
    --tw-translate-x: -32rem
  }

  .print\:hover\:-translate-x-sm:hover {
    --tw-translate-x: -48rem
  }

  .print\:hover\:-translate-x-md:hover {
    --tw-translate-x: -64rem
  }

  .print\:hover\:-translate-x-lg:hover {
    --tw-translate-x: -80rem
  }

  .print\:hover\:-translate-x-xl:hover {
    --tw-translate-x: -96rem
  }

  .print\:hover\:-translate-x-2xl:hover {
    --tw-translate-x: -112rem
  }

  .print\:hover\:-translate-x-3xl:hover {
    --tw-translate-x: -128rem
  }

  .print\:hover\:-translate-x-4xl:hover {
    --tw-translate-x: -144rem
  }

  .print\:hover\:-translate-x-5xl:hover {
    --tw-translate-x: -160rem
  }

  .print\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px
  }

  .print\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.05rem
  }

  .print\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.15rem
  }

  .print\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.25rem
  }

  .print\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.35rem
  }

  .print\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%
  }

  .print\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%
  }

  .print\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%
  }

  .print\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%
  }

  .print\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%
  }

  .print\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%
  }

  .print\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%
  }

  .print\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%
  }

  .print\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%
  }

  .print\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%
  }

  .print\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%
  }

  .print\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%
  }

  .print\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%
  }

  .print\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%
  }

  .print\:hover\:translate-y-0:hover {
    --tw-translate-y: 0
  }

  .print\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.1rem
  }

  .print\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.2rem
  }

  .print\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.3rem
  }

  .print\:hover\:translate-y-4:hover {
    --tw-translate-y: 0.4rem
  }

  .print\:hover\:translate-y-5:hover {
    --tw-translate-y: 0.5rem
  }

  .print\:hover\:translate-y-6:hover {
    --tw-translate-y: 0.6rem
  }

  .print\:hover\:translate-y-7:hover {
    --tw-translate-y: 0.7rem
  }

  .print\:hover\:translate-y-8:hover {
    --tw-translate-y: 0.8rem
  }

  .print\:hover\:translate-y-9:hover {
    --tw-translate-y: 0.9rem
  }

  .print\:hover\:translate-y-10:hover {
    --tw-translate-y: 1.0rem
  }

  .print\:hover\:translate-y-12:hover {
    --tw-translate-y: 1.2rem
  }

  .print\:hover\:translate-y-14:hover {
    --tw-translate-y: 1.4rem
  }

  .print\:hover\:translate-y-16:hover {
    --tw-translate-y: 1.6rem
  }

  .print\:hover\:translate-y-20:hover {
    --tw-translate-y: 2rem
  }

  .print\:hover\:translate-y-24:hover {
    --tw-translate-y: 2.4rem
  }

  .print\:hover\:translate-y-28:hover {
    --tw-translate-y: 2.8rem
  }

  .print\:hover\:translate-y-32:hover {
    --tw-translate-y: 3.2rem
  }

  .print\:hover\:translate-y-36:hover {
    --tw-translate-y: 3.6rem
  }

  .print\:hover\:translate-y-40:hover {
    --tw-translate-y: 4rem
  }

  .print\:hover\:translate-y-44:hover {
    --tw-translate-y: 4.4rem
  }

  .print\:hover\:translate-y-48:hover {
    --tw-translate-y: 4.8rem
  }

  .print\:hover\:translate-y-52:hover {
    --tw-translate-y: 5.2rem
  }

  .print\:hover\:translate-y-56:hover {
    --tw-translate-y: 5.6rem
  }

  .print\:hover\:translate-y-60:hover {
    --tw-translate-y: 6rem
  }

  .print\:hover\:translate-y-64:hover {
    --tw-translate-y: 6.4rem
  }

  .print\:hover\:translate-y-68:hover {
    --tw-translate-y: 6.8rem
  }

  .print\:hover\:translate-y-72:hover {
    --tw-translate-y: 7.2rem
  }

  .print\:hover\:translate-y-76:hover {
    --tw-translate-y: 7.6rem
  }

  .print\:hover\:translate-y-80:hover {
    --tw-translate-y: 8rem
  }

  .print\:hover\:translate-y-84:hover {
    --tw-translate-y: 8.4rem
  }

  .print\:hover\:translate-y-88:hover {
    --tw-translate-y: 8.8rem
  }

  .print\:hover\:translate-y-92:hover {
    --tw-translate-y: 9.2rem
  }

  .print\:hover\:translate-y-96:hover {
    --tw-translate-y: 9.6rem
  }

  .print\:hover\:translate-y-128:hover {
    --tw-translate-y: 12.8rem
  }

  .print\:hover\:translate-y-136:hover {
    --tw-translate-y: 13.6rem
  }

  .print\:hover\:translate-y-160:hover {
    --tw-translate-y: 16rem
  }

  .print\:hover\:translate-y-192:hover {
    --tw-translate-y: 19.2rem
  }

  .print\:hover\:translate-y-200:hover {
    --tw-translate-y: 20rem
  }

  .print\:hover\:translate-y-208:hover {
    --tw-translate-y: 20.8rem
  }

  .print\:hover\:translate-y-216:hover {
    --tw-translate-y: 21.6rem
  }

  .print\:hover\:translate-y-224:hover {
    --tw-translate-y: 22.4rem
  }

  .print\:hover\:translate-y-256:hover {
    --tw-translate-y: 25.6rem
  }

  .print\:hover\:translate-y-288:hover {
    --tw-translate-y: 28.8rem
  }

  .print\:hover\:translate-y-320:hover {
    --tw-translate-y: 32rem
  }

  .print\:hover\:translate-y-360:hover {
    --tw-translate-y: 36rem
  }

  .print\:hover\:translate-y-384:hover {
    --tw-translate-y: 38.4rem
  }

  .print\:hover\:translate-y-400:hover {
    --tw-translate-y: 40rem
  }

  .print\:hover\:translate-y-512:hover {
    --tw-translate-y: 51.2rem
  }

  .print\:hover\:translate-y-640:hover {
    --tw-translate-y: 64rem
  }

  .print\:hover\:translate-y-xs:hover {
    --tw-translate-y: 32rem
  }

  .print\:hover\:translate-y-sm:hover {
    --tw-translate-y: 48rem
  }

  .print\:hover\:translate-y-md:hover {
    --tw-translate-y: 64rem
  }

  .print\:hover\:translate-y-lg:hover {
    --tw-translate-y: 80rem
  }

  .print\:hover\:translate-y-xl:hover {
    --tw-translate-y: 96rem
  }

  .print\:hover\:translate-y-2xl:hover {
    --tw-translate-y: 112rem
  }

  .print\:hover\:translate-y-3xl:hover {
    --tw-translate-y: 128rem
  }

  .print\:hover\:translate-y-4xl:hover {
    --tw-translate-y: 144rem
  }

  .print\:hover\:translate-y-5xl:hover {
    --tw-translate-y: 160rem
  }

  .print\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px
  }

  .print\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.05rem
  }

  .print\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.15rem
  }

  .print\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.25rem
  }

  .print\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.35rem
  }

  .print\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.1rem
  }

  .print\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.2rem
  }

  .print\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.3rem
  }

  .print\:hover\:-translate-y-4:hover {
    --tw-translate-y: -0.4rem
  }

  .print\:hover\:-translate-y-5:hover {
    --tw-translate-y: -0.5rem
  }

  .print\:hover\:-translate-y-6:hover {
    --tw-translate-y: -0.6rem
  }

  .print\:hover\:-translate-y-7:hover {
    --tw-translate-y: -0.7rem
  }

  .print\:hover\:-translate-y-8:hover {
    --tw-translate-y: -0.8rem
  }

  .print\:hover\:-translate-y-9:hover {
    --tw-translate-y: -0.9rem
  }

  .print\:hover\:-translate-y-10:hover {
    --tw-translate-y: -1rem
  }

  .print\:hover\:-translate-y-12:hover {
    --tw-translate-y: -1.2rem
  }

  .print\:hover\:-translate-y-14:hover {
    --tw-translate-y: -1.4rem
  }

  .print\:hover\:-translate-y-16:hover {
    --tw-translate-y: -1.6rem
  }

  .print\:hover\:-translate-y-20:hover {
    --tw-translate-y: -2rem
  }

  .print\:hover\:-translate-y-24:hover {
    --tw-translate-y: -2.4rem
  }

  .print\:hover\:-translate-y-28:hover {
    --tw-translate-y: -2.8rem
  }

  .print\:hover\:-translate-y-32:hover {
    --tw-translate-y: -3.2rem
  }

  .print\:hover\:-translate-y-36:hover {
    --tw-translate-y: -3.6rem
  }

  .print\:hover\:-translate-y-40:hover {
    --tw-translate-y: -4rem
  }

  .print\:hover\:-translate-y-44:hover {
    --tw-translate-y: -4.4rem
  }

  .print\:hover\:-translate-y-48:hover {
    --tw-translate-y: -4.8rem
  }

  .print\:hover\:-translate-y-52:hover {
    --tw-translate-y: -5.2rem
  }

  .print\:hover\:-translate-y-56:hover {
    --tw-translate-y: -5.6rem
  }

  .print\:hover\:-translate-y-60:hover {
    --tw-translate-y: -6rem
  }

  .print\:hover\:-translate-y-64:hover {
    --tw-translate-y: -6.4rem
  }

  .print\:hover\:-translate-y-68:hover {
    --tw-translate-y: -6.8rem
  }

  .print\:hover\:-translate-y-72:hover {
    --tw-translate-y: -7.2rem
  }

  .print\:hover\:-translate-y-76:hover {
    --tw-translate-y: -7.6rem
  }

  .print\:hover\:-translate-y-80:hover {
    --tw-translate-y: -8rem
  }

  .print\:hover\:-translate-y-84:hover {
    --tw-translate-y: -8.4rem
  }

  .print\:hover\:-translate-y-88:hover {
    --tw-translate-y: -8.8rem
  }

  .print\:hover\:-translate-y-92:hover {
    --tw-translate-y: -9.2rem
  }

  .print\:hover\:-translate-y-96:hover {
    --tw-translate-y: -9.6rem
  }

  .print\:hover\:-translate-y-128:hover {
    --tw-translate-y: -12.8rem
  }

  .print\:hover\:-translate-y-136:hover {
    --tw-translate-y: -13.6rem
  }

  .print\:hover\:-translate-y-160:hover {
    --tw-translate-y: -16rem
  }

  .print\:hover\:-translate-y-192:hover {
    --tw-translate-y: -19.2rem
  }

  .print\:hover\:-translate-y-200:hover {
    --tw-translate-y: -20rem
  }

  .print\:hover\:-translate-y-208:hover {
    --tw-translate-y: -20.8rem
  }

  .print\:hover\:-translate-y-216:hover {
    --tw-translate-y: -21.6rem
  }

  .print\:hover\:-translate-y-224:hover {
    --tw-translate-y: -22.4rem
  }

  .print\:hover\:-translate-y-256:hover {
    --tw-translate-y: -25.6rem
  }

  .print\:hover\:-translate-y-288:hover {
    --tw-translate-y: -28.8rem
  }

  .print\:hover\:-translate-y-320:hover {
    --tw-translate-y: -32rem
  }

  .print\:hover\:-translate-y-360:hover {
    --tw-translate-y: -36rem
  }

  .print\:hover\:-translate-y-384:hover {
    --tw-translate-y: -38.4rem
  }

  .print\:hover\:-translate-y-400:hover {
    --tw-translate-y: -40rem
  }

  .print\:hover\:-translate-y-512:hover {
    --tw-translate-y: -51.2rem
  }

  .print\:hover\:-translate-y-640:hover {
    --tw-translate-y: -64rem
  }

  .print\:hover\:-translate-y-xs:hover {
    --tw-translate-y: -32rem
  }

  .print\:hover\:-translate-y-sm:hover {
    --tw-translate-y: -48rem
  }

  .print\:hover\:-translate-y-md:hover {
    --tw-translate-y: -64rem
  }

  .print\:hover\:-translate-y-lg:hover {
    --tw-translate-y: -80rem
  }

  .print\:hover\:-translate-y-xl:hover {
    --tw-translate-y: -96rem
  }

  .print\:hover\:-translate-y-2xl:hover {
    --tw-translate-y: -112rem
  }

  .print\:hover\:-translate-y-3xl:hover {
    --tw-translate-y: -128rem
  }

  .print\:hover\:-translate-y-4xl:hover {
    --tw-translate-y: -144rem
  }

  .print\:hover\:-translate-y-5xl:hover {
    --tw-translate-y: -160rem
  }

  .print\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px
  }

  .print\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.05rem
  }

  .print\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.15rem
  }

  .print\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.25rem
  }

  .print\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.35rem
  }

  .print\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%
  }

  .print\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%
  }

  .print\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%
  }

  .print\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%
  }

  .print\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%
  }

  .print\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%
  }

  .print\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%
  }

  .print\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%
  }

  .print\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%
  }

  .print\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%
  }

  .print\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%
  }

  .print\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%
  }

  .print\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%
  }

  .print\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%
  }

  .print\:focus\:translate-x-0:focus {
    --tw-translate-x: 0
  }

  .print\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.1rem
  }

  .print\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.2rem
  }

  .print\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.3rem
  }

  .print\:focus\:translate-x-4:focus {
    --tw-translate-x: 0.4rem
  }

  .print\:focus\:translate-x-5:focus {
    --tw-translate-x: 0.5rem
  }

  .print\:focus\:translate-x-6:focus {
    --tw-translate-x: 0.6rem
  }

  .print\:focus\:translate-x-7:focus {
    --tw-translate-x: 0.7rem
  }

  .print\:focus\:translate-x-8:focus {
    --tw-translate-x: 0.8rem
  }

  .print\:focus\:translate-x-9:focus {
    --tw-translate-x: 0.9rem
  }

  .print\:focus\:translate-x-10:focus {
    --tw-translate-x: 1.0rem
  }

  .print\:focus\:translate-x-12:focus {
    --tw-translate-x: 1.2rem
  }

  .print\:focus\:translate-x-14:focus {
    --tw-translate-x: 1.4rem
  }

  .print\:focus\:translate-x-16:focus {
    --tw-translate-x: 1.6rem
  }

  .print\:focus\:translate-x-20:focus {
    --tw-translate-x: 2rem
  }

  .print\:focus\:translate-x-24:focus {
    --tw-translate-x: 2.4rem
  }

  .print\:focus\:translate-x-28:focus {
    --tw-translate-x: 2.8rem
  }

  .print\:focus\:translate-x-32:focus {
    --tw-translate-x: 3.2rem
  }

  .print\:focus\:translate-x-36:focus {
    --tw-translate-x: 3.6rem
  }

  .print\:focus\:translate-x-40:focus {
    --tw-translate-x: 4rem
  }

  .print\:focus\:translate-x-44:focus {
    --tw-translate-x: 4.4rem
  }

  .print\:focus\:translate-x-48:focus {
    --tw-translate-x: 4.8rem
  }

  .print\:focus\:translate-x-52:focus {
    --tw-translate-x: 5.2rem
  }

  .print\:focus\:translate-x-56:focus {
    --tw-translate-x: 5.6rem
  }

  .print\:focus\:translate-x-60:focus {
    --tw-translate-x: 6rem
  }

  .print\:focus\:translate-x-64:focus {
    --tw-translate-x: 6.4rem
  }

  .print\:focus\:translate-x-68:focus {
    --tw-translate-x: 6.8rem
  }

  .print\:focus\:translate-x-72:focus {
    --tw-translate-x: 7.2rem
  }

  .print\:focus\:translate-x-76:focus {
    --tw-translate-x: 7.6rem
  }

  .print\:focus\:translate-x-80:focus {
    --tw-translate-x: 8rem
  }

  .print\:focus\:translate-x-84:focus {
    --tw-translate-x: 8.4rem
  }

  .print\:focus\:translate-x-88:focus {
    --tw-translate-x: 8.8rem
  }

  .print\:focus\:translate-x-92:focus {
    --tw-translate-x: 9.2rem
  }

  .print\:focus\:translate-x-96:focus {
    --tw-translate-x: 9.6rem
  }

  .print\:focus\:translate-x-128:focus {
    --tw-translate-x: 12.8rem
  }

  .print\:focus\:translate-x-136:focus {
    --tw-translate-x: 13.6rem
  }

  .print\:focus\:translate-x-160:focus {
    --tw-translate-x: 16rem
  }

  .print\:focus\:translate-x-192:focus {
    --tw-translate-x: 19.2rem
  }

  .print\:focus\:translate-x-200:focus {
    --tw-translate-x: 20rem
  }

  .print\:focus\:translate-x-208:focus {
    --tw-translate-x: 20.8rem
  }

  .print\:focus\:translate-x-216:focus {
    --tw-translate-x: 21.6rem
  }

  .print\:focus\:translate-x-224:focus {
    --tw-translate-x: 22.4rem
  }

  .print\:focus\:translate-x-256:focus {
    --tw-translate-x: 25.6rem
  }

  .print\:focus\:translate-x-288:focus {
    --tw-translate-x: 28.8rem
  }

  .print\:focus\:translate-x-320:focus {
    --tw-translate-x: 32rem
  }

  .print\:focus\:translate-x-360:focus {
    --tw-translate-x: 36rem
  }

  .print\:focus\:translate-x-384:focus {
    --tw-translate-x: 38.4rem
  }

  .print\:focus\:translate-x-400:focus {
    --tw-translate-x: 40rem
  }

  .print\:focus\:translate-x-512:focus {
    --tw-translate-x: 51.2rem
  }

  .print\:focus\:translate-x-640:focus {
    --tw-translate-x: 64rem
  }

  .print\:focus\:translate-x-xs:focus {
    --tw-translate-x: 32rem
  }

  .print\:focus\:translate-x-sm:focus {
    --tw-translate-x: 48rem
  }

  .print\:focus\:translate-x-md:focus {
    --tw-translate-x: 64rem
  }

  .print\:focus\:translate-x-lg:focus {
    --tw-translate-x: 80rem
  }

  .print\:focus\:translate-x-xl:focus {
    --tw-translate-x: 96rem
  }

  .print\:focus\:translate-x-2xl:focus {
    --tw-translate-x: 112rem
  }

  .print\:focus\:translate-x-3xl:focus {
    --tw-translate-x: 128rem
  }

  .print\:focus\:translate-x-4xl:focus {
    --tw-translate-x: 144rem
  }

  .print\:focus\:translate-x-5xl:focus {
    --tw-translate-x: 160rem
  }

  .print\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px
  }

  .print\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.05rem
  }

  .print\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.15rem
  }

  .print\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.25rem
  }

  .print\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.35rem
  }

  .print\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.1rem
  }

  .print\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.2rem
  }

  .print\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.3rem
  }

  .print\:focus\:-translate-x-4:focus {
    --tw-translate-x: -0.4rem
  }

  .print\:focus\:-translate-x-5:focus {
    --tw-translate-x: -0.5rem
  }

  .print\:focus\:-translate-x-6:focus {
    --tw-translate-x: -0.6rem
  }

  .print\:focus\:-translate-x-7:focus {
    --tw-translate-x: -0.7rem
  }

  .print\:focus\:-translate-x-8:focus {
    --tw-translate-x: -0.8rem
  }

  .print\:focus\:-translate-x-9:focus {
    --tw-translate-x: -0.9rem
  }

  .print\:focus\:-translate-x-10:focus {
    --tw-translate-x: -1rem
  }

  .print\:focus\:-translate-x-12:focus {
    --tw-translate-x: -1.2rem
  }

  .print\:focus\:-translate-x-14:focus {
    --tw-translate-x: -1.4rem
  }

  .print\:focus\:-translate-x-16:focus {
    --tw-translate-x: -1.6rem
  }

  .print\:focus\:-translate-x-20:focus {
    --tw-translate-x: -2rem
  }

  .print\:focus\:-translate-x-24:focus {
    --tw-translate-x: -2.4rem
  }

  .print\:focus\:-translate-x-28:focus {
    --tw-translate-x: -2.8rem
  }

  .print\:focus\:-translate-x-32:focus {
    --tw-translate-x: -3.2rem
  }

  .print\:focus\:-translate-x-36:focus {
    --tw-translate-x: -3.6rem
  }

  .print\:focus\:-translate-x-40:focus {
    --tw-translate-x: -4rem
  }

  .print\:focus\:-translate-x-44:focus {
    --tw-translate-x: -4.4rem
  }

  .print\:focus\:-translate-x-48:focus {
    --tw-translate-x: -4.8rem
  }

  .print\:focus\:-translate-x-52:focus {
    --tw-translate-x: -5.2rem
  }

  .print\:focus\:-translate-x-56:focus {
    --tw-translate-x: -5.6rem
  }

  .print\:focus\:-translate-x-60:focus {
    --tw-translate-x: -6rem
  }

  .print\:focus\:-translate-x-64:focus {
    --tw-translate-x: -6.4rem
  }

  .print\:focus\:-translate-x-68:focus {
    --tw-translate-x: -6.8rem
  }

  .print\:focus\:-translate-x-72:focus {
    --tw-translate-x: -7.2rem
  }

  .print\:focus\:-translate-x-76:focus {
    --tw-translate-x: -7.6rem
  }

  .print\:focus\:-translate-x-80:focus {
    --tw-translate-x: -8rem
  }

  .print\:focus\:-translate-x-84:focus {
    --tw-translate-x: -8.4rem
  }

  .print\:focus\:-translate-x-88:focus {
    --tw-translate-x: -8.8rem
  }

  .print\:focus\:-translate-x-92:focus {
    --tw-translate-x: -9.2rem
  }

  .print\:focus\:-translate-x-96:focus {
    --tw-translate-x: -9.6rem
  }

  .print\:focus\:-translate-x-128:focus {
    --tw-translate-x: -12.8rem
  }

  .print\:focus\:-translate-x-136:focus {
    --tw-translate-x: -13.6rem
  }

  .print\:focus\:-translate-x-160:focus {
    --tw-translate-x: -16rem
  }

  .print\:focus\:-translate-x-192:focus {
    --tw-translate-x: -19.2rem
  }

  .print\:focus\:-translate-x-200:focus {
    --tw-translate-x: -20rem
  }

  .print\:focus\:-translate-x-208:focus {
    --tw-translate-x: -20.8rem
  }

  .print\:focus\:-translate-x-216:focus {
    --tw-translate-x: -21.6rem
  }

  .print\:focus\:-translate-x-224:focus {
    --tw-translate-x: -22.4rem
  }

  .print\:focus\:-translate-x-256:focus {
    --tw-translate-x: -25.6rem
  }

  .print\:focus\:-translate-x-288:focus {
    --tw-translate-x: -28.8rem
  }

  .print\:focus\:-translate-x-320:focus {
    --tw-translate-x: -32rem
  }

  .print\:focus\:-translate-x-360:focus {
    --tw-translate-x: -36rem
  }

  .print\:focus\:-translate-x-384:focus {
    --tw-translate-x: -38.4rem
  }

  .print\:focus\:-translate-x-400:focus {
    --tw-translate-x: -40rem
  }

  .print\:focus\:-translate-x-512:focus {
    --tw-translate-x: -51.2rem
  }

  .print\:focus\:-translate-x-640:focus {
    --tw-translate-x: -64rem
  }

  .print\:focus\:-translate-x-xs:focus {
    --tw-translate-x: -32rem
  }

  .print\:focus\:-translate-x-sm:focus {
    --tw-translate-x: -48rem
  }

  .print\:focus\:-translate-x-md:focus {
    --tw-translate-x: -64rem
  }

  .print\:focus\:-translate-x-lg:focus {
    --tw-translate-x: -80rem
  }

  .print\:focus\:-translate-x-xl:focus {
    --tw-translate-x: -96rem
  }

  .print\:focus\:-translate-x-2xl:focus {
    --tw-translate-x: -112rem
  }

  .print\:focus\:-translate-x-3xl:focus {
    --tw-translate-x: -128rem
  }

  .print\:focus\:-translate-x-4xl:focus {
    --tw-translate-x: -144rem
  }

  .print\:focus\:-translate-x-5xl:focus {
    --tw-translate-x: -160rem
  }

  .print\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px
  }

  .print\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.05rem
  }

  .print\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.15rem
  }

  .print\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.25rem
  }

  .print\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.35rem
  }

  .print\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%
  }

  .print\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%
  }

  .print\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%
  }

  .print\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%
  }

  .print\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%
  }

  .print\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%
  }

  .print\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%
  }

  .print\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%
  }

  .print\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%
  }

  .print\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%
  }

  .print\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%
  }

  .print\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%
  }

  .print\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%
  }

  .print\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%
  }

  .print\:focus\:translate-y-0:focus {
    --tw-translate-y: 0
  }

  .print\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.1rem
  }

  .print\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.2rem
  }

  .print\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.3rem
  }

  .print\:focus\:translate-y-4:focus {
    --tw-translate-y: 0.4rem
  }

  .print\:focus\:translate-y-5:focus {
    --tw-translate-y: 0.5rem
  }

  .print\:focus\:translate-y-6:focus {
    --tw-translate-y: 0.6rem
  }

  .print\:focus\:translate-y-7:focus {
    --tw-translate-y: 0.7rem
  }

  .print\:focus\:translate-y-8:focus {
    --tw-translate-y: 0.8rem
  }

  .print\:focus\:translate-y-9:focus {
    --tw-translate-y: 0.9rem
  }

  .print\:focus\:translate-y-10:focus {
    --tw-translate-y: 1.0rem
  }

  .print\:focus\:translate-y-12:focus {
    --tw-translate-y: 1.2rem
  }

  .print\:focus\:translate-y-14:focus {
    --tw-translate-y: 1.4rem
  }

  .print\:focus\:translate-y-16:focus {
    --tw-translate-y: 1.6rem
  }

  .print\:focus\:translate-y-20:focus {
    --tw-translate-y: 2rem
  }

  .print\:focus\:translate-y-24:focus {
    --tw-translate-y: 2.4rem
  }

  .print\:focus\:translate-y-28:focus {
    --tw-translate-y: 2.8rem
  }

  .print\:focus\:translate-y-32:focus {
    --tw-translate-y: 3.2rem
  }

  .print\:focus\:translate-y-36:focus {
    --tw-translate-y: 3.6rem
  }

  .print\:focus\:translate-y-40:focus {
    --tw-translate-y: 4rem
  }

  .print\:focus\:translate-y-44:focus {
    --tw-translate-y: 4.4rem
  }

  .print\:focus\:translate-y-48:focus {
    --tw-translate-y: 4.8rem
  }

  .print\:focus\:translate-y-52:focus {
    --tw-translate-y: 5.2rem
  }

  .print\:focus\:translate-y-56:focus {
    --tw-translate-y: 5.6rem
  }

  .print\:focus\:translate-y-60:focus {
    --tw-translate-y: 6rem
  }

  .print\:focus\:translate-y-64:focus {
    --tw-translate-y: 6.4rem
  }

  .print\:focus\:translate-y-68:focus {
    --tw-translate-y: 6.8rem
  }

  .print\:focus\:translate-y-72:focus {
    --tw-translate-y: 7.2rem
  }

  .print\:focus\:translate-y-76:focus {
    --tw-translate-y: 7.6rem
  }

  .print\:focus\:translate-y-80:focus {
    --tw-translate-y: 8rem
  }

  .print\:focus\:translate-y-84:focus {
    --tw-translate-y: 8.4rem
  }

  .print\:focus\:translate-y-88:focus {
    --tw-translate-y: 8.8rem
  }

  .print\:focus\:translate-y-92:focus {
    --tw-translate-y: 9.2rem
  }

  .print\:focus\:translate-y-96:focus {
    --tw-translate-y: 9.6rem
  }

  .print\:focus\:translate-y-128:focus {
    --tw-translate-y: 12.8rem
  }

  .print\:focus\:translate-y-136:focus {
    --tw-translate-y: 13.6rem
  }

  .print\:focus\:translate-y-160:focus {
    --tw-translate-y: 16rem
  }

  .print\:focus\:translate-y-192:focus {
    --tw-translate-y: 19.2rem
  }

  .print\:focus\:translate-y-200:focus {
    --tw-translate-y: 20rem
  }

  .print\:focus\:translate-y-208:focus {
    --tw-translate-y: 20.8rem
  }

  .print\:focus\:translate-y-216:focus {
    --tw-translate-y: 21.6rem
  }

  .print\:focus\:translate-y-224:focus {
    --tw-translate-y: 22.4rem
  }

  .print\:focus\:translate-y-256:focus {
    --tw-translate-y: 25.6rem
  }

  .print\:focus\:translate-y-288:focus {
    --tw-translate-y: 28.8rem
  }

  .print\:focus\:translate-y-320:focus {
    --tw-translate-y: 32rem
  }

  .print\:focus\:translate-y-360:focus {
    --tw-translate-y: 36rem
  }

  .print\:focus\:translate-y-384:focus {
    --tw-translate-y: 38.4rem
  }

  .print\:focus\:translate-y-400:focus {
    --tw-translate-y: 40rem
  }

  .print\:focus\:translate-y-512:focus {
    --tw-translate-y: 51.2rem
  }

  .print\:focus\:translate-y-640:focus {
    --tw-translate-y: 64rem
  }

  .print\:focus\:translate-y-xs:focus {
    --tw-translate-y: 32rem
  }

  .print\:focus\:translate-y-sm:focus {
    --tw-translate-y: 48rem
  }

  .print\:focus\:translate-y-md:focus {
    --tw-translate-y: 64rem
  }

  .print\:focus\:translate-y-lg:focus {
    --tw-translate-y: 80rem
  }

  .print\:focus\:translate-y-xl:focus {
    --tw-translate-y: 96rem
  }

  .print\:focus\:translate-y-2xl:focus {
    --tw-translate-y: 112rem
  }

  .print\:focus\:translate-y-3xl:focus {
    --tw-translate-y: 128rem
  }

  .print\:focus\:translate-y-4xl:focus {
    --tw-translate-y: 144rem
  }

  .print\:focus\:translate-y-5xl:focus {
    --tw-translate-y: 160rem
  }

  .print\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px
  }

  .print\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.05rem
  }

  .print\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.15rem
  }

  .print\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.25rem
  }

  .print\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.35rem
  }

  .print\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.1rem
  }

  .print\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.2rem
  }

  .print\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.3rem
  }

  .print\:focus\:-translate-y-4:focus {
    --tw-translate-y: -0.4rem
  }

  .print\:focus\:-translate-y-5:focus {
    --tw-translate-y: -0.5rem
  }

  .print\:focus\:-translate-y-6:focus {
    --tw-translate-y: -0.6rem
  }

  .print\:focus\:-translate-y-7:focus {
    --tw-translate-y: -0.7rem
  }

  .print\:focus\:-translate-y-8:focus {
    --tw-translate-y: -0.8rem
  }

  .print\:focus\:-translate-y-9:focus {
    --tw-translate-y: -0.9rem
  }

  .print\:focus\:-translate-y-10:focus {
    --tw-translate-y: -1rem
  }

  .print\:focus\:-translate-y-12:focus {
    --tw-translate-y: -1.2rem
  }

  .print\:focus\:-translate-y-14:focus {
    --tw-translate-y: -1.4rem
  }

  .print\:focus\:-translate-y-16:focus {
    --tw-translate-y: -1.6rem
  }

  .print\:focus\:-translate-y-20:focus {
    --tw-translate-y: -2rem
  }

  .print\:focus\:-translate-y-24:focus {
    --tw-translate-y: -2.4rem
  }

  .print\:focus\:-translate-y-28:focus {
    --tw-translate-y: -2.8rem
  }

  .print\:focus\:-translate-y-32:focus {
    --tw-translate-y: -3.2rem
  }

  .print\:focus\:-translate-y-36:focus {
    --tw-translate-y: -3.6rem
  }

  .print\:focus\:-translate-y-40:focus {
    --tw-translate-y: -4rem
  }

  .print\:focus\:-translate-y-44:focus {
    --tw-translate-y: -4.4rem
  }

  .print\:focus\:-translate-y-48:focus {
    --tw-translate-y: -4.8rem
  }

  .print\:focus\:-translate-y-52:focus {
    --tw-translate-y: -5.2rem
  }

  .print\:focus\:-translate-y-56:focus {
    --tw-translate-y: -5.6rem
  }

  .print\:focus\:-translate-y-60:focus {
    --tw-translate-y: -6rem
  }

  .print\:focus\:-translate-y-64:focus {
    --tw-translate-y: -6.4rem
  }

  .print\:focus\:-translate-y-68:focus {
    --tw-translate-y: -6.8rem
  }

  .print\:focus\:-translate-y-72:focus {
    --tw-translate-y: -7.2rem
  }

  .print\:focus\:-translate-y-76:focus {
    --tw-translate-y: -7.6rem
  }

  .print\:focus\:-translate-y-80:focus {
    --tw-translate-y: -8rem
  }

  .print\:focus\:-translate-y-84:focus {
    --tw-translate-y: -8.4rem
  }

  .print\:focus\:-translate-y-88:focus {
    --tw-translate-y: -8.8rem
  }

  .print\:focus\:-translate-y-92:focus {
    --tw-translate-y: -9.2rem
  }

  .print\:focus\:-translate-y-96:focus {
    --tw-translate-y: -9.6rem
  }

  .print\:focus\:-translate-y-128:focus {
    --tw-translate-y: -12.8rem
  }

  .print\:focus\:-translate-y-136:focus {
    --tw-translate-y: -13.6rem
  }

  .print\:focus\:-translate-y-160:focus {
    --tw-translate-y: -16rem
  }

  .print\:focus\:-translate-y-192:focus {
    --tw-translate-y: -19.2rem
  }

  .print\:focus\:-translate-y-200:focus {
    --tw-translate-y: -20rem
  }

  .print\:focus\:-translate-y-208:focus {
    --tw-translate-y: -20.8rem
  }

  .print\:focus\:-translate-y-216:focus {
    --tw-translate-y: -21.6rem
  }

  .print\:focus\:-translate-y-224:focus {
    --tw-translate-y: -22.4rem
  }

  .print\:focus\:-translate-y-256:focus {
    --tw-translate-y: -25.6rem
  }

  .print\:focus\:-translate-y-288:focus {
    --tw-translate-y: -28.8rem
  }

  .print\:focus\:-translate-y-320:focus {
    --tw-translate-y: -32rem
  }

  .print\:focus\:-translate-y-360:focus {
    --tw-translate-y: -36rem
  }

  .print\:focus\:-translate-y-384:focus {
    --tw-translate-y: -38.4rem
  }

  .print\:focus\:-translate-y-400:focus {
    --tw-translate-y: -40rem
  }

  .print\:focus\:-translate-y-512:focus {
    --tw-translate-y: -51.2rem
  }

  .print\:focus\:-translate-y-640:focus {
    --tw-translate-y: -64rem
  }

  .print\:focus\:-translate-y-xs:focus {
    --tw-translate-y: -32rem
  }

  .print\:focus\:-translate-y-sm:focus {
    --tw-translate-y: -48rem
  }

  .print\:focus\:-translate-y-md:focus {
    --tw-translate-y: -64rem
  }

  .print\:focus\:-translate-y-lg:focus {
    --tw-translate-y: -80rem
  }

  .print\:focus\:-translate-y-xl:focus {
    --tw-translate-y: -96rem
  }

  .print\:focus\:-translate-y-2xl:focus {
    --tw-translate-y: -112rem
  }

  .print\:focus\:-translate-y-3xl:focus {
    --tw-translate-y: -128rem
  }

  .print\:focus\:-translate-y-4xl:focus {
    --tw-translate-y: -144rem
  }

  .print\:focus\:-translate-y-5xl:focus {
    --tw-translate-y: -160rem
  }

  .print\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px
  }

  .print\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.05rem
  }

  .print\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.15rem
  }

  .print\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.25rem
  }

  .print\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.35rem
  }

  .print\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%
  }

  .print\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%
  }

  .print\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%
  }

  .print\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%
  }

  .print\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%
  }

  .print\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%
  }

  .print\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%
  }

  .print\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%
  }

  .print\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%
  }

  .print\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%
  }

  .print\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%
  }

  .print\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%
  }

  .print\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%
  }

  .print\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%
  }

  .print\:skew-x-0 {
    --tw-skew-x: 0deg
  }

  .print\:skew-x-1 {
    --tw-skew-x: 1deg
  }

  .print\:skew-x-2 {
    --tw-skew-x: 2deg
  }

  .print\:skew-x-3 {
    --tw-skew-x: 3deg
  }

  .print\:skew-x-6 {
    --tw-skew-x: 6deg
  }

  .print\:skew-x-12 {
    --tw-skew-x: 12deg
  }

  .print\:-skew-x-12 {
    --tw-skew-x: -12deg
  }

  .print\:-skew-x-6 {
    --tw-skew-x: -6deg
  }

  .print\:-skew-x-3 {
    --tw-skew-x: -3deg
  }

  .print\:-skew-x-2 {
    --tw-skew-x: -2deg
  }

  .print\:-skew-x-1 {
    --tw-skew-x: -1deg
  }

  .print\:skew-y-0 {
    --tw-skew-y: 0deg
  }

  .print\:skew-y-1 {
    --tw-skew-y: 1deg
  }

  .print\:skew-y-2 {
    --tw-skew-y: 2deg
  }

  .print\:skew-y-3 {
    --tw-skew-y: 3deg
  }

  .print\:skew-y-6 {
    --tw-skew-y: 6deg
  }

  .print\:skew-y-12 {
    --tw-skew-y: 12deg
  }

  .print\:-skew-y-12 {
    --tw-skew-y: -12deg
  }

  .print\:-skew-y-6 {
    --tw-skew-y: -6deg
  }

  .print\:-skew-y-3 {
    --tw-skew-y: -3deg
  }

  .print\:-skew-y-2 {
    --tw-skew-y: -2deg
  }

  .print\:-skew-y-1 {
    --tw-skew-y: -1deg
  }

  .print\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg
  }

  .print\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg
  }

  .print\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg
  }

  .print\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg
  }

  .print\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg
  }

  .print\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg
  }

  .print\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg
  }

  .print\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg
  }

  .print\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg
  }

  .print\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg
  }

  .print\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg
  }

  .print\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg
  }

  .print\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg
  }

  .print\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg
  }

  .print\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg
  }

  .print\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg
  }

  .print\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg
  }

  .print\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg
  }

  .print\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg
  }

  .print\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg
  }

  .print\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg
  }

  .print\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg
  }

  .print\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg
  }

  .print\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg
  }

  .print\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg
  }

  .print\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg
  }

  .print\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg
  }

  .print\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg
  }

  .print\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg
  }

  .print\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg
  }

  .print\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg
  }

  .print\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg
  }

  .print\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg
  }

  .print\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg
  }

  .print\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg
  }

  .print\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg
  }

  .print\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg
  }

  .print\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg
  }

  .print\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg
  }

  .print\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg
  }

  .print\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg
  }

  .print\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg
  }

  .print\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg
  }

  .print\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg
  }

  .print\:transition-none {
    transition-property: none
  }

  .print\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .print\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .print\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .print\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .print\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .print\:transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .print\:ease-linear {
    transition-timing-function: linear
  }

  .print\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .print\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .print\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .print\:duration-75 {
    transition-duration: 75ms
  }

  .print\:duration-100 {
    transition-duration: 100ms
  }

  .print\:duration-150 {
    transition-duration: 150ms
  }

  .print\:duration-200 {
    transition-duration: 200ms
  }

  .print\:duration-300 {
    transition-duration: 300ms
  }

  .print\:duration-500 {
    transition-duration: 500ms
  }

  .print\:duration-700 {
    transition-duration: 700ms
  }

  .print\:duration-1000 {
    transition-duration: 1000ms
  }

  .print\:delay-75 {
    transition-delay: 75ms
  }

  .print\:delay-100 {
    transition-delay: 100ms
  }

  .print\:delay-150 {
    transition-delay: 150ms
  }

  .print\:delay-200 {
    transition-delay: 200ms
  }

  .print\:delay-300 {
    transition-delay: 300ms
  }

  .print\:delay-500 {
    transition-delay: 500ms
  }

  .print\:delay-700 {
    transition-delay: 700ms
  }

  .print\:delay-1000 {
    transition-delay: 1000ms
  }

  .print\:animate-none {
    animation: none
  }

  .print\:animate-spin {
    animation: spin 1s linear infinite
  }

  .print\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .print\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .print\:animate-bounce {
    animation: bounce 1s infinite
  }
}
